import React from 'react';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Modals } from 'appConstants/navigation';

class ScopeModalNavigationTracker extends React.Component {
  render() {
    return <NavigationTracker modal={Modals.SCOPE} />;
  }
}

export default withRouter(ScopeModalNavigationTracker);
