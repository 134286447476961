import React, { useState, useCallback } from 'react';
import { RatesDropdownContainer } from 'BudgetModule/components/BudgetTable/styles';
import {
  HeaderContainer,
  StyledMemberName
} from 'BudgetModule/components/BudgetModal/styles';
import TeamRatesDropdown from './TeamRatesDropdown';
import RateDeleteConfirmation from './RateDeleteConfirmation';
import RatesDropdown from 'BudgetModule/components/BudgetModal/RatesDropdown';
import {
  createMemberTeamRate,
  updateMemberTeamRate
} from 'BudgetModule/actionCreators';
import { getSelectedTeamId } from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TeamRateOverrideModal from './TeamRateOverrideModal';
import { RATE_MODAL_TYPES } from 'appConstants';
import MemberInitials from 'views/memberDisplay/MemberInitials';

const noop = () => {};

const TeamRateSelection = ({
  children,
  memberName,
  target,
  handleRateClick = noop,
  handleCreateTeamRate,
  handleUpdateTeamRate,
  handleDeleteTeamRate,
  teamMembershipId,
  accountId,
  member,
  noRateHistoryModal,
  ratesHeaderText,
  showX = false,
  showKarat = true,
  noOverride,
  rates,
  disableChangeEarliestRateStart,
  disableDeleteOnlyRate = true
}) => {
  const [openModal, setOpenModal] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToChangeDate, setItemToChangeDate] = useState(null);
  const [itemToChangeRate, setItemToChangeRate] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToConfirmOverride, setItemToConfirmOverride] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const onStickyClick = useCallback(() => setOpenModal('rates'), []);
  const handleCloseModal = useCallback(() => setOpenModal(null), []);

  const handleOpenTeamRates = (e) => {
    handleRateClick(e);
    setOpenModal(noRateHistoryModal ? 'rates' : 'teamRates');
    e && e.stopPropagation();
  };

  const handleCreateMemberTeamRate = (
    item,
    override,
    modalType = 'teamRates'
  ) => {
    if (override) {
      setIsSubmitted(true);
    } else {
      setOpenModal(noRateHistoryModal ? null : 'teamRates');
      setItemToConfirmOverride(null);
    }
    const startDate = moment().format('YYYY-MM-DD');
    if (handleCreateTeamRate) {
      handleCreateTeamRate({ rate: item, shouldOverride: override, startDate });
    } else {
      dispatch(
        createMemberTeamRate({
          rateId: item.id,
          teamMembershipId,
          startDate,
          override,
          accountId,
          teamId
        })
      );
    }
  };

  const handleDeleteItemSelection = useCallback((item, rates) => {
    setItemToDelete([item, rates]);
  }, []);

  const setEditRate = useCallback((item) => {
    setItemToEdit(item);
    setOpenModal('rates');
  }, []);

  const handleConfirmOverride = (override) => {
    handleCreateMemberTeamRate(itemToConfirmOverride, override);
  };

  const handleOverrideCancel = () => {
    setItemToConfirmOverride(null);
    setOpenModal(noRateHistoryModal ? 'rates' : 'teamRates');
  };

  const handleRatesDropdownSelection = (item) => {
    if (itemToEdit !== null) {
      setItemToChangeRate({ item: itemToEdit, newRate: item });
      setOpenModal('teamRates');
      setItemToConfirmOverride(item);
    } else {
      setOpenModal('teamRates');
      if (!noOverride) {
        setItemToConfirmOverride(item);
      } else {
        handleCreateMemberTeamRate(item);
      }
    }
  };

  const handleClose = () => setOpenModal(null);

  const handleDeleteClose = () => {
    setItemToDelete(null);
    setOpenModal('teamRates');
  };

  const handleDateChange = (item, date, dateType) => {
    setItemToChangeDate({ item, date, dateType });
  };

  const handleDateChangeClose = () => {
    setItemToChangeDate(null);
  };

  const handleDateChangeConfirm = (override) => {
    const dateParam = {
      // key is either 'startDate' or 'endDate'
      [itemToChangeDate.dateType]: moment(itemToChangeDate.date).format(
        'YYYY-MM-DD'
      )
    };
    if (handleUpdateTeamRate) {
      handleUpdateTeamRate({
        teamRateBeingUpdated: itemToChangeDate.item,
        shouldOverride: override,
        ...dateParam
      });
    } else {
      dispatch(
        updateMemberTeamRate({
          teamRateId: itemToChangeDate.item.id,
          ...dateParam,
          teamId,
          teamMembershipId,
          override
        })
      );
    }
    if (override) {
      setIsSubmitted(true);
    } else {
      handleDateChangeClose();
    }
  };

  const handleRateChangeClose = () => {
    setItemToChangeRate(null);
    setItemToConfirmOverride(null);
    setItemToEdit(null);
  };

  const handleRateChangeConfirm = (override) => {
    if (handleUpdateTeamRate) {
      handleUpdateTeamRate({
        teamRateBeingUpdated: itemToChangeRate.item,
        rate: itemToChangeRate.newRate,
        shouldOverride: override
      });
    } else {
      dispatch(
        updateMemberTeamRate({
          teamRateId: itemToChangeRate.item.id,
          rateId: itemToChangeRate.newRate.id,
          teamId,
          teamMembershipId,
          override
        })
      );
    }
    if (override) {
      setIsSubmitted(true);
    } else {
      setOpenModal(noRateHistoryModal ? null : 'teamRates');
      handleRateChangeClose();
    }
  };

  const clearIsSubmitted = () => {
    setIsSubmitted(false);
    handleRateChangeClose();
    handleDateChangeClose();
    setOpenModal(noRateHistoryModal ? null : 'teamRates');
  };

  return (
    <RatesDropdownContainer inTableCell onClick={handleOpenTeamRates}>
      {children}
      {openModal === 'rates' && (
        <RatesDropdown
          target={target}
          showX={showX}
          showKarat={showKarat}
          handleSelect={(e, { item, selectCallback, index }) => {
            handleRatesDropdownSelection(item);
          }}
          handleClose={() =>
            setOpenModal(noRateHistoryModal ? null : 'teamRates')
          }
          showRates
          headerText={ratesHeaderText || 'Select New Bill Rate'}
        />
      )}
      {openModal === 'teamRates' &&
        (itemToDelete !== null ? (
          <RateDeleteConfirmation
            deleteItem={itemToDelete[0]}
            handleClose={handleDeleteClose}
            memberTeamRates={itemToDelete[1]}
            teamMembershipId={teamMembershipId}
            onDeleteConfirm={handleDeleteTeamRate}
          />
        ) : itemToChangeDate ? (
          <TeamRateOverrideModal
            isUpdate
            onYes={() => handleDateChangeConfirm(true)}
            onNo={() => handleDateChangeConfirm(false)}
            onCancel={handleDateChangeClose}
            selectedMemberTeamRate={itemToChangeDate.item}
            selectedDate={itemToChangeDate.date}
            dateType={itemToChangeDate.dateType}
            teamMembershipId={teamMembershipId}
            isSubmitted={isSubmitted}
            clearIsSubmitted={clearIsSubmitted}
            accountId={accountId}
            rates={rates}
          />
        ) : itemToChangeRate ? (
          <TeamRateOverrideModal
            onYes={() => handleRateChangeConfirm(true)}
            onNo={() => handleRateChangeConfirm(false)}
            onCancel={handleRateChangeClose}
            selectedRate={itemToChangeRate.newRate}
            selectedMemberTeamRate={itemToChangeRate.item}
            teamMembershipId={teamMembershipId}
            headerCopy="Update Billing Rate"
            isSubmitted={isSubmitted}
            clearIsSubmitted={clearIsSubmitted}
            accountId={accountId}
            rates={rates}
          />
        ) : itemToConfirmOverride ? (
          <TeamRateOverrideModal
            onYes={() => handleConfirmOverride(true)}
            onNo={() => handleConfirmOverride(false)}
            onCancel={handleOverrideCancel}
            selectedRate={itemToConfirmOverride}
            teamMembershipId={teamMembershipId}
            isSubmitted={isSubmitted}
            clearIsSubmitted={clearIsSubmitted}
            accountId={accountId}
            rates={rates}
          />
        ) : (
          <TeamRatesDropdown
            memberName={memberName}
            target={target}
            popoverClassName="member-rates-modal"
            teamMembershipId={teamMembershipId}
            onStickyClick={onStickyClick}
            handleClose={handleClose}
            handleDelete={handleDeleteItemSelection}
            handleDateChange={handleDateChange}
            editRate={setEditRate}
            member={member}
            modalType={RATE_MODAL_TYPES.BILL_RATE}
            headerText={`${memberName || ''}'s Bill Rate`}
            rates={rates}
            disableChangeEarliestRateStart={disableChangeEarliestRateStart}
            disableDeleteOnlyRate={disableDeleteOnlyRate}
          />
        ))}
    </RatesDropdownContainer>
  );
};

export default TeamRateSelection;
