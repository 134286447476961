import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { downloadUrl } from 'actionCreators';
import { getDownloadUrl, getIsGeneratingUrl } from 'selectors';
import { StyledLoadingContainer, StyledLoader } from './styles';

const FileDownloader = () => {
  const dispatch = useDispatch();
  const urlToDownload = useSelector(getDownloadUrl);
  const isGeneratingUrl = useSelector(getIsGeneratingUrl);

  useEffect(() => {
    if (urlToDownload) {
      window.open(urlToDownload, '_blank');
      dispatch(downloadUrl({ url: null }));
    }
  }, [dispatch, urlToDownload]);

  return isGeneratingUrl ? (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        display: 'flex',
        top: '5%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <StyledLoadingContainer>
        <StyledLoader></StyledLoader>
      </StyledLoadingContainer>
    </div>
  ) : null;
};

export default FileDownloader;
