import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
`;

const PhaseHoursSubRowCell = ({ row }) => {
  if (row.original.row?.index > 0) return null;
  return <StyledText>HOURS REQUIRED</StyledText>;
};

export default PhaseHoursSubRowCell;
