import { useAppSelector } from 'reduxInfra/hooks';
import { getMe } from 'UsersModule/selectors';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import {
  SimpleFilterDropdown,
  SimpleFilterDropdownStyleWrapper
} from './SimpleFilterDropdown';
import { useMembersFilter } from 'FilterModule/hooks/useMembersFilter';
import {
  FilterListTypeDropdown,
  FilterListTypeDropdownUIProps
} from 'FilterModule/types';

export const MembersFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const { updateSelectedItems, optionsArray, selectedItems, itemHash } =
    useMembersFilter();

  return (
    <MembersFilterDropdownUI
      itemHash={itemHash}
      optionsArray={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      renderToggle={renderToggle}
    />
  );
};

export const MembersFilterDropdownUI = ({
  itemHash,
  optionsArray,
  selectedItems,
  updateSelectedItems,
  renderToggle
}: FilterListTypeDropdownUIProps<typeof useMembersFilter>) => {
  const me = useAppSelector(getMe);

  const renderLabel = (accountId: number) => {
    const member = itemHash[accountId];
    // TODO: not handling filter items that should not longer be in the filter yet (eg. deleted account)
    if (!member) return <></>;
    return (
      <StyledItemLabel>
        <MemberInitials
          size="small"
          member={member}
          classes={cn('item-account selected', {
            'small-logged-member': accountId === me?.id,
            'small-regular-member': accountId !== me?.id
          })}
          idx={'idx-placeholder'}
          styles={{ marginLeft: 4 }}
          isOnTaskModal
        />
        <Name className="no-text-overflow" title={member.name}>
          {member.name}
        </Name>
        {member.is_archived && <ArchivedLabel>ARCHIVED</ArchivedLabel>}
      </StyledItemLabel>
    );
  };

  return (
    <SimpleFilterDropdown
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isBatchClearOnly
      labels={labels}
      labelKey="name"
      itemHeight={55}
      listWidth={250}
      listHeight={220}
      itemHash={itemHash}
      renderItemLabel={renderLabel}
      renderToggle={renderToggle}
      styleWrapper={StyledProjectsFiltersDropdown}
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

const labels = {
  searchPlaceholder: 'Type Member or select below'
};

const StyledProjectsFiltersDropdown = styled(SimpleFilterDropdownStyleWrapper)`
  .flyout-list-item-container:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.colorPaleGray9};
  }
`;

const StyledItemLabel = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  position: relative;
  .member-initials {
    margin-right: 10px;
    width: unset;
  }
`;

const Name = styled.span`
  flex: 1;
`;

const ArchivedLabel = styled.span`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  bottom: -7px;
  left: 41px;
  font-weight: 600;
`;
