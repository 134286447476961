import React from 'react';
import ScrollArea from 'react-scrollbar';

// function that checks to see if user is running on given OS
export const checkForOS = (operatingSys) => {
  return navigator.platform.indexOf(operatingSys) > -1;
};

// function for status window will override styles if Windows OS detected
export const windowsStyling = () => {
  // if OS is Mac, return default css classes, otherwise render additional windows scroll class to hide scrollbars
  return checkForOS('Mac')
    ? 'status-list with-add-form'
    : 'status-list with-add-form windows-scroll';
};

// this function will detect if app is running on operatingSys argument, then render custom scrollbar wrapped around whatever content is passed in

// for operatingSys, use 'Mac' or 'Win'

const verticalScrollbarStyle = {
  borderRadius: 5,
  backgroundColor: '#e6e6e6',
  opacity: '1'
};
export const scrollBarStyle = (operatingSys, content) => {
  if (checkForOS(operatingSys)) {
    return (
      <ScrollArea verticalScrollbarStyle={verticalScrollbarStyle}>
        {content}
      </ScrollArea>
    );
  } else {
    return <div className="windows-scroll">{content}</div>;
  }
};
