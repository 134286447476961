import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

interface ListItemProps {
  primary: ReactNode | string;
  secondary?: ReactNode | string;
  trailing?: ReactNode;
  style?: CSSProperties;
}
export const ListItem = ({
  primary,
  secondary,
  trailing,
  style
}: ListItemProps) => {
  return (
    <ListItemContainer style={style} className="dependency-target-item">
      <PrimaryContainer className="dependency-target-item">
        <PrimaryRow className="dependency-target-item">{primary}</PrimaryRow>
        {secondary && (
          <SecondaryRow className="dependency-target-item">
            {secondary}
          </SecondaryRow>
        )}
      </PrimaryContainer>
      {trailing && (
        <TrailingContainer className="dependency-target-item">
          {trailing}
        </TrailingContainer>
      )}
    </ListItemContainer>
  );
};

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 34px;
  padding: 8px 16px;
  gap: 4px;
  width: 100%;
`;

const PrimaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
`;
const TrailingContainer = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;

const PrimaryRow = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const SecondaryRow = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;
