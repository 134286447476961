import React from 'react';
import PropTypes from 'prop-types';
import FLYOUT_ACTIONS from 'appConstants/flyoutActions';

import bindAll from 'lodash/bindAll';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  updateProjectPosition,
  archiveUnarchiveProject,
  deleteProject,
  cloneProjects
} from 'actionCreators';
import { ChangePriorityList } from '..';
import {
  getProjectsState,
  getAuth,
  getMatchedRouteParams,
  getSelectedTeamId,
  getSelectedBoard,
  getGroups
} from 'selectors';

import Popover from 'components/Popover';
import uuid from 'uuid';

class ChangePriorityContainer extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, ['switchPriority', 'handleEditInfo']);
  }

  handleEditInfo(event) {
    const { editProjectInfo } = this.props;
    editProjectInfo(event);
    this.handleClose();
  }

  handleClose = () => {
    const { setPriorityOptionsId } = this.props;
    setPriorityOptionsId(null);
  };

  getPermissions = () => ({
    permissions: {
      projectId: this.props.project.id,
      boardId: this.props.project.board_id,
      teamId: this.props.teamId
    }
  });

  switchPriority(type) {
    const {
      project,
      matchedParams,
      updateProjectPosition,
      projects,
      handleModalState,
      auth,
      archiveUnarchiveProject
    } = this.props;
    const accountIds = projects.selectedAccountIds;

    if (type === FLYOUT_ACTIONS.projects.delete) {
      if (this.props.checkDeletePermission()) {
        handleModalState(true);
      }
    } else if (
      type === FLYOUT_ACTIONS.projects.archive ||
      type === FLYOUT_ACTIONS.projects.unarchive
    ) {
      const payload = {
        token: auth.token,
        projectId: project.id,
        isActive: !projects.isActive,
        position: project.position,
        permissions: this.getPermissions().permissions
      };
      if (this.props.checkArchivePermission()) {
        archiveUnarchiveProject(payload);
      }
    } else {
      const position = 0;
      const isActive = projects.isActive;
      const wasActive = projects.isActive;

      updateProjectPosition(
        project.id,
        isActive,
        position,
        matchedParams.boardId,
        wasActive,
        accountIds
      );
      this.handleClose();
    }
  }

  handleClone = (targetBoard) => {
    const { cloneProjects, project } = this.props;
    cloneProjects({
      projectIds: { [project.id]: project.id },
      targetBoard: targetBoard.id,
      batchId: uuid()
    });
    this.handleClose();
  };

  render() {
    const {
      project,
      projects,
      showPriorityOptions,
      popoverTarget,
      board,
      boards
    } = this.props;

    return (
      <Popover
        isOpen={showPriorityOptions}
        target={popoverTarget}
        className="team-view-project-dropdown"
        closePopover={this.handleClose}
      >
        <ChangePriorityList
          project={project}
          switchPriority={this.switchPriority}
          showPriorityOptions={showPriorityOptions}
          isActive={projects.isActive}
          editProjectInfo={this.handleEditInfo}
          canArchive={this.props.checkArchivePermission}
          canDelete={this.props.checkDeletePermission}
          boardInfo={board}
          boards={boards}
          handleClone={this.handleClone}
        />
      </Popover>
    );
  }
}

ChangePriorityContainer.propTypes = {
  updateProjectPosition: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  projects: getProjectsState(state),
  matchedParams: getMatchedRouteParams(state),
  board: getSelectedBoard(state),
  boards: getGroups(state),
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  updateProjectPosition,
  archiveUnarchiveProject,
  deleteProject,
  cloneProjects
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  updateProjectPosition: (...args) =>
    dispatchProps.updateProjectPosition(stateProps.auth.token, ...args)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(ChangePriorityContainer)
);
