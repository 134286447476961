import React from 'react';
import {
  ReportsHeader,
  MainHeaderContainer
} from 'ReportsModule/components/styles';

import HeaderTooltipIcon from 'icons/HeaderTooltipIcon';
import ContentLoader from 'react-content-loader';

const OptionsSkeletonLoader = ({ unsetWidths, title, style }) => (
  <MainHeaderContainer unsetWidths={unsetWidths} style={style}>
    <ReportsHeader>
      <div className="row">
        <div className="title">{title} </div>
        {/* <HeaderTooltipIcon /> */}
      </div>
      <div className="row spaced">
        <div className="row">
          <ContentLoader
            height="25"
            primaryColor="#ddd"
            secondaryColor="#eee"
            style={{ width: 300 }}
          >
            <rect x="0" y="5" rx="2" ry="2" width="300" height="20" />
          </ContentLoader>
        </div>
      </div>
    </ReportsHeader>
  </MainHeaderContainer>
);

export default OptionsSkeletonLoader;
