import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface ThreePeopleIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  stroke?: string;
}

export const ThreePeopleIcon = ({
  stroke = '#4F4F4F',
  width = '12',
  height = '12'
}: ThreePeopleIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.5" cy="4.5" r="1.5" stroke={stroke} strokeWidth="0.8" />
      <ellipse
        cx="7.89173"
        cy="2.9098"
        rx="2.00501"
        ry="2.00501"
        stroke={stroke}
        strokeWidth="0.8"
      />
      <path
        d="M1 10C1 8.34315 2.16339 7 3.59851 7C4.11465 7 4.59564 7.17373 5 7.4733"
        stroke={stroke}
        strokeWidth="0.8"
      />
      <circle
        r="1.5"
        transform="matrix(-1 0 0 1 12.5 4.5)"
        stroke={stroke}
        strokeWidth="0.8"
      />
      <path
        d="M15 10C15 8.34315 13.8366 7 12.4015 7C11.8854 7 11.4044 7.17373 11 7.4733"
        stroke={stroke}
        strokeWidth="0.8"
      />
      <path
        d="M11.9829 10.2078C11.9829 7.9931 10.1876 6.19775 7.97292 6.19775C5.75824 6.19775 3.96289 7.9931 3.96289 10.2078"
        stroke={stroke}
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
};

export default ThreePeopleIcon;
