import { useGroupedMembersFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { usePositions } from 'BudgetModule/hooks';

export const useMembersByPositionFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  field,
  shouldUseDraft
} = {}) => {
  const { handleNavToStandardPositionsPage, positionHash, getPositionLabel } =
    usePositions({
      isOff
    });

  const groupedMembersFilterValues = useGroupedMembersFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'membersByPositionFilter',
    filterListType: FilterListType.MembersByPosition,
    field,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    groupEntityHash: positionHash,
    getGroupEntityLabel: getPositionLabel,
    handleNavToEntityPage: handleNavToStandardPositionsPage
  };
};
