import { useEffect, lazy, Suspense } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { WorkplanPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/permissionProviders/workplan';

const MemberModalOverview = lazy(() => import('./MemberModalOverview'));
const MemberModalTasks = lazy(() => import('./MemberModalTasks'));
const MemberModalActivity = lazy(() => import('./MemberModalActivity'));
const MemberModalWorkPlans = lazy(() => import('./MemberModalWorkPlans'));
const MemberModalWorkSchedule = lazy(() =>
  import('./schedule/MemberModalWorkSchedule').then((module) => ({
    default: module.MemberModalWorkSchedule
  }))
);
const MemberModalCalendar = lazy(() =>
  import('./calendar/MemberModalCalendar')
);

const ProjectDetailsTableTab = lazy(() =>
  import('components/tables/ProjectDetailsTable/AllProjectsReportPageContainer')
);

const MemberModalProfile = lazy(() => import('./profile/MemberModalProfile'));

const MemberModalCheckin = lazy(() => import('./checkin/MemberModalCheckin'));

const FullWidthStyle = css`
  width: 100%;
  margin: 0;
`;

const Body = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
  overflow-y: hidden;
  ${({ activeTab }) => activeTab === 'schedule' && FullWidthStyle}
`;

const bodyComponentHash = {
  overview: MemberModalOverview,
  tasks: MemberModalTasks,
  activity: MemberModalActivity,
  schedule: MemberModalWorkSchedule,
  workplans: MemberModalWorkPlans,
  profile: MemberModalProfile,
  projects: ProjectDetailsTableTab,
  calendar: MemberModalCalendar,
  'check-in': MemberModalCheckin
};

const MemberModalBody = ({ activeTab, accountId }) => {
  const history = useHistory();

  useEffect(() => {
    // if the user inputs something wrong in the URL then redirect to overview
    if (!bodyComponentHash[activeTab]) {
      history.push('./tasks');
    }
  }, [activeTab, history]);

  const BodyComponent = bodyComponentHash[activeTab] || MemberModalOverview;

  return (
    <WorkplanPermissionProvider>
      <Body key={accountId}>
        <Suspense fallback={<div />}>
          <BodyComponent accountId={accountId} />
        </Suspense>
      </Body>
    </WorkplanPermissionProvider>
  );
};

export default MemberModalBody;
