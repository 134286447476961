import { Draft, createReducer } from '@reduxjs/toolkit';
import {
  fetchEntityRequirementsActionCreatorsMap,
  updateEntityRequirementsActionCreatorsMap
} from '../actionCreators';
import { ENTITY_REQUIREMENT_TYPES } from '../constants';
import { EntitiesRequirementAssociation } from '../types';
import { ValueOf } from 'type-fest';

/**
 * See READMD.md for distinction between entity and requirements
 */
export type EntityRequirementsState = {
  requirementAssociationsByEntity: {
    [entityRequirementType: ValueOf<typeof ENTITY_REQUIREMENT_TYPES>]: {
      [entityId: number]: EntitiesRequirementAssociation;
    };
  };
  /**
   * requirementAssociationsByEntity : {
   *  [ProjectMembership]: {
   *    1: { location_ids: [], region_ids:[], etc... }
   *  }
   * }
   */
};

export const initialState = {
  requirementAssociationsByEntity: {}
};

const handleFetchEntityRequirementsSuccess = (
  state: Draft<EntityRequirementsState>,
  action: ReturnType<typeof fetchEntityRequirementsActionCreatorsMap.success>
) => {
  const { associations } = action.payload.response;
  const nextState = {
    ...state.requirementAssociationsByEntity
  };

  associations.forEach((association) => {
    const { entity_id, entity_type } = association;
    if (!nextState[entity_type]) {
      nextState[entity_type] = {};
    }
    (
      nextState[
        entity_type
      ] as EntityRequirementsState['requirementAssociationsByEntity']['entityRequirementType']
    )[entity_id] = association;
  });

  state.requirementAssociationsByEntity = nextState;
};

const handleUpdateEntityRequirementsSuccess = (
  state: Draft<EntityRequirementsState>,
  action: ReturnType<typeof updateEntityRequirementsActionCreatorsMap.success>
) => {
  const { associations } = action.payload.response;

  const nextState = { ...state.requirementAssociationsByEntity };

  associations.forEach((association) => {
    const { entity_id, entity_type } = association;
    if (!nextState[entity_type]) {
      nextState[entity_type] = {};
    }
    (
      nextState[
        entity_type
      ] as EntityRequirementsState['requirementAssociationsByEntity']['entityRequirementType']
    )[entity_id] = association;
  });

  state.requirementAssociationsByEntity = nextState;
};

export default createReducer(initialState, (builder) => {
  builder.addCase(
    fetchEntityRequirementsActionCreatorsMap.success,
    handleFetchEntityRequirementsSuccess
  );
  builder.addCase(
    updateEntityRequirementsActionCreatorsMap.success,
    handleUpdateEntityRequirementsSuccess
  );
});
