const BudgetSettingsViewTypes = {
  settings: 'settings',
  rates: 'rates'
} as const;

const moduleTextHash = {
  1: 'SETTINGS',
  2: 'RATES'
};

const moduleHash = {
  1: BudgetSettingsViewTypes.settings,
  2: BudgetSettingsViewTypes.rates
} as const;

const moduleIds = [1, 2];

export const budgetSettingsModuleConstants = {
  BudgetSettingsViewTypes,
  moduleTextHash,
  moduleHash,
  moduleIds
};
