import { callIntegrationApi } from 'appUtils/api';

export function fetchCsvImportStatus(
  _: unknown,
  token: string,
  targetServiceId: number,
  body = {}
) {
  const fetchInit = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true'
    },
    body: JSON.stringify(body)
  };

  return callIntegrationApi(
    `mappings/getCsvImportStatus/${targetServiceId}`,
    token,
    fetchInit
  );
}
