import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import sum from 'lodash/sum';
import keyBy from 'lodash/keyBy';
import {
  StyledWorkloadEventTable,
  ProjectFooterLabel,
  ProjectFooterTotal,
  Header,
  ProjectPercentageHeader,
  ProjectRowHeader,
  ProjectHeader
} from './styles';
import Table from 'components/Table';
import { getWorkloadEventScheduleBars } from 'selectors';
import sumBy from 'lodash/sumBy';
import { getAccountCapacities } from 'CapacityModule/selectors';
import { calcWeekCapacity } from 'appUtils/workplanDisplayUtils';
import round from 'lodash/round';

import {
  MemberCell,
  BulkCell,
  BulkHeaderCell,
  ProjectCell,
  ProjectDaysCell,
  ProjectPercentageCell,
  PercentageCell,
  ProjectEmptyCell,
  PercentageHeader,
  MemberHeader
} from './Cells';

const memberColumn = {
  headerType: 'member',
  headerLabel: 'MEMBER',
  accessor: (row) => row.member,
  id: 'member',
  align: 'right'
};
const daysColumn = {
  headerType: 'days',
  headerLabel: '',
  accessor: (row) => row,
  id: 'days',
  align: 'center'
};
const percentageColumn = {
  headerType: 'percentage',
  headerLabel: '',
  id: 'percentage',
  align: 'center',
  accessor: (row) => row
};
const bulkColumn = {
  headerType: 'bulk',
  headerLabel: '',
  id: 'bulk',
  align: 'center',
  accessor: (row) => row
};

const columnHeaders = [memberColumn, daysColumn, percentageColumn, bulkColumn];
const EmptyDiv = () => <div />;
const emptyRow = { rowType: 'emptyRow' };
const noop = () => {};
const emptyObj = {};
const MAX_TABLE_HEIGHT_BUFFER = 520;
const ITEM_HEIGHT = 50;
const PROJECT_ITEM_HEIGHT_W_DESCRIPTION = 85;
const PROJECT_ITEM_HEIGHT_W_ACTIVITY = 70;

const MemberHeaderSelectionDisabled = () => (
  <Header style={{ fontWeight: 600 }}>Member</Header>
);

const MemberSubHeader = ({ row }) => (
  <ProjectHeader height={row?.original?.itemHeight}>PROJECT</ProjectHeader>
);
const DaysSubHeader = ({ row }) => (
  <ProjectRowHeader height={row?.original?.itemHeight}>DAYS</ProjectRowHeader>
);
const PercentageSubHeader = ({ row }) => (
  <ProjectPercentageHeader height={row?.original?.itemHeight}>
    %/WEEK
  </ProjectPercentageHeader>
);
const MemberProjectFooter = ({ row }) => (
  <ProjectFooterLabel height={row?.original?.itemHeight}>
    Total % of Week
  </ProjectFooterLabel>
);
const PercentageProjectFooter = ({ row }) => (
  <ProjectFooterTotal height={row?.original?.itemHeight}>
    {row?.original?.percentCommitted || 0}%
  </ProjectFooterTotal>
);
const headerComponents = {
  member: MemberHeader,
  days: EmptyDiv,
  percentage: PercentageHeader,
  bulk: BulkHeaderCell
};
const memberRowCells = {
  member: MemberCell,
  days: EmptyDiv,
  percentage: PercentageCell,
  bulk: BulkCell
};
const subHeaderRowCells = {
  member: MemberSubHeader,
  days: DaysSubHeader,
  percentage: PercentageSubHeader,
  bulk: ProjectEmptyCell
};
const projectRowCells = {
  member: ProjectCell,
  days: ProjectDaysCell,
  percentage: ProjectPercentageCell,
  bulk: ProjectEmptyCell
};
const projectTotalRowCells = {
  member: MemberProjectFooter,
  days: ProjectEmptyCell,
  percentage: PercentageProjectFooter,
  bulk: ProjectEmptyCell
};
const columnWidths = {
  member: 280,
  days: 110,
  percentage: 54,
  bulk: 40
};
const totalColumnsWidth = sum(Object.values(columnWidths));

const buildHeader = ({ list, customRowProps }) => {
  const { id, headerType, isOpen, headerHeight, renderHeader, headerData } =
    list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    renderHeader,
    customRowProps
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: list.rowHeight || item.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      listItems.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
      if (!isFullyLoaded) {
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }
  // Commented out for removing bottom padding
  // if (lists.every(list => list.isFullyLoaded || !list.isOpen)) {
  //   flatList.push(
  //     buildCustomItem({
  //       list: lists[lists.length - 1] || { getItemId: item => item?.id },
  //       customRowProps,
  //       item: emptyRow
  //     })
  //   );
  // }
  return flatList;
};

const WorkloadEventTable = ({
  selectedEvent,
  orderedTeamMembers,
  viewBy = 'members',
  selectionDisabled
}) => {
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState({});
  const [allOpen, setAllOpen] = useState(false);
  const isLoading = false;
  const recipientIdHash = useMemo(
    () => keyBy(selectedEvent.recipient_account_ids || []),
    [selectedEvent.recipient_account_ids]
  );

  const scheduleBars = useSelector(getWorkloadEventScheduleBars);
  const accountCapacities = useSelector(getAccountCapacities);
  const columns = useMemo(() => {
    const headers = { ...headerComponents };
    if (selectionDisabled) {
      headers.percentage = null;
      headers.bulk = null;
      headers.member = MemberHeaderSelectionDisabled;
    }
    return columnHeaders.map((columnHeader) => ({
      ...columnHeader,
      memberRow: memberRowCells[columnHeader.headerType] || EmptyDiv,
      projectRow: projectRowCells[columnHeader.headerType] || EmptyDiv,
      projectTotalRow:
        projectTotalRowCells[columnHeader.headerType] || EmptyDiv,
      subHeaderRow: subHeaderRowCells[columnHeader.headerType] || EmptyDiv,

      Header: headers[columnHeader.headerType] || EmptyDiv,
      Footer: EmptyDiv,
      emptyRow: EmptyDiv,
      width: columnWidths[columnHeader.headerType]
    }));
  }, [selectionDisabled]);

  const memberGroupedRowBuilder = useCallback(
    ({ formattedTimesheets, members, customRowProps }) => {
      const memberOrder = members.map((member) => member.account.id);
      const memberLists = members.reduce((acc, cur, index) => {
        const accountCapacityTotal = calcWeekCapacity(
          accountCapacities[cur.account.id]
        );

        acc[cur.account.id] = {
          listItems: [],
          closedItems: [],
          customItems: [
            {
              id: cur.id,
              rowHeight: 30,
              rowType: 'subHeaderRow',
              customItems: [
                { id: cur.id, rowHeight: 30, rowType: 'subHeaderRow' }
              ]
            }
          ],
          headerData: {
            account: cur.account,
            // ...customHeaderData,
            isSelected: !!recipientIdHash[cur.account.id],
            accountCapacityTotal
          },
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'projectRow',
          headerType: 'memberRow',
          headerHeight: 52,
          id: cur.account.id,
          skipEmptyGroups: false,
          isOpen:
            isOpen[cur.account.id] === undefined
              ? allOpen
              : isOpen[cur.account.id],
          isEmpty: true,
          isFullyLoaded: null,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          renderSummaryItem: (item) => item,
          skipHeader: false
        };
        return acc;
      }, {});

      formattedTimesheets.forEach((timesheet, index) => {
        const memberList = memberLists[timesheet.account_id];
        if (memberList) {
          memberList.listItems.push({
            ...timesheet,
            rowHeight: timesheet.description?.length
              ? PROJECT_ITEM_HEIGHT_W_DESCRIPTION
              : timesheet.activity_title?.length &&
                !timesheet.is_default_activity
              ? PROJECT_ITEM_HEIGHT_W_ACTIVITY
              : ITEM_HEIGHT,
            percentage: round(
              (timesheet.committment * 100) /
                memberList.headerData.accountCapacityTotal || 40
            )
          });
          memberList.isEmpty = false;
        }
      });

      const listsWithItems = [];
      const listsWithoutItems = [];

      memberOrder.forEach((id) => {
        const list = memberLists[id];

        if (list) {
          const timesheetsInList = list.listItems;
          const timesheetEntryCount = timesheetsInList.length;
          const totalCommitment = sumBy(
            timesheetsInList,
            (scheduleBar) => scheduleBar.committment
          );
          const percentCommitted = round(
            (totalCommitment * 100) / list.headerData.accountCapacityTotal
          );
          list.headerData = {
            ...list.headerData,
            totalCommitment,
            percentCommitted,
            eventId: selectedEvent.scheduled_event_id
          };
          // Insert the total footer row
          const projectTotalRow = {
            rowType: 'projectTotalRow',
            rowHeight: 30,
            percentCommitted
          };
          list.listItems.push(projectTotalRow);
          list.isFullyLoaded = true;

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      const lastList = listsWithItems[listsWithItems.length - 1];
      if (lastList) {
        lastList.headerData.className = 'last-full-row';
      }
      const listsInOrder = [...listsWithItems, ...listsWithoutItems];
      // if (listsInOrder.length) {
      //   const [firstList] = listsInOrder;
      //   firstList.skipHeader = true;
      //   firstList.customItems = [];
      // }
      return listsInOrder;
    },
    [
      accountCapacities,
      recipientIdHash,
      isOpen,
      allOpen,
      selectedEvent.scheduled_event_id
    ]
  );
  const listBuilders = useMemo(
    () => ({
      members: memberGroupedRowBuilder
    }),
    [memberGroupedRowBuilder]
  );
  const handleSetIsOpen = useCallback(
    ({ name, value }) => {
      setIsOpen({ ...isOpen, [name]: value });
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
      resetOnClose({ name, value });
    },
    [isOpen, resetOnClose]
  );

  const handleResetHeightCache = useCallback(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  const customRowProps = useMemo(
    () => ({
      handleSetIsOpen,
      isOpen,
      selectionDisabled
    }),
    [handleSetIsOpen, isOpen, selectionDisabled]
  );
  const lists = useMemo(() => {
    const listBuilder = listBuilders[viewBy];
    if (!listBuilder) {
      return [];
    }
    return listBuilder({
      formattedTimesheets: scheduleBars,
      members: orderedTeamMembers,
      customRowProps
    });
  }, [customRowProps, listBuilders, orderedTeamMembers, scheduleBars, viewBy]);

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps,
      engaged: {},
      search: {},
      emptyRow
    });
  }, [customRowProps, lists]);

  useEffect(() => {
    handleResetHeightCache();
  }, [handleResetHeightCache, rows]);

  const getItemSize = useCallback(
    (index) => rows[index]?.itemHeight || ITEM_HEIGHT,
    [rows]
  );

  var resetOnClose = useCallback(
    ({ value, name }) => {
      if (!value) {
        const index = rows.findIndex((row) => row.listType === name);
        if (index !== -1) {
          listRef.current.scrollToItem(index - 1);
        }
      }
    },
    [rows]
  );

  return (
    <div>
      <StyledWorkloadEventTable className="workload-email-table">
        <Table
          columns={columns}
          data={!isLoading ? rows : []}
          numberOfAdditionalRowsForThreshold={0}
          onDragEnd={noop}
          virtual
          getItemSize={getItemSize}
          maxHeight={window.innerHeight - MAX_TABLE_HEIGHT_BUFFER}
          loadMoreItems={noop}
          handleScroll={noop}
          itemHeight={ITEM_HEIGHT}
          isVariableSizeTable
          customRowProps={emptyObj}
          listRef={listRef}
          showHeader
        />

        {isLoading && (
          <div style={{ width: totalColumnsWidth }}>
            <ContentLoader
              height="100"
              primaryColor="#ddd"
              secondaryColor="#eee"
              style={{ width: totalColumnsWidth }}
            >
              <rect
                x="0"
                y="5"
                rx="2"
                ry="2"
                width={totalColumnsWidth}
                height="15"
              />
              <rect
                x="0"
                y="25"
                rx="2"
                ry="2"
                width={totalColumnsWidth}
                height="15"
              />
              <rect
                x="0"
                y="45"
                rx="2"
                ry="2"
                width={totalColumnsWidth}
                height="15"
              />
              <rect
                x="0"
                y="66"
                rx="2"
                ry="2"
                width={totalColumnsWidth}
                height="15"
              />
            </ContentLoader>
          </div>
        )}
      </StyledWorkloadEventTable>
    </div>
  );
};

export default WorkloadEventTable;
