import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StatusItemContainer, AddMembersContainer } from '..';
import ProgressPill from 'components/ProgressPill';
import {
  fetchProjectById,
  openAddMembersForm,
  openProjectModal,
  setSelectedProject,
  editProject,
  selectProjects,
  unselectProject
} from 'actionCreators';
import {
  BlankColumn,
  Box,
  NoteCountContainer,
  ClientName,
  ReferenceNumber,
  MembersContainer,
  MemberCountContainer,
  ProjectStatusContainer,
  EmptyStatus,
  RemainingBar,
  RemainingBarContainer,
  OverdueTasksContainer,
  EmptyStateContainer,
  EmptyProgressPill
} from './styles';

import { withRouter } from 'react-router-dom';
import {
  getAddMembersForm,
  getSelectedAccountIds,
  getMaxRemainingTasksCount,
  getSelectedProjectIds
} from 'selectors';
import ProjectStage from './ProjectStage';
import ProjectStatus from './ProjectStatus';
import ProjectPriority from './ProjectPriority';
import columnHeaders from './ProjectColumnHeaders';
import Popover from 'components/Popover';
import {
  BatchActionToggleWrapper,
  BatchActionToggleIcon
} from 'components/BatchActions/styles';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
// const portal = document.createElement('div');
// document.body.append(portal); // may be necessary for rendering correct item if sticky scrolled
export function stopEvent(e) {
  return e && event.stopPropagation();
}

const formatCountText = (number, word) => {
  const formattedWord = number === 1 ? word : `${word}s`;
  return `${number} ${formattedWord}`;
};

class ProjectColumns extends React.Component {
  projectPriorityRef = React.createRef();
  projectStageRef = React.createRef();
  projectStatusRef = React.createRef();
  state = {
    isOpenAddMembersModal: false
  };

  addMembersInstance = React.createRef();
  handleUpdateProject = (key) => (value) => {
    const { project, editProject } = this.props;
    const { archived_at, id } = project;
    // const permissions = this.getPermissions();
    const payload = {
      isActive: !archived_at,
      id,
      [key]: value
      // permissions
    };

    editProject(payload);
  };

  openProjectComments = () => {
    const { fetchProjectById, setSelectedProject, openProjectModal, project } =
      this.props;
    fetchProjectById(project.id);
    setSelectedProject({ projectId: project.id });
    openProjectModal({
      projectId: project.id
    });
  };

  makeCounter = (type, count, showSeparator) => {
    const isNote = type === 'Note';
    const classType = isNote ? 'note' : 'task';
    let formattedText;
    if (type === 'Note' || type === 'Task') {
      formattedText = formatCountText(count, type);
    } else {
      formattedText = `${count} ${type}`;
    }
    return (
      <NoteCountContainer>
        <div className={`${classType}-count-text`}>{formattedText}</div>
        {showSeparator && (
          <i className={'count-separator'} onClick={this.props.handleExpand} />
        )}
      </NoteCountContainer>
    );
  };

  renderColumn = (column) => {
    return <td key={column}>{this[`render_${column}`]()}</td>;
  };

  render_tasks = () => {
    const { project, handleExpand } = this.props;
    const { completed_task_count, incomplete_task_count } = project;
    const totalTaskCount = completed_task_count + incomplete_task_count;
    return (
      <Box
        onClick={handleExpand}
        title="Click to view tasks"
        className="app-cues-team-task"
      >
        {incomplete_task_count > 0 ? (
          <ProgressPill
            totalNumber={totalTaskCount}
            indicatorNumber={incomplete_task_count}
            partialNumber={completed_task_count}
          />
        ) : (
          <EmptyStateContainer>
            <EmptyProgressPill />
          </EmptyStateContainer>
        )}
      </Box>
    );
  };

  buildRemainingTasksDataTip = ({
    incomplete_task_count,
    overdue_task_count
  }) => `<div>${incomplete_task_count} tasks incomplete</div>
  ${overdue_task_count > 0 ? `<div>${overdue_task_count} overdue</div>` : ''}
  `;

  render_remaining_tasks = () => {
    const { project, handleExpand, maxRemainingTasksCount } = this.props;
    const { incomplete_task_count, overdue_task_count } = project;

    const dataTip = this.buildRemainingTasksDataTip(project);

    return (
      <Box onClick={handleExpand} className="app-cues-incomplete-team-task">
        {incomplete_task_count > 0 ? (
          <RemainingBarContainer
            data-for={'app-tooltip'}
            data-tip={dataTip}
            data-html
          >
            <RemainingBar
              barWidth={(incomplete_task_count * 100) / maxRemainingTasksCount}
            >
              {incomplete_task_count}
            </RemainingBar>
          </RemainingBarContainer>
        ) : (
          <EmptyStateContainer>
            {columnHeaders.remaining_tasks}
          </EmptyStateContainer>
        )}
        {overdue_task_count > 0 ? (
          <OverdueTasksContainer>
            {overdue_task_count} overdue
          </OverdueTasksContainer>
        ) : null}
      </Box>
    );
  };

  render_notes = () => {
    const { project, handleExpand } = this.props;
    return (
      <Box onClick={handleExpand} className="app-cues-team-notes">
        {project.note_count ? (
          this.makeCounter(
            'Note',
            project.note_count,
            project.incomplete_task_count > 0 ||
              project.completed_task_count > 0 ||
              project.overdue_task_count > 0
          )
        ) : (
          <EmptyStateContainer>{columnHeaders.notes}</EmptyStateContainer>
        )}
      </Box>
    );
  };

  render_client = () => {
    const { project, editProjectOnClick } = this.props;
    return (
      <Box onClick={editProjectOnClick} className="app-cues-team-client">
        {project.client ? (
          <ClientName>{project.client}</ClientName>
        ) : (
          <EmptyStateContainer>{columnHeaders.client}</EmptyStateContainer>
        )}
      </Box>
    );
  };

  render_number = () => {
    const { project, editProjectOnClick } = this.props;
    return (
      <Box onClick={editProjectOnClick} className="app-cues-team-projectnumber">
        {project.project_number ? (
          <ReferenceNumber>{project.project_number}</ReferenceNumber>
        ) : (
          <EmptyStateContainer>{columnHeaders.number}</EmptyStateContainer>
        )}
      </Box>
    );
  };

  render_status = () => {
    const { project } = this.props;
    return (
      <Box className="app-cues-team-project-status" ref={this.projectStatusRef}>
        <ProjectStatus
          projectId={project.id}
          onSubmit={this.handleUpdateProject('status_id')}
          statusId={project.status_id}
          outerPopoverTarget={this.projectStatusRef}
        />
      </Box>
    );
  };

  render_stage = () => {
    const { project } = this.props;
    return (
      <Box className="app-cues-team-project-stage" ref={this.projectStageRef}>
        <ProjectStage
          projectId={project.id}
          onSubmit={this.handleUpdateProject('stage_id')}
          stageId={project.stage_id}
          outerPopoverTarget={this.projectStageRef}
        />
      </Box>
    );
  };

  renderSelect = () => {
    const { selectedProjectIds, project, selectProjects, unselectProject } =
      this.props;
    const isSelected = selectedProjectIds[project.id] !== undefined;
    const handleChange = isSelected ? unselectProject : selectProjects;
    return (
      <td>
        <Box>
          <BatchActionToggleWrapper onClick={() => handleChange([project])}>
            <BatchActionToggleIcon isBatchSelected={isSelected} />
          </BatchActionToggleWrapper>
        </Box>
      </td>
    );
  };

  render_priority = () => {
    const { project } = this.props;
    return (
      <Box
        className="app-cues-team-project-priority"
        ref={this.projectPriorityRef}
      >
        <ProjectPriority
          projectId={project.id}
          onSubmit={this.handleUpdateProject('priority_id')}
          priorityId={project.priority_id}
          outerPopoverTarget={this.projectPriorityRef}
        />
      </Box>
    );
  };

  openAddMembersModal = () => {
    const { project, openAddMembersForm } = this.props;
    this.setState({
      isOpenAddMembersModal: true
    });
    openAddMembersForm(MODAL_TYPE.PROJECT, project);
  };

  closeMembersModal = () => {
    this.setState({
      isOpenAddMembersModal: false
    });
  };

  togglePopover = () => {
    this.setState({
      isOpenAddMembersModal: !this.state.isOpenAddMembersModal
    });
  };

  render_members = () => {
    const { project, openAddMembersForm } = this.props;

    const projectMembership = project.project_membership
      ? project.project_membership.filter((membership) => !!membership.account)
      : [];
    let orderedTeam = [];

    const memberInitialsOnMultiRows = projectMembership.length > 7;
    if (memberInitialsOnMultiRows) {
      orderedTeam = orderedTeam.concat(
        projectMembership.slice(0, 6),
        projectMembership.slice(-1),
        projectMembership.slice(6, -1)
      );
    } else {
      orderedTeam = projectMembership;
    }

    return (
      <Box
        className="app-cues-team-members-count"
        ref={this.addMembersInstance}
        onClick={(e) => {
          e.stopPropagation();
          this.openAddMembersModal();
        }}
      >
        <MembersContainer
          className={`project-${project.id}-row add-members-button`}
        >
          {orderedTeam.length > 0 ? (
            <MemberCountContainer>
              {orderedTeam.length} Member{orderedTeam.length > 1 ? 's' : ''}
            </MemberCountContainer>
          ) : (
            <EmptyStateContainer>{columnHeaders.members}</EmptyStateContainer>
          )}
          <AddMembersContainer
            isOpenMembersModal={this.state.isOpenAddMembersModal}
            closeMembersModal={this.closeMembersModal}
            target={this.addMembersInstance}
            modalType={MODAL_TYPE.PROJECT}
          />
        </MembersContainer>
      </Box>
    );
  };

  render_updates = () => {
    const { me, project, isFiltering } = this.props;

    return (
      <ProjectStatusContainer
        isFiltered={isFiltering}
        onClick={this.openProjectComments}
        title="Click to view all project updates"
      >
        {project.status && project.status.length ? (
          project.status.slice(0, 1).map((status) => (
            <div key={status.id} title="Click to view all project updates">
              <StatusItemContainer
                status={status}
                statusCount={project.status.length}
                projectId={project.id}
                me={me}
                canEdit={false}
              />
            </div>
          ))
        ) : (
          <EmptyStatus title="Click to add a status">
            Add status update
          </EmptyStatus>
        )}
      </ProjectStatusContainer>
    );
  };

  render() {
    const { columnOrder, shouldShowBatchOptions } = this.props;

    return (
      <>
        {columnOrder.map(this.renderColumn)}
        {shouldShowBatchOptions && this.renderSelect()}
        <BlankColumn />
      </>
    );
  }
}

ProjectColumns.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object
};

const mapStateToProps = (state) => ({
  addMembersForm: getAddMembersForm(state),
  selectedAccountIds: getSelectedAccountIds(state),
  maxRemainingTasksCount: getMaxRemainingTasksCount(state),
  selectedProjectIds: getSelectedProjectIds(state)
});

const mapDispatchToProps = {
  fetchProjectById,
  openAddMembersForm,
  openProjectModal,
  setSelectedProject,
  editProject,
  selectProjects,
  unselectProject
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectColumns)
);
