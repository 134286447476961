import styled, { css } from 'styled-components';
import theme from 'theme';
import { LegendBox } from '../../styles';

const emptyObj = {};

export const LegendText = styled.div`
  color: ${theme.colors.colorLightGray10};
  font-size: 12px;
  ${(props) => props.uppercase && 'text-transform: uppercase;'}
  display: flex;
  align-items: center;
`;

export const UtilLegendBox = styled(LegendBox)`
  height: 14px;
  width: 16px;
`;

export const Toggle = styled.div`
  border-radius: 50%;
  background: ${theme.colors.colorLightGray19};
  height: 9px;
  width: 9px;
  margin: 0 1px;
`;

export const ToggleContainer = styled.div`
  margin-left: 5px;
  width: 23px;
  height: 13px;
  border: 1px solid ${theme.colors.colorLightGray15};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
  cursor: pointer;
  background-color: ${({ isOn }) =>
    isOn ? theme.colors.colorQbTooltipGreen : theme.colors.colorLightGray15};
`;

export const ProjectToggleContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;

  /* relative position is different from report pages. ex) utilization report left: -30px, workload report right: -50px */
  ${({ relativePosition }) => relativePosition || ''}
`;
