import { Modal } from 'reactstrap';
import styled from 'styled-components';
import theme from 'theme';
import { TextButtonWithBorder } from 'components/styles';

export const LabelText = styled.div`
  color: ${theme.colors.colorSemiDarkGray2} !important;
  font-size: 15px;
  font-weight: 600;
`;

export const DoneButton = styled(TextButtonWithBorder)`
  color: white;
  background-color: ${theme.colors.colorRoyalBlue};
  border-color: ${theme.colors.colorRoyalBlue};
  padding: 0.2rem 0.6rem;
  position: absolute;
  line-height: 1.4rem;
  right: 0;
`;

export const StyledModal = styled(Modal)`
  color: ${theme.colors.colorMediumGray7};
  width: fit-content;
  .modal-content {
    width: 382px;
    padding: 34px;
    border-radius: 3px !important;
  }
`;
export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: black;
  font-weight: 600;
  margin-bottom: 16px;
`;
export const Body = styled.div`
  color: inherit;
  font-size: 14px;
`;
