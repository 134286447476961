import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeamId, getMyUserId } from 'selectors';
import { useCheckPermission } from 'hocs/withPermissionsCheck';

const emptyObj = {};

/**
 * returns true if user has permission to perform the action. Permission params optional depending on the permission. See permissionActions.js and permissions.md
 * accountId => should be provided when makeAuthorBasedVerification is used
 * isReady => for preventing unnecessary permission check dispatches
 */
const useCan = (
  actionCreator,
  { projectId, boardId, accountId, isReady = true } = emptyObj
) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const myId = useSelector(getMyUserId);

  const checkPermission = useCheckPermission();
  const isAllowed = useMemo(() => {
    if (teamId && isReady) {
      return dispatch(
        checkPermission(actionCreator, {
          permissions: {
            teamId,
            projectId,
            boardId,
            mine: myId == accountId
          }
        })
      );
    }
  }, [
    accountId,
    actionCreator,
    boardId,
    checkPermission,
    dispatch,
    isReady,
    myId,
    projectId,
    teamId
  ]);

  return isAllowed || false;
};

export default useCan;
