/* eslint-disable no-case-declarations */
import uniqBy from 'lodash/uniqBy';
import reject from 'lodash/reject';
import remove from 'lodash/remove';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import * as constants from 'appConstants';
import * as noteConstants from 'NoteModule/constants';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

let closeEditModal = {
  isEditModalOpen: false,
  editingProjectID: null,
  statusText: '',
  refreshMemberList: false,
  editModalProjectIsPersonal: false,
  addEditModalUseSelectedBoardOverride: false
};

const byId = (item) => item && item.id;

const replaceProject = (projectsArray, responseProject) => {
  return projectsArray.map((project) =>
    project.id === responseProject.id ? responseProject : project
  );
};
const sortByPosition = (projects) =>
  sortBy(projects || [], (project) => project.position);

const filterOrSwitchBoards = (
  projects,
  serverData,
  isActive,
  newFilter,
  newSearch,
  newSort
) => {
  // Used to determine whether to add to currentProjects (infinite scroll)
  // or to replace with newly fetched projects

  const onSameBoard = projects.every((proj) => {
    return proj.board_id === parseInt(serverData.requestPayload.params.groupId);
  });
  const viewChange = serverData.requestPayload.params.isActive !== isActive;

  if (serverData.requestPayload) {
    const nonScheduleCheck =
      !projects.length ||
      newSearch ||
      newFilter ||
      !onSameBoard ||
      viewChange ||
      newSort;

    return nonScheduleCheck;
  } else {
    return !projects.length;
  }
};

function projectsWithMembershipRemoved(projects, removedAccountId) {
  return projects.map((project) => {
    return {
      ...project,
      project_membership: project.project_membership.filter((pm) => {
        return pm !== removedAccountId && pm.account?.id !== removedAccountId;
      })
    };
  });
}

function removeMemberFromProjects(projects, projectId, removedAccountId) {
  return projects.map((project) => {
    if (project.id === projectId) {
      return {
        ...project,
        project_membership: project.project_membership.filter((pm) => {
          return pm !== removedAccountId && pm.account?.id !== removedAccountId;
        })
      };
    } else {
      return project;
    }
  });
}

export const filterListInitialState = {
  filterProjectsFetching: false,
  filterProjectsOffset: 0,
  projectOrder: [], // for lists without grouping
  projectsByBoard: {},
  projectGroupCounts: {},
  projectMemberCounts: {},
  projectCount: null
};

export const initialState = {
  addModalProjectIsPersonal: false,
  editModalProjectIsPersonal: false,
  addEditModalUseSelectedBoardOverride: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isOpenDeleteProjectModal: false,
  isActive: true,
  statusText: '',
  isFetchingProjects: false,
  editingProjectID: null,
  selectedProject: [],
  roles: [
    {
      id: 1,
      name: 'Project Manager'
    },
    {
      id: 3,
      name: 'Can Edit'
    },
    { id: 8, name: 'View Only' }
  ],
  allProjects: [],
  cachedProjects: [],
  cachedTotalProjects: 0,
  totalProjects: 0,
  maxRemainingTasksCount: 0,
  filteringByMembers: false,
  selectedAccountIds: [],
  refreshMemberList: false,
  disableSave: false,
  statusList: [],
  newFilter: false,
  newSort: false,
  newSearch: false,
  isLazyLoading: false,
  projectItemState: {
    id: null,
    fixed: false,
    expanded: false,
    contracting: false,
    detailVisible: false
  },
  isCreatingProject: false,
  projectHash: {},
  task_groups: {},
  task_group_order: [],
  offset: 0,
  clients: [],
  selectedProjectIds: {},
  cloningProjectBatches: {},
  fetchedProjectIds: {},
  filterLists: {},
  totalProjectCountsByAccountId: {},
  isUpdatingProjectInfo: false
};

const projects = (state = initialState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.LOCATION_CHANGE:
      return {
        ...state,
        isAddModalOpen: false,
        isEditModalOpen: false,
        isOpenDeleteProjectModal: false,
        isLazyLoading: false
      };
    case constants.LAZY_LOADING_PROJECT_LIST:
      return {
        ...state,
        isLazyLoading: true
      };
    case constants.HANDLE_PROJECT_ITEM_STATE: {
      const newProjectItemState = { ...state.projectItemState };

      newProjectItemState.id = action.payload.id;

      if (typeof action.payload.target !== 'string') {
        action.payload.target.forEach((target, i) => {
          newProjectItemState[action.payload.target[i]] =
            action.payload.bool[i];
        });
      } else {
        newProjectItemState[action.payload.target] = action.payload.bool;
      }
      return {
        ...state,
        projectItemState: newProjectItemState
      };
    }

    case noteConstants.FETCH_NOTES_BY_PROJECT.SUCCESS:
      return {
        ...state,
        projectItemState: {
          ...state.projectItemState,
          detailVisible: true
        }
      };
    case constants.FETCH_PROJECTS.REQUEST:
      return {
        ...state,
        isFetchingProjects: true,
        statusText: null
      };

    case constants.SWITCH_BOARDS:
      return {
        ...state,
        isFetchingProjects: true,
        selectedProjectIds: {}
      };

    // Ensure changing name refreshes projects to reflect proper initials
    case constants.PROFILE_UPDATE.SUCCESS:
    // Ensure clearing search clear and reloads projects
    // eslint-disable-next-line no-fallthrough
    case constants.CLEAR_SEARCH:
      if (action.payload.reloadProjects) {
        return {
          ...state,
          allProjects: [],
          maxRemainingTasksCount: 0
        };
      } else {
        return state;
      }
    case constants.CLEAR_ALL_PROJECTS:
      return {
        ...state,
        allProjects: [],
        maxRemainingTasksCount: 0
      };

    case constants.FILTER_BY_MEMBERS:
      return {
        ...state,
        filteringByMembers: action.isFiltering,
        selectedAccountIds: action.accountIds,
        allProjects: !action.isFiltering ? [] : state.allProjects,
        newFilter: true
      };
    case constants.ON_SEARCH:
      return {
        ...state,
        newSearch: true
      };
    case constants.TRIGGER_MEMBER_LIST_REFRESH:
      return {
        ...state,
        refreshMemberList: action.payload
      };
    case constants.FETCH_PROJECTS.SUCCESS:
      if (
        filterOrSwitchBoards(
          state.allProjects,
          action.payload,
          state.isActive,
          state.newFilter,
          state.newSearch,
          state.newSort
        )
      ) {
        return {
          ...state,
          allProjects: sortByPosition(action.payload.response.projects),
          isFetchingProjects: false,
          statusText: null,
          totalProjects: action.payload.response.total_count,
          isActive: action.payload.requestPayload.params.isActive,
          newFilter: false,
          newSort: false,
          newSearch: false,
          isLazyLoading: false,
          filteringByMembers: false,
          maxRemainingTasksCount:
            action.payload.response.max_remaining_tasks_count
        };
        // Triggered from infinite scroll
      } else {
        return {
          ...state,
          isFetchingProjects: false,
          statusText: null,
          allProjects: sortBy(
            uniqBy(
              [...action.payload.response.projects, ...state.allProjects],
              (project) => project.id
            ),
            (project) => project.position
          ),
          totalProjects: action.payload.response.total_count,
          newFilter: false,
          newSearch: false,
          isLazyLoading: false,
          filteringByMembers: false
        };
      }
    case constants.HARD_DELETE_PROJECT.TRIGGER:
    case constants.DELETE_PROJECT.TRIGGER:
      return {
        ...state,
        allProjects: reject(
          state.allProjects,
          (project) => project.id === action.payload.projectId
        )
      };

    case constants.DELETE_PROJECT_FROM_ORDER: {
      const { projectId } = action.payload;
      const project = state.projectHash[projectId];

      const filterLists = state.filterLists;
      const nextFilterLists = Object.keys(filterLists).reduce((acc, cur) => {
        const nextFilterState = {
          ...filterLists[cur],
          projectsByBoard: {
            ...filterLists[cur].projectsByBoard
          },
          projectOrder: [...filterLists[cur].projectOrder].filter(
            (id) => id !== action.payload.projectId
          )
        };
        // update projectCount if project was deleted from projectOrder
        if (
          (nextFilterState.projectOrder.length =
            filterLists[cur].projectOrder.length - 1)
        ) {
          nextFilterState.projectCount -= 1;
        }
        project.board_ids.forEach((boardId) => {
          nextFilterState.projectsByBoard[boardId] = (
            nextFilterState.projectsByBoard[boardId] || []
          ).filter((id) => id !== projectId);
        });
        acc[cur] = nextFilterState;
        return acc;
      }, {});
      return {
        ...state,
        filterLists: nextFilterLists
      };
    }

    case constants.FETCH_PROJECTS.FAILURE:
      return {
        ...state,
        isFetchingProjects: false,
        statusText: 'Error'
      };
    case constants.PROJECT_UPDATE_POSITION.TRIGGER:
      let toList, withinPriority, updated;

      const { id, isActive, wasActive } = action.payload;
      const position = action.payload.position || 0;

      // Remove the project in question from the fromList
      const fromList = [...state.allProjects];
      const project = remove(fromList, (project) => project.id == id)[0];

      // Project is not in allProjects list eg. when drag dropping
      // from projects sidebar
      if (!project) return state;

      const updatedProject = {
        ...project,
        project_priority: isActive ? 0 : 1 // TODO: remove when Projects component refactored
      };

      // If we're moving the project within the same priority
      // set the toList to be the fromList
      if (isActive === wasActive) {
        toList = fromList;
        withinPriority = true;
        // Insert the project in question into the to list at position `position`
        toList = [
          ...toList.slice(0, position),
          updatedProject,
          ...toList.slice(position, toList.length)
        ];
      } else {
        withinPriority = false;
      }

      // Ensure that the `position` attributes on each
      // project are updated so that in subsequent fetches
      // the order remains intact, since we sort by the position
      // attribute but don't refresh projects from the back-end
      // on position change currently
      toList = map(toList, (proj, index) => ({ ...proj, position: index }));
      // fromList = map(fromList, (proj, index) => ({...proj, position: index }));
      updated = withinPriority ? toList : fromList;

      return {
        ...state,
        allProjects: updated
      };

    case constants.PROJECT_UPDATE_POSITION.SUCCESS:
      const { projectOrderIndex, newProjectOrderIndex, filterListId, boardId } =
        action.payload.requestPayload;

      if (
        filterListId &&
        boardId &&
        projectOrderIndex !== undefined &&
        newProjectOrderIndex !== undefined
      ) {
        const nextProjectOrder = [
          ...state.filterLists[filterListId].projectsByBoard[boardId]
        ];
        const projectId = nextProjectOrder[projectOrderIndex];
        nextProjectOrder.splice(projectOrderIndex, 1);
        nextProjectOrder.splice(newProjectOrderIndex, 0, projectId);
        return {
          ...state,
          filterLists: {
            ...state.filterLists,
            [filterListId]: {
              ...state.filterLists[filterListId],
              projectsByBoard: {
                ...state.filterLists[filterListId].projectsByBoard,
                [boardId]: nextProjectOrder
              }
            }
          }
        };
      }
      return state;

    case constants.CLEAR_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: []
      };
    case constants.PROJECT.REQUEST:
      return {
        ...state,
        isFetchingProjects: true,
        isLazyLoading: false,
        statusTextGroup: null
      };
    case constants.PROJECT.SUCCESS:
      let data = action.payload.response.projects;

      newState.isFetchingProjects = false;
      newState.statusTextProjects = null;
      newState.selectedProject = data;

      if (!data.length) {
        return newState;
      }

      // Update this project in our priorityGrouping as well
      data = data[0];
      // Find index of project in current priorityGrouping
      var indexAllProjects = findIndex(
        state.allProjects,
        (p) => p.id === data.id
      );

      if (indexAllProjects === -1) {
        return newState;
      }

      // Update the state
      return newState;

    case constants.PROJECT.FAILURE:
      return {
        ...state,
        isFetchingProjects: false,
        statusTextProjects: 'Error'
      };
    case constants.OPEN_ADD_PROJECT_MODAL:
      return {
        ...state,
        addingPriorityID: action.payload.priorityId,
        isAddModalOpen: true,
        addModalProjectIsPersonal: !!action.payload.isPersonal,
        addEditModalUseSelectedBoardOverride:
          action.payload.useSelectedBoardOverride || false,
        addModalProjectRedirectOnSuccess: action.payload.redirectOnSuccess,
        addModalProjectSuccessCallback: action.payload.onSuccessCallback
      };
    case constants.CLOSE_ADD_PROJECT_MODAL:
      return {
        ...state,
        addingPriorityID: null,
        isAddModalOpen: false,
        statusText: null,
        addModalProjectIsPersonal: false
      };
    case constants.OPEN_EDIT_PROJECT_MODAL:
      return {
        ...state,
        isEditModalOpen: true,
        editingProjectID: action.payload.id,
        editModalProjectIsPersonal: !!action.payload.isPersonal,
        addEditModalUseSelectedBoardOverride:
          action.payload.useSelectedBoardOverride || false
      };
    case constants.CLOSE_EDIT_PROJECT_MODAL:
      return {
        ...state,
        ...closeEditModal
      };
    case constants.CLOSE_EDIT_PROJECT_MODAL_MAINTAIN_EDIT:
      closeEditModal = { isEditModalOpen: false, statusText: '' };

      return {
        ...state,
        ...closeEditModal
      };
    case constants.CLOSE_INVITE_TEAM_MEMBER_MODAL:
      return {
        ...state,
        editingProjectID: null
      };
    case constants.PROJECT_CREATION.REQUEST:
      return {
        ...state,
        statusText: null,
        isCreatingProject: true
      };
    case constants.PROJECT_CREATION.SUCCESS:
      return {
        ...state,
        isAddModalOpen: false,
        addModalProjectIsPersonal: false,
        statusText: null,
        selectedAccountIds: [],
        isCreatingProject: false
      };
    case constants.PROJECT_CREATION.FAILURE:
      const error = action.payload.error === 'Conflict' ? 409 : 'Error';
      return {
        ...state,
        statusText: error,
        isCreatingProject: false
      };
    case constants.PROJECT_EDITION.TRIGGER:
      // if moving project to different board:
      newState = state;
      if (
        action.payload.projectGroupId !== action.payload.projectGroupIdToRefresh
      ) {
        // remove project directly from store first
        let newList;
        if (
          (action.payload.isActive && state.isActive) ||
          (!action.payload.isActive && !state.isActive)
        ) {
          newList = state.allProjects.filter((project) => {
            return project.id !== action.payload.id;
          });
          newState = {
            ...newState,
            allProjects: newList
          };
        }
        if (action.payload.id) {
          const id = action.payload.id;
          const client = action.payload.client;
          const billable = action.payload.billable;
          const title = action.payload.title;
          const description = action.payload.description;
          const project_number = action.payload.number;
          const is_archived = !action.payload.isActive;
          // Custom fields not updated here.
          newState = {
            ...newState,
            projectHash: {
              ...state.projectHash,
              [id]: {
                ...state.projectHash[id],
                billable,
                client,
                title,
                description,
                project_number,
                is_archived
              }
            }
          };
        }
      }
      return newState;
    case constants.PROJECT_EDITION.REQUEST:
      return {
        ...state,
        statusText: null,
        isUpdatingProjectInfo: true
      };
    case constants.ADD_MEMBER_TO_PROJECT.REQUEST:
    case constants.DELETE_MEMBER_FROM_PROJECT.REQUEST:
    case constants.UPDATE_PROJECT_MEMBER_ROLE.REQUEST:
      return {
        ...state,
        refreshMemberList: true
      };
    case constants.DELETE_MEMBER_FROM_PROJECT.TRIGGER:
      return {
        ...state,
        allProjects: removeMemberFromProjects(
          state.allProjects,
          action.payload.projectId,
          action.payload.accountId
        ),
        selectedProject: removeMemberFromProjects(
          state.selectedProject,
          action.payload.projectId,
          action.payload.accountId
        )
      };
    case constants.WS_PROJECT: {
      const { userId, origin, added, deleted, ...responseProject } =
        action.payload;
      if (userId === origin) {
        return state;
      }
      if (state.isActive) {
        let newProjectsList;
        let total = state.totalProjects;
        if (added) {
          newProjectsList = [
            responseProject,
            ...state.allProjects.map((project) => ({
              ...project,
              position: project.position + 1
            }))
          ];
          total++;
        } else if (deleted) {
          newProjectsList = state.allProjects.filter(
            (project) => project.id !== responseProject.id
          );
          total--;
        } else {
          newProjectsList = state.allProjects.map((project) =>
            project.id === responseProject.id ? responseProject : project
          );
        }
        return {
          ...state,
          allProjects: newProjectsList,
          totalProjects: total
        };
      }
      return state;
    }
    case constants.MOVE_PROJECTS_TO_BOARD.TRIGGER: {
      const { projectIds } = action.payload;
      const newProjects = state.allProjects.filter(
        (project) => projectIds[project.id] === undefined
      );
      return {
        ...state,
        allProjects: newProjects,
        totalProjects: newProjects.length,
        selectedProjectIds: {}
      };
    }
    case constants.WS_PROJECT_MEMBER: {
      const { payload } = action;
      const projectDidUpdate =
        state.selectedProject.length &&
        payload.id === state.selectedProject[0].id;
      return {
        ...state,
        allProjects: state.allProjects.map((project) =>
          project.id === payload.id ? payload : project
        ),
        selectedProject: projectDidUpdate ? [payload] : state.selectedProject
      };
    }
    case constants.ADD_MEMBER_TO_PROJECT.SUCCESS:
    case constants.DELETE_MEMBER_FROM_PROJECT.SUCCESS:
    case constants.UPDATE_PROJECT_MEMBER_ROLE.SUCCESS:
      let projectUpdated = action.payload.response;
      if (action.payload.response.project) {
        projectUpdated = action.payload.response.project[0];
      }
      let updatedProjectList;
      const projectDidUpdate =
        state.selectedProject.length &&
        projectUpdated.id === state.selectedProject[0].id;

      updatedProjectList = state.allProjects.map((project, idx) => {
        if (project.id === projectUpdated.id) {
          projectUpdated.position = projectUpdated.position
            ? projectUpdated.position
            : idx;
          return projectUpdated;
        } else {
          return project;
        }
      });

      return {
        ...state,
        allProjects: updatedProjectList,
        selectedProject: projectDidUpdate
          ? [projectUpdated]
          : state.selectedProject
      };

    case constants.ADD_BULK_MEMBERS_TO_PROJECT.SUCCESS: {
      let projectUpdated = action.payload.response;
      if (action.payload.response.project) {
        projectUpdated = action.payload.response.project[0];
      }
      let updatedProjectList;
      const projectDidUpdate =
        state.selectedProject.length &&
        projectUpdated.id === state.selectedProject[0].id;

      updatedProjectList = state.allProjects.map((project, idx) => {
        if (project.id === projectUpdated.id) {
          projectUpdated.position = projectUpdated.position
            ? projectUpdated.position
            : idx;
          return projectUpdated;
        } else {
          return project;
        }
      });
      return {
        ...state,
        allProjects: updatedProjectList,
        selectedProject: projectDidUpdate
          ? [projectUpdated]
          : state.selectedProject
      };
    }
    case constants.PROJECT_EDITION.SUCCESS: {
      let updatedProjectList;
      const projectUpdated = action.payload.response.project[0];
      const projectDidUpdate =
        state.selectedProject &&
        state.selectedProject.length &&
        projectUpdated.id === state.selectedProject[0].id;
      const boardIdToRefresh =
        action.payload.requestPayload.projectGroupIdToRefresh;
      const updatedProjectHasNewBoard =
        boardIdToRefresh && boardIdToRefresh !== projectUpdated.board_id;

      if (updatedProjectHasNewBoard) {
        updatedProjectList = reject(
          state.allProjects,
          (project) => project.id === projectUpdated.id
        );
      } else {
        updatedProjectList = state.allProjects.map((project, idx) => {
          if (project.id === projectUpdated.id) {
            projectUpdated.position = projectUpdated.position
              ? projectUpdated.position
              : idx;
            return projectUpdated;
          } else {
            return project;
          }
        });
      }
      // the perils of duplicated state
      const projectHashProject = state.projectHash[projectUpdated.id] || {};
      const projectKeys = Object.keys(projectHashProject);
      const newHashProject = projectKeys.reduce((hash, key) => {
        hash[key] =
          projectUpdated[key] !== undefined
            ? projectUpdated[key]
            : projectHashProject[key];
        return hash;
      }, {});
      const mergeHashProject = newHashProject.id
        ? { [newHashProject.id]: newHashProject }
        : {};
      return {
        ...state,
        allProjects: updatedProjectList,
        projectHash: {
          ...state.projectHash,
          ...mergeHashProject
        },
        selectedProject: projectDidUpdate
          ? [projectUpdated]
          : updatedProjectHasNewBoard
          ? []
          : state.selectedProject,
        isUpdatingProjectInfo: false
      };
    }
    case constants.PROJECT_EDITION.FAILURE:
      return {
        ...state,
        statusText: 'Error',
        isUpdatingProjectInfo: false
      };

    case constants.TOGGLE_DELETE_PROJECT_MODAL:
      return {
        ...state,
        isOpenDeleteProjectModal: action.payload.modalState
      };

    case constants.DELETE_MEMBER_FROM_GROUP.SUCCESS:
      const removedAccountId = action.payload.response.account.id;
      return {
        ...state,
        allProjects: projectsWithMembershipRemoved(
          state.allProjects,
          removedAccountId
        )
      };

    case constants.ARCHIVE_UNARCHIVE_PROJECT.TRIGGER: {
      const projectPosition = action.payload.params.position;
      const secondHalfAdjusted = [];
      const currentProjectList = state.allProjects;

      const firstHalf = currentProjectList.slice(0, projectPosition);
      const secondHalf = currentProjectList.slice(projectPosition + 1);

      secondHalf.forEach((proj) => {
        secondHalfAdjusted.push({
          ...proj,
          position: proj.position - 1
        });
      });

      const newProjectList = [...firstHalf, ...secondHalfAdjusted];

      const newSelectedProject = state.selectedProject.map((project) =>
        project.id == action.payload.params.projectId
          ? {
              ...project,
              archived_at: project.archived_at ? null : moment()
            }
          : project
      );

      return {
        ...state,
        allProjects: newProjectList,
        totalProjects: state.totalProjects - 1,
        selectedProject: newSelectedProject
      };
    }
    case constants.ARCHIVE_UNARCHIVE_PROJECT.SUCCESS: {
      const updatedProject = action.payload.response.project[0];
      return {
        ...state,
        projectHash: {
          ...state.projectHash,
          [updatedProject.id]: {
            ...state.projectHash[updatedProject.id],
            is_archived: !!updatedProject.archived_at
          }
        }
      };
    }

    case constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.TRIGGER: {
      const newProjectList = [];
      const projects = action.payload.params.projects;
      const projectIds = Object.keys(projects);
      const currentProjectList = [...state.allProjects];
      const filteredProjectList = currentProjectList.filter(
        (project) => projectIds.indexOf(String(project.id)) === -1
      );
      filteredProjectList.forEach((project, index) => {
        newProjectList.push({ ...project, position: index });
      });
      return {
        ...state,
        allProjects: newProjectList,
        totalProjects: state.totalProjects - projectIds.length
      };
    }

    case constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.SUCCESS: {
      return {
        ...state
      };
    }

    case constants.STAR_PROJECT.SUCCESS: {
      const project = action.payload.response.project[0];
      return {
        ...state,
        allProjects: replaceProject(state.allProjects, project),
        projectHash: {
          ...state.projectHash,
          [project.id]: {
            ...state.projectHash[project.id],
            is_starred: project.is_starred
          }
        }
      };
    }

    case constants.UPDATE_NOTIFICATION_PREFERENCES.TRIGGER: {
      const {
        accountId,
        projectId,
        allActivity,
        projectComments,
        tasks,
        notes
      } = action.payload;

      const newSelectedProject =
        state.selectedProject.length > 0
          ? { ...state.selectedProject[0] }
          : null;

      const newProjectList = state.allProjects.map((project) => {
        if (project.id === projectId) {
          const newProjectMemberships = project.project_membership.map(
            (membership) => {
              if (membership.account?.id === accountId) {
                return {
                  ...membership,
                  notify_comments: allActivity || projectComments,
                  notify_notes: allActivity || notes,
                  notify_tasks: allActivity || tasks
                };
              } else {
                return membership;
              }
            }
          );
          if (newSelectedProject && newSelectedProject.id === projectId) {
            newSelectedProject.project_membership = newProjectMemberships;
          }
          return {
            ...project,
            project_membership: newProjectMemberships
          };
        } else {
          return project;
        }
      });

      return {
        ...state,
        allProjects: newProjectList,
        selectedProject: newSelectedProject ? [newSelectedProject] : []
      };
    }

    case constants.POPULATE_CACHED_PROJECTS:
      return {
        ...state,
        cachedProjects: state.allProjects,
        cachedTotalProjects: state.totalProjects
      };

    case constants.FETCH_CACHED_PROJECTS:
      return {
        ...state,
        isFetchingProjects: false,
        allProjects: state.cachedProjects,
        totalProjects: state.cachedTotalProjects,
        newFilter: false,
        newSearch: false
      };

    case constants.CLEAR_CACHED_PROJECTS:
      return {
        ...state,
        cachedProjects: [],
        totalCachedProjects: 0
      };

    case constants.CREATE_PROJECT_COMMENT.TRIGGER: {
      const { account, description } = action.payload;
      const projectId = action.payload.projectId;
      const newSelectedProject =
        state.selectedProject.length > 0
          ? { ...state.selectedProject[0] }
          : null;

      const dummyStatus = {
        account: {
          id: account.id,
          initials: account.initials,
          name: account.name
        },
        description: description,
        id: 0
      };

      if (newSelectedProject && newSelectedProject.id === projectId) {
        const newStatuses = [dummyStatus, ...newSelectedProject.status];
        newSelectedProject.status = newStatuses;
      }

      const oldProjects = state.allProjects;
      const projectsToChange = oldProjects.map((project) => {
        if (project.id === projectId) {
          const newStatuses = [dummyStatus, ...project.status];
          return {
            ...project,
            status: newStatuses
          };
        }
        return project;
      });

      return {
        ...state,
        selectedProject: newSelectedProject ? [newSelectedProject] : [],
        allProjects: projectsToChange
      };
    }
    case constants.EDIT_PROJECT_COMMENT.SUCCESS:
    case constants.CREATE_PROJECT_COMMENT.SUCCESS: {
      const updatedStatus = action.payload.response;
      const projectId = action.payload.response.projectId; // This is broken, projectId was undeclared previously
      const newSelectedProject = {
        ...(state.selectedProject.length > 0 ? state.selectedProject[0] : null)
      };

      if (newSelectedProject && newSelectedProject.id === projectId) {
        const newStatuses = newSelectedProject.status.map((status) => {
          if (status.id === 0) {
            return updatedStatus;
          } else {
            return status;
          }
        });
        newSelectedProject.status = newStatuses;
      }

      const oldProjects = state.allProjects;
      const projectsToChange = oldProjects.map((project) => {
        if (project.id === updatedStatus.project_id) {
          const newStatuses = project.status.map((status) => {
            if (status.id === 0) {
              return updatedStatus;
            } else {
              return status;
            }
          });
          return {
            ...project,
            status: newStatuses
          };
        }
        return project;
      });

      return {
        ...state,
        selectedProject: newSelectedProject ? [newSelectedProject] : [],
        allProjects: projectsToChange
      };
    }
    case constants.EDIT_PROJECT_COMMENT.TRIGGER: {
      const projectId = action.payload.projectId;
      const description = action.payload.description;
      const statusId = action.payload.statusId;
      const oldProjects = state.allProjects;
      const newSelectedProject = {
        ...(state.selectedProject.length ? state.selectedProject[0] : null)
      };

      if (newSelectedProject && newSelectedProject.id === projectId) {
        const newStatuses = newSelectedProject.status.map((status) => {
          if (status.id === statusId) {
            return {
              ...status,
              description: description
            };
          }
          return status;
        });
        newSelectedProject.status = newStatuses;
      }

      const projectsToChange = oldProjects.map((project) => {
        if (project.id === projectId) {
          const newStatuses = project.status.map((status) => {
            if (status.id === statusId) {
              return {
                ...status,
                description: description
              };
            }
            return status;
          });
          return {
            ...project,
            status: newStatuses
          };
        }
        return project;
      });

      return {
        ...state,
        selectedProject: newSelectedProject ? [newSelectedProject] : [],
        allProjects: projectsToChange
      };
    }
    case constants.DELETE_PROJECT_COMMENT.TRIGGER: {
      const statusId = action.payload.statusId;
      const projectId = action.payload.projectId;
      const oldProjects = state.allProjects;
      const newSelectedProject = {
        ...(state.selectedProject.length ? state.selectedProject[0] : null)
      };

      if (newSelectedProject && newSelectedProject.id === projectId) {
        const newStatuses = reject(
          newSelectedProject.status,
          (status) => status.id === statusId
        );
        newSelectedProject.status = newStatuses;
      }

      const projectsToChange = oldProjects.map((project) => {
        if (project.id === projectId) {
          const newStatusList = reject(
            project.status,
            (status) => status.id === statusId
          );
          return {
            ...project,
            status: newStatusList
          };
        }
        return project;
      });

      return {
        ...state,
        selectedProject: newSelectedProject ? [newSelectedProject] : [],
        allProjects: projectsToChange
      };
    }
    case constants.SET_SELECTED_PROJECT: {
      const { project, projectId } = action;
      const selectedProject = projectId
        ? find(state.allProjects, (project) => {
            return project.id === projectId;
          })
        : project;

      return {
        ...state,
        selectedProject: [selectedProject]
      };
    }

    case constants.TASK_UPDATE_POSITION: {
      const { isCompleted, wasCompleted, isOverdue } = action.payload.params;

      const targetProjectIndex = state.allProjects.findIndex(
        (project) => project.id === action.payload.params.projectId
      );
      const newTargetProject = { ...state.allProjects[targetProjectIndex] };
      const newAllProjects = [...state.allProjects];

      if (!wasCompleted && isCompleted) {
        newTargetProject.completed_task_count++;
        newTargetProject.incomplete_task_count--;
        if (isOverdue) {
          newTargetProject.overdue_task_count--;
        }
      } else if (wasCompleted && !isCompleted) {
        newTargetProject.completed_task_count--;
        newTargetProject.incomplete_task_count++;
        if (isOverdue) {
          newTargetProject.overdue_task_count++;
        }
      }

      newAllProjects[targetProjectIndex] = newTargetProject;
      return {
        ...state,
        allProjects: newAllProjects
      };
    }
    case constants.UPDATE_PROJECT_MODULES.TRIGGER: {
      if (!state.selectedProject[0]) {
        return state;
      }
      return {
        ...state,
        selectedProject: [
          {
            ...state.selectedProject[0],
            task_columns:
              action.payload.taskColumns ||
              state.selectedProject[0].task_columns,
            app_modules:
              action.payload.appModules || state.selectedProject[0].app_modules,
            task_status_order:
              action.payload.taskStatusOrder ||
              state.selectedProject[0].task_status_order,
            phase_orders:
              action.payload.phaseOrder ||
              state.selectedProject[0].phase_orders,
            task_priority_order:
              action.payload.taskPriorityOrder ||
              state.selectedProject[0].task_priority_order
          }
        ]
      };
    }
    case constants.UPDATE_PROJECT_MODULES.SUCCESS: {
      if (!state.selectedProject[0]) {
        return state;
      }
      return {
        ...state,
        selectedProject: action.payload.response.project
      };
    }
    case constants.UPDATE_TASK_STATUS.SUCCESS: {
      const { response } = action.payload;
      const selectedProject = [...state.selectedProject];
      return {
        ...state,
        selectedProject: selectedProject.map((project) => ({
          ...project,
          task_statuses: project.task_statuses.map((taskStatus) =>
            taskStatus.id === response.id ? response : taskStatus
          )
        }))
      };
    }
    case constants.CREATE_TASK_STATUS.SUCCESS: {
      const { response } = action.payload;
      if (response && response.error) return state;

      const selectedProject = [...state.selectedProject];
      return {
        ...state,
        selectedProject: selectedProject.map((project) => ({
          ...project,
          task_statuses:
            project.id === response.project_id
              ? [...project.task_statuses, response]
              : project.task_statuses,
          task_status_order:
            project.id === response.project_id
              ? [...project.task_status_order, response.id]
              : project.task_statuses
        }))
      };
    }
    case constants.DELETE_TASK_STATUS.TRIGGER: {
      const { payload } = action;
      const { project_id, id } = payload;
      const selectedProject = [...state.selectedProject];
      return {
        ...state,
        selectedProject: selectedProject.map((project) => ({
          ...project,
          task_statuses:
            project.id === project_id
              ? project.task_statuses.filter((status) => status.id !== id)
              : project.task_statuses,
          task_status_order:
            project.id === project_id
              ? project.task_status_order.filter((status) => status !== id)
              : project.task_status_order
        }))
      };
    }
    case constants.UPDATE_TASK_PRIORITY.SUCCESS: {
      const { response } = action.payload;
      const selectedProject = [...state.selectedProject];
      return {
        ...state,
        selectedProject: selectedProject.map((project) => ({
          ...project,
          task_priorities: project.task_priorities.map((taskPriority) =>
            taskPriority.id === response.id ? response : taskPriority
          )
        }))
      };
    }
    case constants.CREATE_TASK_PRIORITY.SUCCESS: {
      const { response } = action.payload;
      if (response && response.error) return state;

      const selectedProject = [...state.selectedProject];
      return {
        ...state,
        selectedProject: selectedProject.map((project) => ({
          ...project,
          task_priorities:
            project.id === response.project_id
              ? [...project.task_priorities, response]
              : project.task_priorities,
          task_priority_order:
            project.id === response.project_id
              ? [...project.task_priority_order, response.id]
              : project.task_priority_order
        }))
      };
    }
    case constants.DELETE_TASK_PRIORITY.TRIGGER: {
      const { payload } = action;
      const { project_id, id } = payload;
      const selectedProject = [...state.selectedProject];
      return {
        ...state,
        selectedProject: selectedProject.map((project) => ({
          ...project,
          task_priorities:
            project.id === project_id
              ? project.task_priorities.filter((priority) => priority.id !== id)
              : project.task_priorities,
          task_priority_order:
            project.id === project_id
              ? project.task_priority_order.filter(
                  (priority) => priority !== id
                )
              : project.task_priority_order
        }))
      };
    }
    case constants.CREATE_PHASE.SUCCESS: {
      if (!state.selectedProject[0]) {
        return state; // ignore on team view
      }
      const selectedProject = {
        ...state.selectedProject[0],
        phase_orders: [
          action.payload.response.id,
          ...state.selectedProject[0].phase_orders
        ]
      };

      return {
        ...state,
        selectedProject: [selectedProject]
      };
    }
    case constants.FETCH_ALL_PROJECTS.TRIGGER: {
      const { params, filterListId, initial } = action.payload;
      const { offset, limit, projectIds = [] } = params;
      if (filterListId) {
        const currentFilterList = state.filterLists[filterListId];
        return {
          ...state,
          filterLists: {
            ...state.filterLists,
            [filterListId]: {
              ...(initial ? filterListInitialState : currentFilterList),
              filterProjectsFetching: true
            }
          }
        };
      }
      return {
        ...state,
        isFetchingProjects: true,
        offset: (offset !== undefined ? offset : state.offset) + (limit || 0),
        fetchedProjectIds: {
          ...state.fetchedProjectIds,
          ...keyBy(projectIds)
        }
      };
    }
    case constants.FETCH_MEMBER_PROJECTS.TRIGGER: {
      return {
        ...state,
        isFetchingProjects: true
      };
    }
    case constants.FETCH_ALL_PROJECTS.SUCCESS:
    case constants.FETCH_MEMBER_PROJECTS.SUCCESS:
    case constants.FETCH_PROJECTS_LIST_VIEW.SUCCESS: {
      const { projects = [], project_count = 0 } = action.payload.response;
      const { params, filterListId, initial } = action.payload.requestPayload;
      const newProjectsArray = projects.filter((project) => !!project); // not sure why this is necessary, copied from older code
      const newProjects = keyBy(newProjectsArray, byId);

      if (filterListId) {
        const nextFilterListState = {
          ...(initial
            ? filterListInitialState
            : state.filterLists[filterListId]),
          filterProjectsFetching: false,
          projectCount: project_count
        };
        nextFilterListState.filterProjectsOffset =
          nextFilterListState.filterProjectsOffset + params.limit;
        nextFilterListState.projectOrder = [
          ...nextFilterListState.projectOrder,
          ...newProjectsArray.map(byId)
        ];

        return {
          ...state,
          projectHash: { ...state.projectHash, ...newProjects },
          filterLists: {
            ...state.filterLists,
            [filterListId]: nextFilterListState
          }
        };
      }

      let nextProjectCountsByAccount = state.totalProjectCountsByAccountId;
      if (params?.account_id) {
        nextProjectCountsByAccount = {
          ...state.totalProjectCountsByAccountId,
          [params.account_id]: project_count
        };
      }

      return {
        ...state,
        isFetchingProjects: false,
        projectHash: { ...state.projectHash, ...newProjects },
        totalProjectCountsByAccountId: nextProjectCountsByAccount
      };
    }
    case constants.FETCH_PROJECT_CLIENTS.SUCCESS: {
      return {
        ...state,
        clients: action.payload.response
      };
    }
    case constants.UPDATE_PROJECT_BILLABLE.TRIGGER: {
      const { id, billable } = action.payload;
      return {
        ...state,
        projectHash: {
          ...state.projectHash,
          [id]: {
            ...state.projectHash[id],
            billable
          }
        }
      };
    }
    case constants.SET_BOARD_SORT_PROPERTY: {
      return {
        ...state,
        newSort: true
      };
    }
    case constants.SELECT_PROJECTS: {
      const { selectedProjectIds } = state;
      const newProjects = keyBy(action.payload, (project) => project.id);
      return {
        ...state,
        selectedProjectIds: { ...selectedProjectIds, ...newProjects }
      };
    }
    case constants.UNSELECT_PROJECT: {
      const { selectedProjectIds } = state;
      const unselectedProject = action.payload[0];
      const newSelectedProjectIds = omit(
        selectedProjectIds,
        unselectedProject.id
      );
      return {
        ...state,
        selectedProjectIds: newSelectedProjectIds
      };
    }
    case constants.UNSELECT_ALL_PROJECTS: {
      return {
        ...state,
        selectedProjectIds: {}
      };
    }
    case constants.CLONE_PROJECTS.TRIGGER: {
      const { projectIds, targetBoard, batchId } = action.payload;
      const { cloningProjectBatches } = state;

      // setup batch object
      const projectIdList = Object.keys(projectIds);
      const newBatch = projectIdList.reduce((acc, id) => {
        acc[id] = {
          loading: true,
          targetBoard
        };
        return acc;
      }, {});

      // add batch to current batches object
      const newCloningBatches = { ...cloningProjectBatches };
      newCloningBatches[batchId] = newBatch;

      return {
        ...state,
        selectedProjectIds: {},
        cloningProjectBatches: newCloningBatches
      };
    }
    case constants.CLONE_PROJECT.SUCCESS: {
      const { response, sagaPayload } = action.payload;
      const { batchId, projectId } = sagaPayload;
      const { cloningProjectBatches } = state;

      // batch and project to be updated
      const batch = cloningProjectBatches[batchId];
      const project = batch[projectId];

      // create new project with updates
      const newProject = {
        ...project,
        loading: false,
        cloneId: response.project_id
      };

      // create new batch with updated project
      const newBatch = omit(batch, projectId);
      newBatch[projectId] = newProject;

      // copy batches hash and replace updated batch
      const newCloningProjectBatches = omit(cloningProjectBatches, batchId);
      newCloningProjectBatches[batchId] = newBatch;

      return {
        ...state,
        cloningProjectBatches: newCloningProjectBatches
      };
    }
    case constants.CLONE_PROJECT.FAILURE: {
      const { error, sagaPayload } = action.payload;
      const { batchId, projectId } = sagaPayload;
      const { cloningProjectBatches } = state;

      // batch and project to be updated
      const batch = cloningProjectBatches[batchId];
      const project = batch[projectId];

      // create new project with updates
      const newProject = {
        ...project,
        loading: false,
        error
      };

      // create new batch with updated project
      const newBatch = omit(batch, projectId);
      newBatch[projectId] = newProject;

      // copy batches hash and replace updated batch
      const newCloningProjectBatches = omit(cloningProjectBatches, batchId);
      newCloningProjectBatches[batchId] = newBatch;

      return {
        ...state,
        cloningProjectBatches: newCloningProjectBatches
      };
    }
    case constants.RESET_PROJECT_FILTER_LIST: {
      const filterListId = action.payload.filterListId;
      return {
        ...state,
        filterLists: {
          ...state.filterLists,
          [filterListId]: { ...filterListInitialState }
        }
      };
    }
    case constants.FETCH_PROJECTS_INDEX_V2.TRIGGER: {
      const { offset, limit, initial, search_text } = action.payload.body;
      const { filterListId } = action.payload;
      const currentFilterList =
        state.filterLists[filterListId] || filterListInitialState;

      return {
        ...state,
        filterLists: {
          ...state.filterLists,
          [filterListId]: {
            ...currentFilterList,
            filterProjectsOffset:
              (offset !== undefined
                ? offset
                : currentFilterList.filterProjectsOffset) + (limit || 0),
            projectOrder: initial ? [] : currentFilterList.projectOrder,
            filterProjectsFetching: true,
            projectsByBoard: initial ? {} : currentFilterList.projectsByBoard,
            // Checking search_text here due to the need for having
            // separate fetch (useEffect in VirtualFilter) for all board project counts
            // when there is no search_text (ie. don't want those counts to be overwritten
            // if fetches are out of order)
            projectCount:
              initial && search_text ? null : currentFilterList.projectCount,
            projectGroupCounts:
              initial && search_text
                ? {}
                : currentFilterList.projectGroupCounts,
            memberGroupCounts:
              initial && search_text ? {} : currentFilterList.memberGroupCounts
          }
        }
      };
    }
    case constants.FETCH_PROJECTS_INDEX_V2.SUCCESS: {
      const { project_count, projects, sort_attributes_counts } =
        action.payload.response;
      const {
        body,
        filterListId,
        boardId: fetchingBoardId,
        boardIndexHash = {}
      } = action.payload.requestPayload;
      const projectIds = projects.map(byId);
      const currentFilterList = state.filterLists[filterListId];

      const projectsByBoardHash = { ...currentFilterList.projectsByBoard };

      // For checking if projects already exist in a board's project order
      const boardProjects = Object.keys(projectsByBoardHash).reduce(
        (acc, cur) => {
          acc[cur] = new Set(projectsByBoardHash[cur]);
          return acc;
        },
        {}
      );
      const projectsToAddByBoard = {};

      projects.forEach((project) => {
        const sortedBoardIds = project.board_ids
          ? Array.from(new Set([...project.board_ids, project.board_id])).sort(
              (a, b) => (boardIndexHash[a] - boardIndexHash[b] > 0 ? 1 : -1)
            )
          : [project.board_id];
        for (const boardId of sortedBoardIds) {
          // Since projects can be on multiple boards, make sure they are being added to
          // boards that are = or beneath the fetching board
          if (
            !fetchingBoardId || // when searching, no boardId in action.payload
            boardIndexHash[boardId] >= boardIndexHash[fetchingBoardId]
          ) {
            if (!boardProjects[boardId]?.has(project.id)) {
              if (!projectsToAddByBoard[boardId])
                projectsToAddByBoard[boardId] = [];
              projectsToAddByBoard[boardId].push(project.id);
              if (!boardProjects[boardId]) boardProjects[boardId] = new Set();
              boardProjects[boardId].add(project.id);
              break;
            }
          }
        }
      });
      Object.entries(projectsToAddByBoard).forEach(
        ([boardId, projectsToAdd]) => {
          projectsByBoardHash[boardId] = [
            ...(projectsByBoardHash[boardId] || []),
            ...projectsToAdd
          ];
        }
      );

      // For knowing if the fetch returned 0 projects
      // for any board_ids
      const fetchedBoardIds = new Set();
      sort_attributes_counts?.forEach((item) => {
        if (item.sort_attribute === 'board_id') {
          fetchedBoardIds.add(item.sort_attribute_value);
        }
      });
      // const boardsWithoutProjects =
      //   body.board_ids?.reduce((acc, boardId) => {
      //     if (!fetchedBoardIds.has(boardId)) {
      //       acc[boardId] = {
      //         total: 0,
      //         numArchived: 0
      //       };
      //     }
      //     return acc;
      //   }, {}) || {};
      const boardsWithoutProjects = {};
      if (!fetchedBoardIds.has(fetchingBoardId)) {
        boardsWithoutProjects[fetchingBoardId] = {
          total: 0,
          numArchived: 0
        };
      }

      return {
        ...state,
        fetchedProjectIds: {
          ...state.fetchedProjectIds,
          ...keyBy(projectIds)
        },
        projectHash: {
          ...state.projectHash,
          ...keyBy(projects, byId)
        },
        filterLists: {
          ...state.filterLists,
          [filterListId]: {
            ...currentFilterList,
            projectOrder: body.initial
              ? projectIds
              : [...currentFilterList.projectOrder, ...projectIds],
            filterProjectsFetching: false,
            projectsByBoard: projectsByBoardHash,
            projectGroupCounts: {
              ...(!body.initial && { ...currentFilterList.projectGroupCounts }),
              // ...boardsWithoutProjects,
              default: {
                total: 0,
                numArchived: 0
              },
              ...sort_attributes_counts?.reduce((acc, cur) => {
                acc[cur.sort_attribute_value] = {
                  total: cur.count,
                  numArchived:
                    (
                      cur.sort_attribute_counts?.find(
                        (attribute) =>
                          attribute.sort_attribute === 'is_archived' &&
                          attribute.sort_attribute_value === true
                      ) ||
                      cur.sort_attribute_counts
                        ?.find(
                          (attribute) =>
                            attribute.sort_attribute === 'filter_selection'
                        )
                        ?.sort_attribute_counts?.find(
                          (attribute) =>
                            attribute.sort_attribute === 'is_archived' &&
                            attribute.sort_attribute_value === true
                        )
                    )?.count || 0
                };
                return acc;
              }, {})
            },
            projectCount: body.initial
              ? project_count
              : currentFilterList.projectCount
          }
        }
      };
    }
    default:
      return state;
  }
};

export default projects;
