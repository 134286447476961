import fetchManager from 'classes/FetchManager';

// patches window.fetch
export const initFetchInterceptor = () => {
  const { fetch: originalFetch } = window;
  window.fetch = async (url, config = {}) => {
    /* ----------------------- request interceptor starts ----------------------- */

    /* ---------------- add support for aborting ---------------- */

    // see the note in FetchManagerV2's startRequest method
    const { controller, requestId } = fetchManager.getNextAbortController();

    // add signal to the fetch config so we can abort it
    if (controller) {
      if (fetchManager.debugMode) {
        console.log(
          'Adding AbortController signal (fetchInterceptor)',
          requestId,
          url
        );
      }
      config.signal = controller.signal;
    }

    /* ------------------------------------ - ----------------------------------- */

    const response = await originalFetch(url, config);

    return response;
  };
};
