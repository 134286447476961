import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { closePhaseModal } from 'actionCreators';
import CloseIcon from 'icons/CloseIcon';
import {
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  Left,
  Right,
  ModalHeaderWrapper,
  ModalHeaderLeft,
  ModalHeaderRight
} from '../styles';

class RemoveModal extends React.Component {
  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  handleConfirm = () => {
    const { handleConfirm } = this.props;
    this.handleClose();
    handleConfirm();
  };
  render() {
    const { isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.handleClose} className="phase-modal">
        <ModalHeader>
          <ModalHeaderWrapper>
            <ModalHeaderLeft>Remove Member From Budget</ModalHeaderLeft>
            <ModalHeaderRight>
              <CloseIcon />
            </ModalHeaderRight>
          </ModalHeaderWrapper>
        </ModalHeader>
        <ModalBody>
          Are you sure you want to remove the project member from the project
          budget? The members spent hours will remain, but budgeted hours will
          be removed and the total budgeted amount will be adjusted.
          <ModalButtonsRow>
            <Left />
            <Right>
              <CancelButton onClick={this.handleClose}>Cancel</CancelButton>
              <ConfirmButton onClick={this.handleConfirm}>Yes</ConfirmButton>
            </Right>
          </ModalButtonsRow>
        </ModalBody>
      </Modal>
    );
  }
}

RemoveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  closePhaseModal
};

export default connect(null, mapDispatchToProps)(RemoveModal);
