import { createSelector } from '@reduxjs/toolkit';
import {
  getNavigationHistory,
  getNavigationState,
  isOnTeamSettingsView
} from 'appCore/navigation/selectors';
import { NavigationHistory } from 'appCore/navigation/types';
import { Views } from 'appConstants/navigation';
import { permissionsModuleConstants } from './constants';

const { moduleIds } = permissionsModuleConstants;

const isOnPermissionsSettingsView = (
  navigationHistory: Array<NavigationHistory>,
  index = 0
) => {
  const navigationState = getNavigationState(navigationHistory, index);
  const isOnTeamSettings = isOnTeamSettingsView(navigationHistory, index);
  return isOnTeamSettings && navigationState?.view === Views.PERMISSIONS;
};

export const getIsOnPermissionsSettingsView = createSelector(
  getNavigationHistory,
  isOnPermissionsSettingsView
);

export const getInstalledPermissionsSettingsModuleIds = createSelector(() => {
  const installedModuleIds = moduleIds;
  return installedModuleIds;
});
