import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { Project } from 'ProjectsModule/models/project';
import {
  ProjectWithSuggestionFlag,
  AISuggestedPhaseForAccount,
  SuggestedPhaseForAccount
} from 'SuggestionModule/types';
import {
  isPtoProject,
  splitListIntoTwo,
  makeNonSuggestedItemsInOrder
} from '.';
import { defaultNumOfSuggestedPhaseForAccountsToShow } from 'SuggestionModule/constants';

/* ---------------------- Phase Suggestion For Accounts --------------------- */

interface ExtendedPhase extends Phase {
  is_phase?: boolean;
}

type PtoProjectAndPhases =
  | Project
  | ProjectWithSuggestionFlag
  | ExtendedPhase
  | SuggestedPhaseForAccount;

type SuggestedProjectsAndPhases =
  | ProjectWithSuggestionFlag
  | (ExtendedPhase | SuggestedPhaseForAccount);

type ProjectAndPhases = Project | ExtendedPhase;

type FormatPreviousProjectAndPhasesAndAddToCorrectListArgs = {
  currentProject: Project;
  suggestedPhasesOfCurrentProject: SuggestedPhaseForAccount[];
  nonSuggestedPhasesOfCurrentProject: ExtendedPhase[];
  suggestedPhasesHash: Record<number, AISuggestedPhaseForAccount>;
  numOfSuggestedPhaseToShow: number;
  /* -------------------------------------------------------------------------- */
  ptoProjectAndPhases: PtoProjectAndPhases[];
  suggestedProjectsAndPhases: SuggestedProjectsAndPhases[];
  nonSuggestionProjectAndPhases: ProjectAndPhases[];
};

type MakeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccountsArgs = {
  projectsAndPhases: ProjectAndPhases[]; // List of projects and phases. (i.e: [Pto Project + PTO project phases, Project1 + project1 phases...])
  suggestedPhasesForAccountsHash: {
    [accountId: number]: AISuggestedPhaseForAccount[];
  };
  accountId: number;
  numOfSuggestedPhaseToShow?: number;
};

/**
 * A helper function specifically for makeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccounts
 */

const formatPreviousProjectAndPhasesAndAddToCorrectList = ({
  currentProject,
  suggestedPhasesOfCurrentProject,
  nonSuggestedPhasesOfCurrentProject,
  /* -------------------------------------------------------------------------- */
  suggestedPhasesHash,
  numOfSuggestedPhaseToShow,
  /* -------------------------------------------------------------------------- */
  ptoProjectAndPhases,
  suggestedProjectsAndPhases,
  nonSuggestionProjectAndPhases
}: FormatPreviousProjectAndPhasesAndAddToCorrectListArgs) => {
  // Check if project has suggested default phase
  const currentProjectDefaultPhaseId = currentProject.defaultOrMainPhaseId;
  if (
    currentProject.has_default_phase &&
    currentProjectDefaultPhaseId &&
    suggestedPhasesHash[currentProjectDefaultPhaseId]
  ) {
    currentProject = {
      ...currentProject,
      isSuggestedProject: true
    } as ProjectWithSuggestionFlag;
  }

  // Check if has suggested phases. If yes, sort by score
  const currentProjectHasPhaseSuggestions =
    suggestedPhasesOfCurrentProject.length > 0;
  if (currentProjectHasPhaseSuggestions) {
    suggestedPhasesOfCurrentProject = orderBy(
      suggestedPhasesOfCurrentProject,
      'score',
      'desc'
    );
  }

  // Split suggested phases of this project into 2
  // First half: A list of phase suggestion of length = numOfSuggestedPhaseToShow => Will be used to show on UI as suggested
  // Second half: A list of remaining suggested phase which won't be shown on UI as suggested (considered non-suggested)
  const {
    firstHalf: suggestedPhasesOfCurrentProjectWithinLimit,
    secondHalf: suggestedPhasesOfCurrentProjectNotWithinLimit
  } = splitListIntoTwo<SuggestedPhaseForAccount, SuggestedPhaseForAccount>(
    suggestedPhasesOfCurrentProject,
    numOfSuggestedPhaseToShow
  );

  const suggestedPhasesOfCurrentProjectWithinLimitHash = keyBy(
    suggestedPhasesOfCurrentProjectWithinLimit,
    'id'
  );

  /* This list contains 
      - A list of remaining suggested phase which won't be shown on UI as suggested (considered non-suggested)
      - The non-suggested phases (phases of this project whose ID is Not in suggestedPhasesHash)
    This list needs to be re-ordered to match the original order from `project.phase_order`
  */
  const allNonSuggestedPhasesOfCurrentProjectNotInOrder = [
    // These phases still have suggested data on, but we won't show them because of limit
    ...suggestedPhasesOfCurrentProjectNotWithinLimit, // SuggestedPhaseForAccount[]
    ...nonSuggestedPhasesOfCurrentProject // ExtendedPhase[]
  ];

  let allNonSuggestedPhasesOfCurrentProjectInOrder: (
    | ExtendedPhase
    | SuggestedPhaseForAccount
  )[] = makeNonSuggestedItemsInOrder({
    itemListNotInOrder: allNonSuggestedPhasesOfCurrentProjectNotInOrder,
    suggestionHash: suggestedPhasesOfCurrentProjectWithinLimitHash,
    itemIdsOrder: currentProject.phase_orders
  });

  // Remove isSuggestedPhase flag because they are not in suggested list (Again, to respect numOfSuggestedPhaseToShow)
  allNonSuggestedPhasesOfCurrentProjectInOrder =
    allNonSuggestedPhasesOfCurrentProjectInOrder.map((phase) =>
      omit(phase, 'isSuggestedPhase')
    );

  if (isPtoProject(currentProject)) {
    currentProjectHasPhaseSuggestions
      ? ptoProjectAndPhases.push(
          currentProject,
          ...suggestedPhasesOfCurrentProjectWithinLimit,
          ...allNonSuggestedPhasesOfCurrentProjectInOrder
        )
      : ptoProjectAndPhases.push(
          currentProject,
          ...nonSuggestedPhasesOfCurrentProject
        );
  } else {
    currentProjectHasPhaseSuggestions
      ? suggestedProjectsAndPhases.push(
          currentProject,
          ...suggestedPhasesOfCurrentProjectWithinLimit,
          ...allNonSuggestedPhasesOfCurrentProjectInOrder
        )
      : nonSuggestionProjectAndPhases.push(
          currentProject,
          ...nonSuggestedPhasesOfCurrentProject
        );
  }
};

/**
 * A util function that leverages the list of projects and phases from getPlannerProjectAndPhases selector,
 * with phase suggestion sorted in descending order based on AI suggestion score
 * where each instance of the list follows the pattern:
 * [project1, phase1OfProject1, ..., phase2OfProject1, project2, phase1OfProject2, phase2OfProject2]
 * All suggested phases will come before non-suggested phases
 * (Very specific) Use Cases
 * - ProjectsAndPhasesDropdown on Home Timesheet and Report Timesheet (or anywhere using this dropdown component)
 */

export const makeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccounts = ({
  projectsAndPhases,
  suggestedPhasesForAccountsHash,
  accountId,
  numOfSuggestedPhaseToShow = defaultNumOfSuggestedPhaseForAccountsToShow
}: // i.e: If there are 8 phases suggested out 10 phases for a single project and numOfSuggestedPhaseToShow = 4,
// then only show top 4 ranked phases, show the remaining 4 phases together with remaining 2 non-suggested phases
MakeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccountsArgs) => {
  const suggestedPhasesArray = suggestedPhasesForAccountsHash[accountId];

  if (!projectsAndPhases?.length) return [];

  // No suggestions
  if (!suggestedPhasesArray || !suggestedPhasesArray.length)
    return projectsAndPhases;

  const suggestedPhasesHash = keyBy(suggestedPhasesArray, 'phase_id');

  let currentProject = projectsAndPhases[0] as Project;
  let suggestedPhasesOfCurrentProject: SuggestedPhaseForAccount[] = [];
  let nonSuggestedPhasesOfCurrentProject: ExtendedPhase[] = [];

  const ptoProjectAndPhases: PtoProjectAndPhases[] = [];
  const suggestedProjectsAndPhases: SuggestedProjectsAndPhases[] = [];
  const nonSuggestionProjectAndPhases: ProjectAndPhases[] = [];

  for (let i = 1; i < projectsAndPhases.length; i++) {
    const projectOrPhase = projectsAndPhases[i];
    const isPhase = (projectOrPhase as ExtendedPhase).is_phase;
    const isProject = !isPhase;

    // Project
    if (
      isProject &&
      currentProject &&
      currentProject.id !== (projectOrPhase as Project).id
    ) {
      formatPreviousProjectAndPhasesAndAddToCorrectList({
        currentProject,
        suggestedPhasesOfCurrentProject,
        nonSuggestedPhasesOfCurrentProject,
        suggestedPhasesHash,
        numOfSuggestedPhaseToShow,
        ptoProjectAndPhases,
        suggestedProjectsAndPhases,
        nonSuggestionProjectAndPhases
      });

      // Done with the previous project and phases, move onto the next group
      currentProject = projectOrPhase as Project;
      suggestedPhasesOfCurrentProject = [];
      nonSuggestedPhasesOfCurrentProject = [];
    } else {
      // Phase
      const currentPhase = projectOrPhase as ExtendedPhase;

      const isCurrentPhaseSuggested = !!suggestedPhasesHash[currentPhase.id];

      if (isCurrentPhaseSuggested) {
        const suggestedPhase = {
          ...projectOrPhase,
          ...suggestedPhasesHash[currentPhase.id],
          isSuggestedPhase: true
        } as SuggestedPhaseForAccount;
        suggestedPhasesOfCurrentProject.push(suggestedPhase);
      } else {
        nonSuggestedPhasesOfCurrentProject.push(currentPhase);
      }
    }
  }

  // Edge case: Don't forget to consider the last project and phases once loop ends
  formatPreviousProjectAndPhasesAndAddToCorrectList({
    currentProject,
    suggestedPhasesOfCurrentProject,
    nonSuggestedPhasesOfCurrentProject,
    suggestedPhasesHash,
    numOfSuggestedPhaseToShow,
    ptoProjectAndPhases,
    suggestedProjectsAndPhases,
    nonSuggestionProjectAndPhases
  });

  return [
    ...ptoProjectAndPhases,
    ...suggestedProjectsAndPhases,
    ...nonSuggestionProjectAndPhases
  ];
};

/* -------------------------------------------------------------------------- */

export const getPhaseMembershipIdsWithNoAccountId = (
  phases: Phase[]
): number[] => {
  if (!phases?.length) return [];

  const allPhaseMembershipsIds = new Set<number>();

  phases.forEach((phase) => {
    const currentPhaseMemberships = phase.phase_memberships;
    currentPhaseMemberships.forEach((phaseMembership) => {
      if (!phaseMembership.account_id) {
        allPhaseMembershipsIds.add(phaseMembership.id);
      }
    });
  });

  return Array.from(allPhaseMembershipsIds);
};
