import styled from 'styled-components';
import theme from 'theme';
import ThinCloseIcon from 'icons/ThinCloseIcon';

interface ChipsListProps {
  chips: { label: string; value: string }[];
  onDelete?: (params: { value: string }) => void;
  maxWidth?: number;
}

export const ChipsList = ({ chips, onDelete, maxWidth }: ChipsListProps) => {
  if (!chips.length) return <></>;

  return (
    <RootContainer style={{ maxWidth }}>
      {chips.map((chip) => (
        <ChipContainer key={chip.value}>
          <ChipLabel>{chip.label}</ChipLabel>
          {onDelete && (
            <StyledNewCloseIcon
              width={9}
              height={9}
              onClick={() => onDelete({ value: chip.value })}
            />
          )}
        </ChipContainer>
      ))}
    </RootContainer>
  );
};

const RootContainer = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const ChipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  gap: 10px;
  padding-left: 6px;
  /* TODO: background color is not exactly what is in mocks due to needing to fit in with existing location of app this component is being used. In https://mosaic-ai.atlassian.net/browse/WEB-2212 update background color to match mocks */
  background-color: ${theme.colors.colorLightGray21};
  padding-right: 5px;
`;

const ChipLabel = styled.span`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  // ensure text stays on one line
  white-space: nowrap;
`;

const StyledNewCloseIcon = styled(ThinCloseIcon)`
  cursor: pointer;
`;
