export enum SuggestionSpace {
  Timesheet = 'Timesheet'
}

/**
 * All BE endpoints that provides suggestions from AI must contain a `suggestion_type` key
 * in the query params or body payload
 * See available and valid values for `suggestion_type`
 * Link: https://mosaic-ai.atlassian.net/wiki/spaces/RA/pages/67960833/AI+Suggestion+Rejection+Spec
 */
export enum SuggestionsType {
  Account_PhaseMembership = 'Account_for_PhaseMembership',
  Phase_Account = 'Phase_for_Account',
  Account_Phase = 'Account_for_Phase',
  Account_Project = 'Account_for_Project',
  Skills_Position = 'Skills_for_Position',
  Account_ActivityPhaseMembership = 'Account_for_ActivityPhaseMembership'
}

export * from './phase';
