import { createAction } from 'appUtils';
import * as constants from '../constants';

export const openAddWidgetModal = (params) =>
  createAction(constants.OPEN_ADD_WIDGET_MODAL, params);
export const closeAddWidgetModal = (params) =>
  createAction(constants.CLOSE_ADD_WIDGET_MODAL, params);

export const openWidgetDetailModal = (params) =>
  createAction(constants.OPEN_WIDGET_DETAIL_MODAL, params);
export const closeWidgetDetailModal = (params) =>
  createAction(constants.CLOSE_WIDGET_DETAIL_MODAL, params);

export const setWidgetLoadedState = (params) =>
  createAction(constants.SET_WIDGET_LOADED_STATE, params);
export const clearWidgetLoadedState = (params) =>
  createAction(constants.CLEAR_WIDGET_LOADED_STATE, params);

export const createWidget = (params) =>
  createAction(constants.CREATE_WIDGET.TRIGGER, params);
export const fetchWidgets = (params) =>
  createAction(constants.FETCH_WIDGETS.TRIGGER, params);
export const updateWidget = (params) =>
  createAction(constants.UPDATE_WIDGET.TRIGGER, params);
export const deleteWidget = (params) =>
  createAction(constants.DELETE_WIDGET.TRIGGER, params);

export const createDashboard = (params) =>
  createAction(constants.CREATE_DASHBOARD.TRIGGER, params);
export const fetchDashboards = (params) =>
  createAction(constants.FETCH_DASHBOARDS.TRIGGER, params);
export const updateDashboard = (params) =>
  createAction(constants.UPDATE_DASHBOARD.TRIGGER, params);
export const deleteDashboard = (params) =>
  createAction(constants.DELETE_DASHBOARD.TRIGGER, params);

export const addWidgetToDashboard = (params) =>
  createAction(constants.ADD_WIDGET_TO_DASHBOARD.TRIGGER, params);
export const removeWidgetFromDashboard = (params) =>
  createAction(constants.REMOVE_WIDGET_FROM_DASHBOARD.TRIGGER, params);

export const fetchDashboardWidgets = (params) =>
  createAction(constants.FETCH_DASHBOARD_WIDGETS.TRIGGER, params);

export const fetchDashboardMemberships = (params) =>
  createAction(constants.FETCH_DASHBOARD_MEMBERSHIPS.TRIGGER, params);
export const shareDashboardWith = (params) =>
  createAction(constants.SHARE_DASHBOARD_MEMBERSHIPS.TRIGGER, params);
export const unshareDashboardWith = (params) =>
  createAction(constants.UNSHARE_DASHBOARD_MEMBERSHIPS.TRIGGER, params);
export const shareUnshareDashboardMemberships = (params) =>
  createAction(constants.SHARE_UNSHARE_DASHBOARD_MEMBERSHIPS.TRIGGER, params);
