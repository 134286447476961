import cn from 'classnames';
import {
  zoomToIntervalFormat,
  zoomToIntervalHash
} from 'appUtils/projectPlannerUtils';
import moment from 'appUtils/momentConfig';
import { ZOOM_LEVELS, ZOOM_STRINGS } from 'appConstants/workload';
import { ValueOf } from 'type-fest';
import { BaseIntervalRendererProps } from './types';
import styled from 'styled-components';
import { DateContainer, IntervalContainer } from './styles';

export const SecondaryIntervalRenderer = (props: BaseIntervalRendererProps) => {
  if (!props) return null;

  const { intervalContext, getIntervalProps, data } = props;

  const zoom = data?.zoom;
  if (!zoom) return null;

  const { interval } = intervalContext;
  const formatInterval = zoomToIntervalFormat[zoom];
  const stepValue = zoomToIntervalHash[zoom];

  return (
    <IntervalContainer {...getIntervalProps()}>
      <StyledDateContainer
        className={cn({
          today: isTodayWithinStep(interval.startTime, stepValue),
          left: zoom === ZOOM_LEVELS.DAY || zoom === ZOOM_LEVELS.WEEK,
          singleDay: stepValue === ZOOM_STRINGS.DAY
        })}
      >
        {formatInterval(interval.startTime)}
      </StyledDateContainer>
    </IntervalContainer>
  );
};

const isTodayWithinStep = (
  time: number,
  stepValue: ValueOf<typeof zoomToIntervalHash>
) => {
  return moment(time).range(stepValue).contains(moment());
};

const StyledDateContainer = styled(DateContainer)`
  font-size: 13px;
  // Prevent wrapping and overflow.
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid ${({ theme }) => theme.colors.colorLightGray9};

  > * {
    align-items: center;
    display: flex;
    height: 100%;
  }

  &.left {
    justify-content: flex-start;
    padding-left: min(8px, 10%);
    padding-right: min(8px, 10%);

    &.today {
      padding-left: calc(min(8px, 10%) + 1px);
    }
  }

  &.today {
    color: ${({ theme }) => theme.colors.colorCalendarOrange};
    margin-left: -2px;
    font-weight: 600;

    &.singleDay {
      border-left: 2px solid ${({ theme }) => theme.colors.colorCalendarOrange};
    }
  }
`;
