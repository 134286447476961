import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  closeEditNoteModal,
  closeAddNoteModal,
  openDeleteNoteModal,
  closeDeleteNoteModal
} from 'actionCreators';
import actionCreators from '../actionCreators';
import {
  getTributeNoteTags,
  getSelectedTeamId,
  getMatchedRouteParams
} from 'selectors';
import { getNotes } from '../selectors';
import AddEditNoteModal from './noteSettings/AddEditNoteModal';
import uuid from 'uuid';
import { html, getSlateBody, EMPTY_HTML_BODY } from 'appUtils/slate';
const { createNote, deleteNote, updateNote } = actionCreators;

class AddEditNoteContainer extends React.Component {
  constructor(props, context) {
    super(props, context);
    const note = this.findNote(props.notes.editingNoteId);

    this.state = {
      title: (note && note.title) || '',
      slateBody: getSlateBody(note),
      isTyping: false,
      isNewNote: !note
    };
  }

  componentDidMount() {
    this.typingTimeout = null;
  }

  getPermissions = () => {
    const {
      selectedTeamId,
      matchedParams: { projectId }
    } = this.props;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  // from sprint-11-12, may need it?
  UNSAFE_componentWillReceiveProps(nextProps) {
    const matchNoteId = (note) => note.id === nextProps.notes.editingNoteId;
    if (this.props.notes.newNoteTempId !== nextProps.notes.newNoteTempId) {
      const note =
        nextProps.notes.notesArray.find(matchNoteId) ||
        nextProps.notes.activityFeedNotes[nextProps.notes.editingNoteId];
      this.setState({
        title: (note && note.title) || '',
        slateBody: getSlateBody(note),
        isTyping: false
      });
    } else if (
      this.props.notes.editingNoteId !== nextProps.notes.editingNoteId
    ) {
      const note =
        nextProps.notes.notesArray.find(matchNoteId) ||
        nextProps.notes.activityFeedNotes[nextProps.notes.editingNoteId];
      this.setState({
        title: (note && note.title) || '',
        slateBody: getSlateBody(note),
        isTyping: false,
        isNewNote: !note
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const stoppedTyping = prevState.isTyping && !this.state.isTyping;
    if (stoppedTyping) {
      this.handleSubmit();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.typingTimeout);
    this.handleSubmit();
  }

  // EDIT
  handleSubmit = () => {
    const {
      updateNote,
      notes,
      search,
      me,
      createNote,
      matchedParams: { projectId }
    } = this.props;
    const { editingNoteId, addingNewNote } = notes;
    const { searchText } = search;
    const { isNewNote, title, slateBody } = this.state;
    let { selectedAccountIds } = notes;
    const note = this.findNote(editingNoteId);
    const htmlBody = html.serialize(slateBody);
    const hasContent = title.trim() || htmlBody !== EMPTY_HTML_BODY;
    const permissions = this.getPermissions();
    if (isNewNote && hasContent) {
      const tempId = uuid();
      createNote({
        accountId: me.id,
        title,
        body: htmlBody, // stored in database, mobile friendly
        slateBody, // purely for redux, prevent repeated serialization cycles
        projectId,
        accountIds: selectedAccountIds,
        searchText,
        tempId,
        permissions
      });
      this.setState({ isNewNote: false });
    } else {
      const didTitleUpdate = note && note.title !== title;
      const didBodyUpdate = note && note.body !== htmlBody;
      if (didTitleUpdate || didBodyUpdate) {
        updateNote({
          noteId: editingNoteId,
          title: this.state.title,
          body: htmlBody,
          slateBody,
          accountIds: selectedAccountIds,
          projectId: note.project_id,
          searchText,
          permissions
        });
      }
    }
  };

  deleteNote = () => {
    const { notes, deleteNote, projects } = this.props;
    const { selectedAccountIds, editingNoteId } = notes;
    const note = this.findNote(editingNoteId);
    const projectId = note && note.project_id;
    const permissions = this.getPermissions();

    deleteNote({
      noteId: editingNoteId,
      projectId,
      accountIds: selectedAccountIds,
      permissions
    });
  };

  findNote = (noteId) => {
    const { notesArray, activityFeedNotes } = this.props.notes;
    if (!notesArray || !activityFeedNotes) {
      return {};
    }
    return (
      activityFeedNotes[noteId] || notesArray.find((note) => note.id === noteId)
    );
  };

  saveAfterTyping = () => {
    // resets timer if user continues typing before save timeout completes
    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout(() => {
      this.setState({
        isTyping: false
      });
    }, 2000);
  };

  handleTitleChange = (event) => {
    this.setState({
      title: event.target.value,
      isTyping: true
    });

    this.saveAfterTyping();
  };

  handleBodyChange = ({ value }) => {
    this.setState({
      slateBody: value,
      isTyping: true
    });
    this.saveAfterTyping();
  };

  // ADD
  handleModalClose = () => {
    const { closeAddNoteModal } = this.props;
    closeAddNoteModal();
  };

  render() {
    const {
      notes,
      closeEditNoteModal,
      openDeleteNoteModal,
      closeDeleteNoteModal,
      closeAddNoteModal,
      projects,
      allTags
    } = this.props;
    const { isEditNoteModalOpen, isDeleteNoteModalOpen } = notes;
    const { title, slateBody } = this.state;

    return (
      <AddEditNoteModal
        isOpen={isEditNoteModalOpen || notes.isAddNoteModalOpen}
        title={title}
        slateBody={slateBody}
        closeModal={() => {
          if (!window.getSelection().toString()) {
            if (isEditNoteModalOpen) {
              closeEditNoteModal();
            } else {
              this.handleModalClose();
            }
          }
        }}
        handleSubmit={isEditNoteModalOpen ? this.handleSubmit : () => {}}
        handleTitleChange={this.handleTitleChange}
        handleBodyChange={this.handleBodyChange}
        openDeleteNoteModal={
          isEditNoteModalOpen ? openDeleteNoteModal : closeAddNoteModal
        }
        isEditModal={!!isEditNoteModalOpen}
        deleteNoteModalIsOpen={isDeleteNoteModalOpen}
        toggleDeleteNoteModal={closeDeleteNoteModal}
        deleteNoteOnClick={this.deleteNote}
        projectName={
          (projects.selectedProject[0] && projects.selectedProject[0].title) ||
          ''
        }
        allTags={allTags}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  notes: getNotes(state),
  auth: state.auth,
  projects: state.projects,
  me: state.users.me,
  search: state.search,
  allTags: getTributeNoteTags(state),
  selectedTeamId: getSelectedTeamId(state),
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  closeEditNoteModal,
  openDeleteNoteModal,
  closeDeleteNoteModal,
  updateNote,
  deleteNote,
  closeAddNoteModal,
  createNote
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEditNoteContainer)
);
