import { isPhaseArchived } from 'appUtils/phaseDisplayUtils';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { PhaseMembership } from 'ProjectsModule/phases/models/phaseMembership';
import { WorkPlanState } from '../types';

export const isValidWorkplan = ({
  workplan,
  phase,
  isMemberArchived,
  isNew,
  isRequest
}: {
  workplan: any;
  phase: any;
  isMemberArchived: boolean;
  isNew: boolean;
  forPrediction?: boolean;
  isRequest?: boolean;
}): boolean => {
  const {
    account_id,
    total_hours,
    start_date,
    end_date,
    daily_hours,
    project_id,
    phase_id,
    all_day,
    activity_id,
    member_budget_id,
    work_days
  } = workplan;
  const accountOrMemberBudget = account_id || member_budget_id;
  if (isMemberArchived) return false;
  if (phase_id) {
    if (phase?.activity_order?.length && !activity_id) {
      return false;
    }
    if (isPhaseArchived(phase) && isNew) return false;
  }
  if (all_day) {
    return (
      accountOrMemberBudget && project_id && phase_id && start_date && end_date
    ); //
  } else {
    if (isRequest) {
      // allow prediction to happen when request modal has only start and end and one of the below
      return Boolean(total_hours || work_days);
    }
    return (
      phase_id &&
      ((total_hours && end_date) ||
        ((total_hours || end_date) &&
          (work_days ||
            daily_hours ||
            (isRequest && total_hours && work_days))))
    );
  }
};

export const isValidExceptPhase = (workplan: any) => {
  const {
    account_id,
    total_hours,
    start_date,
    end_date,
    daily_hours,
    project_id,
    all_day,
    member_budget_id
  } = workplan;
  const accountOrMemberBudget = account_id || member_budget_id;
  if (all_day) {
    return accountOrMemberBudget && project_id && start_date && end_date;
  }
  return (
    accountOrMemberBudget &&
    (total_hours || end_date) &&
    daily_hours &&
    project_id
  );
};

export type WorkplanFormValidationError =
  | WorkplanFormFieldValidationError
  | WorkplanFormPhaseMemberValidationError
  | WorkplanFormPhaseValidationError
  | WorkplanFormWorkCategoryValidationError;
export const workplanFormValidator = ({
  workplan,
  phase,
  isNew,
  workdayPercent
}: {
  workplan: WorkPlanState;
  phase: any;
  isNew: boolean;
  workdayPercent?: number;
}): WorkplanFormValidationError[] | undefined => {
  const workplanFormFieldsErrors = workplanFormFieldsValidator({
    workplan,
    workdayPercent
  });

  const phaseMemberErrors =
    workplan.member_budget_id && phase?.phase_memberships
      ? workplanFormPhaseMemberValidator({
          phaseMemberships: phase.phase_memberships,
          memberBudgetId: workplan.member_budget_id
        })
      : undefined;

  const phaseErrors = isNew ? workplanFormPhaseValidator(phase) : undefined;

  const workCategoryErrors = isNew
    ? workplanFormWorkCategoryValidator({ phase, workplan })
    : undefined;

  const errors = [
    ...(workplanFormFieldsErrors ?? []),
    ...(phaseMemberErrors ?? []),
    ...(phaseErrors ?? []),
    ...(workCategoryErrors ?? [])
  ];
  return errors.length ? errors : undefined;
};

export type WorkplanFormPhaseMemberValidationError =
  'selected_archived_phase_member';

export const workplanFormPhaseMemberValidator = ({
  phaseMemberships,
  memberBudgetId
}: {
  phaseMemberships: PhaseMembership[];
  memberBudgetId: number;
}): WorkplanFormPhaseMemberValidationError[] | undefined => {
  const errors: WorkplanFormPhaseMemberValidationError[] = [];
  const phaseMembership = phaseMemberships.find(
    (member) => member?.member_budget_id === memberBudgetId
  );

  const isArchivedMember =
    !!phaseMembership?.discarded_at || phaseMembership?.archived;

  if (isArchivedMember) {
    errors.push('selected_archived_phase_member');
  }

  return errors.length ? errors : undefined;
};

export type WorkplanFormPhaseValidationError = 'selected_archived_phase';

export const workplanFormPhaseValidator = (
  phase
): WorkplanFormPhaseValidationError[] | undefined => {
  const errors: WorkplanFormPhaseValidationError[] = [];
  if (isPhaseArchived(phase)) {
    errors.push('selected_archived_phase');
  }
  return errors.length ? errors : undefined;
};

export type WorkplanFormWorkCategoryValidationError = 'missing_activity_id';
export const workplanFormWorkCategoryValidator = ({
  phase,
  workplan
}: {
  phase: Phase | undefined;
  workplan: WorkPlanState;
}): WorkplanFormWorkCategoryValidationError[] | undefined => {
  const errors: WorkplanFormWorkCategoryValidationError[] = [];
  if (
    phase?.is_like_default &&
    phase?.activity_order?.length &&
    !workplan.activity_id
  )
    errors.push('missing_activity_id');

  return errors.length ? errors : undefined;
};

export type WorkplanFormFieldValidationError =
  | 'missing_account_id_or_member_budget_id'
  | 'missing_project_id'
  | 'missing_phase_id'
  | 'missing_start_date'
  | 'missing_end_date'
  | 'invalid_total_hours'
  | 'invalid_daily_hours'
  | 'invalid_workday_percent';

export const workplanFormFieldsValidator = ({
  workplan,
  workdayPercent
}: {
  workplan: WorkPlanState;
  workdayPercent?: number;
}): WorkplanFormFieldValidationError[] | undefined => {
  const errors: WorkplanFormFieldValidationError[] = [];

  if (!workplan.project_id) {
    errors.push('missing_project_id');
  }

  if (!workplan.phase_id) {
    errors.push('missing_phase_id');
  }

  if (!workplan.account_id && !workplan.member_budget_id) {
    errors.push('missing_account_id_or_member_budget_id');
  }

  if (!workplan.start_date) {
    errors.push('missing_start_date');
    // TODO validate date format
  }

  if (!workplan.end_date) {
    errors.push('missing_end_date');
  }

  if (!workplan.all_day) {
    if (
      !workplan.total_hours ||
      !(workplan.total_hours && Boolean(Number(workplan.total_hours)))
    ) {
      errors.push('invalid_total_hours');
    }
    if (
      !workplan.daily_hours ||
      !(workplan.daily_hours && Boolean(Number(workplan.daily_hours)))
    ) {
      errors.push('invalid_daily_hours');
    }

    if (!workdayPercent || workdayPercent <= 0) {
      errors.push('invalid_workday_percent');
    }
  }

  return errors.length ? errors : undefined;
};
