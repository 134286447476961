import { SimpleConfirmModal } from 'components/Modals';

interface AddTaskConfirmModalProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
  targetPhaseName?: string;
}
export const AddTaskConfirmModal = ({
  isOpen,
  toggle,
  onConfirm,
  targetPhaseName
}: AddTaskConfirmModalProps) => {
  return (
    <SimpleConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      onCancel={toggle}
      shouldSetIsClosingOnClose={false}
      onConfirm={onConfirm}
      header="Add Tasks"
      confirmLabel="Add"
      body={
        <span>
          This task is under a different phase. Do you want to move it to{' '}
          <strong>{targetPhaseName}</strong>?
        </span>
      }
    />
  );
};
