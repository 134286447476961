import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import newClose from 'images/page-1.svg';
import { getMyTeamMembership } from 'selectors';
import { updateTeamMembership } from 'actionCreators';
import styled from 'styled-components';

function ChromeDownloadBanner() {
  const [showConfirmDisableButton, setShowConfirmDisableButton] =
    useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const myTeamMembership = useSelector(getMyTeamMembership);
  const dispatch = useDispatch();

  const bannerDisabled =
    myTeamMembership?.modal_preferences?.hide_chrome_banner;
  const agent = window.navigator.userAgent;

  const isSafari = useMemo(
    () => agent.indexOf('Safari/') > -1 && agent.indexOf('Chrome/') < 0,
    [agent]
  );

  const isChromeOutOfDate = useMemo(() => {
    const rawChromeVersion = navigator.userAgent.match(
      /Chrom(e|ium)\/([0-9]+)\./
    );
    const chromeVersion = rawChromeVersion
      ? parseInt(rawChromeVersion[2], 10)
      : false;

    const minimumSupportedChromeVersion = 85;

    // css grid support
    return chromeVersion && chromeVersion < minimumSupportedChromeVersion;
  }, []);

  const shouldDisplayBanner = useMemo(
    () =>
      !bannerDisabled &&
      (agent.indexOf('MSIE ') > -1 ||
        agent.indexOf('Trident/') > -1 ||
        agent.indexOf('Edge/') > -1 ||
        agent.indexOf('Firefox/') > -1 ||
        isSafari ||
        isChromeOutOfDate),
    [agent, bannerDisabled, isChromeOutOfDate, isSafari]
  );

  if (!shouldDisplayBanner || isHidden) {
    return null;
  }

  const handleClose = () => {
    setShowConfirmDisableButton(true);
  };
  const disableChromeBannerAndClose = () => {
    setIsHidden(true);
    dispatch(
      updateTeamMembership({
        id: myTeamMembership.id,
        modal_preferences: {
          ...myTeamMembership.modal_preferences,
          hide_chrome_banner: true
        }
      })
    );
  };

  const bannerMessage = showConfirmDisableButton ? (
    <p
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      Disable This message in the future?
      <div
        style={{ marginLeft: 10, cursor: 'pointer' }}
        onClick={disableChromeBannerAndClose}
      >
        Yes
      </div>
      <div
        style={{ marginLeft: 10, cursor: 'pointer' }}
        onClick={() => setIsHidden(true)}
      >
        Just this time
      </div>
    </p>
  ) : isChromeOutOfDate ? (
    <p>
      Mosaic runs best on a newer version of Google Chrome. For help updating
      Chrome, please click&nbsp;
      <a href="https://support.google.com/chrome/answer/95414"> here </a>
      <img src={newClose} className="banner-close" onClick={handleClose} />
    </p>
  ) : (
    <p>
      Mosaic runs best on Google Chrome or Microsoft Edge Browsers. Don&#39;t
      Have Chrome? Install&nbsp;
      <a
        target={'_blank'}
        rel={'noopener noreferrer'}
        href="https://www.google.com/chrome/browser/desktop/index.html?brand=CHBD&gclid=CjwKCAjwmIrJBRAREiwAuUPKMroEstfo0MYpVQlzMjt22tmr8c0UE-0A4w8mErlSovplYRtB2vJr2hoC6BUQAvD_BwE"
      >
        here
      </a>
      <img src={newClose} className="banner-close" onClick={handleClose} />
    </p>
  );

  return <ChromeBannerContainer>{bannerMessage}</ChromeBannerContainer>;
}

export default ChromeDownloadBanner;

const ChromeBannerContainer = styled.div`
  position: fixed;
  top: 0px;
  z-index: 1000;
  width: 100%;
  height: 40px;
  background-color: #ef7b31;
  p {
    padding-top: 9px;
    color: white;
    font-size: 15px;
    letter-spacing: -0.1px;
    text-align: center;
  }
  a {
    text-decoration: underline;
  }
  .banner-close {
    float: right;
    margin-top: 6px;
    margin-right: 15px;
    z-index: 100;
  }
`;
