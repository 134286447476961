import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { CapacitiesTable } from './CapacitiesTable';
import { batch } from 'react-redux';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import {
  fetchCapacityPolicies,
  fetchCapacityPoliciesPerMember
} from 'CapacityModule/actionCreators/capacityGroup';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import {
  fetchAllTeamRates,
  fetchPositions,
  fetchRates,
  fetchTeamPositions
} from 'BudgetModule/actionCreators';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

export const CapacitiesTableContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      batch(() => {
        dispatch(fetchCapacityPoliciesPerMember({ teamId }));
        dispatch(fetchCapacityPolicies({ teamId }));
        dispatch(fetchAllTeamRates({ teamId }));
        dispatch(fetchRates({ teamId }));
        dispatch(fetchPositions({ teamId }));
        dispatch(fetchTeamPositions({ teamId }));
      });
    }
  }, [dispatch, teamId]);

  return (
    <DynamicModuleLoader modules={[getCapacitiesModule(), getBudgetModule()]}>
      <CapacitiesTable />
    </DynamicModuleLoader>
  );
};
