import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, enteredFieldIsTrue } from './utils';

import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  newTaskStatuses: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [taskStatus: string]: string };
    };
  }>;
}

export const taskStatusValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { newTaskStatuses } = additionalParams;

  const {
    enteredTaskStatus,
    enteredProjectTitle,
    enteredProjectNumber,
    enteredIsScope
  } = getRowValuesForEntityType(row, entityType);

  // skip validation if user is actually trying to import scope

  if (enteredFieldIsTrue(enteredIsScope)) return true;

  if (!(enteredProjectTitle || enteredProjectNumber)) {
    return ERROR_MESSAGES.missingProjectTitleAndNumber;
  } else if (!enteredProjectTitle) {
    return ERROR_MESSAGES.missingProjectTitle;
  } else if (!enteredProjectNumber) {
    return ERROR_MESSAGES.missingProjectNumber;
  }

  // not required, allow user enter new task statuses
  if (enteredTaskStatus === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (!enteredTaskStatus) return true;
  newTaskStatuses.current = {
    ...newTaskStatuses.current,
    [enteredProjectTitle]: {
      ...newTaskStatuses.current[enteredProjectTitle],
      [enteredProjectNumber]: {
        ...newTaskStatuses.current[enteredProjectTitle]?.[enteredProjectNumber],
        [enteredTaskStatus]: enteredTaskStatus
      }
    }
  };
  return true;
};
