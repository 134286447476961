import { Items } from 'TimelinesModule/types/items';
import { BaseTimelineProps } from 'TimelinesModule/types/timeline';
import { BaseItemRendererProps } from './types';

interface ItemRendererByTypeProps extends BaseItemRendererProps<Items> {
  customRenderers: BaseTimelineProps['itemRenderersByType'];
}

export const ItemRendererByType = (props: ItemRendererByTypeProps) => {
  const { customRenderers, ...rest } = props;
  const { item } = rest;

  const Component = customRenderers[item.type];

  if (!Component) return null;

  return <Component {...rest} />;
};
