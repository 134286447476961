import cn from 'classnames';
import { zoomToTopIntervalFormat } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
import { BaseIntervalRendererProps } from './types';
import styled from 'styled-components';
import { DateContainer, IntervalContainer } from './styles';

export const PrimaryIntervalRenderer = (
  props: BaseIntervalRendererProps | undefined
) => {
  if (!props) return null;

  const { intervalContext, getIntervalProps, data } = props;

  const zoom = data?.zoom;
  if (!zoom) return null;

  const { interval } = intervalContext;
  const formatInterval = zoomToTopIntervalFormat[zoom];
  return (
    <IntervalContainer {...getIntervalProps()}>
      <StyledDateContainer
        className={cn({
          showBorder: zoom === ZOOM_LEVELS.DAY
        })}
      >
        {formatInterval(interval.startTime)}
      </StyledDateContainer>
    </IntervalContainer>
  );
};

const StyledDateContainer = styled(DateContainer)`
  font-size: 14px;
  font-weight: 600;

  &.showBorder {
    border-right: 1px solid ${({ theme }) => theme.colors.colorLightGray9};
  }
`;
