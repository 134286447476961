import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Sections } from 'appConstants/navigation';

class WorkloadNavigationTracker extends React.PureComponent {
  render() {
    return <NavigationTracker section={Sections.WORKLOAD} />;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkloadNavigationTracker)
);
