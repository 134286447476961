import styled from 'styled-components';
import { Groups } from 'TimelinesModule/types/groups';
import WFHIcon from 'icons/WFHIcon';
import cn from 'classnames';
import { UtilizationBreakdownsByDate } from 'UtilizationModule/types';
import {
  RowRenderer,
  HoursDisplayType,
  BucketType,
  WorkFromHomesByDate
} from './types';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { BucketRenderer } from './BucketRenderers/BucketRenderer';
import { AvailabilityBucket } from './BucketRenderers/AvailabilityBucket';
import { FullPTOBucket } from './BucketRenderers/FullPTOBucket';
import { HolidayBucket } from './BucketRenderers/HolidayBucket';
import { getDisplayBucketType } from './utils';
import { ColorScaleIntervalsWithMax } from 'components/ColorScaleModal/types';
import { AvailabilitySkeletonBucket } from './BucketRenderers/AvailabilitySkeletonBucket';
import { useTimelineConfig } from '../Timelines/providers/TimelineConfigProvider';

type AvailabilityRowRendererProps = {
  hoursDisplayType?: HoursDisplayType;
  onWFHClick?: (id?: number) => void;
  onTimesheetTimeClick?: () => void;
  utilizationBreakdownsByDate?: UtilizationBreakdownsByDate;
  workFromHomeByDate?: WorkFromHomesByDate;
  showTimesheetTime?: boolean;
  heatmap: ColorScaleIntervalsWithMax;
};

export const AvailabilityRowRenderer: RowRenderer<
  Groups,
  AvailabilityRowRendererProps
> = ({
  bufferedTimeStart,
  bufferedTimeEnd,
  hoursDisplayType = HoursDisplayType.PercentageOfCapacity,
  onWFHClick,
  onTimesheetTimeClick,
  utilizationBreakdownsByDate,
  workFromHomeByDate,
  showTimesheetTime,
  heatmap
}) => {
  const { readOnly } = useTimelineConfig();

  const handleWFHClick = (id?: number) => () => {
    onWFHClick?.(id);
  };

  return (
    <BucketRenderer
      bufferedTimeStart={bufferedTimeStart}
      bufferedTimeEnd={bufferedTimeEnd}
    >
      {({ bucketMeta }) =>
        bucketMeta.map(({ isoDate, isWeekend }) => {
          const utilizationBreakdown = utilizationBreakdownsByDate?.[isoDate];
          const workFromHome = workFromHomeByDate?.[isoDate];

          const isWFH = !!workFromHome;

          const contentContainerClassName = cn({
            isWeekend
          });

          if (!utilizationBreakdown) {
            // TODO: add skeleton loader
            return (
              <ContentContainer className={contentContainerClassName}>
                <AvailabilitySkeletonBucket heatmap={heatmap} />
              </ContentContainer>
            );
          }

          const { PTO, capacity, holiday, totalTimesheetHours } =
            utilizationBreakdown;

          const bucketType = getDisplayBucketType({
            PTO,
            holiday,
            capacity
          });

          const shouldShowTimesheetTime = Boolean(
            showTimesheetTime && totalTimesheetHours
          );

          const renderBucketByType = (bucketType: BucketType) => {
            switch (bucketType) {
              case BucketType.Availability:
                return (
                  <AvailabilityBucket
                    heatmap={heatmap}
                    hoursDisplayType={hoursDisplayType}
                    utilizationBreakdown={utilizationBreakdown}
                  />
                );
              case BucketType.FullPTO:
                return (
                  <FullPTOBucket
                    hoursDisplayType={hoursDisplayType}
                    utilizationBreakdown={utilizationBreakdown}
                  />
                );
              case BucketType.Holiday:
                return <HolidayBucket />;
              default:
                return null;
            }
          };

          return (
            <ContentContainer
              key={isoDate}
              className={contentContainerClassName}
            >
              {renderBucketByType(bucketType)}
              {shouldShowTimesheetTime && (
                <TopRightCornerContainer>
                  <TimesheetTimeContainer onClick={onTimesheetTimeClick}>
                    {formatNumWithMaxOneDecimal(totalTimesheetHours)}
                  </TimesheetTimeContainer>
                </TopRightCornerContainer>
              )}
              <BottomLeftContainer>
                <StyledWFHIcon
                  onClick={
                    readOnly ? undefined : handleWFHClick(workFromHome?.id)
                  }
                  className={cn({
                    'show-on-hover': !readOnly && !isWFH,
                    readOnly,
                    active: isWFH
                  })}
                />
              </BottomLeftContainer>
            </ContentContainer>
          );
        })
      }
    </BucketRenderer>
  );
};

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.colorPureWhite};

  &.isWeekend {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray3};
  }

  &:not(:hover) {
    .show-on-hover {
      display: none;
    }
  }

  &:hover {
    .hide-on-hover {
      display: none;
    }
  }
`;

const BottomLeftContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 5px;
  left: 5px;
`;

const StyledWFHIcon = styled(WFHIcon).attrs(({ theme }) => ({
  fill: theme.colors.colorCalendarGray,
  height: 12,
  width: 11
}))`
  cursor: pointer;

  &.readOnly:not(.active) {
    display: none;
  }

  &.active path {
    path {
      fill: ${({ theme }) => theme.colors.colorCalendarGray};
    }
  }

  &:hover:not(.readOnly) path {
    fill: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

const TopRightCornerContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 6px;
`;

const TimesheetTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 17px;
  height: 17px;
  border-radius: 1000px;
  cursor: pointer;
  font-size: 12px;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorPureWhite};
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;
