import * as constants from 'appConstants';
import { Sections, Modals } from 'appConstants/navigation';
import {
  SPLIT_SCREEN_TYPES,
  SPLIT_SCREEN_SECTION_HASH
} from 'appConstants/workload';
import { createAction as appCreateAction } from 'appUtils'; // this uses action.meta, matching flux convention
import { createAction as rtkCreateAction } from '@reduxjs/toolkit';
export * from './access';
export * from './phases';
export * from './workload';
export * from './tasks';
export * from './skills';
export * from './recurly';
export * from './csvImport';
export * from './suggestions';
export * from './scopes';
export * from './triggers';
export * from './statuses';
export * from './offline';
export * from './attachments';
export * from './comments';

const createAction = (type, payload, metadata) => {
  const action = {
    type
  };
  if (payload) {
    action.payload = payload;
  }
  if (metadata || payload?.metadata) {
    action.metadata = metadata || payload?.metadata;
    action.meta = metadata || payload?.metadata;
  }
  return action;
};

const createAsyncAction = (type, payload, priority = 5) => {
  const action = createAction(type, payload);
  // merge action.metadata to allow overriding if necessary
  action.metadata = { priority, isAsyncAction: true, ...action.metadata };
  return action;
};
export const addNavigationChange = (params) =>
  createAction(constants.NAVIGATION_CHANGE, params);

export const navigateBack = (params) =>
  createAction(constants.NAVIGATE_BACK, params);
export const navigateBackCleanup = (params) =>
  createAction(constants.NAVIGATE_BACK_CLEANUP, params);

export const navigateUpCleanup = (params) =>
  createAction(constants.NAVIGATE_UP_CLEANUP, params);

export const navigateTo = (params) =>
  createAction(constants.NAVIGATE_TO, params);
export const navigateUp = (params) =>
  createAction(constants.NAVIGATE_UP, params);

export const navigateToProject = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.PROJECT,
    viewType: 'tasks', // default view, can override via params
    ...params
  });

export const navigateToBoard = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAMS,
    viewType: 'status', // default view, can over override via params
    ...params
  });

export const navigateToTaskModal = (params) =>
  createAction(constants.NAVIGATE_TO, {
    modal: Modals.TASK,
    ...params
  });

export const navigateToMemberModal = (params) =>
  createAction(constants.NAVIGATE_TO, {
    modal: Modals.MEMBER,
    ...params
  });

export const navigateToUnplannedModal = (params) =>
  createAction(constants.NAVIGATE_TO, { modal: Modals.UNPLANNED, ...params });

export const navigateToWorkplansModal = (params) =>
  createAction(constants.NAVIGATE_TO, { modal: Modals.WORKPLANS, ...params });

export const navigateToScopeModal = (params) =>
  createAction(constants.NAVIGATE_TO, {
    modal: Modals.SCOPE,
    ...params
  });

export const navigateToBudgetModal = (params) =>
  createAction(constants.NAVIGATE_TO, { modal: Modals.BUDGET, ...params });
export const navigateToActivityFeed = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.HOME,
    homeViewType: 'notifications',
    ...params
  });

export const navigateToTimeSheet = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.HOME,
    homeViewType: 'time',
    ...params
  });

export const navigateToHome = (params) => {
  return createAction(constants.NAVIGATE_TO, {
    section: Sections.HOME,
    homeViewType: 'dashboard',
    useLastLocation: true,
    ...params,
    userSlug: params.userSlug || 'user'
  });
};

export const navigateToUsingPath = (params) => {
  return createAction(constants.NAVIGATE_TO, {
    usePath: true,
    ...params
  });
};

export const navigateToPersonalDashboard = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.HOME,
    homeViewType: 'dashboard',
    ...params
  });

export const navigateToMembers = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.MEMBERS,
    membersViewType: 'members', // default view, can over override via params
    ...params
  });

export const navigateToOrgChart = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.MEMBERS,
    membersViewType: 'chart',
    ...params
  });

export const navigateToSchedule = ({ splitScreenActive, ...params }) =>
  createAction(constants.NAVIGATE_TO, {
    section: splitScreenActive
      ? Sections.PLANNER_SPLIT_SCREEN
      : Sections.PLANNER,
    plannerViewType: 'tasks',
    ...params
  });
export const navigateToWorkload = ({
  workloadViewType,
  splitScreenActive,
  ...params
}) =>
  createAction(constants.NAVIGATE_TO, {
    section: splitScreenActive
      ? Sections.WORKLOAD_SPLIT_SCREEN
      : Sections.WORKLOAD,
    workloadViewType: 'members',
    ...params
  });

export const navigateToReport = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.REPORTS,
    ...params
  });
export const setLaunchDarklyFlags = (params) =>
  createAction(constants.SET_LD_FLAGS, params);
export const navigateToSettings = () =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.SETTINGS
  });
export const navigateToIntegrations = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.INTEGRATIONS,
    ...params
  });

export const navigateToTeamSettings = rtkCreateAction(
  constants.NAVIGATE_TO,
  (params) => ({
    payload: { section: Sections.TEAM_SETTINGS, viewType: 'info', ...params }
  })
);

export const navigateToMembersSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    ...params
  });
export const navigateToMembersRatesSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'budget',
    ...params
  });
export const navigateToMembersSkillsSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'skills',
    ...params
  });
export const navigateToMembersRegionSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'regions',
    ...params
  });
export const navigateToMembersOfficeSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'offices',
    ...params
  });
export const navigateToMembersDisciplineSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'disciplines',
    ...params
  });
export const navigateToMembersDepartmentSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'departments',
    ...params
  });
export const navigateToMembersLocationsSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'members',
    tab: 'locations',
    ...params
  });
export const navigateToStandardRolesSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'standards',
    tab: 'roles',
    ...params
  });
export const navigateToStandardHolidaysSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'standards',
    tab: 'holidays',
    ...params
  });

export const navigateToStandardPhasesSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'standards',
    tab: 'phases',
    ...params
  });
export const navigateToStandardMilestonesSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'standards',
    tab: 'milestones',
    ...params
  });
export const navigateToStandardWorkCategoriesSettings = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.TEAM_SETTINGS,
    viewType: 'standards',
    tab: 'work_categories',
    ...params
  });

export const navigateToDashboard = (params) =>
  createAction(constants.NAVIGATE_TO, {
    section: Sections.DASHBOARD,
    modal: params.reportId ? Modals.REPORT : undefined,
    ...params
  });

export const setNavigationBlocked = (params) =>
  createAction(constants.SET_NAVIGATION_BLOCKED, params);

export const addParsedRouteParams = (params) =>
  createAction(constants.ADD_PARSED_ROUTED_PARAMS, params);
export const removeParsedRouteParams = (params) =>
  createAction(constants.REMOVE_PARSED_ROUTED_PARAMS, params);
export const resetParsedParams = () =>
  createAction(constants.RESET_PARSED_ROUTED_PARAMS);

const createWSRemovalAction = (type, payload, channels) => {
  const action = {
    type
  };
  if (payload) {
    action.payload = payload;
  }
  if (channels) {
    action.payload = {
      ...action.payload,
      channels
    };
  }
  return action;
};

export const toggleSidebarSearch = () => ({
  type: constants.TOGGLE_SIDEBAR_SEARCH
});

export const closeSidebarSearch = () =>
  createAction(constants.CLOSE_SIDEBAR_SEARCH);

export const closeSidebarSearchAndProjects = () =>
  createAction(constants.CLOSE_SIDEBAR_SEARCH_AND_PROJECTS);

export const toggleSidebarProjects = (
  initiator,
  token = null,
  fromHome,
  shouldOpen
) => ({
  type: constants.TOGGLE_SIDEBAR_PROJECTS.TRIGGER,
  payload: { token, initiator, fromHome, shouldOpen }
});

export const openSidebarProjects = () =>
  createAction(constants.OPEN_SIDEBAR_PROJECTS);

export const closeSidebarProjects = () =>
  createAction(constants.CLOSE_SIDEBAR_PROJECTS);

export const updateSidebarProjectsPinnedState = (params) =>
  createAction(constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.TRIGGER, params);

export const setMouseOverSearchBar = (isOverSearch) => ({
  type: constants.SET_MOUSE_OVER_SEARCH_BAR,
  isOverSearch
});

export const toggleMemberInput = (isOpen) => ({
  type: constants.TOGGLE_MEMBER_INPUT,
  isOpen
});

export const toggleInviteButton = (showInviteButton) => ({
  type: constants.TOGGLE_INVITE_BUTTON,
  showInviteButton
});

export const toggleInviteForm = (options) => ({
  type: constants.TOGGLE_INVITE_FORM,
  options
});

export const setMemberInputValue = (payload) => ({
  type: constants.SET_MEMBER_INPUT_VALUE,
  payload
});

export const clearResendInvite = () => ({
  type: constants.CLEAR_RESEND_INVITE
});

/* trial expiry/billing */
export const logoutTrialExpiry = (payload) => ({
  type: constants.LOGOUT_TRIAL_EXPIRY,
  payload
});

export const setModal = (newModalName, prevModal, openMembersTab = false) => ({
  type: constants.SET_MODAL,
  payload: { newModalName, prevModal, openMembersTab }
});

export const setActiveKey = (activeKey) => ({
  type: constants.SET_ACTIVE_KEY,
  payload: activeKey
});

export const cancelTrialExpiry = () => ({
  type: constants.CANCEL_TRIAL_EXPIRY
});

export const clearBillingError = () => ({
  type: constants.CLEAR_BILLING_ERROR
});

/* auth */
export const fetchUser = rtkCreateAction(
  constants.USER.TRIGGER,
  ({ meta } = {}) => ({ meta })
);

export const loginUser = (email, password) => ({
  type: constants.LOGIN.TRIGGER,
  payload: { email, password }
});

export const logoutUser = rtkCreateAction(constants.LOGOUT_USER);

export const triggerLogout = () => ({ type: constants.LOGOUT });

export const logoutUserInvite = () => ({ type: constants.LOGOUT_USER_INVITE });

export const exchangeAccessToken = ({ token, realmId }) =>
  createAction(constants.EXCHANGE_ACCESS_TOKEN, { token, realmId });

/* boards */
export const changeBoardPosition = (boardId, position) =>
  createAction(constants.CHANGE_BOARD_POSITION.TRIGGER, { boardId, position });

export const createBoard = (
  token,
  boardName,
  isPrivate,
  stayOnView,
  color,
  notFromModal,
  onSuccess
) => ({
  type: constants.GROUP_CREATION.TRIGGER,
  payload: {
    token,
    boardName,
    isPrivate,
    stayOnView,
    color,
    notFromModal,
    onSuccess
  }
});

export const editGroup = (token, id, boardName, isPrivate) => ({
  type: constants.GROUP_EDITION.TRIGGER,
  payload: { token, id, boardName, isPrivate }
});

export const fetchGroups = (token) => ({
  type: constants.GROUPS.TRIGGER,
  payload: { token }
});

export const fetchSelectedBoard = (boardId) => ({
  type: constants.FETCH_SELECTED_BOARD.TRIGGER,
  payload: { boardId }
});
export const fetchProjectsListView = (params) =>
  createAction(constants.FETCH_PROJECTS_LIST_VIEW.TRIGGER, params);
export const exportProjectsListView = (params) =>
  createAction(constants.EXPORT_PROJECTS_LIST_VIEW.TRIGGER, params);

export const setSelectedBoard = (params) =>
  createAction(constants.SET_SELECTED_BOARD, params);
export const clearSelectedBoard = () =>
  createAction(constants.CLEAR_SELECTED_BOARD);

export const fetchArchivedTeamBoards = (token, teamId) => ({
  type: constants.FETCH_ARCHIVED_BOARDS.TRIGGER,
  payload: { token, teamId, get_archived: true }
});

export const openAddEditGroupModal = ({
  isEdit,
  id,
  shouldStayOnViewAfterCreate
}) => ({
  type: constants.OPEN_ADD_EDIT_GROUP_MODAL,
  payload: { isEdit, id, shouldStayOnViewAfterCreate }
});

export const closeAddGroupModal = () => ({
  type: constants.CLOSE_ADD_GROUP_MODAL
});

export const closeEditGroupModal = () => ({
  type: constants.CLOSE_EDIT_GROUP_MODAL
});

export const switchBoards = () => ({
  type: constants.SWITCH_BOARDS
});

export const fetchBoardMembers = (boardId) => ({
  type: constants.GROUP_MEMBERS.TRIGGER,
  payload: { boardId }
});

export const fetchAllGroupMembers = (token) => ({
  type: constants.ALL_GROUP_MEMBERS.TRIGGER,
  payload: { token }
});

export const addMemberToLocalGroup = (groupId, member) => ({
  type: constants.ADD_MEMBER_TO_LOCAL_GROUP,
  member
});

export const addBulkMembersToLocalGroup = (groupId, members) => ({
  type: constants.ADD_BULK_MEMBERS_TO_LOCAL_GROUP,
  members
});

export const deleteMemberFromLocalGroup = (groupId, member) => ({
  type: constants.DELETE_MEMBER_FROM_LOCAL_GROUP,
  member
});

export const addMemberToGroup = (token, groupId, member, roleId) => {
  /*
    addMemberToGroup now also called when adding a new mosaic member
    resulting in the need for the ternary.
*/
  const accountId = member.account ? member.account.id : member.id;

  return {
    type: constants.ADD_MEMBER_TO_GROUP.TRIGGER,
    payload: { token, groupId, accountId, roleId }
  };
};

export const addBulkMembersToGroup = (token, groupId, members) =>
  createAction(constants.ADD_BULK_MEMBERS_TO_GROUP.TRIGGER, {
    token,
    groupId,
    members
  });

export const deleteMemberFromGroup = (token, memberAccountId, groupId) => ({
  type: constants.DELETE_MEMBER_FROM_GROUP.TRIGGER,
  payload: { token, memberAccountId, groupId }
});

export const archiveGroup = (id) => ({
  type: constants.ARCHIVE_BOARD.TRIGGER,
  payload: { id, archive: true }
});

export const unarchiveBoard = (boardId) => ({
  type: constants.UNARCHIVE_BOARD.TRIGGER,
  payload: { boardId, unarchive: true }
});

export const deleteBoard = (boardId, teamId) => ({
  type: constants.DELETE_BOARD.TRIGGER,
  payload: { boardId, teamId }
});

export const openArchiveBoardModal = (modalState) => ({
  type: constants.TOGGLE_ARCHIVE_GROUP_MODAL,
  payload: { modalState }
});

/* projects */
export const indicateLazyLoad = () => ({
  type: constants.LAZY_LOADING_PROJECT_LIST
});

export const handleProjectItemState = (id, target, bool) => {
  return {
    type: constants.HANDLE_PROJECT_ITEM_STATE,
    payload: { id, target, bool }
  };
};

export const monthHeaderChange = (newMonth, newYear) => {
  return {
    type: constants.MONTH_HEADER_CHANGE,
    payload: { newMonth, newYear }
  };
};

export const weeksToDisplayUpdate = (newWeeksToDisplay) => {
  return {
    type: constants.WEEKS_TO_DISPLAY_UPDATE,
    payload: { newWeeksToDisplay }
  };
};

export const monthsToDisplayUpdate = (newMonthsToDisplay) => {
  return {
    type: constants.MONTHS_TO_DISPLAY_UPDATE,
    payload: { newMonthsToDisplay }
  };
};

export const daysToDisplayUpdate = (newDaysToDisplay) => {
  return {
    type: constants.DAYS_TO_DISPLAY_UPDATE,
    payload: { newDaysToDisplay }
  };
};

export const clearSelectedProject = () => ({
  type: constants.CLEAR_SELECTED_PROJECT
});

export const onFocusSearch = (isSearching, accountIds = []) => {
  return { type: constants.IS_SEARCHING, isSearching, accountIds };
};

export const onSearch = (searchText) => {
  return { type: constants.ON_SEARCH, searchText };
};

export const onGlobalSearch = (token, searchText) => {
  return {
    type: constants.ON_GLOBAL_SEARCH.TRIGGER,
    payload: {
      token,
      searchText
    }
  };
};

export const toggleSearchResults = (allowOpen) => ({
  type: constants.TOGGLE_SEARCH_RESULTS,
  allowOpen
});

export const onClearSearch = (reloadProjects = true) => ({
  type: constants.CLEAR_SEARCH,
  payload: { reloadProjects }
});

export const saveSearchState = (searchTexts) => ({
  type: constants.SAVE_SEARCH_STATE,
  searchTexts
});

export const useSearchState = (pages) => ({
  type: constants.USE_SEARCH_STATE,
  pages
});

export const fetchProjectsByGroup = (params) => {
  // Ensure blanks -> undefined (backend checks for that)
  if (params.searchText === '') {
    params.searchText = undefined;
  }
  if (params.accountIds === '' || params.accountIds === []) {
    params.accountIds = undefined;
  }

  return {
    type: constants.FETCH_PROJECTS_BY_GROUP,
    payload: {
      params
    }
  };
};

export const archiveUnarchiveProject = (params) => {
  const { permissions, UI_CHECK, ...restParams } = params;
  return {
    type: constants.ARCHIVE_UNARCHIVE_PROJECT.TRIGGER,
    payload: { params: restParams, permissions, UI_CHECK }
  };
};

export const batchArchiveUnarchiveProject = (params) => {
  const { permissions, UI_CHECK, ...restParams } = params;
  return {
    type: constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.TRIGGER,
    payload: { params: restParams, permissions, UI_CHECK }
  };
};

export const starProject = (params) => {
  return {
    type: constants.STAR_PROJECT.TRIGGER,
    payload: { params }
  };
};

export const openAddProjectModal = ({
  priorityId,
  isPersonal,
  isQB,
  name,
  subcustomerId,
  useSelectedBoardOverride,
  onSuccessCallback,
  redirectOnSuccess = true
}) => ({
  type: constants.OPEN_ADD_PROJECT_MODAL,
  payload: {
    priorityId,
    isPersonal,
    isQB,
    name,
    subcustomerId,
    useSelectedBoardOverride,
    onSuccessCallback,
    redirectOnSuccess
  }
});

export const openEditProjectModal = (
  id,
  isPersonal,
  useSelectedBoardOverride
) => ({
  type: constants.OPEN_EDIT_PROJECT_MODAL,
  payload: { id, isPersonal, useSelectedBoardOverride }
});

export const closeAddProjectModal = () => ({
  type: constants.CLOSE_ADD_PROJECT_MODAL
});

export const closeEditProjectModal = () => ({
  type: constants.CLOSE_EDIT_PROJECT_MODAL
});

export const closeEditProjectModalMaintainEditingProject = () => ({
  type: constants.CLOSE_EDIT_PROJECT_MODAL_MAINTAIN_EDIT
});

export const createProject = (
  token,
  {
    title,
    description,
    client,
    number,
    billable,
    projectGroupId,
    isActive,
    projectGroupIdToRefresh,
    memberList,
    permissions,
    budget_status,
    budget_sub_status,
    onSuccessCallback,
    redirectOnSuccess = true
  }
) => {
  return {
    type: constants.PROJECT_CREATION.TRIGGER,
    payload: {
      token,
      title,
      description,
      client,
      number,
      billable,
      projectGroupId,
      isActive,
      projectGroupIdToRefresh,
      memberList,
      permissions,
      budget_status,
      budget_sub_status,
      onSuccessCallback,
      redirectOnSuccess
    }
  };
};

export const editProject = (params) =>
  createAction(constants.PROJECT_EDITION.TRIGGER, params);

export const updateProjectBillable = (params) =>
  createAction(constants.UPDATE_PROJECT_BILLABLE.TRIGGER, params);

export const triggerMemberListRefresh = (bool) => ({
  type: constants.TRIGGER_MEMBER_LIST_REFRESH,
  payload: bool
});

export const toggleDeleteProjectModal = (modalState) => ({
  type: constants.TOGGLE_DELETE_PROJECT_MODAL,
  payload: { modalState }
});

export const deleteProject = (params) => {
  return {
    type: constants.DELETE_PROJECT.TRIGGER,
    payload: params
  };
};

export const hardDeleteProject = (params) => {
  return {
    type: constants.HARD_DELETE_PROJECT.TRIGGER,
    payload: params
  };
};

export const updateProjectPosition = (
  token,
  id,
  isActive,
  position,
  projectGroupIdToRefresh,
  wasActive,
  accountIds,
  projectOrderIndex,
  newProjectOrderIndex,
  filterListId,
  noRefresh,
  boardId
) => {
  return {
    type: constants.PROJECT_UPDATE_POSITION.TRIGGER,
    payload: {
      token,
      id,
      isActive,
      position,
      projectGroupIdToRefresh,
      wasActive,
      accountIds,
      projectOrderIndex,
      newProjectOrderIndex,
      filterListId,
      noRefresh,
      boardId
    }
  };
};

export const fetchProjectById = (
  projectId,
  accountIds,
  fromAddEditDeleteProjectStatus,
  limit,
  isScheduledProjects,
  isProjectDetail
) => {
  return {
    type: constants.FETCH_PROJECT_BY_ID,
    payload: {
      projectId,
      accountIds,
      fromAddEditDeleteProjectStatus,
      limit,
      isScheduledProjects,
      isProjectDetail
    }
  };
};

export const filterByMembers = (isFiltering, accountIds) => {
  return { type: constants.FILTER_BY_MEMBERS, isFiltering, accountIds };
};

export const populateCachedProjects = () => {
  return { type: constants.POPULATE_CACHED_PROJECTS };
};

export const fetchCachedProjects = () => ({
  type: constants.FETCH_CACHED_PROJECTS
});

export const clearCachedProjects = () => ({
  type: constants.CLEAR_CACHED_PROJECTS
});

export const clearAllProjects = () => ({
  type: constants.CLEAR_ALL_PROJECTS
});
export const clearBoardProjects = ({ boardId }) =>
  createAction(constants.CLEAR_BOARD_PROJECTS, { boardId });

/* teams */

export const inviteTeamMember = (
  invitees,
  group,
  project,
  inviteeRole,
  permissions,
  actableId,
  actableType
) => {
  return {
    type: constants.INVITE_MEMBER.TRIGGER,
    payload: {
      invitees,
      group,
      project,
      inviteeRole,
      permissions,
      actableId,
      actableType
    }
  };
};

export const inviteQBMembers = (
  invites,
  group,
  project,
  inviteeRole,
  inviteePrivilege,
  permissions,
  actableId,
  actableType
) => {
  return {
    type: constants.INVITE_QB_MEMBERS.TRIGGER,
    payload: {
      invites,
      group,
      project,
      inviteeRole,
      inviteePrivilege,
      permissions,
      actableId,
      actableType
    }
  };
};

export const updateErrorMessage = (newErrorMessage) => {
  return {
    type: constants.UPDATE_ERROR_MESSAGE,
    payload: { newErrorMessage }
  };
};

export const resendInvite = (token, params) => {
  return {
    type: constants.RESEND_INVITE.TRIGGER,
    payload: {
      token,
      params
    }
  };
};

export const validateInvite = (inviteToken) => {
  return {
    type: constants.INVITE_VALIDATION.TRIGGER,
    payload: { inviteToken }
  };
};

export const fetchTeamMembers = ({ isGetTeamMemberMfa } = {}) => ({
  type: constants.TEAM_MEMBERS.TRIGGER,
  payload: { isGetTeamMemberMfa }
});

export const fetchTeamMembersByBoard = (boardId) => ({
  type: constants.FETCH_TEAM_MEMBERS_BY_BOARD.TRIGGER,
  payload: { boardId }
});

export const fetchAllTeamMembersByBoard = () => ({
  type: constants.FETCH_ALL_TEAM_MEMBERS_BY_BOARD.TRIGGER,
  payload: {}
});

export const fetchAllProjectMembers = (params) => {
  return createAction(constants.FETCH_ALL_PROJECT_MEMBERS.TRIGGER, params);
};

export const fetchTeams = rtkCreateAction(constants.FETCH_TEAMS.TRIGGER);

export const closeTeamMemberModal = () => ({
  type: constants.CLOSE_INVITE_TEAM_MEMBER_MODAL
});

export const openTeamMemberModal = () => ({
  type: constants.OPEN_INVITE_TEAM_MEMBER_MODAL
});

// poorly named action creator
export const fetchProjectTeam = (projectId) => ({
  type: constants.FETCH_PROJECT_MEMBERS.TRIGGER,
  payload: { projectId }
});

export const fetchTeamMemberProfile = (params) => ({
  type: constants.FETCH_TEAM_MEMBER_PROFILE.TRIGGER,
  payload: { params }
});

/**
 * use this for member detailed profile (less coupling)
 */
export const fetchTeamMemberDetail = (params) =>
  createAction(constants.FETCH_TEAM_MEMBER_DETAIL.TRIGGER, params);

export const addMemberToProject = (
  projectId,
  accountId,
  projectRole,
  boardId,
  onSuccess
) => ({
  type: constants.ADD_MEMBER_TO_PROJECT.TRIGGER,
  payload: {
    projectId,
    accountId,
    projectRole,
    boardId,
    onSuccess
  }
});

export const addBulkMembersToProject = (params) =>
  createAction(constants.ADD_BULK_MEMBERS_TO_PROJECT.TRIGGER, params);

// necessary due to inability to insert UI_CHECK in above action due to function arity
export const addBulkMembersToProjectPermissionCheck = (params) =>
  createAction(constants.ADD_BULK_MEMBERS_TO_PROJECT.TRIGGER, params);

export const deleteMemberFromProject = ({
  projectId,
  accountId,
  memberIsLoggedUser,
  permissions,
  phaseId,
  memberBudgetId,
  phaseMembershipId
}) => ({
  type: constants.DELETE_MEMBER_FROM_PROJECT.TRIGGER,
  payload: {
    projectId,
    accountId,
    memberIsLoggedUser,
    permissions,
    phaseId,
    memberBudgetId,
    phaseMembershipId
  }
});

export const deleteMemberFromPhase = (params) =>
  createAction(constants.DELETE_MEMBER_FROM_PHASE.TRIGGER, params);

export const updateProjectMemberRole = (token, params) => ({
  type: constants.UPDATE_PROJECT_MEMBER_ROLE.TRIGGER,
  payload: { token, permissions: params.permissions, params }
});
export const updateBoardMemberRole = (token, params) => ({
  type: constants.UPDATE_BOARD_MEMBER_ROLE.TRIGGER,
  payload: { token, permissions: params.permissions, params }
});

/**
 * @deprecated use typesafe and loosely coupled action creator instead
 */
export const updateTeamMembership = (params) =>
  createAction(constants.UPDATE_TEAM_MEMBERSHIP.TRIGGER, params);
export const updateTeamMembershipProfile = (params) =>
  createAction(constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.TRIGGER, params);
export const updateTeamMemberEmailNotification = (params) =>
  createAction(constants.UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION.TRIGGER, params);
export const createTaskStatus = (params) =>
  createAction(constants.CREATE_TASK_STATUS.TRIGGER, params);
export const updateTaskStatus = (params) =>
  createAction(constants.UPDATE_TASK_STATUS.TRIGGER, params);
export const deleteTaskStatus = (params) => {
  return createAction(constants.DELETE_TASK_STATUS.TRIGGER, params);
};

export const fetchTaskPriorities = (params) =>
  createAsyncAction(constants.FETCH_TASK_PRIORITIES.TRIGGER, params);

// below priority code is defunct, remove
export const createTaskPriority = (params) =>
  createAction(constants.CREATE_TASK_PRIORITY.TRIGGER, params);
export const updateTaskPriority = (params) =>
  createAction(constants.UPDATE_TASK_PRIORITY.TRIGGER, params);
export const deleteTaskPriority = (params) => {
  return createAction(constants.DELETE_TASK_PRIORITY.TRIGGER, params);
};

export const createProjectStage = (params) =>
  createAction(constants.CREATE_PROJECT_STAGE.TRIGGER, params);
export const updateProjectStage = (params) =>
  createAction(constants.UPDATE_PROJECT_STAGE.TRIGGER, params);
export const deleteProjectStage = (params) => {
  return createAction(constants.DELETE_PROJECT_STAGE.TRIGGER, params);
};

export const createProjectStatus = (params) =>
  createAction(constants.CREATE_PROJECT_STATUS.TRIGGER, params);
export const updateProjectStatus = (params) =>
  createAction(constants.UPDATE_PROJECT_STATUS.TRIGGER, params);
export const deleteProjectStatus = (params) => {
  return createAction(constants.DELETE_PROJECT_STATUS.TRIGGER, params);
};

export const createProjectPriority = (params) =>
  createAction(constants.CREATE_PROJECT_PRIORITY.TRIGGER, params);
export const updateProjectPriority = (params) =>
  createAction(constants.UPDATE_PROJECT_PRIORITY.TRIGGER, params);
export const deleteProjectPriority = (params) => {
  return createAction(constants.DELETE_PROJECT_PRIORITY.TRIGGER, params);
};

/* status */
export const createProjectComment = (params) =>
  createAction(constants.CREATE_PROJECT_COMMENT.TRIGGER, params);

export const editProjectComment = (params) =>
  createAction(constants.EDIT_PROJECT_COMMENT.TRIGGER, params);

export const deleteProjectComment = (params) =>
  createAction(constants.DELETE_PROJECT_COMMENT.TRIGGER, params);

/* tasks */

export const fetchSingleTask = (params) => {
  return {
    type: constants.FETCH_SINGLE_TASK,
    payload: {
      params
    }
  };
};

export const updateTaskPosition = (params) => {
  const { permissions, ...restParams } = params;
  return {
    type: constants.TASK_UPDATE_POSITION,
    payload: {
      params: restParams, // taskId, projectId, taskType, isCompleted, taskPosition,
      // oldTaskPosition, selectedAccountIds, searchText, oldAllTasks, wasCompleted,
      permissions
    }
  };
};

export const setEditTaskId = (id) => {
  return {
    type: constants.SET_EDIT_TASK,
    payload: id
  };
};

export const fetchTaskGroups = (payload) => ({
  type: constants.FETCH_TASK_GROUPS.TRIGGER,
  payload
});

export const fetchProjectTaskGroups = (payload) => ({
  type: constants.FETCH_PROJECT_TASK_GROUPS.TRIGGER,
  payload
});

export const addTaskGroupToFetchQueue = (payload) => ({
  type: constants.ADD_TASK_GROUP_TO_FETCH_QUEUE,
  payload
});
export const removeTaskGroupFromFetchQueue = (payload) => ({
  type: constants.REMOVE_TASK_GROUP_FROM_FETCH_QUEUE,
  payload
});
export const createProjectTaskGroup = (payload) => ({
  type: constants.CREATE_PROJECT_TASK_GROUP.TRIGGER,
  payload
});
export const updateProjectTaskGroup = (payload) => ({
  type: constants.UPDATE_PROJECT_TASK_GROUP.TRIGGER,
  payload
});
export const deleteProjectTaskGroup = (payload) => ({
  type: constants.DELETE_PROJECT_TASK_GROUP.TRIGGER,
  payload
});

export const reorderProjectTaskGroups = (payload) => ({
  type: constants.REORDER_PROJECT_TASK_GROUPS.TRIGGER,
  payload
});
export const toggleTaskGroupCollapse = (payload) => ({
  type: constants.TOGGLE_TASK_GROUP,
  payload
});
export const toggleTaskGroupCollapseAll = (payload) => ({
  type: constants.TOGGLE_TASK_GROUP_ALL,
  payload
});
export const fetchProjectTaskGroupTasks = (payload) => ({
  type: constants.FETCH_PROJECT_TASK_GROUP_TASKS.TRIGGER,
  payload
});
/* notes */

export const openAddNoteModal = () => ({
  type: constants.OPEN_ADD_NOTE_MODAL
});

export const closeAddNoteModal = () => ({
  type: constants.CLOSE_ADD_NOTE_MODAL
});

export const openEditNoteModal = (noteId) => ({
  type: constants.OPEN_EDIT_NOTE_MODAL,
  payload: { noteId }
});

export const closeEditNoteModal = () => ({
  type: constants.CLOSE_EDIT_NOTE_MODAL
});

export const openDeleteNoteModal = (token) => ({
  type: constants.OPEN_DELETE_NOTE_MODAL
});

export const closeDeleteNoteModal = () => ({
  type: constants.CLOSE_DELETE_NOTE_MODAL
});

export const clearNotes = () => ({ type: constants.CLEAR_NOTES });

/* tags */
export const fetchNoteTagsByBoard = (params) =>
  createAction(constants.FETCH_NOTE_TAGS_BY_BOARD.TRIGGER, params);

export const setFilterTagId = (id) => {
  return {
    type: constants.SET_FILTER_TAG_ID,
    id
  };
};

export const updateTag = (params) =>
  createAction(constants.UPDATE_TAG.TRIGGER, params);

/* home */

export const setHomeOrigin = (isHomeOrigin) => ({
  type: constants.SET_HOME_ORIGIN,
  isHomeOrigin
});

export const setEnableChangeProject = (isEnabled) => ({
  type: constants.SET_ENABLE_CHANGE_PROJECT,
  isEnabled
});

export const setVideoModalVisited = () => ({
  type: constants.SET_VIDEO_MODAL_VISITED
});

/* settings */
export const updateProfile = (
  token,
  formData,
  isInvite = null,
  isSetup = null
) => ({
  type: constants.PROFILE_UPDATE.TRIGGER,
  payload: { token, formData, isInvite, isSetup }
});

export const updatePassword = (token, password, firstName, lastName) => {
  return {
    type: constants.PASSWORD_UPDATE.TRIGGER,
    payload: { token, password, firstName, lastName }
  };
};

export const updateSettingsPassword = (params) =>
  createAction(constants.PASSWORD_SETTINGS_UPDATE.TRIGGER, params);

export const clearResetPasswordForm = () => ({
  type: constants.PASSWORD_FORGOT_FORM_RESET,
  payload: {}
});

export const updateTeam = (payload) => ({
  type: constants.TEAM_UPDATE.TRIGGER,
  payload
});

export const updateTeamOrderedItems = (payload) =>
  createAction(constants.UPDATE_TEAM_ORDERED_ITEMS.TRIGGER, payload);

/**
 * @deprecated Use the typesafe version from `TeamsModule/actionCreators`.
 */
export const updateTeamViewSettings = (payload) =>
  createAction(constants.UPDATE_TEAM_VIEW_SETTINGS.TRIGGER, payload);

export const clearErrorMessage = () => ({
  type: constants.CLEAR_ERROR_MESSAGE
});

/* BILLING */
export const fetchCurrentSubscription = (token) => ({
  type: constants.SUBSCRIPTION_FETCH.TRIGGER,
  payload: { token }
});

export const enterUpdateCard = () => ({ type: constants.ENTER_UPDATE_CARD });

export const exitUpdateCard = () => ({ type: constants.EXIT_UPDATE_CARD });

export const updatePlanPeriod = (plan) => ({
  type: constants.PLAN_PERIOD_UPDATE,
  payload: { plan: plan }
});

export const cancelSubscription = (token) => ({
  type: constants.SUBSCRIPTION_CANCELLATION.TRIGGER,
  payload: { token }
});

export const fetchMyProjects = (token, params) => {
  return {
    type: constants.FETCH_MY_PROJECTS.TRIGGER,
    payload: {
      token,
      params
    }
  };
};

export const fetchProjectClients = ({ teamId }) => {
  return {
    type: constants.FETCH_PROJECT_CLIENTS.TRIGGER,
    payload: {
      teamId
    }
  };
};
export const fetchAllProjects = (params = {}) => {
  const {
    requestStatusId,
    initial,
    filterListId,
    takeLatest,
    meta,
    ...payload
  } = params;
  return {
    type: constants.FETCH_ALL_PROJECTS.TRIGGER,
    payload: {
      requestStatusId,
      initial,
      takeLatest,
      filterListId,
      params: payload
    },
    meta
  };
};
export const fetchProjectsIndexV2 = (params = {}) =>
  appCreateAction(constants.FETCH_PROJECTS_INDEX_V2.TRIGGER, params);
export const resetProjectFilterList = (params = {}) =>
  createAction(constants.RESET_PROJECT_FILTER_LIST, params);

export const updateMyProject = (params) => {
  return {
    type: constants.MY_PROJECT_UPDATE.TRIGGER,
    payload: {
      params
    }
  };
};

export const deleteProjectFromOrder = (params) =>
  createAction(constants.DELETE_PROJECT_FROM_ORDER, params);

export const loadWithSidebarOpen = (openSidebar, scrollTop) => ({
  type: constants.LOAD_WITH_SIDEBAR_OPEN,
  payload: {
    openSidebar,
    scrollTop
  }
});

export const toggleHelpMenu = (isOpen) => {
  return {
    type: constants.TOGGLE_HELP_MENU,
    isOpen
  };
};

export const toggleEmailNotification = (
  token,
  accountId,
  isEmailNotificationOn
) => {
  return {
    type: constants.TOGGLE_EMAIL_NOTIFICATION.TRIGGER,
    payload: {
      token,
      isEmailNotificationOn,
      accountId
    }
  };
};

export const setBoardSortProperty = (params) =>
  createAction(constants.SET_BOARD_SORT_PROPERTY, params);

/* milestones */

export const addNewMilestoneTemplate = (params) => ({
  type: constants.ADD_NEW_MILESTONE_TEMPLATE,
  payload: params
});

export const removeNewMilestone = (params) => ({
  type: constants.REMOVE_NEW_MILESTONE,
  payload: params
});

export const setEditMilestoneId = (id) => ({
  type: constants.SET_EDIT_MILESTONE_ID,
  payload: id
});
export const setDeleteMilestoneId = (id) => ({
  type: constants.SET_DELETE_MILESTONE_ID,
  payload: id
});

export const openMilestoneModal = (params) => ({
  type: constants.OPEN_MILESTONE_MODAL,
  payload: params
});

export const closeMilestoneModal = () => ({
  type: constants.CLOSE_MILESTONE_MODAL
});

export const openTimerDrawer = () => ({
  type: constants.OPEN_TIMER_DRAWER
});

export const closeTimerDrawer = () => ({
  type: constants.CLOSE_TIMER_DRAWER
});

export const openTimerDeleteModal = ({ timerToDelete }) => ({
  type: constants.OPEN_TIMER_DELETE_MODAL,
  timerToDelete
});

export const closeTimerDeleteModal = () => ({
  type: constants.CLOSE_TIMER_DELETE_MODAL
});

export const setSelectedProject = ({ projectId, project }) => ({
  type: constants.SET_SELECTED_PROJECT,
  projectId,
  project
});

export const clearSelectedTeamMember = () => ({
  type: constants.CLEAR_SELECTED_TEAM_MEMBER
});

export const fetchCommentsAndMetadata = (params) => ({
  type: constants.FETCH_COMMENTS_AND_METADATA.TRIGGER,
  payload: {
    ...params
  }
});

export const createComment = (token, params) => ({
  type: constants.CREATE_COMMENT.TRIGGER,
  payload: {
    token,
    ...params
  }
});

export const updateComment = (token, params) => ({
  type: constants.UPDATE_COMMENT.TRIGGER,
  payload: {
    token,
    ...params
  }
});

export const deleteComment = (token, params) => ({
  type: constants.DELETE_COMMENT.TRIGGER,
  payload: {
    token,
    ...params
  }
});

export const deleteTaskAttachment = (token, attachmentId) => ({
  type: constants.DELETE_TASK_ATTACHMENT.TRIGGER,
  payload: {
    token,
    attachmentId
  }
});

export const deleteProjectAttachment = (token, attachmentId) => ({
  type: constants.DELETE_PROJECT_ATTACHMENT.TRIGGER,
  payload: {
    token,
    attachmentId
  }
});

export const bulkCreateCheckin = (params) =>
  createAction(constants.BULK_CREATE_CHECKIN.TRIGGER, params);

export const createCheckin = (params) =>
  createAction(constants.CREATE_CHECKIN.TRIGGER, params);

export const fetchCheckins = (params) =>
  createAction(constants.FETCH_CHECKINS.TRIGGER, params);

export const updateCheckin = (params) =>
  createAction(constants.UPDATE_CHECKIN.TRIGGER, params);

export const deleteCheckin = (params) =>
  createAction(constants.DELETE_CHECKIN.TRIGGER, params);

export const fetchCheckinsHours = (params) =>
  createAction(constants.FETCH_CHECKINS_HOURS.TRIGGER, params);

export const fetchTimers = (params) =>
  createAction(constants.FETCH_TIMERS.TRIGGER, params);

export const fetchActiveTimer = (params) =>
  createAction(constants.FETCH_ACTIVE_TIMER.TRIGGER, params);

export const startTimer = (params) =>
  createAction(constants.START_TIMER.TRIGGER, params);

export const endTimer = (params) =>
  createAction(constants.END_TIMER.TRIGGER, params);

export const updateTimer = (params) =>
  createAction(constants.UPDATE_TIMER.TRIGGER, params);

export const deleteTimer = (params) =>
  createAction(constants.DELETE_TIMER.TRIGGER, params);

export const associateTask = (params) =>
  createAction(constants.ASSOCIATE_TASK.TRIGGER, params);

export const setTimerDate = (params) =>
  createAction(constants.SET_TIMER_DATE, params);

export const fetchNotifications = (params) =>
  createAction(constants.FETCH_NOTIFICATIONS.TRIGGER, params);

export const fetchNewNotificationsCount = (params) =>
  createAction(constants.FETCH_NOTIFICATIONS_COUNT.TRIGGER, params);

export const updateNotificationAsRead = (params) =>
  createAction(constants.UPDATE_NOTIFICATION_AS_READ.TRIGGER, params);

export const markAllNotificationsRead = () =>
  createAction(constants.MARK_ALL_NOTIFICATIONS_READ.TRIGGER);

export const updateNotificationsAsOld = (params) =>
  createAction(constants.UPDATE_NOTIFICATIONS_AS_OLD.TRIGGER, params);

export const closeAndClearTaskViewModal = (params) =>
  createAction(constants.CLOSE_CLEAR_TASK_VIEW_MODAL, params);

export const closeAndClearMemberModal = (params) =>
  createAction(constants.CLOSE_CLEAR_MEMBER_MODAL, params);

export const setSelectedTask = (taskId) => ({
  type: constants.SET_SELECTED_TASK,
  taskId
});

export const openAddMembersForm = (modalType, project, formLocation) => ({
  type: constants.OPEN_ADD_MEMBERS_MODAL,
  payload: {
    modalType,
    project,
    formLocation
  }
});

export const openBulkAddMembersForm = () => ({
  type: constants.OPEN_BULK_ADD_MEMBERS_MODAL
});

export const closeBulkAddMembersForm = () => ({
  type: constants.CLOSE_BULK_ADD_MEMBERS_MODAL
});
export const closeAddMembersForm = () => ({
  type: constants.CLOSE_ADD_MEMBERS_MODAL
});

export const populateBulkAddMembers = () =>
  createAction(constants.POPULATE_BULK_ADD_MEMBERS);

export const emptyBulkAddMembers = () =>
  createAction(constants.EMPTY_BULK_ADD_MEMBERS);

export const openProjectModal = (params) =>
  createAction(constants.OPEN_PROJECT_MODAL, params);
export const closeProjectModal = () =>
  createAction(constants.CLOSE_PROJECT_MODAL);
// Planner
export const toggleBulkUpdateModal = (params) =>
  createAction(constants.TOGGLE_BULK_UPDATE_MODAL, params);

export const clearRemovedTasks = () =>
  createAction(constants.CLEAR_REMOVED_TASKS);
export const clearRemovedTasksTrigger = () =>
  createAction(constants.CLEAR_REMOVED_TASKS_TRIGGER);

export const updateEditedTasks = (params) =>
  createAction(constants.UPDATE_EDITED_TASKS, params);

export const updateEditedHomeTasks = (params) =>
  createAction(constants.UPDATE_EDITED_HOME_TASKS, params);

export const fetchDayPlanner = (token, params) => {
  return {
    type: constants.FETCH_DAY_PLANNER.TRIGGER,
    payload: { token, params }
  };
};

export const updateDayPlanner = (token, params) => ({
  type: constants.UPDATE_DAY_PLANNER.TRIGGER,
  payload: { token, params }
});

export const setPlannerDateHeader = (params) =>
  createAction(constants.SET_PLANNER_DATE_HEADER, params);

export const fetchPlanners = (token, params) => ({
  type: constants.FETCH_PLANNERS.TRIGGER,
  payload: { token, params }
});

export const bulkEditTasks = (params) =>
  createAction(constants.BULK_EDIT_TASKS.TRIGGER, params);

export const bulkEditHomeTasks = (params) =>
  createAction(constants.BULK_EDIT_HOME_TASKS.TRIGGER, params);

export const triggerTasksAttributesUpdate = ({
  token,
  body,
  options,
  permissions,
  onSuccess = []
}) => ({
  type: constants.UPDATE_TASKS_ATTRIBUTES.TRIGGER,
  payload: {
    token,
    body,
    options,
    permissions,
    onSuccess
  }
});

export const triggerTaskCreationServerRequest = ({
  token,
  body,
  permissions,
  shouldOpenModalAfterCreate
}) => ({
  type: constants.CREATE_TASK_FROM_HOME.TRIGGER,
  payload: {
    token,
    body,
    permissions,
    shouldOpenModalAfterCreate
  }
});

export const moveTasks = (params) =>
  createAction(constants.MOVE_TASKS.TRIGGER, params);
export const copyTasks = (params) =>
  createAction(constants.COPY_TASKS.TRIGGER, params);

export const queryAI = (params) =>
  createAction(constants.QUERY_AI.TRIGGER, params);

export const createTaskAndOpenModal = (params) =>
  createAction(constants.CREATE_TASK_AND_OPEN_MODAL, params);

export const updateTaskSortOrder = ({ sort, direction = 'asc' }) => ({
  type: constants.UPDATE_HOME_TASK_SORT_ORDER,
  payload: {
    sort,
    direction
  }
});

export const triggerFetchInitialTasks = (params) => ({
  type: constants.TASK_LIST_LOADING_INITIAL_TASKS.TRIGGER,
  payload: params
});

export const triggerFetchAdditionalTasks = (params) => ({
  type: constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.TRIGGER,
  payload: params
});

// UPDATE DATA
export const triggerTaskPositionResort = ({ token, params }) => ({
  type: constants.UPDATE_TASK_HOME_POSITION,
  payload: {
    token,
    ...params
  }
});

export const insertTaskBefore = ({ taskId, taskBeforeId }) =>
  createAction(constants.INSERT_TASK_BEFORE, { taskId, taskBeforeId });

export const moveTaskToGroup = ({
  sourceTaskGroupId,
  destinationTaskGroupId,
  taskId,
  index,
  groupType,
  changedTaskAttributes // anticipated side effects of moving to a different taskGroup / activityPhase
}) => ({
  type: constants.MOVE_TASK_TO_GROUP,
  payload: {
    sourceTaskGroupId,
    destinationTaskGroupId,
    taskId,
    index,
    groupType,
    changedTaskAttributes
  }
});

export const removeTaskFromGroup = ({ taskGroupId, taskId, groupType }) => ({
  type: constants.REMOVE_TASK_FROM_GROUP,
  payload: {
    taskGroupId,
    taskId,
    groupType
  }
});

export const addTaskToGroup = ({
  taskGroupId,
  taskId,
  index,
  groupType,
  changedTaskAttributes
}) => ({
  type: constants.ADD_TASK_TO_GROUP,
  payload: {
    taskGroupId,
    taskId,
    index,
    groupType,
    changedTaskAttributes
  }
});
export const triggerBatchInboxTaskAcceptance = ({ token, body }) => ({
  type: constants.BATCH_MOVE_INBOX_TASKS.TRIGGER,
  payload: {
    token,
    updateType: 'inbox_acceptance',
    body
  }
});

export const triggerBatchTasksFollowUp = ({ token, params }) => ({
  type: constants.BATCH_REQUEST_TASKS_FOLLOW_UP.TRIGGER,
  payload: {
    token,
    updateType: 'follow_up',
    ...params
  }
});

export const triggerBatchMoveTasksToTop = ({ token, body }) => ({
  type: constants.BATCH_MOVE_TASKS_TO_PROJECT.TRIGGER,
  payload: {
    token,
    updateType: 'move_to_top',
    body
  }
});

export const triggerBatchCopyTasksToProject = ({ token, body, options }) => ({
  type: constants.BATCH_COPY_TASKS_TO_PROJECT.TRIGGER,
  payload: {
    token,
    updateType: 'copy',
    body,
    options
  }
});

export const triggerBatchRevertTasksToIncomplete = ({ token, params }) => ({
  type: constants.BATCH_REVERT_TASKS_TO_INCOMPLETE.TRIGGER,
  payload: {
    token,
    updateType: 'revert',
    ...params
  }
});

export const triggerBatchDeleteTasks = ({ token, body }) => ({
  type: constants.BATCH_DELETE_TASKS.TRIGGER,
  payload: {
    token,
    updateType: 'delete',
    body
  }
});

export const triggerBatchUnfollowTasks = ({ token, body }) => ({
  type: constants.BATCH_UNFOLLOW_TASKS.TRIGGER,
  payload: {
    token,
    updateType: 'unfollow',
    body
  }
});

export const triggerBatchComplete = ({ token, body }) => ({
  type: constants.BATCH_COMPLETE_TASKS.TRIGGER,
  payload: {
    token,
    updateType: 'complete',
    body
  }
});

export const toggleTaskCreation = () =>
  createAction(constants.TOGGLE_TASK_CREATION);

export const triggerTaskCompletionToggle = ({
  taskId,
  isCompleting,
  permissions,
  doNotRemoveTask
}) => ({
  type: constants.HANDLE_HOME_TASK_COMPLETION_TOGGLE,
  payload: {
    taskId,
    isCompleting,
    permissions,
    doNotRemoveTask
  }
});

export const handleTaskGroupsTasksCompletionToggle = (payload) => ({
  type: constants.HANDLE_TASK_GROUPS_TASK_COMPLETION_TOGGLE,
  payload
});

export const triggerTaskStoreFlush = () => ({
  type: constants.FLUSH_TASK_LIST_STORE
});

export const toggleAppSiderMenu = () => ({
  type: constants.TOGGLE_APP_SIDER_MENU
});
export const openGlobalAdd = () => createAction(constants.OPEN_GLOBAL_ADD);
export const closeGlobalAdd = () => createAction(constants.CLOSE_GLOBAL_ADD);
export const openTaskGlobalAdd = () =>
  createAction(constants.OPEN_TASK_GLOBAL_ADD);
export const closeTaskGlobalAdd = () =>
  createAction(constants.CLOSE_TASK_GLOBAL_ADD);

export const setReloaderScreen = () => ({
  type: constants.SET_RELOADER_SCREEN
});

export const setAppSiderMenuVisibility = ({ appSiderMenuIsVisible }) => ({
  type: constants.SET_APP_SIDER_MENU_VISIBILITY,
  payload: {
    appSiderMenuIsVisible
  }
});

export const setSelectedHomeTask = (taskId) => ({
  type: constants.SET_SELECTED_HOME_TASK,
  taskId
});

export const flushSelectedHomeTask = (taskId) => ({
  type: constants.FLUSH_SELECTED_HOME_TASK
});

export const refreshCurrentlyFetchedHomeTasks = () => ({
  type: constants.REFRESH_CURRENTLY_SELECTED_HOME_TASKS.TRIGGER
});

export const fetchAllHomeTaskTags = (params) =>
  createAction(constants.FETCH_ALL_HOME_TASK_TAGS.TRIGGER, params);

export const triggerTaskCommentCreation = ({ token, body }) => ({
  type: constants.POST_TASK_COMMENT.TRIGGER,
  payload: {
    token,
    body
  }
});

export const triggerAccountFollowUpMessageUpdate = ({ token, body }) => ({
  type: constants.PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE.TRIGGER,
  payload: {
    token,
    body
  }
});

export const setCurrentTaskListFilter = (params) =>
  createAction(constants.TASK_LIST_FILTER_CHANGE, params);

export const updateNotificationPreferences = (params) =>
  createAction(constants.UPDATE_NOTIFICATION_PREFERENCES.TRIGGER, params);

export const setSelectedTeamMember = (params) =>
  createAction(constants.SET_SELECTED_TEAM_MEMBER, params);
export const openActivityRowDropdown = (params) =>
  createAction(constants.OPEN_ACTIVITY_ROW_DROPDOWN, params);
export const closeActivityRowDropdown = (params) =>
  createAction(constants.CLOSE_ACTIVITY_ROW_DROPDOWN, params);
export const openAddEditActivity = (params) =>
  createAction(constants.OPEN_ADD_EDIT_ACTIVITY, params);
export const closeAddEditActivity = (params) =>
  createAction(constants.CLOSE_ADD_EDIT_ACTIVITY, params);
export const openAddEditDescription = (params) =>
  createAction(constants.OPEN_ADD_EDIT_DESCRIPTION, params);
export const closeAddEditDescription = () =>
  createAction(constants.CLOSE_ADD_EDIT_DESCRIPTION);
export const openEditActivityMenu = (params) =>
  createAction(constants.OPEN_EDIT_ACTIVITY_ROW_MENU, params);
export const closeEditActivityMenu = (params) =>
  createAction(constants.CLOSE_EDIT_ACTIVITY_ROW_MENU, params);
export const updateActivityRowDescription = (params) =>
  createAction(constants.UPDATE_ACTIVITY_ROW_DESCRIPTION, params);
export const createTimesheet = (params) =>
  createAction(constants.CREATE_TIMESHEET.TRIGGER, params);
export const updateTimesheet = (params) =>
  createAction(constants.UPDATE_TIMESHEET.TRIGGER, params);
export const bulkUpdateTimesheets = (params) =>
  createAction(constants.BULK_UPDATE_TIMESHEETS.TRIGGER, params);
export const deleteTimesheets = (params) =>
  createAction(constants.DELETE_TIMESHEETS.TRIGGER, params);
export const toggleFlickerForActivityCell = (params) =>
  createAction(constants.TOGGLE_FLICKER_ACTIVITY_CELL, params);
export const toggleFlickerForTaskCell = (params) =>
  createAction(constants.TOGGLE_FLICKER_TASK_CELL, params);
export const openTimesheetActivityModalForDay = (params) =>
  createAction(constants.OPEN_TIMESHEET_ACTIVITY_MODAL_FOR_DAY, params);
export const closeTimesheetActivityModalForDay = (params) =>
  createAction(constants.CLOSE_TIMESHEET_ACTIVITY_MODAL_FOR_DAY, params);

export const submitDates = (params) =>
  createAction(constants.SUBMIT_DATES, params);
export const setSubmissionStatus = (params) =>
  createAction(constants.SET_SUBMISSION_STATUS, params);
export const deleteActivityRow = (params) =>
  createAction(constants.DELETE_ACTIVITY_ROW, params);
export const deleteActivityRowAsk = (params) =>
  createAction(constants.DELETE_ACTIVITY_ROW_ASK, params);
export const deleteActivityRowCancel = () =>
  createAction(constants.DELETE_ACTIVITY_ROW_CANCEL);
export const fetchActivities = (params) =>
  createAction(constants.FETCH_ACTIVITIES.TRIGGER, params);
export const fetchDescriptions = (params) =>
  createAction(constants.FETCH_DESCRIPTIONS.TRIGGER, params);
export const fetchFilteredActivitiesPerProject = (params) =>
  createAction(
    constants.FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS.TRIGGER,
    params
  );
export const fetchTimesheets = (params) =>
  createAction(constants.FETCH_TIMESHEETS.TRIGGER, params);
export const fetchRemainingTimesheetHours = (params) =>
  createAction(constants.FETCH_REMAINING_TIMESHEET_HOURS.TRIGGER, params);

export const fetchUserActivities = (params) =>
  createAction(constants.FETCH_USER_ACTIVITIES.TRIGGER, params);
export const fetchTimesheetsIndex = (params) =>
  createAction(constants.FETCH_TIMESHEETS_INDEX.TRIGGER, params);
export const timeEntryCreateUpdateError = (params) =>
  createAction(constants.TIME_ENTRY_CREATE_UPDATE_ERROR, params);
export const clearTimeEntryError = (params) =>
  createAction(constants.CLEAR_TIME_ENTRY_ERROR, params);
export const fetchTimesheetsUtilization = (params) =>
  createAction(constants.FETCH_TIMESHEETS_UTILIZATION.TRIGGER, params);
export const processTimeEntriesForQuickbooks = (params) =>
  createAction(constants.PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS.TRIGGER, params);
export const exportTimesheets = (params) =>
  createAction(constants.EXPORT_TIMESHEETS.TRIGGER, params);
export const mountTimesheet = ({ time }) =>
  createAction(constants.MOUNT_TIMESHEET, { time });

export const batchSelectTimesheets = (params) =>
  createAction(constants.BATCH_SELECT_TIMESHEETS, params);
export const clearBatchSelectedTimesheets = () =>
  createAction(constants.CLEAR_BATCH_SELECTED_TIMESHEETS);
export const sortTimesheetReport = (params) =>
  createAction(constants.SORT_TIMESHEET_REPORT, params);
export const setTimesheetReportDateRange = (params) =>
  createAction(constants.SET_TIMESHEET_REPORT_DATE_RANGE, params);
export const setTimesheetReminderDay = (params) =>
  createAction(constants.SET_TIMESHEET_REMINDER_DAY, params);
export const setTimesheetReminderSenderId = (params) =>
  createAction(constants.SET_TIMESHEET_REMINDER_SENDER_ID, params);
export const setIsAddingNewTimesheetRow = (params) =>
  createAction(constants.SET_IS_ADDING_NEW_TIMESHEET_ROW, params);
export const setIncompleteTimesheetDates = (params) =>
  createAction(constants.SET_INCOMPLETE_TIMESHEET_DATES, params);
export const clearIsAddingNewTimesheetRow = () =>
  createAction(constants.CLEAR_IS_ADDING_NEW_TIMESHEET_ROW);
export const updateCreateRowData = (params) =>
  createAction(constants.UPDATE_CREATE_ROW_DATA, params);

export const selectSubmissionDates = (params) =>
  createAction(constants.SELECT_SUBMISSION_DATES, params);
export const unselectSubmissionDates = (params) =>
  createAction(constants.UNSELECT_SUBMISSION_DATES, params);
export const flushSubmissionDates = (params) =>
  createAction(constants.FLUSH_SUBMISSION_DATES, params);
export const showTotals = () => createAction(constants.SHOW_TOTALS);
export const hideTotals = () => createAction(constants.HIDE_TOTALS);
export const flushActivities = () => createAction(constants.FLUSH_ACTIVITIES);
export const flushTimesheets = () => createAction(constants.FLUSH_TIMESHEETS);
export const flushScheduleBars = () =>
  createAction(constants.FLUSH_SCHEDULE_BARS);
export const flushDescriptions = () =>
  createAction(constants.FLUSH_DESCRIPTIONS);
export const flushUserActivities = () =>
  createAction(constants.FLUSH_USER_ACTIVITIES);

export const setShowTimesheetMovedMessage = (params) =>
  createAction(constants.SET_SHOW_TIMESHEET_MOVED_MESSAGE, params);
export const setShowUpdatesMovedMessage = (params) =>
  createAction(constants.SET_SHOW_UPDATES_MOVED_MESSAGE, params);

export const createActivity = (params) =>
  createAction(constants.CREATE_ACTIVITY.TRIGGER, params);
export const updateActivity = (params) =>
  createAction(constants.UPDATE_ACTIVITY.TRIGGER, params);
export const createDescription = (params) =>
  createAction(constants.CREATE_DESCRIPTION.TRIGGER, params);
export const updateDescription = (params) =>
  createAction(constants.UPDATE_DESCRIPTION.TRIGGER, params);
export const deleteDescriptions = (params) =>
  createAction(constants.DELETE_DESCRIPTIONS.TRIGGER, params);
export const fetchActivityMembers = (params) =>
  createAction(constants.FETCH_ACTIVITY_MEMBERS.TRIGGER, params);
export const createActivityMembers = (params) =>
  createAction(constants.CREATE_ACTIVITY_MEMBERS.TRIGGER, params);
export const archiveActivityMember = (params) =>
  createAction(constants.ARCHIVE_ACTIVITY_MEMBER.TRIGGER, params);

export const setTaskAccountFilter = (params) =>
  createAction(constants.SET_TASK_ACCOUNT_FILTER, params);

export const resetTaskFilters = () =>
  createAction(constants.RESET_TASK_FILTERS);
export const setActiveTaskFilter = (params) =>
  createAction(constants.SET_ACTIVE_TASK_FILTER, params);
export const setMemberModalTaskFilter = (params) =>
  createAction(constants.SET_MEMBER_MODAL_TASK_FILTER, params);
export const setTaskViewBy = (params) =>
  createAction(constants.SET_TASK_VIEW_BY, params);

export const openFlaggedTasksModal = (params) =>
  createAction(constants.OPEN_FLAGGED_TASKS_MODAL, params);
export const closePastDueTaskModal = () =>
  createAction(constants.CLOSE_PAST_DUE_TASK_MODAL);
export const closeHomeTaskModal = () =>
  createAction(constants.CLOSE_HOME_TASK_MODAL);

export const openColorPicker = (params) =>
  createAction(constants.OPEN_COLOR_PICKER, params);

export const closeColorPicker = () =>
  createAction(constants.CLOSE_COLOR_PICKER);

export const fetchProjectColorPreferences = () =>
  createAction(constants.FETCH_PROJECT_COLOR_PREFERENCES.TRIGGER);
export const createProjectColorPreference = (params) =>
  createAction(constants.CREATE_PROJECT_COLOR_PREFERENCE.TRIGGER, params);
export const updateProjectColorPreference = (params) =>
  createAction(constants.UPDATE_PROJECT_COLOR_PREFERENCE.TRIGGER, params);
export const deleteProjectColorPreference = (params) =>
  createAction(constants.DELETE_PROJECT_COLOR_PREFERENCE.TRIGGER, params);

export const fetchBoardPreferences = () =>
  createAsyncAction(constants.FETCH_BOARD_PREFERENCES.TRIGGER);
export const createBoardPreference = (params) =>
  createAction(constants.CREATE_BOARD_PREFERENCE.TRIGGER, params);
export const updateBoardPreference = (params) =>
  createAction(constants.UPDATE_BOARD_PREFERENCE.TRIGGER, params);
export const deleteBoardPreference = (params) =>
  createAction(constants.DELETE_BOARD_PREFERENCE.TRIGGER, params);

export const fetchTaskGroupPreferences = () =>
  createAsyncAction(constants.FETCH_TASK_GROUP_PREFERENCES.TRIGGER);
export const createTaskGroupPreference = (params) =>
  createAction(constants.CREATE_TASK_GROUP_PREFERENCE.TRIGGER, params);
export const updateTaskGroupPreference = (params) =>
  createAction(constants.UPDATE_TASK_GROUP_PREFERENCE.TRIGGER, params);
export const deleteTaskGroupPreference = (params) =>
  createAction(constants.DELETE_TASK_GROUP_PREFERENCE.TRIGGER, params);

export const fetchTeamMembershipPreferences = () =>
  createAsyncAction(constants.FETCH_TEAM_MEMBERSHIP_PREFERENCES.TRIGGER);
export const createTeamMembershipPreference = (params) =>
  createAction(constants.CREATE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER, params);
export const updateTeamMembershipPreference = (params) =>
  createAction(constants.UPDATE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER, params);
export const deleteTeamMembershipPreference = (params) =>
  createAction(constants.DELETE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER, params);

export const fetchTeamPreferences = () =>
  createAsyncAction(constants.FETCH_TEAM_PREFERENCES.TRIGGER);
export const createTeamPreference = (params) =>
  createAction(constants.CREATE_TEAM_PREFERENCE.TRIGGER, params);
export const updateTeamPreference = (params) =>
  createAction(constants.UPDATE_TEAM_PREFERENCE.TRIGGER, params);
export const deleteTeamPreference = (params) =>
  createAction(constants.DELETE_TEAM_PREFERENCE.TRIGGER, params);

export const fetchTagPreferences = () =>
  createAsyncAction(constants.FETCH_TAG_PREFERENCES.TRIGGER);
export const createTagPreference = (params) =>
  createAction(constants.CREATE_TAG_PREFERENCE.TRIGGER, params);
export const updateTagPreference = (params) =>
  createAction(constants.UPDATE_TAG_PREFERENCE.TRIGGER, params);
export const deleteTagPreference = (params) =>
  createAction(constants.DELETE_TAG_PREFERENCE.TRIGGER, params);

export const setHomeTaskEditProperty = (params) =>
  createAction(constants.SET_HOME_TASK_EDIT_PROPERTY, params);

export const updateProjectModules = (params) =>
  createAction(constants.UPDATE_PROJECT_MODULES.TRIGGER, params);

export const updateBoardModules = (params) =>
  createAction(constants.UPDATE_BOARD_MODULES.TRIGGER, params);

export const setFromBoardViewNavText = () =>
  createAction(constants.SET_FROM_BOARD_VIEW_NAV_TEXT);

export const clearFromBoardViewNavText = () =>
  createAction(constants.CLEAR_FROM_BOARD_VIEW_NAV_TEXT);

export const setBatchSelectedTasks = (params) =>
  createAction(constants.SET_BATCH_SELECTED_TASKS, params);
export const clearBatchSelectedTasks = (params) =>
  createAction(constants.CLEAR_BATCH_SELECTED_TASKS, params);
export const toggleBatchSelectedTasks = (params) =>
  createAction(constants.TOGGLE_BATCH_SELECTED_TASKS, params);
export const flushBatchSelectedTasks = () =>
  createAction(constants.FLUSH_BATCH_SELECTED_TASKS);

export const denyPermission = (action) =>
  createAction(constants.DENY_PERMISSION, action);
export const handleErrorMessage = (action) =>
  createAction(constants.HANDLE_ERROR_MESSAGE, action);
export const clearDenyPermission = () =>
  createAction(constants.CLEAR_DENY_PERMISSION);
export const fetchUserPermissions = () =>
  createAction(constants.FETCH_USER_PERMISSIONS.TRIGGER);

export const removeProjectSubscriptions = (params) =>
  createWSRemovalAction(constants.REMOVE_PROJECT_SUBSCRIPTIONS, params, [
    'HomeProjectTaskChannel',
    'ProjectStatusChannel',
    'TaskCommentChannel',
    'ProjectNoteChannel'
  ]);
export const removeBoardSubscriptions = (params) =>
  createWSRemovalAction(constants.REMOVE_BOARD_SUBSCRIPTIONS, params, [
    'ProjectChannel'
  ]);
export const removeWorkloadSubscriptions = (params) =>
  createWSRemovalAction(constants.REMOVE_WORKLOAD_SUBSCRIPTIONS, params, [
    'WorkloadChannel',
    'ScheduledEventChannel',
    'PhaseWorkloadChannel',
    'HolidayChannel',
    'PositionChannel',
    'TeamPositionChannel',
    'TeamSkillChannel',
    'TeamMemberSkillChannel',
    'WorkGroupMembershipChannel'
  ]);
export const removeAttachmentSubscriptions = (params) =>
  createWSRemovalAction(constants.REMOVE_ATTACHMENT_SUBSCRIPTIONS, params, [
    'AttachmentChannel'
  ]);

export const subscribeWorkload = (params) =>
  createAction(constants.SUBSCRIBE_WORKLOAD, params);

export const wsTask = (payload) => ({ type: constants.WS_TASK, payload });
export const wsProjectMember = (payload) => ({
  type: constants.WS_PROJECT_MEMBER,
  payload
});
export const wsProjectNote = (payload) => ({
  type: constants.WS_PROJECT_NOTE,
  payload
});
export const wsProjectComment = (payload) => ({
  type: constants.WS_PROJECT_COMMENT,
  payload
});
export const wsTaskComment = (payload) => ({
  type: constants.WS_TASK_COMMENT,
  payload
});
export const wsProject = (payload) => ({
  type: constants.WS_PROJECT,
  payload
});
export const wsAccount = (payload) => ({ type: constants.WS_ACCOUNT, payload });
export const wsTeamMember = (payload) => ({
  type: constants.WS_TEAM_MEMBER,
  payload
});
export const wsScheduleBar = (payload) => ({
  type: constants.WS_SCHEDULE_BAR,
  payload
});
export const wsHoliday = (payload) => ({
  type: constants.WS_HOLIDAY,
  payload
});
export const wsPhase = (payload) => ({
  type: constants.WS_PHASE,
  payload
});
export const wsScheduledEvent = (payload) => ({
  type: constants.WS_SCHEDULED_EVENT,
  payload
});
export const wsPosition = (payload) => ({
  type: constants.WS_POSITION,
  payload
});

export const wsTeamPosition = (payload) => ({
  type: constants.WS_TEAM_POSITION,
  payload
});

export const wsTeamMemberSkill = (payload) => ({
  type: constants.WS_TEAM_MEMBER_SKILL,
  payload
});

export const wsTeamSkill = (payload) => ({
  type: constants.WS_TEAM_SKILL,
  payload
});

export const wsWorkGroupMembership = (payload) => ({
  type: constants.WS_WORK_GROUP_MEMBERSHIP,
  payload
});

export const openModulesModal = () =>
  createAction(constants.OPEN_MODULES_MODAL);
export const closeModulesModal = () =>
  createAction(constants.CLOSE_MODULES_MODAL);

export const fetchAccountFilter = (params) =>
  createAction(constants.FETCH_ACCOUNT_FILTER.TRIGGER, params);
export const fetchAccountFilters = () =>
  createAction(constants.FETCH_ACCOUNT_FILTERS.TRIGGER);
export const createAccountFilter = (params) =>
  appCreateAction(constants.CREATE_ACCOUNT_FILTER.TRIGGER, params);
export const updateAccountFilter = (params) =>
  appCreateAction(constants.UPDATE_ACCOUNT_FILTER.TRIGGER, params);

export const fetchFilterByIds = (params) =>
  createAction(constants.FETCH_FILTER_BY_IDS.TRIGGER, params);

export const updateAccountFilterLocal = (params) =>
  createAction(constants.UPDATE_ACCOUNT_FILTER_LOCAL, params);

export const resetAccountFilterLocal = (params) =>
  createAction(constants.RESET_ACCOUNT_FILTER_LOCAL, params);

export const clearAccountFiltersLocal = (params) =>
  createAction(constants.CLEAR_ACCOUNT_FILTERS_LOCAL, params);

// This is being used to sub in some, but not all updateAccountFilterLocal locations until named filters are ready. We are using a separate name to make re-subbing the original action in easier.

export const updateAccountFilterTemporarySaveAction = (params) =>
  createAction(constants.UPDATE_ACCOUNT_FILTER.TRIGGER, params);

export const deleteAccountFilter = (params) =>
  createAction(constants.DELETE_ACCOUNT_FILTER.TRIGGER, params);

export const downloadUrl = (params) =>
  createAction(constants.DOWNLOAD_URL, params);

export const generateReport = (params) =>
  createAction(constants.GENERATE_REPORT.TRIGGER, params);

export const sortBudgetSettings = (params) =>
  createAction(constants.SORT_BUDGET_SETTINGS, params);
export const checkHasTimeEntries = (params) =>
  createAction(constants.CHECK_HAS_TIME_ENTRIES.TRIGGER, params);
export const flushHasTimeEntries = () =>
  createAction(constants.FLUSH_HAS_TIME_ENTRIES);

export const fetchTimesheetsPredictions = (params) =>
  createAction(constants.FETCH_TIMESHEETS_PREDICTIONS.TRIGGER, params);

export const openTimesheetSettingsModal = () =>
  createAction(constants.OPEN_TIMESHEET_SETTINGS_MODAL);
export const closeTimesheetSettingsModal = () =>
  createAction(constants.CLOSE_TIMESHEET_SETTINGS_MODAL);

export const openTimesheetIncompleteModal = () =>
  createAction(constants.OPEN_TIMESHEET_INCOMPLETE_MODAL);
export const closeTimesheetIncompleteModal = () =>
  createAction(constants.CLOSE_TIMESHEET_INCOMPLETE_MODAL);

export const openSendReminderModal = (params) =>
  createAction(constants.OPEN_SEND_REMINDER_MODAL, params);
export const closeSendReminderModal = () =>
  createAction(constants.CLOSE_SEND_REMINDER_MODAL);

export const selectProjects = (payload) =>
  createAction(constants.SELECT_PROJECTS, payload);

export const unselectProject = (payload) =>
  createAction(constants.UNSELECT_PROJECT, payload);

export const unselectAllProjects = () =>
  createAction(constants.UNSELECT_ALL_PROJECTS);

export const moveProjectsToBoard = (params) =>
  createAction(constants.MOVE_PROJECTS_TO_BOARD.TRIGGER, params);

export const cloneProjects = (params) =>
  createAction(constants.CLONE_PROJECTS.TRIGGER, params);

export const batchSelectWorkloadEventRecipients = (params) =>
  createAction(constants.BATCH_SELECT_WORKLOAD_EVENT_RECIPIENTS, params);
export const clearBatchSelectedWorkloadEventRecipients = (params) =>
  createAction(
    constants.CLEAR_BATCH_SELECTED_WORKLOAD_EVENT_RECIPIENTS,
    params
  );

export const fetchWorkGroups = (params) =>
  createAction(constants.FETCH_WORK_GROUPS.TRIGGER, params);
export const fetchWorkGroup = (params) =>
  createAction(constants.FETCH_WORK_GROUP.TRIGGER, params);

export const createWorkGroup = (params) =>
  createAction(constants.CREATE_WORK_GROUP.TRIGGER, params);
export const updateWorkGroup = (params) =>
  createAction(constants.UPDATE_WORK_GROUP.TRIGGER, params);
export const archiveWorkGroup = (params) => {
  return createAction(constants.ARCHIVE_WORK_GROUP.TRIGGER, params);
};
export const unarchiveWorkGroup = (params) => {
  return createAction(constants.UNARCHIVE_WORK_GROUP.TRIGGER, params);
};
export const deleteWorkGroup = (params) => {
  return createAction(constants.DELETE_WORK_GROUP.TRIGGER, params);
};

export const createWorkGroupMembership = (params) =>
  createAction(constants.CREATE_WORK_GROUP_MEMBERSHIP.TRIGGER, params);
export const deleteWorkGroupMembership = (params) => {
  return createAction(constants.DELETE_WORK_GROUP_MEMBERSHIP.TRIGGER, params);
};

export const fetchClients = (params) =>
  createAction(constants.FETCH_CLIENTS.TRIGGER, params);
export const fetchClient = (params) =>
  createAction(constants.FETCH_CLIENT.TRIGGER, params);
export const createClient = (params) =>
  createAction(constants.CREATE_CLIENT.TRIGGER, params);
export const updateClient = (params) =>
  createAction(constants.UPDATE_CLIENT.TRIGGER, params);

export const fetchProjectTotals = (params) => {
  return createAction(constants.FETCH_PROJECT_TOTALS.TRIGGER, params);
};
export const clearProjectTotals = (params) => {
  return createAction(constants.CLEAR_PROJECT_TOTALS, params);
};

export const fetchUserActivitiesForActionable = (params) =>
  createAction(constants.FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.TRIGGER, params);
export const fetchActivityLogUserActivities = (params) =>
  createAction(constants.FETCH_ACTIVITY_LOG_USER_ACTIVITIES.TRIGGER, params);
export const openActivityLogModal = (params) =>
  createAction(constants.OPEN_ACTIVITY_LOG_MODAL, params);
export const closeActivityLogModal = () =>
  createAction(constants.CLOSE_ACTIVITY_LOG_MODAL);
export const clearUserActivities = () =>
  createAction(constants.CLEAR_USER_ACTIVITIES);

export const fetchPriorities = (params) =>
  createAction(constants.FETCH_PRIORITIES.TRIGGER, params);

export const chainActions = (params) =>
  createAction(constants.CHAIN_ACTIONS, params);

// Action creators that are used for changing color.
export const colorActions = {
  createProjectColorPreference,
  updateProjectColorPreference,
  deleteProjectColorPreference,
  createBoardPreference,
  updateBoardPreference,
  deleteBoardPreference,
  createTaskGroupPreference,
  updateTaskGroupPreference,
  deleteTaskGroupPreference,
  createTeamMembershipPreference,
  updateTeamMembershipPreference,
  deleteTeamMembershipPreference,
  createTeamPreference,
  updateTeamPreference,
  deleteTeamPreference,
  createTagPreference,
  updateTagPreference,
  deleteTagPreference,
  updateTeamMembership,
  updateTeam,
  updateTaskStatus,
  updateTag
};

export * from './actionCreatorsTyped';
