import { useState } from 'react';
import { CommentForm, CommentFormValues } from './CommentForm';

interface ReplyCommentContainerProps {
  onReply: (commentFormValues: CommentFormValues) => void;
  onCancel: () => void;
  isDisabled?: boolean;
  formTooltip?: string;
}
export const ReplyCommentContainer = ({
  onReply,
  onCancel,
  isDisabled = false,
  formTooltip
}: ReplyCommentContainerProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleCreateReply = (commentFormValues: CommentFormValues) => {
    onReply(commentFormValues);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    onCancel();
  };

  return (
    <CommentForm
      commentType={'REPLY'}
      isExpanded={isEditing}
      onClick={!isDisabled ? () => setIsEditing(true) : undefined}
      onSave={handleCreateReply}
      onCancel={handleCancel}
      formTooltip={formTooltip}
      isDisabled={isDisabled}
    />
  );
};
