import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportRegion
} from 'CsvImportsModule/types';
import uniq from 'lodash/uniq';
import { getRowValuesForEntityType } from './utils';
interface AdditionalParams {
  existingRegionsByName: Record<string, CsvImportRegion>;
  projectRegionOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectRegionOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingRegionsByName, projectRegionOption } = additionalParams;
  const { enteredProjectTitle, enteredProjectNumber } =
    getRowValuesForEntityType(row, entityType);
  const matchedRegionByProjectTitleAndNumber =
    projectRegionOption.current[enteredProjectTitle]?.[enteredProjectNumber];
  const newRegionOptionsByProjectNumberArray = Object.values(
    projectRegionOption.current
  );
  // store all new region options in an array
  const newRegionOptions: string[] = [];
  newRegionOptionsByProjectNumberArray.forEach((regionOptionByProjectNumber) =>
    Object.values(regionOptionByProjectNumber).forEach((regionOption) =>
      newRegionOptions.push(regionOption)
    )
  );

  // return all possible options, specifically putting the matched region as the second option if exists
  // including '' so that user can go back to empty field
  return uniq([
    '',
    matchedRegionByProjectTitleAndNumber ?? '',
    ...newRegionOptions,
    ...Object.keys(existingRegionsByName)
  ]);
};
