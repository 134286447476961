import React from 'react';
import { connect } from 'react-redux';
import {
  getMatchedRouteParams,
  getInstalledMembersSettingsModuleIds,
  getWorkGroupsCount,
  getTeamMembers,
  getSelectedTeamId
} from 'selectors';
import { fetchWorkGroups } from 'actionCreators';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'SettingsModule/components/styles';

class MemberSettingsTabs extends React.Component {
  componentDidMount() {
    const { fetchWorkGroups, teamId } = this.props;
    fetchWorkGroups({ teamId, permissions: { teamId } });
  }

  // function signature required by ModuleList due to tab switching function signature on board and project views
  changeViewDisplay = (newTab) => (e) => {
    const { history, matchedParams } = this.props;
    const { teamSlug } = matchedParams;

    const route = `/${teamSlug}/settings/members/${newTab}`;
    history.push(route);
  };

  render() {
    const { installedModuleIds, workGroupCount, membersCount } = this.props;
    return (
      <Tabs
        tabCounts={{ departments: workGroupCount, members: membersCount }}
        currentTab={this.props.matchedParams.settingsViewType}
        changeTabs={this.changeViewDisplay}
        installedModuleIds={installedModuleIds}
        viewType="membersSettings"
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  installedModuleIds: getInstalledMembersSettingsModuleIds(state, ownProps),
  matchedParams: getMatchedRouteParams(state),
  workGroupCount: getWorkGroupsCount(state),
  membersCount: getTeamMembers(state).length,
  teamId: getSelectedTeamId(state)
});
export default withRouter(
  connect(mapStateToProps, { fetchWorkGroups })(MemberSettingsTabs)
);
