import { createRequestTypes } from 'appUtils';

export const FETCH_TASKS_V2 = createRequestTypes('FETCH_TASKS_V2');
export const FETCH_TASK_COUNTS = createRequestTypes('FETCH_TASK_COUNTS');
export const FETCH_MEMBER_TODAY_TASK_COUNT = createRequestTypes(
  'FETCH_MEMBER_TODAY_TASK_COUNT'
);
export const FETCH_MEMBER_OVERDUE_TASK_COUNT = createRequestTypes(
  'FETCH_MEMBER_OVERDUE_TASK_COUNT'
);
export const FETCH_MEMBER_SCHEDULED_TASK_COUNT = createRequestTypes(
  'FETCH_MEMBER_SCHEDULED_TASK_COUNT'
);
export const FETCH_MEMBER_COMPLETED_TASK_COUNT = createRequestTypes(
  'FETCH_MEMBER_COMPLETED_TASK_COUNT'
);
export const FETCH_MEMBER_UNSCHEDULED_TASK_COUNT = createRequestTypes(
  'FETCH_MEMBER_UNSCHEDULED_TASK_COUNT'
);
export const FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT = createRequestTypes(
  'FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT'
);

export const EXPORT_TASKS = createRequestTypes('EXPORT_TASKS');

export const OPEN_CONFIRM_COMPLETE_TASK_MODAL =
  'OPEN_CONFIRM_COMPLETE_TASK_MODAL';

export const CLOSE_CONFIRM_COMPLETE_TASK_MODAL =
  'CLOSE_CONFIRM_COMPLETE_TASK_MODAL';

export const CLEAR_LAZY_ITEMS_LAST_UPDATED = 'CLEAR_LAZY_ITEMS_LAST_UPDATED';
export const CLEAR_LAZY_ITEMS = 'CLEAR_LAZY_ITEMS';

const MOVE_WITH_EXISTING = 'MOVE_WITH_EXISTING';
const COPY_WITH_EXISTING = 'COPY_WITH_EXISTING';

export const BATCH_ACTION_TASK_GROUP_HEADER_CONSTANTS = {
  MOVE_WITH_EXISTING,
  COPY_WITH_EXISTING
};
