import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getProjectHash } from 'selectors';
import { makeGetLeanApiIsoStatePartialProjectsHash } from 'LeanApiModule/selectors';
import { FilterField } from 'FilterModule/constants';
import { useGroupedFilter } from '.';
import { GenericFilterListTypeHookWithGroupings } from 'FilterModule/types';
import { Project } from 'ProjectsModule/models/project';

export const useGroupedProjectsFilter: GenericFilterListTypeHookWithGroupings =
  ({
    isOff,
    config,
    isoStateId,
    filterListType,
    shouldUseDraft,
    field = FilterField.project_ids
  }) => {
    const projectHash = useAppSelector((state) =>
      isOff ? emptyObj : getProjectHash(state)
    ) as Record<number, Project>;

    const getLeanApiIsoStatePartialProjectsHash = useMemo(
      makeGetLeanApiIsoStatePartialProjectsHash,
      []
    );

    const partialProjectHash = useAppSelector((state) =>
      isOff
        ? emptyObj
        : getLeanApiIsoStatePartialProjectsHash(state, { isoStateId })
    );

    const mergedHash = useMemo(() => {
      return {
        ...partialProjectHash,
        ...projectHash
      };
    }, [partialProjectHash, projectHash]);

    const getIsItemArchived = useCallback(
      (projectId: number) => {
        return !!projectHash[projectId]?.archived_at ?? false;
      },
      [projectHash]
    );

    const groupedFilterValues = useGroupedFilter({
      isOff,
      config,
      isoStateId,
      filterListType,
      field,
      getIsItemArchived,
      shouldUseDraft
    });

    return {
      ...groupedFilterValues,
      itemHash: mergedHash,
      labelKey: 'title'
    };
  };

// -----------------------------------------------------------------------------

const emptyObj = {};
