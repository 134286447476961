import {
  makeVerification,
  makeAuthorBasedVerification
} from './makeVerification';

export const verifyCreate = makeVerification('create_project_comments');

export const verifyEdit = makeAuthorBasedVerification(
  'edit_project_comments',
  'edit_project_comments_other'
);

export const verifyDelete = makeAuthorBasedVerification(
  'delete_project_comments',
  'delete_project_comments_other'
);
