import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { requireAuthentication } from 'appUtils/authentication';
import ViewFactoryRouter from './ViewFactoryRouter';
import { ProjectDetailContainer } from 'views';
import { getMatchedRouteParams } from 'selectors';
import withRouterParams from './WithRouterParams';
import ProjectDetailsNavigationTracker from 'views/Navigation/ProjectDetailsNavigationTracker';

const ProjectDetailContainerAuthenticated = withRouter(
  requireAuthentication(ProjectDetailContainer)
);

class ProjectDetailsRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;

    return (
      <ProjectDetailContainerAuthenticated {...this.props}>
        <ProjectDetailsNavigationTracker />
        <Route
          path={`${match.url}/:projectViewType`}
          component={ViewFactoryRouter}
        />
      </ProjectDetailContainerAuthenticated>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsRouter))
);
