import { put, select } from 'redux-saga/effects';
import { getTriggerIdsByActionType, getShouldTrigger } from 'selectors';
import { setShouldTrigger } from 'actionCreators';
import { TRIGGER_ACTION_TYPES } from 'appConstants';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

const checkIsTriggered = (action) => {
  return Boolean(action?.payload?.triggerId || action?.meta?.triggerId);
};

/**
 * Returns true if every condition of the payload is true, or if there are no conditions
 * @param {object} conditions key value pairs of { [dot notation payload selector]: value to match }
 * @param {object} payload the action payload to check
 * @returns {boolean}
 */
const checkConditions = (conditions, payload) => {
  return Object.entries(conditions).every(([selector, valueToMatch]) =>
    isEqual(get(payload, selector), valueToMatch)
  );
};

// see reducers/trigger
export function* handleTriggerWorker(action) {
  // ignore trigger state related actions
  if (!TRIGGER_ACTION_TYPES.includes(action.type)) {
    // if the action has triggerId, this is a TRIGGERED action
    // reset its shouldTrigger value to false
    if (checkIsTriggered(action)) {
      const shouldTrigger = yield select(getShouldTrigger);
      const triggerId = action.payload?.triggerId || action.meta.triggerId;
      // only update if necessary
      if (shouldTrigger[triggerId]) {
        yield put(
          setShouldTrigger({
            triggerId,
            value: false
          })
        );
      }
    } else {
      // check if the action type has triggerIds attached to it (this is a TRIGGERING action),
      // and set their shouldTrigger
      const triggerIdsByActionType = yield select(getTriggerIdsByActionType);
      const triggerIdHashOfActionType = triggerIdsByActionType[action.type];
      const triggerIdsThatShouldTrigger = [];

      if (triggerIdHashOfActionType) {
        const triggerIds = Object.keys(triggerIdHashOfActionType);
        // check conditions for each triggerId
        triggerIds.forEach((triggerId) => {
          const conditions = triggerIdHashOfActionType[triggerId];
          const shouldTrigger = checkConditions(conditions, action.payload);

          if (shouldTrigger) {
            triggerIdsThatShouldTrigger.push(triggerId);
          }
        });

        if (triggerIdsThatShouldTrigger.length) {
          yield put(
            setShouldTrigger({
              triggerIds: triggerIdsThatShouldTrigger,
              value: true
            })
          );
        }
      }
    }
  }
}
