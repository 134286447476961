import React from 'react';
import { useSelector } from 'react-redux';
import { getTeamMembershipsByAccountId, getCurrentUserId } from 'selectors';
import { getAverageCapacities } from 'CapacityModule/selectors';
import styled from 'styled-components';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import moment from 'moment';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import {
  StyledBudgetPhaseMilestoneIcon,
  StyledActivityRowIcon
} from './styles';
import {
  TIMESHEET_ACTIONS,
  ACTION_TYPES
} from 'appConstants/actionDescription';
import { TIMESHEET_APPROVAL_LOG_TEXT } from 'appConstants/timesheets';
import WorkplanRequestActivityLogCard from 'components/WorkplanRequests/modals/WorkplanRequestModal/components/WorkplanRequestActivityLogCard';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const getDuration = (bar) =>
  Math.ceil(+bar.total_hours / (+bar.daily_hours || 1));

const getApprovalText = (userActivity) =>
  TIMESHEET_APPROVAL_LOG_TEXT[userActivity.display_data?.time_entry?.status];
const getActionText = (actionType, userActivity = {}) => {
  const actionTypes = {
    46: 'created the Work Plan',
    47: 'updated the Work Plan',
    48: 'deleted the Work Plan',
    151: 'updated the Project to', // for a work plan
    ...TIMESHEET_ACTIONS.reduce((acc, cur) => {
      acc[cur] = 'edited the Time Entry';
      return acc;
    }, {}),
    [ACTION_TYPES.CREATE_TIME_ENTRY]: 'created the Time Entry',
    [ACTION_TYPES.SET_TIME_ENTRY_STATUS]: `${getApprovalText(
      userActivity
    )} the Time Entry`
  };
  return actionTypes[actionType] || '';
};

const getAssignedMemberByActionableType = (
  teamMembershipsByAccountId,
  userActivity
) => {
  const assignedMemberGetters = {
    ActivityPhaseScheduleBar: (userActivity) =>
      teamMembershipsByAccountId[
        userActivity.display_data?.schedule_bar?.account_id
      ],
    TimeEntry: (userActivity) =>
      teamMembershipsByAccountId[
        userActivity.display_data?.time_entry?.account_id
      ]
  };
  const memberGetter = assignedMemberGetters[userActivity.actionable_type];

  if (memberGetter) {
    return memberGetter(userActivity);
  }
};

const FIRST_COLUMN_WIDTH = 48;

export const FirstColumn = styled.div`
  display: flex;
  justify-content: center;
`;
export const CardContainer = styled.div`
  padding: 26px 0;
  border-bottom: 1px solid ${theme.colors.colorPaleGray1};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 1px solid transparent;
    padding-bottom: 0;
  }
  .cell-header {
    color: ${theme.colors.colorCalendarGray};
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
  }
  .cell-body {
    color: ${theme.colors.colorPureBlack};
    font-size: 13px;
    margin-bottom: 5px;
  }
  .member-line {
    display: flex;
    align-items: center;
    margin: 4px 0;
    .member-name {
      font-weight: 600;
    }
    &.member-header {
      .member-name {
        font-size: 16px;
      }
      ${FirstColumn} {
        width: ${FIRST_COLUMN_WIDTH}px;
      }
    }
    &.member-body {
      ${FirstColumn} {
        margin-right: 8px;
      }
      .member-name {
        font-size: 13px;
      }
    }
  }
  .action-text {
    color: ${theme.colors.colorMediumGray9};
    font-size: 13px;
  }
  .project-info {
    color: ${theme.colors.colorMediumGray9};
    font-size: 14px;
  }
`;

const StyledCell = styled.div`
  flex-basis: ${(props) => (100 * props.columns) / 12}%;
`;

const StyledBillableCell = styled.div`
  flex-basis: ${(props) => (100 * props.columns) / 12}%;
`;

const BillableIndicator = styled.div`
  height: 15px;
  width: 15px;
  color: ${(props) => (props.isBillable ? 'white' : '#a4a4a4')};
  background-color: ${(props) => (props.isBillable ? '#1A7D45' : 'inherit')};
  border-radius: 50%;
  border: 1px solid;
  border-color: ${(props) => (props.isBillable ? '#1A7D45' : '#808080')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
`;

const makeTimestamp = (timestamp) =>
  moment(timestamp).calendar(null, {
    lastDay: '[Yesterday]',
    lastWeek: 'ddd M/D/YY', // override default last week handling
    sameElse: 'ddd M/D/YY'
  });

const Cell = ({ header, body, columns }) => {
  return (
    <StyledCell style={{ flexBasis: `${(100 * columns) / 12}%` }}>
      <div className="cell-header">{header}</div>
      <div className="cell-body">{body}</div>
    </StyledCell>
  );
};

const BillableCell = ({ header, body, columns }) => {
  return (
    <StyledBillableCell style={{ flexBasis: `${(100 * columns) / 12}%` }}>
      <div className="cell-header">{header}</div>
      <div
        className="cell-body"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {body}
      </div>
    </StyledBillableCell>
  );
};

const ScheduleBarUpdatedProjectCardInfo = ({ displayData, actor }) => {
  const {
    project_title,
    phase_name,
    activity_title,
    is_default_phase,
    is_main_phase,
    is_default_activity
  } = displayData;
  // explicitly use is_default_phase || is_main_phase due to backend notification serialization
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className="project-info"
    >
      <div>{project_title}</div>
      {!(is_default_phase || is_main_phase) && (
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 4 }}>
            <StyledBudgetPhaseMilestoneIcon />
          </div>
          {phase_name}
        </div>
      )}
      {!is_default_activity && (
        <div>
          <div style={{ marginRight: 4 }}>
            <StyledActivityRowIcon />
          </div>
          {activity_title}
        </div>
      )}
    </div>
  );
};
const ScheduleBarCardInfo = ({
  displayData,
  actor,
  assignedMember,
  userActivity,
  hideBottomRow,
  memberRow,
  footer
}) => {
  const averageCapacities = useSelector(getAverageCapacities);
  const { schedule_bar = {} } = displayData;
  const { account_id, total_hours, daily_hours, start_date, end_date } =
    schedule_bar;
  const averageCapacity = averageCapacities?.[account_id] ?? 8;

  return (
    <>
      {memberRow || (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Cell
            columns={4}
            header={'Member'}
            body={assignedMember?.account?.name}
          />
        </div>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Cell
          header={`% ${formatNumWithMaxOneDecimal(averageCapacity)}H DAY`}
          columns={4}
          body={
            averageCapacity
              ? `${formatNumWithMaxOneDecimal(
                  (daily_hours / averageCapacity) * 100
                )}%`
              : 'N/A'
          } // only undefined when average === 0
        />
        <Cell header={`HRS/DAY`} columns={4} body={`${daily_hours}h`} />
        <Cell header={`TOTAL HOURS`} columns={4} body={`${total_hours}h`} />
        {!hideBottomRow && (
          <>
            <Cell
              header={`START`}
              columns={4}
              body={moment(start_date).format('M/D/YY')}
            />
            <Cell
              header={`END`}
              columns={4}
              body={moment(end_date).format('M/D/YY')}
            />
            <Cell
              header={`WORKDAYS`}
              columns={4}
              body={`${getDuration(schedule_bar)} d`}
            />
          </>
        )}
        {footer && footer}
      </div>
    </>
  );
};
const TimeEntryCardInfo = ({
  displayData,
  actor,
  assignedMember,
  userActivity,
  isHoursOnly
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Cell
        columns={4}
        header={'DATE'}
        body={moment(displayData?.time_entry?.date).format('MM/DD/YY')}
      />

      {assignedMember?.account?.name && (
        <Cell
          columns={4}
          header={'MEMBER'}
          body={assignedMember?.account?.name}
        />
      )}
      {displayData?.activity_title && !displayData?.is_default_activity && (
        <Cell
          columns={4}
          header={'WORK CATEGORY'}
          body={displayData?.activity_title}
        />
      )}
      {displayData?.description_title && (
        <Cell
          columns={4}
          header={'DESCRIPTION'}
          body={displayData?.description_title}
        />
      )}
      {displayData?.time_entry?.hours && (
        <Cell
          columns={4}
          header={'HOURS'}
          body={displayData?.time_entry?.hours}
        />
      )}
      {displayData?.time_entry?.rate && !isHoursOnly && (
        <Cell
          columns={4}
          header={'RATE'}
          body={`$${displayData?.time_entry?.rate}/h`}
        />
      )}
      {displayData?.time_entry && !isHoursOnly && (
        <BillableCell
          columns={4}
          header={'BILL'}
          body={
            <>
              <BillableIndicator isBillable={displayData.time_entry.billable}>
                $
              </BillableIndicator>
              {displayData.time_entry.billable ? 'Billable' : 'Not Billable'}
            </>
          }
        />
      )}
    </div>
  );
};

const MemberLine = ({ member, date, actionText, className = '' }) => {
  const currentUserId = useSelector(getCurrentUserId);
  return (
    <div className={`member-line ${className}`}>
      <FirstColumn>
        <MemberInitials
          member={member}
          classes={cn('item-account selected', {
            'logged-member-no-hover': member?.account.id === currentUserId,
            'regular-member-no-hover': member?.account.id !== currentUserId
          })}
          idx={'idx-placeholder'}
          isOnTaskModal
        />
      </FirstColumn>
      <div>
        <div className="member-name">{member?.account?.name || ''}</div>
        {!!actionText && <div className="action-text">{actionText}</div>}
      </div>
    </div>
  );
};

const ActorHeader = (props) => <MemberLine {...props} />;

const EmptyComponent = () => null;

const ACTIVITY_LOG_BODIES = {
  46: ScheduleBarCardInfo,
  47: ScheduleBarCardInfo,
  48: ScheduleBarCardInfo,
  151: ScheduleBarUpdatedProjectCardInfo
};
const ScheduleBarCard = (props) => {
  const actionText = getActionText(
    props.userActivity.action_type,
    props.userActivity
  );
  const CardInfo =
    ACTIVITY_LOG_BODIES[props.userActivity.action_type] || ScheduleBarCardInfo;
  return (
    <>
      <ActorHeader
        member={props.actor}
        date={props.userActivity.timestamp}
        actionText={`${makeTimestamp(props.userActivity.timestamp)}${
          actionText && ', '
        } ${actionText}`}
        // only include command if followed by valid actionText based on action_type
        className="member-header"
      />
      <div style={{ marginLeft: FIRST_COLUMN_WIDTH }}>
        <CardInfo {...props} />
      </div>
    </>
  );
};

const TimeEntryCard = (props) => {
  const actionText = getActionText(
    props.userActivity.action_type,
    props.userActivity
  );
  const CardInfo =
    ACTIVITY_LOG_BODIES[props.userActivity.action_type] || TimeEntryCardInfo;
  return (
    <>
      <ActorHeader
        member={props.actor}
        date={props.userActivity.timestamp}
        actionText={`${makeTimestamp(props.userActivity.timestamp)}${
          actionText && ', '
        } ${actionText}`}
        // only include command if followed by valid actionText based on action_type
        className="member-header"
      />
      <div style={{ marginLeft: FIRST_COLUMN_WIDTH }}>
        <CardInfo {...props} />
      </div>
    </>
  );
};

const WorkplanRequestCard = ({ userActivity }) => {
  return <WorkplanRequestActivityLogCard userActivity={userActivity} />;
};

const ACTIVITY_LOG_CARDS = {
  ActivityPhaseScheduleBar: ScheduleBarCard,
  ActivityPhaseScheduleBarRequest: WorkplanRequestCard,
  TimeEntry: TimeEntryCard
};

export const ActivityLogCard = ({
  userActivity,
  actor,
  assignedMember,
  displayData,
  actionableType,
  hideBottomRow,
  footer,
  memberRow
}) => {
  const Card = ACTIVITY_LOG_CARDS[actionableType] || EmptyComponent;
  const { isHoursOnly } = useIsHoursOnly();

  return (
    <CardContainer>
      <Card
        displayData={displayData}
        actor={actor}
        assignedMember={assignedMember}
        userActivity={userActivity}
        hideBottomRow={hideBottomRow}
        footer={footer}
        memberRow={memberRow}
        isHoursOnly={isHoursOnly}
      />
    </CardContainer>
  );
};
const ActivityLog = ({ userActivities = [] }) => {
  const teamMembershipsByAccountId = useSelector(getTeamMembershipsByAccountId);
  return (
    <>
      {userActivities.map((userActivity) => {
        const actor = userActivity.actor?.is_integration_account
          ? { account: userActivity.actor }
          : teamMembershipsByAccountId[userActivity.actor?.id];
        const assignedMember = getAssignedMemberByActionableType(
          teamMembershipsByAccountId,
          userActivity
        );

        return (
          <ActivityLogCard
            key={userActivity.id}
            userActivity={userActivity}
            actor={actor}
            assignedMember={assignedMember}
            displayData={userActivity.display_data}
            actionableType={userActivity.actionable_type}
          />
        );
      })}
    </>
  );
};

export default ActivityLog;
