import cn from 'classnames';
import { MemberPermissionListItem } from '../types';
import { BaseCellRootContainer } from './styles';
import { Space } from 'components/Space';
import styled from 'styled-components';
import { MemberInitials } from 'views';
import theme from 'theme';
import { teamUtils } from 'TeamsModule/utils';
import { usePopover } from 'components/Popover/usePopover';
import LockIcon from 'icons/LockIcon';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { navigateToMembersSettings } from 'actionCreators';
import { getTeamSlug } from 'selectors';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

export const MembersPermissionsTableNameCell = ({ row }) => {
  const { teamMember, onRequestEditMemberMetadata } =
    row.original as MemberPermissionListItem;

  const { Popover, openPopover } = usePopover();
  const teamSlug = useAppSelector(getTeamSlug);
  const dispatch = useAppDispatch();

  const handleClickManageMembers = () => {
    if (teamSlug) {
      dispatch(
        navigateToMembersSettings({
          teamSlug
        })
      );
    }
  };

  return (
    <>
      <BaseCellRootContainer
        onClick={() =>
          onRequestEditMemberMetadata({ teamMembershipId: teamMember.id })
        }
      >
        <ContentContainer>
          <MemberInitials
            member={teamMember}
            size="medium"
            classes={cn('regular-member-no-hover selected')}
          />
          <Space value={12} />
          <div>
            <TextContainer
              $isArchived={teamUtils.getIsArchived(teamMember)}
              className="text-container"
            >
              {teamMember.account.name}
            </TextContainer>
            {teamMember.pending_email && (
              <PendingText
                onClick={(e) => {
                  e.stopPropagation();
                  openPopover({ event: e });
                }}
              >
                Pending
              </PendingText>
            )}
          </div>
        </ContentContainer>
      </BaseCellRootContainer>
      <Popover>
        <PendingMemberPopoverContainer onClick={handleClickManageMembers}>
          <GoToProjectIcon color={theme.colors.colorMediumGray9} />
          <span>Manage Members</span>
          <LockIcon fill={theme.colors.colorMediumGray9} />
        </PendingMemberPopoverContainer>
      </Popover>
    </>
  );
};

const TextContainer = styled.div<{ $isArchived: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PendingText = styled.span`
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorDeleteRed};
`;

const PendingMemberPopoverContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 15px 18px;
  gap: 5px;
  cursor: pointer;

  :hover {
    background-color: #ececec;
  }
`;
