import React from 'react';
import { connect } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import BudgetContainer from './BudgetContainer';
import { FILTER_PAGES, VIEW_BY } from 'appConstants/workload';
import { VARIANCE_TYPE } from './BudgetReportTable/constants';
import { withQueryParams, NumberParam } from 'use-query-params';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';

const BudgetVarianceTab = ({ activeFilter, viewBy, pageName, isInModal }) => {
  const { filterSections } = useReportsViewFilter({});

  return (
    <BudgetContainer
      activeFilter={activeFilter}
      viewBy={viewBy}
      pageName={pageName}
      isInModal={isInModal}
      varianceType={VARIANCE_TYPE.BUDGET}
      filterSections={isInModal ? null : filterSections}
    />
  );
};

const makeMapStateToProps = () => {
  const getFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => {
    const isInModal = ownProps.moduleType === 'budgetModalView';
    const pageName = isInModal
      ? 'BUDGET_MODAL_BUDGET_VARIANCE'
      : FILTER_PAGES.BUDGET_VARIANCE;
    const matchedViewBy = isInModal
      ? ownProps.matchedParams.budgetSecondaryViewType
      : ownProps.matchedParams.reportViewType;
    const viewBy = Object.values(VIEW_BY).includes(matchedViewBy)
      ? matchedViewBy
      : VIEW_BY.PROJECTS;
    return {
      activeFilter: getFilter(state, {
        viewBy,
        pageName,
        filterId: ownProps.filterId || (isInModal ? 'new-modal' : null) // budget modal variance report filter has no save option yet
      }),
      viewBy,
      pageName,
      isInModal
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {};

export default withQueryParams(
  {
    filter_id: NumberParam
  },
  connect(makeMapStateToProps, mapDispatchToProps)(BudgetVarianceTab)
);
