import cn from 'classnames';
import styled, { useTheme } from 'styled-components';
import { Modal } from 'reactstrap';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useUpdate } from 'react-use';
import { MFA_STATUS } from 'AuthenticationModule/constants';
import { useSecurity } from 'SettingsModule/components/Security/SecuritySettings/hooks/useSecurity';
import { getTeamWideMfaSetting } from 'AuthenticationModule/selectors';
import { SupportedMfaStatus } from 'AuthenticationModule/types';
import { useAppSelector } from 'reduxInfra/hooks';
import { BlueSubmitButton, TextButtonWithBorder } from 'components/styles';
import { Radio } from 'components/Radio';
import { includes } from 'appUtils/typeUtils';
import { useMfaSetupModal } from './useMfaSetupModal';

interface PreferredMfaMethodModalProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const mfaMethodContentMap: Partial<
  Record<SupportedMfaStatus, { header: ReactNode; subtitle: ReactNode }>
> = {
  [MFA_STATUS.totp]: {
    header: 'Authenticator App',
    subtitle:
      'You can use Google Authenticator or another app that creates one-time verification codes'
  },
  [MFA_STATUS.sms]: {
    header: 'SMS Text Message',
    subtitle: 'Verification codes can be sent in a text message (SMS)'
  },
  [MFA_STATUS.noPreference]: {
    header: 'User preferred method',
    subtitle: 'User can use their own preferred MFA method'
  }
};

export const PreferredMfaMethodModal = ({
  isOpen,
  onToggle
}: PreferredMfaMethodModalProps) => {
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const teamWideMfaSetting = useAppSelector(getTeamWideMfaSetting);
  const theme = useTheme();
  const update = useUpdate();

  /**
   * We need to make this a `useRef` instead of `useState` because the value of this variable
   * is referenced inside a memoized function (`useCallback`) where the memoized function is passed as a value to a `window.addEventListener`, so there are some scenarios (e.g. on mfa verify success) where the value of this variable will be referencing older values (when used as a `useState`), causing logical issues. Switching to using `useRef` fixes this reference issue.
   */
  const selectedMfaMethod = useRef<SupportedMfaStatus | undefined>();

  const {
    isSmsMfaFeatureFlagEnabled,
    isChangingTeamMfaSetting,
    changeTeamMfaSetting,
    getMfaData
  } = useSecurity();

  const availableMfaMethods = useMemo(
    () =>
      isSmsMfaFeatureFlagEnabled
        ? [MFA_STATUS.totp, MFA_STATUS.sms, MFA_STATUS.noPreference]
        : [MFA_STATUS.totp],
    [isSmsMfaFeatureFlagEnabled]
  );

  const syncSelectedMfaMethod = useCallback(() => {
    if (
      teamWideMfaSetting &&
      includes(availableMfaMethods, teamWideMfaSetting)
    ) {
      selectedMfaMethod.current = teamWideMfaSetting;
    } else {
      selectedMfaMethod.current = undefined;
    }
  }, [availableMfaMethods, teamWideMfaSetting]);

  useEffect(() => {
    // always execute `syncSelectedMfaMethod` whenever `isOpen` changes
    // we do this way instead of adding `isOpen` manually to the list of dependencies
    // so that `isOpen` never gets accidentally removed from the list of dependencies
    // by accident in the future by a dev
    if (isOpen) {
      //
    }
    syncSelectedMfaMethod();
  }, [isOpen, syncSelectedMfaMethod]);

  const handleNext = useCallback(() => {
    if (selectedMfaMethod.current) {
      changeTeamMfaSetting({
        value: selectedMfaMethod.current,
        onRequireSetupMFA: () => {
          setIsSetupModalOpen(true);
        },
        onSuccess: () => {
          if (isSetupModalOpen) {
            setIsSetupModalOpen(false);
          }

          onToggle();
        }
      });
    }
  }, [changeTeamMfaSetting, isSetupModalOpen, onToggle, selectedMfaMethod]);

  const handleMfaVerifySuccess = useCallback(() => {
    getMfaData({
      onSuccess: () => {
        setIsSetupModalOpen(false);
        handleNext();
      }
    });
  }, [getMfaData, handleNext]);

  const { MfaSetupModal } = useMfaSetupModal({
    onMfaVerifySuccess: handleMfaVerifySuccess
  });

  const handleCancel = () => {
    onToggle();
    syncSelectedMfaMethod();
  };

  return (
    <>
      <StyledModal isOpen={isOpen}>
        <ContentContainer>
          <Header>
            <Title>Select Preferred MFA Method</Title>
            <Subtitle>Please choose how you wish to receive MFA code</Subtitle>
          </Header>
          <Body>
            <MfaOptionsContainer>
              {availableMfaMethods.map((mfaMethod) => {
                const mfaMethodContent = mfaMethodContentMap[mfaMethod];
                if (!mfaMethodContent) return <></>;

                const isSelected = mfaMethod === selectedMfaMethod.current;

                return (
                  <ListItemContainer
                    key={mfaMethod}
                    className={cn({ selected: isSelected })}
                    onClick={() => {
                      selectedMfaMethod.current = mfaMethod;
                      update();
                    }}
                  >
                    <ListItemContentContainer>
                      <Radio checked={isSelected} />
                      <ListItemDescriptionContainer>
                        <PrimaryText>{mfaMethodContent.header}</PrimaryText>
                        <SecondaryText>
                          {mfaMethodContent.subtitle}
                        </SecondaryText>
                      </ListItemDescriptionContainer>
                    </ListItemContentContainer>
                  </ListItemContainer>
                );
              })}
            </MfaOptionsContainer>
          </Body>
          <Footer>
            <TextButtonWithBorder
              borderColor={theme.colors.colorLightGray12}
              onClick={handleCancel}
            >
              Cancel
            </TextButtonWithBorder>
            <BlueSubmitButton
              isDisabled={!selectedMfaMethod || isChangingTeamMfaSetting}
              fontWeight={600}
              onClick={handleNext}
            >
              Next
            </BlueSubmitButton>
          </Footer>
        </ContentContainer>
      </StyledModal>
      <MfaSetupModal
        isOpen={isSetupModalOpen}
        onToggle={() => setIsSetupModalOpen(false)}
      />
    </>
  );
};

const StyledModal = styled(Modal)`
  max-width: 695px;

  .modal-content {
    width: 695px;
    height: 439px;
    border-radius: 6px !important;
    padding: 30px 36px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  gap: 24px;
`;

const Header = styled.div``;

const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

const Body = styled.div``;

const MfaOptionsContainer = styled.div`
  display: grid;
  gap: 12px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Subtitle = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;

const ListItemContainer = styled.div`
  cursor: pointer;
  padding: 16px;
  height: 74px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.colorLightGray12};

  &.selected {
    border-color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

const ListItemContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const ListItemDescriptionContainer = styled.div`
  display: grid;
  gap: 2px;
`;

const PrimaryText = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const SecondaryText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;
