import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES,
  CsvImportFieldName
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';
import { RowValidator } from 'CsvImportsModule/types';
import moment from 'moment';
import { MergeExclusive } from 'type-fest';

type AdditionalParams = MergeExclusive<
  {
    isRequired?: boolean;
  },
  {
    isRequired?: boolean;
    startDateFieldName: CsvImportFieldName;
    endDateFieldName: CsvImportFieldName;
  }
>;

export const startDateValidator: RowValidator<AdditionalParams> = ({
  row,
  entityType,
  additionalParams
}) => {
  const enteredValues = getRowValuesForEntityType(row, entityType);
  const defaultStartDateFieldName = CsvImportFieldName.EnteredStartDate;
  const defaultEndDateFieldName = CsvImportFieldName.EnteredEndDate;

  const enteredStartDate =
    enteredValues[
      additionalParams?.startDateFieldName ?? defaultStartDateFieldName
    ];
  const enteredEndDate =
    enteredValues[
      additionalParams?.endDateFieldName ?? defaultEndDateFieldName
    ];

  const momentStartDate = moment(enteredStartDate, 'YYYY-MM-DD', true);
  const momentEndDate = moment(enteredEndDate);

  if (enteredStartDate === TRAINING_DATA_REQUIRED_STRING) {
    return false;
  } else if (!enteredStartDate) {
    if (additionalParams?.isRequired) {
      return ERROR_MESSAGES.startDateRequired;
    } else {
      return true;
    }
  } else if (!momentStartDate.isValid()) {
    return ERROR_MESSAGES.invalidDateFormat;
  } else if (!enteredEndDate) {
    return ERROR_MESSAGES.endDateRequired;
  } else if (momentEndDate.isBefore(momentStartDate)) {
    return ERROR_MESSAGES.endDateBeforeStartDate;
  } else if (enteredEndDate) {
    return true;
  }
  return false;
};
