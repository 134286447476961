import React, { Component } from 'react';
import Popover from 'components/Popover';
import { connect } from 'react-redux';
import {
  openAddProjectModal,
  toggleInviteForm,
  openTeamMemberModal,
  openAddEditGroupModal,
  openTaskGlobalAdd,
  updateAccountFilter,
  fetchGroups
} from 'actionCreators';
import {
  getGlobalAddOpen,
  getIsOnScheduleView,
  makeGetActiveWorkloadPlannerFilter,
  getAuth
} from 'selectors';
import GlobalAddListItem from './GlobalAddListItem';
import { FILTER_PAGES } from 'appConstants/workload';
import {
  FlyoutMenuWrapper,
  TeamMemberIcon,
  TaskIcon,
  ProjectIcon,
  TeamIcon
} from './styles';
import {
  inviteMemberToTeam,
  inviteGuests
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { createPortfolio } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  INVITE_MEMBER_TO_TEAM_TIP,
  CREATE_PORTFOLIO_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';

class GlobalAdd extends Component {
  componentDidUpdate(prevProps) {
    rebuildTooltip();
  }

  openAddProjectModal = () => {
    const { onRequestClose, openAddProjectModal, isOnScheduleView } =
      this.props;

    const onSuccessCallback = isOnScheduleView
      ? this.onSuccessCallback
      : undefined;

    openAddProjectModal({
      priorityId: 0,
      redirectOnSuccess: !isOnScheduleView,
      onSuccessCallback: onSuccessCallback
    });

    onRequestClose();
  };

  onSuccessCallback = (res) => {
    const { activeFilter, updateAccountFilter, fetchGroups, auth } = this.props;
    const { id } = res;

    if (activeFilter) {
      updateAccountFilter({
        ...activeFilter,
        project_ids: [...activeFilter.project_ids, id]
      });
    }

    fetchGroups(auth.token);
  };

  openAddEditGroupModal = () => {
    const { openAddEditGroupModal, onRequestClose } = this.props;
    openAddEditGroupModal({});
    onRequestClose();
  };

  onInviteButtonClick = () => {
    const { onRequestClose, toggleInviteForm } = this.props;
    toggleInviteForm({ open: true, origin: 'GlobalAdd' });
    onRequestClose();
  };

  openAddTaskGlobalAdd = () => {
    const { openTaskGlobalAdd, onRequestClose } = this.props;
    openTaskGlobalAdd();
    onRequestClose();
  };

  getCanInviteToTeam = () => {
    const { checkPermission, teamId, inviteMemberToTeam } = this.props;
    if (teamId) {
      return checkPermission(inviteMemberToTeam, {
        permissions: {
          teamId
        }
      });
    }
    return false;
  };

  getCanInviteGuests = () => {
    const { checkPermission, teamId, inviteGuests } = this.props;
    if (teamId) {
      return checkPermission(inviteGuests, {
        permissions: {
          teamId
        }
      });
    }
    return false;
  };

  getCanCreatePortfolio = () => {
    const { checkPermission, teamId, createPortfolio } = this.props;
    if (teamId) {
      return checkPermission(createPortfolio, {
        permissions: {
          teamId
        }
      });
    }
    return false;
  };

  render() {
    const { isOpen, onRequestClose } = this.props;

    const canInvite = this.getCanInviteToTeam() || this.getCanInviteGuests();
    const canCreatePortfolio = this.getCanCreatePortfolio();

    return (
      <div ref={(ref) => (this.target = ref)}>
        <Popover
          isOpen={isOpen}
          closePopover={onRequestClose}
          target={this.target}
        >
          <FlyoutMenuWrapper align="right">
            <div className="project-menu-item-container">
              <GlobalAddListItem
                Icon={TeamMemberIcon}
                onClick={canInvite ? this.onInviteButtonClick : noop}
                tooltip={canInvite ? '' : INVITE_MEMBER_TO_TEAM_TIP}
              >
                Invite Member
              </GlobalAddListItem>
              {/* <GlobalAddListItem
              Icon={TaskIcon}
              onClick={this.openAddTaskGlobalAdd}
            >
              New Task
            </GlobalAddListItem> */}
              <GlobalAddListItem
                Icon={ProjectIcon}
                onClick={this.openAddProjectModal}
              >
                New Project
              </GlobalAddListItem>
              <GlobalAddListItem
                Icon={TeamIcon}
                onClick={canCreatePortfolio ? this.openAddEditGroupModal : noop}
                tooltip={canCreatePortfolio ? '' : CREATE_PORTFOLIO_TIP}
              >
                New Portfolio
              </GlobalAddListItem>
            </div>
          </FlyoutMenuWrapper>
        </Popover>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state) => {
    const isOnScheduleView = getIsOnScheduleView(state);
    return {
      auth: getAuth(state),
      isOpen: getGlobalAddOpen(state),
      teamId: getSelectedTeamId(state),
      activeFilter: isOnScheduleView
        ? getActiveWorkloadPlannerFilter(state, {
            viewBy: 'projects',
            pageName: FILTER_PAGES.WORKLOAD_PLANNER
          })
        : undefined,
      isOnScheduleView
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  openAddProjectModal,
  openAddEditGroupModal,
  toggleInviteForm,
  openTeamMemberModal,
  openTaskGlobalAdd,
  updateAccountFilter,
  fetchGroups,
  inviteMemberToTeam,
  inviteGuests,
  createPortfolio
};
export default withPermissionsCheck(
  connect(makeMapStateToProps, mapDispatchToProps)(GlobalAdd)
);
