import React from 'react';
import SvgIcon from 'components/SvgIcon';

const EnvelopeIcon = ({
  width = '28',
  height = '28',
  fill = '#F2C94C',
  className
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0Z"
      fill={fill}
    />
    <path
      d="M19.25 8H8.75C8.15326 8 7.58097 8.23705 7.15901 8.65901C6.73705 9.08097 6.5 9.65326 6.5 10.25V17.75C6.5 18.3467 6.73705 18.919 7.15901 19.341C7.58097 19.7629 8.15326 20 8.75 20H19.25C19.8467 20 20.419 19.7629 20.841 19.341C21.2629 18.919 21.5 18.3467 21.5 17.75V10.25C21.5 9.65326 21.2629 9.08097 20.841 8.65901C20.419 8.23705 19.8467 8 19.25 8ZM19.25 9.5L14.375 12.8525C14.261 12.9183 14.1317 12.953 14 12.953C13.8683 12.953 13.739 12.9183 13.625 12.8525L8.75 9.5H19.25Z"
      fill="white"
    />
  </SvgIcon>
);

export default EnvelopeIcon;
