import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const fetchScopes = (params) =>
  createAction(constants.FETCH_SCOPES.TRIGGER, params);
export const createScope = (params) =>
  createAction(constants.CREATE_SCOPE.TRIGGER, params);
export const updateScope = (params) =>
  createAction(constants.UPDATE_SCOPE.TRIGGER, params);
export const deleteScope = (params) => {
  return createAction(constants.DELETE_SCOPE.TRIGGER, params);
};
/**
 * update the parent activity phase of the scope. when isCopy: true, will clone the scopes
 */
export const moveScopes = (params) => {
  return createAction(constants.MOVE_SCOPES.TRIGGER, params);
};
// next 2 actions all hit the same endpoint (assign). separated for simplicity. unassign
// will automatically add 'unassign: true' to all members objects in payload
/**
 *  scope_id,
    members: [
      account_id,
      member_budget_id,
      start_date,
      end_date,
      percent_complete,
      unassign
    ]
 */
/**
 * updates the position of a scope in its parent activity phase's scope order
 */
export const updateScopePosition = (params) =>
  createAction(constants.UPDATE_SCOPE_POSITION.TRIGGER, params);

export const assignScopeMembers = (params) => {
  return createAction(constants.ASSIGN_SCOPE_MEMBERS.TRIGGER, params);
};
export const unassignScopeMembers = (params) => {
  return createAction(constants.UNASSIGN_SCOPE_MEMBERS.TRIGGER, params);
};
// update_assignment. update a single scope membership
export const updateScopeMembership = (params) => {
  return createAction(constants.UPDATE_SCOPE_MEMBERSHIP.TRIGGER, params);
};

export const deleteScopeAttachment = (params) => {
  return createAction(constants.DELETE_SCOPE_ATTACHMENT.TRIGGER, params);
};

/* ------------- Selection - FE only; All require filterStateId ------------- */

export const selectScopes = (params) =>
  createAction(constants.SELECT_SCOPES, params);
export const clearSelectedScopes = (params) =>
  createAction(constants.CLEAR_SELECTED_SCOPES, params);
/**
 * Will add topLevelOrder ids to selectedIds. Use when topLevelOrder is [scopeIds]
 */
export const selectAllScopes = (params) =>
  createAction(constants.SELECT_ALL_SCOPES, params);
/**
 * Will add every ordersByGroup ids to selectedIds
 */
export const selectAllGroupsScopes = (params) =>
  createAction(constants.SELECT_ALL_GROUPS_SCOPES, params);
