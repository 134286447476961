import styled from 'styled-components';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { UtilizationBreakdown } from 'UtilizationModule/types';
import { HoursDisplayType } from '../types';
import { getFullPTOValuesByHoursType } from '../utils';
import {
  CenterContainer,
  CenterValueText,
  FloatingContainer,
  PTOHatchBackground,
  PTOValueText
} from './styles';

export const FullPTOBucket = ({
  hoursDisplayType,
  utilizationBreakdown
}: {
  hoursDisplayType: HoursDisplayType;
  utilizationBreakdown: UtilizationBreakdown;
}) => {
  const { capacity, committed } = utilizationBreakdown;

  const isOverCapacity = committed > capacity;

  const { PTOValue, unit } = getFullPTOValuesByHoursType({
    hoursDisplayType,
    utilizationBreakdown
  });

  const shouldShowPTOValue = PTOValue > 0;

  return (
    <PTOHatchBackground>
      <CenterContainer>
        <CenterValueText>
          PTO {isOverCapacity && <WarningText>!</WarningText>}
        </CenterValueText>
        {shouldShowPTOValue && (
          <FloatingContainer>
            <PTOValueText>
              {formatNumWithMaxOneDecimal(PTOValue)}
              {unit}
            </PTOValueText>
          </FloatingContainer>
        )}
      </CenterContainer>
    </PTOHatchBackground>
  );
};

const WarningText = styled.span`
  color: ${({ theme }) => theme.colors.colorCalendarRed};
`;
