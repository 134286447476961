import { getUserPermissions } from 'selectors';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useUserPermissions = () => useSelector(getUserPermissions);
export const useCheckPermission = () =>
  useCallback(
    (actionCreator, payload) => actionCreator({ ...payload, UI_CHECK: true }),
    []
  );

const withPermissionsCheck = (Component) => {
  const WithPermissionsCheck = (props) => {
    const userPermissions = useUserPermissions();
    const checkPermission = useCheckPermission();

    return (
      <Component
        {...props}
        checkPermission={checkPermission}
        userPermissions={userPermissions}
      />
    );
  };

  return WithPermissionsCheck;
};

export default withPermissionsCheck;
