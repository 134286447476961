import styled from 'styled-components';
import {
  ColorScaleStyle,
  ColorScaleStyles,
  ColorScaleColors,
  ColorScaleIntervals,
  ColorScaleIntervalWithMax
} from '../types';
import { ColorScalePreviewInterval } from '../ColorScalePreviewInterval';
import { buildColorScaleStyles, buildIntervalsWithMaxima } from '../utils';

interface ColorScalePreviewBaseProps {
  /**
   * The size of the color scale items.
   */
  size: number;

  /**
   * The space between the color previews.
   */
  spacing: number;
}

interface ColorScalePreviewWithIntervalsProps
  extends ColorScalePreviewBaseProps {
  /**
   * The color scale for the preview.
   */
  colorScale: ColorScaleIntervals;

  /**
   * Function that produces the label that will be placed over the color scale
   * items.
   */
  getLabel?: (interval: ColorScaleIntervalWithMax) => string;

  /**
   * Function that produces the tooltip for the color scale items.
   */
  getTooltipContent?: (interval: ColorScaleIntervalWithMax) => string;
}

interface ColorScalePreviewWithStylesProps extends ColorScalePreviewBaseProps {
  /**
   * The color scale for the preview.
   */
  colorScale: ColorScaleColors | ColorScaleStyles;

  /**
   * Function that produces the label that will be placed over the color scale
   * items.
   */
  getLabel?: (interval: ColorScaleStyle) => string;

  /**
   * Function that produces the tooltip for the color scale items.
   */
  getTooltipContent?: (interval: ColorScaleStyle) => string;
}

/**
 * A preview of a color scale, arranged horizontally from left to right.
 */
export const ColorScalePreview = (
  props: ColorScalePreviewWithIntervalsProps | ColorScalePreviewWithStylesProps
) => {
  if (!props.colorScale.length) {
    return null;
  }

  // Switch on the type of data received. This is done to allow different
  // components with different data structures to render the color scale.
  // The first case includes the labels, whilst the second does not.
  if (isColorScaleIntervals(props)) {
    const {
      colorScale,
      getLabel: label,
      size,
      spacing,
      getTooltipContent
    } = props;
    const intervals = buildIntervalsWithMaxima(colorScale);

    return (
      <Preview $spacing={spacing}>
        {intervals.flatMap((interval, index) =>
          interval.isHidden
            ? []
            : [
                <ColorScalePreviewInterval
                  key={`${index}-${interval.style.color}`}
                  color={interval.style.color}
                  label={label ? label(interval) : undefined}
                  overflow={interval.style.overflow}
                  size={size}
                  tooltipContent={
                    getTooltipContent ? getTooltipContent(interval) : undefined
                  }
                />
              ]
        )}
      </Preview>
    );
  } else {
    const {
      colorScale,
      getLabel: label,
      size,
      spacing,
      getTooltipContent: tooltip
    } = props;

    // Convert the color scale to styles if it is only colors.
    const styles: ColorScaleStyles = isColorScaleColors(colorScale)
      ? buildColorScaleStyles(colorScale)
      : colorScale;

    return (
      <Preview $spacing={spacing}>
        {styles.map((style, index) => (
          <ColorScalePreviewInterval
            key={`${index}-${style.color}`}
            color={style.color}
            label={label ? label(style) : undefined}
            overflow={style.overflow}
            size={size}
            tooltipContent={tooltip ? tooltip(style) : undefined}
          />
        ))}
      </Preview>
    );
  }
};

/**
 * Detects whether the color scale is composed of intervals.
 * @param props The color scale preview properties.
 * @returns `true` if the scale is composed of intervals.
 */
const isColorScaleIntervals = (
  props: ColorScalePreviewWithIntervalsProps | ColorScalePreviewWithStylesProps
): props is ColorScalePreviewWithIntervalsProps =>
  !isColorScaleColors(props.colorScale) &&
  props.colorScale[0] !== undefined &&
  'min' in props.colorScale[0];

/**
 * Detects whether the color scale is composed of colors.
 * @param scale The color scale.
 * @returns `true` if the scale is composed of colors.
 */
const isColorScaleColors = (
  scale: ColorScaleColors | ColorScaleStyles | ColorScaleIntervals
): scale is ColorScaleColors => typeof scale[0] === 'string';

interface PreviewProps {
  $spacing: number;
}

const Preview = styled.div.attrs<PreviewProps>(({ $spacing }) => ({
  style: { '--spacing': `${$spacing}px` }
}))<PreviewProps>`
  display: inline-flex;
  gap: var(--spacing);
  width: fit-content;
`;
