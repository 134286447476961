import { useMemo, useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getMemberBudgetsByProjectIdWithUnassigned,
  getFlatPhasesHash,
  getProjectHash,
  getPositions,
  getOwnProjectUnassignedCounts
} from 'selectors';
import { getMemberBudgets } from 'BudgetModule/selectors';
import { UnassignedMemberBudgetWithPosition } from 'components/roles/types';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import { Project } from 'ProjectsModule/models/project';
import { Position } from 'models/position';
import {
  formatUnassignedRoleName,
  getUnassignedRoleCount
} from 'appUtils/hooks/budget/useMemberBudgetName';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { PhaseMembership } from 'ProjectsModule/phases/models/phaseMembership';

const emptyArr = [];
const emptyObj = {};

type PhaseId = number;

type MemberBudgetId = number;

type FormattedPosition = Position & {
  nameWithCount: string;
  count: number | undefined;
};

/**
 * Phase membership that contains position and member budget information
 */
export type PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition =
  PhaseMembership & {
    memberBudget: UnassignedMemberBudgetWithPosition;
    position: FormattedPosition;
  };

export type ProjectUnassignedMemberBudgetWithPosition =
  UnassignedMemberBudgetWithPosition & {
    position: FormattedPosition;
  };

const getMemberBudgetAndPositionForPhaseMembership = ({
  membership,
  memberBudgetsHash,
  positionHash
}) => {
  const memberBudget = memberBudgetsHash[membership.member_budget_id];
  const position = positionHash[memberBudget?.position_id];

  return { position, memberBudget };
};

type HookReturnsTypes = {
  unassignedMemberBudgetsWithPositionForProject: ProjectUnassignedMemberBudgetWithPosition[];
  unassignedMemberBudgetsWithPositionForProjectHash: Record<
    string,
    ProjectUnassignedMemberBudgetWithPosition
  >;
  phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId: Record<
    PhaseId,
    Record<
      MemberBudgetId,
      PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
    >
  >;
  getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId: ({
    phaseId,
    memberBudgetId
  }: {
    phaseId: Nullable<PhaseId>;
    memberBudgetId: Nullable<MemberBudgetId>;
  }) =>
    | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
    | undefined;
  getUnassignedMemberBudgetWithPositionForProject: ({
    memberBudgetId
  }: {
    memberBudgetId: Nullable<MemberBudgetId>;
  }) => ProjectUnassignedMemberBudgetWithPosition | undefined;
  getPhaseUnassignedMemberBudgetIds: ({
    phaseId
  }: {
    phaseId: Nullable<PhaseId>;
  }) => number[] | never[];
  getUnassignedMemberBudgetsWithPositionForProject: ({
    projectId
  }: {
    projectId: Nullable<number>;
  }) => ProjectUnassignedMemberBudgetWithPosition[];
};

/**
 * Make sure member budget for this projectId and all positions are fetched
 * A project can have many member budgets, but a member budget can only have 1 project membership
 * Phase membership has 1 to 1 relationship with Member Budget
 * "Unassigned Role" is a member budget that has no account_id
 */
const useUnassignedRolesByProjectAndPhases = ({
  projectId
}: {
  projectId: Nullable<number>;
}): HookReturnsTypes => {
  const memberBudgetsByProjectIdWithUnassignedRoles = useAppSelector(
    getMemberBudgetsByProjectIdWithUnassigned
  );
  const projectHash = useAppSelector(getProjectHash);
  const phasesHash = useAppSelector(getFlatPhasesHash);
  const positionHash = useAppSelector(getPositions);
  const memberBudgetsHash = useAppSelector(getMemberBudgets);
  const projectUnassignedRoleCounts = useAppSelector((state) =>
    getOwnProjectUnassignedCounts(state, {
      projectId
    })
  );

  /**
   * (PROJECT LEVEL)
   * List of All unassigned member budgets for this project
   *
   */

  const getUnassignedMemberBudgetsWithPositionForProject = useCallback(
    ({ projectId }: { projectId: Nullable<number> }) => {
      const memberBudgets = isNil(projectId)
        ? emptyArr
        : memberBudgetsByProjectIdWithUnassignedRoles[projectId];

      return memberBudgets
        ? memberBudgets
            .filter(
              (memberBudget) =>
                !memberBudget[`is_discarded?`] && !memberBudget.account_id
            )
            .map((memberBudget) => ({
              ...memberBudget,
              position: {
                ...memberBudget.position,
                nameWithCount: formatUnassignedRoleName({
                  memberBudget,
                  unassignedRoleCounts: projectUnassignedRoleCounts,
                  positionHash,
                  position: memberBudget.position
                }),
                count: getUnassignedRoleCount({
                  memberBudget,
                  unassignedRoleCounts: projectUnassignedRoleCounts
                })
              }
            }))
        : [];
    },
    [
      memberBudgetsByProjectIdWithUnassignedRoles,
      positionHash,
      projectUnassignedRoleCounts
    ]
  );

  const unassignedMemberBudgetsWithPositionForProject = useMemo(() => {
    return getUnassignedMemberBudgetsWithPositionForProject({ projectId });
  }, [getUnassignedMemberBudgetsWithPositionForProject, projectId]);

  /**
   * (PHASE LEVEL)
   * A Hash of phase memberships keyed by phase id, then by member budget id { [phaseId]: { [memberBudgetId]: { ...PhaseMembershipInfo } } }
   * with Unassigned member budget and position information attached
   * Per phase of the provided projectId
   * Note: This hash does not contain phase membership that with memberBudget without a position
   **/
  const phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId =
    useMemo(() => {
      const project: Project | undefined = isNil(projectId)
        ? undefined
        : projectHash[projectId];
      const phases = project?.phase_orders || emptyArr;

      return phases.reduce(
        (
          acc: Record<
            PhaseId,
            Record<
              MemberBudgetId,
              PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
            >
          >,
          phaseId: PhaseId
        ) => {
          const phase: Phase = phasesHash[phaseId];

          if (!phase?.phase_memberships) return acc;

          const phaseMemberships = phase.phase_memberships;

          const phaseMembershipsWithUnassignedMemberBudget = phaseMemberships
            .filter((membership) => {
              const { position } = getMemberBudgetAndPositionForPhaseMembership(
                {
                  membership,
                  memberBudgetsHash,
                  positionHash
                }
              );

              return !membership.account_id && position;
            })
            .map((membership) => {
              const { position, memberBudget } =
                getMemberBudgetAndPositionForPhaseMembership({
                  membership,
                  memberBudgetsHash,
                  positionHash
                });

              return {
                ...membership,
                memberBudget,
                position: {
                  ...position,
                  nameWithCount: formatUnassignedRoleName({
                    memberBudget,
                    unassignedRoleCounts: projectUnassignedRoleCounts,
                    positionHash,
                    position
                  })
                }
              };
            });

          // Filter out to get only the phase memberships with unassigned member budget and position
          acc[phaseId] = keyBy(
            phaseMembershipsWithUnassignedMemberBudget,
            'member_budget_id'
          );

          return acc;
        },
        {}
      );
    }, [
      memberBudgetsHash,
      phasesHash,
      positionHash,
      projectHash,
      projectId,
      projectUnassignedRoleCounts
    ]);

  const unassignedMemberBudgetsWithPositionForProjectHash = useMemo(
    () => keyBy(unassignedMemberBudgetsWithPositionForProject, 'id'),
    [unassignedMemberBudgetsWithPositionForProject]
  );

  /* --------------------------------- Getters -------------------------------- */

  /** Get the phase membership information with member budget and position information */
  const getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId =
    useCallback(
      ({
        phaseId,
        memberBudgetId
      }):
        | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
        | undefined => {
        return phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId?.[
          phaseId
        ]?.[memberBudgetId];
      },
      [
        phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
      ]
    );

  /** Get the phase membership information with member budget and position information */
  const getPhaseUnassignedMemberBudgetIds = useCallback(
    ({ phaseId }): number[] | never[] => {
      const unassignedMemberBudgetIds =
        phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId?.[
          phaseId
        ] || {};
      return Object.keys(unassignedMemberBudgetIds).map((id) => +id);
    },
    [
      phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
    ]
  );

  /** Get the member budget with position information for the provided projectId */
  const getUnassignedMemberBudgetWithPositionForProject = useCallback(
    ({
      memberBudgetId
    }): ProjectUnassignedMemberBudgetWithPosition | undefined => {
      return unassignedMemberBudgetsWithPositionForProjectHash?.[
        memberBudgetId
      ];
    },
    [unassignedMemberBudgetsWithPositionForProjectHash]
  );

  return {
    unassignedMemberBudgetsWithPositionForProject,
    unassignedMemberBudgetsWithPositionForProjectHash,
    phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
    getUnassignedMemberBudgetWithPositionForProject,
    getPhaseUnassignedMemberBudgetIds,
    getUnassignedMemberBudgetsWithPositionForProject
  };
};

export default useUnassignedRolesByProjectAndPhases;
