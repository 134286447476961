import React from 'react';
import PropTypes from 'prop-types';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem as RSDropDownItem,
  DropdownToggle
} from 'reactstrap';
import cn from 'classnames';

export const DropdownItem = ({ children, className, ...props }) => (
  <div className={cn('dropdown-item', className)} {...props}>
    {children}
  </div>
);

DropdownItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func
};

class UserMenu extends React.Component {
  state = {
    settingsOpen: false,
    paymentPlanOpen: false
  };

  render() {
    const {
      userFullName,
      isOpenUserMenu,
      toggleIsOpenUserMenu,
      logoutUser,
      me,
      goToSettings,
      team,
      openSettingsTeamModal,
      sidebarSearchOpen
    } = this.props;

    return (
      <div>
        <Dropdown
          className="user-menu"
          isOpen={isOpenUserMenu}
          toggle={toggleIsOpenUserMenu}
        >
          <DropdownToggle className="user-menu-toggle user-button-padding">
            <span className={cn('username', { hidden: sidebarSearchOpen })}>
              {userFullName}
            </span>
            <FaAngleDown className="down-arrow" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-right">
            {team &&
            me &&
            me.teams_roles &&
            me.teams_roles[team.id] &&
            me.teams_roles[team.id] === 1 ? (
              <div>
                <div className="dropdown-button">
                  <button
                    className="upgrade-button"
                    onClick={openSettingsTeamModal}
                  >
                    <span>Upgrade</span>
                  </button>
                </div>
                <RSDropDownItem divider />
              </div>
            ) : null}
            <DropdownItem
              onClick={() => {
                goToSettings();
                toggleIsOpenUserMenu();
              }}
              className="clickable"
            >
              <i className="settings-ico" />
              Settings
            </DropdownItem>
            <DropdownItem onClick={logoutUser} className="clickable">
              <i className="sign-out-ico" />
              Sign Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

UserMenu.propTypes = {
  isOpenUserMenu: PropTypes.bool.isRequired,
  toggleIsOpenUserMenu: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired
};

export default UserMenu;
