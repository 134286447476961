import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import BatchSelectAllButton, {
  BatchSelectAllButtonWrapper
} from './BatchSelectAllButton';
import {
  makeGetAllIsBatchSelected,
  makeGetSomeIsBatchSelected
} from 'selectors';
import { clearBatchSelectedTasks, setBatchSelectedTasks } from 'actionCreators';
const BatchSelectAllWrapper = styled.div`
  display: flex;
  visibility: ${({ shouldRenderBatchToggle }) =>
    shouldRenderBatchToggle ? 'visible' : 'hidden'};
  border-bottom: ${({ shouldRenderBatchToggle }) =>
    shouldRenderBatchToggle ? '1px solid transparent ' : '0'};
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  left: ${({ showNone, isOnCompletedView, isOnTodayView, isOnHomeView }) =>
    showNone
      ? '-59px'
      : isOnCompletedView
      ? '-49px'
      : isOnTodayView || isOnHomeView
      ? '-56px'
      : '-49px'};

  top: ${({ isOnTodayView }) => (isOnTodayView ? '-10px' : 'inherit')};

  &.virtual-task-list {
    justify-content: center;
    border-bottom: 0;
    left: auto;
    ${BatchSelectAllButtonWrapper} {
      width: auto;
      margin-bottom: 0;
    }
  }
`;

export class BatchSelectAll extends React.Component {
  toggleBatchSelectAll = () => {
    const {
      setBatchSelectedTasks,
      clearBatchSelectedTasks,
      allBatchSelected,
      taskIds,
      taskGroupTaskIds,
      fromTaskGroups
    } = this.props;
    const theTasks = fromTaskGroups ? taskGroupTaskIds : taskIds;
    if (allBatchSelected) {
      clearBatchSelectedTasks({ taskIds: theTasks });
    } else {
      setBatchSelectedTasks({ taskIds: theTasks });
    }
  };

  render() {
    const {
      someBatchSelected,
      allBatchSelected,
      isOnCompletedView,
      isOnTodayView,
      isVirtualTaskList,
      isOnHomeView
    } = this.props;
    return (
      <BatchSelectAllWrapper
        shouldRenderBatchToggle={someBatchSelected}
        showNone={allBatchSelected}
        isOnCompletedView={isOnCompletedView}
        isOnTodayView={isOnTodayView}
        isOnHomeView={isOnHomeView}
        className={isVirtualTaskList ? 'virtual-task-list' : ''}
      >
        <BatchSelectAllButton
          toggleBatchSelectAll={this.toggleBatchSelectAll}
          showNone={allBatchSelected}
          someBatchSelected={someBatchSelected}
          isOnCompletedView={isOnCompletedView}
        />
      </BatchSelectAllWrapper>
    );
  }
}

const makeMapStateToProps = () => {
  const getAllIsBatchSelected = makeGetAllIsBatchSelected();
  const getSomeIsBatchSelected = makeGetSomeIsBatchSelected();
  const mapStateToProps = (state, props) => ({
    allBatchSelected: getAllIsBatchSelected(state, props),
    someBatchSelected: getSomeIsBatchSelected(state, props)
  });
  return mapStateToProps;
};
const mapDispatchToProps = { clearBatchSelectedTasks, setBatchSelectedTasks };
export default connect(makeMapStateToProps, mapDispatchToProps)(BatchSelectAll);
