import React from 'react';
import { searchItemsWithWhiteSpace } from 'appUtils/search';
import Popover from 'components/Popover';

class FilterableFlyout extends React.Component {
  state = {
    searchText: ''
  };

  onInput = (e) => {
    this.setState({ searchText: e.target.value });
  };

  getFilteredItems = () => {
    const { fullItemList, filterKeysArray } = this.props;
    return searchItemsWithWhiteSpace({
      searchText: this.state.searchText,
      itemList: fullItemList,
      filterKeysArray
    });
  };

  getListItems = () => {
    const { ItemComponent, onSelectFactory } = this.props;
    /*
		each 'item' should be object with all properties expected by itemComponent
		e.g. item = {
			name: 'Simple Fractal',
			initials: 'SF'
		}
		const ItemComponent = ({ name, initials, onClick }) => {...}
		*/
    return this.getFilteredItems().map((item, index) => (
      <ItemComponent key={index} {...item} onClick={onSelectFactory(item)} />
    ));
  };

  render() {
    const {
      closeDropdown,
      headerText,
      searchPlaceholder,
      target,
      offset,
      boundariesElement
    } = this.props;
    return (
      <Popover
        target={target}
        isOpen={true}
        closePopover={closeDropdown}
        offset={offset}
        boundariesElement={boundariesElement}
      >
        <div className="filterable-flyout">
          {headerText && <header>{headerText}</header>}
          <input
            className="filterable-input"
            onChange={this.onInput}
            placeholder={searchPlaceholder}
          />
          <ul className="filterable-list">{this.getListItems()}</ul>
        </div>
      </Popover>
    );
  }
}

export default FilterableFlyout;
