import React from 'react';
import { connect } from 'react-redux';
import {
  getPastDueTasks,
  getPastScheduledTasks,
  getDueAtTasks
} from 'selectors';

import { Modal } from 'reactstrap';

import CloseModalIcon from 'components/Modals/CloseModalIcon';
import { CloseModalIconContainer } from 'views/Home/Tasks/styles';

import { BulkUpdateModalTaskList, BulkUpdateModalHeader } from 'views';
import ModalContext from '../../../containers/ModalContext';

class BulkUpdateModal extends React.Component {
  state = {
    bulkUpdateDisplayDate: null
  };

  static contextType = ModalContext;

  updateBulkUpdateDisplayDate = (newDate = null) => {
    this.setState({ bulkUpdateDisplayDate: newDate });
  };

  getTasks = (tasks) => {
    const {
      getDueTasks,
      pastDueTasks,
      pastScheduledTasks,
      selectedFlag,
      selectedDate
    } = this.props;
    const dueTasks = getDueTasks(selectedDate);
    switch (selectedFlag) {
      case 'Due Today':
        return dueTasks;
      case 'Past Due':
        return pastDueTasks;
      case 'Past Schedule':
        return pastScheduledTasks;
      default:
        if (selectedFlag && selectedFlag.startsWith('Due')) {
          return dueTasks;
        }
        return tasks;
    }
  };

  toggle = (e) => {
    const modal = this.context.getModal();
    const { toggle } = this.props;
    if (modal && modal.contains(e.target)) {
      return;
    }
    toggle(e);
  };

  render() {
    const { close, isOpen, updateTasks, tasks, homeTasksView, selectedFlag } =
      this.props;
    const modalTasks = this.getTasks(tasks);
    return (
      <Modal isOpen={isOpen} toggle={this.toggle} className="bulk-update-modal">
        <CloseModalIconContainer data-testid="close-modal-icon" onClick={close}>
          <CloseModalIcon />
        </CloseModalIconContainer>
        <BulkUpdateModalHeader
          updateBulkUpdateDisplayDate={this.updateBulkUpdateDisplayDate}
          bulkUpdateDisplayDate={this.state.bulkUpdateDisplayDate}
          pastScheduledTasks={modalTasks}
          homeTasksView={homeTasksView}
          selectedFlag={selectedFlag}
        />
        <BulkUpdateModalTaskList
          pastScheduledTasks={modalTasks}
          homeTasksView={homeTasksView}
        />
        <div className="footer">
          <div className="modal-button cancel" onClick={close}>
            Cancel
          </div>
          <div className="modal-button done" onClick={updateTasks}>
            Done
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  pastDueTasks: getPastDueTasks(state),
  pastScheduledTasks: getPastScheduledTasks(state),
  getDueTasks: getDueAtTasks(state)
});

export default connect(mapStateToProps)(BulkUpdateModal);
