import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getSkills } from 'selectors';
import { useNavToSettings } from 'SettingsModule/hooks';
import { Skill } from 'models/skill';
import { useLoadSkills } from './useLoadSkills';

const emptyObj = {};

export const useSkills = ({
  isOff,
  shouldAutoLoad
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  /** default is true */
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const skillHash = useAppSelector(getSkills) as Record<number, Skill>;

  const getSkillLabel = useCallback(
    (skillId: number | string) => {
      return skillHash[+skillId]?.name ?? 'No Skill';
    },
    [skillHash]
  );

  const { handleNavToSettings: handleNavToSkillsPage } = useNavToSettings({
    viewType: 'members',
    tab: 'skills'
  });

  const { isLoaded, isLoading, loadSkills } = useLoadSkills({
    isOff,
    shouldAutoLoad
  });

  return {
    isLoaded,
    isLoading,
    loadSkills,
    skillHash,
    handleNavToSkillsPage,
    getSkillLabel
  };
};
