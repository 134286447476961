import { Draft } from '@reduxjs/toolkit';
import {
  DefaultIsoState,
  InferIsoStateTypeFromDraft
} from 'IsoStatesModule/types';
import { defaultInitialIsoState } from 'IsoStatesModule/constants';
import omit from 'lodash/omit';

// util functions for DefaultIsoState

/**
 * Gathers the main record ids from all of the ordersByGroup values and topLevelOrder.
 * Uses keys of ordersByGroup to determine if any entry is a group key/id (in which case
 * it will be ignored). For accuracy, ordersByGroup must have a value for every group and
 * group ids must be distinguishable ie. project-412 and phase-412 are distinguishable, 412 alone is not
 */
export const getAllNonGroupIdsInIsoState = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>(
  isoState: I
) => {
  const { topLevelOrder, ordersByGroup } = isoState;
  return Array.from(
    new Set([...topLevelOrder, ...Object.values(ordersByGroup).flat()])
  ).filter((id) => !ordersByGroup[id]);
};

/**
 * Reset to initialIsoState and set flag to trigger refetch
 */
export const triggerRefetchIsoState = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  nextIsoState,
  initialIsoState
}: {
  nextIsoState: I;
  /** provide when not using DefaultIsoState */
  initialIsoState?: InferIsoStateTypeFromDraft<I>;
}) => {
  Object.assign(nextIsoState, initialIsoState || defaultInitialIsoState);
  nextIsoState.shouldReset = true;
};

/**
 * When there are groupings, total fetched count is the sum of counts of every group's order
 * When no groupings, is topLevelOrder.length
 * Remember to use only after orders of nextIsoState have been updated
 *
 * note: only handles counting non-group ids currently
 */
export const updateTotalFetchedCount = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  nextIsoState,
  groupBy,
  isNotGrouping
}: {
  nextIsoState: I;
  groupBy?: string;
  isNotGrouping?: boolean;
}) => {
  if (!groupBy || isNotGrouping) {
    nextIsoState.totalFetchedCount = nextIsoState.topLevelOrder.length;
  } else {
    const allRecordIds = getAllNonGroupIdsInIsoState(nextIsoState);
    nextIsoState.totalFetchedCount = allRecordIds.length;
  }
};

/**
 * eg. When adding records to groups that are not fully loaded, since we don't know their correct offset, we need to
 * reset the group's order in ordersByGroup, which will trigger refetch (for that group).
 *
 * (On the other hand, groupTotalCounts can be accurately updated)
 */
export const triggerRefetchGroup = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  nextIsoState,
  groupId,
  numToAddToTotalCount = 0
}: {
  nextIsoState: I;
  groupId: string | number;
  numToAddToTotalCount: number;
}) => {
  // increase group's total count. eg. you create a new item and don't know its location in the list so you
  // need to reset the entire list, but know that the total count has grown by 1
  nextIsoState.groupTotalCounts[groupId] += numToAddToTotalCount;
  // subtract the group's order length from total fetched count
  nextIsoState.totalFetchedCount =
    nextIsoState.totalFetchedCount -
    (nextIsoState.ordersByGroup[groupId] as number[]).length;
  nextIsoState.ordersByGroup[groupId] = [];
};

/**
 * Removes a group id if the group no longer has any records
 */
export const removeParentGroupIfNecessary = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  nextIsoState,
  parentGroupId
}: {
  nextIsoState: I;
  parentGroupId: string | number;
}) => {
  if (nextIsoState.groupTotalCounts[parentGroupId] === 0) {
    // We don't know if the parentGroupId is part of the topLevelOrder, or is in a nested group
    // so check both
    nextIsoState.topLevelOrder = nextIsoState.topLevelOrder.filter(
      (groupId) => groupId !== parentGroupId
    );
    Object.entries(nextIsoState.ordersByGroup).forEach(([groupId, order]) => {
      nextIsoState.ordersByGroup[groupId] = order.filter(
        (id) => id !== parentGroupId
      );
    });
    delete nextIsoState.groupTotalCounts[parentGroupId];
    delete nextIsoState.ordersByGroup[parentGroupId];
  }
};

/* -------------------------------- Selection ------------------------------- */

/**
 * Updates isoState.selectedIds and isoState.numSelected based on given ids and isSelect boolean
 */
export const updateSelectedState = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  nextIsoState,
  ids,
  isSelect
}: {
  nextIsoState: I;
  ids: (string | number)[];
  isSelect: boolean;
}) => {
  if (isSelect) {
    nextIsoState.selectedIds = {
      ...nextIsoState.selectedIds,
      ...ids.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as Required<DefaultIsoState>['selectedIds'])
    };
  } else {
    nextIsoState.selectedIds = omit(nextIsoState.selectedIds, ids);
  }
  nextIsoState.numSelected = Object.keys(nextIsoState.selectedIds).length;
};

export const clearSelectedIds = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>(
  nextIsoState: I
) => {
  nextIsoState.selectedIds = {};
  nextIsoState.numSelected = 0;
};

/**
 * Adds all non-group record ids to selectedIds
 */
export const selectAllNonGroupIds = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>(
  nextIsoState: I
) => {
  const ids = getAllNonGroupIdsInIsoState(nextIsoState);
  updateSelectedState({ nextIsoState, ids, isSelect: true });
};

/* ------------------------------------ - ----------------------------------- */

/**
 * Removes the given record ids
 *
 * note: only handles non-group ids currently
 */
export const removeRecordIds = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  nextIsoState,
  idsToRemove,
  parentGroupId,
  isNotGrouping
}: {
  nextIsoState: I;
  idsToRemove: number[];
  parentGroupId?: string | number;
  isNotGrouping?: boolean;
}) => {
  const setOfIdsBeingRemoved = new Set(idsToRemove);

  const getIsNotBeingDeleted = (id: number) => !setOfIdsBeingRemoved.has(id);

  if (isNotGrouping) {
    // no grouping -> topLevelOrder contains the record ids
    nextIsoState.topLevelOrder =
      nextIsoState.topLevelOrder.filter(getIsNotBeingDeleted);
  } else {
    const groupsToCheck =
      parentGroupId !== undefined
        ? [parentGroupId]
        : Object.keys(nextIsoState.ordersByGroup);

    groupsToCheck.forEach((groupId) => {
      // may contain record ids, or more group ids. Therefore group ids must be distinguishable from record ids
      const order = nextIsoState.ordersByGroup[groupId];
      if (order) {
        // for calculating num removed
        const originalCount = order.length;

        // remove the ids from the group
        nextIsoState.ordersByGroup[groupId] =
          order.filter(getIsNotBeingDeleted);

        const numRemoved =
          originalCount -
          (nextIsoState.ordersByGroup[groupId] as number[]).length;
        nextIsoState.groupTotalCounts[groupId] -= numRemoved;
        if (numRemoved > 0) {
          // Remove the parent group id if its order is empty now
          removeParentGroupIfNecessary({
            nextIsoState,
            parentGroupId: groupId
          });
        }
      }
    });
  }

  nextIsoState.totalFetchedCount -= idsToRemove.length;
  nextIsoState.totalCount -= idsToRemove.length;
  updateSelectedState({ nextIsoState, ids: idsToRemove, isSelect: false });
};

/**
 * For use when some id is being moved between groups. Will handle resetting the list as necessary
 * to trigger refetches of groups or entire list
 *
 * Currently only handles groups that are in the topLevelOrder
 */
export const moveIdToDifferentGroup = <
  I extends Draft<DefaultIsoState> = Draft<DefaultIsoState>
>({
  id,
  nextIsoState,
  oldGroupId,
  newGroupId
}: {
  id: number;
  nextIsoState: I;
  oldGroupId: string | number;
  newGroupId: string | number;
}) => {
  // if the new target group does not exist yet, only add it if the list is fully loaded.
  // otherwise, reset the entire list since we don't know the actual location of the group
  if (!nextIsoState.ordersByGroup[newGroupId]) {
    if (nextIsoState.totalCount === nextIsoState.totalFetchedCount) {
      // NOTE: currently adds newGroupId to beginning of the list, which means sort/grouping order may be off
      // could be made more flexible in the future
      nextIsoState.topLevelOrder = [newGroupId, ...nextIsoState.topLevelOrder];
      nextIsoState.ordersByGroup[newGroupId] = [id];
      nextIsoState.groupTotalCounts[newGroupId] = 1;
    } else {
      triggerRefetchIsoState({ nextIsoState });
      return;
    }
  } else {
    // we don't know the actual offset of the moving id, so naive solution is to
    // force the group to refetch by resetting it
    // NOTE: if the group is fully loaded, we could just add the id to the list, but the sort order would be off
    // could be made more flexible in the future
    triggerRefetchGroup({
      nextIsoState,
      groupId: newGroupId,
      numToAddToTotalCount: 1
    });
    // new group's order has not been updated yet, so triggerRefetchGroup will subtract the wrong number from
    // totalFetchedCount. Need to subtract 1 more to adjust for the id being moved
    nextIsoState.totalFetchedCount--;
  }

  // we can update the old group - since we can guarantee that the old group exists and
  // must have had the original id in it
  nextIsoState.groupTotalCounts[oldGroupId] -= 1;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  nextIsoState.ordersByGroup[oldGroupId] = nextIsoState.ordersByGroup[
    oldGroupId
  ]!.filter((requestId) => requestId !== id);

  removeParentGroupIfNecessary({
    nextIsoState,
    parentGroupId: oldGroupId
  });
};
