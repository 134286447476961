import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import ViewFactory from '../../components/ViewFactory/ViewFactory';
import { getMatchedRouteParams } from 'selectors';
import withRouterParams from './WithRouterParams';
import TaskModalRouter from './TaskModalRouter';
import BudgetModalRouter from './BudgetModalRouter';
import MemberModalRouter from './MemberModalRouter';
import ScopeModalRouter from './ScopeModalRouter';

class ViewFactoryRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    return (
      <ViewFactory moduleType="projectDetailView" {...this.props}>
        <Route path={`${match.url}/task/:taskId`} component={TaskModalRouter} />
        <Route
          path={`${match.url}/scope/:scopeId`}
          component={(props) => (
            <ScopeModalRouter {...props} isProjectViewScope />
          )}
        />{' '}
        <Route
          path={`${match.url}/member/:memberId/view/:memberViewType`}
          component={MemberModalRouter}
        />
        <Route
          path={`${match.url}/budgetmodal/:projectId/view/:budgetViewType`}
          component={BudgetModalRouter}
        />
      </ViewFactory>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewFactoryRouter))
);
