import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledTextContainer = styled.ul`
  list-style: outside;
  margin-left: 20px;
`;

const StyledText = styled.li`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  margin-left: -45px;
  text-align: left;
  white-space: nowrap;
`;
const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 90px;
`;

const MemberTitleSubRowCell = ({ row }) => {
  return (
    <StyledTitleContainer>
      <StyledTextContainer>
        <StyledText> {row.original.reason.name} </StyledText>
      </StyledTextContainer>
    </StyledTitleContainer>
  );
};

export default MemberTitleSubRowCell;
