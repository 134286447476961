import { createSelector } from 'reselect';
import { getAuth, getMe } from './core';
import { getSelectedTeam } from './team';
import { ACCESS_ROLES } from 'PermissionsModule/constants';

export const getAccountSlug = (state) =>
  state.auth && state.auth.account && state.auth.account.slug;
export const getUserSlug = (state) =>
  state.users.me ? state.users.me.slug : getAccountSlug(state);

/**
 * @deprecated — Use the typesafe version `getMyUserId` from
 * UsersModule/selectors.
 */
export const getCurrentUserId = createSelector(
  getAuth,
  (auth) => auth && auth.account && auth.account.id
);

export const getUserIsLoaded = createSelector(
  getMe,
  getSelectedTeam,
  (me, selectedTeam) => !!me?.teams_roles && !!selectedTeam?.id
);

/**
 * @deprecated use typesafe selector instead
 */
export const getUserIsAdmin = createSelector(
  getMe,
  getSelectedTeam,
  (me, selectedTeam) =>
    me &&
    selectedTeam &&
    me.teams_roles &&
    me.teams_roles[selectedTeam.id]?.some((role) => role === 1)
);

export const getUserIsFinancialManager = createSelector(
  getMe,
  getSelectedTeam,
  (me, selectedTeam) =>
    me &&
    selectedTeam &&
    me.teams_roles &&
    me.teams_roles[selectedTeam.id]?.some((role) => role === 13)
);

// User's highest role is Workload Manager
export const getUserIsWorkloadManager = createSelector(
  getMe,
  getSelectedTeam,
  (me, selectedTeam) => {
    if (
      me &&
      selectedTeam &&
      me.teams_roles &&
      me.teams_roles[selectedTeam.id]
    ) {
      const myTeamRoles = me.teams_roles[selectedTeam.id];
      return (
        [...myTeamRoles].sort()[myTeamRoles.length - 1] ===
        ACCESS_ROLES.WORKLOAD_MANAGER
      );
    }
    return false;
  }
);

export const getUserNeedsOnboarding = createSelector(
  getMe,
  (me) =>
    me &&
    me.hasCompany !== undefined &&
    me.hasPassword !== undefined &&
    (!me.hasCompany || !me.hasPassword)
);
