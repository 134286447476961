import * as constants from 'appConstants';
import * as capacityConstants from '../constants';
import {
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY
} from 'appConstants/workload';

const { FETCH_CAPACITIES, UPDATE_CAPACITY } = capacityConstants;

export const initialState = {
  accountCapacities: {}
};

const accountCapacities = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_CAPACITIES.SUCCESS: {
      const { account_capacities } = payload.response;
      // activity_phase_schedule_bars is OOO bars
      const newAccountCapacities = account_capacities.reduce(
        (
          capacitiesById,
          { account_id, account_capacity, activity_phase_schedule_bars }
        ) => {
          const total = account_capacity
            ? CAPACITY_DATE_KEYS.reduce(
                (total, day) => +total + +account_capacity[day],
                0
              )
            : DEFAULT_WEEKLY_CAPACITY;
          capacitiesById[account_id] = {
            ...account_capacity,
            total,
            activity_phase_schedule_bars
          };
          return capacitiesById;
        },
        {}
      );
      return {
        ...state,
        accountCapacities: {
          ...state.accountCapacities,
          ...newAccountCapacities
        }
      };
    }
    case UPDATE_CAPACITY.SUCCESS: {
      const { account_capacity } = payload.response;

      const nextAccountCapacity = {
        ...state.accountCapacities[account_capacity.account_id],
        ...account_capacity
      };
      nextAccountCapacity.total = CAPACITY_DATE_KEYS.reduce(
        (total, day) => +total + +nextAccountCapacity[day],
        0
      );

      return {
        ...state,
        accountCapacities: {
          ...state.accountCapacities,
          [account_capacity.account_id]: nextAccountCapacity
        }
      };
    }
    default:
      return state;
  }
};

export default accountCapacities;
