import { reducerMap } from '../reducers';
import reportsSaga from '../sagas';

export function getReportsModule() {
  return {
    id: 'reports',
    reducerMap,
    sagas: [reportsSaga]
  };
}
