import React from 'react';
import { ProjectCommentRow, ProjectReplyCommentContainer } from '../..';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import { withProjectPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/hocs/withProjectPermissionProvider';

class ProjectCommentThreadContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReplyOpen: false
    };
    bindAll(this, ['openReplyInput', 'closeReplyInput']);
  }

  componentDidMount() {
    const { commentThread } = this.props;

    if (
      commentThread &&
      commentThread.replies &&
      commentThread.replies.length
    ) {
      this.setState({ isReplyOpen: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { commentThread } = this.props;
    // both cases below guard against the possibility
    // that the current conditions and the next conditions
    // may be the same, but the expected action has not
    // yet occurred.
    if (
      (commentThread.replies &&
        commentThread.replies.length &&
        !nextProps.commentThread &&
        !nextProps.commentThread.replies &&
        !nextProps.commentThread.replies.length) ||
      (commentThread.replies &&
        !commentThread.replies.length &&
        !nextProps.commentThread &&
        !nextProps.commentThread.replies &&
        !nextProps.commentThread.replies.length)
    ) {
      this.setState({ isReplyOpen: false });
    } else if (
      ((!commentThread.replies ||
        (commentThread.replies && !commentThread.replies.length)) &&
        nextProps.commentThread.replies &&
        nextProps.commentThread.replies.length) ||
      (commentThread.replies &&
        commentThread.replies.length &&
        nextProps.commentThread.replies &&
        nextProps.commentThread.replies.length)
    ) {
      this.setState({ isReplyOpen: true });
    }
  }

  openReplyInput() {
    this.setState({ isReplyOpen: true });
  }

  closeReplyInput() {
    this.setState({ isReplyOpen: false });
  }

  render() {
    const {
      me,
      project,
      commentThread,
      isCreatingComment,
      updateComment,
      deleteComment,
      createComment,
      teamMembers,
      selectedTeamId
    } = this.props;
    const { isReplyOpen } = this.state;
    return (
      <div
        id={`thread-container-${commentThread.id}`}
        className={cn('comment-thread-container', {
          'border-blue': isReplyOpen
        })}
      >
        <div className="comment-thread">
          <ProjectCommentRow
            me={me}
            project={project}
            commentThread={commentThread}
            isCreatingComment={isCreatingComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            teamMembers={teamMembers}
            openReplyInput={this.openReplyInput}
            replyInputOpen={isReplyOpen}
            replies={commentThread.replies}
            selectedTeamId={selectedTeamId}
            permissionProps={{
              accountId: commentThread?.account?.id
            }}
          />
        </div>
        {isReplyOpen && (
          <ProjectReplyCommentContainer
            me={me}
            project={project}
            commentThread={commentThread}
            createComment={createComment}
            isReplyOpen={isReplyOpen}
            closeReplyInput={this.closeReplyInput}
            selectedTeamId={selectedTeamId}
          />
        )}
      </div>
    );
  }
}

export default ProjectCommentThreadContainer;
