export const projectSettingClasses = [
  'project-color-picker',
  'color-picker-button',
  'color-picker-circle-swirl',
  'color-picker-circle',
  'color-picker-square',
  'color-input-container',
  'notification-toggle',
  'notification-bell',
  'my-selected-star',
  'my-unselected-star',
  'before-element',
  'notification-text',
  'project-settings-box'
];

const projectEditClasses = [
  'ico-info-alt',
  'add-members-icon',
  'ico-drag-project',
  'ico-drag-wrapper',
  'project-priority-item',
  'status-item',
  'item-infos',
  'status-date',
  'status-description',
  ...projectSettingClasses
];

export default projectEditClasses;
