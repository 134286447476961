import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectModalHeader, ProjectCommentsContainer } from '../..';
import { closeProjectModal } from 'actionCreators';
import { Modal } from 'reactstrap';
import CloseModalIcon from 'components/Modals/CloseModalIcon';
import { CloseModalIconContainer } from 'views/Home/Tasks/styles';
import { getMe, getSelectedProject } from 'selectors';

const ProjectCommentModal = ({ isOpen, project, closeProjectModal, me }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeProjectModal}
      className="project-comment-container"
    >
      <CloseModalIconContainer
        data-testid="close-modal-icon"
        onClick={closeProjectModal}
      >
        <CloseModalIcon />
      </CloseModalIconContainer>
      <div className="project-comment-modal">
        <ProjectModalHeader project={project} />
        <ProjectCommentsContainer project={project} />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  me: getMe(state),
  isOpen: state.projectComments.isOpen,
  project: getSelectedProject(state)
});

const mapDispatchToProps = {
  closeProjectModal
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectCommentModal)
);
