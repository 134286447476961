import { ReactNode } from 'react';
import { ValueOf } from 'type-fest';
import { permissionsModuleConstants } from 'appCore/navigation/permissions/constants';
import { PermissionsSettingsMembershipTab } from './MembershipTab';
import { PermissionsSettingsAccessTab } from './AccessTab';
import { PermissionsSettingsProjectsTab } from './ProjectsTab';

const { PERMISSIONS_SETTINGS_VIEW_TYPES } = permissionsModuleConstants;

export const PermissionsSettingsViews: Record<
  ValueOf<typeof PERMISSIONS_SETTINGS_VIEW_TYPES>,
  () => ReactNode
> = {
  [PERMISSIONS_SETTINGS_VIEW_TYPES.membership]:
    PermissionsSettingsMembershipTab,
  [PERMISSIONS_SETTINGS_VIEW_TYPES.access]: PermissionsSettingsAccessTab,
  [PERMISSIONS_SETTINGS_VIEW_TYPES.projects]: PermissionsSettingsProjectsTab
};
