import { callApi } from 'appUtils/api';
import { UpdateActivityPhaseMembershipParams } from '../types/activityPhaseMembership';

export const updateActivityPhaseMembership = (
  token: string,
  params: UpdateActivityPhaseMembershipParams
) => {
  const { id, start_date, end_date } = params;
  const body = {
    start_date,
    end_date
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`activity_phase_membership/${id}`, token, fetchInit);
};
