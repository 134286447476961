import { TimesheetStatusId } from 'views/timesheet/Timesheet/types';
import { AccountId } from './account';
import { ActivityId } from './activity';
import { PositionId } from './position';
import { ProjectId, ProjectPriorityId } from 'ProjectsModule/models/project';

export enum Page {
  WorkloadPlanner = 'Workload Planner',
  Teams = 'Teams',
  ProjectTasks = 'Project Tasks',
  Timesheet = 'Timesheet',
  Schedule = 'Schedule',
  Budget = 'Budget',
  Capacity = 'Capacity',
  Utilization = 'Utilization',
  Integration = 'Integration',
  ProfitReport = 'Profit Report',
  Variance = 'Variance',
  Activity = 'Activity',
  TimesheetStatusReport = 'Timesheet Status Report',
  DemandReport = 'Demand Report',
  BudgetTable = 'Budget Table',
  ScopeTable = 'Scope Table',
  BudgetVariance = 'Budget Variance',
  WorkloadForecast = 'Workload Forecast',
  ScheduleVariance = 'Schedule Variance',
  SkillsReport = 'Skills Report',
  CheckInsReport = 'Check Ins Report',
  AllProjectsReport = 'All Projects Report',
  Unplanned = 'Unplanned',
  PtoReport = 'PTO Report',
  TimeProjectionReport = 'Time Projection Report',
  HeadcountReport = 'Headcount Report',
  OrgReport = 'Org Report',
  OvertimeReport = 'Overtime Report',
  SlippageReport = 'Slippage Report',
  MemberModalProjects = 'Member Modal Projects Table',
  FindPeople = 'Find People',

  /* ------------------------------- FE use only ------------------------------ */
  SyncLogOverview = 'Sync Log Overview (FE only)',
  ImportLogOverview = 'Import Log Overview (FE only)',
  CsvImportNewRowsEdit = 'Csv Import New Rows Edit (FE only)',
  MemberAvailabilitySplitView = 'Member Availability Split View (FE only)'
}

export type ClientId = number;

/**
 * Account filter matching BE response
 */
export interface AccountFilter<
  CustomFieldType extends Record<string, unknown> = Record<string, unknown>
> {
  account_id: AccountId;
  account_ids: AccountId[];
  activity_ids: ActivityId[];
  activity_titles: string[];
  board_ids: number[];
  client_ids: ClientId[];
  clients: string[];
  custom: CustomFieldType;
  id: number;
  is_default: Nullable<boolean>; // not sure what this is for
  name: Nullable<string>;
  page: number;
  page_name: Page;
  phase_ids: number[];
  phase_names: string[];
  position_ids: PositionId[];
  priority_ids?: ProjectPriorityId[];
  project_ids: ProjectId[];
  status_ids: TimesheetStatusId[];
  team_id: number;
  team_membership_id: number;
}

export interface StateAccountFilter<
  CustomFieldType extends Record<string, unknown> = Record<string, unknown>
> extends Omit<AccountFilter<CustomFieldType>, 'id'> {
  /** new filters in state will have string ids */
  id: string | number;
  filterChanged?: boolean;
  isNew?: boolean;
}
