import React from 'react';
import { Modal } from 'reactstrap';

const DeleteCommentModal = ({
  isOpen,
  toggle,
  commentId,
  deleteCommentOrReply
}) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    className="delete-modal-container delete-comment-modal"
  >
    <div className="delete-modal-content">
      <div className="delete-modal-header-container">
        <div className="delete-modal-header">Delete Comment</div>
        <div className="close-delete-comment-modal" onClick={() => toggle()} />
      </div>
      <div className="delete-modal-body">
        Are you sure you want to permanently delete this comment? You can&#39;t
        restore a deleted comment.
      </div>
      <div
        className="yes delete-comment-button"
        onClick={() => deleteCommentOrReply(commentId)}
      >
        Yes
      </div>
      <div className="cancel delete-comment-button" onClick={() => toggle()}>
        Cancel
      </div>
    </div>
  </Modal>
);

export default DeleteCommentModal;
