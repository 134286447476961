import { PropsWithChildren } from 'react';
import styled from 'styled-components';
export interface BaseIconButtonProps {
  tooltip: string;
  onClick?: () => void;
}
export const BaseIconButton = ({
  tooltip,
  onClick,
  children
}: PropsWithChildren<BaseIconButtonProps>) => {
  return (
    <IconContainer
      data-for="app-tooltip"
      data-tip={tooltip}
      data-effect="solid"
      onClick={onClick}
    >
      {children}
    </IconContainer>
  );
};

const IconContainer = styled.div`
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  display: inline-flex;
  height: 22px;
  overflow: hidden;
  width: 22px;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  }
`;
