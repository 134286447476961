import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { ProjectCellContainer } from '../styles';
import { StyledDot } from 'views/Home/Widgets/styles';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import FolderIcon from 'icons/FolderIcon';
import WorkCategoryIcon from 'icons/WorkCategoryIcon';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';

const ProjectCellDetails = styled.div`
  padding: 5px 0 5px 35px;
`;

const CustomStyledDot = styled(StyledDot)`
  position: absolute;
  left: 21px;
  top: 19px;
  height: 9px;
  width: 9px;
  margin: 0;
  border-radius: 50%;
`;

const ProjectName = styled.span`
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  font-weight: 600;
`;
const SubText = styled.span`
  display: ${({ block, flex }) => (block ? 'block' : flex ? 'flex' : 'unset')};
  color: ${theme.colors.colorCalendarGray};
  font-size: 11px;
  font-weight: 400;
  line-height: 1.1;

  &.box {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: pre-wrap;
    position: absolute;
    width: 390px;
    line-height: 1.5;
    margin-top: 4px;
    z-index: 1;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ProjectInfo = styled.div`
  width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledFolderIcon = styled(FolderIcon)`
  position: relative;
  top: -1px;
  width: 9px;
  height: 9px;
  path {
    stroke: ${theme.colors.colorCalendarGray};
    stroke-width: 3px;
    fill: transparent;
  }
`;

const PhaseInfo = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconContainer = styled.span`
  margin-right: 2px;
  &.small-diamond-icon {
    padding-left: 6px;
  }
`;

const WorkCategoryContainer = styled.span`
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProjectCell = ({ row }) => {
  const { original } = row;

  return (
    <ProjectCellContainer height={original?.itemHeight}>
      <ProjectCellDetails>
        <SubText block style={{ height: 9 }}>
          {original?.project_number ? `${original.project_number} - ` : ''}
          <IconContainer>
            <StyledFolderIcon />
          </IconContainer>
          {original?.board_name}
        </SubText>
        <div>
          <CustomStyledDot projectId={original?.project_id} />
          <ProjectInfo>
            <ProjectName>{original?.project_name}</ProjectName>
            {original.project_description ? ' - ' : ''}
            <SubText>{original?.project_description}</SubText>
          </ProjectInfo>

          <PhaseInfo>
            {!original.is_like_default && (
              <SubText flex>
                <IconContainer>
                  <BudgetPhaseMilestoneIcon width="9" height="9" />
                </IconContainer>
                {original?.phase_name}
                {(original?.description || !original.is_default_activity) && (
                  <>
                    <IconContainer className="small-diamond-icon">
                      <SmallDiamondIcon />
                    </IconContainer>
                    <WorkCategoryContainer>
                      {original.activity_title}
                    </WorkCategoryContainer>
                  </>
                )}
              </SubText>
            )}
            {(original?.description || !original.is_default_activity) && (
              <SubText className="box">{original.description}</SubText>
            )}
          </PhaseInfo>
        </div>
      </ProjectCellDetails>
    </ProjectCellContainer>
  );
};

export default ProjectCell;
