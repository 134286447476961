import { useState, useRef } from 'react';
import { DependencyDropdownContainer, DependencyMenuHeader } from './styles';

import EllipsisText from 'components/EllipsisText';
import { DEPENDENCY_STRINGS_DISPLAY } from 'appConstants/workload';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import ContentLoader from 'react-content-loader';
import DependencyOptions from './DependencyOptions';
import DependencyRow from './DependencyRow';

const WorkplanDependencySetter = ({
  item,
  itemType,
  disableDependency,
  dependency,
  dependencyStepItem,
  dependencyItem,
  dependencyItemType,
  setDependencyStepItem,
  handleSetDependency,
  listItemContainerStyle = {},
  fixedDependency,
  dropdownStyles = {}
}) => {
  const byId = (item) => item.id;
  const [dependencyMenuIsOpen, setDependencyMenuIsOpen] = useState(false);
  const dependencyPopoverTarget = useRef(null);

  const loadingState = (
    <div style={{ width: '100%' }}>
      <ContentLoader
        height="340"
        primaryColor="#f3f3f3"
        secondaryColor="#eee"
        style={{ width: '100%', marginTop: '-20px' }}
        viewBox="0 0 400 340"
      >
        <rect x="0" y="5" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="70" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="135" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="200" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="265" rx="2" ry="2" width="100%" height="60" />
      </ContentLoader>
    </div>
  );

  const getCopy = () => {
    return {
      headerInitial: 'Select ' + dependencyItemType,
      searchPlaceholder: 'Search',
      emptyState: loadingState
    };
  };

  const renderItem = (props) => {
    const { item } = props;
    const key = item.id;
    const isDisabled = !item.start_date;
    const text = item.description;
    const description = item.name;
    const isValidStart = !!item.start_date;
    const date = `${item.start_date} ${
      item.end_date ? ' - ' + item.end_date : ''
    }`;
    return (
      <DependencyRow
        key={key}
        isDisabled={isDisabled}
        text={text}
        description={description}
        isValidStart={isValidStart}
        date={date}
      />
    );
  };

  const renderHeader = () => {
    const copy = getCopy();
    return <DependencyMenuHeader>{copy.headerInitial}</DependencyMenuHeader>;
  };

  const handleSelect = (e, { item, selectCallback }) => {
    setDependencyStepItem(item);
  };

  const closeDependencyMenu = () => {
    setDependencyMenuIsOpen(false);
  };

  const getItemHeight = () => {
    return 58;
  };

  const itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: [
        'title',
        'description',
        'project_title',
        'project_description',
        'project_number',
        'client',
        'phaseTitles'
      ]
    });

  const itemHeight = 81;

  const loadInitialItems = ({ search }) => {};

  const loadMoreItems = ({ search }) => {};

  return (
    <>
      <DependencyDropdownContainer
        onClick={() => {
          setDependencyMenuIsOpen(true);
        }}
        ref={dependencyPopoverTarget}
        data-testid="calendar-dependency-dropdown"
        style={dropdownStyles}
      >
        <EllipsisText width={245}>
          {!dependencyItem || !dependency
            ? 'No Dependency'
            : `${DEPENDENCY_STRINGS_DISPLAY[dependency]} of Work Plan`}
        </EllipsisText>
      </DependencyDropdownContainer>
      {dependencyMenuIsOpen && !dependencyStepItem && !fixedDependency && (
        <MultiStepFlyout
          copy={getCopy()}
          items={[]}
          getItemId={byId}
          idKey="id"
          renderItem={renderItem}
          renderHeader={renderHeader}
          handleSelect={handleSelect}
          selectCallback={closeDependencyMenu}
          handleSubmit={null}
          itemFilter={itemFilter}
          getItemHeight={getItemHeight}
          isWhite
          searchEnabled={true}
          editDisabled
          top={0}
          target={dependencyPopoverTarget}
          handleClose={closeDependencyMenu}
          loadMoreItems={loadMoreItems}
          loadInitialItems={loadInitialItems}
          hasNextPage={false}
          totalCount={0}
          hideFooter={true}
          itemHeight={itemHeight}
          listItemContainerStyle={listItemContainerStyle}
          emptyContainerClassName="projects-then-phases-dropdown-empty-container"
          popoverClassName={'dependency-list'}
          listWidth="324"
          listHeight={280}
        />
      )}
      {dependencyMenuIsOpen && (dependencyStepItem || fixedDependency) && (
        <DependencyOptions
          disableDependency={disableDependency}
          dependency={dependency}
          dependencyStepItem={dependencyStepItem}
          dependencyStepItemStartDate={dependencyStepItem.start_date}
          dependencyStepItemEndDate={dependencyStepItem.end_date}
          dependencyStepItemName={'Work Plan'}
          setDependencyStepItem={setDependencyStepItem}
          handleSetDependency={handleSetDependency}
          dependencyMenuIsOpen={dependencyMenuIsOpen}
          dependencyPopoverTarget={dependencyPopoverTarget}
          closeDependencyMenu={closeDependencyMenu}
        />
      )}
    </>
  );
};

export default WorkplanDependencySetter;
