import React from 'react';
import cn from 'classnames';
import { isToday } from 'appUtils/momentUtils';
import moment from 'moment';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const formatDate = (date) => date.format('D');

const FixedWeekHeader = ({ visibleTimeStart }) => {
  return (
    <div className="week-header">
      <header>Projects</header>
      {daysOfWeek.map((day, i) => {
        let current = visibleTimeStart;
        if (!moment.isMoment(current)) {
          current = moment(visibleTimeStart);
        }
        const date = current.clone().add(i, 'days');
        const dayIsToday = isToday(date);
        return (
          <span
            key={i}
            className={cn('day', {
              weekend: day === 'Sat' || day === 'Sun',
              today: dayIsToday
            })}
          >
            {`${day}   ${formatDate(date)} `}
          </span>
        );
      })}
    </div>
  );
};

export default FixedWeekHeader;
