import styled from 'styled-components';
import theme from 'theme';
import { GroupedList, SkillLevelFilterListItem } from '../types';
import { StyledKaratRight } from '../styles';

export const SkillHeaderCell = ({
  row
}: {
  row: { original: GroupedList };
}) => {
  const { toggleCollapse, isOpen, listItems, labelText } = row.original;

  const selectedItemsLabel = makeSelectedItemsLabel(
    listItems as SkillLevelFilterListItem[]
  );

  return (
    <StyledSkillHeaderRowCell
      onClick={toggleCollapse}
      className="filter-row-header"
    >
      <StyledKaratRight isOpen={isOpen} height="12px" />
      <span className="no-text-overflow">{labelText}</span>

      <SelectedItemsLabel>{selectedItemsLabel}</SelectedItemsLabel>
    </StyledSkillHeaderRowCell>
  );
};

/* --------------------------------- styling -------------------------------- */

const makeSelectedItemsLabel = (listItems: SkillLevelFilterListItem[]) => {
  const selectedLevels = listItems.reduce<string[]>((acc, listItem) => {
    if (listItem.isSelected && listItem.item.level) {
      acc.push(listItem.item.level);
    }
    return acc;
  }, []);

  let label = '';

  selectedLevels.forEach((level, index) => {
    if (index === 0) {
      label = 'Skill Level ' + level;
    } else if (index === selectedLevels.length - 1) {
      label += ' & ' + level;
    } else {
      label += ', ' + level;
    }
  });

  return label;
};

const StyledSkillHeaderRowCell = styled.div`
  font-size: 13px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray1};
  position: relative;
  width: 100%;
  cursor: pointer;
  padding-left: 5px;

  &:hover {
    background: ${theme.colors.colorTranslucentGray5};
  }
`;

const SelectedItemsLabel = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarBlue};
  padding-left: 20px;

  position: absolute;
  bottom: 4px;
  width: 100%;
  cursor: pointer;
`;
