import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';
import { ValueOf } from 'type-fest';
import { UnassignedMemberBudgetWithPosition } from 'components/roles/types';
import difference from 'lodash/difference';

export const makeEntitiesArrayForEntityRequirementFetch = (
  memberBudgets: UnassignedMemberBudgetWithPosition[],
  entityRequirementType: ValueOf<typeof ENTITY_REQUIREMENT_TYPES>
) =>
  memberBudgets.map((memberBudget) => ({
    entity_type: entityRequirementType,
    entity_id: memberBudget.project_membership.id
  }));

/**
 * Standard util for de-serializing a requirement
 * I.e: skill-1-4 => [skill,1,4]
 * Can also use without itemType like "9-1" => [9,1]
 */
export const deserializeRequirement = (req: string) => req.split('-');

/**
 * Standard util for serializing a requirement
 * I.e: [someRequirement,id] => "someRequirement-id", [skill, 1,3] => "skill-1-3"
 */
export const serializeRequirement = (arr: (number | string)[]) => {
  let str = '';
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      str += arr[i];
    } else {
      str += `-${arr[i]}`;
    }
  }
  return str;
};

export const makeRemoveRequirementIds = (
  previouslySelectedIds: number[],
  nextSelectedIds: number[]
) => {
  return difference(previouslySelectedIds, nextSelectedIds);
};
