import qs from 'qs';
import { callApi } from 'appUtils/api';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { ValueOf } from 'type-fest';

export const fetchDisciplines = (
  id: number,
  token: string,
  { teamId }: { teamId: number }
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify({ team_id: teamId });
  return callApi(`discipline?${stringifiedQueryParams}`, token, fetchInit);
};

export const createDiscipline = (
  token: string,
  { teamId, name }: { teamId: number; name: string }
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ team_id: teamId, name })
  };

  return callApi('discipline', token, fetchInit);
};

export const updateDiscipline = (
  token: string,
  { id, name }: { id: number; name: string }
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ name })
  };

  return callApi(`discipline/${id}`, token, fetchInit);
};

export const deleteDiscipline = (token: string, { id }: { id: number }) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`discipline/${id}`, token, fetchInit);
};

export const updateDisciplineEntities = (
  token: string,
  { id, addEntities, removeEntities }: UpdateDisciplineEntitiesParams
) => {
  const body = convertKeysToSnakeCase({
    addEntities,
    removeEntities
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`discipline/${id}/update_entities`, token, fetchInit);
};

interface UpdateDisciplineEntitiesParams {
  id: string;
  addEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
  removeEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
}
