import React from 'react';
import MemberSelector from 'views/projectPlanner/workloadBarModal/MemberSelector';
import { StyledMemberCell } from 'ReportsModule/components/styles';

const ReadOnlyMembersCell = ({ cell, row }) => {
  const { value } = cell;
  const { original } = row;
  const { member } = original;
  const handleMemberSelect = () => {};
  if (!value) {
    return <div />;
  }
  return (
    <StyledMemberCell>
      <MemberSelector
        accountId={member?.account?.id}
        member={member}
        handleSelect={handleMemberSelect}
        isListItem
        nameStyle=""
        selectionDisabled
        innerDropdownClassName="member-settings"
        popoverClassName="member-select-dropdown"
      />
    </StyledMemberCell>
  );
};

export default ReadOnlyMembersCell;
