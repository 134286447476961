import { useMemo, useCallback, useState } from 'react';
import { useCallbackEffect } from 'appUtils/hooks/useCallbackEffect';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  makeGetAccountFiltersByNameForPageName,
  getAccountFiltersFetched,
  getAccountFilters
} from 'FilterModule/selectors';
import {
  updateAccountFilterLocal,
  updateAccountFilter,
  createAccountFilter,
  clearAccountFiltersLocal
} from 'actionCreators';
import {
  ParsedFilterSchema,
  CurrentFilter,
  ParsedPageFilterSchema,
  DraftFilter,
  FieldSchemasTypeFromCurrentFilter,
  FilterValues
} from 'FilterModule/types';
import { FilterField, isStandardFilterFieldHash } from 'FilterModule/constants';
import { getFilterNameFromFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { StateAccountFilter } from 'models/filter';
import get from 'lodash/get';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import { useFilterListType } from './useFilterListType';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import uuid from 'uuid';
import { newFilter } from 'appUtils/filters';

interface UseCurrentFilterParams<S, CurrentFilterType extends CurrentFilter> {
  filterId?: number;
  currentFilterSchema: S;
  shouldUseFilterLevels: boolean;
  /**
   * Used for preventing bugs in situations where a page may be using both currentFilter and
   * activeFilter, and there are duplicate filters for the same page - name combination.
   * activeFilter selector may not find the same filter as currentFilter logic
   */
  matchingFiltersOverride?: StateAccountFilter[];
  /**
   * Can be used for hacky behaviour like displaying filters based on something that is not a real filter
   * eg. member budget requirements -> can generate a StateAccountFilter based on the values and use that here
   * for displaying filters that match the requirements. In this scenario you should provide
   * overrideUpdate/onSave
   *
   * Keeping this behaviour separate from matchingFiltersOverride to prevent bugs like creating accidental filters
   */
  matchingFakeFiltersOverride?: Partial<StateAccountFilter>[];
  /**
   * Replaces dispatching updateAccountFilterLocal for filters that are in matchingFakeFiltersOverride
   */
  overrideUpdate?: CurrentFilterType['update'];
  /**
   * Will be called during currentFilter.save.
   * Only filters that are not in matchingFakeFiltersOverride will be saved with default behaviour, so this
   * can be used to handle saving fake filters
   */
  onSave?: CurrentFilterType['save'];
}

export const useCurrentFilter = <
  CurrentFilterType extends CurrentFilter,
  S extends ParsedFilterSchema
>({
  filterId,
  currentFilterSchema,
  shouldUseFilterLevels,
  matchingFiltersOverride,
  matchingFakeFiltersOverride,
  overrideUpdate,
  onSave
}: UseCurrentFilterParams<S, CurrentFilterType>) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();
  const isAccountFiltersFetched = useAppSelector(getAccountFiltersFetched);
  const accountFilterHash = useAppSelector(getAccountFilters);

  const saveFilterChainId = useMemo(() => `save-filter-chain-${uuid.v4()}`, []);
  const { status: saveFilterChainStatus } = useRequestStatus({
    requestStatusId: saveFilterChainId
  });

  const isSaving = saveFilterChainStatus?.isExecuting || false;

  const pageName = shouldUseFilterLevels
    ? (currentFilterSchema as ParsedPageFilterSchema).meta.level1
    : undefined;

  const getAccountFiltersByNameForPageName = useMemo(
    makeGetAccountFiltersByNameForPageName,
    []
  );

  const accountFiltersByNameForPageName = useAppSelector((state) =>
    getAccountFiltersByNameForPageName(state, { pageName })
  );

  const accountFiltersByNameForPageNameOverride = useMemo(() => {
    return matchingFiltersOverride
      ? keyBy(matchingFiltersOverride, (filter) => filter.name || '')
      : undefined;
  }, [matchingFiltersOverride]);

  /**
   * Takes precendence over accountFiltersByNameForPageNameOverride
   */
  const fakeAccountFiltersByNameForPageNameOverride = useMemo(() => {
    return matchingFakeFiltersOverride
      ? keyBy(matchingFakeFiltersOverride, (filter) => filter.name || '')
      : undefined;
  }, [matchingFakeFiltersOverride]);

  /**
   * Note: if any values were replaced by validators, their original values will still be
   * saved to the BE. Not sure if it makes any difference yet since currentFilter will hold only valid values
   */
  const saveCurrentFilter: CurrentFilterType['save'] = useCallback(
    (fieldsToSave) => {
      if (!isAccountFiltersFetched || isSaving) return;

      if (onSave) {
        onSave(fieldsToSave);
      }

      const fieldsToSaveSet = fieldsToSave ? new Set(fieldsToSave) : undefined;

      if (shouldUseFilterLevels) {
        // params for updating existing filters
        const updateParamsForExistingFiltersByLevelName = {};
        // params for creating new filters
        const createParamsByLevelName = {};

        // For filtering out filter fields that should not be saved to the BE.
        const fieldsToNotSave = Object.entries(
          currentFilterSchema.fields
        ).reduce((acc, [fieldName, fieldProperties]) => {
          // Treat default as true. Option to not save must be explicitly stated
          if (
            fieldProperties.isSaveable === false ||
            fieldName.endsWith('_local') ||
            (fieldsToSaveSet && !fieldsToSaveSet.has(fieldName))
          ) {
            acc.push(
              isStandardFilterFieldHash[fieldName]
                ? fieldName
                : `custom.${fieldName}`
            );
          }
          return acc;
        }, [] as string[]);

        (
          currentFilterSchema as ParsedPageFilterSchema
        ).meta.filterLevelNames.forEach((levelName) => {
          const filter = getMatchingFilterForLevelName(
            levelName,
            accountFiltersByNameForPageName,
            accountFiltersByNameForPageNameOverride,
            fakeAccountFiltersByNameForPageNameOverride
          );

          if (filter && filter.filterChanged) {
            // fake filters (the ones in matchingFakeFiltersOverride) will not be saved to the BE. onSave
            // should handle those
            if (
              filter.name &&
              fakeAccountFiltersByNameForPageNameOverride?.[filter.name]
            ) {
              return;
            }

            // fields that are FE only and will not be included in the request
            const fieldsToRemove = [
              'filterChanged',
              'isNew',
              ...fieldsToNotSave,
              ...(filter.isNew ? ['id'] : [])
            ];
            const params = omit(filter, fieldsToRemove);
            if (filter.isNew) {
              createParamsByLevelName[levelName] = {
                ...params,
                page: params.page_name // BE accepts page name for page param
              };
            } else {
              updateParamsForExistingFiltersByLevelName[levelName] = params;
            }
          }
        });

        const actions = [
          ...Object.values(updateParamsForExistingFiltersByLevelName).map(
            (params) => updateAccountFilter(params)
          ),
          ...Object.values(createParamsByLevelName).map((params) =>
            createAccountFilter(params)
          )
        ];

        if (actions.length > 0) {
          // using dispatchChain so that we can use 1 requestStatus
          dispatchChain(actions, {
            chainId: saveFilterChainId,
            continueOnFailure: true,
            continueOnCancellation: true
          } as any);
        }
      } else {
        // TODO
      }
    },
    [
      accountFiltersByNameForPageName,
      accountFiltersByNameForPageNameOverride,
      fakeAccountFiltersByNameForPageNameOverride,
      currentFilterSchema,
      dispatchChain,
      shouldUseFilterLevels,
      isAccountFiltersFetched,
      isSaving,
      saveFilterChainId,
      onSave
    ]
  );

  /**
   * For when update + save are called in sequence, need to make sure saveCurrentFilter has the
   * correct dependencies
   */
  const saveCurrentFilterAfterRender = useCallbackEffect(saveCurrentFilter);

  const updateCurrentFilterLocal: CurrentFilterType['update'] = useCallback(
    (values, options) => {
      if (shouldUseFilterLevels) {
        const paramsByFilterName: Record<
          string,
          Partial<StateAccountFilter>
        > = {};

        Object.keys(values).forEach((field) => {
          const levelName = currentFilterSchema.fields[field]?.levelName;
          if (levelName) {
            // see note on getFilterNameFromFilterLevelName for why this step is taken
            const filterName = getFilterNameFromFilterLevelName(levelName);

            const originalFilter: Partial<StateAccountFilter> =
              fakeAccountFiltersByNameForPageNameOverride?.[filterName] ||
                accountFiltersByNameForPageNameOverride?.[filterName] ||
                accountFiltersByNameForPageName?.[filterName] || {
                  ...newFilter,
                  id: levelName,
                  name: filterName,
                  page_name: pageName,
                  isNew: true
                };

            // when using overrideUpdate, pass the unformatted values
            paramsByFilterName[filterName] =
              fakeAccountFiltersByNameForPageNameOverride?.[filterName] &&
              overrideUpdate
                ? values
                : makeUpdateFilterParams(originalFilter, values);
          }
        });

        batch(() => {
          Object.entries(paramsByFilterName).forEach(([filterName, values]) => {
            // handle fake filters using overrideUpdate
            if (fakeAccountFiltersByNameForPageNameOverride?.[filterName]) {
              overrideUpdate && overrideUpdate(values);
            } else {
              dispatch(updateAccountFilterLocal(values));
            }
          });
        });
      } else {
        // TODO
      }

      // workaround for being able to save values immediately (vs calling currentFilter.update
      // then currentFilter.save after)
      if (options?.fieldsToSaveAfterUpdate || options?.shouldSaveAfterUpdate) {
        saveCurrentFilterAfterRender(options?.fieldsToSaveAfterUpdate);
      }
    },
    [
      accountFiltersByNameForPageName,
      accountFiltersByNameForPageNameOverride,
      fakeAccountFiltersByNameForPageNameOverride,
      currentFilterSchema.fields,
      dispatch,
      pageName,
      shouldUseFilterLevels,
      overrideUpdate,
      saveCurrentFilterAfterRender
    ]
  );

  const currentFilter: CurrentFilterType = useMemo(() => {
    /**
     * Clears any local filters that have not been saved to the BE (ie. when filter id = filter level name)
     */
    const clearLocalUnsavedFilters = () => {
      const filterIdsToClear = currentFilterSchema.meta?.filterLevelNames;
      if (filterIdsToClear) {
        dispatch(clearAccountFiltersLocal({ ids: filterIdsToClear }));
      }
    };

    /**
     * Clears the given fields, or clears all values when no fields given
     */
    const reset: CurrentFilter['reset'] = (fields) => {
      const fieldsToReset = Array.isArray(fields)
        ? fields
        : fields
        ? [fields]
        : Object.keys(currentFilterSchema.fields);

      const updatedFieldValues = pick(
        currentFilterSchema.initialValues,
        fieldsToReset
      );

      updateCurrentFilterLocal(updatedFieldValues);
    };

    /**
     * The actual BE filters that matches filterId (when using a single filter) or the levelNames
     * of the filter fields (when using filter levels)
     */
    const matchingFilters = getMatchingFilters({
      filterId,
      accountFilterHash,
      accountFiltersByNameForPageName,
      accountFiltersByNameForPageNameOverride,
      fakeAccountFiltersByNameForPageNameOverride,
      currentFilterSchema,
      shouldUseFilterLevels
    });

    return {
      ...makeMergedFilter({
        currentFilterSchema,
        filterId,
        accountFiltersByNameForPageName,
        accountFiltersByNameForPageNameOverride,
        fakeAccountFiltersByNameForPageNameOverride,
        matchingFilters,
        shouldUseFilterLevels,
        update: updateCurrentFilterLocal,
        save: saveCurrentFilter,
        isSaving,
        isLoading: !isAccountFiltersFetched
      }),
      reset,
      clearLocalUnsavedFilters
    };
  }, [
    accountFilterHash,
    accountFiltersByNameForPageName,
    accountFiltersByNameForPageNameOverride,
    fakeAccountFiltersByNameForPageNameOverride,
    currentFilterSchema,
    filterId,
    shouldUseFilterLevels,
    updateCurrentFilterLocal,
    saveCurrentFilter,
    isSaving,
    isAccountFiltersFetched,
    dispatch
  ]);

  /* ------------------------------ draft filter ------------------------------ */

  const [draftFilterValues, setDraftFilterValues] = useState<
    Partial<FilterValues<FieldSchemasTypeFromCurrentFilter<CurrentFilterType>>>
  >({});

  const draftFilter: DraftFilter<CurrentFilterType> = useMemo(() => {
    const update: DraftFilter['update'] = (values) => {
      const newValues = {};

      Object.keys(values).forEach((field) => {
        const levelName = currentFilterSchema.fields[field]?.levelName;
        if (levelName) {
          newValues[field] = values[field];
        }
      });

      setDraftFilterValues((current) => ({ ...current, ...newValues }));
    };

    /**
     * Saves the given fields (or all draftFilterValues when no fields arg given) - Updates the local (redux) filters
     */
    const saveLocally: DraftFilter['saveLocally'] = (fieldsToSave) => {
      const valuesToSave = fieldsToSave
        ? pick(draftFilterValues, fieldsToSave)
        : null;
      updateCurrentFilterLocal(valuesToSave || draftFilterValues);
    };

    /**
     * Saves the given fields (or all draftFilterValues when no fields arg given).
     * Updates the local (redux) filters first, then saves. This is done only to prevent having
     * to change existing currentFilter.save logic
     */
    const save: DraftFilter['save'] = (fieldsToSave) => {
      saveLocally(fieldsToSave);
      // save after function reference has updated (due to changes in updateCurrentFilterLocal)
      // note this currently saves all local filter changes, not just the specified fields
      saveCurrentFilterAfterRender(fieldsToSave);
    };

    /**
     * Clears the given fields from draftFilterValues, or clears all values when no fields given
     */
    const reset: DraftFilter['reset'] = (fields) => {
      setDraftFilterValues(
        fields?.length
          ? (omit(draftFilterValues, fields) as typeof draftFilterValues)
          : {}
      );
    };

    return {
      ...draftFilterValues,
      update,
      save,
      saveLocally,
      reset,
      meta: {
        hasChanges: Object.keys(draftFilterValues).length > 0
      }
    };
  }, [
    currentFilterSchema.fields,
    draftFilterValues,
    updateCurrentFilterLocal,
    saveCurrentFilterAfterRender
  ]);

  const {
    filterListType: mainFilterListType,
    filterListField: mainFilterListField,
    filterListFieldsToValue: mainFilterListFieldsToValue,
    numFilteredItems: mainFilterListNumSelected
  } = useFilterListType({
    currentFilter,
    keyOfFilterListType:
      FilterField.mainFilterListType_local in currentFilterSchema.fields
        ? FilterField.mainFilterListType_local
        : FilterField.mainFilterListType
  });

  return {
    currentFilter,
    draftFilter,
    mainFilterListType,
    mainFilterListField,
    mainFilterListFieldsToValue,
    mainFilterListNumSelected
  };
};

// -----------------------------------------------------------------------------
//                                utils
// -----------------------------------------------------------------------------

const getMatchingFilterForLevelName = (
  filterLevelName: string | undefined,
  accountFiltersByNameForPageName?: Record<string, StateAccountFilter>,
  accountFiltersByNameForPageNameOverride?: Record<string, StateAccountFilter>,
  fakeAccountFiltersByNameForPageNameOverride?: Record<
    string,
    Partial<StateAccountFilter>
  >
) => {
  let matchingFilter: Partial<StateAccountFilter> | undefined;
  if (filterLevelName) {
    const filterName = getFilterNameFromFilterLevelName(filterLevelName);
    matchingFilter =
      fakeAccountFiltersByNameForPageNameOverride?.[filterName] ||
      accountFiltersByNameForPageNameOverride?.[filterName] ||
      accountFiltersByNameForPageName?.[filterName];
  }
  return matchingFilter;
};

/**
 * Finds matching BE filters by levelNames (when using levels), or id
 */
export const getMatchingFilters = ({
  shouldUseFilterLevels,
  currentFilterSchema,
  accountFiltersByNameForPageName,
  accountFiltersByNameForPageNameOverride,
  fakeAccountFiltersByNameForPageNameOverride,
  accountFilterHash,
  filterId
}: {
  shouldUseFilterLevels: boolean;
  currentFilterSchema: ParsedFilterSchema;
  accountFiltersByNameForPageName?: Record<string, StateAccountFilter>;
  accountFiltersByNameForPageNameOverride?: Record<string, StateAccountFilter>;
  fakeAccountFiltersByNameForPageNameOverride?: Record<
    string,
    Partial<StateAccountFilter>
  >;
  accountFilterHash?: Record<number, StateAccountFilter>;
  filterId?: number;
}): Partial<StateAccountFilter>[] => {
  if (shouldUseFilterLevels) {
    // Find existing filters (if any) that have matching level names with the schema
    const matchingFiltersSet = new Set<Partial<StateAccountFilter>>();

    Object.values(currentFilterSchema.fields).forEach(({ levelName }) => {
      const matchingFilter = getMatchingFilterForLevelName(
        levelName,
        accountFiltersByNameForPageName,
        accountFiltersByNameForPageNameOverride,
        fakeAccountFiltersByNameForPageNameOverride
      );

      if (matchingFilter) {
        matchingFiltersSet.add(matchingFilter);
      }
    });

    return Array.from(matchingFiltersSet);
  } else {
    const matchingFilter = filterId ? accountFilterHash?.[filterId] : null;
    return matchingFilter ? [matchingFilter] : [];
  }
};

/**
 * Replaces the initial/default value of a currentFilter field with the actual value from matchingFilter
 */
const mergeWithMatchingFilter = ({
  currentFilter,
  matchingFilter,
  field
}: {
  currentFilter: CurrentFilter;
  matchingFilter: Partial<StateAccountFilter>;
  field: string;
}) => {
  const fieldKey = isStandardFilterFieldHash[field] ? field : `custom.${field}`;
  const actualValue = get(matchingFilter, fieldKey);

  // note: this means 'undefined' should not be used as a valid filter value. use null instead
  if (actualValue !== undefined) {
    currentFilter[field] = actualValue;
  }
};

/**
 * Returns the currentFilter using the schema and the actual values from any matchingFilters
 */
export const makeMergedFilter = <
  CurrentFilterType extends CurrentFilter,
  S extends ParsedFilterSchema
>({
  currentFilterSchema,
  filterId,
  matchingFilters,
  accountFiltersByNameForPageName,
  accountFiltersByNameForPageNameOverride,
  fakeAccountFiltersByNameForPageNameOverride,
  shouldUseFilterLevels,
  update,
  save,
  isSaving,
  isLoading
}: {
  currentFilterSchema: S;
  filterId?: number;
  matchingFilters: Partial<StateAccountFilter>[];
  accountFiltersByNameForPageName?: Record<string, StateAccountFilter>;
  accountFiltersByNameForPageNameOverride?: Record<string, StateAccountFilter>;
  fakeAccountFiltersByNameForPageNameOverride?: Record<
    string,
    Partial<StateAccountFilter>
  >;
  shouldUseFilterLevels: boolean;
  update: CurrentFilterType['update'];
  save: CurrentFilterType['save'];
  isSaving: boolean;
  isLoading: boolean;
}) => {
  const initialValues = currentFilterSchema.initialValues;
  const mergedFilter = <CurrentFilterType>{
    meta: {},
    update,
    save,
    ...initialValues
  };

  if (shouldUseFilterLevels) {
    mergedFilter.meta = {
      pageName: (currentFilterSchema as ParsedPageFilterSchema).meta.level1,
      filterIds: matchingFilters.map((filter) => filter.id).filter(Boolean) as (
        | string
        | number
      )[],
      hasChanges: matchingFilters.some((filter) => filter.filterChanged),
      isSaving,
      isLoading
    };

    // merge any relevant field values to replace the initial values
    Object.keys(initialValues).forEach((field) => {
      const fieldData = currentFilterSchema.fields[field];
      const validator = fieldData?.validator;
      const optionsConfig = fieldData?.optionsConfig;
      const levelName = fieldData?.levelName;

      const matchingFilter = getMatchingFilterForLevelName(
        levelName,
        accountFiltersByNameForPageName,
        accountFiltersByNameForPageNameOverride,
        fakeAccountFiltersByNameForPageNameOverride
      );

      if (matchingFilter) {
        mergeWithMatchingFilter({
          currentFilter: mergedFilter,
          matchingFilter,
          field
        });
      }

      // validate the field value
      if (validator) {
        (mergedFilter as CurrentFilter)[field] = validator({
          originalFieldValue: mergedFilter[field],
          optionHash: optionsConfig?.optionHash,
          defaultValue: initialValues[field],
          currentFilterSchema,
          // note that this is mergedFilter UP TO THIS POINT. Meaning the validator should only look at
          // the fields of currentFilter that have already been seen in the loop
          currentFilter: mergedFilter
        });
      }
    });
  } else {
    // todo: fix this. filter instances not used/tested yet in this new filter infra
    mergedFilter.meta = {
      filterId,
      isLoading
    };
    const matchingFilter = matchingFilters[0];
    if (matchingFilter) {
      Object.keys(initialValues).forEach((field) => {
        const fieldData = currentFilterSchema.fields[field];
        const validator = fieldData?.validator;
        const optionsConfig = fieldData?.optionsConfig;

        if (matchingFilter) {
          mergeWithMatchingFilter({
            currentFilter: mergedFilter,
            matchingFilter,
            field
          });
        }

        // validate the field value
        if (validator) {
          (mergedFilter as CurrentFilter)[field] = validator({
            originalFieldValue: mergedFilter[field],
            optionHash: optionsConfig?.optionHash,
            defaultValue: initialValues[field],
            currentFilterSchema,
            // note that this is mergedFilter UP TO THIS POINT. Meaning the validator should only look at
            // the fields of currentFilter that have already been seen in the loop
            currentFilter: mergedFilter
          });
        }
      });
    }
  }

  return mergedFilter;
};

export const makeUpdateFilterParams = (
  originalFilter: Partial<StateAccountFilter>,
  values: Record<string, unknown>
) => {
  const params = { ...originalFilter };
  Object.entries(values).forEach(([field, value]) => {
    if (isStandardFilterFieldHash[field]) {
      params[field] = value;
    } else {
      params.custom = {
        ...params.custom,
        [field]: value
      };
    }
  });
  return params;
};
