import { createRequestTypes } from 'appUtils';

export const FETCH_PHASE_TOTALS_BY_BOARD = createRequestTypes(
  'FETCH_PHASE_TOTALS_BY_BOARD'
);
export const FETCH_PHASE_TOTALS = createRequestTypes('FETCH_PHASE_TOTALS');
export const FETCH_PHASE_TOTALS_BUDGET_REPORT = createRequestTypes(
  'FETCH_PHASE_TOTALS_BUDGET_REPORT'
);
export const FETCH_PHASES_BY_PROJECT_IDS = createRequestTypes(
  'FETCH_PHASES_BY_PROJECT_IDS'
);
export const FETCH_PHASES = createRequestTypes('FETCH_PHASES');
export const FETCH_PHASE_NAMES = createRequestTypes('FETCH_PHASE_NAMES');
export const FETCH_FILTERED_PHASES = createRequestTypes(
  'FETCH_FILTERED_PHASES'
);
export const CREATE_PHASE = createRequestTypes('CREATE_PHASE');
export const PREDICT_PHASE = createRequestTypes('PREDICT_PHASE');
export const CONVERT_PHASE_TO_DEFAULT = createRequestTypes(
  'CONVERT_PHASE_TO_DEFAULT'
);
export const UPDATE_PHASE = createRequestTypes('UPDATE_PHASE');
export const DELETE_PHASE = createRequestTypes('DELETE_PHASE');
export const HARD_DELETE_PHASE = createRequestTypes('HARD_DELETE_PHASE');
export const ARCHIVE_PHASE = createRequestTypes('ARCHIVE_PHASE');
export const PREDICT_WORKDAYS_AND_UPDATE_PHASE = createRequestTypes(
  'PREDICT_WORKDAYS_AND_UPDATE_PHASE'
);

export const FETCH_PHASE_MEMBERS = createRequestTypes('FETCH_PHASE_MEMBERS');
export const CREATE_PHASE_MEMBERS = createRequestTypes('CREATE_PHASE_MEMBERS');
export const ARCHIVE_PHASE_MEMBER = createRequestTypes('ARCHIVE_PHASE_MEMBER');
export const DELETE_PHASE_MEMBER = createRequestTypes('DELETE_PHASE_MEMBER');

export const UPDATE_PHASE_MEMBERSHIP = createRequestTypes(
  'UPDATE_PHASE_MEMBERSHIP'
);

export const FETCH_PHASE_TEMPLATES = createRequestTypes(
  'FETCH_PHASE_TEMPLATES'
);
export const CREATE_PHASE_TEMPLATE = createRequestTypes(
  'CREATE_PHASE_TEMPLATE'
);
export const UPDATE_PHASE_TEMPLATE = createRequestTypes(
  'UPDATE_PHASE_TEMPLATE'
);
export const DELETE_PHASE_TEMPLATE = createRequestTypes(
  'DELETE_PHASE_TEMPLATE'
);
export const REORDER_PHASE_TEMPLATES = createRequestTypes(
  'REORDER_PHASE_TEMPLATES'
);
export const CREATE_MILESTONE_TEMPLATE = createRequestTypes(
  'CREATE_MILESTONE_TEMPLATE'
);
export const REORDER_MILESTONE_TEMPLATES = createRequestTypes(
  'REORDER_MILESTONE_TEMPLATES'
);
export const OPEN_PHASE_TEMPLATE_DROPDOWN = 'OPEN_PHASE_TEMPLATE_DROPDOWN';
export const CLOSE_PHASE_TEMPLATE_DROPDOWN = 'CLOSE_PHASE_TEMPLATE_DROPDOWN';
export const OPEN_PHASE_TEMPLATE_MODAL = 'OPEN_PHASE_TEMPLATE_MODAL';
export const CLOSE_PHASE_TEMPLATE_MODAL = 'CLOSE_PHASE_TEMPLATE_MODAL';
export const OPEN_PHASE_MODAL = 'OPEN_PHASE_MODAL';
export const CLOSE_PHASE_MODAL = 'CLOSE_PHASE_MODAL';
export const OPEN_ACTIVITY_PHASE_MODAL = 'OPEN_ACTIVITY_PHASE_MODAL';
export const CLOSE_ACTIVITY_PHASE_MODAL = 'CLOSE_ACTIVITY_PHASE_MODAL';
export const CREATE_BULK_PHASES = createRequestTypes('CREATE_BULK_PHASES');
