import { useContext, createContext, ReactNode } from 'react';
import { useWorkplanPermissionState } from '../hooks/useWorkplanPermissionState';

interface WorkplanPermissionProviderProps {
  children: ReactNode;
}

export type WorkPlanPermissionGetter = (values?: {
  accountId?: Nullable<number>;
}) => boolean;

interface WorkplanPermissionContextValues {
  getCanDeleteWorkPlan: WorkPlanPermissionGetter;
  getCanEditWorkPlan: WorkPlanPermissionGetter;
  getCanCreateWorkPlan: WorkPlanPermissionGetter;
  getCanDragWorkPlan: WorkPlanPermissionGetter;
  getCanResizeWorkPlan: WorkPlanPermissionGetter;
  getCanCopyWorkPlan: WorkPlanPermissionGetter;
  getCanSplitWorkPlan: WorkPlanPermissionGetter;
  getCanImportWorkPlan: WorkPlanPermissionGetter;
  getCanAddWorkPlanComment: WorkPlanPermissionGetter;
  getCanEditWorkPlanTask: WorkPlanPermissionGetter;
  getCanRemoveWorkPlanTask: WorkPlanPermissionGetter;
  getCanEditWorkPlanScope: WorkPlanPermissionGetter;
  getCanRemoveWorkPlanScope: WorkPlanPermissionGetter;
}

const WorkplanPermissionContext = createContext(
  {} as WorkplanPermissionContextValues
);
export const WorkplanPermissionConsumer = WorkplanPermissionContext.Consumer;

export const WorkplanPermissionProvider = ({
  children
}: WorkplanPermissionProviderProps) => {
  const workplanPermissions = useWorkplanPermissionState();

  const value: WorkplanPermissionContextValues = {
    ...workplanPermissions
  };

  return (
    <WorkplanPermissionContext.Provider value={value}>
      {children}
    </WorkplanPermissionContext.Provider>
  );
};

export const useWorkplanPermission = () =>
  useContext(WorkplanPermissionContext);
