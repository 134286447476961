import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportOffice
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingOfficesByName: Record<string, CsvImportOffice>;
  newOfficeNames: React.MutableRefObject<Record<string, boolean>>;
}

export const officeValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingOfficesByName, newOfficeNames } = additionalParams;

  const { enteredOfficeName } = getRowValuesForEntityType(row, entityType);

  const existingOfficeMatchedByEnteredName =
    existingOfficesByName[enteredOfficeName];

  const isNewOfficeName =
    enteredOfficeName &&
    !existingOfficeMatchedByEnteredName &&
    !newOfficeNames.current[enteredOfficeName];

  if (enteredOfficeName === TRAINING_DATA_REQUIRED_STRING) return false;
  // allow new office names, add them to newOfficeNames for display in options dropdown
  else if (isNewOfficeName) {
    newOfficeNames.current[enteredOfficeName] = true;
  }
  return true;
};
