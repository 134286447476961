import { select } from 'redux-saga/effects';
import { getAuthToken } from 'selectors';
import { changeEntity as entityHelper } from 'sagas/generics';
import {
  fetchTeamRoleTemplates,
  fetchTeamRoleTemplatesActionCreatorsMap,
  updateTeamRoleTemplate,
  updateTeamRoleTemplateActionCreatorsMap
} from './actionCreators';
import { roleTemplatesApi } from './api';

function* fetchTeamRoleTemplatesWorker(
  action: ReturnType<typeof fetchTeamRoleTemplates>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchTeamRoleTemplatesActionCreatorsMap,
    roleTemplatesApi.fetchTeamRoleTemplates,
    [token, action.payload],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

function* updateTeamRoleTemplateWorker(
  action: ReturnType<typeof updateTeamRoleTemplate>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    updateTeamRoleTemplateActionCreatorsMap,
    roleTemplatesApi.updateTeamRoleTemplate,
    [token, action.payload],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

export default { fetchTeamRoleTemplatesWorker, updateTeamRoleTemplateWorker };
