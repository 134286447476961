import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Avatar from 'react-avatar';
import { EditPersonalSettings, BodyColor } from '..';
import { isUserTeamAdmin } from 'appUtils';
import ColorPicker from 'components/ColorPicker';
import cn from 'classnames';
import styled from 'styled-components';
import { StyledColorPicker } from 'components/ColorPicker/styles';
import { calculateColorPref } from 'appUtils/styleUtils';
import { navigateToTeamSettings } from 'actionCreators';
import { connect } from 'react-redux';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const StyledAvatar = styled(Avatar)`
  background-color: ${calculateColorPref} !important;
`;

const ColorPickerColumn = styled(Col)`
  display: flex;
  justify-content: flex-start;

  .change-color {
    color: #2f80ed;
    font-size: 14px;
    white-space: nowrap;
  }

  ${StyledColorPicker} {
    flex-direction: column;
  }
`;

class PersonalSettings extends Component {
  setColorPickerRef = (ref) => (this.colorPickerRef = ref);
  onColorPickerClick = () => {
    this.colorPickerRef.handleClick();
  };

  logout = () => {
    const { logoutUser } = this.props;
    logoutUser();
  };

  navigateToTeamSettings = (team) => {
    const { navigateToTeamSettings } = this.props;
    navigateToTeamSettings({ teamSlug: team.slug });
  };

  render() {
    const {
      me,
      teams,
      openEditModal,
      closeEditModal,
      isPersonalModalOpen,
      closeTeamModal,
      billing,
      toggleSwitch,
      isEmailNotificationOn,
      myTeamMembership = {}
    } = this.props;
    return (
      <div className="personal-settings-container">
        <EditPersonalSettings
          isOpen={isPersonalModalOpen}
          closeEditModal={closeEditModal}
          me={me}
        />
        <BodyColor isWhite={true} />
        <div className="personal-settings-header">
          <span className="header-text" />
        </div>
        <Container className="personal-settings">
          <Row className="section-header profile">Profile</Row>
          <Row>
            <ColorPickerColumn className="avatar-col">
              <ColorPicker
                id={myTeamMembership.id}
                originType={ORIGIN_TYPE_STRINGS.TEAM_MEMBERSHIP}
                shouldHide={false}
                className="color-picker-container"
                ref={this.setColorPickerRef}
                row
                memberInitials={me.initials}
                memberName={me.name}
              >
                <StyledAvatar
                  value={me.initials}
                  round={true}
                  size={80}
                  id={myTeamMembership.id}
                  originType="teamMembership"
                  color={'#808080'}
                />
                <div className="change-color">Change Color</div>
              </ColorPicker>
            </ColorPickerColumn>
            <Col className="profile-col">
              <Row>
                <Col sm={6}>{me.name}</Col>
                <Col sm={6}>
                  <a className="edit-settings" onClick={openEditModal}>
                    Edit
                  </a>
                </Col>
              </Row>
              <Row className="account-info">
                <Col sm={3} className="gray-label">
                  Email
                </Col>
                <Col sm={9}>{me.email}</Col>
              </Row>
              <Row className="account-info">
                <Col sm={3} className="gray-label">
                  Password
                </Col>
                <Col sm={9} className="gray-label pw-mask">
                  *********
                </Col>
              </Row>
              <Row className="logout-button-container">
                <Col sm={2}>
                  <div
                    className="btn-primary-mosaic logout-button"
                    onClick={this.logout}
                  >
                    Log Out
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="section-header-email">
            <div className="email-label"> Notification Email </div>
            <div className="email-button">
              <div className="email-switch">
                <label className="switch" htmlFor="is-private">
                  <input
                    type="checkbox"
                    checked={isEmailNotificationOn}
                    name="isPrivate"
                    onChange={toggleSwitch}
                    id="is-private"
                  />
                  <div
                    className={cn('slider round', {
                      private: isEmailNotificationOn
                    })}
                  />
                </label>
              </div>
            </div>
            <div className="email-value">
              {isEmailNotificationOn ? (
                <label id="yes-email"> On </label>
              ) : (
                <label id="no-email"> Off </label>
              )}
            </div>
          </Row>
          <Row className="section-header">Organizations</Row>
          <div className="team-info">
            {teams.map((team, idx) => (
              <Row
                key={idx}
                className="teams-row"
                onClick={() => this.navigateToTeamSettings(team)}
              >
                <UserImage team={team} />
                {isUserTeamAdmin(me.id, team) ? (
                  <div className="is-admin">
                    <i className="fa fa-circle" aria-hidden="true" />
                    Admin
                  </div>
                ) : null}
                <div className="view-account-button">View Account</div>
              </Row>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

export default connect(null, { navigateToTeamSettings })(PersonalSettings);

const UserImage = ({ team }) => {
  return <p className="company-name">{team.name || 'Team Name'}</p>;
};
