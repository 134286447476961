import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ManWithQuestionMarkIcon = ({ width = '12', height = '12' }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.5C4.65685 5.5 6 4.38071 6 3C6 1.61929 4.65685 0.5 3 0.5C1.34315 0.5 0 1.61929 0 3C0 4.38071 1.34315 5.5 3 5.5Z"
        fill="#EAEAEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24805 2.16724C4.24805 2.36035 4.19332 2.52609 4.08388 2.66448C3.97443 2.80287 3.82178 2.93322 3.62593 3.05552C3.53376 3.11345 3.4608 3.16655 3.40703 3.21483C3.35327 3.2631 3.31199 3.31218 3.28319 3.36207C3.25438 3.41195 3.23614 3.46506 3.22846 3.52138C3.22078 3.5777 3.21694 3.64287 3.21694 3.7169V3.83759H2.74459V3.70241C2.74459 3.57368 2.75707 3.46425 2.78203 3.37414C2.80699 3.28402 2.8454 3.20276 2.89724 3.13034C2.94908 3.05793 3.01533 2.99035 3.09597 2.92759C3.17662 2.86483 3.27454 2.79644 3.38975 2.72241C3.50496 2.64839 3.59232 2.56954 3.65185 2.48586C3.71137 2.40218 3.74113 2.29437 3.74113 2.16241C3.74113 2.0208 3.68353 1.89851 3.56832 1.79552C3.50304 1.7408 3.42431 1.69816 3.33215 1.66759C3.23998 1.63701 3.14206 1.62172 3.03837 1.62172C2.91164 1.62172 2.80027 1.64023 2.70427 1.67724C2.60826 1.71425 2.52762 1.76414 2.46233 1.8269C2.39705 1.88966 2.34809 1.96287 2.31544 2.04655C2.2828 2.13023 2.26648 2.21874 2.26648 2.31207H1.74805C1.74805 2.1769 1.77589 2.04494 1.83157 1.91621C1.88726 1.78747 1.96886 1.67402 2.07639 1.57586C2.18392 1.4777 2.31832 1.39885 2.47961 1.33931C2.64091 1.27977 2.82716 1.25 3.03837 1.25C3.19966 1.25 3.35423 1.27092 3.50208 1.31276C3.64993 1.3546 3.77858 1.41494 3.88802 1.49379C3.99747 1.57264 4.08484 1.66839 4.15012 1.78103C4.2154 1.89368 4.24805 2.02241 4.24805 2.16724ZM2.66971 4.75V4.21897H3.2803V4.75H2.66971Z"
        fill="#4F4F4F"
      />
      <ellipse
        cx="7.05111"
        cy="4.11215"
        rx="2.01205"
        ry="2.01205"
        fill="white"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
      <path
        d="M11.1556 11.4357C11.1556 9.21327 9.35397 7.41162 7.13152 7.41162C4.90907 7.41162 3.10742 9.21327 3.10742 11.4357"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
};

export default ManWithQuestionMarkIcon;
