// reference: https://stackoverflow.com/a/70387184
export type AddPrefixToObjectKeys<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
};

// reference: https://fettblog.eu/typescript-array-includes/#option-2%3A-a-helper-with-type-assertions
export function includes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T);
}
