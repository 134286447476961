import styled from 'styled-components';
import { PermissionListItem, AccessLevelColumn } from '../types';
import theme from 'theme';
import {
  getAllPermissionsByActions,
  getOneOffPermissionersMap
} from 'PermissionsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { ROLE_NAME, roleTemplatesHash } from 'PermissionsModule/constants';
import CheckIcon from 'icons/CheckIcon';
import NewCloseIcon from 'icons/NewCloseIcon';
import { HorizontalDashIcon } from 'icons/HorizontalDashIcon';
import MemberIcon from 'icons/MemberIcon';

export const PermissionValueCell = ({
  column,
  row
}: {
  column: AccessLevelColumn;
  row: { original: PermissionListItem };
}) => {
  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const oneOffPermissionsByAction = useAppSelector(getOneOffPermissionersMap);
  const { accessLevel } = column;
  const { permissionId } = row.original;

  const templateRoleName = accessLevelToTemplateRoleName[accessLevel];
  const permissionData = permissionsByAction[permissionId];
  const oneOffPermissions = oneOffPermissionsByAction[permissionId];

  /**
   * Use display_value to determine if role belongs on permission.
   */

  if (
    accessLevel === ROLE_NAME.ALL_MEMBERS &&
    permissionData &&
    oneOffPermissions
  ) {
    // If all members is false and there are some one off members then display this special icon
    if (
      !permissionData[templateRoleName]?.permission_value &&
      oneOffPermissions.length > 0
    ) {
      return (
        <StyledAccessLevelColumnHeaderCell>
          <MemberIcon fill={theme.colors.colorDarkGreen2} />
        </StyledAccessLevelColumnHeaderCell>
      );
    }
  }

  const permissionValue =
    accessLevel === ROLE_NAME.CUSTOM
      ? oneOffPermissions && oneOffPermissions.length > 0
      : permissionData && permissionData[templateRoleName]?.display_value
      ? permissionData[templateRoleName]?.permission_value
      : null;

  return (
    <StyledAccessLevelColumnHeaderCell>
      {permissionValueToIconHash[`${permissionValue}`]}
    </StyledAccessLevelColumnHeaderCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const permissionValueToIconHash = {
  null: <HorizontalDashIcon />,
  true: <CheckIcon fill={theme.colors.colorDarkGreen2} />,
  false: <NewCloseIcon noCircle />
};

const accessLevelToTemplateRoleName = {
  [ROLE_NAME.ADMIN]: roleTemplatesHash.admin,
  [ROLE_NAME.ALL_MEMBERS]: roleTemplatesHash.teamMember,
  // Front end shows budget manager but on backend its financial manager.
  [ROLE_NAME.BUDGET_MANAGER]: roleTemplatesHash.financialManager,
  [ROLE_NAME.WORK_PLANNER]: roleTemplatesHash.workloadPlanner,
  [ROLE_NAME.PORTFOLIO_MANAGER]: roleTemplatesHash.boardManager,
  [ROLE_NAME.PROJECT_MANAGER]: roleTemplatesHash.projectManager,
  [ROLE_NAME.CUSTOM]: 'Custom'
};

const StyledAccessLevelColumnHeaderCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
