import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

// Fix param types

export const createPortfolio =
  createAction<SpacePermissionCheckerParams>('CREATE_PORTFOLIO');

export const archivePortfolio =
  createAction<SpacePermissionCheckerParams>('ARCHIVE_PORTFOLIO');

export const deletePortfolio =
  createAction<SpacePermissionCheckerParams>('DELETE_PORTFOLIO');

export const editPortfolio =
  createAction<SpacePermissionCheckerParams>('EDIT_PORTFOLIO');

export const makePortfolioPrivate = createAction<SpacePermissionCheckerParams>(
  'MAKE_PORTFOLIO_PRIVATE'
);

export const createPublicPortfolioMembership =
  createAction<SpacePermissionCheckerParams>(
    'CREATE_PUBLIC_PORTFOLIO_MEMBERSHIP'
  );
export const createPrivatePortfolioMembership =
  createAction<SpacePermissionCheckerParams>(
    'CREATE_PRIVATE_PORTFOLIO_MEMBERSHIP'
  );
export const deletePublicPortfolioMembership =
  createAction<SpacePermissionCheckerParams>(
    'DELETE_PUBLIC_PORTFOLIO_MEMBERSHIP'
  );
export const deletePrivatePortfolioMembership =
  createAction<SpacePermissionCheckerParams>(
    'DELETE_PRIVATE_PORTFOLIO_MEMBERSHIP'
  );
export const editPublicPortfolioMembership =
  createAction<SpacePermissionCheckerParams>(
    'EDIT_PUBLIC_PORTFOLIO_MEMBERSHIP'
  );
export const editPrivatePortfolioMembership =
  createAction<SpacePermissionCheckerParams>(
    'EDIT_PRIVATE_PORTFOLIO_MEMBERSHIP'
  );

export const createPortolioManager = createAction<SpacePermissionCheckerParams>(
  'CREATE_PORTFOLIO_MANAGER'
);

export const deletePortfolioProject =
  createAction<SpacePermissionCheckerParams>('DELETE_PORTFOLIO_PROJECT');

export const archivePortfolioProject =
  createAction<SpacePermissionCheckerParams>('ARCHIVE_PORTFOLIO_PROJECT');
