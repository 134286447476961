import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchTeamCapacity = {
  request: () => action(constants.FETCH_TEAM_CAPACITY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_CAPACITY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_CAPACITY.FAILURE, { payload: { error } })
};

export const updateTeamCapacity = {
  request: () => action(constants.UPDATE_TEAM_CAPACITY.REQUEST),
  success: (response) =>
    action(constants.UPDATE_TEAM_CAPACITY.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_CAPACITY.FAILURE, { payload: { error } })
};

export const fetchCapacities = {
  request: () => action(constants.FETCH_CAPACITIES.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_CAPACITIES.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_CAPACITIES.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const updateCapacity = {
  request: () => action(constants.UPDATE_CAPACITY.REQUEST),
  success: (response) =>
    action(constants.UPDATE_CAPACITY.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.UPDATE_CAPACITY.FAILURE, { payload: { error } })
};

export const fetchHolidays = {
  request: () => action(constants.FETCH_HOLIDAYS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_HOLIDAYS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_HOLIDAYS.FAILURE, { payload: { error } })
};
export const createHoliday = {
  request: () => action(constants.CREATE_HOLIDAY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_HOLIDAY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_HOLIDAY.FAILURE, { payload: { error } })
};
export const updateHoliday = {
  request: () => action(constants.UPDATE_HOLIDAY.REQUEST),
  success: (response) =>
    action(constants.UPDATE_HOLIDAY.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.UPDATE_HOLIDAY.FAILURE, { payload: { error } })
};
export const deleteHoliday = {
  request: () => action(constants.DELETE_HOLIDAY.REQUEST),
  success: (response) =>
    action(constants.DELETE_HOLIDAY.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_HOLIDAY.FAILURE, { payload: { error } })
};
export const fetchHolidayGroups = {
  request: () => action(constants.FETCH_HOLIDAY_GROUPS.REQUEST),
  success: (response) =>
    action(constants.FETCH_HOLIDAY_GROUPS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_HOLIDAY_GROUPS.FAILURE, { payload: { error } })
};
export const createHolidayGroup = {
  request: () => action(constants.CREATE_HOLIDAY_GROUP.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_HOLIDAY_GROUP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_HOLIDAY_GROUP.FAILURE, { payload: { error } })
};
export const updateHolidayGroup = {
  request: () => action(constants.UPDATE_HOLIDAY_GROUP.REQUEST),
  success: (response) =>
    action(constants.UPDATE_HOLIDAY_GROUP.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.UPDATE_HOLIDAY_GROUP.FAILURE, { payload: { error } })
};
export const deleteHolidayGroup = {
  request: () => action(constants.DELETE_HOLIDAY_GROUP.REQUEST),
  success: (response) =>
    action(constants.DELETE_HOLIDAY_GROUP.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_HOLIDAY_GROUP.FAILURE, { payload: { error } })
};

export const fetchCapacityReport = {
  request: () => action(constants.FETCH_CAPACITY_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CAPACITY_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, requestPayload) =>
    action(constants.FETCH_CAPACITY_REPORT.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchQueuedCapacityReport = {
  request: () => action(constants.FETCH_QUEUED_CAPACITY_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_QUEUED_CAPACITY_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, requestPayload) =>
    action(constants.FETCH_QUEUED_CAPACITY_REPORT.FAILURE, {
      payload: { error, requestPayload }
    })
};
