import React from 'react';
import SvgIcon from 'components/SvgIcon';

const DropdownArrowIcon = () => (
  <SvgIcon width="8" height="5" viewBox="0 0 8 5">
    <path
      fill="#0074D9"
      fillRule="evenodd"
      d="M4 3.673L.967.333l-.3.331L4 4.334 7.333.663l-.3-.33L4 3.672z"
    />
  </SvgIcon>
);

export default React.memo(DropdownArrowIcon);
