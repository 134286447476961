import { createSelector } from 'reselect';
import { getTimesheetActivityModalState } from './core';
import keyBy from 'lodash/keyBy';

const keyByProjectId = (item) => item && item.project_id;

export const getIsOpenTimesheetActivityModal = createSelector(
  getTimesheetActivityModalState,
  (timesheetState) => timesheetState.openTimesheetActivityModal
);

export const getTimesheetActivityModalData = createSelector(
  getTimesheetActivityModalState,
  (timesheetState) => timesheetState.timesheetActivityModalData
);

export const getProjectId = createSelector(
  getTimesheetActivityModalData,
  (modalData) => modalData.projectId
);

export const getProjectSchedulebarsByProjectId = createSelector(
  getTimesheetActivityModalData,
  (modalData) => {
    const { activity_phase_schedule_bars } = modalData;
    return (
      activity_phase_schedule_bars &&
      keyBy(activity_phase_schedule_bars, keyByProjectId)
    );
  }
);

export const getPlannerHoursForProject = createSelector(
  getProjectId,
  getProjectSchedulebarsByProjectId,
  (projectId, projectSchedulebars) =>
    projectId &&
    projectSchedulebars &&
    projectSchedulebars[projectId] &&
    projectSchedulebars[projectId].daily_hours
);

export const getTaskCountActivityModal = createSelector(
  getTimesheetActivityModalState,
  (timesheetState) => timesheetState.taskCount
);

export const getNotesCountActivityModal = createSelector(
  getTimesheetActivityModalState,
  (timesheetState) => timesheetState.notesCount
);
