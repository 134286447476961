import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { addNavigationChange } from 'actionCreators';
import { lastLocationUpdateTimeFormat } from 'appConstants/navigation';

interface NavigationTrackerProps extends PropsFromRedux, RouteComponentProps {
  section?: string;
  view?: string;
  modal?: string;
}

class NavigationTracker extends React.Component<NavigationTrackerProps> {
  constructor(props: NavigationTrackerProps) {
    super(props);
    const { match, addNavigationChange, section, view, modal } = props;
    if (section || view || modal) {
      const updateTime = moment().format(lastLocationUpdateTimeFormat);
      addNavigationChange({ match, section, view, modal, updateTime });
    }
  }

  shouldComponentUpdate(nextProps: NavigationTrackerProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    const shouldUpdate = location.pathname !== nextLocation.pathname;
    return shouldUpdate;
  }

  componentDidUpdate(prevProps: NavigationTrackerProps) {
    const { match, addNavigationChange, section, view, modal } = this.props;
    const {
      section: prevSection,
      view: prevView,
      modal: prevModal,
      match: prevMatch
    } = prevProps;
    if (
      (section !== prevSection ||
        view !== prevView ||
        modal !== prevModal ||
        prevMatch.url !== match.url) &&
      (section || view || modal)
    ) {
      const updateTime = moment().format(lastLocationUpdateTimeFormat);
      addNavigationChange({ match, section, view, modal, updateTime });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  addNavigationChange
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const component = withRouter(connector(NavigationTracker));
export default component;
