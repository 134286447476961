import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import theme from 'theme';
import styled from 'styled-components';

import DaysDropdown from './DaysDropdown';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import {
  getMe,
  getTeamMembershipsByAccountId,
  getTimesheetReminderDay,
  getTimesheetReminderSenderId
} from 'selectors';
import { setTimesheetReminderSenderId } from 'actionCreators';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import MembersDropdown from 'views/projectPlanner/workloadBarModal/MembersDropdown';
import BackKarat from 'icons/BackKarat';

const DayButton = styled.div`
  width: 93px;
  height: 24px;

  font-size: 12px;
  line-height: 15px;

  border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SetReminderModalWrapper = styled.div`
  width: 380px;
  display: flex;
`;

const ColumnHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  margin-bottom: 8px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SenderName = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  margin-left: 5px;
  margin-top: 4px;
`;

const DropdownArrowContainer = styled.div`
  transform: rotate(-90deg);
  margin-left: 8px;
`;

const SetReminderModalBody = ({ teamMembers }) => {
  const targetRef = useRef(null);
  const memberDropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const me = useSelector(getMe);
  const timesheetReminderSenderId = useSelector(getTimesheetReminderSenderId);
  const teamMembershipsByAccountId = useSelector(getTeamMembershipsByAccountId);
  const teamMembership =
    teamMembershipsByAccountId[timesheetReminderSenderId || me?.account_id];
  const timesheetReminderDay = useSelector(getTimesheetReminderDay);
  const dispatch = useDispatch();

  const [isMemberDropdownOpen, setIsMemberDropdownOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openMemberDropdown = () => {
    setIsMemberDropdownOpen(true);
  };

  const closeMemberDropdown = () => {
    setIsMemberDropdownOpen(false);
  };

  const handleSelect = (member) => {
    dispatch(
      setTimesheetReminderSenderId({
        newTimesheetReminderSenderId: member.account.id
      })
    );
    closeMemberDropdown();
  };

  return (
    <SetReminderModalWrapper>
      <ColumnContainer style={{ marginLeft: 16 }}>
        <ColumnHeader> DAY </ColumnHeader>
        <DayButton onClick={handleClick} ref={targetRef}>
          {timesheetReminderDay
            ? capitalize(timesheetReminderDay)
            : 'Select Day'}
        </DayButton>
      </ColumnContainer>
      <ColumnContainer style={{ marginLeft: 40 }}>
        <ColumnHeader> SEND FROM </ColumnHeader>
        {teamMembership && (
          <div
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={openMemberDropdown}
          >
            <MemberInitials
              member={teamMembership}
              size="mediumSmall"
              classes={cn('regular-member-no-hover selected', {})}
            />
            <SenderName ref={memberDropdownRef}>
              {teamMembership.account.name}
            </SenderName>
            <DropdownArrowContainer>
              <BackKarat width={6} height={10} />
            </DropdownArrowContainer>
            {isMemberDropdownOpen && (
              <MembersDropdown
                target={memberDropdownRef}
                handleClose={closeMemberDropdown}
                members={teamMembers || []}
                handleSelect={handleSelect}
                customCopy={{
                  headerInitial: 'Select Member',
                  searchPlaceholder: 'Type name or select below'
                }}
                hideFooter={true}
                handleDone={closeMemberDropdown}
              />
            )}
          </div>
        )}
      </ColumnContainer>
      {open && (
        <DaysDropdown
          onClose={handleClose}
          targetRef={targetRef}
          selectedDay={timesheetReminderDay}
        />
      )}
    </SetReminderModalWrapper>
  );
};

export default SetReminderModalBody;
