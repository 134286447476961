import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MenuNotificationIcon = ({
  className,
  height = '13',
  width = '14',
  currentColor = '#A4A4A4'
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 13 14"
    className={className}
    fill="none"
  >
    <path
      d="M6.44932 0.0641361C6.23154 0.0900213 6.0509 0.304327 6.0544 0.531264V1.02248C5.22561 1.12963 4.51584 1.53115 3.98207 2.10122C3.35481 2.77063 2.95872 3.66217 2.79262 4.61023L2.19789 8.00057C2.07477 8.70428 1.82271 8.84876 1.46378 9.09377C1.28432 9.21658 1.06535 9.3472 0.873688 9.58016C0.682023 9.81312 0.553101 10.1533 0.553101 10.5481C0.553101 11.223 1.09149 11.781 1.74255 11.781H4.27475C4.352 12.9819 5.3225 13.9385 6.50037 13.9385C7.67824 13.9385 8.64867 12.9819 8.72598 11.781H11.2582C11.9092 11.781 12.4476 11.223 12.4476 10.5481C12.4476 10.1532 12.3187 9.81312 12.127 9.58016C11.9354 9.3472 11.7164 9.21656 11.537 9.09377C11.178 8.84877 10.926 8.7043 10.8028 8.00057L10.2081 4.61023C10.042 3.66212 9.64592 2.77051 9.01866 2.10122C8.48492 1.53115 7.77519 1.12963 6.94634 1.02248V0.531264C6.9504 0.268805 6.70082 0.0340237 6.44932 0.0641361ZM6.50043 1.91819C7.28333 1.91819 7.89664 2.22821 8.37754 2.7417C8.85843 3.2552 9.19587 3.98359 9.33468 4.77406L9.9294 8.1644C10.0984 9.12697 10.6635 9.60132 11.0492 9.86435C11.242 9.99618 11.379 10.0931 11.4487 10.1774C11.5184 10.2616 11.5556 10.3279 11.5556 10.5482C11.5556 10.727 11.4307 10.8564 11.2582 10.8564H1.74261C1.57011 10.8564 1.44525 10.727 1.44525 10.5482C1.44525 10.3279 1.48242 10.2616 1.55211 10.1774C1.62181 10.0931 1.75887 9.99617 1.9517 9.86435C2.33735 9.60129 2.90244 9.12692 3.07145 8.1644L3.66617 4.77406C3.80498 3.98366 4.14242 3.25526 4.62331 2.7417C5.10421 2.22814 5.71753 1.91819 6.50043 1.91819ZM5.19018 11.781H7.81069C7.73519 12.4787 7.1968 13.0139 6.50043 13.0139C5.80407 13.0139 5.26568 12.4787 5.19018 11.781Z"
      fill={currentColor}
      stroke={currentColor}
      strokeWidth="0.2"
    />
  </SvgIcon>
);

export default MenuNotificationIcon;
