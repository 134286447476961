import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface HelpIconProps
  extends Pick<SvgIconProps, 'width' | 'height' | 'className'> {
  circleColor?: string;
  questionMarkColor?: string;
  fillColor?: string;
}

const HelpIcon = ({
  circleColor = '#CDCDCD',
  questionMarkColor = '#808080',
  fillColor = 'white',
  className,
  width = 18,
  height = 18
}: HelpIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
        fill={fillColor}
        stroke={circleColor}
        className={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8659 6.79839C11.8659 7.28226 11.7375 7.69758 11.4807 8.04435C11.2239 8.39113 10.8657 8.71774 10.4062 9.02419C10.1899 9.16936 10.0187 9.30242 9.89258 9.42339C9.76643 9.54436 9.66957 9.66734 9.60199 9.79234C9.53441 9.91734 9.49161 10.0504 9.47359 10.1915C9.45556 10.3327 9.44655 10.496 9.44655 10.6815V10.9839H8.33825V10.6452C8.33825 10.3226 8.36753 10.0484 8.4261 9.82258C8.48467 9.59677 8.57478 9.39315 8.69642 9.21169C8.81806 9.03024 8.9735 8.86089 9.16272 8.70363C9.35194 8.54637 9.58171 8.375 9.85203 8.18952C10.1223 8.00403 10.3273 7.80645 10.467 7.59677C10.6067 7.3871 10.6765 7.11694 10.6765 6.78629C10.6765 6.43145 10.5413 6.125 10.271 5.86694C10.1178 5.72984 9.93313 5.62298 9.71687 5.54637C9.50062 5.46976 9.27085 5.43145 9.02756 5.43145C8.73021 5.43145 8.4689 5.47782 8.24364 5.57056C8.01837 5.66331 7.82915 5.78831 7.67597 5.94556C7.52279 6.10282 7.40791 6.28629 7.33132 6.49597C7.25473 6.70565 7.21643 6.92742 7.21643 7.16129H6C6 6.82258 6.06533 6.49194 6.19598 6.16935C6.32663 5.84677 6.51811 5.5625 6.77041 5.31653C7.0227 5.07056 7.33807 4.87298 7.71652 4.72379C8.09497 4.5746 8.53198 4.5 9.02756 4.5C9.40601 4.5 9.76868 4.55242 10.1156 4.65726C10.4625 4.7621 10.7644 4.91331 11.0212 5.11089C11.278 5.30847 11.4829 5.54839 11.6361 5.83065C11.7893 6.1129 11.8659 6.43548 11.8659 6.79839ZM8.16254 13.2702V11.9395H9.59523V13.2702H8.16254Z"
        fill={questionMarkColor}
      />
    </SvgIcon>
  );
};

export default HelpIcon;
