import React, { useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import theme from 'theme';
import moment from 'appUtils/momentConfig';
import keyBy from 'lodash/keyBy';
import { SubmitButton, TextButton, ButtonContainer } from './styles';

import MembersDropdown from 'views/projectPlanner/workloadBarModal/MembersDropdown';
import {
  getSortedAlphabeticalTeamMembers,
  makeGetEditOrViewIdsHash
} from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkloadPlannerEvent } from 'actionCreators';
import SetReminderModalBody from './SetReminderComponents/SetReminderModalBody';

const StyledModal = styled(Modal)`
  .modal-content {
    min-height: 250px;
    width: 500px;
    padding: 35px 45px;
    color: #4f4f4f;
  }
  cursor: default;
`;
const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    font-size: 26px;
    font-weight: 600;
  }
  border: none;
  padding: 0;
  color: ${(props) =>
    props.isSendTimesheetReminder && theme.colors.colorPureBlack};
`;
const StyledModalBody = styled(ModalBody)`
  padding: 30px 15px;
  font-size: 16px;
`;
const StyledModalFooter = styled(ModalFooter)`
  font-size: 14px;
  border: none;
  padding: 0;
  flex-direction: column;
  & > * {
    margin: 0;
  }
`;
const MembersText = styled.span`
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  text-decoration: underline;
`;
const FooterText = styled.div`
  align-self: flex-start;
  padding-left: 15px;
`;
const FollowersText = styled.span`
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
`;
const SemiBold = styled.span`
  font-weight: 600;
`;

const getEditOrViewIdsHash = makeGetEditOrViewIdsHash();

const ConfirmWorkloadEventModal = ({
  onConfirm,
  onCancel,
  isOpen,
  recipientIds,
  start,
  end,
  followerIds = [],
  accessIdentifier,
  selectedEvent,
  isSendCheckin,
  isHomeWorkplan,
  isSendTimesheetReminder,
  modalTitle = 'Confirmation',
  confirmButtonText = 'Send',
  cancelButtonText = 'Go Back'
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isMemberDropdownOpen, setIsMemberDropdownOpen] = useState(false);
  const teamMembers = useSelector(getSortedAlphabeticalTeamMembers);

  const ownProps = useMemo(() => ({ accessIdentifier }), [accessIdentifier]);

  const editOrViewIdsHash = useSelector((state) =>
    getEditOrViewIdsHash(state, ownProps)
  );

  const notifiableTeamMembers = useMemo(
    () => teamMembers.filter((member) => editOrViewIdsHash[member.account.id]),
    [editOrViewIdsHash, teamMembers]
  );

  const openDropdown = () => setIsMemberDropdownOpen(true);
  const closeDropdown = () => setIsMemberDropdownOpen(false);

  const handleSelect = (member) => {
    const accountId = member.account.id;
    const isSelected = followerIds?.includes(accountId);

    const newFollowerIds = isSelected
      ? followerIds.filter((id) => id !== accountId)
      : [...followerIds, accountId];
    dispatch(
      updateWorkloadPlannerEvent({
        event_id: selectedEvent?.scheduled_event_id,
        metadata: { ...selectedEvent?.metadata, follower_ids: newFollowerIds }
      })
    );
  };

  const handleFooterClick = () => {
    dispatch(
      updateWorkloadPlannerEvent({
        event_id: selectedEvent?.scheduled_event_id,
        metadata: {
          ...selectedEvent?.metadata,
          follower_ids: notifiableTeamMembers.map((member) => member.account.id)
        }
      })
    );
  };

  const selectedIds = useMemo(() => keyBy(followerIds), [followerIds]);

  return (
    <StyledModal isOpen={isOpen} toggle={onCancel}>
      <StyledModalHeader isSendTimesheetReminder={isSendTimesheetReminder}>
        {modalTitle}
      </StyledModalHeader>
      {isSendTimesheetReminder ? (
        <StyledModalBody>
          <SetReminderModalBody teamMembers={notifiableTeamMembers} />
        </StyledModalBody>
      ) : (
        <StyledModalBody>
          {isSendCheckin || isHomeWorkplan ? (
            <MembersText onClick={openDropdown} ref={ref}>
              {isHomeWorkplan ? followerIds.length : recipientIds.length}{' '}
              {isHomeWorkplan
                ? followerIds.length === 1
                  ? 'person'
                  : 'people'
                : recipientIds.length === 1
                ? 'person'
                : 'people'}
            </MembersText>
          ) : (
            !isSendTimesheetReminder && (
              <strong>
                {recipientIds.length} Member
                {recipientIds.length === 1 ? '' : 's'}
              </strong>
            )
          )}{' '}
          will be notified of{' '}
          {isSendCheckin ? (
            <>your Check-in.</>
          ) : (
            <>
              {isHomeWorkplan ? <> your </> : <> their </>}
              <SemiBold> Work Plan </SemiBold>
            </>
          )}
          {!isSendCheckin && (
            <>
              for{' '}
              <strong>
                {moment(start).format('MMM DD')}-{moment(end).format('MMM DD')}
              </strong>
              .
            </>
          )}
        </StyledModalBody>
      )}
      <StyledModalFooter>
        {isSendCheckin || isHomeWorkplan ? null : (
          <FooterText ref={ref}>
            <FollowersText onClick={openDropdown}>
              {followerIds.length}{' '}
              {followerIds.length === 1 ? 'person' : 'people'}
            </FollowersText>{' '}
            will receive a copy.
          </FooterText>
        )}
        {isMemberDropdownOpen && (
          <MembersDropdown
            target={ref}
            handleClose={closeDropdown}
            members={notifiableTeamMembers || []}
            handleSelect={handleSelect}
            isAssignMultiple
            customCopy={{
              headerInitial: 'Select Members',
              footerInitial: 'All Workload Viewers',
              searchPlaceholder: 'Type name or select below'
            }}
            hideFooter={false}
            onFooterClick={handleFooterClick}
            selectedIds={selectedIds}
            handleDone={closeDropdown}
          />
        )}
        <ButtonContainer>
          <TextButton onClick={onCancel}>{cancelButtonText}</TextButton>
          <SubmitButton onClick={onConfirm}>{confirmButtonText}</SubmitButton>
        </ButtonContainer>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default ConfirmWorkloadEventModal;
