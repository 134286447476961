import { ValueOf } from 'type-fest';
import type { PermissionsActions, RoleTemplates } from './types';

import { PermissionActionHash } from 'PermissionsModule/components/PermissionsTable/types';
import { permissionConfigs } from './permissionConfigs';

export const ACCESS_ROLES = {
  ADMIN: 1,
  TEAM_MEMBER: 2,
  GUEST: 4,
  LOGIN: 8,
  WORKLOAD_MANAGER: 9,
  TIMESHEET_MANAGER: 10,
  TEAM_BUDGET_MANAGER: 11,
  WORKLOAD_MANAGER_VIEW_ONLY: 12,
  FINANCIAL_MANAGER: 13,
  FINANCIAL_MANAGER_VIEW_ONLY: 14,
  WORKLOAD_PlANNER_BASE: 17,
  WORKLOAD_PLANNER_VIEW_ONLY_BASE: 18,
  FINANCIAL_MANAGER_BASE: 19,
  FINANCIAL_MANAGER_VIEW_ONLY_BASE: 20
} as const;

export enum EMPLOYMENT_TYPES {
  member = 'Member',
  projectGuest = 'Guest',
  internalContractor = 'Contractor (Internal)',
  externalProjectContractor = 'Contractor (External)'
}

export const EMPLOYMENT_TYPES_ARRAY = Object.values(EMPLOYMENT_TYPES);

export const ACCESS_ROLE_TYPES = {
  BASE_MEMBER: ACCESS_ROLES.TEAM_MEMBER,
  WORKLOAD_PLANNER: ACCESS_ROLES.WORKLOAD_MANAGER,
  FINANCIAL_MANAGER: ACCESS_ROLES.FINANCIAL_MANAGER,
  ADMIN: ACCESS_ROLES.ADMIN
} as const;

export const ROLE_RESTRICTIONS = {
  PROJECT_MEMBERSHIP_ONLY: 'Project Membership Only'
} as const;

export const PROJECT_VISIBILITY_TYPES = {
  ALL: 'all',
  PROJECTS_MEMBER_OF: 'projectsMemberOf',
  NO_ACCESS: 'noAccess'
} as const;

export const PROJECT_INTERACTION_TYPES = {
  VIEW: 'view',
  EDIT: 'edit'
} as const;

export const permissionsActionsHash: Partial<PermissionActionHash> = {
  ...permissionConfigs.organizationPermissions,
  ...permissionConfigs.timesheetPermissions,
  ...permissionConfigs.workplanPermissions,
  ...permissionConfigs.integrationPermissions,
  ...permissionConfigs.profilePermissions,
  ...permissionConfigs.budgetPermissions,
  ...permissionConfigs.portfolioPermissions,
  ...permissionConfigs.reportPermissions,
  ...permissionConfigs.dashboardPermissions,
  ...permissionConfigs.projectPermissions
} as const;

export const permissionsActionsArray = Object.keys(
  permissionsActionsHash
) as Array<keyof typeof permissionsActionsHash>;

export const roleTemplatesHash = {
  admin: 'Admin',
  projectManager: 'Project Manager',
  boardManager: 'Board Manager',
  teamMember: 'Team Member',
  financialManager: 'Financial Manager',
  // Budget Manager (FE) == Financial Manager (BE)
  budgetManager: 'Budget Manager',
  projectMember: 'Project Member',
  workloadPlanner: 'Workload Planner'
} as const;

// Reponsible for the name of columns, because they dont always match backend name.
export const ROLE_NAME = {
  ADMIN: roleTemplatesHash.admin,
  ALL_MEMBERS: 'All Members',
  BUDGET_MANAGER: roleTemplatesHash.budgetManager,
  WORK_PLANNER: 'Work Planner',
  PORTFOLIO_MANAGER: 'Portfolio Manager',
  PROJECT_MANAGER: roleTemplatesHash.projectManager,
  CUSTOM: 'Custom'
} as const;

// All the sections in the permission tabs
export const PERMISSION_SECTION = {
  ORGANIZATION: 'organization',
  INTEGRATIONS: 'integrations',
  TIMESHEETS: 'timesheets',
  WORK_PLANS: 'work plans',
  PROFILE: 'profile',
  BUDGET: 'budget',
  PORTFOLIOS: 'portfolios',
  REPORTS: 'reports',
  DASHBOARDS: 'dashboards',
  PROJECTS: 'projects'
} as const;

// Permissions in each section (This should have an entry for each section when complete)
export const sectionPermissionIdentifierHash: Record<
  ValueOf<typeof PERMISSION_SECTION>,
  PermissionsActions[]
> = {
  [PERMISSION_SECTION.ORGANIZATION]: Object.keys(
    permissionConfigs.organizationPermissions
  ) as Array<keyof typeof permissionConfigs.organizationPermissions>,
  [PERMISSION_SECTION.PORTFOLIOS]: Object.keys(
    permissionConfigs.portfolioPermissions
  ) as Array<keyof typeof permissionConfigs.portfolioPermissions>,
  [PERMISSION_SECTION.TIMESHEETS]: Object.keys(
    permissionConfigs.timesheetPermissions
  ) as Array<keyof typeof permissionConfigs.timesheetPermissions>,
  [PERMISSION_SECTION.PROJECTS]: Object.keys(
    permissionConfigs.projectPermissions
  ) as Array<keyof typeof permissionConfigs.projectPermissions>,
  [PERMISSION_SECTION.INTEGRATIONS]: Object.keys(
    permissionConfigs.integrationPermissions
  ) as Array<keyof typeof permissionConfigs.integrationPermissions>,
  [PERMISSION_SECTION.WORK_PLANS]: Object.keys(
    permissionConfigs.workplanPermissions
  ) as Array<keyof typeof permissionConfigs.workplanPermissions>,
  [PERMISSION_SECTION.PROFILE]: Object.keys(
    permissionConfigs.profilePermissions
  ) as Array<keyof typeof permissionConfigs.profilePermissions>,
  [PERMISSION_SECTION.BUDGET]: Object.keys(
    permissionConfigs.budgetPermissions
  ) as Array<keyof typeof permissionConfigs.budgetPermissions>,
  [PERMISSION_SECTION.REPORTS]: Object.keys(
    permissionConfigs.reportPermissions
  ) as Array<keyof typeof permissionConfigs.reportPermissions>,
  [PERMISSION_SECTION.DASHBOARDS]: Object.keys(
    permissionConfigs.dashboardPermissions
  ) as Array<keyof typeof permissionConfigs.dashboardPermissions>
};

export const roleTemplates: RoleTemplates[] = Object.values(roleTemplatesHash);

const ACTABLE_TYPES = {
  PROJECT: 'Project',
  BOARD: 'Board',
  TEAM: 'Team'
} as const;

export const permissionConstants = {
  EMPLOYMENT_TYPES,
  ACTABLE_TYPES
};
