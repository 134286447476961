import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CellContainer from './CellContainer';
import DescriptionContent from './DescriptionContent';
import CreateDescriptionContent from './CreateDescriptionContent';
import { getAuthToken, getIsMemberModalOpen } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import { noop } from 'appUtils/noop';
import * as taskRemoveTypes from 'appConstants/taskRemoveTypes';
import { StyledTableRow } from 'components/Table/styles';

const AddTaskText = styled.div`
  padding-left: 22px;
  color: ${theme.colors.colorMediumGray1};
  font-size: 15px;
  ${StyledTableRow}:hover & {
    ${(props) =>
      !props.isCreatingNewTask &&
      `
      color: ${theme.colors.colorRoyalBlue};
    `}
  }
`;

export const RemovedTask = styled.div`
  color: #979797;
  font-size: 14px;
  flex: auto;
  display: flex;
  align-items: center;
  height: 100%;
`;

const { projectDescriptions } = taskRemoveTypes;

const createRowStyles = { justifyContent: 'flex-start' };
const pointerStyles = { cursor: 'pointer' };
const DescriptionCell = ({ row }) => {
  const target = useRef(null);
  const token = useSelector(getAuthToken);
  const isMemberModalOpen = useSelector(getIsMemberModalOpen);
  // default to editing true so that the description field autofocuses on mount
  const [editing, setEditing] = useState(true);

  const {
    id,
    taskDestination,
    taskProps = {},
    handleTasksAttributesUpdate,
    handleSubmit,
    handleUpdate: handleUpdateCreateRowAttributes,
    createRow,
    isCreatingNewTask,
    customRowProps: { resetCreateState },
    list: { id: listId, createRowData, setCreating, descriptionRef },
    rowNumber,
    createTaskErrors
  } = row.original;
  const { isNewTask } = taskProps;

  // Reset row state for the add task row here, since
  // CreateDescriptionContent handleBlur is not called as
  // the component unmounts before
  useEffect(() => {
    if (
      !isCreatingNewTask &&
      row.state.taskId === 'addTask' &&
      row.state.editingProperty === 'description'
    ) {
      row.setState({ editingProperty: null });
    }
  }, [isCreatingNewTask, row]);

  if (!id && !createRow) {
    return null;
  }
  const handleClick = () =>
    row.setState({
      editingProperty: 'description',
      taskId: row.original.id || 'addTask'
    });

  const handleSelect = () => {
    if (row.state.editingProperty === 'description') {
      row.setState({ editingProperty: null });
    }
  };
  const handleUpdate = (args) => {
    handleUpdateCreateRowAttributes(args);
  };
  const editingToUse = createRow
    ? editing
    : row.state.editingProperty === 'description';
  const updateDescription = (args) => {
    handleTasksAttributesUpdate(args);
    handleSelect();
  };
  const handleCreateRowChange = (text) => {
    if (descriptionRef) {
      descriptionRef.current = text;
    }
  };

  if (createRow && !isCreatingNewTask) {
    return (
      <CellContainer
        taskProperty="description"
        styles={createRowStyles}
        boxStyles={createRowStyles}
        taskProps={{ ...taskProps, isNewTask: true }}
        handleClick={() => {
          setCreating({ id: listId, createRowData });
          setEditing(true);
          handleClick();
        }}
        row={row}
      >
        <AddTaskText isCreatingNewTask={isCreatingNewTask}>
          Add Task
        </AddTaskText>
      </CellContainer>
    );
  }
  if (taskDestination) {
    return (
      <CellContainer
        taskProps={taskProps}
        taskProperty="description"
        handleClick={noop}
        styles={pointerStyles}
        row={row}
      >
        <RemovedTask isMemberModalOpen={isMemberModalOpen}>
          {projectDescriptions[taskDestination]}
        </RemovedTask>
      </CellContainer>
    );
  }
  return (
    <CellContainer
      taskProps={taskProps}
      taskProperty="description"
      handleClick={handleClick}
      styles={pointerStyles}
      row={row}
    >
      {' '}
      {createRow ? (
        <CreateDescriptionContent
          toggleTaskCreationHeader={() => {
            // only called on submit, clickout handles clearing create state in TasksTable
            resetCreateState();
          }}
          groupId={row.original.task_group_id}
          nextTaskId={row.original.nextTaskId}
          task={row.original}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          handleChange={handleCreateRowChange}
          isEditing={editingToUse}
          index={row.index}
          createTaskErrors={createTaskErrors}
        />
      ) : (
        <DescriptionContent
          target={target}
          isNewTask={isNewTask}
          onSubmit={handleSelect}
          taskId={id}
          taskIsEditing={editingToUse}
          isEditingTask={editingToUse}
          task={row.original}
          currentFilter={{}}
          handleContentClick={handleClick}
          handleTaskEditClick={handleClick}
          handleDescriptionTextAreaEvent={noop}
          handleDescriptionSubmit={handleSelect}
          handleTasksAttributesUpdate={updateDescription}
          token={token}
          isOnProjectDetail={true}
          handleBlur={handleSelect}
          index={row.index}
          rowNumber={rowNumber}
          isMemberModalOpen={isMemberModalOpen}
        />
      )}
    </CellContainer>
  );
};
export default DescriptionCell;
