import { MembersTimesheetSettingsTableTimeEntrySettingCell as TimeEntrySettingCell } from './TimeEntrySettingCell';
import { MembersTimesheetSettingsTableReminderSettingCell as ReminderSettingCell } from './ReminderSettingCell';
import { MembersTimesheetSettingsTableNameCell as NameCell } from './NameCell';
import { MembersTimesheetSettingsTableEmailCell as EmailCell } from './EmailCell';
import { MembersTimesheetSettingsTableMemberTypeSectionHeaderCell as MemberTypeSectionHeaderCell } from './MemberTypeSectionHeaderCell';
import { MembersPermissionsTableHeaderCell as HeaderCell } from './HeaderCell';
import { StandardPTOTableArchivedSectionHeaderCell as ArchivedSectionHeaderCell } from 'SettingsModule/components/StandardSettings/PTO/StandardPTOTable/Cells/ArchivedSectionHeaderCell';

export const MembersTimesheetSettingsTableCells = {
  MemberTypeSectionHeaderCell,
  HeaderCell,
  NameCell,
  EmailCell,
  TimeEntrySettingCell,
  ReminderSettingCell,
  ArchivedSectionHeaderCell
};
