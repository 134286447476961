import * as entityActions from '../actions';
import { select } from 'redux-saga/effects';
import { fetchEntity } from './generics';
import { api } from '../service';
import {
  getPlannerMemberIds,
  getCurrentUserId,
  getOnTeamPlannerTasks
} from 'selectors';

const { fetchPlannersAction } = entityActions;

export function* fetchPlanners(action) {
  const token = action.payload.token;
  const { accountIds, baseDate, leftOffset, rightOffset, unscheduled } =
    action.payload.params;

  const isOnTeamPlannerTasks = yield select(getOnTeamPlannerTasks);
  const currentUserId = yield select(getCurrentUserId);
  const plannerMemberIds = yield select(getPlannerMemberIds);

  const taskPlannerIds = isOnTeamPlannerTasks
    ? plannerMemberIds
    : [currentUserId];
  const accountIdsToFetch = accountIds || taskPlannerIds;
  const view = isOnTeamPlannerTasks ? 'team' : 'home';
  if (accountIdsToFetch.length) {
    const { error } = yield fetchEntity(
      fetchPlannersAction,
      api.plannersFetch,
      undefined,
      [token, accountIds, baseDate, leftOffset, rightOffset, unscheduled, view],
      action
    );
  }
}
