import React, { useMemo } from 'react';
import { useFilterFinder } from 'DashboardModule/hooks/useFilterFinder';

const withFilter = (Component) => {
  const WithFilter = ({ activeFilter, filterId, ...props }) => {
    const filterFinder = useFilterFinder();
    const filterToUse = useMemo(
      () => activeFilter || filterFinder(filterId, true),
      [activeFilter, filterFinder, filterId]
    );
    const shouldRender = !!filterToUse;

    return shouldRender ? (
      <Component {...props} activeFilter={filterToUse} />
    ) : null;
  };

  return WithFilter;
};

export default withFilter;
