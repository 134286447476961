import React, { useCallback, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { getMe, getTeamMembersHash } from 'selectors';
import {
  makeGetOwnTeamPositionHistory,
  getPositions
} from 'BudgetModule/selectors/positions';
import moment from 'moment';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import {
  StyledCell,
  StyledDate,
  DeleteIconContainer,
  StyledDeleteIcon,
  HeaderContainer,
  StyledMemberName,
  StyledRowContainer,
  StyledSmallPencilIcon,
  AddRateContainer,
  StyledHeaderCell,
  MiniHeaderContainer,
  CloseIconStyle,
  EmptyCell
} from 'BudgetModule/components/BudgetModal/styles';
import CloseIcon from 'icons/NewCloseIcon';
import RoleOverrideModal from './RoleOverrideModal';
import MemberRoleDeleteConfirmationModal from './MemberRoleDeleteConfirmationModal';
import PositionsDropdown from './dropdowns/PositionsDropdown';
import { EARLIEST_RATE_START_DISPLAY } from 'BudgetModule/constants';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { RATE_MODAL_COLUMNS } from 'appConstants';

const noop = () => {};
const emptyArray = [];

export const StartDateCell = ({
  isEarliest,
  disableChangeEarliestStart,
  updateDate,
  item
}) => {
  return (
    <StyledDate
      className="start-date"
      isEarliest={isEarliest && disableChangeEarliestStart}
      center
    >
      {isEarliest && disableChangeEarliestStart ? (
        <div> {EARLIEST_RATE_START_DISPLAY} </div>
      ) : (
        <DateRangeCalendar
          isSingleDay
          showInputs
          labels={startLabels}
          onSubmit={({ startDate }) => updateDate(item, startDate, 'startDate')}
          itemStartDate={item.start_date}
          customInput={(startDate, endDate, handleOpen) => (
            <div onClick={handleOpen}>
              {moment(startDate).format('M/DD/YY')}
            </div>
          )}
        />
      )}
    </StyledDate>
  );
};

export const EndDateCell = ({ updateDate, item, modalType }) => {
  const isOngoing = ['Present', null].includes(item.end_date);
  return (
    <StyledDate className="end-date" center isDisabled={isOngoing}>
      <DateRangeCalendar
        isSingleDay
        showInputs
        labels={endLabels}
        onSubmit={({ startDate: endDate }) =>
          updateDate(item, endDate, 'endDate')
        }
        itemStartDate={item.end_date}
        customInput={(startDate, endDate, handleOpen) => (
          <div onClick={isOngoing ? noop : handleOpen}>
            {isOngoing ? 'Ongoing' : moment(item.end_date).format('M/DD/YY')}
          </div>
        )}
      />
    </StyledDate>
  );
};

export const DateSeparatorCell = () => <StyledCell center>-</StyledCell>;

export const PositionCell = ({
  onEdit,
  item,
  setRoleDropdownTargetRef,
  roleHash
}) => {
  const targetRef = useRef(null);
  return (
    <StyledCell
      ref={targetRef}
      className="position"
      onClick={() => {
        setRoleDropdownTargetRef(targetRef);
        onEdit(item);
      }}
    >
      <span>{roleHash[item.position_id]?.name}</span>
      {<StyledSmallPencilIcon />}
    </StyledCell>
  );
};

export const DeleteCell = ({ handleDelete, item }) => (
  // if default, prevent delete
  <DeleteIconContainer onClick={() => handleDelete(item)}>
    <StyledDeleteIcon />
  </DeleteIconContainer>
);

export const cells = {
  [RATE_MODAL_COLUMNS.DELETE.name]: DeleteCell,
  [RATE_MODAL_COLUMNS.POSITION.name]: PositionCell,
  [RATE_MODAL_COLUMNS.START_DATE.name]: StartDateCell,
  [RATE_MODAL_COLUMNS.DATE_SEPARATOR.name]: DateSeparatorCell,
  [RATE_MODAL_COLUMNS.END_DATE.name]: EndDateCell,
  [RATE_MODAL_COLUMNS.PADDING.name]: EmptyCell
};

export const HeaderCell = ({ className, column }) => (
  <StyledHeaderCell className={className}>{column}</StyledHeaderCell>
);

const headerCells = {
  [RATE_MODAL_COLUMNS.DELETE.name]: EmptyCell,
  [RATE_MODAL_COLUMNS.POSITION.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.START_DATE.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.DATE_SEPARATOR.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.END_DATE.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.PADDING.name]: EmptyCell
};

const columns = [
  RATE_MODAL_COLUMNS.DELETE,
  RATE_MODAL_COLUMNS.POSITION,
  RATE_MODAL_COLUMNS.START_DATE,
  RATE_MODAL_COLUMNS.DATE_SEPARATOR,
  RATE_MODAL_COLUMNS.END_DATE,
  RATE_MODAL_COLUMNS.PADDING
];

const colWidths = {
  [RATE_MODAL_COLUMNS.DELETE.name]: 23,
  [RATE_MODAL_COLUMNS.POSITION.name]: 243,
  [RATE_MODAL_COLUMNS.START_DATE.name]: 72,
  [RATE_MODAL_COLUMNS.DATE_SEPARATOR.name]: 5,
  [RATE_MODAL_COLUMNS.END_DATE.name]: 72,
  [RATE_MODAL_COLUMNS.PADDING.name]: 20
};

const gridTemplateCols = Object.values(colWidths).reduce(
  (acc, cur) => acc + `${cur}px `,
  ''
);

const listWidth =
  Object.values(colWidths).reduce((acc, cur) => acc + cur, 0) + 16;

const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}
`;

const startLabels = {
  start: 'START'
};

const endLabels = {
  start: 'END'
};

const copy = {
  headerInitial: 'initial header',
  headerEdit: 'select rate',
  headerAdd: 'add new rate',
  sticky: 'add rate',
  footerInitial: 'add/edit rate',
  footerEdit: 'done'
};

const initialNewDateProps = {
  date: null, // moment object
  endOrStart: null // 'endDate' | 'startDate'
};

/* ------------------------------------ - ----------------------------------- */

// currently only for TeamPosition
const MemberRoleHistoryDropdown = ({
  onClose,
  targetRef,
  accountId,
  roleHistory,
  roleHash,
  me,
  member,
  isTeamRole
}) => {
  const [showRolesDropdown, setShowRolesDropdown] = useState(false);
  const [currentModal, setCurrentModal] = useState('history');
  const [selectedNewRole, setSelectedNewRole] = useState(null); // Position
  const [roleMembershipBeingEdited, setRoleMembershipBeingEdited] =
    useState(null); // TeamPosition or MemberPosition
  const [selectedNewDateProps, setSelectedNewDateProps] =
    useState(initialNewDateProps);
  const addNewTargetRef = useRef(null);
  const [roleDropdownTargetRef, setRoleDropdownTargetRef] =
    useState(addNewTargetRef);

  // Hide default Position/role
  const roleHistoryWithoutDefault = useMemo(
    () =>
      roleHistory.filter(
        (teamPosition) => !roleHash[teamPosition.position_id]?.is_default
      ),
    [roleHash, roleHistory]
  );

  // from RolesDropdown list
  const onNewRoleSelect = (role) => {
    if (role) {
      setSelectedNewRole(role);
      setCurrentModal('override');
    }
  };

  const resetState = () => {
    setCurrentModal('history');
    setSelectedNewRole(null);
    setRoleMembershipBeingEdited(null);
    setSelectedNewDateProps(initialNewDateProps);
  };

  /* --------------------------------- render --------------------------------- */

  const renderHeaderText = useCallback(() => {
    return (
      <HeaderContainer style={{ paddingLeft: colWidths.delete }}>
        <MemberInitials
          member={member}
          size="medium"
          classes={`sidebar-member-initials selected ${
            me?.id === member?.account?.id ? 'logged' : 'regular'
          }-member-no-hover`}
          id={member?.id}
        />
        <StyledMemberName
          style={{
            maxWidth: listWidth - colWidths.delete - 32 - 30
          }}
        >
          {member?.account?.first_name}&apos;s {isTeamRole ? 'Default ' : ''}
          Role
        </StyledMemberName>
      </HeaderContainer>
    );
  }, [isTeamRole, me, member]);

  const renderTableBodyHeader = useCallback(() => {
    return (
      <div style={{ width: listWidth }}>
        <MiniHeaderContainer colWidths={gridTemplateCols}>
          {columns.map((column, index) => {
            const Cell = headerCells[column.name];
            return (
              <Cell column={column.label} className={column.name} key={index} />
            );
          })}
        </MiniHeaderContainer>
        <AddRateContainer
          onClick={() => {
            setRoleDropdownTargetRef(addNewTargetRef);
            setShowRolesDropdown(true);
          }}
          emptyList={!roleHistoryWithoutDefault.length}
          style={{
            width: `calc(100% - ${colWidths.delete}px - ${colWidths.padding}px)`,
            marginLeft: colWidths.delete
          }}
          ref={addNewTargetRef}
        >
          + Add New
        </AddRateContainer>
      </div>
    );
  }, [roleHistoryWithoutDefault.length]);

  const renderItem = useCallback(
    ({ item, selectCallback }) => {
      const onEdit = (roleMembership) => {
        setShowRolesDropdown(true);
        setRoleMembershipBeingEdited(roleMembership);
      };

      const updateDate = (roleMembership, date, endOrStart) => {
        setRoleMembershipBeingEdited(roleMembership);
        setSelectedNewDateProps({ date, endOrStart });
        setCurrentModal('override');
      };

      const handleDelete = (roleMembership) => {
        setRoleMembershipBeingEdited(roleMembership);
        setCurrentModal('delete');
      };

      const earliestRoleByDate =
        roleHistoryWithoutDefault[roleHistoryWithoutDefault.length - 1];
      const isEarliest = item.id === earliestRoleByDate.id;
      const disableChangeEarliestStart = true;
      const props = {
        item,
        updateDate,
        isEarliest,
        onEdit,
        setRoleDropdownTargetRef,
        roleHash,
        handleDelete,
        disableChangeEarliestStart
      };
      return (
        <StyledRowContainer
          key={item.id}
          colWidths={gridTemplateCols}
          isLast={isEarliest}
        >
          {columns.map((column, index) => {
            const Cell = cells[column.name];
            return <Cell {...props} key={index} />;
          })}
        </StyledRowContainer>
      );
    },
    [roleHash, roleHistoryWithoutDefault]
  );

  const renderHeaderButton = useCallback(
    () => (
      <CloseIconStyle onClick={onClose}>
        <CloseIcon />
      </CloseIconStyle>
    ),
    [onClose]
  );

  return (
    <>
      {(() => {
        switch (currentModal) {
          case 'override':
            return (
              <RoleOverrideModal
                roleMembershipBeingEdited={roleMembershipBeingEdited}
                selectedNewDateProps={selectedNewDateProps}
                selectedNewRole={selectedNewRole}
                roleHistory={roleHistoryWithoutDefault}
                onClose={resetState}
                accountId={accountId}
                isTeamRole={isTeamRole}
              />
            );

          case 'delete':
            return (
              <MemberRoleDeleteConfirmationModal
                roleMembership={roleMembershipBeingEdited}
                isTeamRole={isTeamRole}
                onClose={resetState}
              />
            );

          case 'history':
          default:
            return (
              <MultiStepFlyout
                copy={copy}
                target={targetRef}
                items={roleHistoryWithoutDefault}
                idKey="id"
                renderHeader={renderHeaderText}
                renderEdit={noop}
                renderItem={renderItem}
                handleSubmit={noop}
                onStickyClick={noop}
                handleClose={onClose}
                stickyRow={true}
                hideFooter
                listWidth={listWidth}
                isWhite
                listItemContainerStyle={listItemContainerStyle}
                renderTableBodyHeader={renderTableBodyHeader}
                renderStickyRow={noop}
                globalClassName={'member-rate-phases-global'}
                isAlwaysGlobal
                renderHeaderButton={renderHeaderButton}
                zIndex={1060} // max value where RolesDropdown (popover) will still show above
              />
            );
        }
      })()}
      {/* Role selection list */}
      {showRolesDropdown && (
        <PositionsDropdown
          onClose={() => setShowRolesDropdown(false)}
          onSelect={onNewRoleSelect}
          targetRef={roleDropdownTargetRef}
          closeOnSelect
        />
      )}
    </>
  );
};

const makeMapStateToProps = () => {
  const getOwnTeamPositionHistory = makeGetOwnTeamPositionHistory();
  const mapStateToProps = (state, ownProps) => {
    const teamMembersHash = getTeamMembersHash(state);
    return {
      me: getMe(state),
      member: teamMembersHash[ownProps.accountId],
      roleHash: getPositions(state),
      // member's history of roles
      roleHistory: ownProps.isTeamRole
        ? getOwnTeamPositionHistory(state, {
            accountId: ownProps.accountId
          })
        : emptyArray
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(MemberRoleHistoryDropdown);
