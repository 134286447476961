import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { PasswordPolicyList } from './PasswordPolicyList';

class ResetPasswordForm extends React.Component {
  state = {
    password: null,
    confirm_password: null,
    validationError: null,
    first_name: '',
    last_name: ''
  };

  handleChange = (event) => {
    const isMobile = this.props.width < 500;
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  };

  formSubmit = (event) => {
    event.preventDefault();
    this.setState({ validationError: null });
    if (
      !(
        this.state.password &&
        this.state.password === this.state.confirm_password &&
        this.state.password.length >= 6
      )
    ) {
      this.setState({
        validationError:
          'Your passwords must match and must be at least 8 characters.'
      });
      return;
    }
    const { handleSubmit } = this.props;
    handleSubmit(
      this.state.password,
      this.state.first_name,
      this.state.last_name
    );
  };

  render() {
    const { errorMessage, isInvite } = this.props;
    const isMobile = this.props.width < 500;

    return (
      <div className="input-reset">
        <h3>Reset Password</h3>
        {!isMobile ? (
          <h6 className="password-reset-text">
            Enter your new password to reset
          </h6>
        ) : null}
        <div className="auth-modal-error">
          <span>{this.state.validationError}</span>
        </div>
        <div>
          <form onSubmit={this.formSubmit}>
            <div className={cn('form-group', { 'has-danger': errorMessage })}>
              <input
                type="password"
                defaultValue={this.state.password}
                name="password"
                onChange={this.handleChange}
                placeholder="Set Password"
                required
                className={cn('user-input', {
                  'form-control-danger': errorMessage
                })}
              />
              <p className="line" />
              <input
                type="password"
                defaultValue={this.state.confirm_password}
                name="confirm_password"
                onChange={this.handleChange}
                placeholder="Confirm Password"
                required
                className={cn('user-input', {
                  'form-control-danger': errorMessage
                })}
              />
              <p className="line" />
              <PasswordPolicyList />
            </div>
            <div className="auth-modal-submit set-password-modal-actions">
              <button type="submit">Update</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isInvite: PropTypes.bool
};

export default ResetPasswordForm;
