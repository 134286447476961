import React from 'react';
import PropTypes from 'prop-types';
import differenceWith from 'lodash/differenceWith';

import { connect } from 'react-redux';
import {
  fetchTeamMembers,
  addMemberToGroup,
  addBulkMembersToGroup,
  deleteMemberFromGroup,
  closeEditGroupModal,
  openTeamMemberModal,
  toggleMemberInput,
  toggleInviteButton,
  toggleInviteForm,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  openAddMembersForm
} from 'actionCreators';
import { EditMemberList } from 'views';
import {
  getEditingGroup,
  getAuth,
  getMe,
  makeGetNonGuestBoardMembers,
  getRegularTeamMembers,
  getSelectedBoard,
  getSelectedGroupId,
  getProjectGroupMemberInputOpen,
  showInviteButton,
  getInviteFormOpen,
  getAddMembersForm
} from 'selectors';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

class EditBoardMemberListContainer extends React.Component {
  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  render() {
    const {
      memberList,
      allMemberList,
      addMemberToGroup,
      addBulkMembersToGroup,
      editingGroup,
      deleteMemberFromGroup,
      closeEditGroupModal,
      openTeamMemberModal,
      me,
      showInput,
      toggleMemberInput,
      showInviteButton,
      toggleInviteButton,
      showInviteForm,
      toggleInviteForm,
      changeInputValue,
      inputValue,
      groupId,
      inviteTeamMember,
      clearMemberFilter,
      addMembersForm,
      openBulkAddMembersForm,
      closeBulkAddMembersForm,
      bulkAddIsOpen,
      populateBulkAddMembers,
      emptyBulkAddMembers,
      openAddMembersForm,
      nonGuestBoardMembers,
      roles,
      updateBoardMemberRole,
      onCloseModal
    } = this.props;

    const membersNotInGroup = differenceWith(
      allMemberList,
      memberList,
      (m1, m2) => m1.account.id === m2.account.id
    );

    return (
      <EditMemberList
        memberList={nonGuestBoardMembers}
        showInput={showInput}
        toggleMemberInput={toggleMemberInput}
        showInviteButton={showInviteButton}
        toggleInviteButton={toggleInviteButton}
        showInviteForm={showInviteForm}
        toggleInviteForm={toggleInviteForm}
        changeInputValue={changeInputValue}
        inputValue={inputValue}
        allMemberList={allMemberList}
        membersNotInGroup={membersNotInGroup}
        addMemberToSelection={addMemberToGroup}
        addBulkMembersToSelection={addBulkMembersToGroup}
        selection={editingGroup}
        deleteMemberFromSelection={deleteMemberFromGroup}
        closeModal={closeEditGroupModal}
        onCloseModal={onCloseModal}
        openTeamMemberModal={openTeamMemberModal}
        me={me}
        groupId={groupId}
        inviteTeamMember={inviteTeamMember}
        clearMemberFilter={clearMemberFilter}
        addMembersForm={addMembersForm}
        bulkAddIsOpen={bulkAddIsOpen}
        openBulkAddMembersForm={openBulkAddMembersForm}
        closeBulkAddMembersForm={closeBulkAddMembersForm}
        populateBulkAddMembers={populateBulkAddMembers}
        emptyBulkAddMembers={emptyBulkAddMembers}
        openAddMembersForm={() => openAddMembersForm(MODAL_TYPE.BOARD)}
        roles={roles}
        changeRoleFromLocalList={updateBoardMemberRole}
      />
    );
  }
}

EditBoardMemberListContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  memberList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  allMemberList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  editingGroup: PropTypes.object,
  addMemberToGroup: PropTypes.func.isRequired,
  closeEditGroupModal: PropTypes.func.isRequired,
  fetchTeamMembers: PropTypes.func.isRequired,
  deleteMemberFromGroup: PropTypes.func.isRequired,
  openTeamMemberModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  showInput: PropTypes.bool,
  changeInputValue: PropTypes.func.isRequired,
  inputValue: PropTypes.string
};

const makeMapStateToProps = () => {
  const getNonGuestBoardMembers = makeGetNonGuestBoardMembers();
  const mapStateToProps = (state, ownProps) => {
    return {
      auth: getAuth(state),
      me: getMe(state),
      allMemberList: getRegularTeamMembers(state),
      editingGroup: getEditingGroup(state) || getSelectedBoard(state),
      groupId: getSelectedGroupId(state),
      showInput: getProjectGroupMemberInputOpen(state),
      showInviteButton: showInviteButton(state),
      showInviteForm: getInviteFormOpen(state),
      addMembersForm: getAddMembersForm(state),
      nonGuestBoardMembers: getNonGuestBoardMembers(state, ownProps)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchTeamMembers,
  addMemberToGroup,
  addBulkMembersToGroup,
  deleteMemberFromGroup,
  closeEditGroupModal,
  openTeamMemberModal,
  toggleMemberInput,
  toggleInviteButton,
  toggleInviteForm,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  openAddMembersForm
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  fetchTeamMembers: () => dispatchProps.fetchTeamMembers(stateProps.auth.token),
  addMemberToGroup: (...args) =>
    dispatchProps.addMemberToGroup(stateProps.auth.token, ...args),
  addBulkMembersToGroup: (...args) =>
    dispatchProps.addBulkMembersToGroup(stateProps.auth.token, ...args),
  deleteMemberFromGroup: (member, selection, me, permissions) =>
    dispatchProps.deleteMemberFromGroup(
      stateProps.auth.token,
      member.account.id,
      selection.id
    )
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditBoardMemberListContainer);
