import { Capacity, CapacityGroup } from 'CapacityModule/models';

export const capacityGroupUtils = {
  getIsArchived: (policy: CapacityGroup) => !!policy.discarded_at,
  getIsUserDefined: (policy: CapacityGroup) => !policy.is_default
};

const capacityDayOfWeekToFormattedString: Record<keyof Capacity, string> = {
  monday: 'Mo',
  tuesday: 'Tu',
  wednesday: 'We',
  thursday: 'Th',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'Su'
};

export const capacityGroupFormatUtils = {
  getFormattedStringForCapacityDayOfWeek: (dayOfWeek: keyof Capacity) =>
    capacityDayOfWeekToFormattedString[dayOfWeek],
  getName: (policy: CapacityGroup) => {
    if (!policy.is_custom) return policy.name;

    let customPolicyName = '';
    const orderedDaysOfWeek: (keyof Capacity)[] = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ];

    orderedDaysOfWeek.forEach((day, index) => {
      if (policy.capacity[day] > 0) {
        customPolicyName += `${policy.capacity[day]}${
          capacityDayOfWeekToFormattedString[day]
        }${index !== orderedDaysOfWeek.length - 1 ? '/' : ''}`;
      }
    });

    return customPolicyName;
  }
};
