import { useState, useMemo } from 'react';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { ValueOf } from 'type-fest';

const emptyArray = [];

export interface UseFilterSearchProps<ItemType, ItemHashType> {
  itemHash?: ItemHashType;
  items: Array<ItemType>;
  itemSearchableKeys?: (keyof ValueOf<ItemHashType>)[];
  /**
   * use when BE handles search to prevent unnecessary calculations and UI flicker.
   * this hook will still provide searchText and setSearchText
   */
  isFeSearchDisabled?: boolean;
}

export const useFilterSearch = <
  ItemType extends string | number,
  ItemHashType extends
    | Record<string | number, string>
    | Record<string | number, Record<string, any>>
>({
  itemHash,
  items,
  itemSearchableKeys = emptyArray,
  isFeSearchDisabled
}: UseFilterSearchProps<ItemType, ItemHashType>) => {
  const [searchText, setSearchText] = useState('');

  const optionsArray = useMemo(() => {
    if (!searchText || isFeSearchDisabled) return items;

    const searchWords = searchText.split(' ').filter((str) => str !== '-');

    function itemFilter(item: typeof items[0]) {
      const itemToUse = itemHash?.[item] || item;

      return typeof itemToUse === 'string'
        ? searchWords.every((word) =>
            itemToUse.toLowerCase().includes(word.toLowerCase().trim())
          )
        : filterItemWithWhiteSpace({
            searchWords,
            item: itemToUse,
            filterKeysArray: itemSearchableKeys
          });
    }

    return items.filter(itemFilter);
  }, [itemSearchableKeys, itemHash, items, searchText, isFeSearchDisabled]);

  return {
    searchText,
    setSearchText,
    optionsArray
  };
};
