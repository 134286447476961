import { initialState } from 'reducers/priorities';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';

const getPriorities = (state) => state.priorities || initialState;

export const getProjectPriorities = createSelector(
  getPriorities,
  (state) => state.projectPriorities
);

export const getProjectPrioritiesIdArray = createSelector(
  getProjectPriorities,
  (priorities) => priorities.map((priority) => priority.id)
);

export const getProjectPrioritiesHash = createSelector(
  getProjectPriorities,
  (state) => keyBy(state, ({ id }) => id)
);
