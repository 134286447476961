import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch, useSelector, batch } from 'react-redux';
import { VIEW_BY } from 'appConstants/workload';
import CollapseAllButton from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';
import NewListButton from './NewListButton';
import { StyledTasksIcon } from 'views/Home/Sidebar/styles';
import {
  getCurrentFilter,
  getMemberModalTaskCounts,
  getMatchedRouteParams,
  getIsOnPersonalProject,
  getSelectedTeamId
} from 'selectors';
import {
  createProjectTaskGroup,
  fetchPhasesByProjectIds,
  openMilestoneModal,
  setMemberModalTaskFilter,
  fetchMemberTodayTaskCount,
  fetchMemberOverdueTaskCount,
  fetchMemberScheduledTaskCount,
  fetchMemberUnscheduledTaskCount,
  fetchMemberCompletedTaskCount
} from 'actionCreators';
import CloseIcon from 'icons/CloseIcon';
import { TASK_STATUS_ITEMS, TASK_STATUS_COLORS } from 'appConstants/taskStatus';

const HeaderText = styled.div`
  font-size: 22px;
  font-weight: 700;
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray1};
  margin-top: 20px;
  margin-left: 40px;
`;

const iconStyle = `
height: 20px;
flex: 0 0 20px;
margin-right: 10px;
`;

const FilterButton = styled.div`
  width: 31px;
  height: 19px;
  border-radius: 11px;
  margin-top: 4px;
  margin-left: 4px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) =>
    props.isActive
      ? `color : white; 
    background: ${props.buttonColor};
    border: 1px solid ${props.buttonColor};
    `
      : `color: ${props.color};
    border: 1px solid ${props.buttonColor};`}
`;

const CloseIconContainer = styled.div`
  margin-top: -40px;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: 16px;
  left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
`;

const FilterButtonContainer = styled.div`
  z-index: 1000;
  position: relative;
`;

const collapseButtonStyles = (length, isDefault) => ({
  left: 44,
  top: isDefault ? 24 : -11,
  zIndex: 10,
  visibility: length <= 1 ? 'hidden' : 'visible'
});

const TasksTableOptions = ({
  toggleCollapseAll,
  allCollapsed,
  numLists,
  viewBy,
  projectId,
  isDefaultList,
  isMemberModalOpen
}) => {
  const dispatch = useDispatch();
  const modalTaskFilter = useSelector(getCurrentFilter);
  const memberModalTaskCounts = useSelector(getMemberModalTaskCounts);
  const matchedParams = useSelector(getMatchedRouteParams);
  const isOnPersonalProject = useSelector(getIsOnPersonalProject);
  const teamId = useSelector(getSelectedTeamId);
  const setFilter = (newFilter) => {
    dispatch(
      setMemberModalTaskFilter({
        memberModalTaskFilter: {
          state: newFilter
        }
      })
    );
  };

  useEffect(() => {
    if (matchedParams?.memberId && teamId) {
      batch(() => {
        dispatch(
          fetchMemberTodayTaskCount({
            accountId: matchedParams.memberId,
            teamId: teamId
          })
        );
        dispatch(
          fetchMemberOverdueTaskCount({
            accountId: matchedParams.memberId,
            teamId: teamId
          })
        );
        dispatch(
          fetchMemberScheduledTaskCount({
            accountId: matchedParams.memberId,
            teamId: teamId
          })
        );
        dispatch(
          fetchMemberUnscheduledTaskCount({
            accountId: matchedParams.memberId,
            teamId: teamId
          })
        );
        /* dispatch(
          fetchMemberCompletedTaskCount({
            accountId: matchedParams.memberId
          })
        ); */
      });
    }
  }, [dispatch, matchedParams, teamId]);

  const handleNewListClick = () => {
    if (!projectId) {
      return;
    }
    if (viewBy === VIEW_BY.TASK_GROUPS) {
      dispatch(
        createProjectTaskGroup({ index: 0, name: 'New Task List', projectId })
      );
    } else if (viewBy === VIEW_BY.PHASES) {
      dispatch(fetchPhasesByProjectIds({ projectIds: [projectId] }));
      dispatch(openMilestoneModal());
    }
  };

  const renderCloseFilterButton = () => (
    <CloseIconContainer onClick={() => setFilter('incomplete')}>
      <CloseIcon width="11px" height="11px" />
    </CloseIconContainer>
  );

  return (
    <div style={{ display: 'flex' }}>
      {isMemberModalOpen ? (
        <HeaderText>
          <StyledTasksIcon
            iconStyle={iconStyle + `margin-left: 12px;`}
            currentColor="#4a4a4a"
          />
          Tasks
          <FilterButtonContainer>
            {modalTaskFilter.state === TASK_STATUS_ITEMS.UNSCHEDULED &&
              renderCloseFilterButton()}
            <FilterButton
              data-for={'app-tooltip'}
              data-tip={'Unplanned Tasks'}
              data-html
              isActive={modalTaskFilter.state === TASK_STATUS_ITEMS.UNSCHEDULED}
              buttonColor={
                TASK_STATUS_COLORS[TASK_STATUS_ITEMS.UNSCHEDULED].buttonColor
              }
              color={TASK_STATUS_COLORS[TASK_STATUS_ITEMS.UNSCHEDULED].color}
              onClick={() => setFilter(TASK_STATUS_ITEMS.UNSCHEDULED)}
            >
              {memberModalTaskCounts.unscheduled}
            </FilterButton>
          </FilterButtonContainer>
          <FilterButtonContainer>
            {modalTaskFilter.state === TASK_STATUS_ITEMS.OVERDUE &&
              renderCloseFilterButton()}
            <FilterButton
              data-for={'app-tooltip'}
              data-tip={'Overdue Tasks'}
              data-html
              isActive={modalTaskFilter?.state === TASK_STATUS_ITEMS.OVERDUE}
              buttonColor={
                TASK_STATUS_COLORS[TASK_STATUS_ITEMS.OVERDUE].buttonColor
              }
              color={TASK_STATUS_COLORS[TASK_STATUS_ITEMS.OVERDUE].color}
              onClick={() => setFilter(TASK_STATUS_ITEMS.OVERDUE)}
            >
              {memberModalTaskCounts.overdue}
            </FilterButton>
          </FilterButtonContainer>
          <FilterButtonContainer>
            {modalTaskFilter.state === TASK_STATUS_ITEMS.TODAY &&
              renderCloseFilterButton()}
            <FilterButton
              data-for={'app-tooltip'}
              data-tip={'Tasks Due Today'}
              data-html
              isActive={modalTaskFilter?.state === TASK_STATUS_ITEMS.TODAY}
              buttonColor={
                TASK_STATUS_COLORS[TASK_STATUS_ITEMS.TODAY].buttonColor
              }
              color={TASK_STATUS_COLORS[TASK_STATUS_ITEMS.TODAY].color}
              onClick={() => setFilter(TASK_STATUS_ITEMS.TODAY)}
            >
              {memberModalTaskCounts.today}
            </FilterButton>
          </FilterButtonContainer>
          <FilterButtonContainer>
            {modalTaskFilter.state === TASK_STATUS_ITEMS.SCHEDULED &&
              renderCloseFilterButton()}
            <FilterButton
              data-for={'app-tooltip'}
              data-tip={'Planned Tasks'}
              data-html
              isActive={modalTaskFilter?.state === TASK_STATUS_ITEMS.SCHEDULED}
              buttonColor={
                TASK_STATUS_COLORS[TASK_STATUS_ITEMS.SCHEDULED].buttonColor
              }
              color={TASK_STATUS_COLORS[TASK_STATUS_ITEMS.SCHEDULED].color}
              onClick={() => setFilter(TASK_STATUS_ITEMS.SCHEDULED)}
            >
              {memberModalTaskCounts.scheduled}
            </FilterButton>
          </FilterButtonContainer>
          {/* }  <FilterButton
            data-for={'app-tooltip'}
            data-tip={'Completed Tasks'}
            data-html
            isActive={modalTaskFilter?.state === TASK_STATUS_ITEMS.COMPLETED}
            buttonColor={TASK_STATUS_COLORS[TASK_STATUS_ITEMS.COMPLETED].buttonColor}
            color={TASK_STATUS_COLORS[TASK_STATUS_ITEMS.COMPLETED].color}
            onClick={() => setFilter(TASK_STATUS_ITEMS.COMPLETED)}
          >
            {memberModalTaskCounts.completed}
      </FilterButton> */}
        </HeaderText>
      ) : (
        <>
          <CollapseAllButton
            onToggle={toggleCollapseAll}
            isCollapsed={allCollapsed}
            style={collapseButtonStyles(numLists, isDefaultList)}
            isOnPersonalProject={isOnPersonalProject}
          />
          <NewListButton
            handleClick={handleNewListClick}
            viewBy={viewBy}
            isDefaultList={isDefaultList}
            numLists={numLists}
          />
        </>
      )}
    </div>
  );
};
export default TasksTableOptions;
