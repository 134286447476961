import React from 'react';
import SvgIcon from 'components/SvgIcon';

const WideFilterIcon = ({
  color = '#2F80ED',
  fill = 'none',
  width = 14,
  height = 10,
  ...props
}) => (
  <SvgIcon
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 13 11"
    {...props}
  >
    <path d="M14 1.46494H7H2.50733e-06L0 0.520508H14V1.46494Z" fill={color} />
    <path d="M5.28302 8.59408V9.52051H8.71698V8.59408H5.28302Z" fill={color} />
    <path
      d="M3.16981 4.59796L3.16981 5.53525H10.8302V4.59796H3.16981Z"
      fill={color}
    />
  </SvgIcon>
);

export default WideFilterIcon;
