import {
  getMatchedRouteParams,
  getInstalledStandardSettingsModuleIds
} from 'selectors';
import { withRouter } from 'react-router-dom';
import { useAppSelector } from 'reduxInfra/hooks';
import { Tabs } from 'SettingsModule/components/styles';

const StandardSettingsTabNav = ({ history }) => {
  const matchedParams = useAppSelector(getMatchedRouteParams);

  const installedModuleIds = useAppSelector(
    getInstalledStandardSettingsModuleIds
  );
  // function signature required by ModuleList due to tab switching function signature on board and project views
  const changeViewDisplay = (newTab) => (e) => {
    const { teamSlug } = matchedParams;

    const route = `/${teamSlug}/settings/standards/${newTab}`;
    history.push(route);
  };

  return (
    <Tabs
      currentTab={matchedParams.settingsViewType}
      changeTabs={changeViewDisplay}
      installedModuleIds={installedModuleIds}
      viewType="standardSettings"
    />
  );
};

export default withRouter(StandardSettingsTabNav);
