import { Component } from 'react';
import styled from 'styled-components';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import CalendarIcon from 'icons/CalendarIcon';
import {
  TaskBatchActionOption,
  TaskBatchActionOptionIcon,
  TaskBatchActionOptionText
} from '../styles';

import { calculateSelectedDateRange } from 'appUtils/dateRangeHelpers';

import { noop } from 'appUtils';

const ScheduleBatchIcon = styled(CalendarIcon)`
  display: flex;
  align-items: center;
  height: 12px;
  width: 12px;
  path {
    fill: ${({ theme }) => theme.colors.colorRoyalBlue};
    stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

class ScheduleBatchMenuItem extends Component {
  state = {
    newDates: null
  };

  handleDateSelection = (values) => {
    const { task } = this.props;

    const newDates = calculateSelectedDateRange({
      newValues: values,
      start: task?.schedule_start,
      end: task?.schedule_end,
      itemExists: !!task
    });
    this.setState({
      newDates
    });
  };

  clearDates = () => {
    this.setState({
      newDates: []
    });
  };

  saveDates = ({ startDate, endDate }) => {
    const { handleSelect } = this.props;
    handleSelect(startDate, endDate);
  };

  renderCustomInput = ({ value, openCalendar }) => (
    <TaskBatchActionOption
      key="batchMenuScheduleItem"
      onClick={this.props.isDisabled ? noop : openCalendar}
      isDisabled={this.props.isDisabled}
    >
      <TaskBatchActionOptionIcon>
        <ScheduleBatchIcon />
      </TaskBatchActionOptionIcon>
      <TaskBatchActionOptionText>Plan</TaskBatchActionOptionText>
    </TaskBatchActionOption>
  );

  render() {
    return (
      <div>
        <DateRangeCalendar
          customInput={(_startDate, _endDate, openCalendar) =>
            this.renderCustomInput({ openCalendar })
          }
          itemStartDate={null}
          itemEndDate={null}
          onSubmit={this.saveDates}
          calendarClassNames={'batch-task-calendar'}
        />
      </div>
    );
  }
}

export default ScheduleBatchMenuItem;
