import * as appConstants from 'appConstants';
import * as constants from '../constants';
import produce from 'immer';

const initialCustomFieldState = {
  allValues: [],
  isFetchingAllValues: false
};

export const initialState = {
  customFieldStates: {} // keyed by internal label, initial value is initialCustomFieldState
};

const customFields = (state = initialState, action) => {
  const { payload } = action;

  return produce(state, (nextState) => {
    switch (action.type) {
      case appConstants.LOGOUT_USER: {
        nextState = initialState;
        break;
      }

      case constants.FETCH_CUSTOM_FIELD_ALL_VALUES.TRIGGER: {
        const { internalLabel } = payload;
        if (!state.customFieldStates[internalLabel]) {
          nextState.customFieldStates[internalLabel] = {
            ...initialCustomFieldState
          };
        }
        nextState.customFieldStates[internalLabel].isFetchingAllValues = true;
        break;
      }

      case constants.FETCH_CUSTOM_FIELD_ALL_VALUES.FAILURE: {
        const { internalLabel } = payload.requestPayload;
        nextState.customFieldStates[internalLabel].isFetchingAllValues = false;
        break;
      }

      case constants.FETCH_CUSTOM_FIELD_ALL_VALUES.SUCCESS: {
        const { internalLabel } = payload.requestPayload;
        const nextCustomFieldState = nextState.customFieldStates[internalLabel];
        nextCustomFieldState.allValues = payload.response;
        nextCustomFieldState.isFetchingAllValues = false;
        break;
      }
    }
  });
};

export default customFields;
