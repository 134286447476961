import { ModalBody, ButtonGroup, Button } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

import {
  getActivityModalId,
  getActivityModalName,
  getActivityModalIsTitleRequired,
  getActivityModalBillable,
  getActivityModalPreFillText,
  getActivityModalById,
  getActivityModalCustom
} from 'BudgetModule/selectors';
import { getSelectedTeamId } from 'selectors';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  ArchiveButton,
  ModalBodyDescriptionText,
  CheckBoxContainer,
  OuterCheckBox,
  FieldsContainer
} from 'BudgetModule/components/styles';
import CheckBox from './CheckBox';
import PhaseOfWorkTemplateInput from 'BudgetModule/components/PhaseOfWorkTemplateInput';
import { closeActivityModal } from 'BudgetModule/actionCreators';
import { createActivity, updateActivity } from 'actionCreators';
import HelpIcon from 'icons/HelpIcon';
import withIsHoursOnly from 'hocs/withIsHoursOnly';

const HelpIconContainer = styled.span`
  position: relative;
  bottom: 11px;
  right: 4px;

  svg {
    path:nth-child(2) {
      stroke: transparent;
      fill: ${theme.colors.colorLightGray12};
    }

    path:nth-child(3) {
      fill: ${theme.colors.colorPureBlack};
    }
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  border-radius: 25px;
  border-style: solid;
  border-color: ${theme.colors.colorLightGray6};
  border-width: thin;
  text-align: center;
  cursor: pointer;
  .left {
    width: 35px;
    color: ${({ isYes }) =>
      isYes ? theme.colors.colorPureWhite : theme.colors.colorCalendarGray};
    background-color: ${({ isYes }) =>
      isYes ? theme.colors.colorCalendarBlue : theme.colors.colorPureWhite};
  }

  .right {
    width: 32px;
    color: ${({ isYes }) =>
      !isYes ? theme.colors.colorPureWhite : theme.colors.colorCalendarGray};
    background-color: ${({ isYes }) =>
      !isYes ? theme.colors.colorCalendarBlue : theme.colors.colorPureWhite};
  }
`;

const ToggleText = styled.div`
  border-radius: 10px;
`;

const RowContainer = styled.div`
  color: ${theme.colors.colorMediumGray9};
  user-select: none;
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 20px;
  justify-content: space-between;
  width: 256px;
`;

const RowsContainer = styled.div`
  padding-left: 10px;
`;

const Text = styled.div``;

const Toggle = ({ isYes, toggle }) => {
  return (
    <ToggleContainer isYes={isYes} onClick={toggle}>
      <ToggleText className="left">Yes</ToggleText>
      <ToggleText className="right">No</ToggleText>
    </ToggleContainer>
  );
};

class PhaseModalBody extends React.Component {
  state = {
    title: this.props.title || this.props.preFillText,
    checked: !!this.props.checked,
    billable: !!this.props.billableChecked
  };

  handleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  onCancel = () => {
    this.handleClose();
  };

  onSubmit = (params) => {
    this.apiCall(params);
    this.handleClose();
  };

  componentDidMount() {
    rebuildTooltip();
  }

  onConfirm = () => {
    const { teamId, id, isCustomActivityModal } = this.props;
    const { title, checked, billable } = this.state;

    this.apiCall({
      teamId,
      title,
      id,
      requireTitle: checked,
      billable,
      isCustom: !!isCustomActivityModal
    });

    this.handleClose();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.preFillText && this.props.preFillText) {
      this.setState({
        name: this.props.preFillText
      });
    }
    if (prevProps.billableChecked !== this.props.billableChecked) {
      this.setState({
        billable: this.props.billableChecked
      });
    }
  }

  handleClose = () => {
    const { closeActivityModal } = this.props;
    closeActivityModal({});
  };

  archiveActivity = (params) => {
    const { updateActivity } = this.props;
    updateActivity({
      ...params,
      archived: true
    });
  };

  unarchiveActivity = (params) => {
    const { updateActivity } = this.props;
    updateActivity({
      ...params,
      archived: false
    });
  };

  apiCall = (params) => {
    const { id, updateActivity, createActivity, isConfirm, activity } =
      this.props;

    const apiCall = id
      ? isConfirm
        ? activity.archived
          ? this.unarchiveActivity
          : this.archiveActivity
        : updateActivity
      : createActivity;
    apiCall(params);
  };

  renderConfirmButtons = () => {
    const { clearConfirm } = this.props;
    return (
      <>
        <Left />
        <Right>
          <CancelButton onClick={clearConfirm}>Cancel</CancelButton>
          <ConfirmButton onClick={this.onConfirm}>Yes</ConfirmButton>
        </Right>
      </>
    );
  };

  toggleCheckBox = () =>
    this.setState((prevState) => ({ checked: !prevState.checked }));

  toggleBillableChecked = (option) => () => {
    this.setState((prevState) => ({ billable: !prevState.billable }));
  };

  renderInitialButtons = () => {
    const { id, setConfirm, activity } = this.props;
    const isGray = this.props.preFillText ? false : !this.state.title;
    return (
      <>
        <Left>
          {id && (
            <ArchiveButton onClick={setConfirm}>
              {activity.archived ? 'Unarchive' : 'Archive'}
            </ArchiveButton>
          )}
        </Left>
        <Right>
          <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
          <ConfirmButton
            className="create-and-save-button"
            isGray={isGray}
            onClick={this.onConfirm}
          >
            {id ? 'Save' : 'Create'}
          </ConfirmButton>
        </Right>
      </>
    );
  };

  renderButtons = () => {
    const { isConfirm } = this.props;
    return isConfirm
      ? this.renderConfirmButtons()
      : this.renderInitialButtons();
  };

  render() {
    const { title, id, isConfirm, preFillText, activity, isHoursOnly } =
      this.props;
    const { checked, billable } = this.state;
    return (
      <ModalBody>
        {isConfirm ? (
          <div>
            <div className="remove-header">
              Are you sure you want to{' '}
              {`${activity.archived ? 'unarchive' : 'archive'} ${title}`} ?
            </div>
            <div className="remove-text" />
          </div>
        ) : (
          <FieldsContainer>
            <PhaseOfWorkTemplateInput
              handleSubmit={this.onSubmit}
              name={this.props.title}
              inputClassName="activity-modal-input"
              placeholder={'Type Work Category'}
              onChangeCallback={this.handleChange}
              id={id}
              preFillText={preFillText}
              isInModal={true}
            />
            <RowsContainer>
              <RowContainer>
                <Text>Require Additional Description</Text>
                <HelpIconContainer
                  data-for="app-tooltip"
                  data-class="center"
                  data-html
                  data-effect="solid"
                  data-tip="When Mosaic's timesheets are used, <br /> check this box to require an additional <br /> description to the Work Category."
                >
                  <HelpIcon height={10} width={10} />
                </HelpIconContainer>
                <Toggle isYes={checked} toggle={this.toggleCheckBox} />
                {/* <CheckBox toggle={this.toggleCheckBox} checked={checked} /> */}
              </RowContainer>
              {!isHoursOnly && (
                <RowContainer>
                  <Text>Make Project Billable</Text>
                  <Toggle
                    isYes={billable}
                    toggle={this.toggleBillableChecked('billable')}
                  />
                </RowContainer>
              )}
            </RowsContainer>
          </FieldsContainer>
        )}
        <ModalButtonsRow>{this.renderButtons()}</ModalButtonsRow>
      </ModalBody>
    );
  }
}

PhaseModalBody.propTypes = {};

const mapStateToProps = (state) => ({
  teamId: getSelectedTeamId(state),
  id: getActivityModalId(state),
  title: getActivityModalName(state),
  checked: getActivityModalIsTitleRequired(state),
  billableChecked: getActivityModalBillable(state),
  isCustomActivityModal: getActivityModalCustom(state),
  preFillText: getActivityModalPreFillText(state),
  activity: getActivityModalById(state)
});

const mapDispatchToProps = {
  updateActivity,
  createActivity,
  closeActivityModal
};

export default withIsHoursOnly(
  connect(mapStateToProps, mapDispatchToProps)(PhaseModalBody)
);
