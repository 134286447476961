import { fetchMemberBudgets } from 'BudgetModule/actionCreators';
import {
  BillRateType,
  BudgetTrackingType
} from 'BudgetModule/models/BudgetViewSettings';
import { MemberBudgetId } from 'models/memberBudget';
import { ProjectId } from 'ProjectsModule/models/project';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getFetchedMemberBudgetProjectIds, getMemberBudgets } from 'selectors';
import { useProjectBudgetViewSettings } from '../../BudgetModule/hooks/useProjectBudgetViewSettings';
import { useTeamRates } from './useTeamRates';
import { v4 as uuid } from 'uuid';
import { useUnmount } from 'react-use';
import FetchManager from 'classes/FetchManager';
import { Rate } from 'RatesModule/models/Rate';
import { Salary } from 'RatesModule/models/Salary';

export const useProjectRates = () => {
  const dispatch = useAppDispatch();

  const {
    getTeamMemberBillRate,
    getTeamMemberCostRate,
    getTeamRoleBillRate,
    getTeamRoleCostRate,
    fetchTeamMemberBillRates,
    fetchTeamMemberCostRates,
    fetchTeamRates,
    fetchTeamRolesRates
  } = useTeamRates();
  const { fetchBudgetViewSettings, getBudgetViewSettings } =
    useProjectBudgetViewSettings();
  const memberBudgets = useAppSelector(getMemberBudgets);
  const fetchedMemberBudgetProjectIds = useAppSelector(
    getFetchedMemberBudgetProjectIds
  );

  const getProjectMemberBillRate = useCallback(
    (memberBudgetId: MemberBudgetId): Rate | undefined => {
      const memberBudget = memberBudgets[memberBudgetId];

      if (!memberBudget) return;

      const budgetViewSettings = getBudgetViewSettings(memberBudget.project_id);

      // When tracking only hours, there are no rates.
      return budgetViewSettings.budget_tracking_type ===
        BudgetTrackingType.Hours ||
        // Work category bill rates depend on the time entry, not the member.
        budgetViewSettings.bill_rate_type === BillRateType.Activity
        ? undefined
        : budgetViewSettings.bill_rate_type === BillRateType.Member
        ? getTeamMemberBillRate(memberBudget.account_id)
        : // budgetViewSettings.bill_rate_type === BillRateType.Position
          getTeamRoleBillRate(
            memberBudget.position_id,
            budgetViewSettings?.rate_group_id ?? undefined
          );
    },
    [
      getBudgetViewSettings,
      getTeamMemberBillRate,
      getTeamRoleBillRate,
      memberBudgets
    ]
  );

  // This function returns a `Rate` if using role cost rates or `Salary` if
  // using member cost rates.
  const getProjectMemberCostRate = useCallback(
    (memberBudgetId: MemberBudgetId): Rate | Salary | undefined => {
      const memberBudget = memberBudgets[memberBudgetId];

      if (!memberBudget) return;

      const budgetViewSettings = getBudgetViewSettings(memberBudget.project_id);

      return !memberBudget ||
        // When tracking only hours, there are no rates.
        budgetViewSettings.budget_tracking_type === BudgetTrackingType.Hours ||
        // Work category bill rates depend on the time entry, not the member.
        budgetViewSettings.bill_rate_type === BillRateType.Activity
        ? undefined
        : budgetViewSettings.bill_rate_type === BillRateType.Member
        ? getTeamMemberCostRate(memberBudget.account_id)
        : // budgetViewSettings.bill_rate_type === BillRateType.Position
          getTeamRoleCostRate(
            memberBudget.position_id,
            budgetViewSettings?.rate_group_id ?? undefined
          );
    },
    [
      getBudgetViewSettings,
      getTeamMemberCostRate,
      getTeamRoleCostRate,
      memberBudgets
    ]
  );

  // Cleanup on unmount.
  const abortId = useMemo(() => `useProjectRates>${uuid()}`, []);
  useUnmount(() => FetchManager.abort(abortId));

  const fetchProjectMemberBudgets = useCallback(
    (projectId: ProjectId, forceRefetch?: boolean) => {
      if (!(projectId in fetchedMemberBudgetProjectIds) || forceRefetch)
        dispatch(fetchMemberBudgets({ projectId, meta: { abortId } }));
    },
    [abortId, dispatch, fetchedMemberBudgetProjectIds]
  );

  const fetchProjectMemberBillRates = useCallback(
    (projectId: ProjectId, forceRefetch?: boolean) => {
      fetchBudgetViewSettings(projectId);
      fetchProjectMemberBudgets(projectId, forceRefetch);
      fetchTeamMemberBillRates(forceRefetch);
      fetchTeamRates(forceRefetch);
      fetchTeamRolesRates(forceRefetch);
    },
    [
      fetchBudgetViewSettings,
      fetchProjectMemberBudgets,
      fetchTeamMemberBillRates,
      fetchTeamRates,
      fetchTeamRolesRates
    ]
  );

  const fetchProjectMemberCostRates = useCallback(
    (projectId: ProjectId, forceRefetch?: boolean) => {
      fetchBudgetViewSettings(projectId);
      fetchProjectMemberBudgets(projectId, forceRefetch);
      fetchTeamMemberCostRates(forceRefetch);
      fetchTeamRates(forceRefetch);
      fetchTeamRolesRates(forceRefetch);
    },
    [
      fetchBudgetViewSettings,
      fetchProjectMemberBudgets,
      fetchTeamMemberCostRates,
      fetchTeamRates,
      fetchTeamRolesRates
    ]
  );

  return {
    getProjectMemberBillRate,
    getProjectMemberCostRate,
    fetchProjectMemberBillRates,
    fetchProjectMemberCostRates
  };
};
