import {
  getRowValuesForEntityType,
  getProjectIdsThatMatchTitleAndNumber,
  getExistingProjectsHashByNumber,
  getExistingProjectsHashByTitle,
  getExistingProjectIdToPortfolio
} from './utils';
import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  existingProjectsHashByTitle: ReturnType<
    typeof getExistingProjectsHashByTitle
  >;
  existingProjectsHashByNumber: ReturnType<
    typeof getExistingProjectsHashByNumber
  >;
  existingProjectIdToPortfolio: ReturnType<
    typeof getExistingProjectIdToPortfolio
  >;
  existingPortfolioNames: string[];
}

export const portfolioOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingProjectIdToPortfolio,
    existingPortfolioNames
  } = additionalParams;

  const { enteredProjectTitle, enteredProjectNumber } =
    getRowValuesForEntityType(row, entityType);

  const projectIdsThatMatchTitleAndNumber =
    getProjectIdsThatMatchTitleAndNumber({
      existingProjectsHashByTitle,
      existingProjectsHashByNumber,
      enteredProjectTitle,
      enteredProjectNumber
    });

  const rowHasMatchingProjects =
    Object.keys(projectIdsThatMatchTitleAndNumber).length > 0;

  // Case 1. User has entered new project title + number combination -> can select any existing portfolio
  if (!rowHasMatchingProjects) {
    return existingPortfolioNames;
  }

  // Case 2. User has entered project title + number combination that already exists: allow selection from a
  // matching portfolio
  const portfoliosOfMatchingProjects = Object.keys(
    projectIdsThatMatchTitleAndNumber
  ).reduce<string[]>((acc, projectId) => {
    const portfolio = existingProjectIdToPortfolio[projectId];
    if (portfolio) {
      acc.push(portfolio.name);
    }
    return acc;
  }, []);

  return portfoliosOfMatchingProjects;
};
