import styled from 'styled-components';
import theme from 'theme';

export const OptionTitle = styled.div`
  align-items: center;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  font-size: 15px;
`;

export const RadioOption = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0;

  .radio-button {
    margin-right: 4px;
  }

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify};
`;

export const HolidayRow = styled.div`
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  width: 500px;
  height: 44px;
  border-top: 1px solid #f0f0f0;
  margin-left: 10px;

  & .DeleteButton {
    width: 20px;
    display: inline-block;
  }

  .TrashIcon {
    display: none;
  }

  &:hover {
    & .TrashIcon {
      display: inline-block;
      cursor: pointer;
    }
  }
`;

export const MemberCapacityTableContainer = styled.div`
  margin-top: 5px;
`;

export const WorkloadHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  // The -1rem is to account for the padding of .modal-body.
  padding-bottom: calc(41px - 1rem);
  width: 100%;
`;

export const SettingsHeader = styled.div`
  color: ${theme.colors.colorPureBlack};
  font-size: 30px;
  font-weight: 700;
`;

export const DoneButton = styled.div`
  background: ${theme.colors.colorRoyalBlue};
  border-radius: 5px;
  color: ${theme.colors.colorPureWhite};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  padding: 6px 14px;

  &:hover {
    background: ${theme.colors.colorDeeperRoyalBlue};
  }
`;

export const MemberCapacityCell = styled.div`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
`;

export const DayHeader = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 13px;
`;

export const StyledMemberCapacityInput = styled.input`
  border: 1px solid ${theme.colors.colorPaleGray5};
  color: ${theme.colors.colorMediumGray9};
  font-size: 16px;
  height: 72px;
  text-align: center;
  width: 70px;
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }
`;

export const TotalsContainer = styled(MemberCapacityCell)`
  margin-left: 2px;
`;

export const TotalsHeader = styled(DayHeader)`
  text-align: center;
`;

export const TotalsValue = styled(StyledMemberCapacityInput).attrs({
  as: 'div'
})`
  font-weight: 600;
  line-height: 70px;
`;

export const HolidaysContainer = styled(FlexContainer)`
  flex-direction: column;
  padding-bottom: 80px;
`;

export const HolidaysTableHeaderContainer = styled(FlexContainer)`
  margin-top: -20px;
  justify-content: space-between;
  font-size: 15px;
  cursor: pointer;
  color: ${theme.colors.colorMediumGray5};
  border-bottom: 1px solid ${theme.colors.colorLightGray6};
`;

export const AddHolidayButton = styled.div`
  padding-right: 35px;
  color: ${theme.colors.colorCalendarBlue};
  margin-top: 1px;
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;
`;

export const SubTitle = styled.div`
  align-items: center;
  color: ${theme.colors.colorMediumGray7};
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.5px;
  padding-left: 2px;
`;

export const SubTitleSubText = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorCalendarGray};
  margin: -3px 0 0 3px;
`;

export const OptionsTitle = styled.div`
  border-bottom: 1px solid ${theme.colors.colorLightGray6};
  color: ${theme.colors.colorMediumGray9};
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 2px 13px;
`;

export const SettingsGrid = styled.div`
  align-items: center;
  display: grid;
  font-size: 13px;
  gap: 32px 12px;
  margin: 28px 0 40px 28px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledQuestionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  margin-left: 5px;
  margin-top: 3px;
  height: 14px;
  width: 16px;
  path:nth-child(2) {
    stroke: ${theme.colors.colorMediumGray9};
    fill: ${theme.colors.colorTranslucentGray4};
  }
  path:nth-child(3) {
    fill: ${theme.colors.colorMediumGray9};
  }
`;

export const HelpIconContainer = styled.span`
  align-self: start;
  display: inline-flex;
  vertical-align: top;
`;
