import * as constants from 'appConstants';
import * as budgetConstants from 'BudgetModule/constants';
import { updateTeamMembersPermissionActionCreatorsMap } from 'PermissionsModule/actionCreators';
import { updateTeamAuthSettingsActionCreatorsMap } from 'SettingsModule/actionCreators';
import { updateTeamMembershipActionCreatorsMap } from 'TeamsModule/actionCreators';

const preserveSettingsTeam = (selectedTeam, updatedTeam) => {
  if (!selectedTeam) {
    return updatedTeam;
  }
  return {
    ...updatedTeam,
    view_settings: selectedTeam.view_settings
  };
};

const updateTeams = (teams, updatedTeam) => {
  if (teams.length) {
    return teams.map((team) =>
      team.id === updatedTeam.id ? updatedTeam : team
    );
  } else {
    return [updatedTeam];
  }
};
const updateTeamsPreserveSettings = (teams, updatedTeam) => {
  if (teams.length) {
    return teams.map((team) =>
      team.id === updatedTeam.id
        ? preserveSettingsTeam(team, updatedTeam)
        : team
    );
  } else {
    return [updatedTeam];
  }
};

export const initialState = {
  allTeams: [],
  selectedTeam: null,
  selectedMemberId: null,
  isFetchingMember: false,
  isFetchingTeam: false
};

const teams = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_TEAMS.TRIGGER:
      return {
        ...state,
        isFetchingTeam: true
      };
    case constants.FETCH_TEAMS.SUCCESS:
      const { teams } = action.payload.response;
      return {
        ...state,
        allTeams: teams,
        isFetchingTeam: false,
        selectedTeam:
          (state.selectedTeam
            ? teams.find((team) => team.id === state.selectedTeam.id)
            : state.selectedTeam) || teams[0]
      };
    case constants.FETCH_TEAMS.FAILURE:
      return {
        ...state,
        isFetchingTeam: false
      };
    case constants.TEAM_UPDATE.SUCCESS:
      const team = action.payload.response;
      const newTeams = updateTeams(state.allTeams, team);
      return {
        ...state,
        allTeams: newTeams,
        ...(state.selectedTeam?.id === team.id && { selectedTeam: team })
      };
    case constants.TEAM_MEMBERS.SUCCESS:
      const oldAllTeams = state.allTeams;
      const newAllTeams = updateTeamsPreserveSettings(
        oldAllTeams,
        action.payload.response
      );
      const preservedSettingsTeam = preserveSettingsTeam(
        state.selectedTeam,
        action.payload.response
      );
      return {
        ...state,
        allTeams: newAllTeams,
        selectedTeam: preservedSettingsTeam
      };

    case updateTeamMembersPermissionActionCreatorsMap.success.type:
    case constants.UPDATE_TEAM_MEMBERSHIP.SUCCESS:
    case updateTeamMembershipActionCreatorsMap.success.type:
    case constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.SUCCESS:
      return {
        ...state,
        allTeams: updateTeamsPreserveSettings(
          state.allTeams,
          action.payload.response.team
        ),
        selectedTeam: preserveSettingsTeam(
          state.selectedTeam,
          action.payload.response.team
        )
      };
    case constants.FETCH_TEAM_MEMBER_PROFILE.TRIGGER:
      return {
        ...state,
        isFetchingMember: true
      };
    case constants.FETCH_TEAM_MEMBER_PROFILE.SUCCESS:
      return {
        ...state,
        selectedTeamMember: action.payload.response.team_member,
        isFetchingMember: false
      };
    case constants.SET_SELECTED_TEAM_MEMBER: {
      return {
        ...state,
        selectedMemberId: action.payload.selectedMemberId,
        selectedAccountId: action.payload.selectedAccountId
      };
    }
    case constants.CLEAR_SELECTED_TEAM_MEMBER:
      return {
        ...state,
        selectedMemberId: null
      };
    case constants.INVITE_MEMBER.SUCCESS: {
      const newTeamMember = {
        account: action.payload.response,
        pending_email: true
      };
      return {
        ...state,
        allTeams: state.allTeams.map((team) =>
          team.id === state.selectedTeam.id
            ? {
                ...team,
                team_members: [...team.team_members, newTeamMember]
              }
            : team
        ),
        selectedTeam: {
          ...state.selectedTeam,
          team_members: [...state.selectedTeam.team_members, newTeamMember]
        }
      };
    }

    case constants.UPDATE_TEAM_ORDERED_ITEMS.TRIGGER: {
      const { team_id, ...items } = action.payload;
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          ...items
        }
      };
    }
    case constants.UPDATE_TEAM_VIEW_SETTINGS.TRIGGER: {
      const { viewSettings } = action.payload;
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          view_settings: {
            ...state.selectedTeam.view_settings,
            ...viewSettings
          }
        }
      };
    }
    case constants.UPDATE_TEAM_VIEW_SETTINGS.SUCCESS: {
      const { view_settings } = action.payload;
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          view_settings: {
            ...state.selectedTeam.view_settings,
            ...view_settings
          }
        }
      };
    }
    case updateTeamAuthSettingsActionCreatorsMap.success: {
      const { team_auth_settings } = action.payload.response;

      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          team_auth_settings: {
            ...state.selectedTeam.team_auth_settings,
            ...team_auth_settings
          }
        }
      };
    }
    case constants.UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION.SUCCESS: {
      const { requestPayload } = action.payload;
      const { accountId, isEmailNotificationOn } = requestPayload;

      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          team_members: state.selectedTeam.team_members.map((member) =>
            member.account.id === accountId
              ? {
                  ...member,
                  account: {
                    ...member.account,
                    email_notification_on: isEmailNotificationOn
                  }
                }
              : member
          )
        }
      };
    }
    case constants.UPDATE_TEAM_VIEW_SETTINGS.FAILURE: {
      const { originalValues } = action.payload.requestPayload;
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          view_settings: {
            ...state.selectedTeam.view_settings,
            ...originalValues
          }
        }
      };
    }
    case constants.CREATE_ACTIVITY.SUCCESS: {
      const { activities } = action.payload.response;
      const [activity] = Object.values(activities);
      const order = activity.billable
        ? 'billable_activity_order'
        : 'nonbillable_activity_order';
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          ...(!activity.is_custom && {
            [order]: [...state.selectedTeam[order], activity.id]
          })
        }
      };
    }
    case constants.UPDATE_ACTIVITY.SUCCESS: {
      const activity = action.payload.response;
      const { selectedTeam } = state;
      const { billable, id } = activity;
      const orderToInsertInName = billable
        ? 'billable_activity_order'
        : 'nonbillable_activity_order';
      const orderToRemoveFromName = !billable
        ? 'billable_activity_order'
        : 'nonbillable_activity_order';
      const orderToInsertIn = selectedTeam[orderToInsertInName];
      const orderToRemoveFrom = selectedTeam[orderToRemoveFromName];
      const isAlreadyInCorrectOrder = orderToInsertIn.includes(+id);
      if (isAlreadyInCorrectOrder) {
        return state;
      }
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          [orderToInsertInName]: [id, ...orderToInsertIn],
          [orderToRemoveFromName]: orderToRemoveFrom.filter(
            (activityId) => +activityId !== +id
          )
        }
      };
    }
    case budgetConstants.CREATE_RATE.SUCCESS: {
      const rate = action.payload.response;
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          rate_order: [rate.id, ...state.selectedTeam.rate_order]
        }
      };
    }
    case budgetConstants.CREATE_TEAM_ROLE.SUCCESS: {
      const teamRole = action.payload.response;
      return {
        ...state,
        selectedTeam: {
          ...state.selectedTeam,
          team_role_order: [
            teamRole.team_role_id,
            ...state.selectedTeam.team_role_order
          ]
        }
      };
    }

    default:
      return state;
  }
};

export default teams;
