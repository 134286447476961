import { useMemo, useEffect } from 'react';
import { generateColumns, buildFlatList, buildRow } from './utils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import useStickyHeader from '../useStickyHeader';

const useTableBuilder = ({
  listRef,
  tableColumns,
  columnWidths,
  customColumnWidths,
  rowTypes,
  rowToCells,
  mainList,
  customRowProps,
  breakOnLoadingRow = true,
  emptyRow = defaultEmptyRow,
  hasTooltips = true,
  stickySection,
  setStickySection
}) => {
  const columns = useMemo(
    () =>
      generateColumns({
        tableColumns,
        columnWidths,
        customColumnWidths,
        rowTypes,
        rowToCells
      }),
    [columnWidths, customColumnWidths, rowToCells, rowTypes, tableColumns]
  );

  const rows = useMemo(() => {
    return buildFlatList({
      mainList,
      customRowProps,
      emptyRow,
      breakOnLoadingRow
    }).list;
  }, [breakOnLoadingRow, customRowProps, emptyRow, mainList]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [listRef, rows]);

  useEffect(() => {
    if (hasTooltips) {
      rebuildTooltip();
    }
  }, [rows, hasTooltips]);

  const { stickyList, subStickyLists, handleScroll, headerItem, headerItem2 } =
    useStickyHeader({
      mainList,
      rows,
      customRowProps,
      stickySection,
      setStickySection,
      isDisabled: !setStickySection || stickySection === undefined // prevent unnecessary calculations for tables without sticky header
    });

  return {
    columns,
    rows,
    buildRow,
    stickyList,
    handleScroll,
    headerItem,
    subStickyLists,
    headerItem2
  };
};

export default useTableBuilder;

/* ------------------------------------ – ----------------------------------- */

const defaultEmptyRow = {
  rowType: 'emptyRow',
  rowHeight: 20,
  isDragDisabled: true
};
