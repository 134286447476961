import React from 'react';
import SvgIcon from 'components/SvgIcon';

const QuestionMarkInsideRectangleIcon = ({
  width = '28',
  height = '28',
  fill = '#FF9E48',
  className
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0Z"
      fill={fill}
    />
    <path
      d="M19.25 7.25H8.75C7.925 7.25 7.25 7.925 7.25 8.75V19.25C7.25 20.075 7.925 20.75 8.75 20.75H19.25C20.075 20.75 20.75 20.075 20.75 19.25V8.75C20.75 7.925 20.075 7.25 19.25 7.25ZM14.0075 18.5C13.4825 18.5 13.0625 18.08 13.0625 17.555C13.0625 17.0225 13.4825 16.6175 14.0075 16.6175C14.54 16.6175 14.945 17.0225 14.945 17.555C14.9375 18.0725 14.54 18.5 14.0075 18.5ZM16.265 12.95C15.695 13.7825 15.155 14.045 14.8625 14.5775C14.7425 14.795 14.6975 14.9375 14.6975 15.635H13.3325C13.3325 15.2675 13.2725 14.6675 13.565 14.15C13.9325 13.4975 14.63 13.1075 15.035 12.53C15.4625 11.9225 15.2225 10.7825 14.0075 10.7825C13.2125 10.7825 12.8225 11.3825 12.6575 11.8925L11.42 11.3675C11.7575 10.3625 12.665 9.5 13.9925 9.5C15.1025 9.5 15.86 10.0025 16.25 10.64C16.58 11.18 16.775 12.1925 16.265 12.95Z"
      fill="white"
    />
  </SvgIcon>
);

export default QuestionMarkInsideRectangleIcon;
