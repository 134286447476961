import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import {
  makeGetTeamMembershipByAccountId,
  getMe,
  makeGetProjectBasicInfoById,
  makeGetBoardByProjectBasicInfoId,
  makeGetProjectMemberships,
  getIsOnReportsView,
  makeGetSortedTeamMembersByPropsIdOrder,
  getMemberBudgetsByProjectIdWithUnassigned,
  getOwnProjectUnassignedCounts,
  getPhaseTotals,
  getOwnProjectUnassignedMemberBudgets
} from 'selectors';
import { fetchBoardMembers } from 'actionCreators';
import { makeGetMemberBudget } from 'BudgetModule/selectors';
import { getPositions } from 'BudgetModule/selectors/positions';
import {
  SelectContainer,
  StyledDownArrow,
  MemberName,
  ArchivedText
} from './styles';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import { InnerDropdownOption } from 'views/projectPlanner/styles';
import MembersDropdown from './MembersDropdown';

import { rebuildTooltip, defaultTooltipProps } from 'appUtils/tooltipUtils';
import QBDownArrow from 'icons/QBDownArrow';
import keyBy from 'lodash/keyBy';
import NewCloseIcon from 'icons/NewCloseIcon';
import { TaskAssigneeCircle } from 'components/TaskAssigneeDropdown/styles';
import { formatUnassignedRoleName } from 'appUtils/hooks/budget/useMemberBudgetName';
import { permissionsUtils } from 'PermissionsModule/utils';

const noop = () => {};
const makeMemberCollector = () => ({
  projectMembers: [],
  boardOnlyMembers: [],
  otherMembers: []
});
const emptyObj = {};
const emptyArray = [];

/*
renderers of MemberSelector that
  1. show unassigned member budgets
  2. pass in a custom renderMember function
are responsible for determining whether they are rendering a member or an unassigned member budget and behaving accordingly.
*/

class MemberSelector extends React.Component {
  state = {
    isOpen: false
  };

  componentDidMount() {
    rebuildTooltip();
    const { board, isListItem } = this.props;
    if (board && !isListItem) {
      this.loadBoardMembers();
    }
  }

  componentDidUpdate(prevProps) {
    const { board, isListItem, isEditing, projectId } = this.props;
    rebuildTooltip();
    if (prevProps.board !== board && !isListItem) {
      this.loadBoardMembers();
    }
    if (isEditing && !prevProps.isEditing) {
      // necessary for larger click area on tasks
      this.setState({ isOpen: true });
    }
  }

  loadBoardMembers = () => {
    const { board, fetchBoardMembers } = this.props;
    if (!board?.id) {
      return;
    }
    fetchBoardMembers(board.id);
  };

  wrapperOpenDropdown = () => {
    const { openDropdown } = this.props;
    if (openDropdown) {
      openDropdown();
    } else {
      this.openDropdown();
    }
  };

  openDropdown = () => {
    const { isListItem } = this.props;
    this.setState({ isOpen: true });
    if (isListItem) {
      this.loadBoardMembers();
    }
  };

  closeDropdown = () => {
    this.setState({ isOpen: false });
    this.props.handleClose?.();
  };

  handleCancel = () => {
    this.props.handleCancel?.();
    this.closeDropdown();
  };

  handleSelect = (item) => {
    const {
      handleSelect,
      isAssignMultiple,
      skipSelectProjectMembership = false
    } = this.props;
    if (item.unassigned && isAssignMultiple) {
      return;
    }

    if (item.position?.name && !item.account?.id) {
      handleSelect('member_budget_id', item.id);

      // use skipSelectProjectMembership for selectors that expect to get account_id when selecting member
    } else if (
      !skipSelectProjectMembership &&
      item.memberBudget?.project_membership?.id
    ) {
      handleSelect(
        'project_membership_id',
        item.memberBudget.project_membership.id
      );
    } else {
      handleSelect('account_id', item.account.id);
    }

    if (!isAssignMultiple) {
      this.closeDropdown();
    }
  };

  handleBatchSelect = (items, value) => {
    this.props.handleBatchSelect?.(items, value);
  };

  handleDone = () => {
    const { handleDone } = this.props;
    handleDone();
    this.closeDropdown();
  };

  renderMember = () => {
    const {
      selectedMember,
      selectedMemberBudget,
      me,
      nameStyle,
      selectionDisabled,
      disabledTooltip,
      customLabel = null,
      isOnWorkplanModal,
      innerDropdownClassName,
      projectUnassignedRoleCounts
    } = this.props;
    const shouldUseMemberBudget =
      selectedMemberBudget?.position?.name && !selectedMember;
    const idToUse = shouldUseMemberBudget
      ? selectedMemberBudget.id
      : selectedMember.account.id;
    const showPositionNumber =
      shouldUseMemberBudget &&
      selectedMemberBudget.position_number !== null &&
      projectUnassignedRoleCounts[selectedMemberBudget.position_id] > 1;
    const nameToDisplay = shouldUseMemberBudget
      ? `${selectedMemberBudget.position?.name}${
          showPositionNumber ? ` | ${selectedMemberBudget.position_number}` : ''
        }`
      : selectedMember.account.name;
    if (selectedMemberBudget && !selectedMember) {
    }
    return (
      <>
        <InnerDropdownOption
          className={innerDropdownClassName}
          onClick={selectionDisabled ? noop : this.openDropdown}
          isArchived={selectedMember?.is_archived}
          isDisabled={isOnWorkplanModal && selectionDisabled}
          data-for="app-tooltip"
          data-tip={selectionDisabled ? disabledTooltip : ''}
          data-html
          data-effect="solid"
          data-class="center"
        >
          {shouldUseMemberBudget ? (
            <TaskAssigneeCircle style={{ marginLeft: 0 }} />
          ) : (
            <MemberInitials
              member={selectedMember}
              classes={cn('regular-member-no-hover selected', {
                'logged-member-no-hover': idToUse === me.id
              })}
              idx={'idx-placeholder'}
              isOnTaskModal
            />
          )}

          <MemberName nameStyle={nameStyle} className="styled-member-name">
            {nameToDisplay}
          </MemberName>

          {!selectionDisabled && (
            <StyledDownArrow className="styled-down-arrow">
              <QBDownArrow />
            </StyledDownArrow>
          )}
          {!customLabel && selectedMember?.is_archived ? (
            <ArchivedText>Archived</ArchivedText>
          ) : null}
        </InnerDropdownOption>
        {customLabel}
      </>
    );
  };

  renderSelect = () => {
    const { isOpen } = this.state;
    const {
      customSelectLabel,
      selectionDisabled,
      disabledTooltip,
      shouldHideCloseIcon,
      showError
    } = this.props;

    return (
      <StyledSelectContainer
        isInvalid={showError}
        {...defaultTooltipProps}
        data-tip={disabledTooltip}
        data-tip-disable={!selectionDisabled}
        className={cn({ disabled: selectionDisabled })}
        onMouseDown={selectionDisabled ? noop : this.openDropdown}
      >
        {customSelectLabel || (
          <div>
            {`Assign Member`}
            <StyledDownArrow>
              <QBDownArrow />
            </StyledDownArrow>
          </div>
        )}
        <NewCloseIconContainer
          onClick={(e) => e.stopPropagation()}
          isHidden={shouldHideCloseIcon || !isOpen}
        >
          <NewCloseIcon />
        </NewCloseIconContainer>
      </StyledSelectContainer>
    );
  };

  getProjectMemberIds = () =>
    this.props.project?.member_account_ids
      ? keyBy(this.props.project?.member_account_ids)
      : [];

  getBoardMemberIds = () =>
    this.props.boardMembers?.length
      ? keyBy(this.props.boardMembers?.map((member) => member.account.id))
      : [];

  getMemberBudgetHashByAccountId = () => {
    const { memberBudgets, project } = this.props;

    return memberBudgets && project
      ? keyBy(
          memberBudgets[project.id],
          (memberBudget) => memberBudget.account_id
        )
      : {};
  };

  getSortedMembers = () => {
    const {
      project,
      board,
      boardMembers,
      members,
      projectMemberships,
      includeUnassigned = false,
      includedUnassignedMemberBudgets = false,
      memberBudgets,
      showBudgetInfo,
      phaseId,
      phaseTotals,
      projectUnassignedRoleCounts,
      positionHash,
      projectUnassignedMemberBudgets,
      includeUnassignedRoles = false
    } = this.props;
    const unassignedRow = {
      unassigned: true,
      account: { id: null }
    };
    if (project && board && !boardMembers?.length) {
      return [];
    }

    if (!project || !board || !boardMembers?.length) {
      if (includeUnassigned) {
        return [unassignedRow, ...members];
      }
      return members;
    }

    // Used to give each member their member budget. Can probably do this in a selector, will probably refactor.
    const memberBudgetHashByAccountId = this.getMemberBudgetHashByAccountId();
    const onProjectIds = this.getProjectMemberIds();
    const onBoardIds = this.getBoardMemberIds();
    const currentAccountTotals = phaseTotals?.[phaseId]?.account_totals;
    const currentAccountTotalsHash = currentAccountTotals
      ? keyBy(currentAccountTotals, 'account_id')
      : {};
    const { projectMembers, boardOnlyMembers, otherMembers } = members.reduce(
      (collector, member) => {
        const { projectMembers, boardOnlyMembers, otherMembers } = collector;
        const memberId = member?.account?.id;
        if (onProjectIds?.[memberId]) {
          const memberData = {
            ...member,
            memberBudget:
              memberBudgetHashByAccountId[member?.account?.id] || emptyObj,
            role_ids: projectMemberships[member?.account?.id]?.role_ids
          };
          if (showBudgetInfo) {
            const accountBudgetInfo = currentAccountTotalsHash[memberId];
            memberData.budgetTotals = {
              estimated_hours: accountBudgetInfo?.estimated?.hours || 0,
              spent_hours: accountBudgetInfo?.spent?.hours || 0,
              planned_hours: accountBudgetInfo?.planned?.hours || 0,
              remaining_hours:
                accountBudgetInfo?.estimated?.remaining?.hours || 0
            };
          }
          projectMembers.push(memberData);
        } else if (permissionsUtils.getIsProjectGuest(member)) {
          // skip project guests that are not already on the project
          return collector;
        } else if (onBoardIds?.[memberId]) {
          boardOnlyMembers.push(member);
        } else {
          otherMembers.push(member);
        }
        return collector;
      },
      makeMemberCollector()
    );

    const existingMemberBudgets = projectUnassignedMemberBudgets;
    const genericRolesOnProject = includeUnassignedRoles
      ? existingMemberBudgets
          .map((existingMemberBudget) => {
            const positionId = existingMemberBudget.position_id;
            const position = positionHash[positionId];
            const name = formatUnassignedRoleName({
              memberBudget: existingMemberBudget,
              position,
              unassignedRoleCounts: projectUnassignedRoleCounts
            });
            return {
              ...position,
              memberBudget: existingMemberBudget,
              name
            };
          })
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
      : emptyArray;

    // Use this for unassigned roles that are not on the project
    // const otherGenericRoles = positions
    //   .map(position => {
    //     // Show count on position if it already exists on the project
    //     const showPositionNumber =
    //       projectUnassignedRoleCounts[position.id] >= 1;
    //     const nameToDisplay = showPositionNumber
    //       ? `${position.name}${
    //           showPositionNumber
    //             ? ` (${projectUnassignedRoleCounts[position.id] + 1})`
    //             : ''
    //         }`
    //       : position.name;
    //     return {
    //       ...position,
    //       name: nameToDisplay
    //     };
    //   })
    //   .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    // Member budget has all the member budget information I need
    const formattedMemberBudgets = includedUnassignedMemberBudgets
      ? memberBudgets[project?.id]
          ?.filter(
            (memberBudget) =>
              memberBudget.account_id === null && memberBudget.position?.name // dont show empty-ish member budgets
          )
          .map((memberBudget) => {
            return {
              ...memberBudget
            };
          }) ?? []
      : [];
    const sortedMembers = [
      ...formattedMemberBudgets,
      ...projectMembers,
      ...genericRolesOnProject,
      ...boardOnlyMembers,
      ...otherMembers
    ];

    if (includeUnassigned) {
      sortedMembers.unshift(unassignedRow);
    }
    return sortedMembers;
  };

  render() {
    const { isOpen } = this.state;
    const {
      selectedMember,
      projectId,
      selectionDisabled,
      joinModalText,
      hideFooter = true,
      customCopy = {},
      onFooterClick,
      isAssignMultiple,
      selectedIds,
      handleBatchClear,
      primaryMemberText,
      noHeader,
      popoverClassName,
      selectedMemberBudget,
      isOnWorkplanModal,
      bar,
      initialBar,
      styles,
      itemHeight,
      hideSelectAll,
      hideClearAll,
      isUsingProjectMembershipId,
      listWidth
    } = this.props;
    const renderMember = this.props.renderMember || this.renderMember;
    const renderSelect = this.props.renderSelect || this.renderSelect;

    return (
      <>
        <div
          style={
            styles || {
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }
          }
          ref={(ref) => (this.target = ref)}
          onClick={this.wrapperOpenDropdown}
        >
          {selectedMember || selectedMemberBudget
            ? renderMember({ onClick: this.openDropdown })
            : renderSelect({ onClick: this.openDropdown })}
        </div>
        {isOpen && !selectionDisabled && (
          <MembersDropdown
            members={this.getSortedMembers()}
            target={this.target}
            handleClose={this.closeDropdown}
            handleCancel={this.handleCancel}
            handleSelect={this.handleSelect}
            projectId={projectId}
            projectMemberIds={this.getProjectMemberIds()}
            boardMemberIds={this.getBoardMemberIds()}
            joinModalText={joinModalText}
            isEntitySelected={!!projectId}
            hideFooter={hideFooter}
            customCopy={customCopy}
            onFooterClick={onFooterClick}
            isAssignMultiple={isAssignMultiple}
            primaryMemberText={primaryMemberText}
            selectedIds={selectedIds}
            handleDone={this.handleDone}
            handleBatchSelect={this.handleBatchSelect}
            handleBatchClear={handleBatchClear}
            noHeader={noHeader}
            popoverClassName={popoverClassName}
            isOnWorkplanModal={isOnWorkplanModal}
            bar={bar}
            listWidth={listWidth}
            initialBar={initialBar}
            itemHeight={itemHeight}
            hideSelectAll={hideSelectAll}
            hideClearAll={hideClearAll}
            isUsingProjectMembershipId={isUsingProjectMembershipId}
          />
        )}
      </>
    );
  }
}

const projectIdGetter = (state, ownProps) => ownProps.projectId;
const makeMapStateToProps = () => {
  const getTeamMembership = makeGetTeamMembershipByAccountId();
  const getProjectMemberships = makeGetProjectMemberships({ projectIdGetter });
  const getProjectInfo = makeGetProjectBasicInfoById();
  const getBoard = makeGetBoardByProjectBasicInfoId();
  const getSortedMembersByPropsIdOrder =
    makeGetSortedTeamMembersByPropsIdOrder();
  const getMemberBudget = makeGetMemberBudget();
  const mapStateToProps = (state, ownProps) => ({
    members:
      ownProps.members || getSortedMembersByPropsIdOrder(state, ownProps),
    projectMemberships: getProjectMemberships(state, ownProps),
    me: getMe(state),
    selectedMember: getTeamMembership(state, ownProps),
    selectedMemberBudget: getMemberBudget(state, ownProps),
    project: getProjectInfo(state, ownProps),
    board: getBoard(state, ownProps),
    boardMembers: state.groups.memberList,
    isOnReportsView: getIsOnReportsView(state),
    memberBudgets: getMemberBudgetsByProjectIdWithUnassigned(state),
    projectUnassignedRoleCounts: getOwnProjectUnassignedCounts(state, {
      projectId: ownProps.projectId
    }),
    phaseTotals: getPhaseTotals(state),
    projectUnassignedMemberBudgets: getOwnProjectUnassignedMemberBudgets(
      state,
      {
        projectId: ownProps.projectId
      }
    ),
    positionHash: getPositions(state)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps, {
  fetchBoardMembers
})(MemberSelector);

export const StyledSelectContainer = styled(SelectContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NewCloseIconContainer = styled.div`
  ${(props) => props.isHidden && 'opacity: 0;'}
  path {
    fill: ${theme.colors.colorSemiDarkGray5};
  }
`;
