import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  StyledProjectTitleContainer,
  StyledProjectTitle,
  StyledProjectDescription,
  StyledProjectNumber as ProjectNumber,
  StyledProjectInfo,
  StyledPhaseName,
  StyledPhaseNameContainer,
  StyledFolderIcon,
  StyledDot as Dot,
  StyledColorContainer,
  ArchivedText
} from 'views/timesheet/Timesheet/TimesheetProject/styles';
import { getProjectHash, getFlatPhasesHash, getGroupsHash } from 'selectors';
import { StyledTeamName } from 'views/timesheet/Timesheet/styles';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import ColorPicker from 'components/ColorPicker';
import { ScheduleBar } from 'models/userActivities';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

interface ActivitySideMenuProjectInfoProps {
  scheduleBar: ScheduleBar;
  userActivityId: number;
}

const ActivitySideMenuProjectInfo = ({
  scheduleBar,
  userActivityId
}: ActivitySideMenuProjectInfoProps) => {
  const boardHash = useSelector(getGroupsHash);
  const projectHash = useSelector(getProjectHash);
  const phaseHash = useSelector(getFlatPhasesHash);

  const project = projectHash[scheduleBar.project_id];
  if (!project) {
    return null;
  }
  const board = boardHash[project.board_id];
  const phase = phaseHash[scheduleBar.phase_id];

  return (
    <ActivityLogCardProjectInfo>
      <ProjectInfoTopRow>
        <StyledProjectNumber show={project.project_number}>
          <span
            data-for="app-tooltip"
            data-html
            data-class="project-info-tooltips"
            data-tip={project.project_number}
            style={{ maxWidth: '135px' }}
            className="no-text-overflow"
          >
            {project.project_number}
          </span>
        </StyledProjectNumber>
        {board && (
          <StyledTeamName>
            <StyledFolderIcon className={''} />
            <span
              data-for="app-tooltip"
              data-html
              data-class="project-info-tooltips"
              data-tip={board.name}
              style={{ maxWidth: '265px' }}
              className="no-text-overflow"
            >
              {board.name}
            </span>
          </StyledTeamName>
        )}
      </ProjectInfoTopRow>
      <div className="project-planner-group-title">
        <StyledProjectInfo
          data-for="app-tooltip"
          data-html="true"
          data-class="project-info-tooltips"
          data-tip={`${project.title} <br /> ${project.description || ''}`}
        >
          <StyledDot projectId={project.id}></StyledDot>
          <StyledColorContainer>
            <ColorPicker
              projectId={project.id}
              id={project.id}
              originType={ORIGIN_TYPE_STRINGS.PROJECT}
              boundariesElement="window"
              pickerLocation={userActivityId}
              row
              className="color-picker-container"
            />
          </StyledColorContainer>
          <StyledProjectTitleContainer
            data-multiline={true}
            id={project.id}
            style={{ width: 305 }}
          >
            <StyledProjectTitle>
              <span className="no-text-overflow">{project.title} </span>
              {project?.is_archived ? (
                <ArchivedText> | Archived</ArchivedText>
              ) : (
                ''
              )}
            </StyledProjectTitle>
            <StyledProjectDescription>
              {project?.description?.length ? ' - ' : null}
              {project.description}
            </StyledProjectDescription>
          </StyledProjectTitleContainer>
        </StyledProjectInfo>
      </div>
      <StyledPhaseNameContainer>
        {phase && !phase.is_like_default && phase?.is_budget && (
          <BudgetPhaseMilestoneIcon
            strokeWidth={1}
            className={''}
            width="12"
            height="14"
          />
        )}
        <StyledPhaseName style={{ width: 300 }}>
          {phase && !phase.is_like_default ? phase?.name : ''}
        </StyledPhaseName>
      </StyledPhaseNameContainer>
    </ActivityLogCardProjectInfo>
  );
};

// ************* Styling *********** //

export const ActivityLogCardProjectInfo = styled.div``;

const ProjectInfoTopRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDot = styled(Dot)<{ projectId: string }>``;

const StyledProjectNumber = styled(ProjectNumber)<{ show: boolean }>``;

export default ActivitySideMenuProjectInfo;
