import React from 'react';
import BudgetContainer from './BudgetContainer';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import { SectionRoutes } from 'appConstants/navigation';
import { FILTER_PAGES } from 'appConstants/workload';

const BudgetTab = ({
  isDashboardWidgetModal,
  widgetType,
  isProjectViewReport,
  widgetDetailModalThreeDotMenuItems
}) => {
  const {
    filterId,
    viewBy,
    pageName,
    activeFilter,
    widgetConfig,
    filterSections
  } = useReportsViewFilter({
    ...(isDashboardWidgetModal && {
      matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType/:reportViewType`,
      widgetType
    }),
    ...(isProjectViewReport && {
      matchUrl: `${SectionRoutes.PROJECT}`,
      pageName: FILTER_PAGES.PROJECT_DETAIL_BUDGET
    })
  });

  return (
    <BudgetContainer
      activeFilter={activeFilter}
      pageName={pageName}
      viewBy={viewBy}
      filterSections={isProjectViewReport ? [] : filterSections}
      isProjectViewReport={isProjectViewReport}
      isDashboardWidgetModal={isDashboardWidgetModal}
      widgetConfig={widgetConfig}
      widgetDetailModalThreeDotMenuItems={widgetDetailModalThreeDotMenuItems}
    />
  );
};

export default BudgetTab;
