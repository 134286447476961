import { MembershipTableDataProvider } from 'PermissionsModule/components/settingsViews/members/DataProvider';
import { MembersTimesheetSettingsTable } from './MembersTimesheetSettingsTable';

const MembersTimesheetSettingsTableContainer = () => (
  <MembershipTableDataProvider>
    <MembersTimesheetSettingsTable />
  </MembershipTableDataProvider>
);

export { MembersTimesheetSettingsTableContainer as MembersTimesheetSettingsTable };
