import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import { searchItemsWithWhiteSpace } from 'appUtils/search';
import {
  getPlannerModalAccountId,
  getPlannerProjectsByMember,
  getPlannerModalProjectsHash,
  getMyProjectIdsIndexes,
  getAuth,
  getMatchedRouteParams,
  getOnProfile
} from 'selectors';
import partition from 'lodash/partition';
import { addMemberToProject } from 'actionCreators';
import Popover from 'components/Popover';
import JoinProjectModal from './JoinProjectModal';

class ProjectSelector extends React.Component {
  state = {
    searchText: '',
    joinModalOpen: false,
    selectedProject: null
  };

  componentDidMount = () => {
    const addingProjectInput = document.querySelector(
      `.adding-sidebar-row input`
    );
    addingProjectInput.focus();
  };

  onInput = (e) => {
    this.setState({
      searchText: e.target.value
    });
  };

  getFilteredItems = () => {
    const {
      availableProjects,
      projectActivityScores,
      projectsHash,
      timesheetDateKeys,
      myProjectsIndexes
    } = this.props;
    if (!availableProjects) {
      return [];
    }

    const filteredAvailableProjects = availableProjects.filter(
      (project) => !projectsHash[project.id] && !project.is_archived
    );

    const [memberProjects, nonMemberProjects] = partition(
      filteredAvailableProjects,
      (project) => project.user_is_member
    );

    const results = searchItemsWithWhiteSpace({
      searchText: this.state.searchText,
      itemList: [...memberProjects, ...nonMemberProjects],
      filterKeysArray: ['title', 'description']
    });
    return results
      .map((result) => ({
        ...result,
        activityScoreTotal: timesheetDateKeys.reduce(
          (total, dateKey) =>
            total + ((projectActivityScores[result.id] || {})[dateKey] || 0),
          0
        )
      }))
      .sort((a, b) => {
        // higher is first
        const totalDiff = b.activityScoreTotal - a.activityScoreTotal;
        // lower is first
        const indexDiff =
          (myProjectsIndexes[a.id] || 0) - (myProjectsIndexes[b.id] || 0);
        if (totalDiff) {
          return totalDiff;
        } else {
          return indexDiff;
        }
      });
  };

  joinProject = () => {
    const { addMemberToProject, selectedAccountId, setNewProjectId } =
      this.props;
    const { selectedProject } = this.state;
    const basicMemberRole = 3;
    addMemberToProject(
      selectedProject.id,
      selectedAccountId,
      basicMemberRole,
      selectedProject.board_id
    );
    this.clearJoinProject();
    setNewProjectId(selectedProject.id);
  };

  setJoinProject = (project) => {
    this.setState({
      selectedProject: project,
      joinModalOpen: true
    });
  };

  clearJoinProject = () =>
    this.setState({ selectedProject: null, joinModalOpen: false });

  handleProjectClick = (project) => (e) => {
    e.stopPropagation();
    if (project.user_is_member) {
      this.props.setNewProjectId(project.id);
    } else {
      this.setJoinProject(project);
    }
  };

  renderFlyout = () => {
    return (
      <div className="project-flyout-menu">
        <div className="project-flyout-contents">
          <ul>
            {this.getFilteredItems().map((project) => (
              <li
                style={{ display: 'flex', justifyContent: 'space-between' }}
                key={project.id}
                onClick={this.handleProjectClick(project)}
              >
                <div
                  style={{
                    width: '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  <span className="project-title">{project.title}</span>
                  {project.description && (
                    <span className="project-description">
                      {` - ${project.description}`}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  closeDropdown = () => {
    const { joinModalOpen } = this.state;
    const { closeDropdown } = this.props;
    if (!joinModalOpen) {
      closeDropdown();
    }
  };

  render() {
    const { isOnMemberProfile, target, me, selectedAccountId } = this.props;
    const { joinModalOpen, selectedProject } = this.state;
    return (
      <Popover
        isOpen={true}
        closePopover={this.closeDropdown}
        target={target}
        boundariesElement="window"
        placement="bottom-start"
        className="adding-project-row-container"
      >
        <div
          className={cn('adding-project-row', {
            'member-profile': isOnMemberProfile
          })}
        >
          <div className={'adding-sidebar-row'}>
            <form onSubmit={(event) => event.preventDefault()}>
              <input
                className="search-bar-input"
                onChange={this.onInput}
                type="text"
                placeholder="Search Projects or Select Below"
              />
              <i className="close-icon" onClick={this.closeDropdown} />
            </form>
          </div>
          <div className="adding-timeline-placeholder" />
          {this.renderFlyout()}
          <JoinProjectModal
            open={joinModalOpen}
            toggle={this.clearJoinProject}
            selectedProject={selectedProject}
            onConfirm={this.joinProject}
            currentUserId={me.id}
            accountId={selectedAccountId}
          />
        </div>
      </Popover>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: getAuth(state),
  me: getAuth(state),
  selectedAccountId: getPlannerModalAccountId(state),
  projectsHash: getPlannerModalProjectsHash(state),
  availableProjects: getPlannerProjectsByMember(state, ownProps),
  myProjectsIndexes: getMyProjectIdsIndexes(state),
  matchedParams: getMatchedRouteParams(state),
  isOnMemberProfile: getOnProfile(state)
});

export default withRouter(
  connect(mapStateToProps, { addMemberToProject })(ProjectSelector)
);
