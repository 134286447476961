import { memo } from 'react';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { isExistenceCheckOptionHash } from 'FilterModule/filterSchemas/utils';
import {
  FilterField,
  FilterListType,
  filterListTypeToField
} from 'FilterModule/constants';
import { AddableFilters, AddableFiltersProps } from './AddableFilters';
import difference from 'lodash/difference';
import { ConfigOptionHash } from 'FilterModule/types';
import pick from 'lodash/pick';

export const AddableFiltersContainer = memo(
  ({
    dropdownHeaderOverride,
    toggleLabelOverride
  }: Pick<
    AddableFiltersProps,
    'dropdownHeaderOverride' | 'toggleLabelOverride'
  >) => {
    const { currentFilter, currentFilterSchema } = useFilterContext();

    const filterOrderFieldSchemaOptionsConfig =
      currentFilterSchema.fields[FilterField.filterOrder]?.optionsConfig;

    const filterOptionsArray =
      filterOrderFieldSchemaOptionsConfig?.optionsArray as FilterListType[];

    const filterOptionHash = filterOrderFieldSchemaOptionsConfig?.optionHash;

    const filterOptionHashToUse =
      !filterOptionHash || isExistenceCheckOptionHash(filterOptionHash)
        ? undefined
        : (filterOptionHash as ConfigOptionHash<
            FilterListType,
            { fieldOverride?: string }
          >);

    /**
     * Updates currentFilter.filterOrder with the given list. Will also
     * reset any removed filterListTypes' fields to their default values
     */
    const updateFilterOrder = (nextOrder: FilterListType[]) => {
      if (currentFilter.filterOrder) {
        const removedFilterListTypes = difference(
          currentFilter.filterOrder,
          nextOrder
        );

        const fieldsToClear = removedFilterListTypes.flatMap(
          (listType) =>
            filterOptionHashToUse?.[listType]?.fieldOverride ||
            filterListTypeToField[listType]
        );

        currentFilter.update({
          ...(fieldsToClear.length > 0 &&
            pick(currentFilterSchema.initialValues, fieldsToClear)),
          filterOrder: nextOrder
        });
      }
    };

    const handleRemoveFilter = (filterListType: FilterListType) => {
      if (currentFilter.filterOrder) {
        const nextOrder = currentFilter.filterOrder?.filter(
          (filter) => filter !== filterListType
        );

        updateFilterOrder(nextOrder);
      }
    };

    if (
      !filterOptionsArray ||
      !filterOptionHash ||
      !currentFilter.filterOrder
    ) {
      return null;
    }

    return (
      <AddableFilters
        dropdownHeaderOverride={dropdownHeaderOverride}
        filterOrder={currentFilter.filterOrder}
        optionsArray={filterOptionsArray}
        optionHash={filterOptionHash}
        toggleLabelOverride={toggleLabelOverride}
        updateFilterOrder={updateFilterOrder}
        handleRemoveFilter={handleRemoveFilter}
      />
    );
  }
);

AddableFiltersContainer.displayName = 'AddableFiltersContainer';
