import React from 'react';
import { connect } from 'react-redux';
import {
  updateTaskStatus,
  deleteTaskStatus,
  createTaskStatus
} from 'actionCreators';
import { getMatchedRouteParams } from 'selectors';
import { withRouter } from 'react-router-dom';
import OptionsSelectFlyoutListItem from 'components/OptionsSelectFlyout/OptionsSelectFlyoutListItem';
import {
  StyledTaskStatusContainer,
  StyledTaskStatusDot,
  StyledEmptyTaskStatusDot,
  StyledTaskStatusText
} from './styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const staticNewStatus = { title: 'New Status', color: '#cccccc', id: 'new' };
const unselectStatus = {
  title: 'No Status',
  color: '#F3F3F3',
  id: null
};

const taskStatusCopy = {
  inputPlaceholder: 'New Status',
  addHeader: 'Add Status',
  editHeader: 'Edit Status'
};

class TaskStatusListItem extends React.Component {
  componentDidMount() {
    rebuildTooltip();
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  handleSave = (updatedState) => {
    const {
      createTaskStatus,
      updateTaskStatus,
      handleEditClear,
      listItem,
      isNew,
      matchedParams: { projectId }
    } = this.props;
    const payload = {
      ...listItem,
      project_id: projectId,
      ...updatedState
    };
    const apiCall = isNew ? createTaskStatus : updateTaskStatus;
    apiCall(payload);
    handleEditClear();
  };

  renderListItem = (item) => (
    <StyledTaskStatusContainer
      data-for="app-tooltip"
      data-class="center mw-250 break-word-tooltips"
      data-tip={item.title}
      data-html
    >
      {item === unselectStatus ? (
        <StyledEmptyTaskStatusDot />
      ) : (
        <StyledTaskStatusDot color={item.color}></StyledTaskStatusDot>
      )}

      <StyledTaskStatusText className="no-text-overflow">
        {item.title}
      </StyledTaskStatusText>
    </StyledTaskStatusContainer>
  );

  render() {
    const { deleteTaskStatus, isUnselect } = this.props;
    return (
      <OptionsSelectFlyoutListItem
        handleSave={this.handleSave}
        handleDelete={deleteTaskStatus}
        colorPickerOriginType={ORIGIN_TYPE_STRINGS.TASK_STATUS}
        staticNewListItem={isUnselect ? unselectStatus : staticNewStatus}
        copy={taskStatusCopy}
        {...this.props}
        renderListItem={this.renderListItem}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateTaskStatus,
  deleteTaskStatus,
  createTaskStatus
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskStatusListItem)
);
