import SvgIcon from 'components/SvgIcon';

const DoubleArrow = ({
  width = '32px',
  height = '32px',
  fill = '#0074D9',
  className = ''
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 32 32"
    className={className}
  >
    <path
      d="M21.6621 16.0007L15.4888 10L14.8081 10.6616L20.3008 16.0005L14.8071 21.3384L15.4878 22L21.6621 16.0007ZM16.5171 16.0007L10.3439 10L9.66308 10.6616L15.1558 16.0005L9.66211 21.3384L10.3429 22L16.5171 16.0007Z"
      fill={fill}
    />
  </SvgIcon>
);

export default DoubleArrow;
