import React from 'react';
import TaskAssignedContent from 'components/TaskAssigneeDropdown/TaskAssignedByContent';

class TaskAssignedCell extends React.Component {
  handleAssigneeSelection = () => {};

  render() {
    const {
      task,
      handleMultiAssignDone,
      isEditing,
      projectDetailView,
      openTaskModal
    } = this.props;
    return (
      <div style={{ width: '100%', zIndex: 1 }}>
        {' '}
        <TaskAssignedContent
          task={task}
          projectId={task?.project_id}
          onSelect={this.handleAssigneeSelection}
          onMultiAssignDone={handleMultiAssignDone}
          isEditing={isEditing}
          projectDetailView={projectDetailView}
          openTaskModal={openTaskModal}
        />
      </div>
    );
  }
}

export default TaskAssignedCell;
