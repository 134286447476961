import { put, select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../service/api';
import { getAuthToken, getPhasesOrder } from 'selectors';
import * as actionCreators from 'actionCreators';
const { fetchAccess, updateAccess } = entityActions;

export function* fetchAccessWorker(action) {
  const token = yield select(getAuthToken);
  const { actableType, actableId, actionType } = action.payload;

  yield fetchEntity(
    fetchAccess,
    api.fetchAccess,
    undefined,
    [token, { actableType, actableId, actionType }],
    action
  );
}

export function* updateAccessWorker(action) {
  const token = yield select(getAuthToken);
  const {
    actableType,
    actableId,
    addableRoleIds,
    removableRoleIds,
    actionType,
    accountIds
  } = action.payload;

  yield changeEntity(updateAccess, api.updateAccess, [
    token,
    { actableType, actableId, addableRoleIds, removableRoleIds, accountIds }
  ]);
  yield put(actionCreators.fetchAccess({ actableType, actableId, actionType }));
}
