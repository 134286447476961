import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import SortOptions from './SortOptions';

import { setTaskViewBy } from 'actionCreators';
import { VIEW_BY, VIEW_BY_DISPLAY } from 'appConstants/workload';
import TaskListIcon from 'icons/TaskListIcon';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import styled from 'styled-components';
import theme from 'theme';

export const SubText = styled.span`
  font-size: 11px;
  color: ${theme.colors.colorCalendarGray};
  margin-left: 4px;
`;

const { TASK_GROUPS, PHASES, TASK_PRIORITIES } = VIEW_BY;

const StyledTaskListIcon = styled(TaskListIcon)``;
const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)``;
const StyledDisplay = styled.span`
  display: flex;
  align-items: center;
  ${StyledTaskListIcon}, ${StyledPhaseMilestoneIcon} {
    margin-right: 4px;
  }
`;

const taskGroupDisplay = {
  ...VIEW_BY_DISPLAY[TASK_GROUPS],
  label: (
    <StyledDisplay>
      <StyledTaskListIcon color={theme.colors.colorRoyalBlue} />
      {VIEW_BY_DISPLAY[TASK_GROUPS].label}
    </StyledDisplay>
  )
};

const phaseDisplay = {
  ...VIEW_BY_DISPLAY[PHASES],
  label: (
    <StyledDisplay>
      <StyledPhaseMilestoneIcon
        strokeColor={theme.colors.colorRoyalBlue}
        fillColor="transparent"
      />
      {VIEW_BY_DISPLAY[PHASES].label}
    </StyledDisplay>
  )
};
const taskGroupOption = {
  ...VIEW_BY_DISPLAY[TASK_GROUPS],
  label: (
    <StyledDisplay>
      <StyledTaskListIcon color={theme.colors.colorSemiDarkGray1} />
      {VIEW_BY_DISPLAY[TASK_GROUPS].label}
      <SubText>(Default)</SubText>
    </StyledDisplay>
  )
};
const phaseOption = {
  ...VIEW_BY_DISPLAY[PHASES],
  label: (
    <StyledDisplay>
      <StyledPhaseMilestoneIcon
        fillColor="transparent"
        strokeColor={theme.colors.colorSemiDarkGray1}
      />
      {VIEW_BY_DISPLAY[PHASES].label}
    </StyledDisplay>
  )
};
const display = {
  [VIEW_BY.TASK_GROUPS]: taskGroupDisplay,
  [VIEW_BY.PHASES]: phaseDisplay
};
const options = [
  taskGroupOption,
  phaseOption
  // VIEW_BY_DISPLAY[TASK_PRIORITIES]
];

const TaskListSortOptions = () => {
  const viewBy = useSelector((state) => state.homeTasks.viewBy);
  const dispatch = useDispatch();
  const setViewBy = ({ viewBy: taskViewBy }) => {
    dispatch(setTaskViewBy({ viewBy: taskViewBy }));
  };

  return (
    <SortOptions
      viewBy={viewBy}
      setViewBy={setViewBy}
      options={options}
      display={display}
      dropdownButtonStyle="padding: 0; margin-left: 20px"
      buttonClassName={'task-dropdown-title blue'}
      left={0} // force rightAlign
      unhideArrowOnHover
    />
  );
};

export default TaskListSortOptions;
