import { useRef, useCallback, useMemo, useEffect } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getCustomFieldsModule } from 'CustomFieldsModule/package/customFieldsModule';
import { useAppDispatch } from 'reduxInfra/hooks';
import DevProps from 'components/dev/DevProps';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

import { VariableSizeList } from 'react-window';
import { TableV2, TableProps } from 'components/Table/TableV2';
import { BaseTableList } from 'components/Table/types';
import { ContainerDiv, StyledFilterListsTable, ClearAllButton } from './styles';
import useNestedCollapse, {
  defaultParentCollapseState
} from 'appUtils/hooks/useNestedCollapse/useNestedCollapse';
import { useFilterListTypeGroupedListsBuilder } from './helpers';
import { getTableConfig } from './tableConfigs';
import { FilterListType } from 'FilterModule/constants';
import type { FilterListTypeHook } from 'FilterModule/types';
import {
  FilterListsTableVariant,
  FilterListsTableCustomRowProps
} from './types';
import { useLoadProjects } from 'ProjectsModule/core/hooks';
import { usePrevious } from 'react-use';

export interface FilterListsTableProps {
  isLoading: boolean;
  filters: FilterListType | FilterListType[];
  filterValuesHash: Partial<
    Record<FilterListType, ReturnType<FilterListTypeHook>>
  >;
  handleClearAllFilters?: () => void;
  numFiltersUsed?: number;
  variant: FilterListsTableVariant;
  /**
   * Provide when the filters here are associated some main result filter
   * eg. StackedFilters that are crossFieldDependencies of a Member filter
   */
  resultsFilterListType?: FilterListType;
  shouldResetCollapseState?: boolean;
  isStickyHeaderHidden?: boolean;
}

export const FilterListsTable = ({
  isLoading,
  filters,
  filterValuesHash,
  handleClearAllFilters,
  numFiltersUsed,
  variant,
  resultsFilterListType,
  shouldResetCollapseState,
  isStickyHeaderHidden = false
}: FilterListsTableProps) => {
  const dispatch = useAppDispatch();
  const listRef = useRef<VariableSizeList>(null);

  const tableConfig = useMemo(() => {
    return getTableConfig({ variant });
  }, [variant]);

  /* ------------------------------ Interactions ------------------------------ */

  const collapseCallback = useCallback(() => {
    if (listRef.current?.resetAfterIndex) {
      listRef.current?.resetAfterIndex(0);
    }
  }, []);

  const {
    setParentCollapseState,
    getIsOpen,
    getCurrentParentCollapseState,
    toggleCollapse,
    toggleCollapseAll
  } = useNestedCollapse({
    ...(!tableConfig.isFilterListsExpandedByDefault && {
      initialParentCollapseStates: {
        root: { ...defaultParentCollapseState, mode: 'OPENING' }
      }
    }),
    toggleCallback: collapseCallback
  });

  const prevShouldResetCollapseState = usePrevious(shouldResetCollapseState);

  useEffect(() => {
    if (
      shouldResetCollapseState &&
      prevShouldResetCollapseState !== shouldResetCollapseState
    ) {
      toggleCollapseAll(undefined, 'collapse');
    }
  }, [
    shouldResetCollapseState,
    toggleCollapseAll,
    prevShouldResetCollapseState
  ]);

  /* --------------------------------- Loading -------------------------------- */

  const { loadProjects } = useLoadProjects();

  /* ----------------------------- Table building ----------------------------- */

  const customRowProps = useMemo<FilterListsTableCustomRowProps>(
    () => ({
      tableConfig,
      resultsFilterListType,
      loadProjects
    }),
    [tableConfig, resultsFilterListType, loadProjects]
  );

  const filterListTypeGroupedListsBuilder =
    useFilterListTypeGroupedListsBuilder({
      filters,
      getIsOpen,
      toggleCollapse,
      toggleCollapseAll,
      setParentCollapseState,
      getCurrentParentCollapseState,
      filterValuesHash,
      skipHeaders: tableConfig.filterListTypeGroupedListsBuilder?.skipHeaders,
      getFilterListTypeSelectorKey: tableConfig.getFilterListTypeSelectorKey
    });

  const mainList: BaseTableList = useMemo(() => {
    // map order to listsbuilders
    // const listItems = data.stackedFilterOrder.map(filterListType => listsBuildersByFilterListType[filterListType])
    // stick with current way. have an {} with all the data
    const listItems =
      filterListTypeGroupedListsBuilder({
        order: Array.isArray(filters) ? filters : [filters],
        parentGroupId: 'main'
      }) || [];

    const list: BaseTableList = {
      listItems,
      isList: true,
      id: 'main',
      isOpen: true,
      isFullyLoaded: true,
      skipHeader: true
    };
    return list;
  }, [filterListTypeGroupedListsBuilder, filters]);

  const loadMoreItems = useCallback((row) => {}, []);

  /* --------------------------------- Render --------------------------------- */

  const dynamicModules = useMemo(() => {
    const filterListTypes = Array.isArray(filters) ? filters : [filters];
    const modules: any[] = [];
    filterListTypes.forEach((filterListType) => {
      if (filterListTypeToModuleLoader[filterListType]) {
        modules.push(filterListTypeToModuleLoader[filterListType]());
      }
    });
    return modules;
  }, [filters]);

  const tableProps: TableProps = {
    mainList,
    listRef,
    customRowProps,
    loadMoreItems,
    shouldHideTooltipOnRowChanges: true,
    shouldUseIsScrolling: true,
    isStickyHeaderHidden,
    ...tableConfig.tableProps
  };

  return (
    // Needed for roles
    <DynamicModuleLoader modules={dynamicModules}>
      <ContainerDiv
        className="filter-list-table-container"
        tableVariant={variant}
      >
        {isLoading ? (
          <SkeletonLoader
            numLoaders={5}
            loaderStyle={{ height: 60, rx: 4 }}
            style={{ paddingTop: 20 }}
          />
        ) : (
          <StyledFilterListsTable tableVariant={variant}>
            {handleClearAllFilters && numFiltersUsed !== undefined && (
              <ClearAllButton
                onClick={numFiltersUsed > 0 ? handleClearAllFilters : undefined}
              >
                {numFiltersUsed > 0 &&
                  `Clear ${
                    numFiltersUsed === 999 ? 'All' : numFiltersUsed
                  } Filter${numFiltersUsed > 1 ? 's' : ''}`}
              </ClearAllButton>
            )}

            <DevProps {...{ mainList }} />
            <TableV2 {...tableProps} />
          </StyledFilterListsTable>
        )}
      </ContainerDiv>
    </DynamicModuleLoader>
  );
};

/* ------------------------------------ - ----------------------------------- */

const filterListTypeToModuleLoader = {
  [FilterListType.Offices]: getSettingsModule,
  [FilterListType.MembersByOffice]: getBudgetModule,
  [FilterListType.Regions]: getSettingsModule,
  [FilterListType.MembersByRegion]: getSettingsModule,
  [FilterListType.Positions]: getBudgetModule,
  [FilterListType.MembersByPosition]: getBudgetModule,
  [FilterListType.Disciplines]: getSettingsModule,
  [FilterListType.MembersByDiscipline]: getSettingsModule,
  [FilterListType.ProfitCenter]: getCustomFieldsModule
};
