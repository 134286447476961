import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { getOrderedCurrentTeamRates } from 'BudgetModule/selectors';
import {
  createMemberBudget,
  openRemoveMemberModal
} from 'BudgetModule/actionCreators';

import styled from 'styled-components';
import theme from 'theme';
import { TextButton } from 'components/styles';

import ReactTooltip from 'react-tooltip';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

import { filterItemWithWhiteSpace } from 'appUtils/search';

const formatRate = (rate) =>
  '$' + `${(+rate || 0).toFixed(2).replace('.00', '')}` + '/h';

const AddRow = styled.div`
  padding-left: 15px;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 14px;
`;

const ItemDetails = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const StyledRate = styled.div`
  flex: 0 0 50px;
  margin-right: 10px;
`;

const StyledItemName = styled.div`
  color: ${(props) =>
    !props.isArchived
      ? theme.colors.colorSemiDarkGray2
      : theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
`;

const ItemRow = styled.div`
  padding-left: 15px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  .remove-text {
    color: ${theme.colors.colorDeleteRed};
    display: ${({ isAdded }) => (isAdded ? 'none' : 'flex')};
  }
  .added-text {
    color: ${theme.colors.colorCalendarBlue};
    display: ${({ isAdded }) => (isAdded ? 'flex' : 'none')};
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    .added-text {
      display: ${({ isAdded }) => (isAdded ? 'none' : 'flex')};
    }
    .remove-text {
      display: ${({ isAdded }) => (isAdded ? 'flex' : 'none')};
    }
  }
`;

const Footer = (
  <LockWithTooltip
    text="Manage Standard Bill Rate/Role"
    tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Standard Roles."
  />
);

const copy = {
  headerInitial: 'Add Role',
  headerEdit: '',
  headerAdd: '',
  sticky: '',
  footerInitial: Footer,
  footerEdit: Footer,
  addConfirm: '',
  editConfirm: '',
  searchPlaceholder: 'Type name or select below'
};

/* ------------------------------------ - ----------------------------------- */

// NOT USED

const AddRolesDropdown = ({
  // eg. { [positionId]: { id: positionId, memberBudget: {...}, name: '...', phaseMembershipId, activityPhaseMembershipId } }
  currentRoles = {},
  teamRates,
  onClose,
  targetRef,
  createMemberBudget,
  phase,
  activity
}) => {
  // when batch actions exist
  // const [addedRoles, setAddedRoles] = useState(new Set(currentRoles));
  // const [removedRoles, setRemovedRoles] = useState(new Set());

  const listItems = useMemo(() => {
    const addedRolesList = [];
    const remainingRolesList = [];

    teamRates.forEach((rate) => {
      if (currentRoles[rate.position_id]) {
        addedRolesList.push(rate);
      } else {
        remainingRolesList.push(rate);
      }
    });

    const addCustomRow = {
      isAddRow: true
    };

    return [addCustomRow, ...remainingRolesList, ...addedRolesList];
  }, [currentRoles, teamRates]);

  /* ------------------------------ interactions ------------------------------ */

  // const addRole = id => {
  //   setAddedRoles(currAddedRoles => new Set([...currAddedRoles, id]));
  //   if (removedRoles.has(id)) {
  //     // delete from remove list
  //     setRemovedRoles(
  //       currRemovedRoles =>
  //         new Set([...currRemovedRoles].filter(roleId => roleId !== id))
  //     );
  //   }
  // };
  // const removeRole = id => {
  //   setAddedRoles(
  //     currAddedRoles =>
  //       new Set([...currAddedRoles].filter(roleId => roleId !== id))
  //   );
  //   if (currentRoles.includes(id)) {
  //     // add to remove list
  //     setRemovedRoles(currRemovedRoles => new Set([...currRemovedRoles, id]));
  //   }
  // };

  const itemFilter = (item, searchWords) =>
    item.isSectionHeader ||
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });

  const handleSelect = (e, { item }) => {
    e.preventDefault();
    const { position_id, isAddRow } = item;
    if (!position_id || !phase) {
      return;
    }
    const currentMembership = currentRoles[position_id];
    if (currentMembership) {
      // not currently used due to ability to add multiple of same role to phase at the moment
      openRemoveMemberModal({
        memberBudget: {
          ...currentMembership.memberBudget,
          phaseId: phase.id,
          activity,
          phaseMembershipId: currentMembership.phaseMembershipId,
          activityMembershipId: currentMembership.activityPhaseMembershipId,
          memberBudgetId: currentMembership.memberBudget.id,
          isUnassigned: true
        }
      });
    } else {
      createMemberBudget({
        projectId: phase.project_id,
        memberBudgets: [
          {
            phase_id: phase.id,
            rate_id: item.id
          }
        ]
      });
    }
    // until batch actions exist - the dropdown will close anyway due to
    // parent list being re created on delete/create member budget so close here
    // to prevent dropdown looking unresponsive during delay
    handleClose();
    // if (addedRoles.has(id)) {
    //   removeRole(id);
    // } else {
    //   addRole(id);
    // }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const onFooterClick = () => {
    // TODO
    ReactTooltip.hide();
    handleClose();
  };

  /* --------------------------------- render --------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;
    const isAdded = currentRoles[item.position_id];
    const isArchived = item.archived;

    const renderDetails = () => (
      <ItemDetails>
        <StyledRate>{formatRate(item.rate)}</StyledRate>
        <StyledItemName isArchived={isArchived}>
          {item.description}
        </StyledItemName>
      </ItemDetails>
    );

    if (item.isAddRow) {
      return <AddRow key="add-row">Add Custom Rate/Role</AddRow>;
    }

    if (isAdded) {
      return (
        <ItemRow isAdded key={item.id}>
          {renderDetails()}
          <div>
            <div className="added-text">Added</div>
            <div className="remove-text">Remove</div>
          </div>
        </ItemRow>
      );
    }
    return (
      <ItemRow key={item.id}>
        {renderDetails()}
        <div className="added-text">Add</div>
      </ItemRow>
    );
  };

  const renderHeaderButton = () => {
    return (
      <TextButton
        color="white"
        padding="4px 8px"
        backgroundColor={theme.colors.colorRoyalBlue}
        onClick={handleClose}
      >
        Done
      </TextButton>
    );
  };

  return (
    <>
      <MultiStepFlyout
        copy={copy}
        target={targetRef}
        items={listItems}
        idKey="id"
        renderHeaderButton={renderHeaderButton}
        renderItem={renderItem}
        handleSelect={handleSelect}
        isWhite
        itemFilter={itemFilter}
        handleClose={handleClose}
        onFooterClick={onFooterClick}
        popoverClassName="add-positions-dropdown"
        listWidth={350}
        listHeight={222}
        itemHeight={48}
        searchEnabled
        isItemUnSelectable={(item) => item.isSectionHeader}
        canMultiSelect
      />
    </>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamRates: getOrderedCurrentTeamRates(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  createMemberBudget,
  openRemoveMemberModal
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AddRolesDropdown);
