import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { StyledLockIcon } from 'BudgetModule/components/styles';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const StyledLockWithTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLock = styled(StyledLockIcon)<{ $isDisabled: boolean }>`
  bottom: 2px;
  position: relative;
  path {
    fill: ${({ $isDisabled }) =>
      $isDisabled ? theme.colors.colorLightGray16 : ''};
  }
`;
const StyledText = styled.span<{ $isDisabled: boolean }>`
  color: ${({ $isDisabled }) =>
    $isDisabled ? theme.colors.colorLightGray16 : ''};
`;

interface LockWithTooltipProps {
  text?: string;
  tooltipContent?: string;
  className?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
}

const LockWithTooltip = ({
  text = '',
  tooltipContent = '',
  className = 'lock-text',
  style,
  isDisabled = false
}: LockWithTooltipProps) => {
  useEffect(() => {
    if (tooltipContent) {
      rebuildTooltip();
    }
  }, [tooltipContent]);

  return (
    <StyledLockWithTooltip
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-class="center"
      data-tip={tooltipContent}
      className={className}
      style={style}
    >
      <StyledLock $isDisabled={isDisabled} />
      {text && (
        <StyledText $isDisabled={isDisabled} className="lock-text-label">
          {text}
        </StyledText>
      )}
    </StyledLockWithTooltip>
  );
};

export default LockWithTooltip;
