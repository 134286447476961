import { fork, takeEvery } from 'redux-saga/effects';

import * as constants from '../constants';
import * as workers from './entityOptions';

export const entityOptionsSagas = [
  fork(
    takeEvery,
    constants.FETCH_ENTITY_OPTIONS.TRIGGER,
    workers.fetchEntityOptionsWorker
  )
];

export default entityOptionsSagas;
