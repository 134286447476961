import styled from 'styled-components';
import bellGray from 'images/bell-outline.svg';
import bellOutlineBlue from 'images/bell-outline-blue.svg';
import bellBlue from 'images/bell-blue.svg';
import theme from 'theme';

const {
  colors: { colorRoyalBlue, colorMediumGray1 }
} = theme;

export const BeforeElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  z-index: 1000;
`;

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const NotificationText = styled.span`
  font-size: 12px;
  color: ${({ activeText, theme }) =>
    activeText ? colorRoyalBlue : colorMediumGray1};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  margin-right: 10px;
`;

export const NotificationBell = styled.i`
  background: ${({ isBlue }) =>
    isBlue
      ? `url(${bellBlue}) no-repeat center`
      : `url(${bellGray}) no-repeat center`};
  background-size: contain;
  width: 14px;
  height: ${({ isOnProjectDetail, isOnCommentModal }) =>
    isOnProjectDetail || isOnCommentModal ? '20px' : '14px'};
  transition-duration: 0.218s;
  transition-timing-function: ease-out;
  &:hover {
    background: ${({ blueHover }) =>
      blueHover && `url(${bellBlue}) no-repeat center`};
    background-size: contain;
    transform: scale(1.2);
  }
`;

export const ProjectRowNumber = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  position: relative;
  left: 5px;
  color: #828282;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  width: 1px;
  height: 16px;
`;
