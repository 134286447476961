import * as constants from 'appConstants';
import moment from 'moment';
import keyBy from 'lodash/keyBy';

export const initialState = {
  isTimerDrawerOpen: false,
  timers: [],
  activeTimer: null,
  timerDate: moment().format('YYYY-MM-DD'),
  isTimerDeleteModalOpen: false,
  timerToDelete: []
};

const timers = (state = initialState, action) => {
  switch (action.type) {
    case constants.OPEN_TIMER_DRAWER: {
      return {
        ...state,
        isTimerDrawerOpen: true
      };
    }

    case constants.CLOSE_TIMER_DRAWER: {
      return {
        ...state,
        isTimerDrawerOpen: false
      };
    }

    case constants.FETCH_TIMERS.SUCCESS: {
      const { response } = action.payload;
      const filteredTimers = response.timers.filter(
        (timer) =>
          !timer.below_duration_threshold ||
          (state.activeTimer && timer.id === state.activeTimer.id)
      );
      return {
        ...state,
        timers: filteredTimers
      };
    }

    case constants.FETCH_ACTIVE_TIMER.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        activeTimer: response.timer
      };
    }

    case constants.START_TIMER.SUCCESS: {
      const { response } = action.payload;

      return {
        ...state,
        activeTimer: response.timer,
        timers: response.stopped_timer
          ? [
              ...state.timers.filter(
                (timer) => timer.id !== response.stopped_timer.id
              ),
              response.timer,
              response.stopped_timer
            ]
          : [...state.timers, response.timer]
      };
    }

    case constants.END_TIMER.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        activeTimer: null,
        timers: [
          ...state.timers.filter((timer) => timer.id !== response.timer.id),
          ...(response.timer.below_duration_threshold ? [] : [response.timer])
        ]
      };
    }

    case constants.ASSOCIATE_TASK.SUCCESS: {
      const { response } = action.payload;
      const updatedTimerIdHash = keyBy(response.timers, 'id');
      return {
        ...state,
        timers: [
          ...state.timers.filter((timer) => !updatedTimerIdHash[timer.id]),
          ...response.timers
        ]
      };
    }

    case constants.SET_TIMER_DATE: {
      const { timerDate } = action.payload;
      return {
        ...state,
        timerDate: timerDate
      };
    }

    case constants.UPDATE_TIMER.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        timers: [
          ...state.timers.filter((timer) => timer.id !== response.timer.id),
          response.timer
        ]
      };
    }

    case constants.DELETE_TIMER.SUCCESS: {
      const { response } = action.payload;
      const { timer_ids } = response;

      const deletedActiveTimerIdHash = {};
      timer_ids.forEach(
        (timerId) => (deletedActiveTimerIdHash[timerId] = true)
      );

      return {
        ...state,
        activeTimer:
          state.activeTimer && deletedActiveTimerIdHash[state.activeTimer.id]
            ? null
            : state.activeTimer,
        timers: [
          ...state.timers.filter((timer) => !deletedActiveTimerIdHash[timer.id])
        ]
      };
    }

    case constants.OPEN_TIMER_DELETE_MODAL: {
      const { timerToDelete } = action;
      return {
        ...state,
        isTimerDeleteModalOpen: true,
        timerToDelete: timerToDelete
      };
    }

    case constants.CLOSE_TIMER_DELETE_MODAL: {
      return {
        ...state,
        isTimerDeleteModalOpen: false,
        timerToDelete: []
      };
    }

    default:
      return state;
  }
};

export default timers;
