import React from 'react';
import { connect } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import {
  TIMESHEET_STATUSES_API,
  FILTER_RENDER_TEXT
} from 'appConstants/timesheets';

const statuses = Object.values(TIMESHEET_STATUSES_API);

const TimesheetStatusFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  statuses,
  isHidden
}) => {
  const setFilterItems = (nextStatuses) => {
    updateAccountFilterLocal({
      ...activeFilter,
      status_ids: nextStatuses
    });
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={statuses}
      initialSelectedItems={activeFilter.status_ids}
      setFilterItems={setFilterItems}
      searchEnabled={false}
      itemHash={FILTER_RENDER_TEXT}
      toggleLabel="Status"
      togglePluralLabel="Statuses"
      filterId={activeFilter.id}
      listWidth={217}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps),
    statuses
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TimesheetStatusFilterDropdown);
