import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import mapValues from 'lodash/mapValues';
import transform from 'lodash/transform';
import { getSortedTeamMembers } from 'selectors';
import { OfficesState, initialState } from 'SettingsModule/reducers/offices';
import { Office } from 'SettingsModule/models/office';

const getOfficesState: Selector<RootState, OfficesState> = (state) =>
  state.offices || initialState;

export const getIsOfficesLoaded = createSelector(
  getOfficesState,
  (state) => state.isLoaded
);

export const getIsLoadingOffices = createSelector(
  getOfficesState,
  (state) => state.isLoading
);

export const getOfficesHash = createSelector(
  getOfficesState,
  (state) => state.officesHash
);

export const getOfficesArray = createSelector(getOfficesHash, (offices) =>
  Object.keys(offices)
    .sort()
    .flatMap((id) => offices[+id] ?? [])
);

export const getAlphabeticalOfficesArray = createSelector(
  getOfficesArray,
  (offices) => sortBy(offices, (office) => office.name)
);

export const getAlphabeticalOfficesIdArray = createSelector(
  getAlphabeticalOfficesArray,
  (offices) => offices.map((office) => office.id)
);

export const getFormattedOfficesWithMembers = createSelector(
  getSortedTeamMembers,
  getOfficesArray,
  (members, officesArray) => {
    const officesWithHashMemberships = officesArray.map((office) => ({
      ...office,
      officeMembersHash: keyBy(
        office.team_memberships,
        (membership) => membership.account_id
      )
    }));

    const formattedOffices = officesWithHashMemberships.map((office) => {
      const formattedOfficeMembers = members.filter(
        (member) => office.officeMembersHash[member.account.id]
      );

      return {
        ...office,
        formattedOfficeMembers
      };
    });

    return formattedOffices;
  }
);

export const getMembershipsByOffice = createSelector(
  getOfficesHash,
  (officeHash) => mapValues(officeHash, (office) => office.team_memberships)
);

export const getAccountIdToOfficeHash = createSelector(
  getOfficesHash,
  (officeHash) =>
    transform<Office, Record<number, Office>>(
      officeHash,
      (result, value) => {
        value.team_memberships.forEach((member) => {
          result[member.account_id] = value;
        });
      },
      {}
    )
);

export const makeGetOfficeByLocationId = () =>
  createSelector(
    (_: RootState, { locationId }: { locationId: number }) => locationId,
    getOfficesArray,
    (locationId, officesArray) =>
      officesArray.find((office) => office.location?.id === locationId)
  );

export const makeGetOfficesByIds = () =>
  createSelector(
    (state, { ids }: { ids: number[] }) => ids,
    getOfficesHash,
    (ids, officesHash) => ids.flatMap((id) => officesHash[id] ?? [])
  );
