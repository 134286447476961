import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledTextContainer = styled.div`
  padding-left: 11px;
  padding-top: 5px;
  font-size: 14px;
  color: ${theme.colors.colorCalendarBlue};
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 35px;
`;

const PositionClosedTitleSubRowCell = ({ row }) => {
  const { memberCount } = row.original;
  return (
    <StyledTitleContainer>
      <StyledTextContainer> {memberCount} Suggestions </StyledTextContainer>
    </StyledTitleContainer>
  );
};

export default PositionClosedTitleSubRowCell;
