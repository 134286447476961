import theme from 'theme';
import HomeTasksIcon from 'icons/HomeTasksIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DeleteXIcon from 'icons/DeleteXIcon';
import { TaskCard } from './TaskCard';
import MatchIcon from 'icons/MatchIcon';
import { TaskWithPlanOverlap } from '../../redux/types';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { DELETE_TASK_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { ReactComponent as PlayIcon } from 'icons/play.svg';
import { ReactComponent as StopIcon } from 'icons/stop.svg';
import { useTimer } from 'appUtils/hooks/useTimer';
import styled from 'styled-components';
import { Alert } from './styles';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import cn from 'classnames';
import { useEffect } from 'react';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

interface DisabledOptionProps {
  disabled?: boolean;
  tooltip?: string;
  disabledTooltip?: string;
}

// deleteTask is handled by the TaskListItem
type TaskMenuKeys =
  | 'markComplete'
  | 'matchWorkPlanDates'
  | 'toggleTimer'
  | 'removeFromWorkPlan';

interface TaskListItemProps {
  task: TaskWithPlanOverlap;
  taskWorkPlanHours?: Nullable<number>;
  handleClickTask: (task: TaskWithPlanOverlap) => void;
  handleMarkComplete: (task: TaskWithPlanOverlap) => void;
  handleMatchWorkPlanDates: (task: TaskWithPlanOverlap) => void;
  handleRemoveTask: (task: TaskWithPlanOverlap) => void;
  handleDeleteTask: (task: TaskWithPlanOverlap) => void;
  disabledOptions?: Partial<Record<TaskMenuKeys, DisabledOptionProps>>;
}

export const TaskListItem = ({
  task,
  taskWorkPlanHours,
  handleClickTask,
  handleMarkComplete,
  handleMatchWorkPlanDates,
  handleRemoveTask,
  handleDeleteTask,
  disabledOptions = {}
}: TaskListItemProps) => {
  const { project_id: projectId } = task;
  const { canDeleteTasks } = useProjectPermissionState({ projectId });
  const { toggleTimer, getIsTaskActiveTimer } = useTimer();

  const isTaskActiveTimer = getIsTaskActiveTimer(task.id);
  const isTaskHoursExceeded =
    (taskWorkPlanHours ?? 0) > (task.estimated_hours ?? 0);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <>
      <PositionnedTaskCard
        key={task.id}
        task={task}
        onClick={handleClickTask}
        menu={[
          {
            icon: (
              <HomeTasksIcon
                currentColor={theme.colors.colorMediumGray9}
                height={16}
                width={16}
                className={undefined}
              />
            ),
            label: (task) =>
              task.completed_at ? 'Mark Incompleted' : 'Mark Completed',
            onClick: handleMarkComplete,
            isDisabled: disabledOptions.markComplete?.disabled,
            menuItemTooltip: disabledOptions.markComplete?.disabled
              ? disabledOptions.markComplete?.disabledTooltip
              : disabledOptions.markComplete?.tooltip
          },
          {
            icon: <MatchIcon color={theme.colors.colorMediumGray9} />,
            label: 'Match Work Plan Dates',
            onClick: handleMatchWorkPlanDates,
            isDisabled: disabledOptions.matchWorkPlanDates?.disabled,
            menuItemTooltip: disabledOptions.matchWorkPlanDates?.disabled
              ? disabledOptions.matchWorkPlanDates?.disabledTooltip
              : disabledOptions.matchWorkPlanDates?.tooltip
          },
          {
            icon: isTaskActiveTimer ? <StopIcon /> : <PlayIcon />,
            label: isTaskActiveTimer ? 'Stop Timer' : 'Start Timer',
            onClick: toggleTimer,
            isDisabled: disabledOptions.toggleTimer?.disabled,
            menuItemTooltip: disabledOptions.toggleTimer?.disabled
              ? disabledOptions.toggleTimer?.disabledTooltip
              : disabledOptions.toggleTimer?.tooltip
          },
          {
            icon: (
              <DeleteXIcon
                color={theme.colors.colorMediumGray9}
                className={undefined}
              />
            ),
            label: 'Remove from Plan',
            onClick: handleRemoveTask,
            isDisabled: disabledOptions.removeFromWorkPlan?.disabled,
            menuItemTooltip: disabledOptions.removeFromWorkPlan?.disabled
              ? disabledOptions.removeFromWorkPlan?.disabledTooltip
              : disabledOptions.removeFromWorkPlan?.tooltip
          },
          {
            icon: <DeleteIcon />,
            label: 'Delete Task',
            onClick: handleDeleteTask,
            isDisabled: !canDeleteTasks,
            menuItemTooltip: !canDeleteTasks ? DELETE_TASK_TIP : undefined
          }
        ]}
      />
      {taskWorkPlanHours && (
        <EstimatedHours
          {...defaultTooltipProps}
          data-tip={`Time Estimate for Task on this Work Plan.${
            isTaskHoursExceeded
              ? '<br />Estimated Time exceeds Task Time Estimate.'
              : ''
          }`}
          data-class={cn(defaultTooltipProps['data-class'], 'center')}
        >
          {formatNumWithMaxTwoDecimals(taskWorkPlanHours)}h
          {isTaskHoursExceeded && <Alert>!</Alert>}
        </EstimatedHours>
      )}
    </>
  );
};

const EstimatedHours = styled.div`
  align-self: end;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 4px;
  justify-self: start;
`;

const PositionnedTaskCard = styled(TaskCard)`
  grid-column: 1;
`;
