import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import styled from 'styled-components';

export const SkeletonLoaderCell = () => (
  <StyledSkeletonLoaderContainer>
    <SkeletonLoader numLoaders={4} loaderStyle={{ height: 70, rx: 4 }} />
  </StyledSkeletonLoaderContainer>
);

/* ------------------------------------ - ----------------------------------- */

const StyledSkeletonLoaderContainer = styled.div`
  padding-top: 30px;
`;
