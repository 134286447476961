import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import {
  PROJECT_BUDGET_STATUSES_DISPLAY,
  BUDGET_STATUSES
} from 'appConstants/budgetStatuses';
import mapValues from 'lodash/mapValues';

const emptyObj = {};

const projectBudgetStatuses = Object.keys(PROJECT_BUDGET_STATUSES_DISPLAY);
const statusesWithoutArchived = projectBudgetStatuses.filter(
  (status) => status !== BUDGET_STATUSES.ARCHIVED
);

const statusHash = mapValues(PROJECT_BUDGET_STATUSES_DISPLAY, (label) => ({
  label
}));

export const useProjectBudgetStatusFilter = ({
  isOff,
  shouldUseDraft,
  resultCountHash,
  field = FilterField.projectBudgetStatus,
  shouldExcludeArchivedOption
}: Parameters<FilterListTypeHookWithItemHash>[0] & {
  shouldExcludeArchivedOption?: boolean;
} = emptyObj) => {
  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: shouldExcludeArchivedOption
      ? statusesWithoutArchived
      : projectBudgetStatuses,
    itemHash: statusHash,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    labelKey,
    shouldUseDraft
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: statusHash,
    labelKey,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'label';
