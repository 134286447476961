import { createAction } from '@reduxjs/toolkit';
import { SetRequired } from 'type-fest';
import { TimelineView } from '../types/timelineView';

export type AddTimelineViewParams = Pick<TimelineView, 'id' | 'type'> & {
  initialVisibleTimeStart?: EpochTimeStamp;
};

type RemoveTimelineViewParams = Pick<TimelineView, 'id'>;

export type UpdateTimelineViewParams = SetRequired<
  Partial<Omit<TimelineView, 'type'>>,
  'id'
>;

export const addTimelineView =
  createAction<AddTimelineViewParams>('ADD_TIMELINE_VIEW');
export const removeTimelineView = createAction<RemoveTimelineViewParams>(
  'REMOVE_TIMELINE_VIEW'
);

export const updateTimelineView = createAction<UpdateTimelineViewParams>(
  'UPDATE_TIMELINE_VIEW'
);
