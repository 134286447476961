import React from 'react';
import bindAll from 'lodash/bindAll';

class ArchivedBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText1: 'Unarchive',
      buttonText2: 'Delete'
    };
    bindAll(this, ['unarchive']);
  }

  unarchive() {
    this.setState({
      buttonText1: 'Restored',
      buttonText2: ''
    });
    setTimeout(() => this.props.unarchiveBoard(), 1000);
  }

  render() {
    const { board } = this.props;
    return (
      <div key={board.id} className="archived-board-row">
        <div className="board-name">{board.name}</div>
        <div className="board-actions">
          <div className="clickable" onClick={this.unarchive}>
            {this.state.buttonText1}
          </div>
          <div
            className="clickable"
            onClick={() => this.props.openDeleteModal(board)}
          >
            {this.state.buttonText2}
          </div>
        </div>
      </div>
    );
  }
}

export default ArchivedBoard;
