import SvgIcon from 'components/SvgIcon';
import { ComponentProps } from 'react';

const PriorityFlagIcon = ({
  width = 15,
  height = 17,
  currentColor,
  stroke,
  ...svgIconProps
}: ComponentProps<typeof SvgIcon> & {
  width?: number;
  height?: number;
  currentColor: string;
  stroke?: string;
}) => (
  <SvgIcon
    height={height}
    strokeWidth={stroke ? 0.8 : 0}
    viewBox="0 0 15 17"
    width={width}
    {...svgIconProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5883 2.04393C13.5301 2.04393 13.4728 2.05662 13.4183 2.07693C12.95 2.25123 12.0504 2.36521 11.4735 2.36521C10.2485 2.36521 8.84854 1.88328 7.44854 1.49774C5.96104 0.919428 4.38603 0.4375 2.89854 0.4375C2.54854 0.4375 2.19853 0.533886 1.93603 0.533886V0.533886C0.969537 0.533886 0.186035 1.31739 0.186035 2.28389V15.5625C0.186035 16.0457 0.577786 16.4375 1.06103 16.4375V16.4375C1.54428 16.4375 1.93603 16.0457 1.93603 15.5625V12.2192C1.93603 11.6266 2.3059 11.0399 2.89854 11.0399V11.0399C4.12353 11.0399 5.52353 11.5218 6.92353 11.9074C8.41103 12.3893 9.98604 12.8712 11.4735 12.8712C12.261 12.8712 13.0485 12.6785 13.661 12.3893V12.3893C13.9839 12.2115 14.1818 11.8694 14.175 11.5009L14.0076 2.45556C14.0034 2.227 13.8169 2.04393 13.5883 2.04393V2.04393Z"
      fill={currentColor}
      stroke={stroke}
    />
  </SvgIcon>
);

export default PriorityFlagIcon;
