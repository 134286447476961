// Individual Notification, will pass appropiate data to child components

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import {
  MarkAsRead,
  UnreadDot,
  Timestamp,
  ActivityItemDetail,
  ActivityItemHeader
} from 'views';
import {
  fetchCommentsAndMetadata,
  setSelectedTask,
  setEditTaskId,
  fetchSingleTask,
  openEditNoteModal,
  navigateToTaskModal,
  openWorkloadEventsModal
} from 'actionCreators';
import noteActionCreators from 'NoteModule/actionCreators';
import {
  isTaskAction,
  isProjectAction,
  isNoteAction,
  isCommentAction
} from 'appUtils';
import {
  getAuth,
  getActivityFeedNotes,
  getCurrentUserId,
  getMe
} from 'selectors';
import {
  ACTION_TYPES,
  INTEGRATION_ACTIONS
} from 'appConstants/actionDescription';
import moment from 'appUtils/momentConfig';
import { getDisplayDataRange } from 'appUtils/workplanDisplayUtils';
const { fetchNote } = noteActionCreators;

class ActivityFeedItem extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'openTaskModal',
      'navigateToProjectDetail',
      'handleClick',
      'handleProjectDetailAnimation',
      'loadProjectNotes'
    ]);
  }

  navigateToProjectDetail(taskOrNoteTab = 'tasks') {
    const { projectUrl, history } = this.props;
    if (taskOrNoteTab === 'tasks') history.push(projectUrl);
    else history.push(projectUrl.replace('tasks', 'notes'));
  }

  openTaskModal(taskId) {
    const { navigateToTaskModal } = this.props;
    navigateToTaskModal({ taskId });
  }

  handleClick() {
    const { actionType, actionableId, displayData, loadedNotes, actor } =
      this.props;
    const isNoteLoaded = loadedNotes[actionableId];

    if (isTaskAction(actionType)) {
      this.openTaskModal(actionableId);
    } else if (isProjectAction(actionType)) {
      // for now, project comments/statuses will display the comment body and redirect to project detail view
      this.navigateToProjectDetail();
    } else if (isNoteAction(actionType) && isNoteLoaded) {
      this.props.openEditNoteModal(actionableId);
    } else if (actionType === ACTION_TYPES.FOLLOWING_SCHEDULED_EVENT) {
      this.props.openWorkloadEventsModal({
        eventId: displayData.scheduled_event_id,
        workloadRangeStart: moment(getDisplayDataRange(displayData)),
        actor,
        displayData
      });
    } else if (actionType === ACTION_TYPES.SEND_SCHEDULED_EVENT) {
      if (displayData?.event_type !== 'time_entry_reminder') {
        this.props.openWorkloadEventsModal({
          isPersonal: true,
          workloadRangeStart: moment(getDisplayDataRange(displayData)),
          actor,
          displayData,
          eventId: displayData.scheduled_event_id
        });
      }
    }
  }

  handleProjectDetailAnimation() {
    const { projectUrl, history } = this.props;
    const dummyActivityAnimationNode =
      document.getElementById('activity-animation');
    dummyActivityAnimationNode.className += ' is-expanding';
    // The transition duration is set to 500ms. 350ms ensures the page is not idle before pushing.
    setTimeout(() => history.push(projectUrl), 350);
  }

  loadProjectNotes() {
    const { actionableId, actionType, loadedNotes, fetchNote } = this.props;

    const isNoteItem = actionType === ACTION_TYPES.NOTE_CREATION;
    const isNoteLoaded = loadedNotes[actionableId];

    if (isNoteItem && !isNoteLoaded) {
      fetchNote({ id: actionableId });
    }
  }

  render() {
    const {
      isRead,
      isNew,
      time,
      markAsRead,
      type,
      project,
      displayData,
      id,
      actor,
      actionType,
      userId,
      isActivityMemberModal,
      accountId
    } = this.props;
    const hideViewButton =
      INTEGRATION_ACTIONS.includes(actionType) ||
      displayData?.event_type === 'time_entry_reminder'; // Visible if NOT includes,
    return (
      <div
        className={cn('activity-feed-item-container', {
          'is-read': isRead
        })}
      >
        <div className="activity-feed-item-info">
          {!isActivityMemberModal && (
            <MarkAsRead onClick={markAsRead(id, !isRead)} isRead={isRead} />
          )}
        </div>
        <ActivityFeedItemBodyContainer>
          <TimestampContinaer>
            <div className="unread-dot-container">
              {isNew && !isRead && <UnreadDot isNew={isNew} />}
            </div>
            <Timestamp time={time} isRead={isRead} />
          </TimestampContinaer>

          <div
            className="activity-feed-item-body"
            onMouseEnter={this.loadProjectNotes}
          >
            <ActivityItemHeaderContainer>
              <ActivityItemHeader
                type={type}
                actionType={actionType}
                displayData={displayData}
                actor={actor}
                createdByCurrentUser={actor && actor.id === userId}
                project={project}
                goToProject={this.handleProjectDetailAnimation}
                id={id}
                handleClick={this.handleClick}
                isActivityMemberModal={isActivityMemberModal}
                accountId={accountId}
              />
              <ViewButton
                className="view-button"
                hideViewButton={hideViewButton}
                onClick={this.handleClick}
              >
                View
              </ViewButton>
            </ActivityItemHeaderContainer>
            <ActivityItemDetail
              project={project}
              displayData={displayData}
              actionType={actionType}
              handleClick={this.handleClick}
            />
          </div>
        </ActivityFeedItemBodyContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  userId: getCurrentUserId(state),
  me: getMe(state),
  loadedNotes: getActivityFeedNotes(state)
});

const mapDispatchToProps = {
  setEditTaskId,
  fetchCommentsAndMetadata,
  setSelectedTask,
  fetchSingleTask,
  openEditNoteModal,
  fetchNote,
  navigateToTaskModal,
  openWorkloadEventsModal
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivityFeedItem)
);

const ViewButton = styled.div`
  visibility: ${({ hideViewButton }) => hideViewButton && 'hidden !important'};
`;

const TimestampContinaer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 13px;
  height: fit-content;
  width: 80px;

  .timestamp {
    font-size: 12px;
    padding-left: 5px;
    color: ${theme.colors.colorSemiDarkGray3};
  }
  .unread-dot-container {
    width: 10px;
    height: 10px;
  }
  .unread-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: ${theme.colors.colorBudgetBlue};
  }
`;

const ActivityFeedItemBodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActivityItemHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
