import { makeVerification } from '../makeVerification';

// Create, Edit, Delete a role
// Not implemented, waiting for custom roles.
export const verifyCreateRoles = makeVerification('create_team_role_templates');
export const verifyEditRoles = makeVerification('edit_team_role_templates');
export const verifyDeleteRoles = makeVerification('delete_team_role_templates');

// Add member to the organization
export const verifyCreateTeamMembership = makeVerification(
  'create_team_memberships'
);

// Remove member to the organization
export const verifyDeleteTeamMembership = makeVerification(
  'delete_team_memberships'
);

// Assign a Member to a Role
export const verifyManageMemberRole = makeVerification('edit_team_member_role');

// Invite guest.
export const verifyInviteGuests = makeVerification('create_guests');
