import {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { produce } from 'immer';
import assert from 'assert';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import template from 'lodash/template';
import styled from 'styled-components';
import theme from 'theme';
import { ColorScalePreviewInterval } from '../ColorScalePreviewInterval';
import {
  ColorScaleIntervals,
  ColorScaleMinima,
  ColorScaleStyle
} from '../types';
import { STRINGS_EN } from './constants';
import { buildIntervalsWithMaxima } from '../utils';

export interface ColorScaleIntervalsInputProps {
  /**
   * The class of the container for this component.
   */
  className?: string;

  /**
   * The interval properties of the color scale.
   */
  intervals: ColorScaleIntervals;

  /**
   * Event handler that is called when the scale changes into a new valid
   * state.
   */
  onChange: (minima: ColorScaleMinima) => void;
}

/**
 * An internationalizable string descriptor.
 */
interface I18NText {
  /**
   * A unique code representing the string.
   */
  code: string;

  /**
   * An object representing any context information required to format the
   * string. The keys of this object will appear as `<%= key %>` in the
   * internationalized string.
   */
  context?: { [key: string]: any };
}

/**
 * Tracks the current state of the values of an interval.
 */
interface ColorScaleIntervalEdit {
  error?: I18NText;
  isFrozen?: boolean;
  isHidden?: boolean;
  max?: string;
  min: string;
  style: ColorScaleStyle;
  minDefault?: string;
}

/**
 * Input to modify the numeric intervals of a color scale.
 */
export const ColorScaleIntervalsInput = ({
  className,
  intervals,
  onChange
}: ColorScaleIntervalsInputProps) => {
  const initialIntervals = useMemo(
    () =>
      buildIntervalsWithMaxima(intervals).map<ColorScaleIntervalEdit>(
        ({ isHidden, min, minDefault, max, maxIsFrozen, style }) => ({
          isFrozen: maxIsFrozen,
          isHidden,
          min: min.toString(),
          max: max?.toString(),
          style,
          minDefault: minDefault?.toString()
        })
      ),
    [intervals]
  );

  const hasDefaults = useMemo(
    () =>
      intervals.some(({ minDefault: defaultMin }) => defaultMin !== undefined),
    [intervals]
  );

  const [editedIntervals, setEditedIntervals] = useState<
    Array<ColorScaleIntervalEdit>
  >(initialIntervals.slice());

  useEffect(() => {
    setEditedIntervals(initialIntervals.slice());
  }, [initialIntervals]);

  // This component uses immer.produce on the `currentIntervals` state, so the
  // `ref`'s cannot be located inside within that structure as they will
  // mutate.
  const [intervalRefs] = useState<Array<React.RefObject<HTMLInputElement>>>(
    editedIntervals.map(() => createRef())
  );

  // Hides the error tooltip of an interval.
  const clearErrorTooltip = useCallback(
    (index: number): void => {
      const intervalRef = intervalRefs[index];
      intervalRef?.current && ReactTooltip.hide(intervalRef.current);
    },
    [intervalRefs]
  );

  // Registers a change to the input values, validates that value, and sets
  // appropriate errors.
  const handleChange = useCallback(
    (index: number) =>
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        const interval = editedIntervals[index];
        if (!interval) return;

        // The entered value must be a number for it to be valid.
        let error: I18NText | undefined;
        const max =
          interval.max === undefined
            ? Number.POSITIVE_INFINITY
            : parseInt(value);
        if (Number.isNaN(max)) {
          // Although this error is never displayed, it is required to detect
          // when the input should revert to the original value.
          error = { code: 'colorscaleintervals_error_nan' };
        } else {
          const intervalAfter = editedIntervals[index + 1];

          // The maximum of the current interval must be between the current
          // interval's minimum value and the following interval's maximum
          // value.
          const min = parseInt(interval.min);
          const maxAfter =
            !intervalAfter || intervalAfter.max === undefined
              ? Number.POSITIVE_INFINITY
              : parseInt(intervalAfter.max);
          if (min > max) {
            error = {
              code: 'colorscaleintervals_error_min',
              context: { min }
            };
          } else if (max > maxAfter) {
            error = {
              code: 'colorscaleintervals_error_max',
              context: { max: maxAfter }
            };
          } else {
            // Check that the adjacent intervals do not have the exact same
            // range. For example, 50-50% followed by 50-50%.
            const intervalBefore = editedIntervals[index - 1];
            const minBefore = intervalBefore
              ? parseInt(intervalBefore.min)
              : Number.NEGATIVE_INFINITY;
            if (max === minBefore) {
              error = {
                code: 'colorscaleintervals_error_samebefore',
                context: { min: minBefore }
              };
            }

            const intervalAfterAfter = editedIntervals[index + 2];
            const maxAfterAfter = intervalAfterAfter?.max
              ? parseInt(intervalAfterAfter.max)
              : undefined;
            if (max === maxAfterAfter) {
              error = {
                code: 'colorscaleintervals_error_sameafter',
                context: { max: maxAfter }
              };
            }
          }
        }

        // The tooltip for the error message is conditionally shown in
        // `useEffect` because it depends on changes to the rendered component.
        clearErrorTooltip(index);
        setEditedIntervals(
          produce(editedIntervals, (draft) => {
            const interval = draft[index];
            if (!interval) return;

            // Set the entered value and clear previous errors.
            interval.max = value;
            interval.error = error;

            // Modify the corresponding value in the following interval.
            const intervalAfter = draft[index + 1];
            if (intervalAfter) intervalAfter.min = value;
          })
        );
      },
    [editedIntervals, clearErrorTooltip]
  );

  useEffect(() => {
    assert(
      editedIntervals.filter((interval) => interval.error).length <= 1,
      'There must be a maximum of one error at a time.'
    );

    // Find the first interval that has an error and show its tooltip.
    const errorIntervalRef =
      intervalRefs[editedIntervals.findIndex((interval) => interval.error)];
    if (errorIntervalRef) {
      errorIntervalRef.current && ReactTooltip.show(errorIntervalRef.current);
    }
  }, [editedIntervals, intervalRefs]);

  // Attempt to commit a change to an interval. If the interval being committed
  // is invalid, clear any errors and reset to the last valid state. If the
  // interval is valid and is different from the original interval, commit it
  // and call the callback function with the new interval set. Otherwise, do
  // nothing.
  const handleCommit = (index: number) => (): void => {
    // Get the interval being committed.
    const interval = editedIntervals[index];
    if (!interval) return;

    if (interval.error) {
      clearErrorTooltip(index);
      setEditedIntervals(initialIntervals.slice());
    } else if (interval.max !== initialIntervals[index]?.max) {
      onChange(editedIntervals.map((interval) => parseInt(interval.min)));
    }
  };

  const handleReset = (): void => {
    // Perform a reset if some values are not currently equal to the default
    // values.
    if (
      editedIntervals.some(
        ({ min, minDefault: defaultMin }) =>
          defaultMin !== undefined && min !== defaultMin
      )
    ) {
      // Update the minima.
      const defaultIntervals = editedIntervals.slice().map((interval) => ({
        ...interval,
        min:
          interval.minDefault !== undefined ? interval.minDefault : interval.min
      }));

      // Update the maxima.
      defaultIntervals.forEach((interval, index) => {
        const prevInterval = defaultIntervals[index - 1];
        if (!prevInterval) return;
        prevInterval.max = interval.min;
      });

      setEditedIntervals(defaultIntervals);
      onChange(defaultIntervals.map((interval) => parseInt(interval.min)));
    }
  };

  return (
    <Container className={className}>
      <Grid>
        {editedIntervals.map(
          (
            { error, isFrozen, isHidden, min, max, style: { color, overflow } },
            index
          ) =>
            !isHidden && (
              <Fragment key={`${index}-${color}`}>
                <ColorScalePreviewInterval
                  color={color}
                  overflow={overflow}
                  size={18}
                />
                <FixedValue>
                  {index === editedIntervals.length - 1 && '>'}
                  {min}
                </FixedValue>
                <span>%</span>
                {index !== editedIntervals.length - 1 && (
                  <>
                    <span>–</span>
                    <NumberFormat
                      customInput={Textbox}
                      format="###"
                      disabled={isFrozen}
                      getInputRef={intervalRefs[index]}
                      mask=""
                      onChange={handleChange(index)}
                      onBlur={handleCommit(index)}
                      size={3}
                      value={max}
                      // Error tooltip
                      data-effect="solid"
                      data-event="focus"
                      data-event-off="focusout"
                      data-for="app-tooltip"
                      data-html
                      data-place="top"
                      data-tip={
                        error && error.code !== 'colorscaleintervals_error_nan'
                          ? template(STRINGS_EN.get(error.code))(error.context)
                          : undefined
                      }
                    />
                    <span>%</span>
                  </>
                )}
              </Fragment>
            )
        )}
      </Grid>
      {hasDefaults && (
        <Reset onClick={handleReset}>Restore System Defaults</Reset>
      )}
    </Container>
  );
};

const Textbox = styled.input.attrs({ type: 'text' })`
  background-color: ${theme.colors.colorLightGray22};
  border: 1px solid ${theme.colors.colorLightGray6};
  color: inherit;
  line-height: 1;
  padding: 0 2px;
  text-align: right;

  &:focus {
    border-color: ${theme.colors.colorCalendarBlue};
  }

  &[disabled] {
    background-color: transparent;
    border-color: transparent;
  }

  // Styles if the entry has an error.
  &[data-tip] {
    border-color: ${theme.colors.colorCalendarRed};
  }
`;

const FixedValue = styled.span`
  text-align: right;

  // Forces the width of the container to match the maximum possible width.
  // This assumes that 9 is the widest character in the active font.
  &::before {
    content: '≥999';
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const Grid = styled.div`
  align-items: center;
  display: inline-grid;
  font-size: 13px;
  grid-template-columns: repeat(6, auto);
  grid-gap: 20px 3px;
  justify-content: center;
  width: fit-content;

  .color-scale-preview-interval {
    margin-right: 7px;
  }
`;

const Reset = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  font-size: 12px;
  width: fit-content;
`;

const Container = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
`;
