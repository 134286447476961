import { BaseIconButton, BaseIconButtonProps } from './BaseIconButton';
import { MinusCircleIconHoverable } from 'icons/PlusMinusCircleIcon';

export const RemoveIconButton = ({
  ...iconButtonProps
}: BaseIconButtonProps) => (
  <BaseIconButton {...iconButtonProps}>
    <MinusCircleIconHoverable width={22} height={22} />
  </BaseIconButton>
);
