import styled from 'styled-components';
import { InlineGroupEditingForm } from './InlineGroupEditingForm';

export const InlineCreateGroupRowCell = ({ row: { original } }) => {
  const { onAddGroupInline, customRowProps } = original;

  const handleAddGroup = (val: string) => {
    onAddGroupInline && val.length && onAddGroupInline({ label: val });
    customRowProps.setIsCreatingGroup(false);
  };

  return (
    <CellContainer>
      <InlineGroupEditingForm initialValue="" onSubmit={handleAddGroup} />
    </CellContainer>
  );
};

const CellContainer = styled.div`
  height: 100%;
`;
