import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import {
  FetchWorkplanRequestsParams,
  FetchWorkplanRequestsPayload,
  FetchWorkplanRequestsSuccessResponse,
  FetchWorkplanRequestsMeta,
  CreateWorkplanRequestParams,
  CreateWorkplanRequestPayload,
  CreateWorkplanRequestSuccessResponse,
  CreateWorkplanRequestMeta,
  UpdateWorkplanRequestParams,
  UpdateWorkplanRequestPayload,
  UpdateWorkplanRequestSuccessResponse,
  UpdateWorkplanRequestMeta,
  DeleteWorkplanRequestParams,
  DeleteWorkplanRequestPayload,
  DeleteWorkplanRequestMeta,
  BatchDeleteWorkplanRequestParams,
  BatchDeleteWorkplanRequestPayload,
  BatchDeleteWorkplanRequestMeta,
  CopyWorkplanRequestsParams,
  CopyWorkplanRequestsMeta,
  CopyWorkplanRequestsPayload,
  CopyWorkplanRequestsSuccessResponse,
  ApproveWorkplanRequestParams,
  ApproveWorkplanRequestPayload,
  ApproveWorkplanRequestSuccessResponse,
  ApproveWorkplanRequestMeta,
  ReorderWorkplanRequestTaskOrScopeParams,
  ReorderWorkplanRequestTaskOrScopePayload,
  ReorderWorkplanRequestTaskOrScopeSuccessResponse,
  ReorderWorkplanRequestTaskOrScopeMeta,
  SelectWorkplanRequestsParams,
  ClearSelectedWorkplanRequestsParams,
  SelectAllWorkplanRequestsParams
} from './types';

/* ---------------------------------- fetch --------------------------------- */

const FETCH_WORKPLAN_REQUESTS = createRequestTypes('FETCH_WORKPLAN_REQUESTS');

export const fetchWorkplanRequests = createAction(
  FETCH_WORKPLAN_REQUESTS.TRIGGER,
  ({ meta, ...payload }: FetchWorkplanRequestsParams) => ({ payload, meta })
);

export const fetchWorkplanRequestsActionCreatorsMap = createActionCreatorsMap<
  FetchWorkplanRequestsPayload,
  FetchWorkplanRequestsSuccessResponse,
  FetchWorkplanRequestsMeta
>(FETCH_WORKPLAN_REQUESTS);

/* --------------------------------- create --------------------------------- */

const CREATE_WORKPLAN_REQUEST = createRequestTypes('CREATE_WORKPLAN_REQUEST');

export const createWorkplanRequest = createAction(
  CREATE_WORKPLAN_REQUEST.TRIGGER,
  ({ meta, ...payload }: CreateWorkplanRequestParams) => ({ payload, meta })
);

export const createWorkplanRequestActionCreatorsMap = createActionCreatorsMap<
  CreateWorkplanRequestPayload,
  CreateWorkplanRequestSuccessResponse,
  CreateWorkplanRequestMeta
>(CREATE_WORKPLAN_REQUEST);

/* --------------------------------- update --------------------------------- */

const UPDATE_WORKPLAN_REQUEST = createRequestTypes('UPDATE_WORKPLAN_REQUEST');

export const updateWorkplanRequest = createAction(
  UPDATE_WORKPLAN_REQUEST.TRIGGER,
  ({ meta, ...payload }: UpdateWorkplanRequestParams) => ({ payload, meta })
);

export const updateWorkplanRequestActionCreatorsMap = createActionCreatorsMap<
  UpdateWorkplanRequestPayload,
  UpdateWorkplanRequestSuccessResponse,
  UpdateWorkplanRequestMeta
>(UPDATE_WORKPLAN_REQUEST);

/* --------------------------------- delete --------------------------------- */

const DELETE_WORKPLAN_REQUEST = createRequestTypes('DELETE_WORKPLAN_REQUEST');

export const deleteWorkplanRequest = createAction(
  DELETE_WORKPLAN_REQUEST.TRIGGER,
  ({ meta, ...payload }: DeleteWorkplanRequestParams) => ({ payload, meta })
);

export const deleteWorkplanRequestActionCreatorsMap = createActionCreatorsMap<
  DeleteWorkplanRequestPayload,
  never,
  DeleteWorkplanRequestMeta
>(DELETE_WORKPLAN_REQUEST);

/* ------------------------------ batch delete ------------------------------ */

const BATCH_DELETE_WORKPLAN_REQUEST = createRequestTypes(
  'BATCH_DELETE_WORKPLAN_REQUEST'
);

export const batchDeleteWorkplanRequest = createAction(
  BATCH_DELETE_WORKPLAN_REQUEST.TRIGGER,
  ({ meta, ...payload }: BatchDeleteWorkplanRequestParams) => ({
    payload,
    meta
  })
);

export const batchDeleteWorkplanRequestActionCreatorsMap =
  createActionCreatorsMap<
    BatchDeleteWorkplanRequestPayload,
    never,
    BatchDeleteWorkplanRequestMeta
  >(BATCH_DELETE_WORKPLAN_REQUEST);

/* --------------------------------- approve -------------------------------- */

const APPROVE_WORKPLAN_REQUEST = createRequestTypes('APPROVE_WORKPLAN_REQUEST');

export const approveWorkplanRequest = createAction(
  APPROVE_WORKPLAN_REQUEST.TRIGGER,
  ({ meta, ...payload }: ApproveWorkplanRequestParams) => ({ payload, meta })
);

export const approveWorkplanRequestActionCreatorsMap = createActionCreatorsMap<
  ApproveWorkplanRequestPayload,
  ApproveWorkplanRequestSuccessResponse,
  ApproveWorkplanRequestMeta
>(APPROVE_WORKPLAN_REQUEST);

/* -------------------------- reorder task or scope ------------------------- */
const REORDER_WORKPLAN_REQUEST_TASK_OR_SCOPE = createRequestTypes(
  'REORDER_WORKPLAN_REQUEST_TASK_OR_SCOPE'
);

export const reorderWorkplanRequestTaskOrScope = createAction(
  REORDER_WORKPLAN_REQUEST_TASK_OR_SCOPE.TRIGGER,
  ({ meta, ...payload }: ReorderWorkplanRequestTaskOrScopeParams) => ({
    payload,
    meta
  })
);

export const reorderWorkplanRequestTaskOrScopeActionCreatorsMap =
  createActionCreatorsMap<
    ReorderWorkplanRequestTaskOrScopePayload,
    ReorderWorkplanRequestTaskOrScopeSuccessResponse,
    ReorderWorkplanRequestTaskOrScopeMeta
  >(REORDER_WORKPLAN_REQUEST_TASK_OR_SCOPE);

/* ---------------------------------- copy ---------------------------------- */

const COPY_WORKPLAN_REQUESTS = createRequestTypes('COPY_WORKPLAN_REQUESTS');

export const copyWorkplanRequests = createAction(
  COPY_WORKPLAN_REQUESTS.TRIGGER,
  ({ meta, ...payload }: CopyWorkplanRequestsParams) => ({
    payload,
    meta
  })
);

export const copyWorkplanRequestActionCreatorsMap = createActionCreatorsMap<
  CopyWorkplanRequestsPayload,
  CopyWorkplanRequestsSuccessResponse,
  CopyWorkplanRequestsMeta
>(COPY_WORKPLAN_REQUESTS);

/* --------------------------- Selection - FE only -------------------------- */

const SELECT_WORKPLAN_REQUESTS = 'SELECT_WORKPLAN_REQUESTS';

export const selectWorkplanRequests = createAction(
  SELECT_WORKPLAN_REQUESTS,
  ({ meta, ...payload }: SelectWorkplanRequestsParams) => ({
    meta,
    payload
  })
);

const CLEAR_SELECTED_WORKPLAN_REQUESTS = 'CLEAR_SELECTED_WORKPLAN_REQUESTS';

export const clearSelectedWorkplanRequests = createAction(
  CLEAR_SELECTED_WORKPLAN_REQUESTS,
  ({ meta, ...payload }: ClearSelectedWorkplanRequestsParams) => ({
    meta,
    payload
  })
);

const SELECT_ALL_WORKPLAN_REQUESTS = 'SELECT_ALL_WORKPLAN_REQUESTS';

/**
 * Will add all workplan request ids in isoState to selectedIds
 */
export const selectAllWorkplanRequests = createAction(
  SELECT_ALL_WORKPLAN_REQUESTS,
  ({ meta, ...payload }: SelectAllWorkplanRequestsParams) => ({
    meta,
    payload
  })
);

/* --------------------------------- shared --------------------------------- */

export const workplanRequestsActionCreatorsMaps = {
  fetchWorkplanRequestsActionCreatorsMap,
  createWorkplanRequestActionCreatorsMap,
  updateWorkplanRequestActionCreatorsMap,
  deleteWorkplanRequestActionCreatorsMap,
  batchDeleteWorkplanRequestActionCreatorsMap,
  copyWorkplanRequestActionCreatorsMap,
  approveWorkplanRequestActionCreatorsMap,
  reorderWorkplanRequestTaskOrScopeActionCreatorsMap
};
