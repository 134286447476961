import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CutIcon = () => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12">
      <g fill="none">
        <path
          d="M10.0539 2.24087C10.4155 1.36617 10.524 0.526957 9.90911 0L5.99216 5.14278L5.53094 5.75896C5.53094 5.75896 4.78016 6.83061 4.51798 7.33043C4.25555 7.83 3.99338 8.38383 3.77633 8.7767C3.5859 9.12156 3.49251 9.18443 3.38711 9.08609C3.37334 9.07033 3.35925 9.05485 3.34485 9.03965L3.34198 9.036C3.30499 8.99113 3.26268 8.95092 3.21598 8.91626C2.96164 8.69974 2.64494 8.57139 2.3019 8.57139C1.46294 8.57139 0.782593 9.33913 0.782593 10.2861C0.782593 11.2325 1.46294 12 2.30216 12C2.98929 12 3.56946 11.4856 3.75729 10.7797V10.7807C3.75729 10.7807 4.12016 9.66965 4.64477 8.86591C5.16938 8.06244 5.99242 7.71417 5.99242 7.71417L6.86085 6.85722C6.86085 6.85722 9.69181 3.11609 10.0539 2.24087ZM2.3019 11.143C1.88294 11.143 1.54329 10.7588 1.54329 10.2861C1.54329 9.81339 1.88294 9.42887 2.3019 9.42887C2.72085 9.42887 3.06051 9.81339 3.06051 10.2861C3.06051 10.7588 2.72085 11.143 2.3019 11.143ZM5.99998 6.85722C5.75998 6.85722 5.56511 6.66522 5.56511 6.42861C5.56511 6.192 5.75998 6 5.99998 6C6.23998 6 6.43485 6.192 6.43485 6.42861C6.43485 6.66522 6.24024 6.85722 5.99998 6.85722Z"
          fill="#4A4A4A"
        />
        <path
          d="M4.67614 6.24783C4.92553 5.86983 5.15614 5.53983 5.17362 5.5153L5.17753 5.51009L5.18144 5.50435L5.64292 4.88843L5.64371 4.88687L5.64501 4.88556L5.72484 4.7807L2.08414 0C1.46901 0.526957 1.57753 1.36617 1.93936 2.24087C2.20988 2.89513 3.86014 5.15061 4.67614 6.24783ZM9.6911 8.57139C9.34779 8.57139 9.03136 8.69974 8.77701 8.91626C8.73031 8.95092 8.688 8.99113 8.65101 9.036L8.64814 9.03965C8.63376 9.05486 8.61967 9.07034 8.60588 9.08609C8.50023 9.18443 8.4071 9.12156 8.21666 8.7767C7.99962 8.38383 7.73718 7.83 7.47501 7.33043C7.41637 7.22105 7.35434 7.11352 7.28901 7.008C7.26253 7.04316 7.236 7.07829 7.20944 7.11339L7.19066 7.13817L7.16849 7.16009L6.38901 7.92965C6.67257 8.11304 7.05657 8.41904 7.34849 8.86617C7.87284 9.66965 8.23597 10.781 8.23597 10.781V10.7797C8.42353 11.4856 9.00371 12 9.69084 12C10.5303 12 11.2174 11.2325 11.2174 10.2858C11.2174 9.33887 10.5303 8.57139 9.6911 8.57139ZM9.6911 11.1428C9.27188 11.1428 8.93249 10.7585 8.93249 10.2858C8.93249 9.81313 9.27188 9.42861 9.6911 9.42861C10.1101 9.42861 10.4497 9.81313 10.4497 10.2858C10.4497 10.7585 10.1101 11.1428 9.6911 11.1428Z"
          fill="#4A4A4A"
        />
      </g>
    </SvgIcon>
  );
};

export default CutIcon;
