import styled from 'styled-components';
import theme from 'theme';
import NumberFormat from 'react-number-format';

/**
 * Common styled components
 */

interface TextButtonProps {
  backgroundColor?: string;
  padding?: string | number;
  width?: number;
  fontWeight?: number;
  lineHeight?: number;
  fontSize?: number;
  borderRadius?: number;
  isHidden?: boolean;
  opacity?: number;
  isDisabled?: boolean;
  color?: string;
}
export const TextButton = styled.button.attrs<TextButtonProps>(
  ({ isDisabled }) => ({
    disabled: isDisabled
  })
)<TextButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.backgroundColor || 'transparent'};

  padding: ${(props) => props.padding || '0.4rem 0.8rem'};
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  font-size: ${(props) => props.fontSize || 14}px;
  color: ${(props) => props.color || theme.colors.colorCalendarGray};
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: ${(props) => props.borderRadius || 4}px;
  white-space: nowrap;
  ${(props) => props.isHidden && 'visibility: hidden;'}
  ${(props) => props.opacity && `opacity: ${props.opacity};`}
  ${(props) => props.isDisabled && 'pointer-events: none;'}
  cursor: pointer;

  &:hover {
    filter: brightness(96%);
  }
  &:focus {
    filter: brightness(80%);
  }
`;

export const TextButtonWithBorder = styled(TextButton)<{
  backgroundColor?: string;
  borderWidth?: number;
  borderStyle?: number;
  borderColor?: string;
  border?: string | number;
}>`
  background-color: ${(props) => props.backgroundColor || 'white'};
  border-style: ${(props) => props.borderStyle || 'solid'};
  border-width: ${(props) => props.borderWidth || 1}px;
  border-color: ${(props) =>
    props.borderColor || props.backgroundColor || theme.colors.colorLightGray6};
  ${(props) => props.border && `border: ${props.border};`}
  line-height: 1.3;
`;

export const BlueSubmitButton = styled(TextButtonWithBorder)<{
  backgroundColor?: string;
  fontSize?: number;
  fontWeight?: number;
}>`
  background-color: ${(props) =>
    props.backgroundColor || theme.colors.colorCalendarBlue};
  color: #fff;
  border-color: ${theme.colors.colorRoyalBlue};
  width: 70px;
  font-size: ${({ fontSize }) => `${fontSize}px` || 'unset'};
  font-weight: ${({ fontWeight }) => fontWeight || 'unset'};
`;

export const ButtonContainer = styled.div<{ isHidden?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  min-width: fit-content;
  ${(props) => props.isHidden && 'visibility: hidden;'}
  ${TextButton} {
    &:first-child {
      margin-right: 9px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ToggleOption = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  text-align: center;
  flex: 1;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  padding: 3px 4px;
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorSemiDarkGray1 : theme.colors.colorMediumGray4};
  box-shadow: ${({ isActive }) =>
    isActive ? 'box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25)' : ''};
  background: ${({ isActive }) =>
    isActive
      ? theme.colors.colorPureWhite
      : theme.colors.colorTranslucentGray3};

  &:first-child {
    border-right: 1px solid
      ${({ isActive }) =>
        isActive ? theme.colors.colorLightGray6 : 'transparent'};
  }
  &:last-child {
    border-left: 1px solid
      ${({ isActive }) =>
        isActive ? theme.colors.colorLightGray6 : 'transparent'};
  }
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const ToggleContainer = styled.div<{
  width?: number;
  borderRadius?: number;
  padding?: string | number;
  optionBorders?: boolean;
}>`
  background: ${theme.colors.colorTranslucentGray3};
  border: 1px solid ${theme.colors.colorLightGray6};
  width: ${(props) => props.width || 120}px;
  z-index: 500;
  display: flex;
  border-radius: ${(props) => props.borderRadius || 25}px;
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${ToggleOption} {
    ${(props) =>
      props.borderRadius && `border-radius: ${props.borderRadius}px;`}
    &:not(:first-child) {
      ${(props) =>
        props.optionBorders &&
        `border-left: 1px solid ${theme.colors.colorPaleGray5};`}
    }
  }
`;

export const AddMembersFormContainer = styled.div`
  position: relative;
  background-color: ${theme.colors.colorPureWhite};
  padding: 0px;
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 103;
  line-height: initial;
  font-size: initial;
  font-weight: initial;
  &.suggestions {
    background-color: ${theme.colors.colorLightGray19};
  }
`;

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const NumberFormatInput = styled(NumberFormat)`
  font-weight: 600;
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  width: 70px;
  height: 25px;
  background: ${theme.colors.colorLightGray19};
  border-radius: 3px;
  border: none;
  text-align: center;
`;
