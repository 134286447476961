import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import PhaseMilestoneIconNarrow from 'icons/PhaseMilestoneIconNarrow';
import PhaseDiamondIcon from 'icons/PhaseDiamondIcon';
import { buildMilestoneDates } from 'appUtils';
import { StyledMilestoneContainer } from './styles';
import {
  BUDGET_STATUSES,
  PHASE_BUDGET_STATUS_DISPLAY
} from 'appConstants/budgetStatuses';

import {
  getPhaseDisplayBudgetStatus,
  isPhaseInactive
} from 'appUtils/phaseDisplayUtils';

const InactiveText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 10px;
  font-weight: 600;
  margin-left: 15px;
  text-transform: uppercase;
`;

const MilestoneDetailList = ({ milestones }) => {
  const list = milestones.map((phase, index) => {
    const { name, end_date, id, is_budget } = phase;

    const budgetStatus = getPhaseDisplayBudgetStatus(phase);
    const isCompleted =
      budgetStatus === PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.COMPLETE];
    const isInactive = isPhaseInactive(phase);

    return (
      <React.Fragment key={id}>
        {isInactive && <InactiveText>{budgetStatus}</InactiveText>}
        <div style={{ marginBottom: '5px', display: 'flex' }}>
          {is_budget ? (
            <StyledMilestoneContainer
              data-tip={'This phase is part of <br/>the project budget.'}
              data-html
              data-effect="solid"
            >
              <PhaseDiamondIcon
                width="14px"
                height="14px"
                className="budget-phase-milestone-icon"
              />
            </StyledMilestoneContainer>
          ) : (
            <PhaseMilestoneIconNarrow className="phase-milestone-icon" />
          )}

          <div style={{ flex: 1, overflow: 'hidden', display: 'block' }}>
            <div
              className={cn('milestone-description', {
                'completed-description': isCompleted,
                'inactive-description': isInactive && !isCompleted
              })}
            >
              {name}
            </div>
            <div
              className={cn('dates', {
                'completed-date': isCompleted
              })}
            >
              {buildMilestoneDates(phase)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  return <div className="milestone-list">{list}</div>;
};

export default MilestoneDetailList;
