import * as appConstants from 'appConstants';
import * as constants from '../constants';
import produce from 'immer';

export const initialState = {
  openSubcustomerModal: false,
  preFillText: null,
  isCreatingNewSubSub: false,
  toBeSynced: {},
  toBeChangedProperty: null,
  alreadyAvailableItems: []
};
const subcustomerModal = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.OPEN_SUBCUSTOMER_MODAL: {
      return produce(state, (draftState) => {
        draftState.openSubcustomerModal = true;
        draftState.preFillText = payload.preFillText;
        draftState.toBeSynced = payload.toBeSynced;
        draftState.toBeChangedProperty = payload.toBeChangedProperty;
        if (payload.isCreatingNewSubSub) {
          draftState.isCreatingNewSubSub = payload.isCreatingNewSubSub;
        }
        if (payload.alreadyAvailableItems) {
          draftState.alreadyAvailableItems = payload.alreadyAvailableItems;
        }
      });
    }
    case constants.CLOSE_SUBCUSTOMER_MODAL: {
      return produce(state, (draftState) => initialState);
    }

    default: {
      return state;
    }
  }
};

export default subcustomerModal;
