import * as constants from 'appConstants';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';

const sortByNewest = (array) =>
  cloneDeep(array)
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    .reverse();

const prepRemoveCommentFromCollection = (idx, collection) => {
  const pre = collection.slice(0, idx);
  const post = collection.slice(idx + 1).map((comment) => {
    return {
      ...comment,
      position: comment.position - 1
    };
  });

  return { pre, post };
};

const repackageThread = (newEl, thread) => {
  return thread.map((el) => {
    if (el.id === newEl.id && el.parent_id === null) {
      return newEl;
    } else {
      return el;
    }
  });
};

export const initialState = {
  taskId: null,
  loadedCount: 0,
  totalCount: 0,
  taskDetailModalOpen: false,
  membersToNotify: [],
  commentThreadsAndMetadataSorted: [],
  // AY: isCreatingReply/isDeletingReply is necessary for adding/deleting a reply+rerendering the modal
  isCreatingReply: false,
  isDeletingReply: false
};

const taskDetail = (state = initialState, action) => {
  let newCommentThreadsAndMetadataSorted;
  let newMetadata;
  switch (action.type) {
    case constants.LOGOUT_USER:
    case constants.LOCATION_CHANGE: {
      return initialState;
    }
    case constants.FETCH_COMMENTS_AND_METADATA.SUCCESS:
      const {
        task_id,
        task_comments,
        loaded_count,
        total_count,
        metadata,
        members_to_notify
      } = action.payload.response;

      const oldComments = state.commentThreadsAndMetadataSorted.filter(
        (commentOrMeta) => commentOrMeta.body
      );
      const oldMetadata = state.commentThreadsAndMetadataSorted.filter(
        (commentOrMeta) => commentOrMeta.type
      );

      const newComments = task_comments || [];

      const uniqueComments = uniqBy([...oldComments, ...newComments], 'id');
      const uniqueMetadata = uniqBy([...oldMetadata, ...metadata], 'id');
      newCommentThreadsAndMetadataSorted = sortByNewest([
        ...uniqueComments,
        ...uniqueMetadata
      ]);

      return {
        ...state,
        taskId: task_id,
        loadedCount: loaded_count,
        totalCount: total_count,
        membersToNotify: members_to_notify,
        taskDetailModalOpen: true,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted
      };
    case constants.FLUSH_SELECTED_HOME_TASK:
    case constants.CLOSE_CLEAR_TASK_VIEW_MODAL:
      return {
        ...state,
        taskId: null,
        taskDetailModalOpen: false,
        commentThreadsAndMetadataSorted: [],
        membersToNotify: []
      };

    case constants.CREATE_COMMENT.REQUEST:
      return {
        ...state,
        isCreatingReply: true
      };
    case constants.WS_TASK_COMMENT: {
      const { payload } = action;
      const { userId, origin, ...comment } = payload;
      if (userId === origin) {
        return state;
      }
      const alreadyExists = state.commentThreadsAndMetadataSorted.find(
        (object) => object.id === comment.id
      );
      if (alreadyExists) {
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((object) =>
            object.id === comment.id
              ? { ...comment, replies: object.replies }
              : object
          );
      } else if (comment.parent_id) {
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((object) => {
            if (!object.type && object.id === comment.parent_id) {
              const replyExists = object.replies.find(
                (reply) => reply.id === comment.id
              );
              let replies;
              if (replyExists) {
                replies = object.replies.map((reply) =>
                  reply.id === comment.id ? comment : reply
                );
              } else {
                replies = [...object.replies, comment] || [];
              }
              return {
                ...object,
                replies
              };
            }
            return object;
          });
      } else {
        const newPayload = { ...payload, replies: [] };
        newCommentThreadsAndMetadataSorted = [
          newPayload,
          ...state.commentThreadsAndMetadataSorted
        ];
      }
      return {
        ...state,
        isCreatingReply: false,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted
      };
    }
    case constants.CREATE_COMMENT.SUCCESS:
      const newComment = action.payload.response;

      if (newComment.parent_id) {
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((object) => {
            if (!object.type && object.id === newComment.parent_id) {
              const replies = [...object.replies, newComment] || [];
              return {
                ...object,
                replies
              };
            }
            return object;
          });
      } else {
        newCommentThreadsAndMetadataSorted = [
          { ...newComment, replies: [] },
          ...state.commentThreadsAndMetadataSorted
        ];
      }
      return {
        ...state,
        isCreatingReply: false,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted
      };
    case constants.UPDATE_COMMENT.REQUEST:
      return {
        ...state
      };
    case constants.UPDATE_COMMENT.SUCCESS:
      const updatedComment = action.payload.response;
      if (!updatedComment.parent_id && !updatedComment.type) {
        // top level comment, find and replace body text
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((object) => {
            if (
              object.hasOwnProperty('body') &&
              object.id === updatedComment.id
            ) {
              return {
                ...object,
                body: updatedComment.body,
                attachments: updatedComment.attachments
              };
            }
            return object;
          });
      } else {
        // comment is a reply, find and replace body text
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((object) => {
            if (object.id === updatedComment.parent_id) {
              return {
                ...object,
                replies: object.replies.map((reply) =>
                  reply.id === updatedComment.id ? updatedComment : reply
                )
              };
            }
            return object;
          });
      }

      return {
        ...state,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted
      };

    case constants.DELETE_COMMENT.REQUEST:
      return {
        ...state,
        isDeletingReply: true
      };

    case constants.DELETE_COMMENT.SUCCESS:
      const { comment_id, parent_id } = action.payload.response;
      if (parent_id) {
        let newReplies;
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((object) => {
            if (object.id === parent_id && !object.type) {
              newReplies = object.replies.filter(
                (reply) => reply.id != comment_id
              );
              return {
                ...object,
                replies: newReplies
              };
            }
            return object;
          });
      } else {
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.filter(
            (object) => object.id != comment_id || object.type
          );
      }
      return {
        ...state,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted,
        isDeletingReply: false
      };

    case constants.DELETE_TASK_ATTACHMENT.SUCCESS: {
      const {
        id,
        entity_id, // entity_id is comment_id
        parent_id,
        entity_type // entity_type is comment_type
      } = action.payload.response;

      if (!parent_id) {
        // attachment is not attached to a reply
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted
            .map((comment) => {
              if (comment.hasOwnProperty('body') && comment.id === entity_id) {
                const newAttachments = comment.attachments.filter(
                  (attachment) => {
                    return attachment.id !== id;
                  }
                );
                return {
                  ...comment,
                  attachments: newAttachments
                };
              }
              return comment;
            })
            .filter((comment) => {
              if (comment.hasOwnProperty('body')) {
                return comment.body || comment.attachments.length;
              } else {
                return comment;
              }
            });
      } else {
        // attachment is on a reply
        newCommentThreadsAndMetadataSorted =
          state.commentThreadsAndMetadataSorted.map((comment) => {
            if (comment.hasOwnProperty('body') && comment.id === parent_id) {
              const newReplies = comment.replies
                .map((reply) => {
                  if (reply.id === entity_id) {
                    const newAttachments = reply.attachments.filter(
                      (attachment) => {
                        return attachment.id !== id;
                      }
                    );
                    return {
                      ...reply,
                      attachments: newAttachments
                    };
                  }
                  return reply;
                })
                .filter((reply) => {
                  return reply.body || reply.attachments.length;
                });
              return {
                ...comment,
                replies: newReplies
              };
            }
            return comment;
          });
      }

      return {
        ...state,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted
      };
    }

    case constants.MOVE_HOME_TASK.SUCCESS:
    case constants.MOVE_TASK.SUCCESS:
      newMetadata = action.payload.response.metadata || [];
      newCommentThreadsAndMetadataSorted = sortByNewest([
        ...state.commentThreadsAndMetadataSorted,
        ...newMetadata
      ]);

      return {
        ...state,
        commentThreadsAndMetadataSorted: newCommentThreadsAndMetadataSorted
      };
    default:
      return state;
  }
};

export default taskDetail;
