import theme from 'theme';
import styled, { css } from 'styled-components';

const BaseChartStyle = css`
  .recharts-cartesian-axis-tick-value {
    font-weight: 600;
    fill: ${theme.colors.colorCalendarGray};
    font-size: 9px;
  }
  .recharts-cartesian-axis-line {
    stroke: ${theme.colors.colorLightGray6};
    fill: ${theme.colors.colorLightGray8};
  }
  .yAxis .recharts-cartesian-axis-ticks {
    transform: translateX(-5px);
  }
  .xAxis .recharts-cartesian-axis-ticks {
    transform: translateY(5px);
  }
  .recharts-cartesian-grid line {
    stroke: ${theme.colors.colorTranslucentGray5};
  }
  .xAxis .recharts-cartesian-axis-line,
  .recharts-cartesian-grid-horizontal line {
    transform: scaleX(1.02) translateX(-1px);
  }
  .yAxis .recharts-cartesian-axis-line,
  .recharts-cartesian-grid-vertical line {
    transform: scaleY(1.05) translateY(-9px);
  }

  .bar-value-label {
    font-size: 11px;
    font-weight: 600;
  }
  .recharts-bar-rectangle {
    cursor: pointer;
  }
`;

export const WidgetChartWrapper = styled.div`
  .recharts-area-curve {
    stroke-width: 3px;
  }
  height: 100%;
  ${BaseChartStyle}
`;
