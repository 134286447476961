import React from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  closeAndClearTaskViewModal,
  flushSelectedHomeTask,
  setEditTaskId,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  fetchSingleTask,
  fetchAllProjects,
  fetchProjectById
} from 'actionCreators';
import { makeGetOwnTaskInfo, getSelectedProject } from 'selectors';
import Task from 'views/Home/Tasks/Task';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: absolute;
  left: calc(50% - 730px / 2);
`;

class TaskModal extends React.Component {
  state = {
    isDeleting: false
  };

  componentDidMount() {
    const { taskId, tempIds } = this.props;
    if (tempIds[taskId]) {
      return;
    }
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const {
      task,
      fetchTaskGroups,
      fetchAllProjects,
      fetchProjectById,
      tempIds,
      taskId
    } = this.props;
    if (task) {
      if (
        task.task_group_id &&
        task.task_group_id !== prevProps.task?.task_group_id
      ) {
        fetchTaskGroups({ taskGroupIds: [task.task_group_id] });
      }
      if (task.project_id && task.project_id !== prevProps.task?.project_id) {
        fetchProjectById(task?.project_id);
        fetchAllProjects({
          projectIds: [task.project_id]
        });
      }
      if (tempIds[taskId] || prevProps.taskId === this.props.taskId) {
        return;
      }
      this.loadData();
    }
  }

  componentWillUnmount() {
    const { flushSelectedHomeTask, setEditTaskId } = this.props;
    flushSelectedHomeTask();
    setEditTaskId(null);
  }

  loadData = () => {
    const {
      setEditTaskId,
      fetchCommentsAndMetadata,
      fetchSingleTask,
      taskId,
      fetchAllProjects,
      fetchTaskGroups,
      task,
      fetchProjectById
    } = this.props;
    const { isDeleting } = this.state;

    // temporary fix to prevent fetch data with tempId
    if (isNaN(Number(taskId))) return;

    if (isDeleting) {
      return;
    }
    fetchSingleTask({
      taskId
    });
    setEditTaskId(+taskId);
    fetchCommentsAndMetadata({
      taskId,
      taskType: 'projects',
      offset: 0,
      limit: 4
    });
    fetchAllProjects({
      offset: 0,
      limit: 30
    });
    if (task) {
      if (task.task_group_id) {
        fetchTaskGroups({ taskGroupIds: [task.task_group_id] });
      }
      if (task.project_id) {
        fetchProjectById(task.project_id);
        fetchAllProjects({
          projectIds: [task.project_id]
        });
      }
    }
  };

  toggle = (event) => {
    const { closeAndClearTaskViewModal } = this.props;
    // ignore calendar clicks
    if (
      (event &&
        event.target.className &&
        event.target.className.includes &&
        event.target.className.includes('rc-calendar-date')) ||
      (event &&
        event.target.offsetParent &&
        event.target.offsetParent.className &&
        event.target.offsetParent.className.includes &&
        (event.target.offsetParent.className.includes('modal-content') ||
          event.target.offsetParent.className.includes('rc-trigger-popup')))
    ) {
      return;
    }
    closeAndClearTaskViewModal();
  };

  handleDelete = () => {
    this.setState({ isDeleting: true });
    this.props.closeAndClearTaskViewModal();
  };

  resetState = () => {
    this.setState({ isDeleting: false });
  };

  render() {
    const { isOpen, taskId, selectedProject, task } = this.props;

    return (
      isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="task-modal"
          onClosed={this.resetState}
        >
          <Task
            taskId={taskId}
            disableOnClickOutside={true}
            isBatchSelected={false}
            taskIsEditing={true}
            isCreatingTask={false}
            alwaysRenderSchedule={true}
            alwaysRenderDue={true}
            isSelectedHomeTask
            excludeScrollbar={true}
            selectedProject={
              selectedProject?.id === task?.project_id ? selectedProject : {}
            }
            onDelete={this.handleDelete}
          />
        </Modal>
      )
    );
  }
}

const makeMapStateToProps = () => {
  const getTask = makeGetOwnTaskInfo();
  const mapStateToProps = (state, ownProps) => ({
    me: state.users.me,
    task: getTask(state, ownProps),
    selectedProject: getSelectedProject(state),
    tempIds: state.homeTasks.tempIds
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  setEditTaskId,
  fetchCommentsAndMetadata,
  fetchSingleTask,
  closeAndClearTaskViewModal,
  flushSelectedHomeTask,
  fetchTaskGroups,
  fetchAllProjects,
  fetchProjectById
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(TaskModal)
);
