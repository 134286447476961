import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  getSelectedTeamId,
  getMatchedRouteParams,
  getOnBoardView,
  getSplitFlags,
  getAddMembersFormModalType
} from 'selectors';
import { makeGetSuggestedAccountsForProjectsByIsoStateId } from 'SuggestionModule/selectors';

import { InviteRow, MemberListItem } from '..';
import { BlueSubmitButton, TextButtonWithBorder } from 'components/styles';
import SuggestionsTable from '../boardDisplay/SuggestionsTable';

import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import { makeMembersListWithSuggestionForEntity } from 'SuggestionModule/utils';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import {
  inviteMemberToTeam,
  inviteGuests
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { INVITE_MEMBER_TO_TEAM_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';

const emptyObj = {};

export const StyledBlueSubmitButton = styled(BlueSubmitButton)`
  width: 70px;
  height: 34px;
  font-size: 16px;
  border-radius: 4px;
  color: ${theme.colors.colorPureWhite};
  background: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  &.disabled {
    background: ${theme.colors.colorCalendarGray};
    border-color: ${theme.colors.colorCalendarGray};
  }
`;

export const StyledWhiteSubmitButton = styled(TextButtonWithBorder)`
  width: 70px;
  height: 34px;
  font-size: 17px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.colorLightGray6};
  color: ${theme.colors.colorMediumGray6AndAHalf};
  background: ${theme.colors.colorPureWhite};
  cursor: pointer;
`;

export const StyledTabContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 20px;
`;

export const StyledTab = styled.div`
  height: 28px;
  width: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.isActive ? theme.colors.colorCalendarBlue : 'transparent'};
  color: ${(props) =>
    props.isActive
      ? theme.colors.colorPureWhite
      : theme.colors.colorCalendarGray};
  font-size: 14px;
  ${(props) => props.isActive && 'font-weight: 600;'}
  cursor: pointer;
  margin: 0px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 0 7px 2px;
  &.suggestion {
    width: 108px;
  }
`;

const getIsNotInBoard = ({ membersNotInBoardSet, member, me }) =>
  membersNotInBoardSet.has(member.account.id) && member.account.id !== me.id;

const getAvailableMembersToAddToUse = ({
  modalType,
  suggestedAccountsForProjects,
  projectId,
  availableMembersToAdd
}) => {
  if (modalType === MODAL_TYPE.PROJECT) {
    const { membersListWithSuggestions } =
      makeMembersListWithSuggestionForEntity({
        suggestedAccountsForEntitiesHash: suggestedAccountsForProjects,
        entityId: projectId,
        membersList: availableMembersToAdd,
        isIncludeProjectGuests: false
      });

    return membersListWithSuggestions;
  }

  // Other modal type (ie Board)
  return availableMembersToAdd;
};

export const BulkAddMemberInputContainer = styled.div`
  width: 100%;
  padding: 0 57px 0 50px;
`;

class BulkAddMemberList extends React.Component {
  state = {
    membersAdded: []
  };

  componentDidUpdate(prevProps) {
    rebuildTooltip();
  }

  getPermissions = () => {
    const {
      matchedParams: { projectId },
      selectedTeamId
    } = this.props;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  bulkAddMembers = (groupId) => () => {
    const { addBulkMembersToSelection, closeBulkAddMembersForm, projectId } =
      this.props;
    const memberIds = this.getMembersAdded().map((member) => member.account.id);
    if (projectId) {
      const permissions = this.getPermissions();
      addBulkMembersToSelection(groupId, memberIds, permissions);
    } else {
      addBulkMembersToSelection(groupId, memberIds);
    }
    closeBulkAddMembersForm();
  };

  addMemberLocally = (member) => {
    this.props.populateBulkAddMembers();
    this.setMembersAdded([...this.getMembersAdded(), member]);
  };

  setMembersAdded = (members) => this.props.setMembersAdded(members);

  removeMemberLocally = (memberToRemove) => {
    const members = this.getMembersAdded().filter(
      (member) => member.id !== memberToRemove.id
    );
    if (members.length === 0) {
      this.props.emptyBulkAddMembers();
    }
    this.setMembersAdded(members);
  };

  isMemberMatching = (member, value) => {
    const { name, initials } = member.account;

    return (
      (name && name.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
      (initials && initials.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    );
  };

  handleKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
    }
  };

  getMembersAdded = () => {
    return this.props.membersAdded;
  };

  getCanInviteToTeam = () => {
    const { checkPermission, inviteMemberToTeam } = this.props;
    return checkPermission(inviteMemberToTeam, {
      permissions: this.getPermissions()
    });
  };

  getCanInviteGuests = () => {
    const { checkPermission, inviteGuests } = this.props;
    return checkPermission(inviteGuests, {
      permissions: this.getPermissions()
    });
  };

  render() {
    const membersAdded = this.getMembersAdded();
    const {
      inputValue,
      membersNotInBoard = [],
      membersNotInGroup = [],
      changeInputValue,
      addMemberToSelection,
      addMemberToProject,
      me,
      groupId,
      projectId,
      toggleInviteForm,
      showSuggestions,
      boardMembers,
      suggestedAccountsForProjects = emptyObj,

      modalType
    } = this.props;

    const membersNotInBoardSet = new Set(
      membersNotInBoard.map((member) => member.account.id)
    );

    const membersAddedSet = new Set(
      membersAdded.map((memberAdded) => memberAdded.account.id)
    );

    const availableMembersToAdd = membersNotInGroup.filter(
      (member) => !membersAddedSet.has(member.account.id)
    );

    const availableMembersToAddToUse = getAvailableMembersToAddToUse({
      suggestedAccountsForProjects,
      availableMembersToAdd,
      projectId,
      modalType
    });

    const canInviteToTeam = this.getCanInviteToTeam();
    const canInviteGuests = this.getCanInviteGuests();
    const canInvite = canInviteToTeam || canInviteGuests;

    return (
      <DynamicModuleLoader modules={[getBudgetModule(), getSettingsModule()]}>
        {showSuggestions ? (
          <SuggestionsTable boardMembers={boardMembers} />
        ) : (
          <div className="form-field-full">
            <div
              className={cn('insert-delete-member-list-auto-complete', {
                'not-new-project': projectId || groupId
              })}
            >
              <ul>
                {/* Members to be added rows */}
                {!!membersAdded.length &&
                  membersAdded.map((member) => (
                    <MemberListItem
                      member={member}
                      removeMemberLocally={this.removeMemberLocally}
                      newMember={true}
                      key={member.account.id}
                      me={me}
                      changeInputValue={changeInputValue}
                      groupId={groupId}
                      projectId={projectId}
                      className="added-member"
                    />
                  ))}
                {/* {membersAdded.length < 1 && (
                  <InviteRow
                    toggleInviteForm={canInvite ? toggleInviteForm : noop}
                    borderTop={membersAdded.length}
                    tooltip={canInvite ? '' : INVITE_MEMBER_TO_TEAM_TIP}
                  />
                )} */}
                {/*  /** Show all members  */}
                {!inputValue && availableMembersToAddToUse
                  ? availableMembersToAddToUse.map((member) => {
                      const notInBoard = getIsNotInBoard({
                        membersNotInBoardSet,
                        member,
                        me
                      });
                      return (
                        <MemberListItem
                          key={member.account.id}
                          me={me}
                          member={member}
                          notInBoard={notInBoard}
                          addMemberToSelection={addMemberToSelection}
                          addMemberToProject={addMemberToProject}
                          changeInputValue={changeInputValue}
                          groupId={groupId}
                          projectId={projectId}
                          addMemberLocally={this.addMemberLocally}
                        />
                      );
                    })
                  : /** Filter by search value  */
                    availableMembersToAddToUse
                      .filter((member) =>
                        this.isMemberMatching(member, inputValue)
                      )
                      .map((member) => {
                        const notInBoard = getIsNotInBoard({
                          membersNotInBoardSet,
                          member,
                          me
                        });
                        return (
                          <MemberListItem
                            key={member.account.id}
                            me={me}
                            member={member}
                            notInBoard={notInBoard}
                            addMemberToSelection={addMemberToSelection}
                            addMemberToProject={addMemberToProject}
                            changeInputValue={changeInputValue}
                            groupId={groupId}
                            projectId={projectId}
                            addMemberLocally={this.addMemberLocally}
                          />
                        );
                      })}
              </ul>
            </div>
          </div>
        )}
      </DynamicModuleLoader>
    );
  }
}

BulkAddMemberList.propTypes = {
  inputValue: PropTypes.string.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  addMemberToSelection: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object,
  openTeamMemberModal: PropTypes.func,
  closeModal: PropTypes.func
};

const makeMapStateToProps = () => {
  const getSuggestedAccountsForProjectsByIsoStateId =
    makeGetSuggestedAccountsForProjectsByIsoStateId();

  const mapStateToProps = (state, ownProps) => ({
    selectedTeamId: getSelectedTeamId(state),
    matchedParams: getMatchedRouteParams(state),
    isOnBoardView: getOnBoardView(state),
    suggestedAccountsForProjects: getSuggestedAccountsForProjectsByIsoStateId(
      state,
      {
        isoStateId: ownProps.projectId
      }
    ),
    modalType: getAddMembersFormModalType(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  inviteMemberToTeam,
  inviteGuests
};

export default compose(
  withPermissionsCheck,
  withRouter
)(connect(makeMapStateToProps, mapDispatchToProps)(BulkAddMemberList));
