import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import TaskSort from './TaskSort';
import { connect } from 'react-redux';
import {
  getTaskColumnOrderWithSelection,
  getMatchedRouteParams,
  getIsOnProjectView,
  getCurrentFilter
} from 'selectors';

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  margin-bottom: 1px;
  padding-left: ${(props) => (props.isHomeIncomplete ? '7px' : 0)};
`;

const SortWrapper = styled.div`
  display: flex;
`;

class TaskListSortContainer extends Component {
  renderSortHeader = (taskProperty) => {
    return <TaskSort taskProperty={taskProperty} key={taskProperty} />;
  };

  render() {
    const {
      taskColumnOrder,
      setSortHeaderHovered,
      unsetSortHeaderHovered,
      matchedParams: { projectId },
      collapsed,
      taskFilter
    } = this.props;

    return (
      <DropdownWrapper
        className="big-wrapper"
        projectId={projectId}
        onMouseEnter={setSortHeaderHovered}
        onMouseLeave={unsetSortHeaderHovered}
        hide={collapsed}
        isHomeIncomplete={
          taskFilter.section === 'My Tasks' && taskFilter.state === 'incomplete'
        }
      >
        <SortWrapper className="wrapper-div">
          {taskColumnOrder.map(this.renderSortHeader)}
        </SortWrapper>
      </DropdownWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  taskColumnOrder: getTaskColumnOrderWithSelection(state),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  taskFilter: getCurrentFilter(state)
});

export default withRouter(connect(mapStateToProps)(TaskListSortContainer));
