import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PinIcon = ({
  className,
  color = '#4F4F4F',
  fill = 'none',
  width = 11,
  height = 17,
  style
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 11 17"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.899805 8.64987V8.98402C0.899805 9.37933 1.22027 9.6998 1.61559 9.6998H8.98402C9.37933 9.6998 9.6998 9.37934 9.6998 8.98402V8.64987C9.6998 8.43909 9.52894 8.26823 9.31816 8.26823C8.93926 8.26823 8.61766 7.99039 8.56264 7.6155L9.15629 7.52838C9.16807 7.6087 9.23698 7.66823 9.31816 7.66823C9.86031 7.66823 10.2998 8.10772 10.2998 8.64987V8.98402C10.2998 9.71071 9.71071 10.2998 8.98402 10.2998H7.0678V10.3175L7.06012 10.3508L5.67094 16.3705H5.0863L3.69712 10.3508L3.68814 10.3118L3.6886 10.2998H1.61559C0.888904 10.2998 0.299805 9.71071 0.299805 8.98402V8.64987C0.299805 8.10772 0.739302 7.66823 1.28145 7.66823C1.36263 7.66823 1.43154 7.6087 1.44332 7.52837L2.10118 3.0459C2.11004 2.98554 2.06295 2.93184 2.00259 2.92298C1.555 2.85733 1.2089 2.41484 1.2089 1.87875V1.61559C1.2089 0.888904 1.71766 0.299805 2.34526 0.299805H8.25435C8.88195 0.299805 9.39071 0.888904 9.39071 1.61559V1.87875C9.39071 2.41484 9.04461 2.85733 8.59702 2.92298C8.53665 2.93184 8.48957 2.98554 8.49843 3.0459L9.15629 7.52838L8.56264 7.6155L7.90479 3.13303L7.90479 3.13303C7.83981 2.6903 8.17898 2.37788 8.50994 2.32934C8.60386 2.31556 8.79071 2.18102 8.79071 1.87875V1.61559C8.79071 1.1347 8.47103 0.899805 8.25435 0.899805H2.34526C2.12858 0.899805 1.8089 1.1347 1.8089 1.61559V1.87875C1.8089 2.18102 1.99575 2.31556 2.08967 2.32934C2.42064 2.37788 2.75979 2.69031 2.69482 3.13303L2.03697 7.6155C1.98195 7.99038 1.66035 8.26823 1.28145 8.26823C1.07067 8.26823 0.899805 8.43909 0.899805 8.64987ZM4.30113 10.2998L5.37862 14.9689L6.4561 10.2998H4.30113Z"
      fill={color}
    />
  </SvgIcon>
);

export default PinIcon;
