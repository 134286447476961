import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import moment from 'moment';
import cn from 'classnames';
import { timelineKeys, VIEW_BY } from 'appConstants/workload';
import { triggerTasksAttributesUpdate } from 'actionCreators';
import { getAuthToken } from 'selectors';
import { useDispatch, useSelector } from 'react-redux';

export default function DroppableLayer({
  getLayerRootProps,
  formattingUtils,
  steps,
  columnWidths,
  monthColumnWidths,
  group,
  viewBy,
  zoom,
  deserializedGroupId
}) {
  const token = useSelector(getAuthToken);
  const dispatch = useDispatch();
  const ungrouped = viewBy === VIEW_BY.NONE;
  const isByProject = viewBy === VIEW_BY.PROJECTS;

  const handleDrop = useCallback(
    ({ id, date }) => {
      const body = {
        task_ids: [id],
        schedule_start: date,
        schedule_end: moment(date).add(2, 'days').format('YYYY-MM-DD')
      };
      const viewByKeys = timelineKeys[viewBy];
      if (viewByKeys && !ungrouped && !isByProject) {
        const itemProperty = viewByKeys.itemGroupKey;
        const groupValue = viewByKeys.groupIdKey;
        if (itemProperty && groupValue) {
          body[itemProperty] = group[groupValue];
        }
      }
      dispatch(
        triggerTasksAttributesUpdate({
          token,
          body
        })
      );
    },
    [dispatch, group, isByProject, token, ungrouped, viewBy]
  );
  return (
    <div
      className={'workload-row-renderer project-row-renderer'}
      {...getLayerRootProps({ style: { height: '100vh' } })}
    >
      {steps.map((slot, index) => {
        const { left, isBorderRight, committedFormat } = formattingUtils[index];
        const width = columnWidths[index];
        return (
          <DropLayer
            key={committedFormat}
            date={committedFormat}
            handleDrop={handleDrop}
            left={left}
            columnWidth={width}
            isBorderRight={isBorderRight}
            ungrouped={ungrouped}
            group={group}
            isByProject={isByProject}
            deserializedGroupId={deserializedGroupId}
          />
        );
      })}
    </div>
  );
}

const DropLayer = React.memo(UnmemoizedDropLayer);

function UnmemoizedDropLayer({
  date,
  handleDrop,
  left,
  columnWidth,
  isBorderRight,
  ungrouped,
  group,
  isByProject,
  deserializedGroupId
}) {
  const [{ canDrop }, drop] = useDrop({
    accept: ['task'],
    drop: (item) => {
      const { id } = item;
      handleDrop({ id, date });
    },
    canDrop: (item) => {
      if (!isByProject || !deserializedGroupId) {
        // todo: make this generic
        return true;
      }
      return `${item.project_id}` === `${deserializedGroupId}`;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  return (
    <div
      ref={drop}
      className={cn({
        'project-row-cell-container': group?.isRoot
      })}
      style={{
        position: 'absolute',
        left,
        width: columnWidth,
        backgroundColor: canDrop ? 'white' : 'transparent',
        minHeight: ungrouped ? '70vh' : '100%',
        borderRight: isBorderRight ? '1px solid #ccc' : '1px solid #e4e4e4',
        overflow: 'hidden'
      }}
    ></div>
  );
}
