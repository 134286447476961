import React from 'react';
import { useSelector } from 'react-redux';
import ActivityItemHeader from 'views/activityFeed/ActivityItemHeader';
import { ACTION_TYPES } from 'appConstants/actionDescription';
import styled from 'styled-components';

const StyledHeader = styled.div`
  .activity-item-header {
    font-size: 16px;
    display: flex;
  }
`;
const SentNotificationHeader = () => {
  const { isPersonal, displayData, actor } = useSelector(
    (state) => state.workloadEvents
  );

  return (
    <StyledHeader>
      <ActivityItemHeader
        actor={actor}
        displayData={displayData}
        actionType={
          isPersonal
            ? ACTION_TYPES.SEND_SCHEDULED_EVENT
            : ACTION_TYPES.FOLLOWING_SCHEDULED_EVENT
        }
      />
    </StyledHeader>
  );
};

export default SentNotificationHeader;
