import { initialState } from 'reducers/triggers';
import { createSelector } from 'reselect';

export const getTriggerState = (state) => state.triggers || initialState;

export const getTriggerIdsByActionType = createSelector(
  getTriggerState,
  (state) => state.triggerIdsByActionType
);

export const getShouldTrigger = createSelector(
  getTriggerState,
  (state) => state.shouldTrigger
);
