import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  getSelectedProject,
  makeGetProjectManagersByAccountId,
  makeGetMemberByAssigneeId,
  getTaskAccountFilter,
  makeGetProjectBasicInfoById
} from 'selectors';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { getMyUserId } from 'UsersModule/selectors';
import {
  getContractorsByAccountId,
  getGuestByAccountId
} from 'PermissionsModule/selectors';

import MemberSelector from 'views/projectPlanner/workloadBarModal/MemberSelector';

import isEmpty from 'lodash/isEmpty';

import { withRouter } from 'react-router-dom';

import PMDot from 'views/memberDisplay/PMDot';

import { RoleIndicator, Wrapper, TaskAssigneeCircle } from './styles';
import { NewTaskProperty } from 'views/Home/Tasks/styles';
import GuestIcon from 'icons/GuestIcon';
import ContractorIcon from 'icons/ContractorIcon';

const noop = () => {};

const ModalWrapper = (props) =>
  props.isSelectedHomeTask ? (
    <>{props.children} </>
  ) : (
    <NewTaskProperty
      $taskIsEditing={props.isEditing}
      $isTaskModal={props.isSelectedHomeTask}
      $showOnHover={!props.projectDetailView}
      style={{ display: 'block', opacity: 1 }}
    >
      {props.children}
    </NewTaskProperty>
  );

class TaskAssignedByContent extends Component {
  getSelectedAssigneeProperty = (assigneeId, key) => {
    const { teamMembersHash } = this.props;
    if (assigneeId === null || isEmpty(teamMembersHash)) return null;
    const selectedAssignee = teamMembersHash[assigneeId];
    return get(selectedAssignee, `account.${key}`);
  };

  getTooltipContent = (assigneeId) => {
    if (Array.isArray(assigneeId)) {
      return `Assigned by${assigneeId
        .slice(0, -1)
        .map(
          (id) => ` ${this.getSelectedAssigneeProperty(id, 'name')}`
        )} and ${this.getSelectedAssigneeProperty(
        assigneeId[assigneeId.length - 1],
        'name'
      )}`;
    }
    const name = this.getSelectedAssigneeProperty(assigneeId, 'name');
    if (name) {
      return `Assigned by ${name}`;
    } else {
      return '';
    }
  };

  renderMember = ({ onClick }) => {
    return null;
    // implement when adding assigned by column on projects
    // see TaskAssigneeDropdown/index.jsx for pattern
  };

  renderAssignedToMeBy = ({ onClick }) => {
    const {
      myId,
      task,
      selectedAccountIds,
      teamMembersHash,
      projectManagersByAccountIds,
      guestsByAccountIds,
      contractorsByAccountIds,
      openTaskModal
    } = this.props;

    const taskAssignment = task.assignments?.find(
      (assignment) => assignment.assignee_id === myId
    );
    if (!taskAssignment) {
      return null;
    }

    const accountId = taskAssignment.assigner_id;
    if (accountId === myId || !accountId) return null;
    const id = accountId;
    const isAssignedToMe = id === myId;
    const teamMembership = teamMembersHash[id];
    const taskAssigneeIconContent = this.getSelectedAssigneeProperty(
      id,
      'initials'
    );
    const memberIsPM = projectManagersByAccountIds[id];
    const memberIsGuest = guestsByAccountIds[id];
    const memberIsContractor = contractorsByAccountIds[id];
    const IndicatorComponent = memberIsGuest
      ? GuestIcon
      : memberIsContractor
      ? ContractorIcon
      : undefined;

    return (
      <TaskAssigneeCircle
        preventHover
        key={id}
        taskAssigneeIconContent={taskAssigneeIconContent}
        onClick={openTaskModal}
        isAssignedToMe={isAssignedToMe}
        data-tip={this.getTooltipContent(id)}
        data-for={`app-tooltip`}
        data-effect="solid"
        data-class="task-assignee-tooltip"
        originType="teamMembership"
        id={teamMembership?.id}
        selected={selectedAccountIds.includes(id)}
        isMultiAssign
      >
        {memberIsPM && <PMDot />}
        {taskAssigneeIconContent}
        {IndicatorComponent && (
          <RoleIndicator>
            <IndicatorComponent width={13} height={13} />
          </RoleIndicator>
        )}
      </TaskAssigneeCircle>
    );
  };

  render() {
    const {
      isOnTaskModal = false,
      project,
      projectInfo,
      projectId,
      task,
      isEditing,
      projectDetailView,
      isSelectedHomeTask
    } = this.props;

    const memberRenderer = projectDetailView
      ? this.renderMember
      : this.renderAssignedToMeBy;

    return (
      <ModalWrapper
        isEditing={isEditing}
        isSelectedHomeTask={isSelectedHomeTask}
        projectDetailView={projectDetailView}
      >
        <Wrapper
          isOnTaskModal={isOnTaskModal}
          style={{
            width: projectDetailView
              ? (Math.max(task?.assignee_ids?.slice(0, 3).length, 1) - 1) * 22 +
                28
              : 28
          }}
        >
          <MemberSelector
            renderMember={memberRenderer}
            renderSelect={memberRenderer}
            projectId={projectId}
            boardId={projectInfo?.board_id || project?.board_id}
            isListItem
            openDropdown={noop}
            popoverClassName="member-select-dropdown"
          />
        </Wrapper>
      </ModalWrapper>
    );
  }
}

const projectIdGetter = (state, ownProps) => ownProps.project?.id;
const makeMapStateToProps = () => {
  const getProjectManagerAccountIds = makeGetProjectManagersByAccountId({
    projectIdGetter
  });
  const getMemberByAssigneeId = makeGetMemberByAssigneeId();
  const getProjectInfo = makeGetProjectBasicInfoById();

  const mapStateToProps = (state, ownProps) => ({
    myId: getMyUserId(state),
    project: getSelectedProject(state),
    projectInfo: getProjectInfo(state),
    teamMembersHash: getTeamMembersHash(state),
    projectManagersByAccountIds: getProjectManagerAccountIds(state, ownProps),
    guestsByAccountIds: getGuestByAccountId(state),
    contractorsByAccountIds: getContractorsByAccountId(state),
    selectedAccountIds: getTaskAccountFilter(state),
    teamMembership: getMemberByAssigneeId(state, ownProps)
  });
  return mapStateToProps;
};

export default withRouter(connect(makeMapStateToProps)(TaskAssignedByContent));
