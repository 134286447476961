import React from 'react';
import { connect } from 'react-redux';
import { makeGetEditOrViewIdsHash } from 'selectors';
import { StyledEyeIcon, VisibilityText } from './styles';
import EyeIcon from 'images/eye.svg';
class BudgetVisibilityIndicator extends React.Component {
  render() {
    const { handleClick, editOrViewIds } = this.props;
    const numMembers = Object.keys(editOrViewIds).length;
    const isPlural = numMembers !== 1;
    return (
      <VisibilityText
        data-for="app-tooltip"
        data-class="center"
        data-effect="solid"
        data-html
        data-tip={`Click to view the ${numMembers} ${
          isPlural ? 'people' : 'person'
        } who can view this Budget.`}
        className="visibility-text"
        onClick={handleClick}
      >
        {numMembers} <StyledEyeIcon src={EyeIcon} />
      </VisibilityText>
    );
  }
}

const makeMapStateToProps = () => {
  const getEditOrViewIdsHash = makeGetEditOrViewIdsHash();
  const mapStateToProps = (state, ownProps) => ({
    editOrViewIds: getEditOrViewIdsHash(state, ownProps)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(BudgetVisibilityIndicator);
