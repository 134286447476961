import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import OptionsDropdown from 'components/options/OptionsDropdown';
import useGoToProject from 'appUtils/hooks/navigation/useGoToProject';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import DeleteIcon from 'icons/DeleteIcon';

const ScopeMenu = ({
  projectId,
  overrideClickHandlers = emptyObject,
  options = defaultOptions
}) => {
  const { navToProject } = useGoToProject({
    projectId
  });

  const clickHandlers = {
    [SCOPE_MENU_OPTIONS.GO_TO_PROJECT]: navToProject,
    [SCOPE_MENU_OPTIONS.DELETE]:
      overrideClickHandlers[SCOPE_MENU_OPTIONS.DELETE] || (() => {}) // todo
  };

  const handleSelect = (option) => {
    clickHandlers[option]?.();
  };

  return (
    <StyledScopeMenu>
      <OptionsDropdown
        onSelect={handleSelect}
        options={options}
        optionsHash={optionsHash}
        renderToggle={(isOpen) => (
          <ThreeDotHorizontal className="three-dot" active={isOpen} />
        )}
      />
    </StyledScopeMenu>
  );
};

export default React.memo(ScopeMenu);

/* ------------------------------------ - ----------------------------------- */

export const SCOPE_MENU_OPTIONS = {
  GO_TO_PROJECT: 'GO_TO_PROJECT',
  DELETE: 'DELETE'
};

const emptyObject = {};

const optionsHash = {
  [SCOPE_MENU_OPTIONS.GO_TO_PROJECT]: {
    value: SCOPE_MENU_OPTIONS.GO_TO_PROJECT,
    label: (
      <>
        <GoToProjectIcon className="go-to-icon" />
        Go to Project
      </>
    )
  },
  [SCOPE_MENU_OPTIONS.DELETE]: {
    value: SCOPE_MENU_OPTIONS.DELETE,
    label: (
      <>
        <DeleteIcon className="delete-icon" />
        Delete Scope
      </>
    )
  }
};
const defaultOptions = Object.values(SCOPE_MENU_OPTIONS);

const StyledScopeMenu = styled.div`
  .dropdown-item {
    padding: 4px 15px;
  }
  .go-to-icon {
    path {
      fill: ${theme.colors.colorMediumGray9};
    }
  }
  .delete-icon {
    margin: 0 7px 0 5px;
    transform: scale(0.9);
  }
  .three-dot {
    margin-left: 10px;
    transform: scale(0.9);
    path {
      fill: ${theme.colors.colorMediumGray7};
    }
  }
  &:hover {
    .three-dot {
      path {
        fill: ${theme.colors.colorRoyalBlue};
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;
