import styled from 'styled-components';
import { RowRendererProps } from '../types';

export const MemberRowEmailCell = ({ row: { original } }: RowRendererProps) => (
  <EmailCellContainer>
    <EmailText>{original.rowData.teamMember?.account.email}</EmailText>
  </EmailCellContainer>
);

const EmailCellContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const EmailText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
`;
