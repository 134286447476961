import { useRef } from 'react';
import { useIntersection } from 'react-use';

export const useVisibility = ({
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }
}: {
  options?: Parameters<typeof useIntersection>[1];
} = {}) => {
  const visibilityRef = useRef(null);
  const intersection = useIntersection(visibilityRef, options);

  const intersectionRatio = intersection?.intersectionRatio || 0;

  return {
    visibilityRef,
    isVisible: intersectionRatio > 0,
    isFullyVisible: intersectionRatio === 1,
    intersectionRatio
  };
};
