import { isActivityPhase, Phase } from 'ProjectsModule/phases/models/phase';
import { ActivityPhase } from 'ActivityPhaseModule/models/activityPhase';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';

export const getIsPhaseLikeDefault = (
  phase: PhaseWithDefault | ActivityPhase | undefined
): boolean =>
  (phase && isActivityPhase(phase)
    ? phase?.is_default
    : phase?.is_default_phase || phase?.is_main_like_default) ?? false;

export const checkPhaseHasActivityPhase = (
  phase: PhaseWithDefault | undefined
) => {
  return phase ? phase.activity_order.length > 0 : false;
};

/**
 * Get the total membership counts of a phase, that is not an unassigned role
 */
export const getNonUnassignedPhaseMembershipCounts = (
  phase: PhaseWithDefault | Phase | undefined
) => {
  return phase
    ? phase.phase_memberships.filter(
        (phaseMembership) => phaseMembership.account_id
      ).length
    : 0;
};

export const getDefaultActivityPhase = (
  phase: PhaseWithDefault | Phase | undefined
) => {
  return phase
    ? phase.activity_phases.find((activityPhase) => activityPhase.is_default)
    : undefined;
};
