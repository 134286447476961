import React from 'react';
import { connect } from 'react-redux';
import theme from 'theme';
import {
  getSortedTeamMembers,
  getMe,
  makeGetProjectBasicInfoById
} from 'selectors';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import {
  ListMemberContainer,
  ListMemberName,
  ListAddText,
  RemoveText,
  ListNameAndTextContainer
} from './styles';
import {
  CloseIconContainer,
  StyledDoneButton
} from 'BudgetModule/components/BudgetModal/styles';
import LightBulbIcon from 'icons/LightBulbIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import withAccountsSuggestionForEntities from 'hocs/withAccountsSuggestionForEntities';

import { membershipLevels } from './shared/constants';

const noop = () => {};

const byAccountId = (item) => item.account && item.account.id;

const copy = {
  headerInitial: 'Add Member',
  searchPlaceholder: 'Search Member or select below'
};
const scopeText = {
  [membershipLevels.activity]: 'Add To Scope',
  [membershipLevels.phase]: 'Add To Work Category, and Scope',
  [membershipLevels.project]: 'Add To Phase, Work Category, and Scope',
  [membershipLevels.board]: 'Add To Project, Phase, Work Category, and Scope',
  [membershipLevels.team]:
    'Add to Portfolio, Project, Phase, Work Category, and Scope'
};
const activityText = {
  [membershipLevels.phase]: 'Add To Work Category',
  [membershipLevels.project]: 'Add To Phase, and Work Category',
  [membershipLevels.board]: 'Add To Project, Phase, and Work Category',
  [membershipLevels.team]: 'Add to Portfolio, Project, Phase, and Work Category'
};
const phaseText = {
  [membershipLevels.project]: 'Add To Phase',
  [membershipLevels.board]: 'Add To Project, and Phase',
  [membershipLevels.team]: 'Add to Portfolio, Project, and Phase'
};
const projectText = {
  [membershipLevels.board]: 'Add To Project',
  [membershipLevels.team]: 'Add to Portfolio and Project'
};
const boardText = {
  [membershipLevels.team]: 'Add To Portfolio'
};

const membershipTexts = {
  [membershipLevels.scope]: scopeText,
  [membershipLevels.activity]: activityText,
  [membershipLevels.phase]: phaseText,
  [membershipLevels.project]: projectText,
  [membershipLevels.board]: boardText
};

// Manage num of rows and row height in the dropdown
const itemHeight = 50;
const numRows = 4;
// + itemHeight / 2 to display the last row half cutoff, by design
const defaultListHeight = numRows * itemHeight + itemHeight / 2;

class MembersDropdown extends React.Component {
  state = {
    selectedMember: null,
    joinModalOpen: false
  };

  renderAddText = (item) => {
    const { membershipLevel } = this.props;
    const mostSpecificMembershipLevel =
      this.getMostSpecificMembershipLevel(item);
    const textToUse =
      membershipTexts[membershipLevel]?.[mostSpecificMembershipLevel];

    return textToUse || null;
  };

  getMostSpecificMembershipLevel = (item) => {
    const {
      projectMemberIds,
      boardMemberIds,
      phaseMemberIds,
      activityMemberIds,
      scopeMemberIds
    } = this.props;

    const memberId = item?.account?.id;
    if (scopeMemberIds[memberId]) {
      return membershipLevels.scope;
    } else if (activityMemberIds?.[memberId]) {
      return membershipLevels.activity;
    } else if (phaseMemberIds[memberId]) {
      return membershipLevels.phase;
    } else if (projectMemberIds[memberId]) {
      return membershipLevels.project;
    } else if (boardMemberIds[memberId]) {
      return membershipLevels.board;
    } else return membershipLevels.team;
  };

  isPrimaryMember = (item) => {
    const {
      projectMemberIds,
      boardMemberIds,
      phaseMemberIds,
      activityMemberIds,
      scopeMemberIds,
      membershipLevel
    } = this.props;
    const memberId = item?.account?.id;
    const membershipChecks = {
      scope: {
        ...scopeMemberIds,
        ...activityMemberIds,
        ...phaseMemberIds,
        ...projectMemberIds
      },
      activity: {
        ...activityMemberIds,
        ...phaseMemberIds,
        ...projectMemberIds
      },
      phase: { ...phaseMemberIds, ...projectMemberIds },
      project: projectMemberIds,
      board: boardMemberIds
    };
    if (!memberId || !membershipChecks[membershipLevel]) {
      return true;
    }
    return membershipChecks[membershipLevel][memberId];
  };

  isItemInactive = (item) => !this.isPrimaryMember(item);
  onFooterClick = () => {
    this.props.onFooterClick();
  };

  renderItem = ({ item, selectCallback }) => {
    const { me, addedMembersByAccountId, addTextClassName } = this.props;

    if (item.unassigned) {
      return this.renderUnassigned({ item, selectCallback });
    }

    const isAdded = !!addedMembersByAccountId[item?.account?.id];

    const showSuggestionIcon = item.isSuggestedMember && !isAdded;

    rebuildTooltip();

    return (
      <ListMemberContainer isAdded={isAdded}>
        <MemberInitials
          projectMembers={true}
          member={item}
          classes={cn('item-account selected', {
            'small-logged-member': item.account.id === me.id,
            'small-regular-member': item.account.id !== me.id
          })}
          idx={'idx-placeholder'}
          isOnTaskModal
        />
        <ListNameAndTextContainer>
          <ListMemberName>{item.account.name} </ListMemberName>
          <ListAddText className={addTextClassName}>
            {!isAdded && this.renderAddText(item)}
            {showSuggestionIcon && (
              <span
                data-for="app-tooltip"
                data-tip="Suggested Member"
                data-effect="solid"
                data-class="blue-dark-tooltip"
                data-html
              >
                <LightBulbIcon
                  fill={theme.colors.colorMediumPurple2}
                  height={11}
                />
              </span>
            )}
          </ListAddText>
        </ListNameAndTextContainer>
        {isAdded && <RemoveText>Remove</RemoveText>}
      </ListMemberContainer>
    );
  };

  handleSelect = (e, { item, selectCallback }) => {
    const { handleSelect } = this.props;
    e.preventDefault();
    e.stopPropagation();

    // used in bulk selector, delegate selection handling to bulk selector
    handleSelect(item);
  };

  handleDone = (e) => {
    this.props.handleDone?.(e);
    this.props.handleClose?.(e);
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item: item.account,
      filterKeysArray: ['name', 'initials']
    });

  renderHeaderButton = () => {
    const { addedMembersByAccountId } = this.props;

    return (
      <CloseIconContainer>
        <StyledDoneButton
          className="add-button"
          onClick={this.props.handleDone}
        >
          {isEmpty(addedMembersByAccountId) ? 'Done' : 'Add'}
        </StyledDoneButton>
      </CloseIconContainer>
    );
  };

  render() {
    const {
      members,
      target,
      footerInitialCopy,
      hideFooter,
      handleClose,
      mediumWidth,
      isOnPlanner,
      isOnBudget,
      isLoading,
      renderHeader,
      renderHeaderButton,
      listWidth,
      listHeight,
      popoverClassName,
      searchPlaceholder,
      popoverPlacement
    } = this.props;

    return (
      <>
        <MultiStepFlyout
          copy={{
            ...copy,
            footerInitial: footerInitialCopy,
            searchPlaceholder
          }}
          items={members}
          getItemId={byAccountId}
          idKey="id"
          renderItem={this.renderItem}
          handleSelect={this.handleSelect}
          handleSubmit={this.handleSubmit}
          renderHeader={renderHeader}
          popoverPlacement={popoverPlacement}
          renderHeaderButton={renderHeaderButton || this.renderHeaderButton}
          itemFilter={this.itemFilter}
          isWhite
          searchEnabled
          editDisabled
          top={0}
          target={target}
          handleClose={handleClose || noop}
          onFooterClick={this.onFooterClick}
          listItemContainerStyle={'border-bottom: none; padding: 0;'}
          popoverClassName={
            popoverClassName ||
            cn('members-bar-dropdown', {
              'medium-width': mediumWidth,
              planner: isOnPlanner,
              budget: isOnBudget
            })
          }
          listWidth={listWidth || (isOnPlanner ? 250 : isOnBudget ? 255 : 373)}
          isItemInactive={this.isItemInactive}
          itemHeight={itemHeight}
          listHeight={listHeight || defaultListHeight}
          isLoading={isLoading}
          hideFooter={hideFooter}
        />
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getProjectInfo = makeGetProjectBasicInfoById();
  const mapStateToProps = (state, ownProps) => ({
    members: ownProps.members || getSortedTeamMembers(state), // allow member override
    me: getMe(state),
    project: getProjectInfo(state, ownProps),
    isLoading: ownProps.isLoading ?? ownProps.isFetchingSuggestions
  });
  return mapStateToProps;
};

export default withAccountsSuggestionForEntities(
  connect(makeMapStateToProps)(MembersDropdown)
);
