import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import {
  StyledSelectCell,
  StyledSelectCellContainer,
  ClickableArea
} from '../styles';
import { batchSelectWorkloadEventRecipients } from 'actionCreators';

const DisabledSelectCell = styled(StyledSelectCell)`
  background: ${theme.colors.colorPaleGray2};
  &:hover {
    cursor: default;
  }
  &:after {
    content: none;
  }
`;

const BulkCell = ({ row }) => {
  const { original } = row;
  const dispatch = useDispatch();

  const onBatchSelect = () => {
    dispatch(
      batchSelectWorkloadEventRecipients({
        eventId: original.eventId,
        ids: [original.account.id],
        value: !original.isSelected
      })
    );
  };

  return (
    <StyledSelectCellContainer>
      {!row?.original?.customRowProps?.selectionDisabled && (
        <>
          {row?.original?.percentCommitted ? (
            <>
              <ClickableArea onClick={onBatchSelect} />
              <StyledSelectCell isChecked={original.isSelected} />
            </>
          ) : (
            <DisabledSelectCell />
          )}
        </>
      )}
    </StyledSelectCellContainer>
  );
};

export default BulkCell;
