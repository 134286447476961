import { put } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity } from './generics';
import { api } from '../service';
import * as actionCreators from 'actionCreators';

const { passwordUpdate, inviteValidation, inviteResend } = entityActions;

export function* validateInvite(action) {
  const { error } = yield changeEntity(
    inviteValidation,
    api.validateInvite,
    [action.payload.inviteToken],
    action
  );
  console.log(error);
}

export function* updatePassword(action) {
  const { error } = yield changeEntity(
    passwordUpdate,
    api.updatePassword,
    [
      action.payload.token,
      action.payload.password,
      action.payload.firstName,
      action.payload.lastName
    ],
    action
  );

  if (!error) {
    yield put(actionCreators.fetchUser());
  }
}

export function* resendInviteEmail(action) {
  const { error, response } = yield changeEntity(
    inviteResend,
    api.resendInvite,
    [
      action.payload.token,
      action.payload.params.email,
      action.payload.params.firstName,
      action.payload.params.lastName
    ],
    action
  );
}
