import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBoardColumnOrder, getMatchedRouteParams } from 'selectors';
import BoardColumnDraggable from './BoardColumnDraggable';

import { updateBoardModules } from 'actionCreators';
import { withRouter } from 'react-router-dom';
import ColumnsDropdown from 'components/ColumnsDropdown';

const taskRemoveSubtext =
  'It will be removed from this view for all project members but data will not be deleted and can be added back at any time.';

const customStyles = {
  width: '120px'
};

class BoardColumnsDropdown extends Component {
  handleReorder = (order) => {
    const {
      updateBoardModules,
      matchedParams: { boardId }
    } = this.props;
    updateBoardModules({
      boardId,
      columnOrder: order
    });
  };

  render() {
    const { columns, show, hideContents } = this.props;
    return (
      <ColumnsDropdown
        DraggableComponent={BoardColumnDraggable}
        handleReorder={this.handleReorder}
        columns={columns}
        removeSubtext={taskRemoveSubtext}
        show={show}
        hideContents={hideContents}
        customStyles={customStyles}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  columns: getBoardColumnOrder(state),
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateBoardModules
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardColumnsDropdown)
);
