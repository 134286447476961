import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getQuickbooksModule } from 'QuickbooksModule/package/quickbooksModule';
import GlobalActivityDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/GlobalActivityDropdown';
import ActivityModal from 'views/projectPlanner/ActivityModal';
import SubcustomerModal from 'QuickbooksModule/components/SubcustomerModal';

const QuickbooksContainer = lazy(() => import('./QuickbooksContainer'));

const LoadQuickbooksContainer = (props) => (
  <DynamicModuleLoader modules={[getQuickbooksModule()]}>
    <Suspense fallback={<div />}>
      <QuickbooksContainer {...props} />
      <GlobalActivityDropdown />
      <ActivityModal />
      <SubcustomerModal />
    </Suspense>
  </DynamicModuleLoader>
);

export default LoadQuickbooksContainer;
