import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface WorkPlanIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  currentColor?: string;
  className?: string;
}

export const WorkPlanIcon = ({
  currentColor = '#4F4F4F',
  width = '16',
  height = '16',
  className
}: WorkPlanIconProps) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="transparent"
    >
      <path
        d="M0.4 1C0.4 0.668629 0.668629 0.4 1 0.4H12C12.3314 0.4 12.6 0.668629 12.6 1C12.6 1.33137 12.3314 1.6 12 1.6H1C0.668629 1.6 0.4 1.33137 0.4 1Z"
        stroke={currentColor}
        strokeWidth="0.8"
      />
      <path
        d="M3.4 6C3.4 5.66863 3.66863 5.4 4 5.4H12C12.3314 5.4 12.6 5.66863 12.6 6C12.6 6.33137 12.3314 6.6 12 6.6H4C3.66863 6.6 3.4 6.33137 3.4 6Z"
        stroke={currentColor}
        strokeWidth="0.8"
      />
      <path
        d="M5.4 11C5.4 10.6686 5.66863 10.4 6 10.4H13C13.3314 10.4 13.6 10.6686 13.6 11C13.6 11.3314 13.3314 11.6 13 11.6H6C5.66863 11.6 5.4 11.3314 5.4 11Z"
        stroke={currentColor}
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
};

export default WorkPlanIcon;
