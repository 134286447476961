import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  getInboxTasks,
  getAuth,
  getCurrentFilter,
  getAllTasks,
  getIsFetchingTasks,
  getIsLazyLoadingTasks,
  getIsSearching,
  getCurrentPage,
  getIsOnProjectView,
  getTaskGroups
} from 'selectors';

import InfiniteScrollContainer from './InfiniteScrollContainer';
import styled from 'styled-components';

const InfiniteScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  left: ${({ left }) => left || 0};
`;

class TasksList extends Component {
  taskGroupsAreEmpty = () =>
    this.props.taskGroups.some((group) => group.task_order.length === 0);

  onlyDefaultTaskGroup = () =>
    this.props.taskGroups.length === 1 && this.props.taskGroups[0].is_default;

  render() {
    const {
      inboxTasks,
      allTasks,
      isOnHomeView,
      selectedBatchTaskIds,
      toggleTaskBatchAction,
      taskFilter,
      scheduledGroups,
      plannerGroups,
      shouldRenderCreateTaskButton,
      alwaysRenderDue,
      alwaysRenderSchedule,
      position,
      isOnTeamProject,
      isOnPersonalProject,
      isLazyLoading,
      currentPage,
      isOnProjectView,
      taskGroups,
      isDayPlannerView,
      plannerDate
    } = this.props;
    const renderedTasks = currentPage.isInbox ? inboxTasks : allTasks;

    const showBatchActionToggles = !isEmpty(selectedBatchTaskIds);

    return (
      <div
        style={{
          flex: '1',
          minHeight: 0,
          zIndex: 4,
          width: isOnHomeView
            ? currentPage.isAssignedByMe
              ? '887px'
              : isDayPlannerView
              ? '1017px'
              : '922px'
            : ''
        }}
      >
        <InfiniteScrollWrapper
          left={isOnProjectView ? '36px' : isOnHomeView ? '-37px' : 0}
        >
          <InfiniteScrollContainer
            toggleTaskBatchAction={toggleTaskBatchAction}
            showBatchActionToggles={showBatchActionToggles}
            selectedBatchTaskIds={selectedBatchTaskIds}
            scheduledGroups={scheduledGroups}
            plannerGroups={plannerGroups}
            shouldRenderCreateTaskButton={shouldRenderCreateTaskButton}
            position={position}
            alwaysRenderSchedule={alwaysRenderSchedule}
            alwaysRenderDue={alwaysRenderDue}
            taskFilter={taskFilter}
            isOnTeamProject={isOnTeamProject}
            isOnHomeView={isOnHomeView}
            allTasks={renderedTasks}
            isLazyLoading={isLazyLoading}
            isOnPersonalProject={isOnPersonalProject}
            showCollapseButton={!!(taskGroups.length > 1)}
            isDayPlannerView={isDayPlannerView}
            plannerDate={plannerDate}
          />
        </InfiniteScrollWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: getAuth(state),
  inboxTasks: getInboxTasks(state),
  allTasks: getAllTasks(state),
  taskGroups: getTaskGroups(state, ownProps),
  isFetchingTasks: getIsFetchingTasks(state),
  isLazyLoading: getIsLazyLoadingTasks(state),
  taskFilter: getCurrentFilter(state),
  isSearching: getIsSearching(state),
  currentPage: getCurrentPage(state),
  isOnProjectView: getIsOnProjectView(state)
});

export default connect(mapStateToProps)(TasksList);
