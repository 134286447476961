import React from 'react';
import { useSelector } from 'react-redux';

const withCheckIfOpen = (Component) => {
  const WithCheckIfOpen = ({ isOpenSelector, isOpen, ...props }) => {
    const selectorIsOpen = useSelector(isOpenSelector || (() => {}));
    const shouldRender = isOpen || selectorIsOpen;

    return shouldRender ? <Component {...props} isOpen /> : null;
  };

  return WithCheckIfOpen;
};

export default withCheckIfOpen;
