export const tooltipPortal = document.createElement('div');
tooltipPortal.classList.add('tooltip-portal');

// see https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
// when using, make sure style on portaled draggable is correct
export const portal = document.createElement('div');
portal.classList.add('dnd-portal');

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(tooltipPortal);
document.body.appendChild(portal);
