import * as constants from 'appConstants';
export const initialState = {
  open: false
};
const modules = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.OPEN_MODULES_MODAL: {
      return {
        ...state,
        open: true
      };
    }
    case constants.CLOSE_MODULES_MODAL: {
      return {
        ...state,
        open: false
      };
    }
    default:
      return state;
  }
};

export default modules;
