import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, useDispatch } from 'react-redux';
import { checkHasTimeEntries } from 'actionCreators';
import { getSelectedTeamId } from 'selectors';

// ==================================================================

import DeleteIcon from 'icons/DeleteIcon';
import { ThreeDotIconContainer } from './styles';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { ThreeDotMenuListItem } from 'components/tables/ProjectDetailsTable/Cells/shared/ThreeDotMenuListItem';

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  &:hover {
    path {
      fill: ${theme.colors.colorDeleteRed};
    }
  }
`;

const MilestoneTableMenuCell = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedTeamId = useSelector(getSelectedTeamId);
  const { phase, canDelete, openPhaseModal } = props;

  const setDeletePhaseId = () => {
    if (!canDelete) {
      return;
    }

    if (selectedTeamId)
      dispatch(
        checkHasTimeEntries({
          phase_ids: [phase.id],
          team_id: selectedTeamId,
          permissions: {
            mine: false,
            teamId: selectedTeamId
          }
        })
      );
    openPhaseModal({
      id: phase.id,
      name: phase.name,
      projectId: phase.project_id,
      isDeletePhase: true
    });
  };

  const menus = [
    {
      icon: <StyledDeleteIcon />,
      label: 'Delete',
      onClick: () => {
        setDeletePhaseId();
      }
    }
  ];
  return null;
};

export default MilestoneTableMenuCell;
