import styled from 'styled-components';
import { NavLink } from 'reactstrap';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';

export const Page = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const StyledContainer = styled.div<{ flexDirection?: string }>`
  flex: 4;
  margin-top: 10px;
  position: relative;
  max-height: calc(100vh - 100px);
  overflow-x: clip;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
`;

export const StyledNavLink = styled(NavLink)`
  color: ${theme.colors.colorMediumGray5};
  padding: 0 7px;
  margin: 14px 0;

  &.active {
    border-left: 2px solid ${(props) => props.theme.colors.colorRoyalBlue};
    color: #4a4a4a;
    font-weight: bold;
  }
`;

export const FeatureHeader = styled.div<{
  isWhite?: boolean;
  headerStyle?: string;
}>`
  font-size: 17px;
  padding: 10px 12px;
  height: 50px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  background: ${({ isWhite }) =>
    isWhite ? theme.colors.colorPureWhite : theme.colors.colorTranslucentGray4};
  position: sticky;
  /* top: 0px; Uncomment if you want permission header to be sticky */
  z-index: 1;
  align-items: center;
  ${({ headerStyle }) => headerStyle};
`;

export const StyledKaratRight = styled(KaratRight)<{ isOpen: boolean }>`
  height: 13px;
  width: 13px;
  transition: 0.2s ease-in-out;
  margin-right: 6px;
  transform: ${({ isOpen }) => (!isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};
`;

export const FeatureContainer = styled.div<{
  isOpen: boolean;
  headerContainerStyle?: string;
}>`
  padding: 0px;
  background: ${theme.colors.colorTranslucentGray4};
  border-bottom: 1px solid ${theme.colors.colorLightGray6};
  margin-bottom: 0px;
  &:hover {
    ${FeatureHeader}:hover {
      color: ${theme.colors.colorRoyalBlue};
      background: ${theme.colors.colorPureWhite};
      ${StyledKaratRight} {
        path {
          fill: ${theme.colors.colorRoyalBlue};
        }
      }
    }
  }
  ${({ headerContainerStyle }) => headerContainerStyle};
`;

export const CheckBoxOptionText = styled.div<{
  isChecked: boolean;
}>`
  color: ${({ isChecked }) =>
    isChecked
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorMediumGray5};
  font-size: 12px;
  margin-left: 6px;
`;

export const DefaultTextContainer = styled.span`
  color: ${theme.colors.colorMediumGray3};
  font-size: 10px;
  margin-left: 6px;
`;

export const TooltipContainer = styled.span`
  margin-left: 5px;
`;

export const PermissionSelection = styled.div`
  background: ${theme.colors.colorPureWhite};
  padding: 20px 44px;
  margin: 0;
`;

export const PermissionCheckbox = styled.div`
  padding-bottom: 20px;
  position: relative;
`;

export const PermissionCheckboxClickArea = styled.div<{ isDisabled: boolean }>`
  display: flex;
  cursor: ${({ isDisabled }) => (!isDisabled ? 'pointer' : 'default')};
`;

export const InfoModalToggle = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const InfoIconContainer = styled.div`
  margin-right: 5px;
  margin-top: -2px;
`;
