import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import KaratRight from 'icons/KaratRight';

const StyledCapacity = styled.div`
  color: ${theme.colors.colorCalendarGray};
  line-height: 1;
  font-size: 10px;
  padding: 5px;
  margin-top: -3px;
  margin-left: -5px;
`;
const StyledNameContainer = styled.div`
  padding-top: 4px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;
const StyledKaratContainer = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-left: 5px;
  path {
    stroke-width: 1;
    stroke: ${theme.colors.colorSemiDarkGray1};
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
  ${({ isOpen }) => isOpen && `background: ${theme.colors.colorPaleGray6};`};
  &:hover {
    background: ${theme.colors.colorPaleGray6};
  }
`;
const TeamMemberCell = styled.div`
  display: flex;
  padding: 0px 4px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const MemberCell = ({ row }) => {
  const {
    account = {},
    currentUserId,
    customRowProps: { handleSetIsOpen },
    list: { isOpen }
  } = row.original;
  const name = `${account.name}`;

  return (
    <TeamMemberCell
      className="team-member-cell app-cues-team-member-cell"
      onClick={() => handleSetIsOpen({ name: account.id, value: !isOpen })}
      showBorder={!isOpen}
    >
      <StyledKaratContainer
        isOpen={isOpen || false}
        className="app-cues-styled-karat"
      >
        <KaratRight />
      </StyledKaratContainer>
      <MemberInitials
        member={account}
        classes={cn('item-account selected', {
          'logged-member-no-hover': account.id === currentUserId,
          'regular-member-no-hover': account.id !== currentUserId
        })}
        idx={'idx-placeholder'}
        isOnTaskModal
      />

      <StyledNameContainer className="app-cues-styled-name-container">
        <StyledName>{name}</StyledName>
        <StyledCapacity className="app-cues-styled-capacity">
          {account.email}
        </StyledCapacity>
      </StyledNameContainer>
    </TeamMemberCell>
  );
};
export default MemberCell;
