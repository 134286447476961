import { useCallback } from 'react';
import { ROW_TYPES } from '../tableConfigs';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import { serializeId } from 'appUtils';
import { ReasonListItemsBuilderArgs, ReasonListItem } from './types';

/* --------------------------------- Reasons --------------------------------- */

export const useReasonListItemsBuilder = ({
  getIsOpen,
  toggleCollapse,
  setParentCollapseState,
  getCurrentParentCollapseState
}: UseNestedCollapseHookReturnedValues) => {
  const reasonListItemsBuilder = useCallback(
    ({
      order,
      parentGroupId,
      reasons,
      accountId,
      phase,
      phaseMembership,
      activityPhase,
      activityPhaseMembership,
      member,
      projectTotal,
      phaseTotals,
      teamMembershipsByAccountId
    }: ReasonListItemsBuilderArgs) => {
      const listItems: ReasonListItem[] = order.map((reasonId, index) => {
        const reasonValue = reasons[reasonId];
        const reason = {
          [reasonId]: reasonValue
        };

        const items = {
          rowType: ROW_TYPES.reasonRow,
          rowHeight: 40,
          id: serializeId({
            itemType: 'reason',
            id: undefined,
            ids: [parentGroupId, reasonId]
          }),
          /* -------------------------------------------------------------------------- */
          accountId,
          reasonId,
          reason,
          phase,
          phaseMembership,
          activityPhase,
          activityPhaseMembership,
          member,
          projectTotal,
          phaseTotals,
          teamMembershipsByAccountId,
          isLast: index === order.length - 1
        };

        return items;
      });
      return listItems;
    },
    []
  );

  return reasonListItemsBuilder;
};
