import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  getAllTasks,
  getBatchSelectedTaskIds,
  getIsOnProjectView
} from 'selectors';
import { setBatchSelectedTasks, clearBatchSelectedTasks } from 'actionCreators';

import { BatchSelectAll } from '../../TaskList/BatchSelectAll';
import { StyledTaskHeader } from './styles';

const rowTypesToOriginTypes = {
  taskGroupRow: 'taskGroup',
  phaseRow: 'phase',
  activityPhaseRow: 'activityPhase'
};

const BulkHeaderCell = ({ column, rows, row }) => {
  const { headerLabel, align } = column;
  const dispatch = useDispatch();

  const rowToUse =
    row?.original ||
    rows?.find(
      (row) =>
        row.original &&
        row.original?.list?.id === row.original?.customRowProps?.activeSection
    )?.original;
  const allSelected = useSelector(
    (state) => state.homeTasks.batchSelectedGroupIds[rowToUse?.list.id]
  );

  const taskOrder = useSelector(getAllTasks);

  const ownTaskIds = rowToUse?.list?.taskIds ?? [];

  const batchSelectedTaskIds = useSelector(getBatchSelectedTaskIds);
  const someIsBatchSelected = useMemo(
    () =>
      !!allSelected ||
      ownTaskIds.some((taskId) => batchSelectedTaskIds[taskId]),
    [allSelected, batchSelectedTaskIds, ownTaskIds]
  );

  const isHideOwnColor =
    useSelector(getIsOnProjectView) && !rowToUse?.list.isOpen;

  const handleSetBatchSelectedTasks = () => {
    batch(() => {
      dispatch(setBatchSelectedTasks({ groupIds: [rowToUse.list.id] }));
      dispatch(clearBatchSelectedTasks({ taskIds: ownTaskIds })); // unset individual tasks
    });
  };
  const handleClearBatchSelectedTasks = () => {
    dispatch(
      clearBatchSelectedTasks({
        groupIds: [rowToUse.list.id],
        taskIds: ownTaskIds
      })
    );
  };

  if (!headerLabel) {
    return <div />;
  }
  return someIsBatchSelected ? (
    <StyledTaskHeader
      originType={rowTypesToOriginTypes[rowToUse?.rowType] || 'taskGroup'}
      taskGroupId={rowToUse?.list.id}
      color="#ccc"
      align={align}
      className="styled-report-header"
    >
      <BatchSelectAll
        taskGroupTaskIds={ownTaskIds}
        taskIds={taskOrder}
        fromTaskGroups
        isVirtualTaskList
        setBatchSelectedTasks={handleSetBatchSelectedTasks}
        clearBatchSelectedTasks={handleClearBatchSelectedTasks}
        allBatchSelected={allSelected}
        someBatchSelected={someIsBatchSelected}
      />
    </StyledTaskHeader>
  ) : (
    <StyledTaskHeader
      align={align}
      onClick={() => {}}
      originType={rowTypesToOriginTypes[rowToUse?.rowType] || 'taskGroup'}
      taskGroupId={rowToUse?.list.id}
      color="#ccc"
      className="styled-report-header"
      isHideOwnColor={isHideOwnColor}
    >
      {rowToUse?.list.isOpen ? 'SELECT' : ''}
    </StyledTaskHeader>
  );
};
export default BulkHeaderCell;
