import { MouseEvent } from 'react';
import styled from 'styled-components';
import { TeamMember } from 'TeamsModule/models/teamMember';
import theme from 'theme';
import { MemberInitials } from 'views';

export const MemberTagItem = ({
  member,
  handleMemberTagClick
}: {
  member: TeamMember;
  handleMemberTagClick: ({
    event,
    member
  }: {
    event: MouseEvent<HTMLElement>;
    member: TeamMember;
  }) => void;
}) => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    handleMemberTagClick({ event, member });
  };

  return (
    <MemberTagItemContainer>
      <MemberContainer key={member.id} onClick={handleClick}>
        <MemberInitials
          member={member}
          classes="regular-member-no-hover"
          size="small"
        />
        <MemberName>{member.account.name}</MemberName>
      </MemberContainer>
    </MemberTagItemContainer>
  );
};

const MemberTagItemContainer = styled.div``;

const MemberContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 25px;
  background-color: ${theme.colors.colorLightGray17};
  display: flex;
  flex-direction: row;
  height: 30px;
  padding-left: 2px;
  padding-right: 13px;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.colorBudgetBlue};
  }
`;

const MemberName = styled.div`
  margin-left: 6px;
  font-size: 12px;
`;
