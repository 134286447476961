import React from 'react';
import { connect } from 'react-redux';
import {
  getEarlierTeamRate,
  getLaterTeamRate,
  getEarliestTeamRate
} from 'BudgetModule/selectors';
import { makeGetOwnAccountIsMappedToQb } from 'IntegrationsModule/selectors';
import moment from 'moment';
import { Modal } from 'reactstrap';
import UpdateRows from './UpdateRows';
import {
  YesButton,
  NoButton,
  ButtonRow,
  RateConfirmRow,
  RateConfirmBox,
  RateDateContainer,
  RateMemberDescription,
  CopyContainer,
  CopyHeader,
  CopyParagraph,
  GreyCopyParagraph,
  StyledDateChangeConfirmation,
  AffectedDate,
  OverrideHeader
} from './styles';
import KaratLeft from 'icons/KaratLeft';
import { EARLIEST_RATE_START_DISPLAY } from 'BudgetModule/constants';
import ProcessStartedModalBody from 'components/ProcessStartedModal/ProcessStartedModalBody';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer
} from 'components/styles';
import capitalize from 'lodash/capitalize';
import { FormattedCurrencyByTeam } from 'components/FormatCurrency';

const copyHash = {
  endDate: 'start date for the next',
  startDate: 'end date for the prior'
};

class TeamRateOverrideModal extends React.Component {
  handleCancel = () => this.props.handleCancel();
  handleConfirm = () => this.props.handleConfirm();

  renderRateDescription = (item) =>
    item ? (
      <RateMemberDescription>
        <div className="rate-cost">
          <FormattedCurrencyByTeam currencyValue={item.rate} />
        </div>
        {item.description}
      </RateMemberDescription>
    ) : null;

  renderRate = () => {
    const { selectedRate, selectedMemberTeamRate = {} } = this.props;
    const { start_date, end_date } = selectedMemberTeamRate;
    const startDate =
      start_date === '1900-01-01'
        ? EARLIEST_RATE_START_DISPLAY
        : moment(start_date).format('M/DD/YY');

    const endDate =
      end_date === 'Present' || !end_date
        ? 'Present'
        : moment(end_date).format('M/DD/YY');

    return (
      <RateConfirmRow>
        {this.renderRateDescription(selectedRate)}
        <RateDateContainer>
          <AffectedDate>{startDate}</AffectedDate> - <div>{endDate}</div>
        </RateDateContainer>
      </RateConfirmRow>
    );
  };

  renderHeader = () => {
    const { isCostRate, rateType } = this.props;
    return (
      <OverrideHeader>
        <div className="karat-container" onClick={this.props.onCancel}>
          <KaratLeft height={15} className="karat-icon" />
        </div>
        {this.props.headerCopy ||
          (isCostRate || rateType === 'cost'
            ? 'Confirm Cost Rate Change'
            : 'Confirm Bill Rate Change')}
      </OverrideHeader>
    );
  };

  renderItems = (isUpdate) => {
    const { selectedDate, earliestRate, earlierRate, laterRate, rateType } =
      this.props;

    return isUpdate ? (
      <UpdateRows
        selectedDate={selectedDate}
        earliestRate={earliestRate}
        earlierRate={earlierRate}
        laterRate={laterRate}
        hideDescription={rateType === 'cost'}
      />
    ) : (
      <RateConfirmBox>{this.renderRate()}</RateConfirmBox>
    );
  };

  multipleRatesAffected = () => this.props.earlierRate && this.props.laterRate;

  renderCopy = (isUpdate) => (
    <CopyContainer>
      <CopyHeader>
        Do you want to push this updated rate to all projects?
      </CopyHeader>
      <CopyParagraph>
        &rarr; If you select yes, this cannot be undone.
      </CopyParagraph>
      <CopyParagraph>
        &rarr; If you click no, this rate will only apply to new projects and
        the rate must be manually updated on all existing projects.
      </CopyParagraph>
      {isUpdate && (
        <CopyParagraph>
          The change will also update the {copyHash[this.props.dateType]} rate
          as shown above.
        </CopyParagraph>
      )}
      {/* <CopyParagraph>
        If there is no rate on the project, this will be the default rate.
      </CopyParagraph> */}
      {this.props.accountIsMappedToQb && (
        <GreyCopyParagraph>
          Note, if you have integrated your financial software this rate change
          will also update the rates there.
        </GreyCopyParagraph>
      )}
    </CopyContainer>
  );

  renderCostRateCopy = (isUpdate) => (
    <CopyContainer>
      <CopyHeader>
        Are you sure you want to edit the date for this rate?
      </CopyHeader>
      {isUpdate && (
        <CopyParagraph>
          This change will also update the {copyHash[this.props.dateType]} rate
          as shown above.
        </CopyParagraph>
      )}
    </CopyContainer>
  );

  renderButtons = () => {
    return (
      <ButtonRow>
        <NoButton onClick={this.props.onNo}>No, New Only</NoButton>
        <YesButton onClick={this.props.onYes}>Yes, Apply to All</YesButton>
      </ButtonRow>
    );
  };

  renderCostRateButtons = () => {
    return (
      <ButtonContainer style={{ width: 130, float: 'right' }}>
        <TextButtonWithBorder onClick={this.props.onCancel}>
          Cancel
        </TextButtonWithBorder>
        <BlueSubmitButton onClick={this.props.onYes}>Save</BlueSubmitButton>
      </ButtonContainer>
    );
  };

  render() {
    const {
      isUpdate,
      isSubmitted,
      clearIsSubmitted,
      isCostRate,
      rateType = 'billing'
    } = this.props;
    return (
      <Modal isOpen={true}>
        <StyledDateChangeConfirmation>
          {isSubmitted ? (
            <ProcessStartedModalBody
              handleOk={clearIsSubmitted}
              title={`Updating ${capitalize(rateType)} Rate`}
              processStartedMessage={`Process initiated. Updating ${rateType} rates across all projects may take a few minutes`}
            />
          ) : (
            <>
              {this.renderHeader()}
              {this.renderItems(isUpdate)}
              {isCostRate
                ? this.renderCostRateCopy(isUpdate)
                : this.renderCopy(isUpdate)}
              {isCostRate ? this.renderCostRateButtons() : this.renderButtons()}
            </>
          )}
        </StyledDateChangeConfirmation>
      </Modal>
    );
  }
}

const makeMapStateToProps = () => {
  const getAccountIsMappedToQb = makeGetOwnAccountIsMappedToQb();
  const mapStateToProps = (state, ownProps) => ({
    earliestRate:
      ownProps.earliestRate !== undefined
        ? ownProps.earliestRate
        : getEarliestTeamRate(state, ownProps),
    earlierRate:
      ownProps.earlierRate !== undefined
        ? ownProps.earlierRate
        : getEarlierTeamRate(state, ownProps),
    laterRate:
      ownProps.laterRate !== undefined
        ? ownProps.laterRate
        : getLaterTeamRate(state, ownProps),
    accountIsMappedToQb: getAccountIsMappedToQb(state, ownProps)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TeamRateOverrideModal);
