import { useMemo } from 'react';
import { DependencyList } from './DependencyList';
import { DepedencyListProps } from 'components/Dependency/types';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { makeGetPhaseDependencyItems } from 'selectors';
import { DEPENDABLE_LABELS, DEPENDABLE_TYPES } from './constants';
import { PhaseDependencyItemRenderer } from './PhaseDependencyItemRenderer';
import { useAppSelector } from 'reduxInfra/hooks';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { DependencyController } from './DependencyV2/components/DependencyController';
import { dependencyComponentType } from './DependencyV2/constants';
import { UseDependencyFormStateReturnType } from './DependencyV2/types';
import { usePhaseDependableItems } from './DependencyV2/hooks/usePhaseDependableItems';

interface BasePhaseDependencyListProps {
  phase: Phase;
  dependencyListProps: Omit<
    DepedencyListProps,
    'dependableItems' | 'baseDependableTypeLabel' | 'targetDependableTypeLabel'
  >;
  // dependencyFormState optional until flexibleDependencyFlag split flag is removed
  flexibleDependencyFormState?: UseDependencyFormStateReturnType;
}

export const BasePhaseDependencyList = ({
  phase,
  dependencyListProps,
  flexibleDependencyFormState
}: BasePhaseDependencyListProps) => {
  const { project_id: projectId, id: phaseId } = phase;

  const { flexibleDependencyFlag } = useFeatureFlags();

  const getPhaseDependencyItems = useMemo(
    () => makeGetPhaseDependencyItems(),
    []
  );
  const dependableItems = useAppSelector((state) =>
    getPhaseDependencyItems(state, {
      phaseId,
      projectId
    })
  );

  const { dependableItems: flexibleDependableItems } = usePhaseDependableItems({
    phaseId,
    projectId
  });

  return flexibleDependencyFlag && flexibleDependencyFormState ? (
    <DependencyController
      dependencyFormState={flexibleDependencyFormState}
      dependableItems={flexibleDependableItems}
      dependencyComponentType={dependencyComponentType.multiOptionDependency}
    />
  ) : (
    <DependencyList
      dependableItems={dependableItems}
      baseDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE]}
      targetDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE]}
      renderDependableItem={PhaseDependencyItemRenderer}
      {...dependencyListProps}
    />
  );
};
