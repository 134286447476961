import React from 'react';
import SvgIcon from 'components/SvgIcon';

const OpenLockIcon = ({
  className = '',
  width = '18',
  height = '14',
  fill = '#A4A4A4',
  style = {}
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    style={style}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7853 15.113V8.94165C14.7853 8.23124 14.2027 7.65333 13.4802 7.63764L12.5893 7.63415H5.28771V5.90129C5.28771 3.9784 7.06062 2.23511 9.02586 2.23511C10.401 2.23511 11.7274 3.08866 12.3999 4.26005L12.9865 3.9081C12.2457 2.50465 10.7484 1.54297 9.02586 1.54297C6.56975 1.54297 4.57154 3.49811 4.57154 5.90129V7.63415H4.55016C3.81342 7.63415 3.21387 8.22078 3.21387 8.94165V15.113C3.21387 15.8339 3.81342 16.4205 4.55016 16.4205H13.449C14.1857 16.4205 14.7853 15.8339 14.7853 15.113Z"
      fill={fill}
    />
    <rect
      x="4.11426"
      y="8.44775"
      width="9.77143"
      height="7.07143"
      rx="0.9"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88134 11.1309C9.88134 11.45 9.69693 11.7167 9.4359 11.8684V13.7459H8.54504V11.8692C8.28313 11.7176 8.09961 11.4508 8.09961 11.1309C8.09961 10.6498 8.49782 10.2593 8.99047 10.2593C9.48134 10.2593 9.88134 10.6489 9.88134 11.1309Z"
      fill={fill}
    />
  </SvgIcon>
);

export default OpenLockIcon;
