import {
  makeVerification,
  makeAuthorBasedVerification
} from '../makeVerification';

// Access All Timesheets View Only on Member Profile (Not Implemented)
export const verifyReadTimesheetEntries = makeAuthorBasedVerification(
  'read_time_entries',
  'read_time_entries_other'
);

// Edit Others Timesheets
export const verifyEditTimesheetEntries = makeAuthorBasedVerification(
  'edit_time_entries',
  'edit_time_entries_other'
);

// Approve Others Timesheets
export const verifyApproveTimesheetEntries = makeVerification(
  'approve_time_entries'
);
