import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSelectedTeamId } from 'selectors';
import {
  makeGetActiveRateByMemberBudget,
  getTeamRates
} from 'BudgetModule/selectors';

import {
  createMemberRate,
  updateMemberRate,
  editMemberRate
} from 'BudgetModule/actionCreators';
import { RatesDropdownContainer } from './styles';
import DateChangeConfirmation from '../BudgetModal/DateChangeConfirmation';
import RateDeleteConfirmation from '../BudgetModal/RateDeleteConfirmation';
import MemberRatesDropdown from '../BudgetModal/MemberRatesDropdown';
import RatesDropdown from '../BudgetModal/RatesDropdown';
import moment from 'moment';
import Popover from 'components/Popover';
import { RATE_MODAL_TYPES } from 'appConstants';

class RateSelection extends React.Component {
  state = {
    openModal: this.props.openRateSelectionType
      ? this.props.openRateSelectionType
      : null,
    selectedDate: null,
    dateType: null,
    item: null,
    isDeleteRate: false,
    deleteItem: null
  };

  componentDidMount() {
    const { addedMemberBudgetId, memberBudget } = this.props;
    if (addedMemberBudgetId && memberBudget?.id === addedMemberBudgetId) {
      this.setState({
        openModal: 'rates'
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.openRateSelectionType && this.props.openRateSelectionType) {
      this.setState({ openModal: this.getModalType() });
    }
  }

  getModalType = () => (this.props.activeRate ? 'memberRates' : 'rates');

  handleOpenModal = (modalType) => (e) => {
    this.setState({ openModal: modalType });
    e && e.stopPropagation();
  };

  handleOpenMemberRates = (e, ignoreClickOverride) => {
    const { handleClick, handleClickOverride, editMemberRate } = this.props;
    handleClick?.(e);
    editMemberRate({
      isEditMemberRate: false,
      editMemberRateItem: {}
    });
    // for when we don't want modal showing on click of the container eg. dropdown in parent
    // shows instead
    if (handleClickOverride && !ignoreClickOverride) {
      handleClickOverride();
    } else {
      const modalType = this.getModalType();
      return this.handleOpenModal(modalType)(e);
    }
  };

  handleOpenRates = this.handleOpenModal('rates');

  handleCloseModal = () => {
    this.setState({ openModal: null });
    this.props.handleClose?.();
  };

  handleCloseDropdown = (e) => {
    this.setState({ openModal: null });
    this.props.handleClose?.();
    e.stopPropagation();
  };

  handleSetDate = ({ selectedDate, dateType, item }) =>
    this.setState({ selectedDate, dateType, item });

  handleDateCancel = () =>
    this.setState({ selectedDate: null, dateType: null, item: null });

  handleReset = () => {
    this.setState({
      openDropdown: null,
      selectedDate: null,
      dateType: null,
      item: null,
      deleteItem: null
    });
  };

  handleDateConfirm = () => {
    const { updateMemberRate, memberBudget } = this.props;
    const { item, dateType, selectedDate } = this.state;
    const { id, rate } = item;
    memberBudget &&
      updateMemberRate({
        id,
        rate,
        [dateType]: moment(selectedDate).format('YYYY-MM-DD'),
        // below used by saga to chain calls, not needed for the update api call
        memberBudgetId: memberBudget.id,
        projectId: memberBudget.project_id
      });
    this.handleReset();
  };

  openDeleteRateModal = (deleteMember) => {
    this.setState({
      isDeleteRate: true,
      deleteItem: deleteMember
    });
  };

  closeDeleteRateModal = () => {
    this.setState({
      isDeleteRate: false,
      deleteItem: null
    });
  };

  render() {
    const {
      openModal,
      selectedDate,
      item,
      dateType,
      isDeleteRate,
      deleteItem
    } = this.state;
    const {
      memberBudget,
      activeRate,
      target,
      children,
      inTableCell,
      projectId,
      memberName,
      renderInitials,
      containerStyles = {},
      showRates,
      inMemberModal
    } = this.props;
    return (
      <RatesDropdownContainer
        inTableCell={inTableCell}
        inMemberModal={inMemberModal}
        onClick={this.handleOpenMemberRates}
        ref={(ref) => (this.target = ref)}
        style={containerStyles}
      >
        {children}

        {openModal === 'rates' && (
          <RatesDropdown
            target={target}
            memberBudgetId={memberBudget && memberBudget.id}
            handleClose={
              activeRate
                ? (e) => this.handleOpenMemberRates(e, true) // ignoreClickOverride = true since that is really just used for container click
                : this.handleCloseModal
            }
            activeRate={activeRate}
            projectId={memberBudget && memberBudget.project_id}
            showRates={showRates}
          />
        )}
        {openModal === 'memberRates' &&
          (selectedDate ? (
            <Popover isOpen target={target} boundariesElement="viewport">
              <DateChangeConfirmation
                handleCancel={this.handleDateCancel}
                handleConfirm={this.handleDateConfirm}
                selectedMemberRate={item}
                dateType={dateType}
                selectedDate={selectedDate}
              />
            </Popover>
          ) : isDeleteRate ? (
            deleteItem && (
              <Popover isOpen target={target} boundariesElement="viewport">
                <RateDeleteConfirmation
                  handleClose={this.closeDeleteRateModal}
                  deleteItem={deleteItem}
                />
              </Popover>
            )
          ) : (
            <MemberRatesDropdown
              target={target}
              onStickyClick={this.handleOpenRates}
              handleClose={this.handleCloseModal}
              handleSetDate={this.handleSetDate}
              openDeleteRateModal={this.openDeleteRateModal}
              closeDeleteRateModal={this.closeDeleteRateModal}
              memberBudgetId={memberBudget && memberBudget.id}
              projectId={projectId}
              memberName={memberName}
              renderInitials={renderInitials}
              popoverClassName="member-rates-modal"
              showRates={showRates}
              modalType={RATE_MODAL_TYPES.BILL_RATE}
            />
          ))}
      </RatesDropdownContainer>
    );
  }
}

RateSelection.propTypes = {
  handleClick: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
  const getActiveRateByMemberBudget = makeGetActiveRateByMemberBudget();
  const mapStateToProps = (state, ownProps) => ({
    teamId: getSelectedTeamId(state),
    rates: getTeamRates(state),
    activeRate: getActiveRateByMemberBudget(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  createMemberRate,
  updateMemberRate,
  editMemberRate
};

export default connect(makeMapStateToProps, mapDispatchToProps)(RateSelection);
