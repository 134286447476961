import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { calculateBudgetDisplayPercentages } from 'appUtils/budgetTooltipContent';
import { OverdueBar } from 'BudgetModule/components/styles';

const GraphCell = ({ row }) => {
  const {
    data,
    customRowProps: { isHoursOnly }
  } = row;
  const formatType = 'hour';
  const { isOverBudget, residualPercent, spentPercent, plannedPercent } =
    useMemo(
      () =>
        calculateBudgetDisplayPercentages({
          spent: isHoursOnly ? +data.spent_hours : +data.spent_cost,
          planned: isHoursOnly ? +data.planned_hours : +data.planned_cost,
          total: isHoursOnly ? +data.estimated_hours : data?.total
        }),
      [
        data.estimated_hours,
        data.planned_cost,
        data.planned_hours,
        data.spent_cost,
        data.spent_hours,
        data?.total,
        isHoursOnly
      ]
    );

  const renderBar = () => {
    const sections = isHoursOnly ? sectionOrderHour : sectionOrder;
    const displayBar = isHoursOnly ? +data.estimated_hours : +data.total;
    return (
      <Bar>
        {displayBar ? (
          sections.map((dataKey) => renderSection(dataKey))
        ) : (
          <GraySection width={10} />
        )}
      </Bar>
    );
  };

  const renderOverBudgetBar = () => {
    const overdueWidth = Math.min(Math.abs(residualPercent), 100);
    const spentWidth = (spentPercent / (+spentPercent + +plannedPercent)) * 100;
    return (
      <Bar>
        {spentPercent > 0 && <SpentSection width={spentWidth} />}
        {plannedPercent > 0 && <PlannedSection width={100 - spentWidth} />}
        <OverdueBar shouldShow flexWidth={overdueWidth} />
      </Bar>
    );
  };

  const renderSection = (dataKey) => {
    const Section = sectionComponent[dataKey];
    const mapPercents = {
      spent_cost: data.spent_percent ?? spentPercent,
      planned_cost: data.planned_percent ?? plannedPercent,
      remaining_cost: Math.abs(data.remaining_percent ?? residualPercent),
      spent_hours: spentPercent,
      planned_hours: plannedPercent,
      remaining_hours: residualPercent
    };

    const percent = mapPercents[dataKey];

    return +percent !== 0 ? <Section width={percent} key={dataKey} /> : null;
  };
  return (
    <GraphCellContainer
      data-for={'budget-bar-tooltip'}
      data-tip={JSON.stringify({
        totals: data,
        formatType,
        isOverBudget
      })}
    >
      {isOverBudget ? renderOverBudgetBar() : renderBar()}
    </GraphCellContainer>
  );
};

export default GraphCell;

const GraphCellContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-top: 12px;
  padding-right: 42px;
  &:first-child {
    padding-top: 0px;
  }
`;

const Bar = styled.div`
  position: relative;
  height: 8px;
  display: flex;
  margin-top: 7.5px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  div[class*='GraySection'] {
    border-radius: 5px;
  }
`;

const BarSection = styled.div`
  height: 100%;
  width: ${(props) => props.width}%;
  min-width: fit-content;
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  align-items: center;
  padding: 0 3px;
`;
const SpentSection = styled(BarSection)`
  background: ${theme.colors.colorBudgetGreen};
  color: white;
  justify-content: flex-end;
`;
const PlannedSection = styled(BarSection)`
  background: ${theme.colors.colorPaleYellow4};
`;
const RemainingSection = styled(BarSection)`
  background: ${theme.colors.colorBudgetLightGreen};
`;

const GraySection = styled(BarSection)`
  background: ${theme.colors.colorPaleGray4};
`;

const sectionComponent = {
  planned_cost: PlannedSection,
  remaining_cost: RemainingSection,
  spent_cost: SpentSection,
  planned_hours: PlannedSection,
  remaining_hours: RemainingSection,
  spent_hours: SpentSection
};

const sectionOrder = ['spent_cost', 'planned_cost', 'remaining_cost'];
const sectionOrderHour = ['spent_hours', 'planned_hours', 'remaining_hours'];
