import { MembersCapacityTableMemberTypeSectionHeaderCell as MemberTypeSectionHeaderCell } from './MemberTypeSectionHeaderCell';
import { MembersPermissionsTableHeaderCell as HeaderCell } from './HeaderCell';
import { MembersPermissionsTableEmployeeNumberCell as EmployeeNumberCell } from './EmployeeNumberCell';
import { MembersPermissionsTableNameCell as NameCell } from './NameCell';
import { MembersPermissionsTableEmailCell as EmailCell } from './EmailCell';
import { MembersPermissionsTableAccessCell as AccessCell } from './AccessCell';
import { StandardPTOTableArchivedSectionHeaderCell as ArchivedSectionHeaderCell } from 'SettingsModule/components/StandardSettings/PTO/StandardPTOTable/Cells/ArchivedSectionHeaderCell';
import { MembersPermissionsTableSelectCell as SelectCell } from './SelectCell';
import { MembersPermissionsThreeDotCell as ThreeDotCell } from './ThreeDotCell';

export const MembersPermissionsTableCells = {
  MemberTypeSectionHeaderCell,
  HeaderCell,
  EmployeeNumberCell,
  NameCell,
  EmailCell,
  AccessCell,
  SelectCell,
  ArchivedSectionHeaderCell,
  ThreeDotCell
};
