import React from 'react';
import styled from 'styled-components';
import ApproveIcon from 'icons/approve.svg';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ApproveIconContainer = styled.img`
  margin-top: 20px;
  margin-left: 14px;
`;

const ApproveCell = ({ row }) => {
  const { userActivity } = row.original;
  const approved = !!userActivity?.display_data?.time_entry?.approved_at;

  return (
    <StyledContainer>
      {approved && <ApproveIconContainer src={ApproveIcon} />}
    </StyledContainer>
  );
};

export default ApproveCell;
