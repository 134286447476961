import { useEffect, useState, useCallback } from 'react';
import { fetchTeamMembers } from 'actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getMyUserId } from 'UsersModule/selectors';
import { getAuthMfaStatusPerAccount } from 'SettingsModule/actionCreators/settings';
import styled from 'styled-components';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import {
  getPreferredAuthMethod,
  getIsSamlEnabled
} from 'AuthenticationModule/selectors';
import {
  associateSamlIdentity,
  generateTeamSamlInfo,
  setAuthMethod
} from 'AuthenticationModule/actionCreators';
import { Space } from 'components/Space';
import { TextButtonWithBorder } from 'components/styles';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { MfaSection } from './MfaSection';
import { SwitchAuthMethodSection } from './SwitchAuthMethodSection';
import { SamlAssociationSection } from './SamlAssociationSection';

export const SecuritySettingsLegacy = () => {
  const [isUserRequestSetupSaml, setIsUserRequestSetupSaml] = useState(false);

  const preferredAuthMethod = useAppSelector(getPreferredAuthMethod);
  const isSamlEnabled = useAppSelector(getIsSamlEnabled);

  const { status: associateSamlIdentityRequestStatus } = useRequestStatus({
    requestStatusId: associateSamlIdentity.type
  });

  const { status: setAuthMethodRequestStatus } = useRequestStatus({
    requestStatusId: setAuthMethod.type
  });

  const { useSamlAuth, mfaEnabledFlag } = useFeatureFlags();
  const dispatch = useAppDispatch();
  const myId = useAppSelector(getMyUserId);
  const teamId = useAppSelector(getSelectedTeamId);

  const getTeamInformation = useCallback(() => {
    dispatch(fetchTeamMembers({ isGetTeamMemberMfa: true }));
  }, [dispatch]);

  useEffect(() => {
    getTeamInformation();

    if (myId && teamId) {
      dispatch(
        getAuthMfaStatusPerAccount({
          accountIds: [myId]
        })
      );

      dispatch(generateTeamSamlInfo({ teamId }));
    }
  }, [dispatch, getTeamInformation, myId, teamId]);

  useEffect(() => {
    if (setAuthMethodRequestStatus?.isSuccess) {
      getTeamInformation();
    }
  }, [getTeamInformation, setAuthMethodRequestStatus?.isSuccess]);

  const isShowMFASection = useSamlAuth
    ? isUserRequestSetupSaml
      ? false
      : !isSamlEnabled && mfaEnabledFlag
    : mfaEnabledFlag;

  const isShowSamlAssociationSection =
    useSamlAuth &&
    isUserRequestSetupSaml &&
    !associateSamlIdentityRequestStatus?.isSuccess;

  const isShowSwitchAuthMethodSection =
    useSamlAuth &&
    (associateSamlIdentityRequestStatus?.isSuccess || isSamlEnabled);

  return (
    <>
      <RootContainer>
        {isShowMFASection && (
          <>
            <MfaSection />
            {useSamlAuth && (
              <>
                <Space vertical value={25} />
                <div>
                  <TextButtonWithBorder
                    onClick={() => setIsUserRequestSetupSaml(true)}
                  >
                    Setup SAML
                  </TextButtonWithBorder>
                </div>
              </>
            )}
          </>
        )}

        {isShowSamlAssociationSection && (
          <SamlAssociationSection
            associateSamlIdentityRequestStatus={
              associateSamlIdentityRequestStatus
            }
          />
        )}
        {isShowSwitchAuthMethodSection && !!preferredAuthMethod && (
          <SwitchAuthMethodSection
            preferredAuthMethod={preferredAuthMethod}
            setAuthMethodRequestStatus={setAuthMethodRequestStatus}
          />
        )}
      </RootContainer>
    </>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
