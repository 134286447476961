import { ModalBody } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';

import {
  getActivityPhaseModalPhase,
  getActivityPhaseModalActivity,
  getModalActivityPhaseHasTimeEntries,
  getAllActivityRowInfo,
  getOrderedNonBillableActivities,
  getOrderedBillableActivities,
  getActivityPhaseModalActivityId
} from 'selectors';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  RemoveButton,
  ArchiveButton,
  TaskCategoryHeader
} from 'BudgetModule/components/styles';
import ActivitiesDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/ActivitiesDropdown';

import { closeActivityPhaseModal, updatePhase } from 'actionCreators';
import {
  bulkMoveTimeAndRemoveActivity,
  bulkDeleteTimeAndRemoveActivity
} from 'BudgetModule/actionCreators';
import { getActivitiesForPhase } from 'appUtils/activityPhaseUtils';
import { getAssociatedTimeMessage } from 'appUtils/budgetUtils';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

class ActivityPhaseModalBody extends React.Component {
  state = {
    moveTimeOpen: false,
    isDeleting: false
  };

  componentWillUnmount() {
    this.clearIsDeleting();
  }

  onCancel = () => {
    this.handleClose();
  };

  onSubmit = () => {
    this.apiCall();
    this.handleClose();
  };

  onConfirm = () => {
    this.setIsDeleting();
    this.apiCall();
    this.handleClose();
  };

  handleClose = () => {
    const { closeActivityPhaseModal } = this.props;
    closeActivityPhaseModal({});
  };

  apiCall = () => {
    const { phase, activityId, updatePhase, onDeleteSuccess } = this.props;
    const { isDeleting } = this.state;
    if (!isDeleting) {
      updatePhase({
        projectId: phase.project_id,
        id: phase.id,
        activityOrder: uniq(
          phase.activity_order.filter((id) => id !== activityId)
        ),
        onSuccess: onDeleteSuccess
      });
    }
  };

  onDeleteTimeAndRemove = () => {
    const { bulkDeleteTimeAndRemoveActivity, activityId, phase } = this.props;
    bulkDeleteTimeAndRemoveActivity({
      activityId,
      phase,
      phaseId: phase.id,
      projectId: phase.project_id
    });
    this.handleClose();
  };

  handleSelectActivity = (item) => {
    const { bulkMoveTimeAndRemoveActivity, phase, activityId } = this.props;
    bulkMoveTimeAndRemoveActivity({
      sourceActivityId: activityId,
      phaseId: phase.id,
      activityId: item.id,
      projectId: phase.project_id,
      phase
    });
    this.handleClose();
  };

  getActivities = () => getActivitiesForPhase(this.props);

  setMoveTimeOpen = () => this.setState({ moveTimeOpen: true });
  setMoveTimeClose = () => this.setState({ moveTimeOpen: false });
  setIsDeleting = () => this.setState({ isDeleting: true });
  clearIsDeleting = () => this.setState({ isDeleting: false });

  renderRemoveButtons = () => {
    return (
      <>
        <Left />
        <Right>
          <ConfirmButton isDelete onClick={this.onConfirm}>
            Delete
          </ConfirmButton>
          <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
        </Right>
      </>
    );
  };

  renderOkayButton = () => {
    return (
      <>
        <Left />
        <Right>
          <ConfirmButton onClick={this.onCancel}>Okay</ConfirmButton>
        </Right>
      </>
    );
  };

  renderHasTimeButtons = () => {
    const { moveTimeOpen } = this.state;
    return (
      <>
        <Left />
        <Right>
          <ConfirmButton
            ref={(ref) => (this.target = ref)}
            onClick={this.setMoveTimeOpen}
          >
            Move
          </ConfirmButton>
          <CancelButton onClick={this.onCancel}>Ok</CancelButton>
        </Right>
        {moveTimeOpen && (
          <ActivitiesDropdown
            target={this.target}
            handleSelect={this.handleSelectActivity}
            handleClose={this.setMoveTimeClose}
            renderHeader={this.renderHeader}
            listWidth={200}
            searchPlaceholder={'Type or select Work Category'}
            popoverClassName="activities-dropdown"
            placeholderSize={'12px'}
            parentActivities={this.getActivities()}
          />
        )}
      </>
    );
  };

  renderMovePlannedTimeButtons = () => {
    const { moveTimeOpen } = this.state;
    return (
      <>
        <Left>
          <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
        </Left>
        <Right>
          <ConfirmButton
            ref={(ref) => (this.target = ref)}
            onClick={this.setMoveTimeOpen}
          >
            Move
          </ConfirmButton>
          <RemoveButton onClick={this.onDeleteTimeAndRemove}>
            Delete
          </RemoveButton>
        </Right>
        {moveTimeOpen && (
          <ActivitiesDropdown
            target={this.target}
            handleSelect={this.handleSelectActivity}
            handleClose={this.setMoveTimeClose}
            renderHeader={this.renderHeader}
            listWidth={200}
            searchPlaceholder={'Type or select Work Category'}
            popoverClassName="activities-dropdown"
            placeholderSize={'12px'}
            parentActivities={this.getActivities()}
          />
        )}
      </>
    );
  };

  renderHeader = () => {
    return <TaskCategoryHeader>Select Work Category</TaskCategoryHeader>;
  };

  render() {
    const { hasTimeEntries, onlyHasPlannedTime, isLoading, phase } = this.props;
    if (isLoading) {
      return <div />;
    }
    if (hasTimeEntries) {
      return (
        <ModalBody>
          <div>
            <div className="remove-header">
              {getAssociatedTimeMessage(
                hasTimeEntries,
                MODAL_TYPE.ACTIVITY_PHASE
              )}{' '}
            </div>
          </div>
          <ModalButtonsRow>{this.renderOkayButton()}</ModalButtonsRow>
        </ModalBody>
      );
    }
    const membershipCount = phase?.phase_memberships?.filter(
      (member) => !member.discarded_at
    ).length;
    const messageText = ` ${membershipCount} budget${
      membershipCount > 1 ? 's' : ''
    } `;
    return (
      <ModalBody>
        <div>
          <div className="remove-text">
            Are you sure you want to remove the Work Category and the{' '}
            {messageText}
            from current phase?
          </div>
        </div>
        <ModalButtonsRow>{this.renderRemoveButtons()}</ModalButtonsRow>
      </ModalBody>
    );
  }
}

ActivityPhaseModalBody.propTypes = {};

const mapStateToProps = (state) => {
  const hasTimeEntries = getModalActivityPhaseHasTimeEntries(state);

  return {
    allActivities: getAllActivityRowInfo(state),
    nonBillableActivities: getOrderedNonBillableActivities(state),
    billableActivities: getOrderedBillableActivities(state),
    phase: getActivityPhaseModalPhase(state),
    activity: getActivityPhaseModalActivity(state),
    activityId: getActivityPhaseModalActivityId(state),
    // If has time entries OR workplans, prevent deletion
    hasTimeEntries
  };
};

const mapDispatchToProps = {
  updatePhase,
  closeActivityPhaseModal,
  bulkMoveTimeAndRemoveActivity,
  bulkDeleteTimeAndRemoveActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityPhaseModalBody);
