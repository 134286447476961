import React from 'react';
import {
  StyledViewBudgetButtonContainer,
  StyledViewBudgetButton
} from './styles';

const RowActionButton = ({
  handleClick,
  projectId,
  isOpen,
  text,
  renderButton
}) => (
  <StyledViewBudgetButtonContainer>
    <StyledViewBudgetButton
      isOpen={isOpen}
      onClick={(e) => {
        e.stopPropagation();

        handleClick(projectId);
      }}
      className="view-budget-button"
    >
      {renderButton ? renderButton() : text}
    </StyledViewBudgetButton>
  </StyledViewBudgetButtonContainer>
);

export default RowActionButton;
