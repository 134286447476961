import styled from 'styled-components';
import theme from 'theme';
import {
  MemberName,
  MemberRateDescription,
  HourTotal
} from 'BudgetModule/components/styles';
import { StyledMemberCircle } from 'views/memberDisplay/MemberInitials';
import NewTabIcon from 'icons/NewTabIcon';
import { RatesDropdownContainer } from '../styles';
import { NavLink } from 'reactstrap';
import NumberFormat from 'react-number-format';

export const AccessoryText = styled.span`
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
`;
export const Cell = styled.div`
  height: 100%;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  .estimated-hours-form,
  .estimated-cost-form,
  .estimated-hours-form-archived,
  .estimated-cost-form-archived {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    color: ${theme.colors.colorCalendarBlue};
    font-size: 14px;
    border: 1px solid transparent;
    ${AccessoryText} {
      color: ${theme.colors.colorCalendarBlue};
    }
    &:hover,
    &:focus-within {
      border: 1px solid ${theme.colors.colorCalendarBlue};
      color: ${theme.colors.colorCalendarBlue};
    }
    &:focus-within {
      background: ${theme.colors.colorPureWhite};
    }
  }

  .estimated-hours-form-archived,
  .estimated-cost-form-archived {
    &::placeholder {
      color: ${theme.colors.colorSemiDarkGray1};
    }
    color: ${theme.colors.colorSemiDarkGray1};
    ${AccessoryText} {
      color: ${theme.colors.colorMediumGray3} !important;
      position: absolute;
      right: 15px;
    }
    &:hover,
    &:focus-within {
      border: 1px solid transparent;
    }
  }
  .estimated-hours-form {
    ${AccessoryText} {
      position: absolute;
      color: ${theme.colors.colorCalendarBlue};
      right: 15px;
    }
  }
  .estimated-cost-form {
    ${AccessoryText} {
      position: absolute;
      color: ${theme.colors.colorCalendarBlue};
      left: 15px;
    }
  }
  .estimated-hours-input,
  .estimated-cost-input {
    border: 1px solid transparent;
    width: 100%;
    background: transparent;
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${theme.colors.colorCalendarBlue};
    font-size: 14px;
    &::placeholder {
      color: ${theme.colors.colorCalendarBlue};
    }
    &:focus {
      &::placeholder {
        color: ${theme.colors.colorMediumGray3};
      }
    }
  }
  .estimated-hours-input,
  .estimated-cost-input {
    &::placeholder {
      color: ${theme.colors.colorMediumGray1};
    }
  }

  .estimated-hours-form-archived {
    .estimated-hours-input {
      color: ${theme.colors.colorMediumGray3};
      ${AccessoryText} {
        color: ${theme.colors.colorMediumGray3};
        position: absolute;
        right: 15px;
      }
      &::placeholder {
        color: ${theme.colors.colorMediumGray6};
      }
    }
  }
  .estimated-percent-form,
  .estimated-percent-form-disabled {
    height: 100%;
    width: 100%;
    position: relative;
    ${AccessoryText} {
      position: absolute;
      right: 15px;
      color: ${theme.colors.colorCalendarBlue};
    }
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${theme.colors.colorCalendarBlue};
    border: 1px solid transparent;
    &:hover,
    &:focus-within {
      border: 1px solid ${theme.colors.colorCalendarBlue};
    }
    &:focus-within {
      background: ${theme.colors.colorPureWhite};
      color: ${theme.colors.colorCalendarBlue};
    }
  }
  .estimated-percent-input {
    border: 1px solid transparent;
    width: 100%;
    background: transparent;
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${theme.colors.colorCalendarBlue};
    &:disabled {
      color: ${theme.colors.colorDarkGray1};
    }
    &:focus {
      color: ${theme.colors.colorCalendarBlue};
      &::placeholder {
        color: ${theme.colors.colorMediumGray1};
      }
    }
    &::placeholder {
      color: ${theme.colors.colorCalendarBlue};
    }
  }
  .estimated-percent-form-disabled {
    color: ${theme.colors.colorSemiDarkGray1};
    ${AccessoryText} {
      color: ${theme.colors.colorMediumGray3};
    }
    .estimated-percent-input {
      color: ${theme.colors.colorMediumGray6};
      &::placeholder {
        color: ${theme.colors.colorMediumGray6};
      }
    }
    &:hover,
    &:focus-within {
      border: 1px solid transparent;
      ${AccessoryText} {
        color: ${theme.colors.colorMediumGray3} !important;
      }
    }
  }
  &,
  ${AccessoryText} {
    color: ${({ isArchived, isScopeEnabled }) =>
      isScopeEnabled
        ? `${theme.colors.colorMediumGray9} !important`
        : isArchived
        ? `${theme.colors.colorMediumGray9} !important`
        : theme.colors.colorCalendarBlue};
  }
`;

export const MemberThreeDotMenuContainer = styled.div`
  display: ${({ show }) => (show ? 'flex' : '')};
  width: 28px;
  margin-right: -3px;
  transition: 0.275s ease-in-out;
  svg {
    height: 22px;
    stroke-width: 0;
    path {
      fill: ${theme.colors.colorSemiDarkGray1};
      stroke: ${theme.colors.colorSemiDarkGray1};
    }
  }
`;

export const StyledMemberCell = styled(Cell)`
  padding-right: 0;
  ${(props) => props.isReadOnly && 'pointer-events: none;'}

  &:hover {
    ${MemberThreeDotMenuContainer} {
      width: 28px;
    }
    ${MemberName} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }

  ${RatesDropdownContainer} {
    pointer-events: none;
  }
  ${MemberName} {
    top: 1px;
    color: ${theme.colors.colorMediumGray9};
  }
  ${MemberRateDescription} {
    position: relative;
    bottom: 3px;
    font-size: 11px;
    color: ${theme.colors.colorCalendarGray};
  }
  ${StyledMemberCircle} {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
`;

export const CellWithInput = styled(Cell)`
  padding-right: 0;
`;
export const StyledEstimatedHoursPercent = styled(CellWithInput)`
  ${(props) => props.isDisabled && `pointer-events: none;`}
  &:hover {
    &,
    ${AccessoryText} {
      font-weight: bold;
    }
  }
`;
export const StyledEstimatedCostPercent = styled(StyledEstimatedHoursPercent)``;
export const StyledEstimatedHours = styled(CellWithInput)`
  &:hover {
    &,
    ${AccessoryText} {
      font-weight: ${({ isEditing }) => !isEditing && 'bold'};
    }
  }
`;
export const StyledEstimatedCost = styled(CellWithInput)``;

export const StyledHoursCells = styled(Cell)`
  font-size: 14px;
  cursor: ${({ isRemaining }) => (isRemaining ? 'default' : '')};
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: ${({ isSemiBold }) => (isSemiBold ? '600' : '400')};

  ${AccessoryText} {
    color: ${({ isSemiBold }) =>
      isSemiBold ? theme.colors.colorSemiDarkGray1 : ''};
    font-weight: ${({ isSemiBold }) => (isSemiBold ? '600' : '400')};
  }
  ${HourTotal} {
    color: ${theme.colors.colorMediumGray9};
    font-weight: 400 !important;
  }

  ${(props) =>
    props.isArchived &&
    `
      &,
      ${AccessoryText} {
        color: ${theme.colors.colorMediumGray10}
      }
    `}
`;
export const StyledCostCells = styled(StyledHoursCells)`
  ${HourTotal} {
    font-weight: 400 !important;
  }
  cursor: ${({ isRemaining }) => (isRemaining ? 'default' : '')};
  color: ${({ isArchived }) =>
    isArchived
      ? theme.colors.colorMediumGray10
      : theme.colors.colorSemiDarkGray1};
`;
export const StyledEstimatedCostCell = styled(StyledHoursCells)`
  &,
  ${AccessoryText} {
    color: ${({ isArchived, isScopeEnabled }) =>
      isScopeEnabled
        ? `${theme.colors.colorMediumGray9} !important`
        : isArchived
        ? `${theme.colors.colorMediumGray5} !important`
        : theme.colors.colorCalendarBlue};
  }

  &:hover {
    &,
    ${AccessoryText} {
      font-weight: bold;
    }
  }
`;
export const OverbudgetContainer = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorDeleteRed};
  margin-left: 2px;
`;

export const PopoverButton = styled.div`
  height: 37px;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledNavLink = styled(NavLink)`
  height: 37px;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  justify-content: center;
  align-items: center;
  color: unset;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledNewTabIcon = styled(NewTabIcon)`
  ${StyledNavLink}:hover &,
  ${PopoverButton}:hover & {
    path {
      fill: ${(props) => props.theme.colors.colorRoyalBlue};
    }
  }
`;
export const NewTabIconContainer = styled.div`
  padding-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInfoRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 63px 98px;
  align-items: center;
  z-index: 1001;
  font-size: 14px;
  margin-right: ${({ marginRight }) => marginRight ?? '0px'};
  ${StyledEstimatedHoursPercent} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  ${StyledEstimatedHours} {
    justify-content: flex-start;
  }
`;

export const StyledHoursInfoRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1001;
  margin-right: 27px;
`;

export const StyledCellContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  ${({ borderBottomColor }) =>
    borderBottomColor &&
    `
    border-bottom: 1px solid ${borderBottomColor};
  `}
  ${({ isHidingPercentage }) =>
    isHidingPercentage &&
    `
      cursor: pointer;
      ${StyledInfoRow} {
        display: flex;
        justify-content: center;
      }
      ${StyledEstimatedHours}, ${StyledEstimatedCostCell} {
        justify-content: center !important;
      }
  `}
`;

export const StyledVerticalBar = styled.div`
  padding: 0 2px;
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
`;

export const StyledText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledInputContainer = styled.div`
  width: 100%;
  ${AccessoryText} {
    left: 0px !important;
  }
  ${StyledEstimatedCostCell} {
    justify-content: flex-start;
  }
`;

export const FormInput = styled(NumberFormat)``;

export const RemainingHoursContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 36px;
  width: 16px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  ${({ borderBottomColor }) =>
    borderBottomColor &&
    `
    border-bottom: 1px solid ${borderBottomColor};
  `}
`;
