import { updatePhase } from 'actionCreators';
import useCan from 'appUtils/hooks/useCan';
import { editPhaseDates } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';
import { ProjectId } from 'ProjectsModule/models/project';

export const useCanEditPhaseDates = ({
  projectId
}: {
  projectId: ProjectId;
}): boolean => {
  const canEditPhase = useCan(updatePhase, { projectId });
  const canEditPhaseDates = useCan(editPhaseDates, { projectId });

  return canEditPhase || canEditPhaseDates;
};
