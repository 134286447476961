import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { SectionTitle } from './styles';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import moment from 'moment';
import { DEPENDENCY_TYPES } from 'appConstants/workload';

const DatesSection = ({
  startDate,
  endDate,
  handleDatesChange,
  disabled,
  isNew,
  bar,
  dateLabel
}) => {
  const dateContainerRef = useRef(null);
  // FIXME: type is only limited to null | undefined when startDate = null used in prop destructuring
  // null to prevent calendar default opening with today selected
  const startDateToUse = startDate || null;
  const endDateToUse = endDate || null;

  const _handleDatesChange = ({
    startDate,
    endDate,
    dependency,
    dependencyItem
  }) => {
    if (!startDate?.isValid()) {
      return;
    }

    /* }  const newDependencyInfos = dependency
      ? [
          {
            dependency_type: dependency,
            dependable_type: DEPENDENCY_TYPES.WORK_PLAN,
            dependable_id: bar.id
          }
        ]
      : []; */

    handleDatesChange({
      startDate,
      endDate: endDate?.isValid?.() ? endDate : startDate,
      dependencyInfos: []
    });
  };

  const handleClearDates = () => {
    handleDatesChange({
      startDate: null,
      endDate: null
    });
  };

  return (
    <StyledDatesSection
      disabled={disabled}
      className="modal-section"
      onClick={() => dateContainerRef.current?.click()}
    >
      <SectionTitle> {dateLabel || 'PLANNED'}</SectionTitle>
      <DateRangeCalendar
        onSubmit={_handleDatesChange}
        customInput={(startDate, endDate, handleOpen) => {
          return (
            <div
              onClick={disabled ? undefined : handleOpen}
              ref={dateContainerRef}
            >
              {/* Empty state */}
              {!(startDate && endDate) ? (
                <AddDatesButton disabled={disabled}>Set Dates</AddDatesButton>
              ) : (
                <StyledDates>
                  {`${formatDate(startDate)} - ${formatDate(endDate)}`}
                </StyledDates>
              )}
            </div>
          );
        }}
        itemStartDate={startDateToUse}
        itemEndDate={endDateToUse}
        showClear
        onClear={handleClearDates}
        initialDependency={null} // get from backend
        initialDependencyItem={null} // if its set it has to be this bar
        initialDependencyStepItem={isNew ? null : bar}
        initialDependencyItemType={DEPENDENCY_TYPES.WORK_PLAN}
        // isScopeCalendar
        // shouldRenderFooter={!isNew}
      />
    </StyledDatesSection>
  );
};

export default DatesSection;

/* ------------------------------------ - ----------------------------------- */

const dateFormat = 'M/D/YY';

const formatDate = (date) => moment(date).format(dateFormat);

const StyledDates = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 14px;
  min-height: 24px; // match to when AddDatesButton is showing
`;

const AddDatesButton = styled.div`
  border: none;
  background: none;
  font-size: 14px;
  color: ${theme.colors.colorBudgetGrey};
  cursor: default;
  ${(props) =>
    !props.disabled &&
    `
      &:hover {
        color: ${theme.colors.colorRoyalBlue};
        cursor: pointer;
      }
  `}
`;

const StyledDatesSection = styled.div`
  position: relative;
  padding: 3px 0;

  &:hover {
    ${AddDatesButton}, ${StyledDates} {
      color: ${({ disabled }) =>
        disabled
          ? theme.colors.colorSemiDarkGray1
          : theme.colors.colorRoyalBlue};
    }
  }
  ${(props) =>
    !props.disabled &&
    `
      &,
      & > * {
        cursor: pointer;
      }
  `}
`;
