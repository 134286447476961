import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface ArchiveIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  fill?: string;
}

export const ArchiveIcon = ({
  fill = '#4F4F4F',
  width = '7',
  height = '6'
}: ArchiveIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 8 6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0H0.5V1.6H0.911765V6H7.08824V1.6H7.5V0ZM6.67647 5.6H1.32353V1.6H6.67647V5.6ZM7.08824 1.2H0.911765V0.4H7.08824V1.2ZM2.97059 3.6H5.036C5.49018 3.6 5.85953 3.2412 5.85953 2.8C5.85953 2.3588 5.49018 2 5.036 2H2.97059C2.51641 2 2.14706 2.3588 2.14706 2.8C2.14706 3.2412 2.51641 3.6 2.97059 3.6ZM2.97059 2.4H5.036C5.26329 2.4 5.44776 2.5792 5.44776 2.8C5.44776 3.0208 5.26329 3.2 5.036 3.2H2.97059C2.74329 3.2 2.55882 3.0208 2.55882 2.8C2.55882 2.5792 2.74329 2.4 2.97059 2.4Z"
        fill={fill}
      />
    </SvgIcon>
  );
};
