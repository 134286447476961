import styled from 'styled-components';
import theme from 'theme';
import { FullWidthButton } from './styles';
import HelpIcon from 'images/help-icon-2.svg';

const ApproveButton = ({
  handleApprove,
  isDisabled,
  disabledTooltip,
  label = 'Approve',
  tooltip = '',
  hideHelpIcon = false
}) => {
  return (
    <StyledApproveButton
      data-testid="request-approve-button"
      onClick={handleApprove}
      isDisabled={isDisabled}
      data-for="app-tooltip"
      data-tip={disabledTooltip}
      data-effect="solid"
      data-tip-disable={!isDisabled}
      data-html
    >
      {label}
      {!hideHelpIcon && (
        <img
          data-for="app-tooltip"
          data-class="center"
          data-tip={tooltip}
          data-effect="solid"
          src={HelpIcon}
          style={approveTooltipIconStyle}
        />
      )}
    </StyledApproveButton>
  );
};

export default ApproveButton;

/* ------------------------------------ - ----------------------------------- */

const StyledApproveButton = styled(FullWidthButton)`
  font-weight: 600;
  color: ${theme.colors.colorCalendarBlue};
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 4px;
  margin-top: 25px;
  ${(props) =>
    props.isDisabled &&
    `
      opacity: 0.9;
      cursor: not-allowed !important;
      background: ${theme.colors.colorLightGray8};
      color: white;
      border-color: ${theme.colors.colorLightGray8};
    `}
`;

const approveTooltipIconStyle = {
  marginLeft: 3,
  width: 7,
  marginBottom: 10,
  filter: 'brightness(0.9)'
};
