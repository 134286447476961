import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CustomHeader } from '..';
import {
  makeGetPlannerColumn,
  makeIsDropDisabled,
  getNavigationHistory
} from 'selectors';
import PlannerColumnDroppable from './PlannerColumnDroppable';
import moment from 'moment';

const UnmemoizedPlannerColumn = ({
  isDropDisabled,
  lane,
  unscheduled,
  isOpen,
  onClose,
  toggleBulkUpdateModal,
  pastScheduledTasksCount,
  accountId,
  rowIndex,
  searchText,
  updateSearchText,
  clearSearchText,
  filterTasks,
  rowHeight,
  navigationHistory,
  isFooter
}) => {
  const tasks = unscheduled ? filterTasks(lane.tasks) : lane.tasks;
  const headerMoment = moment(lane.date, 'YYYY-MM-DD');
  const isToday = headerMoment.isSame(moment(), 'day');

  return (
    <div
      className={cn(`droppable-lane date-${lane.date}`, {
        'unscheduled-slideout-menu': unscheduled,
        show: unscheduled && isOpen,
        'is-today': isToday
      })}
    >
      {rowIndex > 0 && <div className="white-padding" />}
      <CustomHeader
        title={headerMoment}
        isToday={isToday}
        unscheduled={unscheduled}
        onClose={onClose}
        pastScheduledTasksCount={pastScheduledTasksCount}
        toggleBulkUpdateModal={toggleBulkUpdateModal}
        index={rowIndex}
      />
      {unscheduled && (
        <div className="unscheduled-filter-container">
          <input
            className="unscheduled-filter"
            type="text"
            placeholder="Type name, project, or task"
            value={searchText}
            onChange={updateSearchText}
          />
          {!!searchText.length && (
            <div
              className="unscheduled-filter-clear"
              onClick={clearSearchText}
            />
          )}
        </div>
      )}
      {rowIndex !== -1 && (
        <PlannerColumnDroppable
          rowHeight={rowHeight}
          unscheduled={unscheduled}
          isDropDisabled={isDropDisabled}
          date={lane.date}
          accountId={accountId}
          tasks={tasks}
          navigationHistory={navigationHistory}
          isFooter={isFooter}
        />
      )}
    </div>
  );
};
const PlannerColumn = React.memo(UnmemoizedPlannerColumn);

class DoNotUpdate extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.lane.tasks !== nextProps.lane.tasks ||
      this.props.searchText !== nextProps.searchText ||
      this.props.isDropDisabled !== nextProps.isDropDisabled ||
      this.props.rowIndex !== nextProps.rowIndex
    ) {
      return true;
    }
    return false;
  }

  render() {
    return <PlannerColumn {...this.props} />;
  }
}

const makeMapStateToProps = () => {
  const getPlannerColumn = makeGetPlannerColumn();
  const isDropDisabled = makeIsDropDisabled();
  const mapStateToProps = (state, ownProps) => ({
    lane: getPlannerColumn(state, ownProps),
    isDropDisabled: isDropDisabled(state, ownProps),
    navigationHistory: getNavigationHistory(state)
  });

  return mapStateToProps;
};

export default withRouter(connect(makeMapStateToProps)(DoNotUpdate));
