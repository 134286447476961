import styled from 'styled-components';
import { SectionTitle } from './styles';
import { CommentsContainer } from 'views/comments/CommentsContainer';
import { EntityType } from 'models/entity';
import { GetCommentsPermission } from 'views/comments/CommentRow';

interface CommentsSectionProps {
  entityType: EntityType;
  parentEntityId: number;
  isDisabled?: boolean;
  noTitle?: boolean;
  getCommentsPermission: GetCommentsPermission;
  editCommentDisabledTooltip: string;
  deleteCommentDisabledTooltip: string;
}

export const CommentsSectionV2 = ({
  entityType,
  noTitle,
  parentEntityId,
  isDisabled,
  getCommentsPermission,
  editCommentDisabledTooltip,
  deleteCommentDisabledTooltip
}: CommentsSectionProps) => {
  return (
    <StyledCommentsSection>
      {!noTitle && <SectionTitle>COMMENTS</SectionTitle>}
      <CommentsContainer
        parentEntityType={entityType}
        parentEntityId={parentEntityId}
        isDisabled={isDisabled}
        getCommentsPermission={getCommentsPermission}
        editCommentDisabledTooltip={editCommentDisabledTooltip}
        deleteCommentDisabledTooltip={deleteCommentDisabledTooltip}
      />
    </StyledCommentsSection>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledCommentsSection = styled.div`
  padding-top: 20px;
  position: relative;
  svg {
    margin: 0 9px 1px 4px;
  }

  .comments-container {
    .add-comment-container {
      .member-initials {
        display: none;
      }
    }
    .add-comment-container,
    .comment-thread-container {
      margin-left: 0;
      margin-right: 0;
    }
    .add-comment-header {
      display: none; // hide default header
    }
  }
`;
