import React, { useRef } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { TextAreaWithSave } from 'components/Modals/themes/TwoPaneModal';

/**
 * For wrapping styles and providing default props. Handle logic in parent
 */
const DescriptionSection = ({
  text,
  placeholder = 'Enter Scope',
  onChange,
  onSave,
  autoFocus,
  error,
  testId = 'description-section',
  maxCharLength = 1000,
  shouldBlurOnEnter = true,
  isRequestModal,
  disabled
}) => {
  const inputRef = useRef(null);
  return (
    <DescriptionSectionContainer
      disabled={disabled}
      isRequestModal={isRequestModal}
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      <TextAreaWithSave
        text={text}
        placeholder={placeholder}
        onChange={onChange}
        onSave={onSave}
        autoFocus={autoFocus}
        error={error}
        testId={testId}
        maxCharLength={maxCharLength}
        shouldBlurOnEnter={shouldBlurOnEnter}
        disabled={disabled}
        forceInput
        forwardedRef={inputRef}
      />
    </DescriptionSectionContainer>
  );
};

export default DescriptionSection;

/* ------------------------------------ - ----------------------------------- */

const DescriptionSectionContainer = styled.div`
  .text-area {
    border-radius: 3px;
    padding: 10px;
    &:focus {
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }

  &:hover {
    .text-area::placeholder,
    .text-area {
      color: ${({ disabled }) =>
        disabled
          ? theme.colors.colorBudgetGrey
          : theme.colors.colorCalendarBlue};
    }

    .text-area:focus::placeholder {
      color: ${theme.colors.colorBudgetGrey};
    }

    .text-area:focus {
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }
  ${(props) =>
    !props.isRequestModal
      ? `
        .text-area {
          font-weight: 600;
        }
      `
      : ''}
  ${(props) =>
    !props.disabled &&
    `
      &,
      & > * {
        cursor: pointer;
      }
  `}
`;
