import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import reject from 'lodash/reject';
import uniqBy from 'lodash/uniqBy';
import isUndefined from 'lodash/isUndefined';
import * as constants from 'appConstants';
import * as noteConstants from '../constants';
const shouldFilterNotes = (currentNotes, serverData) => {
  // Used to determine whether to add to currentNotes (infinite scroll)
  // or to replace with newly fetched notes

  if (serverData.requestPayload) {
    return (
      !currentNotes.length ||
      serverData.requestPayload.params.searchText ||
      serverData.requestPayload.params.accountIds ||
      serverData.requestPayload.params.tagIds
    );
  } else {
    return !currentNotes.length;
  }
};

export const initialState = {
  isFetchingNotes: false,
  notesArray: [],
  filteringNotes: false,
  isAddNoteModalOpen: false,
  isEditNoteModalOpen: false,
  isDeleteNoteModalOpen: false,
  tempId: null,
  editingNoteId: '',
  selectedAccountIds: [],
  selectedTagIds: [],
  activityFeedNotes: {},
  addingNewNote: false
};

const notes = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.WS_PROJECT_NOTE: {
      const { userId, origin, added, deleted, ...responseNote } =
        action.payload;
      let notesArray;
      if (added && userId !== origin) {
        notesArray = [responseNote, ...state.notesArray];
      } else if (deleted) {
        notesArray = state.notesArray.filter(
          (note) => note.id !== responseNote.id
        );
      } else {
        notesArray = state.notesArray.map((note) =>
          note.id === responseNote.id ? responseNote : note
        );
      }
      return {
        ...state,
        notesArray
      };
    }
    case constants.LOCATION_CHANGE:
      return {
        ...state,
        isAddNoteModalOpen: false,
        isEditNoteModalOpen: false,
        isDeleteNoteModalOpen: false
      };

    // Ensure tasks and filters clear when unmounted
    case constants.CLEAR_NOTES:
      return {
        ...state,
        notesArray: [],
        filteringNotes: false,
        selectedAccountIds: []
      };
    case noteConstants.FETCH_NOTE.SUCCESS: {
      const note = action.payload.response.note;
      return {
        ...state,
        activityFeedNotes: {
          ...state.activityFeedNotes,
          [note.id]: note
        }
      };
    }
    case noteConstants.FETCH_NOTES_BY_PROJECT.TRIGGER:
      const { accountIds, searchText, tagIds } = action.payload.params;
      const accountIdsPresent = accountIds && accountIds.length > 0;
      const tagIdsPresent = (tagIds && tagIds.length > 0) || tagIds > 0;
      const shouldPopulateAccountIds =
        accountIdsPresent || (accountIdsPresent && searchText);
      const shouldPopulateTagIds =
        tagIdsPresent || (tagIdsPresent && searchText);
      const shouldPopulateAccountAndTagIds =
        (accountIdsPresent && tagIdsPresent) ||
        (accountIds && tagIdsPresent && searchText);

      if (shouldPopulateAccountAndTagIds) {
        return {
          ...state,
          selectedAccountIds: accountIds,
          selectedTagIds: tagIds,
          filteringNotes: true
        };
      } else if (shouldPopulateAccountIds) {
        return {
          ...state,
          selectedAccountIds: accountIds,
          selectedTagIds: [],
          filteringNotes: true
        };
      } else if (shouldPopulateTagIds) {
        return {
          ...state,
          selectedAccountIds: [],
          selectedTagIds: tagIds,
          filteringNotes: true
        };
      } else if (searchText) {
        return {
          ...state,
          selectedAccountIds: [],
          selectedTagIds: [],
          filteringNotes: true
        };
      } else {
        return {
          ...state,
          selectedAccountIds: [],
          selectedTagIds: [],
          filteringNotes: false
        };
      }
    case noteConstants.FETCH_NOTES_BY_PROJECT.REQUEST:
      return {
        ...state,
        isFetchingNotes: true
      };
    case noteConstants.FETCH_NOTES_BY_PROJECT.SUCCESS:
      if (shouldFilterNotes(state.notesArray, action.payload)) {
        return {
          ...state,
          isFetchingNotes: false,
          totalNotes: action.payload.response.total_count,
          notesArray: action.payload.response.notes,
          addingNewNote: false,
          activityFeedNotes: {
            ...state.activityFeedNotes,
            ...keyBy(action.payload.response.notes, 'id')
          }
        };
      } else {
        // Triggered from infinite scroll
        return {
          ...state,
          isFetchingNotes: false,
          notesArray: sortBy(
            uniqBy(
              [...action.payload.response.notes, ...state.notesArray],
              (note) => note.id
            ),
            (note) => note.position
          ),
          addingNewNote: false,
          activityFeedNotes: {
            ...state.activityFeedNotes,
            ...keyBy(action.payload.response.notes, 'id')
          }
        };
      }

    case constants.OPEN_ADD_NOTE_MODAL:
      return {
        ...state,
        isAddNoteModalOpen: true
      };
    case noteConstants.NOTE_CREATION.TRIGGER: {
      const { isAddNoteModalOpen } = state;
      const { tempId, title, slateBody } = action.payload;
      const tempNote = {
        id: tempId,
        title,
        slateBody
      };
      return {
        ...state,
        editingNoteId: isAddNoteModalOpen ? tempId : null,
        newNoteTempId: isAddNoteModalOpen ? tempId : null,
        notesArray: [tempNote, ...state.notesArray],
        addingNewNote: true
      };
    }
    case noteConstants.NOTE_CREATION.SUCCESS: {
      const { isAddNoteModalOpen } = state;
      return {
        ...state,
        editingNoteId: isAddNoteModalOpen ? action.payload.response.id : null,
        newNoteTempId: isAddNoteModalOpen ? action.payload.response.id : null,
        notesArray: [action.payload.response, ...state.notesArray.slice(1)],
        addingNewNote: false
      };
    }
    case noteConstants.NOTE_CREATION.FAILURE:
      return {
        ...state,
        addingNewNote: false
      };
    case constants.CLOSE_ADD_NOTE_MODAL:
      return {
        ...state,
        editingNoteId: null,
        isAddNoteModalOpen: false
      };
    case constants.OPEN_EDIT_NOTE_MODAL:
      return {
        ...state,
        isEditNoteModalOpen: true,
        editingNoteId: action.payload.noteId
      };
    case constants.CLOSE_EDIT_NOTE_MODAL:
      return {
        ...state,
        isEditNoteModalOpen: false,
        editingNoteId: ''
      };
    case constants.OPEN_DELETE_NOTE_MODAL:
      return {
        ...state,
        isDeleteNoteModalOpen: true
      };
    case constants.CLOSE_DELETE_NOTE_MODAL:
      return {
        ...state,
        isDeleteNoteModalOpen: false
      };
    case noteConstants.DELETE_NOTE.TRIGGER:
      return {
        ...state,
        totalNotes: state.totalNotes - 1,
        notesArray: reject(
          state.notesArray,
          (note) => note.id === action.payload.noteId
        ),
        isDeleteNoteModalOpen: false,
        isEditNoteModalOpen: false,
        editingNoteId: '',
        activityFeedNotes: omit(state.activityFeedNotes, [
          action.payload.noteId
        ])
      };
    case noteConstants.NOTE_UPDATE.TRIGGER:
      const { noteId, title, slateBody } = action.payload;
      const updatedNote = omitBy({ title, slateBody }, isUndefined);

      return {
        ...state,
        notesArray: state.notesArray.map((note) => {
          if (note.id === noteId) {
            return Object.assign({}, note, updatedNote);
          } else {
            return note;
          }
        }),
        activityFeedNotes: {
          ...state.activityFeedNotes,
          [noteId]: {
            ...state.activityFeedNotes[noteId],
            ...updatedNote
          }
        }
      };
    default:
      return state;
  }
};

export default notes;
