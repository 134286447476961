import { BlueSubmitButton, TextButtonWithBorder } from 'components/styles';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import styled from 'styled-components';
import theme from 'theme';
import { FindPeopleModalContextValuesType } from 'SuggestionModule/components/FindPeople/contexts/types';
import CollapseAllToggle from 'BudgetModule/components/BudgetTable/shared/CollapseAllToggle';
import cn from 'classnames';

const Header = ({
  openAddRemoveUnassignedRoleFromPhasesDropdown,
  openBudgetModal,
  activePhasesNum,
  allCollapsed,
  toggleCollapseAll
}: {
  openAddRemoveUnassignedRoleFromPhasesDropdown: FindPeopleModalContextValuesType['openAddRemoveUnassignedRoleFromPhasesDropdown'];
  openBudgetModal: FindPeopleModalContextValuesType['openBudgetModal'];
  activePhasesNum: FindPeopleModalContextValuesType['activePhasesNum'];
  toggleCollapseAll: () => void;
  allCollapsed: boolean;
}) => {
  return (
    <StyledContainer className={cn({ 'no-phases': !activePhasesNum })}>
      <div className="middle-panel-header">
        <div>
          <CollapseAllToggle
            isHidden={undefined}
            allCollapsed={allCollapsed}
            toggle={toggleCollapseAll}
            top={0}
            left={undefined}
          />
        </div>
        <div className="phase-selector-container">
          <TextButtonWithBorder
            className="add-phase-button"
            onClick={(event) =>
              openAddRemoveUnassignedRoleFromPhasesDropdown({
                event
              })
            }
          >
            {`${
              activePhasesNum > 0
                ? `${activePhasesNum} ${
                    activePhasesNum > 1 ? 'Phases' : 'Phase'
                  }`
                : '+ Phases'
            }`}
          </TextButtonWithBorder>
        </div>
        <div className="buttons-container">
          <StyledButton onClick={openBudgetModal} className="budget">
            <GoToProjectIcon />
            Budget
          </StyledButton>
        </div>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  &.no-phases {
    .app-cues-expand-collapse-all {
      visibility: hidden;
    }
  }
  .middle-panel-header {
    display: flex;

    .app-cues-expand-collapse-all {
      position: relative;
      left: 9px;
      border: none;

      &:hover {
        background-color: ${theme.colors.colorPaleGray5};
        path {
          fill: ${theme.colors.colorRoyalBlue};
        }
      }
    }

    .phase-selector-container {
      padding-left: 14px;
      width: fit-content;
      cursor: pointer;
      color: ${theme.colors.colorCalendarBlue};

      .add-phase-button {
        font-weight: 600;
        border-radius: 3px;
        border: 1px solid ${theme.colors.colorPaleBlue};
        cursor: pointer;
        color: ${theme.colors.colorCalendarBlue};
        height: 24px;
        font-size: 13px;
        filter: none;
        padding: 9px;
      }

      path {
        fill: ${theme.colors.colorCalendarBlue};
      }

      &:hover {
        font-weight: 600;
      }

      .toggle-container {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .toggle-text {
        font-size: 20px;
        font-weight: 600;
        color: ${theme.colors.colorMediumGray9};
      }
    }

    .buttons-container {
      margin-left: auto;
      margin-right: 4rem;
    }
  }
`;

const StyledButton = styled(BlueSubmitButton)`
  width: fit-content;
  border-radius: 20px;
  font-weight: 600;

  &:focus {
    filter: none; // prevent background turning grey after click
  }

  &.budget {
    background-color: ${theme.colors.colorPureWhite};
    color: ${theme.colors.colorCalendarBlue};
    border: 0.5px solid ${theme.colors.colorCalendarBlue};
    font-size: 13px;
    height: 24px;
    padding: 0 9px 0 4px;

    &:hover {
      background-color: ${theme.colors.colorRoyalBlue};
      color: ${theme.colors.colorPureWhite};

      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export default Header;
