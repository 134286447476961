import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

const TasksListContainer = lazy(() => import('./TasksListContainer'));
const LoadTasksListContainer = (props) => {
  return (
    <DynamicModuleLoader modules={[getBudgetModule()]}>
      <Suspense fallback={<div />}>
        <TasksListContainer />
      </Suspense>
    </DynamicModuleLoader>
  );
};
export default LoadTasksListContainer;
