import { TableV2, TableProps } from 'components/Table/TableV2';
import { useMembershipTableData } from 'PermissionsModule/components/settingsViews/members/DataProvider';
import { permissionConstants } from 'PermissionsModule/constants';
import useCollapse from 'appUtils/hooks/useCollapse';
import noop from 'lodash/noop';
import { BaseTableList } from 'components/Table/types';
import { useMemberGroupedListsBuilder } from 'PermissionsModule/components/settingsViews/members/MembersPermissionsTable/helpers/useMemberPermissionListItemsBuilder';
import { useMemberListItemsBuilder } from './helpers/useMemberListItemsBuilder';
import { EditMemberMetadataModalParams, MemberListItemsBuilder } from './types';
import { membersTimesheetSettingsTableHelpers } from './helpers/layout';
import { ContainerDiv, StyledMembersTimesheetSettingsTable } from './styles';
import { EditMemberMetadataModal } from 'TeamsModule/components/EditMemberMetadataModal';
import { useState } from 'react';
const { EMPLOYMENT_TYPES } = permissionConstants;

const {
  columnWidths,
  totalColumnWidths,
  allColumns,
  ROW_TYPES,
  rowToCells,
  stickyHeaderCells,
  emptyRow,
  headersToIgnore,
  maxTotalWidth
} = membersTimesheetSettingsTableHelpers;

const groupedListOrder = [
  EMPLOYMENT_TYPES.member,
  EMPLOYMENT_TYPES.internalContractor,
  EMPLOYMENT_TYPES.externalProjectContractor
];

export const MembersTimesheetSettingsTable = () => {
  const { groupTotalCounts, ordersByGroup } = useMembershipTableData();

  const [editMemberMetadataModalParams, setEditMemberMetadataModalParams] =
    useState<EditMemberMetadataModalParams>();

  const { toggleCollapse, getIsOpen } = useCollapse({
    toggleCallback: noop,
    defaultAllOpen: true,
    topLevelCollapseCount: groupedListOrder.length
  });

  const memberGroupedListsBuilder =
    useMemberGroupedListsBuilder<MemberListItemsBuilder>({
      getIsOpen,
      toggleCollapse,
      ordersByGroup,
      groupTotalCounts,
      listItemsBuilder: useMemberListItemsBuilder({
        onRequestEditMemberMetadata: setEditMemberMetadataModalParams
      })
    });

  const listItems = memberGroupedListsBuilder({
    order: groupedListOrder,
    parentGroupId: 'main'
  });

  const listItemsWithProjectGuestFooter = [
    ...listItems,
    { rowHeight: 50, rowType: ROW_TYPES.projectGuestFooter }
  ];
  const mainList: BaseTableList = {
    listItems: listItemsWithProjectGuestFooter,
    isList: true,
    id: 'main',
    isOpen: true,
    isFullyLoaded: true,
    skipHeader: true,
    addEmptyRow: true
  };

  const tableProps: TableProps = {
    mainList,
    totalColumnsWidthOverride: totalColumnWidths,
    columnWidthsByHeaderType: columnWidths,
    tableColumns: allColumns,
    rowTypesHash: ROW_TYPES,
    rowTypeToCellsByHeaderType: rowToCells,
    stickyHeaderCells,
    isStickyHeaderHidden: false,
    hasStickyHeader: true,
    maxHeight: window.innerHeight,
    showHeader: false,
    emptyRow,
    headersToIgnore
  };

  return (
    <ContainerDiv $width={maxTotalWidth + 270}>
      <StyledMembersTimesheetSettingsTable>
        <TableV2 {...tableProps} />
      </StyledMembersTimesheetSettingsTable>
      {editMemberMetadataModalParams && (
        <EditMemberMetadataModal
          teamMembershipId={editMemberMetadataModalParams.teamMembershipId}
          onToggle={() => setEditMemberMetadataModalParams(undefined)}
        />
      )}
    </ContainerDiv>
  );
};
