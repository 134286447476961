import * as constants from '../constants/discipline';
import { action } from 'appUtils';

export const fetchDisciplinesActionCreatorsMap = {
  request: (id, requestPayload) =>
    action(constants.FETCH_DISCIPLINES.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_DISCIPLINES.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_DISCIPLINES.FAILURE, {
      payload: { error },
      meta
    })
};

export const createDisciplineActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.CREATE_DISCIPLINE.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.CREATE_DISCIPLINE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_DISCIPLINE.FAILURE, {
      payload: { error }
    })
};

export const updateDisciplineActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_DISCIPLINE.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.UPDATE_DISCIPLINE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_DISCIPLINE.FAILURE, {
      payload: { error }
    })
};

export const deleteDisciplineActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.DELETE_DISCIPLINE.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.DELETE_DISCIPLINE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_DISCIPLINE.FAILURE, {
      payload: { error }
    })
};

export const updateDisciplineEntitiesActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_DISCIPLINE_ENTITIES.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.UPDATE_DISCIPLINE_ENTITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_DISCIPLINE_ENTITIES.FAILURE, {
      payload: { error }
    })
};
