import { getAuthToken } from 'AuthenticationModule/selectors';
import { put, select } from 'redux-saga/effects';
import { changeEntity, fetchEntity } from 'sagas/generics';
import {
  createEntityRateApiRequest,
  createRateGroupApiRequest,
  deleteEntityRateApiRequest,
  deleteRateGroupApiRequest,
  fetchRatesApiRequest,
  updateEntityRateApiRequest,
  updateRateGroupApiRequest
} from '../api/ratesApi';
import {
  createRateGroup,
  createRateGroupApiActions,
  deleteRateGroup,
  deleteRateGroupApiActions,
  fetchRateEntitiesApiActions,
  fetchRateGroups,
  fetchRateGroupsApiActions,
  fetchRates,
  fetchRatesApiActions,
  updateRateGroup,
  updateRateGroupApiActions,
  fetchEntityRateHistory,
  createEntityRate,
  createEntityRateApiActions,
  updateEntityRate,
  fetchEntityRateHistoryApiActions,
  deleteEntityRate,
  updateEntityRateApiActions,
  deleteEntityRateApiActions
} from '../ratesActionCreators';
import { fetchLeanApi } from 'LeanApiModule/api';
import { handleErrorMessage } from 'actionCreators';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import { fetchActivities } from 'service/api';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { TeamId } from 'TeamsModule/models/team';

const RATE_GROUP_ORDER = {
  is_default: 'desc',
  name: 'asc'
} as const;

export function* fetchRatesWorker(action: ReturnType<typeof fetchRates>) {
  const { payload } = action;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  yield changeEntity(
    fetchRatesApiActions,
    fetchRatesApiRequest,
    [token, payload],
    action
  );
}

export function* fetchRateGroupsWorker(
  action: ReturnType<typeof fetchRateGroups>
) {
  const {
    payload: { active_entity_type: entityType, team_id }
  } = action;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;
  const selectedTeamId = (yield select(getSelectedTeamId)) as
    | TeamId
    | undefined;
  const teamIdToUse = team_id ?? selectedTeamId;

  const rateGroupsApiParams = {
    all: true,
    entity_type: 'rate_group',
    exclude_value_counts: true,

    // Query
    select: {
      // RateGroup fields
      active_entity_type: 'active_entity_type',
      archived_at: 'archived_at',
      created_at: 'created_at',
      currency: 'currency',
      description: 'description',
      id: 'id',
      is_default: 'is_default',
      name: 'name',
      updated_at: 'updated_at',
      entity_rates: {
        // EntityRate fields
        archived_at: 'er_archived_at',
        created_at: 'er_created_at',
        discarded_at: 'er_discarded_at',
        end_date: 'er_end_date',
        entity_id: 'er_entity_id',
        entity_type: 'er_entity_type',
        id: 'er_id',
        is_cost_rate: 'er_is_cost_rate',
        rate_group_id: 'er_rate_group_id',
        rate_id: 'er_rate_id',
        start_date: 'er_start_date',
        updated_at: 'er_updated_at'
      }
    },
    where: {
      active_entity_type: entityType,
      entity_rates: { end_date: null },
      team_id: teamIdToUse
    },
    order: RATE_GROUP_ORDER
  };

  yield changeEntity(
    fetchRateGroupsApiActions,
    fetchLeanApi,
    [token, rateGroupsApiParams],
    action
  );
}

export function* createRateGroupWorker(
  action: ReturnType<typeof createRateGroup>
) {
  const { payload } = action;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const { error } = yield changeEntity(
    createRateGroupApiActions,
    createRateGroupApiRequest,
    [token, payload],
    action
  );

  if (!error) {
    yield fetchRateGroupsWorker(
      fetchRateGroups({ active_entity_type: action.payload.active_entity_type })
    );
  } else if (
    typeof error === 'object' &&
    'name' in error &&
    error.name.includes('has already been taken')
  ) {
    yield put(
      handleErrorMessage({
        type: action.type,
        isUserFriendlyError: true,
        isRefreshRequiredError: true,
        errorMessage: 'A rate group with that name already exists.'
      })
    );
  }
}

export function* updateRateGroupWorker(
  action: ReturnType<typeof updateRateGroup>
) {
  const { active_entity_type, ...params } = action.payload;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const { error } = yield changeEntity(
    updateRateGroupApiActions,
    updateRateGroupApiRequest,
    [token, params],
    action
  );

  if (!error) {
    yield fetchRateGroupsWorker(fetchRateGroups({ active_entity_type }));
  } else if (
    typeof error === 'object' &&
    'name' in error &&
    error.name.includes('has already been taken')
  ) {
    yield put(
      handleErrorMessage({
        type: action.type,
        isUserFriendlyError: true,
        isRefreshRequiredError: true,
        errorMessage: 'A rate group with that name already exists.'
      })
    );
  }
}

export function* deleteRateGroupWorker(
  action: ReturnType<typeof deleteRateGroup>
) {
  const { active_entity_type, ...params } = action.payload;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const { error } = yield changeEntity(
    deleteRateGroupApiActions,
    deleteRateGroupApiRequest,
    [token, params],
    action
  );

  if (!error) {
    yield fetchRateGroupsWorker(fetchRateGroups({ active_entity_type }));
  }
}

export function* fetchRateEntitiesWorker(
  action: ReturnType<typeof fetchRateGroups>
) {
  const {
    payload: { active_entity_type: entityType }
  } = action;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  if (entityType === EntityRateType.Role)
    yield changeEntity(
      fetchRateEntitiesApiActions,
      fetchLeanApi,
      [
        token,
        {
          all: true,
          entity_type: 'position',
          exclude_value_counts: true,

          // Query
          select: {
            id: 'id',
            name: 'name',
            discarded_at: 'archived_at'
          },
          order: {
            name: 'asc'
          }
        }
      ],
      action
    );
  else
    yield fetchEntity(
      fetchRateEntitiesApiActions,
      fetchActivities,
      undefined,
      [token],
      action
    );
}

export function* fetchEntityRateHistoryWorker(
  action: ReturnType<typeof fetchEntityRateHistory>
) {
  const {
    payload: { entity_id, is_cost_rate }
  } = action;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const entityRateApiParams = {
    all: true,
    entity_type: 'rate_group',
    exclude_value_counts: true,

    // Query
    select: {
      entity_rates: {
        archived_at: 'archived_at',
        created_at: 'created_at',
        discarded_at: 'discarded_at',
        end_date: 'end_date',
        entity_id: 'entity_id',
        entity_type: 'entity_type',
        id: 'id',
        is_cost_rate: 'is_cost_rate',
        rate_group_id: 'rate_group_id',
        rate_id: 'rate_id',
        start_date: 'start_date',
        updated_at: 'updated_at'
      }
    },
    where: {
      entity_rates: {
        entity_id,
        is_cost_rate: !!is_cost_rate
      }
    },
    order: {
      entity_rates: {
        is_cost_rate: 'asc',
        start_date: 'desc'
      }
    }
  };

  yield changeEntity(
    fetchEntityRateHistoryApiActions,
    fetchLeanApi,
    [token, entityRateApiParams],
    action
  );
}

export function* createEntityRateWorker(
  action: ReturnType<typeof createEntityRate>
) {
  const { payload } = action;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const { error } = yield changeEntity(
    createEntityRateApiActions,
    createEntityRateApiRequest,
    [token, payload],
    action
  );

  if (!error) {
    yield fetchEntityRateHistoryWorker(
      fetchEntityRateHistory({
        entity_id: payload.entity_id,
        is_cost_rate: payload.is_cost_rate,
        rate_group_id: payload.rate_group_id
      })
    );
  }
}

export function* updateEntityRateWorker(
  action: ReturnType<typeof updateEntityRate>
) {
  const { entity_id, rate_group_id, ...params } = action.payload;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const { error } = yield changeEntity(
    updateEntityRateApiActions,
    updateEntityRateApiRequest,
    [token, params],
    action
  );

  if (!error) {
    yield fetchEntityRateHistoryWorker(
      fetchEntityRateHistory({
        entity_id,
        is_cost_rate: params.is_cost_rate,
        rate_group_id
      })
    );
  }
}

export function* deleteEntityRateWorker(
  action: ReturnType<typeof deleteEntityRate>
) {
  const { entity_id, is_cost_rate, rate_group_id, ...params } = action.payload;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  const { error } = yield changeEntity(
    deleteEntityRateApiActions,
    deleteEntityRateApiRequest,
    [token, params],
    action
  );

  if (!error) {
    yield fetchEntityRateHistoryWorker(
      fetchEntityRateHistory({
        entity_id,
        is_cost_rate,
        rate_group_id
      })
    );
  }
}
