import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { noop } from 'appUtils';
import CreateTaskWrapper from '../Tasks/CreateTaskWrapper';
import TaskCircle from '../Tasks/TaskCircle';
import { calculateHeaderWidth } from '../TaskList/styles';
import {
  getIsOnTeamProject,
  getIsOnPersonalProject,
  getAuth,
  getAllTasks,
  isOnTeamMemberProfile,
  getTaskColumnOrderWithSelection,
  isSomeTaskEditing,
  getIsOnProjectView
} from 'selectors';
import {
  setEditTaskId,
  toggleTaskCreation,
  flushBatchSelectedTasks
} from 'actionCreators';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import theme from 'theme';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  padding-left: ${(props) =>
    props.paddingLeft ||
    (props.isOnHomeView && props.isCreatingNewTask
      ? 8
      : props.isOnPersonalProject && props.isCreatingNewTask
      ? 32
      : 0)}px;
  margin-left: ${(props) =>
    props.isOnHomeView
      ? 22
      : props.isOnPersonalProject
      ? 0
      : props.isOnProjectView
      ? 28
      : 22}px;
  width: ${(props) =>
    props.isOnPersonalProject
      ? '890px'
      : props.isTaskListEmpty && props.activeTaskColumns
      ? calculateHeaderWidth(props.activeTaskColumns) - 33 + 'px'
      : 'auto'};
  min-height: 60px;

  height: ${({ isCreatingNewTask }) =>
    isCreatingNewTask ? 'fit-content' : '60px'};
  min-width: 465px;
  max-width: 918px;
  margin-bottom: ${({ position, isOnHomeView }) =>
    isOnHomeView ? '0px' : position === 0 ? '4px' : '30px'};
  background-color: ${({
    isCreatingNewTask,
    isOnHomeView,
    isOnPersonalProject
  }) =>
    (isOnHomeView || isOnPersonalProject) && isCreatingNewTask
      ? theme.colors.colorTranslucentGray4
      : 'transparent'};

  &:hover {
    background-color: ${({ position }) =>
      position === -1 && theme.colors.colorTranslucentGray4};
  }

  .home-create-task {
    padding-right: 16px;
  }
`;

const TaskHeaderActionsWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-flow: row nowrap;
  align-items: flex-end;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  background-color: ${({
    isCreatingNewTask,
    isEditing,
    isOnHomeView,
    isOnPersonalProject
  }) =>
    isCreatingNewTask || (isEditing && !isOnHomeView && !isOnPersonalProject)
      ? theme.colors.colorTranslucentGray4
      : theme.colors.colorPureWhite};
  box-shadow: ${({ isCreatingNewTask, position }) =>
    isCreatingNewTask && position === 0 ? '0 0 4px rgba(0,0,0,0.1)' : 'none'};
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #eaeaea')};

  &:hover {
    box-shadow: ${({ isOnHomeView, isSomeTaskEditing, isOnPersonalProject }) =>
      isOnPersonalProject || isOnHomeView || isSomeTaskEditing
        ? 'none'
        : '0px 0px 4px rgba(0, 0, 0, 0.1)'};
  }
`;

const AddTaskButton = styled.div`
  cursor: ${({ shouldRenderCreateTaskButton }) =>
    shouldRenderCreateTaskButton ? 'pointer' : 'auto'};
  display: flex;
  align-items: center;
  height: 100%;
  flex: auto;
  visibility: hidden;
  padding-left: ${({ isOnPersonalProject }) =>
    isOnPersonalProject ? '35px' : '6px'};
  min-width: ${({ isOnHomeView }) => (isOnHomeView ? '521px' : '477px')};

  ${Wrapper}:hover & {
    visibility: visible;
  }

  svg {
    color: ${theme.colors.colorMediumGray5};
  }

  &:hover {
    div {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;
const AddTaskPlusIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  flex: auto;
  visibility: visible;
  position: absolute;
  left: ${({ isOnPersonalProject }) => (isOnPersonalProject ? '35px' : '6px')};
  ${Wrapper}:hover & {
    visibility: hidden;
  }
`;
const AddTaskTextHover = styled.div`
  margin-left: ${({ isOnHomeView, isOnPersonalProject }) =>
    isOnHomeView ? '20px' : isOnPersonalProject ? '18px' : '26.6px'};

  color: ${theme.colors.colorRoyalBlue};
  font-size: 15px;
  margin-top: 1px;
`;
const AddTaskText = styled.div`
  margin-left: ${({ isOnHomeView, isOnPersonalProject }) =>
    isOnHomeView ? '20px' : isOnPersonalProject ? '18px' : '26.6px'};
  color: ${theme.colors.colorMediumGray1};
  font-size: 15px;
`;

const AddIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
`;

class CreateTask extends Component {
  state = {
    isCreatingNewTask: false,
    newTask: {}
  };

  toggleTaskCreationHeader = () => {
    const {
      flushBatchSelectedTasks,

      toggleTaskCreation,
      shouldRenderCreateTaskButton,
      toggleIsCreatingTask
    } = this.props;
    if (!shouldRenderCreateTaskButton) {
      return;
    }
    flushBatchSelectedTasks();
    toggleTaskCreation();
    if (toggleIsCreatingTask) {
      toggleIsCreatingTask();
    }
    this.setState(({ isCreatingNewTask }) => ({
      isCreatingNewTask: !isCreatingNewTask
    }));
  };

  render() {
    const {
      shouldRenderCreateTaskButton,
      position = 0,
      isOnTeamProject,
      isOnHomeView,
      isOnPersonalProject,
      scheduleStart,
      shouldRenderGhostText,
      isOnTeamMemberProfile,
      paddingLeft = 0,
      marginLeft = 0,
      noBorder,
      allTasks,
      activeTaskColumns,
      isSomeTaskEditing,
      groupId,
      isOnProjectView,
      editTaskId
    } = this.props;

    const { isCreatingNewTask, newTask } = this.state;

    return (
      <Wrapper
        isCreatingNewTask={isCreatingNewTask}
        position={position}
        isOnTeamProject={isOnTeamProject}
        isOnPersonalProject={isOnPersonalProject}
        isOnHomeView={isOnHomeView}
        paddingLeft={paddingLeft}
        marginLeft={marginLeft}
        noBorder={noBorder}
        isEditing={!!editTaskId}
        isTaskListEmpty={!allTasks || !allTasks.length}
        activeTaskColumns={activeTaskColumns}
        isOnProjectView={isOnProjectView}
      >
        {((!isOnHomeView && !isOnPersonalProject) || isCreatingNewTask) && (
          <TaskCircle
            isEditing={false}
            isDisabled={true}
            markComplete={this.markTaskCompletion}
            isCompleted={false}
            member={null}
            isOnProjectDetail={!isOnHomeView}
            isOnPersonalProject={isOnPersonalProject}
            isOnHomeFollowed={isOnHomeView}
            isCreatingNewTask={isCreatingNewTask}
            isOnTeamProject={isOnTeamProject}
            isFirstRow
            taskCircleClass={'home-create-task'}
          />
        )}
        {!isCreatingNewTask && (
          <TaskHeaderActionsWrapper
            isOnHomeView={isOnHomeView}
            isOnPersonalProject={isOnPersonalProject}
            isOnTeamMemberProfile={isOnTeamMemberProfile}
            noBorder={noBorder}
            isEditing={!!editTaskId}
            position={position}
            isCreatingNewTask={isCreatingNewTask}
            isSomeTaskEditing={isSomeTaskEditing}
          >
            {shouldRenderCreateTaskButton && (
              <AddTaskPlusIcon
                isOnTeamMemberProfile={isOnTeamMemberProfile}
                isOnHomeView={isOnHomeView}
                isOnPersonalProject={isOnPersonalProject}
              >
                {shouldRenderGhostText && (
                  <>
                    <AddIconWrapper
                      data-for="app-tooltip"
                      data-tip={'Add Task'}
                      data-html
                      data-effect="solid"
                    >
                      <PlusCircleIcon
                        fill={theme.colors.colorMediumGray1}
                        stroke={theme.colors.colorMediumGray1}
                        pathStroke={theme.colors.colorMediumGray1}
                      />
                    </AddIconWrapper>
                    <AddTaskText>Add Task</AddTaskText>
                  </>
                )}
              </AddTaskPlusIcon>
            )}
            <AddTaskButton
              position={position}
              isOnHomeView={isOnHomeView}
              shouldRenderCreateTaskButton={shouldRenderCreateTaskButton}
              onClick={this.toggleTaskCreationHeader}
              isOnTeamMemberProfile={isOnTeamMemberProfile}
              isOnPersonalProject={isOnPersonalProject}
              data-testid="add-task-button"
            >
              {shouldRenderCreateTaskButton && (
                <>
                  <AddIconWrapper
                    data-for="app-tooltip"
                    data-tip={'Add Task'}
                    data-html
                    data-effect="solid"
                  >
                    <PlusCircleIcon />
                  </AddIconWrapper>
                  <AddTaskTextHover>Add Task</AddTaskTextHover>
                </>
              )}
            </AddTaskButton>
          </TaskHeaderActionsWrapper>
        )}
        {isCreatingNewTask && (
          <CreateTaskWrapper
            isHeader={this.props.isHeader}
            toggleTaskCreationHeader={this.toggleTaskCreationHeader}
            isCreatingNewTask={isCreatingNewTask}
            isCompleted={false}
            projectId={0}
            task={newTask}
            key={'top'}
            editTaskId={editTaskId}
            setEditTaskId={this.handleSetTaskId}
            addNewTaskAfter={noop}
            removeAddTaskHeader={this.removeAddTaskHeader}
            position={position}
            scheduleStart={scheduleStart}
            isOnHomeView={isOnHomeView}
            isOnTeamProject={isOnTeamProject}
            groupId={groupId}
          />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: getAuth(state),
  editTaskId: state.tasks.editTaskId,
  isOnTeamProject: getIsOnTeamProject(state),
  isOnPersonalProject: getIsOnPersonalProject(state),
  currentFilter: state.homeTasks.currentFilter,
  allTasks: getAllTasks(state),
  isOnTeamMemberProfile: isOnTeamMemberProfile(state, ownProps),
  activeTaskColumns: getTaskColumnOrderWithSelection(state),
  isSomeTaskEditing: isSomeTaskEditing(state),
  isOnProjectView: getIsOnProjectView(state)
});

export default withRouter(
  connect(mapStateToProps, {
    setEditTaskId,
    toggleTaskCreation,
    flushBatchSelectedTasks
  })(CreateTask)
);
