import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { StyledDragGripIcon } from 'views/Home/TaskList/styles';
import {
  ActivityListContainer,
  ActivityListHeader,
  StyledItemContainer,
  ActivitiesSectionContainer,
  NewActivityContainer,
  StyledAddPlusIcon,
  PlaceholderText
} from './styles';

class ActivityList extends React.Component {
  renderItem = (item, index) => {
    const { renderItem, handleSelect, dragDisabled = false } = this.props;
    return (
      <Draggable
        draggableId={`${item.id}`}
        index={index}
        key={`${item.id}`}
        isDragDisabled={dragDisabled}
      >
        {(provided, snapshot) => (
          <StyledItemContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            onClick={(e) => handleSelect(e, { item })}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', flex: 'auto' }}
            >
              {!dragDisabled && <StyledDragGripIcon />}
              {renderItem({ item })}
            </div>
          </StyledItemContainer>
        )}
      </Draggable>
    );
  };

  onCreateClick = () => {
    const { onCreateClick, billable } = this.props;
    onCreateClick({ billable });
  };

  render() {
    const {
      title,
      droppableId,
      items = [],
      showCreateNew = true,
      dragDisabled = false
    } = this.props;
    return (
      <ActivityListContainer>
        <ActivityListHeader>{title}</ActivityListHeader>
        <Droppable droppableId={droppableId} isDropDisabled={dragDisabled}>
          {(provided, snapshot) => (
            <ActivitiesSectionContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map(this.renderItem)}
              {provided.placeholder}
              {items.length === 0 ? (
                <PlaceholderText>
                  Work categories allow you to group day to day tasks into
                  categories for invoicing detail and reporting on what the team
                  is doing.
                </PlaceholderText>
              ) : (
                ''
              )}
            </ActivitiesSectionContainer>
          )}
        </Droppable>
        {showCreateNew && (
          <NewActivityContainer onClick={this.onCreateClick}>
            <StyledAddPlusIcon />
            Create New Work Category
          </NewActivityContainer>
        )}
      </ActivityListContainer>
    );
  }
}

export default ActivityList;
