import { MembersPermissionsTableCells } from 'PermissionsModule/components/settingsViews/members/MembersPermissionsTable/Cells';
import { ComponentProps } from 'react';
import { MemberPermissionTableCellContainer } from '../styles';

const { EmailCell } = MembersPermissionsTableCells;

// Wrapper component to style the Member Permission Email Cell with transparent background colour
export const MembersTimesheetSettingsTableEmailCell = (
  props: ComponentProps<typeof EmailCell>
) => (
  <MemberPermissionTableCellContainer>
    <EmailCell {...props} />
  </MemberPermissionTableCellContainer>
);
