import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { SettingDiv } from '../styles';
import {
  StyledFilterMenu as StyledOptionMenu,
  FilterTitle as OptionTitle,
  StyledFilterMenuItem
} from 'ReportsModule/components/Filter/styles';
import Popover from 'components/Popover';
import SortUpIcon from 'icons/SortUpIcon';
import { timesheetOptionsNames } from 'appConstants/timesheets';
import { DropdownSettingType } from 'TeamsModule/models/settings';

interface DropdownSettingsProps {
  settingName: string;
  menuTitle: string;
  description?: string;
  onChange: (val: DropdownSettingType) => void;
  currentOption: string;
  options: {
    value: DropdownSettingType;
    name?: string;
  }[];
}

const DropdownSetting = ({
  options,
  currentOption,
  onChange,
  settingName,
  menuTitle,
  description
}: DropdownSettingsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuTarget = useRef(null);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = (value: DropdownSettingType) => {
    onChange(value);
    closeMenu();
  };

  return (
    <SettingDiv>
      <div className="top">
        <div className="title">{settingName}</div>
        <FilterTrigger onClick={openMenu} ref={menuTarget}>
          {timesheetOptionsNames[currentOption]}
          <ArrowContainer>
            <SortUpIcon
              color={theme.colors.colorRoyalBlue}
              height="15"
              width="7"
            />
          </ArrowContainer>
        </FilterTrigger>
        <Popover
          isOpen={isMenuOpen}
          closePopover={closeMenu}
          target={menuTarget}
        >
          <StyledOptionMenu>
            <OptionTitle> {menuTitle} </OptionTitle>
            {options.map(({ name, value }) => (
              <StyledMenuItem
                onClick={() => handleSelect(value)}
                className={currentOption === value ? 'active' : ''}
                key={value}
              >
                {name}
              </StyledMenuItem>
            ))}
          </StyledOptionMenu>
        </Popover>
      </div>
      <div className="description">{description}</div>
    </SettingDiv>
  );
};

const StyledMenuItem = styled(StyledFilterMenuItem)`
  color: ${theme.colors.colorMediumGray9};
`;

const FilterTrigger = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ArrowContainer = styled.div`
  margin-left: 4px;
  margin-top: -1px;
`;

export default DropdownSetting;
