import React, { useEffect, useRef } from 'react';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useRowState
} from 'react-table';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Droppable } from 'react-beautiful-dnd';

import { TableRow, DraggableTableRow } from './VariableSizeTableRow';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
import { DragClone } from './styles';
import cn from 'classnames';
import noop from 'lodash/noop';

const defaultGetItemSize = () => 50;
const emptyObj = {};
const itemKey = (index, data) => {
  // Find the item at the specified index.
  // In this case "data" is an Array that was passed to List as "itemData".
  const item = data[index];
  return item ? item.id : index;
  // Return a value that uniquely identifies this item.
  // Typically this will be a UID of some sort.
};

// optimization to break early if we have many rows.
const getMinHeight = ({ rows, maxHeight, itemHeight }) => {
  let minHeight = 0;
  for (const row of rows) {
    minHeight += +row?.itemHeight || +row?.original?.itemHeight || +itemHeight;
    if (minHeight > maxHeight) {
      return maxHeight;
    }
  }
  return minHeight;
};

const Table = ({
  virtual = false,
  columns,
  data,
  showFooter = false,
  itemHeight = 41,
  maxHeight = 500,
  loadMoreItems = noop,
  handleScroll = noop,
  getItemSize = defaultGetItemSize,
  listRef,
  tableStateRef = {},
  numberOfAdditionalRowsForThreshold = 0,
  showHeader,
  isDragDisabled = true,
  autoResetRowState = false,
  stateReducer,
  columnMaxWidth,
  columnMinWidth,
  dragContainerClassName = 'drag-clone',
  overscanCount = 10,
  itemCount = 2000,
  totalColumnsWidthOverride,
  headersToIgnore,
  infiniteLoaderRef,
  customRowProps,
  hideDevProps,
  shouldUseIsScrolling,
  classNames // : { list?: string }
}) => {
  // cannot provide a fallback ref in props
  const fallbackInfiniteLoaderRef = useRef(null);
  const fallbackTableBodyRef = useRef(null);

  const infiniteLoaderRefToUse = infiniteLoaderRef || fallbackInfiniteLoaderRef;
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: columnMinWidth ?? 30,
      width: 150,
      maxWidth: columnMaxWidth || 600
    }),
    [columnMaxWidth, columnMinWidth]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    state
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetRowState,
      stateReducer
    },
    useBlockLayout,
    useResizeColumns,
    useRowState
  );

  useEffect(() => {
    tableStateRef.current = state;
  }, [state, tableStateRef]);

  const tableBodyProps = getTableBodyProps();

  const itemData = React.useMemo(() => {
    return {
      rows,
      columns,
      prepareRow,
      isDragDisabled,
      tableBodyProps,
      customRowProps,
      hideDevProps
    };
  }, [
    rows,
    columns,
    prepareRow,
    isDragDisabled,
    tableBodyProps,
    customRowProps,
    hideDevProps
  ]);

  const isItemLoaded = React.useCallback(
    (index) => {
      return index < rows.length;
    },
    [rows.length]
  );

  const minHeight = getMinHeight({ rows, maxHeight, itemHeight });

  const renderList = ({ droppableProvided } = emptyObj) => {
    const tableBodyRef = droppableProvided?.innerRef || fallbackTableBodyRef;

    return (
      <div className="table-body" {...tableBodyProps} ref={tableBodyRef}>
        {virtual ? (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={30 + numberOfAdditionalRowsForThreshold}
            ref={infiniteLoaderRefToUse}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                useIsScrolling={shouldUseIsScrolling}
                className={cn('variable-size-list', classNames?.list)}
                onItemsRendered={({
                  overscanStartIndex,
                  overscanStopIndex,
                  visibleStartIndex,
                  visibleStopIndex
                }) => {
                  onItemsRendered({
                    overscanStartIndex,
                    overscanStopIndex,
                    visibleStartIndex,
                    visibleStopIndex
                  });
                  handleScroll({ visibleStartIndex });
                }}
                height={minHeight}
                itemCount={rows.length}
                itemSize={getItemSize}
                itemKey={itemKey}
                width={totalColumnsWidthOverride || totalColumnsWidth}
                outerRef={tableBodyRef}
                itemData={itemData}
                ref={(list) => {
                  ref(list);
                  listRef.current = list;
                }}
                overscanCount={overscanCount}
              >
                {DraggableTableRow}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        ) : (
          rows.map((row, index) => (
            <DraggableTableRow
              index={index}
              data={itemData}
              key={row.original.id || index}
            />
          ))
        )}
      </div>
    );
  };

  return (
    <div {...getTableProps()} className="table">
      {showHeader && (
        <TableHeader
          headerGroups={headerGroups}
          headersToIgnore={headersToIgnore}
          totalColumnsWidthOverride={totalColumnsWidthOverride}
          customRowProps={customRowProps}
        />
      )}
      {isDragDisabled ? (
        renderList()
      ) : (
        <Droppable
          droppableId={'droppable'}
          mode="virtual"
          renderClone={(provided, snapshot, rubric) => (
            <DragClone className={dragContainerClassName}>
              <TableRow
                index={rubric.source.index}
                data={itemData}
                provided={provided}
                style={{ margin: 0 }}
              />
            </DragClone>
          )}
        >
          {(droppableProvided) => renderList({ droppableProvided })}
        </Droppable>
      )}
      {showFooter && <TableFooter headerGroups={headerGroups} />}
    </div>
  );
};

export default Table;
