import React, { useRef } from 'react';
import CellContainer from './CellContainer';
import BulkContent from './BulkContent';
import { makeNoop } from 'appUtils/noop';
const emptyArray = [];
const emptyObj = {};

const BulkCell = ({ row }) => {
  const target = useRef(null);

  const {
    task_status_id,
    id,
    handlePropertySelect = makeNoop,
    taskProps = emptyObj,
    createRow,
    isCreatingNewTask,
    list
  } = row.original;
  const { taskIsEditing, isNewTask, isOnHomeView, isOnTeamProject } = taskProps;

  return (
    <CellContainer
      taskProps={taskProps}
      taskProperty="bulk"
      isHidden={createRow}
      styles={{
        borderLeft:
          (isCreatingNewTask || !createRow) && isOnTeamProject
            ? '2px solid #f3f3f3'
            : 0
      }}
      row={row}
    >
      <BulkContent
        taskIsEditing={taskIsEditing}
        isNewTask={isNewTask}
        isCreatingTask={false}
        isTaskModal={false}
        fillWhite={!isOnHomeView}
        target={target}
        onSubmit={handlePropertySelect('task_status_id')}
        taskId={id}
        task={row.original}
        taskIds={emptyArray}
        groupTaskIds={list.taskIds}
        groupId={list.id}
        groupIsBatchSelected={list.isSelected}
      />
    </CellContainer>
  );
};
export default BulkCell;
