import { ReactElement } from 'react';
import cn from 'classnames';
import theme from 'theme';
import styled from 'styled-components';
import {
  TertText,
  CellHeader
} from 'views/projectPlanner/workloadBarModal/styles';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface NumberFieldProps extends NumberFormatProps {
  labelTooltipContent: string;
  endAdornment?: ReactElement;
  label: string;
  afterValue: string;
  dataTestId: string;
  showWarning: boolean;
  showError?: boolean;
  roundedBorder?: {
    left?: boolean;
    right?: boolean;
  };
  cellTooltipContent: string;
}
export const NumberField = ({
  onFocus,
  onBlur,
  onChange,
  onMouseDown,
  afterValue,
  label,
  disabled,
  labelTooltipContent,
  cellTooltipContent,
  dataTestId,
  decimalScale = 0,
  showWarning = false,
  showError = false,
  roundedBorder = { left: false, right: false },
  endAdornment,
  ...numberFormatProps
}: NumberFieldProps) => {
  return (
    <StyledInputMainContainer>
      <CellHeader
        data-for="app-tooltip"
        data-effect="solid"
        data-html
        data-tip={labelTooltipContent}
      >
        {label}
      </CellHeader>
      <CellContainer
        className={cn({
          roundedLeft: roundedBorder.left,
          roundedRight: roundedBorder.right,
          error: showError,
          disabled
        })}
        data-for="app-tooltip"
        data-effect="solid"
        data-html
        data-tip={cellTooltipContent}
      >
        <InputContainer>
          <NumberInput
            thousandSeparator={true}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={decimalScale}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseDown={onMouseDown}
            type="text"
            autoComplete="off"
            placeholder={disabled ? undefined : '0'}
            disabled={disabled}
            data-testid={dataTestId}
            className={cn({ disabled })}
            {...numberFormatProps}
          />
          <TertTextContainer>
            <TertText>{afterValue}</TertText>
            {showWarning && <IssueIconContainer>!</IssueIconContainer>}
          </TertTextContainer>
        </InputContainer>
        <EndAdornment>{endAdornment}</EndAdornment>
      </CellContainer>
    </StyledInputMainContainer>
  );
};

const StyledInputMainContainer = styled.div``;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 36px;
`;

const NumberInput = styled(NumberFormat)`
  width: 100%;
  outline: none;
  border: none;
  font-size: 15px;
  text-align: right;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.colorMediumGray7};
  margin-right: 1px;
  background-color: transparent;

  &.disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.colorMediumGray7};
  }

  &:focus::placeholder {
    color: ${theme.colors.colorLightGray9};
  }
`;

export const TertTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const IssueIconContainer = styled.span`
  color: ${theme.colors.colorDeleteRed};
  font-size: 15px;
  font-weight: 800;
`;

const EndAdornment = styled.div`
  width: 36px;
  flex: none;
`;

const BORDER_RADIUS_PX = '5px';

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.colorLightGray19};
  border: 1px solid ${({ theme }) => theme.colors.colorPaleGray5};

  &:hover:not(.disabled) {
    border-color: ${theme.colors.colorRoyalBlue};
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.roundedLeft {
    border-top-left-radius: ${BORDER_RADIUS_PX};
    border-bottom-left-radius: ${BORDER_RADIUS_PX};
  }

  &.roundedRight {
    border-top-right-radius: ${BORDER_RADIUS_PX};
    border-bottom-right-radius: ${BORDER_RADIUS_PX};
  }

  &.error {
    border-color: ${({ theme }) => theme.colors.colorCalendarRed};
  }
`;
