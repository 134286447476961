import SvgIcon from 'components/SvgIcon';

const PhaseMilestoneIcon = ({
  height = 14,
  width = 14,
  className = '',
  fillColor = 'white',
  strokeColor = '#828282',
  strokeWidth
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.00119L6.99881 2L2 6.99881L7.00119 12L12 7.00119Z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default PhaseMilestoneIcon;
