import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import { getAuth } from 'selectors';
import { navigateToHome, handleErrorMessage } from 'actionCreators';
import * as Sentry from '@sentry/browser';
import { GENERIC_ACTION } from 'appConstants';
import ErrorGearIcon from 'icons/ErrorGearIcon';

const ErrorMessage = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  font-size: 22px;
  text-align: left;
  color: #4a4a4a;
`;
const MessageBox = styled.div`
  min-width: 800px;
  padding-bottom: 140px;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
const ErrorHeader = styled.div`
  font-size: 40px;
  margin-top: 20px;
  font-weight: 400;
`;

const StyledDescription = styled.div`
  font-size: 18px;
  padding: 20px 0px;
  color: ${theme.colors.colorMediumGray5};
`;

const StyledButton = styled.button`
  padding: 6px 16px;
  color: ${theme.colors.colorPureWhite};
  font-weight: 600;
  border-radius: 6px;
  background: ${theme.colors.colorRoyalBlue};
  font-size: 18px;
`;
class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, errorInfo) {
    const { handleErrorMessage } = this.props;
    const isLoadingChunkError = error?.message
      ?.toLowerCase?.()
      ?.includes?.('loading chunk');
    if (!window.location.href.includes('localhost') && !isLoadingChunkError) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      const url = new URL(document.location);
      const params = url.searchParams;
      const errorParam = params.get('error');
      url.searchParams.set('error', true);

      if (errorParam) {
        this.navigateToHome();
      } else {
        this.setState({ hasError: true });

        if (isLoadingChunkError) {
          handleErrorMessage({
            type: GENERIC_ACTION,
            errorMessage: 'Mosaic Has Received Updates, reloading'
          });
        }
        if (!this.timeout) {
          // set url once for errors that occur within 2 seconds
          // if an error occurs after that navigate home due to url param
          this.timeOut = setTimeout(() => {
            window.history.pushState({}, document.title, url);
            if (isLoadingChunkError) {
              window.location.reload();
            }
            this.timeOut = null;
          }, 2000);
        }
      }
    }
  }

  navigateToHome = () => {
    const { auth, navigateToHome } = this.props;
    this.setState({ hasError: false });
    if (auth?.account) {
      navigateToHome({ userSlug: auth.account?.slug }); // try navigating to friendly url first
    } else {
      window.location.pathname = '/';
    }
  };

  render() {
    // if (this.state.hasError) {
    //   return (
    //     <ErrorMessage>
    //       <MessageBox>
    //         <ErrorGearIcon height="85" width="85" />
    //         <ErrorHeader>
    //           ERROR
    //           <br />
    //         </ErrorHeader>
    //         <div>
    //           <FlexContainer>
    //             <StyledDescription>
    //               Something went wrong while displaying this webpage.
    //             </StyledDescription>
    //             <StyledButton onClick={this.navigateToHome}>Home</StyledButton>
    //           </FlexContainer>
    //         </div>
    //       </MessageBox>
    //     </ErrorMessage>
    //   );
    // }

    return this.props.children;
  }
}
const mapStateToProps = (state) => ({
  auth: getAuth(state)
});
export default connect(mapStateToProps, { navigateToHome, handleErrorMessage })(
  ErrorBoundary
);
