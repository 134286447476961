import { fork, takeEvery, all } from 'redux-saga/effects';

import * as constants from '../constants';
import * as workers from './customFields';

export default function* customFieldsSaga() {
  const customFieldsSagas = [
    yield fork(
      takeEvery,
      constants.FETCH_CUSTOM_FIELD_ALL_VALUES.TRIGGER,
      workers.fetchCustomFieldAllValuesWorker
    )
  ];
  yield all(customFieldsSagas);
}
