import { useAppSelector } from 'reduxInfra/hooks';
import { useCallback, useState } from 'react';
import {
  MemberPermissionListItem,
  ArchivedSectionHeaderListItem,
  MemberPermissionListItemsBuilder
} from '../types';
import { membersPermissionsTableHelpers } from './layout';
import { getTeamMembersHashByTeamMembership } from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';

const { ROW_TYPES } = membersPermissionsTableHelpers;

type UseMemberListItemsBuilderParams = Pick<
  MemberPermissionListItem,
  'onRequestEditMemberAccess' | 'onRequestEditMemberMetadata'
>;

export const useMemberListItemsBuilder = ({
  onRequestEditMemberAccess,
  onRequestEditMemberMetadata
}: UseMemberListItemsBuilderParams) => {
  const [isArchivedMembersVisible, setIsArchivedMembersVisible] =
    useState(false);

  const teamMembersHash = useAppSelector(getTeamMembersHashByTeamMembership);

  const memberListRowBuilder: MemberPermissionListItemsBuilder = useCallback(
    ({ order }) => {
      const archivedMemberhipIds: number[] = [];
      const nonArchivedMemberhipIds: number[] = [];

      const memberPermissionListItemsBuilder = (
        teamMembershipIds: number[]
      ): MemberPermissionListItem[] => {
        return teamMembershipIds.flatMap((teamMembershipId) => {
          const teamMember = teamMembersHash[teamMembershipId];
          if (!teamMember) return [];

          const item: MemberPermissionListItem = {
            teamMember,
            onRequestEditMemberAccess,
            onRequestEditMemberMetadata,
            rowType: ROW_TYPES.memberPermissionRow,
            rowHeight: 60
          };

          return [item];
        });
      };

      const archivedSectionHeaderListItemBuilder =
        (): ArchivedSectionHeaderListItem => {
          const value: ArchivedSectionHeaderListItem = {
            rowType: ROW_TYPES.archivedSectionHeaderRow,
            rowHeight: 35,
            archivedCount: archivedMemberhipIds.length,
            isShowingArchivedPolicies: isArchivedMembersVisible,
            onClick: () => {
              setIsArchivedMembersVisible(!isArchivedMembersVisible);
            }
          };
          return value;
        };

      (order as number[]).forEach((teamMembershipId) => {
        const teamMember = teamMembersHash[teamMembershipId];

        if (teamMember) {
          if (teamUtils.getIsArchived(teamMember)) {
            archivedMemberhipIds.push(teamMember.id);
          } else {
            nonArchivedMemberhipIds.push(teamMember.id);
          }
        }
      });

      const nonArchivedMemberPermissionListItems =
        memberPermissionListItemsBuilder(nonArchivedMemberhipIds);
      const archivedMemberPermissionListItems =
        memberPermissionListItemsBuilder(archivedMemberhipIds);
      const archivedSectionHeaderListItem =
        archivedSectionHeaderListItemBuilder();

      let items: Array<
        MemberPermissionListItem | ArchivedSectionHeaderListItem
      > = [];

      if (!archivedMemberhipIds.length) {
        items = nonArchivedMemberPermissionListItems;
      } else {
        items.push(...nonArchivedMemberPermissionListItems);

        if (!isArchivedMembersVisible) {
          items.push(archivedSectionHeaderListItem);
        } else {
          items.push(...archivedMemberPermissionListItems);
          items.push(archivedSectionHeaderListItem);
        }
      }

      return items;
    },
    [
      isArchivedMembersVisible,
      onRequestEditMemberAccess,
      onRequestEditMemberMetadata,
      teamMembersHash
    ]
  );

  return memberListRowBuilder;
};
