import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import { MemberInitials, BoardList } from '..';
import FolderIcon from 'icons/FolderIcon';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  calculateBoardColorPreference,
  calculateBoardBorderColorWithOpacity
} from 'appUtils/styleUtils';
import {
  openColorPicker,
  deleteBoard,
  archiveGroup,
  unarchiveBoard,
  openAddEditGroupModal,
  navigateToHome,
  exportProjectsListView,
  generateReport
} from 'actionCreators';
import { connect } from 'react-redux';
import ColorPicker from 'components/ColorPicker';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import {
  getUserIsAdmin,
  getUserSlug,
  makeGetNonGuestBoardMembers,
  getSplitFlags
} from 'selectors';
import { AddMembersContainer, DeleteArchivedBoardModal } from 'views';
import DeleteIcon from 'icons/delete.svg';
import DeleteIconRed from 'icons/delete-red.svg';
import ExportTasks from 'icons/export-tasks.svg';
import ArchiveIcon from 'icons/archive.svg';
import SmallPencilIcon from 'icons/small-pencil.svg';
import TeamMembersIcon from 'icons/new-team-member.svg';
import PrivacyIcon from 'icons/privacy-icon.svg';
import CreateFolderIcon from 'icons/create-new-team.svg';
import ColorSetIcon from 'icons/color-set-icon.svg';
import CloseIcon from 'icons/CloseIcon';

import Popover from 'components/Popover';
import TMDot from 'views/memberDisplay/TMDot';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import { PERSONAL_BOARD_NAMES } from 'appConstants';
import ImportIcon from 'images/import-icon.svg';
import { ImportModal } from 'CsvImportsModule/components/ImportModal';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import {
  createPortfolio,
  archivePortfolio,
  deletePortfolio
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import withPermissionsCheck from 'hocs/withPermissionsCheck';

import {
  CREATE_PORTFOLIO_TIP,
  ARCHIVE_PORTFOLIO_TIP,
  DELETE_PORTFOLIO_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const LineBreak = styled.div`
  height: 1px;
  margin: 4px 8px;
  background-color: ${({ theme }) => theme.colors.colorPaleGray5};
`;

const IconContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  min-width: 190px;
  color: ${(props) => props.theme.colors.colorSemiDarkGray3};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 7px;
  .delete-grey-icon {
    display: flex;
  }

  .delete-red-icon {
    display: none;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    color: ${(props) =>
      props.delete
        ? props.theme.colors.colorDeleteRed
        : props.theme.colors.colorSemiDarkGray3};
    cursor: pointer;

    .delete-grey-icon {
      display: none;
    }

    .delete-red-icon {
      display: flex;
    }
  }
`;

const DropdownWrapper = styled.div`
  padding: 0px;
  width: 100%;
`;
const CloseIconContainer = styled.div`
  position: absolute;
  top: -20px;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
`;
const BoardSettings = styled.div`
  display: flex;
  justify-content: space-around;
`;
export function onClickInitialsButton(updateSelectedAccountIds, id) {
  return () => updateSelectedAccountIds(id);
}

const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 10px;
  width: 29px;

  path {
    stroke: ${calculateBoardBorderColorWithOpacity};
    stroke-width: 3;
    fill: ${(props) => calculateBoardColorPreference(props) || `transparent`};
  }
`;

const BoardName = styled.h3`
  display: flex;
  align-items: center;
  float: left;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
  color: ${(props) => props.theme.colors.colorSemiDarkGray1};
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  margin: unset;

  .board-arrow {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  &:hover,
  &.active {
    color: ${(props) => props.theme.colors.colorRoyalBlue};

    .board-arrow {
      border-top: 6px solid ${(props) => props.theme.colors.colorRoyalBlue};
    }
  }
`;

const importIconStyle = {
  filter: 'grayscale(93%) brightness(0.8)',
  transform: 'rotate(-90deg) translate(2px, -2px)'
};

class BoardHeader extends React.Component {
  state = {
    modalStep: null,
    isDeleteModalOpen: false,
    isFolderColorPickerOpen: false,
    isProjectImportModalOpen: false
  };

  popoverTarget = React.createRef();

  export = () => {
    const { exportProjectsListView, boardId } = this.props;
    exportProjectsListView({ exportParam: true, boardId });
  };

  exportXls = () => {
    const { generateReport, boardId } = this.props;
    generateReport({ report_type: 'Projects', actable_id: boardId });
  };

  setModalStep = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      modalStep: e.currentTarget.getAttribute('data-modalstep')
    });
  };

  componentDidUpdate(prevProps) {
    rebuildTooltip();
  }

  closeModalStep = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ modalStep: null, isDeleteModalOpen: false });
  };

  renderModalStep = (step) => {
    const hash = {
      color: this.renderColorModalStep,
      addMembers: this.renderAddMemberModalStep
    };
    return hash[step]();
  };

  renderColorModalStep = () => {
    const { boardId } = this.props;
    const closeOnSelect = () => {
      this.handleCloseFolderColorPicker();
      this.setState({
        modalStep: null
      });
    };
    return (
      <div>
        <ColorPicker
          boardId={boardId}
          id={boardId}
          originType={ORIGIN_TYPE_STRINGS.BOARD}
          pickerLocation="flyout-menu"
          row
          className="color-picker-container"
          headerText="Select Portfolio Color"
          alwaysOpen
          handleSelect={closeOnSelect}
        >
          <div />
        </ColorPicker>
      </div>
    );
  };

  renderAddMemberModalStep = () => (
    <AddMembersContainer
      isOpenMembersModal
      closeModalStep={this.closeModalStep}
      modalType={MODAL_TYPE.BOARD}
    />
  );

  handleArchive = () => {
    const { archiveGroup, unarchiveBoard, selectedBoard, canArchivePortfolio } =
      this.props;
    if (!canArchivePortfolio) {
      return;
    }
    if (selectedBoard.date_archived) {
      unarchiveBoard(selectedBoard.id);
    } else {
      archiveGroup(selectedBoard.id);
      this.navHome();
    }
  };

  openDeleteModal = () => {
    const { userIsAdmin } = this.props;
    if (userIsAdmin) {
      this.setState({
        isDeleteModalOpen: true
      });
    }
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { selectedBoard, deleteBoard } = this.props;
    deleteBoard(selectedBoard.id, selectedBoard.team_id);
    this.closeDeleteModal();
    this.navHome();
  };

  handleAddBoardClick = () => {
    const { openAddEditGroupModal } = this.props;
    openAddEditGroupModal({ isEdit: false, id: null });
  };

  navHome = () => {
    const { userSlug, navigateToHome } = this.props;
    navigateToHome({ userSlug });
  };

  handleOpenFolderColorPicker = () => {
    this.setState({
      isFolderColorPickerOpen: true
    });
  };

  handleCloseFolderColorPicker = () => {
    this.setState({
      isFolderColorPickerOpen: false
    });
  };

  render() {
    const {
      selectedBoardName,
      editGroupOnClick,
      toggleBoardList,
      menuActive,
      boardId,
      openAddMembersForm,
      selectedBoard,
      children,
      splitFlags,
      canCreatePortfolio,
      canArchivePortfolio,
      canDeletePortfolio
    } = this.props;
    const { modalStep, isDeleteModalOpen, menuOpen, isFolderColorPickerOpen } =
      this.state;

    const isBoardPersonal = selectedBoard?.is_personal;
    const boardNameToUse = isBoardPersonal
      ? PERSONAL_BOARD_NAMES[selectedBoardName]
      : selectedBoardName;

    const { projectPhasesActivityPhasesCSVImport } = splitFlags;

    return (
      <div
        style={{
          pointerEvents: 'initial',
          display: 'flex',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <BoardName className={cn('styled-name', { active: menuActive })}>
          <div>
            <div
              onClick={this.handleOpenFolderColorPicker}
              ref={this.popoverTarget}
            >
              <StyledFolderIcon boardId={boardId} />
              <Popover
                className="color-picker-container"
                isOpen={isFolderColorPickerOpen}
                target={this.popoverTarget}
                closePopover={this.handleCloseFolderColorPicker}
              >
                {this.renderColorModalStep()}
              </Popover>
            </div>
          </div>
          <div
            onClick={toggleBoardList}
            style={{ paddingRight: '10px' }}
            data-testid="portfolio-selector"
          >
            {boardNameToUse}
          </div>
          <div onClick={toggleBoardList} className="board-arrow" />
        </BoardName>
        <BoardSettings menuOpen={menuOpen}>
          <ProjectThreeDotMenu
            modalStep={modalStep}
            closeModalStep={this.closeModalStep}
            isModalOpen={isDeleteModalOpen}
            testIdPrefix={boardNameToUse}
          >
            {modalStep ? (
              this.renderModalStep(modalStep)
            ) : (
              <DropdownWrapper>
                {!isBoardPersonal && (
                  <MenuListItem
                    onClick={editGroupOnClick}
                    data-testid="option-rename"
                  >
                    <IconContainer>
                      <img className="grey-icon" src={SmallPencilIcon} />
                    </IconContainer>
                    Rename Portfolio
                  </MenuListItem>
                )}
                <MenuListItem
                  onClick={canCreatePortfolio ? this.handleAddBoardClick : noop}
                  data-testid="option-new"
                  data-for="app-tooltip"
                  data-effect="solid"
                  data-class="center"
                  data-tip={CREATE_PORTFOLIO_TIP}
                  data-tip-disable={canCreatePortfolio}
                >
                  <IconContainer>
                    <img className="grey-icon" src={CreateFolderIcon} />
                  </IconContainer>
                  New Portfolio
                </MenuListItem>
                {!isBoardPersonal && (
                  <>
                    <LineBreak />
                    <MenuListItem
                      data-modalstep="addMembers"
                      onClick={(e) => {
                        openAddMembersForm(e, 'flyout-menu');
                        this.setModalStep(e);
                      }}
                      data-testid="option-add-members"
                    >
                      <IconContainer>
                        <img className="grey-icon" src={TeamMembersIcon} />
                      </IconContainer>
                      Add/Edit Members
                    </MenuListItem>
                    <MenuListItem
                      onClick={editGroupOnClick}
                      data-testid="option-privacy-settings"
                    >
                      <IconContainer>
                        <img className="grey-icon" src={PrivacyIcon} />
                      </IconContainer>
                      Privacy Settings
                    </MenuListItem>
                  </>
                )}
                <MenuListItem
                  onClick={this.setModalStep}
                  data-modalstep="color"
                  data-testid="option-set-color"
                >
                  <IconContainer>
                    <img className="grey-icon" src={ColorSetIcon} />
                  </IconContainer>
                  Set Portfolio Color
                </MenuListItem>
                {!isBoardPersonal && <LineBreak />}
                <MenuListItem
                  onClick={this.export}
                  data-testid="option-download-csv"
                >
                  {' '}
                  <IconContainer>
                    <img className="grey-icon" src={ExportTasks} />
                  </IconContainer>
                  Download to .csv
                </MenuListItem>
                <MenuListItem
                  onClick={this.exportXls}
                  data-testid="option-download-xls"
                >
                  {' '}
                  <IconContainer>
                    <img className="grey-icon" src={ExportTasks} />
                  </IconContainer>
                  Download to .xls
                </MenuListItem>
                {!isBoardPersonal && (
                  <>
                    <MenuListItem
                      onClick={canArchivePortfolio ? this.handleArchive : noop}
                      data-for="app-tooltip"
                      data-effect="solid"
                      data-class="center"
                      data-tip={ARCHIVE_PORTFOLIO_TIP}
                      data-tip-disable={canArchivePortfolio}
                      data-testid="option-archive"
                    >
                      <IconContainer>
                        <img className="grey-icon" src={ArchiveIcon} />
                      </IconContainer>
                      {selectedBoard.date_archived
                        ? 'Unarchive Portfolio'
                        : 'Archive Portfolio'}
                    </MenuListItem>
                    <MenuListItem
                      onClick={canDeletePortfolio ? this.openDeleteModal : noop}
                      data-for="app-tooltip"
                      data-effect="solid"
                      data-class="center"
                      data-tip={DELETE_PORTFOLIO_TIP}
                      data-tip-disable={canDeletePortfolio}
                      delete
                      data-testid="option-delete"
                    >
                      <IconContainer>
                        <img className="delete-grey-icon" src={DeleteIcon} />
                        <img className="delete-red-icon" src={DeleteIconRed} />
                      </IconContainer>
                      Delete Portfolio
                      <DeleteArchivedBoardModal
                        board={selectedBoard}
                        isOpen={isDeleteModalOpen}
                        closeDeleteModal={this.closeDeleteModal}
                        deleteBoard={this.handleDelete}
                      />
                    </MenuListItem>
                  </>
                )}
                {projectPhasesActivityPhasesCSVImport && (
                  <MenuListItem
                    onClick={() =>
                      this.setState({ isProjectImportModalOpen: true })
                    }
                  >
                    <IconContainer>
                      <img
                        src={ImportIcon}
                        width={18}
                        style={importIconStyle}
                      />
                    </IconContainer>
                    <div>Import Projects</div>
                  </MenuListItem>
                )}
              </DropdownWrapper>
            )}
          </ProjectThreeDotMenu>
        </BoardSettings>
        {children} {/* board list dropdown if open */}
        {this.state.isProjectImportModalOpen && (
          <ImportModal
            handleClose={() =>
              this.setState({ isProjectImportModalOpen: false })
            }
            entityType={CsvImportEntity.ProjectPhaseActivityPhase}
          />
        )}
      </div>
    );
  }
}

BoardHeader.propTypes = {
  selectedBoardName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  editGroupOnClick: PropTypes.func.isRequired
};

class BoardMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamBoardMenuOpen: false,
      hideMembers: true
    };
    bindAll(this, ['toggleBoardList', 'closeBoardList']);
  }

  toggleBoardList() {
    this.setState((prevState) => ({
      teamBoardMenuOpen: !prevState.teamBoardMenuOpen
    }));
  }

  setColorPickerRef = (ref) => (this.colorPickerRef = ref);
  onColorPickerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.colorPickerRef.handleClick();
  };

  getCanCreatePortfolio = () => {
    const { checkPermission, createPortfolio, selectedBoard } = this.props;
    if (selectedBoard?.team_id) {
      return checkPermission(createPortfolio, {
        permissions: {
          teamId: selectedBoard.team_id
        }
      });
    }
    return false;
  };

  getCanArchivePortfolio = () => {
    const { checkPermission, archivePortfolio, selectedBoard } = this.props;
    if (selectedBoard?.team_id) {
      return checkPermission(archivePortfolio, {
        permissions: {
          teamId: selectedBoard.team_id
        }
      });
    }
    return false;
  };

  getCanDeletePortfolio = () => {
    const { checkPermission, deletePortfolio, selectedBoard } = this.props;
    if (selectedBoard.team_id) {
      return checkPermission(deletePortfolio, {
        permissions: {
          teamId: selectedBoard.team_id
        }
      });
    }
    return false;
  };

  closeBoardList(event) {
    if (
      !(
        event.target.classList.contains('board-pencil') ||
        event.target.classList.contains('board-section') ||
        event.target.classList.contains('styled-name') ||
        event.target.classList.contains('group-menu-actions') ||
        event.target.classList.contains('group-menu-actions-add') ||
        event.target.classList.contains('group-menu-list') ||
        event.target.classList.contains('lock-icon') ||
        event.target.classList.contains('board-item-contents') ||
        event.target.tagName === 'INPUT' ||
        event.target.tagName === 'path'
      ) &&
      !(
        (event.target.parentElement &&
          (event.target.parentElement.classList.contains('board-section') ||
            event.target.parentElement.classList.contains('styled-name') ||
            event.target.parentElement.classList.contains(
              'group-menu-actions'
            ) ||
            event.target.parentElement.classList.contains(
              'group-menu-actions-add'
            ) ||
            event.target.parentElement.classList.contains(
              'group-menu-list'
            ))) ||
        (event.target.parentElement &&
          (event.target.parentElement.classList.contains('board-pencil') ||
            event.target.parentElement.classList.contains('lock-icon') ||
            event.target.parentElement.classList.contains(
              'board-item-contents'
            ) ||
            event.target.parentElement.tagName === 'INPUT' ||
            event.target.parentElement.tagName === 'path'))
      )
    ) {
      this.setState({
        teamBoardMenuOpen: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedBoard } = this.props;
    if (prevProps.selectedBoard.id !== selectedBoard.id) {
      this.setState({ hideMembers: true });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.closeBoardList);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeBoardList);
  }

  showMembers = () => {
    this.setState({ hideMembers: false });
  };

  renderBoardMembers() {
    const {
      me,
      updateSelectedAccountIds,
      selectedAccountIds,
      nonGuestMembers
    } = this.props;
    const pluralize =
      nonGuestMembers && nonGuestMembers.length === 1 ? '' : 's';

    return this.state.hideMembers && !selectedAccountIds.length ? (
      <div
        className="member-number"
        title={'Click to view all portfolio members'}
        onClick={this.showMembers}
      >
        {`${nonGuestMembers.length} Member${pluralize}`}
      </div>
    ) : (
      <div
        className={cn('group-members-container', {
          'have-margin': selectedAccountIds.length
        })}
      >
        {nonGuestMembers
          .filter((member) => member.account.initials)
          .map((member, idx) => (
            <li key={member.account.id} className={'group-members-list-item'}>
              {selectedAccountIds.indexOf(member.account.id) > -1 ? (
                <CloseIconContainer
                  onClick={onClickInitialsButton(
                    updateSelectedAccountIds,
                    member.account.id
                  )}
                >
                  <CloseIcon></CloseIcon>
                </CloseIconContainer>
              ) : (
                ''
              )}
              <MemberInitials
                member={member}
                shouldShowHTMLTitle={true}
                idx={idx}
                boardMembers={true}
                classes={cn(
                  'regular-member selected',
                  {
                    selected: selectedAccountIds.indexOf(member.account.id) > -1
                  },
                  { 'logged-member': member.account.id === me.id }
                )}
                onClick={onClickInitialsButton(
                  updateSelectedAccountIds,
                  member.account.id
                )}
              />
              {member.role_ids?.some((role) => role === ACCESS_ROLES.ADMIN) && (
                <TMDot />
              )}
            </li>
          ))}
      </div>
    );
  }

  render() {
    const {
      nonGuestMembers,
      selectedBoardName,
      editGroupOnClick,
      openAddMembersForm,
      selectedBoard,
      userIsAdmin,
      deleteBoard,
      history,
      userSlug,
      archiveGroup,
      unarchiveBoard,
      openAddEditGroupModal,
      navigateToHome,
      openAddMembersFromIcon,
      exportProjectsListView,
      generateReport,
      splitFlags
    } = this.props;

    const canCreatePortfolio = this.getCanCreatePortfolio();
    const canArchivePortfolio = this.getCanArchivePortfolio();
    const canDeletePortfolio = this.getCanDeletePortfolio();

    return (
      <div className="group-members header-members">
        <div
          style={{
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <BoardHeader
            selectedBoardName={selectedBoardName || selectedBoard.name}
            boardId={selectedBoard.id}
            editGroupOnClick={editGroupOnClick}
            selectedBoard={selectedBoard}
            toggleBoardList={this.toggleBoardList}
            menuActive={this.state.teamBoardMenuOpen}
            setColorPickerRef={this.setColorPickerRef}
            onColorPickerClick={this.onColorPickerClick}
            userIsAdmin={userIsAdmin}
            openAddMembersForm={openAddMembersForm}
            deleteBoard={deleteBoard}
            history={history}
            userSlug={userSlug}
            archiveGroup={archiveGroup}
            unarchiveBoard={unarchiveBoard}
            openAddEditGroupModal={openAddEditGroupModal}
            navigateToHome={navigateToHome}
            exportProjectsListView={exportProjectsListView}
            generateReport={generateReport}
            splitFlags={splitFlags}
            canCreatePortfolio={canCreatePortfolio}
            canArchivePortfolio={canArchivePortfolio}
            canDeletePortfolio={canDeletePortfolio}
          >
            {this.state.teamBoardMenuOpen ? (
              <BoardList
                teamBoardMenuOpen={this.state.teamBoardMenuOpen}
                toggleIsOpenBoardMenu={this.toggleBoardList}
                showTitle={false}
                canCreatePortfolio={canCreatePortfolio}
              />
            ) : null}
          </BoardHeader>

          {selectedBoard.is_personal ? null : (
            <ul className="group-members-list">
              {nonGuestMembers ? this.renderBoardMembers() : ''}
              <li
                data-tip="Click to Manage Portfolio Members"
                data-for="app-tooltip"
                data-place="left"
                data-effect="solid"
                className="group-members-list-item add-members"
              >
                <button
                  className="add-members-button"
                  onClick={openAddMembersFromIcon}
                >
                  <i className="add-members-icon" />
                </button>
              </li>
              {selectedBoard && selectedBoard.is_private && (
                <li className="group-members-list-item has-lock-icon">
                  <div
                    className="lock-icon visible"
                    data-for="app-tooltip"
                    data-tip="This portfolio is private, viewable by members only"
                    data-class="lock-tooltip"
                    data-html
                    data-effect="solid"
                    data-place="left"
                  />
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

BoardMembers.propTypes = {
  selectedBoardName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  memberList: PropTypes.array,
  updateSelectedAccountIds: PropTypes.func.isRequired,
  editGroupOnClick: PropTypes.func.isRequired,
  openTeamMemberModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedAccountIds: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object
};

const makeMapStateToProps = () => {
  const getNonGuestBoardMembers = makeGetNonGuestBoardMembers();
  const mapStateToProps = (state, ownProps) => ({
    userIsAdmin: getUserIsAdmin(state),
    userSlug: getUserSlug(state),
    nonGuestMembers: getNonGuestBoardMembers(state, ownProps),
    splitFlags: getSplitFlags(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  openColorPicker,
  deleteBoard,
  archiveGroup,
  unarchiveBoard,
  openAddEditGroupModal,
  navigateToHome,
  exportProjectsListView,
  generateReport,
  createPortfolio,
  archivePortfolio,
  deletePortfolio
};

export default compose(
  withPermissionsCheck,
  withRouter
)(connect(makeMapStateToProps, mapDispatchToProps)(BoardMembers));
