import styled from 'styled-components';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';
import CalendarIcon from 'icons/CalendarIcon';
import SortIconUp from 'icons/SortUpIcon';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import FolderIcon from 'icons/FolderIcon';
import { Styles } from 'components/Table/styles';
import {
  NavLink,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import {
  TimesheetTopCell,
  StyledProjectTitle as StyledProjectTitle2,
  StyledProjectDescription as StyledProjectDescription2
} from 'views/timesheet/Timesheet/TimesheetProject/styles';
import {
  ActivitySelectionContainer,
  StyledSelectActivityArrow,
  StyledDayInput
} from 'views/timesheet/Timesheet/styles';
import { TimesheetCollapseIconContainer } from './Time/TimesheetsTable/Cells/styles';

import { StyledInput, CloseIconContainer } from 'views/projectPlanner/styles';

import CloseIcon from 'icons/CloseIcon';
import { SelectContainer } from 'views/projectPlanner/workloadBarModal/styles';

import QBDownArrow from 'icons/QBDownArrow';
import { ExpandCollapseAllContainer } from './Time/TimesheetsTable/CollapseAllButton';
import {
  calculateBoardColorPreference,
  calculateBoardBorderColorWithOpacity
} from 'appUtils/styleUtils';

export const StyledAddPlusIcon = styled(PlusCircleIcon).attrs({
  height: 18,
  width: 18
})`
  cursor: pointer;

  circle {
    fill: ${({ theme }) => theme.colors.colorPureWhite};
  }

  &:hover {
    circle {
      fill: ${({ theme }) => theme.colors.colorRoyalBlue};
    }

    path {
      fill: ${({ theme }) => theme.colors.colorPureWhite};
      stroke: ${({ theme }) => theme.colors.colorPureWhite};
    }
  }
`;

export const StyledAddPlusContainer = styled.div`
  bottom: -8px;
  height: fit-content;
  left: -20px;
  position: absolute;
  width: fit-content;
  z-index: 2;
`;

export const StyledQBDownArrow = styled(QBDownArrow)`
  position: absolute;
  right: 5px;
  top: 8px;
`;
export const ArchivedText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  top: 28px;
  left: 56px;
  font-weight: 600;
`;

export const StyledProjectTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray4};
  font-size: 13px;
  padding: 10px 0px;
  white-space: nowrap;
  font-weight: ${(props) => (props.isSubList ? 500 : 600)};
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.isSubList ? '135px' : '')};
`;

export const StyledProjectDescription = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 11px;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0px;
  margin-left: 12px;
  white-space: nowrap;
  margin-left: 16px;
  max-width: ${(props) => props.maxWidth || '250px'};
`;

export const FilterHeader = styled.div`
  font-size: 18px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  padding: 10px 0 10px 30px;
  margin-top: 20px;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
`;

export const StyledDownArrow = styled(QBDownArrow)`
  height: 5px;
  width: 10px;
  margin-left: 6px;
  margin-top: 2px;
  path {
    fill: ${theme.colors.colorRoyalBlue};
  }
`;

export const Page = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;

  ${StyledInput} {
    margin: 0;
  }

  ${CloseIconContainer} {
    right: 30px;
  }
`;
export const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorMediumGray5};
  font-size: 12px;
`;
export const StyledSortIconContainer = styled.div`
  height: 14px;
  width: 14px;
  transition: transform 0.275s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  position: absolute;
  right: -15px;

  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  transform: ${({ order }) =>
    order === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const StyledSortIcon = styled(SortIconUp)`
  height: 10px;
`;

export const StyledContainer = styled.div`
  flex: 4;
  margin-top: 2px;
  position: relative;
  z-index: 0;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  display: flex;
  padding-left: ${(props) => (props.viewType === 'time' ? '32px' : '10px')};
  ${(props) =>
    props.center &&
    `
    justify-content: center;
    padding-left: 0;
  `}
  ${(props) =>
    props.viewType === 'timesheetstatus' &&
    `
    @media (max-width: 1000px) {
      overflow-x: scroll;
      display: block;
    }
  `}
`;

export const StyledNavLink = styled(NavLink)`
  color: #808080;
  padding: 0 7px;
  margin: 14px 0;

  &.active {
    border-left: 2px solid ${(props) => props.theme.colors.colorRoyalBlue};
    color: #4a4a4a;
    font-weight: bold;
  }
`;

export const FeatureHeader = styled.div`
  font-size: 17px;
  padding: 16px 24px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  background: ${({ isWhite }) =>
    isWhite ? theme.colors.colorPureWhite : theme.colors.colorTranslucentGray4};
  position: sticky;
  top: 37px;
  z-index: 1;
  align-items: center;
  ${({ headerStyle }) => headerStyle};
`;

export const StyledKaratRight = styled(KaratRight)`
  height: 13px;
  width: 13px;
  transition: 0.2s ease-in-out;
  margin-right: 6px;
  transform: ${({ isOpen }) => (!isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};
`;

export const FeatureContainer = styled.div`
  padding: 0px;
  background: ${theme.colors.colorTranslucentGray4};
  border-bottom: 1px solid ${theme.colors.colorLightGray6};
  margin-bottom: ${({ isOpen }) => (isOpen ? '30px' : '0px')};
  &:hover {
    ${FeatureHeader}:hover {
      color: ${theme.colors.colorRoyalBlue};
      ${StyledKaratRight} {
        path {
          fill: ${theme.colors.colorRoyalBlue};
        }
      }
    }
  }
`;

export const CheckBoxOptionText = styled.div`
  color: ${({ isChecked }) =>
    isChecked
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorMediumGray5};
  font-size: 12px;
  margin-left: 6px;
`;

export const CheckBoxOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px;
  position: relative;
`;

export const CheckBoxListItemsContainer = styled.ul`
  background: ${theme.colors.colorPureWhite};
  padding: 20px 44px;
  margin: 0;
`;

export const CheckboxMinusIcon = styled.div`
  background: ${theme.colors.colorPureWhite};
  visibility: hidden;
  position: absolute;
  top: 24px;
  width: 6px;
  height: 2px;
  left: 21px;
  &:hover {
    visibility: visible;
  }
`;

export const CustomCheckBoxContainer = styled.div`
  border-radius: 3px;
  border: 1px solid ${theme.colors.colorLightGray6};
  background: ${({ isDisabled }) =>
    isDisabled ? theme.colors.colorLightGray6 : 'transparent'};
  cursor: ${({ isDisabled }) => (isDisabled ? '' : 'pointer')};
  ${(props) => props.isHidden && 'visibility: hidden;'}
  &:hover {
    border: 1px solid ${theme.colors.colorMediumGray4};
    ${CheckboxMinusIcon} {
      visibility: visible;
    }
  }
`;
export const CheckBoxDiv = styled.div`
  margin: 0;
`;

export const CustomCheckBox = styled.div`
  margin: 2px;

  height: 8px;
  width: 8px;
  border-radius: 2px;
  background: ${({ isChecked, isDisabled }) =>
    isDisabled
      ? 'transparent'
      : isChecked
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorPureWhite};
`;

export const CircledRadioCheckBoxContainer = styled(CustomCheckBoxContainer)`
  border-radius: 50%;
`;
export const CircledRadioCheckBox = styled(CustomCheckBox)`
  margin: 1px;
  height: 9px;
  width: 9px;
  border-radius: 50%;
`;

export const HiddenInput = styled.input`
  position: absolute;
  cursor: ${({ isDisabled }) => (isDisabled ? '' : 'pointer')};
  opacity: 0;
  cursor: pointer;
`;

export const DefaultTextContainer = styled.span`
  color: ${theme.colors.colorMediumGray3};
  font-size: 10px;
  margin-left: 6px;
`;

export const StyledReportHeader = styled.div`
  text-align: ${({ align }) => align};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  padding: 4px 0;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  padding-left: ${({ align }) => (align === 'center' ? '0' : '10px')};
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray5};

  &:hover {
    ${(props) =>
      !props.isDisabled &&
      `
      background: ${theme.colors.colorPureWhite};
      color: ${theme.colors.colorRoyalBlue};
      ${StyledSortIconContainer} {
        visibility: visible;
      }`}
  }
`;

export const StyledReportCell = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const StyledDateCell = styled.div`
  color: ${({ isToday }) =>
    isToday
      ? theme.colors.colorCalendarOrange
      : theme.colors.colorSemiDarkGray1};
  cursor: pointer;
`;

export const StyledDateContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    !props.disableHover &&
    `
    &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
  `}
`;
export const StyledReportProject = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  max-width: 270px;

  &:hover {
    ${StyledProjectTitle2}, ${StyledProjectDescription2} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;
export const StyledQBCell = styled.div`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  &:hover {
    path,
    rect {
      fill: ${({ isClickable }) =>
        isClickable ? theme.colors.colorRoyalBlue : ''};
    }
  }
`;
export const StyledRateCell = styled.div`
  ${StyledDayInput} {
    font-size: 12px;
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 400;
  }
  cursor: pointer;
  width: 100%;
`;
export const StyledBillableCell = styled.div`
  cursor: pointer;
`;
export const StyledInputHours = styled.div``;
export const StatusContainer = styled.div`
  width: 100%;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  .btn-secondary {
    padding: 0;
    width: 100%;
  }
  visibility: ${({ isNewRow }) => (isNewRow ? 'hidden' : 'visible')};
`;
export const StyledSelectCellContainer = styled.div`
  height: 100%;
  width: 100%;
`;
export const StyledSelectCell = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 3px;
  border: 1px solid
    ${(props) =>
      props.isChecked ? 'transparent' : theme.colors.colorMediumGray1};
  background: ${({ isChecked, isDisabled, disabledBackgroundColor }) =>
    isDisabled
      ? disabledBackgroundColor || 'transparent'
      : isChecked
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorPureWhite};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`;
export const StyledStatusValue = styled.div`
  border: 1px solid ${({ color }) => color};
  border-radius: 5px;
  color: ${theme.colors.colorSemiDarkGray1};
  padding: 4px 0px;
  font-size: 12px;
  width: 120px;
  position: relative;
`;
export const StyledMemberName = styled.div`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
`;

export const StyledMemberCell = styled.div`
  display: flex;
  padding-left: 10px;
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray1};
  height: ${(props) => (props.isStickyHeader ? 'auto' : '100%')};
  ${SelectContainer} {
    font-size: 13px;
  }
  .styled-down-arrow {
    display: none;
  }

  &:hover {
    .styled-down-arrow {
      display: inline-flex;
      margin-top: 2px;
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
  .logged-member-no-hover,
  .regular-member-no-hover {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
  }
`;

export const ReportsHeader = styled.div`
  padding: 0 0px 0 0px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => marginBottom || 'unset'};

  .row {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .time-entry-button {
    color: ${theme.colors.colorRoyalBlue};
    cursor: pointer;
    padding: 0px 12px;
    margin-left: 4px;
    font-size: 14px;
    font-weight: 600;
  }
  .spaced {
    justify-content: space-between;
  }

  .title {
    font-weight: 600;
    font-size: 26px;
    margin-right: 10px;
  }
  .save-filter-option {
    padding-bottom: 4px;

    &.workload-save-filter {
      padding-bottom: 0px;
    }

    &.utilization-save-filter {
      padding-bottom: 0;
    }

    &.profit-save-filter {
      padding-bottom: 2px;
      padding-left: 19px;
    }

    &.variance-save-filter {
      padding-bottom: 1px;
      padding-left: 18px;
    }
    &.timesheet-status-save-filter {
      padding-left: 9px;
    }

    &.demand-report-save-filter {
      padding-bottom: 0px;
      margin-left: 15px;
    }

    &.workload-save-filter,
    &.utilization-save-filter,
    &.profit-save-filter,
    &.variance-save-filter,
    &.timesheet-status-save-filter,
    &.demand-report-save-filter {
      svg {
        height: 13px;
      }
    }
  }
`;

export const StyledTimesheetReportTable = styled.div`
  position: relative;
  height: 100%;
  overflow: auto hidden;
  margin: auto;
  width: max-content;
  ${StyledReportHeader} {
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid ${theme.colors.colorLightGray6};
  }
  ${Styles} {
    padding: 0;
    height: ${(props) =>
      props.isLoading ? '0' : `calc(100% - ${props.heightAdjustment}px)`};
  }
  .closedWeeklyHeader.scrollPadding ${StyledReportHeader} {
    border-bottom: none;
  }
  .variable-size-list {
    overflow: hidden auto !important;
    height: 100% !important;
  }
  .table {
    margin-bottom: 0;
    height: 100%;
  }
  .table-body {
    height: calc(100% - 27px); // 27px = sticky header
  }
  .table-column-header {
    z-index: 1;
    &.log,
    &.mappingStatus {
      pointer-events: none;
    }

    &.bulk {
      ${StyledReportHeader} {
        border-bottom: unset;
      }
    }
  }
  .table-headers-container,
  .tr {
    padding-left: 33px;
  }

  .table-column-header,
  .td {
    flex-shrink: 0;
    &.scrollPadding {
      flex-shrink: 1;
    }
  }

  .tr.weeklyTimesheetRow {
    .show-on-row-hover {
      visibility: hidden !important;
    }
    &:hover {
      .show-on-row-hover {
        visibility: visible !important;
      }
    }
    .project-planner-group-title {
      max-width: 264px;
    }
  }

  .tr.weeklySubtotalRow {
    align-items: center;
  }

  .empty {
    visibility: hidden;
  }

  .tr.loadingRow {
    align-items: center;
  }

  .tr.timesheetRow .td {
    background: ${theme.colors.colorPureWhite};
    border-top: 1px solid
      ${(props) =>
        props.isWhite ? 'transparent' : theme.colors.colorPaleGray7};
    border-bottom: 1px solid ${theme.colors.colorPaleGray7};
    ${(props) =>
      !props.isWeek &&
      !props.isWhite &&
      ` &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-last-of-type(2) {
        border: none;
        background: transparent;
      }

    &:nth-last-of-type(3) {
      border-right: 1px solid ${theme.colors.colorPaleGray7};
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    `}

    &.bulk {
      background: transparent;
      border-style: unset;
    }
  }

  .tr.weeklyTimesheetRow .td {
    border: 1px solid ${theme.colors.colorPaleGray7};
  }

  .tr.timesheetRow,
  .tr.weeklyTimesheetRow {
    margin-bottom: 2px !important;
    padding-bottom: 2px;
    color: ${theme.colors.colorSemiDarkGray1};
    .time-reports-pencil-icon {
      display: none;
      height: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
    }

    &:hover {
      .time-reports-pencil-icon {
        display: inline-flex;
      }
    }
    &:last-child {
      margin-bottom: 0px !important;
    }
    .billable-switch-container {
      display: none;
    }
    .billable-text {
      display: flex;
    }
    .td {
      background: ${theme.colors.colorPureWhite};
      &:nth-last-of-type(1) {
        border: none;
        background: transparent;
      }

      ${StyledReportProject} {
        height: 100%;
        margin-top: 1px;
        padding-left: 5px;
        cursor: pointer;
        ${TimesheetTopCell} {
          min-height: 18px;
        }
        ${SelectContainer} {
          font-size: 13px;
          position: relative;
          top: 50%;
          margin-top: -1px;
          transform: translateY(-50%);
        }
        .styled-down-arrow {
          display: none;
        }
        &:hover {
          .styled-down-arrow {
            display: flex;
            path {
              fill: ${theme.colors.colorRoyalBlue};
            }
          }
        }
      }
      ${StyledReportCell} {
        padding: 0px 10px;
        ${StyledSelectActivityArrow} {
          path {
            fill: ${theme.colors.colorPureWhite};
          }
        }
        &:focus-within {
          border: 1px solid ${theme.colors.colorRoyalBlue};
        }
        &:hover {
          ${ActivitySelectionContainer} {
            color: ${theme.colors.colorRoyalBlue};
          }
          ${StyledSelectActivityArrow} {
            path {
              fill: ${theme.colors.colorRoyalBlue};
            }
          }
        }
      }
      ${StyledDateCell} {
        font-size: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: 600;
      }
      ${StatusContainer} {
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      ${StyledQBCell},
      ${StyledRateCell},
      ${StyledBillableCell},
      ${StyledSelectCellContainer} {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ${StyledRateCell} {
        .rate-text {
          font-weight: 400;
        }
      }

      ${StyledInputHours} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 13px;
        font-weight: 400;

        &:focus-within {
          border: 1px solid ${theme.colors.colorRoyalBlue};
        }
        .show-on-row-hover {
          left: 38px;

          &.rate-text {
            left: 57px;
          }
        }
        .hour-text {
          font-size: 13px;
          font-weight: 400;
          opacity: 1;
          color: ${theme.colors.colorSemiDarkGray1};
          visibility: visible;
        }

        input {
          width: 80px;
          font-size: 13px;
          font-weight: 400;
          color: ${theme.colors.colorSemiDarkGray1};
        }
      }
    }
  }

  .tr.timesheetRow:hover,
  .tr.weeklyTimesheetRow:hover {
    .label-container {
      justify-content: center;
    }
    .billable-switch-container {
      display: flex;
      justify-content: center;
    }
    .billable-text {
      display: none;
    }

    ${StyledReportProject} {
      ${TimesheetTopCell} {
        visibility: visible;
      }
    }
  }
  .tr.memberRow,
  .tr.projectRow,
  .tr.weeklyMemberRow {
    margin-bottom: 2px !important;
    padding-bottom: 2px;
    display: flex;
    align-items: flex-end;
    color: ${theme.colors.colorSemiDarkGray1};

    .td:first-child {
      width: 100% !important;
    }
  }

  & ${ExpandCollapseAllContainer} {
    left: -1px;
  }

  & ${TimesheetCollapseIconContainer} {
    ${({ inBudgetModal }) => inBudgetModal && 'top: -2px;'}
  }

  & ${StyledAddPlusContainer} {
    left: -29px;
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 0px;
`;
export const StyledDivider = styled.hr`
  padding: 0px;
  margin: 0px;
`;
export const StyledCalendarIcon = styled(CalendarIcon)`
  margin-right: 8px;
  path {
    fill: ${theme.colors.colorRoyalBlue};
  }
`;
export const ReportsDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) => props.disabled && 'pointer-events: none;'}
`;
export const StyledDates = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.colorRoyalBlue};
  margin-right: 10px;
  cursor: default;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  font-size: 12px;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.btn-secondary {
    padding: 5px 12px;
    color: ${theme.colors.colorPureWhite};
    border: 1px solid ${theme.colors.colorRoyalBlue};
    border-radius: 5px;
    background: ${theme.colors.colorRoyalBlue};
    ${StyledDownArrow} {
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
    &:hover {
      background: ${theme.colors.colorDeeperRoyalBlue};
      color: ${theme.colors.colorPureWhite};
    }
  }
`;

export const StyledDropdownItem = styled(DropdownItem)`
  font-size: 13px;
  margin: 0px;
  padding: ${(props) => (props.noPadding ? '0' : '10px 16px')};

  &:active {
    color: ${theme.colors.colorRoyalBlue};
    background: ${theme.colors.colorTranslucentGray3};
  }
`;

export const StyledCreateRowAddCancel = styled.div`
  display: flex;
  width: 120px;
  height: 103%;
  align-items: center;
  position: relative;
  right: 120px;
  bottom: 2px;
`;

export const StyledStyledCreateRowAddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDisabled
      ? theme.colors.colorCalendarGray
      : theme.colors.colorRoyalBlue};
  width: 60px;
  height: 100%;
  color: ${theme.colors.colorPureWhite};
  text-align: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const StyledStyledCreateRowCancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.colorSemiDarkGrey8};
  width: 60px;
  height: 100%;
  padding: 3px;
  text-align: center;
  color: ${theme.colors.colorPureWhite};
  cursor: pointer;
`;
export const StyledInnerDropdownItem = styled.div`
  padding: 10px 16px;
`;

export const StyledSaveButton = styled.div`
  cursor: pointer;
  color: #828282;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  display: flex;
  width: 81px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 12px;

  && {
    margin-right: 20px;
  }
`;

export const IconContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

export const TimesheetThreeDotMenuContainer = styled.div`
  position: relative;
  .project-menu-item-container {
    width: 135px;
  }
`;

export const SelectAllContainer = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  border-bottom: 1px solid transparent;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  div {
    cursor: pointer;
  }
`;

export const BulkAction = styled.div`
  text-align: center;
  padding-right: 4px;
  padding-left: 4px;
  border-right: 1px solid
    ${({ rightBorder }) =>
      rightBorder ? theme.colors.colorRoyalBlue : 'transparent'};
`;

export const ReportTimesheetStyledCell = styled.div`
  background: ${theme.colors.colorPureWhite};
  padding-left: 5px;
  padding-right: 5px;
  color: ${theme.colors.colorSemiDarkGray1};
  border: 1px solid
    ${({ isBlue }) => (isBlue ? theme.colors.colorRoyalBlue : 'transparent')};
  border-radius: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ isRed }) =>
    isRed
      ? `@keyframes blink { 50% { border-color:red ; }  } animation: blink 0.5s;
    animation-iteration-count: 3;`
      : ''}

  &:hover {
    border: 1px solid
      ${({ isBlue }) => (isBlue ? theme.colors.colorRoyalBlue : 'transparent')};
    .content-editable-description {
      &[data-placeholder]:empty:before {
        color: ${theme.colors.colorRoyalBlue};
      }
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  &:focus-within {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }

  /* Don't convert the following code into styled components
  it creates cursor issues during text input
  */
  .content-editable-description {
    border: none;
    overflow: auto;
    max-height: 100%;
    font-weight: 400;
    width: 100%;
    background: ${theme.colors.colorPureWhite};
    white-space: normal;
    resize: none;
    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &[data-placeholder]:empty:before {
      content: attr(data-placeholder);
      color: ${theme.colors.colorMediumGray3};
    }
  }
`;
export const XIcon = styled.div`
  color: ${(props) =>
    props.isSelected
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorSemiDarkGray1};
  left: -15px;
  width: 20px;
  height: 20px;
  position: absolute;
  visibility: hidden;
  &:hover {
    visibility: visible;
  }
`;

export const StyledFilterRow = styled.div`
  color: ${({ isSelected }) =>
    isSelected ? theme.colors.colorRoyalBlue : theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  .member-initials {
    width: 32px;
    height: 32px;
  }
  ${StyledProjectTitle} {
    color: ${({ isSelected }) =>
      isSelected
        ? theme.colors.colorRoyalBlue
        : theme.colors.colorSemiDarkGray1};
  }
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${XIcon} {
      visibility: ${({ isSelected }) => (isSelected ? 'visible' : 'hidden')};
    }
  }
`;

export const StyledAddRow = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
`;
export const StyledCloseIcon = styled(CloseIcon)`
  height: 8px;
  width: 8px;
`;

export const RowContent = styled.div`
  width: 280px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  margin-left: 8px;
  display: flex;
  span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const MainHeaderContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: space-between;
  position: sticky;
  left: 14px;
  z-index: 5;
  padding-left: ${(props) => props.paddingLeft || '4px'};
  width: ${({ width }) => width || 'unset'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'unset'};
  ${(props) =>
    !props.unsetWidths &&
    `
      max-width: 1370px;
      min-width: 1100px;
  `}
`;
export const ChartAndButtonsContainer = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;
  right: ${({ right }) => right || 'unset'};

  .row {
    margin: 0 15px;
  }
`;

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 16px 10px 8px;

  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;

export const StyledHeaderLabel = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const FullHeightFilterDiv = styled.div`
  font-size: 1rem;
  top: -90px;
  padding-top: 90px;
  height: 100vh;
  left: 0;
  z-index: 10;
  position: ${(props) => (props.center ? 'absolute' : 'relative')};
  border-right: 1px solid
    ${(props) => (props.center ? 'transparent' : '#e4e4e4')};
  box-shadow: ${(props) =>
    props.center || !props.scrolled
      ? 'none'
      : '4px 0px 8px rgba(0, 0, 0, 0.1)'};

  @media (max-width: 1500px) {
    position: relative;
  }
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400;
  height: 100%;
  width: 100%;
  max-width: max-content;
  padding-left: 32px;
  ${MainHeaderContainer} {
    position: relative;
    padding-left: '8px';
    left: 0;
  }
`;

export const NoContentContainer = styled.div``;
export const NoContentText = styled.div``;

export const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 6px;
  width: 14px;
  height: 10px;

  path {
    stroke: ${calculateBoardBorderColorWithOpacity};
    fill: ${calculateBoardColorPreference} !important;
    stroke-width: 4;
  }
`;

export const MenuDisabledWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 10px 16px;
  clear: both;
  font-size: 13px;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: default;
`;
