import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Sections, Views } from 'appConstants/navigation';

class MembersSettingsNavigationTracker extends React.PureComponent {
  render() {
    return (
      <NavigationTracker
        // section={Sections.PLANNER}
        view={Views.MEMBERS}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MembersSettingsNavigationTracker)
);
