import { createRequestTypes } from 'appUtils';
import theme from 'theme';

export const SKILL_LEVELS = {
  1: 'Beginner',
  2: 'Familiar',
  3: 'Intermediate',
  4: 'High',
  5: 'Expert'
};

export const GOAL_LEVELS = {
  1: 'Beginner',
  2: 'Familiar',
  3: 'Intermediate',
  4: 'High',
  5: 'Expert'
};

export const NUM_SKILLS = 5;
export const NUM_GOALS = 5;

export const FETCH_SKILLS = createRequestTypes('FETCH_SKILLS');
export const FETCH_SKILL = createRequestTypes('FETCH_SKILL');
export const CREATE_SKILL = createRequestTypes('CREATE_SKILL');
export const UPDATE_SKILL = createRequestTypes('UPDATE_SKILL');
export const ARCHIVE_SKILL = createRequestTypes('ARCHIVE_SKILL');
export const UNARCHIVE_SKILL = createRequestTypes('UNARCHIVE_SKILL');
export const DELETE_SKILL = createRequestTypes('DELETE_SKILL');
export const CREATE_SKILL_MEMBERSHIP = createRequestTypes(
  'CREATE_SKILL_MEMBERSHIP'
);
export const UPDATE_SKILL_MEMBERSHIP = createRequestTypes(
  'UPDATE_SKILL_MEMBERSHIP'
);
export const DELETE_SKILL_MEMBERSHIP = createRequestTypes(
  'DELETE_SKILL_MEMBERSHIP'
);

export const SKILL_LEVEL_TO_COLOR = {
  0: `${theme.colors.colorLightPurple4}`,
  1: `${theme.colors.colorLightPurple5}`,
  2: `${theme.colors.colorLightPurple6}`,
  3: `${theme.colors.colorMediumPurple3}`,
  4: `${theme.colors.colorDarkPurple2}`
};

export const FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT = createRequestTypes(
  'FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT'
);
export const FETCH_SKILLS_REPORT = createRequestTypes('FETCH_SKILLS_REPORT');
export const UPDATE_SKILL_LEVEL_ON_SKILLS_REPORT_LOCAL =
  'UPDATE_SKILL_LEVEL_ON_SKILLS_REPORT_LOCAL';

/* ------------------------------ Entity Skills ----------------------------- */
export const FETCH_ENTITY_SKILLS = createRequestTypes('FETCH_ENTITY_SKILLS');
export const CREATE_ENTITY_SKILL = createRequestTypes('CREATE_ENTITY_SKILL');
export const UPDATE_ENTITY_SKILL = createRequestTypes('UPDATE_ENTITY_SKILL');
export const DELETE_ENTITY_SKILL = createRequestTypes('DELETE_ENTITY_SKILL');
