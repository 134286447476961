import React from 'react';

import { Route, withRouter } from 'react-router-dom';
import ViewFactory from '../../components/ViewFactory/ViewFactory';
import TaskModalRouter from './TaskModalRouter';
import MemberModalRouter from './MemberModalRouter';

import withRouterParams from './WithRouterParams';

class ViewFactoryRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    return (
      <ViewFactory moduleType="membersView" {...this.props}>
        <Route
          path={`${match.url}/member/:memberId/view/:memberViewType`}
          component={MemberModalRouter}
        />
        <Route
          path={`${match.url}/member/:memberId/view/:memberViewType/task/:taskId`}
          component={TaskModalRouter}
        />
      </ViewFactory>
    );
  }
}

export default withRouterParams(withRouter(ViewFactoryRouter));
