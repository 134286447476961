import styled from 'styled-components';
import cn from 'classnames';

export const Alert = styled.span.attrs<{ $hide?: boolean }>(({ $hide }) => ({
  className: cn({ hide: $hide })
}))<{ $hide?: boolean }>`
  color: ${({ theme }) => theme.colors.colorDeleteRed};
  font-weight: 900;
  margin: 0 2px;

  &.hide {
    visibility: hidden;
  }
`;
