import { serializeId } from 'appUtils';
import { useCallback } from 'react';
import { ROW_TYPES } from './layout';

export const useLoadingRowBuilder = () => {
  const loadingRowBuilder = useCallback((id = 0) => {
    return {
      id: serializeId({
        itemType: 'loading',
        id: id.toString(),
        ids: undefined,
        delimiter: undefined
      }),
      rowType: ROW_TYPES.loadingRow,
      rowHeight: 60
    };
  }, []);

  return loadingRowBuilder;
};
