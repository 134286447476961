import {
  createRateGroup,
  deleteRateGroup,
  fetchRateEntities,
  fetchRateGroups,
  fetchRates,
  updateRateGroup,
  fetchEntityRateHistory,
  deleteEntityRate,
  updateEntityRate,
  createEntityRate
} from '../ratesActionCreators';
import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createEntityRateWorker,
  createRateGroupWorker,
  deleteEntityRateWorker,
  deleteRateGroupWorker,
  fetchEntityRateHistoryWorker,
  fetchRateEntitiesWorker,
  fetchRateGroupsWorker,
  fetchRatesWorker,
  updateEntityRateWorker,
  updateRateGroupWorker
} from './ratesWorkers';

export function* ratesSagas() {
  const ratesSagas = [
    fork(takeEvery, createEntityRate, createEntityRateWorker),
    fork(takeEvery, createRateGroup, createRateGroupWorker),
    fork(takeEvery, deleteEntityRate, deleteEntityRateWorker),
    fork(takeEvery, deleteRateGroup, deleteRateGroupWorker),
    fork(takeEvery, fetchEntityRateHistory, fetchEntityRateHistoryWorker),
    fork(takeLatest, fetchRateEntities, fetchRateEntitiesWorker),
    fork(takeLatest, fetchRateGroups, fetchRateGroupsWorker),
    fork(takeLatest, fetchRates, fetchRatesWorker),
    fork(takeEvery, updateEntityRate, updateEntityRateWorker),
    fork(takeEvery, updateRateGroup, updateRateGroupWorker)
  ];
  yield all(ratesSagas);
}
