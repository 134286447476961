import { select } from 'redux-saga/effects';
import { fetchEntity } from 'sagas/generics';
import * as csvImportApi from 'CsvImportsModule/api';
import {
  fetchCsvImportStatusActionCreatorsMap,
  fetchCsvImportStatus
} from 'CsvImportsModule/actionCreators';
import { getAuthToken } from 'AuthenticationModule/selectors';

export function* fetchCsvImportStatusWorker(
  action: ReturnType<typeof fetchCsvImportStatus>
) {
  const token = yield select(getAuthToken);

  yield fetchEntity(
    fetchCsvImportStatusActionCreatorsMap,
    csvImportApi.fetchCsvImportStatus,
    null,
    [token, action.payload.targetServiceId, action.payload.body],
    action
  );
}
