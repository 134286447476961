import { useState } from 'react';
import NumberFormat, {
  NumberFormatValues,
  SourceInfo
} from 'react-number-format';
import styled from 'styled-components';
import { DependencyTagItemContent } from './DependencyTag';
import { HandleChangeDeltaDays } from '../types';

enum DisplayTypeStrings {
  Text = 'text',
  Input = 'input'
}

export const DeltaDaysInput = ({
  value,
  onChange
}: {
  value: number;
  onChange: HandleChangeDeltaDays;
}) => {
  const [displayType, setDisplayType] = useState(DisplayTypeStrings.Text);

  const handleChange = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (floatValue !== undefined && source === 'event')
      onChange({ value: floatValue });
  };

  const handleClick = () => {
    setDisplayType(DisplayTypeStrings.Input);
  };

  const handleBlur = () => {
    setDisplayType(DisplayTypeStrings.Text);
  };

  const pluralize = value !== 1;

  return (
    <DependencyTagItemContent
      contentText={`${value} Workday${pluralize ? 's' : ''}`}
    >
      <DeltaDaysInputContainer onClick={handleClick}>
        <DeltaDaysNumberFormat
          placeholder=" d"
          value={value}
          onValueChange={handleChange}
          decimalScale={0}
          allowNegative={false}
          thousandSeparator={true}
          onBlur={handleBlur}
          allowEmptyFormatting
          displayType={displayType}
          autoFocus
        />{' '}
        Workday{pluralize ? 's' : ''}
      </DeltaDaysInputContainer>
    </DependencyTagItemContent>
  );
};

const DeltaDaysInputContainer = styled.div`
  input {
    width: 33px;
    height: 19px;
    border: 0.5px solid ${({ theme }) => theme.colors.colorCalendarBlue};
    border-radius: 3px;
  }
`;

const DeltaDaysNumberFormat = styled(NumberFormat)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.colorCalendarBlue};

  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
