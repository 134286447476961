import { MouseEvent } from 'react';
import styled from 'styled-components';
import { RateGroupToggleCellProps, TableCellProps } from '../../types';
import { isRateGroupToggleCell } from '../../utils';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import cn from 'classnames';

export const MenuCell = (props: TableCellProps) =>
  isRateGroupToggleCell(props) ? (
    <MenuCellWithRateGroupToggleProps {...props} />
  ) : null;

const MenuCellWithRateGroupToggleProps = (props: RateGroupToggleCellProps) => {
  const {
    row: {
      original: { isMenuOpen, toggleMenu }
    }
  } = props;

  const handleMenuClick = (event: MouseEvent<SVGElement>) =>
    toggleMenu(event.currentTarget);

  return (
    <Cell>
      <StyledThreeDot active={isMenuOpen} onClick={handleMenuClick} />
    </Cell>
  );
};

const Cell = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 0;
`;

const StyledThreeDot = styled(ThreeDotHorizontal).attrs<{
  $size?: number;
}>(({ $size = 24, active }) => ({
  className: cn({ active }),
  size: $size * (26 / 28)
}))<{ $size?: number }>`
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: 0.1s ease-in;

  &:hover,
  &.active {
    transform: rotate(-90deg) scale(1.1);

    path {
      fill: ${({ theme }) => theme.colors.colorRoyalBlue};
      stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }
`;
