import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  navigateToBoard,
  openAddEditGroupModal,
  setSelectedBoard,
  openAddProjectModal
} from 'actionCreators';
import { getSelectedBoard } from 'selectors';
import Popover from 'components/Popover';
import ThreeDot from 'IntegrationsModule/components/ThreeDot';
import ColorSetIcon from './icons/color-palette-icon.svg';
import InfoIcon from './icons/info-icon.svg';
import {
  Icon,
  StyledMenuItem,
  StyledMenu,
  StyledDropdown
} from './ProjectDropdown';
import ColorPicker from 'components/ColorPicker';
import GoToIcon from 'images/go-to-icon.svg';
import Plus from './icons/plus-icon.svg';
import { isStarredOrUnstarredBoard, isPersonalBoard } from 'appUtils/boards';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const BoardDropdown = ({ board, isOpen, setIsOpen }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const dispatch = useDispatch();
  const toggleRef = useRef(null);
  const selectedBoard = useSelector(getSelectedBoard);
  const [prevSelectedBoard, setPrevSelectedBoard] = useState(null);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
    setShowColorPicker(false);
    // Handles the case where user clicks the toggle, since
    // PopOver closePopover gets called before onClick above
    // so isOpen is incorrect
    setTimeout(() => setIsOpen(false), 200);
  };

  const onGoToPortfolio = () => {
    dispatch(
      navigateToBoard({
        teamSlug: board.team_slug,
        boardSlug: board.slug,
        boardId: board.id
      })
    );
  };

  const onAddProject = () => {
    dispatch(
      openAddProjectModal({
        useSelectedBoardOverride: true
      })
    );
  };

  const onSetColor = (e) => {
    e.stopPropagation();
    setShowColorPicker(true);
  };

  const onInfoClick = () => {
    dispatch(openAddEditGroupModal({ isEdit: true, id: board.id }));
  };

  const onColorSelect = () => {
    setShowColorPicker(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && board?.id && selectedBoard?.id !== board.id) {
      setPrevSelectedBoard(selectedBoard);
      dispatch(setSelectedBoard({ board }));
    }
  }, [board, dispatch, isOpen, selectedBoard]);

  // useEffect(() => {
  //   if (!isOpen) {
  //     if (
  //       prevSelectedBoard &&
  //       selectedBoard &&
  //       prevSelectedBoard.id !== selectedBoard.id &&
  //       selectedBoard.id === board.id
  //     ) {
  //       dispatch(setSelectedBoard({ board: prevSelectedBoard }));
  //     }
  //     if (showColorPicker) {
  //       setShowColorPicker(false);
  //     }
  //   }
  // }, [
  //   board.id,
  //   dispatch,
  //   isOpen,
  //   prevSelectedBoard,
  //   selectedBoard,
  //   showColorPicker
  // ]);

  return (
    <StyledDropdown ref={toggleRef} onClick={onClick} showToggle={isOpen}>
      <ThreeDot />
      <Popover
        isOpen={isOpen}
        closePopover={closeDropdown}
        target={toggleRef}
        boundariesElement="window"
        placement={showColorPicker ? 'bottom-start' : 'auto'}
      >
        {showColorPicker ? (
          <ColorPicker
            boardId={board.id}
            id={board.id}
            originType={ORIGIN_TYPE_STRINGS.BOARD}
            pickerLocation={`sidebar-dropdown-${board.id}`}
            row
            className="color-picker-container"
            headerText="Select Portfolio Color"
            alwaysOpen
            handleSelect={onColorSelect}
          >
            <div />
          </ColorPicker>
        ) : (
          <StyledMenu onClick={closeDropdown}>
            {!isStarredOrUnstarredBoard(board) && (
              <StyledMenuItem onClick={onAddProject}>
                <Icon>
                  <img src={Plus} />
                </Icon>
                Add a Project
              </StyledMenuItem>
            )}
            {!isPersonalBoard(board) && (
              <StyledMenuItem onClick={onGoToPortfolio}>
                <Icon>
                  <img src={GoToIcon} />
                </Icon>
                Go to Portfolio
              </StyledMenuItem>
            )}
            {!board.isPersonal && (
              <StyledMenuItem onClick={onInfoClick}>
                <Icon>
                  <img src={InfoIcon} />
                </Icon>
                Portfolio Info
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={onSetColor}>
              <Icon>
                <img src={ColorSetIcon} />
              </Icon>
              Portfolio Color
            </StyledMenuItem>
          </StyledMenu>
        )}
      </Popover>
    </StyledDropdown>
  );
};

export default BoardDropdown;
