import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import theme from 'theme';
import { getOnProjectDetail } from 'selectors';
import { makeGetTeamMembershipByAccountId } from 'TeamsModule/selectors';
import {
  makeGetMemberIsGuestByAccountId,
  makeGetMemberIsContractorByAccountId
} from 'PermissionsModule/selectors';
import styled from 'styled-components';
import GuestIcon from 'icons/GuestIcon';
import ContractorIcon from 'icons/ContractorIcon';
import PMDot from './PMDot';
import { calculateColorPref } from 'appUtils/styleUtils';
import { createPortal } from 'react-dom';
import { tooltipPortal } from 'appUtils/portal';
import { CONDENSED_ZOOM_LEVELS } from 'appConstants/workload';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import merge from 'lodash/merge';

const IndicatorIconContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top || (props.isOnProjectDetail ? '15px' : '16px')};
  left: ${(props) => props.left || (props.isOnProjectDetail ? '20px' : '19px')};
`;

const calcMemberBackgroundColor = (props) => {
  if (props.selected) {
    return calculateColorPref(props);
  } else if (props.loggedInUser) {
    return theme.colors.colorMediumGray3;
  } else {
    return theme.colors.colorMediumGray1;
  }
};

const calcMemberBorder = (props) => {
  if (props.selected) {
    return 'solid 1px transparent';
  } else {
    return `solid 1px ${calculateColorPref(props)}`;
  }
};

const avatarSizeMap = {
  large: 60,
  xMedium: 50,
  medium: 32,
  default: 30,
  mediumSmall: 28,
  small: 26
};
const fontSizeMap = {
  large: 24,
  xMedium: 20,
  medium: 13,
  default: 13,
  mediumSmall: 11,
  small: 11
};
const smallFontSizeMap = {
  large: 22,
  xMedium: 15,
  medium: 12,
  default: 12,
  mediumSmall: 10,
  small: 10
};

const getAvatarSize = (size) => {
  const s = avatarSizeMap[size] ?? avatarSizeMap.default;
  return s + 'px';
};

const getFontSize = (size, smallFont = false) => {
  const s = smallFont
    ? smallFontSizeMap[size] ?? smallFontSizeMap.default
    : fontSizeMap[size] ?? fontSizeMap.default;
  return s + 'px';
};

export const StyledMemberCircle = styled.button`
  background-color: ${calcMemberBackgroundColor} !important;
  border: ${calcMemberBorder} !important;
  border-width: 2px !important;
  font-weight: 600;
  height: ${({ size }) => getAvatarSize(size)};
  width: ${({ size }) => getAvatarSize(size)};
  flex: 0 0 ${({ size }) => getAvatarSize(size)};
  font-size: ${({ size, smallFont }) => getFontSize(size, smallFont)};
  opacity: ${({ $archived }) => ($archived ? 0.5 : 1)};
`;

class MemberInitials extends React.Component {
  state = {
    showTooltip: false
  };

  getTargetType() {
    const {
      projectDetailView,
      projectId,
      boardMembers,
      projectItem,
      isOnTaskModal,
      isProjectStatus
    } = this.props;

    if (projectDetailView) {
      return `detail-${projectId}`;
    } else if (boardMembers) {
      return 'board';
    } else if (projectItem) {
      return `item-${projectId}`;
    } else if (isOnTaskModal) {
      return 'task-modal';
    } else if (isProjectStatus) {
      return `project-status-${projectId}`;
    }
  }

  render() {
    const {
      classes,
      onClick,
      idx,
      projectMembers,
      projectItem,
      projectDetailView,
      isAnimating,
      projectId,
      isOnProjectDetail,
      taskCircle,
      shouldShowHTMLTitle,
      isOnTaskModal,
      memberIsGuest,
      memberIsContractor,
      member: memberProps = {},
      nonBaseMemberRoleTop,
      nonBaseMemberRoleLeft,
      teamMembership = {},
      overwriteDotPosition,
      size = 'default', // 30px
      styles = {}
    } = this.props;
    const member = merge({}, teamMembership, memberProps);

    const targetType = this.getTargetType();

    const isProjectDetailTooltip =
      isOnProjectDetail && targetType === `detail-${projectId}`;

    const isShowingProjectMember = projectMembers || projectItem;

    const isManager = member.role_ids?.some(
      (role) => role === ACCESS_ROLES.ADMIN
    );
    const shouldShowPMDot =
      (isManager && isShowingProjectMember) || this.props.isManager;
    const isLongInitials = member.initials?.length === 3;

    const roleTop =
      size === CONDENSED_ZOOM_LEVELS.VERY_SMALL ? '7px' : nonBaseMemberRoleTop;
    const roleLeft =
      size === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        ? '10px'
        : nonBaseMemberRoleLeft;

    const IndicatorComponent = memberIsGuest
      ? GuestIcon
      : memberIsContractor
      ? ContractorIcon
      : undefined;
    return (
      <span
        className={cn('member-initials', {
          'project-detail-view': projectDetailView,
          'hidden-xs-up': isAnimating,
          'task-circle-regular-member':
            taskCircle && classes && classes.includes('small-regular-member')
        })}
        member={member}
        selected={classes && classes.includes('selected')}
        style={styles}
      >
        {!isOnTaskModal &&
          (idx !== undefined || isProjectDetailTooltip) &&
          createPortal(
            <ReactTooltip effect="solid" id={`initials-${targetType}-${idx}`}>
              <div style={{ textAlign: 'center' }}>
                {(member.account && member.account.name) || member.name}
              </div>
              {isManager && !isShowingProjectMember && (
                <div className="project-manager-text">Team Manager</div>
              )}
              {isManager && isShowingProjectMember && (
                <div className="project-manager-text">Project Manager</div>
              )}
              {memberIsGuest && <div className="project-guest-text">Guest</div>}
            </ReactTooltip>,
            tooltipPortal
          )}
        <StyledMemberCircle
          size={size}
          title={shouldShowHTMLTitle ? 'Click to filter by member' : null}
          className={classes}
          onClick={onClick}
          data-for={`initials-${targetType}-${idx}`}
          data-iscapture="true" // to not show tooltips while scrolling
          selected
          smallFont={
            (member?.account && member?.account?.initials?.length > 2) ||
            member?.initials?.length > 2
          }
          loggedInUser={classes && classes.includes('logged-member')}
          data-tip
          id={member.id}
          originType="teamMembership"
          color={member.color}
          $archived={member.is_archived}
        >
          <div
            className={cn('initial-div', {
              'long-initials': isLongInitials
            })}
            id={member.account && member.account.id}
          >
            {shouldShowPMDot ? (
              <PMDot overwriteDotPosition={overwriteDotPosition} />
            ) : null}
            {(member.account && member.account.initials) || member.initials}
          </div>
          {IndicatorComponent && (
            <IndicatorIconContainer
              isOnProjectDetail={isOnProjectDetail}
              top={roleTop}
              left={roleLeft}
            >
              <IndicatorComponent width={13} height={13} />
            </IndicatorIconContainer>
          )}
        </StyledMemberCircle>
      </span>
    );
  }
}

MemberInitials.propTypes = {
  classes: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
  const getMemberIsGuest = makeGetMemberIsGuestByAccountId();
  const getMemberIsContractor = makeGetMemberIsContractorByAccountId();
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();
  const mapStateToProps = (state, ownProps) => ({
    isOnProjectDetail: getOnProjectDetail(state),
    memberIsGuest: ownProps.member
      ? getMemberIsGuest(state, ownProps.member)
      : false,
    memberIsContractor: ownProps.member
      ? getMemberIsContractor(state, ownProps.member)
      : false,
    // The color of the member intials is determiend by accoundId/.account.id/account_id of member.
    teamMembership: ownProps.member
      ? getTeamMembershipByAccountId(state, {
          ...ownProps.member,
          accountId: ownProps.member?.id
        })
      : undefined
  });
  return mapStateToProps;
};

export default withRouter(connect(makeMapStateToProps)(MemberInitials));
