import React from 'react';
import { connect } from 'react-redux';
import {
  getProjectModuleOrder,
  getBoardModuleOrder,
  getSelectedBoard,
  getMatchedRouteParams,
  getMembersModuleOrder,
  getSelectedTeamId
} from 'selectors';
import * as projectModuleUtils from 'appUtils/appModuleUtils';
import * as boardModuleUtils from 'appUtils/boardModuleUtils';
import * as membersModuleUtils from 'appUtils/membersModuleUtils';
import {
  updateProjectModules,
  updateBoardModules,
  updateTeamOrderedItems
} from 'actionCreators';
import { withRouter } from 'react-router-dom';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import theme from 'theme';
import { StyledDragGripIcon } from '../../views/Home/TaskList/styles';

const moduleUtilsHash = {
  projectDetailView: projectModuleUtils,
  boardView: boardModuleUtils,
  membersView: membersModuleUtils
};
const indexBreak = (array) => array.indexOf('break');
const sliceBreak = (array) => array.slice(0, indexBreak(array));
const sliceAfterBreak = (array) => array.slice(indexBreak(array) + 1);
export const StyledListBreak = styled.div`
  width: 98%;
  height: 1px;
  background-color: #e4e4e4;
  margin: 12px auto;
`;

const AddText = styled.div`
  font-size: 12px;
  color: #828282;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ListTitle = styled.div`
  font-size: 12;
  color: #828282;
`;
const EmptyContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledModuleListing = styled.div`
  flex-grow: 1;
  display: flex;

  ${StyledDragGripIcon} {
    visibility: hidden;
  }

  &:hover {
    ${StyledDragGripIcon} {
      visibility: visible;
    }
  }
`;
const DropContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.moduleHash.generateModuleBackgroundColor(props)};
  background-image: ${(props) =>
    props.moduleHash.generateModuleBackgroundIcon(props)};
  background-position: center center;
  background-repeat: no-repeat;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #3b3b3b;
`;
const Description = styled.div`
  font-size: 12px;
  color: #808080;
`;
const DescriptionContainer = styled.div`
  padding: 0px 0px 0px 15px;
  width: 364px;
`;

const ComingSoonText = styled.div`
  font-size: 12px;
  color: #f9a810;
  font-style: italic;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PremiumAdd = styled.div``;
const ModuleDraggable = styled.div`
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  outline: none;
  cursor: pointer !important;

  ${PremiumAdd} {
    ${ComingSoonText} {
      display: flex;
    }
    ${AddText} {
      display: none;
    }
  }

  &:hover {
    background-color: #f1f1f1;
  }
  ${StyledDragGripIcon} {
    margin: 0 9px 0 4px;
    cursor: grab;
  }
  &:hover {
    ${AddText} {
      color: #0074d9;
    }

    &:hover {
      ${PremiumAdd} {
        ${ComingSoonText} {
          display: none;
        }
        ${AddText} {
          display: flex;
        }
      }
    }
  }
`;
const ModuleListing = ({ appModule, moduleHash }) => (
  <StyledModuleListing>
    <IconContainer appModule={appModule} moduleHash={moduleHash} />
    <DescriptionContainer>
      <Title>{moduleHash.moduleTextHash[appModule]}</Title>
      <Description>{moduleHash.moduleDescriptionHash[appModule]}</Description>
    </DescriptionContainer>
  </StyledModuleListing>
);

class ModuleOrderableList extends React.Component {
  state = {
    appModules:
      this.props.moduleType === 'projectDetailView'
        ? this.props.projectModules
        : this.props.moduleType === 'membersView'
        ? this.props.membersModules
        : this.props.boardModules
  };

  moduleHash = moduleUtilsHash[this.props.moduleType];
  componentDidUpdate(prevProps) {
    if (
      prevProps.matchedParams.projectId !==
        this.props.matchedParams.projectId &&
      this.props.moduleType === 'projectDetailView'
    ) {
      this.setState({
        appModules: this.props.projectModules
      });
    }
    if (
      this.props.moduleType === 'boardView' &&
      (this.props.selectedBoard && this.props.selectedBoard.board_modules) !==
        (prevProps.selectedBoard && prevProps.selectedBoard.board_modules)
    ) {
      this.setState({
        appModules: this.props.boardModules
      });
    }
    if (
      this.props.moduleType === 'membersView' &&
      this.props.membersModules !== prevProps.membersModules
    ) {
      this.setState({
        appModules: this.props.membersModules
      });
    }
  }

  reorder = (order) => {
    const {
      updateProjectModules,
      updateBoardModules,
      moduleType,
      teamId,
      updateTeamOrderedItems,
      matchedParams: { projectId, boardId }
    } = this.props;
    const appModules = sliceBreak(order);
    if (appModules.length) {
      this.setState({
        appModules: order
      });
      if (moduleType === 'projectDetailView') {
        updateProjectModules({
          projectId,
          appModules
        });
      }
      if (moduleType === 'boardView') {
        updateBoardModules({
          boardId,
          appModules
        });
      }
      if (moduleType === 'membersView') {
        updateTeamOrderedItems({
          team_id: teamId,
          members_views_order: appModules
        });
      }
    }
  };

  onDragEnd = (result) => {
    const { destination, draggableId } = result;
    if (!destination) {
      return;
    }
    const { appModules } = this.state;
    const moduleOrder = appModules.filter(
      (appModule) => appModule !== +draggableId
    );

    const newActive = sliceBreak(appModules).filter(
      (appModule) => appModule !== +draggableId
    );
    const newInactive = sliceAfterBreak(appModules).filter(
      (appModule) => appModule !== +draggableId
    );
    if (destination.droppableId === 'active-modules') {
      newActive.splice(destination.index, 0, +draggableId);
    } else {
      newInactive.splice(destination.index, 0, +draggableId);
    }
    const newOrder = [...newActive, 'break', ...newInactive];

    this.reorder(newOrder);
  };

  move = (index) => {
    const { appModules } = this.state;
    const breakIndex = indexBreak(appModules);
    const newOrder = appModules.filter((appModule, i) => i !== index);

    newOrder.splice(breakIndex, 0, appModules[index]);
    this.reorder(newOrder);
  };

  renderAdd = (appModule) =>
    appModule === 3 || appModule === 6 ? (
      <PremiumAdd>
        <ComingSoonText>Pro Plan</ComingSoonText>
        <AddText> Add </AddText>
      </PremiumAdd>
    ) : (
      <AddText> Add </AddText>
    );

  renderRemove = () => (
    <AddText>
      <span className="remove-text">Remove</span>
    </AddText>
  );

  renderComingSoon = (index) => (
    <div>
      <ComingSoonText>Pro Plan </ComingSoonText>
    </div>
  );

  renderActiveOption = (appModule, index) => (
    <Draggable draggableId={`${appModule}`} index={index} key={`${appModule}`}>
      {(provided, snapshot) => (
        <ModuleDraggable
          index={index}
          appModule={appModule}
          provided={provided}
          isActive={true}
          handleClick={this.handleClick}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => this.move(index)}
        >
          <StyledDragGripIcon />
          <ModuleListing appModule={appModule} moduleHash={this.moduleHash} />
          {this.renderRemove()}
        </ModuleDraggable>
      )}
    </Draggable>
  );

  renderInactiveOption = (appModule, index) => (
    <Draggable draggableId={`${appModule}`} index={index}>
      {(provided, snapshot) => (
        <ModuleDraggable
          index={index}
          appModule={appModule}
          provided={provided}
          isActive={false}
          handleClick={this.handleClick}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() =>
            this.move(index + indexBreak(this.state.appModules) + 1)
          }
        >
          <StyledDragGripIcon />
          <ModuleListing appModule={appModule} moduleHash={this.moduleHash} />
          {this.renderAdd(appModule)}
        </ModuleDraggable>
      )}
    </Draggable>
  );

  renderComingSoonOption = (appModule, index) => (
    <Draggable
      draggableId={`${appModule}`}
      index={index + sliceAfterBreak(this.state.appModules).length}
      isDragDisabled={true}
    >
      {(provided, snapshot) => (
        <ModuleDraggable
          index={index}
          appModule={appModule}
          provided={provided}
          isActive={false}
          handleClick={this.handleClick}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledDragGripIcon />
          <ModuleListing appModule={appModule} moduleHash={this.moduleHash} />
          {this.renderComingSoon()}
        </ModuleDraggable>
      )}
    </Draggable>
  );

  render() {
    const { comingSoonModuleIds } = this.moduleHash;
    const { appModules } = this.state;
    const activeModules = sliceBreak(appModules);
    const inactiveModules = sliceAfterBreak(appModules);
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <DropContainer>
          <Droppable droppableId="active-modules">
            {(provided, snapshot) => (
              <div
                style={{ minHeight: '74px' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {activeModules.map(this.renderActiveOption)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DropContainer>
        <StyledListBreak key={'break'} />
        <DropContainer>
          <Droppable droppableId="inactive-modules">
            {(provided, snapshot) => (
              <div
                style={{ minHeight: '74px' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {inactiveModules.map(this.renderInactiveOption)}
                {comingSoonModuleIds.map(this.renderComingSoonOption)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {inactiveModules.length === 0 ? (
            <EmptyContainer> You have added all your views</EmptyContainer>
          ) : (
            ''
          )}
        </DropContainer>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedBoard: getSelectedBoard(state),
  projectModules: getProjectModuleOrder(state),
  boardModules: getBoardModuleOrder(state),
  membersModules: getMembersModuleOrder(state),
  matchedParams: getMatchedRouteParams(state),
  teamId: getSelectedTeamId(state)
});
const mapDispatchToProps = {
  updateProjectModules,
  updateBoardModules,
  updateTeamOrderedItems
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModuleOrderableList)
);
