import styled from 'styled-components';
import theme from 'theme';
import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { useActivitiesFilter } from 'FilterModule/hooks/useActivitiesFilter';
import {
  FilterListTypeDropdown,
  FilterListTypeDropdownUIProps
} from 'FilterModule/types';

export const ActivitiesFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const {
    updateSelectedItems,
    itemHash,
    optionsArray,
    selectedItems,
    isLoading
  } = useActivitiesFilter();

  return (
    <ActivitiesFilterDropdownUI
      itemHash={itemHash}
      optionsArray={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      renderToggle={renderToggle}
      isLoading={isLoading}
    />
  );
};

export const ActivitiesFilterDropdownUI = ({
  itemHash,
  optionsArray,
  selectedItems,
  updateSelectedItems,
  renderToggle,
  isLoading
}: FilterListTypeDropdownUIProps<typeof useActivitiesFilter>) => {
  const renderLabel = (id: number) => {
    const activity = itemHash[id];
    if (!activity) return <></>;
    return (
      <StyledActivityLabel
        className="no-text-overflow"
        data-for="app-tooltip"
        data-tip={activity.title}
        data-delay-show={500}
        data-effect="solid"
      >
        {activity.archived && (
          <StyledArchivedLabel>ARCHIVED</StyledArchivedLabel>
        )}
        {activity.title}
      </StyledActivityLabel>
    );
  };

  return (
    <SimpleFilterDropdown
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isBatchClearOnly
      labels={labels}
      labelKey="title"
      itemHeight={55}
      listWidth={250}
      listHeight={220}
      itemHash={itemHash}
      renderItemLabel={renderLabel}
      renderToggle={renderToggle}
      isLoading={isLoading}
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

const labels = {
  searchPlaceholder: 'Type Work Category or select below'
};

const StyledActivityLabel = styled.div``;

const StyledArchivedLabel = styled.span`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  bottom: -7px;
  left: 37px;
  font-weight: 600;
`;
