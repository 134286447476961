import PermissionsSection from './PermissionsSection';
import CheckBoxContainer from '../../../CheckboxContainer';
import Collapse from '../../../Collapse';
import { flatOptions } from 'SettingsModule/utils/checkboxOptions';
import buildPermittedRoles from 'SettingsModule/utils/buildPermittedRolesString';
import TeamsIcon from 'icons/TeamsIcon';
import { permissionsActionsHash } from 'PermissionsModule/constants';
import { HeaderContainer, PermissionText } from './styles';
import { PermissionsActions } from 'PermissionsModule/types';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getAllPermissionsByActions,
  getPermissionActionsExpanded
} from 'RoleTemplatesModule/selectors';

interface TeamPermissionsProps {
  makeTogglePermission: (actionType: PermissionsActions) => void;
  toggleOpen: (actionType: PermissionsActions) => void;
}

const TeamPermissions = ({
  makeTogglePermission,
  toggleOpen
}: TeamPermissionsProps) => {
  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const expandedPermissions = useAppSelector(getPermissionActionsExpanded);

  const renderHeader = (permissionAction: PermissionsActions) => {
    const roleTemplatesHash = permissionsByAction[permissionAction];
    const permissionData = permissionsActionsHash[permissionAction];

    return (
      <HeaderContainer>
        <div>{permissionData ? permissionData.question : ''}</div>
        {roleTemplatesHash ? (
          <PermissionText>
            {buildPermittedRoles(roleTemplatesHash)}
          </PermissionText>
        ) : null}
      </HeaderContainer>
    );
  };

  return (
    <PermissionsSection headerText={'Portfolios'} Icon={TeamsIcon}>
      <Collapse
        header={renderHeader('create_public_boards')}
        isOpen={expandedPermissions.create_public_boards}
        toggleOpen={() => toggleOpen('create_public_boards')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_public_boards}
          onCheck={makeTogglePermission('create_public_boards')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('edit_public_board_memberships')}
        isOpen={expandedPermissions.edit_public_board_memberships}
        toggleOpen={() => toggleOpen('edit_public_board_memberships')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.edit_public_board_memberships}
          onCheck={makeTogglePermission('edit_public_board_memberships')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('create_public_board_memberships')}
        isOpen={expandedPermissions.create_public_board_memberships}
        toggleOpen={() => toggleOpen('create_public_board_memberships')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_public_board_memberships}
          onCheck={makeTogglePermission('create_public_board_memberships')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('create_private_boards')}
        isOpen={expandedPermissions.create_private_boards}
        toggleOpen={() => toggleOpen('create_private_boards')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_private_boards}
          onCheck={makeTogglePermission('create_private_boards')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('create_archived_boards')}
        isOpen={expandedPermissions.create_archived_boards}
        toggleOpen={() => toggleOpen('create_archived_boards')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_archived_boards}
          onCheck={makeTogglePermission('create_archived_boards')}
        />
      </Collapse>
      {/* }
      <Collapse header={<div> Who can Delete teams? </div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={this.setSelected}
        />
      </Collapse>
      <Collapse header={<div>Who can Show/Hide Views to Teams?</div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={this.setSelected}
        />
      </Collapse>
      <Collapse header={<div> Who can Show/Hide Budget View to Teams? </div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={this.setSelected}
        />
      </Collapse>
      <Collapse
        header={<div>Who can Add Columns to Team project lists?</div>}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={this.setSelected}
        />
      </Collapse> */}
    </PermissionsSection>
  );
};

export default TeamPermissions;
