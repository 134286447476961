import theme from 'theme';
import { ProjectMembershipRequirementListItem } from '../types';
import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';
import { Tag } from 'SuggestionModule/components/Tag';
import DeleteXIcon from 'icons/DeleteXIcon';
import styled from 'styled-components';

export const ProjectMembershipRequirement = (
  item: ProjectMembershipRequirementListItem
) => {
  const {
    memberBudget: { id: memberBudgetId },
    projectMembership: { id: projectMembershipId },
    requirement,
    onAddRequirementsClick
  } = item;

  const { id, name, tooltip, onDelete, type } = requirement;

  return (
    <StyledItemContainer
      data-testid="project-membership-requirements-item-container"
      className="project-membership-requirements"
    >
      <Tag
        id={id}
        onClick={() => {
          onAddRequirementsClick({ memberBudgetId, projectMembershipId });
        }}
        dataTip={tooltip}
        name={name}
        type={type}
      />
      <DeleteIconContainer
        data-testid="delete-icon-container"
        onClick={onDelete}
      >
        <DeleteXIcon
          color={theme.colors.colorMediumGray9}
          className={undefined}
        />
      </DeleteIconContainer>
    </StyledItemContainer>
  );
};

const DeleteIconContainer = styled.span`
  padding-left: 5px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  path {
    fill: ${theme.colors.colorBudgetGrey};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
`;
