import integrities from './integrities.json';

const loadRecurly = () => {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://js.recurly.com/v4/recurly.js';
  script.integrity = integrities.recurly;
  script.crossOrigin = 'anonymous';
  body.appendChild(script);
};

export const initRecurly = () => {
  loadRecurly();
};
