export const FIELD_RENAME_MAP = {
  department_name: 'department_name',
  department_id: 'department_id',
  position_id: 'position_id',
  account_id: 'account_id',
  project_id: 'project_id',
  phase_id: 'phase_id',
  phase_name: 'phase_name',
  board_id: 'board_id',
  activity_id: 'activity_id',
  client_id: 'client_id',
  client_name: 'client_name',
  billable: 'billable',
  skill_id: 'skill_id',
  region_id: 'region_id',
  office_id: 'office_id',
  discipline_id: 'discipline_id',
  team_membership_id: 'team_membership_id',
  project_budget_status: 'project_budget_status',
  phase_budget_status: 'phase_budget_status',
  profit_center: 'profit_center'
} as const;

export const FIELD_NAMES = {
  team_positions: 'team_positions',
  account_positions: 'account_positions',
  position: 'position',
  position_id_in: 'position_id_in',
  team_member_skills: 'team_member_skills',
  board_memberships: 'board_memberships',
  board: 'board',
  team_skill: 'team_skill',
  region_entities: 'region_entities',
  region: 'region',
  office_entities: 'office_entities',
  office: 'office',
  office_id_in: 'office_id_in',
  discipline_entities: 'discipline_entities',
  discipline: 'discipline',
  discipline_id_in: 'discipline_id_in',
  discarded_at: 'discarded_at',
  archived_at: 'archived_at',
  end_date: 'end_date',
  id_in: 'id_in',
  account: 'account',
  account_id: 'account_id',
  account_id_in: 'account_id_in',
  name_like: 'name_like',
  name: 'name',
  title: 'title',
  title_like: 'title_like',
  work_group_memberships: 'work_group_memberships',
  work_group: 'work_group',
  work_group_id_in: 'work_group_id_in',
  team_membership: 'team_membership',
  project: 'project',
  project_id: 'project_id',
  project_number: 'project_number',
  project_memberships: 'project_memberships',
  description: 'description',
  id: 'id',
  is_default: 'is_default',
  employment_type_in: 'employment_type_in',
  employment_type: 'employment_type'
} as const;

export const ORDER_BY = {
  TEAM_MEMBER_ARCHIVED: 'TEAM_MEMBER_ARCHIVED',
  ACCOUNT_NAME: 'ACCOUNT_NAME',
  POSITION_NAME: 'POSITION_NAME',
  DEFAULT_POSITION: 'DEFAULT_POSITION',
  DEPARTMENT_NAME: 'DEPARTMENT_NAME',
  SKILL_NAME: 'SKILL_NAME',
  OFFICE_NAME: 'OFFICE_NAME',
  REGION_NAME: 'REGION_NAME',
  DISCIPLINE_NAME: 'DISCIPLINE_NAME',
  PORTFOLIO_NAME: 'PORTFOLIO_NAME',
  PROJECT_TITLE: 'PROJECT_TITLE',
  PROJECT_ARCHIVED: 'PROJECT_ARCHIVED'
} as const;
