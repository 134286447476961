import * as constants from 'appConstants';
import * as settingsConstants from '../constants';

export const initialState = {
  organizationSettings: {}
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    /** TODO: Setup reducer case for fetch and update org settings */
    case settingsConstants.FETCH_ORGANIZATION_SETTINGS.SUCCESS: {
      return state;
    }

    case settingsConstants.UPDATE_ORGANIZATION_SETTINGS.SUCCESS: {
      return state;
    }

    default:
      return state;
  }
};

export default settings;
