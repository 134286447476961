import { useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  makeGetActiveWorkloadPlannerFilter,
  getWorkloadViewBy
} from 'selectors';
import WorkloadPlannerContainer from 'views/projectPlanner/WorkloadPlannerContainer';
import { PageContainer } from 'views/PageContainer';
import { Page } from 'models/filter';
import { FILTER_PAGES, VIEW_BY } from 'appConstants/workload';
import { connect } from 'react-redux';
import { useQueryParams, NumberParam } from 'use-query-params';
import { SideFilter } from 'FilterModule/components/SideFilter';
import { initialFilterLevelSchemas } from './filterConfig';
import {
  pageViewFilterContextProviderProps,
  plannerPageViewFilterLevelName
} from './PlannerPageViewFilter/filterConfig';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { PageViewFilterPortal } from 'views/layoutStructuralComponents/PageViewFilter';
import { FilterContextProvider } from 'FilterModule/FilterContextProvider';
import { usePageViewFilter } from './PlannerPageViewFilter/usePageViewFilter';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getRatesModule } from 'RatesModule/package/ratesModule';

const pageName = Page.WorkloadPlanner;

/**
 * Planner - Work Plans tab or Workload depending on viewBy
 */
const ScheduleContainer = (props) => {
  const { crossFilterOnWorkloadFlag, viewFilterOnPlannerFlag } =
    useFeatureFlags();

  const { viewBy } = props;
  const [query] = useQueryParams({
    filter_id: NumberParam
  });
  const { filter_id: filterId } = query;

  const getActiveFilter = useMemo(makeGetActiveWorkloadPlannerFilter, []);

  const activeFilter = useAppSelector((state) =>
    getActiveFilter(state, { viewBy, pageName })
  );

  const pageContainerProps = useMemo(
    () =>
      !crossFilterOnWorkloadFlag
        ? null
        : {
            pageContextProviderProps: {
              currentView: viewBy,
              pageName
            },
            filterContextProviderProps: {
              filterSchemaParams: {
                initialFilterLevelSchemas,
                pageName,
                level2: viewBy
              },
              // making sure the same filter is used (other parts of this page are using older
              // activeFilter infra)
              matchingFiltersOverride: [activeFilter]
            }
          },
    [activeFilter, viewBy, crossFilterOnWorkloadFlag]
  );

  const pageViewFilter = usePageViewFilter(plannerPageViewFilterLevelName);

  const dynamicModules = useMemo(
    () => [getBudgetModule(), getRatesModule()],
    []
  );

  if (!crossFilterOnWorkloadFlag) {
    return (
      <WorkloadPlannerContainer
        viewBy={viewBy}
        pageName={FILTER_PAGES.WORKLOAD_PLANNER}
        plannerType="workload"
        filterId={filterId}
        pageViewFilter={pageViewFilter}
      />
    );
  }

  return (
    // Page/filter context
    <DynamicModuleLoader modules={dynamicModules}>
      <PageContainer {...pageContainerProps}>
        {viewBy === VIEW_BY.MEMBERS && (
          <SideFilter
            variant="WorkloadPlannerSideFilter"
            shouldUsePortal={false}
          />
        )}

        <WorkloadPlannerContainer
          viewBy={viewBy}
          pageName={FILTER_PAGES.WORKLOAD_PLANNER}
          plannerType="workload"
          filterId={filterId}
          activeFilter={activeFilter}
          leanApiProps={{ isoStateId: 'plannerPageViewFilter' }}
          pageViewFilter={pageViewFilter}
        />

        {viewBy !== VIEW_BY.MEMBERS && viewFilterOnPlannerFlag && (
          <FilterContextProvider {...pageViewFilterContextProviderProps}>
            <PageViewFilterPortal />
          </FilterContextProvider>
        )}
      </PageContainer>
    </DynamicModuleLoader>
  );
};

const mapStateToProps = (state) => ({
  viewBy: getWorkloadViewBy(state)
});
export default connect(mapStateToProps)(ScheduleContainer);
