'use strict';

exports.__esModule = true;
exports['default'] = TodayButton;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _util = require('../util/');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function TodayButton(_ref) {
  var prefixCls = _ref.prefixCls,
    locale = _ref.locale,
    value = _ref.value,
    timePicker = _ref.timePicker,
    disabled = _ref.disabled,
    disabledDate = _ref.disabledDate,
    onToday = _ref.onToday,
    text = _ref.text;

  var localeNow = (!text && timePicker ? locale.now : text) || locale.today;
  var disabledToday =
    disabledDate &&
    !(0, _util.isAllowedDate)((0, _util.getTodayTime)(value), disabledDate);
  var isDisabled = disabledToday || disabled;
  var disabledTodayClass = isDisabled ? prefixCls + '-today-btn-disabled' : '';
  return _react2['default'].createElement(
    'a',
    {
      className: prefixCls + '-today-btn ' + disabledTodayClass,
      role: 'button',
      onClick: isDisabled ? null : onToday,
      title: (0, _util.getTodayTimeStr)(value)
    },
    localeNow
  );
}
module.exports = exports['default'];
