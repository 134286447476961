import { useRef, useMemo } from 'react';
import Table from 'components/Table';
import Cells from './Cells';
import ActivityHeaderCell from './Cells/ActivityHeaderCell';
import ContentLoader from 'react-content-loader';
import { ActivityTableStyler } from './styles';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import sum from 'lodash/sum';

const EmptyDiv = () => <div />;

const MAX_HEIGHT_BUFFER = 200;

/** Main Activity Table */

const columnWidths = {
  date: 106,
  actor: 167,
  member: 167,
  project: 225,
  workCategory: 115,
  description: 164,
  rate: 59,
  hours: 53,
  billable: 35,
  threeDot: 35
};

const activityColumnNames = [
  'actor',
  'date',
  'member',
  'project',
  'workCategory',
  'description',
  'rate',
  'hours',
  'billable',
  'threeDot'
];

const dollarBasedColumns = ['rate', 'billable'];

const activityColumns = activityColumnNames.map((activityColumnName) => {
  return {
    headerType: activityColumnName,
    accessor: (row) => row.id,
    align: 'center',
    id: activityColumnName
  };
});

const ActivityTable = ({
  userActivities,
  isFetchingUserActivities,
  loadMoreItems,
  tableColumns = null,
  tableColumnsWidth = null,
  isSyncLogTable = false,
  customHeaderProps = {
    sortAttributes: {
      headerType: '',
      sortDirection: ''
    },
    onHeaderClick: null
  }
}) => {
  const { isHoursOnly } = useIsHoursOnly();

  const columnHeaderComponents = useMemo(() => {
    return {
      actor: ActivityHeaderCell,
      date: ActivityHeaderCell,
      member: ActivityHeaderCell,
      project: ActivityHeaderCell,
      workCategory: ActivityHeaderCell,
      description: ActivityHeaderCell,
      rate: ActivityHeaderCell,
      hours: ActivityHeaderCell,
      billable: ActivityHeaderCell,
      approve: ActivityHeaderCell,
      status: ActivityHeaderCell,
      email: ActivityHeaderCell,
      client: ActivityHeaderCell,
      projectNumber: ActivityHeaderCell,
      threeDot: EmptyDiv
    };
  }, []);

  const columns = useMemo(() => {
    const columnsToUse = tableColumns || activityColumns;
    const columnsToDisplay = isHoursOnly
      ? columnsToUse.filter((column) => !dollarBasedColumns.includes(column.id))
      : columnsToUse;
    const columnWidthsToUse = tableColumnsWidth || columnWidths;
    return columnsToDisplay.map((header) => ({
      ...header,
      Header: columnHeaderComponents[header.headerType],
      activityRow: Cells,
      width: columnWidthsToUse[header.headerType]
    }));
  }, [columnHeaderComponents, isHoursOnly, tableColumns, tableColumnsWidth]);

  const listRef = useRef(null);

  const customRowProps = useMemo(() => {
    return {
      isSyncLogTable,
      customHeaderProps
    };
  }, [customHeaderProps, isSyncLogTable]);

  const rows = useMemo(() => {
    const unsortedRows = userActivities
      ? userActivities.map((userActivity) => ({
          rowType: 'activityRow',
          userActivity: userActivity,
          customRowProps
        }))
      : [];
    return unsortedRows;
  }, [userActivities, customRowProps]);

  const isLoading = isFetchingUserActivities && rows.length === 0;

  return (
    <ActivityTableStyler isLoading={isLoading}>
      <Table
        columns={columns}
        data={!isLoading ? rows : []}
        virtual
        maxHeight={window.innerHeight - MAX_HEIGHT_BUFFER}
        itemHeight={100}
        getItemSize={() => 60}
        isVariableSizeTable
        listRef={listRef}
        isDragDisabled={true}
        columnMaxWidth={650}
        dragContainerClassName={'milestone-row-clone'}
        loadMoreItems={loadMoreItems}
        customRowProps={customRowProps}
        totalColumnsWidthOverride={
          // 20 is extra to prevent horizontal scrollabar
          sum(columns.map((column) => column.width || 0)) + 20
        }
      />

      {isLoading && (
        <div style={{ width: 'calc(100% - 40px)', marginTop: 30 }}>
          <ContentLoader height="100" primaryColor="#ddd" secondaryColor="#eee">
            <rect x="0" y="5" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="25" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="45" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="66" rx="2" ry="2" width="100%" height="15" />
          </ContentLoader>
        </div>
      )}
    </ActivityTableStyler>
  );
};

export default ActivityTable;
