import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import {
  ChartOuterContainer,
  ChartsInnerContainer,
  ChartsDetails,
  FractionNum
} from '../../styles';

import CapacitySummaryChartTooltip from './CapacitySummaryChartTooltip';

const ChartLabel = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 14px;
  font-weight: 600;
  position: relative;
  bottom: 20px;
`;

const fractionStyle = `

font-size: 27px;
position: relative;
bottom: 10px;

`;

const gradients = {
  holiday: 'url(#holidayGradient)',
  pto: 'url(#ptoGradient)'
};

const CapacitySummaryChart = ({
  items,
  percentPlanned,
  accountCapacitySum,
  planned,
  chartWidth = 145,
  chartHeight = 72,
  innerRadius = 56,
  outerRadius = 72,
  cy = 70,
  chartLabel = '',
  shouldShowPaddingAngle
}) => {
  return (
    <ChartOuterContainer>
      <ChartsInnerContainer>
        <ChartsDetails style={{ justifyContent: 'flex-end', marginTop: 11 }}>
          <ChartLabel>{chartLabel}</ChartLabel>
          <FractionNum fractionStyle={fractionStyle}>
            {percentPlanned}%
          </FractionNum>
        </ChartsDetails>
        <PieChart width={chartWidth} height={chartHeight}>
          <Pie
            data={items}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={180}
            endAngle={0}
            fill="#8884d8"
            paddingAngle={shouldShowPaddingAngle ? 1 : 0}
            dataKey={'value'}
            cy={cy}
          >
            {items.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={gradients[entry.name] || entry.color}
                stroke={'#e8e8e8'}
              />
            ))}
          </Pie>
          <Tooltip
            content={
              <CapacitySummaryChartTooltip
                planned={planned}
                accountCapacitySum={accountCapacitySum}
              />
            }
            allowEscapeViewBox={{ x: true, y: true }}
            wrapperStyle={{ zIndex: 5 }}
          />
        </PieChart>
      </ChartsInnerContainer>
    </ChartOuterContainer>
  );
};

export default CapacitySummaryChart;
