import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TaskListColumnsHeader from './TaskListColumnsHeader';
import BatchSelectAll from './BatchSelectAll';
import TaskColumnsDropdown from './TaskColumnsDropdown';
import {
  makeGetSomeIsBatchSelected,
  isOnTeamMemberProfile,
  getTaskColumnOrderWithSelection,
  getPastDueTasks,
  getPastScheduledTasks,
  getIsCreatingTask
} from 'selectors';
import {
  ListTitleWrapper,
  TaskListHeaderStyleWrapper,
  TaskListHeaderWrapper
} from './styles';
import { withRouter } from 'react-router-dom';
import TaskListFlags from './TaskListFlags';
import styled from 'styled-components';

const StyledFlagsContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 20px;
`;

class TaskListHeader extends PureComponent {
  state = {
    isCreatingTask: false
  };

  toggleIsCreatingTask = () => {
    this.setState((prevState) => ({
      isCreatingTask: !prevState.isCreatingTask
    }));
  };

  render() {
    const {
      taskFilter,
      isOnTeamProject,
      isOnHomeView,
      isSortable,
      scheduleStart,
      isTaskListColumnsHeaderVisible,
      someBatchSelected,
      taskIds,
      activeTaskColumns,
      isOnTeamMemberProfile,
      isOnInboxView,
      isOnTodayView,
      isOnCompletedView,
      isOnPersonalProject,
      pastDueTasks,
      pastScheduledTasks,
      isCreatingTask,
      marginTop,
      isDayPlannerView
    } = this.props;

    const shouldRenderCreateTaskButton =
      ['accepted', 'profile', 'project'].includes(taskFilter.scope) &&
      taskFilter.state === 'incomplete';
    const isOnAssignedByMeView = taskFilter.section === 'Assigned By Me';
    return (
      <TaskListHeaderWrapper
        isOnHomeView={isOnHomeView}
        isOnCompletedView={isOnCompletedView}
        isOnTeamProject={isOnTeamProject}
        isHomeCompleted={
          taskFilter.section === 'My Tasks' &&
          taskFilter.subSection === 'completed'
        }
        activeTaskColumns={activeTaskColumns}
        isOnTeamMemberProfile={isOnTeamMemberProfile}
        isOnInboxView={isOnInboxView}
        isOnTodayView={isOnTodayView}
        isOnPersonalProject={isOnPersonalProject}
        isOnAssignedByMeView={isOnAssignedByMeView}
        marginTop={marginTop}
        isDayPlannerView={isDayPlannerView}
      >
        <TaskListHeaderStyleWrapper
          isOnTeamProject={isOnTeamProject}
          isOnAssignedByMeView={isOnAssignedByMeView}
        >
          <ListTitleWrapper>
            {isTaskListColumnsHeaderVisible && (
              <TaskListColumnsHeader
                isHeader
                paddingLeft={0}
                shouldRenderCreateTaskButton={shouldRenderCreateTaskButton}
                isSortable={isSortable}
                isOnHomeView={isOnHomeView}
                scheduleStart={scheduleStart}
                shouldRenderGhostText={true}
                index={0}
                position={0}
                isHomeCompleted={
                  taskFilter.section === 'My Tasks' &&
                  taskFilter.subSection === 'completed'
                }
                isOnAssignedByMeView={isOnAssignedByMeView}
                toggleIsCreatingTask={this.toggleIsCreatingTask}
                setSortHeaderHovered={this.setSortHeaderHovered}
                unsetSortHeaderHovered={this.unsetSortHeaderHovered}
                noBorder
                isDayPlannerView={isDayPlannerView}
              />
            )}
          </ListTitleWrapper>
          {isOnInboxView && !isCreatingTask ? (
            <StyledFlagsContainer>
              <TaskListFlags
                pastDueTasks={pastDueTasks}
                pastScheduledTasks={pastScheduledTasks}
              />
            </StyledFlagsContainer>
          ) : null}
        </TaskListHeaderStyleWrapper>
        {!someBatchSelected ? (
          <>
            {!isOnTeamMemberProfile && isOnTeamProject && (
              <TaskColumnsDropdown show={!isCreatingTask} />
            )}
            <BatchSelectAll
              isOnHomeView={isOnHomeView}
              isOnCompletedView={isOnCompletedView}
              taskIds={taskIds}
            />
          </>
        ) : (
          <>
            <BatchSelectAll
              isOnHomeView={isOnHomeView}
              isOnCompletedView={isOnCompletedView}
              taskIds={taskIds}
            />
            {!isOnTeamMemberProfile && isOnTeamProject && (
              <TaskColumnsDropdown hideContents />
            )}
          </>
        )}
      </TaskListHeaderWrapper>
    );
  }
}

const makeMapStateToProps = () => {
  const getSomeIsBatchSelected = makeGetSomeIsBatchSelected();
  const mapStateToProps = (state, props) => ({
    someBatchSelected: getSomeIsBatchSelected(state, props),
    activeTaskColumns: getTaskColumnOrderWithSelection(state),
    isOnTeamMemberProfile: isOnTeamMemberProfile(state, props),
    pastDueTasks: getPastDueTasks(state),
    pastScheduledTasks: getPastScheduledTasks(state),
    isCreatingTask: getIsCreatingTask(state)
  });
  return mapStateToProps;
};

export default withRouter(connect(makeMapStateToProps)(TaskListHeader));
