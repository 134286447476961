import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import {
  FetchCapacityPoliciesParams,
  FetchCapacityPoliciesSuccessResponse,
  CreateCapacityPolicyParams,
  CreateCapacityPolicySuccessResponse,
  UpdateCapacityPolicyParams,
  UpdateCapacityPolicySuccessResponse,
  ArchiveCapacityPolicyParams,
  ArchiveCapacityPolicySuccessResponse,
  FetchCapacityPoliciesPerMemberParams,
  FetchCapacityPoliciesPerMemberSuccessResponse,
  FetchCapacityGroupMembershipsParams,
  FetchCapacityGroupMembershipsSuccessResponse,
  CreateCapacityGroupMembershipParams,
  CreateCapacityGroupMembershipSuccessResponse,
  UpdateCapacityGroupMembershipParams,
  UpdateCapacityGroupMembershipSuccessResponse,
  DeleteCapacityGroupMembershipParams
} from 'CapacityModule/api/capacityGroup/types';
import { ValueOf } from 'type-fest';
import { ACTION_META_KEY } from 'appConstants/actions';

/* ------------------------- fetch capacity policies ------------------------ */

const FETCH_CAPACITY_POLICIES = createRequestTypes('FETCH_CAPACITY_POLICIES');

export const fetchCapacityPolicies = createAction<FetchCapacityPoliciesParams>(
  FETCH_CAPACITY_POLICIES.TRIGGER
);

export const fetchCapacityPoliciesActionCreatorsMap = createActionCreatorsMap<
  FetchCapacityPoliciesParams,
  FetchCapacityPoliciesSuccessResponse
>(FETCH_CAPACITY_POLICIES);

/* ------------------------- create capacity policy ------------------------- */

type CreateCapacityPolicyInitialPayload =
  ActionParamsWithMeta<CreateCapacityPolicyParams>;

const CREATE_CAPACITY_POLICY = createRequestTypes('CREATE_CAPACITY_POLICY');

export const createCapacityPolicy = createAction(
  CREATE_CAPACITY_POLICY.TRIGGER,
  ({ meta, ...payload }: CreateCapacityPolicyInitialPayload) => ({
    meta,
    payload
  })
);

export const createCapacityPolicyActionCreatorsMap = createActionCreatorsMap<
  CreateCapacityPolicyParams,
  CreateCapacityPolicySuccessResponse
>(CREATE_CAPACITY_POLICY);

/* ------------------------- update capacity policy ------------------------- */

type UpdateCapacityPolicyInitialPayload =
  ActionParamsWithMeta<UpdateCapacityPolicyParams>;

const UPDATE_CAPACITY_POLICY = createRequestTypes('UPDATE_CAPACITY_POLICY');

export const updateCapacityPolicy = createAction(
  UPDATE_CAPACITY_POLICY.TRIGGER,
  ({ meta, ...payload }: UpdateCapacityPolicyInitialPayload) => ({
    meta,
    payload
  })
);

export const updateCapacityPolicyActionCreatorsMap = createActionCreatorsMap<
  UpdateCapacityPolicyParams,
  UpdateCapacityPolicySuccessResponse
>(UPDATE_CAPACITY_POLICY);

/* ------------------------- archive capacity policy ------------------------ */

type ArchiveCapacityPolicyInitialPayload =
  ActionParamsWithMeta<ArchiveCapacityPolicyParams>;

const ARCHIVE_CAPACITY_POLICY = createRequestTypes('ARCHIVE_CAPACITY_POLICY');

export const archiveCapacityPolicy = createAction(
  ARCHIVE_CAPACITY_POLICY.TRIGGER,
  ({ meta, ...payload }: ArchiveCapacityPolicyInitialPayload) => ({
    meta,
    payload
  })
);

export const archiveCapacityPolicyActionCreatorsMap = createActionCreatorsMap<
  ArchiveCapacityPolicyParams,
  ArchiveCapacityPolicySuccessResponse
>(ARCHIVE_CAPACITY_POLICY);

/* ------------------- fetch capacity policies per member ------------------- */

const FETCH_CAPACITY_POLICIES_PER_MEMBER = createRequestTypes(
  'FETCH_CAPACITY_POLICIES_PER_MEMBER'
);

export const fetchCapacityPoliciesPerMember =
  createAction<FetchCapacityPoliciesPerMemberParams>(
    FETCH_CAPACITY_POLICIES_PER_MEMBER.TRIGGER
  );

export const fetchCapacityPoliciesPerMemberActionCreatorsMap =
  createActionCreatorsMap<
    FetchCapacityPoliciesPerMemberParams,
    FetchCapacityPoliciesPerMemberSuccessResponse
  >(FETCH_CAPACITY_POLICIES_PER_MEMBER);

/* ----------------------------------- fetch capacity group memberships ---------------------------------- */

const FETCH_CAPACITY_GROUP_MEMBERSHIPS = createRequestTypes(
  'FETCH_CAPACITY_GROUP_MEMBERSHIPS'
);

type FetchCapacityGroupMembershipsInitialPayload =
  ActionParamsWithMeta<FetchCapacityGroupMembershipsParams>;

export const fetchCapacityGroupMemberships = createAction(
  FETCH_CAPACITY_GROUP_MEMBERSHIPS.TRIGGER,
  ({ meta, ...payload }: FetchCapacityGroupMembershipsInitialPayload) => ({
    meta,
    payload
  })
);

export const fetchCapacityGroupMembershipsActionCreatorsMap =
  createActionCreatorsMap<
    FetchCapacityGroupMembershipsInitialPayload,
    FetchCapacityGroupMembershipsSuccessResponse,
    ValueOf<
      Pick<
        ReturnType<typeof fetchCapacityGroupMemberships>,
        typeof ACTION_META_KEY
      >
    >
  >(FETCH_CAPACITY_GROUP_MEMBERSHIPS);

/* -------------------- create capacity group membership -------------------- */

const CREATE_CAPACITY_GROUP_MEMBERSHIP = createRequestTypes(
  'CREATE_CAPACITY_GROUP_MEMBERSHIP'
);

export const createCapacityGroupMembership =
  createAction<CreateCapacityGroupMembershipParams>(
    CREATE_CAPACITY_GROUP_MEMBERSHIP.TRIGGER
  );

export const createCapacityGroupMembershipActionCreatorsMap =
  createActionCreatorsMap<
    CreateCapacityGroupMembershipParams,
    CreateCapacityGroupMembershipSuccessResponse
  >(CREATE_CAPACITY_GROUP_MEMBERSHIP);

/* ----------------------------------- update capacity group membership ---------------------------------- */

const UPDATE_CAPACITY_GROUP_MEMBERSHIP = createRequestTypes(
  'UPDATE_CAPACITY_GROUP_MEMBERSHIP'
);

export type UpdateCapacityGroupMembershipInitialPayload =
  UpdateCapacityGroupMembershipParams & { meta: { teamMembershipId: number } };

export const updateCapacityGroupMembership = createAction(
  UPDATE_CAPACITY_GROUP_MEMBERSHIP.TRIGGER,
  ({ meta, ...payload }: UpdateCapacityGroupMembershipInitialPayload) => ({
    payload,
    meta
  })
);

export const updateCapacityGroupMembershipActionCreatorsMap =
  createActionCreatorsMap<
    UpdateCapacityGroupMembershipInitialPayload,
    UpdateCapacityGroupMembershipSuccessResponse,
    ValueOf<
      Pick<
        ReturnType<typeof updateCapacityGroupMembership>,
        typeof ACTION_META_KEY
      >
    >
  >(UPDATE_CAPACITY_GROUP_MEMBERSHIP);

/* ----------------------------------- delete capacity group membership ---------------------------------- */

const DELETE_CAPACITY_GROUP_MEMBERSHIP = createRequestTypes(
  'DELETE_CAPACITY_GROUP_MEMBERSHIP'
);

export type DeleteCapacityGroupMembershipInitialPayload =
  DeleteCapacityGroupMembershipParams & { meta: { teamMembershipId: number } };

export const deleteCapacityGroupMembership = createAction(
  DELETE_CAPACITY_GROUP_MEMBERSHIP.TRIGGER,
  ({ meta, ...payload }: DeleteCapacityGroupMembershipInitialPayload) => ({
    payload,
    meta
  })
);

export const deleteCapacityGroupMembershipActionCreatorsMap =
  createActionCreatorsMap<
    DeleteCapacityGroupMembershipInitialPayload,
    undefined,
    ValueOf<
      Pick<
        ReturnType<typeof deleteCapacityGroupMembership>,
        typeof ACTION_META_KEY
      >
    >
  >(DELETE_CAPACITY_GROUP_MEMBERSHIP);
