import { useSelector } from 'react-redux';
import {
  getDisciplinesArray,
  getDisciplines
} from 'SettingsModule/selectors/disciplines';
import {
  getOfficesArray,
  getOfficesHash
} from 'SettingsModule/selectors/offices';
import { getRegionsArray, getRegions } from 'SettingsModule/selectors/regions';

const useSettingsCategory = () => {
  /** Discipline */
  const disciplines = useSelector(getDisciplinesArray);
  const disciplineHash = useSelector(getDisciplines);

  /** Region */

  const regions = useSelector(getRegionsArray);
  const regionHash = useSelector(getRegions);

  /** Offices */

  const offices = useSelector(getOfficesArray);
  const officeHash = useSelector(getOfficesHash);

  return {
    offices,
    regions,
    disciplines,
    officeHash,
    regionHash,
    disciplineHash
  };
};

export default useSettingsCategory;
