import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTeamPositions } from 'BudgetModule/actionCreators';
import {
  getPositions,
  getAccountToTeamPosition
} from 'BudgetModule/selectors/positions';
import { getSelectedTeamId } from 'selectors';
import theme from 'theme';
import styled from 'styled-components';
import QBDownArrow from 'icons/QBDownArrow';
import MemberRolesHistoryDropdown from './MemberRoleHistoryDropdown';

const Arrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  margin-left: 5px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
  visibility: hidden;
`;

export const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  color: ${(props) =>
    props.hasRole
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorCalendarBlue};
  font-size: 13px;
  width: 100%;
  height: 100%;
  &:hover {
    color: ${theme.colors.colorCalendarBlue};
    ${Arrow} {
      visibility: visible;
    }
  }
`;

/**
 * Toggle for opening member's team or project role history
 * currently for TeamPosition only
 */
const MemberRoleSelection = ({ accountId, customRenderToggle, isTeamRole }) => {
  const dispatch = useDispatch();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const teamId = useSelector(getSelectedTeamId);
  const currentTeamRoles = useSelector(getAccountToTeamPosition);
  const roleHash = useSelector(getPositions);
  const toggleRef = useRef(null);

  const handleClick = () => {
    if (teamId && accountId) {
      if (isTeamRole) {
        // fetch member's team role history
        dispatch(
          fetchTeamPositions({
            teamId,
            accountId,
            isActive: false
          })
        );
      }
      setShowHistoryModal(true);
    }
  };

  const currentRoleMembership = isTeamRole ? currentTeamRoles[accountId] : null;
  const currentRole = roleHash[currentRoleMembership?.position_id];
  const hasRole = currentRoleMembership && !currentRole?.is_default;

  return (
    <>
      {customRenderToggle ? (
        customRenderToggle({ toggleRef })
      ) : (
        <StyledToggle
          ref={toggleRef}
          onClick={handleClick}
          className="member-team-roles-dropdown-toggle"
          hasRole={hasRole}
        >
          <span className="no-text-overflow">
            {hasRole ? currentRole?.name : 'Set Role'}
          </span>
          <Arrow />
        </StyledToggle>
      )}

      {/* Member's history of roles */}
      {showHistoryModal && (
        <MemberRolesHistoryDropdown
          onClose={() => setShowHistoryModal(false)}
          targetRef={toggleRef}
          accountId={accountId}
          isTeamRole
        />
      )}
    </>
  );
};

export default MemberRoleSelection;
