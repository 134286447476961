import styled from 'styled-components';
import { ChangeEventHandler, useState } from 'react';
import theme from 'theme';
import { BlueSubmitButton } from 'components/styles';
import { getTeamSamlInfo } from 'AuthenticationModule/selectors';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { associateSamlIdentity } from 'AuthenticationModule/actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { preferredAuthMethods } from 'AuthenticationModule/constants';
import { RequestStatusFields } from 'reducers/statuses';

interface SamlAssociationSectionProps {
  associateSamlIdentityRequestStatus: RequestStatusFields<unknown> | undefined;
}

export const SamlAssociationSection = ({
  associateSamlIdentityRequestStatus
}: SamlAssociationSectionProps) => {
  const [ssoUrl, setSsoUrl] = useState('');
  const [certificate, setCertificate] = useState('');
  const [domain, setDomain] = useState('');

  const dispatch = useAppDispatch();

  const teamSamlInfo = useAppSelector(getTeamSamlInfo);
  const teamId = useAppSelector(getSelectedTeamId);

  const handleAssociateSamlIdentity = () => {
    if (teamId) {
      dispatch(
        associateSamlIdentity({
          certificate,
          teamId,
          domain,
          entryPoint: ssoUrl,
          samlIdp: preferredAuthMethods.saml,
          meta: {
            requestStatusId: associateSamlIdentity.type
          }
        })
      );
    }
  };

  const buildInput = ({
    header,
    placeholder,
    value,
    onChange,
    isDisabled
  }: {
    header: string;
    placeholder?: string;
    value: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    isDisabled?: boolean;
  }) => {
    return (
      <InputFieldContainer>
        <InfoHeader>{header}</InfoHeader>
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          readOnly={isDisabled}
          disabled={isDisabled}
          onChange={onChange}
        />
      </InputFieldContainer>
    );
  };
  return (
    <>
      For a Team using Google Workspace to use Google’s SAML authentication with
      Mosaic, they must go through the initial setup of associating Mosaic as
      part of their Google Workspace’s custom SAML application. On Google
      Workspace admin’s perspective, they could do so by following{' '}
      <a
        target="_blank"
        href="https://support.google.com/a/answer/6087519?product_name=UnuFlow&hl=en&visit_id=637733141688224831-426905289&rd=1&src=supportwidget0&hl=en#mapping"
        rel="noreferrer"
      >
        Google’s official documentation.
      </a>
      <div>
        {buildInput({
          header: 'ACS URL',
          value: teamSamlInfo?.callback_url ?? '',
          isDisabled: true
        })}
      </div>
      <div>
        {buildInput({
          header: 'Entity ID',
          value: teamSamlInfo?.entity_id ?? '',
          isDisabled: true
        })}
      </div>
      <div>
        {buildInput({
          header: 'Domain',
          value: domain,
          placeholder: 'Enter domain associated with the team',
          onChange: (e) => setDomain(e.target.value)
        })}
        {buildInput({
          header: 'SSO URL',
          value: ssoUrl,
          placeholder: 'Enter SSO URL',
          onChange: (e) => setSsoUrl(e.target.value)
        })}
        {buildInput({
          header: 'Certificate',
          value: certificate,
          placeholder: 'Enter Certificate',
          onChange: (e) => setCertificate(e.target.value)
        })}
      </div>
      <div>
        <BlueSubmitButton
          disabled={associateSamlIdentityRequestStatus?.isExecuting}
          onClick={handleAssociateSamlIdentity}
        >
          Save
        </BlueSubmitButton>
      </div>
    </>
  );
};

const InputFieldContainer = styled.div`
  padding-top: 10px;
  display: flex;
  input {
    height: 38px;
    border-radius: 3px;
    width: 800px;
    border-width: 0px;
    padding: 8px;
  }
`;

const InfoHeader = styled.span`
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  width: 150px;
  color: ${theme.colors.colorMediumGray9};
`;
