import React, { useState, useRef, useEffect, useMemo } from 'react';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMe,
  getTimerDate,
  getIsTimerDrawerOpen,
  getIsTimerDeleteModalOpen,
  getAllTimers,
  makeSuggestedActivityHash,
  makeSuggestedPhaseHash,
  makeSuggestedProjectHash,
  getTimerToDelete
} from 'selectors';
import {
  closeTimerDrawer,
  navigateToTimeSheet,
  fetchTimers,
  fetchTasksV2,
  createCheckin,
  fetchCheckins,
  fetchCheckinsHours,
  closeTimerDeleteModal,
  deleteTimer
} from 'actionCreators';
import styled from 'styled-components';
import TimerDateNav from './TimerDateNav';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenuTrigger';
import GearIcon from 'icons/SettingsIcon';
import TimerSettingsModal from './TimerSettingsModal';
import Popover from 'components/Popover';
import Checkins, {
  DailyCheckinContainer
} from 'views/Home/Widgets/Widgets/DailyCheckinWidget';
import AddPlusIcon from 'icons/AddPlusIcon';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';
import moment from 'moment';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';

const TimerSidebarContainer = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const threeDotTarget = useRef(null);
  const targetRef = useRef(null);
  const me = useSelector(getMe);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const isTimerDrawerOpen = useSelector(getIsTimerDrawerOpen);
  const [counter, setCounter] = useState(0);
  const timers = useSelector(getAllTimers);
  const suggestedProjectHash = useSelector(makeSuggestedProjectHash);
  const suggestedPhaseHash = useSelector(makeSuggestedPhaseHash);
  const suggestedActivityHash = useSelector(makeSuggestedActivityHash);
  const isTimerDeleteModalOpen = useSelector(getIsTimerDeleteModalOpen);
  const timerToDelete = useSelector(getTimerToDelete);
  const [isProjectSelectorOpen, setIsProjectSelectorOpen] = useState(false);
  const unformattedTimerDate = useSelector(getTimerDate);

  const timerDate = useMemo(
    () => moment(unformattedTimerDate),
    [unformattedTimerDate]
  );

  const restartCounter = () => {
    setCounter(0);
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setCounter((prevCount) => prevCount + 1);
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  useEffect(() => {
    if (me && me.id && timerDate && isTimerDrawerOpen) {
      dispatch(
        fetchTimers({
          body: {
            account_id: me.id,
            date: timerDate.clone().format('YYYY-MM-DD')
          }
        })
      );
      restartCounter();
    }
  }, [dispatch, me, timerDate, isTimerDrawerOpen]);

  useEffect(() => {
    if (me?.default_team?.id) {
      dispatch(
        fetchCheckins({
          body: {
            date: timerDate.format('YYYY-MM-DD'),
            account_ids: [me?.id]
          },
          filterStateId: timerDate.format('YYYY-MM-DD')
        })
      );
      const body = {
        account_ids: [me.id],
        offset: 0,
        limit: 50,
        team_id: me?.default_team?.id,
        start_date: timerDate.format('MM/DD/YYYY'),
        end_date: timerDate.format('MM/DD/YYYY')
      };
      dispatch(
        fetchCheckinsHours({
          body: body,
          filterStateId: timerDate.format('YYYY-MM-DD'),
          initial: true
        })
      );
    }
  }, [dispatch, me, timerDate]);

  useEffect(() => {
    const taskIds = timers
      .filter((timer) => !!timer.task_id)
      .map((timer) => timer.task_id);
    if (taskIds.length > 0) {
      dispatch(
        fetchTasksV2({
          body: {
            task_ids: taskIds
          },
          taskListType: 'timer'
        })
      );
    }
  }, [timers, dispatch]);

  const handleTimerDrawerClose = () => dispatch(closeTimerDrawer());

  const handleMenuOpen = () => setIsMenuOpen(true);

  const handleMenuClose = () => setIsMenuOpen(false);

  const navToTimesheet = () => {
    dispatch(navigateToTimeSheet({ openInNewWindow: true }));
  };

  const openSettingsModal = () => {
    setIsSettingsOpen(true);
    handleMenuClose();
  };

  const closeSettingsModal = () => {
    setIsSettingsOpen(false);
  };

  const handleOpenProjectSelector = () => {
    setIsProjectSelectorOpen(true);
  };

  const handleCloseProjectSelector = () => {
    setIsProjectSelectorOpen(false);
  };

  const handleSelect = ({ item }) => {
    let newCheckin;
    if (item.is_activity) {
      newCheckin = {
        activity_id: item.id,
        phase_id: item.phase_id,
        project_id: item.project.id
      };
    } else if (item.is_phase) {
      newCheckin = {
        activity_id: null,
        phase_id: item.id,
        project_id: item.project.id
      };
    } else {
      newCheckin = {
        activity_id: null,
        phase_id: item.phases.length > 0 ? item.phases[0] : null,
        project_id: item.id
      };
    }
    handleCreateCheckin(newCheckin);
    handleCloseProjectSelector();
  };

  const handleCreateCheckin = (checkinParams) => {
    dispatch(
      createCheckin({
        title: '',
        estimated_hours: 0,
        date: timerDate.format('YYYY-MM-DD'),
        account_id: me?.id,
        ...checkinParams,
        filterStateId: timerDate.format('YYYY-MM-DD')
      })
    );
  };

  const handleCloseDeleteModal = () => {
    dispatch(closeTimerDeleteModal());
  };

  const renderDeleteBody = () => {
    return (
      <>
        Are you sure you want to <b>permanently</b> delete this Timer? You can’t
        restore a deleted Timer.
      </>
    );
  };

  const handleDelete = () => {
    handleCloseDeleteModal();
    dispatch(
      deleteTimer({
        timer_ids: timerToDelete
      })
    );
    restartCounter();
  };

  const renderDeleteConfirm = () => {
    return <button onClick={handleDelete}>Ok</button>;
  };

  return (
    <StyledTimerSideBarContainer>
      <Header>
        <TitleContainer>
          <Title>
            Time Tracking
            {/* <ThreeDotContainer onClick={handleMenuOpen} ref={threeDotTarget}>
              <ProjectThreeDotMenu
                align="left"
                onCloseCallback={handleMenuClose}
                handleClick={handleMenuOpen}
              >
                <Popover
                  isOpen={isMenuOpen}
                  target={threeDotTarget}
                  closePopover={handleMenuClose}
                  className="styled-member-popover"
                >
                  <MenuListItem onClick={openSettingsModal}>
                    <GearIconContainer>
                      <GearIcon />
                    </GearIconContainer>
                    Settings
                  </MenuListItem>
                </Popover>
              </ProjectThreeDotMenu>
            </ThreeDotContainer> */}
          </Title>
          <SubTitle>Showing Check-ins</SubTitle>
        </TitleContainer>
        <DoneButton onClick={handleTimerDrawerClose}>Done</DoneButton>
      </Header>
      <TimerDateNav />
      <ProjectSelectorRow>
        <ProjectSelectorContainer>
          <DropdownTarget ref={targetRef} />
          <ProjectsThenPhasesSelector
            target={targetRef}
            onClose={handleCloseProjectSelector}
            handleSelect={handleSelect}
            renderSelector={false}
            accountId={me.id}
            suggestedProjectHash={suggestedProjectHash}
            suggestedPhaseHash={suggestedPhaseHash}
            suggestedActivityHash={suggestedActivityHash}
            isOpen={isProjectSelectorOpen}
            hidePTO
            popoverClassName={'timer-project-dropdown'}
          />
          <InputTriggerContainer onClick={handleOpenProjectSelector}>
            <StyledInputTrigger>+ Project</StyledInputTrigger>
          </InputTriggerContainer>
        </ProjectSelectorContainer>
        <TimesheetButton onClick={navToTimesheet}>
          <GoToProjectIconContainer>
            <GoToProjectIcon
              height="24px"
              width="24px"
              color={theme.colors.colorCalendarBlue}
            />
          </GoToProjectIconContainer>
          Go To Timesheet
        </TimesheetButton>
      </ProjectSelectorRow>

      <TimerContainer>
        <Checkins
          isTimerSidebar
          containerWidth={364}
          hideFooter
          hideHeader
          restartCounter={restartCounter}
          counter={counter}
        />
      </TimerContainer>
      <TimerSettingsModal
        isOpen={isSettingsOpen}
        handleClose={closeSettingsModal}
      />
      <DeleteModal
        isOpen={isTimerDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        deleteOnClick={handleDelete}
        renderBody={renderDeleteBody}
        component={'timer'}
        renderDelete={renderDeleteConfirm}
      />
    </StyledTimerSideBarContainer>
  );
};

/* -------------------Styling--------------- */

const StyledTimerSideBarContainer = styled.div`
  height: 100%;
  width: 373px;
  z-index: 10000;
  margin-left: -22px;
  overflow: hidden;
`;

const Header = styled.div`
  height: 50px;
  width: 300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-weight: 700;
  font-size: 22px;
  display: flex;
  align-items: center;
`;

const SubTitle = styled.div`
  color: #a4a4a4;
  font-weight: 400;
  font-size: 12px;
  margin-left: 2px;
  margin-top: -5px;
`;

const DoneButton = styled.div`
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  cursor: pointer;
  border-radius: 5px;
  height: 32px;
  width: 70px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimesheetButton = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
`;

const ThreeDotContainer = styled.div`
  width: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 7px;
  cursor: pointer;
  margin-left: 10px;
`;
const MenuListItem = styled.div`
  font-size: 12px;
  min-width: 190px;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 7px;
  line-height: 34px;
  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    cursor: pointer;
  }
  font-weight: 400;
`;

const GearIconContainer = styled.div`
  margin: 0px 5px;
`;

const TimerContainer = styled.div`
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  height: 90%;
  overflow: auto;
  ${DailyCheckinContainer} {
    padding-bottom: 120px;
  }
`;

const GoToProjectIconContainer = styled.div``;

const StyledInputTrigger = styled.div`
  height: 24px;
  color: ${theme.colors.colorCalendarBlue};
  font-weight: 400;
  font-size: 13px;
  margin-right: 80px;
  margin-left: 3px;
`;
const InputTriggerContainer = styled.div`
  display: flex;
  margin-top: 5px;
  height: 50px;
  align-items: center;
  margin-bottom: -15px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    ${StyledInputTrigger} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const ProjectSelectorContainer = styled.div``;

const DropdownTarget = styled.div`
  height: 1px;
  margin-top: -16px;
  margin-left: -25px;
`;

const ProjectSelectorRow = styled.div`
  display: flex;
  width: 298px;
  align-items: center;
  justify-content: space-between;
  margin-left: 34px;
`;
export default TimerSidebarContainer;
