import React from 'react';
import { connect } from 'react-redux';
import {
  getUnscheduledSidebarTasks,
  getUnscheduledTasks,
  getTaskSidebarProject,
  getWorkloadSplitScreenAccountId
} from 'selectors';
import DoubleArrow from 'icons/DoubleArrow';
import TaskCard from 'components/TaskCard';
import { FixedSizeList, areEqual } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { fetchTasksV2 } from 'actionCreators';
import { Header, Body, Close } from './styles';
import { VIEW_BY } from 'appConstants/workload';
const NON_LIST_HEIGHT = 62;
const ITEM_HEIGHT = 130;

const getItemId = (item) => item.id;
// eslint-disable-next-line react/display-name
const Row = React.memo(({ data: props, index, style }) => {
  // const { tasks } = props;
  const task = props[index];
  if (!task) {
    return null;
  }

  return (
    <div key={task.id} style={style}>
      <TaskCard task={task} priorityId={task?.task_priority_id} />
    </div>
  );
}, areEqual);

/**
 * @deprecated Will be removed once the content from `taskDrawerUpdateFlag` is
 * finalized.
 */
class TaskSidebar extends React.Component {
  state = { shouldLoadMore: false };
  componentDidMount() {
    const { splitScreenAccountId, fetchTasksV2, workloadViewBy } = this.props;
    if (!!splitScreenAccountId && workloadViewBy === VIEW_BY.MEMBERS) {
      fetchTasksV2({
        body: {
          assignee_ids: [splitScreenAccountId],
          scheduled_start: false,
          complete: false,
          limit: 50,
          offset: 0,
          sort: 'created_at'
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isLazyLoading, project, fetchTasksV2, splitScreenAccountId } =
      this.props;
    rebuildTooltip();
    if (
      prevProps.isLazyLoading &&
      !isLazyLoading &&
      this.state.shouldLoadMore
    ) {
      this.setState({ shouldLoadMore: false });
      this.loadMoreItems();
    }
    if (project && project !== prevProps.project) {
      fetchTasksV2({
        body: {
          project_ids: [project.id],
          scheduled_start: false,
          complete: false,
          limit: 50,
          offset: 0,
          sort: 'created_at'
        }
      });
    }
    if (
      splitScreenAccountId &&
      splitScreenAccountId !== prevProps.splitScreenAccountId
    ) {
      fetchTasksV2({
        body: {
          assignee_ids: [splitScreenAccountId],
          scheduled_start: false,
          complete: false,
          limit: 50,
          offset: 0,
          sort: 'created_at'
        }
      });
    }
  }

  loadMoreItems = () => {
    const { fetchTasksV2, isLazyLoading, tasks, project } = this.props;

    if (!project) {
      return;
    }
    if (isLazyLoading) {
      this.setState({ shouldLoadMore: true });
      return;
    }
    fetchTasksV2({
      body: {
        project_ids: [project?.id],
        scheduled_start: false,
        complete: false,
        limit: 50,
        offset: tasks.length,
        sort: 'created_at'
      }
    });
  };

  isItemLoaded = (index) => {
    const { tasks } = this.props;
    return index < tasks.length;
  };

  render() {
    const { toggleIsOpen, tasks, allUnscheduledTasks, showAll } = this.props;
    const maxListHeight = window.innerHeight - NON_LIST_HEIGHT;
    const itemBasedListHeight = showAll
      ? allUnscheduledTasks.length * ITEM_HEIGHT
      : tasks.length * ITEM_HEIGHT;
    return (
      <>
        <Header>
          <Close onClick={toggleIsOpen}>
            <DoubleArrow />
          </Close>
          Unplanned Tasks
        </Header>
        <Body className="home-planner-container">
          <InfiniteLoader
            isItemLoaded={this.isItemLoaded}
            itemCount={2000}
            loadMoreItems={this.loadMoreItems}
            threshold={20}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                onItemsRendered={onItemsRendered}
                height={Math.min(maxListHeight, itemBasedListHeight)}
                itemSize={ITEM_HEIGHT}
                itemCount={showAll ? allUnscheduledTasks.length : tasks.length}
                itemData={showAll ? allUnscheduledTasks : tasks}
                itemKey={getItemId}
                overscanCount={10}
                ref={ref}
              >
                {Row}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        </Body>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  tasks: getUnscheduledSidebarTasks(state),
  allUnscheduledTasks: getUnscheduledTasks(state),
  isLazyLoading: state.homeTasks.isLazyLoading,
  project: getTaskSidebarProject(state),
  splitScreenAccountId: getWorkloadSplitScreenAccountId(state),
  workloadViewBy: state.workloadPlannerFilter.viewBy
});
export default connect(mapStateToProps, { fetchTasksV2 })(TaskSidebar);
