import * as constants from 'appConstants';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  keyifyDate,
  mergeHomePlanners,
  getNewDateRange,
  reducePlanners,
  prepTaskParamsForReducePlanners
} from 'appUtils/plannerUtils';

const moment = extendMoment(Moment);

/* Task info and task order in planners is 100% decoupled.
  Cases that change task info should all follow the same pattern:
    1. Get task from taskHash. This information in conjunction with payload.params is enough to construct the new state in the following way:
        a. Call moveTaskToPlanner() with the appropriate params.
          - moveTaskToPlanner only figures out which planner the task was in, and which planner the task should be in.
        b. call updatePlanners() with planners return from moveTaskToPlanner
          - updatePlanners covers edge cases of same account_id or deletion.
        c. Call updateTaskHash() with the appropriate params.
          - updateTaskHash only updates task info.
    2. Update state with newPlanners from 1.b and new tasks from 1.c
  These util functions are currently safe (i.e. calling them when not necessary should still return the correct data)
*/

export const initialState = {
  isFetchingHomePlanner: false,
  dateHeader: moment(),
  planners: {},
  taskHash: {},
  projects: {},
  earliestDateFetched: moment(),
  latestDateFetched: moment(),
  dateRange: [],
  myId: 0,
  numOverdue: 0,
  changingProject: false,
  bulkUpdateModal: {
    isOpen: false,
    editedTasks: {}
  }
};
const homePlanner = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.USER.SUCCESS:
      return {
        ...state,
        myId: payload.response.account.id
      };

    case constants.FETCH_PLANNERS.TRIGGER: {
      const { leftOffset, rightOffset, shouldUpdateDateRange } = payload.params;

      const currentDateRange = {
        earliest: state.earliestDateFetched,
        latest: state.latestDateFetched
      };
      const newDates = shouldUpdateDateRange
        ? getNewDateRange(state, leftOffset, rightOffset)
        : currentDateRange;

      return {
        ...state,
        isFetchingHomePlanner: true,
        earliestDateFetched: newDates.earliest,
        latestDateFetched: newDates.latest,
        dateRange: Array.from(
          moment.range(newDates.earliest, newDates.latest).by('day')
        )
      };
    }
    case constants.FETCH_PLANNERS.FAILURE:
      return {
        ...state,
        isFetchingHomePlanner: false
      };

    case constants.FETCH_PLANNERS.SUCCESS: {
      const { accounts, project_tasks, projects } = payload.response.planners;

      const planners = mergeHomePlanners(state.planners, accounts);

      return {
        ...state,
        isFetchingHomePlanner: false,
        planners: planners,
        taskHash: {
          ...state.taskHash,
          ...project_tasks
        },
        projects: {
          ...state.projects,
          ...projects
        },
        numOverdue: 0 // get num overdue
      };
    }

    // these two cases are exclusively for completing a task
    // TASK_UPDATE_POSITION is a misnomer
    case constants.TASK_UPDATE_POSITION: {
      const newState = reducePlanners({
        state,
        params: action.payload.params
      });
      return newState;
    }
    case constants.UPDATE_DAY_PLANNER.TRIGGER: {
      const {
        laneId,
        taskId,
        position,
        dateKey,
        currentAccountId,
        newAccountId,
        accountIds
      } = payload.params;
      const configuredParams = {
        taskId,
        position,
        scheduleStartDate:
          keyifyDate(laneId) === 'unscheduled'
            ? 'unscheduled'
            : moment(laneId, 'YYYY-MM-DD'),
        dateKey,
        assigneeId: newAccountId || accountIds[0],
        currentAssigneeId: currentAccountId
      };
      const newState = reducePlanners({
        state,
        params: configuredParams
      });
      return newState;
    }

    case constants.BATCH_DELETE_TASKS.SUCCESS: {
      const { taskIds } = action.payload.requestPayload[2];
      let newState = state;
      taskIds.map((id) => {
        const configuredParams = {
          taskId: id
        };
        newState = reducePlanners({
          state: newState,
          shouldDeleteTask: true,
          params: configuredParams
        });
      });
      return newState;
    }

    case constants.FETCH_DAY_PLANNER.SUCCESS: {
      return state;
    }
    case constants.TOGGLE_BULK_UPDATE_MODAL: {
      return {
        ...state,
        bulkUpdateModal: {
          isOpen: !state.bulkUpdateModal.isOpen,
          editedTasks: {}
        }
      };
    }

    case constants.UPDATE_EDITED_TASKS: {
      const newTasksArray = action.payload;
      let newEditedTasks = { ...state.bulkUpdateModal.editedTasks };
      newTasksArray.forEach((newTask) => {
        newEditedTasks = {
          ...newEditedTasks,
          [newTask.id]: newTask
        };
      });

      return {
        ...state,
        bulkUpdateModal: {
          ...state.bulkUpdateModal,
          editedTasks: newEditedTasks
        }
      };
    }
    case constants.BULK_EDIT_TASKS.TRIGGER: {
      const { project_tasks } = payload;
      const allTasksArray = Object.values(project_tasks);
      const allTasks = [...allTasksArray].map(prepTaskParamsForReducePlanners);
      const newState = allTasks.reduce(
        (intermediateState, currentTask) =>
          reducePlanners({
            state: intermediateState,
            params: currentTask
          }),
        state
      );

      return {
        ...newState,
        bulkUpdateModal: {
          isOpen: false,
          editedTasks: {}
        }
      };
    }
    case constants.UPDATE_TASKS_ATTRIBUTES.SUCCESS: {
      const {
        response: { tasks },
        requestPayload: [token, body]
      } = action.payload;
      const allTasks = tasks.map(prepTaskParamsForReducePlanners);
      const shouldMoveTask = !!body.schedule_start || !!body.assignee_id;
      const newState = allTasks.reduce(
        (intermediateState, currentTask) =>
          reducePlanners({
            state: intermediateState,
            params: currentTask,
            shouldMoveTask
          }),
        state
      );
      return newState;
    }
    case constants.CREATE_TASK_FROM_HOME.REQUEST: {
      const {
        id,
        description,
        due_at,
        schedule_start,
        schedule_end,
        assignee_id,
        project_id,
        home_position,
        phase_id,
        status,
        estimated_hours,
        view_project_id
      } = action.payload.body;
      const tempTask = {
        id,
        description,
        project_id,
        assignee_id,
        due_at,
        schedule_start,
        schedule_end,
        completed_at: null,
        position: home_position,
        phase_id,
        status,
        estimated_hours
      };
      const assigneeId = tempTask.assignee_id;
      if (view_project_id) {
        return state;
      }
      const configuredParams = {
        taskId: tempTask.id,
        scheduleStartDate: schedule_start,
        scheduleEndDate: schedule_end,
        dateKey: keyifyDate(schedule_start),
        assigneeId,
        newTask: tempTask,
        estimatedHours: estimated_hours,
        status: status,
        phaseId: phase_id,
        position: home_position
      };
      const newState = reducePlanners({
        state: state,
        params: configuredParams
      });
      return newState;
    }
    case constants.CREATE_TASK_FROM_HOME.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { task } = response;
      const [token, requestBody] = requestPayload;
      const { id: tempId, home_position, view_project_id } = requestBody;
      if (view_project_id) {
        return state;
      }
      const assigneeId = task.assignee_id;
      let configuredParams = {
        taskId: tempId
      };
      let newState = reducePlanners({
        state: state,
        shouldDeleteTask: true,
        params: configuredParams
      });
      configuredParams = {
        taskId: task.id,
        scheduleStartDate: task.schedule_start,
        dateKey: keyifyDate(task.schedule_start),
        assigneeId,
        newTask: task,
        position: home_position
      };
      newState = reducePlanners({
        state: newState,
        params: configuredParams
      });

      return newState;
    }
    case constants.SET_PLANNER_DATE_HEADER: {
      const { dateHeader } = action.payload;
      return {
        ...state,
        dateHeader
      };
    }

    default:
      return state;
  }
};

export default homePlanner;
