import styled from 'styled-components';
import { ReactComponent as SmallPencilIcon } from 'icons/small-pencil.svg';
import { Space } from 'components/Space';
import { LocationsTableRowData } from '../types';

interface MainWorkAddressCellProps {
  row: {
    original: {
      rowData: {
        data: LocationsTableRowData;
      };
    };
  };
}

export const MainWorkAddressCell = (props: MainWorkAddressCellProps) => {
  const { locationMembership, onAddEditWorkAddress } =
    props.row.original.rowData.data;
  const location = locationMembership.location;

  const locationText = location?.full_address;

  return (
    <RootContainer onClick={() => onAddEditWorkAddress({ locationMembership })}>
      <ContentContainer $hasAddress={!!location}>
        <LocationText>{locationText ?? 'Add Address'}</LocationText>
        <>
          <Space value={7} />
          <EditIconContainer>
            <StyledSmallPencilIcon />
          </EditIconContainer>
        </>
      </ContentContainer>
    </RootContainer>
  );
};

const EditIconContainer = styled.div``;

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${EditIconContainer} {
    opacity: 0;
  }

  :hover {
    ${EditIconContainer} {
      opacity: 1;
    }
  }
`;

const LocationText = styled.div`
  // make 2 lines of text fit in the cell
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  width: 85%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const ContentContainer = styled.div<{ $hasAddress: boolean }>`
  display: flex;
  align-items: center;
  font-size: 13px;

  color: ${({ $hasAddress, theme }) =>
    $hasAddress
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorCalendarBlue};

  :hover {
    color: ${({ theme }) => theme.colors.colorCalendarBlue};
  }
`;

const StyledSmallPencilIcon = styled(SmallPencilIcon).attrs(({ theme }) => ({
  fill: theme.colors.colorBudgetBlue
}))``;
