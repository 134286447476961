import React, { useEffect } from 'react';
import styled from 'styled-components';
import HelpQuestionIcon from 'icons/HelpQuestionIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const StyledIconContainer = styled.div`
  margin-left: 8px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: ${(props) => props.theme.colors.colorPureWhite};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoadImportDataButton = ({ isCsvImportDataReady, isProvisioned }) => {
  const dataTip = isProvisioned
    ? isCsvImportDataReady
      ? 'Trigger CSV Import Portal'
      : 'Data is preloading to assist import validations'
    : 'Initialize Import. Only required once';
  useEffect(() => {
    rebuildTooltip();
  }, [dataTip]);
  return (
    <StyledIconContainer data-for="app-tooltip" data-tip={dataTip}>
      <HelpQuestionIcon height={8} width={8} />
    </StyledIconContainer>
  );
};
export default LoadImportDataButton;
