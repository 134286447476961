import {
  WorkPlanId,
  WorkPlan
} from 'views/projectPlanner/WorkplanModal/models/workPlan';

export interface FetchChunkOptions {
  useChunk?: boolean;
  chunkSize?: number;
}

export interface BaseUseDataHookOptions {
  isReady?: boolean;
}

export enum WorkPlanFetchSource {
  Member
}

export const fetchSourceParamKeyMap: Record<WorkPlanFetchSource, string> = {
  [WorkPlanFetchSource.Member]: 'team_member_ids'
} as const;

export const fetchSourceFilterKeyMap: Record<WorkPlanFetchSource, string> = {
  [WorkPlanFetchSource.Member]: 'account_id'
} as const;

export type WorkPlansHash = Record<WorkPlanId, WorkPlan>;
