import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';

import { getModalProjectInfo } from 'BudgetModule/selectors';
import { getOnWorkloadView, getTeamSlug } from 'selectors';
import {
  StyledInfoIcon,
  LinkText,
  StyledDropdownItem,
  TopHeaderSection,
  StyledProjectLinkContainer
} from 'BudgetModule/components/styles';
import ColorPicker from 'components/ColorPicker';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import Popover from 'components/Popover';
import PropTypes from 'prop-types';
import { OptionsContainer, StyledDot, ColorPickerContainer } from './styles';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { closeBudgetModal } from 'BudgetModule/actionCreators';
import {
  BUDGET_STATUSES,
  PROJECT_BUDGET_STATUSES_DISPLAY
} from 'appConstants/budgetStatuses';
import MappingStatusIcon from 'components/MappingStatusIcon';
import { DATA_TYPES } from 'IntegrationsModule/constants';

import {
  openPhaseModal,
  navigateToProject,
  openEditProjectModal,
  openAccessModal,
  editProject
} from 'actionCreators';
import { buildAccessIdentifier } from 'appUtils/access';
import BudgetStatusDropdown from 'BudgetModule/components/BudgetModal/BudgetStatusDropdown';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
import EllipsisText from 'components/EllipsisText';

const StyledDropdownToggleContainer = styled.div`
  display: flex;
  position: absolute;
  top: -17px;
`;

const StyledDropdownToggle = styled.div`
  background: ${theme.colors.colorPureWhite};
  border-radius: 4px;
  border: 1px solid ${theme.colors.colorLightGray12};
  padding: 0 3px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  color: ${theme.colors.colorCalendarGray};
  cursor: pointer;
  path {
    fill: ${(props) =>
      props.isCompleted
        ? theme.colors.colorMediumGray9
        : theme.colors.colorPureWhite};
  }
  &:hover {
    background: ${theme.colors.colorCalendarBlue};
    color: ${theme.colors.colorPureWhite};
    font-weight: 600;
  }
`;

const StyledMenuItem = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: ${theme.colors.colorMediumGray9};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 13px 8px;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;

const DropdownIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 11px;
`;

const MappingStatusIconContainer = styled.div`
  width: 26px;
  height: 28px;
  padding-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconsContainer = styled.div`
  margin-left: 5px;
  display: flex;
  position: relative;
  bottom: -1px;
`;

class BudgetModalHeader extends React.Component {
  addPhaseButtonRef = React.createRef();
  state = {
    isPhaseTemplateModalOpen: false,
    threeDotMenuOpen: false,
    isOpenAddMembersModal: false,
    statusDropdownIsOpen: false,
    isProposalEstimateMenuOpen: false
  };

  newColorPickerRef = React.createRef();
  popoverTarget = React.createRef();
  projectStatusTarget = React.createRef();

  getAccessIdentifier = () => {
    return buildAccessIdentifier({
      actableType: 'Project',
      actableId: this.props.project.id,
      actionType: 'budget_ui'
    });
  };

  openPhaseTemplatesDropdown = () => {
    this.setState({
      isPhaseTemplateModalOpen: true
    });
  };

  closePhaseTemplatesDropdown = () => {
    this.setState({
      isPhaseTemplateModalOpen: false
    });
  };

  openPhaseModal = ({ id, name }) => {
    const { openPhaseModal, project } = this.props;
    openPhaseModal({
      id,
      name,
      projectId: project.id
    });
  };

  handleClose = () => {
    const { closeBudgetModal } = this.props;
    closeBudgetModal();
  };

  openProjectInfoModal = () => {
    const { project, openEditProjectModal } = this.props;
    openEditProjectModal(project?.id);
  };

  handleOpenModal = () => this.setState({ threeDotMenuOpen: true });
  handleCloseModal = () => this.setState({ threeDotMenuOpen: false });

  renderMenu = () => {
    const isOpen = this.state.threeDotMenuOpen;

    return isOpen ? (
      <Popover
        isOpen
        target={this.popoverTarget}
        closePopover={this.handleCloseModal}
        className="styled-member-popover"
      >
        <StyledDropdownItem onClick={this.openAddMembersForm}>
          <OptionsContainer>Manage Members</OptionsContainer>
        </StyledDropdownItem>
      </Popover>
    ) : null;
  };

  openAddMembersForm = () => {
    const { openAddMembersForm } = this.props;
    openAddMembersForm();
    this.handleCloseModal();
  };

  handleProjectStatusChange = (newStatusParams) => {
    const { editProject } = this.props;
    editProject(newStatusParams);
  };

  projectStatusTooltip = () => {
    const { project } = this.props;

    const tooltip = {
      firstLine: '',
      secondLine: ''
    };

    tooltip.firstLine = `Status: ${
      PROJECT_BUDGET_STATUSES_DISPLAY[project.budget_status] ||
      PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.ACTIVE]
    }`;

    if (project.budget_sub_status) {
      tooltip.secondLine = `Probability - ${project.budget_sub_status}%`;
    }
    return `
      <div
        class="project-status-tooltip"
      >
        <div class="first-line">${tooltip.firstLine}</div>
        ${
          tooltip.secondLine &&
          `<div class="second-line">${tooltip.secondLine}</div>`
        }
      </div>
    `;
  };

  projectStatus = () => {
    const { project } = this.props;
    return `${
      PROJECT_BUDGET_STATUSES_DISPLAY[project.budget_status] ||
      PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.ACTIVE]
    }${
      project.budget_sub_status &&
      project.budget_sub_status > 0 &&
      project.budget_status === BUDGET_STATUSES.PROPOSAL
        ? ` - ${project.budget_sub_status}%`
        : ``
    }`;
  };

  render() {
    const {
      project,
      isOnWorkloadPage,
      navigateToProject,
      teamSlug,
      isLoading
    } = this.props;
    const { statusDropdownIsOpen } = this.state;
    const projectStatus = this.projectStatus();
    const projectStatusToolTip = this.projectStatusTooltip();

    return (
      <StyledBudgetModalHeader>
        <SkeletonLoader
          isLoading={isLoading}
          width={200}
          height={40}
          style={{ marginTop: 10 }}
        >
          <LeftSection>
            <ProjectNameContainer>
              <StyledDropdownToggleContainer>
                <StyledDropdownToggle
                  isCompleted={
                    project.budget_status === BUDGET_STATUSES.COMPLETE
                  }
                  onClick={() => this.setState({ statusDropdownIsOpen: true })}
                  ref={this.projectStatusTarget}
                  data-html
                  data-for="app-tooltip"
                  data-effect="solid"
                  data-class="center"
                  data-tip={projectStatusToolTip}
                >
                  {projectStatus}
                </StyledDropdownToggle>
                <BudgetStatusDropdown
                  isOpen={statusDropdownIsOpen}
                  target={this.projectStatusTarget}
                  project={project}
                  closePopover={() =>
                    this.setState({ statusDropdownIsOpen: false })
                  }
                  handleProjectStatusChange={this.handleProjectStatusChange}
                />
              </StyledDropdownToggleContainer>

              <TopHeaderSection>
                {isOnWorkloadPage ? (
                  <StyledProjectLinkContainer>
                    <GoToProjectIcon />
                    <LinkText
                      onClick={() => {
                        navigateToProject({
                          projectId: project.id,
                          teamSlug,
                          projectSlug: project?.slug ?? 'project',
                          openInNewWindow: true
                        });
                      }}
                    >
                      Go To Project
                    </LinkText>
                  </StyledProjectLinkContainer>
                ) : (
                  ''
                )}
                <ColorPickerContainer
                  className="color-picker-box"
                  ref={this.newColorPickerRef}
                >
                  <StyledDot projectId={project.id} />
                  <ColorPicker
                    projectId={project.id}
                    id={project.id}
                    originType={ORIGIN_TYPE_STRINGS.PROJECT}
                    pickerLocation="color-picker-box"
                    className="color-picker-container"
                    ref={this.newColorPickerRef}
                  />
                </ColorPickerContainer>
                <StyledProjectTitle
                  isDarkGray={isDarkGray(project.budget_status)}
                  onClick={this.openProjectInfoModal}
                >
                  <EllipsisText>{project.title}</EllipsisText>
                  <StyledInfoIcon />
                </StyledProjectTitle>
                <IconsContainer
                  ref={this.popoverTarget}
                  onClick={this.handleOpenModal}
                  style={{}}
                >
                  <MappingStatusIcon
                    id={project.id}
                    dataType={DATA_TYPES.PROJECTS}
                    Container={MappingStatusIconContainer}
                  />
                  <ProjectThreeDotMenu
                    shouldClose={!this.state.threeDotMenuOpen}
                    onCloseCallback={this.handleCloseModal}
                    projectTitle={project.title}
                  >
                    {this.renderMenu()}
                  </ProjectThreeDotMenu>
                </IconsContainer>
              </TopHeaderSection>

              <ProjectDescription>
                <EllipsisText>{project.description}</EllipsisText>
              </ProjectDescription>
              {project.project_number && (
                <ProjectNumber>
                  <EllipsisText>{project.project_number}</EllipsisText>
                </ProjectNumber>
              )}
            </ProjectNameContainer>
          </LeftSection>
        </SkeletonLoader>

        <RightSection>
          <StyledDoneButton onClick={this.handleClose}>Done</StyledDoneButton>
        </RightSection>
      </StyledBudgetModalHeader>
    );
  }
}

BudgetModalHeader.propTypes = {
  project: PropTypes.object,
  closeBudgetModal: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  project: getModalProjectInfo(state, ownProps),
  isOnWorkloadPage: getOnWorkloadView(state),
  teamSlug: getTeamSlug(state)
});

const mapDispatchToProps = {
  closeBudgetModal,
  openPhaseModal,
  navigateToProject,
  openEditProjectModal,
  openAccessModal,
  editProject
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetModalHeader);

/* ------------------------------------ - ----------------------------------- */

const ProjectDescription = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray5};
  max-width: 20vw;
  @media screen and (max-width: 1600px) {
    max-width: 15vw;
  }
  @media screen and (max-width: 1160px) {
    max-width: 10vw;
  }
`;

const ProjectNumber = styled(ProjectDescription)``;

const StyledProjectTitle = styled.div`
  color: ${(props) =>
    props.isDarkGray
      ? theme.colors.colorCalendarGray
      : theme.colors.colorMediumGray9};
  font-weight: 600;
  font-size: 28px;
  line-height: 1.3;
  min-width: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${StyledInfoIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
      circle {
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
  svg {
    flex-shrink: 0;
  }
`;

const ProjectNameContainer = styled.div`
  position: relative;
`;

const StyledDoneButton = styled.button`
  padding: 6px 19px;
  font-size: 14px;
  background: ${theme.colors.colorRoyalBlue};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 5px;
  transform: translateY(12px);
  font-weight: 600;

  &:hover {
    filter: brightness(92%);
  }
`;

const StyledBudgetModalHeader = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 38px 45px 0;
`;

const LeftSection = styled.div`
  max-width: 80%;
  margin-top: 10px;
`;
const RightSection = styled.div``;

const darkGrayTitleList = ['hold', 'complete'];

const isDarkGray = (status) => {
  return darkGrayTitleList.includes(status);
};
