import { useRef, useMemo, useCallback, useEffect } from 'react';
import { getTableConfig } from '../tableConfigs';
import { VariableSizeList } from 'react-window';
import useNestedCollapse, {
  ROOT,
  TOGGLE_VALUES_KEY
} from 'appUtils/hooks/useNestedCollapse/useNestedCollapse';
import { RateGroupId } from 'RatesModule/models/RateGroup';
import xor from 'lodash/xor';
import produce from 'immer';
import { TeamRatesRateGroupsState } from 'RatesModule/reducers/types';

export const useTableBoilerplate = ({
  rateGroupsHash,
  ...getTableConfigProps
}: Parameters<typeof getTableConfig>[0] & {
  rateGroupsHash: TeamRatesRateGroupsState['hash'];
}) => {
  const listRef = useRef<VariableSizeList>(null);

  const handleToggle = useCallback(() => {
    if (listRef.current?.resetAfterIndex) listRef.current?.resetAfterIndex(0);
  }, []);

  const tableConfig = useMemo(
    () => getTableConfig(getTableConfigProps),
    [getTableConfigProps]
  );

  const {
    allCollapsed,
    getIsOpen,
    parentCollapseStates,
    setParentCollapseState,
    toggleCollapse,
    toggleCollapseAll
  } = useNestedCollapse({ toggleCallback: handleToggle });

  const prevRateGroupIds = useRef<RateGroupId[]>([]);
  useEffect(() => {
    // Determine the list of new or removed rate groups.
    const rateGroupIds = Object.values(rateGroupsHash).map(({ id }) => id);
    const changedRateGroupIds = xor(rateGroupIds, prevRateGroupIds?.current);

    // Only update the toggles if there are new or delete rate groups.
    if (changedRateGroupIds.length) {
      const toggles = produce(
        parentCollapseStates[ROOT][TOGGLE_VALUES_KEY] as Record<
          RateGroupId,
          boolean
        >,
        (draft) => {
          changedRateGroupIds.reduce((toggles, rateGroupId) => {
            const rateGroup = rateGroupsHash[rateGroupId];

            // Removed rate group
            if (!rateGroup) delete toggles[rateGroupId];
            // Added archived rate group
            else if (rateGroup.archived_at) toggles[rateGroupId] = true;
            // Added unarchived rate groups are not collapsed by default.

            return toggles;
          }, draft);
        }
      );

      setParentCollapseState({
        values: {
          numToggled: Object.keys(toggles).length,
          [TOGGLE_VALUES_KEY]: toggles
        }
      });
    }

    prevRateGroupIds.current = rateGroupIds;
  }, [parentCollapseStates, setParentCollapseState, rateGroupsHash]);

  // Update the number of toggles when the number of rate groups changes.
  const rateGroupHashLength = Object.keys(rateGroupsHash).length;
  useEffect(() => {
    setParentCollapseState({
      values: { totalNumToggles: rateGroupHashLength }
    });
  }, [rateGroupHashLength, setParentCollapseState]);

  const handleToggleCollapseAll = useCallback(
    () => toggleCollapseAll(),
    [toggleCollapseAll]
  );

  return {
    allCollapsed,
    getIsOpen,
    listRef,
    tableConfig,
    toggleCollapse,
    toggleCollapseAll: handleToggleCollapseAll
  };
};
