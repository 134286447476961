import styled from 'styled-components';
import { COLUMN_CONFIG } from '../tableConfigs';
import { TableCell } from './TableCell';

export const HeaderCell = ({
  className,
  isCostRate,
  label
}: {
  className: string;
  isCostRate?: boolean;
  label: typeof COLUMN_CONFIG[keyof typeof COLUMN_CONFIG]['label'];
}) => (
  <Cell className={className}>
    {typeof label === 'string' ? label : label(isCostRate)}
  </Cell>
);

const Cell = styled(TableCell)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorMediumGray10};
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  font-size: 12px;
  height: 100%;
`;
