import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { GetCommentsPermission } from './CommentRow';

export const useGetWorkPlanCommentsPermission: GetCommentsPermission = ({
  accountId
}) => {
  const {
    canEditWorkPlanComments: canEditComment,
    canDeleteWorkPlanComments: canDeleteComment
  } = useProjectPermissionState({ accountId });

  return {
    canEditComment,
    canDeleteComment
  };
};

export const useGetScopeCommentsPermission: GetCommentsPermission = ({
  accountId
}) => {
  const {
    canEditScopeComments: canEditComment,
    canDeleteScopeComments: canDeleteComment
  } = useProjectPermissionState({ accountId });

  return {
    canEditComment,
    canDeleteComment
  };
};
