import { put, select, call, all, take } from 'redux-saga/effects';

import moment from 'appUtils/momentConfig';
import { delay } from 'redux-saga'; // this import must be updated when upgrading redux-saga to v1
import arrayMove from 'appUtils/arrayMove';
import { isToday } from 'appUtils/momentUtils';
import * as constants from 'appConstants';
import * as actionCreators from 'actionCreators';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import * as api from '../service/api';
import apiRequestSaga from 'appUtils/request';
import { history } from 'store/configureStore';
import {
  getCurrentFilter,
  getTaskAccountFilter,
  getAuthToken,
  getProjectHash,
  getMyTeamMembership,
  getCreatedTasksTempIds,
  getTempIdsToCreatedTaskIds,
  getMyUserId,
  getSplitFlags,
  getSelectedProjectId,
  getHomeTaskObj,
  getPlannerDateHeader,
  getIsTaskModalOpen,
  getMatchedRouteParams,
  getNavigationHistory
} from 'selectors';
import uuid from 'uuid';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';

const {
  updateTasksAttributesEntityActions,
  createTaskEntityAction,
  copyTasksEntityAction,
  moveTasksEntityAction,
  queryAIEntityAction,
  batchRequestTasksFollowUp,
  batchMoveTasksToProject,
  batchCopyTasksToProject,
  batchDeleteTasks,
  batchAcceptInboxTasks,
  batchUnfollowTasks,
  batchCompleteTasks,
  createTaskCommentEntityAction,
  fetchTaskCounts,
  fetchMemberTodayTaskCount,
  fetchMemberOverdueTaskCount,
  fetchMemberScheduledTaskCount,
  fetchMemberUnscheduledTaskCount,
  fetchMemberCompletedTaskCount,
  fetchMemberEndsBeforeTaskCount
} = entityActions;

const { MOVE_WITH_EXISTING, COPY_WITH_EXISTING } =
  constants.BATCH_ACTION_TASK_GROUP_HEADER_CONSTANTS;

export function* updateTasksHomePositionWorker(action) {
  const {
    token,
    allTasks,
    taskSourceIndex,
    taskDestinationIndex,
    homeView,
    projectId
  } = action.payload;
  const sortedTasks = arrayMove(
    allTasks,
    taskSourceIndex,
    taskDestinationIndex
  );
  yield put({
    type: 'UPDATE_TASK_HOME_POSITION_ON_CLIENT',
    payload: {
      sortedTasks
    }
  });
  const body = {
    task_ids: [allTasks[taskSourceIndex]],
    position: taskDestinationIndex,
    home_view: homeView
  };
  if (projectId) {
    body.project_id = projectId;
  }

  const { error } = yield changeEntity(
    updateTasksAttributesEntityActions,
    api.patchTasks,
    [token, body]
  );
}
export function* moveTaskToGroupWorker(action) {
  const {
    sourceTaskGroupId,
    destinationTaskGroupId,
    index,
    groupType = 'taskGroup',
    changedTaskAttributes = {}
  } = action.payload;
  let taskId = action.payload.taskId;

  const taskHash = yield select((state) => state.homeTasks.taskHash);
  const tempIdsToTaskIds = yield select(getTempIdsToCreatedTaskIds);
  const foundTempIdTaskId = tempIdsToTaskIds[taskId];

  if (taskHash[taskId]) {
    taskId = taskHash[taskId].id; // use id instead of draggable temp id
  } else if (foundTempIdTaskId) {
    taskId = foundTempIdTaskId; // use id instead of draggable temp id
  }
  yield put(
    actionCreators.removeTaskFromGroup({
      groupType,
      taskGroupId: sourceTaskGroupId,
      taskId
    })
  );
  yield put(
    actionCreators.addTaskToGroup({
      taskGroupId: destinationTaskGroupId,
      taskId,
      index,
      groupType,
      changedTaskAttributes
    })
  );
}

export function* addTaskToGroupWorker(action) {
  const groupTypeToAttributeHash = {
    taskGroup: 'task_group_id',
    activityPhase: 'activity_phase_id'
  };
  const { taskGroupId, index, groupType } = action.payload;
  let taskId = action.payload.taskId;
  const token = yield select(getAuthToken);
  const tempIds = yield select((state) => state.homeTasks.tempIds);

  if (tempIds[taskId]) {
    const success = yield take(
      (action) =>
        action.type === constants.CREATE_TASK_FROM_HOME.SUCCESS &&
        action.payload.requestPayload[1]?.id === taskId
    );
    taskId = success.payload.response.task.id;
  }
  const updateKey = groupTypeToAttributeHash[groupType];
  if (updateKey) {
    const body = {
      task_ids: [taskId],
      position: index,
      [updateKey]: taskGroupId
    };
    const { error } = yield changeEntity(
      updateTasksAttributesEntityActions,
      api.patchTasks,
      [token, body]
    );
  }
}

export function* updateTasksAttributesWorker(action) {
  const { token, body, onSuccess } = action.payload;
  const tempIds = yield select((state) => state.homeTasks.tempIds);
  let taskIds = body.task_ids;
  if (body.task_ids.some((id) => tempIds[id])) {
    const idsToWaitFor = body.task_ids.filter((id) => tempIds[id]);
    const idsToNotWaitFor = body.task_ids.filter((id) => !tempIds[id]);
    const successes = yield all(
      idsToWaitFor.map((id) =>
        take(
          (action) =>
            action.type === constants.CREATE_TASK_FROM_HOME.SUCCESS &&
            action.payload.requestPayload[1]?.id === id
        )
      )
    );

    const successTaskIds = successes.map(
      (success) => success.payload.response.task.id
    );
    taskIds = [...idsToNotWaitFor, ...successTaskIds];
  }
  const { error, response } = yield changeEntity(
    updateTasksAttributesEntityActions,
    api.patchTasks,
    [token, { ...body, task_ids: taskIds }]
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
  if (moment(body.schedule_start).isValid() || body.assignee_ids) {
    const myId = yield select(getMyUserId);
    yield put(
      actionCreators.fetchPlanners(token, {
        baseDate: moment(body.schedule_start),
        rightOffset: 1,
        leftOffset: 1,
        accountIds: [myId]
      })
    );
  }

  yield put(actionCreators.clearRemovedTasksTrigger());
}

const determineShouldUpdateTaskList = ({
  action,
  selectedAccountIds,
  currentFilter
}) => {
  const { body, skipShouldUpdateTaskList, taskListType } = action.payload;
  if (skipShouldUpdateTaskList) {
    return false;
  }
  if (taskListType) {
    // reducer handles task list type correctly.
    return true;
  }
  const { project_id, assignee_ids, view_project_id, view_assignee_id } = body;
  const { scope } = currentFilter;
  const isOnHome = [
    'accepted',
    'today',
    'assigned_to_me',
    'assigned_by_me'
  ].includes(scope);
  const isTodayView = scope === 'today';
  const assigneeIdHash = keyBy(assignee_ids);

  const isHomeAndAssignedToMe =
    isOnHome &&
    selectedAccountIds?.some((accountId) => assigneeIdHash[accountId]);
  const taskBelongsToProjectDetail =
    view_project_id && +view_project_id === +project_id;
  const taskBelongsToMemberProfile =
    view_assignee_id &&
    assignee_ids?.some((accountId) => +accountId === +view_assignee_id);

  const shouldUpdateTaskList =
    ((!isTodayView || isToday(body)) && isHomeAndAssignedToMe) ||
    taskBelongsToProjectDetail ||
    taskBelongsToMemberProfile;

  return shouldUpdateTaskList;
};

export function* createTaskWorker(action) {
  const { token, body, shouldOpenModalAfterCreate } = action.payload;
  const {
    assignee_ids,
    description,
    due_at,
    id,
    phase_id,
    project_id,
    schedule_start,
    view_assignee_id,
    view_project_id,
    task_priority_id,
    task_group_id,
    estimated_hours,
    task_status_id,
    schedule_end,
    note,
    activity_id,
    activity_phase_id,
    home_position,
    dependency_info,
    project_membership_ids
  } = body;
  const currentFilter = yield select(getCurrentFilter);
  const plannerDateHeader = yield select(getPlannerDateHeader);
  const selectedAccountIds = yield select(getTaskAccountFilter);
  const myId = yield select(getMyUserId);
  const shouldUpdateTaskList = determineShouldUpdateTaskList({
    action,
    currentFilter,
    selectedAccountIds
  });

  const sagaPayload = {
    ...action.payload,
    shouldUpdateTaskList
  };
  const taskBody = {
    assignee_ids,
    description,
    due_at,
    id,
    project_id,
    phase_id,
    schedule_start,
    view_assignee_id,
    view_project_id,
    task_priority_id,
    task_group_id,
    estimated_hours,
    task_status_id,
    schedule_end,
    note,
    activity_id,
    activity_phase_id,
    home_position,
    dependency_info,
    project_membership_ids
  };

  const { response } = yield changeEntity(
    createTaskEntityAction,
    api.createTask,
    [token, taskBody],
    action,
    sagaPayload
  );

  if (response?.task) {
    const { project_id: projectId, id } = response.task;
    const projectHash = yield select(getProjectHash);
    if (projectId && !projectHash[projectId]) {
      yield put(actionCreators.fetchAllProjects({ projectIds: [projectId] }));
    }

    if (shouldOpenModalAfterCreate) {
      yield put(actionCreators.navigateToTaskModal({ taskId: id }));
    }
  }
  if (currentFilter?.scope === 'today') {
    yield put(
      actionCreators.fetchPlanners(token, {
        baseDate:
          currentFilter?.subSection === 'scheduled'
            ? plannerDateHeader
            : moment(),
        rightOffset: 1,
        leftOffset: 1,
        accountIds: [myId]
      })
    );
  }
}

export function* moveTasksWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    groupId,
    phaseId,
    activityId,
    activityPhaseId,
    taskIds,
    groupIds,
    options,
    dontRemoveTask
  } = action.payload;

  const currentFilter = yield select(
    (state) => state.homeTasks.activeTaskFilter
  );
  const shouldUseFilter = groupIds.length;

  const bodyInit = {
    groupId,
    filter: !!shouldUseFilter,
    move_task_ids: taskIds,
    destination_project_id: projectId,
    ...currentFilter,
    task_group_ids: groupIds, // hard code overwriting task_group_ids from filter, can be updated to any type of grouping behavior that exists on FE
    dontRemoveTask
  };

  const bodies = [];

  if (groupId === MOVE_WITH_EXISTING) {
    const taskHash = yield select(getHomeTaskObj);
    const taskIdsByGroup = groupBy(
      taskIds,
      (taskId) => taskHash[taskId]?.task_group_id
    );
    const tasksGroupIds = Object.keys(taskIdsByGroup).filter((id) => id);
    const newGroupIds = Array.from(new Set([...groupIds, ...tasksGroupIds]));

    newGroupIds.forEach((id) => {
      const moveTaskIds = taskIdsByGroup[id];
      const body = {
        ...bodyInit,
        task_group_ids: [id],
        source_task_group_id: id,
        move_task_ids: moveTaskIds, // only defined for specific, non-group selected tasks
        filter: !moveTaskIds // don't apply filter if specific move task ids exist
      };
      bodies.push(body);
    });
  } else {
    const body = {
      ...bodyInit,
      destination_task_group_id: groupId
    };
    bodies.push(body);
  }

  for (const body of bodies) {
    const { error, response } = yield changeEntity(
      moveTasksEntityAction,
      api.moveOrCopyTasks,
      [token, body],
      action,
      action.payload
    );
  }
  if (options?.shouldRemoveTask === false) {
    if (groupId === MOVE_WITH_EXISTING) {
      yield put(actionCreators.triggerTaskStoreFlush());
      yield put(actionCreators.fetchProjectTaskGroups({ projectId }));
    } else {
      console.log('reset to head of target task group');
    }
  }
}
export function* copyTasksWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    groupId,
    taskIds,
    groupIds,
    options = {}
  } = action.payload;

  const currentFilter = yield select(
    (state) => state.homeTasks.activeTaskFilter
  );
  const shouldUseFilter = groupIds.length;

  const bodyInit = {
    copy: true,
    filter: !!shouldUseFilter,
    move_task_ids: taskIds,
    destination_project_id: projectId,
    ...currentFilter,
    task_group_ids: groupIds // hard code overwriting task_group_ids from filter, can be updated to any type of grouping behavior that exists on FE
  };

  const canAnticipateCopiedTasks =
    options.shouldAddTaskToView &&
    taskIds?.length &&
    !groupIds?.length &&
    groupId !== COPY_WITH_EXISTING;
  const bodies = [];
  if (groupId === COPY_WITH_EXISTING) {
    const taskHash = yield select(getHomeTaskObj);
    const taskIdsByGroup = groupBy(
      taskIds,
      (taskId) => taskHash[taskId]?.task_group_id
    );
    const tasksGroupIds = Object.keys(taskIdsByGroup).filter((id) => id);
    const newGroupIds = Array.from(new Set([...groupIds, ...tasksGroupIds]));

    newGroupIds.forEach((id) => {
      const moveTaskIds = taskIdsByGroup[id];
      const body = {
        ...bodyInit,
        task_group_ids: [id],
        source_task_group_id: id,
        move_task_ids: moveTaskIds, // only defined for specific, non-group selected tasks
        filter: !moveTaskIds // don't apply filter if specific move task ids exist
      };
      bodies.push(body);
    });
  } else {
    const uuids = canAnticipateCopiedTasks
      ? taskIds.map((taskId) => uuid())
      : undefined;

    const body = {
      ...bodyInit,
      uuids,
      destination_task_group_id: groupId,
      canAnticipateCopiedTasks
    };
    bodies.push(body);
  }

  for (const body of bodies) {
    const { error, response } = yield changeEntity(
      copyTasksEntityAction,
      api.moveOrCopyTasks,
      [token, body],
      action,
      {
        ...action.payload,
        ...(bodies.length > 1 ? {} : body)
      }
    );
  }
  if (options.shouldAddTaskToView) {
    if (groupId === COPY_WITH_EXISTING || groupIds?.length) {
      yield put(actionCreators.triggerTaskStoreFlush());
      yield put(actionCreators.fetchProjectTaskGroups({ projectId }));
    } else {
      console.log('reset to head of target task group');
    }
  }
}

export function* queryAIWorker(action) {
  // const {
  //   /*body*/
  // } = action.payload;
  const token = yield select(getAuthToken);
  const payload = {
    text: 'nima, for ui tickets, please update the dropbox designs due by next thursday',
    user_id: 2448
  };
  const body = {
    assignee: { id: '2406', name: 'Nima Tayebi' },
    due_date: '2019-08-08T12:00:00.000Z',
    project: { id: 23857, name: 'UI Tickets' },
    source_text:
      'nima, for ui tickets, please update the dropbox designs due by next thursday',
    task_title: 'Please update the dropbox designs',
    user_id: 2448,
    uuid: '9974260ef79f4013946045124877d84b'
  };

  const sagaPayload = {
    ...action.payload,
    shouldUpdateTaskList: true
  };
  if (window.callAI == true) {
    const { response: airesponse, error: aierror } = yield changeEntity(
      queryAIEntityAction,
      api.queryAI,
      [token, payload],
      action,
      sagaPayload
    );
  }
  // const currentFilter = yield select(getCurrentFilter);
  // const selectedAccountIds = yield select(getTaskAccountFilter);
  // const shouldUpdateTaskList = determineShouldUpdateTaskList({
  //   action,
  //   currentFilter,
  //   selectedAccountIds
  // });

  const createTaskBody = {
    assignee_id: 2310,
    description: 'Work on designs for Timesheet approvals',
    due_at: '2019-08-02',
    home_position: 0,
    id: uuid(),
    project_id: 23351,
    schedule_start: null
    // view_assignee_id: 2310
  };
  const { error, response } = yield changeEntity(
    createTaskEntityAction,
    api.createTask,
    [token, createTaskBody],
    action,
    sagaPayload
  );
  if (!error) {
    yield put(actionCreators.closeTaskGlobalAdd());
    yield put(actionCreators.navigateToTaskModal({ taskId: response.task.id }));
  }
}

export function* createTaskAndOpenModalWorker(action) {
  const { token, body } = action.payload;
  const currentFilter = yield select(getCurrentFilter);
  const selectedAccountIds = yield select(getTaskAccountFilter);
  const shouldUpdateTaskList = determineShouldUpdateTaskList({
    action,
    currentFilter,
    selectedAccountIds
  });

  const payload = {
    ...action.payload,
    shouldUpdateTaskList
  };
  const tempId = body.id;

  yield put(createTaskEntityAction.request(payload));
  yield put(actionCreators.navigateToTaskModal({ taskId: tempId }));
  const { error, response } = yield call(api.createTask, token, body);

  if (response?.task) {
    yield put(createTaskEntityAction.success(response, [token, body]));
    const isTaskModalOpen = yield select(getIsTaskModalOpen);
    const matchedRouteParams = yield select(getMatchedRouteParams);
    // ensure modal is still open, user may have closed it.
    if (isTaskModalOpen && matchedRouteParams?.taskId === tempId) {
      const newTaskModalUrl = window.location.pathname.replace(
        tempId,
        response.task.id
      );
      history.push(newTaskModalUrl);
      yield put(
        actionCreators.fetchCommentsAndMetadata({
          taskId: response.task.id,
          taskType: 'project',
          offset: 0,
          limit: 4
        })
      );
    }
  }
  if (currentFilter?.scope === 'today') {
    const plannerDateHeader = yield select(getPlannerDateHeader);
    const myId = yield select(getMyUserId);
    yield put(
      actionCreators.fetchPlanners(token, {
        baseDate:
          currentFilter?.subSection === 'scheduled'
            ? plannerDateHeader
            : moment(),
        rightOffset: 1,
        leftOffset: 1,
        accountIds: [myId]
      })
    );
  }
}

export function* createTaskCommentWorker(action) {
  const { token, body } = action.payload;
  const { error } = yield changeEntity(
    createTaskCommentEntityAction,
    api.createTaskComment,
    [token, body]
  );
}

export function* batchRequestTasksFollowUpWorker(action) {
  const { token, updateType } = action.payload;
  const { error } = yield changeEntity(
    batchRequestTasksFollowUp,
    api.postTasksBatchUpdate,
    [token, updateType]
  );
}

export function* batchMoveTasksToProjectWorker(action) {
  const { token, updateType, body } = action.payload;
  const { error } = yield changeEntity(
    batchMoveTasksToProject,
    api.postTasksBatchUpdate,
    [token, updateType, body]
  );
}

export function* batchAcceptInboxTasksWorker(action) {
  const { token, updateType, body } = action.payload;
  const { error } = yield changeEntity(
    batchAcceptInboxTasks,
    api.postTasksBatchUpdate,
    [token, updateType, body]
  );
}

export function* batchCopyTasksToProjectWorker(action) {
  const { token, updateType, body, options } = action.payload;
  const uuids = body.taskIds.map((taskId) => uuid());
  const { error } = yield changeEntity(
    batchCopyTasksToProject,
    api.postTasksBatchUpdate,
    [token, updateType, body],
    action,
    { ...options, body, uuids }
  );
}

export function* batchDeleteTasksWorker(action) {
  const { token, updateType, body } = action.payload;
  const { error } = yield changeEntity(
    batchDeleteTasks,
    api.postTasksBatchUpdate,
    [token, updateType, body]
  );
  yield put(actionCreators.clearRemovedTasksTrigger());
}

export function* batchRevertTasksToIncompleteWorker(action) {
  const { token, updateType, body } = action.payload;
  const { error } = yield changeEntity(
    batchCopyTasksToProject,
    api.postTasksBatchUpdate,
    [token, updateType, body]
  );
  yield put(actionCreators.clearRemovedTasksTrigger());
}

export function* batchUnfollowTasksWorker(action) {
  const { token, updateType, body } = action.payload;
  const { error } = yield changeEntity(
    batchUnfollowTasks,
    api.postTasksBatchUpdate,
    [token, updateType, body]
  );
}

export function* batchCompleteTasksWorker(action) {
  const { token, updateType, body } = action.payload;
  const { taskIds } = body;
  const { currentFilter } = yield select(getTaskFetchConfig);
  yield put(
    actionCreators.handleTaskGroupsTasksCompletionToggle({
      isCompleting: updateType === 'complete',
      taskIds,
      currentFilter
    })
  );
  const { error } = yield changeEntity(
    batchCompleteTasks,
    api.postTasksBatchUpdate,
    [token, updateType, body],
    action,
    body
  );
  yield put(actionCreators.clearRemovedTasksTrigger());
}

export function* handleHomeCompletionToggle(action) {
  const { currentFilter } = yield select(getTaskFetchConfig);
  yield put(
    actionCreators.handleTaskGroupsTasksCompletionToggle({
      ...action.payload,
      taskIds: [action.payload.taskId],
      currentFilter
    })
  );
  yield put(actionCreators.clearRemovedTasksTrigger());
}

export const getTaskFetchConfig = (state) => ({
  page: state.homeTasks.currentPage,
  search: state.search.searchText,
  filter: state.homeTasks.currentFilter,
  sort: state.homeTasks.sort,
  direction: state.homeTasks.direction,
  currentFilter: state.homeTasks.currentFilter,
  limit: state.homeTasks.limit,
  tag_id: state.tags.filterTagId,
  selectedAccountIds: state.homeTasks.selectedAccountIds,
  viewBy: state.homeTasks.viewBy
});

const getTaskLength = (state) => state.homeTasks.allTasks.length;

export function* fetchTaskListDataInitialDataLoadWorker(action) {
  const { page, currentFilter, selectedAccountIds, ...restProps } =
    yield select(getTaskFetchConfig);
  let body = null;
  if (currentFilter.scope === 'today') {
    body = {
      state: currentFilter.state,
      scope: currentFilter.scope,
      assignee_id: selectedAccountIds,
      ...restProps
    };
  } else {
    body = {
      page: 1,
      state: currentFilter.state,
      scope: currentFilter.scope,
      assignee_id: selectedAccountIds,
      ...restProps
    };
  }

  if (action.payload?.projectId) {
    body.project_id = action.payload.projectId;
  }
  if (action.payload?.pastScheduled) {
    body = {
      ...body,
      scope: 'past_scheduled',
      state: 'incomplete',
      page: undefined
    };
  }
  if (action.payload?.pastDue) {
    body = {
      ...body,
      scope: 'past_due',
      page: undefined
    };
  }
  if (action.payload?.assigneeIds) {
    body = {
      ...body,
      assignee_id: action.payload.assigneeIds
    };
  }

  const options = {
    method: 'POST',
    body
  };
  const endpoint = `tasks`;
  const apiCalls = [];

  apiCalls.push(call(apiRequestSaga, endpoint, options, action));
  const taskLength = yield select(getTaskLength);
  if (
    currentFilter.section === 'My Tasks' &&
    !taskLength &&
    !action.payload.projectId
  ) {
    const countOptions = {
      method: 'POST',
      body: {
        count: true
      }
    };
    apiCalls.push(
      call(apiRequestSaga, endpoint, countOptions, {
        ...action,
        count: true
      })
    );
  }

  yield all(apiCalls);
}

export function* fetchTaskListAdditionalDataLoadWorker(action) {
  const { currentFilter, page, selectedAccountIds, ...restProps } =
    yield select(getTaskFetchConfig);

  const body = {
    page: page + 1,
    state: currentFilter.state,
    scope: currentFilter.scope,
    assignee_id: selectedAccountIds,
    ...restProps
  };

  if (action.payload && action.payload.projectId) {
    body.project_id = action.payload.projectId;
  }

  const options = {
    method: 'POST',
    body
  };
  const endpoint = `tasks`;
  yield call(apiRequestSaga, endpoint, options, action);
}

export function* clearRemovedTasks() {
  yield delay(3000);
  yield put(actionCreators.clearRemovedTasks());
}

export function* registerTaskCompleteModalVisit() {
  const myTeamMembership = yield select(getMyTeamMembership);
  if (myTeamMembership) {
    yield put(
      actionCreators.updateTeamMembership({
        id: myTeamMembership.id,
        modal_preferences: {
          ...myTeamMembership.modal_preferences,
          confirm_complete_task_visits:
            (myTeamMembership.modal_preferences?.confirm_complete_task_visits ??
              0) + 1
        }
      })
    );
  }
}

export function* fetchTaskCountsByAttribute(action) {
  const token = yield select(getAuthToken);
  const splitFlags = yield select(getSplitFlags);
  const projectId = yield select(getSelectedProjectId);
  const {
    page,
    search,
    filter,
    sort,
    direction,
    currentFilter,
    limit,
    tag_id,
    selectedAccountIds
  } = yield select(getTaskFetchConfig);
  const completedParamHash = {
    completed: true,
    incomplete: false,
    default: undefined
  };

  const shouldRefetchTaskCounts = !!projectId;
  if (shouldRefetchTaskCounts) {
    const body = {
      count: true,
      assignee_ids: selectedAccountIds,
      complete: completedParamHash[currentFilter.state],
      tag_ids: tag_id ? [tag_id] : undefined,
      project_ids: [projectId],
      sort_attributes: sort
        ? [
            { attribute: 'task_group', direction: 'asc' },
            {
              attribute: sort,
              direction: direction || 'asc'
            }
          ]
        : [{ attribute: 'task_group', direction: 'asc' }]
    };
    const { error, response } = yield fetchEntity(
      fetchTaskCounts,
      api.fetchTasksV2,
      undefined,
      [token, body],
      action
    );
  }
}

export function* fetchTodayMemberTaskCount(action) {
  const token = yield select(getAuthToken);
  const body = {
    count: true,
    assignee_ids: [action.payload.accountId],
    team_id: action.payload.teamId,
    complete: false,
    ends_on: moment().format('MM/DD/YYYY'),
    assigner_ids: [action.payload.assignerId]
  };
  const { error, response } = yield fetchEntity(
    fetchMemberTodayTaskCount,
    api.fetchTasksV2,
    undefined,
    [token, body],
    action
  );
}

// This is specific for TasksWidget
export function* fetchEndsBeforeMemberTaskCount(action) {
  const token = yield select(getAuthToken);
  const {
    accountId,
    teamId,
    scheduleEndsBefore,
    scheduleStartStartDate,
    scheduleStartEndDate,
    scheduleEndStartDate,
    scheduleEndEndDate
  } = action.payload;
  const body = {
    count: true,
    assignee_ids: [accountId],
    team_id: teamId,
    complete: false,
    ends_before: scheduleEndsBefore,
    schedule_start_start_date: scheduleStartStartDate,
    schedule_start_end_date: scheduleStartEndDate,
    schedule_end_start_date: scheduleEndStartDate,
    schedule_end_end_date: scheduleEndEndDate,
    assigner_ids: [action.payload.assignerId]
  };
  const { error, response } = yield fetchEntity(
    fetchMemberEndsBeforeTaskCount,
    api.fetchTasksV2,
    undefined,
    [token, body],
    action
  );
}

export function* fetchOverdueMemberTaskCount(action) {
  const token = yield select(getAuthToken);
  const body = {
    count: true,
    assignee_ids: [action.payload.accountId],
    team_id: action.payload.teamId,
    complete: false,
    ends_before: moment().format('MM/DD/YYYY'),
    assigner_ids: [action.payload.assignerId]
  };
  const { error, response } = yield fetchEntity(
    fetchMemberOverdueTaskCount,
    api.fetchTasksV2,
    undefined,
    [token, body],
    action
  );
}

export function* fetchScheduledMemberTaskCount(action) {
  const token = yield select(getAuthToken);
  const body = {
    count: true,
    assignee_ids: [action.payload.accountId],
    team_id: action.payload.teamId,
    complete: false,
    scheduled_start: true
  };
  const { error, response } = yield fetchEntity(
    fetchMemberScheduledTaskCount,
    api.fetchTasksV2,
    undefined,
    [token, body],
    action
  );
}

export function* fetchUnscheduledMemberTaskCount(action) {
  const token = yield select(getAuthToken);
  const body = {
    count: true,
    assignee_ids: [action.payload.accountId],
    team_id: action.payload.teamId,
    complete: false,
    scheduled_start: false,
    assigner_ids: [action.payload.assignerId]
  };
  const { error, response } = yield fetchEntity(
    fetchMemberUnscheduledTaskCount,
    api.fetchTasksV2,
    undefined,
    [token, body],
    action
  );
}

export function* fetchCompletedMemberTaskCount(action) {
  const token = yield select(getAuthToken);
  const { completedStartDate, completedEndDate } = action.payload;
  const body = {
    count: true,
    assignee_ids: [action.payload.accountId],
    team_id: action.payload.teamId,
    complete: true,
    completed_start_date: completedStartDate,
    completed_end_date: completedEndDate,
    assigner_ids: [action.payload.assignerId]
  };
  const { error, response } = yield fetchEntity(
    fetchMemberCompletedTaskCount,
    api.fetchTasksV2,
    undefined,
    [token, body],
    action
  );
}
