import styled from 'styled-components';
import theme from 'theme';
import {
  calculateProjectColorPreference,
  calculateColorPref
} from 'appUtils/styleUtils';

import Delete24IconUnmasked from 'icons/Delete24IconUnmasked';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import FilterIcon from 'icons/FilterIcon';
import NewBudgetPhaseMilestoneIcon from 'icons/NewBudgetPhaseMilestoneIcon';
import SmallHamburger from 'icons/SmallHamburger';
import { IconContainer } from 'components/GlobalAdd/styles';
import CheckmarkIcon from 'icons/CheckmarkIcon';
import {
  CONDENSED_VIEW_OVERFLOW_TRIANGLE_DEPRECATED,
  CONDENSED_ZOOM_LEVELS
} from 'appConstants/workload';

export const SummaryContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ isCondensedMemberSummary }) =>
    isCondensedMemberSummary ? '100px' : '100%'};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  .hide-on-hover {
    display: inline;
  }
  .show-on-hover {
    display: none;
  }
  &:hover {
    .hide-on-hover {
      display: none;
    }
    .show-on-hover {
      display: inline;
    }
  }
`;
export const SummaryContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
`;
export const SummaryBackground = styled.div`
  position: absolute;
  background: ${theme.colors.colorPaleGray1};
  width: 100%;
  bottom: 0;
`;
export const SummaryCapacity = styled.div`
  color: #a4a4a4;
  font-weight: 600;
`;
export const SummaryCommitted = styled.div`
  ${(props) =>
    props.isVerySmallCondensedView &&
    `
  margin-top: -3px;
  font-size: 12px;
  `}
`;

export const AvailableText = styled.span`
  position: absolute;
  margin-top: ${({ condensedZoomLevel }) =>
    condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL ? '17px' : '22px'};
  font-size: 10px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.colorMediumGray7};
`;

const secondaryMarginTopMap = {
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: '28px',
  [CONDENSED_ZOOM_LEVELS.SMALL]: '26px',
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: '17px'
};

const secondaryTextSizeMap = {
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 'clamp(10px, 1vw, 13px)',
  [CONDENSED_ZOOM_LEVELS.SMALL]: 'clamp(10px, 1vw, 12px)',
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: '10px'
};

export const OffTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PTOValueText = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: ${({ condensedZoomLevel }) =>
    secondaryMarginTopMap[condensedZoomLevel] ?? '32px'};
  font-size: ${({ condensedZoomLevel }) =>
    secondaryTextSizeMap[condensedZoomLevel] ?? 'clamp(10px, 1vw, 13px)'};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  white-space: nowrap;
  text-align: center;
`;

export const StyledSmallHamburger = styled(SmallHamburger)`
  margin-right: 6px;
  height: 10px;
  margin-top: -1px;
  width: 12px;
  path {
    fill: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledBorder = styled.div`
  height: ${({ isProjectView }) => (isProjectView ? '1px' : '2px')};
  position: relative;
  pointer-events: none;
  background: ${({ isWhite }) => (isWhite ? theme.colors.colorPureWhite : '')};
  background: ${({ isProjectView }) =>
    isProjectView ? theme.colors.colorLightGray12 : ''};
`;
export const StyledRef = styled.div`
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: transparent;
  position: absolute;
`;

export const StyledCheckmarkIcon = styled(CheckmarkIcon)``;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  margin: 0 10px;
  width: calc(100% - 6px);
  border: 1px solid ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  width: calc(100% - 22px);
  background: transparent;
  border-bottom: 1px solid ${theme.colors.colorRoyalBlue};
  padding-bottom: 8px;

  &.workload-filter {
    margin: 7px auto !important;
    background: ${(props) =>
      props.isActive ? theme.colors.colorTranslucentGray4 : 'transparent'};
    padding-bottom: unset;
    padding: 3px 3px 3px 6px;
    width: calc(100% - 27px);
    border: 1px solid
      ${(props) =>
        props.isActive
          ? theme.colors.colorRoyalBlue
          : 'transparent'} !important;
    border-bottom: 1px solid
      ${(props) =>
        props.isActive
          ? theme.colors.colorRoyalBlue
          : theme.colors.colorLightGray15} !important;
    ::placeholder {
      color: ${theme.colors.colorLightGray15};
    }
  }
`;
export const StyledPhaseMilestoneIcon = styled(NewBudgetPhaseMilestoneIcon)`
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: 2px;
  margin-top: ${({ marginTop }) => marginTop || '-1px'};
  height: ${(props) => props.height || '12px'};
  width: ${(props) => props.width || '12px'};
`;

export const StyledButtonContainer = styled.div``;

export const StyledFilter = styled.div`
  left: ${({ filterWidth }) => -(filterWidth || 300)}px;
  top: 45px;
  position: absolute;
  transition: 0.2s;
  min-width: ${({ filterWidth }) => `${filterWidth || 225}px`};
  width: ${({ filterWidth }) => `${filterWidth || 225}px`};
  height: calc(100vh - 115px);
  background-color: white;
  z-index: 110;
  box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);
  display: flex;
  flex-direction: column;

  .my-projects-sidebar-container & {
    width: 291px;
    left: -4px;
    padding-left: 4px;
  }

  && .filter-close-icon {
    right: 15px;
  }
`;

export const FilterContainer = styled.div`
  position: absolute;
  height: 800px;
  background-color: transparent;
  left: 0px;
  top: ${({ isOnWorkloadView }) => (isOnWorkloadView ? '-44px' : '180px')};
  z-index: 10000;
  box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);

  ${StyledFilter} {
    transform: ${({ isOpen, filterWidth }) =>
      isOpen ? `translateX(${filterWidth || 300}px)` : 'none'};
  }

  &.reports-filter-selector {
    height: 100vh;
    top: 0;
    z-index: 1000;
    position: fixed;

    ${StyledFilter} {
      height: 100vh;
      top: 0;
      left: -167px;
    }
    &.projects {
      ${StyledFilter} {
        left: -240px;
      }
    }
  }

  &.legacy-adapter {
    height: 100vh;
    top: 0;
    position: fixed;
    z-index: 1000;
    margin-left: 60px;

    ${StyledFilter} {
      height: 100%;
      top: 0;
    }
  }

  &.find-people {
    height: 100%;
    top: 0;
    z-index: 1000;

    ${StyledFilter} {
      height: 100%;
      top: 0;
    }
  }

  &.dashboard-modal-filter-selector {
    height: 100%;

    ${StyledFilter} {
      height: 100%;
    }
  }

  &.workload-planner-filter {
    right: -295px;
    left: unset;
    ${StyledFilter} {
      transform: ${({ isOpen }) => (isOpen ? 'translateX(-300px)' : 'none')};
      right: 54px;
    }
  }

  .my-projects-sidebar-container & {
    height: 100%;
    top: -45px;
    left: 4px;
    ${StyledFilter} {
      height: 100%;
    }
  }
`;

export const StyledFilterIcon = styled(FilterIcon)`
  height: 10px;
  margin-right: 5px;
`;

export const FilterTrigger = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  background: ${theme.colors.colorPureWhite};
  box-shadow: 3px 0 2px -2px rgba(195, 195, 195, 0.5);
  padding-left: 11px;
  ${({ filterStyling }) => filterStyling}
`;

export const FilterBoxContainer = styled.div`
  height: 100%;
  overflow: auto;
  background: ${theme.colors.colorPureWhite};

  ${({ containerStyle }) => containerStyle};
`;

export const InnerDropdownHeader = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: baseline;
  font-size: 16px;
  padding: 6px 0px 6px 10px;
`;
export const CheckBoxDiv = styled.div`
  margin: 8px 10px;
`;

export const CustomCheckBoxContainer = styled.div`
  border-radius: 3px;
  border: 1px solid ${theme.colors.colorLightGray6};
  background: ${({ isDisabled }) =>
    isDisabled ? theme.colors.colorLightGray6 : 'transparent'};
  cursor: ${({ isDisabled }) => (isDisabled ? '' : 'pointer')};
  &:hover {
    border: 1px solid ${theme.colors.colorMediumGray4};
  }
`;

export const CustomCheckBox = styled.div`
  margin: 1px;

  height: 10px;
  width: 10px;
  border-radius: 2px;
  background: ${({ isChecked, isDisabled }) =>
    isDisabled
      ? 'transparent'
      : isChecked
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorPureWhite};
`;

export const StyledListItemContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.colors.colorSemiDarkGray1};
  cursor: pointer;

  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    ${CustomCheckBoxContainer} {
      border-color: ${({ isDisabled }) =>
        isDisabled ? 'transparent' : theme.colors.colorRoyalBlue};
    }
  }
`;

export const MemberName = styled.div`
  margin-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InnerDropdownOption = styled.div`
  font-size: 12px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 4px 0px;
  margin-left: 0px;
  cursor: pointer;
  text-overflow: ellipsis;
  opacity: ${(props) => (props.isArchived || props.isDisabled ? 0.7 : 1)};
  &.member-settings {
    opacity: 1;
    .member-initials {
      opacity: ${(props) => (props.isArchived || props.isDisabled ? 0.7 : 1)};
    }
    .styled-member-name {
      opacity: ${(props) => (props.isArchived ? 0.7 : 1)};
    }
  }
`;

export const AddWorkPlanButton = styled.div`
  padding: 5px 15px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 20px;
  white-space: nowrap;
  margin-right: 10px;
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};

  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorPureWhite};
  }
`;

export const FilterTriggerButton = styled.div`
  color: ${({ isFiltered }) =>
    isFiltered ? theme.colors.colorRoyalBlue : theme.colors.colorSemiDarkGray1};
  ${StyledFilterIcon} {
    path {
      fill: ${({ isFiltered }) =>
        isFiltered
          ? theme.colors.colorRoyalBlue
          : theme.colors.colorSemiDarkGray1};
    }
  }
  cursor: pointer;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 600;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    font-weight: 700;
    ${StyledFilterIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
    ${IconContainer} {
      transform: scale(1.1);
    }
  }
`;

export const StyledContextualMenu = styled.div`
  border: 1px solid ${theme.colors.colorPaleGray5};
  border-radius: 5px;
  position: absolute;
  background: ${theme.colors.colorPureWhite};
  white-space: nowrap;
`;

export const StyledQuestionIconContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  border-radius: 90px;
  margin-left: 2px;
  height: 13px;
`;

export const StyledMenuItem = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray1};
  padding: 4px 12px 4px 4px;
  ${StyledQuestionIconContainer} {
    position: relative;
    top: -9px;
    left: -2px;
  }
  cursor: pointer;

  &.disabled {
    color: grey;
  }

  &:hover:not(.disabled) {
    background: ${({ opensNewTab }) =>
      (opensNewTab && theme.colors.colorPureWhite) ||
      theme.colors.colorPaleGray5};
    color: ${({ opensNewTab }) => opensNewTab && theme.colors.colorRoyalBlue};
    path.work-plans-split-help {
      fill: ${theme.colors.colorLightGray12};
    }
  }
`;

export const StyledDeleteIcon = styled(Delete24IconUnmasked)`
  height: 24px;
  width: 24px;
`;
export const StyledGoToProjectIcon = styled(GoToProjectIcon)`
  width: 24px;
  height: 24px;
`;
export const StyledCutIconContainer = styled.div`
  margin-left: -1.5px;
  margin-right: 7.5px;
`;
export const StyledInsertIconContainer = styled.div`
  margin-left: -3px;
  margin-right: 6px;
`;

export const FilterHeaderContainer = styled.div`
  align-self: flex-end;
  padding: 15px;
  padding-right: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: black;
  width: ${({ filterWidth }) => filterWidth}px;
  ${({ isCrossFieldFiltersOpen }) =>
    isCrossFieldFiltersOpen &&
    `
    padding: 20px 10px 20px 30px;
    font-size: 22px;
  `}
`;
export const SaveButton = styled.button`
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: none;
  background: ${({ isBlue }) =>
    isBlue ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray1};
  color: ${theme.colors.colorPureWhite};
  padding: 4px 6px;
  font-size: 14px;
  border-radius: 3px;

  &:hover {
    filter: brightness(94%);
  }
`;
export const CancelButton = styled(SaveButton)`
  background: none;
  color: ${theme.colors.colorMediumGray5};
  margin-right: 7px;
  font-weight: normal;
  &:hover {
    background: none;
    color: ${theme.colors.colorSemiDarkGray1};
  }
`;

export const SelectorOption = styled.div`
  padding-right: 0px;
  font-size: 12px;
  min-width: 24px;
  text-align: left;
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const SelectedValue = styled.div`
  height: 18px;
  font-size: 12px;
  color: ${({ isBlue }) =>
    isBlue ? theme.colors.colorRoyalBlue : theme.colors.colorLightGray15};
`;

export const OverflowContainer = styled.div`
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${({ size, condensedZoomLevel }) =>
    size === 'small'
      ? CONDENSED_VIEW_OVERFLOW_TRIANGLE_DEPRECATED[
          condensedZoomLevel || 'NORMAL'
        ].small
      : CONDENSED_VIEW_OVERFLOW_TRIANGLE_DEPRECATED[
          condensedZoomLevel || 'NORMAL'
        ].large}px;
  height: ${({ size, condensedZoomLevel }) =>
    size === 'small'
      ? CONDENSED_VIEW_OVERFLOW_TRIANGLE_DEPRECATED[
          condensedZoomLevel || 'NORMAL'
        ].small
      : CONDENSED_VIEW_OVERFLOW_TRIANGLE_DEPRECATED[
          condensedZoomLevel || 'NORMAL'
        ].large}px;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  background: #cb7986;
`;

export const StyledProjectDot = styled.div`
  background: ${calculateProjectColorPreference};
  border-radius: 24px;
  height: 8px;
  flex-basis: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 2px;
`;

export const StyledProjectTitle = styled.div`
  color: ${(props) =>
    props.isSelected
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorSemiDarkGray4};
  font-size: 13px;
  padding: 10px 0px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledProjectDescription = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 11px;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0px;
  margin-left: 12px;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: calc(50% - 12px);
  display: flex;
  cursor: pointer;
  align-items: center;

  svg {
    height: 26px;
    width: 26px;
  }

  &:hover {
    circle {
      fill: ${theme.colors.colorPureWhite};
    }
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }

  .workload-filter + & {
    right: 15px;
    svg {
      height: 23px;
      width: 23px;
    }
  }
`;
export const ArchivedText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  top: 22px;
  left: 38px;
  font-weight: 600;
`;

export const StyledViewBudgetButtonContainer = styled.div`
  height: 0;
  background: transparent;
  z-index: 83;
`;

export const StyledViewBudgetButton = styled.div`
  position: sticky;
  left: 0px;
  z-index: 100;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  font-size: 12px;
  cursor: pointer;
  display: inline-flex;
  border-radius: 100px;
  font-weight: 600;
  padding: 2px 10px;
  background: transparent;
  color: ${theme.colors.colorRoyalBlue};
  &:hover {
    color: ${theme.colors.colorPureWhite};
    background: ${theme.colors.colorRoyalBlue};

    ${StyledPhaseMilestoneIcon} {
      path {
        stroke: ${theme.colors.colorPureWhite};
        fill: transparent;
      }
    }
    ${StyledSmallHamburger} {
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export const SpectrumLegendContainer = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  top: 46px;
  right: 300px;
  z-index: 101;
  padding: 4px;
  font-size: 12px;
`;

export const StyledLegendBox = styled.div`
  height: 18px;
  width: 18px;
  margin: 0px 4px;
  background: ${({ bgColor }) => bgColor};
`;

export const StyledLeftOptions = styled.div`
  position: absolute;
  left: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate(0, calc(-50% + 3px));
`;

export const IconContainerContainer = styled.div`
  align-items: center;
  display: flex;
  flex: auto;
  justify-content: space-between;
  // padding of OpenSideFilterOption - padding of buttons below
  padding: 0 calc(17px - 8px);

  > * {
    border-radius: 5px;
    color: ${theme.colors.colorRoyalBlue};
    font-size: 12px;
    margin: 0;
    padding: 3px 8px;
    width: auto;

    &:hover {
      background-color: ${({ theme }) => theme.colors.colorLightGray19};
      cursor: pointer;
    }

    &.app-cues-email-icon {
      align-items: flex-start;
      span {
        margin-left: 3px;
      }
    }

    &.app-cues-settings-icon {
      span {
        margin-left: 1px;
      }
    }
  }
`;

export const SendButtonContainer = styled.div`
  position: absolute;
  right: 1px;
  cursor: pointer;
  z-index: 10000;
  ${IconContainer} {
    width: auto;
    font-size: 12px;
    color: ${theme.colors.colorRoyalBlue};

    &:hover {
      cursor: pointer;
      filter: brightness(86%);
    }

    &.app-cues-email-icon {
      align-items: flex-start;
      margin-right: 14px;
      span {
        margin-left: 3px;
      }
    }
  }
`;

export const StyledWFHIconContainer = styled.div`
  position: absolute;
  left: 5px;
  bottom: 4px;
  z-index: 1;
  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
    cursor: pointer;
  }
`;

export const StyledTimeEntryContainer = styled.div`
  visibility: ${({ hideTimeEntry }) => (hideTimeEntry ? 'hidden' : 'visible')};
  position: absolute;
  right: 6px;
  top: 4px;
  z-index: 1;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    background: ${theme.colors.colorPureWhite};
  }
`;
export const StyledMenuDivider = styled.div`
  width: 107px;
  height: 1px;
  background: ${theme.colors.colorLightGray6};
  margin: 2px auto;
`;

export const MenuCloseIconContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : '1px')};
  right: 5px;
  cursor: pointer;
`;

export const StyledTaskIconContainer = styled.div`
  margin: 0px 6px;
`;

export const PlannerFilterContainer = styled.div`
  display: flex;
`;

export const PlannerFilterOption = styled.div`
  display: flex;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  border-bottom: ${({ isSelected }) =>
    isSelected
      ? `3px solid ${theme.colors.colorRoyalBlue}`
      : `3px solid transparent`};
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
  cursor: pointer;
  margin-left: 9px;
`;

export const ActiveWorkplanBar = styled.div`
  background: ${calculateColorPref};
  height: 8px;
  opacity: 0.3;
  position: absolute;
  bottom: 2px;
  width: 102%;
`;

export const CollapseAllContainer = styled.div`
  z-index: 100;
  cursor: pointer;
  ${(props) => props.isCollapsed && 'transform: rotate(-90deg);'}
  transition: 0.275s;

  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;

  &:not(.noMargins) {
    margin-top: 12px;
    margin-left: 15px;
    margin-right: -19px;
  }
`;

export const ColoredText = styled.span`
  color: ${theme.colors.colorMediumGray9};
  font-size: ${(props) => (props.isSmallText ? '10px' : '12px')};
`;

export const SummaryChip = styled.div.attrs(({ $chipSize }) => ({
  style: {
    '--chip-size': `${$chipSize}px`,
    '--size': `${Math.sqrt(2 * $chipSize ** 2)}px`
  }
}))`
  position: absolute;
  background: linear-gradient(
    135deg,
    #ca6778 var(--chip-size),
    transparent var(--chip-size)
  );
  left: 0;
  top: 0;
  height: var(--size);
  width: var(--size);
`;
