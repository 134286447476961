import { useState, useRef, useCallback } from 'react';
import { serializeRoleId } from 'components/roles/utils';
import { createPhaseMembers } from 'actionCreators';
import {
  createMemberBudget,
  createMemberRate
} from 'BudgetModule/actionCreators';
import partition from 'lodash/partition';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

const useUnassignedRoleDropdownBoilerplate = ({
  onBulkUnassignedRoleAddDropdownClose,
  onExistingUnassignedRoleDropdownClose,
  createMemberBudgetOnSuccess,
  projectId,
  phaseId
}: {
  projectId: number;
  phaseId?: number;
  onBulkUnassignedRoleAddDropdownClose?: () => void;
  onExistingUnassignedRoleDropdownClose?: () => void;
  createMemberBudgetOnSuccess?: ({ response }) => void;
}) => {
  const dispatch = useAppDispatch();

  const [isUnassignedRoleDropdownOpen, setIsUnassignedRoleDropdownOpen] =
    useState(false);

  const unassignedRolesSelectDropdownRef = useRef(null);

  const [isBulkAddUnassignedRolesOpen, setIsBulkAddUnassignedRolesOpen] =
    useState(false);

  const bulkUnassignedAddDropdownRef = useRef(null);

  /* -------------------------------------------------------------------------- */

  const closeUnassignedRoleDropdown = useCallback(
    () => setIsUnassignedRoleDropdownOpen(false),
    []
  );

  const openUnassignedRoleDropdown = useCallback(
    () => setIsUnassignedRoleDropdownOpen(true),
    []
  );

  const closeBulkUnassignedRoleAddDropdown = useCallback(() => {
    onBulkUnassignedRoleAddDropdownClose &&
      onBulkUnassignedRoleAddDropdownClose();
    setIsBulkAddUnassignedRolesOpen(false);
  }, [onBulkUnassignedRoleAddDropdownClose]);

  const openBulkUnassignedRoleAddDropdown = useCallback(
    () => setIsBulkAddUnassignedRolesOpen(true),
    []
  );

  const handleCloseUnassignedRoleDropdown = useCallback(() => {
    onExistingUnassignedRoleDropdownClose &&
      onExistingUnassignedRoleDropdownClose();
    closeUnassignedRoleDropdown();
  }, [closeUnassignedRoleDropdown, onExistingUnassignedRoleDropdownClose]);

  const createNewUnassignedRole = useCallback(
    (newRole) => {
      const memberBudgets = Array.isArray(newRole)
        ? newRole.map((role) => {
            return {
              phase_id: phaseId,
              position_id: role.id,
              ...(role.rate_id && { rate_id: role.rate_id })
            };
          })
        : [
            {
              phase_id: phaseId,
              position_id: newRole.id
            }
          ];

      dispatch(
        createMemberBudget({
          projectId,
          memberBudgets: memberBudgets,
          onSuccess: createMemberBudgetOnSuccess
        })
      );
      closeBulkUnassignedRoleAddDropdown();
    },
    [
      closeBulkUnassignedRoleAddDropdown,
      createMemberBudgetOnSuccess,
      dispatch,
      phaseId,
      projectId
    ]
  );

  // This action is very specific to BulkPositionsDropdown
  const handleAddUnassignedRoles = useCallback(
    (roles, unassignedRoleSelectedRates = {}) => {
      const partitionExistingUnassignedRole = partition(
        roles,
        (role) => role.memberBudget
      );

      // List of existing roles that already have member budgets
      const existingRoles = partitionExistingUnassignedRole[0];

      // List of unassigned roles that are new
      const newUnassignedRoles = partitionExistingUnassignedRole[1].map(
        (role) => {
          const uniqueRoleId = serializeRoleId(role);
          if (!unassignedRoleSelectedRates[uniqueRoleId]) {
            return role;
          }
          return {
            ...role,
            rate_id: unassignedRoleSelectedRates[uniqueRoleId].id
          };
        }
      );

      /* -------------------------- New Unassigned roles -------------------------- */

      if (newUnassignedRoles.length) {
        // Add NEW unassigned roles to the project
        createNewUnassignedRole(newUnassignedRoles);
      }

      /* ----------------------------- Existing Unassigned Roles ----------------------------- */
      if (existingRoles.length) {
        // Will update the rates of existing member budgets.
        const onSuccess = [
          {
            successAction: () => {
              existingRoles.forEach((position) => {
                const uniqueRoleId = serializeRoleId(position);
                if (!unassignedRoleSelectedRates[uniqueRoleId]) {
                  return;
                }
                const newRate = unassignedRoleSelectedRates[uniqueRoleId];
                createMemberRate({
                  memberBudgetId: position.memberBudget.id,
                  rateId: newRate.id
                });
              });
            },
            selector: (payload, response) => ({ payload, response })
          }
        ];
        // Adds existing unassigned member budget to phase / project
        const memberBudgetIds = existingRoles.map(
          (role) => role.memberBudget.id
        );
        dispatch(
          createPhaseMembers({
            projectId,
            phaseId,
            memberBudgetIds: memberBudgetIds,
            onSuccess
          })
        );
      }
    },
    [createNewUnassignedRole, dispatch, projectId, phaseId]
  );

  return {
    openBulkUnassignedRoleAddDropdown,
    closeBulkUnassignedRoleAddDropdown,
    closeUnassignedRoleDropdown,
    openUnassignedRoleDropdown,
    handleCloseUnassignedRoleDropdown,
    handleAddUnassignedRoles,
    createNewUnassignedRole,
    bulkUnassignedAddDropdownRef,
    unassignedRolesSelectDropdownRef,
    isBulkAddUnassignedRolesOpen,
    isUnassignedRoleDropdownOpen
  };
};

export default useUnassignedRoleDropdownBoilerplate;
