import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SortOptions from './SortOptions';
import { getScheduleViewBy } from 'selectors';
import { setScheduleViewBy } from 'actionCreators';
import { VIEW_BY, VIEW_BY_DISPLAY } from 'appConstants/workload';

const { NONE, PROJECTS, MEMBERS } = VIEW_BY;
const options = [
  // VIEW_BY_DISPLAY[NONE],
  VIEW_BY_DISPLAY[PROJECTS],
  VIEW_BY_DISPLAY[MEMBERS]
];

const ScheduleSortOptions = ({ viewBy, setScheduleViewBy }) => (
  <SortOptions
    viewBy={viewBy}
    setViewBy={setScheduleViewBy}
    options={options}
    left={0}
    dropdownButtonStyle={`border-top: 1px solid rgb(228, 228, 228); padding-bottom: 3px; padding-left: 5px; margin-top: 6px; transform: translateY(28px)`}
    dataTestId="schedule-sort-options"
  />
);

const mapStateToProps = (state) => ({
  viewBy: getScheduleViewBy(state)
});

export default withRouter(
  connect(mapStateToProps, { setScheduleViewBy })(ScheduleSortOptions)
);
