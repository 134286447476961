import React from 'react';
import styled from 'styled-components';
import BillableIcon from 'icons/billable.svg';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BillableIconContainer = styled.img`
  margin-top: 22px;
  margin-left: 4px;
`;

const BillableCell = ({ row }) => {
  const { userActivity } = row.original;
  const billable = userActivity?.display_data?.time_entry?.billable;

  return (
    <StyledContainer>
      {billable && <BillableIconContainer src={BillableIcon} />}
    </StyledContainer>
  );
};

export default BillableCell;
