import React from 'react';
import { ACTION_TYPES } from 'appConstants/actionDescription';
import { generateTimesheetReminderString } from 'appUtils/';

const PersonalEvent = ({ displayData }) =>
  displayData.event_type === 'workload' ? (
    <div>
      <span>View Work Plan</span>
    </div>
  ) : displayData.event_type === 'checkin' ? (
    <div>
      <span>View Check-ins</span>
    </div>
  ) : displayData.event_type === 'time_entry_reminder' ? (
    <div>
      <span>{generateTimesheetReminderString(displayData.missing_time)}</span>
    </div>
  ) : null;

const ManagerEvent = ({ displayData, handleClick }) => (
  <div onClick={handleClick}>
    {displayData.event_type === 'workload' ? (
      <>
        {' '}
        View {displayData.recipient_count} Work Plan
        {displayData.recipient_count === 1 ? '' : 's'}{' '}
      </>
    ) : (
      <> View Check-ins</>
    )}
  </div>
);

const emailActivityBodyComponents = {
  [ACTION_TYPES.SEND_SCHEDULED_EVENT]: PersonalEvent,
  [ACTION_TYPES.FOLLOWING_SCHEDULED_EVENT]: ManagerEvent
};

const EmailActivityBody = (props) => {
  const ActivityBody = emailActivityBodyComponents[props.actionType];
  return (
    <div className="project-activity-body">
      <ActivityBody {...props} />
    </div>
  );
};

export default EmailActivityBody;
