import { createRequestTypes } from 'appUtils';
import moment from 'moment';

export const INITIALIZE_INTEGRATION = createRequestTypes(
  'INITIALIZE_INTEGRATION'
);

// Accounts
export const FETCH_INTEGRATION_ACCOUNTS = createRequestTypes(
  'FETCH_INTEGRATION_ACCOUNTS'
);
export const MAP_ACCOUNTS = createRequestTypes('MAP_ACCOUNTS');
export const BATCH_SELECT_INTEGRATION_ACCOUNTS =
  'BATCH_SELECT_INTEGRATION_ACCOUNTS';
export const CLEAR_BATCH_SELECTED_INTEGRATION_ACCOUNTS =
  'CLEAR_BATCH_SELECTED_INTEGRATION_ACCOUNTS';
export const SET_SELECTED_INTEGRATION_ACCOUNTS =
  'SET_SELECTED_INTEGRATION_ACCOUNTS';
export const SET_INTEGRATION_ACCOUNTS_SEARCH_LOADING =
  'SET_INTEGRATION_ACCOUNTS_SEARCH_LOADING';
export const SET_ACCOUNT_MAPPING_STEP = 'SET_ACCOUNT_MAPPING_STEP';
export const SET_ACCOUNT_ROW_STATE = 'SET_ACCOUNT_ROW_STATE';
export const CLEAR_ACCOUNTS_MAPPING_STEPS = 'CLEAR_ACCOUNTS_MAPPING_STEPS';
export const REMOVE_INTEGRATION_ACCOUNTS = 'REMOVE_INTEGRATION_ACCOUNTS';
export const FETCH_MAPPED_MOSAIC_ACCOUNT_IDS = createRequestTypes(
  'FETCH_MAPPED_MOSAIC_ACCOUNT_IDS'
);
export const ARCHIVE_MAPPED_MOSAIC_ACCOUNT_SUCCESS =
  'ARCHIVE_MAPPED_MOSAIC_ACCOUNT_SUCCESS';

// Activities
export const FETCH_INTEGRATION_ACTIVITIES = createRequestTypes(
  'FETCH_INTEGRATION_ACTIVITIES'
);
export const MAP_ACTIVITIES = createRequestTypes('MAP_ACTIVITIES');
export const BATCH_SELECT_INTEGRATION_ACTIVITIES =
  'BATCH_SELECT_INTEGRATION_ACTIVITIES';
export const CLEAR_BATCH_SELECTED_INTEGRATION_ACTIVITIES =
  'CLEAR_BATCH_SELECTED_INTEGRATION_ACTIVITIES';
export const SET_SELECTED_INTEGRATION_ACTIVITIES =
  'SET_SELECTED_INTEGRATION_ACTIVITIES';
export const SET_INTEGRATION_ACTIVITIES_SEARCH_LOADING =
  'SET_INTEGRATION_ACTIVITIES_SEARCH_LOADING';
export const SET_ACTIVITY_MAPPING_STEP = 'SET_ACTIVITY_MAPPING_STEP';
export const SET_ACTIVITY_ROW_STATE = 'SET_ACTIVITY_ROW_STATE';
export const CLEAR_ACTIVITIES_MAPPING_STEPS = 'CLEAR_ACTIVITIES_MAPPING_STEPS';
export const REMOVE_INTEGRATION_ACTIVITIES = 'REMOVE_INTEGRATION_ACTIVITIES';
export const FETCH_MAPPED_MOSAIC_ACTIVITY_IDS = createRequestTypes(
  'FETCH_MAPPED_MOSAIC_ACTIVITY_IDS'
);
export const SET_INTEGRATION_ACTIVITIES_COUNTS =
  'SET_INTEGRATION_ACTIVITIES_COUNTS';

// Clients
export const FETCH_INTEGRATION_CLIENTS = createRequestTypes(
  'FETCH_INTEGRATION_CLIENTS'
);
export const MAP_CLIENTS = createRequestTypes('MAP_CLIENTS');
export const BATCH_SELECT_INTEGRATION_CLIENTS =
  'BATCH_SELECT_INTEGRATION_CLIENTS';
export const CLEAR_BATCH_SELECTED_INTEGRATION_CLIENTS =
  'CLEAR_BATCH_SELECTED_INTEGRATION_CLIENTS';
export const SET_SELECTED_INTEGRATION_CLIENTS =
  'SET_SELECTED_INTEGRATION_CLIENTS';
export const SET_INTEGRATION_CLIENTS_SEARCH_LOADING =
  'SET_INTEGRATION_CLIENTS_SEARCH_LOADING';
export const SET_CLIENT_MAPPING_STEP = 'SET_CLIENT_MAPPING_STEP';
export const SET_CLIENT_ROW_STATE = 'SET_CLIENT_ROW_STATE';
export const CLEAR_CLIENTS_MAPPING_STEPS = 'CLEAR_CLIENTS_MAPPING_STEPS';
export const REMOVE_INTEGRATION_CLIENTS = 'REMOVE_INTEGRATION_CLIENTS';
export const RESET_INTEGRATION_CLIENTS = 'RESET_INTEGRATION_CLIENTS';
export const FETCH_MAPPED_MOSAIC_CLIENT_IDS = createRequestTypes(
  'FETCH_MAPPED_MOSAIC_CLIENT_IDS'
);
// Projects
export const FETCH_INTEGRATION_PROJECTS = createRequestTypes(
  'FETCH_INTEGRATION_PROJECTS'
);
export const REFETCH_INTEGRATION_PROJECT = createRequestTypes(
  'REFETCH_INTEGRATION_PROJECT'
);
export const MAP_PROJECTS = createRequestTypes('MAP_PROJECTS');
export const CLEAR_MOVED_INTEGRATION_PROJECTS =
  'CLEAR_MOVED_INTEGRATION_PROJECTS';
export const BATCH_SELECT_INTEGRATION_PROJECTS =
  'BATCH_SELECT_INTEGRATION_PROJECTS';
export const CLEAR_BATCH_SELECTED_INTEGRATION_PROJECTS =
  'CLEAR_BATCH_SELECTED_INTEGRATION_PROJECTS';
export const SET_INTEGRATION_PROJECTS_SEARCH_LOADING =
  'SET_INTEGRATION_PROJECTS_SEARCH_LOADING';
export const SET_INTEGRATIONS_MAPPING_STEP = 'SET_INTEGRATIONS_MAPPING_STEP';
export const CLEAR_INTEGRATIONS_MAPPING_STEP =
  'CLEAR_INTEGRATIONS_MAPPING_STEP';
export const CLEAR_PROJECTS_MAPPING_STEPS = 'CLEAR_PROJECTS_MAPPING_STEPS';
export const FETCH_MAPPED_MOSAIC_PROJECT_IDS = createRequestTypes(
  'FETCH_MAPPED_MOSAIC_PROJECT_IDS'
);
export const MAP_ALL_PHASES = createRequestTypes('MAP_ALL_PHASES');

// Phases
export const CLEAR_PHASES_MAPPING_STEPS = 'CLEAR_PHASES_MAPPING_STEPS';
export const MAP_PHASES = createRequestTypes('MAP_PHASES');
export const FETCH_INTEGRATION_PHASES = createRequestTypes(
  'FETCH_INTEGRATION_PHASES'
);
export const SET_LINKING_INTEGRATION_PHASE = 'SET_LINKING_INTEGRATION_PHASE';
export const SET_LINKING_MOSAIC_PHASE = 'SET_LINKING_MOSAIC_PHASE';
export const CLEAR_LINKING_PHASES = 'CLEAR_LINKING_PHASES';
export const FETCH_MAPPED_MOSAIC_PHASE_IDS = createRequestTypes(
  'FETCH_MAPPED_MOSAIC_PHASE_IDS'
);
export const MAP_ALL_ACTIVITIES = createRequestTypes('MAP_ALL_ACTIVITIES');

// Activity Phases
export const MAP_ACTIVITY_PHASES = createRequestTypes('MAP_ACTIVITY_PHASES');
export const SET_LINKING_INTEGRATION_ACTIVITY_PHASE =
  'SET_LINKING_INTEGRATION_ACTIVITY_PHASE';
export const SET_LINKING_MOSAIC_ACTIVITY_PHASE =
  'SET_LINKING_MOSAIC_ACTIVITY_PHASE';
export const CLEAR_LINKING_ACTIVITY_PHASES = 'CLEAR_LINKING_ACTIVITY_PHASES';

// Time entries
export const FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION =
  createRequestTypes('FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION');
export const FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES =
  'FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES';

// global integrations
export const LINK_INTEGRATION = 'LINK_INTEGRATION';
export const DISCONNECT_INTEGRATION = createRequestTypes(
  'DISCONNECT_INTEGRATION'
);
export const DISCONNECT_INTEGRATION_V2 = createRequestTypes(
  'DISCONNECT_INTEGRATION_V2'
);

export const FETCH_ALL_INTEGRATION_DOMAINS = createRequestTypes(
  'FETCH_ALL_INTEGRATION_DOMAINS'
);
export const FETCH_INTEGRATIONS = createRequestTypes('FETCH_INTEGRATIONS');
export const FETCH_INTEGRATION = createRequestTypes('FETCH_INTEGRATION');
export const SAVE_BUTTON_CLICKED = 'SAVE_BUTTON_CLICKED';
export const IS_SYNC_CANCELLING = 'IS_SYNC_CANCELLING';
export const FETCH_INTEGRATION_AUTH_STATUS = createRequestTypes(
  'FETCH_INTEGRATION_AUTH_STATUS'
);
export const PROVISION_INTEGRATION = createRequestTypes(
  'PROVISION_INTEGRATION'
);
export const SET_IS_PROVISIONING = 'SET_IS_PROVISIONING';

export const UPDATE_INTEGRATION_CONFIG = createRequestTypes(
  'UPDATE_INTEGRATION_CONFIG'
);

export const FETCH_MAPPED_MOSAIC_ENTITY_IDS = 'FETCH_MAPPED_MOSAIC_ENTITY_IDS';
export const FETCH_INTEGRATION_ENTITY_STATS = createRequestTypes(
  'FETCH_INTEGRATION_ENTITY_STATS'
);

export const FETCH_INTEGRATION_SETTINGS_SCHEMA = createRequestTypes(
  'FETCH_INTEGRATION_SETTINGS_SCHEMA'
);

export const FETCH_AUTO_LINK_PHASES = createRequestTypes(
  'FETCH_AUTO_LINK_PHASES'
);

export const FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME = createRequestTypes(
  'FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME'
);

export const FETCH_SYNC_LOG = createRequestTypes('FETCH_SYNC_LOG');

export const UPDATE_TRAY_CONFIG_WIZARD = 'UPDATE_TRAY_CONFIG_WIZARD';

// MAPPING STATUSES
export const MAPPING_STATUS = {
  ACTIVE: 'active',
  DO_NOT_LINK: 'do_not_link',
  CREATE_NEW_ON_MOSAIC: 'CREATE_NEW_ON_MOSAIC',
  CREATE_NEW_ON_TARGET: 'CREATE_NEW_ON_TARGET',
  UPDATE_ON_MOSAIC: 'UPDATE_ON_MOSAIC',
  UPDATE_ON_TARGET: 'UPDATE_ON_TARGET',
  // for different datatype, the name will be STATUS+"_"+Datatype
  DRAFT: 'draft',
  NULL: null,
  // FE only
  PENDING: 'PENDING' // ie. waiting for BE response
} as const;

export const ACTIVE_MAPPING_STATUSES = [
  MAPPING_STATUS.UPDATE_ON_MOSAIC,
  MAPPING_STATUS.UPDATE_ON_TARGET,
  MAPPING_STATUS.CREATE_NEW_ON_TARGET,
  MAPPING_STATUS.ACTIVE
] as const;

export const SYNCING_MAPPING_STATUSES = [
  MAPPING_STATUS.UPDATE_ON_MOSAIC,
  MAPPING_STATUS.UPDATE_ON_TARGET,
  MAPPING_STATUS.CREATE_NEW_ON_TARGET,
  MAPPING_STATUS.CREATE_NEW_ON_MOSAIC
] as const;

export const MAPPED_MAPPING_STATUSES = [
  ...ACTIVE_MAPPING_STATUSES,
  MAPPING_STATUS.CREATE_NEW_ON_MOSAIC
];

export const DATA_TYPES = {
  TIME_ENTRIES: 'time_entry',
  PROJECTS: 'project',
  PHASES: 'phase',
  CLIENTS: 'client',
  EMPLOYEES: 'employee',
  ACTIVITIES: 'activity',
  ACTIVITY_PHASE: 'activity_phase',
  // mosaic only
  ACCOUNTS: 'account'
} as const;

// Actions / mapping steps
export const MODIFY = 'Modify';
export const LINK_WITH_EXISTING = 'Link w/Existing';
export const DO_NOT_LINK = 'Do Not Link';
export const UNLINK = 'Unlink';
export const ARCHIVE = 'Archive On Mosaic';
export const UNARCHIVE = 'Unarchive';
export const IMPORT = 'Import as New';
export const LINK_ALL_PHASES = 'LINK_ALL_PHASES';
export const CREATE = 'Create';
export const SAVING = 'SAVING';
export const CHANGE_DATATYPE_TO_PROJECT = 'Change to Project';
export const IMPORT_ALL_PHASES = 'Import all Phases';
export const IMPORT_ALL_ACTIVITIES = 'Import all Work Categories';
export const DELETE_ON_MOSAIC = 'Delete on Mosaic';
export const CREATE_PHASE = 'Create a New Phase on Mosaic';
export const CREATE_MAIN_PHASE = 'Make Main Project a Phase';
export const DELETE_MAIN_PHASE = 'Main Project is not a Phase';
export const GO_TO_PROJECT = 'Go to Project';

export const MAPPING_STATUS_SORT_ORDER = [
  MAPPING_STATUS.ACTIVE,
  MAPPING_STATUS.UPDATE_ON_MOSAIC,
  MAPPING_STATUS.UPDATE_ON_TARGET,
  MAPPING_STATUS.CREATE_NEW_ON_MOSAIC,
  MAPPING_STATUS.CREATE_NEW_ON_TARGET,
  MAPPING_STATUS.PENDING,
  MAPPING_STATUS.NULL,
  SAVING
] as const;

export const MAPPING_STATUS_SORT_RANKS = MAPPING_STATUS_SORT_ORDER.reduce(
  (acc, cur, index) => {
    acc[cur ?? 'null'] = index;
    return acc;
  },
  {}
);

// Members list sections
export const MEMBERS_LISTS = {
  FROM_INTEGRATION: 'fromIntegration',
  LINKED_TO_MOSAIC: 'linkedToMosaic',
  LINKED_AND_ARCHIVED: 'linkedAndArchived',
  DO_NOT_LINK: 'doNotLink'
} as const;

export const INTEGRATION_LISTS_ORDER = Object.values(MEMBERS_LISTS);
export const INTEGRATION_LISTS_ORDER_RANKS = INTEGRATION_LISTS_ORDER.reduce(
  (acc, cur, index) => {
    acc[cur] = index;
    return acc;
  },
  {}
);

export const SELECT_ALL_SIZE = 20;
export const MAX_NUM_IMPORT = 50;
export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FILTER_START = moment()
  .subtract(1, 'y')
  .format('YYYY-MM-DD');
export const DEFAULT_DATE_FILTER_END = moment().format('YYYY-MM-DD');
export const DEFAULT_BOARD_NAME = 'Import';

export * from './integrations';
