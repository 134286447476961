import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchMembersUtilizationReport } from 'UtilizationModule/actionCreators';
import { GENERATE_UTILIZATION_RANGE } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import MiniUtilizationTable from './MiniUtilizationTable';
import { useAccountCapacityTotals } from 'DashboardModule/hooks/useAccountCapacityTotals';

const MiniUtilizationTableWidget = ({
  activeFilter,
  filterId,
  widgetConfig,
  teamId,
  fetchMembersUtilizationReport,
  includeRemainingCapacity,
  useCapacityAsDenominator
}) => {
  const fetchParams = useMemo(
    () => ({
      account_ids:
        activeFilter.account_ids?.slice(0, widgetConfig.limits.account_ids) ||
        [],
      filterStateId: filterId,
      team_id: teamId,
      ...GENERATE_UTILIZATION_RANGE[
        activeFilter.custom?.range !== undefined
          ? activeFilter.custom.range
          : ZOOM_LEVELS.WEEK
      ]?.({
        start: activeFilter.custom?.start_date,
        end: activeFilter.custom?.end_date
      })
    }),
    [activeFilter, filterId, teamId, widgetConfig.limits.account_ids]
  );

  const accountCapacityTotals = useAccountCapacityTotals({
    accountIds: activeFilter.account_ids || [],
    fetchParams
  });

  useEffect(() => {
    if (fetchParams.account_ids.length && teamId) {
      fetchMembersUtilizationReport({
        ...fetchParams,
        initial: true
      });
    }
  }, [fetchMembersUtilizationReport, fetchParams, teamId]);

  return (
    <DynamicModuleLoader
      modules={[getUtilizationModule(), getCapacitiesModule()]}
    >
      <div
        className="dashboard-widget scrollbar2"
        style={{
          height: '200px',
          overflowY: 'auto',
          marginTop: '10px'
        }}
      >
        <MiniUtilizationTable
          hasData={!!fetchParams.account_ids.length}
          filterStateId={filterId}
          isDashboardWidget
          accountCapacityTotals={accountCapacityTotals}
          includeRemainingCapacity={includeRemainingCapacity}
          useCapacityAsDenominator={useCapacityAsDenominator}
        />
      </div>
    </DynamicModuleLoader>
  );
};

const makeMapStateToProps = () => {
  const includeRemainingCapacity = true;
  const useCapacityAsDenominator = true;
  const mapStateToProps = (state, ownProps) => ({
    includeRemainingCapacity,
    useCapacityAsDenominator
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchMembersUtilizationReport
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(React.memo(MiniUtilizationTableWidget));
