import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
import produce from 'immer';
const byId = (item) => item.id;

const initialState = {
  taskGroupOrders: {},
  taskGroups: {},
  newGroupId: null,
  queuedFetches: {}
};
// these functions only work without return values if we're using immer because it modifies the original state object
const filterOutTaskIds = (taskIds, taskGroups) => {
  const keyedTaskIds = keyBy(taskIds);
  Object.values(taskGroups).forEach((taskGroup) => {
    taskGroup.task_order = taskGroup.task_order.filter(
      (taskId) =>
        !keyedTaskIds[taskId] &&
        taskId !== 'completed' &&
        taskId !== 'incomplete'
    );
  });
};
const markTasksAs = (taskIds, taskGroups, updateType, shouldRemove) => {
  Object.values(taskGroups).forEach((taskGroup) => {
    taskIds.map((taskId) => {
      const index = taskGroup.task_order.findIndex((id) => id === taskId);
      if (index !== -1) {
        if (updateType === 'complete') {
          if (shouldRemove) {
            taskGroup.task_order[index] = 'completed';
          } else {
            taskGroup.task_order.splice(index, 1);
            taskGroup.current_page >= taskGroup.total_pages &&
              taskGroup.task_order.push(taskId);
          }
        }
        if (updateType === 'incomplete') {
          if (shouldRemove) {
            taskGroup.task_order[index] = 'incomplete';
          } else {
            taskGroup.task_order.splice(index, 1);
            taskGroup.task_order.unshift(taskId);
          }
        }
      }
    });
  });
};

const moveTaskToTop = (taskId, taskGroups) => {
  Object.values(taskGroups).forEach((taskGroup) => {
    if (taskGroup.task_order.includes(taskId)) {
      taskGroup.task_order = taskGroup.task_order.filter((id) => id !== taskId);
      taskGroup.task_order.unshift(taskId);
    }
  });
};
export default (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.FETCH_TASK_GROUPS.SUCCESS: {
        const {
          response: { task_groups }
        } = action.payload;
        task_groups.forEach((taskGroup) => {
          draft.taskGroups[taskGroup.id] = {
            ...taskGroup,
            task_order: draft.taskGroups[taskGroup.id]?.task_order ?? []
          };
        });
        break;
      }
      case constants.FETCH_PROJECT_TASK_GROUPS.SUCCESS: {
        const {
          response: { task_groups, task_group_order },
          projectId
        } = action.payload;
        draft.taskGroupOrders[projectId] = task_group_order;
        const theGroups = task_groups.map((group) => {
          return {
            ...group,
            current_page: 0,
            task_order: draft.taskGroups[group.id]?.task_order ?? [],
            collapsed: draft.taskGroups[group.id]?.collapsed
          };
        });
        draft.taskGroups = { ...draft.taskGroups, ...keyBy(theGroups, byId) };
        break;
      }
      case constants.CREATE_PROJECT_TASK_GROUP.SUCCESS: {
        const {
          response,
          requestPayload: [token, projectId, name, index = 0]
        } = action.payload;
        draft.newGroupId = response.id;
        if (
          index === 0 &&
          draft.taskGroupOrders[projectId][0] === response.id
        ) {
          draft.taskGroups[response.id].is_default = false;
          break; // backend actually updated existing default group, do not insert id
        }
        draft.taskGroups[response.id] = {
          ...response,
          current_page: 0,
          task_order: []
        };
        draft.taskGroupOrders[projectId].splice(index, 0, response.id);
        break;
      }
      case constants.DELETE_PROJECT_TASK_GROUP.TRIGGER: {
        draft.newGroupId = null;
        const { projectId, id } = action.payload;
        draft.taskGroupOrders[projectId] = draft.taskGroupOrders[
          projectId
        ].filter((groupId) => groupId !== id);
        delete draft.taskGroups[id];

        break;
      }
      case constants.TOGGLE_TASK_GROUP: {
        const { groupId, collapsed } = action.payload;
        draft.taskGroups[groupId].collapsed = collapsed;
        if (collapsed) {
          draft.taskGroups[groupId].isFetching = false;
          delete draft.queuedFetches[groupId];
        }

        break;
      }
      case constants.TOGGLE_TASK_GROUP_ALL: {
        const { collapsed } = action.payload;
        Object.values(draft.taskGroups).forEach((taskGroup) => {
          taskGroup.collapsed = collapsed;
          if (collapsed) {
            taskGroup.isFetching = false;
          }
        });
        if (collapsed) {
          draft.queuedFetches = {};
        }

        break;
      }
      case constants.UPDATE_PROJECT_TASK_GROUP.TRIGGER: {
        draft.newGroupId = null;
        const { id } = action.payload;
        draft.taskGroups[id] = {
          ...draft.taskGroups[id],
          ...action.payload
        };
        break;
      }
      case constants.UPDATE_PROJECT_TASK_GROUP.SUCCESS: {
        const { response } = action.payload;
        const { id } = response;
        draft.taskGroups[id] = {
          ...response,
          task_order: draft.taskGroups[id].task_order // keep the original task order
        };
        break;
      }
      case constants.REORDER_PROJECT_TASK_GROUPS.TRIGGER: {
        const { projectId, order } = action.payload;
        draft.taskGroupOrders[projectId] = order;
        break;
      }
      case constants.FETCH_PROJECT_TASK_GROUP_TASKS.REQUEST: {
        const { taskGroups } = draft;
        const { request } = action.payload;
        const [groupId] = request.options.body.task_group_ids;
        const group = taskGroups[groupId];

        if (
          group &&
          group.task_order &&
          (group.task_order.length === 0 ||
            group.task_order.length <= group.project_tasks_count - 1) &&
          !group.collapsed
        ) {
          draft.queuedFetches[groupId] = true;
          group.isFetching = true;
          break;
        }
        break;
      }
      case constants.ADD_TASK_GROUP_TO_FETCH_QUEUE:
        {
          const { groupId } = action.payload;
          draft.queuedFetches[groupId] = true;
        }
        break;
      case constants.REMOVE_TASK_GROUP_FROM_FETCH_QUEUE: {
        const { groupId } = action.payload;
        delete draft.queuedFetches[groupId];
        break;
      }
      case constants.FETCH_PROJECT_TASK_GROUP_TASKS.SUCCESS: {
        const {
          meta: {
            action: {
              payload: { groupIds }
            }
          },
          data: { tasks, current_page, total_pages }
        } = action.payload;
        /*
         * if there are no tasks in the response (because none meet the filter/sort criteria), the logic `tasks.map` of clearing "isFetching" etc. will never be called,
         * leading to what appears to be infinite loading state. if there is more than one group in the groupIds array, the current/total page count will be
         * for that request & therefore be inaccurate, preventing us from relying on those counts to determine when we need to stop fetching the current
         *  group and begin fetching the next one
         */
        if (groupIds && groupIds[0] && groupIds.length === 1) {
          draft.taskGroups[groupIds[0]].isFetching = false;
          draft.taskGroups[groupIds[0]].total_pages = total_pages;
          draft.taskGroups[groupIds[0]].current_page = current_page;
        }

        tasks.map((task) => {
          const currentGroup = draft.taskGroups[task.task_group_id];
          if (currentGroup) {
            currentGroup.current_page = current_page;
            currentGroup.total_pages = total_pages;
            currentGroup.task_order.push(task.id);
            currentGroup.isFetching = false;
            if (currentGroup.current_page >= currentGroup.total_pages) {
              delete draft.queuedFetches[currentGroup.id];
            }
          }
        });
        Object.values(draft.taskGroups).forEach((taskGroup) => {
          taskGroup.task_order = [...new Set(taskGroup.task_order)];
        });
        break;
      }
      case constants.CREATE_TASK_FROM_HOME.REQUEST: {
        const { id, task_group_id } = action.payload.body;
        const taskGroup = draft.taskGroups[task_group_id];
        if (taskGroup) {
          taskGroup.task_order.unshift(id);
          taskGroup.tempTaskId = id;
        }
        break;
      }
      case constants.CREATE_TASK_FROM_HOME.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const taskBody = requestPayload[1];
        const groupId = taskBody.task_group_id;
        if (groupId) {
          const taskGroup = draft.taskGroups[groupId];
          if (!taskGroup) {
            break;
          }
          if (taskGroup.tempTaskId) {
            taskGroup.task_order = taskGroup.task_order.map((id) =>
              id === taskGroup.tempTaskId ? response.task.id : id
            );
          } else {
            taskGroup.task_order = [
              response.task.id,
              ...draft.taskGroups[groupId].task_order
            ];
          }
        }
        break;
      }
      case constants.WS_TASK: {
        const { added, deleted, origin, userId, reorder, ...task } =
          action.payload;
        const taskGroup = draft.taskGroups[task.task_group_id];
        if (origin === userId || !taskGroup) {
          break;
        }
        if (deleted) {
          taskGroup.task_order = taskGroup.task_order.filter(
            (taskId) => taskId !== task.id
          );
        }
        if (added && !taskGroup.task_order.includes(task.id)) {
          taskGroup.task_order.unshift(task.id);
          if (taskGroup.tempTaskId) {
            taskGroup.task_order = taskGroup.task_order.filter(
              (id) => id !== taskGroup.tempTaskId
            );
            taskGroup.tempTaskId = null;
          }
        }
        break;
      }
      case constants.BATCH_DELETE_TASKS.TRIGGER: {
        const { body, updateType } = action.payload;
        if (updateType === 'delete') {
          filterOutTaskIds(
            body.taskIds,
            draft.taskGroups,
            draft.taskGroupOrders
          );
        }
        break;
      }
      case constants.UPDATE_TASK_HOME_POSITION: {
        const { source, destination } = action.payload;
        const { taskGroups } = draft;
        if (
          taskGroups[source.droppableId] &&
          taskGroups[destination.droppableId]
        ) {
          const id = taskGroups[source.droppableId].task_order.splice(
            source.index,
            1
          );
          taskGroups[destination.droppableId].task_order.splice(
            destination.index,
            0,
            id
          );
        }
        break;
      }
      case constants.REMOVE_TASK_FROM_GROUP: {
        const { taskId, taskGroupId, groupType } = action.payload;
        if (groupType !== 'taskGroup') {
          return;
        }
        const { taskGroups } = draft;
        const taskGroup = taskGroups[taskGroupId];
        taskGroup.task_order = taskGroup.task_order.filter(
          (id) => id != taskId
        );
        break;
      }
      case constants.ADD_TASK_TO_GROUP: {
        const { taskId, taskGroupId, index, groupType } = action.payload;
        if (groupType !== 'taskGroup') {
          return;
        }
        const { taskGroups } = draft;
        const taskGroup = taskGroups[taskGroupId];
        taskGroup.task_order.splice(index, 0, taskId);
        break;
      }
      case constants.HANDLE_TASK_GROUPS_TASK_COMPLETION_TOGGLE: {
        const { taskIds, isCompleting, currentFilter } = action.payload;
        const { state: filterState } = currentFilter;
        const updateType = isCompleting ? 'complete' : 'incomplete';
        const shouldRemove =
          (filterState === 'completed' && !isCompleting) ||
          (filterState === 'incomplete' && isCompleting);

        markTasksAs(taskIds, draft.taskGroups, updateType, shouldRemove);
        break;
      }

      case constants.CLEAR_REMOVED_TASKS: {
        filterOutTaskIds([], draft.taskGroups, draft.taskGroupOrders);
        break;
      }

      case constants.UPDATE_TASKS_ATTRIBUTES.TRIGGER: {
        const {
          body: { task_group_id, task_ids },
          options
        } = action.payload;
        const { shouldRemoveTask } = options || {}; // sometimes options is not provided
        if (!task_group_id && !shouldRemoveTask) break; // homeTasks reducer handles other task update logic

        const reversedTaskIds = [...task_ids].reverse();

        const taskIds = keyBy(reversedTaskIds); // reversed task_ids because they are sent in reverse order from the action creator.

        Object.values(draft.taskGroups).forEach((taskGroup) => {
          taskGroup.task_order = taskGroup.task_order.filter(
            (id) => !taskIds[id]
          );
        });

        if (draft.taskGroups[task_group_id]) {
          draft.taskGroups[task_group_id].task_order = [
            ...reversedTaskIds,
            ...draft.taskGroups[task_group_id].task_order
          ];
        }
        break;
      }
      case constants.BATCH_MOVE_TASKS_TO_PROJECT.SUCCESS: {
        const { taskIds } = action.payload.requestPayload[2];
        taskIds.forEach((taskId) => moveTaskToTop(taskId, draft.taskGroups));
        break;
      }
      case constants.FLUSH_TASK_LIST_STORE: {
        Object.values(draft.taskGroups).forEach((taskGroup) => {
          taskGroup.task_order = [];
          taskGroup.current_page = 0;
          taskGroup.total_pages = undefined;
          taskGroup.isFetching = false;
        });
        draft.queuedFetches = {};
        break;
      }
    }
  });
};
