import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ActivityFeed, BodyColor } from 'views';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import AddEditNoteContainer from 'NoteModule/components/AddEditNoteContainer';
import flatten from 'lodash/flatten';
import { getNotesModule } from 'NoteModule/package/noteModule';
import {
  closeAndClearTaskViewModal,
  fetchNotifications,
  updateNotificationAsRead,
  updateNotificationsAsOld,
  setEditTaskId,
  setSelectedTask,
  markAllNotificationsRead,
  flushSelectedHomeTask,
  handleErrorMessage,
  setShowUpdatesMovedMessage
} from 'actionCreators';
import WAYPOINT_INTS from 'appConstants/waypointInts';
import { getAuth, getMe } from 'selectors';
import WorkloadEventsModal from 'views/projectPlanner/WorkloadEventsModal/WorkloadEventsModal';
import { GENERIC_ACTION } from 'appConstants';

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;

  ${({ isOnHomeView }) =>
    isOnHomeView
      ? ` width: 100%;
  max-width: 1241px;
  `
      : `width: 100vw;`}
`;

class ActivityFeedContainer extends React.Component {
  componentDidMount() {
    const { fetchNotifications, me, isLoading, showUpdatesMovedMessage } =
      this.props;

    if (me?.id && isLoading === null) {
      fetchNotifications({
        offset: 0,
        limit: WAYPOINT_INTS.notifications.baseLimit,
        user_id: me.id
      });
    }
    if (showUpdatesMovedMessage) {
      this.props.handleErrorMessage({
        type: GENERIC_ACTION,
        errorMessage:
          'Updates have moved to "Notifications" on the left menu of the Home Screen.'
      });
      this.props.setShowUpdatesMovedMessage({ showMessage: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchNotifications, me, isLoading } = this.props;
    if (prevProps.me?.id !== me?.id && !isLoading) {
      fetchNotifications({
        offset: 0,
        limit: WAYPOINT_INTS.notifications.baseLimit,
        user_id: me.id
      });
    }
  }

  componentWillUnmount() {
    const { updateNotificationsAsOld, notificationIds } = this.props;
    updateNotificationsAsOld({ notificationIds, isOld: true });
  }

  lazyLoad = (waypointEvent) => {
    const {
      allNotifications,
      fetchNotifications,
      me,
      isLoading,
      notificationsEndReached
    } = this.props;
    const notificationCount = flatten(
      allNotifications.map((notificationDayObj) =>
        notificationDayObj.notifications.map((notification) => notification.id)
      )
    ).length;
    if (!isLoading && !notificationsEndReached) {
      fetchNotifications({
        offset: notificationCount,
        limit: WAYPOINT_INTS.notifications.baseLimit,
        user_id: me.id
      });
    }
  };

  markAsRead = (activityItemId, isRead) => {
    const { updateNotificationAsRead } = this.props;
    return () => updateNotificationAsRead({ id: activityItemId, isRead });
  };

  markAllRead = () => {
    this.props.markAllNotificationsRead();
  };

  closeTaskModal = () => {
    const {
      setEditTaskId,
      closeAndClearTaskViewModal,
      setSelectedTask,
      flushSelectedHomeTask
    } = this.props;

    closeAndClearTaskViewModal();
    flushSelectedHomeTask();
    setSelectedTask(null);
    setEditTaskId(null);
  };

  render() {
    const { isLoading, allNotifications, children, isOnHomeView } = this.props;
    return (
      <ContainerDiv isOnHomeView={isOnHomeView}>
        <BodyColor isGray />
        <div
          className="dummy-activity-animation-container"
          id="activity-animation"
        />
        <ActivityFeed
          allNotifications={allNotifications}
          isLoading={isLoading}
          markAsRead={this.markAsRead}
          lazyLoad={this.lazyLoad}
          markAllRead={this.markAllRead}
          showHeader
          isOnHomeView={isOnHomeView}
        />
        {children}
        <DynamicModuleLoader modules={[getNotesModule()]}>
          <AddEditNoteContainer />
        </DynamicModuleLoader>
        <WorkloadEventsModal selectionDisabled />
      </ContainerDiv>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  me: getMe(state),
  auth: getAuth(state),
  path: ownProps.location.pathname,
  taskDetailModalOpen: state.taskDetail.taskDetailModalOpen,
  allNotifications: state.notifications.allNotifications,
  notificationsEndReached: state.notifications.notificationsEndReached,
  isLoading: state.notifications.isLoading,
  notificationIds: state.notifications.loadedNotificationIds,
  showUpdatesMovedMessage: state.notifications.showUpdatesMovedMessage
});

const mapDispatchToProps = {
  closeAndClearTaskViewModal,
  fetchNotifications,
  updateNotificationAsRead,
  updateNotificationsAsOld,
  setEditTaskId,
  setSelectedTask,
  markAllNotificationsRead,
  flushSelectedHomeTask,
  handleErrorMessage,
  setShowUpdatesMovedMessage
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivityFeedContainer)
);
