import { createSelector, Selector } from '@reduxjs/toolkit';
import { ReducerName, RootState } from 'reduxInfra/shared';
import { PhasesState } from 'ProjectsModule/reducers/types';
import { initialState as phasesInitialState } from 'reducers/phases';
import { Phase, PhaseId } from 'ProjectsModule/phases/models/phase';
import { getIsPhaseLikeDefault } from 'ProjectsModule/utils/phaseDisplay';
import { ProjectId, ProjectPhases } from 'ProjectsModule/models/project';
import countBy from 'lodash/countBy';
import { ProjectPhasesWithArchived } from 'ProjectsModule/selectors/types';
import { PhaseWithDefault } from './types';

const serializeMainPhase = (
  phase: Phase,
  numProjectPhases: number
): PhaseWithDefault =>
  numProjectPhases <= 1
    ? {
        ...phase,
        is_main_like_default: true
      }
    : {
        ...phase,
        name: 'Main Project Phase'
      };

export const getPhasesState: Selector<RootState, PhasesState> = (state) =>
  state[ReducerName.Phases] || phasesInitialState;

export const getPhaseStatePhaseHash = createSelector(
  getPhasesState,
  (state): Record<PhaseId, Phase> => state.phaseHash
);

export const getFlatPhasesAndMilestonesHash = createSelector(
  getPhaseStatePhaseHash,
  (phaseHash): Record<PhaseId, PhaseWithDefault> => {
    const phases = Object.values(phaseHash);
    const budgetPhases = phases.filter((phase) => phase.is_budget);
    const numBudgetPhasesByProject = countBy(
      Object.values(budgetPhases),
      (phase) => phase.project_id
    );

    const computedPhaseHash = Object.fromEntries(
      phases
        .map<PhaseWithDefault>((phase) =>
          phase.is_main
            ? serializeMainPhase(
                phase,
                numBudgetPhasesByProject[phase.project_id] ?? 0
              )
            : phase
        )
        .map((phase) =>
          getIsPhaseLikeDefault(phase)
            ? { ...phase, is_like_default: true }
            : phase
        )
        .map((phase) => [phase.id, phase])
    );

    return computedPhaseHash;
  }
);

export const getPhasesAndMilestonesByProjectHash = createSelector(
  getPhasesState,
  (state): Record<ProjectId, ProjectPhases> => state.phasesByProject || {}
);

export const getFlatPhasesAndMilestones = createSelector(
  getFlatPhasesAndMilestonesHash,
  (phasesAndMilestonesHash): Array<PhaseWithDefault> =>
    Object.values(phasesAndMilestonesHash)
);

export const getFlatPhases = createSelector(
  getFlatPhasesAndMilestones,
  (phasesAndMilestones): Array<PhaseWithDefault> =>
    phasesAndMilestones.filter((phase) => phase.is_budget)
);

export const getFlatPhasesHash = createSelector(
  getFlatPhases,
  (phases): Record<PhaseId, PhaseWithDefault> => {
    const numPhasesByProjectId = countBy(
      Object.values(phases),
      (phase) => phase.project_id
    );

    const phasesHash = Object.fromEntries(
      phases
        .map((phase) =>
          phase.is_main
            ? serializeMainPhase(
                phase,
                numPhasesByProjectId[phase.project_id] ?? 0
              )
            : phase
        )
        .map((phase) =>
          getIsPhaseLikeDefault(phase)
            ? { ...phase, is_like_default: true }
            : phase
        )
        .map((phase) => [phase.id, phase])
    );

    return phasesHash;
  }
);

export const getPhasesByProjectHash = createSelector(
  getPhasesAndMilestonesByProjectHash,
  getFlatPhasesHash,
  (phasesAndMilestones, phaseHash) => {
    const filtered = Object.values(
      phasesAndMilestones
    ).map<ProjectPhasesWithArchived>((project) => {
      const activePhases = project.phases
        .filter((phase) => phase.is_budget)
        .map<PhaseWithDefault>((phase) => ({
          ...phase,
          ...phaseHash[phase.id]
        }));

      const activePhaseHash = Object.fromEntries(
        activePhases.map((phase) => [phase.id, phase])
      );

      const activePhaseOrders = project.phase_orders.filter(
        (id) => id in activePhaseHash
      );

      return {
        ...project,
        phases: activePhases,
        phase_orders: activePhaseOrders,
        archivedPhaseIds: activePhases
          .filter((phase) => phase.archived)
          .map((phase) => phase.id)
      };
    });

    return Object.fromEntries(filtered.map((phase) => [phase.id, phase]));
  }
);

export const getPhasesByProjectIdsOrder = createSelector(
  getPhasesState,
  (phasesState): Array<ProjectId> => phasesState.phasesByProjectIdsOrder
);

export const getPhasesByProject = createSelector(
  getPhasesByProjectIdsOrder,
  getPhasesByProjectHash,
  (order, hash): Array<ProjectPhasesWithArchived> =>
    order
      .map((id) => hash[id])
      .filter((project): project is ProjectPhasesWithArchived =>
        Boolean(project)
      )
);
