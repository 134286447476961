import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { connect } from 'react-redux';
import theme from 'theme';
import {
  openFlaggedTasksModal,
  triggerFetchInitialTasks
} from 'actionCreators';
import FlagIcon from 'icons/FlagIcon';
import CalendarIcon from 'icons/CalendarIcon';
import {
  getCurrentFilter,
  getPastTasksLoading,
  getPastDueCount,
  getPastScheduledCount
} from 'selectors';
import { isToday } from 'date-fns';

const {
  colors: { colorDeleteRed, colorMediumGray4, colorCalendarOrange }
} = theme;

const RedFlagIcon = styled(FlagIcon)`
  width: 10px;
  fill: ${colorDeleteRed};
`;
const GreyFlagIcon = styled(FlagIcon)`
  width: 10px;
  fill: ${colorMediumGray4};
`;
const WrappedCalendarIcon = styled(CalendarIcon)`
  color: ${(props) => props.color};
  width: 14px;
  height: 14px;
`;

const OrangeFlagIcon = styled(FlagIcon)`
  width: 10px;
  fill: ${colorCalendarOrange};
`;
const ColoredText = styled.div`
  padding-left: 4px;
  color: ${(props) => props.color};
`;
const Wrapper = styled.div`
  position: ${(props) => (props.isOnTaskListheader ? 'absolute' : 'relative')};
  right: 69px;
  display: none;
  align-items: flex-start;
  margin-right: ${(props) => (props.isOnTaskListheader ? '0' : '30px')};
  .date {
    font-size: 14px;
    padding-left: 6px;
    position: absolute;
  }

  .flags {
    display: flex;
    position: relative;
    top: 0px;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
    cursor: ${(props) => (props.noHover ? 'default' : 'pointer')};
    min-width: 36px;
    margin-bottom: 0px;

    &:hover {
      background: ${(props) => (props.noHover ? '' : '#ebebeb')};
    }
  }
`;

const renderTooltip = (taskNumber, copy) =>
  `Click to view ${taskNumber} task${taskNumber === 1 ? '' : 's'} ${copy}`;

class TaskListFlags extends React.Component {
  onDueClick = () => {
    const { openFlaggedTasksModal, dateName, dueTasks } = this.props;
    const numTasksDue = dueTasks && dueTasks.length;
    const dueDate = numTasksDue && dueTasks[0].due_at;
    openFlaggedTasksModal({
      selectedFlag: `Due ${dateName}`,
      selectedDate: dueDate
    });
  };

  onPastDueClick = () => {
    const { openFlaggedTasksModal, handleFetchInitialTasks } = this.props;
    openFlaggedTasksModal({ selectedFlag: 'Past Due' });
    handleFetchInitialTasks({
      pastDue: true
    });
  };

  onPastScheduledClick = () => {
    const { openFlaggedTasksModal, handleFetchInitialTasks } = this.props;

    openFlaggedTasksModal({ selectedFlag: 'Past Schedule' });
    handleFetchInitialTasks({
      pastScheduled: true
    });
  };

  render() {
    const {
      dateName,
      isOnTaskListheader,
      dueTasks,
      pastDueCount,
      pastScheduledCount,
      openFlaggedTasksModal,
      isLoaded
    } = this.props;

    const numTasksDue = dueTasks && dueTasks.length;
    const dueDate = numTasksDue && dueTasks[0].due_at;
    const noDueColor = !isToday(dueDate);
    const noPastDueColor = false;
    const noPastScheduleColor = false;
    const shouldRenderPastScheduled = false; // pastScheduledCount && isLoaded;
    return (
      <Wrapper isOnTaskListheader={isOnTaskListheader}>
        <div className="flags">
          {numTasksDue ? (
            <div
              className="icon-container"
              onClick={() =>
                openFlaggedTasksModal({
                  selectedFlag: `Due ${dateName}`,
                  selectedDate: dueDate
                })
              }
              data-for="due-today-tasks"
              data-tip={renderTooltip(numTasksDue, `Due ${dateName}`)}
            >
              <>
                {noDueColor ? (
                  <GreyFlagIcon
                    width={8}
                    height={14}
                    currentColor={colorMediumGray4}
                  />
                ) : (
                  <OrangeFlagIcon
                    width={8}
                    height={14}
                    currentColor={colorCalendarOrange}
                  />
                )}
                <ColoredText
                  color={noDueColor ? colorMediumGray4 : colorCalendarOrange}
                >
                  {numTasksDue + ' Due'}
                </ColoredText>
                <ReactTooltip effect="solid" id="due-today-tasks" />
              </>
            </div>
          ) : null}
          {pastDueCount && isLoaded ? (
            <div
              className="icon-container"
              onClick={this.onPastDueClick}
              data-for="past-due-tasks"
              data-tip={renderTooltip(pastDueCount, 'due')}
            >
              <>
                {noPastDueColor ? (
                  <GreyFlagIcon width={8} height={14} />
                ) : (
                  <RedFlagIcon width={8} height={14} />
                )}
                <ColoredText
                  color={noPastDueColor ? colorMediumGray4 : colorDeleteRed}
                >
                  {pastDueCount}
                </ColoredText>
                <ReactTooltip effect="solid" id="past-due-tasks" />
              </>
            </div>
          ) : null}
          {shouldRenderPastScheduled ? (
            <div
              className="icon-container"
              onClick={this.onPastScheduledClick}
              data-for="past-scheduled-tasks"
              data-tip={renderTooltip(pastScheduledCount, 'to reschedule')}
            >
              <>
                <WrappedCalendarIcon
                  width={14}
                  height={14}
                  currentColor={
                    noPastScheduleColor ? colorMediumGray4 : colorDeleteRed
                  }
                />
                <ColoredText
                  color={
                    noPastScheduleColor ? colorMediumGray4 : colorDeleteRed
                  }
                >
                  {pastScheduledCount || '  '}
                </ColoredText>
                <ReactTooltip effect="solid" id="past-scheduled-tasks" />
              </>
            </div>
          ) : null}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  taskFilter: getCurrentFilter(state),
  isLoaded: !getPastTasksLoading(state),
  pastDueCount: getPastDueCount(state),
  pastScheduledCount: getPastScheduledCount(state)
});
const mapDispatchToProps = {
  openFlaggedTasksModal,
  handleFetchInitialTasks: triggerFetchInitialTasks
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskListFlags)
);
