import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportDepartment
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingDepartmentsByName: Record<string, CsvImportDepartment>;
  newDepartments: React.MutableRefObject<Record<string, boolean>>;
}

export const departmentOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ additionalParams }) => {
  const { existingDepartmentsByName, newDepartments } = additionalParams;

  return [
    ...Object.keys(newDepartments.current),
    ...Object.keys(existingDepartmentsByName)
  ];
};
