import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportOffice
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingOfficesByName: Record<string, CsvImportOffice>;
  newOfficeNames: React.MutableRefObject<Record<string, boolean>>;
}

export const officeOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ additionalParams }) => {
  const { existingOfficesByName, newOfficeNames } = additionalParams;

  return [
    ...Object.keys(newOfficeNames.current),
    ...Object.keys(existingOfficesByName)
  ];
};
