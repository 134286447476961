import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchPositions } from 'BudgetModule/actionCreators';
import {
  getIsPositionsLoaded,
  getIsFetchingPositions
} from 'BudgetModule/selectors/positions';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const emptyObj = {};

export const useLoadPositions = ({
  isOff,
  shouldAutoLoad = true
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const isLoaded = useAppSelector(getIsPositionsLoaded);
  const isLoading = useAppSelector(getIsFetchingPositions);

  const loadPositions = useCallback(() => {
    if (!isLoaded && !isLoading && teamId) {
      dispatch(fetchPositions({ teamId }));
    }
  }, [dispatch, isLoaded, teamId, isLoading]);

  useEffect(() => {
    if (!isOff && shouldAutoLoad) {
      loadPositions();
    }
  }, [isOff, loadPositions, shouldAutoLoad]);

  return {
    isLoaded,
    isLoading,
    loadPositions
  };
};
