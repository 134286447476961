import { TeamMemberWrapper } from 'TeamsModule/types';
import { StyledAdminListItem, AdminCircle } from './styles';

const AdminListItem = ({ admin }: { admin: TeamMemberWrapper }) => (
  <StyledAdminListItem key={admin.account.id}>
    <AdminCircle />
    {admin.account.name}
  </StyledAdminListItem>
);

export default AdminListItem;
