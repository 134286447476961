import { MemberCapacityTableContainer } from './styles';
import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import { Field, Label } from 'SettingsModule/components/styles';

import CapacityDay from './CapacityDay';

import styled from 'styled-components';

const StandardsSettingsRow = styled.div`
  display: flex;
`;

const daysOfWeek = ['SU', 'M', 'T', 'W', 'TH', 'F', 'SA'];

const StandardGeneralContent = ({
  capacity,
  handleChange,
  handleOnBlur,
  workloadSettings
}) =>
  capacity && capacity.monday !== undefined ? (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Enter the Organization's default Work Capacity<br/> 
          for new members. Default can be modified by going to<br/> 
          'Members' on the left menu, then clicking the Capacity Tab."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Default Work Capacity <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <StandardsSettingsRow>
          <MemberCapacityTableContainer>
            {daysOfWeek.map((day) => (
              <CapacityDay
                key={day}
                day={day}
                capacity={capacity}
                handleChange={handleChange}
                handleOnBlur={handleOnBlur}
                workloadSettings={workloadSettings}
              />
            ))}
            <CapacityDay
              key={'TOTAL'}
              day={'TOTAL'}
              isTotal
              capacity={capacity}
              handleChange={handleChange}
              handleOnBlur={handleOnBlur}
              workloadSettings={workloadSettings}
            />
          </MemberCapacityTableContainer>
        </StandardsSettingsRow>
      </Field>
    </>
  ) : null;

export default StandardGeneralContent;
