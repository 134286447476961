import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getTeamRoleTemplatesByName } from 'PermissionsModule/selectors';
import {
  updateTeamPermissions,
  fetchTeamPermissions,
  toggleAllPermissionsOpen
} from 'PermissionsModule/actionCreators';
import { PermissionsActions } from 'PermissionsModule/types';
import {
  PermissionEdits,
  IsSpecificMemberCheckedByPermissionAction
} from 'PermissionsModule/hooks/types';
import produce from 'immer';
import flatten from 'lodash/flatten';
import xor from 'lodash/xor';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';

type UnSavedChangesEdit = Partial<Record<PermissionsActions, number[]>>;

export const usePermissionsEdit = ({
  fetchTeamPermissionsRequestStatusId,
  updateTeamPermissionsRequestStatusId
}: {
  fetchTeamPermissionsRequestStatusId?: string;
  updateTeamPermissionsRequestStatusId?: string;
}) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const teamRoleTemplatesByName = useAppSelector(getTeamRoleTemplatesByName);
  const [isEditing, setIsEditing] = useState(false);

  /**
   * unSavedRoles stores unsaved changes for
   * role checkboxes (Admins, managers, etc...)
   *
   * unSavedTeamMemberships stores
   * unsaved changes for one off permissions (specific members).
   */
  const [unSavedRoles, setUnsavedRoles] = useState<UnSavedChangesEdit>({});
  const [unSavedTeamMemberships, setUnsavedTeamMemberships] =
    useState<UnSavedChangesEdit>({});

  // When users select permissions or unselects them we have to update this state
  const [permissionEdits, setPermissionEdits] = useState<PermissionEdits>({});

  const [
    isSpecificMemberCheckedByPermissionAction,
    setIsSpecificMemberCheckedByPermissionAction
  ] = useState<IsSpecificMemberCheckedByPermissionAction>({});

  const { status: fetchTeamPermissionsStatus } = useRequestStatus({
    requestStatusId:
      fetchTeamPermissionsRequestStatusId || fetchTeamPermissions.type
  });
  const { status: updateTeamPermissionsStatus } = useRequestStatus({
    requestStatusId:
      updateTeamPermissionsRequestStatusId || updateTeamPermissions.type
  });

  const isLoading =
    fetchTeamPermissionsStatus?.isExecuting ||
    updateTeamPermissionsStatus?.isExecuting;

  useEffect(() => {
    setUnsavedRoles({});
    setUnsavedTeamMemberships({});
  }, [isEditing]);

  const hasUnsavedChanges = useMemo(() => {
    const unSavedRolesIds = Object.values(unSavedRoles);
    const unSavedTeamMembershipsIds = Object.values(unSavedTeamMemberships);

    return (
      flatten(unSavedRolesIds).length !== 0 ||
      flatten(unSavedTeamMembershipsIds).length !== 0
    );
  }, [unSavedRoles, unSavedTeamMemberships]);

  // Will change the permissionsEditState
  const addPermissionToEdits = useCallback(
    ({
      permissionIdentifier,
      roleTemplateIds,
      teamMembershipIds
    }: {
      permissionIdentifier: string;
      teamMembershipIds?: number[];
      roleTemplateIds?: number[];
    }) => {
      // Find if the permission exists already in edits
      const permissionEdit = permissionEdits[permissionIdentifier];
      if (!permissionEdit) {
        // If it doesnt exist we create it and add it to edits
        setPermissionEdits(
          produce((draft) => {
            draft[permissionIdentifier] = {
              true: {
                team_membership_ids: [...(teamMembershipIds || [])],
                role_template_ids: [...(roleTemplateIds || [])]
              },
              false: {
                team_membership_ids: [],
                role_template_ids: []
              }
            };
          })
        );
      } else {
        // If it does exist we add to the true section of the permissionEdit
        setPermissionEdits(
          produce((draft) => {
            if (teamMembershipIds) {
              draft[permissionIdentifier].true.team_membership_ids.push(
                ...teamMembershipIds
              );
              draft[permissionIdentifier].false.team_membership_ids = draft[
                permissionIdentifier
              ].false.team_membership_ids.filter(
                (id) => !teamMembershipIds.includes(id)
              );
            }
            if (roleTemplateIds) {
              draft[permissionIdentifier].true.role_template_ids.push(
                ...roleTemplateIds
              );
              draft[permissionIdentifier].false.role_template_ids = draft[
                permissionIdentifier
              ].false.role_template_ids.filter(
                (id) => !roleTemplateIds.includes(id)
              );
            }
          })
        );
      }
    },
    [permissionEdits]
  );

  const removePermissionFromEdits = useCallback(
    ({
      permissionIdentifier,
      roleTemplateIds,
      teamMembershipIds
    }: {
      permissionIdentifier: string;
      teamMembershipIds?: number[];
      roleTemplateIds?: number[];
    }) => {
      // Find if the permission exists already in edits
      const permissionEdit = permissionEdits[permissionIdentifier];
      if (!permissionEdit) {
        // If it doesnt exist we create it and add it to edits
        setPermissionEdits(
          produce((draft) => {
            draft[permissionIdentifier] = {
              true: {
                team_membership_ids: [],
                role_template_ids: []
              },
              false: {
                team_membership_ids: [...(teamMembershipIds || [])],
                role_template_ids: [...(roleTemplateIds || [])]
              }
            };
          })
        );
      } else {
        // If it does exist we add to the false section of the permissionEdit
        setPermissionEdits(
          produce((draft) => {
            if (teamMembershipIds) {
              draft[permissionIdentifier].false.team_membership_ids.push(
                ...teamMembershipIds
              );
              draft[permissionIdentifier].true.team_membership_ids = draft[
                permissionIdentifier
              ].true.team_membership_ids.filter(
                (id) => !teamMembershipIds.includes(id)
              );
            }
            if (roleTemplateIds) {
              draft[permissionIdentifier].false.role_template_ids.push(
                ...roleTemplateIds
              );
              draft[permissionIdentifier].true.role_template_ids = draft[
                permissionIdentifier
              ].true.role_template_ids.filter(
                (id) => !roleTemplateIds.includes(id)
              );
            }
          })
        );
      }
    },
    [permissionEdits]
  );

  const makeEditPermission = useCallback(
    (actionType: PermissionsActions) =>
      ({ names, teamMembershipIds, checked, item }) => {
        // prop.checked == true for members is adding, !checked is removing permission from member.

        const roleTemplate = names
          ? names.map((name) => teamRoleTemplatesByName[name] ?? null)
          : [];

        // Since 'Specific Member' option is not tracked in teamRoleTemplatesByName
        // Need a separate state to record its checked state
        if (names.includes('Specific Member')) {
          setIsSpecificMemberCheckedByPermissionAction({
            ...isSpecificMemberCheckedByPermissionAction,
            [actionType]: checked
          });
        }

        const roleTemplateIds = roleTemplate
          .filter((role) => !!role?.id)
          .map((role) => role.id);

        /**
         * Update unsaved changes (unSavedRoles, unSavedTeamMemberships states)
         *
         * Since the values are implemented with a list of checkboxes, we can assume the changes to be boolean.
         * If a change occurs on a role/member, we will add the id to the respective array. If a change has already
         * occured and the role/id changes again, we can assume it is reverting back to its original state.
         *
         * This behaviour is represented by lodash xor
         *  a = [1, 2], b = [2, 3] => xor(a,b) = [1,3]
         */
        setUnsavedRoles(
          produce((draft) => {
            draft[actionType] = draft[actionType]
              ? xor(draft[actionType], roleTemplateIds)
              : roleTemplateIds;
          })
        );

        if (teamMembershipIds) {
          setUnsavedTeamMemberships(
            produce((draft) => {
              draft[actionType] = draft[actionType]
                ? xor(draft[actionType], teamMembershipIds)
                : teamMembershipIds;
            })
          );
        }

        if (checked) {
          addPermissionToEdits({
            permissionIdentifier: actionType,
            ...(roleTemplateIds ? { roleTemplateIds } : {}),
            ...(teamMembershipIds ? { teamMembershipIds } : {})
          });
        } else {
          removePermissionFromEdits({
            permissionIdentifier: actionType,
            ...(roleTemplateIds ? { roleTemplateIds } : {}),
            ...(teamMembershipIds ? { teamMembershipIds } : {})
          });
        }
      },
    [
      addPermissionToEdits,
      removePermissionFromEdits,
      teamRoleTemplatesByName,
      isSpecificMemberCheckedByPermissionAction
    ]
  );

  // Can use this as a toggle in the future
  const closeAllPermission = useCallback(() => {
    dispatch(toggleAllPermissionsOpen({ open: false }));
  }, [dispatch]);

  const handleCancelEdit = useCallback(() => {
    setPermissionEdits({});
    setIsSpecificMemberCheckedByPermissionAction({});
    setIsEditing(false);
    closeAllPermission();
  }, [closeAllPermission]);

  const handleSavePermissions = useCallback(() => {
    const permissionUpdateInfo = Object.entries(permissionEdits).reduce(
      (acc, [permissionIdentifier, permissionEdit]) => {
        const { true: truePermissions, false: falsePermissions } =
          permissionEdit;
        const {
          team_membership_ids: trueTeamMembershipIds,
          role_template_ids: trueRoleTemplateIds
        } = truePermissions;
        const {
          team_membership_ids: falseTeamMembershipIds,
          role_template_ids: falseRoleTemplateIds
        } = falsePermissions;

        const changesToMake = [
          ...(trueTeamMembershipIds.length > 0 || trueRoleTemplateIds.length > 0
            ? [
                {
                  permission_value: true,
                  permissions_identifier: permissionIdentifier,
                  team_membership_ids: trueTeamMembershipIds,
                  team_role_template_ids: trueRoleTemplateIds
                }
              ]
            : []),
          ...(falseTeamMembershipIds.length > 0 ||
          falseRoleTemplateIds.length > 0
            ? [
                {
                  permission_value: false,
                  permissions_identifier: permissionIdentifier,
                  team_membership_ids: falseTeamMembershipIds,
                  team_role_template_ids: falseRoleTemplateIds
                }
              ]
            : [])
        ];

        return [...acc, ...changesToMake];
      },
      []
    );

    if (permissionUpdateInfo.length > 0 && teamId) {
      dispatch(
        updateTeamPermissions({
          teamId,
          permissionUpdateInfo,
          meta: {
            requestStatusId:
              updateTeamPermissionsRequestStatusId || updateTeamPermissions.type
          }
        })
      );
    }

    handleCancelEdit();
  }, [
    dispatch,
    handleCancelEdit,
    permissionEdits,
    teamId,
    updateTeamPermissionsRequestStatusId
  ]);

  return {
    isEditing,
    setIsEditing,
    makeEditPermission,
    handleSavePermissions,
    handleCancelEdit,
    hasUnsavedChanges,
    permissionEdits,
    isSpecificMemberCheckedByPermissionAction,
    isLoading
  };
};
