import React from 'react';
import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import theme from 'theme';

const StyledNameContainer = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${calculateColorPref};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;

const TeamMemberCell = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
`;

const PhaseGroupRenderer = ({ group }) => {
  return (
    <TeamMemberCell className="team-member-cell" showBorder>
      <StyledNameContainer>
        <StyledName>{group?.name}</StyledName>
      </StyledNameContainer>
    </TeamMemberCell>
  );
};

export default PhaseGroupRenderer;
