import {
  TaskCommentIconWrapper,
  TaskNoteIconWrapper,
  NoteIconContainer,
  StyledTaskCardIcons
} from 'components/TextArea/styles';
import theme from 'theme';

import CommentBubbleIcon from 'icons/CommentBubbleIconV2';
import NoteIcon from 'icons/NoteIcon';

const TaskCardIcons = ({ task, toggleTaskModal }) => (
  <StyledTaskCardIcons className="task-icons-container">
    <TaskNoteIconWrapper onClick={toggleTaskModal}>
      <NoteIconContainer hasTaskNotes={task.note && task.note.length}>
        <NoteIcon />
      </NoteIconContainer>
    </TaskNoteIconWrapper>
    <TaskCommentIconWrapper
      $inCards
      $show={task.total_comments > 0}
      onClick={toggleTaskModal}
    >
      <CommentBubbleIcon
        color={
          task.total_comments > 0
            ? theme.colors.colorRoyalBlue
            : theme.colors.colorMediumGray1
        }
        height={16}
        width={16}
      />
      {task.total_comments > 0 && task.total_comments}
    </TaskCommentIconWrapper>
  </StyledTaskCardIcons>
);

export default TaskCardIcons;
