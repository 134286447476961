import { useTimer } from 'appUtils/hooks/useTimer';
import { SimpleConfirmModal } from 'components/Modals';
import styled from 'styled-components';
import { useAppSelector } from 'reduxInfra/hooks';
import { getMergedTimers } from 'selectors';
import { getHoursAndMinutes } from 'appUtils/timeUtils';
import { getProjectHash } from 'ProjectsModule/selectors';
import { StyledModal } from 'components/Modals/SimpleConfirmModal';

const ConfirmStopTimerModal = ({
  onClose,
  isOpen,
  explanationText = 'Task was marked completed. Do you want to stop the timer?'
}: {
  isOpen: boolean;
  onClose: () => void;
  explanationText?: string;
}) => {
  const { activeTimer, endTimer } = useTimer();

  const mergedTimers = useAppSelector(getMergedTimers);
  const projectHash = useAppSelector(getProjectHash);

  const handleClose = () => {
    onClose();
  };

  const confirmStop = () => {
    endTimer();
    handleClose();
  };

  const {
    task_id: taskId,
    check_in_id: checkInId,
    project_id: projectId,
    duration = 0
  } = activeTimer ?? {};

  const activeTimerDurationTotal =
    taskId && checkInId
      ? mergedTimers[checkInId]?.[taskId]?.duration ?? duration
      : duration;

  const { hours, minutes } = getHoursAndMinutes({
    seconds: activeTimerDurationTotal
  });

  const { title: projectTitle = undefined } = projectId
    ? projectHash[projectId] ?? {}
    : {};

  const ModalBody = (
    <ModalBodyContainer>
      <PrimaryTextContainer>
        <ProjectTitleText>{projectTitle} Timer is running</ProjectTitleText>
        <TimerDurationText>
          {hours}h {minutes}m
        </TimerDurationText>
      </PrimaryTextContainer>
      <SecondaryTextContainer>
        <SecondaryText>{explanationText}</SecondaryText>
      </SecondaryTextContainer>
    </ModalBodyContainer>
  );

  return (
    <SimpleConfirmModal
      isOpen={isOpen}
      header="Stop Timer"
      body={ModalBody}
      toggle={handleClose}
      onCancel={handleClose}
      onConfirm={confirmStop}
      confirmLabel="Stop Timer"
      cancelLabel="Continue Timer"
      stylesWrapper={ConfirmModalStyleWrapper}
      preventClickOut
    />
  );
};

export default ConfirmStopTimerModal;

const ModalBodyContainer = styled.div`
  font-size: 13px;
  line-height: 22px;
  margin-bottom: -19px;
`;

const PrimaryTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ProjectTitleText = styled.div``;
const TimerDurationText = styled.div`
  font-weight: 600;
`;

const SecondaryTextContainer = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;
const SecondaryText = styled.div``;

const ConfirmModalStyleWrapper = styled(StyledModal)`
  .confirm-modal-confirm-btn {
    font-weight: 600;
  }
`;
