import {
  HTMLAttributes,
  memo,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo
} from 'react';
import styled, { useTheme } from 'styled-components';
import QBDownArrow from 'icons/QBDownArrow';
import { usePopperState } from 'appUtils/hooks/usePopperState';
import cn from 'classnames';

export enum SelectInputVariant {
  Gray,
  White
}

export interface SelectDropdownProps<ValueType> {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (value: ValueType) => void;
  target: Nullable<HTMLElement | SVGElement>;
}

export const SelectInput = <ValueType,>({
  children,
  disabled,
  onSelect,
  renderDropdown,
  showError,
  variant = SelectInputVariant.Gray,
  ...inputProps
}: PropsWithChildren<
  Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
    disabled?: boolean;
    onSelect: (value: ValueType) => void;
    renderDropdown: (
      props: SelectDropdownProps<ValueType>
    ) => ReactElement<any, any> | null;
    showError?: boolean;
    variant?: SelectInputVariant;
  }
>) => {
  const theme = useTheme();
  const { isOpen, anchorElement, open, close } = usePopperState();

  // Event handlers

  const handleClick = useCallback(
    ({ currentTarget }: MouseEvent<HTMLElement>) => {
      (isOpen ? close : open)({ target: currentTarget as HTMLElement });
    },
    [close, isOpen, open]
  );

  const handleSelect = useCallback(
    (value: ValueType) => {
      close();
      onSelect(value);
    },
    [close, onSelect]
  );

  // The dropdown renderer may contain hooks. Consequently, this cannot be
  // memoized with `useMemo` because hooks would then be nested, which is
  // forbidden. Also, this cannot be conditionally rendered because hook would
  // be conditionally rendered, which is also forbidden.
  const dropdown = renderDropdown({
    isOpen,
    onClose: close,
    onSelect: handleSelect,
    target: anchorElement ?? null
  });

  // Rendering

  return (
    <>
      <Input
        $disabled={disabled}
        $isOpen={isOpen}
        $showError={showError}
        $variant={variant}
        {...inputProps}
        onClick={handleClick}
      >
        <Label>{children}</Label>
        <QBDownArrow fill={theme.colors.colorMediumGray9} />
      </Input>
      {isOpen && dropdown}
    </>
  );
};

const Input = styled.div.attrs<{
  $disabled?: boolean;
  $isOpen?: boolean;
  $showError?: boolean;
  $variant?: SelectInputVariant;
}>(({ $disabled, $isOpen, $showError, $variant }) => ({
  className: cn({
    disabled: $disabled,
    error: $showError,
    open: $isOpen,
    white: $variant === SelectInputVariant.White
  })
}))<{
  $disabled?: boolean;
  $isOpen?: boolean;
  $showError?: boolean;
  $variant?: SelectInputVariant;
}>`
  align-items: center;
  background: ${({ theme }) => theme.colors.colorLightGray19};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.colorLightGray6};
  cursor: pointer;
  display: inline-flex;
  font-size: ${({ theme }) => theme.sizes.inputFontSize};
  gap: 14px;
  height: ${({ theme }) => theme.sizes.inputHeight};
  padding: 0 10px;

  &.disabled {
    filter: grayscale(1);
    opacity: 0.65;
  }

  &:not(.disabled) {
    &:hover,
    &.open {
      border-color: ${({ theme }) => theme.colors.colorBudgetBlue};
    }

    &.error {
      border-color: ${({ theme }) => theme.colors.colorCalendarRed};
    }
  }

  &.white {
    background-color: ${({ theme }) => theme.colors.colorPureWhite};
    border: 1px solid ${({ theme }) => theme.colors.colorLightGray12};
    color: ${({ theme }) => theme.colors.colorMediumGray9};
  }
`;

const Label = styled.div`
  flex: 1;
  min-width: 0;
`;
