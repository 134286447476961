import { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useAppSelector } from 'reduxInfra/hooks';
import { getProjectPriorities } from 'selectors';

import PriorityFlagIcon from 'icons/PriorityFlagIcon';
import OptionsDropdown from 'components/options/OptionsDropdown';

import { SectionTitle } from './styles';

const PrioritySection = ({
  priorityId = null,
  title = 'PRIORITY',
  onSelect,
  testId = 'priority-hours-section',
  disabled
}: {
  priorityId?: Nullable<number>;
  title?: string;
  onSelect: (priority: any) => void;
  testId?: string;
  disabled?: boolean;
}) => {
  const priorities = useAppSelector(getProjectPriorities);

  const options = useMemo(
    () => [
      ...priorities.map((priority) => ({
        label: (
          <IconContainer data-testid={testId} data-value={priority}>
            <PriorityFlagIcon
              className="priority-section-flag"
              currentColor={priority.color}
            />
            {priority.title}
          </IconContainer>
        ),
        ...priority
      })),
      {
        label: (
          <IconContainer data-testid={testId} data-value={'No Priority'}>
            <PriorityFlagIcon
              className="priority-section-flag"
              currentColor={'transparent'}
            />
            No Priority
          </IconContainer>
        ),
        value: priorities.length + 1,
        id: null,
        color: 'transparent'
      }
    ],
    [priorities, testId]
  );

  const priority = options.find(
    (priority) => priorityId && priority.id === priorityId
  );

  const handleOnSelect = (optionIndex) => {
    const priorityId = options[optionIndex - 1].id;
    onSelect(priorityId);
  };

  return (
    <StyledPriorityContainer>
      <SectionTitle>{title}</SectionTitle>
      <OptionsDropdown
        onSelect={handleOnSelect}
        currentValue={priority}
        options={options}
        optionsHash={undefined}
        headerText={''}
        renderToggle={() => (
          <>
            {priority ? (
              <IconContainer data-testid={testId} data-value={priority}>
                <PriorityFlagIcon
                  className="priority-section-flag"
                  currentColor={priority.color}
                />
              </IconContainer>
            ) : (
              <SetPriorityButton disabled={disabled}>
                Set Priority
              </SetPriorityButton>
            )}
          </>
        )}
        disableDropdown={undefined}
        disableToggle={undefined}
      />
    </StyledPriorityContainer>
  );
};

export default PrioritySection;

/* ------------------------------------ - ----------------------------------- */

const StyledPriorityContainer = styled.div`
  position: relative;
  padding: 3px 0;
  cursor: pointer;

  .options-dropdown-toggle {
    line-height: 17px;
  }
`;

const IconContainer = styled.div`
  svg {
    margin-right: 8px;
  }
`;

const SetPriorityButton = styled.div<{ disabled?: boolean }>`
  border: none;
  background: none;
  font-size: 14px;
  color: ${theme.colors.colorBudgetGrey};
  cursor: default;
  ${(props) =>
    !props.disabled &&
    `
      &:hover {
        color: ${theme.colors.colorRoyalBlue};
        cursor: pointer;
      }
  `}
`;
