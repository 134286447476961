import { startOfDay } from 'date-fns';
import { differenceInDays } from 'appUtils/dateUtils';
import { DateStringObject } from './type';
import { DateRange } from './DateRange';

interface RangeManager {
  getRanges: () => Array<DateRange>;
  addRanges: (range: DateRange) => void;
  isWithinRange: (range: DateRange) => boolean;
}

export class DateRangeManager implements RangeManager {
  private ranges: Array<DateRange> = [];

  constructor(
    range?: DateRange | DateStringObject | Array<DateRange | DateStringObject>
  ) {
    if (range) {
      this.addRanges(range);
    }
  }

  getRanges(): Array<DateRange> {
    return this.ranges;
  }

  addRanges(
    range: DateRange | DateStringObject | Array<DateRange | DateStringObject>
  ): this {
    if (range instanceof Array) {
      range.forEach((r) => {
        try {
          const validRange = this.validateRange(r);
          this.tryMergeRange(validRange);
        } catch (e) {
          console.error(e);
        }
      });
    } else {
      try {
        this.tryMergeRange(this.validateRange(range));
      } catch (e) {
        console.error(e);
      }
    }

    return this;
  }

  isWithinRange(range: DateRange | DateStringObject): boolean {
    try {
      const validatedRange = this.validateRange(range);
      return this.ranges.some(
        (r) => r.start <= validatedRange.start && r.end >= validatedRange.end
      );
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private validateRange(range: DateRange | DateStringObject): DateRange {
    if (range instanceof DateRange) {
      return range;
    }
    return new DateRange({ start: range.start, end: range.end });
  }

  private sortRange(ranges: Array<DateRange>): Array<DateRange> {
    return ranges.slice().sort((a, b) => {
      const aStartTime = a.start.getTime();
      const bStartTime = b.start.getTime();

      if (aStartTime !== bStartTime) {
        return aStartTime - bStartTime;
      }

      const aEndTime = a.end.getTime();
      const bEndTime = b.end.getTime();
      return aEndTime - bEndTime;
    });
  }

  private tryMergeRange(range: DateRange): void {
    if (!this.ranges.length) {
      this.ranges.push(range);
      return;
    }

    const sortedRange = this.sortRange([...this.ranges, range]);

    this.ranges = sortedRange.reduce((acc, curr) => {
      const prev = acc?.pop();
      if (!prev) return [curr];

      // If the current range starts before the end of the previous range,
      // or if the current range starts on the next day of the previous range,
      // they can be merged.
      if (differenceInDays(startOfDay(curr.start), startOfDay(prev.end)) <= 1) {
        return [
          ...acc,
          new DateRange({
            start: prev.start,
            end: curr.end
          })
        ];
      }

      return [...acc, prev, curr];
    }, [] as Array<DateRange>);
  }
}
