/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef
} from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import {
  getSelectedTeamId,
  getSelectedProject,
  getV2TasksArray,
  getV2Tasks,
  getTaskGroupCounts,
  getMe,
  getUseTimesheetIsWeek,
  getTaskColumnsForTable,
  getAuthToken,
  getTaskEditProperty,
  getIsOnProjectView,
  getOnMembersView,
  getIsOnTeamProject,
  getOnHomeView,
  getAllTasks,
  getBatchSelectedTaskIds,
  getIsOnPersonalProject,
  getAllActivityRowInfo,
  getIsMemberModalOpen,
  getMatchedRouteParams
} from 'selectors';
import DevProps from 'components/dev/DevProps';
import ContentLoader from 'react-content-loader';
import Table from 'components/Table';
import uuid from 'uuid';

import { SORT_BY } from 'appConstants/filters';
import { VIEW_BY } from 'appConstants/workload';
import {
  setIsAddingNewTimesheetRow,
  triggerTasksAttributesUpdate,
  setEditTaskId,
  setHomeTaskEditProperty,
  moveTaskToGroup,
  insertTaskBefore,
  reorderProjectTaskGroups,
  triggerTaskCreationServerRequest,
  updateProjectModules
} from 'actionCreators';
import TasksTableOptions from './TasksTableOptions';

import * as TaskCells from '../Tasks/TaskCells';
import HeaderCell, {
  BorderBottomCell,
  TimerHeaderCell
} from '../Tasks/TaskCells/HeaderCell';
import HeaderCells from '../Tasks/HeaderCells';
import TaskGroupCells from '../Tasks/TaskGroupCells';
import PhaseCells from '../Tasks/PhaseCells';
import ActivityPhaseCells from '../Tasks/ActivityPhaseCells';
import { StyledTaskTable } from './styles';
import { getTruncatedDescription } from 'appUtils';
import usePrevious from 'appUtils/usePrevious';
import { serializeBar, deserializeBar } from 'appUtils/projectPlannerUtils';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import sum from 'lodash/sum';
import moment from 'appUtils/momentConfig';

import TaskColumnsDropdown from './TaskColumnsDropdown';
import { isInAnyPopover, isInAnyModal } from 'appUtils/popoverClicks';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { SCROLLBAR_WIDTH } from 'appConstants/scrollbar';

const NO_PHASE = 'headless--no-phase';

const columnDropdownStyles = {
  top: 37,
  right: 15,
  position: 'absolute'
};

const EmptyDiv = () => <div />;
const emptyRow = {
  rowType: 'emptyRow',
  draggableId: serializeBar({ itemType: 'emptyRow', itemId: 'emptyRow' }),
  isDragDisabled: true,
  listItems: []
};

const sumEstHours = (listItems) =>
  listItems?.reduce((acc, cur) => acc + (+cur.estimated_hours || 0), 0);

const formatDate = (date) => {
  if (!date) return undefined;
  return moment(date).format('YYYY-MM-DD');
};

const stubCellMap = {};
const headerComponents = {
  bulk: HeaderCells.BulkHeaderCell,
  description: BorderBottomCell,
  completed: BorderBottomCell,
  drag: EmptyDiv,
  timer: TimerHeaderCell
  // status: StatusHeaderCell
};
const taskCells = {
  completed: TaskCells.CompleteCell,
  drag: TaskCells.DragCell,
  description: TaskCells.DescriptionCell,
  due_at: TaskCells.DueCell,
  schedule_start: TaskCells.ScheduleCell,
  status: TaskCells.StatusCell,
  priority: TaskCells.PriorityCell,
  phase: TaskCells.PhaseCell,
  task_group: TaskCells.TaskGroupCell,
  estimated_hours: TaskCells.EstimatedHoursCell,
  assignee: TaskCells.AssigneeCell,
  bulk: TaskCells.BulkCell,
  timer: TaskCells.TimerCell
};
const removedTaskCells = {
  description: TaskCells.DescriptionCell
};
const taskGroupCells = {
  completed: BorderBottomCell,
  drag: TaskGroupCells.CompleteCell,
  description: BorderBottomCell,
  due_at: HeaderCell,
  schedule_start: HeaderCell,
  estimated_hours: HeaderCell,
  status: HeaderCell,
  priority: HeaderCell,
  phase: HeaderCell,
  assignee: HeaderCell,
  bulk: HeaderCells.BulkHeaderCell
};
const phaseCells = {
  completed: PhaseCells.PhaseHeaderCell,
  drag: PhaseCells.CompleteCell,
  description: PhaseCells.PhaseHeaderCell,
  due_at: PhaseCells.PhaseHeaderCell,
  schedule_start: PhaseCells.PhaseHeaderCell,
  estimated_hours: PhaseCells.PhaseHeaderCell,
  status: PhaseCells.PhaseHeaderCell,
  priority: PhaseCells.PhaseHeaderCell,
  phase: PhaseCells.PhaseHeaderCell,
  task_group: PhaseCells.PhaseHeaderCell,
  assignee: PhaseCells.PhaseHeaderCell,
  bulk: PhaseCells.BulkCell
};
const activityPhaseCells = {
  completed: BorderBottomCell,
  drag: ActivityPhaseCells.CompleteCell,
  description: BorderBottomCell,
  due_at: HeaderCell,
  schedule_start: HeaderCell,
  estimated_hours: HeaderCell,
  status: HeaderCell,
  priority: HeaderCell,
  phase: HeaderCell,
  task_group: HeaderCell,
  assignee: HeaderCell,
  bulk: HeaderCells.BulkHeaderCell
};
const taskGroupFooterCells = {
  completed: EmptyDiv,
  drag: EmptyDiv,
  description: EmptyDiv,
  due_at: EmptyDiv,
  schedule_start: EmptyDiv,
  estimated_hours: TaskGroupCells.TotalEstHoursCell,
  status: EmptyDiv,
  priority: EmptyDiv,
  phase: EmptyDiv,
  assignee: EmptyDiv,
  bulk: EmptyDiv,
  timer: EmptyDiv
};
const loadingRowCells = {
  description: EmptyDiv // Add loader if desired
};

const columnWidths = {
  [SORT_BY.description]: 540,
  due_at: 116,
  status: 115,
  [SORT_BY.phase]: 110,
  [SORT_BY.task_group]: 110,
  completed_at: 90,
  schedule_start: 110,
  drag: 30,
  completed: 30,
  empty: 80,
  timer: 75
};

const ITEM_HEIGHT = 62;
const defaultMaxTableHeightBuffer = 305;

const noop = () => {};
const emptyObj = {};

const stickyHeaderComponents = {
  taskGroupRow: TaskGroupCells.CompleteCell,
  phaseRow: PhaseCells.CompleteCell,
  activityRow: ActivityPhaseCells.CompleteCell
  // memberRow: MemberHeaderCell,
  // projectRow: ProjectHeaderCell
};

const validateDescription = (description) => {
  if (!description || !description.trim().length) {
    return { field: 'description', error: 'description is empty' };
  }
};

const validateProject = (project) => {
  if (!project?.id) {
    return { field: 'project', error: 'project is not selected' };
  }
};

const validateCreateTask = ({ description, project }) => {
  const errors = [];

  const descriptionError = validateDescription(description);
  if (descriptionError) errors.push(descriptionError);

  const projectError = validateProject(project);
  if (projectError) errors.push(projectError);

  return errors.length ? errors : undefined;
};

const buildHeader = ({ list, customRowProps }) => {
  const {
    id,
    headerType,
    isOpen,
    headerHeight,
    renderHeader,
    headerData,
    sectionId,
    isDragDisabled
  } = list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: sectionId || id,
    renderHeader,
    customRowProps,
    isDragDisabled
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: list.rowHeight || item.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.sectionId || list.id,
  handleClick: list.handleClick,
  customRowProps
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  draggableId: item.draggableId,
  isDragDisabled: item.isDragDisabled,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.sectionId || list.id,
  list,
  customRowProps
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = false
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      listItems.forEach((item) => {
        if (item.hasSubLists) {
          flatList.push(
            ...buildFlatList({
              lists: [item],
              engaged,
              search,
              customRowProps,
              emptyRow
            })
          );
        } else {
          flatList.push(buildRow({ list, item, customRowProps }));
        }
      });
      if (!isFullyLoaded) {
        // dont push multiple loadingRows
        if (flatList[flatList.length - 1]?.rowType !== 'loadingRow') {
          flatList.push(
            buildRow({
              list,
              customRowProps,
              item: {
                isDragDisabled: true,
                rowType: 'loadingRow',
                draggableId: serializeBar({
                  itemType: 'loadingRow',
                  itemId: 'loadingRow'
                })
              }
            })
          );
        }
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }
  if (lists.every((list) => list.isFullyLoaded || !list.isOpen)) {
    flatList.push(
      buildCustomItem({
        list: lists[lists.length - 1] || { getItemId: (item) => item?.id },
        customRowProps,
        item: emptyRow
      })
    );
  }
  return flatList;
};

const findNextTaskId = (order, listHash) => {
  const listWithTasks = order
    .map((id) => listHash[id])
    .find((list) => list.taskIds?.length);
  return listWithTasks?.taskIds?.[0] ?? null;
};

const TaskTable = ({
  loadMore,
  orderedTeamMembers,
  orderedProjects,
  orderedPhases,
  orderedTaskGroups,
  projectHash,
  customHeaders,
  customHeaderData = emptyObj,
  isWhite,
  customCreateRowData,
  hideApprovalRow,
  tableHeightBuffer,
  groupsFullyLoadedOnce,
  setGroupsFullyLoadedOnce,
  handleAdditionalDragTypes,
  currentFilter
}) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const selectedProject = useSelector(getSelectedProject);
  const formattedAllTasks = useSelector(getV2TasksArray);
  const taskHash = useSelector(getV2Tasks);
  const defaultColumnHeaders = useSelector(getTaskColumnsForTable);
  const isMemberModalOpen = useSelector(getIsMemberModalOpen);
  const matchedParams = useSelector(getMatchedRouteParams);

  const taskColumnHeaders = customHeaders || defaultColumnHeaders;
  const totalTaskCount = useSelector((state) => state.homeTasks.taskCount);
  const taskGroupCounts = useSelector(getTaskGroupCounts);
  const selectedGroupIds = useSelector(
    (state) => state.homeTasks.batchSelectedGroupIds
  );
  const selectedTaskIds = useSelector(getBatchSelectedTaskIds);
  const viewBy = useSelector(
    (state) => state.homeTasks.viewBy || VIEW_BY.TASK_GROUPS
  );
  // const viewBy = VIEW_BY.PHASES;
  const me = useSelector(getMe);
  const isWeek = useSelector(getUseTimesheetIsWeek);
  const token = useSelector(getAuthToken);
  const taskEditProperty = useSelector(getTaskEditProperty);
  const taskIsEditing = false;
  const [rowEditing, setRowEditing] = useState(false);
  const isOnProjectView = useSelector(getIsOnProjectView);
  const isOnTeamPageView = useSelector(getOnMembersView);
  const isOnTeamProject = useSelector(getIsOnTeamProject);
  const isOnHomeView = useSelector(getOnHomeView);
  const taskOrder = useSelector(getAllTasks);
  const activitiesHash = useSelector(getAllActivityRowInfo);
  const taskRemovals = useSelector((state) => state.homeTasks.taskRemovals);
  const isPersonalProject = useSelector(getIsOnPersonalProject);

  const listRef = useRef(null);
  const infiniteLoaderRef = useRef(null);
  const orderedListsRef = useRef(null);
  const MAX_TABLE_HEIGHT_BUFFER =
    tableHeightBuffer === undefined
      ? defaultMaxTableHeightBuffer
      : tableHeightBuffer;

  useEffect(() => {
    infiniteLoaderRef.current?.resetloadMoreItemsCache?.();
  }, [viewBy]);

  const stateReducer = React.useCallback((newState, action, prevState) => {
    switch (action.type) {
      case 'setRowState': {
        const isActive = !!action.value?.editingProperty;
        // clear other row states
        const state = {
          ...newState,
          rowState: {
            [action.rowId]: { ...action.value, isActive }
          }
        };
        setRowEditing(isActive ? action.value.taskId : false);
        return state;
      }
    }
    return newState;
  }, []);

  const [isOpen, setIsOpen] = useState({});
  const [allOpen, setAllOpen] = useState(true);

  const [allCollapsed, setAllCollapsed] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [stateIsScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [editingHeight, setEditingHeight] = useState({
    index: null,
    height: null
  });
  const [createTaskErrors, setCreateTaskErrors] = useState();

  const prevEditingHeight = usePrevious(editingHeight);

  const descriptionRef = useRef(null); // optimization to prevent list from re-rendering on key stroke

  const isLoadingInitial = totalTaskCount === null;

  const taskGroupLength = selectedProject?.task_group_order?.length || 1;
  const viewByLength =
    viewBy === VIEW_BY.TASK_GROUPS ? taskGroupLength : orderedPhases.length;
  const projectId = selectedProject?.id;
  const shouldBeCollapsed = viewByLength > 2;
  useEffect(() => {
    if (!projectId) {
      return;
    }

    setAllOpen(!shouldBeCollapsed);
    setAllCollapsed(shouldBeCollapsed);
    // don't include shouldBeCollapsed in the dependency array
    // to prevent list gets collapsed when adding new phases while
    // viewing by phases
  }, [viewBy, projectId]);

  const makeHandleTaskEditClick = useCallback(
    (id) => (taskProperty) => {
      dispatch(setEditTaskId(taskProperty && id));
      dispatch(setHomeTaskEditProperty(taskProperty));
    },
    [dispatch]
  );
  const unsetTaskEditClick = useCallback(
    () => makeHandleTaskEditClick(null)(null),
    [makeHandleTaskEditClick]
  );

  // add clickoutside functionality here - gets used in VariableSizeTableRow
  const makeHandleClickOutside = useCallback(
    (id) => (e) => {
      if (rowEditing === id) {
        // only do something if active row, this fires for all mounted rows on all clicks
      }
    },

    [rowEditing]
  );

  const makeHandlePropertySelect = useCallback(
    (id) => (keyOrConfig) => (value) => {
      let body = { task_ids: [] };
      if (typeof keyOrConfig === 'string') {
        body = {
          task_ids: [id],
          [keyOrConfig]: value
        };
      } else if (typeof keyOrConfig === 'object') {
        body = {
          task_ids: [id],
          ...keyOrConfig
        };
      }

      // add permissions
      // handleTasksAttributesUpdate({ token, body, permissions });
      dispatch(triggerTasksAttributesUpdate({ token, body }));

      unsetTaskEditClick();
    },
    [dispatch, token, unsetTaskEditClick]
  );

  const handleTaskAttributesUpdate = useCallback(
    (args) => dispatch(triggerTasksAttributesUpdate(args)),
    [dispatch]
  );

  const makeHandleMultiAssignDone = useCallback(
    (id) =>
      ({
        assigneeIds = [],
        unassigneeIds = [],
        assignProjectMembershipIds = [],
        unassignProjectMembershipIds = []
      }) => {
        const body = {
          task_ids: [id],
          ...(assigneeIds.length ? { assignee_ids: assigneeIds } : {}),
          ...(unassigneeIds.length ? { unassignee_ids: unassigneeIds } : {}),
          ...(assignProjectMembershipIds.length
            ? { assign_project_membership_ids: assignProjectMembershipIds }
            : {}),
          ...(unassignProjectMembershipIds.length
            ? { unassign_project_membership_ids: unassignProjectMembershipIds }
            : {})
        };

        // const permissions = this.getPermissions();
        dispatch(triggerTasksAttributesUpdate({ token, body }));
        // dispatch(unsetTaskEditClick(null));
      },
    [dispatch, token]
  );

  const [createRows, setCreateRows] = useState({});
  const resetCreateState = useCallback(() => {
    setCreateRows({});
    listRef.current?.resetAfterIndex(0);
    setEditingHeight({ index: null, height: null });
    descriptionRef.current = null;
  }, []);

  const handleCreateRow = useCallback(
    (createRow, { shouldOpenModalAfterCreate }) => {
      createRow = {
        ...createRow,
        description: descriptionRef.current?.replace?.(/&nbsp;/g, ' ').trim()
      };
      const project = isOnProjectView ? selectedProject : createRow.project;
      const errors = validateCreateTask({
        description: createRow.description,
        project
      });

      if (errors) {
        setCreateTaskErrors(errors);
        return;
      }
      const projectId = isOnProjectView
        ? selectedProject?.id
        : createRow.project?.id;

      const permissions = {
        projectId: projectId,
        teamId: teamId
      };
      const defaultAssigneeId = matchedParams?.memberId
        ? matchedParams.memberId
        : null;
      const defaultAssigneeIds = defaultAssigneeId ? [defaultAssigneeId] : null;
      const body = {
        activity_id: createRow.activity_id,
        activity_phase_id: createRow.activity_phase_id,
        project_id: projectId,
        phase_id: createRow.phase_id,
        view_project_id: projectId,
        assignee_ids: createRow.assignee_ids?.length
          ? createRow.assignee_ids
          : defaultAssigneeIds,
        view_assignee_id: defaultAssigneeId,
        description: createRow.description,
        schedule_start: formatDate(createRow.schedule_start),
        schedule_end: formatDate(createRow.schedule_end),
        task_status_id: createRow.task_status_id,
        task_priority_id: createRow.task_priority_id,
        estimated_hours: createRow.estimated_hours,
        due_at: formatDate(createRow.due_at),
        task_group_id: createRow.task_group_id,
        nextTaskId: createRow.nextTaskId,
        project_membership_ids: createRow.assignProjectMembershipIds?.length
          ? createRow.assignProjectMembershipIds
          : defaultAssigneeIds,
        id: uuid()
      };
      dispatch(
        triggerTaskCreationServerRequest({
          token,
          body,
          permissions,
          shouldOpenModalAfterCreate
        })
      );

      descriptionRef.current = null;
      listRef.current?.resetAfterIndex(0);
      resetCreateState();
      setCreateTaskErrors(null);
    },
    [
      dispatch,
      isOnProjectView,
      matchedParams,
      resetCreateState,
      selectedProject,
      teamId,
      token
    ]
  );

  const createRowHandlePropertySelect = useCallback(
    (id) => (keyOrConfig) => (value) => {
      let body = createRows[id] || {};
      if (typeof keyOrConfig === 'string') {
        body = {
          ...body,
          [keyOrConfig]: value
        };
      } else if (typeof keyOrConfig === 'object') {
        body = {
          ...body,
          ...keyOrConfig
        };
      }
      setCreateRows({
        ...createRows,
        [id]: { ...createRows[id], ...body }
      });
      // find text area by id - TODO convert to sane ref implementation
      const createDescription = document.getElementById(
        `task-description-field-${id}`
      );
      if (createDescription) {
        createDescription.focus();
      }
    },
    [createRows]
  );
  const createRowHandleUpdate = useCallback(
    (id) => (body) => {
      const updatedCreateRows = {
        ...createRows,
        [id]: { ...createRows[id], ...(body ?? {}) }
      };
      setCreateRows(updatedCreateRows);

      if (body.project) {
        const createRow = {
          ...(updatedCreateRows[id] || {}),
          description: descriptionRef.current?.replace?.(/&nbsp;/g, ' ').trim()
        };
        setCreateTaskErrors(
          validateCreateTask({
            description: createRow.description,
            project: body.project
          })
        );
      }

      const createDescription = document.getElementById(
        `task-description-field-${id}`
      );
      if (createDescription) {
        createDescription.focus();
      }
    },
    [createRows]
  );

  const createRowHandleSubmit = useCallback(
    (id) =>
      ({ shouldOpenModalAfterCreate }) => {
        const createRow = createRows[id];
        if (!createRow) return false;
        handleCreateRow(createRow, { shouldOpenModalAfterCreate });
      },
    [createRows, handleCreateRow]
  );

  const createRowHandleMultiAssignDone = useCallback(
    (id) =>
      ({
        assigneeIds,
        unassigneeIds,
        assignProjectMembershipIds,
        unassignProjectMembershipIds
      }) => {
        setCreateRows({
          ...createRows,
          [id]: {
            ...createRows[id],
            assignee_ids: assigneeIds,
            unassignee_ids: unassigneeIds,
            assignProjectMembershipIds: assignProjectMembershipIds,
            unassignProjectMembershipIds: unassignProjectMembershipIds
          }
        });
        const createDescription = document.getElementById(
          `task-description-field-${id}`
        );
        if (createDescription) {
          createDescription.focus();
        }
      },
    [createRows]
  );

  const checkFormIsEmpty = useCallback(
    (createRow) => {
      createRow = {
        ...createRow,
        description: descriptionRef.current
      };

      if (isOnProjectView) {
        if (!createRow.description) {
          return true;
        }
      } else {
        if (!createRow.project?.id && !createRow.description) {
          return true;
        }
      }
      return false;
    },
    [isOnProjectView]
  );

  const createRowClickOutside = useCallback(
    (id) => (event) => {
      if (
        isInAnyPopover(event) ||
        (isInAnyModal(event) && !isMemberModalOpen) ||
        event.srcElement.offsetParent?.className.includes('rc-trigger-popup') ||
        event.srcElement.offsetParent?.className.includes('rc-calendar') ||
        event.srcElement.offsetParent?.className.includes(
          'range-option-container'
        ) ||
        !createRows[id] // only active create rows should handle click outside
      ) {
        return;
      }

      const createRow = createRows[id];

      if (!createRow) return false;

      if (checkFormIsEmpty(createRow)) {
        resetCreateState();
        setCreateTaskErrors(null);
      } else {
        handleCreateRow(createRow, { shouldOpenModalAfterCreate: false });
      }
    },
    [
      checkFormIsEmpty,
      createRows,
      handleCreateRow,
      isMemberModalOpen,
      resetCreateState
    ]
  );

  const setCreating = useCallback(
    ({ createRowData, id }) => {
      setCreateRows({
        ...createRows,
        [id]: {
          ...createRowData,
          isCreatingNewTask: true,
          createRow: true,

          handleTaskEditClick: noop
        }
      });
    },
    [createRows]
  );

  const columns = useMemo(() => {
    return taskColumnHeaders.map((taskColumnHeader) => ({
      ...taskColumnHeader,
      loadingRow: loadingRowCells[taskColumnHeader?.headerType] || EmptyDiv,
      taskRow: taskCells[taskColumnHeader?.headerType] || EmptyDiv,
      removedTaskRow:
        removedTaskCells[taskColumnHeader?.headerType] || EmptyDiv,
      taskGroupRow: taskGroupCells[taskColumnHeader?.headerType] || EmptyDiv,
      phaseRow: phaseCells[taskColumnHeader?.headerType] || EmptyDiv,
      activityRow: activityPhaseCells[taskColumnHeader?.headerType] || EmptyDiv,
      taskGroupFooterRow:
        taskGroupFooterCells[taskColumnHeader?.headerType] || EmptyDiv,
      closedWeeklyHeader: stubCellMap[taskColumnHeader?.headerType] || EmptyDiv,
      Cell: stubCellMap[taskColumnHeader?.headerType] || EmptyDiv,
      Header: headerComponents[taskColumnHeader?.headerType] || HeaderCell,
      Footer: EmptyDiv,
      emptyRow: EmptyDiv,
      width: columnWidths[taskColumnHeader?.headerType] || 95
    }));
  }, [taskColumnHeaders]);

  const totalColumnsWidth = useMemo(
    () => columns.reduce((acc, cur) => acc + cur.width, 0),
    [columns]
  );

  const taskProps = useMemo(
    () => ({
      taskIsEditing,
      taskEditProperty,
      projectDetailView: isOnProjectView,
      isOnTeamPageView,
      isOnTeamProject,
      isOnHomeView
    }),
    [
      isOnHomeView,
      isOnProjectView,
      isOnTeamPageView,
      isOnTeamProject,
      taskEditProperty,
      taskIsEditing
    ]
  );

  const formattedTasks = useMemo(
    () =>
      formattedAllTasks.map((task, index, tasks) => ({
        ...task,
        estimated_hours:
          task.estimated_hours !== null
            ? parseFloat(task.estimated_hours)
            : null,
        rowType: taskRemovals[task.id] ? 'removedTaskRow' : 'taskRow',
        taskDestination: taskRemovals[task.id],
        className: taskRemovals[task.id] ? 'removed-task' : '',
        nextTaskId: tasks[index + 1]?.id ?? null,
        draggableId: serializeBar({ itemId: task.id, itemType: 'task' }),
        selectedProject,
        taskProps,
        handlePropertySelect: makeHandlePropertySelect(task.id),
        handleMultiAssignDone: makeHandleMultiAssignDone(task.id),
        handleTaskAttributesUpdate,
        handleTasksAttributesUpdate: handleTaskAttributesUpdate,
        handleTaskEditClick: makeHandleTaskEditClick(task.id),
        unsetTaskEditClick,
        setEditTaskId,
        setEditingHeight,
        handleClickOutside: makeHandleClickOutside(task.id),
        rowHeight:
          (getTruncatedDescription({
            fullText: task.description,
            singleLineCutoff: 60,
            lastLineCutoff: 45,
            numLines: 3
          })?.numDisplayedLines ?? 1) *
            20 +
          42
      })),
    [
      formattedAllTasks,
      handleTaskAttributesUpdate,
      makeHandleClickOutside,
      makeHandleMultiAssignDone,
      makeHandlePropertySelect,
      makeHandleTaskEditClick,
      selectedProject,
      taskProps,
      taskRemovals,
      unsetTaskEditClick
    ]
  );

  const unGroupedRowBuilder = useCallback(
    ({ tasks, customRowProps }) => {
      const createRow = createRows.ungrouped
        ? {
            ...createRows.ungrouped,
            taskProps,
            isDragDisabled: true,
            draggableId: serializeBar({
              itemId: 0,
              itemType: 'newTask'
            }),
            handleMultiAssignDone: createRowHandleMultiAssignDone('ungrouped'),
            handlePropertySelect: createRowHandlePropertySelect('ungrouped'),
            handleUpdate: createRowHandleUpdate('ungrouped'),
            handleSubmit: createRowHandleSubmit('ungrouped'),
            handleClickOutside: createRowClickOutside('ungrouped'),
            createTaskErrors
          }
        : {
            taskProps,
            createRow: true,
            isCreatingNewTask: false,
            isDragDisabled: true,
            draggableId: serializeBar({
              itemId: 0,
              itemType: 'newTask'
            }),
            handleMultiAssignDone: createRowHandleMultiAssignDone('ungrouped'),
            handlePropertySelect: createRowHandlePropertySelect('ungrouped'),
            handleUpdate: createRowHandleUpdate('ungrouped'),
            handleSubmit: createRowHandleSubmit('ungrouped'),
            handleClickOutside: createRowClickOutside('ungrouped'),
            handleTaskEditClick: () => {}
          };

      const list = {
        listItems:
          !isMemberModalOpen || currentFilter?.state === 'incomplete'
            ? [createRow, ...tasks]
            : [...tasks],
        getItemId: (item) => (item.createRow ? 'addTask' : item.id),
        handleClick: (item) => console.log(item),
        rowType: 'taskRow',
        id: 'ungrouped',
        skipEmptyGroups: false,
        skipHeader: true,
        isOpen: true,
        isEmpty: false,
        isFullyLoaded: totalTaskCount <= tasks.length,
        createRowData: {
          ...customCreateRowData
        },
        setCreating,
        setCreateRows,
        descriptionRef
      };
      return [list];
    },
    [
      createRows.ungrouped,
      taskProps,
      createRowHandleMultiAssignDone,
      createRowHandlePropertySelect,
      createRowHandleUpdate,
      createRowHandleSubmit,
      createRowClickOutside,
      createTaskErrors,
      isMemberModalOpen,
      currentFilter,
      totalTaskCount,
      customCreateRowData,
      setCreating
    ]
  );

  const taskGroupGroupedRowBuilder = useCallback(
    ({ tasks, members, customRowProps, taskGroups = [] }) => {
      const taskGroupOrder = taskGroups.map((taskGroup) => taskGroup.id);
      const taskGroupLists = taskGroups.reduce((acc, cur, index) => {
        const groupIsOpen =
          isOpen[cur.id] === undefined ? allOpen : isOpen[cur.id];
        const createRow = createRows[cur.id];
        acc[cur.id] = {
          listItems: createRow
            ? [
                {
                  selectedProject,
                  ...createRow,
                  taskProps,
                  createRow: true,
                  // assignments: [],
                  isCreatingNewTask: true,
                  handleMultiAssignDone: createRowHandleMultiAssignDone(cur.id),
                  handlePropertySelect: createRowHandlePropertySelect(cur.id),
                  handleUpdate: createRowHandleUpdate(cur.id),
                  handleSubmit: createRowHandleSubmit(cur.id),
                  handleClickOutside: createRowClickOutside(cur.id),
                  handleTaskEditClick: () => {},
                  setEditingHeight
                }
              ]
            : [
                {
                  selectedProject,
                  task_group_id: cur.id,
                  project_id: cur.project_id,
                  taskProps,
                  createRow: true,
                  isCreatingNewTask: false,
                  isDragDisabled: true,
                  draggableId: serializeBar({
                    itemId: cur.id,
                    itemType: 'newTask'
                  }),
                  handleMultiAssignDone: createRowHandleMultiAssignDone(cur.id),
                  handlePropertySelect: createRowHandlePropertySelect(cur.id),
                  handleUpdate: createRowHandleUpdate(cur.id),
                  handleClickOutside: createRowClickOutside(cur.id),
                  handleTaskEditClick: () => {}
                }
              ],
          closedItems: [],
          // customItems: [
          //   {
          //     id: cur.id,
          //     rowHeight: 27,
          //     rowType: 'Header'
          //   }
          // ],
          headerData: {
            taskGroup: { ...cur, collapsed: !groupIsOpen, title: cur.name },
            draggableId: serializeBar({
              itemId: cur.id,
              itemType: 'taskGroup'
            }),
            ...customHeaderData
          }, // fit taskGroup to current component prop structure. TODO - refactor components to use correct names
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'taskRow',
          headerType: 'taskGroupRow',
          headerHeight: 60,
          id: cur.id,
          draggableId: serializeBar({
            itemId: cur.id,
            itemType: 'taskGroup'
          }),
          isDragDisabled: false,
          skipEmptyGroups: false,
          isOpen: groupIsOpen,
          isSelected: selectedGroupIds[cur.id],
          isEmpty: false, // !taskGroupCounts[cur.id],
          numItems: taskGroupCounts?.[cur.id],
          isFullyLoaded: null,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          createRowData: {
            task_group_id: cur.id,
            project_id: cur.project_id,
            selectedProject,
            ...customCreateRowData
          },
          setCreating,
          setCreateRows,
          renderSummaryItem: (item) => item,
          descriptionRef
        };
        return acc;
      }, {});

      tasks.forEach((task) => {
        const group = taskGroupLists[task.task_group_id] || {};
        const taskWithIndex = {
          ...task,
          rowNumber: group.listItems?.length || 0,
          isSelected: !!selectedGroupIds[group.id] || !!selectedTaskIds[task.id]
        };
        taskGroupLists[task.task_group_id]?.listItems.push(taskWithIndex);
      });
      const listsWithItems = [];
      const listsWithoutItems = [];

      taskGroupOrder.forEach((id) => {
        const list = taskGroupLists[id];

        if (list) {
          const tasksInList = list.listItems;
          const totalEstHours = sumEstHours(tasksInList);

          list.taskIds = list.listItems
            .map((item) => item.id)
            .filter((id) => id);
          const taskCount = list.taskIds.length;
          list.headerData = {
            ...list.headerData
          };
          list.isFullyLoaded =
            groupsFullyLoadedOnce[list.id] ||
            taskGroupCounts === null || // task group counts is null if there are no tasks to group by, which means the list *is* fully loaded
            (taskGroupCounts &&
              Object.keys(taskGroupCounts).length &&
              taskCount >= (taskGroupCounts[list.id] || 0));
          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);

          if (isOpen && list.isFullyLoaded) {
            const groupFooterRow = {
              rowType: 'taskGroupFooterRow',
              draggableId: serializeBar({
                itemType: 'taskGroupFooter',
                itemId: id
              }),
              id,
              totalEstHours,
              rowHeight: 25,
              isDragDisabled: true
            };
            list.listItems.push(groupFooterRow);
          }
        }
      });
      taskGroupOrder.forEach((id, index) => {
        const list = taskGroupLists[id];
        list.createRowData.nextTaskId = findNextTaskId(
          taskGroupOrder.slice(index),
          taskGroupLists
        );
      });
      const listsInOrder = taskGroupOrder
        .map((id) => taskGroupLists[id])
        .filter((list) => !!list);
      if (listsInOrder.length) {
        const [firstList] = listsInOrder;
        firstList.skipHeader = true;
        firstList.customItems = [];
        firstList.closedItems = [];
      }
      let fetchOffset = 0;
      listsInOrder.forEach((list) => {
        /* fetchOffset for a given list needs to be increment by it's number of
          already loaded tasks without affecting the overall fetchOffset
        */
        list.fetchOffset = fetchOffset + +list.taskIds.length;
        fetchOffset += +list.numItems || 0;
      });
      return listsInOrder;
    },
    [
      isOpen,
      allOpen,
      createRows,
      selectedProject,
      taskProps,
      createRowHandleMultiAssignDone,
      createRowHandlePropertySelect,
      createRowHandleUpdate,
      createRowHandleSubmit,
      createRowClickOutside,
      customHeaderData,
      selectedGroupIds,
      taskGroupCounts,
      customCreateRowData,
      setCreating,
      selectedTaskIds,
      groupsFullyLoadedOnce
    ]
  );

  const activitiesGroupedRowBuilder = useCallback(
    ({
      tasks,
      members,
      customRowProps,
      taskGroups = [],
      phase,
      baseFetchOffsetRef
    }) => {
      const isHeadless = !phase.activity_order.length;
      const activityPhasesByActivityId = keyBy(
        phase.activity_phases,
        (item) => item.activity_id
      );
      const activityPhases = !isHeadless
        ? phase.activity_order.map(
            (activityId) => activityPhasesByActivityId[activityId]
          )
        : [
            {
              isActivityHeadless: true,
              activity_id: null,
              id: `headless--${phase.id}`,
              activity_phase_id: null,
              phase_id: phase.id,
              defaultActivityPhaseId: phase.activity_phases?.find(
                (activityPhase) => activityPhase.is_default
              )?.id
            }
          ];
      const activityPhaseOrder = activityPhases.map(
        (activityPhase) => activityPhase.id
      );

      const activityPhaseLists = activityPhases.reduce((acc, cur, index) => {
        if (!cur) {
          return acc;
        }
        const groupIsOpen =
          isOpen[cur.id] === undefined ? allOpen : isOpen[cur.id];
        const createRow = createRows[cur.id];
        const activity = activitiesHash[cur.activity_id];
        const activityPhaseId = isHeadless ? undefined : cur.id;
        acc[cur.id] = {
          listItems:
            cur.id === NO_PHASE
              ? []
              : createRow
              ? [
                  {
                    selectedProject,
                    ...createRow,
                    taskProps,
                    createRow: true,
                    isCreatingNewTask: true,
                    handleMultiAssignDone: createRowHandleMultiAssignDone(
                      cur.id
                    ),
                    handlePropertySelect: createRowHandlePropertySelect(cur.id),
                    handleUpdate: createRowHandleUpdate(cur.id),
                    handleClickOutside: createRowClickOutside(cur.id),
                    handleSubmit: createRowHandleSubmit(cur.id),
                    handleTaskEditClick: () => {},
                    setEditingHeight
                  }
                ]
              : [
                  {
                    selectedProject,
                    activity_phase_id: activityPhaseId,
                    project_id: cur.project_id,
                    taskProps,
                    createRow: true,
                    isCreatingNewTask: false,
                    isDragDisabled: true,
                    draggableId: serializeBar({
                      itemId: cur.id,
                      itemType: 'newTask'
                    }),
                    handleMultiAssignDone: createRowHandleMultiAssignDone(
                      cur.id
                    ),
                    handlePropertySelect: createRowHandlePropertySelect(cur.id),
                    handleUpdate: createRowHandleUpdate(cur.id),
                    handleClickOutside: createRowClickOutside(cur.id),
                    handleSubmit: createRowHandleSubmit(cur.id),
                    handleTaskEditClick: () => {}
                  }
                ],
          endItems: [], // tasks are added one level up - append footer row after tasks inserted
          closedItems: [],
          headerData: {
            activityPhase: {
              ...cur,
              collapsed: !groupIsOpen,
              activity,
              name: activity?.title,
              activity_phase_id: activityPhaseId
            },
            draggableId: serializeBar({
              itemId: cur.id,
              itemType: 'activity'
            }),
            ...customHeaderData
          }, // fit taskGroup to current component prop structure. TODO - refactor components to use correct names
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'taskRow',
          headerType: 'activityRow',
          headerHeight: 60,
          id: cur.id,
          sectionId: phase.id,
          draggableId: serializeBar({ itemId: cur.id, itemType: 'activity' }),
          isDragDisabled: true,
          skipEmptyGroups: false,
          skipHeader: isHeadless,
          isOpen: isHeadless || groupIsOpen,
          isSelected: selectedGroupIds[cur.id],
          isEmpty: false, // !taskGroupCounts[cur.id],
          numItems:
            taskGroupCounts?.[isHeadless ? cur.defaultActivityPhaseId : cur.id],
          isFullyLoaded: null,
          hasSubLists: true,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          createRowData: {
            activity_phase_id: activityPhaseId,
            phase_id: phase.id,
            activity_id: activity?.id,
            project_id: selectedProject.id,
            selectedProject,
            task_group_id: selectedProject.task_group_order[0],
            ...customCreateRowData
          },
          setCreating,
          setCreateRows,
          renderSummaryItem: (item) => item,
          descriptionRef
        };
        return acc;
      }, {});

      // tasks.forEach(task => {
      //   const group = activityPhaseLists[task.activity_phase_id] || {};
      //   const taskWithIndex = {
      //     ...task,
      //     rowNumber: group.listItems?.length || 0,
      //     isSelected: !!selectedGroupIds[group.id] || !!selectedTaskIds[task.id]
      //   };
      //   activityPhaseLists[task.activity_phase_id]?.listItems.push(
      //     taskWithIndex
      //   );
      // });
      const listsWithItems = [];
      const listsWithoutItems = [];

      activityPhaseOrder.forEach((id) => {
        const list = activityPhaseLists[id];

        if (list) {
          const tasksInList = list.listItems;
          const totalEstHours = sumEstHours(tasksInList);

          list.taskIds = list.listItems
            .map((item) => item.id)
            .filter((id) => id);

          const taskCount = list.taskIds.length;
          list.headerData = {
            ...list.headerData
          };
          list.isFullyLoaded =
            groupsFullyLoadedOnce[
              isHeadless
                ? list.headerData.activityPhase.defaultActivityPhaseId
                : list.id
            ] ||
            taskGroupCounts === null || // task group counts is null if there are no tasks to group by, which means the list *is* fully loaded
            (taskGroupCounts &&
              Object.keys(taskGroupCounts).length &&
              taskCount >= (list.numItems || 0));

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);

          if (isOpen && list.isFullyLoaded) {
            const groupFooterRow = {
              rowType: 'taskGroupFooterRow',
              draggableId: serializeBar({
                itemType: 'taskGroupFooter',
                itemId: id
              }),
              id,
              totalEstHours,
              rowHeight: 25,
              isDragDisabled: true
            };
            list.endItems.push(groupFooterRow);
          }
        }
      });

      const listsInOrder = activityPhaseOrder
        .map((id) => activityPhaseLists[id])
        .filter((list) => !!list);

      // list.taskIds.length is always zero without setTimeout even though it has elements in the array
      // put it on the async event loop, so it will run straight afterwards.
      setTimeout(() => {
        listsInOrder.forEach((list) => {
          list.fetchOffset = baseFetchOffsetRef.offset + list.taskIds.length;
          baseFetchOffsetRef.offset += +list.numItems || 0;
        });
      }, 0);
      // if (listsInOrder.length) {
      //   const [firstList] = listsInOrder;
      //   firstList.skipHeader = true;
      //   firstList.customItems = [];
      //   firstList.closedItems = [];
      // }
      return listsInOrder;
    },
    [
      isOpen,
      allOpen,
      createRows,
      activitiesHash,
      selectedProject,
      taskProps,
      createRowHandleMultiAssignDone,
      createRowHandlePropertySelect,
      createRowHandleUpdate,
      createRowClickOutside,
      createRowHandleSubmit,
      customHeaderData,
      selectedGroupIds,
      taskGroupCounts,
      customCreateRowData,
      setCreating,
      groupsFullyLoadedOnce
    ]
  );

  const phaseGroupedRowBuilder = useCallback(
    ({ tasks, members, customRowProps, taskGroups = [], phases = [] }) => {
      // store in object to allow passing/incrementing in different function
      const baseFetchOffsetRef = { offset: 0 };
      const phaseOrder = Array.from(
        new Set([
          ...phases.map((phase) => phase?.id).filter((id) => !!id),
          'no-phase'
        ])
      );

      const phaseLists = [
        ...phases,
        {
          id: 'no-phase',
          name: 'No Phase',
          activity_order: [],
          activity_phases: []
        }
      ].reduce((acc, cur, index) => {
        if (!cur) {
          return acc;
        }
        const groupIsOpen =
          isOpen[cur.id] === undefined ? allOpen : isOpen[cur.id];
        acc[cur.id] = {
          listItems: [], // create row handled at activity phase level
          closedItems: [],

          headerData: {
            phase: { ...cur, collapsed: !groupIsOpen, title: cur.name },
            draggableId: serializeBar({
              itemId: cur.id,
              itemType: 'phase'
            }),
            ...customHeaderData
          }, // fit taskGroup to current component prop structure. TODO - refactor components to use correct names
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'taskRow',
          headerType: 'phaseRow',
          headerHeight: 60,
          id: cur.id,
          draggableId: serializeBar({ itemId: cur.id, itemType: 'phase' }),
          isDragDisabled: false,
          skipEmptyGroups: false,
          isOpen: groupIsOpen,
          isSelected: selectedGroupIds[cur.id],
          isEmpty: false, // !taskGroupCounts[cur.id],
          isFullyLoaded: null,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          createRowData: {},
          setCreating,
          setCreateRows,
          renderSummaryItem: (item) => item,
          descriptionRef
        };

        acc[cur.id].fetchOffset = baseFetchOffsetRef.offset; // offset before incrementing by activities
        const phaseActivities = activitiesGroupedRowBuilder({
          phaseIndex: index,
          phase: cur,
          customRowProps,
          tasks,
          members,
          taskGroups,
          phases,
          baseFetchOffsetRef
        });
        acc[cur.id].phaseActivities = phaseActivities;
        acc[cur.id].listItems.push(...phaseActivities);
        return acc;
      }, {});

      // step 1 hash activityPhase lists by activity_phase_id or `phase--phase.id`
      // step 2 - push task into activityPhase list if exists, fallback to phase list (headless activityPhase)

      const activityPhaseLists = flatten(
        Object.values(phaseLists).map((list) => list.listItems)
      ).filter((list) => list.headerType === 'activityRow');

      const activityPhaseListHash = keyBy(
        activityPhaseLists,
        (list) => list.id
      );

      tasks.forEach((task) => {
        const activityPhaseList = task.phase_id
          ? activityPhaseListHash[task.activity_phase_id] ||
            activityPhaseListHash[`headless--${task.phase_id}`]
          : activityPhaseListHash['headless--no-phase'];

        const taskWithIndex = {
          ...task,
          rowNumber: activityPhaseList?.listItems?.length || 0,
          isSelected:
            !!selectedGroupIds[activityPhaseList?.id] ||
            !!selectedTaskIds[task.id]
        };

        activityPhaseList?.listItems.push(taskWithIndex);
        activityPhaseList?.taskIds.push(task.id);
      });
      activityPhaseLists.forEach((list) => {
        if (list.listItems.length > list.numItems) {
          // account for header in length
          list.isFullyLoaded = true;
        }
        list.listItems = [...list.listItems, ...list.endItems];
      });
      const listsWithItems = [];
      const listsWithoutItems = [];

      phaseOrder.forEach((id) => {
        const list = phaseLists[id];

        if (list) {
          list.taskIds = list.listItems
            .map((item) => item.id)
            .filter((id) => id);

          list.headerData = {
            ...list.headerData
          };

          list.isFullyLoaded = list.phaseActivities?.every(
            (list) => list.isFullyLoaded
          );

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      phaseOrder.forEach((id, index) => {
        const list = phaseLists[id];
        list.createRowData.nextTaskId = findNextTaskId(
          phaseOrder.slice(index),
          phaseLists
        );
      });
      if ((phaseLists['no-phase']?.listItems.length ?? 0) < 2) {
        delete phaseLists['no-phase'];
      }
      const listsInOrder = phaseOrder
        .map((id) => phaseLists[id])
        .filter((list) => !!list);
      if (listsInOrder.length) {
        const [firstList] = listsInOrder;
        firstList.skipHeader = true;
        firstList.customItems = [];
        firstList.closedItems = [];
      }
      const flattenedOrderedActivityPhaseLists = flatten(
        listsInOrder.map((list) => list.listItems)
      );
      const flattenedOrderedActivityPhaseListsIds =
        flattenedOrderedActivityPhaseLists.map((list) => list.id);

      flattenedOrderedActivityPhaseLists.forEach((list, index) => {
        list.createRowData.nextTaskId = findNextTaskId(
          flattenedOrderedActivityPhaseListsIds.slice(index),
          activityPhaseListHash
        );
      });
      listsInOrder.forEach((list) => {
        list.numItems = sum(
          list.listItems.map((listItem) => listItem.numItems || 0)
        );
      });
      return listsInOrder;
    },
    [
      isOpen,
      allOpen,
      customHeaderData,
      selectedGroupIds,
      setCreating,
      activitiesGroupedRowBuilder,
      selectedTaskIds
    ]
  );

  const listBuilders = useMemo(
    () => ({
      [VIEW_BY.NONE]: unGroupedRowBuilder,
      [VIEW_BY.PHASES]: phaseGroupedRowBuilder,
      [VIEW_BY.TASK_GROUPS]: taskGroupGroupedRowBuilder
    }),
    [phaseGroupedRowBuilder, taskGroupGroupedRowBuilder, unGroupedRowBuilder]
  );

  const handleSetIsOpen = useCallback(
    ({ name, value }) => {
      const nextIsOpen = { ...isOpen, [name]: value };

      const getIsAllCollapsed = () => {
        const groups =
          viewBy === VIEW_BY.TASK_GROUPS ? orderedTaskGroups : orderedPhases;
        return groups.every((group) => {
          return (
            group.id === name ||
            (!allOpen && isOpen[group.id] === undefined) ||
            isOpen[group.id] === false
          );
        });
      };

      if (!value) {
        if (getIsAllCollapsed()) {
          setAllCollapsed(true);
          if (allOpen) {
            setAllOpen(false);
          }
        }
      } else {
        if (allCollapsed) {
          setAllCollapsed(false);
        }
        // snapshot of lists from previous calculation - necessary because InfiniteLoader does not call loadMore in cases where the open action does not result in a different number of items on screen.
        const listToOpen = orderedListsRef.current?.find(
          (list) => list.id === name
        );
        if (
          listToOpen &&
          !listToOpen.isFullyLoaded &&
          listToOpen.fetchOffset !== undefined &&
          !Number.isNaN(listToOpen.fetchOffset)
        ) {
          loadMore(listToOpen.fetchOffset);
        }
      }

      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }

      resetOnClose({ name, value });
      setIsOpen(nextIsOpen);
    },
    [
      allCollapsed,
      allOpen,
      isOpen,
      loadMore,
      orderedPhases,
      orderedTaskGroups,
      resetOnClose,
      viewBy
    ]
  );

  const handleResetHeightCache = useCallback(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  const addNewTimesheetRow = useCallback(
    (payload) => dispatch(setIsAddingNewTimesheetRow(payload)),
    [dispatch]
  );

  const customRowProps = useMemo(
    () => ({
      handleSetIsOpen,
      isOpen,
      me,
      isWeek,
      activeSection,
      handleResetHeightCache,
      setIsAddingNewTimesheetRow: addNewTimesheetRow,
      resetCreateState,
      rowEditing: !!rowEditing,
      allCollapsed
    }),
    [
      activeSection,
      addNewTimesheetRow,
      allCollapsed,
      handleResetHeightCache,
      handleSetIsOpen,
      isOpen,
      isWeek,
      me,
      resetCreateState,
      rowEditing
    ]
  );
  const lists = useMemo(() => {
    const listBuilder = isOnProjectView
      ? listBuilders[viewBy]
      : listBuilders[VIEW_BY.NONE];
    // const listBuilder = listBuilders[viewBy];
    if (!listBuilder) {
      return [];
    }
    return [
      ...listBuilder({
        tasks: formattedTasks,
        members: orderedTeamMembers,
        projects: orderedProjects,
        phases: orderedPhases,
        taskGroups: orderedTaskGroups,
        customRowProps
      }),
      emptyRow
    ];
  }, [
    customRowProps,
    formattedTasks,
    isOnProjectView,
    listBuilders,
    orderedPhases,
    orderedProjects,
    orderedTaskGroups,
    orderedTeamMembers,
    viewBy
  ]);
  useEffect(() => {
    orderedListsRef.current = lists;
  }, [lists]);

  useEffect(() => {
    const newFullyLoadedGroups = lists
      .filter((list) => list.isFullyLoaded && !groupsFullyLoadedOnce[list.id])
      .map((list) => list.id);
    if (newFullyLoadedGroups.length) {
      setGroupsFullyLoadedOnce({
        ...groupsFullyLoadedOnce,
        ...keyBy(newFullyLoadedGroups)
      });
    }
  }, [groupsFullyLoadedOnce, lists, setGroupsFullyLoadedOnce]);

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps,
      engaged: {},
      search: {},
      emptyRow
    });
  }, [customRowProps, lists]);

  useEffect(() => {
    handleResetHeightCache();
    rebuildTooltip();
  }, [handleResetHeightCache, orderedTaskGroups, orderedPhases]);

  const firstListId = useMemo(() => lists[0]?.id, [lists]);

  var resetOnClose = useCallback(
    ({ value, name }) => {
      if (!value) {
        const index = rows.findIndex((row) => row.listType === name);
        if (index !== -1) {
          listRef.current.scrollToItem(index - 1);
        }
      }
    },
    [rows]
  );

  const numberOfAdditionalRowsForThreshold = useMemo(
    () => rows.filter((row) => !row.isRow).length,
    [rows]
  );

  const isLoading = useMemo(() => {
    if (totalTaskCount === 0) {
      // explicit 0 check only gets hit for explicit 0 returns from backend instead of falsey values
      return false;
    }
    // only true on first page load - ensure loading is true even if there are rows for groups (projects, members) present
    if (isLoadingInitial) {
      return true;
    }
    return false; // edit to customize loading based on individual row loading states
  }, [isLoadingInitial, totalTaskCount]);

  const getItemSize = useCallback(
    (index) =>
      index === editingHeight.index
        ? editingHeight.height
        : rows[index]?.itemHeight || ITEM_HEIGHT,
    [editingHeight, rows]
  );

  const isCreateRow = formattedAllTasks?.[0]?.createRow;

  useEffect(() => {
    if (!isLoadingInitial) {
      setActiveSection(firstListId);
    }
  }, [firstListId, isLoadingInitial]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [isCreateRow, orderedTaskGroups]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(
        !prevEditingHeight?.index
          ? editingHeight.index
          : Math.min(prevEditingHeight.index, editingHeight.index)
      );
    }
  }, [editingHeight, prevEditingHeight]);

  useEffect(() => {
    if (totalTaskCount === null && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [totalTaskCount]);

  const handleScroll = ({ visibleStartIndex }) => {
    const isScrolled = visibleStartIndex !== 0;
    if (!isScrolled) {
      setActiveSection(firstListId);
      setIsScrolled(false);
      return;
    }
    const item = rows[visibleStartIndex - 1];
    if (!item) {
      return;
    }
    if (item.list.sectionId && item.list.sectionId !== activeSection) {
      setActiveSection(item.list.sectionId);
    } else if (item.listType !== activeSection) {
      setActiveSection(item.listType);
    }
    if (isScrolled !== stateIsScrolled) {
      setIsScrolled(isScrolled);
    }
  };

  const loadMoreItems = useCallback(
    (startIndex) => {
      const item = rows[startIndex - 1];
      if (item?.rowType === 'loadingRow') {
        loadMore(item.list.fetchOffset ?? startIndex);
      }
    },
    [loadMore, rows]
  );

  const handleCollapseAll = useCallback(() => {
    setAllCollapsed(true);
    const groups =
      viewBy === VIEW_BY.TASK_GROUPS ? orderedTaskGroups : orderedPhases;

    setIsOpen({
      ...groups.reduce((acc, cur) => {
        acc[cur.id] = false;
        return acc;
      }, {})
    });

    if (allOpen) {
      setAllOpen(false);
    }
  }, [allOpen, orderedPhases, orderedTaskGroups, viewBy]);

  const handleExpandAll = useCallback(() => {
    setIsOpen({});
    setAllOpen(true);
    if (allCollapsed) {
      setAllCollapsed(false);
    }
  }, [allCollapsed]);

  const toggleCollapseAll = useCallback(() => {
    if (allCollapsed) {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [allCollapsed, handleCollapseAll, handleExpandAll]);

  const onBeforeCapture = useCallback(({ draggableId }) => {
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md#onbeforecapture - read warnings about modifying position of dragging element before implementing any element repositioning logic.
    const itemType = deserializeBar(draggableId)?.itemType;
    if (itemType === 'taskGroup' || itemType === 'phase') {
      setIsOpen({});
      setAllOpen(false);
      setAllCollapsed(true);
      setIsDragging(true);
    }
  }, []);

  const selectedProjectId = selectedProject?.id;
  const handleDragEnd = useCallback(
    ({ draggableId, destination, source, type }) => {
      const additionalDragTypes = {
        'task-statuses': true,
        'task-priorities': true
      };
      if (additionalDragTypes[type]) {
        handleAdditionalDragTypes({ draggableId, destination, source, type });
      }
      if (!destination) {
        return;
      }
      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;
      const { itemId, itemType } = deserializeBar(draggableId);
      setIsDragging(false);

      // TODO figure out how to abstract drag handlers
      if (itemType === 'task') {
        const task = taskHash[itemId];
        if (!task) {
          return;
        }
        // toLowerGroup is set to true if a task is being moved to a lower group
        let toLowerGroup = false;
        const initialDestinationGroup = lists[0];

        const taskRepositionHelper = rows.slice(0, destinationIndex).reduce(
          (acc, cur, index) => {
            if (
              cur.rowType === 'taskGroupRow' ||
              cur.rowType === 'activityRow' ||
              cur.rowType === 'phaseRow'
            ) {
              acc.destinationGroup = cur;
              acc.index = index + 1;
              if (!toLowerGroup && acc.index > sourceIndex) {
                toLowerGroup = true;
              }
            }
            if (
              cur.rowType === 'taskRow' ||
              cur.rowType === 'taskGroupRow' ||
              cur.rowType === 'activityRow' ||
              cur.rowType === 'phaseRow'
            ) {
              acc.taskBeforeId = cur.id;
              acc.taskBefore = cur;
            }

            return acc;
          },
          {
            destinationGroup: buildHeader(
              { list: initialDestinationGroup },
              customRowProps
            ),
            index: 0
          }
        );
        const {
          destinationGroup,
          index,
          taskBeforeId = undefined
        } = taskRepositionHelper;

        if (destinationGroup) {
          batch(() => {
            dispatch(
              insertTaskBefore({
                taskId: task.id,
                taskBeforeId
              })
            );

            if (viewBy === VIEW_BY.TASK_GROUPS && !isMemberModalOpen) {
              dispatch(
                moveTaskToGroup({
                  sourceTaskGroupId: task.task_group_id,
                  destinationTaskGroupId: destinationGroup.id,
                  taskId: task.id,
                  index:
                    destinationIndex - (index - (toLowerGroup ? 1 : 0)) - 1,
                  groupType: 'taskGroup'
                })
              );
            } else if (viewBy === VIEW_BY.PHASES && !isMemberModalOpen) {
              let update;
              if (destinationGroup.rowType === 'phaseRow') {
                const { phase = {} } = destinationGroup;
                const [activityPhase] = phase?.activity_phases ?? [];
                // not explicitly updating activity when dragging b/c activity is not configured on phase
                update = {
                  phase_id: phase.id,
                  activity_phase_id: activityPhase?.id
                };
              } else if (
                destinationGroup.rowType === 'activityRow' &&
                !isMemberModalOpen
              ) {
                const { activityPhase = {} } = destinationGroup;

                update = {
                  phase_id: activityPhase?.phase_id,
                  activity_phase_id: activityPhase?.id,
                  activity_id: activityPhase?.activity_id
                };
              }
              // if no update, we didn't find a valid destination for the task
              if (update?.activity_phase_id) {
                dispatch(
                  moveTaskToGroup({
                    sourceTaskGroupId: task.activity_phase_id,
                    destinationTaskGroupId: update.activity_phase_id,
                    taskId: task.id,
                    index:
                      destinationIndex - (index - (toLowerGroup ? 1 : 0)) - 1,
                    groupType: 'activityPhase',
                    changedTaskAttributes: update
                  })
                );
              }
            }
          });
        }
        return;
      }

      if (itemType === 'taskGroup') {
        const newOrder = orderedTaskGroups.map((taskGroup) => taskGroup.id);
        if (
          destination?.droppableId === 'droppable' &&
          source?.droppableId === 'droppable'
        ) {
          newOrder.splice(sourceIndex + 1, 1);
          newOrder.splice(destinationIndex + 1, 0, itemId);
        } else if (
          destination?.droppableId === 'droppable' &&
          source?.droppableId === 'table-header'
        ) {
          newOrder.splice(0, 1);
          newOrder.splice(destinationIndex, 0, itemId);
        } else if (
          destination?.droppableId === 'table-header' &&
          source?.droppableId === 'droppable'
        ) {
          newOrder.splice(sourceIndex + 1, 1);
          newOrder.splice(destinationIndex, 0, itemId);
        } else if (
          destination?.droppableId === 'table-header' &&
          source?.droppableId === 'table-header'
        ) {
          // do nothing, the header was dropped back in place
        }

        dispatch(
          reorderProjectTaskGroups({
            projectId: orderedTaskGroups[0]?.project_id,
            order: newOrder
          })
        );
        return;
      }
      if (itemType === 'phase') {
        const newOrder = orderedPhases.map((phase) => phase.id);
        let moveBehindPhaseId;
        const movePhaseId = +itemId;
        if (
          destination?.droppableId === 'droppable' &&
          source?.droppableId === 'droppable'
        ) {
          moveBehindPhaseId = +newOrder[destinationIndex + 1];
          newOrder.splice(sourceIndex + 1, 1);
          newOrder.splice(destinationIndex + 1, 0, movePhaseId);
        } else if (
          destination?.droppableId === 'droppable' &&
          source?.droppableId === 'table-header'
        ) {
          moveBehindPhaseId = +newOrder[destinationIndex];
          newOrder.splice(0, 1);
          newOrder.splice(destinationIndex, 0, movePhaseId);
        } else if (
          destination?.droppableId === 'table-header' &&
          source?.droppableId === 'droppable'
        ) {
          moveBehindPhaseId = null;
          newOrder.splice(sourceIndex + 1, 1);
          newOrder.splice(destinationIndex, 0, movePhaseId);
        } else if (
          destination?.droppableId === 'table-header' &&
          source?.droppableId === 'table-header'
        ) {
          // do nothing, the header was dropped back in place
        }
        dispatch(
          updateProjectModules({
            projectId: selectedProjectId,
            phaseOrder: newOrder,
            movePhaseId,
            moveBehindPhaseId
          })
        );
      }
    },
    [
      handleAdditionalDragTypes,
      taskHash,
      lists,
      rows,
      customRowProps,
      dispatch,
      viewBy,
      isMemberModalOpen,
      orderedTaskGroups,
      orderedPhases,
      selectedProjectId
    ]
  );

  const activeList = lists.find(
    (list) => list.id === activeSection || list.sectionType === activeSection
  );

  const StickyEl = stickyHeaderComponents[activeList?.headerType];
  const headerItem =
    activeList && buildHeader({ list: activeList, customRowProps });
  const orderedGroups =
    viewBy === VIEW_BY.TASK_GROUPS
      ? orderedTaskGroups
      : viewBy === VIEW_BY.PHASES
      ? orderedPhases
      : [];

  const isDragDisabled = !!Object.keys(createRows).length;
  const isGroupDragDisabled = orderedGroups.length < 2 || isDragDisabled;

  const itemType =
    viewBy === VIEW_BY.TASK_GROUPS
      ? 'taskGroup'
      : viewBy === VIEW_BY.PHASES
      ? 'phase'
      : 'unknown';

  const isDefaultListKey =
    viewBy === VIEW_BY.TASK_GROUPS
      ? 'is_default'
      : viewBy === VIEW_BY.PHASES
      ? 'is_like_default'
      : 'unknown';

  const headerKey = serializeBar({
    itemId: headerItem?.[itemType]?.id || 'test',
    itemType
  });

  const isDefaultList = lists[0]?.headerData
    ? lists[0]?.headerData[itemType]?.[isDefaultListKey]
    : true;
  const numLists = orderedGroups?.length;

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onBeforeCapture={onBeforeCapture}
    >
      <StyledTaskTable
        isPersonalProject={isPersonalProject}
        isMemberModalOpen={isMemberModalOpen}
        taskIsEditing={!!rowEditing}
        className="task-table"
        isStickyRowClosed={!headerItem?.isOpen}
        isLoading={isLoading}
      >
        {(isDragging ||
          (viewBy !== VIEW_BY.NONE && activeList && StickyEl)) && (
          <>
            <TasksTableOptions
              numLists={numLists}
              toggleCollapseAll={toggleCollapseAll}
              allCollapsed={allCollapsed}
              projectId={selectedProject?.id}
              viewBy={viewBy}
              isDefaultList={isDefaultList}
            />
            <div style={{ paddingLeft: '16px', position: 'absolute', top: 30 }}>
              <Droppable droppableId="table-header">
                {(droppableProvided) => (
                  <div ref={droppableProvided.innerRef}>
                    <Draggable
                      index={0}
                      draggableId={headerKey}
                      key={headerKey}
                      isDragDisabled={isGroupDragDisabled}
                    >
                      {(draggableProvided) => (
                        <StickyEl
                          row={{ original: headerItem }}
                          isStickyHeader
                          totalColumnsWidth={totalColumnsWidth}
                          onBeforeCapture={onBeforeCapture}
                          draggableProvided={draggableProvided}
                          isOnlyTaskGroup={orderedGroups.length < 2}
                        />
                      )}
                    </Draggable>
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </>
        )}
        {isMemberModalOpen && (
          <TasksTableOptions
            numLists={numLists}
            toggleCollapseAll={toggleCollapseAll}
            allCollapsed={allCollapsed}
            projectId={selectedProject?.id}
            viewBy={viewBy}
            isDefaultList={isDefaultList}
            isMemberModalOpen={isMemberModalOpen}
          />
        )}
        <DevProps
          data={{
            rows
          }}
        />
        <Table
          columns={columns}
          data={!isLoading ? rows : []}
          numberOfAdditionalRowsForThreshold={
            numberOfAdditionalRowsForThreshold
          }
          onDragEnd={() => {}} // handled in this components DragDropContext
          onBeforeCapture={() => {}} // handled in this components DragDropContext
          virtual
          getItemSize={getItemSize}
          maxHeight={window.innerHeight - MAX_TABLE_HEIGHT_BUFFER}
          loadMoreItems={loadMoreItems}
          handleScroll={handleScroll}
          itemHeight={ITEM_HEIGHT}
          isVariableSizeTable
          customRowProps={customRowProps}
          listRef={listRef}
          infiniteLoaderRef={infiniteLoaderRef}
          isDragDisabled={!!Object.keys(createRows).length}
          stateReducer={stateReducer}
          isInDragContext
          totalColumnsWidthOverride={totalColumnsWidth + SCROLLBAR_WIDTH}
          classNames={{ list: 'scrollbar' }}
        />
        {isLoading &&
          (isOnTeamPageView ? (
            <div
              style={{
                width: '100%',
                paddingLeft: 23,
                marginLeft: 3
              }}
            >
              <ContentLoader
                height="468"
                width="892"
                primaryColor="#efefef"
                secondaryColor="#fff"
                style={{ margin: '-10px 20px 0px' }}
                viewBox="0 0 892 468"
              >
                <rect x="0" y="5" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="65" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="125" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="185" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="245" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="305" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="365" rx="2" ry="2" width="892" height="48" />
                <rect x="0" y="425" rx="2" ry="2" width="892" height="48" />
              </ContentLoader>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                paddingLeft: 46
              }}
            >
              <ContentLoader
                height="100"
                primaryColor="#ddd"
                secondaryColor="#eee"
                style={{ margin: '-10px 20px 0px' }}
              >
                <rect x="0" y="5" rx="2" ry="2" width="100%" height="15" />
                <rect x="0" y="25" rx="2" ry="2" width="100%" height="15" />
                <rect x="0" y="45" rx="2" ry="2" width="100%" height="15" />
                <rect x="0" y="66" rx="2" ry="2" width="100%" height="15" />
              </ContentLoader>
            </div>
          ))}
      </StyledTaskTable>
    </DragDropContext>
  );
};

export default TaskTable;
