import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyUserId } from 'selectors';
import { useFilterFinder } from 'DashboardModule/hooks/useFilterFinder';
import TimesheetWidget from 'views/Home/Widgets/Widgets/TimesheetWidget/TimesheetWidget';
import pickBy from 'lodash/pickBy';
import { fetchTimesheetsUtilization } from 'actionCreators';
import moment from 'moment';
import { GENERATE_UTILIZATION_RANGE } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
const getActiveFilterParams = (filter) =>
  pickBy(filter, (value) => Array.isArray(value) && value.length);

const getCustomFilterParams = (filter = {}) => {
  const { custom = {} } = filter;
  const customFilter = {};
  const { billable, ...otherFilters } = custom;

  return customFilter;
};
const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

const { start_date: start, end_date: end } =
  GENERATE_UTILIZATION_RANGE[ZOOM_LEVELS.WEEK]();
const { start_date: secondStart, end_date: secondEnd } =
  GENERATE_UTILIZATION_RANGE[ZOOM_LEVELS.THIS_WEEK]();

const DashboardTimesheetWidget = ({ filterId }) => {
  const dispatch = useDispatch();
  const filterFinder = useFilterFinder();
  const myId = useSelector(getMyUserId);
  const activeFilter = useMemo(
    () => filterFinder(filterId, true),
    [filterFinder, filterId]
  );

  const utilizationByFilter = useSelector(
    (state) => state.timesheets.utilizationByFilter
  );
  const filterParams = useMemo(() => {
    const activeFilterParams = getActiveFilterParams(activeFilter);
    const customFilterParams = getCustomFilterParams(activeFilter);
    return { ...activeFilterParams, ...customFilterParams };
  }, [activeFilter]);

  // todo - figure out why this is rerendering
  const filterEquality = useMemo(
    () => JSON.stringify(filterParams),
    [filterParams]
  );

  const firstRangeTimesheetUtilization =
    utilizationByFilter[`${activeFilter.id}--first-range`];
  const secondRangeTimesheetUtilization =
    utilizationByFilter[`${activeFilter.id}--second-range`];

  useEffect(() => {
    if (myId && activeFilter.id) {
      const body = {
        start_date: formatFetchDate(start),
        end_date: formatFetchDate(end),

        all: true,
        ...filterParams
      };
      dispatch(
        fetchTimesheetsUtilization({
          body,
          filterId: `${activeFilter.id}--first-range`
        })
      );
      dispatch(
        fetchTimesheetsUtilization({
          body: {
            ...body,
            start_date: formatFetchDate(secondStart),
            end_date: formatFetchDate(secondEnd)
          },
          filterId: `${activeFilter.id}--second-range`
        })
      );
    }
    // refetch when timesheetHash changes to account for delete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, myId, filterEquality, start, end]);
  return (
    <TimesheetWidget
      activeFilter={activeFilter}
      accountIds={activeFilter?.account_ids || []}
      chartOnly
      chartWidth={260}
      chartHeight={130}
      innerRadius={110}
      outerRadius={124}
      cy={130}
      containerClassName={'dashboard-widget'}
      firstRangeTimesheets={firstRangeTimesheetUtilization}
      secondRangeTimesheet={secondRangeTimesheetUtilization}
      showPercent
    />
  );
};

export default DashboardTimesheetWidget;
