import styled from 'styled-components';
import isNil from 'lodash/isNil';
import theme from 'theme';
import { formatTotal } from 'BudgetModule/utils';
import { calcColor } from 'BudgetModule/components/BudgetTable/styles';
import { StyledDot, PhaseIcon } from './styles';
import {
  TOOL_TIP_FORMAT_NAMES,
  TARGET,
  PERCENT_CATEGORIES,
  TOOL_TIP_FORMAT_NAMES_WITH_TARGET,
  TOOL_TIP_FORMAT_NAMES_WITH_TARGET_AND_WITHOUT_REMAINING,
  TOOL_TIP_FORMAT_NAMES_PLANNED_ONLY,
  TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING,
  TOOL_TIP_FORMAT_NAMES_SPENT_ONLY,
  TOOL_TIP_FORMAT_NAMES_PLANNED_AND_REMAINING
} from 'BudgetModule/constants';
import {
  toolTipFormatType,
  DISPLAY_FORMAT,
  VIEW_BY
} from 'ReportsModule/components/Budget/BudgetReportTable/constants';
import {
  getRowTypeTarget,
  getMemberViewFeeCellData,
  getProjectViewFeeCellData,
  hasBudgettedTime
} from 'ReportsModule/components/Budget/BudgetReportTable/utils';
import CircledDot from 'icons/CircledDot';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import { useCurrencyFormatterByProject } from 'appUtils/hooks/useFormatCurrency';

const TooltipContainer = styled.div`
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${({ isSmallTooltip }) => (isSmallTooltip ? '140px' : '170px')};
`;

const Label = styled.div`
  color: ${theme.colors.colorCalendarGray};
  display: flex;
  flex-direction: ${({ hasOneRow }) => (hasOneRow ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  span {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ${PhaseIcon} {
    margin-right: 5px;
  }
`;

const FormattedTotal = styled.span`
  text-align: right;
  font-weight: 400;
  &.remaining_cost,
  &.remaining_percent {
    color: ${({ isOverBudget }) =>
      isOverBudget && theme.colors.colorCalendarRed};
  }

  &.target {
    font-weight: bold;
  }
`;

const NameSpan = styled.span`
  padding-left: 8px;
  color: ${theme.colors.colorMediumGray9};
  font-weight: 400;

  &.target {
    font-weight: bold;
  }
`;

const TooltipTitle = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorPureBlack};
  padding: 5px 0;
`;

const TooltipData = ({
  className,
  name,
  formattedTotal,
  formattedName,
  isPhase,
  isSmallTooltip,
  tooltipDotBackground,
  isOverBudget,
  hasUnderLine,
  customDot
}) => (
  <>
    <div style={{ display: 'grid', gridTemplateColumns: '0fr 1fr 1fr' }}>
      <Label isSmallTooltip={isSmallTooltip}>
        {customDot ||
          (tooltipDotBackground || calcColor[name.toLowerCase()] ? (
            <StyledDot
              tooltipDotBackground={tooltipDotBackground}
              type={name.toLowerCase()}
              style={{ height: 11, width: 11 }}
            />
          ) : (
            isPhase && <PhaseIcon style={{ width: 9, height: 9 }} />
          ))}
      </Label>
      <NameSpan className={className}>{formattedName}</NameSpan>
      <FormattedTotal
        isOverBudget={isOverBudget}
        className={className}
        data-testid="tooltip-value"
      >
        {formattedTotal}
      </FormattedTotal>
    </div>
    {hasUnderLine && (
      <hr
        style={{ margin: '5px 0', background: theme.colors.colorLightGray6 }}
      />
    )}
  </>
);

const mapPercentToHours = {
  spent_percent: 'spent_hours',
  planned_percent: 'planned_hours',
  remaining_percent: 'remaining_hours'
};

const mapClasses = {
  spent_cost: 'spent_cost',
  spent_hours: 'spent_hours',
  spent_percent: 'spent_percent',
  planned_cost: 'planned_cost',
  planned_hours: 'planned_hours',
  planned_percent: 'planned_percent',
  remaining_cost: 'remaining_cost',
  remaining_hours: 'remaining_hours',
  remaining_percent: 'remaining_percent',
  target: 'target'
};

const customFormatTotalFnHash = {
  formatPercentageTotalFromSpentAndPlannedHours: ({
    formatType,
    totals,
    name,
    currencyFormatter
  }) => {
    if (formatType === 'percentage') {
      const sum = totals.spent_hours + totals.planned_hours;
      const unformattedTotal = (totals[mapPercentToHours[name]] / sum) * 100;
      return formatTotal(formatType, unformattedTotal);
    }
    // Use regular formatTotal if formatType != percentage
    return formatTotal(formatType, totals[name], currencyFormatter);
  },
  formatPercentageTotalFromHours: ({
    formatType,
    totals,
    name,
    currencyFormatter
  }) => {
    if (formatType === 'percentage') {
      return formatTotal('hour', totals[mapPercentToHours[name]]);
    }
    return formatTotal(formatType, totals[name], currencyFormatter);
  },
  formatTotalWithBudget: ({
    formatType,
    totals,
    name,
    currencyFormatter,
    headerType,
    viewBy,
    showHours,
    isHoursOnly
  }) => {
    const attributesToTotalMap = {
      [VIEW_BY.PROJECTS]: {
        [toolTipFormatType[DISPLAY_FORMAT.DOLLARS]]: {
          [TARGET]: {
            memberRow: () => {
              const { formattedAmount } = getProjectViewFeeCellData({
                totals,
                showHours,
                rowType: headerType,
                isHoursOnly
              });
              return formattedAmount || '';
            },
            default: () => {
              const { formattedAmount } = getProjectViewFeeCellData({
                totals,
                showHours: false,
                rowType: headerType,
                isHoursOnly
              });
              return formattedAmount || '';
            }
          }
        },
        [toolTipFormatType[DISPLAY_FORMAT.HOURS]]: {
          [TARGET]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals.estimated_hours
              )
          }
        },
        // Map Percentage to Hours
        [toolTipFormatType[DISPLAY_FORMAT.PERCENTAGE]]: {
          [TARGET]: {
            memberRow: () => {
              const { formattedAmount } = getProjectViewFeeCellData({
                totals,
                showHours,
                rowType: headerType,
                isHoursOnly
              });
              return formattedAmount || '';
            },
            default: () => {
              const { formattedAmount } = getProjectViewFeeCellData({
                totals,
                showHours: false,
                rowType: headerType,
                isHoursOnly
              });
              return formattedAmount || '';
            }
          },
          [PERCENT_CATEGORIES.spent]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals[mapPercentToHours[name]]
              )
          },
          [PERCENT_CATEGORIES.planned]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals[mapPercentToHours[name]]
              )
          },
          [PERCENT_CATEGORIES.remaining]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals[mapPercentToHours[name]]
              )
          }
        }
      },
      // Unsure of what data to show on Members View for Target Budget with accuracy
      [VIEW_BY.MEMBERS]: {
        [toolTipFormatType[DISPLAY_FORMAT.DOLLARS]]: {
          [TARGET]: {
            default: () => {
              const { formattedAmount } = getMemberViewFeeCellData({
                totals,
                showHours,
                rowType: headerType
              });
              return formattedAmount || '';
            }
          }
        },
        // Map Percentage to Hours
        [toolTipFormatType[DISPLAY_FORMAT.PERCENTAGE]]: {
          [TARGET]: {
            default: () => {
              const { formattedAmount } = getMemberViewFeeCellData({
                totals,
                showHours,
                rowType: headerType
              });
              return formattedAmount || '';
            }
          },
          [PERCENT_CATEGORIES.spent]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals[mapPercentToHours[name]]
              )
          },
          [PERCENT_CATEGORIES.planned]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals[mapPercentToHours[name]]
              )
          },
          [PERCENT_CATEGORIES.remaining]: {
            default: () =>
              formatTotal(
                toolTipFormatType[DISPLAY_FORMAT.HOURS],
                totals[mapPercentToHours[name]]
              )
          }
        }
      }
    };

    return (
      (attributesToTotalMap[viewBy]?.[formatType]?.[name]?.[headerType] &&
        attributesToTotalMap[viewBy][formatType][name][headerType]()) ??
      (attributesToTotalMap[viewBy]?.[formatType]?.[name]?.default &&
        attributesToTotalMap[viewBy][formatType][name].default()) ??
      formatTotal(formatType, totals[name], currencyFormatter)
    );
  }
};

const getTooltipNamesByPageHash = {
  getBudgetReportTooltipNames: ({
    viewBy,
    headerType,
    totals,
    showHours,
    isHoursOnly
  }) => {
    switch (viewBy) {
      case VIEW_BY.PROJECTS: {
        return isHoursOnly
          ? hasBudgettedTime({ totals })
            ? TOOL_TIP_FORMAT_NAMES_WITH_TARGET
            : TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING
          : getRowTypeTarget(headerType, totals)
          ? TOOL_TIP_FORMAT_NAMES_WITH_TARGET
          : TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING;
      }
      case VIEW_BY.MEMBERS: {
        // No Target Budget and Remaining tooltip on Member Grouped row on Member Views
        if (headerType === 'memberHeaderRow') {
          return TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING;
        }

        const { amount } = getMemberViewFeeCellData({
          totals,
          showHours,
          rowType: headerType
        });

        return isHoursOnly
          ? hasBudgettedTime({ totals })
            ? TOOL_TIP_FORMAT_NAMES_WITH_TARGET
            : TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING
          : amount
          ? TOOL_TIP_FORMAT_NAMES_WITH_TARGET
          : TOOL_TIP_FORMAT_NAMES;
      }
    }
  },
  getPlannedTimeReportTooltipNames: () => TOOL_TIP_FORMAT_NAMES_PLANNED_ONLY,
  getBudgetTableChartViewTooltipNames: () => TOOL_TIP_FORMAT_NAMES_WITH_TARGET,
  getTimeProjectionTooltipNames: () => TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING,
  getSpentTimeReportTooltipNames: () => TOOL_TIP_FORMAT_NAMES_SPENT_ONLY,
  getProjectDetailTooltipNames: () => TOOL_TIP_FORMAT_NAMES,
  getUnspentBudgetReportTooltipNames: ({
    viewBy,
    headerType,
    totals,
    isHoursOnly
  }) => {
    switch (viewBy) {
      case VIEW_BY.MEMBERS:
        if (headerType === 'memberHeaderRow') {
          // Member don't have total budget
          return TOOL_TIP_FORMAT_NAMES_PLANNED_ONLY;
        }
    }

    return isHoursOnly
      ? hasBudgettedTime({ totals })
        ? TOOL_TIP_FORMAT_NAMES_PLANNED_AND_REMAINING
        : TOOL_TIP_FORMAT_NAMES_PLANNED_ONLY
      : getRowTypeTarget(headerType, totals)
      ? TOOL_TIP_FORMAT_NAMES_PLANNED_AND_REMAINING
      : TOOL_TIP_FORMAT_NAMES_PLANNED_ONLY;
  }
};

const getFormattedName = ({
  name,
  mappedName,
  showPercent,
  totals,
  valueType,
  headerType,
  viewBy,
  showHours,
  isHoursOnly
}) => {
  const percentValue = totals[PERCENT_CATEGORIES[valueType]];

  const percent = showPercent
    ? !isNil(percentValue) // target does not exist on PERCENT_CATEGORIES
      ? formatTotal('percentage', Math.abs(+percentValue))
      : totals.fee
      ? `${formatNumWithMaxOneDecimal((totals.target / totals.fee) * 100)}%`
      : null
    : null;

  switch (name) {
    case TARGET: {
      const budgetRowHeaderTypeToTextHash = {
        [VIEW_BY.PROJECTS]: {
          projectHeaderRow: () => {
            const { percent } = getProjectViewFeeCellData({
              totals,
              showHours: isHoursOnly,
              rowType: headerType,
              isHoursOnly
            });
            return `Project Budget ${percent ? `- ${percent}% of Fee` : ''}`;
          },
          memberRow: () => {
            const { percent } = getProjectViewFeeCellData({
              totals,
              showHours,
              rowType: headerType,
              isHoursOnly
            });
            return `Member Budget ${percent ? `- ${percent}% of Fee` : ''}`;
          },
          phaseRow: () => {
            const { percent } = getProjectViewFeeCellData({
              totals,
              showHours: isHoursOnly,
              rowType: headerType,
              isHoursOnly
            });
            return `Phase Budget ${percent ? `- ${percent}% of Fee` : ''}`;
          },
          activityRow: () => {
            const { percent } = getProjectViewFeeCellData({
              totals,
              showHours: isHoursOnly,
              rowType: headerType,
              isHoursOnly
            });
            return `Work Category Budget ${
              percent ? `- ${percent}% of Fee` : ''
            }`;
          }
        },
        // Unsure of what data variable to show on Members View for Target Budget with accuracy
        [VIEW_BY.MEMBERS]: {
          // Need to match FeeCell
          projectRow: () => {
            const { percent } = getMemberViewFeeCellData({
              totals,
              showHours,
              rowType: headerType
            });

            return `Project Estimate ${
              percent ? `- ${percent} of Target` : ''
            }`;
          },
          phaseRow: () => {
            const { percent } = getMemberViewFeeCellData({
              totals,
              showHours,
              rowType: headerType
            });
            return `Phase Estimate ${percent ? `- ${percent} of Target` : ''}`;
          },
          activityRow: () => {
            const { percent } = getMemberViewFeeCellData({
              totals,
              showHours,
              rowType: headerType
            });

            return `Work Category Estimate ${
              percent ? `- ${percent} of Target` : ''
            }`;
          }
        }
      };

      const budgetText = budgetRowHeaderTypeToTextHash[viewBy]?.[headerType]
        ? budgetRowHeaderTypeToTextHash[viewBy][headerType]()
        : 'Budget';
      return budgetText;
    }
    default:
      return percent ? (
        <span style={{ display: 'inline-flex' }}>
          <span
            style={{
              width: '100%',
              minWidth: '4em'
            }}
          >
            {percent}
          </span>
          <span>{mappedName}</span>
        </span>
      ) : (
        mappedName
      );
  }
};

const getHasUnderline = ({ name }) => {
  return name === TARGET;
};

const CustomTooltip = ({
  totals,
  texts,
  name = '',
  total,
  isPhase,
  formatType = 'none',
  isOverBudget = false,
  customDisplayData = null,
  title,
  projectId,
  rowTooltipCustomProps = {},
  headerType,
  viewBy
  // displayFormat
}) => {
  const { isHoursOnly } = useIsHoursOnly();
  const currencyFormatter = useCurrencyFormatterByProject({
    projectId
  });

  if (customDisplayData) {
    return (
      <TooltipContainer>
        {customDisplayData.map((data, index) => (
          <TooltipData
            key={index}
            name={data.name}
            isSmallTooltip={data.isSmallTooltip}
            formattedName={data.formattedName}
            formattedTotal={data.formattedTotal}
            isPhase={isPhase}
            isOverBudget={isOverBudget}
            {...data}
          />
        ))}
      </TooltipContainer>
    );
  }
  const defaultMapText = {
    spent_cost: 'Spent',
    spent_hours: 'Spent',
    spent_percent: 'Spent',
    planned_cost: 'Planned',
    planned_hours: 'Planned',
    planned_percent: 'Planned',
    remaining_cost: isOverBudget ? 'Overbudget' : 'Remaining',
    remaining_hours: isOverBudget ? 'Overbudget' : 'Remaining',
    remaining_percent: isOverBudget ? 'Overbudget' : 'Remaining',
    baseline: 'Baseline',
    current: 'Current'
  };

  const {
    rowTooltipGetterFnName,
    customFormatTotalFnName,
    shouldShowPercent = false,
    tooltipShowHours: showHours
  } = rowTooltipCustomProps;
  const shouldShowHours = showHours || isHoursOnly;

  const customFormatTotalFn = customFormatTotalFnHash[customFormatTotalFnName];
  const toolTipNamesToUse = rowTooltipGetterFnName
    ? getTooltipNamesByPageHash[rowTooltipGetterFnName]({
        viewBy,
        headerType,
        totals,
        isHoursOnly,
        showHours
      })[formatType]
    : !shouldShowHours
    ? TOOL_TIP_FORMAT_NAMES_WITH_TARGET[formatType]
    : TOOL_TIP_FORMAT_NAMES[formatType];

  const showPercent = shouldShowPercent && !isHoursOnly;
  const mapTextToUse = texts || defaultMapText;

  const displayDatas = !totals
    ? []
    : toolTipNamesToUse?.map((name) => {
        const displayData = {};
        // const formattedTotal = formatTotal(formatType, totals[name]);
        const formattedTotal = customFormatTotalFn
          ? customFormatTotalFn({
              formatType,
              totals,
              name,
              currencyFormatter,
              headerType,
              viewBy,
              isOverBudget,
              isHoursOnly,
              showHours
            })
          : formatTotal(formatType, totals[name] ?? 0, currencyFormatter);
        const [valueType] = name.split('_'); // (i.e: ['spent', 'cost'], ['target])
        const mappedName = mapTextToUse[name];

        displayData.customDot = name === TARGET ? <CircledDot /> : null;
        displayData.className = mapClasses[name];
        displayData.name = mappedName;
        displayData.formattedTotal = formattedTotal;
        displayData.formattedName = getFormattedName({
          mappedName,
          name,
          totals,
          showPercent,
          valueType,
          headerType,
          viewBy,
          isOverBudget,
          showHours,
          isHoursOnly
        });
        displayData.isSmallTooltip = formatType === 'hour';
        displayData.hasUnderLine = getHasUnderline({ name });
        return displayData;
      });

  /** Individual bar */
  const formattedTotal = formatTotal(formatType, total, currencyFormatter);
  const isSmallTooltip = formatType === 'hour';

  return (
    <TooltipContainer isSmallTooltip={isSmallTooltip}>
      {title && <TooltipTitle>{title}</TooltipTitle>}

      {!totals ? (
        <TooltipData
          name={name}
          formattedTotal={formattedTotal}
          isSmallTooltip={isSmallTooltip}
          isPhase={isPhase}
          isOverBudget={isOverBudget}
        />
      ) : (
        displayDatas?.map((data, index) => (
          <TooltipData
            key={index}
            name={data.name}
            isSmallTooltip={data.isSmallTooltip}
            formattedTotal={data.formattedTotal}
            isPhase={isPhase}
            isOverBudget={isOverBudget}
            {...data}
          />
        ))
      )}
    </TooltipContainer>
  );
};

export default CustomTooltip;
