import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-align: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
`;

const ClientCell = ({ row }) => {
  const { userActivity } = row.original;
  return <StyledContainer>{userActivity?.display_data.client}</StyledContainer>;
};

export default ClientCell;
