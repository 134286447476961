import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import {
  FetchLeanApiParams,
  FetchLeanApiPayload,
  FetchLeanApiSuccessResponse,
  FetchLeanApiMeta,
  ClearLeanApiIsoStatePayload
} from './types';

/* ---------------------------------- fetch --------------------------------- */

const FETCH_LEAN_API = createRequestTypes('FETCH_LEAN_API');

export const fetchLeanApi = createAction(
  FETCH_LEAN_API.TRIGGER,
  ({ meta, ...payload }: FetchLeanApiParams) => ({ payload, meta })
);

export const fetchLeanApiActionCreatorsMap = createActionCreatorsMap<
  FetchLeanApiPayload,
  FetchLeanApiSuccessResponse,
  FetchLeanApiMeta
>(FETCH_LEAN_API);

/* ---------------------------------- clear --------------------------------- */

export const clearLeanApiIsoState = createAction<ClearLeanApiIsoStatePayload>(
  'CLEAR_LEAN_API_ISO_STATE'
);
