import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  TotalsContainer,
  HourTotal,
  OverBudgetTotalsContainer,
  IssueIconContainer
} from './styles';

import { isHoursOverBudget } from 'BudgetModule/utils/isOverBudget';

const CustomHourTotal = styled(HourTotal)`
  justify-content: unset;
  padding-left: 26px;
`;

class Totals extends React.Component {
  render() {
    const {
      totals,
      isMemberBudgetTotal,
      getTooltipContent,
      projectId,
      phaseId,
      memberBudgetId,
      overduePercent,
      isHidden
    } = this.props;
    if (!totals) {
      return null;
    }

    return (
      <TotalsContainer
        totals={totals}
        isMemberBudgetTotal={isMemberBudgetTotal}
        onClick={(event) => event.stopPropagation()}
        isHidden={isHidden}
      >
        <CustomHourTotal>
          {Object.keys(totals).length && isHoursOverBudget(totals) === true
            ? Math.ceil(
                +totals.planned_hours + +totals.spent_hours
              ).toLocaleString()
            : Math.ceil(
                +totals.spent_hours +
                  +totals.planned_hours +
                  +totals.remaining_hours || 0
              ).toLocaleString()}
          h
        </CustomHourTotal>
        {isHoursOverBudget(totals) === true && (
          <>
            <OverBudgetTotalsContainer
              data-for={`${projectId}-${phaseId}-${memberBudgetId}-tooltip`}
              data-tip={getTooltipContent(
                'over_budget',
                isNaN(parseInt(overduePercent))
                  ? 0
                  : parseInt(overduePercent).toFixed(0),
                Object.keys(totals).length &&
                  +totals.planned_hours +
                    +totals.spent_hours -
                    +totals.estimated_hours
              )}
              data-html
            >
              <CustomHourTotal>
                {Object.keys(totals).length &&
                  Math.ceil(
                    +totals.planned_hours +
                      +totals.spent_hours -
                      +totals.estimated_hours
                  ).toLocaleString()}
                h<IssueIconContainer>!</IssueIconContainer>
              </CustomHourTotal>
            </OverBudgetTotalsContainer>
          </>
        )}
      </TotalsContainer>
    );
  }
}

Totals.propTypes = {
  totals: PropTypes.object
};

export default Totals;
