import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewNotesIcon from 'images/addviewnotesicon.svg';
import AddViewKanbanIcon from 'images/addviewkanbanicon.svg';
import AddViewMeetingIcon from 'images/addviewmeetingicon.svg';
import AddViewBudgetIcon from 'images/addviewbudgeticon.svg';
import AddViewPlannerIcon from 'images/addviewplannericon.svg';
import AddViewTimelinesIcon from 'images/addviewtimelinesicon.svg';
import AddViewChecklistIcon from 'images/addviewchecklisticon.svg';

// see https://github.com/LifeCoded/mosaic-rails-api/pull/3924 for how to add more modules. increment app_module_count

export const moduleTextHash = {
  1: 'Tasks',
  2: 'Notes',
  3: 'Schedule',
  // 4: 'Planner',
  // 5: 'Meetings',
  6: 'Budget',
  7: 'Info',
  8: 'Scope'
  // 9: 'Checklist'
  // 10: 'Kanban',
};

export const moduleHash = {
  1: 'tasks',
  2: 'notes',
  3: 'schedule',
  // 4: 'planner',
  // 5: 'meetings',
  6: 'budget',
  7: 'info',
  8: 'scopes'
  // 9: 'checklist'
  // 10: 'kanban',
};

export const defaultNavModuleIds = [1, 2, 3, 7];
const nonDefaultNavModuleIds = [6, 8];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'A shared, flexible list for getting things done.',
  2: 'Naturally create your team’s knowledge base by documenting scope, meeting minutes, research and more.',
  3: 'Visually plan and update your project. Create roadmaps so your team knows where to go next.',
  4: 'Plan who will work on the project and when, including estimated time.',
  // 5: 'Meeting minutes lets you set the agenda, document discussion and track who’s doing what.',
  6: `Easily track the team's hours and project fees. Combine with Planner and Timesheet to get a real time view of your budget.`,
  // 9: 'Checklist provides a standard cookbook list of what needs to get done.'
  10: 'Task workflow visualization to communicate status, progress and issues.'
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#3db489',
  3: '#DAAD0D',
  4: '#E16AA5',
  5: '#F14848',
  6: '#3E9EE4',
  9: '#B1EA4D'
  // 9: '#E16AA5',
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewNotesIcon}) `,
  3: `url(${AddViewTimelinesIcon}) `,
  4: `url(${AddViewPlannerIcon}) `,
  5: `url(${AddViewMeetingIcon}) `,
  6: `url(${AddViewBudgetIcon}) `,
  9: `url(${AddViewChecklistIcon}) `
  // 10: `url(${AddViewKanbanIcon}) `,
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
