import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  calculateBarColorPreference,
  calculateProjectColorPreference,
  calculateColorPref
} from 'appUtils/styleUtils';
import NoteIcon from 'icons/NoteIconThick';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import ActivityRowIcon from 'icons/ActivityRowIcon';
import theme from 'theme';
import moment from 'moment';
import {
  VIEW_BY,
  ZOOM_FIT_ITEM_TO_SCALE,
  ZOOM_STRINGS,
  CAPACITY_DATE_KEYS
} from 'appConstants/workload';
import { StyledDot } from 'views/Home/Widgets/styles';

const NonHoverState = styled.div`
  display: inline-flex;
`;
const StyledNoteIcon = styled(NoteIcon)`
  height: 10px;
  margin-right: 4px;

  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }
`;

const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  path {
    stroke: ${theme.colors.colorSemiDarkGray1};
    fill: transparent;
  }
`;

const HoverState = styled.div`
  display: none;
`;
const StyledBarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  line-height: 1.3;
  justify-content: space-around;
  position: relative;
`;

const StyledPhaseName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TopRowContainer = styled.div`
  display: flex;
  width: fit-content;
  max-width: 100%;
  align-items: center;
  position: sticky;
  left: 5px;
  overflow: hidden;
`;
const MiddleContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  max-width: 100%;
  position: sticky;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  color: ${theme.colors.colorSemiDarkGray1};
  left: 5px;
  line-height: 1.3;
  overflow: hidden;
  padding-top: 1px;

  ${StyledPhaseMilestoneIcon} {
    margin-right: 3px;
    flex: 0 0 13px;
    height: 13px;
  }
`;

const BottomRowContainer = styled.div`
  color: ${theme.colors.colorCalendarGray} !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  align-self: flex-start;
  justify-content: space-between;
  position: sticky;
  left: 5px;
  margin-right: 30px;
  height: 12px;
  font-size: 11px;
  padding-left: 24px;
`;

const StyledProjectBar = styled.div`
  background: ${theme.colors.colorTranslucentGray3};
  border-left: 6px solid ${calculateProjectColorPreference};
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  margin: 6px 0px;
  padding: 5px 8px;
  height: 54px;
  width: 88%;
  border-radius: 5px;

  &:hover {
    ${NonHoverState} {
      display: none;
    }
    ${HoverState} {
      display: inline-flex;
    }
    ${BottomRowContainer} {
      justify-content: space-between;
    }
  }
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
`;
const StyledProjectName = styled.div`
  color: ${theme.colors.colorSemiDarkGray1} !important;
  display: block;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledBottomRight = styled.div`
  position: absolute;
  right: 0px;
  bottom: -2px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  color: ${theme.colors.colorSemiDarkGray1};
`;

const Bold = styled.span`
  font-weight: 600;
`;
const StyledSuggestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CustomStyledDot = styled(StyledDot)`
  display: inline-block;
  height: 9px;
  width: 9px;
  margin: 0;
  margin-right: 5px;
  border-radius: 50%;
`;

const shouldInjectAvailabilities = (zoom) =>
  ZOOM_FIT_ITEM_TO_SCALE[zoom] === ZOOM_STRINGS.DAY;

const renderBottomLeftContent = (item) => (
  <div>
    <NonHoverState>
      {moment(item.start_date).format('MMM-DD')} -{' '}
      {moment(item.end_date).format('MMM-DD')}
    </NonHoverState>
    <HoverState>
      <Bold>
        {`${item.daily_hours}`.replace(/\.0+$/, '') || 0}
        h/d
      </Bold>
    </HoverState>
  </div>
);

const SuggestionItem = (props) => {
  const { item = {}, viewBy, zoom, openBarModal } = props;
  const shouldRenderInitials = viewBy !== VIEW_BY.MEMBERS;

  return (
    <StyledSuggestion className="suggestion" index={props.index}>
      {item.bars.map(({ start_date, end_date }, index) => {
        return (
          <StyledProjectBar
            onClick={(e) => {
              e.stopPropagation();
              openBarModal({
                suggestedBar: {
                  ...item,
                  isWorkplanSuggestion: props.isWorkplanSuggestion
                },
                isNew: true
              });
            }}
            className="project-schedule-bar-metadata"
            originType="project"
            color={
              shouldRenderInitials
                ? theme.colors.colorMediumGray1
                : theme.colors.colorLightGray14
            }
            styleId={item.project_id}
            key={start_date}
          >
            <StyledBarContent
              className="bar-content"
              hasMember={shouldRenderInitials}
            >
              <TopRowContainer className="suggestion-top-container">
                <TitleContainer>
                  <StyledProjectName
                    className="project-name"
                    projectId={item.project_id}
                    color={theme.colors.colorSemiDarkGray1}
                  >
                    {/* <CustomStyledDot projectId={item.project_id} /> */}
                    <Bold>{item.project_name}</Bold>
                  </StyledProjectName>
                </TitleContainer>
              </TopRowContainer>
              <MiddleContainer
                className="suggestion-middle-container"
                item={item}
                isVisible={!!item.phase_name}
              >
                {!item.is_like_default && (
                  <>
                    <StyledPhaseMilestoneIcon />
                    <StyledPhaseName>{item.phase_name}</StyledPhaseName>
                  </>
                )}
              </MiddleContainer>
              <BottomRowContainer
                className="suggestion-bottom-container"
                projectId={item.project_id}
              >
                {renderBottomLeftContent(item)}
              </BottomRowContainer>
              <StyledBottomRight className="hour-container">
                <NonHoverState>
                  {shouldInjectAvailabilities(zoom)
                    ? Math.ceil(+item.total_hours)
                    : item.total_hours}
                  h
                </NonHoverState>
                <HoverState>{item.total_hours}h</HoverState>
              </StyledBottomRight>
            </StyledBarContent>
          </StyledProjectBar>
        );
      })}
    </StyledSuggestion>
  );
};

export default SuggestionItem;
