import { useMemo } from 'react';
import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import { getDragTooltipDates } from 'appUtils/projectPlannerUtils';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import moment from 'moment';
import noAssigneeIcon from 'images/empty-member.svg';
import NoteIcon from 'images/newnoteicon.svg';
import FlagIcon from 'images/flag.svg';
import CommentIcon from 'images/comment-bubble-grey.svg';
import HomeCompletedIcon from 'icons/HomeCompletedIcon';
import cn from 'classnames';

const StyledDateContainer = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorTranslucentGray3};
`;
const StyledTaskTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
`;
const StyledCompletedIcon = styled(HomeCompletedIcon)`
  margin-right: 4px;
  margin-left: auto;
  flex: 0 0 12px;
  position: sticky;
  right: 5px;
`;
const StyledTaskDescription = styled.div`
  white-space: nowrap;
  line-height: 1;
  position: sticky;
  font-size: 12px;
  left: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
const StyledTaskAndProjectContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: ${({ hasRightMargin }) => (hasRightMargin ? '15px' : '')};
  max-width: 100%;
`;

const StyledProjectNameContainer = styled.div`
  display: flex;
  margin-left: 2px;
  align-items: center;
`;
const StyledProjectName = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
  font-weight: 400;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledProjectDescription = styled.span`
  font-size: 10px;
  color: ${theme.colors.colorMediumGray5};
  font-weight: 400;
  line-height: 1.2;
`;

const EmptyMemberInitials = styled.div`
  flex: 0 0 24px;
  height: 24px;
  background: url(${noAssigneeIcon});
  background-size: contain;
`;

const StyledDot = styled.div`
  flex: 0 0 6px;
  height: 6px;
  margin-top: 1px;
  border-radius: 12px;
  margin-right: 4px;
  background: ${theme.colors.colorSemiDarkGray1};
  background: ${calculateColorPref};
`;

const StyledTaskBar = styled.div`
  border: 1px solid ${theme.colors.colorMediumGray3};
  background: ${theme.colors.colorPureWhite};
  color: ${theme.colors.colorSemiDarkGray1};
  border-radius: 100px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: unset;
  min-width: 30px;
  margin: 10px 0px;
  padding: 2px 2px;
  .sidebar-member-initials {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    margin-right: 4px;
  }

  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
  opacity: ${({ isCompleted }) => (isCompleted ? '0.4' : '1')};
`;

const StyledPhaseBarContainer = styled.div`
  position: relative;
`;

const noStyle = {
  borderColor: '',
  background: 'transparent',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer'
};
const draggingStyle = {
  borderColor: '',
  border: 'none',
  backgroundColor: `${theme.colors.colorIceBlue}`,
  cursor: 'grabbing',
  opacity: '0.4'
  // background: 'transparent',
  // backgroundColor: 'transparent'
  // cursor: 'ew-resize'
};

// TODO: tooltip is not sanitized
const getTooltipContent = (item, memberColor, isLoggedInUser) => {
  const startDate = moment(item?.start_date).format('MMM D');
  const endDate = moment(item?.end_date).format('MMM D');
  return `
    <div class="project-timeline-task-bars" style="border: 2px solid ${
      memberColor || '#808080'
    };">
    <div class="right-box" >
      <div class="tooltip-row row-1">
      <div class="member-name"> ${item?.member?.name || 'Unassigned'}</div>



        <div class="tooltip-bar-date-range">
        ${
          item?.due_at
            ? `<div class="tooltip-due-date">
            <div class="flag-icon" style="background: url(${FlagIcon}) no-repeat center;"></div>
            ${moment(item.due_at).format('MMM D')} </div>`
            : ``
        }

        </div>

      </div>
      <div class="tooltip-task-due"></div>
      <div>
      <div class="tooltip-bar-task-name">${item?.description}</div>
      </div>
      <div class="tooltip-row row-3" >
      <div style="display: flex;">
      ${startDate !== endDate ? `${startDate} -  ${endDate}` : `${startDate}`}
      ${
        item?.estimated_hours
          ? ` | <div class="tooltip-estimated-hours">
          ${item?.estimated_hours}h</div>`
          : ``
      }
      </div>

      <div class="tooltip-totals-container">
      ${
        item?.note
          ? `<div class="note-icon" style="background: url(${NoteIcon}) no-repeat center;"></div>`
          : ''
      }
      ${
        item?.task_comment_ids?.length > 0
          ? `<div class="comment-icon" style="background: url(${CommentIcon}) no-repeat center;">${item?.task_comment_ids?.length}</div>`
          : ''
      }
      </div>
      </div>
    </div>
    </div>
    `;
};

const TaskItemRenderer = (props) => {
  const {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    userTheme,
    startDateRef,
    endDateRef,
    me
  } = props;

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle: {
      cursor: 'w-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      left: '5px',
      top: '10px',
      height: '32px'
    },
    rightStyle: {
      cursor: 'e-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      right: '5px',
      top: '10px',
      height: '32px'
    }
  });

  const tooltipDates =
    itemContext.dragging || itemContext.resizing
      ? getDragTooltipDates({ item, itemContext })
      : null;

  const memberColor = calculateColorPref({
    id: item?.member?.id,
    originType: 'teamMembership',
    theme: userTheme.theme
  });

  const taskTooltipContent = useMemo(
    () => getTooltipContent(item, memberColor, item?.assignee_id === me.id),
    [item, memberColor, me]
  );

  return (
    <div
      {...getItemProps({
        ...item.itemProps,
        className: 'mosaic-milestone-bar',
        style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
      })}
    >
      <div {...leftResizeProps}>
        <div className="drag-handle-bar left task-drag" />
      </div>
      <StyledPhaseBarContainer
        data-html
        data-type="light"
        data-tip={
          itemContext.dragging || itemContext.resizing ? '' : taskTooltipContent
        }
        data-delay-show={250}
        data-for="task-bar"
      >
        <StyledTaskBar
          color={'#808080'}
          originType="teamMembership"
          styleId={item?.member?.id}
          isCompleted={item?.is_completed}
        >
          {(itemContext.dragging || itemContext.resizing) && (
            <>
              <div
                ref={startDateRef}
                data-tip={moment(tooltipDates.startDate).format('YYYY-MM-DD')}
                data-for="start-date-tooltip"
                style={{ position: 'absolute', left: 0, top: 0 }}
              />
              <div
                ref={endDateRef}
                data-tip={moment(tooltipDates.endDate).format('YYYY-MM-DD')}
                data-for="end-date-tooltip"
                style={{ position: 'absolute', right: 0, top: 0 }}
              />
            </>
          )}
          <StyledTaskDescription>
            {item?.member ? (
              <MemberInitials
                size="small"
                classes={cn(
                  'sidebar-member-initials regular-member-no-hover selected',
                  {
                    'logged-member-no-hover': me && item?.assignee_id === me.id
                  }
                )}
                originType="teamMembership"
                styleId={item?.assignee_id}
                member={item?.member}
              />
            ) : (
              <EmptyMemberInitials />
            )}
            <StyledTaskAndProjectContainer hasRightMargin={item?.is_completed}>
              <StyledTaskTitle>{item?.description || ''}</StyledTaskTitle>
              <StyledDateContainer>
                <StyledProjectNameContainer>
                  <StyledDot styleId={item?.project_id} originType="project" />
                  <StyledProjectName>
                    {item?.project?.title}{' '}
                    <StyledProjectDescription>
                      {` - ${item?.project?.description}`}
                    </StyledProjectDescription>
                  </StyledProjectName>
                </StyledProjectNameContainer>
              </StyledDateContainer>
            </StyledTaskAndProjectContainer>
          </StyledTaskDescription>
          {item?.is_completed ? (
            <StyledCompletedIcon
              height="10px"
              width="10px"
              style={{ marginRight: '4px', marginLeft: 'auto' }}
              currentColor={theme.colors.colorBudgetGreen}
            />
          ) : (
            ''
          )}
        </StyledTaskBar>
      </StyledPhaseBarContainer>
      <div {...rightResizeProps}>
        <div className="drag-handle-bar right task-drag" />
      </div>
    </div>
  );
};

export default TaskItemRenderer;
