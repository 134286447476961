import React from 'react';
import { useSelector } from 'react-redux';
import { getIsUserPermissionsLoaded, getUserIsLoaded } from 'selectors';
import { openBudgetModal } from 'BudgetModule/actionCreators';
import useCan from 'appUtils/hooks/useCan';
import Can from 'components/Can/Can';

const BudgetModalPermissionCheck = ({ projectId, children }) => {
  const isUserPermissionsLoaded = useSelector(getIsUserPermissionsLoaded);
  const isUserLoaded = useSelector(getUserIsLoaded);
  const canOpenBudgetModal = useCan(openBudgetModal, {
    projectId,
    isReady: isUserPermissionsLoaded && isUserLoaded
  });

  return (
    <Can
      loadingCondition={!(isUserPermissionsLoaded && isUserLoaded)}
      navToHomeIfCantRender
      renderCondition={canOpenBudgetModal}
    >
      {children}
    </Can>
  );
};

export default BudgetModalPermissionCheck;
