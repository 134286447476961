import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TriangleIcon = ({
  width = '10',
  height = '6',
  stroke = '#828282',
  strokeWidth = '0.75',
  fill = 'none',
  ...rest
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 10 6"
    {...rest}
    fill={fill}
  >
    <path
      d="M1.46526 1.125L5 5.41064L8.53474 1.125H1.46526Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default TriangleIcon;
