import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { ButtonContainer, TextButtonWithBorder } from 'components/styles';
import { SaveButton } from './styles';

const HeaderButtons = ({ onClose, onSave, isSaveDisabled, hasChanges }) => {
  return (
    <StyledHeaderButtons>
      <ButtonContainer width={152}>
        {hasChanges && (
          <TextButtonWithBorder
            onClick={onClose}
            fontWeight={400}
            data-testid="header-cancel-button"
          >
            Cancel
          </TextButtonWithBorder>
        )}
        <SaveButton
          color="white"
          backgroundColor={
            isSaveDisabled
              ? theme.colors.colorLightGray8
              : theme.colors.colorRoyalBlue
          }
          onClick={hasChanges ? onSave : onClose}
          fontWeight={600}
          data-testid="header-save-button"
          isSaveDisabled={isSaveDisabled}
        >
          {hasChanges ? 'Save' : 'Done'}
        </SaveButton>
      </ButtonContainer>
    </StyledHeaderButtons>
  );
};

export default HeaderButtons;

/* ------------------------------------ - ----------------------------------- */

const StyledHeaderButtons = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  padding: 30px 30px 0;
`;
