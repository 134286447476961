import styled from 'styled-components';
import CollapseArrow from 'components/CollapseArrow';

export const BaseGroupCollapseCell = ({ row }) => {
  const { toggleCollapse, id, isOpen, isEditing } = row.original;

  return (
    <CollapseCellContainer>
      <CollapseArrow
        testId={`${id}-collapse`}
        toggleCollapse={toggleCollapse}
        isCollapsed={!isOpen}
        iconSize={undefined}
        isHidden={isEditing}
      />
    </CollapseCellContainer>
  );
};

const CollapseCellContainer = styled.div`
  height: 100%;
`;
