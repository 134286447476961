import * as entityActions from '../actions';
import { fetchEntity } from './generics';
import { api } from '../service';
const { onGlobalSearch } = entityActions;

export function* onGlobalSearchSaga(action) {
  const token = action.payload.token;
  const searchText = action.payload.searchText;

  yield fetchEntity(
    onGlobalSearch,
    api.onGlobalSearchAPI,
    undefined,
    [token, searchText],
    action
  );
}
