/**
 * @markupStr i.e <@[My name is 12390218390123](10936)>
 * @return {number} 10936
 */
const extractIdFromMentionMarkup = (markupStr) => {
  const idReversed: string[] = [];
  let i = markupStr.length - 1;
  let bracketCounts = 0;

  while (bracketCounts < 2 || i === 0) {
    const char = markupStr[i];

    if (char === ')' || char === '(') {
      bracketCounts++;
    }

    if (!isNaN(char)) {
      idReversed.push(char);
    }

    i--;
  }

  return +idReversed.reverse().join('');
};

export const parseCommentsForMemberIds = ({
  commentBody,
  teamMembersHash,
  me
}) => {
  const accountId = me?.id;

  const regex = /<@\[(.*?)\]\((\d+)\)>/gm;

  const regexResults = commentBody.match(regex) || [];

  const ids = new Set<number>();

  regexResults.forEach((matchedStr: string) => {
    const id: number = extractIdFromMentionMarkup(matchedStr);
    if (teamMembersHash?.[id] && id !== +accountId) {
      ids.add(id);
    }
  });

  return Array.from(ids);
};
