import { SimpleConfirmModal } from 'components/Modals';

interface ClearAllDayModalProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
}

export const ClearAllDayModal = ({
  isOpen,
  toggle,
  onConfirm
}: ClearAllDayModalProps) => {
  return (
    <SimpleConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      onCancel={toggle}
      shouldSetIsClosingOnClose={false}
      onConfirm={onConfirm}
      header="Clear All Day"
      confirmLabel="Clear"
      body="Do you want to clear the All Day setting?"
      returnFocusAfterClose={false}
    />
  );
};
