import styled from 'styled-components';
import {
  StyledDateSortIcon,
  StyledSortIconContainer,
  HeaderContainer
} from './styles';
import { SORT_BY } from 'appConstants/filters';
import HeaderThreeDotMenu from './HeaderThreeDotMenu';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

interface MilestoneHeaderTitleProps {
  handleSetSort: (newSortType: Nullable<string>) => void;
  isActive: boolean;
  sortDirection: string;
}

const MilestoneHeaderTitle = ({
  handleSetSort,
  isActive,
  sortDirection
}: MilestoneHeaderTitleProps) => {
  const { phaseSortOrderFlag } = useFeatureFlags();
  return (
    <TitleContainer
      onClick={() => handleSetSort(SORT_BY.name)}
      isActive={isActive}
    >
      {phaseSortOrderFlag && (
        <HeaderThreeDotMenu
          headerType={SORT_BY.name}
          sortDirection={sortDirection}
          isActive={isActive}
          handleSetSort={handleSetSort}
        />
      )}
      TITLE
      <StyledSortIconContainer isActive={isActive} order={sortDirection}>
        <StyledDateSortIcon />
      </StyledSortIconContainer>
    </TitleContainer>
  );
};

const TitleContainer = styled(HeaderContainer)`
  justify-content: left;
`;

export default MilestoneHeaderTitle;
