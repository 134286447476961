import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { SectionTitle } from 'components/Modals/themes/TwoPaneModal';
import ProgressPillWithSelector from 'components/progress/ProgressPillWithSelector';

const ProgressSection = ({ percentComplete }) => {
  return (
    <ProgressSectionContainer className="modal-section">
      <SectionTitle>PROGRESS</SectionTitle>
      <ProgressPillWithSelector
        currentPercentage={percentComplete}
        isReadOnly
        progressColor={theme.colors.colorLightGray21}
        percentageColor={theme.colors.colorMediumGray9}
        shouldRound
      />
    </ProgressSectionContainer>
  );
};

export default ProgressSection;

/* ------------------------------------ - ----------------------------------- */

const ProgressSectionContainer = styled.div`
  .progress-pill {
    font-size: 13px;
  }
`;
