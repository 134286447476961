import React from 'react';
import PropTypes from 'prop-types';
import { Project } from '..';
import cn from 'classnames';
import { getGhostText } from 'appUtils';
import { Droppable } from 'react-beautiful-dnd';
import BoardColumnsDropdown from './BoardColumnsDropdown';
import {
  TableWrapper,
  MainTableHeader,
  MainTableHeading,
  BoardsColumnDropdownContainer,
  StyledSortIcon,
  ClearSort,
  ProjectNameHeader,
  LoadersContainer,
  SpinnerContainer
} from './styles';
import columnHeaders from './ProjectColumnHeaders';
import ReactTooltip from 'react-tooltip';
import Spinner from 'react-spinkit';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import SelectAllHeader from './SelectAllHeader';
import ProjectBatchActionMenu from './ProjectBatchActionMenu';
class ProjectsByPriority extends React.Component {
  componentDidUpdate() {
    rebuildTooltip();
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  displayProjectList = () => {
    const {
      projectListGrouped,
      me,
      openEditProjectModal,
      search,
      showPriorityOptionsForId,
      totalAboveProjects,
      setPriorityOptionsId,
      isFetchingProjects,
      deleteProject,
      isFiltering,
      columnOrder,
      shouldShowBatchOptions
    } = this.props;

    if (projectListGrouped.length) {
      return projectListGrouped.map((project, i) => (
        <Project
          position={i + 1}
          index={i}
          project={project}
          key={project.id}
          openEditProjectModal={openEditProjectModal}
          me={me}
          search={search}
          showPriorityOptionsForId={showPriorityOptionsForId}
          setPriorityOptionsId={setPriorityOptionsId}
          deleteProject={deleteProject}
          projectNumber={totalAboveProjects + i + 1}
          isFiltering={isFiltering}
          columnOrder={columnOrder}
          shouldShowBatchOptions={shouldShowBatchOptions}
        />
      ));
    } else if (!isFetchingProjects) {
      return null;
    }
  };

  setSortProperty = (col) => {
    const { boardSortProperty, setBoardSortProperty } = this.props;
    const sortProperty = col === boardSortProperty ? null : col;
    setBoardSortProperty({ sortProperty });
  };

  renderColumnHeader = (col) => (
    <MainTableHeading
      isSorted={this.props.boardSortProperty === col}
      onClick={() => this.setSortProperty(col)}
      scope="col"
      key={col}
      column={col}
    >
      <ClearSort />
      {columnHeaders[col]}
    </MainTableHeading>
  );

  renderSelectColumnHeader = () => {
    const { projectListGrouped } = this.props;

    return (
      <MainTableHeading
        isSorted={false}
        scope="col"
        key="select"
        column={'select'}
      >
        {columnHeaders.select}
        <SelectAllHeader
          selected={false}
          projectListGrouped={projectListGrouped}
        />
      </MainTableHeading>
    );
  };

  renderSkeletons = () => {
    const { columnOrder } = this.props;
    const columnsCount = columnOrder && columnOrder.length;
    return (
      <LoadersContainer colSpan={columnsCount + 1}>
        {
          <SkeletonLoader
            numLoaders={4}
            style={{ margin: '0 auto', width: '100%', height: '100vh' }}
            loaderStyle={{ height: 90, rx: 4, margin: '20px 0' }}
          />
        }
      </LoadersContainer>
    );
  };

  render() {
    const {
      projectListGrouped,
      statusId,
      search,
      isFetchingProjects,
      isFiltering,
      renderHeader,
      columnOrder,
      setScrollableListRef,
      renderWaypoint,
      shouldRenderSkeletons,
      shouldShowSpinner,
      shouldShowBatchOptions
    } = this.props;

    return (
      <>
        <div className="project-list-group-after-spacer">
          <div
            data-priority-id={statusId}
            className={cn('priority-list-wrapper', {
              'is-empty': projectListGrouped.length === 0
            })}
          >
            <TableWrapper ref={setScrollableListRef}>
              <table className="main-table">
                <MainTableHeader>
                  <tr>
                    <ProjectNameHeader scope="col">
                      {renderHeader()}
                    </ProjectNameHeader>
                    {columnOrder.map(this.renderColumnHeader)}
                    {shouldShowBatchOptions && this.renderSelectColumnHeader()}
                    <BoardsColumnDropdownContainer>
                      <BoardColumnsDropdown show />
                      <ProjectBatchActionMenu statusId={statusId} />
                    </BoardsColumnDropdownContainer>
                  </tr>
                  {shouldRenderSkeletons && !shouldShowSpinner && (
                    <tr>{this.renderSkeletons()}</tr>
                  )}
                </MainTableHeader>
                <Droppable droppableId="projects-by-priority">
                  {(droppableProvided) => (
                    <tbody
                      ref={(ref) => {
                        droppableProvided.innerRef(ref);
                      }}
                      {...droppableProvided.droppableProps}
                    >
                      {this.displayProjectList()}
                    </tbody>
                  )}
                </Droppable>
              </table>
              {!!shouldShowSpinner && shouldRenderSkeletons && (
                <SpinnerContainer>
                  <Spinner fadeIn="none" name="ball-beat" />
                </SpinnerContainer>
              )}
              {renderWaypoint({
                isUnscheduledList: true,
                bottomOffset: '-200'
              })}
            </TableWrapper>
            {projectListGrouped.length === 0 && search.searchText !== '' && (
              <div className="no-projects-search">
                <p>We couldn&#39;t find any projects that match your search.</p>
              </div>
            )}
            {projectListGrouped.length === 0 &&
              search.searchText === '' &&
              !isFetchingProjects &&
              (isFiltering ? (
                <div className="ghost-text-container">
                  <p>No results found.</p>
                </div>
              ) : (
                <div className="ghost-text-container">
                  {getGhostText('team', null)}
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }
}

ProjectsByPriority.propTypes = {
  openEditProjectModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectListGrouped: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object
};

export default ProjectsByPriority;
