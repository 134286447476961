import { isValid, parseISO } from 'date-fns';
import { DateString } from './type';

export class DateRange {
  readonly start: Date;
  readonly end: Date;

  constructor({
    start,
    end
  }: {
    start: Date | DateString;
    end: Date | DateString;
  }) {
    this.start = this.tryParseDate(start);
    this.end = this.tryParseDate(end);
  }

  private tryParseDate(date: Date | DateString): Date {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    if (!isValid(parsedDate)) throw Error('Date is not valid');
    return parsedDate;
  }

  valueOf() {
    return { start: this.start, end: this.end };
  }

  toString() {
    return `${this.start.toISOString()} - ${this.end.toISOString()}`;
  }
}
