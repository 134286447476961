export const CALENDAR_EVENT_TYPE = {
  PHASE_SCHEDULE: 'PHASE_SCHEDULE',
  INTEGRATION_EVENT: {
    GOOGLE_CALENDAR: {
      type: 'GOOGLE_CALENDAR',
      idSuffix: 'google-calendar-event'
    }
  }
};

export const EVENT_TYPE_SORT_ORDER = [
  CALENDAR_EVENT_TYPE.PHASE_SCHEDULE,
  CALENDAR_EVENT_TYPE.INTEGRATION_EVENT.GOOGLE_CALENDAR.type
];

export const EVENT_TYPE_SORT_RANKS = EVENT_TYPE_SORT_ORDER.reduce(
  (acc, cur, index) => {
    acc[cur] = index;
    return acc;
  },
  {}
);
