import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  fetchWorkloadPlanner,
  fetchPhasesByProjectIds,
  fetchAllProjects
} from 'actionCreators';
import { fetchTeamUtilizationReport } from 'UtilizationModule/actionCreators';
import {
  getMyUserId,
  getSelectedTeamId,
  getMyPlannerScheduleBars,
  getThisWeekScheduleBarsWithPercentages,
  getNextWeekScheduleBarsWithPercentages,
  getPhasesByProjectHash
} from 'selectors';
import {
  makeGetTeamUtilizationSummaryData,
  makeGetIsFetchingUtilizationReport
} from 'UtilizationModule/selectors';
import styled from 'styled-components';
import theme from 'theme';
import { fetchCapacities } from 'CapacityModule/actionCreators';
import { getAccountCapacities } from 'CapacityModule/selectors';
import SimplePieChart from 'components/Charts/SimplePieChart';
import moment from 'moment';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

const ChartHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin-bottom: 12px;
  text-align: center;
`;

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

const UtilizationSummaryWidgetTwo = ({
  filterId,
  activeFilter,
  fetchParams,
  fetchParamsTwo,
  headerText,
  doubleChart,
  doubleChartHeaderText,
  containerStyles,
  firstRangeUtilization,
  secondRangeUtilization,
  isFirstFetching,
  isSecondFetching,
  widgetConfig
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const myId = useSelector(getMyUserId);
  const teamId = useSelector(getSelectedTeamId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (myId && teamId && activeFilter?.account_ids.length) {
      dispatch(
        fetchTeamUtilizationReport({
          ...fetchParams,
          start_date: formatFetchDate(fetchParams.start_date),
          end_date: formatFetchDate(fetchParams.end_date),
          team_id: teamId,
          filterStateId: `${activeFilter.id}--first-range`
        })
      );
      dispatch(
        fetchTeamUtilizationReport({
          ...fetchParamsTwo,
          start_date: formatFetchDate(fetchParamsTwo.start_date),
          end_date: formatFetchDate(fetchParamsTwo.end_date),
          team_id: teamId,
          filterStateId: `${activeFilter.id}--second-range`
        })
      );
    }
  }, [activeFilter, dispatch, fetchParams, fetchParamsTwo, myId, teamId]);

  const getData = (utilization) => {
    const formatValue = (value) => {
      if (!activeFilter?.account_ids.length) return 0;
      return +value || 0;
    };
    const billable = formatValue(utilization?.billable_hours);
    // const pto = activeFilter.custom.showPto
    //   ? formatValue(utilization?.pto_hours)
    //   : 0;
    // const holiday = activeFilter.custom.showHolidays
    //   ? formatValue(utilization?.holiday_hours)
    //   : 0;
    const nonbillable = formatValue(utilization?.nonbillable_hours);
    const total = billable + nonbillable;

    return {
      nonbillable: total - billable,
      billable,
      total
    };
  };

  const data = getData(firstRangeUtilization);
  const dataTwo = getData(secondRangeUtilization);

  const getChartData = (groupData) => {
    const dataGroups = [
      {
        name: 'nonbillable',
        value: groupData.nonbillable,
        color: theme.colors.colorBudgetGrey
      },
      {
        name: 'billable',
        value: groupData.billable,
        color: theme.colors.colorConnectedGreen
      }
    ];
    return dataGroups;
  };
  const chartData = getChartData(data);
  const chartDataTwo = getChartData(dataTwo);

  useEffect(() => {
    if (!isFirstFetching && !isSecondFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFirstFetching, isSecondFetching]);

  const isLoading = !hasInitiallyLoaded || isFirstFetching || isSecondFetching;

  return (
    <div className="workload-widget-wrapper" style={{ marginTop: '19px' }}>
      {isLoading ? (
        <SkeletonLoader
          numLoaders={1}
          style={{
            margin: 0,
            position: 'relative',
            ...(widgetConfig
              ? {
                  width: '100%',
                  marginTop: '-20px'
                }
              : {
                  paddingLeft: 12,
                  width: '97px',
                  right: '6px',
                  height: '28px'
                })
          }}
          loaderStyle={{ height: 200, rx: 4 }}
        />
      ) : (
        <>
          <div>
            {headerText && <ChartHeader>{headerText}</ChartHeader>}
            <SimplePieChart
              data={chartData}
              label={`${
                Math.round((data.billable / (data.total || 1)) * 100) || 0
              }%`}
              style={containerStyles}
            />
          </div>
          {doubleChart && (
            <div>
              {doubleChartHeaderText && (
                <ChartHeader>{doubleChartHeaderText}</ChartHeader>
              )}
              <SimplePieChart
                data={chartDataTwo}
                label={`${
                  Math.round((dataTwo.billable / (dataTwo.total || 1)) * 100) ||
                  0
                }%`}
                style={containerStyles}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const getUtilizationData = makeGetTeamUtilizationSummaryData();
const getUtilizationDataTwo = makeGetTeamUtilizationSummaryData();

const makeMapStateToProps = () => {
  const getIsFetching = makeGetIsFetchingUtilizationReport();

  const mapStateToProps = (state, ownProps) => ({
    myId: getMyUserId(state),
    teamId: getSelectedTeamId(state),
    myScheduleBars: getMyPlannerScheduleBars(state),
    thisWeekScheduleBars: getThisWeekScheduleBarsWithPercentages(state),
    nextWeekScheduleBars: getNextWeekScheduleBarsWithPercentages(state),
    accountCapacities: getAccountCapacities(state),
    phasesByProject: getPhasesByProjectHash(state),
    firstRangeUtilization: getUtilizationData(state, {
      filterStateId: `${ownProps.activeFilter.id}--first-range`
    }),
    secondRangeUtilization: getUtilizationDataTwo(state, {
      filterStateId: `${ownProps.activeFilter.id}--second-range`
    }),
    isFirstFetching: getIsFetching(state, {
      filterStateId: `${ownProps.activeFilter.id}--first-range`
    }),
    isSecondFetching: getIsFetching(state, {
      filterStateId: `${ownProps.activeFilter.id}--second-range`
    })
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchWorkloadPlanner,
  fetchCapacities,
  fetchPhasesByProjectIds,
  fetchAllProjects
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UtilizationSummaryWidgetTwo);
