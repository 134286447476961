import { useMemo } from 'react';
import theme from 'theme';
import Body from '../../WidgetParts/Body';
import TimesheetChart from './TimesheetChart';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

import { TimesheetChartContainer } from '../../styles';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import groupBy from 'lodash/groupBy';

const defaultChartStyles = { width: 66, height: 66 };
const defaultPieStyles = {
  innerRadius: 24,
  outerRadius: 32,
  fill: '#8884d8',
  paddingAngle: 0
};
const defaultTooltipStyles = {
  allowEscapeViewBox: { x: false, y: false },
  wrapperStyle: { zIndex: 5 },
  offset: -34
};

const fractionNumStyles = {
  fontSize: 13,
  paddingTop: '4px'
};

const byHours = (timesheet) => +timesheet.hours;
const byStatus = (timesheet) => timesheet.status;
const sumArray = (arr) => +arr.reduce((acc, cur) => +acc + +cur, 0).toFixed(2);

const TimesheetWidget = ({
  totalCapacity,
  timesheets,
  showApprovedCategory = false,
  chartStyles,
  pieStyles,
  tooltipStyles,
  containerStyles = {}
}) => {
  const {
    submitted = [],
    approved = [],
    not_submitted: notSubmitted = []
  } = useMemo(() => groupBy(timesheets, byStatus), [timesheets]);

  const submittedTotal = useMemo(
    () =>
      sumArray([
        ...submitted.map(byHours),
        ...(showApprovedCategory ? [] : approved).map(byHours)
      ]),
    [approved, showApprovedCategory, submitted]
  );

  const unsubmittedTotal = useMemo(
    () => sumArray(notSubmitted.map(byHours)),
    [notSubmitted]
  );

  const approvedTotal = useMemo(
    () => sumArray(approved.map(byHours)),
    [approved]
  );

  const totalEntered =
    submittedTotal +
    unsubmittedTotal +
    (showApprovedCategory ? approvedTotal : 0);

  const remaining =
    totalCapacity >= totalEntered ? totalCapacity - totalEntered : 0;

  const dataGroups = useMemo(() => {
    const dataGroups = [
      {
        name: 'submitted',
        label: 'Submitted',
        value: submittedTotal,
        color: theme.colors.colorRemainingTeal
      },
      {
        name: 'entered',
        label: 'Entered',
        value: unsubmittedTotal,
        color: theme.colors.colorRoyalBlue
      }
    ];

    if (remaining > 0) {
      dataGroups.unshift({
        name: 'not_entered',
        label: 'Not Entered',
        value: remaining,
        color: '#FFFFFF'
      });
    }

    if (showApprovedCategory) {
      dataGroups.unshift({
        name: 'approved',
        label: 'Approved',
        value: approvedTotal,
        color: '#7ED321'
      });
    }

    return dataGroups;
  }, [
    approvedTotal,
    remaining,
    showApprovedCategory,
    submittedTotal,
    unsubmittedTotal
  ]);

  const isOverCapacity = totalCapacity < totalEntered;

  const formattedDataItems = useMemo(
    () =>
      dataGroups.map((item) => ({
        ...item,
        value: formatNumWithMaxTwoDecimals(item.value)
      })),
    [dataGroups]
  );

  const formattedDataSummaryItems = useMemo(
    () => [
      {
        name: 'capacity',
        label: 'Capacity',
        value: formatNumWithMaxTwoDecimals(totalCapacity),
        color: 'tranparent'
      },
      ...(isOverCapacity
        ? [
            {
              name: 'overcapacity',
              label: 'Overcapacity',
              value: formatNumWithMaxTwoDecimals(
                Math.abs(+(totalCapacity - totalEntered))
              ),
              color: 'transparent'
            }
          ]
        : [])
    ],
    [isOverCapacity, totalCapacity, totalEntered]
  );

  return (
    <DynamicModuleLoader modules={[getCapacitiesModule()]}>
      <Body>
        <TimesheetChartContainer style={containerStyles}>
          <TimesheetChart
            items={dataGroups}
            totalCapacity={totalCapacity}
            skipHeader
            chartStyles={chartStyles || defaultChartStyles}
            pieStyles={pieStyles || defaultPieStyles}
            tooltipStyles={tooltipStyles || defaultTooltipStyles}
            fractionNumStyles={fractionNumStyles}
            tooltipDataFor="legend-style-tooltip"
            tooltipContent={{
              data: formattedDataItems,
              summary: formattedDataSummaryItems
            }}
            showCustomTooltip
            hasData
          />
        </TimesheetChartContainer>
      </Body>
    </DynamicModuleLoader>
  );
};

export default TimesheetWidget;
