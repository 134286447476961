import { lazy, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';

import { getIsSplitLoaded, getUserSlug } from 'selectors';
import { navigateToHome } from 'actionCreators';

import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import DevProps from 'components/dev/DevProps';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const ForecastChartContainer = lazy(() =>
  import('./ForecastReportChart/ForecastChartContainer')
);

const WorkloadForecastTab = () => {
  const {
    workloadForecastReportFlag,
    workloadForecastReportDemoData,
    roleForecastReportFlag
  } = useFeatureFlags();
  const isSplitLoaded = useSelector(getIsSplitLoaded);
  const userSlug = useSelector(getUserSlug);
  const { filterId, viewBy, pageName, activeFilter } = useReportsViewFilter();
  const dispatch = useDispatch();

  // redirect user if feature flag is off
  useEffect(() => {
    if (!workloadForecastReportFlag && isSplitLoaded) {
      dispatch(navigateToHome({ userSlug }));
    }
  }, [dispatch, isSplitLoaded, userSlug, workloadForecastReportFlag]);

  if (!workloadForecastReportFlag) return null;

  return (
    <DynamicModuleLoader
      modules={[
        getUtilizationModule(),
        getCapacitiesModule(),
        getBudgetModule()
      ]}
    >
      <DevProps
        data={{
          filterId,
          viewBy,
          pageName,
          activeFilter,
          workloadForecastReportFlag,
          workloadForecastReportDemoData
        }}
      />
      <Suspense fallback={<div />}>
        <ForecastChartContainer
          activeFilter={activeFilter}
          filterStateId="workload-forecast"
          // no viewBy when it's not new role forecast report
          viewBy={roleForecastReportFlag ? viewBy : undefined}
          pageName={pageName}
          shouldUseStubData={workloadForecastReportDemoData}
          roleForecastReportFlag={roleForecastReportFlag}
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};

export default WorkloadForecastTab;
