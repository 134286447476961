import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import moment from 'appUtils/momentConfig';

const newEvent = {
  scheduled_event_id: 'new',
  recipient_account_ids: [],
  metadata: { follower_ids: [], date_range: moment().startOf('week') },
  sent_time: null,
  send_time: null
};

const events = [newEvent];

const byId = (item) => item.id;
const byEventId = (item) => item.scheduled_event_id;

export const initialState = {
  isOpen: false,
  isPersonal: false,
  workloadEventOrder: events.map(byEventId),
  workloadEvents: keyBy(events, byEventId),
  workloadRangeStart: moment().startOf('week'),
  lastSent: {},
  displayData: {},
  actor: {},
  isHomeWorkplan: false
};

const workloadEvents = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.OPEN_WORKLOAD_EVENTS_MODAL: {
      return {
        ...state,
        isOpen: true,
        isPersonal: payload.isPersonal,
        workloadRangeStart:
          payload.workloadRangeStart || state.workloadRangeStart,
        selectedEventId: payload.eventId || 'new',
        displayData: payload.displayData || {},
        actor: payload.actor || {},
        isHomeWorkplan: payload.isHomeWorkplan,
        workloadEvents: {
          ...state.workloadEvents,
          [payload.eventId || 'new']: {
            ...state.workloadEvents[payload.eventId || 'new'],
            recipient_account_ids: payload.initialRecipients || [],
            initialRecipientsOnly: payload.initialRecipientsOnly || false
          }
        }
      };
    }
    case constants.CLOSE_WORKLOAD_EVENTS_MODAL: {
      return {
        ...state,
        isOpen: false,
        isPersonal: false,
        isHomeWorkplan: false,
        workloadEvents: {
          ...state.workloadEvents,
          new: newEvent
        },
        selectedEventId: null,
        displayData: {},
        actor: {}
      };
    }
    case constants.SET_SELECTED_WORKLOAD_EVENT: {
      return {
        ...state,
        selectedEventId: payload.eventId
      };
    }
    case constants.FETCH_WORKLOAD_PLANNER_EVENTS.SUCCESS: {
      return {
        ...state,
        workloadEventOrder: payload.response.map(byEventId),
        workloadEvents: {
          ...state.workloadEvents,
          ...keyBy(payload.response, byEventId)
        }
      };
    }
    case constants.FETCH_WORKLOAD_PLANNER_EVENT.SUCCESS: {
      const { scheduled_event_id } = payload.response;
      return {
        ...state,
        workloadEvents: {
          ...state.workloadEvents,
          [scheduled_event_id]: payload.response
        }
      };
    }

    case constants.FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT.SUCCESS: {
      return {
        ...state,
        lastSent: { ...state.lastSent, ...payload.response }
      };
    }
    case constants.SET_WORKLOAD_EVENT_RANGE: {
      return {
        ...state,
        workloadRangeStart: payload.start
      };
    }
    case constants.BATCH_SELECT_WORKLOAD_EVENT_RECIPIENTS: {
      let nextRecipients;
      const currRecipients =
        state.workloadEvents[payload.eventId].recipient_account_ids || [];
      if (payload.value) {
        nextRecipients = Array.from(
          new Set([...currRecipients, ...payload.ids])
        );
      } else {
        const idsToFilter = new Set(payload.ids);
        nextRecipients = currRecipients.filter((id) => !idsToFilter.has(id));
      }

      return {
        ...state,
        workloadEvents: {
          ...state.workloadEvents,
          [payload.eventId]: {
            ...state.workloadEvents[payload.eventId],
            recipient_account_ids: nextRecipients
          }
        }
      };
    }
    case constants.CLEAR_BATCH_SELECTED_WORKLOAD_EVENT_RECIPIENTS: {
      return {
        ...state,
        workloadEvents: {
          ...state.workloadEvents,
          [payload.eventId]: {
            ...state.workloadEvents[payload.eventId],
            recipient_account_ids: []
          }
        }
      };
    }
    case constants.UPDATE_WORKLOAD_PLANNER_EVENT.TRIGGER: {
      const { event_id, ...rest } = payload;
      return {
        ...state,
        workloadEvents: {
          ...state.workloadEvents,
          [event_id]: {
            ...state.workloadEvents[event_id],
            ...rest
          }
        }
      };
    }
    case constants.DELETE_WORKLOAD_PLANNER_EVENT.SUCCESS: {
      const deletedEventId = action.payload.requestPayload[1];
      return {
        ...state,
        workloadEvents: omit(state.workloadEvents, deletedEventId)
      };
    }
    default:
      return state;
  }
};

export default workloadEvents;
