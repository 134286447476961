import React from 'react';
import styled from 'styled-components';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import theme from 'theme';

const IconContainer = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  transform-origin: center center;
  transform: ${(props) => (props.vertical ? `rotate(-90deg)` : ``)};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  transition: 0.1s ease-in;
  path {
    fill: ${(props) =>
      props.active ? theme.colors.colorRoyalBlue : props.initialColor};
    stroke: ${(props) =>
      props.active ? theme.colors.colorRoyalBlue : props.initialColor};
  }

  svg {
    flex: none;
  }

  &:hover {
    transform-origin: center center;
    ${(props) =>
      props.threeDotHoverBackgroundColor &&
      `
      background-color: ${props.threeDotHoverBackgroundColor};
    `}
    transform: ${(props) =>
      props.vertical ? `rotate(-90deg) scale(1.1)` : `scale(1.1)`};
    path {
      fill: ${theme.colors.colorRoyalBlue};
      stroke: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const StyledMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

class ProjectThreeDotMenuTrigger extends React.Component {
  render() {
    const {
      handleClick,
      children,
      open,
      vertical,
      initialColor,
      threeDotClassName,
      projectTitle,
      memberName,
      phaseTitle,
      testIdPrefix,
      size = 28,
      threeDotHoverBackgroundColor
    } = this.props;
    const testIdSuffix = projectTitle || memberName || phaseTitle;
    const testIdToUse = `${
      testIdPrefix ? `${testIdPrefix}-` : ''
    }three-dot-menu${testIdSuffix ? `-${testIdSuffix}` : ''}`;
    return (
      <StyledMenuWrapper
        onClick={handleClick}
        className="three-dot-menu-wrapper"
        data-testid={testIdToUse}
      >
        <IconContainer
          // className="project-three-dot-icon"
          active={open}
          vertical={vertical}
          initialColor={initialColor}
          className={threeDotClassName}
          threeDotHoverBackgroundColor={threeDotHoverBackgroundColor}
          size={size}
        >
          <ThreeDotHorizontal size={size * (26 / 28)} active={open} />
        </IconContainer>
        {children}
      </StyledMenuWrapper>
    );
  }
}

export default ProjectThreeDotMenuTrigger;
