import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SubmitCheck = ({
  className,
  height = '9px',
  width = '12px',
  currentColor = '#808080'
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 12 9"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28571 9L0 4.68138L1.20857 3.46353L4.28571 6.55566L10.7914 0L12 1.22649L4.28571 9Z"
      fill={currentColor}
    />
  </SvgIcon>
);

export default SubmitCheck;
