import React from 'react';

const WEEK_WIDTH = 300;

/*
  This component achieves the sliding calendar week effect
  Used variable width placeholder to represent weeks that are
  earlier in time, but NOT actually rendered.
  Necessary to correctly manage translateX as we slide 1 week to the next
*/

class ScrollableWeeks extends React.Component {
  renderSpaceBefore() {
    // Represents width that would have been taken by weeks we are not actually rendering
    const style = {
      flex: '0 0 auto',
      width: `${this.props.weeksFromStart * WEEK_WIDTH}px`
    };

    return <div style={style} />;
  }

  render() {
    const selectedWeekIndex = this.props.weeksFromStart + 1;
    const slidingStyle = {
      display: 'flex',
      transform: `translateX(-${selectedWeekIndex * WEEK_WIDTH}px)`,
      transition: 'transform 0.25s'
    };

    return (
      <div className="overflow-container">
        <div className="sliding-container" style={slidingStyle}>
          {this.renderSpaceBefore()}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ScrollableWeeks;
