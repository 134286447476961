import { ComponentProps } from 'react';
import styled from 'styled-components';
import { CommentsContainer } from 'views/comments/CommentsContainer';
import { useWorkPlanForm } from '../../WorkPlanFormProvider';
import { EntityType } from 'models/entity';
import { useWorkPlanRequestSidePanelForm } from 'WorkplanRequestsModule/components/WorkplanRequestSidePanelProvider';
import { PanelScroller } from '../shared/PanelScroller';
import {
  DELETE_WORK_PLAN_COMMENT_TIP,
  EDIT_WORK_PLAN_COMMENT_TIP,
  COMMENT_WORK_PLANS_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useGetWorkPlanCommentsPermission } from 'views/comments/useGetCommentsPermissions';

export const CommentMenuTabPanel = ({ providerType }) =>
  providerType === 'WorkPlan' ? (
    <WorkplanCommentMenuTab />
  ) : (
    <WorkplanRequestCommentMenuTab />
  );

const WORK_PLAN_COMMENT_INPUT_DISABLED_TOOLTIP =
  'Create the Work Plan first<br>to add a Comment.';
const WORK_PLAN_REQUEST_COMMENT_INPUT_DISABLED_TOOLTIP =
  'Create the Work Plan request<br>first to add a Comment.';

const WorkplanCommentMenuTab = () => {
  const {
    form: { workplan, isNew },
    permissions: { canAddWorkplanComment }
  } = useWorkPlanForm();

  return (
    <CommentRenderPart
      formTooltip={
        !canAddWorkplanComment
          ? COMMENT_WORK_PLANS_TIP
          : isNew
          ? WORK_PLAN_COMMENT_INPUT_DISABLED_TOOLTIP
          : undefined
      }
      parentEntityType={EntityType.ActivityPhaseScheduleBarGroup}
      parentEntityId={workplan.id}
      isDisabled={isNew || !canAddWorkplanComment}
      getCommentsPermission={useGetWorkPlanCommentsPermission}
      editCommentDisabledTooltip={EDIT_WORK_PLAN_COMMENT_TIP}
      deleteCommentDisabledTooltip={DELETE_WORK_PLAN_COMMENT_TIP}
    />
  );
};

const WorkplanRequestCommentMenuTab = () => {
  const {
    form: { workplanRequest, isNewRequest }
  } = useWorkPlanRequestSidePanelForm();

  return (
    <CommentRenderPart
      formTooltip={
        isNewRequest
          ? WORK_PLAN_REQUEST_COMMENT_INPUT_DISABLED_TOOLTIP
          : undefined
      }
      parentEntityType={EntityType.ActivityPhaseScheduleBarRequest}
      parentEntityId={workplanRequest.id}
      isDisabled={isNewRequest}
      getCommentsPermission={useGetWorkPlanCommentsPermission}
      editCommentDisabledTooltip={EDIT_WORK_PLAN_COMMENT_TIP}
      deleteCommentDisabledTooltip={DELETE_WORK_PLAN_COMMENT_TIP}
    />
  );
};

const CommentRenderPart = ({
  parentEntityType,
  parentEntityId,
  isDisabled,
  formTooltip,
  getCommentsPermission,
  editCommentDisabledTooltip,
  deleteCommentDisabledTooltip
}: ComponentProps<typeof CommentsContainer>) => {
  return (
    <PanelScroller>
      <PanelContainer>
        <CommentsContainer
          formTooltip={formTooltip}
          parentEntityType={parentEntityType}
          parentEntityId={parentEntityId}
          isDisabled={isDisabled}
          getCommentsPermission={getCommentsPermission}
          editCommentDisabledTooltip={editCommentDisabledTooltip}
          deleteCommentDisabledTooltip={deleteCommentDisabledTooltip}
        />
      </PanelContainer>
    </PanelScroller>
  );
};

const PanelContainer = styled.div`
  .comments-container,
  .scroll-box {
    background: transparent;
  }
  .comments-container {
    .add-comment-container {
      .member-initials {
        display: none;
      }
    }
    .add-comment-container,
    .comment-thread-container {
      margin-left: 0;
      margin-right: 0;
    }

    .comment-row,
    .comment-input-container {
      background-color: white;
    }
    .reply-comment-container .comment-input-container {
      background-color: initial;
    }
  }
`;
