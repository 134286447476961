import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import theme from 'theme';
import { updateTeamMembership } from 'actionCreators';
import { getMyTeamMembership, getMyPlannerSettings } from 'selectors';
import { ReactComponent as MemberRoleIcon } from 'icons/member-role-view-icon.svg';
import { ReactComponent as MemberCapacityIcon } from 'icons/member-capacity-view-icon.svg';
import { ReactComponent as TentativePlanIcon } from 'icons/tentative-plan-view-icon.svg';
import CalendarIcon from 'icons/CalendarIcon';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import VisibilitySettings from './VisibilitySettings';
import DollarSignWithCircleIcon from 'icons/DollarSignWithCircleIcon';

const PlannerVisibilitySettings = () => {
  const dispatch = useAppDispatch();
  const myTeamMembership = useAppSelector(getMyTeamMembership);
  const myPlannerSettings = useAppSelector(getMyPlannerSettings);
  const { billRateOnMemberTooltipFlag, tentativePlanVisibilitySettingFlag } =
    useFeatureFlags();

  const options = [
    {
      icon: (
        <CalendarIcon
          width={'12'}
          height={'12'}
          currentColor={theme.colors.colorMediumGray9}
          className={''}
        />
      ),
      title: 'Day View',
      settingName: 'show_day_view',
      isChecked: !!myPlannerSettings?.show_day_view
    },
    {
      icon: <MemberRoleIcon />,
      title: 'Member Role',
      settingName: 'show_member_role',
      isChecked: !!myPlannerSettings?.show_member_role
    },
    ...(billRateOnMemberTooltipFlag
      ? [
          {
            icon: <DollarSignWithCircleIcon height="12" width="12" />,
            title: 'Bill Rate',
            settingName: 'show_bill_rates',
            isChecked: !!myPlannerSettings?.show_bill_rates
          }
        ]
      : []),
    {
      icon: <MemberCapacityIcon />,
      title: 'Member Capacity',
      settingName: 'show_member_capacity',
      isChecked: !!myPlannerSettings?.show_member_capacity
    },
    ...(tentativePlanVisibilitySettingFlag
      ? [
          {
            icon: <TentativePlanIcon />,
            title: 'Tentative Plans',
            settingName: 'show_tentative_plans',
            isChecked: !!myPlannerSettings?.show_tentative_plans
          }
        ]
      : [])
  ];

  const handleChange = (settingName, value) => {
    dispatch(
      updateTeamMembership({
        planner_settings: {
          [settingName]: value
        },
        id: myTeamMembership.id
      })
    );
  };

  return <VisibilitySettings options={options} handleChange={handleChange} />;
};

export default PlannerVisibilitySettings;
