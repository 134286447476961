import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { QBThumb, QuickBooksHeader, ConnectButton } from './styles';
import ConnectBtn from 'images/qb-connect-btn.png';
import CircleSpinner from 'components/Spinners/CircleSpinner';
import { getFetchHeaders } from 'IntegrationsModule/utils';

class QBConnectModal extends Component {
  renderButton = () => (
    <ConnectButton>
      {this.props.isProvisioning ? (
        <CircleSpinner color="white" fadeIn="none" />
      ) : (
        <img src={ConnectBtn} height={'36px'} width={'223px'} />
      )}
    </ConnectButton>
  );

  redirectToAuth = () => {
    const { targetServiceId, targetService, teamId, token } = this.props;
    fetch(
      `${process.env.INTEGRATION_SERVER_URL}forward/${targetService}/auth-url/${teamId}/${targetServiceId}`,
      {
        method: 'GET',
        headers: getFetchHeaders({ token })
      }
    )
      .then((response) => response.json())
      .then(({ redirect_url }) => {
        if (redirect_url) {
          window.location.assign(redirect_url);
        }
      });
  };

  render() {
    const {
      isOpen,
      close,
      targetServiceId,
      targetService,
      onProvision,
      teamId,
      isProvisioning
    } = this.props;
    return (
      <Modal className="quickbooks-connect" isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>
          <QuickBooksHeader>
            <QBThumb />
            QuickBooks Online Connect
          </QuickBooksHeader>
        </ModalHeader>
        <ModalBody>
          <div>
            Click ‘Connect’ to access the QuickBooks login page where you can
            grant Mosaic permission to access your QuickBooks Online Company
            data in order to import projects, employees and time entries to
            Mosaic, and sync them between accounts.
          </div>
        </ModalBody>
        <ModalFooter>
          {onProvision ? (
            <div onClick={isProvisioning ? undefined : onProvision}>
              {this.renderButton()}
            </div>
          ) : targetServiceId && targetService ? (
            <div onClick={this.redirectToAuth}>{this.renderButton()}</div>
          ) : (
            <a
              href={`${process.env.MOSAIC_QB_URL}qb-auth-url/?mosaic_team_id=${teamId}`}
            >
              {this.renderButton()}
            </a>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default QBConnectModal;
