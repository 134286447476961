import { initialState } from 'reducers/attachments';
import { createSelector } from 'reselect';

const getAttachmentsState = (state) => state.attachments || initialState;

export const getAttachmentHash = createSelector(
  getAttachmentsState,
  (state) => state.attachmentHash
);

export const getFetchedAttachmentIds = createSelector(
  getAttachmentsState,
  (state) => state.fetchedAttachmentIds
);
