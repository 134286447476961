import React from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getIsUserPermissionsLoaded, getUserIsLoaded } from 'selectors';
import Can from 'components/Can/Can';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { usePageContext } from 'contexts/PageContextProvider';

interface IDefaultViewPermissionCheckProps {
  children: React.ReactNode;
  flag?: string | undefined;
  viewToFlag?: { [key: string]: string };
  condition: boolean;
  navToHomeIfCantRender?: boolean;
}

const DefaultViewPermissionCheck = ({
  children,
  flag,
  viewToFlag,
  condition,
  navToHomeIfCantRender = true
}: IDefaultViewPermissionCheckProps): JSX.Element => {
  const { currentView } = usePageContext();

  const isUserPermissionsLoaded = useAppSelector(getIsUserPermissionsLoaded);
  const isUserLoaded = useAppSelector(getUserIsLoaded);
  const flags = useFeatureFlags();
  const flagName =
    flag || (currentView ? viewToFlag?.[currentView] : undefined);
  const viewFlag = flagName ? flags[flagName] : null;
  const canView = condition && (viewFlag === null || viewFlag);

  return (
    <Can
      loadingCondition={
        !(isUserPermissionsLoaded && isUserLoaded && flags.isLoaded)
      }
      navToHomeIfCantRender={navToHomeIfCantRender}
      renderCondition={canView}
    >
      {children}
    </Can>
  );
};

export default DefaultViewPermissionCheck;
