import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { fetchAccountsSuggestionForPhases } from 'SuggestionModule/actionCreators';
import { makeGetSuggestedAccountsForPhasesByIsoStateId } from 'SuggestionModule/selectors';
import { AccountsSuggestionForPhasesRequestRecords } from 'SuggestionModule/types';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { SuggestionsType } from 'SuggestionModule/constants';

const emptyStatusObj = {
  isExecuting: false,
  isSuccess: false
};

const emptyArr = [];

type HookProps = {
  isoStateId: string | number;
  phaseIds: Nullable<number[]>;
};

const useAccountsSuggestionForPhases = (props: HookProps) => {
  const dispatch = useAppDispatch();
  const { isoStateId } = props;
  const phaseIds = props.phaseIds || emptyArr;
  const requestStatusId = `accounts-suggestion-for-phases-${isoStateId}`;

  const { status } = useRequestStatus({
    requestStatusId
  });
  const { isExecuting, isSuccess } = status || emptyStatusObj;

  const getSuggestedAccountsForPhasesByIsoStateId = useMemo(
    () => makeGetSuggestedAccountsForPhasesByIsoStateId(),
    []
  );

  const suggestedAccountsForPhases = useAppSelector((state) =>
    getSuggestedAccountsForPhasesByIsoStateId(state, { isoStateId })
  );

  const isFetchingSuggestedAccountsForPhases = isExecuting && !isSuccess;

  useEffect(() => {
    if (phaseIds.length && isoStateId) {
      const requests: AccountsSuggestionForPhasesRequestRecords[] =
        phaseIds.map((id) => ({
          phase_id: id
        }));

      dispatch(
        fetchAccountsSuggestionForPhases({
          requests,
          suggestion_type: SuggestionsType.Account_Phase,
          meta: {
            isoStateId,
            requestStatusId,
            isTakeLatest: true
          }
        })
      );
    }
    // Don't add phaseIds to dependency array or will result in infinite fetches
    // phaseIds.length should suffice
  }, [dispatch, phaseIds.length, isoStateId, requestStatusId]);

  return {
    suggestedAccountsForPhases,
    isFetchingSuggestedAccountsForPhases
  };
};

export default useAccountsSuggestionForPhases;
