import uniq from 'lodash/uniq';
import {
  formatUnassignedRoleNameByPositionNumber,
  formatUnassignedRoleName
} from 'appUtils/hooks/budget/useMemberBudgetName';
import { sortPositionNames } from 'components/roles/utils';
import { MemberBudget } from 'models/memberBudget';
import { Position } from 'models/position';

export interface BulkPositionDropdownItem extends Position {
  existing?: boolean;
  memberBudget?: MemberBudget;
  selected?: boolean;
}

export const getUnassignedDropdownItems = ({
  existingUnassignedMemberBudgets,
  addedRoles,
  scopeId,
  scopeMemberBudgetIds,
  phaseMembershipsMemberBudgetIds,
  positions,
  positionHash,
  projectUnassignedRoleCounts,
  highestPositionNumberByPositionId,
  addedRoleIdCount,
  excludeExisiting,
  customMakeBulkDropdownItems,
  canAddProjectMembership
}: {
  existingUnassignedMemberBudgets: MemberBudget[];
  addedRoles: BulkPositionDropdownItem[];
  scopeId?: number;
  scopeMemberBudgetIds: number[];
  phaseMembershipsMemberBudgetIds: number[];
  positions: Position[];
  positionHash: Record<number, Position>;
  projectUnassignedRoleCounts: Record<number, number>;
  highestPositionNumberByPositionId: Record<number, number>;
  addedRoleIdCount: Record<number, number>;
  excludeExisiting?: boolean;
  customMakeBulkDropdownItems: any; // FIXME: not easy to type since it can be anything
  canAddProjectMembership: boolean;
}) => {
  const addedRolesMemberBudgetId = addedRoles
    .filter((addedRole) => addedRole.memberBudget)
    .map((addedRole) => addedRole.memberBudget?.id);

  const existingPositionIds = uniq<number>(
    existingUnassignedMemberBudgets.map(
      (memberBudget) => memberBudget.position_id
    )
  );

  const existingMemberBudgetIds = scopeId
    ? scopeMemberBudgetIds
    : phaseMembershipsMemberBudgetIds;

  // Unassigned Memberships that are on the project (Need to display the ones that are not already on the project)
  const existingPositions: BulkPositionDropdownItem[] =
    existingUnassignedMemberBudgets
      .filter(
        (existingMemberBudget) =>
          !(
            existingMemberBudgetIds.includes(existingMemberBudget.id) ||
            addedRolesMemberBudgetId.includes(existingMemberBudget.id)
          )
      )
      .map((existingMemberBudget) => {
        const positionId = existingMemberBudget.position_id;
        const position = positionHash[positionId] as Position;
        const name = formatUnassignedRoleName({
          memberBudget: existingMemberBudget,
          position,
          unassignedRoleCounts: projectUnassignedRoleCounts,
          positionHash
        });

        return {
          ...position,
          memberBudget: existingMemberBudget,
          name,
          existing: true
        };
      });

  // These are unassigned roles of positions that are on the project but would be considered an additional count if added.
  const newExistingPositions: BulkPositionDropdownItem[] =
    existingPositionIds.map((existingPositionId) => {
      const position = positionHash[existingPositionId] as Position;
      const name = formatUnassignedRoleNameByPositionNumber({
        position,
        highestPositionNumberByPositionId,
        addedRoleIdCount,
        includeNextInCount: true
      });
      return {
        ...position,
        name
      };
    });

  const allExistingPositions = excludeExisiting
    ? newExistingPositions
    : existingPositions.concat(newExistingPositions);

  const filteredAllPositions = positions.reduce<Position[]>((acc, cur) => {
    return existingPositionIds.includes(cur.id)
      ? acc
      : acc.concat({
          ...cur,
          name: formatUnassignedRoleNameByPositionNumber({
            position: cur,
            highestPositionNumberByPositionId,
            addedRoleIdCount,
            includeNextInCount: true
          })
        });
  }, []);

  const unfilteredPermissionMergedRoles = customMakeBulkDropdownItems
    ? customMakeBulkDropdownItems({
        addedRoles,
        allExistingPositions,
        filteredAllPositions
      })
    : [
        ...sortPositionNames(addedRoles),
        ...sortPositionNames(allExistingPositions),
        ...sortPositionNames(filteredAllPositions)
      ];

  return canAddProjectMembership
    ? unfilteredPermissionMergedRoles
    : unfilteredPermissionMergedRoles.filter((item) => item.existing);
};
