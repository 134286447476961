import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type NavLeftArrowProps = Pick<
  SvgIconProps,
  'width' | 'height' | 'className' | 'stroke'
>;

const NavLeftArrow = ({
  className = '',
  height = 18,
  width = 19,
  stroke = '#4f4f4f'
}: NavLeftArrowProps) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    className={className}
  >
    <path
      d="M9.34949 16.45L1.54949 8.64995L9.34949 0.849951"
      stroke={stroke}
      strokeWidth="2"
    />
    <path
      d="M18.4495 8.64966L1.54946 8.64966"
      stroke={stroke}
      strokeWidth="2"
    />
  </SvgIcon>
);

export default NavLeftArrow;
