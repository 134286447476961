import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { StyledTaskMoveMenu, TaskMoveMenuListItem } from './styles';
import TrashBatchIcon from 'icons/TrashBatchIcon';
import CompletedIcon from 'icons/CompletedIcon';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { noop } from 'appUtils';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { DELETE_TASK_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import cn from 'classnames';

const StyledSpanText = styled.span`
  padding-left: 6px;
`;

const TaskMoveMenu = ({ markTaskCompletion, handleDeleteClick, projectId }) => {
  const { canDeleteTasks } = useProjectPermissionState({ projectId });

  return (
    <StyledTaskMoveMenu>
      <TaskMoveMenuListItem onClick={markTaskCompletion}>
        <CompletedIcon color="#4a4a4a" />
        <StyledSpanText>Complete </StyledSpanText>
      </TaskMoveMenuListItem>
      <TaskMoveMenuListItem
        onClick={canDeleteTasks ? handleDeleteClick : noop}
        className={cn({ disabled: !canDeleteTasks })}
        {...defaultTooltipProps}
        data-tip={DELETE_TASK_TIP}
        data-tip-disable={canDeleteTasks}
      >
        <TrashBatchIcon fill="#4a4a4a" />
        <StyledSpanText>Delete</StyledSpanText>
      </TaskMoveMenuListItem>
    </StyledTaskMoveMenu>
  );
};

export default connect()(TaskMoveMenu);
