// Contains a truncated if need be version of activity contents

import React from 'react';
import {
  TaskActivityBody,
  ProjectActivityBody,
  CommentActivityBody
} from 'views';
import NoteActivityBody from 'views/activityFeed/NoteActivityBody';
import EmailActivityBody from 'views/activityFeed/EmailActivityBody';
import IntegrationNotificationActivityBody from 'views/activityFeed/IntegrationNotificationActivityBody';
import { setTaskIcon } from 'appConstants/actionDescription';
import {
  isTaskAction,
  isProjectAction,
  isNoteAction,
  isCommentAction,
  isEmailAction,
  isIntegrationNotificationAction
} from 'appUtils';

const renderActivityBody = (displayData, actionType, project) => {
  if (!displayData) return null;
  const props = { displayData, actionType, project };

  if (isCommentAction(actionType)) {
    return <CommentActivityBody {...props} />;
  } else if (isProjectAction(actionType)) {
    return <ProjectActivityBody {...props} />;
  } else if (isTaskAction(actionType)) {
    return <TaskActivityBody {...props} />;
  } else if (isNoteAction(actionType)) {
    return <NoteActivityBody {...props} />;
  } else if (isEmailAction(actionType)) {
    return <EmailActivityBody {...props} />;
  } else if (isIntegrationNotificationAction(actionType)) {
    return <IntegrationNotificationActivityBody {...props} />;
  } else {
    return null;
  }
};

const ActivityItemDetail = ({
  project,
  displayData,
  actionType,
  handleClick
}) => (
  <div
    className="activity-item-detail"
    title="Click to view"
    onClick={handleClick}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '28px',
        width: '28px'
      }}
    >
      <div className={`activity-item-icon ${setTaskIcon(actionType)}`} />
    </div>
    <div className="activity-item-description">
      {renderActivityBody(displayData, actionType, project)}
    </div>
  </div>
);

export default ActivityItemDetail;
