import React, { useRef } from 'react';
import CellContainer from './CellContainer';
import TaskStatus from '../TaskStatus';
import { makeNoop } from 'appUtils/noop';

const StatusCell = ({ row }) => {
  const target = useRef(null);

  const {
    task_status_id,
    id,
    handlePropertySelect = makeNoop,
    taskProps = {},
    createRow,
    isCreatingNewTask,
    list: { id: listId, createRowData, setCreating }
  } = row.original;
  const { taskIsEditing } = taskProps;

  return (
    <CellContainer taskProps={taskProps} taskProperty="status" row={row}>
      <TaskStatus
        target={target}
        statusId={task_status_id}
        onSubmit={handlePropertySelect('task_status_id')}
        taskId={id}
        taskIsEditing={taskIsEditing}
      />
    </CellContainer>
  );
};
export default StatusCell;
