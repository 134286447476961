import Html from 'slate-html-serializer';
import serializerSchema from './schema';

export const html = new Html(serializerSchema);

export const EMPTY_HTML_BODY = '<p></p>';
export const getSlateBody = (note) => {
  if (!note) {
    return html.deserialize(EMPTY_HTML_BODY);
  } else if (note.slateBody) {
    return note.slateBody;
  } else {
    return html.deserialize(note.body);
  }
};

export { default as plainText } from 'slate-plain-serializer';
export { default as plugins } from './plugins';
