import styled from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import EllipsisText from 'components/EllipsisText';
import StandardPhaseIcon from 'icons/StandardPhaseIcon';

const PhaseCell = ({ row, column, style }) => {
  const projectTitle = row.project.title;
  const projectId = row.project.id;
  const phase = row.phase;
  const phaseName = phase.name;

  return (
    <PhaseCellContainer>
      <StyledProjectInfoContainer
        data-for="app-tooltip"
        data-tip={projectTitle}
        data-class="center mw-250"
      >
        <StyledDot projectId={projectId} />
        <EllipsisText width={175}>{projectTitle}</EllipsisText>
      </StyledProjectInfoContainer>
      {!phase.is_like_default && (
        <StyledPhaseInfoContainer
          data-for="app-tooltip"
          data-tip={phaseName}
          data-class="center mw-250"
        >
          <StandardPhaseIcon height={13} />
          <EllipsisText width={162}>{phaseName}</EllipsisText>
        </StyledPhaseInfoContainer>
      )}
    </PhaseCellContainer>
  );
};

export default PhaseCell;

const PhaseCellContainer = styled.div`
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  padding-left: 30px;
  line-height: 17px;
  &:first-child {
    padding-top: 0px;
  }
`;

const StyledProjectInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledDot = styled.div`
  height: 9px;
  border-radius: 100px;
  width: 9px;
  margin-right: 5px;
  background: ${calculateProjectColorPreference};
  flex-shrink: 0;
`;

const StyledPhaseInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 13px;
  padding-left: 13px;
  svg {
    margin: 0 !important;
  }
`;
