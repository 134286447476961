import { select } from 'redux-saga/effects';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { getAuthToken } from 'selectors';
import {
  fetchEntityOptions,
  fetchEntityOptionsActionCreatorsMap
} from 'EntityOptionsModule/actionCreators';

export function* fetchEntityOptionsWorker(
  action: ReturnType<typeof fetchEntityOptions>
) {
  const token = yield select(getAuthToken);
  const { team_id, entity_type, entity_id } = action.payload;

  yield fetchEntity(
    fetchEntityOptionsActionCreatorsMap,
    api.fetchEntityOptions,
    null,
    [token, { team_id, entity_id, entity_type }],
    action
  );
}
