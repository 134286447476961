import {
  useRef,
  useEffect,
  createContext,
  useMemo,
  useCallback,
  ReactNode
} from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import theme from 'theme';
import { Widget, WidgetMetadata } from 'models/widget';
import { getWidgets } from 'DashboardModule/selectors';
import { updateWidget as _updateWidget } from 'DashboardModule/actionCreators/dashboard';
import { TextButtonWithBorder, BlueSubmitButton } from 'components/styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { useToggle } from 'react-use';

const EditModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainers = styled.div`
  display: flex;
  gap: 13px;
`;

const ConfirmButton = styled(BlueSubmitButton)``;

const CancelButton = styled(TextButtonWithBorder)`
  border: none;
`;

const EditDashboardWidgetModalTitle = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 22px;
  font-weight: 700;
`;

const EditModalBodyContainer = styled.div``;

const InputField = styled.input`
  width: 100%;
  background: ${theme.colors.colorLightGray19};
  color: ${theme.colors.colorPureBlack};
  border: 1px solid transparent;
  padding: 7px;
  font-size: 11px;

  ::placeholder {
    color: ${theme.colors.colorLightGray15};
  }
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  width: fit-content;
  color: ${theme.colors.colorCalendarGray};
  font-size: 15px;

  &.timesheetStatusTableWidget {
    max-width: 33rem;
    padding-left: 6px;
    -webkit-line-clamp: 1;
    margin-top: -10px;
  }

  &.budgetWidget {
    max-width: 50rem;
  }

  &.utilizationTableWidget,
  &.utilizationWidgetTwo,
  &.utilizationWidget {
    max-width: 35rem;
  }

  &.utilizationOrganizationBarChartWidget,
  &.utilizationAreaChartWidget {
    max-width: 28rem;
  }

  &.workloadWidget,
  &.workloadWidgetTwo {
    max-width: 28rem;
  }

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

interface WidgetConfig {
  title?: string;
  filterInit?: {
    name: string;
    custom: Record<string, unknown>;
  };
  filterPageName?: string;
  icon?: ReactNode;
  info?: string;
  isPersonal?: boolean;
  type?: string;
  id?: string;
  uid?: string;
}

interface HookProps {
  widgetConfig: WidgetConfig;
  widgetId: string | number;
  dashboardId: number;
  onEditDashboardWidgetCancelCallBack?: () => void;
  onEditDashboardWidgetConfirmCallBack?: () => void;
}

interface ReturnValues {
  widget?: Widget;
  isNewWidget?: boolean;
  EditDashboardWidgetModal?: () => JSX.Element;
  openEditDashboardWidgetModal?: () => void;
  closeEditDashboardWidgetModal?: () => void;
  updateWidgetMetaData?: (newMetaData: WidgetMetadata) => void;
  renderWidgetInfo?: () => JSX.Element;
}
interface renderWidgetInfoArgs {
  descriptionClassName?: string;
}

export const DashboardWidgetContext = createContext<ReturnValues>({});

const emptyObj = {};

/**
 * A hook for handling logic for dashboard widgets
 */
const useDashboardWidget = ({
  widgetConfig = emptyObj,
  widgetId,
  dashboardId,
  onEditDashboardWidgetCancelCallBack,
  onEditDashboardWidgetConfirmCallBack
}: HookProps): ReturnValues => {
  const dispatch = useAppDispatch();
  const widgetsHash = useAppSelector(getWidgets);

  const { title: widgetTitle = '' } = widgetConfig;
  const widget: Widget = useMemo(
    () => widgetsHash[widgetId] || emptyObj,
    [widgetId, widgetsHash]
  );
  const { description = '' } = widget.metadata || emptyObj;
  const descriptionRef = useRef(document.createElement('input'));
  const isNewWidget = widgetId === 'newWidget';

  /** Edit dashboard modal logic */
  const [isEditDashboardWidgetModalOpen, toggleIsDashboardWidgetModalOpen] =
    useToggle(false);

  const openEditDashboardWidgetModal = useCallback(() => {
    toggleIsDashboardWidgetModalOpen(true);
  }, [toggleIsDashboardWidgetModalOpen]);

  const closeEditDashboardWidgetModal = useCallback(() => {
    toggleIsDashboardWidgetModalOpen(false);
  }, [toggleIsDashboardWidgetModalOpen]);

  useEffect(() => {
    rebuildTooltip();
  }, [isEditDashboardWidgetModalOpen]);

  const updateWidgetMetaData = useCallback(
    (newMetaData: WidgetMetadata) => {
      dispatch(
        _updateWidget({
          widgetId,
          dashboardId,
          metadata: {
            ...widget.metadata,
            ...newMetaData
          }
        })
      );
    },
    [dashboardId, dispatch, widget.metadata, widgetId]
  );

  const onEditCancel = useCallback(() => {
    closeEditDashboardWidgetModal();
    onEditDashboardWidgetCancelCallBack &&
      onEditDashboardWidgetCancelCallBack();
  }, [closeEditDashboardWidgetModal, onEditDashboardWidgetCancelCallBack]);

  const onEditConfirm = useCallback(() => {
    const newDescription = descriptionRef.current.value;
    updateWidgetMetaData({
      description: newDescription
    });
    closeEditDashboardWidgetModal();
    onEditDashboardWidgetConfirmCallBack &&
      onEditDashboardWidgetConfirmCallBack();
  }, [
    closeEditDashboardWidgetModal,
    onEditDashboardWidgetConfirmCallBack,
    updateWidgetMetaData
  ]);

  const EditModalHeader = useMemo(
    () => (
      <EditModalHeaderContainer>
        <EditDashboardWidgetModalTitle>
          {widgetTitle}
        </EditDashboardWidgetModalTitle>
        <ButtonContainers>
          <CancelButton className="cancel-button" onClick={onEditCancel}>
            Cancel
          </CancelButton>
          <ConfirmButton className="confirm-button" onClick={onEditConfirm}>
            Done
          </ConfirmButton>
        </ButtonContainers>
      </EditModalHeaderContainer>
    ),
    [onEditCancel, onEditConfirm, widgetTitle]
  );

  const EditModalBody = useMemo(
    () => (
      <EditModalBodyContainer>
        <InputField
          ref={descriptionRef}
          defaultValue={description}
          placeholder="Enter Description"
        />
      </EditModalBodyContainer>
    ),
    [description]
  );

  const EditDashboardWidgetModal = useCallback(() => {
    return (
      <SimpleConfirmModal
        body={EditModalBody}
        header={EditModalHeader}
        toggle={onEditCancel}
        isOpen={isEditDashboardWidgetModalOpen}
        hasNoButtons
      />
    );
  }, [
    EditModalBody,
    EditModalHeader,
    isEditDashboardWidgetModalOpen,
    onEditCancel
  ]);

  const renderWidgetInfo = useCallback(
    (args: renderWidgetInfoArgs = {}) => {
      const { descriptionClassName = '' } = args;
      const { id } = widgetConfig;
      if (!isNewWidget && description) {
        return (
          <Description
            className={`${id} ${descriptionClassName}`}
            onClick={openEditDashboardWidgetModal}
            data-tip={description}
            data-for="app-tooltip"
            data-effect="solid"
            data-class="center mw-40vw word-break-all"
          >
            {description}
          </Description>
        );
      }
      return <></>;
    },
    [description, isNewWidget, openEditDashboardWidgetModal, widgetConfig]
  );

  return {
    widget,
    isNewWidget,
    EditDashboardWidgetModal,
    openEditDashboardWidgetModal,
    closeEditDashboardWidgetModal,
    updateWidgetMetaData,
    renderWidgetInfo
  };
};

export default useDashboardWidget;
