import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Sections, Views, Modals } from 'appConstants/navigation';

class SettingsNavigationTracker extends React.Component {
  render() {
    return <NavigationTracker section={Sections.SETTINGS} />;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingsNavigationTracker)
);
