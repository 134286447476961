import React from 'react';
import {
  PlaceHolderContainer,
  PlaceHolderHeader,
  PlaceHolderText
} from './styles';

const getPlaceholderText = (type) => {
  switch (type) {
    case 'activity-feed': {
      return (
        <div>
          <PlaceHolderHeader>This is your Inbox.</PlaceHolderHeader>
          <PlaceHolderText>
            All notifications of tasks, notes, timelines, project updates, and
            comments will appear here.
          </PlaceHolderText>
        </div>
      );
    }
    default: {
      return 'There is nothing here';
    }
  }
};

const PlaceholderText = ({ type }) => (
  <PlaceHolderContainer className={`${type}-ghost-text`}>
    {getPlaceholderText(type)}
  </PlaceHolderContainer>
);

export default PlaceholderText;
