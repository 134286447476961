import { useEffect } from 'react';
import styled from 'styled-components';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { noop } from 'appUtils';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import cn from 'classnames';

const Container = styled.div`
  padding-left: 95px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .disabled {
    cursor: not-allowed;
  }

  .add-member-section:hover:not(.disabled) {
    cursor: pointer;
    .text {
      font-weight: bold;
    }

    .icon {
      circle {
        fill: ${theme.colors.colorRoyalBlue};
      }
      path {
        fill: ${theme.colors.colorPureWhite};
        stroke: ${theme.colors.colorPureWhite};
        stroke-width: 1px;
      }
    }
  }
  .text {
    padding-left: 24px;
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.colorRoyalBlue};
  }
  .find-people-section {
    cursor: pointer;
    border: 0.5px solid ${theme.colors.colorCalendarBlue};
    color: ${theme.colors.colorCalendarBlue};
    border-radius: 20px;
    margin-right: 3rem;
    padding: 0px 10px;
  }
`;

const AddMemberButton = ({
  onClick,
  projectId,
  handleOpenFindPeopleModal,
  newMembersSuggestionTableFlag,
  disabled,
  tooltip
}) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);
  return (
    <Container>
      <div
        onClick={disabled ? noop : onClick}
        className={cn({ disabled }, 'add-member-section')}
        data-for="app-tooltip"
        data-effect="solid"
        data-class="center"
        data-tip={tooltip || ''}
      >
        <PlusCircleIcon className="icon" width={20} height={20} />
        <span className="text">Add Member</span>
      </div>
      {/* {projectId && newMembersSuggestionTableFlag && (
        <div
          className="find-people-section"
          onClick={(e) => {
            handleOpenFindPeopleModal({ projectId });
          }}
        >
          <GoToProjectIcon /> Team Builder
        </div>
      )} */}
    </Container>
  );
};

export default AddMemberButton;
