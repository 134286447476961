import {
  makeAuthorBasedVerification,
  makeVerification
} from '../makeVerification';

// Create, edit, delete dashboard
export const verifyCreateDashboards = makeVerification(
  'create_dashboards_other'
);
export const verifyEditDashboards = makeAuthorBasedVerification(
  'edit_dashboards',
  'edit_dashboards_other'
);
export const verifyDeleteDashboards = makeAuthorBasedVerification(
  'delete_dashboards',
  'delete_dashboards_other'
);

// Add/Edit/Delete widgets
export const verifyCreateWidgets = makeAuthorBasedVerification(
  'create_widgets',
  'create_dashboard_widgets_other'
);
export const verifyEditWidgets = makeAuthorBasedVerification(
  'edit_widgets',
  'edit_dashboard_widgets_other'
);
export const verifyDeleteWidgets = makeAuthorBasedVerification(
  'delete_widgets',
  'delete_dashboard_widgets_other'
);
