import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ProjectInfoIcon = ({
  className,
  fill = '#4f4f4f',
  width = 14,
  height = 14,
  style
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 14 14"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 6.75C12.75 10.0637 10.0637 12.75 6.75 12.75C3.43629 12.75 0.75 10.0637 0.75 6.75C0.75 3.43629 3.43629 0.75 6.75 0.75C10.0637 0.75 12.75 3.43629 12.75 6.75ZM13.5 6.75C13.5 10.478 10.478 13.5 6.75 13.5C3.02208 13.5 0 10.478 0 6.75C0 3.02208 3.02208 0 6.75 0C10.478 0 13.5 3.02208 13.5 6.75Z"
      fill={fill}
    />
    <path
      d="M5.78516 7.35268C5.78516 7.01983 6.21689 6.75 6.74944 6.75C7.282 6.75 7.71373 7.01983 7.71373 7.35268V10.9688C7.71373 11.3016 7.282 11.5714 6.74944 11.5714C6.21689 11.5714 5.78516 11.3016 5.78516 10.9688V7.35268Z"
      fill={fill}
    />
    <path
      d="M5.625 4.125C5.625 3.50368 6.12868 3 6.75 3C7.37132 3 7.875 3.50368 7.875 4.125C7.875 4.74632 7.37132 5.25 6.75 5.25C6.12868 5.25 5.625 4.74632 5.625 4.125Z"
      fill={fill}
    />
  </SvgIcon>
);

export default ProjectInfoIcon;
