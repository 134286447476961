import { fetchEntity, changeEntity } from './generics';
import { put, select, all, call } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import * as actionCreators from 'actionCreators';

export function* fetchWorkGroups(action) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    entityActions.fetchWorkGroups,
    api.fetchWorkGroups,
    teamId,
    [token],
    action
  );
}
export function* fetchWorkGroup(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchWorkGroup,
    api.fetchWorkGroup,
    id,
    [token],
    action
  );
}

export function* createWorkGroup(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    entityActions.createWorkGroup,
    api.createWorkGroup,
    [token, id, payload],
    action
  );

  if (response && !error && payload?.meta?.onSuccess) {
    payload.meta.onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* updateWorkGroup(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateWorkGroup,
    api.updateWorkGroup,
    [token, id, payload],
    action
  );
  yield put(actionCreators.fetchWorkGroup({ id }));
}

export function* archiveWorkGroup(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.archiveWorkGroup,
    api.archiveWorkGroup,
    [token, id],
    action
  );
  yield put(actionCreators.fetchWorkGroup({ id }));
}

export function* unarchiveWorkGroup(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.unarchiveWorkGroup,
    api.unarchiveWorkGroup,
    [token, id],
    action
  );
  yield put(actionCreators.fetchWorkGroup({ id }));
}
export function* deleteWorkGroup(action) {
  const teamId = yield select(getSelectedTeamId);
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.deleteWorkGroup,
    api.deleteWorkGroup,
    [token, id],
    action
  );
  yield put(actionCreators.fetchWorkGroups({ teamId }));
}

export function* createWorkGroupMembership(action) {
  const { accountId, workGroupId } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.createWorkGroupMembership,
    api.createWorkGroupMembership,
    [token, { account_id: accountId, work_group_id: workGroupId }],
    action
  );
  yield put(actionCreators.fetchWorkGroup({ id: workGroupId }));
}
export function* deleteWorkGroupMembership(action) {
  const { accountId, workGroupId } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.deleteWorkGroupMembership,
    api.deleteWorkGroupMembership,
    [token, { account_id: accountId, work_group_id: workGroupId }],
    action
  );
  yield put(actionCreators.fetchWorkGroup({ id: workGroupId }));
}

export function* refetchOnWSWorkGroupMembership(action) {
  const teamId = yield select(getSelectedTeamId);
  if (teamId) {
    yield put(
      actionCreators.fetchWorkGroup({ id: action.payload.work_group_id })
    );
  }
}
