import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface MatchIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  className?: string;
  color?: string;
}

export const MatchIcon = ({
  className,
  color = '#4F4F4F',
  height = 10,
  width = 13
}: MatchIconProps) => {
  return (
    <SvgIcon
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 13 10"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="6"
        rx="0.9"
        stroke={color}
        strokeWidth="1"
        width="8"
        x="4.5"
        y="0.5"
      />
      <rect
        fill="white"
        height="6"
        rx="0.9"
        stroke={color}
        strokeWidth="1"
        width="8"
        x="0.5"
        y="3.5"
      />
    </SvgIcon>
  );
};

export default MatchIcon;
