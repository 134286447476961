import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchProfitReport = {
  request: () => action(constants.FETCH_PROFIT_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROFIT_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROFIT_REPORT.FAILURE, {
      payload: { error }
    })
};
