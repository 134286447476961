import * as constants from '../constants';
import { createAction } from '@reduxjs/toolkit';
import {
  FetchEntityOptionsParams,
  FetchEntityOptionsRequestPayload,
  FetchEntityOptionsSuccessResponse,
  ClearEntityOptionsParams
} from 'EntityOptionsModule/types';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createActionWithMeta } from 'reduxInfra/utils/createActionWithMeta';

/* ---------------------------------- fetch --------------------------------- */

export const fetchEntityOptions =
  createActionWithMeta<FetchEntityOptionsParams>(
    constants.FETCH_ENTITY_OPTIONS.TRIGGER
  );

export const fetchEntityOptionsActionCreatorsMap = createActionCreatorsMap<
  FetchEntityOptionsRequestPayload,
  FetchEntityOptionsSuccessResponse
>(constants.FETCH_ENTITY_OPTIONS);

/* ---------------------------------- clear --------------------------------- */

export const clearEntityOptions = createAction<ClearEntityOptionsParams>(
  constants.CLEAR_ENTITY_OPTIONS
);
