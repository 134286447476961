import { MenuItem, MenuList } from '../../Menu';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import Popover from 'components/Popover';
import GoToIcon from 'icons/GoToIcon';
import { useTheme } from 'styled-components';
import {
  navigateToStandardRolesSettings,
  navigateToStandardWorkCategoriesSettings
} from 'actionCreators';
import { getTeamSlug } from 'selectors';
import { useEffect, useRef } from 'react';

const RATE_GROUP_MENU_COPY: Record<EntityRateType, string> = {
  [EntityRateType.Role]: 'Edit Roles',
  [EntityRateType.WorkCategory]: 'Edit Work Categories'
} as const;

export const AddEntityMenu = ({
  activeEntityType,
  onClose,
  target
}: {
  activeEntityType: EntityRateType;
  onClose: () => void;
  target: Element;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const teamSlug = useAppSelector(getTeamSlug) as Nullable<string>;

  const handleClickEditRateGroupEntities = () =>
    dispatch(
      (activeEntityType === EntityRateType.Role
        ? navigateToStandardRolesSettings
        : navigateToStandardWorkCategoriesSettings)({
        openInNewWindow: true,
        teamSlug
      })
    );

  // Close the menu when the menu trigger is disconnected from the page.
  const detachObserverRef = useRef(
    new IntersectionObserver((entries) =>
      entries.forEach((entry) => {
        if (!entry.intersectionRatio) onClose();
      })
    )
  );
  useEffect(() => {
    const detachObserver = detachObserverRef.current;

    if (target.isConnected) detachObserver.observe(target);

    return () => detachObserver.disconnect();
  }, [target]);

  return (
    <Popover closePopover={onClose} hideArrow isOpen target={target}>
      <MenuList onClick={onClose}>
        <MenuItem
          icon={<GoToIcon color={theme.colors.colorSemiDarkGray3} />}
          onClick={handleClickEditRateGroupEntities}
        >
          {RATE_GROUP_MENU_COPY[activeEntityType]}
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
