import TeamInfoTab from 'views/personalSettings/TeamInfoTab';
import { PermissionsTab } from 'SettingsModule/components/Permissions/PermissionsTab';
import { TeamArchivedBoardsTab, TeamMembersTab } from 'views';
import IntegrationsTab from 'SettingsModule/components/Integrations/IntegrationsTab';
import { SecurityTab } from 'SettingsModule/components/Security/SecurityTab';
import TimesheetsTab from 'SettingsModule/components/Timesheets/TimesheetsTab';
import ImportLogPageContainer from 'SettingsModule/components/Import/ImportLogPageContainer';
// import RecurlyBillingTab from 'SettingsModule/components/RecurlyDemo/RecurlyBillingTab';
import StandardSettingsTab from 'SettingsModule/components/StandardSettings/StandardSettingsTab';

export const TeamSettingsViews = {
  members: TeamMembersTab,
  integrations: IntegrationsTab,
  info: TeamInfoTab,
  permissions: PermissionsTab,
  teams: TeamArchivedBoardsTab,
  security: SecurityTab,
  timesheet: TimesheetsTab,
  // billing: RecurlyBillingTab,
  standards: StandardSettingsTab,
  import: ImportLogPageContainer
};
