import React from 'react';
import expandIcon from 'images/expand.svg';
import styled from 'styled-components';
import theme from 'theme';
const {
  colors: { colorRoyalBlue }
} = theme;

export const ExpandImg = styled.img`
  fill: ${(props) => props.fill};
  title: 'Open Modal Icon';
  width: '12';
  height: '12';
  viewbox: '0 0 12 12';
`;

const OpenModalIcon = ({ fill = colorRoyalBlue }) => (
  <ExpandImg fill={fill} src={expandIcon} />
);

export default OpenModalIcon;
