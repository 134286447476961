import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import {
  ChartHeader,
  ChartOuterContainer,
  ChartsInnerContainer,
  ChartsDetails,
  FractionNum,
  FractionDen
} from '../../styles';

import TimesheetCustomTooltip from './TimesheetChartTooltip';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const defaultChartStyles = { width: 110, height: 110 };
const defaultPieStyles = {
  innerRadius: 40,
  outerRadius: 50,
  fill: '#8884d8',
  paddingAngle: 0
};
const defaultTooltipStyles = {
  allowEscapeViewBox: { x: false, y: false },
  wrapperStyle: { zIndex: 5 },
  offset: -34
};

const emptyObj = {};

const calcFontSizeFromDigits = (digits = '') => {
  // assume decimal point shouldn't impact font size
  const digitsWithoutDecimal = `${digits}`.replace('.', '').replace(',', '');
  if (digitsWithoutDecimal.length > 8) {
    return 10;
  } else if (digitsWithoutDecimal.length > 6) {
    return 12;
  } else if (digitsWithoutDecimal.length > 4) {
    return 15;
  } else if (digitsWithoutDecimal.length === 4) {
    return 22;
  } else {
    return 26;
  }
};

const TimesheetChart = ({
  items,
  headerText,
  totalCapacity,
  skipHeader,
  tooltipStyles = emptyObj,
  pieStyles = emptyObj,
  chartStyles = emptyObj,
  chartHeaderCSS,
  chartOuterContainerCSS,
  showPercent = false,
  fractionNumSuffix,
  fractionNumStyles,
  stroke = 'transparent',
  showCustomTooltip,
  tooltipContent,
  tooltipDataFor,
  hasData,
  testIdPrefix,
  chartsDetailsClassName
}) => {
  const sumNum = items
    .filter((item) => item.name !== 'not_entered')
    .reduce((totalCom, item) => totalCom + item.value, 0);
  const sum = sumNum.toLocaleString(undefined, { maximumFractionDigits: 2 });
  const sumFontSize = calcFontSizeFromDigits(`${sum}`);
  // totalCapacity is for the user, based on workload capacity
  const percent = totalCapacity
    ? Math.round((sumNum / totalCapacity) * 100)
    : 0;

  const formattedTotalCapacity = formatNumWithMaxTwoDecimals(totalCapacity);

  const summary = showPercent ? (
    <FractionNum
      data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ''}fraction-num`}
      fractionStyle={fractionNumStyles}
    >
      {percent}%
    </FractionNum>
  ) : (
    <>
      <FractionNum
        data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ''}fraction-num`}
        fractionStyle={fractionNumStyles}
      >
        {sum}
        {fractionNumSuffix && fractionNumSuffix}
      </FractionNum>
      <FractionDen
        data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ''}fraction-den`}
      >
        /{formattedTotalCapacity}h
      </FractionDen>
    </>
  );
  return (
    <ChartOuterContainer
      chartOuterContainerCSS={chartOuterContainerCSS}
      data-effect="solid"
      data-for={tooltipDataFor}
      data-place="bottom"
      data-tip-disable={!showCustomTooltip || !hasData}
      data-tip={
        showCustomTooltip
          ? JSON.stringify({
              data: tooltipContent.data,
              summary: tooltipContent.summary,
              showBorder: true
            })
          : ''
      }
    >
      {!skipHeader && (
        <ChartHeader className="small-header" chartHeaderCSS={chartHeaderCSS}>
          {headerText}
        </ChartHeader>
      )}

      <ChartsInnerContainer>
        <ChartsDetails
          sumFontSize={sumFontSize}
          className={chartsDetailsClassName}
        >
          {summary}
        </ChartsDetails>
        <PieChart {...defaultChartStyles} {...chartStyles}>
          <Pie
            data={items}
            dataKey={'value'}
            {...defaultPieStyles}
            {...pieStyles}
          >
            {items.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={entry.color}
                stroke={stroke}
              />
            ))}
          </Pie>
          {!showCustomTooltip && (
            <Tooltip
              content={
                <TimesheetCustomTooltip
                  totalCapacity={formattedTotalCapacity}
                />
              }
              {...defaultTooltipStyles}
              {...tooltipStyles}
            />
          )}
        </PieChart>
      </ChartsInnerContainer>
    </ChartOuterContainer>
  );
};

export default React.memo(TimesheetChart);
