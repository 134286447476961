import {
  BillableContractType,
  BillRateType,
  BudgetTrackingType,
  BudgetViewSettings,
  PhaseBudgetType
} from 'BudgetModule/models/BudgetViewSettings';

export const DEFAULT_BUDGET_VIEW_SETTINGS: Omit<
  BudgetViewSettings,
  'id' | 'viewable_id' | 'viewable_type'
> = {
  activity_phase_default_budget_contract_type: BillableContractType.FixedFee,
  activity_phase_default_budget_with: PhaseBudgetType.Estimate,
  bill_rate_type: BillRateType.Member,
  budget_calculation_status: null,
  budget_calculation_time: null,
  budget_tracking_type: BudgetTrackingType.Currency,
  decimals: true,
  estimated_cost: true,
  estimated_hours: true,
  estimated_percent: false,
  fte_week_hours: 0,
  is_scope_enabled: false,
  phase_default_budget_contract_type: BillableContractType.FixedFee,
  phase_default_budget_with: PhaseBudgetType.Estimate,
  planned_cost: true,
  planned_hours: true,
  planned_percent: false,
  rate_group_id: null,
  rate: true,
  remaining_cost: true,
  remaining_hours: true,
  remaining_percent: false,
  spent_cost: true,
  spent_hours: true,
  spent_percent: false
};
