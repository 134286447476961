import { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';
import OptionsDropdown from 'components/options/OptionsDropdown';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import ImportIcon from 'images/import-icon.svg';
import { ImportModal } from 'CsvImportsModule/components/ImportModal';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import { ValueOf } from 'type-fest';
import { INVITE_MEMBER_TO_TEAM_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

interface ThreeDotMenuProps {
  options: Array<ValueOf<typeof OPTION_KEYS>>;
  entityType: CsvImportEntity;
  styles?: ReturnType<typeof css>;
  canInvite?: boolean;
}

export const ThreeDotMenu = ({
  options,
  entityType,
  styles,
  canInvite = true
}: ThreeDotMenuProps) => {
  const [isMemberImportModalOpen, setIsMemberImportModalOpen] = useState(false);

  const actionByOption: Partial<
    Record<ValueOf<typeof OPTION_KEYS>, () => void>
  > = {
    [OPTION_KEYS.IMPORT_MEMBERS]: () => {
      setIsMemberImportModalOpen(true);
    }
  };

  const handleSelect = (option: ValueOf<typeof OPTION_KEYS>) => {
    const action = actionByOption[option];
    if (action) action();
  };

  if (!canInvite) {
    optionsHash[OPTION_KEYS.IMPORT_MEMBERS].tooltip = INVITE_MEMBER_TO_TEAM_TIP;
    optionsHash[OPTION_KEYS.IMPORT_MEMBERS].disabled = true;
  }

  return (
    <StyleWrapper styles={styles}>
      <OptionsDropdown
        currentValue={undefined}
        onSelect={handleSelect}
        options={options}
        optionsHash={optionsHash}
        renderToggle={(isOpen: boolean) => (
          <ThreeDotHorizontal
            className="three-dot"
            active={isOpen}
            color={theme.colors.colorMediumGray9}
          />
        )}
        headerText=""
        disableDropdown={false}
        disableToggle={false}
      />

      {/* Member CSV import modal */}
      {isMemberImportModalOpen && (
        <ImportModal
          handleClose={() => setIsMemberImportModalOpen(false)}
          entityType={entityType}
        />
      )}
    </StyleWrapper>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyleWrapper = styled.div<{ styles?: ReturnType<typeof css> }>`
  .three-dot {
    width: 25px;
  }
  .options-dropdown-option {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  ${(props) => props.styles}
`;

export const OPTION_KEYS = {
  IMPORT_MEMBERS: 'import_members',
  IMPORT_DEPARTMENTS: 'import_departments',
  IMPORT_ROLES: 'import_roles',
  IMPORT_RATE: 'import_rate',
  IMPORT_PTO: 'import_pto',
  IMPORT_CAPACITY: 'import_capacity',
  IMPORT_SKILLS: 'import_skills',
  IMPORT_LOCATIONS: 'import_location',
  IMPORT_REGIONS: 'import_region',
  IMPORT_OFFICES: 'import_office',
  IMPORT_DISCIPLINES: 'import_discipline'
} as const;

const importIconStyle = {
  filter: 'grayscale(93%) brightness(0.8)',
  transform: 'rotate(-90deg) translateX(2px)'
};

const optionsHash: Record<
  ValueOf<typeof OPTION_KEYS>,
  {
    label: JSX.Element;
    value: ValueOf<typeof OPTION_KEYS>;
    tooltip?: string;
    disabled?: boolean;
    isWarningTooltip?: boolean;
    className?: string;
    isDivder?: boolean;
  }
> = {
  [OPTION_KEYS.IMPORT_MEMBERS]: {
    value: OPTION_KEYS.IMPORT_MEMBERS,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Members</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_DEPARTMENTS]: {
    value: OPTION_KEYS.IMPORT_DEPARTMENTS,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Departments</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_ROLES]: {
    value: OPTION_KEYS.IMPORT_ROLES,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Roles</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_RATE]: {
    value: OPTION_KEYS.IMPORT_RATE,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Rate</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_PTO]: {
    value: OPTION_KEYS.IMPORT_PTO,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Pto</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_CAPACITY]: {
    value: OPTION_KEYS.IMPORT_CAPACITY,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Capacity</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_SKILLS]: {
    value: OPTION_KEYS.IMPORT_SKILLS,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Skills</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_LOCATIONS]: {
    value: OPTION_KEYS.IMPORT_LOCATIONS,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Locations</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_REGIONS]: {
    value: OPTION_KEYS.IMPORT_REGIONS,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Regions</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_OFFICES]: {
    value: OPTION_KEYS.IMPORT_OFFICES,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Offices</span>
      </>
    )
  },
  [OPTION_KEYS.IMPORT_DISCIPLINES]: {
    value: OPTION_KEYS.IMPORT_DISCIPLINES,
    label: (
      <>
        <img src={ImportIcon} width={21} style={importIconStyle} />
        <span>Import Disciplines</span>
      </>
    )
  }
};
