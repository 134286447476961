import React, { useMemo } from 'react';
import { useFilterFinder } from 'DashboardModule/hooks/useFilterFinder';
import UtilizationSummaryWidgetTwo from 'views/Home/Widgets/Widgets/UtilizationSummaryWidgetTwo';
import { useAccountCapacitySum } from 'DashboardModule/hooks/useAccountCapacityTotals';
import { GENERATE_UTILIZATION_RANGE } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS, VIEW_BY } from 'appConstants/workload';
import withFilter from 'hocs/withFilter';

const containerStyles = {
  padding: 0
};

const UtilizationWidgetTwo = ({ activeFilter, widgetConfig }) => {
  const fetchParams = useMemo(
    () => ({
      account_ids: activeFilter?.account_ids || [],
      ...GENERATE_UTILIZATION_RANGE[
        ZOOM_LEVELS.WEEK // last week
      ]?.({
        start: activeFilter?.custom?.start_date,
        end: activeFilter?.custom?.end_date
      })
    }),
    [activeFilter]
  );

  // const accountCapacitySum = useAccountCapacitySum({
  //   accountIds: activeFilter?.account_ids || emptyArray,
  //   fetchParams
  // });

  const fetchParamsTwo = useMemo(
    () => ({
      account_ids: activeFilter?.account_ids || [],
      ...GENERATE_UTILIZATION_RANGE[
        ZOOM_LEVELS.PREV_WEEK // two weeks ago
      ]?.({
        start: activeFilter?.custom?.start_date,
        end: activeFilter?.custom?.end_date
      })
    }),
    [activeFilter]
  );

  // const accountCapacitySumTwo = useAccountCapacitySum({
  //   accountIds: activeFilter?.account_ids || emptyArray,
  //   fetchParams: fetchParamsTwo
  // });

  return (
    <UtilizationSummaryWidgetTwo
      viewBy={VIEW_BY.MEMBERS}
      activeFilter={activeFilter}
      // accountCapacitySum={accountCapacitySum}
      // accountCapacitySumTwo={accountCapacitySumTwo}
      doubleChart
      headerText={'Last Week'}
      doubleChartHeaderText={'Two Weeks Ago'}
      chartWidth={260}
      chartHeight={130}
      innerRadius={110}
      outerRadius={124}
      cy={130}
      containerClassName={'dashboard-widget'}
      containerStyles={containerStyles}
      fetchParams={fetchParams}
      fetchParamsTwo={fetchParamsTwo}
      widgetConfig={widgetConfig}
    />
  );
};

export default withFilter(UtilizationWidgetTwo);
