import * as constants from 'appConstants';
import initialTheme from 'theme';
import omit from 'lodash/omit';

const initialState = {
  theme: initialTheme,
  openPickerId: null,
  openPickerOriginType: null,
  openPickerLocation: null
};

const userTheme = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.OPEN_COLOR_PICKER: {
      const { id, originType, pickerLocation } = payload;
      return {
        ...state,
        openPickerId: id,
        openPickerOriginType: originType,
        openPickerLocation: pickerLocation
      };
    }
    case constants.CLOSE_COLOR_PICKER: {
      return {
        ...state,
        openPickerId: null,
        openPickerOriginType: null,
        openPickerLocation: null
      };
    }
    case constants.FETCH_PROJECT_COLOR_PREFERENCES.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        theme: {
          ...state.theme,
          projectColors: {
            ...response
          }
        }
      };
    }
    case constants.UPDATE_PROJECT_COLOR_PREFERENCE.TRIGGER:
    case constants.CREATE_PROJECT_COLOR_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          projectColors: {
            ...state.theme.projectColors,
            [id]: color
          }
        }
      };
    }
    case constants.DELETE_PROJECT_COLOR_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          projectColors: {
            ...omit(state.theme.projectColors, id)
          }
        }
      };
    }
    case constants.FETCH_BOARD_PREFERENCES.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        theme: {
          ...state.theme,
          boardColors: {
            ...state.theme.boardColors,
            ...response
          }
        }
      };
    }
    case constants.UPDATE_BOARD_PREFERENCE.TRIGGER:
    case constants.CREATE_BOARD_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          boardColors: {
            ...state.theme.boardColors,
            [id]: color
          }
        }
      };
    }
    case constants.DELETE_BOARD_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          boardColors: {
            ...omit(state.theme.boardColors, id)
          }
        }
      };
    }
    case constants.FETCH_TASK_GROUP_PREFERENCES.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        theme: {
          ...state.theme,
          taskGroupColors: {
            ...response
          }
        }
      };
    }
    case constants.UPDATE_TASK_GROUP_PREFERENCE.TRIGGER:
    case constants.CREATE_TASK_GROUP_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          taskGroupColors: {
            ...state.theme.taskGroupColors,
            [id]: color
          }
        }
      };
    }
    case constants.DELETE_TASK_GROUP_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          taskGroupColors: {
            ...omit(state.theme.taskGroupColors, id)
          }
        }
      };
    }
    case constants.FETCH_TEAM_MEMBERSHIP_PREFERENCES.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        theme: {
          ...state.theme,
          teamMembershipColors: {
            ...response
          }
        }
      };
    }
    case constants.UPDATE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER:
    case constants.CREATE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          teamMembershipColors: {
            ...state.theme.teamMembershipColors,
            [id]: color
          }
        }
      };
    }
    case constants.DELETE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          teamMembershipColors: {
            ...omit(state.theme.teamMembershipColors, id)
          }
        }
      };
    }
    case constants.FETCH_TEAM_PREFERENCES.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        theme: {
          ...state.theme,
          teamColors: {
            ...response
          }
        }
      };
    }
    case constants.UPDATE_TEAM_PREFERENCE.TRIGGER:
    case constants.CREATE_TEAM_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          teamColors: {
            ...state.theme.teamColors,
            [id]: color
          }
        }
      };
    }
    case constants.DELETE_TEAM_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          teamColors: {
            ...omit(state.theme.teamColors, id)
          }
        }
      };
    }
    case constants.FETCH_TAG_PREFERENCES.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        theme: {
          ...state.theme,
          tagColors: {
            ...response
          }
        }
      };
    }
    case constants.UPDATE_TAG_PREFERENCE.TRIGGER:
    case constants.CREATE_TAG_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          tagColors: {
            ...state.theme.tagColors,
            [id]: color
          }
        }
      };
    }
    case constants.DELETE_TAG_PREFERENCE.TRIGGER: {
      const { color, id } = payload;
      return {
        ...state,
        openPicker: null,
        theme: {
          ...state.theme,
          tagColors: {
            ...omit(state.theme.tagColors, id)
          }
        }
      };
    }
    default:
      return state;
  }
};

export default userTheme;
