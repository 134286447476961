import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import Popover from 'components/Popover';
import { WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES } from 'appConstants/workload';
import DropdownMenuIcon from 'icons/DropdownMenuIcon';
import HelpIcon from 'icons/HelpIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import capitalize from 'lodash/capitalize';

export interface WorkplanCalendarPanelSelectTypeProps {
  selectType: keyof typeof WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES;
  handleChange: (optionType: string) => null;
}

const WorkplanCalendarPanelSelectType = ({
  selectType,
  handleChange
}: WorkplanCalendarPanelSelectTypeProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectTypePopoverTarget = useRef(null);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const panelLabel = `Plan by ${capitalize(selectType)}`;

  const selectTypeOptions = Object.values(
    WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES
  );

  const togglePopover = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (optionType: string) => {
    handleChange(optionType);
    setIsOpen(false);
  };

  return (
    <WorkplanCalendarPanelSelectTypeContainer
      ref={selectTypePopoverTarget}
      onClick={togglePopover}
    >
      <div>{panelLabel}</div>

      <DropdownMenuIconContainer>
        <DropdownMenuIcon />
      </DropdownMenuIconContainer>

      <HelpIconContainer
        data-for="app-tooltip"
        data-tip="Set default from Settings on <br> the top left of this screen."
        data-effect="solid"
        data-html
      >
        <HelpIcon
          width="8"
          height="8"
          circleColor={theme.colors.colorPaleGray10}
          fillColor={theme.colors.colorPaleGray10}
        />
      </HelpIconContainer>

      <Popover
        isOpen={isOpen}
        target={selectTypePopoverTarget}
        closePopover={() => {
          setIsOpen(false);
        }}
        className="workplan-calendar-select-type"
        placement="right-start"
        insideAnotherPopover
      >
        <SelectTypeOptionsPopoverContainer>
          <SelectTypeOptionsPopoverHeader>
            Plan By
          </SelectTypeOptionsPopoverHeader>
          {selectTypeOptions.map((optionType: string) => {
            const isSelectedOption = selectType === optionType;
            return (
              <SelectTypeOptions
                key={optionType}
                onClick={() => handleSelect(optionType)}
                isSelectedOption={isSelectedOption}
              >
                {optionType}
              </SelectTypeOptions>
            );
          })}
        </SelectTypeOptionsPopoverContainer>
      </Popover>
    </WorkplanCalendarPanelSelectTypeContainer>
  );
};

const WorkplanCalendarPanelSelectTypeContainer = styled.div`
  width: 100%;
  height: 37px;
  border: 0.5px solid ${theme.colors.colorLightGray6};
  border-top: none;
  background-color: ${theme.colors.colorTranslucentGray3};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 13px;
  font-weight: 600;

  &:hover {
    font-weight: 800;
    letter-spacing: -0.2px;
  }
`;

const DropdownMenuIconContainer = styled.div`
  padding-left: 5px;
  g {
    fill: ${theme.colors.colorCalendarBlue};
  }
  rect {
    fill: ${theme.colors.colorTranslucentGray3};
  }
`;

const HelpIconContainer = styled.div`
  position: relative;
  top: -7px;
  left: 3px;
`;

const SelectTypeOptionsPopoverContainer = styled.div`
  width: 88px;
  height: 106px;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  font-size: 13px;
`;

const SelectTypeOptionsPopoverHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.colorPureBlack};
  padding-top: 10px;
  padding-left: 16px;
`;

const SelectTypeOptions = styled.div<{ isSelectedOption: boolean }>`
  height: 36px;
  padding-left: 25px;
  &:hover {
    background: ${theme.colors.colorPaleGray5};
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: ${({ isSelectedOption }) => (isSelectedOption ? 600 : 400)};
`;

export default WorkplanCalendarPanelSelectType;
