import { ProjectId } from 'ProjectsModule/models/project';
import { useProjectPermissionState } from '../hooks/useProjectPermissionState';

interface PermissionProps {
  projectId?: ProjectId;
  accountId?: number;
}
interface WithProjectPermissionProviderProps {
  permissionProps?: PermissionProps;
}

export const withProjectPermissionProvider = (Component) => {
  const WithProjectPermissionProvider = ({
    permissionProps = {},
    ...props
  }: WithProjectPermissionProviderProps) => {
    const { projectId, accountId } = permissionProps;
    const projectPermissions = useProjectPermissionState({
      projectId,
      accountId
    });
    return <Component {...props} projectPermissions={projectPermissions} />;
  };

  return WithProjectPermissionProvider;
};
