/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTeamId, getTeamMembers } from 'selectors';
import ContentLoader from 'react-content-loader';
import Table from 'components/Table';
import { StyledBudgetSettingsTable } from 'SettingsModule/components/Budget/styles';
import {
  ListHeader,
  ListSection,
  InviteContainer
} from 'views/personalSettings/styles';
import {
  getBudgetSettingsColumnHeaders,
  getFormattedBudgetSettings
} from 'SettingsModule/selectors';
import {
  fetchRates,
  fetchAllTeamRates,
  fetchAllTeamSalaries,
  fetchOverhead
} from 'BudgetModule/actionCreators';
import { fetchTeamPermissions } from 'PermissionsModule/actionCreators';
import ReadOnlyMemberCell from 'SettingsModule/components/Budget/ReadOnlyMemberCell';
import TeamRateCell from './TeamRateCell';
import RateMultiplierCell from './RateMultiplierCell';
import HeaderCell from './HeaderCell';
import DescriptionCell from './DescriptionCell';
import { SORT_BY } from 'appConstants/filters';
import CostCell from './CostCell';
import EmailCell from './EmailCell';
import { VIEW_BY } from 'appConstants/workload';
import { fetchTeamRoleTemplates } from 'RoleTemplatesModule/actionCreators';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { permissionsUtils } from 'PermissionsModule/utils';

const emptyRow = { rowType: 'emptyRow' };

const buildHeader = ({ list, customRowProps }) => {
  const { id, headerType, isOpen, headerHeight, renderHeader, headerData } =
    list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    renderHeader,
    customRowProps
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: list.rowHeight || item.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      listItems.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
      if (!isFullyLoaded) {
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }
  if (lists.every((list) => list.isFullyLoaded || !list.isOpen)) {
    flatList.push(
      buildCustomItem({
        list: lists[lists.length - 1] || { getItemId: (item) => item?.id },
        customRowProps,
        item: emptyRow
      })
    );
  }
  return flatList;
};

const EmptyDiv = () => <div />;
const HeaderDiv = ({ row }) => (
  <ListHeader>{row?.original?.list?.renderHeader()}</ListHeader>
);
const memberTypeComponents = {
  [SORT_BY.member]: HeaderDiv,
  [SORT_BY.email]: EmptyDiv,
  [SORT_BY.cost]: EmptyDiv,
  [SORT_BY.rate]: EmptyDiv,
  [SORT_BY.description]: EmptyDiv,
  [SORT_BY.rateMultiplier]: EmptyDiv,
  padding: EmptyDiv
};

const cellComponents = {
  [SORT_BY.member]: ReadOnlyMemberCell,
  [SORT_BY.email]: EmailCell,
  [SORT_BY.cost]: CostCell,
  [SORT_BY.rate]: TeamRateCell,
  [SORT_BY.description]: DescriptionCell,
  [SORT_BY.rateMultiplier]: RateMultiplierCell,
  padding: EmptyDiv
};

const columnWidths = {
  [SORT_BY.member]: 190,
  [SORT_BY.email]: 225,
  [SORT_BY.cost]: 165,
  [SORT_BY.rate]: 140,
  [SORT_BY.description]: 150,
  padding: 20
};

const ITEM_HEIGHT = 62;
const MAX_TABLE_HEIGHT_BUFFER = 90 + 73 + 72;

const noop = () => {};

const rowIsLoaded = (row) => row.createRow || row.project?.id;

const orderedMemberTypes = [
  { memberType: 'active', label: 'Team Members' },
  { memberType: 'guest', label: 'Guests' },
  { memberType: 'archived', label: 'Archived' }
];

const BudgetAdminTable = ({ loadMoreItems }) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const listRef = useRef(null);
  const { newPermissionSettings } = useFeatureFlags();
  const fetchTeamPermissionsRequestStatusId = `${fetchTeamPermissions.type}-budget-admin-tab`;
  useEffect(() => {
    if (teamId) {
      dispatch(fetchRates({ teamId }));
      // dispatch(fetchTeamMembersRates({ teamId }));
      if (!newPermissionSettings) {
        dispatch(fetchTeamRoleTemplates({ teamId }));
      } else {
        dispatch(
          fetchTeamPermissions({
            teamId,
            meta: {
              requestStatusId: fetchTeamPermissionsRequestStatusId
            }
          })
        );
      }
      dispatch(fetchAllTeamRates({ teamId }));
      dispatch(fetchAllTeamSalaries({ teamId }));
      dispatch(fetchOverhead({ teamId }));
    }
  }, [
    dispatch,
    fetchTeamPermissionsRequestStatusId,
    newPermissionSettings,
    teamId
  ]);
  const formattedMemberBudgetSettings = useSelector(getFormattedBudgetSettings);
  const budgetSettingsColumnHeaders = useSelector(
    getBudgetSettingsColumnHeaders
  );
  const totalMembersCount = useSelector(getTeamMembers)?.length;
  const [activeSection, setActiveSection] = useState(null);
  const [stateIsScrolled, setIsScrolled] = useState(false);

  useEffect(
    () => {
      // do something when rates change
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, teamId, formattedMemberBudgetSettings]
  );
  const columns = useMemo(() => {
    return budgetSettingsColumnHeaders.map((timesheetColumnHeader) => ({
      ...timesheetColumnHeader,
      emptyRow: EmptyDiv,
      memberRow: cellComponents[timesheetColumnHeader.headerType],
      memberTypeRow: memberTypeComponents[timesheetColumnHeader.headerType],
      Cell: cellComponents[timesheetColumnHeader.headerType],
      Header: HeaderCell,
      Footer: HeaderCell,
      width: columnWidths[timesheetColumnHeader.headerType]
    }));
  }, [budgetSettingsColumnHeaders]);

  const memberTypeGroupedRowBuilder = useCallback(
    ({ formattedMembers = [], customRowProps, memberTypes }) => {
      const memberTypeLists = memberTypes.reduce((acc, cur, index) => {
        acc[cur.memberType] = {
          listItems: [],
          closedItems: [],
          customItems: [],
          headerData: {},
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'memberRow',
          headerType: 'memberTypeRow',
          headerHeight: 86,
          id: cur.memberType,
          skipEmptyGroups: false,
          isOpen: true,
          isEmpty: false,
          isFullyLoaded: null,
          renderHeader: () => cur.label,
          summaryNoun: 'member',
          createRowData: {},
          renderSummaryItem: (item) => item
        };
        return acc;
      }, {});

      // Move members without rates to bottom of list
      // const sortedFormattedMembers = [...formattedMembers].sort((a, b) =>
      //   a.rate && !b.rate ? -1 : 1
      // );

      const sortedFormattedMembers = formattedMembers;

      sortedFormattedMembers.forEach((formattedMember, index) => {
        const { member } = formattedMember;
        const listToPush = member.is_archived
          ? 'archived'
          : permissionsUtils.getIsProjectGuest(member)
          ? 'guest'
          : 'active';
        memberTypeLists?.[listToPush]?.listItems?.push(formattedMember);
      });

      const listsWithItems = [];
      const listsWithoutItems = [];

      orderedMemberTypes.forEach(({ memberType }) => {
        const list = memberTypeLists[memberType];

        if (list) {
          list.isFullyLoaded = true;

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      const listsInOrder = [...listsWithItems, ...listsWithoutItems];
      if (listsInOrder.length) {
        const [firstList] = listsInOrder;
        firstList.skipHeader = true;
        firstList.customItems = [];
        firstList.closedItems = [];
      }
      return listsInOrder;
    },
    []
  );

  const listBuilders = useMemo(
    () => ({
      [VIEW_BY.MEMBER_TYPES]: memberTypeGroupedRowBuilder
    }),
    [memberTypeGroupedRowBuilder]
  );

  const lists = useMemo(() => {
    const listBuilder = listBuilders[VIEW_BY.MEMBER_TYPES];
    if (!listBuilder) {
      return [];
    }
    return listBuilder({
      formattedMembers: formattedMemberBudgetSettings,
      memberTypes: orderedMemberTypes,
      // members: orderedTeamMembers,
      // projects: orderedProjects,
      // phases: orderedPhases,
      customRowProps: {}
    });
  }, [formattedMemberBudgetSettings, listBuilders]);

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps: {},
      engaged: {},
      search: {},
      emptyRow
    });
  }, [lists]);

  const isLoading = !totalMembersCount;

  const firstListId = useMemo(() => lists[0]?.id, [lists]);

  useEffect(() => {
    if (!isLoading) {
      setActiveSection(firstListId);
    }
  }, [firstListId, isLoading]);

  // const handleScroll = ({ visibleStartIndex }) => {
  //   const isScrolled = visibleStartIndex !== 0;
  //   if (!isScrolled) {
  //     setActiveSection(firstListId);
  //     setIsScrolled(false);
  //     return;
  //   }
  //   const item = rows[visibleStartIndex - 1];
  //   if (!item) {
  //     return;
  //   }
  //   if (item.listType !== activeSection) {
  //     setActiveSection(item.listType);
  //   }
  //   if (isScrolled !== stateIsScrolled) {
  //     setIsScrolled(isScrolled);
  //   }
  // };

  return (
    <StyledBudgetSettingsTable>
      <Table
        columns={columns}
        data={!isLoading ? rows : []}
        onDragEnd={noop}
        virtual
        itemHeight={ITEM_HEIGHT}
        // handleScroll={handleScroll}
        getItemSize={() => ITEM_HEIGHT}
        maxHeight={window.innerHeight - MAX_TABLE_HEIGHT_BUFFER}
        loadMoreItems={loadMoreItems}
        isVariableSizeTable
        listRef={listRef}
        isDragDisabled
      />

      {isLoading && (
        <div style={{ width: '100%' }}>
          <ContentLoader
            height="200"
            primaryColor="#ddd"
            secondaryColor="#eee"
            style={{ width: '823px', margin: '-10px 20px 0px' }}
          >
            <rect x="0" y="5" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="25" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="45" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="66" rx="2" ry="2" width="100%" height="15" />
          </ContentLoader>
        </div>
      )}
    </StyledBudgetSettingsTable>
  );
};

export default BudgetAdminTable;
