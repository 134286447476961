import moment from 'appUtils/momentConfig';
import { MomentInput, Moment } from 'moment';

export const calculateWeeklyDateRange = ({
  startDate,
  endDate,
  includeWeekends = false
}: {
  startDate: MomentInput;
  endDate: MomentInput;
  includeWeekends?: boolean;
}): {
  startDate: Moment | undefined;
  endDate: Moment | undefined;
} => {
  return {
    startDate: startDate
      ? moment(startDate).clone().startOf('week')
      : undefined,
    endDate: endDate
      ? moment(endDate)
          .clone()
          .endOf('week')
          .startOf('day')
          .isoWeekday(includeWeekends ? 7 : 5) // Sunday or Friday
      : undefined
  };
};
