import React from 'react';
import { useSelector } from 'react-redux';
import { getUserActivities } from 'selectors';
import styled from 'styled-components';
import { ScopeActivityLogCard } from 'components/Scope/modals/create-edit/components';
import {
  throttleUserActivities,
  defaultEventTypeComparator
} from 'appUtils/momentUtils';
import { actionableTypesHash } from 'appConstants/userActivities';

const ActivityLogSection = () => {
  const activities = useSelector(getUserActivities);
  const throttledActivities = throttle(activities);

  return (
    <StyledActivityLogSection>
      {throttledActivities.map((userActivity) => {
        const CardComponent = typeToCardComponent[userActivity.actionable_type];
        if (!CardComponent) return null;
        return (
          <CardComponent
            key={userActivity.user_activity_id}
            userActivity={userActivity}
          />
        );
      })}
    </StyledActivityLogSection>
  );
};

export default React.memo(ActivityLogSection);

/* ------------------------------------ - ----------------------------------- */

const typeToCardComponent = {
  [actionableTypesHash.ProjectScope]: ScopeActivityLogCard,
  [actionableTypesHash.ProjectScopeRequest]: ScopeActivityLogCard // TODO
};

const throttle = (userActivities) =>
  throttleUserActivities(userActivities, {
    intervalType: 'minutes',
    intervalAmount: 5,
    eventTypeComparator: defaultEventTypeComparator,
    recentEventDataOverWriter: ({
      project,
      display_data,
      action_type,
      timestamp
    }) => ({
      project,
      display_data,
      action_type, // show last action_type for the window of time when merging events
      timestamp
    }),
    keepFirst: true
  });

const StyledActivityLogSection = styled.div`
  padding-top: 20px;
  position: relative;
  overflow: auto;
`;
