import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { LocationAttributes } from 'SettingsModule/models/location';

export class LocationAttributesUtils {
  static isTwoLocationAttributesSame(
    location1: LocationAttributes,
    location2: LocationAttributes
  ): boolean {
    // explicitly pick the fields to compare since there is a chance that `location1` and `location2` types extend from `LocationAttributes` type (meaning a type that has all the fields that `LocationAttributes` has and more is still considered a `LocationAttributes` type)
    const fieldsToCompare: (keyof LocationAttributes)[] = [
      'address_1',
      'address_2',
      'country',
      'city',
      'state',
      'zip'
    ];

    return isEqual(
      pick(location1, fieldsToCompare),
      pick(location2, fieldsToCompare)
    );
  }
}
