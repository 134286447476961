import { createSelector } from 'reselect';
import { initialState as budgetBillingInitialState } from '../reducers/budgetBilling';
import { serializeBillingCategoryPhaseId } from 'BudgetModule/utils';
import { BILLING_TABLE_VIEWS } from 'appConstants';
import { defaultAppColors } from 'appUtils/styleUtils';
import { getFlatPhasesHash } from 'selectors';

const getOwnProjectId = (state, ownProps) => ownProps?.projectId;

export const getBillingState = (state) =>
  state.budgetBilling || budgetBillingInitialState;

export const getInvoicesByBillingCategoryAndPhase = (state) =>
  getBillingState(state).invoicesByBillingCategoryAndPhase;

export const getEstimatesByBillingCategoryAndPhase = (state) =>
  getBillingState(state).estimatesByBillingCategoryAndPhase;

export const getInvoicesHash = (state) => getBillingState(state).invoicesHash;

export const getBillingCategoriesHash = (state) =>
  getBillingState(state).billingCategoriesHash;

export const getProjectBillingCategoryOrder = createSelector(
  getOwnProjectId,
  getBillingState,
  (projectId, state) => state.billingCategoryOrderByProject[projectId] || []
);

export const getBillingTableView = (state) =>
  getBillingState(state).billingTableView;

export const getOrderedProjectBillingCategories = createSelector(
  getBillingCategoriesHash,
  getProjectBillingCategoryOrder,
  (billingCategoriesHash, billingCategoryOrder) => {
    return billingCategoryOrder.map((id) => billingCategoriesHash[id] || {});
  }
);

export const getBillingCategoriesWithPhaseInvoices = createSelector(
  getOrderedProjectBillingCategories,
  getBillingTableView,
  getEstimatesByBillingCategoryAndPhase,
  getInvoicesByBillingCategoryAndPhase,
  getInvoicesHash,
  (
    billingCategories,
    view,
    estimatesByBillingCategoryAndPhase,
    invoicesByBillingCategoryAndPhase,
    invoicesHash
  ) => {
    const billingCategoriesWithPhaseInvoices = [];

    billingCategories.forEach((billingCategory) => {
      const billingCategoryWithInvoices = { ...billingCategory };
      billingCategoryWithInvoices.billing_category_phases =
        billingCategoryWithInvoices.billing_category_phases.map(
          (billingCategoryPhase, index) => {
            const groupId = serializeBillingCategoryPhaseId({
              billingCategoryId: billingCategory.id,
              phaseId: billingCategoryPhase.phase_id
            });
            const invoicesToUse =
              view === BILLING_TABLE_VIEWS.ESTIMATES
                ? estimatesByBillingCategoryAndPhase
                : invoicesByBillingCategoryAndPhase;
            const invoices = (invoicesToUse[groupId] || []).map(
              (id) => invoicesHash[id]
            );
            return {
              ...billingCategoryPhase,
              invoices,
              totalInvoicesAmount: invoices.reduce(
                (acc, cur) => acc + +cur.amount,
                0
              ),
              color: defaultAppColors[index % defaultAppColors.length]
            };
          }
        );
      billingCategoriesWithPhaseInvoices.push(billingCategoryWithInvoices);
    });
    return billingCategoriesWithPhaseInvoices;
  }
);

// V1 spec only - phases on chart will be replaced by billing categories
export const getBillingChartPhaseData = createSelector(
  getBillingCategoriesWithPhaseInvoices,
  getFlatPhasesHash,
  (billingCategories, phaseHash) => {
    const percentageOfFeeData = [];
    const totalsData = [];
    const defaultBillingCategory = billingCategories[0] || {
      billing_category_phases: []
    };
    // Sort and filter out non-billable and phases without fee (that are not Hourly)
    const phasesSortedByFee = defaultBillingCategory.billing_category_phases
      .filter((bcp) => {
        const phase = phaseHash[bcp.phase_id];
        return (
          phase?.billable &&
          (+phase?.total ||
            (phase?.fee_type === 'Hourly' && bcp.totalInvoicesAmount))
        );
      })
      .sort(
        (a, b) =>
          Math.max(+phaseHash[b.phase_id]?.total, b.totalInvoicesAmount) -
          Math.max(+phaseHash[a.phase_id]?.total, a.totalInvoicesAmount)
      );
    phasesSortedByFee.forEach((bcp) => {
      const phase = phaseHash[bcp.phase_id] || {};
      const phaseFee = +phase.total;

      totalsData.push({
        name: bcp.phase_name,
        value: phaseFee || bcp.totalInvoicesAmount,
        totalInvoicesAmount: bcp.totalInvoicesAmount,
        type: 'phase',
        color: bcp.color
      });

      const scaledInvoiceAmount =
        phase.fee_type === 'Hourly'
          ? bcp.totalInvoicesAmount
          : phaseFee
          ? Math.min(phaseFee * (bcp.totalInvoicesAmount / phaseFee), phaseFee)
          : 0;
      percentageOfFeeData.push(
        ...[
          {
            name: bcp.phase_name,
            value: scaledInvoiceAmount,
            color: bcp.color
          },
          {
            name: bcp.phase_name,
            value:
              phase.fee_type === 'Hourly' ? 0 : phaseFee - scaledInvoiceAmount,
            isRemainder: true
          }
        ]
      );
    });

    return {
      percentageOfFeeData,
      totalsData
    };
  }
);
