import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NewMembersIcon = ({
  height = '12',
  width = '12',
  strokeWidth = '0.8',
  strokeColor = '#2F80ED'
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 12 12" fill="none">
      <circle
        cx="6"
        cy="3.8999"
        r="2.6"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d="M1 11.8999C1.625 8.56657 3.5 7.8999 6 7.8999C8.5 7.8999 10.375 8.56657 11 11.8999"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default NewMembersIcon;
