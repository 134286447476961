import styled from 'styled-components';
import theme from 'theme';

export const DateNavigatorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 30px;
`;

export const TimesheetHeaderContainer = styled.div`
  margin-bottom: -70px;
  margin-top: 30px;
`;

export const SecondRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSettingsIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;
