import { MutableRefObject } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import theme from 'theme';

import { SectionTitle } from './styles';

const HoursIndicatorSection = ({
  hours,
  placeholder = '0 h',
  title = 'EST. HRS.',
  onBlur,
  onKeyDown,
  testId = 'estimated-hours-section',
  disabled,
  backgroundColor,
  hoursInputRef
}: {
  hours?: Nullable<string>;
  placeholder?: string;
  title?: string;
  onBlur?: (...args: any[]) => void;
  onKeyDown?: (...args: any[]) => void;
  testId?: string;
  disabled?: boolean;
  backgroundColor?: string;
  hoursInputRef?: MutableRefObject<HTMLInputElement | null>;
}) => {
  return (
    <StyledHoursIndicatorContainer>
      <SectionTitle>{title}</SectionTitle>
      <StyledInput
        data-testid={testId}
        value={hours}
        placeholder={placeholder}
        getInputRef={hoursInputRef}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        decimalScale={2}
        allowNegative={false}
        thousandSeparator={true}
        isNumericString
        allowLeadingZeros={false}
        suffix={' h'}
        backgroundColor={backgroundColor}
        disabled={disabled}
      />
    </StyledHoursIndicatorContainer>
  );
};

export default HoursIndicatorSection;

/* ------------------------------------ - ----------------------------------- */

const StyledHoursIndicatorContainer = styled.div`
  position: relative;
  padding: 3px 0;
`;

const StyledInput = styled(NumberFormat)<{ backgroundColor?: string }>`
  font-weight: 600;
  font-size: 13px;
  color: ${theme.colors.colorCalendarGray};
  width: 75px;
  height: 25px;
  background: ${({ backgroundColor }) =>
    backgroundColor || theme.colors.colorLightGray19};
  border-radius: 3px;
  text-align: left;
  border: none;
  position: relative;
  bottom: 3px;

  ${({ disabled }) =>
    disabled
      ? `
    pointer-event: none;
  `
      : ''}
`;
