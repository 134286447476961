import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ArrowEnterSquareIcon = ({
  height = '169',
  width = '43',
  stroke = '#DBDBDB',
  secondPathStroke = '#333333',
  viewBox = '0 0 169 43',
  fill = '#333333'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="6.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        fill="white"
        stroke={stroke}
        strokeWidth="0.5"
      />
      <path
        d="M12 19.5V15H22V29H12V24.5"
        stroke={secondPathStroke}
        strokeWidth="1.4"
      />
      <path d="M16 19.5L18.5 22L16 24.5V22V19.5Z" fill={fill} />
      <path
        d="M9 22H18.5M18.5 22L16 19.5V24.5L18.5 22Z"
        stroke="#333333"
        strokeWidth="1.2"
      />
    </SvgIcon>
  );
};

export default ArrowEnterSquareIcon;
