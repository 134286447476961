import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CloseModalIcon = (props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M7.25 7.25L14.6746 14.6746"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M7.25 14.6746L14.6746 7.25"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </SvgIcon>
);

export default CloseModalIcon;
