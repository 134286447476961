import { zoomToIntervalHash } from 'appUtils/projectPlannerUtils';
import { DateHeader } from 'react-calendar-timeline';
import { BaseDateHeaderProps } from './types';
import { SecondaryIntervalRenderer } from './SecondaryIntervalRenderer';
import { DATE_HEADER_HEIGHT } from 'TimelinesModule/components/constants';

export const SecondaryDateHeader = ({ zoom }: BaseDateHeaderProps) => {
  return (
    <DateHeader
      height={DATE_HEADER_HEIGHT.Secondary}
      unit={zoomToIntervalHash[zoom]}
      intervalRenderer={SecondaryIntervalRenderer}
      headerData={{ zoom }}
    />
  );
};
