import { fork, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { createChannel } from 'sagas/channels';
import { conditionalTakeLatest } from 'sagas/helpers';
import * as constants from '../constants';
import {
  fetchTeamCapacityWorker,
  updateTeamCapacityWorker
} from './teamCapacities';
import {
  fetchCapacitiesWorker,
  updateCapacityWorker
} from './accountCapacities';
import { fetchCapacityReportWorker } from './capacityReport';
import {
  fetchHolidaysWorker,
  createHolidayWorker,
  updateHolidayWorker,
  deleteHolidayWorker,
  fetchHolidayGroupsWorker,
  createHolidayGroupWorker,
  updateHolidayGroupWorker,
  deleteHolidayGroupWorker
} from './holidays';
import capacityGroupWorkers from './capacityGroup';
import {
  fetchCapacityPolicies,
  createCapacityPolicy,
  updateCapacityPolicy,
  archiveCapacityPolicy,
  fetchCapacityPoliciesPerMember,
  fetchCapacityGroupMemberships,
  createCapacityGroupMembership,
  updateCapacityGroupMembership,
  deleteCapacityGroupMembership
} from 'CapacityModule/actionCreators/capacityGroup';

export default function* capacitySaga() {
  const capacitySagas = [
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_CAPACITY.TRIGGER,
      fetchTeamCapacityWorker
    ),

    yield fork(
      takeEvery,
      constants.UPDATE_TEAM_CAPACITY.TRIGGER,
      updateTeamCapacityWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_CAPACITIES.TRIGGER,
      fetchCapacitiesWorker
    ),
    yield fork(
      conditionalTakeLatest,
      constants.FETCH_CAPACITY_REPORT.TRIGGER,
      fetchCapacityReportWorker,
      undefined, // use default
      ['filterStateId']
    ),
    yield fork(
      createChannel,
      constants.FETCH_QUEUED_CAPACITY_REPORT.TRIGGER,
      fetchCapacityReportWorker
    ),

    yield fork(
      takeEvery,
      constants.UPDATE_CAPACITY.TRIGGER,
      updateCapacityWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_HOLIDAYS.TRIGGER,
      fetchHolidaysWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_HOLIDAY.TRIGGER,
      updateHolidayWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_HOLIDAY.TRIGGER,
      createHolidayWorker
    ),
    yield fork(
      takeLatest,
      constants.DELETE_HOLIDAY.TRIGGER,
      deleteHolidayWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_HOLIDAY_GROUPS.TRIGGER,
      fetchHolidayGroupsWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_HOLIDAY_GROUP.TRIGGER,
      updateHolidayGroupWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_HOLIDAY_GROUP.TRIGGER,
      createHolidayGroupWorker
    ),
    yield fork(
      takeLatest,
      constants.DELETE_HOLIDAY_GROUP.TRIGGER,
      deleteHolidayGroupWorker
    ),
    yield fork(
      takeEvery,
      fetchCapacityPolicies,
      capacityGroupWorkers.fetchCapacityPoliciesWorker
    ),
    yield fork(
      takeEvery,
      createCapacityPolicy,
      capacityGroupWorkers.createCapacityPolicyWorker
    ),
    yield fork(
      takeEvery,
      updateCapacityPolicy,
      capacityGroupWorkers.updateCapacityPolicyWorker
    ),
    yield fork(
      takeEvery,
      archiveCapacityPolicy,
      capacityGroupWorkers.archiveCapacityPolicyWorker
    ),
    yield fork(
      takeEvery,
      fetchCapacityPoliciesPerMember,
      capacityGroupWorkers.fetchCapacityPoliciesPerMemberWorker
    ),
    yield fork(
      takeEvery,
      fetchCapacityGroupMemberships,
      capacityGroupWorkers.fetchCapacityGroupMembershipsWorker
    ),
    yield fork(
      takeEvery,
      createCapacityGroupMembership,
      capacityGroupWorkers.createCapacityGroupMembershipWorker
    ),
    yield fork(
      takeEvery,
      updateCapacityGroupMembership,
      capacityGroupWorkers.updateCapacityGroupMembershipWorker
    ),
    yield fork(
      takeEvery,
      deleteCapacityGroupMembership,
      capacityGroupWorkers.deleteCapacityGroupMembershipWorker
    )
  ];
  yield all(capacitySagas);
}
