import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'reduxInfra/hooks';
import { getInstalledBudgetSettingsModuleIds } from 'SettingsModule/selectors/budgetSettings';
import ModuleList from 'components/ViewFactory/ModuleList';

export const BudgetSettingsTabs = ({ settingsViewType, teamSlug }) => {
  const history = useHistory();
  const installedModuleIds = useAppSelector(
    getInstalledBudgetSettingsModuleIds
  );

  const changeViewDisplay = (newTab: string) => () => {
    const route = `/${teamSlug}/settings/budget/${newTab}`;
    history.push(route);
  };

  return (
    <ModuleList
      currentTab={settingsViewType}
      changeTabs={changeViewDisplay}
      installedModuleIds={installedModuleIds}
      viewType="budgetSettings"
    />
  );
};
