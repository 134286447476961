import React, { useRef } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { SectionTitle } from './styles';
import TextAreaWithSave from './TextAreaWithSave';

/**
 * For wrapping styles and providing default props. Handle logic in parent
 */
const NoteSection = ({
  text,
  placeholder = 'Add Notes',
  title = 'NOTES',
  onChange,
  onSave,
  testId = 'notes-section',
  maxCharLength = 1000,
  disabled
}) => {
  const inputRef = useRef(null);
  return (
    <NoteSectionContainer
      disabled={disabled}
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      <SectionTitle>{title}</SectionTitle>
      <TextAreaWithSave
        text={text}
        placeholder={placeholder}
        onChange={onChange}
        onSave={onSave}
        testId={testId}
        maxCharLength={maxCharLength}
        disabled={disabled}
        forwardedRef={inputRef}
      />
    </NoteSectionContainer>
  );
};

export default NoteSection;

/* ------------------------------------ - ----------------------------------- */

const NoteSectionContainer = styled.div`
  .text-area {
    padding: 3px 0;
    &:focus {
      color: ${({ disabled }) =>
        disabled
          ? theme.colors.colorBudgetGrey
          : theme.colors.colorSemiDarkGray1};
      padding: 3px 5px;
    }
  }

  &:hover {
    .text-area::placeholder,
    .text-area {
      color: ${({ disabled }) =>
        disabled
          ? theme.colors.colorBudgetGrey
          : theme.colors.colorCalendarBlue};
    }

    .text-area:focus::placeholder {
      color: ${theme.colors.colorBudgetGrey};
    }

    .text-area:focus {
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }

  ${(props) =>
    !props.disabled &&
    `
      &,
      & > * {
        cursor: pointer;
      }
  `}
`;
