import 'isomorphic-fetch';
import qs from 'qs';
import { callApi } from 'appUtils/api';

export const fetchUtilizations = (
  team_id,
  token,
  team_member_ids,
  start_date,
  end_date,
  scale,
  include_tentative
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id,
    team_member_ids,
    start_date,
    end_date,
    scale,
    include_tentative
  };

  return callApi(
    `team_planner_utilization?${qs.stringify(queryParams, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
export const fetchUtilizationsReport = (team_id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `team_planner_aggregate_utilization?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const fetchUtilizationsIntervalReport = (_, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `utilization_report?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const fetchTeamHistoricalUtilization = (team_id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `historical_utilization?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
