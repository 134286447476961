/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef
} from 'react';
import { connect } from 'react-redux';
import sumBy from 'lodash/sumBy';
import {
  getMe,
  getTeamMembersHash,
  getProjectHash,
  getGroupsHash,
  getTheme,
  getOOOProject
} from 'selectors';
import { makeGetProjectDataByAccounts } from 'BudgetModule/selectors';
import ContentLoader from 'react-content-loader';
import Spinner from 'react-spinkit';
import Table from 'components/Table';
import { StyledCapacityReportTable, StyledTooltip } from './styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { getUtilizationReportColumnHeaders } from 'ReportsModule/selectors';

import HeaderCell from 'ReportsModule/components/Time/TimesheetsTable/Cells/HeaderCell';

import { SORT_BY } from 'appConstants/filters';
import { VIEW_BY, CAPACITY_DATE_KEYS } from 'appConstants/workload';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import ProjectBreakdownTooltip from './Cells/components/ProjectBreakdownTooltip';
import {
  DATA_KEY,
  CAPACITY_KEY,
  DATA_KEY_TO_PERCENT,
  CHART_STACK_ORDER,
  CHART_STACK_ORDER_WITH_CAPACITY
} from 'UtilizationModule/constants';

import {
  makeGetOrderedMemberUtilizations,
  makeGetMemberUtiliztionsTotalCounts
} from 'UtilizationModule/selectors';
import {
  MemberCell,
  GraphCell,
  SubRowGraphCell,
  TotalCell,
  MemberHeaderCell,
  ProjectBreakdownCell,
  TotalHeaderCell,
  CollapseCell,
  ProjectHeaderCell
} from './Cells';
import { serializeId } from 'appUtils';
import { useCallbackEffect } from 'appUtils/hooks/useCallbackEffect';

const capacitySummaryHash = {};
CAPACITY_DATE_KEYS.forEach((dateKey) => {
  capacitySummaryHash[dateKey] = 0;
});

const EmptyDiv = () => <div />;
const Loader = ({ column }) => {
  return column.headerType === 'graph' ? (
    <Spinner
      fadeIn="none"
      name="ball-beat"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    />
  ) : null;
};
const emptyRow = { rowType: 'emptyRow' };

const rowCells = {};
const memberRowCells = {
  collapse: CollapseCell,
  [SORT_BY.member]: MemberCell,
  graph: GraphCell,
  total: TotalCell
};
const memberRowProjectViewCells = {
  collapse: CollapseCell,
  [SORT_BY.member]: MemberCell,
  graph: ProjectBreakdownCell,
  total: TotalCell
};
const memberDetailRowCells = {};

const projectRowCells = {
  leftPadding: EmptyDiv,
  collapse: EmptyDiv,
  [SORT_BY.member]: ProjectHeaderCell,
  graph: SubRowGraphCell,
  total: TotalCell
};

const headerComponents = {
  graph: EmptyDiv,
  member: MemberHeaderCell,
  total: TotalHeaderCell
};

const loadingRowCells = {
  leftPadding: EmptyDiv,
  graph: Loader,
  member: EmptyDiv
};

const columnWidths = {
  leftPadding: 30,
  subRowLeftPadding: 60,
  collapse: 15,
  [SORT_BY.member]: 235,
  graph: 494,
  total: 130,
  rightPadding: 30
};

const ITEM_HEIGHT = 74;
const MAX_TABLE_HEIGHT_BUFFER = 190;

const noop = () => {};
const emptyObj = {};
const emptyArray = [];

const buildHeader = ({ list, customRowProps }) => {
  const { id, headerType, isOpen, headerHeight, renderHeader, headerData } =
    list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    renderHeader,
    customRowProps
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: item.rowHeight || list.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow,
  breakOnLoadingRow = true
}) => {
  const flatList = [];
  let breakFlag = false;
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      for (const item of listItems) {
        if (item.hasSubLists) {
          const { list: subList, breakFlag: subBreakFlag } = buildFlatList({
            lists: [item],
            engaged,
            search,
            customRowProps,
            emptyRow,
            breakOnLoadingRow
          });
          flatList.push(...subList);
          if (subBreakFlag) {
            breakFlag = true;
            break;
          }
        } else {
          flatList.push(buildRow({ list, item, customRowProps }));
        }
      }

      if (!isFullyLoaded && !breakFlag) {
        flatList.push(
          buildRow({
            list,
            customRowProps,
            item: { rowType: 'loadingRow' }
          })
        );
        if (breakOnLoadingRow) {
          breakFlag = true;
          break;
        }
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
    if (!breakFlag && list.addEmptyRow) {
      flatList.push(
        buildCustomItem({
          list,
          customRowProps,
          item: emptyRow
        })
      );
    }
  }
  return { list: flatList, breakFlag };
};

const UtilizationReportTable = ({
  orderedMemberUtilizations,
  isPercent,
  loaded,
  isDashboardWidgetModal,
  columnHeaders,
  viewBy,
  me,
  teamMembersHash,
  activeFilter,
  accountProjectData,
  totalMemberUtilizationsCount,
  loadTopLevel,
  loadSubLevel,
  updateSortAttributes,
  sortOrder,
  pageName,
  projectsHash,
  boardsHash,
  projectColors,
  ordersByGroup, // from budget_report
  isFetching,
  isFetchingInitially,
  OOOProject,
  totalCounts, // from budget_report
  showUtilizationByProjects,
  useCapacityAsDenominator,
  includeRemainingCapacity,
  isPrinting
}) => {
  const listRef = useRef(null);

  const [isOpen, setIsOpen] = useState({});
  const [allOpen, setAllOpen] = useState(false);
  const [hasLoaded, setHasLoaded] = useState({});

  // Reset on initial loads
  useEffect(() => {
    setIsOpen({});
    setHasLoaded({});
  }, [loadTopLevel]);

  const columns = useMemo(() => {
    return columnHeaders.map((timesheetColumnHeader) => ({
      ...timesheetColumnHeader,
      memberRow: memberRowCells[timesheetColumnHeader.headerType] || EmptyDiv,
      memberDetailRow:
        memberDetailRowCells[timesheetColumnHeader.headerType] || EmptyDiv,
      memberRowProjectView:
        memberRowProjectViewCells[timesheetColumnHeader.headerType] || EmptyDiv,
      projectRow: projectRowCells[timesheetColumnHeader.headerType],
      loadingRow: loadingRowCells[timesheetColumnHeader.headerType],
      Cell: rowCells[timesheetColumnHeader.headerType],
      Header: headerComponents[timesheetColumnHeader.headerType] || HeaderCell,
      Footer: HeaderCell,
      emptyRow: EmptyDiv,
      width: columnWidths[timesheetColumnHeader.headerType]
    }));
  }, [columnHeaders]);

  const { showPto: showPtoFromFilter, showHolidays: showHolidaysFromFilter } =
    activeFilter?.custom;
  const showPto = isDashboardWidgetModal ? false : showPtoFromFilter;
  const showHolidays = isDashboardWidgetModal ? false : showHolidaysFromFilter;

  const totalColumnsWidth = useMemo(
    () =>
      columnHeaders.reduce(
        (acc, cur) => acc + (+columnWidths[cur.headerType] || 0),
        0
      ),
    [columnHeaders]
  );

  /* ------------------------------ Row builders ------------------------------ */

  const projectGroupedRowBuilder = useCallback(
    ({
      parentId,
      order,
      currentMaxValue,
      currentMaxPercent,
      ptoAndHolidayData,
      parentTotalHours,
      accountCapacityTotal // denominator when pto or holiday option is on
    }) => {
      const projectLists = order.reduce((acc, projectId, index) => {
        const uid = serializeId({
          ids: [parentId, projectId],
          itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT
        });

        const accountUid = serializeId({
          ids: [parentId],
          itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT
        });
        const projectData =
          accountProjectData[accountUid]?.[projectId] || emptyObj;

        // spent_hours considered nonbillable hours
        const { spent_hours_billable, spent_hours } = projectData;
        const isBillableProjectRow = +spent_hours_billable > 0;

        // when both pto and holidays are off, percentage is calculated by either billable or nonbillable
        const denominator = accountCapacityTotal;
        const numerator = isBillableProjectRow
          ? +spent_hours_billable
          : +spent_hours;

        const denominatorToUse =
          denominator > 0 ? denominator : parentTotalHours;
        const data = {
          accountCapacityTotal,
          totalPercent: (numerator / denominatorToUse) * 100,
          totalHours: numerator
        };

        // pto_hours and holiday_hours are not part of BE response. they are added manually under fake id
        if (projectId === DATA_KEY.PTO || projectId === DATA_KEY.HOLIDAY) {
          const isPto = projectId === DATA_KEY.PTO;
          const dataKey = isPto ? DATA_KEY.PTO : DATA_KEY.HOLIDAY;
          const ptoOrHolidayInfo = {
            title: isPto ? 'PTO' : 'Holiday',
            dataKey,
            description: ''
          };
          const headerDataProps = {
            ptoOrHolidayInfo,
            dataKey,
            data: {
              totalHours: isPto
                ? ptoAndHolidayData[DATA_KEY.PTO]
                : ptoAndHolidayData[DATA_KEY.HOLIDAY],
              totalPercent: isPto
                ? ptoAndHolidayData[DATA_KEY.PTO_PERCENT]
                : ptoAndHolidayData[DATA_KEY.HOLIDAY_PERCENT],
              accountCapacityTotal
            },
            isPtoOrHoliday: true,
            uid,
            showPto,
            showHolidays
          };
          acc[projectId] = {
            listItems: [],
            closedItems: [],
            customItems: [],
            ...headerDataProps,
            headerData: headerDataProps,
            getItemId: (item) => item.id,
            handleClick: (item) => console.log(item),
            rowType: 'projectRow',
            headerType: 'projectHeaderRow',
            headerHeight: 57,
            rowHeight: 57,
            id: projectId,
            index,
            skipEmptyGroups: false,
            isOpen: true,
            isEmpty: false,
            isFullyLoaded: true,
            renderHeader: () => 'member',
            summaryNoun: 'member',
            renderSummaryItem: (item) => item,
            skipHeader: false,
            hasParentList: true,
            hasSubLists: false
          };

          return acc;
        }

        if (+projectId === OOOProject?.id) return acc;

        acc[projectId] = {
          listItems: [],
          closedItems: [],
          customItems: [],
          projectId,
          data,
          isBillableProjectRow,
          showPto,
          showHolidays,
          dataKey: isBillableProjectRow
            ? 'billable_hours'
            : 'nonbillable_hours',
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'projectRow',
          headerType: 'projectHeaderRow',
          headerHeight: 57,
          rowHeight: 57,
          id: projectId,
          uid,
          index,
          skipEmptyGroups: false,
          isProjectRow: true,
          isOpen: true,
          isEmpty: false,
          isFullyLoaded: true,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          renderSummaryItem: (item) => item,
          skipHeader: false,
          hasParentList: true
        };

        return acc;
      }, {});

      const projectList = order
        .map((id) => {
          const list = projectLists[id];
          if (list) {
            return {
              ...list,
              maxValue: currentMaxValue,
              maxPercent: currentMaxPercent,
              headerData: {
                ...list.headerData,
                maxValue: currentMaxValue,
                maxPercent: currentMaxPercent
              }
            };
          }
          return null;
        })
        .filter((list) => list);

      return { subList: projectList };
    },
    [accountProjectData, OOOProject?.id, showPto, showHolidays]
  );

  const memberGroupedRowBuilder = useCallback(() => {
    let maxHours = 0;
    const maxPercent = { value: 0 };

    const memberLists = orderedMemberUtilizations.reduce(
      (acc, memberUtilization, index) => {
        const accountId = memberUtilization.account_id;
        const account = teamMembersHash[accountId]?.account;

        const uid = serializeId({
          ids: [accountId],
          itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT
        });

        const accountProjectOrder = showUtilizationByProjects
          ? ordersByGroup[uid] || emptyArray
          : emptyArray;

        const accountProjectDataToUse = showUtilizationByProjects
          ? accountProjectData[uid] || emptyObj
          : emptyObj;

        const {
          pto_hours,
          ptoPercent,
          holiday_hours,
          holidayPercent,
          capacity_for_percentage,
          totalHours
        } = memberUtilization;

        // percent for bar length and percent to display are different
        // percent to display does not include remaining capacity
        const percentToShow = sumBy(
          CHART_STACK_ORDER,
          (key) => +memberUtilization[DATA_KEY_TO_PERCENT[key]] || 0
        );

        // this is only used in GraphCell to calculate bar length
        const percentForBarLength = useCapacityAsDenominator
          ? sumBy(
              includeRemainingCapacity
                ? CHART_STACK_ORDER_WITH_CAPACITY
                : CHART_STACK_ORDER,
              (key) =>
                // percent vlues are calculated by capacity when useCapacityAsDenominator is true
                // do not include negative percent value which will make it 100% always
                Math.max(+memberUtilization[DATA_KEY_TO_PERCENT[key]] || 0, 0)
            )
          : +memberUtilization.billable_percentage || 0;

        maxHours = Math.max(totalHours, maxHours);
        maxPercent.value = Math.max(percentForBarLength, maxPercent.value);

        const hasPTO = +memberUtilization[DATA_KEY.PTO] > 0 && showPto;
        const hasHoliday =
          +memberUtilization[DATA_KEY.HOLIDAY] > 0 && showHolidays;
        const sublevelOrder = ordersByGroup[uid] || [];
        const sublevelCount =
          totalCounts[BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT]?.[uid];
        const sublevelFullyLoaded = sublevelOrder.length >= sublevelCount;
        const sublevelOrderForSubrow = sublevelFullyLoaded
          ? [
              ...sublevelOrder,
              ...(hasPTO ? [DATA_KEY.PTO] : emptyArray),
              ...(hasHoliday ? [DATA_KEY.HOLIDAY] : emptyArray)
            ]
          : sublevelOrder;

        const data = {
          ...memberUtilization,
          accountProjectOrder,
          accountProjectData: accountProjectDataToUse,
          accountCapacityTotal: capacity_for_percentage,
          percentForBarLength,
          totalPercent: percentToShow
        };

        acc[accountId] = {
          listItems: [],
          closedItems: [],
          customItems: [],
          account,
          data,
          headerData: {
            account,
            data,
            uid,
            showPto,
            showHolidays,
            hasSubLists: true
          },
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'memberRow',
          headerType: showUtilizationByProjects
            ? 'memberRowProjectView'
            : 'memberRow',
          headerHeight: 74,
          id: accountId,
          uid,
          skipEmptyGroups: false,
          isOpen: isOpen[uid] === undefined ? allOpen : isOpen[uid],
          isEmpty: false,
          isFullyLoaded: sublevelOrder.length >= sublevelCount,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          renderSummaryItem: (item) => item,
          skipHeader: false,
          hasSubLists: true
        };

        // Project sub rows
        if (acc[accountId].isOpen && sublevelOrderForSubrow.length) {
          const { subList } = projectGroupedRowBuilder({
            parentId: accountId,
            order: sublevelOrderForSubrow,
            currentMaxHours: maxHours,
            currentMaxPercent: maxPercent.value,
            ptoAndHolidayData: {
              pto_hours,
              ptoPercent,
              holiday_hours,
              holidayPercent
            },
            parentTotalHours: totalHours,
            accountCapacityTotal: capacity_for_percentage
          });
          acc[accountId].listItems.push(...subList);
        }

        return acc;
      },
      {}
    );

    const listsWithData = [];
    const listsWithoutData = [];

    orderedMemberUtilizations.forEach((utilization) => {
      const accountId = utilization.account_id;
      let list = memberLists[accountId];
      if (list) {
        list = {
          ...list,
          headerData: {
            ...list.headerData,
            maxHours,
            maxPercent: maxPercent.value
          },
          maxHours,
          maxPercent: maxPercent.value
        };
        const listToPush = list.isEmpty ? listsWithoutData : listsWithData;
        listToPush.push(list);
      }
    });

    const lastList = listsWithData[listsWithData.length - 1];
    if (lastList && listsWithoutData.length) {
      lastList.headerData.className = 'last-full-row';
    }
    const listsInOrder = [...listsWithData, ...listsWithoutData];
    return listsInOrder;
  }, [
    orderedMemberUtilizations,
    teamMembersHash,
    showUtilizationByProjects,
    ordersByGroup,
    accountProjectData,
    useCapacityAsDenominator,
    includeRemainingCapacity,
    showPto,
    showHolidays,
    totalCounts,
    isOpen,
    allOpen,
    projectGroupedRowBuilder
  ]);

  const listBuilders = useMemo(
    () => ({
      [VIEW_BY.MEMBERS]: memberGroupedRowBuilder
    }),
    [memberGroupedRowBuilder]
  );

  const handleLoadLevel = useCallback(
    (uid) => {
      if (!hasLoaded[uid]) {
        loadSubLevel(uid);
        setHasLoaded((curr) => ({
          ...curr,
          [uid]: true
        }));
      }
    },
    [hasLoaded, loadSubLevel]
  );

  const handleSetIsOpen = useCallback(
    ({ uid, value }) => {
      setIsOpen({ ...isOpen, [uid]: value });
    },
    [isOpen]
  );

  const customRowProps = useMemo(
    () => ({
      me,
      isPercent,
      showUtilizationByProjects,
      sortOrder,
      updateSortAttributes,
      viewBy,
      activeFilter,
      pageName,
      projectsHash,
      boardsHash,
      projectColors,
      showPto,
      showHolidays,
      isOpen,
      isDashboardWidgetModal,
      useCapacityAsDenominator,
      includeRemainingCapacity,
      handleSetIsOpen,
      handleLoadLevel
    }),
    [
      me,
      isPercent,
      showUtilizationByProjects,
      sortOrder,
      updateSortAttributes,
      viewBy,
      activeFilter,
      pageName,
      projectsHash,
      boardsHash,
      projectColors,
      showPto,
      showHolidays,
      isOpen,
      isDashboardWidgetModal,
      useCapacityAsDenominator,
      includeRemainingCapacity,
      handleSetIsOpen,
      handleLoadLevel
    ]
  );

  const lists = useMemo(() => {
    const listBuilder = listBuilders[viewBy];
    if (!listBuilder) {
      return [];
    }
    const listItems = listBuilder();
    const isFullyLoaded =
      orderedMemberUtilizations.length >= totalMemberUtilizationsCount;

    const mainList = {
      listItems,
      closedItems: [],
      customItems: [],
      addEmptyRow: isFullyLoaded,
      headerData: {},
      getItemId: (item) => item.id,
      handleClick: (item) => console.log(item),
      rowType: showUtilizationByProjects ? 'memberRowProjectView' : 'memberRow',
      headerType: '',
      headerHeight: 79,
      id: 'main',
      skipEmptyGroups: false,
      isOpen: true,
      isEmpty: false,
      isFullyLoaded,
      renderHeader: () => '',
      summaryNoun: '',
      renderSummaryItem: (item) => item,
      skipHeader: true
    };

    return [mainList];
  }, [
    listBuilders,
    orderedMemberUtilizations.length,
    showUtilizationByProjects,
    totalMemberUtilizationsCount,
    viewBy
  ]);

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps,
      engaged: {},
      search: {},
      emptyRow
    }).list;
  }, [customRowProps, lists]);

  const numberOfAdditionalRowsForThreshold = useMemo(
    () => rows.filter((row) => !row.isRow).length,
    [rows]
  );

  const getItemSize = useCallback(
    (index) => rows[index]?.itemHeight || ITEM_HEIGHT,
    [rows]
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [rows, viewBy]);

  useEffect(() => {
    rebuildTooltip();
  }, [accountProjectData, orderedMemberUtilizations]);

  const handleScroll = () => {
    rebuildTooltip();
  };

  const _loadMoreItems = useCallback(
    (startIndex) => {
      const item = rows[startIndex - 1];
      if (item && item.rowType === 'loadingRow' && !isFetching) {
        if (item.listType === 'main') {
          const offset = item.list.listItems.length;
          // Initial load is handled in Container
          if (offset) loadTopLevel(offset);
        } else {
          loadSubLevel(item.list.uid);
        }
      }
    },
    [isFetching, loadSubLevel, loadTopLevel, rows]
  );
  // see comments in the hook for why this is needed
  const loadMoreItems = useCallbackEffect(_loadMoreItems);

  return (
    <StyledCapacityReportTable
      className="capacity-report"
      isPercent={isPercent}
      loaded={loaded}
      isDashboardWidgetModal={isDashboardWidgetModal}
    >
      {isFetchingInitially && (
        <div
          style={{
            marginLeft: columnWidths.collapse,
            width:
              totalColumnsWidth -
              columnWidths.rightPadding -
              columnWidths.collapse
          }}
        >
          <ContentLoader
            height="100"
            primaryColor="#ddd"
            secondaryColor="#eee"
            style={{ width: '100%' }}
          >
            <rect x="0" y="5" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="25" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="45" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="66" rx="2" ry="2" width="100%" height="15" />
          </ContentLoader>
        </div>
      )}

      <Table
        columns={columns}
        data={!isFetchingInitially ? rows : []}
        numberOfAdditionalRowsForThreshold={numberOfAdditionalRowsForThreshold}
        onDragEnd={noop}
        virtual
        getItemSize={getItemSize}
        maxHeight={window.innerHeight - MAX_TABLE_HEIGHT_BUFFER}
        loadMoreItems={loadMoreItems}
        handleScroll={handleScroll}
        itemHeight={ITEM_HEIGHT}
        isVariableSizeTable
        customRowProps={customRowProps}
        listRef={listRef}
        showHeader={!isFetchingInitially}
        // overscanCount={isPrinting ? 999 : undefined}
        columnMinWidth={15}
      />

      {showUtilizationByProjects ? (
        <ProjectBreakdownTooltip />
      ) : (
        <StyledTooltip
          effect="solid"
          id={`schedule-bar`}
          place="top"
          multiline={true}
        />
      )}
    </StyledCapacityReportTable>
  );
};

const makeMapStateToProps = () => {
  const getOrderedMemberUtilizations = makeGetOrderedMemberUtilizations();
  const getMemberUtiliztionsTotalCounts = makeGetMemberUtiliztionsTotalCounts();
  const getProjectDataByAccounts = makeGetProjectDataByAccounts();

  const mapStateToProps = (state, ownProps) => ({
    columnHeaders: getUtilizationReportColumnHeaders(state, ownProps),
    me: getMe(state),
    orderedMemberUtilizations: getOrderedMemberUtilizations(state, ownProps),
    totalMemberUtilizationsCount: getMemberUtiliztionsTotalCounts(
      state,
      ownProps
    ),
    teamMembersHash: getTeamMembersHash(state),
    accountProjectData: getProjectDataByAccounts(state, ownProps),
    projectsHash: getProjectHash(state),
    boardsHash: getGroupsHash(state),
    projectColors: getTheme(state).projectColors,
    OOOProject: getOOOProject(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UtilizationReportTable);
