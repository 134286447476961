import styled from 'styled-components';
import { DependencyTagContent } from '../types';
import { DependencyTag } from './DependencyTag';

export const DependencyTagList = ({
  dependencyTagsContent
}: {
  dependencyTagsContent: DependencyTagContent[];
}) => {
  return (
    <DependencyTagContainer>
      {dependencyTagsContent.map((dependencyTagContent) => (
        <DependencyTag
          key={dependencyTagContent.key}
          dependencyTagContent={dependencyTagContent}
        />
      ))}
    </DependencyTagContainer>
  );
};
export const DependencyTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px 4px;
  flex-wrap: wrap;
  margin-bottom: 6px;
`;
