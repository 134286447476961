import { createReducer, Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import {
  fetchCapacityPoliciesActionCreatorsMap,
  createCapacityPolicyActionCreatorsMap,
  updateCapacityPolicyActionCreatorsMap,
  archiveCapacityPolicyActionCreatorsMap,
  fetchCapacityPoliciesPerMemberActionCreatorsMap,
  fetchCapacityGroupMembershipsActionCreatorsMap,
  createCapacityGroupMembershipActionCreatorsMap,
  updateCapacityGroupMembershipActionCreatorsMap,
  deleteCapacityGroupMembershipActionCreatorsMap
} from 'CapacityModule/actionCreators/capacityGroup';
import { CapacityGroup, CapacityGroupMembership } from 'CapacityModule/models';

const byCapacityGroupId = (item: CapacityGroup) => item.id;

export interface CapacityGroupsState {
  capacityGroupsHash: Record<number, CapacityGroup>;
  capacityGroupsIdsOrder: number[];
  capacitiesByAccountHash: Record<number, CapacityGroup>;
  capacityMembershipByAccountHash: Record<number, CapacityGroupMembership[]>;
}

export const initialState: CapacityGroupsState = {
  capacityGroupsHash: {},
  capacityGroupsIdsOrder: [],
  capacitiesByAccountHash: {},
  capacityMembershipByAccountHash: {}
};

const handleFetchCapacityPoliciesSuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<typeof fetchCapacityPoliciesActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.capacityGroupsHash = keyBy(response.capacity_groups, byCapacityGroupId);
  state.capacityGroupsIdsOrder =
    response.capacity_groups.map(byCapacityGroupId);
};

const handleCreateCapacityPolicySuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<typeof createCapacityPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.capacityGroupsHash[response.id] = response;
  state.capacityGroupsIdsOrder.push(response.id);
};

const handleUpdateCapacityPolicySuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<typeof updateCapacityPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.capacityGroupsHash[response.id] = response;
};

const handleArchiveCapacityPolicySuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<typeof archiveCapacityPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.capacityGroupsHash[response.id] = response;
};

const handleFetchCapacityPoliciesPerMemberSuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<
    typeof fetchCapacityPoliciesPerMemberActionCreatorsMap.success
  >
) => {
  const { response } = action.payload;

  response.forEach((item) => {
    state.capacitiesByAccountHash[item.account_id] = item.capacity_group;
  });
};

const handleFetchCapacityGroupMembershipsSuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<
    typeof fetchCapacityGroupMembershipsActionCreatorsMap.success
  >
) => {
  const { teamMembershipId } = action.payload.initialPayload;
  const { response } = action.payload;

  state.capacityMembershipByAccountHash[teamMembershipId] = [];

  const membershipHashForAccount =
    state.capacityMembershipByAccountHash[teamMembershipId];

  if (membershipHashForAccount) {
    membershipHashForAccount.push(...response);
  }
};

const handleCreateCapacityGroupMembershipSuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<
    typeof createCapacityGroupMembershipActionCreatorsMap.success
  >
) => {
  const { response } = action.payload;
  state.capacityGroupsHash[response.id] = response;
};

const handleUpdateCapacityGroupMembershipSuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<
    typeof updateCapacityGroupMembershipActionCreatorsMap.success
  >
) => {
  const { response } = action.payload;

  state.capacityMembershipByAccountHash[response.team_membership.id] = (
    state.capacityMembershipByAccountHash[response.team_membership.id] ?? []
  ).map((item) => (item.id === response.id ? response : item));
};

const handleDeleteCapacityPolicySuccess = (
  state: Draft<CapacityGroupsState>,
  action: ReturnType<
    typeof deleteCapacityGroupMembershipActionCreatorsMap.success
  >
) => {
  const { id } = action.payload.initialPayload;
  const { teamMembershipId } = action.meta || {};

  if (teamMembershipId) {
    state.capacityMembershipByAccountHash[teamMembershipId] = (
      state.capacityMembershipByAccountHash[teamMembershipId] ?? []
    ).filter((item) => item.id === id);
  }
};

export const capacityGroupsReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    fetchCapacityPoliciesActionCreatorsMap.success,
    handleFetchCapacityPoliciesSuccess
  );
  builder.addCase(
    createCapacityPolicyActionCreatorsMap.success,
    handleCreateCapacityPolicySuccess
  );
  builder.addCase(
    updateCapacityPolicyActionCreatorsMap.success,
    handleUpdateCapacityPolicySuccess
  );
  builder.addCase(
    archiveCapacityPolicyActionCreatorsMap.success,
    handleArchiveCapacityPolicySuccess
  );
  builder.addCase(
    fetchCapacityPoliciesPerMemberActionCreatorsMap.success,
    handleFetchCapacityPoliciesPerMemberSuccess
  );
  builder.addCase(
    fetchCapacityGroupMembershipsActionCreatorsMap.success,
    handleFetchCapacityGroupMembershipsSuccess
  );
  builder.addCase(
    createCapacityGroupMembershipActionCreatorsMap.success,
    handleCreateCapacityGroupMembershipSuccess
  );
  builder.addCase(
    updateCapacityGroupMembershipActionCreatorsMap.success,
    handleUpdateCapacityGroupMembershipSuccess
  );
  builder.addCase(
    deleteCapacityGroupMembershipActionCreatorsMap.success,
    handleDeleteCapacityPolicySuccess
  );
});
