import styled from 'styled-components';
import { StyledViewMore } from 'DashboardModule/components/Widgets/ViewMoreButton';

export const Row = styled.div`
  display: flex;
`;

export const StyledMiniUtilizationTable = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft || 0};

  ${({ centerContent }) =>
    centerContent &&
    `
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      top: -18px;
  `}

  ${StyledViewMore} {
    margin-left: 46px;
  }
`;
