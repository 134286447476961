import React, { useRef } from 'react';
import CellContainer from './CellContainer';
import EstimatedHours from '../EstimatedHours';
import { noop } from 'appUtils/noop';

const HoursCell = ({ row }) => {
  const target = useRef(null);

  const {
    handlePropertySelect,
    handleTaskEditClick = noop,
    estimated_hours,
    taskProps = {}
  } = row.original;
  const { isNewTask } = taskProps;

  return (
    <CellContainer
      taskProps={taskProps}
      target={target}
      taskProperty="estimated_hours"
      handleClick={() => row.setState({ editingProperty: 'estimated_hours' })}
      row={row}
    >
      <EstimatedHours
        isNewTask={isNewTask}
        hours={estimated_hours}
        isEditing={row.state.editingProperty === 'estimated_hours'}
        onSubmit={(value) => {
          handlePropertySelect('estimated_hours')(value);
          if (!row.original.createRow) {
            row.setState({ editingProperty: null });
          }
        }}
        handleBlur={() => row.setState({ editingProperty: null })}
        handleTaskEditClick={handleTaskEditClick}
        taskIsEditing={row.state.editingProperty === 'estimated_hours'}
      />
    </CellContainer>
  );
};
export default HoursCell;
