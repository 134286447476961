import React, { Component } from 'react';
import { PlannerColumn } from '..';
import { connect } from 'react-redux';
import { getMyUnscheduledTaskInfo, getCurrentUserId } from 'selectors';
import { searchItemsWithWhiteSpace } from 'appUtils/search';

class UnscheduledSlideoutMenu extends Component {
  state = {
    searchText: ''
  };

  updateSearchText = (event) => {
    this.setState({ searchText: event.target.value });
  };

  clearSearchText = () => {
    this.setState({ searchText: '' });
  };

  filterByProjectAndDescription = () => {
    const { unscheduledTaskInfo } = this.props;
    const { searchText } = this.state;

    const items = searchItemsWithWhiteSpace({
      searchText,
      itemList: unscheduledTaskInfo,
      filterKeysArray: ['projectTitle', 'description']
    });

    return items.slice(0, 100).map((item) => item.id);
  };

  render() {
    const { isOpen, onClose } = this.props;

    return isOpen ? (
      <PlannerColumn
        {...this.props}
        date="unscheduled"
        unscheduled
        searchText={this.state.searchText}
        updateSearchText={this.updateSearchText}
        clearSearchText={this.clearSearchText}
        filterTasks={this.filterByProjectAndDescription}
      />
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  unscheduledTaskInfo: getMyUnscheduledTaskInfo(state, ownProps)
});

export default connect(mapStateToProps)(UnscheduledSlideoutMenu);
