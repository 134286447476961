import styled from 'styled-components';

export const ProjectGuestFooterLabel = () => (
  <TextContainer>Guests do not get access to timesheets</TextContainer>
);

const TextContainer = styled.div`
  margin-left: 32px;
  margin-top: 7px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;
