import get from 'lodash/get';

export const searchItemsWithWhiteSpace = ({
  searchText,
  itemList,
  filterKeysArray
}) => {
  if (!searchText) {
    return itemList;
  }

  const searchWords = searchText.split(' ').filter((str) => str !== '-');
  const searchResults = itemList.filter((item) =>
    searchWords.every((word) =>
      filterKeysArray.some((key) =>
        item[key]
          ? item[key].toLowerCase().includes(word.toLowerCase().trim())
          : false
      )
    )
  );

  return searchResults;
};

export const filterItemWithWhiteSpace = ({
  searchWords,
  item,
  filterKeysArray
}) => {
  if (!item) {
    return false;
  }
  if (!searchWords.length) {
    return item;
  }
  return searchWords.every((word) =>
    filterKeysArray.some((key) =>
      get(item, key)
        ? String(get(item, key))
            .toLowerCase()
            .includes(word.toLowerCase().trim())
        : false
    )
  );
};

export const filterItemWithKeywords = ({ searchWords, item, filterKey }) => {
  if (!item) {
    return false;
  }
  if (!searchWords.length) {
    return item;
  }
  return searchWords.every((word) =>
    item[filterKey].some((keyword) =>
      String(keyword).toLowerCase().includes(word.toLowerCase().trim())
    )
  );
};
