import React from 'react';
import { connect } from 'react-redux';
import {
  updateTimesheet,
  createTimesheet,
  deleteTimesheets,
  openTimesheetActivityModalForDay,
  toggleFlickerForActivityCell,
  toggleFlickerForTaskCell
} from 'actionCreators';
import {
  getSelectedTeamId,
  getUserIsAdmin,
  getPhasesByProjectHash,
  makeGetDescription,
  getProjectActivityScores,
  getMyUserId,
  makeGetProjectById,
  makeGetActivityByActivityId,
  getFlatPhasesHash,
  getMyTeamMembership,
  getDateRangeProjectActivities,
  getUserIsFinancialManager
} from 'selectors';
import {
  StyledDropdownItem,
  TimesheetStyledDayCell,
  SubmitStatus,
  StyledSubmitCheck,
  HideOnHover,
  ShowOnHover
} from 'views/timesheet/Timesheet/styles';

import { TIMESHEET_STATUSES } from 'appConstants/timesheets';
import { BUDGET_STATUSES } from 'appConstants/budgetStatuses';

import { formatFetchDate, formatActivitiesDate } from '../index';
import { ActivityIconContainer, StyledActivityTimesheetIcon } from './styles';

import TimesheetDay from './index';
import DeleteXIcon from 'icons/DeleteXIcon';
import { isPhaseArchived } from 'appUtils/phaseDisplayUtils';

const { SUBMITTED, NOT_SUBMITTED, APPROVED, REJECTED } = TIMESHEET_STATUSES;

class WeeklyTimesheetDay extends React.Component {
  getPermissions = () => {
    const { projectId, selectedTeamId, accountId, myId } = this.props;
    return {
      projectId,
      teamId: selectedTeamId,
      mine: accountId === myId
    };
  };

  handleTimeSubmission = () => {
    // only used to submit unsubmitted time
    const { info, updateTimesheet, day, descriptionId } = this.props;
    const permissions = this.getPermissions();
    const { status, hours } = info;

    if (status === APPROVED || status === SUBMITTED) {
      return;
    }

    updateTimesheet({
      day,
      timesheetId: info.id,
      descriptionId,
      hours,
      permissions,
      status: SUBMITTED
    });
  };

  deleteTimeEntry = this.props.deleteTimesheets;
  createTimeEntry = this.props.createTimesheet;
  updateTimeEntry = this.props.updateTimesheet;

  onClickFocus = () => {
    const {
      toggleFlickerForActivityCell,
      descriptionId,
      toggleFlickerForTaskCell,
      activitySelected,
      descriptionMissing,
      info
    } = this.props;
    if (info?.status === SUBMITTED) {
      this.setState({ dropdownOpen: true });
    }
    if (!activitySelected) {
      toggleFlickerForActivityCell({ descriptionId });
      setTimeout(() => toggleFlickerForActivityCell({ descriptionId }), 2000);
      return;
    }
    if (descriptionMissing) {
      toggleFlickerForTaskCell({ descriptionId });
      setTimeout(() => toggleFlickerForTaskCell({ descriptionId }), 2000);
    }
  };

  openActivityModalforDay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      openTimesheetActivityModalForDay,
      day,
      accountId,
      projectId,
      phaseId,
      projectActivityScores
    } = this.props;
    openTimesheetActivityModalForDay({
      day,
      accountId,
      projectId,
      phaseId
    });
  };

  renderActivityIcon = () => {
    const { info, dateRangeProjectActivities, projectId, day } = this.props;
    const activitiesDate = formatActivitiesDate(day);
    const hasActivities =
      dateRangeProjectActivities && dateRangeProjectActivities[projectId]
        ? dateRangeProjectActivities[projectId][activitiesDate]
        : false;
    const shouldRender = hasActivities;

    return (
      shouldRender && (
        <ActivityIconContainer
          onClick={this.openActivityModalforDay}
          show={shouldRender}
          data-for="app-tooltip"
          data-effect="solid"
          data-tip="Click to view your project <br/> activity log."
          data-html
        >
          <StyledActivityTimesheetIcon height="14" width="10" />
        </ActivityIconContainer>
      )
    );
  };

  getIsDisabled = () => {
    const {
      isCurrentUser,
      isAdmin,
      info,
      activitySelected,
      descriptionMissing,
      project,
      phases,
      phaseId,
      isTimesheetsDisabled,
      isFinancialManager
    } = this.props;

    const phase = phases[phaseId];
    const isCompletedPhase = phase?.budget_status === BUDGET_STATUSES.COMPLETE;
    const canEditCompletedPhase = isAdmin || isFinancialManager;

    const isDisabled =
      isTimesheetsDisabled ||
      project?.is_archived ||
      isPhaseArchived(phases[phaseId]) ||
      info.status === SUBMITTED ||
      info.status === APPROVED ||
      !activitySelected ||
      descriptionMissing ||
      (!isCurrentUser && !isAdmin) ||
      (isCompletedPhase && !canEditCompletedPhase) ||
      info.id === 'new'; // Timesheet is still creating, prevent writes.
    return isDisabled;
  };

  statusToolTip = {
    [APPROVED]:
      'This time has been approved.</br>Contact your Admin to edit</br>approved time.',
    [SUBMITTED]: 'This time has been</br>submitted',
    [REJECTED]: 'This time has been rejected.',
    [NOT_SUBMITTED]: 'This time has not been</br>submitted'
  };

  renderStatus = () => {
    const { info } = this.props;

    const renderSubmitIcon = () => {
      if (info.status === 'rejected') {
        return <DeleteXIcon />;
      } else if (info.status) {
        return info.status !== 'not_submitted' ? (
          <>
            <HideOnHover>
              <StyledSubmitCheck status={info.status} />
            </HideOnHover>
            <ShowOnHover>
              <DeleteXIcon />
            </ShowOnHover>
          </>
        ) : null;
      } else if (info.id && info.id !== 'new') {
        return <StyledSubmitCheck id={info.id} />;
      } else {
        return null;
      }
    };

    return (
      <SubmitStatus
        onClick={this.handleTimeSubmission}
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={this.statusToolTip[info.status] || info.status || 'Error'}
        data-html
      >
        {renderSubmitIcon()}
      </SubmitStatus>
    );
  };

  renderDropdown = () => {
    return (
      <StyledDropdownItem
        onClick={this.unsubmit}
        padding={'10px 15px 10px 10px'}
      >
        <DeleteXIcon width={20} height={12} />{' '}
        <span style={{ marginLeft: '4px' }}>Unsubmit</span>
      </StyledDropdownItem>
    );
  };

  unsubmit = () => {
    const { info, updateTimesheet, day, descriptionId } = this.props;
    const permissions = this.getPermissions();
    const { hours } = info;

    updateTimesheet({
      day,
      timesheetId: info.id,
      descriptionId,
      hours,
      permissions,
      status: NOT_SUBMITTED
    });
  };

  render() {
    const {
      info,
      activitySelected,
      descriptionMissing,
      description,
      project,
      activity,
      phases,
      phaseId,
      accountId,
      day,
      isAdmin,
      isFinancialManager
    } = this.props;
    const phase = phases[phaseId];
    const isCompletedPhase = phase?.budget_status === BUDGET_STATUSES.COMPLETE;
    const canEditCompletedPhase = isAdmin || isFinancialManager;

    const containerDataTip = project?.is_archived
      ? 'Project is Archived'
      : isPhaseArchived(phase)
      ? 'Phase is Archived'
      : !activitySelected
      ? 'Enter Work Category First'
      : descriptionMissing
      ? 'Description Required'
      : isCompletedPhase
      ? canEditCompletedPhase
        ? ''
        : 'Only Financial Managers/Admins are allowed to modify time on completed phases'
      : '';
    const isDisabled = this.getIsDisabled();
    return (
      <>
        <TimesheetDay
          ContainerEl={TimesheetStyledDayCell}
          isDisabled={isDisabled}
          onClickFocus={this.onClickFocus}
          timeEntry={info}
          handleDeleteTimeEntry={this.deleteTimeEntry}
          handleCreateTimeEntry={this.createTimeEntry}
          handleUpdateTimeEntry={this.updateTimeEntry}
          day={day}
          filterStateId={'timesheet'}
          project={project}
          projectId={project?.id}
          phase={phases[phaseId]}
          activity={activity}
          description={description}
          accountId={accountId}
          containerDataTip={containerDataTip}
          renderStatus={this.renderStatus}
          renderActivityIcon={this.renderActivityIcon}
          shouldTriggerDropdown={info?.status === SUBMITTED}
          dropdownContents={this.renderDropdown}
          key={info?.id}
          timesheetColumnIndex={this.props.timesheetColumnIndex}
        />
      </>
    );
  }
}
const descriptionIdGetter = (state, ownProps) => ownProps.descriptionId;
const projectIdGetter = (state, ownProps) => ownProps.projectId;
const activityIdGetter = (state, ownProps) => ownProps.activity_id;
const makeMapStateToProps = () => {
  const getDescription = makeGetDescription({ descriptionIdGetter });
  const getProjectById = makeGetProjectById({ projectIdGetter });
  const getActivityById = makeGetActivityByActivityId({ activityIdGetter });

  const mapStateToProps = (state, ownProps) => ({
    selectedTeamId: getSelectedTeamId(state),
    isAdmin: getUserIsAdmin(state),
    isFinancialManager: getUserIsFinancialManager(state),
    isCurrentUser: true, // todo fix selector memoization
    phasesByProject: getPhasesByProjectHash(state),
    phases: getFlatPhasesHash(state),
    description: getDescription(state, ownProps),
    project: getProjectById(state, ownProps),
    activity: getActivityById(state, ownProps),
    projectActivityScores: getProjectActivityScores(state),
    myId: getMyUserId(state),
    teamMembership: getMyTeamMembership(state),
    dateRangeProjectActivities: getDateRangeProjectActivities(state)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  updateTimesheet,
  createTimesheet,
  deleteTimesheets,
  openTimesheetActivityModalForDay,
  toggleFlickerForActivityCell,
  toggleFlickerForTaskCell
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WeeklyTimesheetDay);
