import React, { useMemo } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { PieChart, Pie, Cell } from 'recharts';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const INNER_RADIUS = 17;

const HoursLabel = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WarningIcon = styled.span`
  color: ${theme.colors.colorCalendarRed};
  font-weight: 800;
  font-size: 12px;
  position: absolute;
  top: calc(50% + 10px);
  transform: translateY(-50%);
`;

export const StyledHours = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: black;
  line-height: 1.3;
`;

const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  width: ${`${INNER_RADIUS * 2}px`};
  height: ${`${INNER_RADIUS * 2}px`};
  border-radius: 50%;
  ${(props) => {
    if (props.isHoliday) {
      return `background: repeating-linear-gradient(
      135deg,
      #ffffff,
      #ffffff 2px,
      ${theme.colors.colorLightGray6},
      ${theme.colors.colorLightGray6} 4px
    )`;
    } else if (props.hasPto) {
      return `background: repeating-linear-gradient(
      135deg,
      #ffffff,
      #ffffff 2px,
      rgba(137,184,231,0.6),
      rgba(137,184,231,0.6) 4px
      )`;
    }
  }}
`;

export const OVER_CAPACITY_CHART_TOOLTIP_PROPS = {
  'data-for': 'app-tooltip',
  'data-tip': 'Hours Worked <br/> Exceeds Capacity',
  'data-html': true,
  'data-class': 'center',
  'data-effect': 'solid'
};

const defaultPieChartProps = { width: 45, height: 45 };
const defaultPieProps = { innerRadius: 17.5, outerRadius: 21.5 };

const StatusPieChart = ({
  data,
  pieChartProps = defaultPieChartProps,
  pieProps = defaultPieProps,
  noTooltip
}) => {
  const isOverCapacity = +data.totalTimesheetHours > +data.capacity;
  const hasData =
    data.capacity > 0 ||
    +data.totalTimesheetHours > 0 ||
    data.isHoliday ||
    data.isPto ||
    data.forceShow;

  const totalTimesheetHours = formatNumWithMaxTwoDecimals(
    data.totalTimesheetHours
  );
  const value = data.isSummary
    ? totalTimesheetHours
    : data.isHoliday
    ? 'OFF'
    : data.isPto
    ? 'PTO'
    : totalTimesheetHours;

  // const isWarningOnTop = ('' + value).length > 3;

  const formattedDataItems = useMemo(
    () =>
      data.items.map((item) =>
        item.displayValue
          ? {
              ...item,
              displayValue: formatNumWithMaxTwoDecimals(item.displayValue)
            }
          : {
              ...item,
              value: formatNumWithMaxTwoDecimals(item.value)
            }
      ),
    [data.items]
  );

  const formattedDataSummaryItems = useMemo(
    () => [
      {
        name: 'capacity',
        label: 'Capacity',
        value: formatNumWithMaxTwoDecimals(data.capacity),
        color: 'tranparent'
      },
      ...(isOverCapacity
        ? [
            {
              name: 'overcapacity',
              label: 'Overcapacity',
              value: formatNumWithMaxTwoDecimals(
                Math.abs(+(data.capacity - data.totalTimesheetHours))
              ),
              color: 'transparent'
            }
          ]
        : [])
    ],
    [data.capacity, data.totalTimesheetHours, isOverCapacity]
  );

  return (
    <ChartContainer
      data-effect="solid"
      data-for="legend-style-tooltip"
      data-place="bottom"
      data-tip-disable={noTooltip || !hasData}
      data-tip={JSON.stringify({
        data: formattedDataItems,
        summary: formattedDataSummaryItems
      })}
    >
      <Background isHoliday={data.isHoliday} hasPto={data.hasPto} />
      <PieChart {...pieChartProps}>
        <Pie
          data={data.items}
          cx="50%"
          cy="50%"
          fill="#8884d8"
          dataKey="value"
          isAnimationActive={false}
          startAngle={90}
          endAngle={450}
          blendStroke
          {...pieProps}
        >
          {data.items.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      {hasData && (
        <HoursLabel maxWidth={pieProps.innerRadius * 2}>
          <StyledHours>{value}</StyledHours>
          {isOverCapacity && <WarningIcon>&gt;</WarningIcon>}
        </HoursLabel>
      )}
    </ChartContainer>
  );
};

export default React.memo(StatusPieChart);
