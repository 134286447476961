import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFlatPhasesHash, getProjectHash } from 'selectors';

import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { serializeId } from 'appUtils';
import { formatTotals } from 'ReportsModule/components/Budget/BudgetReportTable/utils';
import GraphCell from './Cells/GraphCell';
import PhaseCell from './Cells/PhaseCell';
import theme from 'theme';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const emptyObj = {};
const emptyArray = [];
const EmptyDiv = () => <div></div>;

const phaseColumn = {
  headerType: 'phase',
  headerLabel: '',
  id: 'phase',
  align: 'center'
};
const graphColumn = {
  headerType: 'graph',
  headerLabel: '',
  id: 'graph',
  align: 'center'
};
const tableColumns = [phaseColumn, graphColumn];

const phaseRowCells = {
  graph: GraphCell,
  phase: PhaseCell
};
const columnWidths = {
  graph: 165,
  phase: 222
};

const BudgetWidgetTable = ({
  // props
  phaseOrder = emptyArray,
  data,
  isFetching
}) => {
  const phaseHash = useSelector(getFlatPhasesHash);
  const projectHash = useSelector(getProjectHash);
  const { isHoursOnly } = useIsHoursOnly();

  const columns = useMemo(() => {
    return tableColumns.map((columnHeader) => ({
      ...columnHeader,
      phaseRow: phaseRowCells[columnHeader.headerType] || EmptyDiv,
      width: columnWidths[columnHeader.headerType],
      Cell: <div></div>
    }));
  }, []);

  const totalWidth = useMemo(() => {
    return columns.reduce((acc, cur) => {
      acc += cur.width;
      return acc;
    }, 0);
  }, [columns]);

  const customRowProps = useMemo(
    () => ({
      isHoursOnly
    }),
    [isHoursOnly]
  );

  const rows = useMemo(() => {
    const phasesList = [];

    phaseOrder.forEach((phaseId) => {
      const phase = phaseHash[phaseId] || emptyObj;
      const project = projectHash[phase.project_id] || emptyObj;
      const uid = serializeId({
        ids: [phaseId],
        itemType: 'Phase'
      });
      const phaseData = data[uid] || emptyObj;

      const formattedPhaseData = formatTotals({
        totals: phaseData,
        fee: +phase.total,
        estimatedCost: phaseData.phase_estimate,
        phaseTarget: phaseData.phase_estimate
      });

      phasesList.push({
        data: formattedPhaseData,
        phase,
        project,
        rowType: 'phaseRow',
        rowHeight: 40,
        customRowProps
      });
    });
    return phasesList.sort((a, b) =>
      // sort by project name first and then phase name
      a.project?.title + a.phase?.name > b.project?.title + b.phase?.name
        ? 1
        : -1
    );
  }, [phaseOrder, phaseHash, projectHash, data, customRowProps]);

  return (
    <StyledBudgetWidgetTable className="scrollbar2" width={totalWidth}>
      {isFetching ? (
        <SkeletonLoader
          numLoaders={4}
          style={{ paddingLeft: 14, marginTop: 10, height: 200 }}
          loaderStyle={{ height: 70, rx: 4 }}
        />
      ) : (
        rows.map((row, index) => {
          return (
            <Row key={index}>
              {columns.map((column, index) => {
                const Cell = column[row.rowType];
                return (
                  <div
                    style={{ width: column.width, height: row.rowHeight }}
                    key={index}
                  >
                    <Cell
                      row={row}
                      column={column}
                      style={{ width: column.width }}
                    />
                  </div>
                );
              })}
            </Row>
          );
        })
      )}
    </StyledBudgetWidgetTable>
  );
};

export default BudgetWidgetTable;

const Row = styled.div`
  display: flex;
`;

const StyledBudgetWidgetTable = styled.div`
  margin-top: 22px;
  width: ${(props) => props.width}px;
  max-height: 190px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;
