import styled from 'styled-components';
import cn from 'classnames';
import theme from 'theme';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';
import { ActivityPhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import ActivityPhaseProgress from 'BudgetModule/components/BudgetTable/ActivityPhaseProgress';
import EllipsisText from 'components/EllipsisText';
import { AvailabilityButton } from 'SuggestionModule/components/AvailabilityButton';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const ActivityPhaseTitleCell = ({ row }: ActivityPhaseGroupRowProps) => {
  const {
    toggleCollapse,
    allSuggestedMembers,
    isOpen,
    activityPhase,
    activity,
    isAvailabilityViewActive,
    onToggleViewAvailability
  } = row.original;
  const { title: activityTitle } = activity;

  const { teamBuilderSplitScreenFlag } = useFeatureFlags();

  const numOfSuggestions = allSuggestedMembers?.length || 0;
  const numOfSuggestionsText = `${numOfSuggestions} Suggestion${
    numOfSuggestions > 1 ? 's' : ''
  }`;

  return (
    <StyledContainer>
      <ColumnContainer>
        <StyledNameContainer onClick={toggleCollapse}>
          <SmallDiamondIcon className={'diamond-icon'} />{' '}
          <ActivityPhaseNameContainer>
            <EllipsisText
              maxWidth={190}
              className="name"
              tooltip={activityTitle}
            >
              {activityTitle}
            </EllipsisText>
            {numOfSuggestions && !isOpen ? (
              <EllipsisText
                maxWidth={190}
                className="suggestionsNum"
                tooltip={numOfSuggestionsText}
              >
                {numOfSuggestionsText}
              </EllipsisText>
            ) : (
              <></>
            )}
          </ActivityPhaseNameContainer>
        </StyledNameContainer>
        {teamBuilderSplitScreenFlag && isOpen && numOfSuggestions > 0 && (
          <StyledAvailabilityButton
            active={isAvailabilityViewActive}
            onClick={onToggleViewAvailability}
          />
        )}
      </ColumnContainer>
      <StyledDateSectionContainer>
        <StyledDateContainer>
          <ActivityPhaseProgress
            activityPhase={activityPhase}
            showProgressPercent={undefined}
            isDisabled={undefined}
            disabledTooltip={undefined}
          />
        </StyledDateContainer>
      </StyledDateSectionContainer>
    </StyledContainer>
  );
};

export default ActivityPhaseTitleCell;

const StyledNameContainer = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 27px;
  display: flex;
  align-items: baseline;
  cursor: pointer;

  .diamond-icon {
    position: relative;
    bottom: 2px;
  }

  &:hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;

const StyledAvailabilityButton = styled(AvailabilityButton).attrs(
  ({ active }) => ({
    className: cn({ active })
  })
)`
  margin-left: 38px;
  &.active {
    margin-left: 25px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin-left: 4px;
`;

const StyledDateContainer = styled.div`
  color: ${theme.colors.colorCalendarBlue};

  .date-progress {
    background: none !important;
  }

  .start-date,
  .end-date,
  .app-cues-milestone-progress-bar {
    color: ${theme.colors.colorCalendarBlue};
    font-weight: 600;
  }

  .date-dash {
    position: relative;
    top: -1px;
    color: ${theme.colors.colorCalendarBlue};
  }

  .app-cues-milestone-progress-bar {
    padding-left: 0;
  }

  .budget-phase-progress-dates-container {
    border-radius: 3px;
    &:hover {
      background: ${theme.colors.colorLightGray17};
    }
  }

  .budget-phase-progress-dates {
    background: none !important;
    border: none;
  }

  .add-button {
    width: 123px;
    font-weight: 600;
  }

  &:hover {
    .add-button {
      border-radius: 3px;
      background: ${theme.colors.colorLightGray17};
    }
  }
`;

const ActivityPhaseNameContainer = styled.div`
  padding-left: 5px;

  .name {
    height: 26px;
  }

  .suggestionsNum {
    font-size: 12px;
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const StyledDateSectionContainer = styled.div`
  text-align: center;
  padding-top: 2px;
  padding-left: 22px;
  padding-right: 22px;
`;
