import {
  useFilterSchema,
  UseFilterSchemaParams
} from 'FilterModule/hooks/useFilterSchema';
import { useCurrentFilter } from './useCurrentFilter';
import {
  CurrentFilter,
  ParsedFilterSchemaTypeWithCurrentFilterType
} from 'FilterModule/types';
import { useToggle } from 'react-use';
import { StateAccountFilter } from 'models/filter';

export interface UseFilterParams<CurrentFilterType extends CurrentFilter> {
  filterId?: number;
  filterSchemaParams: UseFilterSchemaParams;
  matchingFiltersOverride?: StateAccountFilter[];
  matchingFakeFiltersOverride?: Partial<StateAccountFilter>[];
  overrideUpdate?: CurrentFilterType['update'];
  onSave?: CurrentFilterType['save'];
}

// filterInstanceSchema is untested and not used yet
export const useFilter = <CurrentFilterType extends CurrentFilter>({
  filterId,
  filterSchemaParams,
  matchingFiltersOverride,
  matchingFakeFiltersOverride,
  overrideUpdate,
  onSave
}: UseFilterParams<CurrentFilterType>) => {
  const [isSideFilterOpen, toggleIsSideFilterOpen] = useToggle(false);

  const {
    pageFilterSchema,
    filterInstanceSchema,
    currentFilterSchema,
    registerFilterSchema
  } = useFilterSchema(filterSchemaParams);

  const {
    currentFilter,
    draftFilter,
    mainFilterListType,
    mainFilterListField,
    mainFilterListFieldsToValue,
    mainFilterListNumSelected
  } = useCurrentFilter<CurrentFilterType, typeof currentFilterSchema>({
    filterId,
    currentFilterSchema,
    shouldUseFilterLevels: !filterSchemaParams.filterInstanceSchema,
    matchingFiltersOverride,
    matchingFakeFiltersOverride,
    overrideUpdate,
    onSave
  });

  return {
    registerFilterSchema,
    currentFilterSchema:
      currentFilterSchema as ParsedFilterSchemaTypeWithCurrentFilterType<
        typeof currentFilterSchema,
        CurrentFilterType
      >,
    pageFilterSchema,
    filterInstanceSchema,
    currentFilter,
    draftFilter,
    isSideFilterOpen,
    toggleIsSideFilterOpen,
    mainFilterListType,
    mainFilterListField,
    mainFilterListFieldsToValue,
    mainFilterListNumSelected
  };
};
