import moment from 'moment';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import * as constants from 'IntegrationsModule/constants';

export const makeDateFilter = (start, end) => [
  {
    filterAttribute: 'targetCreatedAt',
    filterValue: start,
    filterType: 'ge'
  },
  {
    filterAttribute: 'targetCreatedAt',
    // Add 1 day due to time being parsed as 00:00:00
    filterValue: moment(end).add(1, 'd').format(constants.DATE_FILTER_FORMAT),
    filterType: 'le'
  }
];

export const makeQbHappy = (str) =>
  str
    ?.toUpperCase()
    .replace('QUICKBOOKS ONLINE', 'QuickBooks Online')
    .replace('QUICKBOOKS', 'QuickBooks') || '';

/**
 * This util function should only be used for determining whether to trigger `HardDeleteWarningModal`, and what message to display
 * See `PermissionLevelCellContent` for its used with `HardDeleteWarningModal`
 * Returns an object with 3 keys
 * 1) showHardDeleteWarningModal - a boolean to see whether to trigger hard delete warning modal or not.
 * 2) showGenericWarningMessage - a boolean to show generic error message and not a list of integrations (if exist)
 * 3) integrationsContainingMappedDataType - a list of integration that this entity of data type has active mapping status on
 * and will be displayed as bullet points inside HardDeleteWarningModal
 * @param {Record<any,any>} mosaicMappingStatuses
 * @param {Record<number,any>} integrationsV2
 * @param {boolean} isV2Loading
 * @param {boolean} isV2Error
 * @param {string} dataType
 * @param {number} idToCheck
 * @returns {Record<string, boolean | Array<string>>}
 */
export const getHardDeleteWarningModalProps = (
  mosaicMappingStatuses,
  integrationsV2,
  isV2Loading,
  isV2Error,
  dataType,
  idToCheck
) => {
  /**
   * Case 1: If isV2Loading = true, meaning team integrations are not fetched yet -> Display generic error
   * Case 2: If isV2Loading = false and isV2Error = true, meaning fetching integrations failed -> Display generic error
   */
  if (isV2Loading || (!isV2Loading && isV2Error))
    return {
      showHardDeleteWarningModal: true,
      showGenericWarningMessage: true,
      integrationsContainingMappedDataType: []
    };
  const targetServiceIds = Object.keys(integrationsV2);

  /** Case 3: team integrations are already done fetching, but if there is no target service ids,
   * then this team has no integration -> No need to display warning modal
   *
   */
  if (targetServiceIds.length === 0) {
    return {
      showHardDeleteWarningModal: false,
      showGenericWarningMessage: false,
      integrationsContainingMappedDataType: []
    };
  }

  /** Default values. If remains unaltered after .reduce(), it's certain that hard deletion is allowed */
  let showHardDeleteWarningModal = false;
  let isMosaicMappingStatusesFetching = false;

  /** This snippet checks all keys of a team integration in `mosaicMappingStatuses`
   * At this point, team integrations (integrationsV2) should be successfully fetched
   */
  const integrationsContainingMappedDataType = targetServiceIds.reduce(
    (acc, targetServiceId) => {
      const targetService = integrationsV2[targetServiceId].targetService;

      const integrationRecord =
        mosaicMappingStatuses?.[targetService]?.[targetServiceId]?.[dataType];
      /** Case 4: `integrationRecord` is a Record<number,boolean> that represents the mapping status of existing entities
       *  We need this record to be defined in order to determine whether to allow for hard deletion or not.
       *  All it takes is one undefined record to signal to us that `mosaicMappingStatuses` is still fetching
       * -> Display generic error message
       */
      if (!integrationRecord) {
        isMosaicMappingStatusesFetching = true;
        return acc;
      } else if (
        integrationRecord &&
        constants.ACTIVE_MAPPING_STATUSES.includes(integrationRecord[idToCheck])
      ) {
        /** Case 5:
         * This is when `integrationRecord` is fetched and this entity's mapping status is active.
         * We want to trigger the warning modal and add it this target service
         * to the list of integration to be displayed inside the modal
         */
        acc.add(constants.integrationsHash[targetService].label);
        showHardDeleteWarningModal = true;
      }

      return acc;
    },
    new Set()
  );

  /** Case 4 outcome */
  if (isMosaicMappingStatusesFetching) {
    return {
      showHardDeleteWarningModal: true,
      showGenericWarningMessage: true,
      integrationsContainingMappedDataType: []
    };
  }
  /** Case 5 outcome:
   * At this point, all necessary fetches are done
   * There are 2 scenarios that may happen
   * 1) There is at least one integration that this entity contains active mapping status in
   * -> Show warning modal + display list of integration that the user must unlink this entity from
   *
   * 2) There is 0 integration that this entity contains active mapping status in
   * -> Don't show warning modal and/or perform hard delete action (i.e: Trigger hard delete modal)
   *
   */
  return {
    showHardDeleteWarningModal,
    showGenericWarningMessage: false,
    integrationsContainingMappedDataType: Array.from(
      integrationsContainingMappedDataType
    )
  };
};

// Remove fields that are null or undefined
export const makeMosaicData = (obj) => omitBy(obj, isNil);

export const getFetchHeaders = ({ token }) => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
};

/**
 * Find the default integration board for a team
 *
 * is_integration flag is used for determining the
 * default integration board of the team, which will be used
 * in the payload for provisioning an integration
 *
 * It is expected that board that has is_integration = true,
 * is Not deletable and not changed
 */
export const findDefaultIntegrationBoard = (boards = []) => {
  return boards.find((board) => {
    return board.is_integration;
  });
};
