import { TeamMember } from 'TeamsModule/models/teamMember';

/**
 * @deprecated use permissionsUtils.getisArchived instead
 */
const getIsArchived = (member: TeamMember) => member.is_archived;

export const teamUtils = {
  getIsArchived
};
