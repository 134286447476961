import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import styled from 'styled-components';
import {
  updateTaskPosition,
  triggerTaskCompletionToggle
} from 'actionCreators';
import { getAuth } from 'selectors';
import { TaskContentRight } from 'views/Home/Tasks/styles';
import { PickerWrapper } from 'components/DatePicker/styles/components';
import { BulkUpdateModalDateSelector } from 'views';
import { BulkUpdateModalDateRangeSelector } from 'views';

const PastScheduledTasksContainer = styled.div`
  ${PickerWrapper} {
    > div {
      top: -26px !important;
    }
  }
`;

const moment = extendMoment(Moment);

class BulkUpdateModalTaskList extends React.Component {
  markComplete = (task) => {
    const {
      auth,
      updateTaskPosition,
      homeTasksView,
      handleTaskCompletionToggle
    } = this.props;

    const taskId = task.id;
    const isCompleted = task.is_complete || !!task.completed_at;
    const taskType = 'projects';
    const laneId = moment(task.schedule_start).format('YYYY-MM-DD');
    const dateKey = laneId === null ? 'unscheduled' : laneId;

    updateTaskPosition({
      token: auth.token,
      taskId,
      projectId: task.project_id,
      taskType,
      isCompleted: !isCompleted,
      wasCompleted: isCompleted,
      laneId,
      dateKey
    });

    if (homeTasksView) {
      handleTaskCompletionToggle({
        taskId,
        isCompleting: !isCompleted
      });
    }
  };

  render() {
    const { pastScheduledTasks, bulkUpdateDisplayDate, homeTasksView } =
      this.props;
    const tasksArray = [...Object.values(pastScheduledTasks)];
    return (
      // PastScheduledTaskContainer used to position calendar popout
      <PastScheduledTasksContainer className="past-scheduled-tasks-container">
        {tasksArray.map((task) => {
          const singleLineCutoff = 70;
          const secondLineCutoff = 65;
          const firstLineBreak = task.description
            .slice(0, singleLineCutoff)
            .lastIndexOf(' ');
          const firstLine = task.description.slice(0, firstLineBreak);
          const remainder = task.description.slice(firstLineBreak);
          const shouldTruncate = remainder.length > secondLineCutoff;
          const secondLine = shouldTruncate
            ? remainder.slice(0, secondLineCutoff).concat('...')
            : remainder;

          const renderedDescription = firstLine + secondLine;
          return (
            <div className="past-scheduled-task" key={task.id}>
              <div
                className="task-circle"
                onClick={() => this.markComplete(task)}
                style={{
                  marginTop: '18px',
                  alignSelf: 'flex-start'
                }}
              >
                <div className="check-mark" />
              </div>
              <div
                className="task-container"
                style={{
                  alignSelf: 'flex-start',
                  width: '600px',
                  minHeight: '65px'
                }}
              >
                <div className="task-description">
                  <div className="task-name" style={{ paddingTop: `20px` }}>
                    {renderedDescription}
                  </div>
                </div>
                <div
                  className="task-project-title"
                  style={{ fontSize: '12px', color: '#b3b3b3' }}
                >
                  {task?.project?.title ?? ''}
                </div>
              </div>
              <TaskContentRight displayOneDate={!homeTasksView}>
                {homeTasksView && (
                  <BulkUpdateModalDateSelector
                    task={task}
                    taskDisplay={bulkUpdateDisplayDate}
                    homeTasksView={homeTasksView}
                    calendarType="due_at"
                  />
                )}
                <BulkUpdateModalDateRangeSelector task={task} />
                {/* <BulkUpdateModalDateSelector
                  task={task}
                  taskDisplay={bulkUpdateDisplayDate}
                  homeTasksView={homeTasksView}
                  calendarType="schedule_start"
                /> */}
              </TaskContentRight>
            </div>
          );
        })}
      </PastScheduledTasksContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state)
});

const mapDispatchToProps = {
  updateTaskPosition,
  handleTaskCompletionToggle: triggerTaskCompletionToggle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUpdateModalTaskList);
