import React from 'react';
import { connect } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';
import {
  makeGetActiveWorkloadPlannerFilter,
  makeGetActiveWorkloadPlannerFilterIdHashes,
  makeGetOrderedFilterClients
} from 'selectors';
import {
  InnerDropdownHeader,
  SelectorOption,
  SelectedValue,
  StyledInputContainer,
  CustomCheckBoxContainer,
  StyledInput,
  CloseIconContainer
} from './styles';

import NewCloseIcon from 'icons/NewCloseIcon';
import VirtualFilter from 'ReportsModule/components/Filter/VirtualFilter';
import { StyledFilterRow } from 'ReportsModule/components/styles';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import styled from 'styled-components';
import theme from 'theme';

const FilterStyles = styled.div`
  .filter-list-item-container,
  ${StyledFilterRow} {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${theme.colors.colorSemiDarkGray1} !important;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.colorTranslucentGray4};
      ${CustomCheckBoxContainer} {
        border-color: ${({ isDisabled }) =>
          isDisabled ? 'transparent' : theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const customItemsOverride = {
  clientList: []
};

class ClientFilter extends React.Component {
  state = {
    search: '',
    isFocused: false
  };

  componentDidUpdate(prevProps) {
    const { clients } = this.props;
    if (
      clients &&
      prevProps.clients &&
      clients.length !== prevProps.clients.length
    ) {
      this.clearMemo();
    }
  }

  searchMemo = {};
  clearMemo = () => (this.searchMemo = {});
  getItems = () => {
    const { search } = this.state;
    if (!this.searchMemo[search]) {
      this.searchMemo[search] = this.filterItems();
    }
    return this.searchMemo[search];
  };

  filterItems = () => {
    const { search } = this.state;
    const { clients } = this.props;
    const searchWords = search.split(' ').filter((str) => str !== '-');
    return clients.filter(
      (item) =>
        item.id &&
        (!search.length || this.itemFilter(item, searchWords)) &&
        this.itemIsActiveOrSelected(item)
    );
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['title']
    });

  itemIsActiveOrSelected = (item) =>
    !item.is_archived || this.props.activeFilterIdHashes?.client_ids?.[item.id];

  clearSearch = () => this.setState({ search: '' });
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSearchFocus = (isFocused) => {
    this.setState({
      ...this.state,
      isFocused: isFocused
    });
  };

  renderHeader = () => {
    const { filter, updateAccountFilterLocal, allSelected, widgetConfig } =
      this.props;
    const { search, isFocused } = this.state;
    const clientsSelected =
      filter && filter.client_ids ? filter.client_ids.length : '';
    const filterClientIds = filter?.client_ids ?? [];
    const bulkSelectorIds = allSelected
      ? []
      : Array.from(
          new Set([
            ...this.getItems()
              .map((client) => client?.id)
              .filter((id) => id),
            ...filterClientIds
          ])
        );

    const selectionLimit = widgetConfig?.limits?.client_ids;

    return (
      <>
        <StyledInputContainer style={{ display: 'flex' }}>
          <StyledInput
            onChange={this.handleChange}
            name="search"
            value={search}
            placeholder="Search or select below"
            autoComplete="off"
            autoFocus
            onFocus={() => this.handleSearchFocus(true)}
            onBlur={() => this.handleSearchFocus(false)}
            isActive={isFocused || !!search.length}
            className="workload-filter"
          />
          {this.state.search !== '' ? (
            <CloseIconContainer
              onClick={this.clearSearch}
              className="filter-close-icon"
            >
              <NewCloseIcon />
            </CloseIconContainer>
          ) : (
            ''
          )}
        </StyledInputContainer>
        <InnerDropdownHeader style={{ padding: '6px 0px 6px 25px' }}>
          {!selectionLimit && (
            <SelectorOption
              onClick={(e) =>
                updateAccountFilterLocal({
                  ...filter,
                  client_ids: bulkSelectorIds
                })
              }
            >
              {allSelected ? 'None \u00A0' : 'All'}
            </SelectorOption>
          )}

          {!!clientsSelected && (
            <SelectorOption
              onClick={(e) =>
                updateAccountFilterLocal({
                  ...filter,
                  client_ids: []
                })
              }
            >
              Clear Selected &nbsp;
            </SelectorOption>
          )}
          {clientsSelected ? (
            <SelectedValue isBlue={clientsSelected}>
              {clientsSelected}
              {selectionLimit ? `/${selectionLimit}` : ''}
            </SelectedValue>
          ) : (
            <SelectedValue>0 clients selected</SelectedValue>
          )}
        </InnerDropdownHeader>
      </>
    );
  };

  render() {
    const {
      viewType,
      viewBy,
      pageName,
      innerHeightAdjustment,
      widgetConfig,
      filter,
      additionalFilterOptions
    } = this.props;
    const { filterTab, search } = this.state;

    return (
      <>
        {this.renderHeader()}
        <FilterStyles>
          <VirtualFilter
            viewType={viewType}
            pageName={pageName}
            viewBy={viewBy}
            filterSections={['clients']}
            sectionsStartOpen
            showOnEngaged={false}
            showStickyHeader={false}
            searchOverride={{ clients: search }}
            customItems={customItemsOverride}
            leftPadding={`10px`}
            headerLeftOffset={0}
            leftOffset={'0px'}
            width={225}
            skipHeader={{ members: true }}
            innerHeightAdjustment={innerHeightAdjustment || 315}
            saveLocal
            widgetConfig={widgetConfig}
            activeFilter={filter}
            additionalFilterOptions={additionalFilterOptions}
          />
        </FilterStyles>
      </>
    );
  }
}
const makeMapStateToProps = () => {
  const getOrderedFilterClients = makeGetOrderedFilterClients();
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const getActiveWorkloadPlannerFilterIdHashes =
    makeGetActiveWorkloadPlannerFilterIdHashes();
  return (state, ownProps) => {
    const clients = getOrderedFilterClients(state, ownProps);
    return {
      clients,
      filter: getActiveWorkloadPlannerFilter(state, ownProps),
      activeFilterIdHashes: getActiveWorkloadPlannerFilterIdHashes(
        state,
        ownProps
      ),
      allSelected: clients.isAllSelected
    };
  };
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(makeMapStateToProps, mapDispatchToProps)(ClientFilter);
