import { initialState } from 'reducers/workloadEvents';
import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';

const emptyObj = {};
const emptyArray = [];

const getWorkloadEventState = (state) => state.workloadEvents || initialState;

export const getWorkloadEventsModalIsOpen = createSelector(
  getWorkloadEventState,
  (state) => state.isOpen
);

const getWorkloadEventOrder = createSelector(
  getWorkloadEventState,
  (state) => state.workloadEventOrder
);

export const getWorkloadEvents = createSelector(
  getWorkloadEventState,
  (state) => state.workloadEvents
);

export const getWorkloadEventsArray = createSelector(
  getWorkloadEventOrder,
  getWorkloadEvents,
  (ids = [], emails = {}) => ids.map((id) => emails[id])
);

export const getWorkloadEventsSelectedEventId = createSelector(
  getWorkloadEventState,
  (state) => state.selectedEventId
);

export const getWorkloadEventsSelectedEvent = createSelector(
  getWorkloadEventsSelectedEventId,
  getWorkloadEvents,
  (id, events) => events[id]
);

export const getWorkloadEventIsPersonal = createSelector(
  getWorkloadEventState,
  (state) => state.isPersonal
);

export const getWorkloadEventActor = createSelector(
  getWorkloadEventState,
  (state) => state.actor
);

export const getWorkloadEventIsHomeWorkplan = createSelector(
  getWorkloadEventState,
  (state) => state.isHomeWorkplan
);

export const getRemindersByAccountId = createSelector(
  getWorkloadEventState,
  (state) =>
    keyBy(
      filter(
        state.workloadEvents,
        (workloadEvent) => workloadEvent.event_type === 'time_entry_reminder'
      ),
      'recipient_account_ids[0]'
    )
);
