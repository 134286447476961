import { useAppSelector } from 'reduxInfra/hooks';
import { getSplitFlags, getIsSplitLoaded } from 'selectors';
import { SplitFlags } from 'appConstants/splitFlags';

/**
 * Provides a mapping of split flags to boolean values indicating whether the
 * feature should be active or not. Add new split flags to `splitFlags` inside
 * `assets/js/constants/splitFlags.ts` for it to be picked up.
 */
const useFeatureFlags = () => {
  const flags = useAppSelector(getSplitFlags) as SplitFlags;
  const isLoaded = useAppSelector(getIsSplitLoaded) as boolean;

  return {
    ...flags,
    isLoaded
  };
};

export default useFeatureFlags;
