import { select, all, call, put } from 'redux-saga/effects';
import { api } from '../service';
import * as phaseApi from '../service/api/phases';
import * as entityActions from '../actions';
import { fetchPhasesByProjectIds } from '../actions/phaseEntityActions';
import { changeEntity, fetchEntity } from './generics';

import {
  getAuthToken,
  getIsOnProjectView,
  getSelectedProjectAccountIds,
  getSelectedTeamId,
  getAllTimesheetsByDescription,
  getFormattedPlannerModalDates,
  getBatchSelectedTimesheetIds,
  getMe
} from 'selectors';
import {
  getTobeAddedActivityId,
  getBudgetModalOpen,
  getBudgetModalProjectId
} from 'BudgetModule/selectors';
import { fetchMosaicTimeEntryMappingStatuses } from 'IntegrationsModule/actionCreators';
import * as utilizationActionCreators from 'UtilizationModule/actionCreators';
import * as budgetActionCreators from 'BudgetModule/actionCreators';
import * as actionCreators from 'actionCreators';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';
import { isNotStatus, isStatus } from 'appUtils/timesheetUtils';
import {
  syncServices,
  fetchSyncedServices
} from 'QuickbooksModule/entityActions';
import * as qbAPI from 'QuickbooksModule/api';
import { makeIdHash } from 'appUtils';
import flatten from 'lodash/flatten';
import moment from 'moment';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import { readTimesheetEntries } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/timesheet';
import { GENERIC_ACTION } from 'appConstants';
import { READ_TIMESHEET_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const {
  activitiesFetch,
  descriptionsFetch,
  filteredActivitiesPerProjectFetch,
  timesheetsFetch,
  timesheetsIndexFetch,
  fetchTimesheetsUtilization,
  processTimeEntriesForQuickbooks,
  exportingTimesheets,
  timesheetCreate,
  timesheetUpdate,
  timesheetBulkUpdate,
  timesheetsDelete,
  activityCreate,
  activityUpdate,
  fetchActivityMembers,
  archiveActivityMember,
  createActivityMembers,
  descriptionCreate,
  descriptionUpdate,
  descriptionsDelete,
  checkHasTimeEntries,
  timesheetsPredictionsFetch,
  fetchRemainingTimesheetHours
} = entityActions;

export function* fetchActivities(action) {
  const { is_custom, project_id, planned } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    activitiesFetch,
    api.fetchActivities,
    undefined,
    [token, planned, project_id, is_custom],
    action
  );
}
export function* fetchDescriptions(action) {
  const {
    accountId,
    planned,
    projectId,
    startDate,
    endDate,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const { visibleTimeStart, visibleTimeEnd } = yield select(
    getFormattedPlannerModalDates
  );
  const { error, response } = yield fetchEntity(
    descriptionsFetch,
    api.fetchDescriptions,
    undefined,
    [
      token,
      accountId,
      planned,
      projectId,
      startDate || visibleTimeStart,
      endDate || visibleTimeEnd
    ],
    action
  );
  if (!error && response) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* fetchFilteredActivitiesPerProject(action) {
  const { accountId, startDate, endDate, project_ids } = action.payload;
  const token = yield select(getAuthToken);
  const { visibleTimeStart, visibleTimeEnd } = yield select(
    getFormattedPlannerModalDates
  );
  const { error, response } = yield fetchEntity(
    filteredActivitiesPerProjectFetch,
    api.fetchFilteredActivitiesPerProject,
    undefined,
    [
      token,
      accountId,
      project_ids,
      startDate || visibleTimeStart,
      endDate || visibleTimeEnd
    ],
    action
  );
}

export function* fetchTimesheets(action) {
  const { accountId, planned, projectId, startDate, endDate } = action.payload;
  const token = yield select(getAuthToken);
  const { visibleTimeStart, visibleTimeEnd } = yield select(
    getFormattedPlannerModalDates
  );
  const { error, response } = yield fetchEntity(
    timesheetsFetch,
    api.fetchTimesheets,
    undefined,
    [
      token,
      accountId,
      planned,
      projectId,
      startDate || visibleTimeStart,
      endDate || visibleTimeEnd
    ],
    action
  );
}

export function* fetchTimesheetsPredictions(action) {
  const { accountId, startDate, endDate, phaseIds } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    timesheetsPredictionsFetch,
    api.fetchTimesheetsPredictions,
    undefined,
    [token, accountId, startDate, endDate, phaseIds],
    action
  );
}

export function* fetchTimesheetsIndex(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    timesheetsIndexFetch,
    api.fetchTimesheetsIndex,
    undefined,
    [token, body],
    action
  );

  if (!error && response.time_entries?.length) {
    yield put(
      fetchMosaicTimeEntryMappingStatuses({
        timeEntryIds: response.time_entries.map((timeEntry) => timeEntry.id)
      })
    );
  }
}
export function* fetchTimesheetsUtilizationWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    fetchTimesheetsUtilization,
    api.fetchTimesheetsUtilization,
    undefined,
    [token, body],
    action
  );
}
export function* processTimeEntriesForQuickbooksWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    processTimeEntriesForQuickbooks,
    api.processTimeEntriesForQuickbooks,
    [token, body],
    action
  );
}

export function* exportTimesheets(action) {
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    exportingTimesheets,
    api.fetchTimesheetsIndex,
    undefined,
    [token, { ...action.payload.body }],
    action
  );
  if (response.url) {
    yield put(actionCreators.downloadUrl({ url: response.url }));
  }
}

export function* createActivity(action) {
  const { title, requireTitle, billable, isCustom } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const syncedActivities = [];
  const tobeAddedActivityId = yield select(getTobeAddedActivityId);
  const { error, response } = yield changeEntity(
    activityCreate,
    api.createActivity,
    [token, title, requireTitle, billable, isCustom],
    action
  );
  if (response && response.activities && tobeAddedActivityId) {
    const [activity] = Object.keys(response.activities);
    syncedActivities.push({
      item_id: tobeAddedActivityId,
      mosaic_activity_code_id: activity
    });
    const body = {
      records: syncedActivities
    };

    yield changeEntity(
      syncServices,
      qbAPI.syncServices,
      [token, teamId, body],
      action
    );
    yield fetchEntity(
      fetchSyncedServices,
      qbAPI.fetchSyncedServices,
      teamId,
      [token],
      action
    );
  }
}

export function* updateActivity(action) {
  const {
    title,
    id,
    archived,
    billable,
    requireTitle,
    is_custom,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    activityUpdate,
    api.updateActivity,
    [token, id, title, archived, billable, requireTitle, is_custom],
    action
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* fetchActivityMembersWorker(action) {
  const token = yield select(getAuthToken);
  const { activityPhaseId } = action.payload;
  yield fetchEntity(
    fetchActivityMembers,
    api.fetchActivityMembers,
    activityPhaseId,
    [token],
    action
  );
}
export function* createActivityMembersWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    activityPhaseId,
    accountIds,
    memberBudgetIds,
    onSuccess = [],
    shouldRefetch = true
  } = action.payload;

  const { error, response } = yield changeEntity(
    createActivityMembers,
    api.createActivityMembers,
    [token, activityPhaseId, accountIds, memberBudgetIds],
    action
  );

  if (shouldRefetch) {
    yield fetchEntity(
      fetchPhasesByProjectIds,
      phaseApi.fetchPhasesByProjectIds,
      [projectId],
      [token],
      action
    );
    yield put(actionCreators.fetchPhaseTotals({ projectId }));
    yield put(
      actionCreators.fetchAllProjects({
        projectIds: [projectId]
      })
    );
    yield put(actionCreators.fetchProjectById(projectId));
    yield put(actionCreators.fetchProjectTeam(projectId));
    yield put(actionCreators.fetchAllProjects({ projectIds: [projectId] }));
    yield put(actionCreators.fetchTeamMembers());
  }

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* archiveActivityMemberWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    activityPhaseMemberId,
    archive,
    onSuccess = []
  } = action.payload;
  const { error, response } = yield changeEntity(
    archiveActivityMember,
    api.archiveActivityMember,
    [token, activityPhaseMemberId, archive],
    action,
    action.payload
  );

  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    phaseApi.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield put(
    actionCreators.fetchAllProjects({
      projectIds: [projectId]
    })
  );
  yield put(actionCreators.fetchProjectById(projectId));

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
  yield put(
    budgetActionCreators.fetchMemberBudgets({
      projectId
    })
  );
}

// activity, title, and phase are only used by BE when no description id is provided.
export function* createTimesheet(action) {
  const {
    day,
    hours,
    accountId,
    descriptionId,
    projectId,
    planned,
    phaseId,
    billable,
    activityId,
    title,
    overrideRate
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    timesheetCreate,
    api.createTimesheet,
    undefined,
    [
      token,
      accountId,
      descriptionId,
      day,
      hours,
      projectId,
      planned,
      phaseId,
      billable,
      activityId,
      title,
      overrideRate
    ],
    action
  );
  // description was auto created/merged by backend
  if (response && !error) {
    yield put(
      actionCreators.fetchTimesheetsIndex({
        insert: true,
        body: { time_entry_ids: [response.id] }
      })
    );
    yield call(refetchBudgetOnTimesheetChangeWorker);
  }
  if (error) {
    yield put(
      actionCreators.timeEntryCreateUpdateError({
        day,
        descriptionId,
        error
      })
    );
  }
}

export function* updateTimesheet(action) {
  const { timesheetId, hours, status, billable, day, descriptionId } =
    action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    timesheetUpdate,
    api.updateTimesheet,
    undefined,
    [token, timesheetId, +hours, status, billable],
    action
  );
  if (error) {
    yield put(
      actionCreators.timeEntryCreateUpdateError({
        day,
        descriptionId,
        error
      })
    );
  }
  yield call(refetchBudgetOnTimesheetChangeWorker);
}

export function* bulkUpdateTimesheets(action) {
  const token = yield select(getAuthToken);
  const batchSelectedTimesheets = yield select(getBatchSelectedTimesheetIds);

  const { error, response } = yield fetchEntity(
    timesheetBulkUpdate,
    api.bulkUpdateTimesheets,
    undefined,
    [token, { time_entry_ids: batchSelectedTimesheets, ...action.payload }], // allow ids override
    action
  );
  if (response && !error) {
    const { updated } = response;
    yield put(
      actionCreators.fetchTimesheetsIndex({ body: { time_entry_ids: updated } })
    );
    yield call(refetchBudgetOnTimesheetChangeWorker);
  }
}

export function* deleteTimesheets(action) {
  const { timesheetIds, fetchParams } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    timesheetsDelete,
    api.deleteTimesheets,
    undefined,
    [token, timesheetIds],
    action
  );
  yield call(refetchBudgetOnTimesheetChangeWorker);

  if (fetchParams) {
    yield put(
      actionCreators.fetchTimesheetsIndex({
        body: fetchParams,
        initial: true
      })
    );
  }
}
function* refetchBudgetOnTimesheetChangeWorker() {
  const projectId = yield select(getBudgetModalProjectId);
  if (projectId) {
    yield put(
      budgetActionCreators.fetchProjectBudgetViewSettings({ projectId })
    );
    yield put(actionCreators.fetchPhases({ projectId }));
    yield put(budgetActionCreators.fetchMemberBudgets({ projectId }));
    yield put(actionCreators.fetchPhaseTotals({ projectId }));
  }
}

export function* submitDates(action) {
  const {
    payload: { status, dates }
  } = action;
  const teamId = yield select(getSelectedTeamId);
  const me = yield select(getMe);
  const allTimeEntriesByDescription = yield select(
    getAllTimesheetsByDescription
  );
  const dateHash = makeIdHash(dates);
  const timeEntries = flatten(
    Object.values(allTimeEntriesByDescription).map((description) =>
      Object.values(description)
    )
  ).filter((timeEntry) => dateHash[timeEntry.date]);

  let submittableTimeEntries = [];

  if (status === TIMESHEET_STATUSES.NOT_SUBMITTED) {
    submittableTimeEntries = timeEntries.filter(
      isNotStatus(TIMESHEET_STATUSES.APPROVED)
    );
  }
  if (status === TIMESHEET_STATUSES.SUBMITTED) {
    submittableTimeEntries = timeEntries.filter(
      isStatus(TIMESHEET_STATUSES.NOT_SUBMITTED)
    );
  } else {
    submittableTimeEntries = timeEntries;
  }

  yield all(
    submittableTimeEntries.map((timesheet) =>
      call(
        updateTimesheet,
        actionCreators.updateTimesheet({
          timesheetId: timesheet.id,
          hours: timesheet.hours,
          day: timesheet.date,
          descriptionId: timesheet.description_id,
          status
        })
      )
    )
  );
  const startDate = moment(dates[0]).startOf('week').format('MM/DD/YYYY');
  const endDate = moment(dates[0]).endOf('week').format('MM/DD/YYYY');
  yield put(
    utilizationActionCreators.fetchMembersUtilizationReport({
      team_id: teamId,
      account_ids: [me.id],
      show_status_totals: true,
      show_pto: true,
      show_holidays: true,
      interval_type: 'days',
      interval_amount: 1,
      start_date: startDate,
      end_date: endDate
    })
  );
}

export function* createDescription(action) {
  const isOnProjectView = yield select(getIsOnProjectView);
  if (isOnProjectView) {
    const projectMemberAccountIds = yield select(getSelectedProjectAccountIds);
    yield all(
      projectMemberAccountIds.map((accountId) =>
        call(createDescriptionApiCall, {
          ...action,
          payload: {
            ...action.payload,
            accountId
          }
        })
      )
    );
  } else {
    yield* createDescriptionApiCall(action);
  }
}

function* createDescriptionApiCall(action) {
  const {
    activityId,
    projectId,
    title,
    date,
    accountId,
    phaseId,
    planned,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    descriptionCreate,
    api.createDescription,
    [token, activityId, phaseId, projectId, title, date, accountId, planned],
    action
  );
  if (!error && response) {
    onSuccess?.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
}

export function* updateDescription(action) {
  const { title, id, activityId, phaseId, projectId, accountId, timeEntryIds } =
    action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    descriptionUpdate,
    api.updateDescription,
    [token, id, title, activityId, phaseId, projectId, accountId],
    action
  );
  if (timeEntryIds) {
    yield put(
      actionCreators.fetchTimesheetsIndex({
        body: { time_entry_ids: timeEntryIds }
      })
    );
  }
}

export function* deleteDescriptions(action) {
  const { descriptionIds, timesheets = {} } = action.payload;
  const token = yield select(getAuthToken);
  const timesheetsToDelete = Object.values(timesheets);

  if (timesheetsToDelete.length) {
    const { error, response } = yield fetchEntity(
      timesheetsDelete,
      api.deleteTimesheets,
      undefined,
      [token, timesheetsToDelete.map((timesheet) => timesheet.id)],
      action
    );
  }
  const { error, response } = yield changeEntity(
    descriptionsDelete,
    api.deleteDescriptions,
    [token, descriptionIds],
    action
  );
}

export function* checkHasTimeEntriesWorker(action) {
  const token = yield select(getAuthToken);

  const { permissions, ...body } = action.payload;

  const permissionCheckParams = {
    permissions,
    UI_CHECK: true
  };

  const canReadTimesheetEntries = yield put(
    readTimesheetEntries(permissionCheckParams)
  );

  if (!canReadTimesheetEntries) {
    yield put(
      actionCreators.handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: READ_TIMESHEET_TIP
      })
    );

    return;
  }

  const { error, response } = yield fetchEntity(
    checkHasTimeEntries,
    api.checkHasTimeEntries,
    undefined,
    [token, body],
    action
  );
}

export function* fetchRemainingTimesheetHoursWorker(action) {
  const {
    teamId,
    ignoreWeeklyCpacity,
    startDate,
    endDate,
    accountIds,
    onSuccess = []
  } = action.payload;

  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    fetchRemainingTimesheetHours,
    api.fetchRemainingTimesheetHours,
    undefined,
    [
      token,
      convertKeysToSnakeCase({
        teamId,
        ignoreWeeklyCpacity,
        startDate,
        endDate,
        accountIds
      })
    ],
    action
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction((action.payload, response))
    );
  }
}
