import {
  FetchTeamPermissionsParams,
  UpdateTeamPermissionsParams,
  UpdateTeamMembersPermissionParams,
  BulkInviteAccountsParams
} from './types';
import { callApi } from 'appUtils/api';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';

const updateTeamMembersPermissions = (
  token: string,
  { teamId, teamMembershipIds, teamRole }: UpdateTeamMembersPermissionParams
) => {
  const body = convertKeysToSnakeCase({ teamId, teamMembershipIds, teamRole });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('team_memberships/bulk_update_permission', token, fetchInit);
};

/** Team Role Templates */

const fetchTeamPermissions = (
  token: string,
  { teamId }: FetchTeamPermissionsParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`teams/${teamId}/grouped_permission_show`, token, fetchInit);
};

const updateTeamPermissions = (
  token: string,
  { teamId, permissionUpdateInfo }: UpdateTeamPermissionsParams
) => {
  const body = convertKeysToSnakeCase({
    teamId,
    permissionUpdateInfo
  });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(
    `team_role_templates/grouped_permission_update`,
    token,
    fetchInit
  );
};

const bulkInviteAccounts = (
  token: string,
  { accounts: _accounts, actableId, actableType }: BulkInviteAccountsParams
) => {
  const accounts = _accounts.map(
    ({
      email,
      firstName: first_name,
      lastName: last_name,
      referenceNumber: employee_number,
      employmentType: employment_type,
      teamRole: role
    }) => ({
      email,
      first_name,
      last_name,
      employee_number,
      employment_type,
      role,
      ...convertKeysToSnakeCase({ actableId, actableType })
    })
  );
  const body = convertKeysToSnakeCase({
    accounts
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('invite/bulk_create', token, fetchInit);
};

export const permissionsApi = {
  updateTeamMembersPermissions,
  fetchTeamPermissions,
  updateTeamPermissions,
  bulkInviteAccounts
};
