import { Draft, createReducer } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import * as constants from 'SettingsModule/constants/region';
import { Region } from 'SettingsModule/models/region';

export interface RegionsState {
  regionsHash: Record<number, Region>;
  isLoaded: boolean;
  isLoading: boolean;
}

export const initialState: RegionsState = {
  regionsHash: {},
  isLoaded: false,
  isLoading: false
};

const handleFetchRegionsTrigger = (state: Draft<RegionsState>) => {
  state.isLoading = true;
};

const handleFetchRegionsFailure = (state: Draft<RegionsState>) => {
  state.isLoading = false;
  state.isLoaded = true;
};

const handleFetchRegionsSuccess = (state: Draft<RegionsState>, action) => {
  const { response } = action.payload as {
    response: FetchRegionsSuccessResponse;
  };

  state.regionsHash = keyBy(response.regions, (region) => region.id);

  state.isLoaded = true;
  state.isLoading = false;
};

const handleCreateUpdateRegionSuccess = (
  state: Draft<RegionsState>,
  action
) => {
  const { response } = action.payload as {
    response: CreateUpdateRegionSuccessResponse;
  };

  state.regionsHash[response.id] = response;
};

const handleUpdateRegionEntitiesSuccess = (
  state: Draft<RegionsState>,
  action
) => {
  const { response } = action.payload as {
    response: UpdateRegionEntitiesSuccessResponse;
  };

  state.regionsHash[response.id] = response;
};

export const regionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(constants.FETCH_REGIONS.TRIGGER, handleFetchRegionsTrigger);
  builder.addCase(constants.FETCH_REGIONS.FAILURE, handleFetchRegionsFailure);
  builder.addCase(constants.FETCH_REGIONS.SUCCESS, handleFetchRegionsSuccess);
  builder.addCase(
    constants.CREATE_REGION.SUCCESS,
    handleCreateUpdateRegionSuccess
  );
  builder.addCase(
    constants.UPDATE_REGION.SUCCESS,
    handleCreateUpdateRegionSuccess
  );
  builder.addCase(
    constants.UPDATE_REGION_ENTITIES.SUCCESS,
    handleUpdateRegionEntitiesSuccess
  );
});

interface FetchRegionsSuccessResponse {
  regions: Region[];
}

type CreateUpdateRegionSuccessResponse = Region;
type UpdateRegionEntitiesSuccessResponse = Region;
