import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NotificationIcon = ({
  className,
  height = '12',
  width = '14',
  fill = '#979797'
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 12 24"
    className={className}
    fill="none"
  >
    <path
      d="M5.94851 0.0026358C5.72879 0.0287506 5.54655 0.244956 5.55007 0.473905V0.969475C4.71394 1.07758 3.99788 1.48266 3.45938 2.05778C2.82656 2.73313 2.42695 3.63257 2.25937 4.58904L1.65938 8.00944C1.53516 8.71939 1.28086 8.86515 0.91875 9.11234C0.7377 9.23623 0.516795 9.36801 0.32343 9.60304C0.130065 9.83807 0 10.1812 0 10.5796C0 11.2604 0.543165 11.8234 1.2 11.8234H3.75465C3.83258 13.035 4.81169 14 6 14C7.18831 14 8.16735 13.035 8.24535 11.8234H10.8C11.4568 11.8234 12 11.2604 12 10.5796C12 10.1812 11.8699 9.83807 11.6766 9.60304C11.4832 9.36801 11.2623 9.23622 11.0812 9.11234C10.7191 8.86517 10.4648 8.71941 10.3406 8.00944L9.74063 4.58904C9.57305 3.63252 9.17344 2.733 8.54062 2.05778C8.00215 1.48266 7.28613 1.07757 6.44992 0.969475V0.473905C6.45403 0.20912 6.20223 -0.0277436 5.94851 0.0026358ZM6.00007 1.87313C6.78991 1.87313 7.40866 2.18589 7.89382 2.70395C8.37898 3.222 8.7194 3.95684 8.85944 4.75432L9.45944 8.17473C9.62995 9.14583 10.2001 9.62439 10.5891 9.88975C10.7837 10.0227 10.9219 10.1205 10.9922 10.2055C11.0626 10.2906 11.1001 10.3574 11.1001 10.5796C11.1001 10.76 10.9741 10.8906 10.8001 10.8906H1.20006C1.02603 10.8906 0.90006 10.76 0.90006 10.5796C0.90006 10.3574 0.93756 10.2906 1.00787 10.2055C1.07819 10.1205 1.21646 10.0227 1.411 9.88975C1.80007 9.62436 2.37017 9.14578 2.54068 8.17473L3.14068 4.75432C3.28072 3.95692 3.62114 3.22206 4.1063 2.70395C4.59146 2.18583 5.21023 1.87313 6.00007 1.87313ZM4.67819 11.8234H7.32194C7.24577 12.5273 6.70261 13.0672 6.00007 13.0672C5.29753 13.0672 4.75436 12.5273 4.67819 11.8234Z"
      fill={fill}
    />
  </SvgIcon>
);

export default NotificationIcon;
