import * as constants from 'appConstants';

export const initialState = {
  dragulaActive: true,
  isFocused: false,
  isFiltering: false,
  clearSearch: false,
  isGlobalSearching: false,
  showResultsPane: false,
  searchText: '',
  projects: [],
  savedSearchTexts: {
    home: '',
    board: ''
  },
  shouldUse: {
    home: false,
    board: false
  },
  sidebarSearchOpen: false,
  sidebarProjectsOpen: false,
  sidebarProjectsOpener: 'projects',
  mouseOverSearchBar: false,
  myProjectsStateBackendChoice: false
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.TOGGLE_SIDEBAR_SEARCH:
      return {
        ...state,
        sidebarSearchOpen: !state.sidebarSearchOpen
      };
    case constants.CLOSE_SIDEBAR_SEARCH: {
      return {
        ...state,
        sidebarSearchOpen: false
      };
    }
    case constants.OPEN_SIDEBAR_PROJECTS: {
      return {
        ...state,
        sidebarProjectsOpen: true
      };
    }
    case constants.CLOSE_SIDEBAR_PROJECTS: {
      return {
        ...state,
        sidebarProjectsOpen: false
      };
    }
    case constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.TRIGGER: {
      return {
        ...state,
        myProjectsStateBackendChoice: action.payload.isPinned
      };
    }
    case constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.SUCCESS: {
      return {
        ...state,
        myProjectsStateBackendChoice:
          action.payload.response.are_my_projects_open
      };
    }
    case constants.USER.SUCCESS:
      return {
        ...state,
        myProjectsStateBackendChoice:
          action.payload.response.account.are_my_projects_open
      };

    case constants.TOGGLE_SIDEBAR_PROJECTS.TRIGGER:
      const { initiator, fromHome, shouldOpen } = action.payload;

      return {
        ...state,
        sidebarProjectsOpener: initiator,
        sidebarProjectsOpen: shouldOpen
      };

    case constants.SET_MOUSE_OVER_SEARCH_BAR:
      return {
        ...state,
        mouseOverSearchBar: action.isOverSearch
      };

    case constants.TOGGLE_SEARCH_RESULTS:
      return {
        ...state,
        showResultsPane: action.allowOpen
      };

    case constants.FETCH_PROJECTS.REQUEST:
      return {
        ...state,
        dragulaActive: !state.searchText && !state.isFiltering
      };
    case constants.CLEAR_SEARCH:
      return {
        ...state,
        dragulaActive: !state.isFiltering,
        searchText: '',
        projects: [],
        isGlobalSearching: false,
        showResultsPane: false
      };
    case constants.IS_SEARCHING:
      return {
        ...state,
        isFocused: action.isSearching
      };
    case constants.ON_SEARCH:
      return {
        ...state,
        isGlobalSearching: true,
        searchText: action.searchText
      };
    case constants.ON_GLOBAL_SEARCH.REQUEST:
      return {
        ...state,
        isGlobalSearching: true
      };
    case constants.ON_GLOBAL_SEARCH.SUCCESS:
      return {
        ...state,
        projects: action.payload.response.projects,
        showResultsPane: true,
        isGlobalSearching: false
      };

    case constants.FILTER_BY_MEMBERS:
      return {
        ...state,
        isFiltering: action.isFiltering,
        dragulaActive: !state.searchText && !action.isFiltering
      };

    case constants.PROJECT_CREATION.TRIGGER:
      return {
        ...state,
        clearSearch: true,
        searchText: ''
      };

    case constants.PROJECT_CREATION.SUCCESS:
      return {
        ...state,
        clearSearch: false
      };

    case constants.SAVE_SEARCH_STATE:
      return {
        ...state,
        savedSearchTexts: action.searchTexts
      };
    case constants.USE_SEARCH_STATE:
      return {
        ...state,
        shouldUse: action.pages
      };

    default:
      return state;
  }
};

export default search;
