import * as constants from 'appConstants';
const { FETCH_USER_ACTIVITIES, FLUSH_USER_ACTIVITIES } = constants;

// This initial state should be populated with some 'valid' data for demo purposes
const initialState = {
  userActivities: {},
  showUpdatesMovedMessage: false
};
const parseActions = (id, actions) => {
  /*
    ProjectTask: Array(3)
        0: {id: 57258, actions: Array(1)}
        1:
          id: 57248
          actions: Array(1)
            0:
              action_type: 11
                time: "2020-04-19T16:46:01-04:00"
  */
  const parsed = {};
  actions.map((action) => {
    parsed[action.action_type] = parsed[action.action_type] || [];
    const entry = { id, time: action.time };
    parsed[action.action_type].push(entry);
  });
  return parsed;
};
const parseUserActivitiesResponse = (activities) => {
  /* 
    0: [["Project", 29488], [{action_type: 25, time: "2020-04-16T15:10:06-04:00"}]]
    1: [["ProjectTask", 64373], [{action_type: 11, time: "2020-04-16T15:10:13-04:00"}]]
    2: [["ProjectTask", 57263], [{action_type: 11, time: "2020-04-16T15:10:17-04:00"}]]
    3: [["ProjectTask", 57264], [{action_type: 11, time: "2020-04-16T15:10:15-04:00"}]]
    4: [["ProjectTask", 57262], [{action_type: 11, time: "2020-04-16T15:10:20-04:00"}]]
  */
  const parsed = {};
  activities.map((activity) => {
    parsed[activity[0][0]] = parsed[activity[0][0]] || [];
    const id = activity[0][1];
    const actions = parseActions(id, activity[1]);
    Object.keys(actions).map((key) => {
      parsed[activity[0][0]][key] = parsed[activity[0][0]][key] || [];
      parsed[activity[0][0]][key] = [
        ...parsed[activity[0][0]][key],
        ...actions[key]
      ];
    });
  });
  return parsed;
};
const activities = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_USER_ACTIVITIES.SUCCESS: {
      const activities = payload.response.activity_by_entity;
      const newActivities = parseUserActivitiesResponse(activities);
      const accountId = payload.requestPayload?.accountId;
      const date = payload.requestPayload?.date;
      const currentActivities = state.userActivities?.[accountId];
      return {
        ...state,
        userActivities: {
          [accountId]: { ...currentActivities, [date]: newActivities }
        }
      };
    }
    case FETCH_USER_ACTIVITIES.FAILURE: {
      return state;
    }
    case FLUSH_USER_ACTIVITIES: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default activities;
