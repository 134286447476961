import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface NewCloseIconProps
  extends Pick<SvgIconProps, 'className' | 'width' | 'height' | 'onClick'> {
  testId?: SvgIconProps['data-testid'];
  noCircle?: boolean;
  fillColor?: string;
  stroke?: string;
}

export const NewCloseIcon = ({
  className,
  width = '24',
  height = '24',
  fillColor = '#4F4F4F',
  stroke = '#EBEBEB',
  testId = '',
  onClick,
  noCircle = false
}: NewCloseIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    data-testid={testId}
    onClick={onClick}
  >
    {!noCircle && (
      <circle cx="12" cy="12" r="11.5" stroke={stroke} fill="transparent" />
    )}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1823 8.32777L15.6424 6.78791L11.9853 10.445L8.32823 6.78798L6.78837 8.32784L10.4454 11.9849L6.78819 15.6421L8.32805 17.182L11.9853 13.5248L15.6426 17.182L17.1824 15.6422L13.5251 11.9849L17.1823 8.32777Z"
      fill={fillColor}
      stroke="transparent"
    />
  </SvgIcon>
);

export default NewCloseIcon;
