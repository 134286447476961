import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledText = styled.div`
  display: flex;
  align-items: center;
  justify-align: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const EmailCell = ({ row }) => {
  const { userActivity } = row.original;
  return <StyledText>{userActivity?.display_data.email}</StyledText>;
};

export default EmailCell;
