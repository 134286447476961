import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withRouterParams from './WithRouterParams';
import {
  getMatchedRouteParams,
  getHomeTaskObj,
  getTempIdsToCreatedTaskIds
} from 'selectors';
import { TaskModal } from 'views';
import TaskModalNavigationTracker from 'views/Navigation/TaskModalNavigationTracker';

class TaskModalRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      match.url === nextLocation.pathname;
    return shouldUpdate;
  }

  render() {
    const { match, taskHash, tempIdsToTaskIdsHash } = this.props;
    if (!match.params.taskId) {
      return null;
    }
    let taskId = match.params.taskId;
    if (taskHash[taskId]) {
      taskId = taskHash[taskId].id;
    } else {
      const foundTempIdTaskId = tempIdsToTaskIdsHash[taskId];

      if (foundTempIdTaskId) {
        taskId = foundTempIdTaskId;
      }
    }
    return (
      <>
        <TaskModalNavigationTracker />
        <TaskModal taskId={taskId} isOpen={!!match.params.taskId} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  taskHash: getHomeTaskObj(state),
  tempIdsToTaskIdsHash: getTempIdsToCreatedTaskIds(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskModalRouter)),
  true
);
