import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getSelectedTeamId, getIsOnProjectView } from 'selectors';
import cn from 'classnames';
import { budgetAccessCheck } from 'BudgetModule/actionCreators';
import {
  StyledBar,
  StyledBarSection,
  OverdueBudgetBarContainer,
  OverdueBar,
  StyledBarContainer,
  BarNumber,
  PercentHover
} from './styles';
import PropTypes from 'prop-types';
import { isHoursOverBudget } from 'BudgetModule/utils/isOverBudget';
import { LEGEND_ITEMS } from 'BudgetModule/constants';
import { formatBarPercent } from 'BudgetModule/utils';
import Totals from './Totals';
import withPermissionsCheck from 'hocs/withPermissionsCheck';

const emptyObj = {};

const mapLabelText = {
  spent_hours: 'Spent',
  planned_hours: 'Planned',
  remaining_hours: 'Remaining'
};

class BudgetBar extends React.Component {
  getTooltipContent = (text, barPercent, total) => {
    const mapText = {
      spent_hours: 'Spent Hours',
      planned_hours: 'Planned Hours',
      remaining_hours: 'Remaining Hours',
      over_budget: 'Overbudget'
    };
    return `<div>${mapText[text]}</div>
      <div class='member-budget-text-container-tooltip'>
        <div>${Math.ceil(parseInt(total)).toLocaleString()}h</div>
        <div>${barPercent}</div>
      </div>`;
  };

  renderBarSection = (text) => {
    const {
      totals,
      showHours,
      projectId,
      phaseId,
      memberBudgetId,
      noOverBudget,
      disableBarTooltip,
      hideBudgetTotals,
      isOnTimesheet,
      dataTip,
      barClassName,
      calculateBarSectionTotals,
      viewBy,
      rowType,
      calculateAllBarValues
    } = this.props;

    const total =
      noOverBudget && text === 'remaining_hours' // remaining is positive or 0 when noOverBudget true
        ? Math.max(0, totals[text])
        : totals[text];

    // i.e [valueType, format] -> [spent,cost]
    const [valueType] = text.split('_');

    const bartotals = calculateBarSectionTotals
      ? calculateBarSectionTotals({ showHours, totals, viewBy, rowType })
      : +totals.spent_hours + +totals.planned_hours + +totals.remaining_hours;
    const allBarValues = calculateAllBarValues
      ? calculateAllBarValues({
          totals,
          isOverBudget: false,
          viewBy,
          rowType,
          showHours,
          type: text
        })
      : {};

    const barNumberValue = parseInt(
      allBarValues.barNumberValue ?? total
    ).toLocaleString({
      maximumFractionDigits: 0
    });

    // Bar length (in %)
    const barPercent =
      allBarValues.barLength ?? formatBarPercent(total / bartotals);

    // Display
    const barNumberDisplayValue =
      allBarValues.barNumberToDisplay ??
      (showHours ? `${barNumberValue}h` : `$${barNumberValue}`);

    return (
      <StyledBarSection
        key={text}
        text={text}
        total={barPercent}
        maxWidth={barPercent}
        data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
        data-tip={
          dataTip ||
          JSON.stringify({
            total,
            text: mapLabelText[text],
            totals: totals,
            formatType: showHours ? 'hour' : 'currency',
            showHours
          })
        }
        data-testid={`${projectId}-${phaseId}-${memberBudgetId}-bar`}
        isOnTimesheet={isOnTimesheet}
        className={`${barClassName} ${valueType} ${rowType} ${viewBy}`}
      >
        {barNumberDisplayValue && !hideBudgetTotals && (
          <BarNumber className={`hide-on-hover ${valueType} ${barClassName}`}>
            {barNumberDisplayValue}
          </BarNumber>
        )}
        {bartotals !== 0 && !hideBudgetTotals && (
          <PercentHover className={`${valueType} ${barClassName}`}>
            {barPercent}%
          </PercentHover>
        )}
      </StyledBarSection>
    );
  };

  renderOverBudget = () => {
    const {
      totals,
      showHours,
      projectId,
      phaseId,
      memberBudgetId,
      disableBarTooltip,
      hideBudgetTotals,
      dataTip,
      hideOverBudget,
      barClassName,
      calculateBarSectionTotals,
      calculateAllBarValues,
      rowType,
      viewBy
    } = this.props;

    const spentTotal = showHours ? totals.spent_hours : totals.spent_cost;
    const plannedTotal = showHours ? totals.planned_hours : totals.planned_cost;
    const remainingTotal = Math.abs(
      showHours ? +totals.remaining_hours : +totals.remaining_cost
    );
    const formatType = showHours ? 'hour' : 'currency';

    const bartotals = calculateBarSectionTotals
      ? calculateBarSectionTotals({ showHours, totals, rowType, viewBy })
      : spentTotal + plannedTotal + remainingTotal;
    const allBarValues = calculateAllBarValues
      ? calculateAllBarValues({
          totals,
          isOverBudget: true,
          viewBy,
          rowType,
          showHours
        })
      : {};

    // Bar Length (in %)
    const spentPercent =
      allBarValues.spentBarLength ?? formatBarPercent(spentTotal / bartotals);
    const plannedPercent =
      allBarValues.plannedBarLength ??
      formatBarPercent(plannedTotal / bartotals);
    const remainingPercent =
      allBarValues.overBudgetBarLength ??
      formatBarPercent(remainingTotal / bartotals);

    // Display
    const displaySpentValue =
      allBarValues.spentValueToDisplay ??
      (showHours ? `${spentTotal}h` : `$${spentTotal}`);

    const displayPlannedValue =
      allBarValues.plannedValueToDisplay ??
      (showHours ? `${plannedTotal}h` : `$${plannedTotal}`);

    return (
      <OverdueBudgetBarContainer>
        <StyledBarSection
          text={'spent_hours'}
          total={spentPercent}
          data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
          data-tip={
            dataTip ||
            JSON.stringify({
              total: spentTotal,
              text: 'spent',
              totals: totals,
              formatType,
              showHours
            })
          }
          data-testid={`${projectId}-${phaseId}-${memberBudgetId}-bar`}
          className={`${barClassName} spent`}
          maxWidth={spentPercent}
        >
          {displaySpentValue && !hideBudgetTotals && (
            <BarNumber className={`${barClassName} spent hide-on-hover`}>
              {displaySpentValue}
            </BarNumber>
          )}
          {spentPercent && !hideBudgetTotals && (
            <PercentHover className={`${barClassName} spent`}>
              {spentPercent}%
            </PercentHover>
          )}
        </StyledBarSection>
        <StyledBarSection
          maxWidth={plannedPercent}
          text={'planned_hours'}
          total={plannedPercent}
          data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
          data-tip={
            dataTip ||
            JSON.stringify({
              total: plannedTotal,
              text: 'planned',
              totals: totals,
              formatType,
              showHours,
              disableBarTooltip
            })
          }
          className={`${barClassName} planned`}
        >
          {displayPlannedValue && !hideBudgetTotals && (
            <BarNumber className={`hide-on-hover planned ${barClassName}`}>
              {displayPlannedValue}
            </BarNumber>
          )}

          {plannedPercent && !hideBudgetTotals && (
            <PercentHover className={`planned ${barClassName}`}>
              {plannedPercent}%
            </PercentHover>
          )}
        </StyledBarSection>
        {!hideOverBudget && (
          <OverdueBar
            shouldShow={!!(totals && remainingPercent)}
            flexWidth={remainingPercent}
            className={cn({
              [barClassName]: true,
              'no-planned': plannedTotal <= 0
            })}
          />
        )}
      </OverdueBudgetBarContainer>
    );
  };

  renderBudget = (total) => {
    const { totals, barsToUse, shouldShowNoValueBar, showHours } = this.props;
    const shouldRenderBars = !(shouldShowNoValueBar
      ? shouldShowNoValueBar({ totals, showHours })
      : false);
    return shouldRenderBars ? barsToUse.map(this.renderBarSection) : <span />;
  };

  render() {
    const {
      barHeight,
      totals,
      maxMemberBudget,
      phaseId,
      memberBudgetId,
      projectId,
      onClick,
      hideTotal,
      noOverBudget,
      width,
      dataTip,
      disableBarTooltip,
      showHours,
      budgetBarProps,
      shouldShowNoValueBar,
      barsToUse,
      barClassName,
      calculateBarWidthNumerator,
      rowType,
      viewBy
    } = this.props;
    if (!totals) {
      return null;
    }

    const spent = showHours ? +totals.spent_hours : +totals.spent_cost;

    const planned = showHours ? +totals.planned_hours : +totals.planned_cost;

    const remaining = showHours
      ? +totals.remaining_hours
      : +totals.remaining_cost;

    const numerator = calculateBarWidthNumerator
      ? calculateBarWidthNumerator({
          spent,
          planned,
          remaining
        })
      : spent + planned + remaining;

    const reducer = (accumulator, currentValue) =>
      accumulator + Math.abs(+totals[currentValue] || 0);

    const total = totals && barsToUse.reduce(reducer, 0);

    const shouldRenderBars = !(shouldShowNoValueBar
      ? shouldShowNoValueBar({ totals, showHours })
      : false);

    return (
      <StyledBarContainer
        data-tip={dataTip}
        data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
        isMember
        onClick={onClick}
      >
        {/* <ReactTooltip
          id={`${projectId}-${phaseId}-${memberBudgetId}-tooltip`}
          place="top"
        /> */}
        <StyledBar
          isMember
          height={barHeight}
          className={cn(`${barClassName} ${rowType} ${viewBy}`, {
            'show-bars': shouldRenderBars
          })}
          style={{
            width:
              width ||
              `${Math.max(
                Math.pow((numerator || 0) / maxMemberBudget, 0.5) * 100,
                4
              )}%`
          }}
        >
          {!noOverBudget && isHoursOverBudget(totals)
            ? this.renderOverBudget()
            : this.renderBudget(total)}
        </StyledBar>
        {!hideTotal && (
          <Totals
            totals={totals}
            isMemberBudgetTotal
            phaseId={phaseId}
            memberBudgetId={memberBudgetId}
            projectId={projectId}
            getTooltipContent={this.getTooltipContent}
            overduePercent={(Math.abs(remaining) * 100) / spent + planned}
          />
        )}
      </StyledBarContainer>
    );
  }
}

BudgetBar.propTypes = {
  totals: PropTypes.object
};

const makeMapStateToProps = (state, ownProps) => {
  const { barsProps = emptyObj, viewBy, rowType, totals } = ownProps;
  const {
    showHours,
    hideOverBudget,
    barClassName,
    budgetBarProps = emptyObj,
    shouldShowNoValueBar,
    getBars,
    overrideShowHours
  } = barsProps;

  const showHoursToUse = overrideShowHours
    ? overrideShowHours({ showHours, viewBy, rowType })
    : showHours ?? getIsOnProjectView(state);

  const barsToUse = getBars
    ? getBars({
        viewBy,
        rowType,
        totals,
        showHours: showHoursToUse,
        defaultBars: LEGEND_ITEMS
      })
    : LEGEND_ITEMS;

  const {
    calculateBarSectionTotals,
    calculateBarWidthNumerator,
    calculateAllBarValues
  } = budgetBarProps;

  return {
    teamId: getSelectedTeamId(state),
    showHours: showHoursToUse,
    barsToUse,
    calculateAllBarValues,
    calculateBarSectionTotals,
    calculateBarWidthNumerator,
    hideOverBudget,
    barClassName,
    shouldShowNoValueBar
  };
};

const mapDispatchToProps = {
  budgetAccessCheck
};

export default withPermissionsCheck(
  connect(makeMapStateToProps, mapDispatchToProps)(BudgetBar)
);
