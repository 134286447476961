import React from 'react';
import {
  StyledLegendItem,
  StyledLegendBox,
  StyledLegendBoxTarget
} from './styles';
import * as constants from 'appConstants';
import CircledDot from 'icons/CircledDot';

const tooltipHash = {
  spent_hours: constants.SPENT_TOOLTIP,
  spent_percent: constants.SPENT_TOOLTIP,
  spent_cost: constants.SPENT_TOOLTIP,
  spent: constants.SPENT_TOOLTIP,

  planned_hours: constants.PLANNED_TOOLTIP,
  planned: constants.PLANNED_TOOLTIP,
  planned_percent: constants.PLANNED_TOOLTIP,
  planned_cost: constants.PLANNED_TOOLTIP,

  remaining_hours: constants.REMAINING_TOOLTIP,
  remaining_percent: constants.REMAINING_TOOLTIP,
  remaining_cost: constants.REMAINING_TOOLTIP,
  remaining: constants.REMAINING_TOOLTIP,

  fee: '',
  estimated: constants.ESTIMATED_TOOLTIP,
  target: constants.BUDGET_TOOLTIP,
  estimated_hours: constants.ESTIMATED_TOOLTIP,
  estimated_cost: constants.ESTIMATED_TOOLTIP
};

const legendTextHash = {
  spent_hours: 'SPENT',
  spent_percent: 'SPENT',
  spent_cost: 'SPENT',
  spent: 'SPENT',

  planned_hours: 'PLANNED',
  planned_percent: 'PLANNED',
  planned: 'PLANNED',
  planned_cost: 'PLANNED',

  remaining_hours: 'REMAINING',
  remaining_percent: 'REMAINING',
  remaining_cost: 'REMAINING',
  remaining: 'REMAINING',

  fee: 'FEE',
  estimated_cost_percent: 'FEE',
  estimated_hours_percent: 'FEE',

  estimated_cost: 'BUDGET',
  estimated_hours: 'BUDGET',
  estimated: 'BUDGET',
  target: 'BUDGET',

  scope: 'SCOPE',
  completed: 'COMPLETED',

  percent_completed: '% COMPLETED',

  // Schedule Variance
  schedule_variance_planned: 'PLANNED',
  actual: 'ACTUAL'
};

const hasNoDot = {
  scope: true,
  completed: true,
  percent_completed: true
};

const BudgetLegendItem = ({
  text,
  tooltipContent,
  hideTooltip = false,
  hideDot,
  label,
  forceShowDot,
  estimatedNotTarget
}) => (
  <StyledLegendItem
    className="legend-item-container"
    data-for={'app-tooltip'}
    data-effect="solid"
    data-html
    data-class="center"
    data-tip={hideTooltip ? '' : tooltipContent || tooltipHash[text]}
  >
    {forceShowDot ? (
      <StyledLegendBoxTarget>
        <CircledDot />
      </StyledLegendBoxTarget>
    ) : [
        'estimated',
        'estimated_percent',
        'estimated_hours',
        'estimated_cost',
        'target'
      ].includes(text) && !estimatedNotTarget ? (
      !hideDot && (
        <StyledLegendBoxTarget>
          <CircledDot />
        </StyledLegendBoxTarget>
      )
    ) : (
      !hideDot && !hasNoDot[text] && <StyledLegendBox text={text} />
    )}
    {label || legendTextHash[text]}
  </StyledLegendItem>
);
export default BudgetLegendItem;
