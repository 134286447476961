import styled from 'styled-components';
import CloseIcon from 'icons/CloseIcon';
import { MouseEvent } from 'react';

interface AvailabilityButtonProps {
  active?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}
export const AvailabilityButton = ({
  active,
  onClick,
  className = ''
}: AvailabilityButtonProps) => (
  <StyledButton onClick={onClick} className={className}>
    {active && <StyledCloseIcon />}
    {active ? 'Hide Availability' : 'Availability'}
  </StyledButton>
);

const StyledCloseIcon = styled(CloseIcon).attrs(({ theme }) => ({
  width: 9,
  height: 9,
  fill: theme.colors.colorCalendarGray
}))``;

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 0;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  font-weight: 600;
  font-size: 13px;
  user-select: none;
  white-space: nowrap;

  ${StyledCloseIcon} {
    margin-bottom: 2px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.colorCalendarBlue};

    ${StyledCloseIcon} {
      path {
        fill: ${({ theme }) => theme.colors.colorCalendarBlue};
      }
    }
  }
`;
