export const buildAccessIdentifier = ({ actableId, actableType, actionType }) =>
  `${actableId}@@${actableType}@@${actionType}`;

export const parseAccessIdentifier = (identifier) => {
  const [actableId, actableType, actionType] = identifier.split('@@');
  return {
    actableId,
    actableType,
    actionType
  };
};
