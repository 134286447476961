import React from 'react';
import { connect } from 'react-redux';
import {
  makeGetActiveFilterChanged,
  getAccountFiltersFetched
} from 'selectors';
import { updateAccountFilterTemporarySaveAction } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';

import NewSaveIcon from 'icons/NewSaveIcon';
import NewSaveIconSolid from 'icons/NewSaveIconSolid';

export const SaveIconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.onClick && 'cursor: pointer;'}
  .show-on-hover {
    display: none;
  }
  &:hover {
    .hide-on-hover {
      display: none;
    }
    .show-on-hover {
      display: unset;
    }
  }
`;

const SaveFilterOption = ({
  activeFilter,
  tooltip = 'Current view settings<br />and filters are saved',
  activeFilterChangedTooltip = 'Click to save current<br />view settings and filters',
  style,
  iconSize,
  //
  activeFilterChanged,
  updateAccountFilterTemporarySaveAction,
  className,
  isDisabled
}) => {
  const onClick = () => {
    updateAccountFilterTemporarySaveAction(activeFilter);
  };

  const tooltipToUse = isDisabled
    ? 'Saving is currently disabled.'
    : activeFilterChanged
    ? activeFilterChangedTooltip
    : tooltip;

  return (
    <SaveIconContainer
      onClick={activeFilterChanged && !isDisabled ? onClick : undefined}
      className={`save-filter-option ${className}`}
      data-testid="save-filter-icon"
      data-for="app-tooltip"
      data-effect="solid"
      data-class="center"
      data-tip={tooltipToUse}
      data-html
      style={style}
    >
      <div className="hide-on-hover">
        <NewSaveIcon
          color={
            isDisabled
              ? theme.colors.colorLightGray15
              : activeFilterChanged
              ? theme.colors.colorBudgetBlue
              : theme.colors.colorLightGray15
          }
          width={iconSize}
          height={iconSize}
        />
      </div>
      <div className="show-on-hover">
        {(isDisabled || !activeFilterChanged) && (
          <NewSaveIcon
            color={theme.colors.colorLightGray15}
            width={iconSize}
            height={iconSize}
          />
        )}
        {!isDisabled && activeFilterChanged && (
          <NewSaveIconSolid width={iconSize} height={iconSize} />
        )}
      </div>
    </SaveIconContainer>
  );
};

const makeMapStateToProps = () => {
  const getActiveFilterChanged = makeGetActiveFilterChanged();
  const mapStateToProps = (state, ownProps) => {
    const isAccountFiltersFetched = getAccountFiltersFetched(state);
    return {
      activeFilterChanged: getActiveFilterChanged(state, ownProps),
      isDisabled: ownProps.isDisabled || !isAccountFiltersFetched
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterTemporarySaveAction
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SaveFilterOption);
