import {
  ComponentProps,
  forwardRef,
  Ref,
  useEffect,
  useMemo,
  useState
} from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import theme from 'theme';
import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import CommentBubbleIcon from 'icons/CommentBubbleIconV2';
import NoteIcon from 'icons/NoteIcon';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import LightBulbIcon from 'icons/LightBulbIcon';
import HourGlassIcon from 'icons/HourGlassIcon';
import MemberCircles from 'components/members/MemberCircles';
import { Task } from 'models/task';
import { formatDateRangeString } from 'appUtils/dateRangeHelpers';
import { useDrag } from 'react-dnd';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import {
  isEndDateDependency,
  isStartDateDependency
} from 'appUtils/newDependencies';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import { Card } from '../shared/Card';

/**
 * A description of an item in the three-dots menu. If the icon and the text
 * produce `undefined`, the menu item is not rendered.
 */
export interface TaskCardMenuItem {
  /**
   * Optional icon which will be rendered to the left of the item text.
   * Separating the icon from the text allows us to align the icons and the
   * texts.
   *
   * This may also be a function that produces a value or `undefined` according
   * to the task properties.
   */
  icon?: ((task: Task) => React.ReactChild | undefined) | React.ReactChild;

  /**
   * The label, which may be an element, that will be rendered for the item.
   *
   * This may also be a function that produces a value or `undefined` according
   * to the task properties.
   */
  label: ((task: Task) => React.ReactChild | undefined) | React.ReactChild;

  /**
   * The function that is called when the menu item is clicked.
   */
  onClick?: (task: Task) => void;

  /** True if menu item is disabled */
  isDisabled?: boolean;

  /** Tooltip when hovering over menu item */
  menuItemTooltip?: string;
}

export interface TaskCardProps {
  /**
   * The class of the card.
   */
  className?: string;

  /**
   * Indicates the tooltip to be shown along with the date warning icon. If
   * `undefined`, the icon and tooltip do not show.
   */
  dateWarningTooltip?: string;

  /**
   * Indicates whether the menu for the task is floatted to the left or the
   * right. If `undefined`, the menu is set within the card.
   */
  floatMenu?: 'left' | 'right';

  /**
   * Sets the distance, in pixels, between the side of the card and the
   * floatting menu if `floatMenu` is also set. If `floatMenu` is
   * `undefined`, this has no effect.
   */
  floatMenuOffset?: number;

  /**
   * Flag to indicate if the suggestion indicator is shown.
   */
  isSuggestion?: boolean;

  /**
   * A list of menu items. If there are no rendered menu items or if this is
   * not set, the menu button is not drawn. See `TaskCardMenuItem` for a
   * description of the requirements for a rendered menu item.
   */
  menu?: Array<TaskCardMenuItem>;

  /**
   * The function that is called when the task card is clicked.
   */
  onClick?: (task: Task) => void;

  /**
   * The task used to render the card.
   */
  task: Task;
}

/**
 * A task card with key details.
 */
const TaskCardInner = (
  {
    className,
    dateWarningTooltip,
    floatMenu,
    floatMenuOffset = 0,
    isSuggestion = false,
    menu = [],
    onClick,
    task
  }: TaskCardProps,
  ref: Ref<HTMLDivElement>
) => {
  // Used to close the three dot menu when one of the options is clicked.
  const [threeDotIsOpen, setThreeDotIsOpen] = useState(false);

  const {
    assignee_ids: assigneeIds,
    completed_at: completedAt,
    description,
    estimated_hours: estimatedHours,
    note,
    schedule_end: scheduleEnd,
    schedule_start: scheduleStart,
    task_comment_ids: taskCommentIds
  } = task;

  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const assignees = useMemo(
    () =>
      assigneeIds.flatMap((id) => {
        const teamMembership = teamMembershipsByAccountId[id];
        return teamMembership ? [teamMembership.account.id] : [];
      }),
    [assigneeIds, teamMembershipsByAccountId]
  );

  const numberComments = taskCommentIds?.length || 0;

  const menuItems = useMemo(() => {
    return (
      menu
        // Call the rendering functions for the icons and labels.
        .map(({ icon, onClick, label, isDisabled, menuItemTooltip }) => ({
          label: typeof label === 'function' ? label(task) : label,
          icon: typeof icon === 'function' ? icon(task) : icon,
          onClick,
          isDisabled,
          menuItemTooltip
        }))
        // Remove the menu items that have no icon or label.
        .filter(({ label, icon }) => label || icon)
    );
  }, [menu, task]);

  // Flags to determine if there is at least one icon or one label in the menu.
  // This allows conditionally rendering the icons and labels columns.
  const menuHasIcons = menuItems.some(({ icon }) => icon);
  const menuHasLabels = menuItems.some(({ label }) => label);

  // Rebuild the tooltips as needed.
  useEffect(() => {
    if (
      (scheduleStart && dateWarningTooltip) ||
      note !== '' ||
      numberComments > 0 ||
      estimatedHours !== null
    )
      rebuildTooltip();
  }, [dateWarningTooltip, estimatedHours, note, numberComments, scheduleStart]);

  const hasStartDependency = useMemo(
    () =>
      task.dependencies?.some((dependency) =>
        isStartDateDependency(dependency.dependency_type)
      ) ?? false,
    [task.dependencies]
  );

  const hasEndDependency = useMemo(
    () =>
      task.dependencies?.some((dependency) =>
        isEndDateDependency(dependency.dependency_type)
      ) ?? false,
    [task.dependencies]
  );

  const renderTopMatter = () => (
    <>
      {(isSuggestion || scheduleStart || estimatedHours) && (
        <Header>
          {isSuggestion && (
            <LightBulbIcon
              height="12"
              width="8.8"
              fill={theme.colors.colorMediumPurple2}
            />
          )}
          {scheduleStart && (
            <Dates
              data-class="center"
              data-tip-disable={!dateWarningTooltip}
              data-effect="solid"
              data-for="app-tooltip"
              data-html
              data-tip={dateWarningTooltip}
            >
              {hasStartDependency && <DependencyLinkIconStart />}
              {formatDateRangeString({
                start_date: scheduleStart,
                end_date: scheduleEnd,
                dateFormat: 'M/D/YY',
                dateFormat1: undefined,
                dateFormat2: undefined
              })}
              {dateWarningTooltip && <Alert>!</Alert>}
              {hasEndDependency && <DependencyLinkIconEnd />}
            </Dates>
          )}
        </Header>
      )}
      <Body>
        {(description !== '' || note !== '') && (
          <Description>
            {note && (
              <span
                data-class="center"
                data-effect="solid"
                data-for="app-tooltip"
                data-html
                data-tip="Notes"
              >
                <NoteIcon
                  className={undefined}
                  color={theme.colors.colorSemiDarkGray1}
                  height="12"
                  isHovered={false}
                  width="12"
                />
              </span>
            )}
            {note && description && <>&nbsp;</>}
            {description}
          </Description>
        )}
      </Body>
    </>
  );

  return (
    <Base
      className={cn(className, {
        isCompleted: !!completedAt,
        isClickable: !!onClick,
        menuOpen: threeDotIsOpen
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(task);
      }}
      ref={ref}
    >
      {menuItems.length > 0 ? (
        <Top>
          <Left>{renderTopMatter()}</Left>
          <Menu
            className={floatMenu && 'float-' + floatMenu}
            $floatOffset={floatMenu && floatMenuOffset}
          >
            <ThreeDotMenu
              className="menu"
              color={theme.colors.colorRoyalBlue}
              overrideIsOpen={threeDotIsOpen}
              overrideSetIsOpen={setThreeDotIsOpen}
              placement="bottom-end"
              slide={false}
              vertical={false}
            >
              <MenuList>
                {menuItems.map(
                  (
                    { label, icon, onClick, isDisabled, menuItemTooltip },
                    index
                  ) => (
                    <MenuItem
                      key={index}
                      onClick={
                        !isDisabled
                          ? (e) => {
                              e.stopPropagation();
                              setThreeDotIsOpen(false);
                              onClick && onClick(task);
                            }
                          : undefined
                      }
                      className={cn({ disabled: isDisabled })}
                      {...defaultTooltipProps}
                      data-tip={menuItemTooltip}
                    >
                      {menuHasIcons && <MenuIcon>{icon}</MenuIcon>}
                      {menuHasLabels && <MenuLabel>{label}</MenuLabel>}
                    </MenuItem>
                  )
                )}
              </MenuList>
            </ThreeDotMenu>
          </Menu>
        </Top>
      ) : (
        renderTopMatter()
      )}
      <Footer>
        <MemberCircles accountIds={assignees} maxVisible={2} noAnimation />
        <Indicators>
          {numberComments > 0 && (
            <Indicator
              data-class="center"
              data-effect="solid"
              data-for="app-tooltip"
              data-html
              data-tip="Comments"
            >
              <CommentBubbleIcon color={theme.colors.colorSemiDarkGray1} />
              {numberComments}
            </Indicator>
          )}
          {estimatedHours !== null && (
            <Indicator
              data-class="center"
              data-effect="solid"
              data-for="app-tooltip"
              data-html
              data-tip="Time Estimate"
            >
              <HourGlassIcon
                height={10}
                color={theme.colors.colorSemiDarkGray1}
              />
              {formatNumWithMaxTwoDecimals(estimatedHours)}h
            </Indicator>
          )}
        </Indicators>
      </Footer>
    </Base>
  );
};

export const TaskCard = forwardRef(TaskCardInner);

export const DraggableTaskCard = (props: ComponentProps<typeof TaskCard>) => {
  const {
    task: { id: taskId, project_id: projectId }
  } = props;

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'task', id: taskId, project_id: projectId },
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  });

  return <TaskCard ref={dragRef} className={cn({ isDragging })} {...props} />;
};

const DependencyLinkIconStart = styled(DependencyLinkIcon)`
  margin-right: 3px;
`;

const DependencyLinkIconEnd = styled(DependencyLinkIcon)`
  margin-left: 3px;
`;

const Dates = styled.div`
  align-items: center;
  display: flex;
`;

const Alert = styled.span`
  color: ${({ theme }) => theme.colors.colorDeleteRed};
  font-weight: 900;
  margin-left: 4px;
`;

const Description = styled.div`
  flex: 1;
  font-size: 15px;
  overflow: hidden;
  overflow-wrap: break-word;

  // Cut off the text after three lines.
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const MenuItemPart = styled.div`
  display: table-cell;
  padding: 7px 0;
  vertical-align: middle;

  // Space between the consecutive parts of a menu row.
  & + * {
    padding-left: 7px;
  }
`;

const MenuIcon = styled(MenuItemPart)`
  line-height: 0;

  // This assumes that menu icons are tightly cropped.
  > svg {
    height: 11px;
    width: 11px;
  }
`;

const MenuLabel = styled(MenuItemPart)``;

const MenuItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: table-row;

  &.disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }

  &:hover:not(.disabled) {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }

  // These four rules add padding around the menu.
  > :first-child {
    padding-left: 17px;
  }
  > :last-child {
    padding-right: 17px;
  }
  &:first-child > * {
    padding-top: 17px;
  }
  &:last-child > * {
    padding-bottom: 17px;
  }
`;

const MenuList = styled.ul`
  display: inline-table;
  font-size: 13px;
  padding: 0;
  margin: 0;
`;

interface MenuProps {
  $floatOffset?: number;
}

const Menu = styled.div.attrs<MenuProps>(({ $floatOffset = 0 }) => ({
  style: { '--float-distance': `${$floatOffset}px` }
}))<MenuProps>`
  flex: none;
  position: relative;

  // Aligns the right side of the menu dots at the right content edge.
  right: -5px;

  &.float-left,
  &.float-right {
    bottom: 0;
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
  }

  &.float-left {
    right: calc(100% + var(--float-distance));
  }

  &.float-right {
    left: calc(100% + var(--float-distance));
  }

  .menu {
    border-radius: 50%;
  }

  .menuOpen & .menu,
  .menu:hover {
    background-color: ${({ theme }) => theme.colors.colorPaleGray1};
    box-shadow: 0px 1px 4px 0px #00000040;
  }
`;

const Indicator = styled.span`
  align-items: center;
  display: flex;
  flex: none;
  gap: 2px;
`;

const Indicators = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 8px;
  justify-content: space-between;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  flex: none;
  justify-content: space-between;
  width: 100%;
`;

const Header = styled(Row)`
  line-height: 1;

  // Suggestion icon
  > svg:first-child {
    // This is computed so that the suggestion icon is centered in the left
    // padding of the card.
    margin-left: -13.4px;
    position: absolute;
  }
`;

const Body = styled(Row)`
  gap: 4px;
`;

const Footer = styled(Row)`
  align-items: flex-end;
  line-height: 1;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

const Top = styled.div`
  display: flex;
  flex: 1;
`;

const Base = styled(Card)`
  align-items: left;
  position: relative; // for the floating menu

  &.isCompleted {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
    ${Description} {
      color: ${({ theme }) => theme.colors.colorCalendarGray};
      text-decoration: line-through
        ${({ theme }) => theme.colors.colorLightGray15} 0.5px;
    }
  }

  &.isDragging {
    opacity: 0.5;
  }

  .menu {
    visibility: hidden;
  }

  ${Menu} {
    overflow: hidden;
    width: 0;
    transition: 0.275s all step-start;
  }

  // Hover and open menu styles
  &:hover,
  &.menuOpen {
    .menu {
      visibility: visible;
    }

    ${Menu} {
      overflow: visible;
      width: auto;
      transition: 0.275s all step-end;
    }
  }
`;
