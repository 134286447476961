import theme from 'theme';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { portal } from 'appUtils/portal';
import DragGripIcon from 'icons/DragGripIcon';

const ColumnDraggable = ({
  index,
  column,
  provided,
  isActive,
  snapshot = {},
  handleClick,
  renderLabel,
  disableDrag
}) => {
  const usePortal = snapshot.isDragging;
  const child = (
    <StyledColumnDraggable
      index={index}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      isActive={isActive}
      onClick={() => handleClick(index, column)}
    >
      <LeftDropdownItemContent>
        {!disableDrag && <StyledDragGripIcon />}
        <div>{renderLabel(column)}</div>
      </LeftDropdownItemContent>
      <RightDropdownItemContent isActive={isActive}>
        {isActive ? 'Hide' : 'Show'}
      </RightDropdownItemContent>
    </StyledColumnDraggable>
  );
  return usePortal ? ReactDOM.createPortal(child, portal) : child;
};

export default ColumnDraggable;

/* ------------------------------------ - ----------------------------------- */

const StyledDragGripIcon = styled(DragGripIcon)`
  min-width: 7px;
  min-height: 7px;
  height: 7px;
  width: 10px;
  margin: 0px 8px 0px 0px;

  cursor: grab;
`;

const LeftDropdownItemContent = styled.div`
  display: flex;
  align-items: center;

  .task-column-text {
    white-space: nowrap;
    margin-left: 5px;
  }
`;

const RightDropdownItemContent = styled.div`
  opacity: 1;
  color: ${(props) =>
    props.isActive
      ? theme.colors.colorLightGray5
      : theme.colors.colorSemiDarkGray1};
  cursor: pointer !important;
  margin: 0px;
  &:hover {
    div {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const StyledColumnDraggable = styled.div`
  opacity: 1;
  font-size: 14px;
  padding: 5px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  cursor: pointer !important;

  ${StyledDragGripIcon} {
    opacity: 1;
    margin-top: 1px;
  }

  ${LeftDropdownItemContent} {
    opacity: ${(props) => (props.isActive ? 1 : 0.7)};
    font-size: 14px;
    color: ${theme.colors.colorSemiDarkGray1};
  }

  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
    ${RightDropdownItemContent},
    ${StyledDragGripIcon} {
      opacity: 1;
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }
`;
