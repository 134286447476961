import { getAuthToken, getSelectedTeamId } from 'selectors';
import {
  fetchPTOPolicyPerMember,
  fetchPTOPolicyPerMemberActionCreatorsMap,
  fetchPTOPolicies,
  fetchPTOPoliciesActionCreatorsMap,
  fetchPTOPolicy,
  fetchPTOPolicyActionCreatorsMap,
  createPTOPolicy,
  createPTOPolicyActionCreatorsMap,
  updatePTOPolicy,
  updatePTOPolicyActionCreatorsMap,
  archivePTOPolicy,
  archivePTOPolicyActionCreatorsMap,
  fetchPTOGroupMemberships,
  fetchPTOGroupMembershipsActionCreatorsMap,
  createPTOGroupMembership,
  createPTOGroupMembershipActionCreatorsMap,
  deletePTOGroupMembership,
  deletePTOGroupMembershipActionCreatorsMap,
  updatePTOGroupMembership,
  updatePTOGroupMembershipActionCreatorsMap
} from 'SettingsModule/actionCreators/settings/pto';
import { changeEntity as entityHelper } from 'sagas/generics';
import { ptoApi } from 'SettingsModule/api/pto';

import { select, put } from 'redux-saga/effects';

export default {
  fetchPTOPolicyPerMemberWorker,
  fetchPTOPoliciesWorker,
  fetchPTOPolicyWorker,
  createPTOPolicyWorker,
  updatePTOPolicyWorker,
  archivePTOPolicyWorker,
  fetchPTOGroupMembershipsWorker,
  createPTOGroupMembershipWorker,
  updatePTOGroupMembershipWorker,
  deletePTOGroupMembershipWorker
};

function* fetchPTOPolicyPerMemberWorker(
  action: ReturnType<typeof fetchPTOPolicyPerMember>
) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchPTOPolicyPerMemberActionCreatorsMap,
    ptoApi.fetchPTOPolicyPerMember,
    [token, { teamId }],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* fetchPTOPoliciesWorker(action: ReturnType<typeof fetchPTOPolicies>) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchPTOPoliciesActionCreatorsMap,
    ptoApi.fetchPTOPolicies,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* fetchPTOPolicyWorker(action: ReturnType<typeof fetchPTOPolicy>) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchPTOPolicyActionCreatorsMap,
    ptoApi.fetchPTOPolicy,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* createPTOPolicyWorker(action: ReturnType<typeof createPTOPolicy>) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    createPTOPolicyActionCreatorsMap,
    ptoApi.createPTOPolicy,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* updatePTOPolicyWorker(action: ReturnType<typeof updatePTOPolicy>) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    updatePTOPolicyActionCreatorsMap,
    ptoApi.updatePTOPolicy,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* archivePTOPolicyWorker(action: ReturnType<typeof archivePTOPolicy>) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    archivePTOPolicyActionCreatorsMap,
    ptoApi.archivePTOPolicy,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* fetchPTOGroupMembershipsWorker(
  action: ReturnType<typeof fetchPTOGroupMemberships>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchPTOGroupMembershipsActionCreatorsMap,
    ptoApi.fetchPTOGroupMemberships,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );
}

function* createPTOGroupMembershipWorker(
  action: ReturnType<typeof createPTOGroupMembership>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { teamMembershipIds } = action.payload;

  yield entityHelper(
    createPTOGroupMembershipActionCreatorsMap,
    ptoApi.createPTOGroupMembership,
    [token, action.payload],
    action,
    // @ts-expect-error broken types
    action.payload
  );

  yield teamMembershipIds.map((teamMembershipId) =>
    put(fetchPTOGroupMemberships({ teamMembershipId }))
  );

  yield put(fetchPTOPolicyPerMember({ teamId }));
}

function* updatePTOGroupMembershipWorker(
  action: ReturnType<typeof updatePTOGroupMembership>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { payload, meta } = action;

  yield entityHelper(
    updatePTOGroupMembershipActionCreatorsMap,
    ptoApi.updatePTOGroupMembership,
    [token, payload],
    action,
    // @ts-expect-error broken types
    payload
  );

  yield put(
    fetchPTOGroupMemberships({ teamMembershipId: meta.teamMembershipId })
  );

  yield put(fetchPTOPolicyPerMember({ teamId }));
}

function* deletePTOGroupMembershipWorker(
  action: ReturnType<typeof deletePTOGroupMembership>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { payload, meta } = action;

  yield entityHelper(
    deletePTOGroupMembershipActionCreatorsMap,
    ptoApi.deletePTOGroupMembership,
    [token, payload],
    action,
    // @ts-expect-error broken types
    payload
  );

  yield put(
    fetchPTOGroupMemberships({ teamMembershipId: meta.teamMembershipId })
  );

  yield put(fetchPTOPolicyPerMember({ teamId }));
}
