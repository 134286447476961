import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import TeamRatesDropdown from '../TeamRatesDropdown';
import SalaryModal from './SalaryModal';
import {
  fetchMemberSalaries,
  deleteMemberSalary,
  updateMemberSalary
} from 'BudgetModule/actionCreators';
import { getSelectedTeamId } from 'selectors';
import { getMemberSalaries } from 'BudgetModule/selectors';
import { connect } from 'react-redux';
import TeamRateOverrideModal from '../TeamRateOverrideModal';
import OverheadModal from './OverheadModal';
import { RATE_MODAL_TYPES } from 'appConstants';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';

const CostRateContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MODAL_TYPES = {
  RATES: 'rates',
  SALARY: 'salary',
  OVERHEAD: 'overhead',
  CONFIRM_DELETE: 'confirmDelete',
  CHANGE_DATE: 'changeDate'
};

const CostRateSelection = ({
  children,
  target,
  member = {},
  teamId,
  memberSalaries,
  fetchMemberSalaries,
  deleteMemberSalary,
  updateMemberSalary
}) => {
  const [openModal, setOpenModal] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToChangeDate, setItemToChangeDate] = useState(null);
  const [itemToChangeRate, setItemToChangeRate] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToConfirmOverride, setItemToConfirmOverride] = useState(null);
  const onStickyClick = useCallback(() => setOpenModal(MODAL_TYPES.SALARY), []);

  const memberCostRates = memberSalaries[member.id];

  const handleOpenTeamRates = (e) => {
    setOpenModal(MODAL_TYPES.RATES);
    e && e.stopPropagation();
    fetchMemberSalaries({ teamMembershipId: member.id, teamId });
  };

  const handleDeleteItemSelection = useCallback((item, rates) => {
    setItemToDelete([item, rates]);
    setOpenModal(MODAL_TYPES.CONFIRM_DELETE);
  }, []);

  const setEditRate = useCallback((item) => {
    setItemToEdit(item);
    setOpenModal(MODAL_TYPES.RATES);
  }, []);

  const handleClose = () => setOpenModal(null);

  const handleDeleteConfirm = () => {
    if (itemToDelete[0]?.id) {
      deleteMemberSalary({
        teamMembershipId: member.id,
        teamId,
        id: itemToDelete[0].id
      });
    }
    setItemToDelete(null);
  };

  const handleDateChange = (item, date, dateType) => {
    const rateIndex = memberCostRates.findIndex((rate) => rate.id === item.id);
    const earliestRate = memberCostRates[memberCostRates.length - 1] || null;
    const earlierRate =
      dateType === 'endDate' ? item : memberCostRates[rateIndex + 1] || null;
    const laterRate =
      dateType === 'startDate' ? item : memberCostRates[rateIndex - 1] || null;

    setItemToChangeDate({
      item,
      date,
      dateType,
      earliestRate,
      earlierRate,
      laterRate
    });
    setOpenModal(MODAL_TYPES.CHANGE_DATE);
  };

  const handleDateChangeClose = () => {
    setOpenModal(MODAL_TYPES.RATES);
    setItemToChangeDate(null);
  };

  const handleDateChangeConfirm = () => {
    const { item, date, dateType } = itemToChangeDate;
    updateMemberSalary({
      [dateType]: date.format('YYYY-MM-DD'),
      id: item.id,
      teamMembershipId: item.team_membership_id
    });
    handleDateChangeClose();
  };

  const onOverheadUpdateSuccess = () => {
    fetchMemberSalaries({ teamMembershipId: member.id, teamId });
  };

  return (
    <>
      <CostRateContainer onClick={handleOpenTeamRates}>
        {children}
      </CostRateContainer>

      {/* Modal */}
      {(() => {
        switch (openModal) {
          case MODAL_TYPES.RATES:
            return (
              <TeamRatesDropdown
                memberName={member.account?.name}
                target={target}
                popoverClassName="member-rates-modal"
                teamMembershipId={member.id}
                onStickyClick={onStickyClick}
                handleClose={handleClose}
                handleDelete={handleDeleteItemSelection}
                handleDateChange={handleDateChange}
                editRate={setEditRate}
                member={member}
                noDescription
                modalType={RATE_MODAL_TYPES.COST_RATE}
                rates={memberCostRates}
                headerText={`${member.account?.name}'s Cost Rate`}
              />
            );

          case MODAL_TYPES.SALARY:
            return (
              <SalaryModal
                member={member}
                isOpen
                toggle={() => setOpenModal(MODAL_TYPES.RATES)}
                handleOverheadClick={() => setOpenModal(MODAL_TYPES.OVERHEAD)}
              />
            );

          case MODAL_TYPES.OVERHEAD:
            return (
              <OverheadModal
                toggle={() => setOpenModal(MODAL_TYPES.SALARY)}
                onOverheadUpdateSuccess={onOverheadUpdateSuccess}
              />
            );

          case MODAL_TYPES.CONFIRM_DELETE:
            return (
              <SimpleConfirmModal
                header="Delete rate?"
                body="Are you sure you want to delete this rate?"
                onConfirm={handleDeleteConfirm}
                isOpen
                toggle={() => setOpenModal(MODAL_TYPES.RATES)}
              />
            );

          case MODAL_TYPES.CHANGE_DATE:
            return (
              <TeamRateOverrideModal
                isUpdate
                onYes={handleDateChangeConfirm}
                onCancel={handleDateChangeClose}
                selectedMemberTeamRate={itemToChangeDate.item}
                selectedDate={itemToChangeDate.date}
                dateType={itemToChangeDate.dateType}
                teamMembershipId={member.id}
                accountId={member.account?.id}
                earliestRate={itemToChangeDate.earliestRate}
                earlierRate={itemToChangeDate.earlierRate}
                laterRate={itemToChangeDate.laterRate}
                isCostRate
              />
            );

          default:
            return null;
        }
      })()}
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: getSelectedTeamId(state),
  memberSalaries: getMemberSalaries(state)
});
const mapDispatchToProps = {
  fetchMemberSalaries,
  deleteMemberSalary,
  updateMemberSalary
};

export default connect(mapStateToProps, mapDispatchToProps)(CostRateSelection);
