import React from 'react';
import { InputDiv, IconContainer } from './styles';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const Input = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled,
  isFocused,
  singleDay,
  styles = {},
  isWorkplanCalendar,
  isPhaseCalendar,
  isWorkdays,
  hasDependency,
  dependency
}) => {
  const isWorkplanOrPhaseCalendar = !!isWorkplanCalendar || !!isPhaseCalendar;
  return (
    <InputDiv
      style={styles}
      isWorkplanOrPhaseCalendar={isWorkplanOrPhaseCalendar}
      isWorkdays={isWorkdays}
      label={label}
      hasDependency={hasDependency}
      singleDay={singleDay}
      dependency={dependency}
    >
      <div
        className="label"
        style={{
          display: 'flex',
          marginLeft: isWorkplanOrPhaseCalendar && !isWorkdays ? '20px' : '0px'
        }}
      >
        {label}
      </div>
      <div style={{ position: 'relative' }}>
        <input
          className="input"
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          data-testid={`input-${label}`}
          calendarLabel={label}
        />
        {hasDependency && !isWorkdays && (
          <IconContainer isDependency data-testid={`dependency-icon-${label}`}>
            <DependencyLinkIcon />
          </IconContainer>
        )}
      </div>
    </InputDiv>
  );
};

export default Input;
