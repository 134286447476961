import { useContext } from 'react';
import styled from 'styled-components';
import { ItemRenderer } from '../types';
import { ProjectBarBase } from 'views/projectPlanner/WorkPlanItemRenderers/styles';
import { getDragTooltipDates } from 'appUtils/projectPlannerUtils';
import {
  DOUBLE_HANDLE_WIDTH,
  HANDLE_MIN_WIDTH,
  MENU_SIZE,
  SCHEDULE_BAR_FIRST_CHILD_BORDER,
  SCHEDULE_BAR_LAST_CHILD_BORDER
} from 'views/projectPlanner/WorkPlanItemRenderers/constants';
import { ItemDatesTooltipRenderer } from './ItemDatesTooltipRenderer';
import theme from 'theme';
import { ResizeHandle } from '../ResizeHandle';
import { ProjectInfo } from './ProjectInfo';
import { PhaseInfo } from './PhaseInfo';
import { WorkCategoryInfo } from './WorkCategoryInfo';
import moment from 'moment';
import { HelpersContext } from 'react-calendar-timeline';
import cn from 'classnames';
import { WorkPlanItem } from 'TimelinesModule/types/WorkPlanItem';
import { StickyContainer } from './StickyContainer';
import PartialDayIndicator from 'views/projectPlanner/PartialDayIndicator';
import { TotalHoursText } from './TotalHoursText';
import NoteIcon from 'icons/NoteIcon';
import { DailyPlannedText } from './DailyPlannedText';
import { LockHour } from 'views/projectPlanner/WorkPlanItemRenderers/SubComponents/LockHour';
import { useWorkPlanItemMenu } from './WorkPlanItemMenuProvider';
import { ThreeDotMenu } from '../../ThreeDotMenu';
import { Menus } from 'TimelinesModule/components/ThreeDotMenu/Menus';
import { DisplayMemberCapacityOption } from 'TimelinesModule/types/timelineSettings';
import { useTimelineConfig } from 'TimelinesModule/components/Timelines/providers/TimelineConfigProvider';

type WorkPlanItemRendererProps = {
  displayMemberCapacity?: DisplayMemberCapacityOption;
};

export const WorkPlanItemRenderer: ItemRenderer<
  WorkPlanItem,
  WorkPlanItemRendererProps
> = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  startDateRef,
  endDateRef,
  displayMemberCapacity
}) => {
  const { getLeftOffsetFromDate, getItemAbsoluteDimensions } =
    useContext(HelpersContext);

  const { readOnly } = useTimelineConfig();

  const { activeBarIndex, activeItem, openMenu, closeMenu, menus } =
    useWorkPlanItemMenu();

  const handleOpenMenu = (barIndex: number) => () => {
    openMenu({ item, barIndex });
  };

  const { left = 0 } = getItemAbsoluteDimensions(item.id) || {};

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle,
    rightStyle
  });

  const {
    hasStartDependency,
    hasEndDependency,
    hasLastDayRemainder,
    dailyHours,
    totalHours,
    totalDayCount,
    averageCapacity,
    workdayPercent,
    allDay,
    startTime,
    endTime,
    hasLockHour,
    projectId,
    projectName,
    projectDescription,
    phaseName,
    workCategoryName,
    isTentativePlan,
    hasDescription
  } = item;

  const itemProps = getItemProps({
    ...item.itemProps,
    className: 'mosaic-project-schedule-bar',
    style:
      itemContext.dragging || itemContext.resizing ? draggingStyle : noStyle
  });

  // The total width of the bar.
  const width = parseFloat(itemProps.style.width?.toString() ?? '');

  // The width of the horizontal borders of the bar.
  const bordersWidth =
    SCHEDULE_BAR_FIRST_CHILD_BORDER + SCHEDULE_BAR_LAST_CHILD_BORDER;

  // The width of the bar without the borders.
  const widthWithoutBorders = Math.max(width - bordersWidth, 0);

  // A flag indicating whether the bar is in narrow mode. The inner width of
  // the bar is the width without the borders. Both resize handles are in this
  // area by default. If the inner width is less than the minimum width of the
  // two resize handles, the bar changes to narrow mode.
  const narrow = widthWithoutBorders < 2 * HANDLE_MIN_WIDTH;

  const hasEnoughSpaceForDragHandleBar =
    widthWithoutBorders / 2 >= DOUBLE_HANDLE_WIDTH;

  const tooltipDates = getDragTooltipDates({ itemContext, item });

  const shouldShowDailyPlannedTextOnMovingBar =
    width >= 60 &&
    averageCapacity !== undefined &&
    workdayPercent !== undefined &&
    dailyHours !== undefined;
  const shouldShowTotalHoursTextOnMovingBar =
    width >= 120 && dailyHours !== undefined && totalHours !== undefined;

  const isMoving = itemContext.dragging || itemContext.resizing;

  const commonClassName = cn('project-schedule-bar-metadata', {
    moving: isMoving,
    // inactive: !isMoving && item.phase_is_inactive,
    tentative: isTentativePlan
  });

  const commonBarProps = {
    dataTestid: 'schedule-bar',
    // Bar coloring
    $isHeatmapColored: false,
    originType: 'project',
    styleId: projectId
  };

  if (isMoving) {
    return (
      <div {...itemProps}>
        <WorkPlanBar
          {...commonBarProps}
          className={cn(commonClassName, { narrow })}
          key="ghost-drag"
        >
          {/* when moving show ghost drag */}
          <ItemDatesTooltipRenderer
            ref={startDateRef}
            type="start"
            date={tooltipDates.startDate}
          />
          <ItemDatesTooltipRenderer
            ref={endDateRef}
            type="end"
            date={tooltipDates.endDate}
          />
          <>
            {/* render top row */}
            <TopRow>
              {projectName ? (
                <StickyContainer>
                  <ProjectInfo
                    projectName={projectName}
                    description={projectDescription}
                  />
                </StickyContainer>
              ) : (
                <>&#x200b;</>
              )}
            </TopRow>
            {/* render middle row */}
            <MiddleRow>
              <StickyContainer>
                {phaseName && <PhaseInfo phaseName={phaseName} />}
                {workCategoryName && (
                  <WorkCategoryInfo workCategoryName={workCategoryName} />
                )}
                {!phaseName && !workCategoryName && <>&#x200b;</>}
              </StickyContainer>
            </MiddleRow>
            {/* render bottom row */}
            <BottomRow>
              <StickyContainer>
                <BottomLeft>
                  <NoteIcon
                    width={'10'}
                    height={'10'}
                    color={theme.colors.colorSemiDarkGray1}
                  />
                  {
                    /* Force a vertical space if nothing is rendered. */
                    shouldShowDailyPlannedTextOnMovingBar ? (
                      <DailyPlannedText
                        displayMemberCapacity={displayMemberCapacity}
                        allDay={allDay}
                        averageCapacity={averageCapacity}
                        workdayPercent={workdayPercent}
                        dailyHours={dailyHours}
                        startTime={startTime}
                        endTime={endTime}
                      />
                    ) : (
                      <>&#x200b;</>
                    )
                  }
                </BottomLeft>
              </StickyContainer>
              <BottomRight>
                {hasLastDayRemainder && <PartialDayIndicator remainder={1} />}
                {shouldShowTotalHoursTextOnMovingBar && (
                  <TotalHoursText
                    dayCount={totalDayCount}
                    totalHours={totalHours}
                    dailyHours={dailyHours}
                    totalDayCount={totalDayCount}
                  />
                )}
                {hasLockHour && <LockHour />}
              </BottomRight>
            </BottomRow>
          </>
        </WorkPlanBar>
      </div>
    );
  }

  return (
    <div {...itemProps}>
      {/* render multiple bars */}
      {item.bars.map(
        (
          { start_date: startDate, end_date: endDate, day_count: dayCount },
          index
        ) => {
          const lastBarIndex = item.bars.length - 1;

          const isFirstBar = index === 0;
          const isLastBar = index === lastBarIndex;

          //   The -1 pixel on `itemRight` is to exclude the right border of the cell.
          const fitStartDate = moment(startDate).startOf('day').valueOf();
          const fitEndDate = moment(endDate).endOf('day').valueOf();
          const itemLeft = getLeftOffsetFromDate(fitStartDate) - left;
          const itemRight = getLeftOffsetFromDate(fitEndDate) - left - 1;

          const width = itemRight - itemLeft;

          // The width of the horizontal borders of the subbar. This subbar
          // only has a left border if it is the first subbar and only has a
          // right border if it is the last subbar.
          const bordersWidth =
            (index === 0 ? SCHEDULE_BAR_FIRST_CHILD_BORDER : 0) +
            (index === lastBarIndex ? SCHEDULE_BAR_LAST_CHILD_BORDER : 0);

          // The width of the subbar without the borders.
          const widthWithoutBorders = Math.max(width - bordersWidth, 0);

          // The width of the subbar without the borders and the horizontal
          // padding of the subbar.
          const contentWidth = Math.max(
            widthWithoutBorders - 2 * ROW_PADDING,
            0
          );

          // A flag indicating whether the subbar is in narrow mode. The
          // inner width of the subbar is the width without the borders. Both
          // resize handles are in this area by default. If the inner width
          // is less than the minimum width of the two resize handles, the
          // subbar changes to narrow mode.
          const narrow = widthWithoutBorders < 2 * HANDLE_MIN_WIDTH;

          const shouldShowThreeDotMenu = !readOnly && contentWidth >= MENU_SIZE;
          const shouldShowDailyPlannedText =
            width >= 60 &&
            averageCapacity !== undefined &&
            workdayPercent !== undefined &&
            dailyHours !== undefined;
          const shouldShowTotalHoursText =
            width >= 120 &&
            dailyHours !== undefined &&
            totalHours !== undefined;

          const isMenuOpen =
            item.id === activeItem?.id && index === activeBarIndex;

          if (dayCount === 0) {
            return null;
          }

          return (
            <WorkPlanBar
              key={startDate}
              {...commonBarProps}
              className={cn(commonClassName, { narrow })}
              style={{ left: itemLeft, width }}
              data-class="project-bars-tooltips"
              data-delay-show={1000}
              data-effect="float"
              data-for="schedule-bar"
              data-html
              // data-tip={barTooltipContent}
              data-type="light"
            >
              {/* render resize handles */}
              {isFirstBar && itemContext.canResizeLeft && (
                <ResizeHandle resizeProps={leftResizeProps} isNarrow={narrow}>
                  {hasStartDependency ? (
                    <LeftDependencyCrossHatch />
                  ) : (
                    <>
                      <DragHandleBar className="left" />
                      {hasEnoughSpaceForDragHandleBar && (
                        <DragHandleBar className="left" />
                      )}
                    </>
                  )}
                </ResizeHandle>
              )}
              {isLastBar && itemContext.canResizeRight && (
                <ResizeHandle resizeProps={rightResizeProps} isNarrow={narrow}>
                  {hasEndDependency ? (
                    <RightDependencyCrossHatch />
                  ) : (
                    <>
                      <DragHandleBar className="right" />
                      {hasEnoughSpaceForDragHandleBar && (
                        <DragHandleBar className="right" />
                      )}
                    </>
                  )}
                </ResizeHandle>
              )}
              <>
                {/* render top row */}
                <TopRow>
                  <StickyContainer>
                    {projectName ? (
                      <ProjectInfo
                        projectName={projectName}
                        description={projectDescription}
                      />
                    ) : (
                      <>&#x200b;</>
                    )}
                  </StickyContainer>
                  {shouldShowThreeDotMenu && (
                    <ThreeDotMenu
                      isOpen={isMenuOpen}
                      onOpen={handleOpenMenu(index)}
                      onClose={closeMenu}
                    >
                      <Menus onClose={closeMenu} menus={menus} />
                    </ThreeDotMenu>
                  )}
                </TopRow>
                {/* render middle row */}
                <MiddleRow>
                  <StickyContainer>
                    <MiddleRowStickyContainer>
                      {phaseName && <PhaseInfo phaseName={phaseName} />}
                      {workCategoryName && (
                        <WorkCategoryInfo workCategoryName={workCategoryName} />
                      )}
                      {!phaseName && !workCategoryName && <>&#x200b;</>}
                    </MiddleRowStickyContainer>
                  </StickyContainer>
                </MiddleRow>
                {/* render bottom row */}
                <BottomRow>
                  <StickyContainer>
                    <BottomLeft>
                      {hasDescription && (
                        <NoteIcon
                          width="10"
                          height="10"
                          color={theme.colors.colorSemiDarkGray1}
                        />
                      )}
                      {
                        /* Force a vertical space if nothing is rendered. */
                        shouldShowDailyPlannedText ? (
                          <DailyPlannedText
                            displayMemberCapacity={displayMemberCapacity}
                            allDay={allDay}
                            averageCapacity={averageCapacity}
                            workdayPercent={workdayPercent}
                            dailyHours={dailyHours}
                            startTime={startTime}
                            endTime={endTime}
                          />
                        ) : (
                          <>&#x200b;</>
                        )
                      }
                    </BottomLeft>
                  </StickyContainer>
                  <BottomRight>
                    {isLastBar && hasLastDayRemainder && (
                      <PartialDayIndicator remainder={1} />
                    )}
                    {shouldShowTotalHoursText && (
                      <TotalHoursText
                        dayCount={dayCount}
                        totalHours={totalHours}
                        dailyHours={dailyHours}
                        totalDayCount={totalDayCount}
                      />
                    )}
                    {hasLockHour && <LockHour />}
                  </BottomRight>
                </BottomRow>
              </>
              {/* render split preview */}
              {/* {isSplitting && (
                  <SplitPreview
                    startDate={fitStartDate}
                    endDate={fitEndDate}
                    itemFitToScaleValue={itemFitToScaleValue}
                    itemId={deserializedId}
                    handleScheduleBarSplit={handleScheduleBarSplit}
                  />
                )} */}
            </WorkPlanBar>
          );
        }
      )}
    </div>
  );
};

const noStyle = {
  alignItems: 'center',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'flex'
};

const draggingStyle = {
  alignItems: 'center',
  border: 'none',
  backgroundColor: theme.colors.colorIceBlue,
  cursor: 'grabbing',
  display: 'flex'
};

const leftStyle = {
  alignItems: 'center',
  cursor: 'w-resize',
  display: 'flex',
  justifyContent: 'flex-start',
  maxWidth: '50%',
  width: 10
};

const rightStyle = {
  alignItems: 'center',
  cursor: 'e-resize',
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '50%',
  width: 10
};

const WorkPlanBar = styled(ProjectBarBase)`
  flex-direction: column;
  height: calc(100% - 12px);

  &:hover {
    .three-dot-menu {
      display: inline-flex;
    }
  }
`;

const DependencyCrossHatch = styled.div`
  background-image: linear-gradient(
    180deg,
    var(--border-color) 12.5%,
    white 12.5%,
    white 50%,
    var(--border-color) 50%,
    var(--border-color) 62.5%,
    white 62.5%,
    white 100%
  );
  background-size: 8px 8px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  max-width: 20%;
  min-width: 7px;
  position: absolute;
  width: 13px;
`;

const LeftDependencyCrossHatch = styled(DependencyCrossHatch)`
  border-bottom-left-radius: 3px;
  border-right: 1px solid var(--border-color);
  border-top-left-radius: 3px;
  left: -2px;
`;

const RightDependencyCrossHatch = styled(DependencyCrossHatch)`
  border-bottom-right-radius: 3px;
  border-left: 1px solid var(--border-color);
  border-top-right-radius: 3px;
  right: 0px;
`;

const DragHandleBar = styled.div`
  display: none;
  height: 10px;
  width: 2px;
  background: black;
  .left {
    margin-left: 2px;
  }
  .right {
    margin-right: 2px;
  }
`;

const ROW_PADDING = 8;

const RowContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 ${ROW_PADDING}px;
  position: relative;
`;

const TopRow = styled(RowContainer)`
  gap: 5px;
`;

const MiddleRow = styled(RowContainer)`
  align-items: flex-start;
`;

const MiddleRowStickyContainer = styled(StickyContainer)`
  gap: 4px;
`;

const BottomRow = styled(RowContainer)`
  font-size: 11px;
  gap: 5px;
  justify-content: end;

  .moving & {
    justify-content: start;
  }
`;

const BottomLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  gap: 6px;
  padding-left: 1px;
`;

const BottomRight = styled.div`
  align-items: center;
  display: flex;
  flex: none;
`;
