import React, { useState, useEffect, useMemo } from 'react';
import theme from 'theme';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import CustomBar from '../shared/CustomBar';
import XTickValue from '../shared/XTickValue';
import { DATA_KEY, CAPACITY_KEY } from 'UtilizationModule/constants';
import useHasStateChanged from 'appUtils/hooks/useHasStateChanged';
import LegendStyleTooltip from 'components/Tooltips/LegendStyleTooltip';
import { getTooltipContent } from 'ReportsModule/components/Utilization/utils';

const EmptyDiv = () => <div />;

const UtilizationBarChart = ({ data, zoomLevel, includeRemainingCapacity }) => {
  const [tooltipProps, setTooltipProps] = useState({});
  const hideTooltip = () => setTooltipProps({});
  const [zoomLevelToUse, setZoomLevelToUse] = useState(zoomLevel);
  const hasDataChanged = useHasStateChanged({ data });

  const formattedData = useMemo(
    () =>
      data.map((singleData) => ({
        ...singleData,
        totalPercent:
          (Math.max(singleData.totalHours, 0) /
            singleData[CAPACITY_KEY.CAPACITY_FOR_PERCENTAGE]) *
          100
      })),
    [data]
  );

  const formattedTooltipData = useMemo(
    () => formattedData.map((singleData) => getTooltipContent(singleData)),
    [formattedData]
  );

  const handleSetTooltipProps = (props) => {
    if (tooltipProps.index !== props.index) {
      setTooltipProps({
        data: formattedTooltipData[props.index].data,
        summary: formattedTooltipData[props.index].summary,
        showBorder: true,
        index: props.index
      });
    }
  };

  useEffect(() => {
    if (hasDataChanged) {
      setZoomLevelToUse(zoomLevel);
    }
  }, [hasDataChanged, zoomLevel]);

  return (
    <BarChart
      width={792}
      height={473}
      data={data}
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 30
      }}
      barSize={25}
      barGap={1}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis
        dataKey="start_date"
        interval={0}
        tickSize={12}
        tick={<XTickValue zoomLevel={zoomLevelToUse} data={data} />}
        tickLine={false}
      />
      <YAxis
        type="number"
        tickCount={11}
        interval={0}
        allowDecimals={false}
        // Math.round will fix floating point precision.
        tickFormatter={(num) => Math.round(num) + '%'}
        tickLine={false}
        domain={[0, 100]}
      />

      {/* ---------------------------- BILLABLE -------------------------- */}
      <Bar
        dataKey={DATA_KEY.BILLABLE_PERCENT}
        stackId={1}
        fill={theme.colors.colorConnectedGreen}
        isAnimationActive={false}
        shape={(props) => {
          return (
            <CustomBar
              {...props}
              labelColor={'white'}
              dataKey={DATA_KEY.BILLABLE_PERCENT}
              onHover={() => handleSetTooltipProps(props)}
              type="percent"
              onHoverOut={hideTooltip}
              isHoveringBar={tooltipProps.index === props.index}
              includeRemainingCapacity={includeRemainingCapacity}
            />
          );
        }}
      />

      {/* ------------------------ NON-BILLABLE ------------------------ */}
      <Bar
        dataKey={DATA_KEY.NONBILLABLE_PERCENT}
        stackId={1}
        fill={theme.colors.colorLightGray20}
        isAnimationActive={false}
        shape={(props) => {
          return (
            <CustomBar
              {...props}
              dataKey={DATA_KEY.NONBILLABLE_PERCENT}
              onHover={() => handleSetTooltipProps(props)}
              type="percent"
              onHoverOut={hideTooltip}
              isHoveringBar={tooltipProps.index === props.index}
              includeRemainingCapacity={includeRemainingCapacity}
            />
          );
        }}
      />

      {/* ------------------------ CAPACITY ------------------------ */}
      {includeRemainingCapacity && (
        <Bar
          dataKey={CAPACITY_KEY.REMAINING_CAPACITY_PERCENT}
          stackId={1}
          fill={theme.colors.colorPaleGray5}
          isAnimationActive={false}
          shape={(props) => {
            if (props.payload[CAPACITY_KEY.REMAINING_CAPACITY_PERCENT] <= 0)
              return null;
            return (
              <CustomBar
                {...props}
                dataKey={CAPACITY_KEY.REMAINING_CAPACITY_PERCENT}
                onHover={() => handleSetTooltipProps(props)}
                type="percent"
                onHoverOut={hideTooltip}
                isHoveringBar={tooltipProps.index === props.index}
                includeRemainingCapacity={includeRemainingCapacity}
              />
            );
          }}
        />
      )}

      {/* ---------------------------- PTO ---------------------------- */}
      <Bar
        dataKey={DATA_KEY.PTO_PERCENT}
        stackId={1}
        fill={theme.colors.colorPaleBlue4}
        isAnimationActive={false}
        shape={(props) => {
          return (
            <CustomBar
              {...props}
              dataKey={DATA_KEY.PTO_PERCENT}
              onHover={() => handleSetTooltipProps(props)}
              type="percent"
              onHoverOut={hideTooltip}
              isHoveringBar={tooltipProps.index === props.index}
              includeRemainingCapacity={includeRemainingCapacity}
            />
          );
        }}
      />

      {/* --------------------------- HOLIDAYS --------------------------- */}
      <Bar
        dataKey={DATA_KEY.HOLIDAY_PERCENT}
        stackId={1}
        fill={theme.colors.colorLightGray6}
        isAnimationActive={false}
        shape={(props) => {
          return (
            <CustomBar
              {...props}
              dataKey={DATA_KEY.HOLIDAY_PERCENT}
              onHover={() => handleSetTooltipProps(props)}
              type="percent"
              onHoverOut={hideTooltip}
              isHoveringBar={tooltipProps.index === props.index}
              includeRemainingCapacity={includeRemainingCapacity}
            />
          );
        }}
      />

      <Tooltip
        cursor={false}
        content={
          tooltipProps.data ? (
            <LegendStyleTooltip
              className="utilization-report-tooltip"
              isReactTooltip={false}
              {...tooltipProps}
            />
          ) : (
            <EmptyDiv />
          )
        }
        isAnimationActive={false}
      />
    </BarChart>
  );
};

export default React.memo(UtilizationBarChart);
