import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

export const Square = styled.div`
  background: ${(props) =>
    props.filled
      ? props.fillColor || theme.colors.colorCalendarBlue
      : props.emptyColor || theme.colors.colorMediumGray14};
  height: ${(props) => props.size || props.height || 7}px;
  width: ${(props) => props.size || props.width || 7}px;

  &:not(:last-child) {
    margin-right: ${(props) => props.spaceBetween || 2}px;
  }
`;

export const LevelContainer = styled.div`
  display: flex;
`;

const LevelIndicator = ({
  fillColor,
  level,
  levelToColor = {},
  numLevels,
  ...props
}) => {
  return (
    <LevelContainer {...props}>
      {[...Array(numLevels)].map((_, index) => {
        const Component = props.component || Square;
        return (
          <Component
            filled={index < level}
            fillColor={fillColor || levelToColor[index]}
            {...props}
            key={index}
          />
        );
      })}
    </LevelContainer>
  );
};

export default React.memo(LevelIndicator);
