import React from 'react';
import { connect } from 'react-redux';

import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import PhaseTemplatesDropdown from 'BudgetModule/components/BudgetModal/PhaseTemplatesDropdown';
import { DropdownWrapper, MenuContainer, ThreeDotMenuListItem } from './styles';
import {
  navigateToProject,
  openEditProjectModal,
  fetchPhases
} from 'actionCreators';
import { getTeamSlug } from 'selectors';
import Popover from 'components/Popover';

class TimesheetThreeDotMenu extends React.Component {
  state = {
    addingPhase: false,
    threeDotMenuOpen: false
  };

  handleRemove = () => {
    const { handleDeleteClick, isDeleting } = this.props;
    handleDeleteClick();
  };

  navToProject = () => {
    const { navigateToProject, projectId, teamSlug, projectSlug } = this.props;
    navigateToProject({
      teamSlug,
      projectSlug,
      projectId
    });
  };

  menu = React.createRef();

  setAdding = () => {
    const { fetchPhases, projectId } = this.props;
    fetchPhases({ projectId });
    this.setState({ addingPhase: true });
  };

  clearAdding = () => this.setState({ addingPhase: false });
  openProjectInfoModal = () => {
    const { projectId, isPersonal, openEditProjectModal } = this.props;
    openEditProjectModal(projectId, isPersonal, true);
  };

  handleOpenMenu = (e) => {
    e.stopPropagation();
    this.setState({ threeDotMenuOpen: true });
  };

  handleCloseMenu = () => this.setState({ threeDotMenuOpen: false });

  handleClickMenu = (callbackFn) => () => {
    this.handleCloseMenu();
    callbackFn && callbackFn();
  };

  render() {
    const { addingPhase, threeDotMenuOpen: isOpen } = this.state;
    const { projectId, size } = this.props;

    return (
      <MenuContainer
        className="menu-container"
        ref={this.menu}
        onClick={this.handleOpenMenu}
      >
        <ProjectThreeDotMenu
          vertical
          initialColor="#a4a4a4"
          threeDotClassName="project-three-dot-icon"
          onCloseCallback={this.handleCloseMenu}
          size={size}
        >
          <Popover
            isOpen={isOpen}
            target={this.menu}
            closePopover={this.handleCloseMenu}
            className="styled-member-popover"
          >
            <DropdownWrapper>
              <ThreeDotMenuListItem
                onClick={this.handleClickMenu(this.navToProject)}
              >
                Go To Project
              </ThreeDotMenuListItem>
              <ThreeDotMenuListItem
                onClick={this.handleClickMenu(this.openProjectInfoModal)}
              >
                View Project Info
              </ThreeDotMenuListItem>
              <ThreeDotMenuListItem
                onClick={this.handleClickMenu(this.setAdding)}
              >
                View Project Phases
              </ThreeDotMenuListItem>
              <ThreeDotMenuListItem
                onClick={this.handleClickMenu(this.handleRemove)}
              >
                Delete Time Entry
              </ThreeDotMenuListItem>
            </DropdownWrapper>
          </Popover>
        </ProjectThreeDotMenu>
        {addingPhase && (
          <PhaseTemplatesDropdown
            handleClose={this.clearAdding}
            isNew
            projectId={projectId}
            target={this.menu}
            customStyleClass={'timesheet-add-phases-dropdown'}
            isOldPhaseMenu
          />
        )}
      </MenuContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  teamSlug: getTeamSlug(state)
});

const mapDispatchToProps = {
  navigateToProject,
  fetchPhases,
  openEditProjectModal
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetThreeDotMenu);
