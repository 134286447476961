import AddViewTasksIcon from 'images/addviewtasksicon.svg';

// DO NOT USE 20. 20 is only removed from report page
export const moduleTextHash = {
  1: 'Time',
  2: 'Budgets',
  3: 'Workload',
  4: 'Utilization',
  5: 'Activity',
  6: 'Profit',
  7: 'Variance',
  8: 'Budget Variance',
  9: 'Timesheet Status',
  10: 'Capacity',
  11: 'Workload Forecast',
  12: 'Billing Forecast',
  13: 'Schedule Variance',
  14: 'Skills',
  15: 'Check Ins',
  16: 'All Projects',
  17: 'PTO',
  18: 'Time Projection'
};
// DO NOT USE 20. 20 is only removed from report page
export const moduleHash = {
  1: 'time',
  2: 'budget',
  3: 'capacity',
  4: 'utilization',
  5: 'activity',
  6: 'profit',
  7: 'variance',
  8: 'budgetvariance',
  9: 'timesheetstatus',
  10: 'demand',
  11: 'workloadforecast',
  12: 'billingforecast',
  13: 'schedulevariance',
  14: 'skills',
  15: 'checkins',
  16: 'allprojects',
  17: 'pto',
  18: 'timeprojection',
  21: 'spenttime',
  22: 'plannedtime',
  23: 'unspentbudget'
};

export const defaultNavModuleIds = [
  1, 2, 3, 4, 10, 18, 7, 8, 13, 9, 11, 12, 14, 15, 16, 17, 6, 21, 22, 23
];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

// IDs of the reports that should not be displayed because hours only setting is on.
export const DOLLAR_ONLY_REPORTS_IDS = [6, 8, 4];

export const moduleDescriptionHash = {
  1: 'Create Timesheet Reports',
  2: 'Create Budget Reports',
  3: 'Create Work Plan Reports',
  4: 'Create Utilization Reports',
  5: 'View Activity Table',
  6: 'View Profit Report',
  7: 'View Variance Report',
  8: 'View Timesheet Status Report',
  9: 'View Capacity Report',
  10: 'View Budget Variance',
  11: 'View Workload Forecast',
  12: 'View Billing Forecast',
  13: 'View Schedule Variance',
  14: 'View Skills Report',
  15: 'View Check Ins Report',
  16: 'View All Projects Report',
  17: 'View PTO Report'
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#78c946',
  3: '#78c946',
  4: '#78c946',
  5: '#78c946',
  6: '#78c946',
  7: '#78c946',
  8: '#78c946',
  9: '#78c946',
  10: '#78c946',
  11: '#78c946',
  12: '#78c946',
  13: '#78c946',
  14: '#78c946',
  15: '#78c946',
  16: '#78c946',
  17: '#78c946'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon})`,
  2: `url(${AddViewTasksIcon})`,
  3: `url(${AddViewTasksIcon})`,
  4: `url(${AddViewTasksIcon})`,
  5: `url(${AddViewTasksIcon})`,
  6: `url(${AddViewTasksIcon})`,
  7: `url(${AddViewTasksIcon})`,
  8: `url(${AddViewTasksIcon})`,
  9: `url(${AddViewTasksIcon})`,
  10: `url(${AddViewTasksIcon})`,
  11: `url(${AddViewTasksIcon})`,
  12: `url(${AddViewTasksIcon})`,
  13: `url(${AddViewTasksIcon})`,
  14: `url(${AddViewTasksIcon})`,
  15: `url(${AddViewTasksIcon})`,
  16: `url(${AddViewTasksIcon})`,
  17: `url(${AddViewTasksIcon})`
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
