import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';
import NewProjectsIcon from 'icons/NewProjectsIcon';
import Popover from 'components/Popover';
import { navigateToProject, navigateToReport } from 'actionCreators';
import { getProjectHash, getTeamSlug } from 'selectors';

const BoardSettings = styled.div`
  display: flex;
  justify-content: space-around;
  .threeDotMenu {
    position: absolute;
    left: -4px;
    top: 20px;
    width: 20px;
    height: 20px;
    background: ${theme.colors.colorCalendarBlue};
    svg {
      width: 20px;
    }
    path {
      fill: ${theme.colors.colorPureWhite};
      stroke: ${theme.colors.colorPureWhite};
    }
  }
`;

const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  padding: 0px 16px 0px 16px;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 18px;
  white-space: nowrap;
  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    cursor: pointer;
  }
`;

const DropdownWrapper = styled.div`
  padding: 0px;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 8.5px;
`;

const ThreeDotCell = ({ row }) => {
  const { userActivity } = row.original;
  const projectHash = useSelector(getProjectHash);
  const teamSlug = useSelector(getTeamSlug);
  const projectId = userActivity?.display_data?.time_entry?.project_id;
  const project = projectId ? projectHash[projectId] : null;
  const popoverTarget = useRef(null);
  const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);
  const {
    customRowProps: { isSyncLogTable }
  } = row.original;

  const dispatch = useDispatch();

  const handleOpenContextMenu = (e) => {
    setContextMenuIsOpen(true);
    e.stopPropagation();
  };

  const handleCloseContextMenu = () => {
    setContextMenuIsOpen(false);
  };

  const handleViewProject = () => {
    if (!project || !teamSlug || !project.slug) {
      return;
    }
    dispatch(
      navigateToProject({
        projectId: project.id,
        teamSlug,
        projectSlug: project.slug,
        openInNewWindow: true
      })
    );
    handleCloseContextMenu();
  };

  const handleViewTimesheet = () => {
    // set filters accordingly
    dispatch(
      navigateToReport({
        teamSlug,
        viewType: 'time'
      })
    );
    handleCloseContextMenu();
  };

  /** Sync Log Table methods */

  const handleViewActivity = () => {
    dispatch(
      navigateToReport({
        teamSlug,
        viewType: 'time',
        reportViewType: 'activity'
      })
    );
    handleCloseContextMenu();
  };

  return (
    <div>
      <BoardSettings
        onClick={(e) => handleOpenContextMenu(e)}
        ref={popoverTarget}
      >
        <ProjectThreeDotMenu
          threeDotClassName="threeDotMenu"
          onCloseCallback={handleCloseContextMenu}
        >
          <Popover
            className="activity-member-context-menu"
            isOpen={contextMenuIsOpen}
            target={popoverTarget}
            closePopover={handleCloseContextMenu}
          >
            <DropdownWrapper>
              {isSyncLogTable && (
                <MenuListItem onClick={handleViewTimesheet}>
                  <IconContainer style={{ marginLeft: 3 }}>
                    <GoToProjectIcon
                      currentColor={'#4f4f4f'}
                      height="11px"
                      width="11px"
                      viewBox="6 6 11 11"
                      color={theme.colors.colorMediumGray9}
                    />
                  </IconContainer>
                  Go To Timesheet
                </MenuListItem>
              )}

              {project && teamSlug && project.slug && (
                <MenuListItem onClick={handleViewProject}>
                  <IconContainer style={{ marginLeft: 4, marginRight: 9.5 }}>
                    <NewProjectsIcon
                      height="12px"
                      width="9px"
                      strokeWidth="0.7"
                      color={theme.colors.colorMediumGray9}
                    />
                  </IconContainer>
                  View Project
                </MenuListItem>
              )}

              {/* Only visible in Sync Log Table */}
              {isSyncLogTable && (
                <MenuListItem onClick={handleViewActivity}>
                  <IconContainer style={{ marginLeft: 1 }}>
                    <ActivityTimesheetIcon
                      height="11px"
                      width="13px"
                      strokeWidth="0.7"
                      color={theme.colors.colorMediumGray9}
                    />
                  </IconContainer>
                  View Activity
                </MenuListItem>
              )}
            </DropdownWrapper>
          </Popover>
        </ProjectThreeDotMenu>
      </BoardSettings>
    </div>
  );
};

export default ThreeDotCell;
