export const formatPhaseEntityNameWithCount = ({
  shouldShowCount,
  name,
  visibleMembersCount
}: {
  shouldShowCount: boolean;
  name: string;
  visibleMembersCount: number;
}) => {
  return shouldShowCount ? `${name} | ${visibleMembersCount}` : name;
};
