import * as constants from '../constants';
import { action } from 'appUtils';

export const initializeIntegration = {
  request: () => action(constants.INITIALIZE_INTEGRATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.INITIALIZE_INTEGRATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.INITIALIZE_INTEGRATION.FAILURE, { payload: { error } })
};
export const fetchIntegrationAccounts = {
  request: () => action(constants.FETCH_INTEGRATION_ACCOUNTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_ACCOUNTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION_ACCOUNTS.FAILURE, { payload: { error } })
};
export const mapAccounts = {
  request: () => action(constants.MAP_ACCOUNTS.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MAP_ACCOUNTS.SUCCESS, {
      payload: {
        response,
        requestPayload
      }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_ACCOUNTS.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMappedMosaicAccountIds = {
  request: () => action(constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.FAILURE, {
      payload: { error }
    })
};

export const fetchIntegrationActivities = {
  request: () => action(constants.FETCH_INTEGRATION_ACTIVITIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION_ACTIVITIES.FAILURE, {
      payload: { error }
    })
};
export const mapActivities = {
  request: () => action(constants.MAP_ACTIVITIES.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MAP_ACTIVITIES.SUCCESS, {
      payload: {
        response,
        requestPayload
      }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_ACTIVITIES.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMappedMosaicActivityIds = {
  request: () => action(constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.FAILURE, {
      payload: { error }
    })
};

export const fetchIntegrationClients = {
  request: () => action(constants.FETCH_INTEGRATION_CLIENTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_CLIENTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION_CLIENTS.FAILURE, {
      payload: { error }
    })
};
export const mapClients = {
  request: () => action(constants.MAP_CLIENTS.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MAP_CLIENTS.SUCCESS, {
      payload: {
        response,
        requestPayload
      }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_CLIENTS.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMappedMosaicClientIds = {
  request: () => action(constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.FAILURE, {
      payload: { error }
    })
};

export const fetchIntegrationProjects = {
  request: () => action(constants.FETCH_INTEGRATION_PROJECTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION_PROJECTS.FAILURE, { payload: { error } })
};
export const refetchIntegrationProject = {
  request: () => action(constants.REFETCH_INTEGRATION_PROJECT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.REFETCH_INTEGRATION_PROJECT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.REFETCH_INTEGRATION_PROJECT.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const mapProjects = {
  request: () => action(constants.MAP_PROJECTS.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MAP_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.MAP_PROJECTS.FAILURE, { payload: { error } })
};
export const fetchMappedMosaicProjectIds = {
  request: () => action(constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.FAILURE, {
      payload: { error }
    })
};

export const mapAllPhases = {
  request: () => action(constants.MAP_ALL_PHASES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.MAP_ALL_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_ALL_PHASES.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const mapPhases = {
  request: () => action(constants.MAP_PHASES.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MAP_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_PHASES.FAILURE, { payload: { error, requestPayload } })
};

export const fetchIntegrationPhases = {
  request: () => action(constants.FETCH_INTEGRATION_PHASES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION_PHASES.FAILURE, { payload: { error } })
};
export const fetchMappedMosaicPhaseIds = {
  request: () => action(constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.FAILURE, {
      payload: { error }
    })
};

export const mapAllActivities = {
  request: () => action(constants.MAP_ALL_ACTIVITIES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.MAP_ALL_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_ALL_ACTIVITIES.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const mapActivityPhases = {
  request: () => action(constants.MAP_ACTIVITY_PHASES.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MAP_ACTIVITY_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MAP_ACTIVITY_PHASES.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchMosaicTimeEntryMappingStatusesByIntegration = {
  request: () =>
    action(
      constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION.REQUEST
    ),
  success: (response, id, requestPayload) =>
    action(
      constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION.SUCCESS,
      {
        payload: { response, requestPayload }
      }
    ),
  failure: (error) =>
    action(
      constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION.FAILURE,
      {
        payload: { error }
      }
    )
};

export const linkIntegration = {
  request: () => action(constants.LINK_INTEGRATION.REQUEST),
  success: (response, requestPayload, sagaPayload) =>
    action(constants.LINK_INTEGRATION.SUCCESS, {
      payload: { response, requestPayload, sagaPayload }
    }),
  failure: (error) =>
    action(constants.LINK_INTEGRATION.FAILURE, { payload: { error } })
};

export const fetchAllIntegrationDomains = {
  request: () => action(constants.FETCH_ALL_INTEGRATION_DOMAINS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_ALL_INTEGRATION_DOMAINS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ALL_INTEGRATION_DOMAINS.FAILURE, {
      payload: { error }
    })
};
export const fetchIntegrations = {
  request: () => action(constants.FETCH_INTEGRATIONS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_INTEGRATIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATIONS.FAILURE, { payload: { error } })
};

export const fetchIntegration = {
  request: () => action(constants.FETCH_INTEGRATION.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_INTEGRATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION.FAILURE, { payload: { error } })
};

export const disconnectIntegration = {
  request: () => action(constants.DISCONNECT_INTEGRATION.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DISCONNECT_INTEGRATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DISCONNECT_INTEGRATION.FAILURE, { payload: { error } })
};

export const disconnectIntegrationV2 = {
  request: () => action(constants.DISCONNECT_INTEGRATION_V2.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DISCONNECT_INTEGRATION_V2.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DISCONNECT_INTEGRATION_V2.FAILURE, { payload: { error } })
};

export const fetchIntegrationAuthStatus = {
  request: () => action(constants.FETCH_INTEGRATION_AUTH_STATUS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_AUTH_STATUS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_INTEGRATION_AUTH_STATUS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const provisionIntegration = {
  request: () => action(constants.PROVISION_INTEGRATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.PROVISION_INTEGRATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.PROVISION_INTEGRATION.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const updateIntegrationConfig = {
  request: () => action(constants.UPDATE_INTEGRATION_CONFIG.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_INTEGRATION_CONFIG.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_INTEGRATION_CONFIG.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchIntegrationEntityStats = {
  request: () => action(constants.FETCH_INTEGRATION_ENTITY_STATS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_ENTITY_STATS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_INTEGRATION_ENTITY_STATS.FAILURE, {
      payload: { error }
    })
};

export const fetchIntegrationSettingsSchema = {
  request: () => action(constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.FAILURE, {
      payload: { error, response }
    })
};

export const fetchAutoLinkPhases = {
  request: () => action(constants.FETCH_AUTO_LINK_PHASES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_AUTO_LINK_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_AUTO_LINK_PHASES.FAILURE, {
      payload: { error, response }
    })
};

export const fetchAutoLinkChildrenByPhaseName = {
  request: () =>
    action(constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.FAILURE, {
      payload: { error, response }
    })
};

export const fetchSyncLog = {
  request: () => action(constants.FETCH_SYNC_LOG.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SYNC_LOG.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_SYNC_LOG.FAILURE, {
      payload: { error, response }
    })
};
