import {
  ParsedFilterInstanceSchema,
  FilterInstanceSchema
} from 'FilterModule/types';
import { parseFilterSchema } from './parseFilterSchema';

interface GetCurrentFilterInstanceSchemaParams {
  filterInstanceSchema: FilterInstanceSchema;
}

/**
 * Parses a FilterInstanceSchema and returns currentFilterSchema for a given filter instance (eg. for widget filter)
 */
export const parseFilterInstanceSchema = ({
  filterInstanceSchema
}: GetCurrentFilterInstanceSchemaParams) => {
  // default return value
  const parsedValues: ParsedFilterInstanceSchema = {
    mainFilter: null,
    fields: {},
    initialValues: {}
  };

  const { fieldSchemas } = filterInstanceSchema;

  // Add values to parsedValues.fields
  parseFilterSchema({ schema: filterInstanceSchema, parsedValues });

  // Add default values to initialValues
  Object.keys(fieldSchemas).forEach((field) => {
    const defaultValue = fieldSchemas[field]?.defaultValue;
    if (defaultValue !== undefined) {
      parsedValues.initialValues[field] = defaultValue;
    }
  });

  return parsedValues;
};
