import { fork, takeLatest, takeEvery, all } from 'redux-saga/effects';
import {
  initializeIntegrationWorker,
  fetchIntegrationAccountsWorker,
  mapAccountsWorker,
  fetchMappedMosaicAccountIdsWorker,
  fetchIntegrationActivitiesWorker,
  mapActivitiesWorker,
  fetchMappedMosaicActivityIdsWorker,
  fetchIntegrationClientsWorker,
  mapClientsWorker,
  fetchMappedMosaicClientIdsWorker,
  fetchIntegrationProjectsWorker,
  mapProjectsWorker,
  fetchMappedMosaicProjectIdsWorker,
  mapPhasesWorker,
  fetchMappedMosaicPhaseIdsWorker,
  mapActivityPhasesWorker,
  refetchIntegrationProjectWorker,
  mapAllPhasesWorker,
  mapAllActivitiesWorker,
  fetchIntegrationsWorker,
  fetchIntegrationWorker,
  disconnectIntegrationWorker,
  fetchIntegrationAuthStatusWorker,
  provisionIntegrationWorker,
  disconnectIntegrationV2Worker,
  updateIntegrationConfigWorker,
  fetchMosaicTimeEntryMappingStatusesWorker,
  fetchMosaicTimeEntryMappingStatusesByIntegrationWorker,
  fetchIntegrationEntityStatsWorker,
  fetchIntegrationSettingsSchemaWorker,
  fetchAutoLinkPhasesWorker,
  fetchAutoLinkChildrenByPhaseNameWorker,
  fetchSyncLogWorker,
  fetchWorkspacesWorker,
  fetchMappingStatusesWorker
} from './integrations';
import { fetchMappingStatuses } from 'IntegrationsModule/actionCreators';
import * as constants from '../constants';
import { createChannel } from 'sagas/channels';
import { WORKSPACE_ACTIONS_CONST } from 'IntegrationsModule/actionCreators/workspace';

const matchIntegrationPattern = (integration) => (constant) => (action) =>
  action.type === constant && action.payload?.integrationId === integration;

// Target service specific
const makeIntegrationSaga = (integration) => {
  const integrationPattern = matchIntegrationPattern(integration);
  function* integrationsSaga() {
    const integrationsSagas = [
      yield fork(
        takeEvery,
        integrationPattern(constants.INITIALIZE_INTEGRATION.TRIGGER),
        initializeIntegrationWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_INTEGRATION_ACCOUNTS.TRIGGER),
        fetchIntegrationAccountsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_ACCOUNTS.TRIGGER),
        mapAccountsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.TRIGGER),
        fetchMappedMosaicAccountIdsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_INTEGRATION_ACTIVITIES.TRIGGER),
        fetchIntegrationActivitiesWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_ACTIVITIES.TRIGGER),
        mapActivitiesWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.TRIGGER),
        fetchMappedMosaicActivityIdsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_INTEGRATION_CLIENTS.TRIGGER),
        fetchIntegrationClientsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_CLIENTS.TRIGGER),
        mapClientsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.TRIGGER),
        fetchMappedMosaicClientIdsWorker
      ),
      yield fork(
        takeLatest,
        integrationPattern(constants.FETCH_INTEGRATION_PROJECTS.TRIGGER),
        fetchIntegrationProjectsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.REFETCH_INTEGRATION_PROJECT.TRIGGER),
        refetchIntegrationProjectWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_PROJECTS.TRIGGER),
        mapProjectsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_ALL_PHASES.TRIGGER),
        mapAllPhasesWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.TRIGGER),
        fetchMappedMosaicProjectIdsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_PHASES.TRIGGER),
        mapPhasesWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.TRIGGER),
        fetchMappedMosaicPhaseIdsWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_ALL_ACTIVITIES.TRIGGER),
        mapAllActivitiesWorker
      ),
      yield fork(
        takeEvery,
        integrationPattern(constants.MAP_ACTIVITY_PHASES.TRIGGER),
        mapActivityPhasesWorker
      ),
      yield fork(
        takeLatest,
        integrationPattern(constants.FETCH_SYNC_LOG.TRIGGER),
        fetchSyncLogWorker
      )
    ];
    yield all(integrationsSagas);
  }
  return integrationsSaga;
};

export default function* integrationsSaga() {
  const integrationsSagas = [
    yield fork(
      takeEvery,
      constants.FETCH_INTEGRATIONS.TRIGGER,
      fetchIntegrationsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_INTEGRATION.TRIGGER,
      fetchIntegrationWorker
    ),
    yield fork(
      takeEvery,
      constants.DISCONNECT_INTEGRATION.TRIGGER,
      disconnectIntegrationWorker
    ),
    yield fork(
      takeEvery,
      constants.DISCONNECT_INTEGRATION_V2.TRIGGER,
      disconnectIntegrationV2Worker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_INTEGRATION_AUTH_STATUS.TRIGGER,
      fetchIntegrationAuthStatusWorker
    ),
    yield fork(
      takeEvery,
      constants.PROVISION_INTEGRATION.TRIGGER,
      provisionIntegrationWorker
    ),
    yield fork(
      takeLatest,
      constants.UPDATE_INTEGRATION_CONFIG.TRIGGER,
      updateIntegrationConfigWorker
    ),
    yield fork(
      createChannel,
      [
        constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.TRIGGER,
        constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.TRIGGER,
        constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.TRIGGER,
        constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.TRIGGER,
        constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.TRIGGER
      ],
      {
        [constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.TRIGGER]:
          fetchMappedMosaicAccountIdsWorker,
        [constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.TRIGGER]:
          fetchMappedMosaicActivityIdsWorker,
        [constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.TRIGGER]:
          fetchMappedMosaicClientIdsWorker,
        [constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.TRIGGER]:
          fetchMappedMosaicProjectIdsWorker,
        [constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.TRIGGER]:
          fetchMappedMosaicPhaseIdsWorker
      }
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES,
      fetchMosaicTimeEntryMappingStatusesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION.TRIGGER,
      fetchMosaicTimeEntryMappingStatusesByIntegrationWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_INTEGRATION_ENTITY_STATS.TRIGGER,
      fetchIntegrationEntityStatsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.TRIGGER,
      fetchIntegrationSettingsSchemaWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_AUTO_LINK_PHASES.TRIGGER,
      fetchAutoLinkPhasesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.TRIGGER,
      fetchAutoLinkChildrenByPhaseNameWorker
    ),
    yield fork(
      takeLatest,
      WORKSPACE_ACTIONS_CONST.FETCH_WORKSPACES.TRIGGER,
      fetchWorkspacesWorker
    ),
    yield fork(takeEvery, fetchMappingStatuses, fetchMappingStatusesWorker)
  ];
  yield all(integrationsSagas);
}

export { makeIntegrationSaga, integrationsSaga };
