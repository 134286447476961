import 'isomorphic-fetch';
import qs from 'qs';
import { callApi } from 'appUtils/api';
import { FetchEntityOptionsRequestPayload } from 'EntityOptionsModule/types';

export const fetchEntityOptions = (
  _: unknown,
  token: string,
  queryParams: FetchEntityOptionsRequestPayload
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  // If you want the generic entity options for the team pass in entityId null
  return callApi(
    `entity_options?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};
