import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import {
  makeGetIsFetchingUtilizationReport,
  makeGetTeamUtilizationChartData
} from 'UtilizationModule/selectors';
import { fetchTeamUtilizationReport } from 'UtilizationModule/actionCreators';

import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

import { utilizationRangeGeneratorByView } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS, ZOOM_STRINGS_PLURAL } from 'appConstants/workload';
import { WidgetChartWrapper } from '../shared/styles';
import UtilizationAreaChartWidget from './UtilizationAreaChartWidget';
import UtilizationBarChartWidget from './UtilizationBarChartWidget';
import { CHART_TYPE } from 'ReportsModule/constants';

export const DATE_FORMAT = 'YYYY-MM-DD';

/* ------------------------------------------------------------------------ */

const UtilizationChartWidgetContainer = ({
  filterId,
  activeFilter,
  data,
  //
  teamId,
  isFetching,
  fetchTeamUtilizationReport,
  includeRemainingCapacity,
  useCapacityAsDenominator
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);

  const zoomLevel = useMemo(() => {
    return activeFilter.custom?.range !== undefined
      ? activeFilter.custom.range
      : ZOOM_LEVELS.YEAR;
  }, [activeFilter.custom]);

  const startDate = useMemo(
    () => activeFilter?.custom?.start_date,
    [activeFilter]
  );
  const endDate = useMemo(() => activeFilter?.custom?.end_date, [activeFilter]);

  const chartType = activeFilter?.custom?.chartType ?? CHART_TYPE.BAR;

  const dateParams = useMemo(() => {
    return {
      ...utilizationRangeGeneratorByView[activeFilter?.name]?.[zoomLevel]?.({
        start: startDate,
        end: endDate
      }),
      interval_amount: 1,
      interval_type: ZOOM_STRINGS_PLURAL.MONTH
    };
  }, [activeFilter, endDate, startDate, zoomLevel]);

  const fetchParams = useMemo(
    () => ({
      account_ids: activeFilter.account_ids,
      team_id: teamId,
      filterStateId: filterId,
      show_pto: activeFilter.custom.showPto,
      show_holidays: activeFilter.custom.showHolidays,
      ...dateParams
    }),
    [
      activeFilter.account_ids,
      activeFilter.custom.showHolidays,
      activeFilter.custom.showPto,
      dateParams,
      filterId,
      teamId
    ]
  );

  useEffect(() => {
    if (teamId) {
      fetchTeamUtilizationReport(fetchParams);
    }
  }, [fetchParams, fetchTeamUtilizationReport, teamId]);

  useEffect(() => {
    if (!isFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFetching]);

  const showLoader = !hasInitiallyLoaded || isFetching;

  const UtilizationChartComponents = {
    [CHART_TYPE.BAR]: UtilizationBarChartWidget,
    [CHART_TYPE.AREA_GRAPH]: UtilizationAreaChartWidget
  };

  const UtilizationChartComponent = UtilizationChartComponents[chartType];

  return (
    <WidgetChartWrapper>
      {showLoader ? (
        <SkeletonLoader
          numLoaders={1}
          style={{ paddingLeft: 14, marginTop: 10, height: 200 }}
          loaderStyle={{ height: 200, rx: 4 }}
        />
      ) : (
        UtilizationChartComponent && (
          <UtilizationChartComponent
            data={data}
            zoomLevel={zoomLevel}
            includeRemainingCapacity={includeRemainingCapacity}
            useCapacityAsDenominator={useCapacityAsDenominator}
          />
        )
      )}
    </WidgetChartWrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const getTeamUtilizationChartData = makeGetTeamUtilizationChartData();
  const getIsFetchingUtilizationReport = makeGetIsFetchingUtilizationReport();
  const includeRemainingCapacity = true;
  const useCapacityAsDenominator = true;
  return {
    teamId: getSelectedTeamId(state),
    isFetching: getIsFetchingUtilizationReport(state, ownProps),
    data: getTeamUtilizationChartData(state, {
      ...ownProps,
      includeRemainingCapacity,
      useCapacityAsDenominator
    }),
    includeRemainingCapacity,
    useCapacityAsDenominator
  };
};

const mapDispatchToProps = {
  fetchTeamUtilizationReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UtilizationChartWidgetContainer);
