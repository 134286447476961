import { createSelector } from 'reselect';
import { initialState as customFieldsInitialState } from '../reducers/customFields';

const emptyObj = {};
const emptyArray = [];

export const getCustomFieldsState = (state) =>
  state.customFields || customFieldsInitialState;

export const makeGetCustomFieldState = () =>
  createSelector(
    getCustomFieldsState,
    (_, ownProps) => ownProps?.internalLabel,
    (state, internalLabel) => state.customFieldStates[internalLabel] || emptyObj
  );

export const makeGetCustomFieldAllValues = () =>
  createSelector(
    makeGetCustomFieldState(),
    (state) => state.allValues || emptyArray
  );
