import theme from 'theme';
import styled from 'styled-components';

export const ArchivedText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray1};
  position: absolute;
  top: 5px;
`;
