import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ClockIcon = ({
  className,
  width = '25',
  height = '25',
  stroke = '#36465D',
  strokeWidth = '1.5',
  fill = '#333333'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.5755 1.81584C15.2305 1.27452 13.8069 1 12.3434 1C6.62687 1 1.7883 5.27062 1.08635 10.9352C1.02878 11.3979 1 11.8728 1 12.3444C1 18.5987 6.0888 23.6875 12.3432 23.6875C18.5987 23.6875 23.6875 18.5987 23.6875 12.3444C23.6864 7.68612 20.8962 3.55271 16.5755 1.81584ZM12.3434 22.8363C6.55716 22.8363 1.85036 18.1295 1.85036 12.3433C1.85036 11.9061 1.87693 11.4666 1.93117 11.0382C2.57877 5.8001 7.0554 1.85027 12.3434 1.85027C13.6973 1.85027 15.0146 2.10377 16.2576 2.60413C20.2539 4.21147 22.8364 8.03494 22.8364 12.3433C22.8364 18.1294 18.1296 22.8363 12.3434 22.8363Z"
        fill={fill}
      />
      <path
        d="M18.4263 11.9183H12.7685V4.89794M11.9183 4.89794V12.3433C11.9183 12.578 12.1087 12.7684 12.3434 12.7684H18.4263M16.5755 1.81584C15.2305 1.27452 13.8069 1 12.3434 1C6.62687 1 1.7883 5.27062 1.08635 10.9352C1.02878 11.3979 1 11.8728 1 12.3444C1 18.5987 6.0888 23.6875 12.3432 23.6875C18.5987 23.6875 23.6875 18.5987 23.6875 12.3444C23.6864 7.68612 20.8962 3.55271 16.5755 1.81584ZM12.3434 22.8363C6.55716 22.8363 1.85036 18.1295 1.85036 12.3433C1.85036 11.9061 1.87693 11.4666 1.93117 11.0382C2.57877 5.8001 7.0554 1.85027 12.3434 1.85027C13.6973 1.85027 15.0146 2.10377 16.2576 2.60413C20.2539 4.21147 22.8364 8.03494 22.8364 12.3433C22.8364 18.1294 18.1296 22.8363 12.3434 22.8363Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default ClockIcon;
