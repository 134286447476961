import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type BackKaratProps = Pick<SvgIconProps, 'width' | 'height'>;

export const BackKarat = ({ width = '8', height = '12' }: BackKaratProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 8 12">
    <path
      d="M7.33325 1.79556L5.99872 0.5L0.333252 6L5.99872 11.5L7.33325 10.2044L3.00232 6L7.33325 1.79556Z"
      fill="#4a4a4a"
    />
  </SvgIcon>
);

export default BackKarat;
