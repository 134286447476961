import SvgIcon from 'components/SvgIcon';

const TrashBatchIcon = ({ width = '17', height = '14', fill = '#0074D9' }) => (
  <SvgIcon width={width} height={height} viewBox="0 0 9 10">
    <g
      id="Task-UI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Task-Action-UI"
        transform="translate(-825.000000, -573.000000)"
        fill={fill}
      >
        <g id="Group-9" transform="translate(822.000000, 418.000000)">
          <g id="Group-14" transform="translate(3.000000, 150.000000)">
            <g
              id="np_move_344669_000000"
              transform="translate(0.000000, 5.000000)"
            >
              <g id="trash">
                <path
                  d="M0.543333332,2.71666666 L0.543333332,9.50833331 C0.543333332,9.65829331 0.665039998,9.77999997 0.814999998,9.77999997 L7.33499998,9.77999997 C7.48495998,9.77999997 7.60666664,9.65829331 7.60666664,9.50833331 L7.60666664,2.71666666 L7.87833331,2.71666666 C8.02829331,2.71666666 8.14999998,2.59495999 8.14999998,2.44499999 L8.14999998,1.35833333 C8.14999998,1.20837333 8.02829331,1.08666666 7.87833331,1.08666666 L5.43333332,1.08666666 L5.43333332,0.271666666 C5.43333332,0.121706666 5.31162665,0 5.16166665,0 L2.98833332,0 C2.83837333,0 2.71666666,0.121706666 2.71666666,0.271666666 L2.71666666,1.08666666 L0.271666666,1.08666666 C0.121706666,1.08666666 0,1.20837333 0,1.35833333 L0,2.44499999 C0,2.59495999 0.121706666,2.71666666 0.271666666,2.71666666 L0.543333332,2.71666666 Z M6.96916666,9.13270928 L1.18083332,9.13270928 L1.18083332,2.81937423 L6.96916666,2.81937423 L6.96916666,9.13270928 Z M3.25999999,0.543333332 L4.88999999,0.543333332 L4.88999999,1.08666666 L3.25999999,1.08666666 L3.25999999,0.543333332 Z M0.543333332,1.62256655 L7.60583345,1.62256655 L7.60583345,2.18506654 L0.543333332,2.18506654 L0.543333332,1.62256655 Z"
                  id="Fill-1"
                />
                <path
                  d="M2.56521737,7.90625 L2.56521737,3.96875 C2.56521737,3.848 2.4069565,3.75 2.21195651,3.75 C2.01695652,3.75 1.85869565,3.848 1.85869565,3.96875 L1.85869565,7.90625 C1.85869565,8.027 2.01695652,8.125 2.21195651,8.125 C2.4069565,8.125 2.56521737,8.027 2.56521737,7.90625 Z"
                  id="Fill-2"
                />
                <path
                  d="M5.93478258,7.90625 L5.93478258,3.96875 C5.93478258,3.848 5.77652172,3.75 5.58152173,3.75 C5.38652173,3.75 5.22826087,3.848 5.22826087,3.96875 L5.22826087,7.90625 C5.22826087,8.027 5.38652173,8.125 5.58152173,8.125 C5.77652172,8.125 5.93478258,8.027 5.93478258,7.90625 Z"
                  id="Fill-3"
                />
                <path
                  d="M4.19565215,7.90625 L4.19565215,3.96875 C4.19565215,3.848 4.03739128,3.75 3.84239129,3.75 C3.6473913,3.75 3.48913043,3.848 3.48913043,3.96875 L3.48913043,7.90625 C3.48913043,8.027 3.6473913,8.125 3.84239129,8.125 C4.03739128,8.125 4.19565215,8.027 4.19565215,7.90625 Z"
                  id="Fill-4"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default TrashBatchIcon;
