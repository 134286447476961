import { useEffect, useMemo } from 'react';
import { DATE_FNS_USA_DATE } from 'appConstants/date';
import { lightFormat, min } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { fetchMembersUtilizationReport } from 'UtilizationModule/actionCreators';
import { DateRange } from 'views/projectPlanner/DateRangeManager/DateRange';
import { BaseUseDataHookOptions } from './types';
import { useDateRangeManager } from './useDateRangeManager';
import { makeGetMemberTimesheetStatusTotalsByDate } from 'UtilizationModule/selectors';
import { AccountId } from 'models/account';
import {
  UtilizationReportWithTotalTimesheetHours,
  UtilizationReportWithTotalTimesheetHoursByDate
} from 'UtilizationModule/types';
import { DateISOString } from 'models/date';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import pick from 'lodash/pick';

type TotalTimeSheetHoursByDate = Record<
  DateISOString,
  Pick<UtilizationReportWithTotalTimesheetHours, 'totalTimesheetHours'>
>;

interface useFetchTimeEntryTotalProps extends BaseUseDataHookOptions {
  accountIds: number[];
  filterStateId: string;
  startDate: Date;
  endDate: Date;
}
export const useFetchTimeEntryTotal = ({
  accountIds,
  filterStateId,
  startDate,
  endDate,
  isReady = true
}: useFetchTimeEntryTotalProps) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const { getOutOfRangeIds } = useDateRangeManager();

  useEffect(() => {
    if (teamId && accountIds.length && isReady) {
      const idsToFetch = getOutOfRangeIds({
        ids: accountIds,
        dateRange: new DateRange({
          start: startDate,
          end: endDate
        })
      });

      if (idsToFetch.length) {
        const cappedEndDate = min([endDate, new Date()]);
        const isValidDateRange = startDate <= cappedEndDate;
        if (isValidDateRange) {
          dispatch(
            fetchMembersUtilizationReport({
              account_ids: idsToFetch,
              start_date: lightFormat(startDate, DATE_FNS_USA_DATE),
              end_date: lightFormat(cappedEndDate, DATE_FNS_USA_DATE),
              filterStateId,
              team_id: teamId,
              show_status_totals: true,
              interval_type: 'days',
              interval_amount: 1,
              isLazyLoad: true
            })
          );
        }
      }
    }
  }, [
    accountIds,
    endDate,
    filterStateId,
    startDate,
    teamId,
    isReady,
    getOutOfRangeIds,
    dispatch
  ]);

  const getMemberTimesheetStatusTotalsByDate = useMemo(
    () => makeGetMemberTimesheetStatusTotalsByDate(),
    []
  );
  const memberTimesheetStatusTotalsByDate = useAppSelector((state) =>
    getMemberTimesheetStatusTotalsByDate(state, { filterStateId })
  ) as Record<AccountId, UtilizationReportWithTotalTimesheetHoursByDate>;

  // it will be replaced by the new breakdown utilization APIs
  const timeEntryTotals = useMemo<Record<AccountId, TotalTimeSheetHoursByDate>>(
    () =>
      flow(
        Object.entries,
        map<
          [AccountId, UtilizationReportWithTotalTimesheetHoursByDate],
          // output type
          [AccountId, TotalTimeSheetHoursByDate]
        >(([accountId, utilizationReportWithTotalTimesheetHoursByDate]) => {
          // picking only the totalTimesheetHours from the object
          const pickedObject = Object.entries(
            utilizationReportWithTotalTimesheetHoursByDate
          ).reduce<TotalTimeSheetHoursByDate>(
            (timeEntries, [dateKey, utilizationReport]) => {
              timeEntries[dateKey] = pick(
                utilizationReport,
                'totalTimesheetHours'
              );
              return timeEntries;
            },
            {}
          );
          return [accountId, pickedObject];
        }),
        Object.fromEntries
      )(memberTimesheetStatusTotalsByDate),
    [memberTimesheetStatusTotalsByDate]
  );

  return {
    timeEntryTotals
  };
};
