import SvgIcon from 'components/SvgIcon';
import { ComponentProps } from 'react';

const GoToIcon = ({
  color = '#0074d9',
  height = 12.2488,
  viewBox = '0 0 10.4984 12.2488',
  width = 10.4984,
  ...svgIconProps
}: ComponentProps<typeof SvgIcon>) => (
  <SvgIcon
    fill="none"
    height={height}
    viewBox={viewBox}
    width={width}
    {...svgIconProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 9.3335 6.9989 V 9.9159 C 9.3335 10.5602 8.8112 11.0824 8.1665 11.0824 H 1.1665 C 0.5223 11.0824 0 10.5602 0 9.9159 V 2.9159 C 0 2.2712 0.5223 1.7489 1.1665 1.7489 H 4.0835 V 2.9159 H 1.1665 V 9.9159 H 8.1665 V 6.9989 H 9.3335 Z M 9.3319 2.5742 L 5.0771 6.8284 L 4.2524 6.0032 L 8.5066 1.749 H 5.8319 V 0.5825 H 10.4984 V 5.249 H 9.3319 L 9.3319 2.5742 Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoToIcon;
