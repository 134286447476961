import { useGroupedMembersFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { useBoards } from 'appUtils/hooks/useBoards';

export const useMembersByPortfolioFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  field,
  shouldUseDraft
} = {}) => {
  const { boardHash, getBoardLabel } = useBoards();

  const groupedMembersFilterValues = useGroupedMembersFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'membersByPortfolioFilter',
    filterListType: FilterListType.MembersByPortfolio,
    field,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    groupEntityHash: boardHash,
    getGroupEntityLabel: getBoardLabel
  };
};
