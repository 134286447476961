import { select, put } from 'redux-saga/effects';
import * as api from '../api';
import * as entityActions from '../entityActions';
import { fetchEntity } from 'sagas/generics';

import { fetchCapacityReport as fetchCapacityReportAction } from 'CapacityModule/actionCreators';
import { fetchPhasesByProjectIds, fetchAllProjects } from 'actionCreators';
import {
  getAuthToken,
  getProjectHash,
  getFetchedPhasesProjectIdsHash
} from 'selectors';
import * as constants from 'appConstants';

const { BUDGET_RECORD_DATA_TYPES } = constants;

export function* fetchCapacityReportWorker(action) {
  const token = yield select(getAuthToken);
  const projectHash = yield select(getProjectHash);
  const fetchedPhasesProjectIdsHash = yield select(
    getFetchedPhasesProjectIdsHash
  );

  const { params, shouldFetchProjectBreakdownData, filterStateId, teamId } =
    action.payload;

  const { error, response } = yield fetchEntity(
    entityActions.fetchCapacityReport,
    api.fetchCapacityReport,
    params,
    [token],
    action
  );

  if (!error && response && shouldFetchProjectBreakdownData) {
    const { start_date, end_date, data_type } = params;
    const idTypeToCheck =
      data_type === BUDGET_RECORD_DATA_TYPES.ACCOUNT
        ? 'account_id'
        : 'client_id';
    const ids = response.records?.map((record) => record[idTypeToCheck]) || [];

    if (ids.length) {
      yield put(
        fetchCapacityReportAction({
          filterStateId,
          params: {
            start_date,
            end_date,
            ...(data_type === BUDGET_RECORD_DATA_TYPES.ACCOUNT
              ? {
                  data_type: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT,
                  account_ids: ids,
                  // this is independent from showPto option but only to remove
                  // PTO being fetched as a project with AccountProject data type
                  show_pto: false
                }
              : {
                  data_type: BUDGET_RECORD_DATA_TYPES.CLIENT_PROJECT,
                  client_ids: ids
                }),
            team_id: teamId,
            all: true,
            // hide projects with no planned hours only for project breakdown view
            // this will prevent sub rows from showing projects with no planned hours
            hide_none_planned: true
          },
          isProjectBreakdown: true
        })
      );
    }
  }

  if (
    response?.records &&
    [
      BUDGET_RECORD_DATA_TYPES.POSITION_PHASE,
      BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE,
      BUDGET_RECORD_DATA_TYPES.PROJECT,
      BUDGET_RECORD_DATA_TYPES.PHASE
    ].includes(params.data_type)
  ) {
    // Fetch unloaded projects
    const projectIds =
      params.project_ids || response.records.map((record) => record.project_id);
    const unloadedProjectIds = Array.from(
      new Set(projectIds.filter((id) => !projectHash[id]))
    );
    if (unloadedProjectIds.length) {
      yield put(fetchAllProjects({ projectIds: unloadedProjectIds }));
    }
    if (params.data_type !== BUDGET_RECORD_DATA_TYPES.PROJECT) {
      // Fetch unloaded phases
      const unloadedPhasesProjectIds = Array.from(
        new Set(projectIds.filter((id) => !fetchedPhasesProjectIdsHash[id]))
      );
      if (unloadedPhasesProjectIds.length) {
        yield put(
          fetchPhasesByProjectIds({ projectIds: unloadedPhasesProjectIds })
        );
      }
    }
  }
}
