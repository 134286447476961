import React, { useEffect, useMemo, useState, useRef } from 'react';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { updateMemberBudget } from 'BudgetModule/actionCreators';
import { updatePhaseMembership } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

const StyledDateContainer = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & > * {
    color: ${theme.colors.colorCalendarBlue};
  }
  &:hover > * {
    font-weight: bold;
  }
`;

const StyledDate = styled.div`
  font-size: 13px;
  min-width: 55px;
`;

const SetScheduleButton = styled.button`
  outline: none;
  border: none;
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarBlue};
  background: white;
  cursor: pointer;
`;

const StyledDash = styled.div`
  margin: 0px 8px;
`;

const PositionDateCell = ({ row }) => {
  const dispatch = useDispatch();
  const calendarTarget = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [requiredStartDate, setRequiredStartDate] = useState(null);
  const [requiredEndDate, setRequiredEndDate] = useState(null);
  const {
    customRowProps: { showDemoSuggestions },
    phase,
    position: { memberBudget, phaseMembership },
    positionDates: demoDates
  } = row.original;
  const { end_date: phaseEndDate, start_date: phaseStartDate } = phase;

  const phaseHasDates = phaseStartDate && phaseEndDate;
  const phaseMembershipHasDates =
    phaseMembership?.start_date && phaseMembership?.end_date;

  const positionDates = useMemo(() => {
    return {
      startDate: phaseMembershipHasDates
        ? phaseMembership.start_date
        : phaseHasDates
        ? phaseStartDate
        : null,
      endDate: phaseMembershipHasDates
        ? phaseMembership.end_date
        : phaseHasDates
        ? phaseEndDate
        : null
    };
  }, [
    phaseMembershipHasDates,
    phaseMembership.start_date,
    phaseMembership.end_date,
    phaseHasDates,
    phaseStartDate,
    phaseEndDate
  ]);

  useEffect(() => {
    const { startDate, endDate } = positionDates;
    if (!requiredStartDate && startDate) {
      setRequiredStartDate(startDate);
    }
    if (!requiredEndDate && endDate) {
      setRequiredEndDate(endDate);
    }
  }, [positionDates, requiredStartDate, requiredEndDate]);

  const handleUpdatePhaseMembershipDateRange = (startDate, endDate) => {
    if (!phaseMembership) {
      setRequiredStartDate(startDate);
      setRequiredEndDate(endDate);
      return;
    }
    dispatch(
      updatePhaseMembership({
        phaseMembershipId: phaseMembership.id,
        start_date: startDate,
        end_date: endDate
      })
    );
  };

  const handleRangeSubmit = ({ startDate, endDate }) => {
    if (!startDate?.isValid?.() && !endDate?.isValid?.()) {
      return;
    }
    setCalendarOpen(false);
    handleUpdatePhaseMembershipDateRange(
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD')
    );
    setRequiredStartDate(startDate);
    setRequiredEndDate(endDate);
  };

  const handleClearRange = () =>
    handleUpdatePhaseMembershipDateRange(null, null);

  const hasDateRange = requiredStartDate && requiredEndDate;

  return (
    <>
      <div ref={calendarTarget} onClick={() => setCalendarOpen(true)}>
        {hasDateRange ? (
          <StyledDateContainer>
            <StyledDate>
              {requiredStartDate
                ? moment(requiredStartDate).format('M/D/YY')
                : ''}
            </StyledDate>
            <StyledDash> - </StyledDash>
            <StyledDate>
              {requiredEndDate ? moment(requiredEndDate).format('M/D/YY') : ''}
            </StyledDate>
          </StyledDateContainer>
        ) : (
          <SetScheduleButton>Set Schedule</SetScheduleButton>
        )}
      </div>

      {calendarOpen && (
        <DateRangeCalendar
          customInput={() => null}
          startOpen
          target={calendarTarget}
          onClose={() => setCalendarOpen(false)}
          onSubmit={handleRangeSubmit}
          itemStartDate={requiredStartDate}
          itemEndDate={requiredEndDate}
          showClear={hasDateRange}
          onClear={handleClearRange}
        />
      )}
    </>
  );
};

export default PositionDateCell;
