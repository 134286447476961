import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { StyledDot } from 'components/ProjectColorDot';
import {
  DateInputDiv,
  DateProgress,
  DateDash,
  DateDiv
} from 'views/projectDisplay/styles';
import {
  ContentContainer,
  StyledText
} from 'DashboardModule/components/styles';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import MilestoneIcon from 'icons/MilestoneIcon';
import CalendarIcon from 'icons/CalendarIcon';

const StyledProjectDot = styled(StyledDot)`
  flex-shrink: 0;
  position: relative;
  margin-right: 5px;

  &.integration-event-project-dot {
    left: 1px;
  }
`;

const StyledPhaseContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 12px;
  align-items: center;

  :first-child {
    margin-right: 3px;
  }
`;

const StyledTooltip = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 12px;
  font-weight: 400;
  min-width: 237px;
  max-width: 334px;
`;

const StyledContentContainer = styled(ContentContainer)`
  flex-direction: column;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: ${({ hideProjectTitle }) =>
      hideProjectTitle ? '12px' : '8px'};
  }
`;

const StyledProgressContainer = styled.div`
  min-width: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  &.integration-event-progress-container {
    align-items: flex-start;
    padding-top: 2px;
  }
`;

const StyledPhaseName = styled(StyledText)`
  line-height: 17px;
  color: ${theme.colors.colorCalendarGray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ProjectMainContainer = styled.div`
  display: flex;
  width: 100%;
  ${({ hideProjectTitle }) =>
    hideProjectTitle &&
    `
    ${StyledPhaseName} {
      font-size: 13px;
      color: ${theme.colors.colorMediumGray9};
      font-weight: 600;
      margin-left: 3px;
      width: 142px;
      margin-right: 5px;
      margin-top: 3px;
    }
  `}
`;

const ProjectTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;

const ProjectNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2px;
`;

const StyledProjectName = styled(StyledText)`
  min-width: 97px;
  max-width: 194px;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: ${({ marginLeft }) => marginLeft || 'unset'};
`;

const StyledDateInputDiv = styled(DateInputDiv)`
  width: ${({ width }) => width || '122px'};
  min-width: 80px;
  height: 20px;
  margin: 0;
`;

const StyledDateProgress = styled(DateProgress)`
  height: 19px;
`;

const StyledDateDiv = styled(DateDiv)`
  font-size: 12px;
  line-height: 12px;
  color: ${theme.colors.colorMediumGray9};
  align-self: center;
`;

const StyledDateDash = styled(DateDash)`
  font-size: 13px;
  line-height: 12px;
  color: ${theme.colors.colorMediumGray9};
  align-self: center;
`;

const StyledCustomDate = styled.div``;

const StyledEventTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  padding-left: 3px;
  max-width: 175px;
  word-break: break-all;
`;

const StyledEventTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: -2px;

  svg {
    padding-top: 2px;
  }
`;

// Get total remaining PTO days from a ranged PTO schedule as of today
// CAUTION: This will include weekends.
const getRemainingPtoDays = (startDate, endDate, total) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const today = moment();
  if (today.isSameOrBefore(start, 'day')) {
    return total;
  }
  if (end.isSameOrBefore(today, 'day')) {
    return 0;
  }
  if (today.isBetween(start, end, 'day')) {
    return end.endOf('day').diff(today, 'days') + 1;
  }
};

export const getPtoTooltipContent = (pto) => {
  const { start, end, total } = pto;
  const totalToUse = total || moment(end).diff(start, 'days') + 1;
  const remainingDays = getRemainingPtoDays(start, end, totalToUse);
  const currentProgressPercent =
    ((totalToUse - remainingDays) / totalToUse) * 100 || 0;

  return {
    id: 'pto',
    startDate: moment(start).format('M/D/YY'),
    endDate: moment(end).format('M/D/YY'),
    projectName: 'PTO',
    currentProgressPercent,
    isPto: true,
    isRangeType: totalToUse > 1
  };
};

const renderPhaseRow = (isPhase, phaseName, iconSize) => {
  return (
    <StyledPhaseContainer>
      {isPhase ? (
        <PhaseMilestoneIcon width={iconSize} height={iconSize} />
      ) : (
        <MilestoneIcon width={iconSize} height={iconSize} />
      )}
      <StyledPhaseName>{phaseName}</StyledPhaseName>
    </StyledPhaseContainer>
  );
};

const renderEventTitle = (title) => {
  return (
    <StyledEventTitleContainer>
      <CalendarIcon
        currentColor={theme.colors.colorMediumGray9}
        width="13"
        height="14"
      />
      <StyledEventTitle>{title}</StyledEventTitle>
    </StyledEventTitleContainer>
  );
};

const CalendarTooltip = ({ className }) => {
  return (
    <ReactTooltip
      className={`${className} box-shadow-tooltip`}
      id="due-dates-cell-tooltip"
      getContent={(dataTip) => {
        const { data = [], hideProjectTitle } = JSON.parse(dataTip || '{}');
        const iconSize = hideProjectTitle ? '13.5' : '12';
        return (
          <StyledTooltip>
            {data.map((item) => {
              const {
                phase,
                id, // Phase ID
                startDate,
                endDate,
                projectName,
                projectId,
                phaseName,
                isPhase,
                isRangeType,
                currentProgressPercent,
                isPto,
                eventId,
                // Only for integration event
                isIntegrationEventTooltip = false,
                eventTitle,
                customDate
              } = item;

              // If not IS event (it's a phase event), then skip showing in tooltip if missing phase id
              // If's IS event, then skip showing in tooltip only if missing event title and project id
              const skipThisPhaseEvent = !isIntegrationEventTooltip && !id;
              const skipThisIntegrationEvent =
                isIntegrationEventTooltip && !eventTitle && !projectId;

              if (skipThisPhaseEvent || skipThisIntegrationEvent) return null;

              const isDefaulPhase = phase && phase.is_like_default;
              const showPhaseInfo = !isDefaulPhase;

              return (
                <StyledContentContainer
                  hideProjectTitle={hideProjectTitle}
                  key={eventId}
                >
                  <>
                    <ProjectMainContainer hideProjectTitle={hideProjectTitle}>
                      <ProjectNameContainer>
                        {isIntegrationEventTooltip &&
                          eventTitle &&
                          renderEventTitle(eventTitle)}
                        {!hideProjectTitle ? (
                          <ProjectTitleContainer>
                            {!isPto && projectId && (
                              <StyledProjectDot
                                className={`${
                                  isIntegrationEventTooltip &&
                                  'integration-event-project-dot'
                                }`}
                                projectId={projectId}
                              />
                            )}
                            <StyledProjectName
                              data-testid={`tooltip-project-name-${projectName}`}
                              marginLeft={isPto ? '13px' : 'unset'}
                            >
                              {projectName}
                            </StyledProjectName>
                          </ProjectTitleContainer>
                        ) : (
                          !isPto &&
                          showPhaseInfo &&
                          renderPhaseRow(isPhase, phaseName, iconSize)
                        )}
                      </ProjectNameContainer>
                      <StyledProgressContainer
                        hideProjectTitle={hideProjectTitle}
                        className={`${
                          isIntegrationEventTooltip &&
                          'integration-event-progress-container'
                        }`}
                      >
                        {customDate ? (
                          <StyledCustomDate>{customDate}</StyledCustomDate>
                        ) : (
                          <StyledDateInputDiv
                            isPhase
                            isSelected // isPhase and isSelected are used for showing border of date progress
                            width={isRangeType ? '122px' : '80px'}
                          >
                            {isRangeType ? (
                              <>
                                <StyledDateProgress
                                  currentProgress={currentProgressPercent}
                                />
                                <StyledDateDiv
                                  data-testid={`due-dates-phase-start-date-${projectName}-${phaseName}`}
                                >
                                  {startDate
                                    ? moment(startDate).format('M/D/YY')
                                    : 'Start Date'}
                                </StyledDateDiv>
                                <StyledDateDash>-</StyledDateDash>
                                <StyledDateDiv
                                  data-testid={`due-dates-phase-end-date-${projectName}-${phaseName}`}
                                >
                                  {endDate
                                    ? moment(endDate).format('M/D/YY')
                                    : 'End Date'}
                                </StyledDateDiv>
                              </>
                            ) : (
                              <>
                                <StyledDateProgress
                                  currentProgress={currentProgressPercent}
                                />
                                <StyledDateDiv
                                  data-testid={`due-dates-phase-start-date-${projectName}-${phaseName}`}
                                >
                                  {startDate
                                    ? moment(startDate).format('M/D/YY')
                                    : 'Start Date'}
                                </StyledDateDiv>
                              </>
                            )}
                          </StyledDateInputDiv>
                        )}
                      </StyledProgressContainer>
                    </ProjectMainContainer>
                  </>
                  {!isPto &&
                    showPhaseInfo &&
                    !hideProjectTitle &&
                    renderPhaseRow(isPhase, phaseName, iconSize)}
                </StyledContentContainer>
              );
            })}
          </StyledTooltip>
        );
      }}
      type="light"
    />
  );
};

export default CalendarTooltip;
