import { useAppSelector } from 'reduxInfra/hooks';
import { SettingTableTemplateWithMemberCells } from '../../SettingTableTemplate/SettingTableTemplateWithMemberCells';
import { useMemo } from 'react';
import { ColumnProps, RowData } from '../../SettingTableTemplate/types';
import styled from 'styled-components';
import { getFormattedTeamMembersPTOWithSecondaryMembershipArray } from 'SettingsModule/selectors/ptos';
import { PTOsTableRowData } from './types';
import { getOfficesHash } from 'SettingsModule/selectors/offices';
import { getRegions } from 'SettingsModule/selectors/regions';
import { RegionCell } from './Cells/RegionCell';
import { OfficeCell } from './Cells/OfficeCell';
import { PolicyCell } from './Cells/PolicyCell';

export const PTOsTable = () => {
  const officesHash = useAppSelector(getOfficesHash);
  const regionsHash = useAppSelector(getRegions);
  const formattedTeamMembersPTOWithSecondaryMembershipArray = useAppSelector(
    getFormattedTeamMembersPTOWithSecondaryMembershipArray
  );

  const anyMemberHasAnyRegion = useMemo(() => {
    return formattedTeamMembersPTOWithSecondaryMembershipArray.some(
      ({ regionIds }) => regionIds.length > 0
    );
  }, [formattedTeamMembersPTOWithSecondaryMembershipArray]);

  const anyMemberHasAnyOffice = useMemo(() => {
    return formattedTeamMembersPTOWithSecondaryMembershipArray.some(
      ({ officeIds }) => officeIds.length > 0
    );
  }, [formattedTeamMembersPTOWithSecondaryMembershipArray]);

  const rowData = useMemo(() => {
    const data: RowData<PTOsTableRowData>[] = [];

    formattedTeamMembersPTOWithSecondaryMembershipArray.forEach(
      ({ member, policyMembership, officeIds, regionIds }) =>
        data.push({
          data: {
            ptoGroup: policyMembership,
            offices: officeIds.flatMap(
              (officeId) => officesHash[officeId]?.name ?? []
            ),
            regions: regionIds.flatMap(
              (regionId) => regionsHash[regionId]?.name ?? []
            )
          },
          teamMember: member
        })
    );

    return data;
  }, [
    formattedTeamMembersPTOWithSecondaryMembershipArray,
    officesHash,
    regionsHash
  ]);

  const columns: ColumnProps[] = [];

  if (anyMemberHasAnyRegion) {
    columns.push({
      id: 'region',
      label: 'Region',
      width: 180,
      rowCells: { memberRow: RegionCell }
    });
  }

  if (anyMemberHasAnyOffice) {
    columns.push({
      id: 'office',
      label: 'Office',
      width: 180,
      rowCells: { memberRow: OfficeCell }
    });
  }

  columns.push({
    id: 'policy',
    label: 'Policy',
    width: 180,
    rowCells: { memberRow: PolicyCell }
  });

  return (
    <>
      <RootContainer>
        <SettingTableTemplateWithMemberCells
          useGroup={undefined}
          columns={columns}
          rowData={rowData}
          totalCount={rowData.length}
        />
      </RootContainer>
    </>
  );
};

const RootContainer = styled.div`
  height: 100%;
`;
