import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeam } from 'selectors';
import { updateTeam } from 'actionCreators';
import theme from 'theme';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer,
  FlexRow
} from 'components/styles';
import { Modal } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { DEFAULT_FEE_TARGET_PERCENTAGE } from 'appConstants';
import { blurOnEnter } from 'appUtils/userInteractions';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

const RowText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarGray};
  white-space: nowrap;
  margin-right: 8px;
`;

const PercentSign = styled.div`
  color: ${theme.colors.colorMediumGray9};
  right: 6px;
  top: 2px;
  position: absolute;
  font-size: 18px;
`;

const StyledInput = styled(NumberFormat)`
  background: ${theme.colors.colorTranslucentGray4};
  color: ${theme.colors.colorMediumGray9};
  width: 100%;
  border: 1px solid ${theme.colors.colorLightGray6};
  border-radius: 4px;
  text-align: right;
  font-size: 15px;
  height: 30px;
  &::placeholder {
    text-align: left;
    color: ${theme.colors.colorLightGray5};
  }
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }

  padding-right: 23px;
  &::placeholder {
    text-align: right;
  }
`;

const StyledModal = styled(Modal)`
  width: fit-content;
  .modal-content {
    width: 300px;
    padding: 28px 32px;
  }
`;

const Header = styled.div`
  font-size: 22px;
  color: black;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Body = styled.div`
  margin-bottom: 25px;
`;

/* ------------------------------------------------------------------------ */

const FeeTargetModal = ({ toggle }) => {
  const dispatch = useDispatch();
  const selectedTeam = useSelector(getSelectedTeam);
  const [feeTarget, setFeeTarget] = useState(
    formatNumWithMaxOneDecimal(
      selectedTeam.default_target_fee_percentage ||
        DEFAULT_FEE_TARGET_PERCENTAGE
    )
  );
  const feeTargetInputRef = useRef(null);

  const handleFeeTargetChange = (values) => {
    setFeeTarget(values.value);
  };

  const hasNewValues =
    +feeTarget !== +selectedTeam.default_target_fee_percentage;
  const isValid = !isNaN(feeTarget);

  const handleConfirm = () => {
    if (isValid) {
      dispatch(
        updateTeam({
          team: {
            id: selectedTeam.id
          },
          defaultFeeTargetPercentage: feeTarget
        })
      );
    }
    toggle();
  };

  return (
    <StyledModal isOpen toggle={toggle}>
      <Header>Fee Target</Header>
      <Body>
        <FlexRow>
          <RowText>Enter the default Fee Target</RowText>
          <StyledInput
            data-testid="fee-target-input"
            value={feeTarget}
            placeholder={'0'}
            onValueChange={handleFeeTargetChange}
            getInputRef={(ref) => (feeTargetInputRef.current = ref)}
            onKeyDown={blurOnEnter(feeTargetInputRef)}
            onBlur={() => {
              if (feeTarget === '') {
                handleFeeTargetChange({ value: 0 });
              }
            }}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={true}
            isNumericString
            allowLeadingZeros={false}
          />
          <PercentSign>%</PercentSign>
        </FlexRow>
      </Body>
      <ButtonContainer width={152}>
        <TextButtonWithBorder
          isHidden={!hasNewValues}
          onClick={toggle}
          fontWeight={400}
        >
          Cancel
        </TextButtonWithBorder>
        <BlueSubmitButton
          onClick={!hasNewValues ? toggle : handleConfirm}
          fontWeight={400}
          padding="0.4rem 1rem"
        >
          {hasNewValues ? 'Save' : 'Done'}
        </BlueSubmitButton>
      </ButtonContainer>
    </StyledModal>
  );
};

export default FeeTargetModal;
