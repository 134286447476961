import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import { useWorkPlanForm } from '../WorkPlanFormProvider';

export const DeleteWorkplanConfirmModal = () => {
  const {
    form: { isDeleteConfirmModalOpen, onSetDeleteConfirmModalOpen, onDelete }
  } = useWorkPlanForm();
  return (
    <DeleteModal
      isOpen={isDeleteConfirmModalOpen}
      toggle={() => onSetDeleteConfirmModalOpen(false)}
      deleteOnClick={onDelete}
      component={'Work Plan'}
      renderConfirmButtons={undefined}
    />
  );
};
