import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewFactory from '../../components/ViewFactory/ViewFactory';
import withRouterParams from './WithRouterParams';
import { getMatchedRouteParams } from 'selectors';
import NavigationTracker from 'views/Navigation/NavigationTracker';
import { Views } from 'appConstants/navigation';

class StandardSettingsRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      match.url === nextLocation.pathname;
    return shouldUpdate;
  }

  render() {
    return (
      <ViewFactory moduleType={'standardSettingsView'}>
        {/* Override ViewFactory NavigationTracker, which would set view = settingsViewType (navigationChange is takeLatest) */}
        <NavigationTracker view={Views.STANDARDS} />
      </ViewFactory>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(StandardSettingsRouter)
  )
);
