import {
  BILLABLE_VALUES,
  CUSTOM_FILTER_VALUES,
  SORT_ORDER
} from 'appConstants/filters';
import { VIEW_BY } from 'appConstants/workload';
import isPlainObject from 'lodash/isPlainObject';

const emptyObj = {};

export const newFilter = {
  account_ids: [],
  activity_ids: [],
  activity_titles: [],
  board_ids: [],
  client_ids: [],
  clients: [],
  custom: {
    billable: [BILLABLE_VALUES.BILLABLE, BILLABLE_VALUES.NOT_BILLABLE]
  },
  id: 'new',
  phase_ids: [],
  phase_names: [],
  position_ids: [],
  priority_ids: [],
  project_ids: [],
  status_ids: []
};

export const getQueryParamsFromFilter = (filter) => {
  if (!filter) return emptyObj;
  const copyFields = (acc, orig) => {
    Object.keys(orig).forEach((key) => {
      if (isPlainObject(orig[key])) {
        copyFields(acc, orig[key]);
      } else {
        acc[key] = orig[key];
      }
    });
  };
  const queryParams = {};
  copyFields(queryParams, filter);
  return queryParams;
};

export const getFilterFromQueryParams = (queryParams) => {
  if (!queryParams.id) return null; // other values may have defaults from FILTER_QUERY_PARAMS_CONFIG
  const filter = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== undefined)
    .reduce((acc, cur) => {
      if (CUSTOM_FILTER_VALUES[cur]) {
        if (!acc.custom) acc.custom = {};
        acc.custom[cur] = queryParams[cur];
      } else {
        acc[cur] = queryParams[cur];
      }
      return acc;
    }, {});
  return filter;
};

export const VIEW_BY_OVERRIDE = VIEW_BY.MEMBERS; // most common filter viewBy

export const getNextSortOrder = (sortOrder) => {
  switch (sortOrder) {
    case SORT_ORDER.asc:
      return SORT_ORDER.desc;
    case SORT_ORDER.desc:
      return null;
    default:
      return SORT_ORDER.asc;
  }
};

// desc -> asc -> null
export const getNextSortOrderDAN = (sortOrder) => {
  switch (sortOrder) {
    case SORT_ORDER.desc:
      return SORT_ORDER.asc;
    case SORT_ORDER.asc:
      return null;
    default:
      return SORT_ORDER.desc;
  }
};

/**
 * @param {object} { attribute: string, reverse: boolean } sort attribute to use
 * @returns {array} array of objects
 * It is desc first and asc second.
 */
export const generateSortOrdersByAttribute = ({ attribute, reverse }) => {
  const sortAttributes = [
    {
      sortAttribute: attribute,
      sortOrder: SORT_ORDER.desc
    },
    {
      sortAttribute: attribute,
      sortOrder: SORT_ORDER.asc
    }
  ];
  return reverse ? sortAttributes.reverse() : sortAttributes;
};
