import { GroupRow, RowItems } from 'react-calendar-timeline';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { Groups } from 'TimelinesModule/types/groups';
import { BaseTimelineProps } from 'TimelinesModule/types/timeline';
import { TIMELINE_GROUP_TYPES } from '../constants';
import { BaseRowRendererProps } from './types';

interface RowRendererByTypeProps extends BaseRowRendererProps<Groups> {
  customRenderers: BaseTimelineProps['rowRenderersByType'];
  containerRef?: Element;
}

export const RowRendererByType = (props: RowRendererByTypeProps) => {
  const { customRenderers, containerRef, ...rest } = props;
  const { group } = rest;

  const Component = customRenderers[group.type];

  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: true,
    // fixed px values in the rootMargin are used to render the rows before they are actually in view
    rootMargin: '80px 0px',
    root: containerRef
  });

  if (group.type === TIMELINE_GROUP_TYPES.EMPTY) return null;

  if (!Component) return null;

  return (
    <GroupRow>
      <InViewDetector ref={ref} />
      {inView && (
        <>
          <RowItems />
          <Component {...rest} />
        </>
      )}
    </GroupRow>
  );
};

const InViewDetector = styled.div`
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
`;
