import React from 'react';
import styled from 'styled-components';

export const HeaderTile = ({ children }) => {
  return (
    <HeaderTileContainer data-testid="calendar-header-tile">
      <HeaderTileContent>{children}</HeaderTileContent>
    </HeaderTileContainer>
  );
};

const HeaderTileContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const HeaderTileContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
`;
