import styled from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import FolderIcon from 'icons/FolderIcon';
import theme from 'theme';

export const StyledSelectPhaseText = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 10px;
  margin-left: 15px;
`;
export const StyledDot = styled.div`
  background: ${theme.colors.colorMediumGray5};
  background: ${calculateProjectColorPreference};
  flex: 0 0 8px;
  height: 8px;
  margin-left: 5px;
  margin-bottom: 2px;
  border-radius: 100px;
`;

export const StyledPhaseNumber = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 10px;
  position: absolute;
  top: -11px;
  left: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
`;

export const StyledProjectTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  margin-left: 5px;
  font-size: 13px;
  font-weight: 600;
  flex: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const StyledProjectDescription = styled.div`
  color: ${theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  font-size: 12px;
  margin-left: 5px;
  width: 100%;
`;
export const StyledProjectNumber = styled.div`
  color: ${theme.colors.colorMediumGray5};
  line-height: 18px;
  height: 18px;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  padding-left: 5px;
  margin-right: 4px;
`;

export const StyledProjectInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 340px;
  align-items: baseline;
`;
export const StyledTeamName = styled.div`
  align-items: center;
  display: flex;
  visibility: visible;
  font-size: 11px;
  overflow: hidden;
  font-weight: 400;
  margin-left: 4px;
  color: ${theme.colors.colorMediumGray5};
`;

export const StyledProjectInfoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledPhaseNameContainer = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-weight: 600;
  display: flex;
  width: 100%;
  margin-left: 5px;
  width: calc(100% - 5px);
  align-items: center;
  position: relative;
  svg {
    flex-shrink: 0;
  }
`;

export const StyledPhaseName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
  max-width: 282px;
`;

export const TimesheetTopCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const TimesheetBottomCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 18px;
`;
export const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 3px;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  path {
    stroke: #808080;
    stroke-width: 3;
    fill: transparent;
  }
`;

export const StyledPhaseStatus = styled.div`
  position: absolute;
  bottom: 18px;
  left: 46px;
  background: #a4a4a4;
  border-radius: 4px;
  padding: 0 5px;
  font-size: 10px;
  font-weight: bold;
`;

export const StyledInLineContainer = styled.div`
  position: absolute;
  bottom: 17px;
  left: 51px;
`;

export const PhaseProjectListItem = styled.div`
  flex: 0 0 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: ${({ itemHeight }) => itemHeight}px;

  &:hover {
    ${StyledSelectPhaseText} {
      color: ${theme.colors.colorCalendarRed};
    }
    cursor: ${({ hasHoverBackground }) =>
      hasHoverBackground ? 'pointer' : ''};
    background: ${({ hasHoverBackground }) =>
      hasHoverBackground ? theme.colors.colorTranslucentGray4 : ''};
  }

  &.home-timesheet-add-time-entry {
    &:hover {
      ${StyledSelectPhaseText} {
        visibility: visible;
        color: ${theme.colors.colorBudgetBlue};
      }
    }

    ${StyledSelectPhaseText} {
      visibility: hidden;
    }

    ${StyledPhaseNumber} {
      ${({ showPhaseStatus }) =>
        showPhaseStatus &&
        ` 
        display: inline;
        position: static;
        padding-left: 2px;
        `}
    }

    ${StyledPhaseStatus} {
      display: inline;
      position: static;
      color: ${theme.colors.colorPureWhite};
    }

    ${StyledPhaseName} {
      color: ${theme.colors.colorSemiDarkGray6};
    }
  }
`;

export const LightBulbIconContainer = styled.div`
  &.phase {
    flex: 1;
    text-align: right;
    padding-right: 23px;
  }
`;

export const TitleAndDescriptionContainer = styled.div`
  max-width: 313px;
  overflow: hidden;
  width: 313px;
`;
