import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  getActivityModalOpen,
  getActivityModalId
} from 'BudgetModule/selectors';
import { closeActivityModal } from 'BudgetModule/actionCreators';
import GlobalAddEditHeader from 'BudgetModule/components/PhaseTemplateModal/GlobalAddEditHeader';
import ActivityModalBody from './ActivityModalBody';

class ActivityModal extends React.Component {
  state = {
    isConfirm: false
  };

  handleClose = () => {
    const { closeActivityModal } = this.props;
    closeActivityModal({});
  };

  setConfirm = () => this.setState({ isConfirm: true });
  clearConfirm = () => this.setState({ isConfirm: false });

  render() {
    const { open, id } = this.props;
    const { isConfirm } = this.state;
    return (
      <Modal
        isOpen={open}
        toggle={this.handleClose}
        zIndex="2001"
        backdropClassName="global-flyout-modal"
        className="activity-modal"
        onClosed={this.clearConfirm}
        backdrop="static"
      >
        <GlobalAddEditHeader
          handleClose={this.handleClose}
          isConfirm={isConfirm}
          copy="Work Category"
          id={id}
        />
        <ActivityModalBody
          isConfirm={isConfirm}
          setConfirm={this.setConfirm}
          clearConfirm={this.clearConfirm}
        />
      </Modal>
    );
  }
}

ActivityModal.propTypes = {
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  open: getActivityModalOpen(state),
  id: getActivityModalId(state)
});

const mapDispatchToProps = {
  closeActivityModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);
