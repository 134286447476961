import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { getTeamMembers, getTeamMembershipsByAccountId } from 'selectors';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import KaratRight from 'icons/KaratRight';

const StyledKaratContainer = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-right: 5px;
  ${(props) =>
    props.isHidden &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
  }
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
`;

const StyledNameContainer = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .location-text {
    font-size: 12px;
    color: ${theme.colors.colorCalendarGray};
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 2px;
  cursor: pointer;
  margin-left: 27px;
`;

const StyledPercentContainer = styled.div`
  font-size: 12px;
  color: #2f80ed;

  cursor: pointer;
`;

const MemberTitleCell = ({ row }) => {
  const {
    id,
    customRowProps: { handleSetIsOpen, showDemoSuggestions },
    list: { isOpen, listItems },
    member
  } = row.original;
  const teamMembers = useSelector(getTeamMembershipsByAccountId);
  const teamMember = teamMembers[member.id];
  const { score } = member;

  const handleClick = useCallback(() => {
    handleSetIsOpen({ name: id, value: !isOpen });
  }, [handleSetIsOpen, id, isOpen]);

  return (
    <StyledTitleContainer onClick={handleClick}>
      <StyledKaratContainer
        isOpen={isOpen || false}
        className="app-cues-styled-karat"
        onClick={() => {
          handleSetIsOpen({ name: id, value: !isOpen });
        }}
        isHidden={!listItems?.length}
      >
        <KaratRight />
      </StyledKaratContainer>
      <MemberInitials
        member={teamMember}
        classes={cn('regular-member-no-hover selected')}
      />
      <StyledNameContainer>
        <div>{teamMember?.account?.name}</div>
        <StyledPercentContainer onClick={handleClick}>
          {(score * 100).toFixed(0)}% Match
        </StyledPercentContainer>
      </StyledNameContainer>
    </StyledTitleContainer>
  );
};

export default MemberTitleCell;
