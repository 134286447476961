import { put, select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import * as actionCreators from 'actionCreators';
import { changeEntity, fetchEntity } from './generics';
import { getAuthToken, getMyUserId, getSelectedTeamId } from 'selectors';
import { RequestPriorityGroup } from 'appConstants';

const {
  accountFilterFetch,
  accountFiltersFetch,
  accountFilterUpdate,
  accountFilterCreate,
  accountFilterDelete,
  fetchFilterByIds
} = entityActions;

export function* fetchAccountFilterWorker(action) {
  const token = yield select(getAuthToken);
  const { id, body } = action.payload;

  const { error, response } = yield fetchEntity(
    accountFilterFetch,
    api.fetchAccountFilter,
    id,
    [token, body],
    action
  );
}
export function* fetchAccountFiltersWorker(action) {
  const token = yield select(getAuthToken);
  const accountId = yield select(getMyUserId);
  const teamId = yield select(getSelectedTeamId);
  const { error, response } = yield fetchEntity(
    accountFiltersFetch,
    api.fetchAccountFilters,
    accountId,
    [token, teamId],
    action,
    RequestPriorityGroup.Startup
  );
}

export function* updateAccountFilterWorker(action) {
  const { id } = action.payload;

  if (id === 'new') {
    yield put(actionCreators.createAccountFilter(action.payload));
    return;
  }

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    accountFilterUpdate,
    api.updateAccountFilter,
    [token, id, action.payload],
    action
  );
}

export function* createAccountFilterWorker(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  const accountId = yield select(getMyUserId);
  const teamId = yield select(getSelectedTeamId);
  const { error, response } = yield changeEntity(
    accountFilterCreate,
    api.createAccountFilter,
    [token, { ...action.payload, account_id: accountId, team_id: teamId }],
    action
  );
}

export function* deleteAccountFilterWorker(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    accountFilterDelete,
    api.deleteAccountFilter,
    [token, id],
    action
  );
}

export function* closeWorkloadFilterWorker() {
  const isOpen = yield select((state) => state.workloadPlannerFilter.isOpen);
  if (isOpen) {
    yield put(actionCreators.closeWorkloadFilter());
  }
}

export function* fetchFilterByIdsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, filter_ids } = action.payload;
  const { error, response } = yield fetchEntity(
    fetchFilterByIds,
    api.fetchFilterByIds,
    teamId,
    [token, filter_ids],
    action
  );
}
