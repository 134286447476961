import { AggregatedRealmAPIClients } from './AggregatedRealmAPIClients';
import { RealmAPIClient } from './RealmAPIClient';

if (!process.env.MOSAIC_API_URL) {
  throw Error('MOSAIC_API_URL is not defined');
}
if (!process.env.MOSAIC_WS_URL) {
  throw Error('MOSAIC_WS_URL is not defined');
}

// initialize instance for Mosiac Backend API
export const mosaicAPIClients = new AggregatedRealmAPIClients({
  mosaicAPI: new RealmAPIClient({
    baseURL: process.env.MOSAIC_API_URL
  }),
  mosaicWS: new RealmAPIClient({
    baseURL: process.env.MOSAIC_WS_URL
  })
});
