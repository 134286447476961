import { useMemo, useCallback, ComponentType, ComponentProps } from 'react';
import { PermissionCheckboxContainer } from './PermissionCheckboxContainer';
import { getTeamRoleTemplatesByName } from 'PermissionsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { CheckboxItemType } from 'SettingsModule/types/checkBoxOptions';
import { RoleTemplates, RolePermission } from 'PermissionsModule/types';
import {
  PermissionActionsEdits,
  MakeEditPermissionCallbackType
} from 'PermissionsModule/hooks/types';
import { PermissionSelection } from './styles';
import { OneOffPermissionInstance } from 'PermissionsModule/models/teamPermissions';
import difference from 'lodash/difference';
import union from 'lodash/union';
import intersection from 'lodash/intersection';
import { CHECKBOX_DEPENDENCIES } from 'PermissionsModule/utils/checkboxDependencies';

interface PermissionSelectionContainerProps {
  checkboxOptions: CheckboxItemType[];
  checkboxValues: Record<RoleTemplates, RolePermission>;
  /**
   * unSavedEdits, isSpecificMemberChecked and oneOffPermissioners are only defined
   * if there new un-saved changes made to them.
   */
  unSavedEdits: PermissionActionsEdits | undefined;
  isSpecificMemberChecked: boolean | undefined;
  oneOffPermissioners: OneOffPermissionInstance[] | undefined;
  onCheck: MakeEditPermissionCallbackType;
}

export interface HandlePermissionSelectionCheck {
  item: CheckboxItemType;
  teamMembershipIds?: number[];
  isChecked: boolean;
}

export const PermissionSelectionContainer = ({
  checkboxOptions,
  checkboxValues,
  unSavedEdits,
  isSpecificMemberChecked,
  oneOffPermissioners,
  onCheck
}: PermissionSelectionContainerProps) => {
  const teamRoleTemplatesByName = useAppSelector(getTeamRoleTemplatesByName);

  const checkboxOptionNames = useMemo(
    () => checkboxOptions.map((option) => option.name),
    [checkboxOptions]
  );

  const handleCheck = useCallback(
    ({
      item,
      teamMembershipIds,
      isChecked
    }: HandlePermissionSelectionCheck) => {
      const itemDependencies = CHECKBOX_DEPENDENCIES[item.name];

      const itemDependenciesByChecked = itemDependencies
        ? intersection(
            isChecked ? itemDependencies.true : itemDependencies.false,
            checkboxOptionNames
          )
        : [];

      onCheck({
        names: [item.name, ...itemDependenciesByChecked],
        checked: isChecked,
        teamMembershipIds
      });
    },
    [onCheck, checkboxOptionNames]
  );

  /**
   * Determine the most up to date one off permission members by combining saved
   * and unsaved changes.
   */
  const editedTeamMembershipIds = useMemo(() => {
    const savedTeamMembershipIds =
      oneOffPermissioners?.map(
        (permissioner) => permissioner.team_membership_id
      ) ?? [];
    const unSavedTrueTeamMembershipIds =
      unSavedEdits?.true?.team_membership_ids ?? [];
    const unSavedFalseTeamMembershipIds =
      unSavedEdits?.false?.team_membership_ids ?? [];

    return difference(
      union(savedTeamMembershipIds, unSavedTrueTeamMembershipIds),
      unSavedFalseTeamMembershipIds
    );
  }, [oneOffPermissioners, unSavedEdits]);

  const getIsChecked = (optionName) => {
    const savedCheckboxValue =
      checkboxValues[optionName]?.permission_value ?? false;
    const optionId = teamRoleTemplatesByName[optionName]?.id;

    /**
     * For one off permissions, checkbox is checked if user clicks an un-checked checkbox
     * (isSpecificMemberChecked will be true)
     *
     * OR
     *
     * when there is existing one off permission members
     * (editedTeamMembershipIds array not empty)
     */
    if (optionName === 'Specific Member') {
      return isSpecificMemberChecked || editedTeamMembershipIds.length > 0;
    }

    if (!unSavedEdits || !optionId) return savedCheckboxValue;

    /**
     * savedCheckboxValue === true means the saved checkbox value was previously checked.
     * So we want to check the unsaved values to see if it has been unchecked.
     */
    return savedCheckboxValue
      ? !unSavedEdits.false.role_template_ids.includes(optionId)
      : unSavedEdits.true.role_template_ids.includes(optionId);
  };

  return (
    <PermissionSelection>
      {checkboxOptions.map((option) => {
        const CheckboxComponent =
          option.customCheckboxComponent ?? PermissionCheckboxContainer;

        return (
          <CheckboxComponent
            key={option.name}
            isChecked={getIsChecked(option.name)}
            teamMembershipIds={editedTeamMembershipIds}
            isDisabled={option.default}
            onCheck={handleCheck}
            item={option}
          />
        );
      })}
    </PermissionSelection>
  );
};
