import styled from 'styled-components';
import theme from 'theme';

export const AddPlusClickableArea = styled.div`
  margin-left: 9px;
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const AddPlusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
`;

export const ListHeader = styled.div`
  padding: 4px 0px;
  /* border-bottom: 1px solid #dadada; */
  font-size: 16px;
  color: #828282;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

export const ListSection = styled.div`
  padding: 0 22px;
`;

export const InviteContainer = styled.div`
  position: sticky;
  top: 0px;
  padding-top: 30px;
  z-index: 5;
  background: ${theme.colors.colorTranslucentGray4};
`;

export const ExistingMember = styled.div`
  display: flex;

  .permissions-item-header {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    font-size: 13px;
  }
  .box-container {
    margin-right: 10px;
  }
`;
