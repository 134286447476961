import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  navigateToProject,
  deleteProject,
  toggleDeleteProjectModal,
  archiveUnarchiveProject,
  openEditProjectModal,
  handleProjectItemState,
  setSelectedBoard,
  starProject
} from 'actionCreators';
import {
  getGroupsHash,
  getSelectedTeamId,
  getAuthToken,
  getHasTimeEntries,
  getSidebarProjectsOpen
} from 'selectors';
import theme from 'theme';
import styled from 'styled-components';
import Popover from 'components/Popover';
import ThreeDot, {
  IconContainer,
  StyledThreeDot
} from 'IntegrationsModule/components/ThreeDot';
import ColorSetIcon from './icons/color-palette-icon.svg';
import ArchiveIcon from 'icons/archive.svg';
import InfoIcon from './icons/info-icon.svg';
import StarIcon from './icons/favorite-icon.svg';
import UnfavoriteIcon from 'images/unfavorite-icon.svg';
import DeleteIcon from 'icons/DeleteIcon';
import ColorPicker from 'components/ColorPicker';
import GoToProjectIcon from 'images/go-to-icon.svg';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import {
  hasAssociatedTime,
  getAssociatedTimeMessage
} from 'appUtils/budgetUtils';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import { ArchiveProjectModal } from 'components/Modals';
import useCan from 'appUtils/hooks/useCan';
import {
  deletePortfolioProject,
  archivePortfolioProject
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  DELETE_PROJECT_TIP,
  ARCHIVE_PROJECT_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const StyledDivider = styled.hr`
  padding: 0px;
  margin: 5px 9px;
`;
export const Icon = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;
export const StyledMenuItem = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  padding: 6px 19px 6px 10px;
  background: white;
  display: flex;
  align-items: center;

  &:hover {
    filter: brightness(95%);
  }
`;
const DeleteItem = styled(StyledMenuItem)`
  &:hover {
    color: ${theme.colors.colorMediumRed1};
    svg path {
      fill: ${theme.colors.colorMediumRed1};
    }
  }
`;
export const StyledMenu = styled.div`
  padding: 12px 0;
  cursor: pointer;
`;
export const StyledDropdown = styled.div`
  width: 27px;
  height: 27px;
  ${StyledThreeDot} {
    ${(props) => props.showToggle && 'visibility: visible!important;'}
  }
  ${IconContainer} {
    background: white;
    border: 1px solid ${theme.colors.colorLightGray6};
    transform: scale(0.8);
    svg {
      position: relative;
      top: 2px;
      left: 1px;
      path {
        fill: ${theme.colors.colorCalendarGray};
      }
    }
    ${(props) =>
      props.showToggle &&
      `
      transform: scale(1);
      border-color: ${theme.colors.colorCalendarBlue};
      background: ${theme.colors.colorCalendarBlue};
      svg path {
        fill: white;
      }
    `}
  }
  &:hover {
    ${IconContainer} {
      transform: scale(1);
      background: ${theme.colors.colorCalendarBlue};
      border-color: ${theme.colors.colorCalendarBlue};
      svg {
        top: 2px;
        left: 1px;
      }
      path {
        fill: white;
      }
    }
  }
`;

const ProjectDropdown = ({ project, isProjectsSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const boardsHash = useSelector(getGroupsHash);
  const board = boardsHash[project.board_id];
  const selectedTeamId = useSelector(getSelectedTeamId);
  const sidebarProjectsOpen = useSelector(getSidebarProjectsOpen);
  const loadingHasTimeEntries = useSelector(
    (state) => state.timesheets.loadingHasTimeEntries
  );
  const hasTimeEntries = useSelector(getHasTimeEntries);
  const token = useSelector(getAuthToken);
  const dispatch = useDispatch();

  useEffect(() => {
    rebuildTooltip();
  }, [isOpen]);

  const toggle = useCallback(
    (e) => {
      e && e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );
  const toggleRef = useRef(null);

  const closeDropdown = () => {
    setTimeout(() => setIsOpen(false), 200);
  };

  const onGoToProject = () => {
    dispatch(
      navigateToProject({
        teamSlug: project.is_personal ? 'personal' : board.team_slug,
        projectSlug: project.slug,
        projectId: project.id,
        openInNewWindow: true
      })
    );
  };

  const onInfoClick = () => {
    dispatch(setSelectedBoard({ board }));
    dispatch(openEditProjectModal(project.id, project.is_personal, true));
  };

  const onSetColor = (e) => {
    e.stopPropagation();
    setShowColorPicker(true);
  };

  const onFavourite = () => {
    dispatch(
      starProject({
        projectId: project.id,
        starred: !project.is_starred,
        position: 0
      })
    );
  };

  const onColorSelect = () => {
    setShowColorPicker(false);
    toggle();
  };

  /* --------------------------------- Delete --------------------------------- */
  const permissions = useMemo(() => {
    return {
      projectId: project.id,
      boardId: project.board_id,
      teamId: selectedTeamId
    };
  }, [project.board_id, project.id, selectedTeamId]);

  const canDeleteProject = useCan(deleteProject, permissions);
  const canDeletePortfolioProject = useCan(deletePortfolioProject, permissions);

  const canDelete = canDeleteProject && canDeletePortfolioProject;

  const projectHasTimeEntries = useMemo(
    () =>
      hasTimeEntries?.find(
        (hasTimeEntry) =>
          hasTimeEntry.project_id === project.id &&
          hasAssociatedTime(hasTimeEntry)
      ),
    [hasTimeEntries, project.id]
  );

  const onDelete = (e) => {
    toggle(e);
    if (canDelete) {
      setShowDeleteModal(true);
    }
  };

  const onDeleteConfirm = () => {
    if (projectHasTimeEntries) {
      return;
    }

    const editingProjectId = project.id;
    const selectedBoardId = project.board_id;
    const selectedBoardIsPersonal = project.is_personal;
    const boardSlug = selectedBoardIsPersonal ? 'personal' : board.slug;
    const isActive = !project.archived_at;
    const payload = {
      projectId: editingProjectId,
      groupId: selectedBoardId,
      isActive,
      groupSlug: boardSlug,
      isPersonal: selectedBoardIsPersonal,
      noRedirectOnDelete: true,
      permissions
    };
    dispatch(deleteProject(payload));
    dispatch(
      handleProjectItemState(
        null,
        ['detailVisible', 'expanded', 'fixed'],
        [false, false, false]
      )
    );
    setShowDeleteModal(false);
  };

  const renderDeleteBody = () => {
    if (loadingHasTimeEntries) {
      return <div />;
    }
    const isActive = !project.archived_at;
    if (projectHasTimeEntries) {
      const deleteMessage = getAssociatedTimeMessage(
        projectHasTimeEntries,
        MODAL_TYPE.PROJECT
      );
      return (
        <>
          <b>{project.title}</b> {deleteMessage}
          {isActive ? ' Would you like to Archive instead?' : ''}
        </>
      );
    }
    return (
      <>
        Are you sure you want to <b>permanently</b> delete{' '}
        <b>{project.title}</b>? You can’t restore a deleted Project.
      </>
    );
  };

  const handleArchive = () => {
    if (canArchive) {
      dispatch(
        archiveUnarchiveProject({
          token,
          projectId: project.id,
          isActive: !!project.archived_at,
          position: project.position || 0,
          permissions
        })
      );
    }
  };

  const renderDeleteConfirm = ({ deleteOnClick }) => {
    const isActive = !project.archived_at;
    return (
      <button onClick={projectHasTimeEntries ? handleArchive : deleteOnClick}>
        {isActive ? 'Yes' : 'Ok'}
      </button>
    );
  };

  /* ------------------------------------ - ----------------------------------- */

  const canArchiveProject = useCan(archiveUnarchiveProject, permissions);
  const canArchivePortfolioProject = useCan(
    archivePortfolioProject,
    permissions
  );

  const canArchive = canArchiveProject && canArchivePortfolioProject;

  const onArchive = () => {
    if (canArchive) {
      if (!project.is_archived) {
        setShowArchiveModal(true);
      } else {
        dispatch(
          archiveUnarchiveProject({
            token,
            projectId: project.id,
            isActive: true,
            position: project.position || 0,
            permissions
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!isOpen && showColorPicker) {
      setShowColorPicker(false);
    }
  }, [isOpen, showColorPicker]);

  useEffect(() => {
    if (isProjectsSidebar && isOpen && !sidebarProjectsOpen) {
      toggle();
    }
  }, [isOpen, isProjectsSidebar, sidebarProjectsOpen, toggle]);

  return (
    <StyledDropdown
      data-testid={`dropdown-for-${project.title}`}
      ref={toggleRef}
      onClick={toggle}
      showToggle={isOpen}
    >
      <ThreeDot />
      <Popover
        isOpen={isOpen}
        closePopover={closeDropdown}
        target={toggleRef}
        boundariesElement="window"
      >
        {isOpen && (
          <>
            {showColorPicker ? (
              <ColorPicker
                projectId={project.id}
                id={project.id}
                originType={ORIGIN_TYPE_STRINGS.PROJECT}
                pickerLocation={`sidebar-dropdown-${project.id}`}
                row
                className="color-picker-container"
                headerText="Select Project Color"
                alwaysOpen
                handleSelect={onColorSelect}
              >
                <div />
              </ColorPicker>
            ) : (
              <StyledMenu onClick={toggle}>
                {!project.is_administrative && (
                  <>
                    <StyledMenuItem onClick={onGoToProject}>
                      <Icon>
                        <img src={GoToProjectIcon} />
                      </Icon>
                      <span>Go to Project</span>
                    </StyledMenuItem>

                    <StyledMenuItem onClick={onInfoClick}>
                      <Icon>
                        <img src={InfoIcon} />
                      </Icon>
                      <span>Project Info</span>
                    </StyledMenuItem>
                  </>
                )}
                <StyledMenuItem onClick={onSetColor}>
                  <Icon>
                    <img src={ColorSetIcon} />
                  </Icon>
                  <span>Set Color</span>
                </StyledMenuItem>
                <StyledMenuItem onClick={onFavourite}>
                  <Icon>
                    {project.is_starred ? (
                      <img src={UnfavoriteIcon} />
                    ) : (
                      <img src={StarIcon} />
                    )}
                  </Icon>
                  <span>{project.is_starred ? 'Unfavorite' : 'Favorite'}</span>
                </StyledMenuItem>
                {!project.is_administrative && (
                  <>
                    <StyledDivider />
                    <StyledMenuItem
                      onClick={onArchive}
                      data-for="app-tooltip"
                      data-class="center"
                      data-tip={ARCHIVE_PROJECT_TIP}
                      data-tip-disable={canArchive}
                      data-effect="solid"
                    >
                      <Icon>
                        <img src={ArchiveIcon} />
                      </Icon>
                      <span>
                        {project.is_archived ? 'Unarchive' : 'Archive'}
                      </span>
                    </StyledMenuItem>
                    <DeleteItem
                      onClick={onDelete}
                      data-for={'app-tooltip'}
                      data-class="center"
                      data-tip={DELETE_PROJECT_TIP}
                      data-tip-disable={canDelete}
                      data-effect="solid"
                    >
                      <Icon>
                        <DeleteIcon />
                      </Icon>
                      <span>Delete</span>
                    </DeleteItem>
                  </>
                )}
              </StyledMenu>
            )}
          </>
        )}
      </Popover>
      {showDeleteModal && (
        <DeleteModal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(false)}
          deleteOnClick={onDeleteConfirm}
          renderBody={renderDeleteBody}
          component={'project'}
          renderDelete={renderDeleteConfirm}
        />
      )}
      {showArchiveModal && (
        <ArchiveProjectModal
          isOpen={showArchiveModal}
          toggle={() => setShowArchiveModal(false)}
          project={project}
          confirmCallback={() => setShowArchiveModal(false)}
        />
      )}
    </StyledDropdown>
  );
};

export default ProjectDropdown;
