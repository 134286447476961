import React from 'react';
import styled from 'styled-components';
import SkillLevelSelector from 'components/skills/SkillLevelSelector';

const StyledSkill = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  ${(props) => props.isArchived && 'pointer-events: none;'}
`;

const SkillCell = ({ row }) => {
  const {
    original: { member, skillMembership, list }
  } = row;

  return (
    <StyledSkill isArchived={list.isArchived}>
      <SkillLevelSelector member={member} skillMembership={skillMembership} />
    </StyledSkill>
  );
};

export default SkillCell;
