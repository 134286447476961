import { useRef, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { makeGetLeanApiIsoState } from 'LeanApiModule/selectors';
import {
  fetchLeanApi,
  clearLeanApiIsoState
} from 'LeanApiModule/actionCreators';
import debounce from 'lodash/debounce';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { makeFetchRequestStatusId } from 'appUtils/request/actions';
import { initialIsoState } from 'LeanApiModule/reducers/leanApiReducer';
import merge from 'lodash/merge';
import { FIELD_RENAME_MAP, FIELD_NAMES } from 'LeanApiModule/constants';
import { FilterListType } from 'FilterModule/constants';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';

export const useLeanApi = ({
  debounceTime = 300,
  isoStateId
}: {
  debounceTime?: number;
  /**
   * undefined is handling cases where this hook may be conditionally used
   */
  isoStateId: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();

  /* ---------------------------------- fetch --------------------------------- */

  const requestStatusId = isoStateId
    ? makeFetchRequestStatusId(isoStateId)
    : undefined;

  const { status: fetchStatus, removeStatus: removeFetchStatus } =
    useRequestStatus({
      requestStatusId
    });

  const isLoading = !!isoStateId && (!fetchStatus || fetchStatus?.isLoading);

  const debouncedFetchLeanApiRef = useRef(
    debounce(
      (
        params: Parameters<typeof fetchLeanApi>[0],
        baseMeta: {
          isoStateId?: string;
          requestStatusId?: string;
        }
      ) => {
        // note: if arrays are being merged, needs to be replaced by mergeWith
        const mergedParams = merge({}, params, { meta: baseMeta });

        const isPaginated = Boolean(mergedParams.limit);

        if (isPaginated && mergedParams.meta.isInitialFetch && !5) {
          dispatchChain(
            [
              [
                // fetch counts
                fetchLeanApi({
                  ...mergedParams,
                  all: true,
                  excludeRecords: true,
                  meta: {
                    ...mergedParams.meta,
                    cancelAllBefore: true
                  }
                }),
                // fetch records
                fetchLeanApi({
                  ...mergedParams,
                  excludeValueCounts: true,
                  meta: {
                    ...mergedParams.meta,
                    isTakeLatest: false
                  }
                })
              ]
            ],
            {
              chainId: mergedParams.meta.requestStatusId,
              cleanupChainStatuses: true
            } as any
          );
        } else {
          dispatch(fetchLeanApi(mergedParams));
        }
      },
      debounceTime
    )
  ).current;

  const debouncedFetchLeanApi = useCallback(
    (params: Parameters<typeof fetchLeanApi>[0]) => {
      const baseMeta = {
        requestStatusId,
        isoStateId
      };

      debouncedFetchLeanApiRef(params, baseMeta);
    },
    [debouncedFetchLeanApiRef, isoStateId, requestStatusId]
  );

  /* -------------------------------- isoState -------------------------------- */

  const getLeanApiIsoState = useMemo(makeGetLeanApiIsoState, []);

  const leanApiIsoState = useAppSelector((state) =>
    isoStateId
      ? getLeanApiIsoState(state, {
          isoStateId
        })
      : initialIsoState
  );

  /**
   * Deletes the isoState and request status
   */
  const dispatchClearLeanApiIsoState = useCallback(() => {
    if (isoStateId) {
      removeFetchStatus();

      dispatch(
        clearLeanApiIsoState({
          isoStateId
        })
      );
    }
  }, [dispatch, isoStateId, removeFetchStatus]);

  /**
   * Returns the selectedAttributeCounts of the isoState for the field associated with the given
   * FilterListType. eg. if skill ids are in the SELECT clause of the request and FilterListType.Skills
   * is given, selectedAttributeCounts.skill_id will be returned, which if it exists will contain
   * a hash of { [skill ids]: count of associated results }
   */
  const getResultCountHash = useCallback(
    (filterListType: FilterListType) => {
      const resultCountHash = leanApiIsoState?.selectedAttributeCounts;

      const selectedAttributeCountsKey =
        filterListTypeToSelectedAttributeCountsKey[filterListType];

      return selectedAttributeCountsKey
        ? resultCountHash?.[selectedAttributeCountsKey]
        : undefined;
    },
    [leanApiIsoState?.selectedAttributeCounts]
  );

  return {
    debouncedFetchLeanApi,
    dispatchClearLeanApiIsoState,
    leanApiIsoState,
    fetchStatus,
    isLoading,
    getResultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

/**
 * Mapping of filterListType to the key matching the SELECT clause renamings in a lean api call
 */
const filterListTypeToSelectedAttributeCountsKey: Partial<
  Record<
    FilterListType,
    keyof typeof FIELD_RENAME_MAP | keyof typeof FIELD_NAMES
  >
> = {
  [FilterListType.Skills]: FIELD_RENAME_MAP.skill_id,
  [FilterListType.Positions]: FIELD_RENAME_MAP.position_id,
  [FilterListType.Departments]: FIELD_RENAME_MAP.department_id,
  [FilterListType.Regions]: FIELD_RENAME_MAP.region_id,
  [FilterListType.Disciplines]: FIELD_RENAME_MAP.discipline_id,
  [FilterListType.Offices]: FIELD_RENAME_MAP.office_id,

  // below are not used / certain
  [FilterListType.Projects]: FIELD_RENAME_MAP.project_id,
  [FilterListType.ProjectsByPortfolio]: FIELD_RENAME_MAP.project_id,
  [FilterListType.ProjectsByMember]: FIELD_RENAME_MAP.project_id,
  [FilterListType.PhasesByProjectsByPortfolio]: FIELD_RENAME_MAP.phase_id,
  [FilterListType.PhaseNames]: FIELD_RENAME_MAP.phase_id,
  [FilterListType.PhaseIds]: FIELD_RENAME_MAP.phase_id,
  [FilterListType.ActivityIds]: FIELD_RENAME_MAP.activity_id,
  [FilterListType.Portfolios]: FIELD_RENAME_MAP.board_id,
  [FilterListType.ClientNames]: FIELD_RENAME_MAP.client_id,
  [FilterListType.ClientIds]: FIELD_RENAME_MAP.client_id,
  [FilterListType.Billable]: FIELD_RENAME_MAP.billable,
  [FilterListType.Members]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersByDepartment]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersByPosition]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersByRegion]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersBySkill]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersByOffice]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersByPortfolio]: FIELD_RENAME_MAP.account_id,
  [FilterListType.MembersByDiscipline]: FIELD_RENAME_MAP.account_id,
  [FilterListType.ProjectBudgetStatus]: FIELD_RENAME_MAP.project_budget_status,
  [FilterListType.PhaseBudgetStatus]: FIELD_RENAME_MAP.phase_budget_status,
  [FilterListType.ProfitCenter]: FIELD_RENAME_MAP.profit_center,
  [FilterListType.EmploymentType]: FIELD_NAMES.employment_type
};
