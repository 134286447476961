const PATHS = {
  HOME: '/home',
  HOME_TIME: '/home/time',
  TIME: '/time',

  MEMBERS: '/members',
  MEMBERS_DEPARTMENTS: '/members/department',
  MEMBERS_MEMBERS: '/members/members',

  SETTINGS: '/settings',
  SETTINGS_INFO: '/settings/info',
  SETTINGS_MEMBERS: '/settings/members',
  SETTINGS_PERMISSIONS: '/settings/permissions',
  SETTINGS_TEAMS: '/settings/teams',
  SETTINGS_TIMESHEET: '/settings/timesheet',
  WORKLOAD_PROJECTS_BUDGET_MODAL: '/workload/projects/budgetmodal',
  WORKLOAD_MEMBERS: '/workload/members',
  WORKLOAD_PROJECTS: '/workload/projects',

  BUDGET: '/budget',
  BUDGET_PROJECTS: '/budget/projects',
  BUDGET_MEMBERS: '/budget/members',
  BUDGET_MODAL: '/budget/budgetmodal',
  BUDGET_MODAL_STANDALONE: '/budgetmodal',

  REPORTS: '/reports',
  REPORTS_TIMESHEET: '/reports/time',
  REPORTS_TIMESHEET_STATUS: '/reports/timesheetstatus',
  REPORTS_BUDGETS: '/reports/budget',
  REPORTS_VARIANCE: '/reports/variance',
  REPORTS_WORKLOAD: '/reports/capacity',
  REPORTS_TIMESHEET_ACTIVITY: '/reports/activity',
  REPORTS_BUDGET_VARIANCE: '/reports/budgetvariance',
  REPORTS_SCHEDULE_VARIANCE: '/reports/schedulevariance',

  BUDGETS_PROJECT_REPORT: '/projects/budgetmodal',

  PLANNER: '/planner',

  INTEGRATION: '/integrations',

  PROJECT: '/project',

  BOARDS: '/boards',

  DASHBOARD: '/dashboard',

  TEAM_SLUG: '/:teamSlug',

  USER_SLUG: '/:userSlug'
};

export default PATHS;
