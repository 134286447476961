import { FilterListType } from './constants';
import type {
  FilterListTypeHookWithGroupings,
  FilterListTypeHook
} from './types';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { StateAccountFilter } from 'models/filter';

export const isFilterListType = (value): value is FilterListType =>
  value in FilterListType;

export const isValuesOfFilterListTypeHookWithGroupings = (
  filterValues: ReturnType<FilterListTypeHook>
): filterValues is ReturnType<FilterListTypeHookWithGroupings> => {
  return filterValues.isGrouped === true;
};

export const makeAccountFiltersByPageNameThenName = <
  T extends StateAccountFilter[] | Record<string | number, StateAccountFilter>
>(
  filters: T
) =>
  mapValues(groupBy(filters, 'page_name'), (pageFilters) =>
    keyBy(pageFilters, 'name')
  ) as Record<string, Record<string, StateAccountFilter>>;
