import { getAccountFilters } from 'FilterModule/selectors';
import { StateAccountFilter } from 'models/filter';
import { useAppSelector } from 'reduxInfra/hooks';

export const usePageViewFilter = <FilterType extends StateAccountFilter>(
  filterLevelName: string
): FilterType | undefined => {
  const accountFilters = useAppSelector(getAccountFilters);

  return accountFilters[filterLevelName] as FilterType;
};
