import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { UpdateUserParams } from './api/types';

/* ------------------------------- update user ------------------------------ */
const UPDATE_USER = createRequestTypes('UPDATE_USER');

type UpdateUserPayload = ActionParamsWithMeta<
  UpdateUserParams,
  IMeta<() => void>
>;

export const updateUser = createAction(
  UPDATE_USER.TRIGGER,
  ({ meta, ...payload }: UpdateUserPayload) => ({ meta, payload })
);

export const updateUserActionCreatorsMap = createActionCreatorsMap(UPDATE_USER);
