import { VIEW_BY } from 'appConstants/workload';
import {
  HomeTasksState,
  MemberModalTaskFilterState,
  TaskFilterCompletionState,
  TaskFilterScope,
  TaskFilterSection,
  TaskFilterSubSection,
  TaskFilterView
} from './types';

export const homeTasksInitialState: HomeTasksState = {
  activeTaskFilter: {},
  allTasks: [],
  pastDueTasks: [],
  pastScheduledTasks: [],
  pastDueCount: null,
  pastScheduledCount: null,
  scheduledForToday: null,
  taskHash: {},
  projectMembershipHash: {},
  dependencyTaskHash: {},
  timerTaskHash: {},
  editedTasks: {},
  batchSelectedTaskIds: {},
  batchSelectedGroupIds: {},
  isFetchingTasks: 0,
  isFetchingPastDue: false,
  isFetchingPastScheduled: false,
  isLazyLoading: false,
  totalPages: 0,
  currentPage: 0,
  limit: 25,
  currentFilter: {
    scope: TaskFilterScope.Default,
    state: TaskFilterCompletionState.Incomplete,
    section: TaskFilterSection.MyTasks,
    subSection: TaskFilterSubSection.Inbox,
    viewType: TaskFilterView.Default
  },
  memberModalTaskFilter: {
    state: MemberModalTaskFilterState.Incomplete
  },
  memberModalTaskCounts: {
    completed: 0,
    unscheduled: 0,
    scheduled: 0,
    overdue: 0,
    today: 0
  },
  selectedTask: null,
  sort: null,
  viewBy: VIEW_BY.TASK_GROUPS,
  selectedAccountIds: [],
  isCreatingTask: false,
  flaggedTasksModalOpen: false,
  pastScheduledModalOpen: false,
  taskEditProperty: null,
  confirmCompleteModalTaskId: null,
  confirmCompleteModalIsOpen: false,
  tempId: null,
  tempIds: {},
  createdTasksToTempIds: {},
  createRowData: {},
  offset: 0,
  taskCount: null,
  taskGroupCounts: {},
  taskRemovals: {},
  filterStates: {},
  fetchedProjectIds: {},
  workPlanTaskHours: undefined
};
