import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FolderIcon from 'icons/FolderIcon';
import styled from 'styled-components';
import theme from 'theme';
import ColorPicker from 'components/ColorPicker';
import HelpIcon from 'icons/HelpIcon';
import { calculateBoardColorPreference } from 'appUtils/styleUtils';
import {
  MAKE_PORTFOLIO_PRIVATE_TIP,
  EDIT_PORTFOLIO_TITLE_TIP,
  EDIT_PORTFOLIO_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
const StyledFolderIconContainer = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 10px;
  border-right: 1px solid ${theme.colors.colorPaleGray5};
`;
export const StyledHelpIconContainer = styled.span`
  margin-right: 10px;
`;

const SaveButton = styled.button`
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  background: ${({ isBlue }) =>
    isBlue ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray1};
  color: ${theme.colors.colorPureWhite};
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  font-size: 15px;
`;
const CancelButton = styled(SaveButton)`
  margin-right: 10px;
  background: none;
  color: ${theme.colors.colorMediumGray1};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
export const StyledFolderIcon = styled(FolderIcon)`
  width: 30px;
  height: 34px;

  path {
    stroke: ${(props) =>
      props.tempColor ||
      calculateBoardColorPreference(props) ||
      props.theme.colors.colorSemiDarkGray1};
    stroke-width: 3;
    fill: ${(props) =>
      props.tempColor || calculateBoardColorPreference(props) || `transparent`};
  }
`;

const AddEditBoardForm = ({
  isEditForm,
  handleSubmit,
  disableSave,
  boardName,
  handleChange,
  isPrivate,
  handleCheckChange,
  archiveModal,
  userCanArchive,
  userCanTogglePrivacy,
  userCanEditPortfolio,
  isCreatingGroup,
  boardId,
  setColor,
  tempColor,
  teamName,
  closeModal
}) => (
  <form onSubmit={handleSubmit}>
    <div className="add-edit-group-modal-board">
      <div className="team-name-container">
        <StyledFolderIconContainer>
          <div className="modal-board-title-header">COLOR</div>
          <ColorPicker
            boardId={isEditForm ? boardId : 'new'}
            id={isEditForm ? boardId : 'new'}
            originType={ORIGIN_TYPE_STRINGS.BOARD}
            // pickerLocation="flyout-menu"
            row
            className="color-picker-container"
            headerText="Select Portfolio Color"
            apiProps={setColor}

            // alwaysOpen
          >
            <div>
              <StyledFolderIcon boardId={boardId} tempColor={tempColor} />
            </div>
          </ColorPicker>
        </StyledFolderIconContainer>
        <div>
          <div className="modal-board-title-header">PORTFOLIO NAME</div>
          <div
            data-for={'app-tooltip'}
            data-effect="solid"
            data-html
            data-class="center"
            data-tip={EDIT_PORTFOLIO_TITLE_TIP}
            data-tip-disable={userCanEditPortfolio || !isEditForm}
          >
            <input
              type="text"
              value={boardName}
              name="boardName"
              onChange={handleChange}
              placeholder="Portfolio Name"
              className="form-board-name"
              required
              pattern="^[^\s]+(\s+[^\s]+)*$"
              title="Titles cannot begin or end with whitespace."
              disabled={!userCanEditPortfolio && isEditForm}
            />
          </div>
        </div>
      </div>
      <div className="privacy-div">
        <div className="add-edit-group-modal-public">
          <span>
            {isPrivate ? (
              <span className="private-icon">
                <div className="lock-icon" />
                Private
              </span>
            ) : (
              'Public'
            )}
          </span>
        </div>
        <StyledHelpIconContainer
          data-tip={
            isPrivate
              ? 'Only Portfolio Members and Admins can view this Portfolio'
              : `Anyone at ${teamName} can view this Portfolio`
          }
          data-class="center"
          data-for={'app-tooltip'}
          data-effect="solid"
          data-html
        >
          <HelpIcon />
        </StyledHelpIconContainer>

        <div
          className="add-edit-group-modal-switch"
          data-for={'app-tooltip'}
          data-effect="solid"
          data-html
          data-class="center"
          data-tip={MAKE_PORTFOLIO_PRIVATE_TIP}
          data-tip-disable={userCanTogglePrivacy}
        >
          <label className="switch" htmlFor="is-private">
            <input
              type="checkbox"
              checked={isPrivate}
              name="isPrivate"
              onChange={handleCheckChange}
              id="is-private"
              disabled={!userCanTogglePrivacy}
            />
            <div className={cn('slider round', { private: isPrivate })} />
          </label>
        </div>
      </div>
    </div>
    <div className="add-edit-group-modal-footer">
      <div className="add-edit-group-modal-footer-delete">
        {isEditForm && userCanArchive ? (
          <button onClick={archiveModal}>Archive</button>
        ) : (
          ''
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <CancelButton type="reset" onClick={closeModal}>
          Cancel
        </CancelButton>
        <div
          data-for={'app-tooltip'}
          data-effect="solid"
          data-html
          data-class="center"
          data-tip={EDIT_PORTFOLIO_TIP}
          data-tip-disable={userCanEditPortfolio || !isEditForm}
        >
          <SaveButton
            isBlue={boardName}
            type="submit"
            disabled={
              (disableSave && isEditForm) ||
              isCreatingGroup ||
              (!userCanEditPortfolio && isEditForm)
            }
          >
            {isEditForm ? 'Save' : 'Create'}
          </SaveButton>
        </div>
      </div>
    </div>
  </form>
);

AddEditBoardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEditForm: PropTypes.bool.isRequired,
  boardName: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckChange: PropTypes.func.isRequired,
  userCanArchive: PropTypes.bool,
  userCanTogglePrivacy: PropTypes.bool,
  archiveModal: PropTypes.func
};

export default AddEditBoardForm;
