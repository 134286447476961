import 'isomorphic-fetch';
import { callApi } from 'appUtils/api';

export const fetchTeamRoleTemplates = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`teams/${teamId}/team_role_templates`, token, fetchInit);
};

export const updateTeamRoleTemplate = (token, id, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`team_role_templates/${id}`, token, fetchInit);
};

export const fetchOrganizationSettings = (token, id) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  /** TODO: */
  // return callApi(`INSERT ENDPOINT TO FETCH ORG SETTINGS`, token, fetchInit);
};

export const updateOrganizationSettings = (token, id, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  /** TODO: */
  // return callApi(`INSERT ENDPOINT TO UPDATE ORG SETTINGS`, token, fetchInit);
};
