import { select, put } from 'redux-saga/effects';
import {
  updateTeamMembership,
  updateTeamMembershipActionCreatorsMap,
  archiveTeamMembers,
  archiveTeamMembersActionCreatorsMap,
  unarchiveTeamMembers,
  unarchiveTeamMembersActionCreatorsMap,
  hardDeleteTeamMembers,
  hardDeleteTeamMembersActionCreatorsMap
} from './actionCreators';
import { fetchTeamMembers } from 'actionCreators';
import { getAuthToken } from 'AuthenticationModule/selectors';
import { changeEntity as entityHelper } from 'sagas/generics';
import { teamsApi } from './api';

function* updateTeamMembershipWorker(
  action: ReturnType<typeof updateTeamMembership>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    updateTeamMembershipActionCreatorsMap,
    teamsApi.updateTeamMembership,
    [token, action.payload],
    action
  );
}

function* handleModifyTeamMembersSuccess() {
  yield put(fetchTeamMembers());
}

function* archiveTeamMembersWorker(
  action: ReturnType<typeof archiveTeamMembers>
) {
  const { payload, meta } = action;
  const token = yield select(getAuthToken);

  const { response } = yield entityHelper(
    archiveTeamMembersActionCreatorsMap,
    teamsApi.archiveTeamMembers,
    [token, payload],
    action
  );

  if (response) {
    yield handleModifyTeamMembersSuccess();

    if (meta?.onSuccess) {
      meta.onSuccess();
    }
  }
}

function* unarchiveTeamMembersWorker(
  action: ReturnType<typeof unarchiveTeamMembers>
) {
  const token = yield select(getAuthToken);

  const { response } = yield entityHelper(
    unarchiveTeamMembersActionCreatorsMap,
    teamsApi.unarchiveTeamMembers,
    [token, action.payload],
    action
  );

  if (response) {
    yield handleModifyTeamMembersSuccess();
  }
}

function* hardDeleteTeamMembersWorker(
  action: ReturnType<typeof hardDeleteTeamMembers>
) {
  const token = yield select(getAuthToken);

  const { response } = yield entityHelper(
    hardDeleteTeamMembersActionCreatorsMap,
    teamsApi.hardDeleteTeamMembers,
    [token, action.payload],
    action
  );

  if (response) {
    yield handleModifyTeamMembersSuccess();
  }
}

export default {
  updateTeamMembershipWorker,
  archiveTeamMembersWorker,
  unarchiveTeamMembersWorker,
  hardDeleteTeamMembersWorker
};
