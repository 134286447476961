import {
  ActionCreatorWithPreparedPayload,
  createAction
} from '@reduxjs/toolkit';

// This type is used to force the expansion of the `InitialPayloadType` type in
// VSC tooltips.
// Reference: https://stackoverflow.com/a/57683652
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

export const createActionWithMeta = <
  InitialPayloadType = Record<string, never>,
  MetaType = IMeta
>(
  type: string
) =>
  createAction(
    type,
    (params: ActionParamsWithMeta<InitialPayloadType, MetaType>) => {
      if (params === undefined) return { payload: undefined };

      // Typescript would not allow destructuring.
      const payload = { ...params };
      delete payload?.meta;

      return {
        meta: params?.meta,
        payload
      };
    }
  ) as ActionCreatorWithPreparedPayload<
    InitialPayloadType extends Record<string, never>
      ? [] | [Expand<ActionParamsWithMeta<InitialPayloadType, MetaType>>]
      : [Expand<ActionParamsWithMeta<InitialPayloadType, MetaType>>],
    Omit<ActionParamsWithMeta<InitialPayloadType, MetaType>, 'meta'>,
    string,
    never,
    MetaType
  >;
