import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

type PencilIconBlueProps = Pick<
  SvgIconProps,
  'className' | 'width' | 'height' | 'stroke' | 'fill'
>;

const PencilIconBlue = ({
  className,
  stroke = '#2F80ED',
  fill = 'transparent',
  width = '12',
  height = '12'
}: PencilIconBlueProps) => (
  <SvgIcon
    className={className}
    height={height}
    width={width}
    viewBox="0 0 12 12"
  >
    <path
      d="M10.5476 3.13432L4.59382 9.62632L2.22832 7.047L8.20018 0.535331L10.5481 3.09547L10.5571 3.10533C10.5571 3.1114 10.5559 3.11802 10.5537 3.12385C10.5516 3.12949 10.5493 3.13255 10.548 3.13389L10.5476 3.13432ZM0.562439 11.4746L1.24161 8.85414L2.96586 10.734L0.562439 11.4746Z"
      stroke={stroke}
      fill={fill}
    />
  </SvgIcon>
);

export default PencilIconBlue;
