import * as constants from 'SettingsModule/constants/region';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { createAction } from '@reduxjs/toolkit';
import { ValueOf } from 'type-fest';

export const fetchRegions = createAction(
  constants.FETCH_REGIONS.TRIGGER,
  ({ meta, ...payload }: FetchRegionsInitialParams) => ({
    payload,
    meta
  })
);

export const createRegion = createAction<CreateRegionInitialParams>(
  constants.CREATE_REGION.TRIGGER
);

export const updateRegion = createAction<UpdateRegionInitialParams>(
  constants.UPDATE_REGION.TRIGGER
);

export const deleteRegion = createAction<DeleteRegionInitialParams>(
  constants.DELETE_REGION.TRIGGER
);

export const updateRegionEntities =
  createAction<UpdateRegionEntitiesInitialParams>(
    constants.UPDATE_REGION_ENTITIES.TRIGGER
  );

interface FetchRegionsInitialParams {
  teamId: number;
  meta?: IMeta;
}

interface CreateRegionInitialParams {
  teamId: number;
  name: string;
}

interface UpdateRegionInitialParams {
  id: number;
  name: string;
}

interface DeleteRegionInitialParams {
  id: number;
}

interface UpdateRegionEntitiesInitialParams {
  id: number;
  addEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
  removeEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
}
