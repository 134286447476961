import React from 'react';

export const formatMentionedNames = (text) => {
  const mentionRegExp = /\<([^<>]+)\>/;
  const regExp = /\[([^]+)\]/;

  const mentions = mentionRegExp.exec(text);
  const textArray = text.split(mentionRegExp);

  return textArray.map((word, i) => {
    if (regExp.exec(word)) {
      const match = regExp.exec(word)[1];
      return (
        <span key={i} className="mention">
          <b>@{match}</b>
        </span>
      );
    } else {
      return <span key={i}>{word}</span>;
    }
  });
};

export const stripMentionMarkup = (text) => {
  const mentionRegExp = /\<([^<>]+)\>/;
  const regExp = /\[([^]+)\]/;

  const mentions = mentionRegExp.exec(text);
  const textArray = text ? text.split(mentionRegExp) : [];

  const wordArray = textArray.map((word) => {
    if (regExp.exec(word)) {
      const match = regExp.exec(word)[1];
      return match;
    } else {
      return word;
    }
  });

  return wordArray.join('');
};
