import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const TwoWaySortIcon = ({ color = theme.colors.colorRoyalBlue }) => {
  return (
    <SvgIcon
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.50002L5 1.00002L9 5.50002"
        stroke={color}
        strokeWidth="0.75"
      />
      <path d="M9 7.00002L5 11.5L1 7.00002" stroke={color} strokeWidth="0.75" />
    </SvgIcon>
  );
};

export default TwoWaySortIcon;
