import * as constants from 'appConstants';
import { GROUP_BY, VIEW_BY } from 'appConstants/workload';
import omit from 'lodash/omit';
import moment from 'moment';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import { makeIdHash } from 'appUtils';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { FETCH_CAPACITIES } from 'CapacityModule/constants';

const {
  WS_SCHEDULE_BAR,
  CREATE_WORKLOAD_PLANNER,
  FETCH_WORKLOAD_PLANNER,
  UPDATE_WORKLOAD_PLANNER,
  DELETE_WORKLOAD_PLANNER,
  SET_VISIBLE_DATES,
  FLUSH_SCHEDULE_BARS,
  OPEN_WORKLOAD_MEMBER_ROW,
  CLOSE_WORKLOAD_MEMBER_ROW,
  OPEN_ALL_WORKLOAD_MEMBER_ROWS,
  CLOSE_ALL_WORKLOAD_MEMBER_ROWS,
  OPEN_WORKLOAD_PROJECT_ROW,
  CLOSE_WORKLOAD_PROJECT_ROW,
  OPEN_ALL_WORKLOAD_PROJECT_ROWS,
  CLOSE_ALL_WORKLOAD_PROJECT_ROWS,
  TOGGLE_TASK_SIDEBAR_IS_OPEN,
  SET_ZOOM,
  SET_WORKLOAD_SPLIT_SCREEN_ACTIVE,
  SET_WORKLOAD_SPLIT_SCREEN_INACTIVE,
  SET_PLANNER_SPLIT_SCREEN_ACTIVE,
  SET_PLANNER_SPLIT_SCREEN_INACTIVE,
  SET_SPLIT_SCHEDULE_BAR_ID,
  SET_BAR_CONTEXT_MENU_ID,
  FETCH_MEMBER_PROJECTS,
  SET_PROJECT_SUMMARY_ACCOUNT_IDS,
  SET_VISIBLE_INDICES,
  APPROVE_WORKPLAN_REQUESTS,
  OPEN_ACTIVITY_SIDE_MENU,
  CLOSE_ACTIVITY_SIDE_MENU
} = constants;

const byId = (item) => item.id;

const monday = getMondayOfWeek(moment());
const inTwoWeeksMonday = getMondayOfWeek(moment()).add(2, 'week');

const defaultTimeStartEnd = {
  visibleTimeStart: monday,
  visibleTimeEnd: inTwoWeeksMonday
};

const defaultVisibleIndices = [0, 0];

const getGroupId = (bar, groupAttribute, isRequest) => {
  const getField = (field) => {
    // currently does not handle cases where requested_ may not be wanted
    return (isRequest ? 'requested_' : '') + field;
  };

  switch (groupAttribute) {
    case GROUP_BY.PROJECT:
      return `${bar[getField('project_id')] || ''}-${
        bar[getField('phase_id')] || ''
      }-${bar[getField('activity_id')] || ''}-${
        bar[getField('activity_phase_id')] || ''
      }`;
    case GROUP_BY.START_DATE:
      return moment(bar[getField('start_date')]).format('YYYY-MM-DD'); // match BE response for sort counts
    // when account_id is null, group by null-{memberBudgetId}
    case 'account_id':
      return (
        bar[getField('account_id')] ||
        `null-${bar[getField('member_budget_id')]}`
      );
    default:
      return bar[groupAttribute ? getField(groupAttribute) : 'id'];
  }
};

const getNextIsSaving = (currentIsSaving, ids, isSaving) => {
  if (isSaving) {
    return {
      ...currentIsSaving,
      ...ids.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {})
    };
  } else {
    const nextIsSaving = { ...currentIsSaving };
    ids.forEach((id) => {
      delete nextIsSaving[id];
    });
    return nextIsSaving;
  }
};

// For keeping state separate for different parts of the app,
// keyed by an id (eg. location or filterStateId).
// Schedule bar data still stored in main state hash.
// These states should be cleared when no longer in use
const initialWorkplanState = {
  //    eg. { accountId: [workplanIds]}
  //    eg. when there is more nesting (grouped by date then member)
  //   {
  //     'july': ['july-5319', 'july-5312'],
  //     'july-5319': [workplanIds],
  //     'july-5312': [workplanIds]
  //   }
  ordersByGroup: {},
  // eg. ['july', 'aug'] - could also just be workplan Ids when no groupings
  topLevelGroupOrder: [],
  isSelected: {},
  isSaving: {},
  isFetching: false,
  isFetchingInitial: false,
  groupCounts: {},
  totalCount: 0,
  totalFetchedCount: 0
};

export const initialState = {
  fakeScheduleBars: {},
  scheduleBars: {},
  childrenScheduleBars: {},
  workplanStates: {}, // see initialWorkplanState
  offset: 0,
  isLoading: false,
  isLoadingProjects: false,
  totalNumRequests: 0,
  visibleTimes: {
    workload: defaultTimeStartEnd,
    schedule: defaultTimeStartEnd,
    timeline: defaultTimeStartEnd,
    memberModal: defaultTimeStartEnd,
    home: defaultTimeStartEnd
  },
  visibleIndices: defaultVisibleIndices,
  openMembers: {},
  openProjects: {},
  openMembersByProject: {},
  projectSummaryAccountIds: {},
  taskSidebarProjectId: null,
  isTaskSidebarOpen: false,
  isWorkloadSplitScreenActive: false,
  isPlannerSplitScreenActive: false,
  splitScreenProjectId: null,
  splitScreenAccountId: null,
  workloadSplitScreenType: null,
  plannerSplitScreenType: null,
  splitScheduleBarId: null,
  zoom: {
    workload: 66,
    schedule: 66,
    timeline: 66,
    memberModal: 66,
    home: 66
  },
  customStates: {},
  isActivitySideMenuOpen: false
};

const projectPlanner = (state = initialState, action) => {
  const { type, payload } = action;

  const {
    response,
    id,
    visibleTimeStart,
    visibleTimeEnd,
    zoom,
    plannerType,
    visibleIndices,
    workplanStateId,
    initial,
    requestPayload = {}
  } = payload || {};
  const {
    activity_phase_schedule_bars,
    activity_phase_schedule_bar,
    activity_phase_schedule_bars_count,
    sort_attribute_counts,
    projects
  } = response || {};

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FLUSH_SCHEDULE_BARS: {
      return {
        ...state,
        scheduleBars: {}
      };
    }
    case CREATE_WORKLOAD_PLANNER.TRIGGER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case UPDATE_WORKLOAD_PLANNER.SUCCESS: {
      const { wasRequest, workplanStateId, parentGroupId } = requestPayload;

      const nextState = {
        ...state,
        scheduleBars: {
          ...state.scheduleBars,
          [activity_phase_schedule_bar.id]: activity_phase_schedule_bar
        },
        isLoading: false,
        ...(wasRequest && {
          totalNumRequests: Math.max(0, state.totalNumRequests - 1)
        })
      };

      // apsb is no longer a request - remove from order if necessary
      if (wasRequest && workplanStateId) {
        const nextWorkplanState = {
          ...state.workplanStates[workplanStateId],
          ordersByGroup: {
            ...state.workplanStates[workplanStateId]?.ordersByGroup
          }
        };
        if (parentGroupId) {
          nextWorkplanState.ordersByGroup[parentGroupId] =
            nextWorkplanState.ordersByGroup[parentGroupId].filter(
              (id) => id !== activity_phase_schedule_bar.id
            );
          nextWorkplanState.groupCounts = {
            ...nextWorkplanState.groupCounts,
            [parentGroupId]: nextWorkplanState.groupCounts[parentGroupId] - 1
          };
          // Remove the parent group id if its order is empty now
          if (nextWorkplanState.groupCounts[parentGroupId] === 0) {
            nextWorkplanState.topLevelGroupOrder =
              nextWorkplanState.topLevelGroupOrder.filter(
                (groupId) => groupId !== parentGroupId
              );
            delete nextWorkplanState.groupCounts[parentGroupId];
            delete nextWorkplanState.ordersByGroup[parentGroupId];
          }
        } else {
          nextWorkplanState.topLevelGroupOrder =
            nextWorkplanState.topLevelGroupOrder.filter(
              (id) => id !== activity_phase_schedule_bar.id
            );
        }
        nextWorkplanState.totalFetchedCount = Math.max(
          0,
          nextWorkplanState.totalFetchedCount - 1
        );
        nextWorkplanState.totalCount = Math.max(
          0,
          nextWorkplanState.totalCount - 1
        );
        nextState.workplanStates = {
          ...nextState.workplanStates,
          [workplanStateId]: nextWorkplanState
        };
        nextState.totalNumRequests = Math.max(
          0,
          nextState.totalNumRequests - 1
        );
      }

      return nextState;
    }

    case CREATE_WORKLOAD_PLANNER.SUCCESS: {
      const { workplanStateId, groupAttribute, is_request } = requestPayload;
      const nextState = {
        ...state,
        scheduleBars: {
          ...state.scheduleBars,
          [activity_phase_schedule_bar.id]: activity_phase_schedule_bar
        },
        isLoading: false
      };

      if (workplanStateId) {
        const nextWorkplanState = {
          ...state.workplanStates[workplanStateId],
          ordersByGroup: {
            ...state.workplanStates[workplanStateId]?.ordersByGroup
          }
        };
        if (groupAttribute) {
          const groupId = getGroupId(
            activity_phase_schedule_bar,
            groupAttribute,
            activity_phase_schedule_bar.is_request
          );
          if (!nextWorkplanState.ordersByGroup[groupId]) {
            // Add the group id to top level order if it doesn't exist yet
            // (without FE sort this may cause BE/FE order to mismatch)
            nextWorkplanState.topLevelGroupOrder = Array.from(
              new Set([...nextWorkplanState.topLevelGroupOrder, groupId])
            );
          }
          nextWorkplanState.totalFetchedCount++;
          nextWorkplanState.totalCount++;
          nextWorkplanState.ordersByGroup[groupId] = Array.from(
            new Set([
              ...(nextWorkplanState.ordersByGroup[groupId] || []),
              activity_phase_schedule_bar.id
            ])
          );
          nextWorkplanState.groupCounts = {
            ...nextWorkplanState.groupCounts,
            [groupId]: (nextWorkplanState.groupCounts[groupId] || 0) + 1
          };
        } else {
          nextWorkplanState.topLevelGroupOrder = Array.from(
            new Set([
              ...nextWorkplanState.topLevelGroupOrder,
              activity_phase_schedule_bar.id
            ])
          );
        }

        nextState.workplanStates = {
          ...nextState.workplanStates,
          [workplanStateId]: nextWorkplanState
        };
      }

      if (is_request) nextState.totalNumRequests++;

      return nextState;
    }
    case CREATE_WORKLOAD_PLANNER.FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case FETCH_WORKLOAD_PLANNER.TRIGGER: {
      return {
        ...state,
        isLoading: true,
        ...(workplanStateId && {
          workplanStates: {
            ...state.workplanStates,
            [workplanStateId]: {
              ...(initial
                ? initialWorkplanState
                : state.workplanStates[workplanStateId]),
              isFetchingInitial: initial,
              isFetching: true
            }
          }
        })
      };
    }

    case FETCH_WORKLOAD_PLANNER.FAILURE: {
      const { workplanStateId } = requestPayload;
      return {
        ...state,
        isLoading: false,
        ...(workplanStateId && {
          workplanStates: {
            ...state.workplanStates,
            [workplanStateId]: {
              ...state.workplanStates[workplanStateId],
              isFetching: false,
              isFetchingInitial: false
            }
          }
        })
      };
    }

    case FETCH_WORKLOAD_PLANNER.SUCCESS: {
      const {
        workplanStateId,
        groupAttribute,
        isRequest,
        customStateId,
        customFormatter
      } = requestPayload;

      const nonChildrenScheduleBars = activity_phase_schedule_bars.filter(
        ({ parent_id }) => !parent_id
      );

      const childrenScheduleBars = activity_phase_schedule_bars.filter(
        ({ parent_id }) => !!parent_id
      );

      let nextWorkplanStates;
      if (workplanStateId) {
        const currentWorkplanState =
          state.workplanStates[workplanStateId] || initialWorkplanState;
        const ordersByGroup = { ...currentWorkplanState.ordersByGroup };
        const groupCounts = {
          ...currentWorkplanState.groupCounts,
          ...sort_attribute_counts
        };

        if (groupAttribute) {
          const newOrdersByGroup = groupBy(
            nonChildrenScheduleBars,
            (workplan) => getGroupId(workplan, groupAttribute, isRequest)
          );
          // Merge the group orders
          Object.keys(newOrdersByGroup).forEach((groupId) => {
            if (ordersByGroup[groupId]) {
              ordersByGroup[groupId] = Array.from(
                new Set([
                  ...ordersByGroup[groupId],
                  ...newOrdersByGroup[groupId].map((workplan) => workplan.id)
                ])
              );
            } else {
              ordersByGroup[groupId] = newOrdersByGroup[groupId].map(
                (workplan) => workplan.id
              );
            }
          });
          if (groupAttribute === GROUP_BY.PROJECT) {
            // match sort_attribute_counts for activity phases to
            // group id with project id, phase id, etc.
            Object.keys(newOrdersByGroup).forEach((groupId) => {
              if (!groupCounts[groupId]) {
                const [, , , activityPhaseId] = groupId.split('-');
                groupCounts[groupId] = groupCounts[activityPhaseId];
                delete groupCounts[activityPhaseId];
              }
            });
          }
        }

        const nextTopLevelGroupOrder = Array.from(
          new Set([
            ...currentWorkplanState.topLevelGroupOrder,
            ...nonChildrenScheduleBars.map((bar) =>
              getGroupId(bar, groupAttribute, isRequest)
            )
          ])
        );

        nextWorkplanStates = {
          ...state.workplanStates,
          [workplanStateId]: {
            ...currentWorkplanState,
            isFetching: false,
            isFetchingInitial: false,
            ordersByGroup,
            topLevelGroupOrder: nextTopLevelGroupOrder,
            groupCounts,
            totalCount: activity_phase_schedule_bars_count,
            totalFetchedCount:
              currentWorkplanState.totalFetchedCount +
              nonChildrenScheduleBars.length
          }
        };
      } else if (customStateId && customFormatter) {
        const currentCustomState = customFormatter({
          ...response,
          activity_phase_schedule_bars: nonChildrenScheduleBars
        });

        return {
          ...state,
          customStates: {
            ...state.customStates,
            [customStateId]: currentCustomState
          }
        };
      }

      return {
        ...state,
        scheduleBars: {
          ...state.scheduleBars,
          ...keyBy(nonChildrenScheduleBars, byId)
        },
        childrenScheduleBars: {
          ...state.childrenScheduleBars,
          ...keyBy(childrenScheduleBars, byId)
        },
        isLoading: false,
        ...(!workplanStateId &&
          isRequest && {
            totalNumRequests: activity_phase_schedule_bars_count
          }),
        ...(nextWorkplanStates && { workplanStates: nextWorkplanStates })
      };
    }
    case WS_SCHEDULE_BAR: {
      if (action.payload.deleted) {
        return {
          ...state,
          scheduleBars: omit(state.scheduleBars, payload.id)
        };
      } else {
        if (action.payload.parent_id) {
          return {
            ...state,
            childrenScheduleBars: {
              ...state.childrenScheduleBars,
              [payload.id]: action.payload
            }
          };
        }
        return {
          ...state,
          scheduleBars: {
            ...state.scheduleBars,
            [payload.id]: action.payload
          }
        };
      }
    }
    case FETCH_CAPACITIES.SUCCESS: {
      // returns OOO psbs outside current timeline range
      const { account_capacities } = payload.response;
      return {
        ...state,
        scheduleBars: {
          ...state.scheduleBars,
          ...keyBy(
            flatten(
              account_capacities.map(
                (accountCapacity) =>
                  accountCapacity.activity_phase_schedule_bars
              )
            ),
            byId
          )
        }
      };
    }
    case UPDATE_WORKLOAD_PLANNER.TRIGGER: {
      return {
        ...state,
        scheduleBars: {
          ...state.scheduleBars,
          [id]: {
            ...state.scheduleBars[id],
            ...payload
          }
        },
        isLoading: true
      };
    }
    case DELETE_WORKLOAD_PLANNER.TRIGGER: {
      // prevent optimistic updating in work plans table due to it causing unnecessary fetch
      if (!payload?.id || payload.workplanStateId) {
        return state;
      }
      const newScheduleBars = omit(state.scheduleBars, payload.id);
      return {
        ...state,
        scheduleBars: newScheduleBars,
        isLoading: true
      };
    }
    case DELETE_WORKLOAD_PLANNER.SUCCESS: {
      const { workplanStateId, parentGroupId, id, isRequest } = requestPayload;
      const newScheduleBars = omit(state.scheduleBars, payload);
      const nextState = {
        ...state,
        scheduleBars: newScheduleBars,
        isLoading: false
      };

      if (workplanStateId) {
        const nextWorkplanState = {
          ...state.workplanStates[workplanStateId],
          ordersByGroup: {
            ...state.workplanStates[workplanStateId].ordersByGroup
          }
        };
        if (parentGroupId) {
          nextWorkplanState.ordersByGroup[parentGroupId] =
            nextWorkplanState.ordersByGroup[parentGroupId].filter(
              (barId) => barId !== id
            );
          nextWorkplanState.groupCounts = {
            ...nextWorkplanState.groupCounts,
            [parentGroupId]: nextWorkplanState.groupCounts[parentGroupId] - 1
          };
          // Remove the parent group id if its order is empty now
          if (nextWorkplanState.groupCounts[parentGroupId] === 0) {
            nextWorkplanState.topLevelGroupOrder =
              nextWorkplanState.topLevelGroupOrder.filter(
                (groupId) => groupId !== parentGroupId
              );
            delete nextWorkplanState.groupCounts[parentGroupId];
            delete nextWorkplanState.ordersByGroup[parentGroupId];
          }
        } else {
          nextWorkplanState.topLevelGroupOrder =
            nextWorkplanState.topLevelGroupOrder.filter(
              (barId) => barId !== id
            );
        }
        nextWorkplanState.totalFetchedCount--;
        nextWorkplanState.totalCount--;

        nextState.workplanStates = {
          ...nextState.workplanStates,
          [workplanStateId]: nextWorkplanState
        };
      }

      if (isRequest)
        nextState.totalNumRequests = Math.max(
          0,
          nextState.totalNumRequests - 1
        );

      return nextState;
    }

    case APPROVE_WORKPLAN_REQUESTS.TRIGGER: {
      const { workplanStateId, ids } = payload;
      return {
        ...state,
        workplanStates: {
          ...state.workplanStates,
          [workplanStateId]: {
            ...state.workplanStates[workplanStateId],
            isSaving: getNextIsSaving(
              state.workplanStates[workplanStateId].isSaving,
              ids,
              true
            )
          }
        }
      };
    }
    case APPROVE_WORKPLAN_REQUESTS.FAILURE: {
      const { workplanStateId, ids } = payload.requestPayload;
      const currentWorkplanState = state.workplanStates[workplanStateId];
      return {
        ...state,
        workplanStates: {
          ...state.workplanStates,
          [workplanStateId]: {
            ...currentWorkplanState,
            isSaving: getNextIsSaving(currentWorkplanState.isSaving, ids, false)
          }
        }
      };
    }
    case APPROVE_WORKPLAN_REQUESTS.SUCCESS: {
      const { workplanStateId, parentGroupId, removeIdsOnSuccess, ids } =
        payload.requestPayload;

      const nextWorkplanState = {
        ...state.workplanStates[workplanStateId],
        isApproving: false,
        isSaving: getNextIsSaving(
          state.workplanStates[workplanStateId].isSaving,
          ids,
          false
        )
      };
      if (removeIdsOnSuccess) {
        const toRemoveIds = new Set(ids);
        if (parentGroupId) {
          nextWorkplanState.ordersByGroup = {
            ...nextWorkplanState.ordersByGroup,
            [parentGroupId]: nextWorkplanState.ordersByGroup[
              parentGroupId
            ].filter((id) => !toRemoveIds.has(id))
          };
        } else {
          nextWorkplanState.topLevelGroupOrder =
            nextWorkplanState.topLevelGroupOrder.filter(
              (id) => !toRemoveIds.has(id)
            );
        }
      }
      return {
        ...state,
        workplanStates: {
          ...state.workplanStates,
          [workplanStateId]: nextWorkplanState
        }
      };
    }

    case OPEN_WORKLOAD_MEMBER_ROW: {
      const { accountId, projectId } = action.payload;
      if (projectId) {
        return {
          ...state,
          openMembersByProject: {
            ...state.openMembersByProject,
            [projectId]: {
              ...(state.openMembersByProject[projectId] || {}),
              [accountId]: true
            }
          }
        };
      }
      return {
        ...state,
        openMembers: {
          ...state.openMembers,
          [accountId]: true
        }
      };
    }
    case CLOSE_WORKLOAD_MEMBER_ROW: {
      const { accountId, projectId } = action.payload;
      if (projectId) {
        return {
          ...state,
          openMembersByProject: {
            ...state.openMembersByProject,
            [projectId]: {
              ...(state.openMembersByProject[projectId] || {}),
              [accountId]: false
            }
          }
        };
      }
      return {
        ...state,
        openMembers: {
          ...state.openMembers,
          [accountId]: false
        }
      };
    }
    case OPEN_ALL_WORKLOAD_MEMBER_ROWS: {
      const { accountIds } = action.payload;
      return {
        ...state,
        openMembers: makeIdHash(accountIds)
      };
    }
    case CLOSE_ALL_WORKLOAD_MEMBER_ROWS: {
      return {
        ...state,
        openMembers: {}
      };
    }
    case OPEN_WORKLOAD_PROJECT_ROW: {
      const { projectId } = action.payload;
      return {
        ...state,
        openProjects: {
          ...state.openProjects,
          [projectId]: true
        }
      };
    }
    case CLOSE_WORKLOAD_PROJECT_ROW: {
      const { projectId } = action.payload;
      return {
        ...state,
        openProjects: {
          ...state.openProjects,
          [projectId]: false
        }
      };
    }
    case OPEN_ALL_WORKLOAD_PROJECT_ROWS: {
      const { projectIds } = action.payload;
      return {
        ...state,
        openProjects: makeIdHash(projectIds)
      };
    }
    case CLOSE_ALL_WORKLOAD_PROJECT_ROWS: {
      return {
        ...state,
        openProjects: {}
      };
    }
    case SET_VISIBLE_DATES: {
      return {
        ...state,
        visibleTimes: {
          ...state.visibleTimes,
          [plannerType]: {
            visibleTimeStart,
            visibleTimeEnd
          }
        }
      };
    }
    case SET_VISIBLE_INDICES: {
      return {
        ...state,
        visibleIndices
      };
    }
    case SET_ZOOM: {
      return {
        ...state,
        zoom: { ...state.zoom, [plannerType]: zoom }
      };
    }
    case SET_WORKLOAD_SPLIT_SCREEN_ACTIVE: {
      return {
        ...state,
        isWorkloadSplitScreenActive: true,
        splitScreenAccountId:
          action.payload.splitScreenAccountId || state.splitScreenAccountId,
        workloadSplitScreenType:
          action.payload.workloadSplitScreenType ||
          state.workloadSplitScreenType
      };
    }
    case SET_WORKLOAD_SPLIT_SCREEN_INACTIVE: {
      return {
        ...state,
        isWorkloadSplitScreenActive: false,
        splitScreenAccountId: null,
        workloadSplitScreenType: null
      };
    }
    case SET_PLANNER_SPLIT_SCREEN_ACTIVE: {
      return {
        ...state,
        isPlannerSplitScreenActive: true,
        splitScreenProjectId:
          action.payload.splitScreenProjectId || state.splitScreenProjectId,
        plannerSplitScreenType:
          action.payload.plannerSplitScreenType || state.plannerSplitScreenType
      };
    }
    case SET_PLANNER_SPLIT_SCREEN_INACTIVE: {
      return {
        ...state,
        isPlannerSplitScreenActive: false,
        splitScreenProjectId: null,
        plannerSplitScreenType: null
      };
    }
    case SET_SPLIT_SCHEDULE_BAR_ID: {
      return {
        ...state,
        splitScheduleBarId: action.payload.itemId
      };
    }
    case SET_BAR_CONTEXT_MENU_ID: {
      return {
        ...state,
        contextMenuScheduleBarId: action.payload.itemId
      };
    }
    case TOGGLE_TASK_SIDEBAR_IS_OPEN: {
      const { projectId, isOpen } = action.payload;
      return {
        ...state,
        taskSidebarProjectId: projectId,
        isTaskSidebarOpen: isOpen
      };
    }
    case SET_PROJECT_SUMMARY_ACCOUNT_IDS: {
      const { accountIds, projectId } = action.payload;
      return {
        ...state,
        projectSummaryAccountIds: {
          ...state.projectSummaryAccountIds,
          [projectId]: keyBy(accountIds)
        }
      };
    }
    case FETCH_MEMBER_PROJECTS.TRIGGER: {
      const { offset, limit } = action.payload;
      return {
        ...state,
        offset: (offset !== undefined ? offset : state.offset) + (limit || 0),
        isLoadingProjects: true
      };
    }
    case FETCH_MEMBER_PROJECTS.SUCCESS: {
      return {
        ...state,
        isLoadingProjects: false
      };
    }
    case OPEN_ACTIVITY_SIDE_MENU: {
      return {
        ...state,
        isActivitySideMenuOpen: true
      };
    }
    case CLOSE_ACTIVITY_SIDE_MENU: {
      return {
        ...state,
        isActivitySideMenuOpen: false
      };
    }
    default:
      return state;
  }
};

export default projectPlanner;
