import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import {
  getTeamMembershipsByAccountId,
  getSelectedTeamId,
  getSortedTeamMembers,
  makeGetEditOrViewIdsHash,
  makeGetEditIdsHash,
  makeGetViewIdsHash,
  getMe,
  getBulkAddIsOpen,
  getBulkAddIsPopulated
} from 'selectors';
import {
  updateAccess,
  closeAccessModal,
  openAddMembersForm,
  closeBulkAddMembersForm
} from 'actionCreators';
import {
  ACCESS_LABELS,
  ACCESS_BUCKETS,
  BUDGET_ACCESS_ROLES
} from 'appConstants/access';
import {
  HeaderContainer,
  Subtext,
  StyledModalHeader,
  StyledModalBody,
  DoneButton,
  VisibilityMemberRow,
  StyledDropdownToggle
} from './styles';
import { Modal } from 'reactstrap';
import AccessModalBody from './AccessModalBody';
import { calculateHighestTeamMembershipRoleLevel } from 'appUtils/roleDisplay';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

const { BUDGET_MANAGER, BUDGET_MANAGER_VIEW_ONLY } = BUDGET_ACCESS_ROLES;
const { CAN_EDIT_BUCKETS, CAN_VIEW_BUCKETS, NO_BUCKETS, ALL_BUCKETS } =
  ACCESS_BUCKETS;

const removeOption = {
  label: ACCESS_LABELS.REMOVE,
  isValidAdminRole: false,
  value: {
    addableRoleIds: [],
    removableRoleIds: [BUDGET_MANAGER, BUDGET_MANAGER_VIEW_ONLY],
    addableAccessBuckets: NO_BUCKETS,
    removableAccessBuckets: ALL_BUCKETS
  }
};

const options = [
  {
    label: ACCESS_LABELS.CAN_EDIT,
    isValidAdminRole: true,
    value: {
      addableRoleIds: [BUDGET_MANAGER],
      removableRoleIds: [BUDGET_MANAGER_VIEW_ONLY],
      addableAccessBuckets: CAN_EDIT_BUCKETS,
      removableAccessBuckets: CAN_VIEW_BUCKETS
    }
  },
  {
    label: ACCESS_LABELS.VIEW_ONLY,
    isValidAdminRole: false,
    value: {
      addableRoleIds: [BUDGET_MANAGER_VIEW_ONLY],
      removableRoleIds: [BUDGET_MANAGER],
      addableAccessBuckets: CAN_VIEW_BUCKETS,
      removableAccessBuckets: CAN_EDIT_BUCKETS
    }
  },
  removeOption
];

const StyledModalFooter = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid ${theme.colors.colorMediumGray11};
`;

const StyledModal = styled(Modal)`
  &.workload-access-modal.modal-dialog {
    max-width: 478px;
  }
  .modal-content {
    height: 540px;
  }
  ${StyledModalHeader} {
    padding: 40px 50px 23px;
  }
  ${StyledModalBody} {
    padding: 0 50px 20px;
  }
  ${VisibilityMemberRow} {
    padding-left: 0;
    padding-right: 0;
  }
  ${StyledDropdownToggle} {
    padding-right: 10px;
  }
`;

class BudgetAccessModal extends React.Component {
  getInfoCopy = () => {
    const { editOrViewIds } = this.props;
    if (!editOrViewIds) {
      return '';
    }
    return `Visible to ${Object.keys(editOrViewIds).length}`;
  };

  handleSelect = (roles, member) => {
    const { updateAccess, projectId } = this.props;
    const {
      addableRoleIds,
      removableRoleIds,
      isValidAdminRole,
      addableAccessBuckets,
      removableAccessBuckets
    } = roles;
    if (isValidAdminRole || !member.is_admin) {
      updateAccess({
        actableId: projectId,
        actableType: 'Project',
        actionType: 'member_budgets',
        accountIds: [member.account.id],
        addableRoleIds,
        removableRoleIds,
        addableAccessBuckets,
        removableAccessBuckets
      });
    }
  };

  handleAdd = (member) => {
    this.handleSelect({ addableRoleIds: [5], removableRoleIds: [] }, member);
  };

  getMembers = () => {
    const { sortedTeamMembers, editIds, editOrViewIds } = this.props;

    const includedMembers = sortedTeamMembers
      .filter((member) => editOrViewIds[member?.account?.id])
      .map((member) => ({
        ...member,
        label: editIds[member.account.id]
          ? ACCESS_LABELS.CAN_EDIT
          : ACCESS_LABELS.VIEW_ONLY
      }))
      .sort(
        (a, b) =>
          calculateHighestTeamMembershipRoleLevel(b) -
          calculateHighestTeamMembershipRoleLevel(a)
      );
    return includedMembers;
  };

  getNonMembers = () => {
    const { sortedTeamMembers, editOrViewIds } = this.props;

    return sortedTeamMembers.filter(
      (membership) => !editOrViewIds[membership?.account?.id]
    );
  };

  isMemberEditable = (member) => {
    const { workloadEditIds } = this.props;
    return false;
    // return !member.is_admin && !workloadEditIds[member?.account?.id];
  };

  renderTitle = () => {
    return (
      <HeaderContainer>
        <div>Budget Access</div>
        <Subtext>
          Only Financial Managers and Admins <br /> can view fee budgets and
          edit dollars & rates.
        </Subtext>
      </HeaderContainer>
    );
  };

  handleOpenAddMember = () => {
    const { project, openAddMembersForm } = this.props;
    openAddMembersForm(MODAL_TYPE.PROJECT, project);
  };

  handleCloseModal = () => {
    const { closeBulkAddMembersForm, closeAccessModal } = this.props;
    closeBulkAddMembersForm();
    closeAccessModal();
  };

  render() {
    const { isOpen, me } = this.props;
    return (
      <StyledModal
        isOpen={isOpen}
        toggle={this.handleCloseModal}
        className="workload-access-modal"
      >
        <StyledModalHeader>
          <div>Budget Access</div>
          <DoneButton onClick={this.handleCloseModal}>Done</DoneButton>
        </StyledModalHeader>

        <AccessModalBody
          handleAdd={this.handleAdd}
          members={this.getMembers()}
          nonMembers={this.getNonMembers()}
          options={options}
          removeOption={removeOption}
          handleSelect={this.handleSelect}
          currentUser={me ? me.id : null}
          isMemberEditable={this.isMemberEditable}
        />
        <StyledModalFooter>
          Only Financial Managers and Admins <br /> can view fee budgets with
          dollars & rates.
        </StyledModalFooter>
      </StyledModal>
    );
  }
}

const makeMapStateToProps = () => {
  const getEditOrViewIdsHash = makeGetEditOrViewIdsHash();
  const getEditIdsHash = makeGetEditIdsHash();
  const getWorkloadEditIdsHash = makeGetEditIdsHash();
  const getViewIdsHash = makeGetViewIdsHash();
  const mapStateToProps = (state, ownProps) => ({
    teamMembershipsByAccountId: getTeamMembershipsByAccountId(state),
    sortedTeamMembers: getSortedTeamMembers(state),
    teamId: getSelectedTeamId(state),
    editIds: getEditIdsHash(state, ownProps),
    viewIds: getViewIdsHash(state, ownProps),
    editOrViewIds: getEditOrViewIdsHash(state, ownProps),
    workloadEditIds: getWorkloadEditIdsHash(
      state,
      ownProps.workloadAccessProps
    ),
    isOpen: state.access.modalIdentifier === ownProps.accessIdentifier,
    me: getMe(state),
    bulkAddIsOpen: getBulkAddIsOpen(state),
    bulkAddIsPopulated: getBulkAddIsPopulated(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccess,
  closeAccessModal,
  openAddMembersForm,
  closeBulkAddMembersForm
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(BudgetAccessModal);
