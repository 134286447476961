import * as constants from '../constants/office';
import { action } from 'appUtils';

export const fetchOfficesActionCreatorsMap = {
  request: (id, requestPayload) =>
    action(constants.FETCH_OFFICES.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_OFFICES.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_OFFICES.FAILURE, {
      payload: { error },
      meta
    })
};

export const createOfficeActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.CREATE_OFFICE.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_OFFICE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.CREATE_OFFICE.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const updateOfficeMetadataActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_OFFICE_METADATA.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_OFFICE_METADATA.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_OFFICE_METADATA.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const updateOfficeLocationActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_OFFICE_LOCATION.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_OFFICE_LOCATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_OFFICE_LOCATION.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const deleteOfficeActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.DELETE_OFFICE.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.DELETE_OFFICE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_OFFICE.FAILURE, {
      payload: { error }
    })
};

export const updateOfficeEntitiesActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_OFFICE_ENTITIES.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.UPDATE_OFFICE_ENTITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_OFFICE_ENTITIES.FAILURE, {
      payload: { error }
    })
};
