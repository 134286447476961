import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector } from 'react-redux';
import { getAccountCapacities } from 'CapacityModule/selectors';
import { getIncompleteTimesheetDates } from 'selectors';
import { makeGetMemberTimesheetStatusTotalsByDate } from 'UtilizationModule/selectors';
import moment from 'moment';
import { getFormattedChartData } from 'ReportsModule/components/Time/TimesheetStatusTable/utils';
import StatusPieChart, {
  StyledHours
} from 'ReportsModule/components/Time/TimesheetStatusTable/Cells/shared/StatusPieChart';
import { ReactComponent as GoToDateIcon } from 'icons/double-back-karat.svg';
const DateContainer = styled.div`
  margin-top: 10px;
  height: 285px;
  overflow: scroll;
  overflow-x: hidden;
  margin-left: -21px;
  width: 370px;
`;

const GoToDateIconContainer = styled.div`
  visibility: hidden;
  margin-top: -1px;
  margin-right: 6px;
`;

const DateRow = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 315px;
  font-weight: 600;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${GoToDateIconContainer} {
      visibility: visible;
    }
  }
  cursor: pointer;
`;

const DateText = styled.div`
  display: flex;
  z-index: 10000;
`;

const ChartContainer = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 90px;
  ${StyledHours} {
    font-size: 11px;
    color: ${theme.colors.colorMediumGray9};
    font-weight: 600;
    margin-top: 2px;
  }
`;

const IncompleteModalBody = ({
  dateRowStyles = {},
  dateContainerStyles = {},
  id,
  handleDateNav
}) => {
  const incompleteTimesheetDatesByAccount = useSelector(
    getIncompleteTimesheetDates
  );
  const incompleteTimesheetDates = incompleteTimesheetDatesByAccount[id] ?? {};
  const incompleteTimesheetDateKeys = Object.keys(incompleteTimesheetDates);

  const getMemberTimesheetStatusTotalsByDate = useMemo(
    makeGetMemberTimesheetStatusTotalsByDate,
    []
  );
  const memberTimesheetStatusTotalsByDate = useSelector((state) =>
    getMemberTimesheetStatusTotalsByDate(state, {
      filterStateId: 'incompleteModal'
    })
  );

  const dailyData = memberTimesheetStatusTotalsByDate[id];
  const accountCapacities = useSelector(getAccountCapacities);
  const accountCapacity = accountCapacities[id] || {};

  const onNavToDate = (date) => {
    handleDateNav(date);
  };

  const renderDate = (date) => {
    const formattedDate = moment(date).format('ddd, MMM D');
    const day = moment(date).format('dddd').toLowerCase();

    const data = dailyData ? dailyData[date] : {};

    const chartData = {
      ...getFormattedChartData({
        data,
        capacity: accountCapacity[day] || 0,
        statusView: 'entered',
        notEnteredColor: theme.colors.colorTranslucentGray4
      })
    };

    return (
      <DateRow style={dateRowStyles} onClick={() => onNavToDate(date)}>
        <DateText>
          <GoToDateIconContainer>
            <GoToDateIcon />
          </GoToDateIconContainer>
          {formattedDate}
        </DateText>
        <ChartContainer>
          <StatusPieChart data={chartData} />
        </ChartContainer>
      </DateRow>
    );
  };

  return (
    <DateContainer style={dateContainerStyles}>
      {incompleteTimesheetDateKeys.map((date) => renderDate(date))}
    </DateContainer>
  );
};

export default IncompleteModalBody;
