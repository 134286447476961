import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CollapseAllArrows from 'icons/CollapseAllArrows';
import theme from 'theme';
const emptyObj = {};

export const ExpandCollapseAllContainer = styled.div`
  position: relative;
  left: -3px;
  height: 28px;
  width: 28px;
  border-radius: 100px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)'};
  background: ${({ isCollapsed, isOnPersonalProject }) =>
    isCollapsed && isOnPersonalProject
      ? theme.colors.colorTranslucentGray4
      : isCollapsed
      ? theme.colors.colorPureWhite
      : 'transparent'};
  border: 1px solid
    ${({ isCollapsed }) =>
      isCollapsed ? theme.colors.colorPaleGray5 : 'transparent'};
  path {
    fill: ${({ isCollapsed }) =>
      isCollapsed
        ? theme.colors.colorSemiDarkGray1
        : theme.colors.colorMediumGray4};
  }
  ${({ noBackground }) =>
    noBackground &&
    `
    background: transparent;
    border: transparent;
  `}
  &:hover {
    background: ${theme.colors.colorPureWhite};
    ${({ hoverBorderColor }) =>
      hoverBorderColor &&
      `
      border: 1px solid ${hoverBorderColor};
    `}
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const CollapseAllButton = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    props.onToggle(!isOpen);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if ('isCollapsed' in props && props.isCollapsed === isOpen) {
      setIsOpen(!props.isCollapsed);
    }
  }, [isOpen, props]);

  return (
    <ExpandCollapseAllContainer
      onClick={handleToggle}
      isCollapsed={props.isCollapsed || !isOpen}
      data-for="app-tooltip"
      data-effect="solid"
      data-tip={!isOpen ? 'Click to Expand All' : 'Click to Collapse All'}
      style={props.style || emptyObj}
      className={props.className}
      data-testid={props.dataTestId || 'collapse-all'}
      isOnPersonalProject={props.isOnPersonalProject}
      hoverBorderColor={props.hoverBorderColor}
      noBackground
    >
      <CollapseAllArrows height="15" width="15" />
    </ExpandCollapseAllContainer>
  );
};

export default CollapseAllButton;
