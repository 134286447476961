import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledEmail = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

const EmailCell = ({ row }) => {
  if (!row.original.member) {
    return <div> </div>;
  }

  return <StyledEmail>{row.original.email || ''}</StyledEmail>;
};

export default EmailCell;
