import styled from 'styled-components';
import theme from 'theme';

import QBlogoImage from 'images/qblogo.png';
import QBThumbLogo from 'images/qb_thumb.png';
import Logo from 'images/mosaic-logo.png';
import DisconnectQBIcon from 'icons/DisconnectQBIcon';

export const StyledButton = styled.div`
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  background: ${theme.colors.colorBudgetGreen};
  border-radius: 4px;
  color: ${theme.colors.colorPureWhite};
  cursor: pointer;
  padding: 2px 15px;
  margin: 0px 10px;

  &:hover {
    background: ${theme.colors.colorSeaGreen};
  }
`;

export const Container = styled.div`
  flex-grow: 1;
`;
export const QuickbooksHeaderContainer = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 600;
  background: ${theme.colors.colorTranslucentGray4};
  z-index: 2;
  padding-bottom: 5px;
  position: sticky;
  top: 0px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${theme.colors.colorMediumGray2};
`;

export const QuickBooksHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const ConnectButton = styled.div`
  margin: 0px 10px;
  background: #2c9f1c;
  width: 80px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  .sk-circle {
    position: absolute;
    height: 15px;
    width: 15px;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }
`;

export const QBLogo = styled.div`
  width: 150px;
  height: 40px;
  background: url(${QBlogoImage}) center center no-repeat;
  background-size: contain;
`;

export const QBThumb = styled.div`
  width: 24px;
  height: 24px;
  background: url(${QBThumbLogo}) center center no-repeat;
  background-size: contain;
  margin-right: 10px;
`;

export const MosaicLogo = styled.div`
  width: 100px;
  height: 42px;
  background: url(${Logo}) center center no-repeat;
  background-size: contain;
`;

export const StyledLegendContainer = styled.div`
  padding: 0px 40px;
  font-size: 13px;
  margin-top: 80px;
  display: flex;
  justify-content: center;

  table {
    border-collapse: collapse;
    td {
      border: 1px solid ${theme.colors.colorPaleGray5};
      padding: 5px 10px;
      min-width: 120px;
    }
    th {
      padding: 5px 10px;
      border: 1px solid ${theme.colors.colorLightGray6};
    }
    tr:first-child th {
      border-top: 0;
    }
    tr:last-child td {
      border-bottom: 0;
    }
    tr td:first-child,
    tr th:first-child {
      border-left: 0;
    }
    tr td:last-child,
    tr th:last-child {
      border-right: 0;
    }
  }
`;

export const EmptyTextBody = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray5};
  padding-left: 25px;
  margin: 10px 0px;
  max-width: 600px;
`;

export const LoadingWheelContainer = styled.div`
  width: 100%;
`;

export const StyledPrimaryButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  border-radius: 5px;
  font-size: 15px;
  padding: 4px 16px;
  border: none;
  margin-right: 10px;
  color: ${theme.colors.colorPureWhite};
  &:hover {
    background: ${theme.colors.colorDeeperRoyalBlue};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const QuickBooksDropdownItem = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.colorPaleGray5};
  }
`;

export const StyledDisconnectQBIcon = styled(DisconnectQBIcon)`
  margin-right: 6px;
  height: 14px;
  width: 14px;
`;
