import { useAppSelector } from 'reduxInfra/hooks';
import { getAlphabeticalCurrentPositionIds } from 'BudgetModule/selectors/positions';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Position } from 'models/position';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { usePositions } from 'BudgetModule/hooks';

const emptyArray = [];
const emptyObj = {};

/**
 * Requires Budget module
 */
export const usePositionsFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.position_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const { handleNavToStandardPositionsPage, positionHash } = usePositions({
    isOff
  });

  /** Array of alphabetically sorted ids */
  const positionIdsArray: number[] = useAppSelector((state) =>
    isOff ? emptyArray : getAlphabeticalCurrentPositionIds(state)
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: positionIdsArray,
    itemHash: positionHash,
    isOff,
    config,
    shouldUseDraft,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: positionHash,
    isLoading: isResultsLoading,
    labelKey,
    handleNavToEntityPage: handleNavToStandardPositionsPage,
    resultCountHash,
    filterLabel: config?.filterLabel
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'name';

const searchableKeys: (keyof Position)[] = [labelKey];
