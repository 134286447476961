import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSkill } from 'actionCreators';
import { getSelectedTeamId, getSkillsCount, getSkillsByName } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import CollapseAllButton, {
  ExpandCollapseAllContainer
} from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';

const AddSpan = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 5px;
  padding: 1px 7px;
`;

const StyledOptions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-bottom: 15px;

  ${AddSpan} {
    margin-left: 6px;
  }
  ${ExpandCollapseAllContainer} {
    path {
      fill: ${theme.colors.colorSemiDarkGray6};
      &:hover {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const SkillsTableOptions = ({
  toggleCollapseAll,
  allCollapsed,
  scrollToTop
}) => {
  const dispatch = useDispatch();
  const skillsCount = useSelector(getSkillsCount);
  const skillsByName = useSelector(getSkillsByName);
  const teamId = useSelector(getSelectedTeamId);

  const handleCreate = () => {
    const makeNewName = () => {
      let count = skillsCount + 1;
      while (true) {
        const potentialName = `Skill number ${count}`;
        if (!skillsByName[potentialName]) return potentialName;
        count++;
      }
    };

    dispatch(
      createSkill({
        teamId,
        name: makeNewName()
      })
    );
    scrollToTop();
  };
  return (
    <StyledOptions>
      {skillsCount > 0 && (
        <CollapseAllButton
          onToggle={toggleCollapseAll}
          isCollapsed={allCollapsed}
        />
      )}
      <AddSpan onClick={handleCreate}>+ Skill</AddSpan>
    </StyledOptions>
  );
};

export default SkillsTableOptions;
