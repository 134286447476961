import * as appConstants from 'appConstants';
import * as constants from '../constants';
import produce from 'immer';

export const initialState = {
  attachmentHash: {},
  fetchedAttachmentIds: {}
};

const attachments = (state = initialState, action) => {
  const { payload, type } = action;

  return produce(state, (nextState) => {
    switch (type) {
      case appConstants.LOGOUT_USER: {
        nextState = initialState;
        break;
      }

      /* ----------------- WEBSOCKET ----------------- */
      case constants.WS_ATTACHMENT: {
        const attachment = action.payload;
        const { id, deleted } = attachment;

        nextState.attachmentHash[id] = attachment;

        break;
      }
    }
  });
};

export default attachments;
