import React, { useState, useRef, useContext } from 'react';
import theme from 'theme';
import { connect, useDispatch } from 'react-redux';
import { makeGetBoardById } from 'selectors';
import {
  addMemberToProject,
  fetchPhasesByProjectIds,
  createPhaseMembers,
  createActivityMembers,
  navigateToProject,
  openEditProjectModal
} from 'actionCreators';
import {
  StyledProjectInfo,
  StyledProjectTitle,
  StyledProjectDescription,
  StyledPhaseName,
  StyledPhaseNumber,
  StyledActivityNameContainer,
  StyledActivityName,
  PhaseProjectListItem,
  StyledContainer,
  StyledTopCell,
  StyledMiddleCell,
  StyledMiddleContents,
  StyledBottomCell,
  StyledProjectNumber,
  StyledFolderIcon,
  StyledTeamName,
  StyledDot,
  StyledKaratContainer,
  StyledThreeDotMenuItem,
  StyledAddActivityButtonContainer,
  StyledPhaseDate,
  StyledPhaseInfo,
  StyledPhaseThreeDotContainer,
  StyledActivityInfo,
  StyledActivityPhaseDate,
  StyledProjectThreeDotContainer,
  StyledMilestoneIconContainer,
  StyledAddText,
  StyledGoToProjectIcon,
  StyledAddPhaseIcon,
  StyledInfoIcon,
  StyledSuggestedContainer,
  StyledPhaseNameContainer,
  LockIconContainer
} from './styles';
import Popover from 'components/Popover';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import KaratRight from 'icons/KaratRight';
import LockIcon from 'icons/LockIcon';
import ListItemBudgetTotals from './ListItemBudgetTotals';
const emptyObj = {};
export const RowContext = React.createContext();

const ProjectThreeDotMenuOptions = ({
  isOpen,
  target,
  handleClose,
  onAddPhasesClick,
  onGoToProject,
  onOpenProjectInfo,
  popoverClassName
}) => {
  return isOpen ? (
    <Popover
      className={`${popoverClassName} project-selector-add-phase`}
      isOpen
      target={target}
      closePopover={handleClose}
      insideAnotherPopover
    >
      <StyledThreeDotMenuItem onClick={onAddPhasesClick}>
        <StyledAddPhaseIcon width={12} height={13} />
        <span style={{ marginLeft: '6px', marginTop: '1px' }}>
          {' '}
          Add Phases{' '}
        </span>
        <LockIconContainer>
          <LockIcon height={10} width={8} fill="#4f4f4f" />
        </LockIconContainer>
      </StyledThreeDotMenuItem>
      <StyledThreeDotMenuItem onClick={onGoToProject}>
        <StyledGoToProjectIcon
          color={theme.colors.colorMediumGray9}
          width={24}
          height={23}
        />
        <span style={{ marginLeft: '0px', marginTop: '1px' }}>
          {' '}
          Go to Project{' '}
        </span>
      </StyledThreeDotMenuItem>
      <StyledThreeDotMenuItem onClick={onOpenProjectInfo}>
        <StyledInfoIcon
          fill={theme.colors.colorMediumGray9}
          width={12}
          height={13}
          color={theme.colors.colorMediumGray9}
        />
        <span style={{ marginLeft: '7px', marginTop: '1px' }}>
          {' '}
          View Project Info{' '}
        </span>
      </StyledThreeDotMenuItem>
    </Popover>
  ) : null;
};
const PhaseThreeDotMenuOptions = ({
  isOpen,
  target,
  handleClose,
  onAddActivitiesClick
}) => {
  return isOpen ? (
    <Popover
      className="project-selector-add-activity"
      isOpen
      target={target}
      closePopover={handleClose}
      insideAnotherPopover
    >
      <StyledAddActivityButtonContainer onClick={onAddActivitiesClick}>
        <span> Add Work Category </span>
        <LockIcon height={10} width={8} fill="#4f4f4f" />
      </StyledAddActivityButtonContainer>
    </Popover>
  ) : null;
};
const noop = () => {};

const AddTo = ({ handleAddTo, addText }) => (
  <StyledAddText onClick={handleAddTo}>{addText}</StyledAddText>
);

const smallIconStyles = { height: 9, width: 9 };
export const PhaseRow = ({
  item,
  board,
  showSelectPhaseText,
  itemHeight,
  showActivities = true,
  budgetTotalsWidth,
  accountId,
  leftOffset,
  small,
  shouldShowBudgetInfo,
  hideDate = false
}) => {
  const dispatch = useDispatch();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const target = useRef(null);
  const handleDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownIsOpen(true);
  };
  // activity functionality needs to be implemented in parent if active, otherwise is noop
  const { onAddActivitiesClick = noop, setPopoverTarget = noop } =
    useContext(RowContext) || {};

  const handleAddActivitiesClick = () => {
    onAddActivitiesClick({ phaseId: item.id, projectId: item.project_id });
    setPopoverTarget(target);
  };
  const handleAddTo = () => {
    if (accountId && item.project_id && item.id) {
      dispatch(
        createPhaseMembers({
          projectId: item.project_id,
          phaseId: item.id,
          accountIds: [accountId]
        })
      );
    }
  };

  const shouldShowPhaseDate =
    (item.start_date || !small) &&
    !hideDate /* maintain spacing on larger dropdown */ &&
    item.start_date;

  const shouldShowActivities = item.hasActivities && showActivities;
  const isBudgetVisible =
    !shouldShowActivities &&
    shouldShowBudgetInfo &&
    item.budgetTotalsAccountIsMember;
  const isAddToPhaseVisible = !shouldShowActivities && accountId;
  return (
    <StyledContainer leftOffset={leftOffset}>
      <StyledPhaseInfo
        className={small ? 'is-small' : ''}
        hasActivities={shouldShowActivities}
      >
        {item.phase_number !== null && (
          <StyledPhaseNumber>{item.phase_number}</StyledPhaseNumber>
        )}
        <StyledPhaseNameContainer>
          <StyledMilestoneIconContainer
            top={small ? '-1px' : shouldShowPhaseDate ? '1px' : '0'}
          >
            {item?.is_budget ? (
              <BudgetPhaseMilestoneIcon {...(small ? smallIconStyles : {})} />
            ) : (
              <PhaseMilestoneIcon {...(small ? smallIconStyles : {})} />
            )}
          </StyledMilestoneIconContainer>
          <StyledPhaseName>
            {item.is_budget && item.is_like_default
              ? 'Project Schedule'
              : item.title}
          </StyledPhaseName>
          {item.isSuggested && (
            <StyledSuggestedContainer>Suggested</StyledSuggestedContainer>
          )}
          {showActivities && (
            <StyledPhaseThreeDotContainer
              onClick={handleDropdownClick}
              ref={target}
            >
              <ProjectThreeDotMenu />
              <PhaseThreeDotMenuOptions
                onAddActivitiesClick={handleAddActivitiesClick}
                isOpen={dropdownIsOpen}
                handleClose={() => setDropdownIsOpen(false)}
                target={target}
              />
            </StyledPhaseThreeDotContainer>
          )}
        </StyledPhaseNameContainer>
        {shouldShowPhaseDate && (
          <StyledPhaseDate>
            {item.start_date} - {item.end_date}
          </StyledPhaseDate>
        )}
        <StyledBottomCell>
          {isBudgetVisible ? (
            <ListItemBudgetTotals
              width={budgetTotalsWidth || 230}
              totals={item.budgetTotals || emptyObj}
            />
          ) : (
            isAddToPhaseVisible && (
              <AddTo handleAddTo={handleAddTo} addText={'Add to Phase'} />
            )
          )}
        </StyledBottomCell>
      </StyledPhaseInfo>
    </StyledContainer>
  );
};

const ActivityRow = ({
  item,
  board,
  showSelectPhaseText,
  itemHeight,
  accountId,
  small,
  hideDate,
  shouldShowBudgetInfo
}) => {
  const dispatch = useDispatch();
  const handleAddTo = () => {
    if (accountId && item.project_id && item.activityPhase?.id) {
      dispatch(
        createActivityMembers({
          projectId: item.project_id,
          activityPhaseId: item.activityPhase.id,
          accountIds: [accountId]
        })
      );
    }
  };

  const shouldShowDates =
    (item.activityPhase?.start_date || !small) &&
    !hideDate &&
    item.activityPhase?.start_date;

  const isBudgetVisible =
    shouldShowBudgetInfo && item.budgetTotalsAccountIsMember;

  return (
    <StyledContainer>
      <StyledActivityInfo>
        <StyledActivityNameContainer>
          <StyledActivityName>{item.title}</StyledActivityName>
          {item.isSuggested && (
            <StyledSuggestedContainer>Suggested</StyledSuggestedContainer>
          )}
        </StyledActivityNameContainer>
        {shouldShowDates && (
          <StyledActivityPhaseDate>
            {item.activityPhase.start_date} - {item.activityPhase.end_date}
          </StyledActivityPhaseDate>
        )}
        <StyledBottomCell>
          {isBudgetVisible ? (
            <ListItemBudgetTotals
              width={240}
              totals={item.budgetTotals || emptyObj}
            />
          ) : (
            // no member context to show add to work category if not already showing budget info (i.e. from tasks)
            <AddTo handleAddTo={handleAddTo} addText={'Add to Work Category'} />
          )}
        </StyledBottomCell>
      </StyledActivityInfo>
    </StyledContainer>
  );
};

const ProjectRow = (props) => {
  const {
    item,
    board,
    showSelectPhaseText,
    isWide,
    shouldShowBudgetInfo,
    popoverClassName
  } = props;

  const dispatch = useDispatch();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const target = useRef(null);
  const handleDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownIsOpen(true);
  };
  const { onAddPhasesClick } = useContext(RowContext);

  const handleAddPhasesClick = () => {
    onAddPhasesClick(item.id);
  };

  const onGoToProject = () => {
    dispatch(
      navigateToProject({
        teamSlug: item.is_personal ? 'personal' : board.team_slug,
        projectSlug: item.slug,
        projectId: item.id,
        openInNewWindow: true
      })
    );
    setDropdownIsOpen(false);
  };

  const onOpenProjectInfo = () => {
    dispatch(openEditProjectModal(item.id));
    setDropdownIsOpen(false);
  };

  const isBudgetVisible =
    shouldShowBudgetInfo && item.budgetTotalsAccountIsMember;

  return (
    <StyledContainer>
      <StyledProjectInfo>
        <StyledTopCell>
          {(item.number || item.project_number) && (
            <StyledProjectNumber>
              {item.number || item.project_number}
            </StyledProjectNumber>
          )}
          <StyledTeamName>
            <StyledFolderIcon />
            <span className="no-text-overflow">
              {(board && board.name) || item.board?.name || 'Personal'}
            </span>
          </StyledTeamName>
        </StyledTopCell>
        <StyledMiddleCell className="styled-project-info">
          <StyledDot className="project-info-styled-dot" projectId={item.id} />
          {!item.is_administrative && (
            <StyledProjectThreeDotContainer
              className="project-row-three-dot-container"
              onClick={handleDropdownClick}
              ref={target}
            >
              <ProjectThreeDotMenu vertical />
              <ProjectThreeDotMenuOptions
                onAddPhasesClick={handleAddPhasesClick}
                isOpen={dropdownIsOpen}
                handleClose={() => setDropdownIsOpen(false)}
                target={target}
                onGoToProject={onGoToProject}
                onOpenProjectInfo={onOpenProjectInfo}
                popoverClassName={popoverClassName}
              />
            </StyledProjectThreeDotContainer>
          )}
          <StyledMiddleContents>
            <StyledProjectTitle className="project-info-title">
              {item.title}
            </StyledProjectTitle>
            {isWide && (
              <StyledProjectDescription>
                {item.description && '- ' + item.description}
              </StyledProjectDescription>
            )}
          </StyledMiddleContents>
          {item.isSuggested && (
            <StyledSuggestedContainer> Suggested </StyledSuggestedContainer>
          )}
        </StyledMiddleCell>
        <StyledBottomCell>
          {item.description && !isWide && (
            <StyledProjectDescription>
              {item.description}
            </StyledProjectDescription>
          )}
          {isBudgetVisible && (
            <ListItemBudgetTotals
              width={240}
              totals={item.budgetTotals || {}}
            />
          )}
        </StyledBottomCell>
      </StyledProjectInfo>
      {!item.defaultOrMainPhaseId && showSelectPhaseText && (
        <StyledKaratContainer className="project-row-karat-container">
          <KaratRight />
        </StyledKaratContainer>
      )}
    </StyledContainer>
  );
};
class Row extends React.Component {
  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  render() {
    const {
      item,
      board,
      showSelectPhaseText,
      accountId,
      getItemHeight,
      shouldShowBudgetInfo,
      isWide,
      handleClose,
      popoverClassName
    } = this.props;
    if (!item) {
      return null;
    }
    const height = getItemHeight(item, shouldShowBudgetInfo);
    const isPhase = item.is_phase;
    const isActivity = item.is_activity;
    const Component = isPhase
      ? PhaseRow
      : isActivity
      ? ActivityRow
      : ProjectRow;
    return (
      <PhaseProjectListItem
        data-for="app-tooltip"
        data-html="true"
        data-class="project-info-tooltips"
        data-tip={`${item.title} <br /> ${
          item.description ? `${item.description}` : ''
        }`}
        itemHeight={height}
        hasHoverBackground
        unselectable={'true'}
        isActivity={isActivity}
        data-testid="phase-row"
      >
        <Component
          item={item}
          board={board}
          showSelectPhaseText={showSelectPhaseText}
          itemHeight={height}
          accountId={accountId}
          shouldShowBudgetInfo={shouldShowBudgetInfo}
          isWide={isWide}
          handleClose={handleClose}
          popoverClassName={popoverClassName}
        />
      </PhaseProjectListItem>
    );
  }
}

const boardIdGetter = (state, ownProps) => {
  return ownProps.item && ownProps.item.board_id;
};
const makeMapStateToProps = () => {
  const getBoardById = makeGetBoardById({ boardIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    board: getBoardById(state, ownProps)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  fetchPhasesByProjectIds,
  addMemberToProject
};
export default connect(makeMapStateToProps, mapDispatchToProps)(Row);
