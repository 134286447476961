import React from 'react';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import {
  DeleteCommentModal,
  OutsideClickWrapper,
  CommentInputContainer
} from '../..';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { commentTypes } from 'appConstants/commentConstants';
import { formatMentionedNames } from 'appUtils/mentions';
import { noop } from 'appUtils';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import getCaretCoordinates from 'textarea-caret';
import ReactTooltip from 'react-tooltip';
import Linkify from 'linkifyjs/react';
import {
  makeGetCommentAuthorIsPM,
  makeGetCommentAuthorIsGuest,
  getTeamMembershipsByAccountId,
  getAttachmentHash
} from 'selectors';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { removeAttachmentSubscriptions } from 'actionCreators';
import { FILE_STATUS } from 'appConstants';
import {
  DELETE_PROJECT_COMMENT_TIP,
  EDIT_PROJECT_COMMENT_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { withProjectPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/hocs/withProjectPermissionProvider';
const emptyArray = [];

const moment = extendMoment(Moment);

class ProjectCommentRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteCommentModalOpen: false,
      deleteModalCommentId: null,
      commentBody: '',
      isEditingComment: false,
      suggestionListLeft: 0,
      tempFiles: []
    };
    bindAll(this, [
      'deleteCommentOrReply',
      'toggleDeleteCommentModal',
      'handleCommentChange',
      'toggleCommentEditState',
      'handleEditCancel',
      'handleSave',
      'handleKeyPress',
      'handleFiles',
      'removeFile'
    ]);
  }

  getPermissions = () => {
    const {
      selectedTeamId,
      commentThread,
      me,
      project: { id }
    } = this.props;
    const mine = me.id === commentThread.account.id;
    const permissions = {
      teamId: selectedTeamId,
      projectId: id,
      mine
    };
    return permissions;
  };

  toggleDeleteCommentModal(commentId = null) {
    this.setState({ deleteModalCommentId: commentId });
    this.setState({
      deleteCommentModalOpen: !this.state.deleteCommentModalOpen
    });
  }

  deleteCommentOrReply() {
    const { project, commentThread, deleteComment } = this.props;
    const permissions = this.getPermissions();
    deleteComment({
      projectId: project.id,
      commentId: commentThread.id,
      permissions
    });
    if (this.state.deleteCommentModalOpen) this.toggleDeleteCommentModal();
  }

  componentDidMount() {
    this.setState({ commentBody: this.props.commentThread.description });
  }

  componentWillUnmount() {
    const { removeAttachmentSubscriptions, commentThread } = this.props;
    const attachments = commentThread?.attachments || [];
    if (attachments.length) {
      const attachmentIds = attachments.map((attachment) => attachment.id);
      removeAttachmentSubscriptions({ ids: attachmentIds });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.commentThread !== prevProps.commentThread) {
      this.setState({ commentBody: this.props.commentThread.description });
    }
  }

  handleCommentChange(event) {
    this.setState({ commentBody: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key == 'Enter' && this.state.commentBody.length) {
      event.preventDefault();
      this.handleSave();
    }

    if (event.key == '@') {
      const commentInput = document.querySelector(
        '.editable-comment .input textarea'
      );
      const suggestionListLeft = getCaretCoordinates(
        commentInput,
        commentInput.selectionEnd
      ).left;
      this.setState({ suggestionListLeft });
    }
  }

  handleEditCancel() {
    this.setState({
      commentBody: this.props.commentThread.description,
      isEditingComment: false,
      tempFiles: []
    });
  }

  toggleCommentEditState() {
    this.setState((prevState) => ({
      isEditingComment: !prevState.isEditingComment
    }));
  }

  handleSave() {
    const { project, commentThread, updateComment } = this.props;
    const { tempFiles, commentBody } = this.state;
    this.toggleCommentEditState();

    if (
      (commentBody !== commentThread.description || tempFiles.length) &&
      (commentBody || tempFiles.length || commentThread.attachments.length)
    ) {
      const permissions = this.getPermissions();
      updateComment({
        projectId: project.id,
        commentId: commentThread.id,
        accountId: commentThread.account.id,
        description: commentBody,
        tempFiles,
        permissions
      });
      this.setState({ tempFiles: [] });
    } else {
      this.handleEditCancel();
    }
  }

  handleFiles(files) {
    this.setState({ tempFiles: Array.from(files) });
  }

  removeFile(file) {
    this.setState((prevState) => ({
      tempFiles: prevState.tempFiles.filter((stateFile) => stateFile !== file)
    }));
  }

  getCommentsPermissions() {
    const {
      projectPermissions: { canEditProjectComments, canDeleteProjectComments }
    } = this.props;

    return {
      canEditProjectComments,
      canDeleteProjectComments
    };
  }

  renderEditableComment() {
    const { commentBody, suggestionListLeft, project, isReply, tempFiles } =
      this.state;
    const { commentThread } = this.props;
    return (
      <OutsideClickWrapper handleOutsideClick={this.handleSave}>
        <CommentInputContainer
          isExpanded
          isEditingComment
          className={cn('editable-comment', { isReply })}
          commentType={commentTypes.EDIT}
          commentBody={commentBody}
          suggestionListLeft={suggestionListLeft}
          onCancel={this.handleEditCancel}
          onChange={this.handleCommentChange}
          onSubmit={this.handleSave}
          onKeyPress={this.handleKeyPress}
          project={project}
          projectId={project?.id}
          attachments={commentThread.attachments}
          handleFiles={this.handleFiles}
          unsavedFiles={tempFiles}
          removeFile={this.removeFile}
        />
      </OutsideClickWrapper>
    );
  }

  renderCommentRow() {
    const {
      me,
      commentThread,
      isReply,
      replies,
      openReplyInput,
      replyInputOpen,
      memberIsPM,
      memberIsGuest,
      project,
      teamMembersByAccountId,
      attachmentHash
    } = this.props;
    const createdTime = commentThread.creation_date;
    const isMyComment = me.id === commentThread.account.id;
    let formattedTime;

    const { canEditProjectComments, canDeleteProjectComments } =
      this.getCommentsPermissions();

    if (
      moment().startOf('day').diff(moment(createdTime).startOf('day'), 'days') >
      2
    ) {
      formattedTime = moment(createdTime).format('M/D/YY h:mm A');
    } else if (moment(createdTime).isSame(moment(), 'day')) {
      formattedTime = 'Today ' + moment(createdTime).format('h:mm A');
    } else {
      formattedTime = 'Yesterday ' + moment(createdTime).format('h:mm A');
    }
    return (
      <>
        <div
          key="reply-input"
          className={cn('comment-row', {
            isReply,
            'rounded-bottom':
              !replyInputOpen &&
              commentThread.replies &&
              !commentThread.replies.length,
            'shorten-grid-row':
              isReply || (replies && replies.length) || replyInputOpen
          })}
        >
          <div />
          <div className="comment-metadata">
            <div className="comment-left">
              <div className="comment-author">{commentThread.account.name}</div>
              <div className="comment-timestamp">{formattedTime}</div>
            </div>
            {/* always render edit button until ui for blocking is decided on - requests to edit other users comments will be blocked if they do not have permission  */}
            <div className="comment-right">
              <div
                key={1}
                className={cn(
                  { disabled: !canEditProjectComments },
                  'edit-asset'
                )}
                onClick={
                  canEditProjectComments
                    ? this.toggleCommentEditState
                    : undefined
                }
                data-for="comment-icon"
                data-tip={
                  canEditProjectComments
                    ? 'Edit Comment'
                    : EDIT_PROJECT_COMMENT_TIP
                }
              />
              <div className="comment-icon-separator" />
              <div
                key={2}
                className={cn(
                  { disabled: !canDeleteProjectComments },
                  'delete-asset'
                )}
                onClick={
                  canDeleteProjectComments
                    ? () => this.toggleDeleteCommentModal(commentThread.id)
                    : undefined
                }
                data-for="comment-icon"
                data-tip={
                  canDeleteProjectComments
                    ? 'Delete Comment'
                    : DELETE_PROJECT_COMMENT_TIP
                }
              />
            </div>
          </div>
          <div className="initials-col">
            <MemberInitials
              projectId={project.id}
              projectItem={true}
              memberIsGuest={memberIsGuest}
              member={
                project.project_membership?.find(
                  (member) => member?.account?.id === commentThread?.account?.id
                ) || teamMembersByAccountId?.[commentThread?.account?.id]
              }
              originType="teamMembership"
              classes={cn('regular-member-no-hover selected', {
                'logged-member-no-hover': commentThread.account.id === me.id
              })}
            />
          </div>
          <div className="comment-description">
            <Linkify options={{ nl2br: true }}>
              {formatMentionedNames(this.state.commentBody)}
            </Linkify>
          </div>

          {commentThread.attachments &&
            commentThread.attachments.map((attachment) => {
              // If attachment is inside of the hash it was changed in some way by BE.
              const attachmentInfo =
                attachmentHash[attachment.id] || attachment;
              if (attachmentInfo.deleted) {
                return null;
              }
              return (
                <div
                  key={attachmentInfo.file_name}
                  className="file-attachment-container"
                  onClick={() => {
                    if (attachmentInfo.file_url) {
                      window.open(attachmentInfo.file_url, '_blank');
                    }
                  }}
                >
                  <i className="file-type" />
                  <span
                    className="file-name"
                    data-for="app-tooltip"
                    data-effect="solid"
                    data-tip={
                      attachmentInfo?.status === FILE_STATUS.PENDING
                        ? 'Scanning for Malware'
                        : ''
                    }
                  >
                    {attachmentInfo.file_name}
                  </span>
                  <div className="close-circle" onClick={noop}>
                    <i className="delete-file" />
                  </div>
                </div>
              );
            })}
          <div
            className={cn('reply-button', {
              hidden: isReply || (replies && replies.length) || replyInputOpen
            })}
            onClick={openReplyInput}
          >
            <div>
              <i className="reply-arrow" />
            </div>
            <div>Reply</div>
          </div>
        </div>
        <DeleteCommentModal
          isOpen={this.state.deleteCommentModalOpen}
          toggle={this.toggleDeleteCommentModal}
          commentId={this.state.deleteModalCommentId}
          deleteCommentOrReply={this.deleteCommentOrReply}
        />
      </>
    );
  }

  renderReplies() {
    const { me, project, replies, deleteComment, updateComment } = this.props;

    return replies.map((reply) => (
      <ConnectedRow
        key={`reply ${reply.id}`}
        me={me}
        project={project}
        commentThread={reply}
        replies={emptyArray}
        deleteComment={deleteComment}
        updateComment={updateComment}
        attachments={reply.attachments}
        isReply
        permissionProps={{
          accountId: reply?.account?.id
        }}
      />
    ));
  }

  render() {
    const { replies } = this.props;
    return (
      <div>
        {this.state.isEditingComment
          ? this.renderEditableComment()
          : this.renderCommentRow()}
        {replies && replies.length ? this.renderReplies() : null}
      </div>
    );
  }
}
const projectIdGetter = (state, ownProps) => ownProps.project?.id;

const mapDispatchToProps = {
  removeAttachmentSubscriptions
};

const makeMapStateToProps = () => {
  const getCommentAuthorIsPM = makeGetCommentAuthorIsPM({ projectIdGetter });
  const getCommentAuthorIsGuest = makeGetCommentAuthorIsGuest();
  const mapStateToProps = (state, ownProps) => ({
    memberIsPM: getCommentAuthorIsPM(state, ownProps),
    memberIsGuest: getCommentAuthorIsGuest(state, ownProps),
    teamMembersByAccountId: getTeamMembershipsByAccountId(state),
    attachmentHash: getAttachmentHash(state)
  });
  return mapStateToProps;
};
const ConnectedRow = withRouter(
  withProjectPermissionProvider(
    connect(makeMapStateToProps, mapDispatchToProps)(ProjectCommentRow)
  )
);
export default ConnectedRow;
