import widgets from 'DashboardModule/reducers/dashboardWidgets';
import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

const arrayForSkeletons = Array.apply(null, { length: 20 }).map(
  Number.call,
  () => parseInt(Math.random() * 10000)
); // made an array of random numbers that is created per session that gives the array of loaders a 50/50 chance of having a smaller line underneath
const titleHeight = 50;
const descHeight = 25;
export default class SkeletonLoader extends Component {
  render() {
    const { numLoaders, style, loader } = this.props;
    const renderer = loader ? this.loaderWrapper : this.defaultLoader;
    return (
      <div
        style={{
          margin: '5%',
          flexDirection: 'column',
          display: 'flex',
          ...style
        }}
      >
        {arrayForSkeletons.map((item, index) =>
          index < numLoaders ? renderer(item, index) : null
        )}
      </div>
    );
  }

  loaderWrapper = (item, index) => {
    const { loader } = this.props;
    return loader({ item, index, ...this.props });
  };

  defaultLoader = (item, index) => {
    const { loaderStyle = {}, heightBuffer = 10 } = this.props;
    const { height = 50, rx = 5, primaryColor, secondaryColor } = loaderStyle;
    return (
      <ContentLoader
        style={{ height: height + heightBuffer }}
        key={index}
        {...(primaryColor && { primaryColor })}
        {...(secondaryColor && { secondaryColor })}
      >
        <rect width="100%" height={height} x="0" rx={rx} />
      </ContentLoader>
    );
  };
}
export const TitleAndDescriptionLoader = ({
  item,
  index,
  height = 80,
  ...props
}) => {
  const isEven = item % 2 === 0;
  return (
    <ContentLoader
      style={{ flexDirection: 'column', height: height }}
      key={index}
    >
      <rect width="100%" height={titleHeight} x="0" rx="10" />
      {isEven && (
        <rect width="95%" height={descHeight} y={titleHeight + 10} rx="5" />
      )}
    </ContentLoader>
  );
};
