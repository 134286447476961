import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ColorPicker from 'components/ColorPicker';
import { Input } from 'reactstrap';
import { getAuth, getMe } from 'selectors';
import { updateTeam } from 'actionCreators';
import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import EmailSettings from 'SettingsModule/components/EmailSettings';
import OrganizationSettingsForm from 'SettingsModule/components/StandardSettings/General/OrganizationSettings/OrganizationSettingsForm';
import {
  updateOrganizationSettings,
  fetchOrganizationSettings
} from 'SettingsModule/actionCreators';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.colors.colorMediumGray1};
  border: 0;
  border-radius: 6px;
  bottom: 0;
  color: #fff;
  padding: 8px 23px;
  position: absolute;
  right: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

const UserImage = styled.div`
  background-color: ${calculateColorPref};
  color: #fff;
  height: 128px;
  width: 128px;
  border-radius: 20px;
  font-size: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: center;

  .change-color {
    color: #2f80ed;
    font-size: 14px;

    &:hover {
      font-weight: 600;
    }
  }
`;

class TeamInfoTab extends React.Component {
  static defaultProps = { team: {}, me: {} };
  state = {
    teamName: this.props.team.name,
    organizationSettings: {
      time_format: true,
      timesheet: false,
      manage_time: ['hours'],
      date_format: 'mm/dd/yy',
      budget_tracking: 'currency'
    }
  };

  componentDidMount() {
    // this.props.fetchOrganizationSettings({ teamId });
  }

  componentDidUpdate(prevProps) {
    const { team } = this.props;
    if (!prevProps.team.id && team.id) {
      this.setState({ teamName: team.name });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.prepAndUpdateData(this.state.logoFile);
    this.updateOrganizationSettings();
  };

  prepAndUpdateData = () => {
    const { team, updateTeam } = this.props;
    const { teamName } = this.state;

    const payload = {
      team: { id: team.id, name: teamName },
      image: null,
      extendTrial: false
    };
    updateTeam(payload);
  };

  updateOrganizationSettings = () => {
    // this.props.updateOrganizationSettings({ teamId });
  };

  onInputSelect = (name, value, type) => {
    if (type === 'checkbox') {
      let valueArray = [...this.state.organizationSettings[name]];
      if (valueArray.includes(value)) {
        valueArray = valueArray.filter((v) => value !== v);
      } else {
        valueArray.push(value);
      }

      this.setState({
        organizationSettings: {
          ...this.state.organizationSettings,
          [name]: valueArray
        }
      });
    } else {
      this.setState({
        organizationSettings: {
          ...this.state.organizationSettings,
          [name]: value
        }
      });
    }
  };

  getTeamInitials = (teamName) => teamName && teamName.toUpperCase()[0];
  render() {
    const { team, me } = this.props;

    return team.id ? (
      <div className="team-settings-modal team-settings-general">
        <div className="team-info-tab">
          <div className="info-row">
            <div className="team-logo-col">
              <div>
                <UserImage id={team.id} color={team.color} originType="team">
                  {this.getTeamInitials(team.name)}
                </UserImage>
              </div>
            </div>
            <div className="team-info-col">
              <Input
                type="text"
                defaultValue={this.state.teamName}
                onChange={(e) => this.setState({ teamName: e.target.value })}
                name="teamName"
                placeholder="Your team name"
                required
                className="team-name-input"
              />
              <div className="team-email">
                <div className="email-label">Organization Email</div>
                <div className="email-text">{me.email}</div>
              </div>
              <EmailSettings />
              <SaveButton className="save-button" onClick={this.handleSubmit}>
                Save
              </SaveButton>
            </div>
            <ColorPickerContainer>
              <ColorPicker
                id={team.id}
                originType={ORIGIN_TYPE_STRINGS.TEAM}
                shouldHide={false}
                className="color-picker-container"
                // ref={this.setColorPickerRef}
                row
              >
                <div className="change-color">Change Color</div>
              </ColorPicker>
            </ColorPickerContainer>
          </div>
        </div>
        <OrganizationSettingsForm
          organizationSettings={this.state.organizationSettings}
          onInputSelect={this.onInputSelect}
        />
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, params) => ({
  params,
  auth: getAuth(state),
  me: getMe(state),
  team: state.teams.allTeams[0]
});

const mapDispatchToProps = {
  updateTeam,
  fetchOrganizationSettings,
  updateOrganizationSettings
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamInfoTab)
);
