import React from 'react';
import { connect } from 'react-redux';
import CellContainer from './CellContainer';
import FlagIcon from 'icons/FlagIcon';
import { getAuth } from 'selectors';
import { triggerTasksAttributesUpdate } from 'actionCreators';
import { nullifyInvalidDate } from 'appUtils/momentUtils';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import TaskDatePickerInput from 'components/DatePicker/TaskPickerInput';
import moment from 'moment';

const flagIcon = <FlagIcon width={14} height={14} />;

const bindToDay = (date) =>
  date ? date.startOf('day').format('YYYY-MM-DD') : null;

class DueCell extends React.Component {
  handleCalendarSelect = ({ startDate }) => {
    const {
      auth: { token },
      handleTasksAttributesUpdate,
      row: {
        original: { id, handleTaskEditClick, createRow, handleUpdate }
      }
    } = this.props;

    const updatedDate = bindToDay(nullifyInvalidDate(startDate));
    const body = {
      task_ids: [id],
      due_at: updatedDate
    };
    if (createRow) {
      handleUpdate({ due_at: updatedDate });
    } else {
      handleTasksAttributesUpdate({ token, body });
      handleTaskEditClick(null);
    }
  };

  render() {
    const { row } = this.props;
    const task = row.original;
    const { taskProps = {} } = row.original;
    const {
      taskIsEditing,
      isNewTask,
      alwaysRenderScheduleIn,
      taskEditProperty,
      projectDetailView,
      isTaskModal = false,
      currentFilter
    } = taskProps;
    const taskProperty = 'due_at';

    return (
      <CellContainer
        taskProps={taskProps}
        target={(ref) => (this.target = ref)}
        taskProperty={taskProperty}
        row={row}
      >
        <DateRangeCalendar
          onSubmit={this.handleCalendarSelect}
          itemStartDate={task[taskProperty]}
          isSingleDay
          showClear
          onClear={this.handleCalendarSelect}
          customInput={(startDate, endDate, openCalendar) => (
            <div
              onClick={openCalendar}
              style={{ widht: '100%', height: '100%' }}
            >
              <TaskDatePickerInput
                taskIsEditing={taskIsEditing}
                icon={flagIcon}
                selectedDate={moment(startDate).isValid() ? startDate : null}
                taskDateType={taskProperty}
                alwaysRenderInput={alwaysRenderScheduleIn}
                currentFilter={currentFilter}
                taskEditProperty={taskEditProperty}
                isTaskCompleted={task.is_completed}
                projectDetailView={projectDetailView}
                isTaskModal={isTaskModal}
              />
            </div>
          )}
        />
      </CellContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state)
});

const mapDispatchToProps = {
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate
};
export default connect(mapStateToProps, mapDispatchToProps)(DueCell);
