import { memo, useCallback, useLayoutEffect } from 'react';
import { StyledNavTextNavItem, SpaceContainer } from './styles';
import { useLocation, matchPath } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  getIsOnReportsView,
  getIsOnScheduleView,
  getOnHomeView,
  getOnWorkloadView,
  getSidebarProjectsOpen,
  getTeamSlug,
  getUserSlug
} from 'selectors';
import PermissionBasedRenderer from './PermissionBasedRenderer';
import {
  calculateBoardColorPreference,
  calculateBoardBorderColorWithOpacity
} from 'appUtils/styleUtils';
import FolderIcon from 'icons/FolderIcon';
import styled from 'styled-components';
import LockIcon from 'icons/LockIcon';
import HelpIcon from 'icons/HelpIcon';
import theme from 'theme';
import PATHS from 'appConstants/paths';
import {
  navigateToReport,
  navigateToPersonalDashboard,
  navigateBack
} from 'actionCreators';
import lockWhiteIcon from 'images/lock-white.svg';
import format from 'date-fns/format';
import localEnUs from 'date-fns/locale/en-US';
import cn from 'classnames';
import BackArrowIcon from 'icons/BackArrowIcon';
import { NavMemberInviteButton } from './NavMemberInviteButton';

const StyledIconContainer = styled.span`
  align-items: center;
  bottom: 2px;
  color: ${theme.colors.colorMediumGray5};
  display: inline-flex;
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
  position: relative;

  &.help-icon-container {
    position: relative;
    bottom: 15px;
    right: 5px;
    path:nth-child(2) {
      fill: ${theme.colors.colorLightGray2};
    }
    path:nth-child(3) {
      fill: ${theme.colors.colorPureBlack};
    }
  }
`;

const StyledFolderIcon = styled(FolderIcon)`
  fill: ${(props) => calculateBoardColorPreference(props) || `transparent`};
  height: 26px;
  margin: 0 7px 0 10px;
  position: relative;
  top: 1px;
  width: 26px;

  path {
    stroke: ${calculateBoardBorderColorWithOpacity};
    stroke-width: 3px;
  }
`;

const DateText = styled.div`
  font-size: 22px;
  font-weight: 600;
  left: 2px;
  position: absolute;
  top: 1.7em;
  white-space: nowrap;
  width: fit-content;
`;

const ProjectBack = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  // Move into margins whilst keeping 2px of margin
  margin-left: calc(2px - 1.5rem);
  // Account for the margin of the folder icon
  margin-right: -10px;
  width: 40px;

  &:hover {
    background: ${({ theme }) => theme.colors.colorPaleGray11};
  }

  .projectBackArrow {
    // Centers the center of mass of the triangle in the border circle.
    // = 1/3 * width of icon
    margin-right: 4px;
  }
`;

const SpacesTooltipsHash = {
  Home: `This is your private <img class="lock-white-icon" src=${lockWhiteIcon} /> space to manage all of your work.`,
  Updates: '',
  Timesheet: '',
  Portfolios: 'Portfolios are for planning and managing groups of projects.',
  Team: 'This space provides tools for you to manage portfolio members.',
  'Work Planner':
    'This space is for planning who will work on what, and managing project budgets.',
  Workload: "Easily see your team's workload and availability",
  Planner:
    'On this space you can plan work and budgets around projects schedules.'
};

const NavTextNavItem = ({
  navViewText,
  isOnTeamProject,
  boardId,
  isOnProjectView,
  toggleInviteForm,
  isOnSettingsView,
  isOnTeamSettingsView
}) => {
  const dispatch = useAppDispatch();

  const isOnReportsView = useAppSelector(getIsOnReportsView);
  const isOnWorkloadView = useAppSelector(getOnWorkloadView);
  const isOnScheduleView = useAppSelector(getIsOnScheduleView);
  const isOnHomeView = useAppSelector(getOnHomeView);
  const isSidebarProjectsOpen = useAppSelector(getSidebarProjectsOpen);
  const teamSlug = useAppSelector(getTeamSlug);
  const userSlug = useAppSelector(getUserSlug);
  const location = useLocation();

  const handleNavigateBackToLandingPage = useCallback(
    (navViewText) => (e) => {
      switch (navViewText) {
        case 'Home': {
          e.stopPropagation();
          dispatch(navigateToPersonalDashboard({ userSlug }));
          break;
        }
        case 'Reports': {
          e.stopPropagation();
          dispatch(
            navigateToReport({
              teamSlug
            })
          );
          break;
        }
        case 'Projects':
        case 'Profile':
        case 'Settings': {
          e.stopPropagation();
          dispatch(navigateBack());
          break;
        }
        default:
      }
    },
    [dispatch, teamSlug, userSlug]
  );

  useLayoutEffect(() => {
    document.title = `Mosaic ${navViewText ? '| ' + navViewText : ''}`;
  }, [navViewText]);

  const toolTipData = isOnProjectView
    ? `This space provides tools for <br />
      you to manage any project.`
    : SpacesTooltipsHash[navViewText];
  const isOnReportsLandingPage = matchPath(location.pathname, {
    path: `${PATHS.TEAM_SLUG}${PATHS.REPORTS}`,
    exact: true,
    strict: true
  });
  const isOnPersonalDashboard = matchPath(location.pathname, {
    path: `${PATHS.USER_SLUG}/home${PATHS.DASHBOARD}`,
    exact: true,
    strict: true
  });
  const isOnReportsPage = isOnReportsView && !isOnReportsLandingPage;
  const isOnHomeSubMenu = isOnHomeView && !isOnPersonalDashboard;
  const isOnSettingsPage = isOnSettingsView || isOnTeamSettingsView;
  const navTextWithBackArrow =
    isOnReportsPage || isOnHomeSubMenu || isOnSettingsPage;

  return (
    <>
      {isOnProjectView && !isSidebarProjectsOpen && (
        <ProjectBack>
          <BackArrowIcon
            className="projectBackArrow"
            fill={theme.colors.colorCalendarBlue}
            onClick={handleNavigateBackToLandingPage('Projects')}
            width={12}
          />
        </ProjectBack>
      )}
      <StyledNavTextNavItem
        className={cn({
          hasBackArrow: navTextWithBackArrow,
          hoverable: isOnTeamProject,
          narrowBackArrow: navTextWithBackArrow && !isOnSettingsPage
        })}
        onClick={
          navTextWithBackArrow
            ? handleNavigateBackToLandingPage(navViewText)
            : undefined
        }
      >
        {navTextWithBackArrow && (
          <BackArrowIcon
            className="backArrow"
            fill={theme.colors.colorCalendarBlue}
            width={12}
          />
        )}
        {isOnProjectView && <StyledFolderIcon boardId={boardId} />}
        <SpaceContainer
          data-for="app-tooltip"
          data-html
          data-class="center"
          data-effect="solid"
          data-place="right"
          data-arrow-color={theme.colors.colorDarkBlueTooltip}
          // when undefined, tooltip shows 'null'
          data-tip={toolTipData && !isOnHomeSubMenu ? toolTipData : ''}
          className="nav-view-text"
        >
          {navViewText}
          {(isOnWorkloadView || isOnScheduleView) && (
            <StyledIconContainer className="help-icon-container">
              <HelpIcon height="12px" width="12px" />
            </StyledIconContainer>
          )}
          {isOnPersonalDashboard && (
            <StyledIconContainer>
              <LockIcon
                fill={theme.colors.colorMediumGray5}
                height="12px"
                width="12px"
              />
            </StyledIconContainer>
          )}
        </SpaceContainer>
        {navViewText === 'Team' ? (
          <NavMemberInviteButton onClick={toggleInviteForm} />
        ) : null}
        {isOnPersonalDashboard && (
          <DateText>
            {format(Date.now(), 'EEEE, MMMM d, y', { locale: localEnUs })}
          </DateText>
        )}
      </StyledNavTextNavItem>
    </>
  );
};
export default memo(NavTextNavItem);

export const PermissionBasedNavItem = (props) => (
  <PermissionBasedRenderer
    successRender={() => <NavTextNavItem {...props} />}
    failRender={() => (
      <StyledNavTextNavItem>
        {props.teamName && `Shared By ${props.teamName}`}
      </StyledNavTextNavItem>
    )}
    {...props}
  />
);
