import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NewProjectsIcon = ({
  className,
  height = '13',
  width = '11',
  fillColor = '#4f4f4f'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 11 13"
  >
    <path
      d="M0 13H11V3.49375L7.05833 0H0V13ZM7.33333 1.38125L9.44167 3.25H7.33333V1.38125ZM0.916667 0.8125H6.41667V4.0625H10.0833V12.1875H0.916667V0.8125Z"
      fill={fillColor}
    />
    <path d="M5.0415 2.84375H2.2915V3.65625H5.0415V2.84375Z" fill={fillColor} />
    <path d="M7.7915 5.28125H2.2915V6.09375H7.7915V5.28125Z" fill={fillColor} />
    <path
      d="M8.70817 6.90625H2.2915V7.71875H8.70817V6.90625Z"
      fill={fillColor}
    />
    <path
      d="M8.70817 8.53125H2.2915V9.34375H8.70817V8.53125Z"
      fill={fillColor}
    />
  </SvgIcon>
);

export default NewProjectsIcon;
