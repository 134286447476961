import * as constants from 'appConstants';
import {
  VIEW_BY,
  ZOOM_LEVELS,
  VIEW_TYPE,
  CONDENSED_ZOOM_LEVELS
} from 'appConstants/workload';
import { UTILIZATION_OPTIONS_DISPLAY } from 'appUtils/projectPlannerUtils';
import { OPEN_BUDGET_MODAL, CLOSE_BUDGET_MODAL } from 'BudgetModule/constants';
import { Modals } from 'appConstants/navigation';
const {
  OPEN_WORKLOAD_FILTER,
  CLOSE_WORKLOAD_FILTER,
  SET_WORKLOAD_VIEW_BY,
  SET_WORKLOAD_VIEW_TYPE,
  SET_TIMELINE_VIEW_BY,
  SET_SCHEDULE_VIEW_BY,
  SET_TIMESHEET_VIEW_BY,
  SET_UTILIZATION_VIEW_BY,
  SET_CONDENSED_ZOOM_LEVEL,
  SCROLL_TIMESHEET
} = constants;

const initialState = {
  viewBy: VIEW_BY.MEMBERS,
  timelineViewBy: VIEW_BY.NONE,
  scheduleViewBy: VIEW_BY.PROJECTS,
  timesheetViewBy: VIEW_BY.MEMBERS,
  utilizationViewBy: VIEW_BY.MEMBERS,
  timesheetIsWeek: true,
  utilizationRange: UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.WEEK].value,
  utilizationCustomRange: null,
  utilizationIsSummary: true,
  utilizationPercentOrTotal: 'percent',
  isOpen: false,
  timesheetIsScrolled: false,
  viewType: VIEW_TYPE.NORMAL,
  condensedZoomLevel: CONDENSED_ZOOM_LEVELS.MEDIUM
};

const workloadPlannerFilter = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case OPEN_WORKLOAD_FILTER: {
      return {
        ...state,
        isOpen: true
      };
    }
    case CLOSE_WORKLOAD_FILTER: {
      return {
        ...state,
        isOpen: false
      };
    }
    case SET_WORKLOAD_VIEW_BY: {
      return {
        ...state,
        viewBy: payload.viewBy
      };
    }
    case SET_TIMELINE_VIEW_BY: {
      return {
        ...state,
        timelineViewBy: payload.viewBy
      };
    }
    case SET_SCHEDULE_VIEW_BY: {
      return {
        ...state,
        scheduleViewBy: payload.viewBy
      };
    }
    case SET_TIMESHEET_VIEW_BY: {
      return {
        ...state,
        timesheetViewBy:
          payload.viewBy === undefined ? state.timesheetViewBy : payload.viewBy,
        timesheetIsWeek:
          payload.timesheetIsWeek !== undefined
            ? payload.timesheetIsWeek
            : payload.viewBy === VIEW_BY.MEMBERS
            ? true
            : state.timesheetIsWeek
      };
    }
    case SET_UTILIZATION_VIEW_BY: {
      return {
        ...state,
        utilizationViewBy:
          payload.viewBy === undefined
            ? state.utilizationViewBy
            : payload.viewBy,
        utilizationRange:
          payload.range === undefined ? state.utilizationRange : payload.range,
        utilizationIsSummary:
          payload.isSummary === undefined
            ? state.utilizationIsSummary
            : payload.isSummary === 'true',
        utilizationPercentOrTotal:
          payload.percentOrTotal === undefined
            ? state.utilizationPercentOrTotal
            : payload.percentOrTotal
      };
    }
    case constants.NAVIGATION_CHANGE: {
      if (payload?.modal === Modals.BUDGET) {
        return {
          ...state,
          timesheetViewBy: VIEW_BY.PHASES,
          timesheetIsWeek: false
        };
      }
      return state;
    }
    case OPEN_BUDGET_MODAL: {
      return {
        ...state,
        timesheetViewBy: VIEW_BY.PHASES,
        timesheetIsWeek: false
      };
    }
    case CLOSE_BUDGET_MODAL: {
      return {
        ...state,
        timesheetViewBy: VIEW_BY.MEMBERS,
        timesheetIsWeek: true
      };
    }
    case SCROLL_TIMESHEET: {
      return {
        ...state,
        timesheetIsScrolled: payload.scrolled
      };
    }
    case SET_WORKLOAD_VIEW_TYPE: {
      return {
        ...state,
        viewType: payload.viewType
      };
    }
    case SET_CONDENSED_ZOOM_LEVEL: {
      return {
        ...state,
        condensedZoomLevel: payload.condensedZoomLevel
      };
    }
    default:
      return state;
  }
};

export default workloadPlannerFilter;
