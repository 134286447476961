import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

interface BaseCardProps {
  /**
   * Label on the card.
   */
  children: ReactNode | Array<ReactNode>;

  /**
   * The classname for the element wrapping the card.
   */
  className?: string;

  /**
   * Callback invoked when the card is clicked.
   */
  onClick?: () => void;

  /**
   * Tooltip for the card.
   */
  tooltip?: string;
}

export const BaseCard = ({
  children,
  className,
  onClick,
  tooltip
}: BaseCardProps) => {
  useEffect(() => {
    if (tooltip !== undefined) rebuildTooltip();
  }, [tooltip]);

  return (
    <RootContainer
      className={className}
      data-class="center"
      data-effect="solid"
      data-for="app-tooltip"
      data-html
      data-tip={tooltip}
      onClick={onClick}
    >
      {children}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.colorPureWhite};
  width: 100%;
  min-height: 60px;
  padding: 5px 16px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;
