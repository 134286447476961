import CopyIcon from 'icons/CopyIcon';
import CutIcon from 'icons/CutIcon';
import Delete24IconUnmasked from 'icons/Delete24IconUnmasked';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import TaskIcon from 'icons/TaskIcon';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import styled, { useTheme } from 'styled-components';
import { MenuDivider } from 'TimelinesModule/components/ThreeDotMenu/Menus';
import { MenuItem } from 'TimelinesModule/components/ThreeDotMenu/types';
import { WorkPlanItem } from 'TimelinesModule/types/WorkPlanItem';

interface WorkPlanItemMenuContextValues {
  activeItem: WorkPlanItem | undefined;
  activeBarIndex: number | undefined;
  openMenu: ({ item: WorkPlanItem, barIndex: number }) => void;
  closeMenu: () => void;

  menus: MenuItem[];
}

const WorkPlanItemMenuContext = createContext<WorkPlanItemMenuContextValues>(
  {} as WorkPlanItemMenuContextValues
);

type WorkPlanItemMenuProviderProps = unknown;

export const WorkPlanItemMenuProvider = ({
  children
}: PropsWithChildren<WorkPlanItemMenuProviderProps>) => {
  const [activeItem, setActiveItem] = useState<WorkPlanItem>();
  const [activeBarIndex, setActiveBarIndex] = useState<number>();
  const theme = useTheme();

  const handleClose = useCallback(() => {
    setActiveItem(undefined);
    setActiveBarIndex(undefined);
  }, []);

  const handleOpen: WorkPlanItemMenuContextValues['openMenu'] = useCallback(
    ({ item, barIndex }) => {
      if (activeItem === item && activeBarIndex === barIndex) {
        handleClose();
      } else {
        setActiveItem(item);
        setActiveBarIndex(barIndex);
      }
    },
    [activeBarIndex, activeItem, handleClose]
  );

  const copyMenu = useMemo<MenuItem>(
    () => ({
      label: 'Copy',
      onClick: () => {
        console.log('Copy');
        handleClose();
      },
      icon: <CopyIcon />
    }),
    [handleClose]
  );

  const SplitMenu = useMemo<MenuItem>(
    () => ({
      label: 'Split',
      onClick: () => {
        console.log('Split');
        handleClose();
      },
      icon: <CutIcon />
    }),
    [handleClose]
  );

  const DeleteMenu = useMemo<MenuItem>(
    () => ({
      label: 'Delete',
      onClick: () => {
        console.log('Delete');
        handleClose();
      },
      icon: <StyledDeleteIcon />
    }),
    [handleClose]
  );

  const PlanTaskMenu = useMemo<MenuItem>(
    () => ({
      label: 'Plan Task',
      onClick: () => {
        console.log('Plan Task');
        handleClose();
      },
      icon: (
        <TaskIcon
          height="12"
          width="12"
          color={theme.colors.colorMediumGray9}
        />
      )
    }),
    [handleClose, theme.colors.colorMediumGray9]
  );

  const GoToProjectMenu = useMemo<MenuItem>(
    () => ({
      label: 'Go to Project',
      onClick: () => {
        console.log('Go to Project');
        handleClose();
      },
      icon: <GoToProjectIcon color={theme.colors.colorMediumGray9} />
    }),
    [handleClose, theme.colors.colorMediumGray9]
  );

  const menus = useMemo(
    () => [
      copyMenu,
      SplitMenu,
      DeleteMenu,
      MenuDivider,
      PlanTaskMenu,
      GoToProjectMenu
    ],
    [copyMenu, SplitMenu, DeleteMenu, PlanTaskMenu, GoToProjectMenu]
  );

  return (
    <WorkPlanItemMenuContext.Provider
      value={{
        activeItem,
        activeBarIndex,
        openMenu: handleOpen,
        closeMenu: handleClose,
        menus
      }}
    >
      {children}
    </WorkPlanItemMenuContext.Provider>
  );
};

export const useWorkPlanItemMenu = () => useContext(WorkPlanItemMenuContext);

export const StyledDeleteIcon = styled(Delete24IconUnmasked)`
  height: 24px;
  width: 24px;
`;
