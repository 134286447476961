import { convertUsaDateToIsoDate } from 'appUtils/dateUtils';
import { AccountId } from 'models/account';
import { AccountCapacity } from 'models/accountCapacity';

export const mapAccountCapacitiesToWorkFromHomes = ({
  accountCapacities,
  WFHProjectId,
  groupIdSerializer
}: {
  accountCapacities: Record<
    AccountId,
    Pick<AccountCapacity, 'account_id' | 'activity_phase_schedule_bars'>
  >;
  WFHProjectId: number;
  groupIdSerializer: (accountId: AccountId) => string;
}) => {
  return Object.values(accountCapacities).reduce(
    (workFromHomesByGroup, accountCapacity) => {
      const workFromHomeByDate =
        accountCapacity.activity_phase_schedule_bars.reduce((acc, workPlan) => {
          if (workPlan.project_id === WFHProjectId) {
            const startDate = convertUsaDateToIsoDate(workPlan.start_date);
            acc[startDate] = {
              id: workPlan.id
            };
          }
          return acc;
        }, {});

      const groupId = groupIdSerializer(accountCapacity.account_id);

      workFromHomesByGroup[groupId] = workFromHomeByDate;

      return workFromHomesByGroup;
    },
    {}
  );
};
