import styled from 'styled-components';
import { getDisplayValuesByHoursType } from '../utils';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { HoursDisplayType } from '../types';
import { UtilizationBreakdown } from 'UtilizationModule/types';
import {
  CenterValueText,
  FloatingContainer,
  PTOValueText,
  HeatmapContainer,
  CenterContainer
} from './styles';
import { ColorScaleIntervalsWithMax } from 'components/ColorScaleModal/types';
import clamp from 'lodash/clamp';
import { CONDENSED_VIEW_OVERFLOW_TRIANGLE } from 'appConstants/workload';
import { useMemo } from 'react';

export const AvailabilityBucket = ({
  hoursDisplayType,
  utilizationBreakdown,
  heatmap
}: {
  hoursDisplayType: HoursDisplayType;
  utilizationBreakdown: UtilizationBreakdown;
  heatmap: ColorScaleIntervalsWithMax;
}) => {
  const { defaultValue, hoverValue, hourTypeOnHoverText, PTOValue, unit } =
    getDisplayValuesByHoursType({
      hoursDisplayType,
      utilizationBreakdown
    });

  const { committed, capacity } = utilizationBreakdown;
  const percentageOfCapacity =
    capacity === 0 ? 0 : (committed / capacity) * 100;

  const capacityInterval = useMemo(() => {
    const reversedHeatmap = heatmap.slice(0).reverse();
    return (
      heatmap.find(
        (interval) =>
          interval.min === interval.max && percentageOfCapacity === interval.min
      ) ??
      reversedHeatmap.find((interval) => percentageOfCapacity > interval.min) ??
      heatmap[0]
    );
  }, [heatmap, percentageOfCapacity]);

  const overflow = capacityInterval?.style.overflow;
  // TODO: use actual condensed view level
  const overflowSize =
    CONDENSED_VIEW_OVERFLOW_TRIANGLE.NORMAL * (overflow === 'some' ? 2 / 3 : 1);

  const summaryBackgroundHeight = clamp(
    percentageOfCapacity >= 90 && percentageOfCapacity < 100
      ? 90
      : percentageOfCapacity,
    0,
    100
  );

  return (
    <HeatmapContainer
      $capacityColor={capacityInterval?.style.color}
      $overflowSize={overflow ? overflowSize : undefined}
      $summaryBackgroundHeight={summaryBackgroundHeight}
    >
      <CenterContainer>
        <CenterValueText className="hide-on-hover">
          {defaultValue > 0 && (
            <>
              {formatNumWithMaxOneDecimal(defaultValue)}
              {unit}
            </>
          )}
        </CenterValueText>
        <CenterValueText className="show-on-hover">
          {formatNumWithMaxOneDecimal(hoverValue)}
          {unit}
        </CenterValueText>
        <FloatingContainer>
          {PTOValue > 0 && (
            <PTOValueText className="hide-on-hover">
              {formatNumWithMaxOneDecimal(PTOValue)}
              {unit} PTO
            </PTOValueText>
          )}
          <HourTypeOnHoverText className="show-on-hover">
            {hourTypeOnHoverText}
          </HourTypeOnHoverText>
        </FloatingContainer>
      </CenterContainer>
    </HeatmapContainer>
  );
};

const HourTypeOnHoverText = styled.span`
  font-size: 10px;
`;
