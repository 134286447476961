import React from 'react';
import styled from 'styled-components';
import { TimelineGroup } from './TimelineGroup';
import PhaseScheduleTooltip from './PhaseScheduleTooltip';
export const EventTimeline = ({ data, renderItem, keyExtractor }) => {
  return (
    <RootContainer>
      {data.map(({ date, events }) => (
        <TimelineGroup
          date={date}
          events={events}
          key={date}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        ></TimelineGroup>
      ))}
      <PhaseScheduleTooltip></PhaseScheduleTooltip>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
