import { fetchEntity, changeEntity } from './generics';
import { put, select, all, call } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { waitFor } from 'sagas/helpers';
import {
  getAuthToken,
  getSelectedTeamId,
  getEntitySkills,
  getIsFetchingEntitySkills,
  getIsFetchingSkillsReport
} from 'selectors';
import * as actionCreators from 'actionCreators';

export function* fetchSkillsWorker(action) {
  const { teamId, loadAll } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    entityActions.fetchSkills,
    api.fetchSkills,
    teamId,
    [token],
    action
  );
}

export function* createSkillWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, name } = action.payload;
  const body = {
    team_id: teamId,
    name
  };
  yield changeEntity(
    entityActions.createSkill,
    api.createSkill,
    [token, body],
    action
  );
}

export function* updateSkillWorker(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateSkill,
    api.updateSkill,
    [token, id, payload],
    action
  );
}

export function* archiveSkillWorker(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.archiveSkill,
    api.archiveSkill,
    [token, id],
    action,
    action.payload
  );
}

export function* unarchiveSkillWorker(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.unarchiveSkill,
    api.unarchiveSkill,
    [token, id],
    action,
    action.payload
  );
}

export function* deleteSkillWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { id } = action.payload;
  yield changeEntity(
    entityActions.deleteSkill,
    api.deleteSkill,
    [token, id],
    action
  );
  yield put(actionCreators.fetchSkills({ teamId }));
}

/* ---------------------------- Skill membership ---------------------------- */

export function* createSkillMembershipWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamMembershipId,
    skillId,
    level,
    updateSkillsReportLocalParams,
    onSuccess
  } = action.payload;
  const body = {
    team_membership_id: teamMembershipId,
    team_skill_id: skillId,
    level
  };
  const { error, response } = yield changeEntity(
    entityActions.createSkillMembership,
    api.createSkillMembership,
    [token, body],
    action
  );

  if (!error && response) {
    if (updateSkillsReportLocalParams) {
      yield put(
        actionCreators.updateSkillLevelOnSkillsReportLocal({
          ...updateSkillsReportLocalParams,
          teamMemberSkillId: response.id
        })
      );
    }
    if (!error && response) {
      onSuccess?.forEach(({ successAction, selector }) => {
        successAction(
          selector ? selector(action.payload, response) : undefined
        );
      });
    }
  }
}

export function* updateSkillMembershipWorker(action) {
  const token = yield select(getAuthToken);
  const {
    skillMembershipId,
    level,
    goal,
    updateSkillsReportLocalParams,
    onSuccess
  } = action.payload;
  const body = {
    level,
    goal
  };
  const { error, response } = yield changeEntity(
    entityActions.updateSkillMembership,
    api.updateSkillMembership,
    [token, skillMembershipId, body],
    action,
    action.payload
  );

  if (!error && response) {
    if (updateSkillsReportLocalParams) {
      yield put(
        actionCreators.updateSkillLevelOnSkillsReportLocal({
          ...updateSkillsReportLocalParams,
          teamMemberSkillId: response.id
        })
      );
    }

    onSuccess?.forEach(({ successAction, selector }) => {
      successAction(selector ? selector(action.payload, response) : undefined);
    });
  }
}

export function* deleteSkillMembershipWorker(action) {
  const token = yield select(getAuthToken);
  const { skillMembershipId, updateSkillsReportLocalParams } = action.payload;
  const { error, response } = yield changeEntity(
    entityActions.deleteSkillMembership,
    api.deleteSkillMembership,
    [token, skillMembershipId],
    action,
    action.payload
  );

  if (!error && response && updateSkillsReportLocalParams) {
    yield put(
      actionCreators.updateSkillLevelOnSkillsReportLocal({
        ...updateSkillsReportLocalParams,
        teamMemberSkillId: response.id
      })
    );
  }
}

/* ------------------------------ Skills Report ----------------------------- */
export function* fetchEntitySkillsAndSkillsReportWorker(action) {
  const {
    entitySkillsParams,
    skillsReportFetchParams,
    onSuccess = []
  } = action.payload;

  yield put(actionCreators.fetchEntitySkills({ params: entitySkillsParams }));
  yield call(waitFor, (state) => !getIsFetchingEntitySkills(state));

  if (skillsReportFetchParams) {
    const accountIds = skillsReportFetchParams.account_ids;
    const entitySkills = yield select(getEntitySkills);
    const skillIds = (
      entitySkills.Position?.[entitySkillsParams.entity_ids?.[0]] || []
    ).map((skill) => skill.team_skill_id);

    if (accountIds.length && skillIds.length) {
      yield put(
        actionCreators.fetchSkillsReport({
          params: {
            ...skillsReportFetchParams,
            skill_ids: skillIds
          }
        })
      );
      yield call(waitFor, (state) => !getIsFetchingSkillsReport(state));
    }
  }

  onSuccess.forEach(({ successAction }) => successAction());
}

export function* fetchSkillsReportWorker(action) {
  const token = yield select(getAuthToken);
  const { params } = action.payload;

  const { error, response } = yield fetchEntity(
    entityActions.fetchSkillsReport,
    api.fetchSkillsReport,
    params,
    [token],
    action
  );
}

/* ------------------------------ Entity Skills ----------------------------- */
export function* fetchEntitySkillsWorker(action) {
  const { params } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    entityActions.fetchEntitySkills,
    api.fetchEntitySkills,
    undefined,
    [token, params],
    action
  );
}

export function* createEntitySkillWorker(action) {
  const token = yield select(getAuthToken);
  const { body } = action.payload;
  const { error, response } = yield changeEntity(
    entityActions.createEntitySkill,
    api.createEntitySkill,
    [token, body],
    action,
    action.payload
  );

  if (!error && response) {
    const { entity_skills } = response;
    const { team_id, entity_id, entity_type } = entity_skills[0];
    yield put(
      actionCreators.fetchEntitySkills({
        params: {
          team_id,
          entity_type,
          entity_ids: [entity_id]
        }
      })
    );
  }
}

export function* updateEntitySkillWorker(action) {
  const { entitySkillId, body } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    entityActions.updateEntitySkill,
    api.updateEntitySkill,
    [token, entitySkillId, body],
    action,
    action.payload
  );
}

export function* deleteEntitySkillWorker(action) {
  const token = yield select(getAuthToken);
  const { entitySkillId, shouldFetchEntitySkills, entitySkillsParams } =
    action.payload;
  const { error } = yield changeEntity(
    entityActions.deleteEntitySkill,
    api.deleteEntitySkill,
    [token, entitySkillId],
    action,
    action.payload
  );

  if (!error && shouldFetchEntitySkills) {
    yield put(
      actionCreators.fetchEntitySkills({
        params: entitySkillsParams
      })
    );
  }
}
