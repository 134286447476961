import merge from 'lodash/merge';
import { PartialDeep } from 'type-fest';
import { SCROLLBAR_WIDTH } from 'appConstants/scrollbar';
import { CommonTableConfig } from './types';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import { CollapseHeaderCell } from './Cells/Headers/CollapseHeaderCell';
import { NameHeaderCell } from './Cells/Headers/NameHeaderCell';
import { IndicatorHeaderCell } from './Cells/Headers/IndicatorHeaderCell';
import { MenuCell } from './Cells/Headers/MenuHeaderCell';
import { EmptyGroupCell } from './Cells/EmptyGroupCell';
import { LoadingCell } from './Cells/LoadingCell';
import { EmptyCell } from './Cells/EmptyCell';
import { WorkCategoryHeaderCell } from './Cells/Headers/WorkCategoryHeaderCell';
import { RateHeaderCell } from './Cells/Headers/RateHeaderCell';
import { DescriptionHeaderCell } from './Cells/Headers/DescriptionHeaderCell';
import { NameCell } from './Cells/NameCell';
import { RateCell } from './Cells/RateCell';
import { DescriptionCell } from './Cells/DescriptionCell';
import { RoleHeaderCell } from './Cells/Headers/RoleHeaderCell';
import { RateArchiveCell } from './Cells/RateArchiveCell';
import {
  billableColumn,
  billRateColumn,
  collapseColumn,
  costRateColumn,
  descriptionColumn,
  fullContentColumn,
  fullWidthColumn,
  menuColumn,
  nameColumn,
  rateColumn,
  rateGroupHeaderColumn
} from './tableConfigsColumns';
import { BillableCell } from './Cells/BillableCell';
import { BillableHeaderCell } from './Cells/Headers/BillableHeaderCell';

const MAX_TABLE_HEIGHT_BUFFER = 311;
const RATE_COLUMN_WIDTH = 120;
const DESCRIPTION_COLUMN_WIDTH = 300;
const NAME_COLUMN_WIDTH = 240;
const BILLABLE_COLUMN_WIDTH = 80;

// ------------------------------- rows / cells --------------------------------

export const ROW_TYPES = {
  emptyGroupRow: 'emptyGroupRow',
  emptyRow: 'emptyRow',
  loadingRow: 'loadingRow',
  rateArchiveRow: 'rateArchiveRow',
  rateGroupIndicatorRow: 'rateGroupIndicatorRow',
  rateGroupHeaderRow: 'rateGroupHeaderRow',
  rateGroupNameRow: 'rateGroupNameRow',
  rateRow: 'rateRow',
  archivedRateRow: 'archivedRateRow'
} as const;

export const ROW_CLASSES = {
  archivedRateEntity: 'archivedRateEntity',
  archivedRateGroup: 'archivedRateGroup'
} as const;

// -------------------------------- dimensions ---------------------------------

const sharedColumnWidthsByHeaderType = {
  [collapseColumn.headerType]: 26
};

const fixedHeaderColumnWidthsByHeaderType = {
  [menuColumn.headerType]: 24
};

// Compute the width of the columns that are shared by the headers and content.
const sharedColumnWidths = Object.values(sharedColumnWidthsByHeaderType).reduce(
  (acc, w) => (acc += w)
);

// Compute the width of the columns that are shared by the headers.
const fixedHeaderWidths =
  sharedColumnWidths +
  Object.values(fixedHeaderColumnWidthsByHeaderType).reduce(
    (acc, w) => (acc += w)
  );

const columnWidthsByHeaderType = {
  ...sharedColumnWidthsByHeaderType,
  ...fixedHeaderColumnWidthsByHeaderType
};

// ---------------------------------- configs ----------------------------------

const roleRateRow = {
  [collapseColumn.id]: EmptyCell,
  [nameColumn.id]: NameCell,
  [costRateColumn.id]: RateCell,
  [billRateColumn.id]: RateCell,
  [descriptionColumn.id]: DescriptionCell,
  [billableColumn.id]: BillableCell
};

const workCategoryRateRow = {
  [collapseColumn.id]: EmptyCell,
  [nameColumn.id]: NameCell,
  [rateColumn.id]: RateCell,
  [descriptionColumn.id]: DescriptionCell,
  [billableColumn.id]: BillableCell
};

const staticTableConfigs = {
  shared: {
    tableProps: {
      classNames: { list: 'scrollbar' },
      columnWidthsByHeaderType,
      hasStickyHeader: false,
      rowTypesHash: ROW_TYPES,
      rowTypeToCellsByHeaderType: {
        [ROW_TYPES.rateGroupNameRow]: {
          [collapseColumn.id]: CollapseHeaderCell,
          [menuColumn.id]: MenuCell,
          [rateGroupHeaderColumn.id]: NameHeaderCell
        },
        [ROW_TYPES.rateGroupIndicatorRow]: {
          [collapseColumn.id]: EmptyCell,
          [menuColumn.id]: EmptyCell,
          [rateGroupHeaderColumn.id]: IndicatorHeaderCell
        },
        [ROW_TYPES.loadingRow]: {
          [fullWidthColumn.id]: LoadingCell
        },
        [ROW_TYPES.emptyGroupRow]: {
          [collapseColumn.id]: EmptyCell,
          [fullContentColumn.id]: EmptyGroupCell
        },
        [ROW_TYPES.emptyRow]: {},
        [ROW_TYPES.rateArchiveRow]: {
          [collapseColumn.id]: EmptyCell,
          [fullContentColumn.id]: RateArchiveCell
        }
      },
      showHeader: false,
      tableColumns: [
        // Shared
        collapseColumn,

        // Header
        menuColumn,
        rateGroupHeaderColumn,

        // Content
        nameColumn,
        rateColumn,
        costRateColumn,
        billRateColumn,
        descriptionColumn,
        billableColumn,
        fullContentColumn,

        // Loading
        fullWidthColumn
      ]
    }
  },
  [EntityRateType.Role]: {
    tableProps: {
      columnWidthsByHeaderType: {
        [billableColumn.headerType]: BILLABLE_COLUMN_WIDTH,
        [billRateColumn.headerType]: RATE_COLUMN_WIDTH,
        [costRateColumn.headerType]: RATE_COLUMN_WIDTH,
        [descriptionColumn.headerType]: DESCRIPTION_COLUMN_WIDTH,
        [nameColumn.headerType]: NAME_COLUMN_WIDTH
      },
      rowTypeToCellsByHeaderType: {
        [ROW_TYPES.rateGroupHeaderRow]: {
          [collapseColumn.id]: EmptyCell,
          [nameColumn.id]: RoleHeaderCell,
          [costRateColumn.id]: RateHeaderCell,
          [billRateColumn.id]: RateHeaderCell,
          [descriptionColumn.id]: DescriptionHeaderCell
        },
        [ROW_TYPES.rateRow]: roleRateRow,
        [ROW_TYPES.archivedRateRow]: roleRateRow
      }
    }
  },
  [EntityRateType.WorkCategory]: {
    tableProps: {
      columnWidthsByHeaderType: {
        [billableColumn.headerType]: BILLABLE_COLUMN_WIDTH,
        [descriptionColumn.headerType]: DESCRIPTION_COLUMN_WIDTH,
        [nameColumn.headerType]: NAME_COLUMN_WIDTH,
        [rateColumn.headerType]: RATE_COLUMN_WIDTH
      },
      rowTypeToCellsByHeaderType: {
        [ROW_TYPES.rateGroupHeaderRow]: {
          [collapseColumn.id]: EmptyCell,
          [nameColumn.id]: WorkCategoryHeaderCell,
          [rateColumn.id]: RateHeaderCell,
          [descriptionColumn.id]: DescriptionHeaderCell,
          [billableColumn.id]: BillableHeaderCell
        },
        [ROW_TYPES.rateRow]: workCategoryRateRow,
        [ROW_TYPES.archivedRateRow]: workCategoryRateRow
      }
    }
  }
} as const;

export const getTableConfig = ({
  variant
}: {
  variant: EntityRateType;
}): Omit<
  CommonTableConfig,
  | 'mainList'
  | 'listRef'
  | 'customRowProps'
  | 'loadMoreItems'
  | 'customHandleScroll'
> => {
  const config = staticTableConfigs[variant];

  // Compute the width of the full table based on the width of the content of
  // the table variant.
  const totalWidth =
    sharedColumnWidths +
    Object.values(config.tableProps.columnWidthsByHeaderType).reduce<number>(
      (acc, w) => (acc += w),
      0
    );

  const computedTableConfigs: PartialDeep<CommonTableConfig> = {
    tableProps: {
      columnWidthsByHeaderType: {
        [rateGroupHeaderColumn.headerType]: totalWidth - fixedHeaderWidths,
        [fullContentColumn.headerType]: totalWidth - sharedColumnWidths,
        [fullWidthColumn.headerType]: totalWidth
      },
      maxHeight: window.innerHeight - MAX_TABLE_HEIGHT_BUFFER,
      totalColumnsWidthOverride: totalWidth + SCROLLBAR_WIDTH
    }
  };

  // The computed and shared configurations take precedence.
  const mergedConfig = merge(
    {},
    computedTableConfigs,
    staticTableConfigs.shared,
    config
  ) as Omit<
    CommonTableConfig,
    | 'customHandleScroll'
    | 'customRowProps'
    | 'listRef'
    | 'loadMoreItems'
    | 'mainList'
  >;

  return mergedConfig;
};
