import React from 'react';
import SvgIcon from 'components/SvgIcon';

const EstimatedHoursIcon = ({ className }) => (
  <SvgIcon
    className={className}
    fill="none"
    height="10"
    viewBox="0 0 10 10"
    width="10"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.1454 5.56401H4.96358C4.70932 5.56401 4.50903 5.36372 4.50903 5.10946C4.50903 4.85521 4.70932 4.65492 4.96358 4.65492H7.1454C7.39965 4.65492 7.59994 4.85521 7.59994 5.10946C7.59994 5.36372 7.39965 5.56401 7.1454 5.56401Z"
      fill="#808080"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0546 9.83674C2.34587 9.83674 0.145508 7.63637 0.145508 4.92765C0.145508 2.21892 2.34587 0.0185547 5.0546 0.0185547C7.76333 0.0185547 9.96369 2.21892 9.96369 4.92765C9.96369 7.63637 7.76333 9.83674 5.0546 9.83674ZM5.0546 0.927646C2.85424 0.927646 1.0546 2.72735 1.0546 4.92765C1.0546 7.12801 2.85431 8.92765 5.0546 8.92765C7.25496 8.92765 9.0546 7.12794 9.0546 4.92765C9.0546 2.72728 7.25418 0.927646 5.0546 0.927646Z"
      fill="#808080"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96358 5.56401C4.70932 5.56401 4.50903 5.36372 4.50903 5.10946V2.74583C4.50903 2.49157 4.70932 2.29128 4.96358 2.29128C5.21783 2.29128 5.41812 2.49157 5.41812 2.74583V5.10946C5.41812 5.36372 5.21783 5.56401 4.96358 5.56401Z"
      fill="#808080"
    />
  </SvgIcon>
);

export default EstimatedHoursIcon;
