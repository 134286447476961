import { Draft, createReducer } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import * as constants from 'SettingsModule/constants/discipline';
import { Discipline } from 'SettingsModule/models/discipline';

export interface DisciplinesState {
  disciplinesHash: Record<number, Discipline>;
  isLoaded: boolean;
  isLoading: boolean;
}

export const initialState: DisciplinesState = {
  disciplinesHash: {},
  isLoaded: false,
  isLoading: false
};

const handleFetchDisciplinesTrigger = (state: Draft<DisciplinesState>) => {
  state.isLoading = true;
};

const handleFetchDisciplinesFailure = (state: Draft<DisciplinesState>) => {
  state.isLoading = false;
  state.isLoaded = true;
};

const handleFetchDisciplinesSuccess = (
  state: Draft<DisciplinesState>,
  action
) => {
  const { response } = action.payload as {
    response: FetchDisciplinesSuccessResponse;
  };

  state.disciplinesHash = keyBy(
    response.disciplines,
    (discipline) => discipline.id
  );

  state.isLoaded = true;
  state.isLoading = false;
};

const handleCreateUpdateDisciplineSuccess = (
  state: Draft<DisciplinesState>,
  action
) => {
  const { response } = action.payload as {
    response: CreateUpdateDisciplineSuccessResponse;
  };

  state.disciplinesHash[response.id] = response;
};

const handleUpdateDisciplineEntitiesSuccess = (
  state: Draft<DisciplinesState>,
  action
) => {
  const { response } = action.payload as {
    response: UpdateDisciplineEntitiesSuccessResponse;
  };

  state.disciplinesHash[response.id] = response;
};

export const disciplinesReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    constants.FETCH_DISCIPLINES.TRIGGER,
    handleFetchDisciplinesTrigger
  );
  builder.addCase(
    constants.FETCH_DISCIPLINES.FAILURE,
    handleFetchDisciplinesFailure
  );
  builder.addCase(
    constants.FETCH_DISCIPLINES.SUCCESS,
    handleFetchDisciplinesSuccess
  );
  builder.addCase(
    constants.CREATE_DISCIPLINE.SUCCESS,
    handleCreateUpdateDisciplineSuccess
  );
  builder.addCase(
    constants.UPDATE_DISCIPLINE.SUCCESS,
    handleCreateUpdateDisciplineSuccess
  );
  builder.addCase(
    constants.UPDATE_DISCIPLINE_ENTITIES.SUCCESS,
    handleUpdateDisciplineEntitiesSuccess
  );
});

interface FetchDisciplinesSuccessResponse {
  disciplines: Discipline[];
}

type CreateUpdateDisciplineSuccessResponse = Discipline;
type UpdateDisciplineEntitiesSuccessResponse = Discipline;
