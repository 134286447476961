import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createScope } from 'actionCreators';
import { getIsOnProjectView } from 'selectors';
import { StyledScopeModal } from './styles';
import { ParentInfo, DescriptionSection } from '../components';
import {
  NoteSection,
  AttachmentsSection,
  DatesSection,
  HeaderButtons,
  ScrollContainer,
  Header,
  HeaderTitleRow,
  HeaderTitle,
  PaddingContainer
} from 'components/Modals/themes/TwoPaneModal';
import withCheckIfOpen from 'hocs/withCheckIfOpen';
import { formatOnSuccess } from 'appUtils/formatUtils';
import { DESCRIPTION_ERROR_TEXT } from '../constants';
import ScopeMenu, {
  SCOPE_MENU_OPTIONS
} from 'components/Scope/menus/ScopeMenu';
import moment from 'moment';

/**
 *  Required modules: [BudgetModule]
 */
const NewScopeModal = ({
  isNew,
  bar,
  projectId,
  phaseId,
  activityId,
  activityPhaseId,
  toggle,
  isOnProjectView,
  onCreateSuccess
}) => {
  // Prevent changes if the modal is being rendered from a table row cell and the rows change
  const [_props] = useState({
    projectId,
    phaseId,
    activityId,
    activityPhaseId
  });
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [files, setFiles] = useState([]);
  const [note, setNote] = useState('');
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    dependencyInfos: null
  });
  const [isCreating, setIsCreating] = useState(false); // prevent double clicks

  const isValid = !!description.trim().length;

  const handleCreateScope = useCallback(() => {
    if (!isValid) {
      setDescriptionError(DESCRIPTION_ERROR_TEXT);
    } else if (!isCreating) {
      setIsCreating(true);
      dispatch(
        createScope({
          activityPhaseId: _props.activityPhaseId,
          description,
          note,
          onSuccess: formatOnSuccess(onCreateSuccess),
          files,
          // estimatedHours,
          ...(dates.startDate &&
            dates.endDate && {
              scheduleStart: moment(dates.startDate).format('YYYY-MM-DD'),
              scheduleEnd: moment(dates.endDate).format('YYYY-MM-DD'),
              dependencyInfos: JSON.stringify(dates.dependencyInfos)
            })
          // parentScopeId,
          // isRequest
        })
      );
      toggle();
    }
  }, [
    _props.activityPhaseId,
    dates.endDate,
    dates.startDate,
    dates.dependencyInfos,
    description,
    dispatch,
    files,
    isCreating,
    isValid,
    note,
    onCreateSuccess,
    toggle
  ]);

  const handleDescriptionChange = useCallback(
    (newDescription) => {
      // reset error if necessary
      if (descriptionError && newDescription.trim().length) {
        setDescriptionError('');
      }
      setDescription(newDescription);
    },
    [descriptionError]
  );

  const handleNoteChange = useCallback((newNote) => {
    setNote(newNote);
  }, []);

  const handleNewFiles = useCallback(
    (newFiles) => setFiles([...files, ...newFiles]),
    [files]
  );
  const handleRemoveFile = (removedFile) =>
    setFiles(files.filter((file) => file !== removedFile));

  return (
    <StyledScopeModal isOpen $isNew wrapClassName="scope-modal-wrapper">
      <Header>
        <HeaderTitleRow>
          <HeaderTitle>
            <h1>Scope Detail</h1>
            <ScopeMenu
              projectId={_props.projectId}
              options={[SCOPE_MENU_OPTIONS.GO_TO_PROJECT]}
            />
          </HeaderTitle>

          <HeaderButtons
            onClose={toggle}
            onSave={handleCreateScope}
            isSaveDisabled={!isValid}
            projectId={projectId}
            hasChanges
          />
        </HeaderTitleRow>
      </Header>

      <ScrollContainer>
        {/* Project/phase/activity titles */}
        <ParentInfo
          projectId={_props.projectId}
          phaseId={_props.phaseId}
          activityId={_props.activityId}
          showNavToProject={!isOnProjectView}
        />

        {/* Scope description */}
        <DescriptionSection
          onChange={handleDescriptionChange}
          error={descriptionError}
          autoFocus
        />

        <PaddingContainer>
          {/* Notes */}
          <NoteSection onChange={handleNoteChange} />

          {/* Attachments */}
          <AttachmentsSection
            attachments={files}
            handleNewAttachments={handleNewFiles}
            handleDeleteAttachment={handleRemoveFile}
          />

          {/* Dates */}
          <DatesSection
            startDate={dates.startDate}
            endDate={dates.endDate}
            handleDatesChange={setDates}
            bar={bar}
            isNew={isNew}
          />
        </PaddingContainer>
      </ScrollContainer>
    </StyledScopeModal>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => ({
    isOnProjectView: getIsOnProjectView(state)
  });
  return mapStateToProps;
};

export default withCheckIfOpen(connect(makeMapStateToProps)(NewScopeModal));
