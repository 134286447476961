import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import theme from 'theme';
import { navigateBack, navigateToReport } from 'actionCreators';

import { getMe, getNavigationHistory, getTeamSlug } from 'selectors';

const StyledModalBody = styled(ModalBody)`
  padding: 40px 40px 20px;
  border: none;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding: 0px 40px 20px 40px;
  border: none;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  border-radius: 5px;
`;

class ComingSoonModal extends React.Component {
  state = {
    isModalOpen: true
  };

  toggleModal = () => {
    this.setState({ isModalOpen: false });
  };

  navReports = () => {
    const { navigateToReport, teamSlug } = this.props;
    navigateToReport({ teamSlug, viewType: 'time' });
  };

  goBack = () => {
    const { navigateBack } = this.props;
    navigateBack();
  };

  render() {
    const { className, navHistory } = this.props;
    return (
      <div>
        <Modal isOpen={this.state.isModalOpen} className={className}>
          <StyledModalBody>This Timesheet view has been moved.</StyledModalBody>
          <StyledModalFooter>
            <div>
              {navHistory?.length > 1 && (
                <StyledButton color="secondary" onClick={this.goBack}>
                  Go Back
                </StyledButton>
              )}
            </div>
            <div>
              <StyledButton color="primary" onClick={this.navReports}>
                Go to Reports
              </StyledButton>
            </div>
          </StyledModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  teamSlug: getTeamSlug(state),
  me: getMe(state),
  navHistory: getNavigationHistory(state)
});

export default withRouter(
  connect(mapStateToProps, { navigateBack, navigateToReport })(ComingSoonModal)
);
