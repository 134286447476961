import { makeDateFromDateTime } from 'appUtils/momentUtils';

const createAssignmentDescription = (assigneeName) =>
  assigneeName === 'Unassigned'
    ? 'unassigned the task,'
    : `assigned the task to ${assigneeName},`;

const createDueDateDescription = (dueDate) =>
  !dueDate || dueDate === 'Invalid date'
    ? 'removed the due date,'
    : `set due date to ${dueDate},`;

const createScheduleStartDateDescription = (scheduleStart) =>
  !scheduleStart || scheduleStart === 'Invalid date'
    ? 'removed the scheduled date,'
    : `set scheduled date to ${scheduleStart},`;

export const ACTION_TYPES = {
  TASK_EDIT_DESCRIPTION: 2,
  TASK_SET_DUE_DATE: 3,
  TASK_SET_SCHEDULE_DATE: 4,
  TASK_SET_ESTIMATION: 5,
  TASK_ASSIGN: 6,
  TASK_ACCEPT: 7,
  TASK_CHANGE_PROJECT: 8,
  TASK_FOLLOW: 9,
  TASK_UNFOLLOW: 10,
  TASK_COMPLETE: 11,
  TASK_CHANGE_ACTIVITY_PHASE: 216,
  TASK_CHANGE_REQUEST_APPROVER: 217,
  TASK_EDIT_NOTE: 218,
  TASK_APPROVE_REQUEST: 219,
  TASK_REJECT_REQUEST: 220,
  TASK_CREATE_REQUEST: 226,
  TASK_EDIT_REQUEST: 227,
  TASK_DESTROY_REQUEST: 228,
  PROJECT_CREATE: 12,
  PROJECT_ARCHIVE: 13,
  PROJECT_ADD_MEMBER: 14,
  TASK_COMMENT: 15,
  TASK_REPLY: 16,
  TASK_MENTION: 17,
  NOTE_CREATION: 18,
  TASK_INCOMPLETE: 19,
  PROJECT_REMOVE_MEMBER: 20,
  TASK_CREATE: 21,
  PROJECT_STATUS: 22,
  SEND_SCHEDULED_EVENT: 138,
  FOLLOWING_SCHEDULED_EVENT: 139,
  CREATE_TIME_ENTRY: 81,
  READ_TIME_ENTRY: 82,
  UPDATE_TIME_ENTRY: 83,
  DELETE_TIME_ENTRY: 84,
  SET_TIME_ENTRY_HOURS: 85,
  SET_TIME_ENTRY_DESCRIPTION: 86,
  SET_TIME_ENTRY_BILLABLE: 87,
  SET_TIME_ENTRY_STATUS: 88,
  SET_TIME_ENTRY_SYNC_STATUS: 89,
  SET_TIME_ENTRY_OVERRIDE_RATE: 90,
  SET_TIME_ENTRY_DATE: 91,
  INTEGRATION_MESSAGE: 196,
  CREATE_ACTIVITY_PHASE_SCHEDULE_BAR_REQUEST: 281,
  UPDATE_ACTIVITY_PHASE_SCHEDULE_BAR_REQUEST: 282,
  DELETE_ACTIVITY_PHASE_SCHEDULE_BAR_REQUEST: 283,
  ACTIVITY_PHASE_SCHEDULE_BAR_APPROVE_REQUEST: 284,
  ACTIVITY_PHASE_SCHEDULE_BAR_REJECT_REQUEST: 285
};

export const TASK_ACTIONS = [
  ACTION_TYPES.TASK_CREATE,
  ACTION_TYPES.TASK_EDIT_DESCRIPTION,
  ACTION_TYPES.TASK_SET_DUE_DATE,
  ACTION_TYPES.TASK_SET_SCHEDULE_DATE,
  ACTION_TYPES.TASK_SET_ESTIMATION,
  ACTION_TYPES.TASK_ASSIGN,
  ACTION_TYPES.TASK_ACCEPT,
  ACTION_TYPES.TASK_CHANGE_PROJECT,
  ACTION_TYPES.TASK_FOLLOW,
  ACTION_TYPES.TASK_UNFOLLOW,
  ACTION_TYPES.TASK_COMPLETE,
  ACTION_TYPES.TASK_MENTION,
  ACTION_TYPES.TASK_COMMENT,
  ACTION_TYPES.TASK_REPLY,
  ACTION_TYPES.TASK_INCOMPLETE
];

export const PROJECT_ACTIONS = [
  ACTION_TYPES.PROJECT_CREATE,
  ACTION_TYPES.PROJECT_ARCHIVE,
  ACTION_TYPES.PROJECT_ADD_MEMBER,
  ACTION_TYPES.PROJECT_REMOVE_MEMBER,
  ACTION_TYPES.PROJECT_STATUS
];

export const NOTE_ACTIONS = [ACTION_TYPES.NOTE_CREATION];

export const COMMENT_ACTIONS = [
  ACTION_TYPES.TASK_COMMENT,
  ACTION_TYPES.TASK_REPLY,
  ACTION_TYPES.PROJECT_STATUS // currently no distinction between top level and replies
];

export const EMAIL_ACTIONS = [
  ACTION_TYPES.SEND_SCHEDULED_EVENT,
  ACTION_TYPES.FOLLOWING_SCHEDULED_EVENT
];

export const INTEGRATION_ACTIONS = [ACTION_TYPES.INTEGRATION_MESSAGE];

export const TIMESHEET_ACTIONS = [
  ACTION_TYPES.CREATE_TIME_ENTRY,
  ACTION_TYPES.UPDATE_TIME_ENTRY,
  ACTION_TYPES.DELETE_TIME_ENTRY,
  ACTION_TYPES.SET_TIME_ENTRY_HOURS,
  ACTION_TYPES.SET_TIME_ENTRY_DESCRIPTION,
  ACTION_TYPES.SET_TIME_ENTRY_BILLABLE,
  ACTION_TYPES.SET_TIME_ENTRY_STATUS,
  ACTION_TYPES.SET_TIME_ENTRY_SYNC_STATUS,
  ACTION_TYPES.SET_TIME_ENTRY_OVERRIDE_RATE,
  ACTION_TYPES.SET_TIME_ENTRY_DATE
];

// put similar actions in same array
export const TIMESHEET_ACTION_SIMILARITIES = [
  [
    ACTION_TYPES.CREATE_TIME_ENTRY,
    ACTION_TYPES.UPDATE_TIME_ENTRY,
    ACTION_TYPES.DELETE_TIME_ENTRY,
    ACTION_TYPES.SET_TIME_ENTRY_HOURS,
    ACTION_TYPES.SET_TIME_ENTRY_DESCRIPTION,
    ACTION_TYPES.SET_TIME_ENTRY_BILLABLE,
    ACTION_TYPES.SET_TIME_ENTRY_SYNC_STATUS,
    ACTION_TYPES.SET_TIME_ENTRY_OVERRIDE_RATE,
    ACTION_TYPES.SET_TIME_ENTRY_DATE
  ],
  [ACTION_TYPES.SET_TIME_ENTRY_STATUS]
].reduce((actionTypeToIndexHash, similarActions, index) => {
  similarActions.forEach((action) => {
    actionTypeToIndexHash[action] = index;
  });
  return actionTypeToIndexHash;
}, {});

export const WORKPLAN_REQUEST_ACTIONS = [
  ACTION_TYPES.CREATE_ACTIVITY_PHASE_SCHEDULE_BAR_REQUEST,
  ACTION_TYPES.UPDATE_ACTIVITY_PHASE_SCHEDULE_BAR_REQUEST,
  ACTION_TYPES.DELETE_ACTIVITY_PHASE_SCHEDULE_BAR_REQUEST,
  ACTION_TYPES.ACTIVITY_PHASE_SCHEDULE_BAR_APPROVE_REQUEST,
  ACTION_TYPES.ACTIVITY_PHASE_SCHEDULE_BAR_REJECT_REQUEST
];

export const setTaskIcon = (actionType) => {
  switch (actionType) {
    case ACTION_TYPES.TASK_CREATE:
    case ACTION_TYPES.TASK_EDIT_DESCRIPTION:
    case ACTION_TYPES.TASK_SET_DUE_DATE:
    case ACTION_TYPES.TASK_SET_SCHEDULE_DATE:
    case ACTION_TYPES.TASK_SET_ESTIMATION:
    case ACTION_TYPES.TASK_ASSIGN:
    case ACTION_TYPES.TASK_ACCEPT:
    case ACTION_TYPES.TASK_FOLLOW:
      return 'task-icon';
    case ACTION_TYPES.TASK_COMPLETE:
      return 'task-complete-icon';
    case ACTION_TYPES.PROJECT_CREATE:
    case ACTION_TYPES.PROJECT_ARCHIVE:
    case ACTION_TYPES.PROJECT_ADD_MEMBER:
      return 'project-icon';
    case ACTION_TYPES.TASK_COMMENT:
    case ACTION_TYPES.TASK_REPLY:
    case ACTION_TYPES.TASK_MENTION:
      return 'comment-icon';
    case ACTION_TYPES.NOTE_CREATION:
      return 'note-icon';
    default:
      return '';
  }
};

export const actionDescriptionCreator = (metadata, isAssignedToCurrentUser) => {
  let actionDescription;
  const {
    type,
    affected,
    project,
    assignees,
    due_date,
    schedule_start,
    actor
  } = metadata;
  switch (type) {
    case ACTION_TYPES.TASK_EDIT_DESCRIPTION:
      actionDescription = 'edited the task,';
      break;
    case ACTION_TYPES.TASK_SET_DUE_DATE:
      actionDescription = createDueDateDescription(
        makeDateFromDateTime(due_date).startOf('day').format('M/D/YY')
      );
      break;
    case ACTION_TYPES.TASK_SET_SCHEDULE_DATE:
      actionDescription = createScheduleStartDateDescription(
        makeDateFromDateTime(schedule_start).format('M/D/YY')
      );
      break;
    case ACTION_TYPES.TASK_SET_ESTIMATION:
      actionDescription = 'estimated ' + ' hours for the task,';
      break;
    case ACTION_TYPES.TASK_ASSIGN:
      actionDescription = createAssignmentDescription(
        isAssignedToCurrentUser ? 'you' : assignees?.[0]?.name
      );
      break;
    case ACTION_TYPES.TASK_ACCEPT:
      actionDescription = 'accepted the task,';
      break;
    case ACTION_TYPES.TASK_CHANGE_PROJECT:
      actionDescription = project
        ? `changed the project to '${project.title}',`
        : 'removed from a project';
      break;
    case ACTION_TYPES.TASK_COMPLETE:
      actionDescription = 'completed this task,';
      break;
    case ACTION_TYPES.TASK_INCOMPLETE:
      actionDescription = 'marked this task incomplete,';
      break;
    case ACTION_TYPES.PROJECT_ADD_MEMBER:
      if (affected && actor && affected.id === actor.id) {
        actionDescription = 'joined this project';
      } else {
        actionDescription = `added ${
          affected ? affected.name : 'someone'
        } to this project`;
      }
      break;
    case ACTION_TYPES.PROJECT_REMOVE_MEMBER:
      if (affected && actor && affected.id === actor.id) {
        actionDescription = `left this project`;
      } else {
        actionDescription = `removed ${
          affected ? affected.name : 'someone'
        } from this project`;
      }
      break;
    case ACTION_TYPES.PROJECT_CREATE:
      actionDescription = `created this project`;
      break;
    case ACTION_TYPES.PROJECT_ARCHIVE:
      actionDescription = `archived this project`;
      break;
    case ACTION_TYPES.TASK_CREATE:
      actionDescription = `created this task,`;
      break;
  }
  return actionDescription;
};
