import React from 'react';
import { connect } from 'react-redux';
import { getUsers, getGlobalMenuInviteFormOpen } from 'selectors';
import { toggleInviteForm } from 'actionCreators';
import styled, { css } from 'styled-components';
import theme from 'theme';
import EmailIcon from 'icons/EmailIcon';
import { useAppSelector } from 'reduxInfra/hooks';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';

import MemberSettingsTabs from './MemberSettingsTabs';
import { ThreeDotMenu, OPTION_KEYS } from './CsvImportThreeDotMenu';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import useCan from 'appUtils/hooks/useCan';
import {
  inviteGuests,
  inviteMemberToTeam
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { INVITE_MEMBER_TO_TEAM_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';

const StyledMemberInviteButton = styled.div`
  cursor: pointer;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  padding: 8px 12px;
  border-radius: 30px;
  margin-left: 14px;
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 0px;
  min-width: 141px;
  height: 32px;

  svg {
    margin-right: 7px;
  }
`;

const StyledButtonText = styled.div`
  padding-bottom: 2px;
`;

const tabToOption = {
  members: OPTION_KEYS.IMPORT_MEMBERS,
  departments: OPTION_KEYS.IMPORT_DEPARTMENTS,
  roles: OPTION_KEYS.IMPORT_ROLES,
  budget: OPTION_KEYS.IMPORT_RATE,
  pto: OPTION_KEYS.IMPORT_PTO,
  capacity: OPTION_KEYS.IMPORT_CAPACITY,
  skills: OPTION_KEYS.IMPORT_SKILLS,
  locations: OPTION_KEYS.IMPORT_LOCATIONS,
  regions: OPTION_KEYS.IMPORT_REGIONS,
  offices: OPTION_KEYS.IMPORT_OFFICES,
  disciplines: OPTION_KEYS.IMPORT_DISCIPLINES
};

const threeDotMenuStyles = css`
  padding-left: 10px;
`;

const TeamMembersTab = ({ team, users, children, toggleInviteForm }) => {
  const handleInviteButtonClick = () =>
    toggleInviteForm({ open: true, origin: 'GlobalAdd' });

  const { settingsViewType: currentTab } = useAppSelector(
    getMatchedRouteParams
  );

  const { membersCsvImportFlag } = useFeatureFlags();

  const canInviteToTeam = useCan(inviteMemberToTeam);

  const canInviteGuests = useCan(inviteGuests);

  const canInvite = canInviteToTeam || canInviteGuests;

  if (!users.me || !team) {
    return '';
  }

  return (
    <div
      className="team-settings-modal"
      style={{
        flexDirection: 'column',
        display: 'flex',
        overflow: 'visible',
        paddingLeft: 24
      }}
    >
      <div>
        <div
          className="team-settings-modal-header"
          style={{ borderBottom: 'none', paddingLeft: 7 }}
        >
          Member Management
          {/* Only member tab gets csv import for now */}
          {currentTab === 'members' && membersCsvImportFlag && (
            <ThreeDotMenu
              entityType={CsvImportEntity.Member}
              options={[tabToOption[currentTab]]}
              styles={threeDotMenuStyles}
              canInvite={canInvite}
            />
          )}
          <StyledMemberInviteButton
            onClick={canInvite ? handleInviteButtonClick : noop}
            data-html
            data-for="app-tooltip"
            data-effect="solid"
            data-class="center"
            data-tip={INVITE_MEMBER_TO_TEAM_TIP}
            data-tip-disable={canInvite}
          >
            <EmailIcon
              width="16px"
              height="12px"
              fillColor={theme.colors.colorPureWhite}
            />
            <StyledButtonText>Invite to Mosaic</StyledButtonText>
          </StyledMemberInviteButton>
        </div>
        <MemberSettingsTabs />
      </div>
      <div>{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: getUsers(state),
  team: state.teams.allTeams[0],
  inviteFormOpen: getGlobalMenuInviteFormOpen(state)
});
const mapDispatchToProps = { toggleInviteForm };

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembersTab);
