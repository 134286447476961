import { useAppSelector } from 'reduxInfra/hooks';
import { getAlphabeticalOfficesIdArray } from 'SettingsModule/selectors/offices';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Office } from 'SettingsModule/models/office';
import { useOffices } from 'SettingsModule/hooks';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';

const emptyArray = [];
const emptyObj = {};

export const useOfficesFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.office_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const { officeHash, handleNavToOfficesPage } = useOffices({ isOff });

  /** Array of alphabetically sorted office ids */
  const officeIdsArray: number[] = useAppSelector((state) =>
    isOff ? emptyArray : getAlphabeticalOfficesIdArray(state)
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: officeIdsArray,
    itemHash: officeHash,
    isOff,
    config,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    shouldUseDraft,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: officeHash,
    isLoading: isResultsLoading,
    labelKey,
    handleNavToEntityPage: handleNavToOfficesPage,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'name';

const searchableKeys: (keyof Office)[] = [labelKey];
