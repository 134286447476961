import styled from 'styled-components';

export const IntervalContainer = styled.div`
  height: 100%;
`;

export const DateContainer = styled.div`
  color: ${({ theme }) => theme.colors.colorSemiDarkGray3};
  padding: 4px 0;
  padding-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;

  &.showBorder {
    border-right: 1px solid ${({ theme }) => theme.colors.colorLightGray9};
  }
`;
