import * as constants from 'appConstants';
import { upsertItemPosition } from 'appUtils';
import cloneDeep from 'lodash/cloneDeep';

export const initialState = {
  plannerDate: null,
  plan: {
    tasks: []
  }
};

const dayPlanner = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_DAY_PLANNER.SUCCESS: {
      return {
        ...state,
        plan: action.payload.response
      };
    }
    case constants.UPDATE_DAY_PLANNER.TRIGGER: {
      const {
        taskId,
        isPersonal,
        date,
        description,
        project_name,
        due_at,
        position
      } = action.payload.params;
      const newTaskInfo = {
        id: Number(taskId),
        is_personal: isPersonal,
        schedule_start: date,
        description,
        project_name,
        due_at
      };

      const newTasks = upsertItemPosition(
        taskId,
        position,
        cloneDeep(state.plan.tasks),
        newTaskInfo
      );

      return {
        ...state,
        plan: { ...state.plan, tasks: newTasks }
      };
    }
    default:
      return state;
  }
};

export default dayPlanner;
