import { MutableRefObject, ReactNode } from 'react';
import theme from 'theme';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import Popover from 'components/Popover';
import { Placement } from 'popper.js';

interface ThreeDotMenuPopoverProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  popoverTarget: MutableRefObject<null>;
  vertical: boolean;
  placement?: Placement;
  size?: number;
}

export const ThreeDotMenuPopover = ({
  isOpen,
  onClose,
  children,
  popoverTarget,
  vertical,
  placement,
  size
}: ThreeDotMenuPopoverProps) => {
  return (
    <ProjectThreeDotMenu
      onCloseCallback={onClose}
      initialColor={theme.colors.colorSemiDarkGray1}
      vertical={vertical}
      size={size}
    >
      {isOpen && (
        <Popover
          isOpen
          target={popoverTarget}
          closePopover={onClose}
          className="styled-member-popover"
          placement={placement}
        >
          {children}
        </Popover>
      )}
    </ProjectThreeDotMenu>
  );
};
