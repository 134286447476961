import { connectRouter } from 'connected-react-router';

import access from './access';
import invite from './invite';
import billing from './billing';
import settings from './settings';
import users from './users';
import teamMembers from './teamMembers';
import groups from './groups';
import projects from './projects';
import search from './search';
import tasks from './tasks';
import tags from './tags';
import home from './home';
import teams from './teams';
import milestones from './milestones';
import taskDetail from './taskDetail';
import addMembersForm from './addMembersForm';
import dayPlanner from './dayPlanner';
import homePlanner from './homePlanner';
import workloadPlanner from './workloadPlanner';
import workloadEvents from './workloadEvents';
import workloadPlannerFilter from './workloadPlannerFilter';
import workloadPlannerBarModal from './workloadPlannerBarModal';
import projectPlannerModal from './projectPlannerModal';
import workload from './workload';
import notifications from './notifications';
import routeHistory from './routeHistory';
import recurly from './recurly';
import projectComments from './projectComments';
import projectMetadata from './projectMetadata';
import globalReducer from './global';
import phases from './phases';
import phaseTemplates from './phaseTemplates';
import milestoneTemplates from './milestoneTemplates';
import homeTaskTags from './homeTaskTags';
import timesheets from './timesheets';
import activities from './activities';
import userTheme from './userTheme';
import browser from './browser';
import userPermissions from './userPermissions';
import modules from './modules';
import filters from './filters';
import projectTaskGroups from './projectTaskGroups';
import timesheetActivityModal from './timesheetActivityModal';
import downloadFile from './downloadFile';
import taskPriorities from './taskPriorities';
import workGroups from './workGroups';
import skills from './skills';
import suggestions from './suggestions';
import clients from './clients';
import { splitReducer } from '@splitsoftware/splitio-redux';
import profit from './profit';
import userActivities from './userActivities';
import csvImport from './csvImport';
import scopes from './scopes';
import attachments from './attachments';
import dev from './dev';
import priorities from './priorities';
import triggers from './triggers';
import { statusesReducer as statuses } from './statuses';
import predictionHours from './predictions/predictionHours';
import timers from './timers';
import comments from './comments';
import entityOptions from 'EntityOptionsModule/reducers/entityOptions';
import currency from 'CurrencyModule/reducers/currency';
import { ReducerName } from 'reduxInfra/shared';
import { authReducer } from 'AuthenticationModule/reducer';
import { leanApiReducer } from 'LeanApiModule/reducers/leanApiReducer';
import { timelines } from 'TimelinesModule/reducers';
import { homeTasksReducer } from 'TaskModule/reducers/homeTasksReducer';

export const reducerMap = (history) => {
  const unsorted = {
    access,
    activities,
    addMembersForm,
    attachments,
    [ReducerName.Auth]: authReducer,
    billing,
    browser,
    clients,
    comments,
    csvImport,
    dayPlanner,
    dev,
    downloadFile,
    filters,
    globalReducer,
    groups,
    home,
    homePlanner,
    homeTaskTags,
    homeTasks: homeTasksReducer,
    invite,
    milestones,
    modules,
    notifications,
    phaseTemplates,
    milestoneTemplates,
    phases,
    predictionHours,
    profit,
    projectComments,
    projectMetadata,
    projectPlannerModal,
    projectTaskGroups,
    projects,
    recurly,
    routeHistory,
    router: connectRouter(history),
    scopes,
    search,
    settings,
    skills,
    splitio: splitReducer,
    statuses,
    suggestions,
    tags,
    taskDetail,
    taskPriorities,
    tasks,
    teamMembers,
    teams,
    timers,
    timesheetActivityModal,
    timesheets,
    triggers,
    userActivities,
    userPermissions,
    userTheme,
    users,
    workGroups,
    workload,
    workloadEvents,
    workloadPlanner,
    workloadPlannerBarModal,
    workloadPlannerFilter,
    priorities,
    entityOptions,
    currency,
    [ReducerName.LeanApi]: leanApiReducer,
    [ReducerName.Timelines]: timelines
  };

  // Keep reducer state keys alphabetical
  return Object.keys(unsorted)
    .sort()
    .reduce((acc, key) => {
      acc[key] = unsorted[key];
      return acc;
    }, {});
};
