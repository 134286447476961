import { roleTemplatesHash } from 'PermissionsModule/constants';
import { RoleTemplates, RolePermission } from 'PermissionsModule/types';
import { Entries, ValueOf } from 'type-fest';
import { includes } from 'appUtils/typeUtils';
import { TeamMember } from 'TeamsModule/models/teamMember';
import {
  ACCESS_ROLES,
  PROJECT_VISIBILITY_TYPES,
  PROJECT_INTERACTION_TYPES,
  permissionConstants
} from './constants';
import { AccessRoles, ProjectVisibilityTypes } from './types';

const { EMPLOYMENT_TYPES } = permissionConstants;

// What user sees for the role name.
const roleConversionHash: Partial<Record<RoleTemplates, string>> = {
  [roleTemplatesHash.admin]: 'Admins',
  [roleTemplatesHash.projectManager]: 'Project Managers',
  [roleTemplatesHash.workloadPlanner]: 'Work Planners',
  [roleTemplatesHash.boardManager]: 'Portfolio Managers',
  [roleTemplatesHash.teamMember]: 'All Organization Members',
  [roleTemplatesHash.financialManager]: 'Budget Managers'
};

export const buildPermittedRoles = (
  permissionAction: Record<RoleTemplates, RolePermission>
) =>
  (Object.entries(permissionAction) as Entries<typeof permissionAction>)
    .flatMap(([key, value]) => {
      // Make sure that this role is available for permission.
      if (value && !!value.display_value && value.permission_value) {
        return roleConversionHash[key];
      }
      return [];
    })
    .join(', ');

const getIsArchived = (member: TeamMember) => member.is_archived;
const getEmploymentType = (member: TeamMember) => member.employment_type;

const getIsBaseMember = (member: TeamMember) =>
  getEmploymentType(member) === EMPLOYMENT_TYPES.member;

const getIsProjectGuest = (member: TeamMember) => {
  return getEmploymentType(member) === EMPLOYMENT_TYPES.projectGuest;
};

const getIsInternalContractor = (member: TeamMember) =>
  getEmploymentType(member) === EMPLOYMENT_TYPES.internalContractor;

const getIsExternalProjectContractor = (member: TeamMember) =>
  getEmploymentType(member) === EMPLOYMENT_TYPES.externalProjectContractor;

const getIsContractor = (member: TeamMember) =>
  getIsInternalContractor(member) || getIsExternalProjectContractor(member);

const {
  ADMIN,
  TEAM_MEMBER,
  LOGIN,
  WORKLOAD_MANAGER,
  WORKLOAD_MANAGER_VIEW_ONLY,
  FINANCIAL_MANAGER,
  FINANCIAL_MANAGER_VIEW_ONLY,
  WORKLOAD_PlANNER_BASE,
  WORKLOAD_PLANNER_VIEW_ONLY_BASE,
  FINANCIAL_MANAGER_BASE,
  FINANCIAL_MANAGER_VIEW_ONLY_BASE
} = ACCESS_ROLES;

export type AccessLevelMetadata =
  | {
      projectInteractionType?: never;
      accessRoleType: typeof TEAM_MEMBER;
      projectVisibilityType: ProjectVisibilityTypes;
    }
  | {
      accessRoleType: typeof FINANCIAL_MANAGER | typeof WORKLOAD_MANAGER;
      projectInteractionType: ValueOf<typeof PROJECT_INTERACTION_TYPES>;
      projectVisibilityType: ValueOf<
        typeof PROJECT_VISIBILITY_TYPES,
        'ALL' | 'PROJECTS_MEMBER_OF'
      >;
    }
  | {
      accessRoleType: typeof ADMIN;
      projectVisibilityType: typeof PROJECT_VISIBILITY_TYPES.ALL;
      projectInteractionType: typeof PROJECT_INTERACTION_TYPES.EDIT;
    };

const BASE_MEMBER_ROLES = [TEAM_MEMBER, LOGIN];

const FINANCIAL_MANAGER_ROLES = [
  FINANCIAL_MANAGER,
  FINANCIAL_MANAGER_VIEW_ONLY,
  FINANCIAL_MANAGER_BASE,
  FINANCIAL_MANAGER_VIEW_ONLY_BASE
];

const WORKLOAD_PLANNER_ROLES = [
  WORKLOAD_MANAGER,
  WORKLOAD_MANAGER_VIEW_ONLY,
  WORKLOAD_PlANNER_BASE,
  WORKLOAD_PLANNER_VIEW_ONLY_BASE
];

const getAccessRoleType = ({ teamRole }: { teamRole: AccessRoles }) => {
  let accessRoleType: AccessLevelMetadata['accessRoleType'] = TEAM_MEMBER;

  if (teamRole === ADMIN) {
    accessRoleType = ADMIN;
  }

  if (includes(FINANCIAL_MANAGER_ROLES, teamRole)) {
    accessRoleType = FINANCIAL_MANAGER;
  }

  if (includes(WORKLOAD_PLANNER_ROLES, teamRole)) {
    accessRoleType = WORKLOAD_MANAGER;
  }

  if (includes(BASE_MEMBER_ROLES, teamRole)) {
    accessRoleType = TEAM_MEMBER;
  }

  return accessRoleType;
};

const getAccessLevelMetadata = ({
  teamRole
}: {
  teamRole: AccessRoles;
}): AccessLevelMetadata => {
  const accessRoleType = getAccessRoleType({ teamRole });

  if (accessRoleType === ADMIN) {
    return {
      accessRoleType,
      projectInteractionType: PROJECT_INTERACTION_TYPES.EDIT,
      projectVisibilityType: PROJECT_VISIBILITY_TYPES.ALL
    };
  }

  if (accessRoleType === TEAM_MEMBER) {
    const projectVisibilityType =
      teamRole === TEAM_MEMBER
        ? PROJECT_VISIBILITY_TYPES.ALL
        : PROJECT_VISIBILITY_TYPES.NO_ACCESS;

    return {
      accessRoleType,
      projectVisibilityType
    };
  }

  if (
    accessRoleType === FINANCIAL_MANAGER ||
    accessRoleType === WORKLOAD_MANAGER
  ) {
    const projectVisibilityType = includes(
      [
        WORKLOAD_MANAGER,
        WORKLOAD_MANAGER_VIEW_ONLY,
        FINANCIAL_MANAGER,
        FINANCIAL_MANAGER_VIEW_ONLY
      ],
      teamRole
    )
      ? PROJECT_VISIBILITY_TYPES.ALL
      : PROJECT_VISIBILITY_TYPES.PROJECTS_MEMBER_OF;

    const projectInteractionType = includes(
      [
        WORKLOAD_MANAGER,
        FINANCIAL_MANAGER,
        WORKLOAD_PlANNER_BASE,
        FINANCIAL_MANAGER_BASE
      ],
      teamRole
    )
      ? PROJECT_INTERACTION_TYPES.EDIT
      : PROJECT_INTERACTION_TYPES.VIEW;

    return {
      accessRoleType,
      projectVisibilityType,
      projectInteractionType
    };
  }

  // shouldn't reach here but if did, we assume lowest access level possible
  return {
    accessRoleType: TEAM_MEMBER,
    projectVisibilityType: PROJECT_VISIBILITY_TYPES.NO_ACCESS
  };
};

const getTeamMemberRole = (teamMember: TeamMember): AccessRoles | undefined =>
  teamMember?.role_ids?.[0];

export const permissionsUtils = {
  getIsArchived,
  getIsBaseMember,
  getIsProjectGuest,
  getIsInternalContractor,
  getIsExternalProjectContractor,
  getIsContractor,
  getAccessLevelMetadata,
  getEmploymentType,
  getTeamMemberRole
};
