import LRU from 'lru-cache';

// see https://github.com/isaacs/node-lru-cache for all options
const defaultCacheOptions = {
  max: 500, // cache size. can be Infinity
  maxAge: 1000 * 60 * 60 // 1 hour
};

/**
 * Creates a cache for the given selector. The cache is an LRU cache created using cacheOptions. createSelector has
 * cache options, but not for caching based on one argument. createSelectorCache allows for caching
 * the selectors by a certain key, passed in through ownProps. Currently we handle selectors for specific
 * entities by using the makeGet pattern eg. makeGetProjectOnlyHasDefaultPhase
 *
 * When to use over makeGet pattern:
 *    - heavy calculations and virtualized rows
 *        - eg. project-based rows and selector is calculating something specific to the project:
 *              we only want to perform the calculation if the row is showing, which is not achievable
 *              if we keep the selector in the parent and iterate over all projects. But keeping the
 *              selector in the row would cause the calculation to be performed every time the row is
 *              re-rendered.
 *    - when the selector is being used by many other selectors
 *        - eg. you have makeGetSomething = () => getSomething, which calculates based off ownProps.projectId. This selector
 *              is then used by both makeGetOtherThing1 and makeGetOtherThing2:
 *                     const makeGetOtherThing1 = () => createSelector(makeGetSomething(), ...)
 *                     const makeGetOtherThing2 = () => createSelector(makeGetSomething(), ...)
 *              This is creating new instances of getSomething, so if ownProps.projectId is the same, the calculation results
 *              will be the same, but will happen for each instance of getSomething.
 *    - when the selector is being used in many parts of the app
 *        - unlike makeGet and similar to createSelector, the cache lives outside of the scope of where it's being called
 *
 * the function returned by createSelectorCache
 *
 * @param {function} makeSelector function that returns the selector
 * @param {string | number} cacheKeyField
 * @param {LRU.Options<any, any>} cacheOptions
 * @returns function that looks like a regular selector but returns values from cached selectors
 */
const createSelectorCache = (
  makeSelector,
  cacheKeyField = 'cacheKey',
  cacheOptions = defaultCacheOptions
) => {
  // cache of selectors, keyed by ownProps[cacheKeyField]
  let cache = null;

  const cachedSelector = (state, ownProps) => {
    if (!cache) {
      cache = new LRU(cacheOptions);
    }
    if (cache.has(ownProps?.[cacheKeyField])) {
      return cache.get(ownProps[cacheKeyField])(state, ownProps);
    } else {
      // if ownProps or ownProps[cacheKeyField] not provided, will behave like regular createSelector with cache size 1
      cache.set(ownProps?.[cacheKeyField], makeSelector());
      return cache.get(ownProps[cacheKeyField])(state, ownProps);
    }
  };

  cachedSelector.cacheKeyField = cacheKeyField;

  /**
   * @param {string | number} cacheKey
   * @returns the cached selector for the given cacheKey, or undefined if not found
   */
  cachedSelector.getSelector = (cacheKey) => {
    if (cache) return cache.get(cacheKey);
  };

  /** Be careful using the below methods as we currently don't have a way of counting references */

  /**
   * clears the entire cache for the selector
   */
  cachedSelector.clearCache = () => !!cache && cache.reset();

  /**
   * delete the selector associated with a given key
   * @param {string | number} key the key to delete
   */
  cachedSelector.clearCacheKey = (key) => {
    cache && cache.del(key);
  };

  return cachedSelector;
};

export default createSelectorCache;
