import React from 'react';
import SvgIcon from 'components/SvgIcon';

const BoardDropdownIcon = ({ color }) => {
  return (
    <SvgIcon color={color} width="6" height="11" viewBox="0 0 6 11">
      <path
        fillRule="evenodd"
        d="M5.005 5.5L.001 10.505.496 11l5.5-5.5L.495 0 0 .495 5.005 5.5z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default BoardDropdownIcon;
