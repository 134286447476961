import { ENTITY_DESCRIPTIONS, ENTITY_TYPES } from 'SettingsModule/constants';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { SettingTableTemplateWithMemberCells } from '../../SettingTableTemplate/SettingTableTemplateWithMemberCells';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { RowData } from '../../SettingTableTemplate/types';
import styled from 'styled-components';
import { CreateUpdateEntityLocationModal } from '../../CreateUpdateEntityLocationModal';
import { getFormattedTeamMembersLocationsArray } from 'SettingsModule/selectors/locations';
import { MainWorkAddressCell } from './Cells/MainWorkAddressCell';
import {
  LocationAttributes,
  TeamMemberLocationMembership
} from 'SettingsModule/models/location';
import {
  createOrUpdateEntityLocation,
  CreateOrUpdateEntityLocationInitialParams,
  deleteEntityLocation
} from 'SettingsModule/actionCreators/settings/location';
import { getSelectedTeamId } from 'selectors';
import { makeGetOfficeByLocationId } from 'SettingsModule/selectors/offices';
import { LocationsTableRowData } from './types';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';

const getCreateOrUpdateEntityLocationRequestId = (teamMembershipId: number) =>
  `LocationsTable__createOrUpdateEntityLocationRequestId__teamMember__${teamMembershipId}`;

const getDeleteEntityLocationRequestId = (entityLocationId: number) =>
  `LocationsTable__deleteEntityLocation__${entityLocationId}`;

export const LocationsTable = () => {
  const [
    locationMembershipChangingEntity,
    setLocationMembershipChangingEntity
  ] = useState<TeamMemberLocationMembership | undefined>();
  const [isEntityLocationModalOpen, setIsEntityLocationModalOpen] =
    useState(false);

  const formattedTeamMembersLocationsArray = useAppSelector(
    getFormattedTeamMembersLocationsArray
  );

  const teamId = useAppSelector(getSelectedTeamId);

  const createOrUpdateEntityLocationRequestStatus = useRequestStatus({
    requestStatusId: locationMembershipChangingEntity
      ? getCreateOrUpdateEntityLocationRequestId(
          locationMembershipChangingEntity.account_id
        )
      : undefined
  });

  const deleteEntityLocationRequestStatus = useRequestStatus({
    requestStatusId: locationMembershipChangingEntity?.entity_location_id
      ? getDeleteEntityLocationRequestId(
          locationMembershipChangingEntity.entity_location_id
        )
      : undefined
  });

  const getOfficeByLocationId = useMemo(() => makeGetOfficeByLocationId(), []);
  const officeOfLocationMembershipBeingChanged = useAppSelector((state) =>
    locationMembershipChangingEntity?.location?.id
      ? getOfficeByLocationId(state, {
          locationId: locationMembershipChangingEntity.location.id
        })
      : undefined
  );

  const dispatch = useAppDispatch();

  const handleAddEditWorkAddress = useCallback(
    ({
      locationMembership
    }: {
      locationMembership: TeamMemberLocationMembership;
    }) => {
      setLocationMembershipChangingEntity(locationMembership);
      setIsEntityLocationModalOpen(true);
    },
    []
  );

  const rowData = useMemo(() => {
    const data: RowData<LocationsTableRowData>[] = [];

    formattedTeamMembersLocationsArray.forEach(
      ({ member, locationMembership }) => {
        data.push({
          data: {
            locationMembership,
            onAddEditWorkAddress: handleAddEditWorkAddress
          },
          teamMember: member
        });
      }
    );

    return data;
  }, [formattedTeamMembersLocationsArray, handleAddEditWorkAddress]);

  const clearRequestStatuses = useCallback(() => {
    createOrUpdateEntityLocationRequestStatus.removeStatus();

    deleteEntityLocationRequestStatus.removeStatus();
  }, [
    createOrUpdateEntityLocationRequestStatus,
    deleteEntityLocationRequestStatus
  ]);

  const handleCloseEntityLocationModal = useCallback(() => {
    setIsEntityLocationModalOpen(false);
    setLocationMembershipChangingEntity(undefined);
    clearRequestStatuses();
  }, [clearRequestStatuses]);

  useEffect(() => {
    if (
      createOrUpdateEntityLocationRequestStatus.status?.isSuccess ||
      deleteEntityLocationRequestStatus.status?.isSuccess
    ) {
      handleCloseEntityLocationModal();
    }
  }, [
    createOrUpdateEntityLocationRequestStatus.status?.isSuccess,
    deleteEntityLocationRequestStatus.status?.isSuccess,
    handleCloseEntityLocationModal
  ]);

  const handleSubmitEntityLocation = ({
    locationAttributes,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    officeId
  }: {
    locationAttributes: LocationAttributes;
    officeId: number | undefined;
  }) => {
    if (teamId && locationMembershipChangingEntity) {
      const baseParams = {
        teamId,
        location: locationAttributes,
        entityId: locationMembershipChangingEntity.team_membership_id,
        entityType: ENTITY_TYPES.teamMembership,
        description: ENTITY_DESCRIPTIONS.workAddress,
        requestStatusId: getCreateOrUpdateEntityLocationRequestId(
          locationMembershipChangingEntity.account_id
        )
      };

      const params: CreateOrUpdateEntityLocationInitialParams = officeId
        ? {
            ...baseParams,
            referenceEntityDescription: ENTITY_DESCRIPTIONS.officeLocation,
            referenceEntityId: officeId,
            referenceEntityType: ENTITY_TYPES.office
          }
        : baseParams;

      dispatch(createOrUpdateEntityLocation(params));
    }
  };

  const handleDeleteMemberWorkAddress = () => {
    if (locationMembershipChangingEntity?.entity_location_id) {
      dispatch(
        deleteEntityLocation({
          entityLocationId: locationMembershipChangingEntity.entity_location_id,
          meta: {
            entityType: ENTITY_TYPES.teamMembership,
            entityId: locationMembershipChangingEntity.team_membership_id,
            requestStatusId: getDeleteEntityLocationRequestId(
              locationMembershipChangingEntity.entity_location_id
            )
          }
        })
      );
    }
  };

  return (
    <>
      <RootContainer>
        <SettingTableTemplateWithMemberCells
          useGroup={undefined}
          columns={[
            {
              id: 'workAddress',
              label: 'Main Work Address',
              width: 400,
              rowCells: { memberRow: MainWorkAddressCell }
            }
          ]}
          rowData={rowData}
          headerRowProps={{
            blurbProps: {
              message:
                'Locations are used for proximity based project requirements. Ie. Within 25 Miles of New York, NY 10018.'
            }
          }}
          totalCount={rowData.length}
        />
      </RootContainer>
      {isEntityLocationModalOpen && (
        <CreateUpdateEntityLocationModal
          headerText={`${
            locationMembershipChangingEntity?.location ? 'Edit' : 'Add'
          } Work`}
          officeNameInputMethod={'select'}
          initialLocationAttributes={
            locationMembershipChangingEntity?.location
              ? locationMembershipChangingEntity.location
              : undefined
          }
          initialOfficeName={officeOfLocationMembershipBeingChanged?.name}
          onLocationAttributesChanged={clearRequestStatuses}
          onDelete={handleDeleteMemberWorkAddress}
          onSubmit={handleSubmitEntityLocation}
          onToggle={handleCloseEntityLocationModal}
        />
      )}
    </>
  );
};

const RootContainer = styled.div`
  height: 100%;
`;
