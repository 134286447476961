import { useAppSelector } from 'reduxInfra/hooks';
import { getAlphabeticalDisciplinesIdArray } from 'SettingsModule/selectors/disciplines';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Discipline } from 'SettingsModule/models/discipline';
import { useDisciplines } from 'SettingsModule/hooks';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';

const emptyArray = [];
const emptyObj = {};

export const useDisciplinesFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.discipline_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const { disciplineHash, handleNavToDisciplinesPage } = useDisciplines({
    isOff
  });

  const disciplineIdsArray: number[] = useAppSelector((state) =>
    isOff ? emptyArray : getAlphabeticalDisciplinesIdArray(state)
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: disciplineIdsArray,
    itemHash: disciplineHash,
    isOff,
    config,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    shouldUseDraft,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: disciplineHash,
    isLoading: isResultsLoading,
    labelKey,
    handleNavToEntityPage: handleNavToDisciplinesPage,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'name';

const searchableKeys: (keyof Discipline)[] = [labelKey];
