import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CreateFolderIcon = ({ className }) => {
  return (
    <SvgIcon
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.35355 3.64645L6.20711 3.5H6H1.83333C1.09886 3.5 0.5 4.09886 0.5 4.83333V13.1667C0.5 13.9011 1.09886 14.5 1.83333 14.5H11.8333C12.5678 14.5 13.1667 13.9011 13.1667 13.1667V6.5C13.1667 5.76552 12.5678 5.16667 11.8333 5.16667H7.87377L6.35355 3.64645Z"
        stroke="#0074D9"
      />
      <path
        d="M12.4 0.699951H11.9V1.19995V3.89995H9.19995H8.69995V4.39995V5.59995V6.09995H9.19995H11.9V8.79995V9.29995H12.4H13.6H14.1V8.79995V6.09995H16.8H17.3V5.59995V4.39995V3.89995H16.8H14.1V1.19995V0.699951H13.6H12.4Z"
        fill="#0074D9"
        stroke="white"
      />
    </SvgIcon>
  );
};

export default CreateFolderIcon;
