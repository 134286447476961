const PERMISSIONS_SETTINGS_VIEW_TYPES = {
  membership: 'members',
  access: 'orgAccess',
  projects: 'projectAccess'
} as const;

const moduleTextHash = {
  1: 'MEMBERS',
  2: 'ORG ACCESS',
  3: 'PROJECT ACCESS'
};

const moduleHash = {
  1: PERMISSIONS_SETTINGS_VIEW_TYPES.membership,
  2: PERMISSIONS_SETTINGS_VIEW_TYPES.access,
  3: PERMISSIONS_SETTINGS_VIEW_TYPES.projects
} as const;

const moduleIds = [1, 2, 3];

export const permissionsModuleConstants = {
  PERMISSIONS_SETTINGS_VIEW_TYPES,
  moduleTextHash,
  moduleHash,
  moduleIds
};
