import styled from 'styled-components';
import Textarea from 'react-autosize-textarea';
import { TaskWrapper } from 'views/Home/Tasks/styles';
import { calculateTagColorPreference } from 'appUtils/styleUtils';
import ReactContentEditable from 'react-contenteditable';
import theme from 'theme';
import { StyledTableRow } from 'components/Table/styles';
import cn from 'classnames';

export const TaskDescriptionWrapper = styled.div``;

export const TasksMetaContainer = styled.div`
  height: 100%;
  display: ${({ taskIsCreating }) => (taskIsCreating ? 'none' : 'flex')};
  align-items: center;
  position: absolute;
  right: 2px;
  top: ${(props) => (props.isOnProjectView ? '0px' : '2px')};
`;

const contentEditablePlaceholder = `
  [contentEditable='true']:empty:focus:before {
    content: attr(data-placeholder);
    color: #999;
    cursor: text;
  }
`;
/*
Translation of contentEditablePlaceholder css:

For content-editable children that are empty and focused, generate a before element
with content based on the element's 'data-placeholder' attribute
Clicking this before element does nothing, and shows a 'text' cursor as if it is not there
*/

export const TaskDescription = styled.div`
  min-height: 20px;
  display: inline-block;
  padding: 0;
  font-size: 15px;
  outline: 0;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  color: #4a4a4a;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  outline: none;
  width: 100%;

  ${(props) => props.taskIsCreating && contentEditablePlaceholder};
`;

export const TaskIconsContainer = styled.div.attrs(
  ({ $hide, $inTaskAdd, $taskIsEditing }) => ({
    className: cn({ hide: $hide, showBorder: $inTaskAdd || $taskIsEditing })
  })
)`
  align-items: center;
  align-self: center;
  background: ${({ theme }) => theme.colors.colorPureWhite};
  border-radius: 5px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
  cursor: pointer;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  padding: 4px 8px;
  top: 0px;
  line-height: 1rem;

  &:hover {
    border-color: ${({ theme }) => theme.colors.colorRoyalBlue};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  }

  &.hide {
    display: none;
  }

  &:not(:hover) {
    &.showBorder,
    ${TaskWrapper}:hover &,
    ${StyledTableRow}:hover & {
      border-color: ${({ theme }) => theme.colors.colorPaleGray4};
    }
  }

  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover & {
    visibility: visible;
  }
`;

export const TaskNoteIconWrapper = styled.span`
  height: 100%;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
`;
export const NoteIconContainer = styled.div`
  display: flex;
  align-items: center;
  visibility: ${({ hasTaskNotes, show }) =>
    hasTaskNotes || show ? 'visible' : 'hidden'};

  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover & {
    visibility: visible;
  }
  ${TaskNoteIconWrapper}:hover & {
    visibility: visible;
  }
`;

export const NoneditTaskDescriptionWrapper = styled.span`
  line-height: 1.35;
  font-size: 15px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${theme.colors.colorSemiDarkGray1};
  position: relative;
  display: inline;
  white-space: pre-wrap;
  flex: 0 0 auto;
  margin-right: 12px;
  width: 84%;
  word-break: break-word;
  .project-detail-content & {
    white-space: initial;
  }
`;
export const TaskDescriptionSpan = styled.span`
  width: 100%;
  display: flex;
`;

export const TaskCommentIconWrapper = styled.div.attrs(
  ({ $inCards, $show }) => ({
    className: cn({
      inCards: $inCards,
      showCommentIcon: $show
    })
  })
)`
  align-items: center;
  display: flex;
  gap: 3px;
  height: 100%;
  position: relative;
  visibility: hidden;

  &.inCards {
    top: 6px;
  }

  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover &,
  &.showCommentIcon {
    visibility: visible;
  }
`;

export const StyledTextarea = styled(Textarea)`
  display: inline-block;
  visibility: ${({ taskisediting, taskiscreating }) =>
    taskisediting || taskiscreating ? 'visible' : 'hidden'};
  max-height: ${({ taskisediting, taskiscreating }) =>
    taskisediting || taskiscreating ? '80px' : '0px'};
  resize: none;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.35;
  font-size: 15px;
  border: 0;
  box-sizing: border-box;
  overflow-y: hidden;
  width: 100%;
  color: #4a4a4a;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
`;

export const RemainingTagCount = styled.span`
  background-color: ${({ theme, color, isActive }) =>
    isActive && color ? color : theme.colors.colorPaleGray1};
  border: ${({ color }) => `1px solid ${color || 'transparent'}`};
  border-radius: 100px;
  padding: 1px 5px;
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledTag = styled.span`
  background-color: ${(props) =>
    props.isSelected
      ? calculateTagColorPreference(props) || props.theme.colors.colorRoyalBlue
      : props.theme.colors.colorPaleGray4};
  border: 1px solid
    ${(props) => calculateTagColorPreference(props) || 'transparent'};
  border-radius: 5px;
  padding: 0px 5px 1px 5px;
  margin-right: 2px;
  color: ${(props) =>
    props.isSelected ? props.theme.colors.colorPureWhite : '#4a4a4a'};
`;
export const StyledTaskCardIcons = styled.div`
  display: inline-flex;
  justify-content: space-around;
`;

export const ContentEditable = styled(ReactContentEditable)`
  width: 84%;
  line-height: 1.35;
  font-size: 15;
  ${({ taskiscreating }) =>
    taskiscreating
      ? `white-space: pre;
       max-height: 60px;
       width: 410px;
       overflow: auto;
       &::-webkit-scrollbar {
              display: none;
              scrollbar-width: none;
       }
   `
      : ''}
  &:focus {
    outline: none;
  }
  .project-detail-content & {
    white-space: pre-wrap;
  }
`;
