import 'isomorphic-fetch';
import { callApi } from 'appUtils/api';
import qs from 'qs';

export const fetchSpentHoursByDay = (memberBudgetId, token, phaseId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `member_budgets/${memberBudgetId}/phase_spent/${phaseId}`,
    token,
    fetchInit
  );
};
export const fetchPlannedHoursByDay = (memberBudgetId, token, phaseId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `member_budgets/${memberBudgetId}/phase_planned/${phaseId}`,
    token,
    fetchInit
  );
};

export const fetchMemberBudgets = (projectIds, token, memberBudgetIds) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    project_ids: projectIds
  };
  if (memberBudgetIds) {
    queryParams.member_budget_ids = memberBudgetIds;
  }
  return callApi(
    `member_budgets?${qs.stringify(queryParams, { arrayFormat: 'brackets' })}`,
    token,
    fetchInit
  );
};

export const fetchMemberBudgetTotals = (projectIds, token, memberBudgetIds) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    project_ids: projectIds
  };
  if (memberBudgetIds) {
    queryParams.member_budget_ids = memberBudgetIds;
  }
  return callApi(
    `/budgets/project_accounts?${qs.stringify(queryParams, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const fetchMemberBudgetPhase = (
  account_id,
  token,
  phase_id,
  project_id
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    project_ids: [project_id],
    account_ids: [account_id]
  };

  return callApi(
    `/budgets/account_projects?${qs.stringify(queryParams, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const createMemberBudget = (token, project_id, member_budgets) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      project_id,
      member_budgets
    })
  };
  return callApi(`member_budgets`, token, fetchInit);
};
export const updateMemberBudget = (
  token,
  memberBudgetId,
  teamRoleId,
  startDate,
  endDate,
  requiredHours,
  radius
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      team_role_id: teamRoleId,
      start_date: startDate,
      end_date: endDate,
      required_hours: requiredHours,
      radius
    })
  };
  return callApi(`member_budgets/${memberBudgetId}`, token, fetchInit);
};
export const assignMemberBudget = (
  token,
  memberBudgetId,
  projectMembershipId,
  accountId,
  phaseIds
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      project_membership_id: projectMembershipId,
      account_id: accountId,
      phase_ids: phaseIds
    })
  };
  return callApi(`member_budgets/${memberBudgetId}/assign`, token, fetchInit);
};
export const deleteMemberBudget = (token, memberBudgetId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`member_budgets/${memberBudgetId}`, token, fetchInit);
};

export const archiveMemberBudgetPhase = (token, memberBudgetId, phaseId) => {
  const fetchInit = {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  };
  return callApi(
    `member_budgets/${memberBudgetId}/phases/${phaseId}/archive`,
    token,
    fetchInit
  );
};
export const unarchiveMemberBudgetPhase = (token, memberBudgetId, phaseId) => {
  const fetchInit = {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  };
  return callApi(
    `member_budgets/${memberBudgetId}/phases/${phaseId}/activate`,
    token,
    fetchInit
  );
};

export const createPhaseEstimation = (
  token,
  member_budget_id,
  phase_id,
  estimated_hours,
  estimated_amount,
  estimated_percentage,
  activity_id,
  scope_id
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phase_id,
      estimated_hours,
      estimated_amount,
      estimated_percentage,
      activity_id,
      scope_id
    })
  };
  return callApi(
    `member_budgets/${member_budget_id}/activity_phase_estimations`,
    token,
    fetchInit
  );
};
export const updatePhaseEstimation = (
  token,
  id,
  member_budget_id,
  estimated_hours,
  estimated_amount,
  estimated_percentage,
  activity_id
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      estimated_hours,
      estimated_amount,
      estimated_percentage,
      activity_id
    })
  };
  return callApi(
    `member_budgets/${member_budget_id}/activity_phase_estimations/${id}`,
    token,
    fetchInit
  );
};
export const deletePhaseEstimation = (token, id, member_budget_id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `member_budgets/${member_budget_id}/activity_phase_estimations/${id}`,
    token,
    fetchInit
  );
};

export const fetchAllTeamRates = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    include_archived: true
  };

  return callApi(
    `team_rate_index/${teamId}?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const fetchMemberTeamRates = (teamMembershipId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_membership_id: teamMembershipId
  };

  return callApi(`team_rates?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const createMemberTeamRate = (
  token,
  team_membership_id,
  rate_id,
  start_date,
  end_date,
  override
) => {
  const obj = { team_membership_id, rate_id, start_date, override };
  if (end_date) obj.end_date = end_date;

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  return callApi(`team_rates`, token, fetchInit);
};

export const updateMemberTeamRate = (
  token,
  teamRateId,
  rateId,
  startDate,
  endDate,
  override
) => {
  const bodyObj = {};
  if (rateId) bodyObj.rate_id = rateId;
  if (startDate) bodyObj.start_date = startDate;
  if (endDate) bodyObj.end_date = endDate;
  if (override) bodyObj.override = override;

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-TYpe': 'application/json'
    },
    body: JSON.stringify(bodyObj)
  };
  return callApi(`team_rates/${teamRateId}`, token, fetchInit);
};

export const deleteMemberTeamRate = (token, teamRateId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  };
  return callApi(`team_rates/${teamRateId}`, token, fetchInit);
};

export const fetchRates = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`rates?${qs.stringify(queryParams)}`, token, fetchInit);
};
export const fetchTeamMembersRates = (teamId, token, includeArchived) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    include_archived: includeArchived
  };
  return callApi(
    `member_rate_index/${teamId}?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};
export const updateTeamMemberRate = (
  token,
  teamId,
  accountId,
  rateId,
  overrideExisting
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      team_id: teamId,
      rate_id: rateId,
      account_id: accountId,
      override_existing: overrideExisting
    })
  };
  return callApi(`member_rate_override`, token, fetchInit);
};
export const createRate = (
  token,
  teamId,
  description,
  rate,
  multiplierLow,
  multiplierHigh
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      team_id: teamId,
      description,
      rate,
      multiplier_low: multiplierLow,
      multiplier_high: multiplierHigh
    })
  };
  return callApi(`rates`, token, fetchInit);
};

export const updateRate = (
  token,
  id,
  description,
  rate,
  multiplierLow,
  multiplierHigh,
  mergeRateId
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      description,
      rate,
      multiplier_low: multiplierLow,
      multiplier_high: multiplierHigh,
      merge_rate_id: mergeRateId
    })
  };
  return callApi(`rates/${id}`, token, fetchInit);
};

export const archiveRate = (token, id) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`rates/${id}/archive`, token, fetchInit);
};

export const unarchiveRate = (token, id) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`rates/${id}/unarchive`, token, fetchInit);
};

// export const deleteRate = (token, projectId, id) => {
//   const fetchInit = {
//     method: 'delete',
//     credentials: 'include',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       id
//     })
//   };
//   return callApi(`project/${projectId}/phases`, token, fetchInit);
// };

export const fetchMemberRates = (memberBudgetId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    member_budget_id: memberBudgetId
  };
  return callApi(`member_rates?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const createMemberRate = (
  token,
  memberBudgetId,
  rateId,
  startDate,
  endDate
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      member_budget_id: memberBudgetId,
      rate_id: rateId,
      start_date: startDate,
      end_date: endDate
    })
  };
  return callApi(`member_rates`, token, fetchInit);
};

export const updateMemberRate = (
  token,
  memberRateId,
  rate_id,
  start_date,
  end_date
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rate_id,
      start_date,
      end_date
    })
  };
  return callApi(`member_rates/${memberRateId}`, token, fetchInit);
};
export const deleteMemberRate = (token, memberRateId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: memberRateId
    })
  };
  return callApi(`member_rates/${memberRateId}`, token, fetchInit);
};

/* -------------------------------- Salaries -------------------------------- */

export const fetchAllTeamSalaries = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`salaries?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const fetchMemberSalaries = (teamMembershipId, token, teamId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_membership_id: teamMembershipId,
    team_id: teamId
  };
  return callApi(`salaries?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const createMemberSalary = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`salaries`, token, fetchInit);
};

export const updateMemberSalary = (token, salaryId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`salaries/${salaryId}`, token, fetchInit);
};
export const deleteMemberSalary = (token, salaryId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`salaries/${salaryId}`, token, fetchInit);
};

/* -------------------------------- Overhead -------------------------------- */
export const fetchOverhead = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`overhead?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const createOverhead = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`overhead`, token, fetchInit);
};

/* ------------------------------- Team Roles ------------------------------- */

export const fetchTeamRoles = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`team_roles?${qs.stringify(queryParams)}`, token, fetchInit);
};
export const createTeamRole = (token, teamId, name) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      team_id: teamId,
      name
    })
  };
  return callApi(`team_roles`, token, fetchInit);
};

export const updateTeamRole = (token, team_role_id, name) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  };
  return callApi(`team_roles/${team_role_id}`, token, fetchInit);
};
// export const deleteTeamRole = (token, projectId, id) => {
//   const fetchInit = {
//     method: 'delete',
//     credentials: 'include',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       id
//     })
//   };
//   return callApi(`project/${projectId}/phases`, token, fetchInit);
// };

export const fetchProjectBudgetViewSettings = (projectId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `/budget_view_settings/project/${projectId}`,
    token,
    fetchInit
  );
};

export const updateProjectBudgetViewSettings = (token, projectId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `/budget_view_settings/project/${projectId}`,
    token,
    fetchInit
  );
};
export const fetchTeamBudgetViewSettings = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`/budget_view_settings/team/${teamId}`, token, fetchInit);
};

export const updateTeamBudgetViewSettings = (token, teamId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`/budget_view_settings/team/${teamId}`, token, fetchInit);
};

export const bulkAssignActivityEstimations = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `/batch_assign_activity_phase_estimation_activity`,
    token,
    fetchInit
  );
};
export const bulkAssignActivityScheduleBars = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `/batch_assign_activity_phase_schedule_bar_activity`,
    token,
    fetchInit
  );
};
export const bulkAssignDescriptions = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`/batch_assign_description_activity`, token, fetchInit);
};

export const bulkDeleteActivityScheduleBars = (token, body) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `/batch_destroy_activity_phase_schedule_bars`,
    token,
    fetchInit
  );
};

export const updateActivityPhase = (token, activityPhaseId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`activity_phases/${activityPhaseId}/`, token, fetchInit);
};

export const hardDeleteActivityPhase = (token, activityPhaseId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      force_destroy: true
    })
  };
  return callApi(`/activity_phases/${activityPhaseId}`, token, fetchInit);
};

/* --------------------------------- Billing -------------------------------- */

export const fetchProjectBillingCategories = (projectId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`projects/${projectId}/billing_categories`, token, fetchInit);
};

export const fetchProjectInvoices = (projectId, token, is_estimate) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    is_estimate
  };
  return callApi(
    `projects/${projectId}/invoices?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const createProjectInvoice = (token, body, projectId) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`projects/${projectId}/invoices`, token, fetchInit);
};

export const updateProjectInvoice = (token, invoiceId, body, projectId) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `projects/${projectId}/invoices/${invoiceId}`,
    token,
    fetchInit
  );
};

export const deleteProjectInvoice = (token, invoiceId, projectId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `projects/${projectId}/invoices/${invoiceId}`,
    token,
    fetchInit
  );
};

/* ----------------------------- Budget records ----------------------------- */
export const fetchBudgetRecords = (params, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `budget_report?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    token,
    fetchInit
  );
};

/* ----------------------------- Positions  ----------------------------- */

export const fetchPositions = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`positions?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const fetchPosition = (positionId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`positions/${positionId}`, token, fetchInit);
};

export const createPosition = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`positions`, token, fetchInit);
};

export const updatePosition = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`positions/${body.position_id}`, token, fetchInit);
};

// do not use for now
export const deletePosition = (token, positionId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`positions/${positionId}`, token, fetchInit);
};

export const archivePosition = (token, positionId, archive) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `positions/${positionId}/${archive ? 'discard' : 'undiscard'}`,
    token,
    fetchInit
  );
};

/* ----------------------------- Team positions ----------------------------- */

export const fetchTeamPositions = (
  teamId,
  token,
  positionId,
  accountId,
  isActive
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId,
    position_id: positionId,
    account_id: accountId,
    is_active: isActive
  };
  return callApi(
    `team_positions?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const createTeamPosition = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`team_positions`, token, fetchInit);
};

export const updateTeamPosition = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`team_positions/${body.team_position_id}`, token, fetchInit);
};

export const deleteTeamPosition = (token, teamPositionId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`team_positions/${teamPositionId}`, token, fetchInit);
};

/* ---------------------------- Member Positions ---------------------------- */

export const fetchMemberPositions = (params, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`member_positions?${qs.stringify(params)}`, token, fetchInit);
};

export const createMemberPosition = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`member_positions`, token, fetchInit);
};

export const updateMemberPosition = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `member_positions/${body.member_position_id}`,
    token,
    fetchInit
  );
};

export const deleteMemberPosition = (token, memberPositionId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`member_positions/${memberPositionId}`, token, fetchInit);
};

/* ---------------------------- Account Positions --------------------------- */

export const fetchAccountPositions = (params, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`account_positions?${qs.stringify(params)}`, token, fetchInit);
};

export const createAccountPosition = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`account_positions`, token, fetchInit);
};

export const updateAccountPosition = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `account_positions/${body.account_position_id}`,
    token,
    fetchInit
  );
};

export const deleteAccountPosition = (token, accountPositionId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`account_positions/${accountPositionId}`, token, fetchInit);
};

/* ----------------------------- Position Rates ----------------------------- */

export const fetchPositionRates = (
  teamId,
  token,
  positionId,
  rateId,
  isCostRate,
  activeOnly,
  includeArchived
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId,
    position_id: positionId,
    rate_id: rateId,
    is_cost_rate: isCostRate,
    is_active: activeOnly,
    include_archived: includeArchived
  };
  return callApi(
    `position_rates?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const createPositionRate = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`position_rates`, token, fetchInit);
};

export const updatePositionRate = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`position_rates/${body.position_rate_id}`, token, fetchInit);
};

export const deletePositionRate = (token, positionRateId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`position_rates/${positionRateId}`, token, fetchInit);
};

export const updatePositionSkills = (token, position_skill_id, level) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      level
    })
  };

  return callApi(`position_skills/${position_skill_id}`, token, fetchInit);
};

export const createPositionSkills = (
  token,
  team_id,
  member_budget_id,
  skills
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      team_id,
      member_budget_id,
      skills
    })
  };

  return callApi(`position_skills`, token, fetchInit);
};

export const fetchPositionSkills = (token, member_budget_id) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    member_budget_id
  };

  return callApi(
    `position_skills?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const deletePositionSkills = (token, position_skill_id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`position_skills/${position_skill_id}`, token, fetchInit);
};
