import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TimesheetIcon = ({ className, height = '28', width = '28' }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 28 28"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.603 23.8408C18.0518 24.7742 16.2661 25.2446 14.4563 25.1966C11.9956 25.0961 9.66713 24.0551 7.95131 22.2882C6.2354 20.5213 5.26307 18.1632 5.2348 15.7004C5.23648 13.5507 5.96761 11.4653 7.30865 9.78515C8.65012 8.10447 10.5224 6.92898 12.6191 6.45111L12.9031 6.38637V4.74759C12.9031 4.55392 12.9801 4.36817 13.117 4.23122C13.254 4.09427 13.4397 4.01733 13.6334 4.01733H15.8243C16.018 4.01733 16.2038 4.09427 16.3407 4.23122C16.4777 4.36817 16.5546 4.55392 16.5546 4.74759V6.38395L16.8358 6.45044C18.226 6.77919 19.5241 7.41743 20.6334 8.31761L20.8958 8.53059L22.3409 7.00742C22.3574 6.99129 22.3769 6.97844 22.3982 6.96955C22.4204 6.96028 22.4443 6.95551 22.4684 6.95551C22.4925 6.95551 22.5163 6.96028 22.5386 6.96955C22.5608 6.97881 22.581 6.99239 22.598 7.0095L23.3752 7.78672C23.4081 7.82073 23.4264 7.86619 23.4264 7.91352C23.4264 7.96084 23.4081 8.0063 23.3752 8.04031L22.1389 9.27657L22.3972 9.53482L22.1124 9.76341C23.246 11.176 23.952 12.8834 24.1469 14.6841C24.3419 16.4848 24.0176 18.3037 23.2125 19.9262C22.4074 21.5486 21.155 22.9069 19.603 23.8408ZM10.2657 22.382C11.5868 23.2647 13.14 23.7359 14.7289 23.7359C16.8595 23.7359 18.9029 22.8895 20.4094 21.3829C21.916 19.8763 22.7624 17.833 22.7624 15.7023C22.7624 14.1135 22.2913 12.5603 21.4085 11.2391C20.5258 9.91804 19.2711 8.88836 17.8032 8.28032C16.3352 7.67228 14.72 7.51319 13.1616 7.82316C11.6032 8.13314 10.1718 8.89826 9.04829 10.0218C7.92478 11.1453 7.15966 12.5767 6.84968 14.1351C6.5397 15.6934 6.69879 17.3087 7.30683 18.7767C7.91487 20.2446 8.94455 21.4993 10.2657 22.382ZM14.3126 10.5902H15.1451C15.1936 10.5902 15.24 10.6094 15.2742 10.6436C15.3084 10.6779 15.3277 10.7243 15.3277 10.7727V16.2501C15.3277 16.2985 15.3084 16.3449 15.2742 16.3791C15.24 16.4134 15.1935 16.4326 15.1451 16.4326H14.3126C14.2642 16.4326 14.2178 16.4134 14.1835 16.3791C14.1493 16.3449 14.1301 16.2985 14.1301 16.2501V10.7727C14.1301 10.7243 14.1493 10.6779 14.1835 10.6436C14.2177 10.6094 14.2642 10.5902 14.3126 10.5902Z"
      fill="#4F4F4F"
    />
  </SvgIcon>
);

export default TimesheetIcon;
