import TeamMembers from 'views/teamMembers/TeamMembers';
import TeamMemberTasks from 'views/teamMembers/TeamMemberTasks';
import TeamMemberTimesheet from 'views/teamMembers/TeamMemberTimesheet';
import TeamMembersDepartmentsTab from 'views/teamMembers/TeamMembersDepartmentsTab';
import OrgChartTab from 'views/teamMembers/OrgChartTab';

export const MembersViews = {
  members: TeamMembers,
  timesheet: TeamMemberTimesheet,
  tasks: TeamMemberTasks,
  department: TeamMembersDepartmentsTab,
  chart: OrgChartTab
};
