import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, useDispatch } from 'react-redux';
import {
  getActivePhasesByProject,
  getSelectedProject,
  getSplitFlags,
  getSuggestionsByPhase,
  getSelectedTeamId,
  getAllActivityRowInfo,
  getAreSkillsLoaded,
  getSuggestionsByPhaseMembership
} from 'selectors';
import { getMemberBudgets } from 'BudgetModule/selectors';
import { getPositions } from 'BudgetModule/selectors/positions';
import seedrandom from 'seedrandom';
import {
  fetchMemberBudgets,
  fetchPositions,
  fetchRates
} from 'BudgetModule/actionCreators';

import { fetchSkills, fetchSuggestions } from 'actionCreators';

import keyBy from 'lodash/keyBy';
import Table from 'components/Table';
import RemoveMemberModal from 'BudgetModule/components/BudgetModal/RemoveMemberModal';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import random from 'lodash/random';
import {
  reasonsHash,
  makeSuggestedMembersList,
  SuggestionTableRequestStatusesIds
} from './util';
import SkeletonLoaderTableBody from './SkeletonLoaderTableBody';

import {
  PhaseTitleCell,
  PhaseDateCell,
  PhaseHoursCell,
  PhaseDateSubRowCell,
  PhaseHoursSubRowCell,
  PhaseTitleBottomRowCell,
  PositionTitleCell,
  PositionDateCell,
  PositionHoursCell,
  PositionDateSubRowCell,
  PositionHoursSubRowCell,
  MemberTitleCell,
  MemberDateCell,
  MemberHoursCell,
  MemberAddCell,
  MemberTitleSubRowCell,
  NoMatchCell,
  CollapseCell,
  PositionClosedTitleSubRowCell,
  PhaseSubRowCell
} from './Cells';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

import { fetchDisciplines } from 'SettingsModule/actionCreators/settings/discipline';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';
import { fetchRegions } from 'SettingsModule/actionCreators/settings/region';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import useSuggestionTableLoadingState from './useSuggestionTableLoadingState';

const moment = extendMoment(Moment);

const getPhaseMembershipIds = (phases) => {
  if (!phases) return [];

  const allPhaseMembershipsIds = new Set();

  phases.forEach((phase) => {
    const currentPhaseMemberships = phase.phase_memberships;
    currentPhaseMemberships.forEach((phaseMembership) => {
      if (!phaseMembership.account_id) {
        allPhaseMembershipsIds.add(phaseMembership.id);
      }
    });
  });

  return Array.from(allPhaseMembershipsIds);
};

const TableContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  padding-right: 11px;
  background-color: ${theme.colors.colorLightGray19};
  .variable-size-list {
    overflow-x: hidden !important;
    margin-left: 42px;
  }
  .tr.phaseRow {
    padding-top: 4px;
  }
  .tr {
    background-color: white;
  }
  .tr.phaseRow,
  .tr.phaseBottomRow,
  .tr.phaseDivider.closed,
  .td.phaseSubRow.collapse,
  .td.phaseSubRow.add,
  .td.collapse {
    background: ${theme.colors.colorLightGray19} !important;
  }

  .td.positionRow {
    padding-top: 25px;

    &.date {
      position: relative;
      z-index: 0;
    }
  }

  .td.collapse {
    width: 20px !important;

    &.phaseRow {
      width: 30px !important;
    }
  }

  .tr {
    width: 97.5% !important;
  }

  .td.phaseRow {
    &.title,
    &.date,
    &.hours {
      border-bottom: 1px solid ${theme.colors.colorPaleGray3};
    }
  }

  .td.positionDivider.collapse,
  .td.positionDivider.add {
    .divider-line {
      background-color: transparent;
    }
  }

  .td.positionDivider.title,
  .td.positionDivider.date,
  .td.positionDivider.hours {
    border: 2px solid ${theme.colors.colorLightGray19};
  }

  .td.memberRow,
  .td.emptyRow,
  .td.positionRow,
  .td.positionDivider,
  .td.noMatchRow,
  .td.phaseSubRow,
  .td.positionClosedSubRow,
  .td.memberSubRow {
    &.add {
      background-color: ${theme.colors.colorLightGray19};
      width: 50px !important;
    }
  }

  .td.phaseRow.hours {
    width: 110px !important;
  }
`;

const PhaseDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.colors.colorPaleGray3};
`;

const PositionDivider = styled.div``;

const getPositionDates = (memberBudget, phase, showDemoSuggestions) => {
  const { end_date: phaseEndDate, start_date: phaseStartDate } = phase;
  const phaseHasDates = phaseStartDate && phaseEndDate;
  let demoStartDate, demoEndDate;
  if (showDemoSuggestions) {
    // DEMO
    if (phaseHasDates && moment(phaseEndDate).isAfter(moment(phaseStartDate))) {
      const range = Array.from(
        moment.range(phaseStartDate, phaseEndDate).by('day')
      );
      const startIndex = random(0, Math.floor(range.length / 3));
      const endIndex = random(0, Math.floor(range.length / 3));

      demoStartDate = range[startIndex];
      demoEndDate = range[range.length - endIndex];
      return {
        startDate: demoStartDate,
        endDate: demoEndDate
      };
    } else {
      demoStartDate = moment().format('YYYY-MM-DD');
      demoEndDate = moment().add(2, 'weeks').format('YYYY-MM-DD');
      return {
        startDate: demoStartDate,
        endDate: demoEndDate
      };
    }
  }

  return {};
};

const getMemberAvaliableDates = (positionInfo, showDemoSuggestions) => {
  const { startDate, endDate } = positionInfo;

  if (showDemoSuggestions) {
    const daysDiff = moment(endDate, 'YYYY-MM-DD').diff(
      moment(startDate, 'YYYY-MM-DD'),
      'days'
    );

    const randomDays = isNaN(daysDiff)
      ? 0
      : Math.floor(Math.random() * (daysDiff - 1 - 0));

    return {
      memberStartDate: moment(startDate, 'YYYY-MM-DD').add(randomDays, 'days'),
      memberEndDate: moment(endDate, 'YYYY-MM-DD').add(randomDays, 'days')
    };
  }

  return {};
};

// const suggestions = [
//   {
//     phase_id: 29031,
//     suggestions: [
//       {
//         account_id: 5345,
//         rate_description: 'Director',
//         skills: ['Photoshop', 'Equipment Installation', 'Managing Large Teams'],
//         suggestions: [
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-02',
//             available: 20,
//             score: 0.21125
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-16',
//             score: 0.20914
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-04-13',
//             score: 0.20773
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-07-20',
//             score: 0.20736
//           }
//         ]
//       },
//       {
//         account_id: 5323,
//         rate_description: 'Director',
//         skills: [],
//         suggestions: [
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-02',
//             available: 32,
//             score: 0.21125
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-16',
//             score: 0.20914
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-04-13',
//             score: 0.20773
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-07-20',
//             score: 0.20736
//           }
//         ]
//       },
//       {
//         account_id: 2448,
//         rate_description: 'Director',
//         skills: [],
//         suggestions: [
//           {
//             start_date: '2021-01-30',
//             end_date: '2021-02-25',
//             available: 20,
//             score: 0.24
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-16',
//             score: 0.23
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-04-13',
//             score: 0.20773
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-07-20',
//             score: 0.20736
//           }
//         ]
//       },
//       {
//         account_id: 5345,
//         rate_description: 'Manager',
//         skills: [],
//         suggestions: [
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-02',
//             available: 20,
//             score: 0.21125
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-16',
//             score: 0.20914
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-04-13',
//             score: 0.20773
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-07-20',
//             score: 0.20736
//           }
//         ]
//       },
//       {
//         account_id: 5341,
//         rate_description: 'Manager',
//         skills: [],
//         suggestions: [
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-02',
//             available: 20,
//             score: 0.21125
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-16',
//             score: 0.20914
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-04-13',
//             score: 0.20773
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-07-20',
//             score: 0.20736
//           }
//         ]
//       },
//       {
//         account_id: 2448,
//         rate_description: 'Manager',
//         skills: [],
//         suggestions: [
//           {
//             start_date: '2021-02-18',
//             end_date: '2021-02-25',
//             available: 20,
//             score: 0.21125
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-02-16',
//             score: 0.20914
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-04-13',
//             score: 0.20773
//           },
//           {
//             start_date: '2021-01-20',
//             end_date: '2021-07-20',
//             score: 0.20736
//           }
//         ]
//       }
//     ]
//   }
// ];

const emptyArray = [];
const ITEM_HEIGHT = 70;

const serializeId = (...ids) =>
  ids.reduce((acc, id) => {
    return `${acc}-${id}`;
  }, 'id');

const collapseColumn = {
  headerType: 'collapse',
  headerLabel: '',
  accessor: (row) => row,
  id: 'collapse',
  align: 'center'
};
const titleColumn = {
  headerType: 'title',
  headerLabel: 'TITLE',
  accessor: (row) => row.title,
  id: 'title',
  align: 'center'
};
const dateColumn = {
  headerType: 'date',
  headerLabel: 'DATE',
  accessor: (row) => row,
  id: 'date',
  align: 'center'
};
const hoursColumn = {
  headerType: 'hours',
  headerLabel: 'HOURS',
  id: 'hours',
  align: 'center',
  accessor: (row) => row
};
const addColumn = {
  headerType: 'add',
  headerLabel: 'ADD',
  id: 'add',
  align: 'center',
  accessor: (row) => row
};

const columnHeaders = [
  collapseColumn,
  titleColumn,
  dateColumn,
  hoursColumn,
  addColumn
];
const EmptyDiv = () => <div />;
const emptyRow = { rowType: 'emptyRow' };
const phaseDivider = <PhaseDivider className="divider-line" />;
const positionDivider = <PositionDivider className="divider-line" />;
const noop = () => {};
const emptyObj = {};
const MAX_TABLE_HEIGHT_BUFFER = 520;
const headerComponents = {
  collapse: EmptyDiv,
  title: EmptyDiv,
  date: EmptyDiv,
  hours: EmptyDiv,
  add: EmptyDiv
};

const noMatchRowCells = {
  collapse: EmptyDiv,
  title: NoMatchCell,
  date: EmptyDiv,
  hours: EmptyDiv,
  add: EmptyDiv
};

const phaseRowCells = {
  collapse: CollapseCell,
  title: PhaseTitleCell,
  date: PhaseDateCell,
  hours: PhaseHoursCell,
  add: EmptyDiv
};

const phaseSubRowCells = {
  collapse: PhaseSubRowCell,
  title: PhaseSubRowCell,
  date: PhaseSubRowCell,
  hours: PhaseSubRowCell,
  add: PhaseSubRowCell
};

const phaseBottomRowCells = {
  collapse: EmptyDiv,
  title: PhaseTitleBottomRowCell,
  date: EmptyDiv,
  hours: EmptyDiv,
  add: EmptyDiv
};

const positionRowCells = {
  collapse: EmptyDiv,
  title: PositionTitleCell,
  date: PositionDateCell,
  hours: PositionHoursCell,
  add: EmptyDiv
};

const positionSubRowCells = {
  collapse: EmptyDiv,
  title: EmptyDiv,
  date: PositionDateSubRowCell,
  hours: PositionHoursSubRowCell,
  add: EmptyDiv
};

const positionClosedSubRowCells = {
  collapse: EmptyDiv,
  title: PositionClosedTitleSubRowCell,
  date: EmptyDiv,
  hours: EmptyDiv,
  add: EmptyDiv
};

const memberRowCells = {
  collapse: EmptyDiv,
  title: MemberTitleCell,
  date: MemberDateCell,
  hours: MemberHoursCell,
  add: MemberAddCell
};

const memberSubRowCells = {
  collapse: EmptyDiv,
  title: MemberTitleSubRowCell,
  date: EmptyDiv,
  hours: EmptyDiv,
  add: EmptyDiv
};

const columnWidths = {
  collapse: 10,
  title: 305,
  date: 130,
  hours: 130,
  add: 0
};
const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );
      listItems.forEach((item) => {
        if (item.hasSubLists) {
          flatList.push(
            ...buildFlatList({
              lists: [item],
              engaged,
              search,
              customRowProps
            })
          );
        } else {
          flatList.push(buildRow({ list, item, customRowProps }));
        }
      });

      if (!isFullyLoaded) {
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }

  return flatList;
};

const buildHeader = ({ list, customRowProps }) => {
  const { id, headerType, isOpen, headerHeight, renderHeader, headerData } =
    list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    hasNoRole: list?.listItems?.length <= 1,
    renderHeader,
    customRowProps
  };
};

const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  isLastRow: item === list?.listItems?.[list?.listItems?.length - 1],
  hasNoRole: list?.listItems?.length <= 1,
  itemHeight: list.rowHeight || item.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps
});

const headers = { ...headerComponents };
const columns = columnHeaders.map((columnHeader) => ({
  ...columnHeader,
  noMatchRow: noMatchRowCells[columnHeader.headerType] || EmptyDiv,
  phaseRow: phaseRowCells[columnHeader.headerType] || EmptyDiv,
  phaseDivider: phaseDivider || EmptyDiv,
  phaseSubRow: phaseSubRowCells[columnHeader.headerType] || EmptyDiv,
  phaseBottomRow: phaseBottomRowCells[columnHeader.headerType] || EmptyDiv,
  positionRow: positionRowCells[columnHeader.headerType] || EmptyDiv,
  positionDivider: positionDivider || EmptyDiv,
  positionSubRow: positionSubRowCells[columnHeader.headerType] || EmptyDiv,
  memberRow: memberRowCells[columnHeader.headerType] || EmptyDiv,
  memberSubRow: memberSubRowCells[columnHeader.headerType] || EmptyDiv,
  positionClosedSubRow:
    positionClosedSubRowCells[columnHeader.headerType] || EmptyDiv,

  Header: headers[columnHeader.headerType] || EmptyDiv,
  Footer: EmptyDiv,
  emptyRow: EmptyDiv,
  width: columnWidths[columnHeader.headerType]
}));

const SuggestionsTable = ({ boardMembers }) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const phasesByProject = useSelector(getActivePhasesByProject);

  const showDemoSuggestions =
    useSelector(getSplitFlags)?.showAISuggestionsMemberModalDemo;
  const selectedProject = useSelector(getSelectedProject);

  const realSuggestionsByPhaseMemberships = useSelector(
    getSuggestionsByPhaseMembership
  );
  const memberBudgets = useSelector(getMemberBudgets);
  const activitiesHash = useSelector(getAllActivityRowInfo);

  const positionsHash = useSelector(getPositions);
  const skillsAreLoaded = useSelector(getAreSkillsLoaded);

  const phaseInfo = phasesByProject[selectedProject?.id] || {};
  const { phases = emptyArray, phase_orders = emptyArray } = phaseInfo;

  const phaseHash = useMemo(() => keyBy(phases, (phase) => phase.id), [phases]);
  const phaseMembershipsByMemberBudgetIds = useMemo(
    () =>
      phases.reduce((acc, phase) => {
        const phaseMemberships = phase.phase_memberships;
        phaseMemberships.forEach((phaseMembership) => {
          acc[phaseMembership.member_budget_id] = phaseMembership;
        });
        return acc;
      }, {}),
    [phases]
  );

  const orderedPhases = useMemo(
    () => phase_orders.map((id) => phaseHash[id]).filter((phase) => !!phase),
    [phaseHash, phase_orders]
  );

  const phasePositions = useMemo(() => {
    // create hash of activity phases by phase id
    const activityPhasesByPhaseHash = {};
    phases.forEach((phase) => {
      const activityPhases = [];
      const phaseActivityPhases = keyBy(
        phase.activity_phases,
        (activityPhase) => activityPhase.activity_id
      );
      phase.activity_order.forEach((activityId) => {
        activityPhases.push(phaseActivityPhases[activityId]);
      });
      activityPhasesByPhaseHash[phase.id] = activityPhases;
    });
    // create hash of generic roles by phase
    const positionsByPhase = {};
    phases.forEach((phase) => {
      // phase -> has an id, and a
      const seenMemberBudgets = new Set();
      const positions = [];
      const addMemberBudgets = (memberships, isActivityPhaseMemberships) => {
        memberships.forEach((membership) => {
          const memberBudgetId = membership.member_budget_id;
          const memberBudget = memberBudgets[memberBudgetId];
          const position = positionsHash[memberBudget?.position_id];
          if (
            membership.account_id === null &&
            position &&
            !seenMemberBudgets.has(memberBudgetId)
          ) {
            seenMemberBudgets.add(memberBudgetId);
            positions.push({
              name: position.name,
              id: position.id,
              memberBudget,
              ...(isActivityPhaseMemberships && {
                activityPhaseId: membership.activity_phase_id,
                activity: activitiesHash[membership.activity_id]
              }),
              ...(isActivityPhaseMemberships
                ? {
                    activityPhaseMembershipId: membership.id,
                    activityPhaseMembership: membership,
                    phaseMembership:
                      phaseMembershipsByMemberBudgetIds[memberBudgetId],
                    phaseMembershipId:
                      phaseMembershipsByMemberBudgetIds[memberBudgetId].id
                  }
                : {
                    phaseMembershipId: membership.id,
                    phaseMembership: membership
                  })
            });
          }
        });
      };

      // create set of phase memberships + activity phase memberships
      // since roles can be added to phase memberships in this table
      // go through activity phase memberships first to retain
      // activity phase data
      activityPhasesByPhaseHash[phase.id].forEach((activityPhase) => {
        addMemberBudgets(activityPhase.activity_phase_memberships, true);
      });
      addMemberBudgets(phase.phase_memberships);
      positionsByPhase[phase.id] = positions;
    });
    return positionsByPhase;
  }, [
    activitiesHash,
    memberBudgets,
    phaseMembershipsByMemberBudgetIds,
    phases,
    positionsHash
  ]);

  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState({});
  const [allOpen, setAllOpen] = useState(true);
  const [rowHeights, setRowHeights] = useState({
    positionRow: {}
  });
  const { isSuggestionTableLoading } = useSuggestionTableLoadingState();
  const dispatchChain = useDispatchChain();

  // useEffect for trigger initial loading
  useEffect(() => {
    if (teamId) {
      const actionsToDispatch = [
        [
          fetchRates({ teamId }),
          fetchSkills({ teamId }),
          fetchPositions({ teamId }),
          fetchDisciplines({ teamId }),
          fetchRegions({ teamId }),
          fetchOffices({ teamId }),
          fetchMemberBudgets({ projectId: selectedProject?.id })
        ]
      ];
      dispatchChain(actionsToDispatch, {
        chainId: SuggestionTableRequestStatusesIds.FetchEntities,
        initial: true,
        takeLatest: true,
        cleanupChainStatuses: true
      });
    }
    // Don't include selectedProject otherwise this useEffect will be trigged
    // everytime the table rerenders (selectedProject object gets new reference)
  }, [dispatchChain, teamId, selectedProject?.id, showDemoSuggestions]);

  useEffect(() => {
    const phaseMembershipIdsForSuggestions = getPhaseMembershipIds(phases);

    if (phaseMembershipIdsForSuggestions?.length && !showDemoSuggestions) {
      dispatch(
        fetchSuggestions({
          phase_membership_ids: phaseMembershipIdsForSuggestions,
          start_date: moment().add(-2, 'weeks').format('YYYY-MM-DD'),
          end_date: moment().add(2, 'weeks').format('YYYY-MM-DD')
        })
      );
    }
  }, [dispatch, phases, showDemoSuggestions]);

  const generateFilteredReasons = useCallback((reasons) => {
    const maxReasons = 3;
    const reasonThreshold = 0.0;
    const reasonsOrder = Object.entries(reasons)
      .filter(([key]) => reasonsHash[key])
      // Make sure it exists on reasonsHash
      // (i.e: future_engagement_workplan_amplitude was removed on FE,
      // but AI may still suggest member with future_engagement_workplan_amplitude key)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => b.value - a.value);

    /* Filter for at most <maxReason> reasons with the highest
       amplitude that satisfy the threshold.
    */
    const filteredReasons = reasonsOrder
      .slice(0, maxReasons)
      .filter((reason) => reason.value >= reasonThreshold)
      .map((reason) => {
        const id = reason.key;
        const amplitude = reason.value;
        const name = reasonsHash[id];

        return {
          id,
          amplitude,
          name
        };
      });
    return filteredReasons;
  }, []);

  const memberGroupedRowBuilder = useCallback(
    ({ position, members, phase, activityPhaseId, positionInfo }) => {
      const memberSuggestionIdOrder = members.map((member) =>
        serializeId(phase.id, position.id, member.id)
      );
      const memberLists = members.reduce((acc, cur, index) => {
        /** For real data, should use suggestion_id because id (account_id) can
         * be the same if the same account is being suggested multiple time
         * under different phases
         */
        const uniqueId = serializeId(phase.id, position.id, cur.id); // `id-{phase.id}-{position.id}-{cur.id}`;
        acc[uniqueId] = {
          listItems: [],
          closedItems: [],
          customItems: [],
          headerData: {
            position,
            member: cur,
            phase,
            activityPhaseId
          },
          getItemId: (item) => uniqueId,
          handleClick: (item) => console.log(item),
          rowType: 'memberRow',
          headerType: 'memberRow',
          headerHeight: 54,
          hasSubLists: true,
          id: uniqueId,
          skipEmptyGroups: false,
          isOpen: isOpen[uniqueId] === undefined ? false : isOpen[uniqueId],
          isEmpty: true,
          isFullyLoaded: null,
          renderHeader: () => 'member',
          summaryNoun: 'member',
          renderSummaryItem: (item) => item,
          skipHeader: false,
          positionInfo: positionInfo,
          demoMemberDates: getMemberAvaliableDates(
            positionInfo,
            showDemoSuggestions
          )
        };
        return acc;
      }, {});
      const listsWithItems = [];
      const listsWithoutItems = [];

      memberSuggestionIdOrder.forEach((id) => {
        /** id can be either suggestion_id (if real data)
         * or account_id (if demo data) */
        // Insert the total footer row
        const list = memberLists[id];
        if (list) {
          // add the built lists here
          const memberSubRows = generateFilteredReasons(
            list.headerData.member.reasons
          ).map((reason) => {
            return {
              rowType: 'memberSubRow',
              rowHeight: 35,
              reason
            };
          });
          list.listItems.push(...memberSubRows);
          if (memberSubRows.length) {
            list.listItems.push({ id: id, rowHeight: 20, rowType: 'emptyRow' });
          }
          list.isFullyLoaded = true;

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      const lastList = listsWithItems[listsWithItems.length - 1];
      if (lastList) {
        lastList.headerData.className = 'last-full-row';
      }
      const listsInOrder = [...listsWithItems, ...listsWithoutItems];
      return listsInOrder;
    },
    [generateFilteredReasons, isOpen, showDemoSuggestions]
  );

  var positionGroupedRowBuilder = useCallback(
    ({ positions, phase, positionDates }) => {
      const positionLists = positions.reduce((acc, cur, index) => {
        const positionRowHeight = rowHeights.positionRow;

        const uniqueId = cur.phaseMembershipId;
        const positionRowHeightById = positionRowHeight[uniqueId];

        acc[uniqueId] = {
          listItems: [],
          closedItems: [
            { id: index, rowHeight: 2, rowType: 'positionDivider' }
          ],
          customItems: [
            { id: index, rowHeight: 2, rowType: 'positionDivider' }
            // { id: index, rowHeight: 15, rowType: 'positionSubRow', index }
          ],
          headerData: {
            position: cur,
            phase,
            positionDates
          },
          getItemId: (item) => index,
          handleClick: (item) => console.log(item),
          rowType: 'positionRow',
          headerType: 'positionRow',
          headerHeight: positionRowHeightById ? positionRowHeightById + 65 : 80,
          hasSubLists: true,
          id: uniqueId,
          skipEmptyGroups: false,
          isOpen: isOpen[uniqueId] === undefined ? allOpen : isOpen[uniqueId],
          isEmpty: true,
          isFullyLoaded: null,
          renderHeader: () => 'position',
          summaryNoun: 'position',
          renderSummaryItem: (item) => item,
          skipHeader: false
        };
        return acc;
      }, {});

      const listsWithItems = [];
      const listsWithoutItems = [];

      Object.keys(positionLists).forEach((id, index, positionListIds) => {
        // Insert the total footer row
        const list = positionLists[id];
        if (list) {
          // add the built lists here
          const members = memberGroupedRowBuilder({
            position: list.headerData.position,
            members: list.headerData.position.members,
            phase,
            activityPhaseId: list.headerData.position.activityPhaseId,
            positionInfo: list.headerData.positionDates
          });
          if (members.length) {
            list.listItems.push({ id: id, rowHeight: 10, rowType: 'emptyRow' });
            list.listItems.push(...members);
          } else {
            list.listItems.push({
              id: id,
              rowHeight: 30,
              rowType: 'noMatchRow'
            });
          }
          const isLastGroupedPositionRow = positionListIds.length - 1 === index;

          list.listItems.push({
            id: id,
            rowHeight: isLastGroupedPositionRow ? 1 : 30,
            rowType: 'emptyRow'
          });
          list.closedItems.push({
            id: id,
            rowHeight: 30,
            rowType: 'positionClosedSubRow',
            memberCount: members.length
          });
          list.isFullyLoaded = true;

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      const lastList = listsWithItems[listsWithItems.length - 1];
      if (lastList) {
        lastList.headerData.className = 'last-full-row';
      }
      const listsInOrder = [...listsWithItems, ...listsWithoutItems];
      return listsInOrder;
    },
    [rowHeights, isOpen, allOpen, memberGroupedRowBuilder]
  );

  const phaseGroupedRowBuilder = useCallback(
    ({ phases }) => {
      const phaseOrder = phases.map((phase) => phase.id);
      const phaseLists = phases.reduce((acc, cur, index) => {
        // const phaseSuggestion = suggestions[cur.id];
        acc[cur.id] = {
          listItems: [],
          // closedItems: [{ id: cur.id, rowHeight: 30, rowType: 'phaseDivider' }],
          // customItems: phaseSuggestion
          //   ? [{ id: cur.id, rowHeight: 5, rowType: 'phaseDivider' }]
          //   : [{ id: cur.id, rowHeight: 30, rowType: 'phaseDivider' }],
          headerData: {
            phase: cur
          },
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'phaseRow',
          headerType: 'phaseRow',
          headerHeight: 42,
          id: cur.id,
          skipEmptyGroups: false,
          isOpen: isOpen[cur.id] === undefined ? allOpen : isOpen[cur.id],
          isEmpty: true,
          isFullyLoaded: null,
          renderHeader: () => 'phase',
          summaryNoun: 'phase',
          renderSummaryItem: (item) => item,
          skipHeader: false
        };
        return acc;
      }, {});

      const listsWithItems = [];
      const listsWithoutItems = [];

      phaseOrder.forEach((id) => {
        // Insert the total footer row
        const list = phaseLists[id];
        if (list) {
          // add the built lists here
          const positionArrayWithSuggestedMembers = phasePositions[id].map(
            (position, index) => {
              const positionPhaseMembershipId = position.phaseMembershipId;
              /** Make member lists */
              const suggestedMembers = makeSuggestedMembersList({
                realSuggestionsByPhaseMemberships,
                positionPhaseMembershipId,
                showDemoSuggestions,
                boardMembers,
                phase: phaseHash[id],
                position
              });

              return {
                ...position,
                members: suggestedMembers || []
              };
            }
          );

          const positions = positionGroupedRowBuilder({
            positions: positionArrayWithSuggestedMembers,
            phase: phaseHash[id],
            positionDates: getPositionDates(phaseHash[id], showDemoSuggestions)
          });
          list.listItems.push(...positions);
          list.listItems.push({
            id: id,
            rowHeight: 50,
            rowType: 'phaseBottomRow',
            positions: phasePositions[id],
            phase: list.headerData.phase
          });
          list.isFullyLoaded = true;

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      const lastList = listsWithItems[listsWithItems.length - 1];
      if (lastList) {
        lastList.headerData.className = 'last-full-row';
      }
      const listsInOrder = [...listsWithItems, ...listsWithoutItems];
      return listsInOrder;
    },
    [
      isOpen,
      allOpen,
      phasePositions,
      positionGroupedRowBuilder,
      phaseHash,
      showDemoSuggestions,
      realSuggestionsByPhaseMemberships,
      boardMembers
    ]
  );

  const listBuilders = useMemo(
    () => ({
      phases: phaseGroupedRowBuilder
    }),
    [phaseGroupedRowBuilder]
  );

  const handleResetHeightCache = useCallback(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  const lists = useMemo(() => {
    const listBuilder = listBuilders.phases;
    if (!listBuilder) {
      return [];
    }
    return listBuilder({
      phases: orderedPhases
    });
  }, [listBuilders, orderedPhases]);

  const resetOnClose = useCallback(
    ({ value, name }) => {
      if (!value) {
        const index = rows.findIndex((row) => row.listType === name);
        if (index !== -1) {
          listRef.current.scrollToItem(index - 1);
        }
      }
    },
    [rows]
  );

  const handleSetIsOpen = useCallback(
    ({ name, value }) => {
      setIsOpen({ ...isOpen, [name]: value });
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
      resetOnClose({ name, value });
    },
    [isOpen, resetOnClose]
  );

  const handleSetRowHeight = useCallback(
    ({ rowType, id, height }) => {
      const rowHeight = rowHeights[rowType];
      const hasSameHeight = rowHeight && rowHeight[id] === height;

      if (hasSameHeight) {
        return;
      }

      setRowHeights((prev) => ({
        ...prev,
        [rowType]: {
          ...prev[rowType],
          [id]: height
        }
      }));
    },
    [rowHeights]
  );

  const customRowProps = useMemo(
    () => ({
      handleSetIsOpen,
      isOpen,
      showDemoSuggestions,
      handleSetRowHeight,
      rowHeights,
      handleResetHeightCache
    }),
    [
      handleSetIsOpen,
      isOpen,
      showDemoSuggestions,
      handleSetRowHeight,
      rowHeights,
      handleResetHeightCache
    ]
  );

  var rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps,
      engaged: {},
      search: {},
      emptyRow
    });
  }, [customRowProps, lists]);

  const getItemSize = useCallback(
    (index) => rows[index]?.itemHeight || ITEM_HEIGHT,
    [rows]
  );

  useEffect(() => {
    handleResetHeightCache();
  }, [handleResetHeightCache, rows]);

  const showLoaderColumns = !!orderedPhases.length;

  return (
    <DynamicModuleLoader modules={[getBudgetModule()]}>
      {isSuggestionTableLoading ? (
        <SkeletonLoaderTableBody showLoaderColumns={showLoaderColumns} />
      ) : (
        <TableContainer>
          <Table
            columns={columns}
            data={rows || emptyArray}
            numberOfAdditionalRowsForThreshold={0}
            onDragEnd={noop}
            virtual
            getItemSize={getItemSize}
            maxHeight={650}
            totalColumnsWidthOverride={650}
            loadMoreItems={noop}
            handleScroll={noop}
            itemHeight={ITEM_HEIGHT}
            isVariableSizeTable
            customRowProps={emptyObj}
            listRef={listRef}
            showHeader
          />
          <RemoveMemberModal />
        </TableContainer>
      )}
    </DynamicModuleLoader>
  );
};

export default SuggestionsTable;
