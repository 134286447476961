import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, resetTempRef } from './utils';
import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  existingPhaseBudgetStatusOptionsByBudgetStatus: Record<string, string>;
  // { projectTitle: { projectNumber: { phaseName: phaseBudgetStatus } } }
  temporaryPhaseBudgetStatusOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>;
}

// This does not check if entered project + number + phae name is an existing one within the app, BE handles.

export const phaseBudgetStatusValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const {
    enteredPhaseBudgetStatus,
    enteredProjectTitle,
    enteredProjectNumber,
    enteredPhaseName
  } = getRowValuesForEntityType(row, entityType);
  const {
    existingPhaseBudgetStatusOptionsByBudgetStatus,
    temporaryPhaseBudgetStatusOption
  } = additionalParams;

  let res: string | boolean = true;

  if (!enteredProjectTitle || !enteredProjectNumber) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else res = 'Project number is required';
    resetTempRef(temporaryPhaseBudgetStatusOption, isLastRow);
    return res;
  }

  if (enteredPhaseBudgetStatus === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else if (!enteredPhaseBudgetStatus) {
    res = ERROR_MESSAGES.budgetStatusRequired;
  } else if (
    // entered phase budget status is not in the list of existing budget statuses => invalid option
    !existingPhaseBudgetStatusOptionsByBudgetStatus[enteredPhaseBudgetStatus]
  ) {
    res = ERROR_MESSAGES.invalidPhaseBudgetStatus;
  } else {
    const matchedPhaseBudgetStatusInTempHash =
      temporaryPhaseBudgetStatusOption.current[enteredProjectTitle]?.[
        enteredProjectNumber
      ]?.[enteredPhaseName];
    // if there is no option stored yet in the temporary hash, store the option in the temporary hash,
    if (matchedPhaseBudgetStatusInTempHash === undefined) {
      temporaryPhaseBudgetStatusOption.current = {
        ...temporaryPhaseBudgetStatusOption.current,
        [enteredProjectTitle]: {
          ...temporaryPhaseBudgetStatusOption.current[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...temporaryPhaseBudgetStatusOption.current[enteredProjectTitle]?.[
              enteredProjectNumber
            ],
            [enteredPhaseName]: enteredPhaseBudgetStatus
          }
        }
      };
    } else if (
      // there is an option stored in the temporary hash, but it does not match to what the user entered
      matchedPhaseBudgetStatusInTempHash !== enteredPhaseBudgetStatus
    ) {
      res = ERROR_MESSAGES.phaseBudgetStatusDoesNotMatch;
    }
  }
  resetTempRef(temporaryPhaseBudgetStatusOption, isLastRow);
  return res;
};
