import { getRequestStatus } from 'selectors/statuses';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { removeRequestStatusId } from 'actions/statuses';
import { useCallback } from 'react';
import { useUnmount } from 'react-use';

export const useRequestStatus = <E = unknown>({
  requestStatusId
}: {
  requestStatusId: string | undefined;
}) => {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector((state) =>
    requestStatusId ? getRequestStatus<E>(state, requestStatusId) : undefined
  );

  const removeStatus = useCallback(() => {
    if (requestStatus && requestStatusId) {
      dispatch(removeRequestStatusId(requestStatusId));
    }
  }, [dispatch, requestStatus, requestStatusId]);

  useUnmount(removeStatus);

  return { status: requestStatus, removeStatus };
};
