import React, { Component, PureComponent } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { AddTaskCard } from '..';
import { PlannerColumnBody } from './styles';
import { LANE_WIDTH } from 'appConstants/planners';
import cn from 'classnames';
import { isOnTeamTaskPlanner } from 'appUtils/views';
import PlannerColumnTaskList from './PlannerColumnTaskList';

const newTask = { id: null, description: '' };

const droppableStyleProps = { width: `${LANE_WIDTH}px` };

class PlannerColumnDroppable extends PureComponent {
  render() {
    const {
      rowHeight,
      unscheduled,
      isDropDisabled,
      date,
      accountId,
      tasks,
      navigationHistory,
      isFooter
    } = this.props;
    return (
      <Droppable
        className="task-area"
        droppableId={`droppable--${date}--${accountId}`}
        style={droppableStyleProps}
        isDropDisabled={(!unscheduled && isDropDisabled) || isFooter}
      >
        {(provided, snapshot) => (
          <PlannerColumnBody
            ref={provided.innerRef}
            className={cn('scroll-box', {
              'dragging-over': snapshot.isDraggingOver
            })}
            isOnTeamTaskPlanner={isOnTeamTaskPlanner(navigationHistory)}
            rowHeight={rowHeight}
          >
            {!isFooter && (
              <>
                <PlannerColumnTaskList
                  tasks={tasks}
                  date={date}
                  unscheduled={unscheduled}
                  accountId={accountId}
                />
                {provided.placeholder}
                <Draggable
                  draggableId={`${date} ${accountId} add task`}
                  index={tasks.length}
                  isDragDisabled
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <AddTaskCard
                        taskId={null}
                        task={newTask}
                        laneId={date}
                        accountId={accountId}
                      />
                    </div>
                  )}
                </Draggable>
              </>
            )}
          </PlannerColumnBody>
        )}
      </Droppable>
    );
  }
}

export default PlannerColumnDroppable;
