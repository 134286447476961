import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  makeGetIsThisTaskEditing,
  isSomeTaskEditing,
  getCurrentUserId,
  getCurrentFilter,
  makeGetIsBatchSelected,
  makeGetSomeIsBatchSelected,
  getTaskColumnOrderWithSelection,
  getMatchedRouteParams,
  getOnProfile
} from 'selectors';
import InlineTask from './InlineTask';

import { TaskWrapper, TaskDragHandlePanel, RemovedTask } from './styles';

import DragGripIcon from 'icons/DragGripIcon';
import * as taskRemoveTypes from 'appConstants/taskRemoveTypes';
import { toggleBatchSelectedTasks, closeSidebarProjects } from 'actionCreators';

class TaskContainer extends PureComponent {
  handleBatchActionToggleClick = () => {
    const { toggleBatchSelectedTasks, taskId, closeSidebarProjects } =
      this.props;
    closeSidebarProjects();
    toggleBatchSelectedTasks([taskId]);
  };

  renderRemovedText = () => {
    const { taskId, isOnProjectView, isOnTeamMemberProfile, taskRemovals } =
      this.props;
    const { homeDescriptions, projectDescriptions, teamMemberDescriptions } =
      taskRemoveTypes;
    const taskDestination = taskRemovals[taskId];
    if (isOnProjectView) {
      return projectDescriptions[taskDestination];
    } else if (isOnTeamMemberProfile) {
      return teamMemberDescriptions[taskDestination];
    } else {
      return homeDescriptions[taskDestination];
    }
  };

  render() {
    const {
      taskId,
      currentFilter,
      isCreatingTask,
      isThisTaskEditing,
      isSomeTaskEditing,
      alwaysRenderSchedule,
      alwaysRenderDue,
      isBatchSelected,
      someIsBatchSelected,
      isOnHomeView,
      activeTaskColumns,
      isDragging,
      isLastTask,
      rowNumber,
      taskRemovals
    } = this.props;
    if (taskRemovals[taskId]) {
      return (
        <TaskWrapper>
          <RemovedTask
            isOnHomeView={isOnHomeView}
            activeTaskColumns={activeTaskColumns}
          >
            {this.renderRemovedText()}
          </RemovedTask>
        </TaskWrapper>
      );
    }

    return (
      <TaskWrapper className="updated-task-item" isDragging={isDragging}>
        <TaskDragHandlePanel
          currentFilter={currentFilter}
          isThisTaskEditing={isThisTaskEditing}
        >
          <DragGripIcon />
        </TaskDragHandlePanel>
        <InlineTask
          taskId={taskId}
          disableOnClickOutside={!isThisTaskEditing}
          isBatchSelected={isBatchSelected}
          taskIsEditing={isThisTaskEditing}
          isSomeTaskEditing={isSomeTaskEditing}
          isCreatingTask={isCreatingTask}
          alwaysRenderSchedule={alwaysRenderSchedule}
          alwaysRenderDue={alwaysRenderDue}
          fillWhite={!isOnHomeView}
          taskBatchActionIsToggled={isBatchSelected}
          handleBatchActionToggleClick={this.handleBatchActionToggleClick}
          showBatchActionToggles={someIsBatchSelected}
          isDragging={isDragging}
          isLastTask={isLastTask}
          rowNumber={rowNumber}
        />
      </TaskWrapper>
    );
  }
}

const makeMapStateToProps = () => {
  const getIsThisTaskEditing = makeGetIsThisTaskEditing();
  const getIsBatchSelected = makeGetIsBatchSelected();
  const getSomeIsBatchSelected = makeGetSomeIsBatchSelected();
  const mapStateToProps = (state, ownProps) => ({
    isThisTaskEditing: getIsThisTaskEditing(state, ownProps),
    currentUserId: getCurrentUserId(state),
    currentFilter: getCurrentFilter(state),
    isBatchSelected: getIsBatchSelected(state, ownProps),
    someIsBatchSelected: getSomeIsBatchSelected(state, ownProps),
    activeTaskColumns: getTaskColumnOrderWithSelection(state),
    isSomeTaskEditing: isSomeTaskEditing(state),
    matchedParams: getMatchedRouteParams(state),
    isOnTeamMemberProfile: getOnProfile(state),
    taskRemovals: state.homeTasks.taskRemovals
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  toggleBatchSelectedTasks,
  closeSidebarProjects
};
const EnhancedTask = withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(TaskContainer)
);

export default EnhancedTask;
