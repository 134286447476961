import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle
} from 'components/BatchActions/styles';
import styled from 'styled-components';
import theme from 'theme';
import FilterContainer from 'components/FiltersFlyout/FilterContainer';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import FilterDropdownToggle from './FilterDropdownToggle';
import get from 'lodash/get';
import usePrevious from 'appUtils/usePrevious';

export const ListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  padding-left: 13px;

  ${StyledSelectToggleContainer} {
    margin-right: 10px;
  }
`;

const Label = styled.span`
  flex: 1;
  margin-bottom: 1px;
`;

const defaultCopy = {
  searchPlaceholder: 'Search or select below'
};

const SimpleFilterDropdown = ({
  items,
  initialSelectedItems,
  setFilterItems,
  isLoading,
  renderHeaderCopy,
  renderToggle,
  copy = defaultCopy,
  listWidth = 250,
  listHeight = 187,
  minListHeight,
  itemHeight = 30,
  batchClearOnly,
  searchEnabled = true,
  noHeader = true,
  itemHash,
  labelKey,
  filterKeys,
  toggleLabel,
  togglePluralLabel,
  allSelectedCopy,
  renderItem,
  renderLabel,
  loadMoreItems,
  loadInitialItems,
  hasNextPage,
  listItemContainerStyle,
  filterId,
  autoClick = true,
  styleWrapper
}) => {
  const [selectedItems, setSelectedItems] = useState(new Set()); // initial value will be set in useEffect below
  const [isSearchResults, setIsSearchResults] = useState(false);
  const prevFilterId = usePrevious(filterId);

  const listItems = useMemo(() => {
    return Array.from(new Set([...selectedItems, ...items]));
  }, [items, selectedItems]);

  const selectItemCallback = (item) => {
    const nextSelectedItems = new Set(selectedItems);
    const isCurrentlySelected = selectedItems.has(item);

    if (isCurrentlySelected) {
      nextSelectedItems.delete(item);
    } else {
      nextSelectedItems.add(item);
    }

    setSelectedItems(nextSelectedItems);
    setFilterItems(Array.from(nextSelectedItems));
  };

  const handleBatchSelect = ({ value }) => {
    if (value) {
      setFilterItems(items);
      setSelectedItems(new Set(items));
    } else {
      setFilterItems([]);
      setSelectedItems(new Set());
    }
  };

  const handleHideBatchOnSearch = (search) => {
    if (search && !isSearchResults) {
      setIsSearchResults(true);
    } else if (!search && isSearchResults) {
      setIsSearchResults(false);
    }
  };

  const itemFilter = (item, searchWords) => {
    const itemToUse = itemHash?.[item] || item;
    return typeof itemToUse === 'string'
      ? searchWords.every((word) =>
          item.toLowerCase().includes(word.toLowerCase().trim())
        )
      : filterItemWithWhiteSpace({
          searchWords,
          item: itemHash?.[item],
          filterKeysArray: [
            ...(labelKey ? [labelKey] : []),
            ...(filterKeys || [])
          ]
        });
  };

  useEffect(() => {
    // reset state on filter change
    if (prevFilterId !== filterId) {
      setSelectedItems(new Set(initialSelectedItems));
    }
  }, [filterId, initialSelectedItems, prevFilterId, toggleLabel]);

  /* --------------------------------- Render --------------------------------- */

  const customRenderItem = (item) => {
    const isSelected = selectedItems.has(item);
    if (renderItem) return renderItem(item, isSelected);
    const label = itemHash
      ? typeof itemHash[item] === 'string'
        ? itemHash[item]
        : get(itemHash[item], labelKey)
      : item;
    return (
      <ListItem>
        <StyledSelectToggleContainer>
          <StyledSelectToggle isChecked={isSelected} size={14} innerSize={10} />
        </StyledSelectToggleContainer>
        {renderLabel ? (
          renderLabel(item)
        ) : (
          <Label title={label} className="no-text-overflow">
            {label}
          </Label>
        )}
      </ListItem>
    );
  };

  const customRenderToggle = (props) => {
    if (renderToggle) return renderToggle(props);
    else {
      return (
        <FilterDropdownToggle
          count={selectedItems.size}
          maxCount={listItems.length}
          label={toggleLabel}
          pluralLabel={togglePluralLabel}
          allSelectedCopy={allSelectedCopy}
          {...props}
        />
      );
    }
  };

  return (
    <FilterContainer
      items={listItems}
      renderHeaderCopy={renderHeaderCopy}
      searchEnabled={searchEnabled}
      selectItemCallback={selectItemCallback}
      numSelected={selectedItems.size}
      customCopy={copy}
      renderToggle={customRenderToggle}
      listWidth={listWidth}
      listHeight={listHeight}
      minListHeight={minListHeight}
      itemHeight={itemHeight}
      customRenderItem={customRenderItem}
      isLoading={isLoading}
      batchClearOnly={batchClearOnly}
      handleBatchSelect={handleBatchSelect}
      stickyBatchSelect={!isSearchResults}
      onSearchChange={handleHideBatchOnSearch}
      closeCallback={() => setIsSearchResults(false)}
      hideFooter
      itemFilter={itemFilter}
      noHeader={noHeader}
      loadMoreItems={loadMoreItems}
      loadInitialItems={loadInitialItems}
      hasNextPage={hasNextPage}
      listItemContainerStyle={listItemContainerStyle}
      autoClick={autoClick}
      styleWrapper={styleWrapper}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamId: getSelectedTeamId(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  filterItemWithWhiteSpace
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SimpleFilterDropdown);
