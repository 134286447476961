import { makeGetPlannerPhases } from 'selectors';
import { connect } from 'react-redux';
import { PhaseRow } from 'components/ProjectsThenPhasesDropdown/Row';
import { PhaseProjectListItem } from 'components/ProjectsThenPhasesDropdown/styles';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

const listItemStyle = `
  border-bottom: none;
  position: relative;
  cursor: none;
  padding: 0;
  &:hover {
    background:none;
  }`;

const noCopy = {};

const ItemRenderer = ({ item, accountId }) => (
  <PhaseProjectListItem
    key={ItemRenderer.id}
    unselectable={'true'}
    hasHoverBackground
    itemHeight={66}
    noBorder
  >
    <PhaseRow
      item={item}
      showActivities={false}
      budgetTotalsWidth={170}
      leftOffset={-25}
      accountId={accountId}
      hideDate
      shouldShowBudgetInfo
    />
  </PhaseProjectListItem>
);
const MemberPhaseHoursDropdown = ({
  plannerPhases,
  target,
  handleClose,
  accountId,
  listWidth
}) => {
  const maxFourPhases = Math.min(plannerPhases.length, 4);
  const numPhasesToShow = // only show half phase if scrollable
    plannerPhases.length > 4 ? maxFourPhases - 0.5 : plannerPhases.length;
  return (
    <MultiStepFlyout
      renderItem={(props) => <ItemRenderer {...props} accountId={accountId} />}
      items={plannerPhases}
      isWhite
      searchEnabled={false}
      noHeader
      hideFooter
      popoverClassName="member-phase-hours-dropdown"
      editDisabled
      getItemHeight={() => 66}
      listWidth={listWidth}
      listHeight={numPhasesToShow * 66}
      idKey="id"
      copy={noCopy}
      target={target}
      handleClose={handleClose}
      listItemContainerStyle={listItemStyle}
    />
  );
};

const makeMapStateToProps = () => {
  const getPlannerPhases = makeGetPlannerPhases();
  const mapStateToProps = (state, ownProps) => ({
    plannerPhases: getPlannerPhases(state, ownProps)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(MemberPhaseHoursDropdown);
