import React from 'react';
import { TimesheetChartTooltipContainer } from '../../styles';

const TimesheetCustomTooltip = ({ active, payload, totalCapacity }) => {
  if (active) {
    const timesheetSegment = payload[0].payload; // library format
    if (!timesheetSegment) {
      return null;
    }
    const { name, value } = timesheetSegment;
    return (
      <TimesheetChartTooltipContainer className="custom-tooltip">
        {name !== 'not_entered'
          ? `${value} of ${totalCapacity} hours ${name}.`
          : `${value} hours are not entered.`}
      </TimesheetChartTooltipContainer>
    );
  }

  return null;
};

export default TimesheetCustomTooltip;
