import styled from 'styled-components';

export const HeaderCell = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  display: flex;
  font-weight: 600;
  height: 100%;
  width: 100%;
`;

export const CenteredHeaderCell = styled(HeaderCell)`
  justify-content: center;
`;
