/* eslint-disable */
const loadDocument360 = () => {
  (function (w, d, s, o, f, js, fjs) {
    w['JS-Widget'] = o;
    w[o] =
      w[o] ||
      function () {
        (w[o].q = w[o].q || []).push(arguments);
      };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.id = o;
    js.src = f;
    js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
  })(
    window,
    document,
    'script',
    'mw',
    'https://cdn.document360.io/static/js/widget.js'
  );
  mw('init', {
    apiKey:
      'ssXEelWebuh8ihcEBit4IN44xIEmI86hGHoDGfX3yblPZ89WZ2yH+GsCdcziiogX90tEk5Fpc2Iobm23OrlZb3C6OEeRlCwoddJwv0iVgT51JGtExa8/TnDqznpJZHEVtOBjmXn4VATe2ZiS6ar8yQ=='
  });
};

export const initDocument360 = () => {
  if (process.env.NODE_ENV === 'production') {
    loadDocument360();
  }
};
