import AutoReplace from 'slate-auto-replace';
import isHotkey from 'is-hotkey';
import { BLOCK_TAGS, MARK_TAGS } from './schema';

const isBoldHotkey = isHotkey('mod+b');
const isItalicHotkey = isHotkey('mod+i');
const HotkeyPlugin = () => {
  return {
    onKeyDown: (event, editor, next) => {
      if (isBoldHotkey(event)) {
        editor.toggleMark(MARK_TAGS.strong);
      } else if (isItalicHotkey(event)) {
        editor.toggleMark(MARK_TAGS.em);
      } else {
        next();
      }
    }
  };
};

const onBackspace = (event, editor, next) => {
  const { value } = editor;
  const { selection, startBlock } = value;
  const startOffset = selection.start.offset;
  const isAtStartOfListItem = selection.isCollapsed && startOffset === 0;

  if (startBlock.type === BLOCK_TAGS.p && startBlock.getText() === '') {
    // fixes case where empty p block prevents unwrapping lists
    editor
      .unwrapBlock(BLOCK_TAGS.p)
      .unwrapBlock(BLOCK_TAGS.ul)
      .unwrapBlock(BLOCK_TAGS.ol);
    return next();
  }
  if (
    selection.isExpanded ||
    startOffset > 0 ||
    startBlock.type !== BLOCK_TAGS.li
  ) {
    return next();
  } else if (isAtStartOfListItem) {
    event.preventDefault();
    editor
      .setBlocks(BLOCK_TAGS.p)
      .unwrapBlock(BLOCK_TAGS.ul)
      .unwrapBlock(BLOCK_TAGS.ol);
  } else {
    return next();
  }
};

const ListPlugin = () => {
  return {
    onKeyDown: (event, editor, next) => {
      switch (event.key) {
        case 'Backspace': {
          return onBackspace(event, editor, next);
        }
      }
      next();
    }
  };
};

const plugins = [
  AutoReplace({
    trigger: 'space',
    before: /^(-)$/,
    change: (change, e, matches) => {
      return change.setBlocks({ type: BLOCK_TAGS.li }).wrapBlock(BLOCK_TAGS.ul);
    }
  }),
  AutoReplace({
    trigger: 'space',
    before: /^(1\.){1}$/,
    change: (change, e, matches) => {
      return change.setBlocks({ type: BLOCK_TAGS.li }).wrapBlock(BLOCK_TAGS.ol);
    }
  }),
  HotkeyPlugin(),
  ListPlugin()
];

export default plugins;
