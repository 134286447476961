import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import SortOptions from 'views/projectPlanner/projectTimeline/Options/SortOptions';
import QBDownArrow from 'icons/QBDownArrow';
import LockIcon from 'icons/LockIcon';
import useCostRate from 'BudgetModule/hooks/useCostRate';
import {
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledDropdownHeader,
  StyledDropdown
} from 'views/projectPlanner/projectTimeline/Options/styles';
import { READ_BUDGET_BY_COST_RATE_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const StyledLockIcon = styled(LockIcon)`
  position: 'absolute';
  margin-left: 4px;
  margin-bottom: 1px;
`;

const StyleWrapper = styled.div`
  ${StyledDropdown} {
    ${StyledDropdownMenu} {
      position: fixed;
      z-index: 10000;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      background: white;
      padding: 10px 0;
      color: ${theme.colors.colorMediumGray9};
      min-width: 100px;
    }
    ${StyledDropdownMenuItem} {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: 13px;
      padding: 7px 18px;
      &:hover {
        cursor: pointer;
        background: ${theme.colors.colorTranslucentGray4};
      }
    }
    ${StyledDropdownHeader} {
      padding: 2px 0 5px 13px;
      font-size: 14px;
      font-weight: 600;
      pointer-events: none;
    }
  }
`;
export const Arrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  margin-left: 5px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;
export const StyledFilterOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 2px;
  font-size: 13px;
  color: ${theme.colors.colorRoyalBlue};
  height: 100%;
  cursor: pointer;
  text-transform: capitalize;
  white-space: nowrap;
  img {
    transform: scaleX(0.8);
    height: 12px;
    margin-right: 5px;
  }
  ${StyledLockIcon} {
    position: relative;
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
  ${(props) =>
    props.isTeamSetting &&
    `
    ${Arrow} {
      visibility: hidden;
    }
    &:hover {
      ${Arrow} {
        visibility: visible;
      }
    }
  `}
`;
const dropdownButtonStyle = `
height: 100%;
button {
  padding: 0;
}
`;
const selectedStyle = {
  fontWeight: 600
};

const CostRateLabel = ({ isTeamSetting }) => (
  <>
    <span data-label="Cost Rate">Cost Rate</span>
    {!isTeamSetting && (
      <StyledLockIcon
        fill={theme.colors.colorMediumGray9}
        height="12px"
        width="12px"
      />
    )}
  </>
);

/**
 * Toggle for choosing between bill/cost rate. If isTeamSetting is true, used for team default rate setting.
 * Otherwise if filter is provided, used for page filters. value is used for when controlled by state (eg. page
 * without filter)
 */
const RateTypeSelector = ({
  filter,
  onSelect,
  value,
  isTeamSetting,
  isReport
}) => {
  const {
    isViewingCostRate,
    setIsViewingCostRate,
    isUsingCostRate: isTeamUsingCostRate,
    canReadBudgetCostRate
  } = useCostRate(filter);

  const options = useMemo(() => {
    return [
      {
        label: 'Bill Rate',
        value: false
      },
      {
        label: <CostRateLabel isTeamSetting={isTeamSetting} />,
        value: true,
        tooltip: isTeamSetting
          ? ''
          : !canReadBudgetCostRate
          ? READ_BUDGET_BY_COST_RATE_TIP
          : '',
        disabled: !canReadBudgetCostRate
      }
    ];
  }, [isTeamSetting, canReadBudgetCostRate]);

  const isCostRateSelected =
    value !== undefined
      ? value
      : isTeamSetting
      ? isTeamUsingCostRate
      : isViewingCostRate;

  const renderToggle = () => (
    <StyledFilterOption
      isTeamSetting={isTeamSetting}
      className="rate-type-toggle"
    >
      {isCostRateSelected ? (
        <CostRateLabel isTeamSetting={isTeamSetting} />
      ) : (
        <span data-label="Bill Rate">Bill Rate</span>
      )}
      <Arrow className="down-arrow" />
    </StyledFilterOption>
  );

  const handleSelect = (nextIsViewingCostRate) => {
    if (onSelect) {
      onSelect(nextIsViewingCostRate);
    } else {
      // Sets activeFilter.custom.isViewingCostRate
      setIsViewingCostRate(nextIsViewingCostRate);
    }
  };

  const unhideArrowOnHover = isReport;

  return (
    <StyleWrapper>
      <SortOptions
        viewBy={isCostRateSelected}
        setViewBy={({ viewBy }) => handleSelect(viewBy)}
        options={options}
        left={0}
        dropdownButtonStyle={dropdownButtonStyle}
        noCheck
        selectedStyle={selectedStyle}
        headerText="Display"
        renderToggle={renderToggle}
        showHeader={!isTeamSetting}
        unhideArrowOnHover={unhideArrowOnHover}
      />
    </StyleWrapper>
  );
};

export default RateTypeSelector;
