import React from 'react';
import {
  ColumnPropertyCell,
  EmptyStateContainer,
  StyledStageContainer,
  StyledStageIcon,
  StyledStageText
} from './styles';
import { connect } from 'react-redux';
import { getOrderedStages, makeGetOwnStage } from 'selectors';
import OptionsSelectFlyout from 'components/OptionsSelectFlyout/OptionsSelectFlyout';
import ProjectStageListItem from './ProjectStageListItem';
import columnHeader from './ProjectColumnHeaders';

const copy = {
  addEdit: 'Add/Edit Stage',
  createNew: 'Create New Stage'
};

class ProjectStage extends React.PureComponent {
  state = {
    isDropdownOpen: false
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };

  renderSelectedItem = (item) => (
    <StyledStageContainer>
      <StyledStageIcon
        hide={!item.title}
        color={item.color}
        fillcolor={item.color}
      />
      <StyledStageText> {item.title}</StyledStageText>
    </StyledStageContainer>
  );

  render() {
    const {
      projectId,
      stages,
      stage = {},
      onSubmit,
      outerPopoverTarget
    } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <ColumnPropertyCell onClick={this.toggleDropdown}>
        <OptionsSelectFlyout
          selectedItem={stage}
          outerPopoverTarget={outerPopoverTarget}
          listItems={stages}
          rowId={projectId}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={this.toggleDropdown}
          onSubmit={onSubmit}
          ListItemComponent={ProjectStageListItem}
          copy={copy}
          droppableType="stageOrder"
          renderSelectedItem={this.renderSelectedItem}
        />
        <EmptyStateContainer show={isDropdownOpen} isBlue={isDropdownOpen}>
          {!stage.title && columnHeader.stage}
        </EmptyStateContainer>
      </ColumnPropertyCell>
    );
  }
}

const makeMapStateToProps = () => {
  const getOwnStage = makeGetOwnStage();
  const mapStateToProps = (state, ownProps) => ({
    stage: getOwnStage(state, ownProps),
    stages: getOrderedStages(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ProjectStage);
