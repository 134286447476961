import { useRef } from 'react';
import { TimeDropdownContainer, TimeContainer } from './styles';
import CalendarTimePicker from 'components/CalendarTimePicker';
import styled from 'styled-components';
import theme from 'theme';

const CalendarSetTime = ({
  startTime,
  endTime,
  startTimeSelect,
  endTimeSelect,
  dailyHours
}) => {
  const timePopoverTarget = useRef(null);

  return (
    <TimeDropdownContainer ref={timePopoverTarget}>
      <TimeContainer>
        <StyledCalendarTimePicker
          initialTime={startTime}
          className="calendar-time-picker"
          handleChange={(value) => startTimeSelect(value)}
          placeholder="Start Time"
          width={75}
        />
      </TimeContainer>
      <TimeContainer>
        <CalendarTimePicker
          initialTime={endTime}
          className="calendar-time-picker"
          handleChange={(value) => endTimeSelect(value)}
          placeholder={
            startTime && dailyHours
              ? startTime.clone().add(dailyHours, 'hours').format('h:mma')
              : 'End Time'
          }
          width={75}
        />
      </TimeContainer>
    </TimeDropdownContainer>
  );
};

const StyledCalendarTimePicker = styled(CalendarTimePicker)`
  background: ${theme.colors.colorTranslucentGray3};
`;

export default CalendarSetTime;
