import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportPtoPolicy
} from 'CsvImportsModule/types';
import uniq from 'lodash/uniq';
import { getRowValuesForEntityType } from './utils';

interface AdditionalParams {
  existingPtoPoliciesGroupedByHours: Record<string, CsvImportPtoPolicy[]>;
  existingPtoPoliciesByDescription: Record<string, CsvImportPtoPolicy>;
  newPtoHours: React.MutableRefObject<Record<string, boolean>>;
  newPtoDescriptionHoursHashes: React.MutableRefObject<Record<string, string>>;
}

export const ptoHoursOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingPtoPoliciesGroupedByHours,
    existingPtoPoliciesByDescription,
    newPtoHours,
    newPtoDescriptionHoursHashes
  } = additionalParams;

  const { enteredPtoDescription } = getRowValuesForEntityType(row, entityType);

  const existingPtoPolicyMatchedByEnteredDescription =
    existingPtoPoliciesByDescription[enteredPtoDescription];
  const newPtoHoursMatchedByEnteredDescription =
    newPtoDescriptionHoursHashes.current[enteredPtoDescription];

  // no description entered or there is no match by entered description, then grab all pto hours options
  if (
    !enteredPtoDescription ||
    enteredPtoDescription === TRAINING_DATA_REQUIRED_STRING ||
    (existingPtoPolicyMatchedByEnteredDescription === undefined &&
      newPtoHoursMatchedByEnteredDescription === undefined)
  ) {
    return uniq([
      ...Object.keys(newPtoHours.current),
      ...Object.values(newPtoDescriptionHoursHashes.current),
      ...Object.keys(existingPtoPoliciesGroupedByHours)
    ]);
  } else {
    // the entered pto description only has matches in the new ref hash
    if (
      existingPtoPolicyMatchedByEnteredDescription === undefined &&
      newPtoHoursMatchedByEnteredDescription !== undefined
    )
      return [newPtoHoursMatchedByEnteredDescription];
    else if (existingPtoPolicyMatchedByEnteredDescription) {
      return [existingPtoPolicyMatchedByEnteredDescription.hours];
    }
    // there will not be a case where both existingPtoPolicyMatchedByEnteredDescription and newPtoHoursMatchedByEnteredDescription
    // is not undefined because pto desscription is unique and only exists in one of them if it exists.
  }
  return [];
};
