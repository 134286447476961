import { useCallback } from 'react';
import { zoomToTopIntervalHash } from 'appUtils/projectPlannerUtils';
import { DateHeader, IntervalRenderer } from 'react-calendar-timeline';
import { BaseDateHeaderProps } from './types';

export const GhostDateHeader = ({ zoom }: BaseDateHeaderProps) => {
  const emptyIntervalRenderer = useCallback(
    (props: IntervalRenderer<never>) => {
      if (!props) return null;
      const { getIntervalProps } = props;
      return <div {...getIntervalProps()} />;
    },
    []
  );

  return (
    <DateHeader
      height={1}
      unit={zoomToTopIntervalHash[zoom]}
      intervalRenderer={emptyIntervalRenderer}
    />
  );
};
