import styled from 'styled-components';
import inviteMailIcon from 'images/invite-mail-icon.svg';
import colorSetIcon from 'icons/color-set-icon.svg';
import TaskMoveThreeDotIcon from 'icons/TaskMoveThreeDotIcon';
import {
  StyledColorPicker,
  ColorPickerHoverArea
} from 'components/ColorPicker/styles';
import { calculateColorPref } from 'appUtils/styleUtils';

export const StyledTeamMembersPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
  overflow: visible;
  padding-right: 40px;
  margin-right: -40px;
  background-color: #f3f3f3;
`;

export const MemberCardsSectionWrapper = styled.div`
  overflow-y: auto;
  margin-top: 20px;
  padding-bottom: 40px;
  padding-left: 16px;

  .add-dept-button-container {
    margin-bottom: 15px;
    margin-left: 10px;
  }
`;

export const MemberCardsSection = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const SectionHeader = styled.div`
  margin: 10px 0;
  color: ${(props) => props.theme.colors.colorMediumGray5};
  font-size: 16px;
  font-weight: bold;
  display: inline-flex;
`;

export const StyledAddMember = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
`;
export const StyledThreeDotIcon = styled(TaskMoveThreeDotIcon)`
  position: absolute;
  right: 10px;
  top: 10px;

  path {
    fill: ${(props) => props.theme.colors.colorMediumGray1};
  }
`;

export const ColorPickerContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const PendingText = styled.div`
  font-size: 11px;
  position: absolute;
  top: 7px;
  left: 11.5px;
  color: ${(props) => props.theme.colors.colorMediumGray4};
  transition: 0.2s left step-end;
`;

// ColorPickerHoverArea is a div that triggers hover rules on dot - this
// extension should be removed in favor of styled.div`` when ThreeDotMenu
// is implemented.
export const StyledTeamMemberCard = styled(ColorPickerHoverArea)`
  position: relative;
  height: 200px;
  width: 200px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.colorPaleGray5};
  box-shadow: 0px 1px 4px rgba(204, 204, 204, 0.8);
  background-color: ${(props) =>
    props.menuOpen ? '#b3b3b3' : props.theme.colors.colorPureWhite};
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};

  &.pending {
    opacity: 0.7;
  }

  &.me .member-initials {
    background-color: ${(props) => props.theme.colors.colorMediumGray8};
    color: ${(props) => props.theme.colors.colorPureWhite};
  }

  &.invite-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .member-card-tooltip {
    width: 230px;
    z-index: 1002;
  }

  &:hover {
    box-shadow: 0px 1px 8px ${(props) => props.theme.colors.colorMediumGray1};
    ${PendingText} {
      left: 25px;
      transition: 0.2s left step-start;
    }
  }
`;

export const MemberName = styled.div`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.colorSemiDarkGray1};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 45px;
  padding: 0 10px;
  word-wrap: break-word;
`;

export const MemberInitials = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: ${calculateColorPref} 2px solid;
  background-color: ${(props) =>
    props.me
      ? props.theme.colors.colorMediumGray8
      : props.theme.colors.colorLightGray4};
  background: ${calculateColorPref};
  color: ${(props) => props.theme.colors.colorPureWhite};
  font-size: 24px;
  font-weight: 400;
  position: relative;
  font-family: 'proxima-nova';
`;

export const MemberTasks = styled.div`
  display: flex;

  .task-counter {
    flex: 1;

    &.overdue .count:not(.is-zero) {
      color: ${(props) => props.theme.colors.colorCrimsonRed};
    }

    .count {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      color: ${(props) => props.theme.colors.colorMediumGray5};

      &.is-zero {
        opacity: 0.5;
      }
    }

    .task-description {
      font-size: 11px;
      opacity: 0.7;
      text-align: center;
      margin: auto;
      width: 70%;
    }
  }
`;

export const InviteIconContainer = styled.div`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.colorTranslucentGray2};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px rgba(204, 204, 204, 0.8);
`;

export const InviteText = styled.div`
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
`;

export const InviteMailIcon = styled.div`
  height: 42px;
  width: 50px;
  background: ${`url(${inviteMailIcon}) no-repeat`};
  background-size: contain;
  margin-top: 16px;
  margin-left: 5px;
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 40px;
  width: 150px;
  display: flex;
  background-color: #fff;
  z-index: 1;
`;

export const ListItem = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  height: 26px;

  :hover {
    background-color: #e7e7e7;
  }

  ${StyledColorPicker} {
    flex: auto;
    justify-content: flex-start;
  }
`;

export const ColorPallete = styled.div`
  background: ${`url(${colorSetIcon}) no-repeat`};
  height: 18px;
  width: 18px;
  padding-right: 26px;
`;

export const FlexItem = styled.div`
  display: flex;
  align-items: center;
`;
