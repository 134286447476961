import {
  getDatesExcludeDaysOff,
  getWeekendsArrayFromRange,
  getWorkdayPercent,
  getWorkdayHours,
  getWorkDaysFromScheduleBars
} from '../../utils';
import isEqual from 'lodash/isEqual';
import { usePrevious } from 'appUtils/hooks/usePrevious';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { predictWorkloadPlanner } from 'actionCreators';
import { WorkPlan } from '../models/workPlan';
import {
  PredictStatus,
  PredictWorkloadPlannerParams,
  WorkPlanState
} from '../types';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import round from 'lodash/round';
import { defaultDebounceDelay } from '../constants';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getAccountCapacities,
  getTeamCapacity
} from 'CapacityModule/selectors';
import moment from 'moment';
import { DailyCapacity } from 'models/accountCapacity';
import { TeamCapacity } from 'CapacityModule/models';
import { mapDependenciesToDependencyInfo } from '../utils/workPlanDependencyUtil';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import {
  clearOldDependencies,
  hasStartDateDependency,
  hasEndDateDependency
} from 'appUtils/newDependencies';
import omit from 'lodash/omit';

const modifyTargetTolastModifiedMap: Record<string, readonly string[]> = {
  start_date: ['start_date'],
  end_date: ['end_date'],
  date_range: ['start_date', 'end_date'],
  daily_hours: ['daily_hours'],
  workday_percent: ['daily_hours'],
  total_hours: ['total_hours']
} as const;

interface ExtraValues {
  work_days?: number;
  workday_percent?: string; // it should be string to support decimal point while entering value i.e 3.
  datesArray: Array<string>;
}

const calculateNonSuggestionWorkdays = ({
  workplan
}: {
  workplan: Pick<WorkPlan, 'bars'>;
}) => {
  return workplan?.bars ? getWorkDaysFromScheduleBars(workplan.bars) : 1;
};

const calculateSuggestionWorkdays = ({
  workplan
}: {
  workplan: WorkPlanStateOrSuggestedBar;
}) => {
  return workplan?.schedule?.length || 1;
};

const calculateWorkdays = ({
  workplan
}: {
  workplan: WorkPlanStateOrSuggestedBar;
}) => {
  return workplan?.isWorkplanSuggestion
    ? calculateSuggestionWorkdays({ workplan })
    : calculateNonSuggestionWorkdays({ workplan });
};

// constants to refine only predictable values
const predictableValues: Partial<Record<keyof WorkPlan, true>> = {
  start_date: true,
  end_date: true,
  bars: true,
  daily_hours: true,
  total_hours: true,
  all_day: true
};

export type ModifiableTarget =
  | keyof Omit<WorkPlan, 'start_date' | 'end_date' | 'description'>
  | 'date_range'
  | keyof ExtraValues
  | 'reset';

export type UpdateHandler = (
  modifyTarget: ModifiableTarget,
  values: Partial<WorkPlanState> & Partial<ExtraValues>
) => void;

interface UsePredictReturned {
  predictedWorkplan: WorkPlanState;
  extraValues: ExtraValues;
  update: UpdateHandler;
  isPredicting: boolean;
}

interface UsePredictHookOptions {
  debounceDelay?: number;
  shouldPredictWithInitialValue?: boolean;
}
interface WorkPlanStateOrSuggestedBar extends WorkPlanState {
  // these are params from suggestedBar which is used by workplan request
  isWorkplanRequest: boolean;
  workplanRequestId: number;
  work_days?: number;
  schedule?: { date: string; hours: number }[];
  isWorkplanSuggestion?: boolean;
  reject: boolean;
}

type UsePredictHook = (
  initialBar: WorkPlanStateOrSuggestedBar,
  options?: UsePredictHookOptions
) => UsePredictReturned;

/**
 * usePredict
 *
 * take bar and form values, and return predicted values
 */
export const usePredict: UsePredictHook = (initialWorkplan, options = {}) => {
  const {
    debounceDelay = defaultDebounceDelay,
    shouldPredictWithInitialValue = false
  } = options;

  const previousWorkplan = usePrevious(initialWorkplan);

  const [workplan, setWorkplan] = useState<WorkPlanStateOrSuggestedBar>({
    ...initialWorkplan,
    dependency_infos: initialWorkplan.dependencies
      ? mapDependenciesToDependencyInfo(initialWorkplan.dependencies)
      : []
  });
  const [predictStatus, setPredictStatus] = useState<PredictStatus>('initial');

  const defaultDailyTeamCapacity: TeamCapacity =
    useAppSelector(getTeamCapacity);
  const flatPhasesHash = useAppSelector(getFlatPhasesHash);

  const lastModifiedTargetRef = useRef<{
    target: ModifiableTarget | undefined;
    timestamp: number | undefined;
  }>({
    target: undefined,
    timestamp: undefined
  });

  const setLastModifiedTarget = (targetKey: ModifiableTarget | undefined) => {
    if (lastModifiedTargetRef) {
      lastModifiedTargetRef.current = {
        target: targetKey,
        timestamp: new Date().valueOf()
      };
    }
  };

  const [workDays, setWorkdays] = useState<number | undefined>(
    calculateWorkdays({ workplan })
  );

  const [workdayPercent, setWorkdayPercent] = useState<string | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  const accountId = workplan.account_id;
  const accountCapacitiesHash = useAppSelector(getAccountCapacities);
  const accountCapacity = accountCapacitiesHash[accountId];
  const accountCapacityToUse: DailyCapacity | undefined = accountId
    ? accountCapacity
    : workplan.member_budget_id
    ? defaultDailyTeamCapacity
    : undefined;

  const getDatesArray = useCallback(
    ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      if (startDate && endDate) {
        return getDatesExcludeDaysOff({
          startDate,
          endDate,
          daysOff: workplan.include_weekends
            ? []
            : getWeekendsArrayFromRange({
                startDate,
                endDate
              })
        });
      }
      return [];
    },
    [workplan.include_weekends]
  );

  const datesArray = useMemo(
    () =>
      getDatesArray({
        startDate: workplan.start_date,
        endDate: workplan.end_date
      }),
    [getDatesArray, workplan.start_date, workplan.end_date]
  );

  /* ------------------ update workplan state before predict ------------------ */
  const handleUpdate: UpdateHandler = (modifyTarget, values) => {
    // changing lock would not trigger to predict hours
    if (modifyTarget === 'lock_hour') {
      return setWorkplan((prev) => ({
        ...prev,
        lock_hour: Boolean(values.lock_hour)
      }));
    }

    // Omit these values from predict params but use them to update stateBar except ...rest
    const {
      workday_percent: workdayPercent,
      work_days: modifiedWorkDays,
      daily_hours: modifiedDailyHours,
      total_hours: modifiedTotalHours,
      dependency_infos: modifiedDependencyInfos,
      start_date: modifiedStartDate,
      end_date: modifiedEndDate,
      ...rest
    } = values;

    // Build complex values.
    const workdayPercentNumber = isNaN(Number(workdayPercent))
      ? 0
      : Number(workdayPercent);
    let dailyHours =
      'daily_hours' in values
        ? !Number(modifiedDailyHours)
          ? undefined
          : modifiedDailyHours
        : workplan.daily_hours !== ''
        ? workplan.daily_hours
        : undefined;
    let totalHours =
      'total_hours' in values
        ? !Number(modifiedTotalHours)
          ? undefined
          : modifiedTotalHours
        : workplan.total_hours !== ''
        ? workplan.total_hours
        : undefined;
    let dependencyInfoToUse =
      modifiedDependencyInfos ?? workplan.dependency_infos ?? [];
    let startDateToUse = modifiedStartDate ?? workplan.start_date;
    let endDateToUse = modifiedEndDate ?? workplan.end_date;

    // Set state values that cannot be `undefined` in `WorkPlan`.
    if ('work_days' in values)
      setWorkdays(!modifiedWorkDays ? undefined : modifiedWorkDays);
    if ('workday_percent' in values)
      setWorkdayPercent(!workdayPercentNumber ? undefined : workdayPercent);

    // Daily hours and workday percent are related. Convert between them
    // according to the values that have changed.
    const nextAccountCapacityToUse = values.account_id
      ? accountCapacitiesHash[values.account_id]
      : values.member_budget_id
      ? defaultDailyTeamCapacity
      : accountCapacityToUse;
    if (nextAccountCapacityToUse) {
      const datesArray = getDatesArray({
        startDate: values.start_date ?? workplan.start_date,
        endDate: values.end_date ?? workplan.end_date
      });

      // Calculate daily hours based if work day percent is changing.
      if (modifyTarget === 'workday_percent') {
        if (!workdayPercentNumber) {
          dailyHours = undefined;
        } else {
          const newDailyHours = getWorkdayHours({
            dailyCapacity: nextAccountCapacityToUse,
            workdayPercent: workdayPercentNumber,
            datesArray
          });

          dailyHours = newDailyHours ? String(newDailyHours) : undefined;
        }
      }
      // Calculate work day percent if related values change.
      else if (
        modifyTarget === 'reset' ||
        modifyTarget === 'date_range' ||
        modifyTarget === 'account_id' ||
        modifyTarget === 'member_budget_id' ||
        modifyTarget === 'daily_hours'
      ) {
        if (!dailyHours) {
          setWorkdayPercent(undefined);
        } else {
          const newWorkdayPercent = getWorkdayPercent({
            dailyCapacity: nextAccountCapacityToUse,
            dailyHours: Number(dailyHours ?? '0'),
            datesArray
          });

          setWorkdayPercent(
            newWorkdayPercent ? String(newWorkdayPercent) : undefined
          );
        }
      }
    }

    const newPhaseId = values.phase_id;
    let overrideDateModifyTarget:
      | keyof typeof modifyTargetTolastModifiedMap
      | undefined;
    if (
      (modifyTarget === 'project_id' ||
        modifyTarget === 'phase_id' ||
        modifyTarget === 'activity_phase_id') &&
      newPhaseId &&
      newPhaseId !== workplan.phase_id
    ) {
      const phase = flatPhasesHash[newPhaseId];

      // if depedency already exists and phase is changed
      // remove old dependencies and add new dependencies
      dependencyInfoToUse = dependencyInfoToUse.map((dependencyInfo) =>
        dependencyInfo.dependency_type !== 'none'
          ? {
              ...dependencyInfo,
              dependable_id: newPhaseId
            }
          : dependencyInfo
      );

      // remove old dependencies
      if (workplan.dependencies?.length) {
        dependencyInfoToUse.unshift(
          ...clearOldDependencies(workplan.dependencies)
        );
      }

      const hasStartDependency = hasStartDateDependency(dependencyInfoToUse);
      const hasEndDependency = hasEndDateDependency(dependencyInfoToUse);

      if (phase?.start_date && phase?.end_date) {
        // if both start and end date dependency exists,
        // snap to the start date of phase to avoid prediction conflict
        if (hasStartDependency && hasEndDependency) {
          startDateToUse = phase.start_date;
          endDateToUse = phase.end_date;
          overrideDateModifyTarget = 'date_range';

          // if only start date dependency exists, snap to the start date
          // and use existing workday to get correct date range from prediction api
        } else if (hasStartDependency) {
          startDateToUse = phase.start_date;
          endDateToUse = phase.start_date;
          overrideDateModifyTarget = 'start_date';

          // if only end date dependency exists, snap to the end date
          // and use existing workday to get correct date range from prediction api
        } else if (hasEndDependency) {
          startDateToUse = phase.end_date;
          endDateToUse = phase.end_date;
          overrideDateModifyTarget = 'end_date';
        }
        // if phase doesn't have start or end date, remove the existing dependency configs
      } else {
        dependencyInfoToUse = dependencyInfoToUse.filter(
          (dependencyInfo) => dependencyInfo.dependency_type === 'none'
        );
      }
    }

    // Clear total hours if daily hours are locked and cleared.
    if (
      (modifyTarget === 'daily_hours' || modifyTarget === 'workday_percent') &&
      !workplan.lock_hour &&
      !dailyHours
    ) {
      totalHours = undefined;
    }

    // Clear daily hours if total hours are locked and cleared.
    if (modifyTarget === 'total_hours' && workplan.lock_hour && !totalHours) {
      dailyHours = undefined;
      setWorkdayPercent(undefined);
    }

    // Update the work plan state.
    const newWorkplan = {
      ...workplan,
      ...rest,
      total_hours: totalHours ?? '',
      work_days: modifiedWorkDays,
      daily_hours: dailyHours ?? '',
      dependency_infos: dependencyInfoToUse,
      start_date: startDateToUse,
      end_date: endDateToUse
    };
    setWorkplan(newWorkplan);

    // Set the last modified target for use the by prediction endpoint callback.
    setLastModifiedTarget(modifyTarget);

    // Determine the last modified targets for the prediction endpoint. Some
    // targets do not need to be reported, so this value may be `undefined`.
    const lastModifiedTargets = modifyTargetTolastModifiedMap[
      // Dates may be set multiple ways, so we provide special handling for
      // them.
      overrideDateModifyTarget ?? modifyTarget
    ]?.reduce((acc, target) => {
      acc[target] = true;
      return acc;
    }, {});

    const shouldOmitDailyHours =
      newWorkplan.lock_hour && modifyTarget === 'reset';
    const shouldDropTotalHours =
      !newWorkplan.lock_hour && modifyTarget === 'reset';
    const shouldUseExistingWorkDay = modifyTarget === 'include_weekends';

    const refinedValuesToPredict: PredictWorkloadPlannerParams = {
      ...(lastModifiedTargets ? { last_modified: lastModifiedTargets } : {}),
      account_id: newWorkplan.account_id,
      project_id: newWorkplan.project_id,
      phase_id: newWorkplan.phase_id,
      start_date: newWorkplan.start_date,
      end_date: newWorkplan.end_date,
      // if all_day is true, predicted total_hours always 0
      all_day: newWorkplan.all_day ?? false,
      daily_hours: shouldOmitDailyHours ? undefined : dailyHours,
      total_hours: shouldDropTotalHours ? undefined : totalHours,
      include_weekends: newWorkplan.include_weekends,
      include_holidays: newWorkplan.include_holidays,
      // false: lock daily hours, true: lock total hours
      lock_hour: newWorkplan.lock_hour,
      work_days: shouldUseExistingWorkDay
        ? workDays?.toString()
        : modifiedWorkDays?.toString(),
      dependency_infos: newWorkplan.dependency_infos?.filter(
        ({ dependency_type }) => dependency_type !== 'none'
      ),
      type: newWorkplan.type,
      ...rest
    };

    const valuesToPredict = refinedValuesToPredict.all_day
      ? {
          start_date: refinedValuesToPredict.start_date,
          end_date: refinedValuesToPredict.end_date
        }
      : {
          start_date: refinedValuesToPredict.start_date,
          end_date: refinedValuesToPredict.end_date,
          daily_hours: refinedValuesToPredict.daily_hours,
          total_hours: refinedValuesToPredict.total_hours
        };
    const haveEnoughValuesToPredict = validateToPredict(
      valuesToPredict,
      modifyTarget
    );
    const canPredict = haveEnoughValuesToPredict;

    if (canPredict) {
      setPredictStatus('willPredict');
      handleDebouncedPredict({
        values: refinedValuesToPredict,
        lastModifiedTimestamp: lastModifiedTargetRef.current.timestamp,
        onPredict: updatePredictedValues
      });
    }
  };

  /* ------------------- update workplan state after predict ------------------ */
  const updateSetTime = useCallback(
    (dailyHours: string) => {
      const needToUpdateStartandEndTime =
        workplan.start_time &&
        workplan.end_time &&
        dailyHours !== workplan.daily_hours;
      const formattedDailyHours = dailyHours ? round(Number(dailyHours), 2) : 0;

      // Even though already checked for workplan.start_time in needToUpdateStartandEndTime
      // need to re-state this condition to supress warning about it being possibly null
      if (needToUpdateStartandEndTime && workplan.start_time) {
        const [startTime, startTimezone] = workplan.start_time.split(' ');

        const endTime = moment(startTime, 'HH:mm')
          .add(formattedDailyHours, 'h')
          .format('HH:mm');

        setWorkplan((prev) => ({
          ...prev,
          end_time: `${endTime} ${startTimezone}`
        }));
      }
    },
    [workplan.start_time, workplan.end_time, workplan.daily_hours]
  );

  const updatePredictedValues = useCallback(
    (predictedValues: WorkPlan) => {
      const formattedValues: WorkPlan = {
        ...predictedValues,
        daily_hours: Number(predictedValues.daily_hours)
          ? round(Number(predictedValues.daily_hours), 2).toString()
          : '',
        total_hours: Number(predictedValues.total_hours)
          ? round(Number(predictedValues.total_hours), 2).toString()
          : ''
      };

      const pickedValues = omit(
        pickBy(formattedValues, (_, key) => {
          return predictableValues[key];
        }),
        lastModifiedTargetRef.current.target
          ? [lastModifiedTargetRef.current.target]
          : []
      );

      setWorkplan((prev) => ({
        ...prev,
        ...pickedValues
      }));

      // When entering work days, do not override the entered value.
      if (lastModifiedTargetRef.current.target !== 'work_days') {
        const workdays = pickedValues.bars
          ? getWorkDaysFromScheduleBars(pickedValues.bars)
          : 1;
        setWorkdays(workdays);
      }

      // Calculate work day percent when daily hours is predicted, unless work
      // day percent is being entered.
      if (
        lastModifiedTargetRef.current.target !== 'workday_percent' &&
        pickedValues.daily_hours
      ) {
        const nextAccountCapacityToUse = predictedValues.account_id
          ? accountCapacitiesHash[predictedValues.account_id]
          : predictedValues.member_budget_id
          ? defaultDailyTeamCapacity
          : accountCapacityToUse;

        if (nextAccountCapacityToUse) {
          const newWorkdayPercent = getWorkdayPercent({
            dailyCapacity: nextAccountCapacityToUse,
            dailyHours: Number(pickedValues.daily_hours) || 0,
            datesArray: getDatesArray({
              startDate: pickedValues.start_date ?? workplan.start_date,
              endDate: pickedValues.end_date ?? workplan.end_date
            })
          });
          setWorkdayPercent(newWorkdayPercent ? String(newWorkdayPercent) : '');
        }
      }

      updateSetTime(formattedValues.daily_hours);
    },
    [
      accountCapacitiesHash,
      accountCapacityToUse,
      getDatesArray,
      workplan.end_date,
      workplan.start_date,
      defaultDailyTeamCapacity,
      updateSetTime
    ]
  );

  /* --------------------------------- predict -------------------------------- */
  const handlePredict = useCallback(
    ({
      values,
      lastModifiedTimestamp,
      onPredict
    }: {
      values: PredictWorkloadPlannerParams;
      lastModifiedTimestamp?: number;
      onPredict: (predictedValues: WorkPlan) => void;
    }) => {
      setPredictStatus('predicting');
      dispatch(
        predictWorkloadPlanner({
          ...values,
          onSuccess: [
            {
              successAction: ({ activity_phase_schedule_bar: values }) => {
                setPredictStatus('done');
                // prevent to override values while enter values by user
                if (
                  lastModifiedTimestamp ===
                  lastModifiedTargetRef.current.timestamp
                ) {
                  onPredict(values);
                }
              },
              selector: (payload, response) => response
            }
          ],
          onFailure: () => {
            setPredictStatus('error');
          }
        })
      );
    },
    [dispatch]
  );

  const handleDebouncedPredict = useMemo(
    () => debounce(handlePredict, debounceDelay),
    [handlePredict, debounceDelay]
  );

  const initialPredict = useCallback(
    (initialWorkplan: Omit<WorkPlan, 'id'>) => {
      const canPredict = validateToPredict({
        start_date: initialWorkplan.start_date,
        end_date: initialWorkplan.end_date,
        daily_hours: initialWorkplan.daily_hours,
        total_hours: initialWorkplan.total_hours
      });

      if (canPredict) {
        setPredictStatus('willPredict');
        handleDebouncedPredict({
          values: initialWorkplan,
          lastModifiedTimestamp: lastModifiedTargetRef.current.timestamp,
          onPredict: updatePredictedValues
        });
      }
    },
    [handleDebouncedPredict, updatePredictedValues]
  );

  const prevWorkplanAccountCapacity = usePrevious(
    accountCapacitiesHash[initialWorkplan.account_id]
  );

  const workplanAccountCapacity =
    accountCapacitiesHash[initialWorkplan.account_id];

  useEffect(() => {
    if (!isEqual(initialWorkplan, previousWorkplan)) {
      setWorkplan({
        ...initialWorkplan,
        dependency_infos: initialWorkplan.dependencies
          ? mapDependenciesToDependencyInfo(initialWorkplan.dependencies)
          : []
      });
      setWorkdays(calculateWorkdays({ workplan: initialWorkplan }));
    }
  }, [initialWorkplan, previousWorkplan]);

  useEffect(() => {
    if (
      !isEqual(initialWorkplan, previousWorkplan) ||
      !isEqual(prevWorkplanAccountCapacity, workplanAccountCapacity)
    ) {
      const accountCapacityToUse: DailyCapacity | undefined =
        initialWorkplan.account_id
          ? accountCapacitiesHash[initialWorkplan.account_id]
          : initialWorkplan.member_budget_id
          ? defaultDailyTeamCapacity
          : undefined;

      const nextWorkdayPercent = accountCapacityToUse
        ? getWorkdayPercent({
            dailyHours: Number(initialWorkplan.daily_hours) || 0,
            dailyCapacity: accountCapacityToUse,
            datesArray: getDatesArray({
              startDate: initialWorkplan.start_date,
              endDate: initialWorkplan.end_date
            })
          })
        : 0;
      setWorkdayPercent(nextWorkdayPercent ? String(nextWorkdayPercent) : '');

      if (shouldPredictWithInitialValue) {
        initialPredict(initialWorkplan);
      }
    }
  }, [
    accountCapacitiesHash,
    workplanAccountCapacity,
    prevWorkplanAccountCapacity,
    getDatesArray,
    initialWorkplan,
    previousWorkplan,
    defaultDailyTeamCapacity,
    initialPredict,
    shouldPredictWithInitialValue
  ]);

  const validateToPredict = (
    valuesToPredict: {
      start_date?: string;
      end_date?: string;
      daily_hours?: string;
      total_hours?: string;
    },
    modifyTarget?: string
  ): boolean =>
    // If `daily_hours` or `total_hours` is being cleared, there is no need run
    // a prediction on them.
    !(
      (modifyTarget === 'daily_hours' || modifyTarget === 'total_hours') &&
      modifyTarget in valuesToPredict &&
      valuesToPredict[modifyTarget] === undefined
    ) &&
    // If `workday_percent` is being cleared, there is no need run a prediction
    // on `daily_hours`, which equivalent to `workday_percent`.
    !(
      modifyTarget === 'workday_percent' &&
      'daily_hours' in valuesToPredict &&
      valuesToPredict.daily_hours === undefined
    ) &&
    Object.values(valuesToPredict).filter((item) => item === undefined)
      .length <= 1;

  // update predict hours on opening the modal to calculate daily_hours when approving workplan request
  useEffect(() => {
    if (
      initialWorkplan.isWorkplanRequest &&
      initialWorkplan.start_date &&
      initialWorkplan.end_date
    ) {
      handlePredict({
        values: {
          account_id: initialWorkplan.account_id,
          project_id: initialWorkplan.project_id,
          phase_id: initialWorkplan.phase_id,
          start_date: initialWorkplan.start_date,
          end_date: initialWorkplan.end_date,
          all_day: false,
          total_hours: initialWorkplan.total_hours,
          work_days: initialWorkplan.work_days?.toString(),
          include_weekends: false,
          dependency_infos: initialWorkplan.dependency_infos,
          type: initialWorkplan.type
        },
        onPredict: updatePredictedValues
      });
    }
  }, [handlePredict, initialWorkplan, updatePredictedValues]);

  return {
    predictedWorkplan: workplan,
    update: handleUpdate,
    isPredicting:
      predictStatus === 'predicting' || predictStatus === 'willPredict',
    extraValues: {
      work_days: workDays,
      workday_percent: workdayPercent,
      datesArray
    }
  };
};
