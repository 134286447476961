import { createSelector } from 'reselect';
import { initialState } from 'reducers/filters';
import { newFilter } from 'appUtils/filters';
import { makeIdHash } from 'appUtils';
import { FILTER_PAGE_NAMES } from 'appConstants/workload';
import {
  getCurrentUserPlannerMemberProjectIds,
  getProjectHash,
  getWFHProject,
  makeGetFilterProjectOrder
} from './projects';
import { getPhasesByProjectHash, getPhaseNames } from './phases';
import { getTeamMembersHash } from './team';
import { getWidgetsByFilterId } from 'DashboardModule/selectors';

import keyBy from 'lodash/keyBy';
import intersection from 'lodash/intersection';
import { getIsOnReportsView } from './navigations';

/**
 * @deprecated Use the typesafe version from `FilterModule/selectors`.
 */
export const getFiltersState = (state) => state.filters || initialState;

/**
 * @deprecated Use the typesafe version from `FilterModule/selectors`.
 */
export const getAccountFilters = createSelector(
  getFiltersState,
  (state) => state.accountFilters
);

/**
 * @deprecated Use the typesafe version from `FilterModule/selectors`.
 */
export const getAccountFiltersFetched = createSelector(
  getFiltersState,
  (state) => state.fetched
);

/**
 * @deprecated Use the typesafe version from `FilterModule/selectors`.
 */
export const getIsFetchingAccountFilters = createSelector(
  getFiltersState,
  (state) => state.isFetching
);

// assumes one filter per view - will have to modify when filters are selectable
// allow ignoring viewBy
const getOwnViewBy = (state, ownProps) =>
  ownProps.viewByOverride || ownProps.viewBy;

/**
 * @deprecated Use the typesafe version from `FilterModule/selectors`.
 */
const getOwnPageName = (state, ownProps) => ownProps.pageName;
const getOwnFilterId = (state, ownProps) =>
  ownProps.activeFilter?.id || ownProps.filterId;
export const getTimelineViewBy = (state) =>
  state.workloadPlannerFilter.timelineViewBy;

export const makeGetActiveWorkloadPlannerFilter = () =>
  createSelector(
    (state, ownProps) => ownProps.activeFilter,
    getAccountFilters,
    getOwnViewBy,
    getOwnPageName,
    getPhaseNames,
    getTeamMembersHash,
    getOwnFilterId,
    getWidgetsByFilterId,
    (state, ownProps) => ownProps.filterInit,
    (
      ownActiveFilter,
      filters,
      viewBy,
      pageName,
      phaseNames,
      teamMemberHash,
      filterId,
      widgetsByFilterId,
      filterInit
    ) => {
      if (ownActiveFilter) return ownActiveFilter;
      const filterById = filterId && filters[filterId];
      const activeFilter =
        filterById ||
        Object.values(filters).find(
          (filter) =>
            filter.name === viewBy &&
            [FILTER_PAGE_NAMES[pageName], pageName].includes(
              filter.page_name
            ) &&
            // prevent finding widget filter (filterId should be passed when searching for widget filter)
            !widgetsByFilterId[filter.id] &&
            (!filterInit || filter.id === 'new')
        );
      if (activeFilter) {
        const nextActiveFilter = { ...activeFilter };

        if (nextActiveFilter.phase_names?.length) {
          nextActiveFilter.phase_names = intersection(
            nextActiveFilter.phase_names,
            phaseNames || []
          );
        }
        if (nextActiveFilter.account_ids?.length) {
          nextActiveFilter.account_ids = nextActiveFilter.account_ids.filter(
            (accountId) => teamMemberHash[accountId]
          );
        }
        return nextActiveFilter;
      }
      return {
        ...newFilter,
        name: viewBy,
        page_name: FILTER_PAGE_NAMES[pageName] || pageName,
        page: pageName,
        ...(filterInit || {}),
        custom: {
          ...newFilter.custom,
          ...(filterInit?.custom || {})
        }
      };
    }
  );

export const getActiveWorkloadPlannerFilter =
  makeGetActiveWorkloadPlannerFilter();

const transformCustomFilters = (filter = {}) => {
  const { custom = {} } = filter;
  const billableValues = custom.billable?.length ? custom.billable : [];
  const projectBudgetStatuses = custom.projectBudgetStatuses?.length
    ? custom.projectBudgetStatuses
    : [];
  const phaseBudgetStatuses = custom.phaseBudgetStatuses?.length
    ? custom.phaseBudgetStatuses
    : [];
  return {
    billable: makeIdHash(billableValues),
    projectBudgetStatuses: makeIdHash(projectBudgetStatuses),
    phaseBudgetStatuses: makeIdHash(phaseBudgetStatuses)
  };
};

export const makeGetActiveFilterChanged = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (workloadPlannerFilter) => !!workloadPlannerFilter.filterChanged
  );

export const getActiveFilterChanged = makeGetActiveFilterChanged();

export const makeGetActiveWorkloadPlannerFilterIdHashes = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (_, ownProps) => ownProps?.draftFilter,
    (workloadPlannerFilter, draftFilter) => {
      const makeIdHashes = (filter) =>
        Object.entries(filter).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? makeIdHash(value) : value;
          return acc;
        }, {});

      return {
        ...makeIdHashes(workloadPlannerFilter),
        ...transformCustomFilters(workloadPlannerFilter),
        ...(draftFilter && makeIdHashes(draftFilter))
      };
    }
  );

export const getActiveWorkloadPlannerFilterIdHashes =
  makeGetActiveWorkloadPlannerFilterIdHashes();

export const getActiveWorkloadPlannerFilterIsNew = createSelector(
  getActiveWorkloadPlannerFilter,
  (activeFilter) => !activeFilter || activeFilter.id === newFilter.id
);

export const makeGetOrderedFilterWorkloadProjectIds = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    getCurrentUserPlannerMemberProjectIds,
    (filter, projectIds) =>
      Array.from(new Set([...(filter.project_ids || []), ...projectIds]))
  );
export const getOrderedFilterWorkloadProjectIds =
  makeGetOrderedFilterWorkloadProjectIds();

export const makeGetOrderFilterProjectIds = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (state) => state.home.allProjectsOrder,
    (filter, projectIds) =>
      Array.from(new Set([...(filter.project_ids || []), ...projectIds]))
  );

export const makeGetOrderedFilterWorkloadProjects = () =>
  createSelector(
    makeGetOrderedFilterWorkloadProjectIds(),
    getProjectHash,
    (projectIds, projectHash) =>
      projectIds
        .map((id) => projectHash[id])
        .filter((project) => project && !project.is_personal)
  );

export const getOrderedFilterWorkloadProjects =
  makeGetOrderedFilterWorkloadProjects();

export const makeGetOrderedFilterProjects = () =>
  createSelector(
    makeGetOrderedFilterWorkloadProjectIds(),
    getProjectHash,
    getIsOnReportsView,
    makeGetOrderFilterProjectIds(),
    (_, ownProps) => ownProps.isLikeOnReportsView,
    (
      projectIds,
      projectHash,
      isOnReportsView,
      orderProjectIds,
      isLikeOnReportsView
    ) =>
      // isLikeOnReportsView is used to get the same project ids as if it's on ReportsView
      (isOnReportsView || isLikeOnReportsView ? orderProjectIds : projectIds)
        .map((id) => projectHash[id])
        .filter((project) => project && !project.is_personal)
  );
export const getOrderedFilterProjects = makeGetOrderedFilterProjects();

export const makeGetOrderedProjects = () =>
  createSelector(
    makeGetFilterProjectOrder(),
    getProjectHash,
    // VirtualFilter fetches administrative projects for PTO
    // WFH is not used so filter it out. this is temporary measure until we have a feature for it
    getWFHProject,
    (_, ownProps) => ownProps.isPtoSelectable,
    (filterListProjectOrder, projectHash, WFHProject, isPtoSelectable) => {
      return filterListProjectOrder
        .map((id) => projectHash[id])
        .filter(
          (project) =>
            project && !(isPtoSelectable && project.id === WFHProject?.id)
        );
    }
  );

/* dont remove the regex. see https://wiki.postgresql.org/wiki/FAQ#Why_do_my_strings_sort_incorrectly.3F  - postgres sort order only uses white space to break ties */
export const getOrderedFilterActiveProjects = createSelector(
  makeGetActiveWorkloadPlannerFilter(),
  getProjectHash,
  (filter, projectHash) =>
    Array.from(
      new Set(
        filter?.project_ids?.length
          ? filter.project_ids
          : Object.keys(projectHash)
      )
    )
      .map((id) => projectHash[id])
      .filter((project) => project && !project.is_personal)
      .sort((a, b) =>
        a.title.replace(/\s/g, '').toLowerCase() >
        b.title.replace(/\s/g, '').toLowerCase()
          ? 1
          : -1
      )
);

const emptyArray = [];
export const sortPhasesByPhaseOrder = (projectPhases) => {
  if (!projectPhases) {
    return emptyArray;
  }
  const phasesById = keyBy(projectPhases.phases ?? {}, (item) => item.id);
  const phaseOrderHash = keyBy(projectPhases.phase_orders ?? {});
  const orderedPhases = projectPhases.phase_orders.map(
    (phaseId) => phasesById[phaseId]
  );
  const otherPhases = projectPhases.phases.filter(
    (phase) => !phaseOrderHash[phase.id]
  );
  return [...orderedPhases, ...otherPhases];
};
export const getOrderedFilterActivePhases = createSelector(
  getOrderedFilterActiveProjects,
  getPhasesByProjectHash,
  (projects, phaseHash) =>
    projects.reduce((orderedPhases, project) => {
      const projectPhases = phaseHash[project.id];
      const sortedProjectPhases = sortPhasesByPhaseOrder(projectPhases);
      sortedProjectPhases.forEach((phase) => {
        orderedPhases.push(phase);
      });
      return orderedPhases;
    }, [])
);

export const makeGetOrderedFilterPhaseNames = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    getPhaseNames,
    (filter, phaseNames) => {
      const filterPhaseNamesOrder = filter?.phase_names || emptyArray;
      return Array.from(new Set([...filterPhaseNamesOrder, ...phaseNames]));
    }
  );
