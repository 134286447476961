import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import theme from 'theme';

const StyledButton = styled.div`
  background: ${({ isBlue }) =>
    isBlue ? theme.colors.colorCalendarBlue : ''} !important;
  padding: 12px;
  display: flex;
  align-items: center;

  &.cancel-button:hover {
    background-color: ${theme.colors.colorMediumGray14};
  }
`;

const PlannerModalButton = ({
  className,
  text,
  onClick,
  textColor,
  textSize,
  isBlue
}) => (
  <StyledButton
    isBlue={isBlue}
    onClick={onClick}
    className={cn(className, textColor, textSize, 'modal-delete-button')}
  >
    {text}
  </StyledButton>
);

export default PlannerModalButton;
