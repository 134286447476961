import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewNotesIcon from 'images/addviewnotesicon.svg';

export const moduleTextHash = {
  1: 'People',
  2: 'Projects'
};

export const moduleHash = {
  1: 'members',
  2: 'projects'
};

export const defaultNavModuleIds = [1, 2];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'Members.',
  2: 'People'
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#3db489'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewNotesIcon}) `
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
