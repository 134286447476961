import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import sortBy from 'lodash/sortBy';
import { BaseLocationFieldStyles } from './styles';
import QBDownArrow from 'icons/QBDownArrow';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { Office } from 'SettingsModule/models/office';
import { getOfficesArray } from 'SettingsModule/selectors/offices';
import { getTeamSlug } from 'selectors';
import { navigateToTeamSettings } from 'actionCreators';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import { getUserIsAdmin } from 'PermissionsModule/selectors';

const copy = {
  emptyState:
    'There are no offices available to use as a main work address. Add some offices first or input a custom work address.',
  footerInitial: (
    <LockWithTooltip
      text="Manage Standard Work Addresses"
      tooltipContent="Only Admins can Add or Edit Standard Work Addresses"
    />
  )
};

interface OfficeSelectDropdownProps {
  selectedOfficeName: string | undefined;
  onSelect: (office: Office) => void;
}

const customItem = {
  id: 'customOffice',
  name: 'Custom/Remote'
};

export const OfficeSelectDropdown = ({
  selectedOfficeName,
  onSelect
}: OfficeSelectDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const teamSlug = useAppSelector(getTeamSlug);
  const dispatch = useAppDispatch();
  const userIsAdmin = useAppSelector(getUserIsAdmin);

  const handleSelect = (_, { item }: { item: Office }) => {
    onSelect(item);
    setIsOpen(false);
  };

  const _officesArray = useAppSelector(getOfficesArray);

  const sortedOfficesArray = useMemo(
    () => sortBy(_officesArray, (office) => office.name),
    [_officesArray]
  );

  const renderItem = ({
    item,
    selectCallback
  }: {
    item: Office | typeof customItem;
    selectCallback: (item: Office | undefined) => void;
  }) => {
    const isActualOffice = getIsActualOffice(item);
    return (
      <ItemContainer
        key={item.id}
        onClick={() => selectCallback(isActualOffice ? item : undefined)}
      >
        <ItemText>{item.name}</ItemText>
      </ItemContainer>
    );
  };

  const navigateToOfficesPage = useCallback(() => {
    if (teamSlug && userIsAdmin) {
      dispatch(
        navigateToTeamSettings({
          teamSlug,
          viewType: 'members',
          tab: 'offices',
          openInNewWindow: true
        })
      );
    }
  }, [dispatch, teamSlug, userIsAdmin]);

  return (
    <>
      <Trigger ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
        <TriggerContentContainer>
          <TriggerText>
            {selectedOfficeName && selectedOfficeName.length > 0
              ? selectedOfficeName
              : customItem.name}
          </TriggerText>
          <TriggerIcon>
            <QBDownArrow className={undefined} />
          </TriggerIcon>
        </TriggerContentContainer>
      </Trigger>
      {isOpen && (
        <MultiStepFlyout
          items={[customItem, ...sortedOfficesArray]}
          getItemId={(item) => item.id}
          renderItem={renderItem}
          copy={copy}
          idKey="id"
          noHeader
          target={dropdownRef}
          editDisabled
          itemHeight={48}
          listWidth={404}
          popoverClassName={'office-select-dropdown'}
          handleSelect={handleSelect}
          onFooterClick={navigateToOfficesPage}
        />
      )}
    </>
  );
};

const Trigger = styled.div`
  ${BaseLocationFieldStyles()};
  padding: 10px;
  cursor: pointer;
  height: 35px;
  align-items: center;
  width: 100%;
`;

const TriggerContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TriggerText = styled.div`
  width: 90%;
`;
const TriggerIcon = styled.div``;

const ItemContainer = styled.div`
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;

const ItemText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray2};
`;

const getIsActualOffice = (
  item: Office | typeof customItem
): item is Office => {
  return item.id !== 'customOffice';
};
