import { PTOsTableRowData } from '../types';
import styled from 'styled-components';
import { TeamMember } from 'models/teamMember';
import { PTOPolicySelector } from '../PTOPolicySelector';
import { useRef } from 'react';

interface PolicyCellProps {
  row: {
    original: {
      rowData: {
        data: PTOsTableRowData;
        teamMember: TeamMember;
      };
    };
  };
}

export const PolicyCell = (props: PolicyCellProps) => {
  const targetRef = useRef(null);

  const {
    data: { ptoGroup },
    teamMember
  } = props.row.original.rowData;

  const policyText = ptoGroup
    ? `${ptoGroup.hours}h | ${ptoGroup.name}`
    : 'Set Policy';
  return (
    <RootContainer ref={targetRef}>
      <PTOPolicySelector teamMember={teamMember} target={targetRef}>
        <ContentContainer $hasPolicy={!!ptoGroup}>
          <PolicyText>{policyText}</PolicyText>
        </ContentContainer>
      </PTOPolicySelector>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ContentContainer = styled.div<{ $hasPolicy: boolean }>`
  display: flex;
  align-items: center;
  font-size: 13px;

  color: ${({ $hasPolicy, theme }) =>
    $hasPolicy
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorCalendarBlue};

  :hover {
    color: ${({ theme }) => theme.colors.colorCalendarBlue};
  }
`;

const PolicyText = styled.div`
  // make 2 lines of text fit in the cell
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
