import styled from 'styled-components';

interface ProjectInfoProps {
  projectName: string;
  description?: string;
}
export const ProjectInfo = ({ projectName, description }: ProjectInfoProps) => (
  <Title>
    <Name>{projectName}</Name>
    {description && <Description>&nbsp;- {description}</Description>}
  </Title>
);

const Title = styled.div`
  // Text overflow only applies to block containers, so it is not possible to
  // put these rules on Sticky, which is a flexbox container.
  overflow: hidden;
  text-overflow: ellipsis;

  // Space between the phase icon and the title.
  * + & {
    margin-left: 3px;
  }
`;

const Name = styled.span`
  font-weight: 600;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 12px;
`;
