import 'isomorphic-fetch';
import qs from 'qs';
import { callApi } from 'appUtils/api';

export const createCustomField = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`custom_fields`, token, fetchInit);
};

export const fetchCustomField = (internalLabel, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    internal_label: internalLabel
  };

  const apiEndpoint = `custom_fields/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchCustomFieldAllValues = (internalLabel, token, teamId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    internal_label: internalLabel,
    team_id: teamId
  };

  const apiEndpoint = `custom_fields/get_all_values?${qs.stringify(
    queryParams,
    {
      arrayFormat: 'brackets'
    }
  )}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateCustomField = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`custom_fields`, token, fetchInit);
};

export const archiveCustomField = (token, internalLabel) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ internal_label: internalLabel })
  };
  return callApi(`custom_fields/undiscard`, token, fetchInit);
};

export const unarchiveCustomField = (token, internalLabel) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ internal_label: internalLabel })
  };
  return callApi(`custom_fields/undiscard`, token, fetchInit);
};
