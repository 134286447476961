import createPermissionsMiddleware from '../middleware/permissions';
import verifyPermission from '../middleware/permissions/verifyPermission';
import checkActionForPermissionsRequirement from '../middleware/permissions/permissionActions';
import { getUserPermissions } from 'selectors/permissions';
import { denyPermission } from 'actionCreators';

const permissionsMiddleware = createPermissionsMiddleware({
  checkForPermissionsRequirement: checkActionForPermissionsRequirement,
  verifyPermission: verifyPermission,
  denyAction: denyPermission,
  permissionsSelector: getUserPermissions
});

export default permissionsMiddleware;
