import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import { NO_PROJECT } from 'appConstants/planners';
import { calculateColorPref } from 'appUtils/styleUtils';
import {
  TaskCardAssignee,
  StyledNoteIcon,
  StyledCommentIcon,
  StyledCommentIconContainer,
  CommentNumber
} from './styles';
import { ProjectTitleCircle } from 'views/Home/Tasks/styles';
import { getTruncatedDescription } from 'appUtils';
import { isPastDueDate } from 'appUtils/plannerUtils';
import { isToday } from 'appUtils/momentUtils';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';

import {
  getTeamMembersHash,
  makeGetOwnTaskPriority,
  getUserTheme,
  getIsTaskModalOpen
} from 'selectors';
import {
  fetchTaskGroups,
  navigateToTaskModal,
  navigateToUsingPath,
  setSelectedTask,
  fetchCommentsAndMetadata
} from 'actionCreators';
import { useDrag } from 'react-dnd';

const ReadOnlyProjectTitle = ({ task }) => (
  <div
    className={cn('project-menu-container', {
      show: !!task.project?.name
    })}
  >
    <div>
      <ProjectTitleCircle projectId={task.project_id} />
      <span className="project-menu-button">{task.project?.name}</span>
      <div
        className={cn('dropdown-arrow', {
          show: false
        })}
      />
    </div>
  </div>
);

const ReadOnlyDateDisplay = ({ task }) => {
  const overdue = isPastDueDate(task);
  const dateDisplayText = isToday(task.schedule_start)
    ? 'Today'
    : moment(task.schedule_start).format('MMM D');

  const endDateText =
    task.schedule_end &&
    task.schedule_end !== task.schedule_start &&
    isToday(task.schedule_end)
      ? 'Today'
      : moment(task.schedule_end).format('MMM D');
  return (
    <div className="task-date-container due-container">
      <div className="task-date">
        <a
          style={{ marginLeft: '6px' }}
          data-tip={'Completed Date'}
          className={cn('due-date', {
            overdue,
            'no-date': !task.schedule_start,
            today: isToday(task.schedule_start)
          })}
        >
          {dateDisplayText} {task.schedule_end ? `- ${endDateText}` : null}
        </a>
      </div>
    </div>
  );
};

const ReadOnlyDescription = ({ task }) => {
  const { truncatedDescription } = getTruncatedDescription({
    fullText: task.description,
    singleLineCutoff: 35,
    lastLineCutoff: 20,
    numLines: 3
  });

  return (
    <div className="task-description">
      <div className="description-wrapper">{truncatedDescription}</div>
    </div>
  );
};

const mapPriority = {
  'Priority 1': 'P1',
  'Priority 2': 'P2',
  'Priority 3': 'P3',
  'Priority 4': 'P4'
};

export const ReadOnlyTaskCard = ({
  task,
  teamMembersHash,
  taskPriority,
  userTheme,
  style = {},
  isDemoTask = false,
  isDraggable = true,
  isTaskModalOpen
}) => {
  const assignee = task.assignee_id && teamMembersHash[task.assignee_id];
  const wrapperClass = cn('daily-task', {
    completed: task.completed_at,
    'no-project': task.project?.name === NO_PROJECT
  });
  const dispatch = useDispatch();
  const openTaskModal = useCallback(() => {
    dispatch(fetchTaskGroups({ taskGroupIds: [task.task_group_id] }));
    const navigationActionCreator = isTaskModalOpen
      ? navigateToUsingPath
      : navigateToTaskModal;
    dispatch(
      navigationActionCreator({
        taskId: task.id
      })
    );
    dispatch(setSelectedTask(task.id));
    dispatch(
      fetchCommentsAndMetadata({
        taskId: task.id,
        taskType: 'projects',
        offset: 0,
        limit: 4
      })
    );
  }, [dispatch, isTaskModalOpen, task.id, task.task_group_id]);

  const [{ opacity }, dragRef] = useDrag({
    item: { type: 'task', id: task.id, project_id: task.project_id },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    }),
    canDrag: () => isDraggable
  });

  const memberColor = calculateColorPref({
    styleId: assignee?.id,
    originType: 'teamMembership',
    theme: userTheme.theme
  });
  return (
    <div
      className={wrapperClass}
      id={`read-only-daily-task-${task.id}`}
      key={task.id}
      onClick={openTaskModal}
      ref={dragRef}
      style={{
        opacity,
        borderLeft: `4px solid ${memberColor || '#b3b3b3'}`,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        ...style
      }}
    >
      <div className="custom-card-content">
        <div className="top-row">
          {task?.estimated_hours ? (
            <div className="estimated-hours">{task?.estimated_hours}h</div>
          ) : (
            <div className="empty-div" />
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isDemoTask && task.schedule_start && (
              <ReadOnlyDateDisplay task={task} />
            )}
            {task.project?.name && <ReadOnlyProjectTitle task={task} />}
            {taskPriority ? (
              <div className="task-priority">
                <PriorityFlagIcon currentColor={taskPriority.color} />
                {mapPriority[taskPriority.title]}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <ReadOnlyDescription task={task} />
        <div className={cn('bottom-row unscheduled')}>
          <div className="left-side-container">
            {!isDemoTask && (
              <>
                <TaskCardAssignee
                  isAssigned={!!assignee}
                  assignedToMe={assignee && assignee.isLoggedInUser}
                  style={{ borderColor: memberColor || '#e4e4e4' }}
                >
                  {assignee ? assignee.initials : ''}
                </TaskCardAssignee>
                <div
                  className={cn('check-circle', {
                    completed: task.completed_at
                  })}
                />
              </>
            )}
          </div>
          <div className="right-side-container">
            {task.note ? <StyledNoteIcon /> : ''}
            {task.task_comment_ids?.length > 0 ? (
              <StyledCommentIconContainer>
                <StyledCommentIcon />
                <CommentNumber>{task.task_comment_ids?.length}</CommentNumber>
              </StyledCommentIconContainer>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const makeMapStateToProps = (state, ownProps) => {
  const getTaskPriority = makeGetOwnTaskPriority();
  const mapStateToProps = (state) => ({
    teamMembersHash: getTeamMembersHash(state),
    taskPriority: getTaskPriority(state, ownProps),
    userTheme: getUserTheme(state),
    isTaskModalOpen: getIsTaskModalOpen(state)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(React.memo(ReadOnlyTaskCard));

// const demoMapStateToProps =  state => ({
//   teamMembersHash: getTeamMembersHash(state),
//   taskPriority: getTaskPriority(state, ownProps),
//   userTheme: getUserTheme(state),
//   teamMembership: getMemberByAssigneeId(state, ownProps)
// });
