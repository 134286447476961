import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { getAccountWorkloadModalIsOpen, getSelectedTeamId } from 'selectors';
import { closeWorkloadModal } from 'actionCreators';
import { updateCapacity } from 'CapacityModule/actionCreators';
import { getAccountWorkloadCapacity } from 'CapacityModule/selectors';
import AccountWorkloadSettings from './AccountWorkloadSettings';
import { WorkloadHeader, DoneButton, SettingsHeader } from './styles';
class WorkloadSettingsModal extends React.Component {
  state = {
    capacity: {}
  };

  componentDidUpdate(prevProps) {
    const { capacity } = this.props;
    if (prevProps.capacity !== capacity) {
      this.setState({ capacity });
    }
  }

  handleSubmit = () => {
    const { updateCapacity } = this.props;
    const { capacity } = this.state;
    updateCapacity({ capacity });
  };

  handleSubmitAndClose = () => {
    const { closeWorkloadModal } = this.props;
    this.handleSubmit();
    closeWorkloadModal();
  };

  handleChange = (e) => {
    if (!isNaN(e.target.value))
      this.setState({
        capacity: { ...this.state.capacity, [e.target.name]: e.target.value }
      });
  };

  render() {
    const { isOpen, closeWorkloadModal, updateCapacity } = this.props;
    const { capacity } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={closeWorkloadModal}
        className="workload-planner-modal"
      >
        <WorkloadHeader justify="space-between">
          <SettingsHeader>Estimated Work Capacity</SettingsHeader>
          <div>
            <DoneButton onClick={this.handleSubmitAndClose}>Done</DoneButton>
          </div>
        </WorkloadHeader>

        <ModalBody>
          <AccountWorkloadSettings
            updateCapacity={updateCapacity}
            capacity={capacity}
            handleChange={this.handleChange}
          />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: getAccountWorkloadModalIsOpen(state),
  teamId: getSelectedTeamId(state),
  capacity: getAccountWorkloadCapacity(state)
});
const mapDispatchToProps = {
  closeWorkloadModal,
  updateCapacity
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkloadSettingsModal);
