import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { SectionTitle } from './styles';
import useAttachments from 'appUtils/hooks/useAttachments';
import CloseXIcon from 'icons/CloseXIcon';
import { getAttachmentHash } from 'selectors';
import { FILE_STATUS } from 'appConstants';
import { removeAttachmentSubscriptions } from 'actionCreators';

const emptyArray = [];

const AttachmentsSection = ({
  attachments = emptyArray,
  handleNewAttachments,
  handleDeleteAttachment
}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const attachmentHash = useSelector(getAttachmentHash);

  useEffect(() => {
    const attachmentIds = attachments.map((attachment) => attachment.id);
    return () => {
      dispatch(
        removeAttachmentSubscriptions({
          ids: attachmentIds
        })
      );
    };
  }, [dispatch, attachments]);

  const { onDragEnter, DragOverArea, isDraggingOver } = useAttachments({
    onFilesDrop: handleNewAttachments
  });

  const handleDeleteClick = (file) => {
    handleDeleteAttachment(file.id || file); // no id when locally stored
    dispatch(
      removeAttachmentSubscriptions({
        ids: [file.id]
      })
    );
  };

  return (
    <StyledAttachmentsSection
      onDragEnter={onDragEnter}
      isDraggingOver={isDraggingOver}
      className="modal-section"
      onClick={(e) => {
        inputRef.current?.click();
        e.stopPropagation();
      }}
    >
      <SectionTitle>ATTACHMENTS</SectionTitle>

      <DragOverArea />
      {attachments.length > 0 ? (
        <FilesList>
          {attachments.map((file, index) => {
            const isLastFile = index === attachments.length - 1;
            const { id } = file;

            // If attachment is inside of the hash it was changed in some way by BE.
            const attachmentInfo = attachmentHash[id] || file;
            if (attachmentInfo.deleted) {
              return null;
            }
            return (
              <File key={attachmentInfo.file_url}>
                <span
                  onClick={() => {
                    if (attachmentInfo.file_url) {
                      window.open(attachmentInfo.file_url, '_blank');
                    }
                  }}
                  data-for="app-tooltip"
                  data-effect="solid"
                  data-tip={
                    attachmentInfo?.status === FILE_STATUS.PENDING
                      ? 'Scanning for Malware'
                      : ''
                  }
                >
                  {/* .name when locally stored */}
                  {attachmentInfo.file_name || attachmentInfo.name}
                </span>
                {isLastFile ? '' : ','}
                <CloseXIcon
                  fill={theme.colors.colorRoyalBlue}
                  onClick={() => handleDeleteClick(attachmentInfo)}
                />
              </File>
            );
          })}
        </FilesList>
      ) : (
        <>
          {' '}
          <AddAttachmentsButton>Add Attachments</AddAttachmentsButton>
          <input
            className="local-file-input"
            type="file"
            name="local-file"
            id="local-file"
            multiple
            onChange={(e) => handleNewAttachments(e.target.files)}
            ref={inputRef}
          />
        </>
      )}
    </StyledAttachmentsSection>
  );
};

export default AttachmentsSection;

/* ------------------------------------ - ----------------------------------- */

const AddAttachmentsButton = styled.label`
  border: none;
  background: none;
  font-size: 14px;
  color: ${theme.colors.colorBudgetGrey};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const FilesList = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
`;
const StyledAttachmentsSection = styled.div`
  position: relative;
  padding: 3px 0;
  border: 1px solid transparent;
  ${(props) =>
    props.isDraggingOver &&
    `
    border-color: ${theme.colors.colorRoyalBlue};
    border-radius: 3px;
    padding: 3px;
  `}

  svg {
    margin: 2px 10px 0 4px;
  }

  .local-file-input {
    display: none;
  }

  &:hover {
    ${AddAttachmentsButton}, ${FilesList} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  &,
  & > * {
    cursor: pointer;
  }
`;

const File = styled.div`
  cursor: pointer;
  width: fit-content;
  svg {
    transform: translateY(-2px);
    visibility: hidden;
  }
  &:hover {
    svg {
      visibility: visible;
    }
    color: ${theme.colors.colorRoyalBlue};
  }
`;
