import { NonHoverState, HoverState } from '../styles';
import { ZOOM_FIT_ITEM_TO_SCALE, ZOOM_STRINGS } from 'appConstants/workload';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

export const TotalHoursText = ({ zoom, item, dayCount, averageCapacity }) => (
  <>
    <NonHoverState>
      {shouldInjectAvailabilities(zoom)
        ? formatNumWithMaxOneDecimal(
            (item.all_day ? averageCapacity : +item.daily_hours) * dayCount
          )
        : formatNumWithMaxOneDecimal(item.total_hours)}
      h
    </NonHoverState>
    <HoverState>
      {calcDaysOnTotal(item) > 6
        ? `${calcDaysOnTotal(item)}d`
        : `${formatNumWithMaxOneDecimal(item.total_hours)}h`}
    </HoverState>
  </>
);

const shouldInjectAvailabilities = (zoom) =>
  ZOOM_FIT_ITEM_TO_SCALE[zoom] === ZOOM_STRINGS.DAY;

const calcDaysOnTotal = (bar) =>
  bar.bars ? bar.bars.reduce((acc, cur) => acc + cur.day_count, 0) : 0;
