import React from 'react';
export const BUDGET_STATUSES = {
  HOLD: 'hold',
  NOT_STARTED: 'not started',
  ACTIVE: 'active',
  COMPLETE: 'complete',
  ARCHIVED: 'archived', // used by rendering, but is not held as an explicit budget status on the backend.
  PROPOSAL: 'proposal'
};
export const PHASE_BUDGET_STATUS_DISPLAY = {
  [BUDGET_STATUSES.NOT_STARTED]: 'Not Started',
  [BUDGET_STATUSES.HOLD]: 'Hold',
  [BUDGET_STATUSES.ACTIVE]: 'Active',
  [BUDGET_STATUSES.COMPLETE]: 'Completed',
  [BUDGET_STATUSES.ARCHIVED]: 'Archived'
};

// Used for sorting phases by status on the phase modal
export const PHASE_BUDGET_STATUS_SORT_VALUES = {
  [BUDGET_STATUSES.NOT_STARTED]: 4,
  [BUDGET_STATUSES.HOLD]: 3,
  [BUDGET_STATUSES.ACTIVE]: 2,
  [BUDGET_STATUSES.COMPLETE]: 1
};

export const PROJECT_BUDGET_STATUSES_DISPLAY = {
  [BUDGET_STATUSES.PROPOSAL]: 'Proposal',
  [BUDGET_STATUSES.HOLD]: 'Hold',
  [BUDGET_STATUSES.ACTIVE]: 'Active',
  [BUDGET_STATUSES.COMPLETE]: 'Completed',
  [BUDGET_STATUSES.ARCHIVED]: 'Archived'
};

export const INACTIVE_BUDGET_STATUSES = {
  [BUDGET_STATUSES.NOT_STARTED]: true,
  [BUDGET_STATUSES.COMPLETE]: true,
  [BUDGET_STATUSES.ARCHIVED]: true,
  [BUDGET_STATUSES.HOLD]: true,
  [BUDGET_STATUSES.PROPOSAL]: true
};

export const PHASE_BUDGET_STATUS_OPTIONS = [
  {
    label: (
      <span>{PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.NOT_STARTED]}</span>
    ),
    value: BUDGET_STATUSES.NOT_STARTED
  },
  {
    label: <span>{PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.HOLD]}</span>,
    value: BUDGET_STATUSES.HOLD
  },
  {
    label: <span>{PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.ACTIVE]}</span>,
    value: BUDGET_STATUSES.ACTIVE
  },
  {
    label: <span>{PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.COMPLETE]}</span>,
    value: BUDGET_STATUSES.COMPLETE
  }
];

export const PROJECT_BUDGET_STATUS_OPTIONS = [
  {
    label: (
      <span>{PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.PROPOSAL]}</span>
    ),
    value: BUDGET_STATUSES.PROPOSAL
  },
  {
    label: (
      <span>{PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.ACTIVE]}</span>
    ),
    value: BUDGET_STATUSES.ACTIVE
  },
  {
    label: <span>{PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.HOLD]}</span>,
    value: BUDGET_STATUSES.HOLD
  },
  {
    label: (
      <span>{PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.COMPLETE]}</span>
    ),
    value: BUDGET_STATUSES.COMPLETE
  }
];
