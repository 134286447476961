import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import { CSV_IMPORT_DATA_TYPES_FOR_DISPLAY } from 'CsvImportsModule/constants';
import mapValues from 'lodash/mapValues';

const emptyObj = {};

const csvImportDataTypes = Object.keys(CSV_IMPORT_DATA_TYPES_FOR_DISPLAY);

const csvImportDataTypesHash = mapValues(
  CSV_IMPORT_DATA_TYPES_FOR_DISPLAY,
  (label) => ({
    label
  })
);

export const useCsvImportDataTypesFilter = ({
  isOff,
  config,
  resultCountHash,
  shouldUseDraft,
  field = FilterField.csv_import_data_types
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: csvImportDataTypes,
    itemHash: csvImportDataTypesHash,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    labelKey,
    shouldUseDraft
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: csvImportDataTypesHash,
    labelKey,
    resultCountHash,
    filterLabel: config?.filterLabel
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'label';
