const loadAppcues = () => {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://fast.appcues.com/57045.js';
  body.appendChild(script);
};

export const initAppcues = () => {
  if (process.env.NODE_ENV === 'production') {
    loadAppcues();
  }
};
