import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  getProjectPrioritiesIdArray,
  getProjectPrioritiesHash
} from 'selectors';
import { fetchPriorities } from 'actionCreators';
import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import { Priority } from 'models/priority';

const emptyObj = {};

export const usePrioritiesFilter = ({
  isOff,
  config,
  resultCountHash,
  shouldUseDraft,
  field = FilterField.priority_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const dispatch = useAppDispatch();

  const priorityIdOrder = useAppSelector(getProjectPrioritiesIdArray);

  const priorityHash = useAppSelector(getProjectPrioritiesHash) as Record<
    number,
    Priority
  >;

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: priorityIdOrder,
    itemHash: priorityHash,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    labelKey,
    shouldUseDraft
  });

  useEffect(() => {
    if (!isOff && !priorityIdOrder.length) {
      dispatch(fetchPriorities({}));
    }
  }, [dispatch, isOff, priorityIdOrder.length]);

  return {
    ...arrayFilterFieldValues,
    itemHash: priorityHash,
    labelKey,
    resultCountHash,
    filterLabel: config?.filterLabel
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'title';
