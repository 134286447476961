import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
export const initialState = {
  phaseTemplateIds: [],
  phaseTemplateOrder: [],
  phaseTemplates: {},
  fetching: false
};
const byId = (item) => item.id;
const phaseTemplates = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_PHASE_TEMPLATES.TRIGGER: {
      return {
        ...state,
        fetching: true
      };
    }
    case constants.FETCH_PHASE_TEMPLATES.SUCCESS: {
      const {
        phase_templates,
        phase_template_order,
        default_phase_template_id,
        custom_phase_template_id
      } = action.payload.response;
      return {
        ...state,
        phaseTemplateIds: phase_templates.map(byId),
        phaseTemplateOrder: phase_template_order,
        phaseTemplates: keyBy(phase_templates, byId),
        fetching: false
      };
    }
    case constants.CREATE_PHASE_TEMPLATE.SUCCESS: {
      const phase_template = action.payload.response;
      return {
        ...state,
        phaseTemplateIds: [phase_template.id, ...state.phaseTemplateIds],
        phaseTemplateOrder: [phase_template.id, ...state.phaseTemplateOrder],
        phaseTemplates: {
          ...state.phaseTemplates,
          [phase_template.id]: phase_template
        }
      };
    }
    case constants.UPDATE_PHASE_TEMPLATE.SUCCESS: {
      const phase_template = action.payload.response;
      if (!phase_template.is_budget) {
        return state;
      } else {
        return {
          ...state,
          phaseTemplates: {
            ...state.phaseTemplates,
            [phase_template.id]: phase_template
          }
        };
      }
    }
    case constants.REORDER_PHASE_TEMPLATES.TRIGGER: {
      const { phaseTemplateOrder } = action.payload;
      if (!phaseTemplateOrder) {
        return state;
      }
      return {
        ...state,
        phaseTemplateOrder
      };
    }
    default:
      return state;
  }
};

export default phaseTemplates;
