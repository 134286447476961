import React from 'react';
import SvgIcon from 'components/SvgIcon';

const DisconnectQBIcon = ({ className, height = '18', width = '18' }) => (
  <SvgIcon className={className} height="18" width="18" viewBox="0 0 18 18">
    <path
      d="M12.8301 9.90581L14.5802 8.22043C15.2254 7.59391 15.5602 6.72785 15.4972 5.8451C15.4347 4.96233 14.9811 4.14879 14.2529 3.61349C13.81 3.28591 13.2843 3.07915 12.7304 3.01508C11.7623 2.8979 10.7931 3.2208 10.1052 3.89003L8.36272 5.58207C8.27177 5.66957 8.22119 5.78884 8.22119 5.91279C8.22119 6.03674 8.27232 6.156 8.36326 6.2435C8.4542 6.331 8.57745 6.37995 8.70554 6.37995C8.83362 6.37943 8.95632 6.32995 9.04674 6.24193L10.7855 4.54989C11.3415 4.01189 12.1514 3.80149 12.9108 3.99836C13.6701 4.19523 14.2632 4.76915 14.4672 5.50348C14.6706 6.23833 14.4537 7.02273 13.8978 7.5607L12.1488 9.24287C11.9594 9.42515 11.9588 9.72148 12.1472 9.90429C12.3355 10.0871 12.6412 10.0876 12.8301 9.90585L12.8301 9.90581Z"
      fill="#4F4F4F"
      stroke="#4F4F4F"
      strokeWidth="0.2"
    />
    <path
      d="M9.56673 11.7426L7.82628 13.4347C7.2709 13.9721 6.46095 14.1825 5.70159 13.9857C4.94278 13.7888 4.34972 13.2149 4.1463 12.4805C3.94287 11.7457 4.15976 10.9618 4.71568 10.4239C4.71568 10.4239 5.77586 9.39996 6.46371 8.73956C6.55466 8.65258 6.60633 8.53384 6.60686 8.40988C6.60686 8.28593 6.55628 8.16667 6.46587 8.07865C6.37546 7.99063 6.25275 7.94116 6.12467 7.94116C5.99658 7.94116 5.87335 7.99064 5.78293 8.07813L4.03394 9.76403C3.23476 10.538 2.92209 11.665 3.21485 12.7217C3.50761 13.7785 4.3606 14.6039 5.45252 14.8872C6.54445 15.17 7.70905 14.8679 8.50881 14.0946L10.2504 12.402C10.4387 12.2192 10.4382 11.9234 10.2493 11.7416C10.0604 11.5593 9.75472 11.5598 9.56689 11.7426L9.56673 11.7426Z"
      fill="#4F4F4F"
      stroke="#4F4F4F"
      strokeWidth="0.2"
    />
    <rect
      x="10.6968"
      y="14.5161"
      width="1.20002"
      height="3.48393"
      rx="0.600011"
      fill="#4F4F4F"
    />
    <rect
      width="1.18083"
      height="3.54248"
      rx="0.590413"
      transform="matrix(0.718602 -0.695422 0.718602 0.695422 12 13.114)"
      fill="#4F4F4F"
    />
    <rect
      x="13.8003"
      y="11.9531"
      width="1.16131"
      height="3.60007"
      rx="0.580657"
      transform="rotate(-90 13.8003 11.9531)"
      fill="#4F4F4F"
    />
    <rect
      x="7.73633"
      y="3.71289"
      width="1.20003"
      height="3.48395"
      rx="0.600013"
      transform="rotate(-180 7.73633 3.71289)"
      fill="#4F4F4F"
    />
    <rect
      width="1.18083"
      height="3.54249"
      rx="0.590414"
      transform="matrix(-0.718602 0.695421 -0.718602 -0.695421 6.43359 5.11475)"
      fill="#4F4F4F"
    />
    <rect
      x="4.6333"
      y="6.27588"
      width="1.16132"
      height="3.60008"
      rx="0.580658"
      transform="rotate(90 4.6333 6.27588)"
      fill="#4F4F4F"
    />
  </SvgIcon>
);

export default DisconnectQBIcon;
