import CollapseArrow from 'components/CollapseArrow';
import styled from 'styled-components';
import { TableCellProps } from '../../types';
import { isRateGroupToggleCell } from '../../utils';

export const CollapseHeaderCell = (props: TableCellProps) => {
  if (!isRateGroupToggleCell(props)) return null;

  const {
    row: {
      original: { isOpen, toggleCollapse }
    }
  } = props;

  return (
    <Cell>
      <CollapseArrow toggleCollapse={toggleCollapse} isCollapsed={!isOpen} />
    </Cell>
  );
};

const Cell = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 0;
`;
