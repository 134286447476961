import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import ReportsViewsRouter from './ReportsViewsRouter';
import LoadReportsContainer from 'ReportsModule/components/LoadReportsContainer';
import ReportsLanding from 'ReportsModule/components/ReportsLanding';
import withRouterParams from './WithRouterParams';
import ReportsNavigationTracker from 'views/Navigation/ReportsNavigationTracker';
import { VIEW_BY as PROFIT_REPORT_VIEWS } from 'ReportsModule/components/Profit/ProfitReportTable/constants';
import { VIEW_BY } from 'appConstants/workload';

class ReportsRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    return (
      <LoadReportsContainer {...this.props}>
        <ReportsNavigationTracker />
        <Switch>
          <Route exact path={`${match.url}`} component={ReportsLanding} />
          <Redirect
            exact
            from={`${match.url}/capacity`}
            to={`${match.url}/capacity/${VIEW_BY.MEMBERS}`}
          />
          <Redirect
            exact
            from={`${match.url}/demand`}
            to={`${match.url}/demand/${VIEW_BY.MEMBERS}`}
          />
          <Redirect
            exact
            from={`${match.url}/profit`}
            to={`${match.url}/profit/${PROFIT_REPORT_VIEWS.PROJECT}`}
          />
          <Redirect
            exact
            from={`${match.url}/budget`}
            to={`${match.url}/budget/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/variance`}
            to={`${match.url}/variance/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/utilization`}
            to={`${match.url}/utilization/${VIEW_BY.MEMBERS}`}
          />
          <Redirect
            exact
            from={`${match.url}/budgetvariance`}
            to={`${match.url}/budgetvariance/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/schedulevariance`}
            to={`${match.url}/schedulevariance/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/pto`}
            to={`${match.url}/pto/${VIEW_BY.MEMBERS}`}
          />
          <Redirect
            exact
            from={`${match.url}/timeprojection`}
            to={`${match.url}/timeprojection/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/spenttime`}
            to={`${match.url}/spenttime/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/plannedtime`}
            to={`${match.url}/plannedtime/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/unspentbudget`}
            to={`${match.url}/unspentbudget/${VIEW_BY.PROJECTS}`}
          />
          <Redirect
            exact
            from={`${match.url}/checkins`}
            to={`${match.url}/checkins/${VIEW_BY.MEMBERS}`}
          />
          <Redirect
            exact
            from={`${match.url}/timesheetstatus`}
            to={`${match.url}/timesheetstatus/${VIEW_BY.MEMBERS}`}
          />
          <Route
            path={[
              `${match.url}/:viewType/:reportViewType`,
              `${match.url}/:viewType`
            ]}
            component={ReportsViewsRouter}
          />
        </Switch>
      </LoadReportsContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsRouter))
);
