import { createSelector } from 'reselect';
import { getIsQBSynced } from 'QuickbooksModule/selectors';
import {
  getTimesheetViewBy,
  getTimesheetReportDateRange,
  getTimesheetDateKeys,
  getTimesheetIsWeek
} from 'selectors';
import { getIntegrationIds } from 'IntegrationsModule/selectors';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { SORT_BY } from 'appConstants/filters';
import { VIEW_BY, FILTER_PAGES } from 'appConstants/workload';

const moment = extendMoment(Moment);

const dateColumn = {
  headerType: SORT_BY.date,
  headerLabel: SORT_BY.date,
  accessor: (row) => row.date,
  id: 'date',
  align: 'left'
};

const phaseColumn = {
  headerType: SORT_BY.phase,
  headerLabel: SORT_BY.phase,
  accessor: (row) => row.phase,
  id: 'phase',
  align: 'left'
};

const memberColumn = {
  headerType: SORT_BY.member,
  headerLabel: SORT_BY.member,
  accessor: (row) => row.member,
  id: 'member',
  align: 'left'
};

const projectColumn = {
  headerType: SORT_BY.project,
  headerLabel: SORT_BY.project,
  accessor: (row) => row.project,
  id: 'project',
  align: 'left'
};

const activityColumn = {
  headerType: SORT_BY.activity,
  headerLabel: 'WORK CATEGORY',
  accessor: (row) => row.activity,
  id: 'activity',
  align: 'left'
};

const descriptionColumn = {
  headerType: SORT_BY.description,
  headerLabel: SORT_BY.description,
  accessor: (row) => row.description_title,
  id: 'description',
  align: 'left'
};

const mappingStatusColumn = {
  headerType: 'mappingStatus',
  headerLabel: 'SYNC',
  accessor: (row) => row,
  id: 'mappingStatus',
  align: 'center'
};

const qbColumn = {
  headerType: SORT_BY.qb,
  headerLabel: SORT_BY.qb,
  accessor: (row) => row.sync_status,
  id: 'qb',
  align: 'center'
};

const billableColumn = {
  headerType: SORT_BY.billable,
  headerLabel: SORT_BY.billable,
  accessor: (row) => row.billable,
  id: 'billable',
  align: 'center'
};

const rateColumn = {
  headerType: SORT_BY.rate,
  headerLabel: SORT_BY.rate,
  accessor: (row) => row.rate,
  id: 'rate',
  align: 'center'
};

const approverColumn = {
  headerType: SORT_BY.approver,
  headerLabel: 'Approved By',
  accessor: (row) => row.approver,
  id: 'approver',
  align: 'center'
};

const hoursColumn = {
  headerType: SORT_BY.hours,
  headerLabel: 'hrs',
  accessor: (row) => row.hours,
  id: 'hours',
  align: 'center'
};

const amountColumn = {
  headerType: SORT_BY.amount,
  headerLabel: 'amount',
  accessor: (row) =>
    row.amount !== undefined ? row.amount : (row.hours || 0) * (row.rate || 0),
  id: 'amount',
  align: 'center'
};

const bulkColumn = {
  headerType: 'bulk',
  headerLabel: 'ALL',
  accessor: (row) => row.isSelected,
  id: 'bulk',
  align: 'center'
};
const statusColumn = {
  headerType: SORT_BY.status,
  headerLabel: SORT_BY.status,
  accessor: (row) => row.status,
  id: 'status',
  align: 'center'
};

const logColumn = {
  headerType: 'log',
  headerLabel: 'log',
  accessor: (row) => row,
  id: 'log',
  align: 'center'
};

const emptyColumn = {
  headerType: 'empty',
  headerLabel: 'empty',
  accessor: (row) => 'empty',
  id: 'empty',
  align: 'center'
};

const scrollPaddingColumn = {
  headerType: 'scrollPadding',
  accessor: (row) => 'scrollPadding',
  id: 'scrollPadding',
  align: 'center'
};

const timeReportColumns = [
  dateColumn,
  phaseColumn,
  memberColumn,
  projectColumn,
  activityColumn,
  descriptionColumn,
  mappingStatusColumn,
  qbColumn,
  billableColumn,
  rateColumn,
  hoursColumn,
  amountColumn,
  approverColumn,
  logColumn,
  bulkColumn,
  emptyColumn,
  scrollPaddingColumn
  // statusColumn
];

const totalsColumn = {
  headerType: 'total',
  headerLabel: 'TOTAL',
  id: 'total',
  align: 'center',
  accessor: (row) =>
    +(
      Object.values(row.timesheets || {}).reduce(
        (acc, cur) => acc + +cur.hours,
        0
      ) ?? 0
    ).toFixed(2)
};

const timeReportColumnsWithoutQB = timeReportColumns.filter(
  (column) => column.id !== 'qb'
);
const timeReportColumnsWithoutMappingStatus = timeReportColumns.filter(
  (column) => column.id !== 'mappingStatus'
);
const timeReportColumnsWithoutIntegrations = timeReportColumns.filter(
  (column) => column.id !== 'qb' && column.id !== 'mappingStatus'
);
const viewByColumnFilters = {
  [VIEW_BY.MEMBERS]: (columns) =>
    columns.filter((column) => column.id !== 'member'),
  [VIEW_BY.PROJECTS]: (columns) =>
    columns.filter((column) => column.id !== 'project'),
  [VIEW_BY.NONE]: (columns) => columns
};

const makeTimesheetDateAccessor = (date) => (row) => row.timesheets?.[date];

export const getWeeklyTimesheetTableColumns = createSelector(
  getTimesheetReportDateRange,
  ({ start, end }) => [
    projectColumn,
    activityColumn,
    descriptionColumn,
    ...Array.from(moment.range(start, end).by('day')).map((day) => ({
      accessor: makeTimesheetDateAccessor(day.clone().format('MM/DD/YYYY')),
      headerType: 'weekDay',
      id: day.clone().format('MM/DD/YYYY'),
      headerLabel: day.clone().format('ddd D')
    })),
    totalsColumn,
    scrollPaddingColumn
  ]
);

export const getTimesheetReportColumnHeaders = createSelector(
  getIsQBSynced,
  getIntegrationIds,
  getTimesheetViewBy,
  getTimesheetIsWeek,
  getWeeklyTimesheetTableColumns,
  (isQbSynced, integrationIds, viewBy, isWeek, weeklyTimesheetTableColumns) => {
    if (isWeek && viewBy === VIEW_BY.MEMBERS) {
      return weeklyTimesheetTableColumns;
    }
    const columns = isQbSynced
      ? timeReportColumnsWithoutMappingStatus
      : integrationIds.length
      ? timeReportColumnsWithoutQB
      : timeReportColumnsWithoutIntegrations;
    const filter =
      viewByColumnFilters[viewBy] || viewByColumnFilters[VIEW_BY.NONE];
    return filter(columns).filter((column) => column.id !== 'phase');
  }
);

export const getBudgetModalColumnHeaders = createSelector(
  getIsQBSynced,
  getIntegrationIds,
  getTimesheetViewBy,
  getTimesheetReportDateRange,
  getTimesheetIsWeek,
  getWeeklyTimesheetTableColumns,
  (
    isQbSynced,
    integrationIds,
    viewBy,
    dateRange,
    isWeek,
    weeklyTimesheetTableColumns
  ) => {
    if (isWeek && viewBy === VIEW_BY.MEMBERS) {
      return weeklyTimesheetTableColumns;
    }
    const columns = isQbSynced
      ? timeReportColumnsWithoutMappingStatus
      : integrationIds.length
      ? timeReportColumnsWithoutQB
      : timeReportColumnsWithoutIntegrations;
    const filter =
      viewByColumnFilters[viewBy] || viewByColumnFilters[VIEW_BY.NONE];
    if (viewBy === VIEW_BY.PHASES) {
      return filter(columns).filter((column) => column.id !== 'phase');
    }
    return filter(columns);
  }
);

const leftPaddingColumn = {
  headerType: 'leftPadding',
  headerLabel: '',
  accessor: (row) => row,
  id: 'leftPadding',
  align: 'right'
};
const collapseColumn = {
  headerType: 'collapse',
  headerLabel: '',
  accessor: (row) => row,
  id: 'collapse',
  align: 'right'
};
const capacityMemberColumn = {
  headerType: 'member',
  headerLabel: '',
  accessor: (row) => row.member,
  id: 'member',
  align: 'right'
};
const entityColumn = {
  headerType: 'entity',
  headerLabel: '',
  accessor: (row) => row,
  id: 'entity',
  align: 'right'
};
const graphColumn = {
  headerType: 'graph',
  headerLabel: 'graph',
  id: 'graph',
  align: 'center',
  accessor: (row) => row
};
const demandTotalColumn = {
  headerType: 'total',
  headerLabel: 'PLANNED',
  id: 'total',
  align: 'center',
  accessor: (row) => row
};
const capacityTotalColumn = {
  headerType: 'total',
  headerLabel: 'TOTAL',
  id: 'total',
  align: 'center',
  accessor: (row) => row
};
const capacityAvailableColumn = {
  headerType: 'available',
  headerLabel: 'CAPACITY',
  id: 'available',
  align: 'center',
  accessor: (row) => row
};
const rightPaddingColumn = {
  headerType: 'rightPadding',
  headerLabel: '',
  accessor: (row) => row,
  id: 'rightPadding',
  align: 'right'
};

const capacityReportColumns = [
  leftPaddingColumn,
  collapseColumn,
  entityColumn,
  graphColumn,
  capacityTotalColumn,
  rightPaddingColumn
];

const demandReportColumns = [
  leftPaddingColumn,
  collapseColumn,
  entityColumn,
  graphColumn,
  demandTotalColumn,
  capacityAvailableColumn,
  rightPaddingColumn
];

const utilizationReportColumns = [
  leftPaddingColumn,
  collapseColumn,
  capacityMemberColumn,
  graphColumn,
  capacityTotalColumn,
  rightPaddingColumn
];

const columnsByPage = {
  [FILTER_PAGES.CAPACITY]: capacityReportColumns,
  [FILTER_PAGES.DEMAND_REPORT]: demandReportColumns
};

export const getCapacityReportColumnHeaders = createSelector(
  (state, ownProps) => ownProps.pageName,
  (pageName) => {
    return columnsByPage[pageName] || [];
  }
);

export const getUtilizationReportColumnHeaders = createSelector(
  (state, ownProps) => ownProps.viewBy,
  (viewBy) => utilizationReportColumns
);
