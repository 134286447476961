const loadHappyFox = () => {
  window.HFCHAT_CONFIG = {
    EMBED_TOKEN: '25ca0850-f91c-11eb-b8fb-2d93c551f3aa',
    ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
    showBadge: false,
    showBadgeOnLoad: false
  };

  (function () {
    var scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.src = window.HFCHAT_CONFIG.ASSETS_URL + '/js/widget-loader.js';
    // scriptTag.integrity = "<%= htmlWebpackPlugin.options.integrities.happyfoxchat %>"
    // scriptTag.crossOrigin = "anonymous"

    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(scriptTag, s);
  })();
};

export const initHappyFox = () => {
  if (process.env.NODE_ENV === 'production') {
    loadHappyFox();
  }
};
