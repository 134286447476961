import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TimesheetArrowSubmitIcon = ({
  fill = '#ccc',
  width = '23',
  height = '23',
  className
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 23 23"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <circle cx="11.5" cy="11.5" r="11" stroke={fill} />
    <path
      d="M11.5 4.25928V17.8889"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.037 12.3519L11.4999 17.8889L5.96289 12.3519"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default TimesheetArrowSubmitIcon;
