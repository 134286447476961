import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ActivityRowIcon = ({
  width = '15',
  height = '15',
  fill = '#0074D9',
  ...rest
}) => (
  <SvgIcon width={width} height={height} viewBox="0 0 15 15" {...rest}>
    <path
      d="M7.46631 0.75C3.87561 0.75 0.966309 3.6593 0.966309 7.25C0.966309 10.8407 3.87561 13.75 7.46631 13.75C11.057 13.75 13.9663 10.8407 13.9663 7.25C13.9663 3.6593 11.057 0.75 7.46631 0.75Z"
      fill="#F3F3F3"
      stroke="#4F4F4F"
      strokeWidth="1"
      className="circle"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="4"
      width="8"
      height="7"
    >
      <path
        d="M10.3493 4.71782L6.07137 9.02664L4.45342 7.40869L3.84326 8.01884L5.76286 9.93845C5.84856 10.0241 5.9651 10.0927 6.06794 10.0927C6.17078 10.0927 6.2839 10.0241 6.36959 9.94187L10.9526 5.33483L10.3493 4.71782Z"
        fill="#333333"
      />
    </mask>
    <g mask="url(#mask0)"></g>
    <path
      className="check-mark"
      d="M10.4275 4.88683L6.14949 9.19564L4.53155 7.57769L3.92139 8.18785L5.84099 10.1075C5.92668 10.1931 6.04323 10.2617 6.14607 10.2617C6.2489 10.2617 6.36202 10.1931 6.44772 10.1109L11.0308 5.50384L10.4275 4.88683Z"
      fill="#A4A4A4"
    />
  </SvgIcon>
);

export default ActivityRowIcon;
