import { css } from 'styled-components';

export const BaseLocationFieldStyles = ({
  $hasError
}: {
  $hasError?: boolean;
} = {}) => css`
  padding: 4px 0px 4px 10px;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  background-color: ${({ theme }) => theme.colors.colorLightGray19};
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray6};
  ${({ theme }) =>
    `border: 1px solid ${
      $hasError ? theme.colors.colorCalendarRed : 'transparent'
    };`}
  line-height: 26px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }

  :disabled {
    opacity: 0.5;
  }
`;
