import React from 'react';
import { useSelector } from 'react-redux';
import { getIsCsvImportDataReady, getIsCSVProvisioned } from 'selectors';
import { getIsIntegrationsV2Loading } from 'IntegrationsModule/selectors';
import ImportButton from './ImportButton';
import ProvisionButton from './ProvisionButton';
import LoadImportDataButton from './LoadImportDataButton';
import LoadImportDataTooltip from './LoadImportDataTooltip';
import styled from 'styled-components';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';

const entityTypeToFlag = {
  [CsvImportEntity.Member]: 'membersCsvImportFlag',
  [CsvImportEntity.ProjectPhaseActivityPhase]:
    'projectPhasesActivityPhasesCSVImport',
  [CsvImportEntity.TaskScope]: 'taskScopeCsvImportFlag'
};

interface ImportFlowProps {
  entityType: CsvImportEntity;
  buttonLabel?: string;
  importSessionId?: string;
  hideTooltip?: boolean;
  successfulUploadCallbackFn?: () => void;
  shouldUploadPortfolioNames?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
}

const ImportFlow = ({
  entityType,
  buttonLabel,
  importSessionId,
  hideTooltip,
  successfulUploadCallbackFn,
  shouldUploadPortfolioNames = false,
  disabled,
  disabledTooltip
}: ImportFlowProps) => {
  const flags = useFeatureFlags();
  const isCsvImportEnabled = flags[entityTypeToFlag[entityType]] ?? true;
  const isCsvImportDataReady = useSelector(getIsCsvImportDataReady);
  const isProvisioned = useSelector(getIsCSVProvisioned);
  const isFetchingProvisions = useSelector(getIsIntegrationsV2Loading);

  if (!isCsvImportEnabled) {
    return null;
  }

  return (
    <ImportFlowContainer
      {...defaultTooltipProps}
      data-tip={disabledTooltip}
      data-tip-disable={!disabled}
    >
      {!isProvisioned && !isFetchingProvisions && (
        <>
          <ProvisionButton disabled={disabled} />
          <LoadImportDataTooltip
            isProvisioned={isProvisioned}
            isCsvImportDataReady={undefined}
          />
        </>
      )}
      {isProvisioned && (
        <>
          <ImportButton
            isCsvImportDataReady={isCsvImportDataReady}
            entityType={entityType}
            buttonLabel={buttonLabel}
            importSessionId={importSessionId}
            successfulUploadCallbackFn={successfulUploadCallbackFn}
            shouldUploadPortfolioNames={shouldUploadPortfolioNames}
            disabled={disabled}
          />
          {!hideTooltip ? (
            <LoadImportDataTooltip
              isCsvImportDataReady={isCsvImportDataReady}
              isProvisioned={isProvisioned}
            />
          ) : null}
          {!isCsvImportDataReady && <LoadImportDataButton />}
        </>
      )}
    </ImportFlowContainer>
  );
};

export default ImportFlow;

/* ------------------------------------ - ----------------------------------- */

const ImportFlowContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7px;
`;
