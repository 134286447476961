import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  ModalButtonsRow,
  Left,
  Right,
  CancelButton,
  ConfirmButton
} from 'BudgetModule/components/styles';
import { Modal, ModalBody } from 'reactstrap';

const HideModalButtonsRow = styled(ModalButtonsRow)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-top: 0px;
`;

const HideCancelButton = styled(CancelButton)`
  width: 66px;
  height: 33px;
  padding: 0px;
  font-weight: 400;
  font-size: 14px;
`;

const HideButton = styled(ConfirmButton)`
  width: 60px;
  height: 33px;
  font-weight: 600;
  padding: 1px 8px 1px 9px;
  background: ${theme.colors.colorCalendarRed};
`;

const IsRemovingColumnModal = ({
  handleConfirm,
  handleCancel,
  removeSubtext,
  isOpen
}) => (
  <Modal isOpen={isOpen} toggle={handleCancel} className="hide-column-modal">
    <ModalBody>
      <div className="hide-header">Hide View</div>
      <div className="hide-text">{removeSubtext}</div>
    </ModalBody>
    <HideModalButtonsRow>
      <Left />
      <Right>
        <HideCancelButton onClick={handleCancel}>Cancel</HideCancelButton>
        <HideButton isDelete onClick={handleConfirm}>
          Hide
        </HideButton>
      </Right>
    </HideModalButtonsRow>
  </Modal>
);

export default IsRemovingColumnModal;
