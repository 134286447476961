import React from 'react';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import getCaretCoordinates from 'textarea-caret';
import { CommentInputContainer } from '../..';
import { commentTypes } from 'appConstants/commentConstants';
import { parseCommentsForMemberIds } from 'appUtils/task';

class TaskAddCommentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentBody: '',
      isExpanded: false,
      suggestionListLeft: 0,
      files: []
    };
    bindAll(this, [
      'toggleExpansion',
      'handleCommentChange',
      'handleCommentSubmit',
      'handleCancel',
      'handleKeyPress',
      'handleInputClick',
      'removeFile'
    ]);
  }

  getPermissions = () => {
    const { selectedTeamId, task } = this.props;
    const projectId = task.project_id;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  toggleExpansion() {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded
    }));
  }

  handleCommentChange(event) {
    this.setState({ commentBody: event.target.value });
  }

  handleKeyPress(event) {
    const { commentBody } = this.state;
    if (event.key == 'Enter' && !event.shiftKey && commentBody.length) {
      event.target.blur();
      event.preventDefault();
      this.handleCommentSubmit(true);
    }

    if (event.key == '@') {
      const commentInput = document.querySelector(
        '.add-comment-input .input textarea'
      );
      const suggestionListLeft = getCaretCoordinates(
        commentInput,
        commentInput.selectionEnd
      ).left;
      this.setState({ suggestionListLeft });
    }
  }

  handleCommentSubmit(fromEnter) {
    const { createComment, me, task, taskType, teamMembersHash } = this.props;
    const { commentBody, files } = this.state;
    const permissions = this.getPermissions();
    const membersToNotify = parseCommentsForMemberIds({
      commentBody,
      teamMembersHash,
      me
    });
    if (commentBody.length || files.length) {
      createComment({
        taskId: task.id,
        body: commentBody,
        membersToNotify,
        parentId: null,
        accountId: me.id,
        projectId: task.project_id,
        taskType: taskType,
        files,
        permissions
      });

      this.toggleExpansion();
      this.setState({
        commentBody: '',
        files: []
      });
    }
  }

  handleCancel() {
    this.setState({ commentBody: '', files: [] });
    this.toggleExpansion();
  }

  handleInputClick(e) {
    // clicking input box should only ever open/expand
    if (!this.state.isExpanded) {
      this.toggleExpansion();
    }
  }

  handleFiles = (files) => {
    this.setState((state) => ({
      files: [...Array.from(files), ...(state.files || [])]
    }));
  };

  removeFile(file) {
    this.setState((prevState) => ({
      files: prevState.files.filter((stateFile) => stateFile !== file)
    }));
  }

  render() {
    const { taskHasCommentsOrMeta, task, taskListView, isSelectedHomeTask } =
      this.props;
    const { commentBody, isExpanded, suggestionListLeft, files } = this.state;
    return (
      <div
        className={cn('add-comment-container', {
          'has-comments':
            taskHasCommentsOrMeta && (!taskListView || isSelectedHomeTask)
        })}
      >
        {(!taskListView || isSelectedHomeTask) && (
          <div className="add-comment-header">Updates</div>
        )}
        <CommentInputContainer
          className="add-comment-input"
          commentType={commentTypes.ADD}
          commentBody={commentBody}
          handleFiles={this.handleFiles}
          removeFile={this.removeFile}
          isExpanded={isExpanded}
          suggestionListLeft={suggestionListLeft}
          onCancel={this.handleCancel}
          onClick={this.handleInputClick}
          onChange={this.handleCommentChange}
          onSubmit={this.handleCommentSubmit}
          onKeyPress={this.handleKeyPress}
          unsavedFiles={files}
          task={task}
          projectId={task?.project_id}
        />
      </div>
    );
  }
}

export default TaskAddCommentContainer;
