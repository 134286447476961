import styled, { css } from 'styled-components';
import { DependencyTagContent } from '../types';

export const DependencyTag = ({
  dependencyTagContent
}: {
  dependencyTagContent: DependencyTagContent;
}) => {
  const handleClick = (e) => {
    const { onClick, dependencyId } = dependencyTagContent;
    onClick({ e, dependencyId });
  };

  return (
    <DependencyTagItem onClick={handleClick}>
      {dependencyTagContent.content}
    </DependencyTagItem>
  );
};

const HoverStyle = css`
  font-weight: 600;
  letter-spacing: -0.2px;
`;

const DependencyTagItem = styled.div`
  display: flex;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.colorCalendarBlue};
  padding: 0 6px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.colorPaleGray7};
  margin-top: 2px;
  cursor: pointer;
  gap: 1px;

  &:hover {
    ${HoverStyle}
    path {
      stroke: ${({ theme }) => theme.colors.colorCalendarBlue};
      stroke-width: 0.2px;
    }
  }
`;

export const DependencyTagItemContent = styled.div.attrs<{
  contentText?: string;
}>(({ contentText, children }) => ({
  'data-text': contentText ?? children
}))<{ contentText?: string }>`
  // display: flex and align/justify to make items width expand both directions when bolded
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: attr(data-text);
    display: block;
    height: 0;
    visibility: hidden;
    ${HoverStyle}
  }
`;
