import { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignScopeMembers,
  updateScope,
  deleteScopeAttachment
} from 'actionCreators';
import { getScopeUpdatingIds } from 'selectors';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import useEntities from 'appUtils/hooks/useEntities';
import moment from 'moment';

const useScope = ({
  scopeId,
  assignMembersDebounceTime = 800,
  descriptionSaveThrottleTime = 2000,
  notesSaveThrottleTime = 2000
}) => {
  const dispatch = useDispatch();
  const updatingScopeIds = useSelector(getScopeUpdatingIds);
  const { scope } = useEntities({ scopeId });

  /* ------------------------------- Description ------------------------------ */

  // when called from throttled version, must be given scopeId as arg (due to ref closure)
  const updateScopeDescription = useCallback(
    (newDescription, _scopeId = scopeId) => {
      // prevent empty scopes
      if (newDescription.trim().length) {
        dispatch(
          updateScope({
            id: _scopeId,
            description: newDescription
          })
        );
      }
    },
    [dispatch, scopeId]
  );

  const _throttledUpdateScopeDescription = useRef(
    throttle(updateScopeDescription, descriptionSaveThrottleTime, {
      leading: false,
      trailing: true
    })
  ).current;

  /**
   * For auto saving valid descriptions
   */
  const throttledUpdateScopeDescription = useCallback(
    (newDescription) => {
      _throttledUpdateScopeDescription(newDescription, scopeId);
    },
    [_throttledUpdateScopeDescription, scopeId]
  );

  /* ------------------------------- Notes ------------------------------ */

  // when called from throttled version, must be given scopeId as arg (due to ref closure)
  const updateScopeNotes = useCallback(
    (newNotes, _scopeId = scopeId) => {
      dispatch(
        updateScope({
          id: _scopeId,
          note: newNotes
        })
      );
    },
    [dispatch, scopeId]
  );

  const _throttledUpdateScopeNotes = useRef(
    throttle(updateScopeNotes, notesSaveThrottleTime, {
      leading: false,
      trailing: true
    })
  ).current;

  /**
   * For auto saving notes
   */
  const throttledUpdateScopeNotes = useCallback(
    (newNotes) => {
      _throttledUpdateScopeNotes(newNotes, scopeId);
    },
    [_throttledUpdateScopeNotes, scopeId]
  );

  /* --------------------------------- Assign --------------------------------- */

  // when called from debounced version, must be given scope as arg (due to ref closure)
  const assignMembersToScope = useCallback(
    ({ assignedAccountIds, filterStateId, groupBy, _scope = scope }) => {
      const currentAssignees = new Set(_scope.assignees);
      const newAssignees = new Set(assignedAccountIds);
      const assignIds = assignedAccountIds.filter(
        (id) => !currentAssignees.has(id)
      );

      const unassignIds = _scope.assignees.filter(
        (id) => !newAssignees.has(id)
      );

      const makeMemberPayload = (accountId, unassign) => ({
        account_id: accountId,
        unassign
      });

      const nextAssignedMembers = assignIds.map((accountId) =>
        makeMemberPayload(accountId)
      );
      const nextUnassignedMembers = unassignIds.map((accountId) =>
        makeMemberPayload(accountId, true)
      );

      const membersParam = [...nextAssignedMembers, ...nextUnassignedMembers];

      if (membersParam.length) {
        dispatch(
          assignScopeMembers({
            scopeId: _scope.id,
            members: membersParam,
            // for updating UI if necessary
            filterStateId,
            groupBy
          })
        );
      }
    },
    [dispatch, scope]
  );

  const _debouncedAssignMembersToScope = useRef(
    debounce(assignMembersToScope, assignMembersDebounceTime, {
      leading: false,
      trailing: true
    })
  ).current;

  /**
   * For auto saving assigned members
   */
  const debouncedAssignMembersToScope = useCallback(
    ({ assignedAccountIds, filterStateId, groupBy }) => {
      _debouncedAssignMembersToScope({
        assignedAccountIds,
        filterStateId,
        groupBy,
        _scope: scope
      });
    },
    [_debouncedAssignMembersToScope, scope]
  );

  /* ------------------------------- Attachments ------------------------------ */

  const addAttachmentsToScope = useCallback(
    (files) => {
      const newFiles = Array.from(files);
      dispatch(
        updateScope({
          id: scopeId,
          files: newFiles
        })
      );
    },
    [dispatch, scopeId]
  );

  const deleteAttachmentFromScope = useCallback(
    (attachmentId) => {
      dispatch(
        deleteScopeAttachment({
          attachmentId,
          id: scopeId
        })
      );
    },
    [dispatch, scopeId]
  );

  /* ---------------------------------- Dates --------------------------------- */

  const updateScopeDates = useCallback(
    ({ startDate, endDate }) => {
      dispatch(
        updateScope({
          id: scopeId,
          scheduleStart: moment(startDate).format('YYYY-MM-DD'),
          scheduleEnd: moment(endDate).format('YYYY-MM-DD')
        })
      );
    },
    [dispatch, scopeId]
  );

  /* ------------------------------------ - ----------------------------------- */

  return {
    scope,
    updatingScopeIds,
    addAttachmentsToScope,
    deleteAttachmentFromScope,
    assignMembersToScope,
    debouncedAssignMembersToScope,
    flushAssignMembersToScope: _debouncedAssignMembersToScope.flush,
    updateScopeDescription,
    throttledUpdateScopeDescription,
    flushUpdateScopeDescription: _throttledUpdateScopeDescription.flush,
    updateScopeNotes,
    flushUpdateScopeNotes: _throttledUpdateScopeNotes.flush,
    throttledUpdateScopeNotes,
    updateScopeDates
  };
};

export default useScope;
