import React from 'react';
import { connect } from 'react-redux';
import FilterDropDown from './FilterDropdown';
import { HeaderContainer } from './styles';
import QBDownArrow from 'icons/QBDownArrow';

class FilterContainer extends React.Component {
  state = {
    isDropdownOpen: false
  };

  openFilterDropdown = () => {
    this.setState({ isDropdownOpen: true });
  };

  setContainerRef = (ref) => (this.target = ref);

  handleClose = (e) => {
    const { closeCallback } = this.props;
    this.setState({ isDropdownOpen: false });
    closeCallback && closeCallback(e);
  };

  renderHeader(headerCopy) {
    return <div>{headerCopy}</div>;
  }

  selectItemCallback = (item, isSelected) => {
    const { selectItemCallback } = this.props;
    selectItemCallback && selectItemCallback(item, isSelected);
  };

  render() {
    const {
      items,
      renderHeaderCopy,
      hiddenItemsById,
      renderFooterCopy,
      actionCallBackOnAllItems,
      searchEnabled,
      customCopy,
      stickyBatchSelect,
      renderToggle,
      listWidth,
      listItemContainerStyle,
      customRenderItem,
      itemHeight,
      listHeight,
      handleBatchSelect,
      loadMoreItems,
      loadInitialItems,
      hasNextPage,
      numSelected,
      itemFilter,
      isLoading,
      batchClearOnly,
      onSearchChange,
      hideFooter,
      noHeader,
      autoClick,
      styleWrapper
    } = this.props;
    const { isDropdownOpen } = this.state;
    return (
      <>
        <div onClick={this.openFilterDropdown} ref={this.setContainerRef}>
          {renderToggle ? (
            renderToggle({ isOpen: isDropdownOpen })
          ) : (
            <HeaderContainer active={isDropdownOpen}>
              {this.renderHeader(renderHeaderCopy)}
              <QBDownArrow />
            </HeaderContainer>
          )}
        </div>
        {isDropdownOpen && (
          <FilterDropDown
            renderHeaderCopy={renderHeaderCopy}
            items={items}
            handleClose={this.handleClose}
            target={this.target}
            selectItemCallback={this.selectItemCallback}
            hiddenItemsById={hiddenItemsById}
            renderFooterCopy={renderFooterCopy}
            actionCallBackOnAllItems={actionCallBackOnAllItems}
            searchEnabled={searchEnabled}
            customCopy={customCopy}
            customRenderItem={customRenderItem}
            stickyBatchSelect={stickyBatchSelect}
            listItemContainerStyle={listItemContainerStyle}
            listWidth={listWidth}
            listHeight={listHeight}
            itemHeight={itemHeight}
            handleBatchSelect={handleBatchSelect}
            loadMoreItems={loadMoreItems}
            loadInitialItems={loadInitialItems}
            hasNextPage={hasNextPage}
            numSelected={numSelected}
            itemFilter={itemFilter}
            isLoading={isLoading}
            batchClearOnly={batchClearOnly}
            onSearchChange={onSearchChange}
            hideFooter={hideFooter}
            noHeader={noHeader}
            autoClick={autoClick}
            styleWrapper={styleWrapper}
          />
        )}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({});

  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(FilterContainer);
