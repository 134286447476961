import MemberManagementTab from 'views/personalSettings/MemberManagementTab';
import DepartmentsTab from 'views/personalSettings/DepartmentsTab';
import BudgetAdminTab from 'SettingsModule/components/Budget/BudgetAdminTab';
import SkillsTab from 'views/personalSettings/SkillsTab';
import { RegionsTab } from 'views/personalSettings/RegionsTab';
import { OfficesTab } from 'views/personalSettings/OfficesTab';
import { DisciplinesTab } from 'views/personalSettings/DisciplinesTab';
import { LocationsTab } from 'views/personalSettings/LocationsTab';
import { PTOsTab } from 'views/personalSettings/PTOsTab';
import { CapacitiesTab } from 'views/personalSettings/CapacitiesTab';
import { RolesTab } from 'views/personalSettings/RolesTab';

export const MembersSettingsViews = {
  members: MemberManagementTab,
  departments: DepartmentsTab,
  budget: BudgetAdminTab,
  skills: SkillsTab,
  locations: LocationsTab,
  pto: PTOsTab,
  regions: RegionsTab,
  offices: OfficesTab,
  disciplines: DisciplinesTab,
  roles: RolesTab,
  capacity: CapacitiesTab
};
