import styled from 'styled-components';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Capacity, CapacityGroup } from 'CapacityModule/models';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getCapacityPoliciesByDescriptionHash } from 'CapacityModule/selectors/capacityGroup';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import {
  capacityGroupFormatUtils,
  capacityGroupUtils
} from 'CapacityModule/utils/capacityGroup';
import { getSelectedTeamId } from 'selectors';
import {
  archiveCapacityPolicy,
  updateCapacityPolicy,
  createCapacityPolicy
} from 'CapacityModule/actionCreators/capacityGroup';
import {
  StyledInput,
  InputLabel
} from 'components/Modals/budget/shared/styles';
import { ArchiveButton } from 'BudgetModule/components/styles';
import { TextButtonWithBorder } from 'components/styles';
import { Space } from 'components/Space';
import theme from 'theme';
import { Modal } from 'reactstrap';
import moment from 'moment';

interface CapacityInput {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

const createPolicyRequestStatusId = `StandardCapacityTableContainer__createPolicy`;

const getUpdatePolicyRequestStatusId = (id: number) =>
  `StandardCapacityTableContainer__updatePolicy__${id}`;

const getArchivePolicyRequestStatusId = (id: number) =>
  `StandardCapacityTableContainer__archivePolicy__${id}`;

const mapCapacityToCapacityInputs = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
}: Capacity): CapacityInput => ({
  monday: monday.toString(),
  tuesday: tuesday.toString(),
  wednesday: wednesday.toString(),
  thursday: thursday.toString(),
  friday: friday.toString(),
  saturday: saturday.toString(),
  sunday: sunday.toString()
});

const mapCapacityInputsToCapacity = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
}: CapacityInput): Capacity => {
  return {
    monday: +monday,
    tuesday: +tuesday,
    wednesday: +wednesday,
    thursday: +thursday,
    friday: +friday,
    saturday: +saturday,
    sunday: +sunday
  };
};

const getIsCapacityInputsValid = (capacityInput: CapacityInput) =>
  Object.values(capacityInput).every((capacityValue) => !isNaN(capacityValue));

interface AddEditCapacityPolicyModalProps {
  policyBeingEdited?: CapacityGroup;
  isCustom?: boolean;
  teamMembershipIds?: number[];
  onToggle: () => void;
}

export const AddEditCapacityPolicyModal = ({
  policyBeingEdited,
  isCustom = false,
  teamMembershipIds = [],
  onToggle
}: AddEditCapacityPolicyModalProps) => {
  const [capacity, setCapacity] = useState<CapacityInput>(
    policyBeingEdited?.capacity
      ? mapCapacityToCapacityInputs(policyBeingEdited.capacity)
      : {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: ''
        }
  );

  const [description, setDescription] = useState(policyBeingEdited?.name ?? '');

  const [errors, setErrors] = useState<Partial<{ description: string }>>({});
  const dispatch = useAppDispatch();

  const teamCapacityPoliciesByDescription = useAppSelector(
    getCapacityPoliciesByDescriptionHash
  );

  const isEditing = getIsEditing(policyBeingEdited);
  const isNew = !isEditing;

  const updatePolicyRequestStatusId = isEditing
    ? getUpdatePolicyRequestStatusId(policyBeingEdited.id)
    : undefined;

  const archivePolicyRequestStatusId = isEditing
    ? getArchivePolicyRequestStatusId(policyBeingEdited.id)
    : undefined;

  const isEditingDisabled = isEditing
    ? capacityGroupUtils.getIsArchived(policyBeingEdited)
    : false;

  const { status: createPolicyRequestStatus } = useRequestStatus({
    requestStatusId: createPolicyRequestStatusId
  });

  const { status: updatePolicyRequestStatus } = useRequestStatus({
    requestStatusId: updatePolicyRequestStatusId
  });

  const { status: archivePolicyRequestStatus } = useRequestStatus({
    requestStatusId: archivePolicyRequestStatusId
  });

  const teamId = useAppSelector(getSelectedTeamId);

  const isDescriptionAlreadyExists =
    !!teamCapacityPoliciesByDescription[description];

  const hasChangedValues =
    isEditing &&
    (policyBeingEdited?.name !== description ||
      !isEqual(
        policyBeingEdited?.capacity,
        mapCapacityInputsToCapacity(capacity)
      ));

  const handleArchiveClick = () => {
    if (isEditing) {
      dispatch(
        archiveCapacityPolicy({
          id: policyBeingEdited.id,
          isArchive: !capacityGroupUtils.getIsArchived(policyBeingEdited),
          meta: { requestStatusId: archivePolicyRequestStatusId }
        })
      );
    }
  };

  const inputValidations = useMemo(() => {
    const isDescriptionNonEmpty = description.trim().length > 0;
    const isNewPolicyDescriptionValid =
      isNew && isDescriptionNonEmpty && !isDescriptionAlreadyExists;

    const isEditingPolicyDescriptionValid =
      isEditing &&
      isDescriptionNonEmpty &&
      (!isDescriptionAlreadyExists ||
        (isDescriptionAlreadyExists && policyBeingEdited.name === description));

    // We are self-setting the description for custom policies
    const isDescriptionValid = isCustom
      ? true
      : isNew
      ? isNewPolicyDescriptionValid
      : isEditingPolicyDescriptionValid;

    return {
      capacity: { isValid: getIsCapacityInputsValid(capacity) },
      description: { isValid: isDescriptionValid }
    };
  }, [
    capacity,
    description,
    isCustom,
    isDescriptionAlreadyExists,
    isEditing,
    isNew,
    policyBeingEdited?.name
  ]);

  const isValid = useMemo(() => {
    return Object.values(inputValidations).every(({ isValid }) => isValid);
  }, [inputValidations]);

  const resetDescriptionError = () => {
    setErrors(omit(errors, 'description'));
  };

  const handleError = () => {
    if (!inputValidations.description.isValid && description.length) {
      setErrors({
        ...errors,
        description: 'A policy with this description already exists'
      });
    }
  };

  const handleSubmit = () => {
    if (!isValid) {
      handleError();
      return;
    }

    if (isNew) {
      if (teamId) {
        dispatch(
          createCapacityPolicy({
            teamId,
            name: isCustom ? `Custom ${moment().toISOString()}` : description,
            isCustom,
            capacity: mapCapacityInputsToCapacity(capacity),
            teamMembershipIds,
            meta: {
              requestStatusId: createPolicyRequestStatusId
            }
          })
        );
      }
    } else {
      dispatch(
        updateCapacityPolicy({
          id: policyBeingEdited.id,
          name: isCustom ? policyBeingEdited.name : description,
          capacity: mapCapacityInputsToCapacity(capacity),
          meta: {
            requestStatusId: updatePolicyRequestStatusId
          }
        })
      );
    }
  };

  useEffect(() => {
    if (
      createPolicyRequestStatus?.isSuccess ||
      updatePolicyRequestStatus?.isSuccess ||
      archivePolicyRequestStatus?.isSuccess
    ) {
      onToggle();
    }
  }, [
    archivePolicyRequestStatus?.isSuccess,
    createPolicyRequestStatus?.isSuccess,
    onToggle,
    updatePolicyRequestStatus?.isSuccess
  ]);

  const isExecuting =
    createPolicyRequestStatus?.isExecuting ||
    updatePolicyRequestStatus?.isExecuting ||
    archivePolicyRequestStatus?.isExecuting;

  // manually control the order of the days being rendered
  const capacityDayOfWeekToValueArray: [keyof Capacity, string][] = [
    ['sunday', capacity.sunday],
    ['monday', capacity.monday],
    ['tuesday', capacity.tuesday],
    ['wednesday', capacity.wednesday],
    ['thursday', capacity.thursday],
    ['friday', capacity.friday],
    ['saturday', capacity.saturday]
  ];

  return (
    <StyledModal isOpen toggle={onToggle}>
      <Header>
        {isNew
          ? `Create ${isCustom ? 'Custom ' : ''}Capacity Policy`
          : `Edit ${isCustom ? 'Custom ' : ''}Capacity Policy`}
      </Header>
      <Space vertical value={35} />
      <Body>
        {!isCustom ? (
          <>
            <DescriptionInputContainer>
              <InputLabel htmlFor="description-input">POLICY</InputLabel>
              <StyledInput
                id="description-input"
                disabled={isEditingDisabled}
                value={description}
                placeholder={`Type ${isNew ? 'New ' : ''}Policy Name`}
                onChange={(e) => setDescription(e.target.value)}
                onFocus={resetDescriptionError}
              />
              <MessageContainer>{errors.description}</MessageContainer>
            </DescriptionInputContainer>
            <Space vertical value={43} />
          </>
        ) : null}
        <CapacityContentRow>
          {capacityDayOfWeekToValueArray.map(([day, value]) => (
            <CapacitySingleDayContainer key={day}>
              <CapacityDayText>
                {capacityGroupFormatUtils.getFormattedStringForCapacityDayOfWeek(
                  day
                )}
              </CapacityDayText>
              <CapacityDayInput
                as={NumberFormat}
                id={`capacity-input-${day}`}
                disabled={isEditingDisabled}
                value={value}
                decimalScale={2}
                allowNegative={false}
                isNumericString
                $isWeekend={day === 'saturday' || day === 'sunday'}
                onValueChange={({ value }) =>
                  setCapacity({ ...capacity, [day]: value })
                }
              />
            </CapacitySingleDayContainer>
          ))}
          <CapacitySingleDayContainer>
            <CapacityDayText>TOTAL</CapacityDayText>
            <CapacityDayInput
              key={'total'}
              as={NumberFormat}
              id={'capacity-input-total'}
              disabled={true}
              value={Object.values(capacity).reduce((a, b) => +a + +b, 0)}
              decimalScale={2}
              allowNegative={false}
              isNumericString
            />
          </CapacitySingleDayContainer>
        </CapacityContentRow>
      </Body>
      <Footer>
        {isEditing && (
          <ArchiveButton
            style={{ marginRight: 'auto' }}
            disabled={isExecuting}
            onClick={handleArchiveClick}
          >
            {capacityGroupUtils.getIsArchived(policyBeingEdited)
              ? 'Unarchive'
              : 'Archive'}
          </ArchiveButton>
        )}

        <ButtonContainer>
          <TextButtonWithBorder
            padding="8px 16px"
            disabled={isExecuting}
            onClick={onToggle}
          >
            Cancel
          </TextButtonWithBorder>
          <TextButtonWithBorder
            padding="8px 16px"
            backgroundColor={
              isValid
                ? theme.colors.colorRoyalBlue
                : theme.colors.colorLightGray9
            }
            color="white"
            disabled={isExecuting || !isValid}
            onClick={isNew || hasChangedValues ? handleSubmit : onToggle}
          >
            {isNew ? 'Create' : hasChangedValues ? 'Save' : 'Done'}
          </TextButtonWithBorder>
        </ButtonContainer>
      </Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 519px;

  .modal-content {
    padding: 35px 46px;
  }
`;

const Header = styled.div`
  font-weight: 600;
  color: black;
  font-size: 22px;
`;

const Body = styled.div``;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CapacityDayInput = styled(InputContainer)<{ $isWeekend?: boolean }>`
  width: 51.875px;
  border: 1px solid ${theme.colors.colorLightGray12};
  background: ${(props) =>
    props.$isWeekend ? theme.colors.colorLightGray19 : 'white'};
  background-clip: padding-box;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${theme.colors.colorMediumGray9};
  padding: 13px 0;

  &:hover,
  &:active,
  &:focus {
    border-color: ${theme.colors.colorBudgetBlue};
  }
`;

const CapacitySingleDayContainer = styled.div`
  margin-top: -1px;

  &:not(:first-child) ${CapacityDayInput} {
    border-left-color: transparent;

    &:hover,
    &:active,
    &:focus {
      border-left-color: ${theme.colors.colorBudgetBlue};
    }
  }
`;

const CapacityContentRow = styled.div`
  display: flex;
  align-items: center;
`;

const CapacityDayText = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: ${theme.colors.colorMediumGray1};
`;

const DescriptionInputContainer = styled(InputContainer)`
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  min-width: fit-content;
  * {
    &:first-child {
      margin-right: 2px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MessageContainer = styled.span`
  font-size: 11px;
  position: absolute;
  bottom: -17px;
  color: ${theme.colors.colorCalendarRed};
`;

const getIsEditing = (
  policy: CapacityGroup | undefined
): policy is CapacityGroup => {
  return !!policy;
};
