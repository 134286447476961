import React, { Component } from 'react';
import moment from 'moment';
import Calendar from 'rc-calendar/lib/Calendar';
import 'rc-calendar/assets/index.css';
import invariant from 'invariant';
import enUS from 'rc-calendar/lib/locale/en_US';
import Picker from './Picker';
import { PickerWrapper } from './styles/components';
import TaskPickerInput from './TaskPickerInput';
import ModalContext from '../../containers/ModalContext';
import { makeDateFromDateTime } from 'appUtils/momentUtils';
import cn from 'classnames';

const noop = () => {};

class DatePicker extends Component {
  static defaultProps = {
    prefixCls: 'rc-calendar',
    allowClear: true,
    showToday: true,
    showDateInput: true
  };

  static contextType = ModalContext;

  constructor(props) {
    super(props);
    const value = moment(props.selectedValue).isValid()
      ? moment(props.selectedValue)
      : undefined;
    invariant(
      !(value && !value.isValid()),
      `The ${props.value} value/defaultValue of DatePicker is an invalid date.`
    );
    this.state = {
      value: props.selectedValue,
      showDate: value
    };
  }

  componentWillUnmount = () => {
    const container = this.context.getModal();
    container.style.left = '';
    container.style.top = '';
    container.style.right = '';
  };

  getContainerInstance = () => {
    const container = this.context.getModal();
    container.style.top = '120px';
    container.style.right = '300px';
    container.style.left = '';
    return container;
  };

  clearSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleChange(null);
  };

  handleChange = (value) => {
    const { props } = this;
    if (!('value' in props)) {
      this.setState({
        showDate: value
      });
    }
    props.onChange(value, (value && value.format(props.format)) || '');
  };

  handleCalendarChange = (value) => {
    this.setState({ showDate: value });
  };

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  removeDate = (e) => {
    this.handleChange(null);
  };

  renderFooter = () => {
    const {
      hideRemove,
      selectedValue,
      batchSelected,
      showRangeOption,
      onRangeClick = noop
    } = this.props;
    return hideRemove ? (
      ''
    ) : (
      <>
        <div className="due-date-calendar-footer">
          <div className="footer-content remove" onClick={this.removeDate}>
            {selectedValue || batchSelected ? 'Remove' : null}
          </div>
        </div>
        {showRangeOption && (
          <div className="range-option-container" onClick={onRangeClick}>
            <div className="range-option-button"> Set Range</div>
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      renderCustomInput,
      prefixCls,
      taskDateType,
      taskIsCreating,
      alwaysRenderInput,
      icon,
      taskIsEditing,
      bulk,
      currentFilter,
      taskListView,
      taskEditProperty,
      isTaskCompleted,
      projectDetailView,
      isTaskModal,
      selectedValue,
      showRangeOption,
      calendarElStyle
    } = this.props;

    const value = selectedValue
      ? makeDateFromDateTime(selectedValue)
      : undefined;
    const pickerProps = {
      onChange: this.handleChange,
      getCalendarContainer: this.getCalendarContainer,
      disabled: false,
      placement: 'bottomRight',
      calendarElStyle,
      style: {
        width: 'auto'
      }
    };

    const calendar = (
      <Calendar
        prefixCls={prefixCls}
        className={cn('rc-container-arrow-container', {
          'range-option': showRangeOption
        })}
        showOk
        renderFooter={this.renderFooter}
        onChange={this.handleCalendarChange}
        locale={enUS}
        showDateInput={false}
      />
    );
    const renderDefaultInput = () => (
      <span style={{ margin: 0 }}>
        <TaskPickerInput
          taskIsEditing={taskIsEditing}
          taskIsCreating={taskIsCreating}
          icon={icon}
          selectedDate={value}
          taskDateType={taskDateType}
          alwaysRenderInput={alwaysRenderInput}
          bulk={bulk}
          currentFilter={currentFilter}
          taskEditProperty={taskEditProperty}
          isTaskCompleted={isTaskCompleted}
          projectDetailView={projectDetailView}
          isTaskModal={isTaskModal}
        />
      </span>
    );

    const renderInput = () =>
      renderCustomInput ? renderCustomInput() : renderDefaultInput();

    return (
      <PickerWrapper
        className="rc-container-picker-container schedule-container"
        taskListView={taskListView}
      >
        <Picker
          {...pickerProps}
          value={value}
          calendar={calendar}
          getCalendarContainer={this.getContainerInstance}
          prefixCls={`${prefixCls}-picker-container`}
        >
          {renderInput}
        </Picker>
      </PickerWrapper>
    );
  }
}

export default DatePicker;
