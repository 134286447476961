import { useRef, useEffect, useCallback, useMemo } from 'react';
import { List, StaticListRef } from 'components/List';
import useNestedCollapse from 'appUtils/hooks/useNestedCollapse/useNestedCollapse';
import { UseSimpleStaticListArgs } from './types';
import { flattenList, defaultGetItemId } from './utils';

/**
 * A hook that contains repeatable logic for a minimal static list
 * Example:
 * - Project Team Builder Modal
 * -> Project Team (ProjectTeamListBody.tsx)
 * -> Project Unassigned Roles Requirements List (UnassigneRoleListBody.tsx)
 */
const useSimpleStaticList = ({
  itemHeight = 50,
  initialListBuilderArgs,
  renderItem,
  useInitialListBuilder,
  getItemId = defaultGetItemId
}: UseSimpleStaticListArgs<typeof initialListBuilderArgs>) => {
  const listRef = useRef<StaticListRef>(null);

  useEffect(() => {
    listRef.current?.resetHeightsAfterIndex();
  }, []);

  const collapseCallback = useCallback(() => {
    if (listRef.current) {
      listRef.current?.resetHeightsAfterIndex();
    }
  }, []);

  const {
    setParentCollapseState,
    getIsOpen,
    getCurrentParentCollapseState,
    toggleCollapse,
    toggleCollapseAll
  } = useNestedCollapse({
    toggleCallback: collapseCallback
  });

  const listBuilder = useInitialListBuilder({
    setParentCollapseState,
    getIsOpen,
    getCurrentParentCollapseState,
    toggleCollapse,
    toggleCollapseAll,
    ...initialListBuilderArgs
  });

  const list = useMemo(() => listBuilder(), [listBuilder]);

  const items = useMemo(() => flattenList(list), [list]);

  useEffect(() => {
    listRef.current?.resetHeightsAfterIndex();
  }, [items]);

  return {
    List: (
      <List
        getItemId={getItemId}
        items={items}
        ref={listRef}
        renderItem={renderItem}
        itemHeight={itemHeight}
        listClassName={'main-list'}
      />
    )
  };
};

export default useSimpleStaticList;
