import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembershipsByAccountId, getCurrentUserId } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import MemberCircles from 'components/members/MemberCircles';
import cn from 'classnames';
import moment from 'moment';
import { ACTION_TYPES } from 'appConstants/actionDescription';

const emptyObj = {};

const WorkplanRequestActivityLogCard = ({ userActivity }) => {
  const currentUserId = useAppSelector(getCurrentUserId);
  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const { actor, display_data = emptyObj } = userActivity;
  const { note, requested_assignee_account_id, start_date, end_date } =
    display_data;
  const actingMember = teamMembershipsByAccountId[actor?.id];
  const actionText = getActionText(userActivity.action_type);

  return (
    <ActivityLogContainer>
      {/* Header */}
      <ActivityLogHeader>
        {actingMember && (
          <MemberInitials
            size="medium"
            member={actingMember}
            classes={cn('item-account selected', {
              'logged-member-no-hover':
                actingMember?.account.id === currentUserId,
              'regular-member-no-hover':
                actingMember?.account.id !== currentUserId
            })}
            idx={'idx-placeholder'}
            isOnTaskModal
          />
        )}
        <ActionDetails>
          <ActorName>{actingMember?.account.name}</ActorName>
          <Timestamp>{`${makeTimestamp(userActivity.timestamp)}${
            actionText ? `, ${actionText}` : ''
          }`}</Timestamp>
        </ActionDetails>
      </ActivityLogHeader>

      {/* Snapshot */}
      <DataSnapshotContainer>
        {/* Notes */}
        <DataSection>
          <SectionTitle>NOTES</SectionTitle>
          <DataSnapshot>{note}</DataSnapshot>
        </DataSection>

        {/* Dates */}
        <DataSection>
          <SectionTitle>REQUESTED DATES</SectionTitle>
          <DataSnapshot>
            {start_date &&
              end_date &&
              `${formatDate(start_date)} - ${formatDate(end_date)}`}
          </DataSnapshot>
        </DataSection>

        {/* Assignees */}
        <DataSection>
          <SectionTitle>ASSIGNEE</SectionTitle>
          <DataSnapshot>
            <MemberCircles
              accountIds={[requested_assignee_account_id]}
              maxVisible="all"
              isExpanded
            />
          </DataSnapshot>
        </DataSection>
      </DataSnapshotContainer>
    </ActivityLogContainer>
  );
};

export default WorkplanRequestActivityLogCard;

/* ------------------------------------ - ----------------------------------- */

const formatDate = (date) => moment(date).format('MM/DD/YY');

const getActionText = (actionType) => {
  const entityLabel = 'Work Plan Request';
  const actionTypeToActionText = {
    [ACTION_TYPES.TASK_CREATE]: `created ${entityLabel}`,
    [ACTION_TYPES.TASK_EDIT_DESCRIPTION]: `edited ${entityLabel} description`,
    [ACTION_TYPES.TASK_EDIT_NOTE]: `edited ${entityLabel} note`,
    [ACTION_TYPES.TASK_CHANGE_ACTIVITY_PHASE]: `updated ${entityLabel} location`,
    [ACTION_TYPES.TASK_APPROVE_REQUEST]: `approved ${entityLabel}`,
    [ACTION_TYPES.TASK_ASSIGN]: `assigned ${entityLabel}`,
    [ACTION_TYPES.TASK_SET_SCHEDULE_DATE]: `updated ${entityLabel} schedule`,
    [ACTION_TYPES.TASK_CHANGE_REQUEST_APPROVER]: `updated ${entityLabel} approver`
  };
  return actionTypeToActionText[actionType] || `updated ${entityLabel}`;
};

const makeTimestamp = (timestamp) =>
  moment(timestamp).calendar(null, {
    lastDay: '[Yesterday]',
    lastWeek: 'ddd M/D/YY', // override default last week handling
    sameElse: 'ddd M/D/YY'
  });

const DataSnapshotContainer = styled.div`
  padding-left: 50px;
`;

const SectionTitle = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
  margin-bottom: 5px;
`;

const DataSection = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 14px;
  margin-top: 12px;
`;

const DataSnapshot = styled.div`
  min-height: 20px;
`;

const ActorName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

const Timestamp = styled.div`
  font-size: 13px;
`;

const ActionDetails = styled.div`
  color: ${theme.colors.colorMediumGray9};
  margin-left: 18px;
  transform: translateY(2px);
`;

const ActivityLogHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ActivityLogContainer = styled.div`
  margin-bottom: 27px;
`;
