import useMultiLevelDropdown from 'components/MultiLevelDropdown/useMultiLevelDropdown';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PhaseTargetDependableItemRenderer } from './MenuRenderers/PhaseTargetDependableItemRenderer';
import { DependencyTagList } from './DependencyTagList';
import theme from 'theme';
import {
  getDefaultDependencyTagString,
  getDependencyBaseMenuContent,
  getDependencyDeltaTypeMenuContent,
  getDependencyTargetMenuContent,
  getMultiOptionDependencyTagsContent,
  validateNewDependencies
} from './utils';
import {
  HandleChangeDeltaDays,
  HandleDependencyTagClick,
  HandleClickTargetMenu,
  TargetDependableItemRenderer,
  DependencyItem,
  HandleClickBaseMenu,
  HandleClickDeltaTypeMenu,
  HandleUpdateDependencyOnClick,
  DependableItem,
  CommonDependencyItem,
  UseDependencyFormStateReturnType
} from '../types';
import uniqBy from 'lodash/uniqBy';
import {
  dependencyAction,
  dependencyDeltaType,
  dependencyItemTypeStrings,
  dependencyProperty,
  dependencyType,
  dependencyTypeStrings
} from '../constants';
import keyBy from 'lodash/keyBy';

export const MultiOptionDependency = ({
  dependencyFormState,
  dependableItems,
  renderTargetDependableItemMenu = PhaseTargetDependableItemRenderer,
  availableBaseTypeOptions = [dependencyType.start, dependencyType.end],
  availableActionOptions = [
    dependencyAction.blocking,
    dependencyAction.waiting,
    dependencyAction.matches
  ],
  availableDeltaTypeOptions = [
    dependencyDeltaType.atLeast,
    dependencyDeltaType.upTo
  ]
}: {
  dependencyFormState: UseDependencyFormStateReturnType;
  dependableItems: DependableItem[];
  renderTargetDependableItemMenu?: TargetDependableItemRenderer;
  availableBaseTypeOptions?: NonNullable<CommonDependencyItem['baseType']>[];
  availableActionOptions?: NonNullable<CommonDependencyItem['action']>[];
  availableDeltaTypeOptions?: NonNullable<CommonDependencyItem['deltaType']>[];
}) => {
  const { dependencyById, addDependency, updateDependency, removeDependency } =
    dependencyFormState;

  const [editingDependencyId, setEditingDependencyId] =
    useState<Nullable<string>>(null);

  const {
    openDropdown: openBaseDropdown,
    closeDropdown: closeBaseDropdown,
    MultiLevelDropdown: BaseMultiLevelDropdown,
    dropdownRef: baseDropdownRef
  } = useMultiLevelDropdown();

  const {
    openDropdown: openTargetDropdown,
    closeDropdown: closeTargetDropdown,
    MultiLevelDropdown: TargetMultiLevelDropdown,
    dropdownRef: targetDropdownRef
  } = useMultiLevelDropdown();

  const {
    openDropdown: openDeltaTypeDropdown,
    closeDropdown: closeDeltaTypeDropdown,
    MultiLevelDropdown: DeltaTypeDropdown,
    dropdownRef: deltaTypeDropdownRef
  } = useMultiLevelDropdown();

  const handleUpdateDependency: HandleUpdateDependencyOnClick = useCallback(
    ({ updateProps }) => {
      if (!editingDependencyId || !dependencyById[editingDependencyId]) return;

      const editingDependencyItem = {
        ...dependencyById[editingDependencyId],
        ...updateProps
      } as DependencyItem;

      updateDependency({
        dependencyId: editingDependencyId,
        item: editingDependencyItem
      });
    },
    [dependencyById, editingDependencyId, updateDependency]
  );

  const handleClickBaseMenu: HandleClickBaseMenu = useCallback(
    ({ baseType, action }) => {
      handleUpdateDependency({
        updateProps: {
          baseType,
          action
        }
      });

      closeBaseDropdown();
    },
    [handleUpdateDependency, closeBaseDropdown]
  );

  const handleClickTargetMenu: HandleClickTargetMenu = useCallback(
    ({ dependableItem, targetType }) => {
      handleUpdateDependency({
        updateProps: {
          targetItemId: dependableItem.id,
          targetType,
          targetItemType: dependableItem.itemType
        }
      });

      closeTargetDropdown();
    },
    [closeTargetDropdown, handleUpdateDependency]
  );

  const handleClickDeltaTypeMenu: HandleClickDeltaTypeMenu = useCallback(
    ({ deltaType }) => {
      handleUpdateDependency({
        updateProps: {
          deltaType
        }
      });

      closeDeltaTypeDropdown();
    },
    [closeDeltaTypeDropdown, handleUpdateDependency]
  );

  const handleChangeDeltaDays: HandleChangeDeltaDays = ({ value }) => {
    handleUpdateDependency({
      updateProps: {
        deltaDays: value
      }
    });
  };

  const handleBaseDependencyTagClick: HandleDependencyTagClick = ({
    e,
    dependencyId
  }) => {
    baseDropdownRef.current = e.currentTarget;
    openBaseDropdown();
    setEditingDependencyId(dependencyId);
  };

  const handleTargetDependencyTagClick: HandleDependencyTagClick = ({
    e,
    dependencyId
  }) => {
    targetDropdownRef.current = e.currentTarget;
    openTargetDropdown();
    setEditingDependencyId(dependencyId);
  };

  const handleDeltaTypeDependencyTagClick: HandleDependencyTagClick = ({
    e,
    dependencyId
  }) => {
    deltaTypeDropdownRef.current = e.currentTarget;
    openDeltaTypeDropdown();
    setEditingDependencyId(dependencyId);
  };

  const handleDeltaDayDependencyTagClick: HandleDependencyTagClick = ({
    e,
    dependencyId
  }) => {
    setEditingDependencyId(dependencyId);
  };

  const dependencyTagOnClickMap = {
    handleBaseDependencyTagClick,
    handleTargetDependencyTagClick,
    handleDeltaTypeDependencyTagClick,
    handleDeltaDayDependencyTagClick
  };

  const dependencyTagOnChangeMap = {
    handleChangeDeltaDays
  };

  const getSelectedDependencyPropertyValue = useCallback(
    ({
      dependencyPropertyToCheck
    }: {
      dependencyPropertyToCheck: keyof DependencyItem;
    }) => {
      const editingDependency = editingDependencyId
        ? dependencyById[editingDependencyId] ?? {}
        : {};

      return editingDependency[dependencyPropertyToCheck];
    },
    [dependencyById, editingDependencyId]
  );

  const dependencyBaseMenuContent = useMemo(
    () =>
      getDependencyBaseMenuContent({
        availableBaseTypeOptions,
        availableActionOptions,
        selectedBaseTypeOption: getSelectedDependencyPropertyValue({
          dependencyPropertyToCheck: dependencyProperty.baseType
        }),
        selectedActionOption: getSelectedDependencyPropertyValue({
          dependencyPropertyToCheck: dependencyProperty.action
        })
      }),
    [
      availableActionOptions,
      availableBaseTypeOptions,
      getSelectedDependencyPropertyValue
    ]
  );

  const dependencyTargetMenuContent = useMemo(
    () =>
      getDependencyTargetMenuContent({
        availableTargetItemOptions: dependableItems,
        availableTargetTypeOptions: [
          dependencyType.start,
          dependencyType.end,
          ...(editingDependencyId &&
          dependencyById[editingDependencyId]?.action ===
            dependencyAction.matches
            ? [dependencyType.startEnd]
            : [])
        ],
        selectedTargetItemId: getSelectedDependencyPropertyValue({
          dependencyPropertyToCheck: dependencyProperty.targetItemId
        })?.toString(),
        selectedTargetTypeOption: getSelectedDependencyPropertyValue({
          dependencyPropertyToCheck: dependencyProperty.targetType
        }),
        targetDependableItemRenderer: renderTargetDependableItemMenu,
        handleClickTargetMenu
      }),
    [
      dependableItems,
      dependencyById,
      editingDependencyId,
      getSelectedDependencyPropertyValue,
      handleClickTargetMenu,
      renderTargetDependableItemMenu
    ]
  );

  const dependencyDeltaTypeMenuContent = useMemo(
    () =>
      getDependencyDeltaTypeMenuContent({
        availableDeltaTypeOptions,
        selectedDeltaTypeOption: getSelectedDependencyPropertyValue({
          dependencyPropertyToCheck: dependencyProperty.deltaType
        })
      }),
    [availableDeltaTypeOptions, getSelectedDependencyPropertyValue]
  );

  const dependencyBaseMenuOnClickHash = useMemo(() => {
    return {
      [dependencyType.start + dependencyAction.blocking]: () =>
        handleClickBaseMenu({
          baseType: dependencyType.start,
          action: dependencyAction.blocking
        }),
      [dependencyType.start + dependencyAction.waiting]: () =>
        handleClickBaseMenu({
          baseType: dependencyType.start,
          action: dependencyAction.waiting
        }),
      [dependencyType.start + dependencyAction.matches]: () =>
        handleClickBaseMenu({
          baseType: dependencyType.start,
          action: dependencyAction.matches
        }),
      [dependencyType.end + dependencyAction.blocking]: () =>
        handleClickBaseMenu({
          baseType: dependencyType.end,
          action: dependencyAction.blocking
        }),
      [dependencyType.end + dependencyAction.waiting]: () =>
        handleClickBaseMenu({
          baseType: dependencyType.end,
          action: dependencyAction.waiting
        }),
      [dependencyType.end + dependencyAction.matches]: () =>
        handleClickBaseMenu({
          baseType: dependencyType.end,
          action: dependencyAction.matches
        })
    };
  }, [handleClickBaseMenu]);

  const dependencyDeltaTypeMenuOnClickHash = useMemo(() => {
    return {
      [dependencyDeltaType.atLeast]: () =>
        handleClickDeltaTypeMenu({
          deltaType: dependencyDeltaType.atLeast
        }),
      [dependencyDeltaType.upTo]: () =>
        handleClickDeltaTypeMenu({
          deltaType: dependencyDeltaType.upTo
        })
    };
  }, [handleClickDeltaTypeMenu]);

  /* Generate string of all item types in the dependableItems array.
  Ex: This is used to show Select Milesone/Phase */
  const defaultDependableItemTypesString = useMemo(() => {
    const availableOptions = uniqBy(dependableItems, 'itemType')
      .map((item) => dependencyItemTypeStrings[item.itemType])
      .sort();

    return getDefaultDependencyTagString({ availableOptions });
  }, [dependableItems]);

  const defaultBaseTypeString = useMemo(() => {
    const availableOptions = availableBaseTypeOptions.map(
      (availableBaseOption) => dependencyTypeStrings[availableBaseOption]
    );
    return getDefaultDependencyTagString({
      availableOptions
    });
  }, [availableBaseTypeOptions]);

  const dependableItemsById = useMemo(
    () => keyBy(dependableItems, 'id'),
    [dependableItems]
  );

  const popoverProps = {
    className: 'dependency-list-popover',
    insideAnotherPopover: true
  };

  const newDependenciesValid = useMemo(
    () =>
      validateNewDependencies({
        dependencies: Object.values(dependencyById)
      }),
    [dependencyById]
  );

  const shouldShowAddButton = newDependenciesValid;

  return (
    <MultiOptionDependencyContainer>
      {Object.values(dependencyById).map((dependency, index) => {
        const dependencyTagsContent = getMultiOptionDependencyTagsContent({
          dependency,
          dependableItemsById,
          defaultDependableItemTypesString,
          defaultBaseTypeString,
          onClickMap: dependencyTagOnClickMap,
          onChangeMap: dependencyTagOnChangeMap
        });
        return (
          <DependencyTagList
            key={dependency.dependencyId ?? index}
            dependencyTagsContent={dependencyTagsContent}
          />
        );
      })}
      {shouldShowAddButton && (
        <AddButtonContainer onClick={addDependency}>
          <TextButton>+ Add</TextButton>
        </AddButtonContainer>
      )}
      <BaseMultiLevelDropdown
        menuContent={dependencyBaseMenuContent}
        onClickHash={dependencyBaseMenuOnClickHash}
        containerCustomStyle={dropdownContainerCustomStyle}
        popoverProps={popoverProps}
      />
      <TargetMultiLevelDropdown
        menuContent={dependencyTargetMenuContent}
        containerCustomStyle={dropdownContainerCustomStyle}
        popoverProps={popoverProps}
      />
      <DeltaTypeDropdown
        menuContent={dependencyDeltaTypeMenuContent}
        onClickHash={dependencyDeltaTypeMenuOnClickHash}
        containerCustomStyle={dropdownContainerCustomStyle}
        popoverProps={popoverProps}
      />
    </MultiOptionDependencyContainer>
  );
};

const MultiOptionDependencyContainer = styled.div``;

const AddButtonContainer = styled.div``;

const BaseTransparentButton = styled.button`
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
`;

export const TextButton = styled(BaseTransparentButton)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.colorLightGray15 : theme.colors.colorCalendarBlue};

  &:hover {
    font-weight: 600;
    letter-spacing: -0.2px;
  }
`;

const dropdownContainerCustomStyle = `
  .menu-item {
    font-size: 13px;

    &:not(.disabled) {
      &:hover {
        background: ${theme.colors.colorTranslucentGray4};
      }
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
      color: ${theme.colors.colorLightGray15};
    }
  }
  .nested:hover > .nested-dropdown {
    left: 101%;
  }
`;
