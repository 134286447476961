import * as appConstants from 'appConstants';
import * as constants from '../constants';
import createIntegrationReducer from './createIntegrationReducer';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import { makeIdHash } from 'appUtils';

const byId = (item) => item.uuid;

export const initialState = {
  integrationActivities: {},
  activitiesByMappingStatus: {},
  mappedMosaicIds: {},
  selectedActivities: {},
  rowStates: {},
  counts: {},
  isLoading: false,
  isSearchLoading: false,
  initiallyLoaded: false,
  mappingSteps: {},
  // Used to correct fetch offsets when selected ids are held at the top of import
  // list (which may or may not belong given the fetch params)
  offsetAdjustment: 0
};
export const activities = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_INTEGRATION_ACTIVITIES.TRIGGER: {
      const {
        body: { initial }
      } = action.payload;
      const nextState = { ...state, isLoading: true };

      // Reset selected ids and list mapping steps when initial fetch (except for import list)
      if (initial) {
        const nextRowStates = { ...state.rowStates };
        const nextSelectedActivities = { ...state.selectedActivities };
        Object.keys(state.selectedActivities)
          .filter((list) => list !== constants.MEMBERS_LISTS.FROM_INTEGRATION)
          .forEach((list) => {
            state.selectedActivities[list].forEach((uuid) => {
              delete nextRowStates[uuid];
            });
            delete nextSelectedActivities[list];
          });
        nextState.selectedActivities = nextSelectedActivities;
        nextState.rowStates = nextRowStates;
        nextState.mappingSteps = {
          [constants.MEMBERS_LISTS.FROM_INTEGRATION]:
            state.mappingSteps[constants.MEMBERS_LISTS.FROM_INTEGRATION]
        };
      }

      return nextState;
    }
    case constants.FETCH_INTEGRATION_ACTIVITIES.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const {
        body: { offset, limit, initial }
      } = requestPayload;
      const { entities, count } = response;

      const hasSelectedIds = Object.values(state.selectedActivities).some(
        (list) => list.length
      );

      const newEntities = keyBy(entities, byId);

      const groupedByMappingStatus = groupBy(
        entities.map(byId),
        (id) => newEntities[id].mappingStatus
      );

      let nextActivitiesByMappingStatus = groupedByMappingStatus;

      // Adding to the lists of activities (by mapping status) on page fetches
      // or if selected IDs exist (keep them at top of list)
      if (!initial || hasSelectedIds) {
        // Technically at the moment only the import list will keep selected activities through
        // initial fetches
        const selectedIdsHash = Object.values(state.selectedActivities).reduce(
          (acc, list) => {
            list.forEach((id) => (acc[id] = true));
            return acc;
          },
          {}
        );
        nextActivitiesByMappingStatus = { ...state.activitiesByMappingStatus };
        Object.keys(groupedByMappingStatus).forEach((mappingStatus) => {
          nextActivitiesByMappingStatus[mappingStatus] = Array.from(
            new Set([
              // Keep selected ids on filter changes
              ...((hasSelectedIds && initial
                ? state.activitiesByMappingStatus[mappingStatus]?.filter(
                    (id) => selectedIdsHash[id]
                  )
                : state.activitiesByMappingStatus[mappingStatus]) || []),
              ...groupedByMappingStatus[mappingStatus]
            ])
          );
        });
      }

      let nextOffsetAdjustment = state.offsetAdjustment;
      if (hasSelectedIds) {
        // Count the number of selected activities that are going to be kept in the list
        // that don't belong (didn't come from the fetch) - this number will be used
        // for correcting future fetch offsets
        if (initial) {
          let numDontBelong = 0;
          (
            state.selectedActivities[
              constants.MEMBERS_LISTS.FROM_INTEGRATION
            ] || []
          ).forEach((id) => {
            if (!newEntities[id]) {
              numDontBelong = numDontBelong + 1;
            }
          });
          nextOffsetAdjustment = numDontBelong;
        } else {
          // When not initial, we need to decrement offsetAdjustment if a selected activity
          // is in this fetch's response, since it now belongs in the list
          (
            state.selectedActivities[
              constants.MEMBERS_LISTS.FROM_INTEGRATION
            ] || []
          ).forEach((id) => {
            if (newEntities[id]) {
              nextOffsetAdjustment = nextOffsetAdjustment - 1;
            }
          });
        }
      } else {
        nextOffsetAdjustment = 0;
      }

      return {
        ...state,
        integrationActivities:
          initial && !hasSelectedIds
            ? newEntities
            : { ...state.integrationActivities, ...newEntities },
        activitiesByMappingStatus: nextActivitiesByMappingStatus,
        isLoading: false,
        initiallyLoaded: true,
        isSearchLoading: false,
        counts: initial ? count : state.counts,
        offsetAdjustment: nextOffsetAdjustment
      };
    }
    case constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        mappedMosaicIds: makeIdHash(
          [
            ...(response[constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_TARGET] || []),
            ...(response[constants.MAPPING_STATUS.ACTIVE] || [])
          ].filter((id) => id)
        )
      };
    }
    case constants.BATCH_SELECT_INTEGRATION_ACTIVITIES: {
      const { value, integration_ids, section } = payload;
      return {
        ...state,
        selectedActivities: {
          // ...state.selectedActivities,
          [section]: {
            ...state.selectedActivities[section],
            ...integration_ids.reduce((acc, cur) => {
              acc[cur] = value;
              return acc;
            }, {})
          }
        }
      };
    }
    case constants.CLEAR_BATCH_SELECTED_INTEGRATION_ACTIVITIES: {
      return {
        ...state,
        selectedActivities: {},
        rowStates: {}
      };
    }
    case constants.SET_SELECTED_INTEGRATION_ACTIVITIES: {
      const { ids, section, rowState, isSelected, isBatch } = payload;

      const getDefaultRowState = (id) => {
        const integrationActivity = state.integrationActivities[id];

        if (integrationActivity) {
          return {
            mosaicId: integrationActivity.mosaicId,
            name: `${integrationActivity.targetData?.title || ''}`
          };
        }
        return {};
      };

      // Deselect - clear relevant row states and filter out the ids
      if (!isSelected) {
        const toRemoveIds = new Set(ids);
        const nextSelectedActivities = {
          ...state.selectedActivities,
          [section]: (state.selectedActivities[section] || []).filter(
            (id) => !toRemoveIds.has(id)
          )
        };
        const nextRowStates = { ...state.rowStates };
        ids.forEach((id) => delete nextRowStates[id]);
        return {
          ...state,
          selectedActivities: nextSelectedActivities,
          rowStates: nextRowStates
        };
      }

      return {
        ...state,
        selectedActivities: {
          // ...state.selectedActivities, <- if we allow selection in different sections
          [section]: [
            ...(isBatch && state.selectedActivities[section]
              ? state.selectedActivities[section]
              : []),
            ...ids
          ]
        },
        rowStates: {
          ...(isBatch && { ...state.rowStates }),
          ...ids.reduce((acc, cur) => {
            acc[cur] = rowState || getDefaultRowState(cur);
            return acc;
          }, {})
        }
      };
    }

    case constants.SET_ACTIVITY_ROW_STATE: {
      return {
        ...state,
        rowStates: {
          ...state.rowStates,
          [payload.id]: {
            ...state.rowStates[payload.id],
            ...payload.rowState
          }
        }
      };
    }

    case constants.SET_ACTIVITY_MAPPING_STEP: {
      return {
        ...state,
        mappingSteps: {
          // ...state.mappingSteps,
          [payload.section]: payload.mappingStep
        }
      };
    }
    case constants.CLEAR_ACTIVITIES_MAPPING_STEPS: {
      return {
        ...state,
        mappingSteps: {}
      };
    }

    case constants.SET_INTEGRATION_ACTIVITIES_SEARCH_LOADING: {
      return {
        ...state,
        isSearchLoading: payload.value
      };
    }

    case constants.MAP_ACTIVITIES.TRIGGER: {
      const { body } = action.payload;

      const nextIntegrationActivities = { ...state.integrationActivities };

      body.forEach((activity) => {
        const { mappingStatus, uuid, mosaicData } = activity;
        nextIntegrationActivities[uuid] = {
          ...nextIntegrationActivities[uuid],
          mappingStatus:
            mappingStatus === constants.MAPPING_STATUS.NULL
              ? constants.MAPPING_STATUS.NULL
              : constants.MAPPING_STATUS.PENDING,
          ...(mosaicData && { mosaicData })
        };
      });

      return {
        ...state,
        integrationActivities: nextIntegrationActivities
      };
    }
    case constants.MAP_ACTIVITIES.SUCCESS: {
      const updatedActivity = action.payload.response;
      const { uuid, original, isArchived } = action.payload.requestPayload;
      // Update the hash
      const nextIntegrationActivities = { ...state.integrationActivities };
      nextIntegrationActivities[updatedActivity.uuid] = updatedActivity;
      // Update the counts
      const nextCounts = {
        ...state.counts,
        mappingStatus: {
          ...state.counts.mappingStatus
        }
      };
      nextCounts.mappingStatus[original.mappingStatus]--;
      nextCounts.mappingStatus[updatedActivity.mappingStatus] =
        (+nextCounts.mappingStatus[updatedActivity.mappingStatus] || 0) + 1;

      const wasLinked = constants.ACTIVE_MAPPING_STATUSES.includes(
        original.mappingStatus
      );
      const wasNotLinked = !constants.ACTIVE_MAPPING_STATUSES.includes(
        original.mappingStatus
      );
      const noLongerLinked = !constants.ACTIVE_MAPPING_STATUSES.includes(
        updatedActivity.mappingStatus
      );
      const isNowLinked = constants.ACTIVE_MAPPING_STATUSES.includes(
        updatedActivity.mappingStatus
      );

      if ((wasLinked && noLongerLinked) || (wasNotLinked && isNowLinked)) {
        const list = isArchived ? 'mosaicIsArchived' : 'mosaicIsNotArchived';
        nextCounts[constants.MAPPING_STATUS.ACTIVE] = {
          ...state.counts[constants.MAPPING_STATUS.ACTIVE],
          [list]:
            +(state.counts[constants.MAPPING_STATUS.ACTIVE][list] || 0) +
            (wasLinked ? -1 : 1)
        };
      }

      return {
        ...state,
        integrationActivities: nextIntegrationActivities,
        // Update the orders if necessary (mapping status is different)
        ...(original.mappingStatus !== updatedActivity.mappingStatus && {
          activitiesByMappingStatus: {
            ...state.activitiesByMappingStatus,
            // Remove from original order
            [original.mappingStatus]: (
              state.activitiesByMappingStatus[original.mappingStatus] || []
            ).filter((activityId) => activityId !== uuid),
            // Add to new order
            [updatedActivity.mappingStatus]: [
              updatedActivity.uuid,
              ...(state.activitiesByMappingStatus[
                updatedActivity.mappingStatus
              ] || [])
            ]
          }
        }),
        counts: nextCounts,
        offsetAdjustment:
          original.mappingStatus === null
            ? Math.max(0, state.offsetAdjustment - 1)
            : state.offsetAdjustment
      };
    }
    case constants.MAP_ACTIVITIES.FAILURE: {
      const { uuid, original } = action.payload.requestPayload;

      return {
        ...state,
        integrationActivities: {
          ...state.integrationActivities,
          [uuid]: original
        }
      };
    }

    case constants.SET_INTEGRATION_ACTIVITIES_COUNTS: {
      return {
        ...state,
        counts: action.payload.counts
      };
    }

    default: {
      return state;
    }
  }
};

export default createIntegrationReducer(activities, initialState);
