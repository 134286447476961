import { useState, useCallback } from 'react';
import { SettingTableTemplate } from './SettingTableTemplate';
import { GroupData, GroupedTableTemplateProps } from './types';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';

export const selectMembersOriginMap = {
  header: 'header',
  footer: 'footer'
} as const;

export type GroupedSettingTableWithMemberDropdownProps<Data> =
  GroupedTableTemplateProps<Data> & {
    onSelectMembers: ({
      groupData,
      selectedMembers
    }: {
      groupData: GroupData;
      selectedMembers: Array<any>;
    }) => void;
  };

export const GroupedSettingTableWithMemberDropdown = <Data,>({
  useGroup,
  groupRowProps = {},
  onSelectMembers,
  ...restProps
}: GroupedSettingTableWithMemberDropdownProps<Data>) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>();
  const [activeGroup, setActiveGroup] = useState<GroupData | null>();

  const memberFilter = useCallback(
    (members) => {
      const accountIdsToFilter = new Set();

      restProps.rowData.forEach(({ teamMember, groupId }) => {
        if (activeGroup?.id === groupId) {
          accountIdsToFilter.add(teamMember.account.id);
        }
      });

      return members.filter(
        (member) => !accountIdsToFilter.has(member.account.id)
      );
    },
    [restProps.rowData, activeGroup]
  );

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleOpenMemberDropdown = ({
    event,
    groupData
  }: {
    event: any;
    groupData: GroupData;
  }) => {
    setAnchorElement(event.target);
    setActiveGroup(groupData);
  };

  const handleSelectMembers = (selectedMembers) => {
    if (activeGroup && selectedMembers.length) {
      onSelectMembers &&
        onSelectMembers({
          groupData: activeGroup,
          selectedMembers
        });
    }
    setActiveGroup(null);
  };

  return (
    <>
      <SettingTableTemplate
        useGroup
        groupRowProps={{
          ...groupRowProps,
          onGroupRowAddClick: ({ event, groupData }) =>
            handleOpenMemberDropdown({
              event,
              groupData
            })
        }}
        footerRowProps={{
          onFooterRowAddClick: (event, groupData) =>
            handleOpenMemberDropdown({
              event,
              groupData
            })
        }}
        {...restProps}
      />
      {Boolean(anchorElement) && (
        <BulkMemberSelector
          handleDone={handleSelectMembers}
          memberFilter={memberFilter}
          target={anchorElement}
          onClose={handleClose}
          shouldUseMemberFilter
          alwaysOpen
          renderSelect={() => null}
          hideFooter={true}
        />
      )}
    </>
  );
};
