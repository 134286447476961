import {
  OptionsDropdown,
  OptionHash
} from 'components/options/OptionsDropdownV2';
import { FilterField } from 'FilterModule/constants';
import { FilterListTypeDropdown } from 'FilterModule/types';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import invert from 'lodash/invert';

/**
 * Field will be treated as a boolean field.
 * ie. currentFilter[field] = true means unassigned roles, false means assigned roles, and null means all
 */
export const ProjectsWithUnassignedRolesFilterDropdown: FilterListTypeDropdown =
  ({ renderToggle, field = FilterField.isProjectsWithUnassignedRoles }) => {
    const { currentFilter } = useFilterContext();

    const handleSelect = (option: keyof typeof ROLES_OPTIONS) => {
      currentFilter.update({
        [field]: optionToValueTranslation[option]
      });
    };

    const currentFilterValue = currentFilter[field];

    if (
      currentFilterValue === null ||
      typeof currentFilterValue === 'boolean'
    ) {
      const translatedValue =
        valueToOptionTranslation[String(currentFilterValue)];

      return (
        <OptionsDropdown
          currentValue={translatedValue}
          options={options}
          optionHash={optionHash}
          renderToggle={({ isOpen }) =>
            renderToggle({
              isOpen,
              customLabel:
                translatedValue &&
                ((optionHash[translatedValue]?.toggleLabel ??
                  optionHash[translatedValue]?.label) as string)
            })
          }
          sharedHandleClick={handleSelect}
        />
      );
    }

    return <></>;
  };

/* ------------------------------------ - ----------------------------------- */

const ROLES_OPTIONS = {
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
  ALL: 'ALL'
} as const;

const optionToValueTranslation = {
  [ROLES_OPTIONS.UNASSIGNED]: true,
  [ROLES_OPTIONS.ASSIGNED]: false,
  [ROLES_OPTIONS.ALL]: null
};

const valueToOptionTranslation = invert(optionToValueTranslation);

const optionHash: OptionHash = {
  [ROLES_OPTIONS.ALL]: {
    label: 'All Roles',
    toggleLabel: 'All Roles'
  },
  [ROLES_OPTIONS.ASSIGNED]: {
    label: 'Assigned Members Only',
    toggleLabel: 'Assigned'
  },
  [ROLES_OPTIONS.UNASSIGNED]: {
    label: 'Unassigned Roles Only',
    toggleLabel: 'Unassigned'
  }
};

const options = Object.keys(ROLES_OPTIONS);
