import React from 'react';
// key provided by getHeaderGroupProps and getHeaderProps
/* eslint-disable react/jsx-key */
const TableFooter = ({ headerGroups }) => (
  <div>
    {headerGroups.map((headerGroup) => (
      <div {...headerGroup.getHeaderGroupProps()} className="tr">
        {headerGroup.headers.map((column) => (
          <div {...column.getHeaderProps()} className="th">
            {column.render('Footer')}
            {/* Use column.getResizerProps to hook up the events correctly */}
            <div
              {...column.getResizerProps()}
              className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
            />
          </div>
        ))}
      </div>
    ))}
  </div>
);

export default TableFooter;
