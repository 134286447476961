import React from 'react';
import cn from 'classnames';
import Highlighter from 'react-highlight-words';

const isTag = (word) => word[0] === '#' && word !== '#';

const displayTitle = (title, searchWords) => {
  const titleWordsArray = title.split(' ');
  const titleArray = [];

  titleWordsArray.forEach((word, index) => {
    const wordIsTag = isTag(word);
    const wordToPush = wordIsTag ? word.substring(1) : word;
    titleArray.push(
      <span key={index + 1} className={wordIsTag ? 'note-tag' : undefined}>
        {searchWords ? (
          <Highlighter
            highlightClassName="highlight"
            highlightTag="span"
            searchWords={searchWords}
            textToHighlight={wordToPush}
          />
        ) : (
          wordToPush
        )}
      </span>
    );
    titleArray.push(<span key={(index + 1) * -1}> </span>);
  });

  return titleArray;
};

const NoteTitleWithTags = React.memo(
  ({ title, style, onClick, className, searchWords }) => (
    <div
      className={cn('note-title', { [className]: className })}
      style={style}
      onClick={onClick}
    >
      {title ? displayTitle(title, searchWords) : 'Untitled'}
    </div>
  )
);

export default NoteTitleWithTags;
