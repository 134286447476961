import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';
import { ProjectTeamCollapseAllListItem } from 'SuggestionModule/components/FindPeople/List/ProjectTeam/types';
import CollapseAllToggle from 'BudgetModule/components/BudgetTable/shared/CollapseAllToggle';
import cn from 'classnames';

export const ProjectTeamCollapseAll = (
  collapseAllListItem: ProjectTeamCollapseAllListItem
) => {
  const { toggleCollapse, isAllCollapsed, numPhasesCount } =
    collapseAllListItem;

  // Spec: If this list has more than 1 visible phase, add a collapse all row
  const hasMoreThanMinimumPhaseThreshold = numPhasesCount > 1;

  return (
    <StyledItemContainer
      onClick={hasMoreThanMinimumPhaseThreshold ? toggleCollapse : undefined}
      className={cn('project-team-collapse-all', {
        'has-less-than-minimum-phase-threshold':
          !hasMoreThanMinimumPhaseThreshold
      })}
      data-testid="project-team-collapse-all-item-container"
    >
      {hasMoreThanMinimumPhaseThreshold ? (
        <CollapseAllToggle
          isHidden={undefined}
          allCollapsed={isAllCollapsed}
          toggle={undefined}
          top={0}
          left={0}
        />
      ) : (
        <></>
      )}
      <span>Project Team</span>
    </StyledItemContainer>
  );
};
