import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import styled from 'styled-components';
import theme from 'theme';

import { getIsOnPersonalProject } from 'selectors';
import {
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledDropdownMenuNonItem,
  StyledDropdownHeader,
  CheckIconContainer,
  StyledCheckIcon,
  StyledDropdown,
  StyledDropdownToggle
} from './styles';
import { StyledCalendarIcon } from 'ReportsModule/components/styles';
import {
  VIEW_BY,
  VIEW_BY_DISPLAY,
  DEFAULT_VIEW_BY_OPTIONS
} from 'appConstants/workload';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';

const { NONE } = VIEW_BY;

const defaultDisplay = {
  ...VIEW_BY_DISPLAY,
  true: { label: 'Weekly', value: true },
  false: { label: 'Daily', value: false }
};
const defaultOptions = DEFAULT_VIEW_BY_OPTIONS;
const defaultPersonalOptions = DEFAULT_VIEW_BY_OPTIONS.filter(
  (option) => option.value !== VIEW_BY.PHASES
);

const emptyObj = {};

class SortOptions extends React.Component {
  state = {
    open: false
  };

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  handleClick = (value, e) => {
    const { setViewBy, stopPropagation } = this.props;
    setViewBy({ viewBy: value });
    ReactTooltip.hide();

    if (stopPropagation) {
      e?.stopPropagation();
    }
  };

  renderOption = ({
    label,
    value,
    tooltip,
    isWarningTooltip,
    disabled,
    display,
    menuClassName,
    options,
    rebuildTip
  }) => {
    if (tooltip || rebuildTip) rebuildTooltip();

    const commonOptionProps = {
      $isDisabled: disabled,
      active: value === this.props.viewBy,
      key: value,
      style: value === this.props.viewBy ? this.props.selectedStyle : emptyObj,
      'data-for': 'app-tooltip',
      'data-tip': tooltip || '',
      'data-type': isWarningTooltip ? 'error' : undefined,
      'data-effect': 'solid',
      'data-html': true
    };
    const CheckIcon = (
      <StyledCheckIcon
        width="10"
        height="8"
        fill={theme.colors.colorSemiDarkGray1}
      />
    );
    if (options) {
      return (
        <StyledDropdownMenuNonItem
          onClick={(e) => {
            e.stopPropagation(); // dont close current dropdown
            // this.handleClick(value, e);
          }}
          {...commonOptionProps}
        >
          {!this.props.noCheck && (
            <CheckIconContainer
              isActive={
                value === this.props.viewBy ||
                options.some((option) => this.props.viewBy === option.value)
              }
            >
              {CheckIcon}
            </CheckIconContainer>
          )}
          <SortOptions
            viewBy={this.props.viewBy}
            setViewBy={({ viewBy }) => {
              this.handleClick(viewBy);
              this.toggle();
            }}
            options={options}
            display={display}
            renderToggle={() => label}
            showHeader={false}
            dropdownRight
            dropdownButtonStyle="width: 100%;"
            className="nested-dropdown"
            dropdownProps={{ direction: 'right' }}
            dropdownMenuProps={{}}
            left={0}
          />
        </StyledDropdownMenuNonItem>
      );
    }
    return (
      <StyledDropdownMenuItem
        onClick={(e) => (disabled ? null : this.handleClick(value, e))}
        className={menuClassName}
        {...commonOptionProps}
      >
        {!this.props.noCheck && (
          <CheckIconContainer isActive={value === this.props.viewBy}>
            {CheckIcon}
          </CheckIconContainer>
        )}
        {label}
      </StyledDropdownMenuItem>
    );
  };

  render() {
    const {
      viewBy,
      left = 205,
      dropdownButtonStyle = `position: absolute; left:${left}px; top: 40px;`,
      display = defaultDisplay,
      headerText = 'Group By',
      showHeader = true,
      renderToggle,
      className = '',
      buttonClassName = '',
      isOnPersonalProject,
      shouldDisplayRangeFormat,
      dropdownProps = emptyObj,
      dropdownMenuProps = emptyObj,
      isDisabled,
      dataTestId,
      unhideArrowOnHover,
      noBoldToggle
    } = this.props;
    const { open } = this.state;
    const options =
      this.props.options ||
      (isOnPersonalProject ? defaultPersonalOptions : defaultOptions);

    const label =
      display[viewBy]?.value === NONE ? 'Group By' : display[viewBy]?.label;

    const toggle = renderToggle && renderToggle();

    return (
      <StyledDropdown
        toggle={this.toggle}
        isOpen={open}
        $dropdownButtonStyle={dropdownButtonStyle}
        className={cn({ className, boldToggle: !noBoldToggle })}
        {...dropdownProps}
        $isDisabled={isDisabled}
        data-testid={dataTestId}
      >
        <StyledDropdownTogglee
          $unhideArrowOnHover={unhideArrowOnHover}
          className={buttonClassName}
        >
          {toggle ? (
            <div>
              {/*
               * We are rendering the toggle twice to account for the bold
               * style that is applied on hover. The first render is an element
               * that contains the toggle in a bold state. The element is
               * collapsed vertically and only contributes its width to the
               * parent container. The second render is the toggle visible to
               * the user.
               */}
              {!noBoldToggle && <BoldWidth>{toggle}</BoldWidth>}
              <Label>{toggle}</Label>
            </div>
          ) : (
            <StyledFilterOption>
              {(display[viewBy]?.value === 'custom' ||
                shouldDisplayRangeFormat) && (
                <StyledCalendarIcon height="12px" width="12px" />
              )}
              {typeof label === 'string' ? (
                <StyledBoldingLabel
                  className={cn({ boldToggle: !noBoldToggle })}
                >
                  {label}
                </StyledBoldingLabel>
              ) : (
                <div>
                  {/*
                   * We are rendering the toggle twice to account for the bold
                   * style that is applied on hover. The first render is an element
                   * that contains the toggle in a bold state. The element is
                   * collapsed vertically and only contributes its width to the
                   * parent container. The second render is the toggle visible to
                   * the user.
                   */}
                  {!noBoldToggle && <BoldWidth>{label}</BoldWidth>}
                  <Label>{label}</Label>
                </div>
              )}
              <FaAngleDown
                style={{ marginLeft: '-3px', marginTop: '2px' }}
                className={cn('down-arrow', {
                  active: open
                })}
              />
            </StyledFilterOption>
          )}
        </StyledDropdownTogglee>
        {/* default to right aligned, unless dropdown is on left edge of screen */}
        <StyledDropdownMenu right={left !== 0} {...dropdownMenuProps}>
          {showHeader && (
            <StyledDropdownHeader>{headerText}</StyledDropdownHeader>
          )}

          {options.map(this.renderOption)}
        </StyledDropdownMenu>
      </StyledDropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  isOnPersonalProject: getIsOnPersonalProject(state)
});

export default connect(mapStateToProps)(SortOptions);

const StyledFilterOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBoldingLabel = styled.span.attrs((props) => ({
  'aria-hidden': true,
  'data-label': props.children
}))`
  /*
   * Creates an invisible zero-height pseudo-element with the width
   * required for the hover style. This prevents the layout from
   * shifting on hover.
   */
  &.boldToggle::before {
    content: attr(data-label);
    display: block;
    height: 0;
    overflow: hidden;

    /* Hover style */
    font-weight: 600;

    /*
     * Prevents non-visual readers from reading the contents of the
     * ::before pseudo-selector.
     */
    @media speech {
      display: none;
    }
  }
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const BoldWidth = styled.div.attrs({ 'aria-hidden': true })`
  font-weight: 600;
  height: 0;
  overflow: hidden;
`;

const StyledDropdownTogglee = styled(StyledDropdownToggle)`
  &.boldToggle:hover {
    font-weight: 600;
  }

  ${(props) =>
    props.$unhideArrowOnHover &&
    `
    .down-arrow {
      visibility: hidden;
    }

    &:hover .down-arrow {
      visibility: visible;
    }
  `}
`;
