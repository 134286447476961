const WAYPOINT_INTS = {
  offsetAdditional: 200,
  notes: {
    baseLimit: 15
  },
  myProjects: {
    firstLoadBaseLimit: 15,
    baseLimit: 30
  },
  projects: {
    firstLoadBaseLimit: 15,
    baseLimit: 15
  },
  tasks: {
    baseLimit: 25
  },
  notifications: {
    baseLimit: 15
  }
};

export default WAYPOINT_INTS;
