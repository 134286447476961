import { createRequestTypes } from 'appUtils';
import { TaskId } from 'models/task';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createActionWithMeta } from 'reduxInfra/utils/createActionWithMeta';
import { WorkPlanId } from 'views/projectPlanner/WorkplanModal/models/workPlan';
import { ActivityPhaseScheduleBarTaskRelationship } from '../models/ActivityPhaseScheduleBarTaskRelationship';

/* -------------- update work plan-task association attributes -------------- */

export interface UpdateWorkPlanTasksParams {
  work_plan_task_updates: {
    activity_phase_schedule_bar_id: WorkPlanId;
    estimated_hours: Nullable<number>;
    task_id: TaskId;
  }[];
}

export interface UpdateWorkPlanTasksSuccessResponse {
  updates: ActivityPhaseScheduleBarTaskRelationship[];
  errors: string[];
}

export type UpdateWorkPlanTasksError = string;

export type UpdateWorkPlanTasksFailureResponse = Response;

export const UPDATE_WORK_PLAN_TASKS = createRequestTypes(
  'UPDATE_WORK_PLAN_TASK'
);

export const updateWorkPlanTasks =
  createActionWithMeta<UpdateWorkPlanTasksParams>(
    UPDATE_WORK_PLAN_TASKS.TRIGGER
  );

export const updateWorkPlanTasksApiActions = createActionCreatorsMap<
  ActionParamsWithMeta<UpdateWorkPlanTasksParams>,
  UpdateWorkPlanTasksSuccessResponse,
  IMeta,
  UpdateWorkPlanTasksError,
  UpdateWorkPlanTasksFailureResponse
>(UPDATE_WORK_PLAN_TASKS);
