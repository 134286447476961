import { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';
import { StyledKaratRight } from '../styles';
import {
  BaseFilterGroupList,
  FilterListsTableCustomRowProps,
  FilterListsTableVariant
} from '../types';
import { FilterListType } from 'FilterModule/constants';
import { PortfolioLabel } from './labels';

export const DefaultGroupLabelCell = ({
  row,
  customRowProps
}: {
  row: {
    original: BaseFilterGroupList;
  };
  customRowProps: FilterListsTableCustomRowProps;
}) => {
  const [isHidingCounts, setIsHidingCounts] = useState(false);

  const {
    filterListType,
    toggleCollapse,
    labelText,
    numSelectedItems,
    totalCount,
    isOpen
  } = row.original;

  const LabelComponent = filterListTypeToItemLabelComponent[filterListType];

  return (
    <StyledLabelCell
      onClick={toggleCollapse}
      tableVariant={customRowProps.tableConfig.variant}
      className="label-cell"
      shouldHideCountsOnHover={LabelComponent === PortfolioLabel}
    >
      <StyledLabel>
        {LabelComponent ? (
          <LabelComponent
            customRowProps={customRowProps}
            setIsHidingCounts={setIsHidingCounts}
            rowData={row.original}
          />
        ) : (
          <span className="no-text-overflow">{labelText}</span>
        )}
      </StyledLabel>

      <StyledIndicators>
        <StyledCount
          isHighlighted={numSelectedItems > 0}
          isHidden={isHidingCounts}
        >
          {numSelectedItems}/{totalCount}
        </StyledCount>

        <StyledKaratRight isOpen={isOpen} />
      </StyledIndicators>
    </StyledLabelCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const filterListTypeToItemLabelComponent = {
  [FilterListType.MembersByPortfolio]: PortfolioLabel,
  [FilterListType.ProjectsByPortfolio]: PortfolioLabel
};

const StyledIndicators = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const StyledCount = styled.span<{
  isHighlighted: boolean;
  isHidden: boolean;
}>`
  font-size: 12px;
  font-weight: 400;
  padding-right: 7px;
  padding-top: 1px;
  color: ${theme.colors.colorMediumGray5};

  ${(props) =>
    props.isHighlighted &&
    `
    color: ${theme.colors.colorCalendarBlue};
    font-size: 13px;
    font-weight: 600;
  `}

  ${({ isHidden }) => isHidden && `visibility: hidden;`}
`;

const StyledLabel = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }
`;

const StyledLabelCellStylesByTableVariant: Record<
  FilterListsTableVariant,
  ReturnType<typeof css>
> = {
  StackedFilters: css`
    font-size: 13px;
    color: ${theme.colors.colorSemiDarkGray1};
  `,

  SideFilter: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
    color: ${theme.colors.colorMediumGray9};
  `
};

const StyledLabelCell = styled.div<{
  tableVariant: FilterListsTableVariant;
  shouldHideCountsOnHover: boolean;
}>`
  // default styles
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 3px;

  .tr:hover & {
    color: ${theme.colors.colorRoyalBlue};
    cursor: pointer;
  }

  & > * {
    min-width: 0;
  }

  svg {
    flex-shrink: 0;
  }

  ${({ shouldHideCountsOnHover }) =>
    shouldHideCountsOnHover &&
    `
    &:hover ${StyledCount} {
      visibility: hidden;
    }`}

  /* table variant specific styles */
  ${({ tableVariant }) => StyledLabelCellStylesByTableVariant[tableVariant]}
`;
