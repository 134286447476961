import React from 'react';
import { connect } from 'react-redux';
import {
  updateTaskPriority,
  deleteTaskPriority,
  createTaskPriority
} from 'actionCreators';
import { getMatchedRouteParams } from 'selectors';
import { withRouter } from 'react-router-dom';
import OptionsSelectFlyoutListItem from 'components/OptionsSelectFlyout/OptionsSelectFlyoutListItem';
import { StyledTaskPriorityContainer, StyledTaskPriorityText } from './styles';
import { StyledPriorityIcon } from '../TaskList/styles';
import theme from 'theme';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
const staticNewPriority = {
  title: 'New Priority',
  color: '#cccccc',
  id: 'new'
};

const unselectPriority = {
  title: 'No Priority',
  color: '#DEDEDE',
  id: null
};

const taskPriorityCopy = {
  inputPlaceholder: 'New Status',
  addHeader: 'Add Status',
  editHeader: 'Edit Status'
};

class TaskPriorityListItem extends React.Component {
  handleSave = (updatedState) => {
    const {
      createTaskPriority,
      updateTaskPriority,
      handleEditClear,
      listItem,
      isNew,
      matchedParams: { projectId }
    } = this.props;
    const payload = {
      ...listItem,
      project_id: projectId,
      ...updatedState
    };
    const apiCall = isNew ? createTaskPriority : updateTaskPriority;
    apiCall(payload);
    handleEditClear();
  };

  renderListItem = (item) => (
    <StyledTaskPriorityContainer>
      {item === unselectPriority ? (
        <StyledPriorityIcon
          stroke={theme.colors.colorMediumGray1}
          currentColor="none"
        />
      ) : (
        <StyledPriorityIcon currentColor={item.color} />
      )}
      <StyledTaskPriorityText>{item.title}</StyledTaskPriorityText>
    </StyledTaskPriorityContainer>
  );

  render() {
    const { deleteTaskPriority, isUnselect } = this.props;
    return (
      <OptionsSelectFlyoutListItem
        handleSave={this.handleSave}
        handleDelete={deleteTaskPriority}
        colorPickerOriginType={ORIGIN_TYPE_STRINGS.TASK_PRIORITY}
        staticNewListItem={isUnselect ? unselectPriority : staticNewPriority}
        copy={taskPriorityCopy}
        {...this.props}
        renderListItem={this.renderListItem}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateTaskPriority,
  deleteTaskPriority,
  createTaskPriority
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskPriorityListItem)
);
