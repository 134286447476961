import React from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { closeModulesModal } from 'actionCreators';
import theme from 'theme';
import { getModulesModalIsOpen } from 'selectors';
import ModuleOrderableList from '../ViewFactory/ModuleOrderableList';

const StyledModalHeader = styled(ModalHeader)`
  h5 {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    flex: auto;
  }
`;
const StyledModalBody = styled(ModalBody)`
  padding: 15px 0px;
`;
const CloseModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  color: ${theme.colors.colorPureWhite};
  font-weight: 400;
  font-size: 12px;
  background: ${theme.colors.colorRoyalBlue};

  &:hover {
    background: ${theme.colors.colorDeeperRoyalBlue};
  }
`;
class PaymentInfoModal extends React.Component {
  state = {};

  toggle = () => {
    const { closeModulesModal } = this.props;
    closeModulesModal();
  };

  render() {
    const { isOpen, moduleType } = this.props;

    return (
      <div>
        <Modal isOpen={isOpen} toggle={this.toggle} className="modules-modal">
          <StyledModalHeader>
            Add Views
            <CloseModal onClick={this.toggle}>Done</CloseModal>
          </StyledModalHeader>
          <StyledModalBody>
            <ModuleOrderableList isOpen={isOpen} moduleType={moduleType} />
          </StyledModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: getModulesModalIsOpen(state)
});

const mapDispatchToProps = { closeModulesModal };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoModal);
