import SvgIcon from 'components/SvgIcon';

const AddPhaseIcon = ({
  fill = '#0074d9',
  width = '15',
  height = '15',
  className
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 15 15"
    color="none"
  >
    <g clipPath="url(#clip0_3363:102)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8.00155L6.99857 1.5L1 7.99845L7.00143 14.5L13 8.00155Z"
        stroke="#4F4F4F"
        strokeWidth="1.2"
      />
      <rect
        x="7"
        y="5.5"
        width="0"
        height="5"
        fill="#4F4F4F"
        stroke="#4F4F4F"
      />
      <rect
        x="7"
        y="6"
        height="4"
        width="0.1"
        fill="#4F4F4F"
        stroke="#4F4F4F"
      />
      <line x1="9" y1="8" x2="5" y2="8" stroke="#4F4F4F" />
    </g>
    <defs>
      <clipPath id="clip0_3363:102">
        <rect
          width="14"
          height="15"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default AddPhaseIcon;
