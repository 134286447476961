import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import {
  EMPLOYMENT_TYPES,
  EMPLOYMENT_TYPES_ARRAY
} from 'PermissionsModule/constants';
import { ValueOf } from 'type-fest';

const emptyObj = {};

const labelHash: Partial<Record<ValueOf<typeof EMPLOYMENT_TYPES>, string>> = {
  [EMPLOYMENT_TYPES.externalProjectContractor]: 'Project Contractor - External',
  [EMPLOYMENT_TYPES.internalContractor]: 'Contractor - Internal',
  [EMPLOYMENT_TYPES.member]: 'Member of Mosaic'
};

const options = EMPLOYMENT_TYPES_ARRAY.filter(
  (type) => type !== EMPLOYMENT_TYPES.projectGuest
);

const itemHash = options.reduce((acc, cur) => {
  acc[cur] = {
    label: labelHash[cur],
    type: cur
  };
  return acc;
}, {});

export const useEmploymentTypeFilter: FilterListTypeHookWithItemHash = ({
  isOff,
  resultCountHash,
  shouldUseDraft,
  field = FilterField.employmentType
} = emptyObj) => {
  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: options,
    itemHash,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    labelKey,
    shouldUseDraft
  });

  return {
    ...arrayFilterFieldValues,
    itemHash,
    labelKey,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'label';
