import React from 'react';
import SvgIcon from 'components/SvgIcon';

const WorkCategoryIcon = ({
  height = '14',
  width = '14',
  color = '#4F4F4F',
  style
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="7" cy="7" r="6" stroke={color} strokeWidth="1.2" />
      <path
        d="M13 6.9999H7.05C7.02239 6.9999 7 6.97752 7 6.9499V1"
        stroke={color}
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};

export default WorkCategoryIcon;
