import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isNumeric } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportRate
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingRatesGroupedByRateAmount: Record<string, CsvImportRate[]>;
  existingRatesByRateDescription: Record<string, CsvImportRate>;
  newRateAmounts: React.MutableRefObject<Record<string, boolean>>;
  newRateDescriptionAmountHashes: React.MutableRefObject<
    Record<string, string>
  >;
}

export const rateAmountValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingRatesGroupedByRateAmount,
    existingRatesByRateDescription,
    newRateAmounts,
    newRateDescriptionAmountHashes
  } = additionalParams;

  const { enteredRateAmount, enteredRateDescription } =
    getRowValuesForEntityType(row, entityType);

  if (enteredRateAmount === TRAINING_DATA_REQUIRED_STRING) return false;
  // check if entered string is numeric
  if (enteredRateAmount && !isNumeric(enteredRateAmount))
    return ERROR_MESSAGES.invalidRateAmount;

  const enteredRateAmountIsNewOption =
    enteredRateAmount &&
    !existingRatesGroupedByRateAmount[enteredRateAmount] &&
    !newRateAmounts.current[enteredRateAmount];
  if (
    enteredRateDescription &&
    enteredRateDescription !== TRAINING_DATA_REQUIRED_STRING
  ) {
    if (!enteredRateAmount) return ERROR_MESSAGES.rateAmountRequired;
    else {
      // check if enteredRateAmount matches to existing one with enteredRateDescription
      const existingRateByEnteredRateDescription =
        existingRatesByRateDescription[enteredRateDescription];
      const newRateByEnteredRateDescription =
        newRateDescriptionAmountHashes.current[enteredRateDescription];
      if (
        existingRateByEnteredRateDescription === undefined &&
        newRateByEnteredRateDescription === undefined
      ) {
        // entered rate description is new => make enteredRateAmount + enteredRateDescription new combination is being handled in rate description validator.
        return true;
      } else if (
        // entered rate description is not new, check if enteredRateAmount matches
        existingRateByEnteredRateDescription?.rate === enteredRateAmount ||
        newRateByEnteredRateDescription === enteredRateAmount
      )
        return true;
      else return ERROR_MESSAGES.rateDoesNotMatchDescription;
    }
  } else if (
    // no description entered, add entered rate amount as new option if it was not an option before.
    enteredRateAmountIsNewOption
  )
    newRateAmounts.current[enteredRateAmount] = true;
  return true;
};
