import { lazy, Suspense } from 'react';
import styled from 'styled-components';

const StandardCapacityContainer = lazy(
  () => import('./StandardCapacityTable/StandardCapacityTableContainer')
);

export const StandardCapacityTab = () => {
  return (
    <RootContainer>
      <Suspense fallback={<div />}>
        <StandardCapacityContainer />
      </Suspense>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  padding-left: 32px;
  flex: 1;
  min-height: 0px;
`;
