import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { ScrollContainer } from './styles';
import CommentsSection from './CommentsSection';
import ActivityLogSection from './ActivityLogSection';

/**
 * Comments / Activity container
 * Currently entity can be task or scope
 */
const ModalSideBody = ({ entity }) => {
  const [currentTab, setCurrentTab] = useState(TABS.COMMENTS);

  return (
    <PaddingContainer>
      <SideBodyContainer>
        {/* Nav */}
        <TabNav>
          <TabNavButton
            isActive={currentTab === TABS.COMMENTS}
            onClick={() => setCurrentTab(TABS.COMMENTS)}
          >
            Comments
          </TabNavButton>
          <TabNavButton
            isActive={currentTab === TABS.ACTIVITY}
            onClick={() => setCurrentTab(TABS.ACTIVITY)}
          >
            Activity
          </TabNavButton>
        </TabNav>

        <ScrollContainer backgroundColor="transparent">
          {currentTab === TABS.COMMENTS ? (
            /* Comments */
            <CommentsSection entity={entity} noTitle />
          ) : (
            /* Activity log */
            <ActivityLogSection />
          )}
        </ScrollContainer>
      </SideBodyContainer>
    </PaddingContainer>
  );
};

export default ModalSideBody;

/* ------------------------------------ - ----------------------------------- */

const TABS = {
  COMMENTS: 'COMMENTS',
  ACTIVITY: 'ACTIVITY'
};

const TabNavButton = styled.button`
  border: none;
  background: none;
  padding: 1px 3px;
  margin-right: 10px;
  font-size: 18px;
  color: ${theme.colors.colorMediumGray5};
  border-bottom: 2px solid transparent;

  ${(props) =>
    props.isActive &&
    `
    font-weight: 700;
    color: ${theme.colors.colorSemiDarkGray1};
    border-color: ${theme.colors.colorRoyalBlue};
  `}
`;

const TabNav = styled.div`
  display: flex;
  padding-left: 30px;
`;

const SideBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.colorLightGray19};
  padding-top: 20px;
  height: 100%;

  ${ScrollContainer} {
    padding: 0;
    padding-left: 30px;
    padding-right: 20px;
    height: 100%;
  }

  .comments-container,
  .scroll-box {
    background: transparent;
  }
  .comments-container {
    .comment-row,
    .comment-input-container {
      background-color: white;
    }
    .reply-comment-container .comment-input-container {
      background-color: initial;
    }
  }
`;

const PaddingContainer = styled.div`
  padding-right: 30px;
  padding-bottom: 30px;
`;
