import { useAppSelector } from 'reduxInfra/hooks';
import { getSortedWorkGroupIdsArray } from 'selectors';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Department } from 'SettingsModule/models/department';
import { useDepartments } from 'SettingsModule/hooks';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';

const emptyArray = [];
const emptyObj = {};

export const useDepartmentsFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.work_group_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const { isLoaded, departmentHash, handleNavToDepartmentsPage } =
    useDepartments({ isOff });

  /** Array of alphabetically sorted department ids */
  const departmentIdsArray: number[] = useAppSelector((state) =>
    isOff ? emptyArray : getSortedWorkGroupIdsArray(state)
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: departmentIdsArray,
    itemHash: departmentHash,
    isOff,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    config,
    shouldUseDraft,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    isLoading: !isLoaded || isResultsLoading,
    itemHash: departmentHash,
    labelKey,
    handleNavToEntityPage: handleNavToDepartmentsPage,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const searchableKeys: (keyof Department)[] = ['name'];
const labelKey = 'name';
