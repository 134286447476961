import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type ContractorIconProps = Pick<SvgIconProps, 'width' | 'height'>;

const ContractorIcon = ({ width = 14, height = 14 }: ContractorIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 14 14" fill="none">
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        fill="white"
        stroke="#CCCCCC"
        strokeWidth="0.8"
      />
      <path
        d="M7.24219 5.01855C6.95247 5.01855 6.69531 5.07552 6.4707 5.18945C6.24935 5.30013 6.06217 5.46126 5.90918 5.67285C5.75944 5.88444 5.64551 6.13997 5.56738 6.43945C5.48926 6.73893 5.4502 7.07585 5.4502 7.4502C5.4502 7.95475 5.51204 8.38607 5.63574 8.74414C5.7627 9.09896 5.95801 9.37077 6.22168 9.55957C6.48535 9.74512 6.82552 9.83789 7.24219 9.83789C7.5319 9.83789 7.82161 9.80534 8.11133 9.74023C8.4043 9.67513 8.72168 9.58236 9.06348 9.46191V10.7314C8.74772 10.8617 8.43685 10.9544 8.13086 11.0098C7.82487 11.0684 7.48145 11.0977 7.10059 11.0977C6.36491 11.0977 5.75944 10.9463 5.28418 10.6436C4.81217 10.3376 4.46224 9.91113 4.23438 9.36426C4.00651 8.81413 3.89258 8.17285 3.89258 7.44043C3.89258 6.90007 3.96582 6.40527 4.1123 5.95605C4.25879 5.50684 4.47363 5.11784 4.75684 4.78906C5.04004 4.46029 5.38997 4.20638 5.80664 4.02734C6.22331 3.84831 6.70182 3.75879 7.24219 3.75879C7.59701 3.75879 7.95182 3.80436 8.30664 3.89551C8.66471 3.9834 9.00651 4.10547 9.33203 4.26172L8.84375 5.49219C8.57682 5.36523 8.30827 5.25456 8.03809 5.16016C7.7679 5.06576 7.5026 5.01855 7.24219 5.01855Z"
        fill="#828282"
      />
    </SvgIcon>
  );
};

export default ContractorIcon;
