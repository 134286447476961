import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { makeGetCapacityReportDataByFilter } from 'CapacityModule/selectors';
import { GENERATE_CAPACITY_RANGE } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
import WorkloadWidgetPieChart from '../WorkloadWidgetPieChart';
import withFilter from 'hocs/withFilter';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { WidgetChartContainer } from '../../../styles';

const emptyObj = {};
const emptyArray = [];

const WorkloadWidgetTwo = ({
  activeFilter,
  widgetConfig,
  isDashboardWidget
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const teamId = useSelector(getSelectedTeamId);
  const getCapacityReportDataByFilter1 = useMemo(
    () => makeGetCapacityReportDataByFilter(),
    []
  );
  const getCapacityReportDataByFilter2 = useMemo(
    () => makeGetCapacityReportDataByFilter(),
    []
  );
  const isFirstFetching = useSelector(
    (state) =>
      getCapacityReportDataByFilter1(state, {
        filterStateId: `${activeFilter.id}--first-range`
      }).isFetching
  );
  const isSecondFetching = useSelector(
    (state) =>
      getCapacityReportDataByFilter2(state, {
        filterStateId: `${activeFilter.id}--second-range`
      }).isFetching
  );

  const sharedFetchParams = useMemo(
    () => ({
      account_ids: activeFilter.account_ids || emptyArray,
      team_id: teamId,
      data_type: 'Team'
    }),
    [activeFilter.account_ids, teamId]
  );

  // Next 7 days
  const fetchParams1 = useMemo(
    () => ({
      filterStateId: `${activeFilter.id}--first-range`,
      params: {
        ...sharedFetchParams,
        ...GENERATE_CAPACITY_RANGE[ZOOM_LEVELS.WEEK]()
      }
    }),
    [activeFilter.id, sharedFetchParams]
  );

  // Next 14 days
  const fetchParams2 = useMemo(
    () => ({
      filterStateId: `${activeFilter.id}--second-range`,
      params: {
        ...sharedFetchParams,
        ...GENERATE_CAPACITY_RANGE[ZOOM_LEVELS.TWO_WEEKS]()
      }
    }),
    [activeFilter.id, sharedFetchParams]
  );

  useEffect(() => {
    if (!isFirstFetching && !isSecondFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFirstFetching, isSecondFetching]);

  const isLoading =
    (!hasInitiallyLoaded || isFirstFetching || isSecondFetching) &&
    isDashboardWidget;

  return (
    <DynamicModuleLoader modules={[getCapacitiesModule()]}>
      <div className="workload-widget-wrapper has-custom-description">
        {isLoading && (
          <SkeletonLoader
            numLoaders={1}
            style={{
              margin: 0,
              position: 'relative',
              ...(widgetConfig
                ? {
                    width: '100%',
                    marginTop: '-18px'
                  }
                : {
                    paddingLeft: 12,
                    width: '97px',
                    right: '6px',
                    height: '28px'
                  })
            }}
            loaderStyle={{ height: 200, rx: 4 }}
          />
        )}
        <WidgetChartContainer display={isLoading ? 'none' : 'flex'}>
          <WorkloadWidgetPieChart
            header="This Week"
            fetchParams={fetchParams1}
          />
          <WorkloadWidgetPieChart
            header="Next Week"
            fetchParams={fetchParams2}
          />
        </WidgetChartContainer>
      </div>
    </DynamicModuleLoader>
  );
};

export default withFilter(WorkloadWidgetTwo);
