import {
  SUCCESS_SUFFIX,
  FAILURE_SUFFIX,
  ABORT_SUFFIX,
  REQUEST_SUFFIX
} from './constants';
import { REQUEST_TYPES } from 'appUtils';

export const getActionWithSuffix = (suffix) => (actionType) =>
  actionType.replace('_TRIGGER', '') + suffix;

export const success = getActionWithSuffix(SUCCESS_SUFFIX);
export const failure = getActionWithSuffix(FAILURE_SUFFIX);
export const abort = getActionWithSuffix(ABORT_SUFFIX);
export const request = getActionWithSuffix(REQUEST_SUFFIX);

export const getIsSuccessAction = (actionType) =>
  actionType.endsWith(SUCCESS_SUFFIX);

export const getIsFailureAction = (actionType) =>
  actionType.endsWith(FAILURE_SUFFIX);

export const getIsRequestAction = (actionType) =>
  actionType.endsWith(REQUEST_SUFFIX);

export const getIsAbortAction = (actionType) =>
  actionType.endsWith(ABORT_SUFFIX);

export const getIsTriggerAction = (actionType) =>
  actionType.endsWith('_TRIGGER');

export const getActionRequestType = (actionType) => {
  const splitActionType = actionType.split('_');
  const requestType = splitActionType[splitActionType.length - 1];
  return REQUEST_TYPES[requestType] || null;
};

export const makeFetchRequestStatusId = (id) => `fetch-${id}`;
