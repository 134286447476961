import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import difference from 'lodash/difference';

// returns keys that are both present and has defined values
// use lodash/pick if nullish values are not a problem
export const pickDefinedKeysFromObject = <T>({
  keys,
  object
}: {
  keys: string[];
  object: T;
}) => {
  return omitBy(pick(object, keys), isNil);
};

export const getAddedRemovedIds = <T>({
  originalList,
  newList
}: {
  originalList: T[];
  newList: T[];
}): T[][] => {
  const idsToAdd = difference(newList, originalList);
  const idsToRemove = difference(originalList, newList);

  return [idsToAdd, idsToRemove];
};

// returns an array of keys that are either undefined or null from object
// it checks if all the required values are present
export const checkRequiredKeys = <T>({
  keys,
  object
}: {
  keys: string;
  object: T;
}): string[] => {
  const pickedObject = pick(object, keys);
  const missingValues = Object.keys(pickBy(pickedObject, isNil));

  return missingValues;
};
