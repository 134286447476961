import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import Popover from 'components/Popover';
import NumberFormat from 'react-number-format';
import { blurOnEnter } from 'appUtils/userInteractions';

// 0, 10, 20, ..., 100
const options = [...Array(11)].map((_, index) => index * 10);

const ProgressPillWithSelector = ({
  currentPercentage = 0,
  hasSelector = true,
  testId,
  onUpdatePercentage,
  isReadOnly,
  progressColor,
  shouldRound
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isEnteringCustom, setIsEnteringCustom] = useState(false);
  const [customPercentage, setCustomPercentage] = useState('');
  const customInputRef = useRef(null);
  const pillRef = useRef(null);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    if (isEnteringCustom) {
      setIsEnteringCustom(false);
      setCustomPercentage('');
    }
  };

  const renderDropdown = () => {
    const handleUpdatePercentage = (newPercentage) => {
      if (newPercentage !== +currentPercentage && onUpdatePercentage) {
        onUpdatePercentage(newPercentage);
      }
      closeDropdown();
    };

    const handleCustomPercentageChange = (values) => {
      setCustomPercentage(values.value);
    };

    const handleCustomPercentageSubmit = () => {
      handleUpdatePercentage(customPercentage);
      setIsEnteringCustom(false);
      setCustomPercentage('');
    };

    return (
      <Popover
        isOpen
        closePopover={closeDropdown}
        target={pillRef}
        boundariesElement="window"
      >
        <StyledDropdown>
          <CustomInputContainer>
            {isEnteringCustom ? (
              <StyledCustomPercentageInput
                data-testid={`${testId}-custom-%-input`}
                value={customPercentage}
                placeholder={'0%'}
                onValueChange={handleCustomPercentageChange}
                getInputRef={(ref) => (customInputRef.current = ref)}
                onKeyDown={blurOnEnter(customInputRef)}
                onBlur={handleCustomPercentageSubmit}
                suffix="%"
                allowNegative={false}
                thousandSeparator={true}
                isNumericString
                allowLeadingZeros={false}
                autoFocus
              />
            ) : (
              <StyledAddCustomButton onClick={() => setIsEnteringCustom(true)}>
                Custom
              </StyledAddCustomButton>
            )}
          </CustomInputContainer>
          {options.map((percentage) => (
            <StyledPill
              key={percentage}
              percentage={percentage}
              onClick={() => handleUpdatePercentage(percentage)}
            />
          ))}
        </StyledDropdown>
      </Popover>
    );
  };

  return (
    <>
      <StyledPill
        className="progress-pill"
        data-testid={`progress-pill-${testId}`}
        ref={pillRef}
        onClick={hasSelector ? () => setIsDropdownOpen(true) : null}
        percentage={
          shouldRound ? Math.round(currentPercentage) : currentPercentage
        }
        percentageColor={
          !currentPercentage ? theme.colors.colorMediumGray1 : undefined
        }
        progressColor={progressColor || theme.colors.colorPaleBlue6}
        isReadOnly={isReadOnly}
      />
      {isDropdownOpen && renderDropdown()}
    </>
  );
};

export default ProgressPillWithSelector;

/* ------------------------------------ - ----------------------------------- */
/* ------------------------------------ - ----------------------------------- */

const StyledDropdown = styled.div`
  max-width: fit-content;
  height: 235px;
  overflow: auto;
  padding: 20px 25px 25px;
  & > *:not(:last-child, :first-child) {
    margin-bottom: 14px;
  }
`;

const StyledPill = styled.div`
  height: 20px;
  width: 50px;
  border-radius: 54px;
  background: white;
  border: 1px solid ${theme.colors.colorLightGray12};
  font-size: 14px;
  color: ${(props) => props.percentageColor || theme.colors.colorMediumGray7};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.isReadOnly && 'pointer-events: none;'}

  &:hover {
    border-color: ${theme.colors.colorRoyalBlue};
  }

  // progress
  &:before {
    position: absolute;
    left: 0;
    content: '';
    border-bottom-left-radius: 54px;
    border-top-left-radius: 54px;
    width: ${(props) => props.percentage || 0}%;
    background-image: repeating-linear-gradient(
      103deg,
      transparent 2px,
      transparent 3px,
      ${(props) => props.progressColor} 5px,
      ${(props) => props.progressColor} 6px
    );
    height: 100%;
  }

  // percentage
  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: '${(props) => props.percentage || 0}%';
    z-index: 1;
  }
`;

/* ----------------------------- Add custom row ----------------------------- */

const StyledAddCustomButton = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledCustomPercentageInput = styled(NumberFormat)`
  width: 100%;
  height: 100%;
  justify-self: end;
  text-align: right;
  outline: none !important;
  border: 1px solid transparent;
  color: ${theme.colors.colorMediumGray9};
  &:focus {
    border-color: ${theme.colors.colorRoyalBlue};
  }
`;

const CustomInputContainer = styled.div`
  height: 25px;
  margin-bottom: 10px;
`;
