import type {
  LeanApiSchema,
  FetchLeanApiPayload,
  OrderBy
} from 'LeanApiModule/types';
import {
  FIELD_NAMES,
  FIELD_RENAME_MAP,
  ORDER_BY
} from 'LeanApiModule/constants';
import { SortDirection } from 'FilterModule/constants';
import { deserializeSkillLevel } from 'SettingsModule/utils/skills';
import set from 'lodash/set';
import merge from 'lodash/merge';
import { getFilterParamValues } from '..';

// =============================================================================
//                                   WHERE
// =============================================================================

export const defaultTeamMembershipWhereClauses = {
  /**
   * Filters out members' historical positions and archived positions
   */
  position: {
    [FIELD_NAMES.team_positions]: {
      [FIELD_NAMES.discarded_at]: null,
      [FIELD_NAMES.end_date]: null
    }
  },
  department: {
    [FIELD_NAMES.work_group_memberships]: {
      [FIELD_NAMES.discarded_at]: null,
      [FIELD_NAMES.work_group]: {
        [FIELD_NAMES.discarded_at]: null
      }
    }
  },
  skill: {
    [FIELD_NAMES.team_member_skills]: {
      [FIELD_NAMES.discarded_at]: null
    }
  },
  board: {
    [FIELD_NAMES.board_memberships]: {
      [FIELD_NAMES.discarded_at]: null
    }
  }
};

const addTeamMembershipFilterParams = ({
  filterParams,
  where
}: {
  filterParams?: FetchLeanApiPayload['filterParams'];
  where: NonNullable<LeanApiSchema['where']>;
}) => {
  if (filterParams) {
    const {
      positionIds,
      skillIds,
      skillLevels,
      regionIds,
      departmentIds,
      officeIds,
      disciplineIds,
      boardIds,
      employmentTypes
    } = getFilterParamValues({
      filterParams
    });

    // always include default values if the param exists

    if (departmentIds) {
      set(where, FIELD_NAMES.work_group_memberships, {
        ...defaultTeamMembershipWhereClauses.department[
          FIELD_NAMES.work_group_memberships
        ],
        ...(departmentIds.length && {
          [FIELD_NAMES.work_group]: {
            [FIELD_NAMES.id_in]: departmentIds
          }
        })
      });
    }

    if (positionIds) {
      set(where, FIELD_NAMES.team_positions, {
        ...defaultTeamMembershipWhereClauses.position[
          FIELD_NAMES.team_positions
        ],
        ...(positionIds.length && {
          [FIELD_NAMES.position]: {
            [FIELD_NAMES.id_in]: positionIds
          }
        })
      });
    }

    if (skillIds) {
      set(where, FIELD_NAMES.team_member_skills, {
        ...defaultTeamMembershipWhereClauses.skill[
          FIELD_NAMES.team_member_skills
        ],
        ...(skillIds.length && {
          [FIELD_NAMES.team_skill]: {
            [FIELD_NAMES.id_in]: skillIds
          }
        })
      });
    }

    // note: this will overwrite skillIds filter (currently no use case where both skillIds and skillLevels
    // filter will be used together)
    if (skillLevels?.length) {
      set(where, FIELD_NAMES.team_member_skills, {
        ...defaultTeamMembershipWhereClauses.skill[
          FIELD_NAMES.team_member_skills
        ],
        or: skillLevels.map((skillLevel) => {
          const { skillId, level } = deserializeSkillLevel(skillLevel);

          return {
            [FIELD_NAMES.team_skill]: {
              [FIELD_NAMES.id]: skillId
            },
            level
          };
        })
      });
    }

    if (regionIds?.length) {
      set(where, FIELD_NAMES.region_entities, {
        [FIELD_NAMES.region]: {
          [FIELD_NAMES.id_in]: regionIds
        }
      });
    }

    if (officeIds?.length) {
      set(where, FIELD_NAMES.office_entities, {
        [FIELD_NAMES.office]: {
          [FIELD_NAMES.id_in]: officeIds
        }
      });
    }

    if (disciplineIds?.length) {
      set(where, FIELD_NAMES.discipline_entities, {
        [FIELD_NAMES.discipline]: {
          [FIELD_NAMES.id_in]: disciplineIds
        }
      });
    }

    if (boardIds) {
      set(where, FIELD_NAMES.board_memberships, {
        ...defaultTeamMembershipWhereClauses.board[
          FIELD_NAMES.board_memberships
        ],
        ...(boardIds.length && {
          [FIELD_NAMES.board]: {
            [FIELD_NAMES.id_in]: boardIds
          }
        })
      });
    }

    if (employmentTypes?.length) {
      set(where, FIELD_NAMES.employment_type_in, employmentTypes);
    }
  }
};

const addTeamMembershipAccountNameSearchParam = ({
  searchText,
  where
}: {
  searchText?: string;
  where: NonNullable<LeanApiSchema['where']>;
}) => {
  if (searchText) {
    set(where, FIELD_NAMES.account, {
      [FIELD_NAMES.name_like]: `%${searchText}%`
    });
  }
};

/**
 * Returns the WHERE clause for a team_membership entity schema using the given filterParams and searchText
 */
export const buildTeamMembershipWhereClause = ({
  filterParams,
  searchText,
  initialValue
}: {
  filterParams?: FetchLeanApiPayload['filterParams'];
  searchText?: string;
  initialValue?: LeanApiSchema['where'];
}) => {
  let where: LeanApiSchema['where'] = {};

  if (searchText || filterParams) {
    where = {};
    addTeamMembershipAccountNameSearchParam({ searchText, where });
    addTeamMembershipFilterParams({ filterParams, where });
  }

  if (initialValue) {
    merge(where, initialValue);
  }

  return where;
};

// =============================================================================
//                                   SELECT
// =============================================================================

export const teamMembershipSelectClauses = {
  positionId: {
    [FIELD_NAMES.team_positions]: {
      [FIELD_NAMES.position]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.position_id
      }
    }
  },
  accountId: {
    [FIELD_NAMES.account]: {
      [FIELD_NAMES.id]: FIELD_RENAME_MAP.account_id
    }
  },
  employmentType: {
    [FIELD_NAMES.employment_type]: FIELD_NAMES.employment_type
  },
  departmentId: {
    [FIELD_NAMES.work_group_memberships]: {
      [FIELD_NAMES.work_group]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.department_id
      }
    }
  },
  skillId: {
    [FIELD_NAMES.team_member_skills]: {
      [FIELD_NAMES.team_skill]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.skill_id
      }
    }
  },
  regionId: {
    [FIELD_NAMES.region_entities]: {
      [FIELD_NAMES.region]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.region_id
      }
    }
  },
  officeId: {
    [FIELD_NAMES.office_entities]: {
      [FIELD_NAMES.office]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.office_id
      }
    }
  },
  disciplineId: {
    [FIELD_NAMES.discipline_entities]: {
      [FIELD_NAMES.discipline]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.discipline_id
      }
    }
  },
  boardId: {
    [FIELD_NAMES.board_memberships]: {
      [FIELD_NAMES.board]: {
        [FIELD_NAMES.id]: FIELD_RENAME_MAP.board_id
      }
    }
  }
};

/**
 * Returns the SELECT clause for a team_membership entity schema using the given filterParams
 */
export const buildTeamMembershipSelectClause = ({
  filterParams,
  initialValue
}: {
  filterParams?: FetchLeanApiPayload['filterParams'];
  initialValue?: LeanApiSchema['select'];
}) => {
  let select: LeanApiSchema['select'] = teamMembershipSelectClauses.accountId;

  if (filterParams) {
    const {
      positionIds,
      skillIds,
      regionIds,
      departmentIds,
      disciplineIds,
      officeIds,
      boardIds,
      employmentTypes
    } = getFilterParamValues({
      filterParams
    });

    if (departmentIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.departmentId
      };
    }

    if (positionIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.positionId
      };
    }

    if (skillIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.skillId
      };
    }

    if (regionIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.regionId
      };
    }

    if (disciplineIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.disciplineId
      };
    }

    if (officeIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.officeId
      };
    }

    if (boardIds) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.boardId
      };
    }

    if (employmentTypes) {
      select = {
        ...select,
        ...teamMembershipSelectClauses.employmentType
      };
    }
  }

  if (initialValue) {
    merge(select, initialValue);
  }

  return select;
};

// =============================================================================
//                                   ORDER
// =============================================================================

export const formatTeamMembershipOrderClause = ({
  orderBy,
  direction
}: {
  orderBy: OrderBy;
  direction: SortDirection;
}) => {
  const clauses: Partial<Record<OrderBy, LeanApiSchema['order']>> = {
    [ORDER_BY.POSITION_NAME]: {
      [FIELD_NAMES.team_positions]: {
        [FIELD_NAMES.position]: {
          [FIELD_NAMES.name]: direction
        }
      }
    },
    [ORDER_BY.DEFAULT_POSITION]: {
      [FIELD_NAMES.team_positions]: {
        [FIELD_NAMES.position]: {
          [FIELD_NAMES.is_default]: direction
        }
      }
    },
    [ORDER_BY.TEAM_MEMBER_ARCHIVED]: {
      [FIELD_NAMES.discarded_at]: direction
    },
    [ORDER_BY.ACCOUNT_NAME]: {
      [FIELD_NAMES.account]: {
        [FIELD_NAMES.name]: direction
      }
    },
    [ORDER_BY.DEPARTMENT_NAME]: {
      [FIELD_NAMES.work_group_memberships]: {
        [FIELD_NAMES.work_group]: {
          [FIELD_NAMES.name]: direction
        }
      }
    },
    [ORDER_BY.SKILL_NAME]: {
      [FIELD_NAMES.team_member_skills]: {
        [FIELD_NAMES.team_skill]: {
          [FIELD_NAMES.name]: direction
        }
      }
    }
  };
  return clauses[orderBy];
};

/**
 * Returns the ORDER clause for a team_membership entity schema
 */
export const buildTeamMembershipOrderClause = ({
  initialValue
}: {
  initialValue?: LeanApiSchema['order'];
} = {}) => {
  const order: LeanApiSchema['order'] = {
    ...initialValue,
    ...formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.TEAM_MEMBER_ARCHIVED,
      direction: SortDirection.desc
    }),
    ...formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.ACCOUNT_NAME,
      direction: SortDirection.asc
    })
  };

  return order;
};
