import { useMemo, useCallback, useContext, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useAppDispatch } from 'reduxInfra/hooks';
import { StackedFiltersContainer } from 'FilterModule/components/FilterListsTable/StackedFiltersContainer';
import { useMeasure } from 'react-use';
import { Page, StateAccountFilter } from 'models/filter';
import { FilterContextProvider } from 'FilterModule/FilterContextProvider';
import {
  initialFilterLevelSchemas,
  FindPeopleRequirementsCurrentFilterType
} from './requirementsFilterConfig';
import {
  requirementsSelectorPortalId,
  TEAM_BUILDERS_REQUIREMENTS
} from '../constants';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import { updateEntityRequirements } from 'RequirementsModule/actionCreators';
import { AssociationUpdatesInstance } from 'RequirementsModule/actionCreators/types';
import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';
import useRequirementsHash from 'RequirementsModule/hooks/useRequirementsHash';
import {
  deserializeRequirement,
  serializeRequirement
} from 'RequirementsModule/utils';

/**
 * Applied only for instances with nested level
 * Use case: Skill level on side selector
 */
const makeSingleSelectNextState = (draftRequirements: string[]) => {
  const existedIdsSet = new Set();
  const nextState: string[] = [];

  /**
   * Iterating from the end of the draft list and pick the first ones
   * in a group of serialized id
   * i.e: ["9-3", "9-2", "4-3"] -> ["4-3", "9-2"]
   * */
  for (let i = draftRequirements.length; i >= 0; i--) {
    const requirement = draftRequirements[i];
    if (requirement) {
      const [id, _] = deserializeRequirement(requirement);
      if (!existedIdsSet.has(id)) {
        existedIdsSet.add(id);
        nextState.push(requirement);
      }
    }
  }

  return nextState;
};

interface FindPeopleRequirementsSelectorProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

// TODO TB - add location
const requirementsInitialState: {
  skillLevels: string[];
  region_ids: number[];
  discipline_ids: number[];
  office_ids: number[];
} = {
  skillLevels: [],
  region_ids: [],
  discipline_ids: [],
  office_ids: []
};

export const FindPeopleRequirementsSelector = ({
  isOpen,
  toggleIsOpen
}: FindPeopleRequirementsSelectorProps) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const dispatch = useAppDispatch();

  const {
    selectedUnassignedMemberBudget,
    requirementAssociationsByProjectMembershipIdHash,
    teamId
  } = useContext(FindPeopleModalContext);
  const allRequirementsHash = useRequirementsHash();
  const [requirements, setRequirements] = useState(requirementsInitialState);

  // For clicking cancel, revert back to this state
  const [prevRequirements, setPrevRequirements] = useState(
    requirementsInitialState
  );

  useEffect(() => {
    if (selectedUnassignedMemberBudget) {
      const { project_membership } = selectedUnassignedMemberBudget;
      const requirementAssociations =
        requirementAssociationsByProjectMembershipIdHash[project_membership.id];

      if (requirementAssociations) {
        const nextRequirementsState = {
          skillLevels: requirementAssociations.skill_associations.map(
            ({ team_skill_id, level }) =>
              serializeRequirement([team_skill_id, level as number])
          ),
          region_ids: requirementAssociations.region_ids,
          discipline_ids: requirementAssociations.discipline_ids,
          office_ids: requirementAssociations.office_ids
        };
        setRequirements(nextRequirementsState);
        setPrevRequirements(nextRequirementsState);
      } else {
        setRequirements(requirementsInitialState);
        setPrevRequirements(requirementsInitialState);
      }
    }
  }, [
    selectedUnassignedMemberBudget,
    requirementAssociationsByProjectMembershipIdHash
  ]);

  /**
   * generating a 'fake' StateAccountFilter based on the current requirements
   * so we can use the currentFilter infra for the requirements selector
   */
  const requirementsFilter: Partial<StateAccountFilter> = useMemo(() => {
    return {
      name: 'requirements',
      page_name: Page.FindPeople,
      custom: requirements
    };
  }, [requirements]);

  const portalTarget = document.getElementById(requirementsSelectorPortalId); // Must be inside component body

  const handleRequirementsChange: FindPeopleRequirementsCurrentFilterType['update'] =
    useCallback((values) => {
      if (values.skillLevels && values.skillLevels.length) {
        setRequirements((prev) => {
          return {
            ...prev,
            skillLevels: makeSingleSelectNextState(values.skillLevels || [])
          };
        });
      } else {
        setRequirements((prev) => ({
          ...prev,
          ...values
        }));
      }
    }, []);

  const handleRequirementsSave = useCallback(() => {
    if (!selectedUnassignedMemberBudget) {
      toggleIsOpen();
      return;
    }

    let association: AssociationUpdatesInstance = {
      entity_id: selectedUnassignedMemberBudget?.project_membership
        .id as number,
      entity_type: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
    };

    TEAM_BUILDERS_REQUIREMENTS.forEach((req) => {
      const { filterField, formatAssociationUpdateInstance } = req;
      const selectedRequirements = requirements[filterField];
      association = formatAssociationUpdateInstance({
        associationUpdateInstance: association,
        selectedRequirements,
        allRequirementsHash,
        requirementAssociationsByProjectMembershipIdHash,
        projectMembershipId:
          selectedUnassignedMemberBudget.project_membership.id
      });
    });

    dispatch(
      updateEntityRequirements({
        team_id: teamId as number,
        association_updates: [association]
      })
    );
    toggleIsOpen();
  }, [
    selectedUnassignedMemberBudget,
    dispatch,
    teamId,
    toggleIsOpen,
    requirements,
    allRequirementsHash,
    requirementAssociationsByProjectMembershipIdHash
  ]);

  const handleClose = useCallback(() => {
    setRequirements(prevRequirements);
    toggleIsOpen();
  }, [prevRequirements, toggleIsOpen]);

  const handleClearAllFiltersCallback = useCallback(() => {
    setRequirements(requirementsInitialState);
  }, []);

  const requirementsFilterContextProviderProps: React.ComponentProps<
    typeof FilterContextProvider
  > = {
    filterSchemaParams: {
      pageName: Page.FindPeople,
      level2: 'requirements',
      initialFilterLevelSchemas
    },
    matchingFakeFiltersOverride: [requirementsFilter],
    overrideUpdate: handleRequirementsChange
  };

  return (
    <FilterContextProvider {...requirementsFilterContextProviderProps}>
      {portalTarget &&
        createPortal(
          <StyledRequirementsSelector ref={ref} isOpen={isOpen} $width={width}>
            <StackedFiltersContainer
              handleClose={handleClose}
              shouldShowCancelButton={false}
              headerTitle={'+ Requirements'}
              handleDone={handleRequirementsSave}
              handleClearAllFiltersCallback={handleClearAllFiltersCallback}
              isVisible={isOpen}
              variant="Standalone"
              shouldUseDraft={false}
              isStickyHeaderHidden
            />
          </StyledRequirementsSelector>,
          portalTarget
        )}
    </FilterContextProvider>
  );
};

/* ------------------------------------ = ----------------------------------- */

const StyledRequirementsSelector = styled.div<{
  isOpen: boolean;
  $width: number;
}>`
  position: absolute;
  left: ${({ $width }) => (!$width ? '-2000px' : `-${$width}px`)};
  transform: ${({ isOpen, $width }) =>
    isOpen ? `translateX(${$width}px)` : 'none'};
  transition: 0.2s;
  height: 100%;
  top: 0;
  box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);
  z-index: 100;

  .filter-list-table-container {
    width: 275px !important;
    padding-bottom: 0;

    .inner {
      width: 215px !important;
    }
  }

  .stacked-filter-header-title {
    position: absolute;
  }
`;
