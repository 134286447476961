import styled from 'styled-components';
import theme from 'theme';
import { DropdownToggle } from 'reactstrap';
import FolderIcon from 'icons/FolderIcon';

export const StyledFolderIcon = styled(FolderIcon)`
  margin-right: ${({ marginRight }) => marginRight || '8px'};
  width: ${({ width }) => width || '15px'};
  height: ${({ height }) => height || '15px'};

  path {
    stroke: ${({ theme }) => theme.colors.colorSemiDarkGray1};
    fill: transparent;
    stroke-width: 2;
  }
`;

export const TeamNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-right: 8px;
  font-size: 13px;
  &.integration-settings {
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
`;
export const DropdownWrapper = styled.div`
  margin: 0 10px;
`;
export const HeaderContainer = styled.div`
  font-size: 14px;
  display: flex;
  min-width: 160px;
  min-height: 31px;
  margin: 0 10px;
  align-items: center;
  border-radius: 5px;
  color: ${({ active }) =>
    active ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray5};
  justify-content: space-between;
  border: 1px solid
    ${({ active }) =>
      active ? theme.colors.colorRoyalBlue : theme.colors.colorPaleGray5};
  background: ${theme.colors.colorPureWhite};
  padding: 0 8px;
  cursor: pointer;
  path {
    fill: ${({ active }) => (active ? theme.colors.colorRoyalBlue : 'initial')};
  }
  ${StyledFolderIcon} {
    path {
      fill: transparent;
    }
  }

  ${TeamNameContainer} {
    color: ${theme.colors.colorSemiDarkGray1};
  }
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    border: 1px solid ${theme.colors.colorRoyalBlue};
    ${TeamNameContainer} {
      color: ${theme.colors.colorRoyalBlue};
    }
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
    ${StyledFolderIcon} {
      path {
        stroke: ${theme.colors.colorRoyalBlue};
        fill: transparent;
      }
    }
  }

  .integration-settings {
    justify-content: flex-start;
  }
`;
export const CheckBox = styled.input`
  pointer-events: none;
  margin: 0 8px;
`;

export const CheckBoxItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0;
`;
