import KaratRight from 'icons/KaratRight';
import KaratLeft from 'icons/KaratLeft';
import styled from 'styled-components';
import theme from 'theme';
export const KaratContainer = styled.div`
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledKaratRight = styled(KaratRight)`
  cursor: pointer;
  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const StyledKaratLeft = styled(KaratLeft)`
  cursor: pointer;
  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;
