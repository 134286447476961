import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetTeamUtilizationSummaryData } from 'UtilizationModule/selectors';
import { fetchTeamUtilizationReport } from 'UtilizationModule/actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import SimplePieChart from 'components/Charts/SimplePieChart';
import { useAccountCapacitySum } from 'DashboardModule/hooks/useAccountCapacityTotals';
import { DATA_KEY } from 'UtilizationModule/constants';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

const containerStyles = {
  padding: 0
};

const ChartHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin-bottom: 12px;
  text-align: center;
`;

/* ------------------------------------ - ----------------------------------- */

const getTooltipContent = (data) => ({
  data: [
    {
      name: 'entered',
      label: 'Entered',
      value: formatNumWithMaxOneDecimal(data.notSubmitted),
      color: theme.colors.colorBudgetBlue
    },
    {
      name: 'submitted',
      label: 'Submitted',
      value: formatNumWithMaxOneDecimal(data.submitted),
      color: theme.colors.colorRemainingTeal
    },
    {
      name: 'approved',
      label: 'Approved',
      value: formatNumWithMaxOneDecimal(data.approved),
      color: theme.colors.colorMediumGreen6
    }
  ],
  summary: [
    {
      name: 'capacity',
      label: 'Capacity',
      value: formatNumWithMaxOneDecimal(data.capacity),
      color: 'tranparent'
    }
  ]
});

/* ------------------------------------ - ----------------------------------- */

const TimesheetStatusWidgetPieChart = ({
  fetchParams,
  header,
  isSelfLoading = true
}) => {
  const dispatch = useDispatch();

  const getUtilizationData = useMemo(
    () => makeGetTeamUtilizationSummaryData(),
    []
  );
  const rawData = useSelector((state) =>
    getUtilizationData(state, {
      filterStateId: fetchParams.filterStateId
    })
  );
  const totalCapacity = useAccountCapacitySum({
    accountIds: fetchParams.account_ids,
    fetchParams
  });

  const formattedData = useMemo(() => {
    const notSubmitted = +rawData[DATA_KEY.NOT_SUBMITTED];
    const submitted = +rawData[DATA_KEY.SUBMITTED];
    const approved = +rawData[DATA_KEY.APPROVED];
    const notApproved = totalCapacity - approved;
    const remaining = Math.max(0, totalCapacity - notSubmitted - submitted);
    const capacity = +totalCapacity;

    return {
      notSubmitted,
      submitted,
      approved,
      notApproved,
      remaining,
      capacity
    };
  }, [rawData, totalCapacity]);

  const tooltipData = useMemo(
    () => getTooltipContent(formattedData),
    [formattedData]
  );

  const chartData = useMemo(() => {
    const outer = [
      {
        name: 'remaining',
        value: formattedData.remaining,
        color: theme.colors.colorMediumGray1
      },
      {
        name: 'notSubmitted',
        value: formattedData.notSubmitted,
        color: theme.colors.colorRoyalBlue
      },
      {
        name: 'submitted',
        value: formattedData.submitted,
        color: theme.colors.colorRemainingTeal
      }
    ];
    // Inner circle => approved
    const inner = [
      {
        name: 'notApproved',
        value: formattedData.notApproved,
        color: 'transparent'
      },
      {
        name: 'approved',
        value: formattedData.approved,
        color: theme.colors.colorMediumGreen6
      }
    ];
    return { outer, inner };
  }, [
    formattedData.approved,
    formattedData.notApproved,
    formattedData.notSubmitted,
    formattedData.remaining,
    formattedData.submitted
  ]);

  useEffect(() => {
    if (
      isSelfLoading &&
      fetchParams.team_id &&
      fetchParams.account_ids.length
    ) {
      dispatch(fetchTeamUtilizationReport(fetchParams));
    }
  }, [dispatch, fetchParams, isSelfLoading]);
  const hasData =
    +formattedData.approved > 0 ||
    +formattedData.notApproved > 0 ||
    +formattedData.notSubmitted > 0 ||
    +formattedData.submitted > 0;

  return (
    <div
      data-for={'legend-style-tooltip'}
      data-tip={JSON.stringify({
        data: tooltipData.data,
        summary: tooltipData.summary
      })}
      data-tip-disable={!hasData}
    >
      <ChartHeader>{header}</ChartHeader>
      <SimplePieChart
        data={chartData.outer}
        innerData={chartData.inner}
        // label = % entered
        label={`${
          Math.round(
            ((formattedData.submitted + formattedData.notSubmitted) /
              (totalCapacity || 1)) *
              100
          ) || 0
        }%`}
        style={containerStyles}
      />
    </div>
  );
};

export default React.memo(TimesheetStatusWidgetPieChart);
