import styled from 'styled-components';
import theme from 'theme';
import OptionsCheckboxRow from './OptionsCheckboxRow';
import OptionsSubMenuTimeSetter from './OptionsSubMenuTimeSetter';
import { OptionsMenuValueProps, OptionsMenuSetterProps } from '../types';
import {
  WORK_PLAN_CALENDAR_OPTION_TYPES,
  WORK_PLAN_OPTION_TEXT
} from 'appConstants/workload';

type OptionsMenuBodyProps = {
  optionsMenuValueProps: OptionsMenuValueProps;
  optionsMenuSetterProps: OptionsMenuSetterProps;
};

const OptionsMenuBody = ({
  optionsMenuValueProps,
  optionsMenuSetterProps
}: OptionsMenuBodyProps) => {
  const {
    isTentative,
    isAllDay,
    startTime,
    endTime,
    dailyHours,
    isShowTimeSetter,
    isShowAllDayCheckbox
  } = optionsMenuValueProps;
  const {
    onUpdateIsTentative,
    onUpdateIsAllDay,
    onChangePredictableFields,
    canEditWorkplan
  } = optionsMenuSetterProps;

  const optionsMenuTimeSetterValueProps = {
    startTime,
    endTime,
    dailyHours
  };

  const optionsMenuTimeSetterSetterProps = {
    onChangePredictableFields,
    canEditWorkplan
  };

  return (
    <OptionsMenuBodyContainer>
      {isShowAllDayCheckbox && (
        <OptionsMenuRow>
          <OptionsCheckboxRow
            isChecked={isAllDay}
            handleUpdate={onUpdateIsAllDay}
            label={
              WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY]
            }
            isDisabled={!canEditWorkplan}
          />
        </OptionsMenuRow>
      )}
      <OptionsMenuRow>
        <OptionsCheckboxRow
          isChecked={isTentative}
          handleUpdate={onUpdateIsTentative}
          label={
            WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE]
          }
          isDisabled={!canEditWorkplan}
        />
      </OptionsMenuRow>
      {isShowTimeSetter && (
        <OptionsMenuRow>
          <OptionsSubMenuTimeSetter
            optionsMenuTimeSetterValueProps={optionsMenuTimeSetterValueProps}
            optionsMenuTimeSetterSetterProps={optionsMenuTimeSetterSetterProps}
          />
        </OptionsMenuRow>
      )}
    </OptionsMenuBodyContainer>
  );
};

const OptionsMenuBodyContainer = styled.div``;

const OptionsMenuRow = styled.div`
  border-bottom: 1px solid ${theme.colors.colorPaleGray7};
`;

export default OptionsMenuBody;
