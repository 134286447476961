import { useMemo } from 'react';
import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import { getDragTooltipDates } from 'appUtils/projectPlannerUtils';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import moment from 'moment';
import noAssigneeIcon from 'images/empty-member.svg';
import NoteIcon from 'images/newnoteicon.svg';
import FlagIcon from 'images/flag.svg';
import CommentIcon from 'images/comment-bubble-grey.svg';
import HomeCompletedIcon from 'icons/HomeCompletedIcon';
import cn from 'classnames';
import { removeHtmlTags } from 'appUtils';
import {
  ZOOM_TO_MULTIPLE_ASSIGNEES_SHOW_DURATION,
  VIEW_BY
} from 'appConstants/workload';

const StyledDateContainer = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorTranslucentGray3};
  display: none;
`;
const StyledTaskTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  width: 65%;
  font-weight: 400;
  font-size: 12px;
  margin-top: ${({ isCondensedView }) => (isCondensedView ? '-23px' : '0px')};
`;

const StyledCompletedIcon = styled(HomeCompletedIcon)`
  margin-right: 4px;
  margin-left: auto;
  flex: 0 0 12px;
  position: sticky;
  right: 5px;
`;
const StyledTaskDescription = styled.div`
  white-space: nowrap;
  position: sticky;
  font-size: 13px;
  left: 0px;

  display: flex;
  flex-grow: 1;
  align-items: center;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const EmptyMemberInitials = styled.div`
  flex: 0 0 24px;
  margin-right: 4px;
  height: 24px;
  background: url(${noAssigneeIcon});
  background-size: contain;
`;

const StyledEstimatedHours = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 4px;
  margin-left: auto;
  flex: 0 0 12px;
  position: absolute;
  top: -18px;
  right: 8px;
  font-size: 11px;
  color: ${theme.colors.colorSemiDarkGray1};
  visibility: hidden;
`;

const StyledTaskBar = styled.div`
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  background: ${theme.colors.colorPureWhite};
  color: ${theme.colors.colorSemiDarkGray1};
  border-radius: 100px;
  height: ${({ isCondensedView }) => (isCondensedView ? '8px' : '32px')};
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 30px;
  overflow: unset;
  margin: 10px 0px;
  padding: 2px 2px 2px 4px;
  .sidebar-member-initials {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    margin-right: 4px;
  }
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
  opacity: ${({ isCompleted }) => (isCompleted ? '0.4' : '1')};
  &:hover {
    ${StyledEstimatedHours} {
      visibility: visible;
    }
  }
`;

const StyledPhaseBarContainer = styled.div`
  position: relative;
`;

const noStyle = {
  borderColor: '',
  background: 'transparent',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer'
};
const draggingStyle = {
  borderColor: '',
  border: 'none',
  backgroundColor: `${theme.colors.colorIceBlue}`,
  cursor: 'grabbing',
  opacity: '0.4'
  // background: 'transparent',
  // backgroundColor: 'transparent'
  // cursor: 'ew-resize'
};

// TODO: tooltip is not sanitized
const getTooltipContent = (item, memberColor, isLoggedInUser) => {
  const startDate = moment(item?.start_date).format('MMM D');
  const endDate = moment(item?.end_date).format('MMM D');
  return `
  <div class="project-timeline-task-bars" style="border: 2px solid ${
    memberColor || '#808080'
  };">
  <div class="right-box" >
    <div class="tooltip-row row-1">
    <div class="member-name"> ${item?.member?.name || 'Unassigned'}</div>



      <div class="tooltip-bar-date-range">
      ${
        item?.due_at
          ? `<div class="tooltip-due-date">
          <div class="flag-icon" style="background: url(${FlagIcon}) no-repeat center;"></div>
          ${moment(item.due_at).format('MMM D')} </div>`
          : ``
      }

      </div>

    </div>
    <div class="tooltip-task-due"></div>
    <div>
    <div class="tooltip-bar-task-name">
    ${removeHtmlTags(item?.description)}</div>
    </div>
    <div class="tooltip-row row-3" >
    <div style="display: flex;">
    ${startDate !== endDate ? `${startDate} -  ${endDate}` : `${startDate}`}
    ${
      item?.estimated_hours
        ? ` | <div class="tooltip-estimated-hours">
        ${item?.estimated_hours}h</div>`
        : ``
    }
    </div>

    <div class="tooltip-totals-container">
    ${
      item?.note
        ? `<div class="note-icon" style="background: url(${NoteIcon}) no-repeat center;"></div>`
        : ''
    }
    ${
      item?.task_comment_ids?.length > 0
        ? `<div class="comment-icon" style="background: url(${CommentIcon}) no-repeat center;">${item?.task_comment_ids?.length}</div>`
        : ''
    }
    </div>
    </div>
  </div>
  </div>
  `;
};
const TaskItemRenderer = (props) => {
  const {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    userTheme,
    startDateRef,
    endDateRef,
    me,
    zoom,
    isCondensedView,
    viewBy
  } = props;

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle: {
      cursor: 'w-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      left: '0px',
      top: '10px',
      height: '36px',
      width: '36px',
      paddingLeft: '5px'
    },
    rightStyle: {
      cursor: 'e-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      right: '0px',
      top: '10px',
      height: '36px',
      width: '36px',
      paddingRight: '5px'
    }
  });

  const tooltipDates =
    itemContext.dragging || itemContext.resizing
      ? getDragTooltipDates({ item, itemContext })
      : null;

  const memberColor = calculateColorPref({
    id: item?.member?.id,
    originType: 'teamMembership',
    theme: userTheme.theme
  });

  const projectColor = calculateColorPref({
    id: item?.project_id,
    originType: 'project',
    theme: userTheme.theme
  });

  const taskTooltipContent = useMemo(
    () => getTooltipContent(item, memberColor, item?.assignee_id === me.id),
    [item, memberColor, me]
  );

  const numAssigneesToRender =
    item.duration > ZOOM_TO_MULTIPLE_ASSIGNEES_SHOW_DURATION[zoom] ? 3 : 1;
  const lastIndexToRender = numAssigneesToRender - 1;
  const assigneesForWidth = Math.max(item.assignees.length - 1, 0);

  const assigneeWidth =
    28 + Math.min(assigneesForWidth, lastIndexToRender) * 18;

  const itemProps = {
    ...getItemProps({
      ...item.itemProps,
      className: 'mosaic-milestone-bar',
      style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
    })
  };

  const itemWidth = +itemProps.style.width.slice(0, -2);

  return (
    <div {...itemProps}>
      <div {...leftResizeProps}>
        <div className="drag-handle-bar left task-drag" />
      </div>

      <StyledPhaseBarContainer
        data-html
        data-type="light"
        data-delay-show={250}
        data-tip={
          itemContext.dragging || itemContext.resizing ? '' : taskTooltipContent
        }
        data-for="task-bar"
      >
        <StyledTaskBar
          color={'#808080'}
          originType="teamMembership"
          styleId={item?.member?.id}
          isCompleted={item?.is_completed}
          borderColor={projectColor}
          isCondensedView={isCondensedView}
        >
          {(itemContext.dragging || itemContext.resizing) && (
            <>
              <div
                ref={startDateRef}
                data-tip={moment(tooltipDates.startDate).format('M-D-YY')}
                data-for="start-date-tooltip"
                style={{ position: 'absolute', left: 0, top: 0 }}
              />
              <div
                ref={endDateRef}
                data-tip={moment(tooltipDates.endDate).format('M-D-YY')}
                data-for="end-date-tooltip"
                style={{ position: 'absolute', right: 0, top: 0 }}
              />
            </>
          )}
          <StyledTaskDescription>
            {!isCondensedView && (
              <div
                style={{
                  display: 'flex',
                  flex: `0 0 ${assigneeWidth}px`,
                  marginLeft: isCondensedView ? '-6px' : '0px',
                  marginTop: isCondensedView ? '-1px' : '0px'
                }}
              >
                {item?.assignees?.length ? (
                  item.assignees
                    .slice(0, numAssigneesToRender)
                    .map((assignee, index) => {
                      if (
                        index === lastIndexToRender &&
                        item?.assignees?.length > numAssigneesToRender
                      ) {
                        return (
                          <MemberInitials
                            key={assignee?.account?.id}
                            size="small"
                            classes={cn(
                              'sidebar-member-initials regular-member-no-hover selected',
                              {
                                'logged-member-no-hover':
                                  me && assignee === me.id
                              }
                            )}
                            originType="teamMembership"
                            styleId={assignee?.account?.id}
                            member={{
                              initials: `+${
                                item.assignees?.length - lastIndexToRender
                              }`
                            }}
                            styles={{
                              position: index === 0 ? 'static' : 'absolute',
                              zIndex: item.assignees.length - index,
                              left: index * 18
                            }}
                          />
                        );
                      } else {
                        return (
                          <MemberInitials
                            key={assignee?.account?.id}
                            size="small"
                            classes={cn(
                              'sidebar-member-initials regular-member-no-hover selected',
                              {
                                'logged-member-no-hover':
                                  me && assignee === me.id
                              }
                            )}
                            originType="teamMembership"
                            styleId={assignee?.account?.id}
                            member={assignee}
                            styles={{
                              position: index === 0 ? 'static' : 'absolute',
                              zIndex: item.assignees.length - index,
                              left: index * 18
                            }}
                          />
                        );
                      }
                    })
                ) : (
                  <EmptyMemberInitials />
                )}
              </div>
            )}

            <StyledTaskTitle
              style={{ position: 'sticky', left: 0 }}
              isCondensedView={isCondensedView}
            >
              {(isCondensedView && viewBy === VIEW_BY.MEMBERS
                ? item?.project?.title + ' - '
                : '') + item?.description || ''}
            </StyledTaskTitle>
            <StyledDateContainer>
              {moment(item?.start_date).format('MMM D ')} -
              {moment(item?.end_date).format(' MMM D')}
            </StyledDateContainer>
          </StyledTaskDescription>
          {item?.is_completed ? (
            <StyledCompletedIcon
              height="12px"
              width="12px"
              currentColor={theme.colors.colorBudgetGreen}
            />
          ) : (
            !!item.estimated_hours &&
            itemWidth > 180 && (
              <StyledEstimatedHours>
                {item.estimated_hours}
              </StyledEstimatedHours>
            )
          )}
        </StyledTaskBar>
      </StyledPhaseBarContainer>
      <div {...rightResizeProps}>
        <div className="drag-handle-bar right task-drag" />
      </div>
    </div>
  );
};

export default TaskItemRenderer;
