import { createSelector } from 'reselect';
import { initialState } from '../reducers/projectAccounts';
import { formatTotals } from 'appUtils/phaseDisplayUtils';
import { getMemberBudgets, getFlatPhasesHash } from 'selectors';
import keyBy from 'lodash/keyBy';

const emptyObj = {};
const getOwnFilterStateId = (_, ownProps) => ownProps?.filterStateId;
const getOwnShouldFormatData = (_, ownProps) => ownProps?.shouldFormatData;
const getOwnPhaseId = (_, ownProps) => ownProps?.phaseId;

export const getProjectAccountsState = (state) =>
  state.projectAccounts || initialState;

const emptyBudgetTotal = {
  phaseTotals: emptyObj,
  projectTotal: emptyObj
};

/**
 * Calculates phase and activity phase's remaining subtotal based on what the members' estiamtes are
 * for display, since there can be situation where a member can have no estimate but with some spent/planned,
 * then a different member has some estimate, then the subtotal coming from BE will be wrong for display.
 * ex: Member 1: estimated 0, spent 300, planned 0, remaining 0
 *     Member 2: estimated 300, spent 0, planned 0, remaining 300
 *     The subtotal from BE will be: estimated 300, spent 300, planned 0, remaining 0
 *     But we should display the remaining as 300 instead of 0.
 *
 *
 * * activitiesHash and activityAccountTotalsByActivityPhaseMembershipId:
 * It is for simplifying getting the activity total budget data for each membership (account).
 * All members have activity_phase_membership_id, so if you have access to the activityId + activity_phase_membership_id,
 * You can just look up this hash:
   e.e: activitiesHash[ifHeadLess ? null : activityId].activityAccountTotalsByActivityPhaseMembershipId[activityPhaseMembershipId]
  => Headless means phase.activity_order = 0, aka no visible activity phases
  => Got budget data for that member

  From observation, phase that has No activity phase (activity is headless),
  will still have 1 item in `activities` in project_account response, and that one item has
  activityId = null, so you can get the budget data for the phase's member by :
   => activitiesHash['null']?.activityAccountTotalsByActivityPhaseMembershipId[activityPhaseMembershipId]

 */
const formatPhaseTotals = (phaseTotals, phases, memberBudgets) => {
  Object.entries(phaseTotals).forEach(([phaseId, phaseTotal]) => {
    let phaseRemainingSubtotalExpense = 0;
    let phaseRemainingSubtotalHours = 0;
    phaseTotal.activitiesHash = {};

    phaseTotal?.activities.forEach((activityTotal, index) => {
      const activityId = activityTotal.activity_id;
      const isActivityHeadless = !activityId;
      const phase = phases[phaseId];
      const activityPhase = phase
        ? phase.activity_phases.find((ap) =>
            !activityId ? ap.is_default : ap.activity_id === activityId
          )
        : null;
      const activityPhaseMemberships =
        activityPhase?.activity_phase_memberships || [];
      const memberships = isActivityHeadless
        ? phaseTotal?.phase_memberships
        : activityPhaseMemberships;
      const {
        activityRemainingSubtotalExpense,
        activityRemainingSubtotalHours
      } = memberships
        .map((membership) => {
          const memberBudget = memberBudgets[membership?.member_budget_id];
          if (memberBudget) {
            const memberTotal = activityTotal.accounts?.find(
              (account) => account.member_budget_id === memberBudget.id
            );
            return memberTotal;
          } else return undefined;
        })
        .filter((memberTotal) => {
          return (
            !!memberTotal &&
            (memberTotal.planned?.hours ||
              memberTotal.spent?.hours ||
              memberTotal.estimated?.hours)
          );
        })
        .reduce(
          (subtotals, memberTotal) => {
            return {
              activityRemainingSubtotalExpense:
                subtotals.activityRemainingSubtotalExpense +
                memberTotal.estimated.remaining.expense,
              activityRemainingSubtotalHours:
                subtotals.activityRemainingSubtotalHours +
                memberTotal.estimated.remaining.hours
            };
          },
          {
            activityRemainingSubtotalExpense: 0,
            activityRemainingSubtotalHours: 0
          }
        );
      phaseRemainingSubtotalExpense += activityRemainingSubtotalExpense;
      phaseRemainingSubtotalHours += activityRemainingSubtotalHours;
      phaseTotal.activities[index].activityRemainingSubtotal = {
        expense: activityRemainingSubtotalExpense,
        hours: activityRemainingSubtotalHours
      };
      phaseTotal.activitiesHash[activityId] = {
        ...activityTotal,
        activityAccountTotalsByActivityPhaseMembershipId: keyBy(
          activityTotal.accounts,
          'activity_phase_membership_id'
        )
      };
    });
    phaseTotal.phaseRemainingSubtotal = {
      expense: phaseRemainingSubtotalExpense,
      hours: phaseRemainingSubtotalHours
    };
  });
  return phaseTotals;
};

/**
 * GET /project_accounts data for a filterStateId
 * if shouldFormatData is true, then calculate remaining for display only for
 * budget table because we do not show negative remaining for a member when his/her
 * estimate is 0.
 */
export const makeGetOwnBudgetTotals = () =>
  createSelector(
    getOwnFilterStateId,
    getOwnShouldFormatData,
    getProjectAccountsState,
    getMemberBudgets,
    getFlatPhasesHash,
    (filterStateId, shouldFormatData, state, memberBudgets, phases) => {
      const { phaseTotals, projectTotal } =
        state.filterStates[filterStateId] || emptyBudgetTotal;
      return {
        projectTotal,
        phaseTotals: shouldFormatData
          ? formatPhaseTotals(phaseTotals, phases, memberBudgets)
          : phaseTotals
      };
    }
  );

export const makeGetOwnUnformattedPhaseBudgetTotals = () =>
  createSelector(
    makeGetOwnBudgetTotals(),
    getOwnPhaseId,
    (budgetTotals, phaseId) => {
      return budgetTotals.phaseTotals[phaseId] || emptyObj;
    }
  );

export const makeGetOwnFormattedPhaseBudgetTotals = () =>
  createSelector(makeGetOwnUnformattedPhaseBudgetTotals(), (phaseTotal) => {
    if (phaseTotal === emptyObj) {
      return emptyObj;
    }
    const { phase_id } = phaseTotal;
    const numbers = formatTotals(phaseTotal);
    return { ...numbers, phase_id };
  });

export const makeGetOwnUnformattedProjectBudgetTotals = () =>
  createSelector(makeGetOwnBudgetTotals(), (budgetTotals) => {
    return budgetTotals.projectTotal;
  });

export const makeGetOwnFormattedProjectBudgetTotals = () =>
  createSelector(makeGetOwnUnformattedProjectBudgetTotals(), (projectTotal) => {
    if (projectTotal === emptyObj) {
      return emptyObj;
    }

    const { project_id } = projectTotal;

    const projectNumbers = { ...formatTotals(projectTotal), project_id };

    return projectNumbers;
  });
