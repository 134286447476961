import React from 'react';
import { connect } from 'react-redux';

import {
  getUserIsAdmin,
  makeGetDescriptionIsEditible,
  makeGetActivityByDescriptionId,
  getFlickerTaskCategoryCellByProjectId,
  makeGetProjectBasicInfoById
} from 'selectors';
import { updateDescription } from 'actionCreators';
import { TimesheetStyledCell } from '../styles';
import TimesheetDescription from './index';

class WeeklyTimesheetDescription extends React.Component {
  onSubmit = (title) => {
    const {
      updateDescription,
      id,
      date,
      activityId,
      phaseId,
      projectId,
      accountId,
      isTimesheetsDisabled
    } = this.props;
    if (isTimesheetsDisabled) {
      return;
    }
    updateDescription({
      title,
      id,
      date,
      activityId,
      phaseId,
      projectId,
      accountId
    });
  };

  render() {
    const {
      isEditible,
      activity,
      project,
      flickerActivity,
      id,
      title,
      accountId
    } = this.props;

    const isRed = flickerActivity[id];
    return (
      <TimesheetDescription
        ContainerEl={TimesheetStyledCell}
        isRed={isRed}
        handleSubmit={this.onSubmit}
        isEditible={isEditible}
        activity={activity}
        project={project}
        projectId={project?.id}
        accountId={accountId}
        title={title}
      />
    );
  }
}
const makeMapStateToProps = () => {
  const getDescriptionIsEditible = makeGetDescriptionIsEditible();
  const getActivity = makeGetActivityByDescriptionId();
  const getProjectInfo = makeGetProjectBasicInfoById();
  const mapStateToProps = (state, ownProps) => ({
    isAdmin: getUserIsAdmin(state),
    isCurrentUser: true, // todo memoize isCurrentUser,
    isEditible: getDescriptionIsEditible(state, ownProps),
    activity: getActivity(state, ownProps),
    flickerActivity: getFlickerTaskCategoryCellByProjectId(state),
    project: getProjectInfo(state, ownProps)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  updateDescription
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WeeklyTimesheetDescription);
