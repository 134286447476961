import PriorityQueue from 'priorityqueuejs';
import {
  MAX_NUM_RUNNING_REQUESTS,
  RequestPriority,
  REQUEST_PRIORITY_RANKS,
  RequestPriorityGroup,
  DEFAULT_REQUEST_PRIORITY,
  REQUEST_PRIORITY_GROUP_TO_PRIORITY
} from 'appConstants/api';
import { AnyAction } from 'redux';
import { CANCELLED_REQUEST_ERROR } from 'appConstants/ignoredErrors';

type Priority = RequestPriority | RequestPriorityGroup | undefined;

/**
 * Handles prioritizing api requests. Will only start queuing (and therefore prioritizing) once the
 * MAX_NUM_RUNNING_REQUESTS has been reached.
 */
class FetchManager {
  private count = 0; // only used in the generation of ids
  private requestHash: Record<string, RequestData> = {};
  private numRunningRequests = 0; // number of in-flight requests
  private queue = new PriorityQueue<RequestData>(
    (a, b) =>
      REQUEST_PRIORITY_RANKS[a.priority] - REQUEST_PRIORITY_RANKS[b.priority]
  );

  private nextAbortController: {
    controller: Nullable<AbortController>;
    requestId: Nullable<string>;
  } = { controller: null, requestId: null };

  debugMode = false; // for logging debug messages

  /**
   * Will cancel (running or queued) requests that match the given abortId or array of abortIds and return an error message.
   */
  abort(abortIds: string | string[]) {
    const idsToAbortSet = new Set(
      Array.isArray(abortIds) ? abortIds : [abortIds]
    );

    Object.values(this.requestHash).forEach((requestData) => {
      if (requestData.abortId && idsToAbortSet.has(requestData.abortId)) {
        this.handleAbortRequest(requestData);
      }
    });
  }

  abortRequest(requestId: string) {
    const requestData = this.requestHash[requestId];
    if (requestData) {
      this.handleAbortRequest(requestData);
    }
  }

  private handleAbortRequest(requestData: RequestData) {
    // this is AbortController.abort - it will exist for running requests only
    requestData.abort?.();
    // remove the request from the hash
    this.deleteRequestFromHash(requestData.id);
    // return error to indicate this request has been aborted
    requestData.resolve({ error: CANCELLED_REQUEST_ERROR });
  }

  private addRequestToHash(requestData: RequestData) {
    this.requestHash[requestData.id] = requestData;
  }

  private deleteRequestFromHash(id: string) {
    delete this.requestHash[id];
  }

  private getIsRequestInHash(id: string) {
    return !!this.requestHash[id];
  }

  /**
   * @returns number of requests waiting in the priority queue
   */
  private getQueueSize() {
    return this.queue.size();
  }

  /**
   * @returns number of incomplete, running api request sagas
   */
  private getNumRunningRequests() {
    return this.numRunningRequests;
  }

  private getId() {
    return `${this.count++}`;
  }

  private incrementRunningRequestCount() {
    this.numRunningRequests++;
  }

  private decrementRunningRequestCount() {
    this.numRunningRequests--;
  }

  /**
   * A priority is valid if it is not falsey and is either a valid (current) request priority group id or request priority.
   */
  private checkIsValidPriority(priority: Priority): boolean {
    return (
      priority &&
      ((RequestPriorityGroup[priority] &&
        REQUEST_PRIORITY_GROUP_TO_PRIORITY[priority]) ||
        RequestPriority[priority])
    );
  }

  /**
   * Returns the priority for the action's request. Actions can be given request priorities
   * either in the saga worker (default value for the action) or the action's meta (value specific to
   * that dispatch of the action). If neither value is given, default is used.
   *
   * action.meta.requestPriority takes precedence over priority param since it can be specific to the
   * dispatch
   */
  private getRequestPriority(action: AnyAction, priority: Priority) {
    const dispatchRequestPriority = action?.meta?.requestPriority;
    let requestPriority = DEFAULT_REQUEST_PRIORITY;

    const validPriority =
      (this.checkIsValidPriority(dispatchRequestPriority) &&
        dispatchRequestPriority) ||
      (this.checkIsValidPriority(priority) && priority);

    if (validPriority) {
      if (REQUEST_PRIORITY_GROUP_TO_PRIORITY[validPriority]) {
        requestPriority = REQUEST_PRIORITY_GROUP_TO_PRIORITY[validPriority];
      } else if (RequestPriority[validPriority]) {
        requestPriority = validPriority;
      }
    }
    return requestPriority;
  }

  getNextAbortController() {
    const { controller, requestId } = this.nextAbortController;
    this.setNextAbortController({ controller: null, requestId: null });
    return {
      controller,
      ...(this.debugMode && {
        requestId
      })
    };
  }

  private setNextAbortController(values: typeof this.nextAbortController) {
    this.nextAbortController = values;
  }

  /* ------------------------- Request-queue lifecycle ------------------------ */

  /**
   * (1) If max number of running requests has been reached, will add request to the priority queue (2).
   *     Otherwise, will allow the request to run (3). This means that priority only matters once the
   *     queue has > 1 request.
   *
   *     The returned Promise will be resolved when the request completes
   */
  addRequest({
    action,
    priority,
    apiFn,
    args,
    idRef
  }: {
    /** The redux action */
    action: AnyAction;
    /** Priority of the action (could be undefined or overridden - see this.getRequestPriority) */
    priority: Priority;
    apiFn: (...args: unknown[]) => Promise<unknown>;
    args: unknown[];
    /** For allowing the caller to have access to the request's id */
    idRef?: { value: string };
  }) {
    const { type } = action || {};
    const { abortId } = action?.meta || {};
    const requestPriority = this.getRequestPriority(action, priority);
    const id = type + '_____________________' + this.getId();
    if (idRef !== undefined) {
      idRef.value = id;
    }

    let resolveRequest; // set to the blocking promise's resolve so we can control when requests are started
    const promise = new Promise((resolve) => {
      resolveRequest = resolve;
    });
    const requestData: RequestData = {
      id,
      resolve: resolveRequest,
      priority: requestPriority,
      abortId,
      apiFn,
      args
    };

    this.addRequestToHash(requestData);

    if (this.getNumRunningRequests() < MAX_NUM_RUNNING_REQUESTS) {
      this.startRequest(requestData);
    } else {
      this.queueRequest(requestData);
    }
    return promise;
  }

  /**
   * (2) Adds the request to the priority queue
   */
  private queueRequest(requestData: RequestData) {
    this.queue.enq(requestData);

    if (this.debugMode) {
      this.logQueueRequest(requestData);
    }
  }

  /**
   * (3) Start the request and return its result
   */
  private startRequest(requestData: RequestData) {
    const { id, resolve, apiFn, args } = requestData;
    this.incrementRunningRequestCount();

    if (this.debugMode) {
      this.logStartRequest(requestData);
    }

    // this AbortController's signal will be added to the fetch request by fetchInterceptor
    // so that the request can be aborted. Not the most ideal way of going about it but the only way to do it
    // without having to update the args for every api function to accept the signal
    const controller = new AbortController();
    this.setNextAbortController({ controller, requestId: id });

    requestData.abort = () => controller.abort();

    apiFn(...args).then((result) => {
      resolve(result);
      this.decrementRunningRequestCount();

      if (this.debugMode) {
        this.logCompletedRequest(requestData);
      }

      this.deleteRequestFromHash(id);

      this.checkAndStartNextRequest();
    });
  }

  /**
   * (4) Starts the next request in the queue, if there is one
   */
  private checkAndStartNextRequest() {
    if (!this.queue.isEmpty()) {
      const nextRequestData = this.queue.deq();
      // check if the request is still in the hash (if it is not that means we removed it
      // because it has been cancelled)
      if (this.getIsRequestInHash(nextRequestData.id)) {
        this.startRequest(nextRequestData);
      } else {
        this.checkAndStartNextRequest();
      }
    }
  }

  /* -------------------------------- debugging ------------------------------- */

  private formatLogCounts(priority) {
    // "*priority* (numRunning | numQueued)"
    return `*${priority}* (${this.getNumRunningRequests()} | ${this.getQueueSize()}): `;
  }

  private logQueueRequest({ id, priority }) {
    console.log(`ADDING TO QUEUE ${this.formatLogCounts(priority)}`, id);
  }

  private logStartRequest({ id, priority }) {
    console.log(`STARTING ${this.formatLogCounts(priority)}`, id);
  }

  private logCompletedRequest({ id, priority }) {
    const numRunning = this.getNumRunningRequests();
    console.log(
      `COMPLETED ${this.formatLogCounts(priority)}: `,
      id,
      numRunning
    );
  }
}

export default new FetchManager();

/* ------------------------------------ - ----------------------------------- */

interface RequestData {
  resolve: (result: unknown) => void;
  id: string;
  priority: NonNullable<Priority>;
  apiFn: (...args: unknown[]) => Promise<unknown>;
  args: unknown[];
  abortId?: string;
  abort?: () => void;
}
