import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import {
  UpdateActivityPhaseMembershipParams,
  UpdateActivityPhaseMembershipPayload,
  UpdateActivityPhaseMembershipSuccessResponse
} from '../types/activityPhaseMembership';

/* ---------------------------------- Update --------------------------------- */

export const UPDATE_ACTIVITY_PHASE_MEMBERSHIP = createRequestTypes(
  'UPDATE_ACTIVITY_PHASE_MEMBERSHIP'
);

export const updateActivityPhaseMembership = createAction(
  UPDATE_ACTIVITY_PHASE_MEMBERSHIP.TRIGGER,
  ({ meta, ...payload }: UpdateActivityPhaseMembershipParams) => ({
    payload,
    meta
  })
);

export const updateActivityPhaseMembershipActionCreatorsMap =
  createActionCreatorsMap<
    UpdateActivityPhaseMembershipPayload,
    UpdateActivityPhaseMembershipSuccessResponse
  >(UPDATE_ACTIVITY_PHASE_MEMBERSHIP);
