import styled from 'styled-components';
import bellOutline from 'images/bell-outline-dark.svg';
import bellOutlineThick from 'images/bell-outline-dark-thick.svg';
import theme from 'theme';

export const DropdownContainer = styled.div`
  width: 212px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.5);
  position: absolute;
  top: ${(props) => props.top - 30 || 20}px;
  left: ${(props) => props.left - 13 || 0}px;
  cursor: initial;
  z-index: 15;

  .notification-tooltip-options {
    max-width: 250px;
  }

  .multi-line {
    text-align: left !important;
    line-height: 1.2;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.colorPureWhite};
  padding: 10px 14px 5px;
  z-index: 1;
`;

export const HeaderText = styled.span`
  font-size: 15px;
  color: #4a4a4a;
  font-weight: 600;
`;

export const Content = styled.div`
  width: 100%;
  height: ${({ showHelpText }) => (showHelpText ? '90px' : '144px')};
  padding: ${({ showHelpText }) => (showHelpText ? '15px' : '0px 16px 20px')};
  display: flex;
  flex-direction: column;
`;

export const MenuItemText = styled.span`
  font-size: 14px;
  color: ${({ isSelected }) => (isSelected ? '#4a4a4a' : '#808080')};
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding-left: ${({ isIndented }) => (isIndented ? '10px' : '0')};
  padding-top: ${({ isIndented }) => (isIndented ? '2px' : '5px')};
  padding-bottom: ${({ isIndented }) => (isIndented ? '2px' : '5px')};
  cursor: pointer;
`;

export const CheckBox = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 9px;
  border-radius: 3px;
  background-color: ${({ isSelected }) => (isSelected ? '#0074d9' : 'white')};
  border: ${({ isSelected }) =>
    `solid 1px ${isSelected ? '#0074d9' : '#cdcdcd'}`};
`;

export const HelpText = styled.div`
  color: #808080;
  font-size: 14px;
  line-height: 1.43;
`;

export const NotificationBell = styled.i`
  background: url(${bellOutline}) no-repeat center;
  width: 12px;
  height: 16px;
  margin-right: 8px;
`;

export const NotificationBellonModal = styled.i`
  background: url(${bellOutlineThick}) no-repeat center;
  background-size: contain;
  width: 14px;
  height: 16px;
  margin-right: 8px;
`;

export const NotificationHelper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 20px;
    width: 20px;
  }
  cursor: pointer;

  &:hover {
    path {
      fill: ${theme.colors.colorSemiDarkGray1};
    }
    circle {
      fill: ${theme.colors.colorPaleGray5};
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;
