import React from 'react';
import { useDispatch } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';

import SortOptions from 'views/projectPlanner/projectTimeline/Options/SortOptions';

import {
  dropdownButtonStyle,
  dropdownSelectedStyle,
  StyledFilterOption,
  ListIcon,
  Arrow
} from './styles';

import { CHART_TYPE, CHART_TYPE_LABEL } from 'ReportsModule/constants';

const chartOptions = [CHART_TYPE.BAR, CHART_TYPE.AREA_GRAPH];

const options = chartOptions.map((option) => ({
  label: CHART_TYPE_LABEL[option],
  value: CHART_TYPE[option]
}));

const ChartTypeOption = ({ activeFilter, viewBy, pageName, value }) => {
  const dispatch = useDispatch();

  const setChartType = ({ viewBy: chartType }) => {
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        name: viewBy,
        page: pageName,
        custom: {
          ...activeFilter.custom,
          chartType: chartType
        }
      })
    );
  };

  const label = options.find((option) => option.value === value).label;

  const renderToggle = () => (
    <StyledFilterOption>
      <ListIcon />
      <span data-label={label}>{label}</span>
      <Arrow className="down-arrow" />
    </StyledFilterOption>
  );

  return (
    <SortOptions
      viewBy={value}
      setViewBy={setChartType}
      options={options}
      left={0}
      dropdownButtonStyle={dropdownButtonStyle}
      noCheck
      selectedStyle={dropdownSelectedStyle}
      renderToggle={renderToggle}
      headerText="View by"
      unhideArrowOnHover
    />
  );
};

export default ChartTypeOption;
