import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import styled from 'styled-components';

export const TaskCardWrapper = styled.div``;

class OutsideClickWrapper extends Component {
  constructor(props) {
    super(props);
    bindAll(this, ['handleOutsideClick', 'setWrapperRef']);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick(event) {
    event.stopPropagation();
    const clickedOutside = this.wrapper && !this.wrapper.contains(event.target);

    if (clickedOutside) this.props.handleOutsideClick(event);
  }

  setWrapperRef(domNode) {
    this.wrapper = domNode;
  }

  render() {
    return (
      <TaskCardWrapper>
        <div className="outside-click-wrapper" ref={this.setWrapperRef}>
          {this.props.children}
        </div>
      </TaskCardWrapper>
    );
  }
}

OutsideClickWrapper.propTypes = {
  handleOutsideClick: PropTypes.func.isRequired
};

export default OutsideClickWrapper;
