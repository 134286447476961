import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchUtilizations = {
  request: () => action(constants.FETCH_UTILIZATIONS.REQUEST),
  success: (response) =>
    action(constants.FETCH_UTILIZATIONS.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.FETCH_UTILIZATIONS.FAILURE, { payload: { error } })
};

export const fetchUtilizationsReport = {
  request: () => action(constants.FETCH_UTILIZATIONS_REPORT.REQUEST),
  success: (response) =>
    action(constants.FETCH_UTILIZATIONS_REPORT.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_UTILIZATIONS_REPORT.FAILURE, { payload: { error } })
};

export const fetchUtilizationsReportTotals = {
  request: () => action(constants.FETCH_UTILIZATIONS_REPORT_TOTALS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_UTILIZATIONS_REPORT_TOTALS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_UTILIZATIONS_REPORT_TOTALS.FAILURE, {
      payload: { error }
    })
};

export const fetchTeamUtilizationReport = {
  request: () => action(constants.FETCH_TEAM_UTILIZATION_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_UTILIZATION_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_UTILIZATION_REPORT.FAILURE, {
      payload: { error }
    })
};

export const fetchMembersUtilizationReport = {
  request: () => action(constants.FETCH_MEMBERS_UTILIZATION_REPORT.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_MEMBERS_UTILIZATION_REPORT.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_MEMBERS_UTILIZATION_REPORT.FAILURE, {
      payload: { error },
      meta
    })
};

export const fetchProjectUtilizationReport = {
  request: () => action(constants.FETCH_PROJECT_UTILIZATION_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_UTILIZATION_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_UTILIZATION_REPORT.FAILURE, {
      payload: { error }
    })
};
export const fetchPhaseUtilizationReport = {
  request: () => action(constants.FETCH_PHASE_UTILIZATION_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASE_UTILIZATION_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASE_UTILIZATION_REPORT.FAILURE, {
      payload: { error }
    })
};
export const fetchTeamHistoricalUtilization = {
  request: () => action(constants.FETCH_TEAM_HISTORICAL_UTILIZATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_HISTORICAL_UTILIZATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_HISTORICAL_UTILIZATION.FAILURE, {
      payload: { error }
    })
};
