import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportDepartment
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingDepartmentsByName: Record<string, CsvImportDepartment>;
  newDepartments: React.MutableRefObject<Record<string, boolean>>;
}

export const departmentValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  // not required, can be any department
  const { existingDepartmentsByName, newDepartments } = additionalParams;

  const { enteredDepartment } = getRowValuesForEntityType(row, entityType);

  const existingDepartmentMatchedByEnteredName =
    existingDepartmentsByName[enteredDepartment];

  const enteredDepartmentIsNewName =
    enteredDepartment &&
    !existingDepartmentMatchedByEnteredName &&
    !newDepartments.current[enteredDepartment];

  if (enteredDepartment === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (
    // if entered department is non empty and is a new department, add to new department hash
    enteredDepartmentIsNewName
  ) {
    newDepartments.current[enteredDepartment] = true;
    return true;
  } else return true;
};
