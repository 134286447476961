import { createPortal } from 'react-dom';
import Filter from 'views/projectPlanner/Filter';
import { FILTER_PAGE_NAME_TO_ID, VIEW_BY } from 'appConstants/workload';
import { usePageContext } from 'contexts/PageContextProvider';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import { SectionRoutes } from 'appConstants/navigation';
import { SIDE_FILTER_PORTAL_IDS } from 'FilterModule/components/SideFilter/SideFilter';

/**
 * Currently only used for ProjectFilter. do not use otherwise
 */
export const LegacyFilterAdapter = ({
  isDashboardWidgetModal,
  widgetType,
  filterListId
}: {
  isDashboardWidgetModal?: boolean;
  widgetType?: string;
  /**
   * provide for project lists - behaves similar to isoStates
   */
  filterListId?: string;
}) => {
  const { pageName, currentView } = usePageContext();

  const { currentFilter, draftFilter, currentFilterSchema } =
    useFilterContext();

  const {
    activeFilter,
    widgetConfig,
    filterEntity,
    isUsingQueryParamFilter,
    additionalFilterOptions
  } = useReportsViewFilter({
    ...(isDashboardWidgetModal && {
      matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType/:reportViewType`,
      widgetType
    })
  });

  const portalTarget = document.getElementById(
    SIDE_FILTER_PORTAL_IDS.reportSideFilterPortal
  );

  return (
    portalTarget &&
    createPortal(
      <Filter
        viewBy={currentView}
        pageName={FILTER_PAGE_NAME_TO_ID[pageName]}
        filterEntity={filterEntity}
        filterListId={filterListId}
        noTrigger
        innerHeightAdjustment={154}
        filterContainerClass="legacy-adapter"
        listWidth={
          filterEntity === 'projects' || currentView === VIEW_BY.PROJECTS
            ? 300
            : 225
        }
        additionalFilterOptions={additionalFilterOptions}
        crossFieldDependencies={currentFilter?.stackedFilterOrder}
        widgetConfig={widgetConfig}
        // provide activeFilter not to use the one from makeGetActiveWorkloadPlannerFilter directly
        {...(isUsingQueryParamFilter && { activeFilter })}
        draftFilter={draftFilter}
        currentFilterSchema={currentFilterSchema}
      />,
      portalTarget
    )
  );
};
