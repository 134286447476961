export const getActivitiesForPhase = (props) => {
  const { allActivities, phase, billableActivities, nonBillableActivities } =
    props;

  const activities = phase?.activity_order?.length
    ? phase.activity_order.map((id) => allActivities[id])
    : [...billableActivities, ...nonBillableActivities];

  return activities;
};
