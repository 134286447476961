import { useAppSelector } from 'reduxInfra/hooks';
import theme from 'theme';
import styled from 'styled-components';
import { ThreeDotMenu, ThreeDotMenuContainer } from 'components/ThreeDotMenu';
import SaveIcon from 'icons/SaveIcon';
import { getMilestoneModalProject, getSelectedProject } from 'selectors';
import useProjectBudgetSettings from 'BudgetModule/hooks/useProjectBudgetSettings';
import { ReactComponent as MenuIcon } from 'icons/revert-icon.svg';

interface HeaderThreeDotMenuProps {
  headerType: string;
  sortDirection: string;
  isActive: boolean;
  handleSetSort: (newSortType: Nullable<string>) => void;
}

const HeaderThreeDotMenu = ({
  headerType,
  sortDirection,
  isActive,
  handleSetSort
}: HeaderThreeDotMenuProps) => {
  const milestoneModalProject = useAppSelector(getMilestoneModalProject);
  const selectedProject = useAppSelector(getSelectedProject);

  const projectToUse = milestoneModalProject || selectedProject;
  const { updateProjectBudgetSettings } = useProjectBudgetSettings(
    projectToUse?.id
  );

  const handleSaveSortOrder = () => {
    updateProjectBudgetSettings({
      phase_default_sort: [
        {
          direction: sortDirection,
          attribute: headerType
        }
      ]
    });
  };

  const handleClearSortOrder = () => {
    updateProjectBudgetSettings({
      phase_default_sort: []
    });
    handleSetSort(null);
  };

  return isActive ? (
    <Container>
      <ThreeDotMenu
        color={theme.colors.colorRoyalBlue}
        vertical
        size={16}
        slide={false}
      >
        <MenuContainer>
          <MenuItem onClick={handleSaveSortOrder}>
            <SaveIconContainer>
              <SaveIcon color={theme.colors.colorMediumGray7} />
            </SaveIconContainer>
            Save as Default Order
          </MenuItem>
          <MenuItem onClick={handleClearSortOrder}>
            <RevertIconContainer>
              <MenuIcon />
            </RevertIconContainer>
            Revert to Manual Order
          </MenuItem>
        </MenuContainer>
      </ThreeDotMenu>
    </Container>
  ) : null;
};

const Container = styled.div`
  width: 16px;
  margin-left: -16px;
  ${ThreeDotMenuContainer} {
    svg {
      margin-bottom: 6px;
    }
  }
`;

const MenuContainer = styled.div`
  padding: 4px 0px;
`;

const MenuItem = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
`;

const IconContainer = styled.div`
  margin-right: 5px;
`;

const SaveIconContainer = styled(IconContainer)`
  margin-top: -2px;
`;

const RevertIconContainer = styled(IconContainer)`
  margin-top: -1px;
`;

export default HeaderThreeDotMenu;
