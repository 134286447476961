import { AddProjectMembershipRequirementListItem } from '../types';
import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';

export const AddProjectMembershipRequirement = (
  item: AddProjectMembershipRequirementListItem
) => {
  const {
    memberBudget: { id: memberBudgetId },
    projectMembership: { id: projectMembershipId },
    onAddRequirementsClick
  } = item;

  return (
    <StyledItemContainer
      data-testid="add-project-membership-requirements-item-container"
      className="add-project-membership-requirements"
    >
      <div
        onClick={() =>
          onAddRequirementsClick({ memberBudgetId, projectMembershipId })
        }
        data-testid="add-requirement-text"
      >
        + Requirement
      </div>
    </StyledItemContainer>
  );
};
