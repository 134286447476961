export default [
  'check-circle',
  'due-date',
  'project-menu-button',
  'search-bar-input',
  'ico-drag-task',
  'react-date-picker__nav-bar-date',
  'react-flex-item',
  'react-date-picker__footer-button',
  'react-flex',
  'editing',
  'react-date-picker__month-view-day-text',
  'priority-dot-container',
  'toggle-container',
  'priority-dropdown',
  'priority-dot',
  'dropdown-item',
  'pencil-icon',
  'check-circle-img',
  'pill-container',
  'project-title',
  'rc-calendar-header',
  'rc-calendar-body',
  'rc-calendar-date',
  'rc-calendar-next-month-btn',
  'rc-calendar-prev-month-btn',
  'remove',
  'react-flex',
  'project-list-item',
  'project-description'
];
