import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { FilterListTypeDropdown } from 'FilterModule/types';
import { useTimesheetStatusesFilter } from 'FilterModule/hooks/useTimesheetStatusesFilter';

export const TimesheetStatusFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const {
    updateSelectedItems,
    optionsArray,
    selectedItems,
    itemHash,
    labelKey
  } = useTimesheetStatusesFilter();

  return (
    <SimpleFilterDropdown
      itemHash={itemHash}
      labelKey={labelKey}
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      listWidth={150}
      isSearchEnabled={false}
      renderToggle={renderToggle}
      isBatchActionsHidden
    />
  );
};
