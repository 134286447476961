import warning from 'warning';
import { CANCELLED_REQUEST_ERROR } from 'appConstants/ignoredErrors';
import { mosaicAPIClients } from 'appCore/API/apiClients';

// NOTE: these functions should all be replaced

const HOST = process.env.GATEWAY_HOST;

/** Format response for a real Error */
const formatErrorResponse = ({ error, url }) => ({
  error:
    window.isUnloading || error.name === 'AbortError'
      ? CANCELLED_REQUEST_ERROR // will be ignored / not sent to Sentry
      : error.message,
  url
});

export function callApiV2(endpoint, token, fetchInit = {}) {
  const fullUrl = mosaicAPIClients.mosaicAPI.getFullUrl(endpoint);

  const fetchArgs = { ...fetchInit };
  fetchArgs.headers = fetchArgs.headers || {};
  if (token) {
    fetchArgs.credentials = 'include';
    fetchArgs.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'Accept-Version': 'v2'
    };
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }
  fetchArgs.headers['mosaic-host'] = HOST;

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return json.then((data) => Promise.reject({ error: data, response }));
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      (rejected) => {
        if (rejected instanceof Error) {
          return formatErrorResponse({ error: rejected, url: fullUrl });
        }
        const { error, response } = rejected;
        return {
          error:
            error?.error_message ||
            error?.errors?.[0] ||
            error?.statusText ||
            response?.statusText ||
            (window.isUnloading && CANCELLED_REQUEST_ERROR) ||
            'Error',
          response,
          isUserFriendlyError: !!error?.error_message,
          url: fullUrl
        };
      }
    );
}
export function callAI(endpoint, token, fetchInit = {}) {
  const apiUrl = process.env.MOSAIC_AI_URL;
  const fullUrl = apiUrl + endpoint;
  const fetchArgs = { ...fetchInit };
  if (token) {
    fetchArgs.credentials = 'include';
    fetchArgs.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json'
    };
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject({ error: json, response });
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      (rejected) => {
        if (rejected instanceof Error) {
          return formatErrorResponse({ error: rejected, url: fullUrl });
        }
        const { error, response } = rejected;
        return {
          error:
            error?.statusText ||
            response?.statusText ||
            (window.isUnloading && CANCELLED_REQUEST_ERROR) ||
            'Error',
          response,
          url: fullUrl
        };
      }
    );
}

/* global MOSAIC_API_URL */

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.

export function callApi(endpoint, token, fetchInit = {}) {
  const fullUrl = mosaicAPIClients.mosaicAPI.getFullUrl(endpoint);

  const fetchArgs = { ...fetchInit };
  if (token) {
    fetchArgs.credentials = 'include';
    fetchArgs.headers = fetchArgs.headers || {};
    fetchArgs.headers.Authorization = `Bearer ${token}`;
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }
  fetchArgs.headers['mosaic-host'] = HOST;

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return json.then((data) => Promise.reject({ error: data, response }));
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      (rejected) => {
        if (rejected instanceof Error) {
          return formatErrorResponse({ error: rejected, url: fullUrl });
        }
        const { error, response } = rejected;
        return {
          error:
            !response || !response.ok
              ? error?.error_message ||
                (Array.isArray(error?.errors) && error.errors.length > 0)
                ? error?.error_message || error.errors[0]
                : error?.errors ||
                  error ||
                  (response && response.statusText) ||
                  (window.isUnloading && CANCELLED_REQUEST_ERROR) ||
                  'Error'
              : '',
          response,
          isUserFriendlyError: !!error?.error_message,
          url: fullUrl
        };
      }
    );
}

export const callIntegrationApi = (endpoint, token, fetchInit = {}) => {
  const fullUrl =
    endpoint.indexOf(process.env.INTEGRATION_SERVER_URL) === -1
      ? process.env.INTEGRATION_SERVER_URL + endpoint
      : endpoint;
  const fetchArgs = { ...fetchInit };
  if (token) {
    // fetchArgs.credentials = 'include';
    fetchArgs.headers = fetchArgs.headers || {};
    fetchArgs.headers.Authorization = `Bearer ${token}`;
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }
  // fetchArgs.headers['mosaic-host'] = HOST;

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return json.then((data) => Promise.reject({ error: data, response }));
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      (rejected) => {
        if (rejected instanceof Error) {
          return formatErrorResponse({ error: rejected, url: fullUrl });
        }
        const { error, response } = rejected;
        return {
          error:
            !response || !response.ok
              ? error ||
                (response && response.statusText) ||
                (window.isUnloading && CANCELLED_REQUEST_ERROR) ||
                'Error'
              : '',
          response,
          url: fullUrl
        };
      }
    );
};
export const callQBAgentApi = (endpoint, token, fetchInit = {}) => {
  const fullUrl =
    endpoint.indexOf(process.env.MOSAIC_QB_URL) === -1
      ? process.env.MOSAIC_QB_URL + endpoint
      : endpoint;
  const fetchArgs = { ...fetchInit };
  if (token) {
    fetchArgs.credentials = 'include';
    fetchArgs.headers = fetchArgs.headers || {};
    // fetchArgs.headers.Authorization = `Bearer ${token}`;
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }
  // fetchArgs.headers['mosaic-host'] = HOST;

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return json.then((data) => Promise.reject({ error: data, response }));
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      (rejected) => {
        if (rejected instanceof Error) {
          return formatErrorResponse({ error: rejected, url: fullUrl });
        }
        const { error, response } = rejected;
        return {
          error:
            !response || !response.ok
              ? error ||
                (response && response.statusText) ||
                (window.isUnloading && CANCELLED_REQUEST_ERROR) ||
                'Error'
              : '',
          response,
          url: fullUrl
        };
      }
    );
};

export function callGalaxyServerApi(endpoint, token, fetchInit = {}) {
  const fullUrl =
    endpoint.indexOf(process.env.MOSAIC_GALAXY_SERVER_URL) === -1
      ? process.env.MOSAIC_GALAXY_SERVER_URL + endpoint
      : endpoint;

  const fetchArgs = { ...fetchInit };
  if (token) {
    fetchArgs.credentials = 'include';
    fetchArgs.headers = fetchArgs.headers || {};
    fetchArgs.headers.Authorization = `Bearer ${token}`;
  } else {
    warning(false, `WARNING: ${endpoint} with no token`);
  }
  fetchArgs.headers['mosaic-host'] = HOST;

  return fetch(fullUrl, fetchArgs)
    .then((response) => {
      if (response.status === 204) {
        return { json: {}, response };
      }
      return { json: response.json(), response };
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        return json.then((data) => Promise.reject({ error: data, response }));
      }
      return json;
    })
    .then(
      (response) => ({ response }),
      (rejected) => {
        if (rejected instanceof Error) {
          return formatErrorResponse({ error: rejected, url: fullUrl });
        }
        const { error, response } = rejected;
        return {
          error:
            !response || !response.ok
              ? error?.error_message ||
                (Array.isArray(error?.errors) && error.errors.length > 0)
                ? error?.error_message || error.errors[0]
                : error?.errors ||
                  error ||
                  (response && response.statusText) ||
                  (window.isUnloading && CANCELLED_REQUEST_ERROR) ||
                  'Error'
              : '',
          response,
          isUserFriendlyError: !!error?.error_message,
          url: fullUrl
        };
      }
    );
}
