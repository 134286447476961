import { put } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity } from './generics';
import { api } from '../service';
import * as actionCreators from 'actionCreators';

const { subscriptionFetch, subscriptionCancellation } = entityActions;

export function* fetchCurrentSubscription(action) {
  const { error } = yield changeEntity(
    subscriptionFetch,
    api.fetchCurrentSubscription,
    [action.payload.token]
  );
}

export function* cancelSubscription(action) {
  const { error } = yield changeEntity(
    subscriptionCancellation,
    api.cancelSubscription,
    [action.payload.token]
  );
  if (!error) {
    yield put(actionCreators.fetchCurrentSubscription(action.payload.token));
  }
}
