import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { Switch } from 'components/Switch';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import styled from 'styled-components';
import { EntityRateCellProps, TableCellProps } from '../types';
import { isEntityRateCell } from '../utils';
import cn from 'classnames';

export const BillableCell = (props: TableCellProps) =>
  isEntityRateCell(props) ? <BillableCellWithEntityRate {...props} /> : null;

export const BillableCellWithEntityRate = (props: EntityRateCellProps) => {
  const {
    row: {
      original: { isRateGroupArchived, rateEntity, updateEntity }
    }
  } = props;

  const handleChange = () => {
    if (rateEntity.active_entity_type === EntityRateType.WorkCategory) {
      updateEntity({
        id: rateEntity.id,
        billable: !rateEntity.billable
      });
    }
  };

  const isEntityRateArchived = !!rateEntity?.archived;
  const isDisabled = isRateGroupArchived || isEntityRateArchived;
  const { cellProps, switchProps } = isDisabled
    ? {
        cellProps: {
          $disabled: true,
          ...defaultTooltipProps,
          'data-class': cn(defaultTooltipProps['data-class'], 'center'),
          'data-tip': 'Unarchive to edit.'
        },
        switchProps: {
          disabled: true,
          onChange: () => undefined
        }
      }
    : {
        cellProps: {},
        switchProps: {
          onChange: handleChange
        }
      };

  return (
    <Cell {...cellProps}>
      <Switch
        checkedText="Yes"
        isChecked={!!rateEntity.billable}
        uncheckedText="No"
        {...switchProps}
      />
    </Cell>
  );
};

const Cell = styled.div.attrs<{ $disabled?: boolean }>(({ $disabled }) => ({
  className: cn({ disabled: $disabled })
}))<{ $disabled?: boolean }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  &.disabled {
    cursor: not-allowed;
  }
`;
