import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import Body from '../../WidgetParts/Body';
import CapacitySummaryChart from './CapacitySummaryChart';
import { getTheme } from 'selectors';
import {
  makeGetDemandHoursListByViewBy,
  makeGetCapacityReportDataByFilter
} from 'CapacityModule/selectors';
import { fetchCapacityReport } from 'CapacityModule/actionCreators';
import theme from 'theme';
import {
  TimesheetChartContainer,
  TimesheetLegend,
  LegendText,
  LegendContainer,
  LegendDivider,
  projectGradient,
  AdditionalChartInfoContainer,
  StyledInfo
} from '../../styles';

import {
  UtilLegendBox,
  Toggle,
  ToggleContainer
} from '../UtilizationSummaryWidget/styles';
import { GENERATE_CAPACITY_RANGE } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS, VIEW_BY } from 'appConstants/workload';
import { defaultAddableWidgetsHash } from 'DashboardModule/constants/widgets';
import { CUSTOM_FILTER_VALUES } from 'appConstants/filters';
import { updateAccountFilterLocal } from 'actionCreators';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const emptyArray = [];

const getLegend = ({
  viewBy,
  showWorkloadByProjects,
  showPto,
  showHolidays
}) => {
  const legends = {
    [VIEW_BY.MEMBERS]: [
      {
        name: 'Planned',
        color: showWorkloadByProjects ? projectGradient : '#F3F574',
        value: 'planned'
      },
      {
        name: 'Available',
        color: '#CDCDCD',
        value: 'available'
      },
      {
        name: 'PTO',
        value: 'ptoForDisplay',
        color: showPto ? theme.gradients.pto : theme.gradients.disabledHatch
      },
      {
        name: 'Holidays',
        value: 'holidayForDisplay',
        color: showHolidays
          ? theme.gradients.holiday
          : theme.gradients.disabledHatch
      },
      {
        name: 'Total Hours',
        color: 'transparent',
        value: 'total'
      }
    ],
    [VIEW_BY.PROJECTS]: [
      {
        name: 'Planned',
        color: '#F3F574',
        value: 'planned'
      },
      {
        name: 'Available',
        color: '#CDCDCD',
        value: 'available'
      },
      {
        name: 'Total Hours',
        color: 'transparent',
        value: 'total'
      }
    ],
    [VIEW_BY.ORGANIZATION]: [
      {
        name: 'Planned',
        color: '#F3F574',
        value: 'planned'
      },
      {
        name: 'Available',
        color: '#CDCDCD',
        value: 'available'
      },
      {
        name: 'PTO',
        value: 'ptoForDisplay',
        color: showPto ? theme.gradients.pto : theme.gradients.disabledHatch
      },
      {
        name: 'Holidays',
        value: 'holidayForDisplay',
        color: showHolidays
          ? theme.gradients.holiday
          : theme.gradients.disabledHatch
      },
      {
        name: 'Total Hours',
        color: 'transparent',
        value: 'total'
      }
    ],
    [VIEW_BY.CLIENTS]: [
      {
        name: 'Planned',
        color: showWorkloadByProjects ? projectGradient : '#F3F574',
        value: 'planned'
      },
      {
        name: 'Available',
        color: '#CDCDCD',
        value: 'available'
      },
      {
        name: 'Total Hours',
        color: 'transparent',
        value: 'total'
      }
    ]
  };

  return legends[viewBy];
};

const nameToShowParam = {
  PTO: 'showPto',
  Holidays: 'showHolidays'
};

/* ------------------------------------ - ----------------------------------- */

const CapacitySummaryWidget = ({
  activeFilter,
  chartOnly = false,
  chartWidth,
  chartHeight,
  innerRadius,
  outerRadius,
  cy,
  containerClassName,
  chartLabel,
  viewBy,
  pageName,
  capacityReportData,
  fetchParams,
  isSelfLoading = false,
  isFetching,
  widgetConfig,
  projectColors,
  demandHoursList,
  filterIdType,
  isDashboardWidgetModal
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const dispatch = useDispatch();
  const { totalDemand, totalCapacity, totalPto, totalHolidayHours } =
    capacityReportData;

  const {
    showPto: showPtoFromFilter,
    showHolidays: showHolidaysFromFilter,
    showWorkloadByProjects
  } = activeFilter?.custom;
  const isDashboardWidget = widgetConfig || isDashboardWidgetModal;
  const showPto = isDashboardWidget ? false : showPtoFromFilter;
  const showHolidays = isDashboardWidget ? false : showHolidaysFromFilter;
  const shouldUseProjectBreakdown =
    showWorkloadByProjects && !isDashboardWidget;

  const toggleFilter = (param) => {
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        name: viewBy,
        page: pageName,
        custom: {
          ...activeFilter.custom,
          [param]: !activeFilter.custom[param]
        }
      })
    );
  };

  const getGroups = () => {
    // organization view can have data with account_ids.length === 0
    const hasNoData =
      !activeFilter?.[filterIdType]?.length && viewBy !== VIEW_BY.ORGANIZATION;
    const planned = hasNoData ? 0 : +totalDemand;
    const available = hasNoData ? 0 : Math.max(+totalCapacity, 0);
    const pto = hasNoData || !showPto ? 0 : +totalPto;
    const ptoForDisplay = hasNoData ? 0 : +totalPto;
    const holiday = hasNoData || !showHolidays ? 0 : +totalHolidayHours;
    const holidayForDisplay = hasNoData ? 0 : +totalHolidayHours;
    const total = planned + available;

    return {
      available,
      planned,
      pto,
      ptoForDisplay,
      holiday,
      holidayForDisplay,
      total
    };
  };
  const groups = getGroups();

  const getItemsByProjects = useCallback(() => {
    const hasNoData = !activeFilter?.[filterIdType]?.length;

    // Removes projects with 0 demand so that breakdown graph doesnt have white space.
    const filterDemandHoursList = demandHoursList.filter((projectInfo) => {
      const { demand } = projectInfo;
      return demand;
    });
    const dataGroups = filterDemandHoursList.map((accountProject) => {
      const { demand, project_id } = accountProject;
      const project = {
        name: project_id,
        value: demand,
        color: projectColors[project_id] || '#CDCDCD'
      };

      return project;
    });

    const additionalSections = [
      {
        name: 'pto',
        value: groups.pto,
        color: 'white'
      },
      {
        name: 'available',
        value: groups.available,
        color: '#CDCDCD'
      },
      {
        name: 'holiday',
        value: groups.holiday,
        color: 'white'
      }
    ];

    return [
      ...(hasNoData ? emptyArray : dataGroups),
      ...(viewBy === VIEW_BY.MEMBERS ? additionalSections : emptyArray)
    ];
  }, [
    activeFilter,
    filterIdType,
    demandHoursList,
    groups.pto,
    groups.available,
    groups.holiday,
    viewBy,
    projectColors
  ]);

  const getItems = useCallback(
    (viewBy) => {
      const dataGroups = {
        [VIEW_BY.MEMBERS]: [
          { name: 'pto', value: groups.pto, color: 'white' },
          { name: 'planned', value: groups.planned, color: '#F3F574' },
          { name: 'available', value: groups.available, color: '#CDCDCD' },
          { name: 'holiday', value: groups.holiday, color: 'white' }
        ],
        [VIEW_BY.ORGANIZATION]: [
          { name: 'pto', value: groups.pto, color: 'white' },
          { name: 'planned', value: groups.planned, color: '#F3F574' },
          { name: 'available', value: groups.available, color: '#CDCDCD' },
          { name: 'holiday', value: groups.holiday, color: 'white' }
        ],
        [VIEW_BY.PROJECTS]: [
          { name: 'planned', value: groups.planned, color: '#F3F574' },
          { name: 'available', value: groups.available, color: '#CDCDCD' }
        ],
        [VIEW_BY.CLIENTS]: [
          { name: 'planned', value: groups.planned, color: '#F3F574' }
        ]
      };
      return dataGroups[viewBy];
    },
    [groups]
  );

  const styles = containerClassName
    ? undefined
    : { display: 'flex', alignItems: 'flex-end', minHeight: '82px' };

  const totalWithoutOffHours = +totalDemand + +totalCapacity;
  const totalWithOffHours =
    +totalDemand + +totalCapacity + +totalPto + +totalHolidayHours;
  const capacityTotal =
    showPto || showHolidays ? totalWithOffHours : totalWithoutOffHours;
  const hasNoPaddingBottom =
    viewBy === VIEW_BY.PROJECTS || viewBy === VIEW_BY.CLIENTS;

  const Legend = useMemo(
    () =>
      getLegend({
        viewBy,
        showWorkloadByProjects: shouldUseProjectBreakdown,
        showPto: showPtoFromFilter,
        showHolidays: showHolidaysFromFilter
      }),
    [
      viewBy,
      showPtoFromFilter,
      showHolidaysFromFilter,
      shouldUseProjectBreakdown
    ]
  );

  const items = useMemo(
    () => (shouldUseProjectBreakdown ? getItemsByProjects() : getItems(viewBy)),
    [shouldUseProjectBreakdown, viewBy, getItemsByProjects, getItems]
  );

  const filterEquality = useMemo(
    () => JSON.stringify(fetchParams),
    [fetchParams]
  );

  useEffect(() => {
    if (
      isSelfLoading &&
      fetchParams.params.team_id &&
      fetchParams.params[filterIdType].length
    ) {
      dispatch(fetchCapacityReport(fetchParams));
    }
    // do not include fetchParams
  }, [dispatch, isSelfLoading, filterIdType, filterEquality]);

  useEffect(() => {
    if (!isFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFetching]);

  return (
    <DynamicModuleLoader
      modules={[getUtilizationModule(), getCapacitiesModule()]}
    >
      <div style={styles} className={containerClassName}>
        <Body>
          <TimesheetChartContainer
            style={{
              padding: chartOnly
                ? '0'
                : hasNoPaddingBottom
                ? '0 19px'
                : '0 19px 30px',
              flexDirection: chartOnly ? 'column' : 'unset',
              ...(widgetConfig && !isDashboardWidgetModal && { width: '100%' })
            }}
          >
            {!hasInitiallyLoaded || isFetching ? (
              <SkeletonLoader
                numLoaders={1}
                style={{
                  margin: 0,
                  position: 'relative',
                  ...(widgetConfig && !isDashboardWidgetModal
                    ? {
                        width: '100%'
                      }
                    : {
                        paddingLeft: 12,
                        width: '97px',
                        right: '6px',
                        height: '28px'
                      })
                }}
                loaderStyle={{ height: 200, rx: 4 }}
              />
            ) : (
              <>
                <CapacitySummaryChart
                  items={items}
                  percentPlanned={Math.round(
                    (groups.planned / (capacityTotal || 1)) * 100
                  )}
                  accountCapacitySum={capacityTotal}
                  planned={groups.planned}
                  chartWidth={chartWidth}
                  chartHeight={chartHeight}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  cy={cy}
                  chartLabel={chartLabel}
                  shouldShowPaddingAngle={shouldUseProjectBreakdown}
                />
                {chartOnly && (
                  <AdditionalChartInfoContainer>
                    <StyledInfo>
                      Planned
                      <br />
                      {formatNumWithMaxTwoDecimals(groups.planned)}h
                    </StyledInfo>
                    <StyledInfo>
                      Capacity
                      <br />
                      {formatNumWithMaxTwoDecimals(groups.available)}h
                    </StyledInfo>
                  </AdditionalChartInfoContainer>
                )}
              </>
            )}
          </TimesheetChartContainer>
          {!chartOnly && (
            <TimesheetLegend
              style={{ flexDirection: 'column', alignItems: 'stretch' }}
            >
              {Legend.filter(
                (legend) =>
                  !(
                    legend.name === 'PTO' &&
                    widgetConfig?.disabledOptions?.[
                      CUSTOM_FILTER_VALUES.showPto
                    ]
                  ) &&
                  !(
                    legend.name === 'Holidays' &&
                    widgetConfig?.disabledOptions?.[
                      CUSTOM_FILTER_VALUES.showHolidays
                    ]
                  )
              ).map(({ name, color, value }, index) => (
                <div key={index}>
                  {value === 'total' && <LegendDivider />}
                  <LegendContainer
                    key={name}
                    style={{
                      margin: '3px 0',
                      alignItems: 'stretch',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <>
                        <UtilLegendBox color={color} />
                        <LegendText
                          style={{
                            textTransform: 'none',
                            fontWeight: 600,
                            color: `${
                              (name === 'PTO' && !showPtoFromFilter) ||
                              (name === 'Holidays' && !showHolidaysFromFilter)
                                ? '#828282'
                                : '#4f4f4f'
                            }`
                          }}
                        >
                          {groups[value]?.toLocaleString?.() ?? groups[value]}h
                        </LegendText>
                      </>
                    </div>
                    <div style={{ width: 91, display: 'flex' }}>
                      <LegendText uppercase style={{ marginLeft: '10px' }}>
                        <span>{name}</span>
                        {nameToShowParam[name] && (
                          <ToggleContainer
                            isOn={activeFilter.custom[nameToShowParam[name]]}
                            onClick={() => toggleFilter(nameToShowParam[name])}
                          >
                            <Toggle />
                          </ToggleContainer>
                        )}
                      </LegendText>
                    </div>
                  </LegendContainer>
                </div>
              ))}
            </TimesheetLegend>
          )}
        </Body>
      </div>
    </DynamicModuleLoader>
  );
};

const makeMapStateToProps = () => {
  const getCapacityReportData = makeGetCapacityReportDataByFilter();
  const getCapacityReportProjectBreakdownData =
    makeGetCapacityReportDataByFilter();
  const getDemandHoursList = makeGetDemandHoursListByViewBy();
  const mapStateToProps = (state, ownProps) => {
    const capacityReportData = getCapacityReportData(state, ownProps);
    const { isFetching: isFetchingProjectBreakdown } =
      getCapacityReportProjectBreakdownData(state, {
        filterStateId: ownProps.secondaryFilterStateId
      });
    return {
      capacityReportData,
      isFetching: capacityReportData.isFetching,
      isFetchingProjectBreakdown,
      projectColors: getTheme(state).projectColors,
      demandHoursList: getDemandHoursList(state, {
        filterStateId: ownProps.secondaryFilterStateId,
        viewBy: ownProps.viewBy
      })
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(React.memo(CapacitySummaryWidget));
