import {
  StyledDateSortIcon,
  StyledSortIconContainer,
  HeaderContainer
} from './styles';
import { SORT_BY } from 'appConstants/filters';
import HeaderThreeDotMenu from './HeaderThreeDotMenu';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

interface MilestoneHeaderStartDateProps {
  handleSetSort: (newSortType: Nullable<string>) => void;
  isActive: boolean;
  sortDirection: string;
}

const MilestoneHeaderStartDate = ({
  handleSetSort,
  isActive,
  sortDirection
}: MilestoneHeaderStartDateProps) => {
  const { phaseSortOrderFlag } = useFeatureFlags();
  return (
    <HeaderContainer
      onClick={() => handleSetSort(SORT_BY.start_date)}
      isActive={isActive}
    >
      {phaseSortOrderFlag && (
        <HeaderThreeDotMenu
          headerType={SORT_BY.start_date}
          sortDirection={sortDirection}
          isActive={isActive}
          handleSetSort={handleSetSort}
        />
      )}
      PLANNED
      <StyledSortIconContainer isActive={isActive} order={sortDirection}>
        <StyledDateSortIcon />
      </StyledSortIconContainer>
    </HeaderContainer>
  );
};

export default MilestoneHeaderStartDate;
