import styled from 'styled-components';
import theme from 'theme';

const EmptyDiv = styled.div`
  background-color: ${({ isTransparent }) =>
    isTransparent ? theme.colors.colorLightGray19 : 'white'};
  height: 100%;
  border-top: ${({ isNoShowCell }) =>
    isNoShowCell ? 'none' : `1px solid ${theme.colors.colorPaleGray3}`};
`;

const cellsToNotShow = new Set(['collapse', 'add']); // These cells have the same background as the modal's background

const PhaseSubRowCell = ({ row, column }) => {
  const { list } = row.original;
  const hasNoRole = list.listItems.length <= 1; // The only row is phaseBottomRow for adding new role
  const isNoShowCell = cellsToNotShow.has(column.id);
  const isTransparent = hasNoRole || isNoShowCell;

  return (
    <EmptyDiv
      isNoShowCell={isNoShowCell}
      isTransparent={isTransparent}
    ></EmptyDiv>
  );
};

export default PhaseSubRowCell;
