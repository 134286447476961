import { reducerMap } from '../reducers';
import noteSaga from '../sagas';

export function getNotesModule() {
  return {
    id: 'notes',
    reducerMap,
    sagas: [noteSaga]
    // Actions to fire when this module is added/removed
    //initialActions: []
    // finalActions: []
  };
}
