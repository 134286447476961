// dependency injection so we could move to npm module if desired and use on mobile
const domainExists = ({ projectId, teamId }) => projectId || teamId;
const createPermissionsMiddleware =
  ({
    checkForPermissionsRequirement,
    permissionsSelector,
    denyAction,
    verifyPermission
  }) =>
  (store) =>
  (next) =>
  (action) => {
    const permissionRequired = checkForPermissionsRequirement(action);
    if (!permissionRequired) {
      // avoid work if action always permitted
      return next(action);
    } else {
      const permissions = permissionsSelector(store.getState());
      const actionPermitted = verifyPermission(action, permissions);
      const permissionPayloadPresent =
        action.payload &&
        action.payload.permissions &&
        domainExists(action.payload.permissions);
      if (!permissionPayloadPresent) {
        if (action.payload.UI_CHECK) {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              `${action.type} UI_CHECK failed to provide a permissions payload when expected.`
            );
            return true;
          }
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              `${action.type} failed to provide a permissions payload when expected. Permission may be denied by the server.`
            );
          }
          return next(action);
        }
      }
      if (action.payload.UI_CHECK) {
        return actionPermitted;
        /*
        component uses true/false return value to render UI
        redux should ignore
      */
      }
      if (actionPermitted) {
        return next(action);
      } else {
        const denyPermissionAction = denyAction(action);
        return next(denyPermissionAction);
      }
    }
  };

export default createPermissionsMiddleware;
