import { useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import theme from 'theme';
import CalendarIcon from 'icons/HomeScheduledIcon';
import WorkPlanIcon from 'icons/WorkPlanIcon';
import MenuCheckinsIcon from 'icons/MenuCheckinsIcon';
import DateNav from '../../homePlanner/DateNav';
import { TODAY } from 'appConstants/planners';
import PlannerDateNav from './PlannerDateNav';
import { setPlannerDateHeader } from 'actionCreators';

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  color: ${theme.colors.colorMediumGray9};

  svg {
    position: relative;
    top: ${(props) => (props.top ? props.top : '0')};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: ${({ plannerViewType }) =>
    plannerViewType === 'WEEK' ? '23px' : '0'};
`;

const StyledToggle = styled.div`
  font-size: 15px;
  color: ${({ isSelected }) => (isSelected ? '#4a4a4a' : '#808080')};
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  border-bottom: ${({ isSelected }) =>
    isSelected ? `solid 2px ${theme.colors.colorRoyalBlue}` : ''};
  cursor: pointer;
  margin-right: 45px;
`;

const PlannerHeaderContainer = styled.div`
  width: 100%;
  ${({ isOnWeekView }) =>
    isOnWeekView
      ? `
    position: relative;
    left: -19px;
  `
      : ''};
`;

const StyledTitleText = styled.div`
  margin-left: 8px;
  font-size: 24px;
  color: ${theme.colors.colorSemiDarkGray6};
`;

const OldTitleComponent = (
  <StyledTitle>
    <CalendarIcon
      width={17}
      height={17}
      currentColor={theme.colors.colorMediumGray9}
    />
    <StyledTitleText> Task Planner </StyledTitleText>
  </StyledTitle>
);

const NewTitleComponent = (
  <StyledTitle data-tip="Check-in" data-for="app-tooltip" Î>
    <MenuCheckinsIcon
      width={24}
      height={24}
      currentColor={theme.colors.colorMediumGray9}
    />
    <StyledTitleText> Check-in </StyledTitleText>
  </StyledTitle>
);

const PlannerHeader = ({
  plannerViewType,
  setPlannerViewType,
  plannerDate,
  setDate,
  isNewPlanner,
  accountId
}) => {
  const dispatch = useDispatch();
  return (
    <PlannerHeaderContainer isOnWeekView={plannerViewType === 'WEEK'}>
      {isNewPlanner ? NewTitleComponent : OldTitleComponent}
      {!isNewPlanner && (
        <ToggleContainer plannerViewType={plannerViewType}>
          <StyledToggle
            onClick={() => setPlannerViewType('DAY')}
            isSelected={plannerViewType === 'DAY'}
          >
            {' '}
            Day{' '}
          </StyledToggle>
          <StyledToggle
            onClick={() => {
              setPlannerViewType('WEEK');
              dispatch(
                setPlannerDateHeader({
                  dateHeader: moment()
                })
              );
            }}
            isSelected={plannerViewType === 'WEEK'}
          >
            {' '}
            Week
          </StyledToggle>
        </ToggleContainer>
      )}
      {plannerViewType === 'DAY' && (
        <PlannerDateNav
          customPlannerDate={plannerDate}
          setDate={setDate}
          accountId={accountId}
        />
      )}
    </PlannerHeaderContainer>
  );
};

export default PlannerHeader;
