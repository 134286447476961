/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTeamId, getTeamMembers } from 'selectors';
import ContentLoader from 'react-content-loader';
import Table from 'components/Table';
import { StyledBudgetSettingsTable } from 'SettingsModule/components/Budget/styles';
import { ListHeader } from '../styles';
import {
  getMemberManagementColumnHeaders,
  getFormattedBudgetSettings
} from 'SettingsModule/selectors';
import {
  fetchRates,
  fetchAllTeamRates,
  fetchPositions,
  fetchTeamPositions
} from 'BudgetModule/actionCreators';
import { fetchTeamPermissions } from 'PermissionsModule/actionCreators';
import TeamRateCell from 'SettingsModule/components/Budget/TeamRateCell';
import RateMultiplierCell from 'SettingsModule/components/Budget/RateMultiplierCell';
import PermissionLevelCell from 'SettingsModule/components/Budget/PermissionLevelCell';
import EmailNotificationCell from 'SettingsModule/components/Budget/EmailNotificationCell';
import { EditableNameCell } from 'SettingsModule/components/Budget/EditableNameCell';
import { EditableEmailCell } from 'SettingsModule/components/Budget/EditableEmailCell';
import RoleCell from 'SettingsModule/components/Budget/RoleCell';
import HeaderCell from 'SettingsModule/components/Budget/HeaderCell';
import { SORT_BY } from 'appConstants/filters';
import CostCell from 'SettingsModule/components/Budget/CostCell';
import { VIEW_BY } from 'appConstants/workload';
import RemoveMemberModal from './RemoveMemberModal';
import { fetchTeamRoleTemplates } from 'RoleTemplatesModule/actionCreators';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { permissionsUtils } from 'PermissionsModule/utils';
import { permissionConstants } from 'PermissionsModule/constants';
import { useMemberListItemsBuilder } from 'PermissionsModule/components/settingsViews/members/MembersPermissionsTable/helpers/useMemberListItemsBuilder';
import { EditMemberMetadataModal } from 'TeamsModule/components/EditMemberMetadataModal';

const { EMPLOYMENT_TYPES } = permissionConstants;
/*
Cells handle actions differently for the create row - update redux createRowData with correct key/value pairs. Data gets serialized like other timesheets via selectors so we can re-use existing timesheet components.
*/

const emptyRow = { rowType: 'emptyRow' };

const buildHeader = ({ list, customRowProps }) => {
  const { id, headerType, isOpen, headerHeight, renderHeader, headerData } =
    list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    renderHeader,
    customRowProps
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: list.rowHeight || item.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps,
  onRequestEditMemberMetadata: list.onRequestEditMemberMetadata
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      listItems.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
      if (!isFullyLoaded) {
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }
  if (lists.every((list) => list.isFullyLoaded || !list.isOpen)) {
    flatList.push(
      buildCustomItem({
        list: lists[lists.length - 1] || { getItemId: (item) => item?.id },
        customRowProps,
        item: emptyRow
      })
    );
  }
  return flatList;
};

const EmptyDiv = () => <div />;
const HeaderDiv = ({ row }) => (
  <ListHeader>{row?.original?.list?.renderHeader()}</ListHeader>
);
const memberTypeComponents = {
  [SORT_BY.member]: HeaderDiv,
  [SORT_BY.cost]: EmptyDiv,
  [SORT_BY.rate]: EmptyDiv,
  [SORT_BY.rateMultiplier]: EmptyDiv,
  [SORT_BY.permissionLevel]: EmptyDiv,
  [SORT_BY.email]: EmptyDiv,
  [SORT_BY.role]: EmptyDiv,
  [SORT_BY.email_notification_on]: EmptyDiv
};

const cellComponents = {
  // [SORT_BY.member]: MembersPermissionsTableNameCell,
  [SORT_BY.member]: EditableNameCell,
  [SORT_BY.cost]: CostCell,
  [SORT_BY.rate]: TeamRateCell,
  [SORT_BY.rateMultiplier]: RateMultiplierCell,
  [SORT_BY.permissionLevel]: PermissionLevelCell,
  [SORT_BY.email]: EditableEmailCell,
  [SORT_BY.role]: RoleCell,
  [SORT_BY.email_notification_on]: EmailNotificationCell
};

const headerComponents = {
  status: EmptyDiv
};

const columnWidths = {
  [SORT_BY.member]: 250,
  [SORT_BY.email]: 250,
  [SORT_BY.cost]: 250,
  [SORT_BY.rate]: 320,
  [SORT_BY.rateMultiplier]: 140,
  [SORT_BY.permissionLevel]: 320,
  [SORT_BY.role]: 170,
  [SORT_BY.email_notification_on]: 170
};

const ITEM_HEIGHT = 62;
const MAX_TABLE_HEIGHT_BUFFER = 90 + 85 + 33 + 35; // Nav + Header + Sticky Header + margin

const noop = () => {};

const rowIsLoaded = (row) => row.createRow || row.project?.id;

const getOrderedMemberTypes = ({ newPermissionSettings }) => {
  if (newPermissionSettings) {
    return [
      { memberType: [EMPLOYMENT_TYPES.member], label: 'Member' },
      {
        memberType: [EMPLOYMENT_TYPES.internalContractor],
        label: 'Contractor - Internal'
      },
      {
        memberType: [EMPLOYMENT_TYPES.externalProjectContractor],
        label: 'Project Contractor - External'
      },
      { memberType: [EMPLOYMENT_TYPES.projectGuest], label: 'Project Guest' },
      { memberType: 'archived', label: 'Archived' }
    ];
  }

  return [
    { memberType: [EMPLOYMENT_TYPES.member], label: 'Member' },
    { memberType: [EMPLOYMENT_TYPES.projectGuest], label: 'Project Guest' },
    { memberType: 'archived', label: 'Archived' }
  ];
};

const BudgetAdminTable = ({ loadMoreItems }) => {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const { newPermissionSettings } = useFeatureFlags();
  const fetchTeamPermissionsRequestStatusId = `${fetchTeamPermissions.type}-member-management-table`;

  // When opened editMemberMetadataModalParams is an object that contains the
  // teamMembershipId of the user we want to edit.
  const [editMemberMetadataModalParams, setEditMemberMetadataModalParams] =
    useState(undefined);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchRates({ teamId }));
      // dispatch(fetchTeamMembersRates({ teamId }));
      if (!newPermissionSettings) {
        dispatch(fetchTeamRoleTemplates({ teamId }));
      } else {
        dispatch(
          fetchTeamPermissions({
            teamId,
            meta: {
              requestStatusId: fetchTeamPermissionsRequestStatusId
            }
          })
        );
      }
      dispatch(fetchAllTeamRates({ teamId }));
      dispatch(fetchTeamPositions({ teamId }));
      dispatch(fetchPositions({ teamId }));
    }
  }, [
    dispatch,
    fetchTeamPermissionsRequestStatusId,
    newPermissionSettings,
    teamId
  ]);

  const formattedMemberBudgetSettings = useSelector(getFormattedBudgetSettings);
  const budgetSettingsColumnHeaders = useSelector(
    getMemberManagementColumnHeaders
  );
  const totalMembersCount = useSelector(getTeamMembers)?.length;

  useEffect(
    () => {
      // do something when rates change
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, teamId, formattedMemberBudgetSettings]
  );
  const columns = useMemo(() => {
    return budgetSettingsColumnHeaders.map((timesheetColumnHeader) => ({
      ...timesheetColumnHeader,
      emptyRow: EmptyDiv,
      memberRow: cellComponents[timesheetColumnHeader.headerType],
      memberTypeRow: memberTypeComponents[timesheetColumnHeader.headerType],
      Cell: cellComponents[timesheetColumnHeader.headerType],
      Header: headerComponents[timesheetColumnHeader.headerType] || HeaderCell,
      Footer: HeaderCell,
      width: columnWidths[timesheetColumnHeader.headerType]
    }));
  }, [budgetSettingsColumnHeaders]);

  const memberTypeGroupedRowBuilder = useCallback(
    ({ formattedMembers = [], customRowProps, memberTypes }) => {
      const memberTypeLists = memberTypes.reduce((acc, cur, index) => {
        acc[cur.memberType] = {
          listItems: [],
          closedItems: [],
          customItems: [],
          headerData: {},
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: 'memberRow',
          headerType: 'memberTypeRow',
          headerHeight: 32,
          id: cur.memberType,
          skipEmptyGroups: false,
          isOpen: true,
          isEmpty: false,
          isFullyLoaded: null,
          renderHeader: () => cur.label,
          summaryNoun: 'member',
          createRowData: {},
          renderSummaryItem: (item) => item,
          onRequestEditMemberMetadata: setEditMemberMetadataModalParams
        };
        return acc;
      }, {});

      formattedMembers.forEach((formattedMember, index) => {
        const { member } = formattedMember;
        const listToPush = permissionsUtils.getIsArchived(member)
          ? 'archived'
          : permissionsUtils.getIsBaseMember(member)
          ? EMPLOYMENT_TYPES.member
          : permissionsUtils.getIsInternalContractor(member)
          ? EMPLOYMENT_TYPES.internalContractor
          : permissionsUtils.getIsExternalProjectContractor(member)
          ? EMPLOYMENT_TYPES.externalProjectContractor
          : EMPLOYMENT_TYPES.projectGuest;

        memberTypeLists?.[listToPush]?.listItems?.push(formattedMember);
      });

      const listsWithItems = [];
      const listsWithoutItems = [];

      memberTypes.forEach(({ memberType }) => {
        const list = memberTypeLists[memberType];

        if (list) {
          list.isFullyLoaded = true;

          const listToPush = list.isEmpty ? listsWithoutItems : listsWithItems;
          listToPush.push(list);
        }
      });
      const listsInOrder = [...listsWithItems, ...listsWithoutItems];
      if (listsInOrder.length) {
        const [firstList] = listsInOrder;
        firstList.customItems = [];
        firstList.closedItems = [];
      }
      return listsInOrder;
    },
    []
  );

  const listBuilders = useMemo(
    () => ({
      [VIEW_BY.MEMBER_TYPES]: memberTypeGroupedRowBuilder
    }),
    [memberTypeGroupedRowBuilder]
  );

  const lists = useMemo(() => {
    const listBuilder = listBuilders[VIEW_BY.MEMBER_TYPES];
    if (!listBuilder) {
      return [];
    }
    return listBuilder({
      formattedMembers: formattedMemberBudgetSettings,
      memberTypes: getOrderedMemberTypes({ newPermissionSettings }),
      // members: orderedTeamMembers,
      // projects: orderedProjects,
      // phases: orderedPhases,
      customRowProps: {}
    });
  }, [formattedMemberBudgetSettings, listBuilders, newPermissionSettings]);

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps: {},
      engaged: {},
      search: {},
      emptyRow
    });
  }, [lists]);

  const isLoading = !totalMembersCount;

  return (
    <StyledBudgetSettingsTable>
      <Table
        columns={columns}
        data={!isLoading ? rows : []}
        onDragEnd={noop}
        virtual
        itemHeight={ITEM_HEIGHT}
        maxHeight={window.innerHeight - MAX_TABLE_HEIGHT_BUFFER}
        loadMoreItems={loadMoreItems}
        isVariableSizeTable
        listRef={listRef}
        isDragDisabled
      />

      {isLoading && (
        <div style={{ width: '100%' }}>
          <ContentLoader
            height="200"
            primaryColor="#ddd"
            secondaryColor="#eee"
            style={{ width: '640px', margin: '-10px 20px 0px' }}
          >
            <rect x="0" y="5" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="25" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="45" rx="2" ry="2" width="100%" height="15" />
            <rect x="0" y="66" rx="2" ry="2" width="100%" height="15" />
          </ContentLoader>
        </div>
      )}
      <RemoveMemberModal />
      {editMemberMetadataModalParams && (
        <EditMemberMetadataModal
          teamMembershipId={editMemberMetadataModalParams.teamMembershipId}
          onToggle={() => setEditMemberMetadataModalParams(undefined)}
        />
      )}
    </StyledBudgetSettingsTable>
  );
};

export default BudgetAdminTable;
