import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import TaskGroupCollapseIcon from 'icons/TaskGroupCollapseIcon';

export const CollapseIconContainer = styled.div`
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(-90deg)' : '')};
  width: fit-content;
  height: fit-content;

  ${(props) => props.isHidden && 'visibility: hidden;'}

  rect {
    stroke: ${({ isCollapsed }) =>
      isCollapsed ? theme.colors.colorPaleGray5 : 'transparent'};
    fill: ${({ isCollapsed }) =>
      isCollapsed ? theme.colors.colorPureWhite : 'transparent'};
  }
  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }

  &:hover {
    rect {
      fill: ${theme.colors.colorPureWhite};
      stroke: ${theme.colors.colorLightGray1};
    }
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
  ${(props) =>
    props.noBackground &&
    `
    rect {
      stroke: transparent !important;
      fill: transparent !important;
    }
  `}
`;

const CollapseArrow = ({
  testId = 'collapse-arrow',
  toggleCollapse,
  isCollapsed,
  style = {},
  iconSize = undefined,
  className = 'collapse-arrow',
  noBackground = true,
  isHidden = false
}) => {
  return (
    <CollapseIconContainer
      className={className}
      isCollapsed={isCollapsed}
      onClick={toggleCollapse}
      noBackground={noBackground}
      style={style}
      data-testid={testId}
      isHidden={isHidden}
    >
      <TaskGroupCollapseIcon height={iconSize} width={iconSize} />
    </CollapseIconContainer>
  );
};

export default CollapseArrow;
