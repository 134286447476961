import React from 'react';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

import { getSelectedTeamId } from 'selectors';
import { makeGetActivePhases } from 'BudgetModule/selectors';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import ReactTooltip from 'react-tooltip';
import PhasesOfWorkDropdownItem from './PhasesOfWorkDropdownItem';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import { openPhaseTemplateDropdown } from 'actionCreators';

const Footer = (
  <LockWithTooltip
    text="Manage Standard Phases"
    tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Phases <br /> because they affect Project Budgets."
  />
);

const defaultCopy = {
  headerInitial: 'Select Phase',
  headerEdit: 'Add/Edit Standard Phases',
  headerAdd: 'New Phase',
  sticky: 'Add New Phase',
  footerInitial: Footer,
  footerEdit: Footer,
  addConfirm: 'Add',
  editConfirm: 'Save',
  searchPlaceholder: 'Type Phase or select below'
};

class PhasesOfWorkDropdown extends React.Component {
  renderItem = ({ item }) => (
    <PhasesOfWorkDropdownItem item={item} phaseId={item.id} />
  );

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    const { handleSelect } = this.props;
    handleSelect(item);

    if (selectCallback) {
      selectCallback();
    }
  };

  // unused currently, can be passed to multistepflyout to enable search
  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });

  renderHeader = () => {
    const { renderHeader, renderHeaderCopy } = this.props;
    return renderHeader ? renderHeader(renderHeaderCopy) : 'Select Phase';
  };

  getPermissions = () => {
    return {
      teamId: this.props.teamId
    };
  };

  onFooterClick = () => {
    const { openPhaseTemplateDropdown, onFooterClick, handleClose } =
      this.props;
    if (onFooterClick) {
      onFooterClick();
      handleClose();
    }
    const permissions = this.getPermissions();
    openPhaseTemplateDropdown({ permissions });
    ReactTooltip.hide();
    handleClose();
  };

  onStickyClick = (e) => {
    const { onStickyClick } = this.props;
    if (onStickyClick) {
      onStickyClick();
    }
    // const { openPhaseTemplateDropdown } = this.props;
    // openPhaseTemplateDropdown();
  };

  render() {
    const {
      phases,
      target,
      handleClose,
      renderHeaderButton,
      hideFooter = true,
      copy = defaultCopy,
      hideEditIcon,
      hideSticky = false
    } = this.props;

    return (
      <MultiStepFlyout
        copy={{ ...defaultCopy, ...copy }}
        items={phases}
        idKey="id"
        renderHeader={this.renderHeader}
        renderHeaderButton={renderHeaderButton}
        renderItem={this.renderItem}
        handleSelect={this.handleSelect}
        stickyRow={!hideSticky}
        isWhite
        top={0}
        target={target}
        onStickyClick={this.onStickyClick}
        hideFooter={hideFooter}
        handleClose={handleClose}
        onFooterClick={this.onFooterClick}
        hideEditIcon={hideEditIcon}
      />
    );
  }
}

const getActivePhases = makeGetActivePhases();
const mapStateToProps = (state, ownProps) => ({
  phases: ownProps.phases || getActivePhases(state, ownProps),
  teamId: getSelectedTeamId(state)
});

export default connect(mapStateToProps, { openPhaseTemplateDropdown })(
  PhasesOfWorkDropdown
);
