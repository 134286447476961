import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface FilledArrowProps
  extends Pick<SvgIconProps, 'className' | 'width' | 'height' | 'style'> {
  color?: string;
}

/**
 * @deprecated Use tightly cropped version from `./FilledArrowV2.tsx`.
 */
export const FilledArrow = ({
  className,
  color = '#828282',
  width = 6,
  height = 6,
  style
}: FilledArrowProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 6 6"
    style={style}
  >
    <path
      d="M1.25 2.16501L5.58013 2.16501L3.41506 5.91501L1.25 2.16501Z"
      fill={color}
    />
  </SvgIcon>
);

export default FilledArrow;
