import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'moment';
import { isToday as isTodayHelper } from 'appUtils/momentUtils';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  align-items: center;
`;

const StyledText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: ${({ isToday }) =>
    isToday ? theme.colors.colorLightOrange2 : theme.colors.colorMediumGray9};
`;

const TodayText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorLightOrange2};
  font-weight: 600;
  padding-right: 7px;
`;

const DateCell = ({ row }) => {
  const { userActivity } = row.original;
  const date = userActivity?.display_data?.time_entry?.date;
  const makeTimestamp = (timestamp) =>
    moment(timestamp).calendar(null, {
      sameDay: 'h:mm a',
      lastDay: 'M/D/YY',
      lastWeek: 'M/D/YY',
      nextWeek: 'M/D/YY',
      sameElse: 'M/D/YY'
    });
  const timestamp = makeTimestamp(date);
  const isToday = isTodayHelper(date);

  return (
    <TextContainer>
      {isToday && <TodayText> Today </TodayText>}
      {!isToday && <StyledText isToday={isToday}>{timestamp}</StyledText>}
    </TextContainer>
  );
};

export default DateCell;
