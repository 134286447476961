import React, { useEffect, useMemo, useCallback, useState } from 'react';
import moment from 'moment';
import pickBy from 'lodash/pickBy';
import keyBy from 'lodash/keyBy';

import { useDispatch, useSelector } from 'react-redux';
import TasksTable from './TasksTable';

import {
  fetchTasksV2,
  fetchPhasesByProjectIds,
  fetchAllProjects,
  exportTimesheets,
  triggerTaskStoreFlush,
  resetTaskFilters,
  setActiveTaskFilter,
  fetchActivities
} from 'actionCreators';
import {
  getMyUserId,
  getPhasesByProjectHash,
  getProjectHash,
  getIsLoadingPhases,
  getIsFetchingProjects,
  getAccountFiltersFetched,
  getTaskGroups,
  getV2TasksArray,
  getSelectedProject,
  getIsMemberModalOpen,
  getCurrentFilter,
  getSelectedTeamId
} from 'selectors';

import { TIMESHEET_SORT_API_PARAMS } from 'appConstants/filters';
import { VIEW_BY } from 'appConstants/workload';
import { getTaskFetchConfig } from 'sagas/homeTasks';

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

// don't filter by empty arrays - no data will be returned
const getActiveFilterParams = (filter) =>
  pickBy(filter, (value) => !Array.isArray(value) || value.length);

const scheduledHash = {
  unscheduled: false,
  scheduled: true
};

const completedParamHash = {
  completed: true,
  incomplete: false,
  unscheduled: false,
  scheduled: false,
  overdue: false,
  today: false,
  default: undefined
};
const sortValuesHash = {
  assignee: 'primary_assignee'
};
const directionHandlers = {
  priority: {
    asc: 'desc',
    desc: 'asc'
  }
};
const defaultSort = { attribute: 'task_group', direction: 'asc' };
const viewBySortConfigs = {
  [VIEW_BY.TASK_GROUPS]: defaultSort,
  [VIEW_BY.PHASES]: { attribute: 'activity_phase', direction: 'asc' } // required for specific phase -> activity dnd order
};

/**
 *
 * It requires clean up task store and filters somewhere in parents
 * `triggerFetchInitialTasks`, `triggerTaskStoreFlush` should be called when it unmounted
 *
 */
const TasksTableContainer = ({
  // activeFilter,
  viewBy, // pass from above in order to configure filter selections
  customFilter,
  mergeCustomFilter = false,
  customOptions,
  customHeaders,
  customCreateRowData = {},
  customHeaderData,
  tableHeightBuffer,
  handleAdditionalDragTypes,
  hideSubmitWidget
}) => {
  const myId = useSelector(getMyUserId);
  const value = 'projects';
  const order = 'desc';
  let start, end;
  const tasks = useSelector(getV2TasksArray);
  const taskCount = useSelector((state) => state.homeTasks.taskCount);
  const offset = useSelector((state) => state.homeTasks.offset);
  const phasesByProjectHash = useSelector(getPhasesByProjectHash);
  const projectHash = useSelector(getProjectHash);
  const isFetchingPhases = useSelector(getIsLoadingPhases);
  const isFetchingProjects = useSelector(getIsFetchingProjects);
  const isFetchingTasks = useSelector((state) => state.homeTasks.isLazyLoading);
  const filtersFetched = useSelector(getAccountFiltersFetched);
  const [groupsFullyLoadedOnce, setGroupsFullyLoadedOnce] = useState({});
  const selectedProject = useSelector(getSelectedProject);
  const orderedTaskGroups = useSelector(getTaskGroups);
  const isMemberModalOpen = useSelector(getIsMemberModalOpen);
  const currentFilter = useSelector(getCurrentFilter);
  const teamId = useSelector(getSelectedTeamId);

  const phaseInfo = phasesByProjectHash[selectedProject?.id] || {};
  const { phases = [], phase_orders = [] } = phaseInfo;

  const phaseHash = keyBy(phases, (phase) => phase.id);

  const orderedPhases = phase_orders
    .map((id) => phaseHash[id])
    .filter((phase) => phase && !phase.archived);

  const {
    page,
    search,
    filter,
    sort,
    direction,
    limit,
    tag_id,
    selectedAccountIds
  } = useSelector(getTaskFetchConfig);
  const dispatch = useDispatch();

  const activeFilter = useMemo(() => {
    if (page === 'somethingPageDoesntEqual') {
      // prevent eslint from removing these
      console.log({
        page,
        search,
        filter,
        sort,
        direction,
        currentFilter,
        limit,
        tag_id,
        selectedAccountIds
      });
    }
    const viewBySort = viewBySortConfigs[viewBy] || defaultSort;
    return {
      team_id: isMemberModalOpen ? teamId : undefined,
      assignee_ids: selectedAccountIds,
      complete: completedParamHash[currentFilter.state],
      scheduled_start: scheduledHash[currentFilter.state],
      tag_ids: tag_id ? [tag_id] : undefined,
      search: search?.length ? search : undefined,
      sort_attributes: isMemberModalOpen
        ? sort
          ? [
              {
                attribute: sortValuesHash[sort] || sort,
                direction:
                  directionHandlers[sort]?.[direction] || direction || 'asc'
              }
            ]
          : []
        : sort
        ? [
            viewBySort,
            {
              attribute: sortValuesHash[sort] || sort,
              direction:
                directionHandlers[sort]?.[direction] || direction || 'asc'
            }
          ]
        : completedParamHash[currentFilter.state]
        ? [viewBySort, { attribute: 'completed_at', direction: 'asc' }]
        : [viewBySort]
    };
  }, [
    page,
    search,
    filter,
    sort,
    direction,
    currentFilter,
    limit,
    tag_id,
    selectedAccountIds,
    isMemberModalOpen,
    teamId,
    viewBy
  ]);

  const filterParams = useMemo(() => {
    if (customFilter && !mergeCustomFilter) {
      return customFilter;
    }
    const activeFilterParams = getActiveFilterParams(activeFilter);

    return {
      ...activeFilterParams,
      ...(customFilter || {})
    };
  }, [activeFilter, customFilter, mergeCustomFilter]);

  // todo - figure out why this is rerendering
  const filterEquality = useMemo(
    () => JSON.stringify(filterParams),
    [filterParams]
  );

  useEffect(() => {
    dispatch(
      setActiveTaskFilter({
        activeFilter: filterParams
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEquality, dispatch]);

  /*
    load initial items when params change
    params include filter/sort, no timesheet data
    this resets offset for subsequent load more
  */

  const fetchParams = useMemo(() => {
    const body = {
      offset: 0,
      limit: 50,
      ...filterParams
    };
    return body;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end, filterEquality, order, start, value, viewBy]);

  useEffect(() => {
    if (myId) {
      if (!customFilter?.project_ids || isMemberModalOpen || !filtersFetched) {
        return;
      }
      dispatch(fetchTasksV2({ body: fetchParams, initial: true }));
      setGroupsFullyLoadedOnce({});
    }
  }, [
    dispatch,
    myId,
    filterEquality,
    filtersFetched,
    customFilter,
    fetchParams,
    value,
    isMemberModalOpen
  ]);

  /*
    different than the fetch in useEffect above, this must be called explicitly.
    our implementation of react-table handles virtualization + lazy-loading and will call loadMoreItems when it reaches the end of the list.
  */
  const loadMore = useCallback(
    (fetchOffset) => {
      if (isFetchingTasks) {
        return;
      }
      if (!customFilter?.project_ids) {
        return;
      }
      const body = {
        ...filterParams,
        offset: fetchOffset ?? offset,
        limit: 50
      };
      dispatch(fetchTasksV2({ body }));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isFetchingTasks,
      value,
      myId,
      filtersFetched,
      start,
      end,
      offset,
      viewBy,
      order,
      filterParams,
      taskCount,
      customFilter,
      dispatch
    ]
  );

  const handleExport = useCallback(
    (exportType = true) => {
      const sortAttribute = TIMESHEET_SORT_API_PARAMS[value];
      if (sortAttribute && myId) {
        const body = {
          start_date: formatFetchDate(start),
          end_date: formatFetchDate(end),
          export: exportType,
          offset: 0,
          all: true,
          // sort_attributes: getSortParams({
          //   viewBy,
          //   sortOrder: order,
          //   sortValue: sortAttribute
          // }),
          ...filterParams
        };

        dispatch(
          exportTimesheets({
            body,
            initial: false
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [myId, start, end, filterParams, dispatch]
  );

  /*
    load unloaded phases if not already fetching
    This does not 'miss out' on phases because the unloaded list is always current.

    This useMemo + useEffect pattern is a common pattern similar to componentDidMount + componentDidUpdate w/ prop comparisons.
  */
  const unloadedPhasesProjectIds = useMemo(
    () =>
      tasks
        .map((task) => task.project_id)
        .filter((id) => !phasesByProjectHash[id]),
    [phasesByProjectHash, tasks]
  );
  useEffect(() => {
    if (unloadedPhasesProjectIds.length && !isFetchingPhases) {
      dispatch(
        fetchPhasesByProjectIds({ projectIds: unloadedPhasesProjectIds })
      );
    }
  }, [dispatch, unloadedPhasesProjectIds, isFetchingPhases]);

  // load projects
  const unloadedProjectIds = useMemo(
    () => tasks.map((task) => task.project_id).filter((id) => !projectHash[id]),
    [projectHash, tasks]
  );

  useEffect(() => {
    if (unloadedProjectIds.length && !isFetchingProjects) {
      dispatch(fetchAllProjects({ projectIds: unloadedProjectIds }));
      dispatch(fetchPhasesByProjectIds({ projectIds: unloadedProjectIds }));
    }
  }, [dispatch, unloadedProjectIds, isFetchingProjects]);

  useEffect(() => {
    if (myId) {
      dispatch(fetchActivities({}));
    }
  }, [dispatch, myId]);

  return (
    <TasksTable
      currentFilter={currentFilter}
      loadMore={loadMore}
      orderedTaskGroups={orderedTaskGroups}
      orderedPhases={orderedPhases}
      projectHash={projectHash}
      customHeaders={customHeaders}
      // isWhite={isWhite}
      customCreateRowData={customCreateRowData}
      customHeaderData={customHeaderData}
      tableHeightBuffer={tableHeightBuffer}
      groupsFullyLoadedOnce={groupsFullyLoadedOnce}
      setGroupsFullyLoadedOnce={setGroupsFullyLoadedOnce}
      handleAdditionalDragTypes={handleAdditionalDragTypes}
    />
  );
};

export default TasksTableContainer;
