import React from 'react';
import { TaskPropertyCell, NewTaskProperty } from './styles';
import { connect } from 'react-redux';
import {
  getOrderedTaskStatuses,
  makeGetOwnTaskStatus,
  getIsOnProjectView
} from 'selectors';
import {
  StyledTaskStatusContainer,
  StyledTaskStatusDot,
  StyledTaskStatusText,
  EmptyPropertyContainer
} from '../TaskList/styles';
import OptionsSelectFlyout from 'components/OptionsSelectFlyout/OptionsSelectFlyout';
import TaskStatusListItem from './TaskStatusListItem';

const copy = {
  addEdit: 'Add/Edit Status',
  createNew: 'Create New Status'
};

class TaskStatus extends React.PureComponent {
  state = {
    isDropdownOpen: false
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };

  renderSelectedItem = (item) => (
    <StyledTaskStatusContainer
      data-for="app-tooltip"
      data-class="center mw-250 break-word-tooltips"
      data-tip={item.title}
      data-html
    >
      <StyledTaskStatusDot color={item.color} />
      <StyledTaskStatusText className="no-text-overflow">
        {item.title}
      </StyledTaskStatusText>
    </StyledTaskStatusContainer>
  );

  render() {
    const {
      isNewTask,
      taskIsEditing,
      taskId,
      taskStatuses,
      status = {},
      onSubmit,
      isOnProjectView
    } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <TaskPropertyCell onClick={this.toggleDropdown}>
        <OptionsSelectFlyout
          selectedItem={status}
          listItems={taskStatuses}
          rowId={taskId}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={this.toggleDropdown}
          onSubmit={onSubmit}
          ListItemComponent={TaskStatusListItem}
          copy={copy}
          droppableType="task-statuses"
          renderSelectedItem={this.renderSelectedItem}
          isOnProjectView={isOnProjectView}
        />
        {!status.title && (
          <NewTaskProperty
            $isNewTask={isNewTask}
            $showOnHover
            $taskIsEditing={taskIsEditing}
          >
            <EmptyPropertyContainer>
              {/* <StyledTaskStatusIcon /> */}
              Status
            </EmptyPropertyContainer>
          </NewTaskProperty>
        )}
      </TaskPropertyCell>
    );
  }
}

const makeMapStateToProps = () => {
  const getOwnTaskStatus = makeGetOwnTaskStatus();
  const mapStateToProps = (state, ownProps) => ({
    status: getOwnTaskStatus(state, ownProps),
    taskStatuses: getOrderedTaskStatuses(state),
    isOnProjectView: getIsOnProjectView(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TaskStatus);
