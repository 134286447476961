import React from 'react';
import { connect } from 'react-redux';
import ProjectsAndPhasesDropdown from 'components/ProjectsAndPhasesDropdown/ProjectsAndPhasesDropdown';
import { createDescription, fetchTimesheetsPredictions } from 'actionCreators';
import {
  getTimelineMemberId,
  getTimesheetDateKeys,
  makeSuggestedProjectHash,
  getSplitFlags
} from 'selectors';
import { AddEntryRow } from './styles';
import moment from 'moment';
import { formatOnSuccess } from 'appUtils/formatUtils';
import { getProjectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts } from 'SuggestionModule/selectors';
import { makeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccounts } from 'SuggestionModule/utils';

class AddRow extends React.Component {
  state = { modalOpen: false };
  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  renderDropdownHeader = (copy) => <div>{copy}</div>;

  handlePredictionsFetch = (phaseId) => {
    const { accountId, startOfWeek, fetchTimesheetsPredictions } = this.props;
    fetchTimesheetsPredictions({
      accountId,
      startDate: moment(startOfWeek).format('YYYY-MM-DD'),
      endDate: moment(startOfWeek).endOf('week').format('YYYY-MM-DD'),
      phaseIds: [phaseId]
    });
  };

  handleSelectProjectOrPhase = (projectOrPhase) => {
    const {
      createDescription,
      accountId,
      startOfWeek,
      fetchTimesheetsPredictions
    } = this.props;

    const { is_phase, hasPhases } = projectOrPhase;
    if (is_phase) {
      createDescription({
        phaseId: projectOrPhase.id,
        date: startOfWeek,
        projectId: projectOrPhase.project_id,
        accountId,
        onSuccess: formatOnSuccess(() =>
          this.handlePredictionsFetch(projectOrPhase.id)
        )
      });
    } else if (!hasPhases) {
      createDescription({
        phaseId: projectOrPhase.defaultOrMainPhaseId,
        date: startOfWeek,
        projectId: projectOrPhase.id,
        accountId
      });
    }
  };

  render() {
    const { modalOpen } = this.state;
    const {
      accountId,
      suggestedProjectHash,
      projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts,
      flags
    } = this.props;

    const { projectsAndPhases } =
      projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts;
    const formattedProjectsAndPhasesWithPhaseSuggestionsForMembers =
      makeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccounts({
        ...projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts, // Very specific phase suggestion for Account on Timesheet space
        accountId
      });

    return (
      <>
        <AddEntryRow
          ref={(ref) => (this.target = ref)}
          onClick={this.openModal}
        >
          Add Time Entry
        </AddEntryRow>
        {modalOpen && (
          <ProjectsAndPhasesDropdown
            target={this.target}
            handleSelect={this.handleSelectProjectOrPhase}
            handleClose={this.closeModal}
            renderHeader={this.renderDropdownHeader}
            renderHeaderCopy={'Select Work Category'}
            accountId={accountId}
            text={'add a time entry'}
            suggestedProjectHash={suggestedProjectHash}
            projectsAndPhases={
              formattedProjectsAndPhasesWithPhaseSuggestionsForMembers
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    accountId: getTimelineMemberId(state),
    startOfWeek: getTimesheetDateKeys(state)[0],
    suggestedProjectHash: makeSuggestedProjectHash(state),
    projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts:
      getProjectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts(
        state,
        ownProps
      ),
    flags: getSplitFlags(state)
  };
};

const mapDispatchToProps = {
  createDescription,
  fetchTimesheetsPredictions
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRow);
