export const formatCurrencyValue = (
  currencyFormatter: Intl.NumberFormat,
  currencyValue: number
): string => {
  return currencyFormatter.format(currencyValue);
};

export const formatCurrencySymbol = (
  currencyFormatter: Intl.NumberFormat,
  currencyValue: number
): string => {
  const currencySymbol =
    currencyFormatter
      .formatToParts(currencyValue)
      .find((part) => part.type === 'currency')?.value ?? '';

  return currencySymbol;
};
