import React from 'react';
import { useSelector } from 'react-redux';
import { TaskDragHandlePanel } from '../styles';
import DragGripIcon from 'icons/DragGripIcon';
import { getCurrentFilter } from 'selectors';
import CellContainer from './CellContainer';

const DragCell = ({ row }) => {
  const currentFilter = useSelector(getCurrentFilter);
  return (
    <CellContainer taskProps={row.original.taskProps} taskProperty="drag">
      <TaskDragHandlePanel
        currentFilter={currentFilter}
        taskIsEditing={row.editingProperty}
        isVirtual
        isFirstRow={row.original.isFirstRow}
      >
        <DragGripIcon />
      </TaskDragHandlePanel>
    </CellContainer>
  );
};
export default DragCell;
