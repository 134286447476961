import { useState, useCallback } from 'react';
import FindPeopleModal from 'SuggestionModule/components/FindPeople/Modal/FindPeopleModal';

const defaultModalProps = {
  unassignedMemberBudgetId: null,
  projectId: null
};

type ModalPropsType = {
  unassignedMemberBudgetId?: Nullable<number>;
  projectId?: Nullable<number>;
  disableChangingProject?: boolean;
};

const useFindPeopleModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] =
    useState<ModalPropsType>(defaultModalProps);

  const handleOpenFindPeopleModal = useCallback((props: ModalPropsType) => {
    setIsOpen(true);

    if (props) {
      setModalProps(props);
    }
  }, []);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const ModalComponent = useCallback(() => {
    return isOpen && <FindPeopleModal {...modalProps} toggle={toggleModal} />;
  }, [toggleModal, isOpen, modalProps]);

  return {
    FindPeopleModal: ModalComponent,
    handleOpenFindPeopleModal,
    toggleFindPeopleModal: toggleModal
  };
};

export default useFindPeopleModal;
