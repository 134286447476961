import styled from 'styled-components';
import theme from 'theme';
import QBDownArrow from 'icons/QBDownArrow';

const dimOnHoverStyle = 'filter: brightness(98%);';

const StyledToggle = styled.div`
  .scope-table-header &,
  .budget-table-header &,
  .report-page &,
  .activity-side-menu-filter & {
    background: ${theme.colors.colorPaleGray2};
    padding: 3px 8px;
    font-size: 12px;
    color: ${({ hasSelectedItems }) =>
      hasSelectedItems
        ? theme.colors.colorCalendarBlue
        : theme.colors.colorMediumGray9};
    width: max-content;
    border-radius: 6px;
    &:hover {
      ${dimOnHoverStyle}
    }
    ${(props) => props.isOpen && dimOnHoverStyle}
  }
  cursor: pointer;
`;

const Arrow = styled(QBDownArrow)`
  margin-left: 5px;
  margin-bottom: 1px;
  path {
    fill: ${theme.colors.colorMediumGray9};
  }
  ${(props) =>
    props.isOpen &&
    `
    transform: rotate(180deg);
  `}
`;

const FilterDropdownToggle = ({
  count,
  maxCount,
  label,
  pluralLabel,
  allSelectedCopy,
  isOpen
}) => {
  return (
    <StyledToggle
      isOpen={isOpen}
      hasSelectedItems={count > 0}
      className="filter-dropdown-toggle"
      data-testid={`${label}-filter-dropdown-toggle`}
    >
      {maxCount === count && !!allSelectedCopy
        ? allSelectedCopy
        : `${count ? count + ' ' : ''}${
            count > 1 ? pluralLabel || label + 's' : label
          }`}

      <Arrow isOpen={isOpen} />
    </StyledToggle>
  );
};

export default FilterDropdownToggle;
