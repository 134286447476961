import { useSelector } from 'react-redux';
import { getIsUserPermissionsLoaded, getUserIsLoaded } from 'selectors';
import Can from 'components/Can/Can';
import { useRouteMatch } from 'react-router-dom';
import { SectionRoutes } from 'appConstants/navigation';
import { useReportPermissions } from 'appUtils/hooks/useReportPermissions';

const ReportsPermissionCheck = ({
  children,
  viewType,
  navToHomeIfCantRender = false
}) => {
  const isUserPermissionsLoaded = useSelector(getIsUserPermissionsLoaded);
  const isUserLoaded = useSelector(getUserIsLoaded);

  const { params } = useRouteMatch(SectionRoutes.REPORTS);
  const { viewType: reportType } = params;
  const { reportPermissionHash } = useReportPermissions();

  const canView = reportPermissionHash[viewType || reportType];

  // If viewType and reportType are both undefined then report landing page.
  const isReportLandingPage = !viewType && !reportType;

  return (
    <Can
      loadingCondition={!(isUserPermissionsLoaded && isUserLoaded)}
      navToHomeIfCantRender={navToHomeIfCantRender}
      renderCondition={canView || isReportLandingPage}
    >
      {children}
    </Can>
  );
};

export default ReportsPermissionCheck;
