import { useGroupedMembersFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { useSkills } from 'SettingsModule/hooks';

export const useMembersBySkillFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  field,
  shouldUseDraft
} = {}) => {
  const { handleNavToSkillsPage, skillHash, getSkillLabel } = useSkills({
    isOff
  });

  const groupedMembersFilterValues = useGroupedMembersFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'membersBySkillFilter',
    filterListType: FilterListType.MembersBySkill,
    field,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    handleNavToEntityPage: handleNavToSkillsPage,
    groupEntityHash: skillHash,
    getGroupEntityLabel: getSkillLabel
  };
};
