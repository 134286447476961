import { useEffect } from 'react';
import cn from 'classnames';
import theme from 'theme';
import LightBulbIcon from 'icons/LightBulbIcon';
import styled from 'styled-components';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const SuggestionIconContainer = styled.span`
  position: absolute !important;
  right: -30px;
  top: -1px;
`;

const AddMemberToListButton = ({
  projectId,
  newMember,
  notInBoard,
  Guest,
  isSuggestedMember
}) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <div className={cn('add-to-board')}>
      {newMember ? (
        <span className="remove-member">Remove</span>
      ) : (
        <div className="add-to-project-text">
          {notInBoard && !Guest && (
            <p className="not-in-team-text">Add to Portfolio</p>
          )}
          <span>
            {projectId
              ? notInBoard & !Guest
                ? 'and Project '
                : 'Add to Project'
              : 'Add to Portfolio'}
          </span>
          {isSuggestedMember && (
            <SuggestionIconContainer
              data-for="app-tooltip"
              data-tip="Suggested Member"
              data-effect="solid"
              data-class="blue-dark-tooltip"
              data-html
            >
              <LightBulbIcon fill={theme.colors.colorMediumPurple2} />
            </SuggestionIconContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default AddMemberToListButton;
