import { useCallback, useMemo, useEffect } from 'react';
import { useMount } from 'react-use';
import { useAppSelector } from 'reduxInfra/hooks';
import useBudgetProjectAndPhasesMemberships from 'BudgetModule/hooks/useBudgetProjectAndPhasesMemberships';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import useProjectTeamMembersListItemsBuilder from './useProjectTeamMembersListItemsBuilder';
import useProjectTeamActivityPhasesGroupedListBuilder from './useProjectTeamActivityPhasesGroupedListBuilder';
import {
  ActivityPhaseListItem,
  PhaseListItem,
  ProjectTeamCollapseAllListItem,
  MembershipListItem,
  AddMemberToPhaseListItem
} from '../types';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import {
  checkPhaseHasActivityPhase,
  getNonUnassignedPhaseMembershipCounts,
  getDefaultActivityPhase
} from 'ProjectsModule/utils/phaseDisplay';
import { serializeId } from 'appUtils';

const parentId = 'main';

const useProjectTeamPhasesGroupedListBuilder = ({
  phasesOrder,
  projectId,
  openBudgetModal,
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState,
  toggleCollapseAll
}: UseNestedCollapseHookReturnedValues & {
  phasesOrder: number[];
  projectId: number;
  openBudgetModal: () => void;
}) => {
  const {
    getFormattedPhaseMembershipHashOfPhase,
    getFlattenedPartitionedPhaseMembershipListByPhaseId,
    getPhase
  } = useBudgetProjectAndPhasesMemberships({
    projectId,
    phaseIds: phasesOrder
  });
  const teamMembershipByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const memberListItemsBuilder = useProjectTeamMembersListItemsBuilder({
    getIsOpen,
    toggleCollapse,
    getCurrentParentCollapseState,
    setParentCollapseState,
    toggleCollapseAll,
    projectId
  });

  const activityPhasesGroupedListBuilder =
    useProjectTeamActivityPhasesGroupedListBuilder({
      getIsOpen,
      toggleCollapse,
      getCurrentParentCollapseState,
      setParentCollapseState,
      toggleCollapseAll,
      projectId
    });

  // this hook only remounts when projectId changes (selecting a new project)
  useMount(() => {
    toggleCollapseAll(parentId, 'collapse');
  });

  const projectTeamPhasesGroupedListBuilder = useCallback(() => {
    const list = phasesOrder.reduce(
      (
        acc: (PhaseListItem | ProjectTeamCollapseAllListItem)[],
        phaseId: number
      ) => {
        const phase = getPhase(phaseId);

        // Hiding default phase
        if (phase && !phase.is_like_default) {
          const hasActivityPhases = checkPhaseHasActivityPhase(phase);
          const phaseToggleId = serializeId({
            itemType: LIST_ITEM_TYPES.Phase,
            id: phaseId,
            ids: null
          });
          const isPhaseOpen = !!getIsOpen({
            toggleId: phaseToggleId,
            parentId
          });
          let listItems: (
            | ActivityPhaseListItem
            | MembershipListItem
            | AddMemberToPhaseListItem
          )[] = [];

          if (hasActivityPhases) {
            // Build activity phases
            listItems = activityPhasesGroupedListBuilder({
              order: phase.activity_order,
              phase,
              isParentOpen: isPhaseOpen,
              parentId: phaseToggleId,
              openBudgetModal
            });
          } else {
            // Headless activity
            const defaultActivityPhase = getDefaultActivityPhase(phase);
            if (defaultActivityPhase) {
              const activityPhaseMembershipOrder =
                defaultActivityPhase.activity_phase_memberships.map(
                  (apms) => apms.id
                );
              listItems = memberListItemsBuilder({
                hasActivityPhases,
                isParentOpen: isPhaseOpen,
                order: activityPhaseMembershipOrder,
                phaseId,
                phase,
                activityPhase: defaultActivityPhase,
                openBudgetModal
              });
            }
          }

          const phaseListItem = {
            id: phaseToggleId,
            listItems,
            isOpen: isPhaseOpen,
            isParentOpen: true,
            itemType: LIST_ITEM_TYPES.Phase,
            toggleId: phaseToggleId,
            isEmpty: isPhaseOpen ? !listItems.length : true,
            /* -------------------------------------------------------------------------- */
            visibleMembersCount: hasActivityPhases
              ? getNonUnassignedPhaseMembershipCounts(phase)
              : listItems.length,
            hasActivityPhases,
            phase,
            projectId,
            toggleCollapse: () =>
              toggleCollapse({
                toggleId: phaseToggleId,
                parentId
              }),
            openBudgetModal
          };

          acc.push(phaseListItem);
        }

        return acc;
      },
      []
    );

    /* ------------------------ Handling collapse states (in case the list length change) ------------------------ */

    const collapseState = getCurrentParentCollapseState(parentId);
    const numPhasesCount = list.length;

    const isAllCollapsed = collapseState?.allCollapsed;

    list.unshift({
      id: LIST_ITEM_TYPES.ProjectTeamCollapseAll,
      isOpen: true,
      isParentOpen: true,
      itemType: LIST_ITEM_TYPES.ProjectTeamCollapseAll,
      /* -------------------------------------------------------------------------- */
      toggleCollapse: () =>
        toggleCollapseAll(parentId, isAllCollapsed ? 'expand' : 'collapse'),
      numPhasesCount,
      isAllCollapsed
    } as ProjectTeamCollapseAllListItem);

    // Initializing the default collapse state
    if (!collapseState) {
      setParentCollapseState({
        values: {
          totalNumToggles: list.length,
          allCollapsed: true
        },
        id: parentId
      });
    } else if (collapseState.totalNumToggles !== list.length) {
      // update number of toggles
      setParentCollapseState({
        values: {
          totalNumToggles: list.length
        },
        id: parentId
      });
    }

    return list;
  }, [
    activityPhasesGroupedListBuilder,
    getCurrentParentCollapseState,
    getIsOpen,
    getPhase,
    memberListItemsBuilder,
    openBudgetModal,
    phasesOrder,
    projectId,
    setParentCollapseState,
    toggleCollapse,
    toggleCollapseAll
  ]);

  return projectTeamPhasesGroupedListBuilder;
};

export default useProjectTeamPhasesGroupedListBuilder;
