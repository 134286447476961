import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TaskList } from './TaskList';
import { useWorkPlanForm } from '../../WorkPlanFormProvider';
import { useWorkPlanRequestSidePanelForm } from 'WorkplanRequestsModule/components/WorkplanRequestSidePanelProvider';
import { useSidebarPanel, Step } from '../SidebarPanelProvider';
import { useTasks } from 'appUtils/hooks/useTasks';
import { TaskWithPlanOverlap } from '../../redux/types';
import { WorkplanFormValidationError } from '../../utils/validations';
import { getHomeTaskObj } from 'selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { isOverlapPlanTask } from '../../redux/utils';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { EditButton } from '../shared/EditButton';
import { PanelScroller } from 'views/projectPlanner/WorkplanModal/SidebarPanel/shared/PanelScroller';
import cn from 'classnames';
import moment from 'moment';
import { MOMENT_ISO_DATE, MOMENT_USA_DATE } from 'appConstants/date';
import { Task } from 'models/task';
import { ClearTaskDependenciesConfirmModal } from './ClearTaskDependenciesConfirmModal';
import { clearOldDependencies } from 'appUtils/newDependencies';
import { EDIT_TASK_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { useWorkPlanTasks } from '../../hooks/useWorkPlanTasks';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

export const TaskMenuTabPanel = ({ providerType }) =>
  providerType === 'WorkPlan' ? (
    <WorkplanTaskMenuTabPanel />
  ) : (
    <WorkplanRequestTaskMenuTabPanel />
  );

const WorkplanTaskMenuTabPanel = () => {
  const {
    form: {
      hasError,
      workplan: { start_date, end_date }
    },
    task: { onUpdateTaskIds, tasksHours, taskOrder },
    permissions: { canEditWorkplanTask }
  } = useWorkPlanForm();

  useEffect(() => {
    if (!canEditWorkplanTask) rebuildTooltip();
  }, [canEditWorkplanTask]);

  const handleRemoveTask = (taskId: number) => {
    onUpdateTaskIds(taskOrder.filter((id) => id !== taskId));
  };

  return (
    <TaskMenuTabRenderPart
      taskIds={taskOrder}
      hasError={hasError}
      scheduleStart={start_date}
      scheduleEnd={end_date}
      onRemoveTask={handleRemoveTask}
      isDisabled={!canEditWorkplanTask}
      addTaskButtonTooltip={
        !canEditWorkplanTask ? EDIT_TASK_WORK_PLANS_TIP : undefined
      }
      tasksHours={tasksHours}
    />
  );
};

const WorkplanRequestTaskMenuTabPanel = () => {
  const {
    form: { hasError, isNewRequest },
    task: { tasks }
  } = useWorkPlanRequestSidePanelForm();

  return (
    <TaskMenuTabRenderPart
      taskIds={tasks}
      hasError={hasError}
      isDisabled={isNewRequest}
      onRemoveTask={undefined}
    />
  );
};

export interface TaskMenuTabRenderPartProps {
  taskIds: number[];
  hasError: (errorKeys?: WorkplanFormValidationError[]) => boolean;
  isDisabled?: boolean;
  onRemoveTask?: (taskId: number) => void;
  // use scheduleDate to show if date range of task is overlapping base plan date or not
  scheduleStart?: string;
  scheduleEnd?: string;
  addTaskButtonTooltip?: string;
  tasksHours?: ReturnType<typeof useWorkPlanTasks>['tasksHours'];
}

const TaskMenuTabRenderPart = ({
  taskIds,
  hasError,
  isDisabled = false,
  scheduleStart,
  scheduleEnd,
  onRemoveTask,
  addTaskButtonTooltip,
  tasksHours
}: TaskMenuTabRenderPartProps) => {
  const { openStep } = useSidebarPanel();

  const taskHash = useAppSelector(getHomeTaskObj);

  const tasks = useMemo(() => {
    if (!taskIds) return [];

    return taskIds
      .flatMap<TaskWithPlanOverlap>((taskId) => {
        const task = taskHash[taskId];

        // filter out task with empty description
        if (task?.description) {
          return [
            {
              ...task,
              isOverlapPlan:
                scheduleStart && scheduleEnd
                  ? isOverlapPlanTask({
                      task,
                      scheduleStart,
                      scheduleEnd
                    })
                  : // if schedule dates of base plan are not defined, it returns false
                    false
            }
          ];
        }

        return [];
      })
      .sort((a, b) => {
        if (!a.completed_at === !b.completed_at) {
          return 0;
        } else if (!b.completed_at) {
          return 1;
        }
        return -1;
      });
  }, [scheduleEnd, scheduleStart, taskHash, taskIds]);

  const isAddTaskDisabled =
    hasError([
      'missing_project_id',
      'missing_phase_id',
      'missing_activity_id',
      'missing_account_id_or_member_budget_id',
      'missing_start_date',
      'missing_end_date'
    ]) || isDisabled;

  const handleAddTask = () => {
    openStep(Step.addTask);
  };

  const {
    markComplete,
    openTaskModal,
    deleteTasks,
    updateTasks,
    ConfirmStopTimerModal
  } = useTasks();

  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState<number>();

  const handleRemoveTask = (taskId: number) => {
    onRemoveTask && onRemoveTask(taskId);
  };

  const handleAttemptDeleteTask = (taskId: number) => {
    setIsDeleteTaskModalOpen(true);
    setTaskIdToDelete(taskId);
  };

  const handleDeleteTask = () => {
    if (taskIdToDelete) {
      handleRemoveTask(taskIdToDelete);
      deleteTasks([taskIdToDelete]);
    }
    setIsDeleteTaskModalOpen(false);
  };

  const [clearTaskDependenciesModalTask, setClearTaskDependenciesModalTask] =
    useState<Task>();

  const handleCloseClearTaskDependenciesModal = useCallback(() => {
    setClearTaskDependenciesModalTask(undefined);
  }, []);

  const updateTaskDates = useCallback(
    (task: Task) => {
      updateTasks({
        task_ids: [task.id],
        schedule_end: moment(scheduleEnd, MOMENT_USA_DATE).format(
          MOMENT_ISO_DATE
        ),
        schedule_start: moment(scheduleStart, MOMENT_USA_DATE).format(
          MOMENT_ISO_DATE
        ),
        ...(task.dependencies.length > 0
          ? {
              dependency_infos: clearOldDependencies(task.dependencies)
            }
          : {})
      });
    },
    [scheduleEnd, scheduleStart, updateTasks]
  );

  const handleConfirmClearTaskDependencies = useCallback(() => {
    if (clearTaskDependenciesModalTask !== undefined)
      updateTaskDates(clearTaskDependenciesModalTask);
  }, [clearTaskDependenciesModalTask, updateTaskDates]);

  const handleMatchWorkPlanDates = (task: TaskWithPlanOverlap) => {
    const hasDependencies = task.dependencies.length > 0;
    if (hasDependencies) setClearTaskDependenciesModalTask(task);
    else updateTaskDates(task);
  };

  const estimatedHours = Array.from(tasksHours?.values() ?? []).reduce(
    (sum, { hours }) => sum + (hours ?? 0),
    0
  );
  const hasEstimatedHours = estimatedHours > 0;

  return (
    <PanelScroller>
      <PanelContainer $hasEstimatedHours={hasEstimatedHours}>
        <PositionedEditButton
          className={cn({ disabled: isAddTaskDisabled })}
          onClick={!isAddTaskDisabled ? handleAddTask : undefined}
          data-for="app-tooltip"
          data-effect="solid"
          data-tip={addTaskButtonTooltip}
        >
          Edit Tasks
        </PositionedEditButton>
        {hasEstimatedHours && (
          <>
            <TotalHoursLabel>Total Task Hours</TotalHoursLabel>
            <TotalHours>
              {formatNumWithMaxTwoDecimals(estimatedHours)}h
            </TotalHours>
          </>
        )}
        <TaskList
          tasks={tasks}
          tasksHours={tasksHours}
          onClickTask={openTaskModal}
          onDeleteTask={(task) => handleAttemptDeleteTask(task.id)}
          onMarkComplete={markComplete}
          onMatchWorkPlanDates={handleMatchWorkPlanDates}
          onRemoveTask={(task) => handleRemoveTask(task.id)}
        />
      </PanelContainer>
      <DeleteModal
        isOpen={isDeleteTaskModalOpen}
        component={'task'}
        deleteOnClick={handleDeleteTask}
        toggle={() => setIsDeleteTaskModalOpen(false)}
      />
      <ClearTaskDependenciesConfirmModal
        isOpen={clearTaskDependenciesModalTask !== undefined}
        toggle={handleCloseClearTaskDependenciesModal}
        onConfirm={handleConfirmClearTaskDependencies}
      />
      <ConfirmStopTimerModal />
    </PanelScroller>
  );
};

const PanelContainer = styled.div.attrs<{ $hasEstimatedHours: boolean }>(
  ({ $hasEstimatedHours }) => ({
    className: cn({ withEstimatedHours: $hasEstimatedHours })
  })
)<{ $hasEstimatedHours: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px 3px;
  position: relative;

  &.withEstimatedHours {
    grid-template-columns: 1fr min-content;
  }
`;

const TotalHours = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 14px;
  font-weight: 600;
  padding: 13px 0 5px;
`;

const TotalHoursLabel = styled(TotalHours)`
  grid-column: 1;
  padding-left: 5px;
`;

const PositionedEditButton = styled(EditButton)`
  grid-column: 1;
`;
