import React, { lazy, Suspense } from 'react';
const containerStyle = {
  paddingLeft: 32,
  flex: 1,
  minHeight: 0
};

const StandardMilestonesContainer = lazy(() =>
  import('./StandardMilestonesTable/StandardMilestonesTableContainer')
);

const StandardMilestonesTab = () => {
  return (
    <div style={containerStyle}>
      <Suspense fallback={<div />}>
        <StandardMilestonesContainer />
      </Suspense>
    </div>
  );
};

export default StandardMilestonesTab;
