import React from 'react';
import TeamMemberProfile from '../TeamMemberProfile';
import { TimesheetContainer } from 'views';

const TeamMemberTimesheet = () => (
  <TeamMemberProfile>
    <TimesheetContainer />
  </TeamMemberProfile>
);

export default TeamMemberTimesheet;
