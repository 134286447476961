import theme from 'theme';
import { ReactComponent as StopButton } from 'icons/stoptimer.svg';
import styled from 'styled-components';
import { ReactComponent as PlayButton } from 'icons/playtimer.svg';
import { ReactComponent as SmallPlayButton } from 'icons/small-play-button.svg';
import { useTimer } from 'appUtils/hooks/useTimer';
import { Task } from 'models/task';
import { getProjectHash } from 'ProjectsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';

interface TaskModalTimerProps {
  task: Task;
}

export const TaskModalTimer = ({ task }: TaskModalTimerProps) => {
  const { toggleTimer, getIsTaskActiveTimer } = useTimer();
  const projectHash = useAppSelector(getProjectHash);
  const project = task.project_id ? projectHash[task.project_id] : undefined;

  const isTaskActiveTimer = getIsTaskActiveTimer(task.id);
  const isTaskOnPersonalProject = project?.is_personal;

  const handleTimerClick = () => {
    toggleTimer(task);
  };

  if (!project || isTaskOnPersonalProject || !task.status) {
    return null;
  }

  return (
    <TimerIconContainer
      data-for="app-tooltip"
      data-tip={
        isTaskActiveTimer
          ? 'Click to Stop Timer'
          : 'Click to Start Timer </br> View Timers in top right'
      }
      data-html
      data-effect="solid"
      data-class="center"
      onClick={handleTimerClick}
      isTaskActiveTimer={isTaskActiveTimer}
    >
      {isTaskActiveTimer ? (
        <>
          <HideOnHover>
            <PlayButton height={30} width={30} />
          </HideOnHover>
          <ShowOnHover>
            <StopButton height={30} width={30} />
          </ShowOnHover>
        </>
      ) : (
        <SmallPlayButton color={theme.colors.colorCalendarBlue} />
      )}
    </TimerIconContainer>
  );
};

const HideOnHover = styled.div``;

const ShowOnHover = styled.div`
  display: none;
`;

const TimerIconContainer = styled.div<{
  isTaskActiveTimer: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 16px;
  &:hover {
    background: ${(props) =>
      props.isTaskActiveTimer ? '' : theme.colors.colorTranslucentGray4};
    ${HideOnHover} {
      display: ${(props) => (props.isTaskActiveTimer ? 'none' : 'block')};
    }
    ${ShowOnHover} {
      display: ${(props) => (props.isTaskActiveTimer ? 'block' : 'none')};
    }
  }
  ${(props) =>
    props.isTaskActiveTimer &&
    `
    margin-top: -9px;
    transform: scale(2);
  `};
  path {
    stroke: ${(props) =>
      props.isTaskActiveTimer ? '' : theme.colors.colorCalendarBlue};
  }
  svg {
    height: 12px;
    width: 12px;
  }
`;
