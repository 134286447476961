import { createSelector } from 'reselect';
import { initialState } from 'reducers/access';
import { makeIdHash } from 'appUtils';
const emptyArray = [];
const emptyObj = {};
const emptyAccess = {
  can_edit_ids: [],
  can_view_ids: []
};
const getAccessState = (state) => state.access || initialState;
export const getAccess = createSelector(
  getAccessState,
  (state) => state.access
);

export const getAccessIdentifier = (state, ownProps) =>
  ownProps.accessIdentifier;
export const makeGetOwnAccess = () =>
  createSelector(
    getAccess,
    getAccessIdentifier,
    (access, identifier) => access[identifier] || emptyAccess
  );

export const makeGetEditIds = () =>
  createSelector(
    makeGetOwnAccess(),
    (access) => access.can_edit_ids || emptyArray
  );
export const makeGetEditIdsHash = () =>
  createSelector(makeGetEditIds(), makeIdHash);

export const makeGetViewIds = () =>
  createSelector(
    makeGetOwnAccess(),
    (access) => access.can_view_ids || emptyArray
  );
export const makeGetViewIdsHash = () =>
  createSelector(makeGetViewIds(), makeIdHash);

export const makeGetEditOrViewIds = () =>
  createSelector(makeGetEditIds(), makeGetViewIds(), (editIds, viewIds) =>
    Array.from(new Set([...editIds, ...viewIds]))
  );
export const makeGetEditOrViewIdsHash = () =>
  createSelector(makeGetEditOrViewIds(), makeIdHash) || emptyObj;
