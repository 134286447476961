import { fork, takeEvery, takeLatest, all } from 'redux-saga/effects';
import {
  fetchGoogleCalendarEvents,
  updateGoogleCalendarEvents
} from 'EventsModule/actionCreators';
import {
  fetchGoogleCalendarEventsWorker,
  updateGoogleCalendarEventsWorker
} from './googleCalendar';

export default function* eventsSaga() {
  const eventsSagas = [
    fork(
      takeLatest,
      fetchGoogleCalendarEvents,
      fetchGoogleCalendarEventsWorker
    ),
    fork(
      takeEvery,
      updateGoogleCalendarEvents,
      updateGoogleCalendarEventsWorker
    )
  ];
  yield all(eventsSagas);
}
