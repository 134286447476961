import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { ToggleViewDropdown } from 'views';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { getSortOrder, getCurrentFilter } from 'selectors';

const sortOrderDisplay = {
  default: 'All tasks',
  active: 'Incomplete',
  completed: 'Completed'
};

class TaskListSortDropdown extends Component {
  state = {
    open: false
  };

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { sortOrder, taskFilter } = this.props;
    const { open } = this.state;
    return (
      <Dropdown
        className="task-complete-dropdown"
        toggle={this.toggle}
        isOpen={open}
      >
        <DropdownToggle
          className={cn('task-dropdown-title blue', 'task-type', {
            active: open,
            selected: sortOrder
          })}
        >
          {sortOrderDisplay[taskFilter.state] || 'Incomplete'}
          <FaAngleDown
            className={cn('down-arrow', {
              active: open
            })}
          />
        </DropdownToggle>

        <ToggleViewDropdown
          isOpen={open}
          toggle={this.toggle}
          taskFilterState={taskFilter.state}
          isOnTasks
        />
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
  sortOrder: getSortOrder(state),
  taskFilter: getCurrentFilter(state)
});

export default withRouter(connect(mapStateToProps)(TaskListSortDropdown));
