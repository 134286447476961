import { ModalBody } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';

import {
  getPhasesAndMilestonesByProjectHash,
  getModalPhaseProjectId,
  getModalPhaseId,
  getModalPhaseIsAllPhases,
  getIsDeletePhaseFlag,
  getPrevPhaseModal,
  getModalPhaseIsArchivedFlag,
  getModalPhaseHasTimeEntriesOrWorkplans,
  getIsAddMilestone
} from 'selectors';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  ArchiveButton,
  CustomPhaseBottomCopy
} from 'BudgetModule/components/styles';
import PhaseOfWorkInput from 'BudgetModule/components/PhaseOfWorkInput';
import {
  createPhase,
  closePhaseModal,
  updatePhase,
  archivePhase,
  deletePhase,
  openPhaseModal
} from 'actionCreators';
import { getAssociatedTimeMessage } from 'appUtils/budgetUtils';

class PhaseModalBody extends React.Component {
  state = {
    name: (this.props.phase && this.props.phase.name) || '',
    isArchive: false
  };

  handleChange = (e) => {
    this.setState({ name: e.target.value });
  };

  onSubmit = (params) => {
    if (!this.props.isModalOpen) {
      return;
    }

    this.apiCall(params);
    this.handleClose();
  };

  setArchive = () => this.setState({ isArchive: true });
  clearRemove = () => {
    const { isDelete } = this.props;
    if (isDelete) {
      this.onCancel();
    } else {
      this.setState({ isArchive: false });
    }
  };

  onConfirm = () => {
    if (!this.props.isModalOpen) {
      return;
    }

    const { projectId, id } = this.props;
    const { name } = this.state;
    this.apiCall({
      projectId,
      name,
      id
    });
    this.handleClose();
  };

  onCancel = () => {
    const { openPhaseModal, prevModal, id } = this.props;
    if (prevModal) {
      openPhaseModal({
        id,
        [prevModal]: true
      });
    } else {
      this.handleClose();
    }
  };

  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  apiCall = (params) => {
    const {
      isNew,
      updatePhase,
      createPhase,
      isDelete,
      isAddMilestone,
      onDeleteSuccess
    } = this.props;
    const { isArchive } = this.state;
    const apiCall =
      isArchive || isDelete ? this.remove() : isNew ? createPhase : updatePhase;
    const body = {
      ...params,
      ...(isDelete && { onSuccess: onDeleteSuccess })
    };
    body.isBudget = !isAddMilestone;
    // commented out isBudget logic until budget vs non-budget phases is resolved. Defaults to true in saga.
    // do not edit phase.is_budget on existing phases
    // if (!phase) {
    //   body.isBudget = !isAllPhases;
    // }
    if (this.isValid()) {
      apiCall(body);
    }
  };

  remove = () => {
    const { archivePhase, deletePhase, isDelete } = this.props;
    return isDelete ? deletePhase : archivePhase;
  };

  isValid = () => {
    const { name, isArchive } = this.state;
    const { phases, id } = this.props;
    if (isArchive) {
      return true;
    }
    const lowerCaseName = name.toLowerCase();
    return Object.values(phases)
      .filter((phase) => phase.name && !phase.archived)
      .every(
        (phase) => phase.id === id || phase.name.toLowerCase() !== lowerCaseName
      );
  };

  renderRemoveButtons = () => {
    return (
      <>
        <Left />
        <Right>
          <ConfirmButton isDelete onClick={this.onConfirm}>
            Delete
          </ConfirmButton>
          <CancelButton onClick={this.clearRemove}>Cancel</CancelButton>
        </Right>
      </>
    );
  };

  renderCancelButtons = () => {
    return (
      <>
        <Left />
        <Right>
          <ConfirmButton onClick={this.onCancel}>Okay</ConfirmButton>
        </Right>
      </>
    );
  };

  setUnarchive = () => {
    const { updatePhase, closePhaseModal, phase } = this.props;
    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      isArchived: false
    });
    closePhaseModal();
  };

  renderInitialButtons = () => {
    const { isNew, isAlreadyArchived } = this.props;
    return (
      <>
        <Left>
          {!isNew &&
            (isAlreadyArchived ? (
              <ArchiveButton onClick={this.setUnarchive}>
                Make Active
              </ArchiveButton>
            ) : (
              <ArchiveButton onClick={this.setArchive}>
                Make Inactive
              </ArchiveButton>
            ))}
        </Left>
        <Right>
          <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
          <ConfirmButton onClick={this.onConfirm}>
            {isNew ? 'Create' : 'Save'}
          </ConfirmButton>
        </Right>
      </>
    );
  };

  renderButtons = () => {
    const { isArchive } = this.state;
    const { isDelete, hasTimeEntries } = this.props;
    if (isDelete && hasTimeEntries) {
      return this.renderCancelButtons();
    }
    return isArchive || isDelete
      ? this.renderRemoveButtons()
      : this.renderInitialButtons();
  };

  render() {
    const { phase, id, projectId, isDelete, hasTimeEntries, isAddMilestone } =
      this.props;
    const { isArchive } = this.state;
    const isPhase = phase?.name || phase?.is_budget;

    /** If phase is archived, then prevent deletion
     */
    if (isDelete && hasTimeEntries && phase.archived) {
      return (
        <ModalBody>
          <div>
            <div className="remove-header">
              {getAssociatedTimeMessage(
                hasTimeEntries,
                isPhase ? MODAL_TYPE.PHASE : MODAL_TYPE.MILESTONE
              )}{' '}
            </div>
          </div>
          <ModalButtonsRow>{this.renderButtons()}</ModalButtonsRow>
        </ModalBody>
      );
    }

    /** If phase not archived, then show this message and will archive the phase
     * once modal is closed
     */
    if (isDelete && hasTimeEntries && !phase.archived) {
      return (
        <ModalBody>
          <div>
            <div className="remove-header">
              <b>{isPhase ? 'Phase' : 'Milestone'} </b>has been archived in
              order to keep time. You must move or delete all time entered on
              Timesheets to completely remove the phase.
            </div>
          </div>
          <ModalButtonsRow>{this.renderCancelButtons()}</ModalButtonsRow>
        </ModalBody>
      );
    }
    return (
      <ModalBody>
        {isArchive || isDelete ? (
          <div>
            <div className="remove-header">
              Are you sure you want to{' '}
              {isDelete ? (
                <>
                  <span style={{ fontWeight: '600' }}>permanently delete </span>
                  {phase && phase.name} ?
                </>
              ) : (
                <>
                  make {phase?.name}{' '}
                  <span style={{ fontWeight: '600' }}>inactive</span> ?
                </>
              )}
            </div>
            <div className="remove-text">
              All estimated time and planned time will be permanently deleted.
              Time entered on Timesheets must be deleted from the Time Report.
            </div>
          </div>
        ) : (
          <PhaseOfWorkInput
            handleSubmit={this.onSubmit}
            name={phase && phase.name}
            onChangeCallback={this.handleChange}
            id={id}
            projectId={projectId}
            isInModal={true}
          />
        )}
        {!id && (
          <CustomPhaseBottomCopy>
            {!isAddMilestone
              ? 'This is a custom phase for this project only. Standard project phases should be added to templates'
              : 'This is a custom milestone for this project only'}
          </CustomPhaseBottomCopy>
        )}
        <ModalButtonsRow>{this.renderButtons()}</ModalButtonsRow>
      </ModalBody>
    );
  }
}

PhaseModalBody.propTypes = {};

const mapStateToProps = (state) => {
  const projectId = getModalPhaseProjectId(state);
  const id = getModalPhaseId(state);
  const phasesByProject = getPhasesAndMilestonesByProjectHash(state);
  const phase = phasesByProject[projectId]?.phases?.find(
    (phase) => phase.id === id
  );

  return {
    projectId,
    isAlreadyArchived: getModalPhaseIsArchivedFlag(state),
    id,
    isNew: !getModalPhaseId(state),
    phase,
    phases: phasesByProject,
    isAllPhases: getModalPhaseIsAllPhases(state),
    isDelete: getIsDeletePhaseFlag(state),
    prevModal: getPrevPhaseModal(state),
    hasTimeEntries: getModalPhaseHasTimeEntriesOrWorkplans(state),
    isAddMilestone: getIsAddMilestone(state)
  };
};

const mapDispatchToProps = {
  updatePhase,
  createPhase,
  archivePhase,
  deletePhase,
  closePhaseModal,
  openPhaseModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PhaseModalBody);
