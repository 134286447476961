import { serializeId } from 'appUtils';
import { useCallback } from 'react';
import { ROW_TYPES } from './layout';

export const useLoadMoreRowBuilder = () => {
  const loadMoreRowBuilder = useCallback(({ groupData, lastItemIndex }) => {
    return {
      id: serializeId({
        itemType: 'loadMore',
        id: groupData.id,
        ids: undefined,
        delimiter: undefined
      }),
      rowType: ROW_TYPES.loadMoreRow,
      rowHeight: 0.1,
      groupData: groupData,
      lastItemIndex: lastItemIndex
    };
  }, []);

  return loadMoreRowBuilder;
};
