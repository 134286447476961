import * as constants from 'appConstants';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import { omitDaysFromActivities } from 'appUtils/timesheetUtils';
import { makeIdHash } from 'appUtils';
import { SORT_BY, SORT_ORDER } from 'appConstants/filters';
import moment from 'moment';

const {
  UPDATE_ACTIVITY_ROW_DESCRIPTION,
  OPEN_ACTIVITY_ROW_DROPDOWN,
  CLOSE_ACTIVITY_ROW_DROPDOWN,
  FETCH_ACTIVITIES,
  FETCH_TIMESHEETS,
  FETCH_TIMESHEETS_INDEX,
  BULK_UPDATE_TIMESHEETS,
  FETCH_TIMESHEETS_UTILIZATION,
  FETCH_DESCRIPTIONS,
  CREATE_TIMESHEET,
  UPDATE_TIMESHEET,
  TIME_ENTRY_CREATE_UPDATE_ERROR,
  CLEAR_TIME_ENTRY_ERROR,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  UPDATE_TEAM_ORDERED_ITEMS,
  CREATE_DESCRIPTION,
  UPDATE_DESCRIPTION,
  DELETE_DESCRIPTIONS,
  OPEN_ADD_EDIT_ACTIVITY,
  CLOSE_ADD_EDIT_ACTIVITY,
  OPEN_ADD_EDIT_DESCRIPTION,
  CLOSE_ADD_EDIT_DESCRIPTION,
  FLUSH_ACTIVITIES,
  FLUSH_TIMESHEETS,
  FLUSH_DESCRIPTIONS,
  SHOW_TOTALS,
  HIDE_TOTALS,
  SELECT_SUBMISSION_DATES,
  UNSELECT_SUBMISSION_DATES,
  FLUSH_SUBMISSION_DATES,
  SET_SUBMISSION_STATUS,
  SET_SELECTED_TEAM_MEMBER,
  SET_PROJECT_PLANNER_MEMBER,
  OPEN_TIMESHEET_ACTIVITY_MODAL_FOR_DAY,
  CLOSE_TIMESHEET_ACTIVITY_MODAL_FOR_DAY,
  TOGGLE_FLICKER_ACTIVITY_CELL,
  TOGGLE_FLICKER_TASK_CELL,
  SORT_TIMESHEET_REPORT,
  BATCH_SELECT_TIMESHEETS,
  CLEAR_BATCH_SELECTED_TIMESHEETS,
  SET_TIMESHEET_REPORT_DATE_RANGE,
  SET_IS_ADDING_NEW_TIMESHEET_ROW,
  CLEAR_IS_ADDING_NEW_TIMESHEET_ROW,
  UPDATE_CREATE_ROW_DATA,
  MOUNT_TIMESHEET,
  CHECK_HAS_TIME_ENTRIES,
  FLUSH_HAS_TIME_ENTRIES,
  SET_TIMESHEET_REMINDER_DAY,
  SET_TIMESHEET_REMINDER_SENDER_ID,
  FETCH_REMAINING_TIMESHEET_HOURS
} = constants;

const byId = (item) => item.id;
const dateStart = moment().add(-30, 'days').valueOf();
const dateEnd = moment().valueOf();
const today = moment().valueOf();

// Add fields as it requires more
export const initialFilterState = {
  isFetchingTimesheet: false,
  timesheets: {}
};

const initialState = {
  isFetchingActivities: false,
  isActivitiesLoaded: false,
  activityRows: {},
  timesheets: {},
  timesheetHash: {},

  selectedTimesheetsHash: {},
  isAddingNewRow: false,
  timesheetOrder: [],
  timesheetsPredictions: [],
  offset: 0,
  sortBy: {
    value: SORT_BY.date,
    order: SORT_ORDER.desc
  },
  descriptions: {},
  dateRange: {
    start: dateStart,
    end: dateEnd
  },
  createRowData: {},
  createRowListId: null,
  editing: {
    description: false,
    weekday: false,
    uuid: null
  },
  dropdownProjectId: null,
  addEditActivityMenu: {
    isOpen: false,
    prepopulatedTitle: ''
  },
  addEditDescriptionMenu: {
    isOpen: false,
    prepopulatedTitle: ''
  },
  deleteConfirmationId: null,
  editActivityId: null,
  editDescriptionId: null,
  editDescriptionActivityId: null,
  projects: {},
  showTotals: false,
  selectedSubmissionDates: {},
  isLoadingDescriptions: true,
  submissionStatus: 'submitted',
  openTimesheetActivityModal: false,
  timesheetActivityModalData: {},
  flickerActivityCell: {},
  flickerTaskCategoryCell: {},
  timesheetCount: null,
  timesheetGroupCounts: {},
  timesheetGroupTotalHours: {},
  timesheetGroupTotalAmount: {},
  isLoadingTimesheetRows: false,
  timesheetMountedTime: today,
  utilization: [],
  loadingUtilization: true,
  hasTimeEntries: [],
  loadingHasTimeEntries: false,
  isSettingsModalOpen: false,
  dateRangeProjectActivities: {},
  showTimesheetMovedMessage: false,
  timesheetOrderByFilter: {},
  offsetByFilter: {},
  groupCountsByFilter: {},
  loadingByFilter: {},
  utilizationByFilter: {},
  loadingUtilizationByFilter: {},
  isFetchingTimesheet: false,
  filterStates: {},
  isSetRemindersModalOpen: false,
  setReminderMemberId: null,
  timesheetReminderDay: null,
  timesheetReminderSenderId: null,
  isIncompleteModalOpen: false,
  incompleteTimesheetDates: {},
  sendReminderId: null,
  isSendReminderModalOpen: false
};

const timesheets = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.SET_VISIBLE_WEEK: {
      return {
        ...state,
        dropdownProjectId: null
      };
    }
    case OPEN_ACTIVITY_ROW_DROPDOWN: {
      const { projectId } = payload;
      return {
        ...state,
        dropdownProjectId: projectId,
        deleteConfirmationId: null,
        editActivityId: null
      };
    }
    case OPEN_ADD_EDIT_ACTIVITY: {
      const { id, prepopulatedTitle } = payload || {};
      return {
        ...state,
        addEditActivityMenu: {
          isOpen: true,
          prepopulatedTitle: prepopulatedTitle || ''
        },
        editActivityId: id
      };
    }
    case CLOSE_ADD_EDIT_ACTIVITY: {
      return {
        ...state,
        addEditActivityMenu: {
          isOpen: false,
          prepopulatedTitle: ''
        },
        editActivityId: null
      };
    }
    case OPEN_ADD_EDIT_DESCRIPTION: {
      const { id, activityId, prepopulatedTitle } = payload || {};
      return {
        ...state,
        addEditDescriptionMenu: {
          isOpen: true,
          prepopulatedTitle: prepopulatedTitle || ''
        },
        editDescriptionId: id,
        editDescriptionActivityId: activityId || null
      };
    }
    case CLOSE_ADD_EDIT_DESCRIPTION: {
      return {
        ...state,
        addEditDescriptionMenu: {
          isOpen: false,
          prepopulatedTitle: ''
        },
        editDescriptionId: null,
        editDescriptionActivityId: null
      };
    }
    case CLOSE_ACTIVITY_ROW_DROPDOWN: {
      return {
        ...state,
        dropdownProjectId: null
      };
    }
    case FETCH_ACTIVITIES.TRIGGER: {
      return {
        ...state,
        isFetchingActivities: true
      };
    }
    case FETCH_ACTIVITIES.FAILURE: {
      return {
        ...state,
        isFetchingActivities: false
      };
    }
    case CREATE_ACTIVITY.SUCCESS:
    case FETCH_ACTIVITIES.SUCCESS: {
      const { activities } = payload.response;
      const { newActivity, ...rest } = state.activityRows;

      return {
        ...state,
        activityRows: {
          ...rest,
          ...activities
        },
        isFetchingActivities: false,
        isActivitiesLoaded: true
      };
    }
    case FETCH_DESCRIPTIONS.SUCCESS: {
      const { descriptions } = payload.response;
      const flatDescriptions = keyBy(descriptions, (item) => item.id);
      const newDescriptions = {
        ...state.descriptions,
        ...flatDescriptions
      };
      return {
        ...state,
        isLoadingDescriptions: false,
        descriptions: newDescriptions
      };
    }
    case SET_PROJECT_PLANNER_MEMBER:
    case SET_SELECTED_TEAM_MEMBER: {
      return {
        ...state, // don't reset activityRows (called activities or task categories in the app) as they are global
        timesheets: {},
        descriptions: {}
      };
    }
    case FETCH_TIMESHEETS.TRIGGER: {
      const { filterStateId } = payload;
      if (filterStateId) {
        const initial = !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(initial
                ? initialFilterState
                : state.filterStates[filterStateId]),
              isFetchingTimesheet: true
            }
          }
        };
      } else {
        return {
          ...state,
          isFetchingTimesheet: true
        };
      }
    }
    case FETCH_TIMESHEETS.FAILURE: {
      const { filterStateId } = payload.requestPayload;
      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isFetchingTimesheet: false
            }
          }
        };
      } else {
        return {
          ...state,
          isFetchingTimesheet: false
        };
      }
    }
    case FETCH_TIMESHEETS.SUCCESS: {
      const { response, requestPayload } = payload;
      const { filterStateId } = requestPayload;

      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              timesheets: {
                ...state.filterStates[filterStateId].timesheets,
                ...response
              },
              isFetchingTimesheet: false
            }
          }
        };
      }
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          ...action.payload.response
        },
        isFetchingTimesheet: false
      };
    }
    case BULK_UPDATE_TIMESHEETS.TRIGGER: {
      const { status, time_entry_ids } = action.payload;
      if (!status || !time_entry_ids?.length) {
        return state; // have not tested optimistically updating other attributes
      }

      return {
        ...state,
        timesheetHash: {
          ...state.timesheetHash,
          ...time_entry_ids.reduce(
            (timesheetHash, id) => {
              if (timesheetHash[id]) {
                timesheetHash[id] = { ...timesheetHash[id], status };
              }
              return timesheetHash;
            },
            { ...state.timesheetHash }
          )
        }
      };
    }
    case FETCH_TIMESHEETS_INDEX.TRIGGER: {
      const { body, initial } = action.payload;
      const { time_entry_ids } = body;

      if (time_entry_ids?.length) {
        return state;
      }
      return {
        ...state,
        isLoadingTimesheetRows: true,
        timesheetCount: initial ? null : state.timesheetCount,
        timesheetOrder: initial ? [] : state.timesheetOrder,
        timesheetGroupCounts: initial ? {} : state.timesheetGroupCounts,
        timesheetGroupTotalHours: initial ? {} : state.timesheetGroupTotalHours,
        timesheetGroupTotalAmount: initial
          ? {}
          : state.timesheetGroupTotalAmount
      };
    }
    case FETCH_TIMESHEETS_INDEX.FAILURE: {
      return {
        ...state,
        isLoadingTimesheetRows: false
      };
    }
    case FETCH_TIMESHEETS_INDEX.SUCCESS: {
      const { response, requestPayload } = payload;
      const {
        time_entries,
        time_entry_count,
        sort_attribute_counts,
        sort_attribute_total_hours,
        sort_attribute_total_amount
      } = response;
      const {
        initial,
        insert,
        body: { time_entry_ids, offset, limit }
      } = requestPayload;

      if (time_entry_ids) {
        // ignore order updates unless inserting
        // wait to replace creating row with actual row until data fetched to prevent dissapearing row.
        return {
          ...state,
          createRowData: insert ? {} : state.createRowData,
          isAddingNewRow: insert ? false : state.isAddingNewRow,
          timesheetHash: {
            ...state.timesheetHash,
            ...keyBy(time_entries, byId)
          },
          timesheetOrder: insert
            ? Array.from(
                new Set([...time_entries.map(byId), ...state.timesheetOrder])
              )
            : state.timesheetOrder
        };
      }

      return {
        ...state,
        timesheetHash: {
          ...state.timesheetHash,
          ...keyBy(time_entries, byId)
        },
        timesheetOrder: initial
          ? time_entries.map(byId)
          : Array.from(
              new Set([...state.timesheetOrder, ...time_entries.map(byId)])
            ),
        offset: (offset !== undefined ? offset : state.offset) + (limit || 0),
        timesheetCount: time_entry_count,
        timesheetGroupCounts: sort_attribute_counts,
        timesheetGroupTotalHours: sort_attribute_total_hours,
        timesheetGroupTotalAmount: sort_attribute_total_amount,
        isLoadingTimesheetRows: false
      };
    }
    case FETCH_TIMESHEETS_UTILIZATION.TRIGGER: {
      const { filterId } = payload;
      if (filterId) {
        return {
          ...state,
          loadingUtilizationByFilter: {
            ...state.loadingUtilizationByFilter,
            [filterId]: true
          }
        };
      } else {
        return {
          ...state,
          loadingUtilization: true
        };
      }
    }
    case FETCH_TIMESHEETS_UTILIZATION.FAILURE: {
      const { filterId } = payload.requestPayload;
      if (filterId) {
        return {
          ...state,
          loadingUtilizationByFilter: {
            ...state.loadingUtilizationByFilter,
            [filterId]: false
          }
        };
      } else {
        return {
          ...state,
          loadingUtilization: false
        };
      }
    }
    case FETCH_TIMESHEETS_UTILIZATION.SUCCESS: {
      const { response, requestPayload = {} } = payload;
      const { filterId } = requestPayload;
      if (filterId) {
        return {
          ...state,
          utilizationByFilter: {
            ...state.utilizationByFilter,
            [filterId]: response
          },
          loadingUtilizationByFilter: {
            ...state.loadingUtilizationByFilter,
            [filterId]: false
          }
        };
      } else {
        return {
          ...state,
          utilization: response,
          loadingUtilization: false
        };
      }
    }
    case CREATE_ACTIVITY.TRIGGER: {
      const { title } = payload;

      return {
        ...state,
        activityRows: {
          ...state.activityRows,
          newActivity: {
            title
          }
        }
      };
    }
    case UPDATE_ACTIVITY.TRIGGER: {
      const { id } = payload;
      return {
        ...state,
        activityRows: {
          ...state.activityRows,
          [id]: {
            ...state.activityRows[id],
            ...payload
          }
        }
      };
    }
    case UPDATE_ACTIVITY.SUCCESS: {
      const { id } = payload.response;
      return {
        ...state,
        activityRows: {
          ...state.activityRows,
          [id]: {
            ...state.activityRows[id],
            ...payload.response
          }
        }
      };
    }
    case UPDATE_TEAM_ORDERED_ITEMS.TRIGGER: {
      const { billable_activity_order } = action.payload;
      const billableActivityIdsHash = makeIdHash(billable_activity_order);

      const updatedActivityRows = Object.values(state.activityRows).reduce(
        (hash, activity) => {
          if (billableActivityIdsHash[activity.id] === activity.billable) {
            hash[activity.id] = activity; // keep reference if possible
          } else {
            hash[activity.id] = {
              ...activity,
              billable: !!billableActivityIdsHash[activity.id]
            };
          }
          return hash;
        },
        {}
      );

      return {
        ...state,
        activityRows: updatedActivityRows
      };
    }
    case UPDATE_ACTIVITY_ROW_DESCRIPTION: {
      const { description, uuid } = payload;
      return {
        ...state,
        activityRows: {
          ...state.activityRows,
          [uuid]: {
            ...state.activityRows[uuid],
            description
          }
        },
        editing: {
          description: true,
          weekday: false,
          uuid: uuid
        }
      };
    }
    case CREATE_DESCRIPTION.TRIGGER: {
      return {
        ...state,
        addEditDescriptionMenu: {
          isOpen: false,
          prepopulatedTitle: ''
        },
        addEditActivityMenu: {
          isOpen: false,
          prepopulatedTitle: ''
        },
        dropdownProjectId: null
      };
    }
    case CREATE_DESCRIPTION.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          [response.id]: response
        },
        timesheets: {
          ...state.timesheets,
          [response.id]: {}
        }
      };
    }
    case UPDATE_DESCRIPTION.TRIGGER: {
      const { id, date, title, phaseId, activityId, projectId } = payload;
      return {
        ...state,
        descriptions: {
          ...state.descriptions,
          [id]: {
            ...state.descriptions[id],
            title,
            phase_id: phaseId,
            activity_id: activityId,
            project_id: projectId
          }
        }
      };
    }
    case DELETE_DESCRIPTIONS.TRIGGER: {
      const { descriptionIds, timesheets = {} } = payload;
      const timesheetIds = Object.values(timesheets).map(
        (timesheet) => timesheet.id
      );
      const idHash = keyBy(timesheetIds, byId);
      return {
        ...state,
        descriptions: omit(state.descriptions, descriptionIds),
        timesheets: omit(state.timesheets, descriptionIds),
        timesheetOrder: state.timesheetOrder.filter((id) => !idHash[id]),
        timesheetHash: omit(state.timesheetHash, timesheetIds)
      };
    }
    case CREATE_TIMESHEET.TRIGGER: {
      const { day, descriptionId } = payload;
      if (!descriptionId) {
        return state;
      }
      let { hours } = payload;

      if (hours.split('.').length === 1) {
        hours = parseFloat(hours).toFixed(1);
      }
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          [descriptionId]: {
            ...state.timesheets[descriptionId],
            [day]: {
              hours,
              id: 'new',
              description_id: descriptionId
            }
          }
        }
      };
    }
    case CREATE_TIMESHEET.SUCCESS:
    case UPDATE_TIMESHEET.SUCCESS: {
      const { date, description_id } = payload.response;
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          [description_id]: {
            ...state.timesheets[description_id],
            [date]: {
              ...payload.response
            }
          }
        }
      };
    }
    case UPDATE_TIMESHEET.TRIGGER: {
      const { day, uuid, descriptionId } = payload;
      let { hours } = payload;

      if (hours.split('.').length === 1) {
        hours = parseFloat(hours).toFixed(1);
      }
      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          [descriptionId]: {
            ...state.timesheets[descriptionId],
            [day]: {
              ...state.timesheets[descriptionId][day],
              priorHours: state.timesheets[descriptionId][day]?.hours,
              hours
            }
          }
        },
        editing: {
          description: false,
          weekday: day,
          uuid: uuid
        }
      };
    }
    case TIME_ENTRY_CREATE_UPDATE_ERROR: {
      const { descriptionId, day, error } = action.payload;

      return {
        ...state,
        timesheets: {
          ...state.timesheets,
          [descriptionId]: {
            ...state.timesheets[descriptionId],
            [day]: {
              ...state.timesheets[descriptionId]?.[day],
              error
            }
          }
        }
      };
    }
    case CLEAR_TIME_ENTRY_ERROR: {
      const { descriptionId, day } = action.payload;
      const timesheet = state.timesheets[descriptionId]?.[day];
      const isNew = timesheet?.id === 'new';
      const newTimesheets = {
        ...state.timesheets,
        [descriptionId]: {
          ...state.timesheets[descriptionId]
        }
      };
      if (isNew) {
        delete newTimesheets[descriptionId][day];
      } else {
        newTimesheets[descriptionId][day] = {
          ...state.timesheets[descriptionId]?.[day],
          hours: state.timesheets[descriptionId]?.[day]?.priorHours,
          error: undefined
        };
      }
      return {
        ...state,
        timesheets: newTimesheets
      };
    }

    case constants.DELETE_TIMESHEETS.TRIGGER: {
      const { days, descriptionIds, timesheetIds = [] } = action.payload;
      const idHash = keyBy(timesheetIds, byId);
      return {
        ...state,
        timesheets: omitDaysFromActivities(
          days,
          descriptionIds,
          state.timesheets
        ),
        tempdescriptions: omit(state.tempdescriptions, descriptionIds),
        timesheetOrder: state.timesheetOrder.filter((id) => !idHash[id]),
        timesheetHash: omit(state.timesheetHash, timesheetIds)
      };
    }
    case constants.DELETE_ACTIVITY_ROW_ASK: {
      const { uuid } = payload;
      return {
        ...state,
        deleteConfirmationId: uuid,
        editDescriptionId: null
      };
    }
    case constants.DELETE_ACTIVITY_ROW_CANCEL: {
      return {
        ...state,
        deleteConfirmationId: null
      };
    }
    case FLUSH_ACTIVITIES: {
      return {
        ...state,
        activityRows: {}
      };
    }
    case FLUSH_TIMESHEETS: {
      return {
        ...state,
        timesheets: {}
      };
    }
    case FLUSH_DESCRIPTIONS: {
      return {
        ...state,
        descriptions: {},
        tempDescriptions: {}
      };
    }
    case SHOW_TOTALS: {
      return {
        ...state,
        showTotals: true
      };
    }
    case HIDE_TOTALS: {
      return {
        ...state,
        showTotals: false
      };
    }
    case SELECT_SUBMISSION_DATES: {
      const { dates } = action.payload;
      const dateKeys = makeIdHash(dates);
      return {
        ...state,
        selectedSubmissionDates: {
          ...state.selectedSubmissionDates,
          ...dateKeys
        }
      };
    }
    case UNSELECT_SUBMISSION_DATES: {
      const { dates } = action.payload;
      return {
        ...state,
        selectedSubmissionDates: omit(state.selectedSubmissionDates, dates)
      };
    }
    case FLUSH_SUBMISSION_DATES: {
      return {
        ...state,
        selectedSubmissionDates: {}
      };
    }
    case SET_SUBMISSION_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        submissionStatus: status
      };
    }
    case TOGGLE_FLICKER_ACTIVITY_CELL: {
      const { descriptionId } = action.payload;
      return {
        ...state,
        flickerActivityCell: {
          ...state.flickerActivityCell,
          [descriptionId]: !state.flickerActivityCell[descriptionId]
        }
      };
    }
    case TOGGLE_FLICKER_TASK_CELL: {
      const { descriptionId } = action.payload;
      return {
        ...state,
        flickerTaskCategoryCell: {
          ...state.flickerTaskCategoryCell,
          [descriptionId]: !state.flickerTaskCategoryCell[descriptionId]
        }
      };
    }
    case SORT_TIMESHEET_REPORT: {
      const { order, value } = action.payload;
      return {
        ...state,
        sortBy: { order, value }
      };
    }

    case BATCH_SELECT_TIMESHEETS: {
      const { ids, value } = action.payload;
      return {
        ...state,
        selectedTimesheetsHash: {
          ...state.selectedTimesheetsHash,
          ...ids.reduce((acc, cur) => {
            acc[cur] = value;
            return acc;
          }, {})
        }
      };
    }
    case CLEAR_BATCH_SELECTED_TIMESHEETS: {
      return {
        ...state,
        selectedTimesheetsHash: {}
      };
    }
    case SET_TIMESHEET_REPORT_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload
      };
    }
    case SET_IS_ADDING_NEW_TIMESHEET_ROW: {
      return {
        ...state,
        isAddingNewRow: true,
        createRowListId: payload.listId,
        ...(payload.createRowData && { createRowData: payload.createRowData })
      };
    }
    case CLEAR_IS_ADDING_NEW_TIMESHEET_ROW: {
      return {
        ...state,
        isAddingNewRow: false,
        createRowData: {},
        createRowListId: null
      };
    }

    case UPDATE_CREATE_ROW_DATA: {
      return {
        ...state,
        createRowData: {
          ...state.createRowData,
          ...action.payload
        }
      };
    }
    case MOUNT_TIMESHEET: {
      const { time } = payload;
      return {
        ...state,
        time
      };
    }
    case FLUSH_HAS_TIME_ENTRIES: {
      return {
        ...state,
        hasTimeEntries: []
      };
    }
    case CHECK_HAS_TIME_ENTRIES.TRIGGER: {
      return {
        ...state,
        loadingHasTimeEntries: true
      };
    }
    case CHECK_HAS_TIME_ENTRIES.SUCCESS: {
      return {
        ...state,
        loadingHasTimeEntries: false,
        hasTimeEntries: [...state.hasTimeEntries, ...action.payload.response]
      };
    }
    case constants.FETCH_TIMESHEETS_PREDICTIONS.SUCCESS: {
      const { estimates } = payload.response;
      return {
        ...state,
        timesheetsPredictions: [
          ...state.timesheetsPredictions,
          ...(estimates || [])
        ]
      };
    }
    case constants.OPEN_TIMESHEET_SETTINGS_MODAL: {
      return {
        ...state,
        isSettingsModalOpen: true
      };
    }
    case constants.CLOSE_TIMESHEET_SETTINGS_MODAL: {
      return {
        ...state,
        isSettingsModalOpen: false
      };
    }
    case constants.OPEN_TIMESHEET_INCOMPLETE_MODAL: {
      return {
        ...state,
        isIncompleteModalOpen: true
      };
    }
    case constants.CLOSE_TIMESHEET_INCOMPLETE_MODAL: {
      return {
        ...state,
        isIncompleteModalOpen: false
      };
    }
    case constants.OPEN_SEND_REMINDER_MODAL: {
      const { sendReminderId } = action.payload;
      return {
        ...state,
        isSendReminderModalOpen: true,
        sendReminderId: sendReminderId
      };
    }
    case constants.CLOSE_SEND_REMINDER_MODAL: {
      return {
        ...state,
        isSendReminderModalOpen: false,
        sendReminderId: null
      };
    }
    case constants.SET_INCOMPLETE_TIMESHEET_DATES: {
      const { incompleteTimesheetDates } = action.payload;
      return {
        ...state,
        incompleteTimesheetDates: incompleteTimesheetDates ?? {}
      };
    }
    case constants.FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS.SUCCESS: {
      return {
        ...state,
        dateRangeProjectActivities: {
          ...state.dateRangeProjectActivities,
          ...Object.entries(payload.response).reduce((acc, [key, value]) => {
            if (state.dateRangeProjectActivities[key]) {
              acc[key] = {
                ...state.dateRangeProjectActivities[key],
                ...value
              };
            } else {
              acc[key] = value;
            }
            return acc;
          }, {})
        }
      };
    }
    case constants.SET_SHOW_TIMESHEET_MOVED_MESSAGE: {
      const { showMessage } = payload;
      return {
        ...state,
        showTimesheetMovedMessage: showMessage
      };
    }
    case SET_TIMESHEET_REMINDER_DAY: {
      return {
        ...state,
        timesheetReminderDay: action.payload.newTimesheetReminderDay
      };
    }
    case SET_TIMESHEET_REMINDER_SENDER_ID: {
      return {
        ...state,
        timesheetReminderSenderId: action.payload.newTimesheetReminderSenderId
      };
    }
    case FETCH_REMAINING_TIMESHEET_HOURS.SUCCESS: {
      return {
        ...state,
        incompleteTimesheetDates: payload.response
      };
    }
    default: {
      return state;
    }
  }
};

export default timesheets;
