import React from 'react';

type DevPropsProps = {
  [key: string]: unknown;
} & {
  children?: React.ReactNode;
};

/**
 * For quick access to data values we may want to see during dev/debugging
 */
const DevProps = (props: DevPropsProps) => (
  <React.Fragment>{props.children}</React.Fragment>
);

export default DevProps;
