import React from 'react';
import withHovered from 'hocs/withHovered';
import GrayAddMilestone from 'images/add-milestone-gray-outline.svg';
import BlueAddMilestone from 'images/add-milestone-blue.svg';

const AddMilestoneBtn = ({ isHovered, toggleHoveredOn, toggleHoveredOff }) => {
  return (
    <div
      className="add-milestones"
      onMouseEnter={toggleHoveredOn}
      onMouseLeave={toggleHoveredOff}
    >
      <img src={isHovered ? BlueAddMilestone : GrayAddMilestone} />
    </div>
  );
};

export default withHovered(AddMilestoneBtn);
