import { RoleTemplates } from './types';

/**
 * Used by legacy permissions module
 */
export const roleTemplatesHash = {
  admin: 'Admin',
  member: 'Member',
  projectManager: 'Project Manager',
  boardManager: 'Board Manager',
  teamMember: 'Team Member',
  financialManager: 'Financial Manager'
} as const;

export const roleTemplates: RoleTemplates[] = Object.values(roleTemplatesHash);
