import { put, select } from 'redux-saga/effects';
import {
  getAuthToken,
  getTaskAccountFilter,
  getSelectedTeamMember
} from 'selectors';
import { fetchAllHomeTags } from '../actions';
import { fetchEntity } from './generics';
import { api } from '../service';

export function* fetchFilteredHomeTaskTagsWorker(action) {
  const { filter, projectId, assigneeId } = action.payload;
  const selectedAccountIds = yield select(getTaskAccountFilter);
  const selectedTeamMember = yield select(getSelectedTeamMember);

  const selectedAssignees = assigneeId
    ? [assigneeId]
    : (selectedTeamMember &&
        selectedTeamMember.account.id && [selectedTeamMember.account.id]) ||
      selectedAccountIds;

  const token = yield select(getAuthToken);
  const { error } = yield fetchEntity(
    fetchAllHomeTags,
    api.fetchV2HomeTaskTags,
    undefined,
    [token, filter, projectId, selectedAssignees],
    action
  );
}
