const memberListClass = 'insert-delete-member-list-auto-complete';
const memberInputClass = 'add-new-member-input';
const clearMemberInputClass = 'close-icon';
const buttonClass = 'button';
const removeMemberClass = 'remove-member';
const addToBoardClass = 'add-to-board';
const leaveBoardClass = 'leave-board';
const cancelClass = 'cancel';
const memberListItemClass = 'member-line';
const memberNameClass = 'member-name';
const contentsClass = 'member-line-contents';
const privilegeClass = 'privilege';
const memberRoleDropdownClass = 'members-role-dropdown';

export default [
  memberListClass,
  memberInputClass,
  clearMemberInputClass,
  buttonClass,
  removeMemberClass,
  addToBoardClass,
  leaveBoardClass,
  cancelClass,
  memberListItemClass,
  memberNameClass,
  contentsClass,
  privilegeClass,
  memberRoleDropdownClass
];
