/** ************** Organization Section ****************/

export const INVITE_MEMBER_TO_TEAM_TIP =
  'You do not have permission to invite members to this team';

export const DELETE_MEMBER_FROM_TEAM_TIP =
  'You do not have permission to delete members from this team';

export const MANAGE_ROLE_TIP = 'You do not have permission to manage roles';

export const MANAGE_MEMBER_ROLE_TIP =
  'You do not have permission to edit this member access level';

export const INVITE_GUESTS_TIP =
  'You do not have permission to invite guests to this team';

/** ************** Member Section ****************/
export const MEMBER_PROFILE_TIP =
  'You do not have permission to edit member profile';

/** ************** Dashboard Section ****************/

export const CREATE_DASHBOARD_TIP =
  'You do not have permission to create new dashboards';

export const EDIT_DASHBOARD_TIP =
  'You do not have permission to edit dashboards';

export const MANAGE_WIDGET_TIP =
  'You do not have permission to Add/Edit/Remove widgets';

export const CREATE_WIDGET_TIP = 'You do not have permission to create widgets';

export const EDIT_WIDGET_TIP = 'You do not have permission to edit widgets';

export const DELETE_WIDGET_TIP = 'You do not have permission to delete widgets';

/** ************** Portfolio Section ****************/

export const CREATE_PORTFOLIO_TIP =
  'You do not have permission to create portfolios';

export const ARCHIVE_PORTFOLIO_TIP =
  'You do not have permission to archive/unarchive portfolios';

export const DELETE_PORTFOLIO_TIP =
  'You do not have permission to delete portfolios';

export const MAKE_PORTFOLIO_PRIVATE_TIP =
  "You do not have permission to change this portfolio's privacy settings";

export const CREATE_PORTFOLIO_MEMBERSHIP_TIP =
  'You do not have permission to add members to this portfolio';

export const DELETE_PORTFOLIO_MEMBERSHIP_TIP =
  'You do not have permission to delete members from this portfolio';

export const EDIT_PORTFOLIO_MEMBERSHIP_TIP =
  'You do not have permission to edit membership in this portfolio';

export const CREATE_PORTFOLIO_MANAGER_TIP =
  'You do not have permission to assign portfolio managers';

export const DELETE_PROJECT_TIP =
  'You do not have permission to delete projects';

export const ARCHIVE_PROJECT_TIP =
  'You do not have permission to archive projects';

export const EDIT_PORTFOLIO_TITLE_TIP =
  'You do not have permission to edit portfolio titles';

export const EDIT_PORTFOLIO_TIP =
  'You do not have permission to edit portfolios';

/** ************** Timesheet Section ****************/

export const READ_TIMESHEET_TIP =
  'You do not have permission to read timesheets';

export const EDIT_TIMESHEET_TIP =
  'You do not have permission to edit this timesheet';

export const APPROVE_TIMESHEET_TIP =
  'You do not have permission to change the approval status of timesheets';

/** ************** Projects Section ****************/

export const CREATE_PROJECT_MANAGER_TIP =
  'You do not have permission to assign project managers';

export const DELETE_TASK_TIP = 'You do not have permission to delete tasks';
export const DELETE_TASK_BATCH_TIP =
  'You do not have permission to delete one of the selected tasks';

export const EDIT_PROJECT_COLOR_TIP =
  'You do not have permission to edit project color';

export const EDIT_TASK_COMMENT_TIP =
  'You do not have permission to edit task comments';

export const DELETE_TASK_COMMENT_TIP =
  'You do not have permission to delete task comments';

export const EDIT_PROJECT_COMMENT_TIP =
  'You do not have permission to edit project comments';

export const DELETE_PROJECT_COMMENT_TIP =
  'You do not have permission to delete project comments';

export const EDIT_WORK_PLAN_COMMENT_TIP =
  'You do not have permission to edit work plan comments';

export const DELETE_WORK_PLAN_COMMENT_TIP =
  'You do not have permission to delete work plan comments';

export const EDIT_SCOPE_COMMENT_TIP =
  'You do not have permission to edit scope comments. Contact your admin(s).';

export const DELETE_SCOPE_COMMENT_TIP =
  'You do not have permission to delete scope comments. Contact your admin(s).';

export const READ_BUDGET_BY_COST_RATE_TIP =
  'You do not have permission to view by Cost Rate';

export const MANAGE_PROJECT_VIEWS_TIP =
  'You do not have permission to manage project views';

export const ADD_PROJECT_MEMBERSHIP_TIP =
  'You do not have permission to add members to this project';

export const EDIT_PROJECT_MEMBERSHIP_TIP =
  'You do not have permission to edit membership in this project';

export const DELETE_PROJECT_MEMBERSHIP_TIP =
  'You do not have permission to delete members from this project';

export const EDIT_PHASE_TIP =
  'You do not have permission to edit phases in this project';

export const EDIT_PHASE_DATES_TIP =
  'You do not have permission to edit phase dates in this project';

export const EDIT_WORK_CATEGORY_TIP =
  'You do not have permission to edit work categories in this project';

export const EDIT_WORK_CATEGORY_DATES_TIP =
  'You do not have permission to edit work category dates in this project';

export const EDIT_MILESTONE_TIP =
  'You do not have permission to edit milestones in this project';

export const EDIT_MILESTONE_DATES_TIP =
  'You do not have permission to edit milestone dates in this project';

/** ************** WorkPlan Section ****************/

export const DELETE_WORK_PLANS_TIP =
  'You do not have permission to delete work plans';

export const EDIT_WORK_PLANS_TIP =
  'You do not have permission to edit work plans';

export const CREATE_WORK_PLANS_TIP =
  'You do not have permission to create work plans';

export const DRAG_WORK_PLANS_TIP =
  'You do not have permission to drag work plans';

export const RESIZE_WORK_PLANS_TIP =
  'You do not have permission to resize work plans';

export const COPY_WORK_PLANS_TIP =
  'You do not have permission to copy work plans';

export const SPLIT_WORK_PLANS_TIP =
  'You do not have permission to split work plans';

export const IMPORT_WORK_PLANS_TIP =
  'You do not have permission to import work plans';

export const COMMENT_WORK_PLANS_TIP =
  'You do not have permission to post comments on work plans';

export const EDIT_COMMENT_WORK_PLANS_TIP =
  'You do not have permission to edit comments on work plans';

export const DELETE_COMMENT_WORK_PLANS_TIP =
  'You do not have permission to delete comments on work plans';

export const EDIT_TASK_WORK_PLANS_TIP =
  'You do not have permission to edit tasks on work plans';

export const REMOVE_TASK_WORK_PLANS_TIP =
  'You do not have permission to remove tasks from work plans';

export const EDIT_SCOPE_WORK_PLANS_TIP =
  'You do not have permission to edit scope on work plans';

export const REMOVE_SCOPE_WORK_PLANS_TIP =
  'You do not have permission to remove scope from work plans';

/** ************** Misc Section ****************/
export const EDIT_COLOR_TIP = 'You do not have permission to edit color';
export const TEAM_SETTINGS_TIP =
  'You do not have permission to access the Team Settings page';

export const ADD_SKILLS_TIP = 'You do not have permission to add skills';

export const EDIT_SKILLS_TIP = 'You do not have permission to edit skills';

/** ************* Sandbox Section ***************/

export const INVITE_MEMBER_FEATURE_TIP =
  'Invite Member is for presentation only on demo accounts';

export const INTEGRATION_FEATURE_TIP =
  'Integration is for presentation only on demo accounts';

export const TIMESHEET_IMPORT_FEATURE_TIP =
  'Timesheet Import is for presentation only on demo accounts';
