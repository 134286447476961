import React from 'react';
import HeaderCell from '../TaskCells/HeaderCell';

const PhaseHeaderCell = (props) => {
  const { original } = props?.row ?? {};
  if (original?.phase?.activity_order?.length && original?.list?.isOpen) {
    return null;
  }
  return <HeaderCell {...props} />;
};

export default PhaseHeaderCell;
