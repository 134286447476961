import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const BatchSelectAllButtonWrapper = styled.div`
  width: 15px;
  text-align: center;
  color: #0074d9;
  font-size: 12px;
  margin-bottom: 4px;
  position: relative;
  left: 0px;
  font-weight: normal;
  cursor: pointer;
  padding-left: 0px;
  z-index: 0;

  visibility: ${({ someBatchSelected }) =>
    someBatchSelected ? 'visible' : 'hidden'};
  &:hover {
    font-weight: bold;
  }
`;

const BatchSelectAllButton = ({
  showNone,
  toggleBatchSelectAll,
  someBatchSelected
}) => (
  <BatchSelectAllButtonWrapper
    onClick={toggleBatchSelectAll}
    someBatchSelected={someBatchSelected}
  >
    {showNone ? 'None' : 'All'}
  </BatchSelectAllButtonWrapper>
);

BatchSelectAllButton.propTypes = {
  toggleBatchSelectAll: PropTypes.func.isRequired
};

export default BatchSelectAllButton;
