import { ValueOf } from 'type-fest';

export const DEPENDENCY_TYPES = {
  StartDate: 'StartDate',
  EndDate: 'EndDate',
  StartToEndDate: 'StartToEndDate',
  EndToStartDate: 'EndToStartDate',
  StartAndEndDate: 'StartEndDate' // include to support legacy StartAndEnd
} as const;

export const DEPENDABLE_TYPES = {
  PHASE: 'Phase',
  SCOPE: 'BaseTask',
  TASK: 'BaseTask',
  WORK_PLAN: 'ActivityPhaseScheduleBar',
  MILESTONE: 'Milestone'
} as const;

export const DEPENDABLE_LABELS: Record<
  ValueOf<typeof DEPENDABLE_TYPES>,
  string
> = {
  [DEPENDABLE_TYPES.PHASE]: 'Phase',
  [DEPENDABLE_TYPES.SCOPE]: 'Scope',
  [DEPENDABLE_TYPES.TASK]: 'Task',
  [DEPENDABLE_TYPES.WORK_PLAN]: 'Work plan',
  [DEPENDABLE_TYPES.MILESTONE]: 'Milestone'
} as const;

export const AVAILABLE_OPTIONS = {
  start: 'start',
  end: 'end',
  startEnd: 'startEnd'
} as const;
export const AVAILABLE_OPTIONS_LABEL: Record<
  ValueOf<typeof AVAILABLE_OPTIONS>,
  string
> = {
  [AVAILABLE_OPTIONS.start]: 'Start',
  [AVAILABLE_OPTIONS.end]: 'End',
  [AVAILABLE_OPTIONS.startEnd]: 'Start & End'
};
