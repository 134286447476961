import { convertIsoDateToUsaDate } from 'appUtils/dateUtils';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { formatISO } from 'date-fns';
import { DateISOString } from 'models/date';
import { Moment } from 'moment';
import { useEffect } from 'react';
import styled from 'styled-components';
import { CellContentsProps } from '../types';

const CALENDAR_START_LABELS = { start: 'START' };
const CALENDAR_END_LABELS = { start: 'END' };

export const DateCell = ({
  entityRate,
  isStartDate = false,
  setDate
}: CellContentsProps & { isStartDate?: boolean }) => {
  const { date, labels, nullDate, nullDateTooltip } = isStartDate
    ? {
        date: entityRate.start_date,
        labels: CALENDAR_START_LABELS,
        nullDate: 'Start',
        nullDateTooltip: 'Unable to set the start date of the oldest rate.'
      }
    : {
        date: entityRate.end_date,
        labels: CALENDAR_END_LABELS,
        nullDate: 'Ongoing',
        nullDateTooltip: 'Unable to set the end date of the newest rate.'
      };

  const customInputRenderer = (
    date: DateISOString | undefined,
    _: DateISOString | undefined,
    handleOpen: () => void
  ) => (
    <DateInput onClick={handleOpen}>
      {date ? convertIsoDateToUsaDate(date) : nullDate}
    </DateInput>
  );

  const handleDateSelect = ({
    startDate: date
  }: {
    startDate: Nullable<Moment>;
  }) => {
    if (date?.isValid())
      setDate({
        date: formatISO(date.valueOf(), { representation: 'date' }),
        entityRate,
        isStartDate
      });
  };

  useEffect(() => {
    if (!date) rebuildTooltip();
  }, [date]);

  return date ? (
    <DateRangeCalendar
      customInput={customInputRenderer}
      isSingleDay
      itemStartDate={date ?? undefined}
      labels={labels}
      onSubmit={handleDateSelect}
      showInputs
    />
  ) : (
    <div {...defaultTooltipProps} data-tip={nullDateTooltip}>
      {nullDate}
    </div>
  );
};

const DateInput = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.colorBudgetBlue};
  }
`;
