import { DATE_DAYS_MAP } from 'appConstants/date';
import { AccountCapacity } from 'models/accountCapacity';
import { parseISO } from 'date-fns';
import { DateISOString } from 'models/date';
import { UtilizationBreakdown, Hours } from 'UtilizationModule/types';

/**
 * Gets the capacity of an account for a specific date.
 */
export const getCapacityByDate = (
  date: Date,
  accountCapacity: AccountCapacity
): number | undefined => accountCapacity[DATE_DAYS_MAP[date.getDay()]];

export const getUtilizationsBreakdown = ({
  accountCapacity,
  accountUtilization,
  PTOs,
  holidays
}: {
  accountCapacity: AccountCapacity;
  accountUtilization: Record<DateISOString, Hours>;
  PTOs: Record<DateISOString, Hours>;
  holidays: Record<DateISOString, { dateOfWeek: string }>;
}): Record<DateISOString, UtilizationBreakdown> => {
  return Object.entries(accountUtilization).reduce((acc, [dateKey, value]) => {
    const date = parseISO(dateKey);
    const planned = value;
    const capacity = getCapacityByDate(date, accountCapacity) || 0;
    const PTO = PTOs[dateKey] || 0;
    const holiday = holidays[dateKey] ? capacity : 0;

    const isHoliday = Boolean(holidays[dateKey]);

    // assume that holiday is always all day
    const offHours = (isHoliday ? holiday : PTO) || 0;

    const cappedOffHours = Math.min(offHours, capacity);

    const committed = planned + offHours;

    const cappedCommitted = Math.min(committed, capacity);

    const available = capacity - committed;

    acc[dateKey] = {
      capacity,
      planned,
      PTO,
      holiday,
      committed,
      cappedCommitted,
      cappedOffHours,
      available
    };

    return acc;
  }, {});
};

export const utilizationBreakdownMerger = (
  objValue: Partial<UtilizationBreakdown>,
  srcValue: UtilizationBreakdown
) =>
  Object.keys(srcValue).reduce((acc, key) => {
    acc[key] = (objValue?.[key] ?? 0) + srcValue[key];
    return acc;
  }, {});
