import useHardDeletePhaseModal from 'appUtils/hooks/useHardDeletePhaseModal';

const withHardDeletePhaseModal = (Component) => {
  const WithHardDeletePhaseModal = (props) => {
    const { handleOpenHardDeletePhaseModal, HardDeletePhaseModal } =
      useHardDeletePhaseModal();

    return (
      <Component
        {...props}
        handleOpenHardDeletePhaseModal={handleOpenHardDeletePhaseModal}
        HardDeletePhaseModal={HardDeletePhaseModal}
      />
    );
  };

  return WithHardDeletePhaseModal;
};

export default withHardDeletePhaseModal;
