import styled from 'styled-components';
import theme from 'theme';
import {
  StyledStickyHeader,
  StyledStickyCellContainer
} from 'components/Table/TableStickyHeader';

export const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  top: -20px;
  left: 5px;
  z-index: 2;
  position: absolute;
  width: 915px;
  justify-content: space-between;

  .collapse-all {
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: ${theme.colors.colorMediumGray9};
      }
    }
  }
`;

export const ContainerDiv = styled.div<{
  $width: number;
}>`
  margin: 0;
  position: relative;
  width: ${(props) => props.$width}px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const StyledMembersPermissionsTable = styled.div`
  display: flex;
  margin: 40px auto 0 0;
  position: relative;
  flex: 1;
  min-height: 0;

  .collapse-arrow {
    path {
      fill: ${theme.colors.colorMediumGray9};
    }
  }

  .table {
    margin-bottom: 0;
    height: 100%;
  }
  .table-body {
    height: 100%;
    flex: 1 1 0%;
  }
  .variable-size-list {
    height: 100% !important;

    // hide scrollbar: https://stackoverflow.com/a/49278385/15991484
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    /* WebKit */
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  .td {
    height: 100%;
    flex-shrink: 0;

    &.archivedSectionHeaderRow {
      // align the cell to ref cell
      margin-left: 25px;
    }

    &.memberPermissionRow.leadingColumn {
      opacity: 0;
    }
  }

  .tr {
    &.columnHeaderRow .td:not(.select):not(.leadingColumn) {
      border-bottom: 1px solid ${theme.colors.colorLightGray15};

      &.employeeNumber {
        padding-left: 10px;
      }
    }

    &.memberPermissionRow {
      .td:not(.select):not(.leadingColumn) {
        border-bottom: 1px solid ${theme.colors.colorLightGray25};
      }

      &:hover {
        .td.employeeNumber,
        .td.name,
        .td.email {
          .text-container {
            color: ${theme.colors.colorBudgetBlue};
          }
        }

        .td {
          &.leadingColumn {
            opacity: 1;
          }
        }
      }
    }
  }

  ${StyledStickyHeader} {
    padding: 0;

    .td {
      background: ${theme.colors.colorTranslucentGray4};
    }
    ${StyledStickyCellContainer} {
      .td.accessLevel-Member {
        background: ${theme.colors.colorTranslucentGray4};
      }
    }
  }
`;
