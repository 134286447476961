import { createRequestTypes } from 'appUtils';

export const FETCH_UTILIZATIONS = createRequestTypes('FETCH_UTILIZATIONS');
export const FETCH_UTILIZATIONS_REPORT = createRequestTypes(
  'FETCH_UTILIZATIONS_REPORT'
);
export const FETCH_UTILIZATIONS_REPORT_TOTALS = createRequestTypes(
  'FETCH_UTILIZATIONS_REPORT_TOTALS'
);

export const FETCH_TEAM_UTILIZATION_REPORT = createRequestTypes(
  'FETCH_TEAM_UTILIZATION_REPORT'
);

export const FETCH_UTILIZATIONS_ORGANIZATION_CHART = createRequestTypes(
  'FETCH_UTILIZATIONS_ORGANIZATION_CHART'
);

export const FETCH_MEMBERS_UTILIZATION_REPORT = createRequestTypes(
  'FETCH_MEMBERS_UTILIZATION_REPORT'
);

export const FETCH_PROJECT_UTILIZATION_REPORT = createRequestTypes(
  'FETCH_PROJECT_UTILIZATION_REPORT'
);
export const FETCH_PHASE_UTILIZATION_REPORT = createRequestTypes(
  'FETCH_PHASE_UTILIZATION_REPORT'
);
export const FETCH_TEAM_HISTORICAL_UTILIZATION = createRequestTypes(
  'FETCH_TEAM_HISTORICAL_UTILIZATION'
);

/* ------------------------------------ - ----------------------------------- */

export const DATA_KEY = {
  BILLABLE: 'billable_hours',
  NONBILLABLE: 'nonbillable_hours',
  PTO: 'pto_hours',
  PTO_FOR_DISPLAY: 'pto_hours_for_display',
  HOLIDAY: 'holiday_hours',
  HOLIDAY_FOR_DISPLAY: 'holiday_hours_for_display',
  BILLABLE_PERCENT: 'billablePercent',
  NONBILLABLE_PERCENT: 'nonbillablePercent',
  PTO_PERCENT: 'ptoPercent',
  HOLIDAY_PERCENT: 'holidayPercent',
  // timesheet status
  SUBMITTED: 'submitted_hours',
  NOT_SUBMITTED: 'not_submitted_hours',
  APPROVED: 'approved_hours',
  REJECTED: 'rejected_hours'
};

export const CAPACITY_KEY = {
  CAPACITY_FOR_PERCENTAGE: 'capacity_for_percentage',
  REMAINING_CAPACITY: 'remaining_capacity',
  REMAINING_CAPACITY_PERCENT: 'remaining_capacity_percentage'
};

export const DATA_KEY_TO_PERCENT = {
  [DATA_KEY.BILLABLE]: DATA_KEY.BILLABLE_PERCENT,
  [DATA_KEY.NONBILLABLE]: DATA_KEY.NONBILLABLE_PERCENT,
  [DATA_KEY.HOLIDAY]: DATA_KEY.HOLIDAY_PERCENT,
  [DATA_KEY.PTO]: DATA_KEY.PTO_PERCENT,
  [CAPACITY_KEY.REMAINING_CAPACITY]: CAPACITY_KEY.REMAINING_CAPACITY_PERCENT
};

export const DATA_KEY_FOR_SUMMARY_WIDGET = [
  DATA_KEY.PTO_FOR_DISPLAY,
  DATA_KEY.HOLIDAY_FOR_DISPLAY
];

export const CHART_STACK_ORDER = [
  DATA_KEY.HOLIDAY,
  DATA_KEY.PTO,
  DATA_KEY.NONBILLABLE,
  DATA_KEY.BILLABLE
];

export const CHART_STACK_ORDER_WITH_CAPACITY = [
  DATA_KEY.HOLIDAY,
  DATA_KEY.PTO,
  CAPACITY_KEY.REMAINING_CAPACITY,
  DATA_KEY.NONBILLABLE,
  DATA_KEY.BILLABLE
];

// order in CHART_STACK_ORDER_PERCENT and CHART_STACK_ORDER_PERCENT_WITH_CAPACITY is irrelevant with the order in CHART_STACK_ORDER and CHART_STACK_ORDER_WITH_CAPACITY
// CHART_STACK_ORDER_PERCENT* is used for bar charts and CHART_STACK_ORDER* is used for the bar cell in table.
export const CHART_STACK_ORDER_PERCENT = [
  DATA_KEY.HOLIDAY_PERCENT,
  DATA_KEY.PTO_PERCENT,
  DATA_KEY.NONBILLABLE_PERCENT,
  DATA_KEY.BILLABLE_PERCENT
];

export const CHART_STACK_ORDER_PERCENT_WITH_CAPACITY = [
  DATA_KEY.HOLIDAY_PERCENT,
  DATA_KEY.PTO_PERCENT,
  CAPACITY_KEY.REMAINING_CAPACITY_PERCENT,
  DATA_KEY.NONBILLABLE_PERCENT,
  DATA_KEY.BILLABLE_PERCENT
];

export const PERCENT_KEY_TO_DEFAULT = {
  [DATA_KEY.BILLABLE_PERCENT]: DATA_KEY.BILLABLE,
  [DATA_KEY.NONBILLABLE_PERCENT]: DATA_KEY.NONBILLABLE,
  [DATA_KEY.HOLIDAY_PERCENT]: DATA_KEY.HOLIDAY,
  [DATA_KEY.PTO_PERCENT]: DATA_KEY.PTO,
  [CAPACITY_KEY.REMAINING_CAPACITY_PERCENT]: CAPACITY_KEY.REMAINING_CAPACITY
};
