import React from 'react';
import PropTypes from 'prop-types';

import { StyledStickyRow } from './styles';

class FlyoutStickyRow extends React.Component {
  handleClick = (e) => {
    const { handleClick } = this.props;
    handleClick(e);
  };

  render() {
    const { copy, renderStickyRow, renderEditStickyRow, editingId, editMode } =
      this.props;
    return renderEditStickyRow && (editingId || editMode) ? (
      <div onClick={this.handleClick}>{renderEditStickyRow()}</div>
    ) : renderStickyRow ? (
      <div onClick={this.handleClick}>{renderStickyRow()}</div>
    ) : (
      <StyledStickyRow onClick={this.handleClick}>
        {copy.sticky}
      </StyledStickyRow>
    );
  }
}

FlyoutStickyRow.propTypes = {
  copy: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default FlyoutStickyRow;
