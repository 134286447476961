import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSkills } from 'actionCreators';
import { getSelectedTeamId } from 'selectors';
import styled from 'styled-components';
import SkillsTable from './SkillsTable';

const SkillsTableContainer = ({ teamId, fetchSkills }) => {
  useEffect(() => {
    if (teamId) {
      fetchSkills({ teamId, loadAll: true });
    }
  }, [fetchSkills, teamId]);

  return (
    <StyledSkillsTableContainer>
      <SkillsTable />
    </StyledSkillsTableContainer>
  );
};

const mapStateToProps = (state) => ({
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  fetchSkills
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillsTableContainer);

/* ------------------------------------ - ----------------------------------- */

const StyledSkillsTableContainer = styled.div`
  height: 100%;
  padding: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
`;
