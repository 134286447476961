import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';

export const initialState = {
  activeTeamSalaries: {},
  memberSalaries: {},
  overhead: {}
};
const byTeamMembershipId = (item) => item.team_membership_id;

const salaries = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_ALL_TEAM_SALARIES.SUCCESS: {
      const salaries = action.payload.response.salaries;
      return {
        ...state,
        activeTeamSalaries: {
          ...keyBy(salaries, byTeamMembershipId)
        }
      };
    }
    case budgetConstants.FETCH_MEMBER_SALARIES.SUCCESS: {
      const memberSalaries = action.payload.response.salaries;
      const teamMembershipId = action.payload.requestPayload.teamMembershipId;
      return {
        ...state,
        memberSalaries: {
          ...state.memberSalaries,
          [teamMembershipId]: memberSalaries
        }
      };
    }
    case budgetConstants.CREATE_MEMBER_SALARY.SUCCESS: {
      const { response: newSalary, requestPayload } = action.payload;
      const { teamMembershipId } = requestPayload;

      return {
        ...state,
        activeTeamSalaries: {
          ...state.activeTeamSalaries,
          [teamMembershipId]: newSalary
        },
        memberSalaries: {
          ...state.memberSalaries,
          [teamMembershipId]: [
            newSalary,
            ...(state.memberSalaries[teamMembershipId] || [])
          ]
        }
      };
    }
    case budgetConstants.UPDATE_MEMBER_SALARY.SUCCESS: {
      const updatedSalary = action.payload.response;
      const teamMembershipId = action.payload.requestPayload.teamMembershipId;
      const nextSalaries = { ...state.activeTeamSalaries };
      if (nextSalaries[teamMembershipId]?.id === updatedSalary.id) {
        nextSalaries[teamMembershipId] = updatedSalary;
      }
      const nextAccountSalaries =
        state.memberSalaries[teamMembershipId]?.map((salary) =>
          salary.id === updatedSalary.id ? updatedSalary : salary
        ) || [];
      return {
        ...state,
        activeTeamSalaries: nextSalaries,
        memberSalaries: {
          ...state.memberSalaries,
          [teamMembershipId]: nextAccountSalaries
        }
      };
    }
    case budgetConstants.FETCH_OVERHEAD.SUCCESS:
    case budgetConstants.CREATE_OVERHEAD.SUCCESS: {
      return {
        ...state,
        overhead: action.payload.response
      };
    }
    default:
      return state;
  }
};

export default salaries;
