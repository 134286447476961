import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import CapacitySummaryWidget from 'views/Home/Widgets/Widgets/CapacitySummaryWidget/CapacitySummaryWidget';
import { useAccountCapacitySum } from 'DashboardModule/hooks/useAccountCapacityTotals';
import {
  GENERATE_CAPACITY_RANGE,
  CAPACITY_LABELS
} from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import withFilter from 'hocs/withFilter';

const emptyArray = [];
const WorkloadWidget = ({ activeFilter, widgetConfig }) => {
  const teamId = useSelector(getSelectedTeamId);

  const fetchParams = useMemo(
    () => ({
      filterStateId: activeFilter.id,
      params: {
        ...GENERATE_CAPACITY_RANGE[
          activeFilter.custom?.range !== undefined
            ? activeFilter.custom?.range
            : ZOOM_LEVELS.WEEK
        ]?.({
          start: activeFilter.custom?.start_date,
          end: activeFilter.custom?.end_date
        }),
        account_ids: activeFilter.account_ids || emptyArray,
        team_id: teamId,
        data_type: BUDGET_RECORD_DATA_TYPES.ACCOUNT
      },
      initial: true
    }),
    [activeFilter.account_ids, activeFilter.custom, activeFilter.id, teamId]
  );

  return (
    <CapacitySummaryWidget
      activeFilter={activeFilter}
      filterStateId={activeFilter.id}
      viewBy={activeFilter.name}
      fetchParams={fetchParams}
      chartOnly
      chartWidth={260}
      chartHeight={130}
      innerRadius={110}
      outerRadius={124}
      cy={130}
      containerClassName={'dashboard-widget'}
      chartLabel={CAPACITY_LABELS[activeFilter.custom?.range]}
      isSelfLoading
      widgetConfig={widgetConfig}
      // If Team dashboard to have Workload widget for Projects, update filterIdType accordingly.
      filterIdType={'account_ids'}
    />
  );
};

export default withFilter(WorkloadWidget);
