import { useMemo, useState, ChangeEvent, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { getCurrencySymbolByCode } from 'CurrencyModule/utils';
import { UnderlinedTextInput } from './UnderlinedTextInput/UnderlinedTextInput';
import { SelectDropdownProps, SelectInput } from './SelectInput/SelectInput';
import { CurrencyDropdown } from 'CurrencyModule/components/CurrencyDropdown';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  createRateGroup,
  updateRateGroup
} from 'RatesModule/ratesActionCreators';
import { RateGroup } from 'RatesModule/models/RateGroup';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import { getSplitFlags } from 'selectors';
import { getTeamCurrencyCodeByTeamId as makeGetTeamCurrencyCodeByTeamId } from 'CurrencyModule/selectors';
import { RootState } from 'reduxInfra/shared';
import ThinCloseIcon from 'icons/ThinCloseIcon';
import cn from 'classnames';
import { EntityRateType } from 'RatesModule/models/EntityRate';

enum CustomCurrencyItemType {
  OrgDefault
}

type CustomCurrencyItem = {
  type: CustomCurrencyItemType;
  label: string;
};

enum ValidationError {
  NameMissing
}

const isCustomCurrencyItem = (
  value: CustomCurrencyItem | Record<string, unknown>
): value is CustomCurrencyItem => 'type' in value;

const leadingCurrencyItems = {
  items: [
    {
      type: CustomCurrencyItemType.OrgDefault,
      label: 'Use Org Default Currency'
    }
  ] as CustomCurrencyItem[],
  render: (item: CustomCurrencyItem) => item.label,
  getId: (item: CustomCurrencyItem) => item.type
} as const;

export const AddEditRateGroupModal = ({
  activeEntityType,
  onClose,
  rateGroup
}: {
  activeEntityType: EntityRateType;
  onClose: () => void;
  rateGroup?: Pick<RateGroup, 'currency' | 'id' | 'name'>;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const teamId = useAppSelector(getSelectedTeamId);
  const { rateGroupCurrencyFlag } = useAppSelector(getSplitFlags) as Record<
    string,
    boolean
  >;
  const getTeamCurrencyCodeByTeamId = useMemo(
    () => (state: RootState) =>
      makeGetTeamCurrencyCodeByTeamId(state, { teamId }),
    [teamId]
  );
  const teamCurrencyCode = useAppSelector(getTeamCurrencyCodeByTeamId);

  const isEdit = rateGroup !== undefined;

  const [currencyCode, setCurrencyCode] = useState<string | undefined>(
    rateGroup?.currency ?? undefined
  );
  const [name, setName] = useState(rateGroup?.name ?? '');
  const [errors, setErrors] = useState<Set<ValidationError>>(new Set());

  const handleCurrencySelect = (
    value: CustomCurrencyItem | { currencyCode: string }
  ) =>
    setCurrencyCode(
      isCustomCurrencyItem(value) ? undefined : value?.currencyCode
    );

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);

    // Clear any name errors after the name changes.
    setErrors((errors) => {
      errors.delete(ValidationError.NameMissing);
      return errors;
    });
  };

  const validateForm = ({ name }: { name: string }): Set<ValidationError> => {
    const errors = new Set<ValidationError>();

    if (!name.length) errors.add(ValidationError.NameMissing);

    return errors;
  };

  const handleConfirm = () => {
    const errors = validateForm({ name });
    if (errors.size) {
      setErrors(errors);
    } else if (teamId && teamCurrencyCode) {
      if (isEdit) {
        dispatch(
          updateRateGroup({
            active_entity_type: activeEntityType,
            currency: currencyCode ?? teamCurrencyCode,
            name,
            rate_group_id: rateGroup.id
          })
        );
      } else {
        dispatch(
          createRateGroup({
            active_entity_type: activeEntityType,
            currency: currencyCode ?? teamCurrencyCode,
            name,
            team_id: teamId
          })
        );
      }

      onClose();
    }
  };

  const handleRenderDropdown = (
    props: SelectDropdownProps<
      | {
          currencyCode: string | undefined;
        }
      | CustomCurrencyItem
    >
  ) => (
    <CurrencyDropdown
      customHeader={<CurrencyHeader>Rate Group Currency</CurrencyHeader>}
      leadingItems={leadingCurrencyItems}
      HeaderButton={
        <ThinCloseIcon
          stroke={theme.colors.colorPureBlack}
          width={16}
          height={16}
          strokeWidth={0.9}
        />
      }
      {...props}
    />
  );

  const nameError = errors.has(ValidationError.NameMissing)
    ? 'The rate group name is required.'
    : undefined;

  useEffect(() => {
    if (errors.size) rebuildTooltip();
    else ReactTooltip.hide();
  }, [errors.size, nameError]);

  const labelCurrencyCode = currencyCode ?? teamCurrencyCode;

  return (
    <SimpleConfirmModal
      body={
        <Fields $hideCurrency={!rateGroupCurrencyFlag}>
          {rateGroupCurrencyFlag && (
            <>
              <FieldHeader>CURRENCY</FieldHeader>
              <SelectInput
                onSelect={handleCurrencySelect}
                renderDropdown={handleRenderDropdown}
              >
                {labelCurrencyCode
                  ? `${labelCurrencyCode} (${getCurrencySymbolByCode({
                      currencyCode: labelCurrencyCode
                    })})`
                  : 'No currency'}
              </SelectInput>
            </>
          )}
          <>
            <FieldHeader>NAME</FieldHeader>
            <UnderlinedTextInput
              $showError={!!nameError}
              {...defaultTooltipProps}
              data-tip={nameError}
              onChange={handleNameChange}
              value={name}
            />
          </>
        </Fields>
      }
      confirmLabel="Done"
      header={isEdit ? 'Edit Rate Group' : 'Add Rate Group'}
      isOpen
      onCancel={onClose}
      onConfirm={handleConfirm}
      shouldSetIsClosingOnClose={false}
    />
  );
};

const Fields = styled.div.attrs<{ $hideCurrency: boolean }>(
  ({ $hideCurrency }) => ({
    className: cn({
      hideCurrency: $hideCurrency
    })
  })
)<{ $hideCurrency: boolean }>`
  display: grid;
  gap: 2px 15px;
  grid-auto-flow: column;
  grid-template: repeat(2, auto) / auto 1fr;

  &.hideCurrency {
    grid-template-columns: 1fr;
  }
`;

const FieldHeader = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: 700;
`;

const CurrencyHeader = styled.div`
  font-size: 21px;
  font-weight: 600;
`;
