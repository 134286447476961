import { CurrencyDropdown } from './CurrencyDropdown';
import CloseIcon from 'icons/CloseIcon';
import theme from 'theme';
import styled from 'styled-components';
import { updateEntityDefaultCurrency } from 'CurrencyModule/actionCreators';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { useAppDispatch } from 'reduxInfra/hooks';

export const TeamCurrencyDropdown = ({
  isOpen,
  target,
  onClose,
  currencyDropdownProps
}: {
  isOpen: boolean;
  target: HTMLElement | null;
  onClose: () => void;
  currencyDropdownProps: {
    teamId;
  };
}) => {
  const dispatch = useAppDispatch();

  const handleTeamCurrencySelect = ({
    currencyCode
  }: {
    currencyCode: string;
  }) => {
    const { teamId } = currencyDropdownProps;

    dispatch(
      updateEntityDefaultCurrency({
        team_id: teamId,
        // BE not accepting 'Team' as a entity type. Will set team currency as long as entity_id is null.
        // Passing in project as work around since entity_type is required.
        entity_type: ENTITY_TYPES.project,
        currency: currencyCode,
        entity_id: null
      })
    );
  };

  const HeaderButton = <StyledCloseIcon height="10px" width="10px" />;

  return (
    <CurrencyDropdown
      isOpen={isOpen}
      target={target}
      onClose={onClose}
      onSelect={handleTeamCurrencySelect}
      HeaderButton={HeaderButton}
      customHeader="Default Currency"
    />
  );
};

const StyledCloseIcon = styled(CloseIcon)`
  path {
    fill: ${theme.colors.colorPureBlack};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorBudgetBlue};
    }
  }
`;
