import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewProjectsIcon from 'images/addviewprojectsicon.svg';
import AddViewBudgetIcon from 'images/addviewbudgeticon.svg';

export const moduleTextHash = {
  1: 'List',
  3: 'Members',
  4: 'Tasks',
  5: 'Budget'
};

export const moduleHash = {
  1: 'status',
  3: 'members',
  4: 'tasks',
  5: 'budget'
};

export const defaultNavModuleIds = [1];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];

// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [...defaultNavModuleIds, 'break'];
export const allModuleIds = [...defaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'A list of projects on this team.',
  4: `All your team’s tasks in one place.`,
  5: `Easily track team member's hours and fees.`
};
export const moduleColorHash = {
  1: '#78c946',
  4: '#3E9EE4',
  5: '#3E9EE4'
};

export const moduleIconHash = {
  1: `url(${AddViewProjectsIcon}) `,
  3: `url(${AddViewTasksIcon}) `,
  4: `url(${AddViewTasksIcon}) `,
  5: `url(${AddViewBudgetIcon}) `
};
export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
