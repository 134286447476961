import React from 'react';
import ContentLoader from 'react-content-loader';

const MemberCardContentLoader = () => (
  <ContentLoader
    height={32}
    width={150}
    speed={2}
    primaryColor="#e1e1e1"
    secondaryColor="#ccc"
  >
    <rect x="40" y="0" rx="4" ry="4" width="107" height="14" />
    <rect x="40" y="20" rx="3" ry="3" width="85" height="12" />
    <circle cx="16" cy="16" r="16" />
  </ContentLoader>
);

export default MemberCardContentLoader;
