import { getAuthToken } from 'AuthenticationModule/selectors';
import { put, select } from 'redux-saga/effects';
import { fetchEntity, changeEntity } from 'sagas/generics';
import * as actions from '../actionCreators';
import * as api from '../api';

export function* fetchEntityRequirements(
  action: ReturnType<typeof actions.fetchEntityRequirements>
) {
  const token = yield select(getAuthToken);

  yield fetchEntity(
    actions.fetchEntityRequirementsActionCreatorsMap,
    api.fetchEntityRequirements,
    null,
    [token, action.payload],
    action
  );
}

export function* updateEntityRequirements(
  action: ReturnType<typeof actions.updateEntityRequirements>
) {
  const token = yield select(getAuthToken);

  yield changeEntity(
    actions.updateEntityRequirementsActionCreatorsMap,
    api.updateEntityRequirements,
    [token, action.payload],
    action
  );
}
