import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Office } from 'SettingsModule/models/office';
import {
  getOfficesArray,
  makeGetOfficesByIds
} from 'SettingsModule/selectors/offices';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';
import theme from 'theme';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';

const copy = {
  headerInitial: 'Select Office',
  searchPlaceholder: 'Type name or select below'
};

const byOfficeId = (item: Office) => item.id;

interface BulkOfficeSelectorProps {
  isOpen: boolean;
  target: MutableRefObject<null>;
  officeFilter: (offices: Office[]) => Office[];
  initialAddedOfficesIds?: number[];
  onDone: ({
    addedOffices,
    removedOffices
  }: {
    addedOffices: Office[];
    removedOffices: Office[];
  }) => void;
  onClose: () => void;
}

export const BulkOfficeSelector = ({
  target,
  isOpen,
  officeFilter,
  initialAddedOfficesIds = [],
  onDone,
  onClose
}: BulkOfficeSelectorProps) => {
  const [addedOffices, setAddedOffices] = useState<Office[]>([]);
  const [removedOffices, setRemovedOffices] = useState<Office[]>([]);

  const offices = useAppSelector(getOfficesArray);

  const getOfficesByIds = useMemo(makeGetOfficesByIds, []);

  const addedOfficesFromIds = useAppSelector((state) =>
    initialAddedOfficesIds.length
      ? getOfficesByIds(state, { ids: initialAddedOfficesIds })
      : undefined
  );

  const handleSelect = (_, { item }: { item: Office }) => {
    if (addedOffices.find((office) => office.id === item.id)) {
      removeFromAddedOffices(item);
      addToRemovedOffices(item);
    } else {
      removeFromRemovedOffices(item);
      addToAddedOffices(item);
    }
  };

  const resetLocalState = () => {
    setAddedOffices([]);
    setRemovedOffices([]);
  };

  useEffect(() => {
    if (isOpen) {
      if (initialAddedOfficesIds && addedOfficesFromIds) {
        setAddedOffices(addedOfficesFromIds);
      }
    }
  }, [initialAddedOfficesIds, addedOfficesFromIds, isOpen]);

  useEffect(() => {
    rebuildTooltip();
  });

  const addToAddedOffices = (office: Office) => {
    setAddedOffices([...addedOffices, office]);
  };

  const removeFromAddedOffices = (office: Office) => {
    setAddedOffices(
      addedOffices.filter((addedOffice) => addedOffice.id !== office.id)
    );
  };

  const addToRemovedOffices = (office: Office) => {
    setRemovedOffices([...removedOffices, office]);
  };

  const removeFromRemovedOffices = (office: Office) => {
    setRemovedOffices(
      removedOffices.filter((removedOffice) => removedOffice.id !== office.id)
    );
  };

  const handleFilterOffice = (item: Office, searchWords: string[]) =>
    filterItemWithWhiteSpace({ searchWords, item, filterKeysArray: ['name'] });

  const handleDone = () => {
    const initialAddedOfficesIdsHash = keyBy(initialAddedOfficesIds);

    const newlyAddedOffices = addedOffices.filter(
      (region) => !initialAddedOfficesIdsHash[region.id]
    );

    const newlyRemovedOffices = removedOffices.filter(
      (region) => !!initialAddedOfficesIdsHash[region.id]
    );

    onDone({
      addedOffices: newlyAddedOffices,
      removedOffices: newlyRemovedOffices
    });

    resetLocalState();
  };

  const addedOfficesById = keyBy(addedOffices, byOfficeId);

  const getSortedOffices = useCallback(() => {
    const otherOffices = offices.filter(
      (office) => !addedOfficesById[office.id]
    );

    const sortedOffices = [...addedOffices, ...otherOffices];

    return officeFilter(sortedOffices);
  }, [addedOffices, addedOfficesById, officeFilter, offices]);

  const renderItem = ({ item }: { item: Office }) => {
    const isAdded = !!addedOfficesById[item.id];
    return (
      <ItemContainer $isAdded={isAdded}>
        <OfficeText {...defaultTooltipProps} data-tip={item.name}>
          {item.name}
        </OfficeText>
        <OfficeAddRemoveText $isAdded={isAdded}>
          {isAdded ? 'Remove' : 'Add'}
        </OfficeAddRemoveText>
      </ItemContainer>
    );
  };

  const renderHeaderButton = () => {
    return (
      <HeaderButtonContainer>
        <StyledDoneButton onClick={handleDone}>Done</StyledDoneButton>
      </HeaderButtonContainer>
    );
  };

  return isOpen ? (
    <MultiStepFlyout
      copy={copy}
      items={getSortedOffices()}
      idKey="id"
      renderItem={renderItem}
      target={target}
      hideFooter
      editDisabled
      itemHeight={48}
      listWidth={300}
      isWhite
      searchEnabled
      renderHeaderButton={renderHeaderButton}
      handleClose={onClose}
      itemFilter={handleFilterOffice}
      popoverClassName="bulk-office-selector"
      handleSelect={handleSelect}
    />
  ) : null;
};

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledDoneButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};

  &:hover {
    filter: brightness(95%);
  }
`;

const OfficeText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray2};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const OfficeAddRemoveText = styled.div<{ $isAdded: boolean }>`
  font-size: 12px;
  color: ${({ $isAdded, theme }) =>
    $isAdded ? theme.colors.colorCalendarRed : theme.colors.colorCalendarBlue};
`;

const ItemContainer = styled.div<{ $isAdded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  width: 100%;

  :not(:hover) {
    ${OfficeAddRemoveText} {
      ${(props) => (!props.$isAdded ? 'opacity: 0' : '')};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;
