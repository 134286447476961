import { select, all, call, put } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import * as actionCreators from 'actionCreators';
import { getAuthToken } from 'selectors';

export function* fetchProjectTotalsWorker(action) {
  const { startDate, endDate, projectIds, intervalAmount, intervalType } =
    action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    entityActions.fetchProjectTotals,
    api.fetchProjectTotals,
    undefined,
    [token, startDate, endDate, projectIds, intervalAmount, intervalType],
    action
  );
}
