import OptionsSubMenuRow from './OptionsSubMenuRow';
import TimeSetter from './TimeSetter';
import moment, { Moment } from 'moment';
import { UpdateHandler } from '../hooks/usePredict';
import ceil from 'lodash/ceil';
import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

interface OptionsMenuTimeSetterValueProps {
  startTime: string | null;
  endTime: string | null;
  dailyHours: string;
}

interface OptionsMenuTimeSetterSetterProps {
  onChangePredictableFields: UpdateHandler;
  canEditWorkplan: boolean;
}
interface OptionsSubMenuTimeSetterProps {
  optionsMenuTimeSetterValueProps: OptionsMenuTimeSetterValueProps;
  optionsMenuTimeSetterSetterProps: OptionsMenuTimeSetterSetterProps;
}

const OptionsSubMenuTimeSetter = ({
  optionsMenuTimeSetterValueProps,
  optionsMenuTimeSetterSetterProps
}: OptionsSubMenuTimeSetterProps) => {
  const { startTime, endTime, dailyHours } = optionsMenuTimeSetterValueProps;
  const { onChangePredictableFields, canEditWorkplan } =
    optionsMenuTimeSetterSetterProps;

  const getMomentTime = (timeString: string | null): Moment | null => {
    if (!timeString) return null;
    return moment(timeString.substring(0, 5), 'HH:mm A');
  };

  const momentStartTime = getMomentTime(startTime);
  const momentEndTime = getMomentTime(endTime);

  const formatTimeString = (timeString: string, momentTime: Moment): string => {
    const minutes = timeString.substring(3, 5);
    return momentTime.format(minutes === '00' ? 'ha' : 'h:mma');
  };

  const getSetTimeStatusText = (): string => {
    if (startTime && endTime && momentStartTime && momentEndTime)
      return `${formatTimeString(
        startTime,
        momentStartTime
      )} - ${formatTimeString(endTime, momentEndTime)}`;

    if (startTime && momentStartTime)
      return `Starts at ${formatTimeString(startTime, momentStartTime)}`;

    if (endTime && momentEndTime)
      return `Ends at ${formatTimeString(endTime, momentEndTime)}`;

    return '';
  };

  const handleSetStartTime = (newStartTime: Moment) => {
    onChangePredictableFields('start_time', {
      start_time: newStartTime ? newStartTime.format('HH:mm') : undefined,
      end_time: newStartTime
        ? newStartTime.clone().add(dailyHours, 'h').format('HH:mm')
        : undefined
    });
  };

  const handleSetEndTime = (newEndTime: Moment) => {
    const duration = momentStartTime
      ? moment.duration(momentStartTime.diff(newEndTime)).asHours() * -1
      : undefined;

    const hours = duration
      ? ceil(duration + (duration < 0 ? 24 : 0), 2).toString()
      : undefined;

    onChangePredictableFields('end_time', {
      start_time: momentStartTime ? momentStartTime.format('HH:mm') : undefined,
      end_time: newEndTime ? newEndTime.format('HH:mm') : undefined,
      ...(hours ? { daily_hours: hours } : {})
    });
  };

  const optionsSubMenuRowValueProps = {
    label: 'Set Time',
    status: getSetTimeStatusText(),
    subMenuExpandedComponent: (
      <TimeSetter
        startTimeSelect={handleSetStartTime}
        endTimeSelect={handleSetEndTime}
        startTime={momentStartTime}
        endTime={momentEndTime}
        dailyHours={dailyHours}
      />
    ),
    isDisabled: !canEditWorkplan,
    tooltipText: !canEditWorkplan ? EDIT_WORK_PLANS_TIP : undefined
  };

  return <OptionsSubMenuRow {...optionsSubMenuRowValueProps} />;
};

export default OptionsSubMenuTimeSetter;
