import { getIsDefaultRealm } from 'appCore/API/utils';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import { preferredAuthMethods, MFA_STATUS } from './constants';
import { getSelectedTeam } from 'TeamsModule/selectors';
import { AuthState } from './reducer';

export const getAuthState: Selector<RootState, AuthState> = (state) =>
  state.auth;

export const getCurrentAccount = createSelector(
  getAuthState,
  (auth) => auth.account
);

// getAuthToken is used in store creation time to try to get token from persisted state
// so auth object can be undefined if it is not persisted
// but old getAuthToken is being used in createSocketsMiddleware
export const getAuthToken = createSelector(
  getAuthState,
  (state) => state?.token
);

export const getIsImpersonating = createSelector(
  getAuthState,
  (state) => state.isImpersonating
);

// getRealmId is used in store creation time to try to get realmId from persisted state
// so auth object can be undefined if it is not persisted
export const getRealmId = createSelector(
  getAuthState,
  (auth) => auth?.realm?.id
);

export const getIsConnectingToDefaultRealm = createSelector(
  getRealmId,
  // if realm id is not defined by any chance, we can assume that it is using default realm
  (realmId) => (realmId ? getIsDefaultRealm(realmId) : true)
);
export const getTeamSamlInfo = createSelector(
  getAuthState,
  (state) => state.teamSamlInfo
);

export const getTeamAuthSettings = createSelector(
  getSelectedTeam,
  (selectedTeam) => selectedTeam?.team_auth_settings
);

export const getPreferredAuthMethod = createSelector(
  getTeamAuthSettings,
  (teamAuthSettings) => teamAuthSettings?.preferred_auth_method
);

export const getIsSamlEnabled = createSelector(
  getPreferredAuthMethod,
  (preferredAuthMethod) => preferredAuthMethod === preferredAuthMethods.saml
);

export const getTeamWideMfaSetting = createSelector(
  getTeamAuthSettings,
  (teamAuthSettings) => teamAuthSettings?.toggled_mfa
);

export const getIsMfaEnabledTeamWide = createSelector(
  getTeamWideMfaSetting,
  (teamWideMfaSetting) => teamWideMfaSetting !== MFA_STATUS.disabled
);

export const getIsAcceptedTerms = createSelector(
  getAuthState,
  (state) => state.isAcceptedTerms
);
