import React, { useMemo } from 'react';
import { RowItems, GroupRow, HelpersContext } from 'react-calendar-timeline';
import DroppableLayer from './DroppableLayer';
import { zoomToIntervalHash } from 'appUtils/projectPlannerUtils';
import { ZOOM_STRINGS } from 'appConstants/workload';
import useWorkloadDataProvider from 'appUtils/hooks/useWorkloadDataProvider';

import moment from 'moment';
const emptyArray = [];

const TimelineRowRenderer = (props) => {
  const {
    steps,
    getLayerRootProps,
    viewBy,
    group,
    zoom,
    holidayDatesHash = {}
  } = props;
  const { getLeftOffsetFromDate } = React.useContext(HelpersContext);

  const stepValue = zoomToIntervalHash[zoom];
  const monthColumnWidths = useMemo(
    () =>
      stepValue === ZOOM_STRINGS.MONTH
        ? steps.map((step) => {
            const momentObj = moment(step);
            return (
              getLeftOffsetFromDate(momentObj.endOf('month').valueOf()) -
              getLeftOffsetFromDate(momentObj.startOf('month').valueOf())
            );
          })
        : emptyArray,
    [getLeftOffsetFromDate, stepValue, steps]
  );

  const { formattingUtils, columnWidths } = useWorkloadDataProvider(
    zoom,
    steps,
    holidayDatesHash
  );

  return (
    <GroupRow>
      <RowItems />
      <DroppableLayer
        getLayerRootProps={getLayerRootProps}
        formattingUtils={formattingUtils}
        steps={steps}
        columnWidths={columnWidths}
        viewBy={viewBy}
        group={group}
        monthColumnWidths={monthColumnWidths}
        zoom={zoom}
      />
    </GroupRow>
  );
};

export default TimelineRowRenderer;
