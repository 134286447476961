import { Component, createRef } from 'react';
import { batch, connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import moment from 'moment';
import uniq from 'lodash/uniq';
import chunk from 'lodash/chunk';

import { BodyColor } from '..';
import WorkloadPlannerTimelineContainer from './WorkloadPlannerTimelineContainer';
import GlobalPhaseTemplateDropdown from 'BudgetModule/components/GlobalPhaseTemplateDropdown/GlobalPhaseTemplateDropdown';
import GlobalActivityDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/GlobalActivityDropdown';
import ActivityModal from './ActivityModal';
import WorkloadSettings from 'CapacityModule/components/LoadWorkloadContainer';
import AccountWorkloadSettingsModal from 'CapacityModule/components/WorkloadModal/AccountWorkloadSettingsModal';
import PhaseTemplateModal from 'BudgetModule/components/PhaseTemplateModal/PhaseTemplateModal';
import PhaseModal from 'BudgetModule/components/PhaseModal/PhaseModal';
import ActivityPhaseModal from 'BudgetModule/components/ActivityPhaseModal/ActivityPhaseModal';
import WorkloadAccessModal from 'components/Permissions/Access/WorkloadAccessModal';
import GlobalActivityDrawer from 'views/Global/GlobalActivityDrawer';
import {
  fetchWorkGroups,
  fetchWorkloadPlanner,
  fetchMemberProjects,
  openProjectPlannerModal,
  closeProjectPlannerModal,
  flushScheduleBars,
  fetchPhaseTemplates,
  fetchPhasesByProjectIds,
  fetchFilteredPhases,
  fetchPhaseTotalsByBoard,
  fetchAllProjects,
  fetchAccountFilter,
  fetchAccess,
  fetchActivities,
  setVisibleIndices,
  fetchWorkloadPlannerEventLastSent,
  fetchTasksV2,
  subscribeWorkload,
  removeWorkloadSubscriptions,
  fetchProjectTotals,
  fetchPredictionHours
} from 'actionCreators';
import {
  getTimelineMemberId,
  getCurrentUserId,
  getSelectedTeamId,
  getFilteredMembersIds,
  makeGetFilteredProjectsArray,
  getAllTeamMembers,
  getVisibleTimeStart,
  getVisibleTimeEnd,
  makeGetWorkloadRows,
  makeGetPlannerProjectMemberAccountIds,
  makeGetPlannerMemberBudetIds,
  getActiveWorkloadPlannerFilter,
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getPlannerSplitScreenType,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenType,
  getWorkloadSplitScreenAccountId,
  getMyWorkPlanSettings,
  getIsOnScheduleView,
  getPredictionHourProjectIds,
  getPlannerSplitScreenViewMemberIds,
  getZoom,
  getPlannerOpenProjects,
  getPlannerAccountIdsOnOpenProjects,
  getSplitScreenPositionRowIds,
  makeGetPlannerAccountIdsOnOpenMembers
} from 'selectors';
import {
  getIsOnPhaseSplitScreen,
  getIsOnScheduledTaskSplitScreen,
  getIsOnWorkloadSplitScreen
} from 'appCore/navigation/selectors';
import LoadUtilizationModule from 'UtilizationModule/components/LoadUtilizationModule';
import {
  fetchUtilizations,
  fetchMembersUtilizationReport
} from 'UtilizationModule/actionCreators';
import {
  fetchTeamCapacity,
  fetchCapacities,
  fetchHolidays
} from 'CapacityModule/actionCreators';
import { getTeamCapacityId } from 'CapacityModule/selectors';
import difference from 'lodash/difference';
import { buildAccessIdentifier } from 'appUtils/access';
import { GET_WORKLOAD_FETCH_END_DATE_MODIFIERS } from 'appUtils/projectPlannerUtils';
import {
  VIEW_BY,
  SPLIT_SCREEN_TYPES,
  ZOOM_LEVELS
} from 'appConstants/workload';
import WorkloadEventsModal from './WorkloadEventsModal/WorkloadEventsModal';
import { SORT_BY, SORT_ORDER, FILTER_VALUES } from 'appConstants/filters';
import {
  fetchMemberBudgets,
  fetchPositions,
  fetchTeamPositions
} from 'BudgetModule/actionCreators';
import { display } from 'views/projectPlanner/projectTimeline/Options/WorkloadSortOptions';
import { WorkPlanModal } from './WorkplanModal';
import { DateRangeManager } from './DateRangeManager';
import withFindPeopleModal from 'SuggestionModule/hocs/withFindPeopleModal';
import { getFetchPredictionHoursParams } from './utils';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import {
  WorkplanPermissionConsumer,
  WorkplanPermissionProvider
} from 'PermissionsModule/SpaceLevelPermissions/permissionProviders/workplan';
import { SuggestionsType } from 'SuggestionModule/constants';
import { withLeanApi } from 'LeanApiModule/utils/withLeanApi';
import isEqual from 'lodash/isEqual';
import {
  buildPlannerPageViewFilterFetchParams,
  isPlannerPageViewFilterActive
} from './PlannerPageViewFilter/utils';
import { MOMENT_ISO_DATE, MOMENT_USA_DATE } from 'appConstants/date';
import { withProjectRates } from 'RatesModule/hooks/withProjectRates';
import { withTeamRates } from 'RatesModule/hooks/withTeamRates';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { getEntityOptionsByEntityTypeHash } from 'EntityOptionsModule/selectors';
import xor from 'lodash/xor';

const FETCH_CHUNK_SIZE = 30;
const formatFetchDate = (date, formatString = MOMENT_USA_DATE) =>
  moment(date).clone().startOf('day').format(formatString);

const FETCH_OFFSET_DAYS = 14;
class WorkloadPlannerContainer extends Component {
  constructor(props) {
    super(props);
    this.dateRangeManagerByProjectIdRef = createRef();
    this.dateRangeManagerByProjectIdRef.current = {};

    this.dateRangeManagerByMemberIdRef = createRef();
    this.dateRangeManagerByMemberIdRef.current = {};

    this.fetchedCapacityAccountIds = createRef();
    this.fetchedCapacityAccountIds.current = new Set();

    // temporary quick fix for infinite loop on componentDidUpdate
    // TODO: remove this when we have a better solution
    this.fetchedUnloadedProjectIds = createRef();
    this.fetchedUnloadedProjectIds.current = new Set();
    this.fetchedUnloadedPhaseProjectIds = createRef();
    this.fetchedUnloadedPhaseProjectIds.current = new Set();
    this.fetchedUnloadedTaskProjectIds = createRef();
    this.fetchedUnloadedTaskProjectIds.current = new Set();
    this.fetchedUnloadedBudgetProjectIds = createRef();
    this.fetchedUnloadedBudgetProjectIds.current = new Set();
    this.fetchedUnloadedMemberBudgetProjectIds = createRef();
    this.fetchedUnloadedMemberBudgetProjectIds.current = new Set();
  }

  state = {
    earliestDate: moment(8.64e15).format(MOMENT_USA_DATE), // Maximum possible date
    latestDate: moment(0).format(MOMENT_USA_DATE), // Minimum possible date
    inViewIds: {},
    requestsModalIsOpen: false,
    fetchedInitialUtilizations: false
  };

  getPermissions = () => {
    const { teamId } = this.props;
    return {
      teamId
    };
  };

  getProjectRows = () => {
    const { viewBy, plannerRows } = this.props;
    if (viewBy !== VIEW_BY.PROJECTS) {
      return [];
    }

    return plannerRows.filter((row) => {
      const { project_id: projectId, isRoot, isEmptyBottomRow } = row;
      return projectId !== undefined && isRoot && !isEmptyBottomRow;
    });
  };

  getProjectIds = () => this.getProjectRows().map((row) => row.project_id);

  getTaskFetchProjectIds = (additionalProjectIds = []) => {
    const { splitScreenProjectId, viewBy, plannerOpenProjects } = this.props;

    const openProjectIds = Object.keys(plannerOpenProjects).filter(
      (id) => plannerOpenProjects[id]
    );

    const taskProjectIdsToFetch =
      splitScreenProjectId && viewBy === VIEW_BY.PROJECTS
        ? [splitScreenProjectId]
        : uniq([...additionalProjectIds, ...openProjectIds]);

    return this.getSlicedProjectIdsToLoad(
      this.fetchedUnloadedTaskProjectIds,
      taskProjectIdsToFetch
    );
  };

  handleInitialFetches = () => {
    const {
      teamId,
      fetchPhaseTemplates,
      fetchTeamCapacity,
      fetchAccess,
      fetchPositions,
      fetchTeamPositions,
      fetchActivities,
      fetchMemberProjects,
      fetchWorkloadPlannerEventLastSent,
      fetchWorkGroups
    } = this.props;
    fetchAccess({
      actableId: teamId,
      actableType: 'Team',
      actionType: 'activity_phase_schedule_bars_other'
    });
    fetchPositions({ teamId });
    fetchTeamPositions({ teamId });
    fetchTeamCapacity({ teamId });
    fetchPhaseTemplates({ teamId });
    fetchWorkGroups({ teamId, permissions: { teamId } });
    fetchActivities({});
    fetchMemberProjects({});
    fetchWorkloadPlannerEventLastSent({ teamId });
  };

  /**
   * return startDate and endDate with extra time frames based on zoom
   * @returns {{startDate, endDate}} startDate and endDate are moment instance
   */
  getVisibleDateRangeToFetch = () => {
    const { visibleTimeStart, visibleTimeEnd, activeFilter, zoom } = this.props;
    const startDate = moment(visibleTimeStart).clone();
    const endDate = moment(visibleTimeEnd).clone();

    const zoomToUse = activeFilter?.custom?.zoom || zoom;

    if (zoomToUse !== ZOOM_LEVELS.YEAR || zoomToUse === ZOOM_LEVELS.QUARTER) {
      startDate.subtract(2, 'weeks');
      endDate.add(2, 'weeks');
    }

    return { startDate, endDate };
  };

  /**
   * handle lazy loading for planner space
   */
  handleProjectWorkplanFetches = ({ projectIds, startDate, endDate }) => {
    const { myId, fetchWorkloadPlanner } = this.props;

    if (projectIds.length) {
      const isoFormatStartDate = formatFetchDate(startDate, MOMENT_ISO_DATE);
      const isoFormatEndDate = formatFetchDate(endDate, MOMENT_ISO_DATE);
      const usFormatStartDate = formatFetchDate(startDate, MOMENT_USA_DATE);
      const usFormatEndDate = formatFetchDate(endDate, MOMENT_USA_DATE);

      const projectIdsToFetch = [];

      projectIds.forEach((projectId) => {
        const dateRangeManager =
          this.dateRangeManagerByProjectIdRef.current[projectId];

        const dateRange = {
          start: isoFormatStartDate,
          end: isoFormatEndDate
        };

        // initialize new DateRangeManager
        if (!dateRangeManager) {
          this.dateRangeManagerByProjectIdRef.current[projectId] =
            new DateRangeManager(dateRange);
          projectIdsToFetch.push(projectId);
          return;
        }

        // if the date range is out of ranges in manager
        // add the date range to the manager
        // and add the project id to the list of project ids to fetch
        if (!dateRangeManager.isWithinRange(dateRange)) {
          dateRangeManager.addRanges(dateRange);
          projectIdsToFetch.push(projectId);
        }
      });

      const permissions = this.getPermissions();

      if (projectIdsToFetch.length) {
        fetchWorkloadPlanner({
          startDate: usFormatStartDate,
          endDate: usFormatEndDate,
          accountId: myId,
          project_ids: projectIdsToFetch,
          permissions,
          all: true
        });
      }
    }
  };

  handleMemberWorkplanFetches = ({ accountIds, startDate, endDate }) => {
    const { fetchWorkloadPlanner, myId } = this.props;

    if (accountIds.length) {
      const isoFormatStartDate = formatFetchDate(startDate, MOMENT_ISO_DATE);
      const isoFormatEndDate = formatFetchDate(endDate, MOMENT_ISO_DATE);

      const accountIdsToFetch = [];

      accountIds.forEach((accountId) => {
        const dateRangeManager =
          this.dateRangeManagerByProjectIdRef.current[accountId];

        const dateRange = {
          start: isoFormatStartDate,
          end: isoFormatEndDate
        };

        if (!dateRangeManager) {
          this.dateRangeManagerByProjectIdRef.current[accountId] =
            new DateRangeManager(dateRange);
          accountIdsToFetch.push(accountId);
          return;
        }

        if (!dateRangeManager.isWithinRange(dateRange)) {
          dateRangeManager.addRanges(dateRange);
          accountIdsToFetch.push(accountId);
        }
      });

      if (accountIdsToFetch.length) {
        const permissions = this.getPermissions();
        const usFormatStartDate = formatFetchDate(startDate, MOMENT_USA_DATE);
        const usFormatEndDate = formatFetchDate(endDate, MOMENT_USA_DATE);
        // skip fetches on planner split screen
        fetchWorkloadPlanner({
          startDate: usFormatStartDate,
          endDate: usFormatEndDate,
          accountId: myId,
          team_member_ids: accountIds,
          permissions,
          all: true
        });
      }
    }
  };

  handleMemberCapacityFetches = ({ accountIds }) => {
    const { teamId, fetchCapacities } = this.props;
    if (!teamId) return;
    if (!accountIds.length) return;

    const accountIdsToUse = accountIds.filter(
      (id) => !this.fetchedCapacityAccountIds.current.has(id)
    );

    this.fetchedCapacityAccountIds.current.add(...accountIdsToUse);

    if (accountIdsToUse.length) {
      batch(() =>
        chunk(accountIdsToUse, FETCH_CHUNK_SIZE).map((chunkIds) => {
          fetchCapacities({
            accountIds: chunkIds,
            teamId
          });
        })
      );
    }
  };

  /**
   * fetch member specific data
   */
  handleMemberUtilizationFetches = ({
    accountIds,
    startDate,
    endDate,
    includeTentative
  }) => {
    const { teamId, fetchMembersUtilizationReport, fetchUtilizations } =
      this.props;

    if (accountIds.length) {
      const usFormatStartDate = formatFetchDate(startDate, MOMENT_USA_DATE);
      const usFormatEndDate = formatFetchDate(endDate, MOMENT_USA_DATE);

      fetchMembersUtilizationReport({
        account_ids: accountIds,
        filterStateId: 'workload',
        team_id: teamId,
        show_status_totals: true,
        interval_type: 'days',
        interval_amount: 1,
        start_date: moment.min(moment(), startDate).format(MOMENT_USA_DATE),
        end_date: moment.min(moment(), endDate).format(MOMENT_USA_DATE),
        isLazyLoad: true
      });

      fetchUtilizations({
        startDate: usFormatStartDate,
        endDate: usFormatEndDate,
        accountIds: accountIds,
        teamId,
        includeTentative: includeTentative
      });
    }
  };

  handleWorkloadSplitScreenFetch = ({ accountId, startDate, endDate }) => {
    const {
      workloadSplitScreenType,
      fetchFilteredPhases,
      fetchTasksV2,
      fetchWorkloadPlanner,
      splitScreenPositionRowIds
    } = this.props;
    const permissions = this.getPermissions();
    switch (workloadSplitScreenType) {
      case SPLIT_SCREEN_TYPES.PHASE:
        fetchFilteredPhases({
          accountIds: [accountId],
          startDateFrom: formatFetchDate(startDate),
          startDateTo: formatFetchDate(endDate)
        });
        break;
      case SPLIT_SCREEN_TYPES.SCHEDULED_TASK:
        fetchTasksV2({
          body: {
            assignee_ids: [accountId],
            schedule_start_start_date: formatFetchDate(startDate),
            schedule_start_end_date: formatFetchDate(endDate),
            all: true
          }
        });
        break;
      case SPLIT_SCREEN_TYPES.WORK_PLAN:
        if (splitScreenPositionRowIds.length > 0) {
          fetchWorkloadPlanner({
            startDate: formatFetchDate(startDate),
            endDate: formatFetchDate(endDate),
            permissions,
            position_ids: splitScreenPositionRowIds,
            all: true,
            shouldFetchMemberBudgets: true
          });
        }
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    const {
      teamId,
      workloadSettings,
      activeFilter,
      splitScreenAccountId,
      isOnWorkloadSplitScreen,
      filteredMembersIds,
      plannerSplitScreenViewMemberIds,
      plannerAccountIdsOnOpenMembers,
      plannerAccountIdsOnOpenProjects,
      viewBy,
      workloadSplitScreenType,
      workloadSplitScreenActive,
      fetchPredictionHours,
      fetchTeamPositions,
      plannerOpenProjects,
      projectRates,
      fetchEntityOptions,
      entityOptionsByEntityTypeHash,
      teamRates,
      teamMembers
    } = this.props;
    this.subscribeWorkload();
    if (activeFilter) {
      this.loadAccountFilter(activeFilter);
    }
    const isActiveFilterLoaded = activeFilter?.id && activeFilter?.id !== 'new';

    const fetchRange = this.getVisibleDateRangeToFetch();

    if (teamId) {
      this.handleInitialFetches();

      // fetch data only if activeFilter is loaded already
      if (isActiveFilterLoaded) {
        /**
         * on workload screen
         */
        if (viewBy === VIEW_BY.MEMBERS && filteredMembersIds.length) {
          this.handleMemberCapacityFetches({
            accountIds: filteredMembersIds
          });
          fetchPredictionHours({
            account_ids: filteredMembersIds,
            suggestion_type: SuggestionsType.Phase_Account,
            ...getFetchPredictionHoursParams({
              zoom: activeFilter?.custom?.zoom
            })
          });
          this.handleMemberWorkplanFetches({
            accountIds: plannerAccountIdsOnOpenMembers,
            ...fetchRange
          });
          this.handleMemberUtilizationFetches({
            accountIds: filteredMembersIds,
            ...fetchRange,
            includeTentative: workloadSettings.show_tentative_plans
          });

          if (teamMembers.length) {
            teamRates.fetchTeamMemberBillRates();
          }
        }

        /**
         * on planner split screen
         */
        if (plannerSplitScreenViewMemberIds.length) {
          this.handleMemberUtilizationFetches({
            accountIds: plannerSplitScreenViewMemberIds,
            ...fetchRange
            // TODO: add plannerSettings.show_tentative_plans
          });
          this.handleMemberCapacityFetches({
            accountIds: plannerSplitScreenViewMemberIds
          });
        }

        /**
         * on planner
         */
        if (plannerAccountIdsOnOpenProjects.length) {
          this.handleMemberCapacityFetches({
            accountIds: plannerAccountIdsOnOpenProjects
          });
        }
        if (viewBy === VIEW_BY.PROJECTS) {
          const openProjectIds = Object.keys(plannerOpenProjects).filter(
            (id) => plannerOpenProjects[id]
          );

          openProjectIds.forEach((projectId) => {
            projectRates.fetchProjectMemberBillRates(projectId);

            // Get the currency information.
            const areEntityOptionsLoaded =
              !!entityOptionsByEntityTypeHash[ENTITY_TYPES.project]?.[
                projectId
              ];
            if (!areEntityOptionsLoaded)
              fetchEntityOptions({
                entity_id: projectId,
                entity_type: ENTITY_TYPES.project,
                team_id: teamId
              });
          });
        }

        if (workloadSplitScreenActive) {
          if (workloadSplitScreenType === SPLIT_SCREEN_TYPES.WORK_PLAN) {
            fetchTeamPositions({
              teamId,
              accountId: splitScreenAccountId,
              isActive: false
            });
          }
          if (isOnWorkloadSplitScreen) {
            this.handleWorkloadSplitScreenFetch({
              accountIds: splitScreenAccountId,
              ...fetchRange
            });
          }
        }
      }
    }

    /**
     * on workload split screen
     */
    if (isOnWorkloadSplitScreen) {
      this.handleWorkloadSplitScreenFetch({
        accountIds: splitScreenAccountId,
        ...fetchRange
      });
    }
  }

  componentWillUnmount() {
    const { closeProjectPlannerModal, removeWorkloadSubscriptions, myId } =
      this.props;
    closeProjectPlannerModal();
    removeWorkloadSubscriptions({ ids: [myId] });
  }

  componentDidUpdate(prevProps) {
    const {
      teamId,
      capacityId,
      myId,
      plannerSplitScreenViewMemberIds,
      plannerSplitScreenActive,
      fetchHolidays,
      projects,
      scheduleBars,
      fetchPhasesByProjectIds,
      fetchPhaseTotalsByBoard,
      fetchAllProjects,
      fetchedPhasesProjectIds,
      fetchedTotalsProjectIds,
      fetchedProjectIds,
      viewBy,
      setVisibleIndices,
      plannerRows,
      plannerOpenProjects,
      plannerAccountIdsOnOpenProjects,
      isFetchingProjects,
      isFetchingPhases,
      activeFilter,
      fetchTasksV2,
      splitScreenAccountId,
      fetchMemberBudgets,
      fetchedMemberBudgetProjectIds,
      fetchProjectTotals,
      predictionHourProjectIds,
      fetchTeamPositions,
      isOnWorkloadSplitScreen,
      workloadSplitScreenType,
      workloadSettings,
      fetchUtilizations,
      filteredMembersIds,
      workloadSplitScreenActive,
      splitScreenPositionRowIds,
      fetchPredictionHours,
      plannerAccountIdsOnOpenMembers,
      leanApi,
      pageViewFilter,
      projectRates,
      teamRates,
      entityOptionsByEntityTypeHash,
      teamMembers
    } = this.props;

    if (myId && !prevProps.my_id) {
      this.subscribeWorkload();
    }

    if (viewBy !== prevProps.viewBy) {
      setVisibleIndices({ visibleIndices: [0, 0] });
      this.setState({ inViewIds: {} });
    }

    if (prevProps.activeFilter?.id !== activeFilter?.id) {
      this.loadAccountFilter(activeFilter);
    }

    if (!prevProps.capacityId && capacityId) {
      fetchHolidays();
    }

    if (!prevProps.teamId && teamId) {
      this.handleInitialFetches();
      if (
        !workloadSplitScreenActive ||
        workloadSplitScreenType !== SPLIT_SCREEN_TYPES.WORK_PLAN
      ) {
        fetchTeamPositions({
          teamId
        });
      }
    }

    const fetchRange = this.getVisibleDateRangeToFetch();

    /**
     * projects are changed on Planner space
     */
    if (
      viewBy === VIEW_BY.PROJECTS &&
      prevProps.plannerOpenProjects !== plannerOpenProjects
    ) {
      const openProjectIds = Object.keys(plannerOpenProjects).filter(
        (id) => plannerOpenProjects[id]
      );
      const prevOpenProjectIds = Object.keys(
        prevProps.plannerOpenProjects
      ).filter((id) => plannerOpenProjects[id]);

      const newProjectIds = difference(openProjectIds, prevOpenProjectIds);

      if (newProjectIds.length) {
        this.handleProjectWorkplanFetches({
          projectIds: newProjectIds,
          ...fetchRange
        });

        newProjectIds.forEach((projectId) => {
          projectRates.fetchProjectMemberBillRates(projectId);

          // Get the currency information.
          const areEntityOptionsLoaded =
            !!entityOptionsByEntityTypeHash[ENTITY_TYPES.project]?.[projectId];
          if (!areEntityOptionsLoaded)
            fetchEntityOptions({
              entity_id: projectId,
              entity_type: ENTITY_TYPES.project,
              team_id: teamId
            });
        });
      }
    }

    if (
      viewBy === VIEW_BY.PROJECTS &&
      prevProps.plannerAccountIdsOnOpenProjects !==
        plannerAccountIdsOnOpenProjects
    ) {
      this.handleMemberCapacityFetches({
        accountIds: plannerAccountIdsOnOpenProjects
      });
    }
    /**
     * members are changed on Workload space
     */
    if (teamId && viewBy === VIEW_BY.MEMBERS) {
      // Fetch new prediction hours on zoom change
      // One of the zoom level has value = 0
      if (
        activeFilter?.custom?.zoom !== undefined &&
        prevProps.activeFilter?.custom?.zoom !== activeFilter?.custom?.zoom
      ) {
        fetchPredictionHours({
          account_ids: filteredMembersIds,
          suggestion_type: SuggestionsType.Phase_Account,
          ...getFetchPredictionHoursParams({
            zoom: activeFilter?.custom?.zoom
          })
        });
      }

      if (prevProps.filteredMembersIds !== filteredMembersIds) {
        const newAccountIds = difference(
          filteredMembersIds,
          prevProps.filteredMembersIds
        );
        if (newAccountIds.length) {
          this.handleMemberCapacityFetches({
            accountIds: newAccountIds
          });
          fetchPredictionHours({
            account_ids: newAccountIds,
            suggestion_type: SuggestionsType.Phase_Account,
            ...getFetchPredictionHoursParams({
              zoom: activeFilter?.custom?.zoom
            })
          });
          this.handleMemberUtilizationFetches({
            accountIds: newAccountIds,
            ...fetchRange,
            includeTentative: workloadSettings.show_tentative_plans
          });
        }

        const prevTeamMemberIds = prevProps.teamMembers.map(({ id }) => id);
        const teamMemberIds = teamMembers.map(({ id }) => id);
        const isTeamMemberIdsChanged =
          xor(prevTeamMemberIds, teamMemberIds).length > 0;
        if (isTeamMemberIdsChanged) {
          teamRates.fetchTeamMemberBillRates();
        }
      }

      if (
        prevProps.plannerAccountIdsOnOpenMembers !==
        plannerAccountIdsOnOpenMembers
      ) {
        if (plannerAccountIdsOnOpenMembers.length) {
          this.handleMemberWorkplanFetches({
            accountIds: plannerAccountIdsOnOpenMembers,
            ...fetchRange
          });
        }
      }
    }

    /**
     * on planner split screen
     */
    if (
      prevProps.plannerSplitScreenActive !== plannerSplitScreenActive &&
      plannerSplitScreenActive
    ) {
      if (plannerSplitScreenViewMemberIds.length) {
        this.handleMemberWorkplanFetches({
          accountIds: plannerSplitScreenViewMemberIds,
          ...fetchRange
        });
        this.handleMemberUtilizationFetches({
          accountIds: plannerSplitScreenViewMemberIds,
          ...fetchRange
          // TODO: add plannerSettings.show_tentative_plans
        });
        this.handleMemberCapacityFetches({
          accountIds: plannerSplitScreenViewMemberIds
        });
      }
    }

    if (
      workloadSettings &&
      workloadSettings.show_tentative_plans !==
        prevProps.workloadSettings?.show_tentative_plans
    ) {
      fetchUtilizations({
        accountIds: filteredMembersIds,
        teamId,
        startDate: formatFetchDate(fetchRange.startDate),
        endDate: formatFetchDate(fetchRange.endDate),
        includeTentative: workloadSettings.show_tentative_plans ?? true
      });
    }

    if (
      viewBy === VIEW_BY.PROJECTS &&
      this.shouldRenderRow(plannerRows.length) &&
      activeFilter &&
      !activeFilter.project_ids?.length &&
      !isFetchingProjects
    ) {
      // uncomment if/when 0 projects selected === all projects selected
      // fetchAllProjects({ offset, limit: 15 });
    } else if (
      prevProps.activeFilter?.project_ids !== activeFilter?.project_ids ||
      prevProps.projects !== projects ||
      prevProps.scheduleBars !== scheduleBars ||
      prevProps.fetchedPhasesProjectIds !== fetchedPhasesProjectIds ||
      plannerRows !== prevProps.plannerRows ||
      splitScreenAccountId !== prevProps.splitScreenAccountId ||
      predictionHourProjectIds !== prevProps.predictionHourProjectIds
    ) {
      const projectIds = [
        ...(activeFilter?.project_ids || []),
        ...projects.map((project) => project?.id),
        ...(this.getProjectIds() || []),
        ...predictionHourProjectIds
      ].filter((id) => !`${id}`.includes('empty'));

      const scheduleBarProjectIds = Array.from(
        new Set(Object.values(scheduleBars).map((bar) => bar.project_id))
      ).filter((id) => id);
      const unloadedProjectProjectIds = projectIds.filter(
        (projectId) => !fetchedProjectIds[projectId]
      );
      const unloadedBarProjectIds = scheduleBarProjectIds.filter(
        (projectId) => !fetchedProjectIds[projectId]
      );
      const unloadedProjectAndBarProjectIds = uniq([
        ...unloadedProjectProjectIds,
        ...unloadedBarProjectIds
      ]);
      const unloadedBudgetProjectIds =
        viewBy === VIEW_BY.PROJECTS
          ? projectIds.filter(
              (projectId) => !fetchedTotalsProjectIds[projectId]
            )
          : [];
      const unloadedBarPhaseProjectIds = scheduleBarProjectIds.filter(
        (projectId) => !fetchedPhasesProjectIds[projectId]
      );
      const unloadedPhaseProjectIds = Array.from(
        new Set([...unloadedBudgetProjectIds, ...unloadedBarPhaseProjectIds])
      );
      const unloadedMemberBudgetProjectIds = Array.from(
        new Set(projectIds.filter((id) => !fetchedMemberBudgetProjectIds[id]))
      );

      if (unloadedProjectAndBarProjectIds.length) {
        const projectIdsToLoad = this.getSlicedProjectIdsToLoad(
          this.fetchedUnloadedProjectIds,
          unloadedProjectAndBarProjectIds
        );

        if (projectIdsToLoad.length) {
          fetchAllProjects({
            projectIds: projectIdsToLoad
          });
        }
      }
      if (!isFetchingPhases) {
        if (unloadedPhaseProjectIds.length) {
          const projectIdsToLoad = this.getSlicedProjectIdsToLoad(
            this.fetchedUnloadedPhaseProjectIds,
            unloadedPhaseProjectIds
          );
          if (projectIdsToLoad.length) {
            fetchPhasesByProjectIds({
              projectIds: projectIdsToLoad
            });
          }
          if (!isOnWorkloadSplitScreen) {
            const taskFetchProjectIds = this.getTaskFetchProjectIds();
            if (taskFetchProjectIds.length) {
              fetchTasksV2({
                body: {
                  project_ids: this.getTaskFetchProjectIds(),
                  schedule_start_start_date: formatFetchDate(
                    fetchRange.startDate
                  ),
                  schedule_start_end_date: formatFetchDate(fetchRange.endDate),
                  all: true
                }
              });
            }
          }
        }
        if (unloadedBudgetProjectIds.length) {
          const projectIdsToLoad = this.getSlicedProjectIdsToLoad(
            this.fetchedUnloadedBudgetProjectIds,
            unloadedBudgetProjectIds
          );

          if (projectIdsToLoad.length) {
            fetchPhaseTotalsByBoard({
              projectIds: projectIdsToLoad
            });
            fetchProjectTotals({
              projectIds: projectIdsToLoad,
              startDate: formatFetchDate(fetchRange.startDate, MOMENT_ISO_DATE),
              endDate: formatFetchDate(fetchRange.endDate, MOMENT_ISO_DATE),
              intervalAmount: '1',
              intervalType: 'days',
              isFuture: true
            });
          }
        }
        if (unloadedMemberBudgetProjectIds.length) {
          const projectIdsToLoad = this.getSlicedProjectIdsToLoad(
            this.fetchedUnloadedMemberBudgetProjectIds,
            unloadedMemberBudgetProjectIds
          );

          if (projectIdsToLoad.length) {
            fetchMemberBudgets({
              projectIds: projectIdsToLoad,
              permissions: { teamId }
            });
          }
        }
        if (
          (splitScreenAccountId !== prevProps.splitScreenAccountId ||
            workloadSplitScreenType !== prevProps.workloadSplitScreenType ||
            splitScreenPositionRowIds?.length !==
              prevProps.splitScreenPositionRowIds?.length) &&
          workloadSplitScreenActive &&
          splitScreenAccountId &&
          workloadSplitScreenType
        ) {
          this.handleWorkloadSplitScreenFetch({
            accountIds: splitScreenAccountId,
            ...fetchRange
          });
          if (workloadSplitScreenType === SPLIT_SCREEN_TYPES.WORK_PLAN) {
            fetchTeamPositions({
              teamId,
              accountId: splitScreenAccountId,
              isActive: false
            });
          }
        }
      }
    }

    // Update the page view filter results if the filter has changed.
    const prevPageViewFilter = prevProps.pageViewFilter;
    if (
      viewBy === VIEW_BY.PROJECTS &&
      activeFilter &&
      !isEqual(pageViewFilter, prevPageViewFilter) &&
      isPlannerPageViewFilterActive(pageViewFilter)
    ) {
      const params = buildPlannerPageViewFilterFetchParams(pageViewFilter);
      if (params) leanApi.debouncedFetchLeanApi(params);
    }
  }

  getSlicedProjectIdsToLoad = (ref, projectIds) => {
    const projectIdsToLoad = projectIds
      .filter((id) => !!id && !ref.current.has(id))
      .slice(0, 15);

    for (const projectId of projectIdsToLoad) {
      ref.current.add(projectId);
    }

    return projectIdsToLoad;
  };

  subscribeWorkload = () => {
    const { myId, subscribeWorkload } = this.props;
    if (myId) {
      subscribeWorkload({ userId: myId });
    }
  };

  handleZoomChange = ({ visibleTimeStart, visibleTimeEnd }) => {
    const { activeFilter, plannerProjectMemberAccountIds, workloadSettings } =
      this.props;
    if (
      activeFilter?.custom?.zoom !== undefined &&
      !this.state.fetchedInitialUtilizations
    ) {
      // this is workaround for fixing initial load issue
      // when activeFilter is not set yet and zoom is set to default value
      // it will update zoom to activeFilter.custom.zoom once activeFilter is loaded
      // but when it fetches data, visibleTimeStart and visibleTimeEnd are not updated yet
      // so it will fetch data with current visibleTimeStart and visibleTimeEnd
      this.setState({ fetchedInitialUtilizations: true });
      this.handleMemberUtilizationFetches({
        accountIds: plannerProjectMemberAccountIds,
        startDate: visibleTimeStart,
        endDate: visibleTimeEnd,
        includeTentative: workloadSettings?.show_tentative_plans
      });
    }
  };

  handleLazyLoad = (calendarTimeStart, calendarTimeEnd) => {
    const { earliestDate, latestDate } = this.state;
    const isEarliestChanged = moment(calendarTimeStart).isBefore(
      moment(earliestDate)
    );
    const isLatestChanged = moment(latestDate).isBefore(
      moment(calendarTimeEnd)
    );

    if (isEarliestChanged && isLatestChanged) {
      const startDate = moment(calendarTimeStart).subtract(
        FETCH_OFFSET_DAYS,
        'days'
      );
      const endDate = moment(calendarTimeEnd).add(FETCH_OFFSET_DAYS, 'days');

      this.lazyLoad({ startDate, endDate });
      this.setState({
        earliestDate: formatFetchDate(startDate),
        latestDate: formatFetchDate(endDate)
      });
      return;
    }

    if (isEarliestChanged) {
      // set minimum offset past days as FETCH_OFFSET_DAYS
      const startDate = moment.min(
        moment(calendarTimeStart),
        moment(earliestDate).subtract(FETCH_OFFSET_DAYS, 'days')
      );
      const endDate = moment(earliestDate);

      this.lazyLoad({ startDate, endDate });
      this.setState({
        earliestDate: formatFetchDate(startDate)
      });
      return;
    }

    if (isLatestChanged) {
      const startDate = moment(latestDate);
      // set minimum offset future days as FETCH_OFFSET_DAYS
      const endDate = moment.max(
        moment(calendarTimeEnd),
        moment(latestDate).add(FETCH_OFFSET_DAYS, 'days')
      );

      this.lazyLoad({ startDate, endDate });
      this.setState({
        latestDate: formatFetchDate(endDate)
      });
    }
  };

  lazyLoad = ({ startDate, endDate }) => {
    const {
      fetchProjectTotals,
      viewBy,
      plannerSplitScreenActive,
      plannerSplitScreenViewMemberIds,
      isOnWorkloadSplitScreen,
      splitScreenAccountId,
      filteredMembersIds,
      workloadSettings,
      plannerOpenProjects,
      plannerAccountIdsOnOpenMembers
    } = this.props;
    const membersToFetch = filteredMembersIds;

    const isOnWorkload = viewBy === VIEW_BY.MEMBERS;
    const isOnPlanner = viewBy === VIEW_BY.PROJECTS;

    if (isOnWorkload) {
      if (membersToFetch.length) {
        this.handleMemberUtilizationFetches({
          accountIds: membersToFetch,
          startDate,
          endDate,
          includeTentative: workloadSettings.show_tentative_plans
        });
      }
      if (plannerAccountIdsOnOpenMembers.length) {
        this.handleMemberWorkplanFetches({
          accountIds: plannerAccountIdsOnOpenMembers,
          startDate,
          endDate
        });
      }
    }

    if (plannerSplitScreenActive && plannerSplitScreenViewMemberIds.length) {
      this.handleMemberUtilizationFetches({
        accountIds: plannerSplitScreenViewMemberIds,
        startDate,
        endDate
        // TODO: add plannerSettings.show_tentative_plans
      });
    }

    if (isOnWorkloadSplitScreen) {
      this.handleWorkloadSplitScreenFetch({
        accountIds: splitScreenAccountId,
        startDate,
        endDate
      });
    }

    if (isOnPlanner) {
      const openProjectIds = Object.keys(plannerOpenProjects).filter(
        (id) => plannerOpenProjects[id]
      );

      if (openProjectIds.length) {
        this.handleProjectWorkplanFetches({
          projectIds: openProjectIds,
          startDate,
          endDate
        });
      }

      /**
       * fetch budget aggregates
       * mainly used for showing scheduled work plan bars when the project row is collapsed
       */
      const projectIdsToFetch = this.getProjectIds();
      if (projectIdsToFetch.length) {
        fetchProjectTotals({
          projectIds: projectIdsToFetch,
          // fetchProjectTotals requires ISO_DATE_FORMAT
          startDate: formatFetchDate(startDate, MOMENT_ISO_DATE),
          endDate: formatFetchDate(endDate, MOMENT_ISO_DATE),
          intervalAmount: '1',
          intervalType: 'days',
          isFuture: true
        });
      }
    }
  };

  loadAccountFilter = (activeFilter) => {
    if (activeFilter?.id && activeFilter.id !== 'new') {
      let sortAttribute;
      if (this.props.viewBy === VIEW_BY.MEMBERS) {
        if (activeFilter.custom?.sort) {
          sortAttribute = display.members.availability.options.some(
            (option) => option.value === activeFilter.custom.sort
          )
            ? this.props.workloadSettings?.display_member_capacity === 'percent'
              ? 'available_percentage'
              : 'available_hours'
            : activeFilter.custom.sort;
        } else {
          sortAttribute = SORT_BY.name;
        }
      } else if (this.props.viewBy === VIEW_BY.PROJECTS) {
        sortAttribute = activeFilter?.custom?.sort || SORT_BY.alphabetical;
      }

      this.props.fetchAccountFilter({
        id: activeFilter.id,
        body: {
          filter_sort_attributes: [
            {
              filter_value:
                this.props.viewBy === VIEW_BY.PROJECTS
                  ? FILTER_VALUES.project_ids
                  : FILTER_VALUES.account_ids,
              sort_attributes: [
                {
                  attribute: sortAttribute,
                  direction: SORT_ORDER.asc
                }
              ],
              start_date: moment().format('YYYY-MM-DD'),
              end_date: moment()
                .add(...GET_WORKLOAD_FETCH_END_DATE_MODIFIERS(this.props.zoom))
                .format('YYYY-MM-DD')
            }
          ]
        }
      });
    }
  };

  // leaving here in case we decide to do something similar again in the future
  // setInView = ({ id, index, inView }) => {
  //   const {
  //     visibleTimeStart,
  //     visibleTimeEnd,
  //     inViewIndices,
  //     plannerRows
  //   } = this.props;
  //   let { earliestDate, latestDate, inViewIds } = this.state;

  //   const [originalStart = 0, originalStop = 0] = inViewIndices;
  //   let start = originalStart;
  //   let stop = originalStop;

  //   if (inView) {
  //     if (index < start) {
  //       start = index;
  //     }
  //     if (index > stop) {
  //       stop = index;
  //     }
  //   } else {
  //     if (index <= start) {
  //       start = index + 1;
  //     } else if (index > originalStop && inViewIds[id]) {
  //       // only set to this row -1 if row was previously in view
  //       stop = index - 1;
  //     } else if (index < originalStop) {
  //       // only trim bottom if all nearby rows are also not in view.
  //       // Prevents case where unexpected rows leaving cause subsequent rows to fail to render
  //       if (
  //         plannerRows
  //           .slice(index, index + 10)
  //           .every(row => !inViewIndices[row.id])
  //       ) {
  //         stop = index;
  //       }
  //     }
  //   }
  //   if (start < originalStart || stop > originalStop) {
  //     // only reset dates for fetch window if range increases
  //     earliestDate = formatFetchDate(visibleTimeStart.clone().add(-14, 'days'));
  //     latestDate = formatFetchDate(visibleTimeEnd.clone().add(14, 'days'));
  //     this.setState({
  //       earliestDate,
  //       latestDate
  //     });
  //   }

  //   // always update inViewIds and visibleIndices
  //   this.setState(prevState => ({
  //     inViewIds: { ...prevState.inViewIds, [id]: inView }
  //   }));

  //   if (start !== originalStart || stop !== originalStop) {
  //     this.props.setVisibleIndices({ visibleIndices: [start, stop] });
  //   }
  // };

  shouldRenderRow = (index) => {
    const [, stop = 0] = this.props.inViewIndices;
    return index - stop <= 10;
  };

  render() {
    const {
      teamId,
      viewBy,
      pageName,
      visibleTimeStart,
      visibleTimeEnd,
      inViewIndices,
      activeFilter,
      workloadSettings,
      FindPeopleModal,
      handleOpenFindPeopleModal,
      leanApi,
      pageViewFilter,
      projectRates,
      teamRates
    } = this.props;

    return (
      <DynamicModuleLoader modules={[getBudgetModule()]}>
        <WorkplanPermissionProvider>
          <WorkplanPermissionConsumer>
            {(workplanPermissions) => (
              <>
                {teamId && (
                  <div className="project-planner-container">
                    <BodyColor isGray />

                    <WorkloadPlannerTimelineContainer
                      handleLazyLoad={this.handleLazyLoad}
                      viewBy={viewBy}
                      pageName={pageName}
                      plannerType="workload"
                      // setInView={this.setInView}
                      visibleTimeStart={visibleTimeStart}
                      visibleTimeEnd={visibleTimeEnd}
                      shouldRenderRow={this.shouldRenderRow}
                      inViewIndices={inViewIndices}
                      activeFilter={activeFilter}
                      filterId={'workload'}
                      workloadSettings={workloadSettings}
                      handleOpenFindPeopleModal={handleOpenFindPeopleModal}
                      onZoomChange={this.handleZoomChange}
                      workplanPermissions={workplanPermissions}
                      leanApi={leanApi}
                      pageViewFilter={pageViewFilter}
                      projectRates={projectRates}
                      teamRates={teamRates}
                    />
                  </div>
                )}
                <GlobalPhaseTemplateDropdown />
                <GlobalActivityDropdown />
                <PhaseTemplateModal />
                <PhaseModal />

                <ActivityPhaseModal />
                <ActivityModal />
                <WorkloadSettings />
                <AccountWorkloadSettingsModal />
                <WorkPlanModal />
                <LoadUtilizationModule />
                <WorkloadAccessModal
                  accessIdentifier={buildAccessIdentifier({
                    actableType: 'Team',
                    actableId: teamId,
                    actionType: 'activity_phase_schedule_bars_other'
                  })}
                />
                <WorkloadEventsModal />
                <GlobalActivityDrawer />
                <FindPeopleModal />
              </>
            )}
          </WorkplanPermissionConsumer>
        </WorkplanPermissionProvider>
      </DynamicModuleLoader>
    );
  }
}
const emptyObj = {};
const makeMapStateToProps = () => {
  const getPlannerAccountIdsOnOpenMembers =
    makeGetPlannerAccountIdsOnOpenMembers();
  const getFilteredProjectsArray = makeGetFilteredProjectsArray();
  const getWorkloadRows = makeGetWorkloadRows();
  const getPlannerProjectMemberAccountIds =
    makeGetPlannerProjectMemberAccountIds();
  const getPlannerMemberBudetIds = makeGetPlannerMemberBudetIds();

  const mapStateToProps = (state, ownProps) => ({
    projects: getFilteredProjectsArray(state, ownProps),
    scheduleBars: state.workloadPlanner.scheduleBars,
    scheduleBarProjects: state.workloadPlanner.projects,
    plannerLoading: state.workloadPlanner.isLoading,
    myId: getCurrentUserId(state),
    timelineMemberId: getTimelineMemberId(state, ownProps),
    teamId: getSelectedTeamId(state),
    filteredMembersIds: getFilteredMembersIds(state, ownProps),
    capacityId: getTeamCapacityId(state),
    fetchedPhasesProjectIds: state.phases.fetchedPhasesProjectIds,
    fetchedTotalsProjectIds: state.phases.fetchedTotalsProjectIds,
    fetchedProjectIds: state.projects.fetchedProjectIds,
    fetchedMemberBudgetProjectIds:
      state.memberBudgets?.fetchedMemberBudgetProjectIds ?? emptyObj,
    teamMembers: getAllTeamMembers(state),
    visibleTimeStart: getVisibleTimeStart(state, ownProps),
    visibleTimeEnd: getVisibleTimeEnd(state, ownProps),
    plannerRows: getWorkloadRows(state, ownProps),
    plannerOpenProjects: getPlannerOpenProjects(state),
    plannerAccountIdsOnOpenMembers: getPlannerAccountIdsOnOpenMembers(
      state,
      ownProps
    ),
    plannerProjectMemberAccountIds: getPlannerProjectMemberAccountIds(
      state,
      ownProps
    ),
    plannerMemberBudetIds: getPlannerMemberBudetIds(state, ownProps),
    inViewIndices: state.workloadPlanner.visibleIndices || [0, 0],
    isFetchingProjects: state.projects.isFetchingProjects,
    isFetchingPhases: state.phases.fetching,
    projectCount: state.home.totalAllProjects,
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps),
    offset: state.projects.offset,
    workloadSettings: getMyWorkPlanSettings(state),
    isOnScheduleView: getIsOnScheduleView(state),
    predictionHourProjectIds: getPredictionHourProjectIds(state),
    plannerSplitScreenActive: getPlannerSplitScreenActive(state),
    workloadSplitScreenActive: getWorkloadSplitScreenActive(state),
    splitScreenProjectId: getPlannerSplitScreenProjectId(state),
    zoom: getZoom(state, { plannerType: 'workload' }),
    plannerSplitScreenViewMemberIds: getPlannerSplitScreenViewMemberIds(state),
    splitScreenAccountId: getWorkloadSplitScreenAccountId(state),
    plannerSplitScreenType: getPlannerSplitScreenType(state),
    workloadSplitScreenType: getWorkloadSplitScreenType(state),
    isOnPhaseSplitScreen: getIsOnPhaseSplitScreen(state),
    isOnScheduledTaskSplitScreen: getIsOnScheduledTaskSplitScreen(state),
    isOnWorkloadSplitScreen: getIsOnWorkloadSplitScreen(state),
    plannerAccountIdsOnOpenProjects: getPlannerAccountIdsOnOpenProjects(state),
    splitScreenPositionRowIds: getSplitScreenPositionRowIds(state),
    entityOptionsByEntityTypeHash: getEntityOptionsByEntityTypeHash(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchWorkGroups,
  fetchWorkloadPlanner,
  fetchMemberProjects,
  fetchMemberBudgets,
  openProjectPlannerModal,
  closeProjectPlannerModal,
  fetchPhaseTemplates,
  fetchPhasesByProjectIds,
  fetchFilteredPhases,
  flushScheduleBars,
  fetchUtilizations,
  fetchTeamCapacity,
  fetchPhaseTotalsByBoard,
  fetchCapacities,
  fetchHolidays,
  fetchAllProjects,
  fetchAccountFilter,
  fetchAccess,
  fetchActivities,
  setVisibleIndices,
  fetchWorkloadPlannerEventLastSent,
  fetchTasksV2,
  fetchPositions,
  subscribeWorkload,
  removeWorkloadSubscriptions,
  fetchMembersUtilizationReport,
  fetchProjectTotals,
  fetchPredictionHours,
  fetchTeamPositions,
  fetchEntityOptions
};

const WorkloadPlannerContainerWithHocs = withTeamRates(
  withProjectRates(
    withLeanApi(
      withPermissionsCheck(
        withFindPeopleModal(
          connect(
            makeMapStateToProps,
            mapDispatchToProps
          )(WorkloadPlannerContainer)
        )
      )
    )
  )
);

export default WorkloadPlannerContainerWithHocs;
