import { Component } from 'react';
import theme from 'theme';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import MainMenuBoardList from './MainMenuBoardList';
import BoardDropdownIcon from './assets/BoardDropdownIcon';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import MosaicLogo from 'images/mosaic-logo-2021.svg';
import {
  useSearchState,
  saveSearchState,
  handleProjectItemState,
  onClearSearch,
  filterByMembers,
  clearSelectedProject,
  openAddEditGroupModal,
  changeBoardPosition,
  switchBoards,
  setModal,
  setActiveKey,
  toggleInviteForm,
  navigateToBoard,
  navigateToPersonalDashboard,
  navigateToSettings,
  navigateToTeamSettings,
  toggleSidebarProjects
  // setShowTimesheetMovedMessage,
  // setShowUpdatesMovedMessage
} from 'actionCreators';
import {
  getMe,
  getOnActivityFeed,
  getOnMembersView,
  getOnHomePlanner,
  getBillingCurrentModal,
  getOnTeamPlannerTasks,
  getRecentBoard,
  getIsOnOwnTimesheets,
  getOnBoardView,
  getUserIsAdmin,
  getGroups,
  getOnWorkloadView,
  getIsOnScheduleView,
  getIsOnReportsView,
  getIsOnDashboardView,
  getMyTeamMembership,
  getActiveTeam,
  getUserTheme,
  getAuthToken,
  getOnHomeView,
  getSearch,
  getIsTimesheetsDisabled,
  getMatchedRouteParams,
  getUserSlug
} from 'selectors';
import {
  Img,
  DarkImg,
  ImageContainer,
  SpaceDescription,
  TeamList,
  TeamInitials,
  TeamInitialsWrapper,
  StyledSettingsIcon,
  Initials,
  StyledSettingsIconContainer,
  TeamLink,
  BoardsLink,
  HorizontalLine,
  ReportLink,
  HomeLink,
  ProjectLink,
  MenuLabel,
  BoardCount,
  BoardsDropdownWrapper,
  MemberInitialsContainer,
  SettingsContainer,
  MemberInitialBorder,
  MembersContainer
} from './styledComponents';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import { calculateHighestTeamMembershipRoleLevel } from 'appUtils/roleDisplay';
import PermissionBasedRenderer from 'views/layoutStructuralComponents/PermissionBasedRenderer';
import LockIcon from 'icons/LockIcon';
import ScheduleMainIcon from 'images/schedule-main-icon.svg';
import ProjectsIcon from 'images/projects.svg';
import BoardsIcon from 'images/boards-no-member.svg';
import BoardsDarkIcon from 'images/boards-dark-no-member.svg';
import ReportIcon from 'icons/ReportIcon.svg';
import DashboardIcon from 'icons/DashboardIcon.svg';
import OrgIcon from 'images/org-icon.svg';

import ReactTooltip from 'react-tooltip';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import WorkloadLink from './Links/WorkloadLink';
import debounce from 'lodash/debounce';

const boardDropdownIconStyle = { marginLeft: '5px' };

const SideBarList = styled.div`
  height: auto;
  width: 100%;
  &:hover {
    ${Initials} {
      display: none;
    }
    ${StyledSettingsIcon} {
      display: flex;
    }
  }
`;

const SideBarBottomList = styled.div`
  width: 100%;
`;

const SideBarWrapper = styled.div`
  top: 0px;
  height: 100vh;
  width: ${(props) => (props.isExpanded ? '240px' : '60px')};
  z-index: 1001;
  position: fixed;
  background: ${theme.colors.colorDarkBlue2};
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media print {
    display: none;
  }
  .gu-transit {
    background: #324053 !important;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;

class SidebarMenu extends Component {
  state = {
    isTeamSettingsOpen: false,
    teamBoardMenuOpen: false,
    overlayOpen: false
  };

  componentDidMount() {
    window.Appcues && window.Appcues.page();
    window.Appcues && window.Appcues.identify(this.props.me.id);
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  toggleHelpOverlay = () => {
    this.setState((prevState) => ({
      overlayOpen: !prevState.overlayOpen
    }));
  };

  toggleIsTeamBoardMenuOpen = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      teamBoardMenuOpen: !prevState.teamBoardMenuOpen
    }));
  };

  handleStateChange = (state) => {
    if (state.isOpen !== this.props.isOpen) {
      this.props.setIsOpenBoardMenu(state.isOpen);
    }
  };

  handleMenuVisibility = () => {
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
  };

  onProjectsClick = () => {
    const { token, search, toggleSidebarProjects, isOnHomeView } = this.props;
    const toggleInitiator = search.sidebarProjectsOpener;
    const shouldOpen = !search.sidebarProjectsOpen;
    const fromHome = isOnHomeView;
    toggleSidebarProjects(
      toggleInitiator === 'projects' ? 'projects' : null,
      token,
      fromHome,
      shouldOpen
    );
  };

  onClickMosLogo = (isDoubleClick) => {
    const {
      enableSearchState,
      handleProjectItemState,
      navHome,
      saveSearchState
    } = this.props;

    handleProjectItemState(
      null,
      ['detailVisible', 'expanded', 'contracting', 'fixed'],
      [false, false, false, false]
    );
    enableSearchState({ home: false, board: false });
    saveSearchState({ home: '', board: '' });

    navHome({ useLastLocation: !isDoubleClick });
  };

  // Allow up to 400 ms for a double-click to occur.
  debouncedOnClickMosLogo = debounce(this.onClickMosLogo, 400, {
    maxWait: 401
  });

  onClickMosLogoWithDebounce = (e) => {
    const { isOnHomeView } = this.props;
    // If the current view is a home view or if a double click occurs, cancel
    // the debounce for the single click and immediately navigate to the home
    // dashboard.
    if (isOnHomeView || e.detail === 2) {
      this.debouncedOnClickMosLogo.cancel();
      this.onClickMosLogo(true);
    }
    // If a single click occurs, wait a few moments before navigating to the
    // last home view.
    else if (e.detail === 1) this.debouncedOnClickMosLogo(false);
    // Ignore more than two clicks in a row.
  };

  onPlannerClick = () => {
    this.props.navTeamPlanner();
  };

  onSettingsClick = () => {
    this.props.navSettings();
  };

  // onTimesheetClick = () => {
  //   if (!this.props.isTimesheetsDisabled) {
  //     this.props.navTimesheet();
  //     this.props.setShowTimesheetMovedMessage({ showMessage: true });
  //   }
  // };

  onReportsClick = () => {
    this.props.navReports();
  };

  onDashboardClick = () => {
    this.props.navDashboard();
  };

  // onActivityClick = () => {
  //   this.props.navActivityPage();
  //   this.props.setShowUpdatesMovedMessage({ showMessage: true });
  // };

  onPersonalPlannerClick = () => {
    this.props.navPersonalPlannerPage();
  };

  onTeamSettingsClick = () => {
    this.setState(() => ({
      isTeamSettingsOpen: true
    }));
    this.props.setModal('team-settings-modal');
  };

  onTeamClick = () => {
    this.props.navTeamPage();
  };

  onScheduleClick = () => {
    this.props.navSchedulePage();
  };

  onPlannerClick = () => {
    this.props.navPlannerPage();
  };

  onInviteButtonClick = () => {
    this.props.toggleInviteForm({ open: true, origin: 'SidebarMenu' });
  };

  closeTeamModal = () => {
    const { setModal, setActiveKey } = this.props;
    this.setState(() => ({
      isTeamSettingsOpen: false
    }));

    setModal(null);
    setActiveKey('1');
  };

  goToSettings = () => this.props.navigateToSettings();
  goToTeamSettings = () => {
    const { navigateToTeamSettings, team, userIsAdmin } = this.props;
    userIsAdmin && navigateToTeamSettings({ teamSlug: team && team.slug });
  };

  openDefaultBoard = () => {
    const {
      onClearSearch,
      filterByMembers,
      clearSelectedProject,
      switchBoards,
      handleProjectItemState,
      boards,
      recentBoard,
      navigateToBoard,
      isOnBoardsPage
    } = this.props;
    if (isOnBoardsPage) {
      return;
    }
    const nonAdminBoards = boards.filter((board) => !board.is_administrative);
    if (!nonAdminBoards[0]) {
      return;
    }
    const board =
      recentBoard.id &&
      !recentBoard.is_administrative &&
      boards.some((board) => board.id === recentBoard.id)
        ? recentBoard
        : nonAdminBoards[0];
    const { id, team_slug: teamSlug, slug } = board;

    onClearSearch();
    filterByMembers(false, []);
    handleProjectItemState(
      null,
      ['detailVisible', 'expanded', 'fixed'],
      [false, false, false]
    );
    this.handleMenuVisibility();
    clearSelectedProject();
    switchBoards();
    navigateToBoard({
      teamSlug,
      boardSlug: slug,
      boardId: id
    });
  };

  getTeamInitials = (teamName) => teamName && teamName.toUpperCase()[0];

  renderTeamList = () => {
    const {
      isExpanded,
      boards,
      isOnTeamPage,
      isOnBoardsPage,
      isOnOwnTimesheet,
      isOnReportsView,
      isOnDashboardView,
      team,
      userIsAdmin,
      navWorkloadPage,
      checkPermission,
      isOnSchedulePage,
      myTeamMembership,
      matchedParams
    } = this.props;
    const { teamBoardMenuOpen } = this.state;
    const highestTeamMembershipRoleLevel =
      calculateHighestTeamMembershipRoleLevel(myTeamMembership);
    const canAccessReports = highestTeamMembershipRoleLevel < 7;
    const canAccessWorkload = highestTeamMembershipRoleLevel < 7;
    const canAccessPlanner = highestTeamMembershipRoleLevel < 7;

    return (
      <TeamList>
        {/* {!isExpanded ? (
          <div
            data-for="sidebar-menu-tooltip"
            data-tip="Portfolio Settings"
            data-tip-disable={isExpanded}
          >
            <TeamInitialsWrapper
              data-tip
              data-for="team-initials-sidebar-tooltip"
              onClick={this.goToTeamSettings}
            >
              <ReactTooltip
                id="team-initials-sidebar-tooltip"
                effect="solid"
                place="right"
                delayShow={500}
              >
                <div style={{ fontSize: '14px' }}>{teamName} Settings </div>
                {!canAccessSettings ? (
                  <div
                    style={{
                      opacity: '0.6',
                      fontSize: '12px',
                      lineHeight: '15px'
                    }}
                  >
                    <LockIcon height="10" width="12" /> Only admins have access
                    to Organization Settings
                  </div>
                ) : (
                  ''
                )}
              </ReactTooltip>
              <TeamInitials originType="team" id={team?.id} color={team?.color}>
                <Initials>{this.getTeamInitials(teamName)}</Initials>
                <StyledSettingsIcon />
              </TeamInitials>
            </TeamInitialsWrapper>
          </div>
        ) : (
          <TeamExpandedWrapper onClick={this.onTeamSettingsClick}>
            <TeamInitialsWrapper onClick={this.onTeamSettingsClick}>
              <TeamInitials originType="team" id={team?.id} color={team?.color}>
                {this.getTeamInitials(teamName)}
              </TeamInitials>
            </TeamInitialsWrapper>
            <TeamName>{teamName}</TeamName>
            <GearImg>
              <GearIcon />
            </GearImg>
          </TeamExpandedWrapper>
        )} */}
        {/* Members */}
        <MembersContainer
          onClick={this.onTeamClick}
          isModuleSelected={isOnTeamPage && !isOnOwnTimesheet}
          data-testid="sidemenu-members"
        >
          <ImageContainer isModuleSelected={isOnTeamPage}>
            <Img
              src={OrgIcon}
              isModuleSelected={isOnTeamPage && !isOnOwnTimesheet}
            />
            <DarkImg
              src={OrgIcon}
              isModuleSelected={isOnTeamPage && !isOnOwnTimesheet}
            />
            <SpaceDescription isModuleSelected={isOnTeamPage}>
              Team
            </SpaceDescription>
          </ImageContainer>
        </MembersContainer>
        <BoardsLink
          onClick={this.openDefaultBoard}
          data-for="sidebar-menu-tooltip"
          data-tip="Portfolios"
          data-tip-disable={isExpanded}
          data-testid="sidemenu-portfolios"
        >
          <ImageContainer isModuleSelected={isOnBoardsPage}>
            <Img
              src={BoardsIcon}
              onClick={this.openDefaultBoard}
              isModuleSelected={isOnBoardsPage}
            />
            <DarkImg
              src={BoardsDarkIcon}
              onClick={this.openDefaultBoard}
              isModuleSelected={isOnBoardsPage}
            />
            <SpaceDescription isModuleSelected={isOnBoardsPage}>
              Portfolios
            </SpaceDescription>
          </ImageContainer>
          <MenuLabel
            isExpanded={isExpanded}
            isTeams={true}
            isModuleSelected={isOnBoardsPage}
          >
            Portfolios
          </MenuLabel>

          <BoardsDropdownWrapper
            teamBoardMenuOpen={teamBoardMenuOpen}
            onClick={this.toggleIsTeamBoardMenuOpen}
          >
            {!teamBoardMenuOpen && <BoardCount>({boards.length})</BoardCount>}
            <BoardDropdownIcon style={boardDropdownIconStyle} />
          </BoardsDropdownWrapper>
        </BoardsLink>
        <HorizontalLine isExpanded={isExpanded} />
        {isExpanded && (
          <MainMenuBoardList
            teamBoardMenuOpen={teamBoardMenuOpen}
            handleMenuVisibility={this.handleMenuVisibility}
            showTitle={false}
          />
        )}
        <WorkloadLink
          isExpanded={isExpanded}
          navWorkloadPage={navWorkloadPage}
          checkPermission={checkPermission}
          team={team}
          userIsAdmin={userIsAdmin}
          canAccessWorkload={canAccessWorkload}
        />
        <TeamLink
          onClick={canAccessPlanner ? this.onPlannerClick : undefined}
          isOnTeamPage={
            isOnSchedulePage && matchedParams?.plannerViewType === 'workplans'
          }
          data-place="right"
          data-effect="solid"
          data-for="app-tooltip"
          data-tip={
            "You don't have access to this feature. <br/> Contact your Admin(s)."
          }
          data-html
          data-tip-disable={canAccessPlanner || isExpanded}
          data-testid="sidemenu-planner"
        >
          <ImageContainer
            isModuleSelected={isOnSchedulePage}
            data-testid="planner-navbutton"
          >
            <Img src={ScheduleMainIcon} isModuleSelected={isOnSchedulePage} />
            <DarkImg
              src={ScheduleMainIcon}
              isModuleSelected={isOnSchedulePage}
            />
            <SpaceDescription isModuleSelected={isOnSchedulePage}>
              Planner
            </SpaceDescription>
          </ImageContainer>
          <MenuLabel
            isExpanded={isExpanded}
            isModuleSelected={isOnSchedulePage}
          >
            Planner
          </MenuLabel>
        </TeamLink>
        <ReportLink
          onClick={canAccessReports ? this.onReportsClick : null}
          data-for="app-tooltip"
          data-tip={
            canAccessReports
              ? ''
              : "You don't have access to this feature. <br/> Contact your Admin(s)."
          }
          data-place="right"
          data-effect="solid"
          data-html
          data-tip-disable={isExpanded}
          isVisible={team}
          data-testid="sidemenu-reports"
        >
          <ImageContainer isModuleSelected={isOnReportsView}>
            <Img src={ReportIcon} isModuleSelected={isOnReportsView} />
            <DarkImg src={ReportIcon} isModuleSelected={isOnReportsView} />
            <SpaceDescription isModuleSelected={isOnReportsView}>
              Reports
            </SpaceDescription>
          </ImageContainer>
          <MenuLabel isExpanded={isExpanded} isModuleSelected={isOnReportsView}>
            Reports
          </MenuLabel>
        </ReportLink>

        <ReportLink
          onClick={canAccessReports ? this.onDashboardClick : null}
          data-for="app-tooltip"
          data-tip={
            canAccessReports
              ? ''
              : "You don't have access to this feature. <br/> Contact your Admin(s)."
          }
          data-place="right"
          data-effect="solid"
          data-html
          data-tip-disable={isExpanded}
          isVisible={team}
          data-testid="sidemenu-dashboard"
        >
          <ImageContainer isModuleSelected={isOnDashboardView}>
            <Img src={DashboardIcon} isModuleSelected={isOnDashboardView} />
            <DarkImg src={DashboardIcon} isModuleSelected={isOnDashboardView} />
            <SpaceDescription isModuleSelected={isOnDashboardView}>
              Dashboard
            </SpaceDescription>
          </ImageContainer>
          <MenuLabel
            isExpanded={isExpanded}
            isModuleSelected={isOnDashboardView}
          >
            Dashboard
          </MenuLabel>
        </ReportLink>
      </TeamList>
    );
  };

  getUpdatesTooltipContent = () =>
    `<div>
        <div>Updates have moved to "Notifications"</div>
        <div>on the left menu of the Home Screen</div>
      </div>`;

  getTimesheetTooltipContent = () =>
    `<div>
        <div>Timesheets have moved to "Time"</div>
        <div>on the left menu of the Home Screen</div>
     </div>`;

  render() {
    const {
      isExpanded,
      me,
      team,
      userTheme,
      teamName,
      myTeamMembership,
      isOnHomeView
    } = this.props;
    const isOpen = true; // we've decided to always show the minibar. Keeping the code as is in case of design change
    const highestTeamMembershipRoleLevel =
      calculateHighestTeamMembershipRoleLevel(myTeamMembership);
    const canAccessSettings = highestTeamMembershipRoleLevel === 0;

    return (
      <>
        {(isOpen || isExpanded) && (
          <SideBarWrapper isOpen={isOpen} isExpanded={isExpanded}>
            <SideBarList isOpen={isOpen} isExpanded={isExpanded}>
              <HomeLink
                data-effect="solid"
                data-for="app-tooltip"
                data-html
                data-place="right"
                data-testid="sidemenu-home"
                data-tip-disable={isOnHomeView}
                data-tip="<b>Click</b> to go to the most recent Home Space.<br /><b>Double-click</b> to go to the Home Dashboard."
                isOnHome={isOnHomeView}
                onClick={this.onClickMosLogoWithDebounce}
              >
                <ImageContainer>
                  <img src={MosaicLogo} />
                  <SpaceDescription isModuleSelected={isOnHomeView}>
                    Home
                  </SpaceDescription>
                </ImageContainer>
                <MenuLabel
                  isExpanded={isExpanded}
                  isModuleSelected={isOnHomeView}
                >
                  Home
                </MenuLabel>
              </HomeLink>
              <ProjectLink
                isOnHome={isOnHomeView}
                onClick={this.onProjectsClick}
                data-for="sidebar-menu-tooltip"
                data-tip="Projects"
                data-tip-disable={isExpanded}
                data-testid="sidebar-menu-projects-btn"
              >
                <ImageContainer>
                  <img src={ProjectsIcon} />
                  <SpaceDescription>Projects</SpaceDescription>
                </ImageContainer>
                <MenuLabel isExpanded={isExpanded}>Projects</MenuLabel>
              </ProjectLink>
              {/* <ActivityLink
                isOnHome={isOnHomeView}
                onClick={this.onActivityClick}
                data-for="app-tooltip"
                data-place="right"
                data-effect="solid"
                data-html
                data-tip={this.getUpdatesTooltipContent()}
                data-tip-disable={isExpanded}
              >
                <ImageContainer isModuleSelected={isOnActivityFeed}>
                  <Img src={InboxIcon} isModuleSelected={isOnActivityFeed} />
                  <DarkImg
                    src={DarkInboxIcon}
                    isModuleSelected={isOnActivityFeed}
                  />
                  <SpaceDescription isModuleSelected={isOnActivityFeed}>
                    Updates
                  </SpaceDescription>
                </ImageContainer>
                <MenuLabel
                  isExpanded={isExpanded}
                  isModuleSelected={isOnActivityFeed}
                >
                  Updates
                </MenuLabel>
              </ActivityLink>
              <TimesheetLink
                onClick={this.onTimesheetClick}
                isOnTimesheet={isOnOwnTimesheet}
                data-for="app-tooltip"
                data-place="right"
                data-effect="solid"
                data-html
                data-tip={
                  isTimesheetsDisabled
                    ? 'Timesheet Disabled<br>Contact Your Admins'
                    : this.getTimesheetTooltipContent()
                }
                data-tip-disable={isExpanded}
                isVisible={team}
              >
                <ImageContainer isModuleSelected={isOnOwnTimesheet}>
                  <Img
                    src={TimesheetIcon}
                    isModuleSelected={isOnOwnTimesheet}
                  />
                  <DarkImg
                    src={TimesheetDarkIcon}
                    isModuleSelected={isOnOwnTimesheet}
                  />
                  <SpaceDescription isModuleSelected={isOnOwnTimesheet}>
                    Timesheet
                  </SpaceDescription>
                </ImageContainer>
                <MenuLabel
                  isExpanded={isExpanded}
                  isModuleSelected={isOnOwnTimesheet}
                >
                  Timesheet
                </MenuLabel>
                {isTimesheetsDisabled && (
                  <StyledCautionIcon src={CautionIcon} />
                )}
              </TimesheetLink> */}

              <PermissionBasedRenderer
                successRender={this.renderTeamList}
                failRender={() => null}
              />
            </SideBarList>
            <SideBarBottomList>
              {/* <InviteButtonLink>
                <InviteButton
                  onClick={this.onTeamClick}
                  isExpanded={isExpanded}
                >
                  <InviteImg src={NewTeamMemberWhite} />
                  {isExpanded && 'Invite to Mosaic'}
                </InviteButton>
              </InviteButtonLink> */}
              <ReactTooltip
                id="member-initials-sidebar-tooltip"
                effect="solid"
                place="right"
                delayShow={500}
              />

              <SettingsContainer>
                {/* Team Settings */}
                <PermissionBasedRenderer
                  successRender={() => (
                    <TeamInitialsWrapper
                      data-tip
                      data-for="team-initials-sidebar-tooltip"
                      onClick={this.goToTeamSettings}
                      data-testid="sidemenu-team-settings"
                    >
                      <ReactTooltip
                        id="team-initials-sidebar-tooltip"
                        effect="solid"
                        place="right"
                        delayShow={500}
                      >
                        <div style={{ fontSize: '14px' }}>
                          {teamName} Settings{' '}
                        </div>
                        {!canAccessSettings ? (
                          <div
                            style={{
                              opacity: '0.6',
                              fontSize: '12px',
                              lineHeight: '15px'
                            }}
                          >
                            <LockIcon height="10" width="12" /> Only admins have
                            access to Organization Settings
                          </div>
                        ) : (
                          ''
                        )}
                      </ReactTooltip>
                      <TeamInitials
                        originType="team"
                        id={team?.id}
                        color={team?.color}
                      >
                        <Initials>
                          {this.getTeamInitials(team?.name ?? '')}
                        </Initials>
                        <StyledSettingsIcon />
                      </TeamInitials>
                    </TeamInitialsWrapper>
                  )}
                  failRender={() => null}
                />

                {/* Profile */}
                <MemberInitialsContainer
                  onClick={this.goToSettings}
                  data-for="member-initials-sidebar-tooltip"
                  data-tip="Personal Settings"
                  data-testid="sidemenu-member-settings"
                >
                  <MemberInitialBorder>
                    <MemberInitials
                      member={me}
                      classes="sidebar-member-initials logged-member-no-hover selected"
                      originType="teamMembership"
                      nonBaseMemberRoleTop="22px"
                      nonBaseMemberRoleLeft="26px"
                      id={me.team_membership_id}
                    />
                    <StyledSettingsIconContainer
                      styleId={me.team_membership_id}
                      originType="teamMembership"
                      userTheme={userTheme}
                      id={me.team_membership_id}
                    >
                      <StyledSettingsIcon />
                    </StyledSettingsIconContainer>
                  </MemberInitialBorder>
                </MemberInitialsContainer>
              </SettingsContainer>
            </SideBarBottomList>
          </SideBarWrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  isOnActivityFeed: getOnActivityFeed(state),
  isOnProjectPlanner: getOnTeamPlannerTasks(state),
  isOnTeamPage: getOnMembersView(state),
  isOnHomePage: state.router.location.pathname.includes('home/tasks'),
  isOnTimesheetPage: state.router.location.pathname.includes('timesheet'),
  isOnBoardsPage: getOnBoardView(state),
  isOnPersonalPlannerPage: getOnHomePlanner(state),
  isOnOwnTimesheet: getIsOnOwnTimesheets(state),
  isOnWorkloadPage: getOnWorkloadView(state),
  isOnSchedulePage: getIsOnScheduleView(state),
  isOnReportsView: getIsOnReportsView(state),
  isOnDashboardView: getIsOnDashboardView(state),
  isOnHomeView: getOnHomeView(state),
  userIsAdmin: getUserIsAdmin(state),
  me: getMe(state),
  userTheme: getUserTheme(state),
  team: getActiveTeam(state),
  currentBillingModal: getBillingCurrentModal(state),
  boards: getGroups(state),
  recentBoard: getRecentBoard(state),
  myTeamMembership: getMyTeamMembership(state),
  search: getSearch(state),
  isTimesheetsDisabled: getIsTimesheetsDisabled(state),
  matchedParams: getMatchedRouteParams(state),
  userSlug: getUserSlug(state)
});

const mapDispatchToProps = {
  enableSearchState: useSearchState,
  saveSearchState,
  handleProjectItemState,
  onClearSearch,
  filterByMembers,
  clearSelectedProject,
  openAddEditGroupModal,
  changeBoardPosition,
  switchBoards,
  setModal,
  setActiveKey,
  toggleInviteForm,
  navigateToBoard,
  navigateToPersonalDashboard,
  navigateToSettings,
  navigateToTeamSettings,
  toggleSidebarProjects
  // setShowTimesheetMovedMessage,
  // setShowUpdatesMovedMessage
};

export default withRouter(
  withPermissionsCheck(
    connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
  )
);
