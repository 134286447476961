import { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'moment';
import cn from 'classnames';
import {
  DOUBLE_HANDLE_WIDTH,
  HANDLE_MIN_WIDTH,
  SCHEDULE_BAR_FIRST_CHILD_BORDER,
  SCHEDULE_BAR_LAST_CHILD_BORDER
} from './constants';
import { ProjectBarBase } from './styles';
import { TitleText } from './SubComponents/TitleText';
import { HoursOrPercentText } from './SubComponents/HoursOrPercentText';
import { produce } from 'immer';

const ROW_PADDING = 6;

const SmallItemRenderer = (barProps) => {
  const {
    displayMemberCapacity,
    item,
    itemContext,
    startDateRef,
    endDateRef,
    handleScheduleBarClick,
    isHeatmapColored,
    itemProps,
    itemFitToScaleValue,
    barTooltipContent,
    tooltipDates,
    isTentative,
    lastBarIndex,
    itemToUse,
    leftResizeProps,
    rightResizeProps,
    getLeftOffsetFromDate,
    deserializedId,
    isProjectsView,
    left,
    isSplitting,
    handleScheduleBarSplit,
    SplitPreview,
    visibleTimeStart,
    visibleTimeEnd,
    averageCapacity,
    workdayPercent
  } = barProps;

  const titleComponents = useMemo(
    () => <TitleText isProjectsView={isProjectsView} item={item} />,
    [isProjectsView, item]
  );

  const isMoving = itemContext.dragging || itemContext.resizing;
  const commonClassName = cn('project-schedule-bar-metadata', {
    moving: isMoving,
    inactive: !isMoving && item.phase_is_inactive,
    tentative: isTentative
  });
  const commonBarProps = {
    dataTestid: 'schedule-bar',
    // Bar coloring
    $isHeatmapColored: isHeatmapColored,
    color: isProjectsView
      ? theme.colors.colorMediumGray1
      : theme.colors.colorLightGray14,
    originType: 'project',
    styleId: item.project_id
  };

  // The total width of the bar.
  const width = parseFloat(itemProps.style.width);

  // The width of the horizontal borders of the bar.
  const bordersWidth =
    SCHEDULE_BAR_FIRST_CHILD_BORDER + SCHEDULE_BAR_LAST_CHILD_BORDER;

  // The width of the bar without the borders.
  const widthWithoutBorders = Math.max(width - bordersWidth, 0);

  // The width of the bar without the borders and the horizontal padding of the
  // bar.
  const contentWidth = Math.max(widthWithoutBorders - 2 * ROW_PADDING, 0);

  // A flag indicating whether the bar is in narrow mode. The inner width of
  // the bar is the width without the borders. Both resize handles are in this
  // area by default. If the inner width is less than the minimum width of the
  // two resize handles, the bar changes to narrow mode.
  const narrow = widthWithoutBorders < 2 * HANDLE_MIN_WIDTH;

  return (
    <div {...itemProps}>
      {isMoving ? (
        <ProjectBar
          {...commonBarProps}
          className={cn(commonClassName, { narrow })}
          key="ghost-drag"
        >
          <div
            ref={startDateRef}
            data-tip={moment(tooltipDates.startDate)
              .startOf(itemFitToScaleValue)
              .format('MMM D')}
            data-for="start-date-tooltip"
            data-place="left"
            style={{ position: 'absolute', left: 0, bottom: 0, top: 0 }}
          />
          <div
            ref={endDateRef}
            data-tip={moment(tooltipDates.endDate)
              .endOf(itemFitToScaleValue)
              .format('MMM D')}
            data-for="end-date-tooltip"
            data-place="right"
            style={{ position: 'absolute', right: 0, bottom: 0, top: 0 }}
          />

          {contentWidth > 0 && (
            <>
              <TopRow>{titleComponents}</TopRow>
              <BottomRow>
                <HoursOrPercentText
                  averageCapacity={averageCapacity}
                  displayMemberCapacity={displayMemberCapacity}
                  item={item}
                  workdayPercent={workdayPercent}
                />
              </BottomRow>
            </>
          )}
        </ProjectBar>
      ) : (
        itemToUse.bars.map(
          (
            { start_date: startDate, end_date: endDate, day_count: dayCount },
            index
          ) => {
            // horizontal virtualization
            if (
              moment(endDate).isBefore(
                visibleTimeStart.clone().add(-1, 'month')
              ) ||
              moment(startDate).isAfter(visibleTimeEnd.clone().add(1, 'month'))
            ) {
              return null;
            }

            // The -1 pixel on `itemRight` is to exclude the right border of the cell.
            const fitStartDate = moment(startDate)
              .startOf(itemFitToScaleValue)
              .valueOf();
            const fitEndDate = moment(endDate)
              .endOf(itemFitToScaleValue)
              .valueOf();
            const itemLeft = getLeftOffsetFromDate(fitStartDate) - left;
            const itemRight = getLeftOffsetFromDate(fitEndDate) - left - 1;

            // The total width of the subbar.
            const width = itemRight - itemLeft;

            // The width of the horizontal borders of the subbar. This subbar
            // only has a left border if it is the first subbar and only has a
            // right border if it is the last subbar.
            const bordersWidth =
              (index === 0 ? SCHEDULE_BAR_FIRST_CHILD_BORDER : 0) +
              (index === lastBarIndex ? SCHEDULE_BAR_LAST_CHILD_BORDER : 0);

            // The width of the subbar without the borders.
            const widthWithoutBorders = Math.max(width - bordersWidth, 0);

            // The width of the subbar without the borders and the horizontal
            // padding of the subbar.
            const contentWidth = Math.max(
              widthWithoutBorders - 2 * ROW_PADDING,
              0
            );

            // A flag indicating whether the subbar is in narrow mode. The
            // inner width of the subbar is the width without the borders. Both
            // resize handles are in this area by default. If the inner width
            // is less than the minimum width of the two resize handles, the
            // subbar changes to narrow mode.
            const narrow = widthWithoutBorders < 2 * HANDLE_MIN_WIDTH;

            // If the subbar is in narrow mode, the resize handles receive
            // special styling that must not be overwritten by the `style`
            // attribute of their elements. This removes the relevant styles.
            const adjustedLeftResizeProps = narrow
              ? produce(leftResizeProps, (draft) => {
                  delete draft?.style?.height;
                  delete draft?.style?.top;
                })
              : leftResizeProps;
            const adjustedRightResizeProps = narrow
              ? produce(rightResizeProps, (draft) => {
                  delete draft?.style?.height;
                  delete draft?.style?.top;
                })
              : rightResizeProps;

            return dayCount === 0 ? (
              <ProjectBar
                className={cn('ghost', { narrow })}
                key={startDate}
                style={{ left: itemLeft, width }}
              >
                {index === 0 && <div {...adjustedLeftResizeProps}></div>}
                {index === lastBarIndex && (
                  <div {...adjustedRightResizeProps}></div>
                )}
                {isSplitting && (
                  <SplitPreview
                    startDate={fitStartDate}
                    endDate={fitEndDate}
                    itemFitToScaleValue={itemFitToScaleValue}
                    itemId={deserializedId}
                    handleScheduleBarSplit={handleScheduleBarSplit}
                  />
                )}
              </ProjectBar>
            ) : (
              <ProjectBar
                {...commonBarProps}
                className={cn(commonClassName, { narrow })}
                onClick={(e) => {
                  e.stopPropagation();
                  handleScheduleBarClick(deserializedId, e, moment(startDate));
                }}
                key={startDate}
                style={{ left: itemLeft, width }}
                // Tooltip
                data-class="project-bars-tooltips"
                data-delay-show={1000}
                data-effect="float"
                data-for="schedule-bar"
                data-html
                data-tip={barTooltipContent}
                data-type="light"
              >
                {
                  // Left resize handle
                  index === 0 && (
                    <div {...adjustedLeftResizeProps}>
                      <>
                        <div className="drag-handle-bar left" />
                        {widthWithoutBorders / 2 >= DOUBLE_HANDLE_WIDTH && (
                          <div className="drag-handle-bar left" />
                        )}
                      </>
                    </div>
                  )
                }
                {
                  // Right resize handle
                  index === lastBarIndex && (
                    <div {...adjustedRightResizeProps}>
                      <>
                        <div className="drag-handle-bar right" />
                        {widthWithoutBorders / 2 >= DOUBLE_HANDLE_WIDTH && (
                          <div className="drag-handle-bar right" />
                        )}
                      </>
                    </div>
                  )
                }
                {isSplitting && (
                  <SplitPreview
                    startDate={fitStartDate}
                    endDate={fitEndDate}
                    itemFitToScaleValue={itemFitToScaleValue}
                    itemId={deserializedId}
                    handleScheduleBarSplit={handleScheduleBarSplit}
                  />
                )}

                {contentWidth > 0 && (
                  <>
                    <TopRow>{titleComponents}</TopRow>
                    <BottomRow>
                      <HoursOrPercentText
                        averageCapacity={averageCapacity}
                        displayMemberCapacity={displayMemberCapacity}
                        item={item}
                        workdayPercent={workdayPercent}
                      />
                    </BottomRow>
                  </>
                )}
              </ProjectBar>
            );
          }
        )
      )}
    </div>
  );
};

export default SmallItemRenderer;

const ProjectBar = styled(ProjectBarBase)`
  flex-direction: column;
  gap: 10px;
  height: 10px;

  &.narrow::after {
    border-radius: 2px;
    content: '';
    inset: -1em 0;
    position: absolute;
    z-index: -1;
  }

  &.narrow:hover::after {
    background-color: ${theme.colors.colorLightGray1};
  }
`;

const RowContainer = styled.div`
  align-items: center;
  display: flex;
  left: calc(-1 * var(--border-left-width));
  padding: 0 ${ROW_PADDING}px;
  position: absolute;
  width: calc(100% + var(--border-left-width) + var(--border-right-width));
`;

const TopRow = styled(RowContainer)`
  bottom: calc(100% + var(--border-width));
`;

const BottomRow = styled(RowContainer)`
  top: calc(100% + var(--border-width));
`;
