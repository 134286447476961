import React, { createRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import loader from 'images/loading-wheel-small.svg';
import theme from 'theme';
import OptionsDropdown from 'components/options/OptionsDropdown';
import HardDeleteWarningModal from 'components/Modals/HardDeleteWarningModal';
import {
  toggleRemoveOverlay,
  resendInvite,
  navigateToTeamSettings
} from 'actionCreators';
import { getAuth, getSplitFlags } from 'selectors';
import { getHardDeleteWarningModalProps } from 'IntegrationsModule/utils';
import { DATA_TYPES } from 'IntegrationsModule/constants';
import {
  getIsIntegrationsV2Loading,
  getIntegrationsV2,
  getIntegrationsV2Error,
  getMosaicMappingStatuses
} from 'IntegrationsModule/selectors';
import { calculateHighestTeamMembershipRoleId } from 'appUtils/roleDisplay';
import { ExistingMember } from 'views/personalSettings/styles';
import KaratLeft from 'icons/KaratLeft';
import { updateTeamMembersPermission } from 'PermissionsModule/actionCreators';
import { AccessLevelSummary } from 'PermissionsModule/components/AccessLevelSummary';
import { AccessLevelsDropdown } from 'PermissionsModule/components/AccessLevelsDropdown';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { permissionsUtils } from 'PermissionsModule/utils';
import { archiveTeamMembers } from 'TeamsModule/actionCreators';
import Popover from 'components/Popover';
import GoToProjectIcon from 'icons/GoToProjectIcon';

export const DropdownIcon = styled(KaratLeft)`
  transform: rotate(-90deg);
  margin-left: 8px;
  visibility: hidden;
`;

const RestoreAndArchiveContainer = styled.div`
  .options-dropdown-toggle {
    text-align: unset;
  }
`;

const PopoverContainer = styled.div`
  padding: 10px 16px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;

const ARCHIVE_MEMBER_DROP_DOWN_OPTION = {
  RESTORE: 'restore',
  DELETE: 'delete'
};

/**
 * showRemoveOverlay,removeOverlayIsUnarchive, willHardDeleteArchivedMember
 */
const archiveMemberDropdownArgs = {
  [ARCHIVE_MEMBER_DROP_DOWN_OPTION.RESTORE]: [true, true],
  [ARCHIVE_MEMBER_DROP_DOWN_OPTION.DELETE]: [true, true, true]
};

const archivedMemberDropdownOptions = [
  {
    label: 'Restore',
    value: ARCHIVE_MEMBER_DROP_DOWN_OPTION.RESTORE
  },
  {
    label: 'Delete',
    value: ARCHIVE_MEMBER_DROP_DOWN_OPTION.DELETE
  }
];
class PermissionLevelCellContent extends React.Component {
  state = {
    isPermissionsDropdownOpen: false,
    isWarningModalOpen: false,
    integrationsContainingMappedDataType: [],
    showGenericWarningMessage: false,
    isResending: false,
    isGoToPermissionsMenuOpen: false
  };

  handlePermissionsClick = () => {
    const { isDisabled, newPermissionSettingsFlag } = this.props;
    if (isDisabled) {
      return;
    }

    if (newPermissionSettingsFlag) {
      this.setState({ isGoToPermissionsMenuOpen: true });
      return;
    }

    this.setState({
      isPermissionsDropdownOpen: true
    });
  };

  resendInvite = () => {
    const { resendInvite, invite, auth, member } = this.props;

    if (!invite.inviteResent.includes(member.account.id)) {
      const params = {
        email: member.account.email,
        firstName: member.account.first_name,
        lastName: member.account.last_name,
        idx: member.account.id // used to match if checking if row is currently sending, actual index is not important
      };
      resendInvite(auth.token, params);
      this.setState({ isResending: true });
      setTimeout(() => this.setState({ isResending: false }), 2000);
    }
  };

  toggleRemoveOverlay = (
    showRemoveOverlay,
    removeOverlayIsUnarchive,
    willHardDeleteArchivedMember
  ) => {
    this.props.toggleRemoveOverlay({
      showRemoveOverlay,
      removeOverlayIsUnarchive,
      willHardDeleteArchivedMember,
      accountIds: [this.props.member.account.id]
    });
  };

  memberDropdownRef = React.createRef();
  goToPermissionsMenuRef = createRef();

  onUnarchiveDropdownItemSelect = (value) => {
    if (ARCHIVE_MEMBER_DROP_DOWN_OPTION.DELETE === value) {
      const {
        mosaicMappingStatuses,
        member,
        integrationsV2,
        isV2Loading,
        isV2Error
      } = this.props;
      const {
        integrationsContainingMappedDataType,
        showHardDeleteWarningModal,
        showGenericWarningMessage
      } = getHardDeleteWarningModalProps(
        mosaicMappingStatuses,
        integrationsV2,
        isV2Loading,
        isV2Error,
        DATA_TYPES.ACCOUNTS,
        member.account.id
      );
      if (showHardDeleteWarningModal) {
        this.setState({
          isWarningModalOpen: true,
          showGenericWarningMessage,
          integrationsContainingMappedDataType
        });
        return;
      }
    }
    this.toggleRemoveOverlay(...archiveMemberDropdownArgs[value]);
  };

  renderToggle = () => {
    const { member, newPermissionSettingsFlag } = this.props;

    if (
      permissionsUtils.getIsProjectGuest(member) ||
      permissionsUtils.getIsExternalProjectContractor(member)
    ) {
      return '-';
    }

    const role =
      newPermissionSettingsFlag && member.role_ids.length
        ? member.role_ids[0]
        : calculateHighestTeamMembershipRoleId(member);

    return <AccessLevelSummary role={role} isHideArrow />;
  };

  closeWarningModal = () => {
    this.setState({
      isWarningModalOpen: false,
      showGenericWarningMessage: false,
      integrationsContainingMappedDataType: []
    });
  };

  render() {
    const {
      member,
      invite,
      archiveTeamMembers,
      teamId,
      updateTeamMembersPermission
    } = this.props;
    const { isResending } = this.state;
    return (
      <>
        <div key={member.account.id} className="team-members-row">
          <div className="team-members-status-col">
            <ExistingMember
              className="existing-member"
              ref={this.goToPermissionsMenuRef}
              onClick={(e) => {
                this.handlePermissionsClick();
                this.memberDropdownRef = e.currentTarget;
                this.goToPermissionsMenuRef = e.currentTarget;
              }}
            >
              <RestoreAndArchiveContainer>
                <OptionsDropdown
                  renderToggle={this.renderToggle}
                  disableDropdown={!permissionsUtils.getIsArchived(member)}
                  onSelect={this.onUnarchiveDropdownItemSelect}
                  options={archivedMemberDropdownOptions}
                />
              </RestoreAndArchiveContainer>
            </ExistingMember>
            {member.pending_email && !member.is_archived && (
              <>
                <div style={{ width: 10 }} />
                <div className="invited-member">
                  {invite.inviteResent.includes(member.account.id) &&
                  invite.indexMember === member.account.id ? (
                    <div>
                      <img
                        src={loader}
                        className={cn('loader-overlay', {
                          invisible: !isResending
                        })}
                        style={{
                          left: 60
                        }}
                      />
                      <div className="resend-invite-blurred">
                        {isResending ? (
                          <p> Sending ... </p>
                        ) : (
                          <p> Invitation Sent </p>
                        )}
                      </div>
                    </div>
                  ) : invite.inviteResent.includes(member.account.id) &&
                    !(member.account.id === invite.indexMember) ? (
                    <div className="resend-invite-blurred">
                      {<p> Invitation Sent </p>}
                    </div>
                  ) : (
                    <div className="invite-container">
                      <div
                        className="cancel-invite"
                        onClick={() =>
                          archiveTeamMembers({
                            teamMembershipIds: [member.id],
                            teamId
                          })
                        }
                      >
                        Cancel Invite
                      </div>
                      <div
                        className="resend-invite"
                        onClick={this.resendInvite}
                      >
                        Resend Invite
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <HardDeleteWarningModal
          toggle={this.closeWarningModal}
          isOpen={this.state.isWarningModalOpen}
          showGenericWarningMessage={this.state.showGenericWarningMessage}
          dataType={DATA_TYPES.ACCOUNTS}
          integrationsContainingMappedDataType={
            this.state.integrationsContainingMappedDataType
          }
        />

        <AccessLevelsDropdown
          teamMembershipIds={[member.id]}
          isOpen={this.state.isPermissionsDropdownOpen}
          popoverTarget={this.memberDropdownRef}
          onClose={() => this.setState({ isPermissionsDropdownOpen: false })}
          onRequestArchiveMember={() => {
            this.setState({ isPermissionsDropdownOpen: false });
            this.toggleRemoveOverlay(true);
          }}
          onSelect={(newRole) => {
            this.setState({ isPermissionsDropdownOpen: false });
            updateTeamMembersPermission({
              teamId,
              teamMembershipIds: [member.id],
              teamRole: newRole
            });
          }}
        />
        {this.state.isGoToPermissionsMenuOpen && (
          <Popover
            isOpen
            target={this.goToPermissionsMenuRef.current}
            closePopover={() =>
              this.setState({ isGoToPermissionsMenuOpen: false })
            }
          >
            <PopoverContainer
              onClick={() =>
                this.props.navigateToTeamSettings({
                  viewType: 'permissions',
                  tab: 'membership',
                  openInNewWindow: true
                })
              }
            >
              <GoToProjectIcon color={theme.colors.colorMediumGray9} />
              Go To Permissions
            </PopoverContainer>
          </Popover>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  selectedMember: state.settings.selectedMember,
  team: state.teams.allTeams[0],
  teamId: getSelectedTeamId(state),
  invite: state.invite,
  showRemoveOverlay: state.settings.showRemoveOverlay,
  integrationsV2: getIntegrationsV2(state),
  isV2Loading: getIsIntegrationsV2Loading(state),
  isV2Error: getIntegrationsV2Error(state),
  mosaicMappingStatuses: getMosaicMappingStatuses(state),
  newPermissionSettingsFlag: getSplitFlags(state).newPermissionSettings
});

const mapDispatchToProps = {
  updateTeamMembersPermission,
  toggleRemoveOverlay,
  archiveTeamMembers,
  resendInvite,
  navigateToTeamSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionLevelCellContent);
