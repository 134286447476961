import { useMemo, useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getFlatPhasesHash } from 'selectors';
import { ROW_TYPES } from '../tableConfigs';
import {
  BaseTableListItemsBuilder,
  BaseTableListItem
} from 'components/Table/types';
import keyBy from 'lodash/keyBy';
import { makeSuggestedMemberListFromActivityPhaseMembershipSuggestions } from 'SuggestionModule/components/FindPeople//utils';
import { makeGetOwnBudgetTotals } from 'BudgetModule/selectors';

import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import { serializeId } from 'appUtils';
import {
  FilterStateIds,
  teamBuilderIsoStateId
} from 'SuggestionModule/components/FindPeople/constants';

import useUnassignedRolesByProjectAndPhases, {
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { serializeMemberAlternatesRowId } from '../../utils';
import {
  MemberListItem,
  PhaseListItem,
  PhaseGroupedListsBuilderArgs
} from './types';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { useReasonListItemsBuilder } from './useReasonListItemsBuilder';
import { useMembersGroupedListsBuilder } from './useMembersGroupedListsBuilder';
import { useActivityPhasesGroupedListsBuilder } from './useActivityPhasesGroupedListsBuilder';
import {
  checkPhaseHasActivityPhase,
  getDefaultActivityPhase
} from 'ProjectsModule/utils/phaseDisplay';
import useActivityPhases from 'appUtils/hooks/useActivityPhases/useActivityPhases';
import { getAccountTotalsFromFormattedPhaseTotals } from 'BudgetModule/utils/projectAccounts';
import { makeGetSuggestedAccountsForActivityPhaseMembershipByIsoStateId } from 'SuggestionModule/selectors';
import {
  ToggleAvailabilityView,
  AvailabilityViewTriggerSource,
  AvailabilityViewTriggerSourceType
} from '../../contexts/types';

export const usePhasesGroupedListsBuilder = ({
  getIsOpen,
  toggleCollapse,
  toggleCollapseAll,
  setParentCollapseState,
  getCurrentParentCollapseState,
  projectId,
  toggleAvailabilityView,
  selectedAvailabilityViewTriggerSource
}: UseNestedCollapseHookReturnedValues & {
  projectId: Nullable<number>;
  toggleAvailabilityView: ToggleAvailabilityView;
  selectedAvailabilityViewTriggerSource?: AvailabilityViewTriggerSource;
}) => {
  // Building reason rows
  const memberGroupedListItemsBuilder = useReasonListItemsBuilder({
    getIsOpen,
    toggleCollapse,
    setParentCollapseState,
    getCurrentParentCollapseState,
    toggleCollapseAll
  });

  // Building member grouped list
  const memberGroupedListsBuilder = useMembersGroupedListsBuilder({
    getIsOpen,
    toggleCollapse,
    setParentCollapseState,
    getCurrentParentCollapseState,
    toggleCollapseAll,
    listItemsBuilder: memberGroupedListItemsBuilder
  });

  // Building activity phase member grouped list
  const activityPhaseGroupedListsBuilder = useActivityPhasesGroupedListsBuilder(
    {
      getIsOpen,
      toggleCollapse,
      setParentCollapseState,
      getCurrentParentCollapseState,
      toggleCollapseAll,
      listItemsBuilder: memberGroupedListsBuilder,
      projectId,
      toggleAvailabilityView,
      selectedAvailabilityViewTriggerSource
    }
  );

  const phaseHash = useAppSelector(getFlatPhasesHash);

  const getSuggestedAccountsForActivityPhaseMembershipByIsoStateId = useMemo(
    makeGetSuggestedAccountsForActivityPhaseMembershipByIsoStateId,
    []
  );

  const accountSuggestionsByActivityPhaseMemberships = useAppSelector((state) =>
    getSuggestedAccountsForActivityPhaseMembershipByIsoStateId(state, {
      isoStateId: teamBuilderIsoStateId
    })
  );

  const {
    getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
    activityHash
  } = useActivityPhases({ projectId });
  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const getBudgetTotals = useMemo(makeGetOwnBudgetTotals, []);

  const { phaseTotals } = useAppSelector((state) =>
    getBudgetTotals(state, {
      filterStateId: FilterStateIds.fetchPhaseTotals,
      shouldFormatData: true
    })
  );

  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
  } = useUnassignedRolesByProjectAndPhases({ projectId });

  const phasesGroupedListsBuilder = useCallback(
    ({
      order,
      parentGroupId, // main
      showDemoSuggestions,
      unassignedMemberBudgetId
    }: PhaseGroupedListsBuilderArgs) => {
      const lists = order.reduce((acc: PhaseListItem[], phaseId) => {
        const phase = phaseHash[phaseId] || {};

        const phaseMembershipWithUnassignedMemberBudgetAndPosition =
          getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
            { phaseId, memberBudgetId: unassignedMemberBudgetId }
          );
        const hasActivityPhases = checkPhaseHasActivityPhase(phase);

        const phaseMembershipId =
          phaseMembershipWithUnassignedMemberBudgetAndPosition?.id;

        const groupId = serializeId({
          itemType: 'phase',
          id: undefined,
          ids: [phaseId]
        });

        const isListOpen = !!getIsOpen({
          parentId: parentGroupId,
          toggleId: groupId
        });

        const defaultActivityPhase = getDefaultActivityPhase(phase);

        if (!hasActivityPhases && defaultActivityPhase) {
          const activity = activityHash[defaultActivityPhase.activity_id];
          const activityPhaseMembership =
            getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId({
              activityPhaseId: defaultActivityPhase.id,
              memberBudgetId: unassignedMemberBudgetId
            });
          const isShowingAllMembers = phaseMembershipId
            ? !!getIsOpen({
                parentId: groupId,
                toggleId: serializeMemberAlternatesRowId({
                  id: phaseMembershipId
                })
              })
            : false;

          // Spec: Show phase suggestions as suggestion for activity phases as well
          const {
            suggestedMembersToShow,
            numOfRemainingSuggestedMembers,
            allSuggestedMembers,
            shouldShowMemberAlternatesRow,
            memberIdsOrder
          } = makeSuggestedMemberListFromActivityPhaseMembershipSuggestions({
            accountSuggestionsByActivityPhaseMemberships,
            activityPhaseMembershipId: activityPhaseMembership?.id,
            isShowingAllMembers,
            teamMembershipsByAccountId
          });

          const accountTotals = activityPhaseMembership
            ? getAccountTotalsFromFormattedPhaseTotals({
                formattedPhaseTotals: phaseTotals,
                phase,
                activityId: defaultActivityPhase.activity_id,
                activityPhaseMembershipId: activityPhaseMembership.id
              })
            : undefined;

          const phaseList = {
            id: phaseId,
            sectionId: groupId,
            listItems: [] as MemberListItem[],
            customItems: [] as BaseTableListItem[],
            isList: true,
            isFullyLoaded: true,
            addEmptyRow: isListOpen,
            toggleCollapse: () =>
              toggleCollapse({ parentId: parentGroupId, toggleId: groupId }),
            isOpen: isListOpen,
            rowHeight: 75,
            rowType: ROW_TYPES.phaseGroupRow,
            /* -------------------------------------------------------------------------- */
            phaseId,
            phase,
            phaseMembership:
              phaseMembershipWithUnassignedMemberBudgetAndPosition,
            activityPhaseMembership,
            activityPhase: defaultActivityPhase,
            accountTotals,
            hasActivityPhases,
            allSuggestedMembers,
            /* -------------------------------------------------------------------------- */
            isAvailabilityViewActive:
              selectedAvailabilityViewTriggerSource?.type ===
                AvailabilityViewTriggerSourceType.Phase &&
              selectedAvailabilityViewTriggerSource?.id === phaseId,
            onToggleViewAvailability: () => {
              toggleAvailabilityView({
                triggerSource: {
                  type: AvailabilityViewTriggerSourceType.Phase,
                  id: phaseId
                },
                accountIds: memberIdsOrder
              });
            }
          };

          // Check if there are suggested members for this unassigned role
          if (
            memberIdsOrder.length &&
            phaseMembershipWithUnassignedMemberBudgetAndPosition &&
            activityPhaseMembership &&
            activity
          ) {
            const memberListItems = memberGroupedListsBuilder({
              order: memberIdsOrder,
              parentGroupId: groupId,
              membersHash: keyBy(suggestedMembersToShow, 'account_id'),
              phase,
              phaseMembership:
                phaseMembershipWithUnassignedMemberBudgetAndPosition,
              activityPhaseMembership,
              activity,
              activityPhase: defaultActivityPhase,
              numOfRemainingSuggestedMembers,
              isShowingAllMembers,
              shouldShowMemberAlternatesRow,
              phaseTotals
            });

            // Has members suggestion for this phase
            phaseList.listItems = memberListItems;
          } else {
            // No members suggestion for this phase
            const noMatchListItem = {
              rowType: ROW_TYPES.noMatchRow,
              rowHeight: 40
            };
            phaseList.customItems = [noMatchListItem];
          }

          acc.push(phaseList);
        } else if (phaseMembershipWithUnassignedMemberBudgetAndPosition) {
          // Activity phase
          const activityListItems = activityPhaseGroupedListsBuilder({
            order: phase.activity_order,
            parentGroupId: groupId,
            phaseTotals,
            unassignedMemberBudgetId,
            phase,
            phaseMembershipId:
              phaseMembershipWithUnassignedMemberBudgetAndPosition.id,
            phaseMembership:
              phaseMembershipWithUnassignedMemberBudgetAndPosition,
            accountSuggestionsByActivityPhaseMemberships,
            teamMembershipsByAccountId
          });

          const phaseList = {
            id: phaseId,
            sectionId: groupId,
            listItems: activityListItems,
            /**
             * Even though there are matches at the phase level
             * There might still be no activity phase that has this unassigned role
             */
            customItems: activityListItems.length
              ? []
              : [
                  {
                    rowType: ROW_TYPES.noMatchRow,
                    rowHeight: 40
                  }
                ],
            isList: true,
            isFullyLoaded: true,
            addEmptyRow: isListOpen,
            toggleCollapse: () =>
              toggleCollapse({ parentId: parentGroupId, toggleId: groupId }),
            isOpen: isListOpen,
            rowHeight: 75,
            rowType: ROW_TYPES.phaseGroupRow,
            /* -------------------------------------------------------------------------- */
            phaseId,
            phase,
            phaseMembership:
              phaseMembershipWithUnassignedMemberBudgetAndPosition,
            accountTotals: undefined,
            allSuggestedMembers: undefined,
            activityPhase: defaultActivityPhase,
            hasActivityPhases
          };

          acc.push(phaseList);
        }

        return acc;
      }, []);

      /* -------------------------------- Collapse -------------------------------- */
      const collapseState = getCurrentParentCollapseState(parentGroupId);

      if (!collapseState) {
        setParentCollapseState({
          values: {
            totalNumToggles: lists.length
          },
          id: parentGroupId
        });
      } else if (collapseState.totalNumToggles !== lists.length) {
        // update number of toggles
        setParentCollapseState({
          values: {
            totalNumToggles: lists.length
          },
          id: parentGroupId
        });
      }

      return lists;
    },
    [
      accountSuggestionsByActivityPhaseMemberships,
      activityHash,
      activityPhaseGroupedListsBuilder,
      getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
      getCurrentParentCollapseState,
      getIsOpen,
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
      memberGroupedListsBuilder,
      phaseHash,
      phaseTotals,
      selectedAvailabilityViewTriggerSource,
      setParentCollapseState,
      teamMembershipsByAccountId,
      toggleAvailabilityView,
      toggleCollapse
    ]
  );

  return phasesGroupedListsBuilder;
};
