import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RequireAtLeastOne } from 'type-fest';
import {
  makeGetActiveRateByMemberBudget,
  getTeamRates,
  getDefaultTeamRates
} from 'BudgetModule/selectors';
import {
  getPositionToCurrentPositionCostRate,
  getPositionToCurrentPositionRate
} from 'BudgetModule/selectors/positions';
import { MemberBudget } from 'models/memberBudget';

type UseCurrentRatesProps = RequireAtLeastOne<{
  memberBudget?: MemberBudget;
  accountId?: number;
  positionId?: number;
}>;

const useCurrentRates = ({
  memberBudget,
  accountId,
  positionId
}: UseCurrentRatesProps) => {
  const rateHash = useSelector(getTeamRates);
  const defaultTeamRatesHash = useSelector(getDefaultTeamRates);
  const positionToCurrentPositionCostRateHash = useSelector(
    getPositionToCurrentPositionCostRate
  );
  const positionToCurrentPositionRateHash = useSelector(
    getPositionToCurrentPositionRate
  );

  const positionRate = positionId
    ? positionToCurrentPositionRateHash[positionId]
    : null;
  const positionCostRate = positionId
    ? positionToCurrentPositionCostRateHash[positionId]
    : null;

  const teamRate = positionId
    ? rateHash[positionRate?.rate_id]
    : accountId
    ? rateHash[defaultTeamRatesHash[accountId]?.rate_id]
    : memberBudget?.account_id
    ? rateHash[defaultTeamRatesHash[memberBudget.account_id]?.rate_id]
    : null;

  // cost rates need to be handled differently since amounts are entered on create PositionRate.rate_amount
  // and THEN the Rate is created (with is_cost_rate: true). These Rates should not be used around the app.
  // Use the PositionRate instead
  const costRate = positionId ? positionCostRate : null; // todo for member cost rates

  const getActiveRateByMemberBudget = useMemo(
    () => makeGetActiveRateByMemberBudget(),
    []
  );
  const activeMemberRate = useSelector((state) =>
    memberBudget
      ? getActiveRateByMemberBudget(state, { memberBudgetId: memberBudget?.id })
      : undefined
  );

  const memberRate = rateHash[activeMemberRate?.rate_id];

  return {
    teamRate,
    costRate,
    memberRate,
    rateHash,
    defaultTeamRatesHash,
    positionToCurrentPositionCostRateHash,
    positionToCurrentPositionRateHash,
    positionRate,
    positionCostRate
  };
};

export default useCurrentRates;
