import { BaseGroupRendererProps } from './types';
import { BaseTimelineProps } from 'TimelinesModule/types/timeline';
import { Groups } from 'TimelinesModule/types/groups';
import { TIMELINE_GROUP_TYPES } from '../constants';

interface GroupRendererByTypeProps extends BaseGroupRendererProps<Groups> {
  customRenderers: BaseTimelineProps['groupRenderersByType'];
}

export const GroupRendererByType = (props: GroupRendererByTypeProps) => {
  const { customRenderers, ...rest } = props;
  const { group } = rest;

  const Component = customRenderers[group.type];

  if (group.type === TIMELINE_GROUP_TYPES.EMPTY) return null;

  if (!Component) return null;

  return <Component {...rest} />;
};
