import React, { useRef, useState } from 'react';
import TimesheetDay from 'views/timesheet/Timesheet/TimesheetDay';
import {
  StyledPencilIcon,
  StyledRateMultiplierCell,
  RateMultiplierContainer,
  StyledRateMultiplierInputContainer,
  StyledRateMultiplierInput
} from './styles';
import { useDispatch } from 'react-redux';
import { updateCreateRowData, bulkUpdateTimesheets } from 'actionCreators';

const noop = () => {};

const RateMultiplierCell = ({ cell, row }) => {
  const dispatch = useDispatch();
  const { value } = cell;
  const { original } = row;
  const [rateMultiplier, setRateMultiplier] = useState('1');
  const rateMultiplierInput = useRef(null);
  if (!value) {
    return <div />;
  }
  const handleTimeChange = ({ rateMultiplier }) => {
    // dispatch(
    //   updateRates({
    //     rateMultiplier,
    //     time_entry_ids: [original.id]
    //   })
    // );
  };
  const onClickFocus = () => {
    rateMultiplierInput.current.focus();
  };
  const handleChange = (e) => {
    const rateMultiplier = e.target.value.match(/\d{0,2}(\.\d{0,2})?/)[0];
    setRateMultiplier(rateMultiplier);
  };
  const handleRateMultiplierSubmit = () => {
    if (+rateMultiplier === 0) {
      setRateMultiplier('');
    }
    if (rateMultiplier.charAt(rateMultiplier.length - 1) === '.') {
      setRateMultiplier(rateMultiplier.slice(0, -1));
    }
  };
  return (
    <StyledRateMultiplierCell
      data-for="app-tooltip"
      data-delay-show={500}
      data-effect="solid"
      onClick={onClickFocus}
    >
      <RateMultiplierContainer>
        <StyledRateMultiplierInputContainer>
          <StyledRateMultiplierInput
            style={{ width: 35 }}
            ref={rateMultiplierInput}
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleRateMultiplierSubmit}
          />
          <div className="hour-text show-on-row-hover">
            <StyledPencilIcon size="10" />
          </div>
        </StyledRateMultiplierInputContainer>
      </RateMultiplierContainer>
    </StyledRateMultiplierCell>
  );
};

export default RateMultiplierCell;
