import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  width: 145px;
`;

const StyledText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const DescriptionCell = ({ row }) => {
  const { userActivity } = row.original;
  const description = userActivity?.display_data?.description_title;

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <StyledTextContainer>
      <StyledText
        data-tip={description}
        data-for="app-tooltip"
        data-effect="solid"
      >
        {description}
      </StyledText>
    </StyledTextContainer>
  );
};

export default DescriptionCell;
