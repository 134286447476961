import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';

const StyledTooltip = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 12px;
  font-weight: 400;
  padding: 5px 8px;
  ${({ isReactTooltip }) =>
    !isReactTooltip &&
    `
      background: ${theme.colors.colorPureWhite};
      padding: 15px 20px;
  `}
`;
const StyledDivider = styled.hr`
  border-top: 1px solid rgba(205, 205, 205, 0.62);
  width: 100%;
  margin: 4px 0;
`;
const ValueContainer = styled.div`
  display: flex;
`;
const StyledValue = styled.div`
  font-weight: 600;
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.marginRight && `margin-right: ${props.marginRight};`}
  ${(props) => props.width && `width: ${props.width}px;`}

  &.percent-value {
    text-align: right;
    max-width: 52px;
  }
`;
const StyledLabel = styled.span``;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;
const LegendBox = styled.div`
  background: ${(props) => props.color || 'transparent'};
  ${(props) =>
    props.color === 'white' &&
    `border: 1px solid ${theme.colors.colorMediumGray1};`}
  width: 14px;
  height: 12px;
  margin-right: 8px;
  border-radius: 2px;
  border: ${({ border }) => border || 'none'};
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

const renderTooltipContent = ({
  isReactTooltip,
  data,
  summary,
  showBorder,
  valueSuffix,
  className
}) => (
  <StyledTooltip
    className={
      className !== undefined ? `${className} box-shadow-tooltip` : className
    }
    isReactTooltip={isReactTooltip}
  >
    {data.map((item) => {
      // This is specific for Timesheet status typed
      if (['pto', 'holiday', TIMESHEET_STATUSES.REJECTED].includes(item.name))
        return null;
      return (
        <Row key={item.name}>
          <Label>
            <LegendBox
              color={item.color}
              border={showBorder && item.border ? item.border : 'none'}
            />
            <StyledLabel>{item.label}</StyledLabel>
          </Label>
          <ValueContainer>
            <StyledValue
              key={item.name}
              data-testid={`legend-tooltip-${item.label}-value`}
              marginRight={item.percentValue && '4px'}
            >
              {item.displayValue || item.value}
              {valueSuffix}
            </StyledValue>
            {item.percentValue !== undefined && '|'}
            {item.percentValue !== undefined && (
              <StyledValue
                className="percent-value"
                color={theme.colors.colorLightGray15}
                width={item.percentWidth}
                key={`${item.name}-percent`}
                data-testid={`legend-tooltip-${item.label}-percent`}
              >
                {item.percentValue}%
              </StyledValue>
            )}
          </ValueContainer>
        </Row>
      );
    })}

    {summary.length > 0 && (
      <>
        <StyledDivider />
        {summary.map((item) => (
          <Row key={item.name}>
            <Label>
              <LegendBox color={item.color} />
              <StyledLabel>{item.label}</StyledLabel>
            </Label>
            <ValueContainer>
              <StyledValue
                color={
                  item.name === 'overcapacity'
                    ? theme.colors.colorCalendarRed
                    : undefined
                }
                key={item.name}
                data-testid={`legend-tooltip-${item.label}-value`}
                marginRight={item.percentValue && '4px'}
              >
                {item.value}
                {valueSuffix}
              </StyledValue>
              {item.percentValue !== undefined && '|'}
              {item.percentValue !== undefined && (
                <StyledValue
                  className="percent-value"
                  color={theme.colors.colorLightGray15}
                  width={item.percentWidth}
                  key={`${item.name}-percent`}
                  data-testid={`legend-tooltip-${item.label}-percent`}
                >
                  {item.percentValue}%
                </StyledValue>
              )}
            </ValueContainer>
          </Row>
        ))}
      </>
    )}
  </StyledTooltip>
);

const renderTooltip = ({
  id,
  className,
  isReactTooltip,
  data,
  summary,
  showBorder,
  valueSuffix
}) => {
  if (isReactTooltip) {
    return (
      <ReactTooltip
        id={id}
        className={`${className} box-shadow-tooltip`}
        getContent={(dataTip) => {
          const {
            data = [],
            summary = [],
            showBorder,
            valueSuffix = 'h'
          } = JSON.parse(dataTip || '{}');
          return renderTooltipContent({
            isReactTooltip,
            data,
            summary,
            showBorder,
            valueSuffix
          });
        }}
        type="light"
      />
    );
  } else {
    return renderTooltipContent({
      isReactTooltip,
      data,
      summary,
      showBorder,
      valueSuffix,
      className
    });
  }
};

// Tooltip itself won't see whether tooltip content has data. Use 'data-tip-disable' with conditions
// Use legend style tooltip v2 that is incorporated with HTMLTable so you don't have to worry about column alignment problems.
const LegendStyleTooltip = ({
  className,
  id = 'legend-style-tooltip',
  isReactTooltip = true,
  data,
  summary,
  showBorder,
  valueSuffix = 'h'
}) => {
  return renderTooltip({
    id,
    className,
    isReactTooltip,
    data,
    summary,
    showBorder,
    valueSuffix
  });
};

export default LegendStyleTooltip;
