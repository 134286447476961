import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import keyBy from 'lodash/keyBy';
import { getSortedTeamMembers } from 'selectors';
import { RegionsState, initialState } from 'SettingsModule/reducers/regions';

const getRegionsState: Selector<RootState, RegionsState> = (state) =>
  state.regions || initialState;

export const getIsRegionsLoaded = createSelector(
  getRegionsState,
  (state) => state.isLoaded
);

export const getIsLoadingRegions = createSelector(
  getRegionsState,
  (state) => state.isLoading
);

export const getRegions = createSelector(
  getRegionsState,
  (state) => state.regionsHash
);

export const getRegionIdsArray = createSelector(getRegions, (regionHash) =>
  Object.keys(regionHash).map(Number)
);

export const getRegionsArray = createSelector(getRegions, (regions) =>
  Object.keys(regions)
    .sort()
    .flatMap((id) => regions[+id] ?? [])
);

export const getAlphabeticalRegionIds = createSelector(
  getRegionIdsArray,
  getRegions,
  (regionIds, regionHash) => {
    return regionIds.slice().sort((a, b) => {
      const aName = regionHash[a]?.name ?? '';
      const bName = regionHash[b]?.name ?? '';
      return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
    });
  }
);

export const getFormattedRegionsWithMembers = createSelector(
  getSortedTeamMembers,
  getRegionsArray,
  (members, regionsArray) => {
    const regionsWithHashMemberships = regionsArray.map((region) => ({
      ...region,
      regionMembersHash: keyBy(
        region.team_memberships,
        (membership) => membership.account_id
      )
    }));

    const formattedRegions = regionsWithHashMemberships.map((region) => {
      const formattedRegionMembers = members.filter(
        (member) => region.regionMembersHash[member.account.id]
      );

      return {
        ...region,
        formattedRegionMembers
      };
    });

    return formattedRegions;
  }
);

export const makeGetRegionsByIds = () =>
  createSelector(
    (state, { ids }: { ids: number[] }) => ids,
    getRegions,
    (ids, regionsHash) => ids.flatMap((id) => regionsHash[id] ?? [])
  );
