import { createRequestTypes } from 'appUtils';

export const DELETE_NOTE = createRequestTypes('DELETE_NOTE');
export const NOTE_UPDATE = createRequestTypes('NOTE_UPDATE');
export const NOTE_CREATION = createRequestTypes('NOTE_CREATION');
export const FETCH_NOTES_BY_PROJECT = createRequestTypes(
  'FETCH_NOTES_BY_PROJECT'
);
export const FETCH_NOTE = createRequestTypes('FETCH_NOTE');
export const FETCH_PROJECT_NOTES_V2 = createRequestTypes(
  'FETCH_PROJECT_NOTES_V2'
);
