import React, { ReactNode } from 'react';
import {
  PageContextProvider,
  IPageContextProviderProps
} from 'contexts/PageContextProvider';
import {
  FilterContextProvider,
  FilterContextProviderProps
} from 'FilterModule/FilterContextProvider';

export interface PageContainerProps {
  children?: ReactNode;
  pageContextProviderProps: IPageContextProviderProps;
  filterContextProviderProps?: FilterContextProviderProps;
}

/**
 * Use for filterable pages. Using filter context here is optional, but if you don't need it you can just
 * use PageContextProvider instead of PageContainer
 */
export const PageContainer = ({
  children,
  pageContextProviderProps,
  filterContextProviderProps
}: PageContainerProps) => {
  return filterContextProviderProps ? (
    <FilterContextProvider {...filterContextProviderProps}>
      <PageContextProvider {...pageContextProviderProps}>
        {children}
      </PageContextProvider>
    </FilterContextProvider>
  ) : (
    <PageContextProvider {...pageContextProviderProps}>
      {children}
    </PageContextProvider>
  );
};
