import React, { ReactNode, useContext, useRef, MutableRefObject } from 'react';

type IPageContext = {
  currentView: string;
  currentSubView?: string;
  isInModal?: boolean;
  /** hash of ref id to the referenced element  */
  refHash?: MutableRefObject<Record<string, ReactNode>>;
  pageName: string;
};

export interface IPageContextProviderProps {
  children?: ReactNode;
  currentView: string;
  currentSubView?: string;
  isInModal?: boolean;
  refIds?: Record<string, string>;
  pageName: string;
}

/* ------------------------------------ - ----------------------------------- */

export const PageContextProvider = ({
  children,
  currentView,
  currentSubView,
  isInModal,
  refIds,
  pageName
}: IPageContextProviderProps) => {
  // Used to store references to elements that are set to the ref id
  const refHash = useRef<Record<string, ReactNode>>(
    refIds
      ? Object.values(refIds).reduce((acc, refId) => {
          acc[refId] = null; // this will be set by the (ref) element
          return acc;
        }, {})
      : {}
  );

  const pageContextValues: IPageContext = {
    currentView,
    isInModal,
    refHash,
    pageName,
    currentSubView
  };

  return (
    <PageContext.Provider value={pageContextValues}>
      {children}
    </PageContext.Provider>
  );
};

/* ------------------------------------ - ----------------------------------- */

const PageContext = React.createContext({} as IPageContext);
PageContext.displayName = 'PageContext';
export const usePageContext = () => useContext(PageContext);
