import { useCallback, PropsWithChildren } from 'react';
import Popover from '.';
import { usePopperState } from 'appUtils/hooks/usePopperState';

export const usePopover = () => {
  const { isOpen, anchorElement, open, close } = usePopperState();

  const renderPopover = useCallback(
    ({ children, ...popoverProps }: PropsWithChildren<any>) => {
      if (!anchorElement) return null;
      return (
        <Popover
          isOpen={isOpen}
          target={anchorElement}
          closePopover={close}
          hideArrow
          {...popoverProps}
        >
          {children}
        </Popover>
      );
    },
    [anchorElement, close, isOpen]
  );

  return {
    Popover: renderPopover,
    openPopover: open,
    closePopover: close,
    isOpen
  };
};
