import { useMemo, useEffect } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { fetchTasksV2 } from 'actionCreators';
import ContentLoader from 'react-content-loader';
import { getTimerTaskArray, getIsLazyLoadingTasks } from 'selectors';
import EllipsisText from 'components/EllipsisText';

const EmptyState = styled.div`
  font-size: 14px;
  color: ${theme.colors.mediumGray9};
  margin-top: -35px;
  margin-left: -8px;
`;

const TimerTaskModal = ({ onSelect, handleClose, timer, target }) => {
  const byId = (item) => item.id;
  const dispatch = useDispatch();
  const taskCount = useSelector((state) => state.homeTasks.taskCount);
  const tasks = useSelector(getTimerTaskArray);
  const isLoading = useSelector(getIsLazyLoadingTasks);

  const noop = () => {};

  useEffect(() => {
    dispatch(
      fetchTasksV2({
        body: {
          scheduled_start: true,
          project_ids: [timer.project_id],
          phase_ids: [timer.phase_id],
          activity_phase_id: timer.activity_phase_id
            ? [timer.activity_phase_id]
            : undefined,
          all: true,
          archived: false,
          complete: false
        },
        taskListType: 'timer'
      })
    );
  }, [timer, dispatch]);

  const nonArchivedTasks = useMemo(() => {
    return tasks
      ? tasks.filter(
          (task) =>
            task &&
            !task.is_archived &&
            task.schedule_start &&
            !task.is_completed &&
            task.project_id === timer.project_id &&
            task.phase_id === timer.phase_id &&
            (!timer.activity_phase_id ||
              task.activity_phase_id === timer.activity_phase_id)
        )
      : [];
  }, [tasks, timer]);

  const loadingState = isLoading ? (
    <div style={{ width: '100%' }}>
      <ContentLoader
        height="340"
        primaryColor="#f3f3f3"
        secondaryColor="#eee"
        style={{ width: '100%', marginTop: '-20px' }}
        viewBox="0 0 400 340"
      >
        <rect x="0" y="5" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="70" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="135" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="200" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="265" rx="2" ry="2" width="100%" height="60" />
      </ContentLoader>
    </div>
  ) : (
    <EmptyState> No tasks for this check-in </EmptyState>
  );

  const getCopy = () => {
    return {
      headerInitial: 'Select Task',
      searchPlaceholder: 'Search or Select',
      emptyState: loadingState
    };
  };

  const renderItem = (props) => {
    const { item } = props;
    const key = item.id;
    const description = item.description;
    const date = `${item.schedule_start}
      ${item.schedule_end ? ' - ' + item.schedule_end : ''}`;
    const isValidStart = !!item.schedule_start;
    return (
      <StyledTaskContainer
        key={key}
        text={description}
        description={description}
        date={date}
      >
        <DescriptionContainer>
          <EllipsisText width={233}>{description}</EllipsisText>
        </DescriptionContainer>
        {isValidStart && <DateContainer>{date}</DateContainer>}
      </StyledTaskContainer>
    );
  };

  const renderHeader = () => {
    const copy = getCopy();
    return <div>{copy.headerInitial}</div>;
  };

  const handleSelect = (e, { item, selectCallback }) => {
    onSelect(item);
  };

  const getItemHeight = () => {
    return 58;
  };

  const itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: [
        'title',
        'description',
        'project_title',
        'project_description',
        'project_number',
        'client',
        'phaseTitles'
      ]
    });

  const itemHeight = 81;
  const offset = nonArchivedTasks.length;

  const hasNextPage = () => {
    return offset < taskCount;
  };

  return (
    <>
      <MultiStepFlyout
        copy={getCopy()}
        items={nonArchivedTasks}
        getItemId={byId}
        idKey="id"
        renderItem={renderItem}
        renderHeader={renderHeader}
        handleSelect={handleSelect}
        selectCallback={handleClose}
        handleSubmit={null}
        itemFilter={itemFilter}
        getItemHeight={getItemHeight}
        isWhite
        searchEnabled={true}
        editDisabled
        top={0}
        target={target}
        handleClose={handleClose}
        loadMoreItems={noop}
        loadInitialItems={noop}
        hasNextPage={hasNextPage}
        totalCount={taskCount}
        hideFooter={true}
        itemHeight={itemHeight}
        emptyContainerClassName="projects-then-phases-dropdown-empty-container"
        popoverClassName={'dependency-list'}
        listWidth="324"
        listHeight={280}
      />
    </>
  );
};

const StyledTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const DescriptionContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${theme.colors.colorMediumGray9};
`;
const DateContainer = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${theme.colors.colorCalendarGray};
`;

export default TimerTaskModal;
