import { useCallback, useMemo, useRef, useState, Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import round from 'lodash/round';
import LockSVGIcon from 'icons/LockIcon';
import OpenLockSVGIcon from 'icons/OpenLockIcon';
import GearIcon from 'images/tooltip-gear.svg';

import { NumberField } from './NumberField';
import { FlexContainer } from 'views/projectPlanner/workloadBarModal/styles';
import PartialDayIndicator from 'views/projectPlanner/PartialDayIndicator';
import ReactDOMServer from 'react-dom/server';
import { getConstantCapacity } from '../../utils';
import sum from 'lodash/sum';
import { ClearAllDayModal } from './ClearAllDayModal';
import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import cn from 'classnames';

const dailyHoursToolTip = `<div class='mt-13'> When locked, Hours/Day will not be <br/> modified when Work Plan dates change. <div class='horizontal-line-tooltip'/> <div class='lock-hour-tooltip-footer'> Set default from <img class='tooltip-gear-icon' src="${GearIcon}">Settings on this page </div> </div>`;
const totalHoursToolTip = `<div class='mt-13'> When locked, Total Hours will not be <br/> modified when Work Plan dates change.  <div class='horizontal-line-tooltip'/>  <div class='lock-hour-tooltip-footer'> Set default from <img class='tooltip-gear-icon' src="${GearIcon}">Settings on this page </div> </div>`;

export const BarForm = ({
  values: {
    all_day: allDay,
    total_hours: totalHours,
    daily_hours: dailyHours,
    lock_hour: lockHour,
    workday_percent: workdayPercent,
    datesArray,
    dailyCapacity,
    weeklyPlan
  },
  showErrors = {},
  handleChanges,
  handleFocus,
  handleBlur,
  onUpdateIsAllDay,
  disableAll,
  canEditWorkplan
}) => {
  const { isOverAllConstant, isWeeklyConstant, constantCapacities } = useMemo(
    () => getConstantCapacity({ datesArray, dailyCapacity }),
    [datesArray, dailyCapacity]
  );

  const [isOpenClearAllDayModal, setIsOpenClearAllDayModal] = useState(false);
  const clearAllDayModalTargetRef = useRef(null);

  const handleCloseClearAllDayModal = useCallback(() => {
    clearAllDayModalTargetRef.current = null;
    setIsOpenClearAllDayModal(false);
  }, []);

  const handleConfirmClearAllDay = useCallback(() => {
    onUpdateIsAllDay(false);

    clearAllDayModalTargetRef.current?.focus();
  }, [onUpdateIsAllDay]);

  const lastDayRemainder = round((+totalHours || 0) % (+dailyHours || 0), 2);

  const handleMouseDown = useMemo(
    () =>
      allDay
        ? (e) => {
            e.target === document.activeElement && e.target.blur();
            e.preventDefault();
            setIsOpenClearAllDayModal(true);
            clearAllDayModalTargetRef.current = e.target;
          }
        : undefined,
    [allDay]
  );

  const handleChange = useCallback(
    ({ floatValue }, { source, event }) => {
      if (source === 'event') {
        const key = event.target.name;
        handleChanges(key, { [key]: floatValue });
      }
    },
    [handleChanges]
  );

  const handleThisFocus = allDay ? handleMouseDown : handleFocus;

  const handleThisBlur = allDay
    ? undefined
    : (event) => {
        const key = event.target.name;

        // If the blured field is an unlocked field, force a recalculation of
        // its value, if possible.
        if (
          (!lockHour && key === 'total_hours') ||
          (lockHour && key !== 'total_hours')
        ) {
          const value = Number(event.target.value);
          const number = !value ? undefined : value;
          handleChanges(number === undefined ? 'reset' : key, {
            [key]: number
          });
        }

        handleBlur();
      };

  const handleHourLockChange = allDay
    ? handleMouseDown
    : () => handleChanges('lock_hour', { lock_hour: !lockHour });

  const workdayPercentTooltipContent = ReactDOMServer.renderToStaticMarkup(
    getTooltipContent({
      isOverAllConstant,
      isWeeklyConstant,
      constantCapacities
    })
  );

  const workdayPercentLabel = weeklyPlan
    ? getWeeklyWorkdayPercentLabel({
        isWeeklyConstant,
        constantCapacities
      })
    : getDailyWorkdayPercentLabel({
        constantHour: Object.values(constantCapacities)[0],
        isOverAllConstant
      });

  return (
    <StyledBarSelectContainer flex={1}>
      <FlexContainer flex={2}>
        <BarSelectWithIcon>
          <NumberField
            name="workday_percent"
            dataTestId="workday_percent"
            label={workdayPercentLabel}
            value={allDay ? '' : workdayPercent || ''}
            afterValue="%"
            decimalScale={2}
            onFocus={handleThisFocus}
            onBlur={handleThisBlur}
            onValueChange={handleChange}
            onMouseDown={handleMouseDown}
            disabled={disableAll}
            showError={showErrors.workdayPercent}
            labelTooltipContent={workdayPercentTooltipContent}
            cellTooltipContent={!canEditWorkplan ? EDIT_WORK_PLANS_TIP : ''}
            showWarning={Number(allDay ? '' : workdayPercent) > 100}
            roundedBorder={{ left: true }}
          />
        </BarSelectWithIcon>
        <BarSelectWithIcon>
          <NumberField
            name="daily_hours"
            dataTestId="workplan-daily-hours"
            label="Hrs/day"
            value={allDay ? '' : dailyHours || ''}
            afterValue="h"
            decimalScale={2}
            onFocus={handleThisFocus}
            onBlur={handleThisBlur}
            onValueChange={handleChange}
            onMouseDown={handleMouseDown}
            disabled={disableAll}
            showError={showErrors.dailyHours}
            cellTooltipContent={!canEditWorkplan ? EDIT_WORK_PLANS_TIP : ''}
            endAdornment={
              <StyledLockIconContainer
                className={cn({ disabled: allDay || disableAll })}
                data-for="app-tooltip"
                data-html
                data-effect="solid"
                data-class="center"
                data-tip={dailyHoursToolTip}
                onClick={canEditWorkplan ? handleHourLockChange : undefined}
              >
                <LockIcon locked={!lockHour} />
              </StyledLockIconContainer>
            }
          />

          {!!lastDayRemainder && (
            <StyledPartialDayIndicator
              isBold={true}
              remainder={lastDayRemainder}
              fontSize="18px"
            />
          )}
        </BarSelectWithIcon>
      </FlexContainer>
      <BarSelectWithIcon>
        <NumberField
          name="total_hours"
          dataTestId="workplan-total-hours"
          label="total hrs"
          value={allDay ? '' : totalHours || ''}
          afterValue="h"
          decimalScale={2}
          onFocus={handleThisFocus}
          onBlur={handleThisBlur}
          onValueChange={handleChange}
          onMouseDown={handleMouseDown}
          disabled={disableAll}
          showError={showErrors.totalHours}
          roundedBorder={{ right: true }}
          cellTooltipContent={!canEditWorkplan ? EDIT_WORK_PLANS_TIP : ''}
          endAdornment={
            <StyledLockIconContainer
              className={cn({ disabled: allDay || disableAll })}
              data-for="app-tooltip"
              data-html
              data-effect="solid"
              data-class="center"
              data-tip={totalHoursToolTip}
              onClick={canEditWorkplan ? handleHourLockChange : undefined}
            >
              <LockIcon locked={lockHour} />
            </StyledLockIconContainer>
          }
        />
      </BarSelectWithIcon>
      <ClearAllDayModal
        isOpen={isOpenClearAllDayModal}
        toggle={handleCloseClearAllDayModal}
        onConfirm={handleConfirmClearAllDay}
      />
    </StyledBarSelectContainer>
  );
};

const getWeeklyWorkdayPercentLabel = ({
  isWeeklyConstant,
  constantCapacities
}) => {
  if (isWeeklyConstant && constantCapacities) {
    return `% ${sum(Object.values(constantCapacities))}HR WEEK`;
  }
  return '% WEEK';
};

const getDailyWorkdayPercentLabel = ({ constantHour, isOverAllConstant }) => {
  if (isOverAllConstant && constantHour) {
    return `% ${constantHour}HR WORKDAY`;
  }
  return '% WORKDAY';
};

const getTooltipContent = ({
  isOverAllConstant,
  isWeeklyConstant,
  constantCapacities
}) => {
  if (isOverAllConstant) {
    return '';
  }
  if (isWeeklyConstant && constantCapacities) {
    return (
      <TooltipContainer>
        <div>Workday Varies</div>
        <TooltipGrid>
          {Object.entries(constantCapacities).map(([key, val]) => (
            <Fragment key={key}>
              <DateAbbrCell>{key.substr(0, 3)}</DateAbbrCell>
              <HourAbbrCell>{val}h</HourAbbrCell>
            </Fragment>
          ))}
        </TooltipGrid>
      </TooltipContainer>
    );
  }
  return (
    <div>
      <div>Workday Varies</div>
      <div>See Work Schedule On Profile</div>
    </div>
  );
};

const LockIcon = ({ locked }) =>
  locked ? (
    <LockSVGIcon fill={theme.colors.colorMediumGray9} width="16" height="16" />
  ) : (
    <OpenLockSVGIcon width="18" height="18" />
  );

const StyledLockIconContainer = styled.div`
  &:hover {
    background-color: ${theme.colors.colorLightGray22};
  }

  cursor: pointer;
  height: 36px;
  width: 34px;
  padding-left: 2px;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    cursor: not-allowed;
  }
`;

const StyledPartialDayIndicator = styled(PartialDayIndicator)`
  position: absolute;
  right: 24px;
  top: 27px;
`;

const BarSelectWithIcon = styled.div`
  position: relative;
  flex: 1 0 0;
`;

const StyledBarSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  ${FlexContainer} {
    gap: 4px;
  }
`;

const TooltipContainer = styled.div``;
const TooltipGrid = styled.div`
  padding-left: 8px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 8px;
  grid-auto-rows: 1fr;
`;

const DateAbbrCell = styled.span`
  text-transform: capitalize;
`;
const HourAbbrCell = styled.span``;
