import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { portal } from 'appUtils/portal';
import {
  StyledTaskColumnDraggable,
  StyledTaskMilestoneIcon,
  StyledDragGripIcon,
  LeftDropdownItemContent,
  RightDropdownItemContent,
  TaskColumnIconContainer
} from 'views/Home/TaskList/styles';

import NoteIcon from 'icons/NoteIcon';
import TaskIcon from 'icons/TaskIcon';
import UpdatesIcon from 'icons/UpdatesIcon';
import MembersIcon from 'icons/MembersIcon';
import ClientIcon from 'icons/ClientIcon';
import TaskStatusIcon from 'icons/TaskStatusIcon';
import PriorityIcon from 'icons/PriorityIcon';
import StageIcon from 'icons/StageIcon';
import ProjectNumberIcon from 'icons/ProjectNumberIcon';
class ProjectColumnDraggable extends Component {
  getSortContent = (taskProperty) => {
    switch (taskProperty) {
      case 1:
      case '1':
        return {
          text: 'Tasks',
          icon: <TaskIcon height="13" width="13" color="#808080" />
        };
      case '2':
        return {
          text: 'Notes',
          icon: <NoteIcon height="13" width="13" color="#808080" />
        };
      case '3':
        return {
          text: 'Schedule',
          icon: <TaskIcon height="13" width="13" color="#808080" />
        };
      case '6':
        return {
          text: 'Budget',
          icon: <ClientIcon height="14" width="14" />
        };
      case '7':
        return {
          text: 'Info',
          icon: ''
        };
      case '8':
        return {
          text: 'Scope',
          icon: ''
        };
      default:
        return {
          text: '',
          icon: ''
        };
    }
  };

  handleClick = (e) => {
    const { handleClick, index } = this.props;
    handleClick(index);
  };

  render() {
    const { index, column, provided, isActive, snapshot = {} } = this.props;
    const { icon, text } = this.getSortContent(column);
    const usePortal = snapshot.isDragging;
    const child = (
      <StyledTaskColumnDraggable
        index={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isActive={isActive}
        onClick={this.handleClick}
      >
        <LeftDropdownItemContent>
          {isActive ? <StyledDragGripIcon /> : ''}

          <TaskColumnIconContainer>{icon}</TaskColumnIconContainer>
          <div className="task-column-text">{text}</div>
        </LeftDropdownItemContent>
        <RightDropdownItemContent isActive={isActive}>
          {isActive ? (
            <div data-testid={`hide-column-${text}`}>Hide</div>
          ) : (
            <div data-testid={`show-column-${text}`}>Show</div>
          )}
        </RightDropdownItemContent>
      </StyledTaskColumnDraggable>
    );
    return usePortal ? ReactDOM.createPortal(child, portal) : child;
  }
}

export default ProjectColumnDraggable;
