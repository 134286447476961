import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface GoToProjectIconProps extends SvgIconProps {
  color?: string;
}

/**
 * @deprecated Use tightly cropped version from `./GoToIcon.tsx`.
 */
const GoToProjectIcon = ({
  color = '#0074d9',
  className,
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  ...rest
}: GoToProjectIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0835 13.1664V16.0834C16.0835 16.7277 15.5612 17.2499 14.9165 17.2499H7.91648C7.27226 17.2499 6.75 16.7277 6.75 16.0834V9.08345C6.75 8.43868 7.27227 7.91642 7.91648 7.91642H10.8335V9.08345H7.91648V16.0834H14.9165V13.1664H16.0835ZM16.0819 8.74171L11.8271 12.9959L11.0024 12.1707L15.2566 7.91648H12.5819V6.75H17.2484V11.4165H16.0819L16.0819 8.74171Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoToProjectIcon;
