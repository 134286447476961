import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { ValueOf } from 'type-fest';
import { navigateToMemberModal as navigateAction } from 'actionCreators';
import { getTeamSlug } from 'selectors';
import {
  Sections,
  MemberSpaceView,
  MEMBER_VIEW_TYPE
} from 'appConstants/navigation';

export const useGoToMemberModal = () => {
  const dispatch = useAppDispatch();
  const teamSlug = useAppSelector(getTeamSlug);

  const navigateToMemberModal = useCallback(
    ({
      accountId,
      tab,
      openInNewWindow,
      openInMemberSpace,
      queryParams
    }: {
      accountId: number;
      tab: ValueOf<typeof MEMBER_VIEW_TYPE>;
      openInNewWindow?: boolean;
      openInMemberSpace?: boolean;
      queryParams?: { [key: string]: unknown };
    }) => {
      dispatch(
        navigateAction({
          memberId: accountId,
          memberViewType: tab,
          openInNewWindow,
          ...(openInMemberSpace && {
            teamSlug,
            section: Sections.MEMBERS,
            membersViewType: MemberSpaceView.members
          }),
          ...(queryParams && { queryParams })
        })
      );
    },
    [dispatch, teamSlug]
  );

  return { navigateToMemberModal };
};
