import makeReducerMap, { reducerMap } from '../reducers';
import { makeIntegrationSaga, integrationsSaga } from '../sagas';

// Target service specific redux
export function getIntegrationModule(integration) {
  return {
    id: `test-test/${integration}`,
    reducerMap: makeReducerMap(integration),
    sagas: [makeIntegrationSaga(integration)]
    // Actions to fire when this module is added/removed
    // initialActions: []
    // finalActions: []
  };
}

// Global integrations redux
export function getIntegrationsModule() {
  return {
    id: 'integrations',
    reducerMap,
    sagas: [integrationsSaga]
    // Actions to fire when this module is added/removed
    // initialActions: []
    // finalActions: []
  };
}
