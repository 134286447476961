import {
  currencyFormatter,
  useCurrencyFormatterByProject,
  useCurrencyFormatterByTeam,
  CurrencyFormatterProps,
  UseCurrencyFormatterByProjectProps,
  UseCurrencyFormatterByTeamProps
} from 'appUtils/hooks/useFormatCurrency';

import { formatCurrencyValue } from './utils';

export interface FormattedCurrencyByProjectProps {
  currencyFormatterProps: UseCurrencyFormatterByProjectProps;
  currencyValue: number;
  currencyFormatMethod?: (
    currencyFormatter: Intl.NumberFormat,
    currencyValue: number
  ) => string;
}
export interface FormattedCurrencyByTeamProps {
  currencyFormatterProps?: UseCurrencyFormatterByTeamProps;
  currencyValue: number;
  currencyFormatMethod?: (
    currencyFormatter: Intl.NumberFormat,
    currencyValue: number
  ) => string;
}
export interface CustomFormattedCurrencyProps {
  currencyFormatterProps: CurrencyFormatterProps;
  currencyValue: number;
  currencyFormatMethod?: (
    currencyFormatter: Intl.NumberFormat,
    currencyValue: number
  ) => string;
}

// wrapper components to allow class components to use useFormatCurrency hook
export const FormattedCurrencyByProject = ({
  currencyFormatterProps,
  currencyValue,
  currencyFormatMethod = formatCurrencyValue
}: FormattedCurrencyByProjectProps): JSX.Element => {
  const currencyFormatter = useCurrencyFormatterByProject({
    ...currencyFormatterProps
  });

  return (
    <>
      {currencyFormatter &&
        currencyFormatMethod(currencyFormatter, currencyValue)}
    </>
  );
};

export const FormattedCurrencyByTeam = ({
  currencyFormatterProps = {},
  currencyValue,
  currencyFormatMethod = formatCurrencyValue
}: FormattedCurrencyByTeamProps): JSX.Element => {
  const currencyFormatter = useCurrencyFormatterByTeam({
    ...currencyFormatterProps
  });

  return (
    <>
      {currencyFormatter &&
        currencyFormatMethod(currencyFormatter, currencyValue)}
    </>
  );
};

export const CustomFormattedCurrency = ({
  currencyFormatterProps,
  currencyValue,
  currencyFormatMethod = formatCurrencyValue
}: CustomFormattedCurrencyProps): JSX.Element => {
  const { currencyCode } = currencyFormatterProps;

  const customCurrencyFormatter =
    currencyCode &&
    currencyFormatter({
      ...currencyFormatterProps
    });

  return (
    <>
      {customCurrencyFormatter &&
        currencyFormatMethod(customCurrencyFormatter, currencyValue)}
    </>
  );
};
