import { useEffect, useMemo } from 'react';
import { DATE_FNS_USA_DATE } from 'appConstants/date';
import { lightFormat } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { fetchUtilizations } from 'UtilizationModule/actionCreators';
import { DateRange } from 'views/projectPlanner/DateRangeManager/DateRange';
import { useDateRangeManager } from './useDateRangeManager';
import { BaseUseDataHookOptions } from './types';
import { makeGetUtilizationsBreakdownByMember } from 'UtilizationModule/selectors';
import { AccountId } from 'models/account';
import { UtilizationBreakdownsByDate } from 'UtilizationModule/types';
import useFetchCapacitiesAndHolidays from 'appUtils/hooks/useFetchCapacitiesAndHolidays';

interface UseFetchUtilizationsBreakdownProps extends BaseUseDataHookOptions {
  accountIds: number[];
  includeTentative: boolean;
  startDate: Date;
  endDate: Date;
}
export const useFetchUtilizationBreakdown = ({
  accountIds,
  includeTentative,
  startDate,
  endDate,
  isReady = true
}: UseFetchUtilizationsBreakdownProps) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const { getOutOfRangeIds, resetRangeManager } = useDateRangeManager();

  useEffect(() => {
    resetRangeManager();
  }, [includeTentative, resetRangeManager]);

  useEffect(() => {
    if (teamId && accountIds.length && isReady) {
      const idsToFetch = getOutOfRangeIds({
        ids: accountIds,
        dateRange: new DateRange({
          start: startDate,
          end: endDate
        })
      });
      if (idsToFetch.length) {
        dispatch(
          fetchUtilizations({
            startDate: lightFormat(startDate, DATE_FNS_USA_DATE),
            endDate: lightFormat(endDate, DATE_FNS_USA_DATE),
            accountIds,
            teamId,
            includeTentative
          })
        );
      }
    }
  }, [
    accountIds,
    endDate,
    startDate,
    teamId,
    includeTentative,
    isReady,
    getOutOfRangeIds,
    dispatch
  ]);

  useFetchCapacitiesAndHolidays({
    accountIds
  });

  const getUtilizationsBreakdownByMember = useMemo(
    makeGetUtilizationsBreakdownByMember,
    []
  );

  const utilizationsBreakdownByMember: Record<
    AccountId,
    UtilizationBreakdownsByDate
  > = useAppSelector((state) =>
    getUtilizationsBreakdownByMember(state, { accountIds })
  );

  return {
    utilizationsBreakdownByMember
  };
};
