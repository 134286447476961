// import * as constants from 'appConstants';

export const initialState = {
  // billingInfo: {},
  // isLoaded: false,
  // isLoading: false,
  recurlyBeganDate: 'Fri Sep 24 2021 00:00:00 GMT-0400 (Eastern Daylight Time)'
};

const recurly = (state = initialState, action) => {
  switch (action.type) {
    // case constants.SET_RECURLY_BILLING_INFO.SUCCESS: {
    //   return state;
    // }
    // case constants.FETCH_RECURLY_BILLING_INFO.TRIGGER: {
    //   return {
    //     ...state,
    //     isLoading: true
    //   };
    // }
    // case constants.FETCH_RECURLY_BILLING_INFO.SUCCESS: {
    //   return {
    //     ...state,
    //     billingInfo: action.payload.response.billing_info,
    //     isLoaded: true,
    //     isLoading: false
    //   };
    // }
    default:
      return state;
  }
};

export default recurly;
