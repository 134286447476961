import { useDispatch, useSelector } from 'react-redux';
import { getActiveTimer } from 'selectors';
import { openTimerDrawer } from 'actionCreators';
import styled from 'styled-components';
import ClockIcon from 'icons/ClockIcon';
import { ReactComponent as PlayButton } from 'icons/playtimer.svg';
import theme from 'theme';

const TimerToggle = () => {
  const dispatch = useDispatch();
  const activeTimer = useSelector(getActiveTimer);
  const handleClick = () => dispatch(openTimerDrawer());

  return (
    <>
      <StyledClockIcon onClick={handleClick} isActive={!!activeTimer}>
        {activeTimer ? <PlayButton /> : <ClockIcon />}
      </StyledClockIcon>
    </>
  );
};

/* -------------------Styling--------------- */

const StyledClockIcon = styled.li`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: solid 1px transparent;
  pointer-events: initial;
  cursor: pointer;
  position: relative;
  transition: all 0.275s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: solid 1px transparent;
    background-color: rgba(0, 0, 0, 0.03);

    path {
      stroke: ${(props) => !props.isActive && theme.colors.colorRoyalBlue};
    }
  }
`;

export default TimerToggle;
