import { useRef, ReactNode } from 'react';
import styled from 'styled-components';
import OrganizationSettingsDropdown from './OrganizationSettingsDropdown';
import { usePopover } from 'components/Popover/usePopover';
import cn from 'classnames';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';

type PopoverOptions = Record<string, PopoverItem>;

interface PopoverItem {
  label: ReactNode;
  item?: ReactNode;
}

type OrganizationSettingsPopoverProps = {
  options: PopoverOptions;
  currentlySelectedKey: keyof PopoverOptions | undefined;
  showEllipsisTooltip?: boolean;
  onClick?: ({ key }: { key: string }) => void;
};

export const OrganizationSettingsPopover = ({
  options,
  currentlySelectedKey,
  showEllipsisTooltip,
  onClick
}: OrganizationSettingsPopoverProps) => {
  const { Popover, openPopover, closePopover, isOpen } = usePopover();

  const popoverRef = useRef(null);

  const handleToggle = () => {
    if (isOpen) {
      closePopover();
    } else if (popoverRef?.current) {
      openPopover({ target: popoverRef.current });
    }
  };

  const handleSelect = (optionKey: keyof PopoverOptions) => {
    if (onClick) onClick({ key: optionKey });
    closePopover();
    ReactTooltip.hide();
  };

  return (
    <OrganizationSettingsPopoverContainer
      ref={popoverRef}
      onClick={handleToggle}
    >
      <OrganizationSettingsDropdown
        label={
          currentlySelectedKey ? options[currentlySelectedKey]?.label ?? '' : ''
        }
        showEllipsisTooltip={showEllipsisTooltip}
      />
      <Popover>
        {Object.keys(options).map((optionKey) => (
          <PopoverItem
            key={optionKey}
            className={cn({ selected: optionKey === currentlySelectedKey })}
            onClick={() => handleSelect(optionKey)}
          >
            {options[optionKey]?.item ?? options[optionKey]?.label}
          </PopoverItem>
        ))}
      </Popover>
    </OrganizationSettingsPopoverContainer>
  );
};

const OrganizationSettingsPopoverContainer = styled.div``;

const PopoverItem = styled.div`
  cursor: pointer;

  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }

  &.selected {
    font-weight: 600;
  }
`;
