import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import * as constants from 'EventsModule/constants';
import {
  FetchGoogleCalendarEventsRequestPayload,
  FetchGoogleCalendarEventsSuccessResponse,
  UpdateGoogleCalendarEventsRequestPayload,
  UpdateGoogleCalendarEventsSuccessResponse
} from 'EventsModule/types';

/* ---------------------------------- Fetch --------------------------------- */

export const fetchGoogleCalendarEvents = createAction(
  constants.FETCH_GOOGLE_CALENDAR_EVENTS.TRIGGER,
  ({ meta, ...payload }: FetchGoogleCalendarEventsRequestPayload) => ({
    payload,
    meta
  })
);

export const fetchGoogleCalendarEventsActionCreatorsMap =
  createActionCreatorsMap<
    FetchGoogleCalendarEventsRequestPayload,
    FetchGoogleCalendarEventsSuccessResponse
  >(constants.FETCH_GOOGLE_CALENDAR_EVENTS);

/* --------------------------------- Update --------------------------------- */

export const updateGoogleCalendarEvents =
  createAction<UpdateGoogleCalendarEventsRequestPayload>(
    constants.UPDATE_GOOGLE_CALENDAR_EVENTS.TRIGGER
  );

export const updateGoogleCalendarEventsActionCreatorsMap =
  createActionCreatorsMap<
    UpdateGoogleCalendarEventsRequestPayload,
    UpdateGoogleCalendarEventsSuccessResponse
  >(constants.UPDATE_GOOGLE_CALENDAR_EVENTS);
