import React from 'react';
import { TaskPropertyCell, EstimatedInput, NewTaskProperty } from './styles';
import cn from 'classnames';

class EstimatedHours extends React.PureComponent {
  state = {
    hours: this.props.hours || null,
    isFocused: false
  };

  input = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    const beganEditing =
      // Controlled component case
      (!prevProps.isEditing && this.props.isEditing) ||
      // Local case
      (!prevState.isFocused && this.state.isFocused);

    const stoppedEditing = prevProps.isEditing && !this.props.isEditing;

    // Since `stoppedEditing` only applies to controlled components, we check it
    // first.
    if (stoppedEditing) {
      this.setState({ isFocused: false });
      this.handleSubmit();
    } else if (beganEditing && this.input.current) {
      this.setState({ isFocused: true });
      this.input.current.focus();
    }
  }

  checkEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      this.props.handleTaskEditClick(null);
    }
  };

  handleChange = (values) => {
    const { onSubmit, isNewTask } = this.props;
    const hours = values.floatValue || null;
    this.setState({ hours });
    isNewTask && onSubmit(hours?.toString() || '');
  };

  handleSubmit = () => {
    this.input.current?.blur();
    this.changeEntry();
  };

  changeEntry = () => {
    const hoursChanged = this.state.hours !== this.props.hours;
    const shouldStayBlank =
      this.state.hours === '' && this.props.hours === null;
    if (hoursChanged && !shouldStayBlank) {
      this.props.onSubmit(this.state.hours?.toString() || '');
    }
  };

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = (e) => {
    const hours = this.state.hours;
    if (this.props.handleBlur) {
      e.preventDefault();
      if (this.props.taskIsEditing) {
        this.props.handleBlur();
      }
    }

    if (!this.props.isNewTask) {
      return;
    }

    if (hours !== undefined) {
      this.handleSubmit();
    } else {
      this.setState({ isFocused: false });
    }
  };

  render() {
    const { taskIsEditing, isNewTask } = this.props;
    const { hours, isFocused } = this.state;
    const hasValue = !!hours;

    return (
      <TaskPropertyCell estimatedHours>
        <NewTaskProperty
          $showValue={hasValue || isFocused || isNewTask}
          $taskIsEditing={taskIsEditing || isFocused}
        >
          <EstimatedInput
            allowEmptyFormatting
            allowLeadingZeros={false}
            allowNegative={false}
            autoComplete="off"
            className={cn({ taskIsEditing, hasValue })}
            decimalScale={2}
            getInputRef={this.input}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onKeyPress={this.checkEnterPress}
            onValueChange={this.handleChange}
            placeholder={isFocused ? '0' : 'Time est.'}
            suffix={(isFocused || hours) && ' h'}
            thousandSeparator={true}
            type="text"
            value={hasValue && hours}
          />
        </NewTaskProperty>
      </TaskPropertyCell>
    );
  }
}

export default EstimatedHours;
