import { useState, useRef, useCallback } from 'react';

/**
 * Hook for setting a boolean ref value. State is also set to ensure re-rendering
 */
const useImmediateBoolean = (startValue: boolean) => {
  const [_, setBoolState] = useState<boolean>(startValue);
  const boolRef = useRef<boolean>(startValue);

  const setBool = useCallback((value: boolean): void => {
    boolRef.current = value;
    setBoolState(value);
  }, []);

  return { setBool, bool: boolRef.current };
};

export default useImmediateBoolean;
