import { getAuthToken } from 'AuthenticationModule/selectors';
import {
  fetchBoardMembers,
  fetchProjectTeam as fetchProjectMembers,
  fetchTeamMembers
} from 'actionCreators';
import { put, select } from 'redux-saga/effects';
import { changeEntity as entityHelper } from 'sagas/generics';
import {
  fetchTeamPermissions,
  fetchTeamPermissionsActionCreatorsMap,
  updateTeamPermissions,
  updateTeamPermissionsActionCreatorsMap,
  updateTeamMembersPermission,
  updateTeamMembersPermissionActionCreatorsMap,
  bulkInviteAccounts,
  bulkInviteAccountsActionCreatorsMap
} from './actionCreators';
import { permissionsApi } from './api';
import { permissionConstants } from './constants';
const { ACTABLE_TYPES } = permissionConstants;

function* updateTeamMembersPermissionWorker(
  action: ReturnType<typeof updateTeamMembersPermission>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    updateTeamMembersPermissionActionCreatorsMap,
    permissionsApi.updateTeamMembersPermissions,
    [token, action.payload],
    action
  );
}

// *********************** FETCH TEAM PERMISSIONS *************************

function* fetchTeamPermissionsWorker(
  action: ReturnType<typeof fetchTeamPermissions>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchTeamPermissionsActionCreatorsMap,
    permissionsApi.fetchTeamPermissions,
    [token, action.payload],
    action
  );
}

// *********************** UPDATE TEAM PERMISSIONS *************************

function* updateTeamPermissionsWorker(
  action: ReturnType<typeof updateTeamPermissions>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    updateTeamPermissionsActionCreatorsMap,
    permissionsApi.updateTeamPermissions,
    [token, action.payload],
    action
  );
}

// *********************** BULK INVITE ACCOUNTS *************************

function* bulkInviteAccountsWorker(
  action: ReturnType<typeof bulkInviteAccounts>
) {
  const { payload } = action;
  const { actableType, actableId } = payload;
  const token = yield select(getAuthToken);

  const { response } = yield entityHelper(
    bulkInviteAccountsActionCreatorsMap,
    permissionsApi.bulkInviteAccounts,
    [token, payload],
    action
  );

  if (response) {
    yield put(fetchTeamMembers());

    if (actableType && actableId) {
      if (actableType === ACTABLE_TYPES.PROJECT) {
        yield put(fetchProjectMembers(actableId));
      } else if (actableType === ACTABLE_TYPES.BOARD) {
        yield put(fetchBoardMembers(actableId));
      }
    }
  }
}

export default {
  updateTeamMembersPermissionWorker,
  fetchTeamPermissionsWorker,
  updateTeamPermissionsWorker,
  bulkInviteAccountsWorker
};
