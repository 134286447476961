import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { getSelectedTeamId } from 'selectors';
import { fetchLocationsByTeamMembership } from 'SettingsModule/actionCreators/settings/location';
import { LocationsTable } from './LocationsTable';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';

export const LocationsTableContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchLocationsByTeamMembership({ teamId }));
      dispatch(fetchOffices({ teamId }));
    }
  }, [dispatch, teamId]);

  return <LocationsTable />;
};
