import { useSelector } from 'react-redux';
import { getProjectHash, getFlatPhases } from 'selectors';
import styled from 'styled-components';
import { MAIN_PHASE_NAME } from 'appConstants';
import theme from 'theme';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import FolderIcon from 'icons/FolderIcon';
import { useBoards } from 'appUtils/hooks/useBoards';

const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 4px;
  width: 10px;
  height: 10px;
  path {
    stroke: #808080;
    stroke-width: 3;
    fill: transparent;
  }
`;

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`;

const BoardText = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorCalendarGray};
  min-height: 13px;
  padding-right: 5px;
`;

const ProjectNameRow = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 215px;
`;

const ProjectDot = styled.div`
  background: ${calculateProjectColorPreference};
  min-height: 9px;
  min-width: 9px;
  border-radius: 90px;
  margin-right: 5px;
`;

const ProjectName = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
`;

const ProjectDash = styled.div`
  margin: 0px 3px;
`;

const DescriptionText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
`;

const PhaseText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PhaseNameRow = styled.div`
  display: flex;
  align-items: center;
`;

const PhaseIconContainer = styled.div`
  margin-top: -4px;
  margin-left: -2px;
  margin-right: 5px;
  position: relative;
  top: 2px;
`;

const BoardRow = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectCell = ({ row }) => {
  const { getBoardLabel } = useBoards();
  const projectHash = useSelector(getProjectHash);
  const phaseHash = useSelector(getFlatPhases);
  const { userActivity } = row.original;
  const projectName = userActivity?.display_data?.project_title;
  const projectDescription = userActivity?.display_data?.project_description;
  const projectId = userActivity?.display_data?.project_id;

  const boardId = projectHash[projectId]?.board_id;
  const boardName = getBoardLabel(boardId);

  const phaseIsDefault = userActivity?.display_data?.is_default_phase;
  const phaseName = userActivity?.display_data?.phase_name;

  return (
    <CellContainer>
      {boardName && (
        <BoardRow
          data-for="app-tooltip"
          data-tip={boardName}
          data-effect="solid"
          data-class="center"
        >
          <StyledFolderIcon />
          <BoardText className="no-text-overflow"> {boardName} </BoardText>
        </BoardRow>
      )}
      {projectName && (
        <ProjectNameRow>
          <ProjectDot id={projectId} />
          <ProjectName> {projectName} </ProjectName>
          {!!projectDescription && (
            <>
              <ProjectDash> - </ProjectDash>
              <DescriptionText> {projectDescription} </DescriptionText>
            </>
          )}
        </ProjectNameRow>
      )}
      {phaseName &&
        (!phaseHash[userActivity?.display_data.phase_id]?.is_like_default ||
          (!phaseIsDefault && phaseName !== MAIN_PHASE_NAME)) && (
          <PhaseNameRow
            data-for="app-tooltip"
            data-tip={phaseName}
            data-effect="solid"
            data-class="center"
          >
            <PhaseIconContainer>
              <PhaseMilestoneIcon height={12} width={12} fillColor={'none'} />
            </PhaseIconContainer>
            <PhaseText className="no-text-overflow">{phaseName}</PhaseText>
          </PhaseNameRow>
        )}
    </CellContainer>
  );
};

export default ProjectCell;
