import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProjectHash,
  getPhasesByProjectHash,
  getIsFetchingProjects,
  getIsLoadingPhases,
  getFetchedProjectIds,
  getFetchedPhasesProjectIdsHash
} from 'selectors';
import { fetchAllProjects, fetchPhasesByProjectIds } from 'actionCreators';
import { RequestPriority } from 'appConstants/api';

const emptyArray = [];

/**
 * Checks if given list of project IDs have been fetched for
 * and will fetch the ones that haven't. Will also fetch for their phases
 * unless shouldFetchPhases === false
 */
const useFetchUnloadedProjects = ({
  projectIds = emptyArray,
  shouldFetchPhases = true,
  requestPriority = RequestPriority.High
}) => {
  const dispatch = useDispatch();
  const projectHash = useSelector(getProjectHash);
  const phasesByProjectHash = useSelector(getPhasesByProjectHash);
  const fetchedProjectIds = useSelector(getFetchedProjectIds);
  const fetchedPhasesProjectIds = useSelector(getFetchedPhasesProjectIdsHash);
  const isFetchingPhases = useSelector(getIsLoadingPhases);
  const isFetchingProjects = useSelector(getIsFetchingProjects);
  const projectIdsWithNoDuplicates = useMemo(
    () => [...new Set(projectIds)].filter((id) => id),
    [projectIds]
  );

  // projects that haven't been fetched yet
  const unloadedProjectIds = useMemo(
    () =>
      projectIdsWithNoDuplicates.filter(
        (id) => !(fetchedProjectIds[id] || projectHash[id])
      ), // checks if the call made for the id or already in hash
    [fetchedProjectIds, projectHash, projectIdsWithNoDuplicates]
  );

  // projects that don't have their phases fetched
  const unloadedPhasesProjectIds = useMemo(
    () =>
      shouldFetchPhases
        ? projectIdsWithNoDuplicates.filter(
            (id) => !(phasesByProjectHash[id] || fetchedPhasesProjectIds[id])
          )
        : [],

    [
      fetchedPhasesProjectIds,
      phasesByProjectHash,
      projectIdsWithNoDuplicates,
      shouldFetchPhases
    ]
  );

  useEffect(() => {
    if (unloadedProjectIds.length || unloadedPhasesProjectIds.length) {
      const meta = { requestPriority };
      if (unloadedProjectIds.length && !isFetchingProjects) {
        dispatch(
          fetchAllProjects({ projectIds: unloadedProjectIds, all: true, meta })
        );
      }
      if (
        unloadedPhasesProjectIds.length &&
        shouldFetchPhases &&
        !isFetchingPhases
      ) {
        dispatch(
          fetchPhasesByProjectIds({
            projectIds: unloadedPhasesProjectIds,
            meta
          })
        );
      }
    }
  }, [
    dispatch,
    isFetchingPhases,
    isFetchingProjects,
    shouldFetchPhases,
    unloadedProjectIds,
    requestPriority,
    unloadedPhasesProjectIds
  ]);

  return { isFetchingPhases, isFetchingProjects };
};

export default useFetchUnloadedProjects;
