import difference from 'lodash/difference';
import * as constants from 'appConstants';
import { buildAccessIdentifier } from 'appUtils/access';

const { FETCH_ACCESS, UPDATE_ACCESS, OPEN_ACCESS_MODAL, CLOSE_ACCESS_MODAL } =
  constants;

// This initial state should be populated with some 'valid' data for demo purposes
export const initialState = {
  access: {},
  isOpen: false,
  modalIdentifier: null
};

const access = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_ACCESS.SUCCESS: {
      const accessIdentifier = buildAccessIdentifier(payload.requestPayload);
      return {
        ...state,
        access: {
          ...state.access,
          [accessIdentifier]: payload.response
        }
      };
    }
    case UPDATE_ACCESS.TRIGGER: {
      const accessIdentifier = buildAccessIdentifier(payload);
      const {
        addableAccessBuckets = [],
        removableAccessBuckets = [],
        accountIds
      } = payload;

      const accessToUpdate = { ...state.access[accessIdentifier] };
      addableAccessBuckets.forEach(
        (bucket) =>
          (accessToUpdate[bucket] = [...accessToUpdate[bucket], ...accountIds])
      );
      removableAccessBuckets.forEach(
        (bucket) =>
          (accessToUpdate[bucket] = difference(
            accessToUpdate[bucket],
            accountIds
          ))
      );

      return {
        ...state,
        access: {
          ...state.access,
          [accessIdentifier]: accessToUpdate
        }
      };
    }
    case OPEN_ACCESS_MODAL: {
      const { modalIdentifier } = action.payload;
      return {
        ...state,
        isOpen: true,
        modalIdentifier: modalIdentifier
      };
    }
    case CLOSE_ACCESS_MODAL: {
      return {
        ...state,
        isOpen: false,
        modalIdentifier: null
      };
    }
    default: {
      return state;
    }
  }
};

export default access;
