import { put, select } from 'redux-saga/effects';
import { getAuthToken } from 'selectors';
import { fetchEntity, changeEntity } from 'sagas/generics';
import * as api from 'EventsModule/api';
import {
  fetchGoogleCalendarEventsActionCreatorsMap,
  fetchGoogleCalendarEvents,
  updateGoogleCalendarEvents,
  updateGoogleCalendarEventsActionCreatorsMap
} from 'EventsModule/actionCreators';

export function* fetchGoogleCalendarEventsWorker(
  action: ReturnType<typeof fetchGoogleCalendarEvents>
) {
  const { team_id, account_ids, start_datetime, end_datetime } = action.payload;
  const token = yield select(getAuthToken);
  const params = {
    start_datetime,
    end_datetime,
    account_ids
  };

  yield fetchEntity(
    fetchGoogleCalendarEventsActionCreatorsMap,
    api.fetchGoogleCalendarEvents,
    team_id,
    [token, params],
    action
  );
}

export function* updateGoogleCalendarEventsWorker(
  action: ReturnType<typeof updateGoogleCalendarEvents>
) {
  const token = yield select(getAuthToken);

  const { calendar_event_id, account_ids, phase_id, project_id } =
    action.payload;

  const params = {
    calendar_event_id
  };

  const body = {
    account_ids,
    phase_id,
    project_id
  };

  yield changeEntity(
    updateGoogleCalendarEventsActionCreatorsMap,
    api.updateGoogleCalendarEvents,
    [token, params, body],
    action
  );
}
