import React, { useState, useMemo } from 'react';
import theme from 'theme';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import CustomBar from 'ReportsModule/components/Utilization/Charts/shared/CustomBar';
import moment from 'moment';
import { DATA_KEY, CAPACITY_KEY } from 'UtilizationModule/constants';
import GradientsDefs from 'ReportsModule/components/GradientsDefs';
import LegendStyleTooltip from 'components/Tooltips/LegendStyleTooltip';
import { getTooltipContent } from 'ReportsModule/components/Utilization/utils';

const EmptyDiv = () => <div />;

const UtilizationBarChartWidget = ({
  data,
  includeRemainingCapacity,
  useCapacityAsDenominator
}) => {
  const [tooltipProps, setTooltipProps] = useState({});
  const hideTooltip = () => setTooltipProps({});

  const formattedData = useMemo(
    () =>
      data.map((singleData) => ({
        ...singleData,
        totalPercent: useCapacityAsDenominator
          ? (Math.max(singleData.totalHours, 0) /
              singleData[CAPACITY_KEY.CAPACITY_FOR_PERCENTAGE]) *
            100
          : 100
      })),
    [data, useCapacityAsDenominator]
  );

  const formattedTooltipData = useMemo(
    () => formattedData.map((singleData) => getTooltipContent(singleData)),
    [formattedData]
  );

  const handleSetTooltipProps = (props) => {
    if (tooltipProps.index !== props.index) {
      setTooltipProps({
        data: formattedTooltipData[props.index].data,
        summary: formattedTooltipData[props.index].summary,
        showBorder: true,
        index: props.index
      });
    }
  };

  return (
    <>
      <GradientsDefs />
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
          barSize={15}
          barGap={1}
        >
          <CartesianGrid />
          <XAxis
            dataKey="start_date"
            tickFormatter={(date) => moment(date).format('MMM')}
            tickLine={false}
          />
          <YAxis
            type="number"
            tickCount={6}
            interval={0}
            allowDecimals={false}
            // Math.round will fix floating point precision.
            tickFormatter={(num) => Math.round(num) + '%'}
            tickLine={false}
            domain={[0, 100]}
          />

          {/* --------------------------- HOLIDAYS --------------------------- */}
          <Bar
            dataKey={DATA_KEY.HOLIDAY_PERCENT}
            stackId={1}
            fill={theme.colors.colorLightGray6}
            isAnimationActive={false}
            shape={(props) => {
              return (
                <CustomBar
                  {...props}
                  disableLabel
                  dataKey={DATA_KEY.HOLIDAY_PERCENT}
                  onHover={() => handleSetTooltipProps(props)}
                  type="percent"
                  onHoverOut={hideTooltip}
                  includeRemainingCapacity={includeRemainingCapacity}
                />
              );
            }}
          />

          {/* ---------------------------- BILLABLE -------------------------- */}
          <Bar
            dataKey={DATA_KEY.BILLABLE_PERCENT}
            stackId={1}
            fill={theme.colors.colorConnectedGreen}
            isAnimationActive={false}
            shape={(props) => {
              return (
                <CustomBar
                  {...props}
                  disableLabel
                  dataKey={DATA_KEY.BILLABLE_PERCENT}
                  onHover={() => handleSetTooltipProps(props)}
                  type="percent"
                  onHoverOut={hideTooltip}
                  includeRemainingCapacity={includeRemainingCapacity}
                />
              );
            }}
          />

          {/* ------------------------ NON-BILLABLE ------------------------ */}
          <Bar
            dataKey={DATA_KEY.NONBILLABLE_PERCENT}
            stackId={1}
            fill={theme.colors.colorLightGray20}
            isAnimationActive={false}
            shape={(props) => {
              return (
                <CustomBar
                  {...props}
                  disableLabel
                  dataKey={DATA_KEY.NONBILLABLE_PERCENT}
                  onHover={() => handleSetTooltipProps(props)}
                  type="percent"
                  onHoverOut={hideTooltip}
                  includeRemainingCapacity={includeRemainingCapacity}
                />
              );
            }}
          />

          {/* ------------------------ CAPACITY ------------------------ */}
          {includeRemainingCapacity && (
            <Bar
              dataKey={CAPACITY_KEY.REMAINING_CAPACITY_PERCENT}
              stackId={1}
              fill={theme.colors.colorPaleGray5}
              isAnimationActive={false}
              shape={(props) => {
                if (props.payload[CAPACITY_KEY.REMAINING_CAPACITY_PERCENT] <= 0)
                  return null;
                return (
                  <CustomBar
                    {...props}
                    disableLabel
                    dataKey={CAPACITY_KEY.REMAINING_CAPACITY_PERCENT}
                    onHover={() => handleSetTooltipProps(props)}
                    type="percent"
                    onHoverOut={hideTooltip}
                    includeRemainingCapacity={includeRemainingCapacity}
                  />
                );
              }}
            />
          )}

          {/* ---------------------------- PTO ---------------------------- */}
          <Bar
            dataKey={DATA_KEY.PTO_PERCENT}
            stackId={1}
            fill={theme.colors.colorPaleBlue4}
            isAnimationActive={false}
            shape={(props) => {
              return (
                <CustomBar
                  {...props}
                  disableLabel
                  dataKey={DATA_KEY.PTO_PERCENT}
                  onHover={() => handleSetTooltipProps(props)}
                  type="percent"
                  onHoverOut={hideTooltip}
                  includeRemainingCapacity={includeRemainingCapacity}
                />
              );
            }}
          />
          <Tooltip
            cursor={false}
            content={
              tooltipProps.data ? (
                <LegendStyleTooltip
                  className="utilization-widget-tooltip"
                  isReactTooltip={false}
                  {...tooltipProps}
                />
              ) : (
                <EmptyDiv />
              )
            }
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default UtilizationBarChartWidget;
