import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SuggestionsPhaseIcon = ({
  className,
  height = '18px',
  width = '14px',
  color = '#4F4F4F'
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 14 18"
    className={className}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 9.00167L6.99857 2L1 8.99833L7.00143 16L13 9.00167Z"
      stroke={color}
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default SuggestionsPhaseIcon;
