import React from 'react';
import { connect } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import { BILLABLE_VALUES } from 'appConstants/filters';

const billableItems = [BILLABLE_VALUES.BILLABLE, BILLABLE_VALUES.NOT_BILLABLE];

const BillableFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  isHidden
}) => {
  const setFilterItems = (nextBillable) => {
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        ...activeFilter.custom,
        billable: nextBillable
      }
    });
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={billableItems}
      initialSelectedItems={activeFilter.custom.billable}
      setFilterItems={setFilterItems}
      searchEnabled={false}
      toggleLabel="Billable"
      togglePluralLabel="Billable"
      allSelectedCopy="All Time"
      filterId={activeFilter.id}
      listHeight={70}
      listWidth={150}
      batchClearOnly={false}
      autoClick={false}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(BillableFilterDropdown);
