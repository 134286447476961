import { FilterField } from 'FilterModule/constants';
import keyBy from 'lodash/keyBy';
import { AssociationUpdatesInstance } from 'RequirementsModule/actionCreators/types';
import useRequirementsHash from 'RequirementsModule/hooks/useRequirementsHash';
import {
  deserializeRequirement,
  makeRemoveRequirementIds,
  serializeRequirement
} from 'RequirementsModule/utils';
import { ValueOf } from 'type-fest';
import { EntityRequirementsState } from 'RequirementsModule/reducers/entityRequirements';
import { EntitiesRequirementAssociation } from 'RequirementsModule/types';
import { ProjectMembershipRequirementListItemDataObject } from './List/UnassignedRoles/types';
import { FindPeopleModalContextValuesType } from './contexts/types';
import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';

export const phaseSelectorDoneBtnId = 'phase-selector-done-button';

export const requirementsSelectorPortalId = 'requirement-selector-portal';

export const teamBuilderIsoStateId = 'team-builder';

/* ----------------------- Iso State / Filter state Id ---------------------- */

export enum FilterStateIds {
  fetchPhaseTotals = 'fetch-phase-totals-filter-state-id',
  fetchBudgetRecordsForPhases = 'fetch-budget-records-for-phases-filter-state-id'
}

/* --------------------------- Request Status Ids --------------------------- */

export enum RequestStatusIds {
  fetchMemberSuggestionForPhases = 'fetch-member-suggestions-for-phases-request-status-id',
  fetchEntities = 'fetch-entities-request-status-id',
  fetchBudgetRecordsForPhases = 'fetch-budget-records-for-phases-request-status-id',
  fetchEntityRequirements = 'fetch-entities-requirements-request-status-id',
  fetchPhaseTotals = 'fetch-phase-totals-request-status-id',

  // status id for tracking when the refetch actions after adding unassigned role to phases, is successful, in order to trigger a new suggestion fetch
  refetchAfterAddRoleToPhases = 'team-builder-refetch-after-successfully-add-role-to-phases-request-status-id'
}

/* -------------------------------------------------------------------------- */
export const reasonsToNotShowOnUI = new Set([
  'account_starred_project_amplitude'
]);

/* -------------------------------------------------------------------------- */

type FormatAssociationUpdateInstance = ({
  allRequirementsHash,
  associationUpdateInstance,
  selectedRequirements,
  requirementAssociationsByProjectMembershipIdHash,
  projectMembershipId
}: {
  allRequirementsHash: ReturnType<typeof useRequirementsHash>;
  associationUpdateInstance: AssociationUpdatesInstance;
  selectedRequirements: number[] | string[];
  requirementAssociationsByProjectMembershipIdHash: EntityRequirementsState['requirementAssociationsByEntity']['ProjectMembership'];
  projectMembershipId: number;
}) => AssociationUpdatesInstance;

type MakeRequirementDataObjectArray = ({
  requirementsAssociations,
  allRequirementsHash,
  onDeleteRequirement
}: {
  requirementsAssociations: EntitiesRequirementAssociation;
  allRequirementsHash: ReturnType<typeof useRequirementsHash>;
  projectMembershipId: number;
  onDeleteRequirement: FindPeopleModalContextValuesType['onDeleteRequirement'];
}) => ProjectMembershipRequirementListItemDataObject[];

export const TEAM_BUILDERS_REQUIREMENTS: {
  type: string;
  filterField: ValueOf<typeof FilterField>; // Used for side selector
  formatAssociationUpdateInstance: FormatAssociationUpdateInstance;
  makeRequirementDataObjectArray: MakeRequirementDataObjectArray;
}[] = [
  {
    type: 'SKILL',
    filterField: FilterField.skillLevels,
    formatAssociationUpdateInstance: ({
      allRequirementsHash,
      selectedRequirements,
      associationUpdateInstance,
      projectMembershipId,
      requirementAssociationsByProjectMembershipIdHash
    }) => {
      const { skill_associations = [] } =
        requirementAssociationsByProjectMembershipIdHash[projectMembershipId] ||
        {};
      const addSkills = (selectedRequirements as string[]).map((skillLevel) => {
        const [skillId, level] = deserializeRequirement(skillLevel);
        return {
          team_skill_id: +(skillId as string),
          level: level ? +level : null
        };
      });

      return {
        ...associationUpdateInstance,
        add_skills: addSkills,
        remove_skill_ids: makeRemoveRequirementIds(
          skill_associations.map(
            (skillAssociation) => skillAssociation.team_skill_id
          ),
          addSkills.map((skillLevel) => skillLevel.team_skill_id)
        )
      };
    },
    makeRequirementDataObjectArray: ({
      requirementsAssociations,
      allRequirementsHash,
      onDeleteRequirement,
      projectMembershipId
    }) => {
      const { skill_associations = [] } = requirementsAssociations;
      const { skillsHash } = allRequirementsHash;

      return skill_associations.map(({ level, team_skill_id }) => {
        const skill = skillsHash[team_skill_id];
        const name = `${skill?.name} ${level ? `|${level}` : ''}`;
        const serializedId = serializeRequirement(['skill', team_skill_id]);

        const associations = [
          {
            entity_id: projectMembershipId,
            entity_type: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP,
            remove_skill_ids: [team_skill_id]
          }
        ];

        return {
          dataFromHash: skill,
          tooltip: name,
          name,
          id: serializedId,
          serializedId,
          type: 'skill',
          onDelete: () => {
            onDeleteRequirement({ association_updates: associations });
          }
        };
      });
    }
  },
  {
    type: 'DISCIPLINE',
    filterField: FilterField.discipline_ids,
    formatAssociationUpdateInstance: ({
      allRequirementsHash,
      selectedRequirements,
      associationUpdateInstance,
      projectMembershipId,
      requirementAssociationsByProjectMembershipIdHash
    }) => {
      const { discipline_ids = [] } =
        requirementAssociationsByProjectMembershipIdHash[projectMembershipId] ||
        {};
      const selectedRequirementIdsSet = new Set(
        selectedRequirements as number[]
      );

      return {
        ...associationUpdateInstance,
        add_discipline_ids: Array.from(selectedRequirementIdsSet),
        remove_discipline_ids: makeRemoveRequirementIds(
          discipline_ids,
          selectedRequirements as number[]
        )
      };
    },
    makeRequirementDataObjectArray: ({
      requirementsAssociations,
      allRequirementsHash,
      projectMembershipId,
      onDeleteRequirement
    }) => {
      const { discipline_ids = [] } = requirementsAssociations;
      const { disciplinesHash } = allRequirementsHash;

      return discipline_ids.map((id) => {
        const discipline = disciplinesHash[id];
        const name = discipline?.name || '';
        const serializedId = serializeRequirement(['discipline', id]);

        const associations = [
          {
            entity_id: projectMembershipId,
            entity_type: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP,
            remove_discipline_ids: [id]
          }
        ];

        return {
          dataFromHash: discipline,
          tooltip: name,
          name,
          id: serializedId,
          serializedId,
          type: 'discipline',
          onDelete: () => {
            onDeleteRequirement({ association_updates: associations });
          }
        };
      });
    }
  },
  {
    type: 'OFFICE',
    filterField: FilterField.office_ids,
    formatAssociationUpdateInstance: ({
      allRequirementsHash,
      selectedRequirements,
      associationUpdateInstance,
      projectMembershipId,
      requirementAssociationsByProjectMembershipIdHash
    }) => {
      const { office_ids = [] } =
        requirementAssociationsByProjectMembershipIdHash[projectMembershipId] ||
        {};
      const selectedRequirementIdsSet = new Set(
        selectedRequirements as number[]
      );

      return {
        ...associationUpdateInstance,
        add_office_ids: Array.from(selectedRequirementIdsSet),
        remove_office_ids: makeRemoveRequirementIds(
          office_ids,
          selectedRequirements as number[]
        )
      };
    },
    makeRequirementDataObjectArray: ({
      requirementsAssociations,
      allRequirementsHash,
      projectMembershipId,
      onDeleteRequirement
    }) => {
      const { office_ids = [] } = requirementsAssociations;
      const { officesHash } = allRequirementsHash;

      return office_ids.map((id) => {
        const office = officesHash[id];
        const name = office?.name || '';
        const serializedId = serializeRequirement(['office', id]);

        const associations = [
          {
            entity_id: projectMembershipId,
            entity_type: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP,
            remove_office_ids: [id]
          }
        ];

        return {
          dataFromHash: office,
          tooltip: name,
          name,
          id: serializedId,
          serializedId,
          type: 'office',
          onDelete: () => {
            onDeleteRequirement({ association_updates: associations });
          }
        };
      });
    }
  },
  {
    type: 'REGION',
    filterField: FilterField.region_ids,
    formatAssociationUpdateInstance: ({
      allRequirementsHash,
      selectedRequirements,
      associationUpdateInstance,
      projectMembershipId,
      requirementAssociationsByProjectMembershipIdHash
    }) => {
      const { region_ids = [] } =
        requirementAssociationsByProjectMembershipIdHash[projectMembershipId] ||
        {};
      const selectedRequirementIdsSet = new Set(
        selectedRequirements as number[]
      );

      return {
        ...associationUpdateInstance,
        add_region_ids: Array.from(selectedRequirementIdsSet),
        remove_region_ids: makeRemoveRequirementIds(
          region_ids,
          selectedRequirements as number[]
        )
      };
    },
    makeRequirementDataObjectArray: ({
      requirementsAssociations,
      allRequirementsHash,
      projectMembershipId,
      onDeleteRequirement
    }) => {
      const { region_ids = [] } = requirementsAssociations;
      const { regionsHash } = allRequirementsHash;

      return region_ids.map((id) => {
        const region = regionsHash[id];
        const name = region?.name || '';
        const serializedId = serializeRequirement(['region', id]);

        const associations = [
          {
            entity_id: projectMembershipId,
            entity_type: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP,
            remove_region_ids: [id]
          }
        ];

        return {
          dataFromHash: region,
          tooltip: name,
          name,
          id: serializedId,
          serializedId,
          type: 'region',
          onDelete: () => {
            onDeleteRequirement({ association_updates: associations });
          }
        };
      });
    }
  }
  // {Location}
];
