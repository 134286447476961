import React from 'react';
import { connect } from 'react-redux';

import {
  fetchTeamMemberProfile,
  fetchTeamMembersByBoard,
  fetchTeams,
  setSelectedTeamMember
} from 'actionCreators';
import {
  getMyUserId,
  getGuests,
  getRegularTeamMembers,
  getMatchedRouteParams,
  getAllTeamMembers
} from 'selectors';
import TeamMembersHeader from './TeamMembersHeader';
import { StyledTeamMembersPageContainer } from './styles';
import ConfigureModulesModal from 'components/Modals/ConfigureModulesModal';

class TeamMembersContainer extends React.Component {
  componentDidMount() {
    const { fetchTeams, setSelectedTeamMember } = this.props;
    fetchTeams();
    const member = this.getMember();
    if (member) {
      setSelectedTeamMember({
        selectedMemberId: member.id,
        selectedAccountId: member.account.id
      });
    }
    //  will be used for Team Members View by Board once implemented
    // fetchTeamMembersByBoard();
  }

  componentDidUpdate(prevProps) {
    const {
      setSelectedTeamMember,
      matchedParams: { teamMemberId }
    } = this.props;
    if (prevProps.matchedParams.teamMemberId !== teamMemberId) {
      const member = this.getMember();
      if (member) {
        setSelectedTeamMember({
          selectedMemberId: member.id,
          selectedAccountId: member.account.id
        });
      }
    }
  }

  componentWillUnmount() {
    const { setSelectedTeamMember } = this.props;
    setSelectedTeamMember({ selectedMemberId: null, selectedAccountId: null });
  }

  getMember = () => {
    const { matchedParams, teamMembers } = this.props;
    const { teamMemberId } = matchedParams;
    const member =
      teamMembers && teamMembers.find((member) => member.id === +teamMemberId);
    return member;
  };

  handleMemberCardClick = (teamMembershipId) => {
    const { fetchTeamMemberProfile } = this.props;
    fetchTeamMemberProfile({ teamMemberId: teamMembershipId });
  };

  render() {
    const { children } = this.props;
    return (
      <StyledTeamMembersPageContainer>
        <TeamMembersHeader />
        {children}
        <ConfigureModulesModal moduleType="membersView" />
      </StyledTeamMembersPageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  regularTeamMembers: getRegularTeamMembers(state),
  guests: getGuests(state),
  myId: getMyUserId(state),
  matchedParams: getMatchedRouteParams(state),
  teamMembers: getAllTeamMembers(state)
});

const mapDispatchToProps = {
  fetchTeamMemberProfile,
  fetchTeamMembersByBoard,
  fetchTeams,
  setSelectedTeamMember
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembersContainer);
