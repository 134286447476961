import { createSelector, Selector } from 'reselect';
import { ValueOf } from 'type-fest';
import { initialState as entityOptionsInitialState } from '../reducers/entityOptions';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import {
  ProjectEntityOptions,
  EntityOptionsState
} from 'EntityOptionsModule/types';
import { RootState, ReducerName } from 'reduxInfra/shared';

const emptyObj = {};
const emptyArray = [];

const getEntityOptionsState: Selector<RootState, EntityOptionsState> = (
  state
) => state[ReducerName.EntityOptions] || entityOptionsInitialState;

export const getEntityOptionsByEntityTypeHash = createSelector(
  getEntityOptionsState,
  (state) => state.entityOptionsByEntityTypeHash
);

export const getFetchedEntityOptionSerializeIds = createSelector(
  getEntityOptionsState,
  (state) => state.fetchedEntityOptionSerializeIds
);

export const makeGetOwnEntityOptions = () =>
  createSelector(
    (
      state,
      {
        entityType,
        entityId
      }: { entityType: ValueOf<typeof ENTITY_TYPES>; entityId: number }
    ) => ({ entityType, entityId }),
    getEntityOptionsByEntityTypeHash,
    ({ entityType, entityId }, entityOptionsByEntityTypeHash) => {
      return (
        entityOptionsByEntityTypeHash[entityType]?.[entityId] ||
        entityOptionsByEntityTypeHash[entityType]?.default ||
        emptyObj
      );
    }
  );

export const makeGetOwnProjectBudgetStatuses = () => {
  const getOwnEntityOptions = makeGetOwnEntityOptions();
  return createSelector(
    getOwnEntityOptions,
    (entityOptions: ProjectEntityOptions) =>
      entityOptions?.budget_status?.enum || emptyArray
  );
};

export const makeGetOwnBudgetProposalOptions = () => {
  const getOwnEntityOptions = makeGetOwnEntityOptions();
  return createSelector(
    getOwnEntityOptions,
    (entityOptions: ProjectEntityOptions) =>
      entityOptions.budget_sub_status?.budget_status?.proposal?.enum ||
      emptyArray
  );
};
