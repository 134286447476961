import React from 'react';
import { useDispatch } from 'react-redux';
import { updateSkillMembership } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';
import Popover from 'components/Popover';
import {
  SKILL_LEVELS,
  NUM_SKILLS,
  SKILL_LEVEL_TO_COLOR
} from 'appConstants/skills';
import LevelIndicator from 'components/LevelIndicator';
import NewCloseIcon from 'icons/NewCloseIcon';

const CloseIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 21px;
  width: 21px;
  border-radius: 50px;
  position: relative;
  right: -5px;
  top: 2px;

  &:hover {
    background-color: ${theme.colors.colorPaleGray1};
  }
`;

const StyledMenuItem = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  padding: 6px 16px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
`;
const StyledHeader = styled.div`
  font-weight: 600;
  color: black;
  font-size: 16px;
  padding: 0 16px 6px;

  ${({ showCloseIcon }) =>
    showCloseIcon
      ? `
          display: flex;
          justify-content: space-between;
      `
      : ''}
`;
const StyledMenu = styled.div`
  padding: 16px 0 10px;
  width: 187px;
`;

const SkillLevelDropdown = ({
  isOpen,
  targetRef,
  closeDropdown,
  member,
  skillMembership,
  customHandleSelect,
  showDeleteSkill, // this will show as 'Not a Skill' as a way of deleting the skill from the account
  showCloseIcon,
  currentLevel,
  highlightOnSelected
}) => {
  const dispatch = useDispatch();

  const handleSelect = (level) => {
    if (customHandleSelect) {
      customHandleSelect(level);
      closeDropdown();
    } else {
      if (level !== skillMembership.level) {
        dispatch(
          updateSkillMembership({
            skillMembershipId: skillMembership.id,
            level
          })
        );
        closeDropdown();
      }
    }
  };

  const SKILL_LEVELS_TO_USE = showDeleteSkill
    ? { 0: 'Not a Skill', ...SKILL_LEVELS }
    : SKILL_LEVELS;

  return (
    <Popover
      isOpen={isOpen}
      closePopover={closeDropdown}
      target={targetRef}
      boundariesElement="window"
    >
      <StyledMenu>
        <StyledHeader showCloseIcon={showCloseIcon}>
          Select Skill Level
          {showCloseIcon && (
            <CloseIconContainer onClick={closeDropdown}>
              <NewCloseIcon
                width={21}
                height={21}
                noCircle
                fillColor={theme.colors.colorPureBlack}
              />
            </CloseIconContainer>
          )}
        </StyledHeader>
        {Object.keys(SKILL_LEVELS_TO_USE).map((level) => {
          return (
            <StyledMenuItem onClick={() => handleSelect(level)} key={level}>
              <span
                style={{
                  ...(highlightOnSelected &&
                    currentLevel === +level && { fontWeight: '700' })
                }}
              >
                {SKILL_LEVELS_TO_USE[level]}
              </span>
              <LevelIndicator
                levelToColor={SKILL_LEVEL_TO_COLOR}
                level={level}
                numLevels={NUM_SKILLS}
              />
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </Popover>
  );
};

export default SkillLevelDropdown;
