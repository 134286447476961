import React from 'react';
import {
  Header,
  Title,
  Button,
  ButtonDiv,
  ConfirmationMsg
} from 'views/projectDisplay/projectCloneModal/styles';

const ProcessStartedModalBody = ({
  handleOk,
  title = 'Clone Project',
  processStartedMessage = 'Process initiated. Cloning a project may take a few minutes.'
}) => {
  return (
    <>
      <Header>
        <div>
          <Title>{title}</Title>
        </div>
      </Header>
      <ConfirmationMsg>{processStartedMessage}</ConfirmationMsg>
      <ButtonDiv style={{ justifyContent: 'flex-end' }}>
        <Button onClick={handleOk}>Okay</Button>
      </ButtonDiv>
    </>
  );
};

export default ProcessStartedModalBody;
