import React, { Component } from 'react';
import moment from 'moment';
import 'rc-calendar/assets/index.css';
import invariant from 'invariant';
import enUS from 'rc-calendar/lib/locale/en_US';
import Picker from './Picker';
import { PickerWrapper } from './styles/components';
import TaskRangePickerInput from './TaskRangePickerInput';
import ModalContext from '../../containers/ModalContext';
import { makeDateFromDateTime } from 'appUtils/momentUtils';
import MosaicRangeCalendar from 'views/projectDisplay/MosaicRangeCalendar';
const noop = () => {};
class DateRangePicker extends Component {
  static defaultProps = {
    prefixCls: 'rc-calendar',
    allowClear: true,
    showToday: true,
    showDateInput: true
  };

  static contextType = ModalContext;

  constructor(props) {
    super(props);
    const value = moment(props.selectedValue).isValid()
      ? moment(props.selectedValue)
      : undefined;
    invariant(
      !(value && !value.isValid()),
      `The ${props.value} value/defaultValue of DatePicker is an invalid date.`
    );
    this.state = {
      value: props.selectedValue,
      showDate: value
    };
  }

  componentWillUnmount = () => {
    const container = this.context.getModal();
    container.style.left = '';
    container.style.top = '';
    container.style.right = '';
  };

  getContainerInstance = () => {
    const container = this.context.getModal();
    container.style.top = '120px';
    container.style.right = '300px';
    container.style.left = '';
    return container;
  };

  clearSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleChange(null);
  };

  handleChange = (value) => {
    const { props } = this;
    if (!('value' in props)) {
      this.setState({
        showDate: value
      });
    }
    props.onChange(value, (value && value.format(props.format)) || '');
  };

  handleCalendarChange = (value) => {
    this.setState({ showDate: value });
  };

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  removeDate = (e) => {
    this.handleChange(null);
  };

  renderFooter = (...args) => {
    const { prefixCls, renderExtraFooter } = this.props;
    return renderExtraFooter ? (
      <div className={`${prefixCls}-footer-extra`}>
        {renderExtraFooter(...args)}
      </div>
    ) : null;
  };

  renderDefaultInput = ({ openCalendar, value }) => {
    const {
      taskIsCreating,
      alwaysRenderInput,
      icon,
      taskIsEditing,
      bulk,
      currentFilter,
      taskEditProperty,
      isTaskCompleted,
      projectDetailView,
      isTaskModal,
      task
    } = this.props;

    return (
      <span style={{ margin: 0 }}>
        <TaskRangePickerInput
          taskIsEditing={taskIsEditing}
          taskIsCreating={taskIsCreating}
          icon={icon}
          startDate={task?.schedule_start}
          endDate={task?.schedule_end}
          alwaysRenderInput={alwaysRenderInput}
          bulk={bulk}
          currentFilter={currentFilter}
          taskEditProperty={taskEditProperty}
          isTaskCompleted={isTaskCompleted}
          projectDetailView={projectDetailView}
          isTaskModal={isTaskModal}
          openCalendar={openCalendar}
        />
      </span>
    );
  };

  render() {
    const {
      renderCustomInput,
      taskListView,
      handleDateSelection,
      clearDates,
      saveDates,
      newDates,
      task,
      target,
      calendarOpenDefault = false,
      onClose = noop
    } = this.props;

    const renderInput = renderCustomInput || this.renderDefaultInput;
    return (
      <PickerWrapper
        className="rc-container-picker-container schedule-container"
        taskListView={taskListView}
      >
        <MosaicRangeCalendar
          handleDateSelection={handleDateSelection}
          clearDates={clearDates}
          saveDates={saveDates}
          item={task}
          startDate={task?.schedule_start}
          endDate={task?.schedule_end}
          newDates={newDates}
          shouldSave
          calendarContainer={target || this.getContainerInstance()}
          placement={'bottom-left'}
          pickerRenderer={renderInput}
          calendarOpenDefault={calendarOpenDefault}
          onClose={onClose}
        />
      </PickerWrapper>
    );
  }
}

export default DateRangePicker;
