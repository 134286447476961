import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ReportsTimesheetWidget from 'views/Home/Widgets/Widgets/TimesheetWidget/ReportsTimesheetWidget';
import { getPlannerModalAccountId } from 'selectors';
import { getAccountCapacities } from 'CapacityModule/selectors';
import flatten from 'lodash/flatten';
import {
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY
} from 'appConstants/workload';

const TimesheetTotalWidgetContainer = styled.div`
  margin-top: 15px;
  text-transform: lowercase;
`;

const containerStyles = { padding: 0, position: 'absolute', top: '26px' };

const tooltipStyles = {
  allowEscapeViewBox: { x: false, y: false },
  wrapperStyle: { zIndex: 5, left: -130, top: -20 },
  offset: -34
};

const pieStyles = {
  innerRadius: 28,
  outerRadius: 32,
  fill: '#8884d8',
  paddingAngle: 0
};

const TimesheetColumnHeaderTotal = (props) => {
  const matchingTimesheets =
    flatten(
      props.data
        ?.filter((row) => row.timesheetsByDate)
        .map((row) => Object.values(row.timesheetsByDate))
    ).filter((timesheet) => timesheet?.id) ?? [];

  const accountId = useSelector(getPlannerModalAccountId);
  const accountCapacities = useSelector(getAccountCapacities);
  const accountCapacity = accountCapacities[accountId];
  const capacityTotal = accountCapacity
    ? CAPACITY_DATE_KEYS.reduce(
        (total, day) => +total + +accountCapacity[day],
        0
      )
    : DEFAULT_WEEKLY_CAPACITY;

  return (
    <div>
      <TimesheetTotalWidgetContainer>
        <ReportsTimesheetWidget
          timesheets={matchingTimesheets}
          totalCapacity={capacityTotal}
          containerStyles={containerStyles}
          tooltipStyles={tooltipStyles}
          pieStyles={pieStyles}
        />
      </TimesheetTotalWidgetContainer>
    </div>
  );
};
export default TimesheetColumnHeaderTotal;
