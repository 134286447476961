import styled from 'styled-components';
import theme from 'theme';
import { StyledStickyHeader } from 'components/Table/TableStickyHeader';

export const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCapacitiesTable = styled.div`
  position: relative;
  margin-top: 26px;
  flex: 1;
  min-height: 0;

  .collapse-arrow {
    path {
      fill: ${theme.colors.colorMediumGray9};
    }
  }

  ${TableHeaderContainer} {
    top: -26px;
    left: 5px;
    position: absolute;
    .collapse-all {
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: ${theme.colors.colorMediumGray9};
        }
      }
    }
  }

  .table {
    margin-bottom: 0;
    height: 100%;
  }
  .table-body {
    height: 100%;
  }
  .variable-size-list {
    height: 100% !important;
  }

  .td {
    height: 100%;
    flex-shrink: 0;

    &.archivedSectionHeaderRow {
      // align the cell to member initials circle
      margin-left: 32px;
    }
  }

  .tr {
    &.memberHeaderRow {
      border-bottom: 2px solid ${theme.colors.colorLightGray6};
    }

    &.archivedSectionHeaderRow {
      color: ${theme.colors.colorMediumGray9};

      &:hover {
        color: ${theme.colors.colorRoyalBlue};
      }
    }

    &.memberCapacityRow {
      border-bottom: 1px solid ${theme.colors.colorLightGray6};
    }
  }

  ${StyledStickyHeader} {
    padding: 0;
    top: 0px;

    .td {
      background: ${theme.colors.colorTranslucentGray4};
      border-bottom: 2px solid ${theme.colors.colorLightGray6};
    }
  }
`;
