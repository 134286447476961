import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import bindAll from 'lodash/bindAll';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dragula from 'react-dragula';
import { scrollBarStyle } from 'appUtils/customScroller';
import { calculateDragScroll } from 'appUtils';
import { calculateBoardColorPreference } from 'appUtils/styleUtils';
import FolderIcon from 'icons/FolderIcon';
import DragGripIcon from 'icons/DragGripIcon';
import {
  getAuth,
  getGroupsState,
  getOnProjectDetail,
  getMatchedRouteParams
} from 'selectors';
import {
  handleProjectItemState,
  useSearchState,
  saveSearchState,
  onClearSearch,
  filterByMembers,
  clearSelectedProject,
  openAddEditGroupModal,
  changeBoardPosition,
  switchBoards,
  navigateToBoard
} from 'actionCreators';

const BoardListItem = styled.li`
  padding: 6px 0px 6px 0px;
  list-style: none;
  font-size: 14px;
  color: #ffff;
  display: flex;
  align-items: center;

  font-weight: ${({ selectedGroupId, boardId }) =>
    selectedGroupId === boardId ? 'bold' : 'normal'};
  &:hover {
    background-color: #697990;

    path {
      visibility: visible;
    }
  }
`;

const StyledDragGripIcon = styled(DragGripIcon)`
  width: 8px;
  margin: 2px 0px 0px 4px;
  cursor: move;
  path {
    fill: #fff;
    visibility: hidden;
  }
`;

const StyledFolderIcon = styled(FolderIcon)`
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 6px;
  width: 14px;
  height: 14px;

  path {
    stroke: #fff;
    stroke-width: 3;
    fill: ${calculateBoardColorPreference} !important;
  }
`;

class MainMenuBoardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dragging: false };
    bindAll(this, [
      'handleAddBoardClick',
      'onClickGroupNameButton',
      'selectGroup'
    ]);
  }

  componentDidMount() {
    const drake = dragula([], {
      isContainer: (el) => {
        return el.classList.contains('main-menu-boards');
      },
      accepts: (el, target, source, sibling) => {
        return target === source;
      }
    });

    document.addEventListener('mousemove', (e) => {
      if (this.state.dragging) {
        const container = document.querySelector('.main-menu-boards');
        calculateDragScroll(container);
      }
    });

    drake.on('drag', () => {
      this.setState({ dragging: true });
    });

    drake.on('drop', (el, target, source, sibling) => {
      this.setState({ dragging: false });

      const boardId = el.classList[0].split('-')[1];
      let position = sibling ? sibling.classList[0].split('-')[2] : -1;
      const currentPosition = el.classList[0].split('-')[2];

      if (sibling && sibling.classList.contains('gu-unselectable')) {
        position = -1;
      }
      if (Number(currentPosition) > Number(position) || position === -1) {
        this.props.changeBoardPosition(boardId, position);
      } else {
        this.props.changeBoardPosition(boardId, position - 1);
      }
    });
  }

  handleAddBoardClick() {
    const { openAddEditGroupModal } = this.props;
    openAddEditGroupModal({ isEdit: false, id: null });
  }

  selectGroup(groupId, teamSlug, groupSlug) {
    const {
      onClearSearch,
      filterByMembers,
      history,
      clearSelectedProject,
      switchBoards,
      handleProjectItemState,
      handleMenuVisibility,
      groups,
      isOnHome,
      isOnProjectDetail,
      isOnSettings,
      path,
      navigateToBoard
    } = this.props;
    const selectedGroupId = groups.selectedGroupID;

    if (
      groupId !== selectedGroupId ||
      isOnHome ||
      (isOnProjectDetail && groupId !== selectedGroupId) ||
      isOnSettings
    ) {
      onClearSearch();
      filterByMembers(false, []);
      handleMenuVisibility();
      handleProjectItemState(
        null,
        ['detailVisible', 'expanded', 'fixed'],
        [false, false, false]
      );
      clearSelectedProject();
      switchBoards();
      navigateToBoard({ teamSlug, boardSlug: groupSlug, boardId: groupId });
    }
  }

  onClickGroupNameButton(boardId, teamSlug, groupSlug) {
    const { useSearchState, saveSearchState } = this.props;

    useSearchState({ home: false, board: false });
    saveSearchState({ home: '', board: '' });
    this.selectGroup(boardId, teamSlug, groupSlug);
  }

  render() {
    const {
      teamBoardMenuOpen,
      showTitle,
      boards,
      groups,
      isOnHome,
      isOnProjectDetail,
      isOnSettings,
      isOnProfile
    } = this.props;

    const boardMenuContent = (
      <div>
        <div className="group-menu-actions">
          <div className="group-menu-actions-add">
            <button
              className="add-board-button"
              onClick={this.handleAddBoardClick}
              data-testid="dropdown-new-portfolio"
            >
              New Portfolio
            </button>
          </div>
        </div>
        <ul className="main-menu-boards">
          {boards
            ? boards.map((board, idx) => (
                <BoardListItem
                  key={board.id}
                  boardId={board.id}
                  selectedGroupId={groups.selectedGroupID}
                  onClick={() =>
                    this.onClickGroupNameButton(
                      board.id,
                      board.team_slug,
                      board.slug
                    )
                  }
                  className={cn(`board-${board.id}-${idx} clickable`, {
                    selected:
                      groups.selectedGroupID === board.id &&
                      !isOnHome &&
                      !isOnProjectDetail &&
                      !isOnSettings &&
                      !isOnProfile
                  })}
                >
                  <StyledDragGripIcon>
                    <DragGripIcon />
                  </StyledDragGripIcon>
                  <div className="board-title">
                    <StyledFolderIcon boardId={board.id} />
                    {board.name}
                    <div
                      className={cn('lock-icon', {
                        visible: board && board.is_private
                      })}
                    />
                  </div>
                </BoardListItem>
              ))
            : ''}
        </ul>
      </div>
    );

    const boardName = document.querySelector('h3.board-name');
    let width = null;
    if (boardName) {
      boardName.getBoundingClientRect().width > 137
        ? (width = boardName.getBoundingClientRect().width)
        : (width = null);
    }
    if (this.props.showTitle) {
      width = 'auto';
    }

    return (
      <div style={{ width: width }} className="main-menu-boards-section">
        {teamBoardMenuOpen ? scrollBarStyle('Mac', boardMenuContent) : ''}
      </div>
    );
  }
}

const mapStateToProps = (state, params) => {
  const path = params.location.pathname;
  return {
    path,
    isOnHome: state.router.location.pathname.includes('home'),
    isOnProjectDetail: getOnProjectDetail(state),
    isOnSettings: state.router.location.pathname.includes('settings'),
    isOnProfile: !!getMatchedRouteParams(state).teamMemberId,
    boards:
      state.users.teams[0] && state.users.teams[0].boards
        ? state.users.teams[0].boards
        : [],
    groups: getGroupsState(state),
    auth: getAuth(state)
  };
};

const mapDispatchToProps = {
  useSearchState,
  saveSearchState,
  onClearSearch,
  filterByMembers,
  clearSelectedProject,
  handleProjectItemState,
  openAddEditGroupModal,
  changeBoardPosition,
  switchBoards,
  navigateToBoard
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenuBoardList)
);
