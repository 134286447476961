import styled from 'styled-components';
import theme from 'theme';

export const StyledItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  &.phase,
  &.activity-phase {
    width 300px;
    height: 25px;
    cursor: pointer;
    padding-left: 1rem;
    display: grid;
    font-weight: 600;
    grid-template-columns: 20px 16px fit-content(100%) 57px;

    .total-member-count {
      font-size: 16px;
      font-weight: 400;
      color: ${theme.colors.colorLightGray15};
    }
    &:hover {
      background-color: ${theme.colors.colorPaleGray10};
    }
  }

  &.activity-phase {
    padding-left: 37px;
    grid-template-columns: 20px 6px fit-content(100%) 57px;
  }

  &.phase-membership {
    padding-left: 16px;
    display: grid;
    grid-template-columns: 30px 180px 4px 0px 70px;
    width: fit-content;

    .member-initials {
      cursor: default;
    }

    .hour {
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      color: ${theme.colors.colorCalendarBlue};
      cursor: pointer;

      .hour-input {
        cursor: pointer;
        height: 29px;

        ::placeholder {
          color: ${theme.colors.colorCalendarBlue};
        }

        &:hover {
          background: ${theme.colors.colorLightGray17};
        }

        &:focus {
          background: ${theme.colors.colorPureWhite};
        }
      }

      .autoplan-icon {
        padding-left: 2px;
        cursor: pointer;
      }
    }
    .member-name {
      font-size: 13px;
      font-weight: 600;
      color: ${theme.colors.colorMediumGray9};
    }
    .member-role {
      font-size: 12px;
      font-weight: 400;
      color: ${theme.colors.colorMediumGray5};
    }
  }

  &.phase-membership-unassigned-member-budget {
    padding-left: 14px;
  }
  &.project-unassigned-member-budget-with-requirements {
    padding-top: 10px;
    .text-container {
      width: fit-content;
      cursor: pointer;
      padding-right: 2rem;
      padding-left: 6px;

      &:hover {
        color: ${theme.colors.colorCalendarBlue};
      }

      .requirement-counts {
        font-size: 12px;
        color: ${theme.colors.colorCalendarGray};
        font-weight: 400;
        margin-top: -2px;
      }

      .requirement-name {
        font-size: 16px;
        font-weight: 600;
        color: ${theme.colors.colorSemiDarkGray1};
      }

      &.isOpen {
        cursor: default;

        &:hover {
          color: initial;
        }
      }
    }
  }
  &.project-membership-requirements {
    padding-left: 10px;
  }

  &.add-project-membership-requirements {
    padding-left: 15px;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: ${theme.colors.colorCalendarBlue};
    cursor: pointer;
    width: fit-content;
  }

  &.requirement-loader {
    padding-left: 20px;
  }

  &.add-member-to-phase {
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
    color: ${theme.colors.colorCalendarGray};
    padding-left: 62px;
  }

  &.project-team-collapse-all {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.colorMediumGray9};
    cursor: pointer;
    padding-left: 13px;

    &.has-less-than-minimum-phase-threshold {
      cursor: default;
    }
    
    .app-cues-expand-collapse-all {
      position: unset;
      border: none;

      &:hover {
        background-color: ${theme.colors.colorPaleGray5};
        path {
          fill: ${theme.colors.colorRoyalBlue};
        }
      }
    }
  }
`;

export const StyledKaratContainer = styled.div<{
  isOpen?: boolean;
  isEmpty?: boolean;
}>`
  height: 18px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
    path {
      stroke: ${theme.colors.colorCalendarBlue};
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};

  ${({ isEmpty }) => `
    visibility: ${isEmpty ? 'hidden' : 'visible'}
  `}
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  padding-left: 5px;
  &:hover {
    circle {
      fill: ${theme.colors.colorRoyalBlue};
    }

    path {
      stroke-width: 1px;
      stroke: ${theme.colors.colorPureWhite};
    }
  }
`;
