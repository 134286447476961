import React from 'react';
import SvgIcon from 'components/SvgIcon';

const HomeScheduledIcon = ({
  width = 18,
  height = 18,
  className,
  currentColor = '#A4A4A4'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="transparent"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.18352 1.2V2.23528H1.2V16.8H16.8L16.7993 2.23528H12.8158V1.2H11.4437V2.23528H6.55561V1.2H5.18352ZM15.4525 15.5483H2.54497V6.99342H15.4525V15.5483ZM6.5397 2.40025H5.2197V3.60025H6.5397V2.40025ZM11.4593 2.40025H12.7793V3.60025H11.4593V2.40025Z"
      fill={currentColor}
    />
  </SvgIcon>
);

export default HomeScheduledIcon;
