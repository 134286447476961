import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';

import { makeGetProjectBasicInfoById } from 'selectors';
import { makeGetPlannerProjectPhase } from 'BudgetModule/selectors';
import QBDownArrow from 'icons/QBDownArrow';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

import { StyledDownArrow, StyledPhaseNameContainer } from './styles';
import PhasesOfWorkDropdown from '../plannerModal/ActivityRowDropdown/PhasesOfWorkDropdown';
import PhaseMilestoneicon from 'icons/PhaseMilestoneIcon';

export const SelectPhase = ({ selectedPhase = {}, showError = false }) => (
  <PhaseNameContainer className={cn({ error: showError })}>
    <StyledSelectPhase>
      <PhaseMilestoneIconContainer>
        <PhaseMilestoneicon />
      </PhaseMilestoneIconContainer>
      {selectedPhase?.name || 'Select Phase'}
    </StyledSelectPhase>
    <StyledDownArrow isBlue>
      <QBDownArrow />
    </StyledDownArrow>
  </PhaseNameContainer>
);

class PhaseSelector extends React.Component {
  state = {
    isOpen: false
  };

  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  openDropdown = () => this.setState({ isOpen: true });
  closeDropdown = () => this.setState({ isOpen: false });
  handleSelect = (item) => {
    const { handleSelect } = this.props;
    handleSelect({ projectId: item.project_id, phaseId: item.id });
  };

  render() {
    const { isOpen } = this.state;
    const { accountId, projectId, selectedPhase, renderName } = this.props;
    return (
      <>
        <div
          ref={(ref) => (this.target = ref)}
          style={{ padding: '5px 0px' }}
          onClick={this.openDropdown}
        >
          {renderName ? (
            renderName(selectedPhase?.name)
          ) : (
            <SelectPhase selectedPhase={selectedPhase} />
          )}
        </div>
        {isOpen && (
          <PhasesOfWorkDropdown
            target={this.target}
            projectId={projectId}
            handleSelect={this.handleSelect}
            handleClose={this.closeDropdown}
            renderHeaderCopy={'Select a Phase'}
            accountId={accountId}
          />
        )}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getProject = makeGetProjectBasicInfoById();
  const getPhase = makeGetPlannerProjectPhase();
  const mapStateToProps = (state, ownProps) => ({
    project: getProject(state, ownProps),
    phase: getPhase(state, ownProps)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(PhaseSelector);

const PhaseMilestoneIconContainer = styled.div`
  path {
    stroke: ${theme.colors.colorRoyalBlue};
  }
  display: flex;
  margin-right: 5px;
`;

const StyledSelectPhase = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorRoyalBlue};
  font-weight: 800;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -1px;
`;

const PhaseNameContainer = styled(StyledPhaseNameContainer)`
  margin-top: -10px;

  &.error:not(:hover) {
    ${StyledSelectPhase} {
      color: ${({ theme }) => theme.colors.colorCalendarRed};
    }
    ${PhaseMilestoneIconContainer} {
      path {
        stroke: ${({ theme }) => theme.colors.colorCalendarRed};
      }
    }
    ${StyledDownArrow} {
      path {
        fill: ${({ theme }) => theme.colors.colorCalendarRed};
      }
    }
  }
`;
