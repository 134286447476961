import { setAuthMethod } from 'AuthenticationModule/actionCreators';
import { preferredAuthMethods } from 'AuthenticationModule/constants';
import { PreferredAuthMethods } from 'AuthenticationModule/types';
import { Radio } from 'components/Radio';
import styled from 'styled-components';
import theme from 'theme';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { useState } from 'react';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { RequestStatusFields } from 'reducers/statuses';

interface SwitchAuthMethodSectionProps {
  preferredAuthMethod: PreferredAuthMethods;
  setAuthMethodRequestStatus: RequestStatusFields<unknown> | undefined;
}

export const SwitchAuthMethodSection = ({
  preferredAuthMethod,
  setAuthMethodRequestStatus
}: SwitchAuthMethodSectionProps) => {
  const [newPreferredAuthMethod, setNewPreferredAuthMethod] = useState<
    PreferredAuthMethods | undefined
  >(undefined);

  const teamId = useAppSelector(getSelectedTeamId);

  const dispatch = useAppDispatch();

  const handleConfirmAuthenticationMethodChange = () => {
    if (newPreferredAuthMethod && teamId) {
      dispatch(
        setAuthMethod({
          authMethod: newPreferredAuthMethod,
          teamId,
          meta: { requestStatusId: setAuthMethod.type }
        })
      );
    }

    setNewPreferredAuthMethod(undefined);
  };

  const handleCancelAuthenticationMethodChange = () => {
    setNewPreferredAuthMethod(undefined);
  };

  return (
    <>
      <RootContainer>
        <Title>Preferred authentication method</Title>
        <Column>
          <Row>
            <Radio
              value={preferredAuthMethods.default}
              size={18}
              disabled={setAuthMethodRequestStatus?.isExecuting}
              checked={preferredAuthMethod === preferredAuthMethods.default}
              onChange={() =>
                setNewPreferredAuthMethod(preferredAuthMethods.default)
              }
            />
            <OptionLabelContainer>Mosaic (Default)</OptionLabelContainer>
          </Row>
          <Row>
            <Radio
              value={preferredAuthMethods.saml}
              size={18}
              disabled={setAuthMethodRequestStatus?.isExecuting}
              checked={preferredAuthMethod === preferredAuthMethods.saml}
              onChange={() =>
                setNewPreferredAuthMethod(preferredAuthMethods.saml)
              }
            />
            <OptionLabelContainer>Google SAML</OptionLabelContainer>
          </Row>
        </Column>
      </RootContainer>
      <SimpleConfirmModal
        isOpen={!!newPreferredAuthMethod}
        header="Change Preferred Authetication Method"
        body=""
        shouldSetIsClosingOnClose={false}
        onConfirm={handleConfirmAuthenticationMethodChange}
        onCancel={handleCancelAuthenticationMethodChange}
      />
    </>
  );
};

const RootContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
  display: flex;
  align-items: center;
  width: 300px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const OptionLabelContainer = styled.span`
  margin-left: 5px;
`;
