import { SortAttribute } from 'FilterModule/constants';

export const LIMIT = 20;

export const NO_GROUPING_GROUP_BYS = {
  [SortAttribute.CreatedDate]: true
};

export const REQUEST_MODAL_TRIGGER_ID = 'scope-requests-table-request-modal';

export enum UnplannedTableEntityType {
  ScopeRequest = 'ScopeRequest',
  Scope = 'Scope',
  WorkplanRequest = 'WorkplanRequest'
}

export const unplannedTableRequestEntityTypeSet = new Set([
  UnplannedTableEntityType.ScopeRequest,
  UnplannedTableEntityType.WorkplanRequest
]);
