import {
  getWorkloadEventActor,
  getCheckins,
  getCheckinsHours
} from 'selectors';
import theme from 'theme';
import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCheckins,
  fetchCheckinsHours,
  fetchAllProjects,
  fetchPhasesByProjectIds
} from 'actionCreators';
import moment from 'appUtils/momentConfig';
import DailyCheckinItem from 'views/Home/Widgets/Widgets/DailyCheckinWidget/DailyCheckinItem';
import { calculateCheckInTotalHours } from 'appUtils/checkInUtils';

const StyledList = styled.div`
  width: 340px;
  margin: auto;
  max-height: 700px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-bottom: 10px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
  margin-top: 20px;
`;

const WorkedHours = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  width: 40px;
  text-align: right;
`;

const HoursDivider = styled.div`
  color: ${theme.colors.colorLightGray15};
`;

const TotalHours = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 400;
`;

const HoursContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const CheckinEventDetail = ({ selectedEvent }) => {
  const dispatch = useDispatch();
  const workloadEventActor = useSelector(getWorkloadEventActor);
  const checkins = useSelector(getCheckins);
  const checkinsHours = useSelector(getCheckinsHours);

  const sortedCheckins = useMemo(() => {
    const checkinsCopy = [...checkins];
    const sortedArray = checkinsCopy.sort((a, b) => {
      const time1 = moment(a.created_at);
      const time2 = moment(b.created_at);
      return time1.isBefore(time2) ? 1 : -1;
    });
    return sortedArray;
  }, [checkins]);

  useEffect(() => {
    if (workloadEventActor && selectedEvent) {
      const date = selectedEvent.metadata?.date_range?.split(' ')[0];
      if (date) {
        dispatch(
          fetchCheckins({
            body: {
              date: moment(date).format('YYYY-MM-DD'),
              account_ids: [workloadEventActor.id],
              submittal_status: 'submitted'
            }
          })
        );
        const body = {
          account_ids: [workloadEventActor.id],
          offset: 0,
          limit: 50,
          team_id: selectedEvent.team_id,
          start_date: moment(date).format('MM/DD/YYYY'),
          end_date: moment(date).format('MM/DD/YYYY')
        };
        dispatch(
          fetchCheckinsHours({
            body: body,
            initial: true
          })
        );
      }
    }
  }, [workloadEventActor, selectedEvent, dispatch]);

  useEffect(() => {
    dispatch(
      fetchAllProjects({
        projectIds: checkins.map((checkin) => checkin.project_id)
      })
    );
    dispatch(
      fetchPhasesByProjectIds({
        projectIds: checkins.map((checkin) => checkin.project_id)
      })
    );
  }, [checkins, dispatch]);

  const totalHoursArray = useMemo(
    () =>
      checkins.map((checkin) =>
        calculateCheckInTotalHours(
          checkin,
          checkinsHours,
          workloadEventActor.id
        )
      ),
    [checkins, checkinsHours, workloadEventActor.id]
  );

  const workedHoursValue = useMemo(() => {
    return checkins.reduce(
      (total, checkin) => Number(checkin.estimated_hours) + total,
      0
    );
  }, [checkins]);

  const totalHoursValue = useMemo(() => {
    return totalHoursArray.reduce(
      (total, itemTotalHours) => itemTotalHours + total,
      0
    );
  }, [totalHoursArray]);

  return (
    <StyledList>
      {sortedCheckins.map((item, index) => (
        <DailyCheckinItem
          checkin={item}
          totalHours={totalHoursArray[index]}
          key={item.id}
          isNotification
        />
      ))}
      {sortedCheckins.length > 0 && (
        <TotalRow>
          <div>Total</div>
          <HoursContainer>
            <WorkedHours> {Number(workedHoursValue)}h </WorkedHours>
            <HoursDivider>|</HoursDivider>
            <TotalHours> {totalHoursValue}h</TotalHours>
          </HoursContainer>
        </TotalRow>
      )}
    </StyledList>
  );
};

export default CheckinEventDetail;
