import React from 'react';
import SvgIcon from 'components/SvgIcon';

const OpenBookIcon = ({
  width = '28',
  height = '28',
  circleFill = '#CA58D8',
  pathStroke = '#CD7BD7'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill={circleFill} />
      <path
        d="M14 21.9331V10.7814C12.95 9.89632 8.54 8.30305 5.6 8.72788V20.4109C8.54 19.9861 12.95 21.048 14 21.9331Z"
        fill="white"
        stroke={pathStroke}
        strokeWidth="0.5"
      />
      <path
        d="M14 20.8716V9.71985C12.95 8.83479 9.59 7.24152 6.65 7.66635V19.3494C9.59 18.9246 12.95 19.9865 14 20.8716Z"
        fill="white"
        stroke={pathStroke}
        strokeWidth="0.5"
      />
      <path
        d="M14 19.8111V8.65943C12.95 7.77437 10.64 6.17895 7.7 6.60377V18.2868C10.64 17.862 12.95 18.9261 14 19.8111Z"
        fill="white"
        stroke={pathStroke}
        strokeWidth="0.5"
      />
      <path
        d="M14 21.9331V10.7814C15.05 9.89632 19.46 8.30305 22.4 8.72788V20.4109C19.46 19.9861 15.05 21.048 14 21.9331Z"
        fill="white"
        stroke={pathStroke}
        strokeWidth="0.5"
      />
      <path
        d="M14 20.8716V9.71985C15.05 8.83479 18.41 7.24152 21.35 7.66635V19.3494C18.41 18.9246 15.05 19.9865 14 20.8716Z"
        fill="white"
        stroke={pathStroke}
        strokeWidth="0.5"
      />
      <path
        d="M14 19.8111V8.65943C15.05 7.77437 17.36 6.17895 20.3 6.60377V18.2868C17.36 17.862 15.05 18.9261 14 19.8111Z"
        fill="white"
        stroke={pathStroke}
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};

export default OpenBookIcon;
