import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserActivitiesForActionable } from 'actionCreators';
import { getUserActivities } from 'selectors';
import {
  throttleUserActivities,
  defaultEventStreamThrottleConfig
} from 'appUtils/momentUtils';
import { actionableTypesHash } from 'appConstants/userActivities';
import moment from 'moment';
import { ActivityPhaseScheduleBarUserActivity } from '../models/activityPhaseScheduleBarUserActivity';

const defaultThrottle = (
  userActivities: Array<ActivityPhaseScheduleBarUserActivity>
) => throttleUserActivities(userActivities, defaultEventStreamThrottleConfig);

interface useActivityLogsProps {
  actionableId?: number;
  actionableType: string;
}
export const useWorkPlanActivityLogs = ({
  actionableId,
  actionableType = actionableTypesHash.ActivityPhaseScheduleBar
}: useActivityLogsProps) => {
  const dispatch = useDispatch();
  const userActivities = useSelector(getUserActivities);
  const throttledActivities = defaultThrottle(userActivities);

  useEffect(() => {
    if (actionableId) {
      dispatch(
        fetchUserActivitiesForActionable({
          actionable_id: actionableId,
          actionable_type: actionableType,
          start_date: moment().add(-1, 'year').format('YYYY-MM-DD'),
          end_date: moment().add(1, 'day').format('YYYY-MM-DD')
        })
      );
    }
  }, [dispatch, actionableId, actionableType]);

  return { activityLogs: actionableId ? throttledActivities : [] };
};
