// import StandardGeneralTab from 'SettingsModule/components/StandardSettings/General/StandardGeneralTab';
import StandardPhasesTab from 'SettingsModule/components/StandardSettings/Phases/StandardPhasesTab';
import StandardMilestonesTab from 'SettingsModule/components/StandardSettings/Milestones/StandardMilestonesTab';
import StandardRatesTab from 'SettingsModule/components/StandardSettings/Rates/StandardRatesTab';
import StandardRolesTab from 'SettingsModule/components/StandardSettings/Roles/StandardRolesTab';
import StandardActivityTab from 'SettingsModule/components/StandardSettings/Activity/StandardActivityTab';
import StandardHolidaysTab from 'SettingsModule/components/StandardSettings/Holidays/StandardHolidaysTab';
import StandardProjectsTab from 'SettingsModule/components/StandardSettings/Projects/StandardProjectsTab';
import StandardProfileTab from 'SettingsModule/components/StandardSettings/Profile/StandardProfileTab';
import { StandardPTOTab } from 'SettingsModule/components/StandardSettings/PTO/StandardPTOTab';
import { StandardCapacityTab } from 'SettingsModule/components/StandardSettings/Capacity/StandardCapacityTab';

// Settings / Standards
export const StandardSettingsViews = {
  // general: StandardGeneralTab,
  phases: StandardPhasesTab,
  milestones: StandardMilestonesTab,
  work_categories: StandardActivityTab,
  roles: StandardRolesTab,
  rates: StandardRatesTab,
  holidays: StandardHolidaysTab,
  projects: StandardProjectsTab,
  profile: StandardProfileTab,
  pto: StandardPTOTab,
  capacity: StandardCapacityTab
};
