import theme from 'theme';

export const scheduleConfig = {
  calendarColors: {
    dayOfWeek: {
      default: theme.colors.colorMediumGray9
    },
    dateText: {
      default: theme.colors.colorSemiDarkGray1,
      weekend: theme.colors.colorCalendarGray,
      differentMonth: theme.colors.colorLightGray6,
      today: theme.colors.colorLightOrange2
    }
  },
  size: {
    title: {
      height: 32
    },
    header: {
      height: 30
    },
    footer: {
      height: 40
    },
    calendarContent: {
      minWidth: 408,
      minHeight: 270
    },
    spacing: {
      monthGap: 30
    }
  }
};
