import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAppCuesFlowId, getMe, getAuth } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';
import PresentationToolIcon from 'icons/PresentationToolIcon';
import ExclamationMarkInsideCloudIcon from 'icons/ExclamationMarkInsideCloudIcon';
import OpenBookIcon from 'icons/OpenBookIcon';
import EnvelopeIcon from 'icons/EnvelopeIcon';
import LiveChatIcon from 'icons/LiveChatIcon';
import CloseIcon from 'icons/NewCloseIcon';
import QuestionMarkIcon from 'icons/QuestionMarkIcon';
import LINKS from 'appConstants/links';
import PATHS from 'appConstants/paths';
import { INTRO_VIDEOS_FLOW_ID } from 'appConstants';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import PlayVideoIcon from 'icons/PlayVideoIcon';
import QuestionMarkInsideRectangleIcon from 'icons/QuestionMarkInsideRectangleIcon';
import ReactTooltip from 'react-tooltip';

const HelpMenuContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000000;
`;
const HelpMenuTrigger = styled.button`
  position: relative;
  right: 20px;
  bottom: 20px;
  width: 48px;
  height: 48px;
  outline: none;
  border: none;
  border-radius: 50%;
  color: ${theme.colors.colorPureWhite};
  font-size: 27px;
  font-weight: 600;
  background: ${theme.colors.colorMediumBlue1};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledHelpMenu = styled.div`
  background: ${theme.colors.colorPureWhite};
  padding: 40px 0 0 0;
  width: 250px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
`;
const Title = styled.div`
  color: ${theme.colors.colorMediumGray7};
  font-size: 15px;
  font-weight: 600;
  padding-right: 15px;
`;
const Description = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  &.watch-video,
  &.help-overlay,
  &.help-center {
    color: ${theme.colors.colorMediumGray7};
  }
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 25px;
  background: ${theme.colors.colorPureWhite};
  cursor: pointer;

  .karat-right {
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'unset')};
  }
  &:hover {
    text-decoration: none;
    filter: brightness(98%);
  }
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
const MenuItemLogo = styled.div`
  margin-right: 12px;
`;

const CloseIconContainer = styled.div`
  right: 13px;
  top: 13px;
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  circle {
    fill: ${theme.colors.colorPureWhite};
    stroke: transparent;
  }

  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray5};
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const showAppCuesFlow = (flowId) =>
  window.Appcues && window.Appcues.show(flowId);

const HelpMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showHelpOverlay, setShowHelpOverlay] = useState(false);
  const me = useSelector(getMe);
  const appCuesId = useSelector(getAppCuesFlowId);
  const { pathname } = useLocation();
  const auth = useSelector(getAuth);
  const isFullyLoggedIn =
    me?.id && !auth?.isAuthenticating && auth?.isAuthenticated;

  /** Menu action */
  const toggleMenu = useCallback(() => {
    // Rebuild the tooltip for the help menu button if the menu is closing.
    if (menuOpen) {
      ReactTooltip.rebuild();
    }

    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const [expandedMenuIds, setExpandedMenuId] = useState({});

  const closeMenu = useCallback(() => setMenuOpen(false), []);

  const toggleChat = useCallback(() => setShowChat(!showChat), [showChat]);

  const onLiveChatClick = useCallback(() => {
    closeMenu();
    toggleChat();
    window.HappyFoxChat && window.HappyFoxChat.expandChatbox();
  }, [closeMenu, toggleChat]);

  const onCloseChat = () => {
    setShowChat(false);
    if (window.HappyFoxChat) {
      window.HappyFoxChat.collapseChatbox();
      window.HappyFoxChat.unsetVisitor();
    }
  };

  const getIntroVideo = () => {
    if (pathname.includes(PATHS.SETTINGS)) {
      if (pathname.endsWith('members/budget')) {
        showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.COST_RATE_BILL_RATE_VIDEO);
      } else if (pathname.endsWith(PATHS.INTEGRATION)) {
        showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.SELECT_INTEGRATION_HELP_VIDEO);
      } else if (
        pathname.includes(PATHS.INTEGRATION) &&
        !pathname.endsWith(PATHS.INTEGRATION)
      ) {
        showAppCuesFlow(
          INTRO_VIDEOS_FLOW_ID.IMPORTING_OR_LINKING_DATA_INTEGRATION_HELP_VIDEO
        );
      } else {
        window.open(LINKS.ALL_VIDEO_URL);
      }
    } else if (pathname.includes(PATHS.BOARDS)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.PORTFOLIO_HELP_VIDEO);
    } else if (pathname.includes(PATHS.REPORTS_TIMESHEET_STATUS)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.TIME_SHEET_STATUS_REPORT_VIDEO);
    } else if (pathname.includes(PATHS.REPORTS_TIMESHEET)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.TIME_SHEET_REPORT_VIDEO);
    } else if (pathname.includes(PATHS.REPORTS_VARIANCE)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.VARIANCE_REPORT_VIDEO);
    } else if (pathname.includes(PATHS.REPORTS_TIMESHEET_ACTIVITY)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.TIMESHEET_ACTIVITY_HELP_VIDEO);
    } else if (
      pathname.includes(PATHS.BUDGET_MODAL_STANDALONE) ||
      pathname.endsWith(PATHS.BUDGET)
    ) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.BUDGET_WALKTHROUGH_VIDEO);
    } else if (pathname.includes(PATHS.REPORTS_BUDGETS)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.BUDGET_REPORT_VIDEO);
    } else if (pathname.includes(PATHS.REPORTS_WORKLOAD)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.WORKLOAD_REPORT_VIDEO);
    } else if (pathname.includes(PATHS.WORKLOAD_MEMBERS)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.WORKLOAD_MEMBERS_VIDEO);
    } else if (pathname.includes(PATHS.WORKLOAD_PROJECTS)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.WORKLOAD_PROJECTS_VIDEO);
    } else if (
      pathname.includes(PATHS.PROJECT) &&
      !pathname.includes(PATHS.BUDGET)
    ) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.PROJECT_OVERVIEW_HELP_VIDEO);
    } else if (
      pathname.includes(PATHS.HOME) &&
      !pathname.includes(PATHS.TIME)
    ) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.HOME_HELP_VIDEO);
    } else if (
      pathname.includes(PATHS.MEMBERS_MEMBERS) ||
      pathname.includes(PATHS.MEMBERS_DEPARTMENTS)
    ) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.MEMBERS_SETTINGS_VIDEO);
    } else if (pathname.includes(PATHS.HOME_TIME)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.TIMESHEET_HELP_VIDEO);
    } else if (pathname.includes(PATHS.DASHBOARD)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.DASHBOARD_VIDEO);
    } else if (pathname.includes(PATHS.PLANNER)) {
      showAppCuesFlow(INTRO_VIDEOS_FLOW_ID.PLANNER_VIDEO);
    } else {
      window.open(LINKS.ALL_VIDEO_URL);
    }
    closeMenu();
  };

  useEffect(() => {
    if (showHelpOverlay) {
      showAppCuesFlow(appCuesId);
    } else {
      showAppCuesFlow('-M2_5XqX82l7a6h-hpyD');
    }
  }, [appCuesId, showHelpOverlay]);

  const onHelpOverlayClick = useCallback(() => {
    closeMenu();
    setShowHelpOverlay(!showHelpOverlay);
  }, [closeMenu, showHelpOverlay]);

  const onHelpMenuContainerClick = () => {
    if (isFullyLoggedIn && window.HappyFoxChat) {
      window.HappyFoxChat.setVisitorInfo({
        name: me.name,
        email: me.email
      });
    }
  };

  const onHelpCenterClick = useCallback(() => {
    closeMenu();
    const doc360Iframe = document.getElementById('document360-widget-iframe');
    const doc360Button =
      doc360Iframe?.contentWindow.document.getElementById('doc360-button');
    doc360Button && doc360Button.click();
  }, [closeMenu]);

  const onSendFeedbackClick = useCallback(() => {
    if (isFullyLoggedIn) {
      const { name, email } = me;
      const timestamp = moment.utc().format();
      Sentry.captureMessage(`User ${name} sent feedback at ${timestamp}`, {
        name,
        email,
        isUserFeedback: true
      });
    }

    closeMenu();
  }, [closeMenu, isFullyLoggedIn, me]);

  const getIsMenuExpanded = useCallback(
    (id) => expandedMenuIds[id],
    [expandedMenuIds]
  );

  const expandMenu = (id) => {
    setExpandedMenuId({
      ...expandedMenuIds,
      [id]: !getIsMenuExpanded(id)
    });
  };

  const menuItems = [
    {
      main: {
        onClick: expandMenu,
        id: 'learn',
        title: 'Learn',
        description: 'Written how-to',
        icon: <PresentationToolIcon />
      },
      subItems: [
        {
          onClick: onHelpCenterClick,
          id: 'help-center',
          title: 'Help Center',
          description: 'Written how-to',
          icon: <OpenBookIcon />
        },
        {
          onClick: onHelpOverlayClick,
          id: 'help-overlay',
          title: 'Help Overlay',
          description: 'Visual how-to',
          icon: <QuestionMarkIcon fill={theme.colors.colorMediumBlue5} />
        }
      ]
    }
  ];

  const secondMenuItems = [
    {
      main: {
        onClick: expandMenu,
        id: 'report-issue',
        title: 'More Help',
        icon: <QuestionMarkInsideRectangleIcon />
      },
      subItems: [
        {
          id: 'email-support',
          title: 'Email Support',
          description: 'Non-urgent help',
          icon: <EnvelopeIcon />,
          as: 'a',
          href: 'mailto:support@mosaicapp.com'
        },
        {
          onClick: onSendFeedbackClick,
          id: 'report-issue',
          title: 'Report Issue',
          description: 'Submit crash reports',
          icon: <ExclamationMarkInsideCloudIcon />
        }
      ]
    }
  ];

  const renderMenuItems = (menuItems) => {
    return menuItems.map(({ main, subItems }) => (
      <div
        style={{
          paddingBottom: '0.5rem'
        }}
        key={main.id}
      >
        {' '}
        <MenuItem
          isExpanded={getIsMenuExpanded(main.id)}
          onClick={() => main.onClick(main.id)}
        >
          <MenuItemLogo>{main.icon}</MenuItemLogo>
          <TitleContainer>
            <Title>{main.title}</Title>
            <KaratRight width="13" height="13" />
          </TitleContainer>
        </MenuItem>
        <div
          style={{
            display: getIsMenuExpanded(main.id) ? 'block' : 'none'
          }}
        >
          {subItems.map(
            ({ id, as, href, onClick, title, description, icon }) => (
              <MenuItem
                style={{
                  paddingLeft: '2.5rem'
                }}
                key={id}
                as={as}
                id={id}
                href={href}
                onClick={onClick}
              >
                <MenuItemLogo>{icon}</MenuItemLogo>
                <div>
                  <Title className={id}>{title}</Title>
                  <Description className={id}>{description}</Description>
                </div>
              </MenuItem>
            )
          )}
        </div>
      </div>
    ));
  };

  return (
    <HelpMenuContainer
      onClick={onHelpMenuContainerClick}
      id="help-menu-container"
    >
      {!menuOpen && (
        <HelpMenuTrigger
          data-testid="help-menu-btn"
          onClick={showChat ? onCloseChat : toggleMenu}
          data-effect="solid"
          data-for="app-tooltip"
          data-html // Required for the ampersand
          data-tip="Help & Learning Center"
        >
          {showChat ? `\u2715` : '?'}
        </HelpMenuTrigger>
      )}
      {menuOpen && (
        <StyledHelpMenu>
          <CloseIconContainer onClick={toggleMenu}>
            <CloseIcon width="30" height="30" fillColor="#000000" />
          </CloseIconContainer>
          <MenuItem onClick={getIntroVideo}>
            <MenuItemLogo>
              <PlayVideoIcon
                height={30}
                width={29}
                fill={theme.colors.colorMediumBlue4}
              />
            </MenuItemLogo>
            <div>
              <Title>Watch a Video</Title>
              <Description className="watch-video">of this space</Description>
            </div>
          </MenuItem>
          {renderMenuItems(menuItems)}
          <MenuItem onClick={onLiveChatClick}>
            <MenuItemLogo>
              <LiveChatIcon
                height={30}
                width={29}
                fill={theme.colors.colorDarkGreen3}
              />
            </MenuItemLogo>
            <div>
              <Title>Live Chat</Title>
            </div>
          </MenuItem>
          {renderMenuItems(secondMenuItems)}
        </StyledHelpMenu>
      )}
    </HelpMenuContainer>
  );
};

export default React.memo(HelpMenu);
