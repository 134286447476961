import styled from 'styled-components';
import cn from 'classnames';

export const BarPropertiesText = styled.span.attrs<{ $bold?: boolean }>(
  ({ $bold }) => ({
    className: cn({ bold: $bold })
  })
)<{ $bold?: boolean }>`
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
`;
