import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isNumeric } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportPtoPolicy
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingPtoPoliciesByDescription: Record<string, CsvImportPtoPolicy>;
  newPtoDescriptions: React.MutableRefObject<Record<string, boolean>>;
  newPtoDescriptionHoursHashes: React.MutableRefObject<Record<string, string>>;
}

export const ptoDescriptionValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingPtoPoliciesByDescription,
    newPtoDescriptions,
    newPtoDescriptionHoursHashes
  } = additionalParams;

  const { enteredPtoHours, enteredPtoDescription } = getRowValuesForEntityType(
    row,
    entityType
  );
  if (enteredPtoDescription === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (enteredPtoHours && !enteredPtoDescription)
    return ERROR_MESSAGES.ptoDescriptionRequired;

  const existingMatchedPtoPolicyByDescription =
    existingPtoPoliciesByDescription[enteredPtoDescription];
  const newPtoHoursMatchedByDescription =
    newPtoDescriptionHoursHashes.current[enteredPtoDescription];
  if (enteredPtoDescription) {
    const ptoDescriptionIsNewOption =
      !existingMatchedPtoPolicyByDescription &&
      !newPtoHoursMatchedByDescription &&
      !newPtoDescriptions.current[enteredPtoDescription];
    // case 1: entered pto hours is not empty
    if (enteredPtoHours) {
      const enteredValuesIsNewCombination =
        !existingMatchedPtoPolicyByDescription &&
        !newPtoHoursMatchedByDescription;
      const enteredPtoHoursDoesNotMatchDescription =
        existingMatchedPtoPolicyByDescription?.hours !== enteredPtoHours &&
        newPtoHoursMatchedByDescription !== enteredPtoHours;
      // case 1.1: the pto hours and pto description is new combination
      if (enteredValuesIsNewCombination) {
        // only add into hash if pto hours is valid number
        if (isNumeric(enteredPtoHours))
          newPtoDescriptionHoursHashes.current[enteredPtoDescription] =
            enteredPtoHours;
      } else if (
        // case 1.2: the entered pto hours does not match to entered pto description
        enteredPtoHoursDoesNotMatchDescription
      ) {
        // rate description exists but rate amount does not match
        return ERROR_MESSAGES.ptoHoursDoesNotMatchDescription;
      }
    } else if (
      // case 2: entered pto hours is empty, only add pto description as new option if it was not an option before.
      ptoDescriptionIsNewOption
    )
      newPtoDescriptions.current[enteredPtoDescription] = true;
  }

  return true;
};
