import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { EntityRate } from 'RatesModule/models/EntityRate';
import { PropsWithChildren } from 'react';
import { EntityRateModalColumn } from '../types';
import { TableCell } from './TableCell';

export const BodyCell = ({
  children,
  columnId,
  disabledTooltip,
  entityRate,
  isSoleRate,
  onClick
}: PropsWithChildren<{
  columnId: EntityRateModalColumn;
  disabledTooltip?: string;
  entityRate: EntityRate;
  isSoleRate: boolean;
  onClick?: (params: {
    columnId: EntityRateModalColumn;
    entityRate: EntityRate;
  }) => void;
}>) => {
  const handleClick = onClick
    ? () => onClick({ columnId, entityRate })
    : undefined;

  return (
    <TableCell
      $isSoleRate={isSoleRate}
      className={columnId}
      {...defaultTooltipProps}
      data-tip={disabledTooltip}
      onClick={handleClick}
    >
      {children}
    </TableCell>
  );
};
