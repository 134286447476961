import { useCallback } from 'react';
import { serializeId } from 'appUtils';
import { ROW_TYPES } from '../tableConfigs';
import {
  FilterListItemsBuilder,
  FilterListItem
} from 'FilterModule/components/FilterListsTable/types';

export const useFilterListItemsBuilder = () => {
  const filterListItemsBuilder: FilterListItemsBuilder = useCallback(
    ({
      order,
      parentGroupId,
      filterValues,
      filterListType,
      resultCountHash
    }) => {
      const {
        itemHash,
        getIsSelected,
        toggleSelectedItem,
        labelKey,
        isGrouped
      } = filterValues;

      const listItems = order.map((itemOrId, index) => {
        let currItem;

        if (itemHash) {
          currItem = itemHash[itemOrId];
          if (!currItem) return;
        } else {
          currItem = itemOrId;
        }

        const id = serializeId({
          itemType: filterListType,
          ids: [
            // when list items are not under a nested grouping, parentGroupId will be the FilterListType
            parentGroupId === filterListType ? parentGroupId : '',
            itemOrId
          ],
          id: undefined
        });

        const resultCount = resultCountHash
          ? resultCountHash?.[itemOrId] || 0
          : undefined;

        const isSelected = Boolean(getIsSelected?.(itemOrId));

        const filterListItem: FilterListItem = {
          item: currItem,
          filterListType,
          labelText:
            typeof currItem === 'string'
              ? currItem
              : labelKey
              ? currItem[labelKey]
              : '',
          count: resultCount,
          className: resultCount === 0 && !isSelected ? 'muted' : undefined,
          toggleSelect: () => toggleSelectedItem?.(itemOrId),
          isSelected,
          rowType: isGrouped
            ? ROW_TYPES.defaultGroupedFilterItemRow // use case: apply different styling for grouped list items
            : ROW_TYPES.defaultFilterItemRow,
          rowHeight: 40,
          id,
          index
        };

        return filterListItem;
      });
      return listItems.filter((list) => list) as FilterListItem[];
    },
    []
  );

  return filterListItemsBuilder;
};
