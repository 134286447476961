import * as constants from '../constants';
import { createAction } from 'appUtils';

const createWSRemovalAction = (type, payload, channels) => {
  const action = {
    type
  };
  if (payload) {
    action.payload = payload;
  }
  if (channels) {
    action.payload = {
      ...action.payload,
      channels
    };
  }
  return action;
};

export const createNote = (params) =>
  createAction(constants.NOTE_CREATION.TRIGGER, params);
export const deleteNote = (params) =>
  createAction(constants.DELETE_NOTE.TRIGGER, params);
export const updateNote = (params) =>
  createAction(constants.NOTE_UPDATE.TRIGGER, params);

export const fetchNotesByProject = (params) => {
  return {
    type: constants.FETCH_NOTES_BY_PROJECT.TRIGGER,
    payload: {
      params // projectId, limit, offset, searchText, accountIds, tagIds
    }
  };
};

export const fetchProjectNotesV2 = (params) =>
  createAction(constants.FETCH_PROJECT_NOTES_V2.TRIGGER, params);

export const fetchNote = (params) =>
  createAction(constants.FETCH_NOTE.TRIGGER, params);
