import { MembersTimesheetSettingsTableCells } from '../Cells';
import { BaseTableColumn, EmptyRowListItem } from 'components/Table/types';
import { EmptyDiv } from 'components/EmptyDiv';
import sumBy from 'lodash/sumBy';
import { ProjectGuestFooterLabel } from '../Cells/ProjectGuestFooterLabel';

const {
  MemberTypeSectionHeaderCell,
  HeaderCell,
  NameCell,
  EmailCell,
  TimeEntrySettingCell,
  ReminderSettingCell,
  ArchivedSectionHeaderCell
} = MembersTimesheetSettingsTableCells;

const ROW_TYPES = {
  memberHeaderRow: 'memberHeaderRow',
  columnHeaderRow: 'columnHeaderRow',
  memberRow: 'memberRow',
  archivedSectionHeaderRow: 'archivedSectionHeaderRow',
  projectGuestFooter: 'projectGuestFooter'
};

const columnTypes = {
  tableWidth: 'tableWidth',
  leadingColumn: 'leadingColumn',
  name: 'name',
  email: 'email',
  timeEntrySetting: 'timeEntrySetting'
  // reminderSetting: 'reminderSetting'
};

const _columnWidths = {
  [columnTypes.leadingColumn]: 32,
  [columnTypes.name]: 250,
  [columnTypes.email]: 239,
  [columnTypes.timeEntrySetting]: 222
  // [columnTypes.reminderSetting]: 141
};

const tableWidthColumn: BaseTableColumn = {
  id: 'tableWidth',
  headerType: 'tableWidth',
  align: 'center'
};

const headersToIgnore = [tableWidthColumn.id];

const leadingColumn: BaseTableColumn = {
  id: columnTypes.leadingColumn,
  headerType: columnTypes.leadingColumn
};

const nameColumn: BaseTableColumn = {
  id: columnTypes.name,
  headerType: columnTypes.name,
  headerLabel: 'NAME'
};

const emailColumn: BaseTableColumn = {
  id: columnTypes.email,
  headerType: columnTypes.email,
  headerLabel: 'EMAIL'
};

const timeEntrySettingColumn: BaseTableColumn = {
  id: columnTypes.timeEntrySetting,
  headerType: columnTypes.timeEntrySetting,
  headerLabel: 'TIME ENTRY'
};

// const reminderSettingColumn: BaseTableColumn = {
//   id: columnTypes.reminderSetting,
//   headerType: columnTypes.reminderSetting,
//   headerLabel: 'REMINDER'
// };

const allColumns: BaseTableColumn[] = [
  tableWidthColumn,
  leadingColumn,
  nameColumn,
  emailColumn,
  timeEntrySettingColumn
  // reminderSettingColumn
];

// does not include tableWidth
const totalColumnWidths = sumBy(
  allColumns,
  (column) => _columnWidths[column.headerType] ?? 0
);

const columnWidths = {
  ..._columnWidths,
  [columnTypes.tableWidth]: totalColumnWidths
};

const maxTotalWidth = totalColumnWidths;

const rowToCells = {
  [ROW_TYPES.memberHeaderRow]: {
    [columnTypes.tableWidth]: MemberTypeSectionHeaderCell
  },
  [ROW_TYPES.columnHeaderRow]: {
    [columnTypes.leadingColumn]: EmptyDiv,
    [columnTypes.name]: HeaderCell,
    [columnTypes.email]: HeaderCell,
    [columnTypes.timeEntrySetting]: HeaderCell
    // [columnTypes.reminderSetting]: HeaderCell
  },
  [ROW_TYPES.memberRow]: {
    [columnTypes.leadingColumn]: EmptyDiv,
    [columnTypes.name]: NameCell,
    [columnTypes.email]: EmailCell,
    [columnTypes.timeEntrySetting]: TimeEntrySettingCell
    // [columnTypes.reminderSetting]: ReminderSettingCell
  },
  [ROW_TYPES.archivedSectionHeaderRow]: {
    [columnTypes.tableWidth]: ArchivedSectionHeaderCell
  },
  [ROW_TYPES.projectGuestFooter]: {
    [columnTypes.tableWidth]: ProjectGuestFooterLabel
  }
};

const stickyHeaderCells = rowToCells[ROW_TYPES.memberHeaderRow];

const emptyRow: EmptyRowListItem = {
  rowType: 'emptyRow',
  rowHeight: 40,
  isDragDisabled: true
};

export const membersTimesheetSettingsTableHelpers = {
  ROW_TYPES,
  columnTypes,
  totalColumnWidths,
  columnWidths,
  maxTotalWidth,
  headersToIgnore,
  allColumns,
  rowToCells,
  stickyHeaderCells,
  emptyRow
};
