import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CheckIcon = ({
  fill = '#fff',
  width = '16',
  height = '12',
  className = ''
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fillRule="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5102 12L0 6.24184L1.55388 4.61804L5.5102 8.74088L13.8747 0L15.4286 1.63532L5.5102 12Z"
      fill={fill}
    />
  </SvgIcon>
);

export default CheckIcon;
