import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TaskListIcon = ({
  width = 12,
  height = 10,
  color = '#9b9b9b',
  className,
  style
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    style={style}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.22447 0.714286C1.22447 1.10877 0.950364 1.42857 0.612236 1.42857C0.274107 1.42857 0 1.10877 0 0.714286C0 0.319797 0.274107 0 0.612236 0C0.950364 0 1.22447 0.319797 1.22447 0.714286Z"
      fill={color}
    />
    <path
      d="M1.22447 4.71429C1.22447 5.10877 0.950364 5.42857 0.612236 5.42857C0.274107 5.42857 0 5.10877 0 4.71429C0 4.3198 0.274107 4 0.612236 4C0.950364 4 1.22447 4.3198 1.22447 4.71429Z"
      fill={color}
    />
    <path
      d="M1.22447 8.71429C1.22447 9.10877 0.950364 9.42857 0.612236 9.42857C0.274107 9.42857 0 9.10877 0 8.71429C0 8.3198 0.274107 8 0.612236 8C0.950364 8 1.22447 8.3198 1.22447 8.71429Z"
      fill={color}
    />
    <path d="M12 0.214355H3.42871V1.21436H7.71436H12V0.214355Z" fill={color} />
    <path
      d="M12 4.21436L3.42871 4.21436L3.42871 5.21436H7.71436L12 5.21436V4.21436Z"
      fill={color}
    />
    <path d="M12 8.21436H3.42871V9.21436H7.71436H12V8.21436Z" fill={color} />
  </SvgIcon>
);

export default TaskListIcon;
