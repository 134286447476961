import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamSlug } from 'selectors';
import {
  navigateToMembersRatesSettings,
  navigateToMembersSettings
} from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';
import { Modal } from 'reactstrap';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import capitalize from 'lodash/capitalize';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';

const SetDefaultPromptModal = ({ type, onClose, onCancel }) => {
  const teamSlug = useSelector(getTeamSlug);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(
      type === 'role'
        ? navigateToMembersSettings({
            teamSlug
            // openInNewWindow: true // no web sockets yet
          })
        : navigateToMembersRatesSettings({
            teamSlug
            // openInNewWindow: true
          })
    );
  };

  return (
    <SimpleConfirmModal
      isOpen
      toggle={onClose}
      stylesWrapper={DefaultPromptModal}
      hasDoNotShowAgain
      onCancel={onCancel}
      onConfirm={handleConfirm}
      header={`Set Default ${capitalize(type)}`}
      body={`Do you want to set a default ${type} for this member?`}
      doNotShowAgainLabel="Don't show this again."
      doNotShowAgainSettingName={`do_not_show_set_default_project_${type}`}
      confirmLabel={
        <>
          {/* <GoToProjectIcon /> */}
          <span>Set Default</span>
        </>
      }
      cancelLabel="No"
    />
  );
};

export default SetDefaultPromptModal;

/* ------------------------------------ - ----------------------------------- */

const DefaultPromptModal = styled(Modal)`
  color: ${theme.colors.colorMediumGray7};
  .confirm-modal-header {
    font-size: 22px;
    color: black;
  }
  .confirm-modal-body {
    font-size: 13px;
    margin-bottom: 17px;
  }
  .confirm-modal-confirm-btn {
    font-weight: 600;
    height: 32px;
    /* padding: 0 12px 0 6px; */ // only when GoTo icon used
    svg {
      flex-shrink: 0;
      width: 22px;
      path {
        fill: white;
      }
    }
  }
  .confirm-modal-do-not-show {
    font-size: 13px;
    color: black;
    margin-bottom: 40px;
  }
  .modal-content {
    width: 400px;
    padding: 30px 35px;
    border-radius: 6px !important;
  }
`;
