import React from 'react';
import SvgIcon from 'components/SvgIcon';

const DollarIcon = ({
  className,
  color = '#A4A4A4',
  fill = 'none',
  width = 9,
  height = 16,
  style
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 9 16"
    style={style}
  >
    <path
      d="M4.5 15.9997C4.2 15.9997 4 15.7447 4 15.3622V1.33721C4 0.954706 4.2 0.699707 4.5 0.699707C4.8 0.699707 5 0.954706 5 1.33721V15.3622C5 15.7447 4.8 15.9997 4.5 15.9997Z"
      fill={color}
    />
    <path
      d="M4.5 14.1998C2.05714 14.1998 0.128571 13.0298 0 11.2748C0 10.9238 0.257142 10.6898 0.642857 10.6898C0.899999 10.6898 1.28571 10.9238 1.28571 11.2748C1.41429 12.3278 2.7 13.0298 4.5 13.0298C6.3 13.0298 7.71429 12.0938 7.71429 11.0408C7.71429 9.75375 5.91429 9.28576 4.62857 9.05176L4.24286 8.93475C1.41429 8.46675 0 7.41376 0 5.77576C0 4.02075 2.05714 2.49976 4.5 2.49976C6.94286 2.49976 8.87143 3.66976 9 5.42476C9 5.77576 8.74286 6.00975 8.35714 6.00975C7.97143 6.00975 7.71429 5.77576 7.71429 5.42476C7.58572 4.37176 6.3 3.66975 4.5 3.66975C2.7 3.66975 1.28571 4.60576 1.28571 5.77576C1.28571 7.06276 3.08571 7.53076 4.37143 7.76476L4.75714 7.88175C7.58571 8.34975 9 9.40275 9 11.0408C9 12.7958 6.94286 14.1998 4.5 14.1998Z"
      fill={color}
    />
  </SvgIcon>
);

export default DollarIcon;
