import { changeEntity } from './generics';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';

export function* createProjectStage(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.createProjectStage,
    api.createProjectStage,
    [token, id, payload],
    action
  );
}
export function* updateProjectStage(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateProjectStage,
    api.updateProjectStage,
    [token, id, payload],
    action
  );
}

export function* deleteProjectStage(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.deleteProjectStage,
    api.deleteProjectStage,
    [token, id],
    action
  );
}
