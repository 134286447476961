import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

export const inviteMemberToTeam =
  createAction<SpacePermissionCheckerParams>('INVITE_MEMBER');

export const deleteMemberFromTeam = createAction<SpacePermissionCheckerParams>(
  'HARD_DELETE_MEMBER_FROM_TEAM'
);

export const createRoles =
  createAction<SpacePermissionCheckerParams>('CREATE_ROLES');
export const editRoles =
  createAction<SpacePermissionCheckerParams>('EDIT_ROLES');
export const deleteRoles =
  createAction<SpacePermissionCheckerParams>('DELETE_ROLES');

export const manageMemberRole =
  createAction<SpacePermissionCheckerParams>('MANAGE_MEMBER_ROLE');

export const inviteGuests =
  createAction<SpacePermissionCheckerParams>('INVITE_GUESTS');
