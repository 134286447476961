import {
  initialState,
  defaultMosaicMappingStatusesState
} from './globalIntegrations';
import { fetchMappingStatusesActionCreatorsMap } from 'IntegrationsModule/actionCreators';
import { MAPPING_STATUS, DATA_TYPES } from 'IntegrationsModule/constants';

export const handleFetchMappingStatusesSuccess = (
  state: typeof initialState,
  action: ReturnType<typeof fetchMappingStatusesActionCreatorsMap.success>
) => {
  const { response, initialPayload } = action.payload;
  const { dataType } = initialPayload;

  const mappingStatusesByTargetServiceId = response[dataType];

  if (!mappingStatusesByTargetServiceId) return state;

  const nextState: typeof initialState = {
    ...state
  };

  Object.entries(mappingStatusesByTargetServiceId).forEach(
    ([targetServiceId, idsByMappingStatus]) => {
      // keeping this backward compatible - we were using 'account' but IS returns 'employee'
      const dataTypeToUse =
        dataType === DATA_TYPES.EMPLOYEES ? DATA_TYPES.ACCOUNTS : dataType;

      const targetService =
        state.integrationsV2[targetServiceId]?.targetService;
      // note: could lose data here if the targetServiceId isn't loaded for whatever reason in integrationsV2..
      // (shouldn't happen unless we're receiving bad data)

      if (targetService) {
        const targetServiceState =
          nextState.mosaicMappingStatuses[targetService];

        const targetServiceIdState =
          nextState.mosaicMappingStatuses[targetService]?.[targetServiceId] ||
          defaultMosaicMappingStatusesState;

        const targetServiceIdDataTypeState =
          targetServiceIdState[dataTypeToUse];

        // spec: filter out do not link status
        const responseMappingStatuses = Object.keys(idsByMappingStatus).filter(
          (mappingStatus) => mappingStatus !== MAPPING_STATUS.DO_NOT_LINK
        );

        const newIdsToMappingStatusHash = responseMappingStatuses.reduce<
          Record<number, string>
        >((acc, mappingStatus) => {
          (idsByMappingStatus[mappingStatus] ?? []).forEach(
            (id: Nullable<number>) => {
              const isKeptMappingStatus = ![
                String(MAPPING_STATUS.NULL),
                MAPPING_STATUS.DO_NOT_LINK
              ].includes(mappingStatus);

              // filter out null ids + null/do not link statuses
              if (id && isKeptMappingStatus) {
                acc[id] = mappingStatus;
              }
            }
          );
          return acc;
        }, {});

        nextState.mosaicMappingStatuses = {
          ...nextState.mosaicMappingStatuses,
          [targetService]: {
            ...targetServiceState,
            [targetServiceId]: {
              ...targetServiceIdState,
              [dataTypeToUse]: {
                ...targetServiceIdDataTypeState,
                ...newIdsToMappingStatusHash
              }
            }
          }
        };
      }
    }
  );

  return nextState;
};
