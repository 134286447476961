import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMemberPositionsByMemberBudget } from 'BudgetModule/selectors/positions';
import {
  updateMemberPosition,
  createMemberRate
} from 'BudgetModule/actionCreators';

const useMemberPositions = ({ memberBudget, projectId }) => {
  const dispatch = useDispatch();
  const memberPositionsByMemberBudget = useSelector(
    getMemberPositionsByMemberBudget
  );
  const memberPositions = memberPositionsByMemberBudget[memberBudget?.id];

  /**
   * Updates the role on a MemberPosition. 'update' since we are not
   * currently tracking history for MemberPosition
   */
  const handleUpdateRole = useCallback(
    (newRole) => {
      const currentMemberPosition = memberPositions?.[0];
      if (
        memberBudget &&
        projectId &&
        currentMemberPosition &&
        currentMemberPosition.position_id !== newRole.id
      ) {
        // for unassigned roles, updating the role also updates rate to the default rate of the role
        const onSuccess = [
          {
            successAction: () => {
              createMemberRate({
                memberBudgetId: memberBudget.id,
                rateId: newRole.rate_id,
                projectId
              });
            },
            selector: () => {}
          }
        ];
        dispatch(
          updateMemberPosition({
            id: currentMemberPosition.id,
            positionId: newRole.id,
            projectId,
            memberBudgetId: memberBudget.id,
            ...(!memberBudget.account_id && { onSuccess })
          })
        );
      }
    },
    [dispatch, memberBudget, memberPositions, projectId]
  );

  return { handleUpdateRole, memberPositionsByMemberBudget };
};

export default useMemberPositions;
