import * as entityActions from '../actions';
import { put, select } from 'redux-saga/effects';
import { fetchEntity, changeEntity } from './generics';
import { api } from '../service';
import { getTempIdsToCreatedTaskIds } from 'selectors';
import * as actionCreators from 'actionCreators';

const { fetchDayPlanner, updateDayPlanner } = entityActions;

export function* dayPlannerFetch(action) {
  const token = action.payload.token;

  const date =
    action.payload.params.date && action.payload.params.date._d
      ? action.payload.params.date._d
      : action.payload.params.date;
  if (date !== null && date !== 'Invalid Date' && date !== undefined) {
    const { error } = yield fetchEntity(
      fetchDayPlanner,
      api.dayPlannerFetch,
      undefined,
      [token, date],
      action
    );
  }
}

export function* dayPlannerUpdate(action) {
  const { token, params } = action.payload;
  const { position, isPersonal, date, newAccountId, accountIds } = params;

  let taskId = action.payload.params.taskId;
  const taskHash = yield select((state) => state.homeTasks.taskHash);
  const tempIdsToTaskIds = yield select(getTempIdsToCreatedTaskIds);
  const foundTempIdTaskId = tempIdsToTaskIds[taskId];

  if (taskHash[taskId]) {
    taskId = taskHash[taskId].id; // use id instead of draggable temp id
  } else if (foundTempIdTaskId) {
    taskId = foundTempIdTaskId; // use id instead of draggable temp id
  }
  const { error } = yield changeEntity(
    updateDayPlanner,
    api.dayPlannerUpdate,
    [
      token,
      taskId,
      position,
      isPersonal,
      date,
      newAccountId || accountIds?.[0]
    ],
    action
  );
  if (!error) {
    yield put(actionCreators.fetchSingleTask({ taskId }));
  }
}
