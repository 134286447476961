import * as constants from 'appConstants';
import * as budgetConstants from 'BudgetModule/constants';
import { formatTotals } from 'appUtils/phaseDisplayUtils';
import { PLANNER_MODAL } from 'appConstants/taskListTypes';
import { produce } from 'immer';
import { assert } from 'assert';
const {
  OPEN_PLANNER_BAR_MODAL,
  CLOSE_PLANNER_BAR_MODAL,
  PLANNER_BAR_MODAL_CLOSED,
  PREDICT_WORKLOAD_PLANNER,
  PREDICT_WORKDAYS,
  FETCH_PHASES_BY_PROJECT_IDS,
  FETCH_TASKS_V2,
  CREATE_TASK_FROM_HOME,
  CLEAR_LAZY_ITEMS,
  CLEAR_LAZY_ITEMS_LAST_UPDATED
} = constants;

const { FETCH_MEMBER_BUDGET_PHASE } = budgetConstants;
export const initialState = {
  isOpen: false,
  isNew: false,
  isRequest: false,
  workplanStateId: null, // For updating orders on creation/delete in workloadPlanner reducer
  parentGroupId: null, // "
  groupAttribute: null, // "
  barId: null,
  date: null,
  accountId: null,
  projectId: null,
  predictedBar: null,
  suggestedBar: null,
  memberBudgetId: null,
  budgetTotals: {},
  budgetAggregatesByAccountId: {},
  taskOrder: [],
  isLoadingTasks: false,
  calendarPrediction: null,
  shouldPredictWithInitialValue: false,

  // Items ordered by group for a given lazy-loading context.
  //
  // : Map<LazyLoadingId, LazyLoadingContext> | undefined
  tasksOrderByPhase: undefined,
  lazyLoadingContexts: undefined
};

const workloadPlannerBarModal = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case OPEN_PLANNER_BAR_MODAL: {
      const {
        isNew,
        isRequest,
        barId,
        accountId,
        date,
        projectId,
        suggestedBar,
        workplanStateId,
        parentGroupId,
        groupAttribute,
        memberBudgetId,
        shouldPredictWithInitialValue
      } = payload;
      return {
        ...state,
        isOpen: true,
        isRequest,
        isNew,
        barId,
        accountId,
        projectId,
        suggestedBar,
        date,
        workplanStateId,
        parentGroupId,
        groupAttribute,
        memberBudgetId,
        shouldPredictWithInitialValue
      };
    }
    case FETCH_PHASES_BY_PROJECT_IDS.SUCCESS: {
      const { budgetAccountId } = action.payload.requestPayload || {};
      if (!budgetAccountId) {
        return state;
      }
      return {
        ...state,
        budgetAggregatesByAccountId: {
          ...state.budgetAggregatesByAccountId,
          [budgetAccountId]: {
            ...state.budgetAggregatesByAccountId[budgetAccountId],
            ...action.payload.response.projects.reduce(
              (projects, currentProject) => {
                projects[currentProject.id] = {
                  budget_totals: currentProject.budget_totals,
                  phase_totals: currentProject.phases.reduce(
                    (phases, currentPhase) => {
                      phases[currentPhase.id] = {
                        budget_totals: currentPhase.budget_totals,
                        activity_totals:
                          currentPhase.budget_totals?.activity_totals.reduce(
                            (activities, currentActivity) => {
                              activities[currentActivity.activity_id] =
                                currentActivity;
                              return activities;
                            },
                            {}
                          )
                      };
                      return phases;
                    },
                    {}
                  )
                };
                return projects;
              },
              {}
            )
          }
        }
      };
    }
    case PREDICT_WORKDAYS.SUCCESS: {
      return {
        ...state,
        calendarPrediction: action.payload.response
      };
    }
    case PREDICT_WORKLOAD_PLANNER.SUCCESS: {
      return {
        ...state,
        predictedBar: action.payload.response.activity_phase_schedule_bar
      };
    }
    case FETCH_MEMBER_BUDGET_PHASE.SUCCESS: {
      const { response, requestPayload } = action.payload;
      if (response.budgets?.length !== 1) {
        return state;
      }
      const { phaseId } = requestPayload;
      const [budget] = response.budgets;
      const phaseTotal = budget.phases.find(
        (phaseTotal) => phaseTotal.phase_id == phaseId
      );
      if (!phaseTotal) {
        return state;
      }
      const formattedTotals = formatTotals(phaseTotal);

      return {
        ...state,
        budgetTotals: formattedTotals
      };
    }
    case CLOSE_PLANNER_BAR_MODAL: {
      return { ...state, shouldPredictWithInitialValue: false, isOpen: false };
    }
    case PLANNER_BAR_MODAL_CLOSED: {
      return initialState;
    }
    case CREATE_TASK_FROM_HOME.SUCCESS: {
      const { response } = action.payload;
      const { task } = response;
      const { taskListType } = action.payload.requestPayload;
      if (taskListType === PLANNER_MODAL)
        return {
          ...state,
          taskOrder: [task.id, ...state.taskOrder]
        };

      return state;
    }
    case FETCH_TASKS_V2.TRIGGER: {
      const {
        body: { limit, offset },
        lazyLoading,
        taskListType
      } = action.payload;

      if (taskListType === PLANNER_MODAL) {
        return {
          ...state,
          isLoadingTasks: true
        };
      } else if (lazyLoading) {
        const { groupId, id: lazyLoadingId } = lazyLoading;

        return produce(state, (draft) => {
          // Initialize the lazy-loading hash if uninitialized.
          const lazyLoadingContexts = (draft.lazyLoadingContexts =
            draft.lazyLoadingContexts ?? new Map());

          // Initialize the lazy-loading context if uninitialized.
          const lazyLoadingContext =
            lazyLoadingContexts.get(lazyLoadingId) ?? new Map();
          lazyLoadingContexts.set(lazyLoadingId, lazyLoadingContext);

          // Initialize the lazy-loading group if uninitialized.
          const lazyLoadingGroup = lazyLoadingContext.get(groupId) ?? {
            items: undefined,
            lazyLoadingCount: 0
          };
          lazyLoadingContext.set(groupId, lazyLoadingGroup);

          // Increase the number of active lazy-loading requests for the
          // group.
          lazyLoadingGroup.lazyLoadingCount++;

          const items = lazyLoadingGroup.items;
          if (items) {
            // Mark the affected items if the list has previously been
            // requested and loaded. This will not change the length of the
            // array because the items removed by the splice are the same
            // items added by the slice.
            items.splice(
              offset,
              limit,
              // `Array.from` is required here to iterate over the empty
              // entries in the sparse array. `.slice().map()` would exclude
              // the empty entries from the mapping.
              ...Array.from(items.slice(offset, offset + limit), (itemId) =>
                // If the item is not already loaded and is not being lazy
                // loaded, set the lazy loading counter to 1.
                itemId === undefined
                  ? { lazyLoadingCount: 1 }
                  : // If the item is already being lazy loaded, increase its
                  // counter.
                  typeof itemId === 'object'
                  ? { lazyLoadingCount: itemId.lazyLoadingCount + 1 }
                  : // If the item is already loaded, do not change the ID
                    // until the data returns from the request.
                    itemId
              )
            );
          }
        });
      }

      return state;
    }
    case FETCH_TASKS_V2.SUCCESS: {
      const {
        body: { offset },
        lazyLoading,
        taskListType
      } = action.payload.requestPayload;
      const { task_count: taskCount, tasks } = action.payload.response;

      if (taskListType === PLANNER_MODAL) {
        return {
          ...state,
          taskOrder: tasks.map((task) => task.id),
          isLoadingTasks: false
        };
      }

      if (lazyLoading) {
        const { groupId, id: lazyLoadingId } = lazyLoading;

        const itemCount = taskCount;
        const newItemIds = tasks.map((task) => task.id);

        return produce(state, (draft) => {
          // Exit if the lazy-loading context was deleted before the request
          // completed.
          const lazyLoadingContext =
            draft.lazyLoadingContexts?.get(lazyLoadingId);
          if (!lazyLoadingContext) return;

          const lazyLoadingGroup = lazyLoadingContext.get(groupId);

          // The container for the group must have been created in the
          // trigger action and must have had its lazy-loading indicator
          // incremented.
          if (!lazyLoadingGroup || lazyLoadingGroup.lazyLoadingCount < 1) {
            assert.fail('The lazy-loading group was not initialized.');
            return;
          }

          lazyLoadingGroup.lazyLoadingCount--;

          // If this is the first time this group has been lazily-loaded,
          // initialize its items array.
          const items = (lazyLoadingGroup.items = lazyLoadingGroup.items || []);

          // Update the length of the items array for the phase using the
          // count value provided in the response.
          items.length = itemCount;

          // Splice in the values at the appropriate offsets.
          items.splice(offset, newItemIds.length, ...newItemIds);

          // Update the range of updates values by extending the existing
          // range if one exists.
          const updatedRange = (lazyLoadingGroup.updatedRange =
            lazyLoadingGroup.updatedRange || {
              min: Number.POSITIVE_INFINITY,
              max: Number.NEGATIVE_INFINITY
            });
          updatedRange.min = Math.min(updatedRange.min, offset);
          updatedRange.max = Math.max(
            updatedRange.max,
            offset + newItemIds.length
          );
        });
      }

      return state;
    }
    case FETCH_TASKS_V2.FAILURE:
    case FETCH_TASKS_V2.ABORT: {
      const {
        body: { limit, offset },
        lazyLoading
      } = action.payload.requestPayload;

      if (lazyLoading) {
        const { groupId, id: lazyLoadingId } = lazyLoading;

        return produce(state, (draft) => {
          // Exit if the lazy-loading context was deleted before the request
          // completed.
          const lazyLoadingContext =
            draft.lazyLoadingContexts?.get(lazyLoadingId);
          if (!lazyLoadingContext) return;

          const lazyLoadingGroup = lazyLoadingContext.get(groupId);

          // The container for the group must have been created in the
          // trigger action and must have had its lazy-loading indicator
          // incremented.
          if (!lazyLoadingGroup || lazyLoadingGroup.lazyLoadingCount < 1) {
            assert.fail('The lazy-loading group was not initialized.');
            return;
          }

          lazyLoadingGroup.lazyLoadingCount--;

          const items = lazyLoadingGroup.items;
          if (!lazyLoadingGroup.lazyLoadingCount) {
            // Delete the group if it was the first attempt to load items.
            lazyLoadingContext.delete(groupId);
          } else if (items) {
            // Remove the loading count of the affected items if the list has
            // previously been requested and loaded. This will not change the
            // length of the array because the items removed by the splice
            // are the same items added by the slice.
            const requestedItems = items.slice(offset, limit);
            requestedItems.forEach((item, index, items) => {
              // Do not modify the items that are already loaded.
              if (typeof item === 'object') {
                // If the item is being lazy loaded and it has been requested
                // multiple times, decrease its counter by one. Otherwise,
                // clear the lazy loading counter.
                if (item.lazyLoadingCount > 1) {
                  item.lazyLoadingCount--;
                } else {
                  delete items[index];
                }
              }
            });

            items.splice(offset, limit, ...requestedItems);
          }
        });
      }

      return state;
    }
    case CLEAR_LAZY_ITEMS: {
      const { lazyLoadingId } = action.payload;

      return produce(state, (draft) => {
        const lazyLoadingContexts = draft.lazyLoadingContexts;
        if (!lazyLoadingContexts) return;

        lazyLoadingContexts.delete(lazyLoadingId);
        if (!lazyLoadingContexts.size) delete draft.lazyLoadingContexts;
      });
    }
    case CLEAR_LAZY_ITEMS_LAST_UPDATED: {
      const { lazyLoadingId } = action.payload;

      return produce(state, (draft) => {
        // Exit if the lazy-loading context was deleted before the request
        // completed.
        const lazyLoadingContext =
          draft.lazyLoadingContexts?.get(lazyLoadingId);
        if (!lazyLoadingContext) return draft;

        // Delete the updated range of entries for each group in the context.
        lazyLoadingContext.forEach(
          (lazyLoadingGroup) => delete lazyLoadingGroup.updatedRange
        );
      });
    }
    default:
      return state;
  }
};
export default workloadPlannerBarModal;
