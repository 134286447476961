import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createRequestTypes } from 'appUtils';
import {
  FetchCsvImportStatusParams,
  FetchCsvImportStatusSuccessResponse
} from 'CsvImportsModule/types';

/* -----------------------Fetch Import Log Overview Table ------------------------------ */

export const FETCH_CSV_IMPORT_STATUS = createRequestTypes(
  'FETCH_CSV_IMPORT_STATUS'
);

export const fetchCsvImportStatus = createAction(
  FETCH_CSV_IMPORT_STATUS.TRIGGER,
  ({ meta, ...payload }: FetchCsvImportStatusParams) => ({ payload, meta })
);

export const fetchCsvImportStatusActionCreatorsMap = createActionCreatorsMap<
  FetchCsvImportStatusParams,
  FetchCsvImportStatusSuccessResponse
>(FETCH_CSV_IMPORT_STATUS);
