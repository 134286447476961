import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import MilestoneIcon from 'icons/MilestoneIcon';

import moment from 'appUtils/momentConfig';
const formatDate = (date) => moment(date).format('MMM D');

export const formatPhaseScheduleTooltip = ({
  phase,
  project,
  customStartDate,
  customEndDate
}) => {
  return JSON.stringify({
    projectTitle: project?.title,
    phaseName: phase?.name,
    isPhase: phase?.is_budget,
    startDate: customStartDate || phase?.start_date,
    endDate: customEndDate || phase?.end_date,
    phase
  });
};

const PhaseScheduleTooltip = () => {
  return (
    <StyledTooltip
      id="phase-schedule-tooltip"
      place="right"
      getContent={(dataTip) => {
        const { projectTitle, phaseName, isPhase, startDate, endDate, phase } =
          JSON.parse(dataTip || '{}');

        const isDefaulPhase = phase && phase.is_like_default;
        const showPhaseInfo = !isDefaulPhase;
        return (
          <RootContainer>
            <ProjectName>{projectTitle}</ProjectName>
            {showPhaseInfo && (
              <PhaseContainer>
                <IconContainer>
                  {isPhase ? (
                    <PhaseMilestoneIcon width="12" height="12" />
                  ) : (
                    <MilestoneIcon width="12" height="12" />
                  )}
                </IconContainer>
                <PhaseName>{phaseName}</PhaseName>
              </PhaseContainer>
            )}
            <DateContainer>
              {startDate && formatDate(startDate)}
              {endDate && ' - '}
              {endDate && formatDate(endDate)}
            </DateContainer>
          </RootContainer>
        );
      }}
      type="light"
    />
  );
};

export default PhaseScheduleTooltip;

const StyledTooltip = styled(ReactTooltip)`
  box-shadow: 0px 3px 5px rgba(171, 171, 171, 0.5);
`;

const RootContainer = styled.div`
  min-width: 140px;
  max-width: 230px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PhaseContainer = styled(Row)`
  width: 100%;
  gap: 2px;
`;

const IconContainer = styled.div`
  min-width: 12px;
  display: flex;
  align-self: flex-start;
  padding-top: 3px;
`;

const DateContainer = styled(Row)`
  font-size: 12px;
  color: #646363;
`;

const BaseText = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;

const ProjectName = styled(BaseText)`
  font-size: 13px;
  font-weight: 600;
`;

const PhaseName = styled(BaseText)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;
