import { Capacity } from 'CapacityModule/models';
import { BaseTableColumn, EmptyRowListItem } from 'components/Table/types';
import { EmptyDiv } from 'components/EmptyDiv';
import { MembersCapacityTableCells } from '../Cells';

const {
  MemberTypeSectionHeaderCell,
  HeaderCell,
  MemberCell,
  PolicyPickerCell,
  CapacityCells,
  TotalCell,
  ArchivedSectionHeaderCell
} = MembersCapacityTableCells;

const ROW_TYPES = {
  memberHeaderRow: 'memberHeaderRow',
  memberCapacityRow: 'memberCapacityRow',
  archivedSectionHeaderRow: 'archivedSectionHeaderRow'
};

const columnTypes = {
  memberInfo: 'memberInfo',
  policy: 'policy',
  sunday: 'sunday',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
  total: 'total'
};

const columnWidths = {
  [columnTypes.memberInfo]: 255,
  [columnTypes.policy]: 200,
  [columnTypes.sunday]: 70,
  [columnTypes.monday]: 70,
  [columnTypes.tuesday]: 70,
  [columnTypes.wednesday]: 70,
  [columnTypes.thursday]: 70,
  [columnTypes.friday]: 70,
  [columnTypes.saturday]: 70,
  [columnTypes.total]: 110
};

const membersInfoColumn: BaseTableColumn = {
  id: columnTypes.memberInfo,
  headerType: columnTypes.memberInfo
};

const policyColumn: BaseTableColumn = {
  id: columnTypes.policy,
  headerType: columnTypes.policy,
  headerLabel: 'CAPACITY POLICY',
  align: 'center'
};

const capacityDayOfWeekToFormattedString: Record<keyof Capacity, string> = {
  monday: 'M',
  tuesday: 'T',
  wednesday: 'W',
  thursday: 'T',
  friday: 'F',
  saturday: 'S',
  sunday: 'S'
};

const dayColumns: BaseTableColumn[] = [
  columnTypes.sunday,
  columnTypes.monday,
  columnTypes.tuesday,
  columnTypes.wednesday,
  columnTypes.thursday,
  columnTypes.friday,
  columnTypes.saturday
].map((day) => ({
  id: day,
  headerType: day,
  headerLabel: capacityDayOfWeekToFormattedString[day],
  align: 'center'
}));

const totalColumn: BaseTableColumn = {
  id: columnTypes.total,
  headerType: columnTypes.total,
  headerLabel: 'TOTAL',
  align: 'center'
};

const allColumns = [
  membersInfoColumn,
  policyColumn,
  ...dayColumns,
  totalColumn
];

const rowToCells = {
  [ROW_TYPES.memberHeaderRow]: {
    [membersInfoColumn.headerType]: MemberTypeSectionHeaderCell,
    [policyColumn.headerType]: HeaderCell,
    [columnTypes.sunday]: HeaderCell,
    [columnTypes.monday]: HeaderCell,
    [columnTypes.tuesday]: HeaderCell,
    [columnTypes.wednesday]: HeaderCell,
    [columnTypes.thursday]: HeaderCell,
    [columnTypes.friday]: HeaderCell,
    [columnTypes.saturday]: HeaderCell,
    [columnTypes.total]: HeaderCell
  },
  [ROW_TYPES.memberCapacityRow]: {
    [membersInfoColumn.headerType]: MemberCell,
    [policyColumn.headerType]: PolicyPickerCell,
    [columnTypes.sunday]: CapacityCells.SundayCell,
    [columnTypes.monday]: CapacityCells.MondayCell,
    [columnTypes.tuesday]: CapacityCells.TuesdayCell,
    [columnTypes.wednesday]: CapacityCells.WednesdayCell,
    [columnTypes.thursday]: CapacityCells.ThursdayCell,
    [columnTypes.friday]: CapacityCells.FridayCell,
    [columnTypes.saturday]: CapacityCells.SaturdayCell,
    [columnTypes.total]: TotalCell
  },
  [ROW_TYPES.archivedSectionHeaderRow]: {
    [membersInfoColumn.headerType]: ArchivedSectionHeaderCell,
    [policyColumn.headerType]: EmptyDiv,
    [columnTypes.sunday]: EmptyDiv,
    [columnTypes.monday]: EmptyDiv,
    [columnTypes.tuesday]: EmptyDiv,
    [columnTypes.wednesday]: EmptyDiv,
    [columnTypes.thursday]: EmptyDiv,
    [columnTypes.friday]: EmptyDiv,
    [columnTypes.saturday]: EmptyDiv,
    [columnTypes.total]: EmptyDiv
  }
} as const;

const stickyHeaderCells = rowToCells[ROW_TYPES.memberHeaderRow];

const emptyRow: EmptyRowListItem = {
  rowType: 'emptyRow',
  rowHeight: 60,
  isDragDisabled: true
};

export const membersCapacityTableHelpers = {
  ROW_TYPES,
  columnWidths,
  allColumns,
  rowToCells,
  stickyHeaderCells,
  emptyRow
};
