import styled from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import FolderIcon from 'icons/FolderIcon';
import theme from 'theme';

export const StyledSelectPhaseText = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 10px;
  margin-left: 15px;
  visibility: hidden;
`;

export const ArchivedText = styled.span`
  font-size: 11px;
  font-weight: 600;
  flex-shrink: 0;
  margin: 1px 5px 0px 0px;
  color: ${theme.colors.colorMediumGray1};
`;

export const StatusText = styled.span`
  font-size: 11px;
  font-weight: 600;
  flex-shrink: 0;
  margin: 1px 5px 0px 0px;
  color: ${theme.colors.colorSemiDarkGrey10};
  background: ${theme.colors.colorTranslucentGray4};
  border-radius: 4px;
  padding: 0px 4px;
  margin-left: 6px;
`;

export const StyledProjectTitleContainer = styled.div`
  color: ${theme.colors.colorSemiDarkGray4};
  margin-left: 5px;
  font-size: 13px;
  font-weight: 600;
  flex: auto;
  max-width: calc(100% - 15px);
  display: flex;
  align-items: center;
`;
export const StyledProjectTitle = styled.div`
  margin-right: '3px';
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
`;
export const StyledProjectDescription = styled.span`
  color: ${theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  font-size: 12px;
  margin-top: 1px;
`;
export const StyledProjectNumber = styled.div`
  color: ${theme.colors.colorMediumGray5};
  line-height: 18px;
  height: 18px;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  padding-left: 5px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  margin-right: 4px;
`;

export const StyledProjectInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  position: relative;
`;
export const StyledTeamName = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  margin-left: 2px;
`;

export const StyledProjectInfoContainer = styled.div`
  &:hover {
    ${StyledSelectPhaseText} {
      visibility: visible;
    }
  }
`;

export const StyledPhaseNameContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${theme.colors.colorMediumGray5};
  font-weight: normal;
  display: flex;
  width: 100%;
  margin-left: 5px;
  align-items: center;
`;

export const StyledPhaseName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 3px;
`;

export const TimesheetTopCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  visibility: ${({ shouldShow }) => (shouldShow ? 'visible' : 'hidden')};
`;
export const TimesheetBottomCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 18px;
`;
export const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 3px;
  width: 12px;
  height: 12px;
  path {
    stroke: #808080;
    stroke-width: 3;
    fill: transparent;
  }
`;

export const PhaseProjectListItem = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledColorContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  left: 5px;
  top: 4px;
`;

export const StyledDot = styled.div`
  margin-left: 7px;
  height: 8px;
  flex: 0 0 8px;
  margin-top: 2px;
  border-radius: 10px;
  left: 5px;
  top: 4px;
  background: ${theme.colors.colorMediumGray1};
  background: ${calculateProjectColorPreference};
`;

export const OutsideOfPhaseText = styled.div`
  font-weight: 800;
  font-size: 15px;
  color: red;
  display: inline-block;
  margin-left: 3px;
`;

export const LightBulbIconContainer = styled.div``;

export const SuggestionIconsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -15px;
`;
