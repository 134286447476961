import React, { useMemo } from 'react';
import theme from 'theme';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import styled from 'styled-components';
import { getMe } from 'selectors';
import { getAccountCapacities } from 'CapacityModule/selectors';
import { getFormattedChartData } from 'ReportsModule/components/Time/TimesheetStatusTable/utils';
import StatusPieChart, {
  StyledHours
} from 'ReportsModule/components/Time/TimesheetStatusTable/Cells/shared/StatusPieChart';

const TimesheetTotalWidgetContainer = styled.div`
  margin-top: 30px;
  ${StyledHours} {
    font-size: 11px;
    color: ${theme.colors.colorMediumGray9};
    font-weight: 600;
    margin-top: 2px;
  }
`;

const TimesheetColumnHeaderDate = ({
  column,
  customRowProps: { memberTimesheetStatusTotalsByDate, timesheetsByDate }
}) => {
  const me = useSelector(getMe);
  const accountCapacities = useSelector(getAccountCapacities);

  const { day, displayDate, date } = useMemo(
    () => ({
      day: moment(column.id).format('dddd').toLowerCase(),
      displayDate: moment(column.id).format('ddd D'),
      date: moment(column.id).format('YYYY-MM-DD')
    }),
    [column.id]
  );

  const chartData = useMemo(() => {
    const hours = {
      not_submitted_hours: 0,
      submitted_hours: 0,
      total_hours: 0,
      totalTimesheetHours: 0,
      approved_hours: 0
    };

    const matchingTimesheets = timesheetsByDate[column.id] || [];

    matchingTimesheets.forEach((timesheet) => {
      hours.total_hours = hours.total_hours + Number(timesheet.hours);
      hours.totalTimesheetHours =
        hours.totalTimesheetHours + Number(timesheet.hours);
      if (timesheet.status === 'not_submitted') {
        hours.not_submitted_hours =
          hours.not_submitted_hours + Number(timesheet.hours);
      } else {
        hours.submitted_hours = hours.submitted_hours + Number(timesheet.hours);
      }
    });

    const dailyData = memberTimesheetStatusTotalsByDate[me?.id];
    const rawData = dailyData ? dailyData[date] : {};
    const data = { ...rawData, ...hours };
    const accountCapacity = accountCapacities[me?.id] || {};
    return {
      ...getFormattedChartData({
        data,
        capacity: accountCapacity[day] || 0,
        statusView: 'submitted'
      })
    };
  }, [
    accountCapacities,
    column.id,
    date,
    day,
    me?.id,
    memberTimesheetStatusTotalsByDate,
    timesheetsByDate
  ]);

  return (
    <div
      className={cn('center-header', {
        today: displayDate === moment().format('ddd D')
      })}
    >
      <TimesheetTotalWidgetContainer>
        <StatusPieChart data={chartData} />
      </TimesheetTotalWidgetContainer>
      {displayDate}
    </div>
  );
};

export default TimesheetColumnHeaderDate;
