/* eslint-disable camelcase */
import * as appConstants from 'appConstants';
import * as constants from '../constants';
import * as integrationsConstants from 'IntegrationsModule/constants';
import { makeIdHash } from 'appUtils';

export const initialState = {
  expanded: {
    members: false,
    activities: false,
    projects: false
  },
  linked: {
    members: false,
    activities: false,
    projects: false
  },
  defaultBoardId: null,
  addProjectModalOpen: false,
  addProjectModalName: '',
  addProjectSubcustomerId: null,
  showPhasesProjectIds: {}
};

const quickbooksView = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case appConstants.OPEN_ADD_PROJECT_MODAL: {
      const { isQB, name, subcustomerId } = action.payload;
      if (!isQB) {
        return state;
      }
      return {
        ...state,
        addProjectModalOpen: true,
        addProjectModalName: name,
        addProjectSubcustomerId: subcustomerId
      };
    }
    case appConstants.CLOSE_ADD_PROJECT_MODAL: {
      return {
        ...state,
        addProjectModalOpen: false,
        addProjectModalName: '',
        addProjectSubcustomerId: null
      };
    }
    case constants.TOGGLE_QUICKBOOKS_OPEN: {
      const { section } = payload;
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [section]: !state.expanded[section]
        }
      };
    }
    case constants.LINK_QUICKBOOKS_SECTION: {
      const { section } = payload;
      return {
        ...state,
        linked: {
          ...state.linked,
          [section]: true
        }
      };
    }
    case constants.DISCONNECT_QUICKBOOKS_SECTION: {
      const { section } = payload;
      return {
        ...state,
        linked: {
          ...state.linked,
          [section]: false
        }
      };
    }
    case integrationsConstants.FETCH_INTEGRATION.SUCCESS: {
      const [config] = payload.requestPayload;
      const { integration } = config;
      const { employees, projects, time_entries, default_team_id } =
        action.payload;
      if (integration !== 'quickbooks') {
        return state;
      }
      return {
        ...state,
        linked: {
          ...state.linked,
          members: !!employees,
          activities: !!time_entries,
          projects: !!projects,
          defaultBoardId: default_team_id
        }
      };
    }
    case constants.TOGGLE_SHOW_PHASES: {
      const { id } = action.payload;
      return {
        ...state,
        showPhasesProjectIds: {
          ...state.showPhasesProjectIds,
          [id]: !state.showPhasesProjectIds[id]
        }
      };
    }
    case constants.SHOW_PHASES: {
      const { ids } = action.payload;
      const showPhasesIds = makeIdHash(ids);
      return {
        ...state,
        showPhasesProjectIds: {
          ...state.showPhasesProjectIds,
          ...showPhasesIds
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default quickbooksView;
