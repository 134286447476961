import SvgIcon from 'components/SvgIcon';
import noop from 'lodash/noop';

const PencilIcon = ({
  className = '',
  fill = '',
  isAdmin = false,
  onClick = noop,
  size = 15,
  ...rest
}) => (
  <SvgIcon
    className={className}
    height={size}
    width={size}
    viewBox="0 0 15 15"
    onClick={onClick}
    {...rest}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill || (isAdmin === false ? '#9b9b9b' : '#0074D9')}>
        <path d="M1.282 9.796l-1.244 4.4a.606.606 0 0 0 .16.606.63.63 0 0 0 .447.191c.064 0 .127 0 .16-.031l4.4-1.244-3.923-3.922zM14.802 3.45L11.55.198a.63.63 0 0 0-.447-.191.631.631 0 0 0-.447.19l-8.61 8.61 4.146 4.146 8.61-8.61c.255-.255.255-.67 0-.893z" />
      </g>
    </g>
  </SvgIcon>
);

export default PencilIcon;
