const taskCardClasses = [
  'daily-task',
  'custom-card-content',
  'top-row',
  'bottom-row',
  'assignee-span',
  'assignee-dropdown-member-name',
  'assignee-dropdown-member-initials',
  'assigned-member-initials',
  'current-assignee',
  'description-input',
  'description-textarea',
  'description-wrapper',
  'dropdown-arrow',
  'dropdown-item',
  'due-date',
  'editing',
  'follow-button',
  'ico-drag-task',
  'inline-input-highlight-container',
  'pencil-icon',
  'pill-container',
  'priority-dot',
  'priority-dot-container',
  'priority-dropdown',
  'project-menu-container',
  'project-menu-button',
  'project-title',
  'rc-calendar-header',
  'rc-calendar-body',
  'rc-calendar-date',
  'rc-calendar-next-month-btn',
  'rc-calendar-prev-month-btn',
  'remove',
  'react-flex',
  'react-flex-item',
  'search-bar-input',
  'select-assignee',
  'task-description',
  'task-description-container',
  'task-item',
  'toggle-container',
  'comment-bubble',
  'comment-bubble-container'
];

export default taskCardClasses;
