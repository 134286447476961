import keyBy from 'lodash/keyBy';
import sumBy from 'lodash/sumBy';
import * as constants from 'appConstants';
import * as actionConstants from '../constants';
const {
  FETCH_UTILIZATIONS,
  FETCH_UTILIZATIONS_REPORT,
  FETCH_UTILIZATIONS_REPORT_TOTALS,
  FETCH_MEMBERS_UTILIZATION_REPORT,
  FETCH_TEAM_UTILIZATION_REPORT,
  FETCH_PROJECT_UTILIZATION_REPORT,
  FETCH_PHASE_UTILIZATION_REPORT,
  FETCH_TEAM_HISTORICAL_UTILIZATION
} = actionConstants;

export const initialFilterState = {
  isFetching: null,
  // Organization chart - utilizations by date interval
  // Timesheet status report - team totals for single date interval (eg. April)
  teamTotals: [],
  // Report by member - array of account ids
  memberUtilizationsOrder: [],
  // Utilizations by account id
  memberUtilizations: {},
  // ProjectUtilizationReports by project id
  projectUtilizationReports: {},
  // PhaseUtilizationReports by phase id
  phaseUtilizationReports: {},
  teamHistoricalUtilization: {},
  totalCounts: 0
};

export const initialState = {
  utilizations: {},
  utilizationReport: {},
  utilizationReportOrder: [],
  utilizationReportTotal: null,
  utilizationReportTotalByFilter: {},
  filterStates: {}
};

const utilizations = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_UTILIZATIONS.SUCCESS: {
      const newUtilizations = payload.response.reduce(
        (utilizationsHash, memberUtilization) => {
          const { team_member_id, utilization = {} } = memberUtilization;
          utilizationsHash[team_member_id] = state.utilizations[team_member_id]
            ? { ...state.utilizations[team_member_id], ...utilization }
            : utilization;
          return utilizationsHash;
        },
        {}
      );

      return {
        ...state,
        utilizations: {
          ...state.utilizations,
          ...newUtilizations
        }
      };
    }
    case FETCH_UTILIZATIONS_REPORT.SUCCESS: {
      const { response } = payload;

      return {
        ...state,
        utilizationReport: {
          ...state.utilizationReport,
          ...keyBy(response, (item) => item.account_id)
        },
        utilizationReportOrder: Array.from(
          new Set([
            ...state.utilizationReportOrder,
            ...response.map((item) => item.account_id)
          ])
        )
      };
    }
    case FETCH_UTILIZATIONS_REPORT_TOTALS.SUCCESS: {
      const { response, requestPayload } = payload;
      const totals = sumBy(
        response,
        (accountUtilization) => accountUtilization.total_hours
      );
      if (requestPayload?.filterId) {
        return {
          ...state,
          utilizationReportTotalByFilter: {
            ...state.utilizationReportTotalByFilter,
            [requestPayload.filterId]: totals
          }
        };
      }
      return {
        ...state,
        utilizationReportTotal: sumBy(
          response,
          (accountUtilization) => accountUtilization.total_hours
        )
      };
    }

    /* --------------------------- utilization_report --------------------------- */

    case FETCH_MEMBERS_UTILIZATION_REPORT.TRIGGER:
    case FETCH_PROJECT_UTILIZATION_REPORT.TRIGGER:
    case FETCH_TEAM_HISTORICAL_UTILIZATION.TRIGGER:
    case FETCH_PHASE_UTILIZATION_REPORT.TRIGGER:
    case FETCH_TEAM_UTILIZATION_REPORT.TRIGGER: {
      const { filterStateId, initial } = payload;
      const currentFilterState =
        initial || !state.filterStates[filterStateId]
          ? initialFilterState
          : state.filterStates[filterStateId];
      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...currentFilterState,
            isFetching: true
          }
        }
      };
    }

    case FETCH_MEMBERS_UTILIZATION_REPORT.FAILURE:
    case FETCH_PROJECT_UTILIZATION_REPORT.FAILURE:
    case FETCH_TEAM_HISTORICAL_UTILIZATION.FAILURE:
    case FETCH_PHASE_UTILIZATION_REPORT.FAILURE:
    case FETCH_TEAM_UTILIZATION_REPORT.FAILURE: {
      const { filterStateId } = payload;
      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            isFetching: false
          }
        }
      };
    }

    case FETCH_TEAM_UTILIZATION_REPORT.SUCCESS: {
      const { utilization } = payload.response;
      const { filterStateId } = payload.requestPayload;
      const data = utilization[0]?.totals || [];

      return {
        ...state,
        isFetching: false,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            teamTotals: data,
            isFetching: false
          }
        }
      };
    }

    case FETCH_MEMBERS_UTILIZATION_REPORT.SUCCESS: {
      const { utilization, total_count } = payload.response;
      const { filterStateId, initial, isLazyLoad } = payload.requestPayload;
      const nextMemberUtilizationsOrder = [];
      const memberUtilizations = {};
      utilization.forEach((util) => {
        nextMemberUtilizationsOrder.push(util.account_id);
        memberUtilizations[util.account_id] = util;
      });
      const oldMemberUtilizations =
        state.filterStates[filterStateId].memberUtilizations;
      let newMemberUtilizations = {};

      if (isLazyLoad && oldMemberUtilizations) {
        Object.keys(memberUtilizations).forEach((key) => {
          if (oldMemberUtilizations[key]) {
            const dateHash = keyBy(
              oldMemberUtilizations[key].totals,
              'start_date'
            );
            newMemberUtilizations[key] = {
              account_id: key,
              totals: [
                ...oldMemberUtilizations[key].totals,
                ...memberUtilizations[key].totals.filter(
                  (item) => !dateHash[item.start_date]
                )
              ]
            };
          } else {
            newMemberUtilizations[key] = memberUtilizations[key];
          }
        });
      } else {
        newMemberUtilizations = {
          ...state.filterStates[filterStateId].memberUtilizations,
          ...memberUtilizations
        };
      }
      return {
        ...state,
        isFetching: false,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            isFetching: false,
            memberUtilizationsOrder: initial
              ? nextMemberUtilizationsOrder
              : [
                  ...state.filterStates[filterStateId].memberUtilizationsOrder,
                  ...nextMemberUtilizationsOrder
                ],
            memberUtilizations: newMemberUtilizations,
            totalCounts: total_count
          }
        }
      };
    }

    case FETCH_PROJECT_UTILIZATION_REPORT.SUCCESS: {
      const { utilization } = payload.response;
      const { filterStateId } = payload.requestPayload;
      return {
        ...state,
        isFetching: false,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            isFetching: false,
            projectUtilizationReports: {
              ...state.filterStates[filterStateId].projectUtilizationReports,
              ...keyBy(utilization, (item) => item.project_id)
            }
          }
        }
      };
    }

    case FETCH_PHASE_UTILIZATION_REPORT.SUCCESS: {
      const { utilization } = payload.response;
      const { filterStateId } = payload.requestPayload;

      return {
        ...state,
        isFetching: false,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            isFetching: false,
            phaseUtilizationReports: {
              ...state.filterStates[filterStateId].phaseUtilizationReports,
              ...keyBy(utilization, (item) => item.phase_id)
            }
          }
        }
      };
    }

    case FETCH_TEAM_HISTORICAL_UTILIZATION.SUCCESS: {
      const { filterStateId } = payload.requestPayload;
      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            teamHistoricalUtilization: payload.response,
            isFetching: false
          }
        }
      };
    }

    default:
      return state;
  }
};

export default utilizations;
