import { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import NoteIcon from 'icons/NoteIconThick';
import cn from 'classnames';

const placeholderText = 'Add Description';

interface DescriptionFieldProps {
  value: string | undefined;
  onUpdate: (value: string) => void;
  isDisabled?: boolean;
  disabledTooltip?: string;
}

export const DescriptionField = ({
  value: initialValue,
  onUpdate,
  isDisabled = false,
  disabledTooltip
}: DescriptionFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<string>(initialValue || '');
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleClick = !isDisabled ? () => setIsEditing(true) : undefined;

  const handleBlur = () => {
    setIsEditing(false);
    const trimmedValue = value.trim();
    setValue(trimmedValue);
    onUpdate(trimmedValue);
  };

  const showDisabledTooltip = isDisabled && !value;

  return (
    <ActivityDescrptionContainer>
      <StyledNoteIcon />
      {isEditing ? (
        <StyledTextArea
          name="description"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          placeholder={placeholderText}
        />
      ) : (
        <StyledDescriptionContainer onClick={handleClick}>
          <DescriptionText
            className={cn({ disabled: isDisabled })}
            data-for="app-tooltip"
            data-tip={showDisabledTooltip ? disabledTooltip : value}
            data-tip-disable={!showDisabledTooltip}
            data-effect="solid"
            data-class={showDisabledTooltip ? '' : 'mw-250'}
          >
            {value || placeholderText}
          </DescriptionText>
        </StyledDescriptionContainer>
      )}
    </ActivityDescrptionContainer>
  );
};

const ActivityDescrptionContainer = styled.div`
  display: flex;
  margin-left: 17px;
`;

const StyledNoteIcon = styled(NoteIcon)`
  height: 12px;
  margin-right: 4px;
  margin-top: 2px;
  path {
    fill: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  }
`;

const StyledTextArea = styled.textarea`
  flex: 1;
  border: none;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.colorPaleGray5};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.colorRoyalBlue};
    outline: none;
  }
`;
const StyledDescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
`;

const DescriptionText = styled.div`
  font-size: 13px;
  margin-top: -1px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(.disabled) {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }
`;
