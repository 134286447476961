import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAuth, getBilling } from '../../selectors';

class TeamAdminTab extends React.Component {
  render() {
    const { billing } = this.props;
    const currentSubscription = billing.currentSubscription;
    const planInterval = billing.planSelection.plan.name;

    return (
      <div className="team-admin-tab">
        <div className="admin-header-row">
          <div className="plan-name">{currentSubscription.name} Plan</div>
          <div className="cancel-plan">
            <button className="cancel-button">Cancel Plan</button>
          </div>
        </div>
        <div>
          <div className="plan-details">
            {currentSubscription.quantity} Members - {planInterval} Plan
          </div>
        </div>
        <div className="payment-header">Payment Method</div>
        <div className="card-row">
          <div className="card-hint">XXXX-XXXX-1234</div>
          <div className="edit-card">Edit</div>
        </div>
        <div className="actions-row">
          <div className="cancel-link">Cancel</div>
          <button type="submit" className="update-button">
            Update
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  billing: getBilling(state)
});

export default withRouter(connect(mapStateToProps)(TeamAdminTab));
