import React, { useRef } from 'react';
import { batch, connect } from 'react-redux';
import { makeGetIsBatchSelected, makeGetSomeIsBatchSelected } from 'selectors';
import { TaskBatchActionTogglePanel } from '../styles';
import TaskBatchActionToggle from '../TaskBatchActionToggle';
import {
  toggleBatchSelectedTasks,
  closeSidebarProjects,
  clearBatchSelectedTasks,
  setBatchSelectedTasks
} from 'actionCreators';

const BulkContent = ({
  taskIsEditing,
  isNewTask,
  isCreatingTask,
  isTaskModal,
  isBatchSelected,
  groupIsBatchSelected,
  showBatchActionToggles,
  fillWhite,
  taskId,
  closeSidebarProjects,
  toggleBatchSelectedTasks,
  clearBatchSelectedTasks,
  setBatchSelectedTasks,
  groupId,
  groupTaskIds
}) => {
  const handleBatchActionToggleClick = () => {
    closeSidebarProjects();
    if (groupIsBatchSelected) {
      batch(() => {
        setBatchSelectedTasks({ taskIds: groupTaskIds });
        clearBatchSelectedTasks({ groupIds: [groupId], taskIds: [taskId] });
      });
    } else {
      toggleBatchSelectedTasks([taskId]);
    }
  };
  return (
    <TaskBatchActionTogglePanel>
      <TaskBatchActionToggle
        handleBatchActionToggleClick={handleBatchActionToggleClick}
        taskIsEditing={taskIsEditing}
        isCreatingTask={isCreatingTask}
        taskBatchActionIsToggled={isBatchSelected || groupIsBatchSelected}
        showBatchActionToggles={showBatchActionToggles}
        fillWhite={fillWhite}
        isTaskModal={isTaskModal}
        isNewTask={isNewTask}
      />
    </TaskBatchActionTogglePanel>
  );
};

const makeMapStateToProps = () => {
  const getIsBatchSelected = makeGetIsBatchSelected();
  const getSomeIsBatchSelected = makeGetSomeIsBatchSelected();
  const mapStateToProps = (state, ownProps) => ({
    isBatchSelected: getIsBatchSelected(state, ownProps),
    someIsBatchSelected: getSomeIsBatchSelected(state, ownProps)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  closeSidebarProjects,
  toggleBatchSelectedTasks,
  setBatchSelectedTasks,
  clearBatchSelectedTasks
};
export default connect(makeMapStateToProps, mapDispatchToProps)(BulkContent);
