import styled from 'styled-components';
import {
  calculateColorPref,
  calculateBarColorPreference
} from 'appUtils/styleUtils';
import theme from 'theme';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import {
  HANDLE_MIN_WIDTH,
  SCHEDULE_BAR_FIRST_CHILD_BORDER,
  SCHEDULE_BAR_LAST_CHILD_BORDER
} from './constants';

// Text overflow only applies to block containers, so it is not possible to put
// the text ellipsis rules on Sticky, which is a flexbox container.
export const Sticky = styled.div`
  align-items: center;
  display: flex;
  left: 5px; // Sticky position
  overflow: hidden;
  position: sticky;

  // Try to contain the contents, but cut off overflow beyond available width.
  max-width: 100%;
  width: fit-content;
`;

export const StickyWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const HoverState = styled.span`
  align-items: center;
  display: none;
`;

export const NonHoverState = styled.span`
  align-items: center;

  &.flex {
    display: flex;
    min-width: 0;
  }
`;

export const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon).attrs({
  strokeColor: theme.colors.colorSemiDarkGray1,
  fillColor: 'transparent'
})`
  flex: none;
`;

export const ProjectBarBase = styled.div.attrs((props) => ({
  style: {
    ...props.style,
    '--border-color': calculateColorPref(props),
    background: props.$isHeatmapColored
      ? calculateBarColorPreference(props)
      : undefined
  }
}))`
  --border-width: 2px;
  --border-left-width: 0px;
  --border-right-width: 0px;

  background: ${theme.colors.colorLightGray22};
  border-color: var(--border-color);
  border-style: solid;
  border-width: var(--border-width) var(--border-right-width)
    var(--border-width) var(--border-left-width);
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  font-weight: 400;
  justify-content: center;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 100%;

  &:hover {
    ${HoverState} {
      display: inline;

      &.flex {
        display: flex;
      }
    }

    ${NonHoverState} {
      display: none;
    }
  }

  &.inactive::before {
    background: rgba(255, 255, 255, 0.4);
    content: '';
    inset: calc(-1 * var(--border-width)) calc(-1 * var(--border-right-width))
      calc(-1 * var(--border-width)) calc(-1 * var(--border-left-width));
    position: absolute;
  }

  &.moving {
    opacity: 0.4;
  }

  &.tentative {
    border-style: dashed;
  }

  &.narrow {
    background: var(--border-color);
    border-width: 0;
    min-width: ${2 * HANDLE_MIN_WIDTH}px;

    &.inactive::before {
      inset: 0;
    }

    .rct-item-handler {
      height: 50%;
      min-height: 10px;
      top: min(25%, 100% - 10px);
    }
  }

  &.ghost {
    background: none;
    border-width: 0;
  }

  &:first-child {
    // Left border for leftmost bar of an item
    --border-left-width: ${SCHEDULE_BAR_FIRST_CHILD_BORDER}px;
    border-bottom-left-radius: 5px;
    border-left-style: solid;
    border-top-left-radius: 5px;
  }

  &:last-child {
    // Right border for rightmost bar of an item
    --border-right-width: ${SCHEDULE_BAR_LAST_CHILD_BORDER}px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
`;
