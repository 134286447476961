import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
`;

const WorkCategoryCell = ({ row }) => {
  const { userActivity } = row.original;
  const workActivity = userActivity?.display_data?.activity_title;
  return (
    <StyledText>
      <span
        data-for="app-tooltip"
        data-html
        data-class="project-info-tooltips"
        className="no-text-overflow"
        style={{ paddingRight: '10px' }}
        data-tip={workActivity}
      >
        {workActivity}
      </span>
    </StyledText>
  );
};

export default WorkCategoryCell;
