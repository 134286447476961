import React, { useRef } from 'react';
import { StyledDescriptionContainer, DescriptionText } from './styles';
import PencilIcon from 'icons/PencilIcon';
import TeamRateSelection from './TeamRateSelection';
import { useDispatch } from 'react-redux';
import { fetchMemberTeamRates } from 'BudgetModule/actionCreators';

const DescriptionCell = ({ cell, row }) => {
  const dispatch = useDispatch();
  const { original } = row;
  const targetRef = useRef(null);
  const { member } = original;
  const accountId = member?.account?.id;
  const { value: description } = cell;

  const handleRateClick = (e) => {
    dispatch(fetchMemberTeamRates({ teamMembershipId: member.id }));
  };

  const onClickFocus = () => {};

  if (!row.original.member) {
    return <div> </div>;
  }

  return (
    <StyledDescriptionContainer className="left-align">
      {description ? (
        <TeamRateSelection
          memberName={member?.account?.first_name}
          target={targetRef}
          handleRateClick={handleRateClick}
          teamMembershipId={member?.id}
          accountId={accountId}
          member={member}
        >
          <DescriptionText
            data-for="app-tooltip"
            data-tip={description}
            data-class="mw-250"
          >
            {description}
          </DescriptionText>
          <div
            className="edit-icon-container"
            style={{ marginLeft: 7, visibility: 'hidden' }}
          >
            <PencilIcon size={13} />
          </div>
        </TeamRateSelection>
      ) : (
        ''
      )}
    </StyledDescriptionContainer>
  );
};

export default DescriptionCell;
