import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MenuCheckinsIcon = ({
  className,
  width = 13,
  height = 16,
  currentColor = '#A4A4A4'
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 13 16"
    >
      <path
        d="M2.89648 4.29412V1H12.0003V15H2.89648V11.7059"
        stroke={currentColor}
        strokeWidth="1.1"
      />
      <path
        d="M0 8.00003H7.86241M7.86241 8.00003L4.96573 5.11768M7.86241 8.00003L4.96573 10.8824"
        stroke={currentColor}
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};

export default MenuCheckinsIcon;
