import assert from 'assert';
import isEqual from 'lodash/isEqual';
import zip from 'lodash/zip';
import {
  ColorScaleColors,
  ColorScaleIntervals,
  ColorScaleIntervalWithMax,
  ColorScaleIntervalsWithMax,
  ColorScaleStyles,
  ColorScaleMinima
} from './types';
import {
  COLOR_SCALES_COLORS_DEFAULT,
  COLOR_SCALE_NULL_COLOR,
  COLOR_SCALE_REVERSED_NULL_COLOR
} from './constants';

/**
 * Creates a string ID for a color scale.
 */
export const buildColorScaleId = (colors: ColorScaleColors): string =>
  colors.join('_').toLowerCase();

/**
 * Create an array of color scale styles that includes the null capacity and
 * the two overcapacity styles.
 * - Null capacity (0%): special null color
 * - ...`colors`...
 * - Some overcapacity: last color, with small indicator
 * - Ample overcapacity: last color, with large indicator
 *
 * @param colors An ordered list of colors.
 */
export const buildExtendedColorScaleStyles = (
  colors: ColorScaleColors
): ColorScaleStyles => {
  const lastColor = colors[colors.length - 1];
  if (!lastColor) {
    assert(lastColor, 'The `colors` array must contain at least one value.');
    return [];
  }

  // Determine if the color scale is a reversed version of one of the default
  // color scales.
  const isReversed = COLOR_SCALES_COLORS_DEFAULT.some(
    (scale: ColorScaleColors) => isEqual(colors, scale.slice().reverse())
  );

  return [
    // The first style for zero capacity is a special value.
    {
      color: isReversed
        ? COLOR_SCALE_REVERSED_NULL_COLOR
        : COLOR_SCALE_NULL_COLOR
    },

    // The colors of the scale.
    ...buildColorScaleStyles(colors),

    // The last color has two special styles for overcapacity.
    { color: lastColor, overflow: 'some' },
    { color: lastColor, overflow: 'ample' }
  ];
};

/**
 * Create an array of color scale styles from an array of color scale colors.
 *
 * @param colors An ordered list of colors.
 */
export const buildColorScaleStyles = (
  colors: ColorScaleColors
): ColorScaleStyles => colors.map((color) => ({ color }));

/**
 * Create an array of interval definitions that fully define a color scale.
 *
 * It is expected that the number of minima is three greater than the number of
 * colors because of the special values (see `buildExtendedColorScaleStyles`).
 *
 * @param args
 *   - `colors`: An ordered array of colors for the intervals.
 *   - `minima`: An ordered array of minimum values for each interval.
 *   - `defaultMinima`: An ordered array of default minimum values for each
 *     interval.
 * @returns An ordered array of interval definitions.
 */
export const buildColorScaleIntervals = ({
  colors,
  minima,
  minimaDefaults
}: {
  colors: ColorScaleColors;
  minima: ColorScaleMinima;
  minimaDefaults?: ColorScaleMinima;
}): ColorScaleIntervals => {
  if (colors.length + 3 !== minima.length) {
    assert(
      colors.length + 3 === minima.length,
      'The `minima` array must contain 3 values more than the `colors` array.'
    );
    return [];
  }

  if (minimaDefaults && minima.length !== minimaDefaults.length) {
    assert(
      minima.length === minimaDefaults.length,
      'The `minima` and `defaultMinima` arrays must contain the same number of values.'
    );
    return [];
  }

  // This adds three special styles to the color scale.
  const colorStyles = buildExtendedColorScaleStyles(colors);

  return zip(
    minima,
    minimaDefaults || [],
    colorStyles
  ).reduce<ColorScaleIntervals>(
    (result, [minimum, defaultMinimum, style], index) => {
      if (minimum !== undefined && style) {
        result.push({
          min: minimum,
          minDefault: defaultMinimum,
          style,

          // The minimum values of the null capacity, the first non-null
          // capacity and the first overflow capacity must not be modified.
          minIsFrozen: style.overflow === 'some' || index <= 1,

          // The null capacity cannot be modified.
          isHidden: index === 0
        });
      }
      return result;
    },
    []
  );
};

/**
 * Create an array of interval definitions that include the minima and the
 * maxima for each interval.
 *
 * @param scale Definitions of the color scale intervals without maxima.
 * @returns An ordered array of interval definitions with maxima.
 */
export const buildIntervalsWithMaxima = (
  scale: ColorScaleIntervals
): ColorScaleIntervalsWithMax =>
  // The maximum of each interval is the minimum of the following interval.
  // Therefore, start at the second index of the list for the maxima. Then
  // create tuples of minimum and maximum values.
  zip(scale, scale.slice(1)).reduce<Array<ColorScaleIntervalWithMax>>(
    (result, [current, following]) => {
      if (current) {
        result.push({
          min: current.min,
          minDefault: current.minDefault,
          minIsFrozen: current.minIsFrozen,

          max: following?.min,
          maxDefault: following?.minDefault,
          maxIsFrozen: following?.minIsFrozen,

          isHidden: current.isHidden,
          style: current.style
        });
      }
      return result;
    },
    []
  );
