import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'theme';
import styled from 'styled-components';
import { SORT_ORDER } from 'appConstants/filters';
// import { StickyRowContext } from '../TimesheetsTable';
import NewSortIcon from 'icons/NewSortIcon';
import { getNextSortOrderDAN } from 'appUtils/filters';
import {
  Toggle,
  ToggleContainer,
  ProjectToggleContainer
} from 'views/Home/Widgets/Widgets/UtilizationSummaryWidget/styles';
import { projectGradient, RainbowBox } from 'views/Home/Widgets/styles';
import { VIEW_BY } from 'appConstants/workload';
import { updateAccountFilterLocal } from 'actionCreators';

const StyledIconContainer = styled.span`
  height: 100%;
  display: flex;
  margin-left: 2px;
`;

const StyledHeaderLabel = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 16.5px;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorLightGray10};
  ${({ isBlueOnHover }) =>
    isBlueOnHover &&
    `
      &:hover {
        color: ${theme.colors.colorCalendarBlue};
        path {
          fill: ${theme.colors.colorCalendarBlue};
        }
      }
  `}
`;

const StyledReportHeader = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 10px;
  cursor: pointer;
  padding: 4px 0;
`;

const TotalHeaderCell = ({
  customRowProps,
  column,
  rows,
  isDisabled = false
}) => {
  const { headerLabel, headerType } = column;
  const {
    sortOrder,
    viewBy,
    activeFilter,
    pageName,
    updateSortAttributes,
    isDashboardWidgetModal
  } = customRowProps;
  const dispatch = useDispatch();
  const { showUtilizationByProjects } = activeFilter.custom;

  const handleSort = () => {
    if (updateSortAttributes) {
      updateSortAttributes();
    }
  };

  const toggleFilter = useCallback(
    (param) => {
      dispatch(
        updateAccountFilterLocal({
          ...activeFilter,
          name: viewBy,
          page: pageName,
          custom: {
            ...activeFilter.custom,
            [param]: !activeFilter.custom[param]
          }
        })
      );
    },
    [activeFilter, dispatch, pageName, viewBy]
  );

  if (!headerLabel) {
    return <div />;
  }

  return (
    <StyledReportHeader>
      <StyledHeaderLabel
        onClick={headerType === 'total' ? handleSort : undefined}
        isBlueOnHover={headerType === 'total'}
        data-testid="total-sort-button"
      >
        {headerLabel}
        <StyledIconContainer>
          {headerType === 'total' && (
            <NewSortIcon isFlipped={sortOrder === SORT_ORDER.asc} />
          )}
        </StyledIconContainer>
      </StyledHeaderLabel>
      {viewBy === VIEW_BY.MEMBERS && !isDashboardWidgetModal && (
        <ProjectToggleContainer relativePosition="left: -30px;">
          <RainbowBox color={projectGradient} />
          <ToggleContainer
            data-testid={`project-breakdown-toggle`}
            isOn={showUtilizationByProjects}
            onClick={() => toggleFilter('showUtilizationByProjects')}
          >
            <Toggle />
          </ToggleContainer>
        </ProjectToggleContainer>
      )}
    </StyledReportHeader>
  );
};

export default TotalHeaderCell;
