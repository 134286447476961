import {
  TRAINING_DATA_REQUIRED_STRING,
  MEMBER_ACCESS_LEVELS_HASH,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';
import { RowValidator } from 'CsvImportsModule/types';

export const accessLevelValidator: RowValidator = ({ row, entityType }) => {
  const { enteredAccessLevel } = getRowValuesForEntityType(row, entityType);
  if (enteredAccessLevel === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (MEMBER_ACCESS_LEVELS_HASH[enteredAccessLevel] !== undefined)
    return true;
  else return ERROR_MESSAGES.invalidEmploymentType;
};
