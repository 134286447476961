import { createAction } from '@reduxjs/toolkit';
import { RequestStatusFields } from '../reducers/statuses';

export const removeRequestStatusId = createAction<RemoveRequestStatusIdParams>(
  'REMOVE_REQUEST_STATUS_ID'
);

export const setRequestStatus =
  createAction<SetRequestStatusParams>('SET_REQUEST_STATUS');

export type RemoveRequestStatusIdParams = string | string[];

export interface SetRequestStatusParams extends RequestStatusFields {
  requestStatusId: string;
}
