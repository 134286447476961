import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BodyColor, ActivityFeedContainer } from 'views';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { clearSelectedProject, fetchAllHomeTaskTags } from 'actionCreators';
import {
  getAuth,
  getMatchedRouteParams,
  getIsOnProjectView,
  getCurrentFilter,
  getOnHomeView,
  getIsOnTeamProject
} from 'selectors';
import WelcomeModal from 'components/Modals/WelcomeModal';
import HomeTaskListModalContainer from 'views/Home/HomeTaskListModalContainer';
import TimesheetContainer from 'views/timesheet/TimesheetContainer';
import LoadPersonalDashboardContainer from 'DashboardModule/components/LoadPersonalDashboardContainer';
import HomeWorkloadPlannerContainer from './components/HomeWorkloadPlannerContainer';
import HomeTopMenu from './components/HomeTopMenu';
import NavigationTracker from 'views/Navigation/NavigationTracker';
import MemberModalCalendar from 'views/memberDisplay/calendar/MemberModalCalendar';
import ProjectDetailsTablePageContainer from 'components/tables/ProjectDetailsTable/AllProjectsReportPageContainer';
import { Sections, HOME_VIEW_TYPE } from 'appConstants/navigation';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

const TasksListContainer = lazy(() =>
  import('views/Home/TaskList/TasksListContainer')
);

const HomePageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const HomePageContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const HomeTasksWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

// We have future spec for top menu to have links to all different types of tasks. eg completed, assigned by me
const homeTopMenuDisplayHash = [
  HOME_VIEW_TYPE.MY_TASKS,
  HOME_VIEW_TYPE.PLANNER
];

class HomePageContainer extends Component {
  state = {
    welcomeModalIsOpen: false
  };

  componentDidMount() {
    const {
      clearSelectedProject,
      fetchAllHomeTaskTags,
      matchedParams: { projectId },
      isOnProjectView,
      auth
    } = this.props;

    clearSelectedProject();

    if (auth.account) {
      fetchAllHomeTaskTags({
        filter: 'accepted',
        projectId: isOnProjectView && projectId,
        assigneeId: auth.account.id
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      auth,
      fetchAllHomeTaskTags,
      matchedParams: { projectId },
      isOnProjectView
    } = this.props;

    if (prevProps.auth.account !== auth.account && auth.account) {
      fetchAllHomeTaskTags({
        filter: 'accepted',
        projectId: isOnProjectView && projectId,
        assigneeId: auth.account.id
      });
    }
  }

  toggleWelcomeModal = () => {
    this.setState((prevState) => {
      return { welcomeModalIsOpen: !prevState.welcomeModalIsOpen };
    });
  };

  render() {
    const { welcomeModalIsOpen } = this.state;
    const {
      children,
      matchedParams,
      isOnHomeView,
      currentFilter,
      isOnTeamProject,
      auth
    } = this.props;

    const renderAsTeamProject = isOnTeamProject && !isOnHomeView;
    const homeViewType = matchedParams?.homeViewType;
    const shouldDisplayTopMenu = homeTopMenuDisplayHash.includes(homeViewType);
    const accountId = auth.account?.id;
    const isOnPersonalDashboard = homeViewType === 'dashboard';

    return (
      <HomePageWrapper>
        <NavigationTracker section={Sections.HOME} view={homeViewType} />
        <WelcomeModal
          toggleWelcomeModal={this.toggleWelcomeModal}
          welcomeModalIsOpen={welcomeModalIsOpen}
        />
        <ReactTooltip effect="solid" id="my-task" />
        <BodyColor
          isCyanBlue={isOnPersonalDashboard}
          isWhite={!isOnPersonalDashboard}
        />
        <HomePageContentWrapper>
          {/* <HomePlannerContainer /> */}
          {shouldDisplayTopMenu && <HomeTopMenu homeViewType={homeViewType} />}
          {homeViewType === 'dashboard' ? (
            <LoadPersonalDashboardContainer accountId={accountId} />
          ) : homeViewType === 'workplans' ? (
            <HomeWorkloadPlannerContainer />
          ) : homeViewType === 'calendar' ? (
            <MemberModalCalendar
              accountId={accountId}
              shouldUseWhiteBackground
            />
          ) : homeViewType === 'projects' ? (
            <ProjectDetailsTablePageContainer
              accountId={accountId}
              shouldIncludePersonalProjects
            />
          ) : (
            <HomeTasksWrapper
              isOnTeamProject={renderAsTeamProject}
              addPaddingLeft={isOnHomeView}
              plannerViewType={
                currentFilter?.subSection === 'scheduled' ||
                currentFilter?.subSection === 'checkins'
                  ? currentFilter.viewType
                  : undefined
              }
            >
              {homeViewType === 'time' && (
                <>
                  <BodyColor isGray />
                  <TimesheetContainer />
                </>
              )}
              {homeViewType === 'notifications' && (
                <>
                  <BodyColor isGray />
                  <ActivityFeedContainer isOnHomeView />
                </>
              )}
              {homeViewType !== 'time' && homeViewType !== 'notifications' && (
                <DynamicModuleLoader modules={[getBudgetModule()]}>
                  <Suspense fallback={<div />}>
                    <TasksListContainer />
                  </Suspense>
                </DynamicModuleLoader>
              )}
            </HomeTasksWrapper>
          )}
          <HomeTaskListModalContainer />
        </HomePageContentWrapper>
        {children}
      </HomePageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  currentFilter: getCurrentFilter(state),
  isOnHomeView: getOnHomeView(state),
  isOnTeamProject: getIsOnTeamProject(state)
});

const mapDispatchToProps = {
  clearSelectedProject,
  fetchAllHomeTaskTags
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageContainer)
);
