import React from 'react';
import styled from 'styled-components';
import NavLeftArrow from 'icons/NavLeftArrow';
import NavRightArrow from 'icons/NavRightArrow';
import KaratLeft from 'icons/KaratLeft';
import KaratRight from 'icons/KaratRight';
import { DoneButton, CancelButton } from './styles';

const Nav = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${({ isLeft }) => isLeft && 'left: 100px;'}
  ${({ isLeft }) => isLeft && 'padding-right: 4px;'}
  ${({ isRight }) => isRight && 'right: 100px;'}
  ${({ isRight }) => isRight && 'padding-left: 4px;'}
 
  &:hover {
    background: #f3f3f3;
  } ;
`;

export const NavLeft = () => (
  <Nav isLeft>
    <KaratLeft />
  </Nav>
);

export const NavRight = () => (
  <Nav isRight>
    <KaratRight height={'25px'} width={'16px'} />
  </Nav>
);

const MonthDisplay = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .month {
    color: #4f4f4f;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
  }

  .year {
    color: #a4a4a4;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const renderMonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
  isVisible
}) => {
  return (
    <MonthDisplay>
      <div style={{ justifySelf: 'center' }}>
        <div className="month">{month.format('MMM')}</div>
        <div className="year">{month.year()}</div>
      </div>
    </MonthDisplay>
  );
};
