import { createSelector } from 'reselect';
import { initialState as globalIntegrationsInitialState } from '../reducers/globalIntegrations';
import {
  DATA_TYPES,
  ACTIVE_MAPPING_STATUSES
} from 'IntegrationsModule/constants';

const emptyObj = {};
const emptyArray = [];
const getOwnAccountId = (state, ownProps) => ownProps.accountId;

export const makeGetIntegrationProjects = (integration) => (state) =>
  state[`integrations/${integration}`]?.projects?.integrationProjects ||
  emptyObj;

export const makeGetSyncLogState = (integration) => (state) =>
  state[`integrations/${integration}`]?.syncLog || emptyObj;

export const makeGetSyncLogData = (integration) =>
  createSelector(makeGetSyncLogState(integration), (syncLog) => {
    return syncLog.data;
  });

const makeGetIntegrationProjectsOrder = (integration) => (state) =>
  state[`integrations/${integration}`]?.projects?.integrationProjectOrder ||
  emptyArray;

export const makeGetOrderedIntegrationProjects = (integration) =>
  createSelector(
    makeGetIntegrationProjects(integration),
    makeGetIntegrationProjectsOrder(integration),
    (projects, ids) => ids.map((id) => projects[id])
  ) || emptyArray;

export const getIntegrationsProjectState = (integration) => (state) =>
  state[`integrations/${integration}`]?.projects || emptyObj;

export const makeGetIntegrationMovedProjects = (integration) =>
  createSelector(
    getIntegrationsProjectState(integration),
    (state) => state.movedProjects
  );

export const getIntegrationState = (state) =>
  state.integrations || globalIntegrationsInitialState;
export const getIntegrations = createSelector(
  getIntegrationState,
  (state) => state.integrations || emptyObj
);
export const getIntegrationsList = createSelector(
  getIntegrations,
  (integrations) => Object.keys(integrations)
);
export const getIsLoadingForIntegrations = createSelector(
  getIntegrationState,
  (integrationState) => integrationState && integrationState.isLoading
);

export const getSaveButtonClikedFlag = createSelector(
  getIntegrationState,
  (integrationState) => integrationState.saveButtonClicked
);

export const getIsCancellingFlag = createSelector(
  getIntegrationState,
  (integrationState) => integrationState.isCancelling
);

export const getIntegrationsV2 = createSelector(
  getIntegrationState,
  (state) => state.integrationsV2 || emptyObj
);

// aka target service ids
export const getIntegrationIds = createSelector(
  getIntegrationsV2,
  (integrations) => Object.keys(integrations)
);

export const getFetchedMappingStatusMosaicIds = createSelector(
  getIntegrationState,
  (state) => state.fetchedMappingStatusMosaicIds || emptyObj
);

export const getMosaicMappingStatuses = createSelector(
  getIntegrationState,
  (state) => state.mosaicMappingStatuses || {}
);

export const getIsProvisioning = createSelector(
  getIntegrationState,
  (state) => state.isProvisioning
);

export const getIsIntegrationsV2Loading = createSelector(
  getIntegrationState,
  (state) => state?.isV2Loading ?? true
);

export const getIntegrationsV2Error = createSelector(
  getIntegrationState,
  (state) => state?.isV2Error ?? false
);

/**
 * Merges the mapped account ids for a given target service
 */
export const makeGetMappedAccountIdsByTargetService = (targetService) =>
  createSelector(getMosaicMappingStatuses, (mappingStatuses) => {
    // mappingStatuses will only contain statuses when mapped
    return Object.values(mappingStatuses[targetService] || {}).reduce(
      (acc, cur) => {
        acc = {
          ...acc,
          ...cur[DATA_TYPES.ACCOUNTS]
        };
        return acc;
      },
      {}
    );
  });

export const makeGetOwnAccountIsMappedToQb = () =>
  createSelector(
    makeGetMappedAccountIdsByTargetService('quickbooks-online'),
    makeGetMappedAccountIdsByTargetService('quickbooks-desktop'),
    getOwnAccountId,
    (qboAccounts, qbdAccounts, accountId) => {
      return ACTIVE_MAPPING_STATUSES.some(
        (status) =>
          status === qboAccounts[accountId] || status === qbdAccounts[accountId]
      );
    }
  );

export const getIntegrationEntityStats = createSelector(
  getIntegrationState,
  (state) => state.entityStats || {}
);

export const getIntegrationAuthStatuses = createSelector(
  getIntegrationState,
  (state) => state.authStatuses || {}
);

export const getIntegrationSettingsSchemas = createSelector(
  getIntegrationState,
  (state) => state.settingsSchemas
);

export const getIntegrationSettingsSchema = createSelector(
  getIntegrationSettingsSchemas,
  (state, ownProps) => ownProps.targetServiceId,
  (schemas, targetServiceId) => schemas[targetServiceId]
);

export const getIntegrationAutoLink = createSelector(
  getIntegrationState,
  (state) => state.integrationAutoLink
);

export const getIntegrationAutoLinkPhases = createSelector(
  getIntegrationAutoLink,
  (integrationAutoLink) =>
    integrationAutoLink?.phases?.map((phase) => ({
      name: phase,
      isPhase: true
    })) || []
);

export const getIntegrationAutoLinkPhasesHash = createSelector(
  getIntegrationAutoLinkPhases,
  (autolinkPhases) =>
    autolinkPhases.reduce((acc, curr) => {
      const values = { name: curr };
      acc[curr] = values;
      return acc;
    }, {})
);

export const getIntegrationAutoLinkSubphases = createSelector(
  getIntegrationAutoLink,
  (integrationAutoLink) => integrationAutoLink.subPhasesByPhaseName
);

const makeSubphaseArraysKeyedByPhase = (integrationAutoLinkSubphasesHash) => {
  const subphaseArraysKeyedByPhase = {};
  const parentPhaseNames = Object.keys(integrationAutoLinkSubphasesHash);
  parentPhaseNames.forEach((parentPhaseName) => {
    const subPhaseArray =
      integrationAutoLinkSubphasesHash?.[parentPhaseName]?.map((subPhase) => ({
        name: subPhase,
        isPhase: false,
        isSubphase: true,
        parentPhaseName
      })) || null;
    subphaseArraysKeyedByPhase[parentPhaseName] = subPhaseArray;
  });
  return subphaseArraysKeyedByPhase;
};

export const getSubphaseArraysKeyedByPhase = createSelector(
  getIntegrationAutoLinkSubphases,
  makeSubphaseArraysKeyedByPhase
);
export const getIsUpdatingConfigs = createSelector(
  getIntegrationState,
  (state) => state.isUpdatingConfigs
);

export const getTrayConfigWizard = createSelector(
  getIntegrationState,
  (integrationState) => integrationState.trayConfigWizard
);
