import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { UpdateOrttoUserInitialPayload } from './types';

const UPDATE_ORTTO_USER = createRequestTypes('UPDATE_ORTTO_USER');

export const updateOrttoUser = createAction<UpdateOrttoUserInitialPayload>(
  UPDATE_ORTTO_USER.TRIGGER
);

export const updateOrttoUserActionCreatorsMap =
  createActionCreatorsMap<UpdateOrttoUserInitialPayload>(UPDATE_ORTTO_USER);

/* -------------------------------------------------------------------------- */
