import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import includes from 'lodash/includes';
import keyBy from 'lodash/keyBy';
import { Waypoint } from 'react-waypoint';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ProjectsByPriorityContainer, ProjectCommentModal } from '..';
import {
  fetchProjectsByGroup,
  fetchBoardMembers,
  updateProjectPosition,
  deleteProject,
  fetchSelectedBoard,
  clearCachedProjects,
  openEditProjectModal,
  indicateLazyLoad,
  setModal,
  openAddMembersForm,
  clearSelectedBoard,
  removeBoardSubscriptions,
  navigateToSettings,
  updateBoardModules,
  checkHasTimeEntries
} from 'actionCreators';
import WAYPOINT_INTS from 'appConstants/waypointInts';

import {
  getOnProjectDetail,
  getAuthToken,
  getSearchText,
  getProjectsState,
  getSearch,
  getSelectedAccountIds,
  getIsActive,
  getBilling,
  getMe,
  getGroupsState,
  getProjectItemState,
  getRouter,
  getSelectedProjectIsPersonal,
  getMatchedRouteParams,
  getStatusOrder,
  getStageOrder,
  getPriorityOrder,
  getBoardSortProperty,
  getSelectedTeamId
} from 'selectors';

const reorderableProperties = {
  statusOrder: true,
  priorityOrder: true,
  stageOrder: true
};
class ProjectListContainer extends React.Component {
  state = {
    showPriorityOptionsForId: null,
    dragging: false,
    projectOffsetTop: 0,
    bottomBelow: false,
    topAbove: false,
    showLoader: false
  };

  componentDidMount() {
    const {
      fetchProjectsByGroup,
      projects,
      searchText,
      isActive,
      groups,
      location,
      teamId,
      matchedParams: { boardId }
    } = this.props;
    const accountIds = projects.selectedAccountIds;
    const regExpMatch = /[\d|,|.|\+]+/g;
    const target = location.search && location.search;
    const res = target.match(regExpMatch);
    const searchParams =
      res && location.search
        ? {
            isActive: location.search.split(true).length > 1,
            projectPosition: +res[0]
          }
        : null;

    let limit, offset;
    // to load projects on board view, and avoid redundant call on project detail through fetchProjectsById
    if (searchParams) {
      if (
        searchParams.projectPosition + 1 > WAYPOINT_INTS.projects.baseLimit &&
        searchParams.projectPosition > 50
      ) {
        limit = WAYPOINT_INTS.projects.baseLimit;
        offset = searchParams.projectPosition - 15;
      } else {
        limit = searchParams.projectPosition + 1;
        offset = 0;
      }
    } else {
      limit = WAYPOINT_INTS.projects.firstLoadBaseLimit;
      offset = 0;
    }

    fetchProjectsByGroup({
      searchText,
      accountIds,
      groupId: +boardId,
      limit,
      offset,
      isActive: searchParams ? searchParams.isActive : isActive
    });

    this.addPrintListener();
    const projectIds = this.props.projects.allProjects.map(
      (project) => project.id
    );
    if (teamId && projectIds.length) {
      checkHasTimeEntries({
        team_id: teamId,
        project_ids: projectIds,
        permissions: this.getPermissions()
      });
    }
  }

  componentWillUnmount() {
    document.onkeydown = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      fetchProjectsByGroup,
      fetchBoardMembers,
      matchedParams: { boardId },
      projects,
      clearCachedProjects,
      fetchSelectedBoard,
      removeBoardSubscriptions
    } = this.props;

    const nextBoardId = nextProps.matchedParams.boardId;

    const newLoadingState = this.getLoadingState(this.props, nextProps);
    if (newLoadingState !== this.state.showLoader) {
      this.setState({ showLoader: newLoadingState });
    }

    if (nextBoardId !== boardId) {
      clearCachedProjects();
      removeBoardSubscriptions({ ids: [this.props.matchedParams.boardId] });
      // Timeout to ensure cached projects clear first
      setTimeout(() => {
        fetchProjectsByGroup({
          groupId: nextBoardId,
          isActive: projects.isActive
        });
      }, 150);

      fetchSelectedBoard(nextBoardId);
      fetchBoardMembers(nextBoardId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      projects,
      billing,
      setModal,
      navigateToSettings,
      sortProperty,
      matchedParams: { boardId },
      searchText,
      accountIds,
      fetchProjectsByGroup,
      teamId,
      checkHasTimeEntries
    } = this.props;

    const statusText = projects.statusText;

    if (prevProps.projects.statusText !== statusText && statusText === 409) {
      navigateToSettings();
      setModal('payment-plan-modal', billing.currentModal);
    }
    if (sortProperty !== prevProps.sortProperty) {
      fetchProjectsByGroup({
        groupId: boardId,
        isActive: projects.isActive,
        searchText: searchText,
        accountIds: accountIds,
        limit: WAYPOINT_INTS.projects.firstLoadBaseLimit
      });
    }
    if (
      prevProps.projects.allProjects !== this.props.projects.allProjects ||
      (teamId && !prevProps.teamId)
    ) {
      const oldProjectIds = prevProps.teamId
        ? keyBy(prevProps.projects.allProjects, (item) => item.id)
        : {}; // count all project ids as new if team id new
      const newProjectIds = this.props.projects.allProjects
        .map((item) => item.id)
        .filter((id) => !oldProjectIds[id]);
      if (newProjectIds.length && teamId) {
        checkHasTimeEntries({
          team_id: teamId,
          project_ids: newProjectIds,
          permissions: this.getPermissions()
        });
      }
    }
  }

  getPermissions = () => {
    const { teamId } = this.props;
    return {
      teamId,
      mine: false
    };
  };

  onBeforeDragStart = () => {
    this.setState({
      isDragging: true
    });
  };

  isBoardReorderableProperty = (type) => reorderableProperties[type];
  handleBoardPropertyReorder = (sourceIndex, destinationIndex, type) => {
    const {
      updateBoardModules,
      matchedParams: { boardId }
    } = this.props;
    const order = this.props[type];
    const newOrder = [...order];
    const id = newOrder[sourceIndex];
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, id);
    updateBoardModules({
      boardId,
      [type]: newOrder
    });
  };

  onDragEnd = (result) => {
    const {
      matchedParams: { boardId },
      token,
      updateProjectPosition
    } = this.props;
    const { source, destination, draggableId, type } = result;
    if (!source || !destination) {
      console.error('no source or destination');
      return;
    }
    const projectId = draggableId;
    const projectPosition = destination.index;

    if (this.isBoardReorderableProperty(type)) {
      return this.handleBoardPropertyReorder(
        source.index,
        destination.index,
        type
      );
    }

    // this.setState({ isDragging: false });

    updateProjectPosition(
      token,
      projectId,
      true,
      projectPosition,
      boardId,
      true,
      [] // cannot drag & drop while filtering accountIds
    );
  };

  addPrintListener = () => {
    document.onkeydown = this.printProjectsTasksTasks;
  };

  printProjectsTasksTasks = (e) => {
    const {
      fetchProjectsByGroup,
      matchedParams: { boardId },
      projects,
      search
    } = this.props;

    if (
      (e.ctrlKey || e.metaKey) &&
      e.keyCode == 80 &&
      !search.searchText &&
      projects.selectedAccountIds.length === 0
    ) {
      e.preventDefault();

      fetchProjectsByGroup({
        groupId: boardId,
        isActive: projects.isActive,
        all: true
      });

      // Ideally, we'd wait for fetchAllProjects to finish
      setTimeout(window.print, 3000);
    }
  };

  getLoadingState = (currentProps, nextProps) => {
    const duringFetch =
      currentProps.projects.isFetchingProjects &&
      nextProps.projects.isFetchingProjects;
    const beganFetching =
      !currentProps.projects.isFetchingProjects &&
      nextProps.projects.isFetchingProjects;
    const finishedFetching =
      currentProps.projects.isFetchingProjects &&
      !nextProps.projects.isFetchingProjects;

    if (finishedFetching) return false;
    if (beganFetching || duringFetch) {
      return true;
    }

    return this.state.showLoader;
  };

  setListContainerRef = (element) => {
    // keeps track of scroll offset for project detail expansion animation
    this.listContainerRef = element;
  };

  setScrollableListRef = (element) => {
    // for waypoints to know if they are in/out of view
    // also for proj detail expansion animation knowing scroll position
    this.scrollableListRef = element;
  };

  getVerticalLazyLoadOffset = (projectListType) => {
    // projectListType 'allProjects', 'scheduledProjects', 'unscheduledProjects' to match redux
    const { projects } = this.props;
    const length = projects[projectListType].length;
    return length < WAYPOINT_INTS.projects.firstLoadBaseLimit
      ? 0
      : projects[projectListType][projects[projectListType].length - 1]
          .position;
  };

  allProjectsLazyLoad = () => {
    const {
      matchedParams: { boardId },
      projects,
      accountIds,
      fetchProjectsByGroup
    } = this.props;
    fetchProjectsByGroup({
      groupId: +boardId,
      limit: WAYPOINT_INTS.projects.baseLimit,
      offset: this.getVerticalLazyLoadOffset('allProjects'),
      isActive: projects.isActive,
      accountIds
    });
  };

  lazyLoadBottom = ({ previousPosition, currentPosition }) => {
    const { projects } = this.props;
    const isWaypointInView =
      previousPosition === Waypoint.below &&
      currentPosition === Waypoint.inside;

    const statusProjectsLoaded =
      projects.allProjects.length >= projects.totalProjects;

    const shouldLoadStatus = !statusProjectsLoaded;

    if (isWaypointInView && shouldLoadStatus) {
      this.allProjectsLazyLoad();
    }
  };

  renderWaypoint = ({ bottomOffset }) => (
    <Waypoint
      key="waypoint"
      onEnter={(waypointEventObj) => this.lazyLoadBottom(waypointEventObj)}
      scrollableAncestor={this.scrollableListRef}
      bottomOffset={bottomOffset}
    />
  );

  clearPriorityOptions = (e) => {
    const clickedTrigger =
      includes(e.target.className, 'ico-drag-wrapper') ||
      includes(e.target.className, 'ico-drag-project');
    if (!clickedTrigger) {
      this.setState({ showPriorityOptionsForId: null });
    }
  };

  setPriorityOptionsMemo = {};

  setPriorityOptionsId = (projectId) => {
    if (!this.setPriorityOptionsMemo[projectId]) {
      this.setPriorityOptionsMemo[projectId] = () => {
        this.setState({
          showPriorityOptionsForId: this.state.showPriorityOptionsForId
            ? null
            : projectId
        });
      };
    }
    return this.setPriorityOptionsMemo[projectId];
  };

  renderProjectList = () => {
    const {
      projects,
      groups,
      deleteProject,
      isOnProjectDetail,
      projectItemState,
      search
    } = this.props;
    const { isFetchingProjects } = projects;
    const showSpinner =
      !search.isGlobalSearching && projects.allProjects.length;
    return (
      <div
        className={cn('project-list status-view', {
          'd-print-none': isOnProjectDetail
        })}
      >
        <div
          className={cn('project-list-content', {
            'navbar-at-back':
              projectItemState.fixed &&
              projectItemState.expanded &&
              projectItemState.detailVisible
          })}
          ref={this.setListContainerRef}
        >
          <div className={cn('project-list-content-status')}>
            <ProjectsByPriorityContainer
              isFetchingProjects={isFetchingProjects}
              groups={groups}
              projectListGrouped={projects.allProjects}
              statusName={projects.isActive ? 'Active' : 'Archived'}
              statusId={projects.isActive ? 0 : 1}
              showPriorityOptionsForId={this.state.showPriorityOptionsForId}
              setPriorityOptionsId={this.setPriorityOptionsId}
              isSearching={search.isFocused}
              isFiltering={projects.filteringByMembers}
              deleteProject={deleteProject}
              totalAboveProjects={0}
              renderWaypoint={this.renderWaypoint}
              scrollableListRef={this.scrollableListRef}
              setScrollableListRef={this.setScrollableListRef}
              shouldRenderSkeletons={
                this.state.showLoader ||
                projects.filteringByMembers ||
                search.isGlobalSearching
              }
              shouldShowSpinner={showSpinner}
            />
          </div>
        </div>
        <div className="project-list-sides" />
      </div>
    );
  };

  render() {
    const { me, isOnProjectDetail } = this.props;
    if (me) {
      return (
        <div onClick={this.clearPriorityOptions}>
          {!isOnProjectDetail && (
            <DragDropContext
              onBeforeDragStart={this.onBeforeDragStart}
              onDragEnd={this.onDragEnd}
            >
              {this.renderProjectList()}
            </DragDropContext>
          )}
          {this.props.children}
          <ProjectCommentModal />
        </div>
      );
    }
    return null;
  }
}

ProjectListContainer.propTypes = {
  fetchProjectsByGroup: PropTypes.func.isRequired,
  fetchBoardMembers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,
  me: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.object
};

const mapStateToProps = (state) => ({
  me: getMe(state),
  projects: getProjectsState(state),
  token: getAuthToken(state),
  search: getSearch(state),
  searchText: getSearchText(state),
  groups: getGroupsState(state),
  isOnProjectDetail: getOnProjectDetail(state),
  accountIds: getSelectedAccountIds(state),
  projectItemState: getProjectItemState(state),
  router: getRouter(state),
  isActive: getIsActive(state),
  billing: getBilling(state),
  selectedProjectIsPersonal: getSelectedProjectIsPersonal(state),
  matchedParams: getMatchedRouteParams(state),
  statusOrder: getStatusOrder(state),
  priorityOrder: getPriorityOrder(state),
  stageOrder: getStageOrder(state),
  sortProperty: getBoardSortProperty(state),
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  fetchProjectsByGroup,
  fetchBoardMembers,
  updateProjectPosition,
  deleteProject,
  fetchSelectedBoard,
  clearCachedProjects,
  openEditProjectModal,
  indicateLazyLoad,
  setModal,
  openAddMembersForm,
  clearSelectedBoard,
  removeBoardSubscriptions,
  navigateToSettings,
  updateBoardModules,
  checkHasTimeEntries
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectListContainer)
);
