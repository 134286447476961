import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import uuid from 'uuid';
import ProjectSelectionPopover from 'components/ProjectSelectionPopover';
import TextArea from 'components/TextArea';
import FollowedBell from '../../taskDisplay/taskUtilityComponents/FollowedBell';
import TaskColumns from './TaskColumns';
import DropdownArrowIcon from 'icons/ProjectSelectionPopover/DropdownArrowIcon';
import moment from 'moment';
import {
  TaskCreationMainContentPanel,
  TaskContentLeft,
  TaskDescriptionAndProjectContainer,
  CreateTaskClickBox
} from './styles';
import {
  TaskIconsContainer,
  TaskCommentIconWrapper
} from 'components/TextArea/styles';
import CommentBubbleIcon from 'icons/CommentBubbleIconV2';
import NoteIcon from 'icons/NoteIcon';
import {
  triggerTaskCreationServerRequest,
  createTaskAndOpenModal
} from 'actionCreators';
import {
  getIsOnTeamProject,
  getSelectedTeamMember,
  getIsOnPersonalProject,
  makeSafeGetProjectAssignmentProjects,
  getTaskEditProperty,
  getOnHomeView,
  getAuth,
  getSearchText,
  isSomeTaskEditing,
  getSelectedTeamId,
  getMatchedRouteParams,
  getIsOnProjectView,
  getSelectedProject,
  getOnProfile
} from 'selectors';
import { noop } from 'appUtils';
import { isInAnyPopover, isInAnyModal } from 'appUtils/popoverClicks';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import theme from 'theme';

export const ProjectMenuButton = styled.span`
  display: flex;
  font-family: 'Open Sans';
  font-size: 11px;
  line-height: normal;
  align-items: center;
  color: ${({ taskBeingEdited, theme }) =>
    taskBeingEdited
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorMediumGray5};
  cursor: pointer;
  visibility: ${({ taskBeingEdited, hasProject }) =>
    taskBeingEdited || hasProject ? 'visible' : 'hidden'};

  &:hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

export const DropdownArrowWrapper = styled.span`
  margin-left: 6px;
  display: ${({ taskBeingEdited, isDetailModal }) =>
    taskBeingEdited || isDetailModal ? 'inline-block' : 'none'};
`;

export const ScheduleWrapper = styled.span`
  padding-left: 18px;
  height: 100%;
  width: 100%;
`;
const StyledNoteContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
class CreateTaskWrapper extends Component {
  state = {
    id: 0,
    project: {},
    currentDescriptionText: '',
    assigneeIds: [],
    modularTaskProperties: {
      due_at: null,
      schedule_start: this.props.scheduleStart || null
    },
    popoverContainerRef: null
  };

  componentDidMount() {
    const { id } = this.state;
    document.getElementById(`task-description-field-${id}`).focus();
  }

  setPopoverRef = (ref) => {
    if (ref !== this.state.popoverContainerRef)
      this.setState({ popoverContainerRef: ref }); // this has to be done because for whatever reason the ref gets set after the Popover component is rendered
  };

  handleClickOutside = (event) => {
    if (
      isInAnyPopover(event) ||
      isInAnyModal(event) ||
      event.srcElement.offsetParent?.className.includes('rc-trigger-popup') ||
      event.srcElement.offsetParent?.className.includes('rc-calendar') ||
      event.srcElement.offsetParent?.className.includes(
        'range-option-container'
      )
    ) {
      return;
    }
    const { toggleTaskCreationHeader } = this.props;
    const { currentDescriptionText } = this.state;
    if (currentDescriptionText !== '') {
      this.handleTaskCreation();
    }
    toggleTaskCreationHeader();
  };

  getPermissions = () => {
    const {
      matchedParams: { projectId: projectIdParam },
      selectedTeamId,
      isOnProjectView
    } = this.props;
    const {
      project: { id }
    } = this.state;
    const projectId = isOnProjectView ? projectIdParam : null;
    const permissions = {
      projectId: id || projectId,
      teamId: selectedTeamId
    };
    return permissions;
  };

  getButtonText = () => {
    const { project } = this.state;
    const taskIsBeingEdited = true;
    if (!isEmpty(project)) {
      const { title: projectTitle, description: projectDescription } = project;
      const projectAndDescription = `${projectTitle} - ${projectDescription}`;
      const hasProjectDescription =
        projectTitle !== 'Select Project' && projectDescription;
      if (hasProjectDescription && taskIsBeingEdited) {
        return projectAndDescription;
      }
      return projectTitle;
    }
    return 'Select Project';
  };

  getConfiguredAssigneeIds = () => {
    const {
      auth: { account },
      selectedTeamMember,
      isOnProjectView,
      isOnTeamMemberProfile
    } = this.props;

    if (isOnTeamMemberProfile) {
      return [selectedTeamMember.account.id];
    } else if (isOnProjectView) {
      return this.state.assigneeIds;
    } else {
      return this.state.assigneeIds.length
        ? this.state.assigneeIds
        : [account.id];
    }
    // auto-assign self on home
    // auto-assign team member on member profile
  };

  onMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleOpenModalClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.handleTaskCreation({ openTaskModal: true });
  };

  handleTaskCreation = ({ openTaskModal = false } = {}) => {
    const {
      auth: { token, account },
      handleTaskCreationServerRequest,
      createTaskAndOpenModal,
      toggleTaskCreationHeader,
      matchedParams: { projectId: projectIdParam },
      position,
      selectedTeamMember,
      isOnProjectView,
      groupId
    } = this.props;
    const {
      currentDescriptionText: description,
      project,
      modularTaskProperties
    } = this.state;

    // if on project, will not auto assign to self
    const configuredAssigneeIds = this.getConfiguredAssigneeIds();
    const projectId = isOnProjectView ? projectIdParam : null;
    const project_id = 'id' in project ? project.id : null;
    const uniqueId = uuid();
    const permissions = this.getPermissions();
    const taskCreationParams = {
      token,
      body: {
        id: uniqueId,
        description: description.replace(/&nbsp;/g, ' ').trim(),
        assignee_ids: configuredAssigneeIds,
        project_id: projectId || project_id,
        view_assignee_id: selectedTeamMember
          ? selectedTeamMember.account.id
          : account.id,
        view_project_id: projectId,
        [projectId ? 'project_position' : 'home_position']: position,
        ...modularTaskProperties,
        schedule_start:
          modularTaskProperties.schedule_start &&
          moment(modularTaskProperties.schedule_start).format('YYYY-MM-DD'),
        due_at:
          modularTaskProperties.due_at &&
          moment(modularTaskProperties.due_at).format('YYYY-MM-DD')
      },
      permissions
    };
    if (groupId) taskCreationParams.body.task_group_id = groupId;

    if (openTaskModal) {
      createTaskAndOpenModal(taskCreationParams);
      toggleTaskCreationHeader();
    } else {
      handleTaskCreationServerRequest(taskCreationParams);
    }
  };

  handleDescriptionTextAreaEvent = (currentDescriptionText) => {
    this.setState({
      currentDescriptionText
    });
  };

  handleDescriptionSubmit = () => {
    const { toggleTaskCreationHeader } = this.props;
    const { currentDescriptionText } = this.state;
    if (currentDescriptionText !== '') {
      this.handleTaskCreation();
    }
    toggleTaskCreationHeader();
  };

  handleProjectMenuSelection = (
    newProjectId,
    groupId,
    phaseId,
    activityId,
    activityPhaseId
  ) => {
    const { myProjects } = this.props;

    this.setState(() => ({
      project: myProjects.find((project) => project.id === newProjectId) || {},
      modularTaskProperties: {
        ...this.state.modularTaskProperties,
        phase_id: phaseId,
        task_group_id: groupId,
        activity_id: activityId,
        activity_phase_id: activityId ? activityPhaseId : undefined
      }
    }));
    const { id } = this.state;
    document.getElementById(`task-description-field-${id}`).focus();
  };

  updateLocalTaskProperty = ({ body }) => {
    const { task_ids, ...updatedProperty } = body;
    this.setState((prevState) => ({
      modularTaskProperties: {
        ...prevState.modularTaskProperties,
        ...updatedProperty
      }
    }));
  };

  handleScheduleAtCalendarSelect = (scheduleStart) => {
    this.setState(() => ({
      scheduleStart
    }));
    const { id } = this.state;
    document.getElementById(`task-description-field-${id}`).focus();
  };

  handleDueDateCalendarSelect = (dueAt) => {
    this.setState(() => ({
      dueAt
    }));
    const { id } = this.state;
    document.getElementById(`task-description-field-${id}`).focus();
  };

  markTaskCompletion = () => {
    const { toggleTaskCreationHeader } = this.props;
    toggleTaskCreationHeader();
  };

  handleAssigneeSelection = (assigneeIds) => {
    this.setState({
      assigneeIds,
      modularTaskProperties: {
        ...this.state.modularTaskProperties,
        assignee_ids: assigneeIds
      }
    });
    const { id } = this.state;
    document.getElementById(`task-description-field-${id}`).focus();
  };

  renderProjectPopoverMenuItem = (project, isEditing) => {
    const hasProject = !isEmpty(project);
    return (
      <ProjectMenuButton taskBeingEdited={isEditing} hasProject={hasProject}>
        {this.getButtonText()}
        <DropdownArrowWrapper taskBeingEdited={isEditing}>
          <DropdownArrowIcon />
        </DropdownArrowWrapper>
      </ProjectMenuButton>
    );
  };

  render() {
    const {
      taskBeingEdited,
      isOnProjectDetail,
      isOnHomeView,
      currentlyFollowed,
      onFollowClick,
      shouldHideFollowedBell,
      isOnPersonalProject,
      searchText,
      isCreatingNewTask,
      isOnTeamProject,
      taskEditProperty,
      isHeader,
      todayView,
      isOnProjectView,
      selectedProject
    } = this.props;

    const {
      project,
      currentDescriptionText,
      id,
      assigneeIds,
      modularTaskProperties
    } = this.state;

    const projectToUse = isOnProjectView ? selectedProject : project;

    const task = {
      project: projectToUse,
      project_id: projectToUse?.id,
      description: currentDescriptionText,
      id,
      assignee_ids: assigneeIds,
      ...modularTaskProperties
    };

    return (
      <TaskCreationMainContentPanel
        projectDetail={isOnTeamProject}
        isOnPersonalProject={isOnPersonalProject}
        isCreatingNewTask={isCreatingNewTask}
        isHeader={isHeader}
        todayView={todayView}
        isOnTeamProject={isOnTeamProject}
        isOnHomeView={isOnHomeView}
        isSomeTaskEditing={isSomeTaskEditing}
      >
        <TaskContentLeft
          isOnTeamProject={isOnTeamProject}
          isOnPersonalProject={isOnPersonalProject}
          paddingLeft={12}
        >
          <FollowedBell
            isOnProjectDetail={isOnProjectDetail}
            beingEdited={taskBeingEdited}
            currentlyFollowed={currentlyFollowed}
            onFollowClick={onFollowClick}
            shouldHideFollowedBell={shouldHideFollowedBell}
          />
        </TaskContentLeft>
        <TaskDescriptionAndProjectContainer
          ref={this.setPopoverRef}
          isOnTeamProject={isOnTeamProject}
          isProjectDetail={isOnProjectView}
          isTaskEditProperty={'description'}
          taskIsEditing={true}
        >
          <TextArea
            taskId={id}
            searchText={searchText}
            currentDescriptionText={currentDescriptionText}
            onChange={this.handleDescriptionTextAreaEvent}
            onSubmit={this.handleDescriptionSubmit}
            taskIsCreating
          />
          {!isOnProjectView && (
            <ProjectSelectionPopover
              target={this.state.popoverContainerRef}
              task={task}
              onSelect={this.handleProjectMenuSelection}
              modularTaskProperties={this.state.modularTaskProperties}
              header={null}
              headerText={'Select Project'}
              popupAlign={{
                points: ['tl', 'bl'],
                offset: [0, 10]
              }}
            >
              {() => this.renderProjectPopoverMenuItem(project, true)}
            </ProjectSelectionPopover>
          )}
        </TaskDescriptionAndProjectContainer>
        <CreateTaskClickBox
          onClick={this.handleOpenModalClick}
          onMouseDown={this.onMouseDown}
          isOnTeamProject={isOnTeamProject}
        >
          <TaskIconsContainer $inTaskAdd>
            <StyledNoteContainer
              {...defaultTooltipProps}
              data-tip="Add task notes"
            >
              <NoteIcon isHovered={task.note} />
            </StyledNoteContainer>
            <TaskCommentIconWrapper
              $show
              {...defaultTooltipProps}
              data-tip="Add task updates & attachments"
              role="button"
              tabIndex="-1"
            >
              <CommentBubbleIcon
                color={theme.colors.colorMediumGray1}
                height={16}
                width={16}
              />
            </TaskCommentIconWrapper>
          </TaskIconsContainer>
        </CreateTaskClickBox>
        <TaskColumns
          isNewTask
          taskIsEditing
          task={task}
          handleTasksAttributesUpdate={this.updateLocalTaskProperty}
          isOnTeamProject={isOnTeamProject}
          isOnHomeView={isOnHomeView}
          isCreatingTask
          handleTaskEditClick={noop}
          taskEditProperty={taskEditProperty}
          alwaysRenderDue
          alwaysRenderSchedule
        />
      </TaskCreationMainContentPanel>
    );
  }
}

CreateTaskWrapper.propTypes = {};

const makeMapStateToProps = () => {
  const getSafeProjectAssignmentProjects =
    makeSafeGetProjectAssignmentProjects();
  const mapStateToProps = (state, ownProps) => ({
    auth: getAuth(state),
    searchText: getSearchText(state),
    myProjects: getSafeProjectAssignmentProjects(state, ownProps),
    selectedTeamMember: getSelectedTeamMember(state),
    todayView: state.homeTasks.currentFilter.scope === 'today',
    isOnTeamProject: getIsOnTeamProject(state),
    isOnPersonalProject: getIsOnPersonalProject(state),
    taskEditProperty: getTaskEditProperty(state),
    isOnHomeView: getOnHomeView(state),
    selectedTeamId: getSelectedTeamId(state),
    isSomeTaskEditing: isSomeTaskEditing(state),
    matchedParams: getMatchedRouteParams(state),
    isOnProjectView: getIsOnProjectView(state),
    selectedProject: getSelectedProject(state),
    isOnTeamMemberProfile: getOnProfile(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  handleTaskCreationServerRequest: triggerTaskCreationServerRequest,
  createTaskAndOpenModal
};

export default withRouter(
  connect(
    makeMapStateToProps,
    mapDispatchToProps
  )(onClickOutside(CreateTaskWrapper))
);
