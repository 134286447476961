import { TextButtonWithBorder } from 'components/styles';
import { StyledPhaseOfWorkInput } from 'BudgetModule/components/styles';
import NumberFormat from 'react-number-format';
import QBDownArrow from 'icons/QBDownArrow';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import styled from 'styled-components';
import theme from 'theme';

export const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledModalTitle = styled.div`
  font-size: 22px;
  color: black;
  font-weight: 600;
`;
export const StyledModalBody = styled.div`
  position: relative;
  padding: 35px 0 23px;
  ${StyledPhaseOfWorkInput} {
    color: ${theme.colors.colorMediumGray9};
    background: ${theme.colors.colorTranslucentGray4};
    border: 1px solid ${theme.colors.colorLightGray6};
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 7px;
    padding-left: 17px;
    ${(props) =>
      !props.nameIsEditable &&
      `
        padding-right: 36px;
        pointer-events: none;
    `}
  }
`;
export const StyledModalFooter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PaddingContainer = styled.div`
  padding: 0 1px;
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(NumberFormat)`
  background: ${theme.colors.colorTranslucentGray4};
  color: ${theme.colors.colorMediumGray9};
  width: 100%;
  border: 1px solid ${theme.colors.colorLightGray6};
  border-radius: 6px;
  text-align: right;
  font-size: 15px;
  height: 34px;
  line-height: 2.5;
  text-overflow: ellipsis;
  &::placeholder {
    text-align: left;
    color: ${theme.colors.colorLightGray15};
  }
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
    z-index: 1;
  }
`;
export const FeeInput = styled(StyledInput)`
  /* border-top-right-radius: 0; */
  /* border-bottom-right-radius: 0; */
  text-align: left;
  padding-left: 22px;
  ${({ isTotalEmpty }) =>
    isTotalEmpty &&
    `
      color: ${theme.colors.colorLightGray15};
    `}
`;
export const PhaseNumberInput = styled(StyledInput)`
  text-align: left;
  padding-left: 10px;
  color: ${theme.colors.colorLightGray15};
`;

export const ActivityPhaseNameInput = styled(StyledInput)`
  padding-left: 10px;
  padding-right: ${(props) => (props.isCustom ? '0px' : '20px')};
  text-align: left;
  cursor: pointer;
`;

export const PercentageInput = styled(StyledInput)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 21px;
  & + .percent-sign {
    color: ${theme.colors.colorMediumGray9};
    right: 6px;
    top: 25px;
    position: absolute;
    z-index: 2;
  }
  &::placeholder {
    text-align: right;
  }
  ${({ isPercentageEmpty }) =>
    isPercentageEmpty &&
    `
      color: ${theme.colors.colorLightGray15};
    `}
`;
export const EstimatedCostInput = styled(StyledInput)`
  ${({ hasPercentage }) =>
    hasPercentage &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}
  text-align: left;
  padding-left: 22px;
  ${({ isEstCostEmpty }) =>
    isEstCostEmpty &&
    `
      color: ${theme.colors.colorLightGray15};
    `}
`;
export const BottomInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const StyledLabel = styled.label`
  color: ${theme.colors.colorCalendarGray};
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 6px;
`;
export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || 120}px;
`;
export const StyledDollarSign = styled.div`
  position: absolute;
  left: 8px;
  top: 23px;
  font-size: 19px;
  color: ${theme.colors.colorCalendarGray};
  z-index: 2;
`;

export const DeleteButton = styled(TextButtonWithBorder)`
  border-color: transparent;
  &:hover {
    border-color: ${theme.colors.colorPaleRed};
    color: ${theme.colors.colorDeleteRed};
    filter: unset;
  }
`;

export const ActivityPhaseNameContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const DropdownArrow = styled(QBDownArrow)`
  position: absolute;
  top: 14px;
  right: 10px;
  width: 14px;
  height: 10px;
  path {
    fill: ${theme.colors.colorMediumGray9};
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  height: 34px;
  &.btn-secondary {
    background: ${theme.colors.colorTranslucentGray4}!important;
    color: ${theme.colors.colorMediumGray9}!important;
    color: ${(props) =>
      props.muted
        ? theme.colors.colorLightGray15
        : theme.colors.colorMediumGray9}!important;
    width: 100%;
    border: 1px solid ${theme.colors.colorLightGray6}!important;
    border-radius: 6px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
  }
  &.btn-secondary:active,
  &.btn-secondary:focus {
    border: 1px solid ${theme.colors.colorMediumGray9}!important;
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  min-width: 120px;
`;

export const StyledQBDownArrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  path {
    fill: ${(props) =>
      props.fill ||
      (props.muted
        ? theme.colors.colorLightGray15
        : theme.colors.colorMediumGray9)} !important;
  }
`;

export const StyledDropdownItem = styled(DropdownItem)`
  padding: 5px 10px;
  font-size: 13px;
`;
