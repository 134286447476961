import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle
} from 'reactstrap';
import QBDownArrow from 'icons/QBDownArrow';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { useToggle } from 'react-use';

const noop = () => undefined;

export interface OptionConfig {
  isDisabled?: boolean;
  label?: React.ReactNode;
  toggleLabel?: React.ReactNode;
  handleClick?: () => void;
  tooltip?: string;
  isDivider?: boolean;
  className?: string;
}

export type OptionHash = Record<string, OptionConfig>;

interface OptionsDropdownProps<T extends OptionHash> {
  currentValue?: keyof T;
  optionHash: T;
  options: (keyof T)[];
  headerText?: string;
  renderToggle?: ({
    isOpen,
    isActive
  }: {
    isOpen: boolean;
    isActive: boolean;
  }) => React.ReactNode;
  isDisabled?: boolean;
  toggleRef?: React.Ref<DropdownToggle>;
  /**
   * true if the dropdown is being used in some way that is not known within the component itself.
   * eg. clicking an option closes this dropdown, but opens a different one
   */
  isActive?: boolean;
  sharedHandleClick?: (option: keyof T) => void;
  hideDropdownArrow?: boolean;
  invokeWithToggle?: () => void;
}

/**
 *
 * @handleClick a callback that is defined for each individual option once clicked
 * @sharedHandleClick  a shared callback that is called when an option is selected. The callback gets the selected option value
 */
export const OptionsDropdown = <T extends OptionHash>({
  currentValue,
  options,
  optionHash,
  headerText,
  renderToggle,
  isDisabled,
  toggleRef,
  isActive,
  sharedHandleClick,
  invokeWithToggle,
  hideDropdownArrow
}: OptionsDropdownProps<T>) => {
  const [isOpen, _toggleIsOpen] = useToggle(false);

  const toggleIsOpen = React.useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      invokeWithToggle && invokeWithToggle();
      _toggleIsOpen();
    },
    [_toggleIsOpen, invokeWithToggle]
  );

  const onMenuItemClick = ({ event, option, handleClick }) => {
    handleClick && handleClick();

    sharedHandleClick && sharedHandleClick(option);
  };

  const renderOption = (option: typeof options[0]) => {
    const config = optionHash[option];

    if (!config) return null;

    const {
      label,
      tooltip = '',
      handleClick,
      isDisabled,
      isDivider,
      className
    } = config as OptionConfig;

    if (tooltip) rebuildTooltip();
    if (isDivider)
      return (
        <DividerContainer key={option as string}>
          <StyledDivider />
        </DividerContainer>
      );
    return (
      <StyledDropdownMenuItem
        key={option as string}
        className={
          'options-dropdown-option nodrag' + (className ? ` ${className}` : '')
        }
        $isDisabled={isDisabled}
        active={option === currentValue}
        onClick={(event) => {
          isDisabled ? noop() : onMenuItemClick({ event, option, handleClick });
        }}
        data-for="app-tooltip"
        data-tip={tooltip}
        data-class={'blue-dark-tooltip'}
        data-effect="solid"
        data-html
      >
        {label}
      </StyledDropdownMenuItem>
    );
  };

  return (
    <StyledDropdown
      toggle={isDisabled ? noop : toggleIsOpen}
      isOpen={isOpen}
      className="options-dropdown nodrag"
      $isDisabled={isDisabled}
    >
      <StyledDropdownToggle className="options-dropdown-toggle" ref={toggleRef}>
        {renderToggle ? (
          renderToggle({ isOpen, isActive: Boolean(isOpen || isActive) })
        ) : (
          // default toggle
          <>
            {currentValue
              ? optionHash[currentValue]?.toggleLabel ??
                optionHash[currentValue]?.label
              : ''}
            {!hideDropdownArrow && <Arrow className="options-dropdown-arrow" />}
          </>
        )}
      </StyledDropdownToggle>
      <StyledDropdownMenu className="options-dropdown-menu">
        {headerText && (
          <StyledDropdownHeader className="options-dropdown-menu-header">
            {headerText}
          </StyledDropdownHeader>
        )}

        {options.map(renderOption)}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

/* ------------------------------------ - ----------------------------------- */

export const Arrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  margin-left: 5px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 10px 0px;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  min-width: max-content;
`;

export const StyledDropdown = styled(Dropdown)<{ $isDisabled?: boolean }>`
  ${(props) =>
    props.$isDisabled &&
    `
    cursor: not-allowed;
    filter: grayscale(1);
    .btn-secondary {
      pointer-events: none;
    }
  `}
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  font-size: 13px;
  padding: 0;
  &.btn-secondary,
  &.btn-secondary:active,
  &.btn-secondary:focus,
  &.btn-secondary:hover {
    color: ${theme.colors.colorCalendarBlue} !important;
  }
`;

export const StyledDropdownMenuItem = styled(DropdownItem)<{
  $isDisabled?: boolean;
}>`
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 6px 20px;
  ${(props) =>
    props.$isDisabled &&
    `
    opacity: 0.7;
  `}
  &.active {
    color: ${theme.colors.colorMediumGray9};
    font-weight: 600;
    background: transparent;
    position: relative;
  }

  &:hover {
    ${(props) =>
      props.$isDisabled
        ? `
          background: transparent !important;
          cursor: default !important;
          color: unset;
        `
        : `
          background: ${theme.colors.colorPaleGray1};
          color: ${theme.colors.colorMediumGray9};
    `}
  }
`;

export const StyledDropdownHeader = styled(StyledDropdownMenuItem)`
  font-weight: 600;
  font-size: 14px;
  margin: 5px 0 2px;
  pointer-events: none;
  padding: 1px 16px;
  &:hover {
    background: transparent;
  }
`;

const StyledDivider = styled.hr`
  margin: 5px 0;
`;

const DividerContainer = styled.div`
  padding: 0 20px;
`;
