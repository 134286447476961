import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'reactstrap';
import {
  setEditMilestoneId,
  openPhaseModal,
  updateProjectModules,
  deletePhase,
  updatePhase,
  fetchUserActivitiesForActionable,
  openEditProjectModal
} from 'actionCreators';
import {
  getSelectedProject,
  getUserIsAdmin,
  getActivePhasesAndMilestonesByProject,
  getUserPermissions,
  getSelectedTeamId,
  getFlatPhasesAndMilestonesHash,
  getFlatPhasesHash,
  getMilestoneModalProject
} from 'selectors';
import { StyledDot, StyledColorContainer } from './styles';
import AdminTooltip from 'components/Tooltips/ContactAdminsTooltip';
import PhaseModal from 'BudgetModule/components/PhaseModal/PhaseModal';
import ColorPicker from 'components/ColorPicker';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import PhaseTemplateModal from 'BudgetModule/components/PhaseTemplateModal/PhaseTemplateModal';
import GlobalPhaseTemplateDropdown from 'BudgetModule/components/GlobalPhaseTemplateDropdown/GlobalPhaseTemplateDropdown';
import MilestoneTable from './MilestoneTable';
import reorder from 'appUtils/reorder';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import MilestoneTableCell from './MilestoneTableCell';
import EllipsisText from 'components/EllipsisText';
import AddPhaseAndMilestoneButton from './AddPhaseAndMilestoneButton';
import MilestoneModalContextProvider from 'contexts/MilestoneModalContext';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';
import ProjectInfoIcon from 'icons/ProjectInfoIcon';
import { actionableTypesHash } from 'appConstants/userActivities';
import { TitleAndDescriptionLoader } from 'components/SkeletonLoader/SkeletonLoader';
import withHardDeletePhaseModal from 'hocs/withHardDeletePhaseModal';
import { fetchProjectBudgetViewSettings } from 'BudgetModule/actionCreators';
import { getProjectBudgetSettings } from 'BudgetModule/selectors';
import partition from 'lodash/partition';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
import { SORT_BY } from 'appConstants/filters';

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 243px 171px 87px 28px 95px 80px;
  margin-left: 37px;
  margin-bottom: 3px;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
`;

const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 4px;
  margin-right: 5px;
`;

export const StyledActivityTimesheetIcon = styled(ActivityTimesheetIcon)`
  height: 15px;
  width: 15px;
  margin-right: 4px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

class MilestoneModal extends React.Component {
  state = {
    isShowingArchived: false
  };

  componentDidMount() {
    const {
      selectedProject,
      fetchUserActivitiesForActionable,
      fetchProjectBudgetViewSettings
    } = this.props;
    if (selectedProject) {
      fetchUserActivitiesForActionable({
        project_ids: [selectedProject?.id],
        actionable_type: actionableTypesHash.Phase,
        // BE's default limit is 50 and there is no 'all' options given.
        // this is temporary until we have spec how we could paginate and fetch only relevant data
        limit: 500
      });
      fetchProjectBudgetViewSettings({
        projectId: selectedProject?.id
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedProject,
      fetchUserActivitiesForActionable,
      fetchProjectBudgetViewSettings
    } = this.props;
    if (
      selectedProject &&
      selectedProject?.id !== prevProps.selectedProject?.id
    ) {
      fetchUserActivitiesForActionable({
        project_ids: [selectedProject?.id],
        actionable_type: actionableTypesHash.Phase,
        // BE's default limit is 50 and there is no 'all' options given.
        // this is temporary until we have spec how we could paginate and fetch only relevant data
        limit: 500
      });
      fetchProjectBudgetViewSettings({
        projectId: selectedProject?.id
      });
    }
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({ isShowingArchived: false });
    }
    if (this.props.userPermissions !== prevProps.userPermissions) {
      this.canDelete = undefined;
      this.canEdit = undefined;
    }
    rebuildTooltip();
  }

  getPermissions = () => {
    const { selectedProject, selectedTeamId } = this.props;
    const permissions = {
      projectId: selectedProject?.id,
      teamId: selectedTeamId
    };
    return permissions;
  };

  checkDeletePermission = () => {
    if (this.canDelete === undefined) {
      const { deletePhase, checkPermission } = this.props;
      this.canDelete = checkPermission(deletePhase, {
        permissions: this.getPermissions()
      });
    }
    return this.canDelete;
  };

  openPhaseModal = ({ id, name }) => {
    const { openPhaseModal, selectedProject } = this.props;
    openPhaseModal({
      id,
      name,
      projectId: selectedProject?.id,
      isAllPhases: true
    });
  };

  handleDragEnd = ({
    draggableId,
    destination,
    source: { index: sourceIndex }
  }) => {
    const { selectedProject, phasesByProject, updateProjectModules } =
      this.props;

    const phaseInfo = phasesByProject[selectedProject?.id] || {};
    const { manualActivePhasesOrder = [], manualArchivedPhasesOrder = [] } =
      phaseInfo;
    if (!destination || draggableId === 1) {
      return;
    }
    const newOrder = reorder(
      manualActivePhasesOrder,
      sourceIndex,
      destination.index
    );
    const newOrderIndex = newOrder.indexOf(+draggableId);
    const moveBehindPhaseId =
      newOrderIndex > 0 ? newOrder[newOrderIndex - 1] : null;
    updateProjectModules({
      projectId: selectedProject.id,
      movePhaseId: +draggableId,
      moveBehindPhaseId,
      phaseOrder: [...newOrder, ...manualArchivedPhasesOrder]
    });
  };

  renderRow = ({ row, column }) => {
    const { selectedProject, phasesByProject, openPhaseModal } = this.props;
    const phaseInfo = phasesByProject[selectedProject?.id] || {};
    const { phases = [] } = phaseInfo;
    const { phase } = row.original;
    return (
      <MilestoneTableCell
        key={`phase ${phase?.id}`}
        milestonesLength={phases.length}
        index={row.index}
        phase={phase}
        project={selectedProject}
        clearAdding={this.clearAdding}
        selectedProject={selectedProject}
        openPhaseModal={openPhaseModal}
        canDelete={this.checkDeletePermission()}
        column={column}
        row={row}
      />
    );
  };

  setIsShowingArchived = () => this.setState({ isShowingArchived: true });

  getIsSortedOrder = () => {
    const { projectBudgetSettings } = this.props;
    const phaseDefaultSort = projectBudgetSettings?.phase_default_sort;
    return (
      phaseDefaultSort &&
      phaseDefaultSort.length &&
      phaseDefaultSort[0].attribute !== SORT_BY.sort_rank
    );
  };

  render() {
    const { isShowingArchived } = this.state;
    const {
      isOpen,
      toggle,
      selectedProject,
      phasesByProject,
      handleOpenHardDeletePhaseModal,
      HardDeletePhaseModal
    } = this.props;
    const phaseInfo = phasesByProject[selectedProject?.id] || {};
    const {
      allPhases = [],
      manualActivePhases = [],
      manualArchivedPhases = []
    } = phaseInfo;

    const [activePhases, archivedPhases] = partition(
      allPhases,
      (phase) => !phase.archived
    );
    const numArchivedPhases = archivedPhases.length;

    const isSortedOrder = this.getIsSortedOrder();

    // if not sorted order, we must use phase order (manual phases)
    const orderedPhases = isSortedOrder
      ? [...activePhases, ...(isShowingArchived ? archivedPhases : [])]
      : [
          ...manualActivePhases,
          ...(isShowingArchived ? manualArchivedPhases : [])
        ];

    return (
      <MilestoneModalContextProvider>
        <Modal isOpen={isOpen} toggle={toggle} className="milestone-modal">
          <div className="milestone-modal-content">
            <div className="modal-header">
              <div className="modal-header-container">
                <div className="title-description-container">
                  {selectedProject ? (
                    <div className="project-title milestone-project-title">
                      <StyledDot projectId={selectedProject?.id}></StyledDot>
                      <StyledColorContainer onClick={this.onColorPickerClick}>
                        <ColorPicker
                          projectId={selectedProject?.id}
                          id={selectedProject?.id}
                          originType={ORIGIN_TYPE_STRINGS.PROJECT}
                          boundariesElement="window"
                          pickerLocation={'milestone-project-title'}
                          row
                          className="color-picker-container"
                        />
                      </StyledColorContainer>
                      <EllipsisText maxWidth={400} style={{ paddingLeft: 8 }}>
                        {selectedProject?.title}
                      </EllipsisText>
                      <IconContainer
                        onClick={() => {
                          openEditProjectModal(selectedProject.id);
                        }}
                      >
                        <ProjectInfoIcon height={16} width={16} />
                      </IconContainer>
                    </div>
                  ) : (
                    <div style={{ marginTop: '10px', marginBottom: '-20px' }}>
                      <TitleAndDescriptionLoader height={60} />
                    </div>
                  )}
                  <EllipsisText width={400}>
                    <div
                      style={{
                        marginTop: '-4px',
                        marginBottom: '10px',
                        color: '#828282'
                      }}
                    >
                      {selectedProject?.description}
                    </div>
                  </EllipsisText>
                  <AddPhaseAndMilestoneButton />
                </div>
                <LeftSideContainer>
                  <button
                    onClick={toggle}
                    className={`milestone-modal-done-button`}
                  >
                    Done
                  </button>
                </LeftSideContainer>
              </div>

              <TableHeader></TableHeader>
            </div>
            <div className="project-milestones-modal">
              <MilestoneTable
                Cell={this.renderRow}
                orderedPhases={orderedPhases}
                handleDragEnd={this.handleDragEnd}
                isShowingArchived={isShowingArchived}
                numArchivedPhases={numArchivedPhases}
                setIsShowingArchived={this.setIsShowingArchived}
                maxHeight={selectedProject?.description ? 506 : 527}
                handleOpenHardDeletePhaseModal={handleOpenHardDeletePhaseModal}
                project={selectedProject}
              />
              <HardDeletePhaseModal />
            </div>
          </div>
          <AdminTooltip />
          <PhaseModal />
          <PhaseTemplateModal />
          <GlobalPhaseTemplateDropdown />
        </Modal>
      </MilestoneModalContextProvider>
    );
  }
}

MilestoneModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const selectedProject = getSelectedProject(state);
  const milestoneModalProject = getMilestoneModalProject(state);
  const projectToUse = milestoneModalProject || selectedProject;

  return {
    selectedProject: projectToUse,
    userIsAdmin: getUserIsAdmin(state),
    phasesByProject: getActivePhasesAndMilestonesByProject(state),
    userPermissions: getUserPermissions(state),
    selectedTeamId: getSelectedTeamId(state),
    phaseAndMilestonesHash: getFlatPhasesAndMilestonesHash(state),
    phaseHash: getFlatPhasesHash(state),
    projectBudgetSettings: getProjectBudgetSettings(state)
  };
};

const mapDispatchToProps = {
  setEditMilestoneId,
  openPhaseModal,
  updateProjectModules,
  deletePhase,
  updatePhase,
  fetchUserActivitiesForActionable,
  openEditProjectModal,
  fetchProjectBudgetViewSettings
};

export default withRouter(
  withPermissionsCheck(
    withHardDeletePhaseModal(
      connect(mapStateToProps, mapDispatchToProps)(MilestoneModal)
    )
  )
);
