'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require('create-react-class');

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _DateConstants = require('./DateConstants');

var _DateConstants2 = _interopRequireDefault(_DateConstants);

var _util = require('../util/');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function isSameDay(one, two) {
  return one && two && one.isSame(two, 'day');
}

function isLastDayOfMonth(current) {
  if (current) {
    return current.clone().add(1, 'day').month() !== current.month();
  }
}

function isFirstDayOfMonth(current) {
  if (current) {
    return current.clone().add(-1, 'day').month() !== current.month();
  }
}

function beforeCurrentMonthYear(current, today) {
  if (current.year() < today.year()) {
    return 1;
  }
  return current.year() === today.year() && current.month() < today.month();
}

function afterCurrentMonthYear(current, today) {
  if (current.year() > today.year()) {
    return 1;
  }
  return current.year() === today.year() && current.month() > today.month();
}

function getIdFromDate(date) {
  return 'rc-calendar-' + date.year() + '-' + date.month() + '-' + date.date();
}

var DateTBody = (0, _createReactClass2['default'])({
  displayName: 'DateTBody',

  propTypes: {
    contentRender: _propTypes2['default'].func,
    dateRender: _propTypes2['default'].func,
    disabledDate: _propTypes2['default'].func,
    prefixCls: _propTypes2['default'].string,
    selectedValue: _propTypes2['default'].oneOfType([
      _propTypes2['default'].object,
      _propTypes2['default'].arrayOf(_propTypes2['default'].object)
    ]),
    value: _propTypes2['default'].object,
    hoverValue: _propTypes2['default'].any,
    showWeekNumber: _propTypes2['default'].bool
  },

  getDefaultProps: function getDefaultProps() {
    return {
      hoverValue: []
    };
  },
  render: function render() {
    var props = this.props;
    var contentRender = props.contentRender,
      prefixCls = props.prefixCls,
      selectedValue = props.selectedValue,
      value = props.value,
      showWeekNumber = props.showWeekNumber,
      dateRender = props.dateRender,
      disabledDate = props.disabledDate,
      hoverValue = props.hoverValue;

    var iIndex = void 0;
    var jIndex = void 0;
    var current = void 0;
    var dateTable = [];
    var today = (0, _util.getTodayTime)(value);
    var cellClass = prefixCls + '-cell';
    var weekNumberCellClass = prefixCls + '-week-number-cell';
    var dateClass = prefixCls + '-date';
    var todayClass = prefixCls + '-today';
    var selectedClass = prefixCls + '-selected-day';
    var selectedDateClass = prefixCls + '-selected-date'; // do not move with mouse operation
    var selectedStartDateClass = prefixCls + '-selected-start-date';
    var selectedEndDateClass = prefixCls + '-selected-end-date';
    var inRangeClass = prefixCls + '-in-range-cell';
    var lastMonthDayClass = prefixCls + '-last-month-cell';
    var nextMonthDayClass = prefixCls + '-next-month-btn-day';
    var disabledClass = prefixCls + '-disabled-cell';
    var firstDisableClass = prefixCls + '-disabled-cell-first-of-row';
    var lastDisableClass = prefixCls + '-disabled-cell-last-of-row';
    var month1 = value.clone();
    month1.date(1);
    var day = month1.day();
    var lastMonthDiffDay = (day + 7 - value.localeData().firstDayOfWeek()) % 7;
    // calculate last month
    var lastMonth1 = month1.clone();
    lastMonth1.add(0 - lastMonthDiffDay, 'days');
    var passed = 0;
    for (
      iIndex = 0;
      iIndex < _DateConstants2['default'].DATE_ROW_COUNT;
      iIndex++
    ) {
      for (
        jIndex = 0;
        jIndex < _DateConstants2['default'].DATE_COL_COUNT;
        jIndex++
      ) {
        current = lastMonth1;
        if (passed) {
          current = current.clone();
          current.add(passed, 'days');
        }
        dateTable.push(current);
        passed++;
      }
    }
    var tableHtml = [];
    passed = 0;

    for (
      iIndex = 0;
      iIndex < _DateConstants2['default'].DATE_ROW_COUNT;
      iIndex++
    ) {
      var _cx;

      var isCurrentWeek = void 0;
      var weekNumberCell = void 0;
      var isActiveWeek = false;
      var dateCells = [];
      if (showWeekNumber) {
        weekNumberCell = _react2['default'].createElement(
          'td',
          {
            key: dateTable[passed].week(),
            role: 'gridcell',
            className: weekNumberCellClass
          },
          dateTable[passed].week()
        );
      }
      for (
        jIndex = 0;
        jIndex < _DateConstants2['default'].DATE_COL_COUNT;
        jIndex++
      ) {
        var next = null;
        var last = null;
        current = dateTable[passed];
        if (jIndex < _DateConstants2['default'].DATE_COL_COUNT - 1) {
          next = dateTable[passed + 1];
        }
        if (jIndex > 0) {
          last = dateTable[passed - 1];
        }
        var cls = cellClass;
        var disabled = false;
        var selected = false;

        if (isSameDay(current, today)) {
          cls += ' ' + todayClass;
          isCurrentWeek = true;
        }

        var isBeforeCurrentMonthYear = beforeCurrentMonthYear(current, value);
        var isAfterCurrentMonthYear = afterCurrentMonthYear(current, value);

        if (selectedValue && Array.isArray(selectedValue)) {
          var rangeValue = hoverValue.length ? hoverValue : selectedValue;
          if (!isBeforeCurrentMonthYear && !isAfterCurrentMonthYear) {
            var startValue = rangeValue[0];
            var endValue = rangeValue[1];
            if (startValue) {
              if (isSameDay(current, startValue)) {
                selected = true;
                isActiveWeek = true;
                cls += ' ' + selectedStartDateClass;
              }
            }
            if (startValue && endValue) {
              if (isSameDay(current, endValue)) {
                selected = true;
                isActiveWeek = true;
                cls += ' ' + selectedEndDateClass;
              } else if (
                current.isAfter(startValue, 'day') &&
                current.isBefore(endValue, 'day')
              ) {
                cls += ' ' + inRangeClass;
              }
            }
          }
        } else if (isSameDay(current, value)) {
          // keyboard change value, highlight works
          selected = true;
          isActiveWeek = true;
        }
        if (isLastDayOfMonth(current)) {
          cls += ' last-day-of-month ';
        } else if (isFirstDayOfMonth(current)) {
          cls += ' first-day-of-month';
        }

        if (isSameDay(current, selectedValue)) {
          cls += ' ' + selectedDateClass;
        }

        if (isBeforeCurrentMonthYear) {
          cls += ' ' + lastMonthDayClass;
        }
        if (isAfterCurrentMonthYear) {
          cls += ' ' + nextMonthDayClass;
        }

        if (disabledDate) {
          if (disabledDate(current, value)) {
            disabled = true;

            if (!last || !disabledDate(last, value)) {
              cls += ' ' + firstDisableClass;
            }

            if (!next || !disabledDate(next, value)) {
              cls += ' ' + lastDisableClass;
            }
          }
        }

        if (selected) {
          cls += ' ' + selectedClass;
        }

        if (disabled) {
          cls += ' ' + disabledClass;
        }

        var dateHtml = void 0;
        if (dateRender) {
          dateHtml = dateRender(current, value);
        } else {
          var content = contentRender
            ? contentRender(current, value)
            : current.date();
          dateHtml = _react2['default'].createElement(
            'div',
            {
              key: getIdFromDate(current),
              className: dateClass,
              'aria-selected': selected,
              'aria-disabled': disabled
            },
            content
          );
        }

        dateCells.push(
          _react2['default'].createElement(
            'td',
            {
              key: passed,
              onClick: disabled
                ? undefined
                : props.onSelect.bind(null, current),
              onMouseEnter: disabled
                ? undefined
                : (props.onDayHover && props.onDayHover.bind(null, current)) ||
                  undefined,
              role: 'gridcell',
              title: (0, _util.getTitleString)(current),
              className: cls
            },
            dateHtml
          )
        );

        passed++;
      }

      tableHtml.push(
        _react2['default'].createElement(
          'tr',
          {
            key: iIndex,
            role: 'row',
            className: (0, _classnames2['default'])(
              ((_cx = {}),
              (_cx[prefixCls + '-current-week'] = isCurrentWeek),
              (_cx[prefixCls + '-active-week'] = isActiveWeek),
              _cx)
            )
          },
          weekNumberCell,
          dateCells
        )
      );
    }
    return _react2['default'].createElement(
      'tbody',
      { className: prefixCls + '-tbody' },
      tableHtml
    );
  }
});

exports['default'] = DateTBody;
module.exports = exports['default'];
