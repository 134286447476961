/**
 * Width of a scrollbar on an element with the `scrollbar` class.
 *
 * This initial value matches the value of `--scrollbar-width` in the
 * `scrollbar` class definition.
 */
export let SCROLLBAR_WIDTH = 12;

// Detect the correct scrollbar width by creating a temporary element and
// measuring the width of the scrollbar. This event listener is set here because
// it is the initialization function for the above "constant" that is
// properly initialized on page load.
window.addEventListener(
  'load',
  () => {
    // Add a temporary scrolling element that is off the screen.
    const scroller = document.createElement('div');
    scroller.style.left = '-1000px';
    scroller.style.overflow = 'scroll';
    scroller.style.position = 'absolute';
    scroller.style.width = '100px';
    scroller.classList.add('scrollbar');
    document.body.appendChild(scroller);

    // Measure the width of the scroll bar.
    SCROLLBAR_WIDTH = scroller.offsetWidth - scroller.clientWidth;

    // Remove the temporary element.
    document.body.removeChild(scroller);
  },
  { once: true }
);
