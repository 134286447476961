import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { getSelectedTeamId } from 'selectors';
import { RolesTable } from './RolesTable';
import {
  fetchPositions,
  fetchAccountPositions
} from 'BudgetModule/actionCreators';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';

const RolesTableContainerInner = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchPositions({ teamId }));
      dispatch(fetchAccountPositions({ teamId }));
    }
  }, [dispatch, teamId]);

  return <RolesTable />;
};

export const RolesTableContainer = () => {
  return (
    <DynamicModuleLoader modules={[getBudgetModule()]}>
      <RolesTableContainerInner />
    </DynamicModuleLoader>
  );
};
