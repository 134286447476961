import React, {
  useEffect,
  CSSProperties,
  ReactNode,
  useRef,
  useState,
  MouseEvent
} from 'react';
import { Placement } from 'popper.js';
import styled from 'styled-components';
import theme from 'theme';
import { ThreeDotMenuPopover } from './ThreeDotMenuPopover';
import { rebuildTooltip, hideTooltip } from 'appUtils/tooltipUtils';

export const ThreeDotMenuContainer = styled.div<{
  show: boolean;
  slide: boolean;
  color?: string;
}>`
  display: ${({ show, slide }) => (show || !slide ? 'flex' : '')};
  width: ${({ show, slide }) => (show || !slide ? '28px' : '0px')};
  opacity: ${({ show, slide }) => (show || !slide ? '1' : '0')};
  visibility: ${({ show, slide }) => (show || !slide ? 'visible' : 'hidden')};
  margin-right: ${({ show, slide }) => (show || !slide ? '0' : '10px')};
  transition: 0.275s ease-in-out;
  svg {
    path {
      fill: ${({ color }) => color || theme.colors.colorLightGray11};
      stroke: ${({ color }) => color || theme.colors.colorLightGray11};
    }
  }
  &:hover {
    svg {
      path {
        fill: ${theme.colors.colorRoyalBlue};
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

interface ThreeDotMenuProps {
  vertical?: boolean;
  slide?: boolean;
  children: ReactNode;
  style?: CSSProperties;
  color?: string;
  className?: string;
  placement?: Placement;
  overrideIsOpen?: boolean;
  overrideSetIsOpen?: (isOpen: boolean) => void;
  size?: number;
  customOnClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const ThreeDotMenu = ({
  vertical = true,
  slide = true,
  children,
  style,
  color,
  className,
  placement,
  overrideIsOpen,
  overrideSetIsOpen,
  size,
  customOnClick
}: ThreeDotMenuProps) => {
  const [localIsOpen, localSetIsOpen] = useState(false);
  const isOpen = overrideIsOpen === undefined ? localIsOpen : overrideIsOpen;
  const setIsOpen =
    overrideSetIsOpen === undefined ? localSetIsOpen : overrideSetIsOpen;

  const popoverTarget = useRef(null);
  const toggleOpen = (e) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
  };
  const closeMenu = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      rebuildTooltip();
    } else {
      hideTooltip();
    }
  }, [isOpen]);

  return (
    <ThreeDotMenuContainer
      onClick={customOnClick || toggleOpen}
      ref={popoverTarget}
      show={isOpen}
      slide={slide}
      style={style}
      color={color}
      className={className}
    >
      <ThreeDotMenuPopover
        popoverTarget={popoverTarget}
        onClose={closeMenu}
        isOpen={isOpen}
        vertical={vertical}
        placement={placement}
        size={size}
      >
        <div onClick={closeMenu}>{children}</div>
      </ThreeDotMenuPopover>
    </ThreeDotMenuContainer>
  );
};
