import { Draft, createReducer, PayloadAction } from '@reduxjs/toolkit';
import * as constants from 'EntityOptionsModule/constants';
import { EntityOptionsState } from 'EntityOptionsModule/types';
import {
  fetchEntityOptionsActionCreatorsMap,
  clearEntityOptions
} from 'EntityOptionsModule/actionCreators';
import { serializeId } from 'appUtils';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { ValueOf } from 'type-fest';

export const initialState: EntityOptionsState = {
  /**
   *  entityOptionsByEntiyTypeHash: {
   *    [entityType]: {
   *      [entityId]: EntityOption
   *    }
   *  }
   */
  entityOptionsByEntityTypeHash: {},
  /**
   * ids are added on trigger only when all: true present, scope_request_ids param length < limit,
   * or on success of any fetch calls. will not always match scopeRequestHash
   */
  fetchedEntityOptionSerializeIds: {}
};

export const entityOptionSerializeId = ({
  entityId,
  entityType
}: {
  entityId: Nullable<number>;
  entityType: ValueOf<typeof ENTITY_TYPES>;
}): string =>
  serializeId({
    id: [entityId],
    ids: undefined,
    itemType: entityType
  });

const handleFetchEntityOptionsSuccess = (
  state: Draft<EntityOptionsState>,
  action: ReturnType<typeof fetchEntityOptionsActionCreatorsMap.success>
) => {
  const { response, initialPayload } = action.payload;

  const entityId = response.entity_id;
  const desiredEntityId = initialPayload.entity_id;
  const entityType = response.entity_type;
  const serializeId = entityOptionSerializeId({
    entityId: desiredEntityId,
    entityType
  });

  state.entityOptionsByEntityTypeHash[entityType] = {
    ...state.entityOptionsByEntityTypeHash[entityType],
    ...(entityId
      ? { [entityId]: response.column_options }
      : { default: response.column_options })
  };

  state.fetchedEntityOptionSerializeIds[serializeId] = true;
};

const handleClearEntityOptions = (
  state: Draft<EntityOptionsState>,
  action: ReturnType<typeof clearEntityOptions>
) => {
  const { entity_id, entity_type } = action.payload;
  const serializeId = entityOptionSerializeId({
    entityId: entity_id,
    entityType: entity_type
  });

  delete state.fetchedEntityOptionSerializeIds[serializeId];
  delete state.fetchedEntityOptionSerializeIds[entity_type]?.[entity_id];
};

const entityOptions = createReducer(initialState, (builder) => {
  builder.addCase(
    constants.FETCH_ENTITY_OPTIONS.SUCCESS,
    handleFetchEntityOptionsSuccess
  );
  builder.addCase(clearEntityOptions, handleClearEntityOptions);
});

export default entityOptions;
