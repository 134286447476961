import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { DATA_KEY } from 'UtilizationModule/constants';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import {
  BillableSection,
  NonbillableSection,
  HolidaySection,
  PtoSection,
  GraphCellContainer,
  PercentCell
} from './styles';

const Bar = styled.div`
  height: 20px;
  display: flex;
  width: 100%;
`;

const sectionComponent = {
  [DATA_KEY.BILLABLE]: BillableSection,
  [DATA_KEY.NONBILLABLE]: NonbillableSection,
  [DATA_KEY.PTO]: PtoSection,
  [DATA_KEY.HOLIDAY]: HolidaySection
};

const SubRowGraphCell = ({ row }) => {
  const {
    projectId, // present when projectRow
    data,
    dataKey,
    customRowProps: { showUtilizationByProjects, projectColors }
  } = row.original;
  const { totalPercent } = data;
  const Section = sectionComponent[dataKey];

  return (
    <GraphCellContainer>
      <Bar className="bar">
        <Section
          width={totalPercent}
          color={
            showUtilizationByProjects
              ? projectColors[projectId] || theme.colors.colorLightGray6
              : undefined
          }
          isRightmost
        />
        {totalPercent > 0 && (
          <PercentCell>{`${formatNumWithMaxOneDecimal(
            totalPercent
          )}%`}</PercentCell>
        )}
      </Bar>
    </GraphCellContainer>
  );
};
export default SubRowGraphCell;
