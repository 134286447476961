import { ComponentProps, ComponentType } from 'react';
import { useProjectRates } from './useProjectRates';

interface WithProjectRatesProps {
  projectRates: ReturnType<typeof useProjectRates>;
}

export const withProjectRates = <P extends WithProjectRatesProps>(
  WrappedComponent: ComponentType<P>
) => {
  const Hoc = (
    props: Omit<
      ComponentProps<typeof WrappedComponent>,
      keyof WithProjectRatesProps
    >
  ) => {
    const projectRates = useProjectRates();

    return (
      <WrappedComponent
        {...(props as unknown as P)}
        projectRates={projectRates}
      />
    );
  };

  Hoc.displayName = 'withProjectRates';

  return Hoc;
};
