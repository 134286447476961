import { initialState } from 'reducers/workGroups';
import { createSelector } from 'reselect';

const emptyObj = {};
const emptyArray = [];

const getWorkGroupsState = (state) => state.workGroups || initialState;

export const getIsWorkGroupsLoaded = createSelector(
  getWorkGroupsState,
  (state) => state.loaded
);

export const getIsLoadingWorkGroups = createSelector(
  getWorkGroupsState,
  (state) => state.isLoading
);

export const getWorkGroupsOrder = createSelector(
  getWorkGroupsState,
  (state) => state.workGroupsOrder
);

export const getWorkGroupsCount = createSelector(
  getWorkGroupsOrder,
  (workGroups) => workGroups.length
);

export const getWorkGroups = createSelector(
  getWorkGroupsState,
  (state) => state.workGroups
);
export const getNewWorkGroupId = createSelector(
  getWorkGroupsState,
  (state) => state.newGroupId
);

export const getUnorderedWorkGroupsArray = createSelector(
  getWorkGroupsOrder,
  getWorkGroups,
  (ids = [], workGroups = {}) => ids.map((id) => workGroups[id])
);
export const getWorkGroupsArray = createSelector(
  getUnorderedWorkGroupsArray,
  getNewWorkGroupId,

  (workGroups, newWorkGroupId) =>
    workGroups.slice().sort((a, b) => {
      // ensure newly created is at top of list even though it has a provided name under the hood.
      if (a.id === newWorkGroupId) {
        return -1;
      }
      if (b.id === newWorkGroupId) {
        return 1;
      }
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    })
);

export const getSortedWorkGroupIdsArray = createSelector(
  getWorkGroupsArray,
  (workGroups) => workGroups.map((workGroup) => workGroup.id)
);

export const getWorkGroupMemberships = createSelector(
  getWorkGroupsState,
  (state) => state.workGroupMemberships
);

export const getWorkGroupsWithMembers = createSelector(
  getWorkGroupsArray,
  getWorkGroupMemberships,
  (workGroups, workGroupMemberships) =>
    workGroups.map((workGroup) => ({
      ...workGroup,
      work_group_memberships: workGroupMemberships[workGroup.id] || []
    }))
);
