import SvgIcon from 'components/SvgIcon';

const PaperIcon = ({
  className = '',
  color = '#4F4F4F',
  fill = 'none',
  width = 11,
  height = 13,
  style = {}
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 11 13"
    style={style}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.856934 12.381H10.1426V3.32738L6.81527 0H0.856934V12.381ZM7.04741 1.31548L8.82717 3.09524H7.04741V1.31548ZM1.63074 0.773809H6.2736V3.86905H9.36884V11.6071H1.63074V0.773809Z"
        fill={color}
      />
      <path d="M5.11293 2.70801H2.7915V3.48182H5.11293V2.70801Z" fill={color} />
      <path d="M7.43436 5.0293H2.7915V5.80311H7.43436V5.0293Z" fill={color} />
      <path d="M8.20817 6.57715H2.7915V7.35096H8.20817V6.57715Z" fill={color} />
      <path d="M8.20817 8.125H2.7915V8.89881H8.20817V8.125Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="9.28571"
          height="12.381"
          fill="white"
          transform="translate(0.856934)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PaperIcon;
