import 'isomorphic-fetch';
import qs from 'qs';
import { callApi, callApiV2 } from 'appUtils/api';

export const fetchNotesByProject = function (
  id,
  token,
  projectId,
  limit,
  offset,
  searchText,
  accountIds,
  tagIds
) {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    search_text: searchText || undefined,
    'account_ids[]': accountIds ? accountIds.join() : accountIds,
    'tag_ids[]': tagIds,
    limit: limit || 15,
    offset: offset || 0,
    all: searchText || accountIds ? true : undefined
  };

  const apiEndpoint =
    `project/${projectId}/notes/?` + qs.stringify(queryParams);
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchNote = (id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`notes/${id}`, token, fetchInit);
};

export const deleteProjectNote = (token, noteId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ note_id: noteId })
  };
  return callApi(`notes/${noteId}`, token, fetchInit);
};

export const postProjectNote = (
  token,
  accountId,
  title,
  body,
  projectId,
  plainTextBody
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      account_id: accountId,
      title,
      body,
      plain_text_body: plainTextBody
    })
  };
  return callApi(`project/${projectId}/notes`, token, fetchInit);
};

export const putProjectNote = (
  token,
  noteId,
  title,
  body,
  position,
  plainTextBody
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      title,
      body,
      position_change: {
        position
      },
      plain_text_body: plainTextBody
    })
  };
  return callApi(`notes/${noteId}`, token, fetchInit);
};

export const fetchProjectNotesV2 = function (
  id,
  token,
  projectIds,
  limit,
  offset,
  searchText,
  accountIds,
  tagIds
) {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    search_text: searchText || undefined,
    project_ids: projectIds,
    creator_ids: accountIds,
    note_tag_ids: tagIds,
    limit: limit || 15,
    offset: offset || 0,
    all: searchText || accountIds ? true : undefined
  };

  const apiEndpoint = `project_notes/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApiV2(apiEndpoint, token, fetchInit);
};
