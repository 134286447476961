/* eslint-disable react/display-name */
import { BlockingIcon } from 'icons/BlockingIcon';
import { MatchesIcon } from 'icons/MatchesIcon';
import { WaitingIcon } from 'icons/WaitingIcon';
import styled from 'styled-components';
import theme from 'theme';
import { DependencyTagItemContent } from './DependencyTag';
import { DeltaDaysInput } from './DeltaDaysInput';
import {
  DependencyItem,
  DependableItem,
  HandleDependencyTagClick,
  HandleChangeDeltaDays,
  DependencyTagContent,
  MultiLevelDropdownContentRenderItem,
  MultiLevelDropdownContentSubMenu,
  MultiLevelDropdownContentDisabled,
  MultiLevelDropdownContentTooltip,
  HandleClickTargetMenu,
  TargetDependableItemRenderer,
  ParentChildKeyMap,
  CommonDependencyItem
} from '../types';
import { BaseTypeMenuRenderer } from './MenuRenderers/BaseTypeMenuRenderer';
import { ActionMenuRenderer } from './MenuRenderers/ActionMenuRenderer';
import { TargetTypeMenuRenderer } from './MenuRenderers/TargetTypeMenuRenderer';
import { MenuContent } from 'components/MultiLevelDropdown';
import { DeltaTypeRenderer } from './MenuRenderers/DeltaTypeMenuRenderer';
import {
  dependencyAction,
  dependencyType,
  dependencyActionStrings,
  dependencyTypeStrings,
  dependencyDeltaType,
  dependencyDeltaTypeStrings,
  dependencyTagKey,
  dependencyAttributeMap,
  dependencyItemType
} from '../constants';
import { DependencyInfoBEArrayItem } from 'models/dependency';

const actionIconMap = {
  [dependencyAction.blocking]: BlockingIcon,
  [dependencyAction.waiting]: WaitingIcon,
  [dependencyAction.matches]: MatchesIcon
};

export const getDefaultDependencyTagString = ({
  availableOptions
}: {
  availableOptions: string[];
}) => availableOptions.join('/');

export const getMultiOptionDependencyTagsContent = ({
  dependency,
  dependableItemsById,
  defaultDependableItemTypesString,
  defaultBaseTypeString,
  onClickMap,
  onChangeMap
}: {
  dependency: DependencyItem;
  dependableItemsById: Record<number, DependableItem>;
  defaultDependableItemTypesString: string;
  defaultBaseTypeString: string;
  onClickMap: {
    handleBaseDependencyTagClick: HandleDependencyTagClick;
    handleTargetDependencyTagClick: HandleDependencyTagClick;
    handleDeltaTypeDependencyTagClick: HandleDependencyTagClick;
    handleDeltaDayDependencyTagClick: HandleDependencyTagClick;
  };
  onChangeMap: {
    handleChangeDeltaDays: HandleChangeDeltaDays;
  };
}): DependencyTagContent[] => {
  const {
    baseType,
    action,
    targetItemId,
    targetType,
    deltaType,
    deltaDays,
    dependencyId
  } = dependency;

  const ActionIcon = action ? actionIconMap[action] : undefined;

  const {
    handleBaseDependencyTagClick,
    handleTargetDependencyTagClick,
    handleDeltaTypeDependencyTagClick,
    handleDeltaDayDependencyTagClick
  } = onClickMap;

  const { handleChangeDeltaDays } = onChangeMap;

  const { name: targetItemName = null } = targetItemId
    ? dependableItemsById[targetItemId] ?? {}
    : {};

  const baseTagSet = baseType && action;
  const targetTagSet = targetType && targetItemId;
  const deltaTypeTagSet = !!deltaType;

  const showDeltaTags = baseTagSet && targetTagSet;

  return [
    {
      key: dependencyTagKey.base,
      dependencyId,
      content: (
        <>
          <IconContainer>
            {ActionIcon && (
              <ActionIcon fill={theme.colors.colorCalendarBlue} width={11} />
            )}
          </IconContainer>
          <DependencyTagItemContent>
            {baseTagSet
              ? `${dependencyTypeStrings[dependencyType[baseType]]} Date ${
                  dependencyActionStrings[dependencyAction[action]]
                }`
              : `Select ${defaultBaseTypeString}`}
          </DependencyTagItemContent>
        </>
      ),
      onClick: handleBaseDependencyTagClick
    },
    {
      key: dependencyTagKey.target,
      dependencyId,
      content: (
        <DependencyTagItemContent>
          {targetTagSet
            ? `${targetItemName} ${
                dependencyTypeStrings[dependencyType[targetType]]
              }`
            : `Select ${defaultDependableItemTypesString}`}
        </DependencyTagItemContent>
      ),
      onClick: handleTargetDependencyTagClick
    },
    ...(showDeltaTags
      ? [
          {
            key: dependencyTagKey.deltaType,
            dependencyId,
            content: (
              <DependencyTagItemContent>
                {deltaTypeTagSet
                  ? `By ${
                      dependencyDeltaTypeStrings[dependencyDeltaType[deltaType]]
                    }`
                  : 'Select Delta Type'}
              </DependencyTagItemContent>
            ),
            onClick: handleDeltaTypeDependencyTagClick
          },
          {
            key: dependencyTagKey.deltaDays,
            dependencyId,
            content: (
              <DeltaDaysInput
                onChange={handleChangeDeltaDays}
                value={deltaDays}
              />
            ),
            onClick: handleDeltaDayDependencyTagClick
          }
        ]
      : [])
  ];
};

export const getMultiLevelDropdownContent = <ValueType extends string>({
  availableOptions,
  selectedOption,
  getRenderItem,
  getSubMenu,
  getIsDisabled,
  getTooltip,
  parentValue
}: {
  availableOptions: ValueType[];
  selectedOption: ValueType | undefined;
  getRenderItem: MultiLevelDropdownContentRenderItem<ValueType>;
  getSubMenu?: MultiLevelDropdownContentSubMenu<ValueType>;
  getIsDisabled?: MultiLevelDropdownContentDisabled<ValueType>;
  getTooltip?: MultiLevelDropdownContentTooltip<ValueType>;
  parentValue?: string;
}): MenuContent => {
  return {
    items: availableOptions.map((availableOption) => {
      const selected = selectedOption
        ? availableOption === selectedOption
        : false;

      const isDisabled = getIsDisabled
        ? getIsDisabled({ value: availableOption })
        : false;

      return {
        name: parentValue ? parentValue + availableOption : availableOption,
        renderItem: getRenderItem({
          value: availableOption,
          selected,
          disabled: isDisabled
        }),
        subMenu:
          getSubMenu && !isDisabled
            ? getSubMenu({
                parentValue: availableOption
              })
            : undefined,
        isDisabled,
        menuItemTooltip: getTooltip
          ? getTooltip({ value: availableOption })
          : undefined
      };
    })
  };
};

export const getDependencyBaseMenuContent = ({
  availableBaseTypeOptions,
  availableActionOptions,
  selectedBaseTypeOption,
  selectedActionOption
}: {
  availableBaseTypeOptions: NonNullable<CommonDependencyItem['baseType']>[];
  availableActionOptions: NonNullable<CommonDependencyItem['action']>[];
  selectedBaseTypeOption: NonNullable<CommonDependencyItem['baseType']>;
  selectedActionOption: NonNullable<CommonDependencyItem['action']>;
}) => {
  const renderBaseTypeMenu: MultiLevelDropdownContentRenderItem<
    NonNullable<CommonDependencyItem['baseType']>
  > =
    ({ value, selected }) =>
    () =>
      <BaseTypeMenuRenderer baseType={value} selected={selected} />;

  const renderActionMenu: MultiLevelDropdownContentRenderItem<
    NonNullable<CommonDependencyItem['action']>
  > =
    ({ value, selected }) =>
    () =>
      <ActionMenuRenderer actionType={value} selected={selected} />;

  const getBaseTypeMenuSubMenu = ({ parentValue }: { parentValue: string }) =>
    getMultiLevelDropdownContent({
      availableOptions: availableActionOptions,
      selectedOption:
        parentValue === selectedBaseTypeOption
          ? selectedActionOption
          : undefined,
      getRenderItem: renderActionMenu,
      getSubMenu: undefined,
      parentValue
    });

  const baseMenuContent = getMultiLevelDropdownContent({
    availableOptions: availableBaseTypeOptions,
    selectedOption: selectedBaseTypeOption,
    getRenderItem: renderBaseTypeMenu,
    getSubMenu: getBaseTypeMenuSubMenu
  });

  return baseMenuContent;
};

export const getDependencyTargetMenuContent = ({
  availableTargetItemOptions,
  availableTargetTypeOptions,
  selectedTargetItemId,
  selectedTargetTypeOption,
  targetDependableItemRenderer,
  handleClickTargetMenu
}: {
  availableTargetItemOptions: DependableItem[];
  availableTargetTypeOptions: NonNullable<CommonDependencyItem['targetType']>[];
  selectedTargetItemId: string;
  selectedTargetTypeOption: NonNullable<CommonDependencyItem['targetType']>;
  targetDependableItemRenderer: TargetDependableItemRenderer;
  handleClickTargetMenu: HandleClickTargetMenu;
}) => {
  const availableTargetItemOptionsById = availableTargetItemOptions.reduce(
    (acc, targetItem) => {
      acc[targetItem.id] = targetItem;

      return acc;
    },
    {}
  );

  const availableTargetItemOptionIds = Object.keys(
    availableTargetItemOptionsById
  );

  const getTargetItemDisabled = ({
    value: dependableId
  }: {
    value: string;
  }) => {
    const dependableItem = availableTargetItemOptionsById[dependableId];

    return !dependableItem.startDate;
  };

  const getTargetItemTooltip = ({ value: dependableId }: { value: string }) => {
    const dependableItem = availableTargetItemOptionsById[dependableId];

    return !dependableItem.startDate
      ? `${dependableItem.itemType} does not have dates`
      : undefined;
  };

  const getTargetTypeDisabled = ({
    value,
    dependableId
  }: {
    value: NonNullable<CommonDependencyItem['targetType']>;
    dependableId: string;
  }): boolean => {
    const dependableItem = availableTargetItemOptionsById[dependableId];

    const targetTypeDisabledMap = {
      [dependencyType.start]: !dependableItem.startDate,
      [dependencyType.end]: !dependableItem.endDate,
      [dependencyType.startEnd]:
        !dependableItem.startDate || !dependableItem.endDate
    };

    return targetTypeDisabledMap[value] ?? true;
  };

  const getTargetTypeTooltip = ({
    value,
    dependableId
  }: {
    value: NonNullable<CommonDependencyItem['targetType']>;
    dependableId: string;
  }): string | undefined => {
    const dependableItem = availableTargetItemOptionsById[dependableId];

    const targetTypeDisabledMap = {
      [dependencyType.start]: !dependableItem.startDate
        ? `${dependableItem.itemType} does not have start date`
        : undefined,
      [dependencyType.end]: !dependableItem.endDate
        ? `${dependableItem.itemType} does not have end date`
        : undefined,
      [dependencyType.startEnd]: !dependableItem.startDate
        ? `${dependableItem.itemType} does not have start date`
        : !dependableItem.endDate
        ? `${dependableItem.itemType} does not have end date`
        : undefined
    };

    return targetTypeDisabledMap[value];
  };

  const renderTargetItemMenu: MultiLevelDropdownContentRenderItem<string> =
    ({ value: dependableId, selected, disabled }) =>
    () => {
      const dependableItem = availableTargetItemOptionsById[dependableId];

      return targetDependableItemRenderer({
        dependableItem,
        disabled,
        selected
      });
    };

  const renderTargetTypeMenu =
    ({
      value,
      selected,
      disabled,
      dependableId
    }: Parameters<
      MultiLevelDropdownContentRenderItem<
        NonNullable<CommonDependencyItem['targetType']>
      >
    >[0] & { dependableId: string }) =>
    () => {
      const dependableItem = availableTargetItemOptionsById[dependableId];
      return (
        <TargetTypeMenuRenderer
          dependableItem={dependableItem}
          targetType={value}
          onClick={handleClickTargetMenu}
          selected={selected}
          disabled={disabled}
        />
      );
    };

  const getTargetTypeSubMenu = ({
    parentValue: dependableId
  }: {
    parentValue: string;
  }) =>
    getMultiLevelDropdownContent<
      NonNullable<CommonDependencyItem['targetType']>
    >({
      availableOptions: availableTargetTypeOptions,
      selectedOption:
        dependableId === selectedTargetItemId
          ? selectedTargetTypeOption
          : undefined,
      getRenderItem: ({ value, selected, disabled }) =>
        renderTargetTypeMenu({ value, selected, disabled, dependableId }),
      getIsDisabled: ({ value }) =>
        getTargetTypeDisabled({ value, dependableId }),
      getTooltip: ({ value }) => getTargetTypeTooltip({ value, dependableId }),
      getSubMenu: undefined
    });

  const targetMenuContent = getMultiLevelDropdownContent({
    availableOptions: availableTargetItemOptionIds,
    selectedOption: selectedTargetItemId,
    getRenderItem: renderTargetItemMenu,
    getIsDisabled: getTargetItemDisabled,
    getTooltip: getTargetItemTooltip,
    getSubMenu: getTargetTypeSubMenu
  });

  return targetMenuContent;
};

export const getDependencyDeltaTypeMenuContent = ({
  availableDeltaTypeOptions,
  selectedDeltaTypeOption
}: {
  availableDeltaTypeOptions: NonNullable<CommonDependencyItem['deltaType']>[];
  selectedDeltaTypeOption: NonNullable<CommonDependencyItem['deltaType']>;
}) => {
  const renderDeltaTypeMenu: MultiLevelDropdownContentRenderItem<
    NonNullable<CommonDependencyItem['deltaType']>
  > =
    ({ value, selected }) =>
    () =>
      <DeltaTypeRenderer deltaType={value} selected={selected} />;

  const deltaTypeMenuContent = getMultiLevelDropdownContent({
    availableOptions: availableDeltaTypeOptions,
    selectedOption: selectedDeltaTypeOption,
    getRenderItem: renderDeltaTypeMenu
  });

  return deltaTypeMenuContent;
};

/**
 * Map the dependencies array stored in entities to dependencies array used
 * by useDependencyFormState hook.
 */
export const mapEntityDependencies = ({
  entityDependencies,
  keyMap // Maps parent/child relationship to blocking/waiting
}: {
  entityDependencies: DependencyInfoBEArrayItem[];
  keyMap: ParentChildKeyMap;
}) => {
  return entityDependencies.map((dependency) => ({
    dependencyId: dependency.id.toString(),
    baseType: dependencyAttributeMap[dependency[keyMap.baseType]],
    targetType: dependencyAttributeMap[dependency[keyMap.targetType]],
    baseItemId: dependency[keyMap.baseItemId],
    targetItemId: dependency[keyMap.targetItemId],
    baseItemType:
      dependencyItemType[dependency[keyMap.baseItemType].toLowerCase()],
    targetItemType:
      dependencyItemType[dependency[keyMap.targetItemType].toLowerCase()],
    // Show Matches if child can update parent (when parent and child are equivalent)
    action: dependency.child_updates_parent
      ? dependencyAction.matches
      : dependencyAction[keyMap.action],
    // hard code to upTo until we know how to get atLeast from BE
    deltaType: dependencyDeltaType.upTo,
    deltaDays: dependency.date_delta
  }));
};

export const validateNewDependencies = ({
  dependencies
}: {
  dependencies: DependencyItem[];
}) => {
  const newDependencies = dependencies.filter((dependency) => dependency.isNew);

  return (
    newDependencies.filter(
      (newDependency) =>
        Object.values(newDependency).filter(
          (dependencyValue) => dependencyValue === null
        ).length !== 0
    ).length === 0
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
