import React from 'react';
import { StyledRowItemPOW } from './styles';
class PhasesOfWorkDropdownItem extends React.Component {
  render() {
    const { item } = this.props;
    return <StyledRowItemPOW>{item && item.name}</StyledRowItemPOW>;
  }
}

export default PhasesOfWorkDropdownItem;
