/* eslint-disable react/display-name */
import { getMultiLevelDropdownContent } from 'components/Dependency/DependencyV2/components/utils';
import { MultiLevelDropdownContentRenderItem } from 'components/Dependency/DependencyV2/types';
import useMultiLevelDropdown from 'components/MultiLevelDropdown/useMultiLevelDropdown';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { TimeEntrySettingOption } from './types';
import cn from 'classnames';
import KaratRight from 'icons/KaratRight';
import { labelsMap } from './constants';
import QBDownArrow from 'icons/QBDownArrow';

interface TimeEntrySettingSelectProps {
  value: TimeEntrySettingOption;
  onSelect: (value: TimeEntrySettingOption) => void;
  isIntegrationOptionsDisabled?: boolean;
}

export const TimeEntrySettingSelect = ({
  value,
  onSelect
}: TimeEntrySettingSelectProps) => {
  const { openDropdown, closeDropdown, MultiLevelDropdown, dropdownRef } =
    useMultiLevelDropdown();

  const menuContent = useMemo(() => {
    const getIsDisabled = ({ value }) => value === 'Integrations';

    const getSubMenuRenderItem: MultiLevelDropdownContentRenderItem<
      TimeEntrySettingOption
    > = ({ value, selected, disabled }) => {
      const handleOnSelect = (newTimeEntryValue: TimeEntrySettingOption) => {
        onSelect(newTimeEntryValue);
        closeDropdown();
      };
      return () => (
        <TimeEntryDropdownSubMenuItem
          className={cn({ selected, disabled })}
          onClick={() => handleOnSelect(value)}
        >
          {labelsMap[value]}
        </TimeEntryDropdownSubMenuItem>
      );
    };

    const getSubMenu = ({ parentValue }) =>
      // This is a utils func I build during dependencies v2 development. Used it here to save time.
      // It is generic enough to be moved to a utils file. Will do next week during full feature implementation.
      getMultiLevelDropdownContent({
        availableOptions: [
          TimeEntrySettingOption.ManualTimeEntry,
          TimeEntrySettingOption.PastPlannedTime
        ],
        selectedOption: value,
        getRenderItem: getSubMenuRenderItem
      });

    return getMultiLevelDropdownContent({
      // TODO: Create constants for top level options
      availableOptions: ['Mosaic Timesheets', 'Integrations'],
      selectedOption: 'Mosaic Timesheets',
      getRenderItem,
      getIsDisabled,
      getSubMenu
    });
  }, [closeDropdown, onSelect, value]);

  const handleClick = (e) => {
    dropdownRef.current = e.target;
    openDropdown();
  };

  return (
    <TimeEntrySettingSelectContainer>
      <TextContainer onClick={handleClick}>
        Mosaic Timesheet <StyledQBDownArrow />
        <SubTextContainer>{labelsMap[value]}</SubTextContainer>
      </TextContainer>
      {/**
       * This should be defined at table level, not in the cell. But should be fine for now
       * since rows are static. Will address next week during full feature implementation.
       */}
      <MultiLevelDropdown
        menuContent={menuContent}
        containerCustomStyle={dropdownContainerCustomStyle}
      />
    </TimeEntrySettingSelectContainer>
  );
};

const getRenderItem: MultiLevelDropdownContentRenderItem<string> =
  ({ value, selected, disabled }) =>
  () =>
    (
      <TimeEntryDropdownItem className={cn({ selected, disabled })}>
        {value}
        {!disabled && <KaratRight />}
      </TimeEntryDropdownItem>
    );

const StyledQBDownArrow = styled(QBDownArrow)`
  opacity: 0;
  path {
    fill: ${({ theme }) => theme.colors.colorCalendarBlue};
  }
`;

const TextContainer = styled.div`
  font-size: 13px;
`;

const SubTextContainer = styled.div`
  font-size: 12px;
`;

export const TimeEntrySettingSelectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7px;
  color: ${({ theme }) => theme.colors.colorCalendarBlue};
  cursor: pointer;

  &:hover {
    ${StyledQBDownArrow} {
      opacity: 1;
    }
  }
`;

const TimeEntryDropdownItem = styled.div`
  padding: 8px 16px;

  display: flex;
  width: 166px;
  justify-content: space-between;
  align-items: center;

  &.selected {
    font-weight: 600;
  }
`;

const TimeEntryDropdownSubMenuItem = styled(TimeEntryDropdownItem)`
  width: 152px;
`;

const dropdownContainerCustomStyle = `
  .menu-item {
    font-size: 13px;

    &:not(.disabled) {
      &:hover {
        background: ${theme.colors.colorTranslucentGray4};
      }
      color: ${theme.colors.colorMediumGray9};
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
      color: ${theme.colors.colorLightGray15};
    }
  }
  .nested:hover > .nested-dropdown {
    left: 101%;
  }
`;
