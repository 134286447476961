import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { SecuritySettingsLegacy } from './SecuritySettings/legacy';
import { SecuritySettings } from './SecuritySettings';

export const SecurityTab = () => {
  const { securitySettingsNewUIFlag } = useFeatureFlags();
  return securitySettingsNewUIFlag ? (
    <SecuritySettings />
  ) : (
    <SecuritySettingsLegacy />
  );
};
