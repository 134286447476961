import { useContext } from 'react';
import styled from 'styled-components';
import {
  LIST_ITEM_TYPES,
  renderItem
} from 'SuggestionModule/components/FindPeople/List/constants';
import useSimpleStaticList from 'appUtils/hooks/useSimpleStaticList/useSimpleStaticList';
import useProjectTeamPhasesGroupedListBuilder from './helpers/useProjectTeamPhasesGroupedListBuilder';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import { SimpleStaticListItem } from 'appUtils/hooks/useSimpleStaticList/types';

const calculateItemHeight = (item: SimpleStaticListItem) => {
  const { itemType, isOpen, isLast } = item;
  if (itemType === LIST_ITEM_TYPES.AddMemberToPhase) {
    return 25;
  } else if (itemType === LIST_ITEM_TYPES.Phase) {
    const { hasActivityPhases } = item;
    return isOpen ? (hasActivityPhases ? 40 : 25) : 45;
  } else if (itemType === LIST_ITEM_TYPES.ActivityPhase) {
    return isOpen ? 25 : 45;
  } else if (itemType === LIST_ITEM_TYPES.Membership) {
    return isLast ? 60 : 50; // Creating bottom space between the previous phase's last member and next phase
  }
  return 50;
};

const ProjectTeamListBody = () => {
  const { selectedProjectId, allProjectPhasesOrder, openBudgetModal } =
    useContext(FindPeopleModalContext);

  const { List } = useSimpleStaticList({
    useInitialListBuilder: useProjectTeamPhasesGroupedListBuilder,
    renderItem,
    itemHeight: calculateItemHeight,
    initialListBuilderArgs: {
      phasesOrder: allProjectPhasesOrder, // Spec: Also show phases that contain activity phases, but cannot change budget hours nor add members
      projectId: selectedProjectId,
      openBudgetModal
    }
  });

  return <StyledListContainer>{List}</StyledListContainer>;
};

export default ProjectTeamListBody;

const StyledListContainer = styled.div`
  height: 100%;
  width: 100%;
`;
