import React from 'react';
import DateCell from './DateCell';
import ActorCell from './ActorCell';
import MemberCell from './MemberCell';
import ProjectCell from './ProjectCell';
import BillableCell from './BillableCell';
import ApproveCell from './ApproveCell';
import ThreeDotCell from './ThreeDotCell';
import RateCell from './RateCell';
import HoursCell from './HoursCell';
import DescriptionCell from './DescriptionCell';
import WorkCategoryCell from './WorkCategoryCell';
import StatusCell from './StatusCell';
import EmailCell from './EmailCell';
import ClientCell from './ClientCell';
import ProjectNumberCell from './ProjectNumberCell';

class Cells extends React.Component {
  actor = () => <ActorCell {...this.props} />;
  date = () => <DateCell {...this.props} />;
  project = () => <ProjectCell {...this.props} />;
  member = () => <MemberCell {...this.props} />;
  approve = () => <ApproveCell {...this.props} />;
  billable = () => <BillableCell {...this.props} />;
  workCategory = () => <WorkCategoryCell {...this.props} />;
  description = () => <DescriptionCell {...this.props} />;
  rate = () => <RateCell {...this.props} />;
  hours = () => <HoursCell {...this.props} />;
  status = () => <StatusCell {...this.props} />;
  threeDot = () => <ThreeDotCell {...this.props} />;
  projectNumber = () => <ProjectNumberCell {...this.props} />;
  email = () => <EmailCell {...this.props} />;
  client = () => <ClientCell {...this.props} />;

  render() {
    const { column } = this.props;
    const { headerType } = column;
    const CellComponent = this[headerType];

    return <CellComponent />;
  }
}

export default Cells;
