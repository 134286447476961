import SvgIcon from 'components/SvgIcon';

const HourGlassIcon = ({
  className,
  color = '#b2b2b2',
  height = 9,
  width = 8
}: {
  className?: string;
  color?: string;
  height?: number;
  width?: number;
}) => (
  <SvgIcon
    className={className}
    height={height}
    preserveAspectRatio="none"
    viewBox="-0.333333 -0.333333 8.666667 9.666667"
    width={width}
    fill="none"
  >
    <path
      d="M 0 0 H 1.1429 M 8 0 H 6.8571 M 1.1429 0 V 3.2727 L 6.8571 6.1364 V 9 M 1.1429 0 H 6.8571 M 1.1429 9 V 6.1364 L 6.8571 3.2727 V 0 M 0 9 H 8"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={0.666667}
    />
  </SvgIcon>
);

export default HourGlassIcon;
