import { useState, useRef, KeyboardEvent, useEffect } from 'react';
import cn from 'classnames';
import { CommentInputContainer, OutsideClickWrapper } from '..';
import { commentTypes } from 'appConstants/commentConstants';
import getCaretCoordinates from 'textarea-caret';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { Attachment } from 'models/attachment';

export interface CommentFormValues {
  description: string;
  files: Array<File>;
}

export interface CustomPlaceholders {
  ADD?: string;
}

export interface CommentFormProps {
  description?: string;
  attachments?: Array<Attachment>;
  commentType: keyof typeof commentTypes;
  isReply?: boolean;
  isEditingComment?: boolean;
  isExpanded: boolean;
  onClick?: () => void;
  onSave: (CommentFormValues: CommentFormValues) => void;
  onCancel: () => void;
  isDisabled?: boolean;
  placeholders?: CustomPlaceholders;
  formTooltip?: string;
}

export const CommentForm = ({
  description,
  commentType,
  isEditingComment = false,
  isReply = false,
  onClick,
  onSave,
  onCancel,
  isExpanded,
  isDisabled,
  placeholders,
  formTooltip,
  attachments: initialAttachments
}: CommentFormProps) => {
  const [commentBody, setCommentBody] = useState<string>(description ?? '');
  const [attachments, setAttachments] = useState<Array<Attachment>>(
    initialAttachments ?? []
  );
  const [suggestionListLeft, setSuggestionListLeft] = useState<number>(0);
  const [files, setFiles] = useState<Array<File>>([]);
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | undefined>(
    null
  );

  const handleCommentChange = (event) => {
    setCommentBody(event.target.value);
  };

  const handleKeyPress = (
    event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey && commentBody.trim().length) {
      event.preventDefault();
      handleCommentSubmit();
    }

    if (event.key === '@') {
      const commentInput = inputRef.current;
      if (commentInput && commentInput.selectionEnd) {
        const suggestionListLeft = getCaretCoordinates(
          commentInput,
          commentInput.selectionEnd
        ).left;
        setSuggestionListLeft(suggestionListLeft);
      }
    }
  };

  const handleCommentSubmit = () => {
    if (commentBody.trim().length || files.length) {
      onSave({
        description: commentBody,
        files
      });
      resetForm();
    }
  };

  const resetForm = () => {
    setCommentBody('');
    setFiles([]);
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleFiles = (files: Array<File>) => {
    setFiles((prev) => [...Array.from(files), ...(prev || [])]);
  };

  const removeFile = (file: File) => {
    setFiles((prev) => prev.filter((stateFile) => stateFile !== file));
  };

  useEffect(() => {
    if (formTooltip !== undefined) rebuildTooltip();
  }, [formTooltip]);

  const removeAttachment = (attachmentId: number) => {
    setAttachments((prev) => prev.filter(({ id }) => id !== attachmentId));
  };

  return (
    <OutsideClickWrapper handleOutsideClick={handleCommentSubmit}>
      <div
        className={cn({
          'add-comment-container': commentType === 'ADD',
          'edit-comment-container': commentType === 'EDIT',
          'reply-comment-container': commentType === 'REPLY'
        })}
        data-class="center"
        data-effect="solid"
        data-for="app-tooltip"
        data-html
        data-tip={formTooltip}
      >
        <CommentInputContainer
          inputRef={inputRef}
          className={cn({
            isReply,
            'add-comment-input': commentType === 'ADD',
            'editable-comment': commentType === 'EDIT',
            'comment-input': commentType === 'REPLY'
          })}
          commentType={commentTypes[commentType]}
          commentBody={commentBody}
          handleFiles={handleFiles}
          removeFile={removeFile}
          removeAttachment={removeAttachment}
          onClick={onClick}
          isExpanded={isExpanded}
          isEditingComment={isEditingComment}
          suggestionListLeft={suggestionListLeft}
          onCancel={handleCancel}
          onChange={handleCommentChange}
          onSubmit={handleCommentSubmit}
          onKeyPress={handleKeyPress}
          isDisabled={isDisabled}
          placeholders={placeholders}
          unsavedFiles={files}
          attachments={attachments}
        />
      </div>
    </OutsideClickWrapper>
  );
};
