import { useState, useEffect, useMemo, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import {
  createPhaseMembers,
  openMilestoneModal,
  createActivityMembers
} from 'actionCreators';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { getProjectHash } from 'ProjectsModule/selectors';
import useUnassignedRolesByProjectAndPhases, {
  ProjectUnassignedMemberBudgetWithPosition,
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { useSelector } from 'react-redux';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { TextButtonWithBorder, BlueSubmitButton } from 'components/styles';
import EllipsisText from 'components/EllipsisText';
import cn from 'classnames';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { openRemoveMemberModal } from 'BudgetModule/actionCreators';
import RemoveMemberModal from 'BudgetModule/components/BudgetModal/RemoveMemberModal';
import { ActivityPhase } from 'ActivityPhaseModule/models/activityPhase';
import { ActivityPhaseMembership } from 'ActivityPhaseModule/models/activityPhaseMembership';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';
import { serializeId, deserializeId } from 'appUtils';
import { checkPhaseHasActivityPhase } from 'ProjectsModule/utils/phaseDisplay';
import { ValueOf } from 'type-fest';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { AnyAction } from '@reduxjs/toolkit';
import useActivityPhases from 'appUtils/hooks/useActivityPhases/useActivityPhases';
import {
  GetActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
  GetFormattedActivityPhaseByPhaseIdAndActivityId
} from 'appUtils/hooks/useActivityPhases/types';

export const ItemTypes = {
  PHASE: 'phase',
  ACTIVITY_PHASE: 'activity-phase'
} as const;

const emptyArr = [];
const emptyObj = {};

export type DefaultListItemProps = {
  nameToDisplay: string;
  searchKey: string; // Necessary for searching
  hasUnassignedRole?: boolean;
  toAddUnassignedRole?: boolean;
  unselectable: boolean; // Currently activity phase should always be selectable
  itemType: ValueOf<typeof ItemTypes>;
};

export type ActivityPhaseItem = ActivityPhase & DefaultListItemProps;
export type PhaseItem = PhaseWithDefault & DefaultListItemProps;

type ListItem = PhaseItem | ActivityPhaseItem;

// Locally added
type AddedItem = { children?: string[] };

type GroupInfo = {
  group: ListItem[];
  hasUnassignedRole: boolean;
  toAddUnassignedRole: boolean;
};

const addUnassignedRoleToPhasesChainId =
  'add-unassigned-role-to-phases-chain-id';

const itemFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: ['searchKey']
  });

/**
 * Handler for case when phase does not have activity phases
 */
export const handlePhaseGroupWithoutChildren = ({
  groupInfo,
  phaseMembership,
  phase,
  phaseListItem
}: {
  groupInfo: GroupInfo;
  phaseMembership?: PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition;
  phase: PhaseWithDefault;
  phaseListItem: AddedItem | undefined;
}) => {
  const phaseItem = {
    ...phase,
    nameToDisplay: phase.name,
    searchKey: phase.name,
    hasUnassignedRole: false,
    toAddUnassignedRole: false,
    unselectable: false,
    itemType: ItemTypes.PHASE
  };
  if (phaseMembership) {
    // Phase already has this unassigned role
    groupInfo.hasUnassignedRole = true;
    phaseItem.hasUnassignedRole = true;
    groupInfo.group.push(phaseItem);
    return;
  }

  /* --------------- The unassigned role is not in the phase yet -------------- */

  if (phaseListItem) {
    // Added locally - to be added on BE once press Add
    groupInfo.toAddUnassignedRole = true;
    phaseItem.toAddUnassignedRole = true;
  }
  groupInfo.group.push(phaseItem);
};

/**
 * Handler for case when phase has activity phases
 */
export const handlePhaseGroupWithChildren = ({
  groupInfo,
  phase,
  addedItems,
  unassignedMemberBudgetId,
  getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
  getFormattedActivityPhaseByPhaseIdAndActivityId
}: {
  groupInfo: GroupInfo;
  phase: PhaseWithDefault;
  addedItems: Record<string, AddedItem>;
  unassignedMemberBudgetId: number;
  getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId: GetActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId;
  getFormattedActivityPhaseByPhaseIdAndActivityId: GetFormattedActivityPhaseByPhaseIdAndActivityId;
}) => {
  // Currently, parent phase is not selectable when there are activity phases as we are adding unassigned role to activity phase
  const activityPhasesContainUnassignedRole: ActivityPhaseItem[] = []; // First in line (Clicking these will open remove modal to remove unassigned role from them)
  const activityPhasesToAddUnassignedRoleTo: ActivityPhaseItem[] = []; // Second in line (Clicking these will un-select them from being added) - moved to 3rd list
  const activityPhasesNotContainUnassignedRole: ActivityPhaseItem[] = []; // Third in line (Clicking these will select them to be added) - moved to 2nd list

  let phaseSearchKey = '';

  for (const activityId of phase.activity_order) {
    const activityPhase = getFormattedActivityPhaseByPhaseIdAndActivityId({
      phaseId: phase.id,
      activityId
    });
    if (activityPhase) {
      phaseSearchKey += `${activityPhase.activityTitle} `;
      const activityPhaseListItem: ActivityPhaseItem = {
        ...activityPhase,
        searchKey: `${phase.name}-${activityPhase.activityTitle}`,
        nameToDisplay: activityPhase.activityTitle,
        unselectable: false,
        hasUnassignedRole: false,
        toAddUnassignedRole: false,
        itemType: ItemTypes.ACTIVITY_PHASE
      };

      const activityPhaseMembership =
        getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId({
          activityPhaseId: activityPhase.id,
          memberBudgetId: unassignedMemberBudgetId
        });

      // The unassigned role already exists in this activity phase (added on BE)
      if (activityPhaseMembership) {
        groupInfo.hasUnassignedRole = true;
        activityPhaseListItem.hasUnassignedRole = true;
        activityPhasesContainUnassignedRole.push(activityPhaseListItem);
        continue;
      }

      const serializedActivityPhaseId = serializeId({
        itemType: ItemTypes.ACTIVITY_PHASE,
        id: activityPhase.id,
        ids: null
      });
      const isActivityPhaseToBeAdded = !!addedItems[serializedActivityPhaseId];

      if (isActivityPhaseToBeAdded) {
        // Added locally - to be added on BE once confirmed
        groupInfo.toAddUnassignedRole = true;
        activityPhaseListItem.toAddUnassignedRole = true;
        activityPhasesToAddUnassignedRoleTo.push(activityPhaseListItem);
      } else {
        // Not added
        activityPhasesNotContainUnassignedRole.push(activityPhaseListItem);
      }
    }
  }

  groupInfo.group.push({
    ...phase,
    nameToDisplay: phase.name,
    searchKey: phaseSearchKey.trim(),
    unselectable: true,
    itemType: ItemTypes.PHASE
  });

  const activityPhasesInOrder = [
    ...activityPhasesContainUnassignedRole,
    ...activityPhasesToAddUnassignedRoleTo,
    ...activityPhasesNotContainUnassignedRole
  ];

  groupInfo.group.push(...activityPhasesInOrder);
};

const makePhaseGroupInfo = ({
  phase,
  phaseMembership,
  addedItems,
  unassignedMemberBudgetId,
  getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
  getFormattedActivityPhaseByPhaseIdAndActivityId
}: {
  phase: PhaseWithDefault;
  phaseMembership?: PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition;
  addedItems: Record<string, AddedItem>;
  unassignedMemberBudgetId: number;
  getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId: GetActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId;
  getFormattedActivityPhaseByPhaseIdAndActivityId: GetFormattedActivityPhaseByPhaseIdAndActivityId;
}) => {
  const groupInfo: GroupInfo = {
    group: [],
    hasUnassignedRole: false,
    toAddUnassignedRole: false
  };

  const hasActivityPhase = checkPhaseHasActivityPhase(phase);
  const serializedPhaseItemId = serializeId({
    itemType: ItemTypes.PHASE,
    id: phase.id,
    ids: null
  });
  const phaseListItem = addedItems[serializedPhaseItemId];

  if (hasActivityPhase) {
    handlePhaseGroupWithChildren({
      groupInfo,
      phase,
      addedItems,
      unassignedMemberBudgetId,
      getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
      getFormattedActivityPhaseByPhaseIdAndActivityId
    });
  } else {
    // Phase without activity phase
    handlePhaseGroupWithoutChildren({
      groupInfo,
      phaseMembership,
      phase,
      phaseListItem
    });
  }

  return groupInfo;
};

const useAddRemoveUnassignedRoleFromPhasesDropdown = ({
  unassignedMemberBudgetId,
  projectId,
  phasesOrder,
  shouldCreatePhaseMembersActionRefetch = true
}: {
  unassignedMemberBudgetId: Nullable<number>;
  projectId: Nullable<number>;
  phasesOrder?: number[];
  shouldCreatePhaseMembersActionRefetch?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();
  const [addedItems, setAddItems] =
    useState<Record<string, AddedItem>>(emptyObj);

  const phaseHash = useSelector(getFlatPhasesHash);
  const { MultiStepFlyout, openFlyout, closeFlyout, isOpen } =
    useMultiStepFlyout();
  const projectHash = useAppSelector(getProjectHash);
  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
  } = useUnassignedRolesByProjectAndPhases({ projectId });
  const { status } = useRequestStatus({
    requestStatusId: addUnassignedRoleToPhasesChainId
  });

  const project = projectId ? projectHash[projectId] : undefined;

  // Default to phase_orders of the provided projectId
  const phasesOrderToUse =
    phasesOrder || (projectId ? project?.phase_orders || emptyArr : emptyArr);

  const {
    getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
    getFormattedActivityPhaseByPhaseIdAndActivityId
  } = useActivityPhases({
    projectId
  });

  const {
    isExecuting: isAddingUnassignedRoleToPhases,
    isSuccess: addUnassignedRoleToPhasesSuccess
  } = status || {};

  // Closing flyout will reset state
  useEffect(() => {
    if (!isOpen) {
      setAddItems(emptyObj);
    }
  }, [isOpen]);

  const renderItems = useCallback(({ item }: { item: ListItem }) => {
    const { toAddUnassignedRole, hasUnassignedRole, unselectable, itemType } =
      item;

    const textToUse = toAddUnassignedRole
      ? 'Added'
      : hasUnassignedRole
      ? 'Remove'
      : 'Add';

    const Icon =
      itemType === ItemTypes.PHASE ? (
        <BudgetPhaseMilestoneIcon
          className={undefined}
          strokeWidth={undefined}
        />
      ) : (
        <SmallDiamondIcon className={undefined} />
      );

    return (
      <StyledItem
        className={cn(`${itemType}`, {
          unselectable: unselectable,
          'has-activity-phases':
            itemType === ItemTypes.PHASE &&
            checkPhaseHasActivityPhase(item as PhaseWithDefault)
        })}
      >
        <span className="name-container">
          {Icon}
          <EllipsisText maxWidth={350} className="name">
            {item.nameToDisplay}
          </EllipsisText>
        </span>
        <div
          className={cn('add-text', {
            'to-add-unassigned-role': item.toAddUnassignedRole,
            'has-role': item.hasUnassignedRole,
            'has-no-role': !item.hasUnassignedRole
          })}
        >
          {textToUse}
        </div>
      </StyledItem>
    );
  }, []);

  /**
   * Handler for selecting Phase without activity phases
   */
  const handleSelectPhase = useCallback(
    (e, item: PhaseItem) => {
      const phaseId = item.id;
      const { hasUnassignedRole, toAddUnassignedRole } = item;
      if (!hasUnassignedRole && !toAddUnassignedRole) {
        // Add locally
        const serializedPhaseIdToAdd = serializeId({
          itemType: ItemTypes.PHASE,
          id: phaseId,
          ids: undefined
        });
        setAddItems((prev) => {
          return {
            ...prev,
            [serializedPhaseIdToAdd]: {} // No children
          };
        });
      } else if (item.hasUnassignedRole) {
        // Open up the modal to remove
        closeFlyout();
        dispatch(
          openRemoveMemberModal({
            memberBudget: {
              isUnassigned: true,
              phaseMembershipId:
                getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
                  { phaseId: item.id, memberBudgetId: unassignedMemberBudgetId }
                )?.id,
              phaseId: item.id,
              project_id: projectId
            }
          })
        );
      } else if (item.toAddUnassignedRole) {
        // In To Be Added Mode -> Remove from addedItems
        setAddItems((prev) => {
          const serializedPhaseIdToRemove = serializeId({
            itemType: ItemTypes.PHASE,
            id: phaseId,
            ids: undefined
          });
          return omit(prev, serializedPhaseIdToRemove);
        });
      }
    },
    [
      closeFlyout,
      dispatch,
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
      projectId,
      unassignedMemberBudgetId
    ]
  );

  const handleSelectActivityPhase = useCallback(
    (e, item: ActivityPhaseItem) => {
      const { id, hasUnassignedRole, toAddUnassignedRole, phase_id } = item;
      const serializedParentPhaseId = serializeId({
        itemType: ItemTypes.PHASE,
        id: phase_id,
        ids: undefined
      });
      const serializedActivityPhaseId = serializeId({
        itemType: ItemTypes.ACTIVITY_PHASE,
        id: id,
        ids: undefined
      });

      if (!hasUnassignedRole && !toAddUnassignedRole) {
        setAddItems((prev) => {
          const nextState = { ...prev };

          // Add a new instance of this activity phase
          nextState[serializedActivityPhaseId] = {};

          const nextParentPhaseState = nextState[serializedParentPhaseId];

          if (nextParentPhaseState && nextParentPhaseState.children) {
            // Parent phase already exist in added state
            nextState[serializedParentPhaseId] = {
              children: [
                ...nextParentPhaseState.children,
                serializedActivityPhaseId
              ]
            };
          } else {
            // This is the 1st activity phase added
            nextState[serializedParentPhaseId] = {
              children: [serializedActivityPhaseId]
            };
          }

          return nextState;
        });
      } else if (item.hasUnassignedRole) {
        // Open up the modal to remove unassigned role from activity phase
        const activityPhaseMembership =
          getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId({
            activityPhaseId: id,
            memberBudgetId: unassignedMemberBudgetId as number
          }) as ActivityPhaseMembership;
        closeFlyout();
        dispatch(
          openRemoveMemberModal({
            memberBudget: {
              isUnassigned: true,
              activityMembershipId: activityPhaseMembership.id,
              project_id: projectId
            }
          })
        );
      } else if (item.toAddUnassignedRole) {
        // In To-Be-Added Mode -> Remove
        setAddItems((prev) => {
          // Remove this activity phase added instance
          const nextState = { ...prev };
          delete nextState[serializedActivityPhaseId];

          // Checking the parent phase children list
          const nextParentPhaseState = nextState[
            serializedParentPhaseId
          ] as AddedItem;

          // Remove this activity phase instance from list of children

          if (nextParentPhaseState.children) {
            nextParentPhaseState.children =
              nextParentPhaseState.children.filter((serializedChildId) => {
                return serializedChildId !== serializedActivityPhaseId;
              });

            if (nextParentPhaseState.children.length === 0) {
              // All children removed. Also remove this phase item instance
              delete nextState[serializedParentPhaseId];
            }
          }

          return nextState;
        });
      }
    },
    [
      closeFlyout,
      dispatch,
      getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
      projectId,
      unassignedMemberBudgetId
    ]
  );

  const handleSelect = useCallback(
    (e, { item }: { item: ListItem }) => {
      if (item.unselectable) {
        return;
      }

      switch (item.itemType) {
        case ItemTypes.PHASE:
          handleSelectPhase(e, item as PhaseItem);
          break;
        case ItemTypes.ACTIVITY_PHASE:
          handleSelectActivityPhase(e, item as ActivityPhaseItem);
          break;
      }
    },
    [handleSelectActivityPhase, handleSelectPhase]
  );

  const onAddUnassignRoleToPhases = useCallback(() => {
    if (!projectId || isEmpty(addedItems) || !unassignedMemberBudgetId) {
      closeFlyout();
      return;
    }

    const actions = Object.keys(addedItems).reduce(
      (acc: AnyAction[], serializedItemId) => {
        const { itemType, itemId } = deserializeId(serializedItemId);

        if (itemType === ItemTypes.PHASE) {
          const phaseItem = addedItems[serializedItemId];
          const phase = phaseHash[itemId];
          const hasActivityPhase = checkPhaseHasActivityPhase(phase);

          if (hasActivityPhase) {
            // handle add unassigned role to activity phase(s)
            if (phaseItem?.children) {
              const { children } = phaseItem;
              children.forEach((serializedActivityPhaseId) => {
                const { itemId: activityPhaseId } = deserializeId(
                  serializedActivityPhaseId
                );
                acc.push(
                  createActivityMembers({
                    projectId,
                    activityPhaseId,
                    memberBudgetIds: [unassignedMemberBudgetId],
                    shouldRefetch: shouldCreatePhaseMembersActionRefetch
                  })
                );
              });
            }
          } else {
            // If phase does not have children (activity phases)
            acc.push(
              createPhaseMembers({
                projectId,
                phaseId: itemId,
                memberBudgetIds: [unassignedMemberBudgetId],
                shouldRefetch: shouldCreatePhaseMembersActionRefetch
              })
            );
          }
        }
        return acc;
      },
      []
    );

    dispatchChain([actions], {
      chainId: addUnassignedRoleToPhasesChainId,
      continueOnFailure: true,
      continueOnCancellation: true,
      cleanupChainStatuses: true
    } as any);

    closeFlyout();
  }, [
    projectId,
    addedItems,
    unassignedMemberBudgetId,
    dispatchChain,
    closeFlyout,
    phaseHash,
    shouldCreatePhaseMembersActionRefetch
  ]);

  const renderHeaderButton = useCallback((): ReactNode => {
    return (
      <HeaderButtonContainer onClick={closeFlyout}>
        <TextButtonWithBorder>Cancel</TextButtonWithBorder>
        <BlueSubmitButton fontWeight={600} onClick={onAddUnassignRoleToPhases}>
          Add
        </BlueSubmitButton>
      </HeaderButtonContainer>
    );
  }, [closeFlyout, onAddUnassignRoleToPhases]);

  const onFooterClick = useCallback(
    ({ e }) => {
      if (project) {
        closeFlyout();
        dispatch(openMilestoneModal({ project }));
      }
    },
    [closeFlyout, dispatch, project]
  );

  const items = useMemo(() => {
    const groupedItemsContainUnassignedRole: ListItem[] = []; // First in line (Clicking these will open remove modal to remove unassigned role from them)
    const groupedItemsToAddUnassignedRoleTo: ListItem[] = []; // Second in line (Clicking these will un-select them from being added) - moved to 3rd list
    const groupedItemsNotContainUnassignedRole: ListItem[] = []; // Third in line (Clicking these will select them to be added) - moved to 2nd list

    phasesOrderToUse.forEach((id) => {
      const phase = phaseHash[id];

      if (
        phase &&
        !phase.archived // Not allowed archive phase to show here
      ) {
        const phaseMembership =
          getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
            { phaseId: id, memberBudgetId: unassignedMemberBudgetId }
          );

        const { group, hasUnassignedRole, toAddUnassignedRole } =
          makePhaseGroupInfo({
            phase,
            phaseMembership,
            addedItems,
            unassignedMemberBudgetId: unassignedMemberBudgetId as number,
            getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
            getFormattedActivityPhaseByPhaseIdAndActivityId
          });

        if (!hasUnassignedRole && !toAddUnassignedRole) {
          // Not has unassigned role + not to add => This group is at the bottom (3rd list)
          groupedItemsNotContainUnassignedRole.push(...group);
        } else if (hasUnassignedRole) {
          // 1st list
          groupedItemsContainUnassignedRole.push(...group);
        } else {
          // 2nd list
          groupedItemsToAddUnassignedRoleTo.push(...group);
        }
      }
    });

    return [
      ...groupedItemsContainUnassignedRole,
      ...groupedItemsToAddUnassignedRoleTo,
      ...groupedItemsNotContainUnassignedRole
    ];
  }, [
    phasesOrderToUse,
    phaseHash,
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
    unassignedMemberBudgetId,
    addedItems,
    getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
    getFormattedActivityPhaseByPhaseIdAndActivityId
  ]);

  return {
    AddRemoveUnassignedRoleFromPhasesDropdown: (
      <>
        <MultiStepFlyout
          items={items}
          isLoading={isAddingUnassignedRoleToPhases}
          copy={{
            headerInitial: 'Add/Remove Role from Phase',
            footerInitial: (
              <StyledFooterContainer>
                <BudgetPhaseMilestoneIcon
                  className={undefined}
                  strokeWidth={undefined}
                />
                Manage Project Phases
              </StyledFooterContainer>
            ),
            searchPlaceholder: 'Type name or select below'
          }}
          getIdKey={(item: ListItem) =>
            serializeId({ itemType: item.itemType, id: item.id, ids: null })
          }
          disableSearchMemo
          renderItem={renderItems}
          editDisabled
          listWidth={468}
          itemHeight={48}
          isWhite
          isItemUnSelectable={(item: ListItem) => item.unselectable}
          searchEnabled
          renderHeaderButton={renderHeaderButton}
          closePopover={closeFlyout}
          handleSelect={handleSelect}
          itemFilter={itemFilter}
          onFooterClick={onFooterClick}
          popoverClassName="add-remove-unassigned-role-from-phases-dropdown"
        />
        <RemoveMemberModal />
      </>
    ),
    isAddingUnassignedRoleToPhases,
    addUnassignedRoleToPhasesSuccess,
    openAddRemoveUnassignedRoleFromPhasesDropdown: openFlyout,
    closeAddRemoveUnassignedRoleFromPhasesDropdown: closeFlyout
  };
};

export default useAddRemoveUnassignedRoleFromPhasesDropdown;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;

  .to-add-unassigned-role {
    color: ${theme.colors.colorCalendarBlue};
  }
  .has-role {
    color: ${theme.colors.colorCalendarRed};
  }
  .has-no-role {
    color: ${theme.colors.colorCalendarBlue};
  }

  .name-container {
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }

  &.${ItemTypes.ACTIVITY_PHASE} {
    .name-container {
      padding-left: 20px;
    }
  }
  &.${ItemTypes.PHASE} {
    &.has-activity-phases {
      .add-text {
        visibility: hidden;
      }
    }
  }
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;

  path {
    stroke: ${theme.colors.colorRoyalBlue};
  }
`;
