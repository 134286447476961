import React from 'react';
import { StyledColumnLabel } from './styles';

const PhaseDateCell = ({ row }) => {
  const { hasNoRole, isOpen } = row.original;
  return (
    <StyledColumnLabel className="hour">
      {hasNoRole || !isOpen ? '' : 'BUDGET HRS.'}
    </StyledColumnLabel>
  );
};

export default PhaseDateCell;
