import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

/*  https://github.com/reduxjs/reselect#customize-equalitycheck-for-defaultmemoize
 * use sparingly when -
 * - there are valid recomputations that often result in the same outcome
 * - checking for equality is cheaper than recomputing
 * - recomputing causes re-renders that skipping would avoid
 */

// create a "selector creator" that uses lodash.isequal instead of ===
export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);
