import React from 'react';
import SvgIcon from 'components/SvgIcon';

const StandardMilestoneIcon = ({
  height = '15',
  width = '14',
  className = '',
  stroke = '#4F4F4F',
  strokeWidth = '1',
  styles = { margin: '-2px' },
  viewBox = '0 0 14 14'
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      style={styles}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 7.00131L6.49881 1.5L1.5 6.99869L6.50119 12.5L11.5 7.00131Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default StandardMilestoneIcon;
