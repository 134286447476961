import {
  ExistenceCheckOptionHash,
  OptionHash,
  ParsedFilterSchema,
  OptionConfig
} from 'FilterModule/types';

export const isExistenceCheckOptionHash = (
  optionHash: OptionHash<unknown, unknown>
): optionHash is ExistenceCheckOptionHash => {
  return Object.values(optionHash).some((value) => value === true);
};

export const getFilterFieldOptionsConfig = ({
  currentFilterSchema,
  field
}: {
  currentFilterSchema: ParsedFilterSchema;
  field: keyof typeof currentFilterSchema.fields;
}) => {
  return currentFilterSchema.fields[field]?.optionsConfig;
};

export const getFilterFieldOptionLabel = ({
  optionsConfig,
  currentFilterSchema,
  field,
  currentValue
}: {
  optionsConfig?: OptionConfig<unknown, unknown>;
  currentFilterSchema?: ParsedFilterSchema;
  field: string;
  currentValue: unknown;
}) => {
  const optionsConfigToUse =
    optionsConfig ||
    (currentFilterSchema
      ? getFilterFieldOptionsConfig({
          currentFilterSchema,
          field
        })
      : undefined);
  const optionHash = optionsConfigToUse?.optionHash;
  return !optionHash || isExistenceCheckOptionHash(optionHash)
    ? undefined
    : optionHash[String(currentValue)]?.label;
};
