import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CircledDotIcon = ({ height = '10', width = '10', style }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 10 10" style={style}>
      <circle
        cx="5"
        cy="5"
        r="4"
        stroke="#2F80ED"
        strokeWidth="1.5"
        fill="#fff"
      />
      <circle cx="5" cy="5" r="0.75" fill="#2F80ED" />
    </SvgIcon>
  );
};

export default CircledDotIcon;
