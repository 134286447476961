import styled from 'styled-components';
import ColorPicker from 'components/ColorPicker';
import {
  calculateProjectColorPreference,
  calculateBoardColorPreference
} from 'appUtils/styleUtils';
import EllipsisText from 'components/EllipsisText';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { OriginType } from 'components/ColorPicker/types';

type ComponentProps = {
  accessText?: string;
  description: Nullable<string>;
  entityType: string;
  originType: OriginType;
  id: number;
  isLoading: boolean;
  title: string;
};

const EditMemberListHeaderTitle = ({
  accessText,
  description,
  entityType,
  originType,
  id,
  isLoading,
  title
}: ComponentProps) => {
  const { Dot, colorPickerHeaderText } = EntityTypePropsHash[entityType];

  return (
    <>
      {isLoading ? (
        <SkeletonLoader
          numLoaders={1}
          style={{ margin: '0' }}
          heightBuffer={20 - 130}
          loaderStyle={{ height: 130, rx: 0 }}
        />
      ) : (
        id && (
          <StyledContainer>
            <ColorPickerContainer>
              <Dot entityId={id} />
              <ColorPicker
                id={id}
                originType={originType}
                headerText={colorPickerHeaderText}
                pickerLocation="manage-members-modal"
                className="color-picker-container"
              />
            </ColorPickerContainer>
            <ProjectInfoContainerv2>
              <EllipsisText className="title">
                {/* <ProjectTitle>{title}</ProjectTitle> */}
                {title}
              </EllipsisText>
              {description ? (
                <EllipsisText className="description">
                  {description}
                </EllipsisText>
              ) : null}
              {accessText && <AccessText>{accessText}</AccessText>}
            </ProjectInfoContainerv2>
          </StyledContainer>
        )
      )}
    </>
  );
};

const StyledDot = styled.div<{
  entityId: number;
  theme: Record<string, string>;
}>`
  height: 8px;
  width: 8px;
  border-radius: 100px;
  position: absolute;
  top: 2px;
  left: 2px;
`;

const StyledProjectColorDot = styled(StyledDot)`
  background: ${({ entityId, theme }) =>
    calculateProjectColorPreference({
      id: entityId,
      styleId: undefined,
      projectId: undefined,
      theme,
      color: undefined
    })};
`;

const StyledBoardColorDot = styled(StyledDot)`
  background: ${({ entityId, theme }) =>
    calculateBoardColorPreference({
      id: entityId,
      boardId: undefined,
      theme,
      color: undefined
    })};
`;

const EntityTypePropsHash = {
  board: {
    Dot: StyledBoardColorDot,
    colorPickerHeaderText: 'Select Portfolio Color'
  },
  project: {
    Dot: StyledProjectColorDot,
    colorPickerHeaderText: 'Select Project Color'
  }
};

const StyledContainer = styled.div`
  display: flex;
  gap: 11px;
  min-width: 0px;
  .title {
    font-size: 26px;
    color: ${({ theme }) => theme.colors.colorPureBlack};
  }
  .description {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  }
`;

const ProjectInfoContainerv2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  flex: 1;
  font-size: 14px;
`;

const ColorPickerContainer = styled.div`
  position: relative;
  top: 14px;
`;

const AccessText = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  font-size: 13px;
  font-weight: 400;
`;

export default EditMemberListHeaderTitle;
