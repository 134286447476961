import styled from 'styled-components';
import { ArchiveIndicatorRowProps } from '../types';
import { ArchiveIcon } from 'icons/ArchiveIcon';
import FilledArrow from 'icons/FilledArrow';

interface StandardPTOTableArchivedSectionHeaderCellProps {
  row: {
    original: ArchiveIndicatorRowProps;
  };
}

export const StandardPTOTableArchivedSectionHeaderCell = (
  props: StandardPTOTableArchivedSectionHeaderCellProps
) => {
  const { archivedCount, isShowingArchivedPolicies, onClick } =
    props.row.original;
  return (
    <RootContainer onClick={onClick}>
      <ArchiveIcon width={16} height={16} />
      <ArchivedText>{archivedCount} Archived</ArchivedText>
      <ArrowIconContainer
        $isShowingArchivedPolicies={isShowingArchivedPolicies}
      >
        <FilledArrow width={10} height={10} />
      </ArrowIconContainer>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArchivedText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;

const ArrowIconContainer = styled.div<{ $isShowingArchivedPolicies: boolean }>`
  ${(props) => props.$isShowingArchivedPolicies && 'transform: rotate(180deg);'}
  margin-left: 2px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
