import { put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as entityActions from '../entityActions';
import { activityCreate } from 'actions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import actionCreators from '../actionCreators';
import integrationsActionCreators from 'IntegrationsModule/actionCreators';
import { searchItemsWithWhiteSpace } from 'appUtils/search';
import {
  getProjectSearchText,
  getIsConfigDirQbToMosaic
} from 'QuickbooksModule/selectors';
import {
  getSearchedFormattedProjectsListArray,
  getSearchedAllProjectSyncInfoArray
} from 'QuickbooksModule/selectors/phases';
import { getAuthToken, getSelectedTeamId } from 'selectors';
const {
  fetchSyncSettings,
  updateSyncSettings,
  fetchQbMembers,
  fetchQbServices,
  fetchImportedSubcustomers,
  syncQbMembers,
  syncSubcustomersToProjects,
  syncServices,
  syncTimeEntries,
  fetchSyncedMembers,
  fetchSyncedSubcustomers,
  fetchSyncedServices,
  updateSyncStatus,
  deleteSyncedSubcustomers,
  deleteSyncedServices,
  deleteSyncedMembers,
  fetchSubSubCustomers,
  fetchSyncedSubSubCustomers,
  syncSubSubCustomers,
  fetchCustomers,
  fetchSyncedSubcustomer,
  cancelSyncStatus,
  undoPendingActivities,
  undoPendingPhases,
  undoPendingMembers,
  undoPendingSubcustomers
} = entityActions;

export function* fetchSyncSettingsWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchSyncSettings,
    api.fetchSyncSettings,
    teamId,
    [token],
    action
  );
}
export function* updateSyncSettingsWorker(action) {
  const { body } = action.payload;
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    updateSyncSettings,
    api.updateSyncSettings,
    [token, teamId, body],
    action
  );
}

export function* fetchImportedEmployeesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchQbMembers,
    api.fetchQbMembers,
    teamId,
    [token],
    action
  );
}
export function* fetchImportedActivitiesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchQbServices,
    api.fetchQbServices,
    teamId,
    [token],
    action
  );
}
export function* fetchImportedSubcustomersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchImportedSubcustomers,
    api.fetchImportedSubcustomers,
    teamId,
    [token],
    action
  );
}

export function* syncEmployeesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { body } = action.payload;
  yield put(actionCreators.toggleMembersLoader(true));
  const { error, response } = yield changeEntity(
    syncQbMembers,
    api.syncQbMembers,
    [token, teamId, body],
    action
  );
  yield fetchEntity(
    fetchSyncedMembers,
    api.fetchSyncedMembers,
    teamId,
    [token],
    action
  );
  yield put(
    integrationsActionCreators.saveButtonClick({ saveButtonClicked: false })
  );
  yield put(actionCreators.toggleMembersLoader(false));
}
export function* syncSubcustomersToProjectsWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { body } = action.payload;
  yield put(actionCreators.toggleProjectsLoader(true));
  const { error, response } = yield changeEntity(
    syncSubcustomersToProjects,
    api.syncSubcustomersToProjects,
    [token, teamId, body],
    action
  );
  const { records } = body;
  if (records.length === 1) {
    yield fetchEntity(
      fetchSyncedSubcustomer,
      api.fetchSyncedSubcustomer,
      teamId,
      [response.ids[0], token],
      action
    );
  } else {
    yield fetchEntity(
      fetchSyncedSubcustomers,
      api.fetchSyncedSubcustomers,
      teamId,
      [token],
      action
    );
  }
  yield put(
    integrationsActionCreators.saveButtonClick({ saveButtonClicked: false })
  );
  yield put(actionCreators.toggleProjectsLoader(false));
}

export function* syncServicesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { body } = action.payload;

  yield put(actionCreators.toggleActivitiesLoader(true));

  const { error, response } = yield changeEntity(
    syncServices,
    api.syncServices,
    [token, teamId, body],
    action
  );
  yield fetchEntity(
    fetchSyncedServices,
    api.fetchSyncedServices,
    teamId,
    [token],
    action
  );
  yield put(
    integrationsActionCreators.saveButtonClick({ saveButtonClicked: false })
  );
  yield put(actionCreators.toggleActivitiesLoader(false));
}
export function* syncTimeEntriesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { body } = action.payload;

  const { error, response } = yield changeEntity(
    syncTimeEntries,
    api.syncTimeEntries,
    [token, teamId, body],
    action
  );
}

export function* fetchSyncedMembersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield put(actionCreators.initialFetchSyncedMembers(true));
  yield fetchEntity(
    fetchSyncedMembers,
    api.fetchSyncedMembers,
    teamId,
    [token],
    action
  );
  yield put(actionCreators.initialFetchSyncedMembers(false));
}
export function* fetchSyncedSubcustomersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield put(actionCreators.initialFetchSyncedSubCustomers(true));
  yield fetchEntity(
    fetchSyncedSubcustomers,
    api.fetchSyncedSubcustomers,
    teamId,
    [token],
    action
  );
  yield put(actionCreators.initialFetchSyncedSubCustomers(false));
}
export function* fetchSyncedServicesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchSyncedServices,
    api.fetchSyncedServices,
    teamId,
    [token],
    action
  );
}
export function* updateSyncStatusWorker(action) {
  const { body } = action.payload;
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    updateSyncStatus,
    api.updateSyncStatus,
    [token, teamId, body],
    action
  );
  yield delay(5000);
  yield fetchEntity(
    fetchSyncedServices,
    api.fetchSyncedServices,
    teamId,
    [token],
    action
  );
  yield fetchEntity(
    fetchSyncedSubcustomers,
    api.fetchSyncedSubcustomers,
    teamId,
    [token],
    action
  );
  yield fetchEntity(
    fetchSyncedMembers,
    api.fetchSyncedMembers,
    teamId,
    [token],
    action
  );
  yield fetchEntity(
    fetchSyncedSubSubCustomers,
    api.fetchSyncedSubSubCustomers,
    teamId,
    [token],
    action
  );
}
export function* deleteSyncedServicesWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    deleteSyncedServices,
    api.deleteSyncedServices,
    [token, teamId],
    action
  );
  yield fetchEntity(
    fetchSyncedServices,
    api.fetchSyncedServices,
    teamId,
    [token],
    action
  );
}
export function* deleteSyncedSubcustomerWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield put(actionCreators.toggleProjectsLoader(true));
  const { error, response } = yield changeEntity(
    deleteSyncedSubcustomers,
    api.deleteSyncedSubcustomers,
    [token, teamId],
    action
  );
  yield fetchEntity(
    fetchSyncedSubcustomers,
    api.fetchSyncedSubcustomers,
    teamId,
    [token],
    action
  );
  yield put(actionCreators.toggleProjectsLoader(false));
}
export function* deleteSyncedMembersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield put(actionCreators.toggleMembersLoader(true));
  const { error, response } = yield changeEntity(
    deleteSyncedMembers,
    api.deleteSyncedMembers,
    [token, teamId],
    action
  );
  yield fetchEntity(
    fetchSyncedMembers,
    api.fetchSyncedMembers,
    teamId,
    [token],
    action
  );
  yield put(actionCreators.toggleMembersLoader(false));
}
export function* fetchSubSubCustomersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchSubSubCustomers,
    api.fetchSubSubCustomers,
    teamId,
    [token],
    action
  );
}
export function* fetchSyncedSubSubCustomersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchSyncedSubSubCustomers,
    api.fetchSyncedSubSubCustomers,
    teamId,
    [token],
    action
  );
}
export function* syncSubSubCustomersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { body } = action.payload;
  yield put(actionCreators.toggleProjectsLoader(true));
  const { error, response } = yield changeEntity(
    syncSubSubCustomers,
    api.syncSubSubCustomers,
    [token, teamId, body],
    action
  );
  yield fetchEntity(
    fetchSyncedSubSubCustomers,
    api.fetchSyncedSubSubCustomers,
    teamId,
    [token],
    action
  );
  yield put(actionCreators.toggleProjectsLoader(false));
}
export function* fetchCustomersWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchCustomers,
    api.fetchCustomers,
    teamId,
    [token],
    action
  );
}

export function* qbSearchChangeWorker(action) {
  const { section, value } = action.payload;
  yield put(actionCreators.qbSearchChange(action.payload));
  const searchText = yield select(getProjectSearchText);
  if (searchText.length && section === 'projects') {
    // don't force open on empty search
    const isDirQbToMosaic = yield select(getIsConfigDirQbToMosaic);
    const projects = yield select(
      isDirQbToMosaic
        ? getSearchedFormattedProjectsListArray
        : getSearchedAllProjectSyncInfoArray
    );

    const phaseMatchingProjects = searchItemsWithWhiteSpace({
      itemList: projects,
      searchText,
      filterKeysArray: ['searchablePhases']
    });
    const projectIds = phaseMatchingProjects.map(
      (project) => project.mosaic_project_id
    );
    yield put(actionCreators.showPhases({ ids: projectIds }));
  }
}

export function* createNewActivities(action) {
  const { items } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const syncedActivities = [];
  yield put(actionCreators.toggleActivitiesLoader(true));
  for (const item of items) {
    const { error, response } = yield changeEntity(
      activityCreate,
      api.createActivity,
      [token, item.name, false],
      action
    );
    if (response && response.activities) {
      const [activity] = Object.keys(response.activities);
      syncedActivities.push({
        item_id: item.id,
        mosaic_activity_code_id: activity
      });
    }
  }
  const body = {
    records: syncedActivities
  };
  if (syncedActivities.length > 0) {
    yield changeEntity(
      syncServices,
      api.syncServices,
      [token, teamId, body],
      action
    );
    yield fetchEntity(
      fetchSyncedServices,
      api.fetchSyncedServices,
      teamId,
      [token],
      action
    );
  }
  yield put(actionCreators.toggleActivitiesLoader(false));
}

export function* cancelSyncStatusWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  yield put(
    integrationsActionCreators.isSyncCancelling({ isCancelling: true })
  );
  yield changeEntity(
    undoPendingActivities,
    api.undoPendingActivities,
    [token, teamId],
    action
  );
  yield changeEntity(
    undoPendingPhases,
    api.undoPendingPhases,
    [token, teamId],
    action
  );
  yield changeEntity(
    undoPendingMembers,
    api.undoPendingMembers,
    [token, teamId],
    action
  );
  yield changeEntity(
    undoPendingSubcustomers,
    api.undoPendingSubcustomers,
    [token, teamId],
    action
  );
  yield delay(5000);
  yield fetchEntity(
    fetchSyncedServices,
    api.fetchSyncedServices,
    teamId,
    [token],
    action
  );
  yield fetchEntity(
    fetchSyncedSubcustomers,
    api.fetchSyncedSubcustomers,
    teamId,
    [token],
    action
  );
  yield fetchEntity(
    fetchSyncedMembers,
    api.fetchSyncedMembers,
    teamId,
    [token],
    action
  );
  yield fetchEntity(
    fetchSyncedSubSubCustomers,
    api.fetchSyncedSubSubCustomers,
    teamId,
    [token],
    action
  );
  yield put(
    integrationsActionCreators.isSyncCancelling({ isCancelling: false })
  );
}
