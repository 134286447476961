import React from 'react';
import { connect } from 'react-redux';
import {
  getSortedTeamMembers,
  getMe,
  makeGetProjectBasicInfoById,
  getOwnProjectUnassignedCounts
} from 'selectors';
import { addMemberToProject } from 'actionCreators';
import cn from 'classnames';

import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import {
  ListMemberContainer,
  ListMemberName,
  ListAddText,
  ListMemberEmail
} from './styles';
import { CheckBoxContainer } from 'BudgetModule/components/styles';
import CheckBox from 'views/projectPlanner/CheckBox';
import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';
import { TaskAssigneeCircle } from 'components/TaskAssigneeDropdown/styles';
import { DoneButton } from 'views/projectPlanner/plannerModal/ActivityRowDropdown/styles';
import MemberBudgetContainer from './MemberBudgetContainer';

import theme from 'theme';
import { createProjectMemberships } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';

const byAccountId = (item) => (item.account ? item.account.id : item.id);
const noop = () => {};

const copy = {
  headerInitial: 'Select Member',
  searchPlaceholder: 'Type name or select below'
};
class MembersDropdown extends React.Component {
  state = {
    selectedMember: null,
    joinModalOpen: false
  };

  renderAddText = (item, index) => {
    const {
      boardMemberIds,
      isAssignMultiple,
      primaryMemberText = null,
      selectedIds = {}
    } = this.props;
    const memberId = item?.account?.id;
    if (this.isPrimaryMember(item)) {
      if (index === 1 && isAssignMultiple && selectedIds[memberId]) {
        // index 0 is unassigned/bulkassign row
        return primaryMemberText;
      }
      return null;
    }
    if (boardMemberIds[memberId]) {
      return `Add To Project`;
    }
    return `Add To Project And Portfolio`;
  };

  isPrimaryMember = (item) => {
    const {
      projectMemberIds,
      boardMemberIds,
      isEntitySelected = true
    } = this.props;
    const memberId = item?.account?.id;
    if (
      !projectMemberIds ||
      !boardMemberIds ||
      !memberId ||
      !isEntitySelected
    ) {
      return true;
    }
    if (projectMemberIds[memberId]) {
      return true;
    }
    return false;
  };

  isItemInactive = (item) => !this.isPrimaryMember(item);

  renderItem = ({ item, selectCallback, index }) => {
    const {
      me,
      isAssignMultiple,
      selectedIds = {},
      showEmail,
      renderItem,
      isOnWorkplanModal,
      bar,
      initialBar,
      projectUnassignedRoleCounts,
      isUsingProjectMembershipId
    } = this.props;

    if (renderItem) {
      return renderItem({ item, selectCallback, me });
    }
    if (item.unassigned) {
      return this.renderUnassigned({ item, selectCallback });
    }

    const isUnassignedMemberBudget =
      !item.account?.id && (item.position?.name || item.name);
    const idToUse = isUsingProjectMembershipId
      ? item.memberBudget?.project_membership?.id
      : isUnassignedMemberBudget
      ? item?.id
      : item.account?.id;
    const isMe = isUsingProjectMembershipId
      ? me.id === item.account?.id
      : idToUse === me.id;
    const showPositionNumber =
      isUnassignedMemberBudget &&
      item.position_number !== null &&
      projectUnassignedRoleCounts[item.position_id] > 1;
    const nameToDisplay = isUnassignedMemberBudget
      ? `${item.position?.name || item.name}${
          showPositionNumber ? ` | ${item.position_number}` : ''
        }`
      : item.account?.name || item.name;
    return (
      <ListMemberContainer showEmail={showEmail}>
        {isAssignMultiple && (
          <CheckBoxContainer
            checked={selectedIds[idToUse]}
            style={{ marginTop: 0 }}
          >
            <CheckBox
              toggle={(
                e // checkbox is a div, fake the event for handleChange
              ) => {}}
              checked={selectedIds[idToUse]}
            />
          </CheckBoxContainer>
        )}
        {isUnassignedMemberBudget ? (
          <TaskAssigneeCircle style={{ marginLeft: 0 }} />
        ) : (
          <MemberInitials
            projectMembers={true}
            member={item}
            classes={cn('item-account selected', {
              'small-logged-member': isMe,
              'small-regular-member': !isMe
            })}
            idx={'idx-placeholder'}
            isOnTaskModal
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: item.budgetTotals ? '-7px' : '0px',
            overflow: 'hidden'
          }}
        >
          <ListMemberName
            isOnWorkplanModal={isOnWorkplanModal}
            hasBudgetInfo={!!item.budgetTotals}
            isUnassignedMemberBudget={isUnassignedMemberBudget}
          >
            {nameToDisplay}
          </ListMemberName>
          {showEmail && !isUnassignedMemberBudget && (
            <ListMemberEmail>{item.account.email}</ListMemberEmail>
          )}
          {!!item.budgetTotals && (
            <MemberBudgetContainer
              initialBar={initialBar}
              bar={bar}
              phaseId={bar && bar.phase_id}
              accountId={bar && bar.account_id}
              budgetTotals={item.budgetTotals}
            />
          )}
          <ListAddText isAssignMultiple={isAssignMultiple}>
            {this.renderAddText(item, index)}
          </ListAddText>
        </div>
      </ListMemberContainer>
    );
  };

  renderUnassigned = ({ item, selectCallback }) => {
    const {
      isAssignMultiple,
      selectedIds = {},
      members,
      handleBatchSelect,
      handleBatchClear,
      hideSelectAll,
      hideClearAll
    } = this.props;
    const primaryMembers = members
      .filter(this.isPrimaryMember)
      .filter((member) => member?.account?.id || member?.memberBudget);
    const numSelectedIds = Object.values(selectedIds).filter(
      (item) => item
    ).length;
    const allSelected = primaryMembers.length === numSelectedIds;
    return (
      <ListMemberContainer>
        {isAssignMultiple ? (
          <div style={{ color: theme.colors.colorRoyalBlue, display: 'flex' }}>
            {!allSelected && !hideSelectAll && (
              <div
                style={{
                  margin: 4,
                  paddingRight: 10,
                  borderRight: `1px solid ${theme.colors.colorRoyalBlue}`
                }}
                onClick={() => handleBatchSelect(primaryMembers, true)}
              >
                Select All {primaryMembers.length || ''}
              </div>
            )}
            {!hideClearAll && (
              <div style={{ margin: 4 }} onClick={handleBatchClear}>
                Clear {numSelectedIds}
              </div>
            )}
          </div>
        ) : (
          <>
            <TaskAssigneeCircle style={{ marginLeft: 0 }} />
            <ListMemberName>Unassign</ListMemberName>
          </>
        )}
      </ListMemberContainer>
    );
  };

  handleSelect = (e, { item, selectCallback }) => {
    const { handleSelect, project } = this.props;
    e.preventDefault();
    e.stopPropagation();

    // we don't have sufficient information to prompt join modal
    if (!project?.member_account_ids) {
      handleSelect(item);
      return;
    }

    if (
      !item?.account?.id ||
      project.member_account_ids.includes(item.account.id)
    ) {
      handleSelect(item);
    } else {
      this.setJoinMemberToProject(item);
    }
  };

  renderMember = () => {
    const { selectedMember } = this.props;
    return <div>{selectedMember.account.name}</div>;
  };

  handleClose = (e) => {
    const { joinModalOpen } = this.state;
    const { handleClose, isAssignMultiple } = this.props;
    if (joinModalOpen && isAssignMultiple) {
      return;
    }

    handleClose(e);
  };

  handleCancel = (e) => {
    const { handleCancel } = this.props;
    handleCancel?.();
    this.handleClose(e);
  };

  handleDone = () => {
    const { handleDone } = this.props;
    handleDone();
    this.handleClose();
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item: item.account,
      filterKeysArray: ['name', 'initials']
    });

  setJoinMemberToProject = (member) => {
    this.setState({
      selectedMember: member,
      joinModalOpen: true
    });
  };

  clearJoinProject = () =>
    this.setState({ selectedMember: null, joinModalOpen: false });

  joinProject = () => {
    const {
      addMemberToProject,
      project,
      handleSelect,
      handleClose,
      isAssignMultiple
    } = this.props;
    const { selectedMember } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: () => handleSelect(selectedMember),
        selector: noop
      }
    ];
    addMemberToProject(
      project.id,
      selectedMember.account.id,
      basicMemberRole,
      project.board_id,
      onSuccess
    );
    if (!isAssignMultiple) {
      handleClose();
    }
    this.clearJoinProject();
  };

  renderHeaderButton = () => {
    return (
      <div style={{ display: 'flex', height: 30 }}>
        <DoneButton onClick={this.handleDone}>Done</DoneButton>
      </div>
    );
  };

  filterMembersByPermission = () => {
    const { members, canAddProjectMembership } = this.props;

    return canAddProjectMembership
      ? members
      : members.filter((member) => !this.isItemInactive(member));
  };

  render() {
    const {
      target,
      project,
      me,
      joinModalText,
      hideFooter = true,
      customCopy = {},
      onFooterClick,
      isAssignMultiple,
      renderHeader,
      popoverClassName,
      noHeader,
      showCloseInSearch,
      listWidth,
      itemHeight = 46
    } = this.props;

    const filteredMembersByPermission = this.filterMembersByPermission();

    const { joinModalOpen, selectedMember } = this.state;
    return (
      <>
        {(!joinModalOpen || isAssignMultiple) && (
          <MultiStepFlyout
            copy={{ ...copy, ...customCopy }}
            items={filteredMembersByPermission}
            getItemId={byAccountId}
            idKey="id"
            renderItem={this.renderItem}
            handleSelect={this.handleSelect}
            handleSubmit={this.handleSubmit}
            itemFilter={this.itemFilter}
            isWhite
            searchEnabled
            editDisabled
            top={0}
            target={target}
            handleClose={this.handleClose}
            hideFooter={hideFooter}
            listItemContainerStyle={'border-bottom: none; padding: 0px 6px;'}
            popoverClassName={popoverClassName || 'members-bar-dropdown'}
            listWidth={listWidth || '200'}
            isItemInactive={this.isItemInactive}
            itemHeight={itemHeight}
            onFooterClick={onFooterClick}
            renderHeaderButton={
              isAssignMultiple ? this.renderHeaderButton : undefined
            }
            renderHeader={renderHeader}
            noHeader={noHeader}
            showCloseInSearch={showCloseInSearch}
            includeFooterInListHeight
          />
        )}

        <JoinProjectModal
          open={joinModalOpen}
          toggle={this.clearJoinProject}
          selectedProject={project}
          onConfirm={this.joinProject}
          text={joinModalText || 'add a Work Plan'}
          accountId={selectedMember?.account?.id}
          currentUserId={me.id}
        />
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getProjectInfo = makeGetProjectBasicInfoById();
  const mapStateToProps = (state, ownProps) => ({
    members: ownProps.members || getSortedTeamMembers(state), // allow member override
    me: getMe(state),
    project: getProjectInfo(state, ownProps),
    projectUnassignedRoleCounts: getOwnProjectUnassignedCounts(state, {
      projectId: ownProps.projectId
    })
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  addMemberToProject,
  createProjectMemberships
};

const MembersDropdownWrapper = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MembersDropdown);

const MembersDropdownPermissionWrapper = (props) => {
  const { projectId } = props;
  const { canAddProjectMembership } = useProjectPermissionState({ projectId });
  return (
    <MembersDropdownWrapper
      {...props}
      canAddProjectMembership={canAddProjectMembership}
    />
  );
};

export default MembersDropdownPermissionWrapper;
