import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import {
  StyledFilterRow,
  CustomCheckBoxContainer,
  CustomCheckBox,
  CheckBoxDiv
} from 'ReportsModule/components/styles';
import { InnerDropdownOption } from 'views/projectPlanner/styles';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';

const PhaseIcon = styled(BudgetPhaseMilestoneIcon)`
  width: 11px;
  height: 12px;
  transform: scaleX(0.9);
  margin-right: 6px;
  flex-shrink: 0;
  path {
    stroke: ${theme.colors.colorMediumGray9};
    stroke-width: 1.3px;
  }
  position: relative;
  bottom: 1.5px;
`;

const RowInfo = styled(InnerDropdownOption)`
  padding: 0;
  height: 100%;
`;
const StyledPhaseRow = styled(StyledFilterRow)`
  margin-left: 34px;
`;
const PhaseTitle = styled.div`
  font-size: 13px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  color: ${theme.colors.colorMediumGray9};
  margin-bottom: 15px;
  svg {
    margin: 0 4px;
  }
`;

const PhaseStatus = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  padding-left: 5px;
  text-transform: uppercase;
`;
const StyledPhaseInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
`;

const PhaseRow = ({ children, index, search, item, isDragging }) => {
  const { list, isSelected, handleClick, uid, row } = item;

  const onClick = () => {
    if (!list.listItemsSelectionIsDisabled || isSelected) {
      handleClick(item);
    }
  };

  return (
    <StyledPhaseRow
      data-testid={`row-${uid}-${row.projectFilterListId}`}
      onClick={onClick}
    >
      <RowInfo
        key="project-item"
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={`${row.phase.name}`}
        data-delay-show={500}
        data-scroll-hide={true}
        data-html
      >
        <CheckBoxDiv key="1" style={{ marginRight: 2 }}>
          <CustomCheckBoxContainer isChecked={isSelected}>
            <CustomCheckBox isChecked={isSelected} />
          </CustomCheckBoxContainer>
        </CheckBoxDiv>
        <StyledPhaseInfo>
          <PhaseStatus>{row.phase.budget_status}</PhaseStatus>
          <PhaseTitle data-testid={`sidebar-${row.title}`}>
            <PhaseIcon width={14} height={13} />
            {row.phase.name}
          </PhaseTitle>
        </StyledPhaseInfo>
      </RowInfo>
    </StyledPhaseRow>
  );
};

export default PhaseRow;
