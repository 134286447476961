import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type ThreeLineWithPlusIconProps = Pick<
  SvgIconProps,
  'width' | 'height' | 'fill' | 'className'
>;

export const ThreeLineWithPlusIcon = ({
  width = '15',
  height = '9',
  fill = '#2F80ED',
  className = ''
}: ThreeLineWithPlusIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 15.160200 9.113280"
  >
    <path d="M 14 0.9444 H 7 H 0 L 0 0 H 14 V 0.9444 Z" fill={fill} />
    <path d="M 5.283 8.0736 V 9 H 8.717 V 8.0736 H 5.283 Z" fill={fill} />
    <path
      d="M 3.1698 4.0774 L 3.1698 5.0147 H 10.8302 V 4.0774 H 3.1698 Z"
      fill={fill}
    />
    <path
      d="M 13.5508 6.9102 H 15.1602 V 7.4492 H 13.5508 V 9.1133 H 13.0078 V 7.4492 H 11.4062 V 6.9102 H 13.0078 V 5.2383 H 13.5508 V 6.9102 Z"
      fill={fill}
    />
  </SvgIcon>
);

export default ThreeLineWithPlusIcon;
