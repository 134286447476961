const createIntegrationReducer =
  (reducer, initialState = {}) =>
  (integration) =>
  (state = initialState, action) => {
    const integrationId =
      action.payload?.integrationId ||
      action.payload?.requestPayload?.integrationId;

    if (integration !== integrationId) {
      return state;
    }

    return reducer(state, action);
  };

export default createIntegrationReducer;
