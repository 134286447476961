import moment from 'appUtils/momentConfig';
import { v4 as uuid } from 'uuid';
import keyBy from 'lodash/keyBy';

/**
 * @example
 * // return 'Aug'
 * getMonthAbbrev(new Date('2021-08-11'))
 * @param {Date} date
 * @returns {string} Return the month abbreviation of date
 *
 */
export const getMonthAbbrev = (date) => moment(date).format('MMM');

export const daysOfWeekAbbrev = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

/**
 * @example
 * // return ['2021-01-01', '2021-02-01', ..., '2021-12-01']
 * getEveryFirstDayOfYear(new Date(2021,0,1))
 * @param {Date} date
 * @returns {Array<Date>} Return array of first date of month in given date year
 */
export const getEveryFirstDayOfYear = (date) =>
  Array.from(
    moment()
      .range(moment(date).startOf('year'), moment(date).endOf('year'))
      .by('month'),
    (item) => item.toDate()
  );

/**
 *
 * @param {Number} val
 * @returns {String} Return px string
 */
export const toPx = (val) => `${val}px`;

export const getDateRangeString = (start, end) => {
  return Array.from(moment.range(start, end).by('days'), (m) =>
    m.format('YYYY-MM-DD')
  );
};

export const dataToGroup = (data) => {
  return keyBy(data, ({ date }) => moment(date).format('YYYY-MM-DD'));
};

const eventToEntriesByDate = (event) => {
  // giving a internal unique id to each event
  const calendarEventId = uuid();
  return getDateRangeString(event.start, event.end).reduce(
    (acc, dateString) => {
      if (!acc[dateString]) {
        acc[dateString] = { ...event, calendarEventId };
        return acc;
      }

      acc[dateString].push(calendarEventId);
      return acc;
    },
    {}
  );
};

// interface for future reference
// type CalendarEventHashByDate = Record<DateString, Array<CalendarEvent>>
// type CalendarEvent = BackgroundEvent & { calendarEventId: UniqueId }
export const mapEventsByDate = (events) => {
  const filteredEvents = events.filter((event) => event.start && event.end);

  return filteredEvents.reduce((acc, event) => {
    Object.entries(eventToEntriesByDate(event)).forEach(
      ([dateString, entry]) => {
        if (!acc[dateString]) {
          acc[dateString] = [entry];
        } else {
          acc[dateString].push(entry);
        }
      }
    );
    return acc;
  }, {});
};

export const getBackgroundRenderOption = (event, date) => {
  if (
    event.start === date ||
    (moment(date).isAfter(moment(event.start)) && moment(date).day() === 0)
  ) {
    const begin =
      event.start === date
        ? moment.max(moment(date).startOf('week'), moment(event.start))
        : moment(date).day('sunday');
    const end = moment.min(moment(date).day('saturday'), moment(event.end));

    return {
      event: event,
      visible: true,
      offset: moment(begin).day(),
      length: (end.diff(begin, 'days') % 7) + 1,
      isInitialBlock: event.start === date,
      eventOverlap: event.eventOverlap
    };
  }
  return {
    event: event,
    visible: false,
    offset: moment(date).day(),
    length: 1,
    eventOverlap: event.eventOverlap
  };
};
