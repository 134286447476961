import { useEffect, useMemo } from 'react';
import { lightFormat } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { DATE_FNS_USA_DATE } from 'appConstants/date';
import { fetchWorkloadPlanner } from 'actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getMyUserId } from 'UsersModule/selectors';
import { getPlannerSchedules } from 'selectors';
import { DateRange } from 'views/projectPlanner/DateRangeManager/DateRange';
import {
  BaseUseDataHookOptions,
  fetchSourceFilterKeyMap,
  fetchSourceParamKeyMap,
  WorkPlanFetchSource,
  WorkPlansHash
} from './types';
import { useDateRangeManager } from './useDateRangeManager';

interface UseFetchWorkPlansProps extends BaseUseDataHookOptions {
  ids: number[];
  fetchSource: WorkPlanFetchSource;
  startDate: Date;
  endDate: Date;
}

export const useFetchWorkPlans = ({
  ids,
  fetchSource,
  startDate,
  endDate,
  isReady = true
}: UseFetchWorkPlansProps) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const myId = useAppSelector(getMyUserId);
  const idKey = fetchSourceParamKeyMap[fetchSource];
  const idKeyToFilter = fetchSourceFilterKeyMap[fetchSource];
  const { getOutOfRangeIds } = useDateRangeManager();

  useEffect(() => {
    if (teamId && myId && ids.length && isReady) {
      const idsToFetch = getOutOfRangeIds({
        ids,
        dateRange: new DateRange({
          start: startDate,
          end: endDate
        })
      });

      if (idsToFetch.length) {
        dispatch(
          fetchWorkloadPlanner({
            startDate: lightFormat(startDate, DATE_FNS_USA_DATE),
            endDate: lightFormat(endDate, DATE_FNS_USA_DATE),
            accountId: myId,
            permissions: { teamId },
            all: true,
            [idKey]: idsToFetch
          })
        );
      }
    }
  }, [
    ids,
    teamId,
    startDate,
    endDate,
    myId,
    isReady,
    getOutOfRangeIds,
    dispatch,
    idKey
  ]);

  const workPlansHash: WorkPlansHash = useAppSelector(getPlannerSchedules);

  const workPlans = useMemo(
    () =>
      Object.values(workPlansHash).filter((workPlan) => {
        const fetchSourceId = workPlan[idKeyToFilter];
        return fetchSourceId && ids.includes(fetchSourceId);
      }),
    [idKeyToFilter, ids, workPlansHash]
  );

  return {
    workPlans
  };
};
