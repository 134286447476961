import styled from 'styled-components';
import theme from 'theme';
import { PermissionListItem } from '../types';
import { permissionsActionsHash } from 'PermissionsModule/constants';

export const PermissionLabelCell = ({
  row
}: {
  row: { original: PermissionListItem };
}) => {
  const { permissionId } = row.original;

  const permissionData = permissionsActionsHash[permissionId];

  return (
    <StyledPermissionLabelCell>
      {permissionData ? permissionData.permissionLabel : ''}
    </StyledPermissionLabelCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledPermissionLabelCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
`;
