import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

export const createProjectManagers = createAction<SpacePermissionCheckerParams>(
  'CREATE_PROJECT_MANAGERS'
);

export const createProjects =
  createAction<SpacePermissionCheckerParams>('CREATE_PROJECTS');

export const editProjectPrivacy = createAction<SpacePermissionCheckerParams>(
  'EDIT_PROJECT_PRIVACY'
);

export const archiveProjects =
  createAction<SpacePermissionCheckerParams>('ARCHIVE_PROJECTS');

export const deleteProjects =
  createAction<SpacePermissionCheckerParams>('DELETE_PROJECTS');

export const inviteGuestsToProjects =
  createAction<SpacePermissionCheckerParams>('INVITE_GUESTS_TO_PROJECTS');

export const createProjectMemberships =
  createAction<SpacePermissionCheckerParams>('CREATE_PROJECT_MEMBERSHIPS');

export const editProjectMemberships =
  createAction<SpacePermissionCheckerParams>('EDIT_PROJECT_MEMBERSHIPS');

export const deleteProjectMemberships =
  createAction<SpacePermissionCheckerParams>('DELETE_PROJECT_MEMBERSHIPS');

export const editProjectComments = createAction<SpacePermissionCheckerParams>(
  'EDIT_PROJECT_COMMENTS'
);

export const editTaskComments =
  createAction<SpacePermissionCheckerParams>('EDIT_TASK_COMMENTS');

export const editWorkPlanComments = createAction<SpacePermissionCheckerParams>(
  'EDIT_WORKPLAN_COMMENT'
);

export const deleteProjectComments = createAction<SpacePermissionCheckerParams>(
  'DELETE_PROJECT_COMMENTS'
);

export const deleteTaskComments = createAction<SpacePermissionCheckerParams>(
  'DELETE_TASK_COMMENTS'
);

export const deleteWorkPlanComments =
  createAction<SpacePermissionCheckerParams>('DELETE_WORKPLAN_COMMENTS');

export const deleteTasks =
  createAction<SpacePermissionCheckerParams>('DELETE_TASKS');

export const cloneProjects =
  createAction<SpacePermissionCheckerParams>('CLONE_PROJECTS');

export const editProjectViews =
  createAction<SpacePermissionCheckerParams>('EDIT_PROJECT_VIEWS');

export const createInvoices =
  createAction<SpacePermissionCheckerParams>('CREATE_INVOICES');

export const editInvoices =
  createAction<SpacePermissionCheckerParams>('EDIT_INVOICES');

export const editPhaseCost =
  createAction<SpacePermissionCheckerParams>('EDIT_PHASE_COST');

export const editPhaseBillable = createAction<SpacePermissionCheckerParams>(
  'EDIT_PHASE_BILLABLE'
);

export const editPhaseBudgetStatus = createAction<SpacePermissionCheckerParams>(
  'EDIT_PHASE_BUDGET_STATUS'
);

export const createMemberBudgets = createAction<SpacePermissionCheckerParams>(
  'CREATE_MEMBER_BUDGETS'
);

export const editMemberBudgets = createAction<SpacePermissionCheckerParams>(
  'EDIT_MEMBER_BUDGETS'
);

export const deleteMemberBudgets = createAction<SpacePermissionCheckerParams>(
  'DELETE_MEMBER_BUDGETS'
);

export const editProjectColors = createAction<SpacePermissionCheckerParams>(
  'EDIT_PROJECT_COLORS'
);

export const editProjectDates =
  createAction<SpacePermissionCheckerParams>('EDIT_PROJECT_DATES');

export const editPhaseDates =
  createAction<SpacePermissionCheckerParams>('EDIT_PHASE_DATES');

export const editWorkPlanDates = createAction<SpacePermissionCheckerParams>(
  'EDIT_WORK_PLAN_DATES'
);

export const readProjectBudgets =
  createAction<SpacePermissionCheckerParams>('READ_BUDGET_UI');

export const readBudgetCostRate = createAction<SpacePermissionCheckerParams>(
  'READ_BUDGET_BY_COST_RATE'
);
