import * as constants from 'appConstants';

export const taskCountRefetchActions = [
  constants.BULK_EDIT_HOME_TASKS.SUCCESS,
  constants.UPDATE_TASKS_ATTRIBUTES.SUCCESS,
  constants.BATCH_MOVE_INBOX_TASKS.SUCCESS,
  constants.BATCH_MOVE_TASKS_TO_PROJECT.SUCCESS,
  constants.BATCH_COPY_TASKS_TO_PROJECT.SUCCESS,
  constants.BATCH_DELETE_TASKS.SUCCESS,
  constants.BATCH_COMPLETE_TASKS.SUCCESS,
  constants.CREATE_TASK_FROM_HOME.SUCCESS,
  constants.MOVE_TASK.SUCCESS,
  constants.CREATE_PROJECT_TASK_GROUP.SUCCESS,
  constants.COPY_TASKS.SUCCESS,
  constants.MOVE_TASKS.SUCCESS
];
