import React from 'react';

const TaskPencil = ({ setEditTaskId, taskId }) => (
  <div
    className="pencil-icon"
    onClick={(e) => {
      e.stopPropagation();
      setEditTaskId(+taskId);
    }}
  />
);

export default TaskPencil;
