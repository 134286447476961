import React from 'react';
import { connect } from 'react-redux';

import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  closeSidebarSearch,
  setMouseOverSearchBar,
  openSidebarProjects
} from 'actionCreators';
import { getSearch, getSettings, getIsOnSearchablePageOrTab } from 'selectors';
import onClickOutside from 'react-onclickoutside';

class SearchBar extends React.Component {
  clearSearchInput = (e) => {
    e.stopPropagation();
    this.refs.searchInput.value = '';
    this.props.clearSearch();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let element;
    if (nextProps.search.clearSearch && !this.props.search.clearSearch) {
      this.refs.searchInput.value = '';
    }

    if (nextProps.sidebarSearchOpen && !this.props.sidebarSearchOpen) {
      element = document.getElementsByClassName('mosaic-search-bar-input')[0];
      element.readOnly = false;
      element.focus();
    }

    if (!nextProps.sidebarSearchOpen && this.props.sidebarSearchOpen) {
      element = document.getElementsByClassName('mosaic-search-bar-input')[0];
      element.blur();
      element.readOnly = true;
    }
  }

  handleClick = (event) => {
    const {
      sidebarSearchOpen,
      openSidebarProjects,
      toggleSidebarSearch,
      isOnSearchablePageOrTab
    } = this.props;

    if (!sidebarSearchOpen) {
      event.stopPropagation();
      if (isOnSearchablePageOrTab) {
        toggleSidebarSearch();
        return;
      }

      openSidebarProjects('search');
    }
  };

  handleClickOutside = (event) => {
    const { closeSidebarSearch, sidebarSearchOpen } = this.props;

    if (sidebarSearchOpen && !this.refs.searchInput.value.trim()) {
      closeSidebarSearch();
    }
  };

  onFocus = () => {
    const { onFocusSearch, checkForSearch, isOnSearchablePageOrTab } =
      this.props;

    if (isOnSearchablePageOrTab) {
      onFocusSearch(true);
      checkForSearch();
    }
  };

  onBlur = () => {
    const { onFocusSearch, checkForSearch } = this.props;
    onFocusSearch(false);
    checkForSearch();
  };

  render() {
    const {
      onChange,
      onKeyUp,
      searchText,
      searched,
      sidebarSearchOpen,
      settings
    } = this.props;

    return (
      <div
        className={cn('search-bar', {
          'cursor-pointer': !sidebarSearchOpen,
          sidebarSearchOpen
        })}
        onClick={this.handleClick}
      >
        {sidebarSearchOpen ? (
          <div className="mosaic-search-bar-button" />
        ) : null}
        <input
          className={cn('mosaic-search-bar-input', {
            searched: searched && sidebarSearchOpen,
            isActive: searchText && sidebarSearchOpen,
            sidebarSearchOpen: sidebarSearchOpen,
            helpMenuOpen: settings.isHelpMenuOpen
          })}
          id="navbar-search"
          type="text"
          ref="searchInput"
          readOnly
          value={searchText}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placeholder={sidebarSearchOpen && !searchText ? 'Filter Page' : null}
        />
        {searchText ? (
          <i
            className={cn('close-icon', {
              'show-on-hover': !sidebarSearchOpen
            })}
            onClick={this.clearSearchInput}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  search: getSearch(state),
  settings: getSettings(state),
  isOnSearchablePageOrTab: getIsOnSearchablePageOrTab(state)
});

const mapDispatchToProps = {
  closeSidebarSearch,
  setMouseOverSearchBar,
  openSidebarProjects
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(onClickOutside(SearchBar))
);
