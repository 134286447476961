import React from 'react';
import { FilterableFlyout, MemberCircleNameRow } from '../..';

import cn from 'classnames';

class MemberSelector extends React.Component {
  state = {
    isDropdownOpen: false
  };

  openDropdown = () => {
    this.setState({ isDropdownOpen: true });
  };

  closeDropdown = (e) => {
    e.stopPropagation();
    this.setState({ isDropdownOpen: false });
  };

  excludeSelectedFromList = (memberList) => {
    const { selectedMember } = this.props;
    return memberList.filter(
      (member) => member.account.id !== selectedMember.account.id
    );
  };

  onSelectMemberFactory = (member) => {
    const { onSelect } = this.props;

    return (e) => {
      e.stopPropagation();
      onSelect(member);
      this.closeDropdown(e);
    };
  };

  pinIdToTop = () => {
    const { pinIdToTop, availableMembers } = this.props;
    if (pinIdToTop) {
      const self = availableMembers.find((member) => member.id === pinIdToTop);
      const rest = availableMembers.filter(
        (member) => member.id !== pinIdToTop
      );
      return [self, ...rest];
    } else {
      return availableMembers;
    }
  };

  render() {
    const { selectedMember, availableMembers } = this.props;
    const { isDropdownOpen } = this.state;
    const children = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { ...selectedMember })
    );

    return (
      <div
        className={cn('member-selector', {
          'is-dropdown-open': isDropdownOpen
        })}
        onClick={this.openDropdown}
        ref={(ref) => (this.containerRef = ref)}
      >
        {children}
        {isDropdownOpen && (
          <FilterableFlyout
            offset="45"
            closeDropdown={this.closeDropdown}
            target={this.containerRef}
            fullItemList={this.pinIdToTop()}
            ItemComponent={MemberCircleNameRow}
            filterKeysArray={['name', 'initials']}
            onSelectFactory={this.onSelectMemberFactory}
            searchPlaceholder="Type name or select below"
          />
        )}
      </div>
    );
  }
}

export default MemberSelector;
