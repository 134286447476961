import React from 'react';
import { NumMembers } from './NumMembers';

const WidgetHeader = ({
  title,
  description,
  numMembers,
  customDescription = ''
}) => {
  const parsedNumMembers = numMembers && parseInt(numMembers, 10);
  return (
    <>
      <div className="title">{title}</div>
      {/* Actual description */}
      <div className="description custom">{customDescription}</div>
      {/* Date interval */}
      <div className="description">{description}</div>
      {!!parsedNumMembers && (
        <NumMembers
          data-testid="widget-header-num-members"
          numMembers={numMembers}
        />
      )}
    </>
  );
};

export default WidgetHeader;
