import { Children, useContext, useMemo } from 'react';
import styled from 'styled-components';
import {
  addDays,
  startOfDay,
  differenceInDays,
  lightFormat,
  isWeekend
} from 'date-fns';
import { HelpersContext } from 'react-calendar-timeline';
import { DATE_FNS_ISO_DATE } from 'appConstants/date';
import { BufferedTimeRange } from 'TimelinesModule/types/timelineView';

interface BucketMeta {
  isoDate: string;
  isWeekend: boolean;
  left: number;
  width: number;
}

interface BucketRendererProps extends BufferedTimeRange {
  children: (props: { bucketMeta: BucketMeta[] }) => React.ReactNode[];
}

export const BucketRenderer = ({
  bufferedTimeStart,
  bufferedTimeEnd,
  children
}: BucketRendererProps) => {
  const { getLeftOffsetFromDate } = useContext(HelpersContext);

  const steps = useMemo(() => {
    const start = startOfDay(bufferedTimeStart).getTime();
    const end = startOfDay(bufferedTimeEnd).getTime();
    const diff = differenceInDays(end, start);
    return Array.from({
      length: diff
    }).map((_, index) => {
      const leftEdge = addDays(start, index).getTime();
      return {
        isoDate: lightFormat(leftEdge, DATE_FNS_ISO_DATE),
        leftEdge: leftEdge,
        rightEdge: addDays(leftEdge, 1).getTime()
      };
    });
  }, [bufferedTimeEnd, bufferedTimeStart]);

  // FIXME: should be memoized due to performance issues
  const bucketMeta = steps.map(({ isoDate, leftEdge, rightEdge }) => {
    const left = getLeftOffsetFromDate(leftEdge);
    const width = getLeftOffsetFromDate(rightEdge) - left;

    return {
      isoDate,
      isWeekend: isWeekend(leftEdge),
      left,
      width
    };
  });

  const arrayChildren = Children.toArray(children({ bucketMeta }));

  return (
    <>
      {bucketMeta.map(({ isoDate, left, width }, index) => {
        return (
          <BucketContainer key={isoDate} $left={left} $width={width}>
            {arrayChildren[index]}
          </BucketContainer>
        );
      })}
    </>
  );
};

const BucketContainer = styled.div.attrs<{ $left: number; $width: number }>(
  ({ $left, $width }) => ({
    style: {
      left: $left,
      width: $width
    }
  })
)<{ $left: number; $width: number }>`
  position: absolute;
  height: 100%;
  overflow: hidden;
`;
