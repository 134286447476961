import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { AddEditBoardForm, ArchiveBoardModal } from '..';

const AddEditBoardModal = ({
  isOpen,
  isEditModal,
  toggleModal,
  handleSubmit,
  errorMessage,
  boardName,
  isPrivate,
  handleChange,
  handleCheckChange,
  toggleArchiveModal,
  archiveModalIsOpen,
  projectsInGroup,
  archiveGroupOnClick,
  userCanArchive,
  userCanTogglePrivacy,
  userCanEditPortfolio,
  disableSave,
  closeEditGroupModal,
  closeAddGroupModal,
  isCreatingGroup,
  boardId,
  setColor,
  tempColor,
  teamName
}) => (
  <div>
    {isEditModal ? (
      <ArchiveBoardModal
        isOpen={!!archiveModalIsOpen}
        toggle={toggleArchiveModal}
        projectsInGroup={projectsInGroup}
        archiveGroupOnClick={archiveGroupOnClick}
      />
    ) : null}
    <Modal
      isOpen={isOpen && !archiveModalIsOpen}
      toggle={toggleModal}
      className="add-edit-group-modal"
    >
      {errorMessage ? (
        <div className="add-edit-group-modal-error">
          <span>{errorMessage}</span>
        </div>
      ) : null}
      <div className="add-edit-group-modal-title">
        <h3>{isEditModal ? 'Portfolio Info' : 'New Portfolio'}</h3>
        {isEditModal ? (
          <i className="close-icon-dark" onClick={toggleModal} /> // Saves a team/board on clickout
        ) : (
          <i className="close-icon-dark" onClick={closeAddGroupModal} />
        )}
      </div>
      <AddEditBoardForm
        boardName={boardName}
        isPrivate={isPrivate}
        handleChange={handleChange}
        handleCheckChange={handleCheckChange}
        handleSubmit={handleSubmit}
        disableSave={disableSave}
        isEditForm={isEditModal}
        archiveModal={toggleArchiveModal}
        userCanArchive={userCanArchive}
        userCanTogglePrivacy={userCanTogglePrivacy}
        userCanEditPortfolio={userCanEditPortfolio}
        isCreatingGroup={isCreatingGroup}
        boardId={boardId}
        setColor={setColor}
        tempColor={tempColor}
        teamName={teamName}
        closeModal={isEditModal ? closeEditGroupModal : closeAddGroupModal}
      />
    </Modal>
  </div>
);

AddEditBoardModal.open = true;

AddEditBoardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEditModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  boardName: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckChange: PropTypes.func.isRequired,
  toggleArchiveModal: PropTypes.func,
  archiveModalIsOpen: PropTypes.bool,
  projectsInGroup: PropTypes.number,
  archiveGroupOnClick: PropTypes.func,
  userCanArchive: PropTypes.bool,
  userCanTogglePrivacy: PropTypes.bool,
  userCanEditPortfolio: PropTypes.bool
};

export default AddEditBoardModal;
