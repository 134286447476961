import { SimpleConfirmModal } from 'components/Modals';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import { RateGroup } from 'RatesModule/models/RateGroup';
import { deleteRateGroup } from 'RatesModule/ratesActionCreators';
import { useAppDispatch } from 'reduxInfra/hooks';

export const DeleteRateGroupModal = ({
  activeEntityType,
  onClose,
  rateGroup
}: {
  activeEntityType: EntityRateType;
  onClose: () => void;
  rateGroup: Pick<RateGroup, 'currency' | 'id' | 'name'>;
}) => {
  const dispatch = useAppDispatch();

  const handleConfirm = () => {
    dispatch(
      deleteRateGroup({
        rate_group_id: rateGroup.id,
        active_entity_type: activeEntityType
      })
    );
    onClose();
  };

  return (
    <SimpleConfirmModal
      body={
        <>
          Are you sure you want to <b>permanently delete</b> the{' '}
          <b>{rateGroup.name}</b> rate group? You cannot restore a deleted rate
          group.
        </>
      }
      confirmLabel="Yes"
      header="Delete Rate Group"
      isOpen
      onCancel={onClose}
      onConfirm={handleConfirm}
      shouldSetIsClosingOnClose={false}
    />
  );
};
