import { DEPENDENCY_TYPES } from 'components/Dependency/constants';
import { DependencyInfoArrayItem } from 'components/Dependency/types';
import { DependencyInfoBEArrayItem } from 'models/dependency';

// dependencies -> dependency_info
export const mapDependenciesToDependencyInfo = (
  dependencies: DependencyInfoBEArrayItem[]
): DependencyInfoArrayItem[] => {
  // handle legacy StartAndEndDate Dependency
  // split StartAndEndDate into StartDate and EndDate
  if (
    dependencies.length === 1 &&
    dependencies[0]?.dependency_type === DEPENDENCY_TYPES.StartAndEndDate
  ) {
    const dependency = dependencies[0];
    return [
      {
        dependency_type: DEPENDENCY_TYPES.StartDate,
        dependable_id: dependency.parent_id,
        dependable_type: dependency.parent_type
      },
      {
        dependency_type: DEPENDENCY_TYPES.EndDate,
        dependable_id: dependency.parent_id,
        dependable_type: dependency.parent_type
      }
    ];
  }

  return dependencies.map((dependency) => ({
    dependency_type: dependency.dependency_type,
    dependable_id: dependency.parent_id,
    dependable_type: dependency.parent_type
  }));
};
