import CloseIcon from 'icons/CloseIcon';
import {
  DependencyOption,
  DependencyOptionDate,
  CloseIconContainer,
  DependencyOptionContainer,
  DependencyStepHeader
} from './styles';
import Popover from 'components/Popover';
import EllipsisText from 'components/EllipsisText';
import {
  DEPENDENCY_STRINGS_DISPLAY,
  DEPENDENCY_STRINGS
} from 'appConstants/workload';

const DependencyOptions = ({
  disableDependency,
  dependency,
  dependencyStepItem,
  dependencyStepItemStartDate,
  dependencyStepItemEndDate,
  dependencyStepItemName,
  setDependencyStepItem,
  handleSetDependency,
  dependencyMenuIsOpen,
  dependencyPopoverTarget,
  closeDependencyMenu,
  isMilestoneDependencyOptions
}) => {
  const dependencyOptions = () => {
    if (isMilestoneDependencyOptions) {
      return [
        { label: 'No Dependency', value: null },
        {
          label: DEPENDENCY_STRINGS_DISPLAY[DEPENDENCY_STRINGS.START],
          value: DEPENDENCY_STRINGS.START,
          dates: dependencyStepItemStartDate
        },
        {
          label: DEPENDENCY_STRINGS_DISPLAY[DEPENDENCY_STRINGS.END],
          value: DEPENDENCY_STRINGS.END_TO_START,
          dates: dependencyStepItemEndDate
        }
      ];
    }
    return [
      { label: 'No Dependency', value: null },
      {
        label: DEPENDENCY_STRINGS_DISPLAY[DEPENDENCY_STRINGS.START],
        value: DEPENDENCY_STRINGS.START,
        dates: dependencyStepItemStartDate
      },
      {
        label: DEPENDENCY_STRINGS_DISPLAY[DEPENDENCY_STRINGS.END],
        value: DEPENDENCY_STRINGS.END,
        dates: dependencyStepItemEndDate
      },
      {
        label: DEPENDENCY_STRINGS_DISPLAY[DEPENDENCY_STRINGS.START_AND_END],
        value: DEPENDENCY_STRINGS.START_AND_END,
        dates:
          dependencyStepItemStartDate && dependencyStepItemEndDate
            ? dependencyStepItemStartDate + ' - ' + dependencyStepItemEndDate
            : null
      }
    ];
  };

  return (
    <Popover
      isOpen={dependencyMenuIsOpen}
      target={dependencyPopoverTarget}
      closePopover={() => {
        closeDependencyMenu();
        setDependencyStepItem(null);
      }}
      className="dependency-popover"
    >
      <DependencyStepHeader>
        <EllipsisText width={245}>{dependencyStepItemName}</EllipsisText>
        <CloseIconContainer
          onClick={() => {
            closeDependencyMenu();
            setDependencyStepItem(null);
          }}
        >
          <CloseIcon height="10" width="10" fill="#4f4f4f" />
        </CloseIconContainer>
      </DependencyStepHeader>
      <DependencyOptionContainer>
        {dependencyOptions().map(({ value, label, dates }) => (
          <DependencyOption
            isActive={dependency === value}
            disabled={
              (!dates && label !== 'No Dependency') || disableDependency
            }
            onClick={() => {
              handleSetDependency(
                value,
                dependencyStepItemStartDate,
                dependencyStepItemEndDate,
                dependencyStepItem
              );
              closeDependencyMenu();
            }}
            key={label}
            isDependencyStep
            data-for={disableDependency ? 'app-tooltip' : ''}
            data-tip={'Enter dates to set dependency.'}
            data-html
            style={{ height: 40 }}
          >
            <div>{label}</div>
            <DependencyOptionDate>{dates || ''}</DependencyOptionDate>
          </DependencyOption>
        ))}
      </DependencyOptionContainer>
    </Popover>
  );
};

export default DependencyOptions;
