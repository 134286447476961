import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SetColor = ({ fill = '#4f4f4f' }) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="transparent">
      <path
        d="M9.77941 14.5202L9.78021 14.5211C9.88592 14.64 9.94641 14.7866 9.94641 14.9464C9.94641 15.2928 9.66777 15.5714 9.32141 15.5714C5.87005 15.5714 3.07141 12.7728 3.07141 9.32141C3.07141 5.87005 5.87005 3.07141 9.32141 3.07141C12.8299 3.07141 15.5714 5.58637 15.5714 8.57141C15.5714 10.3653 14.1153 11.8214 12.3214 11.8214H10.9939C10.0953 11.8214 9.36891 12.5478 9.36891 13.4464C9.36891 13.8636 9.53304 14.2417 9.77941 14.5202Z"
        stroke={fill}
      />
      <ellipse cx="6.227" cy="8.4549" rx="1.28571" ry="1.28571" stroke={fill} />
      <ellipse
        cx="8.79841"
        cy="5.88349"
        rx="1.28571"
        ry="1.28571"
        stroke={fill}
      />
      <ellipse
        cx="12.6556"
        cy="8.4549"
        rx="1.28572"
        ry="1.28571"
        stroke={fill}
      />
    </SvgIcon>
  );
};

export default SetColor;
