import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateMemberBudget } from 'BudgetModule/actionCreators';
import { updatePhaseMembership } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import random from 'lodash/random';
import NumberFormat from 'react-number-format';
import { blurOnEnter } from 'appUtils/userInteractions';

const StyledText = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  width: 54px;
  height: 22px;
  background: #efecec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: auto;
  font-weight: 600;
  margin-top: -2px;
`;

const StyledInput = styled(NumberFormat)`
  font-weight: 600;
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  width: 70px;
  height: 25px;
  background: ${theme.colors.colorLightGray19};
  border-radius: 3px;
  text-align: right;
  text-align: center;
  border: none;
  position: relative;
  bottom: 3px;
`;

const PositionHoursCell = ({ row }) => {
  const dispatch = useDispatch();
  const {
    customRowProps: { showDemoSuggestions },
    position: { memberBudget, phaseMembership },
    phase: { id }
  } = row.original;
  const [hours, setHours] = useState(phaseMembership?.required_hours || '');
  const hoursInputRef = useRef(null);

  const handleHoursChange = (values) => {
    setHours(values.value);
  };

  const handleBlur = () => {
    if (!phaseMembership) return;

    if (+hours !== +phaseMembership.required_hours) {
      dispatch(
        updatePhaseMembership({
          phaseMembershipId: phaseMembership.id,
          required_hours: hours
        })
      );
    }
  };

  useEffect(() => {
    setHours(phaseMembership?.required_hours || '');
  }, [phaseMembership?.required_hours]);

  return (
    <StyledInput
      data-testid="fee-target-input"
      value={hours}
      placeholder={''}
      onValueChange={handleHoursChange}
      getInputRef={(ref) => (hoursInputRef.current = ref)}
      onKeyDown={blurOnEnter(hoursInputRef)}
      onBlur={handleBlur}
      decimalScale={2}
      allowNegative={false}
      thousandSeparator={true}
      isNumericString
      allowLeadingZeros={false}
      suffix={hours > 999 ? 'h' : ' h'}
    />
  );
};

export default PositionHoursCell;
