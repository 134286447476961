import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
// import moment from 'appUtils/momentConfig';
import { BaseCard } from './BaseCard';
import { ProgressChip } from './ProgressChip';
import moment from 'moment';

const formatDate = (date) => {
  const isThisYear = moment(date).year() === moment().year();
  const format = `MMM D${!isThisYear ? ` YYYY` : ''}`;
  return moment(date).format(format);
};

export const ScopeCard = ({ scope = {}, onClick }) => {
  const {
    description,
    note,
    percent_complete: percentComplete
    // scheduleStartDate,
    // scheduleEndDate
  } = scope;
  return (
    <BaseCard onClick={onClick}>
      <HeaderContainer>
        <LeftContainer>
          <ProgressChip value={percentComplete}></ProgressChip>
        </LeftContainer>
        {scope.schedule_start && scope.schedule_end && (
          <RightContainer>
            {formatDate(scope.schedule_start)} -{' '}
            {formatDate(scope.schedule_end)}
          </RightContainer>
        )}
      </HeaderContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
      {!!note && <NoteContainer>{note}</NoteContainer>}
    </BaseCard>
  );
};

const DescriptionContainer = styled.div`
  background-color: ${theme.colors.colorPureWhite};
  font-size: 15px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const HeaderContainer = styled.div`
  background-color: ${theme.colors.colorPureWhite};
  display: flex;
  color: ${theme.colors.colorSemiDarkGray1};
  height: 20px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

const NoteContainer = styled.div`
  background-color: ${theme.colors.colorPureWhite};
  font-size: 13px;
  font-weight: lighter;
  text-align: left;
  color: ${theme.colors.colorLightGray11};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const DatesContainer = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray9};
  position: absolute;
  right: 0;
  top: 0;
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
`;
