import * as appConstants from 'appConstants';
import * as constants from '../constants';
import createIntegrationReducer from './createIntegrationReducer';

export const initialState = {
  data: [],
  offset: 0,
  isFetching: false
};
export const syncLog = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_SYNC_LOG.TRIGGER: {
      const { isInitialFetch } = action.payload;

      return {
        ...(isInitialFetch ? initialState : state),
        isFetching: true
      };
    }
    case constants.FETCH_SYNC_LOG.SUCCESS: {
      const { isInitialFetch, offset } = action.payload.requestPayload;
      return {
        ...state,
        data: isInitialFetch
          ? payload.response
          : [...state.data, ...payload.response],
        offset: isInitialFetch ? 0 : offset,
        isFetching: false
      };
    }

    case constants.FETCH_SYNC_LOG.FAILURE: {
      return {
        ...state,
        isFetching: false
      };
    }

    default: {
      return state;
    }
  }
};

export default createIntegrationReducer(syncLog, initialState);
