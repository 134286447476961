import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useState } from 'react';
import {
  DependencyById,
  HandleAddDependency,
  HandleRemoveDependency,
  HandleUpdateDependency,
  UseDependencyFormState
} from '../types';
import uuid from 'uuid/v4';

const newDependencyItem = {
  baseType: null,
  targetType: null,
  baseItemType: null,
  targetItemType: null,
  baseItemId: null,
  targetItemId: null,
  action: null,
  deltaType: null,
  deltaDays: 0,
  isNew: true
};

export const useDependencyFormState: UseDependencyFormState = ({
  dependencies,
  baseItemProps
}) => {
  const [dependencyById, setDependencyById] = useState<DependencyById>({});

  useEffect(() => {
    const initialDependenciesById = keyBy(dependencies, 'dependencyId');
    setDependencyById(initialDependenciesById);
  }, [dependencies]);

  const handleAddDependency: HandleAddDependency = useCallback(() => {
    setDependencyById((prevDependencies) => {
      const newDependencyId = uuid();
      return {
        ...prevDependencies,
        [newDependencyId]: {
          ...newDependencyItem,
          ...baseItemProps,
          dependencyId: newDependencyId
        }
      };
    });
  }, [baseItemProps]);

  const handleUpdateDependency: HandleUpdateDependency = useCallback(
    ({ dependencyId, item }) => {
      setDependencyById((prevDependencies) => ({
        ...prevDependencies,
        [dependencyId]: { ...item }
      }));
    },
    []
  );

  const handleRemoveDependency: HandleRemoveDependency = useCallback(
    ({ dependencyId }: { dependencyId: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [dependencyId]: dependencyToBeDeleted, ...restDependencies } =
        dependencyById;

      setDependencyById(restDependencies);
    },
    [dependencyById]
  );

  return {
    dependencyById,
    addDependency: handleAddDependency,
    updateDependency: handleUpdateDependency,
    removeDependency: handleRemoveDependency
  };
};
