import styled from 'styled-components';
import { SmallOptionsContainer, SmallIconContainer } from './styles';
import {
  WORK_PLAN_CALENDAR_OPTION_TYPES,
  WORK_PLAN_OPTION_TEXT
} from 'appConstants/workload';
import theme from 'theme';
import { ValueOf } from 'type-fest';
import { Checkbox } from 'components/Checkbox';

interface WorkplanCalendarPanelCheckboxRowProps {
  checkboxRowValueProps: WorkplanCalendarOptionValues;
  handleChange: WorkplanCalendarHandleChange;
}

export type WorkplanCalendarHandleChange = (
  key: ValueOf<typeof WORK_PLAN_CALENDAR_OPTION_TYPES>,
  value?: boolean
) => void;

export interface WorkplanCalendarOptionValues {
  showAllDay: boolean;
  allDay: boolean;
  includeWeekends: boolean;
}

const WorkplanCalendarPanelCheckboxRow = ({
  checkboxRowValueProps,
  handleChange
}: WorkplanCalendarPanelCheckboxRowProps) => {
  const { showAllDay, allDay, includeWeekends } = checkboxRowValueProps;

  const handleClickAllDay = () => {
    handleChange(WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY, !allDay);
    handleChange(WORK_PLAN_CALENDAR_OPTION_TYPES.SET_WEEKENDS_PREDICT_CONSTANT);
  };

  const handleClickIncludeWeekends = () => {
    handleChange(
      WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS,
      !includeWeekends
    );
    handleChange(WORK_PLAN_CALENDAR_OPTION_TYPES.SET_WEEKENDS_PREDICT_CONSTANT);
  };

  return (
    <WorkplanCalendarCheckboxRowContainer>
      {showAllDay ? (
        <SmallOptionsContainer
          onClick={handleClickAllDay}
          data-testid="calendar-allday-checkbox"
        >
          <SmallIconContainer>
            <Checkbox checked={allDay} onChange={handleClickAllDay} />
          </SmallIconContainer>
          {WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY]}
        </SmallOptionsContainer>
      ) : (
        <SmallOptionsContainer
          onClick={handleClickIncludeWeekends}
          data-testid="calendar-includeweekends-checkbox"
        >
          <SmallIconContainer>
            <Checkbox
              checked={includeWeekends}
              onChange={handleClickIncludeWeekends}
            />
          </SmallIconContainer>
          {
            /* Not renamed to Include Days Off yet as that feature is not yet ready */
            WORK_PLAN_OPTION_TEXT[
              WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS
            ]
          }
        </SmallOptionsContainer>
      )}
    </WorkplanCalendarCheckboxRowContainer>
  );
};

const WorkplanCalendarCheckboxRowContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid ${theme.colors.colorPaleGray7};
`;

export default WorkplanCalendarPanelCheckboxRow;
