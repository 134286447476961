import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import KaratRight from 'icons/KaratRight';

/* ------------------------------- menu list -------------------------------- */

export const MenuList = ({
  children,
  heading,
  maxListHeight,
  ...menuListProps
}: HTMLAttributes<HTMLTableElement> & {
  /**
   * Heading of the menu.
   */
  heading?: ReactNode;

  /**
   * Sets a maximum height for the list of items, excluding the optional
   * heading.
   */
  maxListHeight?: number;
}) => {
  return (
    <Container {...menuListProps}>
      {heading && <Heading>{heading}</Heading>}
      <Scroller $maxHeight={maxListHeight}>{children}</Scroller>
    </Container>
  );
};

const Container = styled.table`
  color: ${({ theme }) => theme.colors.colorSemiDarkGray3};
  font-size: 13px;
  margin: 10px 0;
`;

const Heading = styled.caption`
  background-color: inherit;
  caption-side: top;
  color: inherit;
  font-weight: 600;
  padding: 6px 16px;
  white-space: nowrap;
`;

const Scroller = styled.tbody.attrs<{ $maxHeight?: number }>(
  ({ $maxHeight }) =>
    $maxHeight && {
      className: 'scrollbar',
      style: { maxHeight: $maxHeight }
    }
)<{ $maxHeight?: number }>`
  &.scrollbar {
    display: block;
    overflow-y: scroll;
  }
`;

/* ------------------------------- menu item -------------------------------- */

export const MenuItem = ({
  children,
  disabled,
  icon,
  isActive,
  selected,
  submenu,
  ...elementProps
}: HTMLAttributes<HTMLTableRowElement> & {
  [key: `data-${string}`]: boolean | string | undefined;
} & {
  /**
   * True if menu item is disabled
   */
  disabled?: boolean;

  /**
   * Optional icon which will be rendered to the left of the item text.
   *
   * This may also be a function that produces a value or `null`.
   *
   * If `undefined`, the icon will not be rendered and the label will take up
   * the entire row.
   */
  icon?: (() => ReactNode) | ReactNode;

  /**
   * Forces the item to be active. This, in turn, forces the submenu, if it is
   * defined, to remain open.
   */
  isActive?: boolean;

  /**
   * Renders the item in a selected state.
   */
  selected?: boolean;

  /**
   * Submenu of the menu item.
   */
  submenu?: ReactNode;
}) => {
  return (
    <Item
      {...elementProps}
      $disabled={disabled}
      $isActive={!disabled && isActive}
      $selected={selected}
    >
      {icon !== undefined && <ItemIcon>{icon}</ItemIcon>}
      <LabelAndKarat>
        {submenu ? (
          <Spacer>
            <Label>{children}</Label>
            <KaratRight />
          </Spacer>
        ) : (
          children
        )}
      </LabelAndKarat>
      {submenu && (
        <SubmenuExtension $isForcedOpen={!disabled && isActive}>
          <Submenu>{submenu}</Submenu>
        </SubmenuExtension>
      )}
    </Item>
  );
};

const Spacer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Label = styled.div`
  flex: 1;
`;

const SubmenuExtension = styled.td.attrs<{ $isForcedOpen?: boolean }>(
  ({ $isForcedOpen }) => ({ className: cn({ isOpen: $isForcedOpen }) })
)<{ $isForcedOpen?: boolean }>`
  display: none;
  cursor: initial;
  margin-top: -10px;
  position: absolute;
  padding-left: 6px;

  &.isOpen {
    display: flex;
  }
`;

const Submenu = styled.div`
  background-color: ${({ theme }) => theme.colors.colorPureWhite};
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
`;

const MenuItemPart = styled.td`
  padding: 6px 0;
  vertical-align: middle;

  // Space between the consecutive parts of a menu row.
  & + & {
    padding-left: 10px;
  }
`;

const ItemIcon = styled(MenuItemPart)`
  line-height: 0;

  > svg {
    // This assumes that menu icons are tightly cropped.
    height: 12px;
    width: 12px;
  }
`;

const LabelAndKarat = styled(MenuItemPart)`
  padding-right: 16px;
`;

const Item = styled.tr.attrs<{
  $disabled?: boolean;
  $isActive?: boolean;
  $selected?: boolean;
}>(({ $disabled, $isActive, $selected }) => ({
  className: cn({
    active: $isActive,
    disabled: $disabled,
    selected: $selected
  })
}))<{ $disabled?: boolean; $isActive?: boolean; $selected?: boolean }>`
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.55;
  }

  &:not(.disabled) {
    &:hover,
    &.active {
      background-color: ${({ theme }) => theme.colors.colorPaleGray7};

      > ${SubmenuExtension} {
        display: flex;
      }
    }

    &.selected > ${LabelAndKarat} {
      font-weight: 600;
    }
  }

  // This rule adds left padding to each row.
  & > :first-child {
    padding-left: 16px;
  }
`;
