import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import { serializeBudgetRecord } from 'BudgetModule/utils';
import { serializeId } from 'appUtils';

const { BUDGET_RECORD_DATA_TYPES: DATA_TYPES } = constants;

export const initialFilterState = {
  //    eg. { 'Position--1': [phaseIds]}
  //    eg. from workloadPlanner reducer
  //    when there is more nesting (grouped by date then member)
  //   {
  //     'july': ['july-5319', 'july-5312'],
  //     'july-5319': [workplanIds],
  //     'july-5312': [workplanIds]
  //   }
  ordersByGroup: {},
  // eg. [positionIds]
  topLevelOrder: [],
  isFetching: false,
  // keyed by eg. aggregate data type 'PositionPhase', then by position id
  totalCounts: {},
  intervalRecords: [],
  recordsByAggregateType: {
    [DATA_TYPES.PROJECT]: {}
  }
};

// TODO: update these values to match the new filterStates pattern
export const initialState = {
  isFetching: false,
  fetchedProjectOrder: [],
  fetchedAccountOrder: [],
  // These are projects that have data for the account
  projectOrderByAccountId: {},
  recordsByAggregateType: {},
  // All of the projects for given account ids (budget report account_ids filter)
  accountsProjectOrder: [],
  accountsTotalProjectCount: 0,
  totalCounts: {
    // Top level Project view list
    [DATA_TYPES.PROJECT]: 0,
    // Member view projects under accounts (by account ID)
    [DATA_TYPES.ACCOUNT_PROJECT]: {},
    // Top level accounts
    [DATA_TYPES.ACCOUNT]: 0
  },
  filterStates: {}, // keyed by filter id
  // This will only be used with filterStateId. Check if the action is passing filterStateId
  data: {}
};

const byId = (item) => item.id;
const byGroupId = (item) => serializeBudgetRecord(item);
const emptyArray = [];

const budgetRecords =
  ({ isInModal }) =>
  (state = initialState, action) => {
    const { type, payload } = action;

    // For keeping state separate for budget/variance report and budget modal reports,
    // since modal can be opened from the report
    // must be used in most cases !!
    const shouldUpdateState = (payload) =>
      (isInModal && payload.isInModal) || (!isInModal && !payload.isInModal);

    switch (type) {
      case constants.LOGOUT_USER: {
        return initialState;
      }

      case budgetConstants.CLOSE_BUDGET_MODAL: {
        return isInModal ? initialState : state;
      }

      case budgetConstants.FETCH_BUDGET_RECORDS.TRIGGER: {
        if (!shouldUpdateState(payload)) return state;

        const {
          flushProjectOrder,
          flushAccountOrder,
          flushTopLevelOrder,
          initial,
          params: { data_type },
          filterStateId
        } = action.payload;

        const nextState = { ...state };

        if (filterStateId) {
          const isInitialFilterState =
            initial || !state.filterStates[filterStateId];

          nextState.filterStates = {
            ...state.filterStates,
            [filterStateId]: {
              ...(isInitialFilterState
                ? initialFilterState
                : {
                    ...state.filterStates[filterStateId],
                    topLevelOrder: flushTopLevelOrder
                      ? []
                      : state.filterStates[filterStateId].topLevelOrder
                  }),
              isFetching: true
            }
          };

          return nextState;
        } else {
          return {
            ...state,
            isFetching: true,
            fetchedProjectOrder: flushProjectOrder
              ? []
              : state.fetchedProjectOrder,
            fetchedAccountOrder: flushAccountOrder
              ? []
              : state.fetchedAccountOrder,
            projectOrderByAccountId: flushAccountOrder
              ? {}
              : state.projectOrderByAccountId,
            recordsByAggregateType: initial ? {} : state.recordsByAggregateType
          };
        }
      }

      case budgetConstants.FETCH_BUDGET_RECORDS.FAILURE: {
        if (!shouldUpdateState(payload.requestPayload)) return state;

        const { filterStateId } = action.payload.requestPayload;

        if (filterStateId) {
          return {
            ...state,
            filterStates: {
              ...state.filterStates,
              [filterStateId]: {
                ...state.filterStates[filterStateId],
                isFetching: false
              }
            }
          };
        }

        return {
          ...state,
          isFetching: false
        };
      }

      case budgetConstants.FETCH_BUDGET_RECORDS.SUCCESS: {
        if (!shouldUpdateState(payload.requestPayload)) return state;

        const { records = [], total = 0 } = action.payload.response;
        const {
          flushProjectOrder,
          flushAccountOrder,
          params,
          limit,
          offset,
          filterStateId,
          filterStateModifier,
          isUtilizationReport,
          isProjectBreakdownView,
          isFetchingInterval
        } = action.payload.requestPayload;

        const { data_type, position_ids, account_ids, project_ids, phase_ids } =
          params;

        if (filterStateId) {
          if (filterStateModifier) {
            return filterStateModifier({ state, action, filterStateId });
          }
          const nextState = { ...state };
          const nextFilterState = {
            ...nextState.filterStates[filterStateId],
            isFetching: false
          };

          if (isFetchingInterval) {
            nextFilterState.intervalRecords = records;

            nextState.filterStates = {
              ...nextState.filterStates,
              [filterStateId]: nextFilterState
            };

            return nextState;
          } else {
            switch (data_type) {
              // Top level groupings
              case DATA_TYPES.PHASE:
              case DATA_TYPES.ACCOUNT: {
                const idToCheck =
                  data_type === DATA_TYPES.ACCOUNT ? 'account_id' : 'phase_id';

                nextFilterState.topLevelOrder = [
                  ...nextFilterState.topLevelOrder,
                  ...records.map((record) => record[idToCheck])
                ];

                nextFilterState.totalCounts = {
                  ...nextFilterState.totalCounts,
                  [data_type]: total
                };
                records.forEach((record) => {
                  const uid = serializeId({
                    ids: [record[idToCheck]],
                    itemType: data_type
                  });
                  nextState.data = {
                    ...nextState.data,
                    [uid]: record
                  };
                });
                break;
              }

              /**
               *  This is specifically for AccountProject grouping with multipel ids for Project breakdown view
               *  The data is not specific for a single ID
               *  If AccountProject needs to be used as sub level, use else {} or else if {}
               */
              case DATA_TYPES.ACCOUNT_PROJECT: {
                if (isUtilizationReport) {
                  // when called from Utilization report, it doesn't have topLevelOrder
                  // topLevelOrder is used in budgetRecords selector
                  nextFilterState.topLevelOrder = [
                    ...nextFilterState.topLevelOrder,
                    ...account_ids
                  ];
                  if (isProjectBreakdownView) {
                    const accountProjectsOrderByAccountUids = {};
                    const accountProjectsCountByAccountUids = {};
                    const nextData = {};

                    /**
                     *  Set the default values to an empty array and 0
                     *  If no data found on the BE, the result will be an empty array ergo necessary fields won't be
                     * created which will result in infinite loading because there is no value to read to determine
                     * whether it is fully loaded or not.
                     */
                    account_ids.forEach((id) => {
                      const uid = serializeId({
                        ids: [id],
                        itemType: DATA_TYPES.ACCOUNT
                      });

                      accountProjectsOrderByAccountUids[uid] = [];
                      accountProjectsCountByAccountUids[uid] = 0;
                    });

                    records.forEach((record) => {
                      const { account_id, project_id } = record;
                      const accountUid = serializeId({
                        ids: [account_id],
                        itemType: DATA_TYPES.ACCOUNT
                      });
                      const accountProjectUid = serializeId({
                        ids: [account_id, project_id],
                        itemType: DATA_TYPES.ACCOUNT_PROJECT
                      });
                      // the array from the response is ordered by sort params
                      // this only groups them by account ids and keep the order
                      accountProjectsOrderByAccountUids[accountUid].push(
                        project_id
                      );
                      accountProjectsCountByAccountUids[accountUid] += 1;
                      nextData[accountProjectUid] = record;
                    });

                    nextState.data = {
                      ...nextState.data,
                      ...nextData
                    };

                    nextFilterState.ordersByGroup = {
                      ...nextFilterState.ordersByGroup,
                      ...accountProjectsOrderByAccountUids
                    };

                    nextFilterState.totalCounts = {
                      ...nextFilterState.totalCounts,
                      [DATA_TYPES.ACCOUNT_PROJECT]: {
                        ...nextFilterState.totalCounts[
                          DATA_TYPES.ACCOUNT_PROJECT
                        ],
                        ...accountProjectsCountByAccountUids
                      }
                    };
                  } else {
                    const accountId = account_ids[0];
                    const upperLevelUid = serializeId({
                      itemType: DATA_TYPES.ACCOUNT,
                      ids: [accountId]
                    });

                    nextFilterState.ordersByGroup = {
                      ...nextFilterState.ordersByGroup,
                      [upperLevelUid]: [
                        ...(nextFilterState.ordersByGroup[upperLevelUid] || []),
                        ...records.map((record) => record.project_id)
                      ]
                    };

                    nextFilterState.totalCounts = {
                      ...nextFilterState.totalCounts,
                      [DATA_TYPES.ACCOUNT_PROJECT]: {
                        ...nextFilterState.totalCounts[
                          DATA_TYPES.ACCOUNT_PROJECT
                        ],
                        [upperLevelUid]: total
                      }
                    };

                    records.forEach((record) => {
                      const uid = serializeId({
                        itemType: data_type,
                        ids: [accountId, record.project_id]
                      });
                      nextState.data = {
                        ...nextState.data,
                        [uid]: record
                      };
                    });
                  }
                } else {
                  // TODO add when not used in project breakdown view with filterStateId
                }
                nextState.filterStates = {
                  ...nextState.filterStates,
                  [filterStateId]: nextFilterState
                };

                break;
              }

              // Top level order for Variance report role view
              case DATA_TYPES.POSITION: {
                nextFilterState.topLevelOrder = [
                  ...nextFilterState.topLevelOrder,
                  ...records.map((record) => record.position_id)
                ];
                nextFilterState.totalCounts = {
                  ...nextFilterState.totalCounts,
                  [data_type]: total
                };
                records.forEach((record) => {
                  const uid = serializeId({
                    ids: [record.position_id],
                    itemType: DATA_TYPES.POSITION
                  });
                  nextState.data = {
                    ...nextState.data,
                    [uid]: record
                  };
                });

                break;
              }

              // This is used as top level for Role view from Variance tab, on Budget modal
              case DATA_TYPES.POSITION_PROJECT: {
                nextFilterState.topLevelOrder = [
                  ...nextFilterState.topLevelOrder,
                  ...records.map((record) => record.position_id)
                ];
                nextFilterState.totalCounts = {
                  ...nextFilterState.totalCounts,
                  [data_type]: total
                };
                records.forEach((record) => {
                  const uid = serializeId({
                    ids: [record.position_id],
                    itemType: DATA_TYPES.POSITION_PROJECT
                  });
                  nextState.data = {
                    ...nextState.data,
                    [uid]: record
                  };
                });

                break;
              }

              // Sublevel rows
              case DATA_TYPES.ACCOUNT_POSITION:
              case DATA_TYPES.ACCOUNT_POSITION_PROJECT:
              case DATA_TYPES.ACCOUNT_POSITION_PHASE:
              case DATA_TYPES.ACCOUNT_POSITION_PHASE_ACTIVITY: {
                let idsToCheck;
                let upperLevelDataType;
                let upperLevelIdsToSerialize;
                let currentLevelIdsToSerialize;
                let idTypeToUse;

                switch (data_type) {
                  case DATA_TYPES.ACCOUNT_POSITION: {
                    idsToCheck = position_ids;
                    upperLevelDataType = isInModal
                      ? DATA_TYPES.POSITION_PROJECT
                      : DATA_TYPES.POSITION;
                    upperLevelIdsToSerialize = [idsToCheck[0]];
                    currentLevelIdsToSerialize = ['position_id', 'account_id'];
                    idTypeToUse = 'account_id';
                    break;
                  }

                  /**
                   *  When viewing Variance report in modal, this will be used for both member row under position,
                   * and project row under member row. Data should be identical since there is no difference between
                   * those two rows since budget modal is always about 1 project.
                   *  BudgetReportTable need to read different data types in certain situations.
                   */
                  case DATA_TYPES.ACCOUNT_POSITION_PROJECT: {
                    idsToCheck = isInModal ? position_ids : account_ids;
                    upperLevelDataType = isInModal
                      ? DATA_TYPES.POSITION_PROJECT
                      : DATA_TYPES.ACCOUNT_POSITION;
                    upperLevelIdsToSerialize = isInModal
                      ? [idsToCheck[0]]
                      : [position_ids[0], idsToCheck[0]];
                    currentLevelIdsToSerialize = [
                      'position_id',
                      'account_id',
                      'project_id'
                    ];
                    idTypeToUse = isInModal ? 'account_id' : 'project_id';
                    break;
                  }
                  case DATA_TYPES.ACCOUNT_POSITION_PHASE: {
                    idsToCheck = project_ids;
                    upperLevelDataType = DATA_TYPES.ACCOUNT_POSITION_PROJECT;
                    upperLevelIdsToSerialize = [
                      position_ids[0],
                      account_ids[0],
                      idsToCheck[0]
                    ];
                    currentLevelIdsToSerialize = [
                      'position_id',
                      'account_id',
                      'project_id',
                      'phase_id'
                    ];
                    idTypeToUse = 'phase_id';
                    break;
                  }
                  case DATA_TYPES.ACCOUNT_POSITION_PHASE_ACTIVITY: {
                    idsToCheck = phase_ids;
                    upperLevelDataType = DATA_TYPES.ACCOUNT_POSITION_PHASE;
                    upperLevelIdsToSerialize = [
                      position_ids[0],
                      account_ids[0],
                      project_ids[0],
                      idsToCheck[0]
                    ];
                    currentLevelIdsToSerialize = [
                      'position_id',
                      'account_id',
                      'project_id',
                      'phase_id',
                      'activity_id'
                    ];
                    idTypeToUse = 'activity_id';
                    break;
                  }
                  default:
                    break;
                }

                if (idsToCheck?.length) {
                  // Load sub level for one top level id
                  const upperLevelId = idsToCheck[0];
                  const upperLevelUid = serializeId({
                    itemType: upperLevelDataType,
                    ids: upperLevelIdsToSerialize
                  });

                  nextFilterState.ordersByGroup = {
                    ...nextFilterState.ordersByGroup,
                    [upperLevelUid]: [
                      ...(nextFilterState.ordersByGroup[upperLevelUid] ||
                        emptyArray),
                      ...records.map((record) => record[idTypeToUse])
                    ]
                  };

                  nextFilterState.totalCounts = {
                    ...nextFilterState.totalCounts,
                    [data_type]: {
                      ...nextFilterState.totalCounts[data_type],
                      [upperLevelId]: total
                    }
                  };

                  records.forEach((record) => {
                    const uid = serializeId({
                      ids: currentLevelIdsToSerialize.map(
                        (type) => record[type]
                      ),
                      itemType: data_type
                    });
                    nextState.data = {
                      ...nextState.data,
                      [uid]: record
                    };
                  });
                }
                break;
              }

              default:
                break;
            }

            nextState.filterStates = {
              ...nextState.filterStates,
              [filterStateId]: nextFilterState
            };

            return nextState;
          }
        }
        // non-filterStateId reducer
        else {
          const nextState = {
            ...state,
            isFetching: false,
            recordsByAggregateType: {
              ...state.recordsByAggregateType,
              [data_type]: {
                ...state.recordsByAggregateType[data_type],
                ...keyBy(records, byGroupId)
              }
            }
          };

          switch (data_type) {
            case DATA_TYPES.PROJECT:
              nextState.fetchedProjectOrder = flushProjectOrder
                ? records.map((record) => record.project_id)
                : Array.from(
                    new Set([
                      ...state.fetchedProjectOrder,
                      ...(records.length
                        ? records.map((record) => record.project_id)
                        : [])
                    ])
                  );

              nextState.totalCounts = {
                ...state.totalCounts,
                [DATA_TYPES.PROJECT]: total
              };
              break;

            case DATA_TYPES.ACCOUNT:
              if (params.useTeamOrder) {
                nextState.fetchedAccountOrder = flushAccountOrder
                  ? params.account_ids
                  : Array.from(
                      new Set([
                        ...state.fetchedAccountOrder,
                        ...params.account_ids
                      ])
                    );
              } else {
                nextState.fetchedAccountOrder = flushAccountOrder
                  ? records.map((record) => record.account_id)
                  : Array.from(
                      new Set([
                        ...state.fetchedAccountOrder,
                        ...records.map((record) => record.account_id)
                      ])
                    );
              }
              nextState.totalCounts = {
                ...state.totalCounts,
                [DATA_TYPES.ACCOUNT]: total
              };
              break;

            case DATA_TYPES.ACCOUNT_PROJECT:
              // This should only be called with one account ID
              if (params.account_ids) {
                nextState.projectOrderByAccountId = {
                  ...state.projectOrderByAccountId,
                  ...params.account_ids.reduce((acc, cur) => {
                    acc[cur] = Array.from(
                      new Set([
                        ...(state.projectOrderByAccountId[cur] || []),
                        ...records.map((record) => record.project_id)
                      ])
                    );
                    return acc;
                  }, {})
                };

                nextState.totalCounts = {
                  ...state.totalCounts,
                  [DATA_TYPES.ACCOUNT_PROJECT]: {
                    ...state.totalCounts[DATA_TYPES.ACCOUNT_PROJECT],
                    [params.account_ids[0]]: total
                  }
                };
              } else if (isInModal) {
                // when modal, treat same as Account case above
                nextState.fetchedAccountOrder = flushAccountOrder
                  ? records.map((record) => record.account_id)
                  : Array.from(
                      new Set([
                        ...state.fetchedAccountOrder,
                        ...records.map((record) => record.account_id)
                      ])
                    );

                nextState.totalCounts = {
                  ...state.totalCounts,
                  [DATA_TYPES.ACCOUNT]: total
                };
              }
              break;

            default:
              break;
          }

          return nextState;
        }
      }

      case budgetConstants.FETCH_PROJECT_BUDGET_RECORD.SUCCESS: {
        if (!shouldUpdateState(payload.requestPayload)) return state;

        const { records = [] } = action.payload.response;
        const { filterStateId = '' } = payload.requestPayload;
        const dataType = DATA_TYPES.PROJECT;
        const nextState = { ...state };

        nextState.filterStates = {
          ...nextState.filterStates,
          [filterStateId]: {
            ...nextState.filterStates[filterStateId],
            recordsByAggregateType: {
              ...nextState.filterStates[filterStateId]?.recordsByAggregateType,
              [dataType]: {
                ...nextState.filterStates[filterStateId]
                  ?.recordsByAggregateType[dataType],
                ...keyBy(records, byGroupId)
              }
            }
          }
        };

        return nextState;
      }

      case budgetConstants.CLEAR_BUDGET_RECORDS_STATE: {
        const { filterStateId } = action.payload;

        if (filterStateId) {
          const nextFilterStates = { ...state.filterStates };
          delete nextFilterStates[filterStateId];

          return {
            ...state,
            filterStates: nextFilterStates
          };
        }

        return initialState;
      }

      default:
        return state;
    }
  };

export default budgetRecords;
