import React from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors';
import styled from 'styled-components';
import cn from 'classnames';
import MemberInitials from 'views/memberDisplay/MemberInitials';

const StyledMemberCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const MemberCell = ({ row }) => {
  const { member } = row;
  const me = useSelector(getMe);

  if (!member) return null;

  return (
    <StyledMemberCell>
      <MemberInitials
        member={member}
        classes={cn('regular-member-no-hover', {
          'logged-member-no-hover': member.account.id === me.id
        })}
        idx={'idx-placeholder'}
        isOnTaskModal
        size="small"
      />
    </StyledMemberCell>
  );
};

export default MemberCell;
