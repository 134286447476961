import styled from 'styled-components';
import theme from 'theme';
export const BaseToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
`;

// top + left will be set by updateHoverMenu
export const FloatingToolbar = styled.div`
  position: absolute;
  margin: -6px 7px 7px 0px;
  z-index: 10000;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  transition: opacity 0.75s;
  background-color: white;

  ${BaseToolbar} {
    box-shadow: 0 1px 4px 0 rgba(173, 173, 173, 0.5);
  }
`;

export const StaticToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding: 5px 13px;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0 1px 4px 0 rgba(173, 173, 173, 0.5);
`;

export const FormatButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  g,
  path {
    fill: ${({ isActive, theme }) => isActive && theme.colors.colorRoyalBlue};
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.colorRoyalBlue};
    }

    g {
      fill: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }
`;

export const NoteMenuIconsContainer = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoteMenuItem = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 15px;
  margin: 2px 0px;

  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    &.delete-note-menu-button {
      .link-div {
        color: ${theme.colors.colorDeleteRed};
      }
      path {
        fill: ${theme.colors.colorDeleteRed};
      }
    }
  }
`;
