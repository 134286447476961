import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
const ScopeTab = lazy(() => import('./ScopeTab'));

const LoadBudgetContainer = () => (
  <DynamicModuleLoader modules={[getBudgetModule()]}>
    <Suspense fallback={<div />}>
      <ScopeTab isProjectScopeTable />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadBudgetContainer;
