import { useEffect } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { getSelectedTeamId, getSelectedProject } from 'selectors';
import ProjectInfoTab from './projectInfo/ProjectInfoTab';
import { fetchRegions } from 'SettingsModule/actionCreators/settings/region';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import {
  fetchLocations,
  fetchEntityLocations
} from 'SettingsModule/actionCreators/settings/location';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';

const ProjectInfoTabContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const project = useAppSelector(getSelectedProject);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchRegions({ teamId }));
      dispatch(fetchOffices({ teamId }));
      dispatch(fetchLocations({ teamId }));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (teamId && project) {
      dispatch(
        fetchEntityLocations({
          teamId,
          entityId: project.id,
          entityType: ENTITY_TYPES.project
        })
      );

      dispatch(
        fetchEntityOptions({
          team_id: teamId,
          entity_id: project.id,
          entity_type: ENTITY_TYPES.project
        })
      );
    }
  }, [dispatch, project, teamId]);

  return (
    <DynamicModuleLoader modules={[getSettingsModule()]}>
      <div style={{ width: '100%' }}>
        <ProjectInfoTab />
      </div>
    </DynamicModuleLoader>
  );
};

export default ProjectInfoTabContainer;
