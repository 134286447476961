import { TaskWithPlanOverlap } from '../../redux/types';
import { TaskListItem } from './TaskListItem';
import { useWorkPlanForm } from '../../WorkPlanFormProvider';
import {
  EDIT_WORK_PLANS_TIP,
  REMOVE_TASK_WORK_PLANS_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useWorkPlanTasks } from '../../hooks/useWorkPlanTasks';

export interface TaskListProps {
  onClickTask?: (task: TaskWithPlanOverlap) => void;
  onDeleteTask?: (task: TaskWithPlanOverlap) => void;
  onMarkComplete?: (task: TaskWithPlanOverlap) => void;
  onMatchWorkPlanDates?: (task: TaskWithPlanOverlap) => void;
  onRemoveTask?: (task: TaskWithPlanOverlap) => void;
  tasks: Array<TaskWithPlanOverlap>;
  tasksHours?: ReturnType<typeof useWorkPlanTasks>['tasksHours'];
}

export const TaskList = ({
  onClickTask,
  onDeleteTask,
  onMarkComplete,
  onMatchWorkPlanDates,
  onRemoveTask,
  tasksHours,
  tasks
}: TaskListProps) => {
  const handleClickTask = (task: TaskWithPlanOverlap) => {
    onClickTask && onClickTask(task);
  };

  const handleDeleteTask = (task: TaskWithPlanOverlap) => {
    onDeleteTask && onDeleteTask(task);
  };

  const handleMarkComplete = (task: TaskWithPlanOverlap) => {
    onMarkComplete && onMarkComplete(task);
  };

  const handleMatchWorkPlanDates = (task: TaskWithPlanOverlap) => {
    onMatchWorkPlanDates && onMatchWorkPlanDates(task);
  };

  const handleRemoveTask = (task: TaskWithPlanOverlap) => {
    onRemoveTask && onRemoveTask(task);
  };

  const {
    permissions: { canRemoveWorkplanTask, canEditWorkplan }
  } = useWorkPlanForm();

  return (
    <>
      {tasks.map((task) => (
        <TaskListItem
          key={task.id}
          task={task}
          taskWorkPlanHours={tasksHours?.get(task.id)?.hours}
          handleClickTask={handleClickTask}
          handleMarkComplete={handleMarkComplete}
          handleMatchWorkPlanDates={handleMatchWorkPlanDates}
          handleRemoveTask={handleRemoveTask}
          handleDeleteTask={handleDeleteTask}
          disabledOptions={{
            matchWorkPlanDates: {
              disabled: !canEditWorkplan,
              disabledTooltip: EDIT_WORK_PLANS_TIP
            },
            removeFromWorkPlan: {
              disabled: !canRemoveWorkplanTask,
              disabledTooltip: REMOVE_TASK_WORK_PLANS_TIP
            }
          }}
        />
      ))}
    </>
  );
};
