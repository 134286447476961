import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { connect } from 'react-redux';
import { deleteTaskAttachment, deleteProjectAttachment } from 'actionCreators';
import { CommentControls, MentionInput } from 'views';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import {
  getMentionableTeamMembers,
  getAuth,
  getMe,
  makeGetProjectManagersByAccountId,
  makeGetProjectBasicInfoById,
  getAttachmentHash
} from 'selectors';
import { FILE_STATUS } from 'appConstants';

const DragOverArea = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.3;
  z-index: 1;
`;

class CommentInputContainer extends React.Component {
  state = {
    mentionedMembers: [],
    isDraggingOver: false,
    dataUrlHash: {}
  };

  trackMentionedMembers = (memberId, memberName) => {
    const wasMemberAlreadyMentioned = this.state.mentionedMembers.find(
      (name) => name === memberName
    );

    if (!wasMemberAlreadyMentioned) {
      this.setState((prevState) => ({
        mentionedMembers: [...prevState.mentionedMembers, memberName]
      }));
    }
  };

  parseCommentForMentions = (string) => {
    /*
      when a user mentions a member, they are added to state
      as we type, we check to see that those members have not been deleted
      unfortunately library provides onAdd hook, but can't track removals
      this costs nothing when a member has never been mentioned
      and costs this.state.mentionedMembers.length * commentLength after someone has been mentioned
    */

    const stillMentioned = this.state.mentionedMembers.filter((memberName) => {
      return string.indexOf(`<@[${memberName}]`) > -1;
    });

    const didMemberGetDeleted =
      stillMentioned.length !== this.state.mentionedMembers.length;
    if (didMemberGetDeleted) {
      this.setState({ mentionedMembers: stillMentioned });
    }

    return stillMentioned;
  };

  setInputRowRef = (element) => {
    this.inputRow = element;
  };

  deleteAttachment = (e, attachmentId) => {
    const {
      isOnTaskModal,
      deleteTaskAttachment,
      deleteProjectAttachment,
      removeAttachment
    } = this.props;
    e.stopPropagation();

    if (isOnTaskModal) {
      deleteTaskAttachment(attachmentId);
    } else {
      deleteProjectAttachment(attachmentId);
    }
    removeAttachment && removeAttachment(attachmentId);
  };

  handleFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = [];
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          files.push(file);
        }
      }
    } else {
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        files.push(file);
      }
    }
    // eg for showing thumbnails
    // files.map(file => {
    //   if (file.type.includes('image')) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //       this.setState({
    //         dataUrlHash: {
    //           ...this.state.dataUrlHash,
    //           [file.name]: reader.result
    //         }
    //       });
    //     };
    //   }
    // });
    this.props.handleFiles(files);
    this.setState({ isDraggingOver: false });
    if (!this.props.isExpanded) {
      this.props.onClick();
    }
  };

  onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.isDraggingOver) {
      this.setState({ isDraggingOver: true });
    }
  };

  // Necessary and prevents bug like behaviour
  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDraggingOver: false });
  };

  render() {
    const {
      inputRef,
      attachments,
      me,
      className,
      isExpanded,
      isEditingComment,
      commentType,
      commentBody,
      onCancel,
      mentionableTeamMembers,
      suggestionListLeft,
      onClick,
      onChange,
      onSubmit,
      onKeyPress,
      unsavedFiles,
      handleFiles,
      removeFile,
      project,
      isReplyOpen,
      projectInfo,
      projectManagersByAccountId,
      attachmentHash,
      uploadFileDisabled = false,
      isDisabled,
      placeholders
    } = this.props;

    const isPersonalProject = project?.is_personal;
    const isPersonalTask = projectInfo?.is_personal;
    const isOnPersonalProject = isPersonalProject || isPersonalTask;

    return (
      <div
        className={cn(
          'comment-input-container',
          { 'border-blue': isExpanded || this.state.isDraggingOver },
          className
        )}
        onDragEnter={!uploadFileDisabled ? this.onDragEnter : undefined}
      >
        {this.state.isDraggingOver && (
          <DragOverArea
            onDrop={this.handleFileDrop}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
          />
        )}
        <div
          className={cn('comment-input input', {
            'reply-border-blue': isReplyOpen
          })}
          ref={this.setInputRowRef}
        >
          <MemberInitials
            addNewNote={true}
            member={me}
            isManager={!!projectManagersByAccountId[me.account_id]}
            classes={cn('regular-member-no-hover selected', {
              'logged-member-no-hover': me.id
            })}
            projectId={project?.id}
          />

          <MentionInput
            inputRef={inputRef}
            commentType={commentType}
            commentBody={commentBody}
            mentionableTeamMembers={mentionableTeamMembers}
            suggestionListLeft={suggestionListLeft}
            onChange={onChange}
            onClick={onClick}
            onKeyPress={onKeyPress}
            onAddMention={this.trackMentionedMembers}
            isOnPersonalProject={isOnPersonalProject}
            handleFiles={handleFiles}
            projectManagersByAccountId={projectManagersByAccountId}
            isDisabled={isDisabled}
            placeholders={placeholders}
          />
        </div>
        <div>
          {attachments &&
            attachments.map((attachment) => {
              const attachmentInfo =
                attachmentHash[attachment.id] || attachment;
              if (attachmentInfo.deleted) {
                return null;
              }
              return (
                <div
                  className="file-attachment-container"
                  onClick={() => {
                    if (attachmentInfo.file_url) {
                      window.open(attachmentInfo.file_url, '_blank');
                    }
                  }}
                  key={attachmentInfo.id}
                >
                  <i className="file-type" />
                  <span
                    className="file-name"
                    data-for="app-tooltip"
                    data-effect="solid"
                    data-tip={
                      attachmentInfo?.status === FILE_STATUS.PENDING
                        ? 'Scanning for Malware'
                        : ''
                    }
                  >
                    {attachmentInfo.file_name}
                  </span>
                  <div
                    className="close-circle"
                    onClick={(e) => this.deleteAttachment(e, attachmentInfo.id)}
                  >
                    <i className="delete-file" />
                  </div>
                </div>
              );
            })}
          {unsavedFiles &&
            unsavedFiles.map((file, index) => {
              const attachmentInfo = attachmentHash[file.id] || file;
              return (
                <div className="file-attachment-container" key={index}>
                  <i className="file-type" />
                  <span className="file-name">{attachmentInfo.name}</span>
                  <div
                    className="close-circle"
                    onClick={() => removeFile(attachmentInfo)}
                  >
                    <i className="delete-file" />
                  </div>
                </div>
              );
            })}
        </div>
        <CommentControls
          me={me}
          isExpanded={isExpanded}
          isEditingComment={isEditingComment}
          commentBody={commentBody}
          mentionedMembers={this.parseCommentForMentions(commentBody)}
          onCancel={onCancel}
          onSubmit={onSubmit}
          handleFiles={handleFiles}
          unsavedFiles={unsavedFiles}
          attachedFiles={attachments}
          isOnPersonalProject={isOnPersonalProject}
          uploadFileDisabled={uploadFileDisabled}
        />
      </div>
    );
  }
}
const projectIdGetter = (state, ownProps) => ownProps.project?.id;
const makeMapStateToProps = () => {
  const getProjectInfo = makeGetProjectBasicInfoById();
  const getProjectManagersByAccountId = makeGetProjectManagersByAccountId({
    projectIdGetter
  });
  const mapStateToProps = (state, ownProps) => ({
    auth: getAuth(state),
    me: getMe(state),
    mentionableTeamMembers: getMentionableTeamMembers(state),
    isOnTaskModal: state.taskDetail.taskDetailModalOpen,
    projectInfo: getProjectInfo(state),
    projectManagersByAccountId: getProjectManagersByAccountId(state, ownProps),
    attachmentHash: getAttachmentHash(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  deleteTaskAttachment,
  deleteProjectAttachment
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  deleteTaskAttachment: (attachmentId) =>
    dispatchProps.deleteTaskAttachment(stateProps.auth.token, attachmentId),
  deleteProjectAttachment: (attachmentId) =>
    dispatchProps.deleteProjectAttachment(stateProps.auth.token, attachmentId)
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CommentInputContainer);
