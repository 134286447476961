import styled from 'styled-components';
import { TableCellProps } from '../../types';
import { isRateGroupCell } from '../../utils';

export const IndicatorHeaderCell = (props: TableCellProps) => {
  if (!isRateGroupCell(props)) return null;

  const {
    row: {
      original: {
        rateGroup: { archived_at: archivedAt, is_default: isDefault }
      }
    }
  } = props;

  return <Cell>{isDefault ? 'Default' : archivedAt ? 'Archived' : null}</Cell>;
};

const Cell = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  height: 100%;
  line-height: 1;
  margin-top: -5px;
`;
