import React from 'react';
import SlateEditor from 'NoteModule/components/noteSettings/SlateEditor';
import { html } from 'appUtils/slate';

const NoteActivityBody = ({ displayData }) => (
  <div className="note-activity-body">
    <div className="note-title">{displayData.note_title}</div>
    <div className="note-body">
      {displayData.note_body && (
        <SlateEditor value={html.deserialize(displayData.note_body)} readOnly />
      )}
    </div>
  </div>
);

export default NoteActivityBody;
