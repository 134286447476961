import styled from 'styled-components';

export const EmptyGroupCell = () => <Cell>Add a rate to this rate group.</Cell>;

const Cell = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  display: flex;
  font-style: italic;
  height: 100%;
`;
