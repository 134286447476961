import { MemberAvailabilityTimelineInitializer } from './MemberAvailabilityTimelineInitializer';
import { useMemberAvailabilityFilterContext } from './filterConfig';
import {
  OpenSideFilterOption,
  SideFilter
} from 'FilterModule/components/SideFilter';
import { forwardRef, useImperativeHandle } from 'react';

export interface MemberAvailabilityTimelineFilterRef {
  updateFilter: ReturnType<
    typeof useMemberAvailabilityFilterContext
  >['currentFilter']['update'];
}

/**
 * This component requires FilterContextProvider in its parent component
 */
export const MemberAvailabilityTimelineWithFilter =
  forwardRef<MemberAvailabilityTimelineFilterRef>(
    function MemberAvailabilityTimelineWithFilter(_, ref) {
      const { currentFilter } = useMemberAvailabilityFilterContext();

      useImperativeHandle(
        ref,
        () => ({
          updateFilter: currentFilter.update
        }),
        [currentFilter.update]
      );

      return (
        <>
          <SideFilter
            variant="MemberAvailabilitySideFilter"
            shouldUsePortal={false}
          />
          <MemberAvailabilityTimelineInitializer
            accountIds={currentFilter.account_ids}
            sidebarHeader={
              <OpenSideFilterOption
                style={{
                  boxShadow: 'none'
                }}
              />
            }
          />
        </>
      );
    }
  );
