import * as constants from 'SettingsModule/constants/discipline';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { createAction } from '@reduxjs/toolkit';
import { ValueOf } from 'type-fest';

export const fetchDisciplines = createAction(
  constants.FETCH_DISCIPLINES.TRIGGER,
  ({ meta, ...payload }: FetchDisciplinesInitialParams) => ({
    meta,
    payload
  })
);

export const createDiscipline = createAction<CreateDisciplineInitialParams>(
  constants.CREATE_DISCIPLINE.TRIGGER
);

export const updateDiscipline = createAction<UpdateDisciplineInitialParams>(
  constants.UPDATE_DISCIPLINE.TRIGGER
);

export const deleteDiscipline = createAction<DeleteDisciplineInitialParams>(
  constants.DELETE_DISCIPLINE.TRIGGER
);

export const updateDisciplineEntities =
  createAction<UpdateDisciplineEntitiesInitialParams>(
    constants.UPDATE_DISCIPLINE_ENTITIES.TRIGGER
  );

interface FetchDisciplinesInitialParams {
  teamId: number;
  meta?: IMeta;
}

interface CreateDisciplineInitialParams {
  teamId: number;
  name: string;
}

interface UpdateDisciplineInitialParams {
  id: number;
  name: string;
}

interface DeleteDisciplineInitialParams {
  id: number;
}

interface UpdateDisciplineEntitiesInitialParams {
  id: number;
  addEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
  removeEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
}
