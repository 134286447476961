import React from 'react';
import styled from 'styled-components';
import { getMonthAbbrev, daysOfWeekAbbrev, toPx } from './utils';
import { DateTile } from './DateTile';
import { HeaderTile } from './HeaderTile';
import { useScheduleCalendar } from './ScheduleCalendarProvider';
import theme from 'theme';
import BaseLeftIcon from 'icons/calendar-arrow-left.svg';
import BaseRightIcon from 'icons/calendar-arrow-right.svg';

/**
 *
 * @typedef {Object} MonthViewProps
 * @property {Date} currentDate
 */

/**
 *
 * @param {MonthViewProps} props
 */
export const MonthView = ({ currentDate = new Date(), index = 0 }) => {
  const { calendar, viewType, renderExtraItem, renderExtraFooter } =
    useScheduleCalendar();
  const monthAbbrev = getMonthAbbrev(currentDate);
  const dateRows = calendar.getDatesToRenderByMonth(currentDate);

  const hasYearTitle = index === 0;

  const hasExtraItem = !!renderExtraItem;
  const hasFooter = !!renderExtraFooter;

  const handleClickPrev = (event) => {
    calendar.prev();
  };
  const handleClicNext = (event) => {
    calendar.next();
  };

  const shouldShowNav = viewType === 'month';

  return (
    <MonthViewContainer>
      <CalendarContent>
        <ColumnContainer>
          <TitleContainer>
            {shouldShowNav && (
              <NavButton
                data-testid="calendar-month-nav-prev"
                onClick={handleClickPrev}
              >
                <LeftIcon />
              </NavButton>
            )}
            <TitleTextContainer>
              <TitleText data-testid="calendar-title" viewType={viewType}>
                {monthAbbrev.toUpperCase()}
                {hasYearTitle && ` ${currentDate.getFullYear()}`}
              </TitleText>
            </TitleTextContainer>
            {shouldShowNav && (
              <NavButton
                data-testid="calendar-month-nav-next"
                onClick={handleClicNext}
              >
                <RightIcon />
              </NavButton>
            )}
          </TitleContainer>
          <CalendarHeader>
            {daysOfWeekAbbrev.map((day) => (
              <HeaderTile key={day}>{day[0].toUpperCase()}</HeaderTile>
            ))}
          </CalendarHeader>
          {hasExtraItem && <ExtraColumnContainer></ExtraColumnContainer>}
          <CalendarBody>
            {dateRows.map((row) => (
              <WeekRow key={`weekrow-${row[0].date}`}>
                {row.map((dateObj) => (
                  <DateTile key={dateObj.date} dateObj={dateObj}></DateTile>
                ))}
              </WeekRow>
            ))}
          </CalendarBody>
          {hasFooter && <CalendarFooter></CalendarFooter>}
        </ColumnContainer>
        {hasExtraItem && (
          <ExtraColumnContainer>
            <Spacer></Spacer>
            <ExtraHeader></ExtraHeader>
            <ExtraBody>
              {dateRows.map((row, index) => (
                <ExtraTile key={`weekrow-extra-${row[0].date}`}>
                  {renderExtraItem({
                    index,
                    year: currentDate.getFullYear(),
                    month: currentDate.getMonth(),
                    dateObjArray: row
                  })}
                </ExtraTile>
              ))}
            </ExtraBody>
            {hasFooter && (
              <ExtraFooter>
                {renderExtraFooter({
                  year: currentDate.getFullYear(),
                  month: currentDate.getMonth(),
                  dateRows: dateRows
                })}
              </ExtraFooter>
            )}
          </ExtraColumnContainer>
        )}
      </CalendarContent>
    </MonthViewContainer>
  );
};

const MonthViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ExtraColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExtraHeader = styled.div`
  width: 100%;
  height: ${({ theme }) => toPx(theme.size.header.height)};
`;

const ExtraFooter = styled.div`
  width: 100%;
  height: ${({ theme }) => toPx(theme.size.footer.height)};
`;

const ExtraTile = styled.div`
  flex: 1 0 0;
  min-height: 0;
  display: flex;
`;

const ExtraBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ theme }) => toPx(theme.size.title.height)};
`;

const TitleTextContainer = styled.div`
  min-width: 110px;
  text-align: center;
`;

const TitleText = styled.div`
  font-size: ${({ viewType }) => (viewType === 'month' ? '20px' : '14px')};
  font-weight: 600;
`;
const borderColor = theme.colors.colorPaleGray5;

const WeekRow = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-bottom: 0.3px solid ${borderColor};

  & > div {
    border-right: 0.3px solid ${borderColor};
    &:last-child {
      border-right: none;
    }
  }
`;

const CalendarContent = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${({ theme }) => toPx(theme.size.calendarContent.width)};
  min-height: ${({ theme }) => toPx(theme.size.calendarContent.minHeight)};
  position: relative;
`;

const CalendarHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ theme }) => toPx(theme.size.header.height)};
  border: 0.7px solid ${borderColor};
  background-color: white;
`;

const CalendarBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 0.3px solid ${borderColor};
  border-top: none;

  & ${WeekRow}:last-child {
    border-bottom: none;
  }
  background-color: white;
`;

const CalendarFooter = styled.div`
  width: 100%;
  height: ${({ theme }) => toPx(theme.size.footer.height)};
`;

const Spacer = styled.div`
  height: ${({ theme }) => toPx(theme.size.title.height)};
`;

const NavButton = styled.button`
  border: none;
  margin: 0;
  padding: 8px 16px;
  background: none;
  display: flex;
  align-items: center;
`;

const BaseIcon = styled.div`
  width: 11px;
  height: 16px;
  path {
    fill: ${({ theme }) => theme.colors.colorMediumGray7};
  }
`;

const LeftIcon = styled(BaseIcon)`
  background: url(${BaseLeftIcon});
`;

const RightIcon = styled(BaseIcon)`
  background: url(${BaseRightIcon});
`;
