import { createRequestTypes } from 'appUtils';

export const UPDATE_MEMBER_BUDGET_ORDER = 'UPDATE_MEMBER_BUDGET_ORDER';

export const FETCH_SPENT_HOURS_BY_DAY = createRequestTypes(
  'FETCH_SPENT_HOURS_BY_DAY'
);
export const FETCH_PLANNED_HOURS_BY_DAY = createRequestTypes(
  'FETCH_PLANNED_HOURS_BY_DAY'
);
export const FETCH_MEMBER_BUDGET_TOTALS = createRequestTypes(
  'FETCH_MEMBER_BUDGET_TOTALS'
);
export const FETCH_MEMBER_BUDGETS = createRequestTypes('FETCH_MEMBER_BUDGETS');
export const CREATE_MEMBER_BUDGET = createRequestTypes('CREATE_MEMBER_BUDGET');
export const UPDATE_MEMBER_BUDGET = createRequestTypes('UPDATE_MEMBER_BUDGET');
export const FETCH_MEMBER_BUDGET_PHASE = createRequestTypes(
  'FETCH_MEMBER_BUDGET_PHASE'
);
export const ASSIGN_MEMBER_BUDGET = createRequestTypes('ASSIGN_MEMBER_BUDGET');
export const DELETE_MEMBER_BUDGET = createRequestTypes('DELETE_MEMBER_BUDGET');

export const ARCHIVE_MEMBER_BUDGET_PHASE = createRequestTypes(
  'ARCHIVE_MEMBER_BUDGET_PHASE'
);
export const UNARCHIVE_MEMBER_BUDGET_PHASE = createRequestTypes(
  'UNARCHIVE_MEMBER_BUDGET_PHASE'
);

export const CREATE_PHASE_ESTIMATION = createRequestTypes(
  'CREATE_PHASE_ESTIMATION'
);
export const UPDATE_PHASE_ESTIMATION = createRequestTypes(
  'UPDATE_PHASE_ESTIMATION'
);
export const DELETE_PHASE_ESTIMATION = createRequestTypes(
  'DELETE_PHASE_ESTIMATION'
);

export const UPDATE_ACTIVITY_PHASE = createRequestTypes(
  'UPDATE_ACTIVITY_PHASE'
);
export const HARD_DELETE_ACTIVITY_PHASE = createRequestTypes(
  'HARD_DELETE_ACTIVITY_PHASE'
);

export const OPEN_REMOVE_MEMBER_MODAL = 'OPEN_REMOVE_MEMBER_MODAL';
export const CLOSE_REMOVE_MEMBER_MODAL = 'CLOSE_REMOVE_MEMBER_MODAL';
export const OPEN_ADD_REQUIREMENT_MODAL = 'OPEN_REMOVE_MEMBER_MODAL';
export const CLOSE_ADD_REQUIREMENT_MODAL = 'CLOSE_REMOVE_MEMBER_MODAL';
export const OPEN_ARCHIVE_MEMBER_MODAL = 'OPEN_ARCHIVE_MEMBER_MODAL';
export const CLOSE_ARCHIVE_MEMBER_MODAL = 'CLOSE_ARCHIVE_MEMBER_MODAL';

export const OPEN_BUDGET_MODAL = 'OPEN_BUDGET_MODAL';
export const CLOSE_BUDGET_MODAL = 'CLOSE_BUDGET_MODAL';
export const OPEN_ACTIVITY_DROPDOWN = 'OPEN_ACTIVITY_DROPDOWN';
export const CLOSE_ACTIVITY_DROPDOWN = 'CLOSE_ACTIVITY_DROPDOWN';
export const OPEN_ACTIVITY_MODAL = 'OPEN_ACTIVITY_MODAL';
export const CLOSE_ACTIVITY_MODAL = 'CLOSE_ACTIVITY_MODAL';

export const TOGGLE_DECIMALS = 'TOGGLE_DECIMALS';

export const FETCH_TEAM_MEMBERS_RATES = createRequestTypes(
  'FETCH_TEAM_MEMBERS_RATES'
);
export const UPDATE_TEAM_MEMBER_RATE = createRequestTypes(
  'UPDATE_TEAM_MEMBER_RATE'
);

export const CREATE_POSITION_SKILLS = createRequestTypes(
  'CREATE_POSITION_SKILLS'
);
export const UPDATE_POSITION_SKILLS = createRequestTypes(
  'UPDATE_POSITION_SKILLS'
);
export const FETCH_POSITION_SKILLS = createRequestTypes(
  'FETCH_POSITION_SKILLS'
);
export const DELETE_POSITION_SKILLS = createRequestTypes(
  'DELETE_POSITION_SKILLS'
);

export const UPDATE_POSITION_LOCATION_RANGE = createRequestTypes(
  'UPDATE_POSITION_LOCATION_RANGE'
);
export const DELETE_POSITION_LOCATION_RANGE = createRequestTypes(
  'DELETE_POSITION_LOCATION_RANGE'
);

export const FETCH_RATES = createRequestTypes('FETCH_RATES');
export const CREATE_RATE = createRequestTypes('CREATE_RATE');
export const UPDATE_RATE = createRequestTypes('UPDATE_RATE');
export const ARCHIVE_RATE = createRequestTypes('ARCHIVE_RATE');
export const UNARCHIVE_RATE = createRequestTypes('UNARCHIVE_RATES');
// export const DELETE_RATE = createRequestTypes('DELETE_RATE');

export const FETCH_MEMBER_RATES = createRequestTypes('FETCH_MEMBER_RATES');
export const CREATE_MEMBER_RATE = createRequestTypes('CREATE_MEMBER_RATE');
export const UPDATE_MEMBER_RATE = createRequestTypes('UPDATE_MEMBER_RATE');
export const DELETE_MEMBER_RATE = createRequestTypes('DELETE_MEMBER_RATE');

export const FETCH_ALL_TEAM_SALARIES = createRequestTypes(
  'FETCH_ALL_TEAM_SALARIES'
);
export const FETCH_MEMBER_SALARIES = createRequestTypes(
  'FETCH_MEMBER_SALARIES'
);
export const CREATE_MEMBER_SALARY = createRequestTypes('CREATE_MEMBER_SALARY');
export const UPDATE_MEMBER_SALARY = createRequestTypes('UPDATE_MEMBER_SALARY');
export const DELETE_MEMBER_SALARY = createRequestTypes('DELETE_MEMBER_SALARY');

export const FETCH_OVERHEAD = createRequestTypes('FETCH_OVERHEAD');
export const CREATE_OVERHEAD = createRequestTypes('CREATE_OVERHEAD');

export const FETCH_TEAM_ROLES = createRequestTypes('FETCH_TEAM_ROLES');
export const CREATE_TEAM_ROLE = createRequestTypes('CREATE_TEAM_ROLE');
export const UPDATE_TEAM_ROLE = createRequestTypes('UPDATE_TEAM_ROLE');
// export const DELETE_TEAM_ROLE = createRequestTypes('DELETE_TEAM_ROLE');

export const FETCH_PROJECT_BUDGET_VIEW_SETTINGS = createRequestTypes(
  'FETCH_PROJECT_BUDGET_VIEW_SETTINGS'
);
export const UPDATE_PROJECT_BUDGET_VIEW_SETTINGS = createRequestTypes(
  'UPDATE_PROJECT_BUDGET_VIEW_SETTINGS'
);
export const FETCH_TEAM_BUDGET_VIEW_SETTINGS = createRequestTypes(
  'FETCH_TEAM_BUDGET_VIEW_SETTINGS'
);
export const UPDATE_TEAM_BUDGET_VIEW_SETTINGS = createRequestTypes(
  'UPDATE_TEAM_BUDGET_VIEW_SETTINGS'
);

export const BULK_ASSIGN_ACTIVITY = 'BULK_ASSIGN_ACTIVITY';
export const BULK_ASSIGN_ACTIVITY_ESTIMATIONS = createRequestTypes(
  'BULK_ASSIGN_ACTIVITY_ESTIMATIONS'
);
export const BULK_ASSIGN_ACTIVITY_SCHEDULE_BARS = createRequestTypes(
  'BULK_ASSIGN_ACTIVITY_SCHEDULE_BARS'
);
export const BULK_ASSIGN_DESCRIPTIONS = createRequestTypes(
  'BULK_ASSIGN_DESCRIPTIONS'
);
export const BULK_DELETE_ACTIVITY_SCHEDULE_BARS = createRequestTypes(
  'BULK_DELETE_ACTIVITY_SCHEDULE_BARS'
);

export const BULK_MOVE_TIME_AND_REMOVE_ACTIVITY =
  'BULK_MOVE_TIME_AND_REMOVE_ACTIVITY';
export const BULK_DELETE_TIME_AND_REMOVE_ACTIVITY =
  'BULK_DELETE_TIME_AND_REMOVE_ACTIVITY';

export const OPEN_PROJECT_PHASE_DETAILS = 'OPEN_PROJECT_PHASE_DETAILS';
export const CLOSE_PROJECT_PHASE_DETAILS = 'CLOSE_PROJECT_PHASE_DETAILS';
export const CLEAR_HOURS_DROPDOWN = 'CLEAR_HOURS_DROPDOWN';

const SPENT = 'SPENT';
const PLANNED = 'PLANNED';
const REMAINING = 'REMAINING';
const SCOPE_REMAINING = 'SCOPE_REMAINING';
const COMPLETED = 'COMPLETED';
export const BUDGET_CATEGORIES = {
  SPENT,
  PLANNED,
  REMAINING,
  SCOPE_REMAINING,
  COMPLETED
};
export const SPENT_HOURS = 'spent_hours';
export const PLANNED_HOURS = 'planned_hours';
export const REMAINING_HOURS = 'remaining_hours';
export const TARGET = 'target';
export const LEGEND_ITEMS = [SPENT_HOURS, PLANNED_HOURS, REMAINING_HOURS];

export const SPENT_COST = 'spent_cost';
export const PLANNED_COST = 'planned_cost';
export const REMAINING_COST = 'remaining_cost';

export const SPENT_PERCENT = 'spent_percent';
export const PLANNED_PERCENT = 'planned_percent';
export const REMAINING_PERCENT = 'remaining_percent';

export const PHASE_AND_PROJECT_BAR_CALCULATIONS = [
  SPENT_COST,
  PLANNED_COST,
  REMAINING_COST
];

export const PHASE_AND_PROJECT_BAR_CALCULATIONS_PERCENT = [
  SPENT_PERCENT,
  PLANNED_PERCENT,
  REMAINING_PERCENT
];

export const PERCENT_CATEGORIES = {
  spent: SPENT_PERCENT,
  planned: PLANNED_PERCENT,
  remaining: REMAINING_PERCENT
};

export const PHASE_AND_PROJECT_BAR_CALCULATIONS_HOURS = [
  SPENT_HOURS,
  PLANNED_HOURS,
  REMAINING_HOURS
];

export const TOOL_TIP_FORMAT_NAMES = {
  currency: PHASE_AND_PROJECT_BAR_CALCULATIONS,
  percentage: PHASE_AND_PROJECT_BAR_CALCULATIONS_PERCENT,
  hour: PHASE_AND_PROJECT_BAR_CALCULATIONS_HOURS
};

export const TOOL_TIP_FORMAT_NAMES_WITHOUT_REMAINING = {
  currency: [SPENT_COST, PLANNED_COST],
  percentage: [SPENT_PERCENT, PLANNED_PERCENT],
  hour: [SPENT_HOURS, PLANNED_HOURS]
};

export const TOOL_TIP_FORMAT_NAMES_SPENT_ONLY = {
  currency: [SPENT_COST],
  percentage: [SPENT_PERCENT],
  hour: [SPENT_HOURS]
};

export const TOOL_TIP_FORMAT_NAMES_PLANNED_ONLY = {
  currency: [PLANNED_COST],
  percentage: [PLANNED_PERCENT],
  hour: [PLANNED_HOURS]
};

export const TOOL_TIP_FORMAT_NAMES_WITH_TARGET = {
  currency: [TARGET, ...PHASE_AND_PROJECT_BAR_CALCULATIONS],
  percentage: [TARGET, ...PHASE_AND_PROJECT_BAR_CALCULATIONS_PERCENT],
  hour: [TARGET, ...PHASE_AND_PROJECT_BAR_CALCULATIONS_HOURS]
};

export const TOOL_TIP_FORMAT_NAMES_WITH_TARGET_AND_WITHOUT_REMAINING = {
  currency: [TARGET, SPENT_COST, PLANNED_COST],
  percentage: [TARGET, SPENT_PERCENT, PLANNED_PERCENT],
  hour: [TARGET, SPENT_HOURS, PLANNED_HOURS]
};

export const TOOL_TIP_FORMAT_NAMES_PLANNED_AND_REMAINING = {
  currency: [PLANNED_COST, REMAINING_COST],
  percentage: [PLANNED_PERCENT, REMAINING_PERCENT],
  hour: [PLANNED_HOURS, REMAINING_HOURS]
};

export const SET_INITIAL_MEMBERS_ORDER = 'SET_INITIAL_MEMBERS_ORDER';
export const EDIT_MEMBER_RATE = 'EDIT_MEMBER_RATE';
export const CLEAR_ADDED_BUDGET_ID = 'CLEAR_ADDED_BUDGET_ID';

export const EARLIEST_RATE_START_DISPLAY_PROJECT = 'Project Start';
export const EARLIEST_RATE_START_DISPLAY = 'Start';

export const BUDGET_ACCESS_CHECK = 'BUDGET_ACCESS_CHECK';

export const FETCH_ALL_TEAM_RATES = createRequestTypes('FETCH_ALL_TEAM_RATES');
export const FETCH_MEMBER_TEAM_RATES = createRequestTypes(
  'FETCH_MEMBER_TEAM_RATES'
);
export const CREATE_MEMBER_TEAM_RATE = createRequestTypes(
  'CREATE_MEMBER_TEAM_RATES'
);
export const UPDATE_MEMBER_TEAM_RATE = createRequestTypes(
  'UPDATE_MEMBER_TEAM_RATE'
);
export const DELETE_MEMBER_TEAM_RATE = createRequestTypes(
  'DELETE_MEMBER_TEAM_RATE'
);

// Billing
export const FETCH_PROJECT_BILLING_CATEGORIES = createRequestTypes(
  'FETCH_PROJECT_BILLING_CATEGORIES'
);
export const FETCH_PROJECT_INVOICES = createRequestTypes(
  'FETCH_PROJECT_INVOICES'
);
export const CREATE_PROJECT_INVOICE = createRequestTypes(
  'CREATE_PROJECT_INVOICE'
);
export const UPDATE_PROJECT_INVOICE = createRequestTypes(
  'UPDATE_PROJECT_INVOICE'
);
export const DELETE_PROJECT_INVOICE = createRequestTypes(
  'DELETE_PROJECT_INVOICE'
);
export const SET_BILLING_TABLE_VIEW = 'SET_BILLING_TABLE_VIEW';

// Budget records
export const FETCH_BUDGET_RECORDS = createRequestTypes('FETCH_BUDGET_RECORDS');
export const FETCH_PROJECT_BUDGET_RECORD = createRequestTypes(
  'FETCH_PROJECT_BUDGET_RECORD'
);
export const CLEAR_BUDGET_RECORDS_STATE = 'CLEAR_BUDGET_RECORDS_STATE';

export const FETCH_POSITIONS = createRequestTypes('FETCH_POSITIONS');
export const FETCH_POSITION = createRequestTypes('FETCH_POSITION');
export const CREATE_POSITION = createRequestTypes('CREATE_POSITION');
export const UPDATE_POSITION = createRequestTypes('UPDATE_POSITION');
export const DELETE_POSITION = createRequestTypes('DELETE_POSITION');
export const ARCHIVE_POSITION = createRequestTypes('ARCHIVE_POSITION');

export const FETCH_TEAM_POSITIONS = createRequestTypes('FETCH_TEAM_POSITIONS');
export const CREATE_TEAM_POSITION = createRequestTypes('CREATE_TEAM_POSITION');
export const UPDATE_TEAM_POSITION = createRequestTypes('UPDATE_TEAM_POSITION');
export const DELETE_TEAM_POSITION = createRequestTypes('DELETE_TEAM_POSITION');

export const FETCH_MEMBER_POSITIONS = createRequestTypes(
  'FETCH_MEMBER_POSITIONS'
);
export const CREATE_MEMBER_POSITION = createRequestTypes(
  'CREATE_MEMBER_POSITION'
);
export const UPDATE_MEMBER_POSITION = createRequestTypes(
  'UPDATE_MEMBER_POSITION'
);
export const DELETE_MEMBER_POSITION = createRequestTypes(
  'DELETE_MEMBER_POSITION'
);

export const FETCH_ACCOUNT_POSITIONS = createRequestTypes(
  'FETCH_ACCOUNT_POSITIONS'
);
export const CREATE_ACCOUNT_POSITION = createRequestTypes(
  'CREATE_ACCOUNT_POSITION'
);
export const UPDATE_ACCOUNT_POSITION = createRequestTypes(
  'UPDATE_ACCOUNT_POSITION'
);
export const DELETE_ACCOUNT_POSITION = createRequestTypes(
  'DELETE_ACCOUNT_POSITION'
);

export const FETCH_POSITION_RATES = createRequestTypes('FETCH_POSITION_RATES');
export const CREATE_POSITION_RATE = createRequestTypes('CREATE_POSITION_RATE');
export const UPDATE_POSITION_RATE = createRequestTypes('UPDATE_POSITION_RATE');
export const DELETE_POSITION_RATE = createRequestTypes('DELETE_POSITION_RATE');
