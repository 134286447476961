import React from 'react';
import PropTypes from 'prop-types';

import { StyledFooter, Left, Right, Cancel, Confirm } from './styles';

class FlyoutFooter extends React.Component {
  handleClick = (e) => {
    const {
      editMode,
      setEditMode,
      clearEditMode,
      onFooterClick,
      isInvalid = false
    } = this.props;
    if (onFooterClick) {
      onFooterClick(e);
    }
    if (editMode && isInvalid) {
      return;
    }
    if (editMode && !isInvalid) {
      clearEditMode();
    }
    if (!editMode && isInvalid) {
      return;
    }
    if (!editMode) {
      setEditMode();
    }
  };

  footerCopy = () => {
    const { editMode, copy } = this.props;
    const { footerInitial, footerEdit } = copy;
    return editMode ? footerEdit : footerInitial;
  };

  renderConfirmCopy = () => {
    const { addMode, editingItem, copy } = this.props;
    if (addMode) {
      return copy.addConfirm || 'Add';
    }
    if (editingItem) {
      return copy.editConfirm || 'Save';
    }
    return 'Save';
  };

  render() {
    const {
      addMode,
      editingItem,
      handleSubmit,
      clearEditMode,
      onCancel,
      clearEditingId
    } = this.props;
    const isEditing = addMode || editingItem;
    return (
      <StyledFooter
        center={!isEditing}
        onClick={this.handleClick}
        isWhite={isEditing}
        isSubmit={!isEditing && ['Done', 'done'].includes(this.footerCopy())}
      >
        {isEditing ? (
          <>
            <Left />
            <Right>
              <Cancel
                onClick={
                  onCancel
                    ? (e) => onCancel(e, { clearEditingId })
                    : clearEditMode
                }
              >
                Cancel
              </Cancel>
              <Confirm onClick={handleSubmit}>
                {this.renderConfirmCopy()}
              </Confirm>
            </Right>
          </>
        ) : (
          <div>{this.footerCopy()}</div>
        )}
      </StyledFooter>
    );
  }
}

FlyoutFooter.propTypes = {
  editMode: PropTypes.bool.isRequired,
  clearEditMode: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  copy: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default FlyoutFooter;
