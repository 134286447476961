// Rendered initially, replaced with mark as read on hover

import React from 'react';
import cn from 'classnames';
import moment from 'moment';

const Timestamp = ({ time, isRead }) => (
  <div className={cn('timestamp', { 'is-unread': !isRead })}>
    {moment(time).format('h:mm A')}
  </div>
);

export default Timestamp;
