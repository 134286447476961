import * as constants from 'appConstants';

export const initialState = {
  fetching: false,
  projectPriorities: []
};

const priorities = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_PRIORITIES.TRIGGER: {
      return {
        ...state,
        fetching: true
      };
    }
    case constants.FETCH_PRIORITIES.FAILURE: {
      return {
        ...state,
        fetching: false
      };
    }
    case constants.FETCH_PRIORITIES.SUCCESS: {
      const projectPriorities = payload.response;
      return {
        ...state,
        fetching: false,
        projectPriorities: projectPriorities
      };
    }

    default:
      return state;
  }
};

export default priorities;
