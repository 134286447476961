import { useCallback, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchAllProjects } from 'actionCreators';
import {
  getIsFetchingAllProjects,
  getNumberOfAllProjects,
  getAllFetchedProjectsLength,
  makeSafeGetProjectAssignmentProjects,
  getProjectHash
} from 'selectors';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useArrayFilterField } from '.';
import { Project } from 'ProjectsModule/models/project';

const emptyArray = [];
const emptyObj = {};

export const useProjectsFilter = ({
  isOff,
  config,
  isResultsLoading,
  shouldUseDraft,
  resultCountHash,
  field = FilterField.project_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const dispatch = useAppDispatch();

  const projectHash = useAppSelector(getProjectHash) as Record<
    number,
    Partial<Project>
  >;

  const getSafeProjectAssignmentProjects = useMemo(
    makeSafeGetProjectAssignmentProjects,
    []
  );

  // we've been using this selector value, but this should be replaced
  const projects = useAppSelector((state) =>
    isOff ? emptyArray : getSafeProjectAssignmentProjects(state, {})
  );

  /** Array of selectable project ids */
  const projectIdsArray: number[] = useMemo(
    () => (isOff ? emptyArray : projects.map((project) => project.id)),
    [projects, isOff]
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: projectIdsArray,
    isOff,
    config,
    itemHash: projectHash,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    shouldUseDraft,
    labelKey
  });

  /* --------------------------------- loading -------------------------------- */

  const isFetching = useAppSelector(getIsFetchingAllProjects);
  const totalCount = useAppSelector(getNumberOfAllProjects);
  const fetchedProjectsCount = useAppSelector(getAllFetchedProjectsLength);
  const hasNextPage = !isOff && fetchedProjectsCount < totalCount;

  // Make sure the initially selected projects get loaded
  const [projectsToFetchOnMount] = useState(
    isOff ? emptyArray : arrayFilterFieldValues.selectedItems || []
  );

  useFetchUnloadedProjects({
    projectIds: projectsToFetchOnMount,
    shouldFetchPhases: false
  });

  const loadMoreItems = useCallback(
    ({ search }: { search: string }) => {
      if (!isOff && !isFetching) {
        dispatch(
          fetchAllProjects({
            searchText: search,
            offset: fetchedProjectsCount,
            limit: 30
          })
        );
      }
    },
    [fetchedProjectsCount, isFetching, dispatch, isOff]
  );

  const loadInitialItems = useCallback(
    ({ search }: { search: string }) => {
      if (!isOff) {
        dispatch(
          fetchAllProjects({
            searchText: search,
            offset: 0,
            limit: 30
          })
        );
      }
    },
    [dispatch, isOff]
  );

  return {
    ...arrayFilterFieldValues,
    hasNextPage,
    loadInitialItems,
    loadMoreItems,
    itemHash: projectHash,
    labelKey,
    isLoading: isResultsLoading,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const searchableKeys: (keyof Project)[] = ['title', 'description', 'client'];

const labelKey = 'title';
