import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getNavViewText,
  getOnBoardView,
  getOnSettings,
  getOnProjectDetail,
  getOnScheduleView,
  getOnHomeView,
  getIsOnTeamProject,
  getSelectedProject,
  getUserIsGuest,
  getIsOnOwnTimesheets,
  getTeamSlug,
  getUserSlug,
  getIsOnProjectView,
  getIsOnTeamSettingsView
} from 'selectors';

import {
  useSearchState,
  loadWithSidebarOpen,
  handleProjectItemState,
  navigateToMembers,
  navigateToSettings,
  navigateToProject,
  navigateToActivityFeed,
  navigateToHome,
  navigateBack,
  navigateToBoard
} from 'actionCreators';

const withNavigation = (WrappedComponent) => {
  class WithNavigation extends React.Component {
    navBackToView = (e) => {
      const {
        isOnProjectView,
        isOnTeamSettingsView,
        isOnSettingsView,
        navigateBack
      } = this.props;
      e.stopPropagation();
      if (isOnProjectView || isOnTeamSettingsView || isOnSettingsView) {
        navigateBack();
      }
    };

    navToHome = () => {
      const { userSlug, navigateToHome } = this.props;
      navigateToHome({ userSlug });
    };

    navToView = (e) => {
      const {
        useSearchState,
        handleProjectItemState,
        isOnBoardView,
        isOnHomeView,
        isOnScheduleView,
        isOnProjectDetail,
        navViewText,
        selectedProject,
        userIsGuest,
        loadWithSidebarOpen,
        teamSlug,
        navigateToBoard,
        navigateToMembers
      } = this.props;

      e.stopPropagation();
      if (userIsGuest) {
        return;
      }
      if (isOnProjectDetail && selectedProject && selectedProject.is_personal) {
        this.navToHome();
        return;
      }

      if (
        isOnBoardView ||
        (isOnScheduleView && !isOnProjectDetail) ||
        isOnHomeView
      ) {
        return;
      }

      if (navViewText === 'Team') {
        navigateToMembers({ teamSlug });
      }

      if (isOnProjectDetail && selectedProject) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSearchState({
          home: false,
          board: true
        });
        loadWithSidebarOpen(false, 0);
        handleProjectItemState(
          selectedProject.id,
          ['detailVisible', 'expanded', 'contracting'],
          [true, true, false]
        );
        navigateToBoard({
          teamSlug: teamSlug,
          boardSlug: selectedProject.group_slug,
          boardId: selectedProject.board_id
        });
      }
    };

    render() {
      return (
        <WrappedComponent
          navToView={this.navToView}
          navBackToView={this.navBackToView}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    isOnProjectDetail: getOnProjectDetail(state),
    isOnTeamProject: getIsOnTeamProject(state),
    isOnHomeView: getOnHomeView(state),
    isOnSettingsView: getOnSettings(state),
    isOnBoardView: getOnBoardView(state),
    isOnProjectView: getIsOnProjectView(state),
    isOnTeamSettingsView: getIsOnTeamSettingsView(state),
    isOnScheduleView: getOnScheduleView(state),
    navViewText: getNavViewText(state),
    isOnOwnTimesheets: getIsOnOwnTimesheets(state),
    selectedProject: getSelectedProject(state),
    teamSlug: getTeamSlug(state),
    userIsGuest: getUserIsGuest(state),
    userSlug: getUserSlug(state)
  });

  const mapDispatchToProps = {
    useSearchState,
    loadWithSidebarOpen,
    handleProjectItemState,
    navigateToSettings,
    navigateToActivityFeed,
    navigateToHome,
    navigateBack,
    navigateToProject,
    navigateToBoard,
    navigateToMembers
  };

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WithNavigation)
  );
};
export default withNavigation;
