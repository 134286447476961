import { roleTemplatesHash } from 'PermissionsModule/constants';
import { RoleTemplates } from 'PermissionsModule/types';

const {
  admin,
  projectManager,
  boardManager,
  teamMember,
  financialManager,
  budgetManager,
  projectMember,
  workloadPlanner
} = roleTemplatesHash;

type CheckeboxClickedByUser = RoleTemplates | 'Specific Member';
type CheckboxesAffectedByUserInput = readonly RoleTemplates[];

type CheckboxDependenciesTest = Partial<
  Record<CheckeboxClickedByUser, CheckboxDependencies>
>;

interface CheckboxDependencies {
  true: CheckboxesAffectedByUserInput;
  false: CheckboxesAffectedByUserInput;
}

/**
 * Key: The checkbox clicked by the user
 * Values:
 *  - true: Array of checkboxes that will get checked if the clicked checkbox gets checked
 *  - false: Array of checkboxes that will get unchecked if clicked checkbox gets unchecked
 */
export const CHECKBOX_DEPENDENCIES: CheckboxDependenciesTest = {
  [projectManager]: {
    true: [],
    false: [teamMember]
  },
  [boardManager]: {
    true: [],
    false: [teamMember]
  },
  [teamMember]: {
    true: [
      projectManager,
      boardManager,
      financialManager,
      budgetManager,
      projectMember,
      workloadPlanner
    ],
    false: []
  },
  [financialManager]: {
    true: [],
    false: [teamMember]
  },
  [budgetManager]: {
    true: [],
    false: [teamMember]
  },
  [projectMember]: {
    true: [],
    false: [teamMember]
  },
  [workloadPlanner]: {
    true: [],
    false: [teamMember]
  },
  'Specific Member': {
    true: [],
    false: []
  }
} as const;
