import * as constants from '../constants';
import { createAction } from 'appUtils';

export const createCustomField = (params) =>
  createAction(constants.CREATE_CUSTOM_FIELD.TRIGGER, params);
export const fetchCustomField = (params) =>
  createAction(constants.FETCH_CUSTOM_FIELD.TRIGGER, params);
export const updateCustomField = (params) =>
  createAction(constants.UPDATE_CUSTOM_FIELD.TRIGGER, params);
export const archiveCustomField = (params) =>
  createAction(constants.ARCHIVE_CUSTOM_FIELD.TRIGGER, params);
export const unarchiveCustomField = (params) =>
  createAction(constants.UNARCHIVE_CUSTOM_FIELD.TRIGGER, params);
export const fetchCustomFieldAllValues = (params) =>
  createAction(constants.FETCH_CUSTOM_FIELD_ALL_VALUES.TRIGGER, params);
