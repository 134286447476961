import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import MembersNavigationTracker from 'views/Navigation/MembersNavigationTracker';
import TimesheetNavigationTracker from 'views/Navigation/TimesheetNavigationTracker';
import withRouterParams from './WithRouterParams';
import ProfileContainer from 'views/teamMembers/ProfileContainer';

class TeamMemberProfileRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }
  render() {
    const { match } = this.props;
    return (
      <>
        <MembersNavigationTracker />
        <Route
          path={`${match.url}/timesheet`}
          component={TimesheetNavigationTracker}
        />
        <Route path={`${match.url}/timesheet`} component={ProfileContainer} />
      </>
    );
  }
}

export default withRouterParams(withRouter(TeamMemberProfileRouter));
