import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MembersIcon = ({
  height = '32',
  width = '32',
  circleColor = '#F0F0F0'
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 32 32">
      <rect
        x="1.14307"
        y="1.14288"
        width="29.7143"
        height="29.7143"
        rx="14.8571"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path
          d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0Z"
          fill={circleColor}
        />
        <path
          d="M22.0002 11.7C22.0002 14.9584 19.3587 17.6 16.1002 17.6C12.8417 17.6 10.2002 14.9584 10.2002 11.7C10.2002 8.44153 12.8417 5.79999 16.1002 5.79999C19.3587 5.79999 22.0002 8.44153 22.0002 11.7Z"
          fill="#CCCCCC"
        />
        <path
          d="M26.2001 30.1C26.2001 35.7885 21.5886 40.4 15.9001 40.4C10.2116 40.4 5.6001 35.7885 5.6001 30.1C5.6001 24.4115 10.2116 19.8 15.9001 19.8C21.5886 19.8 26.2001 24.4115 26.2001 30.1Z"
          fill="#CCCCCC"
        />
      </g>
    </SvgIcon>
  );
};

export default MembersIcon;
