/**
 * A map of I18N string codes and English texts for the color scale intervals
 * input. See `interface I18NText` for context.
 */
export const STRINGS_EN = new Map([
  ['colorscaleintervals_error_nan', 'The value must be a whole number.'],
  [
    'colorscaleintervals_error_max',
    'The number must be lower than<br />or equal to <%= max %>.'
  ],
  [
    'colorscaleintervals_error_min',
    'The number must be higher than<br />or equal to <%= min %>.'
  ],
  [
    'colorscaleintervals_error_sameafter',
    'The number must be lower than <%= max %>.'
  ],
  [
    'colorscaleintervals_error_samebefore',
    'The number must be higher than <%= min %>.'
  ]
]);
