import moment from 'moment';

export const getProjectMilestoneTooltipContent = ({ phaseName, startDate }) => {
  return `
  <div class="right-box milestone-tooltip-content">
    <div class="tooltip-row row-1">
    <div class="tooltip-milestone-icon"></div><div class="tooltip-bar-milestone-name">${phaseName} </div>
    </div>
    <div class="tooltip-row row-3" >
    <div class="tooltip-milestone-date-container">${
      startDate ? `${moment(startDate).format('MMM D')}` : ''
    }
    </div>
    </div>
  </div>
  `;
};
