import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { CustomFormattedCurrency } from 'components/FormatCurrency';
import styled from 'styled-components';
import theme from 'theme';
import { costRateColumn } from '../tableConfigsColumns';
import { EntityRateCellProps, TableCellProps } from '../types';
import { isEntityRateCell } from '../utils';
import cn from 'classnames';
import { EditIcon } from './EditIcon';
import { convertIsoDateToUsaDate } from 'appUtils/dateUtils';

export const RateCell = (props: TableCellProps) =>
  isEntityRateCell(props) ? <RateCellWithEntityRate {...props} /> : null;

export const RateCellWithEntityRate = (props: EntityRateCellProps) => {
  const {
    column: { headerType },
    row: {
      original: {
        billEntityRate,
        billRate,
        costEntityRate,
        costRate,
        currencyCode,
        editEntityRates,
        isRateGroupArchived,
        rateEntity
      }
    }
  } = props;

  const isCostRateColumn = headerType === costRateColumn.headerType;
  const rate = isCostRateColumn ? costRate : billRate;
  const entityRate = isCostRateColumn ? costEntityRate : billEntityRate;

  const handleEditRate = () => editEntityRates(isCostRateColumn);

  const displayStartDate = entityRate?.start_date
    ? convertIsoDateToUsaDate(entityRate.start_date)
    : '';
  const isEntityRateArchived = !!rateEntity?.archived;
  const isDisabled = isRateGroupArchived || isEntityRateArchived;
  const { cellProps, pencilIconColor } = isDisabled
    ? {
        cellProps: {
          $disabled: true,
          ...defaultTooltipProps,
          'data-class': cn(defaultTooltipProps['data-class'], 'center'),
          'data-tip': entityRate?.start_date
            ? `Unarchive to edit.<br />Effective rate as of ${displayStartDate}.`
            : 'Unarchive to edit.'
        },
        pencilIconColor: theme.colors.colorMediumGray9
      }
    : {
        cellProps: {
          ...defaultTooltipProps,
          'data-tip': entityRate?.start_date
            ? `Effective rate as of ${displayStartDate}.`
            : undefined,
          onClick: handleEditRate
        },
        pencilIconColor: theme.colors.colorRoyalBlue
      };

  return (
    <Cell {...cellProps}>
      {entityRate && rate ? (
        <Rate>
          <CustomFormattedCurrency
            currencyFormatterProps={{ currencyCode }}
            currencyValue={parseFloat(rate.rate)}
          />
          /h
          {entityRate.start_date && (
            <SubDateText>{displayStartDate}</SubDateText>
          )}
        </Rate>
      ) : (
        <SetRate>Set Rate</SetRate>
      )}
      {(isCostRateColumn || !rate?.description) && (
        <Icon>
          <StyledEditIcon fill={pencilIconColor} />
        </Icon>
      )}
    </Cell>
  );
};

const StyledEditIcon = styled(EditIcon)`
  position: absolute;
`;

const Icon = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const SubDateText = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  font-size: 10px;
`;

const SetRate = styled.div`
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
`;

const Rate = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Cell = styled.div.attrs<{ $disabled?: boolean }>(({ $disabled }) => ({
  className: cn({ disabled: $disabled })
}))<{ $disabled?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;

  &:not(.disabled):hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }

  &.disabled {
    cursor: not-allowed;

    ${SetRate} {
      color: inherit;
    }
  }
`;
