import { useCallback } from 'react';
import { useMount } from 'react-use';
import { togglePermissionOpen } from 'PermissionsModule/actionCreators';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { PermissionsActions, RoleTemplates } from 'PermissionsModule/types';
import {
  PermissionEdits,
  IsSpecificMemberCheckedByPermissionAction,
  MakeEditPermissionType
} from 'PermissionsModule/hooks/types';
import {
  PermissionActionHash,
  SpecificMemberPermissionHash
} from 'PermissionsModule/components/PermissionsTable/types';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

import {
  PERMISSION_SECTION,
  permissionsActionsHash,
  roleTemplatesHash
} from 'PermissionsModule/constants';
import { buildPermittedRoles } from 'PermissionsModule/utils';
import {
  HeaderContainer,
  PermissionText,
  ExplanationText,
  PermissionHeaderTextContainer,
  PermissionHeaderKaratRight,
  PermissionHeaderSubTextContainer,
  PermissionHeaderKaratRightHoverSstyle
} from 'PermissionsModule/components/styles';
import Collapse from 'SettingsModule/components/Collapse';
import {
  getAllPermissionsByActions,
  getPermissionActionsExpanded,
  getPermissionOptionsByActions,
  getOneOffPermissionersMap
} from 'PermissionsModule/selectors';
import { PermissionSelectionContainer } from 'PermissionsModule/components/PermissionsSections/shared/PermissionSelectionContainer';
import {
  DEFAULT_CHECKBOX_ITEMS,
  getCheckboxListItems
} from 'PermissionsModule/utils/checkboxOptionsUtils';
import { permissionConfigs } from 'PermissionsModule/permissionConfigs';

import DashboardIcon from 'icons/DashboardIcon';
import BudgetIcon from 'icons/BudgetIcon';
import IntegrationIcon from 'icons/IntegrationIcon';
import OrganizationIcon from 'icons/OrganizationIcon';
import TeamsIcon from 'icons/TeamsIcon';
import ReportsIcon from 'icons/ReportsIcon';
import TimesheetIcon from 'icons/TimesheetIcon';
import WorkPlanIcon from 'icons/WorkPlanIcon';
import MemberIcon from 'icons/MemberIcon';

import { PermissionSection } from 'PermissionsModule/components/PermissionsSections/PermissionSection';
import styled from 'styled-components';
import { ValueOf } from 'type-fest';
import { CheckboxItemType } from 'SettingsModule/types/checkBoxOptions';
import cn from 'classnames';

const ContainerDiv = styled.div`
  overflow: auto;
  width: fit-content;
`;

const PermissionsContainer = styled.div`
  display: flex;
  width: 800px;
  flex-direction: column;
`;

const sectionComponentConfigs: Partial<
  Record<
    ValueOf<typeof PERMISSION_SECTION>,
    {
      headerText: string;
      icon: (props: unknown) => JSX.Element;
      permissions: Partial<PermissionActionHash>;
    }
  >
> = {
  [PERMISSION_SECTION.ORGANIZATION]: {
    headerText: 'Organization',
    icon: OrganizationIcon,
    permissions: permissionConfigs.organizationPermissions
  },
  [PERMISSION_SECTION.PORTFOLIOS]: {
    headerText: 'Portfolios',
    icon: TeamsIcon,
    permissions: permissionConfigs.portfolioPermissions
  },
  [PERMISSION_SECTION.TIMESHEETS]: {
    headerText: 'Timesheets',
    icon: TimesheetIcon,
    permissions: permissionConfigs.timesheetPermissions
  },
  [PERMISSION_SECTION.INTEGRATIONS]: {
    headerText: 'Integrations',
    icon: IntegrationIcon,
    permissions: permissionConfigs.integrationPermissions
  },
  [PERMISSION_SECTION.WORK_PLANS]: {
    headerText: 'Work Plans',
    icon: WorkPlanIcon,
    permissions: permissionConfigs.workplanPermissions
  },
  [PERMISSION_SECTION.PROFILE]: {
    headerText: 'Profile',
    icon: MemberIcon,
    permissions: permissionConfigs.profilePermissions
  },
  [PERMISSION_SECTION.BUDGET]: {
    headerText: 'Budget',
    icon: BudgetIcon,
    permissions: permissionConfigs.budgetPermissions
  },
  [PERMISSION_SECTION.REPORTS]: {
    headerText: 'Reports',
    icon: ReportsIcon,
    permissions: permissionConfigs.reportPermissions
  },
  [PERMISSION_SECTION.DASHBOARDS]: {
    headerText: 'Dashboards',
    icon: DashboardIcon,
    permissions: permissionConfigs.dashboardPermissions
  }
};

const specificMemberPermissionsHash: SpecificMemberPermissionHash = {
  edit_member_timesheets: true,
  read_time_entries_other: true,
  approve_member_timesheets: true,
  manage_integration_link: true
};

// use this to override the default permission checkbox options
// i.e) Budget Manager and Workload Planner are checked by default
// and the user can't uncheck them
// text can be modified to display a different message
// order can be modified to display the checkbox in a different order
const overridedCheckboxItemsHash: Partial<
  Record<PermissionsActions, Partial<Record<RoleTemplates, CheckboxItemType>>>
> = {
  modify_work_plans: {
    [roleTemplatesHash.admin]: DEFAULT_CHECKBOX_ITEMS[roleTemplatesHash.admin],
    // it is workaround for showing permissions of two roles in the same checkbox option
    [roleTemplatesHash.workloadPlanner]: {
      name: roleTemplatesHash.financialManager,
      text: 'Budget Managers and Work Planners',
      checked: true,
      default: true
    },
    [roleTemplatesHash.teamMember]:
      DEFAULT_CHECKBOX_ITEMS[roleTemplatesHash.teamMember]
  }
};

interface AccessPermissionEditProps {
  makeEditPermission: MakeEditPermissionType;
  unSavedEdits: PermissionEdits;
  sectionOrder: ValueOf<typeof PERMISSION_SECTION>[];
  isSpecificMemberCheckedByPermissionAction: IsSpecificMemberCheckedByPermissionAction;
}

export const AccessPermissionEdit = ({
  makeEditPermission,
  unSavedEdits,
  isSpecificMemberCheckedByPermissionAction,
  sectionOrder
}: AccessPermissionEditProps) => {
  const dispatch = useAppDispatch();

  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const expandedPermissions = useAppSelector(getPermissionActionsExpanded);
  const permissionOptionsByAction = useAppSelector(
    getPermissionOptionsByActions
  );

  const oneOffPermissionersMap = useAppSelector(getOneOffPermissionersMap);

  useMount(() => {
    rebuildTooltip();
  });

  const toggleOpen = useCallback(
    (actionType: PermissionsActions) => {
      dispatch(togglePermissionOpen({ actionType }));
    },
    [dispatch]
  );

  const renderHeader = useCallback(
    (permissionAction: PermissionsActions) => {
      const roleTemplatesHash = permissionsByAction[permissionAction];
      const isOpen = expandedPermissions[permissionAction];
      const permissionData = permissionsActionsHash[permissionAction];

      const SubTextComponent = !isOpen ? (
        roleTemplatesHash ? (
          <PermissionText>
            {buildPermittedRoles(roleTemplatesHash)}
          </PermissionText>
        ) : null
      ) : permissionData && permissionData.explanation ? (
        <ExplanationText>{permissionData.explanation}</ExplanationText>
      ) : null;

      return (
        <HeaderContainer>
          <PermissionHeaderTextContainer>
            <PermissionHeaderKaratRight
              height="10px"
              width="7px"
              className={cn({ open: isOpen })}
            />
            {permissionData ? permissionData.permissionLabel : ''}
          </PermissionHeaderTextContainer>
          <PermissionHeaderSubTextContainer>
            {SubTextComponent}
          </PermissionHeaderSubTextContainer>
        </HeaderContainer>
      );
    },
    [expandedPermissions, permissionsByAction]
  );

  const renderPermissionRow = useCallback(
    (permissionAction: PermissionsActions) => {
      const roleTemplates = permissionOptionsByAction[permissionAction];

      const overridedCheckboxItems =
        overridedCheckboxItemsHash[permissionAction];

      const flatOptions = overridedCheckboxItems
        ? Object.values(overridedCheckboxItems)
        : getCheckboxListItems({
            items: roleTemplates
              ? roleTemplates.map((roleTemplate) => ({ name: roleTemplate }))
              : [],
            options: specificMemberPermissionsHash[permissionAction]
              ? { includeSpecificMember: true }
              : undefined
          });

      const permissionCheckboxValues = permissionsByAction[permissionAction];

      return (
        <Collapse
          header={renderHeader(permissionAction)}
          isOpen={expandedPermissions[permissionAction]}
          toggleOpen={() => toggleOpen(permissionAction)}
          showKarat={false}
          headerContainerStyle={PermissionHeaderKaratRightHoverSstyle}
        >
          {permissionCheckboxValues && (
            <PermissionSelectionContainer
              checkboxOptions={flatOptions}
              checkboxValues={permissionCheckboxValues}
              unSavedEdits={unSavedEdits[permissionAction]}
              isSpecificMemberChecked={
                isSpecificMemberCheckedByPermissionAction[permissionAction]
              }
              oneOffPermissioners={oneOffPermissionersMap[permissionAction]}
              onCheck={makeEditPermission(permissionAction)}
            />
          )}
        </Collapse>
      );
    },
    [
      permissionOptionsByAction,
      renderHeader,
      expandedPermissions,
      permissionsByAction,
      oneOffPermissionersMap,
      makeEditPermission,
      toggleOpen,
      isSpecificMemberCheckedByPermissionAction,
      unSavedEdits
    ]
  );

  return (
    <ContainerDiv>
      <PermissionsContainer>
        {sectionOrder.map((section) => {
          const permissionSectionComponentConfigs =
            sectionComponentConfigs[section];
          return permissionSectionComponentConfigs &&
            Object.keys(permissionSectionComponentConfigs.permissions)
              .length ? (
            <PermissionSection
              renderPermissionRow={renderPermissionRow}
              headerText={permissionSectionComponentConfigs.headerText}
              Icon={permissionSectionComponentConfigs.icon}
              permissions={permissionSectionComponentConfigs.permissions}
            />
          ) : (
            <></>
          );
        })}
      </PermissionsContainer>
    </ContainerDiv>
  );
};
