import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createRequestTypes } from 'appUtils';
import {
  fetchAccountsSuggestionForProjectsActionPayload,
  fetchAccountsSuggestionForProjectsSuccessResponse,
  fetchAccountsSuggestionForPhasesActionPayload,
  fetchAccountsSuggestionForPhasesSuccessResponse,
  FetchAccountsSuggestionForActivityPhaseMembershipsSuccessResponse,
  FetchAccountsSuggestionForActivityPhaseMembershipsParams,
  FetchAccountsSuggestionForActivityPhaseMembershipsMeta
} from 'SuggestionModule/types';
import { createActionWithMeta } from 'reduxInfra/utils/createActionWithMeta';

/* -------------------------------------------------------------------------- */

const FETCH_ACCOUNTS_SUGGESTION_FOR_PROJECTS = createRequestTypes(
  'FETCH_ACCOUNTS_SUGGESTION_FOR_PROJECTS'
);

export const fetchAccountsSuggestionForProjects = createAction(
  FETCH_ACCOUNTS_SUGGESTION_FOR_PROJECTS.TRIGGER,
  ({ meta, ...payload }: fetchAccountsSuggestionForProjectsActionPayload) => ({
    payload,
    meta
  })
);

const fetchAccountsSuggestionForProjectsActionCreatorsMap =
  createActionCreatorsMap<
    fetchAccountsSuggestionForProjectsActionPayload,
    fetchAccountsSuggestionForProjectsSuccessResponse
  >(FETCH_ACCOUNTS_SUGGESTION_FOR_PROJECTS);

/* -------------------------------------------------------------------------- */
const FETCH_ACCOUNTS_SUGGESTION_FOR_PHASES = createRequestTypes(
  'FETCH_ACCOUNTS_SUGGESTION_FOR_PHASES'
);

export const fetchAccountsSuggestionForPhases = createAction(
  FETCH_ACCOUNTS_SUGGESTION_FOR_PHASES.TRIGGER,
  ({ meta, ...payload }: fetchAccountsSuggestionForPhasesActionPayload) => ({
    payload,
    meta
  })
);

const fetchAccountsSuggestionForPhasesActionCreatorsMap =
  createActionCreatorsMap<
    fetchAccountsSuggestionForPhasesActionPayload,
    fetchAccountsSuggestionForPhasesSuccessResponse,
    fetchAccountsSuggestionForPhasesActionPayload['meta']
  >(FETCH_ACCOUNTS_SUGGESTION_FOR_PHASES);

/* -------------------------------------------------------------------------- */
const FETCH_ACCOUNTS_SUGGESTION_FOR_ACTIVITY_PHASE_MEMBERSHIPS =
  createRequestTypes(
    'FETCH_ACCOUNTS_SUGGESTION_FOR_ACTIVITY_PHASE_MEMBERSHIPS'
  );

export const fetchAccountsSuggestionForActivityPhaseMemberships =
  createActionWithMeta<
    FetchAccountsSuggestionForActivityPhaseMembershipsParams,
    FetchAccountsSuggestionForActivityPhaseMembershipsMeta
  >(FETCH_ACCOUNTS_SUGGESTION_FOR_ACTIVITY_PHASE_MEMBERSHIPS.TRIGGER);

const fetchAccountsSuggestionForActivityPhaseMembershipsActionCreatorsMap =
  createActionCreatorsMap<
    FetchAccountsSuggestionForActivityPhaseMembershipsParams,
    FetchAccountsSuggestionForActivityPhaseMembershipsSuccessResponse,
    FetchAccountsSuggestionForActivityPhaseMembershipsMeta
  >(FETCH_ACCOUNTS_SUGGESTION_FOR_ACTIVITY_PHASE_MEMBERSHIPS);

/* -------------------------------------------------------------------------- */
export const accountsSuggestionActionCreatorsMap = {
  fetchAccountsSuggestionForProjects:
    fetchAccountsSuggestionForProjectsActionCreatorsMap,
  fetchAccountsSuggestionForPhases:
    fetchAccountsSuggestionForPhasesActionCreatorsMap,
  fetchAccountsSuggestionForActivityPhaseMemberships:
    fetchAccountsSuggestionForActivityPhaseMembershipsActionCreatorsMap
};

export const accountsSuggestionActions = {
  fetchAccountsSuggestionForProjects,
  fetchAccountsSuggestionForPhases,
  fetchAccountsSuggestionForActivityPhaseMemberships
};
