import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { getSelectedTeamId } from 'selectors';
import { fetchRegions } from 'SettingsModule/actionCreators/settings/region';
import { RegionsTable } from './RegionsTable';

export const RegionsTableContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchRegions({ teamId }));
    }
  }, [dispatch, teamId]);

  return <RegionsTable />;
};
