import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  ReactNode,
  useMemo
} from 'react';
import styled from 'styled-components';
import { useSplitScreenDivider } from 'TimelinesModule/components/SplitScreen/useSplitScreenDivider';
import { SplitDividerVariant, SplitScreenConfig } from './types';
import cn from 'classnames';
import { PartialDeep } from 'type-fest';
import merge from 'lodash/merge';
import { DIVIDER_HEIGHT_BY_VARIANT, SPLIT_SCREEN_Z_INDICES } from './constants';

interface SplitScreenProps {
  className?: string;
  mainView: ReactNode;
  splitView: ReactNode;
  splitScreenConfig?: PartialDeep<SplitScreenConfig>;
  onSplitChange?: (isSplitting: boolean) => void;
}

export interface SplitScreenRef {
  split: () => void;
  collapse: () => void;
  toggle: () => void;
}

const defaultSplitScreenConfig: SplitScreenConfig = {
  minHeights: {
    mainView: 30,
    splitView: 30
  },
  initialSplitDividerPosition: '50%'
};

export const SplitScreen = forwardRef<SplitScreenRef, SplitScreenProps>(
  (
    {
      className,
      mainView,
      splitView,
      splitScreenConfig = defaultSplitScreenConfig,
      onSplitChange
    },
    ref
  ) => {
    const boundaryRef = useRef<HTMLDivElement>(null);

    const splitScreenConfigToUse: SplitScreenConfig = useMemo(
      () => merge({}, splitScreenConfig, defaultSplitScreenConfig),
      [splitScreenConfig]
    );

    const {
      SplitScreenDivider,
      isSplitting,
      split,
      collapse,
      mainViewContainerStyle,
      splitViewContainerStyle
    } = useSplitScreenDivider({
      boundaryRef: boundaryRef.current,
      onSplitChange,
      ...splitScreenConfigToUse
    });

    const toggle = useCallback(
      () => (isSplitting ? collapse() : split()),
      [collapse, isSplitting, split]
    );

    useImperativeHandle(
      ref,
      () => ({
        split,
        collapse,
        toggle
      }),
      [collapse, split, toggle]
    );

    const shouldShowCloseButton =
      splitScreenConfigToUse.splitDividerVariant ===
        SplitDividerVariant.Thick && splitScreenConfigToUse.showCloseButton;

    return (
      <Container
        ref={boundaryRef}
        className={cn('splitScreenContainer', className)}
      >
        <MainViewContainer
          className={cn('mainViewContainer', { isSplitting })}
          style={mainViewContainerStyle}
        >
          {mainView}
        </MainViewContainer>
        {isSplitting && (
          <>
            <DividerContainer>
              <SplitScreenDivider />
              {shouldShowCloseButton && (
                <CloseButton onClick={collapse}>Close</CloseButton>
              )}
            </DividerContainer>
            <SplitViewContainer
              className="splitViewContainer"
              style={splitViewContainerStyle}
            >
              {splitView}
            </SplitViewContainer>
          </>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const MainViewContainer = styled.div`
  &.isSplitting {
    overflow-y: auto;
  }
`;

const SplitViewContainer = styled.div`
  overflow-y: auto;
`;

const DividerContainer = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.colors.colorCalendarBlue};
  color: ${({ theme }) => theme.colors.colorPureWhite};
  position: absolute;
  right: 4px;
  top: 0;
  z-index: ${SPLIT_SCREEN_Z_INDICES.CloseButton};
  height: ${DIVIDER_HEIGHT_BY_VARIANT.Thick}px;
  padding: 0 10px;
  margin: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
`;
