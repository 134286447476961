export const MAPPING_STATUSES = {
  Incomplete: 'Incomplete',
  Completed: 'Completed',
  Processing: 'Processing',
  Deleted: 'Deleted'
} as const;

export const CSV_IMPORT_DATA_TYPES = {
  time_entry: 'time_entry',
  work_plan: 'work_plan',
  employee: 'employee',
  project: 'project'
} as const;

export const CSV_IMPORT_DATA_TYPES_FOR_DISPLAY = {
  [CSV_IMPORT_DATA_TYPES.time_entry]: 'Timesheet',
  [CSV_IMPORT_DATA_TYPES.work_plan]: 'Work Plan',
  [CSV_IMPORT_DATA_TYPES.employee]: 'Member'
};
