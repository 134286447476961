import React from 'react';
import SvgIcon from 'components/SvgIcon';

const HeaderExpandIcon = ({
  strokecolor = '#0074d9',
  fill = '#4a4a4a',
  height = '24',
  width = '24'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.5" y="3.5" width="17" height="17" rx="8.5" stroke="#d9d9d9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 15.3745L13.0533 14.2232L15.6743 11.3585C15.7746 11.2489 15.7746 11.0707 15.6743 10.9606L14.9849 10.2072C14.8846 10.097 14.7216 10.097 14.6213 10.2072L12.0004 13.0718L9.37893 10.2072C9.2782 10.097 9.1156 10.097 9.01522 10.2072L8.32587 10.9606C8.22514 11.0707 8.22514 11.2484 8.32587 11.3585L10.9465 14.2232L11.9999 15.3745Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default HeaderExpandIcon;
