import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';
const {
  colors: { colorRoyalBlue }
} = theme;

const CloseSmallModal = ({ fill = colorRoyalBlue }) => (
  <SvgIcon
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50833 1L4.99167 4.5125L1.49167 1.01667L1 1.50833L4.5 5L1 8.49583L1.49167 8.98333L4.99167 5.49167L8.50833 9L9 8.5125L5.48333 5L9 1.49167L8.50833 1Z"
      fill={fill}
      stroke="#828282"
    />
  </SvgIcon>
);

export default CloseSmallModal;
