import qs from 'qs';
import { callApi } from 'appUtils/api';
import { fetchPhaseSuggestionsForTimesheetAccountsActionPayload } from 'SuggestionModule/types';

export const fetchPhaseSuggestionsForTimesheetAccounts = (
  _,
  token: string,
  params: fetchPhaseSuggestionsForTimesheetAccountsActionPayload
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(params, {
    arrayFormat: 'brackets'
  });
  const url = `suggestions/timesheet_accounts/?${stringifiedQueryParams}`;

  return callApi(url, token, fetchInit);
};

export const phaseSuggestionsApis = {
  fetchPhaseSuggestionsForTimesheetAccounts
};
