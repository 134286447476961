import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { FILTER_PAGES, VIEW_BY } from 'appConstants/workload';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
// import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
// import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
// import ActivityLogModal, {
//   MODAL_LOCATIONS
// } from 'components/ActivityLog/ActivityLogModal';

const viewBy = VIEW_BY.REQUESTS;
const pageName = FILTER_PAGES.WORK_PLANS_TABLE;

const WorkplanRequestsTableContainer = lazy(() =>
  import('./WorkplanRequestsTableContainer')
);

const WorkplanRequestsTab = ({
  activeFilter,
  viewBy,
  pageName,
  workplanStateId
}) => {
  return (
    <DynamicModuleLoader modules={[]}>
      <Suspense fallback={<div />}>
        <WorkplanRequestsTableContainer
          activeFilter={activeFilter}
          viewBy={viewBy}
          pageName={pageName}
          workplanStateId={workplanStateId}
        />
        {/* <ActivityLogModal modalLocation={MODAL_LOCATIONS.WORKPLAN_REQUESTS} /> */}
      </Suspense>
    </DynamicModuleLoader>
  );
};

const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
const mapStateToProps = (state) => {
  return {
    activeFilter: getActiveWorkloadPlannerFilter(state, { pageName, viewBy }),
    viewBy,
    pageName,
    workplanStateId: 'workplan-requests-tab'
  };
};

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkplanRequestsTab);
