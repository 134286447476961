import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { Field, Label } from 'SettingsModule/components/styles';
import styled from 'styled-components';
import {
  getSelectedTeamId,
  getSelectedTeamViewSettings
} from 'TeamsModule/selectors';
import { OrganizationSettingsPopover } from '../shared/OrganizationSettingsPopover';
import { WorkPlanLock, WorkPlanLockLabel } from './constants';
import { updateTeamViewSettings } from 'TeamsModule/actionCreators';
import { TeamWorkloadViewSettings } from 'TeamsModule/models/team';
import { DropdownItemContainer } from '../styles';

export const WorkplanLockSetting = () => {
  const teamViewSettings = useAppSelector(getSelectedTeamViewSettings);
  const teamId = useAppSelector(getSelectedTeamId);
  const { work_load: workloadSettings } = teamViewSettings ?? {};
  const { work_plan_lock: workPlanLock } = workloadSettings ?? {};

  const dispatch = useAppDispatch();

  const { percentageOfWorkdayLockFlag } = useFeatureFlags();

  const handleClick = ({ key: workPlanLockValue }: { key: WorkPlanLock }) => {
    const updatedWorkloadSettings = {
      ...workloadSettings,
      work_plan_lock: workPlanLockValue
    } as TeamWorkloadViewSettings;

    if (teamId)
      dispatch(
        updateTeamViewSettings({
          viewSettings: { work_load: updatedWorkloadSettings },
          teamId
        })
      );
  };

  // Once percentageOfWorkdayLockFlag is removed, can move this out of component
  const options = {
    [WorkPlanLock.HoursPerDay]: {
      label: WorkPlanLockLabel[WorkPlanLock.HoursPerDay],
      item: (
        <DropdownItemContainer
          data-for="app-tooltip"
          data-tip="Lock the Hours Planned per day"
          data-effect="solid"
          data-html
          data-class="center"
          data-offset="{'top': -8}"
        >
          {WorkPlanLockLabel[WorkPlanLock.HoursPerDay]}
          <StyledFloatHelpIcon
            className={undefined}
            width={8}
            height={8}
            dataTip={undefined}
          />
        </DropdownItemContainer>
      )
    },
    ...(percentageOfWorkdayLockFlag
      ? {
          [WorkPlanLock.PercentWorkday]: {
            label: WorkPlanLockLabel[WorkPlanLock.PercentWorkday],
            item: (
              <DropdownItemContainer
                data-for="app-tooltip"
                data-tip="Lock the % of the Assigned<br/>Member's Workday"
                data-effect="solid"
                data-html
                data-class="center"
                data-offset="{'top': -8}"
              >
                {WorkPlanLockLabel[WorkPlanLock.PercentWorkday]}
                <StyledFloatHelpIcon
                  className={undefined}
                  width={8}
                  height={8}
                  dataTip={undefined}
                />
              </DropdownItemContainer>
            )
          }
        }
      : {}),
    [WorkPlanLock.TotalHrs]: {
      label: WorkPlanLockLabel[WorkPlanLock.TotalHrs],
      item: (
        <DropdownItemContainer
          data-for="app-tooltip"
          data-tip="Lock the Total Hours of<br/>the Work Plan"
          data-effect="solid"
          data-html
          data-class="center"
          data-offset="{'top': -8}"
        >
          {WorkPlanLockLabel[WorkPlanLock.TotalHrs]}
          <StyledFloatHelpIcon
            className={undefined}
            width={8}
            height={8}
            dataTip={undefined}
          />
        </DropdownItemContainer>
      )
    }
  };

  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Set the default locking for when Work<br/>
            Plans dates are modified. Defaults can be<br/>
            personalized by individual users and<br/>
            changed for individual work plans."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Work Plan Locking Default
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <OrganizationSettingsPopover
          options={options}
          currentlySelectedKey={workPlanLock}
          showEllipsisTooltip={false}
          onClick={handleClick}
        />
      </Field>
    </>
  );
};

const StyledFloatHelpIcon = styled(FloatHelpIcon)`
  position: relative;
  top: -5px;
  left: 3px;
`;
