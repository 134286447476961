import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES,
  CsvImportFieldName
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, resetTempRef, isNumeric } from './utils';

import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';
interface AdditionalParams {
  // { projectTitle: { projectNumber: { phaseName: { activityTitle: feeTargetValue } } } }
  activityFeeTargetOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>;
  temporaryActivityFeeTargetOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>;
  enteredFieldName: CsvImportFieldName;
  notMatchingFeeTargetErrorMessage: string;
}

export const activityFeeTargetValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const {
    activityFeeTargetOption,
    temporaryActivityFeeTargetOption,
    enteredFieldName,
    notMatchingFeeTargetErrorMessage
  } = additionalParams;

  const enteredValues = getRowValuesForEntityType(row, entityType);

  const {
    enteredProjectTitle,
    enteredProjectNumber,
    enteredPhaseName,
    enteredActivityTitle
  } = enteredValues;

  const enteredActivityFeeTarget = enteredValues[enteredFieldName];

  let res: string | boolean = true;

  // allows empty activity title, but if activity title is empty, then it is not allowed to enter anything for activity fee/target.

  if (!enteredProjectTitle || !enteredProjectNumber) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else res = 'Project number is required';
    resetTempRef(temporaryActivityFeeTargetOption, isLastRow);
    return res;
  }

  const matchedFeeTargetInTemporaryHash =
    temporaryActivityFeeTargetOption.current[enteredProjectTitle]?.[
      enteredProjectNumber
    ]?.[enteredPhaseName]?.[enteredActivityTitle];

  // allow empty as an option in the hashes

  if (enteredActivityFeeTarget === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else if (enteredActivityFeeTarget && !enteredActivityTitle) {
    // Empty actvitiy title but with non empty fee/target entered
    return ERROR_MESSAGES.nonEmptyFieldForDefaultActivity;
  } else if (enteredActivityFeeTarget && !isNumeric(enteredActivityFeeTarget)) {
    // if entered non empty fee/target is not numeric then it is not valid value
    res = ERROR_MESSAGES.invalidFeeTarget;
  } else {
    // if there is no match in temporary hash then add it to temporary hash
    if (matchedFeeTargetInTemporaryHash === undefined) {
      temporaryActivityFeeTargetOption.current = {
        ...temporaryActivityFeeTargetOption.current,
        [enteredProjectTitle]: {
          ...temporaryActivityFeeTargetOption.current[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...temporaryActivityFeeTargetOption.current[enteredProjectTitle]?.[
              enteredProjectNumber
            ],
            [enteredPhaseName]: {
              ...temporaryActivityFeeTargetOption.current[
                enteredProjectTitle
              ]?.[enteredProjectNumber]?.[enteredPhaseName],
              [enteredActivityTitle]: enteredActivityFeeTarget
            }
          }
        }
      };
      // also update the value in activityFeeTargetOption
      activityFeeTargetOption.current = {
        ...activityFeeTargetOption.current,
        [enteredProjectTitle]: {
          ...activityFeeTargetOption.current[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...activityFeeTargetOption.current[enteredProjectTitle]?.[
              enteredProjectNumber
            ],
            [enteredPhaseName]: {
              ...activityFeeTargetOption.current[enteredProjectTitle]?.[
                enteredProjectNumber
              ]?.[enteredPhaseName],
              [enteredActivityTitle]: enteredActivityFeeTarget
            }
          }
        }
      };
    } else if (matchedFeeTargetInTemporaryHash !== enteredActivityFeeTarget) {
      // there is an option stored in the temporary hash, but it does not match to what the user entered
      res = notMatchingFeeTargetErrorMessage;
    }
  }
  resetTempRef(temporaryActivityFeeTargetOption, isLastRow);
  return res;
};
