import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import moment from 'moment';
import { getRowValuesForEntityType } from './utils';
import { RowValidator } from 'CsvImportsModule/types';

export const dateValidator: RowValidator = ({ row, entityType }) => {
  const { enteredDate } = getRowValuesForEntityType(row, entityType);
  const isCorrectFormat = moment(enteredDate, 'YYYY-MM-DD', true).isValid();

  if (enteredDate === TRAINING_DATA_REQUIRED_STRING) return false;
  if (isCorrectFormat) return true;
  else return ERROR_MESSAGES.invalidDateFormat;
};
