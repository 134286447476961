import styled from 'styled-components';
import { MenuItemContainer, StyledKaratRight } from './styles';
import cn from 'classnames';
import { dependencyType, dependencyTypeStrings } from '../../constants';
import { CommonDependencyItem } from '../../types';

export const BaseTypeMenuRenderer = ({
  baseType,
  selected
}: {
  baseType: NonNullable<CommonDependencyItem['baseType']>;
  selected: boolean;
}) => {
  return (
    <StyledMenuItemContainer className={cn({ selected })}>
      {dependencyTypeStrings[dependencyType[baseType]]} <StyledKaratRight />
    </StyledMenuItemContainer>
  );
};

const StyledMenuItemContainer = styled(MenuItemContainer)`
  display: flex;
  width: 100px;
  justify-content: space-between;
  align-items: center;
`;
