import { ROW_CLASSES, ROW_TYPES } from '../tableConfigs';
import {
  EntityRateListItem,
  ListItem,
  RateArchiveListItem,
  RateGroupListItem,
  RateGroupToggleListItem,
  ToggleProps
} from '../types';
import {
  EntityId,
  EntityRate,
  EntityRateId
} from 'RatesModule/models/EntityRate';
import { RATE_GROUP_DEFAULT_NAME } from 'RatesModule/constants';
import { RateEntity } from 'RatesModule/models/RateEntity';
import { RateGroup, RateGroupId } from 'RatesModule/models/RateGroup';
import { ComponentProps, useCallback } from 'react';
import { useSet } from 'react-use';
import cn from 'classnames';
import { CurrencyCode } from 'CurrencyModule/types';
import {
  EntityRateByEntity,
  TeamRatesEntitiesState,
  TeamRatesEntityRatesState,
  TeamRatesRateGroupsState,
  TeamRatesRatesState
} from 'RatesModule/reducers/types';
import { Rate } from 'RatesModule/models/Rate';
import orderBy from 'lodash/orderBy';
import { AddEditEntityRateModal } from 'RatesModule/components/AddEditEntityRateModal/AddEditEntityRateModal';
import { DeleteRateGroupModal } from 'RatesModule/components/DeleteRateGroupModal';
import { AddEditRateGroupModal } from 'RatesModule/components/AddEditRateGroupModal';
import { RateGroupMenu } from '../RateGroupMenu';
import { AddEntityMenu } from '../AddEntityMenu';
import { updateActivity } from 'actionCreators';
import { ValueOf } from 'type-fest';

const entityRateListItemBuilder = ({
  billEntityRate,
  billRate,
  costEntityRate,
  costRate,
  currencyCode,
  entityId,
  isRateGroupArchived,
  onUpdateEntity,
  openAddEditEntityRateModal,
  rateEntity,
  rateGroupId
}: {
  billEntityRate?: EntityRate;
  billRate?: Rate;
  costEntityRate?: EntityRate;
  costRate?: Rate;
  currencyCode: CurrencyCode;
  entityId: EntityId;
  isRateGroupArchived: boolean;
  onUpdateEntity: (
    rateEntity: RateEntity,
    params: Parameters<typeof updateActivity>[0]
  ) => void;
  openAddEditEntityRateModal: (
    props: Pick<
      ComponentProps<typeof AddEditEntityRateModal>,
      'currencyCode' | 'entityId' | 'isCostRate' | 'rateGroupId'
    >
  ) => void;
  rateEntity: RateEntity;
  rateGroupId: RateGroupId;
}): EntityRateListItem => ({
  className: cn({
    archivedRateGroup: isRateGroupArchived,
    [ROW_CLASSES.archivedRateEntity]: rateEntity.archived
  }),
  currencyCode,
  billEntityRate,
  billRate,
  costEntityRate,
  costRate,
  editEntityRates: (isCostRate) =>
    openAddEditEntityRateModal({
      currencyCode,
      entityId,
      isCostRate,
      rateGroupId
    }),
  id: `entityRate-${entityId}`,
  isRateGroupArchived,
  rowHeight: 40,
  rowType: ROW_TYPES.rateRow,
  rateEntity,
  updateEntity: (params) => onUpdateEntity(rateEntity, params)
});

export const useRateGroupGroupedListsBuilder = ({
  getIsOpen,
  onUpdateEntity,
  openAddEditEntityRateModal,
  openAddEditRateGroupModal,
  openDeleteRateGroupModal,
  rateGroupMenuId,
  toggleAddEntityMenu,
  toggleCollapse,
  toggleRateGroupMenu
}: ToggleProps & {
  onUpdateEntity: (
    rateEntity: RateEntity,
    params: Parameters<typeof updateActivity>[0]
  ) => void;
  openAddEditEntityRateModal: (
    props: Pick<
      ComponentProps<typeof AddEditEntityRateModal>,
      'currencyCode' | 'entityId' | 'isCostRate' | 'rateGroupId'
    >
  ) => void;
  openAddEditRateGroupModal: (
    props: Pick<ComponentProps<typeof AddEditRateGroupModal>, 'rateGroup'>
  ) => void;
  openDeleteRateGroupModal: (
    props: Pick<ComponentProps<typeof DeleteRateGroupModal>, 'rateGroup'>
  ) => void;
  rateGroupMenuId?: RateGroupId;
  toggleAddEntityMenu: (
    props: Pick<ComponentProps<typeof AddEntityMenu>, 'target'>
  ) => void;
  toggleRateGroupMenu: (
    props: Pick<
      ComponentProps<typeof RateGroupMenu>,
      'deleteRateGroup' | 'editRateGroup' | 'rateGroup' | 'target'
    >
  ) => void;
}) => {
  const [visibleArchivedRates, { toggle: toggleVisibleArchivedRates }] =
    useSet<RateGroupId>();

  const toggleArchivedRates = useCallback(
    (rateGroupId: RateGroupId) => toggleVisibleArchivedRates(rateGroupId),
    [toggleVisibleArchivedRates]
  );

  const listItemsBuilder = useCallback(
    ({
      entityRatesByEntity,
      entityRatesHash,
      rateEntitiesHash,
      rateEntitiesOrder,
      rateGroup,
      ratesHash
    }: {
      entityRatesByEntity: ValueOf<TeamRatesEntityRatesState['byRateGroup']>;
      entityRatesHash: TeamRatesEntityRatesState['hash'];
      rateEntitiesHash: TeamRatesEntitiesState['hash'];
      rateEntitiesOrder: TeamRatesEntitiesState['order'];
      rateGroup: RateGroup;
      ratesHash: TeamRatesRatesState['hash'];
    }) => {
      const rateGroupId = rateGroup.id;
      const isRateGroupArchived = !!rateGroup.archived_at;
      const currencyCode = rateGroup.currency;

      const getRates = (entityRateId?: EntityRateId) => {
        const entityRate = entityRateId
          ? entityRatesHash[entityRateId]
          : undefined;
        return {
          entityRate,
          rate: entityRate ? ratesHash[entityRate.rate_id] : undefined
        };
      };

      const entityRatesData = Object.entries(entityRatesByEntity).map<{
        billEntityRate?: EntityRate;
        billRate?: Rate;
        costEntityRate?: EntityRate;
        costRate?: Rate;
        entityId: number;
        rateEntity?: RateEntity;
      }>(
        ([
          entityIdAsStr,
          { currentBillRate: billRateId, currentCostRate: costRateId }
        ]) => {
          const entityId = parseInt(entityIdAsStr);

          const rateEntity = rateEntitiesHash[entityId];

          const { entityRate: billEntityRate, rate: billRate } =
            getRates(billRateId);
          const { entityRate: costEntityRate, rate: costRate } =
            getRates(costRateId);

          return {
            billEntityRate,
            billRate,
            costEntityRate,
            costRate,
            entityId,
            rateEntity
          };
        }
      );
      const existingRateEntityIds = new Set(
        entityRatesData.map(({ entityId }) => entityId)
      );

      const entityRatesAndEntitiesData = rateEntitiesOrder.reduce((acc, id) => {
        const rateEntity = rateEntitiesHash[id];
        if (!existingRateEntityIds.has(id) && rateEntity)
          acc.push({
            entityId: id,
            rateEntity
          });

        return acc;
      }, entityRatesData);

      const { archived, list } = orderBy(
        entityRatesAndEntitiesData,
        [
          (item) => parseFloat(item.billRate?.rate ?? '-Infinity'),
          (item) => parseFloat(item.costRate?.rate ?? '-Infinity'),
          (item) => item.rateEntity?.name.toLocaleLowerCase() ?? ''
        ],
        ['desc', 'desc', 'asc']
      ).reduce<{
        archived: ListItem[];
        list: ListItem[];
      }>(
        (
          acc,
          {
            billEntityRate,
            billRate,
            costEntityRate,
            costRate,
            entityId,
            rateEntity
          }
        ) => {
          const { archived, list } = acc;

          if (rateEntity)
            (rateEntity?.archived ? archived : list).push(
              entityRateListItemBuilder({
                billEntityRate,
                billRate,
                costEntityRate,
                costRate,
                entityId,
                currencyCode,
                isRateGroupArchived,
                onUpdateEntity,
                openAddEditEntityRateModal,
                rateEntity,
                rateGroupId
              })
            );

          return acc;
        },
        { archived: [], list: [] }
      );

      if (archived.length) {
        const isArchivedVisible = visibleArchivedRates.has(rateGroupId);

        const rateArchiveListItem: RateArchiveListItem = {
          id: `rateGroup-${rateGroupId}-archived`,
          isArchivedVisible,
          numArchivedRates: archived.length,
          rateGroupId,
          rowHeight: 32,
          rowType: ROW_TYPES.rateArchiveRow,
          toggleArchived: toggleArchivedRates
        };
        list.push(...(isArchivedVisible ? archived : []), rateArchiveListItem);
      }

      return list;
    },
    [
      onUpdateEntity,
      openAddEditEntityRateModal,
      toggleArchivedRates,
      visibleArchivedRates
    ]
  );

  const rateGroupGroupedListsBuilder = useCallback(
    ({
      entityRatesHash,
      entityRatesByRateGroup,
      rateEntitiesHash,
      rateEntitiesOrder,
      rateGroupsHash,
      rateGroupsOrder,
      ratesHash
    }: Omit<
      Parameters<typeof listItemsBuilder>[0],
      'entityRatesByEntity' | 'rateGroup'
    > & {
      entityRatesByRateGroup: TeamRatesEntityRatesState['byRateGroup'];
      rateGroupsHash: TeamRatesRateGroupsState['hash'];
      rateGroupsOrder: RateGroupId[];
    }) => {
      const { archived, list } = rateGroupsOrder.reduce<{
        archived: ListItem[];
        list: ListItem[];
      }>(
        (acc, rateGroupId) => {
          const rateGroup = rateGroupsHash[rateGroupId];
          if (!rateGroup) return acc;

          // Ordered Ids of this particular rate group
          const entityRatesByEntity: Record<EntityId, EntityRateByEntity> =
            entityRatesByRateGroup[rateGroupId] ?? {};
          const isRateGroupArchived = Boolean(rateGroup.archived_at);
          const isSystemDefaultRateGroup =
            RATE_GROUP_DEFAULT_NAME.test(rateGroup.name) &&
            rateGroup.is_default;
          const isListOpen =
            isSystemDefaultRateGroup || Boolean(getIsOpen(rateGroupId));
          const list = isRateGroupArchived ? acc.archived : acc.list;

          if (rateGroupsOrder.length > 1 || !isSystemDefaultRateGroup) {
            const nameListItem: RateGroupToggleListItem = {
              id: `rateGroup-${rateGroupId}-name`,
              isMenuOpen: rateGroupMenuId === rateGroupId,
              isOpen: isListOpen,
              rateGroup,
              rowHeight: 32,
              rowType: ROW_TYPES.rateGroupNameRow,
              toggleAddEntityMenu: (target) => toggleAddEntityMenu({ target }),
              toggleCollapse: () => toggleCollapse(rateGroupId),
              toggleMenu: (target) =>
                toggleRateGroupMenu({
                  deleteRateGroup: () =>
                    openDeleteRateGroupModal({ rateGroup }),
                  editRateGroup: () => openAddEditRateGroupModal({ rateGroup }),
                  rateGroup,
                  target
                })
            };
            list.push(nameListItem);

            if (isRateGroupArchived || rateGroup.is_default) {
              const archivedIndicatorListItem: RateGroupListItem = {
                id: `rateGroup-${rateGroupId}-indicator`,
                rateGroup,
                rowHeight: 16,
                rowType: ROW_TYPES.rateGroupIndicatorRow
              };
              list.push(archivedIndicatorListItem);
            }
          }

          const listItems: ListItem[] =
            Object.keys(entityRatesByEntity).length || rateEntitiesOrder.length
              ? listItemsBuilder({
                  entityRatesHash,
                  entityRatesByEntity,
                  rateEntitiesHash,
                  rateEntitiesOrder,
                  rateGroup,
                  ratesHash
                })
              : [
                  {
                    id: `rateGroup-${rateGroupId}-empty`,
                    rowType: ROW_TYPES.emptyGroupRow,
                    rowHeight: 40
                  }
                ];

          list.push({
            addEmptyRow: isListOpen,
            id: `rateGroup-${rateGroupId}-list`,
            isFullyLoaded: true,
            isList: true,
            isOpen: isListOpen,
            listItems,
            rowHeight: 24,
            rowType: ROW_TYPES.rateGroupHeaderRow,
            skipHeader: !isListOpen
          });

          return acc;
        },
        { archived: [], list: [] }
      );

      list.push(...archived);

      return list;
    },
    [
      getIsOpen,
      listItemsBuilder,
      openAddEditRateGroupModal,
      openDeleteRateGroupModal,
      rateGroupMenuId,
      toggleAddEntityMenu,
      toggleCollapse,
      toggleRateGroupMenu
    ]
  );

  return rateGroupGroupedListsBuilder;
};
