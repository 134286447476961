import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {
  setEditTaskId,
  updateEditedTasks,
  updateEditedHomeTasks
} from 'actionCreators';
import { isSameDay } from 'appUtils/momentUtils';
import { keyifyDate } from 'appUtils/plannerUtils';
import FlagIcon from 'icons/FlagIcon';
import CalendarIcon from 'icons/CalendarIcon';
import DatePicker from 'components/DatePicker';
import {
  makeGetBulkUpdateTaskDisplay,
  makeGetBulkUpdateHomeTaskDisplay,
  getAuth
} from 'selectors';
import theme from 'theme';

const {
  colors: { colorDeleteRed }
} = theme;

const moment = extendMoment(Moment);

class BulkUpdateModalDateSelector extends React.Component {
  updateDate = (date) => {
    const {
      task,
      homeTaskDisplay,
      updateEditedTasks,
      updateEditedHomeTasks,
      homeTasksView,
      calendarType
    } = this.props;
    const newDate = moment(date);
    if (!isSameDay(task[calendarType], date)) {
      const updatePayload = [
        {
          ...task,
          ...homeTaskDisplay,
          [calendarType]: newDate,
          dateKey: keyifyDate(task.schedule_start)
        }
      ];
      homeTasksView
        ? updateEditedHomeTasks(updatePayload)
        : updateEditedTasks(updatePayload);
    }
  };

  renderIcon = () => {
    const { calendarType } = this.props;

    return calendarType === 'due_at' ? (
      <FlagIcon width={10} height={13} />
    ) : (
      <CalendarIcon width={14} height={14} fill={colorDeleteRed} />
    );
  };

  render() {
    const { task, taskDisplay, homeTaskDisplay, calendarType } = this.props;

    const displayDate =
      taskDisplay ||
      (homeTaskDisplay ? homeTaskDisplay[calendarType] : task[calendarType]);

    return (
      <DatePicker
        taskIsEditing={false}
        selectedValue={displayDate}
        icon={this.renderIcon()}
        taskDateType={calendarType === 'due_at' ? 'due' : 'schedule'}
        onChange={this.updateDate}
        showDateInput
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getBulkUpdateTaskDisplay = makeGetBulkUpdateTaskDisplay();
  const getBulkUpdateHomeTaskDisplay = makeGetBulkUpdateHomeTaskDisplay();
  const mapStateToProps = (state, ownProps) => ({
    auth: getAuth(state),
    taskDisplay: getBulkUpdateTaskDisplay(state, ownProps),
    homeTaskDisplay: getBulkUpdateHomeTaskDisplay(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  setEditTaskId,
  updateEditedTasks,
  updateEditedHomeTasks
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(BulkUpdateModalDateSelector)
);
