import { useRef, useState, useEffect } from 'react';
import { makeGetOwnActivityPhase, getAllActivityRowInfo } from 'selectors';
import { Modal, Dropdown } from 'reactstrap';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer
} from 'components/styles';
import { connect } from 'react-redux';
import {
  makeGetOwnPhase,
  makeGetUnformattedPhaseTotal
} from 'BudgetModule/selectors';
import {
  openActivityPhaseModal,
  updateActivity,
  fetchPhaseTotals
} from 'actionCreators';
import { updateActivityPhase } from 'BudgetModule/actionCreators';
import ActivitiesDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/ActivitiesDropdown';
import { CONTRACT_TYPES } from 'BudgetModule/components/BudgetTable/constants';

import {
  StyledModalHeader,
  StyledModalTitle,
  StyledModalBody,
  StyledModalFooter,
  PaddingContainer,
  FeeInput,
  PhaseNumberInput,
  PercentageInput,
  EstimatedCostInput,
  BottomInputsContainer,
  StyledLabel,
  StyledInputContainer,
  StyledDollarSign,
  DeleteButton,
  ActivityPhaseNameInput,
  DropdownArrow,
  ActivityPhaseNameContainer,
  StyledDropdownToggle,
  StyledDropdownMenu,
  StyledQBDownArrow,
  StyledDropdownItem
} from './styles';

import BillableToggle from 'BudgetModule/components/BudgetTable/BillableToggle';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';

const noop = () => {};

const FEE_TYPE_OPTIONS = [
  { value: 'Fixed Fee', label: 'Fixed Fee' },
  { value: 'Hourly', label: 'Hourly' },
  { value: 'Internal', label: 'Internal' }
];

const FIXED_BUDGET_WITH_OPTIONS = {
  spent_and_planned: 'Total Spent + Planned Time',
  member_estimates: 'Total Member Time Budget',
  activity_phase_estimate: 'Use Entered Budget',
  activity_phase_total: 'Use Fee as Budget',
  // If the phase is budgeted with work_category_estimates, this option should not show
  phase_estimate_percentage: 'Use % of Phase Budget'
};

const HOURLY_BUDGET_WITH_OPTIONS = {
  spent_and_planned: 'Total Spent + Planned Time',
  activity_phase_estimate: 'Use Entered Budget',
  member_estimates: 'Total Member Time Budget',
  // If the phase is budgeted with work_category_estimates, this option should not show
  phase_estimate_percentage: 'Use % of Phase Budget'
};

const INTERNAL_BUDGET_WITH_OPTIONS = {
  spent_and_planned: 'Total Spent + Planned Time',
  activity_phase_estimate: 'Use Entered Budget',
  member_estimates: 'Total Member Time Budget',
  // If the phase is budgeted with work_category_estimates, this option should not show
  phase_estimate_percentage: 'Use % of Phase Budget'
};

/* ----------------------------------------------------------------------- */

const ActivityPhaseFeeModal = ({
  phaseId,
  phase,
  handleClose,
  openActivityPhaseModal,
  activityPhaseId, // Used by makeGetOwnActivityPhase
  activityPhase,
  activityHash,
  updateActivityPhase,
  updateActivity,
  phaseTotals
}) => {
  const activity = activityHash[activityPhase.activity_id];
  const phaseFee = phase?.estimated_cost;

  const [isShowingDropdown, setIsShowingDropdown] = useState(false);
  const [budgetWithDropdownOpen, setBudgetWithDropdownOpen] = useState(false);

  // Fee = ActivityPhase.Total
  const [fee, setFee] = useState(+activityPhase.total || 0);
  // % of the PHASE fee (This value is going to come from BE soon)
  const [feePercentage, setFeePercentage] = useState(
    +phaseFee ? Math.round((fee / phaseFee) * 100) : 0
  );

  const [feeType, setFeeType] = useState(
    activityPhase.fee_type || CONTRACT_TYPES.FIXED_FEE
  );

  const [budgetWith, setBudgetWith] = useState(
    (feeType === CONTRACT_TYPES.FIXED_FEE
      ? activityPhase.budget_fixed_fee_with
      : feeType === CONTRACT_TYPES.HOURLY
      ? activityPhase.budget_hourly_with
      : activityPhase.budget_internal_with) || 'activity_phase_estimate'
  );

  useEffect(() => {
    rebuildTooltip();
  }, [feeType, budgetWith]);

  useEffect(() => {
    setBudgetWith(
      (feeType === CONTRACT_TYPES.FIXED_FEE
        ? activityPhase.budget_fixed_fee_with
        : feeType === CONTRACT_TYPES.HOURLY
        ? activityPhase.budget_hourly_with
        : activityPhase.budget_internal_with) || 'activity_phase_estimate'
    );
  }, [
    activityPhase.budget_fixed_fee_with,
    activityPhase.budget_hourly_with,
    activityPhase.budget_internal_with,
    feeType
  ]);

  const setBillable = (billable) => {
    if (billable) {
      setFeeType(CONTRACT_TYPES.FIXED_FEE);
    } else {
      setFeeType(CONTRACT_TYPES.INTERNAL);
    }
  };

  const isDisablingUserActions = activity?.archived;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  // ESTIMATE_COST == BUDGET == TARGET
  const [estimatedCost, setEstimatedCost] = useState(
    parseFloat(activityPhase.estimated_cost) === 0
      ? 0
      : +activityPhase.estimated_cost
  );

  // Value will come from BE if it doesnt, calculate it
  const [estimatedCostPercentage, setEstimatedCostPercentage] = useState(
    activityPhase.profit_percentage
      ? 100 - activityPhase.profit_percentage
      : +activityPhase.total
      ? Math.round((estimatedCost / activityPhase.total) * 100)
      : 0
  );

  // Used when budget is a percentage of phase budget.
  const [percentageOfPhaseBudget, setPercentageOfPhaseBudget] = useState(
    activityPhase.percentage_of_phase
      ? activityPhase.percentage_of_phase
      : phaseTotals.estimated
      ? Math.round((activityPhase.estimated_cost / phaseTotals.estimated) * 100)
      : 100
  );

  const isFeeEmpty = parseFloat(fee) === 0;
  const isFeePercentageEmpty = parseFloat(feePercentage) === 0;

  const isEstimatedCostEmpty = parseFloat(estimatedCost) === 0;
  const isEstimatedCostPercentageEmpty =
    parseFloat(estimatedCostPercentage) === 0;
  const isPercentageOfPhaseBudgetEmpty =
    parseFloat(percentageOfPhaseBudget) === 0;

  const [activityPhaseName, setActivityPhaseName] = useState(activity.title);
  const [phaseNumber, setPhaseNumber] = useState(phase.phase_number || '');

  const [dirtyFlag, setDirtyFlag] = useState(false);

  const isStandardWorkCategory = !activity.is_custom;
  const [nameIsEditable, setNameIsEditable] = useState(!isStandardWorkCategory);
  const [isCreatingCustom, setIsCreatingCustom] = useState(false);

  const phaseNumberRef = useRef(null);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);

  const feeRef = useRef(null);
  const estimatedCostInputRef = useRef(null);
  const feePercentageInputRef = useRef(null);
  const estimatedCostPercentageInputRef = useRef(null);
  const percentageOfPhaseBudgetInputRef = useRef(null);

  const toggleShowDropdown = () => setIsShowingDropdown(!isShowingDropdown);

  const handleNameChange = (e) => {
    setActivityPhaseName(e.target.value);
  };

  const handleDelete = () => {
    openActivityPhaseModal({
      phaseId: phaseId,
      activityId: activity.id
    });
  };

  // When fee changes, % of phase Fee and estimated cost need to update.
  const handleFeeChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    // onValueChange gets called any time the value changes, so this
    // is necessary to prevent unexpected updating (eg. all of the input values will change)
    if (value === '' || fee === '' || +value !== +fee) {
      setFee(value);
      if (!isNaN(value)) {
        const nextEstimatedCost =
          (estimatedCostPercentage / 100) * +value === 0
            ? '0'
            : (estimatedCostPercentage / 100) * +value;
        setEstimatedCost(
          parseFloat(nextEstimatedCost) === 0 ? '0' : nextEstimatedCost
        );
        const nextFeePercentage = +phaseFee
          ? Math.round((value / phaseFee) * 100)
          : '0';
        setFeePercentage(
          parseFloat(nextFeePercentage) === 0 ? '0' : nextFeePercentage
        );
      }
    }
  };

  // When estimated cost changes need to change % of phase activity fee.
  const handleEstimatedCostChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    if (value === '' || estimatedCost === '' || +value !== +estimatedCost) {
      setEstimatedCost(parseFloat(value) === 0 ? '0' : value);
      if (!isNaN(value)) {
        const denominator =
          budgetWith === 'activity_phase_estimate'
            ? fee
            : phaseTotals.estimated;
        const nextEstimatedPercentage = formatNumWithMaxOneDecimal(
          denominator ? (+value / denominator) * 100 : 0,
          true
        );
        // if budget with is `activity_phase_estimate` update estimatedCostPercentage
        // else update percentageOfPhaseBudget
        const percentStateSetter =
          budgetWith === 'activity_phase_estimate'
            ? setEstimatedCostPercentage
            : setPercentageOfPhaseBudget;
        percentStateSetter(nextEstimatedPercentage);
      }
    }
  };

  // When fee percentage changes, update fee and estimated cost if there theres a % for the estimated cost.
  const handleFeePercentageChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    if (value === '' || feePercentage === '' || +value !== +feePercentage) {
      setFeePercentage(value);
      if (!isNaN(value)) {
        // Need to change Fee and also Target.
        const nextFee = +phaseFee ? Math.round((value / 100) * phaseFee) : '';
        setFee(parseFloat(nextFee) === 0 ? '' : nextFee);
        const nextEstimatedCost =
          (estimatedCostPercentage / 100) * +nextFee === 0
            ? '0'
            : (estimatedCostPercentage / 100) * +nextFee;
        setEstimatedCost(
          parseFloat(nextEstimatedCost) === 0 ? '0' : nextEstimatedCost
        );
      }
    }
  };

  const handlePercentageOfPhaseBudgetChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    if (
      value === '' ||
      percentageOfPhaseBudget === '' ||
      +value !== +percentageOfPhaseBudget
    ) {
      setPercentageOfPhaseBudget(value);
      if (!isNaN(value)) {
        // Need to change Budget.
        const nextEstimatedCost = +phaseTotals.estimated
          ? Math.round((value / 100) * phaseTotals.estimated)
          : '';
        setEstimatedCost(
          parseFloat(nextEstimatedCost) === 0 ? '' : nextEstimatedCost
        );
      }
    }
  };

  // When estimated cost percentage changes, update the estimate cost value.
  const handleEstimatedCostPercentageChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    if (
      value === '' ||
      estimatedCostPercentage === '' ||
      +value !== +estimatedCostPercentage
    ) {
      setEstimatedCostPercentage(value);
      if (!isNaN(value)) {
        setEstimatedCost((+value / 100) * fee);
      }
    }
  };

  const handleBlur = () => {
    if (dirtyFlag) {
      const onSuccess = [
        ...(isCreatingCustom
          ? [
              {
                successAction: (res) => {
                  // Update activity so that its custom.
                  const { payload, response } = res;
                  const activityInfo = {
                    is_custom: true,
                    id: response.activity_id
                  };
                  updateActivity(activityInfo);
                },
                selector: (payload, response) => ({ payload, response })
              }
            ]
          : []),
        {
          successAction: (res) => {
            // Fetch phase totals
            const { payload, response } = res;
            fetchPhaseTotals({
              projectId: activityPhase.project_id
            });
          },
          selector: (payload, response) => ({ payload, response })
        }
      ];
      /**
       * If we are updating the activityphase using a title,
       * Change name of ActivityPhase, BE will check if standard activity with desired name already exist,
       * Will create a new activity with same info and remove previous one.
       */
      const activityPhaseInfo = {
        id: activityPhase.id,
        total: +fee,
        estimatedCost: +estimatedCost,
        title: activityPhaseName,
        projectId: activityPhase.project_id,
        profitPercentage: 100 - estimatedCostPercentage,
        feeType,
        billable: feeType !== CONTRACT_TYPES.INTERNAL,
        percentageOfPhaseBudget: parseFloat(percentageOfPhaseBudget),
        ...(feeType === CONTRACT_TYPES.FIXED_FEE && {
          budgetFixedFeeWith: budgetWith
        }),
        ...(feeType === CONTRACT_TYPES.HOURLY && {
          budgetHourlyWith: budgetWith
        }),
        ...(feeType === CONTRACT_TYPES.INTERNAL && {
          budgetInternalWith: budgetWith
        }),
        onSuccess
      };
      updateActivityPhase(activityPhaseInfo);
    }
    clearEditing();
  };

  const clearEditing = () => handleClose();

  const checkEnterPressed = (ref) => (e) => {
    if (e.keyCode === 13) {
      ref.current.blur();
    }
  };

  const handleStandardActivityPhaseSelect = (item) => {
    setActivityPhaseName(item.title);
  };

  // Updates estimated cost based on option selected.
  useEffect(() => {
    if (
      budgetWith === 'activity_phase_estimate' &&
      feeType === CONTRACT_TYPES.FIXED_FEE &&
      fee
    ) {
      const estimatedCostBasedOnFeePercentage =
        (estimatedCostPercentage / 100) * fee;
      setEstimatedCost(estimatedCostBasedOnFeePercentage);
    }
    if (budgetWith === 'phase_estimate_percentage' && phaseTotals.estimated) {
      const estimatedCostBasedOnPhaseBudget =
        (percentageOfPhaseBudget / 100) * phaseTotals.estimated;
      setEstimatedCost(estimatedCostBasedOnPhaseBudget);
    }
    // Only run this when budget with option changes, DONT change dependencies.
  }, [budgetWith, phaseTotals.estimated, fee]);

  /**
   * Checks if any values have been edited by the user
   */
  useEffect(() => {
    if (
      ((phaseNumber || phase.phase_number) &&
        phaseNumber !== phase.phase_number) ||
      +fee !== +activityPhase.total ||
      +estimatedCost !== +activityPhase.estimated_cost ||
      activityPhaseName !== activity.title ||
      isCreatingCustom ||
      (feeType === CONTRACT_TYPES.FIXED_FEE &&
        budgetWith !== activityPhase.budget_fixed_fee_with) ||
      (feeType === CONTRACT_TYPES.HOURLY &&
        budgetWith !== activityPhase.budget_hourly_with) ||
      (feeType === CONTRACT_TYPES.INTERNAL &&
        budgetWith !== activityPhase.budget_internal_with) ||
      percentageOfPhaseBudget !== activityPhase.percentage_of_phase ||
      feeType !== activityPhase.fee_type
    ) {
      setDirtyFlag(true);
    } else {
      setDirtyFlag(false);
    }
  }, [
    estimatedCost,
    phase.phase_number,
    activityPhaseName,
    phaseNumber,
    activity.title,
    fee,
    feePercentage,
    isCreatingCustom,
    activityPhase.billable,
    feeType,
    activityPhase.budget_fixed_fee_with,
    activityPhase.budget_hourly_with,
    activityPhase.budget_internal_with,
    budgetWith,
    activityPhase.total,
    activityPhase.estimated_cost,
    activityPhase.percentage_of_phase,
    percentageOfPhaseBudget,
    activityPhase.fee_type
  ]);

  const isValid = !(
    isNaN(fee) ||
    isNaN(feePercentage) ||
    isNaN(estimatedCost) ||
    isNaN(estimatedCostPercentage) ||
    activityPhaseName?.trim() === ''
  );

  const handleAddCustom = () => {
    setNameIsEditable(true);
    setIsCreatingCustom(true);
    setIsShowingDropdown(false);
  };

  const renderFeeTypeDropdown = () => {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        style={{ marginBottom: 20 }}
      >
        <StyledDropdownToggle>
          {/* Internal === Not Billable */}
          {feeType}
          <StyledQBDownArrow />
        </StyledDropdownToggle>
        <StyledDropdownMenu>
          {FEE_TYPE_OPTIONS.map((option) => (
            <StyledDropdownItem
              key={option.value}
              onClick={() => setFeeType(option.value)}
            >
              {option.label}
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    );
  };

  const renderBudgetWithDropdown = () => {
    const filterOutPercentageOfPhaseBudgetOption =
      (phase.fee_type === CONTRACT_TYPES.FIXED_FEE &&
        phase.budget_fixed_fee_with === 'work_category_estimates') ||
      (phase.fee_type === CONTRACT_TYPES.HOURLY &&
        phase.budget_hourly_with === 'work_category_estimates') ||
      (phase.fee_type === CONTRACT_TYPES.INTERNAL &&
        phase.budget_internal_with === 'work_category_estimates');

    const options = Object.entries(
      feeType === CONTRACT_TYPES.FIXED_FEE
        ? FIXED_BUDGET_WITH_OPTIONS
        : feeType === CONTRACT_TYPES.HOURLY
        ? HOURLY_BUDGET_WITH_OPTIONS
        : INTERNAL_BUDGET_WITH_OPTIONS
    );

    const filteredOptions = filterOutPercentageOfPhaseBudgetOption
      ? options.filter(([key, value]) => key !== 'phase_estimate_percentage')
      : options;

    return (
      <Dropdown
        isOpen={budgetWithDropdownOpen}
        toggle={() => setBudgetWithDropdownOpen(!budgetWithDropdownOpen)}
      >
        <StyledDropdownToggle>
          {/* Internal === Not Billable */}
          {FIXED_BUDGET_WITH_OPTIONS[budgetWith] ||
            HOURLY_BUDGET_WITH_OPTIONS[budgetWith] ||
            INTERNAL_BUDGET_WITH_OPTIONS[budgetWith] ||
            ''}
          <StyledQBDownArrow />
        </StyledDropdownToggle>
        <StyledDropdownMenu>
          {filteredOptions.map(([key, value]) => (
            <StyledDropdownItem key={key} onClick={() => setBudgetWith(key)}>
              {value}
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    );
  };

  const renderActivityPhaseFeeInput = () => {
    return (
      <StyledInputContainer width={111}>
        <StyledLabel>FEE</StyledLabel>
        <StyledDollarSign>$</StyledDollarSign>
        <FeeInput
          value={fee}
          onValueChange={handleFeeChange}
          getInputRef={(ref) => (feeRef.current = ref)}
          placeholder={'0'}
          autoFocus
          onKeyDown={checkEnterPressed(feeRef)}
          onBlur={() => {
            if (fee === '') {
              handleFeeChange({ value: '' });
            }
          }}
          decimalScale={2}
          allowNegative={false}
          thousandSeparator={true}
          isNumericString
          isTotalEmpty={isFeeEmpty}
          data-testid="activity-phase-modal-fee-input"
        />
      </StyledInputContainer>
      /* % of Phase Fee */
      /* <StyledInputContainer width={65} style={{ left: -3 }}>
          <StyledLabel>% PHASE</StyledLabel>
          <PercentageInput
            data-testid="activity-phase-modal-est-percentage-input"
            value={feePercentage}
            placeholder={'0'}
            onValueChange={handleFeePercentageChange}
            getInputRef={(ref) => (feePercentageInputRef.current = ref)}
            onKeyDown={checkEnterPressed(feePercentageInputRef)}
            onBlur={() => {
              if (feePercentage === '') {
                handleFeePercentageChange({ value: '' });
              }
            }}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={true}
            isNumericString
            isPercentageEmpty={isFeePercentageEmpty}
          />
          <div className="percent-sign">%</div>
        </StyledInputContainer> */
    );
  };

  const renderEstimatedCostInput = () => {
    const hasPercentage =
      (feeType === CONTRACT_TYPES.FIXED_FEE &&
        budgetWith === 'activity_phase_estimate') ||
      budgetWith === 'phase_estimate_percentage';
    const disableEstimatedCostPercentageInput = +fee === 0;

    return (
      <>
        <StyledInputContainer
          width={hasPercentage ? 100 : 111}
          style={hasPercentage ? { right: -3 } : {}}
        >
          <StyledLabel>BUDGET</StyledLabel>
          <StyledDollarSign>$</StyledDollarSign>
          <EstimatedCostInput
            value={estimatedCost}
            onValueChange={handleEstimatedCostChange}
            getInputRef={(ref) => (estimatedCostInputRef.current = ref)}
            placeholder={'0'}
            onKeyDown={checkEnterPressed(estimatedCostInputRef)}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={true}
            isNumericString
            isEstCostEmpty={isEstimatedCostEmpty}
            data-testid="activity-phase-modal-est-cost-input"
            hasPercentage={hasPercentage}
          />
        </StyledInputContainer>
        {/* % of Activity Phase Fee */}
        {budgetWith === 'activity_phase_estimate' &&
          feeType === CONTRACT_TYPES.FIXED_FEE && (
            <StyledInputContainer
              {...defaultTooltipProps}
              data-tip="Enter Fee"
              data-tip-disable={!disableEstimatedCostPercentageInput}
              data-offset="{ 'top': -30 }"
              width={76}
            >
              <StyledLabel>% OF FEE</StyledLabel>
              <PercentageInput
                disabled={disableEstimatedCostPercentageInput}
                data-testid="activity-phase-modal-est-percentage-input"
                value={estimatedCostPercentage}
                placeholder={'0'}
                onValueChange={handleEstimatedCostPercentageChange}
                getInputRef={(ref) =>
                  (estimatedCostPercentageInputRef.current = ref)
                }
                onKeyDown={checkEnterPressed(estimatedCostPercentageInputRef)}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={true}
                isNumericString
                isPercentageEmpty={isEstimatedCostPercentageEmpty}
              />
              <div className="percent-sign">%</div>
            </StyledInputContainer>
          )}
        {/* % of Phase Budget */}
        {budgetWith === 'phase_estimate_percentage' && (
          <StyledInputContainer width={80}>
            <StyledLabel>% OF PHASE</StyledLabel>
            <PercentageInput
              data-testid="activity-phase-modal-est-percentage-input"
              value={percentageOfPhaseBudget}
              placeholder={'0'}
              onValueChange={handlePercentageOfPhaseBudgetChange}
              getInputRef={(ref) =>
                (percentageOfPhaseBudgetInputRef.current = ref)
              }
              onKeyDown={checkEnterPressed(percentageOfPhaseBudgetInputRef)}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              isNumericString
              isPercentageEmpty={isPercentageOfPhaseBudgetEmpty}
            />
            <div className="percent-sign">%</div>
          </StyledInputContainer>
        )}
      </>
    );
  };

  return (
    <Modal isOpen toggle={handleClose} className="activity-phase-fee-modal">
      <StyledModalHeader>
        <StyledModalTitle>Work Category Info</StyledModalTitle>
      </StyledModalHeader>

      <PaddingContainer>
        {/* Body */}
        <StyledModalBody nameIsEditable={nameIsEditable}>
          <BillableToggle
            isDisabled={isDisablingUserActions}
            setBillable={setBillable}
            isBillable={feeType !== CONTRACT_TYPES.INTERNAL}
          />
          <BottomInputsContainer>
            <StyledInputContainer width={phaseNumber ? 205 : 307}>
              <StyledLabel>NAME</StyledLabel>
              <ActivityPhaseNameContainer
                onClick={nameIsEditable ? noop : toggleShowDropdown}
                ref={popoverRef}
              >
                <ActivityPhaseNameInput
                  isCustom={nameIsEditable}
                  as="input"
                  value={activityPhaseName}
                  onChange={handleNameChange}
                  ref={inputRef}
                  data-testid="activity-phase-name-input"
                  placeholder={'Type title of work category'}
                  disabled={!nameIsEditable}
                />
                {!nameIsEditable && <DropdownArrow />}
                {isShowingDropdown && (
                  <ActivitiesDropdown
                    target={popoverRef}
                    handleSelect={handleStandardActivityPhaseSelect}
                    handleClose={toggleShowDropdown}
                    renderHeader={() => 'Select Work Category'}
                    phase={phase}
                    phaseActivityOrder={phase.activity_order}
                    copy={{
                      sticky: 'Add Custom Work Category'
                    }}
                    isAddingActivity
                    onStickyClick={handleAddCustom}
                  />
                )}
              </ActivityPhaseNameContainer>
            </StyledInputContainer>
            {phaseNumber && (
              <StyledInputContainer width={100}>
                <StyledLabel>NUMBER</StyledLabel>
                <PhaseNumberInput
                  as="input"
                  value={phaseNumber}
                  onChange={(e) => setPhaseNumber(e.target.value)}
                  ref={(ref) => (phaseNumberRef.current = ref)}
                  placeholder={'Optional'}
                  disabled={true}
                  onKeyDown={checkEnterPressed(phaseNumberRef)}
                  data-testid="activity-phase-modal-number-input"
                />
              </StyledInputContainer>
            )}
          </BottomInputsContainer>
          <BottomInputsContainer>
            {/* {phaseNumber &&
              feeType !== CONTRACT_TYPES.FIXED_FEE &&
              renderPhaseNumberInput()} */}
            <StyledInputContainer
              width={
                budgetWith === 'phase_estimate_percentage' ||
                budgetWith === 'activity_phase_estimate' ||
                feeType === CONTRACT_TYPES.FIXED_FEE
                  ? 194
                  : 308
              }
            >
              <StyledLabel>CONTRACT TYPE</StyledLabel>
              {renderFeeTypeDropdown()}
            </StyledInputContainer>
            {feeType === CONTRACT_TYPES.FIXED_FEE &&
              renderActivityPhaseFeeInput()}
            {feeType !== CONTRACT_TYPES.FIXED_FEE &&
              (budgetWith === 'phase_estimate_percentage' ||
                budgetWith === 'activity_phase_estimate') &&
              renderEstimatedCostInput()}
          </BottomInputsContainer>
          {/* Forth row */}
          <BottomInputsContainer>
            <StyledInputContainer
              width={
                feeType === CONTRACT_TYPES.FIXED_FEE &&
                (budgetWith === 'activity_phase_estimate' ||
                  budgetWith === 'phase_estimate_percentage')
                  ? 178
                  : 308
              }
            >
              <StyledLabel>BUDGET WITH</StyledLabel>
              {renderBudgetWithDropdown()}
            </StyledInputContainer>
            {feeType === CONTRACT_TYPES.FIXED_FEE &&
              (budgetWith === 'activity_phase_estimate' ||
                budgetWith === 'phase_estimate_percentage') &&
              renderEstimatedCostInput()}
          </BottomInputsContainer>
        </StyledModalBody>

        {/* Footer */}
        <StyledModalFooter>
          <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          <ButtonContainer width={152}>
            <TextButtonWithBorder
              onClick={handleClose}
              fontWeight={400}
              data-testid="activity-phase-modal-cancel-btn"
            >
              Cancel
            </TextButtonWithBorder>
            <BlueSubmitButton
              onClick={isValid ? handleBlur : undefined}
              fontWeight={400}
              opacity={isValid ? undefined : '0.6'}
              isDisabled={!isValid}
              width={63}
              data-testid="activity-phase-modal-submit-btn"
            >
              {dirtyFlag ? 'Save' : 'Done'}
            </BlueSubmitButton>
          </ButtonContainer>
        </StyledModalFooter>
      </PaddingContainer>
    </Modal>
  );
};

const makeMapStateToProps = () => {
  const getOwnPhase = makeGetOwnPhase();
  const getActivityPhase = makeGetOwnActivityPhase();
  const getPhaseTotals = makeGetUnformattedPhaseTotal();
  const mapStateToProps = (state, ownProps) => {
    const phase = getOwnPhase(state, ownProps) || {}; // Need this for phase fee info.
    const phaseTotals = getPhaseTotals(state, { phaseId: phase.id });
    return {
      phase,
      activityPhase: getActivityPhase(state, ownProps),
      activityHash: getAllActivityRowInfo(state),
      phaseTotals
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  openActivityPhaseModal,
  updateActivityPhase,
  updateActivity
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ActivityPhaseFeeModal);
