import styled from 'styled-components';
import WorkplanCalendarOptions from './WorkplanCalendarOptions';
import WorkplanCalendarCollapsableMenu from './WorkplanCalendarCollapsableMenu';
import { WORK_PLAN_CALENDAR_MENU_TYPES } from 'appConstants/workload';
import theme from 'theme';

const WorkplanCalendarSidebar = ({
  isOOO,
  workplanCalendarCollapsableMenuProps,
  workplanCalendarOptionSetterTypes,
  workplanCalendarOptionValues
}) => {
  return (
    <WorkplanCalendarSidebarContainer>
      <WorkplanOptionsTitle>MORE OPTIONS</WorkplanOptionsTitle>
      <WorkplanCalendarOptions
        showAllDay={isOOO}
        workplanCalendarOptionSetterTypes={workplanCalendarOptionSetterTypes}
        workplanCalendarOptionValues={workplanCalendarOptionValues}
      />
      <WorkplanCalendarCollapsableMenu
        workplanCalendarMenuType={WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME}
        {...workplanCalendarCollapsableMenuProps[
          WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME
        ]}
      />
    </WorkplanCalendarSidebarContainer>
  );
};

const WorkplanCalendarSidebarContainer = styled.div`
  background-color: white;
  height: 490px;
  width: 190px;
  position: absolute;
  top: -1px;
  right: 100%;
  border: 1px solid ${theme.colors.colorLightGray19};
  border-right: none;
`;

const WorkplanOptionsTitle = styled.div`
  margin-left: 21px;
  margin-top: 28px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: ${theme.colors.colorMediumGray5};
`;

export default WorkplanCalendarSidebar;
