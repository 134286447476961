import styled, { css } from 'styled-components';
import theme from 'theme';
import NewCloseIcon from 'icons/NewCloseIcon';
import type {
  FilterListTypeList,
  FilterListsTableCustomRowProps,
  FilterListsTableVariant
} from '../types';

export const SearchInputCell = ({
  row,
  customRowProps
}: {
  row: {
    original: {
      parentList: FilterListTypeList;
    };
  };
  customRowProps: FilterListsTableCustomRowProps;
}) => {
  const {
    parentList: { searchText, setSearchText, searchPlaceholderText }
  } = row.original;

  const {
    tableConfig: { variant }
  } = customRowProps;

  // this row should only be added if search exists - this is additional safeguard
  if (!setSearchText || searchText === undefined) return <></>;

  return (
    <StyledInputContainer>
      <StyledInput
        onChange={(e) => setSearchText(e.target.value)}
        name="search"
        value={searchText}
        placeholder={searchPlaceholderText}
        autoComplete="off"
        tableVariant={variant}
        isActive={!!searchText}
      />
      {searchText !== '' ? (
        <ClearSearchIconContainer onClick={() => setSearchText('')}>
          <NewCloseIcon stroke="transparent" />
        </ClearSearchIconContainer>
      ) : null}
    </StyledInputContainer>
  );
};

/* ------------------------------------ - ----------------------------------- */

const ClearSearchIconContainer = styled.div`
  position: absolute;
  right: 4px;
  top: calc(50% - 12px);
  display: flex;
  cursor: pointer;
  align-items: center;

  svg {
    height: 23px;
    width: 23px;
  }

  &:hover {
    circle {
      fill: ${theme.colors.colorPureWhite};
    }
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
`;

const StyledInputStylesByTableVariant: Record<
  FilterListsTableVariant,
  ReturnType<typeof css>
> = {
  StackedFilters: css`
    background: white;
    border: 1px solid ${theme.colors.colorPaleGray7};
    padding: 3px 8px;
    font-size: 14px;
  `,

  SideFilter: css`
    font-size: 13px;
    padding: 3px 3px 3px 6px;
    background: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid ${theme.colors.colorLightGray15};

    :focus {
      background: ${theme.colors.colorTranslucentGray4};
    }
  `
};

const StyledInput = styled.input<{
  tableVariant: FilterListsTableVariant;
  isActive: boolean;
}>`
  // base shared styles
  outline: none;
  width: 100%;

  ::placeholder {
    color: ${theme.colors.colorLightGray15};
  }

  // table variant specific styles
  ${(props) => StyledInputStylesByTableVariant[props.tableVariant]};

  ${({ isActive }) =>
    isActive &&
    `
    border-color: ${theme.colors.colorRoyalBlue};`}
`;
