import { select, all, call, put } from 'redux-saga/effects';

import { api } from '../service';
import * as phaseApi from '../service/api/phases';
import * as entityActions from '../actions';
import * as entityOptionsApi from 'EntityOptionsModule/api';
import * as regionApi from 'SettingsModule/api/region';
import * as officeApi from 'SettingsModule/api/office';
import * as disciplineApi from 'SettingsModule/api/discipline';
import { fetchRates, fetchPositions } from 'BudgetModule/api';
import { ptoApi } from 'SettingsModule/api/pto';
import { getAuthToken, getSelectedTeamId, getTeamMembers } from 'selectors';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';

// load all data necessary for validating time entries
// could improve time by loading as necssary in validator
export function* loadCSVImportDataWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const errors = [];

  const [
    { error: projectsError, response: projectsResponse },
    { error: phasesError, response: phasesResponse },
    { error: activitiesError, response: activitiesResponse },
    { error: projectClientsError, response: projectClientsResponse },
    { error: projectPrioritiesError, response: projectPrioritiesResponse },
    { error: projectEntityOptionsError, response: projectEntityOptionResponse },
    { error: phaseEntityOptionsError, response: phaseEntityOptionResponse },
    { error: regionsError, response: regionsResponse },
    { error: officesError, response: officesResponse },
    { error: disciplinesError, response: disciplinesResponse },
    { error: ptoPoliciesError, response: ptoPoliciesResponse },
    { error: positionsError, response: positionsResponse },
    { error: teamRatesError, response: teamRatesResponse },
    { error: departmentsError, response: departmentsResponse },
    { error: taskPrioritiesError, response: taskPrioritiesResponse }
  ] = yield all([
    call(api.fetchProjectsListView, undefined, token, null, undefined, true),
    call(phaseApi.fetchFilteredPhases, teamId, token, {
      all: true,
      import: true
    }),
    call(api.fetchActivities, undefined, token),
    call(api.fetchProjectClients, teamId, token),
    call(api.fetchPriorities, undefined, token),
    call(entityOptionsApi.fetchEntityOptions, undefined, token, {
      entity_type: ENTITY_TYPES.project
    }),
    call(entityOptionsApi.fetchEntityOptions, undefined, token, {
      entity_type: ENTITY_TYPES.phaseMembership
    }),
    call(regionApi.fetchRegions, undefined, token, { teamId }),
    call(officeApi.fetchOffices, undefined, token, { teamId }),
    call(disciplineApi.fetchDisciplines, undefined, token, { teamId }),
    call(ptoApi.fetchPTOPolicies, token, { teamId }),
    call(fetchPositions, teamId, token),
    call(fetchRates, teamId, token),
    call(api.fetchWorkGroups, teamId, token),
    call(api.fetchTaskPriorities, undefined, token, undefined)
  ]);

  if (projectsError) {
    errors.push(projectsError);
  }

  if (phasesError) {
    errors.push(phasesError);
  }

  if (activitiesError) {
    errors.push(activitiesError);
  }

  if (projectClientsError) {
    errors.push(projectClientsError);
  }

  if (projectPrioritiesError) {
    errors.push(projectPrioritiesError);
  }

  if (projectEntityOptionsError) {
    errors.push(projectEntityOptionsError);
  }

  if (phaseEntityOptionsError) {
    errors.push(phaseEntityOptionsError);
  }
  if (regionsError) {
    errors.push(regionsError);
  }

  if (officesError) {
    errors.push(regionsError);
  }

  if (disciplinesError) {
    errors.push(regionsError);
  }

  if (ptoPoliciesError) {
    errors.push(regionsError);
  }

  if (positionsError) {
    errors.push(positionsError);
  }

  if (teamRatesError) {
    errors.push(teamRatesError);
  }

  if (departmentsError) {
    errors.push(departmentsError);
  }

  if (taskPrioritiesError) {
    errors.push(taskPrioritiesError);
  }

  if (errors.length) {
    yield call(entityActions.loadCSVData.failure(errors));
  } else {
    const teamMembersResponse = yield select(getTeamMembers); // assume we have all
    yield put(
      entityActions.loadCSVData.success(
        {
          projects: projectsResponse.projects,
          phases: phasesResponse.phases,
          activities: Object.values(activitiesResponse.activities),
          teamMembers: teamMembersResponse,
          regions: regionsResponse.regions,
          offices: officesResponse.offices,
          disciplines: disciplinesResponse.disciplines,
          ptoPolicies: ptoPoliciesResponse.pto_groups,
          positions: positionsResponse.positions,
          teamRates: teamRatesResponse.rates,
          projectClients: projectClientsResponse,
          projectPriorities: projectPrioritiesResponse,
          projectBudgetStatusOptions:
            projectEntityOptionResponse.column_options.budget_status.enum,
          phaseBudgetStatusOptions:
            phaseEntityOptionResponse.column_options.budget_status.enum,
          departments: departmentsResponse.work_groups,
          taskPriorities: taskPrioritiesResponse
        },
        undefined,
        action.payload
      )
    );
  }
}
