import { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BlueSubmitButton, TextButtonWithBorder } from 'components/styles';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import { SimpleConfirmModal } from 'components/Modals';

interface SharedUiSectionProps {
  headerText: string;
  descriptionText: string;
  confirmDeleteModalContextString: string;
  isForciblyDisabled: boolean;
  forciblyDisabledTooltip: string;
  isActive: boolean;
  isUserActionDisabled: boolean;
  onRequestDisable: () => void;
  onRequestEnable: () => void;
  onRequestEdit: () => void;
}

export const SharedUiSection = ({
  headerText,
  descriptionText,
  confirmDeleteModalContextString,
  isForciblyDisabled,
  forciblyDisabledTooltip,
  isActive,
  isUserActionDisabled,
  onRequestDisable,
  onRequestEnable,
  onRequestEdit
}: SharedUiSectionProps) => {
  const [isConfirmDisableModalOpen, setIsConfirmDisableModalOpen] =
    useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (isForciblyDisabled) {
      rebuildTooltip();
    }
  });

  const closeConfirmDeleteModal = useCallback(() => {
    setIsConfirmDisableModalOpen(false);
  }, []);

  const handleConfirmDeleteRequest = useCallback(() => {
    onRequestDisable();
    closeConfirmDeleteModal();
  }, [closeConfirmDeleteModal, onRequestDisable]);

  return (
    <>
      <RootContainer>
        <div>
          <HeaderText>{headerText}</HeaderText>
          <DescriptionText>{descriptionText}</DescriptionText>
        </div>
        {isForciblyDisabled ? (
          <div {...defaultTooltipProps} data-tip={forciblyDisabledTooltip}>
            <TextButtonWithBorder
              isDisabled
              backgroundColor={theme.colors.colorLightGray12}
              color={theme.colors.colorCalendarGray}
              style={{ fontWeight: 600 }}
            >
              Enable
            </TextButtonWithBorder>
          </div>
        ) : isActive ? (
          <ActionButtonsContainer>
            <TextButtonWithBorder
              backgroundColor="transparent"
              borderColor={theme.colors.colorCalendarRed}
              color={theme.colors.colorCalendarRed}
              isDisabled={isUserActionDisabled}
              onClick={() => setIsConfirmDisableModalOpen(true)}
            >
              Disable
            </TextButtonWithBorder>
            <TextButtonWithBorder
              backgroundColor="transparent"
              borderColor={theme.colors.colorRoyalBlue}
              color={theme.colors.colorRoyalBlue}
              isDisabled={isUserActionDisabled}
              onClick={onRequestEdit}
            >
              Edit
            </TextButtonWithBorder>
          </ActionButtonsContainer>
        ) : (
          <BlueSubmitButton fontWeight={600} onClick={onRequestEnable}>
            Enable
          </BlueSubmitButton>
        )}
      </RootContainer>
      <SimpleConfirmModal
        isOpen={isConfirmDisableModalOpen}
        header={`Disable ${headerText}`}
        body={`Are you sure you want to disable ${confirmDeleteModalContextString}?`}
        shouldSetIsClosingOnClose={false}
        onConfirm={handleConfirmDeleteRequest}
        onCancel={closeConfirmDeleteModal}
      />
    </>
  );
};

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-gap: 70px;
  align-items: center;
`;

const HeaderText = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-weight: 600;
  margin-bottom: 9px;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
