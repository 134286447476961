import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';

const StandardPTOContainer = lazy(
  () => import('./StandardPTOTable/StandardPTOTableContainer')
);

export const StandardPTOTab = () => {
  return (
    <RootContainer>
      <Suspense fallback={<div />}>
        <StandardPTOContainer />
      </Suspense>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  padding-left: 32px;
  flex: 1;
  min-height: 0px;
`;
