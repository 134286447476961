import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import {
  FormGroup,
  Modal,
  Form,
  Button,
  FormFeedback,
  Input
} from 'reactstrap';
import Avatar from 'react-avatar';
import {
  getAuth,
  getUsers,
  getPasswordError,
  getEmailError,
  getSplitFlags
} from 'selectors';
import { updateProfile, updateSettingsPassword } from 'actionCreators';
import { PasswordPolicyList } from '../../loginComponents/PasswordPolicyList';
import { PersonalMfaSettings } from './PersonalMfaSettings';
import { ActionLink } from './styled';
import { Space } from 'components/Space';
import { getIsMfaEnabledTeamWide } from 'AuthenticationModule/selectors';

class EditPersonalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingPassword: false,
      passwordsDontMatch: false,
      old_password: '',
      new_password: '',
      confirm_password: '',
      hasBeenEdited: false,
      first_name: '',
      middle_initial: '',
      last_name: '',
      email: '',
      initials: '',
      incorrectPassword: false,
      passwordNotLongEnough: false,
      existingEmail: false,
      missingFirstName: false,
      missingLastName: false
    };
    bindAll(this, [
      'handleChange',
      'handleSubmit',
      'closeModal',
      'renderPasswordSection',
      'resetState'
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      setTimeout(() => {
        this.setState({
          first_name: this.props.me.first_name,
          middle_initial: this.props.me.middle_initial,
          last_name: this.props.me.last_name,
          email: this.props.me.email,
          initials: this.props.me.initials
        });
      }, 500);
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: !e.target.value ? '' : e.target.value,
      hasBeenEdited: true
    });
    // set the initials to display in avatar
    if ([e.target.name] === 'first_name') {
      this.setState({
        initials:
          e.target.value[0] +
          this.state.middle_initial +
          this.state.last_name[0]
      });
    } else if ([e.target.name] === 'middle_initial') {
      this.setState({
        initials:
          this.state.first_name + e.target.value + this.state.last_name[0]
      });
    } else if ([e.target.name] === 'last_name') {
      this.setState({
        initials:
          this.state.first_name + this.state.middle_initial + e.target.value
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.new_password && this.state.new_password.length < 14) {
      this.setState({
        passwordNotLongEnough: true,
        incorrectPassword: false,
        passwordsDontMatch: false
      });
    } else if (this.state.new_password !== this.state.confirm_password) {
      this.setState({
        passwordsDontMatch: true,
        incorrectPassword: false,
        passwordNotLongEnough: false
      });
    } else if (!this.state.first_name || !this.state.last_name) {
      this.setState({
        missingFirstName: !this.state.first_name,
        missingLastName: !this.state.last_name,
        passwordsDontMatch: false,
        incorrectPassword: false,
        passwordNotLongEnough: false
      });
    } else {
      this.setState({
        passwordsDontMatch: false,
        passwordNotLongEnough: false,
        incorrectPassword: false,
        missingFirstName: false,
        missingLastName: false
      });

      const payload = {
        first_name: this.state.first_name,
        middle_initial: this.state.middle_initial,
        last_name: this.state.last_name,
        email: this.state.email
      };

      this.props.updateProfile(payload);

      if (this.state.old_password && this.state.confirm_password) {
        this.props.updateSettingsPassword({
          current_password: this.state.old_password,
          password: this.state.confirm_password
        });
      }

      setTimeout(() => {
        if (this.props.passwordError === 'Bad Request') {
          this.setState({
            incorrectPassword: true
          });
        } else if (this.props.emailError === 'Forbidden') {
          this.setState({
            existingEmail: true
          });
        } else this.closeModal();
      }, 500);
    }
  }

  closeModal() {
    this.resetState();
    this.props.closeEditModal();
  }

  resetState() {
    this.setState({
      old_password: '',
      new_password: '',
      confirm_password: '',
      first_name: this.props.me.first_name,
      middle_initial: this.props.me.middle_initial,
      last_name: this.props.me.last_name,
      email: this.props.me.email,
      passwordsDontMatch: false,
      incorrectPassword: false,
      editingPassword: false,
      existingEmail: false,
      missingFirstName: false,
      missingLastName: false
    });
  }

  renderPasswordSection() {
    if (!this.state.editingPassword) {
      return (
        <div>
          <span className="password-label">Password</span>
          <span className="password-mask">••••••••••</span>
          <ActionLink onClick={() => this.setState({ editingPassword: true })}>
            Edit
          </ActionLink>
        </div>
      );
    } else {
      return (
        <div className="password-settings">
          <div className="outline-container">
            <FormGroup
              className="form-group"
              color={
                this.state.incorrectPassword ||
                this.state.passwordNotLongEnough ||
                this.state.passwordsDontMatch
                  ? 'danger'
                  : ''
              }
            >
              <Input
                type="password"
                name="old_password"
                onChange={this.handleChange}
                placeholder="Current password"
                className="password"
                state={this.state.incorrectPassword ? 'danger' : null}
              />
              {this.state.incorrectPassword ? (
                <FormFeedback className="form-feedback">
                  Incorrect current password.
                </FormFeedback>
              ) : null}

              <Input
                type="password"
                name="new_password"
                onChange={this.handleChange}
                placeholder="New password"
                className="password new-password"
                state={this.state.passwordNotLongEnough ? 'danger' : null}
              />
              {this.state.passwordNotLongEnough ? (
                <FormFeedback className="form-feedback">
                  Password must be at least 14 characters.
                </FormFeedback>
              ) : null}

              <Input
                type="password"
                name="confirm_password"
                onChange={this.handleChange}
                placeholder="Confirm new password"
                className="password"
                state={this.state.passwordsDontMatch ? 'danger' : null}
              />
              {this.state.passwordsDontMatch ? (
                <FormFeedback className="form-feedback">
                  Passwords must match.
                </FormFeedback>
              ) : null}
            </FormGroup>
          </div>
          <PasswordPolicyList />
        </div>
      );
    }
  }

  render() {
    const { isOpen, closeEditModal, me, splitFlags, isMfaEnabledTeamWide } =
      this.props;
    // takes initials generated from first name (from dupe initials within team)
    const middleInitial = me.middle_initial
      ? me.initials &&
        me.initials.slice(1, me.initials.length - 2) + me.middle_initial
      : me.initials && me.initials.slice(1, me.initials.length - 1);

    return (
      <div>
        {isOpen && (
          <Modal isOpen={isOpen} toggle={this.closeModal}>
            <div className="edit-personal-settings">
              <Form onSubmit={this.handleSubmit}>
                <div>
                  <div className="edit-header">Edit Profile</div>
                  <i className="close-icon" onClick={closeEditModal} />
                  <div className="avatar-name-container">
                    <div className="avatar-col">
                      <Avatar
                        value={me.initials}
                        round={true}
                        size={80}
                        color="#808080"
                      />
                    </div>
                    <div className="name-settings">
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        onChange={this.handleChange}
                        defaultValue={me.first_name}
                        className={cn('names-input first-name', {
                          invalid: this.state.missingFirstName
                        })}
                      />
                      <input
                        type="text"
                        name="middle_initial"
                        placeholder="M.I."
                        onChange={this.handleChange}
                        defaultValue={middleInitial}
                        className="names-input middle-initial"
                      />
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        onChange={this.handleChange}
                        defaultValue={me.last_name}
                        className={cn('names-input last-name', {
                          invalid: this.state.missingLastName
                        })}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="email-label">Email</div>
                    <input
                      disabled
                      type="email"
                      defaultValue={me.email}
                      onChange={this.handleChange}
                      name="email"
                      placeholder="E-mail"
                      required
                      className="email-input"
                      style={{ marginBottom: 10 }}
                    />
                  </div>
                  {this.state.existingEmail ? (
                    <div className="email-error">
                      This email already exists in Mosaic. Please try a
                      different email address.
                    </div>
                  ) : null}
                </div>
                <div />
                {this.renderPasswordSection()}
                {splitFlags.useAuthServer && isMfaEnabledTeamWide && (
                  <PersonalMfaSettings />
                )}
                <Space value={30} vertical />
                <div className="settings-bottom">
                  <a className="deactivate" href="#">
                    Deactivate Account
                  </a>
                  <Button
                    className={cn('update-button', {
                      'save-button': this.state.hasBeenEdited
                    })}
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

EditPersonalSettings.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeEditModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  users: getUsers(state),
  passwordError: getPasswordError(state),
  emailError: getEmailError(state),
  isMfaEnabledTeamWide: getIsMfaEnabledTeamWide(state),
  splitFlags: getSplitFlags(state)
});

const mapDispatchToProps = {
  updateProfile,
  updateSettingsPassword
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  updateProfile: (params) =>
    dispatchProps.updateProfile(stateProps.auth.token, params)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditPersonalSettings)
);
