import React from 'react';
import { MenuContainer, ListItem, ColorPallete, FlexItem } from './styles';
import ColorPicker from 'components/ColorPicker';
import FlyoutMenu from 'views/utilityComponents/FlyoutMenu';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

class TeamMemberMenu extends React.Component {
  render() {
    const { teamMember, menuOpen, onMenuClose } = this.props;
    return (
      <FlyoutMenu open={menuOpen} onRequestClose={onMenuClose}>
        <MenuContainer>
          <ListItem>
            <ColorPicker
              id={teamMember.id}
              originType={ORIGIN_TYPE_STRINGS.TEAM_MEMBERSHIP}
              className="color-picker-container"
              row
              // ref={this.setColorPickerRef}
            >
              <FlexItem>
                <ColorPallete />
                <span>Set Color</span>
              </FlexItem>
            </ColorPicker>
          </ListItem>
        </MenuContainer>
      </FlyoutMenu>
    );
  }
}

export default TeamMemberMenu;
