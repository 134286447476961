import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

export const editWorkPlans = createAction<SpacePermissionCheckerParams>(
  'EDIT_ACTIVITY_PHASE_SCHEDULE_BARS'
);

export const editPersonalWorkPlans = createAction<SpacePermissionCheckerParams>(
  'EDIT_ACTIVITY_PHASE_SCHEDULE_BARS'
);
