import { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import noop from 'lodash/noop';
import { useToggle } from 'react-use';
import { Modal } from 'reactstrap';
import { createWorkGroup } from 'actionCreators';
import { getUserIsAdmin } from 'PermissionsModule/selectors';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import lockWhiteIcon from 'images/lock-white.svg';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { TextButtonWithBorder } from 'components/styles';
import { removeWhiteSpace } from 'appUtils/regex';

export const defaultRequestStatusId = 'add-department';

export const AddDeptButton = ({
  onSuccessDeptCreation,
  requestStatusId = defaultRequestStatusId
}: {
  onSuccessDeptCreation?: { selector: any; successAction: any }[];
  requestStatusId?: string;
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const isAdmin = useAppSelector(getUserIsAdmin);

  return (
    <StyledAddDeptButtonContainer className="add-dept-button-container">
      <StyledAddDeptButton
        $isDisabled={!isAdmin}
        onClick={isAdmin ? toggleIsOpen : noop}
        {...defaultTooltipProps}
        data-class={'center'}
        data-tip={
          isAdmin
            ? undefined
            : `<img class="lock-white-icon" src=${lockWhiteIcon} /> Only Admins can add<br />Departments`
        }
      >
        + Department
      </StyledAddDeptButton>
      {isOpen && (
        <AddDeptModal
          toggle={toggleIsOpen}
          onSuccessDeptCreation={onSuccessDeptCreation}
          requestStatusId={requestStatusId}
        />
      )}
    </StyledAddDeptButtonContainer>
  );
};

// this is temporary. no design is provided for this modal.
const AddDeptModal = ({ toggle, onSuccessDeptCreation, requestStatusId }) => {
  const [departmentName, setDepartmentName] = useState('');
  // removeWhiteSpace is there to handle strings like '   '
  const isEmptyDeptName = removeWhiteSpace(departmentName).length === 0;
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const handleConfirm = () => {
    toggle();
    dispatch(
      createWorkGroup({
        team_id: teamId,
        name: departmentName,
        requestStatusId,
        meta: { onSuccess: onSuccessDeptCreation }
      })
    );
  };

  return (
    <StyledModal isOpen toggle={toggle}>
      <h4>Add department</h4>
      <StyledInput
        onChange={(e) => {
          setDepartmentName(e.target.value);
        }}
        value={departmentName}
        placeholder={'Type department name'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleConfirm();
            e.preventDefault();
          }
        }}
      />
      <StyledButtonsContainer isConfirmDisabled={isEmptyDeptName}>
        <StyledButton
          color={theme.colors.colorMediumGray6AndAHalf}
          backgroundColor="transparent"
          borderColor={theme.colors.colorLightGray6}
          fontWeight={400}
          onClick={toggle}
        >
          Cancel
        </StyledButton>
        <StyledButton
          color="white"
          backgroundColor={
            isEmptyDeptName
              ? theme.colors.colorLightGray8
              : theme.colors.colorRoyalBlue
          }
          fontWeight={600}
          data-testid="workplan-request-save-button"
          cursor={isEmptyDeptName ? 'default' : 'pointer'}
          className="confirm"
          onClick={isEmptyDeptName ? noop : handleConfirm}
          disabled={isEmptyDeptName}
        >
          Create
        </StyledButton>
      </StyledButtonsContainer>
    </StyledModal>
  );
};

const StyledAddDeptButtonContainer = styled.div``;

const StyledAddDeptButton = styled.button<{ $isDisabled: boolean }>`
  width: 103px;
  height: 22px;
  border-radius: 12px;
  background-color: ${theme.colors.colorBudgetBlue};
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.colorPureWhite};
  font-weight: 600;
  border: none;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 400px;
    height: 234px;
    padding: 40px 50px;
    background-color: ${theme.colors.colorLightGray19};
    border: 1px solid ${theme.colors.colorLightGray1};
    h4 {
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 33px;
    }
  }
`;

const StyledInput = styled.input`
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorPaleGray8};
  padding: 5px 0px 5px 10px;
  width: 100%;
  font-size: 13px;
  line-height: 2;

  &::placeholder {
    color: ${theme.colors.colorLightGray15};
    font-weight: 400;
    line-height: 26px;
  }

  &:hover {
    border-color: ${theme.colors.colorRoyalBlue};
  }

  &.error {
    border-color: ${theme.colors.colorCalendarRed};
  }
`;

const StyledButtonsContainer = styled.div<{ isConfirmDisabled: boolean }>`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 20px;
`;

const StyledButton = styled(TextButtonWithBorder)<{
  backgroundColor?: string;
  borderColor?: string;
  fontWeight?: string | number;
  cursor?: string;
}>`
  font-size: 14px;
  cursor: ${({ cursor }) => cursor || 'pointer'} !important;
`;
