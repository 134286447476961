import { useState, ComponentProps, useCallback, MouseEvent } from 'react';
import { MemberTagList } from 'components/MemberTagList';
import { TeamMember } from 'TeamsModule/models/teamMember';
import { PermissionCheckbox } from '../PermissionsSections/shared/styles';
import { PermissionCheckboxInput } from '../PermissionsSections/shared/PermissionCheckboxInput';
import { PermissionCheckboxContainer } from '../PermissionsSections/shared/PermissionCheckboxContainer';
import styled from 'styled-components';
import { ConfirmRemoveCustomMemberModal } from './modals/ConfirmRemoveCustomMemberModal';

export const SpecificMemberPermissionList = ({
  isChecked,
  isDisabled = false,
  onCheck,
  item,
  teamMembershipIds
}: ComponentProps<typeof PermissionCheckboxContainer>) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (isChecked) {
        setShowConfirmationModal(true);
      } else {
        onCheck({ isChecked: true, item });
      }
    },
    [isChecked, item, onCheck]
  );

  const handleMemberSelect = useCallback(
    (newMembers: TeamMember[]) => {
      const newMemberIds = newMembers.map((newMember) => newMember.id);
      onCheck({
        teamMembershipIds: newMemberIds,
        isChecked: true,
        item
      });
    },
    [item, onCheck]
  );

  const handleMemberRemove = useCallback(
    (removedMember: TeamMember) => {
      onCheck({
        teamMembershipIds: [removedMember.id],
        isChecked: false,
        item
      });
    },
    [item, onCheck]
  );

  const handleMemberClear = useCallback(() => {
    onCheck({
      teamMembershipIds: teamMembershipIds,
      isChecked: false,
      item
    });
  }, [item, onCheck, teamMembershipIds]);

  return (
    <PermissionCheckbox>
      <PermissionCheckboxInput
        isChecked={isChecked}
        isDisabled={isDisabled}
        item={item}
        onClick={handleClick}
      />
      <MemberTagListContainer>
        <MemberTagList
          disabled={!isChecked}
          onMemberSelect={handleMemberSelect}
          onMemberRemove={handleMemberRemove}
          initialSelectedMemberIds={teamMembershipIds}
        />
      </MemberTagListContainer>
      {showConfirmationModal && (
        <ConfirmRemoveCustomMemberModal
          closeModal={() => setShowConfirmationModal(false)}
          handleConfirm={handleMemberClear}
        />
      )}
    </PermissionCheckbox>
  );
};

const MemberTagListContainer = styled.div`
  margin-left: 24px;
  margin-top: 6px;
`;
