import { PermissionActionHash } from 'PermissionsModule/components/PermissionsTable/types';
import { REPORT_NAMES } from 'ReportsModule/constants';

const organizationPermissions: Partial<PermissionActionHash> = {
  create_team_memberships: {
    question: 'Who can invite members to the organization?',
    permissionLabel: 'Invite members to organization',
    explanation: 'Only Adminstrators can invite members to the organization.'
  },
  delete_team_memberships: {
    question: 'Who can Remove Members from the organization?',
    permissionLabel: 'Remove Members from the Organization',
    explanation: 'Only Adminstrators can remove members to the organization.'
  },
  // New Permissions In V2
  manage_roles: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Create, Edit, or Delete an Access Level',
    explanation:
      'Only Adminstrators can create, edit, or delete an Access Level.'
  },
  assign_member_roles: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Assign a Member to an Access Level',
    explanation: 'Only Adminstrators can assign a member to an Access Level.'
  },
  invite_guests: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Invite Guests',
    explanation: 'Guests can only access individual projects. '
  }
};

const timesheetPermissions: Partial<PermissionActionHash> = {
  read_time_entries_other: {
    question: 'Who can view timesheets?',
    permissionLabel: 'Access All Timesheets View Only',
    explanation: "Select who can view Timesheets from a Member's Profile page."
  },
  // New Permissions In V2
  edit_member_timesheets: {
    question: 'Doesnt apply to this version',
    permissionLabel: "Edit any Org Member's Timesheet",
    explanation: "Select who can view, edit and update any member's Timesheets."
  },
  approve_member_timesheets: {
    question: 'Doesnt apply to this version',
    permissionLabel: 'Approve Timesheets',
    explanation: 'Select who can approve and reject Timesheets.'
  }
};

const workplanPermissions: Partial<PermissionActionHash> = {
  modify_work_plans: {
    question: '', // Doesn't apply to this version
    permissionLabel: 'Edit Work Plans',
    explanation:
      'Work Planner Edit or View Only permission is set on Individual members permission.'
  }
};

const integrationPermissions: Partial<PermissionActionHash> = {
  // New Permissions In V2
  // manage_integrations: {
  //   question: "Doesn't apply to this version",
  //   permissionLabel: 'Manage Integrations Settings',
  //   explanation: ''
  // },
  // manage_integration_link: {
  //   question: "Doesn't apply to this version",
  //   permissionLabel: 'Manage Integrations Linking',
  //   explanation: ''
  // }
};

const profilePermissions: Partial<PermissionActionHash> = {
  // New Permissions In V2
  edit_member_profiles: {
    question: "Doesn't apply to this version",
    permissionLabel: "Edit Member's Profile",
    explanation: 'Select who can edit Member Profile fields.'
  }
};

const budgetPermissions: Partial<PermissionActionHash> = {
  // New Permissions In V2
  // read_budgets: {
  //   question: "Doesn't apply to this version",
  //   permissionLabel: 'Access Project Budgets',
  //   explanation: 'Select who can View Project Budgets'
  // },
  // manage_budgets: {
  //   question: "Doesn't apply to this version",
  //   permissionLabel: 'Edit Budgets',
  //   explanation: 'Select who can Edit Project Budgets'
  // },
  // read_budget_by_cost_rate: {
  //   question: "Doesn't apply to this version",
  //   permissionLabel: 'View Budgets By Cost Rate',
  //   explanation: 'Select who can View Project Budgets by Cost Rate, which are based on member pay rates.'
  // }
};

const portfolioPermissions: Partial<PermissionActionHash> = {
  create_public_boards: {
    question: 'Who can create New Portfolios?',
    permissionLabel: 'Create New Portfolios',
    explanation: 'Select who can Create new Portfolios.'
  },
  edit_boards: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Edit Portfolio',
    explanation: 'Select who can Edit Portfolios'
  },
  create_archived_boards: {
    question: 'Who can Archive portfolios?',
    permissionLabel: 'Archive Portfolios',
    explanation: 'Select who can Archive Portfolios.'
  },
  delete_boards: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Delete Portfolios',
    explanation:
      'To prevent data loss, Only Administrators can Delete Portfolios.'
  },
  create_private_boards: {
    question: 'Who can make portfolios Private?',
    permissionLabel: 'Make Portfolios Private',
    explanation:
      'Select who can make Portfolios Private, and turn Portfolio Privacy off.'
  },
  // Will be replaced by modify_board_memberships
  // create_public_board_memberships: {
  //   question: 'Who can Add/remove Members from portfolios?',
  //   permissionLabel: 'Add/Remove Members in Portfolios',
  //   explanation: 'Select who can add members to Portfolios.'
  // },
  modify_board_memberships: {
    question: 'Who can Add/Edit/Remove Members from portfolios?',
    permissionLabel: 'Add/Remove Members in Portfolios',
    explanation: 'Select who can add members to Portfolios.'
  },
  // // New Permissions In V2
  create_board_managers: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Set Portfolio Managers',
    explanation: 'Select who can set Portfolio Managers.'
  },
  delete_board_projects: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Delete Projects in Portfolios',
    explanation: 'Select who can Delete Projects.'
  },
  archive_board_projects: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Archive Projects in Portfolios',
    explanation: 'Select who can Archive Projects.'
  }
};

const reportPermissions: Partial<PermissionActionHash> = {
  // New Permissions In V2
  access_timesheet_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.TIMESHEET,
    explanation:
      'Select who can view the Timesheet Report. May contain billing rate info.'
  },
  access_budget_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.BUDGET,
    explanation:
      'Select who can view the Budget Report, which contains project financial info if billing time.'
  },
  access_utilization_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.UTILIZATION,
    explanation:
      'Select who can review the Utilization Rate for members and the organization.'
  },
  access_profit_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.PROFIT,
    explanation:
      'Select who can view the Project Profit Report, which contains project financial info.'
  },
  access_time_variance_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.TIME_VARIANCE,
    explanation:
      'Select who can view the Time Variance Report showing time Planned and Estimated v. Spent.'
  },
  access_timesheet_status_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.TIMESHEET_STATUS,
    explanation:
      'Select who can view the Timesheet Status Report for entered, submitted, and approved Timesheets. '
  },
  access_demand_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.DEMAND,
    explanation:
      'Select who can view the Demand Report showing Planned work vs. Capacity by Role.'
  },
  access_workload_forecast_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.WORKLOAD_FORECAST,
    explanation:
      'Select who can view forecasts for Workload for the Organization.'
  },
  access_skills_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.SKILLS,
    explanation: 'Select who can view the Skills Report. '
  },
  access_planned_time_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.PLANNED_TIME,
    explanation: 'Select who can view the Planned Time Report.'
  },
  access_spent_time_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.SPENT_TIME,
    explanation: 'Select who can view the Spent Time Report.'
  },
  access_time_projection_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.TIME_PROJECTION,
    explanation: 'Select who can view the Time Projection Report.'
  },
  // access_timesheet_approval_report: {
  //   question: "Doesn't apply to this version",
  //   permissionLabel: REPORT_NAMES.TIMESHEET_APPROVAL,
  //   explanation: 'Select who can view the Timesheet Approval Report.'
  // },
  access_workload_report: {
    question: "Doesn't apply to this version",
    permissionLabel: REPORT_NAMES.WORKLOAD,
    explanation:
      'Select who can view the current Workload (total planned time) for members, projects, and oganization.'
  }
};

const dashboardPermissions: Partial<PermissionActionHash> = {
  // New Permissions In V2
  manage_dashboards: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Create & Edit Dashboards',
    explanation: 'Select who can Create & Edit Dashboards'
  },
  manage_widgets: {
    question: "Doesn't apply to this version",
    permissionLabel: 'Add/Edit/Delete Widgets',
    explanation: ''
  }
};

// Anything commented out has not been implmented yet on BE and FE
const projectPermissions: Partial<PermissionActionHash> = {
  create_project_managers: {
    question: '',
    permissionLabel: 'Set Project Managers',
    explanation: ''
  },
  // create_projects: {
  //   question: '',
  //   permissionLabel: 'Create New Projects',
  //   explanation: ''
  // },
  // edit_project_privacy: {
  //   question: '',
  //   permissionLabel: 'Make Projects Private',
  //   explanation: ''
  // },
  archive_projects: {
    question: 'Who can archive projects?',
    permissionLabel: 'Archive projects',
    explanation: ''
  },
  // delete_projects: {
  //   question: '',
  //   permissionLabel: 'Delete Projects',
  //   explanation: ''
  // },

  /*
    Currently, BE maps invite_project_guests to the same permission as
    invite_guests. Hide Invite Guests in Projects section to avoid confusion since
    the two are not synced and control the same permission.
  */

  // invite_project_guests: {
  //   question: '',
  //   permissionLabel: 'Invite Guests',
  //   explanation: ''
  // },
  manage_project_memberships: {
    question: '',
    permissionLabel: 'Add/Remove members from projects',
    explanation: ''
  },
  edit_comments: {
    question: '',
    permissionLabel: 'Edit Comments',
    explanation: ''
  },
  delete_comments: {
    question: '',
    permissionLabel: 'Delete Comments',
    explanation: ''
  },
  delete_tasks: {
    question: '',
    permissionLabel: 'Delete Tasks',
    explanation: ''
  },
  // clone_projects: {
  //   question: '',
  //   permissionLabel: 'Clone Projects',
  //   explanation: ''
  // },
  manage_project_views: {
    question: '',
    permissionLabel: 'Add/Remove Columns on Projects',
    explanation: ''
  },
  modify_project_colors: {
    question: '',
    permissionLabel: 'Change the Color of Projects',
    explanation: ''
  },
  edit_schedules: {
    question: '',
    permissionLabel: 'Edit Schedules',
    explanation: ''
  },
  // read_project_budgets: {
  //   question: '',
  //   permissionLabel: 'Access Project Budgets',
  //   explanation: 'Select who can View Project Budgets'
  // },
  read_budget_by_cost_rate: {
    question: '',
    permissionLabel: 'View Budgets By Cost Rate',
    explanation:
      'Select who can View Project Budgets by Cost Rate, which are based on member pay rates.'
  }
};

export const permissionConfigs = {
  organizationPermissions,
  timesheetPermissions,
  workplanPermissions,
  integrationPermissions,
  profilePermissions,
  budgetPermissions,
  portfolioPermissions,
  reportPermissions,
  dashboardPermissions,
  projectPermissions
};
