import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchMembersUtilizationReport } from 'UtilizationModule/actionCreators';
import { makeGetMemberUtilizationsOrder } from 'UtilizationModule/selectors';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import MiniTimesheetStatusTable from './MiniTimesheetStatusTable/MiniTimesheetStatusTable';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';
import { generateDateRange, DATE_RANGES } from 'appUtils/dateRangeHelpers';

const ROWS_PER_PAGE = 4;

const TimesheetStatusWidget = ({
  filterId,
  activeFilter,
  teamId,
  accountOrder,
  fetchMembersUtilizationReport,
  widgetConfig
}) => {
  const fetchParams = useMemo(
    () => ({
      account_ids:
        activeFilter.account_ids?.slice(0, widgetConfig.limits.account_ids) ||
        [],
      filterStateId: filterId,
      team_id: teamId,
      show_status_totals: true,
      interval_type: 'weeks',
      interval_amount: 1,
      ...generateDateRange({ range: DATE_RANGES.PAST_4_WEEKS, startOfWeek: 0 })
    }),
    [
      activeFilter.account_ids,
      filterId,
      teamId,
      widgetConfig.limits.account_ids
    ]
  );

  useEffect(() => {
    if (fetchParams.account_ids.length && teamId) {
      fetchMembersUtilizationReport({
        ...fetchParams,
        initial: true
      });
    }
  }, [fetchMembersUtilizationReport, fetchParams, teamId]);

  return (
    <DynamicModuleLoader
      modules={[getUtilizationModule(), getCapacitiesModule()]}
    >
      <div
        className="dashboard-widget scrollbar2"
        style={{ height: '230px', overflowY: 'auto' }}
      >
        <MiniTimesheetStatusTable
          statusView={
            activeFilter.custom?.statusView || TIMESHEET_STATUSES.ENTERED
          }
          filterStateId={filterId}
          isDashboardWidget
          hasData={!!fetchParams.account_ids.length}
        />
      </div>
    </DynamicModuleLoader>
  );
};

const makeMapStateToProps = () => {
  const getMemberUtilizationsOrder = makeGetMemberUtilizationsOrder();
  const mapStateToProps = (state, ownProps) => {
    return {
      accountOrder: getMemberUtilizationsOrder(state, ownProps)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchMembersUtilizationReport
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(React.memo(TimesheetStatusWidget));
