import { useEffect, useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  fetchTeamMemberProfile,
  fetchTeamMembersByBoard,
  fetchTeams,
  fetchWorkGroups,
  deleteWorkGroupMembership,
  createWorkGroup,
  navigateToTeamSettings
} from 'actionCreators';
import { getMyUserId, getSelectedTeamId, getActiveTeam } from 'selectors';
import { getFormattedWorkGroupSettings } from 'SettingsModule/selectors';
import { MemberCardsSectionWrapper } from './styles';
import TeamMembersSection from './TeamMembersSection';
import WorkGroupHeader from './WorkGroupHeader';
import {
  useUserPermissions,
  useCheckPermission
} from 'hocs/withPermissionsCheck';
import Landing from 'views/personalSettings/DepartmentsTable/Landing';
import ReactTooltip from 'react-tooltip';
import { AddDeptButton } from 'components/AddDeptButton';

const TeamMemberDepartmentsTab = ({
  fetchTeams,
  fetchWorkGroups,
  workGroups,
  fetchTeamMemberProfile,
  deleteWorkGroupMembership,
  myId,
  regularTeamMembers,
  guests,
  teamId,
  team
}) => {
  const dispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(null);
  const userPermissions = useUserPermissions();
  const checkPermission = useCheckPermission();

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams, fetchWorkGroups]);
  useEffect(() => {
    if (teamId) {
      fetchWorkGroups({ teamId, permissions: { teamId } });
    }
  }, [fetchWorkGroups, teamId]);

  useEffect(() => {
    const permission = dispatch(
      checkPermission(createWorkGroup, { permissions: { teamId } })
    );
    if (permission !== hasPermission) {
      setHasPermission(permission);
    }
  }, [checkPermission, dispatch, hasPermission, teamId, userPermissions]);

  const handleMemberCardClick = useCallback(
    (teamMembershipId) => {
      fetchTeamMemberProfile({ teamMemberId: teamMembershipId });
    },
    [fetchTeamMemberProfile]
  );

  const handleRemoveFromWorkGroup = (memberId, workGroupId) => {
    if (hasPermission) {
      deleteWorkGroupMembership({
        accountId: memberId,
        workGroupId
      });
    }
  };

  const handleNavigateToAdminPanel = () => {
    if (hasPermission) {
      ReactTooltip.hide();
      dispatch(
        navigateToTeamSettings({
          teamSlug: team && team?.slug,
          viewType: 'members',
          tab: 'departments'
        })
      );
    }
  };

  return (
    <MemberCardsSectionWrapper
      style={{ marginTop: '10px', overflow: 'hidden', paddingBottom: '0' }}
    >
      <AddDeptButton />
      {!workGroups.length ? (
        <Landing handleCreate={handleNavigateToAdminPanel} displayTooltip />
      ) : (
        <StyledWorkGroupContainer>
          {workGroups
            .filter((workGroup) => !workGroup?.discarded_at)
            .map((workGroup) => {
              return (
                <TeamMembersSection
                  key={workGroup.id}
                  teamMembers={workGroup.formattedWorkGroupMembers.map(
                    (formattedMember) => formattedMember.member
                  )}
                  header={<WorkGroupHeader workGroup={workGroup} />}
                  workGroupProps={{ workGroup, handleRemoveFromWorkGroup }}
                />
              );
            })}
        </StyledWorkGroupContainer>
      )}
    </MemberCardsSectionWrapper>
  );
};

const mapStateToProps = (state) => ({
  workGroups: getFormattedWorkGroupSettings(state),
  teamId: getSelectedTeamId(state),
  myId: getMyUserId(state),
  team: getActiveTeam(state)
});

const mapDispatchToProps = {
  fetchTeamMemberProfile,
  fetchTeamMembersByBoard,
  fetchTeams,
  fetchWorkGroups,
  deleteWorkGroupMembership
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMemberDepartmentsTab);

const StyledWorkGroupContainer = styled.div`
  overflow: auto;
  /* 160px is header height */
  height: calc(100vh - 160px);
`;
