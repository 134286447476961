import { useState, useRef, useCallback, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useCallbackEffect } from 'appUtils/hooks/useCallbackEffect';

export const useHandlePrint = () => {
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  const onBeforeGetContentResolve = useRef<(dummyValue: number) => void>();

  const handleOnBeforeGetContent = useCallback(
    () =>
      new Promise<number>((resolve) => {
        onBeforeGetContentResolve.current = resolve;
        setIsPrinting(true);
      }),
    []
  );

  // used useCallbackEffect to make handleOnBeforeGetContent's resolve get called
  // on the next render cycle instead of with the same cycle that changes isPrinting
  const resolveOnBeforeGetContent = useCallbackEffect(
    onBeforeGetContentResolve.current
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: () => {
      setIsPrinting(false);
      onBeforeGetContentResolve.current = undefined;
    }
  });

  // resolves promise that needs to wait for rerender
  useEffect(() => {
    if (isPrinting) {
      // useCallbackEffect takes args and it triggers callback when args change
      // since resolve doesn't require any args, we pass in a dummy value that
      // can differentiate between calls. Date.now() returns number which is
      // different every millisecond
      resolveOnBeforeGetContent(Date.now());
    }
  }, [isPrinting, resolveOnBeforeGetContent]);

  return {
    printRef,
    isPrinting,
    handlePrint
  };
};
