import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

const BudgetContainer = lazy(() => import('./BudgetContainer'));
const LoadBudgetContainer = () => (
  <DynamicModuleLoader modules={[getBudgetModule()]}>
    <Suspense fallback={<div />}>
      <BudgetContainer />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadBudgetContainer;
