import { Draft, createReducer } from '@reduxjs/toolkit';
import * as constants from 'EventsModule/constants';
import keyBy from 'lodash/keyBy';
import { EventsState } from 'EventsModule/types';
import {
  fetchGoogleCalendarEventsActionCreatorsMap,
  updateGoogleCalendarEventsActionCreatorsMap
} from 'EventsModule/actionCreators';

export const initialState: EventsState = {
  googleCalendarEvents: {}
};

const handleFetchGoogleCalendarEventsSuccess = (
  state: Draft<EventsState>,
  action: ReturnType<typeof fetchGoogleCalendarEventsActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.googleCalendarEvents = keyBy(response.calendar_events, 'id');
};

const handleUpdateGoogleCalendarEventsSuccess = (
  state: Draft<EventsState>,
  action: ReturnType<typeof updateGoogleCalendarEventsActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  const { calendar_event } = response;
  const { id } = calendar_event;
  state.googleCalendarEvents[id] = calendar_event;
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(
    constants.FETCH_GOOGLE_CALENDAR_EVENTS.SUCCESS,
    handleFetchGoogleCalendarEventsSuccess
  );
  builder.addCase(
    constants.UPDATE_GOOGLE_CALENDAR_EVENTS.SUCCESS,
    handleUpdateGoogleCalendarEventsSuccess
  );
});

export default reducer;
