import { createContext, PropsWithChildren, useContext } from 'react';
import { TimelineConfig } from 'TimelinesModule/types/timeline';

type TimelineConfigValues = TimelineConfig;

interface TimelineConfigProviderProps {
  config: TimelineConfig;
}

const TimelineConfigContext = createContext<TimelineConfigValues>(
  {} as TimelineConfigValues
);
export const TimelineConfigProvider = ({
  children,
  config
}: PropsWithChildren<TimelineConfigProviderProps>) => {
  return (
    <TimelineConfigContext.Provider value={{ ...config }}>
      {children}
    </TimelineConfigContext.Provider>
  );
};

export const useTimelineConfig = () => useContext(TimelineConfigContext);
