import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import omit from 'lodash/omit';
import produce from 'immer';
import { Position, AccountPosition } from 'models/position';
import { AnyAction } from '@reduxjs/toolkit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FixMe = any;

export interface PositionsState {
  isFetchingPositions: boolean;
  positionIds: number[];
  positions: Record<number, Position>;
  isPositionsLoaded: boolean;
  teamPositionsHash: Record<FixMe, FixMe>;
  accountToTeamPositionId: Record<FixMe, FixMe>;
  teamPositionOrderByAccount: Record<FixMe, FixMe>;
  memberPositionsByMemberBudget: Record<FixMe, FixMe>;
  positionRatesHash: Record<FixMe, FixMe>;
  currentPositionRates: Record<FixMe, FixMe>;
  currentPositionCostRates: Record<FixMe, FixMe>;
  positionRateOrders: Record<FixMe, FixMe>;
  positionCostRateOrders: Record<FixMe, FixMe>;
  isFetchingPositionRates: Record<'all' | number, boolean>;
  isFetchingPositionCostRates: Record<'all' | number, boolean>;
  isFetchingAccountPositions: boolean;
  isAccountPositionsLoaded: boolean;
  isFetchingTeamPositions: boolean;
  isTeamPositionsLoaded: boolean;
  accountPositionsHash: Record<number, AccountPosition>;
  accountPositionIdsByAccount: Record<number, number[]>;
}

export const initialState: PositionsState = {
  isFetchingPositions: false,
  positionIds: [], // team's position Ids
  positions: {}, // position hash
  isPositionsLoaded: false,
  teamPositionsHash: {}, // current default team roles for members
  accountToTeamPositionId: {}, // key: teamPosition.account_id, value: TeamPosition id
  teamPositionOrderByAccount: {}, // historical default team roles for members, keyed by account id, value is array of TeamPosition ids
  memberPositionsByMemberBudget: {}, // historical project level roles keyed by member budget id
  positionRatesHash: {}, // PositionRate = standard role's default team rate
  currentPositionRates: {}, // current (active) PositionRate IDs by position_id
  currentPositionCostRates: {}, // same as above, but PositionRates that have is_cost_rate: true
  positionRateOrders: {}, // PositionRate history arrays (PositionRate ids) by position_id
  positionCostRateOrders: {}, // same as above, but PositionRates that have is_cost_rate: true
  isFetchingPositionRates: {
    all: false
    // position id: true/false
  },
  isFetchingPositionCostRates: {
    all: false
    // position id: true/false
  },
  isFetchingAccountPositions: false,
  isAccountPositionsLoaded: false,
  isFetchingTeamPositions: false,
  isTeamPositionsLoaded: false,
  accountPositionsHash: {}, // accountPositions data
  accountPositionIdsByAccount: {}
};

const { WS_POSITION, WS_TEAM_POSITION } = constants;

const byId = (item) => item.id;

const positions = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_POSITIONS.TRIGGER: {
      return {
        ...state,
        isFetchingPositions: true
      };
    }
    case budgetConstants.FETCH_POSITIONS.FAILURE: {
      return {
        ...state,
        isPositionsLoaded: true,
        isFetchingPositions: false
      };
    }
    case budgetConstants.FETCH_POSITIONS.SUCCESS: {
      const { positions } = action.payload.response;
      return {
        ...state,
        positionIds: positions.map(byId),
        positions: {
          ...state.positions,
          ...keyBy(positions, byId)
        },
        isFetchingPositions: false,
        isPositionsLoaded: true
      };
    }
    case budgetConstants.FETCH_POSITION.SUCCESS: {
      const { position } = action.payload.response;
      return {
        ...state,
        positions: {
          ...state.positions,
          [position.id]: position
        }
      };
    }
    case budgetConstants.CREATE_POSITION.SUCCESS: {
      const { position } = action.payload.response;
      return {
        ...state,
        positionIds: state.positions[position.id] // could exist if WS update comes in before create
          ? state.positionIds
          : [...state.positionIds, position.id],
        positions: {
          ...state.positions,
          [position.id]: position
        }
      };
    }
    case WS_POSITION: {
      if (action.payload.deleted) {
        return {
          ...state,
          positions: omit(state.positions, action.payload.id),
          positionIds: state.positionIds.filter(
            (id) => id !== action.payload.id
          )
        };
      } else {
        return {
          ...state,
          positions: {
            ...state.positions,
            [action.payload.id]: action.payload
          },
          positionIds: state.positions[action.payload.id]
            ? state.positionIds
            : [...state.positionIds, action.payload.id]
        };
      }
    }

    case budgetConstants.UPDATE_POSITION.SUCCESS: {
      const { position } = action.payload.response;
      return {
        ...state,
        positions: {
          ...state.positions,
          [position.id]: position
        }
      };
    }

    /* ----------------------------- Team positions ----------------------------- */
    case budgetConstants.FETCH_TEAM_POSITIONS.TRIGGER: {
      return {
        ...state,
        isFetchingTeamPositions: true
      };
    }
    case budgetConstants.FETCH_TEAM_POSITIONS.FAILURE: {
      return {
        ...state,
        isTeamPositionsLoaded: true,
        isFetchingTeamPositions: false
      };
    }
    case budgetConstants.FETCH_TEAM_POSITIONS.SUCCESS: {
      const teamPositions = action.payload.response;
      const {
        requestPayload: { accountId }
      } = action.payload;

      const nextState = { ...state };
      nextState.teamPositionsHash = {
        ...state.teamPositionsHash,
        ...keyBy(teamPositions, byId)
      };
      if (accountId) {
        nextState.teamPositionOrderByAccount = {
          ...state.teamPositionOrderByAccount,
          [accountId]: teamPositions.map(byId)
        };
      } else {
        nextState.accountToTeamPositionId = {
          ...state.accountToTeamPositionId,
          ...teamPositions.reduce((acc, teamPosition) => {
            acc[teamPosition.account_id] = teamPosition.id;
            return acc;
          }, {})
        };
        nextState.isTeamPositionsLoaded = true;
        nextState.isFetchingTeamPositions = false;
      }
      return nextState;
    }

    case budgetConstants.CREATE_TEAM_POSITION.SUCCESS: {
      const newTeamPosition = action.payload.response;
      const { accountId } = action.payload.requestPayload;
      return {
        ...state,
        // teamPositionOrderByAccount: gets updated by re-fetch for member's team positions
        accountToTeamPositionId: {
          ...state.accountToTeamPositionId,
          [accountId]: newTeamPosition.id
        },
        teamPositionsHash: {
          ...state.teamPositionsHash,
          [newTeamPosition.id]: newTeamPosition
        }
      };
    }

    case WS_TEAM_POSITION: {
      if (action.payload.deleted) {
        return {
          ...state,
          accountToTeamPositionId: omit(
            state.accountToTeamPositionId,
            action.payload.account_id
          ),
          teamPositionsHash: omit(
            state.teamPositionsHash,
            action.payload.position_id
          )
        };
      } else {
        return {
          ...state,
          accountToTeamPositionId: {
            ...state.accountToTeamPositionId,
            [action.payload.account_id]: action.payload.position_id
          },
          teamPositionsHash: {
            ...state.teamPositionsHash,
            [action.payload.position_id]: action.payload
          }
        };
      }
    }

    case budgetConstants.DELETE_TEAM_POSITION.SUCCESS: {
      const updatedMemberTeamPositions = action.payload.response;
      const { accountId } = action.payload.requestPayload;
      if (accountId) {
        // sort newest to first of array to match response from fetchTeamPositions
        updatedMemberTeamPositions.sort(
          (a, b) =>
            moment(b.start_date, 'YYYY-MM-DD').valueOf() -
            moment(a.start_date, 'YYYY-MM-DD').valueOf()
        );
        return {
          ...state,
          teamPositionOrderByAccount: {
            ...state.teamPositionOrderByAccount,
            [accountId]: updatedMemberTeamPositions.map(byId)
          },
          accountToTeamPositionId: {
            ...state.accountToTeamPositionId,
            [accountId]: updatedMemberTeamPositions[0]?.id
          },
          teamPositionsHash: {
            ...state.teamPositionsHash,
            ...keyBy(updatedMemberTeamPositions, byId)
          }
        };
      }
      return state;
    }

    /* ---------------------------- Member Positions ---------------------------- */

    case budgetConstants.FETCH_MEMBER_POSITIONS.SUCCESS: {
      // fetch currently returns all (not just active) member positions -> there will be 1 per member budget id since we aren't creating/deleting anywhere
      const memberPositions = action.payload.response;
      return {
        ...state,
        memberPositionsByMemberBudget: {
          ...state.memberPositionsByMemberBudget,
          ...groupBy(
            memberPositions,
            (memberPosition) => memberPosition.member_budget_id
          )
        }
      };
    }

    /* ---------------------------- Account Positions --------------------------- */

    case budgetConstants.FETCH_ACCOUNT_POSITIONS.TRIGGER: {
      return { ...state, isFetchingAccountPositions: true };
    }
    case budgetConstants.FETCH_ACCOUNT_POSITIONS.FAILURE: {
      return { ...state, isFetchingAccountPositions: false };
    }
    case budgetConstants.FETCH_ACCOUNT_POSITIONS.SUCCESS: {
      const {
        requestPayload: { accountId },
        response: accountPositions
      } = action.payload;

      const nextState = {
        ...state,
        isFetchingAccountPositions: false,
        isAccountPositionsLoaded: true
      };
      nextState.accountPositionsHash = {
        ...state.accountPositionsHash,
        ...keyBy(accountPositions, byId)
      };
      if (accountId) {
        nextState.accountPositionIdsByAccount = {
          ...state.accountPositionIdsByAccount,
          [accountId]: accountPositions.map(byId)
        };
      } else {
        nextState.accountPositionIdsByAccount = {
          ...state.accountPositionIdsByAccount,
          ...groupBy(
            accountPositions,
            (accountPosition) => accountPosition.account_id
          )
        };
      }
      return nextState;
    }

    case budgetConstants.DELETE_ACCOUNT_POSITION.SUCCESS: {
      const nextState = { ...state };
      const { id } = action.payload.requestPayload;

      nextState.accountPositionsHash = omit(nextState.accountPositionsHash, [
        id
      ]);

      return nextState;
    }

    /* ----------------------------- Position Rates ----------------------------- */

    case budgetConstants.FETCH_POSITION_RATES.TRIGGER: {
      const { positionId, activeOnly, isCostRate } = action.payload;
      const isFetching = isCostRate
        ? 'isFetchingPositionCostRates'
        : 'isFetchingPositionRates';
      return produce(state, (draftState) => {
        if (positionId && !activeOnly) {
          draftState[isFetching][positionId] = true;
        } else if (!positionId) {
          draftState[isFetching].all = true;
        }
      });
    }

    case budgetConstants.FETCH_POSITION_RATES.SUCCESS: {
      const positionRates = action.payload.response;
      const { positionId, isCostRate, activeOnly } =
        action.payload.requestPayload;

      return produce(state, (draftState) => {
        positionRates.forEach((positionRate) => {
          draftState.positionRatesHash[positionRate.id] = positionRate;
        });
        const currentRates = isCostRate
          ? 'currentPositionCostRates'
          : 'currentPositionRates';
        const rateHistories = isCostRate
          ? 'positionCostRateOrders'
          : 'positionRateOrders';
        const isFetching = isCostRate
          ? 'isFetchingPositionCostRates'
          : 'isFetchingPositionRates';

        if (positionId) {
          const updateCurrentRateOnly = activeOnly;
          if (updateCurrentRateOnly) {
            // updating a single position's current rate
            draftState[currentRates][positionId] = positionRates[0]?.id;
            draftState[isFetching][positionId] = false;
          } else {
            // updating a single position's PositionRate history
            draftState[rateHistories][positionId] = positionRates.map(
              (positionRate) => positionRate.id
            );
          }
        } else {
          if (activeOnly) {
            // updating all positions' current rates
            positionRates.forEach((positionRate) => {
              draftState[currentRates][positionRate.position_id] =
                positionRate.id;
            });
          }
          draftState[isFetching].all = false;
        }
      });
    }

    default:
      return state;
  }
};

export default positions;
