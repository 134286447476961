import { useTeamBudgetViewSettings } from 'BudgetModule/hooks/useTeamBudgetViewSettings';
import { Switch } from 'components/Switch';

export const PhaseReferenceField = () => {
  const isPhaseReferenceEnabled = false;
  // const {
  //   budgetViewSettings: { is_phase_reference_enabled: isPhaseReferenceEnabled },
  //   updateBudgetViewSettings
  // } = useTeamBudgetViewSettings();

  const handleChange = () => undefined;
  // const handleChange = () =>
  //   updateBudgetViewSettings({
  //     is_phase_reference_enabled: !isPhaseReferenceEnabled
  //   });

  return <Switch isChecked={isPhaseReferenceEnabled} onChange={handleChange} />;
};
