export const CHART_TYPE = {
  BAR: 'BAR',
  AREA_GRAPH: 'AREA_GRAPH',
  LINEAR_GRAPH: 'LINEAR_GRAPH'
};
export const CHART_TYPE_LABEL = {
  BAR: 'Bar',
  AREA_GRAPH: 'Graph',
  LINEAR_GRAPH: 'Graph'
};

export const SET_TIMESHEET_REMINDER_DAY = 'SET_TIMESHEET_REMINDER_DAY';
export const HISTORICIAL_UTILIZATION = {
  COMPLIANT_START_PERIOD: 90,
  THRESHOLD: 0.5
};

export const REPORT_NAMES = {
  TIMESHEET: 'Timesheets',
  BUDGET: 'Budget Tracking',
  WORKLOAD: 'Workload',
  UTILIZATION: 'Utilization',
  PROFIT: 'Profit',
  TIME_VARIANCE: 'Time Variance',
  TIMESHEET_STATUS: 'Timesheet Status',
  DEMAND: 'Role Demand',
  BUDGET_VARIANCE: 'Budget Variance',
  WORKLOAD_FORECAST: 'Workload Forecast',
  BILLING_FORECAST: 'Billing Forecast',
  SCHEDULE_VARIANCE: 'Schedule Variance',
  SKILLS: 'Skills',
  CHECKINS: 'Check-ins',
  ALL_PROJECTS: 'All Projects',
  PTO: 'PTO',
  TIME_PROJECTION: 'Time Projection',
  SPENT_TIME: 'Spent Time',
  PLANNED_TIME: 'Planned Time',
  UNSPENT_BUDGET: 'Backlog'
};
