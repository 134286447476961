import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
interface ToggleProps {
  value: boolean;
  setValue: (val: boolean) => void;
}

const Toggle = (props: ToggleProps) => {
  const { value, setValue } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  return (
    <ToggleContainer>
      <ToggleHandle
        checked={value}
        onChange={(e) => handleChange(e)}
        id="toggle"
        type="checkbox"
      />
      <ToggleLabel htmlFor="toggle" />
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  position: relative;
  cursor: pointer;
  line-height: 0;
`;

// Styles for toggle in the 'off' state
export const ToggleLabel = styled.label`
  width: 21px;
  height: 11px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  margin-bottom: 0;
  &::after {
    content: '';
    display: block;
    border-radius: 15px;
    width: 9px;
    height: 9px;
    margin-left: 2px;
    margin-top: 1px;
    background: #ffffff;
    transition: 0.4s;
  }
`;
// Styles for toggle in the 'on' state
export const ToggleHandle = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 21px;
  height: 11px;
  cursor: pointer;
  &:checked + ${ToggleLabel} {
    background: ${theme.colors.colorQbTooltipGreen};
    &::after {
      content: '';
      display: block;
      border-radius: 15px;
      width: 9px;
      height: 9px;
      margin-top: 1px;
      margin-left: 10px;
      transition: 0.4s;
    }
  }
`;

export default Toggle;
