import React from 'react';
import SvgIcon from 'components/SvgIcon';

const HeaderTooltipIcon = ({ className, width = '16', height = '16' }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      fill="transparent"
      stroke="#afafaf"
    />
    <path
      d="M10.5 6.09655C10.5 6.53793 10.3906 6.91678 10.1717 7.2331C9.95276 7.54943 9.64747 7.84736 9.25576 8.1269C9.07143 8.25931 8.9255 8.38069 8.81797 8.49103C8.71044 8.60138 8.62788 8.71356 8.57028 8.82759C8.51267 8.94161 8.47619 9.06299 8.46083 9.19172C8.44547 9.32046 8.43779 9.46942 8.43779 9.63862V9.91448H7.49309V9.60552C7.49309 9.31126 7.51805 9.06115 7.56797 8.85517C7.6179 8.64919 7.6947 8.46345 7.79839 8.29793C7.90207 8.13241 8.03456 7.97793 8.19585 7.83448C8.35714 7.69103 8.55299 7.53471 8.78341 7.36552C9.01383 7.19632 9.18856 7.01609 9.3076 6.82483C9.42665 6.63356 9.48618 6.38713 9.48618 6.08552C9.48618 5.76184 9.37097 5.4823 9.14055 5.2469C9.00998 5.12184 8.85254 5.02437 8.6682 4.95448C8.48387 4.8846 8.28802 4.84966 8.08065 4.84966C7.82719 4.84966 7.60446 4.89195 7.41244 4.97655C7.22043 5.06115 7.05914 5.17517 6.92857 5.31862C6.798 5.46207 6.70008 5.62942 6.63479 5.82069C6.56951 6.01196 6.53687 6.21425 6.53687 6.42759H5.5C5.5 6.11862 5.55568 5.81701 5.66705 5.52276C5.77842 5.2285 5.94163 4.9692 6.15668 4.74483C6.37174 4.52046 6.64055 4.34023 6.96313 4.20414C7.28572 4.06805 7.65822 4 8.08065 4C8.40323 4 8.71236 4.04782 9.00806 4.14345C9.30376 4.23908 9.56106 4.37701 9.77995 4.55724C9.99885 4.73747 10.1736 4.95632 10.3041 5.21379C10.4347 5.47127 10.5 5.76552 10.5 6.09655ZM7.34332 12V10.7862H8.56452V12H7.34332Z"
      fill="#afafaf"
    />
  </SvgIcon>
);

export default HeaderTooltipIcon;
