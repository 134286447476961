import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTaskColumnOrder, getMatchedRouteParams } from 'selectors';
import TaskColumnDraggable from './TaskColumnDraggable';

import { updateProjectModules } from 'actionCreators';
import { withRouter } from 'react-router-dom';
import ColumnsDropdown from 'components/ColumnsDropdown';

const taskRemoveSubtext =
  'It will be removed from this view for all project members but data will not be deleted and can be added back at any time.';

const customStyles = {
  top: '0px',
  position: 'relative'
};
class TaskColumnsDropdown extends Component {
  handleReorder = (order) => {
    const {
      updateProjectModules,
      matchedParams: { projectId }
    } = this.props;
    updateProjectModules({
      projectId,
      taskColumns: order
    });
  };

  render() {
    const { taskColumns, show, hideContents, virtualStyles = {} } = this.props;
    return (
      <ColumnsDropdown
        DraggableComponent={TaskColumnDraggable}
        handleReorder={this.handleReorder}
        columns={taskColumns}
        removeSubtext={taskRemoveSubtext}
        show={show}
        hideContents={hideContents}
        customStyles={{ ...customStyles, ...virtualStyles }}
        isOnProjectView
      />
    );
  }
}
const mapStateToProps = (state) => ({
  taskColumns: getTaskColumnOrder(state),
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateProjectModules
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskColumnsDropdown)
);
