import { all, fork, takeEvery } from 'redux-saga/effects';
import { updateWorkPlanTasks } from 'TaskModule/actionCreators/workPlanTaskAssociationActionCreators';
import { updateWorkPlanTasksWorker } from './homeTasksWorkers';
import { homeTasksSagas as legacyHomeTasksSagas } from 'sagas/homeTasksSagas';

export function* homeTasksSagas() {
  const homeTasksSagas = [
    fork(takeEvery, updateWorkPlanTasks, updateWorkPlanTasksWorker),
    ...legacyHomeTasksSagas
  ];

  yield all(homeTasksSagas);
}
