import { ReactNode } from 'react';
import {
  Sticky,
  StickyWrapper
} from 'views/projectPlanner/WorkPlanItemRenderers/styles';

export const StickyContainer = ({
  children
}: {
  children?: ReactNode | undefined;
}) => (
  <StickyWrapper>
    <Sticky>{children}</Sticky>
  </StickyWrapper>
);
