import KaratRight from 'icons/KaratRight';
import styled from 'styled-components';
import cn from 'classnames';

interface CollapseIconButtonProps {
  isCollapsed: boolean;
}
export const CollapseIconButton = ({
  isCollapsed
}: CollapseIconButtonProps) => {
  return (
    <StyledKaratIcon
      className={cn('app-cues-styled-karat', {
        isCollapsed
      })}
    />
  );
};

const StyledKaratIcon = styled(KaratRight)`
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  path {
    stroke-width: 1;
    stroke: ${({ theme }) => theme.colors.colorCalendarBlue};
  }
  transform: rotate(90deg);

  &.isCollapsed {
    transform: rotate(0deg);
  }
`;
