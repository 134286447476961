import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledSelectCell,
  StyledSelectCellContainer,
  ClickableArea
} from '../styles';
import { getWorkloadEventsSelectedEvent } from 'selectors';
import {
  batchSelectWorkloadEventRecipients,
  clearBatchSelectedWorkloadEventRecipients
} from 'actionCreators';

const BulkHeaderCell = ({ data }) => {
  const selectedEvent = useSelector(getWorkloadEventsSelectedEvent) || {};
  const dispatch = useDispatch();

  const memberIdsWithCommitment = useMemo(() => {
    const ids = [];
    data.forEach((member) => {
      if (member?.account && member?.percentCommitted) {
        ids.push(member.account?.id);
      }
    });
    return ids;
  }, [data]);

  const isAllSelected =
    selectedEvent.recipient_account_ids?.length >=
      memberIdsWithCommitment.length && memberIdsWithCommitment.length;

  const onBatchSelect = () => {
    if (isAllSelected) {
      dispatch(
        clearBatchSelectedWorkloadEventRecipients({
          eventId: selectedEvent.scheduled_event_id
        })
      );
    } else {
      dispatch(
        batchSelectWorkloadEventRecipients({
          eventId: selectedEvent.scheduled_event_id,
          ids: memberIdsWithCommitment,
          value: 1
        })
      );
    }
  };

  return (
    <StyledSelectCellContainer>
      <ClickableArea onClick={onBatchSelect} />
      <StyledSelectCell isChecked={isAllSelected}></StyledSelectCell>
    </StyledSelectCellContainer>
  );
};

export default BulkHeaderCell;
