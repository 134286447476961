import styled from 'styled-components';
import { ProjectFields } from './ProjectFields';
import { MemberFields } from './MemberFields';
import { PredictableFields } from './PredictableFields';
import { StyledDateContainer } from '../../workloadBarModal/styles';

export const WorkPlanForm = () => {
  return (
    <FormContainer>
      <PrimaryFieldContainer>
        <ProjectFields />
      </PrimaryFieldContainer>
      <SecondaryFieldContainer>
        <MemberFields />
      </SecondaryFieldContainer>
      <PredictableFieldsContainer>
        <PredictableFields />
      </PredictableFieldsContainer>
    </FormContainer>
  );
};

const FormContainer = styled.div``;

const PrimaryFieldContainer = styled.div`
  margin-bottom: 33px;

  .project-title {
    font-size: 18px;
  }

  .phase-name {
    max-width: 370px;

    &.show-activities {
      max-width: 200px;
    }
  }
  .selected-activity {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ColorFilledBox = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.colorPaleGray7};
  background-color: ${({ theme }) => theme.colors.colorLightGray19};
  border-radius: 4px;
`;

const SecondaryFieldContainer = styled(ColorFilledBox)`
  margin-bottom: 20px;
`;

const PredictableFieldsContainer = styled.div`
  margin-bottom: 12px;

  ${StyledDateContainer} {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;
