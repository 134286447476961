import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createRequestTypes } from 'appUtils';
import {
  FetchTeamPermissionsParams,
  UpdateTeamPermissionsParams,
  FetchTeamPermissionsSuccessResponse,
  UpdateTeamPermissionsSuccessResponse,
  UpdateTeamMembersPermissionParams,
  UpdateTeamMembersPermissionSuccessResponse,
  BulkInviteAccountsParams
} from './api/types';
import { PermissionsActions } from './types';

/* --------------------- update team members permission --------------------- */

const UPDATE_TEAM_MEMBERS_PERMISSION = createRequestTypes(
  'UPDATE_TEAM_MEMBERS_PERMISSION'
);

export const updateTeamMembersPermission =
  createAction<UpdateTeamMembersPermissionParams>(
    UPDATE_TEAM_MEMBERS_PERMISSION.TRIGGER
  );

export const updateTeamMembersPermissionActionCreatorsMap =
  createActionCreatorsMap<
    UpdateTeamMembersPermissionParams,
    UpdateTeamMembersPermissionSuccessResponse
  >(UPDATE_TEAM_MEMBERS_PERMISSION);

/* ------------------------- toggle permission open ------------------------- */

export const togglePermissionOpen = createAction<{
  actionType: PermissionsActions;
}>('TOGGLE_PERMISSION_OPEN');

export const toggleAllPermissionsOpen = createAction<{ open: boolean }>(
  'TOGGLE_ALL_PERMISSIONS_OPEN'
);

/* ----------------------------------- bulk invite accounts ----------------------------------- */

const BULK_INVITE_ACCOUNTS = createRequestTypes('BULK_INVITE_ACCOUNTS');

export const bulkInviteAccounts = createAction<BulkInviteAccountsParams>(
  BULK_INVITE_ACCOUNTS.TRIGGER
);

export const bulkInviteAccountsActionCreatorsMap =
  createActionCreatorsMap<BulkInviteAccountsParams>(BULK_INVITE_ACCOUNTS);

// *********************** FETCH TEAM PERMISSIONS *************************

const FETCH_TEAM_PERMISSIONS = createRequestTypes('FETCH_TEAM_PERMISSIONS');

export const fetchTeamPermissions = createAction(
  FETCH_TEAM_PERMISSIONS.TRIGGER,
  ({ meta, ...payload }: ActionParamsWithMeta<FetchTeamPermissionsParams>) => ({
    meta,
    payload
  })
);

export const fetchTeamPermissionsActionCreatorsMap = createActionCreatorsMap<
  FetchTeamPermissionsParams,
  FetchTeamPermissionsSuccessResponse
>(FETCH_TEAM_PERMISSIONS);

// *********************** UPDATE TEAM PERMISSIONS *************************

const UPDATE_TEAM_PERMISSIONS = createRequestTypes('UPDATE_TEAM_PERMISSIONS');

export const updateTeamPermissions = createAction(
  UPDATE_TEAM_PERMISSIONS.TRIGGER,
  ({
    meta,
    ...payload
  }: ActionParamsWithMeta<UpdateTeamPermissionsParams>) => ({
    meta,
    payload
  })
);

export const updateTeamPermissionsActionCreatorsMap = createActionCreatorsMap<
  UpdateTeamPermissionsParams,
  UpdateTeamPermissionsSuccessResponse
>(UPDATE_TEAM_PERMISSIONS);
