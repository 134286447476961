import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportRate
} from 'CsvImportsModule/types';
import uniq from 'lodash/uniq';
import { getRowValuesForEntityType } from './utils';

interface AdditionalParams {
  existingRatesGroupedByRateAmount: Record<string, CsvImportRate[]>;
  existingRatesByRateDescription: Record<string, CsvImportRate>;
  newRateAmounts: React.MutableRefObject<Record<string, boolean>>;
  newRateDescriptionAmountHashes: React.MutableRefObject<
    Record<string, string>
  >;
}

export const rateAmountOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingRatesGroupedByRateAmount,
    existingRatesByRateDescription,
    newRateAmounts,
    newRateDescriptionAmountHashes
  } = additionalParams;

  const { enteredRateDescription } = getRowValuesForEntityType(row, entityType);

  const existingRateMatchedByEnteredDescription =
    existingRatesByRateDescription[enteredRateDescription];
  const newRateMatchedByEnteredDescription =
    newRateDescriptionAmountHashes.current[enteredRateDescription];

  // no description entered or it is new description, grab all rates available
  if (
    !enteredRateDescription ||
    enteredRateDescription === TRAINING_DATA_REQUIRED_STRING ||
    (existingRateMatchedByEnteredDescription === undefined &&
      newRateMatchedByEnteredDescription === undefined)
  ) {
    return uniq([
      ...Object.keys(newRateAmounts.current),
      ...Object.values(newRateDescriptionAmountHashes.current),
      ...Object.keys(existingRatesGroupedByRateAmount)
    ]);
  } else {
    // grab only the one that matches the description, this ignores newRateAmounts because that is not associated with a rate description
    if (
      existingRateMatchedByEnteredDescription === undefined &&
      newRateMatchedByEnteredDescription !== undefined
    )
      return [newRateMatchedByEnteredDescription];
    else if (existingRateMatchedByEnteredDescription) {
      return [existingRateMatchedByEnteredDescription.rate];
    }
    // there will not be a case where both existingRateMatchedByEnteredDescription and newRateMatchedByEnteredDescription is not undefined because
    // rate description is unique and should only exists in one of the two if it exists.
  }
  return [];
};
