import { TaskCard, TaskCardProps } from './TaskCard';
import styled from 'styled-components';
import { ReactNode } from 'react';

interface AddTaskRowProps extends TaskCardProps {
  /**
   * The class for the row.
   */
  className?: string;

  /**
   * The class for the task card.
   */
  taskCardClassName?: string;

  /**
   * The content for the container after the task card.
   */
  trailing?: ReactNode;
}

export const AddTaskRow = ({
  className,
  taskCardClassName,
  trailing,
  ...taskProps
}: AddTaskRowProps) => {
  return (
    <AddTaskRowContainer className={className}>
      <TaskCard className={taskCardClassName} {...taskProps} />
      {trailing && <TrailingContainer>{trailing}</TrailingContainer>}
    </AddTaskRowContainer>
  );
};

const AddTaskRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const TrailingContainer = styled.div`
  inset: 0 auto 0 100%;
  position: absolute;
`;
