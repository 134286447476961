import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSelectedProject, getIsTaskSidebarOpen } from 'selectors';
import { toggleTaskSidebarIsOpen } from 'actionCreators';

import TaskSidebarContainer from './TaskSidebarContainer';
import { StyledSmallHamburger, SidebarTrigger } from './styles';

const ProjectDetailTaskSidebarContainer = () => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(getSelectedProject);
  const isTaskSidebarOpen = useSelector(getIsTaskSidebarOpen);

  const toggleIsOpen = () => {
    const projectId = isTaskSidebarOpen ? null : selectedProject?.id;
    dispatch(
      toggleTaskSidebarIsOpen({
        isOpen: !isTaskSidebarOpen,
        projectId
      })
    );
  };
  return (
    <>
      <SidebarTrigger onClick={toggleIsOpen} isOpen={isTaskSidebarOpen}>
        <StyledSmallHamburger /> Tasks
      </SidebarTrigger>
      <TaskSidebarContainer />
    </>
  );
};

export default ProjectDetailTaskSidebarContainer;
