import { useCallback } from 'react';
import { useMount } from 'react-use';
import { togglePermissionOpen } from 'PermissionsModule/actionCreators';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { PermissionsActions } from 'PermissionsModule/types';
import {
  PermissionEdits,
  IsSpecificMemberCheckedByPermissionAction,
  MakeEditPermissionType
} from 'PermissionsModule/hooks/types';
import {
  PermissionActionHash,
  SpecificMemberPermissionHash
} from 'PermissionsModule/components/PermissionsTable/types';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

import {
  PERMISSION_SECTION,
  permissionsActionsHash
} from 'PermissionsModule/constants';
import { buildPermittedRoles } from 'PermissionsModule/utils';
import {
  HeaderContainer,
  PermissionText,
  ExplanationText,
  PermissionHeaderKaratRightHoverSstyle,
  PermissionHeaderTextContainer,
  PermissionHeaderKaratRight,
  PermissionHeaderSubTextContainer
} from 'PermissionsModule/components/styles';
import Collapse from 'SettingsModule/components/Collapse';
import {
  getAllPermissionsByActions,
  getOneOffPermissionersMap,
  getPermissionActionsExpanded,
  getPermissionOptionsByActions
} from 'PermissionsModule/selectors';
import { PermissionSelectionContainer } from 'PermissionsModule/components/PermissionsSections/shared/PermissionSelectionContainer';
import { getCheckboxListItems } from 'PermissionsModule/utils/checkboxOptionsUtils';
import { PermissionSection } from 'PermissionsModule/components/PermissionsSections/PermissionSection';
import ProjectsIcon from 'icons/ProjectsIcon';
import styled from 'styled-components';
import { permissionConfigs } from 'PermissionsModule/permissionConfigs';
import { ValueOf } from 'type-fest';
import cn from 'classnames';

const ContainerDiv = styled.div`
  overflow: auto;
  width: fit-content;
`;

const PermissionsContainer = styled.div`
  display: flex;
  width: 900px;
  flex-direction: column;
`;

const sectionComponentConfigs: Partial<
  Record<
    ValueOf<typeof PERMISSION_SECTION>,
    {
      headerText: string;
      icon: (props: unknown) => JSX.Element;
      permissions: Partial<PermissionActionHash>;
    }
  >
> = {
  [PERMISSION_SECTION.PROJECTS]: {
    headerText: 'Projects',
    icon: ProjectsIcon,
    permissions: permissionConfigs.projectPermissions
  }
};

const specificMemberPermissionsHash: SpecificMemberPermissionHash = {
  edit_comments: true,
  delete_comments: true
};

interface ProjectPermissionEditProps {
  makeEditPermission: MakeEditPermissionType;
  unSavedEdits: PermissionEdits;
  sectionOrder: ValueOf<typeof PERMISSION_SECTION>[];
  isSpecificMemberCheckedByPermissionAction: IsSpecificMemberCheckedByPermissionAction;
}

export const ProjectPermissionEdit = ({
  makeEditPermission,
  unSavedEdits,
  sectionOrder,
  isSpecificMemberCheckedByPermissionAction
}: ProjectPermissionEditProps) => {
  const dispatch = useAppDispatch();

  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const expandedPermissions = useAppSelector(getPermissionActionsExpanded);
  const permissionOptionsByAction = useAppSelector(
    getPermissionOptionsByActions
  );

  const oneOffPermissionersMap = useAppSelector(getOneOffPermissionersMap);

  useMount(() => {
    rebuildTooltip();
  });

  const toggleOpen = useCallback(
    (actionType: PermissionsActions) => {
      dispatch(togglePermissionOpen({ actionType }));
    },
    [dispatch]
  );

  const renderHeader = useCallback(
    (permissionAction: PermissionsActions) => {
      const roleTemplatesHash = permissionsByAction[permissionAction];
      const isOpen = expandedPermissions[permissionAction];
      const permissionData = permissionsActionsHash[permissionAction];

      const SubTextComponent = !isOpen ? (
        roleTemplatesHash ? (
          <PermissionText>
            {buildPermittedRoles(roleTemplatesHash)}
          </PermissionText>
        ) : null
      ) : permissionData && permissionData.explanation ? (
        <ExplanationText>{permissionData.explanation}</ExplanationText>
      ) : null;

      return (
        <HeaderContainer>
          <PermissionHeaderTextContainer>
            <PermissionHeaderKaratRight
              height="10px"
              width="7px"
              className={cn({ open: isOpen })}
            />
            {permissionData ? permissionData.permissionLabel : ''}
          </PermissionHeaderTextContainer>
          <PermissionHeaderSubTextContainer>
            {SubTextComponent}
          </PermissionHeaderSubTextContainer>
        </HeaderContainer>
      );
    },
    [expandedPermissions, permissionsByAction]
  );

  const renderPermissionRow = useCallback(
    (permissionAction: PermissionsActions) => {
      const options = permissionOptionsByAction[permissionAction];

      const flatOptions = getCheckboxListItems({
        items: options ? options.map((option) => ({ name: option })) : [],
        options: specificMemberPermissionsHash[permissionAction]
          ? { includeSpecificMember: true }
          : undefined
      });

      const permissionCheckboxValues = permissionsByAction[permissionAction];

      return (
        <Collapse
          header={renderHeader(permissionAction)}
          isOpen={expandedPermissions[permissionAction]}
          toggleOpen={() => toggleOpen(permissionAction)}
          showKarat={false}
          headerContainerStyle={PermissionHeaderKaratRightHoverSstyle}
        >
          {permissionCheckboxValues && (
            <PermissionSelectionContainer
              checkboxOptions={flatOptions}
              checkboxValues={permissionCheckboxValues}
              unSavedEdits={unSavedEdits[permissionAction]}
              isSpecificMemberChecked={
                isSpecificMemberCheckedByPermissionAction[permissionAction]
              }
              oneOffPermissioners={oneOffPermissionersMap[permissionAction]}
              onCheck={makeEditPermission(permissionAction)}
            />
          )}
        </Collapse>
      );
    },
    [
      permissionOptionsByAction,
      renderHeader,
      expandedPermissions,
      permissionsByAction,
      makeEditPermission,
      toggleOpen,
      unSavedEdits,
      isSpecificMemberCheckedByPermissionAction,
      oneOffPermissionersMap
    ]
  );

  return (
    <ContainerDiv>
      <PermissionsContainer>
        {sectionOrder.map((section) => {
          const permissionSectionComponentConfigs =
            sectionComponentConfigs[section];
          return permissionSectionComponentConfigs &&
            Object.keys(permissionSectionComponentConfigs.permissions)
              .length ? (
            <PermissionSection
              renderPermissionRow={renderPermissionRow}
              headerText={permissionSectionComponentConfigs.headerText}
              Icon={permissionSectionComponentConfigs.icon}
              permissions={permissionSectionComponentConfigs.permissions}
            />
          ) : (
            <></>
          );
        })}
      </PermissionsContainer>
    </ContainerDiv>
  );
};
