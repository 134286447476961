import { fork } from 'redux-saga/effects';
import { conditionalTakeLatest } from 'sagas/helpers';

import { fetchLeanApiWorker } from './sagaWorkers';
import { fetchLeanApi } from '../actionCreators';

export const leanApiSagas = [
  fork(
    conditionalTakeLatest,
    fetchLeanApi,
    fetchLeanApiWorker,
    (action) => action.meta?.isTakeLatest,
    ['isoStateId']
  )
];
