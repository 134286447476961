import React from 'react';
import SvgIcon from 'components/SvgIcon';

const DropdownThinIcon = ({ width = '10', height = '6', fill = '#0074D9' }) => (
  <SvgIcon width={width} height={height} viewBox="0 0 10 6">
    <path
      d="M5.00032 5.00899L0.450117 0.000699997L0 0.496204L5.00032 6L10 0.495502L9.54988 0L5.00032 5.00899Z"
      fill={fill}
    />
  </SvgIcon>
);

export default DropdownThinIcon;
