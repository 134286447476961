import styled from 'styled-components';
import UnassignedRolesListBody from './UnassignedRolesListBody';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';

const UnassignedRolesListContainer = ({ isLoading }) => {
  return (
    <Container>
      <SkeletonLoader isLoading={isLoading} count={15}>
        <UnassignedRolesListBody />
      </SkeletonLoader>
    </Container>
  );
};

export default UnassignedRolesListContainer;

const Container = styled.div`
  height: 100%;
  margin-top: 1rem;
  margin-left: -7px;
`;
