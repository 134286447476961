import styled from 'styled-components';

const IntegrationNotificationActivityContainer = styled.div`
  margin-left: 10px;
  width: 500px;
`;

const IntegrationNotificationActivityBody = ({ displayData }) => (
  <IntegrationNotificationActivityContainer>
    <p>{displayData.message}</p>
  </IntegrationNotificationActivityContainer>
);

export default IntegrationNotificationActivityBody;
