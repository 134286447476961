import React from 'react';
import { VIEW_BY } from 'appConstants/workload';

import { CreateGroupButton, StyledAddListIcon } from './styles';

const newListCopy = {
  [VIEW_BY.TASK_GROUPS]: 'New List',
  [VIEW_BY.PHASES]: 'New Phase'
};

const NewListButton = ({ handleClick, viewBy, isDefaultList, numLists }) => {
  const copyText = newListCopy[viewBy];
  return (
    <CreateGroupButton
      onClick={handleClick}
      style={{
        position: 'relative',
        top: isDefaultList ? 24 : -11,
        left: numLists > 1 ? 51 : 18
      }}
    >
      {copyText && (
        <>
          <StyledAddListIcon />
          {copyText}
        </>
      )}
    </CreateGroupButton>
  );
};

export default NewListButton;
