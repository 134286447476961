import moment from 'moment';
import * as constants from 'appConstants';
import { getMondayOfWeek } from 'appUtils/momentUtils';

const {
  OPEN_PROJECT_PLANNER_MODAL,
  CLOSE_PROJECT_PLANNER_MODAL,
  SET_PROJECT_PLANNER_MEMBER,
  SET_VISIBLE_WEEK,
  ADD_NEW_PROJECT_ROW,
  DELETE_PROJECT_ROW
} = constants;

const initialState = {
  isOpen: false,
  selectedAccountId: null,
  visibleTimeStart: getMondayOfWeek(moment()),
  visibleTimeEnd: getMondayOfWeek(moment()).add(1, 'week'),
  members: {}
};

const projectPlannerModal = (state = initialState, action) => {
  const { type, payload } = action;
  const {
    memberId,
    date,
    visibleTimeStart,
    visibleTimeEnd,
    projectId,
    visibleIndices
  } = payload || {};

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case OPEN_PROJECT_PLANNER_MODAL: {
      return {
        ...state,
        isOpen: true,
        selectedAccountId: memberId,
        visibleTimeStart: getMondayOfWeek(date),
        visibleTimeEnd: getMondayOfWeek(date).add(1, 'week')
      };
    }
    case CLOSE_PROJECT_PLANNER_MODAL: {
      return {
        ...state,
        isOpen: false,
        selectedAccountId: null
      };
    }
    case SET_PROJECT_PLANNER_MEMBER: {
      return {
        ...state,
        selectedAccountId: memberId
      };
    }
    case SET_VISIBLE_WEEK: {
      return {
        ...state,
        visibleTimeStart,
        visibleTimeEnd
      };
    }
    case ADD_NEW_PROJECT_ROW: {
      return {
        ...state,
        members: {
          ...state.members,
          [memberId]: {
            ...state.members[memberId],
            [date]:
              state.members[memberId] && state.members[memberId][date]
                ? [...state.members[memberId][date], projectId]
                : [projectId]
          }
        }
      };
    }
    case DELETE_PROJECT_ROW: {
      return {
        ...state,
        members: {
          ...state.members,
          [memberId]: {
            ...state.members[memberId],
            [date]:
              state.members[memberId] &&
              state.members[memberId][date] &&
              state.members[memberId][date].filter((id) => id !== projectId)
          }
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default projectPlannerModal;
