import React from 'react';

class BodyColor extends React.Component {
  componentDidMount() {
    const isGray = !!this.props.isGray;
    const isWhite = !!this.props.isWhite;
    const isCyanBlue = !!this.props.isCyanBlue;
    document.body.classList.toggle('is-white', isWhite);
    document.body.classList.toggle('is-gray', isGray);
    document.body.classList.toggle('is-cyan', isCyanBlue);
  }

  componentDidUpdate() {
    const isGray = !!this.props.isGray;
    const isWhite = !!this.props.isWhite;
    const isCyanBlue = !!this.props.isCyanBlue;
    document.body.classList.toggle('is-white', isWhite);
    document.body.classList.toggle('is-gray', isGray);
    document.body.classList.toggle('is-cyan', isCyanBlue);
  }

  componentWillUnmount() {
    document.body.classList.remove('is-white');
    document.body.classList.remove('is-gray');
    document.body.classList.remove('is-cyan');
  }

  render() {
    return <></>;
  }
}

export default BodyColor;
