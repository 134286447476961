import React from 'react';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import keyBy from 'lodash/keyBy';

import {
  getSelectedTeamId,
  getOrderedNonBillableActivities,
  getOrderedBillableActivities,
  getOrderedAllActivities,
  getAllActivityRowInfo,
  getAllCustomActivities
} from 'selectors';
import {
  StyledRowItemPOW,
  StyledLinkIcon,
  LinkedContainer,
  StyledDiamondIconContainer,
  ArchivedText,
  StandardLabel
} from './styles';
import {
  openActivityDropdown,
  openActivityModal
} from 'BudgetModule/actionCreators';
import {
  createActivity,
  updateActivity,
  fetchActivities
} from 'actionCreators';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import ReactTooltip from 'react-tooltip';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';

const noop = () => {};

const customDivider = {
  id: 'customDivider',
  name: 'CUSTOM',
  isCustomDivider: true
};

const standardDivider = {
  id: 'standardDivider',
  name: 'STANDARD',
  isStandardDivider: true
};

class ActivitiesDropdown extends React.Component {
  getPermissions = () => {
    return {
      teamId: this.props.teamId
    };
  };

  renderItem = ({ item, selectCallback }) => {
    if (this.props.renderItem) {
      return this.props.renderItem({ item, selectCallback });
    }
    if (item.isStandardDivider || item.isCustomDivider) {
      return (
        <StyledRowItemPOW>
          <StandardLabel>{item.name}</StandardLabel>
        </StyledRowItemPOW>
      );
    }
    return (
      item && (
        <StyledRowItemPOW>
          <StyledDiamondIconContainer>
            {' '}
            <SmallDiamondIcon />
          </StyledDiamondIconContainer>

          {item.title || item.name}
          {item.isAlreadySelected ? (
            <LinkedContainer>
              <StyledLinkIcon />
              Linked
            </LinkedContainer>
          ) : null}
          {item.archived && <ArchivedText>Archived</ArchivedText>}
        </StyledRowItemPOW>
      )
    );
  };

  getCopy = () => {
    const { searchPlaceholder } = this.props;
    return {
      headerInitial: 'Select Work Category',
      headerEdit: 'Add/Edit Work Categories',
      headerAdd: 'New Work Category',
      sticky: 'Add New Work Category',
      footerInitial: (
        <LockWithTooltip
          text="Standard Work Categories"
          tooltipContent="Only Admins and Financial Managers</br>can edit Standard Work Categories"
        />
      ),
      footerEdit: 'Done',
      addConfirm: 'Add',
      editConfirm: 'Save',
      searchPlaceholder:
        searchPlaceholder || 'Type work category or select below'
    };
  };

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    e.stopPropagation();
    const { handleSelect, preventSelectCallback } = this.props;
    if (item.isStandardDivider || item.isCustomDivider) {
      return;
    }
    handleSelect(item);
    if (selectCallback && !preventSelectCallback) {
      selectCallback();
    }
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['title', 'description']
    });

  handleSubmit = (e, { item, isNew, submitCallback = noop }) => {
    e.preventDefault();
    const title = this.roleEdit.value;
    const { updateActivity, createActivity } = this.props;
    if (isNew) {
      createActivity({
        title
      });
    } else {
      const { id } = item;
      updateActivity({
        id,
        title
      });
    }
    submitCallback();
  };

  onFooterClick = () => {
    const { openActivityDropdown, handleClose } = this.props;
    const permissions = this.getPermissions();
    openActivityDropdown({ permissions });
    ReactTooltip.hide();
    handleClose();
  };

  renderHeader = () => {
    const { renderHeader, renderHeaderCopy } = this.props;
    return renderHeader ? renderHeader(renderHeaderCopy) : undefined;
  };

  handleOpenCreateActivityModal = () => {
    const { openActivityModal } = this.props;
    openActivityModal({ isCustomActivityModal: true });
  };

  // Only displays the activties that are on the phase
  getFilteredCustomActivities = () => {
    const { phaseActivityOrder, customActivities } = this.props;
    if (!phaseActivityOrder?.length) {
      // if no work categories, then there are definitely no custom work categories
      return [];
    }
    const activityIdsHash = keyBy(phaseActivityOrder);
    const filteredCustomActivities = customActivities.filter(
      (activity) => activityIdsHash[activity.id]
    );
    return filteredCustomActivities.length > 0
      ? [customDivider, ...filteredCustomActivities]
      : [];
  };

  // Only displays the activties that are not on phase (for adding single)
  getAvailableActivities = () => {
    const { phaseActivityOrder, allActivities } = this.props;

    const activityIdsHash = keyBy(phaseActivityOrder); // Ones that already exist
    // Shows only standard activities in the dropdown.
    const filteredStandardActivities = allActivities.filter(
      (activity) => !activityIdsHash[activity.id] && !activity.is_custom
    );
    return [
      // Show other standards not on the phase
      ...(filteredStandardActivities.length > 0
        ? [standardDivider, ...filteredStandardActivities]
        : [])
    ];
  };

  onStickyClick = (e) => {
    const { onStickyClick } = this.props;
    if (onStickyClick) {
      onStickyClick();
    }
  };

  render() {
    const {
      customActivities,
      allActivities,
      target,
      handleClose,
      parentActivities,
      createNewActivity,
      createNewActivityBtnClick,
      hideFooter,
      fetchActivities,
      listWidth,
      popoverClassName,
      placeholderSize,
      noHeader,
      showCloseInSearch,
      isAddingActivity,
      copy
    } = this.props;

    const items = parentActivities || allActivities;

    return (
      <MultiStepFlyout
        copy={{ ...this.getCopy(), ...copy }}
        items={
          isAddingActivity
            ? this.getAvailableActivities()
            : [standardDivider, ...items, ...this.getFilteredCustomActivities()]
        }
        idKey="id"
        renderHeader={this.renderHeader}
        renderItem={this.renderItem}
        handleSelect={this.handleSelect}
        handleSubmit={this.handleSubmit}
        itemFilter={this.itemFilter}
        isWhite
        searchEnabled={!isAddingActivity}
        editDisabled
        top={0}
        onFooterClick={this.onFooterClick}
        target={target}
        handleClose={handleClose}
        createNewActivity={createNewActivity}
        createNewActivityBtnClick={createNewActivityBtnClick}
        hideFooter={hideFooter}
        loadInitialItems={fetchActivities}
        popoverClassName={popoverClassName}
        listWidth={listWidth}
        placeholderSize={placeholderSize}
        noHeader={noHeader}
        showCloseInSearch={showCloseInSearch}
        stickyRow={isAddingActivity}
        onStickyClick={isAddingActivity ? this.onStickyClick : noop}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  customActivities: getAllCustomActivities(state),
  activities: getOrderedNonBillableActivities(state),
  billableActivities: getOrderedBillableActivities(state),
  allActivities: getOrderedAllActivities(state),
  all: getAllActivityRowInfo(state),
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  updateActivity,
  createActivity,
  openActivityDropdown,
  fetchActivities,
  openActivityModal
};

export default withPermissionsCheck(
  connect(mapStateToProps, mapDispatchToProps)(ActivitiesDropdown)
);
