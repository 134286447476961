import styled from 'styled-components';
import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { usePrioritiesFilter } from 'FilterModule/hooks';
import { FilterListTypeDropdown } from 'FilterModule/types';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';

export const PrioritiesFilterDropdown: FilterListTypeDropdown = ({
  renderToggle,
  field
}) => {
  const {
    updateSelectedItems,
    optionsArray,
    itemHash,
    selectedItems,
    labelKey
  } = usePrioritiesFilter({
    field
  });

  const renderLabel = (priorityId: number) => {
    const priority = itemHash[priorityId];
    if (!priority) return <></>;

    return (
      <StyledPriorityContainer>
        <StyledPriorityIcon
          currentColor={priority.color}
          className={undefined}
        />
        {priority.title}
      </StyledPriorityContainer>
    );
  };

  return (
    <SimpleFilterDropdown
      itemHash={itemHash}
      labelKey={labelKey}
      items={optionsArray}
      renderItemLabel={renderLabel}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isBatchClearOnly
      itemHeight={55}
      listWidth={195}
      listHeight={220}
      renderToggle={renderToggle}
      isSearchEnabled={false}
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

export const StyledPriorityContainer = styled.div`
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
`;

export const StyledPriorityIcon = styled(PriorityFlagIcon)<{
  currentColor: string;
}>`
  margin-right: 8px;
  path {
    box-shadow: 0px 0px 2px rgba(55, 55, 55, 0.25);
  }
`;
