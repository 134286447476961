import keyBy from 'lodash/keyBy';
import {
  LOGOUT_USER,
  BUDGET_RECORD_DATA_TYPES,
  FETCH_ALL_PROJECTS,
  FETCH_PHASES_BY_PROJECT_IDS
} from 'appConstants';
import { GROUP_BY } from 'ReportsModule/components/Profit/ProfitReportTable/constants';
import * as constants from '../constants';

const byPhaseOrProjectId = (record) => {
  return record.phase_id || record.project_id;
};

export const initialState = {
  isFetchingReport: false,
  isFetchingPhases: false,
  isFetchingProjects: false,
  isFetching: false, // isFetchingReport || isFetchingPhases || isFetchingProjects
  fetchedProjectOrder: [],
  fetchedPhaseOrder: [],
  phaseOrderByProjectId: {},
  recordsByDataType: {
    [BUDGET_RECORD_DATA_TYPES.PROJECT]: {},
    [BUDGET_RECORD_DATA_TYPES.PHASE]: {}
  },
  totalCounts: {
    // Grouped by projects
    [BUDGET_RECORD_DATA_TYPES.PROJECT]: 0,
    // Phases under a project (by project ID)
    phasesByProject: {},
    // Grouped by phase
    [BUDGET_RECORD_DATA_TYPES.PHASE]: 0
  }
};

const profitReport = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_PROFIT_REPORT.TRIGGER: {
      const { flushProjectOrder, flushPhaseOrder } = action.payload;
      return {
        ...state,
        isFetching: true,
        isFetchingReport: true,
        isFetchingProjects: true,
        isFetchingPhases: true,
        fetchedProjectOrder: flushProjectOrder ? [] : state.fetchedProjectOrder,
        fetchedPhaseOrder: flushPhaseOrder ? [] : state.fetchedPhaseOrder,
        phaseOrderByProjectId: flushPhaseOrder
          ? {}
          : state.phaseOrderByProjectId,
        totalCounts: {
          ...state.totalCounts,
          [BUDGET_RECORD_DATA_TYPES.PROJECT]: flushProjectOrder
            ? []
            : state.totalCounts[BUDGET_RECORD_DATA_TYPES.PROJECT],
          [BUDGET_RECORD_DATA_TYPES.PHASE]: flushPhaseOrder
            ? []
            : state.totalCounts[BUDGET_RECORD_DATA_TYPES.PHASE]
        }
      };
    }

    case constants.FETCH_PROFIT_REPORT.FAILURE: {
      return {
        ...state,
        isFetching: false,
        isFetchingReport: false,
        isFetchingProjects: false,
        isFetchingPhases: false
      };
    }

    case constants.FETCH_PROFIT_REPORT.SUCCESS: {
      const { records = [], total = 0 } = payload.response;
      const { groupBy, params } = payload.requestPayload;
      const { data_type } = params;

      const nextState = {
        ...state,
        isFetchingReport: false,
        ...(!records.length && {
          isFetchingPhases: false,
          isFetchingProjects: false
        }),
        recordsByDataType: {
          ...state.recordsByDataType,
          [data_type]: {
            ...state.recordsByDataType[data_type],
            ...keyBy(records, byPhaseOrProjectId)
          }
        }
      };

      switch (data_type) {
        case BUDGET_RECORD_DATA_TYPES.PROJECT: {
          nextState.fetchedProjectOrder = [
            ...state.fetchedProjectOrder,
            ...records.map((record) => record.project_id)
          ];

          nextState.totalCounts = {
            ...state.totalCounts,
            [BUDGET_RECORD_DATA_TYPES.PROJECT]: total
          };
          break;
        }

        case BUDGET_RECORD_DATA_TYPES.PHASE: {
          if (groupBy === GROUP_BY.PROJECT && params.project_ids) {
            // Loading for phases when grouped by project => only one project id
            const projectId = params.project_ids[0];
            nextState.phaseOrderByProjectId = {
              ...state.phaseOrderByProjectId,
              // Loading for phases when grouped by project => only one project id
              [projectId]: [
                ...(state.phaseOrderByProjectId[projectId] || []),
                ...records.map((record) => record.phase_id)
              ]
            };

            nextState.totalCounts = {
              ...state.totalCounts,
              phasesByProject: {
                ...state.totalCounts.phasesByProject,
                [projectId]: total
              }
            };
          } else {
            nextState.fetchedPhaseOrder = [
              ...state.fetchedPhaseOrder,
              ...records.map((record) => record.phase_id)
            ];

            nextState.totalCounts = {
              ...state.totalCounts,
              [BUDGET_RECORD_DATA_TYPES.PHASE]: total
            };
          }
          break;
        }
        default:
          break;
      }

      return nextState;
    }

    case FETCH_PHASES_BY_PROJECT_IDS.SUCCESS:
    case FETCH_PHASES_BY_PROJECT_IDS.FAILURE: {
      if (!state.isFetching) return state;
      return {
        ...state,
        isFetchingPhases: false,
        isFetching: state.isFetchingReport || state.isFetchingProjects
      };
    }

    case FETCH_ALL_PROJECTS.SUCCESS:
    case FETCH_ALL_PROJECTS.FAILURE: {
      if (!state.isFetching) return state;
      return {
        ...state,
        isFetchingProjects: false,
        isFetching: state.isFetchingReport || state.isFetchingPhases
      };
    }

    case constants.SET_PROFIT_REPORT_IS_FETCHING_PROJECTS: {
      const value = payload;
      return {
        ...state,
        isFetchingProjects: value,
        ...(!value &&
          state.isFetching &&
          !state.isFetchingReport &&
          !state.isFetchingPhases && {
            isFetching: false
          }),
        ...(value && {
          isFetching: true
        })
      };
    }

    case constants.SET_PROFIT_REPORT_IS_FETCHING_PHASES: {
      const value = payload;
      return {
        ...state,
        isFetchingPhases: value,
        ...(!value &&
          state.isFetching &&
          !state.isFetchingReport &&
          !state.isFetchingProjects && {
            isFetching: false
          }),
        ...(value && {
          isFetching: true
        })
      };
    }

    default:
      return state;
  }
};

export default profitReport;
