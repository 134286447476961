import { BaseTableColumn } from 'components/Table/types';

export const billableColumn: BaseTableColumn = {
  headerType: 'billableColumn',
  id: 'billableColumn'
};

export const billRateColumn: BaseTableColumn = {
  headerType: 'billRateColumn',
  id: 'billRateColumn'
};

export const collapseColumn: BaseTableColumn = {
  headerType: 'collapseColumn',
  id: 'collapseColumn'
};

export const costRateColumn: BaseTableColumn = {
  headerType: 'costRateColumn',
  id: 'costRateColumn'
};

export const descriptionColumn: BaseTableColumn = {
  headerType: 'descriptionColumn',
  id: 'descriptionColumn'
};

export const fullContentColumn: BaseTableColumn = {
  headerType: 'emptyGroupColumn',
  id: 'emptyGroupColumn'
};

export const fullWidthColumn: BaseTableColumn = {
  headerType: 'fullWidthColumn',
  id: 'fullWidthColumn'
};

export const menuColumn: BaseTableColumn = {
  headerType: 'menuColumn',
  id: 'menuColumn'
};

export const nameColumn: BaseTableColumn = {
  headerType: 'nameColumn',
  id: 'nameColumn'
};

export const rateColumn: BaseTableColumn = {
  headerType: 'rateColumn',
  id: 'rateColumn'
};

export const rateGroupHeaderColumn: BaseTableColumn = {
  headerType: 'workGroupHeaderColumn',
  id: 'workGroupHeaderColumn'
};
