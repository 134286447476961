import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

type Props = Pick<SvgIconProps, 'className' | 'width' | 'height' | 'stroke'>;

const SmallUnassignedMemberIcon = ({
  className,
  stroke = '#4F4F4F',
  width = '24',
  height = '24'
}: Props) => (
  <SvgIcon
    className={className}
    height={height}
    width={width}
    viewBox="0 0 13 13"
    fill="none"
  >
    <circle
      cx="6.5"
      cy="6.5"
      r="6.04286"
      stroke={stroke}
      strokeWidth="0.914286"
    />
    <ellipse
      cx="6.44056"
      cy="4.50868"
      rx="1.49964"
      ry="1.50868"
      stroke={stroke}
      strokeWidth="0.685551"
    />
    <path
      d="M9.49857 10.0017C9.49857 8.33529 8.15574 6.98438 6.49929 6.98438C4.84283 6.98438 3.5 8.33529 3.5 10.0017"
      stroke={stroke}
      strokeWidth="0.685551"
    />
  </SvgIcon>
);

export default SmallUnassignedMemberIcon;
