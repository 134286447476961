import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  makeGetActiveWorkloadPlannerFilter,
  getProjectPriorities
} from 'selectors';
import { fetchPriorities, updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';

const ProjectPrioritiesFilterDropdown = ({
  activeFilter,
  priorities,
  updateAccountFilterLocal,
  fetchPriorities,
  isHidden
}) => {
  const setFilterItems = (filterItem) => {
    updateAccountFilterLocal({
      ...activeFilter,
      priority_ids: filterItem.map(({ id }) => id)
    });
  };

  useEffect(() => {
    fetchPriorities({});
  }, [fetchPriorities]);

  const renderLabel = (item) => {
    return (
      <StyledProjectPriorityContainer>
        <StyledProjectPriorityIcon
          currentColor={item.color}
        ></StyledProjectPriorityIcon>
        {item.title}
      </StyledProjectPriorityContainer>
    );
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={priorities}
      renderLabel={renderLabel}
      initialSelectedItems={activeFilter.priority_ids}
      setFilterItems={setFilterItems}
      searchEnabled={false}
      toggleLabel="Priority"
      togglePluralLabel="Priorities"
      filterId={activeFilter.id}
      listWidth={217}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps),
    priorities: getProjectPriorities(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchPriorities,
  updateAccountFilterLocal
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ProjectPrioritiesFilterDropdown);

export const StyledProjectPriorityContainer = styled.div`
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
`;

export const StyledProjectPriorityIcon = styled(PriorityFlagIcon)`
  path {
    box-shadow: 0px 0px 2px rgba(55, 55, 55, 0.25);
  }
`;
