import cn from 'classnames';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { EmploymentTypes } from 'PermissionsModule/types';
import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { permissionsPresentationUtils } from './utils';
import { useAppSelector } from 'reduxInfra/hooks';
import { getUserIsAdmin } from 'PermissionsModule/selectors';
import useCan from 'appUtils/hooks/useCan';
import {
  inviteGuests,
  inviteMemberToTeam
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';

const { member, internalContractor, externalProjectContractor, projectGuest } =
  EMPLOYMENT_TYPES;

const employmentTypesOptions: { id: EmploymentTypes }[] = [
  member,
  internalContractor,
  externalProjectContractor,
  projectGuest
].map((type) => ({ id: type }));

interface EmploymentTypeDropdownOption {
  id: EmploymentTypes;
}

export interface EmploymentTypeDropdownProps {
  isOpen: boolean;
  anchorEl: HTMLElement | SVGElement | Falsy;
  employmentTypeOptions: Record<
    EmploymentTypes,
    { isAvailable?: boolean; unavailableTooltip?: string }
  >;
  onSelect: (employmentType: EmploymentTypes) => void;
  onClose: () => void;
}

export const EmploymentTypeDropdown = ({
  isOpen,
  anchorEl,
  employmentTypeOptions,
  onSelect,
  onClose
}: EmploymentTypeDropdownProps) => {
  const { MultiStepFlyout, openFlyout, closeFlyout } = useMultiStepFlyout();

  const canInviteToTeam = useCan(inviteMemberToTeam);

  const canInviteGuests = useCan(inviteGuests);

  const userIsAdmin = useAppSelector(getUserIsAdmin);

  const employmentTypePermissionCheckMap: Record<EmploymentTypes, boolean> =
    useMemo(() => {
      const value: Record<EmploymentTypes, boolean> = {
        [member]: canInviteToTeam && userIsAdmin,
        [internalContractor]: canInviteToTeam && userIsAdmin,
        [externalProjectContractor]:
          (canInviteToTeam || canInviteGuests) && userIsAdmin,
        [projectGuest]: (canInviteToTeam || canInviteGuests) && userIsAdmin
      };

      return value;
    }, [canInviteGuests, canInviteToTeam, userIsAdmin]);

  const _employmentTypesOptions = useMemo(() => {
    return employmentTypesOptions.filter(({ id: employmentType }) => {
      const employmentTypeParams = employmentTypeOptions[employmentType];

      const isKeep =
        employmentTypePermissionCheckMap[employmentType] &&
        (employmentTypeParams.isAvailable ||
          !!employmentTypeParams.unavailableTooltip);

      return isKeep;
    });
  }, [employmentTypeOptions, employmentTypePermissionCheckMap]);

  useEffect(() => {
    if (isOpen && anchorEl) openFlyout({ target: anchorEl });
    else closeFlyout();
  }, [isOpen, openFlyout, closeFlyout, anchorEl]);

  const handleSelect = (
    _,
    { item: { id: employmentType } }: { item: EmploymentTypeDropdownOption }
  ) => {
    const { isAvailable } = employmentTypeOptions[employmentType];

    if (isAvailable) {
      onSelect(employmentType);
    }
  };

  const hasAnyTooltips = useMemo(() => {
    return Object.values(employmentTypeOptions).some(
      ({ unavailableTooltip }) => !!unavailableTooltip
    );
  }, [employmentTypeOptions]);

  useEffect(() => {
    if (hasAnyTooltips) {
      rebuildTooltip();
    }
  });

  const renderItem = ({
    item: { id: employmentType }
  }: {
    item: EmploymentTypeDropdownOption;
  }) => {
    const label =
      permissionsPresentationUtils.employmentTypeToOptionContent[
        employmentType
      ];

    const { isAvailable, unavailableTooltip } =
      employmentTypeOptions[employmentType];

    return (
      <ItemContainer
        {...defaultTooltipProps}
        data-tip={unavailableTooltip}
        data-tip-disable={isAvailable}
        className={cn({ disabled: !isAvailable })}
      >
        <ItemText>{label}</ItemText>
      </ItemContainer>
    );
  };

  return (
    <MultiStepFlyout
      items={_employmentTypesOptions}
      idKey="id"
      renderItem={renderItem}
      copy={{}}
      noHeader
      hideFooter
      editDisabled
      itemHeight={48}
      listWidth={404}
      minListHeight={0}
      popoverClassName="employment-type-dropdown"
      handleSelect={handleSelect}
      handleClose={onClose}
    />
  );
};

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 15px;

  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }

  &.disabled {
    opacity: 0.6;
  }
`;

const ItemText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray2};
`;
