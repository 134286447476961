import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchSkills } from 'actionCreators';
import { getAreSkillsLoaded, getIsLoadingSkills } from 'selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const emptyObj = {};

export const useLoadSkills = ({
  isOff,
  shouldAutoLoad = true
}: {
  isOff?: boolean;
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const isLoaded: boolean = useAppSelector(getAreSkillsLoaded);
  const isLoading: boolean = useAppSelector(getIsLoadingSkills);

  const loadSkills = useCallback(() => {
    if (!isLoaded && !isLoading && teamId) {
      dispatch(fetchSkills({ teamId }));
    }
  }, [dispatch, isLoaded, teamId, isLoading]);

  useEffect(() => {
    if (!isOff && shouldAutoLoad) {
      loadSkills();
    }
  }, [isOff, loadSkills, shouldAutoLoad]);

  return {
    isLoaded,
    isLoading,
    loadSkills
  };
};
