import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import LandingImage from 'images/departments-header.png';
import { CloseButton } from 'SettingsModule/components/Budget/styles';

const StyledLanding = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 41%;
  min-width: 489px;
  margin: auto;
  text-align: center;
  color: ${theme.colors.colorMediumGray9};
`;
const StyledHeading = styled.h1`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 20px;
`;
const StyledDescription = styled.p`
  font-size: 16px;
`;
const StyledButton = styled(CloseButton)`
  width: auto;
  padding: 2px 8px;
  line-height: 1;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    filter: brightness(97%);
  }
`;

/**
 * Image and text that shows on the departments tab when there are no departments
 */
const Landing = ({ handleCreate, displayTooltip }) => {
  return (
    <StyledLanding>
      <img src={LandingImage} alt="Departments" width={423} height={299} />
      <StyledHeading>Departments</StyledHeading>
      <StyledDescription>
        Departments are for grouping members by Division, Industry, Territory,
        or Product line. You can search and filter to quickly view information
        by Team members.
      </StyledDescription>
      <StyledButton
        onClick={handleCreate}
        data-for="app-tooltip"
        data-class="center"
        data-tip={
          displayTooltip ? 'Only Admins can <br/> manage Departments' : ''
        }
        data-html
      >
        Create a Department
      </StyledButton>
    </StyledLanding>
  );
};

export default React.memo(Landing);
