import React from 'react';
import AccessModal from './AccessModal';
import { connect } from 'react-redux';
import {
  getTeamMembershipsByAccountId,
  getSelectedTeamId,
  getSortedTeamMembers,
  makeGetEditOrViewIdsHash,
  makeGetEditIdsHash,
  makeGetViewIdsHash
} from 'selectors';
import { updateAccess } from 'actionCreators';
import { ACCESS_LABELS, ACCESS_BUCKETS } from 'appConstants/access';
import { ACCESS_ROLES } from 'PermissionsModule/constants';

import { calculateHighestTeamMembershipRoleId } from 'appUtils/roleDisplay';

// Workload manager should always have budget manager permissions
const {
  WORKLOAD_MANAGER,
  TEAM_BUDGET_MANAGER,
  WORKLOAD_MANAGER_VIEW_ONLY,
  FINANCIAL_MANAGER,
  FINANCIAL_MANAGER_VIEW_ONLY
} = ACCESS_ROLES;
const { CAN_EDIT_BUCKETS, CAN_VIEW_BUCKETS, NO_BUCKETS, ALL_BUCKETS } =
  ACCESS_BUCKETS;

const removeOption = {
  label: ACCESS_LABELS.REMOVE,
  isValidAdminRole: false,
  value: {
    addableRoleIds: [],
    removableRoleIds: [
      WORKLOAD_MANAGER,
      // TEAM_BUDGET_MANAGER,
      WORKLOAD_MANAGER_VIEW_ONLY //,
      // FINANCIAL_MANAGER,
      // FINANCIAL_MANAGER_VIEW_ONLY
    ],
    addableAccessBuckets: NO_BUCKETS,
    removableAccessBuckets: ALL_BUCKETS
  }
};

const options = [
  {
    label: ACCESS_LABELS.CAN_EDIT,
    isValidAdminRole: true,
    value: {
      addableRoleIds: [WORKLOAD_MANAGER],
      removableRoleIds: [
        WORKLOAD_MANAGER_VIEW_ONLY,
        FINANCIAL_MANAGER_VIEW_ONLY
        // TEAM_BUDGET_MANAGER,
        // FINANCIAL_MANAGER
      ],
      addableAccessBuckets: CAN_EDIT_BUCKETS,
      removableAccessBuckets: CAN_VIEW_BUCKETS
    }
  },
  {
    label: ACCESS_LABELS.VIEW_ONLY,
    isValidAdminRole: false,
    value: {
      addableRoleIds: [WORKLOAD_MANAGER_VIEW_ONLY],
      removableRoleIds: [
        WORKLOAD_MANAGER
        // TEAM_BUDGET_MANAGER,
        // FINANCIAL_MANAGER,
        // FINANCIAL_MANAGER_VIEW_ONLY
      ],
      addableAccessBuckets: CAN_VIEW_BUCKETS,
      removableAccessBuckets: CAN_EDIT_BUCKETS
    }
  },
  removeOption
];
class WorkloadAccessModal extends React.Component {
  getInfoCopy = () => {
    const { editOrViewIds } = this.props;
    if (!editOrViewIds) {
      return '';
    }
    return `Visible to ${Object.keys(editOrViewIds).length}`;
  };

  handleSelect = (roles, member) => {
    const { updateAccess, teamId } = this.props;
    const {
      addableRoleIds,
      removableRoleIds,
      addableAccessBuckets,
      removableAccessBuckets,
      isValidAdminRole
    } = roles;
    if (isValidAdminRole || !member.is_admin) {
      updateAccess({
        actableId: teamId,
        actableType: 'Team',
        actionType: 'activity_phase_schedule_bars_other',
        accountIds: [member.account.id],
        addableRoleIds,
        removableRoleIds,
        addableAccessBuckets,
        removableAccessBuckets
      });
    }
  };

  handleAdd = (member) => {
    this.handleSelect({ addableRoleIds: [12], removableRoleIds: [] }, member);
  };

  getMembers = () => {
    const { sortedTeamMembers, editIds, editOrViewIds } = this.props;

    const includedMembers = sortedTeamMembers
      .filter((member) => editOrViewIds[member?.account?.id])
      .map((member) => ({
        ...member,
        label: editIds[member.account.id]
          ? ACCESS_LABELS.CAN_EDIT
          : ACCESS_LABELS.VIEW_ONLY
      }))
      .sort(
        (a, b) =>
          calculateHighestTeamMembershipRoleId(b) -
          calculateHighestTeamMembershipRoleId(a)
      );
    return includedMembers;
  };

  getNonMembers = () => {
    const { sortedTeamMembers, editOrViewIds } = this.props;

    return sortedTeamMembers.filter(
      (membership) => !editOrViewIds[membership?.account?.id]
    );
  };

  render() {
    const { accessIdentifier } = this.props;
    return (
      <AccessModal
        members={this.getMembers()}
        nonMembers={this.getNonMembers()}
        title={'Work Planner Access'}
        handleSelect={this.handleSelect}
        handleAdd={this.handleAdd}
        options={options}
        removeOption={removeOption}
        accessIdentifier={accessIdentifier}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getEditOrViewIdsHash = makeGetEditOrViewIdsHash();
  const getEditIdsHash = makeGetEditIdsHash();
  const getViewIdsHash = makeGetViewIdsHash();
  const mapStateToProps = (state, ownProps) => ({
    teamMembershipsByAccountId: getTeamMembershipsByAccountId(state),
    sortedTeamMembers: getSortedTeamMembers(state),
    teamId: getSelectedTeamId(state),
    editIds: getEditIdsHash(state, ownProps),
    viewIds: getViewIdsHash(state, ownProps),
    editOrViewIds: getEditOrViewIdsHash(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccess
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WorkloadAccessModal);
