import { select } from 'redux-saga/effects';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { getAuthToken } from 'selectors';

const { fetchTeamCapacity, updateTeamCapacity } = entityActions;

export function* fetchTeamCapacityWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchTeamCapacity,
    api.fetchTeamCapacity,
    teamId,
    [token],
    action
  );
}

export function* updateTeamCapacityWorker(action) {
  const token = yield select(getAuthToken);
  const { capacity } = action.payload;
  const { id } = capacity;
  const { error, response } = yield changeEntity(
    updateTeamCapacity,
    api.updateTeamCapacity,
    [token, id, capacity]
  );
}
