import { callApi } from 'appUtils/api';
import qs from 'qs';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import {
  FetchCapacityPoliciesParams,
  CreateCapacityPolicyParams,
  UpdateCapacityPolicyParams,
  ArchiveCapacityPolicyParams,
  FetchCapacityPoliciesPerMemberParams,
  FetchCapacityGroupMembershipsParams,
  CreateCapacityGroupMembershipParams,
  UpdateCapacityGroupMembershipParams,
  DeleteCapacityGroupMembershipParams
} from './types';

const fetchCapacityPolicies = (
  token: string,
  {
    teamId,
    isFetchOnlyCustom = false,
    isIncludeArchived
  }: FetchCapacityPoliciesParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(
    convertKeysToSnakeCase({
      teamId,
      isCustom: isFetchOnlyCustom,
      includeArchived: isIncludeArchived
    })
  );

  return callApi(`capacity_groups?${stringifiedQueryParams}`, token, fetchInit);
};

const createCapacityPolicy = (
  token: string,
  {
    name,
    capacity,
    teamId,
    isCustom,
    teamMembershipIds
  }: CreateCapacityPolicyParams
) => {
  const body = {
    name,
    capacity,
    ...convertKeysToSnakeCase({
      teamId,
      teamMembershipIds,
      isCustom
    })
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('capacity_group', token, fetchInit);
};

const updateCapacityPolicy = (
  token: string,
  { id, name, capacity }: UpdateCapacityPolicyParams
) => {
  const body = { name, capacity };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`capacity_group/${id}`, token, fetchInit);
};

const archiveCapacityPolicy = (
  token: string,
  { id, isArchive }: ArchiveCapacityPolicyParams
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `capacity_groups/${id}/${isArchive ? 'archive' : 'unarchive'}`,
    token,
    fetchInit
  );
};

const fetchCapacityPoliciesPerMember = (
  token: string,
  { teamId }: FetchCapacityPoliciesPerMemberParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(
    convertKeysToSnakeCase({
      teamId
    })
  );

  return callApi(
    `capacity_groups/policy_per_members?${stringifiedQueryParams}`,
    token,
    fetchInit
  );
};

const fetchCapacityGroupMemberships = (
  token: string,
  { teamMembershipId }: FetchCapacityGroupMembershipsParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(
    convertKeysToSnakeCase({
      teamMembershipId
    })
  );

  return callApi(
    `capacity_group_memberships?${stringifiedQueryParams}`,
    token,
    fetchInit
  );
};

const createCapacityGroupMembership = (
  token: string,
  { capacityGroupId, teamMembershipIds }: CreateCapacityGroupMembershipParams
) => {
  const body = convertKeysToSnakeCase({ teamMembershipIds });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(
    `capacity_group/${capacityGroupId}/add_members`,
    token,
    fetchInit
  );
};

const updateCapacityGroupMembership = (
  token: string,
  {
    id,
    startDate,
    endDate,
    capacityGroupId
  }: UpdateCapacityGroupMembershipParams
) => {
  const body = convertKeysToSnakeCase({
    startDate,
    endDate,
    capacityGroupId
  });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`capacity_group_membership/${id}`, token, fetchInit);
};

const deleteCapacityGroupMembership = (
  token: string,
  { id }: DeleteCapacityGroupMembershipParams
) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`capacity_group_membership/${id}`, token, fetchInit);
};

export const capacityGroupApi = {
  fetchCapacityPolicies,
  createCapacityPolicy,
  updateCapacityPolicy,
  archiveCapacityPolicy,
  fetchCapacityPoliciesPerMember,
  fetchCapacityGroupMemberships,
  createCapacityGroupMembership,
  updateCapacityGroupMembership,
  deleteCapacityGroupMembership
};
