import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Timeline, {
  DateHeader,
  TimelineHeaders,
  TimelineMarkers,
  SidebarHeader
} from 'react-calendar-timeline';
import moment from 'moment';
import theme from 'theme';
import TaskItemRenderer from './projectTimeline/Items/TaskItemRenderer';
import {
  updateWorkloadPlanner,
  createWorkloadPlanner,
  deleteWorkloadPlanner,
  openProjectPlannerModal,
  updateWorkloadPlannerMembers,
  fetchTeamMemberProfile,
  fetchMemberProjects,
  setVisibleDates,
  openWorkloadModal,
  openWorkloadEventsModal,
  openPlannerBarModal,
  closePlannerBarModal,
  setZoom,
  setCondensedZoomLevel,
  setWorkloadViewType,
  navigateToProject,
  setWorkloadViewBy,
  fetchPhaseTotals,
  fetchPhases,
  fetchPhasesByProjectIds,
  updatePhase,
  openAccessModal,
  openMilestoneModal,
  closeMilestoneModal,
  setSelectedProject,
  fetchProjectById,
  splitWorkloadPlanner,
  openAddMembersForm,
  navigateToWorkload,
  updateAccountFilterTemporarySaveAction,
  setPlannerSplitScreenActive,
  setWorkloadSplitScreenActive,
  setPlannerSplitScreenInactive,
  setWorkloadSplitScreenInactive,
  setSplitScheduleBarId,
  triggerTasksAttributesUpdate,
  fetchTaskGroups,
  navigateToTaskModal,
  setSelectedTask,
  fetchCommentsAndMetadata,
  fetchSuggestions,
  navigateToSchedule,
  navigateToReport,
  openAllWorkloadProjectRows,
  closeAllWorkloadProjectRows,
  addMemberToProject,
  navigateToTeamSettings,
  handleErrorMessage
} from 'actionCreators';
import Popover from 'components/Popover';

import {
  WorkloadPlannerItemRenderer,
  WorkloadPlannerGroupRenderer,
  MilestoneModal,
  AddMembersContainer
} from 'views';
import WorkloadSortOptions from './projectTimeline/Options/WorkloadSortOptions';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import WorkloadPlannerGroupProjectRenderer from './WorkloadPlannerGroupProjectRenderer';
import WorkloadPlannerMilestoneItemRenderer from './WorkloadPlannerMilestoneItemRenderer';
import WorkloadPlannerWorkCategoryItemRenderer from './WorkloadPlannerWorkCategoryItemRenderer';
import { rebuildTooltip, defaultTooltipProps } from 'appUtils/tooltipUtils';
import PlannerRowRenderer from './WorkloadPlannerRowRenderer';
import SplitScreenGroupRenderer from './SplitScreenGroupRenderer';
import TodayMarker from './projectTimeline/Markers/TodayMarker';
import ScheduleBarContextMenu from './ScheduleBarContextMenu';
import Filter from './Filter';
import {
  StyledContextualMenu,
  StyledMenuItem,
  StyledLeftOptions,
  IconContainerContainer,
  PlannerFilterContainer,
  PlannerFilterOption,
  CollapseAllContainer
} from './styles';
import NewGearIcon from 'icons/NewGearIcon';
import SendArrowIcon from 'icons/SendArrowIcon';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { IconContainer } from 'components/GlobalAdd/styles';
import {
  addTimezoneOffsetToDate,
  calcDayChange,
  deserializeBar,
  getVisibleTimeRange,
  getWeeksFromZoom,
  isSameOrBetween,
  keyifyScheduleBarDate,
  getSnappedDate,
  serializeBar,
  zoomToIntervalFormat,
  zoomToIntervalHash,
  zoomToTopIntervalFormat,
  zoomToTopIntervalHash
} from 'appUtils/projectPlannerUtils';
import { LEFT, BOTH } from 'appConstants/projectPlanner';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import {
  getPlannerMemberIds,
  makeGetWorkloadItemIds,
  getPlannerSchedules,
  getHomeTaskObj,
  getMe,
  getProjectPlannerSteps,
  getZoom,
  getTeamSlug,
  getIsOnScheduleView,
  getUserTheme,
  makeGetWorkloadRows,
  makeGetWorkloadSplitScreenRows,
  makeGetWorkloadSplitScreenItems,
  makeGetWorkloadSplitScreenItemIds,
  makeGetRootRowCount,
  makeGetWorkloadItems,
  getAllMilestonesHash,
  getFlatPhasesAndMilestones,
  getSelectedTeamId,
  getSelectedTeamViewSettings,
  getProjectHash,
  getPlannerMembersCount,
  getProjectUtilizations,
  getIsMilestoneModalOpen,
  getOOOProject,
  getWFHProject,
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getPlannerSplitScreenType,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenType,
  getWorkloadSplitScreenAccountId,
  getSelectedProject,
  getInstalledWorkloadModuleIds,
  getMatchedRouteParams,
  getAuthToken,
  getActiveFilterChanged,
  getFlatPhasesHash,
  getActivityPhaseHash,
  getProjectPlannerHoursByDate,
  getWorkloadViewType,
  getCondensedZoomLevel,
  getAllMemberRowsOpen,
  makeGetAllProjectRowsOpen,
  makeGetWorkloadGroupHash,
  getSplitFlags,
  makeGetPlannerProjectMemberAccountIds
} from 'selectors';
import {
  getIsOnPhaseSplitScreen,
  getIsOnScheduledTaskSplitScreen,
  getIsOnWorkplanSplitScreen
} from 'appCore/navigation/selectors';
import {
  getUtilizationsWithSummary,
  makeGetCappedUtilizationsByMember,
  makeGetMemberTimesheetStatusTotalsByDate,
  makeGetUtilizationsBreakdownByMember,
  makeGetSummaryUtilizationsBreakdown
} from 'UtilizationModule/selectors';
import DateNav from '../homePlanner/DateNav';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import {
  getTeamCapacity,
  getAccountCapacitiesWithSummary,
  getProjectCapacities,
  getHolidayDatesHash,
  getAccountCapacitiesSummary,
  getAverageCapacities
} from 'CapacityModule/selectors';
import { fetchTeamCapacity } from 'CapacityModule/actionCreators';
import {
  CAPACITY_TYPES,
  CONDENSED_VIEW_ROW_HEIGHTS,
  CONTEXT_MENU_TYPES,
  FILTER_PAGES,
  ITEM_TYPES,
  SPLIT_SCREEN_TYPES,
  VIEW_BY,
  VIEW_TYPE,
  VIEW_BY_DISPLAY,
  ZOOM_LEVELS,
  ZOOM_TO_SNAP_VALUES,
  ZOOM_TO_STEP_VALUES,
  ZOOM_STRINGS
} from 'appConstants/workload';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

import { buildAccessIdentifier } from 'appUtils/access';
import {
  openBudgetModal,
  fetchMemberBudgets,
  updateMemberBudget,
  updateActivityPhase,
  fetchRates
} from 'BudgetModule/actionCreators';
import { getMemberBudgets } from 'BudgetModule/selectors';
import DeleteModal from '../taskDisplay/taskUtilityComponents/DeleteModal';
import pick from 'lodash/pick';
import SplitScreenDivider from 'TimelinesModule/components/SplitScreen/SplitScreenDivider';
import throttle from 'lodash/throttle';
import TaskSidebarContainer from './projectTimeline/Sidebars/TaskSidebarContainer';
import { isPhaseArchived } from 'appUtils/phaseDisplayUtils';
import CollapseAllIcon from 'icons/CollapseAllIcon';
import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';
import { deserializeId } from 'appUtils';
import NewProjectLink from './NewProjectLink';
import { WorkloadThreeDotMenu } from './projectTimeline/Options/WorkloadThreeDotMenu';
import noop from 'lodash/noop';
import { OpenSideFilterOption } from 'FilterModule/components/SideFilter';
import { FilterContext } from 'FilterModule/FilterContextProvider';
import { getDependencyStatus } from './utils';
import { RoundedOutlinedButton } from 'views/unplanned/UnplannedTable/styles';
import RequestsButton from 'views/unplanned/UnplannedTable/RequestsButton';
import { getUserIsAdmin } from 'PermissionsModule/selectors';
import { GENERIC_ACTION } from 'appConstants';
import {
  EDIT_MILESTONE_DATES_TIP,
  EDIT_PHASE_DATES_TIP,
  EDIT_WORK_CATEGORY_DATES_TIP,
  EDIT_WORK_PLANS_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { editPhaseDates } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';

const emptyObj = {};

const FORWARD = 'forward';
const BACKWARD = 'backward';

const defaultKeys = {
  groupIdKey: 'account_id',
  groupTitleKey: 'id',
  itemIdKey: 'id',
  itemTitleKey: 'title', // key for item div content
  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
  itemGroupKey: 'account_id',
  itemTimeStartKey: 'start_date',
  itemTimeEndKey: 'end_date'
};

const byProjectKeys = {
  groupIdKey: 'project_view_group_key',
  groupTitleKey: 'id',
  itemIdKey: 'id',
  itemTitleKey: 'title', // key for item div content
  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
  itemGroupKey: 'item_project_view_group_key',
  itemTimeStartKey: 'start_date',
  itemTimeEndKey: 'end_date'
};

const byProjectSplitScreenKeys = {
  groupIdKey: 'project_view_group_key',
  groupTitleKey: 'id',
  itemIdKey: 'id',
  itemTitleKey: 'title', // key for item div content
  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
  itemGroupKey: 'item_project_view_split_screen_key',
  itemTimeStartKey: 'start_date',
  itemTimeEndKey: 'end_date'
};

const bindToDay = (date) => (date ? date.format('YYYY-MM-DD') : null);
const defaultTimeStart = getMondayOfWeek(moment());
const defaultTimeEnd = getMondayOfWeek(moment()).add(7, 'day');
const subHeaderLabelFormats = {
  dayLong: 'dd',
  dayMediumLong: 'dd',
  dayMedium: 'dd',
  dayShort: 'dd'
};

const StyledTooltip = styled(ReactTooltip)`
  font-size: 11px;
  font-weight: normal;
  padding: 8px;
  text-align: center;
  z-index: 1000;
  max-width: 240px;
`;

const SidebarBackground = styled.div`
  background: white;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 100px;
  width: 250px;
  box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);
`;

const EmptyGroupCell = styled.div`
  height: 100%;
  border-top: ${({ isLast, isOnScheduleView }) =>
    !isLast && !isOnScheduleView && `1px solid ${theme.colors.colorPaleGray1}`};
  border-bottom: 75px solid ${theme.colors.colorPureWhite};
`;

const clearContextMenuState = {
  contextMenuItemId: null,
  contextMenuTimestamp: null,
  contextMenuX: null,
  contextMenuY: null,
  contextMenuType: null
};

const initialState = {
  ...clearContextMenuState,
  itemTime: null,
  idToSplit: null,
  idToDelete: null,
  deleteModalOpen: false,
  mouseDownX: null,
  mouseDownY: null,
  isOpenAddMembersModal: false,
  showMemberSuggestionsOnOpen: false,
  scrollTimeStart: null,
  scrollTimeEnd: null,
  scrollFrozenCalendar: null,
  dragOffsetY: 0,
  dropOffsetY: 0,
  dragging: false,
  joinModalOpen: false,
  joinProjectAccountId: null,
  joinProjectProject: null,
  joinProjectBar: null
};

class WorkloadPlannerTimelineContainer extends React.PureComponent {
  static contextType = FilterContext;

  constructor(props) {
    super(props);
    this.state = initialState;
    this.groupRenderers = {
      [VIEW_BY.MEMBERS]: this.memberGroupRenderer,
      [VIEW_BY.PROJECTS]: this.projectGroupRenderer
    };
    this.itemMoveHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseMove,
      [ITEM_TYPES.SCHEDULE_BAR]: this.handleScheduleBarMove,
      [ITEM_TYPES.TASK]: this.handleTaskMove,
      [ITEM_TYPES.WORK_CATEGORY]: this.handleWorkCategoryMove
    };
    this.itemResizeHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseResize,
      [ITEM_TYPES.SCHEDULE_BAR]: this.handleScheduleBarResize,
      [ITEM_TYPES.TASK]: this.handleTaskResize,
      [ITEM_TYPES.WORK_CATEGORY]: this.handleWorkCategoryResize
    };
    this.itemClickHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseClick,
      [ITEM_TYPES.SCHEDULE_BAR]: noop, // this.handleScheduleBarClick
      [ITEM_TYPES.TASK]: this.handleTaskClick,
      [ITEM_TYPES.WORK_CATEGORY]: noop
    };
    this.itemContextMenuHandlers = {
      // [ITEM_TYPES.PHASE]: this.handlePhaseContextMenu,
      [ITEM_TYPES.SCHEDULE_BAR]: this.handleScheduleBarContextMenu
    };
    this.itemGetters = {
      [ITEM_TYPES.PHASE]: this.getPhaseByItemId,
      [ITEM_TYPES.SCHEDULE_BAR]: this.getScheduleBarByItemId,
      [ITEM_TYPES.TASK]: this.getTaskByItemId,
      [ITEM_TYPES.WORK_CATEGORY]: this.getWorkCategoryByItemId
    };
  }

  componentDidUpdate(prevProps) {
    rebuildTooltip();
    const {
      setWorkloadViewBy,
      isOnScheduleView,
      activeFilter,
      setCondensedZoomLevel,
      setWorkloadViewType,
      splitScreenProjectId,
      splitScreenAccountId,
      setPlannerSplitScreenActive,
      setPlannerSplitScreenInactive,
      setWorkloadSplitScreenActive,
      setWorkloadSplitScreenInactive,
      workloadSplitScreenType
    } = this.props;
    const newViewBy = isOnScheduleView ? VIEW_BY.PROJECTS : VIEW_BY.MEMBERS;
    if (prevProps.viewBy !== newViewBy) {
      if (newViewBy === VIEW_BY.PROJECTS && splitScreenProjectId) {
        setPlannerSplitScreenActive({
          splitScreenProjectId: splitScreenProjectId,
          plannerSplitScreenType: SPLIT_SCREEN_TYPES.PROJECT
        });
      } else if (newViewBy === VIEW_BY.MEMBERS && splitScreenAccountId) {
        setWorkloadSplitScreenActive({
          splitScreenAccountId: splitScreenAccountId,
          workloadSplitScreenType
        });
      } else {
        if (newViewBy === VIEW_BY.PROJECTS) {
          setPlannerSplitScreenInactive();
        } else if (newViewBy === VIEW_BY.MEMBERS) {
          setWorkloadSplitScreenInactive();
        }
      }
      setWorkloadViewBy({
        viewBy: isOnScheduleView ? VIEW_BY.PROJECTS : VIEW_BY.MEMBERS
      });
    }
    if (prevProps.activeFilter?.custom?.zoom !== activeFilter?.custom?.zoom) {
      const zoom =
        activeFilter?.custom?.zoom || activeFilter?.custom?.zoom === 0
          ? activeFilter.custom.zoom
          : 66;
      this.setZoom(zoom);
    }
    if (
      prevProps.activeFilter?.custom?.condensedZoomLevel !==
      activeFilter?.custom?.condensedZoomLevel
    ) {
      setCondensedZoomLevel({
        condensedZoomLevel: activeFilter?.custom?.condensedZoomLevel
      });
    }
    if (
      prevProps.activeFilter?.custom?.workloadViewType !==
      activeFilter?.custom?.workloadViewType
    ) {
      setWorkloadViewType({
        viewType: activeFilter?.custom?.workloadViewType || VIEW_TYPE.NORMAL
      });
    }
  }

  componentDidMount() {
    rebuildTooltip();
    const {
      matchedParams,
      splitScreenProjectId,
      splitScreenAccountId,
      setWorkloadViewBy,
      match,
      isOnScheduleView,
      activeFilter,
      setCondensedZoomLevel,
      setWorkloadViewType,
      fetchRates,
      teamId,
      setPlannerSplitScreenActive,
      setWorkloadSplitScreenActive
    } = this.props;

    if (activeFilter) {
      const zoom =
        activeFilter?.custom?.zoom || activeFilter?.custom?.zoom === 0
          ? activeFilter.custom.zoom
          : 66;
      this.setZoom(zoom);
      setCondensedZoomLevel({
        condensedZoomLevel: activeFilter?.custom?.condensedZoomLevel
      });
      setWorkloadViewType({
        viewType: activeFilter?.custom?.workloadViewType || VIEW_TYPE.NORMAL
      });
    }

    if (match.params.splitScreenProjectId && !splitScreenProjectId) {
      setPlannerSplitScreenActive({
        splitScreenProjectId: +matchedParams.splitScreenProjectId,
        plannerSplitScreenType: SPLIT_SCREEN_TYPES.PROJECT
      });
    }
    if (
      match.params.splitScreenAccountId &&
      match.params.splitScreenType &&
      !splitScreenAccountId
    ) {
      setWorkloadSplitScreenActive({
        splitScreenAccountId: match.params.splitScreenAccountId,
        workloadSplitScreenType: match.params.splitScreenType
      });
    }
    setWorkloadViewBy({
      viewBy: isOnScheduleView ? VIEW_BY.PROJECTS : VIEW_BY.MEMBERS
    });

    fetchRates({ teamId });
  }

  componentWillUnmount() {
    const { closePlannerBarModal } = this.props;
    closePlannerBarModal();
  }

  handleItemMove = (serializedId, calendarTime, newGroupId) => {
    const {
      workloadGroupHash,
      workplanPermissions: { getCanEditWorkPlan },
      handleErrorMessage
    } = this.props;
    const { itemType, itemId } = deserializeBar(serializedId);

    const canEditWorkplan = getCanEditWorkPlan({
      accountId: this.getScheduleBarByItemId(itemId)?.accountId
    });

    ReactTooltip.hide();
    if (!canEditWorkplan && itemType === ITEM_TYPES.SCHEDULE_BAR) {
      handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: EDIT_WORK_PLANS_TIP
      });
      return;
    }

    const deserializedGroupIdArray = deserializeId(newGroupId);
    const { ids, itemId: deserializedItemId } = deserializedGroupIdArray;
    // two cases either the group id is stand alone in which case we just use item type or its the second part of the whole id after deserialization
    const deserializedGroupId =
      !ids || ids.length === 0
        ? deserializedGroupIdArray.itemType
        : deserializedItemId;
    const newAccountId = workloadGroupHash[deserializedGroupId]?.accountId;
    const newMemberBudgetId =
      workloadGroupHash[deserializedGroupId]?.member_budget_id;

    const handleMove = this.itemMoveHandlers[itemType];
    handleMove(itemId, calendarTime, {
      accountId: newAccountId,
      memberBudgetId: newMemberBudgetId
    });
    this.setState({ itemTime: null });
  };

  handleItemResize = (serializedId, calendarTime, edge) => {
    const {
      workplanPermissions: { getCanResizeWorkPlan },
      handleErrorMessage
    } = this.props;
    const { itemType, itemId } = deserializeBar(serializedId);

    const canResizeWorkplan = getCanResizeWorkPlan({
      accountId: this.getScheduleBarByItemId(itemId)?.accountId
    });

    ReactTooltip.hide();
    if (!canResizeWorkplan && itemType === ITEM_TYPES.SCHEDULE_BAR) {
      handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: EDIT_WORK_PLANS_TIP
      });
      return;
    }

    const handleResize = this.itemResizeHandlers[itemType];
    handleResize(itemId, calendarTime, edge);
    this.setState({ itemTime: null });
  };

  handleItemClick = (serializedId, e, time) => {
    const adjustedTime = addTimezoneOffsetToDate(time);
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleClick = this.itemClickHandlers[itemType];
    handleClick(itemId, e, adjustedTime);
  };

  handleItemDrag = (itemDragObject) => {
    const {
      workplanPermissions: { getCanDragWorkPlan },
      handleErrorMessage
    } = this.props;
    const { itemType, itemId } = deserializeBar(itemDragObject.itemId);

    const canDragWorkplan = getCanDragWorkPlan({
      accountId: this.getScheduleBarByItemId(itemId)?.accountId
    });

    if (!canDragWorkplan && itemType === ITEM_TYPES.SCHEDULE_BAR) {
      handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: EDIT_WORK_PLANS_TIP
      });
      return;
    }

    this.setState({ itemTime: itemDragObject.time });
    ReactTooltip.show(this.startDateRef);
    ReactTooltip.show(this.endDateRef);
  };

  onItemContextMenu = (serializedId, e, time) => {
    const adjustedTime = addTimezoneOffsetToDate(time);
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleContextMenu = this.itemContextMenuHandlers[itemType];
    if (handleContextMenu) {
      handleContextMenu(itemId, e, adjustedTime);
    }
  };

  getPermissions = () => {
    const { teamId } = this.props;
    return {
      teamId
    };
  };

  getPhaseByItemId = (itemId) =>
    this.props.allPhases.find((p) => p.id === +itemId);

  getScheduleBarByItemId = (itemId) => this.props.scheduleBars[itemId];
  getTaskByItemId = (itemId) => this.props.taskHash[itemId];
  getWorkCategoryByItemId = (itemId) => this.props.activityPhaseHash[itemId];

  handleOpenPhaseModal = (projectId, e) => {
    const {
      fetchPhases,
      fetchPhasesByProjectIds,
      openMilestoneModal,
      setSelectedProject,
      fetchProjectById
    } = this.props;
    fetchProjectById(projectId);
    setSelectedProject({ projectId });
    openMilestoneModal();
    fetchPhases({ projectId });
    fetchPhasesByProjectIds({ projectIds: [projectId] });
    if (e) {
      e.stopPropagation();
    }
  };

  getGroupRenderer = () => this.groupRenderers[this.props.workloadViewBy];

  // schedule bar handlers
  handleScheduleBarMove = (itemId, calendarTime, newGroupId) => {
    const {
      updateWorkloadPlanner,
      projectHash,
      workloadGroupHash,
      viewBy,
      plannerSplitScreenActive,
      isOnWorkplanSplitScreen,
      memberBudgets
    } = this.props;

    const { accountId: newAccountId, memberBudgetId: newMemberBudgetId } =
      newGroupId;

    const oldBar = this.getScheduleBarByItemId(itemId);

    const projectChanged =
      oldBar.project_id !== workloadGroupHash[newMemberBudgetId]?.projectId &&
      !isOnWorkplanSplitScreen;
    // disallow dragging to a different project on planner
    const preventMove =
      viewBy === VIEW_BY.PROJECTS &&
      !plannerSplitScreenActive &&
      projectChanged;

    const diffInDays = calcDayChange(calendarTime, oldBar.start_date);
    const newStartDate = keyifyScheduleBarDate(
      moment(oldBar.start_date).add(diffInDays, 'days')
    );
    const newEndDate = keyifyScheduleBarDate(
      moment(oldBar.end_date).add(diffInDays, 'days')
    );

    const barProject = projectHash[oldBar.project_id];
    let createMemberBudget = false;
    // if we are on workplan split screen, check if the drag is valid if changing accounts
    if (!oldBar.account_id && newAccountId && isOnWorkplanSplitScreen) {
      const newMemberBudget = Object.values(memberBudgets).find(
        (memberBudget) =>
          memberBudget.project_id === barProject.id &&
          memberBudget.account_id === newAccountId
      );
      // if theres not a newMemberBudet for the account/project combination, we have to prompt for the account  to join.
      if (!newMemberBudget) {
        createMemberBudget = true;
      }
    }

    const updatedOldBar = {
      ...oldBar,
      start_date: newStartDate,
      end_date: newEndDate,
      account_id: preventMove ? undefined : newAccountId ?? oldBar.account_id,
      member_budget_id:
        preventMove || plannerSplitScreenActive
          ? undefined
          : newMemberBudgetId ?? oldBar.member_budget_id
    };

    if (
      (!preventMove &&
        newGroupId &&
        barProject &&
        barProject.member_account_ids &&
        barProject.member_account_ids.every(
          (memberAccountId) => memberAccountId != newAccountId
        ) &&
        projectChanged) ||
      createMemberBudget
    ) {
      this.setState({
        joinModalOpen: true,
        joinProjectAccountId: newAccountId,
        joinProjectProject: barProject,
        joinProjectBar: updatedOldBar
      });
    } else if (
      !preventMove &&
      (newAccountId || newMemberBudgetId || isOnWorkplanSplitScreen)
    ) {
      updateWorkloadPlanner(updatedOldBar);
    }
  };

  handleScheduleBarResize = (itemId, calendarTime, edge) => {
    const { updateWorkloadPlanner } = this.props;
    const oldBar = this.getScheduleBarByItemId(itemId);

    const propertyToUpdate = edge === LEFT ? 'start_date' : 'end_date';
    const buffer = edge === LEFT ? 1 : -1;

    const updatedOldBar = {
      ...oldBar,
      daily_hours: oldBar.lock_hour ? undefined : oldBar.daily_hours,
      total_hours: oldBar.lock_hour ? oldBar.total_hours : undefined,
      [propertyToUpdate]: keyifyScheduleBarDate(
        moment(calendarTime).add(buffer, 'minute')
      )
    };
    if (
      moment(updatedOldBar.end_date).isBefore(moment(updatedOldBar.start_date))
    ) {
      updatedOldBar.end_date = updatedOldBar.start_date;
    }
    updateWorkloadPlanner(updatedOldBar);
  };

  handleTaskMove = (itemId, calendarTime) => {
    // dont handle moving across groups
    const { triggerTasksAttributesUpdate, token } = this.props;
    const task = this.getTaskByItemId(itemId);
    const diffInDays = calcDayChange(
      bindToDay(moment(calendarTime)),
      task.schedule_start
    );
    const newStartDate = moment(task.schedule_start).add(diffInDays, 'days');

    const newEndDate = moment(
      task.schedule_end || moment(task.schedule_start).add(2, 'days')
    ).add(diffInDays, 'days');

    const permissions = this.getPermissions();
    const body = {
      task_ids: [task.id],
      schedule_start: newStartDate,
      schedule_end: newEndDate
    };

    triggerTasksAttributesUpdate({ token, body, permissions });
  };

  handleTaskResize = (itemId, calendarTime, edge) => {
    const { triggerTasksAttributesUpdate, token } = this.props;
    const task = this.getTaskByItemId(itemId);
    const propertyToUpdate = edge === LEFT ? 'schedule_start' : 'schedule_end';
    const propertyToPreserve =
      edge === LEFT ? 'schedule_end' : 'schedule_start';
    const buffer = edge === LEFT ? 1 : -1;
    const body = {
      task_ids: [task.id],
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]: task[propertyToPreserve]
    };
    if (moment(body.schedule_end).isBefore(moment(body.schedule_start))) {
      return;
    }

    const permissions = this.getPermissions();
    triggerTasksAttributesUpdate({
      token,
      body,
      permissions
    });
  };

  handleWorkCategoryResize = (itemId, calendarTime, edge) => {
    const { updateActivityPhase } = this.props;
    const activityPhase = this.getWorkCategoryByItemId(itemId);

    if (
      !this.checkEditPhaseDatesPermission({
        projectId: activityPhase.project_id,
        errorMessage: EDIT_WORK_CATEGORY_DATES_TIP
      })
    )
      return;

    const propertyToUpdate = edge === LEFT ? 'startDate' : 'endDate';
    const propertyToPreserve = edge === LEFT ? 'endDate' : 'startDate';
    const buffer = edge === LEFT ? 1 : -1;
    const body = {
      id: activityPhase.id,
      projectId: activityPhase.project_id,
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]:
        activityPhase[
          propertyToPreserve === 'startDate' ? 'start_date' : 'end_date'
        ]
    };
    if (moment(body.endDate).isBefore(moment(body.startDate))) {
      return;
    }

    updateActivityPhase({
      id: activityPhase.id,
      projectId: activityPhase.project_id,
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]: activityPhase[propertyToPreserve]
    });
  };

  handleWorkCategoryMove = (itemId, calendarTime) => {
    const { updateActivityPhase } = this.props;
    const activityPhase = this.getWorkCategoryByItemId(itemId);

    if (
      !this.checkEditPhaseDatesPermission({
        projectId: activityPhase.project_id,
        errorMessage: EDIT_WORK_CATEGORY_DATES_TIP
      })
    )
      return;

    const diffInDays = calcDayChange(
      bindToDay(moment(calendarTime)),
      activityPhase.start_date
    );
    const newStartDate = moment(activityPhase.start_date).add(
      diffInDays,
      'days'
    );

    const newEndDate = moment(
      activityPhase.end_date || moment(activityPhase.start_date).add(2, 'days')
    ).add(diffInDays, 'days');

    updateActivityPhase({
      id: activityPhase.id,
      projectId: activityPhase.project_id,
      startDate: moment(newStartDate).format('YYYY-MM-DD'),
      endDate: moment(newEndDate).format('YYYY-MM-DD')
    });
  };

  isBarClick = (bar, time) =>
    bar?.bars.some(
      (duration) =>
        duration.day_count !== 0 &&
        isSameOrBetween(time, duration.start_date, duration.end_date)
    );

  handleScheduleBarClick = (itemId, e, time) => {
    // let onItemContextMenu handle right click
    if (e.which === 3 || e.ctrlKey) {
      return;
    }
    if (this.state.idToSplit) {
      return;
    }
    /*
      fires for onItemSelect and onItemClick
      library fires these first and second clicks of a bar
      onItemSelect is more accurately onFirstClick (ignores 'selected' prop)
    */

    // time is moment instance
    const date = time.format('YYYY-MM-DD');

    // Get the bar used for the timeline.
    const { plannerItems } = this.props;
    const bar = plannerItems.find(({ id }) => id === `scheduleBar--${itemId}`);
    if (!bar) return;

    const accountId = bar.account_id;

    const isBar = this.isBarClick(bar, date);
    this.openBarModal({
      isNew: !isBar,
      barId: isBar ? itemId : null,
      accountId,
      date
    });
  };

  handleScheduleBarContextMenu = (itemId, e, time) => {
    e.stopPropagation();

    // Get the bar used for the timeline.
    const { plannerItems } = this.props;
    const bar = plannerItems.find(({ id }) => id === `scheduleBar--${itemId}`);

    // Open a context menu if the click was in a bar; otherwise, close the
    // opened context menus.
    this.setState(
      bar && this.isBarClick(bar, time)
        ? {
            contextMenuItemId: itemId,
            contextMenuTimestamp: time,
            contextMenuX: e.clientX,
            contextMenuY: e.clientY,
            contextMenuType: CONTEXT_MENU_TYPES.SCHEDULE_BAR
          }
        : {
            contextMenuItemId: null,
            contextMenuTimestamp: null,
            contextMenuX: null,
            contextMenuY: null,
            contextMenuType: null
          }
    );
  };

  handleScheduleBarCopy = (itemId) => {
    const bar = this.getScheduleBarByItemId(itemId);
    if (!bar) {
      return;
    }
    const accountId = bar.account_id;

    this.openBarModal({
      isNew: true,
      barId: itemId,
      accountId,
      suggestedBar: bar
    });
    this.setState(clearContextMenuState);
  };

  setSplitScheduleBar = (itemId) => {
    const { setSplitScheduleBarId } = this.props;
    setSplitScheduleBarId({ itemId });
    this.setState({ idToSplit: itemId, ...clearContextMenuState });
  };

  handleScheduleBarSplit = (itemId, splitTime) => {
    const bar = this.getScheduleBarByItemId(itemId);
    if (!bar) {
      return;
    }
    const time = splitTime || this.state.contextMenuTimestamp;
    const date = moment(time).format('YYYY-MM-DD');
    this.props.splitWorkloadPlanner({
      id: itemId,
      split_date: date,
      account_id: bar.account_id
    });
    this.setState({ ...clearContextMenuState, idToSplit: null });
    this.props.setSplitScheduleBarId({ itemId: null });
  };

  // only use in onMouseDown to block clicks that are far enough away from mouse down to be considered drag attempts
  trackClickStart = (e) => {
    this.setState({
      mouseDownX: e.clientX,
      mouseDownY: e.clientY,
      idToSplit: null
    });
    this.props.setSplitScheduleBarId({ itemId: null });
  };

  resetClickStart = () => this.setState({ mouseDownX: null, mouseDownY: null });

  isDragAttempt = (e) => {
    const { mouseDownX, mouseDownY } = this.state;
    const { clientX, clientY } = e;
    const isDragAttempt =
      mouseDownX &&
      mouseDownY &&
      (Math.abs(mouseDownX - clientX) > 5 ||
        Math.abs(mouseDownY - clientY) > 5);

    return isDragAttempt;
  };

  checkEditPhaseDatesPermission = ({ projectId, errorMessage }) => {
    const {
      checkPermission,
      editPhaseDates,
      teamId,
      handleErrorMessage,
      updatePhase
    } = this.props;

    const canEditPhase = checkPermission(updatePhase, {
      permissions: {
        projectId,
        teamId
      }
    });

    const canEditPhaseDates =
      canEditPhase ||
      checkPermission(editPhaseDates, {
        permissions: {
          projectId,
          teamId
        }
      });

    if (!canEditPhaseDates) {
      handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage
      });
    }
    return canEditPhaseDates;
  };

  // phase/milestone handlers
  handlePhaseMove = (itemId, calendarTime) => {
    const { updatePhase } = this.props;
    const phase = this.getPhaseByItemId(itemId);

    if (
      !phase ||
      isPhaseArchived(phase) ||
      !this.checkEditPhaseDatesPermission({
        projectId: phase.project_id,
        errorMessage: !phase.is_budget
          ? EDIT_MILESTONE_DATES_TIP
          : EDIT_PHASE_DATES_TIP
      })
    ) {
      return;
    }

    const diffInDays = calcDayChange(calendarTime, phase.start_date);
    const newStartDate = moment(phase.start_date)
      .add(diffInDays, 'days')
      .format('MM/DD/YYYY');

    const newEndDate = moment(phase.end_date)
      .add(diffInDays, 'days')
      .format('MM/DD/YYYY');

    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      startDate: newStartDate,
      endDate: newEndDate,
      name: phase.name
    });
  };

  handlePhaseResize = (itemId, calendarTime, edge) => {
    const { updatePhase } = this.props;
    const phase = this.getPhaseByItemId(itemId);
    if (
      !phase ||
      isPhaseArchived(phase) ||
      !this.checkEditPhaseDatesPermission({
        projectId: phase.project_id,
        errorMessage: !phase.is_budget
          ? EDIT_MILESTONE_DATES_TIP
          : EDIT_PHASE_DATES_TIP
      })
    ) {
      return;
    }

    const propertyToUpdate = edge === LEFT ? 'startDate' : 'endDate';
    const buffer = edge === LEFT ? 1 : -1;

    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      startDate: phase.start_date,
      endDate: phase.end_date,
      name: phase.name,
      [propertyToUpdate]: moment(calendarTime)
        .add(buffer, 'minute')
        .format('MM/DD/YYYY')
    });
  };

  handlePhaseClick = (itemId, e) => {
    if (this.isDragAttempt(e)) {
      this.resetClickStart();
      return;
    }
    const phase = this.getPhaseByItemId(itemId);
    const projectId = phase?.project_id;
    if (projectId) {
      this.handleOpenPhaseModal(projectId);
    }
  };

  handleTaskClick = (itemId) => {
    const {
      fetchTaskGroups,
      navigateToTaskModal,
      setSelectedTask,
      fetchCommentsAndMetadata
    } = this.props;
    const task = this.getTaskByItemId(itemId);
    fetchTaskGroups({ taskGroupIds: [task.task_group_id] });
    navigateToTaskModal({
      taskId: task.id
    });
    setSelectedTask(task.id);
    fetchCommentsAndMetadata({
      taskId: task.id,
      taskType: 'projects',
      offset: 0,
      limit: 4
    });
  };

  handleOpenProjectBudgetModal = (projectId) => {
    const {
      openBudgetModal,
      fetchPhaseTotals,
      fetchPhases,
      fetchMemberBudgets,
      checkPermission,
      teamId,
      fetchPhasesByProjectIds
    } = this.props;
    const permissions = {
      projectId,
      teamId
    };
    const hasPermission = checkPermission(openBudgetModal, { permissions });

    if (hasPermission) {
      openBudgetModal({ id: projectId });
      fetchPhaseTotals({ projectId });
      fetchPhases({ projectId });
      fetchMemberBudgets({ projectId });
      fetchPhasesByProjectIds({ projectIds: [projectId] });
    }
  };

  // timeline renderers
  memberGroupRenderer = ({ group }) => {
    const {
      me,
      rootRowCount,
      zoom,
      viewBy,
      condensedZoomLevel,
      workloadViewType,
      plannerRows,
      cappedUtilizationsByMember,
      allMemberRowsOpen,
      workloadSettings,
      isOnScheduleView,
      workloadSplitScreenType,
      showExpandAllFlag,
      teamRates
    } = this.props;
    if (group.isEmptyBottomRow) {
      return (
        <EmptyGroupCell
          isLast={group.isLast}
          isOnScheduleView={isOnScheduleView}
        />
      );
    } else if (!group.shouldRenderRow) {
      return null;
    } else if (group.isSplitScreen) {
      return (
        <SplitScreenGroupRenderer
          group={group}
          splitScreenType={workloadSplitScreenType}
          condensedZoomLevel={condensedZoomLevel}
          workloadViewType={workloadViewType}
          deactivateSplitScreen={this.deactivateWorkloadSplitScreen}
        />
      );
    }
    return (
      <WorkloadPlannerGroupRenderer
        group={group}
        plannerRows={plannerRows}
        key={group.account_id}
        currentUserId={me ? me.id : null}
        rootRowCount={rootRowCount}
        openBarModal={this.openBarModal}
        activateWorkplanSplitScreen={this.activateWorkplanSplitScreen}
        activateTaskSplitScreen={this.activateTaskSplitScreen}
        activatePhaseSplitScreen={this.activatePhaseSplitScreen}
        zoom={zoom}
        isMemberView={viewBy === VIEW_BY.MEMBERS}
        condensedZoomLevel={condensedZoomLevel}
        workloadViewType={workloadViewType}
        cappedUtilizationsByMember={cappedUtilizationsByMember}
        allMemberRowsOpen={allMemberRowsOpen}
        showCapacityHeatmap={
          (workloadSettings && !!workloadSettings.show_capacity_heat_map) ||
          viewBy === VIEW_BY.PROJECTS ||
          workloadSettings?.show_capacity_heat_map === undefined
        }
        deactivateSplitScreen={this.deactivateWorkloadSplitScreen}
        showExpandAll={showExpandAllFlag}
        teamRates={teamRates}
      />
    );
  };

  projectGroupRenderer = ({ group }) => {
    const {
      me,
      plannerSplitScreenActive,
      condensedZoomLevel,
      workloadViewType,
      plannerRows,
      activeFilter,
      isOnScheduleView,
      plannerSplitScreenType,
      handleOpenFindPeopleModal,
      projectRates
    } = this.props;
    if (group.isEmptyBottomRow) {
      return (
        <EmptyGroupCell
          isLast={group.isLast}
          isOnScheduleView={isOnScheduleView}
        />
      );
    } else if (group.isNewProjectLink) {
      return <NewProjectLink activeFilter={activeFilter} />;
    } else if (!group.shouldRenderRow) {
      return null;
    } else if (group.account_id) {
      // This is the case in both non split screen when we open a project row + non root split screen rows
      return (
        <WorkloadPlannerGroupRenderer
          group={group}
          plannerRows={plannerRows}
          key={group.account_id}
          currentUserId={me ? me.id : null}
          // rootRowCount={rootRowCount}
          openBarModal={this.openBarModal}
          condensedZoomLevel={condensedZoomLevel}
          workloadViewType={workloadViewType}
          showCapacityHeatmap={true}
          handleOpenAddMembersForm={this.handleOpenAddMembersForm}
          handleOpenFindPeopleModal={handleOpenFindPeopleModal}
          setShowMemberSuggestionsOnOpen={this.setShowMemberSuggestionsOnOpen}
          projectRates={projectRates}
        />
      );
    } else if (group.isSplitScreen) {
      // Should only for the root
      return (
        <SplitScreenGroupRenderer
          group={group}
          splitScreenType={plannerSplitScreenType}
          condensedZoomLevel={condensedZoomLevel}
          workloadViewType={workloadViewType}
          deactivateSplitScreen={this.deactivatePlannerSplitScreen}
        />
      );
    }
    return (
      <WorkloadPlannerGroupProjectRenderer
        group={group}
        key={group.id}
        viewButtonClick={this.handleOpenProjectBudgetModal}
        viewPhasesClick={this.handleOpenPhaseModal}
        isSplitScreenActive={plannerSplitScreenActive}
        deactivateSplitScreen={this.deactivatePlannerSplitScreen}
        activateSplitScreen={this.activatePlannerSplitScreen}
        condensedZoomLevel={condensedZoomLevel}
        workloadViewType={workloadViewType}
        handleOpenAddMembersForm={this.handleOpenAddMembersForm}
        handleOpenFindPeopleModal={handleOpenFindPeopleModal}
        setShowMemberSuggestionsOnOpen={this.setShowMemberSuggestionsOnOpen}
      />
    );
  };

  /*
    - itemRenderer cannot be determined by props.viewBy because multiple items types exist per view
    - item is only guaranteed to rerender if input parameters here change. It is not aware of prop changes to WorkloadPlannerTimelineContainer due to react-calendar-timeline performance strategies that ignore updates.
    */
  itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
    isSplitScreenItem
  }) => {
    const { itemTime, idToSplit } = this.state;
    const {
      viewSettings,
      workloadSettings,
      zoom,
      userTheme,
      workloadViewBy,
      me,
      condensedZoomLevel,
      workloadViewType,
      visibleTimeStart,
      visibleTimeEnd,
      isOnPhaseSplitScreen
    } = this.props;
    if (item.is_phase) {
      const dependencyStatus = item ? getDependencyStatus(item) : {};

      return (
        <WorkloadPlannerMilestoneItemRenderer
          item={item}
          zoom={zoom}
          timelineContext={timelineContext}
          itemContext={itemContext}
          getItemProps={getItemProps}
          getResizeProps={getResizeProps}
          userTheme={userTheme}
          time={itemTime}
          startDateRef={(ref) => (this.startDateRef = ref)}
          endDateRef={(ref) => (this.endDateRef = ref)}
          showBudget
          isCondensedView={workloadViewType === VIEW_TYPE.CONDENSED}
          condensedZoomLevel={condensedZoomLevel}
          showProjectTitle={isOnPhaseSplitScreen}
          {...dependencyStatus}
        />
      );
    }
    if (item.is_work_category) {
      return (
        <WorkloadPlannerWorkCategoryItemRenderer
          item={item}
          zoom={zoom}
          timelineContext={timelineContext}
          itemContext={itemContext}
          getItemProps={getItemProps}
          getResizeProps={getResizeProps}
          userTheme={userTheme}
          time={itemTime}
          startDateRef={(ref) => (this.startDateRef = ref)}
          endDateRef={(ref) => (this.endDateRef = ref)}
          showBudget
        />
      );
    }
    if (item.is_task) {
      const props = {
        item,
        timelineContext,
        itemContext,
        getItemProps,
        getResizeProps
      };
      return (
        <TaskItemRenderer
          {...props}
          me={me || emptyObj}
          item={item}
          zoom={zoom}
          timelineContext={timelineContext}
          itemContext={itemContext}
          getItemProps={getItemProps}
          getResizeProps={getResizeProps}
          userTheme={userTheme}
          time={itemTime}
          startDateRef={(ref) => (this.startDateRef = ref)}
          endDateRef={(ref) => (this.endDateRef = ref)}
          isCondensedView={workloadViewType === VIEW_TYPE.CONDENSED}
        />
      );
    }

    const dependencyStatus = item ? getDependencyStatus(item) : {};

    return (
      <WorkloadPlannerItemRenderer
        item={item}
        zoom={zoom}
        timelineContext={timelineContext}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        userTheme={userTheme}
        viewBy={workloadViewBy}
        time={itemTime}
        startDateRef={(ref) => (this.startDateRef = ref)}
        endDateRef={(ref) => (this.endDateRef = ref)}
        showHours={!!viewSettings.show_hours}
        handleScheduleBarClick={this.handleScheduleBarClick}
        handleScheduleBarSplit={this.handleScheduleBarSplit}
        idToSplit={idToSplit}
        isSplitting={idToSplit && idToSplit == deserializeBar(item.id)?.itemId}
        handleScheduleBarCopy={this.handleScheduleBarCopy}
        activateTaskSplitScreen={this.activateTaskSplitScreen}
        setSplitScheduleBar={this.setSplitScheduleBar}
        openDeleteModal={this.openDeleteModal}
        isSplitScreenItem={isSplitScreenItem}
        isHeatmapColored={workloadSettings?.capacity_heat_map_color}
        isCondensedView={workloadViewType === VIEW_TYPE.CONDENSED}
        condensedZoomLevel={condensedZoomLevel}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        {...dependencyStatus}
      />
    );
  };

  splitScreenItemRenderer = (props) =>
    this.itemRenderer({ ...props, isSplitScreenItem: true });

  handleWFHContextMenu = (itemId, e, time) => {
    e.stopPropagation();
    this.setState({
      contextMenuItemId: itemId,
      contextMenuTimestamp: time,
      contextMenuX: e.clientX,
      contextMenuY: e.clientY,
      contextMenuType: CONTEXT_MENU_TYPES.WFH
    });
  };

  handleTimeEntryContextMenu = (itemId, e, time) => {
    e.stopPropagation();
    this.setState({
      contextMenuItemId: itemId,
      contextMenuTimestamp: time,
      contextMenuX: e.clientX,
      contextMenuY: e.clientY,
      contextMenuType: CONTEXT_MENU_TYPES.TIME_ENTRY
    });
  };

  rowRenderer = ({ getLayerRootProps, group, rowData, isSplitScreenRow }) => {
    const { contextMenuItemId, contextMenuType, contextMenuTimestamp } =
      this.state;
    const {
      steps,
      teamCapacity,
      zoom,
      accountCapacities,
      projectCapacities,
      workloadViewBy,
      holidayDatesHash,
      utilizationsBreakdown,
      summaryUtilizationsBreakdown,
      cappedUtilizationsByMember,
      phaseHash,
      timeEntryTotals,
      projectTotals,
      condensedZoomLevel,
      workloadViewType,
      workloadSettings,
      isOnScheduledTaskSplitScreen,
      workplanPermissions: { getCanCreateWorkPlan }
    } = this.props;

    const accountId = group.isTaskSplitRow
      ? group.account_id
      : group && group.account && group.account.id;

    const canCreateWorkPlan = getCanCreateWorkPlan({
      accountId
    });
    const projectId =
      group && group.isSummary && group.id && group.id.split('--')[1];
    const isMemberSplitScreenRow = group.isSplitScreen && !group.isSummary;
    const useMemberValues =
      workloadViewBy === VIEW_BY.MEMBERS ||
      isMemberSplitScreenRow ||
      (workloadViewBy === VIEW_BY.PROJECTS && !group.isRoot);
    const idToUse = useMemberValues ? accountId : projectId || group.project_id;
    const capacityToUse = useMemberValues
      ? accountCapacities
      : projectCapacities;
    const capacity = capacityToUse?.[idToUse] ?? teamCapacity;
    const WFHContextMenuId =
      contextMenuType === CONTEXT_MENU_TYPES.WFH ? contextMenuItemId : null;
    const WFHContextMenuStep =
      contextMenuType === CONTEXT_MENU_TYPES.WFH ? contextMenuTimestamp : null;
    const phasesLength = group?.phases?.length;
    const phaseToEvaluate = phaseHash[group?.phases?.[0]];
    const isPhaseDefault =
      phasesLength === 1 &&
      phaseToEvaluate?.is_default_phase &&
      !phaseToEvaluate?.is_like_default;
    return (
      <PlannerRowRenderer
        useMemberValues={useMemberValues}
        timeEntryTotals={timeEntryTotals[idToUse]}
        showTimeEntryTotals={!!workloadSettings?.show_timesheet_time}
        projectTotals={projectTotals[idToUse]}
        utilizations={rowData[idToUse] || emptyObj}
        unCappedUtilizationsByMember={rowData}
        cappedUtilizationsByMember={cappedUtilizationsByMember}
        capacity={capacity}
        steps={steps}
        getLayerRootProps={getLayerRootProps}
        group={group}
        zoom={zoom}
        onCanvasClick={this.onCanvasClick}
        onRootCanvasClick={this.onRootCanvasClick}
        keys={this.getKeys()}
        workloadViewBy={workloadViewBy}
        rowActionText={''}
        holidayDatesHash={holidayDatesHash}
        utilizationsBreakdown={utilizationsBreakdown}
        summaryUtilizationsBreakdown={summaryUtilizationsBreakdown}
        phasesLength={phasesLength}
        isPhaseDefault={isPhaseDefault}
        shouldHighlightUpcoming={
          /*! !viewSettings.highlight_upcoming */ !!workloadSettings?.capacity_heat_map_color
        } // Off by default
        handleCapacityContextMenu={this.handleCapacityContextMenu}
        handleWFHContextMenu={this.handleWFHContextMenu}
        handleTimeEntryContextMenu={this.handleTimeEntryContextMenu}
        WFHContextMenuId={WFHContextMenuId}
        WFHContextMenuStep={WFHContextMenuStep}
        isMemberSplitScreenRow={isMemberSplitScreenRow}
        isSplitScreenRow={isSplitScreenRow}
        workloadViewType={workloadViewType}
        lineHeight={
          workloadViewType === VIEW_TYPE.NORMAL
            ? 74
            : CONDENSED_VIEW_ROW_HEIGHTS[condensedZoomLevel]
        }
        condensedZoomLevel={condensedZoomLevel}
        hasDroppableLayer={isOnScheduledTaskSplitScreen && isSplitScreenRow}
        viewButtonClick={this.handleOpenProjectBudgetModal}
        canCreateWorkPlan={canCreateWorkPlan}
      />
    );
  };

  splitScreenRowRenderer = (props) =>
    this.rowRenderer({ ...props, isSplitScreenRow: true });

  topIntervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const { interval } = intervalContext;
    const { zoom } = this.props;
    const formatInterval = zoomToTopIntervalFormat[zoom];
    return (
      <div {...getIntervalProps()} onClick={noop} className="top-interval">
        <div className="find-me" />
        <div
          className={cn('styled-header-date-container', {
            showBorder: zoom === ZOOM_LEVELS.DAY
          })}
        >
          {formatInterval(interval.startTime)}
        </div>
      </div>
    );
  };

  /**
   * required for fixing scroll bug in split screen. see handleSplitScreenTimelineScroll
   */
  emptyIntervalRenderer = ({ getIntervalProps }) => {
    return <div {...getIntervalProps()} onClick={noop} />;
  };

  checkToday = (date) => {
    const { zoom } = this.props;
    const stepValue = zoomToIntervalHash[zoom];
    return moment(date).range(stepValue).contains(moment());
  };

  intervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const { interval } = intervalContext;
    const { zoom } = this.props;
    const formatInterval = zoomToIntervalFormat[zoom];
    const stepValue = zoomToIntervalHash[zoom];

    return (
      <div {...getIntervalProps()} onClick={noop}>
        <div className="find-me" />
        <div
          className={cn('styled-header-day-container', {
            today: this.checkToday(interval.startTime),
            left: zoom === ZOOM_LEVELS.DAY || zoom === ZOOM_LEVELS.WEEK,
            singleDay: stepValue === ZOOM_STRINGS.DAY
          })}
        >
          {formatInterval(interval.startTime)}
        </div>
      </div>
    );
  };

  handleExpandCollapseAll = (e) => {
    const {
      openAllWorkloadProjectRows,
      closeAllWorkloadProjectRows,
      allProjectRowsOpen,
      plannerRows
    } = this.props;

    if (!allProjectRowsOpen) {
      const plannerRowProjectIds = plannerRows
        .filter((row) => !!row.projectId)
        .map((row) => row.projectId);
      openAllWorkloadProjectRows({
        projectIds: plannerRowProjectIds
      });
    } else {
      closeAllWorkloadProjectRows();
    }
    e.stopPropagation();
  };

  getWidgetConfig = () => {
    const { workloadSelectionLimitFlag } = this.props;
    if (workloadSelectionLimitFlag) {
      return {
        limits: {
          account_ids: 50,
          project_ids: 50
        }
      };
    }
    return {};
  };

  renderLeftSidebarHeader = ({
    getRootProps,
    data: { allProjectRowsOpen }
  }) => {
    const {
      workloadViewBy,
      pageName,
      viewBy,
      zoom,
      crossFieldFilterOnPlannerFlag,
      crossFilterOnWorkloadFlag,
      showExpandAllFlag,
      pageViewFilter
    } = this.props;

    const { currentFilter, draftFilter, currentFilterSchema } =
      this.context || {};

    return (
      <div {...getRootProps()}>
        {/* For Workload - SideFilter is in WorkloadPlannerContainerContainer  */}
        {workloadViewBy === VIEW_BY.PROJECTS || !crossFilterOnWorkloadFlag ? (
          <div>
            <Filter
              innerHeightAdjustment={
                workloadViewBy === VIEW_BY.MEMBERS ? 250 : undefined
              }
              viewBy={workloadViewBy}
              pageName={FILTER_PAGES.WORKLOAD_PLANNER}
              filterListId="workload"
              filterWidth={300}
              listWidth={300}
              crossFieldDependencies={
                crossFieldFilterOnPlannerFlag &&
                workloadViewBy === VIEW_BY.PROJECTS
                  ? currentFilter?.stackedFilterOrder
                  : undefined
              }
              widgetConfig={this.getWidgetConfig()}
              draftFilter={draftFilter}
              currentFilterSchema={currentFilterSchema}
              isSideFilter
              pageViewFilter={pageViewFilter}
            />
          </div>
        ) : (
          <RelativeContainer>
            <OpenSideFilterOption />
            <RequestsButtonContainer>
              <RequestsButton />
            </RequestsButtonContainer>
          </RelativeContainer>
        )}

        <div className="left-sidebar-toggle-container">
          <IconContainerContainer>
            {workloadViewBy === VIEW_BY.PROJECTS && showExpandAllFlag && (
              <IconContainer onClick={(e) => this.handleExpandCollapseAll(e)}>
                &#x200b;
                <CollapseAllContainer
                  isCollapsed={!allProjectRowsOpen}
                  className="noMargins"
                >
                  <CollapseAllIcon fill={theme.colors.colorRoyalBlue} />
                </CollapseAllContainer>
              </IconContainer>
            )}
            {workloadViewBy === VIEW_BY.MEMBERS && (
              <IconContainer
                onClick={this.onEmailClick}
                className="app-cues-email-icon"
              >
                <SendArrowIcon
                  width={16}
                  height={13}
                  color={theme.colors.colorRoyalBlue}
                  strokeWidth={0.7}
                  style={{ marginTop: 2 }}
                />
                <span>Send</span>
              </IconContainer>
            )}

            <IconContainer
              onClick={this.onSettingsClick}
              className="app-cues-settings-icon"
              data-testid="settings-button"
            >
              <NewGearIcon color={theme.colors.colorRoyalBlue} />
              <span style={{ marginLeft: '2px' }}>Settings</span>
            </IconContainer>
            <div className="app-cues-settings-icon">
              {/* <GearIcon color={theme.colors.colorRoyalBlue} /> */}
              <WorkloadSortOptions
                viewBy={viewBy}
                pageName={pageName}
                zoom={zoom}
                workloadViewBy={workloadViewBy}
              />
            </div>

            <WorkloadThreeDotMenu />
          </IconContainerContainer>
        </div>
      </div>
    );
  };

  onSaveFilterClick = () => {
    const {
      activeFilter,
      pageName,
      viewBy,
      updateAccountFilterTemporarySaveAction
    } = this.props;
    updateAccountFilterTemporarySaveAction({
      ...activeFilter,
      name: viewBy,
      page: pageName
    });
  };

  openDeleteModal = (idToDelete) =>
    this.setState({
      idToDelete,
      deleteModalOpen: true,
      ...clearContextMenuState
    });

  closeDeleteModal = () =>
    this.setState({ idToDelete: null, deleteModalOpen: false });

  handleDeleteConfirm = () => {
    const { idToDelete } = this.state;
    const { deleteWorkloadPlanner } = this.props;
    const bar = this.getScheduleBarByItemId(idToDelete);
    deleteWorkloadPlanner(bar);
    this.setState({ idToDelete: null, deleteModalOpen: false });
  };

  getScheduleBarContextMenu = () => {
    const { contextMenuY, contextMenuItemId } = this.state;
    const { scheduleBars, zoom, plannerItems } = this.props;
    const scheduleBar = scheduleBars[contextMenuItemId];

    return scheduleBar ? (
      <ScheduleBarContextMenu
        top={contextMenuY}
        styles={{ top: contextMenuY }}
        handleScheduleBarCopy={this.handleScheduleBarCopy}
        setSplitScheduleBar={this.setSplitScheduleBar}
        openDeleteModal={this.openDeleteModal}
        activateTaskSplitScreen={this.activateTaskSplitScreen}
        scheduleBar={plannerItems.find(
          (bar) =>
            bar.id ===
            serializeBar({ itemId: scheduleBar.id, itemType: 'scheduleBar' })
        )}
        handleCloseContextMenu={() => this.setState(clearContextMenuState)}
        zoom={zoom}
      />
    ) : null;
  };

  getCapacityContextMenu = () => {
    const { contextMenuY } = this.state;
    const { userIsAdmin } = this.props;
    return (
      <StyledContextualMenu style={{ top: contextMenuY }}>
        <div>
          <StyledMenuItem
            onClick={this.handleRequestEditWorkCapacity}
            style={{ padding: '5px 15px' }}
            {...defaultTooltipProps}
            data-tip="Only Admins can Edit Work Capacity"
            data-tip-disable={userIsAdmin}
          >
            Edit Work Capacity
          </StyledMenuItem>
        </div>
        <StyledMenuItem
          onClick={this.handleSetOutOfOffice}
          style={{ padding: '5px 15px' }}
        >
          Out of Office All Day
        </StyledMenuItem>
      </StyledContextualMenu>
    );
  };

  getWFHContextMenu = () => {
    const { contextMenuY } = this.state;
    return (
      <StyledContextualMenu style={{ top: contextMenuY }}>
        <StyledMenuItem
          onClick={this.handleSetWorkFromHome}
          style={{ padding: '5px 15px' }}
        >
          Work Remote Today
        </StyledMenuItem>
      </StyledContextualMenu>
    );
  };

  getTimeEntryContextMenu = () => {
    const { contextMenuY } = this.state;
    const { navigateToReport, teamSlug } = this.props;

    return (
      <StyledContextualMenu style={{ top: contextMenuY }}>
        <StyledMenuItem
          onClick={() =>
            navigateToReport({
              teamSlug: teamSlug,
              viewType: 'time',
              openInNewWindow: true
            })
          }
          style={{ padding: '5px 15px', fontSize: '13px' }}
          opensNewTab={true}
        >
          <GoToProjectIcon color={theme.colors.colorMediumGray9} />
          View on Time Report
        </StyledMenuItem>
      </StyledContextualMenu>
    );
  };

  renderContextMenu = () => {
    const { contextMenuX, contextMenuY, contextMenuType, idToSplit } =
      this.state;
    let contextMenu;
    if (contextMenuType === CONTEXT_MENU_TYPES.SCHEDULE_BAR) {
      contextMenu = this.getScheduleBarContextMenu();
    } else if (contextMenuType === CONTEXT_MENU_TYPES.CAPACITY) {
      contextMenu = this.getCapacityContextMenu();
    } else if (contextMenuType === CONTEXT_MENU_TYPES.WFH) {
      contextMenu = this.getWFHContextMenu();
    } else if (contextMenuType === CONTEXT_MENU_TYPES.TIME_ENTRY) {
      contextMenu = this.getTimeEntryContextMenu();
    }
    return contextMenu ? (
      <Popover
        isOpen={true}
        closePopover={() => this.setState({ ...initialState, idToSplit })}
        target={document.body}
        placement="top-start"
        offset={`${contextMenuX} ${contextMenuY}`}
        className={
          contextMenuType === CONTEXT_MENU_TYPES.SCHEDULE_BAR
            ? 'schedule-bar-popover'
            : ''
        }
      >
        {contextMenu}
      </Popover>
    ) : null;
  };

  handleCapacityContextMenu = (slot, groupId, e) => {
    if (!isNaN(groupId)) {
      e.stopPropagation();
      e.preventDefault();
      this.setState({
        contextMenuItemId: groupId,
        contextMenuTimestamp: slot,
        contextMenuX: e.clientX,
        contextMenuY: e.clientY,
        contextMenuType: CONTEXT_MENU_TYPES.CAPACITY
      });
      return false;
    }
  };

  closeCapacityContextMenu = () => {
    this.setState(clearContextMenuState);
  };

  handleOpenWorkloadModal = (e) => {
    const { openWorkloadModal } = this.props;
    const { contextMenuItemId } = this.state;
    e.stopPropagation();
    openWorkloadModal({
      id: contextMenuItemId,
      type: CAPACITY_TYPES.ACCOUNT
    });
    this.closeCapacityContextMenu();
  };

  handleRequestEditWorkCapacity = () => {
    const { userIsAdmin, navigateToTeamSettings, teamSlug } = this.props;
    if (userIsAdmin && teamSlug) {
      navigateToTeamSettings({
        teamSlug,
        viewType: 'members',
        tab: 'capacity',
        openInNewWindow: true
      });
      this.closeCapacityContextMenu();
    }
  };

  handleSetOutOfOffice = () => {
    const { OOOProject, zoom } = this.props;
    const { contextMenuTimestamp, contextMenuItemId } = this.state;

    const startDate = moment(contextMenuTimestamp).format('MM/DD/YYYY');
    const endDate = moment(startDate)
      .endOf(ZOOM_TO_STEP_VALUES[zoom])
      .format('MM/DD/YYYY');
    const dayCount = ZOOM_TO_SNAP_VALUES[zoom];
    const OOOProjectBar = {
      start_date: startDate,
      end_date: endDate,
      account_id: contextMenuItemId,
      activity_id: null,
      all_day: true,
      bars: [
        {
          day_count: dayCount,
          end_date: endDate,
          start_date: startDate
        }
      ],
      daily_hours: '0.0',
      daily_percent: 0,
      deleted: false,
      description: '',
      include_holidays: false,
      include_weekends: false,
      phase_id: OOOProject.phases[0],
      project_id: OOOProject.id,
      total_hours: '0.0'
    };

    this.openBarModal({
      isNew: true,
      accountId: contextMenuItemId,
      suggestedBar: { ...OOOProjectBar }
    });

    this.closeCapacityContextMenu();
  };

  handleSetWorkFromHome = () => {
    const {
      WFHProject,
      zoom,
      createWorkloadPlanner,
      accountCapacities,
      deleteWorkloadPlanner
    } = this.props;
    const { contextMenuTimestamp, contextMenuItemId } = this.state;

    const scheduleBars =
      accountCapacities[contextMenuItemId]?.activity_phase_schedule_bars;

    const WFHBar = scheduleBars
      ? scheduleBars.filter(
          (bar) =>
            bar.project_id === WFHProject.id &&
            moment(bar.start_date, 'MM/DD/YYYY').isSame(
              moment(contextMenuTimestamp)
            )
        )
      : [];

    if (WFHBar.length > 0) {
      deleteWorkloadPlanner(WFHBar[0]);
    } else {
      const startDate = moment(contextMenuTimestamp).format('MM/DD/YYYY');
      const endDate = moment(startDate)
        .endOf(ZOOM_TO_STEP_VALUES[zoom])
        .format('MM/DD/YYYY');
      const dayCount = ZOOM_TO_SNAP_VALUES[zoom];
      const WFHProjectBar = {
        start_date: startDate,
        end_date: endDate,
        account_id: contextMenuItemId,
        activity_id: null,
        all_day: true,
        bars: [
          {
            day_count: dayCount,
            end_date: endDate,
            start_date: startDate
          }
        ],
        daily_hours: '0.0',
        daily_percent: 0,
        deleted: false,
        description: '',
        include_holidays: false,
        include_weekends: false,
        phase_id: WFHProject.phases[0],
        project_id: WFHProject.id,
        total_hours: '0.0'
      };

      createWorkloadPlanner(WFHProjectBar);
    }
    this.closeCapacityContextMenu();
  };

  // scroll / range handling
  handleTimelineScroll = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    this.props.setVisibleDates({
      visibleTimeStart: moment(visibleTimeStart),
      visibleTimeEnd: moment(visibleTimeEnd),
      plannerType: this.props.plannerType
    });
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    // fixes issue where header and scroll body don't match up (can happen after scrolling fast horizontally)
    const scrollRefScrollLeft = this.scrollRef?.scrollLeft;
    const headerRefScrollLeft = this.headerRef?.scrollLeft;
    if (
      this.scrollRef &&
      this.headerRef &&
      scrollRefScrollLeft !== headerRefScrollLeft
    ) {
      this.scrollRef.scrollLeft = headerRefScrollLeft;
    }
  };

  handleSplitScreenTimelineScroll = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    this.props.setVisibleDates({
      visibleTimeStart: moment(visibleTimeStart),
      visibleTimeEnd: moment(visibleTimeEnd),
      plannerType: this.props.plannerType
    });
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    // fixes issue where header and scroll body don't match up (can happen after scrolling fast horizontally)
    const splitScreenScrollRefScrollLeft =
      this.splitScreenScrollRef?.scrollLeft;
    const splitScreenHeaderRefScrollLeft =
      this.splitScreenHeaderRef?.scrollLeft;

    if (
      this.splitScreenScrollRef &&
      this.splitScreenHeaderRef &&
      splitScreenScrollRefScrollLeft !== splitScreenHeaderRefScrollLeft
    ) {
      this.splitScreenScrollRef.scrollLeft = splitScreenHeaderRefScrollLeft;
    }
  };

  isTodayOnScreen = () => {
    const { visibleTimeStart, visibleTimeEnd } = this.props;
    const today = moment();
    return (
      today.isAfter(visibleTimeStart, 'd') &&
      today.isBefore(visibleTimeEnd, 'd')
    );
  };

  scrollPlannerHalfTimelineWidth = (direction) => {
    const timeline = this.timelineContainer.current;
    const splitTimeline = this.splitTimelineContainer?.current;
    timeline.classList.add('scroll-transition');
    if (splitTimeline) {
      splitTimeline.classList.add('scroll-transition');
    }
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      timeline.classList.remove('scroll-transition');
      if (splitTimeline) {
        splitTimeline.classList.remove('scroll-transition');
      }
    }, 1500);

    const { visibleTimeStart, visibleTimeEnd, setVisibleDates, zoom } =
      this.props;

    const weeks = getWeeksFromZoom(zoom) / 2;
    const operation = direction === FORWARD ? 'add' : 'subtract';

    if (zoom === ZOOM_LEVELS.DAY) {
      setVisibleDates({
        visibleTimeStart: visibleTimeStart.clone()[operation](2, 'day'), // +- 2 days on Day zoom level
        visibleTimeEnd: visibleTimeEnd.clone()[operation](2, 'day'),
        plannerType: this.props.plannerType
      });
      return;
    }

    setVisibleDates({
      visibleTimeStart: visibleTimeStart.clone()[operation](weeks, 'week'),
      visibleTimeEnd: visibleTimeEnd.clone()[operation](weeks, 'week'),
      plannerType: this.props.plannerType
    });
  };

  scrollToToday = () => {
    const { zoom, setVisibleDates } = this.props;

    this.timelineContainer.current.classList.remove('scroll-transition');
    if (this.splitTimelineContainer.current) {
      this.splitTimelineContainer.current.classList.remove('scroll-transition');
    }
    const today = moment().startOf('day');

    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart: today
    });

    setVisibleDates({
      visibleTimeStart,
      visibleTimeEnd,
      plannerType: this.props.plannerType
    });
  };

  headerRange = () => getWeeksFromZoom(this.props.zoom) / 2;

  setZoom = (zoom) => {
    const {
      visibleTimeStart: timeStart,
      setVisibleDates,
      setZoom,
      onZoomChange,
      plannerType
    } = this.props;
    setZoom({ zoom, plannerType });
    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart
    });

    onZoomChange({
      visibleTimeStart,
      visibleTimeEnd
    });

    setVisibleDates({
      visibleTimeStart,
      visibleTimeEnd,
      plannerType
    });
  };

  validateMoveResize = (...props) => getSnappedDate(this.props.zoom, ...props);

  onSettingsClick = () => {
    const { openWorkloadModal, teamCapacity } = this.props;
    openWorkloadModal({ id: teamCapacity.id, type: CAPACITY_TYPES.TEAM });
  };

  onEmailClick = () => {
    const { openWorkloadEventsModal } = this.props;
    openWorkloadEventsModal({});
  };

  buildAccessIdentifier = () => {
    const { teamId } = this.props;
    return buildAccessIdentifier({
      actableType: 'Team',
      actableId: teamId,
      actionType: 'activity_phase_schedule_bars_other'
    });
  };

  onAccessClick = () => {
    const { openAccessModal } = this.props;
    openAccessModal({
      modalIdentifier: this.buildAccessIdentifier()
    });
  };

  getGroupFromGroupId = (groupId) => {
    const {
      plannerRows,
      splitScreenRows,
      plannerSplitScreenActive,
      workloadSplitScreenActive
    } = this.props;
    const isSplitScreenActive =
      plannerSplitScreenActive || workloadSplitScreenActive;
    const { groupIdKey } = this.getKeys();
    const group = plannerRows.find((row) => row[groupIdKey] === groupId);
    if (!group && isSplitScreenActive) {
      return splitScreenRows.find((row) => row[groupIdKey] === groupId);
    }
    return group;
  };

  handleOpenAddMembersForm = (group) => {
    const { openAddMembersForm } = this.props;
    const projectId = group.project_id;
    const boardId = group.board_id;
    openAddMembersForm(
      MODAL_TYPE.PROJECT,
      {
        id: projectId,
        board_id: boardId
      },
      'project-menu-item-container'
    );
    this.setState({
      isOpenAddMembersModal: true
    });
  };

  setShowMemberSuggestionsOnOpen = () => {
    this.setState({ showMemberSuggestionsOnOpen: true });
  };

  onCanvasClick = (groupId, time, e) => {
    const {
      fetchProjectById,
      workloadViewBy,
      setSplitScheduleBarId,
      workplanPermissions: { getCanCreateWorkPlan }
    } = this.props;

    // Return if user not allowed to create work plan (don't open work plan modal)

    if (!getCanCreateWorkPlan({ accountId: null })) return;

    if (this.isDragAttempt(e)) {
      this.resetClickStart();
      return;
    }
    if (this.state.idToSplit) {
      this.setState({ idToSplit: null });
      setSplitScheduleBarId({ itemId: null });
    }

    e.stopPropagation();

    const group = this.getGroupFromGroupId(groupId);
    const isOnPlanner = workloadViewBy === VIEW_BY.PROJECTS;

    if (isOnPlanner && group?.project_id) {
      return this.handleOpenPhaseModal(group.project_id);
    }

    if (group?.isBottom && isOnPlanner && !group?.accountId) {
      fetchProjectById(group.project_id);
      this.handleOpenAddMembersForm(group);
    } else if (group?.isSummary && workloadViewBy === VIEW_BY.PROJECTS) {
      this.activatePlannerSplitScreen(group.projectId);
    } else if (!group?.isRoot && !group?.preventCanvasClick) {
      const barModalValues = pick(group, ['accountId', 'projectId']);
      this.openBarModal({
        isNew: true,
        date: keyifyScheduleBarDate(time),
        memberBudgetId: group.member_budget_id,
        ...barModalValues
      });
    }
  };

  onRootCanvasClick = (groupId, time, e) => {
    if (this.isDragAttempt(e)) {
      this.resetClickStart();
      return;
    }
    e.stopPropagation();
    // handle special cases in onCanvasClick
    this.onCanvasClick(groupId, time, e);
  };

  setScrollRef = (ref) => (this.scrollRef = ref);
  setHeaderRef = (ref) => (this.headerRef = ref);
  setSplitScreenScrollRef = (ref) => (this.splitScreenScrollRef = ref);
  setSplitScreenHeaderRef = (ref) => (this.splitScreenHeaderRef = ref);

  openBarModal = ({
    isNew,
    accountId,
    projectId,
    memberBudgetId,
    barId,
    date,
    suggestedBar
  }) => {
    const { openPlannerBarModal } = this.props;
    openPlannerBarModal({
      isNew,
      accountId,
      projectId,
      memberBudgetId,
      barId,
      date,
      suggestedBar
    });
  };

  timelineContainer = React.createRef();
  splitTimelineContainer = React.createRef();

  getKeys = () =>
    this.props.workloadViewBy === VIEW_BY.MEMBERS ? defaultKeys : byProjectKeys;

  getSplitScreenKeys = () => byProjectSplitScreenKeys;

  changeTabs = (viewType) => () => {
    const { teamSlug, navigateToWorkload, setWorkloadViewBy } = this.props;
    setWorkloadViewBy({ viewBy: viewType });
    this.deactivateSplitScreen();
    navigateToWorkload({
      teamSlug,
      workloadViewType: viewType
    });
  };

  changePlannerTabs = (viewType) => {
    const { teamSlug, navigateToSchedule } = this.props;
    navigateToSchedule({
      teamSlug,
      plannerViewType: viewType
    });
  };

  handleDrag = throttle((...args) => this._handleDrag(...args), 50);
  _handleDrag = ({ deltaY: dragOffsetY }) => {
    if (this.state.dragging) {
      this.setState({ dragOffsetY });
    }
  };

  handleDragStart = () => {
    this.setState({ dragging: true });
  };

  handleDragEnd = () => {
    // clear drag offset, store result of current drag offset plus current drop offset
    const dropOffsetY = this.state.dropOffsetY + this.state.dragOffsetY;
    const cancelSplitScreenDropY = this.getSplitScreenInitialHeight() - 150;
    this.setState({ dragging: false });
    if (dropOffsetY > cancelSplitScreenDropY) {
      this.deactivateSplitScreen();
      return;
    }
    this.setState({
      dropOffsetY,
      dragOffsetY: 0
    });
  };

  renderSplitScreenDivider = () => {
    return (
      <SplitScreenDivider
        handleDrag={this.handleDrag}
        handleDragEnd={this.handleDragEnd}
        handleDragStart={this.handleDragStart}
      />
    );
  };

  deactivateWorkloadSplitScreen = () => {
    const { navigateToWorkload, setWorkloadSplitScreenInactive, teamSlug } =
      this.props;
    setWorkloadSplitScreenInactive();
    navigateToWorkload({
      teamSlug,
      workloadViewType: VIEW_BY.MEMBERS
    });
    this.setState({ dropOffsetY: 0, dragOffsetY: 0 });
  };

  deactivatePlannerSplitScreen = () => {
    const { navigateToSchedule, setPlannerSplitScreenInactive, teamSlug } =
      this.props;
    setPlannerSplitScreenInactive();
    navigateToSchedule({
      teamSlug,
      plannerViewType: VIEW_BY.WORK_PLANS
    });
    this.setState({ dropOffsetY: 0, dragOffsetY: 0 });
  };

  deactivateSplitScreen = () => {
    // when we don't know the page we are on i.e when closing the generic split screen divider, we can call the deactivate function by page
    const { viewBy } = this.props;
    if (viewBy === VIEW_BY.PROJECTS) {
      this.deactivatePlannerSplitScreen();
    } else {
      this.deactivateWorkloadSplitScreen();
    }
  };

  activatePlannerSplitScreen = (projectId) => {
    const { setPlannerSplitScreenActive, navigateToSchedule, teamSlug } =
      this.props;
    setPlannerSplitScreenActive({
      splitScreenProjectId: projectId,
      plannerSplitScreenType: SPLIT_SCREEN_TYPES.PROJECT
    });
    navigateToSchedule({
      teamSlug,
      plannerViewType: VIEW_BY.WORK_PLANS,
      splitScreenProjectId: projectId,
      splitScreenActive: true
    });
  };

  activateWorkplanSplitScreen = (accountId) => {
    const { setWorkloadSplitScreenActive, navigateToWorkload, teamSlug } =
      this.props;
    setWorkloadSplitScreenActive({
      splitScreenAccountId: accountId,
      workloadSplitScreenType: SPLIT_SCREEN_TYPES.WORK_PLAN
    });
    navigateToWorkload({
      teamSlug,
      workloadViewType: VIEW_BY.MEMBERS,
      splitScreenActive: true,
      splitScreenType: SPLIT_SCREEN_TYPES.WORK_PLAN,
      splitScreenAccountId: accountId
    });
    this.setState(clearContextMenuState);
  };

  activateTaskSplitScreen = (accountId) => {
    const { setWorkloadSplitScreenActive, navigateToWorkload, teamSlug } =
      this.props;
    setWorkloadSplitScreenActive({
      splitScreenAccountId: accountId,
      workloadSplitScreenType: SPLIT_SCREEN_TYPES.SCHEDULED_TASK
    });
    navigateToWorkload({
      teamSlug,
      workloadViewType: VIEW_BY.MEMBERS,
      splitScreenActive: true,
      splitScreenType: SPLIT_SCREEN_TYPES.SCHEDULED_TASK,
      splitScreenAccountId: accountId
    });
    this.setState(clearContextMenuState);
  };

  activatePhaseSplitScreen = (accountId) => {
    const { setWorkloadSplitScreenActive, navigateToWorkload, teamSlug } =
      this.props;
    setWorkloadSplitScreenActive({
      splitScreenAccountId: accountId,
      workloadSplitScreenType: SPLIT_SCREEN_TYPES.PHASE
    });
    navigateToWorkload({
      teamSlug,
      workloadViewType: VIEW_BY.MEMBERS,
      splitScreenActive: true,
      splitScreenType: SPLIT_SCREEN_TYPES.PHASE,
      splitScreenAccountId: accountId
    });
    this.setState(clearContextMenuState);
  };

  getSplitScreenInitialHeight = () => {
    const halfScreen = window.innerHeight / 2;
    return halfScreen;
  };

  getDragOffset = () => {
    const { dragOffsetY, dropOffsetY } = this.state;
    return dragOffsetY + dropOffsetY;
  };

  clearJoinProject = () => {
    this.setState({
      joinModalOpen: false,
      joinProjectAccountId: null,
      joinProjectProject: null,
      joinProjectBar: null
    });
  };

  handleJoinProject = () => {
    const { updateWorkloadPlanner, addMemberToProject } = this.props;
    const { joinProjectBar, joinProjectProject } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: () => updateWorkloadPlanner(joinProjectBar),
        selector: noop
      }
    ];
    addMemberToProject(
      joinProjectProject.id,
      joinProjectBar.account_id,
      basicMemberRole,
      joinProjectProject.board_id,
      onSuccess
    );
    this.clearJoinProject();
  };

  render() {
    const {
      handleLazyLoad,
      selectedItemIds,
      visibleTimeStart,
      visibleTimeEnd,
      utilizations,
      zoom,
      isOnWorkloadView,
      workloadViewBy,
      plannerRows,
      plannerItems,
      splitScreenRows,
      splitScreenItems,
      splitScreenItemIds,
      areMembersLoaded,
      projectUtilizations,
      closeMilestoneModal,
      isMilestoneModalOpen,
      inViewIndices,
      selectedProject,
      workloadSplitScreenActive,
      plannerSplitScreenActive,
      isOnScheduleView,
      condensedZoomLevel,
      workloadViewType,
      activeFilterChanged,
      me,
      workloadSettings,
      activeFilter,
      isOnScheduledTaskSplitScreen,
      matchedParams,
      allProjectRowsOpen,
      handleOpenFindPeopleModal,
      newMembersSuggestionTableFlag
    } = this.props;

    const {
      deleteModalOpen,
      contextMenuItemId,
      isOpenAddMembersModal,
      idToSplit,
      joinModalOpen,
      joinProjectAccountId,
      joinProjectProject
    } = this.state;
    // need to wait for formatted planner members to populate in order for tooltips to work
    const shouldRender = areMembersLoaded;
    const preventZoom = visibleTimeEnd.valueOf() - visibleTimeStart.valueOf();
    const isSplitScreenActive =
      workloadViewBy === VIEW_BY.PROJECTS
        ? plannerSplitScreenActive
        : workloadSplitScreenActive;
    const splitScreenHeight =
      isSplitScreenActive && this.getSplitScreenInitialHeight();
    const dragOffset = this.getDragOffset();
    return shouldRender ? (
      <>
        <div
          className={cn(
            'timeline-container project-planner-timeline-container view-hours-default',
            {
              'workload-view': isOnWorkloadView,
              'quarterly-view': zoomToTopIntervalHash[zoom] === 'month',
              'split-screen': isSplitScreenActive,
              'bar-split-active': !!idToSplit,
              'members-view': workloadViewBy === VIEW_BY.MEMBERS
            }
          )}
          onMouseDown={this.trackClickStart}
          ref={this.timelineContainer}
          style={
            isSplitScreenActive
              ? {
                  height: splitScreenHeight + dragOffset
                }
              : {}
          }
        >
          {contextMenuItemId && this.renderContextMenu()}
          <SidebarBackground />
          {isOnScheduledTaskSplitScreen && <TaskSidebarContainer />}
          <DateNav
            scrollBack={() => this.scrollPlannerHalfTimelineWidth(BACKWARD)}
            scrollForward={() => this.scrollPlannerHalfTimelineWidth(FORWARD)}
            scrollToToday={() => this.scrollToToday()}
            header={[visibleTimeStart, visibleTimeEnd]}
            isTodayOnScreen={this.isTodayOnScreen()}
            showZoom
            onAccessClick={this.onAccessClick}
            zoom={zoom}
            setZoom={this.setZoom}
            SHOW_DEMO_VIEWERS_COMPONENT
            accessIdentifier={this.buildAccessIdentifier()}
            isOnScheduleView={isOnScheduleView}
            filter={activeFilter}
            condensedZoomLevel={condensedZoomLevel}
            workloadViewType={workloadViewType}
            activeFilterChanged={activeFilterChanged}
            onSaveFilterClick={this.onSaveFilterClick}
            handleOpenFindPeopleModal={handleOpenFindPeopleModal}
            showDayView={workloadSettings?.show_day_view ?? true}
          >
            {!isOnScheduleView ? (
              <StyledLeftOptions></StyledLeftOptions>
            ) : (
              <StyledLeftOptions>
                <PlannerFilterContainer />
                <PlannerFilterOption
                  onClick={() => this.changePlannerTabs(VIEW_BY.WORK_PLANS)}
                  isSelected={
                    matchedParams?.plannerViewType === VIEW_BY.WORK_PLANS
                  }
                  data-testid="workplans-tab"
                >
                  {VIEW_BY_DISPLAY[VIEW_BY.WORK_PLANS].label}
                </PlannerFilterOption>
                <PlannerFilterOption
                  onClick={() => this.changePlannerTabs(VIEW_BY.TASKS)}
                  isSelected={matchedParams?.plannerViewType === VIEW_BY.TASKS}
                  data-testid="tasks-tab"
                >
                  {VIEW_BY_DISPLAY[VIEW_BY.TASKS].label}
                </PlannerFilterOption>
              </StyledLeftOptions>
            )}
          </DateNav>
          <Timeline
            groups={plannerRows}
            items={plannerItems}
            selected={selectedItemIds}
            itemRenderer={this.itemRenderer}
            groupRenderer={this.getGroupRenderer()}
            dragSnap={60 * 60 * 1000 * 24 * ZOOM_TO_SNAP_VALUES[zoom]}
            canChangeGroup={true}
            stackItems={true}
            keys={this.getKeys()}
            lineHeight={
              !workloadViewType || workloadViewType === VIEW_TYPE.NORMAL
                ? 74
                : CONDENSED_VIEW_ROW_HEIGHTS[condensedZoomLevel]
            }
            itemHeightRatio={44 / 50}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            visibleTimeStart={visibleTimeStart.valueOf()}
            visibleTimeEnd={visibleTimeEnd.valueOf()}
            onTimeChange={this.handleTimelineScroll}
            minZoom={preventZoom}
            minResizeWidth={0}
            maxZoom={preventZoom}
            subHeaderLabelFormats={subHeaderLabelFormats}
            onBoundsChange={handleLazyLoad}
            onCanvasClick={this.onCanvasClick}
            onItemClick={this.handleItemClick}
            onItemSelect={this.handleItemClick}
            onItemMove={this.handleItemMove}
            onItemResize={this.handleItemResize}
            onItemDrag={this.handleItemDrag}
            canResize={BOTH}
            useResizeHandle
            infoLabel={false}
            scrollRef={this.setScrollRef}
            headerRef={this.setHeaderRef}
            rowRenderer={this.rowRenderer}
            rowData={
              VIEW_BY.MEMBERS === workloadViewBy
                ? utilizations
                : projectUtilizations
            }
            moveResizeValidator={this.validateMoveResize}
            onItemContextMenu={this.onItemContextMenu}
            sidebarWidth={250}
            clickTolerance={5}
            inViewIndices={inViewIndices}
            idToSplit={idToSplit}
            key={condensedZoomLevel + workloadViewType}
          >
            <TimelineMarkers>
              <TodayMarker />
            </TimelineMarkers>
            <TimelineHeaders>
              <SidebarHeader headerData={{ allProjectRowsOpen }}>
                {this.renderLeftSidebarHeader}
              </SidebarHeader>
              <DateHeader
                height={35}
                unit={zoomToTopIntervalHash[zoom]}
                intervalRenderer={this.topIntervalRenderer}
              />
              <DateHeader
                height={29}
                unit={zoomToIntervalHash[zoom]}
                intervalRenderer={this.intervalRenderer}
              />
            </TimelineHeaders>
          </Timeline>
          <DeleteModal
            isOpen={deleteModalOpen}
            toggle={this.closeDeleteModal}
            deleteOnClick={this.handleDeleteConfirm}
            component={'Work Plan'}
          />
          <MilestoneModal
            isOpen={isMilestoneModalOpen}
            toggle={closeMilestoneModal}
          />
          <JoinProjectModal
            open={joinModalOpen}
            toggle={this.clearJoinProject}
            selectedProject={joinProjectProject}
            onConfirm={this.handleJoinProject}
            text={'add a Work Plan'}
            accountId={joinProjectAccountId}
            currentUserId={me.id}
          />
          <StyledTooltip
            effect="solid"
            id={`schedule-bar`}
            place="top"
            multiline={true}
          />
          <StyledTooltip
            effect="solid"
            id={`start-date-tooltip`}
            place="top"
            multiline={true}
          />
          <StyledTooltip
            effect="solid"
            id={`end-date-tooltip`}
            place="top"
            multiline={true}
          />
          {isOpenAddMembersModal && (
            <AddMembersContainer
              isOpenMembersModal={isOpenAddMembersModal}
              closeMembersModal={() =>
                this.setState({
                  showMemberSuggestionsOnOpen: false,
                  isOpenAddMembersModal: false
                })
              }
              project={selectedProject}
              modalType={MODAL_TYPE.PROJECT}
              showSuggestionsOnOpen={this.state.showMemberSuggestionsOnOpen}
            />
          )}
        </div>
        {isSplitScreenActive && (
          <>
            {this.renderSplitScreenDivider()}
            <div
              className={cn(
                'timeline-container project-planner-timeline-container split-screen-timeline view-hours-default',
                {
                  'workload-view': isOnWorkloadView,
                  'quarterly-view': zoomToTopIntervalHash[zoom] === 'month',
                  'split-screen': isSplitScreenActive,
                  'members-view': workloadViewBy === VIEW_BY.MEMBERS
                }
              )}
              onMouseDown={this.trackClickStart}
              ref={this.splitTimelineContainer}
              style={
                isSplitScreenActive
                  ? {
                      height: splitScreenHeight - dragOffset
                    }
                  : {}
              }
            >
              <Timeline
                groups={splitScreenRows}
                items={splitScreenItems}
                selected={splitScreenItemIds}
                itemRenderer={this.splitScreenItemRenderer}
                groupRenderer={this.getGroupRenderer()}
                dragSnap={60 * 60 * 1000 * 24 * ZOOM_TO_SNAP_VALUES[zoom]}
                stackItems={true}
                keys={this.getSplitScreenKeys()}
                canChangeGroup={false}
                lineHeight={
                  !workloadViewType || workloadViewType === VIEW_TYPE.NORMAL
                    ? 74
                    : CONDENSED_VIEW_ROW_HEIGHTS[condensedZoomLevel]
                }
                itemHeightRatio={44 / 50}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                visibleTimeStart={visibleTimeStart.valueOf()}
                visibleTimeEnd={visibleTimeEnd.valueOf()}
                onTimeChange={this.handleSplitScreenTimelineScroll}
                minZoom={preventZoom}
                maxZoom={preventZoom}
                subHeaderLabelFormats={subHeaderLabelFormats}
                scrollRef={this.setSplitScreenScrollRef}
                headerRef={this.setSplitScreenHeaderRef}
                onCanvasClick={this.onCanvasClick}
                onItemClick={this.handleItemClick}
                onItemSelect={this.handleItemClick}
                onItemMove={this.handleItemMove}
                onItemResize={this.handleItemResize}
                onItemDrag={this.handleItemDrag}
                canResize={BOTH}
                useResizeHandle
                infoLabel={false}
                rowRenderer={this.splitScreenRowRenderer}
                rowData={utilizations}
                moveResizeValidator={this.validateMoveResize}
                onItemContextMenu={this.onItemContextMenu}
                sidebarWidth={250}
                clickTolerance={5}
                key={
                  condensedZoomLevel + workloadViewType
                } /* This is necessary for condensed views to rerender properly until we find a better way */
              >
                <TimelineHeaders>
                  {/* only for preventing scroll bug */}
                  <DateHeader
                    height={1}
                    unit={zoomToTopIntervalHash[zoom]}
                    intervalRenderer={this.emptyIntervalRenderer}
                  />
                </TimelineHeaders>
              </Timeline>
            </div>
          </>
        )}
      </>
    ) : (
      <div className="loader no-expansion-animation grey" />
    );
  }
}

const makeMapStateToProps = () => {
  const getMemberTimesheetStatusTotalsByDate =
    makeGetMemberTimesheetStatusTotalsByDate();
  const getWorkloadItemIds = makeGetWorkloadItemIds();
  const getPlannerProjectMemberAccountIds =
    makeGetPlannerProjectMemberAccountIds();
  const getUtilizationsBreakdownByMember =
    makeGetUtilizationsBreakdownByMember();
  const getSummaryUtilizationsBreakdown = makeGetSummaryUtilizationsBreakdown();
  const getWorkloadRows = makeGetWorkloadRows();
  const getWorkloadSplitScreenRows = makeGetWorkloadSplitScreenRows();
  const getWorkloadSplitScreenItems = makeGetWorkloadSplitScreenItems();
  const getWorkloadSplitScreenItemIds = makeGetWorkloadSplitScreenItemIds();
  const getRootRowCount = makeGetRootRowCount();
  const getWorkloadItems = makeGetWorkloadItems();
  const getCappedUtilizationsByMember = makeGetCappedUtilizationsByMember();
  const getAllProjectRowsOpen = makeGetAllProjectRowsOpen();
  const getWorkloadGroupHash = makeGetWorkloadGroupHash();

  const mapStateToProps = (state, ownProps) => {
    const accountIds = getPlannerProjectMemberAccountIds(state, ownProps);
    return {
      me: getMe(state),
      token: getAuthToken(state),
      projectHash: getProjectHash(state),
      scheduleBars: getPlannerSchedules(state),
      taskHash: getHomeTaskObj(state),
      plannerLoading: state.workloadPlanner.isLoading,
      isFetchingPhases: state.phases.fetching,
      selectedItemIds: getWorkloadItemIds(state, ownProps), // necessary to allow drag before click
      plannerMemberIds: getPlannerMemberIds(state),
      utilizations: getUtilizationsWithSummary(state, ownProps),
      utilizationsBreakdown: getUtilizationsBreakdownByMember(state, {
        accountIds
      }),
      summaryUtilizationsBreakdown: getSummaryUtilizationsBreakdown(state, {
        accountIds
      }),
      steps: getProjectPlannerSteps(state, ownProps),
      teamCapacity: getTeamCapacity(state),
      accountCapacities: getAccountCapacitiesWithSummary(state, ownProps),
      projectCapacities: getProjectCapacities(state),
      isOnWorkloadView: true,
      isOnScheduleView: getIsOnScheduleView(state),
      teamSlug: getTeamSlug(state),
      userTheme: getUserTheme(state),
      workloadViewBy: state.workloadPlannerFilter.viewBy,
      plannerRows: getWorkloadRows(state, ownProps),
      splitScreenRows: getWorkloadSplitScreenRows(state, ownProps),
      splitScreenItems: getWorkloadSplitScreenItems(state, ownProps),
      splitScreenItemIds: getWorkloadSplitScreenItemIds(state, ownProps), // necessary to allow drag before click
      rootRowCount: getRootRowCount(state, ownProps),
      plannerItems: getWorkloadItems(state, ownProps),
      milestones: getAllMilestonesHash(state),
      allPhases: getFlatPhasesAndMilestones(state),
      teamId: getSelectedTeamId(state),
      viewSettings: getSelectedTeamViewSettings(state),
      areMembersLoaded: getPlannerMembersCount(state) > 0,
      access: state.access.access,
      holidayDatesHash: getHolidayDatesHash(state),
      projectUtilizations: getProjectUtilizations(state),
      cappedUtilizationsByMember: getCappedUtilizationsByMember(
        state,
        ownProps
      ),
      capacitySummary: getAccountCapacitiesSummary(state, ownProps),
      isMilestoneModalOpen: getIsMilestoneModalOpen(state),
      OOOProject: getOOOProject(state),
      WFHProject: getWFHProject(state),
      plannerSplitScreenActive: getPlannerSplitScreenActive(state),
      workloadSplitScreenActive: getWorkloadSplitScreenActive(state),
      splitScreenProjectId: getPlannerSplitScreenProjectId(state),
      splitScreenAccountId: getWorkloadSplitScreenAccountId(state),
      plannerSplitScreenType: getPlannerSplitScreenType(state),
      workloadSplitScreenType: getWorkloadSplitScreenType(state),
      selectedProject: getSelectedProject(state),
      installedModuleIds: getInstalledWorkloadModuleIds(state),
      matchedParams: getMatchedRouteParams(state),
      averageCapacities: getAverageCapacities(state),
      activeFilterChanged: getActiveFilterChanged(state, ownProps),
      phaseHash: getFlatPhasesHash(state),
      activityPhaseHash: getActivityPhaseHash(state),
      timeEntryTotals: getMemberTimesheetStatusTotalsByDate(state, ownProps),
      projectTotals: getProjectPlannerHoursByDate(state, ownProps),
      zoom: getZoom(state, ownProps),
      condensedZoomLevel: getCondensedZoomLevel(state),
      workloadViewType: getWorkloadViewType(state),
      allMemberRowsOpen: getAllMemberRowsOpen(state, ownProps),
      allProjectRowsOpen: getAllProjectRowsOpen(state, ownProps),
      workloadGroupHash: getWorkloadGroupHash(state, ownProps),
      crossFieldFilterOnPlannerFlag:
        getSplitFlags(state).crossFieldFilterOnPlannerFlag,
      crossFilterOnWorkloadFlag: getSplitFlags(state).crossFilterOnWorkloadFlag,
      newMembersSuggestionTableFlag:
        getSplitFlags(state).newMembersSuggestionTableFlag,
      isOnPhaseSplitScreen: getIsOnPhaseSplitScreen(state),
      isOnScheduledTaskSplitScreen: getIsOnScheduledTaskSplitScreen(state),
      isOnWorkplanSplitScreen: getIsOnWorkplanSplitScreen(state),
      workloadSelectionLimitFlag:
        getSplitFlags(state).workloadSelectionLimitFlag,
      showExpandAllFlag: getSplitFlags(state).showExpandAllFlag,
      memberBudgets: getMemberBudgets(state),
      userIsAdmin: getUserIsAdmin(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateWorkloadPlanner,
  createWorkloadPlanner,
  deleteWorkloadPlanner,
  openProjectPlannerModal,
  updateWorkloadPlannerMembers,
  fetchMemberProjects,
  fetchTeamMemberProfile,
  setVisibleDates,
  openWorkloadModal,
  openWorkloadEventsModal,
  openPlannerBarModal,
  closePlannerBarModal,
  fetchTeamCapacity,
  setZoom,
  navigateToProject,
  setWorkloadViewBy,
  openBudgetModal,
  fetchPhaseTotals,
  fetchPhases,
  fetchMemberBudgets,
  fetchPhasesByProjectIds,
  updatePhase,
  openAccessModal,
  updateMemberBudget,
  openMilestoneModal,
  closeMilestoneModal,
  setSelectedProject,
  fetchProjectById,
  splitWorkloadPlanner,
  openAddMembersForm,
  navigateToWorkload,
  navigateToSchedule,
  updateAccountFilterTemporarySaveAction,
  setPlannerSplitScreenActive,
  setWorkloadSplitScreenActive,
  setPlannerSplitScreenInactive,
  setWorkloadSplitScreenInactive,
  setSplitScheduleBarId,
  triggerTasksAttributesUpdate,
  fetchTaskGroups,
  navigateToTaskModal,
  setSelectedTask,
  fetchCommentsAndMetadata,
  fetchSuggestions,
  updateActivityPhase,
  setCondensedZoomLevel,
  setWorkloadViewType,
  navigateToReport,
  openAllWorkloadProjectRows,
  closeAllWorkloadProjectRows,
  addMemberToProject,
  fetchRates,
  navigateToTeamSettings,
  editPhaseDates,
  handleErrorMessage
};

export default withRouter(
  withPermissionsCheck(
    connect(
      makeMapStateToProps,
      mapDispatchToProps
    )(WorkloadPlannerTimelineContainer)
  )
);

const RelativeContainer = styled.div`
  position: relative;
`;

const RequestsButtonContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 5px;
`;
