import * as Cells from './Cells';
import Spinner from 'react-spinkit';
import type {
  BaseTableColumn,
  BaseTableListItem
} from 'components/Table/types';
import type { TableProps } from 'components/Table/TableV2';
import type { FilterListsTableVariant } from './types';
import {
  FilterListType,
  FilterField,
  memberFilterListTypesHash
} from 'FilterModule/constants';
import merge from 'lodash/merge';
import omit from 'lodash/omit';

const emptyObj = {};

const EmptyDiv = () => <></>;
const Loader = () => (
  <Spinner
    fadeIn="none"
    name="ball-beat"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    }}
  />
);

/* ------------------------------ rows / cells ------------------------------ */

export const ROW_TYPES = {
  defaultFilterItemRow: 'defaultFilterItemRow',
  defaultGroupedFilterItemRow: 'defaultGroupedFilterItemRow',
  defaultFilterListTypeHeaderRow: 'defaultFilterListTypeHeaderRow',
  defaultFilterGroupHeaderRow: 'defaultFilterGroupHeaderRow',
  skillHeaderRow: 'skillHeaderRow',
  showArchivedRow: 'showArchivedRow',
  showHiddenItemsRow: 'showHiddenItemsRow',
  addEntityRow: 'addEntityRow',
  loadingRow: 'loadingRow',
  noResultsRow: 'noResultsRow',
  skeletonLoaderRow: 'skeletonLoaderRow',
  searchInputRow: 'searchInputRow',
  batchActionsRow: 'batchActionsRow',
  filterListTypeSelectorRow: 'filterListTypeSelectorRow'
};

type MakeShowArchivedRowParams = {
  numArchived: number;
  toggleIsArchivedShowing: () => void | ((nextValue?: any) => void);
  isShowingArchived?: boolean;
  paddingLeft?: number;
};

export const makeShowArchivedRow = (
  arg: MakeShowArchivedRowParams
): BaseTableListItem & MakeShowArchivedRowParams => ({
  rowType: ROW_TYPES.showArchivedRow,
  rowHeight: 25,
  ...arg
});

type MakeShowHiddenItemsRowParams = {
  numHidden: number;
  toggleIsShowingHiddenItems?: () => void;
  isShowingHiddenItems?: boolean;
};

export const makeShowHiddenItemsRow = (
  arg: MakeShowHiddenItemsRowParams
): BaseTableListItem & MakeShowHiddenItemsRowParams => ({
  rowType: ROW_TYPES.showHiddenItemsRow,
  rowHeight: 25,
  ...arg
});

type MakeAddEntityRowParams = {
  filterListType: FilterListType;
  handleNavToEntityPage: () => void;
};

export const makeAddEntityRow = (
  arg: MakeAddEntityRowParams
): BaseTableListItem & MakeAddEntityRowParams => ({
  rowType: ROW_TYPES.addEntityRow,
  rowHeight: 40,
  ...arg
});

type MakeBatchActionsRowParams = {
  toggleCollapseAll?: () => void;
  isAllCollapsed?: boolean;
  className?: string;
};

export const makeBatchActionsRow = (
  arg: MakeBatchActionsRowParams
): BaseTableListItem & MakeBatchActionsRowParams => ({
  rowType: ROW_TYPES.batchActionsRow,
  rowHeight: 30,
  isStickyHeader: true,
  ...arg
});

type MakeFilterListTypeSelectorRowParams = {
  className?: string;
  filterListTypeKey?: FilterField;
};

export const makeFilterListTypeSelectorRow = (
  arg: MakeFilterListTypeSelectorRowParams = emptyObj
): BaseTableListItem & MakeFilterListTypeSelectorRowParams => ({
  rowType: ROW_TYPES.filterListTypeSelectorRow,
  rowHeight: 30,
  isStickyHeader: true,
  ...arg
});

type MakeSearchInputRowParams = {
  className?: string;
};

export const makeSearchInputRow = (
  arg: MakeSearchInputRowParams = emptyObj
): BaseTableListItem & MakeSearchInputRowParams => ({
  rowType: ROW_TYPES.searchInputRow,
  rowHeight: 30,
  isStickyHeader: true,
  ...arg
});

export const noResultsRow: BaseTableListItem = {
  rowType: ROW_TYPES.noResultsRow,
  rowHeight: 40
};

export const skeletonLoaderRow: BaseTableListItem = {
  rowType: ROW_TYPES.skeletonLoaderRow,
  rowHeight: 345
};

const defaultFilterItemRowCells = {
  leftPadding: EmptyDiv,
  select: Cells.SelectCell,
  label: Cells.DefaultLabelCell,
  rightPadding: EmptyDiv
};

const defaultGroupedFilterItemRowCells = defaultFilterItemRowCells;

const defaultFilterGroupHeaderRowCells = {
  leftPadding: EmptyDiv,
  select: Cells.SelectCell,
  label: Cells.DefaultGroupLabelCell,
  rightPadding: EmptyDiv
};

const defaultFilterListTypeHeaderRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.DefaultFilterListTypeHeaderCell,
  rightPadding: EmptyDiv
};

const skillHeaderRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.SkillHeaderCell,
  rightPadding: EmptyDiv
};

const showArchivedRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.ShowArchivedCell,
  rightPadding: EmptyDiv
};

const showHiddenItemsRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.ShowHiddenItemsCell,
  rightPadding: EmptyDiv
};

const addEntityRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.AddEntityCell,
  rightPadding: EmptyDiv
};

const noResultsRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.NoResultsCell,
  rightPadding: EmptyDiv
};

const skeletonLoaderRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.SkeletonLoaderCell,
  rightPadding: EmptyDiv
};

const loadingRowCells = {
  inner: Loader
};

const searchInputRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.SearchInputCell,
  rightPadding: EmptyDiv
};

const batchActionsRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.BatchActionsCell,
  rightPadding: EmptyDiv
};

const filterListTypeSelectorRowCells = {
  leftPadding: EmptyDiv,
  inner: Cells.FilterListTypeSelectorCell,
  rightPadding: EmptyDiv
};

/* --------------------------------- columns -------------------------------- */

// not used. leaving here
const tableWidthColumn: BaseTableColumn = {
  headerType: 'tableWidth',
  id: 'tableWidth'
};

// used for rows that take up entire table, but within the padding columns
const innerColumn: BaseTableColumn = {
  headerType: 'inner',
  id: 'inner'
};

const leftPaddingColumn: BaseTableColumn = {
  headerType: 'leftPadding',
  id: 'leftPadding'
};

const rightPaddingColumn: BaseTableColumn = {
  headerType: 'rightPadding',
  id: 'rightPadding'
};

const selectColumn: BaseTableColumn = {
  headerType: 'select',
  id: 'select'
};

const labelColumn: BaseTableColumn = {
  headerType: 'label',
  id: 'label'
};

/**
 * tableWidth column width should not include rightPadding or extra 'custom' columns
 */
export const calculateTotalColumnsWidth = (
  columnWidths: Record<string, number>
) => {
  const columnWidthsToUse = omit(columnWidths, [
    innerColumn.headerType,
    rightPaddingColumn.headerType
  ]) as typeof columnWidths;

  return Object.values(columnWidthsToUse).reduce((sum, cur) => (sum += cur), 0);
};

/* --------------------------------- configs -------------------------------- */

const stackedFilterLeftPaddingColumnWidth = 20;
export const stackedFilterSelectColumnWidth = 25;
export const stackedFilterLabelColumnWidth = 163;
const stackedFilterInnerColumnWidth =
  stackedFilterSelectColumnWidth + stackedFilterLabelColumnWidth;
export const stackedFilterRightPaddingColumnWidth = 15;

const stackedFilterColumnWidths = {
  [leftPaddingColumn.headerType]: stackedFilterLeftPaddingColumnWidth,
  [selectColumn.headerType]: stackedFilterSelectColumnWidth,
  [labelColumn.headerType]: stackedFilterLabelColumnWidth,
  [innerColumn.headerType]: stackedFilterInnerColumnWidth,
  [rightPaddingColumn.headerType]: stackedFilterRightPaddingColumnWidth
};

export const stackedFilterMinLeftPaddingColumnWidth = 10;
export const stackedFilterMaxInnerColumnWidth =
  stackedFilterInnerColumnWidth +
  (stackedFilterLeftPaddingColumnWidth -
    stackedFilterMinLeftPaddingColumnWidth);

stackedFilterColumnWidths.tableWidth = calculateTotalColumnsWidth(
  stackedFilterColumnWidths
);

// StackedFilters
const makeStackedFilterConfig = () => {
  const config = {
    tableProps: {
      columnWidthsByHeaderType: stackedFilterColumnWidths,
      customColumnWidthsByHeaderType: {
        [leftPaddingColumn.headerType]: {
          [ROW_TYPES.defaultFilterListTypeHeaderRow]:
            stackedFilterMinLeftPaddingColumnWidth,
          [ROW_TYPES.defaultFilterGroupHeaderRow]:
            stackedFilterMinLeftPaddingColumnWidth,
          [ROW_TYPES.skillHeaderRow]: stackedFilterMinLeftPaddingColumnWidth,
          [ROW_TYPES.searchInputRow]: stackedFilterMinLeftPaddingColumnWidth,
          [ROW_TYPES.batchActionsRow]: stackedFilterMinLeftPaddingColumnWidth,
          [ROW_TYPES.filterListTypeSelectorRow]:
            stackedFilterMinLeftPaddingColumnWidth,
          [ROW_TYPES.skeletonLoaderRow]: stackedFilterMinLeftPaddingColumnWidth
        },
        [innerColumn.headerType]: {
          [ROW_TYPES.defaultFilterListTypeHeaderRow]:
            stackedFilterMaxInnerColumnWidth,
          [ROW_TYPES.skillHeaderRow]: stackedFilterMaxInnerColumnWidth,
          [ROW_TYPES.searchInputRow]: stackedFilterMaxInnerColumnWidth,
          [ROW_TYPES.batchActionsRow]: stackedFilterMaxInnerColumnWidth,
          [ROW_TYPES.filterListTypeSelectorRow]:
            stackedFilterMaxInnerColumnWidth,
          [ROW_TYPES.skeletonLoaderRow]: stackedFilterMaxInnerColumnWidth
        }
      },
      stickyHeaderCells: defaultFilterListTypeHeaderRowCells
    },
    getFilterListTypeSelectorKey: (filterListType: FilterListType) =>
      memberFilterListTypesHash[filterListType]
        ? FilterField.stackedFilterMembersFilterListType
        : undefined
  };

  return config;
};

// SideFilter
const makeSideFilterConfig = () => {
  const config = {
    tableProps: {
      // update sticky header in styles.ts as well
      columnWidthsByHeaderType: {
        [leftPaddingColumn.headerType]: 10,
        [selectColumn.headerType]: 25,
        [labelColumn.headerType]: 245,
        [innerColumn.headerType]: 270, // select + label
        [rightPaddingColumn.headerType]: 20
      },
      customColumnWidthsByHeaderType: {
        [leftPaddingColumn.headerType]: {
          [ROW_TYPES.defaultGroupedFilterItemRow]: 24,
          [ROW_TYPES.searchInputRow]: 16,
          [ROW_TYPES.batchActionsRow]: 16
        },
        [labelColumn.headerType]: {
          [ROW_TYPES.defaultGroupedFilterItemRow]: 245 - (24 - 10) // default label width - the custom leftPadding width difference
        },
        [rightPaddingColumn.headerType]: {
          [ROW_TYPES.searchInputRow]: 14,
          [ROW_TYPES.batchActionsRow]: 14
        }
      }
    },
    filterListTypeGroupedListsBuilder: {
      skipHeaders: true
    },
    isFilterListsExpandedByDefault: true
  };

  const columnWidthsByHeaderType = config.tableProps.columnWidthsByHeaderType;

  columnWidthsByHeaderType.tableWidth = calculateTotalColumnsWidth(
    columnWidthsByHeaderType
  );

  return config;
};

export const getTableConfig = ({
  variant
}: {
  variant: FilterListsTableVariant;
}): {
  tableProps: Omit<TableProps, 'mainList'>;
  filterListTypeGroupedListsBuilder?: {
    skipHeaders?: boolean;
  };
  variant: FilterListsTableVariant;
  isFilterListsExpandedByDefault?: boolean;
  getFilterListTypeSelectorKey?: (
    filterListType?: FilterListType
  ) => FilterField | undefined;
} => {
  const configs = {
    SideFilter: makeSideFilterConfig(),
    StackedFilters: makeStackedFilterConfig(),
    shared: {
      variant,
      tableProps: {
        tableColumns: [
          leftPaddingColumn,
          tableWidthColumn,
          innerColumn,
          selectColumn,
          labelColumn,
          rightPaddingColumn
        ],
        rowTypeToCellsByHeaderType: {
          [ROW_TYPES.defaultFilterListTypeHeaderRow]:
            defaultFilterListTypeHeaderRowCells,
          [ROW_TYPES.defaultFilterItemRow]: defaultFilterItemRowCells,
          [ROW_TYPES.defaultGroupedFilterItemRow]:
            defaultGroupedFilterItemRowCells,
          [ROW_TYPES.defaultFilterGroupHeaderRow]:
            defaultFilterGroupHeaderRowCells,
          [ROW_TYPES.showArchivedRow]: showArchivedRowCells,
          [ROW_TYPES.showHiddenItemsRow]: showHiddenItemsRowCells,
          [ROW_TYPES.addEntityRow]: addEntityRowCells,
          [ROW_TYPES.loadingRow]: loadingRowCells,
          [ROW_TYPES.skeletonLoaderRow]: skeletonLoaderRowCells,
          [ROW_TYPES.noResultsRow]: noResultsRowCells,
          [ROW_TYPES.searchInputRow]: searchInputRowCells,
          [ROW_TYPES.batchActionsRow]: batchActionsRowCells,
          [ROW_TYPES.filterListTypeSelectorRow]: filterListTypeSelectorRowCells,
          [ROW_TYPES.skillHeaderRow]: skillHeaderRowCells
        },
        headersToIgnore: ['tableWidth'],
        rowTypesHash: ROW_TYPES,
        maxHeight: window.innerHeight,
        showHeader: false,
        hasStickyHeader: true
      }
    }
  };

  const config = configs[variant];

  // to avoid 'property does not exist' error
  // eslint-disable-next-line dot-notation
  config.tableProps['totalColumnsWidthOverride'] =
    (config.tableProps.columnWidthsByHeaderType.tableWidth || 0) +
    (config.tableProps.columnWidthsByHeaderType.rightPadding || 0);

  // in the case of conflicting properties, config takes precedence
  const mergedConfig = merge({}, configs.shared, config);

  return mergedConfig;
};
