import { PTOsTableRowData } from '../types';
import styled from 'styled-components';
import { rebuildTooltip, defaultTooltipProps } from 'appUtils/tooltipUtils';
import { useEffect } from 'react';

interface RegionCellProps {
  row: {
    original: {
      rowData: {
        data: PTOsTableRowData;
      };
    };
  };
}

export const RegionCell = (props: RegionCellProps) => {
  const { regions } = props.row.original.rowData.data;
  const isShowTooltip = regions.length > 1;
  const tooltipContent = isShowTooltip ? regions.slice(1).join(', ') : '';
  const trailingContent = isShowTooltip
    ? `, +${regions.length - 1}`
    : undefined;

  useEffect(() => {
    rebuildTooltip();
  }, []);

  if (!regions.length) return null;

  return (
    <RootContainer>
      <RegionName>{regions[0]}</RegionName>
      <OverflowText {...defaultTooltipProps} data-tip={tooltipContent}>
        {trailingContent}
      </OverflowText>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const BaseText = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.colorMediumGray9};
`;

const RegionName = styled(BaseText)`
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  max-width: 80%;
`;

const OverflowText = styled(BaseText)`
  max-width: 20%;
`;
