import * as constants from 'appConstants';
import { ACTION_TYPES } from 'appConstants/actionDescription';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import * as noteConstants from 'NoteModule/constants';

const mergeNotifications = (stateNotifications, newNotifications) => {
  const shouldMerge =
    !!stateNotifications.length &&
    !!newNotifications.length &&
    stateNotifications[stateNotifications.length - 1].date ===
      newNotifications[0].date;

  if (shouldMerge) {
    const oldNotifications = cloneDeep(stateNotifications);
    const lastDay = oldNotifications[oldNotifications.length - 1];
    lastDay.notifications = [
      ...lastDay.notifications,
      ...newNotifications[0].notifications
    ];
    const restOfDays = newNotifications.slice(1);
    return [...oldNotifications, ...restOfDays];
  } else {
    return [...stateNotifications, ...newNotifications];
  }
};

const markNotificationsAsOld = (allNotifications) =>
  allNotifications.map((day) => ({
    ...day,
    notifications: day.notifications.map((notification) => ({
      ...notification,
      is_new: false
    }))
  }));

const containsNote = (notificationsArray, noteId) => {
  return notificationsArray.find(
    (notification) =>
      notification.actionable_id === noteId &&
      notification.action_type === ACTION_TYPES.NOTE_CREATION
  );
};

const replaceNote = (notificationsArray, noteId, updatedNote) => {
  return notificationsArray.map((notification) => {
    const isNote = notification.action_type === ACTION_TYPES.NOTE_CREATION;
    if (isNote && notification.actionable_id === noteId) {
      return {
        ...notification,
        display_data: {
          ...notification.display_data,
          ...updatedNote
        }
      };
    } else {
      return notification;
    }
  });
};

const removeNote = (notificationsArray, noteId) => {
  return notificationsArray.filter((notification) => {
    const isNote = notification.action_type === ACTION_TYPES.NOTE_CREATION;
    return !(isNote && noteId === notification.actionable_id);
  });
};

export const initialFilterState = {
  isLoading: false,
  unreadCountsList: [],
  totalUnreadCount: 0
};

export const initialState = {
  isLoading: null,
  allNotifications: [],
  loadedNotificationIds: [],
  unreadCount: 0,
  error: '',
  loadedCount: 0,
  notificationsEndReached: false,
  showUpdatesMovedMessage: false,
  filterStates: {}
};
const notifications = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_NOTIFICATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: '',
        notificationsEndReached: false
      };
    }
    case constants.FETCH_NOTIFICATIONS.SUCCESS: {
      const newNotificationIds = flatten(
        payload.response.all_notifications.map((notificationDayObj) =>
          notificationDayObj.notifications
            .filter((notification) => notification.is_new === true)
            .map((notification) => notification.id)
        )
      );

      return {
        ...state,
        isLoading: false,
        allNotifications: mergeNotifications(
          state.allNotifications,
          payload.response.all_notifications
        ),
        loadedNotificationIds: [
          ...state.loadedNotificationIds,
          ...newNotificationIds
        ],
        loadedCount: state.loadedCount + payload.response.newly_loaded_count,
        notificationsEndReached: payload.response.all_notifications.length === 0
      };
    }
    case constants.FETCH_NOTIFICATIONS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    }

    case constants.FETCH_NOTIFICATIONS_COUNT.TRIGGER: {
      const { filterStateId } = payload;
      if (filterStateId) {
        const initial = !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(initial
                ? initialFilterState
                : state.filterStates[filterStateId]),
              isLoading: true
            }
          }
        };
      }
      return state;
    }
    case constants.FETCH_NOTIFICATIONS_COUNT.FAILURE: {
      const { filterStateId } = payload.requestPayload;
      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isLoading: false
            }
          }
        };
      }
      return state;
    }
    case constants.FETCH_NOTIFICATIONS_COUNT.SUCCESS: {
      const {
        response,
        requestPayload: { filterStateId, groupByProjects }
      } = payload;

      if (filterStateId) {
        const nextFilterState = { ...state.filterStates[filterStateId] };
        if (groupByProjects) {
          nextFilterState.isLoading = false;
          nextFilterState.unreadCountsList = response;
          nextFilterState.totalUnreadCount = response.reduce((acc, cur) => {
            return (acc += cur.unread_notifications_count || 0);
          }, 0);
        } else {
          nextFilterState.isLoading = false;
          nextFilterState.totalUnreadCount = response;
        }

        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: nextFilterState
          }
        };
      }
      return {
        ...state,
        unreadCount: response
      };
    }
    case constants.UPDATE_NOTIFICATION_AS_READ.REQUEST: {
      return state;
    }
    case constants.UPDATE_NOTIFICATION_AS_READ.SUCCESS: {
      const { response } = payload;
      const newId = response.id;
      const day = moment(response.timestamp).format('YYYY-MM-DD');
      const dayIndex = state.allNotifications.findIndex(
        (notificationObj) => notificationObj.date === day
      );
      const newAllNotifications = [...state.allNotifications];
      const clonedDay = cloneDeep(newAllNotifications[dayIndex]);
      const notificationIndex = clonedDay.notifications.findIndex(
        (notification) => notification.id === newId
      );

      // Setting after cloning
      clonedDay.notifications[notificationIndex] = response;
      newAllNotifications[dayIndex] = clonedDay;

      return {
        ...state,
        allNotifications: [...newAllNotifications]
      };
    }
    case constants.UPDATE_NOTIFICATION_AS_READ.FAILURE: {
      return state;
    }

    case constants.UPDATE_NOTIFICATIONS_AS_OLD.SUCCESS: {
      const oldAllNotifications = [...state.allNotifications];
      const newCount = state.unreadCount - payload.response;
      return {
        ...state,
        allNotifications: markNotificationsAsOld(state.allNotifications),
        unreadCount: newCount < 0 ? 0 : newCount
      };
    }

    case noteConstants.NOTE_UPDATE.TRIGGER: {
      const { noteId, title, body } = action.payload;
      const updatedNote = omitBy(
        {
          note_title: title,
          note_body: body
        },
        isUndefined
      );

      return {
        ...state,
        allNotifications: state.allNotifications.map((day) => {
          if (containsNote(day.notifications, noteId)) {
            return {
              ...day,
              notifications: replaceNote(day.notifications, noteId, updatedNote)
            };
          } else {
            return day;
          }
        })
      };
    }

    case noteConstants.DELETE_NOTE.TRIGGER: {
      const { noteId } = action.payload;
      return {
        ...state,
        allNotifications: state.allNotifications.map((day) => {
          if (containsNote(day.notifications, noteId)) {
            return {
              ...day,
              notifications: removeNote(day.notifications, noteId)
            };
          } else {
            return day;
          }
        })
      };
    }

    case constants.MARK_ALL_NOTIFICATIONS_READ.TRIGGER: {
      return {
        ...state,
        unreadCount: 0,
        allNotifications: state.allNotifications.map((day) => ({
          ...day,
          notifications: day.notifications.map((notification) => ({
            ...cloneDeep(notification),
            is_read: true
          }))
        }))
      };
    }

    case constants.SET_SHOW_UPDATES_MOVED_MESSAGE: {
      const { showMessage } = payload;
      return {
        ...state,
        showUpdatesMovedMessage: showMessage
      };
    }

    default: {
      return state;
    }
  }
};

export default notifications;
