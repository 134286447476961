import styled from 'styled-components';
import theme from 'theme';

export const FlyoutContainer = styled.div`
  position: ${(props) => (props.isInCentralModal ? '' : 'absolute')};
  background: white;
  box-shadow: 0px 2px 3px rgba(61, 61, 61, 0.45);
  /* needs to be on top of modals */
  z-index: 1051;
  ${(props) => (props.globalModal ? `margin: auto;` : '')}
  top: ${(props) => (props.top !== null ? props.top : '56px')};
  max-width: 450px;
  min-width: 250px;
  top: 40px;
  left: 264px;
  font-weight: 400;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.isWhite ? '#fff' : '#ececec')};
  padding: 10px 15px;
  font-size: 15px;
  ${(props) => (props.noMinWidth ? null : `min-width: 250px`)}
`;

export const HeaderCopy = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.colorPureBlack};
  width: 100%;
`;
export const StyledStickyRow = styled.div`
  position: sticky;
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  padding: 10px 15px;
  cursor: pointer;
`;
export const StyledSearch = styled.input`
  position: sticky;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
  width: 100%;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};
  &::placeholder {
    color: ${theme.colors.colorBudgetGrey};
    font-size: ${({ placeholderSize }) => placeholderSize || '14px'};
  }
  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid ${theme.colors.colorRoyalBlue};
  }
`;

export const CloseIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 30px;

  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray1};
    }
  }
`;

export const StyledFlyoutList = styled.div.attrs(
  ({ $listHeight, $minListHeight }) => ({
    style:
      $minListHeight !== undefined
        ? {
            maxHeight: $listHeight,
            minHeight: $minListHeight
          }
        : {
            height: $listHeight,
            maxHeight: $listHeight
          }
  })
)`
  flex: 1;
  position: relative;

  // For FlyoutList scrolling
  &.scrollbar {
    overflow-y: auto;
  }
`;

export const StyledListItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 13px;
  padding: 12px 5px;
  color: ${theme.colors.colorSemiDarkGray1};
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};
  &:last-child {
    border-bottom-color: transparent;
  }

  background: ${(props) =>
    props.isInactive
      ? theme.colors.colorTranslucentGray4
      : theme.colors.colorPureWhite};
  opacity: ${(props) => (props.isInactive ? 0.7 : 1)};

  &:hover {
    background: ${(props) =>
      props.unSelectable ? '' : theme.colors.colorTranslucentGray4};
    cursor: ${(props) => (props.unSelectable ? `default` : `pointer`)};

    .bulk-members-drop-down-add-text {
      visibility: visible;
      color: ${theme.colors.colorRoyalBlue};
      font-size: 12px;
      left: 320px;
      top: 14px;
    }
  }
  ${(props) => props.listItemContainerStyle}
  ${(props) =>
    props.isAlreadySelected &&
    `pointer-events: none;
    color: ${theme.colors.colorMediumGray1};
    `}
  ${(props) => props.firstOnetoBeAdded && `border-top: 1px solid #000;`}
  ${(props) =>
    props.isPhase &&
    `
    border-bottom: 1px solid ${theme.colors.colorTranslucentGray4};
  `}
`;

export const StyledFooter = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.isWhite ? '#fff' : theme.colors.colorIceBlue};
  border-top: 1px solid ${theme.colors.colorPaleGray5};
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  display: flex;
  flex: none;
  font-size: 13px;
  font-weight: 600;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  padding: 10px;
  width: 100%;

  ${(props) =>
    props.isSubmit &&
    `
    color: white;
    background: ${theme.colors.colorCalendarBlue};
  `}
`;

export const Button = styled.button`
  border: none;
  padding: 8px 25px;
  margin: 4px;
  cursor: pointer;
  border-radius: 5px;
`;
export const Cancel = styled(Button)`
  background-color: transparent;
  color: #a4a4a4;
`;

export const Confirm = styled(Button)`
  background-color: ${theme.colors.colorRoyalBlue};
  color: white;
  border: none;
`;

export const Left = styled.div``;
export const Right = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const StyledItemRowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  position: relative;
`;

export const PencilIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;

export const DragGripsContainer = styled.div`
  display: flex;
  align-items: center;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  margin-left: 5px;
  margin-top: 3px;
  width: 20px;
  height: 15px;
  justify-content: center;
`;

export const EmptyStateContainer = styled.div`
  padding: 12px;
  padding: 50px 20px;
  display: flex;
  margin: auto;
  width: 100%;
  white-space: pre-wrap;
  height: 100%;
  font-size: 13px;
  line-height: 1.3;
  color: ${theme.colors.colorMediumGray5};
  border-top: 1px solid #f0f0f0;
  .member-rate-phases-global & {
    border: none;
  }

  &.projects-then-phases-dropdown-empty-container {
    height: 340px;
  }

  &.team-rates-dropdown-empty-container {
    height: 200px;
  }
`;
