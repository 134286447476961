import React from 'react';
import { connect } from 'react-redux';
import { navigateUp, fetchScopes, handleErrorMessage } from 'actionCreators';
import { withRouter } from 'react-router-dom';
import withRouterParams from './WithRouterParams';
import {
  getMatchedRouteParams,
  getFetchedScopeIds,
  getScopeHash
} from 'selectors';
import ScopeModal from 'components/Scope/modals/create-edit/ScopeModal';
import ScopeModalNavigationTracker from 'views/Navigation/ScopeModalNavigationTracker';
import { GENERIC_ACTION } from 'appConstants';

class ScopeModalRouter extends React.Component {
  getScopeId = () => {
    return this.props.match.params.scopeId;
  };

  /**
   * Fetches modal scope if necessary
   */
  fetchScope = () => {
    const { fetchedScopes, fetchScopes } = this.props;
    const scopeId = this.getScopeId();
    if (scopeId !== undefined && !fetchedScopes[scopeId]) {
      fetchScopes({ id: scopeId });
    }
  };

  componentDidMount() {
    this.fetchScope();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      match.url === nextLocation.pathname;
    return shouldUpdate;
  }

  componentDidUpdate() {
    const { fetchedScopes, scopeHash, navigateUp, handleErrorMessage } =
      this.props;
    this.fetchScope();
    const scopeId = this.getScopeId();
    // close the modal if the scope does not exist
    if (!scopeId || (fetchedScopes[scopeId] && scopeHash[scopeId] === null)) {
      handleErrorMessage({
        type: GENERIC_ACTION,
        isFeError: true,
        errorMessage: 'Unable to find scope.'
      });
      navigateUp();
    }
  }

  render() {
    const {
      match,
      navigateUp,
      scopeHash,
      isProjectViewScope = false
    } = this.props;
    const scopeId = match.params.scopeId;
    const scope = scopeHash[scopeId];

    return (
      <>
        <ScopeModalNavigationTracker />
        {scope && (
          <ScopeModal
            scope={scope}
            toggle={() => navigateUp()}
            isProjectViewScope={isProjectViewScope}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  fetchedScopes: getFetchedScopeIds(state),
  scopeHash: getScopeHash(state)
});

const mapDispatchToProps = { navigateUp, fetchScopes, handleErrorMessage };

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ScopeModalRouter)),
  true
);
