import { DEPENDENCY_TYPES } from 'appConstants/workload';
import TaskDependencySetter from './TaskDependencySetter';
import PhaseDependencySetter from './PhaseDependencySetter';
import WorkplanDependencySetter from './WorkplanDependencySetter';
import { DependencySetterLabel, DependencyLabelContainer } from './styles';

const DependencySetter = (props) => {
  const { dependencyItemType } = props;
  const listItemContainerStyle = { 'border-bottom': '0px;' };

  const dependencySetterComponentByType = {
    [DEPENDENCY_TYPES.TASK]: TaskDependencySetter,
    [DEPENDENCY_TYPES.PHASE]: PhaseDependencySetter,
    [DEPENDENCY_TYPES.WORK_PLAN]: WorkplanDependencySetter
  };

  const DependencySetterComponent =
    dependencySetterComponentByType[dependencyItemType];

  if (!DependencySetterComponent) {
    return <></>;
  }

  return (
    <DependencyLabelContainer>
      <DependencySetterLabel>DEPENDENCY</DependencySetterLabel>

      <DependencySetterComponent
        {...props}
        listItemContainerStyle={listItemContainerStyle}
      />
    </DependencyLabelContainer>
  );
};

export default DependencySetter;
