import { put, select } from 'redux-saga/effects';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import { fetchEntity, changeEntity } from 'sagas/generics';
import {
  fetchRegions,
  createRegion,
  updateRegion,
  deleteRegion,
  updateRegionEntities
} from 'SettingsModule/actionCreators/settings/region';
import {
  fetchRegionsActionCreatorsMap,
  createRegionActionCreatorsMap,
  updateRegionActionCreatorsMap,
  deleteRegionActionCreatorsMap,
  updateRegionEntitiesActionCreatorsMap
} from 'SettingsModule/entityActions/region';
import * as actionCreators from 'actionCreators';
import * as api from 'SettingsModule/api/region';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { ValueOf } from 'type-fest';

export function* fetchRegionsWorker(action: ReturnType<typeof fetchRegions>) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    fetchRegionsActionCreatorsMap,
    api.fetchRegions,
    null,
    [token, { teamId }],
    action
  );
}

export function* createRegionWorker(action: ReturnType<typeof createRegion>) {
  const { teamId, name } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    createRegionActionCreatorsMap,
    api.createRegion,
    [token, { teamId, name }],
    action
  );
}

export function* updateRegionWorker(action: ReturnType<typeof updateRegion>) {
  const { id, name } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    updateRegionActionCreatorsMap,
    api.updateRegion,
    [token, { id, name }],
    action
  );
}

export function* deleteRegionWorker(action: ReturnType<typeof deleteRegion>) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  yield changeEntity(
    deleteRegionActionCreatorsMap,
    api.deleteRegion,
    [token, { id }],
    action
  );

  yield put(fetchRegions({ teamId }));
}

export function* updateRegionEntitiesWorker(
  action: ReturnType<typeof updateRegionEntities>
) {
  const { id, addEntities, removeEntities } = action.payload;
  const token = yield select(getAuthToken);

  const { response } = yield changeEntity(
    updateRegionEntitiesActionCreatorsMap,
    api.updateRegionEntities,
    [token, { id, addEntities, removeEntities }],
    action
  );

  if (response) {
    const affectedEntitiesMap = [
      ...(addEntities ?? []),
      ...(removeEntities ?? [])
    ].reduce<Record<ValueOf<typeof ENTITY_TYPES>, number[]>>((acc, curr) => {
      if (!acc[curr.entityType]) {
        acc[curr.entityType] = [];
      }

      if (acc[curr.entityType]) {
        if (!acc[curr.entityType].includes(curr.entityId)) {
          acc[curr.entityType].push(curr.entityId);
        }
      }

      return acc;
    }, {} as Record<ValueOf<typeof ENTITY_TYPES>, number[]>);

    yield Object.entries(affectedEntitiesMap).flatMap(
      ([entityType, entityIds]) =>
        entityType === ENTITY_TYPES.project
          ? [
              put(actionCreators.fetchAllProjects({ projectIds: [entityIds] })),
              ...entityIds.map((id) => put(actionCreators.fetchProjectById(id)))
            ]
          : []
    );
  }
}
