import styled from 'styled-components';

import NewOrganization from 'icons/new-organization.svg';
import NewProject from 'icons/new-project.svg';
import NewTask from 'icons/new-task.svg';
import NewTeamMember from 'icons/new-team-member.svg';
import NewTeam from 'icons/new-team.svg';
import plus from 'icons/plus.svg';
import plusBlue from 'icons/plusblue.svg';

export const FlyoutMenuWrapper = styled.div`
  position: absolute;
  right: -98px;
  background-color: ${({ theme }) => ` ${theme.colors.colorPureWhite}`};
  min-width: 190px;
  top: 17px;
  padding: 0px;
  z-index: 110;
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .project-menu-item-container {
    width: 100%;
  }

  .project-menu-item {
    font-size: 14px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.colorSemiDarkGray3};
    height: 34px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) => theme.colors.colorTranslucentGray4};
    }
  }
`;

export const Icon = styled.div`
  height: 24px;
  width: 24px;
`;

export const IconContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  .show-on-hover {
    display: none;
  }
  &:hover {
    .hide-on-hover {
      display: none;
    }
    .show-on-hover {
      display: unset;
    }
  }
`;

export const TeamMemberIcon = styled(Icon)`
  margin-left: 4px;
  background: url(${NewTeamMember}) no-repeat center;
`;

export const TaskIcon = styled(Icon)`
  margin-left: -2px;
  height: 26px;
  width: 26px;
  background: url(${NewTask}) no-repeat center;
`;
export const ProjectIcon = styled(Icon)`
  margin-top: 3px;
  background: url(${NewProject}) no-repeat center;
`;
export const TeamIcon = styled(Icon)`
  background: url(${NewTeam}) no-repeat center;
`;

export const StyledPlus = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: solid 1px transparent;
  background: url(${plus}) no-repeat center;
  color: ${(props) => props.theme.colors.colorSemiDarkGray1};
  pointer-events: initial;
  cursor: pointer;
  transition: all 0.275s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  position: relative;

  &:hover {
    border: solid 1px transparent;
    background: url(${plusBlue}) no-repeat center;
    background-color: rgba(0, 0, 0, 0.03);
  }
`;
