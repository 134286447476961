import { reducerMap } from '../reducers';
import settingsSaga from '../sagas';

export function getSettingsModule() {
  return {
    id: 'settings',
    reducerMap,
    sagas: [settingsSaga]
    // Actions to fire when this module is added/removed
    //initialActions: []
    // finalActions: []
  };
}
