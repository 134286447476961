import styled from 'styled-components';
import cn from 'classnames';
import theme from 'theme';
import MemberInitials, {
  StyledMemberCircle
} from 'views/memberDisplay/MemberInitials';
import { useDispatch, useSelector } from 'react-redux';

import { openWorkloadModal } from 'actionCreators';
import { getAccountCapacities } from 'CapacityModule/selectors';

import {
  CAPACITY_TYPES,
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY
} from 'appConstants/workload';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { MemberThreeDotMenu } from 'components/MemberThreeDotMenu';
import { getTeamMembersHash } from 'selectors';

import useCurrentRoles from 'BudgetModule/hooks/useCurrentRoles';
import { getMemberAttributesTooltip } from 'appUtils/memberTooltipContent';

const StyledCapacity = styled.div`
  color: ${theme.colors.colorMediumGray5};
  line-height: 1;
  font-size: 11px;
  position: absolute;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
const StyledNameContainer = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
const TeamMemberCell = styled.div`
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    ${StyledCapacity} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  ${StyledMemberCircle} {
    height: 32px;
    width: 32px;
    flex: 0 0 32px;
  }

  .member-three-dot-container {
    position: relative;
    left: -15px;
    width: 14px;
  }
`;

const ArchivedTextContainer = styled.div`
  color: ${theme.colors.colorMediumGray7};
  line-height: 1.5;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  top: -10px;
  padding: 5px;
  padding-left: 0;
`;

const emptyObj = {};

const MemberCell = ({ cell, row }) => {
  const { account, currentUserId } = row.original;
  const dispatch = useDispatch();
  const accountCapacities = useSelector(getAccountCapacities);
  const teamMembersHash = useSelector(getTeamMembersHash);
  const isArchived = teamMembersHash[account.id]?.is_archived;

  const name = `${account.name}`;
  const capacity = accountCapacities[account.id] || emptyObj;
  const capacityTotal = capacity
    ? CAPACITY_DATE_KEYS.reduce((total, day) => total + capacity[day], 0)
    : DEFAULT_WEEKLY_CAPACITY;

  const formattedCapacityTotal = formatNumWithMaxTwoDecimals(capacityTotal);

  const { teamRoleName } = useCurrentRoles({
    accountId: account.id
  });

  return (
    <TeamMemberCell
      className="team-member-cell app-cues-team-member-cell"
      data-for="app-tooltip"
      data-place="top"
      data-class="w-180px"
      data-type="light"
      data-effect="solid"
      data-html
      data-tip={getMemberAttributesTooltip({
        name,
        teamRoleName,
        totalCapacity: formattedCapacityTotal
      })}
    >
      <MemberThreeDotMenu
        accountId={account.id}
        options={['memberProfile', 'workSchedule']}
      />
      <MemberInitials
        member={account}
        classes={cn('item-account selected', {
          'logged-member-no-hover': account.id === currentUserId,
          'regular-member-no-hover': account.id !== currentUserId
        })}
        idx={'idx-placeholder'}
        isOnTaskModal
      />
      <StyledNameContainer
        className="app-cues-styled-name-container"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(
            openWorkloadModal({
              id: account.id,
              type: CAPACITY_TYPES.ACCOUNT
            })
          );
        }}
      >
        {isArchived && <ArchivedTextContainer>ARCHIVED</ArchivedTextContainer>}
        <StyledName>{name}</StyledName>
        <StyledCapacity className="app-cues-styled-capacity">
          {Number.isNaN(capacityTotal)
            ? null
            : `${formattedCapacityTotal} h/wk`}
        </StyledCapacity>
      </StyledNameContainer>
    </TeamMemberCell>
  );
};
export default MemberCell;
