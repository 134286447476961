import { DailyCapacity } from 'models/accountCapacity';
import { Activity } from 'models/activity';
import { Project } from 'ProjectsModule/models/project';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';
import { TIMELINE_ITEM_TYPES } from 'TimelinesModule/components/constants';
import { GroupId } from 'TimelinesModule/types/baseGroup';
import { WorkPlanItem } from 'TimelinesModule/types/WorkPlanItem';
import {
  getDateStringsFromBars,
  getWorkdayPercent,
  isTentativePlan
} from 'views/projectPlanner/utils';
import { WorkPlan } from 'views/projectPlanner/WorkplanModal/models/workPlan';
import round from 'lodash/round';
import {
  hasStartDateDependency,
  hasEndDateDependency
} from 'appUtils/newDependencies';
import { serializeId } from 'appUtils';
import { ItemId } from 'TimelinesModule/types/baseItem';
import parse from 'date-fns/parse';
import { DATE_FNS_USA_DATE } from 'appConstants/date';

type PartialWorkPlan = Pick<
  WorkPlan,
  | 'id'
  | 'account_id'
  | 'member_budget_id'
  | 'start_date'
  | 'end_date'
  | 'bars'
  | 'daily_hours'
  | 'total_hours'
  | 'start_time'
  | 'start_time'
  | 'end_time'
  | 'project_id'
  | 'budget_status'
  | 'description'
  | 'all_day'
  | 'lock_hour'
  | 'dependencies'
>;

type PartialProject = Pick<Project, 'title' | 'description'>;

type PartialPhaseWithDefault = Pick<
  PhaseWithDefault,
  'is_like_default' | 'name'
>;
type PartialActivity = Pick<Activity, 'is_default' | 'title'>;

const serializeItemId = ({
  id,
  groupId,
  itemType
}: {
  id: ItemId;
  groupId: GroupId;
  itemType: TIMELINE_ITEM_TYPES;
}) =>
  serializeId({
    id: undefined,
    itemType,
    ids: [id, groupId]
  });

export const mapWorkPlanToWorkPlanItem = ({
  groupId,
  workPlan,
  project,
  phase,
  activity,
  averageCapacity,
  dailyCapacity
}: {
  groupId: GroupId;
  workPlan: PartialWorkPlan;
  project?: PartialProject;
  phase?: PartialPhaseWithDefault;
  activity?: PartialActivity;
  averageCapacity?: number;
  dailyCapacity?: DailyCapacity;
}): WorkPlanItem => {
  const itemId = serializeItemId({
    id: workPlan.id,
    groupId,
    itemType: TIMELINE_ITEM_TYPES.WORKPLAN
  });

  const {
    id,
    start_date,
    end_date,
    bars,
    daily_hours,
    total_hours,
    start_time,
    end_time,
    project_id,
    budget_status,
    description,
    all_day,
    lock_hour
  } = workPlan;

  const phaseName = phase?.is_like_default ? undefined : phase?.name;
  const workCategoryName = activity?.is_default ? undefined : activity?.title;

  const totalDayCount = bars.reduce((acc, bar) => acc + bar.day_count, 0);

  const baseWorkPlanItem: WorkPlanItem = {
    id: itemId,
    workPlanId: id,
    title: '',
    type: TIMELINE_ITEM_TYPES.WORKPLAN,
    group: groupId,
    start_time: parse(start_date, DATE_FNS_USA_DATE, new Date()).getTime(),
    end_time: parse(end_date, DATE_FNS_USA_DATE, new Date()).getTime(),
    bars,
    startTime: start_time ?? undefined,
    endTime: end_time ?? undefined,
    isTentativePlan: isTentativePlan(budget_status),
    hasDescription: Boolean(description),
    allDay: all_day,
    totalDayCount,
    hasLockHour: lock_hour,
    averageCapacity,
    hasStartDependency: hasStartDateDependency(workPlan.dependencies),
    hasEndDependency: hasEndDateDependency(workPlan.dependencies),
    projectId: project_id,
    projectName: project?.title,
    projectDescription: project?.description,
    phaseName,
    workCategoryName
  };

  if (all_day) {
    const dailyHours = averageCapacity;
    const totalHours = dailyHours ? dailyHours * totalDayCount : undefined;
    return {
      ...baseWorkPlanItem,
      workdayPercent: 100,
      hasLastDayRemainder: false,
      dailyHours,
      totalHours
    };
  }

  // daily_hours and total_hours are ever be NaN
  const dailyHours = Number(daily_hours);
  const totalHours = Number(total_hours);
  const lastDayRemainder = !dailyHours ? 0 : round(totalHours % dailyHours, 2);

  const workdayPercent = dailyCapacity
    ? getWorkdayPercent({
        dailyHours: Number(daily_hours),
        dailyCapacity,
        datesArray: getDateStringsFromBars(bars)
      })
    : undefined;

  return {
    ...baseWorkPlanItem,
    workdayPercent,
    hasLastDayRemainder: lastDayRemainder > 0,
    dailyHours,
    totalHours
  };
};
