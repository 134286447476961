import { CSSProperties } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import OpenFilterMenuIcon from 'icons/OpenFilterMenuIcon';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import {
  FilterListType,
  filterListTypeToEntityLabel
} from 'FilterModule/constants';
import { useFilterData } from 'FilterModule/hooks';

interface OpenSideFilterOptionProps {
  customLabel?: string;
  style?: CSSProperties;
}

/**
 * Button for triggering the side filter
 * Handles labeling the button using currentFilter.mainFilterListType
 */
export const OpenSideFilterOption = ({
  customLabel,
  style
}: OpenSideFilterOptionProps) => {
  const {
    mainFilterListType,
    mainFilterListNumSelected,
    toggleIsSideFilterOpen
  } = useFilterContext();

  const { filterValuesHash } = useFilterData({
    getIsFilterUsed: (filterListType: FilterListType) => {
      return mainFilterListType === filterListType;
    }
  });

  const singularLabel = mainFilterListType
    ? filterListTypeToEntityLabelOverride[mainFilterListType] ||
      filterListTypeToEntityLabel[mainFilterListType]
    : undefined;

  if (!(mainFilterListType && singularLabel)) return null;

  const { onlySelectedItemLabel } = filterValuesHash[mainFilterListType];

  const entityLabel =
    mainFilterListNumSelected === 1 && singularLabel
      ? singularLabel
      : singularLabel + 's';

  const label =
    customLabel ??
    (onlySelectedItemLabel ||
      (mainFilterListNumSelected === 0
        ? `Select ${entityLabel}`
        : `${mainFilterListNumSelected} ${entityLabel}`));

  return (
    <StyledOpenSideFilterButton
      onClick={toggleIsSideFilterOpen}
      className="open-side-filter-button"
      style={style}
    >
      <OpenFilterMenuIcon />
      <span data-text={label}>{label}</span>
    </StyledOpenSideFilterButton>
  );
};

/* ------------------------------------ - ----------------------------------- */

const memberLabel = 'Member';

const filterListTypeToEntityLabelOverride = {
  [FilterListType.Members]: memberLabel,
  [FilterListType.MembersByDepartment]: memberLabel,
  [FilterListType.MembersByPortfolio]: memberLabel,
  [FilterListType.MembersByPosition]: memberLabel,
  [FilterListType.MembersByRegion]: memberLabel,
  [FilterListType.MembersBySkill]: memberLabel,
  [FilterListType.MembersByOffice]: memberLabel,
  [FilterListType.MembersByDiscipline]: memberLabel
};

const StyledOpenSideFilterButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${theme.colors.colorRoyalBlue};
  text-align: center;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
  span {
    text-transform: capitalize;
  }
  // Keeps text in place on font-weight change
  span:after {
    display: block;
    content: attr(data-text);
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
  svg {
    margin-right: 5px;
  }
`;
