import { put, select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import {
  getMyProjectsLength,
  getAuthToken,
  getMyUserId,
  getTimerDate,
  getSelectedTeamId,
  getProjectHash
} from 'selectors';
import {
  fetchCheckins,
  fetchMyProjects as fetchMyProjectsActionCreator,
  fetchTimers,
  fetchActiveTimer,
  fetchCheckinsHours,
  handleErrorMessage
} from 'actionCreators';
import moment from 'moment';
import { isToday } from 'appUtils/momentUtils';
import { GENERIC_ACTION } from 'appConstants';

const {
  fetchMyProjects,
  fetchAllProjects,
  myProjectUpdate,
  generateReport,
  checkinsHoursFetch,
  bulkCheckinCreate,
  checkinCreate,
  checkinDelete,
  checkinUpdate,
  checkinsFetch,
  timersFetch,
  activeTimerFetch,
  timerStart,
  timerEnd,
  timerUpdate,
  timerDelete,
  taskAssociate
} = entityActions;

export function* fetchMyActiveProjects(action) {
  const token = action.payload.token;
  const { params } = action.payload;
  let searchText = '';
  let limit = 30;
  let offset = 0;

  if (params) {
    searchText = params.searchText || '';
    limit = params.limit || 30;
    offset = params.offset || 0;
  }

  yield fetchEntity(
    fetchMyProjects,
    api.fetchMyProjectsAPI,
    undefined,
    [token, searchText, limit, offset],
    action
  );
}
export function* fetchAllActiveProjects(action) {
  const token = yield select(getAuthToken);
  const { params } = action.payload;
  const onSuccess = params.onSuccess || [];
  let searchText = '';
  let limit = 30;
  let offset = 0;
  let board_ids = [];
  let project_ids = [];
  let is_archived = null;
  let is_administrative = null;
  let account_ids = [];
  let all = false;
  if (params) {
    searchText = params.searchText || '';
    limit = params.limit || 30;
    offset = params.offset || 0;
    board_ids = params.board_ids || [];
    project_ids = params.projectIds || [];
    account_ids =
      (params.accountIds?.length ? params.accountIds : params.account_ids) ||
      [];
    all = params.all;
    if (params.is_archived !== undefined) {
      is_archived = params.is_archived;
    }
    if (params.is_administrative !== undefined) {
      is_administrative = params.is_administrative;
    }
  }

  const { error, response } = yield fetchEntity(
    fetchAllProjects,
    api.fetchAllProjectsAPI,
    undefined,
    [
      token,
      offset,
      limit,
      searchText,
      board_ids,
      project_ids,
      account_ids,
      is_archived,
      is_administrative,
      all,
      params.flexible_filter,
      params.is_personal
    ],
    action
  );

  if (onSuccess.length && !error && response) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* fetchProjectsIndexV2(action) {
  const token = yield select(getAuthToken);
  const { body, onSuccess = [] } = action.payload;

  const { error, response } = yield fetchEntity(
    entityActions.fetchProjectsIndexV2,
    api.fetchProjectsIndexV2,
    undefined,
    [token, body],
    action
  );

  if (onSuccess.length && !error && response) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* updateMyProject(action) {
  const { params } = action.payload;
  const { myProjectId, position, token, starred, fromDragAndDrop } = params;
  const myProjectsLimit = yield select(getMyProjectsLength);

  const { error } = yield changeEntity(myProjectUpdate, api.putMyProjectAPI, [
    token,
    myProjectId,
    position,
    starred
  ]);

  if (!error && !fromDragAndDrop) {
    yield put(
      fetchMyProjectsActionCreator(token, { limit: myProjectsLimit, offset: 0 })
    );
  }
}

export function* generateReportWorker(action) {
  const token = yield select(getAuthToken);

  const { error } = yield changeEntity(
    generateReport,
    api.generateReport,
    [token, action.payload],
    action
  );
}

export function* fetchCheckinsHoursWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    checkinsHoursFetch,
    api.fetchCheckinsHours,
    undefined,
    [token, body],
    action
  );
}

export function* bulkCreateCheckinWorker(action) {
  const { payload } = action;
  const { date } = payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getMyUserId);

  const { error, response } = yield changeEntity(
    bulkCheckinCreate,
    api.bulkCreateCheckin,
    [token, null, payload],
    action
  );
  if (!error) {
    yield put(fetchCheckins({ body: { date: date, account_ids: [myId] } }));
  }
}

export function* createCheckinWorker(action) {
  const { payload } = action;
  const { isCopy, date } = payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getMyUserId);

  const { error, response } = yield changeEntity(
    checkinCreate,
    api.createCheckin,
    [token, null, payload],
    action
  );
  if (!error && !isCopy) {
    yield put(
      fetchCheckins({
        body: { date: date, account_ids: [myId] },
        filterStateId: payload.filterStateId
      })
    );
  }
}

export function* deleteCheckinWorker(action) {
  const { id, date, filterStateId } = action.payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getMyUserId);
  const { error, response } = yield changeEntity(
    checkinDelete,
    api.deleteCheckin,
    [token, id],
    action
  );
  if (!error) {
    yield put(
      fetchCheckins({
        body: { date: date, account_ids: [myId] },
        filterStateId
      })
    );
  }
}

export function* updateCheckinWorker(action) {
  const { id, date, filterStateId, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getMyUserId);

  const { error, response } = yield changeEntity(
    checkinUpdate,
    api.updateCheckin,
    [token, id, payload],
    action
  );
  if (!error) {
    yield put(
      fetchCheckins({
        body: { date: date, account_ids: [myId] },
        filterStateId
      })
    );
  }
}

export function* fetchCheckinsWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    checkinsFetch,
    api.fetchCheckins,
    undefined,
    [token, body],
    action
  );
}

export function* fetchTimersWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    timersFetch,
    api.fetchTimers,
    undefined,
    [token, body],
    action
  );
}

export function* fetchActiveTimerWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    activeTimerFetch,
    api.fetchActiveTimer,
    undefined,
    [token, body],
    action
  );
}

export function* startTimerWorker(action) {
  const { id, project_id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getMyUserId);
  const teamId = yield select(getSelectedTeamId);
  const unformattedTimerDate = yield select(getTimerDate);
  const projectHash = yield select(getProjectHash);
  const project = projectHash[project_id];
  const timerDate = moment(unformattedTimerDate);

  const { error, response } = yield changeEntity(
    timerStart,
    api.startTimer,
    [token, id, project_id, payload],
    action
  );
  if (!error) {
    if (isToday(timerDate)) {
      yield put(
        fetchCheckins({
          body: {
            date: timerDate.format('YYYY-MM-DD'),
            account_ids: [myId]
          },
          filterStateId: timerDate.format('YYYY-MM-DD')
        })
      );
      yield put(
        fetchCheckinsHours({
          body: {
            account_ids: [myId],
            offset: 0,
            limit: 50,
            team_id: teamId,
            start_date: timerDate.format('MM/DD/YYYY'),
            end_date: timerDate.format('MM/DD/YYYY')
          },
          initial: true,
          filterStateId: timerDate.format('YYYY-MM-DD')
        })
      );
      if (project) {
        yield put(
          handleErrorMessage({
            type: GENERIC_ACTION,
            isFeError: true,
            errorMessage: `${project.title} Timer started`
          })
        );
      }
    }
  }
}

export function* endTimerWorker(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    timerEnd,
    api.endTimer,
    [token, id, payload],
    action
  );
}

export function* updateTimerWorker(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);

  const { error } = yield changeEntity(
    timerUpdate,
    api.updateTimer,
    [token, id, payload],
    action
  );
}

export function* deleteTimerWorker(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    timerDelete,
    api.deleteTimer,
    [token, id, payload],
    action
  );
}

export function* associateTaskWorker(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    taskAssociate,
    api.associateTask,
    [token, id, payload],
    action
  );
}
