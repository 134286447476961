import 'isomorphic-fetch';
import { callQBAgentApi, callApi } from 'appUtils/api';

export const fetchSyncSettings = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`qb-sync-settings/${teamId}`, token, fetchInit);
};
export const updateSyncSettings = (token, teamId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(`qb-sync-settings/${teamId}/`, token, fetchInit);
};

export const fetchQbMembers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`qb-imported-employees/${teamId}`, token, fetchInit);
};
export const fetchQbServices = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`qb-imported-items/${teamId}`, token, fetchInit);
};

export const fetchImportedSubcustomers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`qb-imported-subcustomers/${teamId}`, token, fetchInit);
};

export const syncQbMembers = (token, teamId, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(
    `qb-select-employees-to-sync/${teamId}/`,
    token,
    fetchInit
  );
};

export const syncSubcustomersToProjects = (token, teamId, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(
    `qb-map-subcustomers-to-projects/${teamId}/`,
    token,
    fetchInit
  );
};

export const syncTimeEntries = (token, teamId, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(
    `qb-select-time-entries-to-import/${teamId}/`,
    token,
    fetchInit
  );
};

export const syncServices = (token, teamId, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(
    `qb-map-items-to-activity-codes/${teamId}/`,
    token,
    fetchInit
  );
};

export const fetchSyncedMembers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`employee-mapping/${teamId}`, token, fetchInit);
};

export const fetchSyncedSubcustomers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`subcustomer-mapping/${teamId}`, token, fetchInit);
};

export const fetchSyncedServices = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`item-mapping/${teamId}`, token, fetchInit);
};

export const updateSyncStatus = (token, teamId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(`qb-sync-status/${teamId}/`, token, fetchInit);
};

export const deleteSyncedMembers = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`employee-mapping/${teamId}/`, token, fetchInit);
};

export const deleteSyncedSubcustomers = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`subcustomer-mapping/${teamId}/`, token, fetchInit);
};

export const deleteSyncedServices = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`item-mapping/${teamId}/`, token, fetchInit);
};

export const fetchSubSubCustomers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`qb-imported-phases/${teamId}`, token, fetchInit);
};

export const fetchSyncedSubSubCustomers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`phase-mapping/${teamId}`, token, fetchInit);
};

export const fetchCustomers = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`qb-imported-customers/${teamId}`, token, fetchInit);
};

export const syncSubSubCustomers = (token, teamId, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callQBAgentApi(
    `qb-map-phases-to-mos-phases/${teamId}/`,
    token,
    fetchInit
  );
};

export const createActivity = (token, title, requireTitle) => {
  const body = {
    title,
    require_title: requireTitle
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`activities`, token, fetchInit);
};

export const fetchSyncedSubcustomer = (teamId, subcustomerId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(
    `subcustomer-mapping/${teamId}/${subcustomerId}/`,
    token,
    fetchInit
  );
};

export const undoPendingActivities = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(
    `item-mapping/${teamId}/pending/undo/`,
    token,
    fetchInit
  );
};

export const undoPendingSubcustomers = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(
    `subcustomer-mapping/${teamId}/pending/undo/`,
    token,
    fetchInit
  );
};

export const undoPendingMembers = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(
    `employee-mapping/${teamId}/pending/undo/`,
    token,
    fetchInit
  );
};

export const undoPendingPhases = (token, teamId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(
    `phase-mapping/${teamId}/pending/undo/`,
    token,
    fetchInit
  );
};

export const deletePhaseMapping = (token, teamId, phaseId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(
    `phase-mapping/${teamId}/${phaseId}/`,
    token,
    fetchInit
  );
};
