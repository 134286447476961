import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHook } from 'FilterModule/types';
import { useBooleanFilterField } from './useBooleanFilterField';

const emptyObj = {};

export const useDuplicateCheckFilter = ({
  field = FilterField.isDuplicates,
  shouldUseDraft
}: Parameters<FilterListTypeHook>[0] = emptyObj) => {
  const booleanFilterFieldValues = useBooleanFilterField({
    field,
    items: optionsArray,
    shouldUseDraft
  });

  return {
    ...booleanFilterFieldValues,
    optionsArray
  };
};

/* ------------------------------------ - ----------------------------------- */

const optionsArray = ['Show Duplicates'];
