import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { Scope } from '@sentry/core';

// sanitize data only on prod
const shouldSanitizeData = process.env.UI_ENV === 'prod';

// initLogRocket only on non-staging production build
const isValidEnv =
  process.env.NODE_ENV === 'production' && process.env.UI_ENV !== 'staging';

const initLogRocket = () => {
  if (isValidEnv) {
    LogRocket.init('djgmqk/mosaicapptest', {
      dom: {
        textSanitizer: shouldSanitizeData,
        inputSanitizer: shouldSanitizeData
      },
      network: {
        requestSanitizer: (request) => {
          if (shouldSanitizeData) {
            request.body = undefined;
          }
          return request;
        },
        responseSanitizer: (response) => {
          if (shouldSanitizeData) {
            response.body = undefined;
          }
          return response;
        }
      }
    });
    setupLogRocketReact(LogRocket);
  }
};

const initLogRocketMiddleware = (reduxMiddleware) => {
  if (isValidEnv) {
    reduxMiddleware.push(
      LogRocket.reduxMiddleware({
        stateSanitizer: (state) => {
          // it is return nothing to exclude any private data
          if (shouldSanitizeData) {
            return {};
          }
          return state;
        }
      })
    );
  }
};

const configureLogRocketOnSentry = (sentryScope: Scope) => {
  if (isValidEnv && sentryScope) {
    LogRocket.getSessionURL((sessionURL) => {
      sentryScope.setExtra('sessionURL', sessionURL);
    });
  }
};

export { initLogRocket, initLogRocketMiddleware, configureLogRocketOnSentry };
