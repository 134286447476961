import React from 'react';
import cn from 'classnames';
import FolderIcon from 'icons/FolderIcon';
import styled from 'styled-components';
import PencilIcon from 'icons/PencilIcon';
import {
  calculateBoardColorPreference,
  calculateBoardBorderColorWithOpacity
} from 'appUtils/styleUtils';

const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 8px;
  margin-left: 6px;
  width: 26px;
  height: 18px;
  fill: ${calculateBoardColorPreference};
  path {
    stroke: ${calculateBoardBorderColorWithOpacity};
    stroke-width: 2;
  }
`;

const StyledPencilIcon = styled(PencilIcon)`
  margin-left: 3px;
  height: 12px;
  width: 11px;
`;

const TitleInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 15px;
  color: inherit;
  line-height: 15px;
  text-overflow: ellipsis;

  cursor: ${(props) => (props.disabled ? 'pointer' : 'text')};
`;

const BoardItemContents = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .lock-icon {
    position: relative;
    margin-left: 3px;
  }
`;

class BoardListItem extends React.PureComponent {
  state = {
    boardName: this.props.board.name,
    isEditing: false
  };

  titleInput = React.createRef();

  componentDidUpdate = (prevProps, prevState) => {
    const beganEditingTitle = !prevState.isEditing && this.state.isEditing;
    if (beganEditingTitle) {
      this.titleInput.current.focus();
    }
  };

  handleClick = (e) => {
    const { onClick, board } = this.props;
    onClick(board.id, board.team_slug, board.slug);
  };

  handleChange = (e) => {
    this.setState({ boardName: e.target.value });
  };

  beginEditing = (e) => {
    e.stopPropagation();
    this.setState({ isEditing: true });
  };

  updateTitle = () => {
    const { boardName } = this.state;
    this.setState({ isEditing: false });
    const { editGroup, board } = this.props;
    if (boardName !== '') {
      editGroup('', board.id, boardName);
    } else {
      this.setState({ boardName: this.props.board.name });
    }
  };

  onEnterPress = (e) => {
    if (this.state.isEditing && e.key === 'Enter') {
      e.target.blur();
    }
  };

  render() {
    const { board, index, isSelected } = this.props;
    const { isEditing } = this.state;

    return (
      <li
        key={board.id}
        onClick={this.handleClick}
        className={cn(`board-${board.id}-${index} clickable`, {
          selected: isSelected,
          editing: isEditing
        })}
        data-testid={`portfolio-${this.state.boardName}`}
      >
        <div className="board-grip" />
        <BoardItemContents className="board-item-contents">
          <StyledFolderIcon boardId={board.id} />
          <TitleInput
            disabled={!isEditing}
            ref={this.titleInput}
            value={this.state.boardName}
            onChange={this.handleChange}
            onBlur={this.updateTitle}
            onKeyPress={this.onEnterPress}
          />
          <div
            className={cn('lock-icon', {
              visible: board && board.is_private
            })}
          />
        </BoardItemContents>
      </li>
    );
  }
}

export default BoardListItem;
