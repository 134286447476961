import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  getLocationsArray,
  makeGetLocationsByIds
} from 'SettingsModule/selectors/locations';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';
import theme from 'theme';
import { EntityLocation } from 'SettingsModule/models/location';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';

const copy = {
  headerInitial: 'Select Location',
  searchPlaceholder: 'Type name or select below'
};

const byLocationId = (item: EntityLocation) => item.id;

interface BulkLocationSelectorProps {
  isOpen: boolean;
  target: MutableRefObject<null>;
  locationFilter: (locations: EntityLocation[]) => EntityLocation[];
  initialAddedLocationsIds?: number[];
  onDone: ({
    addedLocations,
    removedLocations
  }: {
    addedLocations: EntityLocation[];
    removedLocations: EntityLocation[];
  }) => void;
  onClose: () => void;
}

export const BulkLocationSelector = ({
  target,
  isOpen,
  locationFilter,
  initialAddedLocationsIds = [],
  onDone,
  onClose
}: BulkLocationSelectorProps) => {
  const [addedLocations, setAddedLocations] = useState<EntityLocation[]>([]);
  const [removedLocations, setRemovedLocations] = useState<EntityLocation[]>(
    []
  );

  const locations = useAppSelector(getLocationsArray);

  const getLocationsByIds = useMemo(makeGetLocationsByIds, []);

  const addedLocationsFromIds = useAppSelector((state) =>
    initialAddedLocationsIds.length
      ? getLocationsByIds(state, { ids: initialAddedLocationsIds })
      : undefined
  );

  const handleSelect = (_, { item }: { item: EntityLocation }) => {
    if (addedLocations.find((location) => location.id === item.id)) {
      removeFromAddedLocations(item);
      addToRemovedLocations(item);
    } else {
      removeFromRemovedLocations(item);
      addToAddedLocations(item);
    }
  };

  const resetLocalState = () => {
    setAddedLocations([]);
    setRemovedLocations([]);
  };

  useEffect(() => {
    if (isOpen) {
      resetLocalState();

      if (initialAddedLocationsIds && addedLocationsFromIds) {
        setAddedLocations(addedLocationsFromIds);
      }
    }
  }, [addedLocationsFromIds, initialAddedLocationsIds, isOpen]);

  useEffect(() => {
    rebuildTooltip();
  });

  const addToAddedLocations = (location: EntityLocation) => {
    setAddedLocations([...addedLocations, location]);
  };

  const removeFromAddedLocations = (location: EntityLocation) => {
    setAddedLocations(
      addedLocations.filter((addedLocation) => addedLocation.id !== location.id)
    );
  };

  const addToRemovedLocations = (location: EntityLocation) => {
    setRemovedLocations([...removedLocations, location]);
  };

  const removeFromRemovedLocations = (location: EntityLocation) => {
    setRemovedLocations(
      removedLocations.filter(
        (removedLocation) => removedLocation.id !== location.id
      )
    );
  };

  const handleFilterLocation = (item: EntityLocation, searchWords: string[]) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['full_address']
    });

  const handleDone = () => {
    const initialAddedLocationsIdsHash = keyBy(initialAddedLocationsIds);

    const newlyAddedLocations = addedLocations.filter(
      (region) => !initialAddedLocationsIdsHash[region.id]
    );

    const newlyRemovedLocations = removedLocations.filter(
      (region) => !!initialAddedLocationsIdsHash[region.id]
    );

    onDone({
      addedLocations: newlyAddedLocations,
      removedLocations: newlyRemovedLocations
    });

    resetLocalState();
  };

  const addedLocationsById = keyBy(addedLocations, byLocationId);

  const getSortedLocations = useCallback(() => {
    const otherLocations = locations.filter(
      (location) => !addedLocationsById[location.id]
    );

    const sortedLocations = [...addedLocations, ...otherLocations];

    return locationFilter(sortedLocations);
  }, [addedLocations, addedLocationsById, locationFilter, locations]);

  const renderItem = ({ item }: { item: EntityLocation }) => {
    const isAdded = !!addedLocationsById[item.id];
    const fullAddressText = item.full_address;
    return (
      <ItemContainer $isAdded={isAdded}>
        <LocationText {...defaultTooltipProps} data-tip={fullAddressText}>
          {fullAddressText}
        </LocationText>
        <LocationAddRemoveText $isAdded={isAdded}>
          {isAdded ? 'Remove' : 'Add'}
        </LocationAddRemoveText>
      </ItemContainer>
    );
  };

  const renderHeaderButton = () => {
    return (
      <HeaderButtonContainer>
        <StyledDoneButton onClick={handleDone}>Done</StyledDoneButton>
      </HeaderButtonContainer>
    );
  };

  return isOpen ? (
    <MultiStepFlyout
      copy={copy}
      items={getSortedLocations()}
      idKey="id"
      renderItem={renderItem}
      target={target}
      hideFooter
      editDisabled
      itemHeight={48}
      listWidth={300}
      isWhite
      searchEnabled
      renderHeaderButton={renderHeaderButton}
      handleClose={onClose}
      itemFilter={handleFilterLocation}
      popoverClassName="bulk-location-selector"
      handleSelect={handleSelect}
    />
  ) : null;
};

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledDoneButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};

  &:hover {
    filter: brightness(95%);
  }
`;

const LocationText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray2};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const LocationAddRemoveText = styled.div<{ $isAdded: boolean }>`
  font-size: 12px;
  color: ${({ $isAdded, theme }) =>
    $isAdded ? theme.colors.colorCalendarRed : theme.colors.colorCalendarBlue};
`;

const ItemContainer = styled.div<{ $isAdded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  width: 100%;

  :not(:hover) {
    ${LocationAddRemoveText} {
      ${(props) => (!props.$isAdded ? 'opacity: 0' : '')};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;
