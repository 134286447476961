import { useMemo, useRef, useCallback } from 'react';
import styled from 'styled-components';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import theme from 'theme';
import { MemberGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { useMemberOptions } from 'SuggestionModule/components/FindPeople/Table/helpers';

const StyledCellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 13px;
  padding-right: 25px;
  cursor: pointer;

  &:hover {
    path {
      fill: ${theme.colors.colorCalendarBlue};
      stroke: ${theme.colors.colorCalendarBlue};
    }
  }
`;

const MemberOptionCell = ({ row }: MemberGroupRowProps) => {
  const {
    phase,
    phaseMembership,
    member: suggestedMember,
    phaseTotals,
    accountId,
    projectTotal,
    teamMembershipsByAccountId,
    customRowProps: {
      handleOpenMergeBudgetModal,
      projectId,
      unassignedMemberBudgetId,
      resetUnassignedRoleAndSelectedPhaseIds
    }
  } = row.original;

  const {
    openMenu,
    menuRef,
    assignToPhasesDropdownRef,
    MemberOptionMenu,
    AssignToPhasesDropdown
  } = useMemberOptions({
    handleOpenMergeBudgetModal,
    resetUnassignedRoleAndSelectedPhaseIds,
    teamMembershipsByAccountId,
    accountId,
    unassignedMemberBudgetId,
    projectId,
    projectTotal,
    phaseTotals,
    phaseId: phase.id
  });

  return (
    <StyledCellContainer onClick={openMenu} ref={menuRef}>
      <div ref={assignToPhasesDropdownRef} />
      <ThreeDotHorizontal />
      <MemberOptionMenu />
      <AssignToPhasesDropdown />
    </StyledCellContainer>
  );
};

export default MemberOptionCell;
