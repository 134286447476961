import { select } from 'redux-saga/effects';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import authApi from 'SettingsModule/api/auth';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import {
  getAuthMfaStatusPerAccountActionCreatorsMap,
  toggleIndividualMfaActionCreatorsMap,
  toggleMfaResetActionCreatorsMap
} from 'SettingsModule/entityActions';
import {
  updateTeamAuthSettings,
  getAuthMfaStatusPerAccount,
  toggleIndividualMfa,
  toggleMfaReset,
  updateTeamAuthSettingsActionCreatorsMap
} from 'SettingsModule/actionCreators';

export function* updateTeamAuthSettingsWorker(
  action: ReturnType<typeof updateTeamAuthSettings>
) {
  const { payload, meta } = action;
  const { teamMfaSetting } = payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  const { response } = yield changeEntity(
    updateTeamAuthSettingsActionCreatorsMap,
    authApi.updateTeamAuthSettings,
    [token, { teamMfaSetting, teamId }],
    action
  );

  if (response && meta?.onSuccess) {
    meta.onSuccess();
  }
}

export function* getAuthMfaStatusPerAccountWorker(
  action: ReturnType<typeof getAuthMfaStatusPerAccount>
) {
  const { payload, meta } = action;
  const { accountIds } = payload;
  const token = yield select(getAuthToken);

  const { response } = yield fetchEntity(
    getAuthMfaStatusPerAccountActionCreatorsMap,
    api.getAuthMfaStatusPerAccount,
    null,
    [token, { accountIds }],
    action
  );

  if (response && meta?.onSuccess) {
    meta.onSuccess();
  }
}

export function* toggleIndividualMfaWorker(
  action: ReturnType<typeof toggleIndividualMfa>
) {
  const { payload, meta } = action;
  const { accountIds, isEnableMfa, mfaType } = payload;
  const token = yield select(getAuthToken);

  const { response } = yield changeEntity(
    toggleIndividualMfaActionCreatorsMap,
    api.toggleIndividualMfa,
    [token, { accountIds, isEnableMfa, mfaType }],
    action
  );

  if (response && meta?.onSuccess) {
    meta.onSuccess();
  }
}

export function* toggleMfaResetWorker(
  action: ReturnType<typeof toggleMfaReset>
) {
  const { payload } = action;
  const { email, targetMfa, onSuccess } = payload;
  const token = yield select(getAuthToken);

  const { response } = yield changeEntity(
    toggleMfaResetActionCreatorsMap,
    api.toggleMfaReset,
    [token, { email, targetMfa }],
    action
  );

  if (response) {
    onSuccess?.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
}
