import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import {
  FetchEntityRequirementsInitialPayload,
  FetchEntityRequirementsSuccessResponse,
  UpdateEntityRequirementsInitialPayload,
  UpdateEntityRequirementsSuccessResponse
} from './types';

const FETCH_ENTITY_REQUIREMENTS = createRequestTypes(
  'FETCH_ENTITY_REQUIREMENTS'
);

export const fetchEntityRequirements = createAction(
  FETCH_ENTITY_REQUIREMENTS.TRIGGER,
  ({ meta, ...payload }: FetchEntityRequirementsInitialPayload) => ({
    payload,
    meta
  })
);

export const fetchEntityRequirementsActionCreatorsMap = createActionCreatorsMap<
  FetchEntityRequirementsInitialPayload,
  FetchEntityRequirementsSuccessResponse
>(FETCH_ENTITY_REQUIREMENTS);

/* ------------------------------------ Update ----------------------------------- */
const UPDATE_ENTITY_REQUIREMENTS = createRequestTypes(
  'UPDATE_ENTITY_REQUIREMENTS'
);

export const updateEntityRequirements =
  createAction<UpdateEntityRequirementsInitialPayload>(
    UPDATE_ENTITY_REQUIREMENTS.TRIGGER
  );

export const updateEntityRequirementsActionCreatorsMap =
  createActionCreatorsMap<
    UpdateEntityRequirementsInitialPayload,
    UpdateEntityRequirementsSuccessResponse
  >(UPDATE_ENTITY_REQUIREMENTS);
