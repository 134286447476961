import styled from 'styled-components';
import { DELETE_COLUMN_WIDTH } from '../tableConfigs';
import { TableCell } from './TableCell';
import cn from 'classnames';

export const AddRateCell = styled(TableCell).attrs<{
  $isLastItem: boolean;
  $width: number;
}>(({ $isLastItem, $width }) => ({
  className: cn({ lastItem: $isLastItem }),
  style: { width: $width - DELETE_COLUMN_WIDTH }
}))<{ $isLastItem: boolean; $width: number }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
  cursor: pointer;
  font-weight: 600;
  gap: 4px;
  height: 54px;
  margin-left: ${DELETE_COLUMN_WIDTH}px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray4};
  }

  &.lastItem {
    border-bottom: none;
  }
`;
