import keyBy from 'lodash/keyBy';
import {
  NumberParam,
  NumericArrayParam,
  ArrayParam,
  StringParam,
  BooleanParam,
  withDefault,
  encodeNumber,
  decodeNumber,
  encodeString,
  decodeString
} from 'use-query-params';
import { INTERNAL_LABELS } from 'appConstants/customFields';
import { CHART_TYPE } from 'ReportsModule/constants';

const sort_rank = 'sort_rank';
const date = 'date';
const start_date = 'start_date';
const end_date = 'end_date';
const phase = 'phase';
const member = 'member';
const project = 'project';
const activity = 'activity';
const description = 'description';
const qb = 'qb';
const billable = 'billable';
const rate = 'rate';
const costRate = 'costRate';
const role = 'role';
const hours = 'hours';
const status = 'status';
const rateMultiplier = 'rateMultiplier';
const permissionLevel = 'permissionLevel';
const cost = 'cost';
const approver = 'approver';
const amount = 'amount';
const email = 'email';
const skillLevel = 'level';
const alphabetical = 'alphabetical';
const name = 'name';
const project_number = 'project_number';
const active_project = 'active_project';
const active_phase = 'active_phase';
const active_phase_schedule_bar = 'active_phase_schedule_bar';
const task_group = 'task_group';
const today = 'today';
const this_week = 'this_week';
const next_two_weeks = 'next_two_weeks';
const next_four_weeks = 'next_four_weeks';
const custom = 'custom';
const demand = 'demand';
const percentage_of_available_capacity_planned =
  'percentage_of_available_capacity_planned';
const billable_percentage = 'billable_percentage';
const spent_hours_billable = 'spent_hours_billable';
const email_notification_on = 'email_notification_on';
const total_work_days = 'total_work_days';
const budget_status = 'budget_status';

const asc = 'asc';
const desc = 'desc';
const ASC = 'ASC';
const DESC = 'DESC';

export const SORT_BY = {
  sort_rank,
  date,
  end_date,
  phase,
  member,
  project,
  activity,
  description,
  qb,
  billable,
  rate,
  costRate,
  role,
  hours,
  status,
  rateMultiplier,
  cost,
  approver,
  amount,
  permissionLevel,
  email,
  skillLevel,
  alphabetical,
  name,
  project_number,
  active_project,
  active_phase,
  active_phase_schedule_bar,
  task_group,
  today,
  this_week,
  next_two_weeks,
  next_four_weeks,
  custom,
  demand,
  percentage_of_available_capacity_planned,
  billable_percentage,
  spent_hours_billable,
  email_notification_on,
  total_work_days,
  budget_status,
  start_date
} as const;

export const SORT_ORDER = {
  asc,
  desc,
  ASC,
  DESC
} as const;

export const OPPOSITE_SORT_ORDER = {
  asc: desc,
  desc: asc,
  ASC: DESC,
  DESC: ASC
} as const;

export const TIMESHEET_SORT_API_PARAMS = {
  date: 'date',
  member: 'member',
  members: 'member',
  project: 'project',
  projects: 'project',
  phases: 'phase',
  activity: 'activity',
  description: 'description',
  qb: 'sync_status',
  billable: 'billable',
  rate: 'rate',
  hours: 'hours',
  status: 'status',
  approver: 'approved_rejected'
} as const;

// determines what the sort_attribute_* totals will be grouped by
export const TIMESHEET_SORT_TO_GROUP_DETERMINANT = {
  date: 'date',
  member: 'member',
  members: 'member',
  project: 'phase_id',
  projects: 'phase_id',
  phases: 'phase_id',
  activity: 'activity',
  description: 'description',
  qb: 'sync_status',
  billable: 'billable',
  rate: 'rate',
  hours: 'hours',
  status: 'status',
  approver: 'approved_rejected'
} as const;

export const BILLABLE_VALUES = {
  BILLABLE: 'Billable',
  NOT_BILLABLE: 'Not Billable'
} as const;

export const BILLABLE_VALUES_REVERSE = keyBy(Object.values(BILLABLE_VALUES));

export const BILLABLE_FILTER_API_VALUES = {
  [BILLABLE_VALUES.BILLABLE]: true,
  [BILLABLE_VALUES.NOT_BILLABLE]: false
} as const;

export const SYNC_STATUS_VALUES = {
  SYNCED: 'Synced',
  NOT_SYNCED: 'Not Synced',
  INTERNAL_QB_SERVER_ERROR: 'Internal QuickBooks Agent Server Error',
  NOT_ATTEMPTED: 'Not Attempted',
  INTERNAL_MOSAIC_SERVER_ERROR: 'Internal Mosaic Server Error'
} as const;

// to be replaced by FILTER_FIELDS
export const FILTER_SECTIONS = {
  MEMBERS: 'members',
  PROJECTS: 'projects',
  PHASES: 'phases',
  ACTIVITIES: 'activities',
  BILLABLE: 'billable',
  STATUSES: 'statuses',
  CLIENTS: 'clients',
  /**
   * Project status ie. active archived
   */
  PROJECT_STATUSES: 'project_statuses',
  PROJECT_PRIORITY: 'project_priority',
  PROFIT_CENTERS: 'profitCenters',
  POSITIONS: 'positions',
  PROJECT_BUDGET_STATUSES: 'projectBudgetStatuses',
  EDITORS: 'editors', // for activity side menu
  /**
   * Budget Table Specific
   */
  PHASE_BUDGET_STATUSES: 'phase_budget_status',
  PHASE_FEE_TYPES: 'phase_fee_type'
} as const;

// use with lodash get
// to be deprecated once using filterSchemas
export const filterSectionToFilterKey = {
  [FILTER_SECTIONS.MEMBERS]: 'account_ids',
  [FILTER_SECTIONS.PROJECTS]: 'project_ids',
  [FILTER_SECTIONS.POSITIONS]: 'position_ids',
  [FILTER_SECTIONS.PHASES]: 'phase_names',
  [FILTER_SECTIONS.ACTIVITIES]: 'activity_ids',
  [FILTER_SECTIONS.BILLABLE]: 'custom.billable',
  [FILTER_SECTIONS.PROJECT_STATUSES]: 'custom.projectStatus',
  [FILTER_SECTIONS.STATUSES]: 'status_ids',
  [FILTER_SECTIONS.CLIENTS]: 'clients',
  [FILTER_SECTIONS.PROJECT_PRIORITY]: 'project_priority',
  [FILTER_SECTIONS.PROFIT_CENTERS]: `custom.${INTERNAL_LABELS.PROFIT_CENTER}`,
  [FILTER_SECTIONS.PROJECT_BUDGET_STATUSES]: `custom.${FILTER_SECTIONS.PROJECT_BUDGET_STATUSES}`,
  [FILTER_SECTIONS.EDITORS]: `custom.${FILTER_SECTIONS.EDITORS}`,
  /**
   * BUDGET TABLE PHASE
   */
  [FILTER_SECTIONS.PHASE_BUDGET_STATUSES]: `custom.${FILTER_SECTIONS.PHASE_BUDGET_STATUSES}`,
  [FILTER_SECTIONS.PHASE_FEE_TYPES]: `custom.${FILTER_SECTIONS.PHASE_FEE_TYPES}`
} as const;

const account_ids = 'account_ids';
const project_ids = 'project_ids';
const clients = 'clients';
const phase_names = 'phase_names';
const activity_ids = 'activity_ids';
const client_ids = 'client_ids';
const position_ids = 'position_ids';

// to be replaced by FILTER_FIELDS
export const FILTER_VALUES = {
  account_ids,
  project_ids,
  clients,
  phase_names,
  activity_ids,
  client_ids,
  position_ids
} as const;

// to be replaced by FILTER_FIELDS
export const CUSTOM_FILTER_VALUES = {
  range: 'range',
  statusView: 'statusView',
  showPto: 'showPto',
  showHolidays: 'showHolidays',
  billable: 'billable',
  syncStatuses: 'syncStatuses',
  groupBy: 'groupBy',
  hideNoBudget: 'hideNoBudget',
  displayFormat: 'displayFormat',
  editors: 'editors',
  chartType: 'chartType'
} as const;

/**
 * Standard filter fields, including custom filter fields
 */
export const FILTER_FIELDS = {
  account_ids: 'account_ids',
  position_ids: 'position_ids',
  project_ids: 'project_ids',
  phase_names: 'phase_names',
  activity_ids: 'activity_ids',
  clients: 'clients',
  board_ids: 'board_ids',
  client_ids: 'client_ids',
  billable: 'billable',
  groupBy: 'groupBy'
} as const;

export enum Page {
  WorkloadPlanner = 'Workload Planner',
  Teams = 'Teams',
  ProjectTasks = 'Project Tasks',
  Timesheet = 'Timesheet',
  Schedule = 'Schedule',
  Budget = 'Budget',
  Capacity = 'Capacity',
  Utilization = 'Utilization',
  Integration = 'Integration',
  ProfitReport = 'Profit Report',
  Variance = 'Variance',
  Activity = 'Activity',
  TimesheetStatusReport = 'Timesheet Status Report',
  DemandReport = 'Demand Report',
  BudgetTable = 'Budget Table',
  ScopeTable = 'Scope Table',
  BudgetVariance = 'Budget Variance',
  WorkloadForecast = 'Workload Forecast',
  ScheduleVariance = 'Schedule Variance',
  SkillsReport = 'Skills Report',
  CheckInsReport = 'Check Ins Report',
  AllProjectsReport = 'All Projects Report',
  Unplanned = 'Unplanned',
  PtoReport = 'PTO Report'
}

/**
 *  This is precautionary comment:
 *  range has 2 different data types: string and number. This will handle both types.
 * Beware that even when the originally passed value is stringified number, it will always decode to number type.
 * We don't have any stringified values for range, so let's keep it that way in order to avoid unexpected behaviour.
 */
const NumberOrStringParam = {
  encode: (value) =>
    typeof value === 'number' && Number.isFinite(value)
      ? encodeNumber(value)
      : encodeString(value),
  decode: (value) =>
    Number.isNaN(+value) ? decodeString(value) : decodeNumber(value)
} as const;

export const FILTER_QUERY_PARAMS_CONFIG = {
  filter_id: NumberParam,
  // filter fields
  account_id: NumberParam,
  account_ids: withDefault(NumericArrayParam, []),
  activity_ids: withDefault(NumericArrayParam, []),
  board_ids: withDefault(NumericArrayParam, []),
  clients: withDefault(ArrayParam, []),
  id: NumberParam,
  is_default: BooleanParam,
  name: StringParam,
  page_name: StringParam,
  phase_names: withDefault(ArrayParam, []),
  position_ids: withDefault(NumericArrayParam, []),
  project_ids: withDefault(NumericArrayParam, []),
  range: NumberOrStringParam,
  statusView: StringParam,
  status_ids: withDefault(NumericArrayParam, []),
  team_id: NumberParam,
  team_membership_id: NumberParam,
  billable: withDefault(ArrayParam, [
    BILLABLE_VALUES.BILLABLE,
    BILLABLE_VALUES.NOT_BILLABLE
  ]),
  chartType: withDefault(StringParam, CHART_TYPE.BAR)
} as const;
