import React from 'react';
import { connect } from 'react-redux';
import CellContainer from './CellContainer';
import { calculateSelectedDateRange } from 'appUtils/dateRangeHelpers';
import moment from 'appUtils/momentConfig';
import { nullifyInvalidDate } from 'appUtils/momentUtils';
import { getAuth, getDependencyTaskHash } from 'selectors';
import { triggerTasksAttributesUpdate } from 'actionCreators';
import NewDependencyDateRangeCalendar from 'components/DateRangeCalendar/NewDependencyDateRangeCalendar';
import TaskDatePickerInput from 'components/DatePicker/TaskPickerInput';
import TaskRangePickerInput from 'components/DatePicker/TaskRangePickerInput';
import { DEPENDENCY_TYPES, DEPENDENCY_STRINGS } from 'appConstants/workload';
import {
  generateNewDependencyInfos,
  isStartDateDependency,
  isEndDateDependency,
  isLegacyStartAndEndDateDependency
} from 'appUtils/newDependencies';
import { StyledNewDependencyDateRangeCalendar } from '../styles';

const bindToDay = (date) =>
  date ? date.startOf('day').format('YYYY-MM-DD') : null;

class ScheduleCell extends React.Component {
  state = {
    adding: false,
    newDates: null,
    rangeCalendarActive: false
  };

  setRangeCalendarActive = () => {
    this.setState({ rangeCalendarActive: true });
  };

  clearRangeCalendarActive = (isClickOutside) => {
    this.setState({ rangeCalendarActive: false });
    isClickOutside && this.handleDateSelection([]);
  };

  handleDateSelection = (values) => {
    const {
      row: { original: task }
    } = this.props;
    const newDates = calculateSelectedDateRange({
      newValues: values,
      start: task?.schedule_start,
      end: task?.schedule_end,
      itemExists: !!task
    });
    this.setState({
      newDates
    });
  };

  handleCalendarSelect = ({ startDate }) => {
    const {
      auth: { token },
      handleTasksAttributesUpdate,
      row: {
        original: { id, handleTaskEditClick, createRow, handleUpdate }
      }
    } = this.props;

    const updatedDate = bindToDay(nullifyInvalidDate(startDate));
    const body = {
      task_ids: [id],
      schedule_start: updatedDate,
      schedule_end: null
    };
    if (createRow) {
      handleUpdate({ schedule_start: updatedDate });
    } else {
      handleTasksAttributesUpdate({ token, body });
      handleTaskEditClick(null);
    }
    this.clearRangeCalendarActive();
  };

  handleRangeCalendarSelect = (datesObj) => {
    const {
      auth: { token },
      handleTasksAttributesUpdate,
      row: {
        original: { id, handleTaskEditClick, createRow, handleUpdate }
      }
    } = this.props;

    const body = {
      task_ids: [id],
      ...datesObj
    };
    if (createRow) {
      handleUpdate(datesObj);
    } else {
      handleTasksAttributesUpdate({ token, body });
      handleTaskEditClick(null);
    }
  };

  getHandleChange = () => {
    const { rangeCalendarActive } = this.state;
    const { schedule_end } = this.props.row?.original ?? {};
    return rangeCalendarActive || schedule_end
      ? this.handleRangeCalendarSelect
      : this.handleCalendarSelect;
  };

  render() {
    const { row, taskHash } = this.props;
    const task = row.original;
    const {
      taskProps = {},

      isCreatingNewTask,
      createRow,
      list: { id: listId, createRowData, setCreating }
    } = row.original;
    const {
      taskIsEditing,
      isNewTask,
      alwaysRenderScheduleIn,
      taskEditProperty,
      projectDetailView,
      isTaskModal = false,
      currentFilter
    } = taskProps;
    const { rangeCalendarActive } = this.state;
    const taskProperty = 'schedule_start';

    const handleDateRangeSelect = (
      startDate,
      endDate,
      handler,
      dependency,
      dependencyItem
    ) => {
      if (!endDate) {
        this.handleCalendarSelect({
          startDate: nullifyInvalidDate(startDate, 'YYYY-MM-DD')
        });
      } else {
        const dependencyInfos = generateNewDependencyInfos({
          item: task,
          dependency,
          dependencyItem,
          dependableType: DEPENDENCY_TYPES.TASK
        });

        handler({
          schedule_start: nullifyInvalidDate(startDate, 'YYYY-MM-DD'),
          schedule_end: nullifyInvalidDate(endDate, 'YYYY-MM-DD'),
          dependency_infos: dependencyInfos
        });
      }
    };

    const mapDependencies = () => {
      const initialDependencyItem = { start: null, end: null };
      const initialDependency = { start: null, end: null };
      if (task.dependencies?.length > 0) {
        task.dependencies?.forEach((dependency) => {
          const dependencyTaskItem = taskHash[dependency.parent_id] ?? {
            description: '',
            start_date: '',
            end_date: ''
          };
          const isStartDependency = isStartDateDependency(
            dependency.dependency_type
          );
          const isEndDependency = isEndDateDependency(
            dependency.dependency_type
          );

          if (
            isStartDependency ||
            isLegacyStartAndEndDateDependency(dependency.dependency_type)
          ) {
            initialDependency.start = isStartDependency
              ? dependency.dependency_type
              : DEPENDENCY_STRINGS.START;

            initialDependencyItem.start = {
              ...dependencyTaskItem,
              name: dependencyTaskItem.description, // map below properties to properties read by DependencyList
              start_date: dependencyTaskItem.schedule_start,
              end_date: dependencyTaskItem.schedule_end
            };
          } else if (
            isEndDependency ||
            isLegacyStartAndEndDateDependency(dependency.dependency_type)
          ) {
            initialDependency.end = isEndDependency
              ? dependency.dependency_type
              : DEPENDENCY_STRINGS.END;

            initialDependencyItem.end = {
              ...dependencyTaskItem,
              name: dependencyTaskItem?.description, // map below properties to properties read by DependencyList
              start_date: dependencyTaskItem?.schedule_start,
              end_date: dependencyTaskItem?.schedule_end
            };
          }
        });
      }
      return { initialDependency, initialDependencyItem };
    };

    const { initialDependency, initialDependencyItem } = mapDependencies();

    const hasStartDependency = isStartDateDependency(initialDependency.start);

    const hasEndDependency = isEndDateDependency(initialDependency.end);

    return (
      <CellContainer
        taskProps={taskProps}
        target={(ref) => (this.target = ref)}
        taskProperty={taskProperty}
        row={row}
      >
        {task.schedule_end || rangeCalendarActive ? (
          <StyledNewDependencyDateRangeCalendar
            onSubmit={({ startDate, endDate, dependency, dependencyItem }) =>
              handleDateRangeSelect(
                startDate,
                endDate,
                this.getHandleChange(),
                dependency,
                dependencyItem
              )
            }
            itemStartDate={task.schedule_start}
            itemEndDate={task.schedule_end}
            showClear
            onClear={({ startDate, endDate }) =>
              handleDateRangeSelect(startDate, endDate, this.getHandleChange())
            }
            onClose={this.clearRangeCalendarActive}
            isTaskCalendar
            shouldRenderFooter
            item={task}
            initialDependency={initialDependency}
            initialDependencyItem={initialDependencyItem}
            initialDependencyItemType={DEPENDENCY_TYPES.TASK}
            customInput={(startDate, endDate, openCalendar) => (
              <div style={{ width: '100%', height: '100%' }}>
                <TaskRangePickerInput
                  taskIsEditing={taskIsEditing}
                  startDate={task?.schedule_start}
                  endDate={task?.schedule_end}
                  alwaysRenderInput={alwaysRenderScheduleIn}
                  currentFilter={currentFilter}
                  taskEditProperty={taskEditProperty}
                  isTaskCompleted={task.is_completed}
                  projectDetailView={projectDetailView}
                  isTaskModal={isTaskModal}
                  openCalendar={openCalendar}
                  hasStartDependency={hasStartDependency}
                  hasEndDependency={hasEndDependency}
                />
              </div>
            )}
            calendarClassNames={'task-calendar'}
          />
        ) : (
          <StyledNewDependencyDateRangeCalendar
            onSubmit={this.handleCalendarSelect}
            itemStartDate={task[taskProperty]}
            isSingleDay
            showClear
            onClear={this.handleCalendarSelect}
            showSetRange
            onSetRange={this.setRangeCalendarActive}
            customInput={(startDate, endDate, openCalendar) => (
              <div
                onClick={openCalendar}
                style={{ width: '100%', height: '100%' }}
              >
                <TaskDatePickerInput
                  taskIsEditing={taskIsEditing}
                  selectedDate={moment(startDate).isValid() ? startDate : null}
                  taskDateType={taskProperty}
                  alwaysRenderInput={alwaysRenderScheduleIn}
                  currentFilter={currentFilter}
                  taskEditProperty={taskEditProperty}
                  isTaskCompleted={task.is_completed}
                  projectDetailView={projectDetailView}
                  isTaskModal={isTaskModal}
                />
              </div>
            )}
            calendarClassNames={'task-calendar'}
          />
        )}
      </CellContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  taskHash: getDependencyTaskHash(state)
});

const mapDispatchToProps = {
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate
};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCell);
