import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateCheckin,
  startTimer,
  endTimer,
  handleErrorMessage
} from 'actionCreators';
import {
  getProjectHash,
  getFlatPhasesHash,
  getAllActivityRowInfo,
  getMe,
  getTimerDate
} from 'selectors';
import styled, { keyframes } from 'styled-components';
import theme from 'theme';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import TextareaAutosize from 'react-textarea-autosize';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';
import FolderIcon from 'icons/FolderIcon';
import CompletedIcon from 'icons/CompletedIcon';
import FilledArrow from 'icons/FilledArrow';
import TimerIcon from 'icons/TimerIcon';
import TimerTask from './TimerTask';
import moment from 'moment';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { isToday } from 'appUtils/momentUtils';
import { GENERIC_ACTION } from 'appConstants';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { ReactComponent as StopButton } from 'icons/stoptimer.svg';
import { ReactComponent as PlayButton } from 'icons/playtimer.svg';
import NumberFormat from 'react-number-format';
import LoadingWheelIcon from 'icons/LoadingWheel';

const TimerProject = ({
  checkin,
  totalHours,
  isNotification,
  handleCreateCheckin,
  footerButtonClassName,
  showInLineWorkCategory = false,
  timers,
  restartCounter,
  counter,
  filterStateId
}) => {
  const {
    id,
    project_id,
    phase_id,
    activity_id,
    estimated_hours,
    title,
    date,
    isWorkplan,
    activity_phase_id
  } = checkin;

  const [showTimerDetails, setShowTimerDetails] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(title);
  const [newHours, setNewHours] = useState(Number(estimated_hours || 0));
  const dispatch = useDispatch();
  const me = useSelector(getMe);
  const projectHash = useSelector(getProjectHash);
  const phasesHash = useSelector(getFlatPhasesHash);
  const activities = useSelector(getAllActivityRowInfo);
  const project = projectHash[project_id];
  const projectDescription = project?.description;
  const projectName = project?.title;
  const phase = phase_id ? phasesHash[phase_id] : null;
  const activityName =
    activity_id && !activities[activity_id]?.is_default
      ? activities[activity_id]?.title
      : '';
  const timersArray = Object.values(timers);
  const isActiveTimer = timersArray.some((timer) => timer.isActive);
  const timerDuration =
    timersArray.reduce((acc, timer) => acc + timer.duration, 0) +
    (isActiveTimer ? counter : 0);
  const unformattedTimerDate = useSelector(getTimerDate);
  const timerDate = moment(unformattedTimerDate);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setIsEditing(false);
    setNewText(title);
    setNewHours(Number(estimated_hours || 0));
  }, [estimated_hours, id, title]);

  const handleSubmit = () => {
    if (isWorkplan) {
      handleCreateCheckin({
        activity_id,
        project_id,
        phase_id,
        title: newText,
        estimated_hours: newHours,
        isWorkplan: true
      });
    } else {
      dispatch(
        updateCheckin({
          id: id,
          title: newText,
          estimated_hours: isNaN(newHours) ? 0 : newHours, // If the user does not enter a valid number, it will default to 0
          date: date,
          filterStateId
        })
      );
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setNewText(title);
    setNewHours(Number(estimated_hours || 0));
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
      e.target.blur();
      e.stopPropagation();
    }
  };

  const handleArrowClick = () => {
    setShowTimerDetails(!showTimerDetails);
  };

  const handleStartTimer = (timer) => {
    dispatch(
      startTimer({
        account_id: me.id,
        task_id: timer?.task_id,
        activity_phase_id: activity_phase_id,
        activity_id: activity_id,
        phase_id: phase_id,
        project_id: project_id,
        date: moment().format('YYYY-MM-DD'), // this will always be the current date
        check_in_id: isToday(timerDate) ? (isWorkplan ? null : id) : null,
        auto_generate_check_in: isWorkplan || !isToday(timerDate)
      })
    );
    closeConfirmModal();
  };

  const handleTimerToggle = (timer) => {
    if (isActiveTimer) {
      dispatch(
        endTimer({
          account_id: me.id
        })
      );
      if (timerDuration < 60) {
        dispatch(
          handleErrorMessage({
            type: GENERIC_ACTION,
            isFeError: true,
            errorMessage: 'Timer’s less than 1 min are considered cancelled.'
          })
        );
      }
    } else {
      if (isToday(timerDate)) {
        handleStartTimer(timer);
      } else {
        setShowConfirmModal(true);
      }
    }
    restartCounter();
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const timerColor = isActiveTimer
    ? theme.colors.colorQbTooltipGreen
    : theme.colors.colorLightGray15;

  const formattedHours = Math.floor(timerDuration / 3600);
  const minutes = Math.floor((timerDuration % 3600) / 60);

  return (
    <ListItemContainer isEditing={isEditing}>
      <ListItemHeader>
        <TimerIconContainer
          isActiveTimer={isActiveTimer}
          onClick={handleTimerToggle}
          data-for="app-tooltip"
          data-effect="solid"
          data-tip={
            isActiveTimer ? 'Click to Stop Timer ' : 'Click to Start Timer'
          }
        >
          <HideOnHover>
            {isActiveTimer ? (
              <PlayButton />
            ) : (
              <TimerIcon
                height={22}
                width={22}
                fill={timerColor}
                stroke={timerColor}
              />
            )}
          </HideOnHover>
          <ShowOnHover>
            <StopButton height={22} width={22} />
          </ShowOnHover>
        </TimerIconContainer>
        <ProjectNameRow>
          <ProjectDot id={project_id} />
          <ProjectName> {projectName || ''}</ProjectName>
          <ProjectDash> {projectDescription && '-'} </ProjectDash>
          <DescriptionText>
            {projectDescription && projectDescription}
          </DescriptionText>
        </ProjectNameRow>
        <PhaseNameRow shouldHide={!phase || phase.is_like_default}>
          <MilestoneIconContainer>
            <PhaseMilestoneIcon
              height="12"
              width="10"
              fillColor={theme.colors.colorLightGray19}
            />
          </MilestoneIconContainer>
          <PhaseRowText> {phase?.name || ''}</PhaseRowText>
          <StyledWorkCategoryIconContainer
            showIcon={showInLineWorkCategory && activity_id && activityName}
          >
            <SmallDiamondIcon />
          </StyledWorkCategoryIconContainer>
          <PhaseRowText
            showInLineWorkCategory={
              showInLineWorkCategory && activity_id && activityName
            }
            className="work-category-inline"
          >
            {activityName || ''}
          </PhaseRowText>
        </PhaseNameRow>
        <PhaseNameRow shouldHide={!activity_id || showInLineWorkCategory}>
          <PhaseRowText>{activityName || ''}</PhaseRowText>
        </PhaseNameRow>
      </ListItemHeader>
      <ItemText
        isEditing={isEditing}
        onClick={() => setIsEditing(true)}
        onKeyPress={handleKeyPress}
        onChange={(e) => setNewText(e.target.value)}
        value={isEditing ? newText : title}
        disabled={isNotification}
        placeholder={!isNotification ? 'Add Description' : ''}
        isNotification={isNotification}
        data-testid={`${checkin.id}-${projectName}-${phase?.name}-description`}
      />
      <Footer isEditing={isEditing}>
        {isEditing ? (
          <ButtonContainer>
            {isEditing && (
              <>
                <FooterButton
                  className={`${checkin.id}-cancel-button ${footerButtonClassName}`}
                  isCancel
                  onClick={handleCancel}
                >
                  Cancel
                </FooterButton>
                <SaveButton onClick={() => handleSubmit(newText)}>
                  Save
                </SaveButton>
              </>
            )}
          </ButtonContainer>
        ) : (
          <TimerDetailContainer onClick={handleArrowClick}>
            <CompletedIconContainer>
              <CompletedIcon
                color={theme.colors.colorCalendarGray}
                height={9}
                width={9}
              />
            </CompletedIconContainer>
            {timersArray.length} |{' '}
            {`${Math.floor(formattedHours)}h ${minutes}m`}
            <ArrowContainer isOpen={showTimerDetails}>
              <FilledArrow />
            </ArrowContainer>
            {isActiveTimer && (
              <SpinnerContainer>
                <LoadingWheelIcon height={10} width={10} />
              </SpinnerContainer>
            )}
          </TimerDetailContainer>
        )}
        <HoursContainer>
          <WorkedHours
            onClick={() => {
              setIsEditing(true);
            }}
            as={NumberFormat}
            suffix={'h'}
            isZero={!isEditing && Number(estimated_hours) === 0}
            isEditing={isEditing}
            value={`${
              isEditing
                ? newHours || ''
                : formatNumWithMaxTwoDecimals(Number(estimated_hours))
            }`}
            onKeyPress={handleKeyPress}
            onValueChange={(values) => setNewHours(values.value)}
            disabled={isNotification}
            isNotification={isNotification}
            placeholder="0h"
            data-testid={`${checkin.id}-${projectName}-${phase?.name}-hour`}
          />
          {!isEditing && <HoursDivider>|</HoursDivider>}
          <TotalHours
            data-for={'app-tooltip'}
            data-html
            data-effect="solid"
            data-tip={'Work Plan Hours'}
            data-testid={`${checkin.id}-${projectName}-${phase?.name}-workplan-hour`}
          >
            {' '}
            {totalHours || 0}h{' '}
          </TotalHours>
        </HoursContainer>
      </Footer>
      {showTimerDetails && (
        <>
          {timersArray.map((timer) => (
            <TimerTask
              key={timer.id}
              timer={timer}
              restartCounter={restartCounter}
              counter={counter}
              handleTimerToggle={handleTimerToggle}
            />
          ))}
        </>
      )}
      <SimpleConfirmModal
        isOpen={showConfirmModal}
        toggle={closeConfirmModal}
        onCancel={closeConfirmModal}
        onConfirm={handleStartTimer}
        header={`Start Timer`}
        body={`This will start a timer for this project today.`}
      />
    </ListItemContainer>
  );
};

/* ---------------------Styling-------------------- */

const HideOnHover = styled.div``;

const ShowOnHover = styled.div`
  display: none;
`;

const TimerIconContainer = styled.div`
  position: absolute;
  right: 25px;
  top: 17px;
  cursor: pointer;
  &:hover {
    ${HideOnHover} {
      display: ${(props) => (props.isActiveTimer ? 'none' : 'block')};
    }
    ${ShowOnHover} {
      display: ${(props) => (props.isActiveTimer ? 'block' : 'none')};
      margin-right: -1px;
    }
    ${(props) =>
      !props.isActiveTimer &&
      `
    path {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorRoyalBlue};
    }
    path:nth-child(2) {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorPureWhite};
    }
    `};
  }
`;

const PhaseNameRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  margin-left: 9px;
  min-height: 16px;
  visibility: ${({ shouldHide }) => (shouldHide ? 'hidden' : 'visible')};
  max-width: 260px;
  overflow: hidden;
`;

const PhaseRowText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
  margin-left: 6px;
  font-size: 12px;
  line-height: 17px;
  max-width: 175px;

  &.work-category-inline {
    display: ${({ showInLineWorkCategory }) =>
      showInLineWorkCategory ? 'inline' : 'none'};
    margin-left: 1px;
  }
`;

const ItemText = styled(TextareaAutosize)`
  margin-left: 4px;
  display: inline-block;
  margin-top: -15px;
  border: none;
  resize: none;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  padding: 0px 10px;
  padding-top: 5px;
  background: ${({ isEditing }) => (isEditing ? `white` : 'white')};
  border: ${({ isEditing }) =>
    isEditing
      ? `0.5px solid ${theme.colors.colorRoyalBlue}`
      : `0.5px solid ${theme.colors.colorPureWhite}`};
  &::placeholder {
    color: ${({ isNotification, isEditing }) =>
      !isNotification && !isEditing && theme.colors.colorRoyalBlue};
    font-size: 14px;
  }
  overflow: hidden;
  min-height: 33px;
`;

const BoardSettings = styled.div`
  display: flex;
  justify-content: space-around;
  top: 5px;
  right: 0px;
  position: absolute;
  .threeDotMenu {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.colorPureWhite};
    path {
      fill: ${theme.colors.colorLightGray15};
      stroke: ${theme.colors.colorLightGray15};
    }
  }
`;

const BoardText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
  min-height: 13px;
  display: flex;
  align-items: center;
  margin-left: 13px;
  justify-content: space-between;
`;

const HoverOptions = styled.div`
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 25px;
  right: 35px;
  position: absolute;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: fit-content;
  padding: 9px 0 12px 7px;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  border-radius: 6px;
  padding: 12px 35px;
  &:hover {
    ${BoardSettings} {
      .threeDotMenu {
        path {
          fill: ${theme.colors.colorCalendarBlue};
          stroke: ${theme.colors.colorCalendarBlue};
        }
        &:hover {
          background: ${theme.colors.colorCalendarBlue};
          path {
            fill: ${theme.colors.colorPureWhite};
            stroke: ${theme.colors.colorPureWhite};
          }
        }
      }
    }
    ${BoardText} {
      visibility: visible;
    }
    ${HoverOptions} {
      visibility: ${({ isEditing }) => !isEditing && 'visible'};
    }
  }
`;

const ProjectNameRow = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 260px;
  overflow: hidden;
`;

const ProjectDot = styled.div`
  background: ${calculateProjectColorPreference};
  min-height: 9px;
  min-width: 9px;
  border-radius: 90px;
  margin-right: 5px;
`;

const ProjectName = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  font-size: 14px;
`;

const ProjectDash = styled.div`
  margin: 0px 3px;
`;

const DescriptionText = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${theme.colors.colorCalendarGray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ListItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 5px;
  margin-top: 5px;
  min-width: 131px;
  justify-content: space-between;
  align-items: center;
`;

const Footer = styled.div`
  min-width: 13%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: ${({ isEditing }) => (isEditing ? '2px' : '0px')};
`;

const HoursContainer = styled.div`
  min-width: 13%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: ${({ isEditing }) => (isEditing ? '5px' : '0px')};
  position: relative;
`;

const WorkedHours = styled.input`
  color: ${({ isNotification }) =>
    !isNotification
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  width: 45px;
  text-align: right;
  background: ${({ isEditing }) => (isEditing ? `white` : 'white')};
  border: ${({ isEditing }) =>
    isEditing
      ? `0.5px solid ${theme.colors.colorRoyalBlue}`
      : `0.5px solid ${theme.colors.colorPureWhite}`};
  ${({ isEditing }) =>
    isEditing &&
    `padding: 0px 3px;
    margin-right: 3px;
    `}
`;

const HoursDivider = styled.div`
  color: ${theme.colors.colorLightGray15};
`;

const TotalHours = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
`;

const FooterButton = styled.div`
  width: 40px;
  height: 13px;
  font-size: 14px;
  color: ${({ isCancel }) =>
    isCancel ? theme.colors.colorMediumGray9 : theme.colors.colorRoyalBlue};
  cursor: pointer;
  white-space: nowrap;
  margin-top: -5px;
  margin-left: 3px;
  &.check-ins-view-buttons.save-button {
    text-align: center;
    background: ${theme.colors.colorBudgetBlue};
    border: 1px solid ${theme.colors.colorBudgetBlue};
    box-sizing: border-box;
    border-radius: 4px;
    width: 50px;
    color: ${theme.colors.colorPureWhite};
    height: auto;
  }
`;

const SaveButton = styled.div`
  width: 54px;
  height: 26px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MilestoneIconContainer = styled.div`
  transform: translate(5px, -3px);
  margin-right: 1px;
`;

const StyledWorkCategoryIconContainer = styled.span`
  padding: 0 2px 1px 4px;
  position: relative;
  bottom: 2px;
  visibility: ${({ showIcon }) => (showIcon ? 'visible' : 'hidden')};
`;

const TimerDetailContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  margin-left: 15px;
  cursor: pointer;
`;

const CompletedIconContainer = styled.div`
  margin-right: 5px;
  display: flex;
`;

const ArrowContainer = styled.div`
  transform: ${(props) => (!props.isOpen ? 'rotate(180deg)' : 'none')};
  margin-top: ${(props) => (!props.isOpen ? '5px' : '-1px')};
  margin-left: 3px;
`;

const SpinnerContainer = styled.div`
  margin-left: 6px;
  margin-top: -1px;
`;

export default TimerProject;
