import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DepartmentsTable from './DepartmentsTable';
import DepartmentsTableOptions from './DepartmentsTableOptions';
import { getFormattedWorkGroupSettings } from 'SettingsModule/selectors';

const DepartmentsTableContainer = () => {
  const formattedMemberBudgetSettings = useSelector(
    getFormattedWorkGroupSettings
  );

  return (
    <StyledDepartmentsTableContainer>
      <DepartmentsTableOptions
        showLanding={!formattedMemberBudgetSettings.length}
      />
      <DepartmentsTable
        formattedMemberBudgetSettings={formattedMemberBudgetSettings}
      />
    </StyledDepartmentsTableContainer>
  );
};
export default DepartmentsTableContainer;

/* ------------------------------------ - ----------------------------------- */

const StyledDepartmentsTableContainer = styled.div`
  height: 100%;
  padding: 20px 0 0 26px;
  display: flex;
  flex-direction: column;
`;
