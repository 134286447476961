import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TaskMoveThreeDot = ({ className, color = '#0074D9' }) => (
  <SvgIcon
    className={className}
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" rx="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8C10.896 8 10 7.104 10 6C10 4.896 10.896 4 12 4C13.104 4 14 4.896 14 6C14 7.104 13.104 8 12 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14C10.896 14 10 13.104 10 12C10 10.896 10.896 10 12 10C13.104 10 14 10.896 14 12C14 13.104 13.104 14 12 14Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C10.896 20 10 19.104 10 18C10 16.896 10.896 16 12 16C13.104 16 14 16.896 14 18C14 19.104 13.104 20 12 20Z"
      fill={color}
    />
  </SvgIcon>
);

export default TaskMoveThreeDot;
