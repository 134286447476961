import * as constants from 'appConstants';
import * as dashboardConstants from '../constants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

export const initialFilterState = {
  // dashboard information is still stored in state.dashboards
  selectedDashboardId: undefined,
  dashboardOrder: [], // max 1 for now
  loaded: false
};

export const initialState = {
  dashboards: {},
  dashboardOrder: [],
  isAddWidgetModalOpen: false,
  widgetDetailModalId: null,
  widgetDetailModalType: null,
  loaded: false,
  membershipsByDashboard: {},
  filterStates: {}
};

const byId = (item) => item.id;

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case dashboardConstants.OPEN_ADD_WIDGET_MODAL: {
      return {
        ...state,
        isAddWidgetModalOpen: true,
        widgetDetailModalId: null
      };
    }
    case dashboardConstants.CLOSE_ADD_WIDGET_MODAL: {
      return {
        ...state,
        isAddWidgetModalOpen: false
      };
    }
    case dashboardConstants.OPEN_WIDGET_DETAIL_MODAL: {
      return {
        ...state,
        isAddWidgetModalOpen: false,
        widgetDetailModalId: action.payload.widgetId,
        widgetDetailModalType: action.payload.widgetType
      };
    }
    case dashboardConstants.CLOSE_WIDGET_DETAIL_MODAL: {
      return {
        ...state,
        widgetDetailModalId: null
      };
    }

    case dashboardConstants.FETCH_DASHBOARDS.TRIGGER: {
      const { isPersonal, filterStateId } = action.payload;

      if (isPersonal) {
        const initial = !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(initial
                ? initialFilterState
                : state.filterStates[filterStateId]),
              loaded: false
            }
          }
        };
      }

      return state;
    }
    case dashboardConstants.FETCH_DASHBOARDS.SUCCESS: {
      const {
        response,
        requestPayload: { isPersonal, filterStateId }
      } = action.payload;

      // dashboard information will be stored in state.dashboards
      const nextState = {
        ...state,
        dashboards: {
          ...state.dashboards,
          ...keyBy(response, byId)
        }
      };

      if (isPersonal) {
        const nextFilterState = { ...nextState.filterStates[filterStateId] };
        const personalDashboardOrder = Array.from(
          new Set([...nextFilterState.dashboardOrder, ...response.map(byId)])
        );
        nextFilterState.loaded = true;
        nextFilterState.dashboardOrder = personalDashboardOrder;
        // selector the first one for now. BE is restricting it to 1
        nextFilterState.selectedDashboardId = personalDashboardOrder[0];

        nextState.filterStates = {
          ...nextState.filterStates,
          [filterStateId]: nextFilterState
        };
      } else {
        nextState.dashboardOrder = Array.from(
          new Set([...state.dashboardOrder, ...response.map(byId)])
        );
        nextState.loaded = true;
      }

      return nextState;
    }

    case dashboardConstants.CREATE_DASHBOARD.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { isPersonal, filterStateId } = requestPayload;

      const nextState = {
        ...state,
        dashboards: {
          ...state.dashboards,
          [response.id]: response
        }
      };

      if (isPersonal) {
        // CREATE_DASHBOARD will not be triggered before FETCH_DASHBOARD which creates filter state first.
        const nextFilterState = { ...nextState.filterStates[filterStateId] };
        const personalDashboardOrder = Array.from(
          new Set([...nextFilterState.dashboardOrder, response.id])
        );
        nextFilterState.dashboardOrder = personalDashboardOrder;
        nextFilterState.selectedDashboardId = personalDashboardOrder[0];
        nextState.filterStates = {
          ...nextState.filterStates,
          [filterStateId]: nextFilterState
        };
      } else {
        nextState.dashboardOrder = Array.from(
          new Set([...nextState.dashboardOrder, response.id])
        );
      }

      return nextState;
    }
    case dashboardConstants.UPDATE_DASHBOARD.SUCCESS: {
      const { response } = action.payload;

      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [response.id]: response
        }
      };
    }
    case dashboardConstants.DELETE_DASHBOARD.SUCCESS: {
      const { requestPayload } = action.payload;

      return {
        ...state,
        dashboards: omit(state.dashboards, requestPayload.dashboard.id),
        dashboardOrder: state.dashboardOrder.filter(
          (dashboardId) => dashboardId !== requestPayload.dashboard.id
        )
      };
    }
    case dashboardConstants.FETCH_DASHBOARD_MEMBERSHIPS.SUCCESS: {
      const { response, requestPayload } = action.payload;

      return {
        ...state,
        membershipsByDashboard: {
          ...state.membershipsByDashboard,
          [requestPayload.dashboardId]: response
        }
      };
    }

    default:
      return state;
  }
};

export default dashboard;
