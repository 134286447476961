import { initCalendly } from './calendlyConfig';
import { initDocument360 } from './document360Config';
import { initFullStory } from './fullStoryConfig';
import { initLogRocket } from './logRocketConfig';
import { initHappyFox } from './happyFoxConfig';
import { initHeapAnalysis } from './heapAnalysisConfig';
import { initStripe } from './stripeConfig';
import { initSentry } from './sentryConfig';
import { initGoogleTag } from './googleTagManagerConfig';
import { initRecurly } from './reculyConfig';
import { initTypekit } from './typekitConfig';
import { initOsmos } from './osmosConfig';
import { initAppcues } from './appcuesConfig';
import { initMixpanel } from './mixpanelConfig';
import { initOrtto } from './orttoConfig';

const initModules = () => {
  initCalendly();
  initDocument360();
  initFullStory();
  initLogRocket();
  initHappyFox();
  initHeapAnalysis();
  initStripe();
  initSentry();
  initGoogleTag();
  initRecurly();
  initTypekit();
  initOsmos();
  initAppcues();
  initMixpanel();
  initOrtto();
};

initModules();
