import * as constants from 'QuickbooksModule/constants';

export const sortArray = (array, sortColumn, order) => {
  return array.slice().sort((a, b) => {
    const first = a[sortColumn] || '';
    const second = b[sortColumn] || '';
    if (first < second) {
      return order === 'desc' ? 1 : -1;
    } else if (first > second) {
      return order === 'desc' ? -1 : 1;
    } else {
      return 0;
    }
  });
};

export const getTakeActionAs = (
  dontSync,
  isPending,
  showExpand,
  createNewProject,
  is_qb_project
) => {
  if (dontSync) {
    return constants.DO_NOT_LINK;
  }
  if (isPending && createNewProject && !showExpand) {
    return constants.CREATE_NEW;
  } else if (isPending && !createNewProject && showExpand) {
    return constants.LINK_PHASES_BELOW;
  } else if (isPending && is_qb_project) {
    return constants.LINK_ALL_PHASES;
  } else if (isPending && !createNewProject) {
    return constants.LINK_WITH_EXISTING;
  } else if (!isPending && showExpand) {
    return constants.LINK_PHASES_BELOW;
  } else if (is_qb_project) {
    return constants.LINKED_ALL_PHASES;
  } else {
    return constants.LINKED;
  }
};
