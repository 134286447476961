import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import {
  getConfirmCompleteTaskModalIsOpen,
  getAuthToken,
  getConfirmCompleteTaskModalTask,
  getSelectedTeamId,
  getMyTeamMembership
} from 'selectors';
import {
  closeConfirmCompleteTaskModal,
  triggerBatchComplete,
  updateTeamMembership
} from 'actionCreators';
import {
  CheckBoxDiv,
  CustomCheckBoxContainer,
  CustomCheckBox
} from 'views/projectPlanner/styles';
import theme from 'theme';

const ConfirmCompleteTaskModal = () => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const myTeamMembership = useSelector(getMyTeamMembership);
  const isOpen = useSelector(getConfirmCompleteTaskModalIsOpen);
  const task = useSelector(getConfirmCompleteTaskModalTask);
  const teamId = useSelector(getSelectedTeamId);
  const token = useSelector(getAuthToken);

  const permissions = {
    projectId: task?.project_id,
    teamId
  };
  const handleClose = () => {
    dispatch(closeConfirmCompleteTaskModal());
    if (
      myTeamMembership &&
      myTeamMembership.modal_preferences?.prevent_confirm_complete !== isChecked
    ) {
      dispatch(
        updateTeamMembership({
          id: myTeamMembership.id,
          modal_preferences: {
            ...myTeamMembership.modal_preferences,
            prevent_confirm_complete: isChecked
          }
        })
      );
    }
  };
  const confirmComplete = () => {
    const body = {
      taskIds: [task.id]
    };
    dispatch(
      triggerBatchComplete({
        token,
        body,
        permissions
      })
    );
    handleClose();
  };
  return (
    <Modal isOpen={isOpen} toggle={handleClose} className="delete-modal">
      <div className="delete-modal-title">
        <h3>Mark Completed</h3>
        <button type="button" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="delete-modal-body">
        This Task is assigned to multiple people. Are you sure you want to mark
        this task {task?.is_completed ? 'incompleted' : 'complete'} for all
        assignees?
      </div>
      <div
        className="delete-modal-footer"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div className="delete-modal-footer-yes">
          <div
            style={{
              display: 'flex',
              flex: 'nowrap',
              alignItems: 'center',
              height: 30,
              fontSize: 12,
              cursor: 'pointer',
              color: theme.colors.colorRoyalBlue
            }}
            onClick={() => setIsChecked(!isChecked)}
          >
            <CheckBoxDiv key="1">
              <CustomCheckBoxContainer isChecked={isChecked}>
                <CustomCheckBox isChecked={isChecked} />
              </CustomCheckBoxContainer>
            </CheckBoxDiv>
            Do not show this warning again
          </div>
        </div>
        <div>
          <div className="delete-modal-footer-yes">
            <button onClick={confirmComplete}>Yes</button>
          </div>
          <div className="delete-modal-footer-cancel">
            <button onClick={handleClose}>Cancel</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCompleteTaskModal;
