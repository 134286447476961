import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createRequestTypes } from 'appUtils';
import {
  FetchTeamRoleTemplatesParams,
  FetchTeamRoleTemplatesSuccessResponse,
  UpdateTeamRoleTemplateParams,
  UpdateTeamRoleTemplateSuccessResponse
} from './api/types';

const FETCH_TEAM_ROLE_TEMPLATES = createRequestTypes(
  'FETCH_TEAM_ROLE_TEMPLATES'
);

export const fetchTeamRoleTemplates =
  createAction<FetchTeamRoleTemplatesParams>(FETCH_TEAM_ROLE_TEMPLATES.TRIGGER);

export const fetchTeamRoleTemplatesActionCreatorsMap = createActionCreatorsMap<
  FetchTeamRoleTemplatesParams,
  FetchTeamRoleTemplatesSuccessResponse
>(FETCH_TEAM_ROLE_TEMPLATES);

const UPDATE_TEAM_ROLE_TEMPLATE = createRequestTypes(
  'UPDATE_TEAM_ROLE_TEMPLATE'
);

export const updateTeamRoleTemplate =
  createAction<UpdateTeamRoleTemplateParams>(UPDATE_TEAM_ROLE_TEMPLATE.TRIGGER);

export const updateTeamRoleTemplateActionCreatorsMap = createActionCreatorsMap<
  UpdateTeamRoleTemplateParams,
  UpdateTeamRoleTemplateSuccessResponse
>(UPDATE_TEAM_ROLE_TEMPLATE);
