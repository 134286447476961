import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

export const ProgressChip = ({ value = 0, suffix = '%' }) => {
  const parsedPercent = parseInt(value || 0, 10);
  const backgroundPercent = Math.min(Math.max(0, parsedPercent), 100);
  return (
    <ProgressChipContainer>
      <Background value={backgroundPercent}></Background>
      <TextContainer>{`${value}${suffix}`}</TextContainer>
    </ProgressChipContainer>
  );
};

const ProgressChipContainer = styled.div`
  position: relative;
  width: 40px;
  height: 14px;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border-radius: 20px;
  font-size: 11px;
  border: 1px solid ${theme.colors.colorLightGray12};
`;

const Background = styled.div`
  position: absolute;
  width: ${(props) => props.value}%;
  height: 100%;
  background: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 1px,
    ${theme.colors.colorLightGray12} 3px
  );
  border-radius: 20px;
`;

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
