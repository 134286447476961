import { useMemo } from 'react';
import styled from 'styled-components';
import {
  calculateBorderColorPreference,
  calculateColorPref
} from 'appUtils/styleUtils';
import { getDragTooltipDates } from 'appUtils/projectPlannerUtils';
import theme from 'theme';
import { getUserTheme, makeGetBoardById } from 'selectors';
import moment from 'moment';
import { connect } from 'react-redux';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { getProjectPhaseTooltipContent } from 'appUtils/projectPhaseTooltipContent';
import { getProjectMilestoneTooltipContent } from 'appUtils/projectMilestoneTooltipContent';
import MilestoneIcon from 'icons/WorkloadMilestoneIcon';

const StyledDateContainer = styled.div`
  font-size: 10px;
  margin-top: 2px;
  color: ${theme.colors.colorTranslucentGray4};
  text-shadow: none;
`;

const StyledPhase = styled.div`
  white-space: nowrap;
  line-height: 1;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
`;

const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  height: 18px;
  width: 18px;
  margin-right: 5px;
  path {
    stroke: ${theme.colors.colorPureWhite};
    fill: transparent;
  }
`;
const StyledBudgetPhaseMilestoneIcon = styled(BudgetPhaseMilestoneIcon)`
  height: 18px;
  width: 18px;
  margin-right: 5px;
  .milestone-icon-box {
    stroke: ${theme.colors.colorPureWhite};
  }
  .dollar-sign-in-icon {
    fill: ${theme.colors.colorPureWhite};
  }
`;
const StyledMilestoneBar = styled.div`
  background-color: ${calculateColorPref};
  border: 2px solid ${calculateBorderColorPreference};
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
  height: 32px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px 0px 8px;
  border-radius: 100px;
  &:hover {
    background: ${calculateBorderColorPreference};
  }
`;

const StyledPhaseBarContainer = styled.div`
  position: relative;
`;

const noStyle = {
  borderColor: '',
  background: 'transparent',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer'
};
const draggingStyle = {
  borderColor: '',
  border: 'none',
  backgroundColor: `${theme.colors.colorIceBlue}`,
  cursor: 'grabbing'
  // background: 'transparent',
  // backgroundColor: 'transparent'
  // cursor: 'ew-resize'
};

const MilestoneIconContainer = styled.div`
  text-align: center;
  margin-top: 5px;
`;

const getPhaseTooltipContent = ({ item, projectColor, board }) => {
  const totals =
    item?.total_work_days &&
    `${item.remaining_work_days}/${item?.total_work_days} Workdays`;

  return getProjectPhaseTooltipContent({
    projectColor,
    projectName: item?.project?.title,
    projectNumber: item?.project?.project_number,
    projectDescription: item?.project?.description,
    startDate: item?.start_date_label,
    endDate: item?.end_date_label,
    phaseName: item?.name,
    isBudgetPhase: item?.is_budget,
    isDefaultPhase: item?.is_like_default,
    totals,
    boardName: board?.name
  });
};

const getMilestoneTooltipContent = ({ item }) => {
  return getProjectMilestoneTooltipContent({
    startDate: item?.start_date_label,
    phaseName: item?.name
  });
};

const MilestoneItemRenderer = (props) => {
  const {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    userTheme,
    startDateRef,
    endDateRef,
    board
  } = props;

  const projectColor = calculateColorPref({
    styleId: item?.project?.id,
    originType: 'project',
    theme: userTheme.theme
  });

  const phaseTooltipContent = useMemo(
    () => getPhaseTooltipContent({ item, projectColor, board }),
    [item, projectColor, board]
  );

  const MilestoneTooltipContent = useMemo(
    () =>
      getMilestoneTooltipContent({
        item
      }),
    [item]
  );

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle: {
      cursor: 'w-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      top: '10px',
      height: '32px',
      left: '5px'
    },
    rightStyle: {
      cursor: 'e-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      top: '10px',
      height: '32px',
      right: '5px'
    }
  });

  const tooltipDates =
    itemContext.dragging || itemContext.resizing
      ? getDragTooltipDates({ item, itemContext })
      : null;

  return !item?.is_budget ? (
    <div
      {...getItemProps({
        ...item.itemProps,
        className: 'mosaic-milestone-bar',
        style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
      })}
      data-for="app-tooltip"
      data-tip={MilestoneTooltipContent}
      data-class={'project-bars-tooltips'}
      data-html
      data-type={'light'}
      data-delay-show={500}
    >
      <MilestoneIconContainer>
        <MilestoneIcon color={projectColor} />
      </MilestoneIconContainer>
    </div>
  ) : (
    <div
      {...getItemProps({
        ...item.itemProps,
        className: 'mosaic-milestone-bar',
        style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
      })}
    >
      <div {...leftResizeProps}>
        <div className="drag-handle-bar left" />
        <div className="drag-handle-bar left" />
      </div>
      {(itemContext.dragging || itemContext.resizing) && (
        <StyledMilestoneBar
          styleId={item.project_id}
          dragging
          color={'#808080'}
          originType="project"
        >
          <div
            ref={startDateRef}
            data-tip={moment(tooltipDates.startDate).format('YYYY-MM-DD')}
            data-for="start-date-tooltip"
            style={{ position: 'absolute', left: 0, top: 0 }}
          />
          <div
            ref={endDateRef}
            data-tip={moment(tooltipDates.endDate).format('YYYY-MM-DD')}
            data-for="end-date-tooltip"
            style={{ position: 'absolute', right: 0, top: 0 }}
          />
          {item.name || ''}
        </StyledMilestoneBar>
      )}
      {!itemContext.dragging && !itemContext.resizing && (
        <StyledPhaseBarContainer>
          <StyledMilestoneBar
            styleId={item?.project?.id || item?.project_id}
            color={'#808080'}
            originType="project"
            data-for="app-tooltip"
            data-type="light"
            theme={userTheme.theme}
            data-class="project-bars-tooltips"
            data-tip={phaseTooltipContent}
            data-html
            data-delay-show={500}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                left: '0px',
                overflow: 'hidden'
              }}
            >
              {item?.is_budget ? (
                <StyledBudgetPhaseMilestoneIcon
                  styleId={item.project_id}
                  color={'#808080'}
                  originType="project"
                  theme={userTheme.theme}
                  strokeColor={calculateBorderColorPreference}
                />
              ) : (
                <StyledPhaseMilestoneIcon
                  styleId={item.project_id}
                  color={'#808080'}
                  originType="project"
                  theme={userTheme.theme}
                  strokeColor={calculateBorderColorPreference}
                />
              )}

              <StyledPhase>
                {item.name ? `${item.name}` : ''}
                <StyledDateContainer>
                  {moment(item.start_date_label).format('MMM D ')} -
                  {moment(item.end_date_label).format(' MMM D')}
                </StyledDateContainer>
              </StyledPhase>
            </div>
          </StyledMilestoneBar>
        </StyledPhaseBarContainer>
      )}
      <div {...rightResizeProps}>
        <div className="drag-handle-bar right" />
        <div className="drag-handle-bar right" />
      </div>
    </div>
  );
};

const boardIdGetter = (state, ownProps) => {
  return ownProps.item && ownProps.item.project?.board_id;
};
const makeMapStateToProps = () => {
  const getBoardById = makeGetBoardById({ boardIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    board: getBoardById(state, ownProps),
    userTheme: getUserTheme(state)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(MilestoneItemRenderer);
