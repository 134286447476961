import styled from 'styled-components';
import {
  BaseFilterList,
  PriorityFilterListItem
} from 'FilterModule/components/FilterListsTable/types';
import { TableRow } from 'components/Table/types';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';

export const PriorityLabel = ({
  rowData
}: {
  rowData: TableRow<PriorityFilterListItem, BaseFilterList>;
}) => {
  const {
    item: { title, color }
  } = rowData;

  return (
    <StyledLabel>
      <PriorityFlagIcon currentColor={color} className={undefined} />
      <span>{title}</span>
    </StyledLabel>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledLabel = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  span {
    margin-left: 8px;
  }
`;
