import React, { useCallback, useMemo } from 'react';
import TaskBatchActionsModal from 'views/Home/TaskBatchActions/TaskBatchActionsModal';
import {
  getArchiveMemberModalPhase,
  getArchiveMemberModalOpen,
  getArchiveMemberModalMemberBudget,
  getModalProjectInfo,
  getArchiveMemberModalActivityMembership,
  getArchiveMemberModalActivity
} from 'BudgetModule/selectors';
import { useSelector, useDispatch } from 'react-redux';

import { closeArchiveMemberModal } from 'BudgetModule/actionCreators';
import { archivePhaseMember, archiveActivityMember } from 'actionCreators';

const ArchiveUnarchiveMemberModal = () => {
  const isArchiveMemberModalOpen = useSelector(getArchiveMemberModalOpen);
  const memberBudget = useSelector(getArchiveMemberModalMemberBudget);
  const activity = useSelector(getArchiveMemberModalActivity);
  const activityMembership = useSelector(
    getArchiveMemberModalActivityMembership
  );
  const phase = useSelector(getArchiveMemberModalPhase);
  const phaseMembership = phase?.phase_memberships?.find(
    (phaseMember) =>
      phaseMember.member_budget_id === memberBudget.id &&
      !phaseMember.discarded_at
  );

  const isArchived = activityMembership
    ? activityMembership.discarded_at
    : phaseMembership?.archived;

  const projectIdProps = useMemo(
    () => ({ projectId: phase?.project_id }),
    [phase]
  );
  const getProjectSelector = useCallback(
    (state) => getModalProjectInfo(state, projectIdProps),
    [projectIdProps]
  );
  const project = useSelector(getProjectSelector);
  const dispatch = useDispatch();

  const handleConfirmation = useCallback(() => {
    if (activityMembership) {
      dispatch(
        archiveActivityMember({
          projectId: project.id,
          activityPhaseMemberId: activityMembership.id,
          archive: !isArchived
        })
      );
    } else {
      dispatch(
        archivePhaseMember({
          id: phaseMembership?.id,
          phaseId: phase.id,
          projectId: project.id,
          archive: !isArchived
        })
      );
    }

    dispatch(closeArchiveMemberModal());
  }, [
    activityMembership,
    dispatch,
    project,
    phaseMembership,
    phase,
    isArchived
  ]);

  const toggle = useCallback(() => {
    dispatch(closeArchiveMemberModal());
  }, [dispatch]);

  const renderModalContent = (
    <div>
      Are you sure you want to{' '}
      {isArchived ? 'add' : <span style={{ fontWeight: 600 }}> remove </span>}
      this member {isArchived ? ' to' : ' from'}
      <span style={{ fontWeight: 600 }}>
        {activity ? ` ${activity.title}` : phase && ` ${phase.name}`}
      </span>
      ?
      {!isArchived && (
        <div className="delete-modal-subtext">
          Planned and Estimated time will be deleted. Spent time will be
          archived until the time is deleted or moved to another{' '}
          {activity ? 'work category' : 'phase'}.
        </div>
      )}
    </div>
  );

  const removeButtonRow = (
    <div className="delete-modal-footer">
      <div className="delete-modal-footer-cancel">
        <button className=" remove-cancel-button" onClick={toggle}>
          Cancel
        </button>
      </div>
      <div className="delete-modal-footer-yes">
        <button className=" remove-confirm-button" onClick={handleConfirmation}>
          Remove
        </button>
      </div>
    </div>
  );

  return isArchived ? (
    <TaskBatchActionsModal
      hideClose
      isOpen={isArchiveMemberModalOpen}
      title={renderModalContent}
      content={`This member will be added to the ${
        activity ? 'work category' : 'phase'
      }, previous spent time is kept, and planned or estimated time can be added.`}
      handleConfirmation={handleConfirmation}
      toggle={toggle}
    />
  ) : (
    <TaskBatchActionsModal
      hideClose
      isOpen={isArchiveMemberModalOpen}
      title={`Remove Member from ${activity ? 'Work Category' : 'Phase'}`}
      content={renderModalContent}
      handleConfirmation={handleConfirmation}
      toggle={toggle}
      buttonRow={removeButtonRow}
    />
  );
};

export default ArchiveUnarchiveMemberModal;
