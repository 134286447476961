import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';
const {
  colors: { colorRoyalBlue }
} = theme;

const CloseEditStateIcon = ({ fill = colorRoyalBlue }) => (
  <SvgIcon
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0195 1.48148L8.53805 0L5.01953 3.51852L1.50101 0L0.0195316 1.48148L3.53805 5L0.0195312 8.51852L1.50101 10L5.01953 6.48148L8.53805 10L10.0195 8.51852L6.50101 5L10.0195 1.48148Z"
      fill={fill}
    />
  </SvgIcon>
);

export default CloseEditStateIcon;
