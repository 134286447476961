import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { MutableRefObject, useCallback } from 'react';
import { PTOGroup } from 'SettingsModule/models/pto';
import { getOrderedCurrentPTOPolicies } from 'SettingsModule/selectors/ptos';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import {
  MiniHeaderContainer,
  HeaderContainer,
  StyledMemberTitle,
  StyledRatesRowContainer,
  StyledRatesRowOuterContainer,
  DeleteIconContainer,
  BackKaratContainer,
  StyledHeaderCell,
  StyledCell
} from 'BudgetModule/components/BudgetModal/styles';
import BackKarat from 'icons/BackKarat';
import { navigateToTeamSettings } from 'actionCreators';
import { getTeamSlug } from 'selectors';
import { SCROLLBAR_WIDTH } from 'appConstants/scrollbar';

const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}

`;

const copy = {
  footerInitial: 'Edit Standard PTO Policies'
};

const rowColWidths = '23px 90px 250px';

interface PTOTeamPolicyDropdownProps {
  target: MutableRefObject<null>;
  onSelect: (policy: PTOGroup) => void;
  onClose: () => void;
}

export const PTOTeamPolicyDropdown = ({
  target,
  onSelect,
  onClose
}: PTOTeamPolicyDropdownProps) => {
  const dispatch = useAppDispatch();
  const policies = useAppSelector(getOrderedCurrentPTOPolicies);
  const teamSlug = useAppSelector(getTeamSlug);

  const handleSelect = useCallback(
    (e, { item }: { item: PTOGroup }) => onSelect(item),
    [onSelect]
  );

  const handleFooterClick = () => {
    if (teamSlug) {
      dispatch(
        navigateToTeamSettings({
          teamSlug,
          viewType: 'standards',
          tab: 'pto'
        })
      );
    }
  };

  const renderItem = useCallback(({ item }: { item: PTOGroup }) => {
    return (
      <StyledRatesRowOuterContainer>
        <StyledRatesRowContainer colWidths={rowColWidths}>
          <DeleteIconContainer />
          <StyledCell style={{ paddingLeft: 10 }}>{item.hours}h</StyledCell>
          <StyledCell>
            <span className="no-text-overflow">{item.name}</span>
          </StyledCell>
        </StyledRatesRowContainer>
      </StyledRatesRowOuterContainer>
    );
  }, []);

  const renderTableBodyHeader = useCallback(() => {
    const headerColWidths = rowColWidths;
    return (
      <MiniHeaderContainer colWidths={headerColWidths}>
        <DeleteIconContainer />
        <StyledHeaderCell>Hours</StyledHeaderCell>
        <StyledHeaderCell>Description</StyledHeaderCell>
      </MiniHeaderContainer>
    );
  }, []);

  const renderHeaderText = () => {
    return (
      <HeaderContainer>
        <BackKaratContainer showKarat onClick={onClose}>
          <BackKarat />
        </BackKaratContainer>
        <StyledMemberTitle>Select New PTO Policy</StyledMemberTitle>
      </HeaderContainer>
    );
  };

  return (
    <MultiStepFlyout
      copy={copy}
      items={policies}
      idKey="id"
      editDisabled
      renderItem={renderItem}
      handleSelect={handleSelect}
      isWhite
      handleClose={onClose}
      target={target}
      stickyRow
      isAlwaysGlobal
      listWidth={418 - SCROLLBAR_WIDTH}
      listHeight={Math.min(policies.length * 54, 600)}
      globalClassName="pto-team-policy-dropdown-global"
      listItemContainerStyle={listItemContainerStyle}
      renderTableBodyHeader={renderTableBodyHeader}
      renderHeader={renderHeaderText}
      renderStickyRow={() => <div />}
      onFooterClick={handleFooterClick}
      hideClose
    />
  );
};
