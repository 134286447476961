import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScopes } from 'actionCreators';
import { getScopeHash } from 'selectors';
import { Scope } from 'models/scope';

interface UseWorkPlanScopesProps {
  scopeOrders: Array<number>;
}
export const useWorkPlanScopes = ({
  scopeOrders: initialScopeOrders
}: UseWorkPlanScopesProps) => {
  const [scopeOrders, setScopeOrders] = useState(initialScopeOrders);
  const dispatch = useDispatch();
  const scopeHash = useSelector(getScopeHash);

  useEffect(() => {
    setScopeOrders(initialScopeOrders);
  }, [initialScopeOrders]);

  const scopes = useMemo(
    () => scopeOrders.map((id) => scopeHash[id]).filter((item) => item),
    [scopeHash, scopeOrders]
  );

  useEffect(() => {
    if (scopeOrders.length) {
      dispatch(
        fetchScopes({
          ids: scopeOrders
        })
      );
    }
  }, [dispatch, scopeOrders]);

  const handleAddScope = (scope: Scope) => {
    setScopeOrders((prev) => [...prev, scope.id]);
  };
  const handleRemoveScope = (scope: Scope) => {
    setScopeOrders((prev) => prev.filter((id) => id !== scope.id));
  };
  const handleClearScope = () => {
    setScopeOrders([]);
  };

  return {
    scopes,
    onAddScope: handleAddScope,
    onRemoveScope: handleRemoveScope,
    onClearScope: handleClearScope
  };
};
