import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Sections, Views } from 'appConstants/navigation';

class TeamsProjectsNavigationTracker extends React.PureComponent {
  render() {
    return <NavigationTracker section={Sections.TEAMS} view={Views.STATUS} />;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamsProjectsNavigationTracker)
);
