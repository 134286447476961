import { FilterField, BillableValues } from 'FilterModule/constants';
import { FilterListTypeHook } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const emptyObj = {};
const billableItems = [BillableValues.BILLABLE, BillableValues.NOT_BILLABLE];

export const useBillableFilter = ({
  isOff,
  resultCountHash,
  shouldUseDraft,
  field = FilterField.billable
}: Parameters<FilterListTypeHook>[0] = emptyObj) => {
  const { isHoursOnly } = useIsHoursOnly();

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: billableItems,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    shouldUseDraft
  });

  return {
    ...arrayFilterFieldValues,
    resultCountHash,
    isOff: isOff || isHoursOnly
  };
};
