import React from 'react';
import SvgIcon from 'components/SvgIcon';

const InfoIcon = ({
  width = '14',
  height = '14',
  color = '#9b9b9b',
  className = '',
  style = {}
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 14"
    style={style}
  >
    <circle cx="7" cy="7" r="6.5" stroke={color} fill="transparent" />
    <path
      d="M6 7.625C6 7.27983 6.44772 7 6.99999 7C7.55228 7 8 7.27983 8 7.625V11.375C8 11.7202 7.55228 12 6.99999 12C6.44772 12 6 11.7202 6 11.375V7.625Z"
      fill={color}
    />
    <path
      d="M5.83325 4.27799C5.83325 3.63366 6.35558 3.11133 6.99992 3.11133C7.64426 3.11133 8.16659 3.63366 8.16659 4.27799C8.16659 4.92233 7.64426 5.44466 6.99992 5.44466C6.35558 5.44466 5.83325 4.92233 5.83325 4.27799Z"
      fill={color}
    />
  </SvgIcon>
);

export default InfoIcon;
