import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, Route } from 'react-router-dom';
import ViewFactory from '../../components/ViewFactory/ViewFactory';
import withRouterParams from './WithRouterParams';
import { getMatchedRouteParams, getSplitFlags } from 'selectors';
import MembersSettingsRouter from './MembersSettingsRouter';
import StandardSettingsRouter from './StandardSettingsRouter';
import { PermissionsSettingsRouter } from 'appCore/navigation/permissions/PermissionsSettingsRouter';
import BudgetSettingsRouter from './BudgetSettingsRouter';
import IntegrationsRouter from './IntegrationsRouter';
import CanIfAdmin from 'components/Can/CanIfAdmin';

// Any page(viewType) with tabs will match the Route path.
const tabbedViewTypeRouters = {
  members: MembersSettingsRouter,
  permissions: PermissionsSettingsRouter,
  standards: StandardSettingsRouter
};

class TeamSettingsRouter extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { auth, match, shouldEnableBudget, shouldUseIntegrationsV2 } =
      this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation,
      shouldEnableBudget: nextShouldEnableBudget,
      shouldUseIntegrationsV2: nextShouldUseIntegrationsV2
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      match.url === nextLocation.pathname ||
      shouldEnableBudget !== nextShouldEnableBudget ||
      shouldUseIntegrationsV2 !== nextShouldUseIntegrationsV2;
    return shouldUpdate;
  }

  render() {
    const { match, shouldEnableBudget, shouldUseIntegrationsV2 } = this.props;
    return (
      <CanIfAdmin passThrough>
        {(isAllowed) => {
          if (isAllowed) {
            if (
              match.params.viewType === 'integrations' &&
              shouldUseIntegrationsV2
            ) {
              return <IntegrationsRouter />;
            } else if (match.params.viewType === 'budget') {
              return shouldEnableBudget ? (
                <BudgetSettingsRouter />
              ) : (
                <Redirect to={match.url.replace(/\/budget$/, '/info')} />
              );
            } else {
              const TabbedSettingsRouter =
                tabbedViewTypeRouters[match.params.viewType];
              return (
                // Renders the main page (eg. when page has tabs -> header with tab navigations on Members page)
                <ViewFactory moduleType={'teamSettingsView'} {...this.props}>
                  {/* Renders the tab of the page */}
                  <Route
                    path={`${match.url}/:settingsViewType`}
                    component={TabbedSettingsRouter}
                  />
                </ViewFactory>
              );
            }
          }
          return null; // can render fallback ui for not allowed if desired
        }}
      </CanIfAdmin>
    );
  }
}

const mapStateToProps = (state) => {
  const { settingsBudgetTabFlag, shouldUseIntegrationsV2 } =
    getSplitFlags(state);

  return {
    matchedParams: getMatchedRouteParams(state),
    shouldUseIntegrationsV2: shouldUseIntegrationsV2,
    shouldEnableBudget: settingsBudgetTabFlag
  };
};

export default withRouterParams(
  withRouter(connect(mapStateToProps)(TeamSettingsRouter))
);
