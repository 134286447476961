import { SpecificMemberPermissionList } from 'PermissionsModule/components/SpecificMemberPermission';
import { ConfirmRemoveCustomMemberModal } from 'PermissionsModule/components/SpecificMemberPermission/modals/ConfirmRemoveCustomMemberModal';
import { CheckboxItemType } from 'SettingsModule/types/checkBoxOptions';
import { ValueOf } from 'type-fest';
import { roleTemplatesHash } from 'PermissionsModule/constants';
import { RoleTemplates } from 'PermissionsModule/types';

/**
 * name is role representation on backend.
 * text is what we show on front end.
 *
 * ie. Front end shows 'Budget Manager' but this is represented as 'Financial Manager' on backend.
 */
const adminOption: CheckboxItemType = {
  text: 'Admins',
  name: 'Admin',
  checked: true,
  default: true
};

const allOption: CheckboxItemType = {
  text: 'All Organization Members',
  name: 'Team Member'
};

const financialManagerOption: CheckboxItemType = {
  text: 'Financial Managers',
  name: 'Financial Manager'
};

const boardManagerOption: CheckboxItemType = {
  text: 'Portfolio Managers',
  name: 'Board Manager'
};
const projectManagerOption: CheckboxItemType = {
  text: 'Project Managers',
  name: 'Project Manager'
};
const budgetManagerOption: CheckboxItemType = {
  text: 'Budget Managers',
  name: 'Financial Manager'
};
const workloadPlannerOption: CheckboxItemType = {
  text: 'Work Planners',
  name: 'Workload Planner'
};

const specificMemberOptions: CheckboxItemType = {
  text: 'Specific Members',
  name: 'Specific Member',
  customCheckboxComponent: SpecificMemberPermissionList
};

const projectMemberOption: CheckboxItemType = {
  text: 'Project Members',
  name: 'Project Member'
};

export const DEFAULT_CHECKBOX_ITEMS_NAMES = {
  admin: 'admin',
  boardManager: 'boardManager',
  projectManager: 'projectManager',
  financialManager: 'financialManager',
  allOption: 'allOption',
  budgetManager: 'budgetManager',
  workloadPlanner: 'workloadPlanner',
  specificMember: 'specificMember'
} as const;

const SPECIFIC_MEMBER_TEXT = 'Specific Member' as const;

export const DEFAULT_CHECKBOX_ITEMS: Partial<
  Record<RoleTemplates | typeof SPECIFIC_MEMBER_TEXT, CheckboxItemType>
> = {
  [roleTemplatesHash.admin]: adminOption,
  [roleTemplatesHash.boardManager]: boardManagerOption,
  [roleTemplatesHash.projectManager]: projectManagerOption,
  [roleTemplatesHash.financialManager]: budgetManagerOption,
  [roleTemplatesHash.teamMember]: allOption,
  [roleTemplatesHash.budgetManager]: budgetManagerOption,
  [roleTemplatesHash.workloadPlanner]: workloadPlannerOption,
  [roleTemplatesHash.projectMember]: projectMemberOption,
  // Append this item to options to give one off permission.
  [SPECIFIC_MEMBER_TEXT]: specificMemberOptions
} as const;

export const getCheckboxListItems = ({
  items,
  options
}: {
  items: CheckboxItemType[];
  options?: {
    includeSpecificMember?: boolean;
  };
}): CheckboxItemType[] => {
  const { includeSpecificMember } = options || {};
  const allItems = [
    ...items,
    ...(includeSpecificMember ? [{ name: SPECIFIC_MEMBER_TEXT }] : [])
  ];
  return allItems.map((item) => {
    const optionItem = DEFAULT_CHECKBOX_ITEMS[item.name];

    return optionItem || item;
  });
};
