import { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';

// ==================================================================

import CloseSmallModal from 'icons/CloseSmallModal';
import PencilIconDark from 'icons/PencilIconDark';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { ThreeDotMenuListItem } from 'components/tables/ProjectDetailsTable/Cells/shared/ThreeDotMenuListItem';
import { ThreeDotIconContainer } from './Styles';
import { useInView } from 'react-intersection-observer';
import RemoveWithCircleIcon from 'icons/RemoveWithCircleIcon';
import MembersIcon from 'icons/MembersIcon';
import CalendarIcon from 'icons/CalendarIcon';
import { noop } from 'appUtils/noop';
import { DELETE_PROJECT_MEMBERSHIP_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';

const StyledPencilIcon = styled(PencilIconDark)`
  cursor: pointer;
`;

const MemberModalRowThreeDotMenu = ({
  handleDelete,
  isUnassigned,
  handleModify,
  listRef,
  alwaysShowThreeDot,
  projectId,
  handleNavigateToMemberModalOverView,
  handleNavigateToMemberModalScheduleTab
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    root: listRef.current
  });

  const { canDeleteProjectMembership } = useProjectPermissionState({
    projectId
  });

  useEffect(() => {
    if (!inView) {
      setIsOpen(false);
    }
  }, [inView]);

  const menus = [
    {
      icon: isUnassigned ? <CloseSmallModal /> : <RemoveWithCircleIcon />,
      label: isUnassigned ? 'Delete Role' : 'Remove Member',
      onClick: handleDelete,
      disabled: !canDeleteProjectMembership,
      tooltip: !canDeleteProjectMembership
        ? DELETE_PROJECT_MEMBERSHIP_TIP
        : undefined
    },
    isUnassigned && {
      icon: <StyledPencilIcon />,
      label: 'Modify Role',
      onClick: handleModify
    },
    !isUnassigned && {
      label: 'Member Profile',
      onClick: handleNavigateToMemberModalOverView,
      icon: (
        <MembersIcon
          currentColor={theme.colors.colorMediumGray9}
          height="13px"
          width="13px"
        />
      )
    },
    !isUnassigned && {
      label: 'Work Schedule',
      onClick: handleNavigateToMemberModalScheduleTab,
      icon: (
        <CalendarIcon
          height="13px"
          width="13px"
          currentColor={theme.colors.colorMediumGray9}
        />
      )
    }
  ].filter((item) => item);

  return (
    <ThreeDotIconContainer
      className="three-dot-menu"
      alwaysShowThreeDot={alwaysShowThreeDot}
      isOpen={isOpen}
      ref={ref}
    >
      <ThreeDotMenu
        slide={false}
        style={{ width: '16px', justifyContent: 'center' }}
        overrideSetIsOpen={setIsOpen}
        overrideIsOpen={isOpen}
        placement="left"
      >
        {menus.map(({ label, icon, onClick, disabled, tooltip }) => (
          <ThreeDotMenuListItem
            key={label}
            icon={icon}
            label={label}
            onClick={!disabled ? onClick : noop}
            disabled={disabled}
            tooltip={tooltip}
          ></ThreeDotMenuListItem>
        ))}
      </ThreeDotMenu>
    </ThreeDotIconContainer>
  );
};

export default MemberModalRowThreeDotMenu;
