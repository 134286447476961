import { useContext, useState, useEffect, useMemo, useRef } from 'react';
import moment from 'appUtils/momentConfig';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import {
  openWorkloadMemberRow,
  closeWorkloadMemberRow,
  openWorkloadModal,
  openWorkloadFilter,
  openWorkloadEventsModal,
  fetchPhasesByProjectIds,
  openAllWorkloadMemberRows,
  closeAllWorkloadMemberRows,
  navigateToMemberModal
} from 'actionCreators';
import {
  getMyProjects,
  getDefaultActivityId,
  getWFHProject,
  getOOOProject,
  getProjectHash,
  getPredictionHours,
  getPrepWorkloadEventScheduleBars,
  getOwnProjectUnassignedCounts,
  getMyWorkPlanSettings,
  getSplitFlags
} from 'selectors';
import { getIsOnWorkloadSplitScreen } from 'appCore/navigation/selectors';
import { getMemberBudgets } from 'BudgetModule/selectors';
import KaratRight from 'icons/KaratRight';
import SendArrowIcon from 'icons/SendArrowIcon';
import HomeTasksIcon from 'icons/HomeTasksIcon';
import CalendarIcon from 'icons/CalendarIcon';
import UnassignedMemberIcon from 'icons/UnassignedMemberIcon';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import theme from 'theme';
import {
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY,
  VIEW_TYPE,
  CONDENSED_ZOOM_LEVELS
} from 'appConstants/workload';
import { useInView } from 'react-intersection-observer';
import LastSentTimeStamp from './LastSentTimeStamp';
import SuggestionItem from './SuggestionItem';
import { getFlatPhasesHash, getPhasesByProjectHash } from 'selectors/phases';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import Popover from 'components/Popover';
import MemberPhaseHoursDropdown from './MemberPhaseHoursDropdown';
import { noop } from 'appUtils/noop';
import { HelpersContext } from 'react-calendar-timeline';
import { makeSuggestedWorkplansWithProjectInfo } from 'appUtils/workplanSuggestions';
import CollapseAllIcon from 'icons/CollapseAllIcon';
import { CollapseAllContainer } from './styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import useCurrentRoles from 'BudgetModule/hooks/useCurrentRoles';
import MembersIcon from 'icons/MembersIcon';
import UnassignedThreeDotMenu from './UnassignedThreeDotMenu';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import ContentLoader from 'react-content-loader';
import { ReactComponent as ProjectScheduleIcon } from 'icons/project-schedule.svg';
import PhaseDiamondIcon from 'icons/PhaseDiamondIcon';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { getMemberAttributesTooltip } from 'appUtils/memberTooltipContent';
import { CloseTrigger } from './WorkloadPlannerGroupProjectRenderer';
import {
  useCurrencyFormatterByProject,
  useCurrencyFormatterByTeam
} from 'appUtils/hooks/useFormatCurrency';

export const AddMemberRowHeight = 400;
const memberRowWidth = 250;

/**
 * Tracks the visibility of the row and renders the group renderer only when visible
 */
const WorkloadPlannerGroupRendererWrapper = (props) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0
  }); // only measures intersection of ref and view, NOT contents of ref.

  const projectCurrencyFormatter = useCurrencyFormatterByProject({
    projectId: props.group.projectId
  });
  const teamCurrencyFormatter = useCurrencyFormatterByTeam({});

  return (
    <>
      <div ref={ref} />
      {inView && (
        <ConnectedWorkloadPlannerGroupRenderer
          {...props}
          currencyFormatter={
            props.group.projectId
              ? projectCurrencyFormatter ?? teamCurrencyFormatter
              : teamCurrencyFormatter
          }
        />
      )}
    </>
  );
};

const WorkloadPlannerGroupRenderer = ({
  suggestionsHashByAccount,
  group,
  openWorkloadMemberRow,
  closeWorkloadMemberRow,
  currentUserId,
  openWorkloadModal,
  openWorkloadFilter,
  rootRowCount,
  openBarModal,
  bars,
  phases,
  myProjects,
  phasesByProject,
  defaultActivityId,
  openWorkloadEventsModal,
  fetchPhasesByProjectIds,
  OOOProject,
  WFHProject,
  zoom,
  activateTaskSplitScreen = noop,
  activatePhaseSplitScreen = noop,
  activateWorkplanSplitScreen = noop,
  isOnScheduleView,
  isMemberView,
  workloadViewType,
  condensedZoomLevel,
  isOnTeamSchedules,
  fetchPredictionHours,
  allProjects,
  plannerRows,
  cappedUtilizationsByMember,
  openAllWorkloadMemberRows,
  closeAllWorkloadMemberRows,
  allMemberRowsOpen,
  showCapacityHeatmap,
  projectUnassignedRoleCounts,
  memberBudgetHash,
  navigateToMemberModal,
  handleOpenAddMembersForm,
  setShowMemberSuggestionsOnOpen,
  myWorkPlanSettings,
  isOnWorkloadSplitScreen,
  deactivateSplitScreen,
  handleOpenFindPeopleModal,
  showExpandAll = false,
  projectRates,
  teamRates,
  billRateOnMemberTooltipFlag,
  currencyFormatter
}) => {
  const [phaseHoursDropdownOpen, setPhaseHoursDropdownOpen] = useState(false);
  const [isShowingSuggestions, setIsShowingSuggestions] = useState(false);
  const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);
  const { plannerViewPhaseHoursFlag } = useFeatureFlags();
  const popoverTarget = useRef(null);
  const teamMemberCellRef = useRef(null);
  const helpersContext = useContext(HelpersContext);
  const memberBudget = memberBudgetHash[group.member_budget_id];
  const { unassignedWorkPlansSplitView } = useFeatureFlags();
  const { memberRole, teamRoleName } = useCurrentRoles({
    memberBudget,
    accountId: group.accountId
  });

  const memberRoleName = memberRole?.name ?? '';
  const roleName = isMemberView ? teamRoleName : memberRoleName;

  const showMemberCapacity = myWorkPlanSettings?.show_member_capacity ?? true;
  const showMemberRole = myWorkPlanSettings?.show_member_role ?? true;
  const showBillRates = !!myWorkPlanSettings?.show_bill_rates;
  const tooltipRef = useRef();

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const suggestedWorkPlansWithProjectInfo = useMemo(() => {
    const accountId = group.accountId;
    const suggestionsByAccountId = suggestionsHashByAccount[accountId];

    if (!suggestionsByAccountId?.length) {
      return [];
    }

    return makeSuggestedWorkplansWithProjectInfo({
      allProjects,
      defaultActivityId,
      accountId,
      phases,
      suggestionsByAccountId
    });
  }, [allProjects, defaultActivityId, group, phases, suggestionsHashByAccount]);

  /** Commented out snippets below are old logic for building workplan suggestion. Keeping for reference, instead of removing completely */

  // const { chunksSortedByLength } = useMemo(
  //   () =>
  //     getChunksSortedByLength({
  //       suggestionsHashByAccount,
  //       accountId: group.accountId,
  //       accountCapacity: group.accountCapacity,
  //       cappedUtilizationsByMember
  //     }),

  //   [
  //     group.accountId,
  //     group.accountCapacity,
  //     cappedUtilizationsByMember,
  //     suggestionsHashByAccount
  //   ]
  // );

  // const suggestedWorkPlansWithProjectInfo = useMemo(() => {
  //   const accountId = group.accountId;
  //   if (
  //     !suggestionsHashByAccount[accountId]?.length ||
  //     !chunksSortedByLength?.length
  //   ) {
  //     return emptyArray;
  //   }
  //   const filteredSuggestedWorkPlans = [];

  //   // suggestion is AI suggestion
  //   // suggestedWorkPlan is created from Front end based on AI suggestion
  //   suggestionsHashByAccount[accountId].forEach((suggestion) => {
  //     const project = allProjects?.[suggestion.project_id];
  //     const phase = phases?.[suggestion.phase_id];

  //     const formattedProjectInfo = {
  //       project_id: project?.id,
  //       phase_id: phase?.id,
  //       project_name: project?.title,
  //       phase_name: phase?.name,
  //       is_default_phase: phase?.is_default_phase,
  //       is_like_default: getIsPhaseLikeDefault(phase),
  //       activity_id: phase?.activity_order[0] || defaultActivityId
  //     };
  //     const predictedTotalHours = suggestion.predicted_value;
  //     // below used for testing with semi-random values
  //     // const predictedTotalHours = (s.predicted_value * 10000) % 7;

  //     // skip too small suggestions
  //     if (predictedTotalHours < SUGGESTIONS_MIN_HOURS) {
  //       return;
  //     }

  //     // construct the suggested work plans from AI suggestions
  //     const suggestedWorkPlans = constructSuggestedWorkPlans(
  //       suggestion,
  //       chunksSortedByLength
  //     );

  //     // find the valid suggested work plan if it fits available constraints
  //     const validSuggestedWorkPlan = suggestedWorkPlans.find(
  //       (suggestedWorkPlan) =>
  //         checkIfSuggestedWorkPlanFitsAvailibityConstraints(suggestedWorkPlan)
  //     );

  //     if (validSuggestedWorkPlan) {
  //       filteredSuggestedWorkPlans.push({
  //         ...validSuggestedWorkPlan,
  //         ...formattedProjectInfo
  //       });
  //     }
  //   });
  //   return filteredSuggestedWorkPlans;
  // }, [
  //   allProjects,
  //   defaultActivityId,
  //   group.accountId,
  //   phases,
  //   suggestionsHashByAccount,
  //   chunksSortedByLength
  // ]);

  const baseHeight = useMemo(() => {
    if (!isShowingSuggestions) {
      return;
    }

    const { getGroupDimensions } = helpersContext;
    const height = plannerRows.reduce((acc, row) => {
      if (row.accountId && row.accountId === group.accountId) {
        const groupDimensions = getGroupDimensions(row.account_id);
        return acc + (groupDimensions?.height || 0);
      }
      return acc;
    }, 0);
    return height;
  }, [group.accountId, helpersContext, isShowingSuggestions, plannerRows]);

  useEffect(() => {
    if (!group?.isOpen) {
      setIsShowingSuggestions(false);
    }
  }, [group]);

  const handleOpenSendPlan = (accountId) => {
    openWorkloadEventsModal({
      initialRecipients: [accountId]
    });
  };

  const handleOpenContextMenu = (e) => {
    setContextMenuIsOpen(true);
    e.stopPropagation();
  };

  const handleCloseContextMenu = () => {
    setContextMenuIsOpen(false);
  };

  const handleExpandCollapseAll = (e) => {
    if (!allMemberRowsOpen) {
      const plannerRowAccountIds = plannerRows
        .filter((row) => !!row.accountId)
        .map((row) => row.accountId);
      openAllWorkloadMemberRows({
        accountIds: plannerRowAccountIds
      });
    } else {
      closeAllWorkloadMemberRows();
    }
    e.stopPropagation();
  };

  const showMemberTootlip = () => {
    if (tooltipRef?.current) {
      ReactTooltip.show(tooltipRef.current);
    }
  };

  const hideMemberTooltip = () => {
    if (tooltipRef?.current) {
      ReactTooltip.hide(tooltipRef.current);
    }
  };

  const handleClickMenu = (callbackFn) => (event) => {
    event.stopPropagation();
    handleCloseContextMenu();
    return callbackFn && callbackFn(event);
  };

  const handleViewHours = () => {
    setPhaseHoursDropdownOpen(true);
    fetchPhasesByProjectIds({
      projectIds: [group.projectId],
      budgetAccountId: group.accountId
    });
  };

  const isCondensedView = workloadViewType === VIEW_TYPE.CONDENSED;

  if (
    group.isRoot ||
    group.isAccountRoot ||
    group.isSplitScreen ||
    (!showCapacityHeatmap && !group.isBottom && !isOnScheduleView)
  ) {
    const isProjectViewRoot = group.isAccountRoot;
    const {
      account_id: groupAccountId,
      isOpen,
      accountCapacity,
      accountLastSent,
      projectId,
      isSplitScreen
    } = group;

    const accountId =
      showCapacityHeatmap || isOnScheduleView
        ? groupAccountId.split('--')[1]
        : groupAccountId;
    const capacityTotalUnformatted = accountCapacity
      ? CAPACITY_DATE_KEYS.reduce(
          (total, day) => total + accountCapacity[day],
          0
        )
      : DEFAULT_WEEKLY_CAPACITY;

    const capacityTotal = formatNumWithMaxTwoDecimals(capacityTotalUnformatted);

    const handleClick = group.isOpen
      ? closeWorkloadMemberRow
      : openWorkloadMemberRow;

    const showCapacity = Boolean(
      showMemberCapacity && (workloadViewType !== VIEW_TYPE.CONDENSED || isOpen)
    );

    const showInlineViewHours = Boolean(
      isProjectViewRoot &&
        !plannerViewPhaseHoursFlag &&
        (workloadViewType !== VIEW_TYPE.CONDENSED || isOpen)
    );

    const showRoleName = Boolean(
      showMemberRole && (workloadViewType !== VIEW_TYPE.CONDENSED || isOpen)
    );

    const showSuggestionText = Boolean(
      suggestedWorkPlansWithProjectInfo?.length &&
        !isShowingSuggestions &&
        isOpen
    );

    const showSuggestionItems = Boolean(
      isShowingSuggestions && (!isCondensedView || isOpen)
    );

    const showLastSentTimeStamp =
      !isOnScheduleView &&
      (!isCondensedView || condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM);

    if (group.isLoading) {
      return (
        <ContentLoader height="100" primaryColor="#ddd" secondaryColor="#eee">
          <rect x="55" y="35" rx="2" ry="2" width="180" height="24" />
        </ContentLoader>
      );
    }

    const sharedMenus = {
      MemberProfile: {
        label: 'Member Profile',
        onClick: handleClickMenu(() =>
          // Opens Member Modal / overview on Workload
          navigateToMemberModal({
            memberId: accountId,
            memberViewType: 'overview'
          })
        ),
        icon: Icons.MembersIcon
      },
      WorkSchedule: {
        label: 'Work Schedule',
        onClick: handleClickMenu(() =>
          // Opens Member Modal / schedule on Workload
          navigateToMemberModal({
            memberId: accountId,
            memberViewType: 'schedule'
          })
        ),
        icon: Icons.CalendarIcon
      },
      SendWorkPlan: {
        label: 'Send Work Plan',
        onClick: handleClickMenu(() => handleOpenSendPlan(accountId)),
        icon: Icons.SendArrowIcon
      }
    };

    const workloadThreeDotMenus = [
      sharedMenus.MemberProfile,
      sharedMenus.WorkSchedule,
      unassignedWorkPlansSplitView && {
        label: 'View Unassigned Work Plans',
        onClick: handleClickMenu(() => activateWorkplanSplitScreen(accountId)),
        icon: Icons.ProjectScheduleIcon
      },
      {
        label: 'View Scheduled Tasks',
        onClick: handleClickMenu(() => activateTaskSplitScreen(accountId)),
        icon: Icons.HomeTaskIcon
      },
      {
        label: 'View Project Schedule',
        onClick: handleClickMenu(() => activatePhaseSplitScreen(accountId)),
        icon: Icons.ProjectScheduleIcon
      },
      sharedMenus.SendWorkPlan
    ];

    const plannerThreeDotMenus = [
      sharedMenus.MemberProfile,
      sharedMenus.WorkSchedule,
      plannerViewPhaseHoursFlag && {
        label: 'View Phase Hours',
        icon: Icons.PhaseDiamondIcon,
        onClick: handleClickMenu(handleViewHours)
      },
      sharedMenus.SendWorkPlan
    ];

    const threeDotMenus = (
      isMemberView ? workloadThreeDotMenus : plannerThreeDotMenus
    ).filter(
      (menu) =>
        menu &&
        // temporarilly filter out 'View Phase Hours' menu when the project is PTO
        // this will be removed once we have a feature for PTO project
        !(
          group.projectId === OOOProject?.id &&
          menu.label === 'View Phase Hours'
        )
    );

    const showKarat =
      !isOnWorkloadSplitScreen && (isOnScheduleView || showCapacityHeatmap);

    const billRate = isMemberView
      ? accountId
        ? teamRates?.getTeamMemberBillRate(accountId)?.rate
        : undefined
      : group.member_budget_id
      ? projectRates?.getProjectMemberBillRate(group.member_budget_id)?.rate
      : undefined;

    const showBillRateOnGroup = Boolean(
      billRateOnMemberTooltipFlag &&
        showBillRates &&
        billRate &&
        currencyFormatter &&
        isOpen
    );

    return (
      <TeamMemberCell
        className="team-member-cell app-cues-team-member-cell"
        onClick={() => {
          if (!showCapacityHeatmap && !isOnScheduleView) {
            return;
          }

          handleClick({
            accountId,
            projectId: isSplitScreen ? undefined : projectId
          });
        }}
        showBorder={!isOpen}
        height={isProjectViewRoot ? 73 : undefined}
        data-testid="workload-member"
        isMemberCell={true}
        showCapacityHeatmap={showCapacityHeatmap}
        condensedZoomLevel={condensedZoomLevel}
        ref={teamMemberCellRef}
        isSplitScreenRow={group.isSplitScreen}
      >
        {showLastSentTimeStamp && !group.is_archived && (
          <StyledLastNotificationSent isCondensedView={isCondensedView}>
            <LastSentTimeStamp
              lastSent={accountLastSent}
              hideWeekOf
              hideTime={!isOpen}
            />
          </StyledLastNotificationSent>
        )}
        {group.is_archived && (
          <ArchivedTextContainer isCondensedView={isCondensedView}>
            {`ARCHIVED ${moment(group.archived_at).format('YYYY-MM-DD')}`}
          </ArchivedTextContainer>
        )}
        <StyledGroupBodyContainer
          isCondensedView={isCondensedView}
          isMemberView={isMemberView}
          condensedZoomLevel={condensedZoomLevel}
          isOnTeamSchedules={isOnTeamSchedules}
          isSplitScreenRow={group.isSplitScreen}
          data-offset="{'right': -25}"
        >
          <StyledKaratContainer
            isOpen={!!isOpen}
            className="app-cues-styled-karat"
            showCapacityHeatmap={showCapacityHeatmap}
            // hide={isProjectViewRoot}
          >
            {showKarat && <KaratRight />}
          </StyledKaratContainer>
          {isOnWorkloadSplitScreen && (
            <CloseTrigger
              onClick={deactivateSplitScreen}
              data-testid="close-split-screen"
            >{`\u2715`}</CloseTrigger>
          )}
          <MemberTooltipContainer
            ref={tooltipRef}
            data-for={'app-tooltip'}
            data-place="top"
            data-class="w-180px"
            data-type="light"
            data-effect="solid"
            data-html
            data-tip={getMemberAttributesTooltip({
              name: group.name,
              teamRoleName: roleName,
              totalCapacity: capacityTotal,
              billRate:
                billRateOnMemberTooltipFlag && showBillRates
                  ? billRate
                  : undefined,
              currencyFormatter
            })}
          >
            <MemberInitials
              member={group}
              classes={cn('item-account selected', {
                'logged-member-no-hover': group.account?.id === currentUserId,
                'regular-member-no-hover': group.account?.id !== currentUserId,
                'is-small-condensed-view':
                  isCondensedView &&
                  condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL,
                'is-very-small-condensed-view':
                  isCondensedView &&
                  condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
              })}
              idx={'idx-placeholder'}
              isOnTaskModal
              size={isCondensedView ? condensedZoomLevel : 'medium'}
              styles={{ height: '10px !important' }}
            />
            <StyledNameContainer className="app-cues-styled-name-container">
              <StyledName condensedZoomLevel={condensedZoomLevel}>
                {group.name}
              </StyledName>
            </StyledNameContainer>
          </MemberTooltipContainer>
          <SecondaryRowContainer condensedZoomLevel={condensedZoomLevel}>
            <TooltipArea
              onMouseOver={showMemberTootlip}
              onMouseOut={hideMemberTooltip}
            >
              {showRoleName && (
                <StyledRole className="app-cues-styled-capacity">
                  {roleName}
                </StyledRole>
              )}
              {showCapacity && (
                <StyledSecondaryText
                  onClick={(e) => {
                    e.stopPropagation();

                    navigateToMemberModal({
                      memberId: accountId,
                      memberViewType: 'schedule'
                    });
                  }}
                  className="app-cues-styled-capacity"
                >
                  {capacityTotal}h/wk
                </StyledSecondaryText>
              )}
              {showBillRateOnGroup && (
                <div>{currencyFormatter.format(billRate)}/h</div>
              )}
              {showInlineViewHours && (
                <>
                  <StyledSecondaryText
                    onClick={handleClickMenu(handleViewHours)}
                  >
                    View Hours
                  </StyledSecondaryText>
                </>
              )}
              {phaseHoursDropdownOpen && (
                <MemberPhaseHoursDropdown
                  accountId={group.accountId}
                  projectId={group.projectId}
                  target={teamMemberCellRef}
                  handleClose={() => setPhaseHoursDropdownOpen(false)}
                  listWidth={memberRowWidth}
                />
              )}
            </TooltipArea>
            {showSuggestionText && (
              <SuggestionsText
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowingSuggestions(true);
                }}
                isMediumCondensedView={
                  isCondensedView &&
                  condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
                }
                isSmallCondensedView={
                  isCondensedView &&
                  condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
                }
                isVerySmallCondensedView={
                  isCondensedView &&
                  condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
                }
              >
                {suggestedWorkPlansWithProjectInfo.length} Suggested
              </SuggestionsText>
            )}
          </SecondaryRowContainer>

          <StyledTooltip
            id={group.name}
            place="top"
            effect="solid"
            delayShow={200}
          />

          {showSuggestionItems && (
            <StyledSuggestionsWrapper condensedZoomLevel={condensedZoomLevel}>
              <StyledSuggestionsScrollableContainer
                condensedZoomLevel={condensedZoomLevel}
                baseHeight={baseHeight}
              >
                <StyledSuggestionsContainer>
                  {suggestedWorkPlansWithProjectInfo.map((s, index) => (
                    <SuggestionItem
                      key={index}
                      index={index}
                      item={{
                        ...s,
                        account_id: accountId
                      }}
                      openBarModal={openBarModal}
                      isWorkplanSuggestion
                    />
                  ))}
                </StyledSuggestionsContainer>
              </StyledSuggestionsScrollableContainer>
            </StyledSuggestionsWrapper>
          )}
          {!isOnScheduleView && (
            <ThreeDotContainer
              onClick={(e) => handleOpenContextMenu(e)}
              ref={popoverTarget}
              isSmallCondensedView={
                isCondensedView &&
                condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
              }
              isVerySmallCondensedView={
                isCondensedView &&
                condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
              }
            >
              <ProjectThreeDotMenu
                threeDotClassName="threeDotMenu workload-three-dot-menu"
                onCloseCallback={handleCloseContextMenu}
                shouldClose={!contextMenuIsOpen}
                memberName={group.name}
              >
                <Popover
                  className="member-context-menu"
                  isOpen={contextMenuIsOpen}
                  target={popoverTarget}
                  closePopover={handleCloseContextMenu}
                >
                  <DropdownWrapper>
                    {threeDotMenus.map(({ label, icon, onClick, disabled }) => (
                      <MenuListItem
                        key={label}
                        onClick={onClick}
                        isDisabled={disabled}
                      >
                        {icon}
                        {label}
                      </MenuListItem>
                    ))}
                  </DropdownWrapper>
                </Popover>
              </ProjectThreeDotMenu>
            </ThreeDotContainer>
          )}
        </StyledGroupBodyContainer>
      </TeamMemberCell>
    );
  } else if (group.isSummary) {
    const shouldShowMemberCount =
      !group.isLoading &&
      (!isCondensedView ||
        condensedZoomLevel !== CONDENSED_ZOOM_LEVELS.VERY_SMALL);

    return (
      <TeamMemberCell
        isSmallCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        }
        className="team-member-cell"
        isMemberCell={false}
      >
        {showExpandAll && (
          <CollapseAllContainer
            isCollapsed={!allMemberRowsOpen}
            onClick={(e) => handleExpandCollapseAll(e)}
          >
            <CollapseAllIcon fill={theme.colors.colorRoyalBlue} />
          </CollapseAllContainer>
        )}
        <StyledNameContainer
          style={{ marginLeft: 24 }}
          onClick={openWorkloadFilter}
        >
          <StyledName hasTopMargin={!shouldShowMemberCount}>
            Total Capacity
          </StyledName>
          {shouldShowMemberCount && (
            <MemberInfoContainer>
              {rootRowCount} Member{rootRowCount === 1 ? '' : 's'}
            </MemberInfoContainer>
          )}
        </StyledNameContainer>
      </TeamMemberCell>
    );
  } else if (group.isMemberBudget) {
    const memberIconSize =
      condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        ? 28
        : condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        ? 18
        : 32;
    const memberBudget = memberBudgetHash[group.member_budget_id];
    const showPositionNumber =
      memberBudget?.position_number !== null &&
      projectUnassignedRoleCounts[memberBudget.position_id] > 1;

    const positionName = `${group.name}${
      showPositionNumber ? ` | ${memberBudget.position_number}` : ''
    }`;
    return (
      <TeamMemberCell
        className="team-member-cell"
        showBorder={true}
        height={undefined}
      >
        <UnassignedMemberIconContainer
          isSmallCondensedView={
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
          }
          isVerySmallCondensedView={
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
          }
        >
          <UnassignedMemberIcon
            height={memberIconSize}
            width={memberIconSize}
          />
        </UnassignedMemberIconContainer>
        <StyledNameContainer className="app-cues-styled-name-container">
          <StyledName
            data-for={'app-tooltip'}
            data-place="top"
            data-effect="solid"
            data-tip={positionName}
            data-delay-show="250"
            data-offset="{'right': -25}"
          >
            {positionName}
          </StyledName>
        </StyledNameContainer>
        <UnassignedSettings
          isSmallCondensedView={
            isCondensedView &&
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
          }
          isVerySmallCondensedView={
            isCondensedView &&
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
          }
        >
          <UnassignedThreeDotMenu
            group={group}
            project={allProjects[group?.projectId]}
            memberBudgetId={group?.member_budget_id}
            modalType={MODAL_TYPE.PROJECT}
            positionName={positionName}
            handleOpenAddMembersForm={handleOpenAddMembersForm}
            handleOpenFindPeopleModal={handleOpenFindPeopleModal}
            setShowMemberSuggestionsOnOpen={setShowMemberSuggestionsOnOpen}
          />
        </UnassignedSettings>
      </TeamMemberCell>
    );
  } else if (group.isPosition) {
    const memberIconSize =
      condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        ? 28
        : condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        ? 18
        : 32;
    const positionName = group?.name;

    return (
      <PositionCell
        className="team-member-cell app-cues-team-member-cell"
        showBorder={true}
        height={undefined}
        isMemberCell={true}
        condensedZoomLevel={condensedZoomLevel}
      >
        <UnassignedMemberIconContainer
          isSmallCondensedView={
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
          }
          isVerySmallCondensedView={
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
          }
        >
          <UnassignedMemberIcon
            height={memberIconSize}
            width={memberIconSize}
          />
        </UnassignedMemberIconContainer>
        <StyledNameContainer className="app-cues-styled-name-container">
          <StyledName
            data-for={'app-tooltip'}
            data-place="top"
            data-effect="solid"
            data-tip={group.name}
            data-delay-show="250"
            data-offset="{'right': -25}"
          >
            {positionName}
          </StyledName>
        </StyledNameContainer>
      </PositionCell>
    );
  } else {
    return <EmptyCell />;
  }
};

const mapStateToProps = (state, ownProps) => ({
  suggestionsHashByAccount: getPredictionHours(state),
  bars: getPrepWorkloadEventScheduleBars(state),
  phases: getFlatPhasesHash(state),
  phasesByProject: getPhasesByProjectHash(state),
  myProjects: getMyProjects(state),
  defaultActivityId: getDefaultActivityId(state),
  WFHProject: getWFHProject(state),
  OOOProject: getOOOProject(state),
  allProjects: getProjectHash(state),
  projectUnassignedRoleCounts: getOwnProjectUnassignedCounts(state, {
    projectId: ownProps.group.projectId
  }),
  memberBudgetHash: getMemberBudgets(state),
  myWorkPlanSettings: getMyWorkPlanSettings(state),
  isOnWorkloadSplitScreen: getIsOnWorkloadSplitScreen(state),
  billRateOnMemberTooltipFlag: getSplitFlags(state).billRateOnMemberTooltipFlag
});

const mapDispatchToProps = {
  openWorkloadMemberRow,
  closeWorkloadMemberRow,
  openWorkloadModal,
  openWorkloadFilter,
  openWorkloadEventsModal,
  fetchPhasesByProjectIds,
  openAllWorkloadMemberRows,
  closeAllWorkloadMemberRows,
  navigateToMemberModal
};

const ConnectedWorkloadPlannerGroupRenderer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkloadPlannerGroupRenderer);

export default WorkloadPlannerGroupRendererWrapper;

export const IconContainer = styled.div`
  display: flex;
  width: 34px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Icons = {
  MembersIcon: (
    <IconContainer>
      <MembersIcon
        currentColor={theme.colors.colorMediumGray9}
        height="13px"
        width="13px"
      />
    </IconContainer>
  ),
  CalendarIcon: (
    <IconContainer>
      <CalendarIcon
        currentColor={theme.colors.colorMediumGray9}
        height="13px"
        width="13px"
      />
    </IconContainer>
  ),
  HomeTaskIcon: (
    <IconContainer>
      <HomeTasksIcon
        currentColor={theme.colors.colorMediumGray9}
        height="14px"
        width="16px"
        strokeWidth="0.7"
      />
    </IconContainer>
  ),
  ProjectScheduleIcon: (
    <IconContainer>
      <ProjectScheduleIcon color={theme.colors.colorMediumGray9} />
    </IconContainer>
  ),
  SendArrowIcon: (
    <IconContainer>
      <SendArrowIcon height="14px" width="16px" strokeWidth="0.7" />
    </IconContainer>
  ),
  PhaseDiamondIcon: (
    <IconContainer>
      <PhaseDiamondIcon height="14px" width="16px" />
    </IconContainer>
  )
};

const toPixel = (val = 0) => `${val}px`;

const getStyleByZoomLevel = (condensedZoomLevel, valueMap) =>
  valueMap[condensedZoomLevel ?? 'default'] ?? valueMap.default;

const StyledTooltip = styled(ReactTooltip)`
  position: absolute !important;
  top: calc(50% - 50px) !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: fit-content;
`;

const StyledSecondaryText = styled.div`
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const StyledRole = styled.div`
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;
const StyledLastNotificationSent = styled.div`
  color: ${theme.colors.colorMediumGray9};
  display: none;
  line-height: 1.5;
  font-size: 10px;
  position: absolute;
  top: 0;
  padding: 5px;
  padding-top: ${({ isCondensedView }) => (isCondensedView ? '0px' : '5px')};
`;

const ArchivedTextContainer = styled.div`
  color: ${theme.colors.colorMediumGray7};
  line-height: 1.5;
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  top: 0;
  padding: 5px;
  padding-top: ${({ isCondensedView }) => (isCondensedView ? '0px' : '5px')};
`;

const EmptyCell = styled.div`
  height: 100%;
`;
const StyledNameContainer = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
  width: ${({ condensedZoomLevel }) =>
    condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
      ? '151px'
      : '141px'};
  margin-top: ${({ hasTopMargin }) => (hasTopMargin ? '5px' : '0px')};
`;
const StyledKaratContainer = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-left: 5px;
  path {
    stroke-width: 1;
    stroke: ${theme.colors.colorSemiDarkGray1};
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};

  &:hover {
    background: ${({ showCapacityHeatmap }) =>
      showCapacityHeatmap ? theme.colors.colorPaleGray6 : ''};
  }
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
`;

const TeamMemberCell = styled.div`
  display: flex;
  padding: ${(props) => {
    if (!props.showCapacityHeatmap && props.isMemberCell) {
      if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM) {
        return '2px 4px';
      } else if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL) {
        return '6px 4px';
      } else if (
        props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
      ) {
        return '4px 4px';
      } else {
        return '20px 4px';
      }
    } else {
      return '0px 4px';
    }
  }};
  align-items: ${(props) =>
    props.showCapacityHeatmap || !props.isMemberCell ? 'center' : 'flex-start'};
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
  padding-bottom: ${(props) => (props.isSmallCondensedView ? '5px' : '0px')};
  ${(props) => props.height && `height: ${props.height}px;`}
  ${(props) =>
    props.isMemberCell &&
    `&:hover ${ThreeDotContainer} {
      .threeDotMenu {
        path {
          fill: ${theme.colors.colorCalendarBlue};
          stroke: ${theme.colors.colorCalendarBlue};
        }
      }
  }`}

  &:hover ${StyledLastNotificationSent} {
    display: block;
  }
`;

const PositionCell = styled(TeamMemberCell)`
  padding-top: ${(props) => {
    if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM) {
      return '8px';
    } else if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL) {
      return '7px';
    } else if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL) {
      return '4px';
    } else {
      return '20px';
    }
  }};
  ${StyledName} {
    margin-top: ${(props) => {
      if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM) {
        return '8px';
      } else if (props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL) {
        return '6px';
      } else if (
        props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
      ) {
        return '0';
      } else {
        return '8px';
      }
    }};
  }
`;

const ThreeDotContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 100px;
  height: 24px;
  width: 24px;
  margin-left: ${(props) =>
    props.isSmallCondensedView || props.isVerySmallCondensedView
      ? '11px'
      : '9px'};
  margin-top: -4px;
  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${theme.colors.colorMediumGray1};
      stroke: ${theme.colors.colorMediumGray1};
    }
  }

  &:hover {
    background: ${theme.colors.colorPaleGray8};
  }
`;

const UnassignedSettings = styled(ThreeDotContainer)`
  height: unset;
  margin-left: ${(props) =>
    props.isSmallCondensedView || props.isVerySmallCondensedView
      ? '21px'
      : '9px'};
`;

const StyledGroupBodyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({
    isCondensedView,
    isMemberView,
    condensedZoomLevel,
    isOnTeamSchedules,
    isSplitScreenRow
  }) =>
    isSplitScreenRow
      ? '0px'
      : isCondensedView
      ? isOnTeamSchedules
        ? `4px`
        : isMemberView
        ? condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
          ? `10px`
          : `2px`
        : condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
        ? '-20px'
        : condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        ? '-31px'
        : condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        ? '-42px'
        : '29px'
      : ``};
`;

export const DropdownWrapper = styled.div`
  padding: 0px;
  width: 100%;
`;

export const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  min-width: 190px;
  color: ${(props) => props.theme.colors.colorSemiDarkGray3};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding: 0 8px;
  .delete-grey-icon {
    display: flex;
  }

  .delete-red-icon {
    display: none;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    color: ${(props) =>
      props.delete
        ? props.theme.colors.colorDeleteRed
        : props.theme.colors.colorSemiDarkGray3};
    cursor: pointer;

    .delete-grey-icon {
      display: none;
    }

    .delete-red-icon {
      display: flex;
    }
  }
`;

const UnassignedMemberIconContainer = styled.div`
  line-height: 0;
  margin-left: ${(props) =>
    props.isSmallCondensedView || props.isVerySmallCondensedView
      ? '30px'
      : '28px'};
`;

const suggestionTopPaddingMap = {
  default: 74,
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 70,
  [CONDENSED_ZOOM_LEVELS.SMALL]: 62,
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: 52
};

const StyledSuggestionsWrapper = styled.div`
  position: absolute;
  width: 250px;
  left: 0px;
  overflow: hidden;
  top: ${(props) =>
    toPixel(
      getStyleByZoomLevel(props.condensedZoomLevel, suggestionTopPaddingMap)
    )};
`;

const StyledSuggestionsContainer = styled.div`
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const suggestionGapMap = {
  default: 8,
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 12,
  [CONDENSED_ZOOM_LEVELS.SMALL]: 4,
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: 4
};

const suggestionItemHeightMap = {
  default: 54,
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 41,
  [CONDENSED_ZOOM_LEVELS.SMALL]: 41,
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: 41
};

const StyledSuggestionsScrollableContainer = styled.div`
  width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: ${(props) =>
    toPixel(
      props.baseHeight -
        getStyleByZoomLevel(props.condensedZoomLevel, suggestionTopPaddingMap)
    )};

  .suggestion,
  .project-schedule-bar-metadata {
    height: ${(props) =>
      toPixel(
        getStyleByZoomLevel(props.condensedZoomLevel, suggestionItemHeightMap)
      )};
  }

  ${StyledSuggestionsContainer} {
    gap: ${(props) =>
      toPixel(getStyleByZoomLevel(props.condensedZoomLevel, suggestionGapMap))};
  }

  .suggestion-top-container {
    flex: ${(props) => (props.isVerySmallCondensedView ? 1 : 'inherit')};
  }

  .hour-container {
    display: ${(props) =>
      props.isVerySmallCondensedView ? 'none' : 'inherit'};
  }
  .suggestion-middle-container {
    display: ${(props) =>
      props.isMediumCondensedView ||
      props.isSmallCondensedView ||
      props.isVerySmallCondensedView
        ? 'none'
        : 'inherit'};
  }

  .suggestion-bottom-container {
    padding-left: ${(props) =>
      props.isMediumCondensedView ||
      props.isSmallCondensedView ||
      props.isVerySmallCondensedView
        ? '1px'
        : '17px'};

    margin-right: ${(props) =>
      props.isVerySmallCondensedView ? '0px' : 'inherit'};
    width: ${(props) =>
      props.isVerySmallCondensedView ? 'initial' : 'inherit'};
  }

  .bar-content {
    ${(props) =>
      props.isVerySmallCondensedView &&
      `
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    `}
  }
`;

const MemberTooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SecondaryTextStyle = css`
  line-height: 1.4;
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
`;

const MemberInfoContainer = styled.div`
  ${SecondaryTextStyle}
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 5px;
  margin-top: ${({ isVerySmallCondensedView }) =>
    isVerySmallCondensedView ? '-8px' : '-3px'};
  margin-left: -5px;
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuggestionsText = styled.div`
  ${SecondaryTextStyle}
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
  cursor: pointer;
`;

const secondaryRowLeftPaddingMap = {
  default: 72,
  verysmall: 60
};
const secondaryRowTopPaddingMap = {
  default: 43,
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 36,
  [CONDENSED_ZOOM_LEVELS.SMALL]: 28,
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: 20
};

const SecondaryRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: ${({ condensedZoomLevel }) =>
    toPixel(
      getStyleByZoomLevel(condensedZoomLevel, secondaryRowLeftPaddingMap)
    )};
  top: ${({ condensedZoomLevel }) =>
    toPixel(
      getStyleByZoomLevel(condensedZoomLevel, secondaryRowTopPaddingMap)
    )};
  ${SecondaryTextStyle}
`;

const TooltipArea = styled.div``;
