import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import {
  BudgetTrackingType,
  BudgetViewSettings
} from 'BudgetModule/models/BudgetViewSettings';
import { getProjectBudgetSettingsHash } from 'BudgetModule/selectors';
import { ProjectId } from 'ProjectsModule/models/project';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useTeamBudgetViewSettings } from './useTeamBudgetViewSettings';
import { v4 as uuid } from 'uuid';
import {
  fetchProjectBudgetViewSettings,
  updateProjectBudgetViewSettings
} from 'BudgetModule/actionCreators';
import FetchManager from 'classes/FetchManager';
import { useUnmount } from 'react-use';
import memoize from 'lodash/memoize';

export const useProjectBudgetViewSettings = (): {
  /**
   * The result of this function is memoized.
   */
  getBudgetViewSettings: (projectId: ProjectId) => BudgetViewSettings;
  getIsHoursOnly: (projectId: ProjectId) => boolean;
  fetchBudgetViewSettings: (projectId: ProjectId, force?: boolean) => void;
  updateBudgetViewSettings: (
    projectId: ProjectId,
    params: Parameters<
      typeof updateProjectBudgetViewSettings
    >[0]['budgetSettings']
  ) => void;
} => {
  const dispatch = useAppDispatch();
  const { projectHoursOnlyFlag } = useFeatureFlags();

  const projectBudgetViewSettingsHash = useAppSelector(
    getProjectBudgetSettingsHash
  );

  const { budgetViewSettings: teamBudgetViewSettings } =
    useTeamBudgetViewSettings();

  // The return values of the function are memoized, since, in most cases, a
  // small fixed set of project settings will be required. `useMemo` is used
  // here instead of `useCallback` because React is otherwise unable to properly
  // deduce the dependencies.
  const getBudgetViewSettings = useMemo(
    () =>
      memoize((projectId: ProjectId): BudgetViewSettings => {
        const budgetViewSettings =
          projectBudgetViewSettingsHash[projectId] ?? teamBudgetViewSettings;

        // Masking the settings to only render currency or hours views, defaulting
        // to cost views. NOTE: This is unrelated to
        // `BudgetViewSettings.budget_tracking_type`.
        const isCost =
          budgetViewSettings.estimated_cost ||
          budgetViewSettings.spent_cost ||
          budgetViewSettings.planned_cost ||
          !(
            budgetViewSettings.estimated_hours ||
            budgetViewSettings.spent_hours ||
            budgetViewSettings.planned_hours
          );
        const isHours = !isCost;

        return {
          ...budgetViewSettings,

          // Currently using `TeamViewSettings.is_hours_only` for budget tracking.
          // Will be removed in https://mosaic-ai.atlassian.net/browse/WEB-4480.
          budget_tracking_type: projectHoursOnlyFlag
            ? budgetViewSettings.budget_tracking_type
            : teamBudgetViewSettings.budget_tracking_type,

          // Percentage
          estimated_percent: true,
          planned_percent: false,
          remaining_percent: false,
          spent_percent: false,

          // Hours
          estimated_hours: isHours,
          planned_hours: isHours,
          remaining_hours: isHours,
          spent_hours: isHours,

          // Cost
          estimated_cost: isCost,
          planned_cost: isCost,
          remaining_cost: isCost,
          spent_cost: isCost,

          // Other constant settings
          decimals: false,
          rate: false
        };
      }),
    [
      projectBudgetViewSettingsHash,
      projectHoursOnlyFlag,
      teamBudgetViewSettings
    ]
  );

  const updateBudgetViewSettings = useCallback(
    (
      projectId: ProjectId,
      updatedSettings: Parameters<
        typeof updateProjectBudgetViewSettings
      >[0]['budgetSettings']
    ) => {
      dispatch(
        updateProjectBudgetViewSettings({
          projectId,
          budgetSettings: updatedSettings,
          meta: {
            showDelayedCalculationNotification:
              updatedSettings.bill_rate_type !== undefined ||
              updatedSettings.rate_group_id !== undefined
          }
        })
      );
    },
    [dispatch]
  );

  // Currently unused
  // const updateIsCostSetting = useCallback(
  //   (isCost: boolean) => {
  //     const isHours = !isCost;
  //     const updatedSettings = {
  //       // Hours
  //       estimated_hours: isHours,
  //       planned_hours: isHours,
  //       remaining_hours: isHours,
  //       spent_hours: isHours,
  //
  //       // Cost
  //       estimated_cost: isCost,
  //       planned_cost: isCost,
  //       remaining_cost: isCost,
  //       spent_cost: isCost
  //     };
  //     updateBudgetViewSettings(updatedSettings);
  //   },
  //   [updateBudgetViewSettings]
  // );

  const getIsHoursOnly = useCallback(
    (projectId: ProjectId) => {
      const budgetViewSettings =
        projectBudgetViewSettingsHash[projectId] ?? teamBudgetViewSettings;

      // Currently using `TeamViewSettings.is_hours_only` for budget tracking.
      // Will be removed in https://mosaic-ai.atlassian.net/browse/WEB-4480.
      const budgetTrackingType = projectHoursOnlyFlag
        ? budgetViewSettings.budget_tracking_type
        : teamBudgetViewSettings.budget_tracking_type;

      return budgetTrackingType === BudgetTrackingType.Hours;
    },
    [
      projectBudgetViewSettingsHash,
      projectHoursOnlyFlag,
      teamBudgetViewSettings
    ]
  );

  // Cleanup on unmount.
  const abortId = useMemo(() => `useProjectBudgetViewSettings>${uuid()}`, []);
  useUnmount(() => FetchManager.abort(abortId));

  const fetchBudgetViewSettings = useCallback(
    (projectId: ProjectId, force?: boolean) => {
      const budgetViewSettings = projectBudgetViewSettingsHash[projectId];

      if (!budgetViewSettings || force)
        dispatch(
          fetchProjectBudgetViewSettings({ projectId, meta: { abortId } })
        );
    },
    [abortId, dispatch, projectBudgetViewSettingsHash]
  );

  return {
    fetchBudgetViewSettings,
    getBudgetViewSettings,
    getIsHoursOnly,
    updateBudgetViewSettings
  };
};
