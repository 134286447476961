import React from 'react';

import {
  MemberInfo,
  SelectRoleContainer,
  UnassignedMemberRateDescription,
  UnassignedMemberName
} from './styles';
import { connect } from 'react-redux';
import {
  getUnassignedMemberBudgetSequence,
  getProjectBudgetSettings
} from 'BudgetModule/selectors';
import { getPositions } from 'BudgetModule/selectors/positions';
import { getOwnProjectUnassignedCounts } from 'selectors';
import RateSelection from './BudgetTable/RateSelection';
import { formatCurrency } from 'BudgetModule/utils';

class UnassignedMemberInfo extends React.Component {
  rateSelectionRef = React.createRef();
  handleRateSelectionClick = (fetchMemberRates, memberBudget) => {
    fetchMemberRates({ memberBudgetId: memberBudget.id });
  };

  render() {
    const {
      memberBudget,
      rate,
      isInTable,
      fetchMemberRates,
      sequenceHash,
      openRateSelectionType,
      budgetSettings,
      projectId,
      showHourlyRate,
      roleHash,
      testIdPrefix,
      projectUnassignedRoleCounts
    } = this.props;
    const sequence = sequenceHash[memberBudget.id];
    // Only one instance can be open at a time. This is the cleanest way to support open on click for unassigned rows that show up on multiple phases
    const role = roleHash[memberBudget.position_id];

    return (
      <MemberInfo
        data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ''}unassigned-${
          memberBudget.position_id
        }`}
      >
        <RateSelection
          handleClick={(e) =>
            this.handleRateSelectionClick(fetchMemberRates, memberBudget)
          }
          memberBudget={memberBudget}
          memberBudgetId={memberBudget && memberBudget.id}
          target={this.rateSelectionRef}
          key={'unassigned-rate'}
          inTableCell={isInTable}
          openRateSelectionType={openRateSelectionType}
          memberName={role?.name || 'Unassigned Member'}
          projectId={projectId}
        >
          <UnassignedMemberRateDescription ref={this.rateSelectionRef}>
            {role?.name}{' '}
            {/* show count in () if there is more than 1 of this unassigned role in the project */}
            {memberBudget.position_number !== null &&
              projectUnassignedRoleCounts[memberBudget.position_id] > 1 &&
              `| ${memberBudget.position_number}`}
          </UnassignedMemberRateDescription>
        </RateSelection>
        <UnassignedMemberName isInTable={isInTable}>
          {isInTable && showHourlyRate
            ? rate?.rate
              ? `${formatCurrency(rate.rate)}/h`
              : 'Set Rate '
            : ''}
          {isInTable ? `` : ` - ${rate?.description}`}
        </UnassignedMemberName>
      </MemberInfo>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sequenceHash: getUnassignedMemberBudgetSequence(state),
  budgetSettings: getProjectBudgetSettings(state, ownProps),
  roleHash: getPositions(state),
  projectUnassignedRoleCounts: getOwnProjectUnassignedCounts(state, {
    projectId: ownProps.projectId
  })
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedMemberInfo);
