import React, { lazy, Suspense } from 'react';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import DevProps from 'components/dev/DevProps';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { SectionRoutes } from 'appConstants/navigation';

const TimesheetStatusTableContainer = lazy(() =>
  import('./TimesheetStatusTableContainer')
);

const TimesheetStatusReportTab = ({
  isDashboardWidgetModal,
  widgetDetailModalThreeDotMenuItems,
  widgetType
}) => {
  const { filterId, viewBy, pageName, activeFilter, widget, filterSections } =
    useReportsViewFilter({
      ...(isDashboardWidgetModal && {
        matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType`,
        widgetType
      })
    });

  return (
    <DynamicModuleLoader
      modules={[getUtilizationModule(), getCapacitiesModule()]}
    >
      <DevProps data={{ filterId, viewBy, pageName, activeFilter }} />
      <Suspense fallback={<div />}>
        <TimesheetStatusTableContainer
          activeFilter={activeFilter}
          viewBy={viewBy}
          pageName={pageName}
          isDashboardWidgetModal={isDashboardWidgetModal}
          widget={widget}
          widgetDetailModalThreeDotMenuItems={
            widgetDetailModalThreeDotMenuItems
          }
          filterStateId="timesheet-status-report"
          filterSections={filterSections}
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};

export default TimesheetStatusReportTab;
