import React from 'react';
import SvgIcon from 'components/SvgIcon';

const LiveChatIcon = ({
  width = '28',
  height = '28',
  fill = '#EB5757',
  className
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0Z"
      fill={fill}
    />
    <rect
      x="8.16663"
      y="5.83301"
      width="14"
      height="11.6667"
      rx="4.28571"
      fill="white"
    />
    <mask
      id="path-3-outside-1_2442:27488"
      maskUnits="userSpaceOnUse"
      x="3.83337"
      y="6.16699"
      width="18"
      height="19"
      fill="black"
    >
      <rect fill="white" x="3.83337" y="6.16699" width="18" height="19" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04766 8.16699C7.27246 8.16699 5.83337 9.60608 5.83337 11.3813V16.6194C5.83337 18.3946 7.27246 19.8337 9.04766 19.8337H11.4864L12.8334 22.1667L14.1803 19.8337H16.6191C18.3943 19.8337 19.8334 18.3946 19.8334 16.6194V11.3813C19.8334 9.60608 18.3943 8.16699 16.6191 8.16699H9.04766Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.04766 8.16699C7.27246 8.16699 5.83337 9.60608 5.83337 11.3813V16.6194C5.83337 18.3946 7.27246 19.8337 9.04766 19.8337H11.4864L12.8334 22.1667L14.1803 19.8337H16.6191C18.3943 19.8337 19.8334 18.3946 19.8334 16.6194V11.3813C19.8334 9.60608 18.3943 8.16699 16.6191 8.16699H9.04766Z"
      fill="white"
    />
    <path
      d="M11.4864 19.8337L12.4143 19.2979L12.105 18.7622H11.4864V19.8337ZM12.8334 22.1667L11.9055 22.7024L12.8334 24.3095L13.7613 22.7024L12.8334 22.1667ZM14.1803 19.8337V18.7622H13.5617L13.2525 19.2979L14.1803 19.8337ZM6.9048 11.3813C6.9048 10.1978 7.86419 9.23842 9.04766 9.23842V7.09556C6.68072 7.09556 4.76195 9.01435 4.76195 11.3813H6.9048ZM6.9048 16.6194V11.3813H4.76195V16.6194H6.9048ZM9.04766 18.7622C7.86419 18.7622 6.9048 17.8028 6.9048 16.6194H4.76195C4.76195 18.9863 6.68073 20.9051 9.04766 20.9051V18.7622ZM11.4864 18.7622H9.04766V20.9051H11.4864V18.7622ZM13.7613 21.631L12.4143 19.2979L10.5585 20.3694L11.9055 22.7024L13.7613 21.631ZM13.2525 19.2979L11.9055 21.631L13.7613 22.7024L15.1082 20.3694L13.2525 19.2979ZM16.6191 18.7622H14.1803V20.9051H16.6191V18.7622ZM18.7619 16.6194C18.7619 17.8028 17.8026 18.7622 16.6191 18.7622V20.9051C18.986 20.9051 20.9048 18.9863 20.9048 16.6194H18.7619ZM18.7619 11.3813V16.6194H20.9048V11.3813H18.7619ZM16.6191 9.23842C17.8026 9.23842 18.7619 10.1978 18.7619 11.3813H20.9048C20.9048 9.01434 18.986 7.09556 16.6191 7.09556V9.23842ZM9.04766 9.23842H16.6191V7.09556H9.04766V9.23842Z"
      fill={fill}
      mask="url(#path-3-outside-1_2442:27488)"
    />
  </SvgIcon>
);

export default LiveChatIcon;
