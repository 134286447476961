import React from 'react';
import { useDispatch } from 'react-redux';
import { updateActivityPhase } from 'BudgetModule/actionCreators';
import DateProgressBar from './DateProgressBar';
import moment from 'moment';
import { EDIT_WORK_CATEGORY_DATES_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useCanEditPhaseDates } from 'PermissionsModule/SpaceLevelPermissions/hooks/project/useCanEditPhaseDates';

const ActivityPhaseProgress = ({
  activityPhase,
  showProgressPercent,
  isDisabled,
  disabledTooltip
}) => {
  const dispatch = useDispatch();

  const canEditActivityPhaseDates = useCanEditPhaseDates({
    projectId: activityPhase.project_id
  });

  const onUpdate = ({ startDate, endDate, workDays }) => {
    if (activityPhase) {
      dispatch(
        updateActivityPhase({
          id: activityPhase.id,
          projectId: activityPhase.project_id,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          workDays
        })
      );
    }
  };

  return (
    <DateProgressBar
      isDisabled={isDisabled || !canEditActivityPhaseDates}
      disabledTooltip={
        !canEditActivityPhaseDates
          ? EDIT_WORK_CATEGORY_DATES_TIP
          : disabledTooltip
      }
      data={activityPhase}
      onSave={onUpdate}
      showProgressPercent={showProgressPercent}
    />
  );
};

export default ActivityPhaseProgress;
