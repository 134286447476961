import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';

class CalendarClickoutListener extends Component {
  constructor(props) {
    super(props);
    bindAll(this, ['handleOutsideClick', 'setWrapperRef']);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick(event) {
    // if click detected OUTSIDE of calendar...
    // nextSibling is quirk of sidebar being rendered as sibling to calendar panel
    const clickedOutside =
      this.wrapper && !this.wrapper.nextSibling.contains(event.target);
    const clickedStartOrEndDate =
      event.target.classList.contains('rc-calendar-picker');
    const clickedPriority = event.target.classList.contains('priority-dot');

    if (clickedOutside && !clickedStartOrEndDate && !clickedPriority) {
      this.props.handleOutsideClick();
    }
  }

  setWrapperRef(domNode) {
    this.wrapper = domNode;
  }

  render() {
    return <div className="calendar-click-wrapper" ref={this.setWrapperRef} />;
  }
}

CalendarClickoutListener.propTypes = {
  handleOutsideClick: PropTypes.func.isRequired
};

export default CalendarClickoutListener;
