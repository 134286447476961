import { ValueOf } from 'type-fest';
import { ParentChildKeyMap } from './types';

export const dependencyComponentType = {
  multiOptionDependency: 'multiOptionDependency',
  singleLineDependency: 'singleLineDependency'
} as const;

export const dependencyType = {
  start: 'start',
  end: 'end',
  startEnd: 'startEnd'
} as const;

// Map parent_attribute_name and child_attribute_name from
// BE dependencies response to dependencyType
export const dependencyAttributeMap = {
  start_date: dependencyType.start,
  end_date: dependencyType.end
} as const;

export const dependencyTypeStrings: Record<
  ValueOf<typeof dependencyType>,
  string
> = {
  [dependencyType.start]: 'Start',
  [dependencyType.end]: 'End',
  [dependencyType.startEnd]: 'Start & End'
} as const;

export const dependencyItemType = {
  phase: 'phase',
  workPlan: 'workPlan',
  task: 'task',
  milestone: 'milestone'
} as const;

export const dependencyItemTypeStrings: Record<
  ValueOf<typeof dependencyItemType>,
  string
> = {
  [dependencyItemType.phase]: 'Phase',
  [dependencyItemType.workPlan]: 'Work Plan',
  [dependencyItemType.task]: 'Task',
  [dependencyItemType.milestone]: 'Milestone'
} as const;

export const dependencyAction = {
  blocking: 'blocking',
  waiting: 'waiting',
  matches: 'matches'
} as const;

export const dependencyActionStrings: Record<
  ValueOf<typeof dependencyAction>,
  string
> = {
  [dependencyAction.blocking]: 'Blocking',
  [dependencyAction.waiting]: 'Waiting',
  [dependencyAction.matches]: 'Matches'
} as const;

export const dependencyDeltaType = {
  atLeast: 'atLeast',
  upTo: 'upTo'
} as const;

export const dependencyDeltaTypeStrings: Record<
  ValueOf<typeof dependencyDeltaType>,
  string
> = {
  atLeast: 'At least',
  upTo: 'Up to'
} as const;

export const dependencyProperty = {
  baseType: 'baseType',
  targetType: 'targetType',
  action: 'action',
  baseItemId: 'baseItemId',
  baseItemType: 'baseItemType',
  targetItemId: 'targetItemId',
  targetItemType: 'targetItemType',
  deltaType: 'deltaType',
  deltaDays: 'deltaDays',
  dependencyId: 'dependencyId'
} as const;

export const dependencyTagKey = {
  base: 'base',
  target: 'target',
  deltaType: 'deltaType',
  deltaDays: 'deltaDays'
};

export const parentChildKeyMap: {
  parent: ParentChildKeyMap;
  child: ParentChildKeyMap;
} = {
  child: {
    baseType: 'child_attribute_name',
    targetType: 'parent_attribute_name',
    baseItemId: 'child_id',
    targetItemId: 'parent_id',
    baseItemType: 'child_type',
    targetItemType: 'parent_type',
    action: 'waiting'
  },
  parent: {
    baseType: 'parent_attribute_name',
    targetType: 'child_attribute_name',
    baseItemId: 'parent_id',
    targetItemId: 'child_id',
    baseItemType: 'parent_type',
    targetItemType: 'child_type',
    action: 'blocking'
  }
} as const;
