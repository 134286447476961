import { select } from 'redux-saga/effects';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { getAuthToken, getSelectedTeamId } from 'selectors';

export function* fetchCustomFieldAllValuesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, internalLabel } = action.payload;

  yield fetchEntity(
    entityActions.fetchCustomFieldAllValues,
    api.fetchCustomFieldAllValues,
    internalLabel,
    [token, teamId],
    action
  );
}
