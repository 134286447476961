import { initialState } from 'reducers/clients';
import { createSelector } from 'reselect';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';

const emptyArray = [];

const getClientsState = (state) => state.clients || initialState;

export const getIsClientsLoaded = createSelector(
  getClientsState,
  (state) => state.loaded
);

export const getClientsOrder = createSelector(
  getClientsState,
  (state) => state.clientsOrder
);

export const getClientsHash = createSelector(
  getClientsState,
  (state) => state.clients
);

export const getOrderedAllClients = createSelector(
  getClientsOrder,
  getClientsHash,
  (ids = [], clients = {}) => ids.map((id) => clients[id])
);

export const getClientIdsByName = createSelector(
  getClientsOrder,
  getClientsHash,
  (ids, clientHash) =>
    ids.reduce((acc, clientId) => {
      acc[clientHash[clientId].title] = clientId;
      return acc;
    }, {})
);

export const getIsFetchingClients = createSelector(
  getClientsState,
  (state) => state.isLoading
);

export const makeGetFilteredClientIds = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (filter) => filter.client_ids || emptyArray
  );

const makeGetFilteredClientNames = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (filter) => filter.clients
  );

// Selected at the top, rest below
export const makeGetOrderedFilterClients = () =>
  createSelector(
    makeGetFilteredClientIds(),
    makeGetFilteredClientNames(),
    getClientsHash,
    getClientsOrder,
    getClientIdsByName,
    (
      filteredClientIds,
      filteredClientNames,
      clientHash,
      clientIds,
      clientIdsByName
    ) => {
      const useClientNames = filteredClientNames.length > 0; // only one or the other is used, so choose the one that has values
      const selectedFilterClientIds = useClientNames
        ? filteredClientNames.map((clientName) => clientIdsByName[clientName])
        : filteredClientIds;
      const selectedFilterClients = selectedFilterClientIds
        .map((id) => clientHash[id])
        .filter((client) => client); // filter may be stale and have clients that no longer exist, so remove those
      const allClients = clientIds.map((id) => clientHash[id]);
      const orderedFilterClients = Array.from(
        new Set([...selectedFilterClients, ...allClients])
      );

      orderedFilterClients.isAllSelected =
        selectedFilterClients.length === allClients.length;
      return orderedFilterClients;
    }
  );
