import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  makeGetActiveWorkloadPlannerFilter,
  getAllSortedAlphabeticalTeamMemberIds,
  getTeamMembersHash,
  getMe
} from 'selectors';
import { updateAccountFilterLocal, fetchPhaseNames } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { filterItemWithWhiteSpace } from 'appUtils/search';

const StyledItemLabel = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  position: relative;
  .member-initials {
    margin-right: 10px;
    width: unset;
  }
`;

const Name = styled.span`
  flex: 1;
`;

const ArchivedLabel = styled.span`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  bottom: -7px;
  left: 41px;
  font-weight: 600;
`;

const copy = {
  searchPlaceholder: 'Search member or select below'
};

const MembersFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  teamMemberIds,
  memberHash,
  me,
  isHidden
}) => {
  const setFilterItems = (nextAccounts) => {
    updateAccountFilterLocal({
      ...activeFilter,
      account_ids: nextAccounts
    });
  };

  const renderLabel = (accountId) => {
    const member = memberHash[accountId];
    return (
      <StyledItemLabel>
        <MemberInitials
          size="small"
          member={member}
          classes={cn('item-account selected', {
            'small-logged-member': accountId === me?.id,
            'small-regular-member': accountId !== me?.id
          })}
          idx={'idx-placeholder'}
          styles={{ marginLeft: 4 }}
          isOnTaskModal
        />
        <Name className="no-text-overflow" title={member.name}>
          {member.name}
        </Name>
        {member.is_archived && <ArchivedLabel>ARCHIVED</ArchivedLabel>}
      </StyledItemLabel>
    );
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={teamMemberIds}
      copy={copy}
      initialSelectedItems={activeFilter.account_ids}
      setFilterItems={setFilterItems}
      itemHash={memberHash}
      labelKey="name"
      toggleLabel="Member"
      renderLabel={renderLabel}
      listHeight={220}
      itemHeight={45}
      filterId={activeFilter.id}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps),
    teamMemberIds: getAllSortedAlphabeticalTeamMemberIds(state),
    memberHash: getTeamMembersHash(state),
    me: getMe(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal,
  fetchPhaseNames,
  filterItemWithWhiteSpace
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MembersFilterDropdown);
