import { useAppSelector } from 'reduxInfra/hooks';
import { getMe } from 'UsersModule/selectors';
import styled from 'styled-components';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import {
  AccountFilterListItem,
  BaseFilterList
} from 'FilterModule/components/FilterListsTable/types';
import { TableRow } from 'components/Table/types';

export const AccountLabel = ({
  rowData
}: {
  rowData: TableRow<AccountFilterListItem, BaseFilterList>;
}) => {
  const { item } = rowData;
  const { name } = item;
  const me = useAppSelector(getMe);

  return (
    <StyledLabel>
      <MemberInitials
        size="small"
        member={item}
        classes={cn('item-account selected', {
          'small-logged-member': item.account.id === me?.id,
          'small-regular-member': item.account.id !== me?.id
        })}
        idx={'idx-placeholder'}
        styles={{ marginLeft: 4 }}
        isOnTaskModal
      />
      <span className="no-text-overflow">{name}</span>
      {item.is_archived && <ArchivedText>ARCHIVED</ArchivedText>}
    </StyledLabel>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledLabel = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  span {
    margin-left: 8px;
  }
`;

const ArchivedText = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  top: 28px;
  left: 39px;
  font-weight: 600;
`;
