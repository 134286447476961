import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportRole
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingRolesByName: Record<string, CsvImportRole>;
  newRoles: React.MutableRefObject<Record<string, boolean>>;
}

export const roleValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingRolesByName, newRoles } = additionalParams;

  const { enteredRole } = getRowValuesForEntityType(row, entityType);

  const existingRoleMatchedByEnteredName = existingRolesByName[enteredRole];

  const enteredRoleIsNewRoleName =
    enteredRole &&
    !existingRoleMatchedByEnteredName &&
    !newRoles.current[enteredRole];

  if (enteredRole === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (enteredRoleIsNewRoleName) {
    newRoles.current[enteredRole] = true;
    return true;
  } else return true;
};
