import { serializeId } from 'appUtils';
import { useCallback } from 'react';
import { FooterRowProps, GroupData } from '../types';
import { ROW_TYPES } from './layout';

export const useFooterRowBuilder = ({
  footerRowProps = {}
}: {
  footerRowProps?: FooterRowProps;
}) => {
  const footerRowBuilder = useCallback(
    ({ groupDataItem }: { groupDataItem: GroupData }) => {
      const serializedId = serializeId({
        itemType: 'footer',
        id: groupDataItem,
        ids: undefined,
        delimiter: undefined
      });
      return {
        id: serializedId,
        rowType: ROW_TYPES.footerRow,
        rowHeight: 60,
        rowData: groupDataItem,
        onFooterRowAddClick: footerRowProps?.onFooterRowAddClick
      };
    },
    [footerRowProps?.onFooterRowAddClick]
  );

  return footerRowBuilder;
};
