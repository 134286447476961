import React from 'react';
import styled from 'styled-components';

import CreateFolderIcon from 'icons/CreateFolderIcon';
import { useDispatch } from 'react-redux';
import { openAddEditGroupModal } from 'actionCreators';
import useCan from 'appUtils/hooks/useCan';
import { createPortfolio } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import { CREATE_PORTFOLIO_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';

export const StyledAddFolderIcon = styled(CreateFolderIcon)`
  margin-right: 4px;
  path {
    stroke-width: 0.8;
  }
`;

const StyledBoardCreateButton = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
  display: flex;
  align-items: center;
`;

const BoardCreateButton = ({ shouldStayOnViewAfterCreate }) => {
  const dispatch = useDispatch();

  const canCreatePortfolio = useCan(createPortfolio);

  const handleClick = React.useCallback(() => {
    dispatch(
      openAddEditGroupModal({
        isEdit: false,
        id: null,
        shouldStayOnViewAfterCreate
      })
    );
  }, [dispatch, shouldStayOnViewAfterCreate]);
  return (
    <StyledBoardCreateButton
      className="add-board-button"
      onClick={canCreatePortfolio ? handleClick : noop}
      data-for="app-tooltip"
      data-effect="solid"
      data-class="center"
      data-tip={CREATE_PORTFOLIO_TIP}
      data-tip-disable={canCreatePortfolio}
    >
      <StyledAddFolderIcon />
      New Portfolio
    </StyledBoardCreateButton>
  );
};
export default BoardCreateButton;
