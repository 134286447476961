import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface SendArrowIconProps
  extends Pick<
    SvgIconProps,
    'className' | 'width' | 'height' | 'fill' | 'style'
  > {
  color?: string;
  strokeWidth?: string | number;
}

const SendArrowIcon = ({
  className = '',
  color = '#4F4F4F',
  fill = 'none',
  width = 13,
  height = 11,
  strokeWidth = '0.5',
  style = {}
}: SendArrowIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 13 11"
    style={style}
  >
    <path
      d="M11.5 5.5L1.11715 1.05021C1.07537 1.0323 1.0333 1.07493 1.05177 1.11647L3 5.5M11.5 5.5L1.11715 9.94979C1.07537 9.9677 1.0333 9.92507 1.05177 9.88353L3 5.5M11.5 5.5H3"
      stroke={color}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default SendArrowIcon;
