export const MAX_NUM_RUNNING_REQUESTS = 1000; // see FetchManager

/* ---------------------------- Request priorities ---------------------------- */

// 'requestPriority' can be given in action.meta (specific to that dispatched instance), or provided as
// argument to fetchEntity (which provides the default priority for the saga worker). It can be either a
// value of RequestPriority or a RequestPriorityGroup id. meta value overrides the saga's default value, and that in
// turn overrides the default priority (DEFAULT_REQUEST_PRIORITY) for all requests fed through FetchManager

export enum RequestPriority {
  Highest = 'HIGHEST', // reserve for actions that other actions/many parts of the app depend on (eg. fetchUser)
  High = 'HIGH',
  Medium = 'MEDIUM', // default
  Low = 'LOW'
}

export const DEFAULT_REQUEST_PRIORITY = RequestPriority.Medium;

export const REQUEST_PRIORITY_ORDER = [
  RequestPriority.Low,
  RequestPriority.Medium,
  RequestPriority.High,
  RequestPriority.Highest
];

export const REQUEST_PRIORITY_RANKS = REQUEST_PRIORITY_ORDER.reduce(
  (acc, cur, index) => {
    acc[cur] = index;
    return acc;
  },
  {} as Record<RequestPriority, number>
);

/**
 * For defining a request priority for groups of actions. If a request priority group is provided
 * as action.meta.requestPriority, the priority related to that group will be used.
 */
export enum RequestPriorityGroup {
  Startup = 'Startup',
  Dashboard = 'Dashboard'
}

export const REQUEST_PRIORITY_GROUP_TO_PRIORITY: Record<
  RequestPriorityGroup,
  RequestPriority
> = {
  [RequestPriorityGroup.Startup]: RequestPriority.Highest,
  [RequestPriorityGroup.Dashboard]: RequestPriority.High
};
