import { ReactNode } from 'react';
import SvgIcon from 'components/SvgIcon';

/**
 * The direction of the arrows of the reverse icon.
 */
type ReverseIconDirection = 'left' | 'right' | 'both';

interface ReverseIconProps {
  /**
   * The HTML class of the SVG element.
   */
  className?: string;

  /**
   * The color of the icon elements.
   */
  color?: string;

  /**
   * The direction of the arrow elements. This allows splitting the icon into
   * two parts to wrap it around text.
   */
  direction?: ReverseIconDirection;

  /**
   * The scale of the icon. Defaults to a width of 8 pixels.
   */
  scale?: number;
}

const DIRECTION_PARAMS: Record<
  ReverseIconDirection,
  { paths: ReactNode; width: number; height: number }
> = {
  left: {
    paths: (
      <>
        <line x1="8" y1="2.5" x2="0" y2="2.5" />
        <line x1="0.35355" y1="2.35355" x2="2.35355" y2="0.35355" />
      </>
    ),
    width: 8,
    height: 5
  },
  right: {
    paths: (
      <>
        <line x1="0" y1="2.5" x2="8" y2="2.5" />
        <line x1="7.64645" y1="2.64645" x2="5.64645" y2="4.64645" />
      </>
    ),
    width: 8,
    height: 5
  },
  both: {
    paths: (
      <>
        <line x1="8" y1="2.5" x2="0" y2="2.5" />
        <line x1="0.35355" y1="2.35355" x2="2.35355" y2="0.35355" />

        <line x1="0" y1="4.5" x2="8" y2="4.5" />
        <line x1="7.64645" y1="4.64645" x2="5.64645" y2="6.64645" />
      </>
    ),
    width: 8,
    height: 7
  }
};

const ReverseIcon = ({
  className,
  color = '#2F80ED',
  direction = 'both',
  scale = 1
}: ReverseIconProps) => {
  const { paths, height, width } = DIRECTION_PARAMS[direction];

  return (
    <SvgIcon
      className={className}
      title="Reverse Icon"
      viewBox={`0 0 ${width} ${height}`}
      height={height * scale}
      width={width * scale}
      stroke={color}
    >
      {paths}
    </SvgIcon>
  );
};

export default ReverseIcon;
