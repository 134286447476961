import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchPhaseNames } from 'actionCreators';
import { getPhaseNames, getHasFetchedPhaseNames } from 'selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { FilterListTypeHook } from 'FilterModule/types';

const emptyObj = {};

export const usePhaseNamesFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.phase_names
}: Parameters<FilterListTypeHook>[0] = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  // List of the team's phase names
  const phaseNames = useAppSelector(getPhaseNames) as string[];

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: phaseNames,
    isOff,
    config,
    resultCountHash,
    shouldUseDraft
  });

  /* --------------------------------- loading -------------------------------- */

  const isLoaded = useAppSelector(getHasFetchedPhaseNames) as boolean;

  // load the phase names if necessary
  useEffect(() => {
    if (!isOff && !isLoaded && teamId) {
      dispatch(fetchPhaseNames({ teamId }));
    }
  }, [dispatch, teamId, isOff, isLoaded]);

  return {
    ...arrayFilterFieldValues,
    isLoading: !isLoaded || isResultsLoading,
    resultCountHash,
    filterLabel: config?.filterLabel
  };
};
