import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES,
  CsvImportFieldName
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, resetTempRef, isNumeric } from './utils';

import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  // { projectTitle: { projectNumber: { phaseName: feeTargetValue } } }
  phaseFeeTargetOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>;
  temporaryPhaseFeeTargetOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>;
  enteredFieldName: CsvImportFieldName;
  notMatchingFeeTargetErrorMessage: string;
}

export const phaseFeeTargetValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const {
    phaseFeeTargetOption,
    temporaryPhaseFeeTargetOption,
    enteredFieldName,
    notMatchingFeeTargetErrorMessage
  } = additionalParams;

  const enteredValues = getRowValuesForEntityType(row, entityType);

  const { enteredProjectTitle, enteredProjectNumber, enteredPhaseName } =
    enteredValues;

  const enteredPhaseFeeTarget = enteredValues[enteredFieldName];

  let res: string | boolean = true;

  if (!enteredProjectTitle || !enteredProjectNumber) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else res = 'Project number is required';
    resetTempRef(temporaryPhaseFeeTargetOption, isLastRow);
    return res;
  }

  const matchedFeeTargetInTemporaryHash =
    temporaryPhaseFeeTargetOption.current[enteredProjectTitle]?.[
      enteredProjectNumber
    ]?.[enteredPhaseName];

  // allow empty as an option in the hashes
  if (enteredPhaseFeeTarget === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else if (enteredPhaseFeeTarget && !isNumeric(enteredPhaseFeeTarget)) {
    // non empty entered value is not a positive numeric value
    res = ERROR_MESSAGES.invalidFeeTarget;
  } else {
    // if the entered value is not added into the temp hash yet, add the entered value as the option, also updates phaseFeeTargetOption to the new entered value
    if (matchedFeeTargetInTemporaryHash === undefined) {
      temporaryPhaseFeeTargetOption.current = {
        ...temporaryPhaseFeeTargetOption.current,
        [enteredProjectTitle]: {
          ...temporaryPhaseFeeTargetOption.current[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...temporaryPhaseFeeTargetOption.current[enteredProjectTitle]?.[
              enteredProjectNumber
            ],
            [enteredPhaseName]: enteredPhaseFeeTarget
          }
        }
      };
      phaseFeeTargetOption.current = {
        ...phaseFeeTargetOption.current,
        [enteredProjectTitle]: {
          ...phaseFeeTargetOption.current[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...phaseFeeTargetOption.current[enteredProjectTitle]?.[
              enteredProjectNumber
            ],
            [enteredPhaseName]: enteredPhaseFeeTarget
          }
        }
      };
      // there is an option stored in the temporary hash => check if the entered value is the same as the stored value
    } else if (matchedFeeTargetInTemporaryHash !== enteredPhaseFeeTarget) {
      res = notMatchingFeeTargetErrorMessage;
    }
  }
  resetTempRef(temporaryPhaseFeeTargetOption, isLastRow);
  return res;
};
