import styled from 'styled-components';
import { isPhaseInactive } from 'appUtils/phaseDisplayUtils';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';
import { StickyWrapper, Sticky, StyledPhaseMilestoneIcon } from '../styles';

export const PhaseDetailsText = ({ isProjectsView, item }) =>
  item.is_like_default || (isProjectsView && item.is_default_activity) ? (
    /* Forces a vertical space if nothing is rendered. */
    <>&#x200b;</>
  ) : (
    <StickyWrapper>
      <Sticky>
        <PhaseTitle>
          {(!isProjectsView || item.is_default_activity) && (
            <>
              <StyledPhaseMilestoneIcon height={14} width={14} />
              <PhaseName isInactive={isPhaseInactive(item)}>
                {item.phase_name}
              </PhaseName>
            </>
          )}
          {!item.is_default_activity && (
            <>
              {!isProjectsView && <StyledSmallDiamondIcon />}
              <PhaseName>{item.activity_title}</PhaseName>
            </>
          )}
        </PhaseTitle>
      </Sticky>
    </StickyWrapper>
  );

const StyledSmallDiamondIcon = styled(SmallDiamondIcon)`
  flex: none;
  margin-left: 5px;
`;

const PhaseName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme, isInactive }) =>
    isInactive && `color: ${theme.colors.colorCalendarGray};`}

  * + & {
    margin-left: 3px;
  }
`;

const PhaseTitle = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
`;
