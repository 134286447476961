import moment from 'moment';
import { getSortedTeamMembers } from 'selectors';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import { PTOState } from 'SettingsModule/reducers/ptos';
import keyBy from 'lodash/keyBy';

const getPtoState: Selector<RootState, PTOState> = (state) => state.pto;

const getPTOByAccountHash = createSelector(
  getPtoState,
  (state) => state.ptoByAccountHash
);

export const getPTOHash = createSelector(getPtoState, (state) => state.ptoHash);

const getPTOMembershipByAccountHash = createSelector(
  getPtoState,
  (state) => state.ptoMembershipByAccountHash
);

export const getPTOPoliciesByDescriptionHash = createSelector(
  getPTOHash,
  (ptos) => keyBy(ptos, (pto) => pto.name)
);

const getPTOIdsOrder = createSelector(
  getPtoState,
  (state) => state.ptoIdsOrder
);

const getSecondaryMembershipByAccountHash = createSelector(
  getPtoState,
  (state) => state.secondaryMembershipByAccountHash
);

const getOrderedPTOPolicies = createSelector(
  getPTOIdsOrder,
  getPTOHash,
  (ids, ptoHash) => ids.flatMap((id) => ptoHash[id] ?? [])
);

export const getOrderedCurrentPTOPolicies = createSelector(
  getOrderedPTOPolicies,
  (ptos) => ptos.filter((pto) => !pto.discarded_at)
);

export const getOrderedArchivedPTOPolicies = createSelector(
  getOrderedPTOPolicies,
  (ptos) => ptos.filter((pto) => !!pto.discarded_at)
);

export const getFormattedTeamMembersPTOWithSecondaryMembershipArray =
  createSelector(
    getPTOByAccountHash,
    getSecondaryMembershipByAccountHash,
    getSortedTeamMembers,
    (ptoMembershipHash, secondaryMembershipHash, members) => {
      return members.flatMap((member) => {
        const policyMembershipForMember = ptoMembershipHash[member.account.id];

        // we want to continue if we have a null policy membership
        if (policyMembershipForMember === undefined) {
          return [];
        }

        const officeIdsForMember: number[] =
          secondaryMembershipHash[member.account.id]?.officeIds ?? [];
        const regionIdsForMember: number[] =
          secondaryMembershipHash[member.account.id]?.regionIds ?? [];

        return [
          {
            member,
            policyMembership: policyMembershipForMember,
            officeIds: officeIdsForMember,
            regionIds: regionIdsForMember
          }
        ];
      });
    }
  );

const getOwnTeamMembershipId = (
  state,
  { teamMembershipId }: { teamMembershipId: number }
) => teamMembershipId;

export const makeGetOrderedMemberPTOPolicies = () =>
  createSelector(
    getPTOMembershipByAccountHash,
    getOwnTeamMembershipId,
    (ptoMembershipByAccountHash, teamMembershipId) => {
      const memberPTOPolicies =
        ptoMembershipByAccountHash[teamMembershipId] ?? [];

      return memberPTOPolicies
        .slice()
        .sort(
          (a, b) =>
            moment(b.start_date).valueOf() - moment(a.start_date).valueOf()
        );
    }
  );
