import * as appConstants from 'appConstants';
import * as constants from '../constants';
import { serializeId } from 'appUtils';

const { BUDGET_RECORD_DATA_TYPES: DATA_TYPES } = appConstants;

export const initialFilterState = {
  //    eg. { 'Position--1': [phaseIds]}
  //    eg. from workloadPlanner reducer
  //    when there is more nesting (grouped by date then member)
  //   {
  //     'july': ['july-5319', 'july-5312'],
  //     'july-5319': [workplanIds],
  //     'july-5312': [workplanIds]
  //   }
  ordersByGroup: {},
  // eg. [positionIds]
  topLevelOrder: [],
  isFetching: false,
  // keyed by eg. aggregate data type 'PositionPhase', then by position id
  totalCounts: {},
  // total demand and capacity are the total amounts for the current filter values
  totalDemand: 0,
  totalDemandAssigned: 0,
  totalDemandNonTentative: 0,
  totalDemandNonTentativeAssigned: 0,
  totalDemandNonTentativeUnassigned: 0,
  totalDemandTentative: 0,
  totalDemandTentativeAssigned: 0,
  totalDemandTentativeUnassigned: 0,
  totalDemandUnassigned: 0,
  totalCapacity: 0, // actually availability
  totalAvailableCapacity: 0,
  totalRawCapacity: 0, // original capacity without omitting pto and holiday hours
  totalPto: 0,
  totalHolidayHours: 0,
  intervalRecords: []
};

export const initialState = {
  // keyed by filter id
  filterStates: {},
  // keyed by some uid eg. 'PositionPhase--2--31075'
  data: {}
};

const capacityReport = (state = initialState, action) => {
  const { type, payload } = action;
  const {
    filterStateId,
    requestPayload = {},
    initial,
    response
  } = payload || {};
  const nextState = { ...state };

  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_QUEUED_CAPACITY_REPORT.TRIGGER:
    case constants.FETCH_CAPACITY_REPORT.TRIGGER: {
      if (filterStateId) {
        nextState.filterStates = {
          ...state.filterStates,
          [filterStateId]: {
            ...(initial || !state.filterStates[filterStateId]
              ? initialFilterState
              : state.filterStates[filterStateId]),
            isFetching: true
          }
        };
      }
      return nextState;
    }

    case constants.FETCH_QUEUED_CAPACITY_REPORT.FAILURE:
    case constants.FETCH_CAPACITY_REPORT.FAILURE: {
      const { filterStateId } = requestPayload;
      if (filterStateId) {
        nextState.filterStates = {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            isFetching: false
          }
        };
      }
      return nextState;
    }

    case constants.FETCH_QUEUED_CAPACITY_REPORT.SUCCESS:
    case constants.FETCH_CAPACITY_REPORT.SUCCESS: {
      const {
        filterStateId,
        params: {
          data_type,
          position_ids,
          account_ids,
          project_ids,
          client_ids,
          phase_ids
        },
        isProjectBreakdown,
        isFetchingInterval
      } = requestPayload;
      const {
        total_count,
        total_demand,
        total_capacity,
        total_available_capacity,
        total_raw_capacity,
        total_pto_hours,
        total_holiday_hours,
        total_demand_assigned,
        total_demand_non_tentative,
        total_demand_non_tentative_assigned,
        total_demand_non_tentative_unassigned,
        total_demand_tentative,
        total_demand_tentative_assigned,
        total_demand_tentative_unassigned,
        total_demand_unassigned,
        records
      } = response;

      if (filterStateId) {
        const nextFilterState = {
          ...nextState.filterStates[filterStateId],
          isFetching: false
        };

        // interval data will go into nextFilterState.intervalRecords
        if (isFetchingInterval) {
          nextFilterState.intervalRecords = records;
          nextState.filterStates = {
            ...nextState.filterStates,
            [filterStateId]: nextFilterState
          };

          nextFilterState.totalDemand = total_demand;
          nextFilterState.totalCapacity = total_capacity;
          nextFilterState.totalAvailableCapacity = total_available_capacity;
          nextFilterState.totalRawCapacity = total_raw_capacity;
          nextFilterState.totalPto = total_pto_hours;
          nextFilterState.totalHolidayHours = total_holiday_hours;
          nextFilterState.totalDemandAssigned = total_demand_assigned;
          nextFilterState.totalDemandNonTentative = total_demand_non_tentative;
          nextFilterState.totalDemandNonTentativeAssigned =
            total_demand_non_tentative_assigned;
          nextFilterState.totalDemandNonTentativeUnassigned =
            total_demand_non_tentative_unassigned;
          nextFilterState.totalDemandTentative = total_demand_tentative;
          nextFilterState.totalDemandTentativeAssigned =
            total_demand_tentative_assigned;
          nextFilterState.totalDemandTentativeUnassigned =
            total_demand_tentative_unassigned;
          nextFilterState.totalDemandUnassigned = total_demand_unassigned;
        } else {
          switch (data_type) {
            // Top level groupings
            case 'Team':
            case DATA_TYPES.PROJECT:
            case DATA_TYPES.ACCOUNT:
            case DATA_TYPES.CLIENT:
            case DATA_TYPES.POSITION: {
              const idToCheck =
                data_type === DATA_TYPES.PROJECT
                  ? 'project_id'
                  : data_type === DATA_TYPES.ACCOUNT
                  ? 'account_id'
                  : data_type === DATA_TYPES.POSITION
                  ? 'position_id'
                  : data_type === DATA_TYPES.CLIENT
                  ? 'client_id'
                  : 'team_id';

              nextFilterState.topLevelOrder = [
                ...nextFilterState.topLevelOrder,
                ...records.map((record) => record[idToCheck])
              ];

              nextFilterState.totalCounts = {
                ...nextFilterState.totalCounts,
                [data_type]: total_count
              };
              nextFilterState.totalDemand = total_demand;
              nextFilterState.totalCapacity = total_capacity;
              nextFilterState.totalAvailableCapacity = total_available_capacity;
              nextFilterState.totalRawCapacity = total_raw_capacity;
              nextFilterState.totalPto = total_pto_hours;
              nextFilterState.totalHolidayHours = total_holiday_hours;
              nextFilterState.totalDemandAssigned = total_demand_assigned;
              nextFilterState.totalDemandNonTentative =
                total_demand_non_tentative;
              nextFilterState.totalDemandNonTentativeAssigned =
                total_demand_non_tentative_assigned;
              nextFilterState.totalDemandNonTentativeUnassigned =
                total_demand_non_tentative_unassigned;
              nextFilterState.totalDemandTentative = total_demand_tentative;
              nextFilterState.totalDemandTentativeAssigned =
                total_demand_tentative_assigned;
              nextFilterState.totalDemandTentativeUnassigned =
                total_demand_tentative_unassigned;
              nextFilterState.totalDemandUnassigned = total_demand_unassigned;

              // add records to data hash, keyed by uid
              records.forEach((record) => {
                const uid = serializeId({
                  itemType: data_type,
                  ids: [record[idToCheck]]
                });
                nextState.data = {
                  ...nextState.data,
                  [uid]: record
                };
              });
              break;
            }

            /**
             *  This is specifically for AccountProject grouping with multipel ids for Project breakdown view
             *  The data is not specific for a single ID
             *  If AccountProject needs to be used as sub level, use else {} or else if {}
             */
            case DATA_TYPES.CLIENT_PROJECT:
            case DATA_TYPES.ACCOUNT_PROJECT: {
              if (isProjectBreakdown) {
                const projectsGroupedByUids = {};
                const projectsLengthGroupedByIds = {};

                /**
                 *  Set the default values to an empty object and 0
                 *  If no data found on the BE, the result will be an empty array ergo necessary fields won't be
                 * created which will result in infinite loading because there is no value to read to determine
                 * whether it is fully loaded or not.
                 */
                const ids =
                  data_type === DATA_TYPES.ACCOUNT_PROJECT
                    ? account_ids
                    : client_ids;

                ids.forEach((id) => {
                  const uid = serializeId({
                    ids: [id],
                    itemType:
                      data_type === DATA_TYPES.ACCOUNT_PROJECT
                        ? DATA_TYPES.ACCOUNT
                        : DATA_TYPES.CLIENT
                  });

                  projectsGroupedByUids[uid] = {
                    uid,
                    id: id,
                    projects: {}
                  };
                  projectsLengthGroupedByIds[id] = 0;
                });

                records.forEach((record) => {
                  const { account_id, client_id, project_id } = record;
                  const uid = serializeId({
                    itemType:
                      data_type === DATA_TYPES.ACCOUNT_PROJECT
                        ? DATA_TYPES.ACCOUNT
                        : DATA_TYPES.CLIENT,
                    ids: [
                      data_type === DATA_TYPES.ACCOUNT_PROJECT
                        ? account_id
                        : client_id
                    ]
                  });
                  const projectUid = serializeId({
                    itemType:
                      data_type === DATA_TYPES.ACCOUNT_PROJECT
                        ? DATA_TYPES.ACCOUNT_PROJECT
                        : DATA_TYPES.CLIENT_PROJECT,
                    ids: [
                      data_type === DATA_TYPES.ACCOUNT_PROJECT
                        ? account_id
                        : client_id,
                      project_id
                    ]
                  });

                  projectsGroupedByUids[uid].projects[project_id] = record;
                  nextState.data = {
                    ...nextState.data,
                    [projectUid]: record
                  };
                });

                const projectIdsByView = Object.values(
                  projectsGroupedByUids
                ).reduce((acc, cur) => {
                  const projectIds = Object.keys(cur.projects);
                  projectsLengthGroupedByIds[cur.id] = projectIds.length;
                  acc[cur.uid] = projectIds;
                  return acc;
                }, {});

                nextFilterState.ordersByGroup = {
                  ...nextFilterState.ordersByGroup,
                  ...projectIdsByView
                };

                const totalCountDataType =
                  data_type === DATA_TYPES.ACCOUNT_PROJECT
                    ? DATA_TYPES.ACCOUNT_PROJECT
                    : DATA_TYPES.CLIENT_PROJECT;
                nextFilterState.totalCounts = {
                  ...nextFilterState.totalCounts,
                  [totalCountDataType]: {
                    ...nextFilterState.totalCounts[totalCountDataType],
                    ...projectsLengthGroupedByIds
                  }
                };
              } else {
                const isAccountProject =
                  data_type === DATA_TYPES.ACCOUNT_PROJECT;
                const idsToCheck = isAccountProject ? account_ids : client_ids;
                const upperLevelDataType = serializeId({
                  itemType: isAccountProject
                    ? DATA_TYPES.ACCOUNT
                    : DATA_TYPES.CLIENT,
                  ids: [idsToCheck[0]]
                });

                if (idsToCheck?.length) {
                  nextFilterState.ordersByGroup = {
                    ...nextFilterState.ordersByGroup,
                    [upperLevelDataType]: [
                      ...(nextFilterState.ordersByGroup[upperLevelDataType] ||
                        []),
                      ...records.map((record) => record.project_id)
                    ]
                  };

                  nextFilterState.totalCounts = {
                    ...nextFilterState.totalCounts,
                    [data_type]: {
                      ...nextFilterState.totalCounts[data_type],
                      [idsToCheck[0]]: total_count
                    }
                  };

                  records.forEach((record) => {
                    const uid = serializeId({
                      itemType: data_type,
                      ids: [idsToCheck[0], record.project_id]
                    });
                    nextState.data = {
                      ...nextState.data,
                      [uid]: record
                    };
                  });
                }
              }

              break;
            }

            // Sub level for phases under project under account or phases under projects on project tab.
            case DATA_TYPES.CLIENT_PHASE:
            case DATA_TYPES.ACCOUNT_PHASE: {
              const { isMembersView, isClientsView } = requestPayload;
              if (
                (isMembersView && account_ids?.length) ||
                (isClientsView && client_ids?.length)
              ) {
                const upperLevelId = [
                  isMembersView ? account_ids[0] : client_ids[0],
                  project_ids[0]
                ];
                const topLevelUid = serializeId({
                  // This type is not supported by BE
                  itemType: isMembersView
                    ? DATA_TYPES.PROJECT
                    : DATA_TYPES.CLIENT_PROJECT,
                  ids: upperLevelId
                });

                nextFilterState.ordersByGroup = {
                  ...nextFilterState.ordersByGroup,
                  [topLevelUid]: [
                    ...(nextFilterState.ordersByGroup[topLevelUid] || []),
                    ...records.map((record) => record.phase_id)
                  ]
                };

                nextFilterState.totalCounts = {
                  ...nextFilterState.totalCounts,
                  [topLevelUid]: total_count
                };

                records.forEach((record) => {
                  const uid = serializeId({
                    itemType: data_type,
                    ids: [
                      isMembersView ? record.account_id : record.client_id,
                      record.project_id,
                      record.phase_id
                    ]
                  });
                  nextState.data = {
                    ...nextState.data,
                    [uid]: record
                  };
                });
              } else {
                const idsToCheck = account_ids?.length
                  ? account_ids
                  : phase_ids;
                const upperLevelDataType = account_ids?.length
                  ? DATA_TYPES.ACCOUNT
                  : DATA_TYPES.PHASE;

                if (idsToCheck?.length) {
                  const upperLevelId = idsToCheck[0];
                  const topLevelUid = serializeId({
                    itemType: upperLevelDataType,
                    ids: [upperLevelId]
                  });
                  nextFilterState.ordersByGroup = {
                    ...nextFilterState.ordersByGroup,
                    [topLevelUid]: [
                      ...(nextFilterState.ordersByGroup[topLevelUid] || []),
                      ...records.map((record) =>
                        upperLevelDataType === DATA_TYPES.PHASE
                          ? record.account_id || `Generic-${record.position_id}`
                          : record.phase_id
                      )
                    ]
                  };

                  nextFilterState.totalCounts = {
                    ...nextFilterState.totalCounts,
                    [data_type]: {
                      ...nextFilterState.totalCounts[data_type],
                      // key by top level id to know its num of phases
                      [upperLevelId]: total_count
                    }
                  };

                  // add records to data hash, keyed by uid
                  // for account_phase -> when top level is Account, uid is AccountPhase-{accountId}-{phaseId}
                  //                   -> when top level is Phase, uid is AccountPhase-{phaseId}-{accountId}
                  records.forEach((record) => {
                    const uid = serializeId({
                      itemType: data_type,
                      ids: [
                        ...(data_type === DATA_TYPES.POSITION_PHASE
                          ? [record.position_id]
                          : data_type === DATA_TYPES.ACCOUNT_PHASE
                          ? upperLevelDataType === DATA_TYPES.PHASE
                            ? [record.phase_id]
                            : [record.account_id]
                          : []), // data type is Phase -> uid is just Phase-{phaseId}
                        ...(upperLevelDataType === DATA_TYPES.PHASE
                          ? [
                              record.account_id ||
                                `Generic-${record.position_id}`
                            ] // generic members will have account_id null
                          : [record.phase_id])
                      ]
                    });
                    nextState.data = {
                      ...nextState.data,
                      [uid]: record
                    };
                  });
                }
              }
              break;
            }

            // Sub levels
            case DATA_TYPES.PHASE:
            case DATA_TYPES.POSITION_PHASE: {
              let idsToCheck;
              let upperLevelDataType;

              switch (data_type) {
                case DATA_TYPES.PHASE:
                  idsToCheck = project_ids;
                  upperLevelDataType = DATA_TYPES.PROJECT;
                  break;
                case DATA_TYPES.ACCOUNT_PHASE:
                  // account_ids.length -> phases under account
                  // phase_ids.length -> accounts under phase
                  idsToCheck = account_ids?.length ? account_ids : phase_ids;
                  upperLevelDataType = account_ids?.length
                    ? DATA_TYPES.ACCOUNT
                    : DATA_TYPES.PHASE;
                  break;
                case DATA_TYPES.CLIENT_PROJECT:
                  idsToCheck = client_ids;
                  upperLevelDataType = DATA_TYPES.CLIENT;
                  break;
                default:
                  // POSITION_PHASE
                  idsToCheck = position_ids;
                  upperLevelDataType = DATA_TYPES.POSITION;
              }

              if (idsToCheck?.length) {
                // Loading for sub level => only one top level id
                const upperLevelId = idsToCheck[0];

                const topLevelUid = serializeId({
                  itemType: upperLevelDataType,
                  ids: [upperLevelId]
                });

                nextFilterState.ordersByGroup = {
                  ...nextFilterState.ordersByGroup,
                  // key by top level uid eg. Position--1 to know its phase order
                  [topLevelUid]: [
                    ...(nextFilterState.ordersByGroup[topLevelUid] || []),
                    ...records.map((record) =>
                      upperLevelDataType === DATA_TYPES.PHASE
                        ? record.account_id || `Generic-${record.position_id}`
                        : upperLevelDataType === DATA_TYPES.CLIENT
                        ? record.project_id
                        : record.phase_id
                    )
                  ]
                };

                nextFilterState.totalCounts = {
                  ...nextFilterState.totalCounts,
                  [data_type]: {
                    ...nextFilterState.totalCounts[data_type],
                    // key by top level id to know its num of phases
                    [upperLevelId]: total_count
                  }
                };

                // add records to data hash, keyed by uid
                // for account_phase -> when top level is Account, uid is AccountPhase-{accountId}-{phaseId}
                //                   -> when top level is Phase, uid is AccountPhase-{phaseId}-{accountId}
                records.forEach((record) => {
                  const uid = serializeId({
                    itemType: data_type,
                    ids: [
                      ...(data_type === DATA_TYPES.POSITION_PHASE
                        ? [record.position_id]
                        : data_type === DATA_TYPES.CLIENT_PROJECT
                        ? [record.client_id]
                        : data_type === DATA_TYPES.ACCOUNT_PHASE
                        ? upperLevelDataType === DATA_TYPES.PHASE
                          ? [record.phase_id]
                          : [record.account_id]
                        : []), // data type is Phase -> uid is just Phase-{phaseId}
                      ...(upperLevelDataType === DATA_TYPES.PHASE
                        ? [record.account_id || `Generic-${record.position_id}`] // generic members will have account_id null
                        : upperLevelDataType === DATA_TYPES.CLIENT
                        ? [record.project_id]
                        : [record.phase_id])
                    ]
                  });
                  nextState.data = {
                    ...nextState.data,
                    [uid]: record
                  };
                });
              }
              break;
            }

            default:
              break;
          }
          nextState.filterStates = {
            ...nextState.filterStates,
            [filterStateId]: nextFilterState
          };
        }
      }

      return nextState;
    }

    default:
      return state;
  }
};

export default capacityReport;
