import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import ColorPickerButton from './ColorPickerButton';
import ColorPickerInput from './ColorPickerInput';
import { StyledColorPicker } from './styles';
import { openColorPicker, closeColorPicker } from 'actionCreators';
import {
  makeGetColorPickerIsOpen,
  makeGetPreferenceExistence
} from 'selectors';
import { originTypeToColorsHash } from 'appUtils/styleUtils';
import Popover from 'components/Popover';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import {
  EDIT_COLOR_TIP,
  EDIT_PROJECT_COLOR_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';

import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

class ColorPicker extends React.Component {
  state = { ref: null };
  setButtonRef = (ref) => {
    this.buttonRef = ref;
    if (ref !== this.state.ref) this.setState({ ref });
    /* in certain cases, because PopoverWrapper is lower in the render method, it'll mount before buttonRef is set,
        meaning there will be a null target for the popover. rerendering solves this */
  };

  componentWillUnmount() {
    const { isOpen, id, originType, pickerLocation, closeColorPicker } =
      this.props;
    /* The pickerLocation should be unique for components within the same page to avoid
    multiple color picker opened and is only used to determine which color picker to close,
    it has nothing to do with location of the app. */
    if (isOpen) closeColorPicker({ id, originType, pickerLocation });
  }

  componentDidMount() {
    const {
      isOpen,
      alwaysOpen,
      id,
      originType,
      pickerLocation,
      openColorPicker
    } = this.props;
    if (!isOpen && alwaysOpen)
      openColorPicker({ id, originType, pickerLocation });
  }

  handleClose = (e) => {
    const {
      id,
      originType,
      pickerLocation,
      openColorPicker,
      closeColorPicker,
      isOpen,
      stopPropagation,
      canEditColors
    } = this.props;
    /* The pickerLocation should be unique for components within the same page to avoid
    multiple color picker opened and is only used to determine which color picker to open/close,
    it has nothing to do with location of the app. */
    if (isOpen) closeColorPicker({ id, originType, pickerLocation });
    else if (canEditColors) openColorPicker({ id, originType, pickerLocation });

    stopPropagation && e && e.stopPropagation && e.stopPropagation();
  };

  renderButton = () => {
    const { children, isOpen, id, originType, alwaysShow, canEditColors } =
      this.props;
    const isDisabled = !canEditColors;
    if (children && !isDisabled) {
      return React.Children.map(children, (child) =>
        React.cloneElement(child, { ref: this.setButtonRef })
      );
    } else {
      return (
        <ColorPickerButton
          className="color-picker-button"
          id={id}
          innerRef={this.setButtonRef}
          isOpen={isOpen}
          originType={originType}
          alwaysShow={alwaysShow}
          isDisabled={isDisabled}
        />
      );
    }
  };

  PopoverWrapper = (children) => {
    const { isOpen, alwaysOpen, shouldHide, boundariesElement, className } =
      this.props;
    return (
      <Popover
        boundariesElement={boundariesElement}
        placement="bottom-start"
        isOpen={(isOpen || alwaysOpen) && !shouldHide}
        target={this.buttonRef}
        closePopover={this.handleClose}
        className={className}
      >
        {children}
      </Popover>
    );
  };

  handleSelect = () => {
    const { id, originType, pickerLocation, closeColorPicker, handleSelect } =
      this.props;
    if (handleSelect) handleSelect();
    /* The pickerLocation should be unique for components within the same page to avoid
    multiple color picker opened and is only used to determine which color picker to close,
    it has nothing to do with location of the app. */
    closeColorPicker({ id, originType, pickerLocation });
  };

  render() {
    const {
      id,
      isOpen,
      row,
      preferenceExists,
      originType,
      alwaysOpen,
      showInPopover,
      headerText,
      className,
      apiProps,
      preference,
      memberName,
      memberInitials,
      canEditColors
    } = this.props;
    return (
      <StyledColorPicker
        $open={isOpen}
        $preferenceExists={preferenceExists}
        $row={row}
        className={cn('project-color-picker', className)}
        {...defaultTooltipProps}
        data-tip-disable={canEditColors}
        data-tip={
          originType === ORIGIN_TYPE_STRINGS.PROJECT
            ? EDIT_PROJECT_COLOR_TIP
            : EDIT_COLOR_TIP
        }
        onClick={this.handleClose}
      >
        {this.renderButton()}
        <ColorPickerInput
          handleSelect={this.handleSelect}
          handleClose={this.handleClose}
          colors={originTypeToColorsHash[originType]}
          id={id}
          originType={originType}
          isOpen={isOpen}
          alwaysOpen={alwaysOpen}
          showInPopover={showInPopover}
          apiProps={apiProps}
          headerText={headerText}
          PopoverWrapper={this.PopoverWrapper}
          preference={preference} // for items that carry own preference independent of userTheme
          memberName={memberName}
          memberInitials={memberInitials}
        />
      </StyledColorPicker>
    );
  }
}

const makeMapStateToProps = () => {
  const getColorPickerIsOpen = makeGetColorPickerIsOpen();
  const getPreferenceExistence = makeGetPreferenceExistence();
  const mapStateToProps = (state, ownProps) => ({
    isOpen: getColorPickerIsOpen(state, ownProps),
    preferenceExists: getPreferenceExistence(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = { openColorPicker, closeColorPicker };

const ColorPickerWrapper = connect(
  makeMapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(ColorPicker);

const ColorPickerPermissionWrapper = (props) => {
  /**
    Export this wrapper component to pass projectId to project permission hook.
    Using the project permission HOC would require updating every user of ColorPicker
    to have permissionProps prop containing projectid.
   */
  const { projectId, originType } = props;
  const { canEditProjectColors } = useProjectPermissionState({ projectId });
  return (
    <ColorPickerWrapper
      {...props}
      canEditColors={
        // Check edit project color permission if color picker is editing project color
        originType !== ORIGIN_TYPE_STRINGS.PROJECT || canEditProjectColors
      }
    />
  );
};

export default ColorPickerPermissionWrapper;
