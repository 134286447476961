import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CalendarIcon = ({
  width = '13',
  height = '13',
  className,
  currentColor = '#808080'
}) => (
  <SvgIcon
    className={className}
    title="Calendar Icon"
    width={width}
    height={height}
    strokeWidth={0}
    viewBox="0 0 13 13"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90898 1.77273C4.90898 2.19949 4.56302 2.54545 4.13625 2.54545C3.70949 2.54545 3.36353 2.19949 3.36353 1.77273C3.36353 1.34596 3.70949 1 4.13625 1C4.56302 1 4.90898 1.34596 4.90898 1.77273ZM5.80823 2.36328C5.56498 3.05199 4.90824 3.54545 4.13625 3.54545C3.36426 3.54545 2.70753 3.05199 2.46427 2.36328H0V1.36328H2.41105C2.59586 0.581633 3.29813 0 4.13625 0C4.97438 0 5.67665 0.581633 5.86146 1.36328H13V2.36328H5.80823Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90898 11.1726C4.90898 11.5994 4.56302 11.9454 4.13625 11.9454C3.70949 11.9454 3.36353 11.5994 3.36353 11.1726C3.36353 10.7459 3.70949 10.3999 4.13625 10.3999C4.56302 10.3999 4.90898 10.7459 4.90898 11.1726ZM5.80823 11.7632C5.56498 12.4519 4.90824 12.9454 4.13625 12.9454C3.36426 12.9454 2.70753 12.4519 2.46427 11.7632H0V10.7632H2.41105C2.59586 9.98154 3.29813 9.3999 4.13625 9.3999C4.97438 9.3999 5.67665 9.98154 5.86146 10.7632H13V11.7632H5.80823Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63627 6.50027C9.63627 6.92703 9.29031 7.27299 8.86355 7.27299C8.43678 7.27299 8.09082 6.92703 8.09082 6.50027C8.09082 6.0735 8.43678 5.72754 8.86355 5.72754C9.29031 5.72754 9.63627 6.0735 9.63627 6.50027ZM10.5355 7.09082C10.2923 7.77953 9.63554 8.27299 8.86355 8.27299C8.09156 8.27299 7.43482 7.77953 7.19157 7.09082H0V6.09082H7.13834C7.32315 5.30917 8.02542 4.72754 8.86355 4.72754C9.70167 4.72754 10.4039 5.30917 10.5888 6.09082H13V7.09082H10.5355Z"
        fill={currentColor}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="12.9998" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CalendarIcon;
