import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';
import {
  getIsOfficesLoaded,
  getIsLoadingOffices
} from 'SettingsModule/selectors/offices';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const emptyObj = {};

export const useLoadOffices = ({
  isOff,
  shouldAutoLoad = true
}: {
  isOff?: boolean;
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const isLoaded = useAppSelector(getIsOfficesLoaded);
  const isLoading = useAppSelector(getIsLoadingOffices);

  const loadOffices = useCallback(() => {
    if (!isLoaded && !isLoading && teamId) {
      dispatch(fetchOffices({ teamId }));
    }
  }, [dispatch, isLoaded, teamId, isLoading]);

  // load offices if necessary
  useEffect(() => {
    if (!isOff && shouldAutoLoad) {
      loadOffices();
    }
  }, [loadOffices, shouldAutoLoad, isOff]);

  return {
    isLoaded,
    isLoading,
    loadOffices
  };
};
