import { createSelector, Selector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { TeamPermissionsState } from 'PermissionsModule/reducers/types';
import { RootState } from 'reduxInfra/shared';
import {
  ACCESS_ROLES,
  permissionsActionsArray
} from 'PermissionsModule/constants';
import { PermissionsActions } from 'PermissionsModule/types';
import { roleTemplates } from './constants';
import { RoleTemplates, RolePermission } from './types';
import {
  makeGetTeamMembershipByAccountId,
  getSortedTeamMembers,
  getSelectedTeam
} from 'TeamsModule/selectors';
import { permissionsUtils } from 'PermissionsModule/utils';
import { TeamMember } from 'TeamsModule/models/teamMember';
import { getMe } from 'UsersModule/selectors';
import { Entries } from 'type-fest';

const byMemberAccountId = (item: TeamMember) => item.account.id;

const getTeamPermissionsState: Selector<RootState, TeamPermissionsState> = (
  state
) => state.teamPermissions;

export const getTeamRoleTemplates = createSelector(
  getTeamPermissionsState,
  (state) => state.teamRoleTemplates
);

export const getOneOffPermissionersMap = createSelector(
  getTeamPermissionsState,
  (state) => state.oneOffPermissionersMap
);

export const getTeamRoleTemplatesByName = createSelector(
  getTeamRoleTemplates,
  (getTeamRoleTemplates) =>
    keyBy(Object.values(getTeamRoleTemplates), (item) => item.name)
);

export const getPermissionActionsExpanded = createSelector(
  getTeamPermissionsState,
  (state) => state.expanded
);

export const getAllPermissionActionsExpanded = createSelector(
  getPermissionActionsExpanded,
  (permissionActions) =>
    Object.values(permissionActions).every((action) => !!action)
);

export const getAllPermissionsByActions = createSelector(
  getTeamRoleTemplatesByName,
  (teamRoleTemplates) =>
    permissionsActionsArray.reduce<
      Partial<Record<PermissionsActions, Record<RoleTemplates, RolePermission>>>
    >((actionHash, permissionAction) => {
      actionHash[permissionAction] = roleTemplates.reduce<
        Record<RoleTemplates, RolePermission>
      >((acc, cur: RoleTemplates) => {
        const teamRoleTemplate = teamRoleTemplates[cur];

        if (teamRoleTemplate) {
          acc[cur] = teamRoleTemplate[permissionAction];
        }
        return acc;
      }, {} as Record<RoleTemplates, RolePermission>);
      return actionHash;
    }, {})
);

/**
 * Gives you the options that should be on the permission.
 */
export const getPermissionOptionsByActions = createSelector(
  getAllPermissionsByActions,
  (permissionsByAction) => {
    return (
      Object.entries(permissionsByAction) as Entries<typeof permissionsByAction>
    ).reduce<Partial<Record<PermissionsActions, RoleTemplates[]>>>(
      (permissionOptionHash, [action, permissions]) => {
        permissionOptionHash[action] = permissions
          ? (Object.entries(permissions) as Entries<typeof permissions>).reduce<
              RoleTemplates[]
            >((permissionOptions, [role, permission]) => {
              if (permission && permission.display_value) {
                permissionOptions.push(role);
              }
              return permissionOptions;
            }, [])
          : [];
        return permissionOptionHash;
      },
      {}
    );
  }
);

export const getGuests = createSelector(getSortedTeamMembers, (members) =>
  members.filter(permissionsUtils.getIsProjectGuest)
);

export const getContractors = createSelector(getSortedTeamMembers, (members) =>
  members.filter(permissionsUtils.getIsContractor)
);

export const getInternalContractors = createSelector(
  getSortedTeamMembers,
  (members) => members.filter(permissionsUtils.getIsInternalContractor)
);

export const getExternalProjectContractors = createSelector(
  getSortedTeamMembers,
  (members) => members.filter(permissionsUtils.getIsExternalProjectContractor)
);

export const getGuestByAccountId = createSelector(getGuests, (guests) =>
  keyBy(guests, byMemberAccountId)
);

export const getContractorsByAccountId = createSelector(
  getContractors,
  (contractors) => keyBy(contractors, byMemberAccountId)
);

export const makeGetMemberIsGuestByAccountId = () => {
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();

  return createSelector(
    getTeamMembershipByAccountId,
    (membership) => membership && permissionsUtils.getIsProjectGuest(membership)
  );
};

export const makeGetMemberIsContractorByAccountId = () => {
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();

  return createSelector(
    getTeamMembershipByAccountId,
    (membership) =>
      (membership && permissionsUtils.getIsInternalContractor(membership)) ||
      (membership &&
        permissionsUtils.getIsExternalProjectContractor(membership))
  );
};

export const getUserIsAdmin = createSelector(
  getMe,
  getSelectedTeam,
  (me, selectedTeam) =>
    (me &&
      selectedTeam &&
      me.teams_roles &&
      me.teams_roles[selectedTeam.id]?.some(
        (role) => role === ACCESS_ROLES.ADMIN
      )) ??
    false
);
