import styled from 'styled-components';

interface SpaceProps {
  value?: number;
  vertical?: boolean;
}

export const Space = styled.div<SpaceProps>`
  width: ${({ value = 8, vertical }) => `${vertical ? 0 : value}px`};
  height: ${({ value = 8, vertical }) => `${vertical ? value : 0}px`};
`;
