import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { navigateToScopeModal } from 'actionCreators';
import { AddScopeModal } from '../../extraModals/AddScopeModal';
import { Scope } from 'models/scope';
import { useWorkPlanForm } from '../../WorkPlanFormProvider';
import { ScopeList } from './ScopeList';
import { useWorkPlanRequestSidePanelForm } from 'WorkplanRequestsModule/components/WorkplanRequestSidePanelProvider';
import { EditButton } from '../shared/EditButton';
import { PanelScroller } from '../shared/PanelScroller';
import cn from 'classnames';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { EDIT_SCOPE_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

export const ScopeMenuTabPanel = ({ providerType }) =>
  providerType === 'WorkPlan' ? (
    <WorkplanScopeMenuTabPanel providerType={providerType} />
  ) : (
    <WorkplanRequestScopeMenuTabPanel providerType={providerType} />
  );

const WorkplanScopeMenuTabPanel = ({ providerType }) => {
  const {
    scope: { scopes },
    form: { hasError },
    permissions: { canEditWorkplanScope }
  } = useWorkPlanForm();

  useEffect(() => {
    if (!canEditWorkplanScope) rebuildTooltip();
  }, [canEditWorkplanScope]);

  return (
    <ScopeMenuTabRenderPart
      scopes={scopes}
      hasError={hasError}
      providerType={providerType}
      isDisabled={!canEditWorkplanScope}
      addScopeButtonTooltip={
        !canEditWorkplanScope ? EDIT_SCOPE_WORK_PLANS_TIP : undefined
      }
    />
  );
};

const WorkplanRequestScopeMenuTabPanel = ({ providerType }) => {
  const {
    scope: { scopes },
    form: { hasError, isNewRequest }
  } = useWorkPlanRequestSidePanelForm();

  return (
    <ScopeMenuTabRenderPart
      scopes={scopes}
      hasError={hasError}
      providerType={providerType}
      isDisabled={isNewRequest}
    />
  );
};

const ScopeMenuTabRenderPart = ({
  scopes,
  hasError,
  providerType,
  isDisabled = false,
  addScopeButtonTooltip = ''
}) => {
  const dispatch = useDispatch();
  const [addScopeModalOpen, setAddScopeModalOpen] = useState(false);
  const handleSetAddScopeModalOpen = (value: boolean) => {
    setAddScopeModalOpen(value);
  };

  const onClickScope = (scope: Scope) => {
    dispatch(navigateToScopeModal({ scopeId: scope.id }));
  };

  const onClickAddScope = () => {
    handleSetAddScopeModalOpen(true);
  };

  const handleModalClose = () => {
    setAddScopeModalOpen(false);
  };

  const isAddScopeDisabled =
    hasError(['missing_project_id', 'missing_phase_id', 'missing_activity']) ||
    isDisabled;

  return (
    <PanelScroller>
      <PanelContainer>
        <EditButton
          className={cn({ disabled: isAddScopeDisabled })}
          onClick={!isAddScopeDisabled ? onClickAddScope : undefined}
          data-for="app-tooltip"
          data-effect="solid"
          data-tip={addScopeButtonTooltip}
        >
          Edit Scopes
        </EditButton>
        <ScopeList scopes={scopes} onClickScope={onClickScope} />
        <AddScopeModal
          open={addScopeModalOpen}
          toggle={handleModalClose}
          providerType={providerType}
        />
      </PanelContainer>
    </PanelScroller>
  );
};

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
