import { createSelector, Selector } from '@reduxjs/toolkit';
import { ReducerName, RootState } from 'reduxInfra/shared';
import { TeamRatesState } from './reducers/types';
import { initialState } from './reducers/ratesReducers';
import { EntityRateType } from './models/EntityRate';

export const getRatesState: Selector<RootState, TeamRatesState> = (state) =>
  state[ReducerName.TeamRates] ?? initialState;

/* ----------------------------- rate entities ------------------------------ */

export const getRateEntitiesHash = createSelector(
  getRatesState,
  (rates) => rates.entities?.hash
);

export const getRateEntitiesOrder = createSelector(
  getRatesState,
  (rates) => rates.entities?.order
);

/* --------------------------------- rates ---------------------------------- */

export const getRatesHash = createSelector(
  getRatesState,
  (rates) => rates.rates?.hash
);

export const getRatesOrder = createSelector(
  getRatesState,
  (rates) => rates.rates?.order
);

export const getIsRatesLoaded = createSelector(
  getRatesState,
  (rates) => !!rates.rates
);

/* ------------------------------ rate groups ------------------------------- */

export const getRateGroupsHash = createSelector(
  getRatesState,
  (rates) => rates.rateGroups?.hash
);

export const getRateGroupsOrder = createSelector(
  getRatesState,
  (rates) => rates.rateGroups?.order
);

export const getRoleRateGroupsOrder = createSelector(
  getRateGroupsOrder,
  (orderByType) => orderByType?.[EntityRateType.Role]
);

export const getWorkCategoryRateGroupsOrder = createSelector(
  getRateGroupsOrder,
  (orderByType) => orderByType?.[EntityRateType.WorkCategory]
);

export const getDefaultRoleRateGroupId = createSelector(
  getRoleRateGroupsOrder,
  getRateGroupsHash,
  (roleRateGroupsOrder, rateGroupsHash) =>
    roleRateGroupsOrder?.find(
      (rateGroupId) => rateGroupsHash?.[rateGroupId]?.is_default
    )
);

export const getDefaultWorkCategoryRateGroupId = createSelector(
  getWorkCategoryRateGroupsOrder,
  getRateGroupsHash,
  (workCategoryGroupsOrder, rateGroupsHash) =>
    workCategoryGroupsOrder?.find(
      (rateGroupId) => rateGroupsHash?.[rateGroupId]?.is_default
    )
);

export const getIsRoleRateGroupsLoaded = createSelector(
  getRoleRateGroupsOrder,
  (order) => !!order
);

export const getIsWorkCategoryRateGroupsLoaded = createSelector(
  getWorkCategoryRateGroupsOrder,
  (order) => !!order
);

/* -------------------------- entity rates history -------------------------- */

export const getEntityRatesHistoryByRateGroup = createSelector(
  getRatesState,
  (rates) => rates.entityRatesHistory?.byRateGroup
);

export const getEntityRatesHistoryHash = createSelector(
  getRatesState,
  (rates) => rates.entityRatesHistory?.hash
);

/* ------------------------------ entity rates ------------------------------ */

export const getEntityRatesHash = createSelector(
  getRatesState,
  (rates) => rates.entityRates?.hash
);

export const getEntityRatesByRateGroup = createSelector(
  getRatesState,
  (rates) => rates.entityRates?.byRateGroup
);
