import React, { useEffect, useMemo, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  getSelectedTeamId,
  getSelectedTeam,
  getSplitFlags,
  getAccountFiltersFetched
} from 'selectors';
import { makeGetCapacityIntervalRecordsByFilter } from 'CapacityModule/selectors';
import isEmpty from 'lodash/isEmpty';
import {
  makeGetIsFetchingUtilizationReport,
  makeGetTeamUtilizationChartData,
  makeGetTeamUtilizationSummaryData,
  makeGetTeamHistoricalUtilization
} from 'UtilizationModule/selectors';
import {
  fetchTeamUtilizationReport,
  fetchTeamHistoricalUtilization
} from 'UtilizationModule/actionCreators';
import { fetchCapacityReport } from 'CapacityModule/actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import { NoDataText } from '../styles';

import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import UtilizationBarChart from './BarChart/UtilizationBarChart';

import { utilizationRangeGeneratorByView } from 'appUtils/projectPlannerUtils';
import UtilizationAreaChart from './AreaChart/UtilizationAreaChart';
import { CHART_TYPE, HISTORICIAL_UTILIZATION } from 'ReportsModule/constants';
import {
  ZOOM_LEVELS,
  VIEW_BY,
  ZOOM_TO_STEP_VALUES_PLURAL,
  ZOOM_STRINGS_PLURAL
} from 'appConstants/workload';
import moment from 'moment';

import MosaicLogo from 'images/mosaic-logo-2021.svg';

const HistoricalInfoBox = styled.div`
  position: absolute;
  width: 160px;
  height: 81px;
  left: 820px;
  font-size: 16px;
  padding: 14px 11px;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorLightGray21};
  border-radius: 3px;
`;

const UltilizationPercentage = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
`;

const InfoLogoBoxContainer = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

const CurrentLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.colorMediumGray9};
  width: 69%;
  white-space: nowrap;
`;
const HistoricalLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  width: 69%;
  white-space: nowrap;
  padding-left: 23px;
`;

export const DATE_FORMAT = 'YYYY-MM-DD';

const UtilizationChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 792px;
  padding-top: 16px;

  .recharts-wrapper {
    z-index: 1;
  }
  .yAxis {
    .recharts-cartesian-axis-tick-value {
      font-size: 12px;
      color: ${theme.colors.colorSemiDarkGray4};
      transform: translateX(-5px);
    }
    .recharts-cartesian-axis-line {
      stroke: ${theme.colors.colorLightGray6};
    }
  }
  .xAxis {
    .recharts-cartesian-axis-tick text {
      font-size: 12px;
      color: ${theme.colors.colorSemiDarkGray4};
      transform: translateY(6px);
    }
    .recharts-cartesian-axis-tick line {
      transform: translateY(-6px);
      cursor: pointer;
    }
    .recharts-cartesian-axis-tick-line {
      stroke-width: 2;
      stroke: ${theme.colors.colorLightGray8};
    }
  }
  .recharts-cartesian-axis-line {
    stroke: ${theme.colors.colorLightGray8};
    fill: ${theme.colors.colorLightGray8};
  }
  .recharts-surface {
    overflow: visible;
  }
  .bar-value-label {
    font-size: 11px;
    font-weight: 600;
  }
  .recharts-bar-rectangle {
    cursor: pointer;
  }
`;

/* ------------------------------------------------------------------------ */
const UtilizationChartContainer = ({
  filterStateId,
  activeFilter,
  viewBy,
  pageName,
  data,
  isDashboardWidgetModal,
  //
  teamId,
  isFetching,
  filtersFetched,
  fetchTeamUtilizationReport,
  fetchTeamHistoricalUtilization,
  fetchCapacityReport,
  monthCapacityInterval,
  twoWeeksCapacityInterval,
  historicalUltilizationSummaryData,
  currentUltilizationSummaryData,
  teamHistoricalUltilization,
  includeRemainingCapacity,
  useCapacityAsDenominator
}) => {
  const selectedTeam = useSelector(getSelectedTeam);
  const { historicalUtilization } = useSelector(getSplitFlags);

  const getNextTwoWeekDemand = useCallback(() => {
    // Fetch next 2 week work plan demand.
    const today = moment();
    const twoWeeks = today.clone().add(2, 'weeks');
    fetchCapacityReport({
      params: {
        start_date: today.add(1, 'day').format('MM/DD/YYYY'),
        end_date: twoWeeks.format('MM/DD/YYYY'),
        interval_type: 'week',
        interval_amount: 2,
        account_ids:
          viewBy === VIEW_BY.ORGANIZATION ? activeFilter?.account_ids : [],
        team_id: teamId,
        data_type: 'Team',
        all: true,
        show_pto: activeFilter?.custom.showPto,
        show_holidays: activeFilter?.custom.showHolidays
      },
      filterStateId: `${filterStateId}-future-two-week`,
      isFetchingInterval: true
    });
  }, [
    activeFilter?.account_ids,
    activeFilter?.custom.showHolidays,
    activeFilter?.custom.showPto,
    fetchCapacityReport,
    filterStateId,
    teamId,
    viewBy
  ]);

  const mosaicUtilizationStartDate = useMemo(() => {
    return teamHistoricalUltilization?.timestamps?.length > 0
      ? teamHistoricalUltilization?.timestamps[0].start
      : null;
  }, [teamHistoricalUltilization?.timestamps]);

  const getUtilizationSinceMosaicStart = useCallback(() => {
    if (!mosaicUtilizationStartDate) return;
    const startMosaicDate = moment(mosaicUtilizationStartDate);
    // Fetch historical utilization (2018 to min(date that they start using Mosaic, end of 2019)).
    // Ignoring 2020 and 2021 because of covid from historical calculations.
    fetchTeamUtilizationReport({
      account_ids:
        viewBy === VIEW_BY.ORGANIZATION ? activeFilter?.account_ids : [],
      team_id: teamId,
      filterStateId: `${filterStateId}-current`,
      start_date: startMosaicDate.format('MM/DD/YYYY'),
      end_date: moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('MM/DD/YYYY'),
      interval_amount: 10,
      interval_type: 'year',
      show_pto: activeFilter?.custom.showPto,
      show_holidays: activeFilter?.custom.showHolidays,
      all: true
    });
  }, [
    activeFilter?.account_ids,
    activeFilter?.custom.showHolidays,
    activeFilter?.custom.showPto,
    fetchTeamUtilizationReport,
    filterStateId,
    mosaicUtilizationStartDate,
    teamId,
    viewBy
  ]);

  const getHistoricalUtilization = useCallback(() => {
    const startMosaicDate = moment(selectedTeam?.created_at);
    const startOfHistoricalDate = moment('01/01/2018', 'MM/DD/YYYY');
    const endOfHistoricalDate = moment('12/31/2019', 'MM/DD/YYYY');
    // Fetch historical utilization (2018 to min(date that they start using Mosaic, end of 2019)).
    // Ignoring 2020 and 2021 because of covid from historical calculations.
    fetchTeamUtilizationReport({
      account_ids:
        viewBy === VIEW_BY.ORGANIZATION ? activeFilter?.account_ids : [],
      team_id: teamId,
      filterStateId: `${filterStateId}-historical`,
      start_date: startOfHistoricalDate.format('MM/DD/YYYY'),
      end_date: startMosaicDate.isBetween(
        startOfHistoricalDate,
        endOfHistoricalDate
      )
        ? startMosaicDate.format('MM/DD/YYYY')
        : endOfHistoricalDate.format('MM/DD/YYYY'),
      interval_amount: 2,
      interval_type: 'year',
      show_pto: activeFilter?.custom.showPto,
      show_holidays: activeFilter?.custom.showHolidays,
      all: true
    });
  }, [
    activeFilter?.account_ids,
    activeFilter?.custom.showHolidays,
    activeFilter?.custom.showPto,
    fetchTeamUtilizationReport,
    filterStateId,
    selectedTeam?.created_at,
    teamId,
    viewBy
  ]);

  // Not using this because historical utilization end point gets the start date.
  const getMosaicUtlizationStartDate = useCallback(() => {
    const teamCreationDate = moment(selectedTeam?.created_at).add(91, 'days');
    const currentDate = moment();
    // Fetch all work plan demand for ever month starting from day of
    // Mosaic use + 91 days.
    fetchCapacityReport({
      params: {
        start_date: teamCreationDate.format('MM/DD/YYYY'),
        end_date: currentDate.format('MM/DD/YYYY'),
        interval_type: 'month',
        interval_amount: 1,
        team_id: teamId,
        data_type: 'Team',
        account_ids:
          viewBy === VIEW_BY.ORGANIZATION ? activeFilter?.account_ids : [],
        all: true,
        show_pto: activeFilter?.custom.showPto,
        show_holidays: activeFilter?.custom.showHolidays
      },
      filterStateId: `${filterStateId}-months-post-mosaic`,
      isFetchingInterval: true
    });
  }, [
    activeFilter?.account_ids,
    activeFilter?.custom.showHolidays,
    activeFilter?.custom.showPto,
    fetchCapacityReport,
    filterStateId,
    selectedTeam,
    teamId,
    viewBy
  ]);

  const getTeamHistoricalUtilization = useCallback(() => {
    fetchTeamHistoricalUtilization({
      team_id: teamId,
      threshold: HISTORICIAL_UTILIZATION.THRESHOLD,
      compliant_start_period: HISTORICIAL_UTILIZATION.COMPLIANT_START_PERIOD,
      filterStateId: `${filterStateId}-team-historical`
    });
  }, [fetchTeamHistoricalUtilization, filterStateId, teamId]);

  useEffect(() => {
    if (
      teamId &&
      selectedTeam &&
      (viewBy === VIEW_BY.ORGANIZATION || viewBy === VIEW_BY.TEAM) &&
      historicalUtilization
    ) {
      getTeamHistoricalUtilization();
      getNextTwoWeekDemand();
      // getMosaicUtlizationStartDate(); // Get Team historical Utilization Should return start date use that start date as Mosaic Start
      getHistoricalUtilization();
      getUtilizationSinceMosaicStart();
    }
  }, [
    getHistoricalUtilization,
    getNextTwoWeekDemand,
    getTeamHistoricalUtilization,
    getUtilizationSinceMosaicStart,
    historicalUtilization,
    selectedTeam,
    teamId,
    viewBy,
    filtersFetched
  ]);

  // Used to find the mosaic start date, new end point does this.
  const mosaicUtilizationStartRecord = useMemo(() => {
    return monthCapacityInterval.find((record) => {
      return record?.percentage_of_available_capacity_planned > 50;
    });
  }, [monthCapacityInterval]);

  const hasNextTwoWeeksPlanned = useMemo(
    () =>
      // Determine if the next 2 weeks have > 50% work plan.
      twoWeeksCapacityInterval[0]?.percentage_of_available_capacity_planned >
      50,
    [twoWeeksCapacityInterval]
  );

  const hasHistoricalUtlization = useMemo(
    // Check to see if there is any historical data.
    () =>
      !isEmpty(historicalUltilizationSummaryData) &&
      parseFloat(historicalUltilizationSummaryData.billable_percentage) !== 0,
    [historicalUltilizationSummaryData]
  );

  const zoomLevel = useMemo(() => {
    return activeFilter.custom?.range !== undefined
      ? activeFilter.custom.range
      : ZOOM_LEVELS.YEAR;
  }, [activeFilter.custom]);

  const startDate = useMemo(
    () => activeFilter?.custom?.start_date,
    [activeFilter]
  );
  const endDate = useMemo(() => activeFilter?.custom?.end_date, [activeFilter]);

  const chartType = activeFilter?.custom?.chartType ?? CHART_TYPE.BAR;

  // Returns { start_date, end_date, interval_type, interval_amount }
  const dateParams = useMemo(() => {
    return {
      ...utilizationRangeGeneratorByView[viewBy]?.[zoomLevel]?.({
        start: startDate,
        end: endDate
      }),
      interval_amount: 1,
      interval_type:
        ZOOM_TO_STEP_VALUES_PLURAL[zoomLevel] ?? ZOOM_STRINGS_PLURAL.MONTH
    };
  }, [endDate, startDate, viewBy, zoomLevel]);

  const shouldDisplayUtilizationInfo = useMemo(() => {
    return Boolean(
      hasNextTwoWeeksPlanned &&
        mosaicUtilizationStartDate &&
        // mosaicUtilizationStartRecord &&
        hasHistoricalUtlization &&
        historicalUtilization
    );
  }, [
    hasHistoricalUtlization,
    hasNextTwoWeeksPlanned,
    historicalUtilization,
    mosaicUtilizationStartDate
  ]);

  const hasSelectedMembers = !!activeFilter.account_ids.length;

  const shouldSelectMembers =
    !isDashboardWidgetModal &&
    viewBy === VIEW_BY.ORGANIZATION &&
    !hasSelectedMembers;

  const filterParams = useMemo(() => {
    return {
      account_ids: activeFilter.account_ids,
      show_pto: activeFilter.custom.showPto,
      show_holidays: activeFilter.custom.showHolidays
    };
  }, [
    activeFilter.account_ids,
    activeFilter.custom.showPto,
    activeFilter.custom.showHolidays
  ]);

  const filterEquality = useMemo(
    () => JSON.stringify(filterParams),
    [filterParams]
  );

  useEffect(() => {
    if (teamId && !shouldSelectMembers) {
      fetchTeamUtilizationReport({
        team_id: teamId,
        filterStateId,
        ...dateParams,
        ...filterParams
      });
    }
    // keep filterParams out of the array
  }, [
    filterEquality,
    dateParams,
    fetchTeamUtilizationReport,
    filterStateId,
    shouldSelectMembers,
    teamId
  ]);

  const UtilizationChartComponents = {
    [CHART_TYPE.BAR]: UtilizationBarChart,
    [CHART_TYPE.AREA_GRAPH]: UtilizationAreaChart
  };

  const UtilizationChartComponent = UtilizationChartComponents[chartType];

  const isLoading = isFetching || !filtersFetched;

  return (
    <UtilizationChartWrapper>
      {isLoading ? (
        <SkeletonLoader
          numLoaders={5}
          style={{ paddingLeft: 12, margin: 0, marginTop: '5%' }}
          loaderStyle={{ height: 70, rx: 4 }}
        />
      ) : shouldSelectMembers ? (
        <NoDataText style={{ marginTop: 25 }}>Select members above</NoDataText>
      ) : !data?.length ? (
        <NoDataText>There is no data to display</NoDataText>
      ) : (
        UtilizationChartComponent && (
          <>
            {shouldDisplayUtilizationInfo &&
              currentUltilizationSummaryData &&
              chartType === CHART_TYPE.AREA_GRAPH &&
              historicalUtilization && (
                <HistoricalInfoBox>
                  <CurrentLabel>
                    <InfoLogoBoxContainer
                      height="20px"
                      width="20px"
                      src={MosaicLogo}
                    />
                    Mosaic
                    <UltilizationPercentage>
                      {
                        ~~parseFloat(
                          currentUltilizationSummaryData?.billable_percentage
                        ).toFixed(0)
                      }
                      %
                    </UltilizationPercentage>
                  </CurrentLabel>
                  <HistoricalLabel>
                    Historical{' '}
                    <UltilizationPercentage>
                      {parseFloat(
                        historicalUltilizationSummaryData?.billable_percentage
                      ).toFixed(0)}
                      %
                    </UltilizationPercentage>
                  </HistoricalLabel>
                </HistoricalInfoBox>
              )}
            <UtilizationChartComponent
              data={data}
              zoomLevel={zoomLevel}
              utlizationIconInfo={{
                shouldDisplayIcons: shouldDisplayUtilizationInfo,
                mosaicUtilizationStartDate:
                  // mosaicUtilizationStartRecord?.start_date
                  mosaicUtilizationStartDate
              }}
              includeRemainingCapacity={includeRemainingCapacity}
            />
          </>
        )
      )}
    </UtilizationChartWrapper>
  );
};

const makeMapStateToProps = () => {
  const getTeamUtilizationChartData = makeGetTeamUtilizationChartData();
  const getIsFetchingUtilizationReport = makeGetIsFetchingUtilizationReport();
  const getTwoWeeksCapacityInterval = makeGetCapacityIntervalRecordsByFilter();
  const getMonthsCapacityInterval = makeGetCapacityIntervalRecordsByFilter(); // This is from creation date + 91 days to current date.
  const getHistoricalUltilizationSummaryData =
    makeGetTeamUtilizationSummaryData();
  const getUtilizationSinceMosaicStart = makeGetTeamUtilizationSummaryData();

  const getTeamHistoricalUtilization = makeGetTeamHistoricalUtilization();

  const mapStateToProps = (state, ownProps) => ({
    teamId: getSelectedTeamId(state),
    isFetching: getIsFetchingUtilizationReport(state, ownProps),
    data: getTeamUtilizationChartData(state, ownProps),
    filtersFetched: getAccountFiltersFetched(state),
    twoWeeksCapacityInterval: getTwoWeeksCapacityInterval(state, {
      filterStateId: `${ownProps.filterStateId}-future-two-week`
    }),
    monthCapacityInterval: getMonthsCapacityInterval(state, {
      filterStateId: `${ownProps.filterStateId}-months-post-mosaic`
    }),
    historicalUltilizationSummaryData: getHistoricalUltilizationSummaryData(
      state,
      {
        filterStateId: `${ownProps.filterStateId}-historical`
      }
    ),
    currentUltilizationSummaryData: getUtilizationSinceMosaicStart(state, {
      filterStateId: `${ownProps.filterStateId}-current`
    }),
    teamHistoricalUltilization: getTeamHistoricalUtilization(state, {
      filterStateId: `${ownProps.filterStateId}-team-historical`
    })
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchTeamUtilizationReport,
  fetchCapacityReport,
  fetchTeamHistoricalUtilization
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UtilizationChartContainer);
