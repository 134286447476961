import React from 'react';
import styled from 'styled-components';
import { Nav, NavItem } from 'reactstrap';
import { StyledNavLink } from './styles';
import { getMatchedRouteParams } from 'selectors';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class SidebarNav extends React.Component {
  renderNav = (num) => {
    const {
      activeView,
      moduleHash,
      textHash,
      baseRoute,
      moduleHashWithoutExtensions = {}
    } = this.props;

    return (
      <NavItem key={num}>
        <StyledNavLink
          active={
            activeView === moduleHash[num] ||
            activeView === moduleHashWithoutExtensions[num]
          }
          tag={Link}
          to={baseRoute ? `${baseRoute}/${moduleHash[num]}` : moduleHash[num]}
          className="styled-nav-link"
          data-testid={`integration-sidebar-${textHash[num]}`}
        >
          {textHash[num]}
        </StyledNavLink>
      </NavItem>
    );
  };

  render() {
    const { className, moduleIds, styles = {} } = this.props;

    return (
      <Sidebar className={className} style={styles}>
        <Nav vertical style={{ maxWidth: 'fit-content' }}>
          {moduleIds.map(this.renderNav)}
        </Nav>
      </Sidebar>
    );
  }
}

const Sidebar = styled.div`
  flex: 0.7;
  min-width: 220px;
`;

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});
export default connect(mapStateToProps)(SidebarNav);
