import * as constants from '../constants';
import { createAction } from 'appUtils';

export const fetchProfitReport = (params) =>
  createAction(constants.FETCH_PROFIT_REPORT.TRIGGER, params);

export const setProfitReportIsFetchingPhases = (params) =>
  createAction(constants.SET_PROFIT_REPORT_IS_FETCHING_PHASES, params);

export const setProfitReportIsFetchingProjects = (value) =>
  createAction(constants.SET_PROFIT_REPORT_IS_FETCHING_PROJECTS, value);
