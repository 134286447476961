import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import sortBy from 'lodash/fp/sortBy';
import orderBy from 'lodash/fp/orderBy';
import map from 'lodash/fp/map';
import moment from 'appUtils/momentConfig';
import { EVENT_TYPE_SORT_RANKS, CALENDAR_EVENT_TYPE } from './constants';
import { getRemainingWorkDays } from 'appUtils/phaseDisplayUtils';

export const formatDate = (date) => moment(date).format('MM/DD/YYYY');

// Lower number comes first
const byType = ({ type }) => EVENT_TYPE_SORT_RANKS[type];

const byDate = ({ data }) => moment(data.sortByDate).valueOf();

export const groupAndSortEvents = (events) => {
  const result = flow(
    orderBy([byType, byDate], ['asc', 'asc']),
    groupBy(({ date }) => date),
    Object.entries,
    map(([key, val]) => ({
      date: key,
      events: val
    }))
  )(events);
  return result;
};

export const generatePhaseScheduleEvent = ({ phase }) => {
  const date = formatDate(phase.end_date);

  return {
    eventId: `phase-event-start-${phase.id}`,
    date,
    type: CALENDAR_EVENT_TYPE.PHASE_SCHEDULE,
    data: {
      sortByDate: phase.end_date,
      phase
    }
  };
};

const formatProgressPercent = (phase) => {
  const { total_work_days: totalWorkDays = 0 } = phase;
  const remainingWorkDays = getRemainingWorkDays(phase);
  const currentProgressPercent = totalWorkDays
    ? ((totalWorkDays - remainingWorkDays) / totalWorkDays) * 100
    : 0;
  return currentProgressPercent;
};

export const formatPhaseCalendarTooltip = ({
  phase = {},
  project = {},
  eventId
}) => {
  const {
    start_date: startDate,
    end_date: endDate,
    is_budget: isBudget,
    name,
    total_work_days
  } = phase;

  return {
    currentProgressPercent: formatProgressPercent(phase),
    startDate,
    endDate,
    phase,
    project,
    id: phase.id,
    isPhase: isBudget,
    isRangeType: total_work_days > 1,
    phaseName: name,
    projectId: project.id,
    projectName: project.title,
    eventId
  };
};
