import {
  hasAssociatedTimeTypes,
  deleteMessages
} from 'appConstants/deleteCopy';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

export const hasAssociatedTime = (entry) =>
  entry?.has_associated_time_entries ||
  entry?.has_associated_activity_phase_schedule_bars;

export const getAssociatedTimeMessage = (
  entry,
  entryType = MODAL_TYPE.PROJECT
) => {
  const { TIME_ENTRIES, WORK_PLANS, BOTH } = hasAssociatedTimeTypes;
  const messageType =
    entry[TIME_ENTRIES] && entry[WORK_PLANS]
      ? BOTH
      : entry[TIME_ENTRIES]
      ? TIME_ENTRIES
      : WORK_PLANS;

  return deleteMessages[entryType]?.[messageType] ?? '';
};

export const addCommasToNum = (num) => Number(num).toLocaleString();
export const removeCommas = (value) => value.replace(/,/g, '');

export const getIsUnassignedMemberBudget = (memberBudget) =>
  memberBudget?.account_id === null;
