import { createRequestTypes } from 'appUtils';

export const TOGGLE_QUICKBOOKS_OPEN = 'TOGGLE_QUICKBOOKS_OPEN';
export const LINK_QUICKBOOKS_SECTION = 'LINK_QUICKBOOKS_SECTION';
export const DISCONNECT_QUICKBOOKS_SECTION = 'DISCONNECT_QUICKBOOKS_SECTION';

export const FETCH_SYNC_SETTINGS = createRequestTypes('FETCH_SYNC_SETTINGS');
export const UPDATE_SYNC_SETTINGS = createRequestTypes('UPDATE_SYNC_SETTINGS');
export const FETCH_QB_MEMBERS = createRequestTypes('FETCH_QB_MEMBERS');
export const FETCH_SERVICES = createRequestTypes('FETCH_SERVICES');
export const FETCH_SUBCUSTOMERS = createRequestTypes('FETCH_SUBCUSTOMERS');
export const SYNC_MEMBERS = createRequestTypes('SYNC_MEMBERS');
export const SYNC_SUBCUSTOMERS_TO_PROJECTS = createRequestTypes(
  'SYNC_SUBCUSTOMERS_TO_PROJECTS'
);
export const SYNC_TIME_ENTRIES = createRequestTypes('SYNC_TIME_ENTRIES');
export const SYNC_ACTIVITIES = createRequestTypes('SYNC_ACTIVITIES');
export const FETCH_SYNCED_MEMBERS = createRequestTypes('FETCH_SYNCED_MEMBERS');
export const FETCH_SYNCED_SUBCUSTOMERS = createRequestTypes(
  'FETCH_SYNCED_SUBCUSTOMERS'
);
export const FETCH_SYNCED_SUBCUSTOMER = createRequestTypes(
  'FETCH_SYNCED_SUBCUSTOMER'
);
export const FETCH_SYNCED_SERVICE = createRequestTypes('FETCH_SYNCED_SERVICE');
export const UPDATE_SYNC_STATUS = createRequestTypes('UPDATE_SYNC_STATUS');

export const DELETE_SYNCED_EMPLOYEES = createRequestTypes(
  'DELETE_SYNCED_EMPLOYEES'
);

export const DELETE_SYNCED_SUBCUSTOMERS = createRequestTypes(
  'DELETE_SYNCED_SUBCUSTOMERS'
);

export const DELETE_SYNCED_ACTIVITIES = createRequestTypes(
  'DELETE_SYNCED_ACTIVITIES'
);

export const FETCH_SUB_SUB_CUSTOMERS = createRequestTypes(
  'FETCH_SUB_SUB_CUSTOMERS'
);

export const FETCH_SYNCED_SUB_SUB_CUSTOMERS = createRequestTypes(
  'FETCH_SYNCED_SUB_SUB_CUSTOMERS'
);

export const SYNC_SUB_SUB_CUSTOMERS = createRequestTypes(
  'SYNC_SUB_SUB_CUSTOMERS'
);

export const CANCEL_SYNC_STATUS = 'CANCEL_SYNC_STATUS';

export const FETCH_CUSTOMERS = createRequestTypes('FETCH_CUSTOMERS');

export const SET_CONFIG_DIR_QB_TO_MOSAIC = 'SET_CONFIG_DIR_QB_TO_MOSAIC';

export const OPEN_SUBCUSTOMER_MODAL = 'OPEN_SUBCUSTOMER_MODAL';

export const CLOSE_SUBCUSTOMER_MODAL = 'CLOSE_SUBCUSTOMER_MODAL';
export const TOGGLE_SHOW_PHASES = 'TOGGLE_SHOW_PHASES';
export const SHOW_PHASES = 'SHOW_PHASES';
export const SET_HIDDEN_CUSTOMERS = 'SET_HIDDEN_CUSTOMERS';
export const SET_HIDDEN_TEAMS = 'SET_HIDDEN_TEAMS';

export const CREATE_NEW = 'Create New';
export const DO_NOT_LINK = 'Do not Link';
export const LINK_WITH_EXISTING = 'Link with Existing';
export const INVITE_MEMBER_TO_MOSAIC = 'Invite Member to Mosaic';

export const TOGGLE_SYNCED_MEMBERS = 'TOGGLE_SYNCED_MEMBERS';
export const TOGGLE_SYNCED_SERVICES = 'TOGGLE_SYNCED_SERVICES';
export const TOGGLE_SYNCED_PROJECTS = 'TOGGLE_SYNCED_PROJECTS';

export const QB_SEARCH_CHANGE_TRIGGER = 'QB_SEARCH_CHANGE_TRIGGER';
export const QB_SEARCH_CHANGE = 'QB_SEARCH_CHANGE';

export const QB_TOGGLE_BATCH_SELECT = 'QB_TOGGLE_BATCH_SELECT';
export const QB_FLUSH_BATCH_SELECT = 'QB_FLUSH_BATCH_SELECT';
export const TOGGLE_MAPPING_FILTER = 'TOGGLE_MAPPING_FILTER';

export const CREATE_NEW_ACTIVITIES_IN_BATCH = createRequestTypes(
  'CREATE_NEW_ACTIVITIES_IN_BATCH'
);

export const PREFILL_CREATE_NEW_ROWS = 'PREFILL_CREATE_NEW_ROWS';

export const FLUSH_PREFILL_CREATE_NEW_ROWS = 'FLUSH_PREFILL_CREATE_NEW_ROWS';
export const mappingFilters = {
  SHOW_ALL: 'Show All',
  SHOW_LINKED: 'Show Linked',
  SHOW_UNLINKED: 'Show Unlinked',
  SHOW_UNLINKED_PHASES: 'Show Unlinked Phases',
  SHOW_ARCHIVED: 'Show Archived',
  SHOW_ACTIVE: 'Show Active',
  SHOW_ACTIVE_AND_ARCHIVED: 'Show Active/Archived'
};

export const UNDO_PENDING_ACTIVITIES = createRequestTypes(
  'UNDO_PENDING_ACTIVITIES'
);
export const UNDO_PENDING_MEMBERS = createRequestTypes('UNDO_PENDING_MEMBERS');
export const UNDO_PENDING_PHASES = createRequestTypes('UNDO_PENDING_PHASES');
export const UNDO_PENDING_SUBCUSTOMERS = createRequestTypes(
  'UNDO_PENDING_SUBCUSTOMERS'
);
export const DELETE_PHASE_MAPPING = createRequestTypes('DELETE_PHASE_MAPPING');
export const TOGGLE_DEFAULT_TEAM_ERROR = 'TOGGLE_DEFAULT_TEAM_ERROR';

export const TOGGLE_SERVICES_LOADING = 'TOGGLE_SERVICES_LOADING';
export const INITIAL_FETCH_SYNC_MEMBERS = 'INITIAL_FETCH_SYNC_MEMBERS';
export const INITIAL_FETCH_SYNC_PROJECTS = 'INITIAL_FETCH_SYNC_PROJECTS';
export const TOGGLE_MEMBERS_LOADING = 'TOGGLE_MEMBERS_LOADING';
export const TOGGLE_PROJECTS_LOADING = 'TOGGLE_PROJECTS_LOADING';
export const MAKE_BILLABLE = 'Make Billable';
export const MAKE_NOT_BILLABLE = 'Make not Billable';
export const LINKED = 'Linked';
export const LINK_PHASES_BELOW = 'Link Phases below';
export const LINK_ALL_PHASES = 'Link all Phases';
export const LINKED_ALL_PHASES = 'Linked all Phases';
export const QB_SORT_CHANGE = 'QB_SORT_CHANGE';
export const IS_PENDING = 0;
export const IS_COMPLETED = 1;
