import { PERSONAL_BOARD_NAMES } from 'appConstants';

export const isStarredOrUnstarredBoard = (board) =>
  board &&
  [
    PERSONAL_BOARD_NAMES['Starred Projects'],
    PERSONAL_BOARD_NAMES['UnStarred Projects'],
    'Starred Projects',
    'UnStarred Projects'
  ].includes(board.name) &&
  (board.is_personal || board.isPersonal);

export const isPersonalBoard = (board) =>
  board && (board.is_personal || board.isPersonal);
