import { reducerMap } from 'EventsModule/reducers';
import eventsSaga from 'EventsModule/sagas';

export function getEventsModule() {
  return {
    id: 'events',
    reducerMap,
    sagas: [eventsSaga]
  };
}
