import React, { useRef, useCallback } from 'react';
import CellContainer from './CellContainer';
import TaskMilestone from '../TaskMilestone';
import { makeNoop } from 'appUtils/noop';

const throwawayValue = '';
const PhaseCell = ({ row }) => {
  const target = useRef(null);

  const {
    phase_id,
    activity_id,
    activity_phase_id,
    id,
    handlePropertySelect = makeNoop,
    selectedProject,
    taskProps = {}
  } = row.original;
  const { taskIsEditing, isNewTask } = taskProps;
  const projectPhases =
    selectedProject?.phases.filter((phase) => phase.is_budget) ?? [];

  const handleSubmit = useCallback(
    (config) => handlePropertySelect(config)(throwawayValue),
    [handlePropertySelect]
  );

  return (
    <CellContainer
      taskProps={taskProps}
      target={target}
      taskProperty="phase_id"
      handleClick={() => row.setState({ editingProperty: 'phase_id' })}
      row={row}
    >
      <TaskMilestone
        target={target}
        phaseId={phase_id}
        activityId={activity_id}
        activityPhaseId={activity_phase_id}
        projectId={selectedProject?.id}
        onSubmit={handleSubmit}
        projectPhases={projectPhases}
        taskId={id}
        taskIsEditing={taskIsEditing}
      />
    </CellContainer>
  );
};
export default PhaseCell;
