/* eslint-disable no-unused-expressions */
import React from 'react';

import { connect } from 'react-redux';

import { makeGetColorPickerIsOpen } from 'selectors';

import TaskBatchActionsModal from 'views/Home/TaskBatchActions/TaskBatchActionsModal';

import SetColorIcon from 'icons/SetColor';
import DeleteIcon from 'icons/DeleteIcon';
import CloseIcon from 'icons/CloseIcon';
import NewGroupIcon from 'icons/NewGroupIcon';
import EditTaskGroupIcon from 'icons/EditTaskGroupIcon';
import {
  CollapseIconContainer,
  IconsContainer,
  ThreeDotMenuListItem,
  ShowOnHover,
  DropdownWrapper,
  StyledDragGrips,
  TaskGroupHeaderContainer,
  DropdownIconContainer,
  StyledGroupHeaderWrapper
} from './styles';
import TaskGroupCollapseIcon from 'icons/TaskGroupCollapseIcon';
import DragGripIcon from 'icons/DragGripIcon';

import Popover from 'components/Popover';
import GroupHeader from './GroupHeader';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';

// Parent components call some class methods by name via `onOptionClick` , do not rename class methods without checking where GenericGroupHeader.
class GenericGroupHeader extends React.Component {
  state = {
    collapsed: false,
    editing: false,
    threeDotMenuOpen: false,
    forceColorPickerOpen: false,
    name: this.props.group
      ? this.props.isNewGroup
        ? ''
        : this.props.group.name
      : '',
    isDeleting: false
  };

  input = React.createRef();

  toggleCollapse = () => {
    const {
      toggleCollapse,
      group: { id, collapsed }
    } = this.props;
    if (this.props.disabled) {
      return;
    }
    toggleCollapse({ groupId: id, collapsed: !collapsed });
    this.clearThreeDotMenuOpen();
  };

  componentDidMount() {
    const { isNewGroup } = this.props;
    if (this.props.disabled) {
      return;
    }
    if (isNewGroup) {
      this.setEditing();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.disabled) {
      return;
    }
    if (!prevState.editing && this.state.editing && this.input.current) {
      this.input.current.focus();
    }
    if (!prevProps.isNewGroup && this.props.isNewGroup) {
      // this happens when the first task group goes from default to named
      this.setEditing();
    }
    if (
      prevProps.colorPickerOpen &&
      !this.props.colorPickerOpen &&
      this.state.editing
    ) {
      this.handleSubmit();
    }
  }

  toggleCollapseAll = () => {
    const { toggleCollapseAll, allCollapsed } = this.props;
    toggleCollapseAll({ collapsed: !allCollapsed });
    this.clearThreeDotMenuOpen();
  };

  clearIsDeleting = () => {
    this.setState({ isDeleting: false });
    this.props.clearIsDeleting?.();
  };

  handleInsertList = () => {
    const { handleInsertList } = this.props;

    handleInsertList();
    this.clearThreeDotMenuOpen();
  };

  setEditing = () => this.setState({ editing: true, threeDotMenuOpen: false });

  setColorEditing = () =>
    this.setState({
      editing: true,
      forceColorPickerOpen: true,
      threeDotMenuOpen: false
    });

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  handleSubmit = (e) => {
    const { name } = this.state;
    e && e.preventDefault();
    if (name || !this.props.preventEmptyName) {
      this.setState({ editing: false, forceColorPickerOpen: false });
      this.props.handleSubmit({ name });
    } else {
      this.setState({
        editing: false,
        forceColorPickerOpen: false,
        name: this.props.group?.name || ''
      });
    }
  };

  handleBlur = () => {
    setTimeout(() => {
      if (!this.props.colorPickerOpen && !this.state.forceColorPickerOpen) {
        this.handleSubmit();
      }
    }, 200);
  };

  setDeleting = () =>
    this.setState({ isDeleting: true, threeDotMenuOpen: false });

  clearDeleting = () => this.setState({ isDeleting: false });

  confirmDelete = () => {
    this.clearIsDeleting();
    this.props.onConfirmDelete();
  };

  setThreeDotMenuOpen = (e) => {
    e && e.stopPropagation();
    this.setState({ threeDotMenuOpen: true });
  };

  clearThreeDotMenuOpen = (e) => {
    e && e.stopPropagation();
    this.setState({ threeDotMenuOpen: false });
  };

  removeHeader = () => this.setState({ name: '' }, this.handleSubmit);

  onOptionClick = (option) => {
    option.onClick?.();
    this[option.actionType]?.();
  };

  renderDropdownOptions = () => {
    const { isOnlyGroup, removeLastGroupEnabled, dropdownOptions } = this.props;
    if (dropdownOptions) {
      return (
        <DropdownWrapper>
          {dropdownOptions.map((option) => (
            <ThreeDotMenuListItem
              key={option.label}
              onClick={(e) => {
                e?.stopPropagation?.();
                this.onOptionClick(option);
              }}
            >
              {option.icon && (
                <DropdownIconContainer>{option.icon}</DropdownIconContainer>
              )}
              {option.label}
            </ThreeDotMenuListItem>
          ))}
        </DropdownWrapper>
      );
    } else {
      return (
        <DropdownWrapper>
          <ThreeDotMenuListItem onClick={this.setEditing}>
            <DropdownIconContainer>
              <EditTaskGroupIcon />
            </DropdownIconContainer>
            Rename List
          </ThreeDotMenuListItem>
          <ThreeDotMenuListItem onClick={this.setColorEditing}>
            <DropdownIconContainer>
              <SetColorIcon />
            </DropdownIconContainer>
            Set List Color
          </ThreeDotMenuListItem>
          <ThreeDotMenuListItem onClick={this.handleInsertList}>
            <DropdownIconContainer>
              <NewGroupIcon />
            </DropdownIconContainer>
            Insert New List
          </ThreeDotMenuListItem>
          {isOnlyGroup && removeLastGroupEnabled && (
            <ThreeDotMenuListItem onClick={this.removeHeader}>
              <DropdownIconContainer>
                <CloseIcon width="10" height="10" fill="#808080" />
              </DropdownIconContainer>
              Remove List Title
            </ThreeDotMenuListItem>
          )}
          <ThreeDotMenuListItem
            className="delete-icon"
            onClick={this.setDeleting}
          >
            <DropdownIconContainer>
              <DeleteIcon height="14" />
            </DropdownIconContainer>
            Delete List
          </ThreeDotMenuListItem>
        </DropdownWrapper>
      );
    }
  };

  renderGroupHeader = () => {
    const {
      group = {},
      provided = {},
      snapshot = {},
      isOnlyGroup,
      isDefaultGroup,
      removeLastGroupEnabled,
      wrapperStyles = {},
      alwaysShowDotMenu,
      preventEmptyName,
      namePlaceholderText,
      disabled,
      editDisabled,
      dragDisabled,
      emptyNameText,
      hideCollapse,
      isOnPersonalProject,
      threeDotHoverBackgroundColor
    } = this.props;
    const { threeDotMenuOpen, editing, forceColorPickerOpen, name } =
      this.state;

    return (
      <div style={wrapperStyles}>
        <TaskGroupHeaderContainer hideDotMenu={editing}>
          {!isDefaultGroup ? (
            <IconsContainer
              open={threeDotMenuOpen && !editing}
              hide={editing}
              ref={(ref) => (this.popoverTarget = ref)}
            >
              {!isOnlyGroup && !disabled && !dragDisabled && (
                <div {...provided.dragHandleProps}>
                  <StyledDragGrips isDragging={snapshot.isDragging}>
                    <DragGripIcon />
                  </StyledDragGrips>
                </div>
              )}

              <CollapseIconContainer
                isCollapsed={group.collapsed}
                onClick={this.toggleCollapse}
                disabled={disabled}
                isHidden={hideCollapse}
                isOnPersonalProject={isOnPersonalProject}
              >
                <TaskGroupCollapseIcon height="36px" width="36px" />
              </CollapseIconContainer>

              {!editDisabled && (
                <ShowOnHover
                  openDotMenu={
                    (threeDotMenuOpen || alwaysShowDotMenu) && !editing
                  }
                  onClick={this.setThreeDotMenuOpen}
                >
                  <ProjectThreeDotMenu
                    onCloseCallback={this.clearThreeDotMenuOpen}
                    threeDotHoverBackgroundColor={threeDotHoverBackgroundColor}
                  >
                    {threeDotMenuOpen && (
                      <Popover
                        isOpen
                        target={this.popoverTarget}
                        closePopover={this.clearThreeDotMenuOpen}
                        boundariesElement="window"
                        className="delete-popover"
                      >
                        {this.renderDropdownOptions()}
                      </Popover>
                    )}
                  </ProjectThreeDotMenu>
                </ShowOnHover>
              )}
            </IconsContainer>
          ) : null}

          <GroupHeader
            group={group}
            setEditing={this.setEditing}
            handleSubmit={this.handleSubmit}
            onChange={this.handleChange}
            name={name}
            namePlaceholderText={namePlaceholderText}
            preventEmptyName={preventEmptyName}
            editing={editing}
            inputRef={this.input}
            handleBlur={this.handleBlur}
            toggleCollapse={this.toggleCollapse}
            forceColorPickerOpen={forceColorPickerOpen}
            colorPickerEnabled={this.props.colorPickerEnabled}
            originType={this.props.originType}
            colorPickerHeaderText={this.props.colorPickerHeaderText}
            editDisabled={editDisabled || disabled}
            renderIcon={this.props.renderIcon}
            renderAfterName={this.props.renderAfterName}
            emptyNameText={emptyNameText}
          />
        </TaskGroupHeaderContainer>
      </div>
    );
  };

  renderDeleteConfirmation = () => {
    return (
      <TaskBatchActionsModal
        isOpen={this.state.isDeleting}
        handleConfirmation={this.confirmDelete}
        toggle={this.clearIsDeleting}
        {...this.props.deleteModalProps}
      />
    );
  };

  render() {
    return (
      <StyledGroupHeaderWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {this.renderGroupHeader()}
          {this.state.isDeleting && this.renderDeleteConfirmation()}
        </div>
      </StyledGroupHeaderWrapper>
    );
  }
}

const makeMapStateToProps = () => {
  const getColorPickerIsOpen = makeGetColorPickerIsOpen();
  const mapStateToProps = (state, ownProps) => ({
    colorPickerOpen: getColorPickerIsOpen(state, ownProps)
  });

  return mapStateToProps;
};
export default connect(makeMapStateToProps)(GenericGroupHeader);
