import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHook } from 'FilterModule/types';
import { useBooleanFilterField } from './useBooleanFilterField';

const emptyObj = {};

export const useShowProjectsWithUnassignedRolesFilter: FilterListTypeHook = ({
  field = FilterField.isProjectsWithUnassignedRoles_cf,
  shouldUseDraft
} = emptyObj) => {
  const booleanFilterFieldValues = useBooleanFilterField({
    field,
    items,
    shouldUseDraft
  });

  return {
    ...booleanFilterFieldValues,
    optionsArray: items,
    onlySelectedItemLabel: ''
  };
};

/* ------------------------------------ - ----------------------------------- */

const option = `Only show Projects \nwith Open Roles`;

const items = [option];
