import styled from 'styled-components';
import { Modal } from 'reactstrap';
import { AddScopeStep } from '../../workloadBarModal/AddScopeStep';
import { useWorkPlanForm } from '../WorkPlanFormProvider';
import { useWorkPlanRequestSidePanelForm } from 'WorkplanRequestsModule/components/WorkplanRequestSidePanelProvider';

interface AddScopeModalProps {
  open: boolean;
  toggle: () => void;
  providerType: string;
}
export const AddScopeModal = ({
  open,
  toggle,
  providerType
}: AddScopeModalProps) =>
  providerType === 'WorkPlan' ? (
    <WorkplanAddScopeModal open={open} toggle={toggle} />
  ) : (
    <WorkplanRequestAddScopeModal open={open} toggle={toggle} />
  );

const WorkplanAddScopeModal = ({ open, toggle }) => {
  const {
    form: { isNew, workplan },
    scope: { scopes, onAddScope, onRemoveScope }
  } = useWorkPlanForm();

  return (
    <AddScopeModalRenderPart
      open={open}
      toggle={toggle}
      isNew={isNew}
      workplan={workplan}
      scopes={scopes}
      onAddScope={onAddScope}
      onRemoveScope={onRemoveScope}
    />
  );
};

const WorkplanRequestAddScopeModal = ({ open, toggle }) => {
  const {
    form: { isNewRequest, workplanRequest },
    scope: { scopes, onAddScope, onRemoveScope }
  } = useWorkPlanRequestSidePanelForm();

  return (
    <AddScopeModalRenderPart
      open={open}
      toggle={toggle}
      isNew={isNewRequest}
      workplan={workplanRequest}
      scopes={scopes}
      onAddScope={onAddScope}
      onRemoveScope={onRemoveScope}
    />
  );
};

const AddScopeModalRenderPart = ({
  open,
  toggle,
  isNew,
  workplan,
  scopes,
  onAddScope,
  onRemoveScope
}) => {
  return (
    <StyledModal isOpen={open} toggle={toggle} className="add-scope-modal">
      <AddScopeStep
        bar={workplan}
        isNew={isNew}
        projectId={workplan.project_id}
        accountId={workplan.account_id}
        phaseId={workplan.phase_id}
        activityPhaseId={workplan.activity_phase_id}
        activityId={workplan.activity_id}
        onGoBack={toggle}
        scopes={scopes}
        onAddScope={onAddScope}
        onRemoveScope={onRemoveScope}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-content {
    height: min(90vh, 900px);
    padding-bottom: 10px;
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray3};
  }
  .modal-body {
    padding: 0;
  }
`;
