import { ValueOf } from 'type-fest';
import { DEPENDENCY_TYPES, DEPENDABLE_LABELS } from './constants';

export const getDependencyTypeLabels = (
  dependencyType: ValueOf<typeof DEPENDENCY_TYPES>
): { from: string; to: string } => {
  switch (dependencyType) {
    case DEPENDENCY_TYPES.StartDate:
      return {
        from: 'Start',
        to: 'Start Date'
      };
    case DEPENDENCY_TYPES.EndDate:
      return {
        from: 'End',
        to: 'End Date'
      };
    case DEPENDENCY_TYPES.StartToEndDate:
      return {
        from: 'Start',
        to: 'End Date'
      };
    case DEPENDENCY_TYPES.EndToStartDate:
      return {
        from: 'End',
        to: 'Start Date'
      };
    default:
      return {
        from: '',
        to: ''
      };
  }
};

export const getDependencyItemStatusTooltip = ({
  entity,
  startDate
}: {
  entity: ValueOf<typeof DEPENDABLE_LABELS>;
  startDate?: string;
}): string | undefined => {
  if (!startDate) return `${entity} does not have dates`;
};
