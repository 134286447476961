import React from 'react';
import styled from 'styled-components';

const generateAdminsTooltip = (admins) => 'admins';
const generateMessage = (admins) => `Contact your ${generateAdminsTooltip(
  admins
)} to sign up for a Guest Plan to Invite
non-team members to this project. Clients, consultants,
friends - anyone can collaborate on Mosaic. `;

const MessageWrapper = styled.div`
  font-size: 13px;
  padding: 15px !important;
  margin-left: 0px;
  text-align: left;
  margin-right: 0;
  width: 100%;
  border: none;
  max-width: 392px;
`;

const ContactAdminMessage = () => (
  // generateMessage could be used to implement tooltip
  <MessageWrapper className="admin-message">{generateMessage()}</MessageWrapper>
);

export default ContactAdminMessage;
