import React from 'react';
import ActivityTable from './ActivityTable/ActivityTableContainer';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import { SectionRoutes } from 'appConstants/navigation';

const ActivityTab = ({ isDashboardWidgetModal, widgetType }) => {
  const { filterId, viewBy, pageName, activeFilter, widget, filterSections } =
    useReportsViewFilter({
      ...(isDashboardWidgetModal && {
        matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType/:reportViewType`,
        widgetType
      })
    });
  return (
    <ActivityTable
      activeFilter={activeFilter}
      viewBy={viewBy}
      pageName={pageName}
      filterSections={filterSections}
    />
  );
};

export default ActivityTab;
