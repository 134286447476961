import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SideBarBackArrowIcon = ({
  width = '12',
  height = '20',
  fill = '#13225C',
  ...rest
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 12 20"
    {...rest}
    fill={fill}
  >
    <path
      d="M9.68328 0.000246048L11.735 2.05196L4.10335 9.64868L11.7001 17.2803L9.64836 19.332L0 9.64875L9.68328 0.000246048Z"
      fill={fill}
    />
  </SvgIcon>
);

export default SideBarBackArrowIcon;
