import { SimpleConfirmModal } from 'components/Modals';

interface ClearTaskDependenciesConfirmModalProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
}

export const ClearTaskDependenciesConfirmModal = ({
  isOpen,
  toggle,
  onConfirm
}: ClearTaskDependenciesConfirmModalProps) => (
  <SimpleConfirmModal
    isOpen={isOpen}
    toggle={toggle}
    onCancel={toggle}
    shouldSetIsClosingOnClose={false}
    onConfirm={onConfirm}
    header="Clear Dependencies"
    confirmLabel="Clear"
    body="This task has dependencies. Do you want to clear them?"
  />
);
