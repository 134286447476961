import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Sections, Views, Modals } from 'appConstants/navigation';

class IntegrationsNavigationTracker extends React.Component {
  render() {
    return <NavigationTracker view={Sections.INTEGRATIONS} />;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IntegrationsNavigationTracker)
);
