import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';
import { getRowValuesForEntityType } from './utils';
interface AdditionalParams {
  // { projectTitle: { projectNumber: { phaseName: { activityTitle: feeTargetValue } } } }
  activityFeeTargetOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>;
}

export const activityFeeTargetOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { activityFeeTargetOption } = additionalParams;
  const {
    enteredProjectTitle,
    enteredProjectNumber,
    enteredPhaseName,
    enteredActivityTitle
  } = getRowValuesForEntityType(row, entityType);
  const matchedActivityFeeTargetByEnteredFieldValues =
    activityFeeTargetOption.current[enteredProjectTitle]?.[
      enteredProjectNumber
    ]?.[enteredPhaseName]?.[enteredActivityTitle];

  // if there were no option for this project title + project number + phase name + activity title combination, return an empty array
  // including '' so that user can go back to empty field
  if (matchedActivityFeeTargetByEnteredFieldValues === undefined) return [''];
  // else reuturn the matched option
  else if (matchedActivityFeeTargetByEnteredFieldValues === '') return [''];
  else return ['', matchedActivityFeeTargetByEnteredFieldValues];
};
