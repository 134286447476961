import theme from 'theme';

export const TASK_STATUS_ITEMS = {
  OVERDUE: 'overdue',
  UNSCHEDULED: 'unscheduled',
  TODAY: 'today',
  SCHEDULED: 'scheduled',
  NEXT_7_DAYS: 'next7Days',
  COMPLETED: 'completed'
};

export const TASK_STATUS_COLORS_WIDGET = {
  [TASK_STATUS_ITEMS.UNSCHEDULED]: theme.colors.colorLightGray15,
  [TASK_STATUS_ITEMS.OVERDUE]: theme.colors.colorMediumRed3,
  [TASK_STATUS_ITEMS.TODAY]: theme.colors.colorLightOrange4,
  [TASK_STATUS_ITEMS.SCHEDULED]: theme.colors.colorMediumYellow3,
  [TASK_STATUS_ITEMS.NEXT_7_DAYS]: theme.colors.colorMediumYellow3,
  [TASK_STATUS_ITEMS.COMPLETED]: theme.colors.colorPaleGreen3
};

export const TASK_STATUS_COLORS = {
  [TASK_STATUS_ITEMS.UNSCHEDULED]: {
    buttonColor: theme.colors.colorLightGray15,
    color: theme.colors.colorSemiDarkGray7
  },
  [TASK_STATUS_ITEMS.OVERDUE]: {
    buttonColor: theme.colors.colorMediumRed3,
    color: theme.colors.colorMediumRed4
  },
  [TASK_STATUS_ITEMS.TODAY]: {
    buttonColor: theme.colors.colorLightOrange4,
    color: theme.colors.colorMediumOrange4
  },
  [TASK_STATUS_ITEMS.SCHEDULED]: {
    buttonColor: theme.colors.colorMediumYellow3,
    color: theme.colors.colorMediumYellow4
  },
  [TASK_STATUS_ITEMS.NEXT_7_DAYS]: {
    buttonColor: theme.colors.colorMediumYellow3,
    color: theme.colors.colorMediumYellow4
  },
  [TASK_STATUS_ITEMS.COMPLETED]: {
    buttonColor: theme.colors.colorMediumGreen7,
    color: theme.colors.colorPaleGreen3
  }
};

export const TASK_STATUSES_WIDGET = [
  {
    name: TASK_STATUS_ITEMS.UNSCHEDULED,
    label: 'Not Planned',
    color: TASK_STATUS_COLORS_WIDGET[TASK_STATUS_ITEMS.UNSCHEDULED]
  },
  {
    name: TASK_STATUS_ITEMS.NEXT_7_DAYS,
    label: 'Next 7 Days',
    color: TASK_STATUS_COLORS_WIDGET[TASK_STATUS_ITEMS.NEXT_7_DAYS]
  },
  {
    name: TASK_STATUS_ITEMS.TODAY,
    label: 'Today',
    color: TASK_STATUS_COLORS_WIDGET[TASK_STATUS_ITEMS.TODAY]
  },
  {
    name: TASK_STATUS_ITEMS.OVERDUE,
    label: 'Overdue',
    color: TASK_STATUS_COLORS_WIDGET[TASK_STATUS_ITEMS.OVERDUE]
  }
];
