import SvgIcon from 'components/SvgIcon';

const DropdownThickIcon = ({
  width = '12',
  height = '8',
  fill = '#FFFFFF',
  viewBox = '0 0 12 8',
  ...rest
}) => (
  <SvgIcon
    viewBox={viewBox}
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path d="M1.5 1.5L6 6.5L10.5 1.5" stroke="white" strokeWidth="1.5" />
  </SvgIcon>
);

export default DropdownThickIcon;
