import { createRequestTypes } from 'appUtils';

export const FETCH_ACCESS = createRequestTypes('FETCH_ACCESS');
export const UPDATE_ACCESS = createRequestTypes('UPDATE_ACCESS');
export const OPEN_ACCESS_MODAL = 'OPEN_ACCESS_MODAL';
export const CLOSE_ACCESS_MODAL = 'CLOSE_ACCESS_MODAL';

export const ACCESS_LABELS = {
  REMOVE: 'Remove',
  CAN_EDIT: 'Can Edit',
  VIEW_ONLY: 'View Only'
};

const CAN_VIEW_IDS = 'can_view_ids';
const CAN_EDIT_IDS = 'can_edit_ids';

export const ACCESS_BUCKETS = {
  CAN_EDIT_BUCKETS: [CAN_EDIT_IDS],
  CAN_VIEW_BUCKETS: [CAN_VIEW_IDS],
  NO_BUCKETS: [],
  ALL_BUCKETS: [CAN_EDIT_IDS, CAN_VIEW_IDS]
};

export const BUDGET_ACCESS_ROLES = {
  BUDGET_MANAGER: 5,
  BUDGET_MANAGER_VIEW_ONLY: 6
};
