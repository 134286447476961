import { useEffect } from 'react';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ListItem } from './ListItem';
import { DependableItem } from 'components/Dependency/types';
import styled from 'styled-components';
import DropdownMenuIcon from 'icons/DropdownMenuIcon';
import moment from 'moment';
import PhaseMilestoneIconNarrow from 'icons/PhaseMilestoneIconNarrow';
import EllipsisText, { TooltipBehavior } from 'components/EllipsisText';
import theme from 'theme';

import {
  DependencyItemContainer,
  ListItemContainer,
  ArrowIconContainer
} from './styles';
import { getDependencyItemStatusTooltip } from './utils';
import { DEPENDABLE_LABELS, DEPENDABLE_TYPES } from './constants';

export const PhaseDependencyItemRenderer = ({
  item: { dependableId, label, startDate, endDate }
}: {
  item: DependableItem;
}) => {
  const formattedStartDate: string = startDate
    ? moment(startDate).format('M/D/YY')
    : '';
  const formattedEndDate: string = endDate
    ? moment(endDate).format('M/D/YY')
    : '';

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const dependencyItemStatusTooltipText = getDependencyItemStatusTooltip({
    entity: DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE],
    startDate: formattedStartDate
  });

  const primaryText = (
    <PrimaryTextContainer>
      <PhaseIconContainer>
        <PhaseMilestoneIconNarrow className={undefined} />
      </PhaseIconContainer>
      <EllipsisText
        maxWidth={135}
        style={{
          color: !formattedStartDate ? theme.colors.colorLightGray15 : undefined
        }}
        tooltip={dependencyItemStatusTooltipText}
        tooltipBehavior={
          dependencyItemStatusTooltipText
            ? TooltipBehavior.AlwaysEnabled
            : TooltipBehavior.EnabledOnEllipsis
        }
        width={135}
      >
        {label}
      </EllipsisText>
    </PrimaryTextContainer>
  );

  return (
    <DependencyItemContainer>
      <ListItemContainer>
        <ListItem
          key={dependableId}
          primary={primaryText}
          secondary={`${formattedStartDate}${
            formattedEndDate ? ' - ' + formattedEndDate : ''
          }`}
        />
      </ListItemContainer>
      {formattedStartDate && (
        <ArrowIconContainer>
          <DropdownMenuIcon />
        </ArrowIconContainer>
      )}
    </DependencyItemContainer>
  );
};

const PrimaryTextContainer = styled.div`
  display: flex;
`;

const PhaseIconContainer = styled.div`
  flex-basis: 14px;
  margin-top: -1px;
`;
