import { useWorkPlanForm } from '../WorkPlanFormProvider';
import { SimpleConfirmModal } from 'components/Modals';

export const UnarchiveMemberConfirmModal = () => {
  const {
    member: {
      member,
      onConfirmToUnarchiveMember,
      isUnarchiveMemberConfirmModalOpen,
      onSetUnarchiveMemberConfirmModalOpen
    }
  } = useWorkPlanForm();

  const getConfirmMessage = () => {
    return (
      <div>
        <b>{member?.account?.name}</b> needs to be unarchive to update Work
        Plan.
        <br />
        Do you want to unarchive this member?
      </div>
    );
  };

  return (
    <SimpleConfirmModal
      isOpen={isUnarchiveMemberConfirmModalOpen}
      onConfirm={onConfirmToUnarchiveMember}
      toggle={() => {
        onSetUnarchiveMemberConfirmModalOpen(false);
      }}
      confirmLabel="Unarchive"
      body={getConfirmMessage()}
      header={'Unarchive Member'}
      confirmOnly={undefined}
      stylesWrapper={undefined}
      hasDoNotShowAgain={undefined}
      doNotShowAgainSettingName={undefined}
      submitButtonColor={undefined}
      confirmOnLeft={undefined}
      onCancel={undefined}
      preventClickOut={undefined}
      className={undefined}
    />
  );
};
