import styled from 'styled-components';
import theme from 'theme';

// file in react-dates with modifiers
// src/components/CalendarDay.jsx

export const StyledDropdownArrowContainer = styled.div`
  z-index: 10;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0px;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DependencyDropdownContainer = styled.div`
  height: 38px;
  border: 0.4375px solid #cdcdcd;
  width: 288px;
  margin: auto;
  margin-top: -9px;
  margin-bottom: -10px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  color: ${(props) =>
    props.isDisabled ? '#a4a4a4' : theme.colors.colorMediumGray9};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  background: #ffffff;
  border-radius: 4px;
`;

export const TimeDropdownContainer = styled.div`
  height: 28px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: ${theme.colors.colorMediumGray9};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const CalendarFooter = styled.div`
  height: calc(100% + 2px);
  width: 178px;
  width: 180px;
  left: -179px;
  background: white;
  top: -2px;
  position: absolute;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background: #f7f7f7;
`;

export const CalendarFooterHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #333333;
  margin-left: 22px;
  margin-top: 32px;
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  margin-left: -27px;
`;

export const DependencyStepHeader = styled.div`
  font-size: 20px;
  color: ${theme.colors.colorPureBlack};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  width: 288px;
  padding: 0px 19px;
  margin-top: 8px;
`;

export const DependencyMenuHeader = styled.div`
  font-size: 20px;
  color: ${theme.colors.colorPureBlack};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  width: 288px;
  margin-top: -1px;
  margin-left: -2px;
  margin-right: -56px;
  margin-bottom: -5px;
`;

export const DependencyItemName = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${theme.colors.colorMediumGray9};
`;

export const DependencyItemDate = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${theme.colors.colorCalendarGray};
`;

export const DependencyOption = styled.div`
  display: ${(props) => (props.isDependencyStep ? 'flex' : 'block')};
  font-weight: 400;
  background: white;
  color: #4f4f4f;
  line-height: 24px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
  width: 100%;
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  color: ${(props) =>
    props.disabled
      ? theme.colors.colorPaleGray5
      : theme.colors.colorMediumGray9};
  cursor: 'pointer';
  font-size: 14px;
  padding: 7px 10px 7px 26px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
  height: 60px;
  ${(props) =>
    props.isDependencyRow &&
    `
      flex-direction: column;
      justify-content: center;
      ${DependencyItemName} {
        transform: translateX(-8px);
      }
      ${DependencyItemDate} {
        align-self: start;
      }
  `}
`;

export const DependencyOptionDate = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorMediumGray7};
  font-size: 13px;
`;

export const DependencyPhaseName = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${theme.colors.mediumGray9};
  display: flex;
  align-items: center;
`;

export const DependencyPhaseDate = styled.div`
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  font-size: 13px;
  margin-left: 15px;
  margin-top: -4px;
`;

export const NoDependencyText = styled.div`
  font-weight: 600;
  margin-left: 13px;
`;

export const PhaseIconContainer = styled.div`
  margin-left: -5px;
  display: flex;
  margin-right: 5px;
`;

export const FooterOptionsContainer = styled.div`
  margin: auto;
  margin-top: 22px;
  padding-left: 18px;
  padding-bottom: 18px;
`;

export const DependencyOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: auto;
  max-height: 205px;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const CalendarContainer = styled.div`
  width: 276px;
  height: ${(props) => (props.shouldRenderButtonPanel ? '390px' : '348px')};
  background-color: ${(props) =>
    props.isWorkplanOrPhaseCalendar ? '#f5f5f5' : 'transparent'};

  .DayPicker {
    border-radius: ${(props) => (props.isWorkplanCalendar ? 0 : undefined)};
    height: ${(props) => (props.isWorkplanCalendar ? '489px' : undefined)};
  }

  .DayPicker,
  .CalendarMonth,
  .CalendarMonthGrid {
    background-color: ${(props) =>
      props.isWorkplanOrPhaseCalendar ? '#f3f3f3' : '#ffffff'};
  }

  .weekend {
    color: ${theme.colors.colorCalendarGray};
  }

  .circleContainer {
    width: 100%;
    height: 99%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CalendarDay__Default,
  .CalendarDay {
    color: #383737;
    border: none;
    background-color: ${(props) =>
      props.isWorkplanOrPhaseCalendar ? '#f3f3f3' : 'transparent'};
    :hover {
      background-color: inherit;
      .circleContainer {
        background-color: ${(props) =>
          props.isWeekly ? 'transparent' : '#e4e4e7'};
      }
    }
  }

  .CalendarDay__outside {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }

  .CalendarDay.CalendarDay__selected,
  .CalendarDay.CalendarDay__selected.CalendarDay.CalendarDay__hovered_offset {
    background-color: ${({ isSingleDay }) =>
      !isSingleDay ? '#ddeeff' : 'white'};
    opacity: 1;

    .circleContainer {
      background-color: ${theme.colors.colorCalendarBlue};
      color: #ffffff;
    }

    :hover {
      .circleContainer {
        background-color: ${theme.colors.colorCalendarBlue} !important;
      }
    }
  }

  .CalendarDay__selected_start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .CalendarDay__selected_end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .CalendarDay.CalendarDay__selected_span,
  .CalendarDay.CalendarDay__selected_span.CalendarDay.CalendarDay__hovered_offset {
    background-color: #ddeeff;
    opacity: 1;

    :hover {
      background-color: #ddeeff !important;
    }
  }

  .CalendarDay.CalendarDay__hovered_span {
    :hover {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }

  .CalendarDay.CalendarDay__hovered_span,
  .CalendarDay.CalendarDay__hovered_offset {
    opacity: 1;
    background-color: ${(props) =>
      props.isWorkplanOrPhaseCalendar ? '#e9e9e9' : '#f4f5f5'};

    .circleContainer {
      width: 100%;
      height: 99%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background-color: ${(props) =>
          props.isWeekly ? 'transparent' : '#e4e4e7'};
      }
    }
  }

  .CalendarDay__today {
    color: #ff8520;
    font-weight: bold;
    font-size: 18px;
  }

  .CalendarDay__highlighted_calendar {
    color: ${theme.colors.colorCalendarRed} !important;
  }

  .CalendarDay__today.CalendarDay__selected,
  .CalendarDay__today.CalendarDay__selected.CalendarDay.CalendarDay__hovered_offset {
    background-color: ${({ isSingleDay }) =>
      !isSingleDay ? '#ddeeff' : 'white'};
    opacity: 1;

    .circleContainer {
      background-color: #ff8520;
      color: #ffffff;
    }

    :hover {
      .circleContainer {
        background-color: #ff8520 !important;
      }
    }
  }
  .CalendarMonth_caption {
    padding-top: 6px;
    padding-bottom: 24px;
  }
`;

export const InfoPanelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonDiv = styled.div`
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  margin-top: 24px;
`;

export const DoneButton = styled.button`
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 5px;
  background: ${theme.colors.colorCalendarBlue};
  color: #ffffff;
  width: 52px;
  height: 28px;
  font-size: 13px;
  position: absolute;
  top: 21px;
  right: -27px;
  z-index: 100;
  font-weight: 600;
`;

export const CancelButton = styled.button`
  border: ${({ isWorkplanOrPhaseCalendar }) =>
    isWorkplanOrPhaseCalendar ? '1px solid #dbdbdb' : '1px solid #cdcdcd'};
  border-radius: 5px;
  background: ${({ isWorkplanOrPhaseCalendar }) =>
    isWorkplanOrPhaseCalendar ? '#f5f5f5' : '#ffffff'};
  color: #828282;
  width: 52px;
  height: 28px;
  font-size: 13px;
  position: absolute;
  top: 21px;
  left: 18px;
  z-index: 100;
  ${({ showClear }) => !showClear && 'display: none;'}
  font-weight: 600;
`;

export const InputDiv = styled.div`
  margin: 0px 10px;
  margin-top: ${({ isWorkplanOrPhaseCalendar }) =>
    isWorkplanOrPhaseCalendar ? '-23px' : '0px'};
  margin-left: ${({ isWorkplanOrPhaseCalendar, label }) =>
    isWorkplanOrPhaseCalendar && label === 'START' ? '19px' : '10px'};
  position: relative;
  .label {
    color: ${({ isWorkplanOrPhaseCalendar }) =>
      isWorkplanOrPhaseCalendar ? '#a4a4a4' : '#828282'};
    font-weight: ${({ isWorkplanOrPhaseCalendar }) =>
      isWorkplanOrPhaseCalendar ? '600' : '400'};
    font-size: 12px;
    margin-bottom: ${({ isWorkplanOrPhaseCalendar }) =>
      isWorkplanOrPhaseCalendar ? '4px' : '6px'};
    text-transform: uppercase;
  }

  .input {
    width: ${({ isWorkplanOrPhaseCalendar, isWorkdays }) =>
      isWorkdays ? '70px' : isWorkplanOrPhaseCalendar ? '90px' : '120px'};
    height: ${({ isWorkplanOrPhaseCalendar, isWorkdays }) =>
      isWorkplanOrPhaseCalendar ? (isWorkdays ? '35px' : '33px') : '30px'};
    border: ${({
      isFocused,
      disabled,
      isWorkplanOrPhaseCalendar,
      isWorkdays,
      dependency
    }) =>
      isFocused && !disabled && !isWorkplanOrPhaseCalendar
        ? ` 1px solid ${theme.colors.colorCalendarBlue}`
        : isWorkplanOrPhaseCalendar && !isWorkdays
        ? 'none'
        : '1px solid #cdcdcd'};
    background: ${({ dependency }) =>
      dependency === 'StartEndDate' ? '#f5f5f5' : ''};
    border-radius: ${({ isWorkplanOrPhaseCalendar }) =>
      isWorkplanOrPhaseCalendar ? '0px' : '5px'};
    font-size: 13px;
    color: ${({ singleDay, hasDependency }) =>
      singleDay ? '#a4a4a4' : hasDependency ? '#828282' : '#333333'};
    text-align: ${({ isWorkplanOrPhaseCalendar, isWorkdays }) =>
      isWorkplanOrPhaseCalendar && isWorkdays ? 'center' : 'left'};
    margin-top: ${({ isWorkplanOrPhaseCalendar }) =>
      isWorkplanOrPhaseCalendar ? '1px' : '0px'};
    pointer-events: ${({ hasDependency }) => (hasDependency ? 'none' : 'auto')};
    border-color: ${({ isFocused, disabled, dependency }) =>
      dependency === 'StartEndDate'
        ? '#f5f5f5'
        : isFocused && !disabled
        ? '#2f80ed'
        : '#cdcdcd'};
    padding-left: 5px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1px; // to aligin margin from parent
  bottom: 0;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: ${theme.colors.colorDeleteRed};
  font-size: 14px;
`;

export const FormContainer = styled.form`
  width: 100%;
`;

export const SetRangeButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  height: 42px;
  font-size: 13px;
  font-weight: bold;
  color: ${theme.colors.colorCalendarBlue};
  border-top: 1px solid ${theme.colors.colorLightGray7};
`;

export const CustomCheckBoxContainer = styled.div`
  border-radius: 3px;
  border: 1px solid ${theme.colors.colorMediumGray5};
  background: ${({ isDisabled }) =>
    isDisabled ? theme.colors.colorLightGray6 : 'transparent'};
  cursor: ${({ isDisabled }) => (isDisabled ? '' : 'pointer')};
`;

export const CustomCheckBox = styled.div`
  margin: 3px;
  height: 6px;
  width: 6px;
  border-radius: 2px;
  background: ${({ isChecked, isDisabled }) =>
    isDisabled
      ? 'transparent'
      : isChecked
      ? theme.colors.colorRoyalBlue
      : '#f5f5f5'};
`;

export const SmallOptionsContainer = styled.div`
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  align-items: center;
  padding: 8px 0px;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${CustomCheckBoxContainer} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
      border: 1px solid ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const SmallIconContainer = styled.div`
  height: 14px;
  display: flex;
  align-items: center;
  width: 14px;
  margin-right: 6px;
  margin-left: 6px;
`;

export const WorkplanInputContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  height: 35px;
  width: 210px;
  border: ${({ dependency }) =>
    dependency === 'StartEndDate'
      ? '0.5px solid #f5f5f5'
      : '0.5px solid #cdcdcd'};
  background: ${({ dependency }) =>
    dependency === 'StartEndDate' ? 'transparent' : 'white'};
  margin-left: 13px;
`;

export const StyledBackKaratContainer = styled.div`
  transform: rotate(180deg);
  width: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-left: 12px;
  margin-top: 3px;
  margin-right: 63px;
  path {
    stroke: ${theme.colors.colorMediumGray9};
    fill: ${theme.colors.colorMediumGray9};
    stroke-width: 1;
  }
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  position: absolute;
  right: -44px;
  top: 20px;
`;

export const StyledSearch = styled.input`
  position: sticky;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};
  &::placeholder {
    color: ${theme.colors.colorMediumGray1};
    font-size: ${({ placeholderSize }) => placeholderSize || '14px'};
  }
  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid ${theme.colors.colorRoyalBlue};
  }
  margin-bottom: 9px;
`;

export const OverCapacityLabel = styled.div`
  position: absolute;
  color: ${theme.colors.colorCalendarRed};
  z-index: 1;
  font-weight: 600;
  font-size: 12px;
  left: 95px;
  top: -1px;
`;

export const TimeDropdownLabel = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
  font-size: 12px;
  margin-top: -12px;
  margin-bottom: 10px;
  margin-left: 18px;
`;

export const TimeContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  font-weight: 400;
  cursor: pointer;
  color: ${(props) =>
    props.isDisabled
      ? theme.colors.colorLightGray15
      : theme.colors.colorMediumGray9};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }

  & .rc-time-picker-input {
    background-color: ${theme.colors.colorTranslucentGray3};
    padding-left: 6px !important;
  }
`;

export const DependencySetterLabel = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
  font-size: 12px;
  width: ${(props) => (props.width ? props.width : '288px')};
  margin: auto;
  position: relative;
  left: 2.5px;
  top: -9px;
`;

export const DependencyLabelContainer = styled.div``;

export const StyledHelpIconContainer = styled.div`
  position: relative;
  top: -8px;
  left: 1px;
`;
