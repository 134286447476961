import { put, select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { fetchEntity } from './generics';
import { api } from '../service';
import { getAuthToken, getTeamSlug } from 'selectors';
import { navigateToMembers } from 'actionCreators';

const { memberProfileFetch } = entityActions;

export function* teamMemberProfileFetch(action) {
  const { params } = action.payload;
  const token = yield select(getAuthToken);
  const { teamMemberId, teamSlug } = params;
  const teamSlugSelect = yield select(getTeamSlug);
  const viableTeamSlug = teamSlug || teamSlugSelect;

  const { error, response } = yield fetchEntity(
    memberProfileFetch,
    api.fetchTeamMemberProfile,
    undefined,
    [teamMemberId, token],
    action
  );
  if (!error) {
    if (!error) {
      yield put(
        navigateToMembers({
          teamSlug: viableTeamSlug,
          membersViewType: 'tasks',
          teamMemberId
        })
      );
    }
  }
}
