import { select, put } from 'redux-saga/effects';
import * as api from '../api';
import * as entityActions from '../entityActions';
import { fetchEntity } from 'sagas/generics';
import { fetchBudgetRecords } from 'BudgetModule/actionCreators';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';

import { getAuthToken, getSelectedTeamId, getZoom } from 'selectors';
import { ZOOM_LEVELS } from 'appConstants/workload';

const {
  fetchUtilizations,
  fetchUtilizationsReport,
  fetchUtilizationsReportTotals
} = entityActions;

const zoomToScale = {
  [ZOOM_LEVELS.YEAR]: 'daily',
  [ZOOM_LEVELS.QUARTER]: 'daily',
  [ZOOM_LEVELS.MONTH]: 'daily',
  [ZOOM_LEVELS.WEEK]: 'daily',
  [ZOOM_LEVELS.DAY]: 'daily'
};
export function* fetchUtilizationsWorker(action) {
  const { startDate, endDate, memberId, teamId, includeTentative } =
    action.payload;
  const token = yield select(getAuthToken);
  const zoom = yield select((state) =>
    getZoom(state, { plannerType: 'workload' })
  );

  const scale = zoomToScale[zoom];
  const accountIds = action.payload.accountIds.slice();
  while (accountIds.length) {
    const { error, response } = yield fetchEntity(
      fetchUtilizations,
      api.fetchUtilizations,
      teamId,
      [
        token,
        accountIds.splice(0, 20),
        startDate,
        endDate,
        scale,
        includeTentative
      ],
      action
    );
  }
}
export function* fetchUtilizationsReportWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    fetchUtilizationsReport,
    api.fetchUtilizationsReport,
    undefined,
    [token, body],
    action
  );
}
export function* fetchUtilizationsReportTotalsWorker(action) {
  const { body } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    fetchUtilizationsReportTotals,
    api.fetchUtilizationsReport,
    undefined,
    [token, body],
    action
  );
}

export function* fetchTeamUtilizationReportWorker(action) {
  const token = yield select(getAuthToken);
  const { filterStateId, ...body } = action.payload;

  const params = {
    ...body,
    group_by: 'Team',
    all: true
  };

  const { error, response } = yield fetchEntity(
    entityActions.fetchTeamUtilizationReport,
    api.fetchUtilizationsIntervalReport,
    undefined,
    [token, params],
    action
  );
}

export function* fetchMembersUtilizationReportWorker(action) {
  const token = yield select(getAuthToken);
  const { filterStateId, takeLatest, ...fetchParams } = action.payload;
  const params = {
    all: true,
    group_by: 'Member',
    ...fetchParams
  };

  const { error, response } = yield fetchEntity(
    entityActions.fetchMembersUtilizationReport,
    api.fetchUtilizationsIntervalReport,
    undefined,
    [token, params],
    action
  );
}

export function* fetchProjectUtilizationReportWorker(action) {
  const token = yield select(getAuthToken);
  const { filterStateId, takeLatest, ...fetchParams } = action.payload;
  const params = {
    ...fetchParams,
    group_by: 'Project',
    all: true
  };

  const { error, response } = yield fetchEntity(
    entityActions.fetchProjectUtilizationReport,
    api.fetchUtilizationsIntervalReport,
    undefined,
    [token, params],
    action
  );
}

export function* fetchPhaseUtilizationReportWorker(action) {
  const token = yield select(getAuthToken);
  const { filterStateId, takeLatest, ...fetchParams } = action.payload;
  const params = {
    ...fetchParams,
    group_by: 'Phase',
    all: true
  };

  const { error, response } = yield fetchEntity(
    entityActions.fetchPhaseUtilizationReport,
    api.fetchUtilizationsIntervalReport,
    undefined,
    [token, params],
    action
  );
}

export function* fetchTeamHistoricalUtilizationWorker(action) {
  const token = yield select(getAuthToken);
  const { filterStateId, ...body } = action.payload;

  // May need to change params
  const params = {
    ...body,
    group_by: 'Team',
    all: true
  };

  const { error, response } = yield fetchEntity(
    entityActions.fetchTeamHistoricalUtilization,
    api.fetchTeamHistoricalUtilization,
    undefined,
    [token, params],
    action
  );
}
