import { ColorScaleIntervalsWithMax } from 'components/ColorScaleModal/types';
import { HeatmapContainer } from './styles';

interface AvailabilitySkeletonBucketProps {
  heatmap?: ColorScaleIntervalsWithMax;
}
export const AvailabilitySkeletonBucket = ({
  heatmap
}: AvailabilitySkeletonBucketProps) => {
  const color = heatmap?.[0]?.style?.color;
  return (
    <HeatmapContainer $capacityColor={color} $summaryBackgroundHeight={0}>
      {/* TODO: add skeleton ui */}
    </HeatmapContainer>
  );
};
