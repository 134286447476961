import { useMemo } from 'react';
import theme from 'theme';
import sum from 'lodash/sum';
import styled, { css } from 'styled-components';
import { SORT_ORDER } from 'appConstants/filters';
import {
  Bar,
  BarSection as DefaultBarSection,
  NonbillableSection as DefaultNonbillableSection,
  HolidaySection as DefaultHolidaySection,
  PtoSection as DefaultPtoSection,
  RemainingCapacitySection as DefaultCapacitySeciton,
  GraphCellContainer
} from './styles';

const baseColumnWidth = 493;
const minimumWidth = 4;

const ptoBorder = '1px solid rgba(137, 184, 231, 0.3)';
const holidayBorder = '1px solid rgba(205, 205, 205, 0.4)';

const hoverEffectCommon = css`
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: 1000;
  ${(props) =>
    props.isRightmost
      ? `
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  `
      : ''}
`;

const BarSection = styled(DefaultBarSection)`
  border-right: 1px solid ${theme.colors.colorPureWhite};
  position: relative;
  :hover {
    :after {
      background: ${({ color }) => color || theme.colors.colorLightGray6};
      border: none;
      ${hoverEffectCommon}
    }
  }
`;
const ProjectSection = styled(BarSection)`
  background: ${({ color }) => color || theme.colors.colorLightGray6};
  color: white;
  padding: 0;
  min-width: 4px;
`;

const NonbillableSection = styled(DefaultNonbillableSection)`
  border-right: 1px solid ${theme.colors.colorPureWhite};
  position: relative;
  :hover {
    :after {
      background: ${theme.colors.colorLightGray20};
      ${hoverEffectCommon}
    }
  }
`;

const HolidaySection = styled(DefaultHolidaySection)`
  position: relative;
  :hover {
    :after {
      background: ${theme.gradients.holiday};
      border-top: ${holidayBorder};
      border-bottom: ${holidayBorder};
      ${(props) =>
        props.isRightmost &&
        `
          border-right: ${holidayBorder};
        `}
      ${hoverEffectCommon}
    }
  }
`;

const PtoSection = styled(DefaultPtoSection)`
  position: relative;
  :hover {
    :after {
      background: ${theme.gradients.pto};
      border-top: ${ptoBorder};
      border-bottom: ${ptoBorder};
      ${(props) =>
        props.isRightmost &&
        `
          border-right: ${ptoBorder};
        `}
      ${hoverEffectCommon}
    }
  }
`;

const RemainingCapacitySection = styled(DefaultCapacitySeciton)`
  border-right: 1px solid ${theme.colors.colorPureWhite};
  position: relative;
  :hover {
    :after {
      background: ${theme.colors.colorPaleGray5};
      ${hoverEffectCommon}
    }
  }
`;

const nonProjects = {
  pto: { name: 'pto', label: 'PTO', color: theme.gradients.pto },
  holiday: {
    name: 'holiday',
    label: 'Holiday',
    color: theme.gradients.holiday
  },
  nonbillable: {
    name: 'nonbillable',
    label: 'Not Billable',
    color: theme.colors.colorLightGray20
  },
  capacity: {
    name: 'capacity',
    label: 'Remaining Capacity',
    color: theme.colors.colorPaleGray5
  }
};

const getTooltipContent = (
  barSectionId,
  projectsHash,
  colorHash,
  boardsHash,
  hours = ''
) => {
  const project = projectsHash[barSectionId] || nonProjects[barSectionId];

  return {
    projectNumber: project?.project_number || '',
    boardName:
      project?.board?.name || boardsHash[project?.board_id]?.name || '',
    projectName: project?.title || project?.label,
    projectColor: colorHash[barSectionId] || project?.color,
    hours
  };
};

const formatBarSectionData = ({ barSectionId, data, totalHours }) => {
  const hours = +data.hours || +data.spent_hours_billable;
  const percent = ((hours || 0) / (totalHours || 1)) * 100;
  return {
    id: barSectionId,
    hours,
    percent
  };
};

const nonProjectBarSections = {
  pto: PtoSection,
  holiday: HolidaySection,
  nonbillable: NonbillableSection,
  capacity: RemainingCapacitySection
};

const ProjectBreakdownCell = ({ row }) => {
  const {
    maxHours,
    maxPercent,
    customRowProps,
    data: {
      accountProjectOrder,
      accountProjectData,
      totalHours,
      totalPercent,
      billable_percentage,
      nonbillablePercent,
      nonbillable_hours,
      ptoPercent,
      pto_hours,
      holidayPercent,
      holiday_hours,
      remaining_capacity,
      remaining_capacity_percentage
    },
    account
  } = row.original;
  const {
    isPercent,
    projectsHash,
    boardsHash,
    projectColors,
    showPto,
    showHolidays,
    sortOrder
  } = customRowProps;
  const hoursRatio = maxHours
    ? Math.min((totalHours / maxHours) * 100, 100)
    : 0;
  const percentRatio = maxPercent
    ? ((totalPercent + remaining_capacity_percentage) / maxPercent) * 100
    : 0;
  const accountId = account?.id;
  const nonProjectData = useMemo(
    () => ({
      pto: {
        hours: pto_hours,
        percent: ptoPercent
      },
      holiday: {
        hours: holiday_hours,
        percent: holidayPercent
      },
      nonbillable: {
        hours: nonbillable_hours,
        percent: nonbillablePercent
      },
      capacity: {
        hours: remaining_capacity,
        percent: remaining_capacity_percentage
      }
    }),
    [
      holidayPercent,
      holiday_hours,
      nonbillablePercent,
      nonbillable_hours,
      ptoPercent,
      pto_hours,
      remaining_capacity,
      remaining_capacity_percentage
    ]
  );

  const formattedBarSectionOrder = useMemo(() => {
    let remainingPercent =
      100 -
      sum(
        +remaining_capacity_percentage,
        +nonbillablePercent,
        showPto ? ptoPercent : 0,
        showHolidays ? holidayPercent : 0
      ); // sum of non project type items' percentage

    const currentRowWidth = (percentRatio * baseColumnWidth) / 100;
    // calculates percent that is equivalent to 4px width of the row
    const minimumPercentThreshold = (minimumWidth * 100) / currentRowWidth;

    // the logic is to make items with less than 4px with to have minimum 4px width
    // because of that, when it's ascending order, smaller ones will get 4px width until
    // it starts to see bigger itmes and they will be omitted instead of smaller ones.
    // reverse the order, filter and then reverse it back so that it only omits the smaller ones
    const accountProjectOrderToUse =
      sortOrder === SORT_ORDER.asc
        ? [...accountProjectOrder].reverse()
        : accountProjectOrder;

    const filteredProjectOrder = accountProjectOrderToUse.filter(
      (projectId, index) => {
        const hasBillableHours =
          accountProjectData[projectId]?.spent_hours_billable > 0;
        const { percent } = formatBarSectionData({
          barSectionId: null,
          data: accountProjectData[projectId],
          totalHours: totalHours + remaining_capacity
        });
        const hasNotPassedThreshold =
          remainingPercent >= minimumPercentThreshold;

        // subtract current percent from remianing percent for the next item
        remainingPercent -= Math.max(percent, minimumPercentThreshold);

        return hasBillableHours && (hasNotPassedThreshold || index < 10);
      }
    );

    // reverse the order back to original after filtering when it's ascending order
    const filteredProjectOrderToUse =
      sortOrder === SORT_ORDER.asc
        ? [...filteredProjectOrder].reverse()
        : filteredProjectOrder;

    const hasHoliday = +holiday_hours > 0;
    const hasPto = +pto_hours > 0;
    const hasNonbillable = +nonbillable_hours > 0;
    const hasRemainingCapacity = +remaining_capacity > 0;

    hasNonbillable && filteredProjectOrderToUse.push('nonbillable');
    hasRemainingCapacity && filteredProjectOrderToUse.push('capacity');
    showPto && hasPto && filteredProjectOrderToUse.push('pto');
    showHolidays && hasHoliday && filteredProjectOrderToUse.push('holiday');

    return filteredProjectOrderToUse;
  }, [
    showPto,
    ptoPercent,
    showHolidays,
    holidayPercent,
    remaining_capacity_percentage,
    nonbillablePercent,
    percentRatio,
    accountProjectOrder,
    holiday_hours,
    pto_hours,
    nonbillable_hours,
    remaining_capacity,
    accountProjectData,
    totalHours,
    sortOrder
  ]);

  const renderSection = (barSectionId, index) => {
    const data =
      accountProjectData[barSectionId] || nonProjectData[barSectionId];
    const formattedData = formatBarSectionData({
      barSectionId,
      data,
      totalHours: totalHours + remaining_capacity
    });

    const BarSectionToRender =
      nonProjectBarSections[barSectionId] || ProjectSection;

    const tooltipContent = getTooltipContent(
      barSectionId,
      projectsHash,
      projectColors,
      boardsHash,
      formattedData.hours
    );
    return formattedData?.percent ? (
      <BarSectionToRender
        data-for={'project-breakdown-tooltip'}
        data-tip={JSON.stringify({
          barData: tooltipContent
        })}
        width={formattedData.percent}
        isRightmost={index === formattedBarSectionOrder.length - 1}
        key={`${accountId}-${barSectionId}`}
        color={projectColors[barSectionId]}
        data-testid={`account-${accountId}-section-${barSectionId}`}
      />
    ) : null;
  };

  return (
    <GraphCellContainer>
      <Bar className="bar" width={isPercent ? percentRatio : hoursRatio}>
        {formattedBarSectionOrder.map((barSectionId, index) => {
          return renderSection(barSectionId, index);
        })}
      </Bar>
    </GraphCellContainer>
  );
};
export default ProjectBreakdownCell;
