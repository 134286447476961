import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHook } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import {
  TIMESHEET_STATUSES_API,
  FILTER_RENDER_TEXT
} from 'appConstants/timesheets';

const emptyObj = {};

export const useTimesheetStatusesFilter = ({
  isOff,
  resultCountHash,
  shouldUseDraft,
  field = FilterField.status_ids
}: Parameters<FilterListTypeHook>[0] = emptyObj) => {
  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: statusIds,
    itemHash,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    shouldUseDraft,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    itemHash,
    resultCountHash,
    isOff,
    labelKey
  };
};

/* ------------------------------------ - ----------------------------------- */

const statusIds = Object.values(TIMESHEET_STATUSES_API);

const itemHash = statusIds.reduce<Record<string, { label: string }>>(
  (acc, cur) => {
    acc[cur] = {
      label: FILTER_RENDER_TEXT[cur] || ''
    };
    return acc;
  },
  {}
);

const labelKey = 'label';
