import React from 'react';
import SvgIcon from '../../components/SvgIcon';

const RichBulletedListIcon = () => (
  <SvgIcon width="12" height="8" viewBox="0 0 12 8">
    <g fill="gray" fillRule="nonzero" transform="translate(.5)">
      <path d="M2.567 0h8.342v2H2.567zM2.567 3h8.342v2H2.567zM2.567 6h8.342v2H2.567z" />
      <ellipse cx=".963" cy="1" rx="1" ry="1" />
      <path d="M.962 3C.43 3 0 3.447 0 3.999 0 4.552.43 5 .962 5s.963-.448.963-1.001c0-.552-.432-.999-.963-.999zM.962 6C.43 6 0 6.448 0 7c0 .553.43 1 .962 1s.963-.447.963-1c0-.552-.432-1-.963-1z" />
    </g>
  </SvgIcon>
);

export default RichBulletedListIcon;
