import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { TeamMember } from 'TeamsModule/models/teamMember';
import {
  AccountsSuggestionForEntitiesHash,
  AccountsSuggestionForEntitiesInstance,
  SuggestedMember
} from 'SuggestionModule/types';
import { permissionsUtils } from 'PermissionsModule/utils';

const getMemberAccountId = (member) => member.account?.id;

/* --------------------- Member Suggeestion For Entitie (Project, Phase) --------------------- */

/**
 * entityId - project_id, phase_id etc..
 */
type MakeMembersListWithSuggestionForEntityArgs<T> = {
  suggestedAccountsForEntitiesHash: AccountsSuggestionForEntitiesHash<T>;
  entityId: number;
  membersList: TeamMember[];
  numOfMembersToShow?: number;
  isIncludeProjectGuests: boolean;
};

/**
 * A utility function that formats the original membersList to include member suggestions on top of list
 * with `isSuggestedMember` flag and other suggestion properties (score, reasons) attached
 * Also returns `membersToSuggest` and `remainingMembers` seperately (which combined to get `membersListWithSuggestions`)
 * for other use cases
 *
 */
export const makeMembersListWithSuggestionForEntity = <
  E extends AccountsSuggestionForEntitiesInstance
>({
  suggestedAccountsForEntitiesHash,
  entityId,
  membersList,
  numOfMembersToShow = 5,
  isIncludeProjectGuests
}: MakeMembersListWithSuggestionForEntityArgs<E>) => {
  const preFilteredMembersList = isIncludeProjectGuests
    ? membersList
    : membersList.filter(
        (member) => !permissionsUtils.getIsProjectGuest(member)
      );

  const suggestedAccountsData = suggestedAccountsForEntitiesHash?.[entityId];

  const suggestedAccounts = suggestedAccountsData?.suggestions;

  // No Member Suggestions for this entity
  if (!suggestedAccounts?.length)
    return {
      membersListWithSuggestions: preFilteredMembersList,
      membersToSuggest: [],
      remainingMembers: []
    };

  const suggestedAccountsHash = keyBy(suggestedAccounts, 'account_id');

  // Store all members that is suggestable, but not guaranteed to be suggested, due to numOfMembersToShow limit
  const unsortedSuggestableMembers = preFilteredMembersList.reduce(
    (acc: SuggestedMember[], member) => {
      const accountId = getMemberAccountId(member);
      const memberAccountInSuggestion = suggestedAccountsHash[accountId];

      if (memberAccountInSuggestion) {
        acc.push({
          ...member,
          ...memberAccountInSuggestion,
          isSuggestedMember: true
        });
      }

      return acc;
    },
    []
  );

  // Sort by score
  const suggestableMembersSortedByScore = orderBy(
    unsortedSuggestableMembers,
    'score',
    'desc'
  );

  // Limit showing member suggestion to numOfMembersToShow
  const membersToSuggest = suggestableMembersSortedByScore.slice(
    0,
    numOfMembersToShow
  );

  // Get a set of suggested member ids
  const membersIdToSuggestSet = new Set(
    membersToSuggest.map((member) => getMemberAccountId(member))
  );

  // Removing suggested members to show from preFilteredMembersList
  const remainingMembers = preFilteredMembersList.filter(
    (member) => !membersIdToSuggestSet.has(getMemberAccountId(member))
  );

  return {
    membersListWithSuggestions: [...membersToSuggest, ...remainingMembers],
    membersToSuggest,
    remainingMembers
  };
};

/* ----------------------- Member Suggestion For Phase ---------------------- */
