import { select } from 'redux-saga/effects';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { getAuthToken } from 'selectors';

const { fetchCapacities, updateCapacity } = entityActions;

export function* fetchCapacitiesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, accountIds } = action.payload;
  yield fetchEntity(
    fetchCapacities,
    api.fetchCapacities,
    teamId,
    [token, accountIds],
    action
  );
}

export function* updateCapacityWorker(action) {
  const token = yield select(getAuthToken);
  const { add_one_off_capacities, remove_one_off_capacities, capacity } =
    action.payload;
  const { id } = capacity;
  const { error, response } = yield changeEntity(
    updateCapacity,
    api.updateCapacity,
    [token, id, add_one_off_capacities, remove_one_off_capacities, capacity]
  );
}
