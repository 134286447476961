import { useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'reduxInfra/hooks';

import { toggleTaskSidebarIsOpen, fetchFilteredPhases } from 'actionCreators';
import theme from 'theme';

import {
  EmptyCell,
  StyledSmallHamburger,
  StyledNameContainer,
  StyledDot,
  CloseTrigger
} from './WorkloadPlannerGroupProjectRenderer';

import {
  CONDENSED_ZOOM_LEVELS,
  VIEW_TYPE,
  SPLIT_SCREEN_TYPES
} from 'appConstants/workload';
import StandardPhaseIcon from 'icons/StandardPhaseIcon';
const TaskButtonContainer = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  margin-left: 12px;
`;

const StyledName = styled.div<{
  isSmallCondensedView: boolean;
  isMediumCondensedView: boolean;
}>`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
  &.member-count {
    position: absolute;
    top: ${({ isSmallCondensedView, isMediumCondensedView }) =>
      isSmallCondensedView
        ? '-11px'
        : (isMediumCondensedView && '0px') || '13px'};
  }
`;

const StyledCapacity = styled.div<{
  isSmallCondensedView: boolean;
  isMediumCondensedView: boolean;
  isVerySmallCondensedView: boolean;
}>`
  line-height: 1;
  font-size: ${({ isVerySmallCondensedView }) =>
    isVerySmallCondensedView ? '12px' : '14px'};
  margin-top: 2px;
  position: absolute;
  top: 13px;
  color: ${theme.colors.colorCalendarBlue};
  &.with-member-count {
    position: unset;
    margin-top: ${({
      isSmallCondensedView,
      isVerySmallCondensedView,
      isMediumCondensedView
    }) =>
      isVerySmallCondensedView
        ? '-36px'
        : isSmallCondensedView
        ? '-33px'
        : isMediumCondensedView
        ? '-13px'
        : '0px'};
  }
  &.active {
    cursor: pointer;
  }
`;

const ProjectCell = styled.div<{
  isSmallCondensedView: boolean;
  isMediumCondensedView: boolean;
  isVerySmallCondensedView: boolean;
}>`
  display: flex;
  padding: 0px 4px;
  padding-top: ${({
    isSmallCondensedView,
    isMediumCondensedView,
    isVerySmallCondensedView
  }) =>
    isVerySmallCondensedView
      ? '0px'
      : isSmallCondensedView
      ? '6px'
      : isMediumCondensedView
      ? '9px'
      : '16px'};
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
  &:hover {
    ${StyledDot} {
      opacity: 1;
    }
    ${StyledCapacity} {
      font-weight: 600;
    }
  }
`;

const PhaseGroupBody = styled.div`
  margin-left: 14px;
  margin-top: 0.5px;
  display: flex;
  align-items: center;
`;

const PhaseIconContainer = styled.div`
  margin-right: 4px;
  margin-top: -2px;
`;

export const AddMemberRowHeight = 400;

const SplitScreenGroupRenderer = ({
  group,
  splitScreenType,
  deactivateSplitScreen,
  workloadViewType,
  condensedZoomLevel
}) => {
  const dispatch = useAppDispatch();
  const isCondensedView = workloadViewType === VIEW_TYPE.CONDENSED;

  const handleOpenTaskSideBar = () => {
    dispatch(
      toggleTaskSidebarIsOpen({
        isOpen: true,
        projectId: null
      })
    );
  };

  const generateRoot = (element) => {
    return (
      <ProjectCell
        className="team-member-cell"
        isMediumCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
        }
        isSmallCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        }
        isVerySmallCondensedView={
          isCondensedView &&
          condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        }
      >
        {element}
      </ProjectCell>
    );
  };

  const renderPlannerRoot = () => {
    const numberOfMembers = group.member_account_ids?.length || 0;
    return generateRoot(
      <>
        <CloseTrigger
          onClick={deactivateSplitScreen}
          data-testid="close-split-screen"
        >{`\u2715`}</CloseTrigger>

        <StyledNameContainer style={{ paddingLeft: 24 }}>
          <StyledName
            isSmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
            }
            isMediumCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
            }
          >
            Total Capacity
          </StyledName>
          <StyledCapacity
            isSmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
            }
            isMediumCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
            }
            isVerySmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
            }
          >
            {numberOfMembers} Member{numberOfMembers === 1 ? '' : 's'}
          </StyledCapacity>
        </StyledNameContainer>
      </>
    );
  };

  const renderScheduledTaskRoot = () => {
    return generateRoot(
      <TaskButtonContainer onClick={handleOpenTaskSideBar}>
        <StyledSmallHamburger /> Tasks
      </TaskButtonContainer>
    );
  };

  const renderPhaseRoot = () => {
    return generateRoot(
      <TaskButtonContainer>
        <PhaseGroupBody>
          <PhaseIconContainer>
            <StandardPhaseIcon stroke={theme.colors.colorRoyalBlue} />
          </PhaseIconContainer>
          Phases
        </PhaseGroupBody>
      </TaskButtonContainer>
    );
  };

  if (!group?.id?.split) {
    return null;
  }

  // only two cases haves to be handled here unless a more complex split screen comes along
  // NOTE: work_plan split screen has no root
  switch (splitScreenType) {
    case SPLIT_SCREEN_TYPES.PROJECT:
      return group.isRoot ? renderPlannerRoot() : <EmptyCell />;
    case SPLIT_SCREEN_TYPES.SCHEDULED_TASK:
      return group.isRoot ? renderScheduledTaskRoot() : <EmptyCell />;
    case SPLIT_SCREEN_TYPES.PHASE:
      return group.isRoot ? renderPhaseRoot() : <EmptyCell />;
    default:
      return null;
  }
};

export default SplitScreenGroupRenderer;
