import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const openProjectPlannerModal = (params) =>
  createAction(constants.OPEN_PROJECT_PLANNER_MODAL, params);
export const closeProjectPlannerModal = () =>
  createAction(constants.CLOSE_PROJECT_PLANNER_MODAL);
export const setProjectPlannerMember = (params) =>
  createAction(constants.SET_PROJECT_PLANNER_MEMBER, params);
export const setVisibleWeek = (params) =>
  createAction(constants.SET_VISIBLE_WEEK, params);
export const setVisibleDates = (params) =>
  createAction(constants.SET_VISIBLE_DATES, params);
export const setVisibleIndices = (params) =>
  createAction(constants.SET_VISIBLE_INDICES, params);

export const predictWorkdays = (params) =>
  createAction(constants.PREDICT_WORKDAYS.TRIGGER, params);
export const predictWorkloadPlanner = (params) =>
  createAction(constants.PREDICT_WORKLOAD_PLANNER.TRIGGER, params);
export const createWorkloadPlanner = (params) =>
  createAction(constants.CREATE_WORKLOAD_PLANNER.TRIGGER, params);
export const fetchWorkloadPlanner = (params) =>
  createAction(constants.FETCH_WORKLOAD_PLANNER.TRIGGER, params);
export const viewWorkloadPlanner = (params) =>
  createAction(constants.VIEW_WORKLOAD_PLANNER, params);
export const updateWorkloadPlanner = (params) =>
  createAction(constants.UPDATE_WORKLOAD_PLANNER.TRIGGER, params);
export const approveWorkplanRequests = (params) =>
  createAction(constants.APPROVE_WORKPLAN_REQUESTS.TRIGGER, params);
export const splitWorkloadPlanner = (params) =>
  createAction(constants.SPLIT_WORKLOAD_PLANNER.TRIGGER, params);
export const deleteWorkloadPlanner = (params) =>
  createAction(constants.DELETE_WORKLOAD_PLANNER.TRIGGER, params);
export const updateWorkloadPlannerMembers = (params) =>
  createAction(constants.UPDATE_WORKLOAD_PLANNER_MEMBERS.TRIGGER, params);
export const fetchMemberProjects = (params) =>
  createAction(constants.FETCH_MEMBER_PROJECTS.TRIGGER, params);
export const addNewProjectRow = (params) =>
  createAction(constants.ADD_NEW_PROJECT_ROW, params);
export const deleteProjectRow = (params) =>
  createAction(constants.DELETE_PROJECT_ROW, params);

export const openWorkloadEventsModal = (params) =>
  createAction(constants.OPEN_WORKLOAD_EVENTS_MODAL, params);
export const setSelectedWorkloadEvent = (params) =>
  createAction(constants.SET_SELECTED_WORKLOAD_EVENT, params);
export const closeWorkloadEventsModal = () =>
  createAction(constants.CLOSE_WORKLOAD_EVENTS_MODAL);

export const createWorkloadPlannerEvent = (params) =>
  createAction(constants.CREATE_WORKLOAD_PLANNER_EVENT.TRIGGER, params);
export const createNotificationPreview = (params) =>
  createAction(constants.CREATE_NOTIFICATION_PREVIEW.TRIGGER, params);
export const sendWorkloadPlannerEvent = (params) =>
  createAction(constants.SEND_WORKLOAD_PLANNER_EVENT.TRIGGER, params);
export const fetchWorkloadPlannerEvents = (params) =>
  createAction(constants.FETCH_WORKLOAD_PLANNER_EVENTS.TRIGGER, params);
export const fetchWorkloadPlannerEvent = (params) =>
  createAction(constants.FETCH_WORKLOAD_PLANNER_EVENT.TRIGGER, params);
export const updateWorkloadPlannerEvent = (params) =>
  createAction(constants.UPDATE_WORKLOAD_PLANNER_EVENT.TRIGGER, params);
export const deleteWorkloadPlannerEvent = (params) =>
  createAction(constants.DELETE_WORKLOAD_PLANNER_EVENT.TRIGGER, params);

export const fetchWorkloadPlannerEventLastSent = (params) =>
  createAction(
    constants.FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT.TRIGGER,
    params
  );

export const setWorkloadEventRange = (params) =>
  createAction(constants.SET_WORKLOAD_EVENT_RANGE, params);

export const openWorkloadModal = (params) =>
  createAction(constants.OPEN_WORKLOAD_MODAL, params);
export const closeWorkloadModal = () =>
  createAction(constants.CLOSE_WORKLOAD_MODAL);

export const openWorkloadMemberRow = ({ accountId, projectId }) =>
  createAction(constants.OPEN_WORKLOAD_MEMBER_ROW, { accountId, projectId });
export const closeWorkloadMemberRow = ({ accountId, projectId }) =>
  createAction(constants.CLOSE_WORKLOAD_MEMBER_ROW, { accountId, projectId });
export const openAllWorkloadMemberRows = ({ accountIds }) =>
  createAction(constants.OPEN_ALL_WORKLOAD_MEMBER_ROWS, { accountIds });
export const closeAllWorkloadMemberRows = () =>
  createAction(constants.CLOSE_ALL_WORKLOAD_MEMBER_ROWS);

export const openWorkloadProjectRow = ({ projectId }) =>
  createAction(constants.OPEN_WORKLOAD_PROJECT_ROW, { projectId });
export const closeWorkloadProjectRow = ({ projectId }) =>
  createAction(constants.CLOSE_WORKLOAD_PROJECT_ROW, { projectId });
export const openAllWorkloadProjectRows = ({ projectIds }) =>
  createAction(constants.OPEN_ALL_WORKLOAD_PROJECT_ROWS, { projectIds });
export const closeAllWorkloadProjectRows = () =>
  createAction(constants.CLOSE_ALL_WORKLOAD_PROJECT_ROWS);

export const openPlannerBarModal = ({
  isNew,
  isRequest,
  accountId,
  projectId,
  barId,
  date,
  suggestedBar,
  workplanStateId, // for updating redux lists
  parentGroupId, // "
  groupAttribute, // "
  memberBudgetId,
  shouldPredictWithInitialValue = false
}) =>
  createAction(constants.OPEN_PLANNER_BAR_MODAL, {
    isNew,
    isRequest,
    accountId,
    projectId,
    barId,
    date,
    suggestedBar,
    workplanStateId,
    parentGroupId,
    groupAttribute,
    memberBudgetId,
    shouldPredictWithInitialValue
  });

export const closePlannerBarModal = () =>
  createAction(constants.CLOSE_PLANNER_BAR_MODAL);

export const openActivitySideMenu = () =>
  createAction(constants.OPEN_ACTIVITY_SIDE_MENU);

export const closeActivitySideMenu = () =>
  createAction(constants.CLOSE_ACTIVITY_SIDE_MENU);

export const plannerBarModalClosed = () =>
  createAction(constants.PLANNER_BAR_MODAL_CLOSED);

export const openWorkloadFilter = () =>
  createAction(constants.OPEN_WORKLOAD_FILTER);
export const closeWorkloadFilter = () =>
  createAction(constants.CLOSE_WORKLOAD_FILTER);

export const setWorkloadViewBy = (payload) =>
  createAction(constants.SET_WORKLOAD_VIEW_BY, payload);

export const setCondensedZoomLevel = (payload) =>
  createAction(constants.SET_CONDENSED_ZOOM_LEVEL, payload);

export const setWorkloadViewType = (payload) =>
  createAction(constants.SET_WORKLOAD_VIEW_TYPE, payload);

export const setTimelineViewBy = (payload) =>
  createAction(constants.SET_TIMELINE_VIEW_BY, payload);
export const setScheduleViewBy = (payload) =>
  createAction(constants.SET_SCHEDULE_VIEW_BY, payload);
export const setTimesheetViewBy = (payload) =>
  createAction(constants.SET_TIMESHEET_VIEW_BY, payload);
export const scrollTimesheet = (payload) =>
  createAction(constants.SCROLL_TIMESHEET, payload);

export const toggleTaskSidebarIsOpen = ({ projectId, isOpen }) =>
  createAction(constants.TOGGLE_TASK_SIDEBAR_IS_OPEN, { projectId, isOpen });
export const setZoom = (payload) => createAction(constants.SET_ZOOM, payload);

export const setProjectSummaryAccountIds = (payload) =>
  createAction(constants.SET_PROJECT_SUMMARY_ACCOUNT_IDS, payload);

export const setWorkloadSplitScreenActive = ({
  splitScreenAccountId,
  workloadSplitScreenType
}) =>
  createAction(constants.SET_WORKLOAD_SPLIT_SCREEN_ACTIVE, {
    splitScreenAccountId,
    workloadSplitScreenType
  });
export const setWorkloadSplitScreenInactive = (params) =>
  createAction(constants.SET_WORKLOAD_SPLIT_SCREEN_INACTIVE, params);

export const setPlannerSplitScreenActive = ({
  splitScreenProjectId,
  plannerSplitScreenType
}) =>
  createAction(constants.SET_PLANNER_SPLIT_SCREEN_ACTIVE, {
    splitScreenProjectId,
    plannerSplitScreenType
  });
export const setPlannerSplitScreenInactive = (params) =>
  createAction(constants.SET_PLANNER_SPLIT_SCREEN_INACTIVE, params);
export const setSplitScheduleBarId = (params) =>
  createAction(constants.SET_SPLIT_SCHEDULE_BAR_ID, params);
export const setBarContextMenuId = (params) =>
  createAction(constants.SET_BAR_CONTEXT_MENU_ID, params);

export const fetchSuggestions = (params) =>
  createAction(constants.FETCH_SUGGESTIONS.TRIGGER, params);
