import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { FullWidthButton } from './styles';
import DeleteIcon from 'icons/DeleteIcon';

const DeleteButton = ({ onClick }) => {
  return (
    <StyledDeleteButton onClick={onClick} data-testid="modal-delete-button">
      <DeleteIcon />
      Delete
    </StyledDeleteButton>
  );
};

export default DeleteButton;

/* ------------------------------------ - ----------------------------------- */

const StyledDeleteButton = styled(FullWidthButton)`
  margin-top: auto;
  color: ${theme.colors.colorCalendarGray};
  border: 1px solid ${theme.colors.colorCalendarGray};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 5px;
    path {
      fill: ${theme.colors.colorCalendarGray};
    }
  }
`;
