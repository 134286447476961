import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import TaskListIcon from 'icons/TaskListIcon';
import OptionsDropdown from 'components/options/OptionsDropdown';
import { getCurrentPositions } from 'BudgetModule/selectors/positions';
import { getWorkGroupsCount } from 'selectors';
import { getSkillsArray } from 'selectors/skills';
import { getOfficesArray } from 'SettingsModule/selectors/offices';
import { getRegionsArray } from 'SettingsModule/selectors/regions';
import { getDisciplineIdsArray } from 'SettingsModule/selectors/disciplines';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { FilterListType, FilterField } from 'FilterModule/constants';
import {
  getFilterFieldOptionLabel,
  getFilterFieldOptionsConfig,
  isExistenceCheckOptionHash
} from 'FilterModule/filterSchemas/utils';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import {
  useLoadOffices,
  useLoadRegions,
  useLoadDepartments,
  useLoadDisciplines,
  useLoadSkills
} from 'SettingsModule/hooks';
import { useLoadPositions } from 'BudgetModule/hooks';

interface FilterListTypeDropdownProps {
  headerText?: string;
  filterListTypeKey: FilterField;
}

/**
 * Handles updating currentFilter[filterListTypeKey] using FilterContext
 * Will only render if currentFilter[filterListTypeKey] is a FilterListType
 *
 * Define options in filter schema for the filterListTypeKey
 *
 * All the loading logic is for spec related to showing options with muted style when they are empty
 */
export const FilterListTypeDropdown = ({
  headerText = 'Group By',
  filterListTypeKey
}: FilterListTypeDropdownProps) => {
  const offices = useAppSelector(getOfficesArray);
  const disciplines = useAppSelector(getDisciplineIdsArray);
  const regions = useAppSelector(getRegionsArray);
  const skills = useAppSelector(getSkillsArray);
  const positions = useAppSelector(getCurrentPositions);
  const departmentCount = useAppSelector(getWorkGroupsCount);

  const { currentFilter, currentFilterSchema } = useFilterContext();

  const optionsConfig = getFilterFieldOptionsConfig({
    currentFilterSchema,
    field: filterListTypeKey
  });

  const currentValue = currentFilter[filterListTypeKey];

  /**
   * Used for sorting and styling list type options that are empty
   */
  const isFilterListTypesEmptyHash = useMemo(() => {
    return {
      [FilterListType.Members]: false, // There is always at least 1 member
      [FilterListType.MembersByPosition]: positions.length <= 1, // 'No Role' default position is a real position
      [FilterListType.Positions]: positions.length <= 1,
      [FilterListType.MembersByPortfolio]: false, // there will always be portfolio
      [FilterListType.MembersByDepartment]: departmentCount === 0,
      [FilterListType.MembersByRegion]: regions.length === 0,
      [FilterListType.MembersBySkill]: skills.length === 0,
      [FilterListType.MembersByOffice]: offices.length === 0,
      [FilterListType.MembersByDiscipline]: disciplines.length === 0,
      [FilterListType.Disciplines]: disciplines.length === 0,
      [FilterListType.Skills]: skills.length === 0,
      [FilterListType.Offices]: offices.length === 0,
      [FilterListType.Regions]: regions.length === 0,
      [FilterListType.Departments]: departmentCount === 0
    };
  }, [offices, positions, regions, skills, disciplines, departmentCount]);

  const formattedOptionsHash = useMemo(() => {
    const optionHash = optionsConfig?.optionHash;
    if (!optionHash || isExistenceCheckOptionHash(optionHash)) return undefined;

    return mapValues(optionHash, (option) => {
      const value = option.value;
      return isFilterListTypesEmptyHash[String(value)]
        ? {
            ...option,
            className: 'muted'
          }
        : option;
    });
  }, [isFilterListTypesEmptyHash, optionsConfig?.optionHash]);

  const formattedOptionsArray = useMemo(() => {
    const optionsArray = optionsConfig?.optionsArray;
    if (!optionsArray) return undefined;
    return sortBy(optionsArray, (option) => isFilterListTypesEmptyHash[option]);
  }, [optionsConfig?.optionsArray, isFilterListTypesEmptyHash]);

  const { loadOffices } = useLoadOffices({
    isOff: !(
      formattedOptionsHash?.[FilterListType.MembersByOffice] ||
      formattedOptionsHash?.[FilterListType.Offices]
    ),
    shouldAutoLoad: false
  });

  const { loadRegions } = useLoadRegions({
    isOff: !(
      formattedOptionsHash?.[FilterListType.MembersByRegion] ||
      formattedOptionsHash?.[FilterListType.Regions]
    ),
    shouldAutoLoad: false
  });

  const { loadDepartments } = useLoadDepartments({
    isOff: !(
      formattedOptionsHash?.[FilterListType.MembersByDepartment] ||
      formattedOptionsHash?.[FilterListType.Departments]
    ),
    shouldAutoLoad: false
  });

  const { loadSkills } = useLoadSkills({
    isOff: !(
      formattedOptionsHash?.[FilterListType.MembersBySkill] ||
      formattedOptionsHash?.[FilterListType.Skills]
    ),
    shouldAutoLoad: false
  });

  const { loadDisciplines } = useLoadDisciplines({
    isOff: !(
      formattedOptionsHash?.[FilterListType.MembersByDiscipline] ||
      formattedOptionsHash?.[FilterListType.Disciplines]
    ),
    shouldAutoLoad: false
  });

  const { loadPositions } = useLoadPositions({
    isOff: !(
      formattedOptionsHash?.[FilterListType.MembersByPosition] ||
      formattedOptionsHash?.[FilterListType.Positions]
    ),
    shouldAutoLoad: false
  });

  const filterListTypeToLoadCallback = useMemo(() => {
    return {
      [FilterListType.MembersByDepartment]: loadDepartments,
      [FilterListType.Departments]: loadDepartments,
      [FilterListType.MembersByRegion]: loadRegions,
      [FilterListType.Regions]: loadRegions,
      [FilterListType.MembersBySkill]: loadSkills,
      [FilterListType.Skills]: loadSkills,
      [FilterListType.MembersByOffice]: loadOffices,
      [FilterListType.Offices]: loadOffices,
      [FilterListType.MembersByDiscipline]: loadDisciplines,
      [FilterListType.Disciplines]: loadDisciplines,
      [FilterListType.MembersByPosition]: loadPositions,
      [FilterListType.Positions]: loadPositions
    };
  }, [
    loadDepartments,
    loadDisciplines,
    loadOffices,
    loadPositions,
    loadRegions,
    loadSkills
  ]);

  /**
   * Loads relevant data for knowing counts (to show muted styling of dropdown options for empty lists)
   */
  const loadData = useCallback(() => {
    if (formattedOptionsArray) {
      formattedOptionsArray.forEach((filterListType) => {
        filterListTypeToLoadCallback[filterListType]?.();
      });
    }
  }, [filterListTypeToLoadCallback, formattedOptionsArray]);

  if (!formattedOptionsHash || !formattedOptionsArray) return null;

  const handleSelect = (filterListType: FilterListType) => {
    currentFilter.update({ [filterListTypeKey]: filterListType });
    // currentFilter.save(); // spec: save on dropdown selection
  };

  const renderToggle = () => (
    <StyledToggle onMouseOver={loadData}>
      <TaskListIcon
        width={14}
        height={10}
        color={theme.colors.colorCalendarBlue}
        className={undefined}
        style={undefined}
      />
      <StyledLabel>
        {getFilterFieldOptionLabel({
          optionsConfig,
          field: filterListTypeKey,
          currentValue
        })}
      </StyledLabel>
    </StyledToggle>
  );

  return (
    <StyleWrapper>
      <OptionsDropdown
        onSelect={handleSelect}
        options={formattedOptionsArray}
        optionsHash={formattedOptionsHash}
        currentValue={currentValue}
        renderToggle={renderToggle}
        headerText={headerText}
      />
    </StyleWrapper>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledLabel = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  margin-left: 4px;
  font-weight: 600;
`;

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(80%);
    }
    `}
`;

const StyleWrapper = styled.div`
  .options-dropdown-option.muted:not(.active) {
    color: ${theme.colors.colorMediumGray5};
    &:hover {
      background: none;
    }
  }
`;
