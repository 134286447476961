import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ComponentProps } from 'react';

interface SkeletonLoaderProps extends ComponentProps<typeof Skeleton> {
  isLoading: boolean;
  children: JSX.Element;
}

export const SkeletonLoader = ({
  isLoading,
  children,
  ...skeletonProps
}: SkeletonLoaderProps) => {
  return isLoading ? <Skeleton {...skeletonProps} /> : children;
};
