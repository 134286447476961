import React from 'react';
import { connect } from 'react-redux';
import {
  getPlannerProjects,
  makeGetPlannerPhases,
  getPlannerPhasesAndActivities,
  getPhaseSearchOffset,
  getIsLoadingPhases,
  getTotalProjectCount,
  getIsMilestoneModalOpen,
  getAuth,
  getMe,
  getFlatPhasesHash,
  getOrderedAllActivities,
  getOOOProject
} from 'selectors';
import {
  addMemberToProject,
  fetchPhasesByProjectIds,
  fetchAllProjects,
  fetchPhases,
  openMilestoneModal,
  fetchProjectById,
  setSelectedProject,
  updatePhase
} from 'actionCreators';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import Row, { RowContext } from './Row';
import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';
import ActivityBulkAssignModal from 'BudgetModule/components/BudgetModal/ActivityBulkAssignModal';
import PhaseActivitiesDropdown from 'BudgetModule/components/BudgetModal/PhaseActivitiesDropdown';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import noop from 'lodash/noop';
import { StyledBackKaratContainer } from './styles';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import TaskGroupCollapseIcon from 'icons/TaskGroupCollapseIcon';
import ContentLoader from 'react-content-loader';
import cn from 'classnames';

const byId = (item) =>
  item && item.is_activity ? `${item.id}--${item.phase_id}` : item.id;

const loadingState = (
  <div style={{ width: '100%' }}>
    <ContentLoader
      height="340"
      primaryColor="#f3f3f3"
      secondaryColor="#eee"
      style={{ width: '100%', marginTop: '-20px' }}
      viewBox="0 0 400 340"
    >
      <rect x="0" y="5" rx="2" ry="2" width="100%" height="60" />
      <rect x="0" y="70" rx="2" ry="2" width="100%" height="60" />
      <rect x="0" y="135" rx="2" ry="2" width="100%" height="60" />
      <rect x="0" y="200" rx="2" ry="2" width="100%" height="60" />
    </ContentLoader>
  </div>
);

const copy = {
  headerInitial: 'Select Project',
  searchPlaceholder: 'Type project or select below',
  emptyState: loadingState
};

const Footer = (
  <LockWithTooltip
    text="Project Phases"
    tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Phases <br /> because they affect Project Budgets."
  />
);

const phaseCopy = {
  headerInitial: 'Select Phase',
  searchPlaceholder: 'Type phase or select below',
  footerInitial: Footer
};
const itemHeight = 81;
const listItemStyle = `
  border-bottom: none;
  position: relative;
  cursor: none;
  padding: 0;
  &:hover {
    background:none;
  }`;

class ProjectsThenPhasesDropdown extends React.Component {
  state = {
    joinModalOpen: false,
    selectedProject: null,
    selectedItem: null,
    activitiesOpen: false
  };

  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate(prevProps) {
    rebuildTooltip();
    const milestoneModalClosedAndNoPhasesAdded =
      prevProps.isMilestoneModalOpen &&
      !this.props.isMilestoneModalOpen &&
      !this.props.phases.length;
    if (milestoneModalClosedAndNoPhasesAdded) {
      this.props.clearSelectedProjectId && this.props.clearSelectedProjectId();
    }
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  itemFilter = (item, searchWords) => {
    if (this.showPhases()) {
      return filterItemWithWhiteSpace({
        searchWords,
        item,
        filterKeysArray: ['title']
      });
    }

    return filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: [
        'title',
        'description',
        'project_title',
        'project_description',
        'project_number',
        'client',
        'phaseTitles'
      ]
    });
  };

  renderItem = (props) => {
    const { isWide, handleClose, showBudgetInformation, popoverClassName } =
      this.props;
    return (
      <Row
        {...props}
        accountId={this.props.accountId}
        itemHeight={itemHeight}
        getItemHeight={this.getItemHeight}
        showSelectPhaseText
        shouldShowBudgetInfo={showBudgetInformation}
        isWide={isWide}
        handleClose={handleClose}
        popoverClassName={popoverClassName}
      />
    );
  };

  handleSelect = (e, { item, selectCallback, clearSearch }) => {
    const { handleSelect, accountId, fetchPhasesByProjectIds } = this.props;
    e.preventDefault();
    e.stopPropagation();
    const accountIds = keyBy(
      item.member_account_ids || item.project.member_account_ids || []
    );
    clearSearch();

    if (!item.is_phase && !item.is_activity) {
      const defaultOrMainPhase =
        this.props.phaseHash[item.defaultOrMainPhaseId];
      const hasActivity = !!defaultOrMainPhase?.activity_order?.length;

      if (defaultOrMainPhase && !hasActivity) {
        if (
          !(item.is_phase || item.is_activity) &&
          (accountIds[accountId] || !accountId)
        ) {
          selectCallback && selectCallback();
        } else {
          this.setJoinProject(item, item);
          return;
        }
      } else {
        fetchPhasesByProjectIds({
          projectIds: [item.id],
          budgetAccountId: accountId
        });
      }
      handleSelect(item);
      return;
    }
    if (item.is_phase && item.hasActivities) {
      return;
    }
    // used in places where no account id exists yet - don't assert membership on non-entity
    if (accountIds[accountId] || !accountId) {
      handleSelect(item);
      if (selectCallback) {
        selectCallback();
      }
    } else {
      const project = !(item.is_phase || item.is_activity)
        ? item
        : item.project;
      this.setJoinProject(project, item);
    }
  };

  setJoinProject = (project, item) => {
    this.setState({
      selectedProject: project,
      selectedItem: item,
      joinModalOpen: true
    });
  };

  clearJoinProject = () =>
    this.setState({ selectedProject: null, joinModalOpen: false });

  joinProject = () => {
    const { addMemberToProject, accountId, handleSelect, handleClose } =
      this.props;
    const { selectedProject, selectedItem } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: () => handleSelect(selectedItem),
        selector: noop
      }
    ];
    addMemberToProject(
      selectedProject.id,
      accountId,
      basicMemberRole,
      selectedProject.board_id,
      onSuccess
    );
    handleClose();
    this.clearJoinProject();
  };

  loadMoreItems = ({ search }) => {
    const {
      fetchPhasesByProjectIds,
      offset,
      isLoading,
      fetchAllProjects,
      accountId
    } = this.props;
    if (!isLoading) {
      fetchAllProjects({
        searchText: search,
        offset,
        limit: 60
      });
      fetchPhasesByProjectIds({
        search,
        all: true,
        offset,
        limit: 60,
        budgetAccountId: accountId
      });
    }
  };

  loadInitialItems = ({ search }) => {
    const { fetchPhasesByProjectIds, fetchAllProjects, OOOProject, accountId } =
      this.props;
    fetchAllProjects({
      searchText: search,
      offset: 0,
      limit: 60
    });
    if (OOOProject?.id) {
      fetchPhasesByProjectIds({ projectIds: [OOOProject.id] });
    }
    fetchPhasesByProjectIds({
      search,
      all: true,
      offset: 0,
      limit: 60,
      budgetAccountId: accountId
    });
  };

  hasNextPage = () => {
    const { totalCount, offset } = this.props;
    return offset < totalCount;
  };

  handleClose = (e, forceClose) => {
    const { joinModalOpen, activitiesOpen } = this.state;
    const { handleClose, preventCloseOnSelect, isMilestoneModalOpen } =
      this.props;
    if (
      (!joinModalOpen &&
        !activitiesOpen &&
        !preventCloseOnSelect &&
        !isMilestoneModalOpen) ||
      forceClose
    ) {
      handleClose(e);
    }
  };

  showPhases = () => !!this.props.projectId;

  getItems = () => {
    const { hidePTO, OOOProject } = this.props;
    const projects = hidePTO
      ? this.props.projects?.filter((project) => OOOProject?.id !== project.id)
      : this.props.projects;
    return this.showPhases()
      ? this.props.showActivities
        ? this.props.phasesAndActivities
        : this.props.phases
      : projects;
  };

  getCopy = () => (this.showPhases() ? phaseCopy : copy);

  renderHeader = ({ clearSearch }) => {
    const { projectStepEnabled, isWide } = this.props;
    const isShowPhases = this.showPhases();
    const copy = this.getCopy();

    if (isWide && !isShowPhases) {
      return <></>;
    } else if (isShowPhases) {
      return (
        <>
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={
              projectStepEnabled
                ? () => {
                    clearSearch();
                    this.props.clearSelectedProjectId &&
                      this.props.clearSelectedProjectId();
                  }
                : noop
            }
          >
            <StyledBackKaratContainer hide={!projectStepEnabled}>
              <TaskGroupCollapseIcon height="36" width="36" />
            </StyledBackKaratContainer>
            <span style={{ position: 'relative' }}>{copy.headerInitial}</span>
          </div>
        </>
      );
    } else {
      return copy.headerInitial;
    }
  };

  onAddPhasesClick = (projectId) => {
    const {
      fetchPhases,
      fetchPhasesByProjectIds,
      openMilestoneModal,
      setSelectedProject,
      fetchProjectById,
      handleSelect,
      accountId
    } = this.props;
    handleSelect({ id: projectId });
    fetchProjectById(projectId);
    setSelectedProject({ projectId });
    openMilestoneModal();
    fetchPhases({ projectId });
    fetchPhasesByProjectIds({
      projectIds: [projectId],
      budgetAccountId: accountId
    });
  };

  onAddActivitiesClick = ({ projectId, phaseId }) => {
    this.setState({ activitiesOpen: true, selectedPhaseId: phaseId });
  };

  setPopoverTarget = (ref) => (this.popoverTarget = ref);
  shouldShowBudgetInfo = () => this.props.showBudgetInformation;

  rowContextValue = {
    onAddPhasesClick: this.onAddPhasesClick,
    onAddActivitiesClick: this.onAddActivitiesClick,
    setPopoverTarget: this.setPopoverTarget,
    shouldShowBudgetInfo: this.shouldShowBudgetInfo
  };

  closeAddActivities = () => this.setState({ activitiesOpen: false });

  bulkAssignPreviousTime = ({ activity }) => {
    const { updatePhase } = this.props;
    const phase = this.getSelectedPhase();
    updatePhase({
      activityOrder: [activity.id],
      id: phase.id,
      projectId: phase.project_id
    });
    this.closeAddActivities();
  };

  handleSelectActivity = ({ activity }) => {
    const { updatePhase } = this.props;
    const phase = this.getSelectedPhase();
    updatePhase({
      activityOrder: uniq([activity.id, ...phase.activity_order])
    });
    this.closeAddActivities();
  };

  getSelectedPhase = () => {
    const { selectedPhaseId } = this.state;
    const { phaseHash } = this.props;
    const phase = phaseHash[selectedPhaseId] || {};
    return phase;
  };

  renderActivitiesDropdown = () => {
    const { allActivities } = this.props;
    const phase = this.getSelectedPhase();
    const phaseHasTaskCategories = !!phase?.activity_order?.length;
    return phaseHasTaskCategories ? (
      <PhaseActivitiesDropdown
        target={this.popoverTarget}
        handleSelect={this.handleSelectActivity}
        handleClose={this.closeAddActivities}
        renderHeader={() => 'Add Work Categories'}
        activities={allActivities}
        className={'workload-dropdown'}
        phase={phase}
      />
    ) : (
      <ActivityBulkAssignModal
        isOpen
        closeActivityDropdown={this.closeAddActivities}
        bulkAssignPreviousTime={this.bulkAssignPreviousTime}
      />
    );
  };

  onPhasesFooterClick = () => {
    const { projectId } = this.props;
    if (projectId) {
      this.onAddPhasesClick(this.props.projectId);
    }
  };

  getItemHeight = (item, shouldShowBudgetInfo) => {
    const { customGetItemHeight } = this.props;

    if (customGetItemHeight) {
      return customGetItemHeight(item, shouldShowBudgetInfo);
    }

    const isActivity = item.is_activity;
    const isPhaseWithActivities =
      item.is_phase && item.hasActivities && this.props.showActivities;
    if (isActivity) {
      // When the row is displaying an activity that contains a date range &
      // has additional sub text, row needs to be taller
      const hasDate =
        item.activityPhase?.end_date && item.activityPhase?.start_date;
      return hasDate &&
        shouldShowBudgetInfo &&
        !item.budgetTotalsAccountIsMember
        ? 52
        : hasDate
        ? 60
        : 43;
    } else if (isPhaseWithActivities) {
      return 58;
    } else if (item.is_phase) {
      return 66;
    } else {
      return 81;
    }
  };

  render() {
    const {
      target,
      totalCount,
      text,
      me,
      accountId,
      isWide,
      popoverClassName
    } = this.props;
    const { joinModalOpen, selectedProject, activitiesOpen } = this.state;
    const showPhases = this.showPhases();
    return (
      <>
        {
          <RowContext.Provider value={this.rowContextValue}>
            {!joinModalOpen && (
              <MultiStepFlyout
                copy={this.getCopy()}
                items={this.getItems()}
                getItemId={byId}
                idKey="id"
                renderItem={this.renderItem}
                renderHeader={this.renderHeader}
                handleSelect={this.handleSelect}
                selectCallback={this.handleClose}
                handleSubmit={this.handleSubmit}
                itemFilter={this.itemFilter}
                getItemHeight={this.getItemHeight}
                isWhite
                searchEnabled={
                  !showPhases || (showPhases && this.getItems().length > 5)
                }
                editDisabled
                top={0}
                target={target}
                handleClose={this.handleClose}
                loadMoreItems={this.loadMoreItems}
                loadInitialItems={this.loadInitialItems}
                hasNextPage={this.hasNextPage}
                totalCount={totalCount}
                hideFooter={!showPhases}
                itemHeight={itemHeight}
                listItemContainerStyle={listItemStyle}
                onFooterClick={this.onPhasesFooterClick}
                emptyContainerClassName="projects-then-phases-dropdown-empty-container"
                popoverClassName={cn('projects-and-phases-dropdown', {
                  'checkin-project-dropdown': isWide,
                  [popoverClassName]: popoverClassName
                })}
                listWidth="324"
                listHeight={280}
              />
            )}
          </RowContext.Provider>
        }

        <JoinProjectModal
          open={joinModalOpen}
          toggle={this.clearJoinProject}
          selectedProject={selectedProject}
          onConfirm={this.joinProject}
          text={text}
          accountId={accountId}
          currentUserId={me.id}
        />
        {activitiesOpen && this.renderActivitiesDropdown()}
      </>
    );
  }
}

const getPlannerPhases = makeGetPlannerPhases();
const mapStateToProps = (state, ownProps) => ({
  auth: getAuth(state),
  me: getMe(state),
  projects: getPlannerProjects(state, ownProps),
  phaseHash: getFlatPhasesHash(state),
  phases: getPlannerPhases(state, ownProps),
  phasesAndActivities: getPlannerPhasesAndActivities(state, ownProps),
  allActivities: getOrderedAllActivities(state),
  offset: getPhaseSearchOffset(state),
  isLoading: getIsLoadingPhases(state),
  totalCount: getTotalProjectCount(state),
  isMilestoneModalOpen: getIsMilestoneModalOpen(state),
  OOOProject: getOOOProject(state)
});

const mapDispatchToProps = {
  fetchPhasesByProjectIds,
  addMemberToProject,
  fetchAllProjects,
  fetchPhases,
  openMilestoneModal,
  fetchProjectById,
  setSelectedProject,
  updatePhase
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsThenPhasesDropdown);
