import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getMyUserId,
  getSelectedTeamId,
  getGroups,
  getGroupsHash
} from 'selectors';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import {
  getExistingProjectsHashByNumber,
  getExistingProjectsHashByTitle,
  getExistingPortfoliosHashByName,
  getExistingProjectIdToPortfolio
} from './utils';

export const useCsvImportData = () => {
  /* ------------------------------- portfolios ------------------------------- */

  const existingPortfolios = useSelector(getGroups);
  const existingPortfolioNames = useMemo(
    () => existingPortfolios.map((portfolio) => portfolio.name),
    [existingPortfolios]
  );
  const portfolioHash = useSelector(getGroupsHash);

  /**
   * eg { 'Portfolio name': { 52: { id: 52, name: 'Portfolio name', ... } } }
   */
  const existingPortfoliosHashByName = useMemo(
    () => getExistingPortfoliosHashByName(existingPortfolios),
    [existingPortfolios]
  );

  /* -------------------------------- projects -------------------------------- */
  const existingProjects = useSelector((state) => state.csvImport.projects);

  /**
   * eg { 'Project name': { 52: { id: 52, title: 'Project name', ... } } }
   */
  const existingProjectsHashByTitle = useMemo(
    () => getExistingProjectsHashByTitle(existingProjects),
    [existingProjects]
  );

  /**
   * eg. { '18825': { 52: { id: 52, project_number: '18825', ... } } }
   */
  const existingProjectsHashByNumber = useMemo(
    () => getExistingProjectsHashByNumber(existingProjects),
    [existingProjects]
  );

  const existingProjectIdToPortfolio = useMemo(
    () => getExistingProjectIdToPortfolio(existingProjects, portfolioHash),
    [existingProjects, portfolioHash]
  );

  const existingProjectsById = useMemo(
    () => keyBy(existingProjects, 'id'),
    [existingProjects]
  );

  const existingProjectBudgetStatusOptions = useSelector(
    (state) => state.csvImport.projectBudgetStatusOptions
  );

  const existingProjectBudgetStatusOptionsByBudgetStatus = useMemo(
    () => keyBy(existingProjectBudgetStatusOptions),
    [existingProjectBudgetStatusOptions]
  );

  const existingProjectPriorities = useSelector(
    (state) => state.csvImport.projectPriorities
  );
  const existingProjectPrioritiesByTitle = useMemo(
    () => keyBy(existingProjectPriorities, 'title'),
    [existingProjectPriorities]
  );

  const existingProjectClients = useSelector(
    (state) => state.csvImport.projectClients
  );

  /* --------------------------------- phases --------------------------------- */
  const existingPhases = useSelector((state) => state.csvImport.phases);
  const existingPhasesGroupedByName = useMemo(
    () => groupBy(existingPhases, 'name'),
    [existingPhases]
  );
  const existingPhasesGroupedByProjectId = useMemo(
    () => groupBy(existingPhases, 'project_id'),
    [existingPhases]
  );

  const existingPhaseBudgetStatusOptions = useSelector(
    (state) => state.csvImport.phaseBudgetStatusOptions
  );

  const existingPhaseBudgetStatusOptionsByBudgetStatus = useMemo(
    () => keyBy(existingPhaseBudgetStatusOptions),
    [existingPhaseBudgetStatusOptions]
  );

  /* ------------------------------- activities ------------------------------- */
  const existingActivities = useSelector((state) => state.csvImport.activities);
  const existingActivitiesByTitle = useMemo(
    () => keyBy(existingActivities, 'title'),
    [existingActivities]
  );
  const existingActivitiesById = useMemo(
    () => keyBy(existingActivities, 'id'),
    [existingActivities]
  );

  /* --------------------------------- members -------------------------------- */
  const existingTeamMembers = useSelector(
    (state) => state.csvImport.teamMembers
  );
  const existingEmails = useMemo(
    () => new Set(existingTeamMembers.map((account) => account?.email)),
    [existingTeamMembers]
  );

  /* ---------------------------------- PTO ----------------------------------- */
  const unformattedExistingPtoPolicies = useSelector(
    (state) => state.csvImport.ptoPolicies
  );

  const existingPtoPolicies = useMemo(
    () =>
      unformattedExistingPtoPolicies.map((ptoPolicy) => ({
        ...ptoPolicy,
        hours: parseFloat(ptoPolicy.hours).toString()
      })),
    [unformattedExistingPtoPolicies]
  );

  const existingPtoPoliciesGroupedByHours = useMemo(
    () => groupBy(existingPtoPolicies, 'hours'),
    [existingPtoPolicies]
  );

  const existingPtoPoliciesByDescription = useMemo(
    () => keyBy(existingPtoPolicies, 'name'),
    [existingPtoPolicies]
  );

  /* --------------------------------- Offices -------------------------------- */
  const existingOffices = useSelector((state) => state.csvImport.offices);
  const existingOfficesByName = useMemo(
    () => keyBy(existingOffices, 'name'),
    [existingOffices]
  );

  /* --------------------------------- Regions -------------------------------- */
  const existingRegions = useSelector((state) => state.csvImport.regions);
  const existingRegionsByName = useMemo(
    () => keyBy(existingRegions, 'name'),
    [existingRegions]
  );

  /* --------------------------------- Positions ------------------------------ */
  const existingRoles = useSelector((state) => state.csvImport.positions);
  const existingRolesByName = useMemo(
    () => keyBy(existingRoles, 'name'),
    [existingRoles]
  );

  /* --------------------------------- Disciplines ---------------------------- */
  const existingDisciplines = useSelector(
    (state) => state.csvImport.disciplines
  );
  const existingDisciplinesByName = useMemo(
    () => keyBy(existingDisciplines, 'name'),
    [existingDisciplines]
  );

  /* --------------------------------- Departments ---------------------------- */
  const existingDepartments = useSelector(
    (state) => state.csvImport.departments
  );
  const existingDepartmentsByName = useMemo(
    () => keyBy(existingDepartments, 'name'),
    [existingDepartments]
  );

  /* ------------------------------------ Rates ------------------------------- */

  const unformattedExistingTeamRates = useSelector(
    (state) => state.csvImport.teamRates
  );

  const existingTeamRates = useMemo(
    () =>
      unformattedExistingTeamRates.map((teamRate) => ({
        ...teamRate,
        rate: parseFloat(teamRate.rate).toString()
      })),
    [unformattedExistingTeamRates]
  );

  const existingRatesByRateDescription = useMemo(
    () => keyBy(existingTeamRates, 'description'),
    [existingTeamRates]
  );

  const existingRatesGroupedByRateAmount = useMemo(
    () => groupBy(existingTeamRates, 'rate'),
    [existingTeamRates]
  );

  /* ---------------------------- Task Priorities ----------------------------- */

  const existingTaskPriorities = useSelector(
    (state) => state.csvImport.taskPriorities
  );

  const existingTaskPrioritiesByTitle = useMemo(
    () => keyBy(existingTaskPriorities, 'title'),
    [existingTaskPriorities]
  );

  /* ---------------------------------- misc ---------------------------------- */
  const teamId = useSelector(getSelectedTeamId);
  const myId = useSelector(getMyUserId);

  return {
    existingPortfolios,
    existingPortfolioNames,
    existingPortfoliosHashByName,
    existingProjects,
    existingProjectsById,
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingProjectIdToPortfolio,
    existingProjectPrioritiesByTitle,
    existingProjectClients,
    existingProjectBudgetStatusOptions,
    existingProjectBudgetStatusOptionsByBudgetStatus,
    existingPhases,
    existingPhasesGroupedByName,
    existingPhaseBudgetStatusOptions,
    existingPhaseBudgetStatusOptionsByBudgetStatus,
    existingPhasesGroupedByProjectId,
    existingActivities,
    existingActivitiesById,
    existingActivitiesByTitle,
    existingTeamMembers,
    existingEmails,
    existingPtoPoliciesGroupedByHours,
    existingPtoPoliciesByDescription,
    existingOfficesByName,
    existingRegionsByName,
    existingRolesByName,
    existingDisciplinesByName,
    existingDepartmentsByName,
    existingTeamRates,
    existingRatesByRateDescription,
    existingRatesGroupedByRateAmount,
    existingTaskPrioritiesByTitle,
    teamId,
    myId
  };
};
