import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCurrentPage,
  getCurrentFilter,
  getPastScheduledTasks,
  getPastDueTasks
} from 'selectors';

import TasksGroupHeader from 'views/Home/TaskList/TasksGroupHeader';

import theme from 'theme';

const emptyObj = {};

class CompleteCellContent extends PureComponent {
  render() {
    const {
      isOnHomeView,
      group,
      groupIndex,
      setIsOpen,
      draggableProvided = emptyObj,
      allCollapsed,
      isOpen,
      numTasks
    } = this.props;
    return (
      <div
        ref={draggableProvided?.innerRef}
        {...draggableProvided.draggableProps}
      >
        <TasksGroupHeader
          index={groupIndex}
          projectId={group.project_id}
          group={group}
          id={group.id}
          originType={'taskGroup'} // necessary to determine color picker open/close state
          pickerLocation={'flyout-menu'}
          isOnHomeView={isOnHomeView}
          isOnProjectView={true}
          taskIds={group.task_order}
          provided={draggableProvided}
          // snapshot={snapshot}
          collapsedForDrag={false}
          isOnlyTaskGroup={this.props.isOnlyTaskGroup}
          setIsOpen={setIsOpen}
          key={group?.id}
          hideSort
          isVirtualTaskList
          tableAllCollapsed={allCollapsed}
          isOpen={isOpen}
          numTasks={numTasks}
          threeDotHoverBackgroundColor={theme.colors.colorPureWhite}
        />
      </div>
    );
  }
}

const mapStateToPRops = (state) => ({
  currentPage: getCurrentPage(state),
  currentFilter: getCurrentFilter(state),
  pastDueTasks: getPastDueTasks(state),
  pastScheduledTasks: getPastScheduledTasks(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToPRops, mapDispatchToProps)(CompleteCellContent)
);
