export const TOTALS_FORMAT = {
  PERCENTAGE: 'PERCENTAGE',
  DOLLARS: 'DOLLARS'
};

export const VIEW_BY = {
  PROJECT: 'projects',
  MEMBER: 'members',
  CLIENT: 'clients',
  ORGANIZATION: 'organization'
};

export const SORT_BY = {
  TITLE: 'TITLE',
  PROJECT_NUMBER_ASC: 'PROJECT_NUMBER_ASC',
  PROJECT_NUMBER_DESC: 'PROJECT_NUMBER_DESC',
  PROFIT_DOLLAR_ASC: 'PROFIT_DOLLAR_ASC',
  PROFIT_DOLLAR_DESC: 'PROFIT_DOLLAR_DESC',
  PROFIT_PERCENT_ASC: 'PROFIT_PERCENT_ASC',
  PROFIT_PERCENT_DESC: 'PROFIT_PERCENT_DESC'
};

export const GROUP_BY = {
  PHASE: 'PHASE',
  PROJECT: 'PROJECT'
};

// Number of divisions in bar chart
export const NUM_DIVISIONS = 6;

export const LIMIT = 20;
