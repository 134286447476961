import {
  editWorkPlans,
  editPersonalWorkPlans
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/workplan';
import useCan from 'appUtils/hooks/useCan';
import { getMyUserId } from 'UsersModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { WorkPlanPermissionGetter } from '../permissionProviders/workplan';
import { useCallback, useMemo } from 'react';
import { deleteWorkloadPlanner } from 'actionCreators';

// To add a new permission,
// add new action keys to the enum
// retrieve the new permission from the useCan hook
// add another permission getter function
// return the new permission getter
enum WorkPlanActionKeys {
  editWorkPlan,
  deleteWorkPlan
}

type WorkPlanPermissions = Record<WorkPlanActionKeys, boolean>;

export const useWorkplanPermissionState = () => {
  const myId = useAppSelector(getMyUserId);

  const getIsPersonalWorkPlan = useCallback(
    (accountId) => accountId === myId,
    [myId]
  );

  const canEditWorkplan = useCan(editWorkPlans);
  const canEditPersonalWorkplan = useCan(editPersonalWorkPlans);

  const canDeleteWorkPlan = useCan(deleteWorkloadPlanner);
  const canDeletePersonalWorkPlan = useCan(deleteWorkloadPlanner);

  const permissionsMap: {
    personal: WorkPlanPermissions;
    global: WorkPlanPermissions;
  } = useMemo(
    () => ({
      personal: {
        [WorkPlanActionKeys.editWorkPlan]: canEditPersonalWorkplan,
        [WorkPlanActionKeys.deleteWorkPlan]: canDeleteWorkPlan
      },
      global: {
        [WorkPlanActionKeys.editWorkPlan]: canEditWorkplan,
        [WorkPlanActionKeys.deleteWorkPlan]: canDeletePersonalWorkPlan
      }
    }),
    [
      canDeletePersonalWorkPlan,
      canDeleteWorkPlan,
      canEditPersonalWorkplan,
      canEditWorkplan
    ]
  );

  const makeGetCanPermission = useCallback(
    ({
      accountId,
      permissionKey
    }: {
      accountId?: Nullable<number>;
      permissionKey: WorkPlanActionKeys;
    }) =>
      // accountId can be null for unassigned role's work plan
      getIsPersonalWorkPlan(accountId)
        ? permissionsMap.personal[permissionKey]
        : permissionsMap.global[permissionKey],
    [getIsPersonalWorkPlan, permissionsMap.global, permissionsMap.personal]
  );

  const getCanEditWorkPlan: WorkPlanPermissionGetter = useCallback(
    ({ accountId } = {}) =>
      makeGetCanPermission({
        accountId,
        permissionKey: WorkPlanActionKeys.editWorkPlan
      }),
    [makeGetCanPermission]
  );

  const getCanDeleteWorkPlan: WorkPlanPermissionGetter = useCallback(
    ({ accountId } = {}) =>
      makeGetCanPermission({
        accountId,
        permissionKey: WorkPlanActionKeys.deleteWorkPlan
      }),
    [makeGetCanPermission]
  );

  return {
    getCanEditWorkPlan,
    getCanDeleteWorkPlan,
    // currently, below getters are referencing the same permission getter
    // use new permission getters if you need to add a new permission
    getCanCreateWorkPlan: getCanEditWorkPlan,
    getCanDragWorkPlan: getCanEditWorkPlan,
    getCanResizeWorkPlan: getCanEditWorkPlan,
    getCanCopyWorkPlan: getCanEditWorkPlan,
    getCanSplitWorkPlan: getCanEditWorkPlan,
    getCanImportWorkPlan: getCanEditWorkPlan,
    getCanAddWorkPlanComment: getCanEditWorkPlan,
    getCanEditWorkPlanTask: getCanEditWorkPlan,
    getCanRemoveWorkPlanTask: getCanEditWorkPlan,
    getCanEditWorkPlanScope: getCanEditWorkPlan,
    getCanRemoveWorkPlanScope: getCanEditWorkPlan
  };
};
