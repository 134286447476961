import reject from 'lodash/reject';
import * as constants from 'appConstants';

export const initialState = {
  isMilestoneModalOpen: false,
  editingMilestoneId: null,
  deletingMilestoneId: null,
  editingProjectId: null,
  lastHoveredProjectId: null,
  /**
   * Use Case:
   * - Opening Team Builder Modal on Project Info page of Project A, change to Project B on Team Builder, open Milestone Modal
   * - If you use setSelectedProject(ProjectB), then exiting Team Builder modal will change Project Info page to Project B, while the URL will be of Project A
   *  => We don't want to change `selectedProject` (because what's showing on project info page relies on it) nor the url
   *  => Approach: Call openMilestoneModal({project})
   *  => The goal is to Temporarily set the project that we will be modifying on Milestone Modal using `milestoneModalProject`
   *  => Closing the milestone modal will reset milestoneModalProject to null, default back to `selectedProject` (See MilestoneModal.js mapStateToProps)
   */
  milestoneModalProject: null
};

const milestones = (state = initialState, action) => {
  let newMilestones;

  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.ADD_NEW_MILESTONE_TEMPLATE: {
      const { addedFromHeader, previousMilestoneIdx } = action.payload;

      // id of 0 will mean new milestone
      const newMilestoneTemplate = {
        id: addedFromHeader ? 'top' : 0,
        description: '',
        isCompleted: false,
        projectId: state.editingProjectId,
        startDate: null,
        endDate: null
      };

      newMilestones = [...state.milestonesArray];
      newMilestones.splice(previousMilestoneIdx + 1, 0, newMilestoneTemplate);

      return {
        ...state,
        milestonesArray: newMilestones
      };
    }

    case constants.REMOVE_NEW_MILESTONE:
      return {
        ...state,
        milestonesArray: reject(
          state.milestonesArray,
          (milestone) => milestone.id === 0 || milestone.id === 'top'
        )
      };

    case constants.OPEN_MILESTONE_MODAL:
      return {
        ...state,
        isMilestoneModalOpen: true,
        milestoneModalProject: action.payload?.project
      };
    case constants.CLOSE_MILESTONE_MODAL:
      return {
        ...state,
        isMilestoneModalOpen: false,
        milestoneModalProject: null
      };

    case constants.SET_EDIT_MILESTONE_ID:
      return {
        ...state,
        editingMilestoneId: action.payload
      };
    case constants.SET_DELETE_MILESTONE_ID: {
      return {
        ...state,
        deletingMilestoneId: action.payload
      };
    }
    default:
      return state;
  }
};

export default milestones;
