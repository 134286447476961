import { useEffect, useState } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle
} from 'reactstrap';
import QBDownArrow from 'icons/QBDownArrow';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';

export const Arrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  margin-left: 5px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;

const noop = () => {};

export const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 10px 0px;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  min-width: unset;
`;

export const StyledDropdown = styled(Dropdown)`
  ${(props) =>
    props.$isDisabled &&
    `
    cursor: not-allowed;
    filter: grayscale(1);
    .btn-secondary {
      pointer-events: none;
    }
  `}
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  font-size: 13px;
  padding: 0;
  &.btn-secondary,
  &.btn-secondary:active,
  &.btn-secondary:focus,
  &.btn-secondary:hover {
    color: ${theme.colors.colorCalendarBlue} !important;
  }
`;
export const StyledDropdownMenuItem = styled(DropdownItem)`
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 6px 20px;
  ${(props) =>
    props.$isDisabled &&
    `
    opacity: 0.7;
  `}
  &.active {
    color: ${theme.colors.colorMediumGray9};
    font-weight: 600;
    background: transparent;
    position: relative;
  }

  &:hover {
    ${(props) =>
      props.$isDisabled
        ? `
          background: transparent !important;
          cursor: default !important;
          color: unset;
        `
        : `
          background: ${theme.colors.colorPaleGray1};
          color: ${theme.colors.colorMediumGray9};
    `}
  }
`;

export const StyledDropdownHeader = styled(StyledDropdownMenuItem)`
  font-weight: 600;
  font-size: 14px;
  margin: 5px 0 2px;
  pointer-events: none;
  padding: 1px 16px;
  &:hover {
    background: transparent;
  }
`;

const StyledDivider = styled.hr`
  margin: 5px 0;
`;

const DividerContainer = styled.div`
  padding: 0 20px;
`;

/* ------------------------------------ - ----------------------------------- */

const OptionsDropdown = ({
  onSelect,
  currentValue,
  options,
  optionsHash,
  headerText,
  testId = '',
  renderToggle,
  disableDropdown = false,
  disableToggle = false,
  onDropdownOpen = noop
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (value) => {
    onSelect(value);
    ReactTooltip.hide();
  };

  const renderOption = (option) => {
    const {
      label,
      value,
      tooltip,
      isWarningTooltip,
      disabled,
      isDivider,
      className
    } = optionsHash ? optionsHash[option] : option;

    if (tooltip) rebuildTooltip();
    if (isDivider)
      return (
        <DividerContainer key={value}>
          <StyledDivider />
        </DividerContainer>
      );
    return (
      <StyledDropdownMenuItem
        key={value}
        className={
          'options-dropdown-option' + (className ? ` ${className}` : '')
        }
        data-testid={`options-dropdown-option-${value}`}
        $isDisabled={disabled}
        active={value === currentValue}
        onClick={() => {
          return disabled ? noop : handleClick(value);
        }}
        data-for="app-tooltip"
        data-tip={tooltip || ''}
        data-type={isWarningTooltip ? 'error' : undefined}
        data-effect="solid"
        data-html
      >
        {label}
      </StyledDropdownMenuItem>
    );
  };

  useEffect(() => {
    if (onDropdownOpen && isOpen) {
      onDropdownOpen();
    }
  }, [isOpen, onDropdownOpen]);

  return (
    <StyledDropdown
      toggle={disableDropdown ? noop : toggle}
      isOpen={isOpen}
      className="options-dropdown"
      $isDisabled={disableToggle}
    >
      <StyledDropdownToggle
        className="options-dropdown-toggle"
        data-testid={`options-dropdown-toggle-${testId}`}
      >
        {renderToggle ? (
          renderToggle(isOpen)
        ) : (
          <>
            {options[currentValue]?.label}
            <Arrow className="options-dropdown-arrow" />
          </>
        )}
      </StyledDropdownToggle>
      <StyledDropdownMenu className="options-dropdown-menu">
        {headerText && (
          <StyledDropdownHeader className="options-dropdown-menu-header">
            {headerText}
          </StyledDropdownHeader>
        )}

        {options.map(renderOption)}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default OptionsDropdown;
