import styled from 'styled-components';
import theme from 'theme';
import { StyledKaratRight } from '../styles';
import HelpIcon from 'images/help-icon-2.svg';
import { FilterListTypeList } from '../types';
import { FilterListType, memberFilterListTypes } from 'FilterModule/constants';

export const DefaultFilterListTypeHeaderCell = ({
  row
}: {
  row: { original: FilterListTypeList };
}) => {
  const {
    toggleCollapse,
    isOpen,
    numSelectedItems,
    onlySelectedItemLabel,
    filterListType,
    filterLabel,
    isAllSelected
  } = row.original;

  const selectedItemsLabel =
    onlySelectedItemLabel ??
    (numSelectedItems === 0 ||
    (filterListType === FilterListType.Billable && isAllSelected)
      ? NONE_SELECTED_LABEL
      : `${numSelectedItems} Selected`);

  const tooltip = tooltipHash[filterListType];

  return (
    <StyledFilterSectionHeaderRowCell
      isOpen={isOpen}
      onClick={toggleCollapse}
      className="filter-row-header"
      backgroundColor={numSelectedItems > 0 ? 'white' : undefined}
    >
      <div>
        <span>{filterLabel ?? defaultHeaderLabels[filterListType]}</span>
        {tooltip && (
          <StyledHelpIcon
            data-for="app-tooltip"
            data-tip={tooltip}
            data-effect="solid"
            data-class="mw-250 center"
            src={HelpIcon}
          />
        )}
      </div>
      <StyledKaratRight isOpen={isOpen} width="12px" height="12px" />

      <SelectedItemsLabel isActive={selectedItemsLabel !== NONE_SELECTED_LABEL}>
        <div className="selected-items-label">{selectedItemsLabel}</div>
      </SelectedItemsLabel>
    </StyledFilterSectionHeaderRowCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const NONE_SELECTED_LABEL = 'All';

// if these need to be overriden, define filterLabel using filterSchema optionHash
const defaultHeaderLabels = {
  ...memberFilterListTypes.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: 'Member'
    }),
    {}
  ),
  [FilterListType.Projects]: 'Project',
  [FilterListType.PhaseNames]: 'Phase',
  [FilterListType.ActivityIds]: 'Work Category',
  [FilterListType.Billable]: 'Billable',
  [FilterListType.ClientNames]: 'Client',
  [FilterListType.Departments]: 'Department',
  [FilterListType.Positions]: 'Role',
  [FilterListType.Regions]: 'Region',
  [FilterListType.Disciplines]: 'Discipline',
  [FilterListType.Skills]: 'Skill',
  [FilterListType.SkillLevels]: 'Skill',
  [FilterListType.Offices]: 'Office',
  [FilterListType.Portfolios]: 'Portfolio',
  [FilterListType.ProjectBudgetStatus]: 'Project Status',
  [FilterListType.PhaseBudgetStatus]: 'Phase Status',
  [FilterListType.ProfitCenter]: 'Profit Center',
  [FilterListType.EmploymentType]: 'Membership',
  [FilterListType.ShowProjectsWithUnassignedRoles]: 'Open Roles',
  [FilterListType.Priorities]: 'Priority'
};

const tooltipHash = {
  [FilterListType.ShowProjectsWithUnassignedRoles]:
    'Filter to show only projects with Open Roles.'
};

/* --------------------------------- styling -------------------------------- */

const StyledHelpIcon = styled.img`
  margin-left: 4px;
  margin-bottom: 14px;
  filter: brightness(0.96);
  width: 8px;
  height: 8px;
`;

const StyledFilterSectionHeaderRowCell = styled.div<{
  isOpen: boolean;
  backgroundColor?: string;
}>`
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.colorSemiDarkGray1};
  position: relative;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-left: 10px;

  .first & {
    border-top: 1px solid ${theme.colors.colorPaleGray7};
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    border-bottom-color: ${theme.colors.colorPaleGray7};
  `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background: ${backgroundColor};
  `}

  &:hover {
    background: ${theme.colors.colorTranslucentGray5};
  }
`;

const SelectedItemsLabel = styled.div<{ isActive: boolean }>`
  padding: inherit; // needed for keeping child from overflowing
  left: 0; // ^
  font-size: 12px;
  color: ${(props) =>
    props.isActive
      ? theme.colors.colorCalendarBlue
      : theme.colors.colorLightGray15};

  position: absolute;
  bottom: 4px;
  width: 100%;
  cursor: pointer;

  .selected-items-label {
    padding-left: 7px;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
  }
`;
