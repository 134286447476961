import React from 'react';
import TeamMemberProfile from '../TeamMemberProfile';
import TasksListContainer from 'views/Home/TaskList/TasksListContainer';

const TeamMemberTasks = () => (
  <TeamMemberProfile>
    <TasksListContainer />
  </TeamMemberProfile>
);

export default TeamMemberTasks;
