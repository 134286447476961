import { useSelector } from 'react-redux';
import {
  getAllActivityRowInfo,
  getProjectHash,
  getFlatPhasesHash,
  getGroupsHash,
  getScopeHash,
  getPlannerSchedules
} from 'selectors';

const useEntitiesHash = () => {
  const projectHash = useSelector(getProjectHash);
  const phaseHash = useSelector(getFlatPhasesHash);
  const activityHash = useSelector(getAllActivityRowInfo);
  const boardsHash = useSelector(getGroupsHash);
  const scopeHash = useSelector(getScopeHash);
  const workplanHash = useSelector(getPlannerSchedules);

  return {
    projectHash,
    phaseHash,
    activityHash,
    boardsHash,
    scopeHash,
    workplanHash
  };
};

export default useEntitiesHash;
