import styled, { css } from 'styled-components';
import theme from 'theme';
import FilterIcon from 'icons/WideFilterIcon';
import NewCloseIcon from 'icons/NewCloseIcon';
import type { StackedFiltersVariant } from './StackedFiltersContainer';
import { ButtonContainer, FlexRow } from 'components/styles';

interface StackedFiltersHeaderProps {
  handleClose: () => void;
  handleDone?: () => void;
  headerTitle?: string;
  numStackedFiltersUsed: number;
  variant: StackedFiltersVariant;
  shouldShowCancelButton?: boolean;
}

export const StackedFiltersHeader = ({
  handleClose,
  handleDone,
  headerTitle,
  numStackedFiltersUsed,
  variant,
  shouldShowCancelButton = true
}: StackedFiltersHeaderProps) => {
  return (
    <>
      {headerTitle && (
        <StyledTitle className="stacked-filter-header-title">
          {headerTitle}
        </StyledTitle>
      )}

      <StyledStackedFiltersHeader variant={variant}>
        {variant === 'SideFilter' ? (
          <>
            <FilterTrigger onClick={handleClose}>
              <FilterIcon
                height={16}
                color={theme.colors.colorCalendarBlue}
                className="filter-icon"
              />
              <FilterText>
                {numStackedFiltersUsed || ''} Filter
                {numStackedFiltersUsed > 1 ? 's' : ''}
              </FilterText>
            </FilterTrigger>

            <CloseIconContainer>
              <NewCloseIcon
                width={23}
                onClick={handleClose}
                stroke="transparent"
              />
            </CloseIconContainer>
          </>
        ) : variant === 'Standalone' ? (
          <FlexRow>
            {shouldShowCancelButton && (
              <StyledCancelButton onClick={handleClose}>
                Cancel
              </StyledCancelButton>
            )}

            <StyledDoneButton onClick={handleDone}>Done</StyledDoneButton>
          </FlexRow>
        ) : null}
      </StyledStackedFiltersHeader>
    </>
  );
};

/* ------------------------------------ - ----------------------------------- */

const FilterText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  margin-left: 4px;
  font-weight: 600;
`;

const FilterTrigger = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1px;
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(80%);
    }
    `}
`;

const StyledDoneButton = styled.button`
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: none;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  padding: 5px 6px;
  font-size: 14px;
  border-radius: 3px;

  &:hover {
    filter: brightness(94%);
  }
`;

const StyledCancelButton = styled(StyledDoneButton)`
  background: none;
  color: ${theme.colors.colorMediumGray5};
  margin-right: 5px;
  font-weight: normal;
  &:hover {
    background: none;
    color: ${theme.colors.colorSemiDarkGray1};
  }
`;

const StyledFilterListsTableStylesByTableVariant: Partial<
  Record<StackedFiltersVariant, ReturnType<typeof css>>
> = {
  SideFilter: css`
    height: 20px;
    margin-bottom: 4px;
    justify-content: space-between;

    .filter-icon-container {
      margin-top: 0;
    }
  `,

  Standalone: css`
    height: 50px;
    background: ${theme.colors.colorLightGray19};
    justify-content: end;
    padding-top: 14px;

    ${ButtonContainer} {
      height: 100%;
    }
  `
};

const StyledStackedFiltersHeader = styled.div<{
  variant: StackedFiltersVariant;
}>`
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 31px;

  /* variant specific styles */
  ${({ variant }) => StyledFilterListsTableStylesByTableVariant[variant]}
`;

const CloseIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 30px;

  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray1};
    }
  }
`;

const StyledTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 600;
  color: black;
  padding: 14px 30px;
`;
