import {
  SimpleFilterDropdown,
  SimpleFilterDropdownStyleWrapper
} from './SimpleFilterDropdown';
import { FilterListTypeDropdown } from 'FilterModule/types';
import { useDisciplinesFilter } from 'FilterModule/hooks';
import styled from 'styled-components';

export const DisciplinesFilterDropdown: FilterListTypeDropdown = ({
  renderToggle,
  field
}) => {
  const {
    updateSelectedItems,
    optionsArray,
    itemHash,
    selectedItems,
    labelKey
  } = useDisciplinesFilter({ field });

  return (
    <SimpleFilterDropdown
      isBatchClearOnly
      isSearchEnabled={true}
      itemHash={itemHash}
      itemHeight={55}
      items={optionsArray}
      labelKey={labelKey}
      listHeight={230}
      listWidth={250}
      renderToggle={renderToggle}
      selectedItems={selectedItems}
      styleWrapper={StyledPositionsFiltersDropdown}
      updateSelectedItems={updateSelectedItems}
    />
  );
};

const StyledPositionsFiltersDropdown = styled(SimpleFilterDropdownStyleWrapper)`
  .flyout-list-item-container:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  }
`;
