import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';
import { getSelectedTeamIsUsingCostRate } from 'selectors';
import useCan from 'appUtils/hooks/useCan';
import { readBudgetCostRate } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';

const emptyObj = {};

const useCostRate = (filter = emptyObj) => {
  const dispatch = useDispatch();

  // Team-wide setting
  const isUsingCostRate = useSelector(getSelectedTeamIsUsingCostRate);

  const canReadBudgetCostRate = useCan(readBudgetCostRate);

  // Filter-specific and used for charts/reports.
  // Only roles with permissions can view by cost rate - default for admins is cost rate
  const isViewingCostRate = useMemo(() => {
    if (!filter.custom || !canReadBudgetCostRate) return false;
    return filter.custom.isViewingCostRate ?? isUsingCostRate;
  }, [filter.custom, canReadBudgetCostRate, isUsingCostRate]);

  const setIsViewingCostRate = useCallback(
    (newIsViewingCostRate) => {
      if (canReadBudgetCostRate && isViewingCostRate !== newIsViewingCostRate) {
        dispatch(
          updateAccountFilterLocal({
            ...filter,
            custom: {
              ...filter.custom,
              isViewingCostRate: newIsViewingCostRate
            }
          })
        );
      }
    },
    [dispatch, filter, canReadBudgetCostRate, isViewingCostRate]
  );

  return {
    isUsingCostRate,
    isViewingCostRate,
    setIsViewingCostRate,
    canReadBudgetCostRate
  };
};

export default useCostRate;
