import * as actions from '../actionCreators';
import { fork, takeEvery, all } from 'redux-saga/effects';
import * as workers from './workers';

export default function* requirementSaga() {
  const sagas = [
    fork(
      takeEvery,
      actions.fetchEntityRequirements,
      workers.fetchEntityRequirements
    ),
    fork(
      takeEvery,
      actions.updateEntityRequirements,
      workers.updateEntityRequirements
    )
  ];
  yield all(sagas);
}
