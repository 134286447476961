import theme from 'theme';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import styled from 'styled-components';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import { toggleRemoveOverlay } from 'actionCreators';
import { getTeamMembersHash, getSelectedTeamId } from 'TeamsModule/selectors';
import {
  archiveTeamMembers,
  hardDeleteTeamMembers,
  unarchiveTeamMembers
} from 'TeamsModule/actionCreators';

const RemoveMemberModal = ({ onConfirm = undefined }) => {
  const dispatch = useAppDispatch();
  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const isOpen = useAppSelector((state) => state.settings.showRemoveOverlay);

  const removeMemberIds = useAppSelector(
    (state) => state.settings.removeOverlayAccountIds
  );
  const teamId = useAppSelector(getSelectedTeamId);

  // Unarchive: This user clicked on is currently archived and will be unarchive or delete
  const isUnarchive = useAppSelector(
    (state) => state.settings.removeOverlayIsUnarchive
  );

  const willHardDeleteArchivedMember = useAppSelector(
    (state) => state.settings.willHardDeleteArchivedMember
  );
  const isRestoreMemberAction = isUnarchive && !willHardDeleteArchivedMember;
  const isHardDeleteMemberAction = isUnarchive && willHardDeleteArchivedMember;

  const teamMembersToRemove = (removeMemberIds ?? []).flatMap(
    (id) => teamMembersHash[id] ?? []
  );

  const isArchiveAction = !isUnarchive && !willHardDeleteArchivedMember;

  const closeModal = () =>
    dispatch(toggleRemoveOverlay({ showRemoveOverlay: false }));

  const handleRestoreMember = () => {
    /** This action is for restoring member */
    dispatch(
      unarchiveTeamMembers({
        teamMembershipIds: teamMembersToRemove.map((member) => member.id),
        teamId
      })
    );
  };

  const handleHardDeleteMember = () => {
    dispatch(
      hardDeleteTeamMembers({
        teamId,
        teamMembershipIds: teamMembersToRemove.map((member) => member.id),
        permissions: { teamId }
      })
    );
  };

  const handleDeleteOnClick = () => {
    if (isHardDeleteMemberAction) {
      handleHardDeleteMember();
    } else if (isRestoreMemberAction) {
      handleRestoreMember();
    } else {
      dispatch(
        archiveTeamMembers({
          teamId,
          teamMembershipIds: teamMembersToRemove.map((member) => member.id),
          meta: { onSuccess: onConfirm }
        })
      );
    }
    closeModal();
  };

  const renderHeader = () => {
    if (isHardDeleteMemberAction) {
      return <>Delete Archived Team Member</>;
    } else if (isRestoreMemberAction) {
      return <>Restore Team Member</>;
    }
  };

  const isSingleMemberBeingRemoved = teamMembersToRemove.length === 1;

  const renderBody = () => {
    if (isHardDeleteMemberAction) {
      return (
        <>
          {`Are you sure you want to remove ${
            isSingleMemberBeingRemoved
              ? teamMembersToRemove[0].account.name
              : 'them'
          } from Mosaic?`}
        </>
      );
    } else if (isRestoreMemberAction) {
      return (
        <>
          {`Are you sure you want to restore ${
            isSingleMemberBeingRemoved
              ? `${teamMembersToRemove[0].account.name}'s`
              : 'their'
          } access to Mosaic?`}
        </>
      );
    }
    return (
      <>
        Are you sure you want to archive{'  '}
        <b>
          {isSingleMemberBeingRemoved
            ? teamMembersToRemove[0].account.name
            : 'them'}
        </b>
        ? You can restore archived members {/* or Permanently Delete them */}{' '}
        from the archived list.
      </>
    );
  };

  const renderConfirmButtons = () => {
    return (
      <>
        <div className="delete-modal-footer-cancel">
          <button onClick={closeModal}>Cancel</button>
        </div>
        <div className="delete-modal-footer-yes">
          <button
            onClick={handleDeleteOnClick}
            style={{
              background: isHardDeleteMemberAction
                ? theme.colors.colorCalendarRed
                : theme.colors.colorDeleteRed
            }} // colorCalendarRed for hard delete
            className="delete-modal-footer-yes-red"
          >
            {/* when hard delete is possible handle conditional text here */}
            {isHardDeleteMemberAction ? 'Delete' : 'Archive Member'}
          </button>
        </div>
      </>
    );
  };
  return (
    <DeleteModal
      renderHeader={
        isRestoreMemberAction || isRestoreMemberAction
          ? renderHeader
          : isArchiveAction
          ? renderArchivedHeader
          : undefined
      } // delegate delete to normal header
      isOpen={isOpen}
      toggle={closeModal}
      renderBody={renderBody}
      deleteOnClick={handleDeleteOnClick}
      component={'Team Member'}
      renderConfirmButtons={
        isRestoreMemberAction ? undefined : renderConfirmButtons
      }
      isArchiveAction={
        isArchiveAction
      } /* add conditional to handle deleting archived members when implemented */
      showCloseIcon={!isArchiveAction}
    />
  );
};

export default RemoveMemberModal;

const StyledArchivedHeader = styled.div`
  font-size: 26px;
  color: ${theme.colors.colorPureBlack};
`;

const renderArchivedHeader = () => {
  return <StyledArchivedHeader>Archive Team Member</StyledArchivedHeader>;
};
