import KaratRight from 'icons/KaratRight';
import styled, { css } from 'styled-components';

export const FillIconStyles = css`
  path {
    fill: ${({ theme }) => theme.colors.colorMediumGray9};
  }

  &.disabled {
    path {
      fill: ${({ theme }) => theme.colors.colorLightGray15};
    }
  }

  &.selected {
    path {
      stroke: ${({ theme }) => theme.colors.colorMediumGray9};
      stroke-width: 0.2px;
    }

    &.disabled {
      path {
        stroke: ${({ theme }) => theme.colors.colorLightGray15};
        stroke-width: 0.2px;
      }
    }
  }
`;

export const StrokeIconStyles = css`
  path {
    stroke: ${({ theme }) => theme.colors.colorMediumGray9};
  }

  &.disabled {
    path {
      stroke: ${({ theme }) => theme.colors.colorLightGray15};
    }
  }

  &.selected {
    path {
      stroke: ${({ theme }) => theme.colors.colorMediumGray9};
      stroke-width: 1.2px;
    }

    &.disabled {
      path {
        stroke: ${({ theme }) => theme.colors.colorLightGray15};
        stroke-width: 1.2px;
      }
    }
  }
`;

export const StyledKaratRight = styled(KaratRight).attrs({
  width: '7px',
  height: '11px'
})`
  ${FillIconStyles};
`;

export const MenuItemContainer = styled.div`
  &:not(.disabled) {
    color: ${({ theme }) => theme.colors.colorMediumGray9};
  }
  &.disabled {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }
  &.selected {
    font-weight: 600;
  }
  padding: 8px 16px;
`;
