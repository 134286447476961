import {
  DependencyOption,
  StyledBackKaratContainer,
  DependencyItemName,
  DependencyItemDate
} from './styles';
import EllipsisText, { TooltipBehavior } from 'components/EllipsisText';
import BackKaratIcon from 'icons/BackKarat';

const DependencyRow = ({
  key,
  isDisabled,
  text,
  description,
  date,
  isValidStart
}) => {
  return (
    <DependencyOption
      isDependencyRow
      isDependencyStep
      key={key}
      disabled={isDisabled}
    >
      <DependencyItemName>
        <EllipsisText
          tooltip={text}
          tooltipBehavior={
            text !== description
              ? TooltipBehavior.AlwaysEnabled
              : TooltipBehavior.EnabledOnEllipsis
          }
          width={233}
        >
          {description}
        </EllipsisText>
      </DependencyItemName>
      {isValidStart && (
        <StyledBackKaratContainer>
          <BackKaratIcon />
        </StyledBackKaratContainer>
      )}
      {isValidStart && <DependencyItemDate>{date}</DependencyItemDate>}
    </DependencyOption>
  );
};

export default DependencyRow;
