import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList, areEqual } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  StyledFlyoutList,
  StyledListItem,
  StyledItemRowContainer,
  PencilIconContainer,
  EmptyStateContainer
} from './styles';
import PencilIconBlue from 'icons/PencilIconBlue';
import AutoSizer from 'react-virtualized-auto-sizer';
import { SCROLLBAR_WIDTH } from 'appConstants/scrollbar';

const noop = () => {};

const NonMemoRow = ({ data: props, index, style }) => {
  const {
    renderItem,
    editMode,
    getItemId,
    handleSelect,
    selectCallback,
    unSelectable,
    isItemUnSelectable = noop,
    setEditing,
    listItemContainerStyle = {},
    items,
    hideEditIcon,
    clearSearch,
    currentlySelectedId,
    isInactive = () => {}
  } = props;

  const item = items[index];
  if (!item && item !== 0) {
    return null;
  }
  const id = (item && getItemId(index, props)) || 'new';

  return (
    <StyledListItem
      className={`flyout-list-item-container ${
        item.id === currentlySelectedId ? 'currently-selected' : ''
      } ${item.className || ''}`}
      unSelectable={unSelectable || isItemUnSelectable(item)}
      listItemContainerStyle={listItemContainerStyle}
      isAlreadySelected={item && item.isAlreadySelected}
      style={style}
      isInactive={isInactive(item)}
      onClick={(e) =>
        handleSelect(e, { item, selectCallback, index, clearSearch })
      }
    >
      <StyledItemRowContainer className="flyout-item-row-container">
        {renderItem({ item, selectCallback, index })}
      </StyledItemRowContainer>
      <PencilIconContainer
        show={editMode && !hideEditIcon}
        onClick={() => setEditing(id)}
      >
        <PencilIconBlue />
      </PencilIconContainer>
    </StyledListItem>
  );
};

const Row = React.memo(NonMemoRow, areEqual);

const emptyArray = [];
const byId = (item) => item.id;
const getItemSize = ({ getItemHeight, index, itemHeight, items }) =>
  getItemHeight
    ? getItemHeight(items[index])
    : items[index]?.itemHeight || itemHeight;

/**
 * Computes the height of the list if the height of the list should shrink to
 * fit a shorter list. The following dimensions are used.
 * - `minListHeight` acts as `min-height`,
 * - `computedHeight` acts as `height`, and
 * - `listHeight` acts as `max-height`.
 * If the list should not shrink to fit, return `undefined` to dynamically
 * size the list based on the available space.
 *
 * This function intentionally uses arguments instead of the component
 * properties to ensure that the caller in `componentDidUpdate` checks for
 * dependency updates.
 */
const computeListHeight = ({
  getItemHeight,
  itemHeight,
  items = [],
  listHeight,
  minListHeight
}) => {
  if (minListHeight === undefined) return listHeight;

  // Sum the heights of the items in the list.
  const computedHeight = items
    .map((_, index) => getItemSize({ getItemHeight, index, itemHeight, items }))
    .reduce((sum, x) => sum + x, 0);

  return Math.min(Math.max(minListHeight, computedHeight), listHeight);
};

class VirtualFlyoutList extends React.Component {
  static defaultProps = {
    idKey: byId,
    items: emptyArray
  };

  getItemSizeWrapped = (index) => {
    const { getItemHeight, itemHeight, items } = this.props;
    return getItemSize({ getItemHeight, index, itemHeight, items });
  };

  state = {
    computedHeight: computeListHeight({
      getItemHeight: this.props.getItemHeight,
      itemHeight: this.props.itemHeight,
      items: this.props.items,
      listHeight: this.props.listHeight,
      minListHeight: this.props.minListHeight
    })
  };

  componentDidUpdate(prevProps) {
    const { getItemHeight, itemHeight, items, listHeight, minListHeight } =
      this.props;

    // The list must recompute the item heights if any value that may determine
    // the height of an item changes.
    if (
      getItemHeight !== prevProps.getItemHeight ||
      itemHeight !== prevProps.itemHeight ||
      items !== prevProps.items
    )
      this.listRef?.resetAfterIndex?.(0);

    // The list height must be recomputed if any value that may determine its
    // height changes.
    if (
      getItemHeight !== prevProps.getItemHeight ||
      itemHeight !== prevProps.itemHeight ||
      items !== prevProps.items ||
      listHeight !== prevProps.listHeight ||
      minListHeight !== prevProps.minListHeight
    )
      this.setState({
        computedHeight: computeListHeight({
          getItemHeight,
          itemHeight,
          items,
          listHeight,
          minListHeight
        })
      });
  }

  setEditing = (id) => {
    const { setEditing } = this.props;
    setEditing(id);
  };

  renderEmpty = () => {
    const { copy, emptyContainerClassName } = this.props;
    return (
      <EmptyStateContainer
        className={cn('empty-list-container', emptyContainerClassName)}
      >
        {copy.emptyState}
      </EmptyStateContainer>
    );
  };

  setListRef = (ref) => (this.listRef = ref);

  render() {
    const {
      getItemId,
      isEmpty,
      isItemLoaded,
      isLoading,
      items = [],
      listWidth,
      loadMoreItems,
      renderLoading,
      renderTableBodyHeader,
      threshold = 30,
      totalCount
    } = this.props;

    const { computedHeight } = this.state;

    return (
      <StyledFlyoutList className="styled-flyout-list-container">
        {renderTableBodyHeader && renderTableBodyHeader()}
        {isLoading ? (
          renderLoading()
        ) : isEmpty ? (
          this.renderEmpty()
        ) : (
          <AutoSizer
            defaultHeight={computedHeight}
            disableHeight={computedHeight !== undefined}
            disableWidth
          >
            {({ height }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalCount || 2000} // can be arbitrarily large
                loadMoreItems={loadMoreItems}
                threshold={threshold}
              >
                {({ onItemsRendered, ref }) => (
                  <VariableSizeList
                    className="scrollbar variable-size-list"
                    onItemsRendered={onItemsRendered}
                    height={height ?? computedHeight}
                    width={listWidth + SCROLLBAR_WIDTH}
                    itemSize={this.getItemSizeWrapped}
                    itemCount={items.length}
                    itemData={this.props}
                    ref={(list) => {
                      ref(list);
                      this.setListRef(list);
                    }}
                    itemKey={getItemId}
                  >
                    {Row}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        )}
      </StyledFlyoutList>
    );
  }
}

VirtualFlyoutList.propTypes = {
  setEditing: PropTypes.func.isRequired,
  getIdKey: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired
};

export default VirtualFlyoutList;
