import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getAccountFilters } from 'selectors';
export const useFilterFinder = () => {
  const filters = useSelector(getAccountFilters);
  return useCallback(
    (filterType, byId = false) => {
      // encapsulate complicated filter finding logic here as it evolves to custom filters
      return Object.values(filters).find((accountFilter) =>
        byId
          ? accountFilter.id === filterType
          : accountFilter.page_name === filterType
      );
    },
    [filters]
  );
};
