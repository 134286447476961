import React from 'react';
import styled from 'styled-components';
import MosaicLogo from 'images/mosaic-logo-2021.svg';
const LogoHoverArea = styled.rect`
  cursor: pointer;
`;

export const LogoComponent = (props) => {
  return (
    <>
      <image
        xlinkHref={MosaicLogo}
        x={props.cx - 25}
        y={props.cy - 25}
        width={50}
        height={50}
      />
      <LogoHoverArea
        stroke="transparent"
        fill="transparent"
        x={props.cx - 25}
        y={props.cy - 25}
        width="50"
        height="50"
        onMouseOver={() => {
          if (!props.isHoveringDot) props.setIsHoveringDot(true);
        }}
        onMouseOut={() => {
          if (props.isHoveringDot) props.setIsHoveringDot(false);
        }}
      />
    </>
  );
};
