import React from 'react';
import TaskContent from '../TaskContent';
import { connect } from 'react-redux';
import { setEditTaskId } from 'actionCreators';
import { ProjectMenuButton, ProjectTitleCircle } from '../styles';
import isEmpty from 'lodash/isEmpty';
import { getProjectHash } from 'selectors';

class DescriptionContent extends React.Component {
  state = {
    currentDescriptionText: this.props.task.description,
    noteText: this.props.task.note || ''
  };

  componentDidUpdate(prevProps, prevState) {
    const { task, isEditingTask, isClosing } = this.props;
    const newDescription = this.props.task.description;
    const oldDescription = prevProps.task.description;
    const newNote = this.props.task.note;
    const oldNote = prevProps.task.note;
    const oldIsEditingTask = prevProps.isEditingTask;

    if (newDescription !== oldDescription) {
      this.setState({
        currentDescriptionText: newDescription
      });
    }
    if (newNote !== oldNote) {
      this.setState({
        noteText: newNote
      });
    }
    if (isClosing && isEditingTask !== oldIsEditingTask) {
      const { currentDescriptionText, noteText } = this.state;
      if (currentDescriptionText !== task.description) {
        this.handleDescriptionSubmit();
      }
      this.clearUpdateTimeout();
      if (task.note !== noteText) {
        this.handleUpdateTaskNote({
          note: noteText
        });
      }
    }
  }

  handleProjectMenuSelection = (
    newProjectId = null,
    taskGroupId = null,
    newPhaseId = null,
    newActivityId = null,
    newActivityPhaseId = null
  ) => {
    const { handleTasksAttributesUpdate, token, task } = this.props;
    const body = {
      task_ids: [task.id],
      project_id: newProjectId
    };
    if (taskGroupId) {
      body.task_group_id = taskGroupId;
    }
    if (newPhaseId) {
      body.phase_id = newPhaseId;
    }

    if (newActivityId) {
      body.activity_id = newActivityId;
    }

    if (newActivityPhaseId) {
      body.activity_phase_id = newActivityPhaseId;
    }
    handleTasksAttributesUpdate({
      token,
      body,
      options: {}
    });
  };

  getButtonText = () => {
    const {
      taskIsBeingEdited,
      task: { project, project_id },
      projectHash
    } = this.props;
    if (!isEmpty(project)) {
      const { title, description } = project;
      const projectAndDescription = `${title} - ${description}`;
      const hasProjectDescription = title !== 'Select Project' && description;
      if (hasProjectDescription && taskIsBeingEdited) {
        return projectAndDescription;
      }
      return title;
    } else if (projectHash[project_id]) {
      // fallback on projectHash for info if project isnt fetched
      const hashProject = projectHash[project_id];
      const { title, description } = hashProject;
      const projectAndDescription = `${title} - ${description}`;
      const hasProjectDescription = title !== 'Select Project' && description;
      if (hasProjectDescription && taskIsBeingEdited) {
        return projectAndDescription;
      }
      return title;
    }
    return 'Select Project';
  };

  renderProjectPopoverMenuItem = () => {
    const {
      task,
      taskIsEditing,
      isSelectedHomeTask,
      isOnProjectDetail,
      isMemberModalOpen,
      projectHash
    } = this.props;
    const showProjectSelector =
      (isMemberModalOpen && task.project_id) ||
      (!isOnProjectDetail && task.project_id && !isSelectedHomeTask);
    return (
      <ProjectMenuButton
        show={showProjectSelector}
        className="project-title"
        taskBeingEdited={taskIsEditing}
        hasProject={task.project_id}
      >
        {(!isOnProjectDetail || isMemberModalOpen) && (
          <ProjectTitleCircle projectId={task.project_id} />
        )}
        {this.getButtonText()}
      </ProjectMenuButton>
    );
  };

  handleDescriptionTextAreaEvent = (currentDescriptionText) => {
    this.setState({
      currentDescriptionText
    });
  };

  handleDescriptionSubmit = () => {
    const { task, handleTasksAttributesUpdate, setEditTaskId, token } =
      this.props;
    const { currentDescriptionText } = this.state;
    let preparedText = currentDescriptionText
      .replace(/<br>/g, '')
      .replace(/&nbsp;/g, ' ')
      .trim();

    handleTasksAttributesUpdate({
      token,
      body: {
        task_ids: [task.id],
        description: preparedText
      }
    });
    setEditTaskId(null);
    this.setState({
      currentDescriptionText: preparedText
    });
  };

  handleBlur = () => {
    if (this.props.isEditingTask) {
      this.props.handleBlur?.();
      if (this.state.currentDescriptionText !== this.props.task?.description) {
        this.handleDescriptionSubmit();
      }
    }
  };

  render() {
    const {
      task,
      taskIsEditing,
      isBatchSelected,
      currentFilter,
      isOnTeamProject,
      isCreatingTask,
      isOnPersonalProject,
      isOnHomeView,
      taskId,
      handleTasksAttributesUpdate,
      isSelectedHomeTask,
      taskEditProperty,
      rowNumber,
      isOnProjectView,
      selectedProject,
      index
    } = this.props;

    const { currentDescriptionText } = this.state;
    const isComplete = task.completed_at !== null;
    const shouldRenderAssignee =
      (!isOnPersonalProject &&
        (currentFilter.scope === 'assigned_by_me' || isOnProjectView)) ||
      isSelectedHomeTask;
    const taskModalProps = {};
    if (isSelectedHomeTask) {
      taskModalProps.id = 'task-modal';
    }
    // isOnTeamProject only tells us what whether the selected project in redux is personal or not

    return (
      <TaskContent
        taskIsEditing={taskIsEditing}
        openTaskModal={this.openTaskModal}
        markTaskCompletion={this.markTaskCompletion}
        renderProjectPopoverMenuItem={this.renderProjectPopoverMenuItem}
        handleProjectMenuSelection={this.handleProjectMenuSelection}
        task={task}
        shouldRenderAssignee={shouldRenderAssignee}
        taskId={taskId}
        handleTasksAttributesUpdate={handleTasksAttributesUpdate}
        handleDescriptionTextAreaEvent={this.handleDescriptionTextAreaEvent}
        handleDescriptionSubmit={this.handleDescriptionSubmit}
        currentDescriptionText={currentDescriptionText}
        isOnTeamProject={isOnTeamProject}
        isCreatingTask={isCreatingTask}
        isSelectedHomeTask={isSelectedHomeTask}
        isComplete={isComplete}
        isBatchSelected={isBatchSelected}
        taskProperty={'description'}
        handleTaskEditClick={this.props.handleTaskEditClick}
        handleContentClick={this.props.handleContentClick}
        handleBlur={this.handleBlur}
        isTaskEditProperty={taskEditProperty === 'description'}
        checkForTyping={this.checkForTyping}
        handleNoteChange={this.handleNoteChange}
        noteText={this.state.noteText}
        rowNumber={rowNumber}
        selectedProject={selectedProject}
        setEditingHeight={task.setEditingHeight}
        index={index}
        isVirtualList
      />
    );
  }
}

const mapStateToProps = (state) => ({
  projectHash: getProjectHash(state)
});

export default connect(mapStateToProps, { setEditTaskId })(DescriptionContent);
