import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import bindAll from 'lodash/bindAll';
import {
  commentTypes,
  BASE_COMMENT_HEIGHT
} from 'appConstants/commentConstants';
import PMDot from 'views/memberDisplay/PMDot';
import GuestIcon from 'icons/GuestIcon';
import ContractorIcon from 'icons/ContractorIcon';
import keyBy from 'lodash/keyBy';
import styled from 'styled-components';
import { ACCESS_ROLES } from 'PermissionsModule/constants';

const RoleIndicator = styled.div`
  position: absolute;
  top: 14px;
  left: 18px;
  z-index: 1;
`;

const Circle = styled.div`
  position: relative;

  ${PMDot} {
    z-index: 1;
    right: -1px;
    top: -1px;
  }
`;
const instructions = 'Start typing a name to send to someone this update.';

class MentionInput extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, ['memberFilter']);
  }

  componentDidMount() {
    // this will resize the textarea to fit its contents (no scrollbar)
    const textareaId = `${this.props.commentType}-textarea`;
    const textarea = document.getElementById(textareaId);
    const startHeight =
      textarea.scrollHeight < BASE_COMMENT_HEIGHT
        ? BASE_COMMENT_HEIGHT
        : textarea.scrollHeight;

    if (textarea) {
      textarea.setAttribute(
        'style',
        `height: ${startHeight}px; overflow-y: hidden;
        padding-left: 6px;
        margin-top: 3px;
        `
      );
      textarea.addEventListener('input', this.resizeTextarea, false);
    }
  }

  resizeTextarea() {
    if (this.scrollHeight > BASE_COMMENT_HEIGHT) {
      this.style.height = `${this.scrollHeight}px`;
    }
  }

  memberFilter(query, populateSuggestionList) {
    const { mentionableTeamMembers, projectManagersByAccountId } = this.props;
    if (query) {
      const results = mentionableTeamMembers
        .filter(
          (member) =>
            member.display.toLowerCase().indexOf(query.toLowerCase()) >= 0
        )
        .map((member) => ({
          ...member,
          isPM: projectManagersByAccountId[member.id]?.role_ids?.some(
            (role) => role === ACCESS_ROLES.ADMIN
          )
        }));
      populateSuggestionList(results);
    } else {
      populateSuggestionList([
        {
          id: 'mention-instructions',
          display: instructions
        }
      ]);
    }
  }

  preventInstructionsOnChange(onChangeFunction) {
    // if user clicks or enters/tabs instructions text, do nothing
    // refer to selectFocused and executeOnChange in react-mentions code,
    // which mocks an onChange event with the selected mention item's display text
    return function (e) {
      const mentionInputText = e.target.value;
      if (mentionInputText.includes(instructions)) {
        return;
      }

      onChangeFunction(e);
    };
  }

  renderSuggestion(suggestion, search, highlightedDisplay) {
    if (suggestion.id === 'mention-instructions') {
      return <div className="plain-mention-text">{suggestion.display}</div>;
    } else {
      const IndicatorComponent = suggestion.isGuest
        ? GuestIcon
        : suggestion.isContractor
        ? ContractorIcon
        : undefined;

      return (
        <div className="user">
          <Circle className="circle">
            {suggestion.isPM && <PMDot />}
            {IndicatorComponent && (
              <RoleIndicator>
                <IndicatorComponent width={13} height={13} />
              </RoleIndicator>
            )}
            {suggestion.isGuest && <RoleIndicator />}
            <span className="member-initials">
              <button>
                <div className="initial-div">{suggestion.initials}</div>
              </button>
            </span>
          </Circle>
          <div className="text">{highlightedDisplay}</div>
        </div>
      );
    }
  }

  getInputProps() {
    switch (this.props.commentType) {
      case commentTypes.ADD:
        return {
          id: `${commentTypes.ADD}-textarea`,
          placeholder:
            this.props.placeholders?.ADD ||
            'Add an update, comment, or ask a question.'
        };
      case commentTypes.EDIT:
        return {
          id: `${commentTypes.EDIT}-textarea`,
          placeholder: 'Type to edit'
        };
      case commentTypes.REPLY:
        return {
          id: `${commentTypes.REPLY}-textarea`,
          placeholder: 'Type to reply'
        };
      default:
        return {};
    }
  }

  handleFilePaste = (e) => {
    const { handleFiles } = this.props;
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    const files = [];
    for (const index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        files.push(item.getAsFile());
      }
    }
    handleFiles(files);
  };

  render() {
    const {
      inputRef,
      commentBody,
      suggestionListLeft,
      onChange,
      onClick,
      onKeyPress,
      onAddMention,
      isOnPersonalProject,
      isDisabled
    } = this.props;

    const suggestionsListStyle = {
      suggestions: {
        top: 5,
        left: `${suggestionListLeft}`,
        list: {
          width: 302,
          maxHeight: 215,
          overflow: 'auto',
          position: 'fixed',
          backgroundColor: '#fff',
          border: 'solid 1px #e4e4e4'
        },
        item: {
          '&focused': {
            backgroundColor: '#eaeaea'
          }
        }
      }
    };

    const PickedMentionStyle = {
      backgroundColor: '#eaeaea',
      color: '#4c4c4c !important',
      lineHeight: '1.7'
    };

    const inputProps = this.getInputProps();
    return (
      <MentionsInput
        inputRef={inputRef}
        id={inputProps.id}
        placeholder={inputProps.placeholder}
        value={commentBody}
        onClick={onClick}
        onChange={this.preventInstructionsOnChange(onChange)}
        onKeyPress={onKeyPress}
        className="input"
        type="text"
        allowSpaceInQuery={true}
        markup="<@[__display__](__id__)>"
        style={suggestionsListStyle}
        onPaste={this.handleFilePaste}
        disabled={isDisabled}
      >
        {!isOnPersonalProject && (
          <Mention
            trigger={'@'}
            data={this.memberFilter}
            style={PickedMentionStyle}
            renderSuggestion={this.renderSuggestion}
            onAdd={onAddMention}
          />
        )}
      </MentionsInput>
    );
  }
}

export default MentionInput;
