import React from 'react';
import CompleteCellContent from './CompleteCellContent';
import { noop } from 'appUtils/noop';
const CompleteCell = ({
  row,
  onBeforeCapture = noop,
  draggableProvided,
  isOnlyTaskGroup
}) => {
  return (
    <CompleteCellContent
      group={row.original.activityPhase || {}}
      setIsOpen={row.original.customRowProps.handleSetIsOpen}
      onBeforeCapture={onBeforeCapture}
      draggableProvided={draggableProvided}
      isOnlyTaskGroup={isOnlyTaskGroup}
      allCollapsed={row.original.customRowProps.allCollapsed}
      isOpen={row.original.isOpen}
      numTasks={row.original.list?.numItems}
    />
  );
};

export default CompleteCell;
