import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Modal, ModalFooter } from 'reactstrap';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer
} from 'components/styles';
import { updatePhase } from 'actionCreators';

const StyledModal = styled(Modal)`
  top: 30%;
  width: 402px;
  .modal-content {
    padding: 24px;
  }
`;
const StyledModalHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
`;
const StyledModalBody = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin: 20px 0 15px;
`;
const StyledModalFooter = styled(ModalFooter)`
  border: none;
  padding: 0;
`;

const SemiBold = styled.span`
  font-weight: 600;
`;

const PhaseArchiveConfirmModal = ({ phase, isOpen, toggle }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (phase.id && phase.project_id) {
      dispatch(
        updatePhase({
          id: phase.id,
          projectId: phase.project_id,
          budgetStatus: 'complete'
        })
      );
    }
    toggle();
  };

  return (
    <StyledModal isOpen={isOpen} toggle={toggle}>
      <StyledModalHeader>Make Phase Completed</StyledModalHeader>
      <StyledModalBody>
        Time can not be entered on Completed Phases. <br /> Are you sure you
        want to mark <SemiBold>{`'${phase.name}'`} Completed</SemiBold>?
      </StyledModalBody>
      <StyledModalFooter>
        <ButtonContainer width={200}>
          <BlueSubmitButton onClick={handleSubmit} width={114} height={33}>
            Make Completed
          </BlueSubmitButton>
          <TextButtonWithBorder onClick={toggle} width={66} height={33}>
            Cancel
          </TextButtonWithBorder>
        </ButtonContainer>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default PhaseArchiveConfirmModal;
