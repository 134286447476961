import { noop } from 'appUtils';

import PhaseUserActivityTitleCell from './phaseUserActivityCell/PhaseUserActivityTitleCell';
import PhaseUserActivityWorkdaysCell from './phaseUserActivityCell/PhaseUserActivityWorkdaysCell';
import PhaseUserActivityStartDateCell from './phaseUserActivityCell/PhaseUserActivityStartDateCell';

const EmptyDiv = (props) => <div> </div>;

const PhaseUserActivityRow = (props) => {
  const phaseUserActivityCells = {
    title: PhaseUserActivityTitleCell,
    workdays: PhaseUserActivityWorkdaysCell,
    startDate: PhaseUserActivityStartDateCell
  };

  const CellComponent = phaseUserActivityCells[props.column.id] || EmptyDiv;

  return <CellComponent {...props} />;
};

export default PhaseUserActivityRow;
