import React from 'react';
import SvgIcon from 'components/SvgIcon';

const StageIcon = ({
  height = '11',
  width = '10',
  color = '#828282',
  fillcolor = 'transparent',
  className
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 11 10"
    >
      <path
        d="M1.61803 9.5C1.24634 9.5 1.0046 9.10884 1.17082 8.77639L5.05279 1.01246C5.23705 0.643937 5.76295 0.643937 5.94721 1.01246L9.82918 8.77639C9.99541 9.10884 9.75366 9.5 9.38197 9.5H1.61803Z"
        stroke={color}
        fill={fillcolor}
      />
    </SvgIcon>
  );
};

export default StageIcon;
