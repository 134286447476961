import React, { Fragment, useMemo } from 'react';
import theme from 'theme';
import {
  DATA_KEY,
  DATA_KEY_TO_PERCENT,
  CHART_STACK_ORDER,
  CAPACITY_KEY,
  CHART_STACK_ORDER_WITH_CAPACITY
} from 'UtilizationModule/constants';
import { getTopDataField } from 'UtilizationModule/utils';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import {
  Bar,
  BillableSection,
  NonbillableSection,
  PtoSection,
  HolidaySection,
  RemainingCapacitySection,
  GraphCellContainer,
  PercentCell
} from './styles';

import { getTooltipContent } from 'ReportsModule/components/Utilization/utils';

const sectionComponent = {
  [DATA_KEY.BILLABLE]: BillableSection,
  [DATA_KEY.NONBILLABLE]: NonbillableSection,
  [DATA_KEY.PTO]: PtoSection,
  [DATA_KEY.HOLIDAY]: HolidaySection,
  [CAPACITY_KEY.REMAINING_CAPACITY]: RemainingCapacitySection
};

const GraphCell = ({ row }) => {
  const {
    maxPercent,
    projectId, // present when projectRow
    data,
    isProjectRow,
    isPtoOrHoliday,
    customRowProps: {
      showPto,
      showHolidays,
      showUtilizationByProjects,
      projectColors,
      useCapacityAsDenominator,
      includeRemainingCapacity
    }
  } = row.original;
  const sectionOrder = useMemo(
    () =>
      [
        ...(includeRemainingCapacity
          ? CHART_STACK_ORDER_WITH_CAPACITY
          : CHART_STACK_ORDER)
      ].reverse(),
    [includeRemainingCapacity]
  );

  const { percentForBarLength } = data;

  // calculate bar widths relative to the max billable percent
  const percentRatio = maxPercent
    ? (percentForBarLength / maxPercent) * 100
    : 0;

  const rightmostDataField = getTopDataField(
    data,
    null,
    includeRemainingCapacity
  );
  const tooltipData = getTooltipContent(data);
  const hasData = includeRemainingCapacity
    ? !isProjectRow && !isPtoOrHoliday
    : +data.billable_hours > 0 ||
      +data.nonbillable_hours > 0 ||
      (showPto && +data.pto_hours > 0) ||
      (showHolidays && +data.holiday_hours > 0);

  const renderSection = (dataKey) => {
    const Section = sectionComponent[dataKey];
    const percent = +data[DATA_KEY_TO_PERCENT[dataKey]];

    return percent && percent > 0 ? (
      <Fragment key={dataKey}>
        <Section
          width={percent}
          isRightmost={isProjectRow ? true : dataKey === rightmostDataField}
          key={dataKey}
          color={
            showUtilizationByProjects
              ? projectColors[projectId] || theme.colors.colorLightGray6
              : undefined
          }
        >
          {!isProjectRow && !isPtoOrHoliday && (
            <span>{`${Math.round(percent)}%`}</span>
          )}
        </Section>
        {(isProjectRow || isPtoOrHoliday) && (
          <PercentCell>{`${formatNumWithMaxOneDecimal(percent)}%`}</PercentCell>
        )}
      </Fragment>
    ) : null;
  };

  return (
    <GraphCellContainer>
      <Bar
        className="bar"
        width={percentRatio}
        data-for="legend-style-tooltip"
        data-tip={JSON.stringify({
          data: tooltipData.data,
          summary: tooltipData.summary,
          hasData,
          showBorder: true
        })}
        data-tip-disable={isProjectRow || isPtoOrHoliday}
      >
        {sectionOrder.map((dataKey) => renderSection(dataKey))}
      </Bar>
    </GraphCellContainer>
  );
};
export default GraphCell;
