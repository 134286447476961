import { useMemo, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import { navigateToMemberModal, navigateToOrgChart } from 'actionCreators';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import KaratRight from 'icons/KaratRight';
import SmallUnassignedMemberIcon from 'icons/SmallUnassignedMemberIcon';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { Handlers, ProjectTotal } from './types';
import useMultiLevelDropdown from 'components/MultiLevelDropdown/useMultiLevelDropdown';
import { MOUSE_EVENTS } from 'components/MultiLevelDropdown';
import PhasesDropdown from '../../PhasesDropdown';
import { useToggle } from 'react-use';
import { FormattedPhaseTotals } from 'BudgetModule/utils/projectAccounts';
import { getAssignMemberBudgetRefetchActions } from '../../utils';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import OrgIcon from 'icons/OrgIcon';
import { getTeamSlug } from 'selectors';

enum MemberMenuOptions {
  AddMember = 'addMember',
  AssignAllHoursToPhaseMember = 'AssignAllHoursToPhaseMember',
  AssignToPhases = 'assignToPhases',
  MemberProfile = 'memberProfile',
  ViewAvailability = 'viewAvailability',
  ViewOrgChart = 'viewOrgChart',
  OpenAssignToPhasesDropdown = 'OpenAssignToPhasesDropdown'
}

const noop = () => undefined;

type UseMemberOptionHookArgs = {
  handleOpenMergeBudgetModal: Handlers['handleOpenMergeBudgetModal'];
  resetUnassignedRoleAndSelectedPhaseIds: Handlers['resetUnassignedRoleAndSelectedPhaseIds'];
  teamMembershipsByAccountId: ReturnType<typeof getTeamMembershipsByAccountId>;
  accountId: number;
  unassignedMemberBudgetId: number;
  projectId: number;
  phaseId: number;
  projectTotal: ProjectTotal;
  phaseTotals: FormattedPhaseTotals;
};

export const useMemberOptions = ({
  handleOpenMergeBudgetModal,
  resetUnassignedRoleAndSelectedPhaseIds,
  phaseId,
  teamMembershipsByAccountId,
  accountId,
  unassignedMemberBudgetId,
  projectId,
  projectTotal,
  phaseTotals
}: UseMemberOptionHookArgs) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();
  const teamSlug = useAppSelector(getTeamSlug);

  const { dropdownRef, openDropdown, closeDropdown, MultiLevelDropdown } =
    useMultiLevelDropdown();

  const assignToPhasesDropdownRef = useRef(null);

  /**
   * Assigning to specific selected phases only, not all phases
   */
  const [assignToPhasesDropdownOpen, toggleAssignToPhasesDropdown] =
    useToggle(false);

  const openAssignToPhasesDropdown = useCallback(() => {
    toggleAssignToPhasesDropdown();
    closeDropdown();
  }, [closeDropdown, toggleAssignToPhasesDropdown]);

  const handleAssignToPhases = useCallback(
    ({ phaseIds }: { phaseIds: number[] }) => {
      if (!phaseIds.length) return;
      const member = teamMembershipsByAccountId[accountId];

      if (member && member.account && unassignedMemberBudgetId) {
        const account = member.account;

        // Calculating the total estimated and planned hours for the selected phases only
        const { totalPlannedHours, totalEstimatedHours } = phaseIds.reduce(
          (acc, phaseId) => {
            const { account_totals = [] } = phaseTotals[phaseId] || {};
            const accountTotal = account_totals.find(
              ({ member_budget_id }) =>
                member_budget_id === unassignedMemberBudgetId
            );

            if (accountTotal) {
              acc.totalEstimatedHours += accountTotal.estimated?.hours || 0;
              acc.totalPlannedHours += accountTotal.planned?.hours || 0;
            }

            return acc;
          },
          { totalEstimatedHours: 0, totalPlannedHours: 0 }
        );

        handleOpenMergeBudgetModal({
          assignedAccountId: account.id,
          assignedName: account.name,
          phaseIds,
          unassignedMemberBudgetId,
          projectId,
          totalEstimatedHours,
          totalPlannedHours,
          overrideAssignMemberBudgetOnSuccess: () => {
            const actions = getAssignMemberBudgetRefetchActions({
              projectId,
              memberBudgetId: unassignedMemberBudgetId
            });
            dispatchChain([actions], {
              continueOnFailure: true,
              continueOnCancellation: true,
              cleanupChainStatuses: true
            } as any);
          }
        });

        closeDropdown();
      }
    },
    [
      accountId,
      closeDropdown,
      dispatchChain,
      handleOpenMergeBudgetModal,
      phaseTotals,
      projectId,
      teamMembershipsByAccountId,
      unassignedMemberBudgetId
    ]
  );

  // Assign this suggested member the total estimated hours and planned hours of this unassigned role
  const handleAssignAllHoursToPhaseMember = useCallback(() => {
    const member = teamMembershipsByAccountId[accountId];

    if (member && member.account && unassignedMemberBudgetId) {
      const account = member.account;
      const accountTotal = projectTotal.account_totals?.find(
        (accountTotal) =>
          accountTotal.member_budget_id === unassignedMemberBudgetId
      );
      const totalEstimatedHours = accountTotal?.estimated?.hours || 0;
      const totalPlannedHours = accountTotal?.planned?.hours || 0;

      handleOpenMergeBudgetModal({
        assignedAccountId: account.id,
        assignedName: account.name,
        unassignedMemberBudgetId,
        projectId,
        totalEstimatedHours,
        totalPlannedHours,
        overrideAssignMemberBudgetOnSuccess: () => {
          const actions = getAssignMemberBudgetRefetchActions({
            projectId,
            memberBudgetId: unassignedMemberBudgetId
          });
          resetUnassignedRoleAndSelectedPhaseIds();

          dispatchChain([actions], {
            continueOnFailure: true,
            continueOnCancellation: true,
            cleanupChainStatuses: true
          } as any);
        }
      });

      closeDropdown();
    }
  }, [
    teamMembershipsByAccountId,
    accountId,
    unassignedMemberBudgetId,
    projectTotal.account_totals,
    handleOpenMergeBudgetModal,
    projectId,
    closeDropdown,
    resetUnassignedRoleAndSelectedPhaseIds,
    dispatchChain
  ]);

  const handleViewOrgChart = useCallback(() => {
    dispatch(
      navigateToOrgChart({
        openInNewWindow: true,
        teamSlug
      })
    );
  }, [dispatch, teamSlug]);

  const handleViewAvailability = useCallback(() => noop, []);

  const handleOpenMemberProfile = useCallback(() => {
    closeDropdown();
    dispatch(
      navigateToMemberModal({
        memberId: accountId,
        memberViewType: 'profile',
        membersViewType: 'members'
      })
    );
  }, [accountId, closeDropdown, dispatch]);

  const menuContent = useMemo(() => {
    return {
      items: [
        // {
        //   name: MemberMenuOptions.AddMember,
        //   label: (
        //     <StyledDropdownItem>
        //       <span className="left-add-member-icon">
        //         <PlusCircleIcon
        //           pathStroke={theme.colors.colorMediumGray9}
        //           stroke={theme.colors.colorMediumGray9}
        //           width={14}
        //           height={18}
        //         />
        //       </span>
        //       <span className="label">Add Member</span>
        //       <span>
        //         <KaratRight />
        //       </span>
        //     </StyledDropdownItem>
        //   ),
        //   subMenu: {
        //     items: [
        //       {
        //         name: MemberMenuOptions.AssignAllHoursToPhaseMember,
        //         label: (
        //           <span>
        //             {/* This is exactly what this option is doing even though Figma's wording is different. Can't afford to confuse members
        //               i.e: Assigning all estimated and planned hours of this unassigned role across all Phases of this project to this member, making the unassigned role disappears
        //             */}
        //             Assign Phase Hours to Member
        //           </span>
        //         ),
        //         subMenu: undefined
        //       },
        //       {
        //         name: MemberMenuOptions.OpenAssignToPhasesDropdown,
        //         label: 'Assign to Multiple Phases',
        //         subMenu: undefined
        //       }
        //     ]
        //   }
        // },
        /* ---------------------------------- TODO ---------------------------------- */
        {
          name: MemberMenuOptions.MemberProfile,
          label: (
            <StyledDropdownItem>
              <span>
                <SmallUnassignedMemberIcon width={14} height={18} />
              </span>
              <span className="label">Member Profile</span>
            </StyledDropdownItem>
          ),
          subMenu: undefined
        },
        {
          name: MemberMenuOptions.AssignAllHoursToPhaseMember,
          label: (
            <StyledDropdownItem>
              <span>
                <PlusCircleIcon
                  pathStroke={theme.colors.colorMediumGray9}
                  stroke={theme.colors.colorMediumGray9}
                  width={14}
                  height={18}
                />
              </span>
              <span className="label">Assign Phase Hours to Member</span>
            </StyledDropdownItem>
          ),
          subMenu: undefined
        },
        {
          name: MemberMenuOptions.OpenAssignToPhasesDropdown,
          label: (
            <StyledDropdownItem>
              <span>
                <PlusCircleIcon
                  pathStroke={theme.colors.colorMediumGray9}
                  stroke={theme.colors.colorMediumGray9}
                  width={14}
                  height={18}
                />
              </span>
              <span className="label">Assign to Multiple Phases</span>
            </StyledDropdownItem>
          ),
          subMenu: undefined
        },
        // {
        //   name: MemberMenuOptions.ViewAvailability,
        //   label: (
        //     <StyledDropdownItem>
        //       <span>
        //         <TwoStackIcon width={14} height={18} />
        //       </span>
        //       <span className="label">View Availability</span>
        //     </StyledDropdownItem>
        //   ),
        //   subMenu: undefined
        // },
        {
          name: MemberMenuOptions.ViewOrgChart,
          label: (
            <StyledDropdownItem>
              <span>
                <OrgIcon width={14} height={18} />
              </span>
              <span className="label">View Org Chart</span>
            </StyledDropdownItem>
          ),
          subMenu: undefined
        }
      ]
    };
  }, []);

  const AssignToPhasesDropdown = useCallback(() => {
    return assignToPhasesDropdownOpen ? (
      <PhasesDropdown
        target={assignToPhasesDropdownRef}
        open={assignToPhasesDropdownOpen}
        handleDone={handleAssignToPhases}
        toggle={toggleAssignToPhasesDropdown}
        initialSelectedPhases={[phaseId]} // Spec: Auto-selected the current phase
      />
    ) : (
      <></>
    );
  }, [
    handleAssignToPhases,
    phaseId,
    assignToPhasesDropdownOpen,
    assignToPhasesDropdownRef,
    toggleAssignToPhasesDropdown
  ]);

  const onClickHash = useMemo(() => {
    const value = {
      [MemberMenuOptions.AssignAllHoursToPhaseMember]:
        handleAssignAllHoursToPhaseMember,
      [MemberMenuOptions.MemberProfile]: handleOpenMemberProfile,
      [MemberMenuOptions.ViewAvailability]: handleViewAvailability,
      [MemberMenuOptions.ViewOrgChart]: handleViewOrgChart,
      [MemberMenuOptions.OpenAssignToPhasesDropdown]: openAssignToPhasesDropdown
    };

    return value;
  }, [
    handleAssignAllHoursToPhaseMember,
    handleOpenMemberProfile,
    handleViewAvailability,
    handleViewOrgChart,
    openAssignToPhasesDropdown
  ]);

  const MemberOptionMenu = useCallback(() => {
    return (
      <MultiLevelDropdown
        menuContent={menuContent}
        onClickHash={onClickHash}
        openMethod={MOUSE_EVENTS.CLICK}
      />
    );
  }, [MultiLevelDropdown, menuContent, onClickHash]);

  return {
    MemberOptionMenu,
    menuRef: dropdownRef,
    assignToPhasesDropdownRef,
    openMenu: openDropdown,
    closeMenu: closeDropdown,
    AssignToPhasesDropdown
  };
};

const StyledDropdownItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .label {
    padding: 0 20px 0 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
`;
