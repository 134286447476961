import type {
  LeanApiSchema,
  FetchLeanApiSuccessResponse,
  LeanApiSchemaBuilder,
  ValueCounts
} from 'LeanApiModule/types';
import {
  FIELD_NAMES,
  FIELD_RENAME_MAP,
  ORDER_BY
} from 'LeanApiModule/constants';
import {
  buildTeamMembershipWhereClause,
  defaultTeamMembershipWhereClauses,
  buildTeamMembershipSelectClause,
  teamMembershipSelectClauses,
  buildTeamMembershipOrderClause,
  formatTeamMembershipOrderClause
} from './utils';
import { SortDirection } from 'FilterModule/constants';
import merge from 'lodash/merge';

// NOTE: currently no pagination for team_membership schemas

export type BaseGroupedMemberResponse<
  RecordFields extends Record<string, unknown> = Record<never, never>,
  ValueCountsFields extends Record<string, unknown> = Record<never, never>
> = PickAndRequire<
  FetchLeanApiSuccessResponse<
    {
      [groupEntityId: string]: Array<
        {
          [FIELD_RENAME_MAP.account_id]: number;
        } & RecordFields
      >;
    },
    ValueCounts & ValueCountsFields
  >,
  'records' | 'session_id' | 'value_counts'
>;

/* -------------------------- Grouped by Department ------------------------- */

export const membersByDepartmentSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText,
    initialValue: defaultTeamMembershipWhereClauses.department
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.departmentId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.DEPARTMENT_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.department_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersByDepartmentResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.work_group_memberships]: Array<{
      [FIELD_RENAME_MAP.department_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.department_id]: { [departmentId: string]: number };
  }
>;

/* --------------------------- Grouped by Position -------------------------- */

export const membersByPositionSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText,
    initialValue: defaultTeamMembershipWhereClauses.position
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.positionId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: merge(
      formatTeamMembershipOrderClause({
        orderBy: ORDER_BY.DEFAULT_POSITION,
        direction: SortDirection.asc
      }),
      formatTeamMembershipOrderClause({
        orderBy: ORDER_BY.POSITION_NAME,
        direction: SortDirection.asc
      })
    )
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.position_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersByPositionResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.team_positions]: Array<{
      [FIELD_RENAME_MAP.position_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.position_id]: { [positionId: string]: number };
  }
>;

/* --------------------------- Grouped by Skill -------------------------- */

export const membersBySkillSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText,
    initialValue: defaultTeamMembershipWhereClauses.skill
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.skillId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.SKILL_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.skill_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersBySkillResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.team_member_skills]: Array<{
      [FIELD_RENAME_MAP.skill_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.skill_id]: { [skillId: string]: number };
  }
>;

/* --------------------------- Grouped by Office -------------------------- */

export const membersByOfficeSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.officeId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.OFFICE_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.office_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersByOfficeResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.office_entities]: Array<{
      [FIELD_RENAME_MAP.office_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.office_id]: { [officeId: string]: number };
  }
>;

/* --------------------------- Grouped by Region -------------------------- */

export const membersByRegionSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.regionId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.REGION_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.region_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersByRegionResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.region_entities]: Array<{
      [FIELD_RENAME_MAP.region_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.region_id]: { [regionId: string]: number };
  }
>;

/* --------------------------- Grouped by Discipline -------------------------- */

export const membersByDisciplineSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.disciplineId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.DISCIPLINE_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.discipline_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersByDisciplineResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.discipline_entities]: Array<{
      [FIELD_RENAME_MAP.discipline_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.discipline_id]: { [disciplineId: string]: number };
  }
>;

/* --------------------------- Grouped by Portfolio -------------------------- */

export const membersByPortfolioSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText
  });

  const select = buildTeamMembershipSelectClause({
    filterParams,
    initialValue: teamMembershipSelectClauses.boardId
  });

  const order = buildTeamMembershipOrderClause({
    initialValue: formatTeamMembershipOrderClause({
      orderBy: ORDER_BY.PORTFOLIO_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.team_membership]: [FIELD_RENAME_MAP.board_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type MembersByPortfolioResponse = BaseGroupedMemberResponse<
  {
    [FIELD_NAMES.board_memberships]: Array<{
      [FIELD_RENAME_MAP.board_id]: Nullable<number>;
    }>;
  },
  {
    [FIELD_RENAME_MAP.board_id]: { [boardId: string]: number };
  }
>;

/* ------------------------------- No grouping ------------------------------ */

export const allMembersSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildTeamMembershipWhereClause({
    filterParams,
    searchText
  });

  const select = buildTeamMembershipSelectClause({
    filterParams
  });

  const order = buildTeamMembershipOrderClause();

  const schema: LeanApiSchema = {
    entity_type: 'team_membership',
    where,
    select,
    order,
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type AllMembersResponse = PickAndRequire<
  FetchLeanApiSuccessResponse<
    Array<{
      [FIELD_RENAME_MAP.account_id]: number;
    }>
  >,
  'records' | 'session_id' | 'value_counts'
>;
