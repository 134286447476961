import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface BudgetIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  currentColor?: string;
  className?: string;
}

export const BudgetIcon = ({
  currentColor = '#4F4F4F',
  width = '18',
  height = '18',
  className
}: BudgetIconProps) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="transparent"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.8C13.3078 16.8 16.8 13.3078 16.8 9C16.8 4.69218 13.3078 1.2 9 1.2C4.69218 1.2 1.2 4.69218 1.2 9C1.2 13.3078 4.69218 16.8 9 16.8ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill={currentColor}
      />
      <path
        d="M12 10.6049C12 11.1468 11.7746 11.5951 11.3238 11.9498C10.8729 12.3005 10.2431 12.5216 9.43425 12.6133V14H8.57901V12.6671C8.08398 12.6671 7.60442 12.6332 7.14033 12.5655C6.67624 12.4937 6.29613 12.3961 6 12.2726V11.3401C6.36685 11.4875 6.78895 11.6091 7.2663 11.7047C7.74807 11.7964 8.18564 11.8422 8.57901 11.8422V9.21219C7.67293 8.95318 7.03646 8.65232 6.66961 8.30962C6.30718 7.96693 6.12597 7.52461 6.12597 6.98267C6.12597 6.46065 6.34917 6.03228 6.79558 5.69755C7.24641 5.36282 7.84088 5.15959 8.57901 5.08787V4H9.43425V5.07591C10.2475 5.09584 11.032 5.24328 11.7878 5.51823L11.4431 6.30125C10.7845 6.06615 10.1149 5.92668 9.43425 5.88284V8.47699C10.1282 8.67623 10.6475 8.87149 10.9923 9.06276C11.337 9.25005 11.5912 9.46722 11.7547 9.71429C11.9182 9.95736 12 10.2542 12 10.6049ZM10.8729 10.6826C10.8729 10.3957 10.7735 10.1646 10.5746 9.98924C10.3801 9.80992 10 9.6326 9.43425 9.45726V11.7824C10.3934 11.6629 10.8729 11.2963 10.8729 10.6826ZM7.24641 6.97071C7.24641 7.27356 7.34586 7.51664 7.54475 7.69994C7.74365 7.88324 8.0884 8.05658 8.57901 8.21996V5.90675C8.14144 5.97051 7.80994 6.09603 7.58453 6.28332C7.35912 6.46663 7.24641 6.69576 7.24641 6.97071Z"
        fill={currentColor}
      />
    </SvgIcon>
  );
};

export default BudgetIcon;
