import { createSelector } from 'reselect';
import { getCurrentUserId, getUserIsAdmin } from './user';
import { getPlannerMembersInfo } from './plannerMembersInfo';
import { initialState as homeInitialState } from 'reducers/home';
import {
  initialState as projectsInitialState,
  filterListInitialState
} from 'reducers/projects';
import keyBy from 'lodash/keyBy';

const emptyArray = [];
const emptyObj = {};
const byId = (item) => item && item.id;

const getHomeState = (state) => state.home || homeInitialState;

/**
 * @deprecated Use the typesafe version from `ProjectsModule/selectors`.
 */
export const getProjectsState = (state) =>
  state.projects || projectsInitialState;

export const getProjectSearch = createSelector(
  (state) => state.search.projects,
  getUserIsAdmin,
  (projects, userIsAdmin) =>
    userIsAdmin
      ? projects
      : projects.filter((project) => !project.is_administrative)
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/selectors`.
 */
export const getOwnProjectId = (state, ownProps) =>
  ownProps && ownProps.projectId;

/**
 * @deprecated Use the typesafe version from `ProjectsModule/selectors`.
 */
export const getProjectHash = createSelector(
  getProjectsState,
  (state) => state.projectHash
);

/**
 * @deprecated — Use the typesafe version from ProjectsModule/selectors.
 */
export const getCurrentUserPlannerMemberProjectIds = createSelector(
  getCurrentUserId,
  getPlannerMembersInfo,
  (accountId, memberInfo) => memberInfo?.[accountId] ?? emptyArray
);

/**
 * @deprecated — Use the typesafe version from ProjectsModule/selectors.
 */
export const getMemberProjectsArray = createSelector(
  getCurrentUserPlannerMemberProjectIds,
  getProjectHash,
  (projectIds, projectsHash) => {
    return (projectIds.map((id) => projectsHash[id]) || emptyArray).filter(
      (project) => project && !project.is_personal
    );
  }
);

/**
 * @deprecated — Use the typesafe version from ProjectsModule/selectors.
 */
export const getMemberProjectsHash = createSelector(
  getMemberProjectsArray,
  (memberProjects) => keyBy(memberProjects, byId)
);

export const cachedProjects = createSelector(
  getProjectsState,
  (state) => state.cachedProjects
);

export const getProjectItemState = createSelector(
  getProjectsState,
  (state) => state.projectItemState
);
export const getEditingProjectId = createSelector(
  getProjectsState,
  (state) => state.editingProjectID
);
export const getIsCreatingProject = createSelector(
  getProjectsState,
  (state) => state.isCreatingProject
);
export const getIsUpdatingProjectInfo = createSelector(
  getProjectsState,
  (state) => state.isUpdatingProjectInfo
);
export const getIsCreatingOrUpdatingProject = createSelector(
  getIsCreatingProject,
  getIsUpdatingProjectInfo,
  (isCreatingProject, isUpdatingProjectInfo) =>
    isCreatingProject || isUpdatingProjectInfo
);
export const getIsActive = createSelector(
  getProjectsState,
  (state) => state.isActive
);

/**
 * @deprecated — Use the typesafe version from ProjectsModule/selectors.
 */
export const getSelectedProject = createSelector(
  getProjectsState,
  (state) => state.selectedProject[0]
);

export const getSelectedProjectAccountsByProjectMembershipId = createSelector(
  getSelectedProject,
  (selectedProject) =>
    keyBy(
      selectedProject?.project_membership,
      (projectMembership) => projectMembership.id
    )
);
export const getProjectActivity = (state) =>
  state.projectActivityScores || emptyObj;
export const getSelectedProjectId = createSelector(getProjectsState, (state) =>
  state.selectedProject[0] ? state.selectedProject[0].id : null
);

export const getProjectActivityScores = createSelector(
  getProjectActivity,
  (state) => state.projects
);

export const getAllFetchedProjectsArray = createSelector(
  getProjectsState,
  getHomeState,

  (projects, home) =>
    home.allProjectsOrder.map((id) => projects.projectHash[id])
);
export const getSidebarProjects = createSelector(
  getAllFetchedProjectsArray,
  getUserIsAdmin,
  (projects, userIsAdmin) =>
    userIsAdmin
      ? projects
      : projects.filter((project) => !project.is_administrative)
);
export const getAllFetchedProjectIdsHash = createSelector(
  (state) => state.home.allProjectsOrder,
  (allProjectsOrder) => keyBy(allProjectsOrder)
);
export const getAllFetchedProjects = createSelector(
  getProjectsState,
  (state) => state.projectHash
);
export const getAllFetchedProjectsLength = createSelector(
  getAllFetchedProjectsArray,
  (projects) => projects.length
);

export const makeGetProjectBasicInfoById = (getters = emptyObj) =>
  createSelector(
    getProjectHash,
    getters.projectIdGetter || getOwnProjectId,
    (projects, projectId) => projects[projectId]
  );

/**
 * @deprecated Use the typesafe version from `ProjectsModule/selectors`.
 */
export const makeGetProjectById = (getters = emptyObj) =>
  createSelector(
    getProjectHash,
    getters.projectIdGetter || getOwnProjectId,
    (projects, projectId) => projects[projectId]
  );

export const getOOOProject = createSelector(getProjectHash, (projects) =>
  Object.values(projects).find(
    (project) => project.is_administrative && project.administrative_flag === 1
  )
);

export const getWFHProject = createSelector(getProjectHash, (projects) =>
  Object.values(projects).find(
    (project) => project.is_administrative && project.administrative_flag === 2
  )
);

export const getSelectedProjectIds = createSelector(
  getProjectsState,
  (state) => state.selectedProjectIds
);

export const getProjectFilterLists = createSelector(
  getProjectsState,
  (state) => state.filterLists
);

export const getOwnProjectFilterListId = (state, ownProps) =>
  ownProps.projectFilterListId;

const makeGetProjectFilterList = () =>
  createSelector(
    getProjectFilterLists,
    getOwnProjectFilterListId,
    (projectFilterLists, projectFilterListId) =>
      projectFilterLists[projectFilterListId] || filterListInitialState
  );

export const makeGetFilterProjectsFetching = () =>
  createSelector(
    makeGetProjectFilterList(),
    (projectFilterList) => projectFilterList.filterProjectsFetching
  );

export const makeGetFilterProjectsOffset = () =>
  createSelector(
    makeGetProjectFilterList(),
    (projectFilterList) => projectFilterList.filterProjectsOffset
  );

export const makeGetFilterProjectsCount = () =>
  createSelector(
    makeGetProjectFilterList(),
    (_, ownProps) => ownProps.isPtoSelectable,
    // VirtualFilter fetches administrative projects which are OOO and WFH.
    // we only use OOO when isPtoSelectable is ture so subtract 1 from the count to discard WFH from counted.
    // this is temporary until we get the feature for PTO in the future
    (projectFilterList, isPtoSelectable) =>
      projectFilterList.projectCount - (isPtoSelectable ? 1 : 0)
  );

export const makeGetFilterProjectsGroupCounts = () =>
  createSelector(
    makeGetProjectFilterList(),
    (projectFilterList) => projectFilterList.projectGroupCounts
  );

/** Returns orders of project IDs by board ID */
export const makeGetFilterProjectOrdersByBoard = () =>
  createSelector(
    makeGetProjectFilterList(),
    (projectFilterList) => projectFilterList.projectsByBoard
  );

/**
 * Selector returns array of ungrouped projects for a projectFilterList
 */
export const makeGetFilterProjectOrder = () =>
  createSelector(
    makeGetProjectFilterList(),
    (projectFilterList) => projectFilterList.projectOrder
  );

export const getTotalProjectCountsByAccountId = createSelector(
  getProjectsState,
  (state) => state.totalProjectCountsByAccountId
);

export const getProjectsByAccountId = createSelector(
  getProjectsState,
  (state) => state.projectsByAccountId
);

export const getFetchedProjectIds = createSelector(
  getProjectsState,
  (state) => state.fetchedProjectIds
);

export const addModalProjectRedirectOnSuccess = createSelector(
  getProjectsState,
  (state) => state.addModalProjectRedirectOnSuccess
);

export const addModalProjectSuccessCallback = createSelector(
  getProjectsState,
  (state) => state.addModalProjectSuccessCallback
);
