import { createRequestTypes } from 'appUtils';

export const FETCH_SCOPES = createRequestTypes('FETCH_SCOPES');
export const CREATE_SCOPE = createRequestTypes('CREATE_SCOPE');
export const UPDATE_SCOPE = createRequestTypes('UPDATE_SCOPE');
export const DELETE_SCOPE = createRequestTypes('DELETE_SCOPE');
export const MOVE_SCOPES = createRequestTypes('MOVE_SCOPES');
export const UPDATE_SCOPE_POSITION = createRequestTypes(
  'UPDATE_SCOPE_POSITION'
);
export const ASSIGN_SCOPE_MEMBERS = createRequestTypes('ASSIGN_SCOPE_MEMBERS');
export const UNASSIGN_SCOPE_MEMBERS = createRequestTypes(
  'UNASSIGN_SCOPE_MEMBERS'
);
export const UPDATE_SCOPE_MEMBERSHIP = createRequestTypes(
  'UPDATE_SCOPE_MEMBERSHIP'
);

export const DELETE_SCOPE_ATTACHMENT = createRequestTypes(
  'DELETE_SCOPE_ATTACHMENT'
);

// FE only
export const SELECT_SCOPES = 'SELECT_SCOPES';
export const CLEAR_SELECTED_SCOPES = 'CLEAR_SELECTED_SCOPES';
export const SELECT_ALL_SCOPES = 'SELECT_ALL_SCOPES';
export const SELECT_ALL_GROUPS_SCOPES = 'SELECT_ALL_GROUPS_SCOPES';
