import produce from 'immer';
import get from 'lodash/get';
import * as constants from 'appConstants';

const initialState = {
  loading: false,
  teamTaskTags: [],
  personalTaskTags: []
};

const homeTaskTags = (state = initialState, action) =>
  produce(state, (draft) => {
    const mergeTaskTags = (updatedTask) => {
      const taskType = get(updatedTask, 'project.is_personal') ? 0 : 1;
      const taskTags = updatedTask.tags;

      if (!taskTags || (taskTags && !taskTags.length)) {
        return;
      }

      if (taskType === 0) {
        draft.personalTaskTags = Object.values(
          [...draft.personalTaskTags, ...updatedTask.tags].reduce(
            (acc, cur) => {
              acc[cur.id] = cur;
              return acc;
            },
            {}
          )
        );
      } else {
        draft.teamTaskTags = Object.values(
          [...draft.teamTaskTags, ...updatedTask.tags].reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {})
        );
      }
    };

    switch (action.type) {
      case constants.LOGOUT_USER: {
        return initialState;
      }
      case constants.FETCH_ALL_HOME_TASK_TAGS.REQUEST:
        draft.loading = true;
        break;
      case constants.FETCH_ALL_HOME_TASK_TAGS.SUCCESS: {
        const { team_task_tags: teamTags, personal_task_tags: personalTags } =
          action.payload.response;
        draft.teamTaskTags = JSON.parse(teamTags);
        draft.personalTaskTags = JSON.parse(personalTags);
        draft.loading = false;
        break;
      }
      case constants.CREATE_TASK_FROM_HOME.SUCCESS: {
        const { response } = action.payload;
        mergeTaskTags(response.task);
        break;
      }
      case constants.UPDATE_TASKS_ATTRIBUTES.SUCCESS: {
        const {
          response: { tasks }
        } = action.payload;

        tasks.forEach(mergeTaskTags);
        break;
      }
      case constants.UPDATE_TAG.SUCCESS: {
        const { response } = action.payload;
        draft.teamTaskTags = draft.teamTaskTags.map((tag) =>
          tag.id === response.id ? response : tag
        );
        draft.personalTaskTags = draft.personalTaskTags.map((tag) =>
          tag.id === response.id ? response : tag
        );
        break;
      }
    }
  });

export default homeTaskTags;
