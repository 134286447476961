import {
  FetchLeanApiParams,
  FetchLeanApiSuccessResponse
} from 'LeanApiModule/types';
import { FIELD_NAMES } from 'LeanApiModule/constants';
import merge from 'lodash/merge';
import { PlannerPageViewFilter } from './types';

export const isPlannerPageViewFilterActiveByParts = (
  filter?: PlannerPageViewFilter
): { hasAccountsFilter: boolean; hasUnassignedOrAssignedFilter: boolean } => {
  if (!filter)
    return { hasAccountsFilter: false, hasUnassignedOrAssignedFilter: false };

  const filterRest = [
    filter.account_ids,
    filter.position_ids,
    filter.custom.discipline_ids,
    filter.custom.office_ids,
    filter.custom.work_group_ids
  ];

  return {
    hasAccountsFilter: Object.values(filterRest).some((ids) => ids?.length),
    hasUnassignedOrAssignedFilter:
      typeof filter.custom.isProjectsWithUnassignedRoles === 'boolean'
  };
};

export const isPlannerPageViewFilterActive = (
  filter: PlannerPageViewFilter
): boolean =>
  Object.values(isPlannerPageViewFilterActiveByParts(filter)).some(
    (isActive) => isActive
  );

export const buildPlannerPageViewFilterFetchParams = (
  pageViewFilter: PlannerPageViewFilter
): FetchLeanApiParams | undefined => {
  const {
    account_ids: accountIds,
    custom: {
      discipline_ids: disciplineIds,
      office_ids: officeIds,
      work_group_ids: workGroupIds
    },
    position_ids: positionIds
  } = pageViewFilter;

  const whereParts: NestedObject<unknown>[] = [];
  if (accountIds?.length)
    whereParts.push({ [FIELD_NAMES.account_id_in]: accountIds });
  if (disciplineIds?.length)
    whereParts.push({
      [FIELD_NAMES.discipline_entities]: {
        [FIELD_NAMES.discipline_id_in]: disciplineIds
      }
    });
  if (officeIds?.length)
    whereParts.push({
      [FIELD_NAMES.office_entities]: {
        [FIELD_NAMES.office_id_in]: officeIds
      }
    });
  if (positionIds?.length)
    whereParts.push({
      [FIELD_NAMES.account_positions]: {
        [FIELD_NAMES.position_id_in]: positionIds,
        [FIELD_NAMES.discarded_at]: null
      }
    });
  if (workGroupIds?.length)
    whereParts.push({
      [FIELD_NAMES.work_group_memberships]: {
        [FIELD_NAMES.work_group_id_in]: workGroupIds,
        [FIELD_NAMES.discarded_at]: null
      }
    });

  // `isProjectsWithUnassignedRoles` is handled in the
  // `makeGetPlannerPageViewFilterResults` selector. As a result, there may no
  // need for backend filtering.
  return whereParts.length
    ? {
        meta: {
          isoStateModifiers: {
            fetchLeanApiSuccess: ({ response: untypedResponse, isoState }) => {
              const response = untypedResponse as PickAndRequire<
                FetchLeanApiSuccessResponse<
                  Array<{
                    id: number;
                  }>
                >,
                'records' | 'session_id' | 'value_counts'
              >;

              isoState.topLevelOrder = response.records.map(({ id }) => id);
            }
          },
          isTakeLatest: true
        },
        schema: {
          all: true,
          entity_type: 'team_membership',
          exclude_value_counts: true,

          // Query
          select: { [FIELD_NAMES.account_id]: FIELD_NAMES.id },
          where: merge({}, ...whereParts)
        }
      }
    : undefined;
};
