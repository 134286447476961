import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { connect } from 'react-redux';

import 'rc-calendar/assets/index.css';
import enUS from 'rc-calendar/lib/locale/en_US';
import Calendar from 'rc-calendar';
import { CalendarClickoutListener } from 'views';
import { updateEditedTasks } from 'actionCreators';

class TaskDatePicker extends React.Component {
  renderSidebar = () => {
    // wrapper not allowed between RangeCalendar and Picker
    // therefore use 'sidebar' to render a click handling wrapper
    return (
      <CalendarClickoutListener
        key="sidebar"
        handleOutsideClick={this.props.closeCalendar}
      />
    );
  };

  render() {
    const {
      chooseNewDate,
      date,
      scheduleDate,
      isDailyTask,
      closeCalendar,
      mouseX,
      mouseY
    } = this.props;
    const container = document.querySelector('#modal');
    container.style.left = `${mouseX}px`;
    container.style.top = `${mouseY}px`;
    container.style.right = ``;
    return ReactDOM.createPortal(
      <div
        className={cn('date-picker-flyout', {
          'task-date-chosen': date
        })}
        onBlur={() => {
          if (isDailyTask) closeCalendar();
        }}
      >
        <Calendar
          className={cn({
            calendar: true,
            'task-calendar': true
          })}
          renderSidebar={this.renderSidebar}
          onSelect={chooseNewDate}
          locale={enUS}
          defaultValue={scheduleDate || null}
          showDateInput={false}
          showOk={true}
        />
      </div>,
      container
    );
  }
}

const mapStateToProps = (state) => ({
  isBulkUpdateModalOpen: state.homePlanner.bulkUpdateModal.isOpen
});

const mapDispatchToProps = { updateEditedTasks };

export default connect(mapStateToProps, mapDispatchToProps)(TaskDatePicker);
