import {
  ComponentProps,
  useState,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import { WorkPlanGroup } from 'TimelinesModule/types/workPlanGroup';
import { MemberGroup } from 'TimelinesModule/types/memberGroup';
import { BaseTimelineProps } from 'TimelinesModule/types/timeline';
import { WorkPlanItem } from 'TimelinesModule/types/WorkPlanItem';
import { MemberGroupRenderer } from '../../GroupRenderers/MemberGroupRenderer/MemberGroupRenderer';
import { AvailabilityRowRenderer } from '../../RowRenderers/AvailabilityRowRenderer';
import { BaseTimeline } from '../BaseTimeline';
import {
  UtilizationBreakdownsByGroup,
  WorkFromHomesByGroup
} from '../../RowRenderers/types';
import {
  COLOR_SCALE_COLORS_DEFAULT,
  COLOR_SCALE_MINIMA_DEFAULT
} from 'components/ColorScaleModal/constants';
import {
  buildColorScaleIntervals,
  buildIntervalsWithMaxima
} from 'components/ColorScaleModal/utils';
import { PlaceholderRowRenderer } from '../../RowRenderers/PlaceholderRowRenderer';
import { EmptyGroupRenderer } from '../../GroupRenderers/EmptyGroupRenderer';
import { PlaceholderGroup } from 'TimelinesModule/types/placeholderGroup';
import { TIMELINE_GROUP_TYPES } from '../../constants';
import { EmptyGroup } from 'TimelinesModule/types/emtpyGroup';
import {
  generateEmptyGroups,
  serializeGroupId
} from 'TimelinesModule/utils/groupMapperUtils';
import { MemberAvailabilityTimelineConfig } from './types';
import { WorkPlanItemRenderer } from 'TimelinesModule/components/ItemRenderers/WorkPlanItemRenderer/WorkPlanItemRenderer';
import { serializeId } from 'appUtils';

type AvailableGroups =
  | MemberGroup
  | WorkPlanGroup
  | PlaceholderGroup
  | EmptyGroup;

type MemberAvailabilityTimelineBaseProps = BaseTimelineProps<
  WorkPlanItem,
  AvailableGroups
>;

type MemberAvilabilityTimelineProps = Pick<
  MemberAvailabilityTimelineBaseProps,
  | 'groups'
  | 'items'
  | 'initialVisibleTimeEnd'
  | 'initialVisibleTimeStart'
  | 'onItemMove'
  | 'onThrottledTimeChange'
  | 'sidebarHeader'
> & {
  utilizationBreakdownsByGroup?: UtilizationBreakdownsByGroup;
  workFromHomesByGroup?: WorkFromHomesByGroup;
  onGroupExpand: (group: MemberGroup) => void;
  onGroupCollapse: (group: MemberGroup) => void;
} & {
  timelineConfig?: Partial<MemberAvailabilityTimelineConfig>;
};

const emptyGroups = generateEmptyGroups({ count: 2 });

export const MemberAvailabilityTimeline = ({
  utilizationBreakdownsByGroup,
  workFromHomesByGroup,
  groups: groupsProp,
  items,
  onGroupExpand,
  onGroupCollapse,
  timelineConfig = {},
  ...rest
}: MemberAvilabilityTimelineProps) => {
  const [groups, setGroups] = useState<AvailableGroups[]>([
    ...groupsProp,
    ...emptyGroups
  ]);

  const {
    capacityHeatMapColors = COLOR_SCALE_COLORS_DEFAULT,
    capacityHeatMapIntervals = COLOR_SCALE_MINIMA_DEFAULT,
    showTimesheetTime,
    showMemberRole,
    showMemberCapacity
  } = timelineConfig;

  useEffect(() => {
    if (groupsProp) {
      setGroups([...groupsProp, ...emptyGroups]);
    }
  }, [groupsProp]);

  const handleItemClick: NonNullable<BaseTimelineProps['onItemClick']> =
    useCallback((item, event, time) => {
      //
    }, []);

  const handleRoleNameClick: NonNullable<
    ComponentProps<typeof MemberGroupRenderer>['onRoleNameClick']
  > = useCallback((event) => {
    // TODO: impelemnt actual click handler
    event.stopPropagation();
  }, []);

  const handleCapacityClick: NonNullable<
    ComponentProps<typeof MemberGroupRenderer>['onCapacityClick']
  > = useCallback((event) => {
    // TODO: impelemnt actual click handler
    event.stopPropagation();
  }, []);

  const heatmap = useMemo(
    () =>
      buildIntervalsWithMaxima(
        buildColorScaleIntervals({
          colors: capacityHeatMapColors,
          minima: capacityHeatMapIntervals
        })
      ),
    [capacityHeatMapColors, capacityHeatMapIntervals]
  );

  const handleGroupExpand: NonNullable<
    MemberAvailabilityTimelineBaseProps['onGroupExpand']
  > = useCallback(
    (expandedGroup) => {
      if (expandedGroup.type === TIMELINE_GROUP_TYPES.MEMBER) {
        const { id, accountId } = expandedGroup;
        onGroupExpand(expandedGroup);

        const workPlanGroupId = serializeGroupId({
          id: accountId,
          groupType: TIMELINE_GROUP_TYPES.WORK_PLAN
        });

        const workPlanGroup: WorkPlanGroup = {
          id: workPlanGroupId,
          title: '',
          type: TIMELINE_GROUP_TYPES.WORK_PLAN,
          parentId: expandedGroup.id
        };

        const placeholderGroupId = serializeId({
          id: accountId,
          itemType: TIMELINE_GROUP_TYPES.PLACEHOLDER,
          ids: undefined
        });

        const placeholderGroup: PlaceholderGroup = {
          id: placeholderGroupId,
          title: '',
          type: TIMELINE_GROUP_TYPES.PLACEHOLDER,
          parentId: id
        };

        setGroups((prev) =>
          prev.reduce<AvailableGroups[]>((acc, group) => {
            if (group.id === id) {
              acc.push(group);
              acc.push(workPlanGroup);
              acc.push(placeholderGroup);
            } else {
              acc.push(group);
            }
            return acc;
          }, [])
        );
      }
    },
    [onGroupExpand]
  );

  const handleGroupCollapse: NonNullable<
    MemberAvailabilityTimelineBaseProps['onGroupCollapse']
  > = useCallback(
    (collpasedGroup) => {
      if (collpasedGroup.type === TIMELINE_GROUP_TYPES.MEMBER) {
        onGroupCollapse(collpasedGroup);
        setGroups((prev) =>
          prev.filter((group) => {
            if ('parentId' in group) {
              return group.parentId !== collpasedGroup.id;
            }
            return true;
          })
        );
      }
    },
    [onGroupCollapse]
  );

  const groupRenderersByType = useMemo(() => {
    const memberGroupRenderer: typeof MemberGroupRenderer = (props) => (
      <MemberGroupRenderer
        {...props}
        showMemberRole={showMemberRole}
        showMemberCapacity={showMemberCapacity}
        onRoleNameClick={handleRoleNameClick}
        onCapacityClick={handleCapacityClick}
      />
    );

    return {
      member: memberGroupRenderer,
      workPlan: EmptyGroupRenderer,
      placeholder: EmptyGroupRenderer
    };
  }, [
    handleCapacityClick,
    handleRoleNameClick,
    showMemberCapacity,
    showMemberRole
  ]);

  const rowRenderersByType = useMemo(() => {
    const availabilityRowRenderer: typeof AvailabilityRowRenderer = (props) => {
      const utilizationBreakdownByDate =
        utilizationBreakdownsByGroup?.[props.group.id];
      const workFromHomeByDate = workFromHomesByGroup?.[props.group.id];
      return (
        <AvailabilityRowRenderer
          {...props}
          workFromHomeByDate={workFromHomeByDate}
          heatmap={heatmap}
          showTimesheetTime={showTimesheetTime}
          utilizationBreakdownsByDate={utilizationBreakdownByDate}
        />
      );
    };

    const placeholderRowRenderer: typeof PlaceholderRowRenderer = (props) => {
      const utilizationBreakdownByDate =
        utilizationBreakdownsByGroup?.[props.group.parentId];
      return (
        <PlaceholderRowRenderer
          {...props}
          utilizationBreakdownsByDate={utilizationBreakdownByDate}
        />
      );
    };

    return {
      member: availabilityRowRenderer,
      workPlan: placeholderRowRenderer,
      placeholder: placeholderRowRenderer
    };
  }, [
    heatmap,
    showTimesheetTime,
    utilizationBreakdownsByGroup,
    workFromHomesByGroup
  ]);

  const itemRenderersByType = useMemo(() => {
    const workPlanItemRenderer: typeof WorkPlanItemRenderer = (props) => {
      return <WorkPlanItemRenderer {...props} />;
    };

    return {
      workPlan: workPlanItemRenderer
    };
  }, []);

  return (
    <BaseTimeline
      {...rest}
      groups={groups}
      items={items}
      onItemClick={handleItemClick}
      groupRenderersByType={groupRenderersByType}
      rowRenderersByType={rowRenderersByType}
      onGroupExpand={handleGroupExpand}
      onGroupCollapse={handleGroupCollapse}
      itemRenderersByType={itemRenderersByType}
      // current base timeline does not support updating items
      timelineConfig={{
        readOnly: true
      }}
    />
  );
};
