import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PriorityIcon = ({ width = '17', height = '17', className }) => (
  <SvgIcon
    className={className}
    viewBox="0 0 17 17"
    fill="none"
    width={width}
    height={height}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M5.30933 3.50201L5.30944 3.502L5.30774 3.49133C5.23245 3.01902 5.53265 2.46726 6.18111 1.98088C6.81401 1.50616 7.63275 1.21387 8.26164 1.21387H8.30979C8.95175 1.22194 9.77114 1.51637 10.4001 1.98756C11.0453 2.47093 11.3426 3.01841 11.2674 3.49158L11.2673 3.49156L11.2659 3.50201L10.1957 11.2954C10.1956 11.2954 10.1955 11.2957 10.1954 11.2961C10.1927 11.3033 10.1712 11.3601 10.054 11.4564C9.92875 11.5592 9.74447 11.6646 9.52052 11.7601C9.06451 11.9544 8.55922 12.06 8.29624 12.06H8.27898C8.01638 12.06 7.51127 11.9544 7.0552 11.7601C6.83124 11.6646 6.64688 11.5592 6.52152 11.4563C6.40391 11.3598 6.38246 11.3028 6.37978 11.2957C6.37963 11.2953 6.37954 11.295 6.37949 11.295L5.30933 3.50201Z"
        fill="transparent"
        stroke="#808080"
      />
      <path
        d="M9.54751 15.2627C9.54751 15.954 8.98722 16.5139 8.29634 16.5139C7.60545 16.5139 7.04517 15.954 7.04517 15.2627C7.04517 14.5722 7.60532 14.0122 8.29634 14.0122C8.98736 14.0122 9.54751 14.5722 9.54751 15.2627Z"
        fill="transparent"
        stroke="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="17" fill="transparent" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PriorityIcon;
