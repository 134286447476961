import theme from 'theme';
import styled from 'styled-components';
import { MemberGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const NoMatchCell = ({ row }: MemberGroupRowProps) => {
  return <StyledNoMatchContainer>No Matches</StyledNoMatchContainer>;
};

export default NoMatchCell;

const StyledNoMatchContainer = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  padding-left: 24px;
`;
