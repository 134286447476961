import React from 'react';
import SvgIcon from 'components/SvgIcon';

const style = { cursor: 'grab' };

const DragGripIcon = ({ className = '' }) => (
  <SvgIcon
    className={className}
    style={style}
    width="12"
    height="10"
    fill="currentColor"
  >
    <path
      d="M12 0L0 0L0 0.851852L12 0.851852V0ZM12 3.07407L0 3.07407L0 3.92593L12 3.92593V3.07407ZM12 6.14815L0 6.14815L0 7L12 7V6.14815Z"
      fill="#808080"
    />
  </SvgIcon>
);

export default React.memo(DragGripIcon);
