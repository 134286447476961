import { useMemo } from 'react';
import styled from 'styled-components';
import {
  calculateColorPref,
  calculateBorderColorPreference
} from 'appUtils/styleUtils';
import { getDragTooltipDates } from 'appUtils/projectPlannerUtils';
import theme from 'theme';
import {
  getUserTheme,
  makeGetBoardById,
  getUserIsWorkloadManager
} from 'selectors';
import moment from 'moment';
import { connect } from 'react-redux';
import { getBudgetTooltipContent } from 'appUtils/budgetTooltipContent';
import { getProjectPhaseTooltipContent } from 'appUtils/projectPhaseTooltipContent';
import { getProjectMilestoneTooltipContent } from 'appUtils/projectMilestoneTooltipContent';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import MilestoneIcon from 'icons/WorkloadMilestoneIcon';
import {
  DEPENDENCY_STRINGS,
  CONDENSED_ZOOM_LEVELS,
  CONDENSED_VIEW_MILESTONE_PROPERTIES
} from 'appConstants/workload';
import { makeGetDefaultCurrencyCodeById } from 'CurrencyModule/selectors';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { BUDGET_BY } from 'BudgetModule/components/BudgetTable/constants';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const condensedZoomLevelBarHeights = {
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: '23px',
  [CONDENSED_ZOOM_LEVELS.SMALL]: '20px',
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: '5px'
};

const calcColor = {
  spent: theme.colors.colorBudgetGreen,
  planned: theme.colors.colorPlannedHours,
  remaining: theme.colors.colorBudgetLightGreen
};

const StyledDateContainer = styled.div`
  font-size: 10px;
  margin-top: 2px;
  color: ${theme.colors.colorTranslucentGray4};
  text-shadow: none;
`;
const StyledPhase = styled.div`
  white-space: nowrap;
  line-height: 1;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
`;

const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  height: 16px;
  width: 16px;
  margin-right: 5px;
  path {
    stroke: ${(props) =>
      props.isVerySmallView
        ? theme.colors.colorMediumGray9
        : theme.colors.colorPureWhite};
    fill: transparent;
  }
`;
const StyledBudgetPhaseMilestoneIcon = styled(BudgetPhaseMilestoneIcon)`
  height: 16px;
  width: 16px;
  margin-right: 5px;
  .milestone-icon-box {
    stroke: ${(props) =>
      props.isVerySmallView
        ? theme.colors.colorMediumGray9
        : theme.colors.colorPureWhite};
  }
  .dollar-sign-in-icon {
    fill: ${(props) =>
      props.isVerySmallView
        ? theme.colors.colorMediumGray9
        : theme.colors.colorPureWhite};
  }
`;

const StyledMilestoneBar = styled.div`
  background-color: ${calculateColorPref};
  border: 2px solid ${calculateBorderColorPreference};
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
  height: ${(props) =>
    props.isCondensedView
      ? condensedZoomLevelBarHeights[props.condensedZoomLevel]
      : '32px'};
  margin: ${(props) =>
    props.isCondensedView &&
    props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL &&
    !props.isOnTeamSchedules
      ? '8px 0'
      : props.isCondensedView &&
        props.isOnTeamSchedules &&
        props.condensedZoomLevel !== CONDENSED_ZOOM_LEVELS.VERY_SMALL
      ? '12px 0'
      : props.isCondensedView &&
        props.condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL &&
        !props.isOnTeamSchedules
      ? '20px 0'
      : '17px 0'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px 0px 8px;
  border-radius: 100px;
  &:hover {
    background: ${calculateBorderColorPreference};
  }
`;

const StyledPhaseBarContainer = styled.div`
  position: relative;
`;

const StyledSchedulePhaseNameContainer = styled.div`
  font-size: 10px;
  margin-top: 2px;
  color: ${theme.colors.colorTranslucentGray4};
  text-shadow: none;
`;

const StyledBudgetTimelineBar = styled.div`
  position: absolute;
  height: 8px;
  top: -10px;
  width: 100%;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  display: flex;
  border-radius: 100px;
`;
const BudgetTimeline = styled.div`
  height: 8px;
  display: flex;
  background: ${({ text }) => calcColor[text]};
  flex: ${({ flexWidth }) => flexWidth};
  border-radius: 100px;

  &:first-child {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  &:last-child {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
`;

const OverBudgetTimeline = styled(BudgetTimeline)`
  flex: auto;
  width: ${({ overdueWidth }) => overdueWidth}%;
  max-width: 100%;
  position: absolute;
  right: 0px;
  background: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 2px,
    #ff0000 4px
  );
`;
const noStyle = {
  borderColor: '',
  background: 'transparent',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer'
};
const draggingStyle = {
  borderColor: '',
  border: 'none',
  backgroundColor: `${theme.colors.colorIceBlue}`,
  cursor: 'grabbing'
  // background: 'transparent',
  // backgroundColor: 'transparent'
  // cursor: 'ew-resize'
};

const MilestoneIconContainer = styled.div`
  text-align: center;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`;

const DependencyCrossHatch = styled.div`
  position: absolute;
  width: 7px;
  max-width: 20%;
  min-width: 7px;
  height: 28px;
  background-image: repeating-linear-gradient(
    0,
    white 0 1px,
    transparent 1px 3px
  );
`;

const LeftDependencyCrossHatch = styled(DependencyCrossHatch)`
  left: 2px;
  clip-path: circle(14px at 14px 14px);
`;

const RightDependencyCrossHatch = styled(DependencyCrossHatch)`
  right: 2px;
  clip-path: circle(14px at -7px 14px);
`;

const StyledBarContent = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0px;
  overflow: hidden;
  margin-top: ${({ isVerySmallView }) => (isVerySmallView ? '-23px' : '')};
  color: ${({ isVerySmallView }) =>
    isVerySmallView ? theme.colors.colorMediumGray9 : ''};
  height: ${({ isVerySmallView }) => (isVerySmallView ? '14px' : '')};
`;

const getMilestoneTooltipContent = ({ item }) => {
  return getProjectMilestoneTooltipContent({
    startDate: item?.start_date_label,
    phaseName: item?.name
  });
};

const getPhaseTooltipContent = ({ item, projectColor, board }) => {
  const totals =
    item?.total_work_days &&
    `${item.remaining_work_days}/${item?.total_work_days} Workdays`;
  return getProjectPhaseTooltipContent({
    projectColor,
    projectName: item?.project?.title,
    projectDescription: item?.project?.description,
    startDate: item?.start_date_label,
    endDate: item?.end_date_label,
    isBudgetPhase: item?.is_budget,
    isDefaultPhase: item?.is_like_default,
    phaseName: item?.name,
    projectNumber: item?.project?.project_number,
    totals,
    boardName: board?.name
  });
};

const WorkloadPlannerMilestoneItemRenderer = (props) => {
  const {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    startDateRef,
    endDateRef,
    showBudget,
    userTheme,
    board,
    userIsWorkloadManager,
    isCondensedView,
    condensedZoomLevel,
    isOnTeamSchedules,
    hasStartDependency,
    hasEndDependency,
    showProjectTitle,
    defaultCurrencyCode
  } = props;

  const { isHoursOnly } = useIsHoursOnly();

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle: {
      position: 'absolute',
      width: '12px',
      maxWidth: '20%',
      minWidth: '2px',
      height: '100%',
      top: '0px',
      left: '4px',
      cursor: 'w-resize',
      zIndex: '88',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    rightStyle: {
      position: 'absolute',
      width: '12px',
      maxWidth: '20%',
      minWidth: '2px',
      height: '100%',
      top: '0px',
      right: '4px',
      cursor: 'e-resize',
      zIndex: '88',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  });

  const { totals = {}, budget_phase_by: budgetPhaseBy } = item;
  const spentCostOrHours =
    (isHoursOnly ? +totals.spent_hours : +totals.spent_cost) || 0;
  const plannedCostOrHours =
    (isHoursOnly ? +totals.planned_hours : +totals.planned_cost) || 0;
  const total =
    (isHoursOnly
      ? budgetPhaseBy === BUDGET_BY.MEMBER_TIME_BUDGET
        ? +totals.estimated_hours
        : +totals.total_estimated_hours
      : +totals.profit_calc_estimated_cost) || 0;

  const projectColor = calculateColorPref({
    styleId: item.project_id.split('--')[1],
    originType: 'project',
    theme: userTheme.theme
  });
  const budgetTooltipContent = useMemo(() => {
    return totals
      ? getBudgetTooltipContent({
          spent: spentCostOrHours,
          planned: plannedCostOrHours,
          total,
          projectColor,
          text: item.name,
          startDate: moment(item?.start_date_label).format('MMM D'),
          endDate: moment(item?.end_date_label).format('MMM D'),
          isWorkloadManager: userIsWorkloadManager,
          currencyCode: defaultCurrencyCode,
          isDisplayHours: isHoursOnly
        })
      : '';
  }, [
    totals,
    spentCostOrHours,
    plannedCostOrHours,
    total,
    projectColor,
    item.name,
    item?.start_date_label,
    item?.end_date_label,
    userIsWorkloadManager,
    defaultCurrencyCode,
    isHoursOnly
  ]);

  const showBudgetTooltip =
    (isHoursOnly && total) || (!isHoursOnly && totals.total);

  const tooltipDates =
    itemContext.dragging || itemContext.resizing
      ? getDragTooltipDates({ item, itemContext })
      : null;
  const isOverBudget = total < spentCostOrHours + plannedCostOrHours;
  const OverdueWidth =
    ((spentCostOrHours + plannedCostOrHours - total) * 100) / total;

  const phaseTooltipContent = useMemo(
    () =>
      getPhaseTooltipContent({
        item,
        projectColor,
        board
      }),
    [board, item, projectColor]
  );

  const MilestoneTooltipContent = useMemo(
    () =>
      getMilestoneTooltipContent({
        item
      }),
    [item]
  );

  const getPhaseBarContent = () => (
    <StyledBarContent
      isVerySmallView={
        isCondensedView &&
        condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
      }
      condensedZoomLevel={condensedZoomLevel}
    >
      {item?.is_budget ? (
        <StyledBudgetPhaseMilestoneIcon
          styleId={item.project_id.split('--')[1]}
          originType="project"
          theme={userTheme.theme}
          isVerySmallView={
            isCondensedView &&
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
          }
        />
      ) : (
        <StyledPhaseMilestoneIcon
          styleId={item.project_id.split('--')[1]}
          originType="project"
          theme={userTheme.theme}
          isVerySmallView={
            isCondensedView &&
            condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
          }
        />
      )}
      <StyledPhase>
        {item.is_like_default
          ? 'Project Schedule'
          : showProjectTitle
          ? item.project?.title
          : item.name}
        <div style={{ alignItems: 'center', display: 'flex' }}>
          {showProjectTitle && !isCondensedView && (
            <>
              <StyledSchedulePhaseNameContainer>
                {item.name}
              </StyledSchedulePhaseNameContainer>
              <div style={{ margin: '0px 5px' }}>|</div>
              <StyledDateContainer>
                {moment(item.start_date_label).format('MMM D ')} -
                {moment(item.end_date_label).format(' MMM D')}
              </StyledDateContainer>
            </>
          )}
          {!showProjectTitle && !isCondensedView && (
            <StyledDateContainer>
              {moment(item.start_date_label).format('MMM D ')} -
              {moment(item.end_date_label).format(' MMM D')}
            </StyledDateContainer>
          )}
        </div>
      </StyledPhase>
    </StyledBarContent>
  );

  return !item?.is_budget ? (
    <div
      {...getItemProps({
        ...item.itemProps,
        className: 'mosaic-milestone-bar',
        style: noStyle
      })}
      data-for="app-tooltip"
      data-tip={MilestoneTooltipContent}
      data-class={'project-bars-tooltips'}
      data-html
      data-type={'light'}
      data-delay-show={500}
    >
      <MilestoneIconContainer
        marginTop={
          isCondensedView
            ? CONDENSED_VIEW_MILESTONE_PROPERTIES[condensedZoomLevel].marginTop
            : 0
        }
      >
        {itemContext.dragging ||
          (itemContext.resizing && (
            <div
              ref={startDateRef}
              data-tip={moment(tooltipDates.startDate).format('MMM D')}
              data-for="start-date-tooltip"
              data-place="bottom"
              style={{ position: 'absolute', left: '50%', bottom: 0 }}
            ></div>
          ))}
        <MilestoneIcon
          height={
            isCondensedView
              ? CONDENSED_VIEW_MILESTONE_PROPERTIES[condensedZoomLevel].height
              : undefined
          }
          width={
            isCondensedView
              ? CONDENSED_VIEW_MILESTONE_PROPERTIES[condensedZoomLevel].width
              : undefined
          }
          color={projectColor}
        />
      </MilestoneIconContainer>
    </div>
  ) : (
    <div
      {...getItemProps({
        ...item.itemProps,
        className: 'mosaic-milestone-bar',
        style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
      })}
      data-for="app-tooltip"
      data-tip={
        !itemContext.dragging || !item.resizing
          ? showBudgetTooltip
            ? budgetTooltipContent
            : phaseTooltipContent
          : ''
      }
      data-html
      data-class={
        showBudgetTooltip ? 'phase-bar-budget-tooltip' : 'project-bars-tooltips'
      }
      data-type="light"
      data-delay-show={500}
    >
      {(itemContext.dragging || itemContext.resizing) && (
        <StyledMilestoneBar
          styleId={item.project_id.split('--')[1]}
          dragging
          originType="project"
          color={'#808080'}
          theme={userTheme.theme}
          isCondensedView={isCondensedView}
          condensedZoomLevel={condensedZoomLevel}
          isOnTeamSchedules={isOnTeamSchedules}
        >
          <div
            ref={startDateRef}
            data-tip={moment(tooltipDates.startDate).format('MMM D')}
            data-for="start-date-tooltip"
            data-place="bottom"
            style={{ position: 'absolute', left: 0, bottom: 0 }}
          />
          <div
            ref={endDateRef}
            data-tip={moment(tooltipDates.endDate).format('MMM D')}
            data-for="end-date-tooltip"
            data-place="bottom"
            style={{ position: 'absolute', right: 0, bottom: 0 }}
          />
          {getPhaseBarContent()}
        </StyledMilestoneBar>
      )}
      {!itemContext.dragging && !itemContext.resizing && (
        <StyledPhaseBarContainer>
          {showBudget && !isCondensedView && (
            <StyledBudgetTimelineBar
              isHidden={!isHoursOnly ? !totals.total : !total}
            >
              <BudgetTimeline flexWidth={spentCostOrHours} text="spent" />
              <BudgetTimeline flexWidth={plannedCostOrHours} text="planned" />
              {isOverBudget ? (
                <OverBudgetTimeline
                  overdueWidth={OverdueWidth}
                ></OverBudgetTimeline>
              ) : (
                <BudgetTimeline
                  flexWidth={totals.remaining_cost_of_estimated}
                  text="remaining"
                />
              )}
            </StyledBudgetTimelineBar>
          )}
          <StyledMilestoneBar
            color={'#808080'}
            originType="project"
            styleId={item.project_id.split('--')[1]}
            theme={userTheme.theme}
            isCondensedView={isCondensedView}
            condensedZoomLevel={condensedZoomLevel}
            isOnTeamSchedules={isOnTeamSchedules}
          >
            {item.dependencies.length > 0 && hasStartDependency ? (
              !isCondensedView && <LeftDependencyCrossHatch />
            ) : (
              <div {...leftResizeProps}>
                <div className="drag-handle-bar left" />
                <div className="drag-handle-bar left" />
              </div>
            )}
            {getPhaseBarContent()}
            {item.dependencies.length > 0 && hasEndDependency ? (
              !isCondensedView && <RightDependencyCrossHatch />
            ) : (
              <div {...rightResizeProps}>
                <div className="drag-handle-bar right" />
                <div className="drag-handle-bar right" />
              </div>
            )}
          </StyledMilestoneBar>
        </StyledPhaseBarContainer>
      )}
    </div>
  );
};

const boardIdGetter = (state, ownProps) => {
  return ownProps.item && ownProps.item.project?.board_id;
};
const makeMapStateToProps = () => {
  const getBoardById = makeGetBoardById({ boardIdGetter });
  const getDefaultCurrencyCode = makeGetDefaultCurrencyCodeById();
  const mapStateToProps = (state, ownProps) => ({
    board: getBoardById(state, ownProps),
    userTheme: getUserTheme(state),
    userIsWorkloadManager: getUserIsWorkloadManager(state),
    defaultCurrencyCode: ownProps.item?.project_id
      ? getDefaultCurrencyCode(state, {
          entityType: ENTITY_TYPES.project,
          entityId: ownProps.item?.project_id
        })
      : undefined
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  WorkloadPlannerMilestoneItemRenderer
);
