import { useState, useRef, useMemo, useCallback, MouseEvent } from 'react';
import styled from 'styled-components';
import Table from 'components/Table';
import useCollapse from 'appUtils/hooks/useCollapse';
import {
  FlatTableTemplateProps,
  GroupedTableTemplateProps,
  CommonTableTemplateProps,
  ColumnProps,
  RowToCells,
  RowDataWithGroupId,
  GroupData
} from './types';
import {
  ROW_TYPES,
  groupHeaderColumn,
  tableWidthColumn,
  leadingColumnProp
} from './tableUtils/layout';
import { StyledStickyHeader } from 'components/Table/TableStickyHeader';
import CollapseAllButton from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';
import { TabBlurb } from 'SettingsModule/components/StandardSettings/generic';
import { Styles } from 'components/Table/styles';
import { EmptyDiv } from './shared/EmptyDiv';
import { FooterRowCell } from './Cells/FooterRowCell';
import { LoadingRowCell } from './Cells/LoadingRowCell';
import { BaseFlatHeaderCell } from './Cells/BaseFlatHeaderCell';
import { LoadMoreRowCell } from './Cells/LoadMoreRowCell';
import { BaseGroupHeaderCell } from './Cells/BaseGroupHeaderCell';
import { DeleteIconContainer } from './Cells/MemberRowLeadingCell';
import { BaseGroupCollapseCell } from './Cells/BaseGroupCollapseCell';
import { InlineCreateGroupRowCell } from './Cells/InlineCreateGroupRowCell';
import useTableBuilder from 'appUtils/hooks/useTableBuilder';
import { useEmptyRowBuilder } from './tableUtils/useEmptyRowBuilder';
import { useGroupRowBuilder } from './tableUtils/useGroupRowBuilder';
import { useFooterRowBuilder } from './tableUtils/useFooterRowBuilder';
import { useMemberRowBuilder } from './tableUtils/useMemberRowBuilder';
import { useLoadingRowBuilder } from './tableUtils/useLoadingRowBuilder';
import { useLoadMoreRowBuilder } from './tableUtils/useLoadMoreRowBuilder';
import { useInlineCreateGroupRowBuilder } from './tableUtils/useInlineCreateGroupRowBuilder';

export type SettingTableTemplateProps<Data = any> = (
  | FlatTableTemplateProps<Data>
  | GroupedTableTemplateProps<Data>
) &
  CommonTableTemplateProps;

const ITEM_HEIGHT = 62;

export const SettingTableTemplate = <Data,>(
  props: SettingTableTemplateProps<Data>
) => {
  const {
    useGroup,
    columns,
    rowData,
    groupData,
    groupRowProps,
    footerRowProps,
    headerRowProps,
    memberRowProps,
    loadMore,
    isLoading
  } = props;

  const listRef = useRef(null);
  const untypedListRefCurrent = listRef.current as any;

  const [stickySection, setStickySection] = useState<string | undefined>();
  const [isCreatingGroup, setIsCreatingGroup] = useState<boolean>(false);

  const collapseCallback = useCallback(() => {
    if (untypedListRefCurrent) {
      untypedListRefCurrent?.resetAfterIndex(0);
    }
  }, [untypedListRefCurrent]);

  const {
    toggleCollapse,
    toggleCollapseAll,
    getIsOpen,
    collapseValues,
    allCollapsed
  } = useCollapse({
    topLevelCollapseCount: groupData?.length,
    toggleCallback: collapseCallback,
    defaultAllOpen: true
  });

  const columnsToUse = [
    leadingColumnProp,
    ...(columns ?? [])
  ] as Array<ColumnProps>;

  const rowToCells = columnsToUse.reduce(
    (acc, { id, rowCells }) => {
      acc.Header = {
        ...(acc.Header || {}),
        [id]: useGroup ? EmptyDiv : BaseFlatHeaderCell
      };
      Object.entries(rowCells).forEach(([rowKey, cellComponent]) => {
        acc[rowKey] = { ...(acc[rowKey] || {}), [id]: cellComponent };
      });
      return acc;
    },
    {
      inlineCreateGroupRow: {
        leadingColumn: EmptyDiv,
        tableWidth: InlineCreateGroupRowCell
      },
      groupRow: {
        leadingColumn: BaseGroupCollapseCell,
        groupHeader: BaseGroupHeaderCell
      },
      footerRow: {
        tableWidth: FooterRowCell
      },
      loadingRow: {
        leadingColumn: EmptyDiv,
        tableWidth: LoadingRowCell
      },
      loadMoreRow: {
        tableWidth: LoadMoreRowCell
      },
      emptyRow: {
        tableWidth: EmptyDiv
      }
    } as unknown as RowToCells
  );

  const columnWidthsWithTotal = columnsToUse.reduce((acc, { id, width }) => {
    acc[id] = width;
    acc.tableWidth = (acc.tableWidth || 0) + width;
    return acc;
  }, {} as Record<string & 'tableWidth', number>);

  const columnWidths = {
    ...columnWidthsWithTotal,
    leadingColumn: 40,
    groupHeader: columnWidthsWithTotal.tableWidth - 40
  };

  const tableColumns = [
    ...columnsToUse.map((item) => ({
      id: item.id,
      headerType: item.id,
      headerLabel: item.label,
      ...(item.columnProps ?? {})
    })),
    groupHeaderColumn,
    tableWidthColumn
  ];

  const getIsLoading = useCallback(
    (id?: number): boolean => {
      if (useGroup) {
        return Boolean(isLoading && id && isLoading[id]);
      } else {
        return Boolean(isLoading);
      }
    },
    [useGroup, isLoading]
  );

  // FIXME should use order data from props once it has custom order feature
  const groupOrders = groupData?.map((item) => item.id);

  const loadingRowBuilder = useLoadingRowBuilder();

  const loadMoreRowBuilder = useLoadMoreRowBuilder();

  const emptyRowBuilder = useEmptyRowBuilder();

  const footerRowBuilder = useFooterRowBuilder({ footerRowProps });

  const memberRowBuilder = useMemberRowBuilder({ memberRowProps });

  const inlineCreateGroupRowBuilder = useInlineCreateGroupRowBuilder({
    onAddGroupInline: groupRowProps?.onAddGroupInline
  });

  const groupRowBuilder = useGroupRowBuilder({
    groupOrders,
    isCreatingGroup,
    getIsLoading,
    getIsOpen,
    toggleCollapse,
    groupRowProps,
    memberRowBuilder,
    footerRowBuilder,
    emptyRowBuilder,
    loadingRowBuilder,
    loadMoreRowBuilder,
    inlineCreateGroupRowBuilder
  });

  const listItems = useMemo(
    () =>
      useGroup
        ? groupData &&
          groupRowBuilder({
            groupData,
            rowData: rowData as Array<RowDataWithGroupId<Data>>
          })
        : [
            ...memberRowBuilder({ rowData: rowData as any }),
            ...(getIsLoading() ? [loadingRowBuilder()] : []),
            emptyRowBuilder()
          ],
    [
      emptyRowBuilder,
      getIsLoading,
      groupData,
      groupRowBuilder,
      loadingRowBuilder,
      memberRowBuilder,
      rowData,
      useGroup
    ]
  );

  const mainList = useMemo(
    () => ({
      listItems: listItems || [],
      id: 'main',
      rowType: 'Header',
      skipHeader: true,
      isOpen: true
    }),
    [listItems]
  );

  const groupedLoadMoreItems = useCallback(
    ({
      groupData,
      lastItemIndex
    }: {
      groupData: GroupData;
      lastItemIndex: number;
    }) => {
      if (loadMore && !getIsLoading(groupData.id)) {
        loadMore({ groupData, lastItemIndex });
      }
    },
    [getIsLoading, loadMore]
  );

  const customRowProps = useMemo(
    () => ({
      groupedLoadMoreItems,
      useGroup,
      isCreatingGroup,
      setIsCreatingGroup
    }),
    [groupedLoadMoreItems, useGroup, isCreatingGroup, setIsCreatingGroup]
  );

  const {
    columns: cols,
    rows,
    handleScroll,
    stickyList,
    headerItem
  } = useTableBuilder({
    listRef,
    mainList,
    tableColumns,
    columnWidths,
    rowTypes: ROW_TYPES,
    rowToCells,
    customColumnWidths: undefined,
    customRowProps,
    stickySection,
    setStickySection
  });

  const stickyHeaderCells = rowToCells.groupRow;

  const stickyHeaderColumns = useMemo(() => {
    return stickyHeaderCells
      ? cols.filter((column) => stickyHeaderCells[column.headerType])
      : [];
  }, [cols, stickyHeaderCells]);

  const loadMoreItems = useCallback(
    (startIndex) => {
      const item = rows[startIndex - 1];
      if (item && props.loadMore && !getIsLoading() && !props.useGroup) {
        props.loadMore({ lastItemIndex: rowData.length - 1 });
      }
    },
    [getIsLoading, props, rowData.length, rows]
  );

  const handleClickAddGroupButton = (event: MouseEvent<HTMLButtonElement>) => {
    if (groupRowProps?.useInlineCreation === false) {
      headerRowProps?.buttonProps?.onClick &&
        headerRowProps?.buttonProps.onClick(event);
    } else {
      setIsCreatingGroup(true);
      untypedListRefCurrent?.scrollToItem(0);
    }
  };

  const getItemSize = useCallback(
    (index) => rows[index]?.itemHeight || ITEM_HEIGHT,
    [rows]
  );

  return (
    <RootContainer>
      <TableHeaderContainer>
        {useGroup && (
          <>
            {(groupData?.length ?? 0) > 0 && (
              <AllCollapseButtonContainer>
                <CollapseAllButton
                  onToggle={toggleCollapseAll}
                  isCollapsed={allCollapsed}
                  className="collapse-all"
                  data-testid="workplan-requests-collapse-all"
                />
              </AllCollapseButtonContainer>
            )}
            {headerRowProps?.buttonProps && (
              <AddGroupButton onClick={handleClickAddGroupButton}>
                + {headerRowProps.buttonProps?.label}
              </AddGroupButton>
            )}
          </>
        )}
        {headerRowProps?.blurbProps && (
          <TabBlurb>{headerRowProps.blurbProps.message}</TabBlurb>
        )}
      </TableHeaderContainer>
      <StyledSettingTableTemplate $hasHeader={!useGroup}>
        {/* {headerItem && stickyList && stickyList.isOpen && (
          <StickyHeader
            row={{ original: headerItem }}
            columnWidths={columnWidths}
            totalWidth={columnWidths['tableWidth']}
            cells={stickyHeaderCells}
            columns={cols}
            rowClassName={stickyList.rowType}
            backgroundColor={theme.colors.colorTranslucentGray4}
            customRowProps={undefined}
            isHidden={undefined}
            templateCols={undefined}
            marginTop={undefined}
            height={undefined}
          />
        )} */}
        <Table
          listRef={listRef}
          columns={cols}
          data={rows}
          itemHeight={ITEM_HEIGHT}
          getItemSize={getItemSize}
          maxHeight={window.innerHeight}
          handleScroll={handleScroll}
          loadMoreItems={useGroup ? undefined : loadMoreItems}
          customRowProps={customRowProps}
          virtual
          showHeader
          headersToIgnore={['tableWidth']}
          isVariableSizeTable
          columnMinWidth={0}
          columnMaxWidth={2000}
        />
      </StyledSettingTableTemplate>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSettingTableTemplate = styled.div<{ $hasHeader: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0; // will prevent table to exceed parent div

  ${Styles} {
    padding: 0;
    height: 100%;
  }

  .table {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tables-header-sticky {
    display: ${({ $hasHeader }) =>
      $hasHeader ? 'inline-flex !important' : 'none'};
    height: ${({ $hasHeader }) => ($hasHeader ? '20px' : '0')};
  }

  .table-body {
    height: calc(100% - 17px); // 17px = sticky header
  }

  .td {
    height: 100%;
    flex-shrink: 0;
    &.rightPadding {
      flex-shrink: 1;
    }
  }
  .table-column-header:not(.rightPadding):not(.leadingColumn) {
    border-bottom: 1px solid
      ${({ theme, $hasHeader }) =>
        $hasHeader ? theme.colors.colorBudgetGrey : 'transparent'};
  }

  .tr.groupRow {
    .td:not(.rightPadding):not(.leadingColumn) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorBudgetGrey};
    }
  }

  .tr.memberRow {
    .td:not(.rightPadding):not(.leadingColumn) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorLightGray21};
    }
  }

  .memberRow {
    &:hover {
      ${DeleteIconContainer} {
        opacity: 1;
      }
    }

    ${StyledStickyHeader} {
      padding: 0;
      top: 0px;
      .td.rightPadding {
        opacity: 0;
      }
    }
  }

  .variable-size-list {
    height: 100% !important;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  ${TabBlurb} {
    margin-left: 16px;
    width: unset;
    height: 37px;
  }
`;

const AllCollapseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`;

const AddGroupButton = styled.button`
  padding: 4px 8px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.colorCalendarBlue};
  border: 1px solid ${({ theme }) => theme.colors.colorCalendarBlue};
  border-radius: 5px;
  white-space: nowrap;
`;
