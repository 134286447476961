import { useCallback, useMemo, useEffect } from 'react';
import {
  ROW_TYPES,
  makeAddEntityRow,
  makeShowHiddenItemsRow,
  makeShowArchivedRow,
  makeBatchActionsRow,
  skeletonLoaderRow,
  noResultsRow,
  makeFilterListTypeSelectorRow,
  makeSearchInputRow
} from '../tableConfigs';
import {
  BaseTableListsBuilder,
  BaseTableListItem
} from 'components/Table/types';
import { FilterListType, FilterField } from 'FilterModule/constants';
import type { FilterListsTableProps } from '../FilterListsTable';
import type {
  FilterListTypeList,
  BaseGroupedFilterListsBuilder,
  GroupEntity
} from '../types';
import { useFilterListItemsBuilder, useGroupedListsBuilder } from '.';
import { isValuesOfFilterListTypeHookWithGroupings } from 'FilterModule/utils';

import { COLLAPSE_MODES } from 'appUtils/hooks/useNestedCollapse/useNestedCollapse';
import { useSetState } from 'react-use';

export const useFilterListTypeGroupedListsBuilder = ({
  filters,
  getIsOpen,
  toggleCollapse,
  toggleCollapseAll,
  filterValuesHash,
  skipHeaders,
  setParentCollapseState,
  getCurrentParentCollapseState,
  getFilterListTypeSelectorKey
}: {
  filters: FilterListType | FilterListType[];
  getIsOpen: (id: string | number) => boolean | undefined;
  toggleCollapseAll: (
    parentId: Nullable<string | number>,
    collapseOrExpand: 'collapse' | 'expand'
  ) => void;
  toggleCollapse: (
    id:
      | string
      | number
      | { parentId: string | number; toggleId: string | number }
  ) => void;
  filterValuesHash: FilterListsTableProps['filterValuesHash'];
  skipHeaders?: boolean;
  setParentCollapseState: any; // FIXME
  getCurrentParentCollapseState: any; // FIXME
  getFilterListTypeSelectorKey?: (
    filterListType?: FilterListType
  ) => FilterField | undefined;
}) => {
  const listItemsBuilder = useFilterListItemsBuilder();

  const [prevCollapseState, setPrevCollapseState] = useSetState({});

  const groupedListsBuilder = useGroupedListsBuilder({
    listItemsBuilder,
    isOff: false, // TODO,

    getIsOpen,
    toggleCollapse,
    setParentCollapseState
  });

  const filterListTypeToGroupedListsBuilder: Partial<
    Record<FilterListType, BaseGroupedFilterListsBuilder>
  > = useMemo(
    () => ({
      [FilterListType.MembersByDepartment]: groupedListsBuilder,
      [FilterListType.MembersByPosition]: groupedListsBuilder,
      [FilterListType.MembersBySkill]: groupedListsBuilder,
      [FilterListType.MembersByOffice]: groupedListsBuilder,
      [FilterListType.MembersByRegion]: groupedListsBuilder,
      [FilterListType.MembersByDiscipline]: groupedListsBuilder,
      [FilterListType.MembersByPortfolio]: groupedListsBuilder,
      [FilterListType.ProjectsByPortfolio]: groupedListsBuilder,
      [FilterListType.SkillLevels]: groupedListsBuilder
    }),
    [groupedListsBuilder]
  );

  /**
   * For FilterListTypes with groupings, initializes/updates collapseStates when necessary
   */
  useEffect(() => {
    (Array.isArray(filters) ? filters : [filters]).forEach((filterListType) => {
      const filterValues = filterValuesHash[filterListType];
      const collapseState = getCurrentParentCollapseState(filterListType);

      if (filterValues?.isGrouped && filterValues.topLevelOrder) {
        const { topLevelOrder } = filterValues;

        // initialize collapse state
        if (!collapseState) {
          setParentCollapseState({
            values: {
              totalNumToggles: topLevelOrder.length,
              allCollapsed: true
            },
            id: filterListType
          });
        } else if (collapseState.totalNumToggles !== topLevelOrder.length) {
          // update number of toggles
          setParentCollapseState({
            values: {
              totalNumToggles: topLevelOrder.length
            },
            id: filterListType
          });
        }
      }
    });
  }, [
    filterValuesHash,
    filters,
    getCurrentParentCollapseState,
    prevCollapseState,
    setParentCollapseState,
    setPrevCollapseState,
    toggleCollapseAll
  ]);

  /**
   * For FilterListTypes with groupings, handles spec of expanding all groups when filtering or searching,
   * and resetting to the previous collapse state when filters/search are cleared
   */
  useEffect(() => {
    (Array.isArray(filters) ? filters : [filters]).forEach((filterListType) => {
      const filterValues = filterValuesHash[filterListType];
      const collapseState = getCurrentParentCollapseState(filterListType);

      if (filterValues?.isGrouped && filterValues.topLevelOrder) {
        const { hasFilterParams, isLoading } = filterValues;

        // Expand all when there are search/filter params and save the current collapse state.
        // When the params are removed, the collapse state will be restored to the previous state
        if (
          hasFilterParams &&
          isLoading &&
          collapseState?.mode !== COLLAPSE_MODES.COLLAPSING
        ) {
          setPrevCollapseState({
            [filterListType]: collapseState
          });
          toggleCollapseAll(filterListType, 'expand');
        } else if (
          !hasFilterParams &&
          isLoading &&
          prevCollapseState[filterListType]
        ) {
          setParentCollapseState({
            values: prevCollapseState[filterListType],
            id: filterListType
          });
          setPrevCollapseState({
            [filterListType]: null
          });
        }
      }
    });
  }, [
    filterValuesHash,
    filters,
    getCurrentParentCollapseState,
    prevCollapseState,
    setParentCollapseState,
    setPrevCollapseState,
    toggleCollapseAll
  ]);

  /**
   * Initializes/updates the root/top level collapse state
   */
  useEffect(() => {
    const filterListTypes = Array.isArray(filters) ? filters : [filters];

    const collapseState = getCurrentParentCollapseState();

    if (collapseState?.totalNumToggles !== filterListTypes.length) {
      setParentCollapseState({
        values: {
          totalNumToggles: filterListTypes.length
        }
      });
    }
  }, [filters, getCurrentParentCollapseState, setParentCollapseState]);

  /* --------------------------------- builder -------------------------------- */

  const filterListTypeGroupedListsBuilder: BaseTableListsBuilder<FilterListTypeList> =
    useCallback(
      ({ order }) => {
        const lists = (order as FilterListType[]).reduce(
          (acc, filterListType, index) => {
            const filterValues = filterValuesHash[filterListType];

            if (!filterValues) {
              console.error(
                `Data for FilterListType ${filterListType} not found - could be missing from useFilterData`
              );
              return acc;
            }

            const {
              hasNextPage,
              optionsArray,
              selectedItems,
              searchText,
              setSearchText,
              selectAllItems,
              clearSelectedItems,
              isAllSelected,
              isLoading,
              isSingleSelect,
              handleNavToEntityPage,
              isGrouped,
              numHiddenItems,
              resultCountHash,
              numArchivedItems,
              isShowingArchivedItems,
              toggleIsShowingArchivedItems,
              isUnableToSelectMoreItems,
              selectionLimit,
              onlySelectedItemLabel,
              filterLabel,
              isOff
            } = filterValues;

            if (isOff) {
              return acc;
            }

            const groupedListsBuilder = isGrouped
              ? filterListTypeToGroupedListsBuilder[filterListType]
              : undefined;

            if (isGrouped && !groupedListsBuilder) {
              console.error(
                `groupedListsBuilder for FilterListType ${filterListType} not found`
              );
              return acc;
            }

            const isFilterListTypeHookWithGroupings =
              isValuesOfFilterListTypeHookWithGroupings(filterValues);

            const filterListTypeCollapseState =
              getCurrentParentCollapseState(filterListType);

            const preListItems: BaseTableListItem[] = [];

            /**
             * if exists, means this FilterListType can be replaced by another in the order
             * collapse state should be shared
             */
            const filterListTypeKey =
              getFilterListTypeSelectorKey?.(filterListType);

            if (filterListTypeKey) {
              preListItems.push(
                makeFilterListTypeSelectorRow({
                  filterListTypeKey
                })
              );
            }

            // has search
            if (searchText !== undefined && setSearchText) {
              preListItems.push(
                makeSearchInputRow({
                  className: makeStickyHeaderClassName(preListItems.length)
                })
              );
            }

            // has batchActionsRow
            if (!isLoading && (selectAllItems || clearSelectedItems)) {
              preListItems.push(
                makeBatchActionsRow({
                  ...(isGrouped && {
                    toggleCollapseAll: () =>
                      toggleCollapseAll(
                        filterListType,
                        filterListTypeCollapseState?.allCollapsed
                          ? 'expand'
                          : 'collapse'
                      ),
                    isAllCollapsed: filterListTypeCollapseState?.allCollapsed
                  }),
                  className: makeStickyHeaderClassName(preListItems.length)
                })
              );
            }

            const stickyHeaderOffset = isGrouped
              ? (skipHeaders ? 0 : 2) + preListItems.length // since rows are different heights, this is an approximation
              : 0;

            const listItems: (
              | ReturnType<BaseGroupedFilterListsBuilder>[0]
              | ReturnType<typeof listItemsBuilder>[0]
              | ReturnType<typeof makeAddEntityRow>
              | ReturnType<typeof makeShowHiddenItemsRow>
              | ReturnType<typeof makeShowArchivedRow>
              | typeof noResultsRow
            )[] = isFilterListTypeHookWithGroupings
              ? groupedListsBuilder
                ? groupedListsBuilder({
                    order: filterValues.topLevelOrder,
                    filterValues,
                    parentGroupId: filterListType,
                    groupEntityType:
                      filterListTypeToGroupEntity[filterListType],
                    stickyHeaderOffset
                  })
                : [] // will never reach here. just prevents having to use groupedListsBuilder!({...})
              : listItemsBuilder({
                  order: optionsArray,
                  filterValues,
                  parentGroupId: filterListType,
                  filterListType,
                  resultCountHash
                });

            if (numHiddenItems) {
              listItems.push(
                makeShowHiddenItemsRow({
                  numHidden: numHiddenItems
                })
              );
            }

            // show archived row - only for non-grouped FilterListTypes (for grouped FilterListTypes, this
            // has to be done in the groupedListsBuilder)
            if (!isGrouped) {
              if (numArchivedItems && toggleIsShowingArchivedItems) {
                listItems.push(
                  makeShowArchivedRow({
                    numArchived: numArchivedItems,
                    paddingLeft: 41,
                    toggleIsArchivedShowing: toggleIsShowingArchivedItems,
                    isShowingArchived: isShowingArchivedItems
                  })
                );
              }
            }

            // Add no results row
            // when grouping - check topLevelOrder length
            // otherwise check optionsArray length
            if (
              !isLoading &&
              ((isFilterListTypeHookWithGroupings &&
                !filterValues.topLevelOrder.length) ||
                !optionsArray.length)
            ) {
              listItems.push(noResultsRow);
            }

            if (handleNavToEntityPage) {
              listItems.push(
                makeAddEntityRow({
                  filterListType,
                  handleNavToEntityPage
                })
              );
            }

            const isListOpen = getIsOpen(filterListTypeKey || filterListType);

            const list: FilterListTypeList = {
              id: filterListType,
              sectionId: filterListType,
              stickyHeaderOffset,
              filterListType,
              searchPlaceholderText: 'Search or select below',
              customItems: preListItems,
              listItems: isLoading ? [skeletonLoaderRow] : listItems,
              numSelectedItems: selectedItems.length,
              isUnableToSelectMoreItems,
              selectionLimit,
              onlySelectedItemLabel,
              isList: true,
              // when loading, consider as fully loaded to prevent side effects (eg. triggering loadMore or showing
              // loading dots - skeletonLoaderRow handles that)
              isFullyLoaded: isLoading ? true : !hasNextPage,
              addEmptyRow: isListOpen,
              toggleCollapse: () =>
                toggleCollapse(filterListTypeKey || filterListType),
              isOpen: Boolean(isListOpen),
              rowHeight: 70,
              rowType: ROW_TYPES.defaultFilterListTypeHeaderRow,
              skipHeader: skipHeaders,
              searchText,
              setSearchText,
              selectAllItems,
              clearSelectedItems,
              isAllSelected,
              isSingleSelect,
              filterLabel,
              ...(index === 0 && { className: 'first' })
            };

            acc[filterListType] = list;
            return acc;
          },
          {} as Partial<Record<FilterListType, FilterListTypeList>>
        );

        const orderedLists = (order as FilterListType[])
          .map((id) => lists[id])
          .filter((list): list is FilterListTypeList => Boolean(list));

        return orderedLists;
      },
      [
        filterValuesHash,
        getIsOpen,
        listItemsBuilder,
        filterListTypeToGroupedListsBuilder,
        toggleCollapse,
        toggleCollapseAll,
        skipHeaders,
        getCurrentParentCollapseState,
        getFilterListTypeSelectorKey
      ]
    );

  return filterListTypeGroupedListsBuilder;
};

/* ------------------------------------ - ----------------------------------- */

/**
 * sticky header position depends on existence of other sticky headers
 */
const makeStickyHeaderClassName = (numItemsBefore: number) =>
  `sticky-${numItemsBefore}`;

const filterListTypeToGroupEntity: Partial<
  Record<FilterListType, GroupEntity>
> = {
  [FilterListType.MembersByDepartment]: 'department',
  [FilterListType.MembersByPosition]: 'position',
  [FilterListType.MembersBySkill]: 'skill',
  [FilterListType.MembersByOffice]: 'office',
  [FilterListType.MembersByRegion]: 'region',
  [FilterListType.MembersByDiscipline]: 'discipline',
  [FilterListType.MembersByPortfolio]: 'board',

  [FilterListType.ProjectsByPortfolio]: 'board',

  [FilterListType.SkillLevels]: 'skill'
};
