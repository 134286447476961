import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchCommentsAndMetadata,
  createComment,
  updateComment,
  deleteComment
} from 'actionCreators';

import {
  TaskAddCommentContainer,
  TaskCommentThreadContainer,
  MetadataRow
} from '../..';
import {
  getSelectedTeamId,
  getAuthToken,
  getMatchedRouteParams,
  makeGetProjectBasicInfo,
  getCurrentUserId,
  getTeamMembersHash
} from 'selectors';
import cn from 'classnames';
import { CommentScrollBox } from './styles';
import { ACTION_TYPES } from 'appConstants/actionDescription';

class TaskCommentsContainer extends React.Component {
  loadMoreComments = () => {
    // initially load 4 comments, then 15 per lazy load
    const { fetchCommentsAndMetadata, task, loadedCount, taskType } =
      this.props;
    fetchCommentsAndMetadata({
      taskType,
      taskId: task.id,
      offset: loadedCount,
      limit: 15
    });
  };

  render() {
    const {
      task,
      me,
      commentThreadsAndMetadataSorted,
      isCreatingReply,
      isDeletingReply,
      loadedCount,
      totalCount,
      createComment,
      updateComment,
      deleteComment,
      taskDetail,
      taskListView,
      isSelectedHomeTask,
      selectedTeamId,
      project,
      selectedProject,
      isUserActivitiesHidden = false,
      userId,
      teamMembersHash
    } = this.props;

    const allCommentsLoaded = loadedCount === totalCount;
    const taskIdForTaskModal = taskDetail.taskId;
    const shouldShowComments = !taskListView || isSelectedHomeTask;

    return (
      <div
        className={cn({
          'comments-container': !taskListView || isSelectedHomeTask
        })}
      >
        <TaskAddCommentContainer
          me={me}
          task={task}
          createComment={createComment}
          taskHasCommentsOrMeta={commentThreadsAndMetadataSorted.length}
          taskType={this.props.matchedParams.taskType}
          projectId={this.props.projectId}
          taskListView={taskListView}
          isSelectedHomeTask={isSelectedHomeTask}
          selectedTeamId={selectedTeamId}
          teamMembersHash={teamMembersHash}
        />
        {shouldShowComments && (
          <CommentScrollBox>
            {commentThreadsAndMetadataSorted.map((commentThreadOrMetadata) => {
              const isActivityDoneByCurrentUser =
                userId === commentThreadOrMetadata.actor?.id;

              // assigned to self = assigned by user to the same user
              // (Note: can be any user, not just current user)
              const isAssignedToSelf =
                commentThreadOrMetadata.primary_assignee_id &&
                commentThreadOrMetadata.primary_assigner_id &&
                commentThreadOrMetadata.primary_assignee_id ===
                  commentThreadOrMetadata.primary_assigner_id;

              // assigned to current user = assigned by another user to current user
              const isAssignedToCurrentUser =
                commentThreadOrMetadata.primary_assignee_id &&
                commentThreadOrMetadata.primary_assigner_id &&
                commentThreadOrMetadata.primary_assignee_id !==
                  commentThreadOrMetadata.primary_assigner_id &&
                userId === commentThreadOrMetadata.primary_assignee_id;

              if (commentThreadOrMetadata.type) {
                if (
                  isUserActivitiesHidden ||
                  // Don't show assign update on self assigned tasks
                  (commentThreadOrMetadata.type === ACTION_TYPES.TASK_ASSIGN &&
                    isAssignedToSelf)
                ) {
                  return null;
                }

                return (
                  <MetadataRow
                    key={`metadata ${commentThreadOrMetadata.id}`}
                    metadata={commentThreadOrMetadata}
                    isActivityDoneByCurrentUser={isActivityDoneByCurrentUser}
                    isAssignedToCurrentUser={isAssignedToCurrentUser}
                  />
                );
              } else if (
                commentThreadOrMetadata.project_task_id === taskIdForTaskModal
              ) {
                return (
                  <TaskCommentThreadContainer
                    key={`comment ${commentThreadOrMetadata.id}`}
                    me={me}
                    task={task}
                    commentThread={commentThreadOrMetadata}
                    isCreatingReply={isCreatingReply}
                    isDeletingReply={isDeletingReply}
                    createComment={createComment}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                    selectedTeamId={selectedTeamId}
                    selectedProject={selectedProject}
                    teamMembersHash={teamMembersHash}
                  />
                );
              } else {
                return null;
              }
            })}
            {allCommentsLoaded ? null : (
              <div className="task-comments-load-more">
                <span onClick={this.loadMoreComments}>See older comments</span>
              </div>
            )}
          </CommentScrollBox>
        )}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getProjectInfo = makeGetProjectBasicInfo();

  const mapStateToProps = (state, ownProps) => ({
    token: getAuthToken(state),
    commentThreadsAndMetadataSorted:
      state.taskDetail.commentThreadsAndMetadataSorted,
    isCreatingReply: state.taskDetail.isCreatingReply,
    isDeletingReply: state.taskDetail.isDeletingReply,
    loadedCount: state.taskDetail.loadedCount,
    totalCount: state.taskDetail.totalCount,
    taskDetail: state.taskDetail,
    selectedTeamId: getSelectedTeamId(state),
    matchedParams: getMatchedRouteParams(state),
    project: getProjectInfo(state, ownProps),
    userId: getCurrentUserId(state),
    teamMembersHash: getTeamMembersHash(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchCommentsAndMetadata,
  createComment,
  updateComment,
  deleteComment
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createComment: (params) =>
    dispatchProps.createComment(stateProps.token, params),
  deleteComment: (params) =>
    dispatchProps.deleteComment(stateProps.token, params),
  updateComment: (params) =>
    dispatchProps.updateComment(stateProps.token, params)
});

export default withRouter(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(TaskCommentsContainer)
);
