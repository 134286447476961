const FLYOUT_ACTIONS = {
  projects: {
    moveToTop: 'Move To Top',
    archive: 'Archive',
    delete: 'Delete',
    unarchive: 'Unarchive'
  },
  tasks: {
    moveToTop: 'Move To Top',
    completed: 'Completed',
    delete: 'Delete',
    restore: 'Restore'
  }
};

export default FLYOUT_ACTIONS;
