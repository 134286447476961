import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

export const readTimesheetEntries = createAction<SpacePermissionCheckerParams>(
  'READ_TIMESHEET_ENTRIES'
);

export const editTimesheetEntries = createAction<SpacePermissionCheckerParams>(
  'EDIT_TIMESHEET_ENTRIES'
);

export const approveTimesheetEntries =
  createAction<SpacePermissionCheckerParams>('APPROVE_TIMESHEET_ENTRIES');
