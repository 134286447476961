import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from './ErrorBoundary';
import Routing from './Routing';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ChromeDownloadBanner } from 'views';

const Root = ({ store, history }) => (
  <ErrorBoundary store={store}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ChromeDownloadBanner />
          <Routing />
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
);

Root.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired
};

export default Root;
