import {
  EntityRateCellProps,
  RateArchiveCellProps,
  RateGroupCellProps,
  RateGroupToggleCellProps,
  TableCellProps
} from './types';

export const isEntityRateCell = (
  props: TableCellProps
): props is EntityRateCellProps => 'rateEntity' in props.row.original;

export const isRateArchiveCell = (
  props: TableCellProps
): props is RateArchiveCellProps => 'numArchivedRates' in props.row.original;

export const isRateGroupCell = (
  props: TableCellProps
): props is RateGroupCellProps => 'rateGroup' in props.row.original;

export const isRateGroupToggleCell = (
  props: TableCellProps
): props is RateGroupToggleCellProps => 'toggleCollapse' in props.row.original;
