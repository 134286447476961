import { permissionConstants } from 'PermissionsModule/constants';

const { ACTABLE_TYPES } = permissionConstants;

const verifyProject = (permission, payloadPermissionCredentials) =>
  permission.actable_type === ACTABLE_TYPES.PROJECT &&
  +permission.actable_id === +payloadPermissionCredentials.projectId;

const verifyBoard = (permission, payloadPermissionCredentials) =>
  permission.actable_type === ACTABLE_TYPES.BOARD &&
  +permission.actable_id === +payloadPermissionCredentials.boardId;

const verifyTeam = (permission, payloadPermissionCredentials) =>
  permission.actable_type === ACTABLE_TYPES.TEAM &&
  +permission.actable_id === +payloadPermissionCredentials.teamId;

/* If the action does not provide a permissions payload,
   we don't have sufficient information to block on FE.
*/
const verifyDomain = (permission, payloadPermissionCredentials) =>
  payloadPermissionCredentials
    ? verifyProject(permission, payloadPermissionCredentials) ||
      verifyBoard(permission, payloadPermissionCredentials) ||
      verifyTeam(permission, payloadPermissionCredentials)
    : true;

export default verifyDomain;
