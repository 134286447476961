// Lazy loading list of notifications
import styled from 'styled-components';
import theme from 'theme';
import { ActivityFeedDay, PlaceholderText } from 'views';
import { Waypoint } from 'react-waypoint';
import LoadingWheel from 'icons/LoadingWheel';
import Spinner from 'react-spinkit';
import { ReactComponent as BellIcon } from 'images/bell-icon.svg';
import cn from 'classnames';

const ActivityFeed = ({
  accountId,
  allNotifications,
  isLoading,
  lazyLoad,
  markAsRead,
  markAllRead,
  isActivityMemberModal,
  isOnHomeView,
  showHeader
}) => {
  const shouldShowPlaceholder = isLoading === false && !allNotifications.length;
  return (
    <div
      className={cn('activity-feed-container', {
        'home-notifications': isOnHomeView,
        'member-modal-activity': isActivityMemberModal
      })}
    >
      {showHeader && (
        <ActivityFeedHeader>
          <NotificationIconContainer>
            <BellIcon />
          </NotificationIconContainer>
          Notifications
        </ActivityFeedHeader>
      )}
      <div className="activity-feed-body">
        {shouldShowPlaceholder ? (
          <PlaceholderText type={'activity-feed'} />
        ) : (
          allNotifications.map((notificationDay, index) => (
            <ActivityFeedDay
              key={notificationDay.date}
              day={notificationDay}
              markAsRead={markAsRead}
              markAllRead={markAllRead}
              index={index}
              accountId={accountId}
              isActivityMemberModal={isActivityMemberModal}
            />
          ))
        )}
        {isLoading && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '100px'
            }}
          >
            {allNotifications.length ? (
              <Spinner fadeIn="none" name="ball-beat" />
            ) : (
              <LoadingWheel />
            )}
          </div>
        )}
        {!!allNotifications.length && (
          <Waypoint onEnter={(waypointEvent) => lazyLoad(waypointEvent)} />
        )}
      </div>
    </div>
  );
};

const ActivityFeedHeader = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: -15px;
`;

const NotificationIconContainer = styled.div`
  margin-top: -5px;
  stroke-width: 200;
  margin-right: 8px;
`;
export default ActivityFeed;
