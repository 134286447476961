import React from 'react';
import TaskAssigneeDropdown from 'components/TaskAssigneeDropdown';

class TaskAssigneeCell extends React.Component {
  render() {
    const {
      task,
      handleMultiAssignDone,
      isEditing,
      projectDetailView,
      isNewTask
    } = this.props;
    return (
      <div style={{ width: '100%', height: '100%', zIndex: 1 }}>
        <TaskAssigneeDropdown
          task={task}
          projectId={task?.project_id}
          onMultiAssignDone={handleMultiAssignDone}
          isEditing={isEditing}
          projectDetailView={projectDetailView}
          isNewTask={isNewTask}
        />
      </div>
    );
  }
}

export default TaskAssigneeCell;
