import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCurrentPage,
  getCurrentFilter,
  getPastScheduledTasks,
  getPastDueTasks,
  getIsOnProjectView
} from 'selectors';

import TasksGroupHeader from 'views/Home/TaskList/TasksGroupHeader';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import theme from 'theme';
const emptyObj = {};

class CompleteCellContent extends PureComponent {
  renderIcon = () => {
    if (this.props.group?.is_like_default || this.props.isOnProjectView) {
      return null;
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <PhaseMilestoneIcon
          height={22}
          width={22}
          fillColor={'transparent'}
          strokeColor={theme.colors.colorSemiDarkGray1}
        />
      </div>
    );
  };

  render() {
    const {
      isOnHomeView,
      group,
      groupIndex,
      setIsOpen,
      draggableProvided = emptyObj,
      allCollapsed,
      isOpen,
      numTasks
    } = this.props;
    return (
      <div
        ref={draggableProvided?.innerRef}
        {...draggableProvided.draggableProps}
      >
        <TasksGroupHeader
          index={groupIndex}
          projectId={group.project_id}
          group={group.is_like_default ? { ...group, name: '' } : group}
          id={group.id}
          originType={'phase'} // necessary to determine color picker open/close state
          pickerLocation={'flyout-menu'}
          isOnHomeView={isOnHomeView}
          isOnProjectView={true}
          taskIds={group.task_order}
          provided={draggableProvided}
          // snapshot={snapshot}
          collapsedForDrag={false}
          isOnlyTaskGroup={this.props.isOnlyTaskGroup}
          setIsOpen={setIsOpen}
          key={group?.id}
          hideSort
          isVirtualTaskList
          tableAllCollapsed={allCollapsed}
          renderIcon={this.renderIcon}
          editDisabled
          isTaskGroup={false}
          isOpen={isOpen}
          numTasks={numTasks}
        />
      </div>
    );
  }
}

const mapStateToPRops = (state) => ({
  currentPage: getCurrentPage(state),
  currentFilter: getCurrentFilter(state),
  pastDueTasks: getPastDueTasks(state),
  pastScheduledTasks: getPastScheduledTasks(state),
  isOnProjectView: getIsOnProjectView(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToPRops, mapDispatchToProps)(CompleteCellContent)
);
