import React from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import ActivityList from './ActivityList';

import { getActivityDropdownOpen } from 'BudgetModule/selectors';
import {
  getSelectedTeamId,
  getOrderedNonBillableActivities,
  getOrderedBillableActivities,
  getOrderedArchivedActivities
} from 'selectors';

import {
  openActivityModal,
  closeActivityDropdown
} from 'BudgetModule/actionCreators';
import { updateTeamOrderedItems } from 'actionCreators';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import withIsHoursOnly from 'hocs/withIsHoursOnly';

import {
  ModalHeaderLeft,
  ModalHeaderRight
} from 'BudgetModule/components/styles';
import {
  StandardActivitiesHeader,
  DoneButton,
  StyledItemContainer,
  StyledEditIcon
} from './styles';

import { filterItemWithWhiteSpace } from 'appUtils/search';
import reorder from 'appUtils/reorder';

const droppableIdToTeamPropertyHash = {
  billableActivities: 'billable_activity_order',
  nonBillableActivities: 'nonbillable_activity_order'
};

class GlobalActivityDropdown extends React.Component {
  renderItem = ({ item, selectCallback }) => (
    <div
      onClick={(e) => this.handleSelect(e, { item, selectCallback })}
      style={{
        display: 'flex',
        flex: 'auto',
        justifyContent: 'space-between',
        opacity: item?.archived ? 0.7 : 1
      }}
      data-testid={`globalactivitydropdown-${
        item?.billable ? 'billable' : 'non-billable'
      }-${item?.title}`}
    >
      {item?.title}
      {!item?.archived && (
        <div>
          <StyledEditIcon />
        </div>
      )}
    </div>
  );

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    const { openActivityModal } = this.props;
    const { id } = item;
    openActivityModal({ id });
  };

  onFooterClick = () => {
    const { closeActivityDropdown } = this.props;
    closeActivityDropdown();
  };

  onStickyClick = () => {
    const { openActivityModal } = this.props;
    openActivityModal({});
  };

  onItemClick = (id) => this.props.openActivityModal({ id });

  reorder = (newOrder) => {
    const { updateTeamOrderedItems, teamId } = this.props;
    updateTeamOrderedItems({
      team_id: teamId,
      nonBillableActivities: newOrder
    });
  };

  updateActivityOrders = (newOrders) => {
    const { updateTeamOrderedItems, teamId } = this.props;
    updateTeamOrderedItems({
      team_id: teamId,
      ...newOrders
    });
  };

  getListFromDroppableId = (list) => this.props[list.droppableId];

  onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;
    const sourceList = this.getListFromDroppableId(source);
    const destinationList = this.getListFromDroppableId(destination);
    const sourceTeamProperty =
      droppableIdToTeamPropertyHash[source.droppableId];
    const destinationTeamProperty =
      droppableIdToTeamPropertyHash[destination.droppableId];

    if (source.droppableId === destination.droppableId) {
      const newOrder = reorder(
        sourceList.map((item) => Number(item.id)),
        source.index,
        destination.index
      );
      const newOrders = {
        [sourceTeamProperty]: newOrder
      };
      this.updateActivityOrders(newOrders);
    } else {
      const newDestinationOrder = destinationList.map((item) =>
        Number(item.id)
      );
      newDestinationOrder.splice(destination.index, 0, draggableId);

      const newSourceOrder = sourceList.map((item) => Number(item.id));
      newSourceOrder.splice(source.index, 1);
      const newOrders = {
        [destinationTeamProperty]: newDestinationOrder,
        [sourceTeamProperty]: newSourceOrder
      };
      this.updateActivityOrders(newOrders);
    }
  };

  render() {
    const {
      isOpen,
      nonBillableActivities,
      archivedActivities,
      billableActivities,
      openActivityModal,
      isHoursOnly
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onFooterClick}
        className="timesheet-activities-modal"
        wrapClassName="timesheet-activities-modal-wrapper"
      >
        <ModalHeader>
          <StandardActivitiesHeader>
            <ModalHeaderLeft>Standard Work Categories</ModalHeaderLeft>
            <ModalHeaderRight onClick={this.onFooterClick}>
              <DoneButton> Done</DoneButton>
            </ModalHeaderRight>
          </StandardActivitiesHeader>
        </ModalHeader>
        <ModalBody>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <ActivityList
              title={!isHoursOnly ? 'Billable' : 'Work categories'}
              droppableId="billableActivities"
              onCreateClick={openActivityModal}
              renderItem={this.renderItem}
              handleSelect={this.handleSelect}
              items={billableActivities}
              billable
            />
            {!isHoursOnly && (
              <ActivityList
                title="Non-Billable"
                droppableId="nonBillableActivities"
                onCreateClick={openActivityModal}
                renderItem={this.renderItem}
                handleSelect={this.handleSelect}
                items={nonBillableActivities}
              />
            )}
            <ActivityList
              title="Archived"
              droppableId="archivedActivities"
              showCreateNew={false}
              dragDisabled
              onCreateClick={openActivityModal}
              renderItem={this.renderItem}
              handleSelect={() => {}}
              items={archivedActivities}
            />
          </DragDropContext>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: getActivityDropdownOpen(state),
  teamId: getSelectedTeamId(state),
  nonBillableActivities: getOrderedNonBillableActivities(state),
  billableActivities: getOrderedBillableActivities(state),
  archivedActivities: getOrderedArchivedActivities(state)
});

const mapDispatchToProps = {
  openActivityModal,
  closeActivityDropdown,
  updateTeamOrderedItems
};

export default withIsHoursOnly(
  connect(mapStateToProps, mapDispatchToProps)(GlobalActivityDropdown)
);
