import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import { Field, Label } from 'SettingsModule/components/styles';
import { OrganizationSettingsPopover } from '../shared/OrganizationSettingsPopover';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  getSelectedTeamId,
  getSelectedTeamViewSettings
} from 'TeamsModule/selectors';
import { WorkPlanHourDisplay, WorkPlanHourDisplayLabel } from './constants';
import { updateTeamViewSettings } from 'TeamsModule/actionCreators';
import { TeamWorkloadViewSettings } from 'TeamsModule/models/team';
import { DropdownItemContainer } from '../styles';

export const WorkplanHoursDisplaySetting = () => {
  const teamViewSettings = useAppSelector(getSelectedTeamViewSettings);
  const teamId = useAppSelector(getSelectedTeamId);
  const { work_load: workloadSettings } = teamViewSettings ?? {};
  const { display_member_capacity: displayMemberCapacity } =
    workloadSettings ?? {};
  const dispatch = useAppDispatch();

  const handleClick = ({
    key: workPlanHourDisplayValue
  }: {
    key: WorkPlanHourDisplay;
  }) => {
    const updatedWorkloadSettings = {
      ...workloadSettings,
      display_member_capacity: workPlanHourDisplayValue
    } as TeamWorkloadViewSettings;

    if (teamId)
      dispatch(
        updateTeamViewSettings({
          viewSettings: { work_load: updatedWorkloadSettings },
          teamId
        })
      );
  };

  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="This can be overridden by individual users"
        data-effect="solid"
        data-html
        data-class="center"
      >
        Work Plan Hrs Display Default
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <OrganizationSettingsPopover
          options={options}
          currentlySelectedKey={displayMemberCapacity}
          onClick={handleClick}
          showEllipsisTooltip={false}
        />
      </Field>
    </>
  );
};

const options = {
  [WorkPlanHourDisplay.TotalHrs]: {
    label: WorkPlanHourDisplayLabel[WorkPlanHourDisplay.TotalHrs],
    item: (
      <DropdownItemContainer>
        {WorkPlanHourDisplayLabel[WorkPlanHourDisplay.TotalHrs]}
      </DropdownItemContainer>
    )
  },
  [WorkPlanHourDisplay.AvailableHrs]: {
    label: WorkPlanHourDisplayLabel[WorkPlanHourDisplay.AvailableHrs],
    item: (
      <DropdownItemContainer>
        {WorkPlanHourDisplayLabel[WorkPlanHourDisplay.AvailableHrs]}
      </DropdownItemContainer>
    )
  },
  [WorkPlanHourDisplay.PercentCapacity]: {
    label: WorkPlanHourDisplayLabel[WorkPlanHourDisplay.PercentCapacity],
    item: (
      <DropdownItemContainer>
        {WorkPlanHourDisplayLabel[WorkPlanHourDisplay.PercentCapacity]}
      </DropdownItemContainer>
    )
  }
};
