import styled from 'styled-components';
import theme from 'theme';
const { colors } = theme;

const DropdownArrow = styled.div`
  display: inline-block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.colorSemiDarkGray1};
  transform: ${({ direction }) =>
    direction === 'up' ? 'rotate(180deg)' : 'none'};
`;

export default DropdownArrow;
