import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  CopyContainer,
  CopyHeader,
  CopyParagraph,
  StyledDateChangeConfirmation
} from 'BudgetModule/components/styles';
import {
  updateMemberTeamRate,
  deleteMemberTeamRate
} from 'BudgetModule/actionCreators';
import moment from 'moment';
import { getSelectedTeamId } from 'selectors';

const copyHash = {
  end_date: 'start date for the next',
  start_date: 'end date for the prior'
};

class RateDeleteConfirmation extends React.Component {
  renderCopy = () => (
    <CopyContainer>
      <CopyHeader>Are you sure you want to delete this rate?</CopyHeader>
      <CopyParagraph>
        {copyHash[this.props.dateType] &&
          `The change will also update the ${
            copyHash[this.props.dateType]
          } rate as
           shown above.`}
      </CopyParagraph>
    </CopyContainer>
  );

  renderButtons = () => {
    const { handleClose } = this.props;
    return (
      <ModalButtonsRow>
        <Left />
        <Right>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ConfirmButton onClick={this.handleConfirm}>Confirm</ConfirmButton>
        </Right>
      </ModalButtonsRow>
    );
  };

  handleConfirm = () => {
    const { deleteItem, handleClose, onDeleteConfirm } = this.props;
    if (onDeleteConfirm) {
      onDeleteConfirm(deleteItem);
    } else {
      this.deleteMemberRate();
    }
    handleClose();
  };

  deleteMemberRate = () => {
    const { deleteMemberTeamRate, deleteItem, teamId, teamMembershipId } =
      this.props;
    const { id } = deleteItem;
    deleteMemberTeamRate({
      teamRateId: id,
      teamId,
      teamMembershipId
    });
  };

  render() {
    return (
      <Modal isOpen={true} className="delete-rate-modal">
        <StyledDateChangeConfirmation>
          {this.renderCopy()}
          {this.renderButtons()}
        </StyledDateChangeConfirmation>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  updateMemberTeamRate,
  deleteMemberTeamRate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RateDeleteConfirmation);
