import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTimesheetSortState } from 'selectors';
import { sortTimesheetReport } from 'actionCreators';
import {
  StyledReportHeader,
  StyledSortIcon,
  StyledSortIconContainer,
  StyledHeaderLabel,
  StyledAddPlusIcon,
  StyledAddPlusContainer
} from 'ReportsModule/components/styles';
import {
  SORT_ORDER,
  OPPOSITE_SORT_ORDER,
  TIMESHEET_SORT_API_PARAMS
} from 'appConstants/filters';
import { StickyRowContext } from '../StickyRowContext';

const HeaderCell = ({
  column,
  row, // undefined when Table Header
  isDisabled = false,
  customRowProps // only when Table Header
}) => {
  const { headerLabel, headerType, align, index } = column;
  const dispatch = useDispatch();
  const { value, order } = useSelector(getTimesheetSortState);
  // row exists when HeaderCell is not the actual Header
  const isWeek = row?.original.customRowProps.isWeek || customRowProps?.isWeek;
  const stickyHeader = useContext(StickyRowContext);
  const shouldRenderAddButton =
    !index && !isWeek && !isDisabled && (!row ? stickyHeader?.isOpen : true);

  const isActive = value === headerType && !isDisabled;

  const showAsActive =
    isActive &&
    !(
      headerType === TIMESHEET_SORT_API_PARAMS.date && order === SORT_ORDER.desc
    );

  const setSortState = () => {
    if (isDisabled) {
      return;
    }
    const newOrder = isActive ? OPPOSITE_SORT_ORDER[order] : SORT_ORDER.desc;

    const newValue =
      order === SORT_ORDER.asc && isActive
        ? TIMESHEET_SORT_API_PARAMS.date
        : headerType;

    dispatch(sortTimesheetReport({ order: newOrder, value: newValue }));
  };

  const handleAdd = (e) => {
    e.stopPropagation();
    const rowToUse = row?.original || stickyHeader;
    if (!rowToUse) {
      return;
    }
    const { customRowProps } = rowToUse;
    const { setIsAddingNewTimesheetRow, isTimesheetsDisabled } = customRowProps;
    if (isTimesheetsDisabled) {
      return;
    }
    setIsAddingNewTimesheetRow({
      createRowData: rowToUse.list.createRowData,
      listId: rowToUse.list.id
    });
  };

  if (!headerLabel) {
    return <div />;
  }

  return (
    <>
      {shouldRenderAddButton && (
        <StyledAddPlusContainer
          data-for="app-tooltip"
          data-tip="Add Time Entry"
          data-html
          data-effect="solid"
          data-class="center"
          onClick={handleAdd}
        >
          <StyledAddPlusIcon />
        </StyledAddPlusContainer>
      )}
      <StyledReportHeader
        align={align}
        onClick={setSortState}
        isActive={showAsActive}
      >
        <StyledHeaderLabel>
          {headerLabel}

          <StyledSortIconContainer isActive={showAsActive} order={order}>
            <StyledSortIcon />
          </StyledSortIconContainer>
        </StyledHeaderLabel>
      </StyledReportHeader>
    </>
  );
};

export default HeaderCell;

export const EmptyHeaderCell = ({ children }) => (
  <StyledReportHeader style={{ pointerEvents: 'none' }}>
    <StyledHeaderLabel style={{ visibility: 'hidden' }}>.</StyledHeaderLabel>
    {children}
  </StyledReportHeader>
);

export const HeaderIfOpenCell = (props) => {
  if (props.row.original.isOpen) {
    return null;
  }
  return <EmptyHeaderCell {...props} />;
};
