import React from 'react';

import {
  TaskCheckImage,
  TaskCircleCheckWrapper,
  TaskCircleWrapper,
  TaskCircleImage,
  TaskCirclePath
} from './styles';
import ReactTooltip from 'react-tooltip';

const pathAttributeMapping = {
  'project-detail-task': {
    transform: 'translate(8,8)',
    d: 'M10 5.625H5.625V10H4.375V5.625H0V4.375H4.375V0H5.625V4.375H10V5.625Z'
  },
  default: {
    transform: null,
    d: 'M11.6201 14.6015L9.35292 12.3495C9.27557 12.2722 9.1498 12.2737 9.07401 12.3495L8.05801 13.3718C7.98066 13.4492 7.98066 13.5749 8.05801 13.6523C9.20141 14.7882 10.3435 15.9242 11.4869 17.0585C11.5643 17.1358 11.69 17.1358 11.7674 17.0585L17.9444 10.853C18.0202 10.7756 18.0202 10.6499 17.9428 10.5725L16.9205 9.55801C16.8432 9.48066 16.7174 9.48066 16.64 9.55801L11.6205 14.6021L11.6201 14.6015Z'
  }
};
class TaskCircle extends React.PureComponent {
  state = {
    isAnimating: false
  };

  animate = (e) => {
    const { isAnimating, isDisabled } = this.state;
    const { onCreateRowClick } = this.props;
    e.stopPropagation();
    if (isAnimating) {
      return;
    }

    this.setState(() => ({
      isAnimating: true
    }));
    // animation takes 0.25s (see TaskCircleImage)
    // this results in a snappier experience than using onAnimationEnd
    if (isDisabled) {
      return;
    }
    onCreateRowClick && onCreateRowClick();
    setTimeout(this.markCompleteAndResetState, 200);
  };

  markCompleteAndResetState = () => {
    const { markComplete, isDisabled } = this.props;
    ReactTooltip.hide();
    if (isDisabled) {
      return;
    }
    this.setState({ isAnimating: false });
    markComplete();
  };

  render() {
    const { isAnimating } = this.state;
    const {
      isEditing,
      isOnTeamProject,
      isCompleted,
      isSelectedHomeTask,
      isDisabled,
      isOnTeamPageView,
      isOnProjectView,
      rowEditing,
      isMemberModalOpen,
      taskCircleClass,
      isCreateRow,
      testIdPrefix
    } = this.props;

    const pathAttribute =
      (isCreateRow && pathAttributeMapping[taskCircleClass]) ||
      pathAttributeMapping.default;

    return (
      <TaskCircleWrapper
        className={`app-cues-task-circle ${taskCircleClass}`}
        onClick={rowEditing ? undefined : this.animate}
        isOnProjectView={isOnProjectView}
      >
        <TaskCircleCheckWrapper
          data-for={
            isCompleted !== undefined && !isAnimating ? 'app-tooltip' : ''
          }
          data-tip={
            (isCreateRow && 'Add Task') ||
            (rowEditing
              ? ''
              : isCompleted
              ? 'Mark Incomplete'
              : 'Mark Complete')
          }
          data-effect="solid"
          data-delay-show={400}
          data-testid={`${testIdPrefix}-task-circle-check`}
        >
          <TaskCircleImage $isAnimating={isAnimating}>
            <g fillRule="evenodd">
              <TaskCirclePath
                className={taskCircleClass}
                isCompleted={isCompleted && !isDisabled}
                isAnimating={isAnimating}
                isEditing={isEditing}
                isDisabled={isDisabled}
                isCreateRow={isCreateRow}
                isGray={
                  !isMemberModalOpen &&
                  (isDisabled ||
                    isOnTeamPageView ||
                    (isOnTeamProject && !isSelectedHomeTask))
                }
                rowEditing={rowEditing}
                d="M1 13c0 6.629 5.371 12 12 12s12-5.371 12-12S19.629 1 13 1 1 6.371 1 13z"
              />
              <TaskCheckImage
                className={taskCircleClass}
                isCompleted={isCompleted && !isDisabled}
                isAnimating={isAnimating && !isDisabled}
                rowEditing={rowEditing}
                isCreateRow={isCreateRow}
                transform={pathAttribute.transform}
                d={pathAttribute.d}
              />
            </g>
          </TaskCircleImage>
        </TaskCircleCheckWrapper>
      </TaskCircleWrapper>
    );
  }
}

export default TaskCircle;
