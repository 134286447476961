import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { PTOsTable } from './PTOsTable';
import {
  fetchPTOPolicies,
  fetchPTOPolicyPerMember
} from 'SettingsModule/actionCreators/settings/pto';
import { batch } from 'react-redux';
import { fetchRegions } from 'SettingsModule/actionCreators/settings/region';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';
import { getSelectedTeamId } from 'selectors';

export const PTOsTableContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      batch(() => {
        dispatch(fetchOffices({ teamId }));
        dispatch(fetchRegions({ teamId }));
        dispatch(fetchPTOPolicyPerMember({ teamId }));
        dispatch(fetchPTOPolicies({ teamId }));
      });
    }
  }, [dispatch, teamId]);

  return <PTOsTable />;
};
