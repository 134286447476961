import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import theme from 'theme';
import {
  getDragTooltipDates,
  deserializeBar
} from 'appUtils/projectPlannerUtils';
import { HelpersContext } from 'react-calendar-timeline';
import moment from 'moment';
import {
  VIEW_BY,
  ZOOM_FIT_ITEM_TO_SCALE,
  ZOOM_STRINGS,
  DISPLAY_MEMBER_CAPACITY,
  CONDENSED_ZOOM_LEVELS
} from 'appConstants/workload';
import { getProjectPhaseTooltipContent } from 'appUtils/projectPhaseTooltipContent';
import { getOOOProject, getMyWorkPlanSettings } from 'selectors';
import {
  getAccountCapacities,
  getAverageCapacities,
  getTeamCapacity
} from 'CapacityModule/selectors';
import { setBarContextMenuId } from 'actionCreators';
import { BUDGET_STATUSES } from 'appConstants/budgetStatuses';
import round from 'lodash/round';
import VerySmallItemRenderer from './VerySmallItemRenderer';
import SmallItemRenderer from './SmallItemRenderer';
import MediumItemRenderer from './MediumItemRenderer';
import BigItemRenderer from './BigItemRenderer';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import {
  getDatesExcludeDaysOff,
  getWeekendsArrayFromRange,
  getWorkdayPercent
} from '../utils';

const StyledSplitPreviewContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  left: 0;
  justify-content: space-between;
`;
const SplitPreviewLine = styled.div`
  border: 1px dashed ${theme.colors.colorMediumGray9};
  height: 48px;
  z-index: 1;
  position: absolute;
  left: -1px;
  top: -4px;
`;
const StyledSplitPreview = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 40px;
  flex: auto;
  z-index: 1;
  position: relative;
  ${SplitPreviewLine} {
    opacity: 0;
  }
  &:hover {
    ${SplitPreviewLine} {
      opacity: 1;
    }
  }
`;

const noStyle = {
  alignItems: 'center',
  borderColor: '',
  background: 'transparent',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  display: 'flex'
};
const draggingStyle = {
  alignItems: 'center',
  borderColor: '',
  border: 'none',
  backgroundColor: `${theme.colors.colorIceBlue}`,
  cursor: 'grabbing',
  display: 'flex'
  // background: 'transparent',
  // backgroundColor: 'transparent'
  // cursor: 'ew-resize'
};

const shouldInjectAvailabilities = (zoom) =>
  ZOOM_FIT_ITEM_TO_SCALE[zoom] === ZOOM_STRINGS.DAY;

const injectAvailabilities = (item, zoom) => {
  if (item.is_phase) return item;
  if (item.bars && item.bars.length && shouldInjectAvailabilities(zoom)) {
    return item;
  } else
    return {
      ...item
    };
};

const calcDaysOnTotal = (bar) =>
  bar.bars ? bar.bars.reduce((acc, cur) => acc + cur.day_count, 0) : 0;

const getIsTentative = (item) =>
  item?.budget_status === BUDGET_STATUSES.PROPOSAL;

const getTooltipContent = (
  item,
  projectColor,
  name,
  description,
  averageCapacity
) => {
  const totals = item.all_day
    ? `All day | ${calcDaysOnTotal(item)}d`
    : `${item.all_day ? averageCapacity : item.daily_hours}`.replace(
        /\.0+$/,
        ''
      ) +
      'h/d' +
      ' | ' +
      `${calcDaysOnTotal(item)}d` +
      ' | ' +
      `${item.total_hours}`.replace(/\.0+$/, '') +
      'h';

  const isTentative = getIsTentative(item);

  return getProjectPhaseTooltipContent({
    projectColor,
    projectName: name,
    projectDescription: description,
    phaseName: item?.phase_name,
    startDate: item?.start_date_label,
    endDate: item?.end_date_label,
    isDefaultPhase: item?.is_like_default,
    activityName: item?.activity_title,
    isDefaultActivity: item?.is_default_activity,
    totals,
    isTentative
  });
};

const leftStyle = {
  alignItems: 'center',
  cursor: 'w-resize',
  display: 'flex',
  justifyContent: 'flex-start',
  maxWidth: '50%',
  width: 10
};

const rightStyle = {
  alignItems: 'center',
  cursor: 'e-resize',
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '50%',
  width: 10
};

const SplitPreview = ({
  startDate,
  endDate,
  itemFitToScaleValue,
  handleScheduleBarSplit,
  itemId
}) => {
  const startDates = Array.from(
    moment.range(startDate, endDate).by(itemFitToScaleValue)
  ).map((date) => date.valueOf());

  return (
    <StyledSplitPreviewContainer style={{ width: '100%' }}>
      {startDates.map((date) => (
        <StyledSplitPreview
          key={date}
          onClick={(e) => {
            e.stopPropagation();
            handleScheduleBarSplit(itemId, date);
          }}
        >
          <SplitPreviewLine />
        </StyledSplitPreview>
      ))}
    </StyledSplitPreviewContainer>
  );
};

const WorkloadPlannerItemRenderer = (props) => {
  const {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    userTheme,
    viewBy,
    time,
    startDateRef,
    endDateRef,
    zoom,
    showHours,
    handleScheduleBarClick,
    handleScheduleBarSplit,
    isSplitting,
    handleScheduleBarCopy,
    activateTaskSplitScreen,
    setSplitScheduleBar,
    openDeleteModal,
    isSplitScreenItem,
    isHeatmapColored,
    isCondensedView,
    canDelete,
    canEdit,
    canCreate,
    condensedZoomLevel,
    visibleTimeStart,
    visibleTimeEnd,
    hasStartDependency,
    hasEndDependency
  } = props;
  const dispatch = useDispatch();
  const deserializedId = deserializeBar(item.id)?.itemId;
  const averageCapacities = useSelector(getAverageCapacities);
  const accountCapacitiesHash = useSelector(getAccountCapacities);
  const defaultDailyTeamCapacity = useSelector(getTeamCapacity);

  const OOOProject = useSelector(getOOOProject);
  const myWorkPlanSettings = useSelector(getMyWorkPlanSettings);
  const averageCapacity = !isNaN(averageCapacities[item.account_id])
    ? formatNumWithMaxTwoDecimals(averageCapacities[item.account_id])
    : 8;
  const Helpers = React.useContext(HelpersContext);
  const isProjectsView = viewBy !== VIEW_BY.MEMBERS && !isSplitScreenItem;
  const [isMenuOpenDate, setIsMenuOpen] = useState(null);
  const handleCloseContextMenu = () => {
    setIsMenuOpen(null);
    dispatch(setBarContextMenuId({ itemId: null }));
  };
  const [, setRerenderCount] = useState(0);
  const rerender = useCallback(() => setRerenderCount((prev) => prev + 1), []);
  const popoverTarget = useRef(null);
  useEffect(() => {
    if (isMenuOpenDate) {
      rerender();
    }
  }, [isMenuOpenDate, rerender]);
  const { getLeftOffsetFromDate, getItemAbsoluteDimensions } = Helpers;
  const itemDimensions = getItemAbsoluteDimensions(item.id) || {};
  const { left = 0 } = itemDimensions;
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle,
    rightStyle
  });

  const itemToUse = useMemo(
    () => injectAvailabilities(item, zoom),
    [item, zoom]
  );

  const lastBarIndex = itemToUse.bars ? itemToUse.bars.length - 1 : 0;

  const isTentative = getIsTentative(item);

  const tooltipDates =
    itemContext.dragging || itemContext.resizing
      ? getDragTooltipDates({ item, itemContext })
      : null;

  const leftBarColor = calculateColorPref({
    theme: userTheme.theme,
    styleId: item.project_id,
    color: '#4a4a4a',
    originType: 'project'
  });

  const barTooltipContent = useMemo(
    () =>
      getTooltipContent(
        item,
        leftBarColor,
        isProjectsView
          ? item?.member?.name || item?.project_name
          : item?.project_name,
        isProjectsView ? '' : item.project_description,
        averageCapacity,
        OOOProject
      ),
    [item, leftBarColor, isProjectsView, averageCapacity, OOOProject]
  );

  const itemFitToScaleValue = ZOOM_FIT_ITEM_TO_SCALE[zoom];
  const itemProps = getItemProps({
    ...item.itemProps,
    className: 'mosaic-project-schedule-bar',
    style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
  });
  const itemWidth = +itemProps.style.width.slice(0, -2);
  const isVerySmallBar = itemWidth < 30;
  const isSmallBar = itemWidth < 60;
  const isMediumBar = itemWidth < 120;

  const displayMemberCapacity =
    myWorkPlanSettings?.display_member_capacity ||
    DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS;

  const lastDayRemainder =
    +item.daily_hours && round(item.total_hours % item.daily_hours, 2);

  const datesArray = useMemo(
    () =>
      getDatesExcludeDaysOff({
        // when it is condensed view, endDate has buffered date to avoid overlapping between bars
        // to get original formatted start and end date string, use [start|end]_date_label instead
        startDate: item.start_date_label,
        endDate: item.end_date_label,
        daysOff: item.include_weekends
          ? []
          : getWeekendsArrayFromRange({
              startDate: item.start_date_label,
              endDate: item.end_date_label
            })
      }),
    [item.end_date_label, item.include_weekends, item.start_date_label]
  );

  const accountCapacity = item.account_id
    ? accountCapacitiesHash[item.account_id]
    : item.member_budget_id
    ? defaultDailyTeamCapacity
    : undefined;

  const workdayPercent = useMemo(
    () =>
      getWorkdayPercent({
        dailyHours: Number(item.daily_hours) || 0,
        dailyCapacity: accountCapacity,
        datesArray
      }),
    [accountCapacity, datesArray, item.daily_hours]
  );

  const BarProps = {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    userTheme,
    viewBy,
    time,
    startDateRef,
    endDateRef,
    zoom,
    showHours,
    handleScheduleBarClick,
    handleScheduleBarSplit,
    isSplitting,
    handleScheduleBarCopy,
    activateTaskSplitScreen,
    setSplitScheduleBar,
    openDeleteModal,
    isSplitScreenItem,
    isHeatmapColored,
    isCondensedView,
    itemProps,
    lastDayRemainder,
    displayMemberCapacity,
    isSmallBar,
    isMediumBar,
    isVerySmallBar,
    itemWidth,
    itemFitToScaleValue,
    barTooltipContent,
    leftBarColor,
    tooltipDates,
    isTentative,
    lastBarIndex,
    itemToUse,
    leftResizeProps,
    rightResizeProps,
    itemDimensions,
    getLeftOffsetFromDate,
    isMenuOpenDate,
    setIsMenuOpen,
    getItemAbsoluteDimensions,
    popoverTarget,
    deserializedId,
    averageCapacities,
    OOOProject,
    myWorkPlanSettings,
    averageCapacity,
    Helpers,
    isProjectsView,
    handleCloseContextMenu,
    left,
    SplitPreview,
    canEdit,
    canDelete,
    canCreate,
    visibleTimeStart,
    visibleTimeEnd,
    workdayPercent,
    hasStartDependency,
    hasEndDependency
  };

  if (!isCondensedView) {
    return <BigItemRenderer {...BarProps} />;
  } else if (condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM) {
    return <MediumItemRenderer {...BarProps} />;
  } else if (condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL) {
    return <SmallItemRenderer {...BarProps} />;
  } else {
    return <VerySmallItemRenderer {...BarProps} />;
  }
};

export default WorkloadPlannerItemRenderer;
