import styled from 'styled-components';
import theme from 'theme';

export const TabBlurb = styled.div`
  display: flex;
  width: 100%;
  background-color: rgba(99, 161, 215, 0.1);
  margin-left: 30px;
  color: #828282;
  font-size: 13px;
  line-height: 18px;

  padding: 0px 12px;
  align-items: center;
`;

export const NoShrinkIconContainer = styled.div`
  svg {
    flex-shrink: 0;
  }
`;

export const Toggle = styled.div`
  border-radius: 50%;
  background: ${theme.colors.colorLightGray19};
  height: 9px;
  width: 9px;
  margin: 0 1px;
`;

export const ToggleContainer = styled.div`
  margin-left: 5px;
  width: 23px;
  height: 13px;
  border: 1px solid ${theme.colors.colorLightGray15};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
  cursor: pointer;
  background-color: ${({ isOn }) =>
    isOn ? theme.colors.colorQbTooltipGreen : theme.colors.colorLightGray15};
`;
