import theme from 'theme';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import styled from 'styled-components';

const ListItem = styled.li``;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 20px;
  color: #4c4c4c;
  font-weight: 600;
`;

const ModalBody = styled.div`
  margin: 20px 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const List = styled.ul`
  list-style-type: circle;
`;

const Message = styled.p`
  max-width: 450px;
`;

const ConfirmButton = styled.div`
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 5px;
  background-color: ${theme.colors.colorCalendarBlue};
  color: ${theme.colors.colorPureWhite};
`;

const XButton = styled.span`
  font-size: 27px;
  color: ${theme.colors.colorCalendarGray};
`;

const DefaultFooter = (onFooterButtonClick, confirmButtonText) => (
  <ConfirmButton onClick={onFooterButtonClick}>
    {confirmButtonText}
  </ConfirmButton>
);

const getGenericErrorMessage = (dataType) =>
  `Unable to delete this ${dataType} at this moment. Try again later.`;

const dataTypeLabel = {
  account: 'Team Member'
};

const DefaultBody = (
  message,
  dataType,
  integrationsContainingMappedDataType,
  showGenericWarningMessage
) => {
  return (
    <>
      {' '}
      <Message>
        {message ||
          (showGenericWarningMessage
            ? getGenericErrorMessage(dataType)
            : `To delete this ${dataType}, you must first unlink them from the following integration(s):`)}
      </Message>
      {!showGenericWarningMessage && (
        <List>
          {integrationsContainingMappedDataType.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
      )}
    </>
  );
};
const DefaultHeader = (toggle, dataType) => (
  <>
    <div>Delete {dataTypeLabel[dataType] || dataType}</div>
    <div type="button" aria-label="Close" onClick={toggle}>
      <XButton aria-hidden="true">&times;</XButton>
    </div>
  </>
);

const HardDeleteWarningModal = ({
  isOpen,
  toggle,
  showGenericWarningMessage,
  integrationsContainingMappedDataType = [], //  (list of integrations the data type has active mapping status on)
  message,
  dataType = '',
  confirmButtonText = 'Okay',

  /** Custom component props */
  renderFooter = null,
  renderBody = null,
  renderHeader = null
}) => {
  return (
    <Modal
      className="hard-delete-warning-modal"
      isOpen={isOpen}
      toggle={toggle}
      autoFocus={false}
    >
      <ModalHeader>
        {renderHeader ? renderHeader() : DefaultHeader(toggle, dataType)}
      </ModalHeader>
      <ModalBody>
        {renderBody
          ? renderBody()
          : DefaultBody(
              message,
              dataType,
              integrationsContainingMappedDataType,
              showGenericWarningMessage
            )}
      </ModalBody>
      <ModalFooter>
        {renderFooter
          ? renderFooter()
          : DefaultFooter(toggle, confirmButtonText)}
      </ModalFooter>
    </Modal>
  );
};

HardDeleteWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default HardDeleteWarningModal;
