import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import styled from 'styled-components';
import {
  setEditTaskId,
  updateEditedTasks,
  updateEditedHomeTasks
} from 'actionCreators';

import { keyifyDate } from 'appUtils/plannerUtils';
import { getBulkModalCalendarType, getAuth } from 'selectors';
import DatePicker from 'components/DatePicker';
import EmptyFlagIcon from 'icons/EmptyFlagIcon';
import CalendarIcon from 'icons/CalendarIcon';
import theme from 'theme';
const {
  colors: { colorDeleteRed, colorCalendarOrange, colorMediumGray5 }
} = theme;
const moment = extendMoment(Moment);

const RedFlagIcon = styled(EmptyFlagIcon)`
  width: 20px;
  height: 20px;
  color: ${colorDeleteRed};
  path {
    fill: ${colorDeleteRed};
  }
`;

const OrangeFlagIcon = styled(EmptyFlagIcon)`
  width: 20px;
  height: 20px;
  color: ${colorCalendarOrange};
  path {
    fill: ${colorCalendarOrange};
  }
`;
const GreyFlagIcon = styled(EmptyFlagIcon)`
  width: 20px;
  height: 20px;
  color: ${colorMediumGray5};
  path {
    fill: ${colorMediumGray5};
  }
`;
const GreyTitle = styled.div`
  color: ${colorMediumGray5};
`;
const RedTitle = styled.div`
  color: ${colorDeleteRed};
  fill: ${colorDeleteRed};
`;
const OrangeTitle = styled.div`
  color: ${colorCalendarOrange};
`;
class BulkUpdateModalHeader extends React.Component {
  handleBulkCalenderSelection = (newDate) => {
    const {
      updateEditedTasks,
      updateEditedHomeTasks,
      updateBulkUpdateDisplayDate,
      pastScheduledTasks,
      homeTasksView,
      calendarType
    } = this.props;
    const scheduleStartDate = newDate ? moment(newDate) : null;
    updateBulkUpdateDisplayDate(scheduleStartDate);
    const newEditedTasks = [...Object.values(pastScheduledTasks)].map(
      (task) => ({
        ...task,
        [calendarType]: scheduleStartDate,
        dateKey: keyifyDate(task.schedule_start)
      })
    );

    homeTasksView
      ? updateEditedHomeTasks(newEditedTasks)
      : updateEditedTasks(newEditedTasks);
  };

  getSelectedFlagTitle = (selectedFlag) => {
    switch (selectedFlag) {
      case 'Due Today':
        return (
          <OrangeTitle>
            <OrangeFlagIcon />
            <span>Due Today</span>
          </OrangeTitle>
        );
      case 'Past Due':
      case 'due_at':
        return (
          <RedTitle>
            <RedFlagIcon />
            <span>Past Due</span>
          </RedTitle>
        );
      case 'Past Schedule':
      case 'schedule_start':
        return (
          <RedTitle>
            <CalendarIcon width={14} height={14} fill={colorDeleteRed} />
            <span>Reschedule Tasks</span>
          </RedTitle>
        );

      default:
        return (
          <GreyTitle>
            <GreyFlagIcon />
            <span>{selectedFlag}</span>
          </GreyTitle>
        );
    }
  };

  renderHeaderTitle = () => {
    const { calendarType, selectedFlag } = this.props;
    const flag = selectedFlag || calendarType;
    return this.getSelectedFlagTitle(flag);
  };

  render() {
    const { bulkUpdateDisplayDate, calendarType } = this.props;
    return (
      <div className="header-container">
        <div className="header">{this.renderHeaderTitle()}</div>
        <DatePicker
          taskIsEditing={false}
          selectedValue={bulkUpdateDisplayDate || 'All'}
          icon={false}
          taskDateType={calendarType === 'due_at' ? 'due' : 'schedule'}
          onChange={this.handleBulkCalenderSelection}
          showDateInput
          bulk
          alwaysRenderInput
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  calendarType: getBulkModalCalendarType(state)
});

const mapDispatchToProps = {
  setEditTaskId,
  updateEditedTasks,
  updateEditedHomeTasks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUpdateModalHeader);
