import React from 'react';
import bindAll from 'lodash/bindAll';

const withHovered = (Component) =>
  class WithHovered extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isHovered: false
      };
      bindAll(this, ['toggleHoveredOn', 'toggleHoveredOff']);
    }

    toggleHoveredOn() {
      this.setState({ isHovered: true });
    }

    toggleHoveredOff() {
      this.setState({ isHovered: false });
    }

    render() {
      return (
        <Component
          {...this.props}
          isHovered={this.state.isHovered}
          toggleHoveredOn={this.toggleHoveredOn}
          toggleHoveredOff={this.toggleHoveredOff}
        />
      );
    }
  };

export default withHovered;
