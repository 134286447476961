import * as constants from 'appConstants';

export const initialState = {
  dont: {
    App: {},
    NavbarContainer: {
      // on startup
      // fetchMemberProjects: true, // do not commit
      // fetchMyProjects: true, // do not commit
      // fetchAllProjects: true, // do not commit
      // fetchCurrentSubscription: true, // do not commit
      // fetchProjectColorPreferences: true, // do not commit
      // fetchBoardPreferences: true, // do not commit
      // fetchTaskGroupPreferences: true, // do not commit
      // fetchTeamMembershipPreferences: true, // do not commit
      // fetchTeamPreferences: true, // do not commit
      // fetchTagPreferences: true, // do not commit
      // fetchTaskPriorities: true, // do not commit
      // fetchActivities: true, // do not commit
      // fetchIntegrations: true, // do not commit
      // checkCompanyAndBillingStatusLoaded: true // do not commit
    }
  }
};

const dev = (state = initialState, action) => {
  const { type, payload } = action;
  return state;
};

export default dev;
