import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import {
  PHASE_BUDGET_STATUS_OPTIONS,
  PHASE_BUDGET_STATUS_DISPLAY
} from 'appConstants/budgetStatuses';
import { AccountFilter } from 'models/filter';

const phaseStatusItems = PHASE_BUDGET_STATUS_OPTIONS.map((item) => item.value);

type PositionFilterDropdownParams = PropsFromRedux & {
  activeFilter: AccountFilter;
  updateAccountFilterLocal: ({ ...filters }: AccountFilter) => void;
  isHidden: boolean;
};

const PhaseBudgetStatusFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  isHidden
}: PositionFilterDropdownParams) => {
  const setFilterItems = (nextStatusViews: Array<string>) => {
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        ...activeFilter.custom,
        phase_budget_status: nextStatusViews
      }
    });
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={phaseStatusItems}
      itemHash={PHASE_BUDGET_STATUS_DISPLAY}
      initialSelectedItems={activeFilter.custom.phase_budget_status}
      setFilterItems={setFilterItems}
      searchEnabled={false}
      toggleLabel="Status"
      togglePluralLabel="Statuses"
      filterId={activeFilter.id}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const component = connector(PhaseBudgetStatusFilterDropdown);
export default component;
