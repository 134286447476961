import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchSyncSettings = {
  request: () => action(constants.FETCH_SYNC_SETTINGS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SYNC_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SYNC_SETTINGS.FAILURE, { payload: { error } })
};
export const updateSyncSettings = {
  request: () => action(constants.UPDATE_SYNC_SETTINGS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_SYNC_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_SYNC_SETTINGS.FAILURE, { payload: { error } })
};
export const fetchQbMembers = {
  request: () => action(constants.FETCH_QB_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_QB_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_QB_MEMBERS.FAILURE, { payload: { error } })
};
export const fetchQbServices = {
  request: () => action(constants.FETCH_SERVICES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SERVICES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SERVICES.FAILURE, { payload: { error } })
};
export const fetchImportedSubcustomers = {
  request: () => action(constants.FETCH_SUBCUSTOMERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SUBCUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SUBCUSTOMERS.FAILURE, {
      payload: { error }
    })
};
export const syncQbMembers = {
  request: () => action(constants.SYNC_MEMBERS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.SYNC_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SYNC_MEMBERS.FAILURE, { payload: { error } })
};
export const syncSubcustomersToProjects = {
  request: () => action(constants.SYNC_SUBCUSTOMERS_TO_PROJECTS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.SYNC_SUBCUSTOMERS_TO_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SYNC_SUBCUSTOMERS_TO_PROJECTS.FAILURE, {
      payload: { error }
    })
};
export const syncServices = {
  request: () => action(constants.SYNC_ACTIVITIES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.SYNC_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SYNC_ACTIVITIES.FAILURE, { payload: { error } })
};
export const syncTimeEntries = {
  request: () => action(constants.SYNC_TIME_ENTRIES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.SYNC_TIME_ENTRIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SYNC_TIME_ENTRIES.FAILURE, { payload: { error } })
};
export const fetchSyncedMembers = {
  request: () => action(constants.FETCH_SYNCED_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SYNCED_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SYNCED_MEMBERS.FAILURE, { payload: { error } })
};
export const fetchSyncedSubcustomers = {
  request: () => action(constants.FETCH_SYNCED_SUBCUSTOMERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SYNCED_SUBCUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SYNCED_SUBCUSTOMERS.FAILURE, { payload: { error } })
};
export const fetchSyncedServices = {
  request: () => action(constants.FETCH_SYNCED_SERVICE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SYNCED_SERVICE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SYNCED_SERVICE.FAILURE, { payload: { error } })
};
export const updateSyncStatus = {
  request: () => action(constants.UPDATE_SYNC_STATUS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_SYNC_STATUS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_SYNC_STATUS.FAILURE, { payload: { error } })
};
export const deleteSyncedSubcustomers = {
  request: () => action(constants.DELETE_SYNCED_SUBCUSTOMERS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_SYNCED_SUBCUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_SYNCED_SUBCUSTOMERS.FAILURE, { payload: { error } })
};
export const deleteSyncedMembers = {
  request: () => action(constants.DELETE_SYNCED_EMPLOYEES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_SYNCED_EMPLOYEES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_SYNCED_EMPLOYEES.FAILURE, { payload: { error } })
};
export const deleteSyncedServices = {
  request: () => action(constants.DELETE_SYNCED_ACTIVITIES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_SYNCED_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_SYNCED_ACTIVITIES.FAILURE, { payload: { error } })
};
export const fetchSubSubCustomers = {
  request: () => action(constants.FETCH_SUB_SUB_CUSTOMERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SUB_SUB_CUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SUB_SUB_CUSTOMERS.FAILURE, {
      payload: { error }
    })
};
export const fetchSyncedSubSubCustomers = {
  request: () => action(constants.FETCH_SYNCED_SUB_SUB_CUSTOMERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SYNCED_SUB_SUB_CUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SYNCED_SUB_SUB_CUSTOMERS.FAILURE, {
      payload: { error }
    })
};
export const syncSubSubCustomers = {
  request: () => action(constants.SYNC_SUB_SUB_CUSTOMERS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.SYNC_SUB_SUB_CUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SYNC_SUB_SUB_CUSTOMERS.FAILURE, { payload: { error } })
};

export const fetchCustomers = {
  request: () => action(constants.FETCH_CUSTOMERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_CUSTOMERS.FAILURE, {
      payload: { error }
    })
};

export const createNewActivities = {
  request: () => action(constants.CREATE_NEW_ACTIVITIES_IN_BATCH.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_NEW_ACTIVITIES_IN_BATCH.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_NEW_ACTIVITIES_IN_BATCH.FAILURE, {
      payload: { error }
    })
};

export const fetchSyncedSubcustomer = {
  request: () => action(constants.FETCH_SYNCED_SUBCUSTOMER.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_SYNCED_SUBCUSTOMER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SYNCED_SUBCUSTOMER.FAILURE, {
      payload: { error }
    })
};

export const cancelSyncStatus = {
  request: () => action(constants.CANCEL_SYNC_STATUS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CANCEL_SYNC_STATUS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CANCEL_SYNC_STATUS.FAILURE, {
      payload: { error }
    })
};

export const undoPendingActivities = {
  request: () => action(constants.UNDO_PENDING_ACTIVITIES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UNDO_PENDING_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNDO_PENDING_ACTIVITIES.FAILURE, {
      payload: { error }
    })
};

export const undoPendingPhases = {
  request: () => action(constants.UNDO_PENDING_PHASES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UNDO_PENDING_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNDO_PENDING_PHASES.FAILURE, {
      payload: { error }
    })
};

export const undoPendingMembers = {
  request: () => action(constants.UNDO_PENDING_MEMBERS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UNDO_PENDING_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNDO_PENDING_MEMBERS.FAILURE, {
      payload: { error }
    })
};

export const undoPendingSubcustomers = {
  request: () => action(constants.UNDO_PENDING_SUBCUSTOMERS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UNDO_PENDING_SUBCUSTOMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNDO_PENDING_SUBCUSTOMERS.FAILURE, {
      payload: { error }
    })
};

export const deletePhaseMapping = {
  request: () => action(constants.DELETE_PHASE_MAPPING.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_PHASE_MAPPING.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PHASE_MAPPING.FAILURE, {
      payload: { error }
    })
};
