import styled from 'styled-components';
import { ReactComponent as LeftArrow } from 'images/schedule-left-arrow-gray.svg';
import { ReactComponent as RightArrow } from 'images/schedule-right-arrow-gray.svg';
import { TodayButton } from './TodayButton';
import { isSameMonth, isSameYear, format } from 'date-fns';

interface DateNavProps {
  onPrev: () => void;
  onNext: () => void;
  onToday: () => void;
  visibleTimeStart: EpochTimeStamp;
  visibleTimeEnd: EpochTimeStamp;
  isTodayOnScreen?: boolean;
}

export const DateNav = ({
  onPrev,
  onNext,
  onToday,
  visibleTimeStart,
  visibleTimeEnd,
  isTodayOnScreen
}: DateNavProps) => {
  const isVisibleTimeSameMonth = isSameMonth(visibleTimeStart, visibleTimeEnd);
  const isVisibleTimeSameYear = isSameYear(visibleTimeStart, visibleTimeEnd);

  const formattedStartMonth = format(visibleTimeStart, 'MMM');
  const formattedEndMonth = format(visibleTimeEnd, 'MMM');
  const startYear = new Date(visibleTimeStart).getFullYear();
  const endYear = new Date(visibleTimeEnd).getFullYear();

  return (
    <DateNavContainer>
      <ArrowButton onClick={onPrev}>
        <LeftArrow height={25} />
      </ArrowButton>
      <DateContainer>
        <DateText>
          {formattedStartMonth}&nbsp;
          {!isVisibleTimeSameYear && <>{startYear}&nbsp;</>}
          {!isVisibleTimeSameMonth && <>-&nbsp;{formattedEndMonth}&nbsp;</>}
          {endYear}
        </DateText>
        <TodayButton active={!isTodayOnScreen} onClick={onToday} />
      </DateContainer>
      <ArrowButton onClick={onNext}>
        <RightArrow height={25} />
      </ArrowButton>
    </DateNavContainer>
  );
};

const DateNavContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
`;

const DateContainer = styled.div`
  min-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
`;

const DateText = styled.div`
  font-size: 19px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  line-height: 1.35rem;
`;
