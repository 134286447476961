import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { updateTeamMemberEmailNotification } from 'actionCreators';
import { Switch } from 'components/Switch';

const StyledEmailNotificationCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailNotificationCell = ({ row }) => {
  const dispatch = useDispatch();
  const { member } = row.original;
  const { id: accountId, email_notification_on } = member.account;

  if (!member) return null;

  const toggleNotification = () => {
    dispatch(
      updateTeamMemberEmailNotification({
        accountId,
        isEmailNotificationOn: !email_notification_on
      })
    );
  };

  return (
    <StyledEmailNotificationCell>
      <Switch isChecked={email_notification_on} onChange={toggleNotification} />
    </StyledEmailNotificationCell>
  );
};

export default EmailNotificationCell;
