import React from 'react';
import theme from 'theme';
import SvgIcon from 'components/SvgIcon';

const SyncedIcon = ({
  width = '10',
  height = '10',
  className,
  color = '#4f4f4f',
  showSlash
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fillRule="none"
  >
    <path
      d="M11.5196 5.12176L11.6996 3.14176C11.7235 2.92567 11.4835 2.75786 11.2918 2.86566L10.7396 3.14176C9.83958 1.42612 8.05182 0.26176 5.99958 0.26176C3.58734 0.26176 1.54734 1.85788 0.875703 4.054C0.791796 4.3301 1.00789 4.61791 1.2957 4.61791C1.48789 4.61791 1.6557 4.48571 1.7157 4.30571C2.27961 2.47007 3.98346 1.13795 5.99958 1.13795C7.7157 1.13795 9.2157 2.11014 9.95958 3.52571L9.39568 3.81352C9.19177 3.90962 9.19177 4.19743 9.37177 4.30571L11.0757 5.33791C11.2674 5.44619 11.4957 5.32619 11.5196 5.12181L11.5196 5.12176Z"
      fill={color}
    />
    <path
      d="M6.0005 11.3522C8.41274 11.3522 10.4527 9.75611 11.1244 7.55999C11.2083 7.2839 10.9922 6.99609 10.7044 6.99609C10.5122 6.99609 10.3444 7.12828 10.2844 7.30828C9.72048 9.14392 8.01662 10.476 6.0005 10.476C4.28438 10.476 2.78438 9.50385 2.0405 8.08828L2.60441 7.80047C2.80831 7.70438 2.80831 7.41657 2.62831 7.30828L0.912675 6.27609C0.732675 6.16828 0.504867 6.28828 0.480483 6.49219L0.300483 8.48395C0.276576 8.70004 0.516579 8.86785 0.708291 8.76004L1.26048 8.48395C2.16048 10.1878 3.94824 11.3522 6.00048 11.3522L6.0005 11.3522Z"
      fill={color}
    />
    {showSlash && (
      <path
        d="M-0.176851 11.8231L-0.353628 11.9999L-0.176851 12.1767L0.530255 12.8838L0.707032 13.0606L0.883809 12.8838L12.8838 0.883808L13.0606 0.707031L12.8838 0.530255L12.1767 -0.176852L11.9999 -0.353629L11.8231 -0.176852L-0.176851 11.8231Z"
        fill={color}
        stroke="white"
        strokeWidth="0.5"
      />
    )}
  </SvgIcon>
);

export default SyncedIcon;
