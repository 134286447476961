import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

const ArchiveBoardModal = ({
  isOpen,
  toggle,
  projectsInGroup,
  archiveGroupOnClick
}) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle} className="archive-group-modal">
      <div className="archive-group-modal-title">
        <h3>Archive Portfolio</h3>
        <button type="button" aria-label="Close" onClick={toggle}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="archive-group-modal-body">
        Are you sure you want to archive this portfolio? {projectsInGroup}{' '}
        projects in this portfolio will be archived and will not be shown in the
        app.
      </div>
      <div className="archive-group-modal-footer">
        <div className="archive-group-modal-footer-yes">
          <button onClick={archiveGroupOnClick}>Yes</button>
        </div>
        <div className="archive-group-modal-footer-cancel">
          <button onClick={toggle}>Cancel</button>
        </div>
      </div>
    </Modal>
  </div>
);

ArchiveBoardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  projectsInGroup: PropTypes.number.isRequired,
  archiveGroupOnClick: PropTypes.func.isRequired
};

export default ArchiveBoardModal;
