import { select, put } from 'redux-saga/effects';
import * as api from '../api';
import * as entityActions from '../entityActions';
import { fetchEntity } from 'sagas/generics';

import { fetchPhasesByProjectIds, fetchAllProjects } from 'actionCreators';
import {
  getAuthToken,
  getProjectHash,
  getFetchedPhasesProjectIdsHash
} from 'selectors';
import * as constants from 'appConstants';
import {
  setProfitReportIsFetchingProjects,
  setProfitReportIsFetchingPhases
} from 'ProfitReportModule/actionCreators';
const { BUDGET_RECORD_DATA_TYPES } = constants;

export function* fetchProfitReportWorker(action) {
  const token = yield select(getAuthToken);
  const projectHash = yield select(getProjectHash);
  const fetchedPhasesProjectIdsHash = yield select(
    getFetchedPhasesProjectIdsHash
  );

  const { params, onReportFetchSuccess = [] } = action.payload;

  const { error, response } = yield fetchEntity(
    entityActions.fetchProfitReport,
    api.fetchProfitReport,
    params,
    [token],
    action
  );

  if (!error) {
    onReportFetchSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }

  // Fetching associated projects and phases
  // isFetchingProjects/Phases is set to true by default in fetch report trigger
  // so need to be set false here if not fetching. handled in reducer
  // if outer condition is false
  if (
    response?.records &&
    [BUDGET_RECORD_DATA_TYPES.PROJECT, BUDGET_RECORD_DATA_TYPES.PHASE].includes(
      params.data_type
    )
  ) {
    // Fetch unloaded projects
    const projectIds = response.records.map((record) => record.project_id);
    const unloadedProjectIds = Array.from(
      new Set(projectIds.filter((id) => !projectHash[id]))
    );
    if (unloadedProjectIds.length) {
      yield put(fetchAllProjects({ projectIds: unloadedProjectIds }));
    } else {
      yield put(setProfitReportIsFetchingProjects(false));
    }
    // Fetch unloaded phases
    const unloadedPhasesProjectIds = Array.from(
      new Set(projectIds.filter((id) => !fetchedPhasesProjectIdsHash[id]))
    );
    if (unloadedPhasesProjectIds.length) {
      yield put(
        fetchPhasesByProjectIds({ projectIds: unloadedPhasesProjectIds })
      );
    } else {
      yield put(setProfitReportIsFetchingPhases(false));
    }
  }
}
