import { useEffect } from 'react';
import { ListItem } from './ListItem';
import { AvailableOption, DependableItem } from './types';
import {
  AVAILABLE_OPTIONS,
  AVAILABLE_OPTIONS_LABEL,
  DEPENDABLE_LABELS,
  DEPENDABLE_TYPES
} from './constants';
import DropdownMenuIcon from 'icons/DropdownMenuIcon';
import moment from 'moment';
import {
  DependencyItemContainer,
  ListItemContainer,
  ArrowIconContainer
} from './styles';
import EllipsisText, { TooltipBehavior } from 'components/EllipsisText';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { getDependencyItemStatusTooltip } from './utils';

export const DefaultBaseDependencyTypeRenderer = ({
  option,
  dependableTypeLabel
}: {
  option: AvailableOption;
  dependableTypeLabel?: string;
}) => {
  const optionLabel = AVAILABLE_OPTIONS_LABEL[option];
  return (
    <ListItem
      primary={
        dependableTypeLabel
          ? `${dependableTypeLabel} ${optionLabel}`
          : optionLabel
      }
    />
  );
};
export const DefaultTargetDependencyTypeRenderer = ({
  option,
  dependableTypeLabel,
  dependableItem
}: {
  option: AvailableOption;
  dependableTypeLabel?: string;
  dependableItem: DependableItem;
}) => {
  const getTrailingText = () => {
    if (option === AVAILABLE_OPTIONS.start) {
      return moment(dependableItem.startDate).format('M/D/YY');
    }
    if (option === AVAILABLE_OPTIONS.end) {
      return dependableItem.endDate
        ? moment(dependableItem.endDate).format('M/D/YY')
        : '';
    }
    return '';
  };

  const optionLabel = AVAILABLE_OPTIONS_LABEL[option];
  return (
    <ListItem
      style={{ minWidth: '200px' }}
      primary={
        dependableTypeLabel
          ? `${dependableTypeLabel} ${optionLabel}`
          : optionLabel
      }
      trailing={getTrailingText()}
    />
  );
};
export const DefaultDependencyItemRenderer = ({
  item: { dependableId, label, startDate, endDate }
}: {
  item: DependableItem;
}) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  const formattedStartDate: string = startDate
    ? moment(startDate).format('M/D/YY')
    : '';
  const formattedEndDate: string = endDate
    ? moment(endDate).format('M/D/YY')
    : '';

  const dependencyItemStatusTooltipText = getDependencyItemStatusTooltip({
    entity: DEPENDABLE_LABELS[DEPENDABLE_TYPES.TASK],
    startDate: formattedStartDate
  });

  const primaryText = (
    <EllipsisText
      maxWidth={135}
      style={{
        color: !formattedStartDate ? theme.colors.colorLightGray15 : undefined
      }}
      tooltip={dependencyItemStatusTooltipText}
      tooltipBehavior={
        dependencyItemStatusTooltipText
          ? TooltipBehavior.AlwaysEnabled
          : TooltipBehavior.EnabledOnEllipsis
      }
      width={135}
    >
      {label}
    </EllipsisText>
  );

  return (
    <DependencyItemContainer>
      <ListItemContainer>
        <ListItem
          key={dependableId}
          primary={primaryText}
          secondary={`${formattedStartDate}${
            formattedEndDate ? ' - ' + formattedEndDate : ''
          }`}
        />
      </ListItemContainer>
      {formattedStartDate && (
        <ArrowIconContainer>
          <DropdownMenuIcon />
        </ArrowIconContainer>
      )}
    </DependencyItemContainer>
  );
};
