import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import bindAll from 'lodash/bindAll';
import { isToday } from 'appUtils/momentUtils';
import { DayNavArrow, DayPlannerWeek, ScrollableWeeks } from '../..';

const moment = extendMoment(Moment);
const WEEKS_BEFORE_AND_AFTER_TODAY = 30;

class DayPlannerNavContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weeksWithinSixMonths: [],
      weeksFromStart: WEEKS_BEFORE_AND_AFTER_TODAY
    };
    bindAll(this, ['viewPrevWeek', 'viewNextWeek', 'handleDayClick']);
  }

  componentDidMount() {
    this.setState({ weeksWithinSixMonths: this.getWeeksWithinSixMonths() });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentMonth = moment(this.props.selectedDate).format('MMMM');
    const nextPropsMonth = moment(nextProps.selectedDate).format('MMMM');
    if (currentMonth !== nextPropsMonth) {
      this.props.setMonthHeader(nextPropsMonth);
    }

    if (isToday(moment(nextProps.selectedDate))) {
      this.scrollToTodayWeek();
    }
  }

  componentDidUpdate(prevProps) {
    const isTodayInView = this.isTodayInView();
    if (prevProps.isTodayInView !== isTodayInView) {
      this.props.setTodayInView(isTodayInView);
    }
  }

  scrollToTodayWeek() {
    this.setState({ weeksFromStart: WEEKS_BEFORE_AND_AFTER_TODAY });
  }

  isTodayInView() {
    return this.state.weeksFromStart === WEEKS_BEFORE_AND_AFTER_TODAY;
  }

  getWeeksWithinSixMonths() {
    const sixMonthsAgo = moment().subtract(
      WEEKS_BEFORE_AND_AFTER_TODAY,
      'weeks'
    );
    const totalWeeks = WEEKS_BEFORE_AND_AFTER_TODAY * 2 + 1;

    const weeksWithinSixMonths = [...new Array(totalWeeks)].map(
      (element, index) => {
        const dayInWeek = sixMonthsAgo.clone().add(index, 'weeks');
        return this.createDaysOfWeekMomentArray(dayInWeek);
      }
    );

    return weeksWithinSixMonths;
  }

  getStartOfWeekInLocalTime(momentDate) {
    const daysFromPrevSunday = momentDate.weekday(); // 0 == sun, 6 == sat
    const startOfWeek = momentDate.clone().subtract(daysFromPrevSunday, 'days'); // Sunday of week LOCAL TIME
    return startOfWeek;
  }

  createDaysOfWeekMomentArray(momentDate) {
    const startOfWeek = this.getStartOfWeekInLocalTime(momentDate);

    const dayOffsets = [0, 1, 2, 3, 4, 5, 6];
    return dayOffsets.map((offset) => startOfWeek.clone().add(offset, 'days'));
  }

  viewNextWeek() {
    const { setDayPlannerDate, fetchDayPlanner } = this.props;
    const selectedDate = moment(this.props.selectedDate);
    selectedDate.add(7, 'days');
    setDayPlannerDate(selectedDate);
    fetchDayPlanner({ date: selectedDate });

    // 'scroll' to next week
    this.setState((prevState) => ({
      weeksFromStart: prevState.weeksFromStart + 1
    }));
  }

  viewPrevWeek() {
    const { setDayPlannerDate, fetchDayPlanner } = this.props;
    const selectedDate = moment(this.props.selectedDate);
    selectedDate.subtract(7, 'days');
    setDayPlannerDate(selectedDate);
    fetchDayPlanner({ date: selectedDate });

    // 'scroll' to prev week
    this.setState((prevState) => ({
      weeksFromStart: prevState.weeksFromStart - 1
    }));
  }

  handleDayClick(momentDate) {
    const { setDayPlannerDate, fetchDayPlanner } = this.props;
    return () => {
      setDayPlannerDate(momentDate);
      fetchDayPlanner({ date: momentDate });
    };
  }

  renderNearbyWeeks(weekArray) {
    /*
      map over all weeks within 6 months of today
      only render current week, prev week, and next week to keep DOM lightweight
      need next/prev week so we can animate/scroll into them
    */
    const { selectedDate } = this.props;
    const startOfWeek = weekArray[0];
    const startOfSelected = this.getStartOfWeekInLocalTime(
      moment(selectedDate)
    );
    const isWithinOneWeek =
      Math.abs(startOfSelected.diff(startOfWeek, 'weeks')) <= 1;

    if (isWithinOneWeek) {
      return (
        <DayPlannerWeek
          key={weekArray[0]._d}
          weekArray={weekArray}
          selectedDate={selectedDate}
          handleDayClick={this.handleDayClick}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="calendar-strip-container">
        <DayNavArrow direction="left" handleClick={this.viewPrevWeek} />
        <ScrollableWeeks weeksFromStart={this.state.weeksFromStart}>
          {this.state.weeksWithinSixMonths.map((week) => {
            return this.renderNearbyWeeks(week);
          })}
        </ScrollableWeeks>
        <DayNavArrow direction="right" handleClick={this.viewNextWeek} />
      </div>
    );
  }
}

export default DayPlannerNavContainer;
