import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getReportsModule } from 'ReportsModule/package/reportsModule';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import ReportsPermissionCheck from '../components/ReportsPermissionCheck';

const ReportsContainer = lazy(() => import('./ReportsContainer'));

const LoadReportsContainer = (props) => (
  <DynamicModuleLoader
    modules={[getReportsModule(), getBudgetModule(), getCapacitiesModule()]}
  >
    <Suspense fallback={<div />}>
      <ReportsPermissionCheck navToHomeIfCantRender={true}>
        <ReportsContainer {...props} />
      </ReportsPermissionCheck>
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadReportsContainer;
