import React, { useRef } from 'react';
import TaskAssigneeCell from '../TaskAssigneeCell';
import CellContainer from './CellContainer';
const noop = () => {};

const AssigneeCell = ({ row }) => {
  const target = useRef(null);

  const {
    handleMultiAssignDone = noop,
    createRow,
    taskProps = {}
  } = row.original;

  const { taskIsEditing, isNewTask, taskEditProperty, projectDetailView } =
    taskProps;

  return (
    <CellContainer taskProps={taskProps} taskProperty="assigned" row={row}>
      <TaskAssigneeCell
        task={row.original}
        handleMultiAssignDone={handleMultiAssignDone}
        isEditing={taskIsEditing && taskEditProperty === 'assignee'}
        isNewTask={createRow}
        projectDetailView={projectDetailView}
      />
    </CellContainer>
  );
};
export default AssigneeCell;
