import React from 'react';
import PropTypes from 'prop-types';
import partial from 'lodash/partial';
import cn from 'classnames';
import { MemberInitials } from '..';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchMemberBudgets,
  openRemoveMemberModal
} from 'BudgetModule/actionCreators';
import { fetchPhasesByProjectIds, navigateToMemberModal } from 'actionCreators';
import {
  makeGetMemberIsGuestByAccountId,
  getSelectedTeamId,
  getMatchedRouteParams
} from 'selectors';
import Popover from 'components/Popover';
import {
  StyledSelectedOption,
  StyledDownArrow,
  StyledDropdownItem
} from './styles';

import { PROJECT, PORTFOLIO } from './constants';

import MemberModalRowThreeDotMenu from 'views/inviteMemberComponents/MemberModalRowThreeDotMenu.jsx';
import { makeGetMemberIsContractorByAccountId } from 'PermissionsModule/selectors';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import {
  deletePrivatePortfolioMembership,
  deletePublicPortfolioMembership,
  createPortolioManager,
  editPublicPortfolioMembership,
  editPrivatePortfolioMembership
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import {
  CREATE_PORTFOLIO_MANAGER_TIP,
  EDIT_PORTFOLIO_MEMBERSHIP_TIP,
  CREATE_PROJECT_MANAGER_TIP,
  EDIT_PROJECT_MEMBERSHIP_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { noop } from 'appUtils';
import { withProjectPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/hocs/withProjectPermissionProvider';
import { ROLE_NAME } from 'PermissionsModule/constants';

class MemberLine extends React.Component {
  state = {
    // overlayOn: false,
    roleDropdownOpen: false
  };

  popoverTarget = React.createRef();
  getPermissions = () => {
    const {
      matchedParams: { projectId },
      selectedTeamId
    } = this.props;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  componentDidUpdate(prevProps) {
    rebuildTooltip();
  }

  onChangeSelect = (event) => {
    const { member, roles, changeRoleFromLocalList, selection } = this.props;
    const role = roles.find((r) => r.id === parseInt(event.target.value, 10));
    const id = selection ? selection.id : null;
    const permissions = this.getPermissions();
    changeRoleFromLocalList(member, role, id, permissions);
  };

  onSelectValue = (value) => {
    const { member, roles, changeRoleFromLocalList, selection } = this.props;
    const role = roles.find((r) => r.id === value);
    const id = selection ? selection.id : null;
    const permissions = this.getPermissions();
    changeRoleFromLocalList(member, role, id, permissions);
    this.hideRoleDropdown();
  };

  onDelete = (e) => {
    const {
      deleteMemberFromSelection,
      openRemoveMemberModal,
      matchedParams: { projectId },
      member,
      selection,
      me,
      fetchMemberBudgets,
      fetchPhasesByProjectIds,
      groupType
    } = this.props;
    if (groupType === PROJECT) {
      openRemoveMemberModal({
        memberBudget: member.memberBudget
      });
    } else if (deleteMemberFromSelection) {
      const permissions = this.getPermissions();
      if (selection && selection.id) {
        deleteMemberFromSelection(member, selection, me, permissions);
      } else {
        deleteMemberFromSelection(member, null, null, permissions);
      }
    }
    if (projectId) {
      fetchMemberBudgets({ projectId });

      fetchPhasesByProjectIds({
        projectIds: [projectId]
      });
    }
  };

  handleNavigateToMemberModalOverView = () => {
    const { navigateToMemberModal, member } = this.props;
    navigateToMemberModal({
      memberId: member.account.id,
      memberViewType: 'overview'
    });
  };

  handleNavigateToMemberModalScheduleTab = () => {
    const { navigateToMemberModal, member } = this.props;
    navigateToMemberModal({
      memberId: member.account.id,
      memberViewType: 'schedule'
    });
  };

  showOverlay = () => {
    this.setState({
      overlayOn: true
    });
  };

  hideOverlay = () => {
    this.setState({
      overlayOn: false
    });
  };

  showRoleDropdown = () => {
    this.setState({
      roleDropdownOpen: true
    });
  };

  hideRoleDropdown = () => {
    this.setState({
      roleDropdownOpen: false
    });
  };

  // Determine if you can delete from portfolio
  getCanDeletePortfolioMembership = () => {
    const {
      checkPermission,
      deletePrivatePortfolioMembership,
      deletePublicPortfolioMembership,
      selection: { id, is_private: isPrivate },
      selectedTeamId
    } = this.props;

    const deleteFromPortfolioCheck = isPrivate
      ? deletePrivatePortfolioMembership
      : deletePublicPortfolioMembership;

    return checkPermission(deleteFromPortfolioCheck, {
      permissions: {
        teamId: selectedTeamId,
        boardId: id
      }
    });
  };

  getCanEditPortfolioMembership = () => {
    const {
      checkPermission,
      editPublicPortfolioMembership,
      editPrivatePortfolioMembership,
      selection: { id, is_private: isPrivate },
      selectedTeamId
    } = this.props;

    const editPortfolioMembershipCheck = isPrivate
      ? editPrivatePortfolioMembership
      : editPublicPortfolioMembership;

    return checkPermission(editPortfolioMembershipCheck, {
      permissions: {
        boardId: id,
        teamId: selectedTeamId
      }
    });
  };

  getCanCreatePortfolioManager = () => {
    const {
      checkPermission,
      createPortolioManager,
      selection: { id },
      selectedTeamId
    } = this.props;

    return checkPermission(createPortolioManager, {
      permissions: {
        boardId: id,
        teamId: selectedTeamId
      }
    });
  };

  getCanCreateProjectManager = () => {
    const {
      projectPermissions: { canCreateProjectManagers }
    } = this.props;

    return canCreateProjectManagers;
  };

  getCanEditProjectMembership = () => {
    const {
      projectPermissions: { canEditProjectMembership }
    } = this.props;

    return canEditProjectMembership;
  };

  render() {
    const {
      member,
      deleteMemberFromSelection,
      selection,
      me,
      roles,
      teamMemberModal,
      memberIsGuest,
      memberIsContractor,
      projectMembers,
      groupType,
      listRef,
      alwaysShowThreeDot,
      projectId
    } = this.props;
    const canDelete =
      groupType !== PORTFOLIO || this.getCanDeletePortfolioMembership();
    const canCreatePortfolioManager =
      groupType !== PORTFOLIO || this.getCanCreatePortfolioManager();
    const canEditPortfolioMembership =
      groupType !== PORTFOLIO || this.getCanEditPortfolioMembership();

    const canCreateProjectManager =
      groupType !== PROJECT || this.getCanCreateProjectManager();

    const canEditProjectMembership =
      groupType !== PROJECT || this.getCanEditProjectMembership();

    const disableOpenMemberRoleDropdown =
      (groupType === PORTFOLIO && !canEditPortfolioMembership) ||
      (groupType === PROJECT && !canEditProjectMembership);

    const disableDelete =
      (teamMemberModal && me && me.id === member.account.id) || !canDelete;

    const memberRoleName = roles.find(
      (r) =>
        member.role_ids?.find((roleId) => r.id === roleId) ||
        r.id === member.role_id
    )?.name;

    return (
      <li key={member.account.id} className="member-item project-member">
        <div className="remove-member-container">
          {deleteMemberFromSelection && !disableDelete ? (
            <MemberModalRowThreeDotMenu
              listRef={listRef}
              handleDelete={
                groupType === PROJECT ? this.onDelete : this.showOverlay
              }
              projectId={projectId}
              alwaysShowThreeDot={alwaysShowThreeDot}
              handleNavigateToMemberModalOverView={
                this.handleNavigateToMemberModalOverView
              }
              handleNavigateToMemberModalScheduleTab={
                this.handleNavigateToMemberModalScheduleTab
              }
            />
          ) : null}
        </div>
        <MemberInitials
          member={member}
          isGuest={memberIsGuest}
          inProjectModal={true}
          projectMembers={projectMembers}
          classes={cn('regular-member-no-hover selected', {
            'logged-member-no-hover': me && member.account.id === me.id,
            pending: member.is_pending
          })}
          onClick={(e) => {
            e.preventDefault();
          }}
          nonBaseMemberRoleTop="19px"
          nonBaseMemberRoleLeft="21px"
        />
        <span
          data-for="member-line-tooltip"
          data-tip={member.account.name}
          className={cn('member-name', { pending: member.is_pending })}
          data-testid={`member-${member.account.name}`}
        >
          {member.account.name}
        </span>

        <div className="member-info">
          {member.is_pending && <span className="pending-text">Pending</span>}
          {memberIsGuest && <span className="member-is-guest">Guest</span>}
          {memberIsContractor && (
            <span className="member-is-contractor">Contractor</span>
          )}
        </div>

        {roles && roles.length > 0 ? (
          <div className="pull-right" ref={this.popoverTarget}>
            <StyledSelectedOption
              onClick={
                !disableOpenMemberRoleDropdown ? this.showRoleDropdown : noop
              }
              isActive={this.state.roleDropdownOpen}
              className={cn('member-line-selection-option', {
                'is-active': this.state.roleDropdownOpen,
                disabled: disableOpenMemberRoleDropdown
              })}
              data-testid={`role-dropdown-${member.account.name}`}
              data-for="app-tooltip"
              data-effect="solid"
              data-class="center"
              data-tip={
                !canEditPortfolioMembership
                  ? EDIT_PORTFOLIO_MEMBERSHIP_TIP
                  : !canEditProjectMembership
                  ? EDIT_PROJECT_MEMBERSHIP_TIP
                  : undefined
              }
              data-tip-disable={!disableOpenMemberRoleDropdown}
            >
              {memberRoleName}
              <StyledDownArrow />
            </StyledSelectedOption>
            {this.state.roleDropdownOpen && (
              <Popover
                isOpen
                target={this.popoverTarget}
                closePopover={this.hideRoleDropdown}
                placement="bottom-end"
                className="members-role-dropdown"
              >
                {roles.map((role) => {
                  const disablePortfolioManagerOption =
                    !canCreatePortfolioManager &&
                    (role.name === ROLE_NAME.PORTFOLIO_MANAGER ||
                      memberRoleName === ROLE_NAME.PORTFOLIO_MANAGER);
                  const disableProjectManagerOption =
                    !canCreateProjectManager &&
                    (role.name === ROLE_NAME.PROJECT_MANAGER ||
                      memberRoleName === ROLE_NAME.PROJECT_MANAGER);

                  const disabledTooltipContent = disablePortfolioManagerOption
                    ? CREATE_PORTFOLIO_MANAGER_TIP
                    : disableProjectManagerOption
                    ? CREATE_PROJECT_MANAGER_TIP
                    : undefined;

                  const disabled =
                    disablePortfolioManagerOption ||
                    disableProjectManagerOption;
                  return (
                    <StyledDropdownItem
                      key={role.id}
                      onClick={(e) => {
                        if (!disabled) {
                          e.preventDefault();
                          e.stopPropagation();
                          this.onSelectValue(role.id);
                        }
                      }}
                      data-testid={`role-option-${role.name}`}
                      data-for="app-tooltip"
                      data-effect="solid"
                      data-class="center"
                      data-tip={disabledTooltipContent}
                      data-tip-disable={!disabled}
                      className={cn({ disabled })}
                    >
                      {role.name}
                    </StyledDropdownItem>
                  );
                })}
                {!disableDelete ? (
                  <StyledDropdownItem
                    key={'remove'}
                    onClick={() => {
                      if (groupType === PROJECT) {
                        this.onDelete();
                      } else {
                        this.showOverlay();
                      }
                      this.hideRoleDropdown();
                    }}
                  >
                    Remove from {groupType}
                  </StyledDropdownItem>
                ) : null}
              </Popover>
            )}
          </div>
        ) : null}
        {this.state.overlayOn ? (
          <div className="warning-overlay">
            <p>Are you sure you want to remove this member?</p>
            <div>
              <span onClick={this.onDelete} className="button">
                Yes
              </span>
              <span onClick={this.hideOverlay} className="button">
                No
              </span>
            </div>
          </div>
        ) : null}
      </li>
    );
  }
}
MemberLine.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  member: PropTypes.object.isRequired,
  deleteMemberFromSelection: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array,
  changeRoleFromLocalList: PropTypes.func,
  teamMemberModal: PropTypes.bool
};

const makeMapStateToProps = () => {
  const getMemberIsGuest = makeGetMemberIsGuestByAccountId();
  const getMemberIsContractor = makeGetMemberIsContractorByAccountId();

  const mapStateToProps = (state, ownProps) => ({
    memberIsGuest: getMemberIsGuest(state, ownProps.member),
    memberIsContractor: getMemberIsContractor(state, ownProps.member),
    selectedTeamId: getSelectedTeamId(state),
    matchedParams: getMatchedRouteParams(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchMemberBudgets,
  fetchPhasesByProjectIds,
  openRemoveMemberModal,
  deletePrivatePortfolioMembership,
  deletePublicPortfolioMembership,
  createPortolioManager,
  editPublicPortfolioMembership,
  editPrivatePortfolioMembership,
  navigateToMemberModal
};

export default compose(
  withProjectPermissionProvider,
  withPermissionsCheck,
  withRouter
)(connect(makeMapStateToProps, mapDispatchToProps)(MemberLine));
