import React from 'react';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import autosize from 'autosize';
import Highlighter from 'appUtils/highlighter';
import TextareaAutosize from 'react-autosize-textarea';
import { getTruncatedDescription } from 'appUtils';
import TaskCardIcons from 'views/taskDisplay/dayPlannerComponents/TaskCardIcons';

class DailyTaskDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taskTitle: props.description || '',
      isEditingDescription: props.isEditingDescription
    };
    bindAll(this, [
      'linkify',
      'displayDescription',
      'onChangeTitle',
      'updateDescriptionAndBlur',
      'handleEnterPress',
      'moveCaretToEnd'
    ]);
  }

  componentDidMount() {
    if (this.state.isEditingDescription) {
      document.querySelector('.task-description textarea').focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { description } = this.props;
    if (nextProps.description !== description) {
      this.setState({ taskTitle: nextProps.description });
    }
    if (!this.props.isEditingDescription && nextProps.isEditingDescription) {
      this.setState({ isEditingDescription: nextProps.isEditingDescription });
    } else if (
      this.props.isEditingDescription &&
      !nextProps.isEditingDescription
    ) {
      this.setState({ isEditingDescription: nextProps.isEditingDescription });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isEditingDescription && this.state.isEditingDescription) {
      document.querySelector('.task-description textarea').focus();
      autosize(document.querySelector('textarea'));
    }
  }

  linkify(body) {
    if (body) {
      body = body.replace(
        /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi,
        function (url) {
          let full_url = url;
          if (!full_url.match('^https?://')) {
            full_url = 'http://' + full_url;
          }
          return '<a href="' + full_url + '" target="_blank">' + url + '</a>';
        }
      );
    }
    return body;
  }

  displayDescription() {
    const { taskTitle } = this.state;
    const { searchWords } = this.props;

    const searchRegex = [
      /\B#([a-z0-9]{2,})(?![~!@#$%^&*()=+_`\-\|\/'\[\]\{\}]|[?.,]*\w)/,
      /((?:https\:\/\/)|(?:http\:\/\/)|(?:www\.))?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\??)[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~]+)/
    ];
    const searchParams = [...searchRegex, ...searchWords];

    const { truncatedDescription } = getTruncatedDescription({
      fullText: taskTitle,
      singleLineCutoff: 35,
      lastLineCutoff: 20,
      numLines: 3
    });

    return (
      <Highlighter
        highlightClassName={'highlight'}
        highlightTag="span"
        searchWords={searchParams}
        textToHighlight={truncatedDescription}
      />
    );
  }

  onChangeTitle(e) {
    this.props.onChange(e);
    this.setState({
      taskTitle: e.target.value
    });
  }

  updateDescriptionAndBlur() {
    const { task, updateDescription, description } = this.props;
    const isTaskBeingAdded = task.id === 'new';
    if (isTaskBeingAdded) {
      return;
    }
    if (!this.state.taskTitle.length) {
      this.setState({ taskTitle: description });
    } else if (this.state.taskTitle !== description) {
      updateDescription({ description: this.state.taskTitle });
    }
    this.setState({ isEditingDescription: false });
  }

  handleEnterPress(e) {
    const { updateDescription, description, task, setEditTaskId } = this.props;
    const { taskTitle } = this.state;
    const isEnterKey = e.keyCode === 13;
    if (isEnterKey) {
      updateDescription({ description: taskTitle });
      this.setState({ isEditingDescription: false });
      setEditTaskId(null);
    }
  }

  moveCaretToEnd(e) {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  }

  render() {
    const { task, toggleTaskModal } = this.props;
    const isTaskBeingAdded = task.id === 'new';

    return (
      <div
        className={cn('task-description', {
          'is-editing': this.state.isEditingDescription
        })}
        onBlur={() => {
          if (isTaskBeingAdded) {
          } else {
            this.setState({ isEditingDescription: false });
          }
        }}
      >
        {this.state.isEditingDescription ? (
          <TextareaAutosize
            className="description-textarea"
            onFocus={this.moveCaretToEnd}
            value={this.state.taskTitle}
            onChange={this.onChangeTitle}
            onBlur={this.updateDescriptionAndBlur}
            onKeyDown={this.handleEnterPress}
          />
        ) : this.state.taskTitle ? (
          <div className="description-wrapper">
            {this.displayDescription()}
            <TaskCardIcons
              toggleTaskModal={toggleTaskModal}
              task={task}
              className="task-icons-container"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default DailyTaskDescription;
