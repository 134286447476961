import React from 'react';
import SvgIcon from 'components/SvgIcon';

const AddMembersIconNoClipPath = ({ width = '16', height = '16' }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="4.50124"
        cy="6.87942"
        rx="1.60964"
        ry="1.60964"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
      <ellipse
        cx="9.24935"
        cy="5.51205"
        rx="2.01205"
        ry="2.01205"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
      <path
        d="M1.28223 12.8357C1.28223 11.0578 2.72355 9.61646 4.50151 9.61646C5.14094 9.61646 5.73684 9.80288 6.2378 10.1244"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
      <path
        d="M13.3548 12.8356C13.3548 10.6132 11.5532 8.81152 9.33074 8.81152C7.10829 8.81152 5.30664 10.6132 5.30664 12.8356"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
      <line
        x1="11.8457"
        y1="5.36367"
        x2="15.6183"
        y2="5.36367"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
      <line
        x1="13.7094"
        y1="7.27271"
        x2="13.7094"
        y2="3.5001"
        stroke="#4F4F4F"
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
};

export default AddMembersIconNoClipPath;
