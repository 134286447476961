import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const startOfflineMode = () =>
  createAction(constants.START_OFFLINE_MODE);

export const tryStartOnlineMode = () =>
  createAction(constants.TRY_START_ONLINE_MODE);

export const startOnlineMode = () => createAction(constants.START_ONLINE_MODE);
