const LINKS = {
  HELP_CENTER_LINK: 'https://mosaic.document360.io/v1/en',
  ALL_VIDEO_URL: 'https://mosaic.document360.io/docs/video-walkthroughs',
  ORGANIZATION_ADMIN_SETTINGS_VIDEO_URL:
    'https://player.vimeo.com/video/567038295?badge=0&autopause=0&player_id=0&app_id=58479',
  COST_RATE_AND_BILL_RATE_VIDEO_URL:
    'https://player.vimeo.com/video/567037724?badge=0&autopause=0&player_id=0&app_id=58479',
  PROJECT_BUDGET_VIDEO_URL:
    'https://player.vimeo.com/video/567036840?badge=0&autopause=0&player_id=0&app_id=58479',
  WORKLOAD_REPORT_VIDEO_URL:
    'https://player.vimeo.com/video/569569264?badge=0&autopause=0&player_id=0&app_id=58479'
};

export default LINKS;
