import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import {
  StyledCell,
  StyledDate,
  DeleteIconContainer,
  StyledDeleteIcon,
  HeaderContainer,
  StyledMemberName,
  StyledRowContainer,
  StyledSmallPencilIcon,
  AddRateContainer,
  StyledHeaderCell,
  MiniHeaderContainer,
  CloseIconStyle,
  EmptyCell,
  IssueIconContainer
} from 'BudgetModule/components/BudgetModal/styles';
import { DoneButton } from 'CapacityModule/components/WorkloadModal/styles';
import { EARLIEST_RATE_START_DISPLAY } from 'BudgetModule/constants';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { connect } from 'react-redux';
import { makeGetOrderedMemberTeamRates } from 'BudgetModule/selectors';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import UnassignedMemberIcon from 'icons/UnassignedMemberIcon';
import { getMe } from 'selectors';
import {
  RATE_MODAL_TYPES,
  RATE_MODAL_COLUMNS,
  RATE_MODAL_ADD_ROW_LABEL
} from 'appConstants';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import styled from 'styled-components';
import {
  FormattedCurrencyByProject,
  FormattedCurrencyByTeam
} from 'components/FormatCurrency';

const noop = () => {};

export const StartDateCell = ({
  isEarliestRate,
  disableChangeEarliestRateStart,
  updateDate,
  item,
  modalType
}) => {
  return (
    <StyledDate
      className="start-date"
      isEarliest={isEarliestRate && disableChangeEarliestRateStart}
      center
    >
      {isEarliestRate && disableChangeEarliestRateStart ? (
        <div> {EARLIEST_RATE_START_DISPLAY} </div>
      ) : (
        <DateRangeCalendar
          isSingleDay
          showInputs
          labels={startLabels}
          onSubmit={({ startDate }) => {
            if (startDate?.isValid?.()) {
              updateDate(item, startDate, 'startDate');
            }
          }}
          itemStartDate={item.start_date}
          customInput={(startDate, endDate, handleOpen) => (
            <div onClick={handleOpen}>
              {moment(startDate).format('M/DD/YY')}
            </div>
          )}
        />
      )}
    </StyledDate>
  );
};

export const EndDateCell = ({ updateDate, item, modalType }) => {
  const isOngoing = ['Present', null].includes(item.end_date);
  return (
    <StyledDate className="end-date" center isDisabled={isOngoing}>
      <DateRangeCalendar
        isSingleDay
        showInputs
        labels={endLabels}
        onSubmit={({ startDate: endDate }) => {
          if (endDate?.isValid?.()) {
            updateDate(item, endDate, 'endDate');
          }
        }}
        itemStartDate={item.end_date}
        customInput={(startDate, endDate, handleOpen) => (
          <div onClick={isOngoing ? noop : handleOpen}>
            {isOngoing ? 'Ongoing' : moment(item.end_date).format('M/DD/YY')}
          </div>
        )}
      />
    </StyledDate>
  );
};

export const DateSeparatorCell = () => <StyledCell center>-</StyledCell>;

export const MultiplierCell = ({ item }) => {
  const isOutsideTarget = item.multiplier_data?.out_of_range;
  return (
    <StyledCell
      data-for="app-tooltip"
      data-tip={
        isOutsideTarget
          ? `Outside of Target Range <br/> ${item.rate.multiplier_low} - ${item.rate.multiplier_high}`
          : ''
      }
      data-html
      data-class="center"
      className="multiplier"
      center
    >
      {item.multiplier_data?.actual_multiplier || ''}
      {isOutsideTarget && <IssueIconContainer>!</IssueIconContainer>}
    </StyledCell>
  );
};

export const RateCell = ({ editRate, item, projectId }) => {
  const currencyFormatterProps = projectId ? { projectId } : {};
  const FormattedCurrencyComponent = projectId
    ? FormattedCurrencyByProject
    : FormattedCurrencyByTeam;
  return (
    <StyledCell className="rate" onClick={() => editRate(item)}>
      {item.actual_hourly_rate >= 0 ? (
        <>
          {FormattedCurrencyComponent ? (
            <FormattedCurrencyComponent
              currencyFormatterProps={currencyFormatterProps}
              currencyValue={item.actual_hourly_rate}
            />
          ) : (
            item.actual_hourly_rate
          )}
          /h
        </>
      ) : (
        <FormattedCurrencyComponent
          currencyFormatterProps={currencyFormatterProps}
          currencyValue={item.rate.rate}
        />
      )}
    </StyledCell>
  );
};

export const DescriptionCell = ({ editRate, item }) => (
  <StyledCell className="position" onClick={() => editRate(item)}>
    <span>{item.rate.description}</span>
    <StyledSmallPencilIcon />
  </StyledCell>
);

export const DeleteCell = ({
  handleDelete,
  item,
  rates,
  disableDeleteOnlyRate,
  isOnlyRate
}) => {
  const deleteDisabled = isOnlyRate && disableDeleteOnlyRate;
  return (
    <DeleteIconContainer
      data-for="app-tooltip"
      data-tip={deleteDisabled ? 'Unable to delete only rate.' : ''}
      data-effect="solid"
      onClick={() => !deleteDisabled && handleDelete(item, rates)}
    >
      <StyledDeleteIcon />
    </DeleteIconContainer>
  );
};

export const cells = {
  [RATE_MODAL_COLUMNS.DELETE.name]: DeleteCell,
  [RATE_MODAL_COLUMNS.DESCRIPTION.name]: DescriptionCell,
  [RATE_MODAL_COLUMNS.RATE.name]: RateCell,
  [RATE_MODAL_COLUMNS.MULTIPLIER.name]: MultiplierCell,
  [RATE_MODAL_COLUMNS.START_DATE.name]: StartDateCell,
  [RATE_MODAL_COLUMNS.DATE_SEPARATOR.name]: DateSeparatorCell,
  [RATE_MODAL_COLUMNS.END_DATE.name]: EndDateCell
};

export const HeaderCell = ({ className, column }) => (
  <StyledHeaderCell className={className}>{column}</StyledHeaderCell>
);

export const headerCells = {
  [RATE_MODAL_COLUMNS.DELETE.name]: EmptyCell,
  [RATE_MODAL_COLUMNS.DESCRIPTION.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.RATE.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.MULTIPLIER.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.START_DATE.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.DATE_SEPARATOR.name]: HeaderCell,
  [RATE_MODAL_COLUMNS.END_DATE.name]: HeaderCell
};

export const columns = {
  [RATE_MODAL_TYPES.COST_RATE]: [
    RATE_MODAL_COLUMNS.DELETE,
    RATE_MODAL_COLUMNS.RATE,
    RATE_MODAL_COLUMNS.START_DATE,
    RATE_MODAL_COLUMNS.DATE_SEPARATOR,
    RATE_MODAL_COLUMNS.END_DATE
  ],
  [RATE_MODAL_TYPES.BILL_RATE]: [
    RATE_MODAL_COLUMNS.DELETE,
    RATE_MODAL_COLUMNS.RATE,
    RATE_MODAL_COLUMNS.DESCRIPTION,
    RATE_MODAL_COLUMNS.START_DATE,
    RATE_MODAL_COLUMNS.DATE_SEPARATOR,
    RATE_MODAL_COLUMNS.END_DATE
  ],
  [RATE_MODAL_TYPES.UNASSIGNED]: [
    RATE_MODAL_COLUMNS.DELETE,
    RATE_MODAL_COLUMNS.DESCRIPTION,
    RATE_MODAL_COLUMNS.RATE
  ]
};
export const colWidths = {
  delete: 34
};
export const modalColWidths = {
  [RATE_MODAL_TYPES.COST_RATE]: `${colWidths.delete}px 195px 76px 5px 76px`,
  [RATE_MODAL_TYPES.BILL_RATE]: `${colWidths.delete}px 230px 190px 80px 24px 80px`,
  [RATE_MODAL_TYPES.UNASSIGNED]: `${colWidths.delete}px 230px auto 16px`
};
export const listWidths = {
  [RATE_MODAL_TYPES.COST_RATE]: 375 + 16,
  [RATE_MODAL_TYPES.BILL_RATE]: 627 + 16,
  [RATE_MODAL_TYPES.UNASSIGNED]: 627 + 16
};

const copy = {
  headerInitial: 'initial header',
  headerEdit: 'select rate',
  headerAdd: 'add new rate',
  sticky: 'add rate',
  footerInitial: 'add/edit rate',
  footerEdit: 'done'
};

const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}
`;

const startLabels = {
  start: 'START'
};

const endLabels = {
  start: 'END'
};

/* ------------------------------------ - ----------------------------------- */

const TeamRatesDropdown = ({
  target,
  memberName,
  teamMembershipId,
  rates,
  onStickyClick,
  handleClose,
  handleDelete,
  handleDateChange,
  editRate,
  member,
  disableChangeEarliestRateStart,
  disableDeleteOnlyRate,
  me,
  modalType = RATE_MODAL_TYPES.BILL_RATE,
  headerText
}) => {
  const renderHeaderText = useCallback(() => {
    return (
      <HeaderContainer style={{ paddingLeft: colWidths.delete }}>
        {teamMembershipId || member ? (
          <MemberInitials
            member={member}
            size="medium"
            classes={`sidebar-member-initials selected ${
              me?.id === member?.account?.id ? 'logged' : 'regular'
            }-member-no-hover`}
            id={teamMembershipId}
          />
        ) : (
          <UnassignedMemberIcon />
        )}
        <StyledMemberName
          style={{
            maxWidth: listWidths[modalType] - colWidths.delete - 32 - 30
          }}
        >
          {headerText || memberName}
        </StyledMemberName>
      </HeaderContainer>
    );
  }, [member, me, teamMembershipId, modalType, headerText, memberName]);

  const updateDate = useCallback(
    (item, date, dateType) => {
      handleDateChange(item, date, dateType);
    },
    [handleDateChange]
  );

  const renderTableBodyHeader = useCallback(() => {
    return (
      <div style={{ width: listWidths[modalType] }}>
        <MiniHeaderContainer colWidths={modalColWidths[modalType]}>
          {columns[modalType].map((column, index) => {
            const Cell = headerCells[column.name];
            return (
              <Cell column={column.label} className={column.name} key={index} />
            );
          })}
        </MiniHeaderContainer>
        <AddRateContainer
          onClick={onStickyClick}
          emptyList={!rates?.length}
          style={{
            width: `calc(100% - ${colWidths.delete}px)`,
            marginLeft: colWidths.delete
          }}
        >
          {RATE_MODAL_ADD_ROW_LABEL[modalType]}
        </AddRateContainer>
      </div>
    );
  }, [modalType, onStickyClick, rates]);

  const renderItem = useCallback(
    ({ item, selectCallback }) => {
      const earliestRateByDate = rates[rates.length - 1];
      const isEarliestRate = item.id === earliestRateByDate.id;
      const isOnlyRate = rates.length === 1;
      const props = {
        item,
        rates,
        handleDelete,
        editRate,
        updateDate,
        isEarliestRate,
        disableChangeEarliestRateStart,
        modalType,
        disableDeleteOnlyRate,
        isOnlyRate
      };
      return (
        <StyledRowContainer
          key={item.id}
          colWidths={modalColWidths[modalType]}
          isLast={isEarliestRate}
        >
          {columns[modalType].map((column, index) => {
            const Cell = cells[column.name];
            return <Cell {...props} key={index} />;
          })}
        </StyledRowContainer>
      );
    },
    [
      disableChangeEarliestRateStart,
      editRate,
      handleDelete,
      modalType,
      rates,
      updateDate,
      disableDeleteOnlyRate
    ]
  );

  const renderHeaderButton = useCallback(
    () => (
      <CloseIconStyle onClick={handleClose}>
        <DoneButton>Done</DoneButton>
      </CloseIconStyle>
    ),
    [handleClose]
  );

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <MultiStepFlyout
      copy={copy}
      target={target}
      items={rates}
      idKey="id"
      renderHeader={renderHeaderText}
      renderEdit={noop}
      renderItem={renderItem}
      handleSubmit={noop}
      onStickyClick={noop}
      handleClose={handleClose}
      stickyRow={true}
      hideFooter
      listWidth={listWidths[modalType]}
      isWhite
      listItemContainerStyle={listItemContainerStyle}
      emptyContainerClassName="team-rates-dropdown-empty-container"
      renderTableBodyHeader={renderTableBodyHeader}
      renderStickyRow={noop}
      globalClassName={'member-rate-phases-global'}
      isAlwaysGlobal
      renderHeaderButton={renderHeaderButton}
    />
  );
};

const makeMapStateToProps = () => {
  const getMemberTeamRates = makeGetOrderedMemberTeamRates();
  const mapStateToProps = (state, ownProps) => ({
    rates: ownProps.rates || getMemberTeamRates(state, ownProps),
    me: getMe(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TeamRatesDropdown);
