import { RealmAPIClient } from './RealmAPIClient';

export class AggregatedRealmAPIClients {
  private readonly apiClients: {
    mosaicAPI: RealmAPIClient;
    mosaicWS: RealmAPIClient;
  };

  constructor(apiClients: {
    mosaicAPI: RealmAPIClient;
    mosaicWS: RealmAPIClient;
  }) {
    this.apiClients = apiClients;
  }

  useRealm(realmId: string) {
    this.apiClients.mosaicAPI.useRealm(realmId);
    this.apiClients.mosaicWS.useRealm(realmId);
  }

  resetToDefaultBaseURL() {
    this.apiClients.mosaicAPI.resetToDefaultBaseURL();
    this.apiClients.mosaicWS.resetToDefaultBaseURL();
  }

  get mosaicAPI() {
    return this.apiClients.mosaicAPI;
  }

  get mosaicWS() {
    return this.apiClients.mosaicWS;
  }
}
