import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import theme from 'theme';

export interface ConfirmRemoveCustomMemberModalProps {
  closeModal: () => void;
  handleConfirm: () => void;
}

export const ConfirmRemoveCustomMemberModal = ({
  closeModal,
  handleConfirm
}: ConfirmRemoveCustomMemberModalProps) => {
  return (
    <SimpleConfirmModal
      isOpen
      header="Remove Custom Members"
      body={
        <p>
          Are you sure you want to <b>remove</b> access from all custom members
          and <b>delete</b> them?
        </p>
      }
      toggle={closeModal}
      onConfirm={handleConfirm}
      confirmLabel="Remove"
      cancelLabel="Cancel"
      submitButtonColor={theme.colors.colorCalendarRed}
      stylesWrapper={StyledConfirmModal}
    />
  );
};

const StyledConfirmModal = styled(Modal)`
  width: 425px;
  .modal-content {
    width: 100%;
    height: 180px;
    padding: 30px 35px;
    color: black;
    border-radius: 6px !important;
    .confirm-modal-header {
      font-size: 22px;
      margin-bottom: 12px;
    }
    .confirm-modal-body {
      margin: 0;
      font-size: 13px;
    }
  }
`;
