import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import { makeGetCustomFieldState } from 'CustomFieldsModule/selectors';
import { fetchCustomFieldAllValues } from 'CustomFieldsModule/actionCreators';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import { internalToExternalLabel } from 'appConstants/customFields';

const emptyArray = [];

const copy = {
  searchPlaceholder: 'Type name or select below'
};

const CustomFieldsFilterDropdown = ({
  internalLabel,
  activeFilter,
  updateAccountFilterLocal,
  fetchCustomFieldAllValues,
  items,
  isFetching,
  teamId,
  isHidden
}) => {
  const setFilterItems = (nextItems) => {
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        ...activeFilter.custom,
        [internalLabel]: nextItems
      }
    });
  };

  useEffect(() => {
    if (teamId) {
      fetchCustomFieldAllValues({
        teamId,
        internalLabel
      });
    }
  }, [fetchCustomFieldAllValues, internalLabel, teamId]);

  if (isHidden || !items.length) return null;

  return (
    <SimpleFilterDropdown
      items={items}
      copy={copy}
      isLoading={isFetching}
      batchClearOnly
      initialSelectedItems={activeFilter.custom[internalLabel] || emptyArray}
      setFilterItems={setFilterItems}
      toggleLabel={internalToExternalLabel[internalLabel] || internalLabel}
      filterId={activeFilter.id}
      listWidth={217}
    />
  );
};

const makeMapStateToProps = () => {
  const getCustomFieldState = makeGetCustomFieldState();
  const mapStateToProps = (state, ownProps) => {
    const customFieldState = getCustomFieldState(state, {
      internalLabel: ownProps.internalLabel
    });
    return {
      items: customFieldState.allValues || emptyArray,
      isFetching: customFieldState.isFetching || false,
      teamId: getSelectedTeamId(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal,
  fetchCustomFieldAllValues
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CustomFieldsFilterDropdown);
