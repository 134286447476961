import { ReactNode } from 'react';
import {
  EmploymentTypes,
  ProjectVisibilityTypes,
  ProjectInteractionTypes
} from 'PermissionsModule/types';
import {
  permissionConstants,
  ACCESS_ROLES,
  PROJECT_VISIBILITY_TYPES,
  PROJECT_INTERACTION_TYPES
} from 'PermissionsModule/constants';
import { AccessLevelMetadata } from 'PermissionsModule/utils';

const accessRoleToContent: Record<
  AccessLevelMetadata['accessRoleType'],
  ReactNode
> = {
  [ACCESS_ROLES.TEAM_MEMBER]: 'Base Member',
  [ACCESS_ROLES.WORKLOAD_MANAGER]: 'Work Planner',
  [ACCESS_ROLES.FINANCIAL_MANAGER]: 'Budget Manager',
  [ACCESS_ROLES.ADMIN]: 'Administrator'
};

const projectVisibilityToContent: Record<ProjectVisibilityTypes, ReactNode> = {
  [PROJECT_VISIBILITY_TYPES.ALL]: 'All Projects',
  [PROJECT_VISIBILITY_TYPES.PROJECTS_MEMBER_OF]: 'Projects they are a member',
  [PROJECT_VISIBILITY_TYPES.NO_ACCESS]: 'No Access'
};

const projectVisibilityToContentLowercase: Record<
  ProjectVisibilityTypes,
  ReactNode
> = {
  [PROJECT_VISIBILITY_TYPES.ALL]: 'all projects',
  [PROJECT_VISIBILITY_TYPES.PROJECTS_MEMBER_OF]: 'projects they are a member',
  [PROJECT_VISIBILITY_TYPES.NO_ACCESS]: 'no access'
};

const projectInteractionTypeToContent: Record<
  ProjectInteractionTypes,
  ReactNode
> = {
  [PROJECT_INTERACTION_TYPES.EDIT]: 'Can edit',
  [PROJECT_INTERACTION_TYPES.VIEW]: 'Can view'
};

const getSecondaryContent = ({
  metadata
}: {
  metadata: AccessLevelMetadata;
}): ReactNode => {
  const { accessRoleType, projectVisibilityType, projectInteractionType } =
    metadata;

  if (
    accessRoleType === ACCESS_ROLES.TEAM_MEMBER ||
    accessRoleType === ACCESS_ROLES.ADMIN
  ) {
    return projectVisibilityToContent[projectVisibilityType];
  }

  return `${
    projectInteractionType
      ? projectInteractionTypeToContent[projectInteractionType]
      : ''
  } ${projectVisibilityToContentLowercase[projectVisibilityType]}`;
};

const { EMPLOYMENT_TYPES } = permissionConstants;
const { member, internalContractor, externalProjectContractor, projectGuest } =
  EMPLOYMENT_TYPES;

const employmentTypeToOptionContent: Record<EmploymentTypes, ReactNode> = {
  [member]: 'Member',
  [internalContractor]: 'Contractor - Internal',
  [externalProjectContractor]: 'Project Contractor - External',
  [projectGuest]: 'Project Guest'
};
export const permissionsPresentationUtils = {
  employmentTypeToOptionContent,
  accessRoleToContent,
  projectVisibilityToContent,
  getSecondaryContent
};
