import { buildCalendarMonthDateRows } from 'appUtils/dateRangeHelpers';
import { getEveryFirstDayOfYear } from './utils';
import moment from 'moment';
import { EventEmitter } from 'core/helpers/EventEmitter';

const subscribableEvents = {
  onSetRange: 'onSetRange'
};

export class Calendar {
  #viewType = 'year';
  #currentDate = new Date();
  #range = {
    start: undefined,
    end: undefined
  };

  #emitter = new EventEmitter();
  #groupedDatesToRender = {};

  /**
   *
   * @param {Object} param
   * @param {'year' | 'month'} param.initialViewType
   * @param {Date} param.initialDate
   */
  constructor({ initialViewType = 'year', initialDate = new Date() }) {
    this.#initCalendar({ initialViewType, initialDate });
  }

  #initCalendar({ initialViewType, initialDate }) {
    this.#viewType = initialViewType;
    this.#currentDate = initialDate;
    this.#setRange(initialViewType, initialDate);
  }

  #setRange(viewType, date) {
    switch (viewType) {
      case 'year':
        this.#setYearRange(date);
        break;
      case 'month':
        this.#setMonthRange(date);
        break;
      default:
        throw Error('viewType is invalid');
    }
  }

  #setMonthRange(date) {
    const key = this.#getMonthKey(date);
    const monthArr = buildCalendarMonthDateRows(date);
    const flatten = monthArr.flat();

    this.#groupedDatesToRender = {
      [key]: monthArr
    };
    this.#range = {
      start: moment(flatten[0].date).toDate(),
      end: moment(flatten[flatten.length - 1].date).toDate()
    };

    this.#emitter.emit('onSetRange', {
      ...this.getRange(),
      currentDate: this.#currentDate
    });
  }

  #setYearRange(date) {
    const firstDayArr = getEveryFirstDayOfYear(date);
    const groupedDatesToRender = firstDayArr.reduce((acc, d) => {
      const key = this.#getMonthKey(d);
      acc[key] = buildCalendarMonthDateRows(d);
      return acc;
    }, {});

    const flatten = Array.from(Object.values(groupedDatesToRender)).flat(3);

    this.#groupedDatesToRender = groupedDatesToRender;
    this.#range = {
      start: moment(flatten[0].date).toDate(),
      end: moment(flatten[flatten.length - 1].date).toDate()
    };

    this.#emitter.emit('onSetRange', {
      ...this.getRange(),
      currentDate: this.#currentDate
    });
  }

  #getMonthKey(date) {
    return moment(date).format('YYYY-MM');
  }

  #format(date) {
    return moment.utc(date).format('YYYY-MM-DD');
  }

  getRange() {
    return {
      start: this.#range.start,
      end: this.#range.end,
      startStr: this.#range.start && this.#format(this.#range.start),
      endStr: this.#range.end && this.#format(this.#range.end)
    };
  }

  getCurrentDate() {
    return {
      date: this.#currentDate,
      dateStr: this.#format(this.#currentDate)
    };
  }

  getCurrentViewType() {
    return this.#viewType;
  }

  prev() {
    const newDate = moment(this.#currentDate)
      .clone()
      .subtract(1, this.#viewType)
      .toDate();
    this.#currentDate = newDate;
    this.#setRange(this.#viewType, newDate);
  }

  next() {
    const newDate = moment(this.#currentDate)
      .clone()
      .add(1, this.#viewType)
      .toDate();
    this.#currentDate = newDate;
    this.#setRange(this.#viewType, newDate);
  }

  jumpTo(date) {
    this.#currentDate = date;
    this.#setRange(this.#viewType, date);
  }

  getDatesToRenderByMonth(date) {
    const key = this.#getMonthKey(date);
    return this.#groupedDatesToRender[key];
  }

  /**
   * @param {keyof subscribableEvents} eventName
   * @param {Funtion} fn
   */
  on(eventName, fn) {
    if (this.#checkEventIsValid(eventName)) {
      this.#emitter.on(eventName, fn);
    }
  }

  /**
   * @param {keyof subscribableEvents} eventName
   * @param {Funtion} fn
   */
  off(eventName, fn) {
    this.#emitter.off(eventName, fn);
  }

  #checkEventIsValid(eventName) {
    return !!subscribableEvents[eventName];
  }
}
