import { RequirementLoaderListItem } from '../types';
import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';

export const RequirementLoader = (item: RequirementLoaderListItem, index) => {
  const {
    memberBudget: { id: memberBudgetId },
    projectMembership: { id: projectMembershipId }
  } = item;

  return (
    <StyledItemContainer className="requirement-loader">
      <SkeletonLoader isLoading width={200} height={30}>
        <div></div>
      </SkeletonLoader>
    </StyledItemContainer>
  );
};
