import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';

const byId = (item) => item.id;

export const initialState = {
  loaded: false,
  suggestions: []
};

const stub = [
  {
    phase_membership_id: 4452,
    suggestions: [
      {
        account_id: 2396,
        suggestion_id: '20220111_140922:62248', // last number is 8
        score: 0.30102509815442147,
        reasons: {
          position_match_amplitude: 1,
          collaboration_potential_amplitude: 0.019230769230769232,
          remaining_work_amplitude: 0.09813084112149528
        },
        start_date: '2022-01-11',
        end_date: '2022-01-31',
        total_available_hours: '112.0',
        schedule: {
          '2022-01-11': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-12': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-13': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-14': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-15': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-16': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-17': {
            total_capacity: '8.0',
            holiday_hours: '8.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-18': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-19': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-20': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-21': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-22': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-23': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-24': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-25': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-26': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-27': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-28': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-29': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-30': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-31': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          }
        }
      },
      {
        account_id: 2396,
        suggestion_id: '20220111_140922:62247',
        score: 0.30102509815442147,
        reasons: {
          position_match_amplitude: 1,
          collaboration_potential_amplitude: 0.019230769230769232,
          remaining_work_amplitude: 0.09813084112149528
        },
        start_date: '2022-01-11',
        end_date: '2022-01-31',
        total_available_hours: '112.0',
        schedule: {
          '2022-01-11': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-12': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-13': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-14': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-15': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-16': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-17': {
            total_capacity: '8.0',
            holiday_hours: '8.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-18': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-19': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-20': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-21': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-22': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-23': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-24': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-25': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-26': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-27': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-28': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          },
          '2022-01-29': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-30': {
            total_capacity: '0.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '0.0'
          },
          '2022-01-31': {
            total_capacity: '8.0',
            holiday_hours: '0.0',
            planned_hours: '0.0',
            spent_hours: '0.0',
            available_capacity: '8.0'
          }
        }
      }
    ]
  }
];

/**
 * Outdated suggestion reducer.
 * Please Do Not use this reducer for further feature development
 * Instead, SuggestionModule will store all suggestion data there
 * Apart from keeping suggestion related assets in one organized place,
 * this reducer state won't be dynamically cleared, but
 * SuggestionModule reducer state will be, saving memory usage when it's not used
 */
const suggestions = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_SUGGESTIONS.SUCCESS: {
      const suggestions = payload.response;
      return {
        ...state,
        loaded: true,
        suggestions
      };
    }

    default:
      return state;
  }
};

export default suggestions;
