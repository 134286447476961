import {
  BatchActionsMenu,
  BatchActionsMenuOption
} from 'components/BatchActions/BatchActionsMenuV2';
import { useMembershipTableData } from 'PermissionsModule/components/settingsViews/members/DataProvider';
import PencilIcon from 'icons/PencilIcon';
import { ArchiveIcon } from 'icons/ArchiveIcon';
import theme from 'theme';
import { MouseEventHandler } from 'react';
import { includes } from 'appUtils/typeUtils';
import { ReactivateMemberIcon } from 'icons/ReactivateMemberIcon';

interface MembershipPermissionTableBatchActionsMenuProps {
  onRequestBulkModifyAccessLevel: MouseEventHandler<HTMLElement>;
  onRequestBulkToggleArchiveTeamMembers: () => void;
}

export const MembershipPermissionTableBatchActionsMenu = ({
  onRequestBulkModifyAccessLevel,
  onRequestBulkToggleArchiveTeamMembers
}: MembershipPermissionTableBatchActionsMenuProps) => {
  const {
    selectedTeamMemberIds,
    totalTeamMembersSelectable,
    selectAllTeamMembers,
    clearAllSelectedTeamMembers,
    selectedTeamMembersArchiveStatus
  } = useMembershipTableData();

  const archiveOptionHash =
    selectedTeamMembersArchiveStatus === 'archived'
      ? {
          label: 'Reactivate Member',
          icon: (
            <ReactivateMemberIcon
              width={15}
              height={14}
              fill={theme.colors.colorRoyalBlue}
            />
          ),
          onClick: onRequestBulkToggleArchiveTeamMembers
        }
      : selectedTeamMembersArchiveStatus === 'active'
      ? {
          label: 'Archive Member',
          icon: (
            <ArchiveIcon
              width={15}
              height={14}
              fill={theme.colors.colorRoyalBlue}
            />
          ),
          onClick: onRequestBulkToggleArchiveTeamMembers
        }
      : null;

  return (
    <BatchActionsMenu
      optionHash={{
        [BatchActionsMenuOption.SelectAll]: {
          label: `All ${totalTeamMembersSelectable}`,
          onClick: selectAllTeamMembers
        },
        [BatchActionsMenuOption.Clear]: {
          label: `Clear ${selectedTeamMemberIds.length}`,
          onClick: clearAllSelectedTeamMembers
        },
        modifyAccessLevel: {
          label: 'Modify Access Level',
          icon: <PencilIcon fill={theme.colors.colorRoyalBlue} />,
          onClick: onRequestBulkModifyAccessLevel
        },
        ...(!!archiveOptionHash && { archive: archiveOptionHash })
      }}
      options={[
        [BatchActionsMenuOption.SelectAll, BatchActionsMenuOption.Clear],
        'modifyAccessLevel',
        ...(includes(['archived', 'active'], selectedTeamMembersArchiveStatus)
          ? ['archive']
          : [])
      ]}
    />
  );
};
