import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import theme from 'theme';
import { StyledFolderIcon } from 'components/FiltersFlyout/styles';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

const StyledTooltip = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 12px;
  font-weight: 400;
  padding: 5px 8px;
  display: flex;
  width: 213px;
  height: 42px;
  align-items: center;
`;

const ProjectColorBox = styled.div`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 4px;
  background: ${({ color }) => color || 'transparent'};
  position: absolute;
  left: -1px;
  top: -50%;
  height: 100%;
  transform: translate(0, 50%);
`;

const ProjectInfoContainer = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: ${theme.colors.colorCalendarGray};
  width: 100%;
  overflow: hidden;
`;

const MainInfoWrapper = styled.div`
  display: flex;
  line-height: 14px;
  white-space: nowrap;
`;

const StyledProjectName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.colorMediumGray9};
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  line-height: 14px;
  align-items: center;
`;

const StyledProjectNumber = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.colorMediumGray9};

  ${(props) => (props.hasNumber ? 'margin-right: 5px;' : '')};
`;
const StyledBoardName = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.colorMediumGray9};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ProjectBreakdownTooltip = ({
  id = 'project-breakdown-tooltip',
  className = 'project-breakdown-tooltip'
}) => {
  return (
    <ReactTooltip
      id={id}
      className={className}
      getContent={(dataTip) => {
        const { barData = {} } = JSON.parse(dataTip || '{}');
        const { projectNumber, boardName, projectName, projectColor, hours } =
          barData;

        return (
          <StyledTooltip>
            <ProjectColorBox
              color={projectColor || theme.colors.colorLightGray6}
            />
            {!projectName ? (
              <StyledProjectName>No Project Information</StyledProjectName>
            ) : (
              <>
                <ProjectInfoContainer>
                  <AdditionalInfoContainer>
                    <StyledProjectNumber hasNumber={!!projectNumber}>
                      {projectNumber}
                    </StyledProjectNumber>
                    {boardName && (
                      <StyledFolderIcon
                        width="12px"
                        height="10px"
                        marginRight="4px"
                      />
                    )}
                    <StyledBoardName>{boardName}</StyledBoardName>
                  </AdditionalInfoContainer>
                  <MainInfoWrapper>
                    <StyledProjectName>{projectName}</StyledProjectName>
                  </MainInfoWrapper>
                </ProjectInfoContainer>
                <span style={{ marginLeft: '5px' }}>
                  {`${formatNumWithMaxOneDecimal(+hours)}h`}
                </span>
              </>
            )}
          </StyledTooltip>
        );
      }}
      type="light"
    />
  );
};

export default ProjectBreakdownTooltip;
