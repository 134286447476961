import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import produce from 'immer';
import sum from 'lodash/sum';
import {
  getTeamMembersHashByTeamMembership,
  makeGetSortedTeamMembers
} from 'TeamsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { permissionsUtils } from 'PermissionsModule/utils';
import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { EmploymentTypes } from 'PermissionsModule/types';
import { ValueOf } from 'type-fest';

interface MembershipTableDataContextValues {
  groupTotalCounts: Record<EmploymentTypes, number>;
  ordersByGroup: Record<EmploymentTypes, number[]>;
  selectedTeamMemberIds: number[];
  totalTeamMembersSelectable: number;
  selectedTeamMembersArchiveStatus: ArchiveStatus;
  selectAllTeamMembers: () => void;
  clearAllSelectedTeamMembers: () => void;
  getIsTeamMemberSelected: ({
    teamMembershipId
  }: {
    teamMembershipId: number;
  }) => boolean;
  toggleTeamMembershipSelectedState: ({
    teamMembershipId
  }: {
    teamMembershipId: number;
  }) => void;
}

const CreateMembershipTableDataContext = createContext(
  {} as MembershipTableDataContextValues
);

export const MembershipTableDataProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const teamMembersHash = useAppSelector(getTeamMembersHashByTeamMembership);
  const getSortedTeamMembers = useMemo(makeGetSortedTeamMembers, []);

  const sortedTeamMembers = useAppSelector((state) =>
    getSortedTeamMembers(state, { isIncludeArchived: true })
  );

  const { groupTotalCounts, ordersByGroup } = useMemo(() => {
    const regularMemberIds: number[] = [];
    const guestIds: number[] = [];
    const internalContractorIds: number[] = [];
    const externalProjectContractorIds: number[] = [];

    sortedTeamMembers.forEach((member) => {
      const teamMember = teamMembersHash[member.id];

      if (teamMember) {
        const isGuest = permissionsUtils.getIsProjectGuest(teamMember);
        const isInternalContractor =
          permissionsUtils.getIsInternalContractor(teamMember);
        const isExternalProjectContractor =
          permissionsUtils.getIsExternalProjectContractor(teamMember);

        const listToAdd = isGuest
          ? guestIds
          : isInternalContractor
          ? internalContractorIds
          : isExternalProjectContractor
          ? externalProjectContractorIds
          : regularMemberIds;

        listToAdd.push(teamMember.id);
      }
    });

    return {
      ordersByGroup: {
        [EMPLOYMENT_TYPES.member]: regularMemberIds,
        [EMPLOYMENT_TYPES.internalContractor]: internalContractorIds,
        [EMPLOYMENT_TYPES.externalProjectContractor]:
          externalProjectContractorIds,
        [EMPLOYMENT_TYPES.projectGuest]: guestIds
      },
      groupTotalCounts: {
        [EMPLOYMENT_TYPES.member]: regularMemberIds.length,
        [EMPLOYMENT_TYPES.internalContractor]: internalContractorIds.length,
        [EMPLOYMENT_TYPES.externalProjectContractor]:
          externalProjectContractorIds.length,
        [EMPLOYMENT_TYPES.projectGuest]: guestIds.length
      }
    };
  }, [sortedTeamMembers, teamMembersHash]);

  const [selectedTeamMembershipIdsHash, setSelectedTeamMembershipIdsHash] =
    useState<Record<number, boolean>>({});

  const totalTeamMembersSelectable = useMemo(
    () => sum(Object.values(groupTotalCounts)),
    [groupTotalCounts]
  );

  const selectedTeamMemberIds = useMemo(
    () => Object.keys(selectedTeamMembershipIdsHash).map((id) => +id),
    [selectedTeamMembershipIdsHash]
  );

  const selectedTeamMembersArchiveStatus: ArchiveStatus = useMemo(() => {
    if (!selectedTeamMemberIds.length) return archiveStatus.unknown;

    // initially assume members are archived and active and then go to through all
    // the members until we encounter a member that is not archived and active, respectively
    let isAllArchived = true;
    let isAllActive = true;

    selectedTeamMemberIds.forEach((id) => {
      const teamMemberFromId = teamMembersHash[id];

      if (teamMemberFromId) {
        const isMemberArchived =
          permissionsUtils.getIsArchived(teamMemberFromId);

        isAllArchived = isAllArchived && isMemberArchived;
        isAllActive = isAllActive && !isMemberArchived;
      }
    });

    if (isAllActive) return archiveStatus.active;
    if (isAllArchived) return archiveStatus.archived;
    return archiveStatus.unknown;
  }, [selectedTeamMemberIds, teamMembersHash]);

  const getIsTeamMemberSelected: MembershipTableDataContextValues['getIsTeamMemberSelected'] =
    useCallback(
      ({ teamMembershipId }) => {
        return !!selectedTeamMembershipIdsHash[teamMembershipId];
      },
      [selectedTeamMembershipIdsHash]
    );

  const toggleTeamMembershipSelectedState: MembershipTableDataContextValues['toggleTeamMembershipSelectedState'] =
    useCallback(
      ({ teamMembershipId }) => {
        setSelectedTeamMembershipIdsHash(
          produce(selectedTeamMembershipIdsHash, (draft) => {
            if (getIsTeamMemberSelected({ teamMembershipId })) {
              delete draft[teamMembershipId];
              return;
            }

            draft[teamMembershipId] = true;
          })
        );
      },
      [getIsTeamMemberSelected, selectedTeamMembershipIdsHash]
    );

  const clearAllSelectedTeamMembers: MembershipTableDataContextValues['clearAllSelectedTeamMembers'] =
    useCallback(() => {
      setSelectedTeamMembershipIdsHash({});
    }, []);

  const selectAllTeamMembers: MembershipTableDataContextValues['selectAllTeamMembers'] =
    useCallback(() => {
      setSelectedTeamMembershipIdsHash(
        produce(selectedTeamMembershipIdsHash, (draft) => {
          Object.values(ordersByGroup).forEach((teamMemberIds) => {
            teamMemberIds.forEach((id) => (draft[id] = true));
          });
        })
      );
    }, [ordersByGroup, selectedTeamMembershipIdsHash]);

  const value: MembershipTableDataContextValues = {
    groupTotalCounts,
    ordersByGroup,
    selectedTeamMemberIds,
    totalTeamMembersSelectable,
    selectedTeamMembersArchiveStatus,
    getIsTeamMemberSelected,
    selectAllTeamMembers,
    clearAllSelectedTeamMembers,
    toggleTeamMembershipSelectedState
  };

  return (
    <CreateMembershipTableDataContext.Provider value={value}>
      {children}
    </CreateMembershipTableDataContext.Provider>
  );
};

export const useMembershipTableData = () =>
  useContext(CreateMembershipTableDataContext);

const archiveStatus = {
  archived: 'archived',
  active: 'active',
  unknown: 'unknown'
} as const;

type ArchiveStatus = ValueOf<typeof archiveStatus>;
