import { Sections } from './navigation';
import invert from 'lodash/invert';

export const VIEW_BY = {
  MEMBERS: 'members',
  MEMBER_TYPES: 'memberTypes',
  PROJECTS: 'projects',
  TASK_GROUPS: 'taskGroups',
  NONE: 'none',
  TASK_PRIORITIES: 'taskPriorities',
  PHASES: 'phases',
  ORGANIZATION: 'organization',
  TEAM: 'team',
  POSITIONS: 'roles',
  REQUESTS: 'requests',
  TASKS: 'tasks',
  WORK_PLANS: 'workplans',
  CLIENTS: 'clients',
  ACTIVITY: 'activity'
} as const;

export const VIEW_BY_DISPLAY = {
  [VIEW_BY.NONE]: { label: 'None', value: VIEW_BY.NONE },
  [VIEW_BY.MEMBERS]: { label: 'Member', value: VIEW_BY.MEMBERS },
  [VIEW_BY.TASK_GROUPS]: { label: 'List', value: VIEW_BY.TASK_GROUPS },
  [VIEW_BY.TASK_PRIORITIES]: {
    label: 'Priority',
    value: VIEW_BY.TASK_PRIORITIES
  },
  [VIEW_BY.PHASES]: { label: 'Phase', value: VIEW_BY.PHASES },
  [VIEW_BY.PROJECTS]: { label: 'Project', value: VIEW_BY.PROJECTS },
  [VIEW_BY.ORGANIZATION]: {
    label: 'Org',
    value: VIEW_BY.ORGANIZATION
  },
  [VIEW_BY.TEAM]: {
    label: 'Team',
    value: VIEW_BY.TEAM
  },
  [VIEW_BY.TASKS]: { label: 'Tasks', value: VIEW_BY.TASKS },
  [VIEW_BY.WORK_PLANS]: { label: 'Work Plans', value: VIEW_BY.WORK_PLANS }
} as const;

export const DEFAULT_VIEW_BY_OPTIONS = [
  VIEW_BY_DISPLAY[VIEW_BY.NONE],
  VIEW_BY_DISPLAY[VIEW_BY.TASK_GROUPS],
  VIEW_BY_DISPLAY[VIEW_BY.MEMBERS],
  VIEW_BY_DISPLAY[VIEW_BY.TASK_PRIORITIES],
  VIEW_BY_DISPLAY[VIEW_BY.PHASES]
];

export const GROUP_BY = {
  DATE_OF_REQUEST: 'Date Requested',
  START_DATE: 'Start Date',
  ASSIGNED: 'Assigned',
  PROJECT: 'Project',
  NONE: 'NONE'
} as const;
export const groupByToAttribute = {
  [GROUP_BY.ASSIGNED]: 'account_id'
} as const;

export const CONTEXT_MENU_TYPES = {
  SCHEDULE_BAR: 'scheduleBar',
  CAPACITY: 'capacity',
  WFH: 'wfh',
  TIME_ENTRY: 'timeEntry'
} as const;

export const ITEM_TYPES = {
  PHASE: 'phase',
  SCHEDULE_BAR: 'scheduleBar',
  TASK: 'task',
  WORK_CATEGORY: 'workCategory'
} as const;

export const CAPACITY_TYPES = {
  TEAM: 'teamCapacity',
  ACCOUNT: 'accountCapacity'
} as const;

export const CAPACITY_DATE_KEYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const;

export const CAPACITY_HEAT_MAP = {
  noColor: 'transparent',
  0: '#f8e6e6',
  1: '#f8e6e6',
  2: '#f8e6e6',
  3: '#ffeedf',
  4: '#fff5dc',
  5: '#ddf5e7',
  over: '#ddf5e7'
} as const;
export const CAPACITY_GRAY_HEAT_MAP = {
  noColor: 'transparent',
  0: 'transparent',
  1: '#d5dadf',
  2: '#d5dadf',
  3: '#c1c8d0',
  4: '#acb5c0',
  5: '#97a3b0',
  over: '#97a3b0'
} as const;
export const CAPACITY_FONT_COLOR = {
  noColor: 'transparent',
  0: '#4F4F4F',
  1: '#333333',
  2: '#333333',
  3: '#333333',
  4: '#333333',
  5: '#333333',
  over: '#333333'
} as const;

export const DEFAULT_WEEKLY_CAPACITY = 40;

// Do not use stringified numbers for ZOOM_LEVELS
const THIS_YEAR = -3;
const YEAR = -2;
const HALF = -1;
const QUARTER = 0;
const MONTH = 33;
const THIS_MONTH = 34;
const PREV_MONTH = 35;
const TWO_WEEKS = 50;
const THIS_WEEK = 51;
const NEXT_WEEK = 52;
const PREV_WEEK = 53;
const WEEK = 66;
const DAY = 100;
const PREV_DAY = 101;
const ALL_TIME = 'All Time';
const LAST_12_WEEKS = 'LAST_12_WEEKS';
const THIS_WHOLE_MONTH = 'THIS_WHOLE_MONTH';
const THIS_WHOLE_WEEK = 'THIS_WHOLE_WEEK';
const LAST_3_MONTHS = 'LAST_3_MONTHS';
const YEAR_TO_DATE = 'YEAR_TO_DATE';
const START_OF_WEEK_BEFORE_LAST_WEEK_TO_END_OF_LAST_WEEK =
  'START_OF_WEEK_BEFORE_LAST_WEEK_TO_END_OF_LAST_WEEK';
const START_OF_THIS_WEEK_TO_END_OF_NEXT_WEEK =
  'START_OF_THIS_WEEK_TO_END_OF_NEXT_WEEK';
const START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK =
  'START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK';
const CUSTOM = 'custom';

export const ZOOM_LEVELS = {
  YEAR,
  HALF,
  QUARTER,
  MONTH,
  THIS_MONTH,
  PREV_MONTH,
  LAST_3_MONTHS,
  TWO_WEEKS,
  THIS_WEEK,
  NEXT_WEEK,
  WEEK,
  PREV_WEEK,
  START_OF_WEEK_BEFORE_LAST_WEEK_TO_END_OF_LAST_WEEK,
  START_OF_THIS_WEEK_TO_END_OF_NEXT_WEEK,
  START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK,
  DAY,
  PREV_DAY,
  THIS_YEAR,
  ALL_TIME,
  LAST_12_WEEKS,
  THIS_WHOLE_MONTH,
  THIS_WHOLE_WEEK,
  YEAR_TO_DATE,
  CUSTOM
} as const;

export const ZOOM_STRINGS = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day'
} as const;

// BE uses plural for interval types
export const ZOOM_STRINGS_PLURAL = {
  YEAR: 'years',
  MONTH: 'months',
  WEEK: 'weeks',
  DAY: 'days'
} as const;

export const ZOOM_TO_STEP_VALUES = {
  [ZOOM_LEVELS.DAY]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.TWO_WEEKS]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.THIS_WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.NEXT_WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.PREV_WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.MONTH]: ZOOM_STRINGS.WEEK,
  [ZOOM_LEVELS.THIS_MONTH]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.QUARTER]: ZOOM_STRINGS.WEEK,
  [ZOOM_LEVELS.HALF]: ZOOM_STRINGS.MONTH,
  [ZOOM_LEVELS.YEAR]: ZOOM_STRINGS.MONTH,
  [ZOOM_LEVELS.THIS_YEAR]: ZOOM_STRINGS.MONTH,
  [ZOOM_LEVELS.LAST_12_WEEKS]: ZOOM_STRINGS.WEEK
} as const;

export const ZOOM_TO_STEP_VALUES_PLURAL = {
  [ZOOM_LEVELS.DAY]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.WEEK]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.TWO_WEEKS]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.THIS_WEEK]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.NEXT_WEEK]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.PREV_WEEK]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.MONTH]: ZOOM_STRINGS_PLURAL.WEEK,
  [ZOOM_LEVELS.THIS_MONTH]: ZOOM_STRINGS_PLURAL.DAY,
  [ZOOM_LEVELS.QUARTER]: ZOOM_STRINGS_PLURAL.WEEK,
  [ZOOM_LEVELS.HALF]: ZOOM_STRINGS_PLURAL.MONTH,
  [ZOOM_LEVELS.YEAR]: ZOOM_STRINGS_PLURAL.MONTH,
  [ZOOM_LEVELS.THIS_YEAR]: ZOOM_STRINGS_PLURAL.MONTH,
  [ZOOM_LEVELS.LAST_12_WEEKS]: ZOOM_STRINGS_PLURAL.WEEK
} as const;

export const ZOOM_TO_SNAP_VALUES = {
  [ZOOM_LEVELS.DAY]: 1,
  [ZOOM_LEVELS.WEEK]: 1,
  [ZOOM_LEVELS.TWO_WEEKS]: 1,
  [ZOOM_LEVELS.THIS_WEEK]: 1,
  [ZOOM_LEVELS.NEXT_WEEK]: 1,
  [ZOOM_LEVELS.PREV_WEEK]: 1,
  [ZOOM_LEVELS.MONTH]: 1,
  [ZOOM_LEVELS.THIS_MONTH]: 1,
  [ZOOM_LEVELS.QUARTER]: 1,
  [ZOOM_LEVELS.HALF]: 1,
  [ZOOM_LEVELS.YEAR]: 7
} as const;

export const ZOOM_TO_MULTIPLE_ASSIGNEES_SHOW_DURATION = {
  [ZOOM_LEVELS.DAY]: 1,
  [ZOOM_LEVELS.WEEK]: 1,
  [ZOOM_LEVELS.TWO_WEEKS]: 1,
  [ZOOM_LEVELS.THIS_WEEK]: 1,
  [ZOOM_LEVELS.NEXT_WEEK]: 1,
  [ZOOM_LEVELS.PREV_WEEK]: 1,
  [ZOOM_LEVELS.MONTH]: 3,
  [ZOOM_LEVELS.THIS_MONTH]: 3,
  [ZOOM_LEVELS.QUARTER]: 7,
  [ZOOM_LEVELS.HALF]: 14,
  [ZOOM_LEVELS.YEAR]: 28
} as const;

export const ZOOM_FIT_ITEM_TO_SCALE = {
  [ZOOM_LEVELS.DAY]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.TWO_WEEKS]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.THIS_WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.NEXT_WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.PREV_WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.MONTH]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.THIS_MONTH]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.QUARTER]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.HALF]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.YEAR]: ZOOM_STRINGS.DAY
} as const;

const keys = {
  groupIdKey: 'id',
  itemIdKey: 'id',
  itemTimeStartKey: 'start_date',
  itemTimeEndKey: 'end_date'
} as const;

export const timelineKeys = {
  [VIEW_BY.NONE]: {
    ...keys,
    itemGroupKey: 'project_id'
  },
  [VIEW_BY.MEMBERS]: {
    ...keys,
    groupIdKey: 'account_id',
    itemGroupKey: 'assignee_id'
  },
  [VIEW_BY.TASK_GROUPS]: {
    ...keys,
    itemGroupKey: 'task_group_id'
  },
  [VIEW_BY.TASK_PRIORITIES]: {
    ...keys,
    itemGroupKey: 'task_priority_id'
  },
  [VIEW_BY.PHASES]: {
    ...keys,
    itemGroupKey: 'phase_id'
  },
  [VIEW_BY.PROJECTS]: {
    ...keys,
    itemGroupKey: 'item_project_view_group_key'
  }
} as const;

export const scheduleTimelineKeys = {
  ...timelineKeys,
  [VIEW_BY.NONE]: {
    ...keys,
    itemGroupKey: 'ungrouped_id'
  },
  [VIEW_BY.MEMBERS]: {
    ...keys,
    groupIdKey: 'account_id',
    itemGroupKey: 'assignee_id'
  }
} as const;

export const FILTER_PAGES = {
  WORKLOAD_PLANNER: 1,
  TEAMS: 2,
  PROJECT_TASKS: 3,
  TIMESHEET: 4,
  SCHEDULE: 5,
  BUDGET: 6,
  CAPACITY: 7,
  UTILIZATION: 8,
  INTEGRATION: 9,
  PROFIT: 10,
  VARIANCE: 11,
  ACTIVITY: 12,
  TIMESHEET_STATUS_REPORT: 13,
  WORK_PLANS_TABLE: 14,
  DEMAND_REPORT: 15,
  BUDGET_TABLE: 16,
  SCOPE_TABLE: 17,
  SCOPE_REQUESTS_TABLE: 18,
  BUDGET_VARIANCE: 19,
  WORKLOAD_FORECAST: 20,
  SCHEDULE_VARIANCE: 21,
  SKILLS_REPORT: 22,
  CHECK_INS_REPORT: 23,
  ALL_PROJECTS_REPORT: 24,
  PROJECT_DETAILS_TABLE: 25,
  TIME_PROJECTION_REPORT: 27,
  SPENT_TIME_REPORT: 32,
  PLANNED_TIME_REPORT: 33,
  UNSPENT_BUDGET_REPORT: 34,
  PROJECT_DETAIL_BUDGET: 999 // Dummy Variable, put other pages above
} as const;

// Used for identifying current filter from API, follows BE terminology
export const FILTER_PAGE_NAMES = {
  [FILTER_PAGES.WORKLOAD_PLANNER]: 'Workload Planner',
  [FILTER_PAGES.TEAMS]: 'Teams',
  [FILTER_PAGES.PROJECT_TASKS]: 'Project Tasks',
  [FILTER_PAGES.TIMESHEET]: 'Timesheet',
  [FILTER_PAGES.SCHEDULE]: 'Schedule',
  [FILTER_PAGES.BUDGET]: 'Budget',
  [FILTER_PAGES.CAPACITY]: 'Capacity',
  [FILTER_PAGES.UTILIZATION]: 'Utilization',
  [FILTER_PAGES.INTEGRATION]: 'Integration',
  [FILTER_PAGES.PROFIT]: 'Profit Report',
  [FILTER_PAGES.VARIANCE]: 'Variance',
  [FILTER_PAGES.ACTIVITY]: 'Activity',
  [FILTER_PAGES.TIMESHEET_STATUS_REPORT]: 'Timesheet Status Report',
  [FILTER_PAGES.WORK_PLANS_TABLE]: 'Work Plans Table',
  [FILTER_PAGES.DEMAND_REPORT]: 'Demand Report',
  [FILTER_PAGES.BUDGET_TABLE]: 'Budget Table',
  [FILTER_PAGES.SCOPE_REQUESTS_TABLE]: 'Scope Requests Table',
  [FILTER_PAGES.BUDGET_VARIANCE]: 'Budget Variance',
  [FILTER_PAGES.SCHEDULE_VARIANCE]: 'Schedule Variance',
  [FILTER_PAGES.WORKLOAD_FORECAST]: 'Workload Forecast',
  [FILTER_PAGES.SKILLS_REPORT]: 'Skills Report',
  [FILTER_PAGES.CHECK_INS_REPORT]: 'Check Ins Report',
  [FILTER_PAGES.ALL_PROJECTS_REPORT]: 'All Projects Report',
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: 'Time Projection Report',
  [FILTER_PAGES.SPENT_TIME_REPORT]: 'Spent Time Report',
  [FILTER_PAGES.PLANNED_TIME_REPORT]: 'Planned Time Report',
  [FILTER_PAGES.UNSPENT_BUDGET_REPORT]: 'Unspent Budget Report'
} as const;

export const FILTER_PAGE_NAME_TO_ID = invert(FILTER_PAGE_NAMES);

export const DISPLAY_MEMBER_CAPACITY = {
  PERCENT: 'percent',
  AVAILABLE_HOURS: 'available_hours',
  TOTAL_HOURS: 'total_hours'
} as const;

export const DEPENDENCY_STRINGS = {
  START: 'StartDate',
  END: 'EndDate',
  START_AND_END: 'StartEndDate',
  START_TO_END: 'StartToEndDate',
  END_TO_START: 'EndToStartDate',
  NONE: 'None'
} as const;

export const DEPENDENCY_STRINGS_DISPLAY = {
  [DEPENDENCY_STRINGS.START]: 'Start',
  [DEPENDENCY_STRINGS.END]: 'End',
  [DEPENDENCY_STRINGS.END_TO_START]: 'End',
  [DEPENDENCY_STRINGS.START_TO_END]: 'Start',
  [DEPENDENCY_STRINGS.START_AND_END]: 'Start and End',
  [DEPENDENCY_STRINGS.NONE]: 'No Dependency'
} as const;

export const DEPENDENCY_SHORTENED_STRINGS_DISPLAY = {
  ...DEPENDENCY_STRINGS_DISPLAY,
  [DEPENDENCY_STRINGS.START_AND_END]: 'Start & End'
} as const;

/**
 * @deprecate use DEPENDABLE_TYPES instead in assets/js/components/Dependency/constants.ts@DEPENDABLE_TYPES
 */
export const DEPENDENCY_TYPES = {
  PHASE: 'Phase',
  SCOPE: 'BaseTask',
  TASK: 'BaseTask',
  WORK_PLAN: 'ActivityPhaseScheduleBar',
  MILESTONE: 'Milestone'
} as const;

export const DRAG_ACTIONS = {
  MOVE: 'move',
  RESIZE: 'resize'
} as const;

export const RESIZE_DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right'
} as const;

export const VIEW_TYPE = {
  NORMAL: 'normal',
  CONDENSED: 'condensed'
} as const;

export const CONDENSED_ZOOM_LEVELS = {
  MEDIUM: 'medium',
  SMALL: 'small',
  VERY_SMALL: 'verysmall'
} as const;

export const CONDENSED_VIEW_ROW_HEIGHTS = {
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 52,
  [CONDENSED_ZOOM_LEVELS.SMALL]: 42,
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: 28
} as const;

export const CONDENSED_VIEW_MILESTONE_PROPERTIES = {
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: { height: 20, width: 19, marginTop: 4 },
  [CONDENSED_ZOOM_LEVELS.SMALL]: { height: 17, width: 16, marginTop: -2 },
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: { height: 17, width: 12, marginTop: -3 }
} as const;

export const TIME_TYPE = {
  START: 'start',
  END: 'end'
} as const;

/**
 * @deprecated Use `CONDENSED_VIEW_OVERFLOW_TRIANGLE` instead.
 *
 * small -> 20px (original)
 *
 * 100% - 13.4
 * 75 - 9
 * 50 - 6.03
 * 25 - 4
 * --
 * large -> 30px (original)
 *
 * 100% - 20.1px
 * 75 - 13.5px
 * 50 - 9px
 * 25 - 6px
 */
export const CONDENSED_VIEW_OVERFLOW_TRIANGLE_DEPRECATED = {
  NORMAL: {
    small: 13.4,
    large: 20.1
  },
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: {
    small: 9,
    large: 13.5
  },
  [CONDENSED_ZOOM_LEVELS.SMALL]: {
    small: 6,
    large: 9
  },
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: {
    small: 4,
    large: 6
  }
} as const;
export const CONDENSED_VIEW_OVERFLOW_TRIANGLE = {
  NORMAL: 14,
  [CONDENSED_ZOOM_LEVELS.MEDIUM]: 9.5,
  [CONDENSED_ZOOM_LEVELS.SMALL]: 6.25,
  [CONDENSED_ZOOM_LEVELS.VERY_SMALL]: 4.25
} as const;

export const WORK_PLAN_CALENDAR_MENU_TYPES = {
  SET_TIME: 'setTime',
  SET_DAYS: 'setDays',
  SET_TO_REPEAT: 'setToRepeat'
} as const;

export const WORK_PLAN_CALENDAR_MENU_TITLE = {
  [WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME]: 'Set Time',
  [WORK_PLAN_CALENDAR_MENU_TYPES.SET_DAYS]: 'Set Days',
  [WORK_PLAN_CALENDAR_MENU_TYPES.SET_TO_REPEAT]: 'Set to Repeat'
} as const;

export const WORK_PLAN_CALENDAR_OPTION_TYPES = {
  ALL_DAY: 'setAllDay',
  INCLUDE_WEEKENDS: 'setIncludeWeekends',
  IS_TENTATIVE: 'setIsTentative',
  IS_WEEKLY_PLANNING: 'setIsWeeklyPlanning',
  SET_WEEKENDS_PREDICT_CONSTANT: 'setWeekendsPredictConstant',
  IS_AUTO_RESCHEDULE: 'setIsAutoReschedule'
} as const;

export const WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES = {
  DAYS: 'days',
  WEEKS: 'weeks'
} as const;

export const WORK_PLAN_OPTION_TEXT = {
  [WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY]: 'All Day',
  [WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS]: 'Working Weekends',
  [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE]: 'Tentative Plan',
  [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING]: 'Full Weeks',
  [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_AUTO_RESCHEDULE]: 'Auto Reschedule'
} as const;

export const SPLIT_SCREEN_TYPES = {
  PROJECT: 'project',
  PHASE: 'phase',
  SCHEDULED_TASK: 'scheduledtask',
  WORK_PLAN: 'workplan'
} as const;

export const SPLIT_SCREEN_SECTION_HASH = {
  [SPLIT_SCREEN_TYPES.PROJECT]: Sections.PLANNER_SPLIT_SCREEN,
  [SPLIT_SCREEN_TYPES.PHASE]: Sections.WORKLOAD_SPLIT_SCREEN,
  [SPLIT_SCREEN_TYPES.SCHEDULED_TASK]: Sections.WORKLOAD_SPLIT_SCREEN,
  [SPLIT_SCREEN_TYPES.WORK_PLAN]: Sections.WORKLOAD_SPLIT_SCREEN
};

export const FORECAST_HORIZONS = {
  NEXT_14_DAYS: 'next_14days',
  NEXT_CALENDAR_MONTH_1: 'next_calendar_month_1'
};

export const PTO_SELECTABLE_BY_FILTER_PAGE = {
  [FILTER_PAGES.WORKLOAD_PLANNER]: true,
  [FILTER_PAGES.TIMESHEET]: true,
  [FILTER_PAGES.CAPACITY]: true,
  [FILTER_PAGES.VARIANCE]: true,
  [FILTER_PAGES.BUDGET_VARIANCE]: true,
  [FILTER_PAGES.SCHEDULE_VARIANCE]: true,
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: true,
  [FILTER_PAGES.SPENT_TIME_REPORT]: true,
  [FILTER_PAGES.PLANNED_TIME_REPORT]: true
};
