import uniqBy from 'lodash/uniqBy';
import * as constants from 'appConstants';

export const initialState = {
  uniqNoteTags: [],
  allNoteTags: [],
  filterTagId: null
};

const tags = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_NOTE_TAGS_BY_BOARD.SUCCESS: {
      const { accountIds } = action.payload.requestPayload;
      // ensure allNoteTags remains unchanged after initial load
      return {
        ...state,
        allNoteTags:
          accountIds && accountIds.length > 0
            ? state.allNoteTags
            : action.payload.response.tags,
        uniqNoteTags: uniqBy(
          [...action.payload.response.tags],
          (tag) => tag.title
        )
      };
    }

    case constants.SET_FILTER_TAG_ID:
      return {
        ...state,
        filterTagId: action.id
      };

    default:
      return state;
  }
};

export default tags;
