import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportDiscipline
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingDisciplinesByName: Record<string, CsvImportDiscipline>;
  newDisciplines: React.MutableRefObject<Record<string, boolean>>;
}

export const disciplineOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ additionalParams }) => {
  const { existingDisciplinesByName, newDisciplines } = additionalParams;

  return [
    ...Object.keys(newDisciplines.current),
    ...Object.keys(existingDisciplinesByName)
  ];
};
