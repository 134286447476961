import React from 'react';
import theme from 'theme';
import Spinner from 'react-spinkit';

interface CircleSpinnerProps {
  color?: string;
  fadeIn?: 'full' | 'half' | 'quarter' | 'none';
  style?: React.CSSProperties;
}

const CircleSpinner = ({
  color = theme.colors.colorRoyalBlue,
  fadeIn,
  style
}: CircleSpinnerProps) => (
  <Spinner color={color} name="circle" fadeIn={fadeIn} style={style} />
);

export default CircleSpinner;
