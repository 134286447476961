import styled from 'styled-components';
import theme from 'theme';
import { StyledDropdownMenuItem } from 'views/projectPlanner/projectTimeline/Options/styles';

export const Page = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const StyledContainer = styled.div`
  flex: 4;
  margin-top: 2px;
  position: relative;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1500px) {
  }
  .timesheetstatus-tooltip {
    padding: 6px 14px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => padding || 'unset'};
  margin-left: ${({ marginLeft }) => marginLeft || '144px'};
  justify-content: space-between;
  align-items: center;
`;

export const StyledAddWidgetButton = styled.div`
  background-color: ${theme.colors.colorRoyalBlue};
  height: 28px;
  width: 116px;
  color: ${theme.colors.colorPureWhite};
  border-radius: 20px;
  line-height: 24px;
  font-size: 15px;
  padding: 0 10px 0 8px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }
`;

export const DashboardInfoContainer = styled.div`
  .dashboard-dropdown {
    font-size: 20px;
    line-height: 17px;
    font-weight: 600;
    color: ${theme.colors.colorMediumGray9};
    padding: 0;
  }
`;

export const StyledDashboardDescription = styled.div`
  font-size: 13px;
  line-height: 12px;
  font-weight: 600;
  min-height: 12px;
  color: ${theme.colors.colorLightGray15};
  padding-top: 2px;
  cursor: pointer;
`;

export const PlusIconContainer = styled.div`
  font-size: 26px;
  color: ${theme.colors.colorPureWhite};
  font-weight: 600;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const PencilIconContainer = styled.span`
  display: flex;
  width: fit-content;
  align-items: center;
  svg {
    margin-right: 3px;
    path {
      fill: ${theme.colors.colorCalendarBlue};
    }
  }

  &.team-dashboard-pencil-icon {
    display: none;
    padding-left: 10px;
    padding-bottom: 3px;
    ${StyledDropdownMenuItem}:hover & {
      display: flex;
    }
  }
`;

export const DashboardTitleContainer = styled.div`
  display: flex;
  align-items: center;

  /* handles sort options left margin to match design */
  .down-arrow {
    margin-left: 5px !important;
  }

  /* gives hover effect to team dashboard three dot menu */
  .dashboard-three-dot {
    :hover {
      transform: scale(1.05);
      path {
        fill: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;

export const WidgetChartContainer = styled.div`
  width: 100%;
  display: ${({ display }) => display || 'flex'};
  justify-content: space-around;

  ${(props) => props.widgetChartContainerCSS};
`;

export const ChartAndListContainer = styled.div`
  display: flex;
  ${(props) => props.chartAndListContainerCSS};
`;

export const IconContainer = styled.div`
  ${(props) => props.iconContainerCSS};
`;

export const WidgetContentContainer = styled.div`
  display: flex;
  height: fit-content;
`;

export const Divider = styled.hr`
  border-color: ${({ borderColor }) =>
    borderColor || theme.colors.colorPaleGray5};
  width: ${({ width }) => width || '84.5%'};
  margin: 1px 0 4px 0;
`;

export const HeaderText = styled.div`
  font-size: 10px;
  font-weight: 600;
  line-height: 17px;
  color: ${theme.colors.colorMediumGray1};
  width: 100%;
`;

export const StyledText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ color }) => color || theme.colors.colorMediumGray9};
  width: 100%;
  text-align: ${({ textAlign }) => textAlign || 'unset'};
  padding: ${({ padding }) => padding || 'unset'};

  ${(props) => props.textCSS};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || '0 4px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};
  width: ${({ width }) => width || 'unset'};
  overflow: ${({ overflow }) => overflow || 'unset'};

  ${(props) => props.contentContainerCSS};
`;

export const StyledTotalCell = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.colorSemiDarkGray1};
  width: 100%;
  font-size: 12px;
  font-weight: 600;
`;

export const TooltipContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 1;
  z-index: 5;
  border: 1px solid ${theme.colors.colorLightGray1};
  border-left: 4px solid ${({ color }) => color || 'unset'};
  background: ${theme.colors.colorPureWhite};
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
  white-space: nowrap;

  ${(props) => props.tooltipContainerCSS};
`;

export const StyledDashboardSharedMemberContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  gap: 3px;
  width: fit-content;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.colorCalendarBlue};
    svg path {
      stroke: ${theme.colors.colorCalendarBlue};
    }
  }
`;
