import {
  SelectDropdownProps,
  SelectInput,
  SelectInputVariant
} from 'RatesModule/components/SelectInput/SelectInput';
import { useTeamBudgetViewSettings } from 'BudgetModule/hooks/useTeamBudgetViewSettings';
import { BillRateType } from 'BudgetModule/models/BudgetViewSettings';
import {
  BillRateTypeDropdown,
  BILL_RATE_TYPE_COPY
} from 'BudgetModule/components/BudgetModal/BudgetSettingsModal/Fields/BillRateTypeDropdown';

export const BillRateTypeField = () => {
  const {
    budgetViewSettings: { bill_rate_type: billRateType },
    updateBudgetViewSettings
  } = useTeamBudgetViewSettings();

  const handleChange = (value: BillRateType) =>
    updateBudgetViewSettings({ bill_rate_type: value, rate_group_id: null });

  const dropdownRenderer = (props: SelectDropdownProps<BillRateType>) => (
    <BillRateTypeDropdown value={billRateType} {...props} />
  );

  return (
    <SelectInput
      onSelect={handleChange}
      renderDropdown={dropdownRenderer}
      variant={SelectInputVariant.White}
    >
      {BILL_RATE_TYPE_COPY[billRateType].label}
    </SelectInput>
  );
};
