import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchMemberTeamRates } from 'BudgetModule/actionCreators';
import {
  StyledRateContainer,
  MemberRateDescription,
  SelectRoleContainer,
  RateCellContainer,
  RateContainer,
  SubDateText
} from './styles';
import TeamRateSelection from './TeamRateSelection';
import moment from 'moment';
import { FormattedCurrencyByTeam } from 'components/FormatCurrency';

const TeamRateCell = ({ cell, row }) => {
  const dispatch = useDispatch();
  const { value } = cell;
  const rate = value;
  const { original } = row;
  const { member } = original;
  const accountId = member?.account?.id;
  const targetRef = useRef(null);

  const handleRateClick = (e) => {
    dispatch(fetchMemberTeamRates({ teamMembershipId: member.id }));
  };

  const startDate = rate?.start_date
    ? moment(rate.start_date).format('M/D/YY')
    : undefined;

  return (
    <StyledRateContainer ref={targetRef}>
      <TeamRateSelection
        memberName={member?.account?.first_name}
        target={targetRef}
        handleRateClick={handleRateClick}
        teamMembershipId={member?.id}
        accountId={accountId}
        member={member}
      >
        <MemberRateDescription>
          {rate ? (
            <RateCellContainer>
              <RateContainer>
                <FormattedCurrencyByTeam
                  currencyFormatterProps={{
                    options: {
                      maximumFractionDigits: 2
                    }
                  }}
                  currencyValue={parseFloat(rate.rate)}
                />
                /h
              </RateContainer>
            </RateCellContainer>
          ) : (
            <SelectRoleContainer isInTable className="app-cues-set-rate-role">
              Set Rate
            </SelectRoleContainer>
          )}
        </MemberRateDescription>
        {startDate && (
          <SubDateText
            data-for="app-tooltip"
            data-tip={startDate ? `Effective as of ${startDate}` : ''}
            data-class="center"
          >
            {startDate}
          </SubDateText>
        )}
      </TeamRateSelection>
    </StyledRateContainer>
  );
};

export default TeamRateCell;
