import styled from 'styled-components';
import cn from 'classnames';
import { FillIconStyles, MenuItemContainer } from './styles';
import { BlockingIcon } from 'icons/BlockingIcon';
import { WaitingIcon } from 'icons/WaitingIcon';
import { MatchesIcon } from 'icons/MatchesIcon';
import { dependencyAction, dependencyActionStrings } from '../../constants';
import { SvgIconProps } from 'components/SvgIcon';
import { CommonDependencyItem } from '../../types';

export const ActionMenuRenderer = ({
  actionType,
  selected
}: {
  actionType: NonNullable<CommonDependencyItem['action']>;
  selected: boolean;
}) => {
  const ActionIcon = actionIconMap[actionType];

  return (
    <MenuItemContainer className={cn({ selected })}>
      <MenuItemTextContainer>
        <ActionIcon className={cn({ selected })} />
        {dependencyActionStrings[dependencyAction[actionType]]}
      </MenuItemTextContainer>
    </MenuItemContainer>
  );
};

const MenuItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledBlockingIcon = styled(BlockingIcon)`
  ${FillIconStyles}
`;

const StyledWaitingIcon = styled(WaitingIcon)`
  ${FillIconStyles}
`;

const StyledMatchesIcon = styled(MatchesIcon)`
  ${FillIconStyles}
`;

export const actionIconMap: Record<
  NonNullable<CommonDependencyItem['action']>,
  (
    props: Pick<SvgIconProps, 'className' | 'width' | 'height' | 'fill'>
  ) => JSX.Element
> = {
  [dependencyAction.blocking]: StyledBlockingIcon,
  [dependencyAction.waiting]: StyledWaitingIcon,
  [dependencyAction.matches]: StyledMatchesIcon
};
