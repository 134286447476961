import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
const byId = (item) => item && item.id;
const byMosaicPhaseId = (item) => item && (item.mosaic_phase_id || item.id);

export const initialState = {
  qbSubSubCustomers: {},
  qbSyncedSubSubCustomers: {}
};
const qbSubSubCustomers = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_SUB_SUB_CUSTOMERS.SUCCESS: {
      const { phases } = payload.response;
      return {
        ...state,
        qbSubSubCustomers: keyBy(phases, byId)
      };
    }
    case constants.FETCH_SYNCED_SUB_SUB_CUSTOMERS.SUCCESS: {
      const { mappings } = payload.response;
      return {
        ...state,
        qbSyncedSubSubCustomers: keyBy(mappings, byMosaicPhaseId)
      };
    }

    default: {
      return state;
    }
  }
};

export default qbSubSubCustomers;
