import React from 'react';
import { useSelector } from 'react-redux';
import { getProjectHash } from 'selectors';
import styled from 'styled-components';
import ProjectColorDot from 'components/ProjectColorDot';
import theme from 'theme';
import { VIEW_BY } from 'appConstants/workload';

const ProjectTitleContainer = styled.div`
  display: flex;
`;

const StyledProjectTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
`;

export const StyledProjectHeaderCell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

const StyledProjectDescription = styled.div`
  color: ${theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 18px;
`;

const ProjectHeaderCell = ({ row }) => {
  const {
    projectId,
    ptoOrHolidayInfo,
    customRowProps: { viewBy }
  } = row.original;
  const projectHash = useSelector(getProjectHash);
  // project ids are not present for pto nor holiday bars
  const rowInfoToUse = projectHash[projectId] || ptoOrHolidayInfo;

  if (!rowInfoToUse) return null;

  return (
    <StyledProjectHeaderCell
      paddingTop={viewBy === VIEW_BY.PROJECTS ? '11px' : '0'}
    >
      <ProjectTitleContainer>
        <ProjectColorDot size={9} projectId={projectId} />
        <StyledProjectTitle>{rowInfoToUse.title}</StyledProjectTitle>
      </ProjectTitleContainer>
      <StyledProjectDescription>
        {rowInfoToUse.description}
      </StyledProjectDescription>
    </StyledProjectHeaderCell>
  );
};

export default ProjectHeaderCell;
