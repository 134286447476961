import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { openTimesheetSettingsModal } from 'actionCreators';
import SettingsIcon from 'icons/SettingsIcon';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';

const TimesheetTitleRow = styled.div`
  font-weight: 700;
  color: ${theme.colors.colorMediumGray9};
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  z-index: 10;
  position: absolute;
  top: -10px;
`;

const ActivityTimesheetIconContainer = styled.div`
  margin-right: 11px;
  margin-top: -2px;
`;

const SettingsIconContainer = styled.div`
  margin-left: 8px;
  margin-top: -1px;
  cursor: pointer;
  path {
    stroke: ${theme.colors.colorRoyalBlue};
  }
`;

const TimesheetTitle = (props) => {
  const dispatch = useDispatch();
  const handleSettingsClick = () => {
    dispatch(openTimesheetSettingsModal());
  };

  return (
    <TimesheetTitleRow>
      <ActivityTimesheetIconContainer>
        <ActivityTimesheetIcon
          width={20}
          height={20}
          color={theme.colors.colorMediumGray9}
        />
      </ActivityTimesheetIconContainer>
      Timesheet
      <SettingsIconContainer onClick={handleSettingsClick}>
        <SettingsIcon />
      </SettingsIconContainer>
    </TimesheetTitleRow>
  );
};
export default TimesheetTitle;
