import { SimpleConfirmModal } from 'components/Modals';
import { EntityRate } from 'RatesModule/models/EntityRate';
import { deleteEntityRate } from 'RatesModule/ratesActionCreators';
import { useAppDispatch } from 'reduxInfra/hooks';

export const DeleteEntityRateModal = ({
  entityRate,
  onClose
}: {
  entityRate: EntityRate;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { entity_id, id, rate_group_id } = entityRate;

  const handleConfirm = () => {
    dispatch(
      deleteEntityRate({
        entity_id,
        entity_rate_id: id,
        rate_group_id
      })
    );
    onClose();
  };

  return (
    <SimpleConfirmModal
      body="Are you sure you want to delete this rate?"
      confirmLabel="Yes"
      header="Delete Rate"
      isOpen
      onCancel={onClose}
      onConfirm={handleConfirm}
      shouldSetIsClosingOnClose={false}
    />
  );
};
