import styled from 'styled-components';
import cn from 'classnames';
import { EntityRateModalColumn } from '../types';

export const TableCell = styled.div.attrs<{ $isSoleRate?: boolean }>(
  ({ $isSoleRate }) => ({
    className: cn({ soleRate: $isSoleRate })
  })
)<{ $isSoleRate?: boolean }>`
  align-items: center;
  display: flex;
  font-size: 15px;

  &:not(.${EntityRateModalColumn.DateSeparator}) {
    padding: 0 5px;
  }

  &.${EntityRateModalColumn.DateSeparator},
    &.${EntityRateModalColumn.Delete},
    &.${EntityRateModalColumn.EndDate},
    &.${EntityRateModalColumn.StartDate} {
    justify-content: center;
  }

  &.${EntityRateModalColumn.EndDate}, &.${EntityRateModalColumn.StartDate} {
    align-items: stretch;

    & > div {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }

  &.${EntityRateModalColumn.Delete} {
    visibility: hidden;

    &.soleRate {
      cursor: not-allowed;
    }

    &:hover path {
      fill: ${({ theme }) => theme.colors.colorDeleteRed};
    }
  }
`;
