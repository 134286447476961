import React from 'react';
import { NavItem } from 'reactstrap';
import GlobalAddFlyout from './GlobalAddFlyout';
import GlobalAddPlus from './GlobalAddPlus';
import { connect } from 'react-redux';
import { openGlobalAdd, closeGlobalAdd } from 'actionCreators';

const GlobalAdd = ({ openGlobalAdd, closeGlobalAdd }) => (
  <NavItem className="hide-on-search">
    <GlobalAddPlus handleClick={openGlobalAdd} />
    <GlobalAddFlyout onRequestClose={closeGlobalAdd} />
  </NavItem>
);

const mapDispatchToProps = { openGlobalAdd, closeGlobalAdd };

export default connect(null, mapDispatchToProps)(GlobalAdd);
