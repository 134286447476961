import {
  serializeFilterLevelName,
  getFilterLevelTypeFromFilterLevelName,
  deserializeFilterLevelName,
  generateMatchingFilterLevelNames
} from '.';
import { UNSPECIFIED_FILTER_LEVEL } from '../constants';
import { parseNull } from 'appUtils/formatUtils';
import { PageFilterSchema, ParsedPageFilterSchema } from 'FilterModule/types';
import { Page } from 'models/filter';
import { parseFilterSchema } from './parseFilterSchema';

const getCurrentLevelNumber = ({
  level1,
  level2,
  level3,
  level4
}: {
  level1?: string;
  level2?: string;
  level3?: string;
  level4?: string;
}) => {
  if (level4) return 4;
  if (level3) return 3;
  if (level2) return 2;
  if (level1) return 1;
};

interface ParsePageFilterSchemaParams<P> {
  filterSchema: P;
  filterLevelName: string;
}

/**
 * Parses a PageFilterSchema using given filterLevelName and returns currentFilterSchema as merged data
 * based on all matching filter levels
 */
export const parsePageFilterSchema = <P extends PageFilterSchema>({
  filterSchema,
  filterLevelName
}: ParsePageFilterSchemaParams<P>) => {
  const { levelSchemas } = filterSchema;
  const {
    level1: matchingLevel1,
    level2: matchingLevel2,
    level3: matchingLevel3,
    level4: matchingLevel4
  } = deserializeFilterLevelName(filterLevelName);

  // default return value
  const parsedValues: ParsedPageFilterSchema = {
    meta: {
      level1: matchingLevel1 as Page, // level1 should always exist
      level2: matchingLevel2 || UNSPECIFIED_FILTER_LEVEL,
      level3: matchingLevel3 || UNSPECIFIED_FILTER_LEVEL,
      level4: matchingLevel4 || UNSPECIFIED_FILTER_LEVEL,
      filterLevelNames: []
    },
    mainFilter: null,
    fields: {},
    initialValues: {}
  };
  // Used for knowing the level numbers of levels added by default (due to nextLevelKey). See note below
  // when it's being added to
  const filterFieldToLevelNumber = {};

  // Find initial schemas matching the filterLevelName
  const matchingLevelNames = generateMatchingFilterLevelNames(filterLevelName);

  const seenMatchingLevelNames = new Set(matchingLevelNames);
  // There is one filter level name max per filter level type
  // eg. type 12 can match only one of 'Time Variance -> projects' or 'Time Variance -> members'
  const seenMatchingFilterLevelTypes = new Set(
    matchingLevelNames.map((levelName) =>
      getFilterLevelTypeFromFilterLevelName(levelName)
    )
  );

  /**
   * adds to the list of matching names if we haven't seen the name or its filter type before
   */
  const addMatchingLevelName = (newLevelName: string) => {
    const newLevelType = getFilterLevelTypeFromFilterLevelName(newLevelName);
    if (
      !seenMatchingLevelNames.has(newLevelName) &&
      !seenMatchingFilterLevelTypes.has(newLevelType)
    ) {
      matchingLevelNames.push(newLevelName);
      seenMatchingLevelNames.add(newLevelName);
      seenMatchingFilterLevelTypes.add(newLevelType);
    }
  };

  // Iterate over the matching level names to gather information from their schemas
  for (let i = 0; i < matchingLevelNames.length; i++) {
    const levelName = matchingLevelNames[i] as string;

    // Add current level values if they now exist (due to default next levels)
    const { level1, level2, level3, level4 } =
      deserializeFilterLevelName(levelName);
    let hasAddedNewLevel = false;
    if (level2 && parsedValues.meta.level2 === UNSPECIFIED_FILTER_LEVEL) {
      parsedValues.meta.level2 = level2;
      hasAddedNewLevel = true;
    }
    if (level3 && parsedValues.meta.level3 === UNSPECIFIED_FILTER_LEVEL) {
      parsedValues.meta.level3 = level3;
      hasAddedNewLevel = true;
    }
    if (level4 && parsedValues.meta.level4 === UNSPECIFIED_FILTER_LEVEL) {
      parsedValues.meta.level4 = level4;
      hasAddedNewLevel = true;
    }
    // If new level was added due to default nextLevelKey value, add it to matchingLevelNames
    // eg. initial filterLevelName = 'Time Variance -> ___ -> account'. If 'Time Variance' levelSchema
    // has a default next level of 'members', only levelNames matching 'Time Variance -> members' will be
    // added
    if (hasAddedNewLevel) {
      const newLevelName = serializeFilterLevelName({
        level1: parsedValues.meta.level1,
        level2: parsedValues.meta.level2,
        level3: parsedValues.meta.level3,
        level4: parsedValues.meta.level4
      });
      addMatchingLevelName(newLevelName);
    }

    const filterLevelSchema = levelSchemas[levelName];

    // Parse schema
    if (filterLevelSchema) {
      const { nextLevelKey, fieldSchemas } = filterLevelSchema;

      // If the level schema has nextLevelKey and that level hasn't been seen yet, set it as the next level
      // and add its matching names
      //   eg. initial filterLevelName = 'Time Variance -> members', and its matching levelSchema has
      //       nextLevelKey = 'groupBy'. We add the default value of the next level as level3,
      //       which results in adding the matching names: 'Time Variance -> members -> account' and
      //       'Time Variance -> ___ -> account' to matchingLevelNames
      if (nextLevelKey) {
        const currentLevelNumber = getCurrentLevelNumber({
          level1,
          level2,
          level3,
          level4
        }) as number;

        // Keep track of the level number for the key, so we can decide later if we want to add either its default
        // value or parsed value (from the filterLevelName) to initialValues
        const nextLevelNumber = currentLevelNumber + 1;
        const nextLevelId = `level${nextLevelNumber}`;
        filterFieldToLevelNumber[nextLevelKey] = nextLevelNumber;

        const nextLevelDefaultValue = fieldSchemas[nextLevelKey]?.defaultValue;

        if (
          parsedValues.meta[nextLevelId] === UNSPECIFIED_FILTER_LEVEL &&
          (nextLevelDefaultValue === null ||
            (nextLevelDefaultValue &&
              typeof nextLevelDefaultValue !== 'object')) // values matching to level keys must be simple/stringifiable
        ) {
          // Generate new names based on the added level
          const nextMatchingLevelNames = generateMatchingFilterLevelNames(
            serializeFilterLevelName({
              level1: parsedValues.meta.level1,
              level2: parsedValues.meta.level2,
              level3: parsedValues.meta.level3,
              level4: parsedValues.meta.level4,
              [nextLevelId]: nextLevelDefaultValue
            })
          );
          nextMatchingLevelNames.forEach((levelName) => {
            addMatchingLevelName(levelName);
          });
        }
      }

      parseFilterSchema({ schema: filterLevelSchema, parsedValues, levelName });
    }
  }

  parsedValues.meta.filterLevelNames = matchingLevelNames;

  // Add default values to initialValues. This has to be done outside of the loop
  // since values can come from levels that are seen at the end of the loop
  Object.entries(parsedValues.fields).forEach(([field, fieldData]) => {
    const levelName = fieldData.levelName as string;
    const defaultValue =
      levelSchemas[levelName]?.fieldSchemas[field]?.defaultValue;
    const levelNumber = filterFieldToLevelNumber[field];
    const fieldDefaultValue = levelNumber
      ? parseNull(parsedValues.meta[`level${levelNumber}`])
      : defaultValue;
    parsedValues.initialValues[field] = fieldDefaultValue;
  });

  return parsedValues;
};
