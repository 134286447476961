import React from 'react';
import SvgIcon from 'components/SvgIcon';

const LinkIcon2 = ({
  className,
  color = '#2F80ED',
  fill = 'none',
  width = 13,
  height = 13,
  style
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 13 13"
    style={style}
  >
    <g clipPath="url(#clip0)">
      <mask id="path-1-inside-1" fill="white">
        <path d="M8.99095 5.69979C8.82898 5.33654 8.60297 4.99435 8.30465 4.69698C8.00632 4.39962 7.66501 4.17269 7.30175 4.01074L6.85153 4.46092L6.53858 4.77292L6.21374 5.09774C6.15335 5.15813 6.1021 5.22309 6.05727 5.29171C6.48825 5.32008 6.89089 5.49392 7.19927 5.80136C7.51589 6.11796 7.68059 6.52604 7.70805 6.94052C7.7236 7.1711 7.69524 7.40169 7.62112 7.62311C7.53876 7.86925 7.40699 8.10166 7.21482 8.29928C7.20842 8.30477 7.20567 8.31209 7.20018 8.31849L6.83142 8.68632L4.6005 10.917C4.26375 11.2538 3.81719 11.4386 3.34137 11.4386C2.86646 11.4386 2.41898 11.2528 2.08224 10.917C1.74641 10.5812 1.56155 10.1338 1.56155 9.65893C1.56155 9.18315 1.7464 8.73572 2.08315 8.39992L3.32764 7.15462C3.25626 6.93411 3.20594 6.70812 3.17391 6.48028C3.15103 6.31009 3.13456 6.139 3.13456 5.96789C3.13181 5.65312 3.17116 5.33838 3.24437 5.03003L0.97866 7.29554C-0.32622 8.6003 -0.32622 10.7166 0.97866 12.0215C1.63111 12.6739 2.48669 13.0005 3.34137 13.0005C4.19695 13.0005 5.05255 12.6739 5.70502 12.0215L8.3048 9.42193C8.49788 9.22887 8.65893 9.01659 8.79435 8.79334C9.00756 8.4429 9.15305 8.06409 9.22534 7.67247C9.26286 7.47117 9.28299 7.26713 9.28299 7.06308C9.2839 6.81421 9.25279 6.56533 9.19789 6.32011C9.14939 6.10875 9.08076 5.90104 8.99108 5.69976L8.99095 5.69979Z" />
      </mask>
      <path
        d="M8.99095 5.69979C8.82898 5.33654 8.60297 4.99435 8.30465 4.69698C8.00632 4.39962 7.66501 4.17269 7.30175 4.01074L6.85153 4.46092L6.53858 4.77292L6.21374 5.09774C6.15335 5.15813 6.1021 5.22309 6.05727 5.29171C6.48825 5.32008 6.89089 5.49392 7.19927 5.80136C7.51589 6.11796 7.68059 6.52604 7.70805 6.94052C7.7236 7.1711 7.69524 7.40169 7.62112 7.62311C7.53876 7.86925 7.40699 8.10166 7.21482 8.29928C7.20842 8.30477 7.20567 8.31209 7.20018 8.31849L6.83142 8.68632L4.6005 10.917C4.26375 11.2538 3.81719 11.4386 3.34137 11.4386C2.86646 11.4386 2.41898 11.2528 2.08224 10.917C1.74641 10.5812 1.56155 10.1338 1.56155 9.65893C1.56155 9.18315 1.7464 8.73572 2.08315 8.39992L3.32764 7.15462C3.25626 6.93411 3.20594 6.70812 3.17391 6.48028C3.15103 6.31009 3.13456 6.139 3.13456 5.96789C3.13181 5.65312 3.17116 5.33838 3.24437 5.03003L0.97866 7.29554C-0.32622 8.6003 -0.32622 10.7166 0.97866 12.0215C1.63111 12.6739 2.48669 13.0005 3.34137 13.0005C4.19695 13.0005 5.05255 12.6739 5.70502 12.0215L8.3048 9.42193C8.49788 9.22887 8.65893 9.01659 8.79435 8.79334C9.00756 8.4429 9.15305 8.06409 9.22534 7.67247C9.26286 7.47117 9.28299 7.26713 9.28299 7.06308C9.2839 6.81421 9.25279 6.56533 9.19789 6.32011C9.14939 6.10875 9.08076 5.90104 8.99108 5.69976L8.99095 5.69979Z"
        fill={color}
        stroke="white"
        strokeWidth="0.366667"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <path d="M12.0213 0.979038C11.3688 0.326643 10.5133 0 9.65859 0C8.80301 0 7.94742 0.326643 7.29494 0.979038L4.6961 3.57765C4.50302 3.77072 4.34197 3.983 4.20655 4.20625C3.99334 4.55669 3.84786 4.9355 3.77463 5.32712C3.73711 5.52841 3.71698 5.73246 3.71698 5.93651C3.71698 6.18538 3.74718 6.43426 3.80208 6.67948C3.84966 6.89176 3.91829 7.09947 4.00888 7.30075C4.17085 7.664 4.39686 8.00528 4.69519 8.30356C4.99349 8.60184 5.33482 8.82785 5.69808 8.9898L6.14739 8.54053L6.46033 8.22853L6.78426 7.90463C6.84466 7.84424 6.8959 7.77928 6.94074 7.71066C6.50975 7.68229 6.10711 7.50936 5.79874 7.201C5.4812 6.88441 5.3165 6.47541 5.28904 6.0591C5.2744 5.82944 5.30277 5.59979 5.37689 5.37836C5.45833 5.13223 5.59101 4.89982 5.78318 4.70219C5.78867 4.6967 5.79233 4.68938 5.79874 4.68298L8.39852 2.08343C8.73526 1.74671 9.18182 1.56188 9.65765 1.56188C10.1335 1.56188 10.58 1.74762 10.9159 2.08343C11.6104 2.77789 11.6104 3.907 10.9168 4.60053L9.67138 5.84582C9.74275 6.06725 9.79308 6.29232 9.82511 6.52108C9.8489 6.69127 9.86445 6.86236 9.86445 7.03347C9.8672 7.34824 9.82785 7.66298 9.75465 7.97133L12.0213 5.70582C13.3261 4.40014 13.3261 2.28386 12.0213 0.978932L12.0213 0.979038Z" />
      </mask>
      <path
        d="M12.0213 0.979038C11.3688 0.326643 10.5133 0 9.65859 0C8.80301 0 7.94742 0.326643 7.29494 0.979038L4.6961 3.57765C4.50302 3.77072 4.34197 3.983 4.20655 4.20625C3.99334 4.55669 3.84786 4.9355 3.77463 5.32712C3.73711 5.52841 3.71698 5.73246 3.71698 5.93651C3.71698 6.18538 3.74718 6.43426 3.80208 6.67948C3.84966 6.89176 3.91829 7.09947 4.00888 7.30075C4.17085 7.664 4.39686 8.00528 4.69519 8.30356C4.99349 8.60184 5.33482 8.82785 5.69808 8.9898L6.14739 8.54053L6.46033 8.22853L6.78426 7.90463C6.84466 7.84424 6.8959 7.77928 6.94074 7.71066C6.50975 7.68229 6.10711 7.50936 5.79874 7.201C5.4812 6.88441 5.3165 6.47541 5.28904 6.0591C5.2744 5.82944 5.30277 5.59979 5.37689 5.37836C5.45833 5.13223 5.59101 4.89982 5.78318 4.70219C5.78867 4.6967 5.79233 4.68938 5.79874 4.68298L8.39852 2.08343C8.73526 1.74671 9.18182 1.56188 9.65765 1.56188C10.1335 1.56188 10.58 1.74762 10.9159 2.08343C11.6104 2.77789 11.6104 3.907 10.9168 4.60053L9.67138 5.84582C9.74275 6.06725 9.79308 6.29232 9.82511 6.52108C9.8489 6.69127 9.86445 6.86236 9.86445 7.03347C9.8672 7.34824 9.82785 7.66298 9.75465 7.97133L12.0213 5.70582C13.3261 4.40014 13.3261 2.28386 12.0213 0.978932L12.0213 0.979038Z"
        fill={color}
        stroke="white"
        strokeWidth="0.366667"
        mask="url(#path-2-inside-2)"
      />
      <rect
        x="0.256293"
        width="1.77566"
        height="12.5813"
        transform="matrix(0.69898 -0.715141 0.69898 0.715141 0.454957 2.945)"
        fill={color}
        stroke="white"
        strokeWidth="0.366667"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LinkIcon2;
