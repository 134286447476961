import styled from 'styled-components';
import DownArrow from 'icons/QBDownArrow';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import {
  SectionTitle,
  SelectContainer,
  OptionalLabel,
  SelectButton
} from './styles';

// select project / phase / activity phase
const SelectProjectSection = ({
  sectionTitle,
  projectId,
  phaseId,
  activityId,
  selectLabel = 'Select',
  onSelect,
  isOptional = true,
  isNoneSelected
}) => {
  const renderSelect = (onClick) => {
    return (
      <SelectButton onClick={onClick}>
        Select <DownArrow className="down-arrow" />
      </SelectButton>
    );
  };

  return (
    <div className="modal-section">
      {sectionTitle && <SectionTitle>{sectionTitle}</SectionTitle>}
      <SelectProjectContainer>
        <ProjectsThenPhasesSelector
          handleSelect={onSelect}
          renderPhaseDates={true}
          projectId={projectId}
          phaseId={phaseId}
          activityId={activityId}
          selectLabel={selectLabel}
          renderSelect={renderSelect}
          hidePTO
        />
        {isOptional && isNoneSelected && (
          <OptionalLabel>Optional</OptionalLabel>
        )}
      </SelectProjectContainer>
    </div>
  );
};

export default SelectProjectSection;

/* ------------------------------------ - ----------------------------------- */

const SelectProjectContainer = styled(SelectContainer)`
  .project-selector-container {
    padding: 0 !important;
  }
`;
