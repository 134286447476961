export const getProjectIds = (action) => [action.payload.projectId];
export const getUserId = (action) => [`${action.payload.response.account.id}`];
export const getTeamMembershipId = (action) => [
  action.payload.response.account.team_membership_id
];
export const getBoardId = (action) => [`${action.payload.response.id}`];
export const getAccountId = (action) => [`${action.payloadlaccountId}`];
export const getAttachmentIds = (action) => action.payload.attachmentIds;
export const getAttachmentIdsFromScope = (action) => {
  // Attachments for a scope are stored as an array of attachmentObjs.
  const scopes = action.payload.response.scopes || [];
  if (scopes.length) {
    const scopeAttachments = action.payload.response.scopes.map(
      (scope) => scope.attachments
    );

    // Iterate through all attachments, extracting their IDs.
    const attachmentIds = scopeAttachments.reduce(
      (acc, attachments) =>
        acc.concat(attachments.map((attachment) => attachment.id)),
      []
    );
    return attachmentIds;
  }
  return [];
};
export const getAttachmentIdsFromComments = (action) => {
  // Extract all attachment ids from all comments in thread
  const taskComments = action.payload.response.task_comments || [];
  if (taskComments.length) {
    const taskCommentAttachments = taskComments.map(
      (comment) => comment.attachments
    );
    const attachmentIds = taskCommentAttachments.reduce(
      (acc, attachments) =>
        acc.concat(attachments.map((attachment) => attachment.id)),
      []
    );
    return attachmentIds;
  }
  return [];
};
export const getAttachmentIdsFromComment = (action) => {
  // Extract attachmentIds for a single comment
  const attachments = action.payload.response.attachments || [];
  if (attachments.length) {
    return attachments.map((attachment) => attachment.id);
  }
  return [];
};
export const getAttachmentIdsFromProject = (action) => {
  // Extract the comments from a single project
  const projects = action.payload.response.projects || [];
  if (projects.length) {
    const attachmentIds = projects[0].status.reduce((acc, status) => {
      return acc.concat(status.attachments.map((attachment) => attachment.id));
    }, []);
    return attachmentIds;
  }
  return [];
};
