import SvgIcon from 'components/SvgIcon';

const MemberWithPencil = ({
  width = '15',
  height = '12',
  stroke = '#4f4f4f',
  strokeWidth = '0.8'
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 15 12" fill="none">
      <ellipse
        cx="4.94345"
        cy="4.51205"
        rx="2.01205"
        ry="2.01205"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M9.0482 11.8356C9.0482 9.61317 7.24655 7.81152 5.0241 7.81152C2.80165 7.81152 1 9.61317 1 11.8356"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <path
        d="M9.43165 5.30521L10.1576 6.09665L9.14571 6.40846L9.43165 5.30521ZM14.5644 1.78517L11.2955 5.34955L10.0936 4.03901L13.3625 0.474638L14.5644 1.78517Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default MemberWithPencil;
