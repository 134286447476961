import { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Modal, ModalBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getIsIncompleteModalOpen,
  getIncompleteTimesheetDates,
  getMe,
  getSelectedTeamId
} from 'selectors';
import { closeTimesheetIncompleteModal, setVisibleWeek } from 'actionCreators';
import { StyledModalHeader, StyledHeaderDiv as HeaderDiv } from '../styles';
import moment from 'moment';
import { fetchMembersUtilizationReport } from 'UtilizationModule/actionCreators';
import { ReactComponent as ExclamationIcon } from 'icons/exclamation-icon.svg';
import IncompleteModalBody from './IncompleteModalBody';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import LegendStyleTooltip from 'components/Tooltips/LegendStyleTooltip';

const IncompleteHeaderText = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: ${theme.colors.colorCalendarRed};
  margin-left: -25px;
  display: flex;
  align-items: center;
`;

const SubHeaderText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: 400;
  margin-top: -5px;
  margin-left: 3px;
`;

const ExclamationIconContainer = styled.div`
  margin-left: -5px;
  margin-right: 5px;
  margin-top: -3px;
  display: inline-block;
`;

const StyledHeaderDiv = styled(HeaderDiv)`
  margin-bottom: 0px;
  width: 309px;
`;

const StyledButton = styled.div`
  width: 74px;
  height: 30px;
  background: ${theme.colors.colorRoyalBlue};
  border-radius: 4px;
  border: 1px solid ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
`;

const TimesheetIncompleteModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsIncompleteModalOpen);

  const teamId = useSelector(getSelectedTeamId);
  const me = useSelector(getMe);
  const incompleteTimesheetDatesByAccount = useSelector(
    getIncompleteTimesheetDates
  );
  const incompleteTimesheetDates =
    incompleteTimesheetDatesByAccount[me?.id] ?? {};
  const incompleteTimesheetDateKeys = Object.keys(incompleteTimesheetDates);

  useEffect(() => {
    if (me?.id && teamId) {
      const today = moment().format('MM/DD/YYYY');
      const startDate = moment().add(-30, 'days').format('MM/DD/YYYY');
      dispatch(
        fetchMembersUtilizationReport({
          team_id: teamId,
          account_ids: [me?.id],
          show_status_totals: true,
          show_pto: true,
          show_holidays: true,
          interval_type: 'days',
          interval_amount: 1,
          start_date: startDate,
          end_date: today,
          filterStateId: 'incompleteModal'
        })
      );
    }
  }, [me, teamId, dispatch]);

  const handleClose = () => {
    dispatch(closeTimesheetIncompleteModal());
  };

  const handleDateNav = (date) => {
    dispatch(
      setVisibleWeek({
        visibleTimeStart: getMondayOfWeek(moment(date)),
        visibleTimeEnd: getMondayOfWeek(moment(date).add(1, 'week'))
      })
    );
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="timesheet-incomplete-modal"
    >
      <ModalBody>
        <StyledHeaderDiv>
          <StyledModalHeader>
            <IncompleteHeaderText>
              <ExclamationIconContainer>
                <ExclamationIcon />
              </ExclamationIconContainer>
              {incompleteTimesheetDateKeys.length} Incomplete
            </IncompleteHeaderText>
            <SubHeaderText>Past 30 days</SubHeaderText>
          </StyledModalHeader>
          <StyledButton onClick={handleClose}>Done</StyledButton>
        </StyledHeaderDiv>
        <IncompleteModalBody handleDateNav={handleDateNav} id={me?.id} />
      </ModalBody>
      <LegendStyleTooltip />
    </Modal>
  );
};

export default TimesheetIncompleteModal;
