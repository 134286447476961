import React, { useCallback, useEffect } from 'react';
import { useDispatch, batch } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { createWorkGroupMembership } from 'actionCreators';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';

const StyledAddCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const AddMemberButton = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: ${theme.colors.colorCalendarGray};
  span {
    margin-left: 30px;
  }
  cursor: pointer;
  &:hover {
    filter: brightness(70%);
  }
`;

const AddMemberCell = ({ row }) => {
  const dispatch = useDispatch();
  const handleAdd = (members) => {
    batch(() =>
      members.map((member) => {
        dispatch(
          createWorkGroupMembership({
            accountId: member.account.id,
            workGroupId: row.original.id
          })
        );
      })
    );
  };

  const memberFilter = useCallback(
    (members) =>
      members.filter(
        (member) =>
          !row.original?.list?.headerData?.workGroupMembersHash[
            member.account.id
          ]
      ),
    [row.original]
  );

  return (
    <StyledAddCell>
      <BulkMemberSelector
        handleDone={handleAdd}
        memberFilter={memberFilter}
        shouldUseMemberFilter
        renderSelect={({ onClick }) => {
          return (
            <AddMemberButton onClick={onClick}>
              <span>+</span>
              <span>Add Members</span>
            </AddMemberButton>
          );
        }}
      />
    </StyledAddCell>
  );
};

export default AddMemberCell;
