import * as Cells from './Cells';
import type {
  BaseTableColumn,
  BaseTableListItem
} from 'components/Table/types';
import { EmptyDiv } from 'components/EmptyDiv';
import type {
  PermissionsTableVariant,
  AccessLevelColumn,
  PermissionsTableConfig
} from './types';
import merge from 'lodash/merge';
import { ROLE_NAME } from 'PermissionsModule/constants';

/* ---------------------------------- columns --------------------------------- */

const ACCESS_PERMISSION_TABLE_COLUMNS = [
  ROLE_NAME.ALL_MEMBERS,
  ROLE_NAME.ADMIN,
  ROLE_NAME.BUDGET_MANAGER,
  ROLE_NAME.WORK_PLANNER,
  ROLE_NAME.PORTFOLIO_MANAGER
  // ROLE_NAME.CUSTOM
];

const PROJECT_PERMISSION_TABLE_COLUMNS = [
  ROLE_NAME.ALL_MEMBERS,
  ROLE_NAME.ADMIN,
  ROLE_NAME.BUDGET_MANAGER,
  ROLE_NAME.WORK_PLANNER,
  ROLE_NAME.PORTFOLIO_MANAGER,
  ROLE_NAME.PROJECT_MANAGER
  // ROLE_NAME.CUSTOM
];

const tableVariantToColumns = {
  access: ACCESS_PERMISSION_TABLE_COLUMNS,
  projects: PROJECT_PERMISSION_TABLE_COLUMNS
};

/* ------------------------------ rows / cells ------------------------------ */

export const ROW_TYPES = {
  permissionsSectionHeaderRow: 'permissionsSectionHeaderRow',
  permissionRow: 'permissionRow'
};

const permissionsSectionHeaderRowCells = {
  leftPadding: EmptyDiv,
  label: Cells.PermissionsSectionHeaderCell,
  accessLevel: Cells.AccessLevelColumnHeaderCell,
  rightPadding: EmptyDiv
};

const permissionRowCells = {
  leftPadding: EmptyDiv,
  label: Cells.PermissionLabelCell,
  accessLevel: Cells.PermissionValueCell,
  rightPadding: EmptyDiv
};

/* --------------------------------- columns -------------------------------- */

const leftPaddingColumn: BaseTableColumn = {
  headerType: 'leftPadding',
  id: 'leftPadding'
};

const labelColumn: BaseTableColumn = {
  headerType: 'label',
  id: 'label'
};

const accessLevelColumn: BaseTableColumn = {
  headerType: 'accessLevel',
  id: 'accessLevel'
};

const rightPaddingColumn: BaseTableColumn = {
  headerType: 'rightPadding',
  id: 'rightPadding'
};

const generateAccessLevelColumns = (
  variant: PermissionsTableVariant
): AccessLevelColumn[] => {
  const tablePermissionAccessColumns = tableVariantToColumns[variant];
  return tablePermissionAccessColumns.map((accessLevel) => ({
    ...accessLevelColumn,
    id: `accessLevel-${accessLevel}`,
    accessLevel
  }));
};

/* --------------------------------- configs -------------------------------- */

// currently only one variant, so only configs.shared is used
export const getTableConfig = ({
  variant
}: {
  variant: PermissionsTableVariant;
}) => {
  const accessLevelColumns = generateAccessLevelColumns(variant);
  const configs: {
    shared: PermissionsTableConfig;
  } & Partial<
    Record<PermissionsTableVariant, Partial<PermissionsTableConfig>>
  > = {
    shared: {
      variant,
      tableProps: {
        columnWidthsByHeaderType: {
          [leftPaddingColumn.headerType]: 15,
          [labelColumn.headerType]: 250,
          [accessLevelColumn.headerType]: 100,
          [rightPaddingColumn.headerType]: 30
        },
        tableColumns: [
          leftPaddingColumn,
          labelColumn,
          ...accessLevelColumns,
          rightPaddingColumn
        ],
        rowTypeToCellsByHeaderType: {
          [ROW_TYPES.permissionsSectionHeaderRow]:
            permissionsSectionHeaderRowCells,
          [ROW_TYPES.permissionRow]: permissionRowCells
        },
        rowTypesHash: ROW_TYPES,
        maxHeight: window.innerHeight,
        showHeader: false,
        hasStickyHeader: true
      }
    }
  };

  const config = configs[variant];

  // in the case of conflicting properties, config takes precedence
  const mergedConfig = merge({}, configs.shared, config);

  return mergedConfig;
};
