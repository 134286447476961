import styled from 'styled-components';
import { NavLink } from 'reactstrap';

export const StyledNavLink = styled(NavLink)`
  color: #808080;
  padding: 0 9px;
  margin: 14px 0 14px 25px;
  cursor: pointer;

  &.active {
    border-left: 2px solid ${(props) => props.theme.colors.colorRoyalBlue};
    color: #4a4a4a;
    font-weight: bold;
    padding-left: 7px;
  }
`;
