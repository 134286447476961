import React from 'react';
import theme from 'theme';
import { getTeamMembersHash } from 'selectors';
import styled from 'styled-components';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { useAppSelector } from 'reduxInfra/hooks';
import EllipsisText from 'components/EllipsisText';

const MemberNameStyle = {
  color: theme.colors.colorMediumGray9,
  fontSize: 16,
  fontWeight: 600,
  marginLeft: 7
};

interface ActivitySideMenuProjectInfoProps {
  accountId: number;
}
const ActivitySideMenuMemberInfo = ({
  accountId
}: ActivitySideMenuProjectInfoProps) => {
  const memberHash = useAppSelector(getTeamMembersHash);
  const member = memberHash[accountId];
  // no member means unassigned role. We cant handle this yet without a BE change so show nothing in that case
  return member ? (
    <ActivityLogCardMemberInfo>
      <TopRow> ASSIGNED TO </TopRow>
      <MemberNameContainer>
        <MemberInitials
          size={'mediumSmall'}
          member={member}
          classes="regular-member-no-hover"
        />
        <EllipsisText style={MemberNameStyle} maxWidth={300} width={300}>
          {member.name}
        </EllipsisText>
      </MemberNameContainer>
    </ActivityLogCardMemberInfo>
  ) : null;
};

// ************* Styling *********** //

const ActivityLogCardMemberInfo = styled.div`
  margin-top: 18px;
  margin-left: -2px;
`;

const TopRow = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
`;

const MemberNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export default ActivitySideMenuMemberInfo;
