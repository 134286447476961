import qs from 'qs';
import { callApi } from 'appUtils/api';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { ValueOf } from 'type-fest';
import {
  EntityLocation,
  LocationAttributes
} from 'SettingsModule/models/location';
import isEmpty from 'lodash/isEmpty';
import { CreateOfficeFailure } from 'domain/office/failures';
import {
  UpdateOfficeLocationFailure,
  UpdateOfficeMetadataFailure
} from 'domain/office/failures';

export const fetchOffices = (
  id: number,
  token: string,
  { teamId }: { teamId: number }
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify({ team_id: teamId });
  return callApi(`offices?${stringifiedQueryParams}`, token, fetchInit);
};

export const createOffice = async (
  token: string,
  {
    teamId,
    name,
    locationAttributes
  }: { teamId: number; name: string; locationAttributes: EntityLocation }
) => {
  const body = convertKeysToSnakeCase({ teamId, name, locationAttributes });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const result = await callApi('office', token, fetchInit);
  if (!('error' in result) || isEmpty(result.error)) return result;

  const error: CreateOfficeFailure = {
    type: result.error?.status === 422 ? 'invalidAddress' : 'unknownFailure'
  };

  return { error };
};

export const updateOfficeMetadata = async (
  token: string,
  { id, name }: { id: number; name: string }
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ name })
  };

  const result = await callApi(`office/${id}`, token, fetchInit);

  if (!('error' in result) || isEmpty(result.error)) return result;

  const error: UpdateOfficeMetadataFailure = {
    type: 'unknownFailure'
  };

  return { error };
};

export const updateOfficeLocation = async (
  token: string,
  {
    officeLocationId,
    locationAttributes
  }: { officeLocationId: number; locationAttributes: LocationAttributes }
) => {
  const body = {
    ...convertKeysToSnakeCase(locationAttributes),
    is_manual_entry: true
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const result = await callApi(
    `locations/${officeLocationId}`,
    token,
    fetchInit
  );
  if (!('error' in result) || isEmpty(result.error)) return result;

  const error: UpdateOfficeLocationFailure = {
    type: 'unknownFailure'
  };

  return { error };
};

export const deleteOffice = (token: string, { id }: { id: number }) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`office/${id}`, token, fetchInit);
};

export const updateOfficeEntities = (
  token: string,
  { id, addEntities, removeEntities }: UpdateOfficeEntitiesParams
) => {
  const body = convertKeysToSnakeCase({
    addEntities,
    removeEntities
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`office/${id}/update_entities`, token, fetchInit);
};

interface UpdateOfficeEntitiesParams {
  id: string;
  addEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
  removeEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
}
