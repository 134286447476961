import { ComponentProps, ComponentType, useMemo } from 'react';
import { useLeanApi } from './useLeanApi';

interface WithLeanApiProps {
  leanApi: ReturnType<typeof useLeanApi>;
}

export const withLeanApi = <P extends WithLeanApiProps>(
  WrappedComponent: ComponentType<P>
) => {
  const Hoc = (
    props: Omit<
      ComponentProps<typeof WrappedComponent>,
      keyof WithLeanApiProps
    > & {
      leanApiProps?: Parameters<typeof useLeanApi>[0];
    }
  ) => {
    const { leanApiProps: providedLeanApiProps, ...componentProps } = props;

    const leanApiProps = useMemo(
      () => providedLeanApiProps ?? { isoStateId: undefined },
      [providedLeanApiProps]
    );
    const leanApi = useLeanApi(leanApiProps);

    return (
      <WrappedComponent
        {...(componentProps as unknown as P)}
        leanApi={leanApi}
      />
    );
  };

  Hoc.displayName = 'withLeanApi';

  return Hoc;
};
