import { ReactNode } from 'react';
import { DateSeparatorCell } from './Cells/DateSeparatorCell';
import { DeleteCell } from './Cells/DeleteCell';
import { DescriptionCell } from './Cells/DescriptionCell';
import { EmptyCell } from './Cells/EmptyCell';
import { EndDateCell } from './Cells/EndDateCell';
import { HeaderCell } from './Cells/HeaderCell';
import { RateCell } from './Cells/RateCell';
import { StartDateCell } from './Cells/StartDateCell';
import {
  CellContentsProps,
  EntityRateModalColumn,
  EntityRateModalType
} from './types';

export const DELETE_COLUMN_WIDTH = 34;

export const COLUMN_CONFIG: Record<
  EntityRateModalColumn,
  {
    cell: (props: CellContentsProps) => ReactNode;
    header: typeof HeaderCell | typeof EmptyCell;
    label: string | ((isCostRate?: boolean) => string);
  }
> = {
  [EntityRateModalColumn.DateSeparator]: {
    cell: DateSeparatorCell,
    header: HeaderCell,
    label: ''
  },
  [EntityRateModalColumn.Delete]: {
    cell: DeleteCell,
    header: EmptyCell,
    label: ''
  },
  [EntityRateModalColumn.Description]: {
    cell: DescriptionCell,
    header: HeaderCell,
    label: 'Description'
  },
  [EntityRateModalColumn.EndDate]: {
    cell: EndDateCell,
    header: HeaderCell,
    label: 'End'
  },
  [EntityRateModalColumn.Rate]: {
    cell: RateCell,
    header: HeaderCell,
    label: (isCostRate) => (isCostRate ? 'Cost Rate/Hr' : 'Bill Rate/Hr')
  },
  [EntityRateModalColumn.StartDate]: {
    cell: StartDateCell,
    header: HeaderCell,
    label: 'Start'
  }
} as const;

const COLUMNS_BILL_RATE = [
  { id: EntityRateModalColumn.Delete, width: DELETE_COLUMN_WIDTH },
  { id: EntityRateModalColumn.Rate, width: 100 },
  { id: EntityRateModalColumn.Description, width: 300 },
  { id: EntityRateModalColumn.StartDate, width: 96 },
  { id: EntityRateModalColumn.DateSeparator, width: 10 },
  { id: EntityRateModalColumn.EndDate, width: 96 }
];

const COLUMNS_COST_RATE = [
  { id: EntityRateModalColumn.Delete, width: DELETE_COLUMN_WIDTH },
  { id: EntityRateModalColumn.Rate, width: 100 },
  { id: EntityRateModalColumn.StartDate, width: 96 },
  { id: EntityRateModalColumn.DateSeparator, width: 10 },
  { id: EntityRateModalColumn.EndDate, width: 96 }
];

export const TABLE_CONFIG: Record<
  EntityRateModalType,
  {
    addLabel: string;
    columns: readonly { id: EntityRateModalColumn; width: number }[];
    width: number;
  }
> = {
  [EntityRateModalType.BillRate]: {
    addLabel: '+ Add New Bill Rate',
    columns: COLUMNS_BILL_RATE,
    width: COLUMNS_BILL_RATE.reduce((sum, { width }) => sum + width, 0)
  },
  [EntityRateModalType.CostRate]: {
    addLabel: '+ Add New Cost Rate',
    columns: COLUMNS_COST_RATE,
    width: COLUMNS_COST_RATE.reduce((sum, { width }) => sum + width, 0)
  }
} as const;
