import { MouseEvent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { SingleLineDependencyItemRow } from './SingleLineDependencyItemRow';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

interface BaseButtonProps {
  label: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  tooltip?: string;
  disabled?: boolean;
}

export interface DependencyItemDisplayProps {
  baseButtonProps: BaseButtonProps;
  targetButtonProps: BaseButtonProps & {
    dependableItemType: string;
    dependableItemLabel: string;
  };
  shouldUseSingleLineSetter?: boolean;
}
export const DependencyItemDisplay = ({
  baseButtonProps: {
    label: baseLabel,
    onClick: onBaseClick,
    tooltip: baseButtonTooltip = ''
  },
  targetButtonProps: {
    dependableItemType,
    dependableItemLabel,
    label: targetLabel,
    onClick: onTargetClick,
    tooltip: targetButtonTooltip = '',
    disabled: targetDisabled = false
  },
  shouldUseSingleLineSetter
}: DependencyItemDisplayProps) => {
  const handleOnTargetClick = (e) => {
    if (!targetDisabled && onTargetClick) onTargetClick(e);
  };

  useEffect(() => {
    if (baseButtonTooltip) rebuildTooltip();
  }, [baseButtonTooltip]);

  return (
    <DependencyItemDisplayContainer>
      {shouldUseSingleLineSetter ? (
        <SingleLineDependencyItemRow
          dependableItemType={dependableItemType}
          baseLabel={baseLabel}
          onClick={onBaseClick}
        />
      ) : (
        <>
          <Row>
            Link{' '}
            <TextButton
              onClick={onBaseClick}
              data-testid="base-dependency-type-button"
              data-tip={baseButtonTooltip}
              data-for="app-tooltip"
              data-effect="solid"
            >
              {baseLabel}
            </TextButton>
          </Row>
          <Row>
            to
            <OverflowTextButton
              onClick={handleOnTargetClick}
              data-testid="target-dependency-type-button"
              data-tip={targetButtonTooltip}
              data-for="app-tooltip"
              data-effect="solid"
              disabled={targetDisabled}
            >
              <DependantText>{dependableItemLabel}</DependantText>
              {!!dependableItemLabel && <span>&nbsp;</span>}
              <DependantTypeText>{targetLabel}</DependantTypeText>
            </OverflowTextButton>
          </Row>
        </>
      )}
    </DependencyItemDisplayContainer>
  );
};

const DependencyItemDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled.div`
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
  gap: 4px;
  width: 100%;
`;

const HoverStyle = css`
  :hover {
    font-weight: 600;
    letter-spacing: -0.2px;
  }
`;

const UnderlineTextStyle = css`
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
`;

const TextButton = styled.button<{ disabled?: boolean }>`
  padding: 0;
  outline: none;
  border: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.colorLightGray15 : theme.colors.colorCalendarBlue};
  background-color: transparent;
  ${({ disabled }) =>
    !disabled ? `${HoverStyle} ${UnderlineTextStyle}` : undefined};
  $
`;

const OverflowTextButton = styled(TextButton)`
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
`;

const TextOverflowStyle = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DependantText = styled.div`
  max-width: 185px;
  ${TextOverflowStyle}
`;

const DependantTypeText = styled.div`
  white-space: pre;
`;
