import * as constants from 'appConstants';
import * as capacityConstants from '../constants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

const {
  FETCH_HOLIDAYS,
  UPDATE_HOLIDAY,
  CREATE_HOLIDAY,
  OPEN_HOLIDAY_MODAL,
  CLOSE_HOLIDAY_MODAL,
  DELETE_HOLIDAY
} = capacityConstants;
const { WS_HOLIDAY } = constants;
export const initialState = {
  holidays: {},
  holidayOrder: [],
  modalOpen: false,
  modalHolidayId: null,
  modalHolidayIsNew: null,
  modalHolidayIsRemove: false,
  isFetching: false
};
const byId = (item) => item && item.id;
const holidays = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_HOLIDAYS.TRIGGER: {
      return {
        ...state,
        isFetching: true
      };
    }
    case FETCH_HOLIDAYS.SUCCESS: {
      const { holidays } = payload.response;
      return {
        ...state,
        holidays: keyBy(holidays, byId),
        holidayOrder: holidays.map(byId),
        isFetching: false
      };
    }
    case WS_HOLIDAY: {
      if (payload.deleted) {
        return {
          ...state,
          holidays: omit(state.holidays, payload.id),
          holidayOrder: state.holidayOrder.filter((id) => id != payload.id)
        };
      } else {
        return {
          ...state,
          holidays: {
            ...state.holidays,
            [payload.id]: payload
          },
          holidayOrder: state.holidays[payload.id]
            ? state.holidayOrder
            : [...state.holidayOrder, payload.id]
        };
      }
    }
    case UPDATE_HOLIDAY.SUCCESS: {
      const { holiday } = payload.response;
      return {
        ...state,
        holidays: {
          ...state.holidays,
          [holiday.id]: holiday
        }
      };
    }
    case CREATE_HOLIDAY.SUCCESS: {
      const { holiday } = payload.response;
      return {
        ...state,
        holidays: {
          ...state.holidays,
          [holiday.id]: holiday
        },
        holidayOrder: Array.from(new Set([...state.holidayOrder, holiday.id]))
      };
    }
    case DELETE_HOLIDAY.TRIGGER: {
      const { holiday } = payload;
      const { holidays } = state;
      const newHolidays = omit(holidays, [`${holiday.id}`]);
      return {
        ...state,
        holidays: newHolidays,
        holidayOrder: state.holidayOrder.filter((id) => id !== holiday.id)
      };
    }
    case OPEN_HOLIDAY_MODAL: {
      const { id, isNew, isRemove = false } = payload;
      return {
        ...state,
        modalOpen: true,
        modalHolidayId: id,
        modalHolidayIsNew: isNew,
        modalHolidayIsRemove: isRemove
      };
    }
    case CLOSE_HOLIDAY_MODAL: {
      return {
        ...state,
        modalOpen: false,
        modalHolidayId: null,
        modalHolidayIsNew: null
      };
    }
    default:
      return state;
  }
};

export default holidays;
