import ImportFlow from 'CsvImportsModule/components/ImportFlow';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import { TIMESHEET_IMPORT_FEATURE_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useAppSelector } from 'reduxInfra/hooks';
import { getIsConnectingToDefaultRealm } from 'AuthenticationModule/selectors';
import { MembersTimesheetSettingsTable } from './MembersTimesheetSettingsTable';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const TimesheetsTab = () => {
  const isTimesheetImportFeatureEnabled = useAppSelector(
    getIsConnectingToDefaultRealm
  );

  const { pastPlannedAsSpentFlag } = useFeatureFlags();

  return (
    <div
      className="team-settings-modal"
      style={{
        flexDirection: 'column',
        display: 'flex',
        overflow: 'visible',
        paddingLeft: 24
      }}
    >
      <div
        className="team-settings-modal-header"
        style={{ borderBottom: 'none', paddingLeft: 7 }}
      >
        Timesheet
      </div>
      <ImportFlow
        entityType={CsvImportEntity.Timesheet}
        disabled={!isTimesheetImportFeatureEnabled}
        disabledTooltip={TIMESHEET_IMPORT_FEATURE_TIP}
      />
      {pastPlannedAsSpentFlag && <MembersTimesheetSettingsTable />}
    </div>
  );
};

export default TimesheetsTab;
