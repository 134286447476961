/* eslint-disable no-labels */
import { all, put, take } from 'redux-saga/effects';
import { setRequestStatus } from 'actionCreators';
import {
  getIsTriggerAction,
  getActionRequestType,
  success,
  failure,
  abort
} from 'appUtils/request/actions';
import { REQUEST_TYPES } from 'appUtils';
import uuid from 'uuid';
import { initialRequestStatusState } from 'reducers/statuses';

/**
 * returns pattern(s) to be used with saga effect 'take'. see https://redux-saga.js.org/docs/api/#takepattern
 * 1. matching pattern has the correct actionUid
 * 2. furthermore, if action is a request (ie. ends with _TRIGGER), matching patterns must also be
 *    either SUCCESS, FAILURE, or ABORT type of the action
 * @param {string} actionType the action type to match
 * @param {string} actionUid the uid to match
 * @returns
 */
export const generateMatchingPatterns = (actionType, actionUid) => {
  const isRequestAction = getIsTriggerAction(actionType);

  const matchesActionUid = (action) => {
    return action.meta?.actionUid === actionUid;
  };
  const generateMatchingRequestPattern = (requestType) => (action) =>
    action.type === requestType(actionType) && matchesActionUid(action);

  return isRequestAction
    ? [
        generateMatchingRequestPattern(success),
        generateMatchingRequestPattern(failure),
        generateMatchingRequestPattern(abort)
      ]
    : (action) => action.type === actionType && matchesActionUid(action);
};

export const generateErrorMessage = (action, failedOrCancelled, layer) => {
  const cancelled = failedOrCancelled === 'cancelled';
  return `Chain ${cancelled ? 'cancelled' : 'failed'} due to action ${
    action.type
  } in layer ${layer} with actionUid ${action.meta.actionUid}`;
};

export function* chainActionsWorker(action) {
  const {
    actions,
    continueOnFailure,
    continueOnCancellation,
    chainId,
    initial,
    onChainSuccess // temporary callback (until incorporated into the chain) on chain success status
  } = action.payload;
  let index = 0;

  if (chainId) {
    yield put(
      setRequestStatus({
        requestStatusId: chainId,
        ...initialRequestStatusState,
        isExecuting: true,
        ...(initial && { isLoading: true })
      })
    );
  }

  // go through the chain of actions, waiting for completion of each layer
  // - if a layer is an array of actions, all actions must complete before proceeding to the next layer
  // - if !continueOnFailure, any failure will cancel the entire chain
  // - if !continueOnCancellation, any cancellation will cancel the entire chain
  // - if an action is not a request action, we do not need to wait for it to complete
  while (index < actions.length) {
    const actionOrArray = actions[index];
    const actionsToDispatch = Array.isArray(actionOrArray)
      ? actionOrArray
      : [actionOrArray];

    // Give each action instance a unique id. This is used for tracking completion of an action and
    // may be useful for debugging. Can be provided in action.meta, but ultimately this option is for being
    // able to write tests
    const actionUids = actionsToDispatch.map(
      (action) => action.meta?.actionUid || uuid()
    );
    const requestActions = actionsToDispatch.map((action) =>
      getIsTriggerAction(action.type)
    );

    // functions for finding matching actions that will trigger the next chained action(s)
    const matchingPatternFunctions = actionsToDispatch.flatMap(
      (actionToDispatch, index) =>
        requestActions[index]
          ? generateMatchingPatterns(actionToDispatch.type, actionUids[index])
          : [] // we do not need to wait for non-request actions to complete
    );

    // add actionUid and chainId to each action.meta
    const formattedActionsToDispatch = actionsToDispatch.map(
      (actionToDispatch, index) => ({
        ...actionToDispatch,
        meta: {
          ...actionToDispatch.meta,
          actionUid: actionUids[index],
          chainId
        }
      })
    );

    // dispatch the actions for this layer
    yield all(
      formattedActionsToDispatch.map((actionToDispatch) =>
        put(actionToDispatch)
      )
    );

    // for tracking when all actions in this layer have completed
    const actionUidsToWaitFor = new Set(
      actionUids.filter((_, index) => requestActions[index])
    );

    while (actionUidsToWaitFor.size > 0) {
      // wait for any of the matching action types
      const matchedAction = yield take(matchingPatternFunctions);
      const actionUid = matchedAction.meta.actionUid;
      const requestType = getActionRequestType(matchedAction.type);

      const shouldCancelChainDueToActionFailure =
        requestType === REQUEST_TYPES.FAILURE && !continueOnFailure;
      const shouldCancelChainDueToActionCancellation =
        requestType === REQUEST_TYPES.ABORT && !continueOnCancellation;

      if (
        shouldCancelChainDueToActionFailure ||
        shouldCancelChainDueToActionCancellation
      ) {
        if (chainId) {
          yield put(
            setRequestStatus({
              requestStatusId: chainId,
              ...initialRequestStatusState,
              error: generateErrorMessage(
                matchedAction,
                shouldCancelChainDueToActionCancellation
                  ? 'cancelled'
                  : 'failed',
                index
              )
            })
          );
        }
        // cancel the rest of the chain
        return;
      }

      actionUidsToWaitFor.delete(actionUid);
    }

    // go to the next layer
    index++;
  }
  if (chainId) {
    yield put(
      setRequestStatus({
        requestStatusId: chainId,
        ...initialRequestStatusState,
        isSuccess: true
      })
    );
  }
  onChainSuccess && onChainSuccess(); // temporary until incorporated into the chain
}
