'use strict';

exports.__esModule = true;
exports['default'] = OkButton;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function OkButton(_ref) {
  var prefixCls = _ref.prefixCls,
    locale = _ref.locale,
    okDisabled = _ref.okDisabled,
    onOk = _ref.onOk;

  var className = prefixCls + '-ok-btn';
  if (okDisabled) {
    className += ' ' + prefixCls + '-ok-btn-disabled';
  }
  return _react2['default'].createElement(
    'a',
    {
      className: className,
      role: 'button',
      onClick: okDisabled ? null : onOk
    },
    locale.ok
  );
}
module.exports = exports['default'];
