'use strict';

exports.__esModule = true;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(
  _possibleConstructorReturn2
);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DateTHead = require('./DateTHead');

var _DateTHead2 = _interopRequireDefault(_DateTHead);

var _DateTBody = require('./DateTBody');

var _DateTBody2 = _interopRequireDefault(_DateTBody);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var DateTable = (function (_React$Component) {
  (0, _inherits3['default'])(DateTable, _React$Component);

  function DateTable() {
    (0, _classCallCheck3['default'])(this, DateTable);
    return (0, _possibleConstructorReturn3['default'])(
      this,
      _React$Component.apply(this, arguments)
    );
  }

  DateTable.prototype.render = function render() {
    var props = this.props;
    var prefixCls = props.prefixCls;
    return _react2['default'].createElement(
      'table',
      { className: prefixCls + '-table', cellSpacing: '0', role: 'grid' },
      _react2['default'].createElement(_DateTHead2['default'], props),
      _react2['default'].createElement(_DateTBody2['default'], props)
    );
  };

  return DateTable;
})(_react2['default'].Component);

exports['default'] = DateTable;
module.exports = exports['default'];
