import React, { lazy, Suspense } from 'react';
const containerStyle = {
  paddingLeft: 32,
  flex: 1,
  minHeight: 0
};

const StandardPhasesContainer = lazy(() =>
  import('./StandardPhasesTable/StandardPhasesTableContainer')
);

const StandardPhasesTab = () => {
  return (
    <div style={containerStyle}>
      <Suspense fallback={<div />}>
        <StandardPhasesContainer />
      </Suspense>
    </div>
  );
};

export default StandardPhasesTab;
