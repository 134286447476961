import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NoteIconThick = ({
  color = '#808080',
  height = '10',
  className = '',
  width = '13'
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.625 12.1333V0.866667H5H6.25H6.875V3.03333C6.875 3.51198 7.15481 3.9 7.5 3.9H9.375V4.875V6.5V12.1333H0.625ZM9.18119 3.03333L8.53219 2.29506L7.5 1.12096V3.03333H9.18119ZM0 0.866667C0 0.38802 0.279822 0 0.625 0H5H6.25H7.27644C7.421 0 7.56106 0.0694783 7.67281 0.196604L8.92856 1.625L9.77138 2.58371C9.91613 2.74832 10 2.99421 10 3.25377V4.875V6.5V12.1333C10 12.612 9.72019 13 9.375 13H0.625C0.279822 13 0 12.612 0 12.1333V0.866667Z"
      fill={color}
    />
  </SvgIcon>
);

export default NoteIconThick;
