import React from 'react';
import styled from 'styled-components';
import ImportFlow from 'CsvImportsModule/components/ImportFlow';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import { Modal } from 'reactstrap';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';

interface ImportModalProps {
  handleClose: () => void;
  entityType: CsvImportEntity;
}

export const ImportModal = ({ handleClose, entityType }: ImportModalProps) => {
  return (
    <SimpleConfirmModal
      isOpen
      toggle={handleClose}
      header={headerOptions[entityType]}
      body={
        <BodyContainer>
          <ImportFlow entityType={entityType} />
        </BodyContainer>
      }
      stylesWrapper={StyleWrapper}
      hasNoButtons
      hasXCloseIcon
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyleWrapper = styled(Modal)`
  .modal-content {
    width: 460px;
    padding: 34px;
    border-radius: 3px !important;
    margin: auto;
  }
  .confirm-modal-header {
    font-size: 26px;
  }
  .confirm-modal-body {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 45px;
  }
`;

const BodyContainer = styled.div`
  padding-left: 26px;
`;

const headerOptions = {
  [CsvImportEntity.WorkPlan]: 'Import Work Plans',
  [CsvImportEntity.Member]: 'Import Members',
  [CsvImportEntity.ProjectPhaseActivityPhase]:
    'Import Projects, Phases, and Work Categories'
} as Record<CsvImportEntity, string>;
