import { useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Moment } from 'moment';
import SinglePaneCalendarTimePicker from 'components/CalendarTimePicker/SinglePaneCalendarTimePicker';

interface CalendarSetTimeProps {
  startTime: Moment | null;
  endTime: Moment | null;
  startTimeSelect: (value: Moment) => void;
  endTimeSelect: (value: Moment) => void;
  dailyHours: string;
}

const CalendarSetTime = ({
  startTime,
  endTime,
  startTimeSelect,
  endTimeSelect,
  dailyHours
}: CalendarSetTimeProps) => {
  const timePopoverTarget = useRef(null);

  const startTimePlaceholder = startTime
    ? startTime.format('h:mm a')
    : 'Start Time';
  const endTimePlaceholder = endTime ? endTime.format('h:mm a') : 'End Time';

  return (
    <TimeDropdownContainer ref={timePopoverTarget}>
      <SinglePaneCalendarTimePicker
        initialTime={startTime}
        handleUpdateTime={startTimeSelect}
        placeholder={startTimePlaceholder}
      />

      <SinglePaneCalendarTimePicker
        initialTime={endTime}
        handleUpdateTime={endTimeSelect}
        placeholder={endTimePlaceholder}
      />
    </TimeDropdownContainer>
  );
};

const TimeDropdownContainer = styled.div`
  height: 28px;
  width: 100%;
  font-size: 13px;
  display: flex;
  align-items: center;
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
`;

export default CalendarSetTime;
