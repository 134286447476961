import React from 'react';
import { connect } from 'react-redux';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  CopyContainer,
  CopyHeader,
  CopyParagraph,
  StyledDateChangeConfirmation
} from 'BudgetModule/components/styles';
import {
  updateMemberRate,
  deleteMemberRate
} from 'BudgetModule/actionCreators';
import moment from 'moment';

const copyHash = {
  end_date: 'start date for the next',
  start_date: 'end date for the prior'
};

class RateDeleteConfirmation extends React.Component {
  renderCopy = () => (
    <CopyContainer>
      <CopyHeader>Are you sure you want to delete this rate?</CopyHeader>
      <CopyParagraph>
        {copyHash[this.props.dateType] &&
          ` The change will also update the ${
            copyHash[this.props.dateType]
          } rate as
        shown above.`}
      </CopyParagraph>
    </CopyContainer>
  );

  renderButtons = () => {
    const { handleClose } = this.props;
    return (
      <ModalButtonsRow>
        <Left />
        <Right>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ConfirmButton onClick={this.handleConfirm}>Confirm</ConfirmButton>
        </Right>
      </ModalButtonsRow>
    );
  };

  handleConfirm = () => {
    const { deleteItem, handleClose } = this.props;
    const { memberRates, rate_id } = deleteItem;
    this.deleteMemberRate();
    handleClose();
  };

  deleteMemberRate = () => {
    const { deleteMemberRate, deleteItem } = this.props;
    const { rate_id, member_budget_id, projectId } = deleteItem;
    deleteMemberRate({
      id: rate_id,
      memberBudgetId: member_budget_id,
      projectId
    });
  };

  render() {
    return (
      <StyledDateChangeConfirmation>
        {this.renderCopy()}
        {this.renderButtons()}
      </StyledDateChangeConfirmation>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {
  updateMemberRate,
  deleteMemberRate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RateDeleteConfirmation);
