const EmptyDiv = () => <div />;

const getColumnCells = (rowTypes, rowToCells, column) => {
  return Object.values(rowTypes).reduce((acc, rowType) => {
    const isEntireRowSameComponent = typeof rowToCells[rowType] === 'function';

    if (!isEntireRowSameComponent && !rowToCells[rowType]) {
      console.error(`Missing rowToCells.${rowType}`);
    }

    acc[rowType] = isEntireRowSameComponent
      ? rowToCells[rowType] // the entire row has same Component
      : rowToCells[rowType][column] || rowToCells[rowType].default;
    return acc;
  }, {});
};

const generateColumns = ({
  tableColumns,
  columnWidths,
  customColumnWidths = {},
  rowTypes,
  rowToCells
}) => {
  return tableColumns.map((columnHeader) => {
    const headerType = columnHeader.headerType;
    // The insertion of custom properties into the column definitions requires
    // interface changes to `ColumnInterface<D extends object = {}>`.
    return {
      ...columnHeader,
      Cell: EmptyDiv,
      Header: EmptyDiv,
      Footer: EmptyDiv,
      ...getColumnCells(rowTypes, rowToCells, headerType),
      width: columnWidths[headerType] || 0,
      customColumnWidths: {
        ...customColumnWidths[headerType]
      }
    };
  });
};

export default generateColumns;
