import { noop } from 'appUtils';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const ArchivedContainer = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-size: 15px;
  cursor: pointer;
  position: relative;
  :hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const MilestoneShowArchivedRow = (props) => {
  if (props.column.id !== 'title') {
    return null;
  }
  const handleClick = props?.row?.original?.setIsShowingArchived || noop;
  const numArchivedPhases = props?.row?.original?.numArchivedPhases || '';
  return (
    <ArchivedContainer onClick={handleClick}>
      Show Archived ({numArchivedPhases})
    </ArchivedContainer>
  );
};

export default MilestoneShowArchivedRow;
