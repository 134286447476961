import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';

import { PersonalSettings } from '..';

import {
  fetchTeams,
  setModal,
  setActiveKey,
  toggleEmailNotification,
  logoutUser
} from 'actionCreators';
import {
  getUsers,
  getGroupsState,
  getSettings,
  getBilling,
  getAuth,
  getMe,
  getMyTeamMembership
} from 'selectors';

class PersonalSettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPersonalModalOpen: false,
      isTeamModalOpen: false
    };
    bindAll(this, [
      'goBack',
      'openEditModal',
      'closeEditModal',
      'openTeamModal',
      'closeTeamModal',
      'toggleSwitch'
    ]);
  }

  toggleSwitch() {
    this.props.toggleEmailNotification(
      !this.props.settings.isEmailNotificationOn
    );
  }

  componentDidMount() {
    this.props.fetchTeams();
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  openEditModal() {
    this.setState({ isPersonalModalOpen: true });
  }

  closeEditModal() {
    this.setState({ isPersonalModalOpen: false });
  }

  openTeamModal() {
    this.setState({ isTeamModalOpen: true });
  }

  closeTeamModal() {
    const { setModal, setActiveKey } = this.props;

    setModal(null);
    setActiveKey('1');
  }

  render() {
    const {
      logoutUser,
      setModal,
      billing,
      teams,
      history,
      me = ''
    } = this.props;
    const isEmailNotificationOn = this.props.settings.isEmailNotificationOn;
    const myTeamMembership = this.props.myTeamMembership;

    return (
      <PersonalSettings
        me={me}
        teams={teams}
        billing={billing}
        setModal={setModal}
        goBack={this.goBack}
        openEditModal={this.openEditModal}
        closeEditModal={this.closeEditModal}
        isPersonalModalOpen={this.state.isPersonalModalOpen}
        openTeamModal={this.openTeamModal}
        closeTeamModal={this.closeTeamModal}
        isTeamModalOpen={this.state.isTeamModalOpen}
        toggleSwitch={this.toggleSwitch}
        isEmailNotificationOn={isEmailNotificationOn}
        myTeamMembership={myTeamMembership}
        logoutUser={logoutUser}
        history={history}
      />
    );
  }
}

PersonalSettingsContainer.propTypes = {
  fetchTeams: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  me: getMe(state),
  auth: getAuth(state),
  teams: state.teams.allTeams,
  billing: getBilling(state),
  settings: getSettings(state),
  groups: getGroupsState(state),
  users: getUsers(state),
  myTeamMembership: getMyTeamMembership(state)
});

const mapDispatchToProps = {
  fetchTeams,
  setModal,
  setActiveKey,
  toggleEmailNotification,
  logoutUser
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  toggleEmailNotification: (isEmailNotificationOn) =>
    dispatchProps.toggleEmailNotification(
      stateProps.auth.token,
      stateProps.auth.account.id,
      isEmailNotificationOn
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(PersonalSettingsContainer)
);
