import { useMemo } from 'react';
import usePrevious from 'appUtils/usePrevious';
import isEqual from 'lodash/isEqual';

/**
 * For checking if any of the given values have changed (using deep comparison) pre-render
 * eg use case - for preventing loadMoreItems being called with incorrect offset
 *               in a Table on viewBy change before a useEffect calling initial fetch
 *               can trigger ID order reset - match the initial fetch dependency array
 * @param {any} state  the values being checked
 * @returns {boolean}     true if some values have changed
 */
const useHasStateChanged = (state) => {
  const prevState = usePrevious(state);
  return useMemo(() => {
    return !isEqual(state, prevState);
  }, [prevState, state]);
};

export default useHasStateChanged;
