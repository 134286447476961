import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
const byId = (item) => item && item.id;

export const initialState = {
  qbCustomers: {},
  hiddenTeams: {},
  hiddenQbCustomers: {}
};
const qbCustomers = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_CUSTOMERS.SUCCESS: {
      const { customers } = payload.response;
      return {
        ...state,
        qbCustomers: keyBy(customers, byId)
      };
    }
    case constants.SET_HIDDEN_CUSTOMERS: {
      const { customers } = action;
      return {
        ...state,
        hiddenQbCustomers: keyBy(customers, byId)
      };
    }
    case constants.SET_HIDDEN_TEAMS: {
      const { teams } = action;
      return {
        ...state,
        hiddenTeams: keyBy(teams, byId)
      };
    }

    default: {
      return state;
    }
  }
};

export default qbCustomers;
