import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface ThreeLinesIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  fill?: string;
}

const ThreeLinesIcon = ({
  height = 13,
  width = 20,
  fill = '#0074D9'
}: ThreeLinesIconProps) => {
  return (
    <SvgIcon fill="none" width={width} height={height} viewBox={'0 0 20 13'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.94118H17.8861V0H0V1.94118Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.2927H19.5121V10.3516H0V12.2927Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.12086H14.6341V5.17969H0V7.12086Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ThreeLinesIcon;
