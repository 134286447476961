import React from 'react';
import PropTypes from 'prop-types';
import {
  formatStatusDate,
  getTruncatedDescription,
  stopPropagation
} from 'appUtils';
import { stripMentionMarkup } from 'appUtils/mentions';
import replyArrow from 'images/reply-status.svg';
import MemberInitials, {
  StyledMemberCircle
} from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import CommentBubbleIcon from 'icons/CommentBubbleIcon';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme from 'theme';

const StatusCount = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 12px;
    margin-right: 3px;
  }
`;

const StatusItemContainer = styled.div`
  display: flex;
  .small-logged-member {
    height: 28px;
    width: 28px;
    flex: 0 0 28px;
    font-size: 12px;
  }
  &:hover {
    ${StatusCount} {
      color: ${theme.colors.colorRoyalBlue};
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const MemberInitialsContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'proxima-nova';
  font-weight: 400;
  && ${StyledMemberCircle} {
    height: 30px;
    width: 30px;
    flex: 0 0 30px;
  }
`;

const StatusDescriptionContainer = styled.div`
  padding: 0px;
  margin: 0 0 0 8px;
  width: 100%;
`;

const StatusMetadata = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StatusAuthor = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray3};
`;

const StatusDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
  color: ${theme.colors.colorMediumGray5};
`;

const StatusDescription = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StatusHeaderContainer = styled.div`
  display: flex;
`;

const linkify = (words) => {
  const wordsArray = words.split(' ');
  const regExp =
    /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi;

  const linkifiedWords = wordsArray.map((word) => {
    if (word.match(regExp)) {
      let full_url = word;
      if (!full_url.match('^https?://')) {
        full_url = 'http://' + full_url;
      }
      return (
        <a key={word} href={full_url} target="_blank">
          {word}
        </a>
      );
    } else {
      return ` ${word} `;
    }
  });
  return linkifiedWords;
};

// statuses will change into comments/replies next sprint
const renderReplyCounter = (statusCount) => {
  return [
    <img key={1} src={replyArrow} />,
    <span key={2} className="count">
      {statusCount}
    </span>
  ];
};

const StatusItem = ({
  status,
  me,
  canEdit,
  handleClick,
  projectId,
  isOnProjectDetail,
  statusCount
}) => {
  const singleLineCutoff = isOnProjectDetail ? 40 : 43;
  const numLines = isOnProjectDetail ? 2 : 3;

  const description = getTruncatedDescription({
    fullText: status.description,
    singleLineCutoff,
    lastLineCutoff: singleLineCutoff,
    numLines
  }).truncatedDescription;

  return (
    <StatusItemContainer>
      {me && status && (
        <>
          <MemberInitialsContainer>
            <MemberInitials
              isProjectStatus={true}
              projectId={projectId}
              member={status}
              classes={cn('item-account selected', {
                'small-logged-member': status.account.id === me.id,
                'small-regular-member': status.account.id !== me.id
              })}
              onClick={stopPropagation}
              idx={'idx-placeholder'}
            />
          </MemberInitialsContainer>
          <StatusDescriptionContainer>
            <StatusMetadata>
              <StatusHeaderContainer>
                <StatusAuthor>{status.account.name}</StatusAuthor>
                <StatusDate>
                  {formatStatusDate(status.creation_date)}
                </StatusDate>
              </StatusHeaderContainer>
              <StatusCount data-for="count" data-tip="Project Updates">
                <CommentBubbleIcon /> {statusCount}
              </StatusCount>
            </StatusMetadata>

            <StatusDescription>
              {linkify(stripMentionMarkup(description))}
              {isOnProjectDetail &&
                statusCount &&
                renderReplyCounter(statusCount)}
            </StatusDescription>
          </StatusDescriptionContainer>
        </>
      )}
      <ReactTooltip effect="solid" id="count" place="top" />
    </StatusItemContainer>
  );
};

StatusItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  status: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default StatusItem;
