import { Modal, ModalBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getIsSettingsModalOpen, getMyTeamMembership } from 'selectors';
import { closeTimesheetSettingsModal } from 'actionCreators';
import { updateTeamMembership } from 'TeamsModule/actionCreators';
import { StyledModalHeader, StyledHeaderDiv, DoneButton } from '../styles';
import Setting from './Setting';
import DropdownSetting from './DropdownSetting';
import {
  groupByOptions,
  placeholderOptions,
  placeholderDescriptions,
  timesheetOptions
} from 'appConstants/timesheets';
import {
  TimesheetPreferences,
  GroupByType,
  PlaceholderType
} from 'TeamsModule/models/settings';

const TimesheetSettingsModal = () => {
  const isOpen = useAppSelector(getIsSettingsModalOpen);
  const teamMembership = useAppSelector(getMyTeamMembership);
  const currentSettings = teamMembership?.timesheet_preferences;
  const dispatch = useAppDispatch();

  const getDropdownSettings = (currentSettingsValues: TimesheetPreferences) => {
    // We need this since theres a chance that show_placeholder may be true or false from before.
    // In this case we want placeholders to be off. this can eventually be removed once a BE script has run to map true/false to appropriate new values
    const currentPlaceholder = placeholderDescriptions[
      currentSettingsValues?.show_placeholder
    ]
      ? currentSettingsValues.show_placeholder
      : timesheetOptions.NO_PLACEHOLDER;
    return [
      {
        settingName: 'Group Time Entries',
        menuTitle: 'Group By',
        currentOption: currentSettingsValues?.group_by || 'project',
        options: groupByOptions,
        handleChange: handleGroupByChange,
        description: ''
      },
      {
        settingName: 'Placeholder Time Estimates',
        menuTitle: 'Placeholder Time Estimates',
        description: placeholderDescriptions[currentPlaceholder],
        currentOption: currentPlaceholder,
        options: placeholderOptions,
        handleChange: handlePlaceholderChange
      }
    ];
  };

  const getSwitchSettings = (currentSettingsValues: TimesheetPreferences) => {
    return [
      {
        title: 'Last Week’s Projects',
        description:
          "Automatically add any projects from last week's Timesheet to this week’s Timesheet.",
        isOn:
          currentSettingsValues?.auto_create_following_week_corresponding_description ??
          false,
        onText: 'Yes',
        offText: 'No',
        formatChange: (val: boolean) => ({
          auto_create_following_week_corresponding_description: val
        })
      },
      // {   ***** Hide for now as this is not ready/ does not work from a BE perspective
      //   title: 'Auto-fill',
      //   description: 'Automatically Enter Time',
      //   isOn: currentSettingsValues?.automate
      //     ? currentSettingsValues.automate
      //     : false,
      //   formatChange: (val) => ({ automate: val })
      // },
      {
        title: 'Suggested Projects',
        description:
          'Automatically add projects to your timesheet based on scheduling information and activity.',
        isOn:
          currentSettingsValues?.auto_create_actor_descriptions_by_user_activity ??
          false,
        onText: 'Yes',
        offText: 'No',
        formatChange: (val: boolean) => ({
          auto_create_actor_descriptions_by_user_activity: val
        })
      }
    ];
  };

  const handleClose = () => {
    dispatch(closeTimesheetSettingsModal());
  };

  const handleChange = (val: Partial<TimesheetPreferences>) => {
    const body = {
      timesheetPreferences: val,
      teamMembershipId: teamMembership?.id
    };
    dispatch(updateTeamMembership(body));
  };

  const handleGroupByChange = (val: GroupByType) => {
    handleChange({
      group_by: val
    });
  };

  const handlePlaceholderChange = (val: PlaceholderType) => {
    handleChange({
      show_placeholder: val
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="timesheet-settings-modal"
    >
      <ModalBody>
        <StyledHeaderDiv>
          <StyledModalHeader>Timesheet Settings</StyledModalHeader>
          <DoneButton onClick={handleClose}>Done</DoneButton>
        </StyledHeaderDiv>
        {getDropdownSettings(currentSettings).map((setting, index) => {
          const {
            handleChange,
            options,
            menuTitle,
            settingName,
            currentOption,
            description
          } = setting;
          return (
            <DropdownSetting
              key={`dropdown-${index}`}
              settingName={settingName}
              menuTitle={menuTitle}
              options={options}
              onChange={handleChange}
              currentOption={currentOption}
              description={description}
            />
          );
        })}
        {getSwitchSettings(currentSettings).map((setting, index) => {
          const { formatChange, ...rest } = setting;
          return (
            <Setting
              key={`switch-${index}`}
              {...rest}
              onChange={() => handleChange(formatChange(!setting.isOn))}
            />
          );
        })}
      </ModalBody>
    </Modal>
  );
};

export default TimesheetSettingsModal;
