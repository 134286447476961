import { SamlDetailsEditModal } from 'AuthenticationModule/components/SamlDetailsEditModal';
import { useState } from 'react';
import styled from 'styled-components';
import { useSecurity } from '../hooks/useSecurity';
import { SharedUiSection } from './SharedUiSection';

export const SamlSection = () => {
  const [isSamlDetailsEditModalOpen, setIsSamlDetailsEditModalOpen] =
    useState(false);

  const { isSamlActive, isSamlForciblyDisabled, disableSaml, isModifyingSaml } =
    useSecurity();

  const openSamlDetailsEditModal = () => {
    setIsSamlDetailsEditModalOpen(true);
  };

  return (
    <>
      <RootContainer>
        <SharedUiSection
          headerText="SAML 2.0 Single Sign On"
          descriptionText="Enable and configure SAML 2.0 SSO for your organization."
          confirmDeleteModalContextString="SAML 2.0 Single Sign On"
          isForciblyDisabled={isSamlForciblyDisabled}
          isActive={isSamlActive}
          forciblyDisabledTooltip="Can't enable SAML 2.0 Single Sign On. Disable multi-factor authentication first."
          isUserActionDisabled={isModifyingSaml}
          onRequestDisable={disableSaml}
          onRequestEnable={openSamlDetailsEditModal}
          onRequestEdit={openSamlDetailsEditModal}
        />
      </RootContainer>
      <SamlDetailsEditModal
        isOpen={isSamlDetailsEditModalOpen}
        onToggle={() => setIsSamlDetailsEditModalOpen(false)}
      />
    </>
  );
};

const RootContainer = styled.div``;
