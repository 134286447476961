import React from 'react';
import cn from 'classnames';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchProjectsByGroup,
  clearCachedProjects,
  triggerFetchInitialTasks,
  triggerTaskStoreFlush,
  setCurrentTaskListFilter
} from 'actionCreators';
import {
  getSearchText,
  getSelectedAccountIds,
  getMatchedRouteParams,
  getIsOnProjectView
} from 'selectors';
import VIEWS from 'appConstants/taskViewTypes';
class ToggleViewDropdown extends React.Component {
  sortTasks =
    ({ newFilter }) =>
    () => {
      const {
        setCurrentTaskListFilter,
        isOnProjectView,
        triggerFetchInitialTasks
      } = this.props;

      setCurrentTaskListFilter({ state: newFilter });
      !isOnProjectView && triggerFetchInitialTasks({});
    };

  fetchProjectsByView = (nextView) => () => {
    const {
      searchText,
      projectsAccountIds,
      clearCachedProjects,
      fetchProjectsByGroup,
      matchedParams: { boardId }
    } = this.props;
    clearCachedProjects();
    fetchProjectsByGroup({
      groupId: boardId,
      isActive: nextView === VIEWS.INCOMPLETE_OR_ACTIVE,
      searchText,
      accountIds: projectsAccountIds
    });
  };

  fetchActiveProjects = this.fetchProjectsByView(VIEWS.INCOMPLETE_OR_ACTIVE);
  fetchArchivedProjects = this.fetchProjectsByView(VIEWS.COMPLETE_OR_ARCHIVED);

  sortIncomplete = this.sortTasks({ newFilter: 'incomplete' });
  sortAll = this.sortTasks({ newFilter: 'default' });
  sortComplete = this.sortTasks({ newFilter: 'completed' });

  render() {
    const { isOnTasks, taskFilterState, isArchived = false } = this.props;

    return (
      <div className="task-dropdown-container">
        <DropdownMenu className="dropdown-menu-right">
          <DropdownItem
            className={cn('clickable', {
              selected: isOnTasks
                ? taskFilterState === 'incomplete'
                : !isArchived
            })}
            onClick={isOnTasks ? this.sortIncomplete : this.fetchActiveProjects}
          >
            {isOnTasks ? 'Incomplete' : 'Active'}
          </DropdownItem>

          <DropdownItem
            className={cn('clickable', {
              selected: isOnTasks ? taskFilterState === 'completed' : isArchived
            })}
            onClick={isOnTasks ? this.sortComplete : this.fetchArchivedProjects}
          >
            {isOnTasks ? 'Completed' : 'Archived'}
          </DropdownItem>
          {isOnTasks && (
            <DropdownItem
              className={cn('clickable', {
                selected: taskFilterState === 'default'
              })}
              onClick={this.sortAll}
            >
              {'All Tasks'}
            </DropdownItem>
          )}
        </DropdownMenu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchText: getSearchText(state),
  projectsAccountIds: getSelectedAccountIds(state),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state)
});

const mapDispatchToProps = {
  fetchProjectsByGroup,
  clearCachedProjects,
  triggerFetchInitialTasks,
  triggerTaskStoreFlush,
  setCurrentTaskListFilter
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToggleViewDropdown)
);
