import React, { lazy, Suspense } from 'react';

const containerStyle = {
  paddingLeft: 32,
  flex: 1,
  minHeight: 0
};

const StandardRatesTableContainer = lazy(() =>
  import('./StandardRatesTable/StandardRatesTableContainer')
);

const StandardRatesTab = () => {
  return (
    <div style={containerStyle}>
      <Suspense fallback={<div />}>
        <StandardRatesTableContainer />
      </Suspense>
    </div>
  );
};

export default StandardRatesTab;
