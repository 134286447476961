import React, { Component } from 'react';
import truncate from 'lodash/truncate';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import {
  makeSafeGetProjectAssignmentProjects,
  getAuth,
  getProjectTaskGroups,
  getProjectTaskGroupOrder,
  getNumberOfAllProjects,
  getIsFetchingAllProjects,
  getAllFetchedProjects,
  getAllFetchedProjectsLength,
  makeGetTaskProjectIsPersonalOrUndefined
} from 'selectors';
import MultiStepFlyout from '../MultiStepFlyout/MultiStepFlyout';
import { fetchProjectTaskGroups, fetchAllProjects } from 'actionCreators';
import BackKarat from 'icons/BackKarat';
import Row from 'components/ProjectsAndPhasesDropdown/Row';
import { BATCH_ACTION_TASK_GROUP_HEADER_CONSTANTS } from 'appConstants';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';

const HeaderText = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #3b3b3b;
`;
const MoveToGroupText = styled.div`
  margin-left: 10px;
  .project-task-move-select-list & {
    font-size: 22px;
    margin-left: 0px;
  }
`;

export const ProjectListItem = styled.li`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray4};
  }
`;

const ProjectTitle = styled.span`
  color: ${({ theme }) => theme.colors.colorSemiDarkGray4};
  font-size: 13px;
  margin-right: 4px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const moveToCopy = {
  headerInitial: 'Move To',
  headerEdit: 'Move To',
  searchPlaceholder: 'Search Projects or Select Below'
};
const groupCopy = {
  headerInitial: 'Move To',
  headerEdit: 'Move To',
  searchPlaceholder: 'Search Task Lists or Select Below'
};

const { MOVE_WITH_EXISTING, COPY_WITH_EXISTING } =
  BATCH_ACTION_TASK_GROUP_HEADER_CONSTANTS;

const copy = {
  id: 'keep',
  name: 'Copy With Existing Header(s)'
};

const move = {
  id: 'keep',
  name: 'Move With Existing Header(s)'
};

const withExitingHeaderOptions = {
  [MOVE_WITH_EXISTING]: move,
  [COPY_WITH_EXISTING]: copy
};
const resetIdsState = {
  selectedProjectId: null,
  selectedActivityId: null,
  selectedPhaseId: null,
  selectedActivityPhaseId: null
};
class ProjectMenu extends Component {
  state = {
    isOpen: false,
    searchText: '',
    groupsVisible: this.props.groupsVisible || false,
    selectedProjectId: this.props.groupsVisible ? this.props.projectId : null,
    selectedActivityId: this.props.groupsVisible ? this.props.activityId : null,
    selectedPhaseId: this.props.groupsVisible ? this.props.phaseId : null,
    selectedActivityPhaseId: this.props.groupsVisible
      ? this.props.activityPhaseId
      : null
  };

  componentDidMount() {
    const { projectId, fetchProjectTaskGroups } = this.props;
    if (projectId) {
      fetchProjectTaskGroups({ projectId });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { groupsVisible, projectId, activityId, activityPhaseId, phaseId } =
      this.props;
    const newIds = {
      selectedProjectId: projectId,
      selectedPhaseId: phaseId,
      selectedActivityId: activityId,
      selectedActivityPhaseId: activityPhaseId
    };
    if (prevProps.groupsVisible !== groupsVisible) {
      this.setState({
        groupsVisible,
        ...(groupsVisible ? newIds : resetIdsState)
      });
    }
  }

  handleProjectClick = (e, { item }) => {
    const {
      onSelect,
      setVisibility,
      fetchProjectTaskGroups,
      isVirtualList,
      projectsOnly,
      preventCloseOnSelect
    } = this.props;
    const { id, task_groups_count } = item;

    e.preventDefault();
    e.stopPropagation();
    if (!projectsOnly) {
      this.setState({
        groupsVisible: true,
        selectedProjectId: id
      });
      if (task_groups_count > 1 || isVirtualList) {
        fetchProjectTaskGroups({ projectId: id });
        return;
      }
    }
    if (!preventCloseOnSelect) {
      setVisibility(false);
    }
    onSelect(id);
  };

  handleProjectPhaseActivityClick = ({
    projectId,
    phaseId,
    activityId,
    activityPhaseId,
    taskGroupsCount,
    defaultTaskGroupId
  }) => {
    const {
      onSelect,
      setVisibility,
      fetchProjectTaskGroups,
      isVirtualList,
      projectsOnly,
      preventCloseOnSelect
    } = this.props;

    if (!projectsOnly) {
      this.setState({
        groupsVisible: true,
        selectedProjectId: projectId,
        selectedPhaseId: phaseId,
        selectedActivityId: activityId,
        selectedActivityPhaseId: activityPhaseId
      });
      if (taskGroupsCount > 1 || isVirtualList) {
        fetchProjectTaskGroups({ projectId });
        return;
      }
    }
    if (!preventCloseOnSelect) {
      setVisibility(false);
    }

    onSelect(
      projectId,
      defaultTaskGroupId,
      phaseId,
      activityId,
      activityPhaseId,
      taskGroupsCount
    );
  };

  itemFilter = (item, searchWords) => {
    return filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['title', 'description', 'name', 'project_number']
    });
  };

  taskGroupItemFilter = (item, searchWords) => {
    const { projectTaskGroups } = this.props;
    const taskGroup = projectTaskGroups[item];
    return (
      taskGroup &&
      filterItemWithWhiteSpace({
        searchWords,
        item: taskGroup,
        filterKeysArray: ['name']
      })
    );
  };

  renderItemProject = ({ item }) => {
    if (this.props.renderItemProject) {
      return this.props.renderItemProject(item);
    }
    return (
      <ProjectListItem key={item.id}>
        <Row item={item} />
      </ProjectListItem>
    );
  };

  // item is an id
  handleGroupClick = (e, { item }) => {
    const { setVisibility, onSelect } = this.props;
    const {
      selectedProjectId,
      selectedPhaseId,
      selectedActivityId,
      selectedActivityPhaseId
    } = this.state;
    e.preventDefault();
    e.stopPropagation();
    setVisibility(false);

    onSelect(
      selectedProjectId,
      item,
      selectedPhaseId,
      selectedActivityId,
      selectedActivityPhaseId
    );
  };

  // item is an id
  renderItemGroups = ({ item }) => {
    const { projectTaskGroups } = this.props;

    const taskGroup = projectTaskGroups[item] || withExitingHeaderOptions[item];
    if (!taskGroup) return null;
    return (
      <ProjectListItem key={taskGroup.id}>
        <ProjectTitle role="button" tabIndex="-1" className="project-title">
          {taskGroup.name}
        </ProjectTitle>
      </ProjectListItem>
    );
  };

  toggleGroups = () =>
    this.setState({
      groupsVisible: !this.state.groupsVisible,
      selectedProjectId: null
    });

  loadMoreItems = ({ search }) => {
    const { fetchAllProjects, isLoading, fetchedProjectsCount } = this.props;
    if (!isLoading) {
      fetchAllProjects({
        searchText: search,
        offset: fetchedProjectsCount,
        limit: 30
      });
    }
  };

  loadInitialItems = ({ search }) => {
    const { fetchAllProjects } = this.props;
    fetchAllProjects({
      searchText: search,
      offset: 0,
      limit: 30
    });
  };

  hasNextPage = () => {
    const { totalCount, fetchedProjectsCount } = this.props;
    return fetchedProjectsCount < totalCount;
  };

  renderHeader = () => {
    const { groupsVisible } = this.state;
    const {
      headerText,
      disableProjectSelection = false,
      popoverClassName
    } = this.props;
    return (
      <div
        style={{
          justifyContent: 'flex-start',
          padding: '2px 0px',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {groupsVisible && !disableProjectSelection && (
          <div
            onClick={this.toggleGroups}
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
              alignSelf: 'center',
              cursor: 'pointer'
            }}
          >
            <BackKarat />
          </div>
        )}
        <HeaderText>
          {groupsVisible ? (
            <MoveToGroupText>{'Select List'}</MoveToGroupText>
          ) : (
            headerText
          )}
        </HeaderText>
      </div>
    );
  };

  handleClose = () => this.props.setVisibility(false);

  getProjectTaskGroupItems = () => {
    const { selectedProjectId } = this.state;
    const { projectTaskGroupOrder, isVirtualList, isCopy } = this.props;
    const taskGroups = projectTaskGroupOrder[selectedProjectId] || [];
    const keepTaskGroupOption = isCopy
      ? COPY_WITH_EXISTING
      : MOVE_WITH_EXISTING;
    // const onlyTasksOption = 'onlyTasks';

    if (!isVirtualList) {
      // logic not present to handle preserving task groups
      return taskGroups;
    }
    return [
      keepTaskGroupOption,
      ...taskGroups
      // todo - implement only tasks option
      // ...(taskGroups.length ? taskGroups : [onlyTasksOption])
    ];
  };

  render() {
    const {
      target,
      myProjects,
      isCopy,
      isMove,
      totalCount,
      popoverClassName,
      noHeader,
      listWidth,
      itemPreFilter = () => true,
      styleWrapper,
      itemHeight,
      customCopy,
      showCloseInSearch,
      taskProjectIsPersonal,
      hidePTO,
      searchTextOnOpen,
      task,
      shouldUseOldProjectDropdown
    } = this.props;
    const { groupsVisible } = this.state;
    const popoverOffset = isCopy ? '0px, -90px' : isMove ? '0px, -125px' : null;
    /* offset is needed because the target is the container, not the actual button due to using React.cloneElement in TaskBatchActionsMenu.js
    for the children of <ProjectSelectionPopover/> aka the actual buttons which prevents ref from being set & passed as the target */
    const data = groupsVisible
      ? this.getProjectTaskGroupItems().filter(itemPreFilter)
      : myProjects.filter(itemPreFilter);

    const oldProjectDropdown = (
      <MultiStepFlyout
        copy={{ ...moveToCopy, ...customCopy }}
        key="projects"
        items={data}
        idKey="id"
        isGlobal
        renderHeader={this.renderHeader}
        renderItem={this.renderItemProject}
        itemHeight={itemHeight || 56}
        handleSelect={this.handleProjectClick}
        handleSubmit={this.handleProjectClick}
        itemFilter={this.itemFilter}
        handleClose={this.handleClose}
        isWhite
        searchEnabled
        editDisabled
        hideFooter
        top={0}
        target={target}
        popoverOffset={popoverOffset}
        loadMoreItems={this.loadMoreItems}
        loadInitialItems={this.loadInitialItems}
        hasNextPage={this.hasNextPage}
        totalCount={totalCount}
        popoverClassName={`${popoverClassName || ''} ${
          groupsVisible ? 'groups-visible' : ''
        }`}
        noHeader={noHeader}
        listWidth={listWidth}
        styleWrapper={styleWrapper}
        showCloseInSearch={showCloseInSearch}
        searchTextOnOpen={searchTextOnOpen}
      />
    );

    return groupsVisible ? (
      <MultiStepFlyout
        key="groups" // need separate keys so that they're unmounted because of memo logic in flyout
        copy={groupCopy}
        items={data}
        idKey="id"
        renderHeader={this.renderHeader}
        renderItem={this.renderItemGroups}
        handleSelect={this.handleGroupClick}
        handleSubmit={this.handleGroupClick}
        itemFilter={this.taskGroupItemFilter}
        handleClose={this.handleClose}
        isWhite
        isGlobal
        searchEnabled
        editDisabled
        itemHeight={itemHeight || 56}
        hideFooter
        top={0}
        target={target}
        popoverOffset={popoverOffset}
        popoverClassName={`${popoverClassName || ''} ${
          groupsVisible ? 'groups-visible' : ''
        }`}
        noHeader={noHeader}
        listWidth={listWidth}
        styleWrapper={styleWrapper}
        showCloseInSearch={showCloseInSearch}
      />
    ) : shouldUseOldProjectDropdown ? (
      oldProjectDropdown
    ) : (
      <ProjectsThenPhasesSelector
        renderSelector={false}
        isOpen
        handleSelect={this.handleProjectPhaseActivityClick}
        target={target}
        showBudgetInformation={false}
        includePersonalProjects={taskProjectIsPersonal}
        hidePTO={hidePTO}
        onClose={this.handleClose}
        preventCloseOnSelect
        accountId={task?.assignee_id}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getSafeProjectAssignmentProjects =
    makeSafeGetProjectAssignmentProjects();
  const getTaskProjectIsPersonalOrUndefined =
    makeGetTaskProjectIsPersonalOrUndefined();
  const mapStateToProps = (state, ownProps) => ({
    taskProjectIsPersonal: getTaskProjectIsPersonalOrUndefined(state, ownProps),
    auth: getAuth(state),
    projectTaskGroups: getProjectTaskGroups(state),
    projectTaskGroupOrder: getProjectTaskGroupOrder(state),
    isOnTaskModal: state.taskDetail.taskDetailModalOpen,
    myProjects: getSafeProjectAssignmentProjects(state, ownProps),
    isLoading: getIsFetchingAllProjects(state),
    allProjects: getAllFetchedProjects(state),
    totalCount: getNumberOfAllProjects(state),
    fetchedProjectsCount: getAllFetchedProjectsLength(state)
  });

  return mapStateToProps;
};
const mapDispatchToProps = {
  fetchProjectTaskGroups,
  fetchAllProjects
};
export default connect(makeMapStateToProps, mapDispatchToProps)(ProjectMenu);
