import React from 'react';
import ProjectNotificationSettings from 'components/ProjectNotificationSettings';

const emptyObj = {};
const ProjectModalHeader = ({ project = emptyObj }) => {
  return (
    <header className="project-modal-header">
      <div className="project-notifications">
        <ProjectNotificationSettings project={project} onCommentModal />
      </div>
      <div className="title">{project.title}</div>
      <div className="description">{project.description}</div>
    </header>
  );
};

export default ProjectModalHeader;
