import * as constants from '../constants/region';
import { action } from 'appUtils';

export const fetchRegionsActionCreatorsMap = {
  request: (id, requestPayload) =>
    action(constants.FETCH_REGIONS.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_REGIONS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_REGIONS.FAILURE, {
      payload: { error },
      meta
    })
};

export const createRegionActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.CREATE_REGION.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.CREATE_REGION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_REGION.FAILURE, {
      payload: { error }
    })
};

export const updateRegionActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_REGION.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.UPDATE_REGION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_REGION.FAILURE, {
      payload: { error }
    })
};

export const deleteRegionActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.DELETE_REGION.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.DELETE_REGION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_REGION.FAILURE, {
      payload: { error }
    })
};

export const updateRegionEntitiesActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.UPDATE_REGION_ENTITIES.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.UPDATE_REGION_ENTITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_REGION_ENTITIES.FAILURE, {
      payload: { error }
    })
};
