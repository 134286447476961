import { useCallback } from 'react';
import {
  getProjectIdsThatMatchTitleAndNumber,
  getRowValuesForEntityType
} from './utils';
import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';

// phase_name is required: for project that already exists, validate against project title and number
// otherwise, just non-empty is valid
// Note: schema hasn't been changed yet, so if user does not touch this column, validation won't run.
// Therefore tied the non-empty validation to the project cell in useProjectTitleValidator as well
export const usePhaseValidator = ({
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  existingPhasesGroupedByName,
  existingPhasesGroupedByProjectId,
  newPhaseNames,
  entityType
}) => {
  return useCallback(
    (rows) =>
      validateRows(
        rows,
        existingProjectsHashByTitle,
        existingProjectsHashByNumber,
        existingPhasesGroupedByName,
        existingPhasesGroupedByProjectId,
        newPhaseNames,
        entityType
      ),
    [
      existingPhasesGroupedByName,
      existingPhasesGroupedByProjectId,
      existingProjectsHashByTitle,
      existingProjectsHashByNumber,
      newPhaseNames,
      entityType
    ]
  );
};

/* ------------------------------------ - ----------------------------------- */

export const validateRows = (
  rows,
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  existingPhasesGroupedByName,
  existingPhasesGroupedByProjectId,
  newPhaseNames,
  entityType
) => {
  const rowValidationResults = [];
  let nextNewPhaseNames = {};

  const pushResult = (result) => rowValidationResults.push(result);

  rows.forEach((row) => {
    const {
      enteredProjectTitle,
      enteredProjectNumber,
      enteredPhaseNumber,
      enteredPhaseName
    } = getRowValuesForEntityType(row, entityType);
    const matchingPhases = existingPhasesGroupedByName[enteredPhaseName];

    // Can only validate phase if we know the project title and number
    if (!(enteredProjectTitle || enteredProjectNumber)) {
      pushResult('Project title and number are required');
      return;
    }
    if (!enteredProjectTitle) {
      pushResult('Project title is required');
      return;
    }
    if (!enteredProjectNumber) {
      pushResult('Project number is required');
      return;
    }
    if (enteredPhaseName === TRAINING_DATA_REQUIRED_STRING) {
      pushResult(false);
      return;
    }

    const projectIdsThatMatchTitleAndNumber =
      getProjectIdsThatMatchTitleAndNumber({
        existingProjectsHashByTitle,
        existingProjectsHashByNumber,
        enteredProjectTitle,
        enteredProjectNumber
      });

    const rowHasMatchingProjects =
      Object.keys(projectIdsThatMatchTitleAndNumber).length > 0;

    const nextNewPhaseNamesMatchedByEnteredProjectTitleAndNumber =
      nextNewPhaseNames[enteredProjectTitle]?.[enteredProjectNumber];
    const newPhaseNamesMatchedByEnteredProjectTitleAndNumber =
      newPhaseNames.current[enteredProjectTitle]?.[enteredProjectNumber];
    const nextNewPhaseNamesMatchedByEnteredProjectTitleAndNumberWithNonEmptyEntry =
      nextNewPhaseNamesMatchedByEnteredProjectTitleAndNumber &&
      Object.keys(nextNewPhaseNamesMatchedByEnteredProjectTitleAndNumber)?.some(
        (phaseName) => phaseName !== ''
      );

    const newPhaseNamesMatchedByEnteredProjectTitleAndNumberWithNonEmptyEntry =
      newPhaseNamesMatchedByEnteredProjectTitleAndNumber &&
      Object.keys(newPhaseNamesMatchedByEnteredProjectTitleAndNumber)?.some(
        (phaseName) => phaseName !== ''
      );

    // User has entered new project title + number combination
    if (!rowHasMatchingProjects) {
      // if entered phase name is empty, then check both hashes to see if there are non empty keys
      // if exists non empty keys, then it is invalid
      // otherwise, it is valid
      if (!enteredPhaseName) {
        // there is non empty keys, then it is invalid
        if (
          nextNewPhaseNamesMatchedByEnteredProjectTitleAndNumberWithNonEmptyEntry ||
          newPhaseNamesMatchedByEnteredProjectTitleAndNumberWithNonEmptyEntry
        ) {
          pushResult(ERROR_MESSAGES.phaseRequiredForRow);
          return;
        } else {
          pushResult(true);
          return;
        }
      } else {
        nextNewPhaseNames = {
          ...nextNewPhaseNames,
          [enteredProjectTitle]: {
            ...nextNewPhaseNames[enteredProjectTitle],
            [enteredProjectNumber]: {
              ...nextNewPhaseNames[enteredProjectTitle]?.[enteredProjectNumber],
              [enteredPhaseName]: true
            }
          }
        };
        pushResult(true);
        return;
      }
    }

    // User has entered existing project title + number combination
    // if there is at least one matched project that has a phase that is not default, then empty phase name is invalid
    const matchedProjectsHasAtLeastOnePhase = Object.keys(
      projectIdsThatMatchTitleAndNumber
    ).some(
      (projectId) =>
        existingPhasesGroupedByProjectId[projectId] &&
        existingPhasesGroupedByProjectId[projectId].some(
          (phase) => !phase.is_default
        )
    );

    if (matchedProjectsHasAtLeastOnePhase && !enteredPhaseName) {
      pushResult('Phase name is required');
      return;
    }

    // phase number is entered and none of the matching phases have that number
    if (
      enteredPhaseNumber &&
      !matchingPhases?.some(
        (phase) => phase.phase_number === enteredPhaseNumber
      )
    ) {
      pushResult('Phase number does not match');
      return;
    }

    // add entered phase name as an option for this project title + number combination
    if (!matchedProjectsHasAtLeastOnePhase) {
      // if there is no phase for matched project, but there is a stored phase name for this project in one of the hash, and if enteredPhaseName is empty => it is invalid
      if (
        (nextNewPhaseNamesMatchedByEnteredProjectTitleAndNumberWithNonEmptyEntry ||
          newPhaseNamesMatchedByEnteredProjectTitleAndNumberWithNonEmptyEntry) &&
        !enteredPhaseName
      ) {
        pushResult(ERROR_MESSAGES.phaseRequiredForRow);
        return;
      }
    }
    if (enteredPhaseName)
      nextNewPhaseNames = {
        ...nextNewPhaseNames,
        [enteredProjectTitle]: {
          ...nextNewPhaseNames[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...nextNewPhaseNames[enteredProjectTitle]?.[enteredProjectNumber],
            [enteredPhaseName]: true
          }
        }
      };
    pushResult(true);
  });
  newPhaseNames.current = nextNewPhaseNames;
  return rowValidationResults;
};
