import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import OrganizationSettingsDropdown from '../shared/OrganizationSettingsDropdown';
import { TeamCurrencyDropdown } from 'CurrencyModule/components/TeamCurrencyDropdown';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getTeamCurrencyCodeByTeamId } from 'CurrencyModule/selectors';
import { getSelectedCurrencyString } from 'CurrencyModule/utils';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';

const OrganizationCurrencyDropdown = ({ teamId }: { teamId: number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currencyDropdownRef = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchEntityOptions({
        team_id: teamId,
        entity_type: ENTITY_TYPES.project,
        entity_id: null
      })
    );
  }, [dispatch, teamId]);

  const currencyCode = useAppSelector(getTeamCurrencyCodeByTeamId) ?? '';

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <OrganizationCurrencyDropdownContainer
      ref={currencyDropdownRef}
      onClick={handleClick}
    >
      <OrganizationSettingsDropdown
        label={getSelectedCurrencyString({ currencyCode })}
      />
      <TeamCurrencyDropdown
        isOpen={isOpen}
        target={currencyDropdownRef?.current}
        onClose={() => setIsOpen(false)}
        currencyDropdownProps={{ teamId }}
      />
    </OrganizationCurrencyDropdownContainer>
  );
};

const OrganizationCurrencyDropdownContainer = styled.div``;

export default OrganizationCurrencyDropdown;
