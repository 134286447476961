import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector } from 'react-redux';
import {
  getAllTeamMembersWithArchived,
  getNumberOfAllProjects,
  getClientsOrder
} from 'selectors';
import { getCurrentPositionIds } from 'BudgetModule/selectors/positions';
import capitalize from 'lodash/capitalize';

const StyledPill = styled.div`
  padding: 6px;
  background: ${theme.colors.colorMediumGray11};
  color: ${theme.colors.colorMediumGray9};
  font-size: 11px;
  border-radius: 5px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
  cursor: default; // on team dashboard widget detail modal, this shows as text type
`;

const ReportFilteredTotalPill = ({
  value,
  total,
  style,
  dataType,
  showOnlyTotal,
  customTooltip,
  tooltipClassName
}) => {
  const teamMembers = useSelector(getAllTeamMembersWithArchived);
  const totalProjectCount = useSelector(getNumberOfAllProjects);
  const positions = useSelector(getCurrentPositionIds);
  const clients = useSelector(getClientsOrder);

  const displayedTotal =
    total !== undefined
      ? +total
      : dataType === 'members'
      ? '' + teamMembers.length
      : dataType === 'projects'
      ? '' + totalProjectCount
      : dataType === 'positions'
      ? '' + positions.length
      : dataType === 'clients'
      ? '' + clients.length
      : '0';

  return (
    <StyledPill
      className="report-filter-pill"
      style={style}
      data-for={!showOnlyTotal && 'app-tooltip'}
      data-tip={
        customTooltip ||
        (showOnlyTotal
          ? `Showing ${displayedTotal} of <br/> ${displayedTotal} ${capitalize(
              dataType
            )}`
          : `Filtered showing ${value} <br/> of ${displayedTotal} ${capitalize(
              dataType
            )}`)
      }
      data-class={tooltipClassName}
      data-delay-show="700"
      data-effect="solid"
      data-html
    >
      {showOnlyTotal ? displayedTotal : `${+value}/${displayedTotal}`}
    </StyledPill>
  );
};

export default React.memo(ReportFilteredTotalPill);
