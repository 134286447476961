import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { mosaicMembershipConfigs } from './MosaicMembershipConfigs';
import { MosaicMembershipActionHash, MosaicMembershipActions } from './types';
import { ValueOf } from 'type-fest';
interface CellProps {
  [name: string]: {
    value: string;
    subValue?: string;
  };
}

const memberValueMap: Record<EMPLOYMENT_TYPES.member, CellProps> = {
  [EMPLOYMENT_TYPES.member]: {
    cost: {
      value: 'Standard Rates'
    },
    integration: {
      value: 'check'
    },
    portfolios: {
      value: 'check'
    },
    projects: {
      value: 'check'
    },
    timesheets: {
      value: 'check'
    },
    member_profile: {
      value: 'check'
    },
    team_builder: {
      value: 'check'
    },
    org_charts: {
      value: 'check'
    },
    workload: {
      value: 'check'
    },
    planner: {
      value: 'check'
    },
    project_budget: {
      value: 'check'
    },
    reports: {
      value: 'check'
    },
    dashboard: {
      value: 'check'
    },
    role: {
      value: 'check'
    },
    billing: {
      value: 'check'
    },
    holiday: {
      value: 'check'
    },
    pto_tracking: {
      value: 'check'
    },
    work_schedule: {
      value: 'check'
    },
    department: {
      value: 'check'
    },
    skills: {
      value: 'check'
    },
    location: {
      value: 'check'
    },
    region: {
      value: 'check'
    },
    office: {
      value: 'check'
    },
    discipline: {
      value: 'check'
    }
  }
};

const internalContractorValueMap: Record<
  EMPLOYMENT_TYPES.internalContractor,
  CellProps
> = {
  [EMPLOYMENT_TYPES.internalContractor]: {
    cost: {
      value: 'Standard Rates'
    },
    integration: {
      value: 'check'
    },
    portfolios: {
      value: 'check'
    },
    projects: {
      value: 'check'
    },
    timesheets: {
      value: 'check'
    },
    member_profile: {
      value: 'check'
    },
    team_builder: {
      value: 'check'
    },
    org_charts: {
      value: 'check'
    },
    workload: {
      value: 'check'
    },
    planner: {
      value: 'check'
    },
    project_budget: {
      value: 'check'
    },
    reports: {
      value: 'check'
    },
    dashboard: {
      value: 'check'
    },
    role: {
      value: 'check'
    },
    billing: {
      value: 'check'
    },
    holiday: {
      value: 'check'
    },
    pto_tracking: {
      value: 'check'
    },
    work_schedule: {
      value: 'check'
    },
    department: {
      value: 'check'
    },
    skills: {
      value: 'check'
    },
    location: {
      value: 'check'
    },
    region: {
      value: 'check'
    },
    office: {
      value: 'check'
    },
    discipline: {
      value: 'check'
    }
  }
};

const externalContractorValueMap: Record<
  EMPLOYMENT_TYPES.externalProjectContractor,
  CellProps
> = {
  [EMPLOYMENT_TYPES.externalProjectContractor]: {
    cost: {
      value: '$1.99/user/month'
    },
    integration: {
      value: 'dash'
    },
    portfolios: {
      value: 'dash'
    },
    projects: {
      value: 'check',
      subValue: 'Individual Projects'
    },
    timesheets: {
      value: 'check'
    },
    member_profile: {
      value: 'check'
    },
    team_builder: {
      value: 'check'
    },
    org_charts: {
      value: 'check'
    },
    workload: {
      value: 'dash'
    },
    planner: {
      value: 'dash'
    },
    project_budget: {
      value: 'dash'
    },
    reports: {
      value: 'dash'
    },
    dashboard: {
      value: 'dash'
    },
    role: {
      value: 'check'
    },
    billing: {
      value: 'check'
    },
    holiday: {
      value: 'dash'
    },
    pto_tracking: {
      value: 'dash'
    },
    work_schedule: {
      value: 'check'
    },
    department: {
      value: 'check'
    },
    skills: {
      value: 'check'
    },
    location: {
      value: 'check'
    },
    region: {
      value: 'check'
    },
    office: {
      value: 'check'
    },
    discipline: {
      value: 'check'
    }
  }
};

const projectGuestValueMap: Record<EMPLOYMENT_TYPES.projectGuest, CellProps> = {
  [EMPLOYMENT_TYPES.projectGuest]: {
    cost: {
      value: '$1.99/user/month'
    },
    integration: {
      value: 'dash'
    },
    portfolios: {
      value: 'dash'
    },
    projects: {
      value: 'dash',
      subValue: 'Project Invite Only'
    },
    timesheets: {
      value: 'dash'
    },
    member_profile: {
      value: 'dash'
    },
    team_builder: {
      value: 'dash'
    },
    org_charts: {
      value: 'dash'
    },
    workload: {
      value: 'dash'
    },
    planner: {
      value: 'dash'
    },
    project_budget: {
      value: 'dash'
    },
    reports: {
      value: 'dash'
    },
    dashboard: {
      value: 'dash'
    },
    role: {
      value: 'dash'
    },
    billing: {
      value: 'dash'
    },
    holiday: {
      value: 'dash'
    },
    pto_tracking: {
      value: 'dash'
    },
    work_schedule: {
      value: 'dash'
    },
    department: {
      value: 'dash'
    },
    skills: {
      value: 'dash'
    },
    location: {
      value: 'dash'
    },
    region: {
      value: 'dash'
    },
    office: {
      value: 'dash'
    },
    discipline: {
      value: 'dash'
    }
  }
};

export const valueMap = {
  ...memberValueMap,
  ...internalContractorValueMap,
  ...externalContractorValueMap,
  ...projectGuestValueMap
} as const;

export const headerNamesHash = {
  [EMPLOYMENT_TYPES.member]: 'Standard Member',
  [EMPLOYMENT_TYPES.internalContractor]: 'Internal Contractor',
  [EMPLOYMENT_TYPES.externalProjectContractor]: 'Project Contractor',
  [EMPLOYMENT_TYPES.projectGuest]: 'Project Guest'
} as const;

export const headerInfoHash = {
  [EMPLOYMENT_TYPES.member]: [
    'Standard organization members with all Access options available in Permissions Settings.'
  ],
  [EMPLOYMENT_TYPES.internalContractor]: [
    'Same Access options as Members, but cannot be Administrators.',
    'Access to Organization spaces including Portfolios, Workload, Planner or Org is covered by permissions. ',
    'Work Plans can be created. '
  ],
  [EMPLOYMENT_TYPES.externalProjectContractor]: [
    'Access to individual projects, but not Organization spaces including Portfolios, Workload, Planner or Org.',
    'Show on “Add Member” lists to easily add to projects.  ',
    'Work Plans can be created.'
  ],
  [EMPLOYMENT_TYPES.projectGuest]: [
    'Do not show on lists to add to projects and must be added to each project individually. ',
    'Do not get access to Organization spaces including Portfolios, Workload, Planner or Org. and cannot utilize Timesheet functions on Mosaic.',
    'Work Plans cannot be created.'
  ]
} as const;

export const MOSAIC_MEMBERSHIP_SECTION = {
  ACCESS: 'access',
  MEMBERS: 'members'
} as const;

export const mosaicMembershipActionsHash: Partial<MosaicMembershipActionHash> =
  {
    ...mosaicMembershipConfigs.accessPermissions,
    ...mosaicMembershipConfigs.memberPermissions
  } as const;

export const sectionMosaicMembershipIdentifierHash: Record<
  ValueOf<typeof MOSAIC_MEMBERSHIP_SECTION>,
  MosaicMembershipActions[]
> = {
  [MOSAIC_MEMBERSHIP_SECTION.ACCESS]: Object.keys(
    mosaicMembershipConfigs.accessPermissions
  ) as Array<keyof typeof mosaicMembershipConfigs.accessPermissions>,
  [MOSAIC_MEMBERSHIP_SECTION.MEMBERS]: Object.keys(
    mosaicMembershipConfigs.memberPermissions
  ) as Array<keyof typeof mosaicMembershipConfigs.memberPermissions>
};
