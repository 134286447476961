import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import CellContainer from './CellContainer';
import { getProjectTaskGroups } from 'selectors';
// import TaskGroup from '../TaskGroup'
import { makeNoop } from 'appUtils/noop';
import ProjectSelectionPopover from 'components/ProjectSelectionPopover';
const TaskGroupCell = ({ row }) => {
  const target = useRef(null);
  const taskGroups = useSelector(getProjectTaskGroups);

  const {
    task_priority_id,
    id,
    handlePropertySelect = makeNoop,
    taskProps = {}
  } = row.original;
  const { taskIsEditing, isNewTask } = taskProps;

  const taskGroupTriggerRef = useRef(null);

  const handleTaskGroupSelect = (projectId, taskGroupId) => {
    handlePropertySelect({
      task_group_id: taskGroupId,
      project_id: projectId
    })();
  };
  const taskGroup = taskGroups[row.original.task_group_id];

  return (
    <CellContainer
      taskProps={taskProps}
      target={target}
      taskProperty="phase_id"
      handleClick={() => row.setState({ editingProperty: 'task_group_id' })}
      row={row}
      innerRef={taskGroupTriggerRef}
    >
      <ProjectSelectionPopover
        target={target}
        disabled //= {!showProjectSelector}
        task={row.original}
        onSelect={handleTaskGroupSelect}
        header={null}
        headerText={'Select Project'}
        showDeselect={false}
        // popupAlign={popupAlign}
        // isSelectedHomeTask={isSelectedHomeTask}
        taskGroupTriggerRef={taskGroupTriggerRef}
        // project={{}}
        // taskGroup={{}}
      >
        {() => <div>{taskGroup?.name ?? ''}</div>}
      </ProjectSelectionPopover>
    </CellContainer>
  );
};
export default TaskGroupCell;
