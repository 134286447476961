import styled from 'styled-components';
import theme from 'theme';
import QBDownArrow from 'icons/QBDownArrow';
import TaskListIcon from 'icons/TaskListIcon';

export const StyledFilterOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* padding: 0 10px 0 2px; */
  font-size: 13px;
  color: ${theme.colors.colorRoyalBlue};
  height: 100%;
  cursor: pointer;
  text-transform: capitalize;
`;

export const Arrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;

export const dropdownButtonStyle = `
height: 100%;
& > button {
  padding: 0;
}
`;
export const dropdownSelectedStyle = {
  fontWeight: 600
};

export const ListIcon = styled(TaskListIcon)`
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;
