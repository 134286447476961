import { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import OptionsDropdown from 'components/options/OptionsDropdown';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import ImportIcon from 'images/import-icon.svg';
import { ImportModal } from 'CsvImportsModule/components/ImportModal';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import { IMPORT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useWorkplanPermission } from 'PermissionsModule/SpaceLevelPermissions/permissionProviders/workplan';

export const WorkloadThreeDotMenu = () => {
  const [isWorkPlanImportModalOpen, setIsWorkPlanImportModalOpen] =
    useState(false);

  const { getCanImportWorkPlan } = useWorkplanPermission();

  const canImportWorkPlan = getCanImportWorkPlan();

  const handleSelect = (option: string) => {
    if (option === options.IMPORT) {
      setIsWorkPlanImportModalOpen(true);
    }
  };

  const optionsArrayWithProps = useMemo(
    () =>
      optionsArray.map((option) => ({
        ...option,
        disabled: !canImportWorkPlan,
        tooltip: !canImportWorkPlan ? IMPORT_WORK_PLANS_TIP : undefined
      })),
    [canImportWorkPlan]
  );

  return (
    <StyleWrapper>
      <OptionsDropdown
        currentValue={undefined}
        onSelect={handleSelect}
        options={optionsArrayWithProps}
        optionsHash={undefined}
        renderToggle={(isOpen: boolean) => (
          <ThreeDots>
            <ThreeDotHorizontal
              active={isOpen}
              className="three-dot"
              color={theme.colors.colorCalendarBlue}
              size={13}
              noPadding
            />
            &#x200b;
          </ThreeDots>
        )}
        headerText=""
        disableDropdown={false}
        disableToggle={false}
      />

      {/* Work plan CSV import modal */}
      {isWorkPlanImportModalOpen && (
        <ImportModal
          handleClose={() => setIsWorkPlanImportModalOpen(false)}
          entityType={CsvImportEntity.WorkPlan}
        />
      )}
    </StyleWrapper>
  );
};

/* ------------------------------------ - ----------------------------------- */

const ThreeDots = styled.div`
  display: flex;
  align-items: center;
`;

const StyleWrapper = styled.div`
  .three-dot {
    stroke-width: 0;
  }
  .options-dropdown-toggle {
    display: block;
    font-size: inherit;
    line-height: inherit;
  }
  .options-dropdown-option {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
`;

const options = {
  IMPORT: 'IMPORT'
};

const optionsArray = [
  {
    value: options.IMPORT,
    label: (
      <>
        <img
          src={ImportIcon}
          width={21}
          style={{
            filter: 'grayscale(93%) brightness(0.8)',
            transform: 'rotate(-90deg) translateX(2px)'
          }}
        />
        <span>Import Work Plans</span>
      </>
    )
  }
];
