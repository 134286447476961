import { useCallback } from 'react';
import { serializeId } from 'appUtils';
import groupBy from 'lodash/groupBy';
import { defaultGroupRowHeight } from '../constants';
import { GroupData } from '../types';
import { ROW_TYPES } from './layout';
import { RowDataWithGroupId } from '../types';

export const useGroupRowBuilder = ({
  groupOrders,
  isCreatingGroup,
  getIsOpen,
  getIsLoading,
  toggleCollapse,
  groupRowProps,
  memberRowBuilder,
  footerRowBuilder,
  emptyRowBuilder,
  loadingRowBuilder,
  loadMoreRowBuilder,
  inlineCreateGroupRowBuilder
}) => {
  const groupRowBuilder = useCallback(
    ({
      groupData = [],
      rowData = []
    }: {
      groupData: Array<GroupData>;
      rowData: Array<RowDataWithGroupId<any>>;
    }) => {
      const groupedRowData = groupBy(rowData, (item) => item.groupId);

      const groupList = groupData.reduce((acc, dataItem) => {
        const serializedId = serializeId({
          itemType: 'group',
          id: dataItem.id,
          ids: undefined,
          delimiter: undefined
        });

        const isListOpen = getIsOpen(serializedId);

        const listItems = [
          ...memberRowBuilder({
            rowData: groupedRowData[dataItem.id] || []
          })
        ];

        const lastItemIndex = Math.max(
          (groupedRowData[dataItem.id]?.length ?? 0) - 1,
          0
        );

        if (getIsLoading(dataItem.id)) {
          listItems.push(loadingRowBuilder({ groupData: dataItem }));
        }

        if (lastItemIndex) {
          listItems.push(
            loadMoreRowBuilder({
              groupData: dataItem,
              lastItemIndex
            })
          );
        }

        listItems.push(
          footerRowBuilder({
            groupDataItem: dataItem
          })
        );

        const baseRowHeight = isListOpen
          ? groupRowProps?.rowHeight?.expanded ?? defaultGroupRowHeight.expanded
          : groupRowProps?.rowHeight?.collapsed ??
            defaultGroupRowHeight.collapsed;

        const secondaryRowHeight =
          groupRowProps?.rowHeight?.secondaryRow ??
          defaultGroupRowHeight.secondaryRow;

        const isEditing = groupRowProps?.editingGroupId === dataItem.id;
        // need extra row height to expand some rowHeight since the input container has little bit taller than label
        const valueToAdd = isEditing ? 20 : 0;
        let rowHeight = baseRowHeight + valueToAdd;
        if (groupRowProps?.secondaryRowRenderer) {
          rowHeight += secondaryRowHeight;
        }

        acc[dataItem.id] = {
          id: serializedId,
          rowType: ROW_TYPES.groupRow,
          rowHeight,
          skipHeader: false,
          hasSubLists: true,
          isOpen: isListOpen,
          headerData: dataItem,
          listItems,
          groupRowMenus: groupRowProps?.groupRowMenus,
          onGroupRowAddClick: groupRowProps?.onGroupRowAddClick,
          onEditGroupInline: groupRowProps?.onEditGroupInline,
          secondaryRowHeight,
          secondaryRowRenderer: groupRowProps?.secondaryRowRenderer,
          isEditing,
          toggleCollapse: () => {
            toggleCollapse(serializedId);
          },
          sectionId: serializedId
        };
        return acc;
      }, {});

      const list: Array<any> = [];

      if (isCreatingGroup) {
        list.push(inlineCreateGroupRowBuilder());
      }

      groupOrders.forEach((id) => {
        if (groupList[id]) {
          list.push(groupList[id]);
          list.push(emptyRowBuilder(id));
        }
      });
      list.push(emptyRowBuilder());

      return list;
    },
    [
      groupOrders,
      isCreatingGroup,
      getIsOpen,
      getIsLoading,
      toggleCollapse,
      groupRowProps,
      memberRowBuilder,
      footerRowBuilder,
      emptyRowBuilder,
      loadingRowBuilder,
      loadMoreRowBuilder,
      inlineCreateGroupRowBuilder
    ]
  );
  return groupRowBuilder;
};
