import React from 'react';
import SvgIcon from 'components/SvgIcon';

const LinkIcon2 = ({
  className,
  color = '#2F80ED',
  fill = 'none',
  width = 13,
  height = 14,
  style
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 13 14"
    style={style}
  >
    <mask id="path-1-inside-1" fill="white">
      <path d="M8.99083 5.69992C8.82886 5.33664 8.60286 4.99442 8.30454 4.69703C8.00621 4.39965 7.66491 4.17271 7.30165 4.01074L6.85144 4.46095L6.5385 4.77298L6.21366 5.09782C6.15327 5.15821 6.10202 5.22318 6.05718 5.29181C6.48816 5.32017 6.8908 5.49404 7.19917 5.8015C7.51579 6.11811 7.68049 6.52623 7.70795 6.94074C7.7235 7.17134 7.69513 7.40194 7.62102 7.62338C7.53866 7.86953 7.40689 8.10196 7.21473 8.2996C7.20832 8.30509 7.20558 8.31241 7.20009 8.31882L6.83132 8.68666L4.60043 10.9176C4.26369 11.2543 3.81714 11.4391 3.34132 11.4391C2.86642 11.4391 2.41895 11.2534 2.08221 10.9176C1.74638 10.5817 1.56153 10.1343 1.56153 9.65936C1.56153 9.18354 1.74637 8.73607 2.08312 8.40024L3.32759 7.15486C3.25622 6.93433 3.20589 6.70832 3.17387 6.48046C3.15099 6.31027 3.13452 6.13916 3.13452 5.96804C3.13177 5.65325 3.17112 5.33848 3.24432 5.03011L0.978647 7.29578C-0.326216 8.60065 -0.326216 10.7171 0.978647 12.0221C1.63109 12.6745 2.48665 13.0012 3.34133 13.0012C4.19689 13.0012 5.05248 12.6745 5.70495 12.0221L8.30469 9.42234C8.49777 9.22926 8.65881 9.01696 8.79424 8.79369C9.00744 8.44323 9.15292 8.06439 9.22522 7.67274C9.26273 7.47143 9.28286 7.26737 9.28286 7.06331C9.28378 6.81442 9.25267 6.56552 9.19776 6.32028C9.14927 6.10891 9.08064 5.90118 8.99096 5.69989L8.99083 5.69992Z" />
    </mask>
    <path
      d="M8.99083 5.69992C8.82886 5.33664 8.60286 4.99442 8.30454 4.69703C8.00621 4.39965 7.66491 4.17271 7.30165 4.01074L6.85144 4.46095L6.5385 4.77298L6.21366 5.09782C6.15327 5.15821 6.10202 5.22318 6.05718 5.29181C6.48816 5.32017 6.8908 5.49404 7.19917 5.8015C7.51579 6.11811 7.68049 6.52623 7.70795 6.94074C7.7235 7.17134 7.69513 7.40194 7.62102 7.62338C7.53866 7.86953 7.40689 8.10196 7.21473 8.2996C7.20832 8.30509 7.20558 8.31241 7.20009 8.31882L6.83132 8.68666L4.60043 10.9176C4.26369 11.2543 3.81714 11.4391 3.34132 11.4391C2.86642 11.4391 2.41895 11.2534 2.08221 10.9176C1.74638 10.5817 1.56153 10.1343 1.56153 9.65936C1.56153 9.18354 1.74637 8.73607 2.08312 8.40024L3.32759 7.15486C3.25622 6.93433 3.20589 6.70832 3.17387 6.48046C3.15099 6.31027 3.13452 6.13916 3.13452 5.96804C3.13177 5.65325 3.17112 5.33848 3.24432 5.03011L0.978647 7.29578C-0.326216 8.60065 -0.326216 10.7171 0.978647 12.0221C1.63109 12.6745 2.48665 13.0012 3.34133 13.0012C4.19689 13.0012 5.05248 12.6745 5.70495 12.0221L8.30469 9.42234C8.49777 9.22926 8.65881 9.01696 8.79424 8.79369C9.00744 8.44323 9.15292 8.06439 9.22522 7.67274C9.26273 7.47143 9.28286 7.26737 9.28286 7.06331C9.28378 6.81442 9.25267 6.56552 9.19776 6.32028C9.14927 6.10891 9.08064 5.90118 8.99096 5.69989L8.99083 5.69992Z"
      fill={color}
      stroke="white"
      strokeWidth="0.4"
      mask="url(#path-1-inside-1)"
    />
    <mask id="path-2-inside-2" fill="white">
      <path d="M12.0213 0.97911C11.3689 0.326667 10.5133 0 9.65863 0C8.80307 0 7.94748 0.326667 7.29502 0.97911L4.69621 3.57792C4.50313 3.771 4.34209 3.9833 4.20667 4.20656C3.99346 4.55703 3.84798 4.93587 3.77475 5.32752C3.73723 5.52883 3.7171 5.73289 3.7171 5.93695C3.7171 6.18584 3.7473 6.43474 3.8022 6.67998C3.84978 6.89227 3.91841 7.09999 4.009 7.30129C4.17097 7.66457 4.39697 8.00588 4.69529 8.30418C4.99359 8.60248 5.33492 8.82851 5.69818 8.99047L6.14748 8.54117L6.46042 8.22915L6.78434 7.90522C6.84474 7.84483 6.89598 7.77986 6.94082 7.71123C6.50984 7.68286 6.1072 7.50992 5.79883 7.20154C5.4813 6.88492 5.3166 6.47589 5.28914 6.05955C5.2745 5.82987 5.30287 5.6002 5.37699 5.37876C5.45843 5.13261 5.59111 4.90018 5.78328 4.70254C5.78877 4.69705 5.79243 4.68973 5.79883 4.68333L8.39858 2.08358C8.73532 1.74684 9.18187 1.56199 9.65769 1.56199C10.1335 1.56199 10.5801 1.74775 10.9159 2.08358C11.6104 2.7781 11.6104 3.90729 10.9168 4.60087L9.67142 5.84626C9.74279 6.0677 9.79312 6.29279 9.82515 6.52156C9.84894 6.69176 9.86449 6.86287 9.86449 7.03399C9.86724 7.34878 9.82789 7.66355 9.75469 7.97192L12.0213 5.70624C13.3261 4.40047 13.3261 2.28403 12.0213 0.979004L12.0213 0.97911Z" />
    </mask>
    <path
      d="M12.0213 0.97911C11.3689 0.326667 10.5133 0 9.65863 0C8.80307 0 7.94748 0.326667 7.29502 0.97911L4.69621 3.57792C4.50313 3.771 4.34209 3.9833 4.20667 4.20656C3.99346 4.55703 3.84798 4.93587 3.77475 5.32752C3.73723 5.52883 3.7171 5.73289 3.7171 5.93695C3.7171 6.18584 3.7473 6.43474 3.8022 6.67998C3.84978 6.89227 3.91841 7.09999 4.009 7.30129C4.17097 7.66457 4.39697 8.00588 4.69529 8.30418C4.99359 8.60248 5.33492 8.82851 5.69818 8.99047L6.14748 8.54117L6.46042 8.22915L6.78434 7.90522C6.84474 7.84483 6.89598 7.77986 6.94082 7.71123C6.50984 7.68286 6.1072 7.50992 5.79883 7.20154C5.4813 6.88492 5.3166 6.47589 5.28914 6.05955C5.2745 5.82987 5.30287 5.6002 5.37699 5.37876C5.45843 5.13261 5.59111 4.90018 5.78328 4.70254C5.78877 4.69705 5.79243 4.68973 5.79883 4.68333L8.39858 2.08358C8.73532 1.74684 9.18187 1.56199 9.65769 1.56199C10.1335 1.56199 10.5801 1.74775 10.9159 2.08358C11.6104 2.7781 11.6104 3.90729 10.9168 4.60087L9.67142 5.84626C9.74279 6.0677 9.79312 6.29279 9.82515 6.52156C9.84894 6.69176 9.86449 6.86287 9.86449 7.03399C9.86724 7.34878 9.82789 7.66355 9.75469 7.97192L12.0213 5.70624C13.3261 4.40047 13.3261 2.28403 12.0213 0.979004L12.0213 0.97911Z"
      fill={color}
      stroke="white"
      strokeWidth="0.4"
      mask="url(#path-2-inside-2)"
    />
  </SvgIcon>
);

export default LinkIcon2;
