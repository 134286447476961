import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { EmploymentTypes } from 'PermissionsModule/types';

const employmentTypeDescriptions: Partial<Record<EmploymentTypes, string>> = {
  [EMPLOYMENT_TYPES.internalContractor]:
    'Internal Contractors have permissions and roles exactly like standard members, but cannot be Administators. Mosaic allow filtering by this Membership, since they are typically not on an Organization’s payroll, and the hourly resource cost may be higher.',
  [EMPLOYMENT_TYPES.externalProjectContractor]:
    'External Project Contractors can only access projects they are a member of. They do not have access to the Organization spaces like Portfolios, Workload. External Project Contractors can enter their time on Timesheeets (Project Guests Cannot), and they appear on lists to add to projects.',
  [EMPLOYMENT_TYPES.projectGuest]:
    'Project Guests must be added to projects individually and only have access to projects they are a member of. They do not have any access to the Organization spaces and unlike External Project Contractors, they cannot enter time on Timesheets, and will not appear on the Org Chart.'
};

export const permissionsModuleConstants = {
  employmentTypeDescriptions
};
