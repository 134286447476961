import React from 'react';
import { connect } from 'react-redux';
import keyBy from 'lodash/keyBy';
import { ArchivedText } from './styles';
import {
  makeGetProjectMembershipsByAccountId,
  getMyUserId,
  getOrderedAllActivities,
  getOrderedAllActivitiesWithArchived
} from 'selectors';
import { addMemberToProject } from 'actionCreators';
import {
  StyledSelectActivity,
  ActivitySelectionContainer,
  StyledSelectActivityArrow,
  SelectedWorkCategoryContainer,
  SelectedWorkCategory,
  SmallIconContainer
} from './../styles';
import ActivitiesDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/ActivitiesDropdown';

import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';

import SmallDiamondIcon from 'icons/SmallDiamondIcon';

import { noop } from 'appUtils';
import { EDIT_TIMESHEET_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

/* TODO: Refactor Join Project Modal + success callback to not have to be rendered/handled in individual timesheet cells. See TimesheetDay, TimesheetDescription, TimesheetActivity, and TimesheetProject -> ProjectsAndPhasesDropdown */
class TimesheetActivity extends React.Component {
  state = {
    openModal: false,
    selectedProject: null,
    selectedItem: null,
    joinModalOpen: false
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isActivityDropdownOpen &&
      this.props.isActivityDropdownOpen &&
      !this.state.openModal
    ) {
      this.openModal();
    }
  }

  openModal = () => this.setState({ openModal: true });
  handleSelect = (item) => {
    const { handleSelect } = this.props;
    const { accountId, projectMembersByAccountId, project } = this.props;
    if (projectMembersByAccountId[accountId] || !accountId) {
      handleSelect(item);
    } else {
      this.setJoinProject(project, item);
    }
    this.handleClose();
  };

  setJoinProject = (project, item) => {
    this.setState({
      selectedProject: project,
      selectedItem: item,
      joinModalOpen: true
    });
  };

  joinProject = () => {
    const { addMemberToProject, accountId } = this.props;
    const { selectedProject, selectedItem } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: () => this.handleSelect(selectedItem),
        selector: () => {}
      }
    ];
    addMemberToProject(
      selectedProject.id,
      accountId,
      basicMemberRole,
      selectedProject.board_id,
      onSuccess
    );
    this.clearJoinProject();
  };

  clearJoinProject = () =>
    this.setState({
      selectedProject: null,
      selectedItem: null,
      joinModalOpen: false
    });

  handleClose = () => {
    this.setState({ openModal: false });
    this.props.onActivityDropdownClose?.();
  };

  handleOutsideClick = () => {
    if (this.props.isActivityDropdownOpen) {
      return;
    }
    this.handleClose();
  };

  renderHeader = () => (
    <div
      style={{
        fontSize: '22px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        bottom: '2px'
      }}
    >
      Select Work Category
    </div>
  );

  getActivities = () => {
    const { allActivities, allActivitiesWithArchived, phase } = this.props;
    if (!phase?.activity_order?.length) {
      // if no work categories configured on phase/budget, all selecting an active activity
      return allActivities;
    }

    // if work categories configured on phase/budget do not restrict to active work categories as the work category (activity_phase) on the budget may still be active.
    const activityIdsHash = keyBy(phase.activity_order);
    return allActivitiesWithArchived.filter(
      (activity) => activityIdsHash[activity.id]
    );
  };

  render() {
    const {
      activity,
      isRed,
      ContainerEl,
      accountId,
      myId,
      phase,
      canEditTimesheet
    } = this.props;
    const { joinModalOpen, selectedProject } = this.state;

    const disableActivitySelection =
      canEditTimesheet !== undefined && !canEditTimesheet;

    return (
      <ContainerEl
        ref={(ref) => (this.target = ref)}
        onClick={
          disableActivitySelection ? noop : phase ? this.openModal : null
        }
        style={{ cursor: phase ? 'pointer' : 'auto' }}
        isRed={isRed}
        data-for="app-tooltip"
        data-html
        data-effect="solid"
        data-offset="{'top': -20, 'left': 20}"
        data-tip={
          disableActivitySelection
            ? EDIT_TIMESHEET_TIP
            : phase
            ? ''
            : 'Please select a phase first'
        }
      >
        <ActivitySelectionContainer
          disableHoverEffect={!phase || disableActivitySelection}
        >
          {activity ? (
            <>
              <SelectedWorkCategoryContainer>
                <SmallIconContainer>
                  <SmallDiamondIcon />
                </SmallIconContainer>
                <SelectedWorkCategory>{activity.title}</SelectedWorkCategory>
              </SelectedWorkCategoryContainer>
              {activity.archived && <ArchivedText>Archived</ArchivedText>}
            </>
          ) : (
            <StyledSelectActivity show={this.state.openModal}>
              Select Work Category
            </StyledSelectActivity>
          )}
          <StyledSelectActivityArrow show={this.state.openModal} />
        </ActivitySelectionContainer>
        {this.state.openModal && (
          <ActivitiesDropdown
            target={this.target}
            handleSelect={this.handleSelect}
            handleClose={this.handleOutsideClick}
            renderHeader={this.renderHeader}
            listWidth={300}
            searchPlaceholder={'Type or select Work Category'}
            popoverClassName="activities-dropdown"
            placeholderSize={'12px'}
            parentActivities={this.getActivities()}
            phaseActivityOrder={phase?.activity_order}
          />
        )}
        <JoinProjectModal
          open={joinModalOpen}
          toggle={this.clearJoinProject}
          selectedProject={selectedProject}
          onConfirm={this.joinProject}
          text={'edit the description'}
          accountId={accountId}
          currentUserId={myId}
        />
      </ContainerEl>
    );
  }
}

const makeMapStateToProps = () => {
  const getProjectMembersByAccountId = makeGetProjectMembershipsByAccountId();
  const mapStateToProps = (state, ownProps) => ({
    projectMembersByAccountId: getProjectMembersByAccountId(state, ownProps),
    allActivities: getOrderedAllActivities(state),
    allActivitiesWithArchived: getOrderedAllActivitiesWithArchived(state),
    myId: getMyUserId(state)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  addMemberToProject
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TimesheetActivity);
