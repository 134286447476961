import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getAccountFiltersFetched } from 'selectors';

import styled from 'styled-components';
import DevProps from 'components/dev/DevProps';
import { FilterListsTable } from './FilterListsTable';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { useFilterData } from 'FilterModule/hooks';
import { FilterListType, FilterField } from 'FilterModule/constants';
import { isExistenceCheckOptionHash } from 'FilterModule/filterSchemas/utils';

export const FilterListContainer = () => {
  const { currentFilter, currentFilterSchema } = useFilterContext();
  const { mainFilterListType, mainFilterListType_local } = currentFilter;

  const mainFilterListTypeValueToUse =
    mainFilterListType_local || mainFilterListType;

  const getIsFilterUsed = (filterListType: FilterListType) => {
    return mainFilterListTypeValueToUse === filterListType;
  };

  const getFilterListTypeConfig = useCallback(
    (filterListType: FilterListType) => {
      const fieldToUse =
        mainFilterListTypeValueToUse === mainFilterListType_local
          ? FilterField.mainFilterListType_local
          : FilterField.mainFilterListType;

      const optionHash =
        currentFilterSchema.fields[fieldToUse]?.optionsConfig?.optionHash;

      if (optionHash && !isExistenceCheckOptionHash(optionHash)) {
        const filterListTypeConfig = optionHash[filterListType];
        return filterListTypeConfig;
      }
    },
    [
      currentFilterSchema.fields,
      mainFilterListTypeValueToUse,
      mainFilterListType_local
    ]
  );

  const { filterValuesHash } = useFilterData({
    getIsFilterUsed,
    getFilterListTypeConfig,
    isoStateIdPrefix: 'SideFilter',
    shouldUseDraft: true
  });

  /* --------------------------------- Render --------------------------------- */

  const filtersFetched = useAppSelector(getAccountFiltersFetched);

  if (!mainFilterListTypeValueToUse) return null;

  const isPageReady = filtersFetched;

  return (
    <DevProps currentFilter={currentFilter}>
      <StyledFilterListContainer>
        <FilterListsTable
          isLoading={!isPageReady}
          filters={mainFilterListTypeValueToUse}
          filterValuesHash={filterValuesHash}
          variant="SideFilter"
        />
      </StyledFilterListContainer>
    </DevProps>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledFilterListContainer = styled.div`
  min-height: 0;
  flex: 1;
`;
