import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { getSubcustomerModalOpen } from 'QuickbooksModule/selectors';
import { closeSubcustomerModal } from 'QuickbooksModule/actionCreators';
import SubcustomerModalBody from './SubcustomerModalBody';
import GlobalAddEditHeader from 'BudgetModule/components/PhaseTemplateModal/GlobalAddEditHeader';

class SubcustomerModal extends React.Component {
  state = {
    isRemove: false
  };
  handleClose = () => {
    const { closeSubcustomerModal } = this.props;
    closeSubcustomerModal({});
  };

  setRemove = () => this.setState({ isRemove: true });
  clearRemove = () => this.setState({ isRemove: false });

  render() {
    const { open, id } = this.props;
    const { isRemove } = this.state;
    return (
      <Modal
        isOpen={open}
        toggle={this.handleClose}
        className="phase-modal"
        zIndex="2001"
      >
        <GlobalAddEditHeader
          handleClose={this.handleClose}
          isRemove={isRemove}
          copy="Sub Customer"
          id={id}
        />
        <SubcustomerModalBody />
      </Modal>
    );
  }
}

SubcustomerModal.propTypes = {
  open: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  open: getSubcustomerModalOpen(state)
});

const mapDispatchToProps = {
  closeSubcustomerModal
};

export default connect(mapStateToProps, mapDispatchToProps)(SubcustomerModal);
