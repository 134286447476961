import { reducerMap } from 'CsvImportsModule/reducers';
import csvImportSaga from 'CsvImportsModule/sagas';

export function getCsvImportsModule() {
  return {
    id: 'csvImports',
    reducerMap,
    sagas: [csvImportSaga]
  };
}
