import * as appConstants from 'appConstants';
import * as constants from '../constants';

export const initialState = {
  sorting: {}
};

const sort = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.QB_SORT_CHANGE: {
      const { section, sortColumn, order } = payload;
      return {
        ...state,
        sorting: {
          ...state.sorting,
          [section]: {
            ...state.sorting[section],
            sortColumn,
            order
          }
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default sort;
