import { UpdateWorkPlanTasksParams } from '../actionCreators/workPlanTaskAssociationActionCreators';
import { callApi } from 'appUtils/api';

/**
 * The `updateWorkPlanTasksApiRequest` endpoint will always return a success
 * status code, unless some unexpected error occus, since the success and
 * failure results are determined per update item. The success results are found
 * in `updates` and the failures in `errors`.
 */
export const updateWorkPlanTasksApiRequest = (
  token: string,
  params: UpdateWorkPlanTasksParams
) => {
  const fetchInit = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  return callApi(
    'activity_phase_schedule_bar_task_relationships',
    token,
    fetchInit
  );
};
