import * as constants from 'appConstants';
import produce from 'immer';
import omit from 'lodash/omit';

/**
 * triggerIdsByActionType: {
 *    CREATE_COMMENTS_SUCCESS: {
 *      'some-trigger-id': {}
 *       // value is optional hash of conditions of key (selector) - value (value to match)
 *       // (selector to be checked against action.payload using lodash get)
 *       // eg. {
 *       //        'requestPayload.taskId': 94251,
 *       //        'requestPayload.members[0].name': 'John Doe'
 *       //     }
 *       // 'some-trigger-id' will be triggered if CREATE_COMMENTS_SUCCESS's action.payload.requestPayload
 *       // has { taskId: 94251 && members: [{ name: 'John Doe'}] }
 *    }
 * },
 * shouldTrigger: {
 *    'some-trigger-id': true
 * }
 */
export const initialState = {
  triggerIdsByActionType: {},
  shouldTrigger: {}
};

/**
 * For eg. triggering refetches
 * triggerIdsByActionType: hashes of triggerIds keyed by action types. These triggerIds are 'subscribed' to
 *    the action type and will have their value in shouldTrigger set to true when the action is dispatched
 * shouldTrigger: hash of triggerIds, with their boolean value indicating that the parent action type has dispatched
 *    the values are reset to false by the handleTriggerWorker saga when triggering actions (.TRIGGER or lack of request suffix)
 *    have the same triggerId in their payload.
 *
 * A different use case is eg. wanting to open a modal with certain props. In that case, shouldTrigger can be used
 * independently of triggerIdsByActionType and be a hash of { triggerId: { ...modalProps } }. See below
 */
export const triggers = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (nextState) => {
    switch (type) {
      case constants.LOGOUT_USER: {
        nextState = initialState;
        break;
      }

      /* ------------------------------- ADD TRIGGER ------------------------------ */

      /**
       * triggerId / triggerIds:
       *    allows for providing either one id or array of ids. only one of these will be used
       * triggerAfter:
       *    'CREATE_COMMENT_SUCCESS' or ['CREATE_COMMENT_SUCCESS', 'CREATE_COMMENT_FAILURE']
       * conditions:
       *    value is either hash of { payload selector: expected value }
       *    or the above hashed again by trigger Ids.
       *    Conditions will be the same for all actions in triggerAfter
       *
       *    {
       *      'requestPayload.taskId': 3045
       *    }
       *
       *    or (keyed by trigger Id):
       *    'some-trigger-id': {
       *        'requestPayload.taskId': 3045
       *    }
       *
       * conditionsByAction:
       *    same as conditions above, but keyed by action type.
       *    Only one of conditions or conditionsByAction will be used
       *
       *    {
       *      'CREATE_COMMENT_SUCCESS': {
       *        'requestPayload.taskId': 3045
       *      }
       *    }
       *
       *    or (conditions by action type then trigger Id):
       *    {
       *       'CREATE_COMMENT_SUCCESS': {
       *           'some-trigger-id': {
       *               'requestPayload.taskId': 3045
       *            }
       *        }
       *    }
       * }
       */
      case constants.ADD_TRIGGER: {
        const {
          triggerId,
          triggerIds = [],
          triggerAfter = [],
          conditions = {},
          conditionsByAction = {}
        } = payload;
        const newTriggerIds = getTriggerIdsToUse(triggerId, triggerIds);
        const newActionsToTriggerAfter = !Array.isArray(triggerAfter)
          ? [triggerAfter]
          : triggerAfter;

        const nextTriggerIdsByActionType = nextState.triggerIdsByActionType;

        // add the new trigger ids under each actionType
        newActionsToTriggerAfter.forEach((actionType) => {
          // add the action type to the hash if necessary
          if (!state.triggerIdsByActionType[actionType]) {
            nextTriggerIdsByActionType[actionType] = {};
          }
          const conditionsForAction = conditionsByAction[actionType];

          // iterate through the new trigger ids and add them to triggerIdsByActionType with conditions
          newTriggerIds.forEach((triggerId) => {
            // conditions for the triggerId and action combination
            const conditionsForTriggerIdAndAction = conditionsForAction
              ? conditionsForAction[triggerId] || conditionsForAction
              : conditions; // see above. may be specific to the triggerId, or be the same for all actions
            // set the value of the trigger id under triggering action to the conditions
            nextTriggerIdsByActionType[actionType][triggerId] =
              conditionsForTriggerIdAndAction;
          });
        });
        // set all new ids' shouldTrigger to false
        nextState.shouldTrigger = {
          ...nextState.shouldTrigger,
          ...newTriggerIds.reduce((acc, triggerId) => {
            acc[triggerId] = false;
            return acc;
          }, {})
        };
        break;
      }

      /* ----------------------------- REMOVE TRIGGER ----------------------------- */

      case constants.REMOVE_TRIGGER: {
        const { triggerId, triggerIds } = payload;
        const triggerIdsToRemove = getTriggerIdsToUse(triggerId, triggerIds);

        Object.keys(state.triggerIdsByActionType).forEach((actionType) => {
          // remove the triggerIds
          triggerIdsToRemove.forEach((triggerIdToRemove) => {
            delete nextState.triggerIdsByActionType[actionType][
              triggerIdToRemove
            ];
            // remove the action being watched if it has no more triggerIds under it
            if (
              !Object.keys(nextState.triggerIdsByActionType[actionType]).length
            ) {
              delete nextState.triggerIdsByActionType[actionType];
            }
          });
        });
        // remove the triggerIds from shouldTrigger
        nextState.shouldTrigger = omit(state.shouldTrigger, triggerIdsToRemove);
        break;
      }

      /* --------------------------- SET SHOULD TRIGGER --------------------------- */

      /**
       *  eg of using shouldTrigger independently (to open a modal with props):
       *
       *  dispatch setShouldTrigger({ triggerId: 'open-some-modal', value: { projectId: 4193 } })
       *
       *  on close: setShouldTrigger({ triggerId: 'open-some-modal', value: false })
       *
       */

      case constants.SET_SHOULD_TRIGGER: {
        const { triggerId, triggerIds, value } = payload;
        const triggerIdsToUpdate = getTriggerIdsToUse(triggerId, triggerIds);

        nextState.shouldTrigger = {
          ...nextState.shouldTrigger,
          ...triggerIdsToUpdate.reduce((acc, id) => {
            acc[id] = value;
            return acc;
          }, {})
        };

        break;
      }
    }
  });
};

export default triggers;

/* ------------------------------------ - ----------------------------------- */

const getTriggerIdsToUse = (triggerId, triggerIds) => {
  return triggerId ? [triggerId] : triggerIds;
};
