import { ReactNode } from 'react';
import styled from 'styled-components';

export interface ThreeDotMenuListItemProps<T = any> {
  icon?: ReactNode;
  label: string;
  onClick: (data: T) => void;
  tooltip?: string;
}

export const ThreeDotMenuListItem = ({
  icon,
  label,
  onClick,
  tooltip = ''
}: ThreeDotMenuListItemProps) => {
  return (
    <RootContainer
      onClick={onClick}
      data-for="app-tooltip"
      data-effect="solid"
      data-class="center"
      data-tip={tooltip}
    >
      <LeadingIconContainer>{icon}</LeadingIconContainer>
      <LabelText>{label}</LabelText>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0 24px 0 8px;
  cursor: pointer;
  gap: 8px;

  :hover {
    background-color: #ececec;
  }
`;

const LeadingIconContainer = styled.div`
  width: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;
