import React from 'react';
import theme from 'theme';
import {
  SaveButton,
  Header,
  HeaderTitle,
  HeaderTitleRow
} from 'components/Modals/themes/TwoPaneModal';
import { ButtonContainer, TextButtonWithBorder } from 'components/styles';
import ScopeMenu, {
  SCOPE_MENU_OPTIONS
} from 'components/Scope/menus/ScopeMenu';

interface ScopeModalHeaderProps {
  isNew?: boolean;
  isRequestModal?: boolean;
  handleDeleteClick: () => void;
  isValid: boolean;
  /** only for creation modal - 'cancel' */
  handleCloseModal?: () => void;
  handleDone: () => void;
  isProjectViewScope?: boolean;
  projectId?: number;
}

const ScopeModalHeader = ({
  isNew,
  isRequestModal,
  handleDeleteClick,
  isValid,
  handleCloseModal,
  handleDone,
  isProjectViewScope,
  projectId
}: ScopeModalHeaderProps) => {
  return (
    <Header>
      <HeaderTitleRow>
        <HeaderTitle>
          <h1>{isRequestModal ? 'Scope Request' : 'Scope Detail'}</h1>

          {/* Three dot menu */}
          {!isProjectViewScope && !isNew && (
            <ScopeMenu
              projectId={projectId}
              options={[
                ...(projectId ? [SCOPE_MENU_OPTIONS.GO_TO_PROJECT] : []),
                SCOPE_MENU_OPTIONS.DELETE
              ]}
              overrideClickHandlers={{
                [SCOPE_MENU_OPTIONS.DELETE]: handleDeleteClick
              }}
            />
          )}
        </HeaderTitle>

        {/* Done button */}
        <ButtonContainer>
          {isNew ? (
            <TextButtonWithBorder
              onClick={handleCloseModal}
              fontWeight={400}
              data-testid="scope-request-cancel-button"
            >
              Cancel
            </TextButtonWithBorder>
          ) : null}
          <SaveButton
            color="white"
            backgroundColor={
              !isValid
                ? theme.colors.colorLightGray8
                : theme.colors.colorRoyalBlue
            }
            onClick={handleDone}
            fontWeight={600}
            data-testid="scope-request-save-button"
            isSaveDisabled={!isValid}
          >
            {isNew ? 'Save' : 'Done'}
          </SaveButton>
        </ButtonContainer>
      </HeaderTitleRow>
    </Header>
  );
};

export default ScopeModalHeader;
