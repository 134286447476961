import theme from 'theme';
import styled from 'styled-components';

import DeleteIcon from 'icons/DeleteIcon';
import SmallOutlinedPencil from 'icons/SmallOutlinedPencil';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';

import { VisibilityText } from 'components/Permissions/Access/styles';

export const CloseIconStyle = styled.div`
  cursor: pointer;

  svg {
    height: 29px;
    width: 29px;
  }
  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray5};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const BackKaratContainer = styled.div<{ showKarat?: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: ${theme.colors.colorPaleGray1} 1px solid;
  border-radius: 90px;
  visibility: ${({ showKarat }) => (showKarat ? 'visible' : 'hidden')};
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const XIconContainer = styled.div<{ showX?: boolean }>`
  border: unset;
  cursor: pointer;
  margin-left: 140px;
  visibility: ${({ showX }) => (showX ? 'visible' : 'hidden')};

  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray5};
    }
  }
  path {
    fill: ${theme.colors.colorPureBlack};
  }
`;

export const CreateStandardButton = styled.div`
  margin-left: 44px;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 14px;
  padding: 2px 12px;
  background: ${theme.colors.colorTranslucentGray3};
  display: flex;
  border-radius: 100px;
  color: ${theme.colors.colorRoyalBlue};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorPureWhite};
  }
`;
export const StyledMemberName = styled.div`
  margin-left: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledMemberTitle = styled.div`
  font-size: 20px;
  padding-left: 19px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
`;

export const StyledDeleteIcon = styled(DeleteIcon)``;

export const StyledSmallPencilIcon = styled(SmallOutlinedPencil)`
  margin-left: 5px;
`;
export const DeleteIconContainer = styled.div<{
  isHidden?: boolean;
  hide?: boolean;
}>`
  width: 34px;
  height: 100%;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  &:hover {
    ${StyledDeleteIcon} {
      path {
        fill: ${theme.colors.colorDeleteRed};
      }
    }
  }
  ${(props) =>
    props.hide &&
    `
    pointer-events: none;
    visibility: hidden !important;
  `}
`;

export const StyledDot = styled.div`
  position: absolute;
  height: 10px;
  top: 50%;
  border-radius: 100px;
  transform: translateY(-50%);
  width: 10px;
  background: ${theme.colors.colorMediumGray1};
  background: ${calculateProjectColorPreference};
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -26px;
  height: 26px;
  width: 26px;
  top: 7px;
  border-radius: 100px;
  .color-picker-container {
    height: 100%;
    width: 100%;
  }

  &:hover {
    .project-color-picker {
      visibility: visible !important;
    }
  }
`;

export const BudgetModalLegendsContainer = styled.div`
  position: relative;
  z-index: 120;
  top: 10px;
`;

export const StyledListRow = styled.div`
  height: 100%;
  margin: 0 20px 0 0;
  white-space: nowrap;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};
`;

export const StyledCol = styled.div<{ width?: number; textCenter?: boolean }>`
  ${(props) => props.width && `width: ${props.width}px;`}
  height: 100%;
  ${(props) => props.textCenter && 'text-align: center;'}
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
`;

export const EmptyCell = styled.div`
  height: 100%;
  border: none !important;
`;

export const StyledCell = styled.div<{ center?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
  ${(props) => props.center && 'justify-content: center;'}
`;

export const StyledHeaderCell = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  height: 100%;
  border-bottom: 2px solid ${theme.colors.colorLightGray9};

  &.multiplier,
  &.start-date,
  &.startDate,
  &.end-date,
  &.endDate {
    text-align: center;
  }
`;

export const StyledRateContainer = styled.div`
  flex-grow: 1;
  max-width: 224px;
  display: flex;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDatesContainer = styled.div`
  width: 150px;
  margin-right: 10px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
`;
export const StyledRate = styled(StyledCol)`
  text-align: left;
`;

export const StyledRateDescription = styled(StyledCol)<{
  hasPadding?: boolean;
}>`
  padding-left: 5px;
  display: flex;
  align-items: center;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  padding-left: ${({ hasPadding }) => (hasPadding ? '10px' : '')};
`;
export const StyledDate = styled(StyledCell)<{
  isEarliest?: boolean;
  isDisabled?: boolean;
  center?: boolean;
}>`
  white-space: break-spaces;
  text-align: center;
  &:hover {
    color: ${(props) =>
      props.isEarliest || props.isDisabled ? '' : theme.colors.colorRoyalBlue};
  }
  .rc-container-picker-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StandardRatesContainer = styled.div`
  padding: 0px 12px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  padding: 0px 10px;
  width: 100%;
  min-width: 280px;

  ${StyledRateDescription} {
    margin-left: 5px;
    flex-grow: 1;
  }
`;
export const EditRateContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;
export const StyledNewRateContainer = styled.form`
  display: flex;
  padding: 3px 0 10px;
  font-size: 13px;
  & > * {
    margin-right: 6px;
  }
`;
export const StyledRateMessage = styled.div`
  font-size: 13px;
  line-height: 1.2;
  padding: 0 0 10px;
  color: ${theme.colors.colorSemiDarkGray1};
`;
export const StyledTitleTextContainer = styled.div`
  display: flex;
  div {
    margin-right: 6px;
  }
`;

export const StyledTitleText = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  text-transform: uppercase;
  color: ${theme.colors.colorMediumGray1};
  width: 60px;
`;
export const StyledMultiplierText = styled(StyledTitleText)`
  width: 120px;
  position: relative;
`;
export const StyledDescriptionText = styled(StyledTitleText)`
  width: 250px;
`;

const StyledInput = styled.input<{ isInvalid: boolean }>`
  border: 1px solid
    ${(props) =>
      props.isInvalid ? theme.colors.colorDeleteRed : 'transparent'};
  border-bottom: 1px solid
    ${(props) =>
      props.isInvalid
        ? theme.colors.colorDeleteRed
        : theme.colors.colorPaleGray4};
  background: ${theme.colors.colorTranslucentGray4};
  padding-bottom: 2px;
  font-size: 13px;
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }
  &::placeholder {
    color: ${theme.colors.colorMediumGray3};
  }
  transition: border ${(props) => (props.isInvalid ? 0 : 150)}ms ease-in-out;
`;

export const StyledNewRate = styled(StyledInput)`
  width: 60px;
`;

export const StyledNewMultiplier = styled(StyledInput)`
  width: 50px;
  padding: 10px;
`;

export const StyledNewRateDescription = styled(StyledInput)`
  width: 250px;
  padding: 10px;
  text-align: left;
`;

export const StyledRoleContainer = styled.div`
  padding: 0px 12px;
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledRoleEdit = styled(StyledInput)`
  width: 90%;
  margin: 0px 15px;
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const AddedText = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
`;
export const RemoveText = styled.div`
  color: ${theme.colors.colorDeleteRed};
`;

export const StandardPhaseDotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 28px;
  width: 28px;
`;

export const AddText = styled.p`
  visibility: hidden;
  color: ${theme.colors.colorCalendarBlue};
  position: relative;
  top: 8px;
`;

export const StyledPhasesOfWork = styled.div<{ isInEditMode: boolean }>`
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${AddedText} {
    display: flex;
  }
  ${RemoveText} {
    display: none;
  }
  ${StandardPhaseDotContainer} {
    opacity: ${({ isInEditMode }) => (isInEditMode ? '1' : '0')};
  }

  &:hover {
    ${AddedText} {
      display: none;
    }
    ${RemoveText} {
      display: flex;
    }
    ${StandardPhaseDotContainer} {
      opacity: 1;
    }
    ${AddText} {
      visibility: visible;
    }
  }
`;

export const StyledPhaseName = styled.div<{ isArchived: boolean }>`
  color: ${(props) =>
    !props.isArchived
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  padding-left: 8px;
`;

export const StyledPhaseState = styled.div<{ isArchived: boolean }>`
  color: ${(props) =>
    !props.isArchived
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorMediumGray5};
  font-size: 11px;
`;

export const SpentHoursContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SpentHoursDate = styled.div`
  width: 40%;
  padding-left: 12px;
`;
export const SpentHoursDay = styled.div`
  width: 40%;
`;
export const SpentHoursTime = styled.div`
  width: 20%;
`;
export const PlannedHoursContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const PlannedHoursDate = styled.div`
  width: 40%;
  padding-left: 12px;
`;
export const PlannedHoursDay = styled.div`
  flex-grow: 1;
`;
export const PlannedHoursTime = styled.div`
  width: 30%;
`;

export const AddPhaseButtonContainer = styled.div`
  margin-left: 15px;
  width: 100px;
  margin-bottom: 0px;
`;

export const ExpandCollapseToggle = styled.div`
  cursor: pointer;
  padding-right: 24px;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;
export const DotsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
export const MemberContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 87px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  color: ${theme.colors.colorCalendarBlue};
  z-index: 101;
`;

export const BudgetViewersContainer = styled.div`
  font-weight: 400;
  cursor: pointer;
  width: fit-content;
  ${VisibilityText} {
    justify-content: unset;
  }
  &:not(:hover) {
    ${VisibilityText} {
      font-weight: 400;
      justify-content: unset;
    }
  }
`;

export const HoverContainer = styled.div`
  padding: 2px 2px 1px 5px;
  ${BudgetViewersContainer}:hover, &:hover {
    background-color: ${theme.colors.colorPaleGray7};
    border-radius: 3px;
  }
`;

export const ProjectTotalsContainer = styled.div`
  width: 160px;
  margin: 0 20px;
  text-align: left;
`;

const StyledBar = styled.div<{ flexWidth: number | string }>`
  height: 100%;
  flex: ${({ flexWidth }) => flexWidth};
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const StyledSpentBar = styled(StyledBar)`
  background: ${theme.colors.colorBudgetGreen};
`;
export const StyledPlannedBar = styled(StyledBar)`
  background: ${theme.colors.colorPlannedHours};
`;
export const StyledRemainingBar = styled(StyledBar)`
  background: ${theme.colors.colorBudgetLightGreen};
`;
export const StyledOverBudgetBar = styled(StyledBar)<{
  flexWidth?: number | string;
}>`
  background: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 2px,
    #ff0000 4px
  );
  position: absolute;
  right: 0px;
  width: ${({ flexWidth }) => flexWidth}%;
  max-width: 100%;
`;
export const AddHolidayContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorRoyalBlue};
  position: relative;
  font-weight: 400;
  padding-top: 8px;
  cursor: pointer;
`;
export const StyledHolidayContainer = styled.div`
  padding: 0px 10px;
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

export const StyledHolidayName = styled.div`
  font-size: 12px;
`;

export const MenuItem = styled.div`
  padding: 3px 20px 3px 10px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
  }
`;

export const StyledDoneButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};
  font-weight: 600;

  &:hover {
    filter: brightness(95%);
  }
`;

export const CancelButton = styled.button`
  color: ${theme.colors.colorMediumGray1};
  border: 1px solid ${theme.colors.colorPaleGray5};
  font-size: 12px;
  background: transparent;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 0px 5px;
  font-weight: 600;

  &:hover {
    background: ${theme.colors.colorPaleGray5};
    border: 1px solid ${theme.colors.colorPaleGray5};
    color: ${theme.colors.colorMediumGray5};
  }
`;

export const OptionsContainer = styled.div`
  padding: 4px 16px;
  display: flex;
  align-items: center;
`;
export const StyledRowContainer = styled.div<{
  colWidths: string;
  // TODO: Refactor all places using this prop to not use it anymore once they shift over to updated design for "timeline" modal
  isLast?: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props) => props.colWidths};
  height: 100%;
  width: 100%;
  align-items: center;

  ${StyledCell} {
    &:not(:first-child) {
      border-bottom: 1px solid ${theme.colors.colorPaleGray9};
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
  }

  ${(props) =>
    props.isLast &&
    `
    ${StyledCell} {
      border-bottom: none !important;
    }
  `}

  ${DeleteIconContainer} {
    visibility: hidden;
  }
  ${StyledSmallPencilIcon} {
    visibility: hidden;
  }
  &:hover {
    ${StyledCell} {
      background: ${theme.colors.colorTranslucentGray4};
    }
    ${DeleteIconContainer} {
      visibility: visible;
    }
    ${StyledSmallPencilIcon} {
      visibility: visible;
    }
  }

  &:hover {
    ${StyledListRow} {
      background: ${theme.colors.colorTranslucentGray3};
    }
  }
`;

export const AddRateContainer = styled.div<{ emptyList: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};
  padding: 10px 5px;
  border-bottom: ${(props) =>
    props.emptyList ? 'none' : `1px solid ${theme.colors.colorPaleGray5}`};
  width: 100%;
`;

export const MiniHeaderContainer = styled.div<{ colWidths: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.colWidths};
  align-items: center;
  text-transform: uppercase;
  padding-bottom: 2px;
  margin: 0 auto;
  width: 100%;

  ${StyledHeaderCell}:nth-child(2) {
    padding-left: 10px;
  }
`;

export const MiniDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
  margin-right: 10px;
`;

export const MiniStandardRatesHeader = styled.div`
  padding: 0 44px;
`;
export const StyledRatesRowContainer = styled.div<{
  colWidths: string;
  isArchived?: boolean;
}>`
  font-size: 15px;
  margin: 12px 0;
  align-items: center;

  display: grid;
  grid-template-columns: ${(props) => props.colWidths};
  height: 100%;
  width: 100%;

  ${StyledRate}, ${StyledRateDescription} {
    ${(props) => props.isArchived && `color: ${theme.colors.colorMediumGray5}`}
  }

  div:not(:first-child) {
    border-bottom: 1px solid ${theme.colors.colorPaleGray6};
    // Edit mode border and background hover color are handled in
    // modal style .styled-flyout-list-container
    .edit-mode & {
      border: none;
    }
  }
`;

export const StyledRatesRowOuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${DeleteIconContainer} {
    visibility: hidden;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    ${StyledRatesRowContainer} div:not(:first-child) {
      background: ${theme.colors.colorTranslucentGray3};
    }
  }
`;

export const EditHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
  justify-content: space-between;
  margin-left: 21px;
`;

export const StyledBlueButton = styled.button`
  padding: 4px 16px;
  font-size: 14px;
  background: ${theme.colors.colorRoyalBlue};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 5px;

  &:hover {
    border: 1px solid ${theme.colors.colorDeeperRoyalBlue};
    background: ${theme.colors.colorDeeperRoyalBlue};
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: 47px; // Match row height
  border-bottom: 1px solid #e2e2e2;
  span {
    position: relative;
    top: 21px;
    left: 5px;
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.colorMediumGray5};
  }
`;

export const StyledBudgetIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: -5px;
  .milestone-icon-box {
    stroke: ${theme.colors.colorMediumGray5};
    stroke-width: 1.5px;
  }
  .dollar-sign-in-icon {
    fill: ${theme.colors.colorMediumGray5};
  }
`;

export const StyledBudgetPhaseMilestoneIcon = styled(BudgetPhaseMilestoneIcon)`
  flex-basis: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 5px;
`;

export const IssueIconContainer = styled.div`
  color: ${theme.colors.colorDeleteRed};
  font-size: 15px;
  font-weight: 800;
  margin-left: 5px;
`;

export const ActivitiesDropdownDivider = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: ${theme.colors.colorLightGray15};
  border-bottom: 1px solid ${theme.colors.colorLightGray15};
  width: 100%;
`;

export const CreateActivityToggle = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  margin-left: 10px;
`;
