import React from 'react';
import { StyledHeader, HeaderCopy, CloseIconContainer } from './styles';
import PropTypes from 'prop-types';
import NewCloseIcon from '../../assets/icons/NewCloseIcon';
import theme from 'theme';

class FlyoutHeader extends React.Component {
  renderHeaderCopy = () => {
    const {
      copy,
      addMode,
      editingId,
      editMode,
      renderHeader,
      headerTitle,
      clearSearch
    } = this.props;
    if (headerTitle) return headerTitle;
    if (renderHeader) {
      return renderHeader({
        addMode,
        clearSearch,
        editingId
      });
    }
    if (addMode) {
      return copy.headerAdd;
    }
    if (editingId || editMode) {
      return copy.headerEdit;
    }
    return copy.headerInitial;
  };

  renderRightButton = () => {
    const { handleClose, renderHeaderButton, hideClose, popoverClassName } =
      this.props;
    if (renderHeaderButton) {
      return renderHeaderButton({ handleClose });
    }

    return (
      !hideClose && (
        <CloseIconContainer onClick={(e) => handleClose(e, true)}>
          <NewCloseIcon
            className="close-icon-black"
            width={30}
            height={30}
            fillColor={theme.colors.colorPureBlack}
          />
        </CloseIconContainer>
      )
    );
  };

  render() {
    const {
      addMode,
      editingId,
      editMode,
      isWhite,
      renderEditHeaderText,
      noMinWidth
    } = this.props;

    return renderEditHeaderText && (editingId || editMode) ? (
      <StyledHeader isWhite className="styled-header-modal" i>
        {renderEditHeaderText({ addMode, editMode })}
      </StyledHeader>
    ) : (
      <StyledHeader
        noMinWidth={noMinWidth}
        className="styled-header-modal"
        isWhite={isWhite || addMode || editingId}
      >
        <HeaderCopy className="styled-header-copy">
          {this.renderHeaderCopy()}
        </HeaderCopy>
        {this.renderRightButton()}
      </StyledHeader>
    );
  }
}

FlyoutHeader.propTypes = {
  copy: PropTypes.object.isRequired,
  editingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addMode: PropTypes.bool.isRequired
};

export default FlyoutHeader;
