import React from 'react';
import cn from 'classnames';

const TaskCommentBubble = ({
  totalComments,
  unviewed,
  toggleTaskModal,
  taskId
}) => (
  <div
    className="comment-bubble-container"
    onClick={(e) => toggleTaskModal(true, taskId, e)}
  >
    <div className={cn('comment-bubble', { unviewed })}>
      {totalComments > 0 && unviewed ? totalComments : ''}
    </div>
  </div>
);

export default TaskCommentBubble;
