import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SmallWorkCategoryIcon = ({
  height = '5',
  width = '6',
  color = '#4F4F4F',
  style
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 5 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.61328 2.83389L2.61281 0.5L0.613281 2.83278L2.61376 5.16667L4.61328 2.83389Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default SmallWorkCategoryIcon;
