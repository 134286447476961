import { createRequestTypes } from 'appUtils';

/* ----------------------------- entity comment ----------------------------- */
// original CREATE_COMMENT is dedicated for task
// new actions required to decouple comments with specific entity

export const FETCH_ENTITY_COMMENTS = createRequestTypes(
  'FETCH_ENTITY_COMMENTS'
);

export const CREATE_ENTITY_COMMENT = createRequestTypes(
  'CREATE_ENTITY_COMMENT'
);

export const UPDATE_ENTITY_COMMENT = createRequestTypes(
  'UPDATE_ENTITY_COMMENT'
);

export const DELETE_ENTITY_COMMENT = createRequestTypes(
  'DELETE_ENTITY_COMMENT'
);
