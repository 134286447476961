import cn from 'classnames';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getAllActivityRowInfo } from 'selectors';
import { StyledDownArrow, StyledDate } from './styles';
import QBDownArrow from 'icons/QBDownArrow';
import moment from 'moment';
import { DATE_DISPLAY_TINY } from 'appConstants/date';

const WorkloadActivity = ({
  activityId,
  activityPhase,
  handleClick,
  isInvalid
}) => {
  const activitiesObj = useSelector(getAllActivityRowInfo);
  const activity = activitiesObj[activityId];
  const showPlaceholder = !activityId;

  const renderPlaceholder = () => (
    <>
      Select Work Category
      <StyledDownArrow isBlue>
        <QBDownArrow />
      </StyledDownArrow>
    </>
  );

  const renderActivity = () => (
    <>
      <span className="activity-title">{activity?.title}</span>
      {activityPhase?.start_date && (
        <StyledDate className="activity-phase-name">
          {moment(activityPhase.start_date).format(DATE_DISPLAY_TINY)}{' '}
          {activityPhase.end_date &&
            `- ${moment(activityPhase.end_date).format(DATE_DISPLAY_TINY)}`}
        </StyledDate>
      )}
    </>
  );

  return (
    <SelectedActivity
      className={cn({
        'selected-activity': !showPlaceholder,
        error: isInvalid,
        placeholder: showPlaceholder
      })}
      onClick={handleClick}
    >
      {showPlaceholder ? renderPlaceholder() : renderActivity()}
    </SelectedActivity>
  );
};

export default WorkloadActivity;

const SelectedActivity = styled.div`
  margin-left: 5px;
  margin-top: -1px;
  &.placeholder {
    font-size: 12px;
    font-weight: 800;
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }

  &.error:not(:hover) {
    color: ${({ theme }) => theme.colors.colorCalendarRed};
    ${StyledDownArrow} {
      path {
        fill: ${({ theme }) => theme.colors.colorCalendarRed};
      }
    }
  }
`;
