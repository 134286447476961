import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import SyncFailedIcon from 'icons/SyncFailedIcon';
import SyncedIcon from 'icons/SyncedIcon';
import * as constants from 'IntegrationsModule/constants';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-align: center;
  font-size: 12px;
  padding-left: 13px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
`;

const StatusCell = ({ row }) => {
  const { userActivity } = row.original;
  const mappingStatus = userActivity.mappingStatus;
  let Icon;

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const isActive = constants.ACTIVE_MAPPING_STATUSES.includes(mappingStatus);
  const isSyncing = constants.SYNCING_MAPPING_STATUSES.includes(mappingStatus);

  /** Syncing */
  if (isSyncing) {
    Icon = (
      <SyncedIcon
        width={13}
        height={13}
        color={theme.colors.colorLightOrange2}
      />
    );
  } else if (isActive) {
    /** Active */
    Icon = (
      <SyncedIcon
        width={13}
        height={13}
        color={theme.colors.colorBudgetDotGreen}
      />
    );
  } else {
    /** Blocked */
    Icon = <SyncFailedIcon width={13} height={13} />;
  }

  return (
    <StyledContainer
      data-tip={isSyncing ? 'Import in Progress' : ''}
      data-for={isSyncing ? 'app-tooltip' : ''}
      data-effect="solid"
    >
      {Icon}
    </StyledContainer>
  );
};

export default StatusCell;
