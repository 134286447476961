import React from 'react';
import cn from 'classnames';
import { MemberTasks } from './styles';

const TeamMemberCardTasksContainer = ({
  incompleteTasksCount,
  overdueTasksCount
}) => (
  <MemberTasks>
    <div className="task-counter incomplete">
      <div
        className={cn('count', {
          'is-zero': !incompleteTasksCount
        })}
      >
        {incompleteTasksCount}
      </div>
      <div className="task-description">To Do</div>
    </div>
    <div className="task-counter overdue">
      <div
        className={cn('count', {
          'is-zero': !overdueTasksCount
        })}
      >
        {overdueTasksCount}
      </div>
      <div className="task-description">Overdue</div>
    </div>
  </MemberTasks>
);

export default TeamMemberCardTasksContainer;
