import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { ValueOf } from 'type-fest';
import MoveIcon from 'icons/MoveIcon';
import MoveTasksToTopIcon from 'icons/MoveTasksToTopIcon';
import TrashBatchIcon from 'icons/TrashBatchIcon';
import CopyBatchIcon from 'icons/CopyBatchIcon';
import HomeTasksIcon from 'icons/HomeTasksIcon';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

interface BaseBatchActionsMenuOptionConfig {
  isDisabled?: boolean;
  tooltip?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}

interface CustomBatchActionsMenuOptionConfig
  extends BaseBatchActionsMenuOptionConfig {
  label?: ReactNode;
  icon?: ReactNode;
}

type OptionHash = Partial<
  Record<BatchActionsMenuOptions, BaseBatchActionsMenuOptionConfig>
> &
  Record<string, CustomBatchActionsMenuOptionConfig>;

interface BatchActionsMenuProps {
  optionHash: OptionHash;
  options: (string | string[])[];
  isDisabled?: boolean;
  menuContainer?: () => JSX.Element;
}

export const BatchActionsMenu = ({
  optionHash,
  options,
  menuContainer: MenuContainer
}: BatchActionsMenuProps) => {
  const Container = MenuContainer || BatchActionsMenuContainer;

  const renderOption = (id: string) => {
    const {
      onClick,
      isDisabled = false,
      tooltip
    } = optionHash[id] as BaseBatchActionsMenuOptionConfig;

    if (tooltip) rebuildTooltip();

    if (id === BatchActionsMenuOption.Divider) {
      return <Divider key={id} />;
    }

    // use fallback to empty object in case user of component forgets to add the current `id` to `optionHash` and also this id not being inside `commonOptionHash`
    const { label, icon } = (optionHash[id] ||
      commonOptionHash[id] ||
      {}) as Pick<CustomBatchActionsMenuOptionConfig, 'label' | 'icon'>;

    const tooltipProps = tooltip
      ? {
          'data-for': 'app-tooltip',
          'data-tip': tooltip,
          'data-effect': 'solid'
        }
      : undefined;

    if (!label && !icon) return <></>;

    return (
      <MenuOption
        key={id}
        onClick={isDisabled ? undefined : onClick}
        $isDisabled={isDisabled}
        className="batch-actions-option"
        {...tooltipProps}
      >
        {icon && (
          <OptionIcon className="batch-actions-option-icon-container">
            {icon}
          </OptionIcon>
        )}
        <OptionLabel className="batch-actions-option-label">
          {label}
        </OptionLabel>
      </MenuOption>
    );
  };

  return (
    <Container className="batch-actions-container">
      {options.map((optionConfig) => {
        if (Array.isArray(optionConfig)) {
          return (
            <OptionsRow key={optionConfig[0]}>
              {optionConfig.map(renderOption)}
            </OptionsRow>
          );
        }

        return renderOption(optionConfig);
      })}
    </Container>
  );
};

export const BatchActionsMenuOption = {
  SelectAll: 'SelectAll',
  Clear: 'Clear',
  Approve: 'Approve',
  Move: 'Move',
  MoveToTop: 'MoveToTop',
  Copy: 'Copy',
  Delete: 'Delete',
  Divider: 'Divider'
} as const;

const MenuOption = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  height: 20px;
  margin: 8px 0px;
  width: fit-content;
  color: ${theme.colors.colorRoyalBlue};
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.$isDisabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.$isDisabled ? 0.6 : 1)};
  ${(props) =>
    props.$isDisabled &&
    `
    &:hover {
      .batch-actions-option-label {
        font-weight: 400 !important;
      }
      cursor: not-allowed;
    }
  `}
`;

const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  margin-right: 9px;
`;

const OptionLabel = styled.div`
  font-size: 13px;
  ${MenuOption}:hover & {
    font-weight: bold;
  }
`;

type BatchActionsMenuOptions = ValueOf<typeof BatchActionsMenuOption>;

const CheckmarkIcon = styled(HomeTasksIcon)`
  path {
    stroke-width: 0.2;
  }
`;

const BatchActionsMenuContainer = styled.div`
  height: fit-content;
`;

const Divider = styled.div`
  width: 80%;
  height: 1px;
  margin: 2px 0;
  background-color: ${theme.colors.colorPaleGray5};
`;

const OptionsRow = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 10px;
  }
`;

export const commonOptionHash: Record<
  BatchActionsMenuOptions,
  { label: ReactNode; icon: Nullable<ReactNode> }
> = {
  [BatchActionsMenuOption.SelectAll]: {
    label: 'All',
    icon: null
  },
  [BatchActionsMenuOption.Clear]: {
    label: 'Clear',
    icon: null
  },
  [BatchActionsMenuOption.Approve]: {
    label: 'Approve',
    icon: (
      <CheckmarkIcon
        width={14}
        height={14}
        currentColor={theme.colors.colorRoyalBlue}
        className={undefined}
      />
    )
  },
  [BatchActionsMenuOption.Move]: {
    label: 'Move',
    icon: <MoveIcon />
  },
  [BatchActionsMenuOption.MoveToTop]: {
    label: 'Top',
    icon: <MoveTasksToTopIcon />
  },
  [BatchActionsMenuOption.Copy]: {
    label: 'Copy',
    icon: <CopyBatchIcon />
  },
  [BatchActionsMenuOption.Delete]: {
    label: 'Delete',
    icon: <TrashBatchIcon />
  },
  [BatchActionsMenuOption.Divider]: {
    label: '',
    icon: null
  }
};
