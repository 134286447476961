import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import { Field, Label } from 'SettingsModule/components/styles';
import { OrganizationSettingsPopover } from '../shared/OrganizationSettingsPopover';

const options = {
  sunday: { label: 'Sunday' },
  monday: { label: 'Monday' },
  tuesday: { label: 'Tuesday' },
  wednesday: { label: 'Wednesday' },
  thursday: { label: 'Thursday' },
  friday: { label: 'Friday' },
  saturday: { label: 'Saturday' }
};

export const StartOfWeekSetting = () => {
  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Select the day of the week to view start<br/>
          of week for work planning & timesheets."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Start of Week
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <OrganizationSettingsPopover
          options={options}
          currentlySelectedKey="sunday"
        />
      </Field>
    </>
  );
};
