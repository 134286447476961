import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

const BudgetReportContainerV2 = lazy(() =>
  import(
    'ReportsModule/components/Budget/BudgetReportTable/BudgetReportContainer'
  )
);
const LoadBoardBudgetContainer = (props) => {
  return (
    <DynamicModuleLoader modules={[getBudgetModule()]}>
      <Suspense fallback={<div />}>
        <BudgetReportContainerV2
          activeFilter={props.activeFilter}
          viewBy={props.viewBy}
          pageName={props.pageName}
          isInModal={props.isInModal}
          filterSections={props.filterSections}
          varianceType={props.varianceType}
          isProjectViewReport={props.isProjectViewReport}
          isDashboardWidgetModal={props.isDashboardWidgetModal}
          widgetConfig={props.widgetConfig}
          widgetDetailModalThreeDotMenuItems={
            props.widgetDetailModalThreeDotMenuItems
          }
          viewType={props.viewType}
          customProjectTitle={props.isInModal ? 'Project Total' : ''}
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};
export default LoadBoardBudgetContainer;
