import * as constants from 'appConstants';

export const initialState = {
  isLoading: false,
  errorMessage: null,
  showCancellationConfirmation: false,
  planSelection: {},
  plans: {
    yearly: {},
    monthly: {}
  },
  currentSubscription: {},
  card: {},
  updatingCard: false,
  currentModal: null,
  modalHistory: [],
  trialExpiryProcess: {
    triggered: false,
    trialExtended: false,
    isAdmin: false
  },
  openMembersTabDefault: false
};

const billing = (state = initialState, action) => {
  let errorMessage = '';
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.SUBSCRIPTION_CANCELLATION.SUCCESS:
      return {
        ...state,
        currentModal: 'team-settings-modal',
        modalHistory: []
      };

    case constants.CLEAR_BILLING_ERROR:
      return {
        ...state,
        errorMessage: null
      };

    case constants.LOGOUT_TRIAL_EXPIRY:
      return {
        ...state,
        currentModal: 'base-expiry-modal',
        trialExpiryProcess: {
          triggered: true,
          trialExtended: action.payload.trialExtended,
          isAdmin: action.payload.isAdmin
        }
      };

    case constants.SET_MODAL:
      let newState;

      if (
        action.payload.newModalName === state.modalHistory[0] &&
        !action.payload.prevModal
      ) {
        newState = {
          ...state,
          currentModal: action.payload.newModalName,
          modalHistory:
            state.modalHistory.length > 1 ? state.modalHistory.slice(1) : [],
          openMembersTabDefault: action.payload.openMembersTab
        };
      } else {
        newState = {
          ...state,
          currentModal: action.payload.newModalName,
          modalHistory: action.payload.prevModal
            ? [action.payload.prevModal, ...state.modalHistory]
            : [...state.modalHistory],
          openMembersTabDefault: action.payload.openMembersTab
        };
      }

      return newState;

    case constants.CANCEL_TRIAL_EXPIRY:
      return {
        ...state,
        currentModal: null,
        modalHistory: [],
        trialExpiryProcess: {
          triggered: false,
          trialExtended: false
        }
      };
    case constants.SUBSCRIPTION_CANCELLATION.TRIGGER:
      return {
        ...state,
        errorMessage: null,
        isLoading: true
      };
    case constants.SUBSCRIPTION_FETCH.SUCCESS:
      return {
        ...state,
        currentSubscription: action.payload.response
      };
    case constants.USER.SUCCESS: {
      const { default_team } = action.payload.response.account;
      return {
        ...state,
        me: action.payload.response.account,
        planSelection: {
          ...state.planSelection,
          quantity: default_team ? default_team.members_count : 0
        }
      };
    }
    case constants.PLAN_PERIOD_UPDATE:
      return {
        ...state,
        planSelection: {
          ...state.planSelection,
          plan: action.payload.plan
        }
      };
    case constants.SUBSCRIPTION_CANCELLATION.FAILURE:
      if (action.payload.response.status === 422) {
        errorMessage =
          '"You cannot downgrade the plan if you have more team members than the free plan allows (maximum of 5).';
      } else {
        errorMessage = 'There was an error canceling your plan.';
      }
      return {
        ...state,
        isLoading: false,
        errorMessage
      };
    case constants.SUBSCRIPTION_CANCELLATION.SUCCESS:
      return {
        ...state,
        cancellationConfirmation: false,
        isLoading: false,
        errorMessage: null
      };
    case constants.ENTER_UPDATE_CARD:
      return {
        ...state,
        updatingCard: true
      };
    case constants.EXIT_UPDATE_CARD:
      return {
        ...state,
        updatingCard: false,
        isLoading: false,
        errorMessage: null
      };
    default:
      return state;
  }
};

export default billing;
