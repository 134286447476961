import styled from 'styled-components';
import {
  Header,
  ScrollContainer,
  StyledTwoPaneModal
} from 'components/Modals/themes/TwoPaneModal/styles';

import {
  ModalBodyContainer,
  MainBody
} from 'components/Modals/themes/TwoPaneModal';
import { WorkPlanModalHeader } from './WorkPlanModalHeader';
import { WorkPlanForm } from './form/WorkPlanForm';
import {
  WorkPlanFormProvider,
  WorkPlanFormConsumer,
  WorkPlanFormProviderProps
} from './WorkPlanFormProvider';
import { ReassignWorkplansConfirmModal } from './extraModals/ReassignWorkplansConfirmModal';
import { UnarchiveMemberConfirmModal } from './extraModals/UnarchiveMemberConfirmModal';
import { DeleteWorkplanConfirmModal } from './extraModals/DeleteWorkplanConfirmModal';
import { ProviderType, SidebarPanel } from './SidebarPanel/SidebarPanel';
import { SidebarPanelProvider } from './SidebarPanel/SidebarPanelProvider';
import { WorkplanPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/permissionProviders/workplan';

export const WorkPlanModal = ({
  workPlanFormProviderProps
}: {
  workPlanFormProviderProps?: Omit<WorkPlanFormProviderProps, 'children'>;
}) => {
  return (
    <WorkplanPermissionProvider>
      <WorkPlanFormProvider {...workPlanFormProviderProps}>
        <WorkPlanFormConsumer>
          {({ modal: { isOpen, closeModal, onOpenModal, onCloseModal } }) => (
            <StyledWorkPlanModal
              isOpen={isOpen}
              $hasSideBody
              toggle={closeModal}
              onOpened={onOpenModal}
              onClosed={onCloseModal}
              backdrop="static"
            >
              <SidebarPanelProvider>
                <WorkPlanModalHeader />
                <StyledModalBodyContainer hasSideBody>
                  <LeftPanel>
                    <WorkPlanForm />
                  </LeftPanel>
                  <RightPanel>
                    <SidebarPanel providerType={ProviderType.WorkPlan} />
                  </RightPanel>
                </StyledModalBodyContainer>
              </SidebarPanelProvider>
              <ReassignWorkplansConfirmModal />
              <DeleteWorkplanConfirmModal />
              <UnarchiveMemberConfirmModal />
            </StyledWorkPlanModal>
          )}
        </WorkPlanFormConsumer>
      </WorkPlanFormProvider>
    </WorkplanPermissionProvider>
  );
};
const StyledWorkPlanModal = styled(StyledTwoPaneModal)<{
  $hasSideBody: boolean;
}>`
  width: 920px;

  ${Header} {
    padding-top: 33px;
    padding-bottom: 20px;
    padding-left: 48px;
    padding-right: 35px;
  }
  ${ScrollContainer} {
    padding-left: 73px;
    padding-right: 37px;
  }

  .modal-content {
    border-radius: 6px !important;
  }
`;

const LeftPanel = styled(MainBody).attrs({ backgroundColor: 'unset' })`
  padding-top: 12px;
  padding-bottom: 20px;
`;

const RightPanel = styled.div`
  height: 100%;
`;

const StyledModalBodyContainer = styled(ModalBodyContainer)<{
  hasSideBody: boolean;
}>`
  & > div {
    // Right side
    width: 440px;
    &:first-child {
      // Left side
      width: 480px;
    }
  }
`;
