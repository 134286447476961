import styled from 'styled-components';

export const EditButton = styled.div`
  align-items: center;
  border: 0.5px solid ${({ theme }) => theme.colors.colorCalendarBlue};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.colorCalendarBlue};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  padding: 9px 0;

  &:not(.disabled):hover {
    background-color: ${({ theme }) => theme.colors.colorIceBlue};
  }

  &.disabled {
    background-color: ${({ theme }) => theme.colors.colorPaleGray10};
    border-color: ${({ theme }) => theme.colors.colorCalendarGray};
    color: ${({ theme }) => theme.colors.colorCalendarGray};
    cursor: not-allowed;
  }
`;
