import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import theme from 'theme';
import HomeDarkIcon from 'images/home-dark.svg';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

interface GetWorkloadCapacityTooltipProps {
  isWorkingRemotely: boolean;
  capacity: number;
  planned: number;
  available: number;
  pto: number;
  holiday: number;
  timeEntry: number;
}

export const getWorkloadCapacityTooltip = ({
  isWorkingRemotely,
  capacity,
  planned,
  available,
  pto,
  holiday,
  timeEntry
}: GetWorkloadCapacityTooltipProps) => {
  const formattedCapacity = formatNumWithMaxOneDecimal(capacity);
  const formattedPlanned = formatNumWithMaxOneDecimal(planned);
  const formattedAvailable = formatNumWithMaxOneDecimal(available);
  const formattedPTO = formatNumWithMaxOneDecimal(pto);
  const formattedTimeEntry = formatNumWithMaxOneDecimal(timeEntry);
  const formattedHoliday = formatNumWithMaxOneDecimal(holiday);

  return ReactDOMServer.renderToString(
    <WorkloadCapacityTooltipContainer>
      {isWorkingRemotely && (
        <Header>
          <WFHIcon src={HomeDarkIcon} />
          Working Remotely
        </Header>
      )}

      <RowsContainer>
        {timeEntry > 0 && (
          <>
            <Label>TIMESHEET</Label>
            <Value>{formattedTimeEntry}h</Value>
            <StyledDivider />
          </>
        )}
        <Label>CAPACITY</Label>
        <Value>{formattedCapacity}h</Value>
        <Label>PLANNED</Label>
        <Value>{formattedPlanned}h</Value>
        {pto > 0 && (
          <>
            <Label>PTO</Label>
            <Value>{formattedPTO}h</Value>
          </>
        )}
        {holiday > 0 && (
          <>
            <Label>HOLIDAY</Label>
            <Value>{formattedHoliday}h</Value>
          </>
        )}
        <StyledDivider />
        <Label>AVAILABLE</Label>
        <Value>{formattedAvailable}h</Value>
      </RowsContainer>
    </WorkloadCapacityTooltipContainer>
  );
};

const WorkloadCapacityTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 15px;
  align-self: center;
  margin-bottom: 5px;
`;

const RowsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-self: center;
  gap: 0 28px;
  padding: 0 15px;
`;

const StyledDivider = styled.hr`
  background: ${theme.colors.colorLightGray6};
  margin: 3px 0 0;
  grid-column: 1 / -1;
`;

const Label = styled.div`
  padding-left: 10px;
  justify-self: start;
`;

const Value = styled.div`
  padding-right: 10px;
  justify-self: end;
  font-weight: 600;
`;

const WFHIcon = styled.img`
  transform: scale(0.85);
  position: relative;
  bottom: 2px;
`;
