import React from 'react';
import Highlighter from 'react-highlight-words';
import autosize from 'autosize';
import DeleteModal from '../taskUtilityComponents/DeleteModal';
import TextareaAutosize from 'react-autosize-textarea';
import Tribute from 'tributejs';
import { createAddNewTagTemplate } from 'appUtils/tributeUtils';
import { TaskPencil, TaskCommentBubble } from '../..';

class TaskDescriptionWithTags extends React.Component {
  state = {
    taskTitle: this.props.description,
    modalOpen: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { description } = this.props;

    if (nextProps.description !== description) {
      this.setState({ taskTitle: nextProps.description });
    }
  }

  componentDidUpdate(prevProps) {
    const { isEditingDescription, isTaskModal } = this.props;

    if (!prevProps.isEditingDescription && isEditingDescription) {
      document.querySelector('.description-input').focus();
      if (isTaskModal) {
        autosize(document.querySelector('.description-input'));
        this.initTributeTagging();
      }
    }
  }

  initTributeTagging = () => {
    const { allTags } = this.props;
    this.tribute = new Tribute({
      selectClass: 'dropdown-highlight',
      onSelectItem: this.onSelectTag,
      noMatchTemplate: createAddNewTagTemplate,
      containerClass: 'large-text',
      values: allTags
    });

    this.tribute.attach(this.textarea);
  };

  onSelectTag = () => {
    // tag autocomplete bypasses setState- we update state with this hook
    this.setState({ taskTitle: this.textarea.value });
  };

  linkify = (body) => {
    if (body) {
      body = body.replace(
        /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi,
        function (url) {
          let full_url = url;
          if (!full_url.match('^https?://')) {
            full_url = 'http://' + full_url;
          }
          return '<a href="' + full_url + '" target="_blank">' + url + '</a>';
        }
      );
    }
    return body;
  };

  displayDescription = (fullDescription, searchWords) => {
    const { isTaskModal } = this.props;
    const shouldTruncate = !isTaskModal && fullDescription.length > 80;
    const renderedDescription = shouldTruncate
      ? fullDescription.slice(0, 80).concat('...')
      : fullDescription;
    const descriptionWordsArray = renderedDescription.split(' ');
    const descriptionArray = [];

    descriptionWordsArray.map((word, index) => {
      if (word[0] === '#' && word !== '#') {
        descriptionArray.push(
          <span key={index + 1} className="task-tag">
            <Highlighter
              highlightClassName="highlight"
              highlightTag="span"
              searchWords={searchWords}
              textToHighlight={word.substring(1)}
            />
          </span>
        );
      } else if (
        word.match(
          /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi
        )
      ) {
        let full_url = word;
        if (!full_url.match('^https?://')) {
          full_url = 'http://' + full_url;
        }
        descriptionArray.push(
          <a
            key={index + 1}
            className="description-link"
            href={full_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {word}
          </a>
        );
      } else {
        descriptionArray.push(
          <span key={index + 1}>
            <Highlighter
              highlightClassName="highlight"
              highlightTag="span"
              searchWords={searchWords}
              textToHighlight={word}
            />
          </span>
        );
      }
      descriptionArray.push(<span key={(index + 1) * -1}> </span>);
    });
    return descriptionArray;
  };

  displayPencilAndBubble = () => {
    const {
      isEditingDescription,
      setEditTaskId,
      task,
      toggleTaskModal,
      isDragActive
    } = this.props;

    const shouldShow = !isDragActive && !isEditingDescription;
    return (
      shouldShow && (
        <div className="task-icons-container">
          <TaskPencil setEditTaskId={setEditTaskId} taskId={task.id} />
          <TaskCommentBubble
            totalComments={task.total_comments}
            unviewed={task.unviewed}
            taskId={task.id}
            toggleTaskModal={toggleTaskModal}
          />
        </div>
      )
    );
  };

  onChangeTitle = (e) => {
    this.setState({
      taskTitle: e.target.value
    });
  };

  updateDescriptionAndBlur = () => {
    const { updateDescription, description } = this.props;
    const { taskTitle } = this.state;

    if (!taskTitle.length) {
      this.openModal();
    } else if (taskTitle !== description) {
      updateDescription({ description: taskTitle });
    }
  };

  handleEnterPress = (e) => {
    const { updateDescription, closeDescription, description } = this.props;
    if (e.keyCode === 13 && this.state.taskTitle !== description) {
      updateDescription({ description: this.state.taskTitle });
      closeDescription();
    }
  };

  moveCaretToEnd = (e) => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  toggle = () => {
    this.setState({ modalOpen: false, taskTitle: this.props.description });
    this.props.setEditTaskId(null);
  };

  delete = () => {
    const { updateDescription, description } = this.props;
    updateDescription({ description: '' });
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      description,
      style,
      isEditingDescription,
      searchWords,
      isTaskModal
    } = this.props;
    const { modalOpen } = this.state;
    return (
      <div className="task-description">
        {!isTaskModal && (
          <DeleteModal
            isOpen={this.state.modalOpen}
            component={'task'}
            toggle={this.toggle}
            deleteOnClick={this.delete}
          />
        )}
        {isEditingDescription ? (
          <TextareaAutosize
            innerRef={(ref) => (this.textarea = ref)}
            className="description-input"
            onFocus={this.moveCaretToEnd}
            value={this.state.taskTitle}
            onChange={this.onChangeTitle}
            onBlur={this.updateDescriptionAndBlur}
            onKeyDown={this.handleEnterPress}
            spellCheck={false}
          />
        ) : (
          !!description && (
            <div
              className="description-wrapper"
              style={{ visibility: `${modalOpen ? 'hidden' : 'visible'}` }}
            >
              {this.displayDescription(description, searchWords)}
              {this.displayPencilAndBubble()}
            </div>
          )
        )}
      </div>
    );
  }
}

export default TaskDescriptionWithTags;
