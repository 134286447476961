import React, { useMemo, useEffect } from 'react';
import cn from 'classnames';
import {
  getRemainingWorkDays,
  getWorkdaysWithBaselineTooltipContent
} from 'appUtils/phaseDisplayUtils';
import moment from 'moment';
import styled from 'styled-components';
import BaselineIcon from 'icons/BaselineIcon';
import theme from 'theme';

const BaselineContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: relative;
  top: -5px;
  text-align: center;
  font-size: 12px;
  width: 100%;
  color: ${(props) =>
    (props.difference &&
      (props.difference > 0
        ? theme.colors.colorCalendarRed
        : theme.colors.colorLightGreen3)) ||
    theme.colors.colorLightGray15}};

  svg {
    path {
      stroke: ${(props) =>
        props.difference &&
        (props.difference > 0
          ? theme.colors.colorCalendarRed
          : theme.colors.colorLightGreen3)};
    }
  }
`;

const MilestoneWorkDaysInput = ({
  phase,
  handleChange,
  newWorkDays,
  checkForEnterPress,
  handleBlur,
  isPhaseMilestone
}) => {
  const { name, is_complete, total_work_days, start_date, end_date, baseline } =
    phase || {};
  const displayWorkDays =
    newWorkDays !== null ? newWorkDays : phase ? total_work_days : '';
  const remainingWorkDays = getRemainingWorkDays(phase);
  const shouldDisplayRemaining = useMemo(
    () => moment().isBetween(start_date, end_date) && remainingWorkDays,
    [end_date, remainingWorkDays, start_date]
  );

  const toolTipContent = getWorkdaysWithBaselineTooltipContent({
    remainingWorkdays: remainingWorkDays,
    totalWorkdays: newWorkDays,
    baseline: baseline?.total_work_days
  });

  const baselineExpectedWorkdaysDiff =
    newWorkDays - baseline?.total_work_days || 0;

  return isPhaseMilestone ? (
    <div
      className={cn('milestone-input milestone-work-days-input', {
        'is-complete': is_complete
      })}
      style={{ fontSize: '15px' }}
      data-html
      data-for="app-tooltip"
      data-effect="solid"
      data-class="phase-display-workday-tooltip"
      data-tip={toolTipContent}
    >
      {shouldDisplayRemaining ? `${remainingWorkDays}/` : ''}
      {newWorkDays && (
        <>
          {`${newWorkDays}d`}
          <BaselineContainer difference={baselineExpectedWorkdaysDiff}>
            <BaselineIcon width={12} height={12} />
            {baseline?.total_work_days || ''}
          </BaselineContainer>
        </>
      )}
    </div>
  ) : (
    <input
      className={cn('milestone-input milestone-work-days-input', {
        'is-complete': is_complete
      })}
      type="text"
      onKeyDown={checkForEnterPress}
      onChange={handleChange}
      onBlur={handleBlur}
      value={displayWorkDays}
    />
  );
};

export default MilestoneWorkDaysInput;
