import mapValues from 'lodash/mapValues';
import { put, select, take } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import moment from 'moment';
import * as constants from 'appConstants';

import {
  getAuthToken,
  getLocation,
  getOnTaskDetailModal,
  getDayPlannerDate
} from 'selectors';
import * as actionCreators from 'actionCreators';
import {
  fetchDayPlanner,
  downloadUrl,
  fetchProjectTaskGroups as fetchProjectTaskGroupsActionCreator
} from 'actionCreators';
const {
  fetchTask,
  taskUpdatePosition,
  commentsAndMetadataFetch,
  commentCreate,
  commentUpdate,
  commentDelete,
  tasksBulkEdit,
  homeTasksBulkEdit,
  gettingTasks,
  exportingTasks,
  fetchTaskGroups: fetchTaskGroupsEntityAction,
  fetchProjectTaskGroups: fetchProjectTaskGroupsEntityAction,
  createProjectTaskGroup: createProjectTaskGroupEntityAction,
  updateProjectTaskGroup: updateProjectTaskGroupEntityAction,
  deleteProjectTaskGroup: deleteProjectTaskGroupEntityAction,
  reorderProjectTaskGroups: reorderProjectTaskGroupsEntityAction
} = entityActions;

export function* fetchSingleTask(action) {
  const token = yield select(getAuthToken);

  const { projectId, isPersonal } = action.payload.params;
  let taskId = action.payload.params.taskId;
  const tempIds = yield select((state) => state.homeTasks.tempIds);
  if (tempIds[taskId]) {
    const success = yield take(
      (action) =>
        action.type === constants.CREATE_TASK_FROM_HOME.SUCCESS &&
        action.payload.requestPayload[1]?.id === taskId
    );
    taskId = success.payload.response.task.id;
  }
  const { error } = yield fetchEntity(
    fetchTask,
    api.fetchSingleTask,
    undefined,
    [token, taskId, projectId, isPersonal],
    action
  );
}

export function* updateTaskPosition(action) {
  const {
    token,

    taskType,
    isCompleted,
    wasCompleted,
    taskPosition,
    isDailyTask,
    circleClicked,
    scheduleStartDate,
    dateKey
  } = action.payload.params;
  // Fetch Redux Values:
  const dayPlannerDate = yield select(getDayPlannerDate);
  const path = yield select(getLocation);
  const isOnHome = path.includes('home');
  const isOnPlanner = path.includes('planner');

  const onTaskDetailModal = yield select(getOnTaskDetailModal);

  // PUT task
  const tempIds = yield select((state) => state.homeTasks.tempIds);
  let taskId = action.payload.params.taskId;
  if (tempIds[taskId]) {
    const success = yield take(
      (action) =>
        action.type === constants.CREATE_TASK_FROM_HOME.SUCCESS &&
        action.payload.requestPayload[1]?.id === taskId
    );
    taskId = success.payload.response.task.id;
  }

  const { error, response } = yield changeEntity(
    taskUpdatePosition,
    api.updateTaskPosition,
    [token, taskId, isCompleted, taskPosition, wasCompleted, onTaskDetailModal]
  );

  // No reason to refetch from DB after task is completed.
  if (!error && circleClicked && isOnHome) {
    // Update day planner after completing task.
    yield put(
      fetchDayPlanner(token, {
        date: isOnPlanner && dateKey ? scheduleStartDate : dayPlannerDate
      })
    );
  }

  // Moving home tasks
  const isNotPersonalType =
    taskType === 'followed' ||
    (taskType === 'projects' && path.includes('home'));

  if (isDailyTask) {
    yield put(fetchDayPlanner(token, { date: dayPlannerDate }));

    if (!error && isNotPersonalType && isOnHome) {
      yield put(
        fetchDayPlanner(token, {
          date: isOnPlanner ? scheduleStartDate : dayPlannerDate
        })
      );
    }
  }
}

export function* fetchTaskGroups(action) {
  const { taskGroupIds } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield fetchEntity(
    fetchTaskGroupsEntityAction,
    api.fetchTaskGroups,
    undefined,
    [token, taskGroupIds],
    action
  );
}
export function* fetchProjectTaskGroups(action) {
  const { projectId } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield fetchEntity(
    fetchProjectTaskGroupsEntityAction,
    api.fetchProjectTaskGroups,
    projectId,
    [token],
    action
  );
}
export function* createProjectTaskGroup(action) {
  const { projectId, name, index } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    createProjectTaskGroupEntityAction,
    api.createProjectTaskGroup,
    [token, projectId, name, index],
    action
  );
}
export function* updateProjectTaskGroup(action) {
  const { projectId, name, id } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    updateProjectTaskGroupEntityAction,
    api.updateProjectTaskGroup,
    [token, id, name, projectId],
    action
  );
}
export function* reorderProjectTaskGroups(action) {
  const { projectId, order } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    reorderProjectTaskGroupsEntityAction,
    api.reorderProjectTaskGroups,
    [token, projectId, order],
    action
  );
}

const getProjectTaskState = (state) => state.projectTaskGroups;
export function* deleteProjectTaskGroup(action) {
  const { id, projectId } = action.payload;
  const token = yield select(getAuthToken);

  const { error } = yield changeEntity(
    deleteProjectTaskGroupEntityAction,
    api.deleteProjectTaskGroup,
    [token, id],
    action
  );
  const { taskGroupOrders } = yield select(getProjectTaskState);
  if (taskGroupOrders[projectId].length === 0) {
    yield put(fetchProjectTaskGroupsActionCreator({ projectId }));
  }
}

const getDateParams = ({ schedule_start, schedule_end, due_at }) => ({
  schedule_start: schedule_start && moment(schedule_start).format('YYYY-MM-DD'),
  schedule_end: schedule_end && moment(schedule_end).format('YYYY-MM-DD'),
  due_at: due_at && moment(due_at).format('YYYY-MM-DD')
});
const getBulkDateParams = (tasks) => mapValues(tasks, getDateParams);

export function* bulkEditTasks(action) {
  const { token, project_tasks } = action.payload;

  const { error } = yield changeEntity(tasksBulkEdit, api.bulkEditTasks, [
    token,
    getBulkDateParams(project_tasks)
  ]);
}
export function* bulkEditHomeTasks(action) {
  const { token, tasks } = action.payload;
  const { error } = yield changeEntity(homeTasksBulkEdit, api.bulkEditTasks, [
    token,
    getBulkDateParams(tasks)
  ]);
}

export function* fetchCommentsAndMetadata(action) {
  const { taskId, taskType, offset, limit } = action.payload;
  const token = yield select(getAuthToken);
  const tempId = yield select((state) => state.homeTasks.tempId);
  let successTaskId;
  if (taskId === tempId) {
    const success = yield take(
      (action) =>
        action.type === constants.CREATE_TASK_FROM_HOME.SUCCESS &&
        action.payload.requestPayload[1]?.id === tempId
    );
    successTaskId = success.payload.response.task.id;
  }
  const { error, response } = yield fetchEntity(
    commentsAndMetadataFetch,
    api.getCommentsAndMetadata,
    successTaskId || taskId,
    [token, taskType, offset, limit],
    action
  );
}

export function* createComment(action) {
  const { token, taskId, body, parentId, accountId, files, membersToNotify } =
    action.payload;
  const tempId = yield select((state) => state.homeTasks.tempId);
  let successTaskId;
  if (taskId === tempId) {
    const success = yield take(
      (action) =>
        action.type === constants.CREATE_TASK_FROM_HOME.SUCCESS &&
        action.payload.requestPayload[1]?.id === tempId
    );
    successTaskId = success.payload.response.task.id;
  }

  const { error, response } = yield changeEntity(
    commentCreate,
    api.postComment,
    [
      token,
      successTaskId || taskId,
      body,
      parentId,
      accountId,
      files,
      membersToNotify
    ]
  );
}

export function* updateComment(action) {
  const { token, taskCommentId, body, files } = action.payload;
  const { error, response } = yield changeEntity(
    commentUpdate,
    api.putComment,
    [token, taskCommentId, body, files]
  );
}

export function* deleteComment(action) {
  const { token, taskCommentId } = action.payload;
  const { error, response } = yield changeEntity(
    commentDelete,
    api.deleteComment,
    [token, taskCommentId]
  );
}

export function* fetchTasksV2(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    gettingTasks,
    api.fetchTasksV2,
    undefined,
    [token, action.payload.body],
    action
  );
}

export function* exportTasks(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    exportingTasks,
    api.fetchTasksV2,
    undefined,
    [token, { ...action.payload, export: true }],
    action
  );
  if (response.url) {
    yield put(downloadUrl({ url: response.url }));
  }
}
