import { ActivityLogsMenuTabPanel } from './activityMenu/ActivityMenuTabPanel';
import { CommentMenuTabPanel } from './commentMenu/CommentMenuTabPanel';
import { ScopeMenuTabPanel } from './scopeMenu/ScopeMenuTabPanel';
import { WorkPlanSubMenuTabs } from './SidebarPanel';
import { TaskMenuTabPanel } from './taskMenu/TaskMenuTabPanel';

const tabPanelMap: Record<
  WorkPlanSubMenuTabs,
  ({ providerType: string }) => JSX.Element
> = {
  comments: CommentMenuTabPanel,
  scope: ScopeMenuTabPanel,
  tasks: TaskMenuTabPanel,
  activity: ActivityLogsMenuTabPanel
};

interface SidebarTabPanelProps {
  currentTab: WorkPlanSubMenuTabs;
  providerType: string;
}

export const SidebarTabPanel = ({
  currentTab,
  providerType
}: SidebarTabPanelProps) => {
  const PanelComponent = tabPanelMap[currentTab];

  if (!PanelComponent) return <></>;
  return <PanelComponent providerType={providerType} />;
};
