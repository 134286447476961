import React, { Component } from 'react';
import Trigger from 'rc-trigger';

import ProjectMenu from './ProjectMenu';

function noop() {}

const shouldComponentUpdateProps = [
  'task',
  'showDeselect',
  'selectedTaskIds',
  'isSelectedHomeTask',
  'target',
  'project',
  'taskGroup',
  'taskIsEditing',
  'modularTaskProperties'
];
const shouldComponentUpdateState = ['open', 'groupsVisible'];

class ProjectSelectionPopover extends Component {
  state = {
    open: false,
    groupsVisible: false
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      shouldComponentUpdateProps.some(
        (prop) => nextProps[prop] !== this.props[prop]
      ) ||
      shouldComponentUpdateState.some(
        (state) => nextState[state] !== this.state[state]
      )
    );
  };

  onProjectSelect = (
    projectId,
    groupId,
    phaseId,
    activityId,
    activityPhaseId
  ) => {
    const { onSelect } = this.props;
    onSelect(projectId, groupId, phaseId, activityId, activityPhaseId);
  };

  setVisibility = (open) => {
    const { onOpenChange } = this.props;
    if (this.state.open !== open) {
      if (!('open' in this.props)) {
        this.setState({
          open
        });
      }
      onOpenChange(open);
    }
  };

  onKeyDown = (event) => {
    const { taskGroupTriggerRef } = this.props;
    if (!this.state.open) {
      this.setState({
        groupsVisible: taskGroupTriggerRef?.current?.contains?.(event.target)
      });

      this.open();
      event.preventDefault();
    }
  };

  open = () => {
    this.setVisibility(true);
  };

  close = () => {
    this.setVisibility(false);
    this.setState({ groupsVisible: false });
  };

  render() {
    const { open, groupsVisible } = this.state;
    const {
      children,
      disabled,
      popupAlign,
      headerText,
      header,
      task,
      selectedBatchTaskIds,
      target,
      isCopy,
      isMove,
      isVirtualList,
      popoverClassName,
      shouldUseOldProjectDropdown = false
    } = this.props;
    const projectMenu = (
      <ProjectMenu
        selectedBatchTaskIds={selectedBatchTaskIds}
        onSelect={this.onProjectSelect}
        setVisibility={this.setVisibility}
        groupsVisible={groupsVisible}
        headerText={headerText}
        header={header}
        task={task}
        projectId={task?.project_id}
        target={target}
        isCopy={isCopy}
        isMove={isMove}
        isVirtualList={isVirtualList}
        disableProjectSelection
        shouldUseOldProjectDropdown={shouldUseOldProjectDropdown}
        hidePTO
        popoverClassName={popoverClassName}
      />
    );

    return (
      <Trigger
        action={disabled && !open ? [] : ['click']}
        destroyPopupOnHide
        popupVisible={open}
        popup={projectMenu}
        onPopupVisibleChange={this.setVisibility}
        popupAlign={popupAlign}
      >
        {React.cloneElement(children(this.state, this.props), {
          onClick: this.onKeyDown
        })}
      </Trigger>
    );
  }
}

const defaultPopupAlign = {
  points: ['tl', 'bl'],
  offset: [0, 3]
};

ProjectSelectionPopover.defaultProps = {
  onOpenChange: noop,
  headerText: '',
  disabled: false,
  popupAlign: defaultPopupAlign
};

export default ProjectSelectionPopover;
