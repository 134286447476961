import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';
interface AdditionalParams {
  existingPhaseBudgetStatusOptions: string[];
}

export const phaseBudgetStatusOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ additionalParams }) => {
  const { existingPhaseBudgetStatusOptions } = additionalParams;

  // return all the possible phase budget status options
  return existingPhaseBudgetStatusOptions;
};
