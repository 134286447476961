import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TeamsIcon = ({ className, height = '28', width = '28' }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="transparent"
    viewBox="0 0 28 28"
  >
    <path
      d="M14.1971 7.90422L14.1961 7.90317C13.9393 7.64484 13.59 7.49971 13.2257 7.5L13.2254 7.5L6.53335 7.5C5.77856 7.5 5.16669 8.11188 5.16669 8.86667V20.0667C5.16669 20.8215 5.77856 21.4333 6.53335 21.4333H21.4667C22.2215 21.4333 22.8334 20.8215 22.8334 20.0667V10.7333C22.8334 9.97855 22.2215 9.36667 21.4667 9.36667H16.6417H16.6414V8.86667C16.1438 8.86706 15.6668 8.66884 15.316 8.316L14.1971 7.90422ZM14.1971 7.90422L14.9614 8.6685L14.1971 7.90422Z"
      stroke="#4F4F4F"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="7"
          height="9.625"
          fill="white"
          transform="translate(10.5 10.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default TeamsIcon;
