import * as constants from 'appConstants';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

export const initialState = {
  isOpen: false,
  modalType: null, // type from MODAL_TYPE or null
  project: null,
  bulkAddIsOpen: false,
  bulkAddIsPopulated: false,
  formLocation: null
};

const addMembersForm = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.DELETE_MEMBER_FROM_PROJECT.TRIGGER:
      if (
        state.isOpen &&
        state.project?.id === action.payload.projectId &&
        state.project?.project_membership &&
        state.modalType === MODAL_TYPE.PROJECT
      ) {
        return {
          ...state,
          project: {
            ...state.project,
            project_membership: state.project.project_membership.filter(
              (member) => {
                return member.account?.id !== action.payload.accountId;
              }
            )
          }
        };
      } else return state;
    case constants.OPEN_ADD_MEMBERS_MODAL: {
      const { modalType, project, formLocation } = payload;
      return {
        ...state,
        isOpen: true,
        bulkAddIsOpen: false,
        modalType,
        project,
        formLocation
      };
    }
    case constants.CLOSE_ADD_MEMBERS_MODAL:
      return {
        ...state,
        project: null,
        isOpen: false,
        bulkAddIsOpen: false,
        modalType: null,
        formLocation: null
      };
    case constants.OPEN_BULK_ADD_MEMBERS_MODAL: {
      return {
        ...state,
        bulkAddIsOpen: true
      };
    }
    case constants.CLOSE_BULK_ADD_MEMBERS_MODAL:
      return {
        ...state,
        bulkAddIsOpen: false,
        bulkAddIsPopulated: false
      };
    case constants.POPULATE_BULK_ADD_MEMBERS:
      return {
        ...state,
        bulkAddIsPopulated: true
      };
    case constants.EMPTY_BULK_ADD_MEMBERS:
      return {
        ...state,
        bulkAddIsPopulated: false
      };
    case constants.FETCH_PROJECT_MEMBERS.SUCCESS:
      if (state.modalType === MODAL_TYPE.PROJECT) {
        return {
          ...state,
          project: {
            ...state.project,
            project_membership: payload.response.project_members
          }
        };
      } else return state;

    default:
      return state;
  }
};

export default addMembersForm;
