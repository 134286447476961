import { useMount } from 'react-use';
import styled from 'styled-components';
import theme from 'theme';
import { useSecurity } from './hooks/useSecurity';
import { MfaSection } from './uiSections/MfaSection';
import { SamlSection } from './uiSections/SamlSection';

export const SecuritySettings = () => {
  const {
    isMfaFeatureFlagEnabled,
    isSamlFeatureFlagEnabled,
    getMfaData,
    getTeamInformation,
    getSamlData
  } = useSecurity();

  useMount(() => {
    getTeamInformation();

    if (isMfaFeatureFlagEnabled) {
      getMfaData();
    }

    if (isSamlFeatureFlagEnabled) {
      getSamlData();
    }
  });

  return (
    <RootContainer>
      <HeadingText>Security</HeadingText>
      <MainContent>
        {isMfaFeatureFlagEnabled && <MfaSection />}
        {isSamlFeatureFlagEnabled && <SamlSection />}
      </MainContent>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  width: 100%;
  max-width: 950px;
`;

const HeadingText = styled.div`
  font-size: 26px;
  color: ${theme.colors.colorMediumGray9};
  font-weight: 600;
  margin-bottom: 56px;
`;

const MainContent = styled.div`
  margin-left: 25px;
  display: grid;
  gap: 80px;
`;
