import React from 'react';
import {
  ColorDot,
  StyledBudgetTotal
} from 'views/projectPlanner/workloadBarModal/styles';
import theme from 'theme';
import * as constants from 'appConstants';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import CircledDot from 'icons/CircledDot';

const calcColor = {
  estimated: theme.colors.colorMediumGray2,
  spent: theme.colors.colorBudgetGreen,
  planned: theme.colors.colorPlannedHours,
  remaining: theme.colors.colorBudgetLightGreen
};
const BudgetTotal = ({ dataTip, totalType, total = 0 }) => {
  return (
    <StyledBudgetTotal
      data-tip={dataTip}
      data-for="app-tooltip"
      data-html
      data-testid={'budget-' + totalType}
    >
      {totalType === 'estimated' ? (
        <CircledDot style={{ marginRight: '4px' }} />
      ) : (
        <ColorDot cellColor={calcColor[totalType]} />
      )}{' '}
      {formatNumWithMaxOneDecimal(total)}h
    </StyledBudgetTotal>
  );
};
const ListItemBudgetTotals = ({ width, totals }) => {
  return (
    <div style={{ width, display: 'flex', justifyContent: 'space-between' }}>
      <BudgetTotal
        dataTip={constants.TIME_MEMBER_BUDGET_TOOLTIP}
        total={totals.estimated_hours}
        totalType="estimated"
      />
      <BudgetTotal
        total={totals.spent_hours}
        dataTip={constants.SPENT_TOOLTIP}
        totalType="spent"
      />
      <BudgetTotal
        total={totals.planned_hours}
        dataTip={constants.PLANNED_TOOLTIP}
        totalType="planned"
      />
      <BudgetTotal
        total={totals.remaining_hours}
        dataTip={constants.MEMBERS_REMAINING_TOOLTIP}
        totalType="remaining"
      />
    </div>
  );
};

export default ListItemBudgetTotals;
