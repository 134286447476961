import styled from 'styled-components';
import theme from 'theme';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';

export const Button = styled.button`
  padding: 5px;
  width: 100px;
  border-radius: 4px;
  color: #fff;
  background-color: ${theme.colors.colorRoyalBlue};
`;

export const ActivityModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 45px;
  align-items: flex-start;
`;

export const ActivityModalContainer = styled.div`
  padding: 0 45px 50px;
`;

export const ActivityModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActivityModalRow = styled.div`
  border-bottom: 1px solid ${theme.colors.colorPaleGray6};
  padding: 12px 20px;
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray2};
`;

export const ModalTitleHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${theme.colors.colorSemiDarkGray1};
`;

export const ModalContainerHeader = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid ${theme.colors.colorLightGray6};
`;

export const StyledActivityTimesheetIcon = styled(ActivityTimesheetIcon)`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  &:hover {
    circle {
      fill: ${theme.colors.colorLightGray5};
    }
  }
`;

export const StyledProjectInfo = styled.div`
  font-size: 18px;
  position: relative;
`;
export const StyledProjectDescription = styled.span`
  font-size: 16px;
  color: ${theme.colors.colorMediumGray5};
  font-weight: 400;
`;
export const StyledPhaseName = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.colorMediumGray5};
`;

export const StyledDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  position: absolute;
  top: 8px;
  left: -20px;
  background: ${calculateProjectColorPreference};
`;
export const StyledColorPicker = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  position: absolute;
  top: 8px;
  opacity: 0;
  left: -20px;
  z-index: 10;

  &:hover {
    opacity: 1;
  }
`;
