import React, { lazy, Suspense } from 'react';

const containerStyle = {
  paddingLeft: 32,
  flex: 1,
  minHeight: 0
};

const StandardHolidaysTableContainer = lazy(() =>
  import('./StandardHolidaysTable/StandardHolidaysTableContainer')
);

const StandardHolidaysTab = () => {
  return (
    <div style={containerStyle}>
      <Suspense fallback={<div />}>
        <StandardHolidaysTableContainer />
      </Suspense>
    </div>
  );
};

export default StandardHolidaysTab;
