import { useCallback } from 'react';
import { ROW_TYPES } from './layout';

export const useInlineCreateGroupRowBuilder = ({ onAddGroupInline }) => {
  const emptyRowBuilder = useCallback(() => {
    return {
      id: 'inlineCreateGroupRow',
      rowType: ROW_TYPES.inlineCreateGroupRow,
      rowHeight: 60,
      onAddGroupInline
    };
  }, [onAddGroupInline]);

  return emptyRowBuilder;
};
