import { useCallback, useMemo, useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useAppSelector } from 'reduxInfra/hooks';
import useUnassignedRolesByProjectAndPhases from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import {
  AddProjectMembershipRequirementListItem,
  ProjectMembershipRequirementListItem,
  ProjectMembershipRequirementListItemDataObject,
  ProjectUnassignedMemberBudgetWithRequirementsListItem
} from 'SuggestionModule/components/FindPeople/List/UnassignedRoles/types';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import cn from 'classnames';
import useProjectMembershipRequirementsItemsBuilder from './useProjectMembershipRequirementsItemsBuilder';
import { FindPeopleModalContextValuesType } from 'SuggestionModule/components/FindPeople/contexts/types';
import { TEAM_BUILDERS_REQUIREMENTS } from 'SuggestionModule/components/FindPeople/constants';
import { getRequirementAssociationsByEntityIdHash } from 'RequirementsModule/selectors/entityRequirements';
import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';
import useRequirementsHash from 'RequirementsModule/hooks/useRequirementsHash';

const parentId = 'main';

const makeRequirementDataObjectOrder = ({
  requirementsAssociations,
  allRequirementsHash,
  onDeleteRequirement,
  projectMembershipId
}) => {
  if (!requirementsAssociations) return [];

  return TEAM_BUILDERS_REQUIREMENTS.reduce((acc, requirement) => {
    const { makeRequirementDataObjectArray } = requirement;
    const requirementDataObjectArray = makeRequirementDataObjectArray({
      requirementsAssociations,
      allRequirementsHash,
      onDeleteRequirement,
      projectMembershipId
    });
    acc.push(...requirementDataObjectArray);
    return acc;
  }, [] as ProjectMembershipRequirementListItemDataObject[]);
};

const useProjectUnassignedRolesListBuilder = ({
  projectId,
  isFetchingEntityRequirements,
  handleSelectUnassignedRole,
  onAddRequirementsClick,
  onDeleteRequirement,
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState,
  toggleCollapseAll
}: UseNestedCollapseHookReturnedValues & {
  projectId: number;
  isFetchingEntityRequirements: FindPeopleModalContextValuesType['isFetchingEntityRequirements'];
  handleSelectUnassignedRole: FindPeopleModalContextValuesType['handleSelectUnassignedRole'];
  toggleRequirementsSelector: FindPeopleModalContextValuesType['toggleRequirementsSelector'];
  onAddRequirementsClick: FindPeopleModalContextValuesType['onAddRequirementsClick'];
  onDeleteRequirement: FindPeopleModalContextValuesType['onDeleteRequirement'];
}) => {
  const [collapseId, setCollapseId] = useState<Nullable<string>>(null);
  const [
    hoveredUnassignedMemberBudgetGroupId,
    setHoveredUnassignedMemberBudgetGroupId
  ] = useState<Nullable<string>>(null);

  const requirementsAssociationsByProjectMembershipId = useAppSelector(
    (state) =>
      getRequirementAssociationsByEntityIdHash(state, {
        entityRequirementType: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
      })
  );
  const allRequirementsHash = useRequirementsHash();

  const itemsBuilder = useProjectMembershipRequirementsItemsBuilder({
    getIsOpen,
    toggleCollapse,
    getCurrentParentCollapseState,
    setParentCollapseState,
    toggleCollapseAll
  });

  const {
    unassignedMemberBudgetsWithPositionForProject,
    getUnassignedMemberBudgetWithPositionForProject
  } = useUnassignedRolesByProjectAndPhases({
    projectId
  });

  // Spec: Select the first unassigned role and expand when a new project is selected
  useMount(() => {
    const firstMemberBudgetId =
      unassignedMemberBudgetsWithPositionForProject[0]?.id;

    if (firstMemberBudgetId) {
      const memberBudget = getUnassignedMemberBudgetWithPositionForProject({
        memberBudgetId: firstMemberBudgetId
      });
      if (memberBudget) {
        const projectMembership = memberBudget.project_membership;
        const projectMembershipId = projectMembership.id;
        const unassignedMemberBudgetGroupId = `project-membership-${projectMembershipId}-mb-${firstMemberBudgetId}`;
        handleSelectUnassignedRole({
          memberBudgetId: firstMemberBudgetId
        });
        setCollapseId(unassignedMemberBudgetGroupId);
      }
    }
  });

  const projectUnassignedRolesListBuilder = () => {
    const unassignedMemberBudgetIdsOrder =
      unassignedMemberBudgetsWithPositionForProject.map((mb) => mb.id);

    const list = unassignedMemberBudgetIdsOrder.reduce(
      (
        acc: ProjectUnassignedMemberBudgetWithRequirementsListItem[],
        memberBudgetId: number
      ) => {
        const memberBudget = getUnassignedMemberBudgetWithPositionForProject({
          memberBudgetId
        });

        if (memberBudget) {
          const projectMembership = memberBudget.project_membership;
          const projectMembershipId = projectMembership.id;
          const unassignedMemberBudgetGroupId = `project-membership-${projectMembershipId}-mb-${memberBudgetId}`;
          const isUnassignedMemberBudgetOpen = Boolean(
            collapseId === unassignedMemberBudgetGroupId
          );
          const requirementsAssociations =
            requirementsAssociationsByProjectMembershipId[projectMembershipId];

          const requirementDataObjectOrder = makeRequirementDataObjectOrder({
            requirementsAssociations,
            allRequirementsHash,
            onDeleteRequirement,
            projectMembershipId
          });

          const isHoveringOnGroup =
            hoveredUnassignedMemberBudgetGroupId ===
            unassignedMemberBudgetGroupId;

          const projectMembershipRequirementsArray = itemsBuilder({
            isParentOpen: isUnassignedMemberBudgetOpen,
            order: requirementDataObjectOrder,
            parentId: unassignedMemberBudgetGroupId,
            projectMembership,
            memberBudget,
            isFetchingEntityRequirements,
            isHoveringOnGroup,
            onAddRequirementsClick,
            setHoveredUnassignedMemberBudgetGroupId
          });

          // Add +
          const addProjectMembershipRequirement: AddProjectMembershipRequirementListItem =
            {
              id: `add-requirements-item-project-membership-${projectMembershipId}`,
              memberBudget,
              projectMembership,
              itemType: LIST_ITEM_TYPES.AddProjectMembershipRequirement,
              toggleId: undefined,
              parentId: unassignedMemberBudgetGroupId,
              toggleCollapse: undefined,
              isOpen: true,
              isParentOpen: isUnassignedMemberBudgetOpen,
              containerClassName: cn(
                'add-requirements-item-project-membership-list-item-container open',
                { 'is-hovering-on-group': isHoveringOnGroup }
              ),
              onMouseEnterContainer: () => {
                setHoveredUnassignedMemberBudgetGroupId(
                  unassignedMemberBudgetGroupId
                );
              },
              onMouseLeaveContainer() {
                setHoveredUnassignedMemberBudgetGroupId(null);
              },
              onAddRequirementsClick,
              setHoveredUnassignedMemberBudgetGroupId
            };

          const emptyItem = {
            id: `empty-item-${projectMembershipId}`,
            itemType: LIST_ITEM_TYPES.EmptyItem,
            isParentOpen: isUnassignedMemberBudgetOpen
          };

          const requirementCounts = projectMembershipRequirementsArray.length;

          const unassignedMemberBudgetListItem: ProjectUnassignedMemberBudgetWithRequirementsListItem =
            {
              memberBudget,
              id: unassignedMemberBudgetGroupId,
              listItems: [
                addProjectMembershipRequirement,
                ...projectMembershipRequirementsArray,
                emptyItem
              ],
              requirementCounts,
              itemType:
                LIST_ITEM_TYPES.ProjectUnassignedMemberBudgetWithRequirements,
              toggleId: unassignedMemberBudgetGroupId,
              isOpen: isUnassignedMemberBudgetOpen,
              isEmpty: false,
              containerClassName: cn(
                'unassigned-member-budget-list-item-container',
                {
                  open: isUnassignedMemberBudgetOpen,
                  closed: !isUnassignedMemberBudgetOpen,
                  'is-hovering-on-group': isHoveringOnGroup
                }
              ),
              onMouseEnterContainer: () => {
                setHoveredUnassignedMemberBudgetGroupId(
                  unassignedMemberBudgetGroupId
                );
              },
              onMouseLeaveContainer() {
                setHoveredUnassignedMemberBudgetGroupId(null);
              },
              setHoveredUnassignedMemberBudgetGroupId,
              toggleCollapse: () => {
                if (!isUnassignedMemberBudgetOpen) {
                  handleSelectUnassignedRole({
                    memberBudgetId
                  });
                }

                setCollapseId((prev) =>
                  unassignedMemberBudgetGroupId === prev
                    ? null
                    : unassignedMemberBudgetGroupId
                );
              }
            };

          acc.push(unassignedMemberBudgetListItem);
        }
        return acc;
      },
      []
    );

    return list;
  };

  return projectUnassignedRolesListBuilder;
};

export default useProjectUnassignedRolesListBuilder;
