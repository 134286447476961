import * as appConstants from 'appConstants';
import * as constants from '../constants';
import createIntegrationReducer from './createIntegrationReducer';
import keyBy from 'lodash/keyBy';
import { deserializeItem, makeIdHash } from 'appUtils';

const byId = (item) => item.id;

export const initialState = {
  integrationPhases: {},
  phaseMappings: {},
  selectedPhases: {},
  mappedMosaicIds: {},
  isLoading: false,
  count: 0,
  mappingSteps: {},
  linkingMosaicPhase: null,
  linkingIntegrationPhase: null
};
export const phases = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_INTEGRATION_PHASES.SUCCESS: {
      const phases = payload.response;
      return {
        ...state,
        integrationPhases: keyBy(phases, byId)
      };
    }
    case constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        mappedMosaicIds: makeIdHash(
          [
            ...(response[constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_TARGET] || []),
            ...(response[constants.MAPPING_STATUS.ACTIVE] || [])
          ].filter((id) => id)
        )
      };
    }
    case constants.SET_INTEGRATIONS_MAPPING_STEP: {
      const { id, mappingStep } = payload;
      const { itemType } = deserializeItem(id);
      if (!['phase', 'activity'].includes(itemType)) return state;

      return {
        ...state,
        mappingSteps: {
          // ...state.mappingSteps,  currently not allowing multiple edits
          [id]: mappingStep
        }
      };
    }
    case constants.CLEAR_INTEGRATIONS_MAPPING_STEP: {
      const { id } = payload;
      const { itemType } = deserializeItem(id);
      if (itemType !== 'phase') return state;

      const nextMappingSteps = { ...state.mappingSteps };
      delete nextMappingSteps[id];
      return {
        ...state,
        mappingSteps: nextMappingSteps
      };
    }
    case constants.CLEAR_PHASES_MAPPING_STEPS: {
      return {
        ...state,
        mappingSteps: {},
        linkingMosaicPhase: null,
        linkingIntegrationPhase: null
      };
    }
    case constants.SET_LINKING_INTEGRATION_PHASE: {
      return {
        ...state,
        linkingIntegrationPhase: payload.phase
      };
    }
    case constants.SET_LINKING_MOSAIC_PHASE: {
      return {
        ...state,
        linkingMosaicPhase: payload.phase
      };
    }
    case constants.CLEAR_LINKING_PHASES: {
      return {
        ...state,
        linkingMosaicPhase: null,
        linkingIntegrationPhase: null
      };
    }
    case constants.MAP_PHASES.TRIGGER: {
      return {
        ...state,
        mappingSteps: {},
        linkingMosaicPhase: null,
        linkingIntegrationPhase: null
      };
    }
    case constants.MAP_ACTIVITY_PHASES.TRIGGER: {
      return {
        ...state,
        mappingSteps: {}
      };
    }

    default: {
      return state;
    }
  }
};

export default createIntegrationReducer(phases, initialState);
