import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import {
  GenerateTeamSamlInfoParams,
  AssociateSamlIdentityParams,
  SetAuthMethodParams,
  ResetPasswordParams
} from './types';
import { callApi } from 'appUtils/api';

const generateTeamSamlInfo = (
  token: string,
  { teamId, samlIdp }: GenerateTeamSamlInfoParams
) => {
  const body = convertKeysToSnakeCase({ teamId, samlIdp });
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('auth/get_team_saml_info', token, fetchInit);
};

const associateSamlIdentity = (
  token: string,
  {
    certificate,
    domain,
    entryPoint,
    samlIdp,
    teamId
  }: AssociateSamlIdentityParams
) => {
  const body = convertKeysToSnakeCase({
    certificate,
    domain,
    teamId,
    samlIdp,
    entryPoint
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('auth/associate_saml_idp', token, fetchInit);
};

const setAuthMethod = (
  token: string,
  { teamId, authMethod }: SetAuthMethodParams
) => {
  const body = convertKeysToSnakeCase({ teamId, authMethod });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('auth/set_auth_method', token, fetchInit);
};

const resetPassword = ({ email }: ResetPasswordParams) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  };

  return callApi('reset_password', null, fetchInit);
};

export const authenticationApi = {
  generateTeamSamlInfo,
  associateSamlIdentity,
  setAuthMethod,
  resetPassword
};
