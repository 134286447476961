import React from 'react';
import cn from 'classnames';

import styled from 'styled-components';
import CloseXIcon from 'icons/CloseXIcon';

import FolderIcon from 'icons/FolderIcon';
import BoardCreateButton, { StyledAddFolderIcon } from './BoardCreateButton';

const BoardSelectMenuWrapper = styled.div``;

const CloseModal = styled.span`
  position: absolute;
  right: -12px;
  color: #b3b3b3;
  font-size: 12px;
  cursor: pointer;
`;

const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 10px;
  width: 26px;
  height: 18px;

  path {
    stroke: ${({ theme }) => theme.colors.colorSemiDarkGray1};
    stroke-width: 2;
  }
`;

const TitleInput = styled.div`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 15px;
  color: inherit;
  line-height: 15px;
  cursor: pointer;
`;
const StyledHeader = styled.div`
  color: ${(props) => props.theme.colors.colorPureBlack};
  font-size: 26px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  position: relative;
`;

const StyledBody = styled.div`
  height: 350px;
  overflow-y: auto;
  /*
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    color: transparent;
  } */
`;

const BoardItemContents = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  font-size: 15px;
  cursor: pointer;
  margin-top: ${(props) => (props.personal ? '10px' : '0px')};
  .lock-icon {
    position: relative;
    margin-left: 3px;
  }

  ${StyledAddFolderIcon} {
    height: 22px;
    width: 31px;
  }
`;

const TeamHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  color: ${(props) => props.theme.colors.colorSemiDarkGray3};
  padding: 2px 20px 2px;
  margin-top: 10px;
`;

const BoardSelectMenu = ({
  openAddEditGroupModal,
  setSelectedBoard,
  boards = [],
  personalBoardId,
  toggleModal,
  teamName
}) => (
  <BoardSelectMenuWrapper>
    <StyledHeader>
      Select Portfolio
      <CloseModal onClick={toggleModal}>
        <CloseXIcon fill={'#000'} width="40" height="30" />
      </CloseModal>
    </StyledHeader>
    <StyledBody>
      <BoardItemContents
        key={0}
        className="board-item-contents"
        personal={true}
        onClick={() =>
          setSelectedBoard({
            id: personalBoardId,
            name: 'Personal',
            is_private: false
          })
        }
      >
        <StyledFolderIcon />
        <TitleInput>{`Home (Personal Project)`}</TitleInput>
        <div
          className={cn('lock-icon', {
            visible: false
          })}
        />
      </BoardItemContents>
      <TeamHeader>{teamName}</TeamHeader>
      <BoardItemContents>
        <BoardCreateButton />
      </BoardItemContents>
      {boards.map((board) => (
        <BoardItemContents
          key={board.id}
          className="board-item-contents"
          onClick={() => setSelectedBoard(board)}
        >
          <StyledFolderIcon />
          <TitleInput>{board.name} </TitleInput>
          <div
            className={cn('lock-icon', {
              visible: board && board.is_private
            })}
          />
        </BoardItemContents>
      ))}
    </StyledBody>
  </BoardSelectMenuWrapper>
);

export default BoardSelectMenu;
