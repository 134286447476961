import React from 'react';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import moment from 'moment';
import {
  getTeamRates,
  getProjectBudgetSettings,
  makeGetOwnSortedMemberBudgetRates,
  getMemberBudgets
} from 'BudgetModule/selectors';
import { getSelectedTeamId } from 'selectors';
import {
  StyledDate,
  HeaderContainer,
  StyledMemberName,
  StyledRowContainer,
  AddRateContainer,
  MiniHeaderContainer,
  StyledDoneButton
} from './styles';
import {
  updateTeamRole,
  createTeamRole,
  updateMemberRate,
  editMemberRate
} from 'BudgetModule/actionCreators';
import DatePicker from 'components/DatePicker';
import { EARLIEST_RATE_START_DISPLAY_PROJECT } from 'BudgetModule/constants';
import { RATE_MODAL_TYPES, RATE_MODAL_COLUMNS } from 'appConstants';
import {
  cells,
  headerCells,
  columns,
  colWidths,
  modalColWidths,
  listWidths
} from 'SettingsModule/components/Budget/TeamRatesDropdown';
import { StyledCell } from 'BudgetModule/components/BudgetModal/styles';
import AddPlusIcon from 'icons/AddPlusIcon';

export const UnassignedMultiplierCell = ({ item }) => (
  <StyledCell center>
    {item?.rate.multiplier_low && item?.rate.multiplier_high
      ? `${item?.rate.multiplier_low} - ${item?.rate.multiplier_high}`
      : ''}
  </StyledCell>
);

export const StartDateCell = ({
  isEarliestRate,
  updateDate,
  item,
  setDateEditing
}) => {
  return (
    <StyledDate
      isEarliest={isEarliestRate}
      onClick={(e) => {
        if (!isEarliestRate) {
          setDateEditing(e, item, 'start_date');
        }
      }}
      center
    >
      {isEarliestRate ? (
        <div>{EARLIEST_RATE_START_DISPLAY_PROJECT}</div>
      ) : (
        <DatePicker
          onChange={updateDate}
          selectedValue={item.start_date}
          renderCustomInput={() => (
            <div>{moment(item.start_date).format('M/DD/YY')}</div>
          )}
        />
      )}
    </StyledDate>
  );
};

export const EndDateCell = ({ setDateEditing, updateDate, item }) => {
  const isOngoing = ['Present', null].includes(item.end_date);
  return (
    <StyledDate onClick={(e) => setDateEditing(e, item, 'end_date')} center>
      <DatePicker
        onChange={updateDate}
        selectedValue={item.end_date}
        renderCustomInput={() => (
          <div>
            {isOngoing ? 'Ongoing' : moment(item.end_date).format('M/DD/YY')}
          </div>
        )}
      />
    </StyledDate>
  );
};

const overrideCells = {
  [RATE_MODAL_COLUMNS.START_DATE.name]: StartDateCell,
  [RATE_MODAL_COLUMNS.END_DATE.name]: EndDateCell
};

const noop = () => {};
const copy = {
  headerInitial: 'Select Member Rate/Hr',
  headerEdit: 'Select Rate',
  headerAdd: 'Add New ',
  sticky: 'Add Rate',
  footerInitial: 'Add/Edit Rate',
  footerEdit: 'Done'
};
const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}
`;

class MemberRatesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datePickerOpen: false,
      dateType: null,
      item: null,
      dateSelected: null,
      shouldShowRates:
        this.props.showRates || this.props.budgetSettings?.estimated_cost
    };
  }

  editRate = (item) => {
    const { onStickyClick, editMemberRate } = this.props;
    editMemberRate({ editMemberRateItem: item });
    onStickyClick();
  };

  renderStickyRow = () => <div />;

  renderTableBodyHeader = () => {
    const { onStickyClick, memberRates, modalType, memberBudget } = this.props;
    return (
      <div style={{ width: listWidths[modalType] }}>
        <MiniHeaderContainer colWidths={modalColWidths[modalType]}>
          {columns[modalType].map((column, index) => {
            const Cell = headerCells[column.name];
            return (
              <Cell column={column.label} className={column.name} key={index} />
            );
          })}
        </MiniHeaderContainer>
        {/* Hide Add Rate for unassigned */}
        {memberBudget && memberBudget.account_id && (
          <AddRateContainer
            onClick={onStickyClick}
            emptyList={!memberRates?.length}
            style={{
              width: `calc(100% - ${colWidths.delete}px)`,
              marginLeft: colWidths.delete
            }}
          >
            <AddPlusIcon></AddPlusIcon>
            <div>Add Rate</div>
          </AddRateContainer>
        )}
      </div>
    );
  };

  renderItem = ({ item, selectCallback }) => {
    const { memberBudget, memberRates, budgetSettings, modalType, projectId } =
      this.props;
    const earliestRateByDate = memberRates[memberRates.length - 1];
    const isEarliestRate = item.id === earliestRateByDate.id;
    const isOnlyRate = memberRates.length === 1;
    const props = {
      item,
      handleDelete: this.deleteRate,
      editRate: this.editRate,
      updateDate: this.updateDate,
      isEarliestRate,
      setDateEditing: this.setDateEditing,
      disableDeleteOnlyRate: true,
      isOnlyRate,
      projectId
    };

    return (
      <StyledRowContainer
        colWidths={modalColWidths[modalType]}
        key={item.id}
        isLast={isEarliestRate}
      >
        {columns[modalType].map((column, index) => {
          const Cell = overrideCells[column.name] || cells[column.name];
          // if (modalType === RATE_MODAL_TYPES.UNASSIGNED) {
          //   Cell = UnassignedMultiplierCell;
          // }
          return <Cell {...props} key={index} />;
        })}
      </StyledRowContainer>
    );
  };

  deleteRate = (item) => {
    const { openDeleteRateModal, memberRates, projectId } = this.props;
    const delete_member = {
      rate_id: item.id,
      member_budget_id: item.member_budget_id,
      memberRates,
      projectId
    };
    openDeleteRateModal(delete_member);
  };

  setDateEditing = (e, item, dateType) => {
    this.setState({ datePickerOpen: true, item, dateType });
  };

  renderEdit = ({ isNew, item, submitCallback = noop }) => (
    <form
      onSubmit={(e) =>
        this.handleSubmit(e, {
          isNew,
          item,
          submitCallback
        })
      }
    >
      <input
        ref={(ref) => (this.rateEdit = ref)}
        defaultValue={isNew ? '' : item.name}
      />
    </form>
  );

  updateDate = (selectedDate) => {
    const { handleSetDate } = this.props;
    const { item, dateType } = this.state;
    handleSetDate({ item, dateType, selectedDate });
  };

  handleSubmit = (e, { item, isNew, submitCallback = noop }) => {
    e.preventDefault();
    const name = this.rateEdit.value;
    const { updateTeamRole, createTeamRole, teamId } = this.props;
    if (isNew) {
      createTeamRole({
        name,
        teamId
      });
    } else {
      const { team_role_id } = item;
      updateTeamRole({
        team_role_id,
        name
      });
    }
    submitCallback();
  };

  renderHeaderText = () => {
    const { renderInitials, memberName, modalType } = this.props;
    return (
      <HeaderContainer style={{ paddingLeft: 23 }}>
        {renderInitials ? renderInitials() : ''}{' '}
        <StyledMemberName
          style={{
            maxWidth: listWidths[modalType] - colWidths.delete - 32 - 30
          }}
        >
          {memberName}'s Bill Rate/Hr
        </StyledMemberName>
      </HeaderContainer>
    );
  };

  renderHeaderButton = () => {
    return (
      <StyledDoneButton onClick={this.props.handleClose}>Done</StyledDoneButton>
    );
  };

  render() {
    const { memberRates, onStickyClick, handleClose, target, modalType } =
      this.props;

    return (
      <>
        <MultiStepFlyout
          copy={copy}
          target={target}
          items={memberRates}
          idKey="id"
          renderHeader={this.renderHeaderText}
          renderHeaderButton={this.renderHeaderButton}
          renderEdit={this.renderEdit}
          renderItem={this.renderItem}
          handleSubmit={this.handleSubmit}
          onStickyClick={onStickyClick}
          handleClose={handleClose}
          stickyRow={false}
          hideFooter
          isAlwaysGlobal
          listWidth={listWidths[modalType]}
          isWhite
          globalClassName={'member-rate-phases-global'}
          listItemContainerStyle={listItemContainerStyle}
          renderTableBodyHeader={this.renderTableBodyHeader}
          renderStickyRow={this.renderStickyRow}
        />
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getOwnMemberBudgetRates = makeGetOwnSortedMemberBudgetRates();
  const mapStateToProps = (state, ownProps) => {
    const memberBudgetHash = getMemberBudgets(state);
    const memberBudget = memberBudgetHash[ownProps.memberBudgetId];
    return {
      memberRates: getOwnMemberBudgetRates(state, {
        memberBudgetRates: memberBudget.member_rates
      }),
      memberBudget,
      rates: getTeamRates(state),
      teamId: getSelectedTeamId(state),
      budgetSettings: getProjectBudgetSettings(state, ownProps)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateTeamRole,
  createTeamRole,
  updateMemberRate,
  editMemberRate
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MemberRatesDropdown);
