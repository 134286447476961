import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchCustomField = {
  request: () => action(constants.FETCH_CUSTOM_FIELD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CUSTOM_FIELD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_CUSTOM_FIELD.FAILURE, { payload: { error } })
};

export const updateCustomField = {
  request: () => action(constants.UPDATE_CUSTOM_FIELD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_CUSTOM_FIELD.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.UPDATE_CUSTOM_FIELD.FAILURE, { payload: { error } })
};

export const createCustomField = {
  request: () => action(constants.CREATE_CUSTOM_FIELD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_CUSTOM_FIELD.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.CREATE_CUSTOM_FIELD.FAILURE, { payload: { error } })
};

export const archiveCustomField = {
  request: () => action(constants.ARCHIVE_CUSTOM_FIELD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_CUSTOM_FIELD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_CUSTOM_FIELD.FAILURE, { payload: { error } })
};

export const unarchiveCustomField = {
  request: () => action(constants.UNARCHIVE_CUSTOM_FIELD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UNARCHIVE_CUSTOM_FIELD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNARCHIVE_CUSTOM_FIELD.FAILURE, { payload: { error } })
};

export const fetchCustomFieldAllValues = {
  request: () => action(constants.FETCH_CUSTOM_FIELD_ALL_VALUES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CUSTOM_FIELD_ALL_VALUES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, requestPayload) =>
    action(constants.FETCH_CUSTOM_FIELD_ALL_VALUES.FAILURE, {
      payload: { error, requestPayload }
    })
};
