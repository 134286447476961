export class EventEmitter {
  private listenersHash = {};
  addListener(eventName, handler) {
    this.listenersHash[eventName] = this.listenersHash[eventName] || [];
    this.listenersHash[eventName].push(handler);
  }

  removeListener(eventName, handler) {
    const listeners = this.listenersHash[eventName];
    if (listeners) {
      this.listenersHash[eventName] = listeners.filter((fn) => fn !== handler);
    }
  }

  on(eventName, handler) {
    this.addListener(eventName, handler);
  }

  off(eventName, handler) {
    this.removeListener(eventName, handler);
  }

  emit(eventName, ...args) {
    const fns = this.listenersHash[eventName];
    if (!fns) return false;
    fns.forEach((fn) => fn(...args));
    return true;
  }

  removeAllListener(eventName) {
    if (eventName) {
      this.listenersHash[eventName] = [];
    } else {
      this.listenersHash = {};
    }
  }

  get listeners() {
    return this.listenersHash;
  }
}
