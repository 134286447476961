import { useAppSelector } from 'reduxInfra/hooks';
import { getAlphabeticalRegionIds } from 'SettingsModule/selectors/regions';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Region } from 'SettingsModule/models/region';
import { useRegions } from 'SettingsModule/hooks';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';

const emptyObj = {};
const emptyArray = [];

export const useRegionsFilter: FilterListTypeHookWithItemHash = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.region_ids
} = emptyObj) => {
  const { regionHash, handleNavToRegionsPage } = useRegions({
    isOff
  });

  /** Array of alphabetically sorted ids */
  const regionIdsArray: number[] = useAppSelector((state) =>
    isOff ? emptyArray : getAlphabeticalRegionIds(state)
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: regionIdsArray,
    itemHash: regionHash,
    isOff,
    config,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    shouldUseDraft,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: regionHash,
    isLoading: isResultsLoading,
    labelKey,
    handleNavToEntityPage: handleNavToRegionsPage,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'name';

const searchableKeys: (keyof Region)[] = [labelKey];
