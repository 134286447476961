import React from 'react';
import styled from 'styled-components';

const StyledNoMatchContainer = styled.div`
  font-size: 14px;
  position: absolute;
  left: 45px;
  top: 5px;
  color: #4f4f4f;
`;

const NoMatchCell = ({ row }) => {
  return <StyledNoMatchContainer>No Matches</StyledNoMatchContainer>;
};

export default NoMatchCell;
