import { PropsWithChildren } from 'react';
import { SidebarHeaderChildrenFnProps } from 'react-calendar-timeline';
import styled from 'styled-components';

type LeftSidebarHeaderProps = SidebarHeaderChildrenFnProps<never>;
export const LeftSidebarHeader = ({
  getRootProps,
  children
}: PropsWithChildren<LeftSidebarHeaderProps>) => {
  return <SideBarContainer {...getRootProps()}>{children}</SideBarContainer>;
};

const SideBarContainer = styled.div`
  border: solid ${({ theme }) => theme.colors.colorLightGray9};
  border-width: 1px 0;
  height: auto;
  box-shadow: 3px 0 2px -2px rgba(195, 195, 195, 0.5);
  background: ${({ theme }) => theme.colors.colorPureWhite};
`;
