import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
const byEmail = (item) => item.email;
const byId = (item) => item.id;

export const initialState = {
  qbMembers: {},
  qbSyncedMembers: {},
  isShowOnlySynced: false,
  isFetchingSyncedMembers: false,
  isInitialFetchSyncMembers: false,
  selectedMembers: {},
  mappingFilter: constants.mappingFilters.SHOW_ALL
};
const qbMembers = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_QB_MEMBERS.SUCCESS: {
      const { employees } = payload.response;
      return {
        ...state,
        qbMembers: keyBy(employees, byId)
      };
    }
    case constants.FETCH_SYNCED_MEMBERS.REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case constants.FETCH_SYNCED_MEMBERS.SUCCESS: {
      const { mappings } = payload.response;
      return {
        ...state,
        qbSyncedMembers: keyBy(mappings, byId)
      };
    }
    case constants.TOGGLE_SYNCED_MEMBERS: {
      const { isShowOnlySynced } = action;
      return {
        ...state,
        isShowOnlySynced
      };
    }
    case constants.QB_TOGGLE_BATCH_SELECT: {
      const { itemType, items, value } = payload;
      if (itemType !== 'members') {
        return state;
      }
      const itemsById = keyBy(items, byId);
      if (value) {
        return {
          ...state,
          selectedMembers: {
            ...state.selectedMembers,
            ...itemsById
          }
        };
      } else {
        return {
          ...state,
          selectedMembers: omit(state.selectedMembers, Object.keys(itemsById))
        };
      }
    }
    case constants.QB_FLUSH_BATCH_SELECT: {
      const { itemType } = payload;
      if (itemType !== 'members') {
        return state;
      }
      return {
        ...state,
        selectedMembers: {}
      };
    }

    case constants.TOGGLE_MAPPING_FILTER: {
      const { section, mappingFilter } = payload;
      if (section !== 'members') {
        return state;
      }
      return {
        ...state,
        mappingFilter
      };
    }
    case constants.INITIAL_FETCH_SYNC_MEMBERS: {
      const { isInitialFetch } = action;
      return {
        ...state,
        isInitialFetchSyncMembers: isInitialFetch
      };
    }
    case constants.TOGGLE_MEMBERS_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isFetchingSyncedMembers: isLoading
      };
    }

    default: {
      return state;
    }
  }
};

export default qbMembers;
