import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ConnectionIcon = () => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" fillRule="none">
    <path
      d="M10.4699 3.52988L9.81521 4.1846L12.1722 6.54163H1.82752L4.18455 4.1846L3.52982 3.52988L0.0597567 6.99994L3.52982 10.47L4.18455 9.81527L1.82752 7.45825H12.1722L9.81521 9.81527L10.4699 10.47L13.94 6.99994L10.4699 3.52988Z"
      fill="#4F4F4F"
    />
  </SvgIcon>
);

export default ConnectionIcon;
