import { createRequestTypes } from 'appUtils';

export const FETCH_TEAM_CAPACITY = createRequestTypes('FETCH_TEAM_CAPACITY');
export const UPDATE_TEAM_CAPACITY = createRequestTypes('UPDATE_TEAM_CAPACITY');

export const FETCH_CAPACITIES = createRequestTypes('FETCH_CAPACITIES');
export const UPDATE_CAPACITY = createRequestTypes('UPDATE_CAPACITY');

export const FETCH_HOLIDAYS = createRequestTypes('FETCH_HOLIDAYS');
export const CREATE_HOLIDAY = createRequestTypes('CREATE_HOLIDAY');
export const UPDATE_HOLIDAY = createRequestTypes('UPDATE_HOLIDAY');
export const DELETE_HOLIDAY = createRequestTypes('DELETE_HOLIDAY');

export const FETCH_HOLIDAY_GROUPS = createRequestTypes('FETCH_HOLIDAY_GROUPS');
export const CREATE_HOLIDAY_GROUP = createRequestTypes('CREATE_HOLIDAY_GROUP');
export const UPDATE_HOLIDAY_GROUP = createRequestTypes('UPDATE_HOLIDAY_GROUP');
export const DELETE_HOLIDAY_GROUP = createRequestTypes('DELETE_HOLIDAY_GROUP');

export const OPEN_HOLIDAY_MODAL = 'OPEN_HOLIDAY_MODAL';
export const CLOSE_HOLIDAY_MODAL = 'CLOSE_HOLIDAY_MODAL';

export const FETCH_CAPACITY_REPORT = createRequestTypes(
  'FETCH_CAPACITY_REPORT'
);

export const FETCH_QUEUED_CAPACITY_REPORT = createRequestTypes(
  'FETCH_QUEUED_CAPACITY_REPORT'
);

// demand_non_tentative + demand_tentative = demand

// non_tentative_percentage_of_available_capacity_planned +
// tentative_percentage_of_available_capacity_planned = PERCENTAGE_OF_AVAILABLE_CAPACITY_PLANNED
export const DATA_KEY = {
  DEMAND: 'demand',
  CAPACITY: 'capacity',
  PTO: 'pto_hours',
  PTO_FOR_DISPLAY: 'pto_hours_for_display',
  HOLIDAY: 'holiday_hours',
  HOLIDAY_FOR_DISPLAY: 'holiday_hours_for_display',
  CAPACITY_PERCENT: 'capacity_percent',
  PERCENTAGE_OF_AVAILABLE_CAPACITY_PLANNED:
    'percentage_of_available_capacity_planned',
  PTO_PERCENT: 'pto_percent',
  HOLIDAY_PERCENT: 'holiday_percent',
  TOTAL_AVAILABLE_CAPACITY: 'total_available_capacity',
  TOTAL_CAPACITY: 'total_capacity',
  AVAILABLE_CAPACITY: 'available_capacity',
  DEMAND_TENTATIVE: 'demand_tentative',
  DEMAND_NON_TENTATIVE: 'demand_non_tentative',
  NON_TENTATIVE_PERCENTAGE_OF_AVAILABLE_CAPACTIY_PLANNED:
    'non_tentative_percentage_of_available_capacity_planned',
  TENTATIVE_PERCENTAGE_OF_AVAILABLE_CAPACTIY_PLANNED:
    'tentative_percentage_of_available_capacity_planned'
};

export const CHART_STACK_ORDER = [
  DATA_KEY.HOLIDAY,
  DATA_KEY.PTO,
  DATA_KEY.CAPACITY,
  // DATA_KEY.DEMAND,
  DATA_KEY.DEMAND_TENTATIVE,
  DATA_KEY.DEMAND_NON_TENTATIVE
];

export const DATA_KEY_TO_PERCENT = {
  [DATA_KEY.DEMAND]: [DATA_KEY.PERCENTAGE_OF_AVAILABLE_CAPACITY_PLANNED],
  [DATA_KEY.CAPACITY]: [DATA_KEY.CAPACITY_PERCENT],
  [DATA_KEY.PTO]: [DATA_KEY.PTO_PERCENT],
  [DATA_KEY.HOLIDAY]: [DATA_KEY.HOLIDAY_PERCENT],
  [DATA_KEY.DEMAND_TENTATIVE]: [
    DATA_KEY.TENTATIVE_PERCENTAGE_OF_AVAILABLE_CAPACTIY_PLANNED
  ],
  [DATA_KEY.DEMAND_NON_TENTATIVE]: [
    DATA_KEY.NON_TENTATIVE_PERCENTAGE_OF_AVAILABLE_CAPACTIY_PLANNED
  ]
};

export const CAPACITY_COLUMN_TYPE = {
  ENTITY: 'entity',
  COLLAPSE: 'collapse',
  GRAPH: 'graph',
  TOTAL: 'total',
  AVAILABLE: 'available'
};

export const CAPACITY_ROW_TYPE = {
  MEMBER_HEADER_ROW: 'memberHeaderRow',
  MEMBER_ROW: 'memberRow',
  POSITION_ROW: 'positionRow',
  PROJECT_HEADER_ROW: 'projectHeaderRow',
  PROJECT_PHASE_ROW: 'projectPhaseRow',
  PHASE_ROW: 'phaseRow',
  EMPTY_ROW: 'emptyRow',
  LOADING_ROW: 'loadingRow',
  ACCOUNT_PROJECT_PHASE_ROW: 'accountProjectPhaseRow'
};

export const DATA_KEY_BY_COLUMN_TYPE = {
  [CAPACITY_COLUMN_TYPE.TOTAL]: DATA_KEY.DEMAND,
  [CAPACITY_COLUMN_TYPE.AVAILABLE]: DATA_KEY.AVAILABLE_CAPACITY
};

export const REPORT_TYPE = {
  CAPACITY: 'capacity-report',
  DEMAND: 'demand-report'
};
