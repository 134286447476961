import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchNotesByProject } from 'NoteModule/actionCreators';
import { getNoteTagsByProject, getMatchedRouteParams } from 'selectors';
import { getNotes } from 'NoteModule/selectors';

class NoteTagsList extends React.Component {
  state = {
    selectedTagName: '',
    filterId: null
  };

  filterNotesByTag = (tagIds) => {
    const { notes, searchText, fetchNotesByProject, matchedParams } =
      this.props;
    const accountIds = notes.selectedAccountIds;
    const { projectId } = matchedParams;

    fetchNotesByProject({
      projectId: projectId,
      tagIds,
      accountIds,
      searchText
    });
  };

  filterByTag = (id, title) => {
    const { selectedTagName, filterId } = this.state;
    const isSelected = filterId === id || selectedTagName === title;

    this.setState({
      filterId: isSelected ? null : id,
      selectedTagName: selectedTagName === title ? '' : title
    });

    /* passing an empty array ensures that when API is called,
    tagIds will be undefined as a parameter */
    this.filterNotesByTag(isSelected ? [] : id);
  };

  renderTags = () => {
    const { tags } = this.props;

    if (tags.length > 0) {
      return tags.map((tag, i) => (
        <li
          id={`tag-${tag.id}`}
          title="Click to filter by tag"
          className={`tag-item ${
            this.state.selectedTagName === tag.title ? 'filter' : ''
          }`}
          key={tag.id}
          onClick={() => this.filterByTag(tag.id, tag.title)}
        >
          {tag.title}
        </li>
      ));
    }
  };

  render() {
    const { tags } = this.props;

    return (
      <div className="tags-list-wrapper project-detail-location">
        <ul className="tags-list">{this.renderTags()}</ul>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const path = ownProps.location.pathname;
  return {
    path,
    auth: state.auth,
    tags: getNoteTagsByProject(state, ownProps),
    notes: getNotes(state),
    matchedParams: getMatchedRouteParams(state)
  };
};

const mapDispatchToProps = {
  fetchNotesByProject
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NoteTagsList)
);
