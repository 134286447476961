import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TaskMilestoneIcon = ({ width = 14, height = 14, className }) => (
  <SvgIcon
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    width={width}
    height={height}
  >
    <rect
      x="2.5"
      y="2.5"
      width="8"
      height="8"
      rx="6.5"
      fill="transparent"
      stroke="#808080"
    />
  </SvgIcon>
);

export default TaskMilestoneIcon;
