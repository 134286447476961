import { useCallback, useState, useRef, useEffect, MouseEvent } from 'react';
import styled from 'styled-components';
import { MemberTagItem } from './MemberTagItem';
import theme from 'theme';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import { usePopover } from 'components/Popover/usePopover';
import { ThreeDotMenuListItem } from 'views/personalSettings/SettingTableTemplate/shared/ThreeDotMenuListItem';
import { navigateToMemberModal } from 'actionCreators';
import DeleteIcon from 'icons/DeleteIcon';
import MembersIcon from 'icons/MembersIcon';
import { ValueOf } from 'type-fest';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { usePopperState } from 'appUtils/hooks/usePopperState';
import { TeamMember } from 'TeamsModule/models/teamMember';
import { getTeamMembersHashByTeamMembership } from 'TeamsModule/selectors';

const AVAILABLE_MEMBER_OPTIONS = {
  viewMemberProfile: 'viewMemberProfile',
  remove: 'remove'
} as const;

const AVAILABLE_MEMBER_OPTIONS_LABELS: Record<
  ValueOf<typeof AVAILABLE_MEMBER_OPTIONS>,
  string
> = {
  [AVAILABLE_MEMBER_OPTIONS.viewMemberProfile]: 'View Member Profile',
  [AVAILABLE_MEMBER_OPTIONS.remove]: 'Remove'
} as const;

const availableOptions = [
  AVAILABLE_MEMBER_OPTIONS.viewMemberProfile,
  AVAILABLE_MEMBER_OPTIONS.remove
];

const optionsMenuIcon = {
  [AVAILABLE_MEMBER_OPTIONS.remove]: <DeleteIcon />,
  [AVAILABLE_MEMBER_OPTIONS.viewMemberProfile]: (
    <MembersIcon
      currentColor={theme.colors.colorMediumGray9}
      height="13px"
      width="13px"
    />
  )
};

export const MemberTagList = ({
  disabled,
  onMemberSelect,
  onMemberRemove,
  initialSelectedMemberIds
}: {
  disabled: boolean;
  onMemberSelect: (selectedMembers: TeamMember[]) => void;
  onMemberRemove: (removedMember: TeamMember) => void;
  initialSelectedMemberIds: number[];
}) => {
  const selectedMemberTag = useRef<TeamMember | null>(null);
  const [selectedMembersList, setSelectedMembersList] = useState<TeamMember[]>(
    []
  );
  const {
    Popover: MemberPopoverMenu,
    openPopover: openMemberPopoverMenu,
    closePopover: closeMemberPopoverMenu
  } = usePopover();

  const {
    isOpen: isMemberSelectorOpen,
    anchorElement: memberSelectorAnchorElement,
    open: openMemberSelector,
    close: closeMemberSelector
  } = usePopperState();

  const memberHash = useAppSelector(getTeamMembersHashByTeamMembership);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (disabled) setSelectedMembersList([]);
  }, [disabled]);

  useEffect(() => {
    setSelectedMembersList(
      initialSelectedMemberIds.flatMap((memberId) => {
        const hashedMember = memberHash[memberId];

        return hashedMember ?? [];
      })
    );
  }, [initialSelectedMemberIds, memberHash]);

  const handleMemberTagClick = ({
    event,
    member
  }: {
    event: MouseEvent<HTMLElement>;
    member: TeamMember;
  }) => {
    openMemberPopoverMenu({ event });
    selectedMemberTag.current = member;
  };

  const handleMenuOptionClick = ({
    option
  }: {
    option: ValueOf<typeof AVAILABLE_MEMBER_OPTIONS>;
  }) => {
    const optionAction = onMenuOptionClick[option];
    if (optionAction && selectedMemberTag?.current)
      optionAction({ member: selectedMemberTag.current });
    closeMemberPopoverMenu();
  };

  const handleRemoveMember = ({ member }: { member: TeamMember }) => {
    setSelectedMembersList(
      selectedMembersList.filter(
        (selectedMember) => selectedMember.id !== member.id
      )
    );
    onMemberRemove(member);
  };

  const handleViewMember = ({ member }: { member: TeamMember }) => {
    // need to ensure router is set up to accept the url
    // see PermissionsSettingsRouter
    dispatch(
      navigateToMemberModal({
        memberId: member.account.id,
        memberViewType: 'overview'
      })
    );
  };

  const handleSelectMembers = (selectedMembers: TeamMember[]) => {
    setSelectedMembersList([...selectedMembersList, ...selectedMembers]);
    onMemberSelect(selectedMembers);
  };

  const handleOpenMemberSelector = (event: MouseEvent<HTMLElement>) => {
    if (!disabled) openMemberSelector({ event });
  };

  const handleCloseMemberSelector = () => {
    closeMemberSelector();
  };

  const memberFilter = useCallback(
    (members) => {
      const selectedMemberIdsSet = new Set();

      selectedMembersList.forEach((member) =>
        selectedMemberIdsSet.add(member.account.id)
      );

      return members.filter(
        (member) => !selectedMemberIdsSet.has(member.account.id)
      );
    },
    [selectedMembersList]
  );

  const onMenuOptionClick = {
    [AVAILABLE_MEMBER_OPTIONS.viewMemberProfile]: handleViewMember,
    [AVAILABLE_MEMBER_OPTIONS.remove]: handleRemoveMember
  };

  return (
    <MemberTagListContainer>
      <SelectedMemberTagListContainer>
        {selectedMembersList.map((member) => (
          <MemberTagItem
            key={member.id}
            member={member}
            handleMemberTagClick={handleMemberTagClick}
          />
        ))}
      </SelectedMemberTagListContainer>
      <AddMemberTagList onClick={handleOpenMemberSelector} disabled={disabled}>
        + Member
      </AddMemberTagList>

      {isMemberSelectorOpen && (
        <BulkMemberSelector
          handleDone={handleSelectMembers}
          memberFilter={memberFilter}
          target={memberSelectorAnchorElement}
          onClose={handleCloseMemberSelector}
          shouldUseMemberFilter
          alwaysOpen
          renderSelect={() => null}
          hideFooter={true}
        />
      )}

      <MemberPopoverMenu className="specific-member-tag-list-menu">
        {availableOptions.map((option) => (
          <ThreeDotMenuListItem
            key={option}
            label={AVAILABLE_MEMBER_OPTIONS_LABELS[option] ?? ''}
            onClick={() => handleMenuOptionClick({ option })}
            icon={optionsMenuIcon[option]}
          />
        ))}
      </MemberPopoverMenu>
    </MemberTagListContainer>
  );
};

const MemberTagListContainer = styled.div``;

const AddMemberTagList = styled.div<{ disabled: boolean }>`
  font-size: 13px;
  color: ${({ disabled }) =>
    !disabled ? theme.colors.colorBudgetBlue : theme.colors.colorMediumGray5};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : undefined)};
`;

const SelectedMemberTagListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
