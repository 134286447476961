import React from 'react';
import { connect } from 'react-redux';
import {
  getIsTimesheetsDisabled,
  getProjectHash,
  getTeamMembersHash,
  getMe,
  getSplitFlags
} from 'selectors';
import TimesheetDay from '../TimesheetDay/WeeklyTimesheetDay';
import TimesheetProject from '../TimesheetProject/WeeklyTimesheetProject';
import TimesheetActivity from '../TimesheetActivity/WeeklyTimesheetActivity';
import TimesheetDescription from '../TimesheetDescription/WeeklyTimesheetDescription';
import TimesheetRowTotal from '../TimesheetRowTotal';
import AddRow from './AddRow';
import EstimatedTimeController from './EstimatedTimeController';
import { makeGetAccountPhaseActivityBudgetRecords } from 'BudgetModule/selectors';

const NoCell = ({ height }) => <div style={{ height }} />;

class TimesheetCellAdapter extends React.Component {
  date = () => {
    const {
      column,
      row: { original },
      isTimesheetsDisabled
    } = this.props;
    const day = column.id;
    const {
      timesheetsByDate,
      project_id,
      phase_id,
      account_id,
      id,
      activity_id,
      titleRequired,
      title
    } = original;
    const timesheet = timesheetsByDate[day] || {};
    return (
      <TimesheetDay
        id={timesheet.id}
        day={day}
        info={timesheet}
        projectId={project_id}
        phaseId={phase_id}
        accountId={account_id}
        descriptionId={id}
        activitySelected={!!activity_id}
        activity_id={activity_id}
        descriptionMissing={titleRequired && !title}
        isTimesheetsDisabled={isTimesheetsDisabled}
        timesheetColumnIndex={column.index}
      />
    );
  };

  project = () => {
    const { row, isTimesheetsDisabled } = this.props;
    const { original } = row;
    return (
      <TimesheetProject
        activityId={original.activity_id}
        projectId={row.state.phase?.project_id || original.project_id}
        phaseId={row.state.phase?.id || original.phase_id}
        description={original}
        date={original.date}
        totalLeft={this.getTotalLeft()}
        openActivityDropdown={({ phase }) =>
          row.setState({ activityDropdownOpen: true, phase })
        }
        isTimesheetsDisabled={isTimesheetsDisabled}
      />
    );
  };

  activity = () => {
    const { row, isTimesheetsDisabled } = this.props;
    const { original } = row;
    return (
      <TimesheetActivity
        activityId={original.activity_id}
        date={original.date}
        phase={row.state.phase || original.phase}
        phaseId={row.state.phase?.id || original.phase_id}
        projectId={row.state.phase?.project_id || original.project_id}
        title={original.title}
        accountId={original.account_id}
        id={original.id} // id is description id
        onActivityDropdownClose={() => {
          row.setState({
            activityDropdownOpen: false,
            phase: null
          });
        }}
        isActivityDropdownOpen={row.state?.activityDropdownOpen}
        isTimesheetsDisabled={isTimesheetsDisabled}
      />
    );
  };

  description = () => {
    const {
      row: { original },
      isTimesheetsDisabled
    } = this.props;
    const { titleRequired, title } = original;
    return (
      <TimesheetDescription
        id={original.id}
        title={original.title}
        projectId={original.project_id}
        phaseId={original.phase_id}
        activityId={original.activity_id}
        accountId={original.account_id}
        date={original.date}
        timesheets={original.timesheetsByDate}
        descriptionMissing={titleRequired && !title}
        isTimesheetsDisabled={isTimesheetsDisabled}
      />
    );
  };

  total = () => {
    const {
      row: { original },
      projectHash,
      teamMembersHash,
      me,
      budgetRecords
    } = this.props;

    const id = `${me?.id}-${original.phase_id}-${original.activity_id}`;
    const rawTotals = budgetRecords[id] || {};
    let totals = {};
    if (Object.keys(rawTotals).length > 0) {
      totals = {
        ...rawTotals,
        remaining_hours:
          Number(rawTotals.estimated_hours) -
          (Number(rawTotals.planned_hours) + Number(rawTotals.spent_hours))
      };
    } else {
      totals = {};
    }

    return (
      <TimesheetRowTotal
        timesheets={original.timesheetsByDate}
        projectTitle={projectHash?.[original.project_id]?.title}
        memberName={teamMembersHash?.[original.account_id]?.name}
        totals={totals}
        projectId={original.project_id}
        phaseId={original.phase_id}
      />
    );
  };

  getTotalLeft = () => {
    const { columns } = this.props;
    const { totalLeft } = columns[columns.length - 2];
    return totalLeft;
  };

  render() {
    const { column, row, timesheetEstimate } = this.props;

    const { headerType } = column;
    if (row.original.createRow) {
      const CellComponent =
        headerType === 'project'
          ? AddRow
          : headerType === 'total' && timesheetEstimate
          ? () => <EstimatedTimeController />
          : () => <NoCell height={row.original.itemHeight} />;
      return <CellComponent />;
    }
    const CellComponent = this[headerType] || NoCell;
    return <CellComponent />;
  }
}

const makeMapStateToProps = () => {
  const getAccountPhaseActivityBudgetRecords =
    makeGetAccountPhaseActivityBudgetRecords();
  const mapStateToProps = (state, ownProps) => ({
    isTimesheetsDisabled: getIsTimesheetsDisabled(state),
    projectHash: getProjectHash(state),
    teamMembersHash: getTeamMembersHash(state),
    me: getMe(state),
    budgetRecords: getAccountPhaseActivityBudgetRecords(state),
    timesheetEstimate: getSplitFlags(state).timesheetEstimate
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TimesheetCellAdapter);
