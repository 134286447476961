import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportDiscipline
} from 'CsvImportsModule/types';
import uniq from 'lodash/uniq';
import { getRowValuesForEntityType } from './utils';
interface AdditionalParams {
  existingDisciplinesByName: Record<string, CsvImportDiscipline>;
  projectDisciplineOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectDisciplineOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingDisciplinesByName, projectDisciplineOption } =
    additionalParams;
  const { enteredProjectTitle, enteredProjectNumber } =
    getRowValuesForEntityType(row, entityType);
  const matchedRegionByProjectTitleAndNumber =
    projectDisciplineOption.current[enteredProjectTitle]?.[
      enteredProjectNumber
    ];
  const newDisciplineOptionsByProjectNumberArray = Object.values(
    projectDisciplineOption.current
  );
  // store all new discipline options in an array
  const newDisciplineOptions: string[] = [];
  newDisciplineOptionsByProjectNumberArray.forEach(
    (disciplineOptionByProjectNumber) =>
      Object.values(disciplineOptionByProjectNumber).forEach(
        (disciplineOption) => newDisciplineOptions.push(disciplineOption)
      )
  );

  // always return all possible dicipline options, specifically putting the matched discipline as the second option if exists
  // including '' so that user can go back to empty field
  return uniq([
    '',
    matchedRegionByProjectTitleAndNumber ?? '',
    ...newDisciplineOptions,
    ...Object.keys(existingDisciplinesByName)
  ]);
};
