import { SimpleStaticListItem } from './types';

export const flattenList = (items: SimpleStaticListItem[]) => {
  const flatList: SimpleStaticListItem[] = [];
  for (const item of items) {
    const { listItems, isParentOpen, isOpen } = item || {};
    if (listItems) {
      // Has list items (children) - despite listItems = []
      flatList.push(item);

      // Only build children if the current item (parent) is expanded
      if (isOpen) {
        flatList.push(...flattenList(listItems));
      }
    } else {
      // Does not have list items
      if (isParentOpen) {
        flatList.push(item);
      }
    }
  }

  return flatList;
};

export const defaultGetItemId = (item: SimpleStaticListItem, index: number) => {
  return item.id;
};
