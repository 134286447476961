import React from 'react';
import { connect } from 'react-redux';
import { BulkUpdateModal } from 'views';
import {
  getHomePlannerOverdueTasks,
  getHomePlannerEditedTasks,
  getAuth
} from 'selectors';
import { bulkEditTasks } from 'actionCreators';

class BulkUpdateModalPlannerConnector extends React.Component {
  state = {
    bulkUpdateDisplayDate: null
  };

  updateBulkUpdateDisplayDate = (newDate = null) => {
    this.setState({ bulkUpdateDisplayDate: newDate });
  };

  updateTasks = () => {
    const { auth, bulkEditTasks, editedTasks } = this.props;

    bulkEditTasks({
      token: auth.token,
      project_tasks: editedTasks
    });
  };

  render() {
    return (
      <BulkUpdateModal
        {...this.props}
        updateTasks={this.updateTasks}
        homeTasksView={false}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  editedTasks: getHomePlannerEditedTasks(state),
  tasks: getHomePlannerOverdueTasks(state)
});

const mapDispatchToProps = {
  bulkEditTasks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUpdateModalPlannerConnector);
