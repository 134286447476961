import {
  ERROR_MESSAGES,
  TRAINING_DATA_REQUIRED_STRING
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, resetTempRef } from './utils';

import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  projectDisciplineOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
  temporaryProjectDisciplineOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectDisciplineValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const { projectDisciplineOption, temporaryProjectDisciplineOption } =
    additionalParams;

  const { enteredProjectTitle, enteredProjectNumber, enteredDiscipline } =
    getRowValuesForEntityType(row, entityType);

  let res: string | boolean = true;

  if (!enteredProjectTitle || !enteredProjectNumber) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else res = 'Project number is required';
    resetTempRef(temporaryProjectDisciplineOption, isLastRow);
    return res;
  }

  if (enteredDiscipline === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else {
    const matchedDisciplineInTempHash =
      temporaryProjectDisciplineOption.current[enteredProjectTitle]?.[
        enteredProjectNumber
      ];
    // project discipline can be empty string so empty string can be an option added to the hash here
    // if no option stored for this project title + project number combination, then add this entered value as option to both hashes.
    if (matchedDisciplineInTempHash === undefined) {
      temporaryProjectDisciplineOption.current = {
        ...temporaryProjectDisciplineOption.current,
        [enteredProjectTitle]: {
          ...temporaryProjectDisciplineOption.current[enteredProjectTitle],
          [enteredProjectNumber]: enteredDiscipline
        }
      };
      projectDisciplineOption.current = {
        ...projectDisciplineOption.current,
        [enteredProjectTitle]: {
          ...projectDisciplineOption.current[enteredProjectTitle],
          [enteredProjectNumber]: enteredDiscipline
        }
      };
      // if there was an option and it does not match to the entered value, return an error message.
    } else if (matchedDisciplineInTempHash !== enteredDiscipline) {
      res = ERROR_MESSAGES.projectDisciplineDoesNotMatch;
    }
  }
  resetTempRef(temporaryProjectDisciplineOption, isLastRow);
  return res;
};
