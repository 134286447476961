import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import {
  UpdateEntityDefaultCurrencyParams,
  UpdateEntityDefaultCurrencyRequestPayload
} from '../types';
import { FetchEntityOptionsSuccessResponse } from 'EntityOptionsModule/types';

const UPDATE_ENTITY_DEFAULT_CURRENCY = createRequestTypes(
  'UPDATE_ENTITY_DEFAULT_CURRENCY'
);

export const updateEntityDefaultCurrency =
  createAction<UpdateEntityDefaultCurrencyParams>(
    UPDATE_ENTITY_DEFAULT_CURRENCY.TRIGGER
  );

export const updateEntityDefaultCurrencyActionCreatorsMap =
  createActionCreatorsMap<
    UpdateEntityDefaultCurrencyRequestPayload,
    FetchEntityOptionsSuccessResponse
  >(UPDATE_ENTITY_DEFAULT_CURRENCY);
