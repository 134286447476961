import {
  CsvImportRate,
  RowOptionsGetterWithRequiredAdditionalParams
} from 'CsvImportsModule/types';
import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import uniq from 'lodash/uniq';
import { getRowValuesForEntityType } from './utils';

interface AdditionalParams {
  existingRatesGroupedByRateAmount: Record<string, CsvImportRate[]>;
  existingRatesByRateDescription: Record<string, CsvImportRate>;
  newRateDescriptions: React.MutableRefObject<Record<string, boolean>>;
  newRateDescriptionAmountHashes: React.MutableRefObject<
    Record<string, string>
  >;
}

export const rateDescriptionOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingRatesGroupedByRateAmount,
    existingRatesByRateDescription,
    newRateDescriptions,
    newRateDescriptionAmountHashes
  } = additionalParams;
  const { enteredRateAmount } = getRowValuesForEntityType(row, entityType);
  if (
    !enteredRateAmount ||
    enteredRateAmount === TRAINING_DATA_REQUIRED_STRING
  ) {
    // return all possible rate descriptions
    return uniq([
      ...Object.keys(newRateDescriptions.current),
      ...Object.keys(newRateDescriptionAmountHashes.current),
      ...Object.keys(existingRatesByRateDescription)
    ]);
  } else if (
    // if entered rate amount is not empty
    enteredRateAmount &&
    enteredRateAmount !== TRAINING_DATA_REQUIRED_STRING
  ) {
    const existingRatesMatchedByEnteredRateAmount =
      existingRatesGroupedByRateAmount[enteredRateAmount];
    const newMatchedDescriptions: string[] = [];
    // add all new matched descriptions to newMatchedDescriptions
    Object.entries(newRateDescriptionAmountHashes.current).forEach(
      ([key, value]) => {
        if (value === enteredRateAmount) newMatchedDescriptions.push(key);
      }
    );
    // return all rate descriptions matched by entered rate amount
    if (existingRatesMatchedByEnteredRateAmount) {
      return [
        ...newMatchedDescriptions,
        ...existingRatesMatchedByEnteredRateAmount.map(
          (rate) => rate.description
        )
      ];
    } else {
      return newMatchedDescriptions;
    }
  }

  return [];
};
