import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Modal, ModalBody } from 'reactstrap';

const TimerSettingsModal = ({ isOpen, handleClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="timer-settings-modal"
    >
      <ModalHeader justify="space-between">
        <ModalTitle> Time Tracking Settings </ModalTitle>
        <div>
          <DoneButton onClick={handleClose}>Done</DoneButton>
        </div>
      </ModalHeader>

      <ModalBody>
        <SettingsRow>
          <div>Track Time</div>
          <OptionsContainer>
            <OptionContainer>
              <input type={'radio'} value={false} />
              <OptionLabel>by Project</OptionLabel>
            </OptionContainer>
            <OptionContainer>
              <input type={'radio'} value={false} />
              <OptionLabel>by Task</OptionLabel>
            </OptionContainer>
          </OptionsContainer>
        </SettingsRow>
      </ModalBody>
    </Modal>
  );
};

/* --------------------Styling--------------- */

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 372px;
  margin-top: 25px;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: ${theme.colors.colorMediumGray7};
`;

const DoneButton = styled.div`
  width: 54px;
  height: 26px;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const SettingsRow = styled.div`
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  width: 360px;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

const OptionsContainer = styled.div`
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.colorMediumGray9};
  width: 170px;
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
`;

const OptionContainer = styled.div`
  align-items: center;
  display: flex;
`;

const OptionLabel = styled.div`
  align-items: center;
  display: flex;
  margin-top: -2px;
  margin-left: 3px;
`;

export default TimerSettingsModal;
