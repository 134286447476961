import useAccountsSuggestionForPhases from 'SuggestionModule/hooks/useAccountsSuggestionForPhases';

const emptyAccountsSuggestionsForPhasesProps = {
  isoStateId: '',
  phaseIds: []
};

type WrappedComponentProps = {
  suggestionsProps: {
    accountsSuggestionsForPhasesProps?: {
      isoStateId: string | number;
      phaseIds: number[];
    };
  };
};

const withAccountsSuggestionForEntities = (WrappedComponent) => {
  const WithAccountsSuggestionForEntities = (props: WrappedComponentProps) => {
    /* ---------------------------------- Phase --------------------------------- */
    const { accountsSuggestionsForPhasesProps } =
      props.suggestionsProps || emptyAccountsSuggestionsForPhasesProps;
    const { isoStateId, phaseIds } =
      accountsSuggestionsForPhasesProps ||
      emptyAccountsSuggestionsForPhasesProps;
    const { suggestedAccountsForPhases, isFetchingSuggestedAccountsForPhases } =
      useAccountsSuggestionForPhases({
        isoStateId,
        phaseIds
      });

    /* ------------------------------ Other entity logic ------------------------------ */

    /* --------------------------------- Loading -------------------------------- */

    const isFetchingSuggestions = isFetchingSuggestedAccountsForPhases;

    return (
      <WrappedComponent
        suggestedAccountsForPhases={suggestedAccountsForPhases}
        isFetchingSuggestions={isFetchingSuggestions}
        {...props}
      />
    );
  };
  return WithAccountsSuggestionForEntities;
};

export default withAccountsSuggestionForEntities;
