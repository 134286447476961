import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const fetchTasksV2 = (params) =>
  createAction(constants.FETCH_TASKS_V2.TRIGGER, params);

export const fetchMemberTodayTaskCount = (params) =>
  createAction(constants.FETCH_MEMBER_TODAY_TASK_COUNT.TRIGGER, params);

export const fetchMemberOverdueTaskCount = (params) =>
  createAction(constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.TRIGGER, params);

export const fetchMemberScheduledTaskCount = (params) =>
  createAction(constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.TRIGGER, params);
export const fetchMemberUnscheduledTaskCount = (params) =>
  createAction(constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.TRIGGER, params);
export const fetchMemberCompletedTaskCount = (params) =>
  createAction(constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.TRIGGER, params);
export const fetchMemberEndsBeforeTaskCount = (params) =>
  createAction(constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.TRIGGER, params);

export const exportTasks = (params) =>
  createAction(constants.EXPORT_TASKS.TRIGGER, params);

export const openConfirmCompleteTaskModal = (params) =>
  createAction(constants.OPEN_CONFIRM_COMPLETE_TASK_MODAL, params);
export const closeConfirmCompleteTaskModal = (params) =>
  createAction(constants.CLOSE_CONFIRM_COMPLETE_TASK_MODAL, params);

export const clearLazyItemsLastUpdated = (params) =>
  createAction(constants.CLEAR_LAZY_ITEMS_LAST_UPDATED, params);
export const clearLazyLoadingContext = (params) =>
  createAction(constants.CLEAR_LAZY_ITEMS, params);
