import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { getSelectedTeamId } from 'selectors';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';
import { OfficesTable } from './OfficesTable';

export const OfficesTableContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchOffices({ teamId }));
    }
  }, [dispatch, teamId]);

  return <OfficesTable />;
};
