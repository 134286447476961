import { createSelector, Selector } from '@reduxjs/toolkit';
import {
  CapacityGroupsState,
  initialState
} from 'CapacityModule/reducers/capacityGroup';
import { RootState } from 'reduxInfra/shared';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

const getCapacityGroupsState: Selector<RootState, CapacityGroupsState> = (
  state
) => state.capacityGroups || initialState;

export const getCapacityGroupsByAccountHash = createSelector(
  getCapacityGroupsState,
  (state) => state.capacitiesByAccountHash
);

export const getCapacityGroupsHash = createSelector(
  getCapacityGroupsState,
  (state) => state.capacityGroupsHash
);

const getCapacityMembershipByAccountHash = createSelector(
  getCapacityGroupsState,
  (state) => state.capacityMembershipByAccountHash
);

export const getCapacityPoliciesByDescriptionHash = createSelector(
  getCapacityGroupsHash,
  (capacityGroupsHash) =>
    keyBy(capacityGroupsHash, (capacityGroup) => capacityGroup.name)
);

const getCapacityIdsOrder = createSelector(
  getCapacityGroupsState,
  (state) => state.capacityGroupsIdsOrder
);

const getOrderedCapacityPolicies = createSelector(
  getCapacityGroupsHash,
  getCapacityIdsOrder,
  (capacityGroupsHash, ids) => ids.flatMap((id) => capacityGroupsHash[id] ?? [])
);

export const getOrderedCurrentCapacityPolicies = createSelector(
  getOrderedCapacityPolicies,
  (policies) => policies.filter((policy) => !policy.discarded_at)
);

export const getOrderedArchivedCapacityPolicies = createSelector(
  getOrderedCapacityPolicies,
  (policies) => policies.filter((policy) => !!policy.discarded_at)
);

const getOwnTeamMembershipId = (
  state,
  { teamMembershipId }: { teamMembershipId: number }
) => teamMembershipId;

export const makeGetOrderedMemberCapacityPolicies = () =>
  createSelector(
    getCapacityMembershipByAccountHash,
    getOwnTeamMembershipId,
    (ptoMembershipByAccountHash, teamMembershipId) => {
      const memberPTOPolicies =
        ptoMembershipByAccountHash[teamMembershipId] ?? [];

      return [...memberPTOPolicies].sort((a, b) => {
        // policies that don't have a start date should be sorted to the bottom
        if (!a.start_date) return 1;
        if (!b.start_date) return -1;
        // policies that have a newer start date should be sorted higher than those that have an older start date
        return moment(b.start_date).valueOf() - moment(a.start_date).valueOf();
      });
    }
  );
