import styled from 'styled-components';
import theme from 'theme';

import { generatePageOverlay } from 'appUtils/styleUtils';

export const StyledListBreak = styled.div`
  height: 1px;
  background-color: ${theme.colors.colorSemiDarkGray1};
  margin: 5px 0px;
`;

const Before = generatePageOverlay({
  zIndex: 1,
  backgroundColor: 'transparent'
});

export const StyledColumnOrderDropdown = styled.div`
  z-index: 2;
  display: flex;
  position: absolute;
  flex-direction: column;
  min-width: 220px;
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
  right: ${(props) => props.offsetRight || 0}px;
`;
export const StyledColumnOrderDroppable = styled.div`
  background-color: white;
  padding-bottom: 15px;
  z-index: 2;
`;

export const DropdownHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  .help-column-dropdown-tooltip {
    font-size: 12px;
    padding: 12px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
    width: 160px;
  }
`;

export const HeaderText = styled.span`
  color: ${theme.colors.colorMediumGray9};
  flex-basis: 100px;
`;

export const HelpIconContainer = styled.div`
  margin-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.div`
  font-size: 12px;
  border-radius: 5px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 8px;
  background: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  font-weight: 400;
`;

const getCustomStyles = (props) => props.customStyles;

export const ColumnOrderDropdownWrapper = styled.div`
  width: 0px;
  height: 30px;
  align-self: flex-end;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  ${(props) => props.isDropdownOpen && Before}
  ${getCustomStyles}
`;

export const ColumnOrderDropdownButton = styled.div`
  width: 70px;
  height: 30px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #808080;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.2s, background-color 0.2s;
  cursor: pointer;

  .dropdown-header-content {
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .add-text {
    opacity: 0;
    font-size: 12px;
    font-family: 'proxima-nova';
    text-transform: uppercase;
    margin-left: 7px;
    margin-top: 2px;
  }

  .dropdown-header-content:hover {
    .add-text {
      opacity: 1;
    }
  }
  &:hover {
    opacity: 1;
    color: ${theme.colors.colorRoyalBlue};
    background-color: ${theme.colors.colorPureWhite};
    .add-text {
      opacity: 1;
    }
  }
`;

export const ColumnOrderDropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  font-size: 15px;
  color: ${theme.colors.colorMediumGray5};
  font-weight: 600;
  background: ${theme.colors.colorPureWhite};
  z-index: 2;
`;

export const IsRemovingModalContent = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 15px;

  .column-modal-title {
    line-height: 19px;
    font-size: 14px;
    color: ${theme.colors.colorSemiDarkGray1};
    margin-bottom: 10px;
  }
  .column-modal-subheader {
    line-height: 17px;
    font-size: 12px;
    color: #a2a2a2;
    margin-bottom: 10px;
  }
`;

export const IsRemovingButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  div {
    margin-left: 6px;
  }
`;
