import React from 'react';
import { connect } from 'react-redux';

import { FlexContainer } from './styles';
import BarBudget from './BarBudget';
import * as constants from 'appConstants';

class MemberBudgetContainer extends React.Component {
  getTotalHoursDiff = () => {
    const { bar, initialBar } = this.props;
    const initialHours =
      !initialBar || bar.phase_id !== initialBar.phase_id
        ? 0
        : +initialBar.total_hours;
    const currentHours = +bar.total_hours || 0;
    const hourDiff = currentHours - initialHours;
    return hourDiff || 0;
  };

  render() {
    const { budgetTotals = {} } = this.props;
    const hourDiff = this.getTotalHoursDiff();

    return (
      <FlexContainer
        flex={1}
        style={{
          justifyContent: 'space-between',
          width: '250px',
          marginLeft: '12px'
        }}
      >
        <BarBudget
          value={+budgetTotals.estimated_hours || 0}
          name="estimated_hours"
          header={'estimated'}
          data-html
          dataTip={constants.TIME_MEMBER_BUDGET_TOOLTIP}
          hideHeader
        />
        <BarBudget
          value={+budgetTotals.spent_hours || 0}
          name="spent_hours"
          header={'spent'}
          data-html
          dataTip={constants.SPENT_TOOLTIP}
          hideHeader
        />
        <BarBudget
          value={+budgetTotals.planned_hours || 0}
          name={'planned_hours'}
          header={'planned'}
          dataTip={constants.PLANNED_TOOLTIP}
          hideHeader
        />
        <BarBudget
          value={
            +budgetTotals.remaining_hours
              ? +budgetTotals.remaining_hours - hourDiff
              : 0
          }
          showOverdue={+budgetTotals.estimated_hours > 0}
          name={'remaining_hours'}
          header={'remaining'}
          tooltipClass="work-plan-box-remaining-tooltip"
          dataTip={
            +budgetTotals.remaining_hours >= 0 || !budgetTotals.remaining_hours
              ? constants.MEMBERS_REMAINING_TOOLTIP
              : 'Planned + Spent Time is over time estimated.'
          }
          hideHeader
        />
      </FlexContainer>
    );
  }
}

export default connect()(MemberBudgetContainer);
