import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

type Props = Pick<SvgIconProps, 'className' | 'width' | 'height' | 'stroke'>;

const OrgIcon = ({
  className,
  stroke = '#4F4F4F',
  width = '24',
  height = '24'
}: Props) => (
  <SvgIcon
    className={className}
    height={height}
    width={width}
    viewBox="0 0 18 18"
    fill="none"
  >
    <rect
      x="6.45225"
      y="3.4"
      width="5.09462"
      height="3.41045"
      rx="0.6"
      stroke={stroke}
      strokeWidth="0.8"
    />
    <rect
      x="1.4"
      y="11.8219"
      width="3.41045"
      height="3.41045"
      rx="0.6"
      stroke={stroke}
      strokeWidth="0.8"
    />
    <rect
      x="7.29502"
      y="11.8219"
      width="3.41045"
      height="3.41045"
      rx="0.6"
      stroke={stroke}
      strokeWidth="0.8"
    />
    <rect
      x="13.1896"
      y="11.8219"
      width="3.41045"
      height="3.41045"
      rx="0.6"
      stroke={stroke}
      strokeWidth="0.8"
    />
    <path
      d="M9.00009 7.21094V9.7372M9.00009 9.7372H3.10547V11.4214M9.00009 9.7372V11.4214M9.00009 9.7372H14.8947V11.4214"
      stroke={stroke}
      strokeWidth="0.8"
    />
  </SvgIcon>
);

export default OrgIcon;
