import { leanApiSchemaVariant } from '.';
import type {
  MembersByDepartmentResponse,
  MembersByPositionResponse,
  MembersBySkillResponse,
  MembersByOfficeResponse,
  MembersByRegionResponse,
  MembersByDisciplineResponse,
  MembersByPortfolioResponse,
  AllMembersResponse,
  BaseGroupedMemberResponse
} from './teamMembership/schemas';
import type {
  BaseGroupedProjectResponse,
  ProjectsByPortfolioResponse
} from './project/schemas';
import {
  LeanApiIsoState,
  LeanApiIsoStateModifiers,
  LeanApiSchemaVariant
} from 'LeanApiModule/types';
import { Draft } from '@reduxjs/toolkit';
import { FIELD_RENAME_MAP } from 'LeanApiModule/constants';

/**
 * BE sends back objects instead of arrays, so in order to maintain the order of the keys, BE sends
 * back eg. '@52' instead of '52' as a hacky way of maintaining javascript object key order (since integers
 * and integer strings are sorted in objects)
 */
const parseNumericId = (id: string) => {
  return id.split('@')[1] || ''; // fallback value should be unnecessary
};

const genericTeamMembershipSuccessIsoStateModifier = <
  R extends BaseGroupedMemberResponse
>({
  response,
  isoState,
  groupCountsKey
}: {
  response: R;
  isoState: Draft<LeanApiIsoState>;
  groupCountsKey: string;
}) => {
  const { [groupCountsKey]: _groupCounts, ...selectedAttributeCounts } =
    response.value_counts;

  const { records } = response;

  const countsByGroupId = _groupCounts ?? {};
  isoState.groupTotalCounts = countsByGroupId;
  isoState.selectedAttributeCounts = selectedAttributeCounts;

  const ordersByGroup = {};
  const topLevelOrder: string[] = [];

  Object.entries(records).forEach(([groupId, groupRecords]) => {
    const parsedId = parseNumericId(groupId);
    ordersByGroup[parsedId] = groupRecords.map(({ account_id }) => account_id);
    topLevelOrder.push(parsedId);
  });

  isoState.ordersByGroup = ordersByGroup;
  isoState.topLevelOrder = topLevelOrder;
};

const genericProjectSuccessIsoStateModifier = <
  R extends BaseGroupedProjectResponse
>({
  response,
  isoState,
  groupCountsKey
}: {
  response: R;
  isoState: Draft<LeanApiIsoState>;
  groupCountsKey: string;
}) => {
  const { [groupCountsKey]: _groupCounts, ...selectedAttributeCounts } =
    response.value_counts;

  const { records } = response;

  const countsByGroupId = _groupCounts ?? {};
  isoState.groupTotalCounts = countsByGroupId;
  isoState.selectedAttributeCounts = selectedAttributeCounts;

  const ordersByGroup = {};
  const topLevelOrder: string[] = [];

  Object.entries(records).forEach(([groupId, groupRecords]) => {
    const parsedId = parseNumericId(groupId);
    ordersByGroup[parsedId] = [];

    groupRecords.forEach((project) => {
      ordersByGroup[parsedId].push(project.id);
      isoState.dataHash.partialProjects[project.id] = {
        ...project,
        isPartial: true
      };
    });

    topLevelOrder.push(parsedId);
  });

  isoState.ordersByGroup = ordersByGroup;
  isoState.topLevelOrder = topLevelOrder;
};

/**
 * default isoStateModifiers by action and variant
 */
export const defaultIsoStateModifiers: {
  fetchLeanApiSuccess: Record<
    LeanApiSchemaVariant,
    LeanApiIsoStateModifiers['fetchLeanApiSuccess']
  >;
} = {
  fetchLeanApiSuccess: {
    [leanApiSchemaVariant.allMembers]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as AllMembersResponse;

      isoState.selectedAttributeCounts = response.value_counts;
      isoState.topLevelOrder = response.records.map(
        ({ account_id }) => account_id
      );
    },

    [leanApiSchemaVariant.membersByDepartment]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersByDepartmentResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.department_id
      });
    },

    [leanApiSchemaVariant.membersByPosition]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersByPositionResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.position_id
      });
    },

    [leanApiSchemaVariant.membersBySkill]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersBySkillResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.skill_id
      });
    },

    [leanApiSchemaVariant.membersByOffice]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersByOfficeResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.office_id
      });
    },

    [leanApiSchemaVariant.membersByDiscipline]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersByDisciplineResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.discipline_id
      });
    },

    [leanApiSchemaVariant.membersByRegion]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersByRegionResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.region_id
      });
    },

    [leanApiSchemaVariant.membersByPortfolio]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as MembersByPortfolioResponse;

      genericTeamMembershipSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.board_id
      });
    },

    [leanApiSchemaVariant.projectsByPortfolio]: ({
      response: _untypedResponse,
      isoState
    }) => {
      const response = _untypedResponse as ProjectsByPortfolioResponse;

      genericProjectSuccessIsoStateModifier({
        response,
        isoState,
        groupCountsKey: FIELD_RENAME_MAP.board_id
      });
    }
  }
};
