import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ColorScaleColors, ColorScaleMinima } from '../types';
import { usePopover } from 'components/Popover/usePopover';
import { ReversibleColorScaleColorsInput } from '../ReversibleColorScaleColorsInput';
import styled from 'styled-components';
import PencilIcon from 'icons/PencilIcon';
import HelpIcon from 'icons/HelpIcon';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ColorScaleIntervalsInput } from '../ColorScaleIntervalsInput';
import { buildColorScaleIntervals } from '../utils';
import isEqual from 'lodash/isEqual';
import { COLOR_SCALE_MINIMA_DEFAULT } from '../constants';
import LockIcon from 'icons/LockIcon';

/**
 * Contains the values set by the user via `ColorScaleDropdown`. If a value was
 * not modified, it is `undefined`.
 */
export interface OnChangeReturn {
  /**
   * The updated colors of the color scale intervals.
   */
  colors?: Nullable<ColorScaleColors>;

  /**
   * The updated minima of the color scale intervals.
   */
  minima?: ColorScaleMinima;
}

interface ColorScaleDropdownPropsBase {
  /**
   * The base colors of the current color scale intervals.
   */
  colors?: Nullable<ColorScaleColors>;

  /**
   * The title for the color scale selector.
   */
  colorScaleTitle?: string;

  /**
   * The list of color scale colors from which the user can select.
   */
  colorsOptions: Array<ColorScaleColors>;

  /**
   * The default color scale colors.
   */
  defaultColors?: ColorScaleColors;

  /**
   * The label to choose the default color scale colors.
   */
  defaultColorsLabel?: string;

  /**
   * Flag indicating whether the dropdown is currently open. This flag only
   * opens the modal if `target` is also set.
   */
  isOpen: boolean;

  /**
   * The function that is called when the user closes the modal without making
   * any changes.
   */
  onCancel: () => void;

  /**
   * The function that is called when the user commits changes.
   */
  onChange: (values: OnChangeReturn) => void;

  /**
   * The element to which the dropdown will be attached.
   */
  target: Nullable<HTMLElement>;
}

export interface ColorScaleDropdownWithoutIntervalsProps
  extends ColorScaleDropdownPropsBase {
  /**
   * The icon for the button to edit the intervals.
   */
  editIntervalsIcon?: ReactNode;

  /**
   * The content for the tooltip of the button to edit the intervals.
   */
  editIntervalsTooltipContent?: string;

  /**
   * Callback that is invoked when the button to edit the intervals is clicked.
   */
  onClickEditIntervals?: () => void;
}

export interface ColorScaleDropdownWithIntervalsProps
  extends ColorScaleDropdownPropsBase {
  /**
   * The minimum values of all of the current color scale intervals. If
   * `undefined`, the dropdown will disallow access to the interval values
   * input.
   */
  minima?: ColorScaleMinima;
}

export type ColorScaleDropdownProps =
  | ColorScaleDropdownWithoutIntervalsProps
  | ColorScaleDropdownWithIntervalsProps;

export const ColorScaleDropdown = (props: ColorScaleDropdownProps) => {
  const {
    colors,
    colorScaleTitle,
    colorsOptions,
    defaultColors,
    defaultColorsLabel,
    editIntervalsIcon,
    editIntervalsTooltipContent,
    isOpen,
    minima,
    onCancel,
    onChange,
    onClickEditIntervals,
    target
  } = props as ColorScaleDropdownWithoutIntervalsProps &
    ColorScaleDropdownWithIntervalsProps;
  const { Popover, openPopover, closePopover } = usePopover();
  const [isColorsPanel, setIsColorsPanel] = useState(true);
  const [currentMinima, setCurrentMinima] = useState(minima);
  const intervals = useMemo(
    () =>
      colors && currentMinima
        ? buildColorScaleIntervals({
            colors,
            minima: currentMinima,
            minimaDefaults: COLOR_SCALE_MINIMA_DEFAULT
          })
        : undefined,
    [colors, currentMinima]
  );

  useEffect(() => {
    rebuildTooltip();
  }, [isColorsPanel]);

  useEffect(() => {
    if (isOpen && target) {
      // Reset the modal
      setIsColorsPanel(true);
      setCurrentMinima(minima);

      openPopover({ target });
      rebuildTooltip();
    } else {
      closePopover();
    }
  }, [isOpen, openPopover, closePopover, minima, target]);

  const handleColorsSelect = (newColors: Nullable<ColorScaleColors>): void =>
    isEqual(newColors, colors) ? onCancel() : onChange({ colors: newColors });

  const handleIntervalsChange = setCurrentMinima;

  const handleIntervalsSubmit = (): void =>
    isEqual(currentMinima, minima)
      ? onCancel()
      : onChange({ minima: currentMinima });

  const handleToIntervalsPanel = (): void => setIsColorsPanel(false);

  const handleClose = onCancel;

  return (
    <Popover closePopover={handleClose}>
      {isColorsPanel || !intervals ? (
        <Panel>
          <Title
            data-tip="Anyone can set their own color spectrum."
            data-effect="solid"
            data-for="app-tooltip"
          >
            {colorScaleTitle ?? 'Heatmap'}
            <Help>
              <HelpIcon
                circleColor={theme.colors.colorPaleGray10}
                fillColor={theme.colors.colorPaleGray10}
                questionMarkColor={theme.colors.colorPureBlack}
                height="8"
                width="8"
              />
            </Help>
          </Title>
          <ReversibleColorScaleColorsInput
            className="color-scale-colors-input-rev"
            defaultColors={defaultColors}
            defaultColorsLabel={defaultColorsLabel}
            maxItemsVisible={5.5}
            options={colorsOptions}
            onSelect={handleColorsSelect}
            value={colors}
          />
          <Edit
            data-tip={editIntervalsTooltipContent}
            data-class="center"
            data-effect="solid"
            data-for="app-tooltip"
            data-html
            onClick={onClickEditIntervals || (minima && handleToIntervalsPanel)}
          >
            {editIntervalsIcon || (
              <PencilIcon fill={theme.colors.colorRoyalBlue} size={8} />
            )}
            Edit % & Org Default
            {<LockIcon fill={theme.colors.colorRoyalBlue} />}
          </Edit>
        </Panel>
      ) : (
        <Panel>
          <Title
            data-tip="Heatmap Percentages are set<br />at the Organization Level and<br />can only be modified by Admins."
            data-class="center"
            data-effect="solid"
            data-for="app-tooltip"
            data-html
          >
            Heatmap Percentages
            <Help>
              <HelpIcon
                circleColor={theme.colors.colorPaleGray10}
                fillColor={theme.colors.colorPaleGray10}
                questionMarkColor={theme.colors.colorPureBlack}
                height="8"
                width="8"
              />
            </Help>
          </Title>
          <ColorScaleIntervalsInput
            className="color-scale-intervals-input"
            intervals={intervals}
            onChange={handleIntervalsChange}
          />
          <Done onClick={handleIntervalsSubmit}>Done</Done>
        </Panel>
      )}
    </Popover>
  );
};

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 15px 10px 10px;
`;

const Help = styled.span`
  display: inline-flex;
  vertical-align: top;
`;

const Button = styled.button`
  align-items: center;
  border-width: 0;
  border-top: 1px solid ${theme.colors.colorLightGray1};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  width: 100%;
`;

const Edit = styled(Button)`
  background-color: #eff7ff;
  color: ${theme.colors.colorRoyalBlue};
  gap: 5px;
  margin-top: 16px;

  &[data-tip] {
    cursor: not-allowed;
  }
`;

const Done = styled(Button)`
  background-color: #2f80ed;
  color: ${theme.colors.colorPureWhite};
  margin-top: 8px;
`;

const Panel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  & > .color-scale-colors-input-rev,
  & > .color-scale-intervals-input {
    width: 100%;
  }
`;
