import { TeamMember } from 'models/teamMember';
import { ActivityPhaseListItem } from '../types';
import { useAppDispatch } from 'reduxInfra/hooks';
import { createActivityMembers } from 'actionCreators';
import { getCreatePhaseMembersRefetchActions } from 'SuggestionModule/components/FindPeople/utils';
import {
  StyledKaratContainer,
  StyledItemContainer,
  StyledIcon
} from 'SuggestionModule/components/FindPeople/List/styles';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';
import KaratRight from 'icons/KaratRight';
import EllipsisText from 'components/EllipsisText';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import keyBy from 'lodash/keyBy';
import { formatPhaseEntityNameWithCount } from '../helpers/utils';

const activityPhaseMembersFilter = (
  members: TeamMember[],
  activityPhase: ActivityPhaseListItem['activityPhase']
) => {
  const membershipsByAccountId = keyBy(
    activityPhase?.activity_phase_memberships?.filter(
      (member) => !member.discarded_at
    ),
    (item) => item.account_id
  );

  return members.filter(
    (member) => !membershipsByAccountId[member?.account?.id]
  );
};

export const ActivityPhase = (activityPhaseListItem: ActivityPhaseListItem) => {
  const dispatch = useAppDispatch();
  const {
    isOpen,
    toggleCollapse,
    isEmpty,
    projectId,
    visibleMembersCount,
    activityPhase
  } = activityPhaseListItem;
  const { activityTitle, phase_id: phaseId, id } = activityPhase;

  const handleDone = (members: TeamMember[]) => {
    const accountIds = members.map((member) => member.account.id);
    dispatch(
      createActivityMembers({
        projectId,
        activityPhaseId: id,
        accountIds,
        shouldRefetch: false,
        // Default refetch does not support isoState
        // getCreatePhaseMembersRefetchActions returns the actions that needs to be performed when successfully create the phase member
        // but with isoState
        onSuccess: [
          {
            successAction: () => {
              const refetchActions = getCreatePhaseMembersRefetchActions({
                projectId
              });
              refetchActions.forEach((action) => dispatch(action));
            },
            selector: (payload, response) => ({ payload, response })
          }
        ]
      })
    );
  };

  const shouldShowCount = !!(visibleMembersCount && !isOpen);

  const formattedActivityTitle = formatPhaseEntityNameWithCount({
    shouldShowCount,
    name: activityTitle,
    visibleMembersCount
  });

  return (
    <StyledItemContainer
      data-testid="activity-phase-item-container"
      onClick={toggleCollapse}
      className="activity-phase"
    >
      <StyledKaratContainer
        isEmpty={false} // Spec: Showing arrow even phase has no members
        isOpen={isOpen}
      >
        <KaratRight />
      </StyledKaratContainer>
      <SmallDiamondIcon className={undefined} />{' '}
      <EllipsisText
        tooltip={formattedActivityTitle}
        width={undefined}
        maxWidth={220}
        data-testid="activity-title"
      >
        {`${activityTitle}`}
      </EllipsisText>{' '}
      {shouldShowCount ? (
        <span className="total-member-count" data-testid="total-member-count">
          | {visibleMembersCount}
        </span>
      ) : (
        <></>
      )}
      <BulkMemberSelector
        projectId={projectId}
        shouldUseMemberFilter
        shouldNotLoadBoardMembers
        phaseId={phaseId}
        renderSelect={({ onClick }) =>
          isOpen && (
            <StyledIcon
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              <PlusCircleIcon height={15} width={15} />
            </StyledIcon>
          )
        }
        activityPhase={activityPhase}
        handleDone={handleDone}
        membershipLevel={'activity'}
        hideFooter
        memberFilter={(members: TeamMember[]) =>
          activityPhaseMembersFilter(members, activityPhase)
        }
        searchPlaceholder="Type name or select below"
      />
    </StyledItemContainer>
  );
};
