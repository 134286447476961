import { useMemo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  getDisplayedDescriptions,
  getSortedAndGroupedDisplayedDescriptions,
  getTimesheetDateKeys,
  getTimesheetTableColumns,
  getSelectedTeamId,
  getDescriptionsProjectIdsString,
  getIsLoadingDescriptions,
  getPhasesByProjectHash,
  getIsOnOwnTimesheets,
  getPlannerModalAccountId,
  getMyUserId,
  getDateRangeProjectActivities
} from 'selectors';
import ContentLoader from 'react-content-loader';
import Table from 'components/Table';
import TimesheetCell from './TimesheetCell';
import TimesheetColumnHeader from './TimesheetColumnHeader';
import TimesheetFooterTotal from './TimesheetColumnFooter/TimesheetFooterTotal';
import TimesheetColumnHeaderDate from './TimesheetColumnHeader/TimesheetColumnHeaderDate';
import TimesheetDay from './TimesheetColumnFooter/WeeklyTimesheetDay';

import TimesheetColumnHeaderTotal from './TimesheetColumnTotal/TimesheetColumnHeaderTotal';
import TimesheetDateNavigator from './TimesheetDateNavigator';
import { TimesheetMainContainer } from './styles';
import {
  mountTimesheet,
  fetchFilteredActivitiesPerProject,
  fetchRemainingTimesheetHours
} from 'actionCreators';
import { makeGetMemberTimesheetStatusTotalsByDate } from 'UtilizationModule/selectors';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';

const EmptyDiv = () => <div />;

const columnFooterComponents = {
  project: EmptyDiv,
  activity: EmptyDiv,
  description: EmptyDiv,
  date: TimesheetDay,
  total: TimesheetFooterTotal,
  rightPadding: EmptyDiv
};

const columnHeaderComponents = {
  project: TimesheetColumnHeader,
  activity: TimesheetColumnHeader,
  description: TimesheetColumnHeader,
  date: TimesheetColumnHeaderDate,
  total: TimesheetColumnHeaderTotal,
  rightPadding: EmptyDiv
};

const columnWidths = {
  project: 325,
  activity: 202,
  description: 202,
  date: 60,
  total: 66,
  rightPadding: 15
};

const ITEM_HEIGHT = 60;

export const formatActivitiesDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

export const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('YYYY-MM-DD');
const dateRange = 7;
const noop = () => {};
const createRow = {
  createRow: true,
  itemHeight: ITEM_HEIGHT
};
const Timesheet = ({
  formattedDescriptions,
  phasesByProject,
  timesheetColumnHeaders,
  teamId,
  descriptionProjectIdsString,
  isLoadingDescriptions,
  isOnOwnTimesheet,
  selectedAccountId,
  visibleTimeStart,
  myAccountId,
  data,
  dateRangeProjectActivities
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (myAccountId && teamId) {
      const today = moment();
      const startDate = formatActivitiesDate(
        today.clone().subtract(30, 'days')
      );
      const endDate = formatActivitiesDate(today.clone().subtract(1, 'days'));
      dispatch(
        fetchRemainingTimesheetHours({
          teamId,
          ignoreWeeklyCapacity: false,
          startDate,
          endDate,
          accountIds: [myAccountId]
        })
      );
    }
  }, [teamId, myAccountId, dispatch]);

  useEffect(() => {
    dispatch(mountTimesheet({ time: moment().valueOf() }));
  }, [dispatch]);

  const formattedDescriptionsEquality = useMemo(
    () => JSON.stringify(formattedDescriptions),
    [formattedDescriptions]
  );

  const projectIds = useMemo(
    () =>
      [
        ...new Set(
          formattedDescriptions.map((description) => description.project_id)
        )
      ].filter((id) => id),
    // keep formattedDescriptions out of this dep array. old issue (see https://github.com/LifeCoded/mosaic-web/pull/2322/files#)
    // and not entirely clear why
    [formattedDescriptionsEquality]
  );

  useFetchUnloadedProjects({ projectIds });

  const unfetchedFilteredActivitiesProjectIds = useMemo(
    () =>
      projectIds.filter((projectId) => !dateRangeProjectActivities[projectId]),
    [projectIds, dateRangeProjectActivities]
  );

  useEffect(() => {
    if (teamId) {
      const baseDate = visibleTimeStart || moment();
      // The activities date requires a different date format
      const startDateActivities = formatActivitiesDate(
        baseDate.clone().add(-dateRange, 'days')
      );
      const endDateActivities = formatActivitiesDate(
        baseDate.clone().add(dateRange, 'days')
      );
      if (unfetchedFilteredActivitiesProjectIds.length > 0) {
        dispatch(
          fetchFilteredActivitiesPerProject({
            project_ids: unfetchedFilteredActivitiesProjectIds,
            accountId: selectedAccountId,
            startDate: startDateActivities,
            endDate: endDateActivities
          })
        );
      }
    }
  }, [
    dispatch,
    teamId,
    descriptionProjectIdsString,
    visibleTimeStart,
    selectedAccountId,
    myAccountId,
    unfetchedFilteredActivitiesProjectIds
  ]);

  const columns = useMemo(() => {
    return timesheetColumnHeaders.map((timesheetColumnHeader, index) => ({
      ...timesheetColumnHeader,
      Cell: TimesheetCell,
      Header: columnHeaderComponents[timesheetColumnHeader.headerType],
      Footer: columnFooterComponents[timesheetColumnHeader.headerType],
      width: columnWidths[timesheetColumnHeader.headerType],
      index
    }));
  }, [timesheetColumnHeaders]);

  const totalColumnWidth = useMemo(
    () => columns.reduce((acc, cur) => acc + cur.width || 0, 0),
    [columns]
  );

  const rows = useMemo(() => {
    return [createRow, ...formattedDescriptions];
  }, [formattedDescriptions]);

  const timesheetsByDate = useMemo(() => {
    return groupBy(
      flatten(
        formattedDescriptions
          .filter((row) => row.timesheetsByDate)
          .map((row) => Object.values(row.timesheetsByDate))
      ),
      (timesheet) => timesheet.date
    );
  }, [formattedDescriptions]);

  const customRowProps = useMemo(
    () => ({
      memberTimesheetStatusTotalsByDate: data,
      timesheetsByDate
    }),
    [data, timesheetsByDate]
  );

  return (
    <TimesheetMainContainer
      marginLeft={isOnOwnTimesheet ? 0 : '65px'}
      isOnMemberProfile={!isOnOwnTimesheet}
    >
      <TimesheetDateNavigator />
      {!isLoadingDescriptions ? (
        <Table
          columns={columns}
          data={!isLoadingDescriptions ? rows : []}
          onDragEnd={noop}
          itemHeight={ITEM_HEIGHT}
          showFooter={!isLoadingDescriptions}
          customRowProps={customRowProps}
          virtual
          maxHeight={window.innerHeight}
        />
      ) : (
        <div
          style={{
            width: totalColumnWidth,
            height: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ContentLoader style={{ width: '96%', marginTop: 100 }}>
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="22" />
            <rect x="0" y="26" rx="4" ry="4" width="100%" height="22" />
            <rect x="0" y="52" rx="4" ry="4" width="100%" height="22" />
            <rect x="0" y="78" rx="4" ry="4" width="100%" height="22" />
          </ContentLoader>
        </div>
      )}
    </TimesheetMainContainer>
  );
};

const makeMapStateToProps = () => {
  const getMemberTimesheetStatusTotalsByDate =
    makeGetMemberTimesheetStatusTotalsByDate();
  const mapStateToProps = (state, ownProps) => ({
    activityRows: getDisplayedDescriptions(state),
    formattedDescriptions: getSortedAndGroupedDisplayedDescriptions(state),
    dateKeys: getTimesheetDateKeys(state),
    timesheetColumnHeaders: getTimesheetTableColumns(state),
    teamId: getSelectedTeamId(state),
    descriptionProjectIdsString: getDescriptionsProjectIdsString(state),
    phasesByProject: getPhasesByProjectHash(state),
    isLoadingDescriptions: getIsLoadingDescriptions(state),
    isOnOwnTimesheet: getIsOnOwnTimesheets(state),
    selectedAccountId: getPlannerModalAccountId(state),
    myAccountId: getMyUserId(state),
    visibleTimeStart: state.projectPlannerModal.visibleTimeStart,
    data: getMemberTimesheetStatusTotalsByDate(state, ownProps),
    dateRangeProjectActivities: getDateRangeProjectActivities(state)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(Timesheet);
