import React from 'react';
import SvgIcon from 'components/SvgIcon';

const EmptyFlagIcon = ({ width = 8, height = 12, className, fill }) => (
  <SvgIcon
    className={className}
    viewBox="0 0 8 11"
    width={width}
    height={height}
  >
    <path
      fill="gray"
      fillRule="evenodd"
      d="M3.874.658L3.85.646A5.212 5.212 0 0 0 1.077.43L.718.502V.359A.353.353 0 0 0 .357 0 .353.353 0 0 0 0 .359V10.64c0 .203.156.359.359.359a.353.353 0 0 0 .358-.359V5.703l.502-.096a4.46 4.46 0 0 1 2.38.18l.024.011c.574.216 1.172.311 1.77.311.418 0 .836-.048 1.243-.156l.825-.203a.359.359 0 0 0 .275-.346V.944a.357.357 0 0 0-.143-.286.368.368 0 0 0-.311-.06L6.457.8A4.327 4.327 0 0 1 3.874.658zm2.762.837l.382-.096v3.718l-.561.144a4.37 4.37 0 0 1-2.595-.143l-.023-.012a5.315 5.315 0 0 0-1.77-.311c-.335 0-.67.036-1.005.096l-.358.072v-3.72l.502-.095a4.463 4.463 0 0 1 2.379.179l.024.012c.98.347 2.021.406 3.025.156z"
    />
  </SvgIcon>
);

export default EmptyFlagIcon;
