import React from 'react';
import { connect } from 'react-redux';
import {
  getUserIsGuest,
  getSelectedProjectIsPersonal,
  getAllPermissions,
  getUserRoles
} from 'selectors';
import { withRouter } from 'react-router-dom';

class PermissionBasedRenderer extends React.Component {
  render() {
    const {
      permissions,
      roles,
      successRender,
      failRender,
      isProjectPersonal,
      userIsGuest,
      ...props
    } = this.props;
    return userIsGuest && !isProjectPersonal
      ? failRender(props)
      : successRender(props);
  }
}

const mapStateToProps = (state) => ({
  permissions: getAllPermissions(state),
  roles: getUserRoles(state),
  userIsGuest: getUserIsGuest(state),
  isProjectPersonal: getSelectedProjectIsPersonal(state)
});

export default withRouter(connect(mapStateToProps)(PermissionBasedRenderer));
