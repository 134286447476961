import React from 'react';
import styled from 'styled-components';
import ProjectNotificationSettings from 'components/ProjectNotificationSettings';
import MyStar from './MyStar';
import { DATA_TYPES } from 'IntegrationsModule/constants';
import MappingStatusIcon from 'components/MappingStatusIcon';

const MappingStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  padding-top: 1px;
`;

const SettingsColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-height: 100px;
  cursor: pointer;
`;

export const SettingsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

class ProjectSettings extends React.Component {
  setNotificationsRef = (ref) => (this.notificationsRef = ref);
  setColorPickerRef = (ref) => (this.colorPickerRef = ref);

  onNotificationsClick = () => {
    this.notificationsRef.openDropdown();
  };

  onColorPickerClick = () => {
    this.colorPickerRef.handleClick();
  };

  render() {
    const {
      position,
      starProject,
      starType,
      onProjectRow,
      project,
      shouldHide,
      rowNumber,
      isVisible
    } = this.props;
    return (
      <SettingsColumn className="project-inner-left">
        <SettingsBox className="project-settings-box" onClick={starProject}>
          <MyStar
            starType={starType}
            position={position}
            project={project}
            hovered={isVisible}
            onProjectRow={onProjectRow}
          />
        </SettingsBox>
        <MappingStatusIcon
          id={project.id}
          dataType={DATA_TYPES.PROJECTS}
          Container={MappingStatusContainer}
          containerClass={
            isVisible ? 'mapping-status-icon is-visible' : 'mapping-status-icon'
          }
        />
        <SettingsBox
          className="project-settings-box"
          onClick={this.onNotificationsClick}
        >
          <ProjectNotificationSettings
            onProjectRow={onProjectRow}
            project={project}
            rowNumber={rowNumber}
            hovered={isVisible}
            ref={this.setNotificationsRef}
          />
        </SettingsBox>
      </SettingsColumn>
    );
  }
}

export default ProjectSettings;
