import React from 'react';

import { TaskColumnBox, TaskColumnBoxHeightWrapper } from '../styles';
const CellContainer = (props) => {
  const {
    taskProperty,
    handleClick,
    taskProps = {},
    target,
    styles,
    boxStyles,
    row,
    innerRef
  } = props;

  const {
    isTaskModal,
    taskEditProperty,
    isOnTeamProject,
    isComplete,
    isBatchSelected,
    isNewTask,
    isOnHomeView,
    projectDetailView
  } = taskProps;

  const taskIsEditing = row?.original?.customRowProps?.rowEditing;
  const isActive = row?.state?.isActive;

  const isCreatingTask =
    row &&
    row.original &&
    row.original.createRow &&
    row.original.isCreatingNewTask;

  const isHidden =
    row &&
    row.original &&
    row.original.createRow &&
    !row.original.isCreatingNewTask &&
    taskProperty !== 'description' &&
    taskProperty !== 'completed';

  const triggerAddState = () => {
    const { setCreating, id, createRowData } = row.original.list;
    setCreating({ id, createRowData });
    row.setState({ editingProperty: 'description', taskId: row.original.id });
  };

  return (
    <TaskColumnBox
      key={taskProperty}
      onClick={isHidden ? triggerAddState : handleClick}
      isTaskEditProperty={taskProperty === taskEditProperty}
      beingEdited={taskIsEditing}
      isOnTeamProject={isOnTeamProject}
      isComplete={isComplete}
      isBatchSelected={isBatchSelected || row?.original?.isSelected}
      isCreatingTask={isCreatingTask}
      isOnHomeView={isOnHomeView}
      isNewTask={isNewTask}
      isActive={isActive}
      isOnProjectView={projectDetailView}
      taskProperty={taskProperty}
      style={styles}
      isAddRow={isHidden || isNewTask}
      ref={innerRef}
      className="taskCompleteCell"
    >
      <TaskColumnBoxHeightWrapper
        isTaskModal={isTaskModal}
        ref={target}
        style={boxStyles}
        isOnProjectView={projectDetailView}
      >
        {!isHidden && !props.isHidden && props.children}
      </TaskColumnBoxHeightWrapper>
    </TaskColumnBox>
  );
};

export default CellContainer;
