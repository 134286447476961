import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import styled from 'styled-components';
import {
  ChartOuterContainer,
  ChartsInnerContainer,
  ChartsDetails,
  FractionNum,
  FractionDen
} from '../../styles';

import UtilizationSummaryChartTooltip from './UtilizationSummaryChartTooltip';
import {
  formatNumWithMaxOneDecimal,
  calcFontSizeFromDigits
} from 'appUtils/formatUtils';

const defaultFractionStyle = `

font-size: 29px;

`;

const gradients = {
  holiday: 'url(#holidayGradient)',
  pto: 'url(#ptoGradient)'
};

const ChartLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  bottom: 40%;
  ${({ labelStyle }) => labelStyle};
`;

const UtilizationSummaryChart = ({
  items,
  percentBillable,
  total,
  billable,
  chartWidth = 145,
  chartHeight = 72,
  innerRadius = 56,
  outerRadius = 72,
  cy = 70,
  chartLabel = 'SUMMARY',
  shouldShowPaddingAngle,
  fractionStyle = defaultFractionStyle,
  labelStyle,
  isDashboardWidget
}) => {
  const baseFontSize = isDashboardWidget ? 42 : 27;
  const threshold = isDashboardWidget ? 7 : 3;
  const factor = isDashboardWidget ? 3 : 2;
  const displayBillable = formatNumWithMaxOneDecimal(billable);
  const percentTextFontSize = `  font-size: ${calcFontSizeFromDigits(
    displayBillable + '',
    baseFontSize,
    threshold,
    factor
  )}px;`;
  return (
    <ChartOuterContainer>
      <ChartsInnerContainer>
        <ChartsDetails
          style={{
            justifyContent: 'flex-end',
            ...(isDashboardWidget && { marginTop: '8px' })
          }}
        >
          <ChartLabel labelStyle={labelStyle}>{chartLabel}</ChartLabel>
          <FractionNum className="hide-on-hover" fractionStyle={fractionStyle}>
            {percentBillable}%
          </FractionNum>
          <FractionNum
            className="show-on-hover"
            fractionStyle={fractionStyle + percentTextFontSize}
          >
            {displayBillable}h
          </FractionNum>
        </ChartsDetails>
        <PieChart width={chartWidth} height={chartHeight}>
          <Pie
            data={items}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={180}
            endAngle={0}
            fill="#8884d8"
            paddingAngle={shouldShowPaddingAngle ? 1 : 0}
            dataKey={'value'}
            cy={cy}
          >
            {items.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={gradients[entry.name] || entry.color}
                stroke={'#e8e8e8'}
              />
            ))}
          </Pie>
          <Tooltip
            content={
              <UtilizationSummaryChartTooltip
                billable={billable}
                total={total}
              />
            }
            allowEscapeViewBox={{ x: true, y: true }}
            wrapperStyle={{ zIndex: 5 }}
          />
        </PieChart>
      </ChartsInnerContainer>
    </ChartOuterContainer>
  );
};

export default UtilizationSummaryChart;
