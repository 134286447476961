import styled from 'styled-components';
import theme from 'theme';

export const DeleteAccountButton = () => {
  return (
    <StyledButton
      data-for="app-tooltip"
      data-effect="solid"
      data-tip="Click to email us to<br/>delete account"
      data-html
      data-class="center"
    >
      Delete Account
    </StyledButton>
  );
};

const StyledButton = styled.button`
  border: 1px solid ${theme.colors.colorCalendarRed};
  color: ${theme.colors.colorCalendarRed};
  width: 123px;
  height: 33px;
  border-radius: 5px;

  &:hover {
    font-weight: bold;
  }
`;
