import { useCallback } from 'react';
import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

export const useProjectNumberValidator = ({ entityType }) => {
  return useCallback((rows) => validateRows(rows, entityType), [entityType]);
};

export default useProjectNumberValidator;

/* ------------------------------------ - ----------------------------------- */

export const validateRows = (rows, entityType) => {
  return rows.map((row) => {
    const { enteredProjectNumber } = getRowValuesForEntityType(row, entityType);

    // project number mandatory - this validation won't run if project number column
    // is not edited by the user and schema does not have this column as required, so
    // to avoid having to change the table schema there is additional validation in
    // project title validator to check that the project number is not empty
    if (
      !enteredProjectNumber ||
      enteredProjectNumber === TRAINING_DATA_REQUIRED_STRING
    ) {
      return false;
    }
    return true;

    // the following have been commented out due to spec change requiring only that project number is
    // non-empty

    // a single project exists with the same title and project number: valid 1-to-1 mapping
    // if (
    //   matchingProjects?.filter(project => project.title === title)
    //     ?.length === 1
    // ) {
    //   return true;
    // }
    // // no project exists with this project number: valid as new project import
    // if (!matchingProjects?.length) {
    //   return true;
    // }
    // // invalid
    // return false; // tbd on error messages once UI is available
  });
};
