import SvgIcon from 'components/SvgIcon';

const PhaseDiamondIcon = ({
  height = '10',
  width = '9',
  strokeColor = '#828282',
  className = ''
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 9 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2 5.00095L4.59914 1L1 4.99905L4.60086 9L8.2 5.00095Z"
        stroke={strokeColor}
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
};

export default PhaseDiamondIcon;
