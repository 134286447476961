import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const fetchAccess = ({ actableType, actableId, actionType }) =>
  createAction(constants.FETCH_ACCESS.TRIGGER, {
    actableId,
    actableType,
    actionType
  });

export const updateAccess = ({
  actableType,
  actableId,
  actionType, // only used for refetch
  accountIds,
  addableRoleIds,
  removableRoleIds,
  addableAccessBuckets,
  removableAccessBuckets
}) =>
  createAction(constants.UPDATE_ACCESS.TRIGGER, {
    actableId,
    actableType,
    actionType,
    accountIds,
    addableRoleIds,
    removableRoleIds,
    addableAccessBuckets,
    removableAccessBuckets
  });

export const openAccessModal = ({ modalIdentifier }) =>
  createAction(constants.OPEN_ACCESS_MODAL, { modalIdentifier });
export const closeAccessModal = () =>
  createAction(constants.CLOSE_ACCESS_MODAL);
