import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { updatePhase } from 'actionCreators';

import BaselineIcon from 'icons/BaselineIcon';

const WorkdaysContainer = styled.div`
  font-size: 13px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const BaselineIconContainer = styled.div`
  position: absolute;
  left: 65px;
  top: -1px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;

const PhaseUserActivityWorkdaysCell = (props) => {
  const { userActivity, phase } = props.row.original;
  const workdays = userActivity?.display_data?.total_work_days;
  const dispatch = useDispatch();

  const updatePhaseBaseline = () => {
    if (userActivity) {
      dispatch(
        updatePhase({
          id: phase.phase.id,
          projectId: userActivity.project.id,
          baselineUserActivityId: userActivity.user_activity_id
        })
      );
    }
  };

  return (
    <WorkdaysContainer>
      {workdays && (
        <>
          {workdays}d
          <BaselineIconContainer
            onClick={updatePhaseBaseline}
            data-for="app-tooltip"
            data-tip={
              'Click to set this as the phase <br /> baseline workdays.'
            }
            data-class="center"
            data-html
            data-effect="solid"
          >
            <BaselineIcon width={12} height={12} />
          </BaselineIconContainer>
        </>
      )}
    </WorkdaysContainer>
  );
};

export default PhaseUserActivityWorkdaysCell;
