import { useRef, useMemo } from 'react';
import styled from 'styled-components';
import {
  OptionsDropdown,
  OptionConfig
} from 'components/options/OptionsDropdownV2';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';
import { LabelWithIcon } from 'components/labels/LabelWithIcon';
import PaperIcon from 'icons/PaperIcon';

type UtilizationReportThreeDotOptions = 'printPage';

export const UtilizationThreeDot = ({
  handlePrint
}: {
  handlePrint: () => void;
}) => {
  const toggleRef = useRef(null);

  const optionHash: {
    [key in UtilizationReportThreeDotOptions]: OptionConfig;
  } = useMemo(
    () => ({
      printPage: {
        label: <LabelWithIcon icon={<PaperIcon />} label="Print Page" />,
        handleClick: handlePrint
      }
    }),
    [handlePrint]
  );

  return (
    <ThreeDotContianer
      ref={toggleRef}
      className={'utilization-report-dropdown active'}
    >
      <OptionsDropdown<typeof optionHash>
        options={options}
        optionHash={optionHash}
        renderToggle={({ isActive }) => (
          <ThreeDotHorizontal
            className="three-dot"
            active={isActive}
            strokeWidth={0.3}
            size={22}
          />
        )}
        isActive={false}
      />
    </ThreeDotContianer>
  );
};

const options: UtilizationReportThreeDotOptions[] = ['printPage'];

const ThreeDotContianer = styled.div`
  .options-dropdown-menu {
    padding: 0;
    margin: 0;
  }
`;
