import { SimpleConfirmModal } from 'components/Modals';

export const NewRateOvertakeConfirmationModal = ({
  onClose,
  onConfirm
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <SimpleConfirmModal
    body="Adding a new rate will overwrite and delete other rates. Do you want to add a new rate?"
    confirmLabel="Change"
    header="Add New Rate"
    isOpen
    onConfirm={onConfirm}
    shouldSetIsClosingOnClose={false}
    toggle={onClose}
  />
);
