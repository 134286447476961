import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import MonthPicker, { getDefaultStartDate } from './MonthPicker';
import CalendarIcon from 'icons/CalendarIcon';
import moment from 'appUtils/momentConfig';

const DefaultToggle = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${theme.colors.colorRoyalBlue};
  ${({ isDisabled }) =>
    !isDisabled
      ? `
    &:hover {
      cursor: pointer;
      filter: brightness(85%);
    }
  `
      : `
     cursor: not-allowed;
     svg {
      path {
        fill: ${theme.colors.colorMediumGray9};
      }
     }
     span {
      color: ${theme.colors.colorMediumGray9};
     }
  `}

  svg {
    margin-right: 6px;
    margin-bottom: 1px;
  }
`;

const MonthPickerDropdown = ({
  startDate,
  onSubmit,
  onClear,
  customToggle,
  customTargetRef,
  interval,
  popoverClassName,
  hideRangePanel,
  isDisabled
}) => {
  const targetRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const hidePicker = () => setShowPicker(false);

  const defaultStartDate = getDefaultStartDate(interval);

  const renderToggle = () => {
    if (customToggle) {
      return customToggle();
    }
    return (
      <DefaultToggle isDisabled={isDisabled}>
        <CalendarIcon
          height={12}
          width={12}
          currentColor={theme.colors.colorRoyalBlue}
        />
        <span>{moment(startDate || defaultStartDate).format('MMM, YYYY')}</span>
      </DefaultToggle>
    );
  };

  return (
    <div
      onClick={() => (isDisabled ? undefined : setShowPicker(true))}
      ref={customTargetRef || targetRef}
    >
      {renderToggle()}
      {showPicker && (
        <MonthPicker
          isOpen
          toggle={hidePicker}
          startDate={startDate}
          onClear={onClear}
          onSubmit={onSubmit}
          targetRef={targetRef}
          interval={interval}
          popoverClassName={popoverClassName}
          hideRangePanel={hideRangePanel}
        />
      )}
    </div>
  );
};

export default React.memo(MonthPickerDropdown);
