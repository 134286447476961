import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PriorityIcon = ({ width = '14', height = '12', className }) => (
  <SvgIcon
    className={className}
    viewBox="0 0 14 12"
    fill="none"
    width={width}
    height={height}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96496 1H1.71429C1.3198 1 1 1.3198 1 1.71429C1 2.10878 1.3198 2.42857 1.71429 2.42857H5.96495C6.35945 2.42857 6.67924 2.10877 6.67924 1.71429C6.67924 1.3198 6.35945 1 5.96496 1ZM1.71429 0C0.767512 0 0 0.767512 0 1.71429C0 2.66106 0.767511 3.42857 1.71429 3.42857H5.96495C6.91173 3.42857 7.67924 2.66106 7.67924 1.71429C7.67924 0.767512 6.91173 0 5.96496 0H1.71429ZM8.88962 5.28589H4.24272C3.84823 5.28589 3.52843 5.60569 3.52843 6.00017C3.52843 6.39466 3.84823 6.71446 4.24272 6.71446H8.88962C9.28411 6.71446 9.6039 6.39466 9.6039 6.00017C9.6039 5.60569 9.28411 5.28589 8.88962 5.28589ZM4.24272 4.28589C3.29594 4.28589 2.52843 5.0534 2.52843 6.00017C2.52843 6.94695 3.29594 7.71446 4.24272 7.71446H8.88962C9.83639 7.71446 10.6039 6.94695 10.6039 6.00017C10.6039 5.0534 9.83639 4.28589 8.88962 4.28589H4.24272ZM7.63874 9.57129H12.2856C12.6801 9.57129 12.9999 9.89109 12.9999 10.2856C12.9999 10.6801 12.6801 10.9999 12.2856 10.9999H7.63874C7.24425 10.9999 6.92446 10.6801 6.92446 10.2856C6.92446 9.89109 7.24425 9.57129 7.63874 9.57129ZM5.92446 10.2856C5.92446 9.3388 6.69197 8.57129 7.63874 8.57129H12.2856C13.2324 8.57129 13.9999 9.3388 13.9999 10.2856C13.9999 11.2323 13.2324 11.9999 12.2856 11.9999H7.63874C6.69197 11.9999 5.92446 11.2323 5.92446 10.2856Z"
      fill="#9b9b9b"
    />
  </SvgIcon>
);

export default PriorityIcon;
