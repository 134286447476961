import { useMemo } from 'react';
import { AccountId } from 'models/account';
import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { TIMELINE_GROUP_TYPES } from 'TimelinesModule/components/constants';
import { WorkPlanFetchSource } from 'TimelinesModule/hooks/types';
import { useFetchWorkPlans } from 'TimelinesModule/hooks/useFetchWorkPlans';
import { MemberGroup } from 'TimelinesModule/types/memberGroup';
import { WorkPlanItem } from 'TimelinesModule/types/WorkPlanItem';
import {
  mapTeamMemberToMemberGroup,
  serializeGroupId
} from 'TimelinesModule/utils/groupMapperUtils';
import { mapWorkPlanToWorkPlanItem } from 'TimelinesModule/utils/itemMapperUtils';
import { useFetchUtilizationBreakdown } from 'TimelinesModule/hooks/useFetchUtilizationsBreakdown';
import {
  UtilizationBreakdownsByGroup,
  WorkFromHomesByGroup
} from 'TimelinesModule/components/RowRenderers/types';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { getProjectHash } from 'ProjectsModule/selectors';
import {
  getAllActivityRowInfo,
  getMyWorkloadSettings,
  getWFHProject
} from 'selectors';
import { Activity, ActivityId } from 'models/activity';
import {
  getAccountCapacities,
  getAverageCapacities
} from 'CapacityModule/selectors';
import { AccountCapacityWithTotal } from 'CapacityModule/types';
import { getBufferedTimeRange } from 'TimelinesModule/utils/timeRangeUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
import { Project } from 'ProjectsModule/models/project';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import { useFetchTimeEntryTotal } from 'TimelinesModule/hooks/useFetchTimeEntryTotal';
import { merge } from 'lodash';
import { MemberAvailabilityTimelineConfig } from './types';
import { WorkloadSettings } from 'TimelinesModule/types/timelineSettings';
import { mapAccountCapacitiesToWorkFromHomes } from 'TimelinesModule/utils/rowMapperUtils';

interface useMemberAvailabilityTimelineDataProps {
  accountIds: AccountId[];
  // lazy load work plans and time entries total only when rows are open
  openAccountIds: AccountId[];
  startTime: EpochTimeStamp;
  endTime: EpochTimeStamp;
}

const memberAvailabilityTimelineFilterStateId = 'member-availability-timeline';
/**
 * useMemberAvailabilityTimelineData will fetch all the data needed for the member availability timeline
 * - Work Plans
 *   - Project
 *   - Phase
 *   - Activity
 * - Capacity
 *   - Account Capacity
 *   - Team Capacity
 *   - Holiday
 * - Utilizations
 *   - Utilization
 *   - Utilization Report for Time Entry Total
 * - WFH
 */
export const useMemberAvailabilityTimelineData = ({
  accountIds,
  openAccountIds,
  startTime,
  endTime
}: useMemberAvailabilityTimelineDataProps): {
  groups: MemberGroup[];
  items: WorkPlanItem[];
  utilizationsBreakdownByGroup: UtilizationBreakdownsByGroup;
  workFromHomesByGroup: WorkFromHomesByGroup;
  timelineConfig?: Partial<MemberAvailabilityTimelineConfig>;
} => {
  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  // member availability timeline partially inherits workload settings
  const workloadSettings = useAppSelector(getMyWorkloadSettings) as
    | WorkloadSettings
    | undefined;

  const { startTime: bufferedStartTime, endTime: bufferedEndTime } = useMemo(
    () =>
      getBufferedTimeRange({
        startTime,
        endTime,
        zoom: ZOOM_LEVELS.WEEK
      }),
    [startTime, endTime]
  );

  // work plans
  const { workPlans } = useFetchWorkPlans({
    ids: openAccountIds,
    fetchSource: WorkPlanFetchSource.Member,
    startDate: new Date(bufferedStartTime),
    endDate: new Date(bufferedEndTime)
  });

  // utilization
  const { utilizationsBreakdownByMember } = useFetchUtilizationBreakdown({
    accountIds,
    startDate: new Date(bufferedStartTime),
    endDate: new Date(bufferedEndTime),
    includeTentative: Boolean(workloadSettings?.show_tentative_plans),
    isReady: !!workloadSettings
  });

  const projectHash = useAppSelector(getProjectHash);
  const flatPhasesHash = useAppSelector(getFlatPhasesHash);
  const activityHash = useAppSelector(getAllActivityRowInfo) as Record<
    ActivityId,
    Activity
  >;

  const workPlansProjectIds = useMemo(
    () => workPlans.map(({ project_id }) => project_id),
    [workPlans]
  );

  // fetch projects
  useFetchUnloadedProjects({
    projectIds: workPlansProjectIds
  });

  const { timeEntryTotals } = useFetchTimeEntryTotal({
    accountIds,
    filterStateId: memberAvailabilityTimelineFilterStateId,
    startDate: new Date(bufferedStartTime),
    endDate: new Date(bufferedEndTime)
  });

  const accountAverageCapacity = useAppSelector(getAverageCapacities) as Record<
    AccountId,
    number
  >;

  const accountCapacities = useAppSelector(getAccountCapacities) as Record<
    AccountId,
    AccountCapacityWithTotal
  >;

  const groups: MemberGroup[] = useMemo(
    () =>
      accountIds.map((accountId) => {
        const teamMember = teamMembershipsByAccountId[accountId];

        if (teamMember) {
          return mapTeamMemberToMemberGroup({
            teamMember,
            totalCapacity: accountCapacities[accountId]?.total
          });
        }

        // TODO: implement groups loading state
        return {
          id: serializeGroupId({
            id: accountId,
            groupType: TIMELINE_GROUP_TYPES.MEMBER
          }),
          title: '',
          type: TIMELINE_GROUP_TYPES.MEMBER,
          accountId
        };
      }),
    [accountCapacities, accountIds, teamMembershipsByAccountId]
  );

  const WFHProject = useAppSelector(getWFHProject) as Project | undefined;

  const items: WorkPlanItem[] = useMemo(
    () =>
      workPlans
        .filter((workPlan) => workPlan.project_id !== WFHProject?.id)
        .map((workPlan) => {
          const groupId = serializeGroupId({
            id: workPlan.account_id,
            groupType: TIMELINE_GROUP_TYPES.WORK_PLAN
          });
          return mapWorkPlanToWorkPlanItem({
            groupId,
            workPlan,
            project: projectHash[workPlan.project_id],
            phase: flatPhasesHash[workPlan.phase_id],
            activity: activityHash[workPlan.activity_id],
            averageCapacity: accountAverageCapacity[workPlan.account_id],
            dailyCapacity: accountCapacities[workPlan.account_id]
          });
        }),
    [
      workPlans,
      WFHProject?.id,
      projectHash,
      flatPhasesHash,
      activityHash,
      accountAverageCapacity,
      accountCapacities
    ]
  );

  const utilizationsBreakdownByGroup = useMemo<UtilizationBreakdownsByGroup>(
    () =>
      groups.reduce((acc, group) => {
        if (group.type === TIMELINE_GROUP_TYPES.MEMBER) {
          acc[group.id] = merge(
            {},
            utilizationsBreakdownByMember?.[group.accountId],
            timeEntryTotals?.[group.accountId]
          );
        }
        return acc;
      }, {}),
    [groups, timeEntryTotals, utilizationsBreakdownByMember]
  );

  const workFromHomesByGroup = useMemo<WorkFromHomesByGroup>(() => {
    if (!WFHProject) return {};

    const groupIdSerializer = (id: AccountId) =>
      serializeGroupId({
        id,
        groupType: TIMELINE_GROUP_TYPES.MEMBER
      });

    return mapAccountCapacitiesToWorkFromHomes({
      accountCapacities,
      WFHProjectId: WFHProject.id,
      groupIdSerializer
    });
  }, [WFHProject, accountCapacities]);

  return {
    groups,
    items,
    utilizationsBreakdownByGroup,
    workFromHomesByGroup,
    timelineConfig: {
      capacityHeatMapColors:
        workloadSettings?.capacity_heat_map_colors ?? undefined,
      capacityHeatMapIntervals:
        workloadSettings?.capacity_heat_map_intervals ?? undefined,
      showTimesheetTime: workloadSettings?.show_timesheet_time,
      showMemberCapacity: workloadSettings?.show_member_capacity,
      showMemberRole: workloadSettings?.show_member_role
    }
  };
};
