import { createSelector } from 'reselect';

export const getUserPermissions = (state) => state.userPermissions;
export const getAllPermissions = createSelector(
  getUserPermissions,
  (permissions) => permissions.allPermissions
);
export const getIsUserPermissionsLoaded = createSelector(
  getUserPermissions,
  (permissions) => permissions.userPermissionsLoaded
);
export const getUserRoles = createSelector(
  getUserPermissions,
  (permissions) => permissions.roles
);
export const getActionDenied = createSelector(
  getUserPermissions,
  (state) => state.actionDenied
);
export const getErrorMessage = createSelector(
  getUserPermissions,
  (state) => state.errorMessage
);

// For displaying user friendly error messages regardless of split flag
export const getIsUserFriendlyError = createSelector(
  getUserPermissions,
  (state) => state.isUserFriendlyError
);
// For displaying front end errors regardless of split flag
export const getIsFeError = createSelector(
  getUserPermissions,
  (state) => state.isFeError
);
export const getIsPermissionError = createSelector(
  getUserPermissions,
  (state) => state.isPermissionError
);
