import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TaskCommentsContainer } from 'views';
import { StyledTaskContentBottom } from './styles';
import { triggerTasksAttributesUpdate } from 'actionCreators';
import { getMe, getIsOnTeamProject } from 'selectors';

class TaskContentBottom extends Component {
  render() {
    const {
      task,
      me,
      isSelectedHomeTask,
      isOnTeamProject,
      isTaskModal,
      selectedProject
    } = this.props;

    return (
      <StyledTaskContentBottom
        isSelectedHomeTask={isSelectedHomeTask}
        isOnTeamProject={isOnTeamProject}
        isTaskModal={isTaskModal}
      >
        <TaskCommentsContainer
          task={task}
          me={me}
          taskType="projects"
          projectId={task.project_id}
          taskListView
          isSelectedHomeTask={isSelectedHomeTask}
          selectedProject={selectedProject}
        />
      </StyledTaskContentBottom>
    );
  }
}

const mapStateToProps = (state) => ({
  me: getMe(state),
  isOnTeamProject: getIsOnTeamProject(state)
});
const mapDispatchToProps = {
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskContentBottom)
);
