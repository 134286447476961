import {
  StyledCheckBox,
  OuterStyledCheckBox
} from 'BudgetModule/components/styles';

/**
 * @deprecated Use the typesafe version from `components/Checkbox`.
 */
const CheckBox = ({
  checked,
  toggle,
  outerBoxClassName,
  innerBoxClassName
}) => (
  <OuterStyledCheckBox
    className={outerBoxClassName}
    checked={checked}
    onClick={toggle}
  >
    <StyledCheckBox className={innerBoxClassName} checked={checked} />
  </OuterStyledCheckBox>
);
export default CheckBox;
