import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isNumeric } from './utils';
import { RowValidator } from 'CsvImportsModule/types';

export const totalHoursValidator: RowValidator = ({ row, entityType }) => {
  const { enteredHoursPerDay, enteredTotalHours } = getRowValuesForEntityType(
    row,
    entityType
  );

  if (enteredTotalHours === TRAINING_DATA_REQUIRED_STRING) {
    return false;
  } else if (!enteredHoursPerDay && !enteredTotalHours) {
    return ERROR_MESSAGES.missingHourFields;
  } else if (enteredTotalHours) {
    if (!isNumeric(enteredTotalHours))
      return ERROR_MESSAGES.enteredHoursIsNotNumeric;
    else if (enteredHoursPerDay) {
      if (+enteredHoursPerDay === 0 && +enteredTotalHours === 0)
        return ERROR_MESSAGES.hoursPerDayAndTotalHoursAreZero;
      else return ERROR_MESSAGES.tooManyHourFields;
    }
  }
  return true;
};
