import {
  formatStartToEndDateTime,
  pluralizeTimeUnit,
  isTomorrow,
  hasPassed,
  isHappening
} from 'appUtils/momentUtils';
import moment from 'appUtils/momentConfig';
import 'moment-precise-range-plugin';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

export const formatIntegrationEventTime = ({
  valueByUnits,
  startDateString,
  endDateString
}) => {
  const today = moment();

  const formattedTime = formatStartToEndDateTime({
    startDate: startDateString,
    endDate: endDateString
  });

  if (isHappening(today, startDateString, endDateString)) {
    return {
      eventTime: `Event Happening, ${formattedTime}`,
      formattedTime
    };
  }

  if (isTomorrow(today, startDateString)) {
    return {
      eventTime: `Tomorrow ${formattedTime}`,
      formattedTime
    };
  }

  if (hasPassed(today, startDateString)) {
    return {
      eventTime: 'Event ended',
      formattedTime
    };
  }

  const nonZeroUnits = valueByUnits
    .filter((time) => time.value > 0)
    .map(({ unit, value, noSpaceBetweenUnitAndValue, doNotPluralize }) => {
      const formattedUnit = pluralizeTimeUnit({ unit, value, doNotPluralize });
      if (noSpaceBetweenUnitAndValue) {
        return `${value}${formattedUnit}`;
      }
      return `${value} ${formattedUnit}`;
    });

  return {
    eventTime: `Starting in ${nonZeroUnits.join(' ')}, ${formattedTime}`,
    formattedTime
  };
};

export const generateIntegrationEvent = ({
  event,
  project,
  phase,
  projectColors,
  type,
  eventIdSuffix
}) => {
  const { id, start_datetime, end_datetime } = event;

  const startDateMoment = moment(start_datetime);
  const todayMoment = moment();
  const { years, months, days, hours, minutes } = moment.preciseDiff(
    todayMoment,
    startDateMoment,
    true
  );

  const valueByUnits = [
    {
      unit: 'year',
      value: years
    },
    {
      value: months,
      unit: 'month'
    },
    {
      value: days,
      unit: 'day'
    },
    {
      unit: 'h',
      noSpaceBetweenUnitAndValue: true,
      doNotPluralize: true,
      value: hours
    },
    {
      unit: 'min',
      doNotPluralize: true,
      value: minutes
    }
  ];

  const { eventTime, formattedTime } = formatIntegrationEventTime({
    valueByUnits,
    startDateString: start_datetime,
    endDateString: end_datetime
  });

  return {
    eventId: `${eventIdSuffix}-${id}`,
    date: formatDate(start_datetime),
    type,
    displayColor: projectColors?.[project?.id],
    data: {
      ...event,
      sortByDate: start_datetime,
      eventTime,
      formattedTime,
      phase,
      project
    }
  };
};

export const formatIntegrationEventCalendarTooltip = ({
  phase,
  project,
  event
}) => {
  return {
    phase,
    id: phase?.id,
    isPhase: phase?.is_budget,
    phaseName: phase?.name,

    project,
    projectId: project?.id,
    projectName: project?.title,

    isIntegrationEventTooltip: true,
    event,
    eventId: event.eventId,
    customDate: event.data?.formattedTime,
    eventTitle: event.data?.title
  };
};

export const getIntegrationEventStartAndEndDateParams = (monthKey) => {
  const startOfMonth = moment(monthKey)
    .startOf('month')
    .format('YYYY-MM-DD HH:SS');
  const endOfMonth = moment(monthKey).endOf('month').format('YYYY-MM-DD HH:SS');

  return {
    startOfMonth,
    endOfMonth
  };
};
