import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportDiscipline
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingDisciplinesByName: Record<string, CsvImportDiscipline>;
  newDisciplines: React.MutableRefObject<Record<string, boolean>>;
}

export const disciplineValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingDisciplinesByName, newDisciplines } = additionalParams;

  const { enteredDiscipline } = getRowValuesForEntityType(row, entityType);

  const existingDisciplineMatchedByEnteredName =
    existingDisciplinesByName[enteredDiscipline];

  const enteredDisciplineIsNewName =
    enteredDiscipline &&
    !existingDisciplineMatchedByEnteredName &&
    !newDisciplines.current[enteredDiscipline];

  if (enteredDiscipline === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (
    // if entered discipline is non empty and is a new discipline, add to new discipline hash
    enteredDisciplineIsNewName
  ) {
    newDisciplines.current[enteredDiscipline] = true;
    return true;
  } else return true;
};
