import React, { useMemo } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import { getTeamSlug } from 'selectors';

import ReactTooltip from 'react-tooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import * as constants from 'appConstants';
import { navigateToMembersLocationsSettings } from 'actionCreators';

const HeaderText = styled.div`
  font-size: 22px;
`;

const SubHeaderText = styled.div`
  font-size: 14px;
  font-weight: normal;

  margin-top: -5px;

  color: #828282;
`;

const RangeItemRow = styled.div`
  padding-left: 30px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .remove-text {
    color: ${theme.colors.colorDeleteRed};
    display: ${({ isSelected }) => (isSelected ? 'none' : 'flex')};
  }
  .added-text {
    color: ${theme.colors.colorCalendarBlue};
    display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    .added-text {
      display: ${({ isSelected }) => (isSelected ? 'none' : 'flex')};
    }
    .remove-text {
      display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
    }
  }
`;

const StyledItemName = styled.div`
  font-size: 14px;
`;

const Footer = (
  <LockWithTooltip
    text="Manage Locations"
    tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Locations."
  />
);

const copy = {
  headerInitial: 'Locations',
  headerEdit: '',
  headerAdd: '',
  sticky: '',
  footerInitial: Footer,
  footerEdit: Footer,
  addConfirm: '',
  editConfirm: ''
};

const DistanceDropdown = ({ onClose, targetRef, selectedRange }) => {
  const dispatch = useDispatch();
  const teamSlug = useSelector(getTeamSlug);

  const listItems = useMemo(() => {
    const selectedRangeRow = [];
    const unselectedRangeRows = [];
    if (selectedRange) {
      selectedRangeRow.push({
        id: selectedRange
      });
    }

    constants.LOCATION_DISTANCES.forEach((id) => {
      if (id !== selectedRange) {
        unselectedRangeRows.push({
          id
        });
      }
    });

    return [...selectedRangeRow, ...unselectedRangeRows];
  }, [selectedRange]);

  const handleSelect = (e, { item }) => {
    e.preventDefault();
    const { id } = item;

    if (!id) {
      return;
    }

    handleClose(id);
  };

  const handleClose = (id) => {
    if (onClose) onClose(id);
  };

  const onFooterClick = () => {
    ReactTooltip.hide();
    dispatch(
      navigateToMembersLocationsSettings({
        teamSlug,
        openInNewWindow: true
      })
    );
    handleClose();
  };

  /* --------------------------------------- render ----------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;
    const isSelected = item.id === selectedRange;

    if (isSelected) {
      return (
        <RangeItemRow isSelected>
          <StyledItemName>{`${item.id} Miles`}</StyledItemName>
          <div>
            <div className="added-text">Selected</div>
            <div className="remove-text">Remove</div>
          </div>
        </RangeItemRow>
      );
    } else {
      return (
        <RangeItemRow>
          <StyledItemName>{`${item.id} Miles`}</StyledItemName>
        </RangeItemRow>
      );
    }
  };

  const renderHeaderButton = () => {
    return null;
  };

  const renderHeaderText = () => {
    return (
      <>
        <HeaderText>Location</HeaderText>
        <SubHeaderText>Distance to Project</SubHeaderText>
      </>
    );
  };

  return (
    <>
      <MultiStepFlyout
        copy={copy}
        target={targetRef}
        items={listItems}
        idKey="id"
        renderHeader={renderHeaderText}
        renderHeaderButton={renderHeaderButton}
        renderItem={renderItem}
        handleSelect={handleSelect}
        isWhite
        handleClose={handleClose}
        onFooterClick={onFooterClick}
        popoverClassName="add-requirements-dropdown"
        listWidth={200}
        listHeight={200}
        itemHeight={48}
        isItemUnSelectable={(item) => item.isSectionHeader}
        canMultiSelect
        noMinWidth={true}
      />
    </>
  );
};

export default DistanceDropdown;
