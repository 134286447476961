import { createSelector, Selector } from '@reduxjs/toolkit';
import { Sections } from 'appConstants/navigation';
import { SPLIT_SCREEN_TYPES } from 'appConstants/workload';
import { RootState } from 'reduxInfra/shared';
import { NavigationHistory, RouteHistoryState } from './types';

const getRouteHistoryState: Selector<RootState, RouteHistoryState> = (state) =>
  state.routeHistory;

export const getNavigationHistory = createSelector(
  getRouteHistoryState,
  (state) => state.navigationHistory
);

export const getMatchedRouteParams = createSelector(
  getRouteHistoryState,
  (state) => state.matchedParams
);

export const getNavigatingTo = createSelector(
  getRouteHistoryState,
  (state) => state.navigatingTo
);

export const getNavigationState = (
  navigationHistory: Array<NavigationHistory>,
  index = 0
) => navigationHistory[index];

export const isOnTeamSettingsView = (
  navigationHistory: Array<NavigationHistory>,
  index = 0
) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState?.section === Sections.TEAM_SETTINGS;
};

/* Split screen navigation selectors */

const getWorkloadSplitScreenActive = (state) =>
  state.workloadPlanner.isWorkloadSplitScreenActive;
const getWorkloadSplitScreenAccountId = (state) =>
  state.workloadPlanner.splitScreenAccountId;
const getWorkloadSplitScreenType = (state) =>
  state.workloadPlanner.workloadSplitScreenType;

export const isOnPhaseSplitScreen = (
  navigationHistory: Array<NavigationHistory>,
  index = 0,
  workloadSplitScreenActive: boolean,
  workloadSplitScreenAccountId: number | null,
  workloadSplitScreenType: string | null
) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState?.section === Sections.WORKLOAD &&
    workloadSplitScreenActive &&
    !!workloadSplitScreenAccountId &&
    workloadSplitScreenType === SPLIT_SCREEN_TYPES.PHASE
  );
};

export const isOnScheduledTaskSplitScreen = (
  navigationHistory: Array<NavigationHistory>,
  index = 0,
  workloadSplitScreenActive: boolean,
  workloadSplitScreenAccountId: number | null,
  workloadSplitScreenType: string | null
) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState?.section === Sections.WORKLOAD &&
    workloadSplitScreenActive &&
    workloadSplitScreenAccountId &&
    workloadSplitScreenType === SPLIT_SCREEN_TYPES.SCHEDULED_TASK
  );
};

export const isOnWorkplanSplitScreen = (
  navigationHistory: Array<NavigationHistory>,
  index = 0,
  workloadSplitScreenActive: boolean,
  workloadSplitScreenAccountId: number | null,
  workloadSplitScreenType: string | null
) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState?.section === Sections.WORKLOAD &&
    workloadSplitScreenActive &&
    workloadSplitScreenAccountId &&
    workloadSplitScreenType === SPLIT_SCREEN_TYPES.WORK_PLAN
  );
};

export const getIsOnPhaseSplitScreen = createSelector(
  getNavigationHistory,
  () => 0,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenAccountId,
  getWorkloadSplitScreenType,
  isOnPhaseSplitScreen
);

export const getIsOnScheduledTaskSplitScreen = createSelector(
  getNavigationHistory,
  () => 0,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenAccountId,
  getWorkloadSplitScreenType,
  isOnScheduledTaskSplitScreen
);

export const getIsOnWorkplanSplitScreen = createSelector(
  getNavigationHistory,
  () => 0,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenAccountId,
  getWorkloadSplitScreenType,
  isOnWorkplanSplitScreen
);

export const getIsOnWorkloadSplitScreen = createSelector(
  getIsOnPhaseSplitScreen,
  getIsOnScheduledTaskSplitScreen,
  getIsOnWorkplanSplitScreen,
  (
    isOnPhaseSplitScreen,
    isOnScheduledTaskSplitScreen,
    isOnWorkplanSplitScreen
  ) =>
    isOnScheduledTaskSplitScreen ||
    isOnPhaseSplitScreen ||
    isOnWorkplanSplitScreen
);
