import { MouseEvent } from 'react';
import styled from 'styled-components';
import { RowRendererProps } from '../types';

export const FooterRowCell = ({ row: { original } }: RowRendererProps) => {
  const { onFooterRowAddClick, buttonRef, rowData: groupData } = original;

  const handleClickAddButton = (event: MouseEvent<HTMLButtonElement>) => {
    onFooterRowAddClick && onFooterRowAddClick(event, groupData);
  };
  return (
    <FooterCellContainer>
      <AddMemberButton ref={buttonRef} onClick={handleClickAddButton}>
        + Member
      </AddMemberButton>
    </FooterCellContainer>
  );
};

const FooterCellContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 44px;
`;

const AddMemberButton = styled.button`
  padding: 4px 8px;
  font-size: 15px;
  font-weight: 400;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
`;
