import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';
import {
  getRowValuesForEntityType,
  enteredFieldIsTrue,
  isValidBooleanString
} from './utils';
import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
interface AdditionalParams {
  billablePhaseContractTypes: string[];
  nonBillablePhaseContractTypes: string[];
}

export const phaseContractTypeOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { billablePhaseContractTypes, nonBillablePhaseContractTypes } =
    additionalParams;
  const { enteredPhaseBillable } = getRowValuesForEntityType(row, entityType);
  const enteredBillableIsValidBooleanString =
    isValidBooleanString(enteredPhaseBillable);
  // if the project title + project number + phase name combination has no matched contract type,
  // then show all contract types options
  if (
    enteredPhaseBillable === TRAINING_DATA_REQUIRED_STRING ||
    !enteredPhaseBillable ||
    !enteredBillableIsValidBooleanString
  )
    return [...billablePhaseContractTypes, ...nonBillablePhaseContractTypes];
  else if (enteredFieldIsTrue(enteredPhaseBillable))
    return billablePhaseContractTypes;
  else return nonBillablePhaseContractTypes;
};
