import { lazy, Suspense, useMemo } from 'react';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import DevProps from 'components/dev/DevProps';
import GradientsDefs from '../GradientsDefs';
import { SectionRoutes } from 'appConstants/navigation';
import { VIEW_BY } from 'appConstants/workload';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { CUSTOM_FILTER_VALUES } from 'appConstants/filters';

const CapacityTableContainer = () =>
  import('./CapacityTable/CapacityTableContainer');
const CapacityChartContainer = () =>
  import('./CapacityChart/CapacityChartContainer');

const capacityReportTypes = {
  [VIEW_BY.MEMBERS]: CapacityTableContainer,
  [VIEW_BY.PROJECTS]: CapacityTableContainer,
  [VIEW_BY.CLIENTS]: CapacityTableContainer,
  [VIEW_BY.ORGANIZATION]: CapacityChartContainer
  // [VIEW_BY.TEAM]: CapacityChartContainer
};

const filterStateIds = {
  [VIEW_BY.MEMBERS]: 'workload-report-table-members',
  [VIEW_BY.PROJECTS]: 'workload-report-table-projects',
  [VIEW_BY.ORGANIZATION]: 'workload-org-chart',
  [VIEW_BY.CLIENTS]: 'workload-report-table-clients'
};

const getCapacityReportComponent = (viewBy) =>
  lazy(capacityReportTypes[viewBy]);

const CapacityTab = ({
  widgetDetailModalThreeDotMenuItems,
  isDashboardWidgetModal,
  widgetType
}) => {
  const {
    filterId,
    viewBy,
    pageName,
    activeFilter,
    widget,
    widgetConfig,
    filterSections
  } = useReportsViewFilter({
    ...(isDashboardWidgetModal && {
      matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType/:reportViewType`,
      widgetType
    })
  });

  const CapacityReportComponent = useMemo(
    () => getCapacityReportComponent(viewBy),
    [viewBy]
  );

  const activeFilterWithOverridenDateRange = useMemo(
    () => ({
      ...activeFilter,
      // override range value if widget config's range option is disabled
      // disabled = user can not adjust the date range themselves
      // this is being done here instead of `useReportsViewFilter` because
      // overriding values inside filter selector may cause more issues and it is hard to test,
      // the purpose is to support backward compatibility where widgets thats already being created
      // will not have the new range option.
      ...(widgetConfig?.filterInit?.custom?.range &&
        widgetConfig?.disabledOptions?.[CUSTOM_FILTER_VALUES.range] && {
          custom: {
            ...activeFilter.custom,
            range: widgetConfig.filterInit.custom.range
          }
        })
    }),
    [
      activeFilter,
      widgetConfig?.disabledOptions,
      widgetConfig?.filterInit?.custom?.range
    ]
  );

  return (
    <DynamicModuleLoader
      modules={[getUtilizationModule(), getCapacitiesModule()]}
    >
      <DevProps data={{ filterId, viewBy, pageName, activeFilter }} />
      <GradientsDefs />
      <Suspense fallback={<div />}>
        <CapacityReportComponent
          activeFilter={activeFilterWithOverridenDateRange}
          viewBy={viewBy}
          pageName={pageName}
          filterStateId={filterStateIds[viewBy]}
          widget={widget}
          isDashboardWidgetModal={isDashboardWidgetModal}
          widgetDetailModalThreeDotMenuItems={
            widgetDetailModalThreeDotMenuItems
          }
          filterSections={filterSections}
          widgetConfig={widgetConfig}
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};

export default CapacityTab;
