import React from 'react';
import { useSelector } from 'react-redux';
import { getIsOnPersonalProject } from 'selectors';
import {
  AddPhaseButton,
  StyledPhaseMilestoneIcon,
  LeftOptionsContainer
} from 'views/homePlanner/styles';

const Options = ({ children, onButtonClick }) => {
  const isOnPersonalProject = useSelector(getIsOnPersonalProject);
  return (
    <LeftOptionsContainer>
      <AddPhaseButton onClick={onButtonClick} hide={isOnPersonalProject}>
        <StyledPhaseMilestoneIcon
          strokeColor="#0074d9"
          height="12"
          width="12"
          fillColor="transparent"
        />
        Phases
      </AddPhaseButton>

      {children}
    </LeftOptionsContainer>
  );
};

export default Options;
