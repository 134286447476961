import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';

export const initialState = {
  plannedHours: {},
  spentHours: {}
};

const memberBudgets = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_PLANNED_HOURS_BY_DAY.SUCCESS: {
      const { planned_hours } = action.payload.response;
      return {
        ...state,
        plannedHours: planned_hours
      };
    }
    case budgetConstants.FETCH_SPENT_HOURS_BY_DAY.SUCCESS: {
      const { spent_hours } = action.payload.response;
      return {
        ...state,
        spentHours: spent_hours
      };
    }
    case budgetConstants.CLEAR_HOURS_DROPDOWN: {
      return initialState;
    }

    default:
      return state;
  }
};

export default memberBudgets;
