import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import {
  getRowValuesForEntityType,
  getProjectIdsThatMatchTitleAndNumber,
  getExistingProjectsHashByNumber,
  getExistingProjectsHashByTitle,
  getExistingPortfoliosHashByName,
  getExistingProjectIdToPortfolio
} from './utils';
import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  existingProjectsHashByTitle: ReturnType<
    typeof getExistingProjectsHashByTitle
  >;
  existingProjectsHashByNumber: ReturnType<
    typeof getExistingProjectsHashByNumber
  >;
  existingPortfoliosHashByName: ReturnType<
    typeof getExistingPortfoliosHashByName
  >;
  existingProjectIdToPortfolio: ReturnType<
    typeof getExistingProjectIdToPortfolio
  >;
  isRequired?: boolean;
}

export const portfolioValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingPortfoliosHashByName,
    existingProjectIdToPortfolio,
    isRequired
  } = additionalParams;

  const { enteredPortfolioName, enteredProjectTitle, enteredProjectNumber } =
    getRowValuesForEntityType(row, entityType);

  const matchingPortfolios = existingPortfoliosHashByName[enteredPortfolioName];

  // Case 1. Blank portfolio name = error if required
  if (!enteredPortfolioName) {
    if (isRequired) return ERROR_MESSAGES.portfolioNameRequired;
    else return true;
  }
  // Case 2. Training data required = invalid
  else if (enteredPortfolioName === TRAINING_DATA_REQUIRED_STRING) return false;

  const projectIdsThatMatchTitleAndNumber =
    getProjectIdsThatMatchTitleAndNumber({
      existingProjectsHashByTitle,
      existingProjectsHashByNumber,
      enteredProjectTitle,
      enteredProjectNumber
    });

  const rowHasMatchingProjects =
    Object.keys(projectIdsThatMatchTitleAndNumber).length > 0;

  // User has entered new project title + number combination
  if (!rowHasMatchingProjects) {
    // Case 3. User can select any existing portfolio = valid
    if (matchingPortfolios) return true;
    // Case 4. Portfolio does not exist = error
    else return ERROR_MESSAGES.portfolioDoesNotExist;
  }

  // User has entered project title + number combination that already exists + at least one of the matching
  // projects has a portfolio that matches the entered portfolio name
  const someProjectMatchesPortfolio = Object.keys(
    projectIdsThatMatchTitleAndNumber
  ).some(
    (projectId) =>
      existingProjectIdToPortfolio[projectId]?.name === enteredPortfolioName
  );

  // Case 5. Project exists and doesn't exist in any portfolio with matching name
  if (!someProjectMatchesPortfolio) {
    return ERROR_MESSAGES.projectDoesntMatchPortfolio;
  }

  return true;
};
