import styled, { css } from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import NewBudgetPhaseMilestoneIcon from 'icons/NewBudgetPhaseMilestoneIcon';
import { Dropdown } from 'reactstrap';
import ActivityRowIcon from 'icons/ActivityRowIcon';
import theme from 'theme';

export const RemoveText = styled.div`
  position: absolute;
  right: 12px;
  font-size: 12px;
  color: ${theme.colors.colorDeleteRed};
`;

export const StyledActivityRowIcon = styled(ActivityRowIcon)`
  margin-right: 9px;
  height: 13px;
  width: 12px;
  align-self: center;
`;

export const ActivityContainer = styled.div`
  font-size: 14px;
  padding-bottom: 6px;
  overflow: hidden;
  cursor: pointer;
  color: ${theme.colors.colorMediumGray5};
  margin-left: 5px;
  text-align: left;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  flex: ${(props) => props.flex || 'none'};
  ${(props) => props.justify && `justify-content: ${props.justify}`}
`;

export const SelectContainer = styled.div`
  border: none;
  font-size: 16px;
  padding: 4px;
  font-weight: 600;
  cursor: pointer;
  color: ${(props) =>
    props.isInvalid
      ? theme.colors.colorDeleteRed
      : theme.colors.colorRoyalBlue};
  path {
    fill: ${(props) =>
      props.isInvalid
        ? theme.colors.colorDeleteRed
        : theme.colors.colorRoyalBlue};
  }
  transition: all ${(props) => (props.isInvalid ? 0 : 150)}ms ease-in-out;
  text-align: left;

  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledDownArrow = styled.span`
  margin: 0px 6px;
  path {
    fill: ${({ isBlue }) => (isBlue ? theme.colors.colorRoyalBlue : '')};
  }
`;

export const StyledDropdownArrowContainer = styled.div`
  padding: 10px 8px;
  visibility: hidden;
  border-left: 1px solid ${theme.colors.colorPaleGray5};
  background: ${theme.colors.colorPureWhite};
`;

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  ${({ isPercentInput }) =>
    isPercentInput &&
    `.dropdown-menu {
    margin-left:24px;
  }`};
`;

export const ColorDot = styled.div`
  background: ${({ cellColor }) => cellColor};
  height: 9px;
  width: 9px;
  border-radius: 100px;
  margin-right: 4px;
`;
export const StyledBudgetTotal = styled.div`
  display: flex;
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray6};
  font-weight: 400;
  align-items: center;
`;

export const StyledDateRowContainer = styled.div`
  font-size: 15px;
  position: relative;
  margin: 18px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDateContainer = styled.div`
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorPaleGray5};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 7px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: ${theme.colors.colorSemiDarkGray1};

  ${({ disabled }) =>
    disabled &&
    `
      color: ${theme.colors.colorMediumGray3};
      background: transparent;
      border-color: transparent;
      cursor: not-allowed;
    `}

  &:hover:not([disabled]) {
    border-color: ${theme.colors.colorRoyalBlue};
  }
`;

export const DateDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  &:not([disabled]) {
    cursor: pointer;
  }
  ${(props) =>
    props.isDependant &&
    `
      color: ${theme.colors.colorPaleGray5};
      background: transparent;
      border-color: transparent;
    `}
`;

export const StyledDateDisplay = styled.div`
  text-align: center;
  min-width: 107px;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  color: ${({ isDisabled }) =>
    isDisabled ? theme.colors.colorMediumGray3 : theme.colors.colorMediumGray9};
`;

export const CellHeader = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.colorMediumGray1};
`;
export const StyledProjectTitle = styled.span`
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.nameStyle};
`;

export const StyledDot = styled.div`
  background: ${(props) =>
    calculateProjectColorPreference(props) || theme.colors.colorMediumGray5};
  height: 10px;
  width: 10px;
  border-radius: 100px;
  position: absolute;
`;
export const StyledProjectDescription = styled.span`
  color: ${theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  font-size: 16px;
`;

export const StyledProjectInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  cursor: pointer;
  position: relative;
`;

export const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  height: 12px;
  width: 12px;
  path {
    stroke: ${theme.colors.colorSemiDarkGray1};
  }
  margin-left: 18px;
  margin-top: 3px;
`;

export const StyledBudgetPhaseMilestoneIcon = styled(
  NewBudgetPhaseMilestoneIcon
)`
  height: 12px;
  width: 12px;
  path {
    stroke: ${theme.colors.colorCalendarGray};
  }
  margin-left: 18px;
  margin-top: 5px;
`;

export const BaseText = css`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
`;
export const StyledPhaseName = styled.div`
  ${BaseText}
  margin-left: 5px;
`;
export const StyledDate = styled.div`
  ${BaseText}
`;

export const StyledPhaseNameContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  color: ${theme.colors.colorMediumGray5};
  font-weight: normal;
  display: flex;
  width: 100%;
  align-items: center;

  &:hover {
    .phase-name {
      color: ${theme.colors.colorRoyalBlue};
    }

    .activity-title {
      color: ${theme.colors.colorRoyalBlue};
    }

    ${StyledBudgetPhaseMilestoneIcon} path, ${StyledBudgetPhaseMilestoneIcon} path {
      stroke: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const MemberName = styled.div`
  font-size: 12px;
  font-weight: 600;
  padding-left: 8px;
  white-space: nowrap;
  color: ${theme.colors.colorCalendarBlue};
  overflow: hidden;
  text-overflow: ellipsis;
  &.styled-member-name {
    line-height: 25px;
  }
  ${(props) => props.nameStyle};
`;

export const ArchivedText = styled.div`
  position: absolute;
  top: 29px;
  left: 38px;
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
`;
export const ListAddText = styled.div`
  padding: 0px 6px;
  font-size: 9px;
  color: ${theme.colors.colorSemiDarkGray1};
  &.bulk-members-drop-down-add-text {
    visibility: hidden;
  }
`;

export const UpdatedAtText = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
`;

export const ListMemberContainer = styled.div`
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  width: 100%;
  .small-logged-member,
  .small-regular-member {
    height: 28px;
    width: 28px;
    flex: 0 0 28px;
  }
  &:hover {
    ${ListAddText} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  // With Email column
  ${(props) =>
    props.showEmail &&
    `
    display: grid;
    grid-template-columns: 0fr 1.3fr 1fr;
  `}
  ${(props) => props.isAdded && `background-color: white;`}
`;

export const ListMemberName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  padding: ${(props) =>
    props.isUnassignedMemberBudget ? '0px 3px' : '0px 6px'};
  font-size: 14px;
  display: flex;
  font-weight: 400;
  color: ${theme.colors.colorSemiDarkGray1};
  ${(props) =>
    props.isOnWorkplanModal &&
    props.hasBudgetInfo &&
    `  margin-top: 18px;
      margin-left: 8px;
      margin-bottom: -4px;`};
`;

export const ListMemberEmail = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorCalendarGray};
`;

export const StyledRowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  opacity: ${(props) => (props.isDisabled ? 0.7 : 1)};

  ${StyledDownArrow} {
    visibility: hidden;
  }

  &:hover {
    ${StyledDownArrow} {
      visibility: visible;
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }

    ${StyledProjectTitle} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const ModalProjectContainer = styled.div`
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 2px;
`;

export const StyledColorPicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export const CellColorContainer = styled.div`
  border-radius: 15px;
  font-size: 15px;
  font-weight: 400;
  background: 'transparent';
  color: ${theme.colors.colorSemiDarkGray1};
  width: 100%;
  min-height: 26px;
  display: flex;
  position: relative;
  align-items: center;
`;

export const TertText = styled.span`
  font-size: 15px;
  line-height: 15px;
  ${CellColorContainer} & {
    margin-left: 1px;
  }
  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-top: 1px;
`;

export const StyledIssue = styled.span`
  color: ${theme.colors.colorDeleteRed};
  font-weight: 800;
`;

export const CellHeaderColorContainer = styled.div`
  width: 25%;
  padding-bottom: 10px;
  cursor: default;
`;

export const ListNameAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThreeLinesIconContainer = styled.div`
  display: inline-block;
  padding-right: 3px;
`;
