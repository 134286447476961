import { useGroupedMembersFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { useDepartments } from 'SettingsModule/hooks';

export const useMembersByDepartmentFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  field,
  shouldUseDraft
} = {}) => {
  const { handleNavToDepartmentsPage, departmentHash, getDepartmentLabel } =
    useDepartments({
      isOff
    });

  const groupedMembersFilterValues = useGroupedMembersFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'membersByDepartmentFilter',
    filterListType: FilterListType.MembersByDepartment,
    field,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    handleNavToEntityPage: handleNavToDepartmentsPage,
    groupEntityHash: departmentHash,
    getGroupEntityLabel: getDepartmentLabel
  };
};
