import mixpanel from 'mixpanel-browser';
// dependency injection so we could move to npm module if desired and use on mobile
import {
  calculateHighestTeamMembershipRoleId,
  roleIdToStringHash
} from 'appUtils/roleDisplay';

import { getMe, getMyTeamMembership, getSelectedTeam } from 'selectors';

const initMixpanel = () => {
  mixpanel.init('75e861a26605220557d0bcf4d27acbf1');
};

const createMixpanelMiddleware =
  ({ meSelector, myTeamMembershipSelector, selectedTeamSelector }) =>
  (store) =>
  (next) =>
  (action) => {
    if (
      window.location.href.includes('http://localhost') ||
      window.location.href.includes('staging')
    ) {
      return next(action);
    }

    const user = meSelector(store.getState());
    const myTeamMembership = myTeamMembershipSelector(store.getState());
    const highestRoleId = myTeamMembership
      ? calculateHighestTeamMembershipRoleId(myTeamMembership)
      : null;
    user &&
      mixpanel &&
      mixpanel.track &&
      mixpanel.track(action.type, {
        url: window.location.href,
        userId: user.id,
        access_role: highestRoleId
          ? roleIdToStringHash[highestRoleId]
          : '' || '',
        myProjectsOpen: user.are_my_projects_open,
        emailNotification: user.email_notification_on,
        salesForceId: user.default_team?.sales_force_id || '',
        activeUsers: user.default_team?.actives_count ?? 0
      });
    return next(action);
  };

const initMixpanelMiddleware = (reduxMiddleware) => {
  const MixpanelMiddleware = createMixpanelMiddleware({
    meSelector: getMe,
    myTeamMembershipSelector: getMyTeamMembership,
    selectedTeamSelector: getSelectedTeam
  });
  reduxMiddleware.push(MixpanelMiddleware);
};

export { initMixpanel, initMixpanelMiddleware };
