import { serializeId } from 'appUtils';

// See `BaseBuiltRow` for return type information.
export const buildRow = ({
  parentList,
  item,
  customRowProps,
  isHeader,
  isCustom,
  customBuildRow,
  isStickyHeader
}) => {
  const id =
    item.id ||
    serializeId({
      itemType: parentList?.id,
      ids: [item.rowType]
    });
  return {
    customRowProps,
    isHeader,
    isCustom,
    isRow: !isHeader,
    ...item,
    id,
    parentList,
    itemHeight: item.rowHeight || parentList?.rowHeight,
    rowType: item.rowType,
    sectionId: item.sectionId || parentList?.sectionId,
    stickyHeaderOffset:
      item.stickyHeaderOffset || parentList?.stickyHeaderOffset,
    draggableId: item.draggableId || id,
    isDragDisabled: !!item.isDragDisabled,
    // add custom row attributes here
    ...(customBuildRow &&
      customBuildRow({ parentList, item, customRowProps, isHeader, isCustom })),
    ...(isStickyHeader && { isStickyHeader })
  };
};

const buildFlatList = ({
  mainList,
  customRowProps,
  emptyRow,
  breakOnLoadingRow
}) => {
  if (!mainList) return { list: [] };
  const buildList = ({ parentList, lists }) => {
    const flatList = [];
    let breakFlag = false;
    for (const list of lists) {
      const {
        listItems = [],
        skipEmptyGroups,
        skipHeader,
        isOpen,
        customItems,
        closedItems,
        isFullyLoaded = true,
        addEmptyRow
      } = list;

      if (!listItems.length && skipEmptyGroups) {
        continue;
      }
      if (!skipHeader) {
        // Add header row for the list type
        flatList.push(
          buildRow({
            parentList,
            item: list,
            customRowProps,
            isHeader: true
          })
        );
      }
      if (isOpen) {
        customItems?.forEach((item) =>
          flatList.push(
            buildRow({ parentList: list, item, customRowProps, isCustom: true })
          )
        );

        for (const item of listItems) {
          // isList is used by newer typed tables. keeping it backward compatible
          if (item.hasSubLists || item.isList) {
            const { list: subList, breakFlag: subBreakFlag } = buildList({
              parentList: list,
              lists: [item],
              customRowProps,
              emptyRow,
              breakOnLoadingRow
            });
            flatList.push(...subList);
            if (subBreakFlag) {
              breakFlag = true;
              break;
            }
          } else {
            // Add list items as rows when !item.hasSublists
            flatList.push(buildRow({ parentList: list, item, customRowProps }));
          }
        }

        if (!isFullyLoaded && !breakFlag) {
          flatList.push(
            buildRow({
              parentList: list,
              customRowProps,
              item: { rowType: 'loadingRow' }
            })
          );
          if (breakOnLoadingRow) {
            breakFlag = true;
            break;
          }
        }
      } else {
        closedItems?.forEach((item) =>
          flatList.push(buildRow({ list, item, customRowProps }))
        );
      }
      if (!breakFlag && addEmptyRow) {
        flatList.push(
          buildRow({
            parentList: list,
            customRowProps,
            item: emptyRow,
            isCustom: true
          })
        );
      }
    }
    return { list: flatList, breakFlag };
  };
  return buildList({
    parentList: null,
    lists: Array.isArray(mainList) ? mainList : [mainList]
  });
};

export default buildFlatList;
