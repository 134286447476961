import { useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getSelectedTeamViewSettings, getSplitFlags } from 'selectors';

/**
 * @deprecated Use the `isHoursOnly` return property from
 * `useTeamBudgetViewSettings` or `useProjectBudgetViewSettings`.
 *
 * @returns if the current team is hours only.
 */
const useIsHoursOnly = () => {
  const teamId = useAppSelector(getSelectedTeamId);
  const teamSettings = useAppSelector(getSelectedTeamViewSettings);
  const { hoursOnlyFlag } = useAppSelector(getSplitFlags);

  // Check team level settings.
  const isHoursOnlyTeam = useMemo(() => {
    if (teamId) {
      return !!teamSettings?.is_hours_only && hoursOnlyFlag;
    }
  }, [hoursOnlyFlag, teamId, teamSettings?.is_hours_only]);

  // If any are true, then is hours only.
  const isHoursOnly = isHoursOnlyTeam;

  return {
    isHoursOnly,
    hoursOnlyFlag
  };
};

export default useIsHoursOnly;
