import React from 'react';
import cn from 'classnames';

const DayNavArrow = ({ direction, handleClick }) => {
  return (
    <div
      className={cn('nav-strip-arrow', {
        'right-arrow': direction === 'right',
        'left-arrow': direction === 'left'
      })}
      onClick={handleClick}
    />
  );
};

export default DayNavArrow;
