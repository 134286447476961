import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter, Switch } from 'react-router-dom';
import LoadSettingsContainer from 'SettingsModule/components/LoadSettingsContainer';
import TeamSettingsViewsRouter from './TeamSettingsViewsRouter';
import withRouterParams from './WithRouterParams';
import TeamSettingsNavigationTracker from 'views/Navigation/TeamSettingsNavigationTracker';
import { permissionsModuleConstants } from 'appCore/navigation/permissions/constants';

const { PERMISSIONS_SETTINGS_VIEW_TYPES } = permissionsModuleConstants;

class TeamSettingsContainerRouter extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    return (
      <LoadSettingsContainer {...this.props}>
        <TeamSettingsNavigationTracker />
        <Switch>
          <Redirect
            exact
            from={`${match.url}/members`}
            to={`${match.url}/members/members`}
          />
          <Redirect
            exact
            from={`${match.url}/standards`}
            to={`${match.url}/standards/phases`}
          />
          <Redirect
            exact
            from={`${match.url}/permissions`}
            to={`${match.url}/permissions/${PERMISSIONS_SETTINGS_VIEW_TYPES.membership}`}
          />
          <Route
            path={`${match.url}/:viewType`}
            component={TeamSettingsViewsRouter}
          />
        </Switch>
      </LoadSettingsContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TeamSettingsContainerRouter)
  )
);
