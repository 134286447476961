import PermissionsSection from './PermissionsSection';
import CheckBoxContainer from '../../../CheckboxContainer';
import Collapse from '../../../Collapse';
import Admin from './Admin';
import AdminList from 'components/Tooltips/AdminList';
import { flatOptions } from 'SettingsModule/utils/checkboxOptions';
import buildPermittedRoles from 'SettingsModule/utils/buildPermittedRolesString';

import { permissionsActionsHash } from 'PermissionsModule/constants';
import OrganizationIcon from 'icons/OrganizationIcon';
import HelpIcon from 'icons/HelpIcon';
import { HeaderContainer, PermissionText } from './styles';
import { StyledHelpIconContainer } from 'BudgetModule/components/styles';
import { PermissionsActions } from 'PermissionsModule/types';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getAllPermissionsByActions,
  getPermissionActionsExpanded
} from 'RoleTemplatesModule/selectors';

interface OrganizationPermissionsProps {
  makeTogglePermission: (actionType: PermissionsActions) => void;
  toggleOpen: (actionType: PermissionsActions) => void;
  adminTabOpen: boolean;
  toggleAdminTabOpen: () => void;
}

const OrganizationPermissions = ({
  makeTogglePermission,
  toggleOpen,
  adminTabOpen,
  toggleAdminTabOpen
}: OrganizationPermissionsProps) => {
  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const expandedPermissions = useAppSelector(getPermissionActionsExpanded);

  const renderHeader = (permissionAction: PermissionsActions) => {
    const roleTemplatesHash = permissionsByAction[permissionAction];
    const permissionData = permissionsActionsHash[permissionAction];

    return (
      <HeaderContainer>
        <div>{permissionData ? permissionData.question : ''}</div>
        {roleTemplatesHash ? (
          <PermissionText>
            {buildPermittedRoles(roleTemplatesHash)}
          </PermissionText>
        ) : null}
      </HeaderContainer>
    );
  };

  return (
    <PermissionsSection headerText={'Organization'} Icon={OrganizationIcon}>
      <Collapse
        header={
          <HeaderContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Admins
              <StyledHelpIconContainer
                data-for="app-tooltip"
                data-html
                data-tip="Make members Admins from the<br /> members section of this Settings <br />space."
                data-effect="solid"
              >
                <HelpIcon />
              </StyledHelpIconContainer>
            </div>
          </HeaderContainer>
        }
        isOpen={adminTabOpen}
        toggleOpen={toggleAdminTabOpen}
      >
        <AdminList ListItemComponent={Admin} />
      </Collapse>

      {/* <Collapse
        header={
          <div>Who can make members Administrators of the organization?</div>
        }
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={canCreateMembers}
          onCheck={makeTogglePermission('create_team_memberships')}
        />
      </Collapse> */}
      <Collapse
        header={renderHeader('create_team_memberships')}
        isOpen={expandedPermissions.create_team_memberships}
        toggleOpen={() => toggleOpen('create_team_memberships')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_team_memberships}
          onCheck={makeTogglePermission('create_team_memberships')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('delete_team_memberships')}
        isOpen={expandedPermissions.delete_team_memberships}
        toggleOpen={() => toggleOpen('delete_team_memberships')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.delete_team_memberships}
          onCheck={makeTogglePermission('delete_team_memberships')}
        />
      </Collapse>
      {/* <Collapse
        header={
          <div>Who can create Company dashboards from the organization? </div>
        }
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={canDeleteMembers}
          onCheck={makeTogglePermission('create_team_memberships')}
        />
      </Collapse> */}
    </PermissionsSection>
  );
};

export default OrganizationPermissions;
