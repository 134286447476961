import PencilIcon from 'icons/PencilIcon';
import styled from 'styled-components';

export const EditIcon = styled(PencilIcon).attrs({
  className: 'editIcon',
  height: 13,
  width: 13
})`
  display: none;
  left: 5px;
`;
