import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isNumeric } from './utils';
import { RowValidator } from 'CsvImportsModule/types';

export const hoursValidator: RowValidator = ({ row, entityType }) => {
  const { enteredHours } = getRowValuesForEntityType(row, entityType);

  if (enteredHours === TRAINING_DATA_REQUIRED_STRING) {
    return false;
  } else if (enteredHours) {
    if (!isNumeric(enteredHours))
      return ERROR_MESSAGES.enteredHoursIsNotNumeric;
    else if (+enteredHours === 0) {
      return ERROR_MESSAGES.zeroHourEntered;
    }
  }
  return true;
};
