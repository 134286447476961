import { createSelector } from 'reselect';
import { initialState } from '../reducers/qbSyncSettings';

export const getQBSyncSettings = (state) =>
  state.quickbooks?.qbSyncSettings ?? initialState;

export const getIsQBSynced = createSelector(
  getQBSyncSettings,
  (state) =>
    state.syncSettings?.authenticated && !state.syncSettings?.auth_error
);
export const getIsQBDisconnected = createSelector(
  getQBSyncSettings,
  (state) => state.syncSettings?.authenticated && state.syncSettings?.auth_error
);

export const getIsQBSyncSettingsFetched = createSelector(
  getQBSyncSettings,
  (state) => state.syncFetched
);
