/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import produce from 'immer';
import {
  getAllActivityRowInfo,
  getAllFetchedProjects,
  getAllGroups,
  getGroupsHash,
  getTeamMembershipsByAccountId,
  getAllFetchedProjectsArray,
  getProjectHash
} from 'selectors';
import { getQBProjectsState, getIsConfigDirQbToMosaic } from './quickbooksCore';
import * as constants from 'QuickbooksModule/constants';

import { searchItemsWithWhiteSpace } from 'appUtils/search';
import { initialState as quickbooksViewInitialState } from 'QuickbooksModule/reducers/quickbooksView';
import { makeSearchableTeamMember } from 'QuickbooksModule/qbUtils/makeSearchableStrings';
import { sortArray } from 'QuickbooksModule/qbUtils';
export * from './quickbooksCore';
export * from './qbSyncSettings';

const emptyObj = {};
const { mappingFilters } = constants;

const byId = (item) => item && item.id;
const byAccountId = (item) => item && item.mosaic_account_id;
const byCustomerName = (item) => item && item.name;
const byMosaicActivityId = (item) => item && item.mosaic_activity_code_id;
const byMosaicProjectId = (item) => item && item.mosaic_project_id;
const byEmployeeId = (item) => item && item.employee_id;
const bySubcustomerName = (item) => item && item.subcustomer_name;

const getQuickbooksView = (state) => state.quickbooks || emptyObj;

export const getQuickbooksViewState = createSelector(
  getQuickbooksView,
  (state) => state.quickbooksView || quickbooksViewInitialState
);
const bySubcustomerId = (item) => item && item.subcustomer_id;

export const getQuickbooksExpanded = createSelector(
  getQuickbooksViewState,
  (viewState) => viewState.expanded
);
export const getQuickbooksShowPhasesProjectIds = createSelector(
  getQuickbooksViewState,
  (viewState) => viewState.showPhasesProjectIds
);

export const getIsQBAddProjectModalOpen = createSelector(
  getQuickbooksViewState,
  (viewState) => viewState.addProjectModalOpen
);

export const getQBAddProjectModalName = createSelector(
  getQuickbooksViewState,
  (viewState) => viewState.addProjectModalName
);
export const getQBAddProjectSubcustomerId = createSelector(
  getQuickbooksViewState,
  (viewState) => viewState.addProjectSubcustomerId
);
export const getQBBudgetState = (state) =>
  (state.quickbooks && state.quickbooks.budget) || {};

export const getQBCustomersState = (state) =>
  (state.quickbooks && state.quickbooks.qbCustomers) || {};

export const getQBMembersState = (state) =>
  (state.quickbooks && state.quickbooks.qbMembers) || {};

export const getQbServicesState = (state) =>
  (state.quickbooks && state.quickbooks.qbServices) || {};

export const getMemberMappingValue = createSelector(
  getQBMembersState,
  (memberState) => memberState.mappingFilter
);
export const getActivityMappingValue = createSelector(
  getQbServicesState,
  (activityState) => activityState.mappingFilter
);
export const getQbSortState = createSelector(
  getQuickbooksView,
  (qbState) => (qbState.sort && qbState.sort.sorting) || emptyObj
);

export const getQbMemberSortColumn = createSelector(
  getQbSortState,
  (sortState) => sortState.members
);
export const getQbProjectSortColumn = createSelector(
  getQbSortState,
  (sortState) => sortState.projects
);
export const getQbServicesSortColumn = createSelector(
  getQbSortState,
  (sortState) => sortState.activities
);
export const getProjectMappingValue = createSelector(
  getQBProjectsState,
  (projectState) => projectState.mappingFilter
);
export const getProjectIsActiveMappingValue = createSelector(
  getQBProjectsState,
  (projectState) => projectState.isActiveMappingFilter
);

export const getQbMembers = createSelector(
  getQBMembersState,
  (qbMembers) => (qbMembers && qbMembers.qbMembers) || emptyObj
);
export const getIsLoadingMembers = createSelector(
  getQBMembersState,
  (qbMemberState) => qbMemberState && qbMemberState.isInitialFetchSyncMembers
);
export const getSyncedMembers = createSelector(
  getQBMembersState,
  (qbMembers) => (qbMembers && qbMembers.qbSyncedMembers) || emptyObj
);
export const isPendingMembers = createSelector(
  getSyncedMembers,
  (syncedEmployees) => {
    const pendingStatusMembers =
      (syncedEmployees &&
        Object.values(syncedEmployees).filter(
          (employee) => employee.status === constants.IS_PENDING
        )) ||
      [];
    return pendingStatusMembers.length > 0;
  }
);
export const getSyncedMembersByEmployeeId = createSelector(
  getSyncedMembers,
  (syncedEmployees) => keyBy(Object.values(syncedEmployees), byEmployeeId)
);
export const getShowOnlySyncedMembersFlag = createSelector(
  getQBMembersState,
  (qbMemberState) => qbMemberState && qbMemberState.isShowOnlySynced
);

export const getSyncedMembersByAccountId = createSelector(
  getSyncedMembers,
  (syncedEmployees) => keyBy(Object.values(syncedEmployees), byAccountId)
);

export const getQbSubCustomers = createSelector(
  getQBProjectsState,
  (qbSubCustomers) =>
    (qbSubCustomers && qbSubCustomers.qbSubCustomers) || emptyObj
);

export const getQBProjectsByCustomerName = createSelector(
  getQbSubCustomers,
  (qbSubCustomers) => keyBy(Object.values(qbSubCustomers), bySubcustomerName)
);

export const getQBSyncedProjects = createSelector(
  getQBProjectsState,
  (qbSubCustomers) =>
    (qbSubCustomers && qbSubCustomers.qbSyncedSubCustomers) || emptyObj
);

export const isPendingProjects = createSelector(
  getQBSyncedProjects,
  (syncedProjects) => {
    const pendingStatusProjects =
      (syncedProjects &&
        Object.values(syncedProjects).filter(
          (project) => project && project.status === constants.IS_PENDING
        )) ||
      [];
    return pendingStatusProjects.length > 0;
  }
);

export const getQBSyncedProjectsByCustomerName = createSelector(
  getQBSyncedProjects,
  (syncedProjects) => keyBy(Object.values(syncedProjects), bySubcustomerName)
);

export const getFilteredQBProjects = createSelector(
  getQBProjectsByCustomerName,
  getQBSyncedProjectsByCustomerName,
  (qbSubCustomers, syncedProjects) => {
    const filteredQBProjects = produce(qbSubCustomers, (draftQBProjects) => {
      Object.keys(draftQBProjects).forEach((key) => {
        if (syncedProjects && syncedProjects[key]) {
          draftQBProjects[key].isAlreadySelected = true;
        }
      });
    });
    return keyBy(Object.values(filteredQBProjects), byId);
  }
);

export const getIsLoadingProjects = createSelector(
  getQBProjectsState,
  (qbSubCustomersState) =>
    qbSubCustomersState && qbSubCustomersState.isInitialFetchSyncSubCustomers
);

export const getShowSyncedProjectsFlag = createSelector(
  getQBProjectsState,
  (qbProjectState) => qbProjectState && qbProjectState.isShowOnlySynced
);

export const getQBSyncedProjectsByProjectId = createSelector(
  getQBSyncedProjects,
  (qbSyncedSubCustomers) =>
    keyBy(Object.values(qbSyncedSubCustomers), byMosaicProjectId)
);

export const getFilteredProjectListbyProjectId = createSelector(
  getProjectHash,
  getQBSyncedProjectsByProjectId,
  (allProjects, qbSyncedSubCustomersByProjectId) =>
    produce(allProjects, (draftAllProjects) => {
      Object.keys(qbSyncedSubCustomersByProjectId).forEach(
        (syncedProjectId) => {
          if (
            draftAllProjects[syncedProjectId] &&
            !draftAllProjects[syncedProjectId].dont_sync
          ) {
            draftAllProjects[syncedProjectId].isAlreadySelected = true;
          }
        }
      );
    })
);

export const getFilteredProjectsListByTeamId = createSelector(
  getFilteredProjectListbyProjectId,
  (projects) => {
    const dict = {};
    Object.values(projects).forEach((project) => {
      if (dict[project.board_id]) {
        dict[project.board_id].push(project);
      } else {
        dict[project.board_id] = [project];
      }
    });
    return dict;
  }
);

export const getIsConsolidatedLoading = createSelector(
  getIsLoadingMembers,
  getIsLoadingProjects,
  (isLoadingMembers, isLoadingProjects) => isLoadingMembers || isLoadingProjects
);

export const getIntegrationsState = (state) => state.integrations || emptyObj;

export const getSyncStatus = createSelector(
  getIntegrationsState,
  (state) => state.syncStatus
);

export const getSyncCompletedStatus = createSelector(
  getSyncStatus,
  (syncStatus) => syncStatus.completed
);

export const getQuickbooksLinkedSections = createSelector(
  getSyncedMembers,
  getQBSyncedProjects,
  getIsConsolidatedLoading,
  getSyncCompletedStatus,
  (syncedEmployees, syncedQbSubCustomers, isLoading, isCompleted) => {
    return {
      members:
        !isLoading &&
        isCompleted &&
        Object.keys(syncedEmployees || emptyObj).length > 0,
      projects:
        !isLoading &&
        isCompleted &&
        Object.keys(syncedEmployees || emptyObj).length > 0 &&
        Object.keys(syncedQbSubCustomers || emptyObj).length > 0,
      activities:
        !isLoading &&
        isCompleted &&
        Object.keys(syncedEmployees || emptyObj).length > 0 &&
        Object.keys(syncedQbSubCustomers || emptyObj).length > 0
    };
  }
);

export const getQBDefaultTeam = createSelector(
  getQBProjectsState,
  (qbSubCustomers) => qbSubCustomers && qbSubCustomers.qbDefaultTeam
);

export const getHiddenQbCustomersById = createSelector(
  getQBCustomersState,
  (qbCustomers) => (qbCustomers && qbCustomers.hiddenQbCustomers) || emptyObj
);

export const getQBCustomersById = createSelector(
  getQBCustomersState,
  (qbCustomerState) => qbCustomerState.qbCustomers
);

export const getHiddenQBCustomersByName = createSelector(
  getHiddenQbCustomersById,
  (hiddenQBCustomersById) =>
    keyBy(Object.values(hiddenQBCustomersById), byCustomerName)
);

export const getSyncedProjectsBySubcustomerId = createSelector(
  getQBSyncedProjects,
  (qbSyncedSubCustomers) =>
    keyBy(Object.values(qbSyncedSubCustomers), bySubcustomerId)
);

export const getFilteredQBProjectsById = createSelector(
  getHiddenQBCustomersByName,
  getQbSubCustomers,
  getSyncedProjectsBySubcustomerId,
  getProjectMappingValue,
  getProjectIsActiveMappingValue,
  getProjectHash,
  (
    hiddenQbCustomers,
    qbSubCustomers,
    qbSyncedSubCustomers,
    mappingValue,
    isActiveMappingValue,
    projectHash
  ) => {
    const filteredProjects = Object.values(qbSubCustomers)
      .filter((project) => {
        const qbSyncedSubCustomer = qbSyncedSubCustomers?.[project.id];
        const dont_sync = qbSyncedSubCustomer?.dont_sync;
        if (hiddenQbCustomers && !hiddenQbCustomers[project.customer_name]) {
          if (
            mappingValue === mappingFilters.SHOW_LINKED &&
            qbSyncedSubCustomer &&
            !dont_sync
          ) {
            return true;
          } else if (
            mappingValue === mappingFilters.SHOW_UNLINKED &&
            qbSyncedSubCustomer &&
            dont_sync
          ) {
            return true;
          } else if (mappingValue === mappingFilters.SHOW_ALL) {
            return true;
          } else if (mappingValue === mappingFilters.SHOW_UNLINKED_PHASES) {
            return true;
          }
        }
      })
      .filter((project) => {
        const qbSyncedSubCustomer = qbSyncedSubCustomers?.[project.id];
        const mosaicProject =
          projectHash[qbSyncedSubCustomer?.mosaic_project_id];
        if (isActiveMappingValue === mappingFilters.SHOW_ACTIVE_AND_ARCHIVED) {
          return true;
        } else if (isActiveMappingValue === mappingFilters.SHOW_ACTIVE) {
          return !mosaicProject?.is_archived && !project?.is_archived;
        } else if (isActiveMappingValue === mappingFilters.SHOW_ARCHIVED) {
          return mosaicProject?.is_archived || project?.is_archived;
        }
        return true;
      });
    return keyBy(filteredProjects, byId);
  }
);

export const getFilteredQBCustomersById = createSelector(
  getQBCustomersState,
  getHiddenQbCustomersById,
  (qbCustomers, hiddenQbCustomersById) => {
    const customers = Object.values(qbCustomers.qbCustomers).filter(
      (customer) =>
        !(
          hiddenQbCustomersById &&
          hiddenQbCustomersById[customer && customer.id]
        )
    );
    return keyBy(customers, byId);
  }
);

const getOwnSection = (state, ownProps) => ownProps.section;
const getQBSearchState = (state) =>
  (state.quickbooks && state.quickbooks.search) || emptyObj;

export const getProjectSearchText = createSelector(
  getQBSearchState,
  (searchState) => searchState.searches.projects || ''
);
export const makeGetSearchText = () =>
  createSelector(
    getOwnSection,
    getQBSearchState,
    (section, searchState) => searchState.searches[section]
  );

export const getQbServices = createSelector(
  getQbServicesState,
  (qbServices) => qbServices && qbServices.qbServices
);

export const getQbSyncedServices = createSelector(
  getQbServicesState,
  (qbServices) => (qbServices && qbServices.qbSyncedServices) || emptyObj
);

export const isPendingActivities = createSelector(
  getQbSyncedServices,
  (syncedActivities) => {
    const pendingStatusActivities =
      (syncedActivities &&
        Object.values(syncedActivities).filter(
          (activity) => activity.status === constants.IS_PENDING
        )) ||
      [];
    return pendingStatusActivities.length > 0;
  }
);

export const getShowSyncedServicesFlag = createSelector(
  getQbServicesState,
  (qbServices) => qbServices && qbServices.isShowOnlySynced
);

export const getQbSyncedServicesByMosActivityId = createSelector(
  getQbSyncedServices,
  (syncedActivities) =>
    keyBy(Object.values(syncedActivities), byMosaicActivityId)
);

export const getFilteredActivityInfo = createSelector(
  getQbSyncedServicesByMosActivityId,
  getAllActivityRowInfo,
  (syncedActivities, allActivities) =>
    produce(allActivities, (draftAllActivities) => {
      Object.keys(syncedActivities).forEach((syncedActivityId) => {
        if (draftAllActivities[syncedActivityId]) {
          draftAllActivities[syncedActivityId].isAlreadySelected = true;
        }
      });
    })
);

const getActivitySearchText = createSelector(
  getQBSearchState,
  (searchState) => searchState.searches.activities || ''
);

export const getActivities = createSelector(
  getQbServices,
  getQbSyncedServices,
  getActivityMappingValue,
  getIsConfigDirQbToMosaic,
  getAllActivityRowInfo,
  (qbServices, syncedActivities, mappingValue, isQbToMosaic, allActivities) => {
    const syncedActs = keyBy(
      Object.values(syncedActivities || emptyObj),
      (item) => item.item_id
    );
    const linkedActivityIds = Object.keys(syncedActs);
    const activitiesWithSyncedInfo = produce(
      qbServices,
      (draftImportedActivities) => {
        Object.keys(draftImportedActivities || emptyObj).forEach((key) => {
          draftImportedActivities[key].serviceCode =
            draftImportedActivities[key].name;
          if (syncedActs[key]) {
            draftImportedActivities[key].isPending =
              syncedActs[key].status === constants.IS_PENDING;
            draftImportedActivities[key].synced = syncedActs[key];
            draftImportedActivities[key].activityCategory =
              allActivities[syncedActs[key].mosaic_activity_code_id] &&
              allActivities[syncedActs[key].mosaic_activity_code_id].title;
          }
        });
      }
    );
    // } else {
    //   activitiesWithSyncedInfo = produce(allActivities, draftAllActivities => {
    //     Object.keys(draftAllActivities || emptyObj).forEach(key => {
    //       draftAllActivities[key].activityCategory =
    //         draftAllActivities[key].title;
    //       if (syncedActs[key]) {
    //         draftAllActivities[key].isPending =
    //           syncedActs[key].status === constants.IS_PENDING;
    //         draftAllActivities[key].synced = syncedActs[key];
    //         draftAllActivities[key].serviceCode = syncedActs[key].item_name;
    //       }
    //     });
    //   });
    // }

    switch (mappingValue) {
      case mappingFilters.SHOW_ALL: {
        return activitiesWithSyncedInfo;
      }
      case mappingFilters.SHOW_LINKED: {
        return pick(activitiesWithSyncedInfo, linkedActivityIds);
      }
      case mappingFilters.SHOW_UNLINKED: {
        return omit(activitiesWithSyncedInfo, linkedActivityIds);
      }
    }
    return activitiesWithSyncedInfo;
  }
);

export const getSearchableActivities = createSelector(
  getActivities,
  getAllActivityRowInfo,
  getQbServicesSortColumn,
  (activities, allActivities, activitiesSort) => {
    const activityList = Object.values(activities).map((activity) => {
      let searchableString = `${activity.name} ${activity.description}`;
      if (activity.synced) {
        const mosaicActivity =
          allActivities[activity.synced.mosaic_activity_code_id];
        if (mosaicActivity) {
          searchableString += ` ${mosaicActivity.title}`;
        }
      }
      return {
        ...activity,
        searchableString
      };
    });
    if (activitiesSort) {
      const { order, sortColumn } = activitiesSort;
      return sortArray(activityList, sortColumn, order);
    } else {
      return activityList;
    }
  }
);

export const getSearchedActivities = createSelector(
  getSearchableActivities,
  getActivitySearchText,
  (activities, searchText) => {
    return searchText.length > 0
      ? searchItemsWithWhiteSpace({
          searchText,
          itemList: activities,
          filterKeysArray: ['searchableString']
        })
      : activities;
  }
);

export const getDefaultTeam = createSelector(
  getSyncStatus,
  getQBDefaultTeam,
  (syncStatus, qbDefaultTeam) => {
    if (qbDefaultTeam && qbDefaultTeam.mosaic_default_board_id) {
      const { mosaic_default_board_id } = qbDefaultTeam;
      return {
        mosaic_default_board_id
      };
    } else if (syncStatus && syncStatus.mosaic_default_board_id) {
      const { mosaic_default_board_id } = syncStatus;
      return {
        mosaic_default_board_id
      };
    } else {
      return {};
    }
  }
);

export const getHiddenTeamsById = createSelector(
  getQBCustomersState,
  (qbCustomers) => (qbCustomers && qbCustomers.hiddenTeams) || emptyObj
);

const filterProjectsByLinked = ({ mappingValue, projects, syncedHash }) => {
  switch (mappingValue) {
    case mappingFilters.SHOW_LINKED: {
      return projects.filter((project) => !!syncedHash[project.id]);
    }
    case mappingFilters.SHOW_UNLINKED: {
      return projects.filter((project) => !syncedHash[project.id]);
    }
    default: {
      return projects;
    }
  }
};
const filterProjectsByIsActive = ({ mappingValue, projects }) => {
  switch (mappingValue) {
    case mappingFilters.SHOW_ACTIVE_AND_ARCHIVED: {
      return projects;
    }
    case mappingFilters.SHOW_ACTIVE: {
      return projects.filter((project) => !project.is_archived);
    }
    case mappingFilters.SHOW_ARCHIVED: {
      return projects.filter((project) => project.is_archived);
    }
  }
};
export const getFilteredProjects = createSelector(
  getAllFetchedProjectsArray,
  getHiddenTeamsById,
  getProjectMappingValue,
  getProjectIsActiveMappingValue,
  getQBSyncedProjectsByProjectId,
  (
    allFetchedProjects,
    hiddenTeamsById,
    mappingValue,
    isActiveMappingValue,
    synced
  ) => {
    const filteredProjects = allFetchedProjects.filter(
      (project) => !hiddenTeamsById[project.board_id] && !project.is_personal
    );
    const projectsFilteredByLinked = filterProjectsByLinked({
      mappingValue,
      projects: filteredProjects,
      syncedHash: synced
    });
    const projectsFilteredbyActive = filterProjectsByIsActive({
      mappingValue: isActiveMappingValue,
      projects: projectsFilteredByLinked
    });
    return projectsFilteredbyActive;
  }
);

export const getAllProjectSyncInfoArray = createSelector(
  getFilteredProjects,
  getQBSyncedProjectsByProjectId,
  getGroupsHash,
  getAllFetchedProjects,
  (allFilteredProjects, synced, allGroupsHash, allMosaicProjects) => {
    const projects = allFilteredProjects.map((project) => {
      const { id, board_id, billable, title } = project;
      const syncedProject = synced[id];
      const mosaicBoard = allGroupsHash[board_id];
      const mosaicProject = allMosaicProjects[id];

      let formattedProject = {
        id,
        billable,
        mosaic_project_id: id,
        mosaic_name: title,
        mosaic_board_id: board_id,
        team_name: mosaicBoard?.name,
        project_name: mosaicProject?.title,
        is_archived: (project || mosaicProject)?.is_archived,
        synced: true,
        dont_sync:
          syncedProject && syncedProject.dont_sync !== undefined
            ? syncedProject.dont_sync
            : true
      };
      if (syncedProject) {
        formattedProject = {
          ...formattedProject,
          customer_name: syncedProject.customer_name,
          subcustomer_name: syncedProject.subcustomer_name,
          subcustomer_id: syncedProject.subcustomer_id,
          subcustomer_mapping_id: syncedProject.id,
          createNewProject:
            syncedProject.create_new_on_qb || syncedProject.create_new_project,
          isPending: syncedProject.status === constants.IS_PENDING,
          is_qb_project: syncedProject.is_qb_project
        };
      }
      return formattedProject;
    });
    return projects;
  }
);

export const getAllProjectSyncInfo = createSelector(
  getAllProjectSyncInfoArray,
  (projects) => keyBy(projects, byId)
);

export const getFilteredGroupsObj = createSelector(
  getAllGroups,
  getHiddenTeamsById,
  (groups, hiddenTeamsById) =>
    keyBy(
      groups &&
        groups.filter(
          (group) => !(hiddenTeamsById && hiddenTeamsById[group && group.id])
        ),
      byId
    )
);

export const getQBCustomersList = createSelector(
  getQBCustomersById,
  (qbCustomersById) => Object.values(qbCustomersById || emptyObj)
);

export const getProjectId = (state, ownProps) =>
  ownProps.project && ownProps.project.id;

export const getSubcustomerModalState = (state) =>
  (state.quickbooks && state.quickbooks.subcustomerModal) || {};

export const getSubcustomerModalOpen = createSelector(
  getSubcustomerModalState,
  (subcustomerModalState) => subcustomerModalState.openSubcustomerModal
);

export const getSubcustomerPrefillText = createSelector(
  getSubcustomerModalState,
  (subcustomerModalState) => subcustomerModalState.preFillText
);

export const getSubcustomerTobeSynced = createSelector(
  getSubcustomerModalState,
  (subcustomerModalState) => subcustomerModalState.toBeSynced
);

export const getCreatingNewSubSubFlag = createSelector(
  getSubcustomerModalState,
  (subcustomerModalState) => subcustomerModalState.isCreatingNewSubSub
);

export const getSubcustomerAlreadyAvailableItems = createSelector(
  getSubcustomerModalState,
  (subcustomerModalState) => subcustomerModalState.alreadyAvailableItems
);

export const getTobeChangedSyncedProperty = createSelector(
  getSubcustomerModalState,
  (subcustomerModalState) => subcustomerModalState.toBeChangedProperty
);

export const getFilteredTeamMembershipsByAccountId = createSelector(
  getSyncedMembersByAccountId,
  getTeamMembershipsByAccountId,
  (syncedEmployees, teamMembershipsByAccountId) =>
    produce(teamMembershipsByAccountId, (draftTeamMembershipsByAccountId) => {
      Object.keys(syncedEmployees).forEach((syncedEmployeeAccountId) => {
        if (draftTeamMembershipsByAccountId[syncedEmployeeAccountId]) {
          draftTeamMembershipsByAccountId[
            syncedEmployeeAccountId
          ].isAlreadySelected = true;
        }
      });
    })
);

export const getMembersSearchText = createSelector(
  getQBSearchState,
  (searchState) => searchState.searches.members || ''
);

export const getFormattedQbMembers = createSelector(
  getQbMembers,
  getSyncedMembersByEmployeeId,
  getMemberMappingValue,
  getFilteredTeamMembershipsByAccountId,
  (qbMembers, syncedEmployees, mappingValue, teamMembersByAccountId) => {
    const filteredEmployees = {};

    qbMembers &&
      Object.keys(qbMembers).forEach((key) => {
        const qbMember = qbMembers[key];
        const syncedEmployee = syncedEmployees[key];
        if (syncedEmployee && !syncedEmployee.dont_sync) {
          const teamMember =
            teamMembersByAccountId[syncedEmployee.mosaic_account_id];
          filteredEmployees[key] = {
            ...qbMember,
            syncedMember: teamMember,
            email: teamMember?.account?.email,
            isPending: syncedEmployee.status === constants.IS_PENDING,
            searchableString: `${qbMember.first_name} ${
              qbMember.last_name
            } ${makeSearchableTeamMember(teamMember)}`
          };
        } else {
          filteredEmployees[key] = {
            ...qbMember,
            email:
              (qbMember && qbMember.account && qbMember.account.email) ||
              (qbMember && qbMember.email),
            searchableString: `${qbMember.first_name} ${qbMember.last_name}`
          };
        }
      });

    return filteredEmployees;
  }
);

export const getFilteredQBMembers = createSelector(
  getFormattedQbMembers,
  getMemberMappingValue,
  getSyncedMembersByEmployeeId,
  (formattedQbMembers, mappingValue, syncedEmployees) => {
    const linkedEmployeeIds = Object.values(syncedEmployees)
      .filter((employee) => !employee.dont_sync)
      .map((employee) => employee.employee_id);

    switch (mappingValue) {
      case mappingFilters.SHOW_ALL: {
        return formattedQbMembers;
      }
      case mappingFilters.SHOW_LINKED: {
        return pick(formattedQbMembers, linkedEmployeeIds);
      }
      case mappingFilters.SHOW_UNLINKED: {
        return omit(formattedQbMembers, linkedEmployeeIds);
      }
    }
    return formattedQbMembers;
  }
);

export const getFilteredQbMembersArray = createSelector(
  getFilteredQBMembers,
  getQbMemberSortColumn,
  (members, memberSort) => {
    if (memberSort) {
      const { order, sortColumn } = memberSort;
      return sortArray(Object.values(members), sortColumn, order);
    } else {
      return Object.values(members);
    }
  }
);

export const getSearchedQBMembers = createSelector(
  getFilteredQbMembersArray,
  getMembersSearchText,
  (members, searchText) =>
    searchText.length
      ? searchItemsWithWhiteSpace({
          itemList: members,
          searchText,
          filterKeysArray: ['searchableString']
        })
      : members
);

export const getSelectedMembers = createSelector(
  getQBMembersState,
  (qbMembers) => qbMembers.selectedMembers
);

export const getSelectedMembersLength = createSelector(
  getSelectedMembers,
  (selectedMembers) => Object.keys(selectedMembers).length
);
export const getAllMembersAreSelected = createSelector(
  getSearchedQBMembers,
  getSelectedMembers,
  (searchedMembers, selectedMembers) =>
    searchedMembers.every((member) => selectedMembers[member.id])
);

export const makeGetMappingFilterValue = () =>
  createSelector(
    getOwnSection,
    getMemberMappingValue,
    getActivityMappingValue,
    getProjectMappingValue,
    getProjectIsActiveMappingValue,
    (
      section,
      memberMappingValue,
      activityMappingValue,
      projectMappingValue,
      projectIsActiveMappingValue
    ) => {
      const mappings = {
        members: memberMappingValue,
        activities: activityMappingValue,
        projects: projectMappingValue,
        projectsIsActive: projectIsActiveMappingValue
      };
      return mappings[section];
    }
  );

export const isPendingStatus = createSelector(
  isPendingMembers,
  isPendingProjects,
  isPendingActivities,
  // isPendingPhases,
  (membersPending, projectsPending, activitiesPending, pendingPhases) => {
    return (
      membersPending || projectsPending || activitiesPending || pendingPhases
    );
  }
);

export const getIsDefaultTeamSelectedFlag = createSelector(
  getQBProjectsState,
  (state) => state.isDefaultTeamSelected
);
