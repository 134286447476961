import React from 'react';
import PropTypes from 'prop-types';
import { formatStatusDate } from 'appUtils';
import { Navbar as BootstrapNavbar, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import {
  SearchBar,
  AddEditProjectContainer,
  ActivityBell,
  TimerToggle
} from 'views';
import { NavLeft, NavRight } from './styles';
import { PermissionBasedNavItem } from './NavTextNavItem';
import cn from 'classnames';
import { InviteTeamMemberFormContainer } from 'views/inviteMemberComponents/InviteTeamMemberFormContainer';
import { generatePageOverlay } from 'appUtils/styleUtils';
import {
  getMatchedRouteParams,
  getIsOnProjectView,
  getSelectedProjectBoardId,
  getGlobalMenuInviteFormOpen,
  getSidebarProjectsOpen,
  getSplitFlags
} from 'selectors';
import { toggleInviteForm, fetchActiveTimer } from 'actionCreators';
import GlobalAdd from 'components/GlobalAdd/GlobalAdd';
import { PageViewFilter } from './PageViewFilter';

const InvitationWrapper = styled.div`
  ${() =>
    generatePageOverlay({
      zIndex: 110,
      backgroundColor: theme.colors.colorPureBlack,
      opacity: '.65',
      pointerEvents: 'none'
    })};
`;
export class Navbar extends React.Component {
  componentDidMount() {
    const { me, fetchActiveTimer } = this.props;
    if (me.id) {
      fetchActiveTimer({
        body: {
          account_id: me.id
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { me, fetchActiveTimer } = this.props;
    if (me.id !== prevProps.me.id) {
      fetchActiveTimer({
        body: {
          account_id: me.id
        }
      });
    }
  }

  onInvitationWrapperClick = (e) => {
    e.stopPropagation();
  };

  toggleInviteForm = () =>
    this.props.toggleInviteForm({ open: true, origin: 'GlobalAdd' });

  render() {
    const {
      userFullName,
      isOpenUserMenu,
      isOpenHelpSubmenu,
      onSearch,
      onKeyUp,
      clearSearch,
      searchText,
      onFocusSearch,
      searched,
      isOnProjectDetail,
      isOnProjectPlanner,
      navActivity,
      navViewText,
      navToView,
      isOnSettingsView,
      isOnTeamSettingsView,
      onChange,
      checkForSearch,
      sidebarSearchOpen,
      toggleSidebarSearch,
      toggleSidebarProjects,
      unreadNotificationsCount,
      homeTaskModalIsOpen,
      isOnTeamProject,
      teamName,
      matchedParams,
      isOnProjectView,
      inviteFormOpen,
      selectedProjectBoardId,
      sidebarProjectsOpen,
      isTimeTrackingEnabled,
      viewFilterOnPlannerFlag
    } = this.props;

    const isOnSettingsPage = isOnSettingsView || isOnTeamSettingsView;

    const date = formatStatusDate(new Date());
    return (
      <div>
        <BootstrapNavbar
          className={cn('mosaic-nav-bar', {
            'on-settings': isOnSettingsPage,
            'on-project-detail': isOnProjectDetail,
            'menu-open': isOpenUserMenu || isOpenHelpSubmenu,
            'is-gray': isOnProjectPlanner,
            'home-task-modal-open': homeTaskModalIsOpen,
            'sidebar-projects-open': sidebarProjectsOpen
          })}
          light
        >
          <NavLeft navbar>
            <NavItem
              className={cn('nav-view-type', {
                'project-detail': isOnProjectDetail
              })}
              onClick={navToView}
            >
              <PermissionBasedNavItem
                navViewText={navViewText}
                isOnTeamProject={isOnTeamProject}
                teamName={teamName}
                isOnProjectView={isOnProjectView}
                toggleInviteForm={this.toggleInviteForm}
                isOnSettingsView={isOnSettingsView}
                isOnTeamSettingsView={isOnTeamSettingsView}
                boardId={
                  isOnProjectView
                    ? selectedProjectBoardId
                    : matchedParams && matchedParams.boardId
                }
              />
            </NavItem>
            {viewFilterOnPlannerFlag && <PageViewFilter />}
          </NavLeft>

          <NavRight className="d-print-none" navbar>
            {!sidebarSearchOpen && (
              <>
                <GlobalAdd />
                {isTimeTrackingEnabled && <TimerToggle />}
                <NavItem className="hide-on-search">
                  <ActivityBell
                    handleClick={navActivity}
                    unreadNotificationsCount={unreadNotificationsCount}
                  />
                </NavItem>
              </>
            )}
            <NavItem className="nav-item-search-bar">
              <SearchBar
                clearSearch={clearSearch}
                onFocusSearch={onFocusSearch}
                onKeyUp={onKeyUp}
                onChange={onChange}
                onSearch={onSearch}
                searched={searched}
                searchText={searchText}
                checkForSearch={checkForSearch}
                sidebarSearchOpen={sidebarSearchOpen}
                toggleSidebarSearch={toggleSidebarSearch}
                toggleSidebarProjects={toggleSidebarProjects}
              />
            </NavItem>
          </NavRight>
          <NavItem className="print-name-date d-none d-print-inline-block">
            <p>{date}</p>
            <p>{userFullName}</p>
          </NavItem>
        </BootstrapNavbar>
        {inviteFormOpen ? (
          <InvitationWrapper onClick={this.onInvitationWrapperClick}>
            <InviteTeamMemberFormContainer />
          </InvitationWrapper>
        ) : null}
        <AddEditProjectContainer />
      </div>
    );
  }
}

Navbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  isOnProjectDetail: PropTypes.bool,
  isOpenHelpSubmenu: PropTypes.bool.isRequired,
  isOpenUserMenu: PropTypes.bool.isRequired,
  me: PropTypes.object.isRequired,
  onFocusSearch: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  searched: PropTypes.bool,
  searchText: PropTypes.string,
  userFullName: PropTypes.string
};

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  selectedProjectBoardId: getSelectedProjectBoardId(state),
  inviteFormOpen: getGlobalMenuInviteFormOpen(state),
  sidebarProjectsOpen: getSidebarProjectsOpen(state),
  isTimeTrackingEnabled: getSplitFlags(state).timeTrackingFlag,
  viewFilterOnPlannerFlag: getSplitFlags(state).viewFilterOnPlannerFlag
});

const mapDispatchToProps = {
  toggleInviteForm,
  fetchActiveTimer
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
