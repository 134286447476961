import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'moment';
const StyledDateContainer = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: space-between;
  padding-top: 11px;
  padding-left: 1px;
`;
const StyledDate = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 12px;
  min-width: 55px;
`;
const StyledDash = styled.div`
  margin: 0px 8px;
`;
const MemberDateCell = ({ row }) => {
  const {
    member,
    list,
    customRowProps: { showDemoSuggestions }
  } = row.original;

  if (!showDemoSuggestions) return null;

  //  Below is the demo case.
  const startDate = member.start_date;
  const endDate = member.end_date;

  return (
    <StyledDateContainer>
      <StyledDate>
        {' '}
        {moment(startDate, 'YYYY-MM-DD').format('M/D/YY')}{' '}
      </StyledDate>
      <StyledDash> - </StyledDash>
      <StyledDate>
        {' '}
        {moment(endDate, 'YYYY-MM-DD').format('M/D/YY')}{' '}
      </StyledDate>
    </StyledDateContainer>
  );
};
export default MemberDateCell;
