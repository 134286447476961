import React from 'react';
import TaskCard from './TaskCard';
import ReadOnlyTaskCard from './ReadOnlyTaskCard';

class TaskCardSwitch extends React.Component {
  state = {
    isEditable: false
  };

  enableEditing = () => {
    /*
      It is unclear what condition would make it safe to toggle back
      to read-only. Therefore, set to editable and forget about it.
    */
    this.setState({ isEditable: true });
  };

  render() {
    const readOnlyProps = {
      taskId: this.props.taskId,
      unscheduled: this.props.unscheduled
    };

    return this.state.isEditable ? (
      <TaskCard {...this.props} />
    ) : (
      <ReadOnlyTaskCard enableEditing={this.enableEditing} {...readOnlyProps} />
    );
  }
}

export default TaskCardSwitch;
