import { fork, takeEvery, all } from 'redux-saga/effects';
import * as workers from './currency';
import { updateEntityDefaultCurrency } from '../actionCreators';

export const currencySagas = [
  fork(
    takeEvery,
    updateEntityDefaultCurrency,
    workers.updateEntityDefaultCurrencyWorker
  )
];

export default function* currencyModuleSagas() {
  yield all(currencySagas);
}
