import styled from 'styled-components';
import theme from 'theme';
import HomeTasksIcon from 'icons/HomeTasksIcon';
import HomeCompletedIcon from 'icons/HomeCompletedIcon';
import HomeAssignedToMeIcon from 'icons/HomeAssignedToMeIcon';
import HomeScheduledIcon from 'icons/HomeScheduledIcon';
import TimeIcon from 'icons/TimeIcon';
import WorkPlanIcon from 'icons/WorkPlanIcon';
import MenuNotificationIcon from 'icons/MenuNotificationIcon';
import MenuCheckinsIcon from 'icons/MenuCheckinsIcon';
import MenuDashboardIcon from 'icons/MenuDashboardIcon';
import { Link } from 'react-router-dom';

const pillColor = {
  new: theme.colors.colorRoyalBlue,
  'past due': theme.colors.colorDeleteRed,
  today: theme.colors.colorCalendarOrange
};

export const Pill = styled.div`
  font-size: 10px;
  font-weight: 800;
  text-align: center;
  color: ${theme.colors.colorPureWhite};
  background: ${({ type }) => pillColor[type]};
  padding: 0px 3px;
  height: 15px;
  flex-grow: 1;

  &:first-child {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  &:last-child {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
`;

export const HomeSidebarContainerWrapper = styled.div`
  position: absolute;
  width: 220px;
  left: 60px;
  top: -10px;
  height: 100vh;
  opacity: 1;
  background: ${theme.colors.colorTranslucentGray4};
  margin: 8px 0px 0px 0px;
  min-height: 360px;
  overflow-y: hidden;
  z-index: 5;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;

export const StyledLockIconContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  display: flex;
  font-weight: 400;
  align-items: center;
  margin-left: 10px;
  position: relative;
  bottom: 2px;
`;

export const HomeText = styled.div`
  font-size: 28px;
  display: flex;
  align-items: baseline;
  margin-top: 14px;
  font-weight: 600;
  color: ${theme.colors.colorDarkBlue};
  margin-left: 22px;
`;
export const Menu = styled.div`
  outline: none;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-top: 20px;
  padding: 0px 10px 20px 24px;
`;

const menuItemStyles = `margin: 0;
position: relative;
display: flex;
padding: 10px 0px;
white-space: nowrap;
cursor: pointer;
align-items: center;
justify-content: space-between;
&:hover {
  text-decoration: none;
}`;

export const MenuItem = styled(Link)`
  ${menuItemStyles}
`;

export const DisabledMenuItem = styled.div`
  ${menuItemStyles}
`;

export const IconContainer = styled.div`
  flex: 0 0 16px;
  margin-right: 5px;
  height: 16px;
  border-radius: 24px;
  background: ${({ isSelected }) =>
    isSelected ? theme.colors.colorDarkBlue : theme.colors.colorMediumGray5};
`;
export const MenuItemText = styled.span`
  margin-left: 5px;
  margin-top: 1px;
  vertical-align: middle;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  font-size: 14px;
  color: ${(props) =>
    props.isSelected
      ? theme.colors.colorDarkBlue
      : theme.colors.colorMediumGray5};
`;
export const PillsContainer = styled.div`
  display: ${({ isSelected }) => (isSelected ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 10px;
`;

export const StyledTasksIcon = styled(HomeTasksIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;
export const StyledHomeCompletedIcon = styled(HomeCompletedIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;
export const StyledHomeAssignedToMeIcon = styled(HomeAssignedToMeIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;
export const StyledHomeScheduledIcon = styled(HomeScheduledIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;

export const StyledTimeIcon = styled(TimeIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;

export const StyledWorkPlanIcon = styled(WorkPlanIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  transform: translateY(2px);
  ${({ iconStyle }) => iconStyle};
`;

export const StyledMenuDashboardIcon = styled(MenuDashboardIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;

export const StyledMenuNotificationIcon = styled(MenuNotificationIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;

export const StyledMenuCheckinsIcon = styled(MenuCheckinsIcon)`
  flex: 0 0 16px;
  margin-right: 5px;
  ${({ iconStyle }) => iconStyle};
`;

export const MenuItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    ${MenuItemText} {
      color: ${theme.colors.colorDarkBlue};
    }

    ${StyledMenuDashboardIcon} {
      path {
        fill: ${theme.colors.colorDarkBlue};
      }
    }

    ${StyledMenuNotificationIcon} {
      path {
        fill: ${theme.colors.colorDarkBlue};
        stroke: ${theme.colors.colorDarkBlue};
      }
    }

    ${StyledWorkPlanIcon} {
      path {
        stroke: ${theme.colors.colorDarkBlue};
      }
    }

    ${StyledTasksIcon} {
      path {
        fill: ${theme.colors.colorDarkBlue};
        stroke: ${theme.colors.colorDarkBlue};
      }
      circle {
        stroke: ${theme.colors.colorDarkBlue};
      }
    }

    ${StyledHomeScheduledIcon},
    ${StyledHomeAssignedToMeIcon} {
      path {
        fill: ${theme.colors.colorDarkBlue};
      }
    }

    ${StyledTimeIcon} {
      path {
        stroke: ${theme.colors.colorDarkBlue};
      }
    }

    ${StyledHomeCompletedIcon} {
      circle {
        fill: ${theme.colors.colorDarkBlue};
        stroke: ${theme.colors.colorDarkBlue};
      }
    }
  }
`;

export const StyledDivider = styled.hr`
  width: 100%;
  margin: 10px 0px;
  color: ${theme.colors.colorMediumGray5};
`;
