import { useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchClients } from 'actionCreators';
import { getOrderedAllClients, getIsClientsLoaded } from 'selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { FilterListTypeHook } from 'FilterModule/types';

const emptyArray = [];
const emptyObj = {};

export const useClientNamesFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.clients
}: Parameters<FilterListTypeHook>[0] = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  // List of the team's clients
  const clients = useAppSelector((state) =>
    isOff ? emptyArray : getOrderedAllClients(state)
  ) as { title: string }[]; // FIXME

  const clientNames = useMemo(
    () => (isOff ? emptyArray : clients.map((client) => client.title)),
    [clients, isOff]
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: clientNames,
    isOff,
    config,
    resultCountHash,
    shouldUseDraft
  });

  /* --------------------------------- loading -------------------------------- */

  const isLoaded = useAppSelector(getIsClientsLoaded);

  // load clients if necessary
  useEffect(() => {
    if (!isOff && !isLoaded && teamId) {
      dispatch(fetchClients({ teamId }));
    }
  }, [dispatch, teamId, isOff, isLoaded]);

  return {
    ...arrayFilterFieldValues,
    isLoading: !isLoaded || isResultsLoading,
    resultCountHash
  };
};
