import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import ConfirmCompleteTaskModal from '../Tasks/ConfirmCompleteTaskModal';
import PlannerHeader from './PlannerHeader';
import CheckinContainer from 'views/Home/Widgets/Widgets/DailyCheckinWidget';
import { BodyColor, MilestoneModal } from 'views';

import {
  WEEK_FETCH_OFFSET,
  WEEK_FETCH_AHEAD_OFFSET,
  TODAY
} from 'appConstants/planners';

import TaskListTitle from './TaskListTitle';
import {
  triggerFetchInitialTasks,
  triggerTaskStoreFlush,
  fetchAllHomeTaskTags,
  setTaskAccountFilter,
  resetTaskFilters,
  setCurrentTaskListFilter,
  fetchPlanners,
  setBatchSelectedTasks,
  clearBatchSelectedTasks,
  closeMilestoneModal,
  fetchTimers
} from 'actionCreators';

import TasksList from './TasksList';

import {
  makeScheduleGroupedTasks,
  makePlannerGroupedTasks,
  getIsOnTeamProject,
  getIsOnPersonalProject,
  getSelectedTeamMember,
  getAllTasks,
  getCurrentFilter,
  getAuthToken,
  getBatchSelectedTaskIds,
  getCurrentPage,
  getMatchedRouteParams,
  getIsOnProjectView,
  getOnMembersView,
  getOnProfile,
  getMyTeamMembership,
  getOnHomeView,
  getCurrentUserId,
  getIsMemberModalOpen,
  getIsMilestoneModalOpen,
  getSelectedTeamId,
  getHomeTaskObj
} from 'selectors';
import TaskListHeader from './TaskListHeader';

import {
  fetchMemberBudgets,
  fetchPositions
} from 'BudgetModule/actionCreators';
import { getCurrentAccount } from 'AuthenticationModule/selectors';

const TaskListWrapper = styled.div`
  position: relative;
  height: 100%;
  width: ${({ width }) => width || '100%'};
  flex: ${({ flex }) => flex || 1};
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
`;

const NOTIFICATIONS = 'notifications';
const CHECK_INS = 'check-in';
const MY_TASKS = 'tasks';
const PLANNER = 'planner';
const TIME = 'time';
const ASSIGNED_BY_ME = 'assigned-by-me';
const COMPLETED = 'completed';

class TasksListContainer extends Component {
  state = {
    plannerDate: TODAY
  };

  componentDidMount() {
    const {
      handleFetchInitialTasks,
      isOnHomeView,
      matchedParams: { homeViewType, projectId },
      isMemberModalOpen,
      fetchPositions,
      fetchMemberBudgets,
      teamId,
      taskHash,
      allTasks
    } = this.props;
    this.handleSidebarNavigation(homeViewType);
    this.initViewFilters();
    if (!isMemberModalOpen) {
      this.fetchTasksAndTags();
    }
    if (
      isOnHomeView &&
      this.props.taskFilter.section === 'My Tasks' &&
      this.props.taskFilter.subSection === 'scheduled'
    ) {
      this.fetchPersonalPlanner();
      handleFetchInitialTasks({
        pastScheduled: true
      });
    }
    if (projectId) {
      fetchMemberBudgets({ projectId });
    } else if (allTasks) {
      const projectIdsOfTask = [
        ...new Set(
          allTasks.reduce((acc, cur) => {
            if (taskHash[cur]?.project_id) {
              acc.push(taskHash[cur]?.project_id);
            }
            return acc;
          }, [])
        )
      ];
      fetchMemberBudgets({ projectIds: projectIdsOfTask });
    }
    if (teamId) {
      fetchPositions({ teamId });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      handleTaskStoreFlushTrigger,
      taskFilter,
      handleFetchInitialTasks,
      matchedParams: { projectId },
      matchedParams: { homeViewType },
      selectedTeamMember,
      isMemberModalOpen,
      fetchMemberBudgets,
      fetchPositions,
      taskHash,
      allTasks,
      teamId
    } = this.props;

    if (prevProps.matchedParams.homeViewType !== homeViewType) {
      this.handleSidebarNavigation(homeViewType);
    }
    if (
      prevProps.taskFilter.section !== taskFilter.section ||
      prevProps.taskFilter.subSection !== taskFilter.subSection ||
      prevProps.matchedParams.projectId !== projectId ||
      prevProps.selectedTeamMember?.account?.id !==
        selectedTeamMember?.account?.id
    ) {
      handleTaskStoreFlushTrigger();
      this.fetchTasksAndTags();
    }
    if (
      (prevProps.taskFilter.scope !== taskFilter.scope ||
        prevProps.taskFilter.state !== taskFilter.state) &&
      !isMemberModalOpen
    ) {
      this.fetchTasksAndTags();
    }
    if (
      prevProps.taskFilter.subSection !== 'scheduled' &&
      this.props.taskFilter.subSection === 'scheduled'
    ) {
      this.fetchPersonalPlanner();
      handleFetchInitialTasks({
        pastScheduled: true
      });
    }
    if (prevProps.matchedParams.projectId !== projectId) {
      fetchMemberBudgets({ projectId });
    } else if (prevProps.allTasks !== allTasks) {
      const projectIdsOfTask = [
        ...new Set(
          allTasks.reduce((acc, cur) => {
            if (taskHash[cur]?.project_id) {
              acc.push(taskHash[cur]?.project_id);
            }
            return acc;
          }, [])
        )
      ];
      fetchMemberBudgets({ projectIds: projectIdsOfTask });
    }
    if (prevProps.teamId !== teamId) {
      fetchPositions({ teamId });
    }
  }

  componentWillUnmount() {
    this.props.handleTaskStoreFlushTrigger();
    this.props.resetTaskFilters();
  }

  handleSidebarNavigation = (key) => {
    const {
      setCurrentTaskListFilter,
      setTaskAccountFilter,
      accountId,
      fetchTimers
    } = this.props;

    switch (key) {
      case NOTIFICATIONS: {
        setTaskAccountFilter({ selectedAccountIds: [accountId] });
        setCurrentTaskListFilter({
          scope: 'today',
          state: 'default',
          section: 'My Tasks',
          subSection: 'notifications'
        });
        break;
      }
      case CHECK_INS: {
        setTaskAccountFilter({ selectedAccountIds: [accountId] });
        setCurrentTaskListFilter({
          scope: 'today',
          state: 'default',
          section: 'My Tasks',
          subSection: 'checkins',
          viewType: 'DAY'
        });
        break;
      }
      case MY_TASKS: {
        setTaskAccountFilter({ selectedAccountIds: [accountId] });
        setCurrentTaskListFilter({
          scope: 'accepted',
          state: 'incomplete',
          section: 'My Tasks',
          subSection: 'inbox'
        });
        break;
      }
      case COMPLETED: {
        setTaskAccountFilter({ selectedAccountIds: [accountId] });

        setCurrentTaskListFilter({
          scope: 'accepted',
          state: 'completed',
          section: 'My Tasks',
          subSection: 'completed'
        });
        break;
      }
      case PLANNER: {
        setTaskAccountFilter({ selectedAccountIds: [accountId] });
        setCurrentTaskListFilter({
          scope: 'today',
          state: 'default',
          section: 'My Tasks',
          subSection: 'scheduled',
          viewType: 'DAY'
        });
        fetchTimers({
          body: {
            account_id: accountId,
            date: moment().format('YYYY-MM-DD')
          }
        });
        break;
      }
      case TIME: {
        setTaskAccountFilter({ selectedAccountIds: [accountId] });
        setCurrentTaskListFilter({
          scope: 'today',
          state: 'default',
          section: 'My Tasks',
          subSection: 'time'
        });
        break;
      }
      case ASSIGNED_BY_ME: {
        setTaskAccountFilter({ selectedAccountIds: [] });
        setCurrentTaskListFilter({
          scope: 'assigned_by_me',
          state: 'incomplete',
          section: 'Assigned By Me',
          subSection: 'assigned'
        });
        break;
      }
    }
  };

  initViewFilters = () => {
    const {
      isOnMemberProfile,
      setCurrentTaskListFilter,
      setTaskAccountFilter,
      currentAccount,
      selectedTeamMember,
      taskFilter,
      isOnProjectView,
      matchedParams: { homeViewType }
    } = this.props;

    const { subSection } = taskFilter;
    const onProjectDetail = isOnProjectView;

    if (onProjectDetail) {
      setCurrentTaskListFilter({ scope: 'project', state: 'incomplete' });
      setTaskAccountFilter({ selectedAccountIds: [] });
    } else if (isOnMemberProfile) {
      setCurrentTaskListFilter({ scope: 'profile', state: 'incomplete' });
      setTaskAccountFilter({
        selectedAccountIds: [selectedTeamMember.account.id]
      });
    } else if (currentAccount) {
      // initializing list on home
      // returns a user to the same My Tasks filter they were viewing before they left
      setTaskAccountFilter({
        selectedAccountIds:
          homeViewType === 'assigned' ? [] : [currentAccount.id]
      });
      this.handleSidebarNavigation(homeViewType);
    }
  };

  fetchPersonalPlanner = () => {
    const { handleFetchPlanners, token, currentAccount } = this.props;

    if (currentAccount) {
      handleFetchPlanners(token, {
        baseDate: moment(),
        rightOffset: WEEK_FETCH_AHEAD_OFFSET,
        leftOffset: WEEK_FETCH_OFFSET,
        accountIds: [currentAccount.id]
      });
    }
  };

  fetchTasksAndTags = () => {
    const {
      handleFetchInitialTasks,
      fetchAllHomeTaskTags,
      taskFilter,
      matchedParams: { projectId },
      isOnProjectView,
      isOnProfile,
      selectedTeamMember
    } = this.props;
    const profileFetch = {
      assigneeIds: selectedTeamMember ? [selectedTeamMember.account.id] : []
    };
    const nonProfileFetch = {
      projectId: isOnProjectView && projectId
    };
    const fetchBody = isOnProfile ? profileFetch : nonProfileFetch;

    if (!isOnProjectView) {
      handleFetchInitialTasks(fetchBody);
    }

    fetchAllHomeTaskTags({
      filter:
        taskFilter.state === 'completed' ? taskFilter.state : taskFilter.scope,
      projectId: isOnProjectView && projectId
    });
  };

  isOnMemberProfile = () => {
    return window.location.href.includes('/profile/'); // we are not counting in for member profiles in currentFilter
  };

  setPlannerViewType = (newPlannerViewType) => {
    const { taskFilter, setCurrentTaskListFilter } = this.props;
    setCurrentTaskListFilter({
      ...taskFilter,
      viewType: newPlannerViewType
    });
  };

  // prevDay = () => {
  //   const { plannerDate } = this.state;
  //   const dateClone = plannerDate.clone();
  //   dateClone.subtract(1, 'days');
  //   this.setState({
  //     plannerDate: dateClone
  //   });
  // };

  // nextDay = () => {
  //   const { plannerDate } = this.state;
  //   const dateClone = plannerDate.clone();
  //   dateClone.add(1, 'days');
  //   this.setState({
  //     plannerDate: dateClone
  //   });
  // };

  render() {
    const {
      taskFilter,
      isOnHomeView,
      isOnProfile,
      isOnPersonalProject,
      scheduledGroups,
      plannerGroups,
      setBatchSelectedTasks,
      clearBatchSelectedTasks,
      allTasks,
      batchSelectedTaskIds,
      currentPage,
      isOnProjectView,
      isOnTeamProject,
      isMemberModalOpen,
      plannerViewType,
      isOnMembersView,
      isMilestoneModalOpen,
      closeMilestoneModal
    } = this.props;

    const { plannerDate } = this.state;

    // isOnTeamProject only tells us what whether the selected project in redux is personal or not
    const renderAsTeamProject = isOnTeamProject && !isOnHomeView;
    const isDayPlannerView =
      taskFilter?.subSection === 'scheduled' &&
      plannerViewType === 'DAY' &&
      !isOnProjectView;

    const defaultFilter = {
      scope: 'accepted',
      section: 'My Tasks',
      state: 'incomplete',
      subSection: 'inbox'
    };

    const newTaskFilter = isMemberModalOpen ? defaultFilter : taskFilter;

    return (
      <>
        {!isOnProjectView && !isOnMembersView && <BodyColor isWhite />}
        <TaskListWrapper
          width={isOnHomeView ? 'fit-content' : '100%'}
          flex={isOnHomeView ? 'unset' : 1}
        >
          {taskFilter?.subSection === 'checkins' &&
            !isOnProjectView &&
            !isMemberModalOpen && (
              <>
                {/* The props that is being passed in to Planner Header were not taking
                any effect anyways, so just commenting it out until it needs one. */}
                <PlannerHeader
                  // nextDay={this.nextDay}
                  // prevDay={this.prevDay}
                  // plannerDate={plannerDate}
                  plannerViewType={plannerViewType}
                  setPlannerViewType={this.setPlannerViewType}
                  isNewPlanner
                />
                <CheckinContainer
                  showInLineWorkCategory
                  footerButtonClassName="check-ins-view-buttons"
                />
              </>
            )}
          {/* The props that is being passed in to Planner Header were not taking
          any effect anyways, so just commenting it out until it needs one. */}
          {taskFilter?.subSection === 'scheduled' &&
            !isOnProjectView &&
            !isMemberModalOpen && (
              <PlannerHeader
                // nextDay={this.nextDay}
                // prevDay={this.prevDay}
                // plannerDate={plannerDate}
                plannerViewType={plannerViewType}
                setPlannerViewType={this.setPlannerViewType}
              />
            )}
          {!isOnProjectView && !isOnProfile && !isMemberModalOpen && (
            <TaskListTitle taskFilter={newTaskFilter} />
          )}
          {((!currentPage.isToday && !isOnProjectView && !isMemberModalOpen) ||
            (isDayPlannerView && !isMemberModalOpen)) &&
            taskFilter?.subSection !== 'checkins' && (
              <TaskListHeader
                selectedBatchTaskIds={batchSelectedTaskIds}
                setBatchSelectedTasks={setBatchSelectedTasks}
                clearBatchSelectedTasks={clearBatchSelectedTasks}
                taskFilter={newTaskFilter}
                isOnTeamProject={renderAsTeamProject}
                isOnHomeView={isOnHomeView}
                isOnCompletedView={currentPage.isHomeCompleted}
                TaskListColumnsHeader={false}
                isTaskListColumnsHeaderVisible={true}
                isSortable={true}
                taskIds={allTasks}
                isOnInboxView={currentPage.isInbox}
                isOnTodayView={currentPage.isToday}
                isDayPlannerView={isDayPlannerView}
              />
            )}
          {taskFilter?.subSection !== 'checkins' && (
            <TasksList
              toggleTaskBatchAction={this.toggleTaskBatchAction}
              isOnHomeView={isOnHomeView}
              scheduledGroups={
                isOnHomeView && currentPage.isToday && scheduledGroups
              }
              plannerGroups={plannerGroups}
              shouldRenderCreateTaskButton={true}
              taskFilter={newTaskFilter}
              isOnTeamProject={renderAsTeamProject}
              isOnPersonalProject={isOnPersonalProject}
              isDayPlannerView={isDayPlannerView}
              plannerDate={plannerDate}
              plannerViewType={plannerViewType}
            />
          )}
          <ConfirmCompleteTaskModal />
          <MilestoneModal
            isOpen={isMilestoneModalOpen}
            toggle={closeMilestoneModal}
          />
        </TaskListWrapper>
      </>
    );
  }
}

TasksListContainer.propTypes = {
  taskFilter: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  handleFetchInitialTasks: PropTypes.func.isRequired,
  handleTaskStoreFlushTrigger: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const isOnHomeView = getOnHomeView(state);
  const getScheduleGroupedTasks = makeScheduleGroupedTasks(isOnHomeView);
  const getPlannerGroupedTasks = makePlannerGroupedTasks(isOnHomeView);
  const matchedParams = getMatchedRouteParams(state);
  const taskFilter = getCurrentFilter(state);
  return {
    teamId: getSelectedTeamId(state),
    accountId: getCurrentUserId(state),
    currentAccount: getCurrentAccount(state),
    allTasks: getAllTasks(state),
    scheduledGroups: getScheduleGroupedTasks(state),
    plannerGroups: getPlannerGroupedTasks(state),
    taskFilter,
    plannerViewType: taskFilter.viewType,
    token: getAuthToken(state),
    isOnHomeView,
    isOnProfile: getOnProfile(state),
    isOnTeamProject: getIsOnTeamProject(state),
    isOnPersonalProject: getIsOnPersonalProject(state),
    selectedTeamMember: getSelectedTeamMember(state),
    batchSelectedTaskIds: getBatchSelectedTaskIds(state),
    currentPage: getCurrentPage(state),
    matchedParams,
    isOnProjectView: getIsOnProjectView(state),
    isOnMembersView: getOnMembersView(state),
    isOnMemberProfile: getOnProfile(state),
    myTeamMembership: getMyTeamMembership(state),
    isMemberModalOpen: getIsMemberModalOpen(state),
    isMilestoneModalOpen: getIsMilestoneModalOpen(state),
    taskHash: getHomeTaskObj(state)
  };
};
const mapDispatchToProps = {
  handleFetchInitialTasks: triggerFetchInitialTasks,
  handleFetchPlanners: fetchPlanners,
  handleTaskStoreFlushTrigger: triggerTaskStoreFlush,
  closeMilestoneModal,
  fetchAllHomeTaskTags,
  setTaskAccountFilter,
  resetTaskFilters,
  setCurrentTaskListFilter,
  setBatchSelectedTasks,
  clearBatchSelectedTasks,
  fetchTimers,
  fetchMemberBudgets,
  fetchPositions
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TasksListContainer)
);
