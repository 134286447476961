import { RootState, ReducerName } from 'reduxInfra/shared';
import { createSelector, Selector } from '@reduxjs/toolkit';

const getOwnIsoStateId = (
  _: RootState,
  ownProps: {
    [key: string]: unknown;
  } & {
    isoStateId: string;
  }
) => ownProps.isoStateId;

export const makeGetIsoState = <IsoStateType>(
  reducerName: ReducerName,
  initialIsoState: IsoStateType
) =>
  createSelector<
    [
      Selector<RootState, Record<string, IsoStateType> | undefined>,
      typeof getOwnIsoStateId
    ],
    IsoStateType
  >(
    (state) => state[reducerName]?.isoStates,
    getOwnIsoStateId,
    (isoStates, isoStateId) => isoStates?.[isoStateId] || initialIsoState
  );
