import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import {
  getSelectedTeamId,
  getSelectedTeamDefaultFeeTarget,
  getSelectedTeamViewSettings,
  getSplitFlags
} from 'selectors';
import { getOverhead } from 'BudgetModule/selectors';
import { updateTeamViewSettings } from 'actionCreators';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import BudgetAdminTable from './BudgetAdminTable';
import HelpIcon from 'images/help-icon-2.svg';
import PencilIcon from 'icons/PencilIcon';
import OverheadModal from './CostRate/OverheadModal';
import FeeTargetModal from './FeeTargetModal';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { ToggleContainer, ToggleOption } from 'components/styles';
import { DEFAULT_FEE_TARGET_PERCENTAGE } from 'appConstants';
import RateTypeSelector, {
  StyledFilterOption
} from 'components/options/RateTypeSelector';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';

const StyledPencil = styled(PencilIcon)`
  opacity: 0;
  height: 10px;
  margin-left: 3px;
  margin-bottom: 1px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
const SettingValue = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  &:hover {
    ${StyledPencil} {
      opacity: 1;
    }
  }
  width: 31%;
`;
const SettingLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  width: 69%;
  white-space: nowrap;
`;
const SettingsRow = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
`;
const SettingsBox = styled.div`
  width: 246px;
  position: absolute;
  left: 589px;
  top: -12px;
  background: white;
  padding: 5px 15px;
  border: 1px solid ${theme.colors.colorPaleGray9};
  ${(props) =>
    props.noRateToggle &&
    `
    left: 653px;
  `}
  ${StyledFilterOption} {
    font-weight: 600;
  }
`;

class BudgetAdminTab extends React.Component {
  state = {
    adminTabOpen: true,
    overheadModalOpen: false,
    feeTargetModalOpen: false,
    costRateConfirmOpen: false
  };

  componentDidMount() {
    const { teamId, fetchEntityOptions } = this.props;
    fetchEntityOptions({
      team_id: teamId,
      entity_type: ENTITY_TYPES.project
    });
    rebuildTooltip();
  }

  openOverheadModal = () => this.setState({ overheadModalOpen: true });

  openFeeTargetModal = () => this.setState({ feeTargetModalOpen: true });

  handleRateTypeChange = (nextIsViewingCostRate) => {
    if (nextIsViewingCostRate) {
      this.setState({ costRateConfirmOpen: true });
    } else {
      this.setIsUsingCostRate(false);
    }
  };

  setIsUsingCostRate = (nextIsViewingCostRate) => {
    const { teamViewSettings, updateTeamViewSettings, teamId } = this.props;
    const viewSettings = {
      ...teamViewSettings,
      use_cost_rate: nextIsViewingCostRate
    };
    updateTeamViewSettings({
      viewSettings,
      teamId,
      originalValues: {
        use_cost_rate: teamViewSettings.use_cost_rate
      }
    });
  };

  render() {
    const {
      overheadFactor,
      teamViewSettings,
      showRateToggle,
      teamDefaultTargetFeePercentage
    } = this.props;
    const settingsLoaded = !!overheadFactor;
    const isUsingCostRate = teamViewSettings.use_cost_rate;

    return (
      <DynamicModuleLoader modules={[getBudgetModule()]}>
        {settingsLoaded && (
          <SettingsBox noRateToggle={!showRateToggle}>
            {/* <SettingsRow>
              <SettingLabel>
                OVERHEAD FACTOR
                <img
                  data-for="app-tooltip"
                  data-tip="Salary expense is multiplied by <br/> Overhead Factor to determine <br/> member's hourly cost."
                  data-html
                  data-effect="solid"
                  src={HelpIcon}
                  style={{ marginLeft: 4 }}
                />
              </SettingLabel>

              <SettingValue onClick={this.openOverheadModal}>
                {overheadFactor}
                <StyledPencil onClick={this.openOverheadModal} size={12} />
              </SettingValue>
            </SettingsRow> */}
            {showRateToggle && (
              <SettingsRow>
                <SettingLabel>
                  BUDGET TRACKING
                  <img
                    data-for="app-tooltip"
                    data-tip="Set which rate is used in <br/> Project Fee Budget Calcs."
                    data-html
                    data-effect="solid"
                    src={HelpIcon}
                    style={{ marginLeft: 4, marginRight: 12 }}
                  />
                </SettingLabel>
                <SettingValue>
                  <RateTypeSelector
                    onSelect={this.handleRateTypeChange}
                    isTeamSetting
                  />
                </SettingValue>
              </SettingsRow>
            )}
            <SettingsRow>
              <SettingLabel>
                {isUsingCostRate ? 'COST RATE TARGET' : '% of Fee Default'}
                <img
                  data-for="app-tooltip"
                  data-tip="Enter the % of Fee used to <br/> budget projects."
                  data-html
                  data-effect="solid"
                  src={HelpIcon}
                  style={{ marginLeft: 4 }}
                />
              </SettingLabel>
              <SettingValue onClick={this.openFeeTargetModal}>
                {formatNumWithMaxOneDecimal(
                  teamDefaultTargetFeePercentage ||
                    DEFAULT_FEE_TARGET_PERCENTAGE
                )}
                %
              </SettingValue>
            </SettingsRow>
          </SettingsBox>
        )}
        <div
          style={{
            flexGrow: '1',
            padding: '0 15px',
            marginTop: 15,
            overflow: 'hidden'
          }}
        >
          <BudgetAdminTable />
        </div>
        {this.state.overheadModalOpen && (
          <OverheadModal
            toggle={() => this.setState({ overheadModalOpen: false })}
          />
        )}
        {this.state.feeTargetModalOpen && (
          <FeeTargetModal
            toggle={() => this.setState({ feeTargetModalOpen: false })}
          />
        )}
        {this.state.costRateConfirmOpen && (
          <SimpleConfirmModal
            isOpen
            onConfirm={() => this.setIsUsingCostRate(true)}
            toggle={() => this.setState({ costRateConfirmOpen: false })}
            confirmLabel="Confirm"
            header="Use Cost Rate?"
            body="All Project Budgets will be changed to be based on Cost Rate, which includes member salary and will be visible to all Financial Managers and Admins."
          />
        )}
      </DynamicModuleLoader>
    );
  }
}
const mapStateToProps = (state) => ({
  teamId: getSelectedTeamId(state),
  teamDefaultTargetFeePercentage: getSelectedTeamDefaultFeeTarget(state),
  overheadFactor: getOverhead(state).overhead_factor,
  teamViewSettings: getSelectedTeamViewSettings(state),
  showRateToggle: getSplitFlags(state).budgetRateToggle
});

const mapDispatchToProps = {
  updateTeamViewSettings,
  fetchEntityOptions
};
export default connect(mapStateToProps, mapDispatchToProps)(BudgetAdminTab);
