import { useState, useRef, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { navigateToStandardRolesSettings } from 'actionCreators';
import {
  getTeamSlug,
  getUserIsAdmin,
  getSelectedTeamId,
  getOwnProjectUnassignedMemberBudgets,
  getOwnProjectUnassignedCounts,
  getOwnHighestPositionNumberByPositionId,
  getFetchedMemberBudgetProjectIds,
  getFlatPhasesHash,
  getScopeHash
} from 'selectors';

import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import styled from 'styled-components';
import theme from 'theme';
import {
  TextButton,
  TextButtonWithBorder,
  ButtonContainer
} from 'components/styles';

import ReactTooltip from 'react-tooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import UnassignedMemberIcon from 'icons/UnassignedMemberIcon';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import Popover from 'components/Popover';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';

import {
  getMemberBudgets,
  getOrderedCurrentTeamRates
} from 'BudgetModule/selectors';
import {
  getCurrentPositions,
  getPositions,
  getIsFetchingPositions
} from 'BudgetModule/selectors/positions';
import {
  fetchPositions,
  fetchMemberBudgets
} from 'BudgetModule/actionCreators';
import {
  formatUnassignedRoleNameByPositionNumber,
  formatUnassignedRoleName
} from 'appUtils/hooks/budget/useMemberBudgetName';
import { sortPositionNames, serializeRoleId } from 'components/roles/utils';

import countBy from 'lodash/countBy';
import differenceBy from 'lodash/differenceBy';
import KaratRight from 'icons/KaratRight';

import cn from 'classnames';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import { createProjectMemberships } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';
import useCan from 'appUtils/hooks/useCan';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { getUnassignedDropdownItems } from './helpers';

const ItemDetails = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  align-items: center;
`;

const StyledItemName = styled.div`
  color: ${(props) =>
    !props.isSelected
      ? theme.colors.colorMediumGray5
      : theme.colors.colorSemiDarkGray2};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

const ItemRow = styled.div`
  padding-left: 15px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  ${(props) => props.selected && 'font-weight: 600;'}
`;

const ItemAddText = styled.div`
  position: absolute;
  top: 18px;
  left: 58px;
  font-size: 9px;
  color: ${(props) =>
    !props.isSelected
      ? theme.colors.colorMediumGray5
      : theme.colors.colorSemiDarkGray2};
`;

const NameContainer = styled.div`
  margin-left: 10px;
`;

export const RemoveText = styled.div`
  position: absolute;
  right: 16px;
  font-size: 12px;
  color: ${theme.colors.colorDeleteRed};
`;

export const RatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 175px;
  width: 255px;
  /* &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  } */
`;

export const RateLine = styled.div`
  font-size: 14px;
  align-items: center;
  display: grid;
  grid-template-columns: 70px 150px;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 10px 0 10px 20px;
  color: ${theme.colors.colorMediumGray9};
  cursor: pointer;
  ${(props) => props.selected && 'font-weight: 600;'}
  .rate-description {
    display: flex;
    align-items: center;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray1};
  }
`;

const StyledKaratContainer = styled.span`
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  position: absolute;
  right: 0px;
  width: 10px;
`;

/* ------------------------------------ - ----------------------------------- */

const Footer = (
  <LockWithTooltip
    text="Manage Standard Roles"
    tooltipContent="Only Admins can Add or Edit Standard Roles."
  />
);
const copy = {
  headerInitial: 'Edit Role',
  headerEdit: '',
  headerAdd: '',
  sticky: 'Add New',
  footerInitial: Footer,
  footerEdit: Footer,
  addConfirm: '',
  editConfirm: '',
  searchPlaceholder: 'Type name or select below'
};

const emptyArray = [];
/**
 * Will show a list of all unassigned roles that can be added.
 * Selected roles will appear at the top, followed by any existing unassigned roles already on the project,
 * and all unassigned roles in the orginization.
 *
 * Can select the rate which the unassigned role will have by clicking on the > icon.
 */
const BulkPositionDropdown = ({
  onClose,
  onAdd,
  closeOnSelect,
  targetRef,
  teamSlug,
  isAdmin,
  navigateToStandardRolesSettings,
  headerTitle,
  hideFooter,
  noHeader,
  phaseId,
  scopeId,
  projectId,
  activityPhaseId,
  teamId,
  positionHash,
  isFetchingPositions,
  positions,
  scopeHash,
  memberBudgetHash,
  phaseHash,
  fetchedMemberBudgetProjectIds,
  projectUnassignedMemberBudgets,
  projectUnassignedRoleCounts,
  highestPositionNumberByPositionId,
  currentRates,

  // action creators
  fetchPositions,
  fetchMemberBudgets,

  // custom
  excludeExisiting, // Will not display existing roles already on project
  isOnPlanner,
  isOnBudget,
  showAddToProjectOnly,
  shouldFetch = true, // If false, prevent fetching position and member budget from being fetched here (i.e: In case the container is already fetching them).
  customMakeBulkDropdownItems,
  customRenderItem,
  popoverClassName,
  confirmButtonText,
  isParentLoading = false,
  shouldShowCancelButton = true
}) => {
  /** Association between unassigned roll and rate
   *  Update whenever a rate is selected
   *
   * {
   *    item.id-item.name: Role
   * }
   *
   */
  const [unassignedRoleSelectedRates, setUnassignedRoleSelectedRates] =
    useState({});

  const rateSelectTargetRef = useRef(null);

  // Keeps track of if the rate flyout is open.
  const [isRateSelectionOpen, setIsRateSelectionOpen] = useState(false);

  // The selected role that the user wishes to change the rate for.
  const [changeRateRole, setChangeRateRole] = useState({});

  const scopeMemberBudgetIds = scopeId
    ? scopeHash[scopeId]?.member_budgets || emptyArray
    : emptyArray;

  // List of positions on phase already
  const phaseMemberships = phaseHash[phaseId]?.phase_memberships || emptyArray;
  // List of positions on activity phase already
  const activityPhaseMemberships =
    phaseHash[phaseId]?.activity_phases?.find((ap) => ap.id === activityPhaseId)
      ?.activity_phase_memberships || emptyArray;

  const phaseMembershipsMemberBudgetIds = useMemo(
    () =>
      (activityPhaseId ? activityPhaseMemberships : phaseMemberships).map(
        (phaseMembership) => phaseMembership.member_budget_id
      ),
    [activityPhaseId, activityPhaseMemberships, phaseMemberships]
  );

  const isProjectProvided = projectId !== undefined;

  const { isHoursOnly } = useIsHoursOnly();

  const [addedRoles, setAddedRoles] = useState([]);

  const { canAddProjectMembership } = useProjectPermissionState({
    projectId
  });

  // Count of unassigned position by positionId that have been selected so far.
  const addedRoleIdCount = useMemo(
    () =>
      countBy(
        addedRoles.filter((addedRole) => !addedRole.memberBudget),
        (addedRole) => addedRole.id
      ),
    [addedRoles]
  );

  const addRole = (role) => {
    setAddedRoles([...addedRoles, role]);
  };

  const removeRole = (role) => {
    if (role.memberBudget) {
      setAddedRoles(
        addedRoles.filter((addedRole) => {
          return addedRole?.memberBudget?.id !== role.memberBudget.id;
        })
      );
    } else {
      const position = positionHash[role?.id];
      const positionCount =
        ~~projectUnassignedRoleCounts[role.id] + ~~addedRoleIdCount[role.id];
      const deleteRoleName =
        positionCount === 1
          ? role.name
          : formatUnassignedRoleNameByPositionNumber({
              position,
              highestPositionNumberByPositionId,
              addedRoleIdCount
            });
      setAddedRoles(
        addedRoles.filter((addedRole) => {
          return addedRole.id !== role.id || addedRole.name !== deleteRoleName;
        })
      );
    }
  };

  const handleSelect = (e, { item }) => {
    e.preventDefault();

    if (item.unSelectable) return;

    if (
      addedRoles.find((role) => role.id === item.id && item.name === role.name)
    ) {
      removeRole(item);
    } else {
      addRole({ ...item, selected: 'true' });
    }
  };

  const isLoadingPositions = isFetchingPositions && !positions.length;
  const isLoadingProjectMemberBudgets =
    isProjectProvided &&
    [undefined, 'pending'].includes(fetchedMemberBudgetProjectIds[projectId]);
  const isLoading =
    isParentLoading || isLoadingPositions || isLoadingProjectMemberBudgets;

  /* ------------------------------------ - ----------------------------------- */

  // Since default behaviour when project is provided is to show existing roles,
  // if the project ends up not having any after fetching, we need to show standard roles

  // Show all the roles, show selected at the top
  useEffect(() => {
    if (teamId && shouldFetch) {
      fetchPositions({ teamId });
    }
  }, [fetchPositions, teamId, shouldFetch]);

  useEffect(() => {
    if (
      isProjectProvided &&
      !fetchedMemberBudgetProjectIds[projectId] &&
      shouldFetch
    ) {
      fetchMemberBudgets({ projectId });
    }
  }, [
    fetchedMemberBudgetProjectIds,
    projectId,
    isProjectProvided,
    fetchMemberBudgets,
    shouldFetch
  ]);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  /**
   * Array of positions order:
   * - Selected roles
   * - Roles already on the project (not added to the phase but not on phase)
   * - Exisiting Roles that would create duplicate unassigned roles.
   * - All the other roles not on the project or phase.
   */
  const dropdownItems = useMemo(
    () =>
      getUnassignedDropdownItems({
        existingUnassignedMemberBudgets: projectUnassignedMemberBudgets,
        addedRoles,
        scopeId,
        scopeMemberBudgetIds,
        phaseMembershipsMemberBudgetIds,
        positions,
        positionHash,
        projectUnassignedRoleCounts,
        highestPositionNumberByPositionId,
        addedRoleIdCount,
        excludeExisiting,
        customMakeBulkDropdownItems,
        canAddProjectMembership
      }),
    [
      projectUnassignedMemberBudgets,
      addedRoles,
      scopeId,
      scopeMemberBudgetIds,
      phaseMembershipsMemberBudgetIds,
      excludeExisiting,
      positions,
      customMakeBulkDropdownItems,
      canAddProjectMembership,
      positionHash,
      projectUnassignedRoleCounts,
      highestPositionNumberByPositionId,
      addedRoleIdCount
    ]
  );

  // ==================================================================

  const closeRatesSelection = () => {
    setIsRateSelectionOpen(false);
    setChangeRateRole({});
    rateSelectTargetRef.current = null;
  };

  const openRatesSelection = () => {
    setIsRateSelectionOpen(true);
  };

  const itemFilter = (item, searchWords) => {
    if (item?.selected) {
      return true;
    }
    return filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });
  };

  const handleAdd = (e) => {
    e.preventDefault();
    onAdd(addedRoles, unassignedRoleSelectedRates);
    if (closeOnSelect) onClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const onFooterClick = () => {
    if (isAdmin) {
      navigateToStandardRolesSettings({ teamSlug, openInNewWindow: true });
      ReactTooltip.hide();
      handleClose();
    }
  };

  const handleOpenRateSelection = (e, item) => {
    e.stopPropagation();
    rateSelectTargetRef.current = e.target;
    if (item.id !== changeRateRole?.id || item.name !== changeRateRole?.name) {
      setIsRateSelectionOpen(true);
      setChangeRateRole(item);
    } else {
      // Click to toggle
      setIsRateSelectionOpen(!isRateSelectionOpen);
      setChangeRateRole({});
    }
  };

  const handleSelectRate = (rate) => {
    // Need another ID because list is a list of positions, smae positions have same ID different name.
    const uniqueRoleId = serializeRoleId(changeRateRole);
    setUnassignedRoleSelectedRates({
      ...unassignedRoleSelectedRates,
      [uniqueRoleId]: rate
    });
    closeRatesSelection();
  };

  const onScroll = () => {
    if (isRateSelectionOpen) {
      closeRatesSelection();
    }
  };
  /* --------------------------------- render --------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;

    const uniqueRoleId = serializeRoleId(item);
    const changeRateRoleUniqueId = serializeRoleId(changeRateRole);
    return (
      <ItemRow
        key={uniqueRoleId}
        selected={changeRateRoleUniqueId === uniqueRoleId}
      >
        <ItemDetails>
          <UnassignedMemberIcon
            circleColor={
              item.selected
                ? theme.colors.colorLightGray7
                : theme.colors.colorPureWhite
            }
          />
          <NameContainer>
            <StyledItemName
              className={cn('item-name', { selected: item?.selected })}
              isSelected={item.selected}
              isOnPlanner={isOnPlanner}
              {...defaultTooltipProps}
              data-tip={item.name}
            >
              {item.name}
            </StyledItemName>
          </NameContainer>
          {!item.selected && (
            <ItemAddText>
              {isOnPlanner || showAddToProjectOnly
                ? 'Add To Project'
                : item.existing
                ? `Add To ${
                    scopeId
                      ? 'Scope'
                      : activityPhaseId
                      ? 'Work Category'
                      : 'Phase'
                  }`
                : `Add To ${
                    scopeId
                      ? 'Project and Scope'
                      : activityPhaseId
                      ? 'Project, Phase and Work Category'
                      : 'Project and Phase'
                  }`}
            </ItemAddText>
          )}
        </ItemDetails>
        {!isHoursOnly && !!currentRates.length && (
          <StyledKaratContainer
            onClick={(e) => handleOpenRateSelection(e, item)}
          >
            <KaratRight />
          </StyledKaratContainer>
        )}
        {item.selected && <RemoveText>Remove</RemoveText>}
      </ItemRow>
    );
  };

  const renderHeaderButton = () => {
    return (
      <ButtonContainer>
        {shouldShowCancelButton && (
          <TextButtonWithBorder
            styles={{ marginRight: '5px' }}
            color={theme.colors.colorMediumGray6AndAHalf}
            padding="4px 8px"
            onClick={handleClose}
          >
            Cancel
          </TextButtonWithBorder>
        )}
        <TextButton
          color="white"
          padding="4px 8px"
          backgroundColor={theme.colors.colorRoyalBlue}
          onClick={handleAdd}
          lineHeight={1.4}
          fontWeight={600}
          width={55}
        >
          {confirmButtonText || 'Add'}
        </TextButton>
      </ButtonContainer>
    );
  };

  return (
    <>
      <MultiStepFlyout
        copy={copy}
        target={targetRef}
        items={dropdownItems}
        idKey={(item) => serializeRoleId(item)}
        renderHeaderButton={renderHeaderButton}
        renderItem={customRenderItem || renderItem}
        handleSelect={handleSelect}
        isWhite
        itemFilter={itemFilter}
        handleClose={handleClose}
        stickyBelowSearch
        hideFooter={hideFooter}
        onFooterClick={onFooterClick}
        popoverClassName={cn(
          `bulk-add-positions-dropdown ${popoverClassName}`,
          {
            planner: isOnPlanner,
            budget: isOnBudget
          }
        )}
        listWidth={isOnPlanner ? 250 : isOnBudget ? 257 : 400}
        listHeight={222}
        itemHeight={48}
        searchEnabled
        canMultiSelect
        isLoading={isLoading}
        headerTitle={headerTitle}
        noHeader={noHeader}
        onScroll={onScroll}
        disableSearchMemo
        editDisabled
      />
      <Popover
        target={rateSelectTargetRef}
        isOpen={isRateSelectionOpen}
        placement="bottom-start"
        className="rate-dropdown-popover"
        closePopover={closeRatesSelection}
      >
        <RatesContainer>
          {currentRates.map((rate) => {
            const uniqueRoleId = serializeRoleId(changeRateRole);
            return (
              <RateLine
                onClick={() => handleSelectRate(rate)}
                key={rate.id}
                selected={
                  unassignedRoleSelectedRates[uniqueRoleId]?.id === rate?.id
                }
              >
                <span className={'rate'}>${rate.rate}</span>
                <span className={'rate-description'}>{rate.description}</span>
              </RateLine>
            );
          })}
        </RatesContainer>
      </Popover>
    </>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamSlug: getTeamSlug(state),
    isAdmin: getUserIsAdmin(state),
    teamId: getSelectedTeamId(state),
    positionHash: getPositions(state),
    isFetchingPositions: getIsFetchingPositions(state),
    positions: getCurrentPositions(state),
    scopeHash: getScopeHash(state),
    memberBudgetHash: getMemberBudgets(state),
    phaseHash: getFlatPhasesHash(state),
    fetchedMemberBudgetProjectIds: getFetchedMemberBudgetProjectIds(state),
    projectUnassignedMemberBudgets: getOwnProjectUnassignedMemberBudgets(
      state,
      {
        projectId: ownProps.projectId
      }
    ),
    projectUnassignedRoleCounts: getOwnProjectUnassignedCounts(state, {
      projectId: ownProps.projectId
    }),
    highestPositionNumberByPositionId: getOwnHighestPositionNumberByPositionId(
      state,
      { projectId: ownProps.projectId }
    ),
    currentRates: getOrderedCurrentTeamRates(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  navigateToStandardRolesSettings,
  fetchPositions,
  fetchMemberBudgets
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(BulkPositionDropdown);
