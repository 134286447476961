import { put, select, all } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import * as actionCreators from 'actionCreators';
import { fetchEntity, changeEntity } from './generics';
import { getAuthToken } from 'selectors';
import * as constants from 'appConstants';
const { user, fetchTeams } = entityActions;

export function* tryStartOnlineModeWorker(action) {
  const token = yield select(getAuthToken);

  const [{ error, response }, { error: teamsError, response: teamsResponse }] =
    yield all([
      fetchEntity(user, api.fetchMe, undefined, [token], action),
      changeEntity(fetchTeams, api.fetchAllTeams, [token], action)
    ]);

  const userFailure = error || !response;
  const teamsFailure = teamsError || !teamsResponse;
  if (userFailure || teamsFailure) {
    // user has already seen error message, indicate that there are still issues.
    yield put(
      actionCreators.handleErrorMessage({
        type: constants.GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: constants.APP_OFFLINE_MESSAGE
      })
    );
  }
  if (!userFailure && !teamsFailure) {
    yield put(actionCreators.startOnlineMode());
  }
}
