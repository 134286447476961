import moment from 'moment';

export const DEFAULT_START_DATE = moment()
  .startOf('month')
  .format('MM/DD/YYYY');
export const DEFAULT_END_DATE = moment().format('MM/DD/YYYY');

export const PAGE_TITLE = 'Timesheet Status';

export const LIMIT = 20;
