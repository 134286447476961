import * as constants from 'appConstants';

export const initialState = {
  downloadUrl: null,
  isGeneratingUrl: false
};

const downloadFile = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.DOWNLOAD_URL: {
      const { url } = action.payload;
      return {
        downloadUrl: url,
        isGeneratingUrl: url ? state.isGeneratingUrl : false
      };
    }
    case constants.GENERATE_REPORT.SUCCESS: {
      const { url } = action.payload.response;
      return {
        downloadUrl: url,
        isGeneratingUrl: url ? state.isGeneratingUrl : false
      };
    }
    case constants.GENERATE_REPORT.TRIGGER:
    case constants.EXPORT_TIMESHEETS.TRIGGER:
    case constants.EXPORT_TASKS.TRIGGER: {
      return {
        ...state,
        isGeneratingUrl: true
      };
    }
    default:
      return state;
  }
};

export default downloadFile;
