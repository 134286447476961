import styled from 'styled-components';
import theme from 'theme';
import QBDownArrow from 'icons/QBDownArrow';
export const StyledDownArrow = styled(QBDownArrow)`
  height: 8px;
  margin-left: 5px;
`;

export const StyledSelectedOption = styled.div`
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px 5px 5px 9px;
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorRoyalBlue : theme.colors.colorSemiDarkGray1};
  margin-right: 15px;
  cursor: pointer;
  padding: 5px 9px;
  ${StyledDownArrow} {
    visibility: hidden;
    path {
      fill: ${({ isActive }) =>
        isActive
          ? theme.colors.colorRoyalBlue
          : theme.colors.colorSemiDarkGray1};
    }
  }
  &:hover:not(.disabled) {
    ${StyledDownArrow} {
      visibility: visible;
    }
  }
  &:hover:not(.disabled),
  &.is-active {
    color: ${theme.colors.colorRoyalBlue};
    border-color: rgba(47, 128, 237, 0.5);
    ${StyledDownArrow} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledDropdownItem = styled.div`
  font-size: 12px;
  padding: 6px 16px;
  color: ${theme.colors.colorSemiDarkGray1};
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &:hover:not(.disabled) {
    background: ${theme.colors.colorTranslucentGray3};
  }
  &:first-child {
    margin-top: 6px;
  }
  &:last-child {
    margin-bottom: 6px;
  }
`;
