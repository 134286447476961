import { AccessLevelSummary } from 'PermissionsModule/components/AccessLevelSummary';
import { permissionsUtils } from 'PermissionsModule/utils';
import { teamUtils } from 'TeamsModule/utils';
import { MemberPermissionListItem } from '../types';
import { BaseCellRootContainer } from './styles';
import { manageMemberRole } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import useCan from 'appUtils/hooks/useCan';
import { MANAGE_MEMBER_ROLE_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';

export const MembersPermissionsTableAccessCell = ({ row }) => {
  const { teamMember, onRequestEditMemberAccess } =
    row.original as MemberPermissionListItem;

  const memberTeamRole = teamMember.role_ids[0];
  const isMemberArchived = teamUtils.getIsArchived(teamMember);

  const canEditMemberRole = useCan(manageMemberRole);

  // base members and external contracts are the only ones that can modify their access level
  const isDisplayAccessLevelInformation =
    !!memberTeamRole &&
    (permissionsUtils.getIsBaseMember(teamMember) ||
      permissionsUtils.getIsInternalContractor(teamMember));

  return (
    <BaseCellRootContainer
      onClick={
        isDisplayAccessLevelInformation && canEditMemberRole
          ? (event) =>
              onRequestEditMemberAccess({
                anchorEl: event.currentTarget,
                teamMembershipIds: [teamMember.id]
              })
          : noop
      }
      data-for="app-tooltip"
      data-effect="solid"
      data-class="center"
      data-tip={MANAGE_MEMBER_ROLE_TIP}
      data-tip-disable={canEditMemberRole}
    >
      {isDisplayAccessLevelInformation && (
        <AccessLevelSummary
          isMemberArchived={isMemberArchived}
          role={memberTeamRole}
        />
      )}
    </BaseCellRootContainer>
  );
};
