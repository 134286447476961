import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { PermissionsSettingsTabs } from 'appCore/navigation/permissions/PermissionsSettingsTabs';
import { ReactNode } from 'react';
import { LegacyPermissionsTab } from './Legacy';
import styled from 'styled-components';
import theme from 'theme';
import EmailIcon from 'icons/EmailIcon';
import { useAppDispatch } from 'reduxInfra/hooks';
import { toggleInviteForm } from 'actionCreators';
import useCan from 'appUtils/hooks/useCan';
import {
  inviteMemberToTeam,
  inviteGuests
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { INVITE_MEMBER_TO_TEAM_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';
interface PermissionsTabProps {
  children: ReactNode;
}

export const PermissionsTab = ({ children }: PermissionsTabProps) => {
  const dispatch = useAppDispatch();
  const { newPermissionSettings } = useFeatureFlags();

  const canInviteToTeam = useCan(inviteMemberToTeam);

  const canInviteGuests = useCan(inviteGuests);

  const canInvite = canInviteToTeam || canInviteGuests;

  if (!newPermissionSettings) {
    return <LegacyPermissionsTab />;
  }

  const handleInviteButtonClick = () => {
    dispatch(toggleInviteForm({ open: true }));
  };

  return (
    <div
      className="team-settings-modal"
      style={{
        flexDirection: 'column',
        display: 'flex',
        overflow: 'visible'
      }}
    >
      <div>
        <HeaderContainer className="team-settings-modal-header">
          <span>Permissions</span>
          <StyledMemberInviteButton
            onClick={canInvite ? handleInviteButtonClick : noop}
            data-html
            data-for="app-tooltip"
            data-effect="solid"
            data-class="center"
            data-tip={INVITE_MEMBER_TO_TEAM_TIP}
            data-tip-disable={canInvite}
          >
            <EmailIcon
              width="16px"
              height="12px"
              fillColor={theme.colors.colorPureWhite}
            />
            <StyledButtonText>Invite to Mosaic</StyledButtonText>
          </StyledMemberInviteButton>
        </HeaderContainer>
        <PermissionsSettingsTabs />
      </div>
      <div>{children}</div>
    </div>
  );
};

const HeaderContainer = styled.div`
  && {
    border-bottom: none;
  }
  padding-left: 7px;
  display: grid;
  gap: 12px;
`;

const StyledMemberInviteButton = styled.div`
  cursor: pointer;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  padding: 8px 12px;
  border-radius: 30px;
  margin-left: 14px;
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 0px;
  min-width: 141px;
  height: 32px;

  svg {
    margin-right: 7px;
  }
`;

const StyledButtonText = styled.div`
  padding-bottom: 2px;
`;
