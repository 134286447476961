import { SuggestionTableRequestStatusesIds } from './util';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';

const useSuggestionTableLoadingState = () => {
  const { status: fetchingEntitiesStatus } = useRequestStatus({
    requestStatusId: SuggestionTableRequestStatusesIds.FetchEntities
  });
  const {
    isLoading: fetchEntitiesIsLoading,
    isExecuting: fetchingEntitiesIsExecuting,
    isSuccess: fetchingEntitiesIsSuccess
  } = fetchingEntitiesStatus || {};

  // const { status: fetchMemberBudgetsAndSuggestionsStatus } = useRequestStatus({
  //   requestStatusId:
  //     SuggestionTableRequestStatusesIds.FetchMemberBudgetsAndSuggestions
  // });
  // const {
  //   isExecuting: fetchMemberBudgetsAndSuggestionsIsExecuting,
  //   isSuccess: fetchMemberBudgetsAndSuggestionsIsSuccess
  // } = fetchMemberBudgetsAndSuggestionsStatus || {};

  // const isFetchingMemberBudgetsAndSuggestions =
  //   fetchMemberBudgetsAndSuggestionsIsExecuting &&
  //   !fetchMemberBudgetsAndSuggestionsIsSuccess;

  const isSuggestionTableLoading = fetchEntitiesIsLoading;

  return {
    isSuggestionTableLoading
  };
};

export default useSuggestionTableLoadingState;
