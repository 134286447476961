import FetchManager from 'classes/FetchManager';
import { updateEntityDefaultCurrency } from 'CurrencyModule/actionCreators';
import { DEFAULT_CURRENCY } from 'CurrencyModule/constants/currencyConstants';
import { getDefaultCurrency } from 'CurrencyModule/selectors';
import { CurrencyCode } from 'CurrencyModule/types';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { TeamId } from 'TeamsModule/models/team';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { v4 as uuid } from 'uuid';

export const useTeamCurrency = (
  teamId?: TeamId
): {
  currencyCode: CurrencyCode;
  updateCurrencyCode: (
    updatedCurrencyCode: Parameters<
      typeof updateEntityDefaultCurrency
    >[0]['currency']
  ) => void;
} => {
  const dispatch = useAppDispatch();

  const selectedTeamId = useAppSelector(getSelectedTeamId);
  const defaultCurrenciesByType = useAppSelector(getDefaultCurrency);

  const teamIdToUse = teamId ?? selectedTeamId;
  const teamCurrencyCode =
    defaultCurrenciesByType.selectedTeam?.[teamIdToUse ?? -1];

  const abortId = useMemo(() => `useTeamCurrency>${uuid()}`, []);
  useEffect(() => {
    if (teamIdToUse && !teamCurrencyCode) {
      dispatch(
        fetchEntityOptions({
          entity_id: null,
          entity_type: ENTITY_TYPES.project,
          team_id: teamIdToUse,
          meta: { abortId }
        })
      );

      return () => {
        // Cleanup on unmount.
        FetchManager.abort(abortId);
      };
    }
  }, [abortId, dispatch, teamIdToUse, teamCurrencyCode]);

  const updateCurrencyCode = useCallback(
    (
      updatedCurrencyCode: Parameters<
        typeof updateEntityDefaultCurrency
      >[0]['currency']
    ) => {
      if (teamIdToUse !== undefined)
        dispatch(
          updateEntityDefaultCurrency({
            currency: updatedCurrencyCode,
            entity_id: null,
            // BE is not accepting 'Team' as an entity type. This will set the
            // team currency as long as `entity_id` is `null`. Passing in the
            // project entity type as a work around since `entity_type` is
            // required.
            entity_type: ENTITY_TYPES.project,
            team_id: teamIdToUse
          })
        );
    },
    [dispatch, teamIdToUse]
  );

  return {
    currencyCode: teamCurrencyCode ?? DEFAULT_CURRENCY,
    updateCurrencyCode
  };
};
