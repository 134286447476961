import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { makeGetActivityTotalHours } from 'selectors';
import { RowTotal } from './../styles';
import BudgetBar from 'BudgetModule/components/BudgetBar';
import { StyledBarSection } from 'BudgetModule/components/styles';

const HoursContainer = styled.div`
  text-align: center;
`;

const BudgetBarContainer = styled.div`
  ${StyledBarSection} {
    min-width: 0px;
  }
`;

const RowContainer = styled.div``;

export const TimesheetRowTotal = ({
  memberName,
  projectTitle,
  totalHours,
  totals,
  phaseId,
  projectId
}) => {
  const renderHours = totalHours.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });

  const dataTip = JSON.stringify({
    total: totals,
    totals: totals,
    formatType: 'hour',
    title: 'PHASE TOTAL'
  });

  return (
    <RowTotal
      data-for="budget-bar-tooltip"
      data-testid={`timesheet-row-total ${projectTitle} ${memberName}`}
      data-tip={JSON.stringify({
        total: totals,
        totals: totals,
        formatType: 'hour',
        title: 'PHASE TOTAL'
      })}
    >
      <HoursContainer>
        {renderHours !== 0 ? `${renderHours}h` : ''}
      </HoursContainer>
      <BudgetBarContainer>
        <BudgetBar
          totals={totals}
          isMember
          phaseId={phaseId}
          projectId={projectId}
          hideBudgetTotals
          hideTotal
          width={40}
          disableBarTooltip
        />
      </BudgetBarContainer>
    </RowTotal>
  );
};

const makeMapStateToProps = () => {
  const getActivityTotalHours = makeGetActivityTotalHours();
  const mapStateToProps = (state, ownProps) => ({
    totalHours: getActivityTotalHours(state, ownProps)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TimesheetRowTotal);
