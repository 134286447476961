import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import pickBy from 'lodash/pickBy';
import { useDispatch, useSelector } from 'react-redux';
import ActivityTable from './ActivityTable';
import {
  fetchActivityLogUserActivities,
  fetchAllProjects
} from 'actionCreators';
import {
  getUserActivities,
  getIsFetchingUserActivities,
  getUserActivitiesOffset,
  getUserActivitiesAllFetched,
  getAccountFiltersFetched,
  getMyUserId,
  getHasFetchedPhaseNames
} from 'selectors';
import moment from 'moment';
import {
  StyledDates as Dates,
  StyledCalendarIcon
} from 'ReportsModule/components/styles';
import { FlexRow } from 'components/styles';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import Filters from 'components/filters/Filters';

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin: 35px auto 0;
  margin-bottom: 0px;
  cursor: pointer;
`;

const StyledDates = styled(Dates)`
  margin-left: 15px;
`;

const OptionsRow = styled(FlexRow)`
  bottom: 20px;
`;

const formatDate = (date) => date?.clone?.().format?.('MMM DD, YYYY');
const getActiveFilterParams = (filter) =>
  pickBy(filter, (value) => Array.isArray(value) && value.length);

const ActivityTableContainer = ({
  customFilter,
  activeFilter,
  filterSections,
  customStartDate,
  customEndDate,
  hasOptions = true
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment().add(-1, 'week'));
  const [endDate, setEndDate] = useState(moment());
  const userActivities = useSelector(getUserActivities);
  const isFetchingUserActivities = useSelector(getIsFetchingUserActivities);
  const userActivitiesAllFetched = useSelector(getUserActivitiesAllFetched);
  const offset = useSelector(getUserActivitiesOffset);
  const hasFetchedPhaseNames = useSelector(getHasFetchedPhaseNames);
  const accountFiltersFetched = useSelector(getAccountFiltersFetched);
  const filtersFetched = hasFetchedPhaseNames && accountFiltersFetched;
  const myId = useSelector(getMyUserId);
  const filterParams = useMemo(() => {
    if (customFilter) {
      return customFilter;
    }
    const activeFilterParams = getActiveFilterParams(activeFilter);

    return activeFilterParams;
  }, [activeFilter, customFilter]);

  // todo - figure out why this is rerendering
  const filterEquality = useMemo(
    () => JSON.stringify(filterParams),
    [filterParams]
  );
  useEffect(() => {
    if (!filtersFetched || !myId) {
      return;
    }
    const fetchEndDate = endDate.clone().add(1, 'days').format('YYYY-MM-DD');
    const fetchStartDate = startDate.clone().format('YYYY-MM-DD');
    dispatch(
      fetchActivityLogUserActivities({
        actionable_type: 'TimeEntry',
        start_date: fetchStartDate,
        end_date: fetchEndDate,
        offset: 0,
        limit: 100,
        initial: true,
        ...filterParams
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, filterEquality, dispatch, filtersFetched, myId]);

  useEffect(() => {
    if (customStartDate && customEndDate) {
      setStartDate(moment(customStartDate));
      setEndDate(moment(customEndDate));
    }
  }, [customStartDate, customEndDate]);

  const loadMoreItems = useCallback(() => {
    if (isFetchingUserActivities || userActivitiesAllFetched) {
      return;
    }
    const fetchEndDate = endDate.clone().add(1, 'days').format('YYYY-MM-DD');
    dispatch(
      fetchActivityLogUserActivities({
        actionable_type: 'TimeEntry',
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: fetchEndDate,
        offset: offset,
        limit: 100,
        initial: false,
        filterParams
      })
    );
  }, [
    isFetchingUserActivities,
    userActivitiesAllFetched,
    endDate,
    dispatch,
    startDate,
    offset,
    filterParams
  ]);

  // this can have same project ids but not necessary to filter them out here,
  // because useFetchUnloadedProjects can take care of them by itself.
  const projectIds = useMemo(
    () =>
      userActivities.map(
        (userActivity) => userActivity?.display_data?.project_id
      ),
    [userActivities]
  );

  useFetchUnloadedProjects({
    projectIds,
    shouldFetchPhases: false
  });

  const onDateChange = ({ startDate, endDate }) => {
    if (startDate.isValid() && !endDate) {
      setStartDate(startDate);
      setEndDate(startDate);
    } else if (startDate.isValid() && endDate?.isValid()) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  return (
    <StyledContainerDiv>
      {hasOptions && (
        <OptionsRow>
          <DateRangeCalendar
            onSubmit={onDateChange}
            customInput={(start, end, handleOpen) => (
              <StyledDateContainer onClick={handleOpen}>
                <StyledDates>
                  <StyledCalendarIcon height="12px" width="12px" />
                  {formatDate(startDate)} - {formatDate(endDate)}
                </StyledDates>
              </StyledDateContainer>
            )}
            itemStartDate={startDate}
            itemEndDate={endDate}
          />
          <Filters sections={filterSections} activeFilter={activeFilter} />
        </OptionsRow>
      )}
      <ActivityTable
        userActivities={userActivities}
        isFetchingUserActivities={isFetchingUserActivities}
        loadMoreItems={loadMoreItems}
      />
    </StyledContainerDiv>
  );
};

export default ActivityTableContainer;
