import * as constants from 'appConstants';
import pick from 'lodash/pick';
import keyBy from 'lodash/keyBy';

const byId = (item) => item.id;

export const initialState = {
  loading: false,
  loaded: false,
  errors: [],
  projects: [],
  phases: [],
  teamMembers: [],
  activities: [],
  projectPriorities: [],
  projectClients: [],
  projectBudgetStatusOptions: [],
  phaseBudgetStatusOptions: [],
  regions: [],
  offices: [],
  disciplines: [],
  ptoPolicies: [],
  positions: [],
  teamRates: [],
  departments: [],
  taskPriorities: []
};

const stripProjects = (projects) =>
  projects.map((project) =>
    pick(project, [
      'title',
      'id',
      'project_number',
      'board_id',
      'task_statuses'
    ])
  );
const stripPhases = (phases) =>
  phases.map((phase) =>
    pick(phase, [
      'name',
      'project_id',
      'id',
      'activity_order',
      'is_main',
      'is_default',
      'phase_number'
    ])
  );
const stripActivities = (activities) =>
  activities.map((activity) => pick(activity, ['id', 'title']));

const stripTeamMembers = (teamMembers) =>
  teamMembers.map(({ account }) =>
    pick(account, ['first_name', 'last_name', 'account_id', 'email'])
  );
const stripProjectClients = (projectClients) =>
  projectClients.filter(
    (projectClient) => projectClient && projectClient.trim() !== ''
  );

const stripProjectPriorities = (projectPriorities) =>
  projectPriorities.map((projectPriority) => pick(projectPriority, ['title']));
const stripIdAndName = (responseDatas) =>
  responseDatas.map((data) => pick(data, ['id', 'name']));

const stripPositions = (positions) =>
  positions
    .filter((position) => !position.discarded_at && !position.is_default)
    .map((position) => pick(position, ['id', 'name']));

const stripPtoPolicies = (ptoPolicies) =>
  ptoPolicies
    .filter((ptoPolicy) => !ptoPolicy.discarded_at)
    .map((ptoPolicy) => pick(ptoPolicy, ['id', 'hours', 'name']));

const stripTeamRates = (rates) =>
  rates
    .filter((rate) => !rate.archived && !rate.is_default)
    .map((rate) => pick(rate, ['id', 'rate', 'description']));

const stripUnarchivedIdAndName = (responseDatas) =>
  responseDatas
    .filter((data) => !data.archived)
    .map((data) => pick(data, ['id', 'name']));

const stripTaskPriorities = (taskPriorities) =>
  taskPriorities.map((taskPriority) => pick(taskPriority, ['title']));

const csvImport = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOAD_CSV_IMPORT_DATA.TRIGGER: {
      return {
        ...state,
        loading: true
      };
    }
    case constants.LOAD_CSV_IMPORT_DATA.SUCCESS: {
      const {
        projects,
        phases,
        activities,
        teamMembers,
        projectPriorities,
        projectClients,
        projectBudgetStatusOptions,
        phaseBudgetStatusOptions,
        regions,
        offices,
        disciplines,
        ptoPolicies,
        positions,
        teamRates,
        departments,
        taskPriorities
      } = payload.response;
      return {
        ...state,
        loading: false,
        loaded: true,
        projects: stripProjects(projects),
        phases: stripPhases(phases),
        activities: stripActivities(activities),
        teamMembers: stripTeamMembers(teamMembers),
        projectPriorities: stripProjectPriorities(projectPriorities),
        projectClients: stripProjectClients(projectClients),
        projectBudgetStatusOptions: projectBudgetStatusOptions,
        phaseBudgetStatusOptions: phaseBudgetStatusOptions,
        regions: stripIdAndName(regions),
        offices: stripIdAndName(offices),
        disciplines: stripIdAndName(disciplines),
        ptoPolicies: stripPtoPolicies(ptoPolicies),
        positions: stripPositions(positions),
        teamRates: stripTeamRates(teamRates),
        departments: stripUnarchivedIdAndName(departments),
        taskPriorities: stripTaskPriorities(taskPriorities)
      };
    }
    case constants.LOAD_CSV_IMPORT_DATA.FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: payload.error
      };
    }
    case constants.CLEAR_CSV_IMPORT_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};

export default csvImport;
