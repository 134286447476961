import { DateISOString } from 'models/date';
import { RateId } from './Rate';
import { RateGroupId } from './RateGroup';

export type EntityRateId = number;
export type EntityId = number;

export enum EntityRateType {
  Role = 'Position',
  WorkCategory = 'Activity'
}

export interface EntityRate {
  archived_at: Nullable<DateISOString>;
  created_at: DateISOString;
  discarded_at: Nullable<DateISOString>;
  end_date: Nullable<DateISOString>;
  entity_id: EntityId;
  entity_type: EntityRateType;
  id: EntityRateId;
  is_cost_rate: boolean;
  rate_group_id: RateGroupId;
  rate_id: RateId;
  /**
   * This type differs from the model provided by the backend because the
   * response returns `'1900-01-01'` to indicate that a rate was the initial
   * rate, while here it is set to `null`. Reducers are responsible for making
   * the change.
   */
  start_date: Nullable<DateISOString>;
  updated_at: DateISOString;
}
