import {
  BudgetViewSettings,
  ContractType
} from 'BudgetModule/models/BudgetViewSettings';
import {
  ContractTypeDropdown,
  CONTRACT_TYPE_COPY
} from './ContractTypeDropdown';
import { FullWidthSelectInput } from '../styles';
import { SelectDropdownProps } from 'RatesModule/components/SelectInput/SelectInput';
import { UpdateProjectBudgetViewSettings } from '../types';

export enum ContractTypeVariant {
  ActivityPhase = 'activity_phase_default_budget_contract_type',
  Phase = 'phase_default_budget_contract_type'
}

export const ContractTypeField = ({
  budgetViewSettings,
  updateBudgetViewSettings,
  variant
}: {
  variant: ContractTypeVariant;
  budgetViewSettings: BudgetViewSettings;
  updateBudgetViewSettings: UpdateProjectBudgetViewSettings;
}) => {
  const contractType = budgetViewSettings[variant];

  const handleChange = (value: ContractType) =>
    updateBudgetViewSettings(
      variant === ContractTypeVariant.ActivityPhase
        ? { activity_phase_default_budget_contract_type: value }
        : { phase_default_budget_contract_type: value }
    );

  const dropdownRenderer = (props: SelectDropdownProps<ContractType>) => (
    <ContractTypeDropdown value={contractType} {...props} />
  );

  return (
    <FullWidthSelectInput
      onSelect={handleChange}
      renderDropdown={dropdownRenderer}
    >
      {CONTRACT_TYPE_COPY[contractType].label}
    </FullWidthSelectInput>
  );
};
