import { useCallback } from 'react';
import { useFilterContext } from 'FilterModule/FilterContextProvider';

const emptyArray = [];

/**
 * Implemented this way to mimic useArrayFilterField (easier to render boolean fields the same way as array fields)
 */
export const useBooleanFilterField = ({
  field,
  shouldUseDraft,
  items
}: {
  items: string[]; // TODO: follow items/itemHash pattern like useArrayFilterField when needed
  field: string;
  shouldUseDraft?: boolean;
}) => {
  const { currentFilter, draftFilter } = useFilterContext();

  const isFiltering = Boolean(
    shouldUseDraft
      ? draftFilter[field] ?? currentFilter[field]
      : currentFilter[field]
  );

  const getIsSelected = useCallback(() => isFiltering, [isFiltering]);

  const toggleSelectedItem = useCallback(() => {
    (shouldUseDraft ? draftFilter : currentFilter).update({
      [field]: !isFiltering
    });
  }, [currentFilter, field, isFiltering, draftFilter, shouldUseDraft]);

  const selectedItems = isFiltering ? items : emptyArray;

  return {
    toggleSelectedItem,
    getIsSelected,
    selectedItems
  };
};
