import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { openFlaggedTasksModal } from 'actionCreators';
import { getPastDueTasks, getPastScheduledTasks } from 'selectors';
import { DayTasksCircle } from './DayTasksCircle';

const HomeTaskListTitle = styled.div`
  height: 48px;
  font-size: 19px;
  line-height: 26px;
  color: #4a4a4a;
  padding-left: 30px;
  flex: 0 0 48px;
  display: flex;
  width: 850px;
`;

const TaskViewTitles = {
  'My Tasks': 'My Tasks',
  'Assigned To Me': 'Assigned To Me',
  Today: 'My Tasks',
  'Assigned By Me': 'Assigned By Me',
  Completed: 'My Tasks'
};
const NO_TITLE = true;
const getTaskViewTitle = ({ section = 'My Tasks', state }) => {
  return TaskViewTitles[section];
};

const TaskListTitle = ({ taskFilter, pastDueTasks, pastScheduledTasks }) => {
  return NO_TITLE ? null : (
    <HomeTaskListTitle taskFilter={taskFilter}>
      <div style={{ marginRight: '10px' }}>{getTaskViewTitle(taskFilter)}</div>
    </HomeTaskListTitle>
  );
};

const mapStateToProps = (state) => ({
  pastDueTasks: getPastDueTasks(state),
  pastScheduledTasks: getPastScheduledTasks(state)
});
const mapDispatchToProps = {
  openFlaggedTasksModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TaskListTitle));
