import React from 'react';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import {
  DeleteCommentModal,
  OutsideClickWrapper,
  CommentInputContainer
} from '../..';
import { withRouter } from 'react-router-dom';
import { commentTypes } from 'appConstants/commentConstants';
import { formatMentionedNames } from 'appUtils/mentions';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import getCaretCoordinates from 'textarea-caret';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {
  makeGetMemberIsGuestByAccountId,
  makeGetCommentAuthorIsPM,
  getMatchedRouteParams,
  getTeamMembersHash,
  getAttachmentHash
} from 'selectors';
import Linkify from 'linkifyjs/react';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { removeAttachmentSubscriptions } from 'actionCreators';
import { FILE_STATUS } from 'appConstants';
import {
  DELETE_TASK_COMMENT_TIP,
  EDIT_TASK_COMMENT_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { withProjectPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/hocs/withProjectPermissionProvider';

const moment = extendMoment(Moment);

class TaskCommentRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteCommentModalOpen: false,
      deleteModalCommentId: null,
      commentBody: this.props.commentThread.body,
      isEditingComment: false,
      suggestionListLeft: 0,
      files: []
    };
    bindAll(this, [
      'deleteCommentOrReply',
      'toggleDeleteCommentModal',
      'handleCommentChange',
      'toggleCommentEditState',
      'handleEditCancel',
      'handleSave',
      'handleKeyPress',
      'removeFile'
    ]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.commentThread !== this.props.commentThread) {
      this.setState({
        commentBody: this.props.commentThread.body
      });
    }
  }

  componentWillUnmount() {
    const { removeAttachmentSubscriptions, commentThread } = this.props;
    const attachments = commentThread?.attachments || [];
    if (attachments.length) {
      const attachmentIds = attachments.map((attachment) => attachment.id);
      removeAttachmentSubscriptions({ ids: attachmentIds });
    }
  }

  getPermissions = () => {
    const {
      commentThread,
      me,
      selectedTeamId,
      matchedParams: { projectId }
    } = this.props;
    const mine = me.id === commentThread.account.id;
    const permissions = { projectId, teamId: selectedTeamId, mine };
    return permissions;
  };

  toggleDeleteCommentModal(commentId = null) {
    this.setState({ deleteModalCommentId: commentId });
    this.setState({
      deleteCommentModalOpen: !this.state.deleteCommentModalOpen
    });
  }

  deleteCommentOrReply(commentId) {
    const { deleteComment } = this.props;

    const permissions = this.getPermissions();
    deleteComment({
      taskCommentId: commentId,
      permissions
    });
    if (this.state.deleteCommentModalOpen) this.toggleDeleteCommentModal();
  }

  handleCommentChange(event) {
    this.setState({ commentBody: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key == 'Enter' && this.state.commentBody.length) {
      event.preventDefault();
      this.handleSave();
    }

    if (event.key == '@') {
      const commentInput = document.querySelector(
        '.editable-comment .input textarea'
      );
      const suggestionListLeft = getCaretCoordinates(
        commentInput,
        commentInput.selectionEnd
      ).left;
      this.setState({ suggestionListLeft });
    }
  }

  handleEditCancel() {
    this.setState({
      commentBody: this.props.commentThread.body,
      isEditingComment: false,
      files: []
    });
  }

  toggleCommentEditState() {
    this.setState((prevState) => ({
      isEditingComment: !prevState.isEditingComment
    }));
  }

  handleFiles = (files) => {
    this.setState({ files: Array.from(files) });
  };

  removeFile(file) {
    this.setState((prevState) => ({
      files: prevState.files.filter((stateFile) => stateFile !== file)
    }));
  }

  handleSave() {
    const { commentThread, updateComment } = this.props;
    const { files, commentBody } = this.state;
    this.toggleCommentEditState();
    if (
      (commentBody !== commentThread.body || files.length) &&
      (commentBody || files.length || commentThread.attachments.length)
    ) {
      const permissions = this.getPermissions();
      updateComment({
        taskCommentId: commentThread.id,
        body: commentBody,
        files,
        permissions
      });
      this.setState({ files: [] });
    } else {
      this.handleEditCancel();
    }
  }

  renderEditableComment() {
    const { commentThread, isReply, task } = this.props;
    const { commentBody, suggestionListLeft, files } = this.state;
    return (
      <OutsideClickWrapper handleOutsideClick={this.handleSave}>
        <CommentInputContainer
          isExpanded
          isEditingComment
          className={cn('editable-comment', { isReply })}
          attachments={commentThread.attachments}
          commentType={commentTypes.EDIT}
          commentBody={commentBody}
          handleFiles={this.handleFiles}
          removeFile={this.removeFile}
          suggestionListLeft={suggestionListLeft}
          onCancel={this.handleEditCancel}
          onChange={this.handleCommentChange}
          onSubmit={this.handleSave}
          onKeyPress={this.handleKeyPress}
          unsavedFiles={files}
          task={task}
          projectId={task?.project_id}
        />
      </OutsideClickWrapper>
    );
  }

  renderCommentRow() {
    const {
      me,
      isReply,
      commentThread,
      replies,
      openReplyInput,
      replyInputOpen,
      memberIsGuest,
      memberIsPM,
      task,
      teamMembers,
      attachmentHash,
      projectPermissions: { canEditTaskComments, canDeleteTaskComments }
    } = this.props;
    const createdTime = commentThread.created_at;
    const member = teamMembers[commentThread?.account?.id];
    let formattedTime;

    if (moment().startOf('day').diff(moment(createdTime), 'days') > 2) {
      formattedTime = moment(createdTime).format('M/D/YY h:mm A');
    } else if (moment(createdTime).isSame(moment(), 'day')) {
      formattedTime = 'Today ' + moment(createdTime).format('h:mm A');
    } else {
      formattedTime = 'Yesterday ' + moment(createdTime).format('h:mm A');
    }

    return (
      <>
        <div
          className={cn('comment-row', {
            isReply,
            'rounded-bottom':
              !replyInputOpen &&
              commentThread.replies &&
              !commentThread.replies.length,
            'shorten-grid-row':
              isReply || (replies && replies.length) || replyInputOpen
          })}
        >
          <div />
          <div className="comment-metadata">
            <div className="comment-left">
              <div className="comment-author">{commentThread.account.name}</div>
              <div className="comment-timestamp">{formattedTime}</div>
            </div>
            {/* always show editable button until UI for permission is ironed out.
            Api call is blocked if permission is not sufficient to edit other users comments */}
            <>
              <div className="comment-right">
                <ReactTooltip
                  className="notification-tooltip"
                  effect="solid"
                  id="comment-icon"
                  place="top"
                />
                <div
                  className={cn(
                    { disabled: !canEditTaskComments },
                    'edit-asset'
                  )}
                  onClick={
                    canEditTaskComments
                      ? this.toggleCommentEditState
                      : undefined
                  }
                  data-for="comment-icon"
                  data-tip={
                    canEditTaskComments ? 'Edit Comment' : EDIT_TASK_COMMENT_TIP
                  }
                />
                <div className="comment-icon-separator" />
                <div
                  className={cn(
                    { disabled: !canDeleteTaskComments },
                    'delete-asset'
                  )}
                  onClick={
                    canDeleteTaskComments
                      ? () => this.toggleDeleteCommentModal(commentThread.id)
                      : undefined
                  }
                  data-for="comment-icon"
                  data-tip={
                    canDeleteTaskComments
                      ? 'Delete Comment'
                      : DELETE_TASK_COMMENT_TIP
                  }
                />
              </div>
            </>
          </div>
          <div className="initials-col">
            <MemberInitials
              projectId={task?.project?.id}
              projectItem={true}
              memberIsGuest={memberIsGuest}
              member={member}
              isManager={memberIsPM}
              originType="teamMembership"
              classes={cn('regular-member-no-hover selected', {
                'logged-member-no-hover': commentThread.account.id === me.id
              })}
            />
          </div>
          <div className="comment-description">
            <Linkify options={{ nl2br: true }}>
              {formatMentionedNames(this.state.commentBody)}
            </Linkify>
          </div>
          {commentThread.attachments &&
            commentThread.attachments.map((attachment) => {
              const attachmentInfo =
                attachmentHash[attachment.id] || attachment;
              if (attachmentInfo.deleted) {
                return null;
              }
              return (
                <div
                  key={attachmentInfo.file_url}
                  className="file-attachment-container"
                  onClick={() => {
                    if (attachmentInfo.file_url) {
                      window.open(attachmentInfo.file_url, '_blank');
                    }
                  }}
                >
                  <i className="file-type" />
                  <span
                    className="file-name"
                    data-for="app-tooltip"
                    data-effect="solid"
                    data-tip={
                      attachmentInfo?.status === FILE_STATUS.PENDING
                        ? 'Scanning for Malware'
                        : ''
                    }
                  >
                    {attachmentInfo.file_name}
                  </span>
                </div>
              );
            })}
          <div
            className={cn('reply-button', {
              hidden: isReply || (replies && replies.length) || replyInputOpen
            })}
            onClick={openReplyInput}
          >
            <div>
              <i className="reply-arrow" />
            </div>
            <div>Reply</div>
          </div>
        </div>

        <DeleteCommentModal
          isOpen={this.state.deleteCommentModalOpen}
          toggle={this.toggleDeleteCommentModal}
          commentId={this.state.deleteModalCommentId}
          deleteCommentOrReply={this.deleteCommentOrReply}
        />
      </>
    );
  }

  renderReplies() {
    const {
      me,
      task,
      replies,
      deleteComment,
      updateComment,
      selectedProject,
      projectPermissions
    } = this.props;
    return replies.map((reply) => (
      <ConnectedRow
        key={`reply ${reply.id}`}
        me={me}
        task={task}
        commentThread={reply}
        replies={[]}
        deleteComment={deleteComment}
        updateComment={updateComment}
        isReply
        selectedProject={selectedProject}
        projectPermissions={projectPermissions}
        permissionProps={{
          accountId: reply?.account?.id
        }}
      />
    ));
  }

  render() {
    const { replies } = this.props;
    return (
      <div>
        {this.state.isEditingComment
          ? this.renderEditableComment()
          : this.renderCommentRow()}
        {replies && replies.length ? this.renderReplies() : null}
      </div>
    );
  }
}

const projectIdGetter = (state, ownProps) => ownProps.task?.project_id;

const mapDispatchToProps = {
  removeAttachmentSubscriptions
};

const makeMapStateToProps = () => {
  const getMemberIsGuest = makeGetMemberIsGuestByAccountId();
  const getCommentAuthorIsPM = makeGetCommentAuthorIsPM({ projectIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    memberIsGuest: getMemberIsGuest(state, ownProps.commentThread),
    memberIsPM: getCommentAuthorIsPM(state, ownProps),
    matchedParams: getMatchedRouteParams(state),
    teamMembers: getTeamMembersHash(state),
    attachmentHash: getAttachmentHash(state)
  });
  return mapStateToProps;
};

const ConnectedRow = withRouter(
  withProjectPermissionProvider(
    connect(makeMapStateToProps, mapDispatchToProps)(TaskCommentRow)
  )
);

export default ConnectedRow;
