import { useState, useEffect, useCallback } from 'react';
import { useMount } from 'react-use';
import OrganizationPermissions from './OrganizationPermissions';
import TeamPermissions from './TeamPermissions';
import ProjectPermissions from './ProjectPermissions';
import TimesheetPermissions from './TimesheetPermissions';
import OrganizationGraphic from 'icons/OrganizationGraphic';
import {
  togglePermissionOpen,
  toggleAllPermissionsOpen
} from 'PermissionsModule/actionCreators';
import {
  fetchTeamRoleTemplates,
  updateTeamRoleTemplate
} from 'RoleTemplatesModule/actionCreators';

import { getSelectedTeamId } from 'TeamsModule/selectors';
import {
  StyledGraphicDisplay,
  PermissionsHeader,
  CollapeExpandContainer
} from './styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  getTeamRoleTemplatesByName,
  getAllPermissionActionsExpanded
} from 'RoleTemplatesModule/selectors';
import { PermissionsActions } from 'PermissionsModule/types';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

export const LegacyPermissionsTab = () => {
  const [adminTabOpen, setAdminTabOpen] = useState(false);

  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const allExpanded = useAppSelector(getAllPermissionActionsExpanded);
  const teamRoleTemplatesByName = useAppSelector(getTeamRoleTemplatesByName);

  useMount(() => {
    rebuildTooltip();
  });

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamRoleTemplates({ teamId }));
    }
  }, [dispatch, teamId]);

  const toggleAdminTab = () => setAdminTabOpen(!adminTabOpen);

  const makeTogglePermission = useCallback(
    (actionType: PermissionsActions) =>
      (e, { name, checked }) => {
        const roleTemplate = teamRoleTemplatesByName[name];

        if (roleTemplate) {
          dispatch(
            updateTeamRoleTemplate({
              id: roleTemplate.id,
              checked,
              actionType
            })
          );
        }
      },
    [dispatch, teamRoleTemplatesByName]
  );

  const toggleOpen = (actionType: PermissionsActions) => {
    dispatch(togglePermissionOpen({ actionType }));
  };

  const toggleAllOpen = () => {
    dispatch(toggleAllPermissionsOpen({ open: !allExpanded }));
    setAdminTabOpen(!allExpanded);
  };

  return (
    <div style={{ overflow: 'scroll', display: 'flex', width: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <div style={{ flexGrow: 1, paddingBottom: '36px' }}>
          <PermissionsHeader>
            <div className="archived-boards-header">Permission Settings</div>
            <CollapeExpandContainer onClick={toggleAllOpen}>
              {allExpanded ? 'Collapse' : 'Expand'} all
            </CollapeExpandContainer>
          </PermissionsHeader>

          <OrganizationPermissions
            makeTogglePermission={makeTogglePermission}
            toggleOpen={toggleOpen}
            toggleAdminTabOpen={toggleAdminTab}
            adminTabOpen={adminTabOpen}
          />
          <TeamPermissions
            makeTogglePermission={makeTogglePermission}
            toggleOpen={toggleOpen}
          />
          <ProjectPermissions
            makeTogglePermission={makeTogglePermission}
            toggleOpen={toggleOpen}
          />
          <TimesheetPermissions
            makeTogglePermission={makeTogglePermission}
            toggleOpen={toggleOpen}
          />
        </div>
      </div>
      <StyledGraphicDisplay>
        <OrganizationGraphic />
      </StyledGraphicDisplay>
    </div>
  );
};
