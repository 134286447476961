import styled from 'styled-components';
import cn from 'classnames';

export const UnderlinedTextInput = styled.input.attrs<{ $showError?: boolean }>(
  ({ $showError }) => ({ className: cn({ error: $showError }), type: 'text' })
)<{ $showError?: boolean }>`
  background-color: ${({ theme }) => theme.colors.colorLightGray19};
  border-color: ${({ theme }) => theme.colors.colorPaleGray6};
  border-width: 0 0 1px;
  font-size: ${({ theme }) => theme.sizes.inputFontSize};
  height: ${({ theme }) => theme.sizes.inputHeight};
  padding: 0 10px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.65;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.colorBudgetBlue};
    }

    &.error {
      border-color: ${({ theme }) => theme.colors.colorCalendarRed};
    }
  }
`;
