import React from 'react';
import LoadBoardBudgetContainer from 'BudgetModule/components/LoadBoardBudgetContainer';

const BudgetContainer = ({
  activeFilter,
  viewBy,
  pageName,
  isInModal,
  filterSections,
  varianceType,
  isProjectViewReport,
  isDashboardWidgetModal,
  widgetConfig,
  widgetDetailModalThreeDotMenuItems,
  viewType
}) => {
  return (
    <LoadBoardBudgetContainer
      activeFilter={activeFilter}
      viewBy={viewBy}
      pageName={pageName}
      isInModal={isInModal}
      varianceType={varianceType}
      filterSections={filterSections}
      isProjectViewReport={isProjectViewReport}
      isDashboardWidgetModal={isDashboardWidgetModal}
      widgetConfig={widgetConfig}
      widgetDetailModalThreeDotMenuItems={widgetDetailModalThreeDotMenuItems}
      viewType={viewType}
    />
  );
};

export default BudgetContainer;
