import Popover from 'components/Popover';
import { KeyboardEvent, useEffect, useState } from 'react';
import NumberFormat, {
  NumberFormatValues,
  SourceInfo
} from 'react-number-format';
import { useUpdate } from 'react-use';
import styled from 'styled-components';
import {
  MenuItem,
  MenuList
} from 'SettingsModule/components/BudgetSettings/Menu';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import { InlineHelpIcon } from '../styles';
import { SelectDropdownProps } from 'RatesModule/components/SelectInput/SelectInput';

export enum BudgetType {
  PercentOfFee = 'percentOfFee',
  SumOfMemberTime = 'sumOfMemberTime',
  TotalHours = 'totalHours'
}

export const BUDGET_TYPE_COPY: Record<
  BudgetType,
  {
    inputLabel: (percentOfFee: number) => string;
    label: string;
    tooltip: string;
  }
> = {
  [BudgetType.PercentOfFee]: {
    inputLabel: (percentOfFee: number) => `Budget ${percentOfFee}% of Fee`,
    label: 'Budget % of Fee',
    tooltip: 'Enter the % of Fee used to budget projects.'
  },
  [BudgetType.TotalHours]: {
    inputLabel: () => 'Use Total Hours as Budget',
    label: 'Use Total Hours as Budget',
    tooltip:
      'Budget Projects by the amount manually entered for the Project and Phase.'
  },
  [BudgetType.SumOfMemberTime]: {
    inputLabel: () => 'Sum of Member Time Budget',
    label: 'Sum of Member Time Budget',
    tooltip: 'Budget Projects by the total of the Member’s Budgeted Hours.'
  }
} as const;

export type BudgetTypeDropdownValue =
  | { type: Exclude<BudgetType, BudgetType.PercentOfFee> }
  | { type: BudgetType.PercentOfFee; percentOfFee: number };

export const BudgetTypeDropdown = ({
  isHours,
  isOpen,
  onClose,
  onSelect,
  target,
  value
}: SelectDropdownProps<BudgetTypeDropdownValue> & {
  isHours: boolean;
  value?: BudgetTypeDropdownValue;
}) => {
  const [customPercent, setCustomPercent] = useState<number>();
  const update = useUpdate();

  const isEditingCustom = customPercent !== undefined;

  const handleClose = () => {
    ReactTooltip.hide();
    onClose();
  };

  const handleItemClick =
    (type: Exclude<BudgetType, BudgetType.PercentOfFee>) => () => {
      onSelect({ type });
      handleClose();
    };

  const handleClickCustomPercent = () => {
    setCustomPercent(0);
  };

  const handleCustomPercentInputChange = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (source === 'event') {
      setCustomPercent(Math.min(floatValue ?? 0, 100));

      // This is required because the state value may not change, event though the
      // input value did. A user may enter a value greater than 100 when the state
      // value was already 100, but the value is capped to 100.
      update();
    }
  };

  const handleCustomPercentInputBlur = () => {
    setCustomPercent(undefined);
  };

  const handleCustomPercentInputKeyDown = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    if (customPercent !== undefined && e.key === 'Enter') {
      onSelect({
        type: BudgetType.PercentOfFee,
        percentOfFee: customPercent
      });
    }
  };

  const handleClickPercent = (percent: number) => () => {
    onSelect({ type: BudgetType.PercentOfFee, percentOfFee: percent });
  };

  useEffect(() => () => {
    ReactTooltip.hide();
  });
  useEffect(() => {
    if (isOpen) {
      rebuildTooltip();
    } else {
      ReactTooltip.hide();
      setCustomPercent(undefined);
    }
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      stopPropagation
      target={target}
      togglePopover={handleClose}
    >
      <MenuList>
        {isHours ? (
          <MenuItem
            {...defaultTooltipProps}
            data-class="center mw-250 w-100vw"
            data-tip={BUDGET_TYPE_COPY[BudgetType.TotalHours].tooltip}
            key={BudgetType.TotalHours}
            onClick={handleItemClick(BudgetType.TotalHours)}
            selected={BudgetType.TotalHours === value?.type}
          >
            {BUDGET_TYPE_COPY[BudgetType.TotalHours].label}
            <InlineHelpIcon />
          </MenuItem>
        ) : (
          <MenuItem
            isActive={isEditingCustom}
            key={BudgetType.PercentOfFee}
            submenu={
              <MenuList heading="% of Fee" maxListHeight={205}>
                <MenuItem
                  isActive={isEditingCustom}
                  selected={
                    !isEditingCustom &&
                    value?.type === BudgetType.PercentOfFee &&
                    (value.percentOfFee % 10 !== 0 ||
                      value.percentOfFee < 0 ||
                      value.percentOfFee > 100)
                  }
                >
                  {isEditingCustom && (
                    <CustomPercentInput
                      allowNegative={false}
                      autoFocus
                      decimalScale={0}
                      onBlur={handleCustomPercentInputBlur}
                      onKeyDown={handleCustomPercentInputKeyDown}
                      onValueChange={handleCustomPercentInputChange}
                      placeholder="0%"
                      suffix="%"
                      value={customPercent || undefined}
                    />
                  )}
                  <CustomPercent onClick={handleClickCustomPercent}>
                    Custom
                  </CustomPercent>
                </MenuItem>
                <MenuItem
                  onClick={handleClickPercent(0)}
                  selected={
                    value?.type === BudgetType.PercentOfFee &&
                    value.percentOfFee === 0
                  }
                >
                  <Percent>None</Percent>
                </MenuItem>
                {Array.from(Array(10).keys()).map((i) => {
                  const percent = (i + 1) * 10;

                  return (
                    <MenuItem
                      key={i}
                      onClick={handleClickPercent(percent)}
                      selected={
                        value?.type === BudgetType.PercentOfFee &&
                        value.percentOfFee === percent
                      }
                    >
                      <Percent>{percent}%</Percent>
                    </MenuItem>
                  );
                })}
              </MenuList>
            }
            selected={BudgetType.PercentOfFee === value?.type}
          >
            <div
              style={{ flex: 1 }}
              {...defaultTooltipProps}
              data-class="center mw-250 w-100vw"
              data-tip={BUDGET_TYPE_COPY[BudgetType.PercentOfFee].tooltip}
            >
              {BUDGET_TYPE_COPY[BudgetType.PercentOfFee].label}
              <InlineHelpIcon />
            </div>
          </MenuItem>
        )}

        <MenuItem
          {...defaultTooltipProps}
          data-class="center mw-250 w-100vw"
          data-tip={BUDGET_TYPE_COPY[BudgetType.SumOfMemberTime].tooltip}
          key={BudgetType.SumOfMemberTime}
          onClick={handleItemClick(BudgetType.SumOfMemberTime)}
          selected={BudgetType.SumOfMemberTime === value?.type}
        >
          {BUDGET_TYPE_COPY[BudgetType.SumOfMemberTime].label}
          <InlineHelpIcon />
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

const Percent = styled.div`
  text-align: center;
`;

const CustomPercent = styled(Percent)`
  color: ${({ theme }) => theme.colors.colorBudgetBlue};
`;

const CustomPercentInput = styled(NumberFormat)`
  background-color: ${({ theme }) => theme.colors.colorLightGray19};
  border: 1px solid ${({ theme }) => theme.colors.colorPaleGray6};
  font-size: inherit;
  text-align: right;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.colorBudgetBlue};
  }

  // Keep the width of the label, but otherwise hide it, to prevent horizontal
  // layout shifts when the input is shown.
  & + ${CustomPercent} {
    height: 0;
    visibility: hidden;
  }
`;
