import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { LabelText } from './styles';

const InputContainer = styled.div`
  ${LabelText} {
    text-align: center;
    white-space: nowrap;
  }
`;

export const StyledInput = styled(NumberFormat)`
  outline: none;
  border: none;
  background: ${theme.colors.colorTranslucentGray4};
  width: ${(props) => props.width}px;
  color: ${theme.colors.colorMediumGray9};
  padding: 7px;
  margin-top: 3px;
  font-size: 14px;
  text-align: center;
  &:disabled {
    opacity: 0.8;
    color: ${theme.colors.colorLightGray15};
  }
`;

const Input = ({ label, ...props }) => {
  return (
    <InputContainer>
      <LabelText>{label}</LabelText>
      <StyledInput
        decimalScale={2}
        allowNegative={false}
        thousandSeparator={true}
        inputmode="numeric"
        {...props}
      />
    </InputContainer>
  );
};

export default Input;
