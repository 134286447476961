import styled from 'styled-components';
import theme from 'theme';

export const LevelsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 185px;
`;

export const Level = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 7px;
  height: 45px;
  cursor: pointer;
  .level-text {
    padding-left: 10px;
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  }
  .level-shapes {
    padding-right: 7px;
    .expert {
      margin-bottom: 1rem;
    }
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray1};
  }
`;

export const StyledItemName = styled.div`
  color: ${(props) =>
    !props.isArchived
      ? theme.colors.colorSemiDarkGray2
      : theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${({ isAdded }) => (isAdded ? 'bold' : 'normal')};
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
`;

export const ItemRow = styled.div`
  padding-left: 12px;
  width: 100%;
  max-width: 195px;
  display: flex;
`;

export const AddRequirementButton = styled.button`
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 30px;
  padding: 0 6px;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
  white-space: nowrap;
  background: white;
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  path {
    fill: ${theme.colors.colorPureBlack};
  }
`;

export const AddRow = styled.div`
  padding-left: 48px;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 13px;
`;

export const SectionHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.colors.colorCalendarGray};
  padding-left: 30px;
`;
