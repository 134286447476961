import React, { useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import noop from 'lodash/noop';
import { Modal } from 'reactstrap';
import {
  fetchMemberRates,
  deleteMemberBudget
} from 'BudgetModule/actionCreators';
import { fetchProjectById, openAddMembersForm } from 'actionCreators';
import { getUserIsAdmin, getUserIsFinancialManager } from 'selectors';
import {
  getMemberBudgets,
  makeGetUnformattedBudgetTotals
} from 'BudgetModule/selectors';
import RateSelection from 'BudgetModule/components/BudgetTable/RateSelection';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import Popover from 'components/Popover';
import TwoMembersIcon from 'icons/TwoMembersIcon';
import CloseIconBig from 'icons/CloseIconBig';
import MemberWithPencil from 'icons/MemberWithPencil';
import LightBulbIcon from 'icons/LightBulbIcon';
import { useMergeBudgetModal } from 'BudgetModule/hooks/useMergeBudgetModal';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { membershipLevels } from './workloadBarModal/shared/constants';

const emptyObj = {};

const DROPDOWN_NAMES = {
  ASSIGN_MEMBER: 'assignMember',
  MODIFY_ROLE: 'modifyRole',
  REMOVE: 'remove'
};

const UnassignedThreeDotMenu = ({
  project = emptyObj,
  memberBudget = emptyObj,
  group = emptyObj,
  handleOpenAddMembersForm,
  positionName,
  fetchMemberRates,
  deleteMemberBudget,
  isAdmin,
  isFinancialManager,
  setShowMemberSuggestionsOnOpen,
  fetchProjectById,
  projectTotal,
  handleOpenFindPeopleModal
}) => {
  const [selectedModal, setSelectedModal] = useState(undefined);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const projectId = project.id;
  const memberBudgetId = memberBudget.id;
  const hasPermission = isAdmin || isFinancialManager;
  const { isHoursOnly } = useIsHoursOnly();
  const { newMembersSuggestionTableFlag } = useFeatureFlags();

  const { handleOpenMergeBudgetModal, MergeBudgetModal } =
    useMergeBudgetModal();

  const handleDropdownOpen = () => {
    fetchProjectById(projectId);
    setIsDropdownOpen(true);
  };
  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const handleClearSelectedModal = () => {
    setSelectedModal(undefined);
  };

  const handleRateSelectionClick = () => {
    fetchMemberRates({ memberBudgetId: memberBudgetId });
  };

  const handleDeleteUnassigned = useCallback(() => {
    deleteMemberBudget({ projectId, id: memberBudgetId });
  }, [deleteMemberBudget, projectId, memberBudgetId]);

  const openMembersModal = () => {
    if (newMembersSuggestionTableFlag) {
      // Team Builder modal
      // Disable changing project when open on top of budget modal due to many side effect issues
      // ref: https://github.com/LifeCoded/mosaic-web/pull/10773#issuecomment-1240148284
      handleOpenFindPeopleModal({ projectId, disableChangingProject: true });
    } else {
      setShowMemberSuggestionsOnOpen();
      handleOpenAddMembersForm({
        project_id: projectId,
        board_id: project.board_id
      });
    }
    handleDropdownClose();
  };

  const handleAssignHoursDone = (members) => {
    const account = members?.[0]?.account;
    if (memberBudgetId && account) {
      const accountTotal = projectTotal?.account_totals?.find(
        (accountTotal) => accountTotal.member_budget_id === memberBudgetId
      );
      const totalEstimatedHours = accountTotal?.estimated?.hours || 0;
      const totalPlannedHours = accountTotal?.planned?.hours || 0;

      handleOpenMergeBudgetModal({
        assignedAccountId: account.id,
        assignedName: account.name,
        unassignedMemberBudgetId: memberBudgetId,
        projectId,
        totalEstimatedHours,
        totalPlannedHours
      });
    }
  };

  return (
    <UnassignedThreeDotMenuContainer
      onClick={(e) => {
        e.stopPropagation();
        handleDropdownOpen();
      }}
      ref={dropdownRef}
    >
      <ProjectThreeDotMenu
        threeDotClassName="threeDotMenu unassigned-three-dot-menu"
        onCloseCallback={handleDropdownClose}
        shouldClose={!isDropdownOpen}
        memberName={group.name}
        size={24}
      >
        <Popover
          isOpen={isDropdownOpen}
          target={dropdownRef}
          closePopover={handleDropdownClose}
          className="unassigned-three-dot-popover with-border-radius"
        >
          <StyledDropdownItem
            onClick={() => {
              setSelectedModal(DROPDOWN_NAMES.ASSIGN_MEMBER);
              handleDropdownClose();
            }}
          >
            <div className="icon-container">
              <TwoMembersIcon width="14px" height="14px" />
            </div>
            Assign Member
          </StyledDropdownItem>
          <StyledDropdownItem
            onClick={() => {
              openMembersModal();
            }}
          >
            <div className="icon-container">
              <LightBulbIcon color={theme.colors.colorMediumGray9} />
            </div>
            Team Builder
          </StyledDropdownItem>
          {!isHoursOnly && (
            <StyledDropdownItem
              isDisabled={!hasPermission}
              onClick={
                hasPermission
                  ? () => {
                      setSelectedModal(DROPDOWN_NAMES.MODIFY_ROLE);
                      handleRateSelectionClick();
                      handleDropdownClose();
                    }
                  : noop
              }
            >
              <div className="icon-container">
                <MemberWithPencil />
              </div>
              Modify Role
            </StyledDropdownItem>
          )}
          <StyledDropdownItem
            isDisabled={!hasPermission}
            onClick={
              hasPermission
                ? () => {
                    setSelectedModal(DROPDOWN_NAMES.REMOVE);
                    handleDropdownClose();
                  }
                : noop
            }
          >
            <div className="icon-container">
              <CloseIconBig width="10" height="10" />
            </div>
            Remove Role
          </StyledDropdownItem>
        </Popover>
      </ProjectThreeDotMenu>
      {selectedModal && selectedModal === DROPDOWN_NAMES.ASSIGN_MEMBER ? (
        <BulkMemberSelector
          projectId={projectId}
          alwaysOpen
          handleDone={handleAssignHoursDone}
          membershipLevel={membershipLevels.project}
          renderSelect={() => null}
          singleSelect
          hideFooter
          target={dropdownRef}
          onClose={handleClearSelectedModal}
        />
      ) : selectedModal === DROPDOWN_NAMES.REMOVE ? (
        <SimpleConfirmModal
          isOpen
          toggle={handleClearSelectedModal}
          onCancel={handleClearSelectedModal}
          onConfirm={handleDeleteUnassigned}
          header={`Remove Role`}
          body={
            <StyledModalBody>
              Are you sure you want to <b>Remove</b> This <b>{positionName}</b>{' '}
              from the Project?
              <br />
              All estimated and planned time will be permanently deleted.
            </StyledModalBody>
          }
          confirmLabel={<DeleteButtonText>Delete Role</DeleteButtonText>}
          cancelLabel="Cancel"
          submitButtonColor={theme.colors.colorCalendarRed}
          styledWrapper={StylesWrapper}
        />
      ) : null}
      {/* Don't render it conditionally. It needs to see openRateSelectionType change in order to update properly */}
      <RateSelection
        handleClick={() => {
          handleRateSelectionClick();
        }}
        handleClose={() => {
          handleClearSelectedModal();
          handleDropdownClose();
        }}
        memberBudget={memberBudget}
        memberBudgetId={memberBudget && memberBudgetId}
        key={'unassigned-rate'}
        inTableCell
        openRateSelectionType={
          selectedModal === DROPDOWN_NAMES.MODIFY_ROLE ? 'rates' : null
        }
        memberName={positionName || 'Unassigned Member'}
      />
      <MergeBudgetModal />
    </UnassignedThreeDotMenuContainer>
  );
};

const makeMapStateToProps = () => {
  const getTotalEstimatedHours = makeGetUnformattedBudgetTotals();
  const mapStateToProps = (state, ownProps) => {
    const memberBudgetHash = getMemberBudgets(state);
    const memberBudget = memberBudgetHash[ownProps.memberBudgetId];
    return {
      memberBudget,
      isAdmin: getUserIsAdmin(state),
      isFinancialManager: getUserIsFinancialManager(state),
      projectTotal: getTotalEstimatedHours(state, {
        ...ownProps,
        projectId: ownProps.project?.id
      })
    };
  };

  return mapStateToProps;
};
const mapDispatchToProps = {
  deleteMemberBudget,
  openAddMembersForm,
  fetchProjectById,
  fetchMemberRates
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UnassignedThreeDotMenu);

const UnassignedThreeDotMenuContainer = styled.div`
  height: 24px;
  width: 24px;
`;

const StyledDropdownItem = styled.div`
  font-size: 13px;
  padding: 6px 12px;
  color: ${({ isDisabled }) =>
    isDisabled
      ? theme.colors.colorMediumGray14
      : theme.colors.colorMediumGray9};
  margin: 1px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? `svg {
      path {
        fill: ${theme.colors.colorMediumGray14};
        stroke: ${theme.colors.colorMediumGray14};
      }
    }`
      : ''}

  &:hover {
    background: ${({ isDisabled }) =>
      isDisabled ? 'none' : theme.colors.colorTranslucentGray4};
  }
`;

const StyledModalBody = styled.div`
  font-size: 13px;
  line-height: 17.7px;
  color: ${theme.colors.colorPureBlack};
`;

const DeleteButtonText = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const StylesWrapper = styled(Modal)`
  width: 450px;
  height: 217px;
  border-radius: 6px;
`;
