import React from 'react';
import Picker from 'rc-calendar/lib/Picker';
import RangeCalendar from '../../fractal-rc-calendar/lib/RangeCalendar';
import enUS from 'rc-calendar/lib/locale/en_US';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import cn from 'classnames';
import { CalendarClickoutListener } from '..';

const now = moment();

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

const formatStr = 'M/D/YY';
function format(v) {
  return v ? v.format(formatStr) : '';
}

function isValidRange(v) {
  return v && v[0]; // && v[1];
}

class MosaicRangeCalendar extends React.Component {
  state = {
    open: this.props.calendarOpenDefault || false
  };

  openCalendar = () => {
    this.setState({
      open: true
    });
  };

  closeCalendar = (event, isClickOutside) => {
    const { saveDates, onClose, shouldSave = true } = this.props;
    this.setState({ open: false });
    if (!isClickOutside && shouldSave) {
      saveDates();
    }
    if (onClose) {
      onClose(isClickOutside);
    }
  };

  renderSidebar = () => {
    // wrapper not allowed between RangeCalendar and Picker
    // therefore use 'sidebar' to render a click handling wrapper
    return (
      <CalendarClickoutListener
        handleOutsideClick={(event) => this.closeCalendar(event, true)}
      />
    );
  };

  renderFooter = () => {
    const { newDates, clearDates, item } = this.props;
    const startDate = this.props.startDate || item?.start_date;
    const endDate = this.props.endDate || item?.end_date;
    const hasOldDates = startDate && endDate && !newDates;

    const customFooter = (
      <div className="custom-milestone-buttons" key="calendar footer">
        {hasOldDates || (newDates && newDates.length) ? (
          <div className="milestone-calendar-clear" onClick={clearDates}>
            Clear
          </div>
        ) : null}
        <div className="milestone-calendar-done" onClick={this.closeCalendar}>
          Done
        </div>
      </div>
    );

    return [customFooter];
  };

  getCalendarContainer = () => this.props.calendarContainer;

  getDateValues = () => {
    const { newDates, item } = this.props;

    const { start_date, end_date, completed_at } = item || {};

    const startDate = start_date || this.props.startDate;
    const endDate = end_date || this.props.endDate;
    const completedAt = completed_at || this.props.completedAt;
    const dateValues =
      item && startDate && endDate && !newDates
        ? [moment(startDate), moment(endDate)]
        : newDates === null
        ? []
        : newDates.length === 1
        ? [newDates[0], null]
        : newDates;
    return dateValues;
  };

  defaultPickerRenderer = ({ value }) => {
    const { open } = this.state;
    const { item, withinMilestonesList, newDates } = this.props;
    const { completed_at } = item || {};
    const dateValues = this.getDateValues();
    const completedAt = completed_at || this.props.completedAt;
    return (
      <div
        className={cn('dates-col', {
          'is-complete': item && completedAt
        })}
      >
        <div
          className={cn('dates-row', {
            'existing-milestone': withinMilestonesList
          })}
        >
          <input
            className={cn('project-milestone-start-date', {
              'add-milestone-header': !item,
              'calendar-open': open && !newDates
            })}
            value={
              isValidRange(dateValues)
                ? `${format(dateValues[0])}`
                : 'Start Date'
            }
            onClick={this.openCalendar}
            readOnly
          />
          <div className="project-milestone-date-hyphen"> - </div>
          <input
            className={cn('project-milestone-end-date', {
              'add-milestone-header': !item,
              'calendar-open': open && newDates && newDates.length === 1
            })}
            value={
              isValidRange(dateValues) && dateValues[1]
                ? `${format(dateValues[1])}`
                : 'End Date'
            }
            onClick={this.openCalendar}
            readOnly
          />
        </div>
      </div>
    );
  };

  renderPicker = ({ value }) => {
    const PickerRenderer =
      this.props.pickerRenderer || this.defaultPickerRenderer;
    return <PickerRenderer value={value} openCalendar={this.openCalendar} />;
  };

  render() {
    const { handleDateSelection, placement } = this.props;
    const calendar = (
      <RangeCalendar
        showWeekNumber={false}
        dateInputPlaceholder={['Start', 'End']}
        defaultValue={[now, now.clone().add(1, 'months')]}
        locale={enUS}
        renderFooter={this.renderFooter}
        renderSidebar={this.renderSidebar}
        onChange={handleDateSelection}
        className="milestone-calendar"
        placement={placement || 'bottom'}
      />
    );

    const dateValues = this.getDateValues();

    return (
      <Picker
        value={dateValues}
        animation="slide-up"
        calendar={calendar}
        open={this.state.open}
        getCalendarContainer={this.getCalendarContainer}
      >
        {this.renderPicker}
      </Picker>
    );
  }
}

export default MosaicRangeCalendar;
