import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewNotesIcon from 'images/addviewnotesicon.svg';
import AddViewKanbanIcon from 'images/addviewkanbanicon.svg';
import AddViewMeetingIcon from 'images/addviewmeetingicon.svg';
import AddViewBudgetIcon from 'images/addviewbudgeticon.svg';
import AddViewPlannerIcon from 'images/addviewplannericon.svg';
import AddViewTimelinesIcon from 'images/addviewtimelinesicon.svg';
import AddViewChecklistIcon from 'images/addviewchecklisticon.svg';

export const moduleTextHash = {
  1: 'ALL MEMBERS',
  // 2: 'Timesheets',
  // 3: 'Task Planner',
  // 4: 'Project Planner',
  // 5: 'Tasks',
  6: 'DEPARTMENTS',
  7: 'ORG CHART'
};

export const moduleHash = {
  1: 'members',
  2: 'timesheet',
  // 3: 'task-planner',
  // 4: 'project-planner',
  // 5: 'tasks',
  6: 'department',
  7: 'chart'
};

export const defaultNavModuleIds = [1, 6, 7];
const nonDefaultNavModuleIds = [2];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'Manage Members.',
  2: 'View the latest timesheet submissions and manage approvals for teams that track time.',
  3: `Keep up on your teams workload at a glance with a view of everyone's planned tasks.`,
  4: `Keep up on your teams workload at a glance with a heatmap view of everyone's planned hours.`,
  5: `All your team's tasks in one place`,
  6: `Break down members by Department.`
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#3db489',
  3: '#DAAD0D',
  4: '#E16AA5',
  5: '#F14848',
  6: '#F14848'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewNotesIcon}) `,
  3: `url(${AddViewTimelinesIcon}) `,
  4: `url(${AddViewPlannerIcon}) `,
  5: `url(${AddViewMeetingIcon}) `,
  6: `url(${AddViewMeetingIcon}) `
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];

export const moduleToFlagHash = {
  7: 'orgChartFlag'
};
