import React from 'react';
import CompleteCell from './CompleteCell';
import TotalEstHoursCell from './TotalEstHoursCell';
import PhaseHeaderCell from './PhaseHeaderCell';
import PhaseBulkHeaderCell from './PhaseBulkHeaderCell';
const EmptyDiv = () => <div></div>;

const PhaseCells = {
  CompleteCell,
  DragCell: EmptyDiv,
  DescriptionCell: EmptyDiv,
  DueCell: EmptyDiv,
  ScheduleCell: EmptyDiv,
  StatusCell: EmptyDiv,
  PriorityCell: EmptyDiv,
  PhaseCell: EmptyDiv,
  AssigneeCell: EmptyDiv,
  BulkCell: PhaseBulkHeaderCell,
  PhaseHeaderCell,
  // Footer cells,
  TotalEstHoursCell
};

export default PhaseCells;
