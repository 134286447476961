import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getIntegrationsList,
  getIntegrations,
  getIsLoadingForIntegrations,
  getSaveButtonClikedFlag,
  getIsCancellingFlag
} from 'IntegrationsModule/selectors';
import { getSelectedTeamId, getSplitFlags } from 'selectors';
import ContentLoader from 'react-content-loader';
import {
  linkIntegration,
  disconnectIntegration,
  fetchIntegrations,
  saveButtonClick
} from 'IntegrationsModule/actionCreators';
import {
  updateSyncStatus,
  cancelSyncStatus
} from 'QuickbooksModule/actionCreators';

import QBConnectModal from './QBConnectModal';
import ConnectBtn from 'images/qb-connect-btn.png';
import { isPendingStatus } from 'QuickbooksModule/selectors';
import QuickbooksIntegration from 'QuickbooksModule/components/LoadQuickbooksContainer';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';

import {
  QuickbooksHeaderContainer,
  QBThumb,
  QuickBooksHeader,
  StyledLegendContainer,
  StyledPrimaryButton,
  ButtonsContainer,
  StyledDisconnectQBIcon,
  QuickBooksDropdownItem
} from './styles';
import EmptyQuickBooksText from './EmptyQuickBooksText';
import LoadingWheel from 'icons/LoadingWheel';

const integrationComponents = {
  quickbooks: QuickbooksIntegration
};

class IntegrationsTab extends React.Component {
  state = {
    isQBConnectModalOpen: false
  };

  handleConnectClick = (integration) => {
    const { linkIntegration, disconnectIntegration, isIntegratedHash } =
      this.props;
    const isIntegrated = isIntegratedHash[integration];
    if (isIntegrated) {
      disconnectIntegration({ integration });
    } else {
      linkIntegration({ integration });
    }
  };

  renderButton = ({ isIntegrated, integration }) => {
    const { isLoading } = this.props;
    if (integration === 'quickbooks') {
      return isIntegrated ? (
        <ButtonsContainer>
          <ProjectThreeDotMenu align="right">
            <QuickBooksDropdownItem
              onClick={() => this.handleConnectClick(integration)}
            >
              <StyledDisconnectQBIcon />
              Disconnect
            </QuickBooksDropdownItem>
          </ProjectThreeDotMenu>
        </ButtonsContainer>
      ) : isLoading ? null : (
        <img
          src={ConnectBtn}
          width={'180px'}
          onClick={this.OpenQBConnectModal}
        />
      );
    }
    return null;
  };

  OpenQBConnectModal = () => {
    this.setState({
      isQBConnectModalOpen: true
    });
  };

  closeQBConnectModal = () => {
    this.setState({
      isQBConnectModalOpen: false
    });
  };

  import = () => {
    const { saveButtonClick, updateSyncStatus } = this.props;
    saveButtonClick({
      saveButtonClicked: true
    });
    updateSyncStatus({
      body: {
        completed: true
      }
    });
  };

  cancel = () => {
    const { cancelSyncStatus } = this.props;
    cancelSyncStatus({});
  };

  // onClick={() => this.handleConnectClick(integration)}
  renderIntegrationTab = (integration) => {
    const {
      isIntegratedHash,
      isPending,
      isLoading,
      saveButtonClicked,
      isCancelling
    } = this.props;
    const isIntegrated = isIntegratedHash[integration];
    const Integration = integrationComponents[integration];
    return (
      <div
        style={{ overflow: 'scroll', display: 'flex', width: '100%' }}
        key={integration}
      >
        <div key={integration}>
          <div style={{ flexGrow: '1', minWidth: '1048px' }}>
            <QuickbooksHeaderContainer>
              <QuickBooksHeader>
                <QBThumb /> QuickBooks Integration
              </QuickBooksHeader>
              <ButtonsContainer>
                {isCancelling && <LoadingWheel width={20} height={20} />}
                {isPending && !saveButtonClicked && !isCancelling && (
                  <StyledPrimaryButton onClick={this.cancel}>
                    Cancel
                  </StyledPrimaryButton>
                )}
                {isPending && !saveButtonClicked && (
                  <StyledPrimaryButton onClick={this.import}>
                    Save
                  </StyledPrimaryButton>
                )}
                {this.renderButton({ isIntegrated, integration })}
              </ButtonsContainer>
            </QuickbooksHeaderContainer>
            {isLoading ? (
              <ContentLoader style={{ width: '100%', paddingTop: '20px' }}>
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="22" />
                <rect x="0" y="26" rx="4" ry="4" width="100%" height="22" />
                <rect x="0" y="52" rx="4" ry="4" width="100%" height="22" />
                <rect x="0" y="78" rx="4" ry="4" width="100%" height="22" />
              </ContentLoader>
            ) : (
              <>
                {isIntegrated ? <Integration /> : <EmptyQuickBooksText />}
                {this.state.isQBConnectModalOpen && (
                  <QBConnectModal
                    isOpen={this.state.isQBConnectModalOpen}
                    close={this.closeQBConnectModal}
                    teamId={this.props.teamId}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <StyledLegendContainer>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <strong>Sync Terminology</strong>
            </div>
            <table>
              <thead>
                <tr>
                  <th>QuickBooks</th>
                  <th>Mosaic</th>
                </tr>
                <tr>
                  <td>Employees</td>
                  <td>Members</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>Client</td>
                </tr>
                <tr>
                  <td>Sub-Customer</td>
                  <td>Project</td>
                </tr>
                <tr>
                  <td>Sub/sub-Customer</td>
                  <td>Phase Of Work</td>
                </tr>
                <tr>
                  <td>Service</td>
                  <td>Work Category</td>
                </tr>
              </thead>
            </table>
          </div>
        </StyledLegendContainer>
      </div>
    );
  };

  render() {
    const { integrations } = this.props;

    return integrations.map(this.renderIntegrationTab);
  }
}

const mapStateToProps = (state) => ({
  integrations: getIntegrationsList(state),
  isIntegratedHash: getIntegrations(state),
  teamId: getSelectedTeamId(state),
  isLoading: getIsLoadingForIntegrations(state),
  isPending: isPendingStatus(state),
  saveButtonClicked: getSaveButtonClikedFlag(state),
  isCancelling: getIsCancellingFlag(state),
  shouldUseQBV2: getSplitFlags(state)?.QB_V2_WhiteList,
  shouldUseIntegrationsV2: getSplitFlags(state)?.shouldUseIntegrationsV2
});

const mapDispatchToProps = {
  linkIntegration,
  disconnectIntegration,
  fetchIntegrations,
  updateSyncStatus,
  saveButtonClick,
  cancelSyncStatus
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IntegrationsTab)
);
