import { MembersPermissionsTableCells } from '../Cells';
import { BaseTableColumn, EmptyRowListItem } from 'components/Table/types';
import { EmptyDiv } from 'components/EmptyDiv';
import sumBy from 'lodash/sumBy';

const {
  MemberTypeSectionHeaderCell,
  HeaderCell,
  EmployeeNumberCell,
  NameCell,
  EmailCell,
  AccessCell,
  SelectCell,
  ArchivedSectionHeaderCell,
  ThreeDotCell
} = MembersPermissionsTableCells;

const ROW_TYPES = {
  memberHeaderRow: 'memberHeaderRow',
  columnHeaderRow: 'columnHeaderRow',
  memberPermissionRow: 'memberPermissionRow',
  archivedSectionHeaderRow: 'archivedSectionHeaderRow'
};

const columnTypes = {
  tableWidth: 'tableWidth',
  leadingColumn: 'leadingColumn',
  employeeNumber: 'employeeNumber',
  name: 'name',
  email: 'email',
  access: 'access',
  select: 'select'
};

const _columnWidths = {
  [columnTypes.leadingColumn]: 26,
  [columnTypes.employeeNumber]: 72,
  [columnTypes.name]: 220,
  [columnTypes.email]: 245,
  [columnTypes.access]: 360,
  [columnTypes.select]: 48
};

const tableWidthColumn: BaseTableColumn = {
  id: 'tableWidth',
  headerType: 'tableWidth',
  align: 'center'
};

const headersToIgnore = [tableWidthColumn.id];

const leadingColumn: BaseTableColumn = {
  id: columnTypes.leadingColumn,
  headerType: columnTypes.leadingColumn
};

const employeeNumberColumn: BaseTableColumn = {
  id: columnTypes.employeeNumber,
  headerType: columnTypes.employeeNumber,
  headerLabel: 'REF'
};

const nameColumn: BaseTableColumn = {
  id: columnTypes.name,
  headerType: columnTypes.name,
  headerLabel: 'NAME'
};

const emailColumn: BaseTableColumn = {
  id: columnTypes.email,
  headerType: columnTypes.email,
  headerLabel: 'EMAIL'
};

const accessColumn: BaseTableColumn = {
  id: columnTypes.access,
  headerType: columnTypes.access,
  headerLabel: 'ACCESS'
};

const selectColumn: BaseTableColumn = {
  id: columnTypes.select,
  headerType: columnTypes.select
};

const allColumns: BaseTableColumn[] = [
  tableWidthColumn,
  leadingColumn,
  employeeNumberColumn,
  nameColumn,
  emailColumn,
  accessColumn,
  selectColumn
];

// does not include tableWidth
const totalColumnWidths = sumBy(
  allColumns,
  (column) => _columnWidths[column.headerType] ?? 0
);

const columnWidths = {
  ..._columnWidths,
  [columnTypes.tableWidth]: totalColumnWidths
};

const maxTotalWidth = totalColumnWidths;

const rowToCells = {
  [ROW_TYPES.memberHeaderRow]: {
    [columnTypes.tableWidth]: MemberTypeSectionHeaderCell
  },
  [ROW_TYPES.columnHeaderRow]: {
    [columnTypes.leadingColumn]: EmptyDiv,
    [columnTypes.employeeNumber]: HeaderCell,
    [columnTypes.name]: HeaderCell,
    [columnTypes.email]: HeaderCell,
    [columnTypes.access]: HeaderCell,
    [columnTypes.select]: EmptyDiv
  },
  [ROW_TYPES.memberPermissionRow]: {
    [columnTypes.leadingColumn]: ThreeDotCell,
    [columnTypes.employeeNumber]: EmployeeNumberCell,
    [columnTypes.name]: NameCell,
    [columnTypes.email]: EmailCell,
    [columnTypes.access]: AccessCell,
    [columnTypes.select]: SelectCell
  },
  [ROW_TYPES.archivedSectionHeaderRow]: {
    [columnTypes.tableWidth]: ArchivedSectionHeaderCell
  }
};

const stickyHeaderCells = rowToCells[ROW_TYPES.memberHeaderRow];

const emptyRow: EmptyRowListItem = {
  rowType: 'emptyRow',
  rowHeight: 40,
  isDragDisabled: true
};

export const membersPermissionsTableHelpers = {
  ROW_TYPES,
  columnTypes,
  totalColumnWidths,
  columnWidths,
  maxTotalWidth,
  headersToIgnore,
  allColumns,
  rowToCells,
  stickyHeaderCells,
  emptyRow
};
