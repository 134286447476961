import { Component } from 'react';
import { connect } from 'react-redux';
import { getProjectModuleOrder } from 'selectors';
import ProjectColumnDraggable from './ProjectColumnDraggable';

import { updateProjectModules } from 'actionCreators';
import { withRouter } from 'react-router-dom';
import ColumnsDropdown from 'components/ColumnsDropdown';
import AddViewIcon from 'icons/AddViewIcon';
import { AddViewIconContainer } from './styles';
import cn from 'classnames';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { MANAGE_PROJECT_VIEWS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { withProjectPermissionProvider } from 'PermissionsModule/SpaceLevelPermissions/hocs/withProjectPermissionProvider';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
const taskRemoveSubtext = (
  <div>
    It will be removed for all project members but data will not be deleted and
    can be added back at any time. Are you sure you want to{' '}
    <strong>hide</strong> this view?
  </div>
);

const customStyles = {
  width: 'auto',
  'align-self': 'auto'
};

class ProjectColumnsDropdown extends Component {
  handleReorder = (order) => {
    const {
      updateProjectModules,
      matchedParams: { projectId }
    } = this.props;
    updateProjectModules({
      projectId,
      appModules: order.map((id) => +id)
    });
  };

  renderButton = () => {
    const {
      projectPermissions: { canEditProjectViews }
    } = this.props;
    const isDisabled = !canEditProjectViews;

    return (
      <li className="clickable" id="add-tab">
        <AddViewIconContainer
          className={cn({ disabled: isDisabled })}
          {...defaultTooltipProps}
          data-tip={MANAGE_PROJECT_VIEWS_TIP}
          data-tip-disable={!isDisabled}
        >
          <AddViewIcon />
        </AddViewIconContainer>
      </li>
    );
  };

  render() {
    const {
      columns,
      show,
      hideContents,
      projectPermissions: { canEditProjectViews }
    } = this.props;
    const isDisabled = !canEditProjectViews;

    return (
      <ColumnsDropdown
        DraggableComponent={ProjectColumnDraggable}
        handleReorder={this.handleReorder}
        columns={columns.map((id) => `${id}`)}
        removeSubtext={taskRemoveSubtext}
        show={show}
        hideContents={hideContents}
        customStyles={customStyles}
        renderButton={this.renderButton}
        headerText="Edit Views"
        viewType="view"
        // = (width of dropdown) - (width of the result of `renderButton`)
        offsetRight={-207}
        isDisabled={isDisabled}
      />
    );
  }
}
const mapStateToProps = (state) => {
  const matchedParams = getMatchedRouteParams(state);
  const { projectId } = matchedParams;
  return {
    columns: getProjectModuleOrder(state),
    matchedParams,
    permissionProps: {
      projectId
    }
  };
};

const mapDispatchToProps = {
  updateProjectModules
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withProjectPermissionProvider(ProjectColumnsDropdown))
);
