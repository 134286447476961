import { getSelectedTeamId } from 'selectors';
import {
  createRegion,
  updateRegion,
  deleteRegion,
  updateRegionEntities
} from 'SettingsModule/actionCreators/settings/region';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { getFormattedRegionsWithMembers } from 'SettingsModule/selectors/regions';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { SettingTableTemplateWithMemberCells } from '../SettingTableTemplate/SettingTableTemplateWithMemberCells';
import { useMemo, useState } from 'react';
import { GroupData, RowDataWithGroupId } from '../SettingTableTemplate/types';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { ReactComponent as SmallPencilIcon } from 'icons/small-pencil.svg';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';

export const RegionsTable = () => {
  const [toDeleteGroupData, setToDeleteGroupData] = useState<
    GroupData | undefined
  >();
  const [editingGroupId, setEditingGroupId] = useState<number | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const formattedRegions = useAppSelector(getFormattedRegionsWithMembers);
  const teamId = useAppSelector(getSelectedTeamId);
  const dispatch = useAppDispatch();

  const { groupData, rowData } = useMemo(() => {
    const groupData: GroupData[] = [];
    const rowData: RowDataWithGroupId<any>[] = [];

    formattedRegions.forEach(
      ({ id, name, team_membership_count, formattedRegionMembers }) => {
        groupData.push({ id, label: name, totalCount: team_membership_count });

        formattedRegionMembers.forEach((member) =>
          rowData.push({
            data: member,
            groupId: id,
            teamMember: member
          })
        );
      }
    );

    return { groupData, rowData };
  }, [formattedRegions]);

  const handleAddNewRegion = ({ label }: { label: string }) => {
    if (teamId) {
      dispatch(createRegion({ name: label, teamId }));
    }
  };

  const handleToggleRegionDelete = () => {
    setIsDeleteModalOpen(false);
    setToDeleteGroupData(undefined);
  };

  const handleConfirmDeleteRegion = () => {
    setIsDeleteModalOpen(false);

    if (toDeleteGroupData) {
      dispatch(deleteRegion({ id: toDeleteGroupData.id }));
    }

    setToDeleteGroupData(undefined);
  };

  const handleRemoveMemberFromRegion = (
    rowData: RowDataWithGroupId<unknown>
  ) => {
    if (teamId) {
      dispatch(
        updateRegionEntities({
          id: rowData.groupId,
          removeEntities: [
            {
              entityId: rowData.teamMember.id,
              entityType: ENTITY_TYPES.teamMembership
            }
          ]
        })
      );
    }
  };

  const handleSelectMembers = ({
    groupData,
    selectedMembers
  }: {
    groupData: GroupData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedMembers: any[];
  }) => {
    dispatch(
      updateRegionEntities({
        id: groupData.id,
        addEntities: selectedMembers.map((member) => ({
          entityType: ENTITY_TYPES.teamMembership,
          entityId: member.id
        }))
      })
    );
  };

  const handleEditGroup = ({ id, label }) => {
    setEditingGroupId(undefined);

    if (label.length) {
      dispatch(updateRegion({ id, name: label }));
    }
  };

  return (
    <>
      <RootContainer>
        <SettingTableTemplateWithMemberCells
          useGroup
          groupData={groupData}
          rowData={rowData}
          headerRowProps={{
            buttonProps: { label: 'Region' },
            blurbProps: {
              message: 'Use Region to filter project members on Mosaic. '
            }
          }}
          groupRowProps={{
            groupRowMenus: [
              {
                label: 'Edit Region Name',
                icon: <SmallPencilIcon />,
                onClick: (data) => setEditingGroupId(data.id)
              },
              {
                label: 'Delete Region',
                icon: <DeleteIcon />,
                onClick: (data) => {
                  setToDeleteGroupData(data);
                  setIsDeleteModalOpen(true);
                }
              }
            ],
            onAddGroupInline: handleAddNewRegion,
            useInlineCreation: true,
            editingGroupId,
            onEditGroupInline: handleEditGroup
          }}
          memberRowProps={{ onRemoveRow: handleRemoveMemberFromRegion }}
          onSelectMembers={handleSelectMembers}
        />
      </RootContainer>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        component={'region'}
        toggle={handleToggleRegionDelete}
        deleteOnClick={handleConfirmDeleteRegion}
      />
    </>
  );
};

const RootContainer = styled.div`
  height: 100%;
`;
