import { getOOOProject } from 'selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getAccountCapacities,
  getHolidaysArray
} from 'CapacityModule/selectors';
import { filterBarsByDateRange } from 'appUtils/workplanDisplayUtils';
import flatten from 'lodash/flatten';
import countBy from 'lodash/countBy';
import moment from 'appUtils/momentConfig';
import sum from 'lodash/sum';

const emptyObj = {};
const emptyArray = [];
export const useAccountCapacityTotals = ({
  accountIds = emptyArray,
  fetchParams = emptyObj
}) => {
  const OOOProject = useSelector(getOOOProject);
  const accountCapacities = useSelector(getAccountCapacities);

  const dateRangeFilter = useMemo(
    () => filterBarsByDateRange(fetchParams.start_date, fetchParams.end_date),
    [fetchParams.end_date, fetchParams.start_date]
  );
  const holidays = useSelector(getHolidaysArray);

  const rangeWeekDayOccurences = useMemo(() => {
    if (!fetchParams.start_date || !fetchParams.end_date) {
      return {};
    }
    const weekDaysInRange = Array.from(
      moment.range(fetchParams.start_date, fetchParams.end_date).by('day')
    ).map((day) => day.format('dddd').toLowerCase());
    return countBy(weekDaysInRange);
  }, [fetchParams.end_date, fetchParams.start_date]);

  const weekDayOccurencesMinusHolidays = useMemo(
    () =>
      dateRangeFilter(holidays).reduce(
        (weekDayHolidayOccurences, holiday) => {
          if (!fetchParams.start_date || !fetchParams.end_date) {
            return {};
          }
          // usually one day but this prepares for ranged holidays
          const holidayWeekDaysInRange = Array.from(
            moment.range(holiday.start_date, holiday.end_date).by('day')
          ).map((day) => day.format('dddd').toLowerCase());

          holidayWeekDaysInRange.forEach(
            (day) => (weekDayHolidayOccurences[day] -= 1)
          );
          return weekDayHolidayOccurences;
        },
        { ...rangeWeekDayOccurences }
      ),
    [
      dateRangeFilter,
      fetchParams.end_date,
      fetchParams.start_date,
      holidays,
      rangeWeekDayOccurences
    ]
  );

  const accountCapacityTotals = useMemo(
    () =>
      accountIds.reduce((totalsHash, accountId) => {
        if (!fetchParams.start_date || !fetchParams.end_date) {
          return {};
        }
        const accountCapacity = accountCapacities[accountId] || {};
        const OOOSubBars = dateRangeFilter(
          flatten(
            accountCapacity?.activity_phase_schedule_bars
              ?.filter((bar) => bar.project_id === OOOProject?.id)
              .map((bar) => bar.bars)
          ) ?? []
        );
        const OOOweekDaysInRanges = flatten(
          OOOSubBars.map((bar) =>
            Array.from(
              moment.range(bar.start_date, bar.end_date).by('day')
            ).map((day) => day.format('dddd').toLowerCase())
          )
        );
        const outOfOfficeOccurences = countBy(OOOweekDaysInRanges);
        const accountTotalCapacity = Object.entries(
          weekDayOccurencesMinusHolidays
        ).reduce(
          (totalCapacity, [dateKey, numOccurrences]) =>
            totalCapacity +
            (numOccurrences - (outOfOfficeOccurences[dateKey] || 0) || 0) *
              (accountCapacity[dateKey] || 0),
          0
        );
        totalsHash[accountId] = accountTotalCapacity;
        return totalsHash;
      }, {}),
    [
      OOOProject,
      accountCapacities,
      accountIds,
      dateRangeFilter,
      fetchParams.end_date,
      fetchParams.start_date,
      weekDayOccurencesMinusHolidays
    ]
  );
  return accountCapacityTotals;
};

export const useAccountCapacitySum = ({
  accountIds = emptyArray,
  fetchParams = emptyObj
}) => {
  const accountCapacityTotals = useAccountCapacityTotals({
    accountIds,
    fetchParams
  });
  return sum(Object.values(accountCapacityTotals));
};
