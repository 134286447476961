import type {
  BaseTableColumn,
  BaseTableListItem
} from 'components/Table/types';
import merge from 'lodash/merge';
import { ValueOf } from 'type-fest';
import {
  ReasonTitleCell,
  ReasonHoursCell,
  ActivityPhaseCollapseCell,
  PhaseCollapseCell,
  MemberTitleCell,
  MemberHoursCell,
  PhaseTitleCell,
  PhaseMembershipDateCell,
  PhaseHoursCell,
  MemberOptionCell,
  MemberNoMatchCell,
  MemberAlternatesTitleCell,
  ActivityPhaseTitleCell,
  ActivityPhaseHoursCell,
  ActivityPhaseMembershipDateCell
} from '../Cells';

export enum FindPeopleTableVariantOptions {
  MemberSuggestionByPhase = 'MemberSuggestionByPhase'
}

export type FindPeopleTableVariantType = ValueOf<
  typeof FindPeopleTableVariantOptions
>;

const EmptyDiv = () => {
  return <></>;
};

/* ------------------------------ rows / cells ------------------------------ */

export const ROW_TYPES = {
  phaseGroupRow: 'phaseGroupRow',
  activityPhaseGroupRow: 'activityPhaseGroupRow',
  memberGroupRow: 'memberGroupRow',
  reasonRow: 'reasonRow',
  noMatchRow: 'noMatchRow',
  memberAlternatesRow: 'memberAlternatesRow',

  /* ---------------------------------- Main row above ---------------------------------- */
  loadingRow: 'loadingRow'
};

export const noMatchRow: BaseTableListItem = {
  rowType: ROW_TYPES.noMatchRow,
  rowHeight: 40
};

const phaseGroupHeaderRowCells = {
  collapse: PhaseCollapseCell,
  title: PhaseTitleCell,
  date: PhaseMembershipDateCell,
  hours: PhaseHoursCell,
  option: EmptyDiv
};

const activityPhaseGroupHeaderRowCells = {
  collapse: ActivityPhaseCollapseCell,
  title: ActivityPhaseTitleCell,
  date: ActivityPhaseMembershipDateCell,
  hours: ActivityPhaseHoursCell,
  option: EmptyDiv
};

const memberGroupHeaderRowCells = {
  collapse: EmptyDiv,
  title: MemberTitleCell,
  date: EmptyDiv,
  hours: MemberHoursCell,
  option: MemberOptionCell
};

const noMatchHeaderRowCells = {
  collapse: EmptyDiv,
  title: MemberNoMatchCell,
  date: EmptyDiv,
  hours: EmptyDiv,
  option: EmptyDiv
};

const memberAlternatesRowHeaderRowCells = {
  collapse: EmptyDiv,
  title: MemberAlternatesTitleCell,
  date: EmptyDiv,
  hours: EmptyDiv,
  option: EmptyDiv
};

const reasonHeaderRowCells = {
  collapse: EmptyDiv,
  title: ReasonTitleCell,
  date: EmptyDiv,
  hours: ReasonHoursCell,
  option: EmptyDiv
};

const loadingRowCells = {
  collapse: EmptyDiv,
  title: EmptyDiv,
  date: EmptyDiv,
  hours: EmptyDiv,
  option: EmptyDiv
};

/* --------------------------------- columns -------------------------------- */

const collapseColumn: BaseTableColumn = {
  headerType: 'collapse',
  id: 'collapse'
};

const titleColumn: BaseTableColumn = {
  headerType: 'title',
  id: 'title'
};

const dateColumn: BaseTableColumn = {
  headerType: 'date',
  id: 'date'
};

const hoursColumn: BaseTableColumn = {
  headerType: 'hours',
  id: 'hours'
};

const optionColumn: BaseTableColumn = {
  headerType: 'option',
  id: 'option'
};
/* --------------------------------- configs -------------------------------- */
// currently only one variant, so only configs.shared is used
export const getTableConfig = ({
  variant
}: {
  variant: FindPeopleTableVariantType;
}) => {
  const configs = {
    shared: {
      variant
    },
    [FindPeopleTableVariantOptions.MemberSuggestionByPhase]: {
      tableProps: {
        columnWidthsByHeaderType: {
          [collapseColumn.headerType]: 35,
          [titleColumn.headerType]: 390,
          [dateColumn.headerType]: 130,
          [hoursColumn.headerType]: 110,
          [optionColumn.headerType]: 65
        },
        tableColumns: [
          collapseColumn,
          titleColumn,
          dateColumn,
          hoursColumn,
          optionColumn
        ],
        rowTypeToCellsByHeaderType: {
          [ROW_TYPES.phaseGroupRow]: phaseGroupHeaderRowCells,
          [ROW_TYPES.activityPhaseGroupRow]: activityPhaseGroupHeaderRowCells,
          [ROW_TYPES.memberGroupRow]: memberGroupHeaderRowCells,
          [ROW_TYPES.reasonRow]: reasonHeaderRowCells,
          [ROW_TYPES.noMatchRow]: noMatchHeaderRowCells,
          [ROW_TYPES.loadingRow]: loadingRowCells,
          [ROW_TYPES.memberAlternatesRow]: memberAlternatesRowHeaderRowCells
        },
        rowTypesHash: ROW_TYPES,
        maxHeight: window.innerHeight,
        showHeader: true,
        emptyRow: {
          rowType: 'emptyRow',
          rowHeight: 5,
          isDragDisabled: true
        },
        hasStickyHeader: true
      },
      isListsExpandedByDefault: true
    }
  };

  const config = configs[variant];

  // in the case of conflicting properties, config takes precedence
  const mergedConfig = merge({}, configs.shared, config);

  return mergedConfig;
};
