import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import LevelIndicator from 'components/LevelIndicator';
import KaratRight from 'icons/KaratRight';
import {
  SKILL_LEVELS,
  NUM_SKILLS,
  SKILL_LEVEL_TO_COLOR
} from 'appConstants/skills';

const ArrowDown = styled(KaratRight)`
  transform: rotate(90deg);
  height: 8px;
  width: 8px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  span {
    color: ${theme.colors.colorSemiDarkGray1};
    font-size: 13px;
    margin-right: 6px;
  }
  ${ArrowDown} {
    visibility: hidden;
  }
  &:hover {
    cursor: pointer;
    span {
      color: ${theme.colors.colorCalendarBlue};
    }
    ${ArrowDown} {
      visibility: visible;
    }
  }
`;

const SkillLevelContainer = styled.div``;

const SkillLevelIndicator = ({ level, targetRef, onClick }) => {
  return (
    <SkillLevelContainer onClick={onClick} ref={targetRef}>
      <LevelIndicator
        level={level}
        numLevels={NUM_SKILLS}
        levelToColor={SKILL_LEVEL_TO_COLOR}
      />
      <Label>
        <span>{SKILL_LEVELS[level]}</span>
        <ArrowDown />
      </Label>
    </SkillLevelContainer>
  );
};

export default SkillLevelIndicator;
