import { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import theme from 'theme';

import { connect } from 'react-redux';
import styled from 'styled-components';
import SortOptions from './SortOptions';
import {
  makeGetActiveWorkloadPlannerFilter,
  getMyWorkPlanSettings
} from 'selectors';
import { updateAccountFilterLocal, fetchAccountFilter } from 'actionCreators';
import TwoWaySortIcon from 'icons/TwoWaySortIcon';
import { FILTER_VALUES, SORT_BY, SORT_ORDER } from 'appConstants/filters';
import {
  GET_WORKLOAD_FETCH_END_DATE_MODIFIERS,
  GET_MEMBER_WORKLOAD_FETCH_END_DATE_MODIFIERS
} from 'appUtils/projectPlannerUtils';
import { VIEW_BY } from 'appConstants/workload';
import KaratRight from 'icons/KaratRight';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('YYYY-MM-DD');

const AvailabilityLabel = styled.span`
  flex: 1;
  text-align: left;
  color: ${theme.colors.colorSemiDarkGray1};
`;

const membersAvailabilityDisplay = {
  today: {
    value: SORT_BY.today,
    label: 'Today'
  },
  this_week: {
    value: SORT_BY.this_week,
    label: 'This Week'
  },
  next_two_weeks: {
    value: SORT_BY.next_two_weeks,
    label: 'Next 2 Weeks'
  },
  next_four_weeks: {
    value: SORT_BY.next_four_weeks,
    label: 'Next 4 Weeks'
  },
  custom: {
    value: SORT_BY.custom,
    label: 'Custom'
  }
};
export const display = {
  projects: {
    project_number: {
      value: SORT_BY.project_number,
      label: 'Project Number'
    },
    title: {
      value: SORT_BY.alphabetical,
      label: 'Project Title A-Z'
    },
    active_phase_schedule_bar: {
      value: SORT_BY.active_phase_schedule_bar,
      label: 'Active Work Plan'
    },
    active_project: {
      value: SORT_BY.active_project,
      label: 'Active Project'
    },
    active_phase: {
      value: SORT_BY.active_phase,
      label: 'Active Phase'
    }
  },
  members: {
    name: {
      value: SORT_BY.name,
      label: 'Alphabetical'
    },
    availability: {
      value: 'availability',
      label: (
        <>
          <AvailabilityLabel>Availability</AvailabilityLabel>
          <KaratRight />
        </>
      ),
      display: membersAvailabilityDisplay,
      options: [
        membersAvailabilityDisplay.today,
        membersAvailabilityDisplay.this_week,
        membersAvailabilityDisplay.next_two_weeks,
        membersAvailabilityDisplay.next_four_weeks,
        membersAvailabilityDisplay.custom
      ]
    }
  }
};
const options = {
  projects: [
    display.projects.title,
    display.projects.project_number,
    display.projects.active_phase_schedule_bar,
    display.projects.active_project,
    display.projects.active_phase
  ],
  members: [display.members.name, display.members.availability]
};

const StyledWorkloadSortToggle = styled.div`
  font-size: 12px;
`;

const WorkloadSortToggle = () => (
  <StyledWorkloadSortToggle>
    <TwoWaySortIcon /> Sort
  </StyledWorkloadSortToggle>
);

const WorkloadSortOptions = ({
  updateAccountFilterLocal,
  fetchAccountFilter,
  activeFilter,
  zoom,
  workloadViewBy,
  workloadSettings
}) => {
  const calendarTarget = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const alphabeticalSortType =
    workloadViewBy === VIEW_BY.PROJECTS ? SORT_BY.alphabetical : SORT_BY.name;
  const handleCustomRangeSubmit = ({ startDate, endDate }) => {
    if (
      !startDate?.isValid?.() &&
      !endDate?.isValid?.() &&
      !moment(endDate).isBefore(moment(startDate))
    ) {
      return;
    }
    setCalendarOpen(false);
    setViewBy({
      viewBy: 'custom',
      customStartDate: startDate,
      customEndDate: endDate
    });
  };

  const setViewBy = ({ viewBy, customStartDate, customEndDate }) => {
    if (viewBy === 'custom' && !customStartDate) {
      setCalendarOpen(true);
      return;
    }
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        ...activeFilter?.custom,
        sort: viewBy
      }
    });
    if (
      activeFilter?.id &&
      activeFilter.id !== 'new' &&
      workloadViewBy === VIEW_BY.PROJECTS
    ) {
      fetchAccountFilter({
        id: activeFilter.id,
        body: {
          filter_sort_attributes: [
            {
              filter_value: FILTER_VALUES.project_ids,
              start_date: formatFetchDate(moment()),
              end_date: formatFetchDate(
                moment().add(...GET_WORKLOAD_FETCH_END_DATE_MODIFIERS(zoom))
              ),
              sort_attributes: [
                {
                  attribute: viewBy,
                  direction: SORT_ORDER.asc
                }
              ]
            }
          ]
        }
      });
    } else if (
      activeFilter?.id &&
      activeFilter.id !== 'new' &&
      workloadViewBy === VIEW_BY.MEMBERS
    ) {
      fetchAccountFilter({
        id: activeFilter.id,
        body: {
          filter_sort_attributes: [
            {
              filter_value: FILTER_VALUES.account_ids,
              start_date:
                viewBy !== 'custom'
                  ? formatFetchDate(moment())
                  : formatFetchDate(customStartDate),
              end_date:
                viewBy !== 'custom'
                  ? formatFetchDate(
                      GET_MEMBER_WORKLOAD_FETCH_END_DATE_MODIFIERS(viewBy)
                    )
                  : formatFetchDate(customEndDate),
              sort_attributes: [
                {
                  attribute: display.members.availability.options.some(
                    (option) => option.value === viewBy
                  )
                    ? workloadSettings?.display_member_capacity === 'percent'
                      ? 'available_percentage'
                      : 'available_hours'
                    : viewBy,
                  direction:
                    viewBy === 'name' ? SORT_ORDER.asc : SORT_ORDER.desc
                }
              ]
            }
          ]
        }
      });
    }
  };

  return (
    <div ref={calendarTarget}>
      <SortOptions
        viewBy={activeFilter?.custom?.sort || alphabeticalSortType}
        setViewBy={setViewBy}
        options={options[workloadViewBy]}
        dropdownButtonStyle={
          'font-size: 12px; padding: 0; .btn { padding: 0 !important; }'
        }
        headerText={'Sort By'}
        display={display[workloadViewBy]}
        renderToggle={WorkloadSortToggle}
        noBoldToggle
      />
      {calendarOpen && (
        <DateRangeCalendar
          customInput={() => null}
          startOpen
          target={calendarTarget}
          onClose={() => setCalendarOpen(false)}
          onSubmit={handleCustomRangeSubmit}
        />
      )}
    </div>
  );
};

const makeMapStateToProps = () => {
  const getActiveFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveFilter(state, ownProps),
    workloadSettings: getMyWorkPlanSettings(state)
  });
  return mapStateToProps;
};

export default withRouter(
  connect(makeMapStateToProps, {
    updateAccountFilterLocal,
    fetchAccountFilter
  })(WorkloadSortOptions)
);
