import React from 'react';
import styled from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import ColorPicker from 'components/ColorPicker';
import { ColorPickerHoverArea } from 'components/ColorPicker/styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

export const StyledDot = styled.div`
  height: ${(props) => props.size || 8}px;
  width: ${(props) => props.size || 8}px;
  /* flex: 0 0 8px; */
  border-radius: 50%;
  background: ${calculateProjectColorPreference};
  position: absolute;
`;

export const StyledDotContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.marginRight || '6px'};
  .color-picker-container {
    visibility: ${(props) => (props.hasColorPicker ? 'visible' : 'hidden')};
  }
`;

const ProjectColorDot = ({ projectId, pickerLocation, ...props }) => {
  return (
    <StyledDotContainer {...props}>
      <ColorPickerHoverArea>
        <ColorPicker
          projectId={projectId}
          id={projectId}
          originType={ORIGIN_TYPE_STRINGS.PROJECT}
          row
          className="color-picker-container"
          stopPropagation
          pickerLocation={pickerLocation}
        />
      </ColorPickerHoverArea>
      <StyledDot {...props} projectId={projectId} />
    </StyledDotContainer>
  );
};

export default ProjectColorDot;
