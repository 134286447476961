import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const SyncFailedIcon = ({
  width = '10',
  height = '10',
  className,
  color = theme.colors.colorCalendarRed
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fillRule="none"
  >
    <path
      d="M11.5196 5.1217L11.6996 3.1417C11.7235 2.9256 11.4835 2.7578 11.2918 2.8656L10.7396 3.1417C9.83958 1.42606 8.05182 0.261699 5.99958 0.261699C3.58734 0.261699 1.54734 1.85782 0.875703 4.05394C0.791796 4.33004 1.00789 4.61784 1.2957 4.61784C1.48789 4.61784 1.6557 4.48565 1.7157 4.30565C2.27961 2.47001 3.98346 1.13789 5.99958 1.13789C7.7157 1.13789 9.2157 2.11008 9.95958 3.52565L9.39568 3.81346C9.19177 3.90955 9.19177 4.19736 9.37177 4.30565L11.0757 5.33785C11.2674 5.44613 11.4957 5.32613 11.5196 5.12175L11.5196 5.1217Z"
      fill={color}
    />
    <path
      d="M6.0005 11.3522C8.41274 11.3522 10.4527 9.75605 11.1244 7.55993C11.2083 7.28384 10.9922 6.99603 10.7044 6.99603C10.5122 6.99603 10.3444 7.12822 10.2844 7.30822C9.72048 9.14386 8.01662 10.476 6.0005 10.476C4.28438 10.476 2.78438 9.50379 2.0405 8.08822L2.60441 7.80041C2.80831 7.70432 2.80831 7.41651 2.62831 7.30822L0.912675 6.27603C0.732675 6.16822 0.504867 6.28822 0.480483 6.49212L0.300483 8.48388C0.276576 8.69998 0.516579 8.86779 0.708291 8.75998L1.26048 8.48388C2.16048 10.1878 3.94824 11.3521 6.00048 11.3521L6.0005 11.3522Z"
      fill={color}
    />
    <path
      d="M6.58204 6.47159C6.5625 6.80022 6.23438 6.92582 6 6.92582C5.7375 6.92582 5.44062 6.81728 5.41796 6.47159L5.21796 3.61031C5.21796 3.27314 5.56796 3 6 3C6.43126 3 6.78204 3.27314 6.78204 3.61031L6.58204 6.47159Z"
      fill={color}
    />
    <path
      d="M5.29218 7.66905C5.47812 7.52332 5.73594 7.43919 6 7.43919C6.26406 7.43919 6.5203 7.52333 6.70624 7.66905C6.89375 7.81353 7 8.01535 7 8.21959C7 8.42506 6.89374 8.62687 6.70624 8.77198C6.52188 8.91769 6.26406 9 6 9C5.73594 9 5.47812 8.91709 5.29218 8.77198C5.10625 8.62687 5 8.42567 5 8.21959C5 8.01474 5.10624 7.81354 5.29218 7.66905Z"
      fill={color}
    />
  </SvgIcon>
);

export default SyncFailedIcon;
