import { put } from 'redux-saga/effects';
import { removeRequestStatusId, setRequestStatus } from 'actionCreators';
import {
  getIsTriggerAction,
  getIsFailureAction,
  getIsSuccessAction
} from 'appUtils/request/actions';
import { initialRequestStatusState } from 'reducers/statuses';
import { PayloadAction } from '@reduxjs/toolkit';

/**
 * For automated request statuses. See reducers/statuses.ts
 */
export function* handleRequestStatusWorker(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: PayloadAction<any, string, any>
) {
  // ignore requestStatus related actions
  if (
    ![setRequestStatus.type, removeRequestStatusId.type].includes(action.type)
  ) {
    // if the action has requestStatusId, find out if it is SUCCESS/FAILURE/TRIGGER
    // if it is SUCCESS or FAILURE, set loading status to false. If TRIGGER, set to true
    const requestStatusId: string | undefined =
      action.payload?.requestStatusId ||
      action.payload?.requestPayload?.requestStatusId ||
      action.meta?.requestStatusId ||
      action.payload?.initialPayload?.requestStatusId;
    if (requestStatusId) {
      const actionType = action.type || '';
      const isSuccessAction = getIsSuccessAction(actionType);
      const isFailureAction = getIsFailureAction(actionType);
      if (actionType && (isSuccessAction || isFailureAction)) {
        yield put(
          setRequestStatus({
            requestStatusId,
            ...initialRequestStatusState,
            ...(isSuccessAction && { isSuccess: true }),
            ...(isFailureAction && { error: action.payload.error })
          })
        );
      } else if (actionType && getIsTriggerAction(actionType)) {
        // if payload includes 'initial: true', set isLoading to true to indicate 'first loading'
        // matches RTK query terminology
        yield put(
          setRequestStatus({
            requestStatusId,
            ...initialRequestStatusState,
            isExecuting: true,
            ...((action.payload.initial || action.meta?.isInitialFetch) && {
              isLoading: true
            })
          })
        );
      }
    }
  }
}
