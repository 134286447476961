import styled from 'styled-components';
import { BaseTableColumn } from 'components/Table/types';
import theme from 'theme';
import { MemberHeaderListType } from '../types';
import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { membersTimesheetSettingsTableHelpers } from '../helpers/layout';
import { EmptyDiv } from 'components/EmptyDiv';

interface MembersPermissionsTableHeaderCellProps {
  column: BaseTableColumn;
  row: { original: MemberHeaderListType };
}

export const MembersPermissionsTableHeaderCell = ({
  column,
  row
}: MembersPermissionsTableHeaderCellProps) => {
  const { employmentType } = row.original;

  const isHideContent =
    employmentType === EMPLOYMENT_TYPES.projectGuest &&
    column.id ===
      membersTimesheetSettingsTableHelpers.columnTypes.timeEntrySetting;

  if (isHideContent) return <EmptyDiv />;

  return (
    <StyledHeaderCell style={{ justifyContent: column.align }}>
      <span>{column.headerLabel}</span>
    </StyledHeaderCell>
  );
};

const StyledHeaderCell = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
