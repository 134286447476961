import { fork, takeLatest, takeEvery, all } from 'redux-saga/effects';
import {
  fetchSyncSettingsWorker,
  updateSyncSettingsWorker,
  fetchImportedEmployeesWorker,
  fetchImportedActivitiesWorker,
  fetchImportedSubcustomersWorker,
  syncEmployeesWorker,
  syncSubcustomersToProjectsWorker,
  syncTimeEntriesWorker,
  syncServicesWorker,
  fetchSyncedMembersWorker,
  fetchSyncedSubcustomersWorker,
  fetchSyncedServicesWorker,
  updateSyncStatusWorker,
  deleteSyncedServicesWorker,
  deleteSyncedMembersWorker,
  deleteSyncedSubcustomerWorker,
  fetchSubSubCustomersWorker,
  fetchSyncedSubSubCustomersWorker,
  syncSubSubCustomersWorker,
  fetchCustomersWorker,
  qbSearchChangeWorker,
  createNewActivities,
  cancelSyncStatusWorker
} from './quickbooks';
import * as constants from '../constants';

export default function* quickbooksSaga() {
  const quickbooksSagas = [
    yield fork(
      takeEvery,
      constants.FETCH_SYNC_SETTINGS.TRIGGER,
      fetchSyncSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_SYNC_SETTINGS.TRIGGER,
      updateSyncSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_QB_MEMBERS.TRIGGER,
      fetchImportedEmployeesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SERVICES.TRIGGER,
      fetchImportedActivitiesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SUBCUSTOMERS.TRIGGER,
      fetchImportedSubcustomersWorker
    ),
    yield fork(takeEvery, constants.SYNC_MEMBERS.TRIGGER, syncEmployeesWorker),
    yield fork(
      takeEvery,
      constants.SYNC_SUBCUSTOMERS_TO_PROJECTS.TRIGGER,
      syncSubcustomersToProjectsWorker
    ),
    yield fork(
      takeEvery,
      constants.SYNC_TIME_ENTRIES.TRIGGER,
      syncTimeEntriesWorker
    ),
    yield fork(
      takeEvery,
      constants.SYNC_ACTIVITIES.TRIGGER,
      syncServicesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SYNCED_MEMBERS.TRIGGER,
      fetchSyncedMembersWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SYNCED_SUBCUSTOMERS.TRIGGER,
      fetchSyncedSubcustomersWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SYNCED_SERVICE.TRIGGER,
      fetchSyncedServicesWorker
    ),
    yield fork(
      takeLatest,
      constants.UPDATE_SYNC_STATUS.TRIGGER,
      updateSyncStatusWorker
    ),
    yield fork(
      takeLatest,
      constants.DELETE_SYNCED_ACTIVITIES.TRIGGER,
      deleteSyncedServicesWorker
    ),
    yield fork(
      takeLatest,
      constants.DELETE_SYNCED_EMPLOYEES.TRIGGER,
      deleteSyncedMembersWorker
    ),
    yield fork(
      takeLatest,
      constants.DELETE_SYNCED_SUBCUSTOMERS.TRIGGER,
      deleteSyncedSubcustomerWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SUB_SUB_CUSTOMERS.TRIGGER,
      fetchSubSubCustomersWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SYNCED_SUB_SUB_CUSTOMERS.TRIGGER,
      fetchSyncedSubSubCustomersWorker
    ),
    yield fork(
      takeEvery,
      constants.SYNC_SUB_SUB_CUSTOMERS.TRIGGER,
      syncSubSubCustomersWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_CUSTOMERS.TRIGGER,
      fetchCustomersWorker
    ),
    yield fork(
      takeEvery,
      constants.QB_SEARCH_CHANGE_TRIGGER,
      qbSearchChangeWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_NEW_ACTIVITIES_IN_BATCH.TRIGGER,
      createNewActivities
    ),
    yield fork(takeEvery, constants.CANCEL_SYNC_STATUS, cancelSyncStatusWorker)
  ];
  yield all(quickbooksSagas);
}
