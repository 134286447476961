import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFlatPhasesAndMilestonesHash,
  getProjectHash,
  getTheme,
  getOOOProject,
  getSelectedTeamId
} from 'selectors';
import { getGoogleCalendarEventsArray } from 'EventsModule/selectors';
import {
  fetchGoogleCalendarEvents,
  updateGoogleCalendarEvents
} from 'EventsModule/actionCreators';
import {
  formatIntegrationEventCalendarTooltip,
  generateIntegrationEvent,
  getIntegrationEventStartAndEndDateParams
} from 'EventsModule/utils';
import { getEventsModule } from 'EventsModule/package/eventsModule';
import { IntegrationEventTimelineItem } from 'EventsModule/components/Timeline/IntegrationEventTimelineItem';
import { fetchWorkloadPlanner } from 'actionCreators';

import moment from 'appUtils/momentConfig';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';

import { CALENDAR_EVENT_TYPE } from './constants';
import {
  groupAndSortEvents,
  generatePhaseScheduleEvent,
  formatDate,
  formatPhaseCalendarTooltip
} from './utils';
import { PhaseScheduleTimelineItem } from './PhaseScheduleTimelineItem';
import { EventTimeline } from './EventTimeline';
import { EventCalendar } from './EventCalendar';
import { getBackgroundEvents } from '../schedule/scheduleSelectors';
import {
  fetchHolidays,
  fetchTeamCapacity
} from 'CapacityModule/actionCreators';
import { getTeamCapacityId } from 'CapacityModule/selectors';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { Calendar } from 'components/ScheduleCalendar/Calendar';
import { useFetchPhasesThroughLeanApiForCalendar } from 'DashboardModule/hooks/useFetchPhasesThroughLeanApiForCalendar';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import uniq from 'lodash/uniq';

const MemberModalCalendar = ({ accountId, shouldUseWhiteBackground }) => {
  const filterId = `memberCalendar-${accountId}`;
  const googleCalendarRequestStatusId = `google-calendar-${accountId}`;
  const dispatch = useDispatch();
  const projectHash = useSelector(getProjectHash);
  const { googleCalendarEventsFlag } = useFeatureFlags();
  const phasesAndMilestonesHash = useSelector(getFlatPhasesAndMilestonesHash);
  const googleCalendarEvents = useSelector(getGoogleCalendarEventsArray);
  const teamId = useSelector(getSelectedTeamId);
  const teamCapacityId = useSelector(getTeamCapacityId);
  const { projectColors } = useSelector(getTheme);

  const { status: googleCalendarRequestStatus } = useRequestStatus({
    requestStatusId: googleCalendarRequestStatusId
  });
  const { isExecuting: isFetchingGoogleCalendarEvents } =
    googleCalendarRequestStatus || {};

  const PTOProject = useSelector(getOOOProject);

  const backgroundEvents = useSelector((state) =>
    // when it's on Home, accountId is number type not string.
    getBackgroundEvents(state, { memberId: accountId && accountId.toString() })
  );
  const backgroundEventWithOverlap = useMemo(
    () => backgroundEvents.map((item) => ({ ...item, eventOverlap: true })),
    [backgroundEvents]
  );

  const calendarRef = useRef(new Calendar({ initialViewType: 'month' }));

  const projectIds = useMemo(
    () => uniq(googleCalendarEvents, (event) => event.projectId),
    [googleCalendarEvents]
  );

  const { isFetchingPhases, isFetchingProjects } = useFetchUnloadedProjects({
    projectIds
  });

  const { fetchPhasesWithinRange, fetchStatus, leanApiIsoState } =
    useFetchPhasesThroughLeanApiForCalendar({
      isoStateId: filterId
    });

  const {
    error: isLeanApiPhaseFetchFailed,
    isLoading: isLeanApiPhaseFetching
  } = fetchStatus || {};

  const currentIntervalPhaseIds = useMemo(() => {
    const { start, end } = calendarRef.current.getRange();
    const startDate = formatDate(start);
    const formattedEnd = formatDate(end);
    return (
      leanApiIsoState.ordersByGroup[
        `${formatDate(startDate)} - ${formatDate(formattedEnd)}`
      ] || []
    );
  }, [leanApiIsoState.ordersByGroup]);

  const { partialProjects, partialPhases } = leanApiIsoState.dataHash;

  /**
   * @type {{[eventId:string]: any}}
   */
  const [eventsHash, setEventHash] = useState({});

  const eventsDataGroupByMonth = useMemo(
    () =>
      flow(groupBy(({ date }) => getMonthKey(date)))(Object.values(eventsHash)),
    [eventsHash]
  );
  const [currentMonthKey, setCurrentMonthKey] = useState();

  const eventsData = useMemo(
    () =>
      eventsDataGroupByMonth[currentMonthKey]
        ? groupAndSortEvents(eventsDataGroupByMonth[currentMonthKey])
        : [],
    [currentMonthKey, eventsDataGroupByMonth]
  );

  const handleSetDate = useCallback(
    ({ start, end, currentDate }) => {
      setCurrentMonthKey(getMonthKey(currentDate));

      const formattedStart = formatDate(start);
      const formattedEnd = formatDate(end);

      // fetch phases through lean api
      const startDateForLeanApi = new Date(start);
      const endDateForLeanApi = new Date(end);
      fetchPhasesWithinRange({
        startDate: startDateForLeanApi,
        endDate: endDateForLeanApi,
        accountId
      });

      dispatch(
        fetchWorkloadPlanner({
          startDate: formattedStart,
          endDate: formattedEnd,
          accountId: accountId,
          project_ids: [PTOProject?.id],
          permissions: { teamId },
          team_member_ids: [accountId],
          all: true
        })
      );
    },
    [PTOProject?.id, accountId, dispatch, fetchPhasesWithinRange, teamId]
  );

  const getPhaseScheduleEventById = useCallback(
    (eventId) => {
      const event = { ...eventsHash[eventId] };
      const projectId = event.data.phase?.project_id;
      // fetched phases will use partialProjects hash but google events will use projectHash
      event.data.project = partialProjects[projectId] || projectHash[projectId];
      return event;
    },
    [eventsHash, partialProjects, projectHash]
  );

  /* ----------------------------- Google Calendar ---------------------------- */

  const getGoogleCalendarEventById = useCallback(
    (eventId) => {
      return eventsHash[eventId];
    },
    [eventsHash]
  );

  const associateMosaicProjectAndPhaseWithEvent = useCallback(
    (selectedProjectAndPhase, event) => {
      const { id } = event.data;
      const { projectId, phaseId } = selectedProjectAndPhase;
      const payload = {
        calendar_event_id: id,
        project_id: projectId,
        phase_id: phaseId,
        account_ids: [accountId]
      };
      dispatch(updateGoogleCalendarEvents(payload));
    },
    [dispatch, accountId]
  );

  const renderItem = useCallback(
    (event, index) => {
      const { eventId } = event;
      const { GOOGLE_CALENDAR } = CALENDAR_EVENT_TYPE.INTEGRATION_EVENT;

      switch (event.type) {
        case CALENDAR_EVENT_TYPE.PHASE_SCHEDULE:
          return (
            <PhaseScheduleTimelineItem
              event={getPhaseScheduleEventById(eventId)}
            />
          );

        case GOOGLE_CALENDAR.type:
          return (
            <IntegrationEventTimelineItem
              event={getGoogleCalendarEventById(eventId)}
              accountId={accountId}
              associateMosaicProjectAndPhaseWithEvent={
                associateMosaicProjectAndPhaseWithEvent
              }
            />
          );
        default: {
          console.error('event type is invalid');
          return <></>;
        }
      }
    },
    [
      accountId,
      associateMosaicProjectAndPhaseWithEvent,
      getGoogleCalendarEventById,
      getPhaseScheduleEventById
    ]
  );

  const renderTooltip = useCallback(
    ({ dateObj, events }) => {
      const tooltips = events.reduce((acc, event) => {
        const {
          type,
          data: { phase, project_id },
          eventId
        } = event;
        const projectId = phase?.project_id || project_id;
        const project = projectHash[projectId];
        const { INTEGRATION_EVENT, PHASE_SCHEDULE } = CALENDAR_EVENT_TYPE;
        const { GOOGLE_CALENDAR } = INTEGRATION_EVENT;

        switch (type) {
          case PHASE_SCHEDULE: {
            if (project) {
              const tooltip = formatPhaseCalendarTooltip({
                phase,
                project,
                eventId
              });
              acc.push(tooltip);
            }
            break;
          }
          case GOOGLE_CALENDAR.type: {
            const tooltip = formatIntegrationEventCalendarTooltip({
              phase,
              project,
              event
            });
            acc.push(tooltip);
            break;
          }
        }
        return acc;
      }, []);
      if (tooltips.length) {
        return { data: tooltips };
      }
    },
    [projectHash]
  );

  useEffect(() => {
    let newPhaseEvents = {};

    // Mosaic Phase events
    if (currentIntervalPhaseIds.length && PTOProject?.id) {
      newPhaseEvents = currentIntervalPhaseIds.reduce((acc, id) => {
        const phase = partialPhases[id] || {};

        const isNotPTOProject = phase.project_id !== PTOProject.id;

        if (phase && isNotPTOProject) {
          const event = generatePhaseScheduleEvent({ phase });
          event.displayColor = projectColors[phase.project_id];
          const key = event.eventId;
          acc[key] = event;
        }
        return acc;
      }, {});
    }

    // Google Calendar (Integration) events
    const newGoogleCalendarEvents = googleCalendarEvents.reduce(
      (acc, event) => {
        const { project_id, phase_id } = event;
        const project = projectHash[project_id];
        const phase = phasesAndMilestonesHash[phase_id];
        const { idSuffix, type } =
          CALENDAR_EVENT_TYPE.INTEGRATION_EVENT.GOOGLE_CALENDAR;

        const googleCalendarEvent = generateIntegrationEvent({
          event,
          project,
          phase,
          type,
          projectColors,
          eventIdSuffix: idSuffix
        });
        acc[googleCalendarEvent.eventId] = googleCalendarEvent;

        return acc;
      },
      {}
    );

    setEventHash({
      ...newPhaseEvents,
      ...newGoogleCalendarEvents
    });
  }, [
    PTOProject?.id,
    googleCalendarEvents,
    phasesAndMilestonesHash,
    projectColors,
    projectHash,
    partialPhases,
    currentIntervalPhaseIds
  ]);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamCapacity({ teamId }));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (currentMonthKey && teamId && accountId && googleCalendarEventsFlag) {
      const { startOfMonth, endOfMonth } =
        getIntegrationEventStartAndEndDateParams(currentMonthKey);
      dispatch(
        fetchGoogleCalendarEvents({
          team_id: teamId,
          account_ids: [accountId],
          start_datetime: startOfMonth,
          end_datetime: endOfMonth,
          meta: {
            requestStatusId: googleCalendarRequestStatusId
          }
        })
      );
    }
  }, [
    currentMonthKey,
    dispatch,
    accountId,
    teamId,
    filterId,
    googleCalendarRequestStatusId,
    googleCalendarEventsFlag
  ]);

  useEffect(() => {
    if (teamCapacityId) {
      dispatch(fetchHolidays());
    }
  }, [dispatch, teamCapacityId]);

  useEffect(() => {
    rebuildTooltip();
  }, [currentIntervalPhaseIds, projectHash]);

  const isFetching =
    accountId &&
    teamId &&
    PTOProject &&
    (isFetchingGoogleCalendarEvents ||
      isLeanApiPhaseFetching ||
      isLeanApiPhaseFetchFailed ||
      isFetchingPhases ||
      isFetchingProjects);

  return (
    <DynamicModuleLoader modules={[getCapacitiesModule(), getEventsModule()]}>
      <RootContainer>
        <ScrollContainer>
          <Content>
            <HeaderContainer
              shouldUseWhiteBackground={shouldUseWhiteBackground}
            >
              <HeaderTitle>Calendar</HeaderTitle>
            </HeaderContainer>
            {/* TODO: implement tabs when we have other event types */}
            {/* <div>Tabs</div> */}

            <CalendarContainer>
              <EventCalendar
                data={eventsData}
                backgroundEvents={backgroundEventWithOverlap}
                onSetRange={handleSetDate}
                renderTooltip={renderTooltip}
                calendarRef={calendarRef.current}
              ></EventCalendar>
            </CalendarContainer>

            {isFetching ? (
              <SkeletonLoader
                numLoaders={8}
                loaderStyle={{ height: 70, rx: 4 }}
              />
            ) : (
              <EventTimeline
                data={eventsData}
                renderItem={renderItem}
                keyExtractor={(event) => event.eventId}
              ></EventTimeline>
            )}
          </Content>
        </ScrollContainer>
      </RootContainer>
    </DynamicModuleLoader>
  );
};

export default MemberModalCalendar;

const getMonthKey = (date) => moment(date).format('YYYY-MM');

const CALENDAR_CONTENT_WIDTH = '600px';

const RootContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

const ScrollContainer = styled.div`
  height: 100%;
  /* padding: 0 calc(20%); */
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 10vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${CALENDAR_CONTENT_WIDTH};
  height: fit-content;
  padding-bottom: 8vh;
`;

const CalendarContainer = styled.div`
  padding: 20px 0 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-self: flex-start;
  background: ${({ shouldUseWhiteBackground, theme }) =>
    shouldUseWhiteBackground
      ? theme.colors.colorPureWhite
      : theme.colors.colorTranslucentGray4};
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
`;

const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 24px;
  font-weight: 600;
`;
