import styled from 'styled-components';
import LockIcon from 'icons/LockIcon';

export const LockHour = () => (
  <StyledLockIconContainer
    data-for="app-tooltip"
    data-html
    data-effect="solid"
    data-tip="When locked, Total Hours will not be <br/> modified when Work Plan date changes. <br/> Set default in Settings."
  >
    <LockIcon width="10" height="11" fill="#4f4f4f" />
  </StyledLockIconContainer>
);

const StyledLockIconContainer = styled.div`
  margin-left: 2px;
  margin-top: -2px;
  transform: translateY(-0.5px);
`;
