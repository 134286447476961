import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import MyCheckbox from 'images/green-login-check.svg';
import EmptyCheckBox from 'images/empty-login-check.svg';
import LoadingWheel from 'icons/LoadingWheel';

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      keepLogged: true
    };
    bindAll(this, ['handleChange', 'formSubmit', 'handleKeepLoggedInClick']);
  }

  handleChange(event) {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleKeepLoggedInClick(event) {
    const name = event.target.name;
    this.setState({ keepLogged: !this.state[name] });
  }

  formSubmit(event) {
    event.preventDefault();
    const { handleSubmit, users } = this.props;
    const emailMatch = users.me && users.me.email === this.state.email;
    if (
      window.location.href.toLowerCase().includes('projects.mosaicapp.com') &&
      this.state.email &&
      this.state.email.toLowerCase().includes('@edgnyc.com')
    ) {
      window.location.replace('https://pilot.mosaicapp.com');
    }
    if (emailMatch && users.me.default_team && !users.me.default_team.id) {
      this.props.forceOnboard();
      return;
    }
    handleSubmit(this.state.email, this.state.password);
  }

  render() {
    const { errorMessage, goToSignup, auth } = this.props;

    return (
      <div className="input-login">
        <h3>Log In</h3>
        <form onSubmit={this.formSubmit}>
          <p className="action-link">
            New to Mosaic?
            <a className="signup-link" onClick={goToSignup}>
              {'Sign up for free'}
            </a>
          </p>
          <div
            className={cn('form-group form-input', {
              'has-danger': errorMessage
            })}
          >
            <input
              type="email"
              value={this.state.email}
              name="email"
              onChange={this.handleChange}
              placeholder=" Email"
              required
              className={cn('user-input', {
                'form-control-danger': errorMessage
              })}
            />
          </div>
          <div
            className={cn('form-group form-input', {
              'has-danger': errorMessage
            })}
          >
            <input
              type="password"
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              placeholder=" Password"
              required
              className={cn('user-input', {
                'form-control-danger': errorMessage
              })}
            />
          </div>
          <div className="login-options">
            <label htmlFor="keepLogged">
              {this.state.keepLogged ? (
                <img
                  src={MyCheckbox}
                  name="keepLogged"
                  className="keepLogged"
                  onClick={this.handleKeepLoggedInClick}
                />
              ) : (
                <img
                  src={EmptyCheckBox}
                  className="keepLogged"
                  onClick={this.handleKeepLoggedInClick}
                />
              )}
              &nbsp; Keep me logged in
            </label>
            <div className="forgot-password">
              <a id="forgot-password-link" href="/forgot">
                Forgot Password ?
              </a>
            </div>
          </div>
          <div className="button-container">
            <button type="submit">
              {auth && auth.isAuthenticating ? (
                <LoginContainer>
                  <LoadingWheel width={18} height={18} />
                </LoginContainer>
              ) : (
                'LOG IN'
              )}
            </button>
          </div>
        </form>
        {typeof errorMessage === 'string' && (
          <p className="incorrect-combo">{errorMessage}</p>
        )}
      </div>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};
