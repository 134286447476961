import React from 'react';
import { useSelector } from 'react-redux';
import {
  RateConfirmRow,
  RateConfirmBox,
  RateDateContainer,
  RateMemberDescription
} from 'SettingsModule/components/Budget/styles';
import DevProps from 'components/dev/DevProps';
import { getPositions } from 'BudgetModule/selectors/positions';
import moment from 'appUtils/momentConfig';

const UpdateRows = ({
  selectedDate,
  selectedDateType,
  roleHistory,
  roleMembershipBeingEdited,
  disableChangeEarliestRoleStart
}) => {
  const roleHash = useSelector(getPositions);

  const renderSelectedDate = () => (
    <div>{selectedDate?.clone().format('M/DD/YY')}</div>
  );

  const roleBeingEditedIndex = roleHistory.findIndex(
    (roleMembership) => roleMembership.id === roleMembershipBeingEdited.id
  );
  const earliestRoleMembership = roleHistory[roleHistory.length - 1];
  const earlierRoleMembership =
    selectedDateType === 'endDate'
      ? roleMembershipBeingEdited
      : roleHistory[roleBeingEditedIndex + 1];
  const laterRoleMembership =
    selectedDateType === 'startDate'
      ? roleMembershipBeingEdited
      : roleHistory[roleBeingEditedIndex - 1];

  const isEarliestRoleMembership =
    earlierRoleMembership === earliestRoleMembership;

  const renderRoleName = (roleMembershipBeingEdited) => {
    const role = roleHash[roleMembershipBeingEdited.position_id];
    return <RateMemberDescription>{role.name}</RateMemberDescription>;
  };

  const renderLaterRoleMembership = () => {
    return laterRoleMembership ? (
      <RateConfirmRow>
        {renderRoleName(laterRoleMembership)}
        <RateDateContainer>
          {renderSelectedDate()} -{' '}
          <div>
            {!laterRoleMembership.end_date ||
            laterRoleMembership.end_date === 'Present'
              ? 'Present'
              : moment(laterRoleMembership.end_date).format('M/DD/YY')}
          </div>
        </RateDateContainer>
      </RateConfirmRow>
    ) : null;
  };

  const renderEarlierRoleMembership = () => {
    const earlierRoleDisplayDate =
      isEarliestRoleMembership && disableChangeEarliestRoleStart
        ? 'Start'
        : moment(earlierRoleMembership?.start_date).format('M/DD/YY');
    return earlierRoleMembership ? (
      <RateConfirmRow>
        {renderRoleName(earlierRoleMembership)}
        <RateDateContainer>
          <div>{earlierRoleDisplayDate}</div> - {renderSelectedDate()}
        </RateDateContainer>
      </RateConfirmRow>
    ) : null;
  };

  return (
    <RateConfirmBox>
      <DevProps
        roleBeingEditedIndex={roleBeingEditedIndex}
        earliestRole={earliestRoleMembership}
        earlierRole={earlierRoleMembership}
        laterRole={laterRoleMembership}
      />
      {renderEarlierRoleMembership()}
      {renderLaterRoleMembership()}
    </RateConfirmBox>
  );
};

export default UpdateRows;
