import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import {
  toggleInviteButton,
  setMemberInputValue,
  toggleInviteForm
} from 'actionCreators';

class EditMemberListBehaviorContainer extends React.Component {
  constructor(props) {
    super(props);

    bindAll(this, ['changeInputValue', 'clearMemberFilter']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.addMembersForm.isOpen && !nextProps.addMembersForm.isOpen) {
      this.clearMemberFilter();
    }
  }

  clearMemberFilter() {
    const { toggleInviteButton, setMemberInputValue, toggleInviteForm } =
      this.props;

    setMemberInputValue('');
    toggleInviteButton(false);
    toggleInviteForm(false);
  }

  changeInputValue(eventOrText) {
    const { setMemberInputValue } = this.props;
    const inputValue = eventOrText.target
      ? eventOrText.target.value
      : eventOrText;

    setMemberInputValue(inputValue);
  }

  render() {
    return (
      <div>
        {React.cloneElement(this.props.children, {
          clearMemberFilter: this.clearMemberFilter,
          changeInputValue: this.changeInputValue,
          inputValue: this.props.inputValue
        })}
      </div>
    );
  }
}

EditMemberListBehaviorContainer.propTypes = {
  children: PropTypes.element.isRequired
};

const mapStateToProps = (state) => ({
  inputValue: state.settings.projectGroupMemberInputValue,
  addMembersForm: state.addMembersForm
});

const mapDispatchToProps = {
  toggleInviteButton,
  setMemberInputValue,
  toggleInviteForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMemberListBehaviorContainer);
