import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { useEffect, ReactNode, useMemo, useCallback } from 'react';
import { CURRENCY_LIST_UNIQUE } from 'CurrencyModule/constants/currencyList';
import styled from 'styled-components';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { getCurrencySymbolByCode } from '../utils';
import { Currency, CurrencyCode } from '../types';
import EllipsisText from 'components/EllipsisText';
import cn from 'classnames';

const isCurrency = <LeadingItemType extends Record<string, unknown> = never>(
  item: Currency | LeadingItemType
): item is Currency => 'currencyCode' in item;

const handleFilter = <LeadingItemType extends Record<string, unknown> = never>(
  item: Currency | LeadingItemType,
  searchWords: string[]
): boolean =>
  !isCurrency(item) ||
  !!filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: ['currencyCode', 'currencyName']
  });

export const CurrencyDropdown = <
  LeadingItemType extends Record<string, unknown> = never
>({
  isOpen,
  target,
  onClose,
  onSelect,
  HeaderButton,
  customHeader,
  popoverClassname = 'currency-item',
  leadingItems: leadingItemsObj,
  value
}: {
  isOpen: boolean;
  target: HTMLElement | SVGElement | null;
  onClose: () => void;
  onSelect: (value: { currencyCode: string } | LeadingItemType) => void;
  HeaderButton?: ReactNode;
  customHeader?: ReactNode;
  popoverClassname?: string;
  leadingItems?: {
    getId: (item: LeadingItemType) => string | number;
    items: LeadingItemType[];
    render: (item: LeadingItemType) => ReactNode;
  };
  value?: CurrencyCode;
}) => {
  type ListItem = Currency | LeadingItemType;

  const getLeadingItemId = leadingItemsObj?.getId;
  const leadingItems = leadingItemsObj?.items;
  const renderLeadingItem = leadingItemsObj?.render;

  const { MultiStepFlyout, openFlyout, closeFlyout } = useMultiStepFlyout();

  useEffect(() => {
    if (isOpen && target) openFlyout({ target });
    else closeFlyout();
  }, [isOpen, openFlyout, closeFlyout, target]);

  const handleClose = useCallback(() => {
    closeFlyout();
    onClose();
  }, [closeFlyout, onClose]);

  const handleSelect = useCallback(
    (_e: MouseEvent, { item }: { item: ListItem }) => {
      onSelect(isCurrency(item) ? { currencyCode: item.currencyCode } : item);
      handleClose();
    },
    [handleClose, onSelect]
  );

  const renderItem = useCallback(
    ({ item }: { item: ListItem }): ReactNode => {
      if (!isCurrency(item)) return <Item>{renderLeadingItem?.(item)}</Item>;

      const { currencyCode, currencyName } = item;
      const currencySymbol = getCurrencySymbolByCode({ currencyCode });
      const currencyString = `${currencyName} (${currencyCode})`;

      return (
        <CurrencyItem $selected={currencyCode === value}>
          <EllipsisText>{currencyString}</EllipsisText>
          <CurrencySymbol>{currencySymbol}</CurrencySymbol>
        </CurrencyItem>
      );
    },
    [renderLeadingItem]
  );

  const handleGetIdKey = useCallback(
    (item: ListItem) =>
      isCurrency(item)
        ? item.currencyCode
        : getLeadingItemId
        ? getLeadingItemId(item)
        : '',
    [getLeadingItemId]
  );

  const renderHeaderButton = useCallback((): ReactNode => {
    return (
      <HeaderButtonContainer onClick={handleClose}>
        {HeaderButton}
      </HeaderButtonContainer>
    );
  }, [HeaderButton, handleClose]);

  const items = useMemo(
    () => [...(leadingItems ?? []), ...CURRENCY_LIST_UNIQUE],
    [leadingItems]
  );

  return (
    <MultiStepFlyout
      copy={{
        headerInitial: customHeader ?? 'Select Currency',
        searchPlaceholder: 'Search or Select'
      }}
      items={items}
      idKey={handleGetIdKey}
      renderItem={renderItem}
      hideFooter
      editDisabled
      itemHeight={48}
      isWhite
      searchEnabled
      renderHeaderButton={renderHeaderButton}
      popoverClassName={popoverClassname}
      closePopover={handleClose}
      handleSelect={handleSelect}
      itemFilter={handleFilter}
      className="currency-list-flyout"
      target={target}
    />
  );
};

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Item = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  display: flex;
  padding: 15px 20px;
  width: 100%;
`;

const CurrencyItem = styled(Item).attrs<{ $selected: boolean }>(
  ({ $selected }) => ({ className: cn({ selected: $selected }) })
)<{ $selected: boolean }>`
  justify-content: space-between;

  &.selected {
    font-weight: 600;
  }
`;

const CurrencySymbol = styled.div`
  flex: none;
  width: fit-content;
`;
