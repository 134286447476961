import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { budgetAccessCheck } from 'BudgetModule/actionCreators';
import { getSelectedTeamId } from 'selectors';
import styled from 'styled-components';
import {
  StyledBar,
  StyledBarLabel,
  StyledBarLabelOverBudget,
  StyledBarSection,
  OverdueBudgetBarContainer,
  OverdueBar,
  StyledBarContainer,
  BarNumber,
  DateContainerBar,
  Today,
  TodayBar,
  BarLabelsContainer
} from './styles';
import PropTypes from 'prop-types';
import {
  PHASE_AND_PROJECT_BAR_CALCULATIONS,
  PHASE_AND_PROJECT_BAR_CALCULATIONS_HOURS
} from 'BudgetModule/constants';
import {
  isCostOverBudget,
  isHoursOverBudget
} from 'BudgetModule/utils/isOverBudget';
import moment from 'moment';
import { calculateBudgetDisplayPercentages } from 'appUtils/budgetTooltipContent';
import OverBudget from 'BudgetModule/components/BudgetTable/OverBudget';
import ReactTooltip from 'react-tooltip';
import CustomTooltip from 'ReportsModule/components/Profit/Charts/CustomTooltip';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import withIsHoursOnly from 'hocs/withIsHoursOnly';
import { formatBarPercent } from 'BudgetModule/utils';

const emptyObj = {};

// Have this component on same page as the bar
export const BudgetBarTooltip = () => {
  return (
    <ReactTooltip
      id="budget-bar-tooltip"
      type="light"
      getContent={(dataTip) => {
        const {
          total,
          text,
          totals,
          texts,
          formatType,
          isOverBudget = false,
          customDisplayData,
          title,
          rowTooltipCustomProps,
          headerType,
          viewBy
        } = JSON.parse(dataTip || '{}');
        return (
          <CustomTooltip
            total={total}
            totals={totals}
            texts={texts}
            name={text}
            isOverBudget={isOverBudget}
            formatType={formatType}
            customDisplayData={customDisplayData}
            title={title}
            projectId={totals?.original?.project_id}
            rowTooltipCustomProps={rowTooltipCustomProps}
            headerType={headerType}
            viewBy={viewBy}
          />
        );
      }}
    />
  );
};

/**
 * Spec on budget table is to show on entire row hover, instead of bar hover
 * The flag is used on most BudgetBarCell component to prevent
 * hover on individual bars
 *
 * If there is no `disabledBarTooltip` props being passed down,
 * the tooltip content will show on bar level (See TimesheetRowTotal)
 */
class PhaseAndProjectBudgetBar extends React.Component {
  renderBarSection = (text) => {
    const {
      totals,
      disableBarTooltip,
      dataTip,
      showHours,
      barClassName,
      calculateBarSectionTotals,
      calculateAllBarValues,
      viewBy,
      rowType
    } = this.props;

    const value = +totals[text];

    const spent = showHours ? +totals.spent_hours : +totals.spent_cost;
    const planned = showHours ? +totals.planned_hours : +totals.planned_cost;
    const total = showHours ? +totals.estimated_hours : +totals.total;
    const { spentPercent, plannedPercent, residualPercent } =
      calculateBudgetDisplayPercentages({
        spent,
        planned,
        total
      });
    const allBarValues = calculateAllBarValues
      ? calculateAllBarValues({
          totals,
          isOverBudget: false,
          viewBy,
          rowType,
          showHours,
          type: text
        })
      : {};

    const mapPercents = {
      spent_cost: totals.spent_percent ?? spentPercent,
      planned_cost: totals.planned_percent ?? plannedPercent,
      remaining_cost: Math.abs(totals.remaining_percent ?? residualPercent),
      spent_hours: spentPercent,
      planned_hours: plannedPercent,
      remaining_hours: Math.abs(
        totals.remaining_percent_hour ?? residualPercent
      )
    };
    const mapText = {
      spent_cost: 'Spent',
      spent_hours: 'Spent',
      planned_cost: 'Planned',
      planned_hours: 'Planned',
      remaining_cost: 'Remaining',
      remaining_hours: 'Remaining'
    };

    const formatType = showHours ? 'hours' : 'currency';
    const [valueType] = text.split('_');

    const barTotals = calculateBarSectionTotals
      ? calculateBarSectionTotals({ showHours, totals })
      : showHours
      ? +totals.spent_hours +
        +totals.planned_hours +
        Math.abs(+totals.remaining_hours)
      : +totals.spent_cost +
        +totals.planned_cost +
        Math.abs(+totals.remaining_cost);

    const valueToShow = parseInt(value).toLocaleString({
      maximumFractionDigits: 0
    });

    const barPercent =
      allBarValues.barLength ?? formatBarPercent(value / barTotals);

    return barPercent > 0 ? (
      <StyledBarSection
        key={text}
        text={text}
        total={barPercent}
        maxWidth={barPercent}
        data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
        data-tip={
          dataTip ||
          JSON.stringify({
            total: totals[text],
            text: mapText[text],
            totals: totals,
            formatType
          })
        }
        className={`${valueType} ${barClassName}`}
      >
        <BarNumber className={`${valueType} ${barClassName}`}>
          {allBarValues.barNumberToDisplay ??
            (showHours ? `${valueToShow}h` : `$${valueToShow}`)}
        </BarNumber>
      </StyledBarSection>
    ) : null;
  };

  renderOverBudget = () => {
    const {
      totals,
      disableBarTooltip,
      dataTip,
      showHours,
      hideOverBudget,
      barClassName,
      calculateBarSectionTotals,
      calculateAllBarValues,
      viewBy,
      rowType
    } = this.props;
    const spent = showHours ? +totals.spent_hours : +totals.spent_cost;
    const planned = showHours ? +totals.planned_hours : +totals.planned_cost;
    const total = showHours ? +totals.estimated_hours : +totals.total;
    const { spentPercent, plannedPercent, residualPercent } =
      calculateBudgetDisplayPercentages({
        spent,
        planned,
        total
      });
    const allBarValues = calculateAllBarValues
      ? calculateAllBarValues({
          showHours,
          totals,
          isOverBudget: true,
          viewBy,
          rowType
        })
      : {};

    const formatType = showHours ? 'hours' : 'currency';
    const spentValueToUse =
      allBarValues.spentValue ??
      (showHours ? totals.spent_hours : totals.spent_cost);
    const plannedValueToUse =
      allBarValues.plannedValue ??
      (showHours ? totals.planned_hours : totals.planned_cost);
    const spentText = showHours ? 'spent_hours' : 'spent_cost';
    const plannedText = showHours ? 'planned_hours' : 'planned_cost';

    const remainingValueToUse = Math.abs(
      showHours ? +totals.remaining_hours : +totals.remaining_cost
    );

    const barTotals = calculateBarSectionTotals
      ? calculateBarSectionTotals({ showHours, totals })
      : spentValueToUse + plannedValueToUse + remainingValueToUse;

    // Display
    const displaySpentValue =
      allBarValues.spentValueToDisplay ??
      (showHours ? `${spentValueToUse}h` : `$${spentValueToUse}`);
    const displayPlannedValue =
      allBarValues.plannedValueToDisplay ??
      (showHours ? `${plannedValueToUse}h` : `$${plannedValueToUse}`);

    // Bar length
    const spentBarPercent =
      allBarValues.spentBarLength ??
      formatBarPercent(spentValueToUse / barTotals);
    const plannedBarPercent =
      allBarValues.plannedBarLength ??
      formatBarPercent(plannedValueToUse / barTotals);
    // limit the width of the overdue bar to max at 100%  of the bar
    const overdueWidth =
      allBarValues.overBudgetBarLength ??
      Math.min(Math.abs(residualPercent), 100);

    return (
      <>
        <BarLabelsContainer className={`${barClassName}`}>
          {spentValueToUse > 0 ? (
            <StyledBarLabel
              data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
              data-tip={
                dataTip ||
                JSON.stringify({
                  total: spentValueToUse,
                  text: 'spent',
                  totals: totals,
                  formatType,
                  isOverBudget: true
                })
              }
              className={`spent ${barClassName}`}
              text={spentText} // calculates border
              total={spentBarPercent}
              style={{
                flexShrink: 1,
                flexBasis: `${
                  totals.estimated_hours && showHours
                    ? (spentValueToUse /
                        (+spentValueToUse + +plannedValueToUse)) *
                      100
                    : 100
                }%`
              }}
            >
              {displaySpentValue}
            </StyledBarLabel>
          ) : null}
          {plannedValueToUse > 0 ? (
            <StyledBarLabel
              className={`planned ${barClassName}`}
              data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
              data-tip={
                dataTip ||
                JSON.stringify({
                  total: plannedValueToUse,
                  text: 'planned',
                  totals: totals,
                  formatType
                })
              }
              text={plannedText}
              total={plannedBarPercent}
              style={{ flexShrink: 2, flexGrow: 1 }}
            >
              {displayPlannedValue}
            </StyledBarLabel>
          ) : null}
          {!hideOverBudget && (
            <StyledBarLabelOverBudget
              className={`${barClassName} overbudget`}
              text={plannedText}
            >
              {this.renderOverBudgetText(totals)}
            </StyledBarLabelOverBudget>
          )}
        </BarLabelsContainer>

        <OverdueBudgetBarContainer>
          <StyledBarSection
            data-for={disableBarTooltip ? '' : 'budget-bar-tooltip'}
            data-tip={
              dataTip ||
              JSON.stringify({
                total: spentValueToUse,
                text: 'spent',
                totals: totals,
                formatType,
                isOverBudget: true
              })
            }
            text={spentText} // calculates border
            total={spentBarPercent}
            className={`${barClassName} spent`}
            maxWidth={spentBarPercent}
          />
          {plannedValueToUse > 0 && (
            <StyledBarSection
              className={`${barClassName} planned`}
              text={plannedText}
              total={plannedBarPercent}
              maxWidth={plannedBarPercent}
            />
          )}
          {!hideOverBudget && (
            <OverdueBar
              shouldShow={!!(totals && overdueWidth)}
              className={cn({
                [barClassName]: true,
                'no-planned': plannedValueToUse <= 0
              })}
              flexWidth={overdueWidth}
            />
          )}
        </OverdueBudgetBarContainer>
      </>
    );
  };

  renderBudget = (total) => {
    const {
      dataTip,
      disableBarTooltip,
      barsToUse,
      showHours,
      shouldShowNoValueBar,
      totals,
      barClassName
    } = this.props;

    const shouldRenderBars = !(shouldShowNoValueBar
      ? shouldShowNoValueBar({ totals, showHours })
      : false);

    return shouldRenderBars ? (
      barsToUse.map(this.renderBarSection)
    ) : (
      <BarNumber
        data-tip={dataTip}
        data-for={disableBarTooltip ? ' ' : 'budget-bar-tooltip'}
        className={`${barClassName} no-value`}
      >
        {'0%'}
      </BarNumber>
    );
  };

  renderTodayInBetween = (start_date, end_date) => {
    const startDate = moment.utc(start_date);
    const totalDaysEstimated =
      Math.abs(moment.utc(end_date).diff(startDate, 'days')) + 1;
    const spentTillNow = moment.utc().diff(startDate, 'days');
    const daysPassedTillNow = spentTillNow > 0 ? spentTillNow : 0;
    const completedPercentage = parseInt(
      (daysPassedTillNow / totalDaysEstimated) * 100
    );
    return (
      <Today left={`${completedPercentage}%`}>
        <TodayBar />
        <div style={{ position: 'absolute', top: -40 }}>
          {moment.utc().format('M/D/YY') || ''}
        </div>
      </Today>
    );
  };

  renderOverBudgetText = (totals) => {
    const { showHours, hideOverBudget, isOverBudgetChecker } = this.props;

    const isOverBudget = isOverBudgetChecker(totals) && totals.total;
    if (!isOverBudget || hideOverBudget) return null;
    const { phase = {} } = this.props;
    const overBudgetBy = showHours
      ? Math.abs(totals.remaining_percent_hour || 0).toFixed(0)
      : Math.abs(
          (totals.remaining_percent_of_estimate !== undefined // different names depending on where they're coming from
            ? totals.remaining_percent_of_estimate
            : totals.remaining_percent) || 0
        ).toFixed(0);
    return (
      <OverBudget
        textToRender={overBudgetBy + '%'}
        tooltipContent={`${
          phase.project_id ? 'Phase' : 'Project'
        } is ${overBudgetBy}% overbudget`}
      />
    );
  };

  formatMilestone = (date, placeholder) =>
    moment(date).isValid() ? moment(date).format('M/D/YY') : placeholder;

  render() {
    const {
      barHeight,
      totals,
      phase = {},
      onClick,
      isProjectViewReport,
      barsToUse,
      barContainerTooltipProps,
      barClassName,
      shouldShowNoValueBar,
      showHours,
      isOverBudgetChecker
    } = this.props;
    const { start_date, end_date, project_id } = phase;
    const startDate = start_date && this.formatMilestone(start_date, '');
    const endDate = end_date && this.formatMilestone(end_date, '');
    const reducer = (accumulator, currentValue) =>
      accumulator + Math.abs(+totals[currentValue]); // Edge case: parseInt of fraction (0.42) can result in 0
    const today = moment.utc();
    const startDateDiffWithToday =
      start_date && today.diff(moment.utc(start_date), 'days');
    const endDateDiffWithToday =
      end_date && today.diff(moment.utc(end_date), 'days');

    const total = totals && barsToUse.reduce(reducer, 0);
    if (!totals) {
      return null;
    }

    const shouldRenderBars = !(shouldShowNoValueBar
      ? shouldShowNoValueBar({ totals, showHours })
      : false);

    return (
      <StyledBarContainer
        phaseIsOverBudget={isOverBudgetChecker(totals)}
        style={{ paddingRight: project_id ? 70 : 78 }}
        onClick={onClick}
        {...barContainerTooltipProps}
      >
        <StyledBar
          className={cn(`${barClassName}`, {
            'show-bars': shouldRenderBars
          })}
          height={barHeight}
        >
          {isOverBudgetChecker(totals)
            ? this.renderOverBudget()
            : this.renderBudget(total)}
        </StyledBar>
        {/* <TotalsContainer isPhase={!!project_id}> */}
        {/* <HeaderHoursTotal>
            {Math.ceil(
              totals?.spent_hours +
                totals?.planned_hours +
                totals?.remaining_hours || 0
            ).toLocaleString()}
            h
          </HeaderHoursTotal> */}
        {/* {this.renderOverBudgetText(totals)}
        </TotalsContainer> */}
        {start_date && isProjectViewReport && !!total && (
          <DateContainerBar
            isProjectViewReport={isProjectViewReport}
            widthAdjust={isOverBudgetChecker(totals)}
          >
            <>
              {startDateDiffWithToday > 0 &&
                endDateDiffWithToday < 0 &&
                this.renderTodayInBetween(startDate, endDate)}
            </>
          </DateContainerBar>
        )}
      </StyledBarContainer>
    );
  }
}

PhaseAndProjectBudgetBar.propTypes = {
  totals: PropTypes.object,
  phase: PropTypes.object
};

const makeMapStateToProps = (state, ownProps) => {
  const {
    barsProps = emptyObj,
    isHoursOnly,
    viewBy,
    rowType,
    totals
  } = ownProps;
  const {
    showHours,
    getBars,
    hideOverBudget,
    barClassName,
    shouldShowNoValueBar,
    budgetBarProps = emptyObj
  } = barsProps;
  const defaultBars =
    showHours || isHoursOnly
      ? PHASE_AND_PROJECT_BAR_CALCULATIONS_HOURS
      : PHASE_AND_PROJECT_BAR_CALCULATIONS;

  const barsToUse = getBars
    ? getBars({ viewBy, rowType, showHours, defaultBars, totals })
    : defaultBars;
  const { calculateBarSectionTotals, calculateAllBarValues } = budgetBarProps;
  const showHoursToUse = showHours || isHoursOnly;
  const isOverBudgetChecker = showHoursToUse
    ? isHoursOverBudget
    : isCostOverBudget;

  return {
    teamId: getSelectedTeamId(state),
    showHours: showHoursToUse,
    barsToUse,
    hideOverBudget,
    barClassName,
    shouldShowNoValueBar,
    calculateBarSectionTotals,
    calculateAllBarValues,
    isOverBudgetChecker
  };
};

const mapDispatchToProps = {
  budgetAccessCheck
};

export default compose(
  withPermissionsCheck,
  withIsHoursOnly
)(connect(makeMapStateToProps, mapDispatchToProps)(PhaseAndProjectBudgetBar));
