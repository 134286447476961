import CloseEditStateIcon from 'icons/CloseEditStateIcon';
import { MouseEvent } from 'react';
import styled from 'styled-components';
import { DependencyItemDisplay } from '.';
import { DependencyItemDisplayProps } from './DependencyItemDisplay';
import theme from 'theme';
import { IconButton } from './styles';

interface DependencyItemRowProps extends DependencyItemDisplayProps {
  onRemove?: (event: MouseEvent<HTMLButtonElement>) => void;
  'data-testid'?: string;
}

export const DependencyItemRow = ({
  onRemove,
  'data-testid': dataTestId,
  ...dependencyItemProps
}: DependencyItemRowProps) => {
  return (
    <RowContainer data-testid={dataTestId}>
      <LeadingContainer>
        <IconButton onClick={onRemove} data-testid="remove-dependency-button">
          <CloseEditStateIcon fill={theme.colors.colorCalendarBlue} />
        </IconButton>
      </LeadingContainer>
      <DependencyItemDisplay {...dependencyItemProps} />
    </RowContainer>
  );
};

const LeadingContainer = styled.div`
  ${IconButton} {
    padding: 8px 4px;
    visibility: hidden;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  :hover {
    ${IconButton} {
      visibility: visible;
    }
  }
`;
