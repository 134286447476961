import styled from 'styled-components';
import { TextButton } from 'components/styles';
import { useWorkPlanForm } from '../WorkPlanFormProvider';
import {
  WORK_PLAN_CALENDAR_OPTION_TYPES,
  WORK_PLAN_OPTION_TEXT,
  DEPENDENCY_SHORTENED_STRINGS_DISPLAY,
  DEPENDENCY_STRINGS
} from 'appConstants/workload';
import {
  isStartDateDependency,
  isEndDateDependency,
  isStartAndEndDateDependency
} from 'appUtils/newDependencies';
import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

interface OptionItemProps {
  text: string;
  visible: boolean;
  handleClick: () => void;
  disabled: boolean;
}
export const ActiveOptions = () => {
  const {
    calendar: { handleSetWorkplanCalendarOpen },
    optionsMenu: {
      handleSetWorkplanMoreOptionsOpen,
      isOpen: isOptionsMenuOpen
    },
    moreOptions: {
      workingWeekends,
      weeklyPlan,
      tenativePlan,
      initialDependency
    },
    form: { isMissingInfo },
    projectAndPhase: { isPTO },
    form: {
      workplan: { all_day: isAllDay }
    },
    permissions: { canEditWorkplan }
  } = useWorkPlanForm();

  const allowCalendarToOpen = !isMissingInfo;

  const handleOpenCalendar = () => {
    handleSetWorkplanCalendarOpen(true);
  };

  const handleOpenOptionsMenu = () => {
    handleSetWorkplanMoreOptionsOpen(true);
  };

  const dependencyStringDisplayKey = isStartAndEndDateDependency(
    initialDependency
  )
    ? DEPENDENCY_STRINGS.START_AND_END
    : isStartDateDependency(initialDependency.start)
    ? DEPENDENCY_STRINGS.START
    : isEndDateDependency(initialDependency.end)
    ? DEPENDENCY_STRINGS.END
    : undefined;

  const optionItems: OptionItemProps[] = [
    {
      text:
        WORK_PLAN_OPTION_TEXT[
          WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING
        ] || '',
      visible: weeklyPlan,
      handleClick: handleOpenCalendar,
      disabled: !allowCalendarToOpen || !canEditWorkplan
    },
    {
      text:
        WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY] || '',
      visible: isPTO && isAllDay,
      handleClick: handleOpenOptionsMenu,
      disabled: !canEditWorkplan
    },
    {
      text:
        WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE] ||
        '',
      visible: tenativePlan && !isOptionsMenuOpen,
      handleClick: handleOpenOptionsMenu,
      disabled: !canEditWorkplan
    },
    {
      text:
        WORK_PLAN_OPTION_TEXT[
          WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS
        ] || '',
      visible: workingWeekends && !isPTO,
      handleClick: handleOpenCalendar,
      disabled: !allowCalendarToOpen || !canEditWorkplan
    },
    {
      text:
        dependencyStringDisplayKey &&
        DEPENDENCY_SHORTENED_STRINGS_DISPLAY[dependencyStringDisplayKey]
          ? `Dependent On Phase ${DEPENDENCY_SHORTENED_STRINGS_DISPLAY[dependencyStringDisplayKey]} Date`
          : '',
      visible: !!dependencyStringDisplayKey,
      handleClick: handleOpenCalendar,
      disabled: !allowCalendarToOpen || !canEditWorkplan
    }
  ];

  return (
    <OptionsContainer>
      {optionItems.map(
        ({ text, visible, handleClick, disabled }) =>
          visible && (
            <OptionItemContainer key={text} disabled={disabled}>
              <BulletPoint />
              <OptionItem
                data-for="app-tooltip"
                data-tip={EDIT_WORK_PLANS_TIP}
                data-effect="solid"
                data-tip-disable={canEditWorkplan}
                onClick={!disabled ? handleClick : undefined}
              >
                {text}
              </OptionItem>
            </OptionItemContainer>
          )
      )}
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-bottom: 4px;
`;

const BaseContainer = styled.div`
  width: 4px;
  height: 4px;
`;

const BulletPoint = styled(BaseContainer)`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.colorMediumGray9};
  border-radius: 4px;
  margin-top: 2px;
`;

const BaseTextButton = styled(TextButton)`
  width: fit-content;
  padding: 4px 8px;
`;

const OptionItem = styled(BaseTextButton)``;

const OptionItemContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  ${BulletPoint} {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.colorMediumGray3 : theme.colors.colorRoyalBlue};
  }
  ${OptionItem} {
    cursor: ${({ disabled }) => (disabled ? 'default !important' : undefined)};
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.colorMediumGray3 : theme.colors.colorRoyalBlue};
  }
`;
