import styled from 'styled-components';
import theme from 'theme';

export const ContainerDiv = styled.div`
  .unassigned-role-name-container {
    display: flex;
    gap: 5px;
  }
  .unassigned-role-name,
  .selector-text {
    font-size: 14px;
    font-weight: 600;
    width: fit-content;
    cursor: pointer;
    color: ${theme.colors.colorRoyalBlue};
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
  &:hover {
    .unassigned-role-name {
      font-weight: 500;
    }
  }
`;
