import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import ProjectSelectionPopover from 'components/ProjectSelectionPopover';
import TextArea from 'components/TextArea';
import FollowedBell from '../../../taskDisplay/taskUtilityComponents/FollowedBell';
import DropdownArrowIcon from 'icons/ProjectSelectionPopover/DropdownArrowIcon';
import {
  VirtualTaskCreationMainContentPanel,
  TaskContentLeft,
  TaskDescriptionAndProjectContainer,
  CreateTaskClickBox
} from '../styles';
import {
  TaskIconsContainer,
  TaskCommentIconWrapper
} from 'components/TextArea/styles';
import CommentBubbleIcon from 'icons/CommentBubbleIconV2';
import NoteIcon from 'icons/NoteIcon';
import theme from 'theme';

import {
  getIsOnTeamProject,
  getSelectedTeamMember,
  getIsOnPersonalProject,
  makeSafeGetProjectAssignmentProjects,
  getTaskEditProperty,
  getOnHomeView,
  getAuth,
  getSearchText,
  isSomeTaskEditing,
  getSelectedTeamId,
  getMatchedRouteParams,
  getIsOnProjectView,
  getSelectedProject,
  getOnProfile,
  getIsMemberModalOpen
} from 'selectors';

export const ProjectMenuButton = styled.span`
  display: flex;
  font-family: 'Open Sans';
  font-size: 11px;
  line-height: normal;
  align-items: center;
  color: ${({ hasProjectError, taskBeingEdited, theme }) =>
    hasProjectError
      ? theme.colors.colorMediumRed3
      : taskBeingEdited
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorMediumGray5};
  cursor: pointer;
  visibility: ${({ taskBeingEdited, hasProject }) =>
    taskBeingEdited || hasProject ? 'visible' : 'hidden'};

  &:hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

export const DropdownArrowWrapper = styled.span`
  margin-left: 6px;
  display: ${({ taskBeingEdited, isDetailModal }) =>
    taskBeingEdited || isDetailModal ? 'inline-block' : 'none'};
`;

export const ScheduleWrapper = styled.span`
  padding-left: 18px;
  height: 100%;
  width: 100%;
`;
const StyledNoteContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
class CreateDescriptionContent extends Component {
  state = {
    id: 0,
    project: {},
    currentDescriptionText: '',
    assigneeIds: [],
    modularTaskProperties: {
      due_at: null,
      schedule_start: this.props.scheduleStart || null
    },
    popoverContainerRef: null
  };

  componentDidMount() {
    const { task } = this.props;
    if (this.props.isEditing) {
      document
        .getElementById(`task-description-field-${task.list.id}`)
        ?.focus();
    }
  }

  setPopoverRef = (ref) => {
    if (ref !== this.state.popoverContainerRef)
      this.setState({ popoverContainerRef: ref }); // this has to be done because for whatever reason the ref gets set after the Popover component is rendered
  };

  getButtonText = () => {
    const { project } = this.state;
    const taskIsBeingEdited = true;
    if (!isEmpty(project)) {
      const { title: projectTitle, description: projectDescription } = project;
      const projectAndDescription = `${projectTitle} - ${projectDescription}`;
      const hasProjectDescription =
        projectTitle !== 'Select Project' && projectDescription;
      if (hasProjectDescription && taskIsBeingEdited) {
        return projectAndDescription;
      }
      return projectTitle;
    }
    return 'Select Project';
  };

  onMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleOpenModalClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.handleSubmit({ shouldOpenModalAfterCreate: true });
  };

  handleDescriptionTextAreaEvent = (currentDescriptionText) => {
    const { handleChange } = this.props;
    handleChange && handleChange(currentDescriptionText);
    this.setState({
      currentDescriptionText
    });
  };

  markTaskCompletion = () => {
    const { toggleTaskCreationHeader } = this.props;
    toggleTaskCreationHeader();
  };

  handleProjectMenuSelection = (newProjectId) => {
    const { myProjects, isMemberModalOpen, handleUpdate } = this.props;
    const project =
      myProjects.find((project) => project.id === newProjectId) || {};
    this.setState(() => ({
      project: project
    }));

    // TODO: when create task, selecting the project is required.
    // related jira issue:
    // https://mosaicnyc.atlassian.net/browse/WEB-78
    // https://mosaicnyc.atlassian.net/browse/WEB-68
    //
    handleUpdate &&
      handleUpdate({
        project: project
      });
    const { id } = this.state;
    if (!isMemberModalOpen) {
      document.getElementById(`task-description-field-${id}`).focus();
    }
  };

  handleAssigneeSelection = (assigneeIds) => {
    const { handleUpdate } = this.props;
    handleUpdate &&
      handleUpdate({
        assigneeIds
      });
    const { id } = this.state;
    document.getElementById(`task-description-field-${id}`).focus();
  };

  handleSubmit = ({ shouldOpenModalAfterCreate }) => {
    const { handleSubmit } = this.props;
    handleSubmit && handleSubmit({ shouldOpenModalAfterCreate });
  };

  handleDescriptionSubmit = () => {
    this.handleSubmit({ shouldOpenModalAfterCreate: false });
  };

  renderProjectPopoverMenuItem = (project, isEditing) => {
    const hasProject = !isEmpty(project);
    const hasProjectError = !!this.props.createTaskErrors?.find(
      (error) => error?.field === 'project'
    );
    return (
      <ProjectMenuButton
        taskBeingEdited={isEditing}
        hasProject={hasProject}
        hasProjectError={hasProjectError}
      >
        {this.getButtonText()}
        <DropdownArrowWrapper taskBeingEdited={isEditing}>
          <DropdownArrowIcon />
        </DropdownArrowWrapper>
      </ProjectMenuButton>
    );
  };

  handleBlur = () => {};

  render() {
    const {
      taskBeingEdited,
      isOnProjectDetail,
      isOnHomeView,
      currentlyFollowed,
      onFollowClick,
      shouldHideFollowedBell,
      isOnPersonalProject,
      searchText,
      isOnTeamProject,
      isHeader,
      todayView,
      isOnProjectView,
      task,
      index,
      isMemberModalOpen
    } = this.props;

    return (
      <VirtualTaskCreationMainContentPanel
        projectDetail={isOnTeamProject}
        isOnPersonalProject={isOnPersonalProject}
        isMemberModalOpen={isMemberModalOpen}
        isCreatingNewTask
        isHeader={isHeader}
        todayView={todayView}
        isOnTeamProject={isOnTeamProject}
        isOnHomeView={isOnHomeView}
        isSomeTaskEditing={isSomeTaskEditing}
      >
        <TaskContentLeft
          isOnTeamProject={isOnTeamProject}
          isOnPersonalProject={isOnPersonalProject}
          paddingLeft={12}
        >
          <FollowedBell
            isOnProjectDetail={isOnProjectDetail}
            beingEdited={taskBeingEdited}
            currentlyFollowed={currentlyFollowed}
            onFollowClick={onFollowClick}
            shouldHideFollowedBell={shouldHideFollowedBell}
          />
        </TaskContentLeft>
        <TaskDescriptionAndProjectContainer
          ref={this.setPopoverRef}
          isOnTeamProject={isOnTeamProject}
          isProjectDetail={isOnProjectView}
          isTaskEditProperty={'description'}
          taskIsEditing={true}
        >
          <TextArea
            textAreaId={task.list.id}
            searchText={searchText}
            currentDescriptionText={this.state.currentDescriptionText}
            onChange={this.handleDescriptionTextAreaEvent}
            onSubmit={this.handleDescriptionSubmit}
            handleBlur={this.handleBlur}
            taskIsCreating
            setEditingHeight={task.setEditingHeight}
            index={index}
          />
          {!isOnProjectView && (
            <ProjectSelectionPopover
              target={this.state.popoverContainerRef}
              task={task}
              onSelect={this.handleProjectMenuSelection}
              header={null}
              headerText={'Select Project'}
              popupAlign={{
                points: ['tl', 'bl'],
                offset: [0, 10]
              }}
            >
              {() => this.renderProjectPopoverMenuItem({}, true)}
            </ProjectSelectionPopover>
          )}
        </TaskDescriptionAndProjectContainer>
        <CreateTaskClickBox
          onClick={this.handleOpenModalClick}
          onMouseDown={this.onMouseDown}
          isOnTeamProject={isOnTeamProject}
        >
          <TaskIconsContainer $inTaskAdd data-testid="task-icons-container">
            <StyledNoteContainer
              data-for="app-tooltip"
              data-effect="solid"
              data-tip="Add task notes"
              data-delay-show="500"
            >
              <NoteIcon isHovered={task.note} />
            </StyledNoteContainer>
            <TaskCommentIconWrapper
              $show
              data-delay-show="500"
              data-effect="solid"
              data-for="app-tooltip"
              data-tip="Add task updates & attachments"
              role="button"
              tabIndex="-1"
            >
              <CommentBubbleIcon
                color={theme.colors.colorMediumGray1}
                height={16}
                width={16}
              />
            </TaskCommentIconWrapper>
          </TaskIconsContainer>
        </CreateTaskClickBox>
      </VirtualTaskCreationMainContentPanel>
    );
  }
}

CreateDescriptionContent.propTypes = {};

const makeMapStateToProps = () => {
  const getSafeProjectAssignmentProjects =
    makeSafeGetProjectAssignmentProjects();
  const mapStateToProps = (state, ownProps) => ({
    auth: getAuth(state),
    searchText: getSearchText(state),
    myProjects: getSafeProjectAssignmentProjects(state, ownProps),
    selectedTeamMember: getSelectedTeamMember(state),
    todayView: state.homeTasks.currentFilter.scope === 'today',
    isOnTeamProject: getIsOnTeamProject(state),
    isOnPersonalProject: getIsOnPersonalProject(state),
    taskEditProperty: getTaskEditProperty(state),
    isOnHomeView: getOnHomeView(state),
    selectedTeamId: getSelectedTeamId(state),
    isSomeTaskEditing: isSomeTaskEditing(state),
    matchedParams: getMatchedRouteParams(state),
    isOnProjectView: getIsOnProjectView(state),
    selectedProject: getSelectedProject(state),
    isOnTeamMemberProfile: getOnProfile(state),
    isMemberModalOpen: getIsMemberModalOpen(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(CreateDescriptionContent);
