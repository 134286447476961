import { useState, useEffect } from 'react';
import {
  CheckBoxOptionText,
  CheckBoxOptionsContainer,
  CheckBoxListItemsContainer,
  CustomCheckBox,
  CustomCheckBoxContainer,
  HiddenInput,
  DefaultTextContainer,
  TooltipContainer,
  SubText,
  CustomSubComponentContainer,
  RequiredText,
  WhiteStripeLine
} from './styles';
import HelpIcon from 'icons/HelpIcon';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const CheckBox = ({
  item,
  onCheck,
  checked,
  isDisabled,
  condensed,
  showPartialSelectCheckbox,
  uncheckSpecificFlag
}) => {
  const [isChecked, setCheck] = useState(checked);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);
  const handleChange = (e) => {
    if (item.confirmation) {
      const { confirmOnChecked } = item.confirmation;

      if (confirmOnChecked === isChecked) {
        setShowConfirmationModal(true);
        // return so we get confirmation before running onCheck/setCheck
        return;
      }
    }
    if (
      item.confirmationComponent &&
      item.showConfirmationModalOnChecked &&
      isChecked
    ) {
      setShowConfirmationModal(true);
      return;
    }
    onCheck(e, { name: item.name, checked: !isChecked, item });
  };

  const CustomSubComponent = item.customSubComponent;
  const ConfirmationComponent =
    item.confirmation?.confirmationComponent || item.confirmationComponent;
  const isShowingRequiredText = item.isRequired && isChecked;
  const grayOutCheckBox = item.grayoutWhenChecked && isChecked;
  const checkboxIsDisabled = isDisabled || item.isRequired || grayOutCheckBox;
  const showPartiallySelectCheckbox = showPartialSelectCheckbox && isChecked;

  return (
    <CheckBoxOptionsContainer
      condensed
      hasCustomSubComponent={!!CustomSubComponent}
      // classNames are for custom styling without causing problems with other components that uses
      // CheckboxContainer
      className={item.className}
    >
      <HiddenInput
        type="checkbox"
        defaultChecked={isChecked}
        onChange={checkboxIsDisabled ? undefined : handleChange}
        isDisabled={isDisabled || grayOutCheckBox}
        disableCursor={item.isRequired}
      />
      <CustomCheckBoxContainer
        isChecked={isChecked}
        isDisabled={isDisabled || grayOutCheckBox}
        disableCursor={item.isRequired}
      >
        <CustomCheckBox
          isChecked={isChecked}
          isDisabled={isDisabled || grayOutCheckBox}
        />
        {/* show partial selecting checkbox when showPartialCheckbox is true and not all childNodes are checked */}
        {showPartiallySelectCheckbox ? <WhiteStripeLine /> : null}
      </CustomCheckBoxContainer>
      {/* Add tooltip to the label if the item is required by other checkboxes */}
      <CheckBoxOptionText
        isChecked={isChecked}
        condensed={condensed}
        isHeader={item.isHeader && item.parent === undefined}
        data-for="app-tooltip"
        data-tip={item.isRequired ? item.requiredTooltip : ''}
        data-effect="solid"
        data-html
        data-class="center"
      >
        {item.text}{' '}
        {isShowingRequiredText ? <RequiredText>(Required)</RequiredText> : null}
        {item.tooltip && (
          <TooltipContainer
            data-for={'app-tooltip'}
            data-tip={item.tooltip}
            data-effect="solid"
            data-html
            data-class="center"
          >
            <HelpIcon
              width={8}
              height={8}
              fillColor={theme.colors.colorPaleGray10}
              questionMarkColor={theme.colors.colorPureBlack}
            />
          </TooltipContainer>
        )}
        {item.default ? (
          <DefaultTextContainer>Default</DefaultTextContainer>
        ) : (
          ''
        )}
        {item.subText && <SubText>{item.subText}</SubText>}
        {CustomSubComponent && (
          <CustomSubComponentContainer isChecked={isChecked}>
            <CustomSubComponent
              isChecked={isChecked}
              handleChange={item.handleChange}
            />
          </CustomSubComponentContainer>
        )}
        {showConfirmationModal && ConfirmationComponent && (
          <ConfirmationComponent
            closeModal={() => setShowConfirmationModal(false)}
            handleConfirm={() => {
              // checked value = item.confirmation.confirmOnChecked
              // modal only display it equals checkbox value
              onCheck(null, {
                name: item.name,
                checked: item.confirmation.confirmOnChecked,
                item
              });
              setCheck(item.confirmation.confirmOnChecked);
            }}
            uncheckSpecificFlag={uncheckSpecificFlag}
          />
        )}
      </CheckBoxOptionText>
    </CheckBoxOptionsContainer>
  );
};
const CheckBoxContainer = ({
  item,
  onCheck,
  isDisabled,
  dataValues = {},
  condensed,
  showPartialCheckbox,
  uncheckSpecificFlag
}) => {
  // traverse all subflags, if one of them is not checked, and showPartialCheckbox is true
  // then show partial select stripe checkbox
  let showPartialSelectCheckbox = showPartialCheckbox;
  if (showPartialCheckbox) {
    const hasSubFlagNotChecked = traverseSubFlags(item.childNodes, dataValues);
    showPartialSelectCheckbox =
      showPartialSelectCheckbox && hasSubFlagNotChecked;
  }

  return (
    <li>
      <CheckBox
        isDisabled={isDisabled}
        item={item}
        onCheck={onCheck}
        checked={!!dataValues[item.name]}
        condensed={condensed}
        showPartialSelectCheckbox={showPartialSelectCheckbox}
        uncheckSpecificFlag={uncheckSpecificFlag}
      />
      {item.childNodes && item.childNodes.length > 0 ? (
        <CheckBoxList
          onCheck={onCheck}
          dataList={item.childNodes}
          dataValues={dataValues}
          condensed={condensed}
          showPartialCheckbox={showPartialCheckbox}
          uncheckSpecificFlag={uncheckSpecificFlag}
        />
      ) : null}
    </li>
  );
};

const CheckBoxList = ({
  dataList,
  dataValues,
  onCheck,
  condensed,
  showPartialCheckbox,
  uncheckSpecificFlag
}) => (
  <CheckBoxListItemsContainer condensed={condensed}>
    {dataList.map((item) => (
      <CheckBoxContainer
        key={item.name}
        item={item}
        onCheck={onCheck}
        dataValues={dataValues}
        isDisabled={item.default}
        condensed={condensed}
        showPartialCheckbox={showPartialCheckbox}
        uncheckSpecificFlag={uncheckSpecificFlag}
      />
    ))}
  </CheckBoxListItemsContainer>
);
const CheckBoxListContainer = ({
  dataList,
  dataValues,
  onCheck,
  condensed = false,
  showPartialCheckbox = false,
  uncheckSpecificFlag = undefined
}) => {
  useEffect(() => {
    rebuildTooltip();
  }, [dataList]);
  return (
    <CheckBoxList
      dataList={dataList}
      dataValues={dataValues}
      onCheck={onCheck}
      condensed={condensed}
      showPartialCheckbox={showPartialCheckbox}
      uncheckSpecificFlag={uncheckSpecificFlag}
    />
  );
};

export default CheckBoxListContainer;

const traverseSubFlags = (childNodes, dataValues) => {
  for (let i = 0; i < childNodes.length; i++) {
    const childNode = childNodes[i];
    if (childNode.noPartialSelectCheckBox) return false;
    const isChildNodeNotChecked = !dataValues[childNode.name];
    if (isChildNodeNotChecked) return true;
    const hasSubFlagNotChecked = traverseSubFlags(
      childNode.childNodes,
      dataValues
    );
    if (hasSubFlagNotChecked) return true;
  }
  return false;
};
