import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getDisciplines } from 'SettingsModule/selectors/disciplines';
import { useNavToSettings } from 'SettingsModule/hooks';
import { useLoadDisciplines } from './useLoadDisciplines';

const emptyObj = {};

export const useDisciplines = ({
  isOff,
  shouldAutoLoad
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  /** default is true */
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const disciplineHash = useAppSelector(getDisciplines);

  const getDisciplineLabel = useCallback(
    (disciplineId: number | string) => {
      return disciplineHash[+disciplineId]?.name ?? 'No Discipline';
    },
    [disciplineHash]
  );

  const { handleNavToSettings: handleNavToDisciplinesPage } = useNavToSettings({
    viewType: 'members',
    tab: 'disciplines'
  });

  const { isLoaded, isLoading, loadDisciplines } = useLoadDisciplines({
    isOff,
    shouldAutoLoad
  });

  return {
    isLoaded,
    isLoading,
    loadDisciplines,
    disciplineHash,
    handleNavToDisciplinesPage,
    getDisciplineLabel
  };
};
