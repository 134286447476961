import { useCallback, useMemo } from 'react';
import {
  getOnWorkloadView,
  getTaskSidebarProject,
  getWorkloadSplitScreenAccountId,
  getWorkloadViewBy
} from 'selectors';
import DoubleArrow from 'icons/DoubleArrow';
import { fetchTasksV2 } from 'actionCreators';
import { Header, Body, Close } from './styles';
import { TASK_LIST_ITEM_GAP } from 'views/projectPlanner/WorkplanModal/SidebarPanel/taskMenu/constants';
import { VIEW_BY } from 'appConstants/workload';
import { useAppSelector } from 'reduxInfra/hooks';
import { LazyLoadingList } from 'views/projectPlanner/WorkplanModal/SidebarPanel/taskMenu/LazyLoadingList';
import { Task } from 'models/task';
import { AnyAction } from 'redux';
import {
  EmptyBehavior,
  ListNode
} from 'views/projectPlanner/WorkplanModal/SidebarPanel/taskMenu/LazyLoadingList/utils';
import { ValueOf } from 'type-fest';
import {
  LazyLoadingContextId,
  makeGetLazyLoadingTasks
} from 'TaskModule/selectors';
import { DraggableTaskCard } from 'views/projectPlanner/WorkplanModal/SidebarPanel/taskMenu/TaskCard';

/**
 * Gets the string ID of a task.
 */
const handleGetItemId = (item: Task): string => item.id.toString();

export const TaskSidebar = (props: { toggleIsOpen: () => void }) => {
  const { toggleIsOpen } = props;

  // These values determine the type of task list that is displayed.
  const projectId = useAppSelector(getTaskSidebarProject)?.id;
  const splitScreenAccountId = useAppSelector(getWorkloadSplitScreenAccountId);
  const isWorkloadView = useAppSelector(getOnWorkloadView);
  const workloadViewBy: ValueOf<typeof VIEW_BY> =
    useAppSelector(getWorkloadViewBy);

  // Fetch the appropriate page in the task list for the given group.
  const handleGetFetchAction = useCallback((groupId: string): AnyAction => {
    const parts = groupId.split('-');
    const id = parseInt(parts[1] ?? '');

    return fetchTasksV2({
      body: {
        [`${parts[0]}_ids`]: [id],
        scheduled_start: false,
        complete: false,
        sort: 'created_at'
      }
    });
  }, []);

  // Set up the hierarchy of the task list.
  const hierarchy = useMemo(
    () =>
      ({
        emptyBehavior: EmptyBehavior.EmptyList,
        hideHeading: true,
        id:
          isWorkloadView &&
          splitScreenAccountId !== undefined &&
          workloadViewBy === VIEW_BY.MEMBERS
            ? `assignee-${splitScreenAccountId}`
            : `project-${projectId}`,
        lazyLoadingGroup: true
      } as ListNode<never>),
    [isWorkloadView, projectId, splitScreenAccountId, workloadViewBy]
  );

  // Get the items in the groupings to build the list.
  const itemSelector = useCallback(
    (ownProps: { lazyLoadingId: LazyLoadingContextId }) =>
      makeGetLazyLoadingTasks({ ...ownProps }),
    []
  );

  const handleRenderItem = (task: Task) => (
    <DraggableTaskCard className="list-item" task={task} />
  );

  return (
    <>
      <Header>
        <Close onClick={toggleIsOpen}>
          <DoubleArrow />
        </Close>
        Unplanned Tasks
      </Header>
      <Body className="home-planner-container">
        <LazyLoadingList
          getFetchAction={handleGetFetchAction}
          getItemId={handleGetItemId}
          hierarchy={hierarchy}
          itemClassName="list-item"
          itemSelector={itemSelector}
          lazyLoaderGap={TASK_LIST_ITEM_GAP}
          renderItem={handleRenderItem}
        />
      </Body>
    </>
  );
};
