import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { ExpandCollapseAllContainer } from 'BudgetModule/components/BudgetTable/styles';
import CollapseAllArrows from 'icons/CollapseAllArrows';

const CollapseAllToggle = ({
  isHidden,
  allCollapsed,
  toggle,
  top,
  left,
  isRelative = false,
  hasHoverEffect = true
}) => {
  return (
    <StyledCollapseAll
      onClick={toggle}
      isCollapsed={allCollapsed}
      data-for="app-tooltip"
      data-effect="solid"
      className="app-cues-expand-collapse-all"
      data-tip={allCollapsed ? 'Click to Expand All' : 'Click to Collapse All'}
      isHidden={isHidden}
      top={top}
      left={left}
      hasHoverEffect={hasHoverEffect}
      isRelative={isRelative}
      data-testid="collapse-all-toggle"
    >
      <CollapseAllArrows />
    </StyledCollapseAll>
  );
};

export default CollapseAllToggle;

const StyledCollapseAll = styled(ExpandCollapseAllContainer)`
  top: ${(props) => props.top ?? 15}px;
  left: ${(props) => props.left ?? -33}px;
  svg path {
    fill: ${theme.colors.colorMediumGray9};
  }

  &:hover {
    ${({ hasHoverEffect }) =>
      hasHoverEffect
        ? `
        svg path {
          fill: ${theme.colors.colorRoyalBlue};
        }
        background: ${theme.colors.colorPureWhite};
        border: 1px solid ${theme.colors.colorLightGray9};
    `
        : `
        svg path {
          fill: ${theme.colors.colorSemiDarkGray1};
        }
        `}
  }
`;
