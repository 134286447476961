import styled from 'styled-components';
import { ReasonRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const ReasonHoursCell = ({ row }: ReasonRowProps) => {
  const {
    phase,
    phaseMembership,
    phaseTotals,
    accountId,
    projectTotal,
    teamMembershipsByAccountId,
    isLast,
    customRowProps: {
      handleOpenMergeBudgetModal,
      projectId,
      unassignedMemberBudgetId,
      resetUnassignedRoleAndSelectedPhaseIds
    }
  } = row.original;

  // SPEC: Only show the content for this cell when this is the last row
  if (!isLast) return <></>;

  // TODO when API ready
  const handleRejectSuggestedMember = () => undefined;

  return (
    <StyledContainer>
      <StyledIconsContainer>
        {/* When BE is ready */}

        {/* <StyledPlusIcon
          data-tip="Reject & Hide <br /> Suggestion"
          data-for="app-tooltip"
          data-html
          data-effect="solid"
          className="reject-icon"
          onClick={handleRejectSuggestedMember}
        >
          <NewCloseIcon width={21} height={21} />
        </StyledPlusIcon> */}
      </StyledIconsContainer>
    </StyledContainer>
  );
};

export default ReasonHoursCell;

const StyledIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledContainer = styled.div``;
