import { MenuItemContainer } from './styles';
import cn from 'classnames';
import styled from 'styled-components';
import {
  CommonDependencyItem,
  DependableItem,
  HandleClickTargetMenu
} from '../../types';
import { dependencyType, dependencyTypeStrings } from '../../constants';
import { getFormattedDateWithDateFns } from 'appUtils/dateUtils';
import { DATE_FNS_SHORT_USA_DATE } from 'appConstants/date';

export const TargetTypeMenuRenderer = ({
  dependableItem,
  targetType,
  onClick,
  disabled,
  selected
}: {
  dependableItem: DependableItem;
  targetType: NonNullable<CommonDependencyItem['targetType']>;
  onClick: HandleClickTargetMenu;
  disabled: boolean;
  selected: boolean;
}) => {
  const handleClick = () => {
    onClick({ dependableItem, targetType });
  };

  const { startDate, endDate } = dependableItem;

  const formattedStartDate = startDate
    ? getFormattedDateWithDateFns(startDate, DATE_FNS_SHORT_USA_DATE)
    : null;

  const formattedEndDate = endDate
    ? getFormattedDateWithDateFns(endDate, DATE_FNS_SHORT_USA_DATE)
    : null;

  return (
    <StyledMenuItemContainer
      className={cn({
        disabled,
        selected,
        hasStartEnd: targetType === dependencyType.startEnd
      })}
      onClick={!disabled ? handleClick : undefined}
    >
      {dependencyTypeStrings[dependencyType[targetType]]}
      <DateText>
        {targetType === dependencyType.startEnd &&
        formattedStartDate &&
        formattedEndDate
          ? `${formattedStartDate}-${formattedEndDate}`
          : targetType === dependencyType.start
          ? formattedStartDate
          : formattedEndDate}
      </DateText>
    </StyledMenuItemContainer>
  );
};

const StyledMenuItemContainer = styled(MenuItemContainer)`
  display: flex;
  justify-content: space-between;
  min-width: 142px;

  &.hasStartEnd {
    width: 238px;
  }
`;

const DateText = styled.div`
  font-weight: 600;
`;
