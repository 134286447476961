import styled from 'styled-components';
import theme from 'theme';
import NumberFormat from 'react-number-format';

export const DayHeader = styled.div`
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};
  border-bottom: none;
  font-size: 13px;
  color: ${theme.colors.colorCalendarGray};
  font-weight: 400;
`;

export const MemberCapacityCell = styled.div`
  text-align: center;
  position: relative;
`;

export const StyledMemberCapacityInput = styled(NumberFormat)`
  width: 100%;
  color: ${(props) =>
    !props.disabled ? theme.colors.colorMediumGray9 : 'transparent'};
  border: 1px solid ${theme.colors.colorPaleGray5};
  text-align: center;
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }
  height: 72px;
  width: 70px;
  font-size: 16px;
  background: ${(props) =>
    !props.disabled ||
    `repeating-linear-gradient(
    120deg,
    transparent 1.5px,
    transparent 5px,
    ${theme.colors.colorLightGray2} 0px,
    ${theme.colors.colorLightGray2} 8px
  );`};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify};
`;

export const GhostText = styled.div`
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  right: 10px;
  color: ${theme.colors.colorMediumGray1};
  top: 26px;
  visibility: hidden;
`;

export const MemberCapacityTableContainer = styled(FlexContainer)`
  &:hover {
    ${GhostText} {
      visibility: visible;
    }
  }
`;
