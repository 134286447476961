import React, { useCallback, useEffect } from 'react';
import { Modal } from 'reactstrap';
import {
  toggleInviteForm,
  clearErrorMessage,
  inviteTeamMember
} from 'actionCreators';
import {
  getInviteFormOpen,
  getGroups,
  getSelectedGroupId,
  getAllProjects,
  getIsOnProjectView,
  getOnBoardView
} from 'selectors';
import { permissionConstants } from 'PermissionsModule/constants';
import {
  InviteTeamMemberForm,
  InviteTeamMemberFormProps
} from 'views/inviteMemberComponents/InviteTeamMemberForm';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { usePrevious } from 'appUtils/hooks/usePrevious';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import { bulkInviteAccounts } from 'PermissionsModule/actionCreators';

const { ACTABLE_TYPES } = permissionConstants;

export const InviteTeamMemberFormContainer = () => {
  const showInviteForm = useAppSelector(getInviteFormOpen);
  const { projectId, boardId } = useAppSelector(getMatchedRouteParams);

  const isOnProjectView = useAppSelector(getIsOnProjectView);
  const isOnTeamView = useAppSelector(getOnBoardView);
  const prevShowInviteForm = usePrevious(showInviteForm);
  const groups = useAppSelector(getGroups);
  const groupId = useAppSelector(getSelectedGroupId);
  const projects = useAppSelector(getAllProjects);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (prevShowInviteForm && !showInviteForm) {
      dispatch(clearErrorMessage());
    }
  }, [dispatch, prevShowInviteForm, showInviteForm]);

  const getActableId = useCallback(() => {
    return isOnProjectView ? projectId : isOnTeamView ? boardId : undefined;
  }, [boardId, isOnProjectView, isOnTeamView, projectId]);

  const getActableType = useCallback(() => {
    return isOnProjectView && projectId
      ? ACTABLE_TYPES.PROJECT
      : isOnTeamView && boardId
      ? ACTABLE_TYPES.BOARD
      : undefined;
  }, [boardId, isOnProjectView, isOnTeamView, projectId]);

  const formSubmit: InviteTeamMemberFormProps['formSubmit'] = useCallback(
    (event, inviteeRole, permissions, invitees) => {
      const groupObj = groups.find((group) => group.id === groupId);

      const projObj = projectId
        ? projects.find((project) => project.id === +projectId)
        : null;

      const actableId = getActableId();
      const actableType = getActableType();
      event.preventDefault();

      dispatch(
        inviteTeamMember(
          invitees,
          groupObj,
          projObj,
          inviteeRole,
          permissions,
          actableId,
          actableType
        )
      );

      dispatch(toggleInviteForm({ open: false }));
    },
    [
      dispatch,
      getActableId,
      getActableType,
      groupId,
      groups,
      projectId,
      projects
    ]
  );

  const newFormSubmit: InviteTeamMemberFormProps['newFormSubmit'] = useCallback(
    ({ invitees }) => {
      const actableId = getActableId();
      const actableType = getActableType();

      dispatch(
        bulkInviteAccounts({
          accounts: invitees,
          actableType,
          actableId: actableId ? +actableId : undefined
        })
      );

      dispatch(toggleInviteForm({ open: false }));
    },
    [dispatch, getActableId, getActableType]
  );

  return (
    <Modal
      backdrop={false}
      className="invite-team-member-modal"
      isOpen
      toggle={() => dispatch(toggleInviteForm({ open: false }))}
    >
      <InviteTeamMemberForm
        formSubmit={formSubmit}
        newFormSubmit={newFormSubmit}
      />
    </Modal>
  );
};
