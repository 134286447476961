import React from 'react';
import * as budgetModalModuleUtils from 'appUtils/budgetModalModuleUtils';
import { getMatchedRouteParams, getSplitFlags } from 'selectors';
import { useSelector } from 'react-redux';
import SidebarNav from 'components/SidebarNav';
import mapValues from 'lodash/mapValues';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const { moduleTextHash, defaultNavModuleIds, moduleHash } =
  budgetModalModuleUtils;

const styles = {
  position: 'sticky',
  left: 0,
  flex: 0.4,
  minWidth: 120,
  width: 120,
  paddingTop: 125
};

const BudgetModalSidebarNav = () => {
  const { budgetViewType } = useSelector(getMatchedRouteParams);
  const flags = useSelector(getSplitFlags);
  const { isHoursOnly } = useIsHoursOnly();

  const moduleIdToFlag = {
    6: 'scope',
    // Prevents display of billing, and profit tab when hours only.
    ...(isHoursOnly ? { 4: 'billing', 3: 'profit' } : {})
  };

  const modifiedModuleHash = mapValues(moduleHash, (route) =>
    route === 'variance' ? 'variance/projects' : route
  );

  const moduleIds = defaultNavModuleIds.filter(
    (moduleId) => !moduleIdToFlag[moduleId] || flags[moduleIdToFlag[moduleId]]
  );

  return (
    <SidebarNav
      textHash={moduleTextHash}
      moduleHash={modifiedModuleHash}
      moduleHashWithoutExtensions={moduleHash}
      moduleIds={moduleIds}
      activeView={budgetViewType}
      styles={styles}
      baseRoute={budgetViewType === 'variance' ? '..' : undefined}
    />
  );
};

export default BudgetModalSidebarNav;
