import styled from 'styled-components';
import theme from 'theme';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';

export const LegendDivider = styled.hr`
  width: 100%;
  margin: 3px 0;
`;

const percentage = 100 / 6;

export const projectGradient = `repeating-linear-gradient(
  90deg,
    ${theme.colors.colorMediumOrange5},
    ${theme.colors.colorMediumOrange5} ${percentage * 1}%,
    ${theme.colors.colorMediumYellow5} ${percentage * 1}%,
    ${theme.colors.colorMediumYellow5} ${percentage * 2}%,
    ${theme.colors.colorMediumGreen9} ${percentage * 2}%,
    ${theme.colors.colorMediumGreen9} ${percentage * 3}%,
    ${theme.colors.colorPaleBlue7} ${percentage * 3}%,
    ${theme.colors.colorPaleBlue7} ${percentage * 4}%,
    ${theme.colors.colorLightPurple3} ${percentage * 4}%,
    ${theme.colors.colorLightPurple3} ${percentage * 5}%,
    ${theme.colors.colorPaleRed3} ${percentage * 5}%,
    ${theme.colors.colorPaleRed3} 100%
)`;

export const StyledWidgetContainer = styled.div`
  flex: 0 0 295px;
  position: relative;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  margin-top: ${({ plannerViewType }) =>
    plannerViewType === 'WEEK' ? '66px' : plannerViewType ? '85px' : '0px'};
  top: 32px;
  color: ${theme.colors.colorSemiDarkGray1};
  height: calc(100vh - 184px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 75px;
  margin-left: 30px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.colorTranslucentGray4};
    border-radius: 25px;
  }
  @media (max-width: 1750px) {
    margin-left: ${({ plannerViewType, isOnAssignedByMe }) =>
      plannerViewType === 'WEEK'
        ? '115px'
        : plannerViewType === 'DAY'
        ? '30px'
        : isOnAssignedByMe
        ? '90px'
        : '30px'};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 5px 15px 0;
  background: ${theme.colors.colorPureWhite};
  position: sticky;
  top: 0px;
  z-index: 3;
  border-bottom: 2px solid ${theme.colors.colorLightGray1};
`;

export const ChartHeader = styled.div`
  text-align: center;
  font-size: 14px;

  &.small-header {
    font-weight: 600;
    font-size: 12px;
    color: ${theme.colors.colorMediumGray9};
  }

  ${(props) => props.chartHeaderCSS};
`;
export const FractionNum = styled.div`
  font-size: 22px;
  line-height: 1;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;

  ${({ fractionStyle }) => fractionStyle};
`;
export const FractionDen = styled.div`
  color: ${theme.colors.colorMediumGray5};

  ${(props) => props.fractionDenCSS};
`;

export const ChartOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  justify-content: space-between;
  width: fit-content;

  ${(props) => props.chartOuterContainerCSS}
`;
export const WorkplanChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  ${(props) => props.containerCSS};
`;
export const TimesheetChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  width: 100%;
}
`;

export const StyledDot = styled.div`
  height: 8px;
  margin-left: 2px;
  flex: 0 0 8px;
  margin-right: 6px;
  margin-top: 2px;
  border-radius: 10px;
  background: ${calculateProjectColorPreference};
`;

export const ChartsInnerContainer = styled.div`
  position: relative;

  .show-on-hover {
    display: none;
  }
  &:hover {
    .hide-on-hover {
      display: none;
    }
    .show-on-hover {
      display: unset;
    }
  }
`;
export const ChartsDetails = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.timesheet-widget-charts-details {
    top: 10px;
    ${FractionNum} {
      font-size: ${({ sumFontSize }) =>
        sumFontSize ? `${sumFontSize}px` : '21px'};
    }
  }
`;

export const HeaderText = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: ${theme.colors.colorMediumGray9};
  position: relative;
  top: 2px;
`;

export const SubHeader = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray1};
  text-transform: uppercase;
`;

export const WidgetOption = styled.div`
  color: ${(props) => (props.isActive ? '#4f4f4f' : '#808080')};
  font-weight: ${(props) => (props.isActive ? '600' : 'normal')};

  font-size: 10px;
  padding: 0px 6px;
  text-align: center;
  cursor: pointer;

  &:first-child {
    border-right: 1px solid ${theme.colors.colorLightGray1};
    padding-left: 0px;
  }
`;

export const StyledWorkplanListItem = styled.div`
  border-bottom: 1px solid ${theme.colors.colorTranslucentGray6};
  padding: 10px 0;
`;
export const WorkplanListItemTop = styled.div`
  display: grid;
  grid-template-columns: 160px 80px 40px;
  width: 100%;
  line-height: 1;
`;

export const WorkplanListItemBottom = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  padding-left: 28px;
  padding-top: 4px;
`;
export const StyledWorkplanListHeader = styled(HeaderContainer)`
  display: grid;
  grid-template-columns: 160px 84px 50px;
  width: 100%;
  padding: 0;
  justify-content: unset;
  ${SubHeader} {
    font-size: 10px;
    &:first-child {
      padding-left: 10px;
    }
  }
`;

export const ProjectNameContainer = styled.div`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding-right: 6px;
`;

export const StyledProjectName = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  line-height: 1.2;
  margin-right: 2px;
`;
export const StyledProjectDescription = styled.span`
  font-size: 9px;
  color: ${theme.colors.colorMediumGray5};
`;

export const StyledCapacity = styled.div`
  flex-basis: 60px;
  text-align: right;
  font-weight: 600;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 10px;
  line-height: 2;
`;

export const StyledDays = styled.div`
  font-size: 10px;
  line-height: 2;
`;

export const StyledPhaseName = styled.div`
  font-size: 9px;
  color: ${theme.colors.colorMediumGray5};
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  margin-right: 4px;
  width: 9px;
`;
export const StyledBudgetPhaseMilestoneIcon = styled(BudgetPhaseMilestoneIcon)`
  margin-right: 4px;
  width: 7px;
`;

export const TimesheetLegend = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: baseline;

  ${(props) => props.timesheetLegendCSS};
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const LegendBox = styled.div`
  background: ${({ color }) => color};
  height: 12px;
  width: 15px;
  border-radius: 3px;
  margin-right: 5px;
  border: 1px solid
    ${(props) => (props.color === 'transparent' ? 'transparent' : '#e8e8e8')};

  ${(props) => props.legendBoxCSS};
`;
export const LegendText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;

  ${(props) => props.legendTextCSS};
`;

export const WorkloadChartTooltipContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  transform: scaleX(-1);
  opacity: 1;
  min-width: 150px;
  border: 1px solid ${theme.colors.colorLightGray1};
  border-left: 4px solid ${calculateProjectColorPreference};
  background: ${theme.colors.colorPureWhite};
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 5;
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
`;

export const TimesheetChartTooltipContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 1;
  z-index: 5;
  border: 1px solid ${theme.colors.colorLightGray1};
  background: ${theme.colors.colorPureWhite};
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
`;
export const BillableChartTooltipContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 1;
  z-index: 5;
  width: 160px;
  border: 1px solid ${theme.colors.colorLightGray1};
  background: ${theme.colors.colorPureWhite};
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
`;

export const AdditionalChartInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 35px;
`;

export const StyledInfo = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: ${theme.colors.colorLightGray15};
  text-align: center;
`;

export const StyledPercentage = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.colors.colorMediumGray9};
  align-self: center;
  margin-left: 20px;
`;

export const ContentContainer = styled.div`
  ${(props) => props.contentContainerCSS};
`;

export const RainbowBox = styled.div`
  background: ${({ color }) => color};
  height: 12px;
  width: 16px;
  border-radius: 3px;
  margin-right: 5px;
  border: 1px solid ${theme.colors.colorPaleGray12};
`;
