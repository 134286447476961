import SvgIcon from 'components/SvgIcon';

const FlagIcon = ({ width = 11, height = 13, className, currentColor }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 11 13"
    strokeWidth={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0174 1.4087C9.75655 1.51304 9.49568 1.56522 9.13046 1.56522C8.40002 1.56522 5.56524 1.30435 4.73046 1.09565C3.8435 0.782609 2.90437 0.521739 2.01742 0.521739C1.80872 0.521739 1.60002 0.573913 1.4435 0.573913V0H0.400024V13H1.4435V9.36522C1.60002 9.31304 1.80872 9.26087 2.01742 9.26087C2.74785 9.26087 3.58263 9.52174 4.41742 9.73043C5.30437 9.9913 8.2435 10.2522 9.13046 10.2522C9.60002 10.2522 10.0696 10.1478 10.4348 9.9913L10.7479 9.83478V1.04348L10.0174 1.4087ZM9.70438 9.2087C9.54786 9.26087 9.33916 9.26087 9.13047 9.26087C8.40003 9.26087 5.56525 9 4.73047 8.7913C3.84351 8.53044 2.90438 8.26957 2.01742 8.26957C1.80873 8.26957 1.60003 8.26957 1.44351 8.32174V1.66957C1.60003 1.61739 1.80873 1.56522 2.01742 1.56522C2.74786 1.56522 3.58264 1.82609 4.41742 2.03478C5.30438 2.29565 8.24351 2.55652 9.13047 2.55652C9.33916 2.55652 9.54786 2.55652 9.70438 2.50435V9.2087Z"
      fill={currentColor}
    />
  </SvgIcon>
);

export default FlagIcon;
