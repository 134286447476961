import React from 'react';

import { connect } from 'react-redux';
import { ProjectDetailBlock, StatusItemContainer } from '../..';
import { openProjectModal, fetchProjectById } from 'actionCreators';
import cn from 'classnames';
import styled from 'styled-components';

class ProjectDetailUpdates extends React.Component {
  toggleCommentModal = () => {
    const { project, openProjectModal, fetchProjectById } = this.props;
    openProjectModal({
      projectId: project.id
    });
    fetchProjectById(project.id, [], null, null, null, true);
  };

  render() {
    const { project } = this.props;
    if (!project) return null;
    const latestStatus = project.status?.[0];

    return (
      <ProjectDetailBlock
        handleClick={this.toggleCommentModal}
        className={cn({ 'has-updates': latestStatus }, 'project-updates')}
        title={!latestStatus ? 'Click to add project update' : undefined}
      >
        {latestStatus ? (
          <StatusItemContainer
            status={latestStatus}
            projectId={project.id}
            canEdit={false}
            statusCount={project.status.length}
            isOnProjectDetail
          />
        ) : (
          <AddProjectUpdateText>+ Update</AddProjectUpdateText>
        )}
      </ProjectDetailBlock>
    );
  }
}

const mapDispatchToProps = {
  openProjectModal,
  fetchProjectById
};

const AddProjectUpdateText = styled.div`
  color: ${({ theme }) => theme.colors.colorBudgetBlue};
  &:hover {
    font-weight: 600;
    letter-spacing: -0.2px;
  }
  margin-top: -3px;
`;

export default connect(null, mapDispatchToProps)(ProjectDetailUpdates);
