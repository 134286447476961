import { lazy, Suspense } from 'react';
import DefaultViewPermissionCheck from 'containers/DefaultViewPermissionCheck';
import { PageContainer, PageContainerProps } from 'views/PageContainer';
import { Page } from 'models/filter';
import { initialFilterLevelSchemas } from './filterConfig';
import { useAppSelector } from 'reduxInfra/hooks';
import { getCSVAgent } from 'selectors';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCsvImportsModule } from 'CsvImportsModule/package/csvImportsModule';

const ImportLogTableContainer = lazy(
  () => import('./ImportLogTable/ImportLogTableContainer')
);

const pageName = Page.ImportLogOverview;
// only 1 view for now on this page
const view = 'overview';

const ImportLogPageContainer = (props) => {
  const csvAgentIntegration = useAppSelector(getCSVAgent);
  const pageContainerProps: PageContainerProps = {
    pageContextProviderProps: {
      currentView: view,
      pageName: pageName
    },
    filterContextProviderProps: {
      filterSchemaParams: {
        pageName: pageName,
        initialFilterLevelSchemas,
        level2: view
      }
    }
  };
  return (
    <DynamicModuleLoader modules={[[getCsvImportsModule()]]}>
      {/* Page context */}
      <PageContainer {...pageContainerProps}>
        {/* permission check */}
        <DefaultViewPermissionCheck
          condition={true} // TODO space permission check
          // viewToFlag={}
        >
          <Suspense fallback={<div />}>
            <ImportLogTableContainer
              {...props}
              targetServiceId={csvAgentIntegration?.id}
            />
          </Suspense>
        </DefaultViewPermissionCheck>
      </PageContainer>
    </DynamicModuleLoader>
  );
};

export default ImportLogPageContainer;
