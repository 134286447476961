import {
  Sections,
  viewTypesHash,
  defaultViewTypes
} from 'appConstants/navigation';
import moment from 'moment';

export const calcBackDestination = (navigationHistory) => {
  // This function will send you back to one these
  // - the first non-project section you were on
  // - home
  let index = 0;
  // The first page that is not a in a project section
  let destination = null;
  while (index < navigationHistory.length) {
    const section = navigationHistory[index].section;
    if (
      section !== Sections.PROJECT &&
      section !== Sections.TEAM_SETTINGS &&
      section !== Sections.SETTINGS
    ) {
      destination = navigationHistory[index];
      break;
    }
    index++;
  }
  return { destination, index };
};

export const calcUpDestinationIndex = (navigationHistory) => {
  let index = 1;
  const currentNav = navigationHistory[0];
  while (index < navigationHistory.length) {
    if (
      currentNav.modal === 'TASK' &&
      navigationHistory[index].modal === 'MEMBER'
    ) {
      // if we opened the task modal on the member modal
      break;
    }
    if (
      currentNav.modal === 'TASK' &&
      navigationHistory[index].modal === 'BUDGET'
    ) {
      // if we opened the task modal on the budget modal
      break;
    }
    if (
      currentNav.modal === 'SCOPE' &&
      navigationHistory[index].modal === 'MEMBER'
    ) {
      // if we opened the scope modal on the member modal
      break;
    }
    if (
      currentNav.modal === 'SCOPE' &&
      navigationHistory[index].modal === 'UNPLANNED'
    ) {
      // if we opened the scope modal on the unplanned modal
      break;
    }
    if (
      currentNav.modal === 'SCOPE' &&
      navigationHistory[index].modal === 'BUDGET'
    ) {
      // if we opened the scope modal on the budget modal
      break;
    }
    if (
      currentNav.modal === 'MEMBER' &&
      navigationHistory[index].modal === 'BUDGET'
    ) {
      // if we opened the Member modal on the budget modal
      break;
    }

    if (currentNav.modal && !navigationHistory[index].modal) {
      break;
    }
    if (currentNav.view && !navigationHistory[index].view) {
      break;
    }
    if (
      currentNav.section &&
      navigationHistory[index].section !== currentNav.section
    ) {
      break;
    }
    index++;
  }
  return index;
};

export const getLastLocation = ({ lastLocationsState, section }) => {
  const sectionLocationState = lastLocationsState[section];
  if (!sectionLocationState) {
    return defaultViewTypes[section];
  }

  const { location, updateTime } = sectionLocationState;
  const resetAt =
    new Date().getHours() < 3
      ? moment().startOf('day').add(-1, 'day').add(3, 'hours')
      : moment().startOf('day').add(3, 'hours');

  const shouldResetLocation =
    !moment(updateTime).isValid() || !moment(updateTime).isAfter(resetAt);

  return shouldResetLocation
    ? defaultViewTypes[section]
    : viewTypesHash[section]?.[location] || defaultViewTypes[section];
};
