import { useContext } from 'react';
import cn from 'classnames';
import theme from 'theme';
import styled from 'styled-components';
import FindPeopleTable from './Table/FindPeopleTable';
import FindPeopleFilter from './Filter/FindPeopleFilter';
import Header from './Header';
import ProjectTeam from 'SuggestionModule/components/FindPeople/List/ProjectTeam/ProjectTeamListContainer';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import RemoveMemberModal from 'BudgetModule/components/BudgetModal/RemoveMemberModal';
import { FindPeopleProjectSelector } from './ProjectSelector/FindPeopleProjectSelector';
import { FindPeopleTableVariantOptions } from './Table/tableConfigs';

export const FindPeople = () => {
  const {
    toggleModal,
    selectedProjectId,
    selectedUnassignedMemberBudgetId,
    activePhasesNum,
    isFetchingPhaseTotals,
    isFetchingEntityRequirements,
    isFetchingMemberSuggestionForPhase,
    isProjectSelectorOpen,
    toggleIsProjectSelectorOpen
  } = useContext(FindPeopleModalContext);

  const isPageLoading =
    isFetchingEntityRequirements ||
    isFetchingPhaseTotals ||
    isFetchingMemberSuggestionForPhase;

  return (
    <RootContainer>
      <FindPeopleProjectSelector
        isOpen={isProjectSelectorOpen}
        toggleIsOpen={toggleIsProjectSelectorOpen}
      />
      <ContainerDiv>
        <Header toggleModal={toggleModal} />
        <Body>
          {/* Left Panel */}
          <FilterContainer>
            <FindPeopleFilter />
          </FilterContainer>
          {/* Middle Panel */}
          <TableContainer
            className={cn({
              'project-selected': !!selectedProjectId,
              'no-phases-selected':
                !!selectedProjectId &&
                !!selectedUnassignedMemberBudgetId &&
                activePhasesNum === 0
            })}
          >
            {selectedUnassignedMemberBudgetId ? (
              <FindPeopleTable
                variant={FindPeopleTableVariantOptions.MemberSuggestionByPhase}
              />
            ) : (
              <div className="default-table-text">
                {isPageLoading
                  ? ''
                  : getDefaultText({
                      projectId: selectedProjectId,
                      unassignedMemberBudgetId: selectedUnassignedMemberBudgetId
                    })}
              </div>
            )}
          </TableContainer>
          {/* Right Panel */}
          <RightPanelContainer>
            {selectedProjectId ? (
              <ProjectTeam isLoading={isFetchingPhaseTotals} />
            ) : (
              <div className="right-panel-empty-div"></div>
            )}
          </RightPanelContainer>
        </Body>
        {/* <AddUnassignRoleToPhasesModal /> */}
        <RemoveMemberModal />
      </ContainerDiv>
    </RootContainer>
  );
};

const getDefaultText = ({
  projectId,
  unassignedMemberBudgetId
}: {
  projectId: Nullable<number>;
  unassignedMemberBudgetId: Nullable<number>;
}) => {
  // Case 1: Nothing was selected
  if (!projectId && !unassignedMemberBudgetId)
    return 'Add Project and Role to find available team members.';

  // Case 2: Only project is selected, not unassigned role
  if (projectId && !unassignedMemberBudgetId)
    return 'Choose or Add Role to find available team members.';

  return '';
};

const RootContainer = styled.div`
  position: relative;
  height: 100%;
`;

const ContainerDiv = styled.div`
  margin: 35px 50px 0 50px;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
`;

const RightPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .right-panel-empty-div {
    width: 25%;
  }
`;

const FilterContainer = styled.div`
  padding-top: 10px;
  width: 320px;
`;

const TableContainer = styled.div`
  margin: 0 auto;

  .default-table-text {
    position: relative;
    top: 6rem;
    font-size: 14px;
    right: 175px;
    font-weight: 400;
    font-style: italic;
    color: ${theme.colors.colorCalendarGray};
  }

  &.project-selected {
    .default-table-text {
      right: 0;
    }
  }

  &.no-phases-selected {
    .default-table-text {
      text-align: center;
      top: 4.5rem;
    }
  }
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;
