import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getOnProjectDetail,
  getProjectsState,
  getSearchText,
  getMe,
  getBilling,
  getCurrentPage,
  getSelectedProject,
  getMatchedRouteParams,
  getIsOnProjectView,
  getIsOnTeamSettingsView,
  getTeamSlug,
  getUserSlug,
  getWorkloadViewBy,
  getIsOfflineMode,
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getPlannerSplitScreenType,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenType,
  getWorkloadSplitScreenAccountId
} from 'selectors';
import {
  fetchBoardMembers,
  onClearSearch,
  filterByMembers,
  changeBoardPosition,
  handleProjectItemState,
  clearSelectedProject,
  fetchProjectsByGroup,
  setModal,
  fetchTeamMemberProfile,
  useSearchState,
  loadWithSidebarOpen,
  navigateBack,
  navigateToBoard,
  navigateToHome,
  navigateToMembers,
  navigateToSchedule,
  navigateToWorkload,
  navigateToSettings,
  // navigateToActivityFeed,
  navigateToReport,
  navigateToDashboard,
  tryStartOnlineMode
} from 'actionCreators';
import { AddEditBoardContainer } from '..';
import SidebarMenu from './sidebarMenu/SidebarMenu';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import withNavigation from 'hocs/withNavigation';

const StyledTooltip = styled(ReactTooltip)`
  font-size: 11px;
  font-weight: normal;
  padding: 8px;
  text-align: center;
  z-index: 9999;
  margin-left: 0px !important;
`;

const Wrapper = styled.div``;

class PageMenuContainer extends React.Component {
  state = {
    teamBoardMenuOpen: true
  };

  selectGroup = (groupId, teamSlug, groupSlug) => {
    const {
      onClearSearch,
      filterByMembers,
      toggleIsOpenBoardMenu,
      clearSelectedProject,
      handleProjectItemState,
      groups,
      isOnHome,
      isOnProjectDetail,
      isOnSettings,
      navigateToBoard
    } = this.props;
    const selectedGroupId = groups.selectedGroupID;

    if (
      groupId !== selectedGroupId ||
      isOnHome ||
      (isOnProjectDetail && groupId !== selectedGroupId) ||
      isOnSettings
    ) {
      toggleIsOpenBoardMenu({ isOpen: false });
      onClearSearch();
      filterByMembers(false, []);
      navigateToBoard({
        teamSlug,
        boardSlug: groupSlug,
        boardId: groupId
      });

      handleProjectItemState(
        null,
        ['detailVisible', 'expanded', 'fixed'],
        [false, false, false]
      );
      clearSelectedProject();
    }
  };

  navHome = ({ useLastLocation } = {}) => {
    const { navigateToHome, userSlug, isOfflineMode, tryStartOnlineMode } =
      this.props;
    if (isOfflineMode) {
      tryStartOnlineMode();
    }

    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    navigateToHome({ userSlug, useLastLocation });
  };

  navPersonalPlannerPage = () => {
    const { history } = this.props;
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    history.push(`/${this.props.me.slug}/home/planner`);
  };

  navTeamPlanner = () => {
    const { history } = this.props;
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    const teamSlug = this.props.me.default_team
      ? this.props.me.default_team.slug
      : null;
    if (teamSlug) {
      history.push(`/${teamSlug}/planner/team-task-planner`);
    }
  };

  navSettings = () => {
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    this.props.navigateToSettings();
  };

  navTeamPage = () => {
    const { teamSlug, navigateToMembers } = this.props;
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    navigateToMembers({ teamSlug });
  };

  navPlannerPage = () => {
    const {
      teamSlug,
      navigateToSchedule,
      splitScreenProjectId,
      plannerSplitScreenActive
    } = this.props;
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    navigateToSchedule({
      teamSlug,
      plannerViewType: 'workplans',
      splitScreenActive: plannerSplitScreenActive && splitScreenProjectId,
      splitScreenProjectId
    });
  };

  navSchedulePage = () => {
    const { teamSlug, navigateToSchedule } = this.props;
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    navigateToSchedule({ teamSlug, plannerViewType: 'tasks' });
  };

  navWorkloadPage = () => {
    const {
      teamSlug,
      navigateToWorkload,
      workloadViewBy,
      splitScreenAccountId,
      workloadSplitScreenType,
      workloadSplitScreenActive
    } = this.props;
    this.props.toggleIsOpenBoardMenu({ isOpen: false });
    navigateToWorkload({
      teamSlug,
      workloadViewType: workloadViewBy,
      splitScreenActive: workloadSplitScreenActive && splitScreenAccountId,
      splitScreenAccountId,
      splitScreenType: workloadSplitScreenType
    });
  };

  // navTimesheet = () => {
  //   const {
  //     history,
  //     me: { slug, default_team, team_membership_id }
  //   } = this.props;
  //   const teamSlug = default_team ? default_team.slug : null;
  //   if (teamSlug) {
  //     history.push(`/${this.props.me.slug}/home/time`);
  //   }
  // };

  navReports = () => {
    const { navigateToReport, teamSlug } = this.props;
    navigateToReport({ teamSlug });
  };

  navDashboard = () => {
    const { navigateToDashboard, teamSlug } = this.props;
    navigateToDashboard({ teamSlug });
  };

  // navActivityPage = () => {
  //   const { navigateToActivityFeed } = this.props;
  //   this.props.toggleIsOpenBoardMenu({ isOpen: false });
  //   navigateToActivityFeed({ userSlug: this.props.me.slug });
  // };

  editGroupOnClickWrapper = (group) => (event) => {
    const { id } = group;
    event.stopPropagation();
    this.props.fetchBoardMembers(id);
    this.props.openAddEditGroupModal({ isEdit: true, id });
  };

  render() {
    const {
      isOpenBoardMenu,
      setIsOpenBoardMenu,
      teams,
      groups,
      isOnHome,
      toggleIsOpenBoardMenu,
      isSidebarMenuOpen,
      isOnProjectView,
      isOnTeamSettingsView,
      isOnSettings
    } = this.props;

    const shouldRenderSideMenu =
      !isOnProjectView && !isOnTeamSettingsView && !isOnSettings;
    const team = teams.length > 0 ? teams[0] : {};
    const teamName = team && team.name ? team.name : 'Your Portfolio';
    const boards = groups && groups.groupList.length ? groups.groupList : [];

    return (
      <Wrapper>
        <AddEditBoardContainer />
        {shouldRenderSideMenu && (
          <div>
            {isSidebarMenuOpen && (
              <StyledTooltip
                effect="solid"
                id="sidebar-menu-tooltip"
                place="right"
              />
            )}
            <SidebarMenu
              toggleIsOpenBoardMenu={toggleIsOpenBoardMenu}
              isOpen={isSidebarMenuOpen}
              isExpanded={isOpenBoardMenu}
              setIsOpenBoardMenu={setIsOpenBoardMenu}
              navHome={this.navHome}
              navPersonalPlannerPage={this.navPersonalPlannerPage}
              navSettings={this.navSettings}
              navTeamPlanner={this.navTeamPlanner}
              navTeamPage={this.navTeamPage}
              navSchedulePage={this.navSchedulePage}
              navPlannerPage={this.navPlannerPage}
              navWorkloadPage={this.navWorkloadPage}
              // navTimesheet={this.navTimesheet}
              navReports={this.navReports}
              navDashboard={this.navDashboard}
              // navActivityPage={this.navActivityPage}
              handleProjectItemState={handleProjectItemState}
              isOnHome={isOnHome}
              teamName={teamName}
              boards={boards}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

PageMenuContainer.propTypes = {
  openAddEditGroupModal: PropTypes.func.isRequired,
  toggleIsOpenBoardMenu: PropTypes.func.isRequired,
  isOpenBoardMenu: PropTypes.bool.isRequired,
  fetchBoardMembers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groupList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.object
};

const mapStateToProps = (state, params) => {
  const path = params.location.pathname;
  return {
    path,
    isOnHome: state.router.location.pathname.includes('/home'),
    isOnProjectDetail: getOnProjectDetail(state),
    isOnSettings: state.router.location.pathname.includes('/settings'),
    projects: getProjectsState(state),
    teams: state.users.teams,
    searchText: getSearchText(state),
    me: getMe(state),
    billing: getBilling(state),
    isOnProfile: !!getMatchedRouteParams(state).teamMemberId,
    selectedTeamMember: state.teams.selectedTeamMember,
    currentPage: getCurrentPage(state),
    selectedProject: getSelectedProject(state),
    userSlug: getUserSlug(state),
    isOnProjectView: getIsOnProjectView(state),
    isOnTeamSettingsView: getIsOnTeamSettingsView(state),
    teamSlug: getTeamSlug(state),
    workloadViewBy: getWorkloadViewBy(state),
    isOfflineMode: getIsOfflineMode(state),
    plannerSplitScreenActive: getPlannerSplitScreenActive(state),
    workloadSplitScreenActive: getWorkloadSplitScreenActive(state),
    splitScreenProjectId: getPlannerSplitScreenProjectId(state),
    splitScreenAccountId: getWorkloadSplitScreenAccountId(state),
    plannerSplitScreenType: getPlannerSplitScreenType(state),
    workloadSplitScreenType: getWorkloadSplitScreenType(state)
  };
};

const mapDispatchToProps = {
  fetchBoardMembers,
  onClearSearch,
  filterByMembers,
  changeBoardPosition,
  handleProjectItemState,
  fetchProjectsByGroup,
  clearSelectedProject,
  setModal,
  fetchTeamMemberProfile,
  useSearchState,
  loadWithSidebarOpen,
  navigateBack,
  navigateToBoard,
  navigateToMembers,
  navigateToSchedule,
  navigateToWorkload,
  navigateToHome,
  navigateToSettings,
  // navigateToActivityFeed,
  navigateToReport,
  navigateToDashboard,
  tryStartOnlineMode
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNavigation(PageMenuContainer))
);
