import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import KaratRight from 'icons/KaratRight';

const StyledKaratContainer = styled.div`
  height: 18px;
  width: 20px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-left: -4px;
  position: relative;
  left: 10px;
  top: 4px;
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
`;

const CollapseCell = ({ row }) => {
  const {
    id,
    customRowProps: { handleSetIsOpen },
    list: { isOpen }
  } = row.original;

  return (
    <StyledKaratContainer
      isOpen={isOpen || false}
      className="app-cues-styled-karat"
      onClick={() => {
        handleSetIsOpen({ name: id, value: !isOpen });
      }}
    >
      <KaratRight />
    </StyledKaratContainer>
  );
};

export default CollapseCell;
