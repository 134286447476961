import styled from 'styled-components';
import { makeFilterListTypeSelectorRow } from '../tableConfigs';
import { FilterListTypeList } from '../types';
import { TableRow } from 'components/Table/types';
import { FilterListTypeDropdown } from 'FilterModule/components/FilterListTypeDropdown';

export const FilterListTypeSelectorCell = ({
  row
}: {
  row: {
    original: TableRow<
      ReturnType<typeof makeFilterListTypeSelectorRow>,
      FilterListTypeList
    >;
  };
}) => {
  const { filterListTypeKey } = row.original;

  return filterListTypeKey ? (
    <StyledFilterListTypeSelectorCell>
      <FilterListTypeDropdown filterListTypeKey={filterListTypeKey} />
    </StyledFilterListTypeSelectorCell>
  ) : (
    <></>
  );
};

// -----------------------------------------------------------------------------

const StyledFilterListTypeSelectorCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
