import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, resetTempRef } from './utils';
import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  existingProjectBudgetStatusOptionsByBudgetStatus: Record<string, string>;
  temporaryProjectBudgetStatusOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectBudgetStatusValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const {
    enteredProjectBudgetStatus,
    enteredProjectTitle,
    enteredProjectNumber
  } = getRowValuesForEntityType(row, entityType);
  const {
    existingProjectBudgetStatusOptionsByBudgetStatus,
    temporaryProjectBudgetStatusOption
  } = additionalParams;

  let res: string | boolean = true;

  if (!enteredProjectTitle || !enteredProjectNumber) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else res = 'Project number is required';
    resetTempRef(temporaryProjectBudgetStatusOption, isLastRow);
    return res;
  }

  if (enteredProjectBudgetStatus === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else if (!enteredProjectBudgetStatus) {
    res = ERROR_MESSAGES.budgetStatusRequired;
  } else if (
    !existingProjectBudgetStatusOptionsByBudgetStatus[
      enteredProjectBudgetStatus
    ]
  ) {
    res = ERROR_MESSAGES.invalidProjectBudgetStatus;
  } else {
    const matchedProjectBudgetStatusInTempHash =
      temporaryProjectBudgetStatusOption.current[enteredProjectTitle]?.[
        enteredProjectNumber
      ];
    // if there were no option stored in the temporary hash for this project title + project number combination,
    // then add the entered budget status as new option to the temporary hash
    if (matchedProjectBudgetStatusInTempHash === undefined) {
      temporaryProjectBudgetStatusOption.current = {
        ...temporaryProjectBudgetStatusOption.current,
        [enteredProjectTitle]: {
          ...temporaryProjectBudgetStatusOption.current[enteredProjectTitle],
          [enteredProjectNumber]: enteredProjectBudgetStatus
        }
      };
    } else if (
      matchedProjectBudgetStatusInTempHash !== enteredProjectBudgetStatus
    ) {
      res = ERROR_MESSAGES.projectBudgetStatusDoesNotMatch;
    }
  }
  resetTempRef(temporaryProjectBudgetStatusOption, isLastRow);
  return res;
};
