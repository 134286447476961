import { useState, useCallback, MouseEvent } from 'react';
import { CapacityPolicySelector } from 'CapacityModule/components/CapacityPolicySelector';
import {
  CapacityMemberPolicyDropdown,
  CapacityMemberPolicyDropdownProps
} from 'CapacityModule/components/CapacityMemberPolicyDropdown';
import { DeleteModal } from 'components/Modals/DeleteModal';
import {
  createCapacityGroupMembership,
  updateCapacityGroupMembership,
  UpdateCapacityGroupMembershipInitialPayload,
  deleteCapacityGroupMembership
} from 'CapacityModule/actionCreators/capacityGroup';
import { CapacityGroup, CapacityGroupMembership } from 'CapacityModule/models';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { AddEditCapacityPolicyModal } from 'CapacityModule/components/AddEditCapacityPolicyModal';

interface CapacityPolicyMemberTimelineProps {
  memberPolicyDropdownAnchorEl: HTMLElement;
  accountId: number;
  teamMembershipId: number;
  onClose: () => void;
}

export const CapacityPolicyMemberTimeline = ({
  memberPolicyDropdownAnchorEl,
  accountId,
  teamMembershipId,
  onClose
}: CapacityPolicyMemberTimelineProps) => {
  const [addNewPolicyAnchorEl, setAddNewPolicyAnchorEl] = useState<
    HTMLElement | undefined
  >();
  const [isCreateCustomPolicyModalOpen, setIsCreateCustomPolicyModalOpen] =
    useState(false);

  const [itemToDelete, setItemToDelete] = useState<
    CapacityGroupMembership | undefined
  >();
  const [itemToEdit, setItemToEdit] = useState<
    CapacityGroupMembership | undefined
  >();

  const dispatch = useAppDispatch();

  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const teamMember = teamMembersHash[accountId];

  const handleRequestDeletePolicyMembership = useCallback(
    (item: CapacityGroupMembership) => setItemToDelete(item),
    []
  );

  const handleEditPolicy = useCallback(
    ({
      item,
      event
    }: {
      item: CapacityGroupMembership;
      event: MouseEvent<HTMLDivElement>;
    }) => {
      setItemToEdit(item);
      setAddNewPolicyAnchorEl(event.currentTarget);
    },
    []
  );

  const handleTeamPolicySelection = (capacityGroup: CapacityGroup) => {
    if (!teamMember) return;

    if (itemToEdit) {
      dispatch(
        updateCapacityGroupMembership({
          ...itemToEdit,
          capacityGroupId: capacityGroup.id,
          meta: { teamMembershipId: teamMember.id }
        })
      );
      setItemToEdit(undefined);
    } else {
      dispatch(
        createCapacityGroupMembership({
          capacityGroupId: capacityGroup.id,
          teamMembershipIds: [teamMember.id]
        })
      );
    }

    setAddNewPolicyAnchorEl(undefined);
  };

  const handleDeleteClose = () => {
    setItemToDelete(undefined);
    setAddNewPolicyAnchorEl(undefined);
  };

  const handleConfirmDeletePolicyMembership = () => {
    if (!teamMember || !itemToDelete) return;

    dispatch(
      deleteCapacityGroupMembership({
        id: itemToDelete.id,
        meta: { teamMembershipId: teamMember.id }
      })
    );

    handleDeleteClose();
  };

  const handleChangePolicyDate: CapacityMemberPolicyDropdownProps['onDateChange'] =
    ({ item: policyMembership, dateType, date }) => {
      if (!teamMember) return;

      const baseParams = {
        id: policyMembership.id,
        meta: { teamMembershipId: teamMember.id }
      };

      const params: UpdateCapacityGroupMembershipInitialPayload =
        dateType === 'startDate'
          ? { ...baseParams, startDate: date.format('YYYY-MM-DD') }
          : { ...baseParams, endDate: date.format('YYYY-MM-DD') };

      dispatch(updateCapacityGroupMembership(params));
    };

  const handleRequestCreateCustomPolicy = useCallback(() => {
    setIsCreateCustomPolicyModalOpen(true);
  }, []);

  return (
    <>
      {itemToDelete ? (
        <DeleteModal
          isOpen
          component="policy"
          toggle={handleDeleteClose}
          deleteOnClick={handleConfirmDeletePolicyMembership}
        />
      ) : (
        !isCreateCustomPolicyModalOpen && (
          <CapacityMemberPolicyDropdown
            parentRequestStatusId="CapacitiesTable"
            accountId={accountId}
            teamMembershipId={teamMembershipId}
            anchorEl={memberPolicyDropdownAnchorEl}
            onClose={onClose}
            onDelete={handleRequestDeletePolicyMembership}
            onEdit={handleEditPolicy}
            onStickyClick={(event) =>
              setAddNewPolicyAnchorEl(event.currentTarget)
            }
            onDateChange={handleChangePolicyDate}
          />
        )
      )}
      {addNewPolicyAnchorEl && (
        <CapacityPolicySelector
          anchorEl={addNewPolicyAnchorEl}
          onSelect={handleTeamPolicySelection}
          onRequestCreateCustomPolicy={handleRequestCreateCustomPolicy}
          onClose={() => setAddNewPolicyAnchorEl(undefined)}
        />
      )}
      {isCreateCustomPolicyModalOpen && teamMember?.id && (
        <AddEditCapacityPolicyModal
          isCustom
          teamMembershipIds={[teamMember.id]}
          onToggle={() => setIsCreateCustomPolicyModalOpen(false)}
        />
      )}
    </>
  );
};
