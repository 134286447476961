import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import { CHART_TYPE } from 'ReportsModule/constants';

import { getIsSplitLoaded, getUserSlug } from 'selectors';
import { navigateToHome } from 'actionCreators';

import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import DevProps from 'components/dev/DevProps';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const ForecastChartContainer = lazy(() =>
  import('./ForecastReportChart/ForecastChartContainer')
);

const BillingForecastTab = () => {
  const { billingForecastReportFlag } = useFeatureFlags();
  const isSplitLoaded = useSelector(getIsSplitLoaded);
  const userSlug = useSelector(getUserSlug);
  const { filterId, viewBy, pageName, activeFilter, filterSections } =
    useReportsViewFilter();
  const dispatch = useDispatch();

  // redirect user if feature flag is off
  useEffect(() => {
    if (!billingForecastReportFlag && isSplitLoaded) {
      dispatch(navigateToHome({ userSlug }));
    }
  }, [dispatch, isSplitLoaded, userSlug, billingForecastReportFlag]);

  if (!billingForecastReportFlag) return null;

  return (
    <DynamicModuleLoader
      modules={[
        getUtilizationModule(),
        getCapacitiesModule(),
        getBudgetModule()
      ]}
    >
      <DevProps
        data={{
          filterId,
          viewBy,
          pageName,
          activeFilter,
          filterSections,
          billingForecastReportFlag
        }}
      />
      <Suspense fallback={<div />}>
        <ForecastChartContainer
          activeFilter={activeFilter}
          filterStateId="billing-forecast"
          viewBy={viewBy}
          pageName={pageName}
          filterSections={filterSections}
          isBillingForecast
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};

export default BillingForecastTab;
