import AddViewTasksIcon from 'images/addviewtasksicon.svg';

export const moduleTextHash = {
  1: 'Time',
  2: 'Spent',
  3: 'Progress',
  4: 'Billing',
  5: 'Variance',
  6: 'Scope'
};
export const moduleHash = {
  1: 'budget',
  2: 'time',
  3: 'profit',
  4: 'billing',
  5: 'variance',
  6: 'scopes'
};

export const defaultNavModuleIds = [1, 6, 2, 4, 3, 5];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'Project Budget Details',
  2: 'Project Timesheet Details',
  3: 'Project Profit Details',
  4: 'Project Billing Details',
  5: 'Project Variance Details',
  6: 'Project Scope Details'
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#78c946',
  3: '#78c946',
  4: '#78c946',
  5: '#78c946',
  6: '#78c946'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon})`,
  2: `url(${AddViewTasksIcon})`,
  3: `url(${AddViewTasksIcon})`,
  4: `url(${AddViewTasksIcon})`,
  5: `url(${AddViewTasksIcon})`,
  6: `url(${AddViewTasksIcon})`
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
