import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchOrganizationSettings = {
  request: () => action(constants.FETCH_ORGANIZATION_SETTINGS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_ORGANIZATION_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ORGANIZATION_SETTINGS.FAILURE, {
      payload: { error }
    })
};

export const updateOrganizationSettings = {
  request: () => action(constants.UPDATE_ORGANIZATION_SETTINGS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_ORGANIZATION_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_ORGANIZATION_SETTINGS.FAILURE, {
      payload: { error }
    })
};

export const getAuthMfaStatusPerAccountActionCreatorsMap = {
  request: (id, requestPayload) =>
    action(constants.GET_AUTH_MFA_STATUS_PER_ACCOUNT.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload) =>
    action(constants.GET_AUTH_MFA_STATUS_PER_ACCOUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.GET_AUTH_MFA_STATUS_PER_ACCOUNT.FAILURE, {
      payload: { error }
    })
};

export const toggleIndividualMfaActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.TOGGLE_INDIVIDUAL_MFA.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.TOGGLE_INDIVIDUAL_MFA.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.TOGGLE_INDIVIDUAL_MFA.FAILURE, {
      payload: { error }
    })
};

export const toggleMfaResetActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.TOGGLE_MFA_RESET.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, requestPayload) =>
    action(constants.TOGGLE_MFA_RESET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.TOGGLE_MFA_RESET.FAILURE, {
      payload: { error }
    })
};
