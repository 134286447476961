import React from 'react';
import { connect } from 'react-redux';
import {
  getPlannerProjectsAndPhases,
  getPhaseSearchOffset,
  getIsLoadingPhases,
  getTotalProjectCount,
  getAuth,
  getMe,
  getOOOProject,
  getIsOnOwnTimesheets
} from 'selectors';
import {
  addMemberToProject,
  fetchPhasesByProjectIds,
  fetchAllProjects
} from 'actionCreators';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import Row from './Row';
import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import keyBy from 'lodash/keyBy';

const byId = (item) => item && item.id;
const noop = () => {};

const copy = {
  headerInitial: 'Select Project',
  searchPlaceholder: 'Search project or select below'
};
const itemHeight = 54;
const listItemStyle = `
  border-bottom: none;
  position: relative;
  cursor: none;
  padding: 0;
  &:hover {
    background:none;
  }`;
class ProjectsAndPhasesDropdown extends React.Component {
  state = {
    joinModalOpen: false,
    selectedProject: null,
    selectedItem: null
  };

  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: [
        'title',
        'description',
        'project_title',
        'project_description',
        'project_number',
        'client',
        'phaseTitles'
      ]
    });

  renderItem = (props) => {
    const { isOnOwnTimesheets, suggestedProjectHash } = this.props;
    const { item } = props;
    const isSuggestedProject =
      item.isSuggestedProject ??
      (suggestedProjectHash && suggestedProjectHash[item.id]);
    const isSuggestedPhase = item.isSuggestedPhase;

    const showPhaseStatus = isOnOwnTimesheets && !!item.budget_status;
    return (
      <Row
        {...props}
        itemHeight={itemHeight}
        showPhaseStatus={showPhaseStatus}
        showSelectPhaseText
        classes={isOnOwnTimesheets && 'home-timesheet-add-time-entry'}
        isSuggestedProject={isSuggestedProject}
        isSuggestedPhase={isSuggestedPhase}
      />
    );
  };

  handleSelect = (e, { item, selectCallback }) => {
    const { handleSelect, accountId } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (!item.is_phase && !item.defaultOrMainPhaseId) {
      return;
    }
    const accountIds = keyBy(
      item.member_account_ids || item.project.member_account_ids || []
    );
    // used in places where no account id exists yet - don't assert membership on non-entity
    if (accountIds[accountId] || !accountId) {
      handleSelect(item);
      if (selectCallback) {
        selectCallback();
      }
    } else {
      const project = item.is_phase ? item.project : item;
      this.setJoinProject(project, item);
    }
  };

  setJoinProject = (project, item) => {
    this.setState({
      selectedProject: project,
      selectedItem: item,
      joinModalOpen: true
    });
  };

  clearJoinProject = () =>
    this.setState({ selectedProject: null, joinModalOpen: false });

  joinProject = () => {
    const { addMemberToProject, accountId, handleSelect, handleClose } =
      this.props;
    const { selectedProject, selectedItem } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: () => handleSelect(selectedItem),
        selector: noop
      }
    ];
    addMemberToProject(
      selectedProject.id,
      accountId,
      basicMemberRole,
      selectedProject.board_id,
      onSuccess
    );
    handleClose();
    this.clearJoinProject();
  };

  loadMoreItems = ({ search }) => {
    const { fetchPhasesByProjectIds, offset, isLoading, fetchAllProjects } =
      this.props;
    if (!isLoading) {
      fetchAllProjects({
        searchText: search,
        offset,
        limit: 60
      });
      fetchPhasesByProjectIds({
        search,
        all: true,
        offset,
        limit: 60
      });
    }
  };

  loadInitialItems = ({ search }) => {
    const { fetchPhasesByProjectIds, fetchAllProjects, OOOProject } =
      this.props;
    fetchAllProjects({
      searchText: search,
      offset: 0,
      limit: 60
    });
    if (OOOProject?.id) {
      fetchPhasesByProjectIds({ projectIds: [OOOProject.id] });
    }
    fetchPhasesByProjectIds({
      search,
      all: true,
      offset: 0,
      limit: 60
    });
  };

  hasNextPage = () => {
    const { totalCount, offset } = this.props;
    return offset < totalCount;
  };

  handleClose = (e) => {
    const { joinModalOpen } = this.state;
    const { handleClose } = this.props;
    if (!joinModalOpen) {
      handleClose(e);
    }
  };

  render() {
    const {
      projectsAndPhases,
      target,
      totalCount,
      text,
      me,
      accountId,
      popoverClassName,
      isLoading
    } = this.props;

    // We are checking for the length === 1 because the PTO project is always here by defaults
    const showSkeletonLoader = isLoading && projectsAndPhases.length === 1;
    const { joinModalOpen, selectedProject } = this.state;
    return (
      <>
        {!joinModalOpen && (
          <MultiStepFlyout
            copy={copy}
            items={projectsAndPhases}
            isLoading={showSkeletonLoader}
            getItemId={byId}
            idKey="id"
            renderItem={this.renderItem}
            handleSelect={this.handleSelect}
            handleSubmit={this.handleSubmit}
            itemFilter={this.itemFilter}
            isWhite
            searchEnabled
            editDisabled
            top={0}
            target={target}
            handleClose={this.handleClose}
            loadMoreItems={this.loadMoreItems}
            loadInitialItems={this.loadInitialItems}
            hasNextPage={this.hasNextPage}
            totalCount={totalCount}
            hideFooter
            itemHeight={itemHeight}
            listItemContainerStyle={listItemStyle}
            popoverClassName={`projects-and-phases-dropdown ${popoverClassName}`}
            listWidth="324"
            listHeight={280}
          />
        )}
        <JoinProjectModal
          open={joinModalOpen}
          toggle={this.clearJoinProject}
          selectedProject={selectedProject}
          onConfirm={this.joinProject}
          text={text}
          accountId={accountId}
          currentUserId={me.id}
        />
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  auth: getAuth(state),
  me: getMe(state),
  projectsAndPhases:
    ownProps.projectsAndPhases || getPlannerProjectsAndPhases(state, ownProps),
  offset: getPhaseSearchOffset(state),
  isLoading: getIsLoadingPhases(state),
  isOnOwnTimesheets: getIsOnOwnTimesheets(state),
  totalCount: getTotalProjectCount(state),
  OOOProject: getOOOProject(state)
});

const mapDispatchToProps = {
  fetchPhasesByProjectIds,
  addMemberToProject,
  fetchAllProjects
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsAndPhasesDropdown);
