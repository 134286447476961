import { useCallback } from 'react';
import { serializeId } from 'appUtils';
import {
  BaseTableListsBuilder,
  BaseTableList,
  BaseTableListItemsBuilder,
  BaseTableListItem
} from 'components/Table/types';
import { Merge } from 'type-fest';

/**
 * The partial list that is created by GenericGroupedListsBuilder. Remaining properties are added
 * by the formatList function
 */
type PartialList<ListType extends BaseTableList> = Pick<
  ListType,
  | 'id'
  | 'sectionId'
  | 'listItems'
  | 'isList'
  | 'isFullyLoaded'
  | 'totalCount'
  | 'addEmptyRow'
  | 'baseOffset'
  | 'fetchOffset'
  | 'toggleCollapse'
  | 'isOpen'
>;

/**
 * A function that will add remaining properties to the given partialList, which just has common list properties.
 * If it returns a falsey value, the list will be skipped by the builder
 */
export type FormatList<
  ListType extends BaseTableList,
  AdditionalParams extends Record<string, unknown> = Record<never, never>
> = ({
  orderId,
  partialList,
  parentGroupId
}: Merge<
  {
    orderId: string | number;
    partialList: PartialList<ListType>;
    parentGroupId?: string | number;
  },
  AdditionalParams
>) => ListType | Falsy;

export interface UseGenericGroupedListsBuilderParams<
  ListItemsBuilderType extends BaseTableListItemsBuilder,
  FormatListType extends FormatList<BaseTableList>
> {
  /** Can be used for preventing unnecessary hook behaviour */
  isOff?: boolean;
  listItemsBuilder: ListItemsBuilderType;
  toggleCollapse: (id: string) => void;
  getIsOpen: (id: string) => boolean;
  // ordersByGroup and groupTotalCounts are only optional if not provided as args to the callback
  ordersByGroup?: Record<string | number, (string | number)[]>;
  groupTotalCounts?: Record<string | number, number>;
  formatList: FormatListType;
  /** Used when serializing listId. Any string to set it apart from other lists */
  listDescription: string;
  getOrderToUse?: (order: (string | number)[]) => (string | number)[];
}

/**
 * Params that may be shared by different grouped list builders
 */
export type GenericGroupedListsBuilderSharedParams<
  ListItemsBuilderType extends BaseTableListItemsBuilder = BaseTableListItemsBuilder
> = Pick<
  UseGenericGroupedListsBuilderParams<ListItemsBuilderType, never>,
  | 'listItemsBuilder'
  | 'getIsOpen'
  | 'toggleCollapse'
  | 'ordersByGroup'
  | 'groupTotalCounts'
  | 'isOff'
>;

/**
 * Returns a pre-configured list builder that supports collapse and lazy loading
 */
export const useGenericGroupedListsBuilder = <
  ListItemsBuilderType extends BaseTableListItemsBuilder<
    BaseTableListItem,
    Record<string, unknown>
  >,
  FormatListType extends FormatList<BaseTableList>,
  ListType extends BaseTableList = FormatListType extends FormatList<infer T>
    ? T
    : never
>({
  listItemsBuilder,
  formatList,
  getOrderToUse,
  getIsOpen,
  toggleCollapse,
  ordersByGroup,
  groupTotalCounts,
  listDescription
}: UseGenericGroupedListsBuilderParams<
  ListItemsBuilderType,
  FormatListType
>) => {
  const groupedListsBuilder: BaseTableListsBuilder<
    ListType,
    {
      // ordersByGroup and groupTotalCounts are only optional if not provided as args to the hook
      ordersByGroup?: Record<string | number, number[]>;
      groupTotalCounts?: Record<string | number, number>;
      additionalListItemsBuilderParams?: ListItemsBuilderType extends BaseTableListItemsBuilder<
        infer L,
        infer A
      >
        ? A
        : never;
      additionalFormatListParams?: FormatListType extends FormatList<
        ListType,
        infer A
      >
        ? A
        : never;
    }
  > = useCallback(
    ({
      order,
      parentGroupId,
      ordersByGroup: _ordersByGroup,
      groupTotalCounts: _groupTotalCounts,
      additionalListItemsBuilderParams,
      additionalFormatListParams
    }) => {
      // small optimization to avoid unnecessary Array.filter
      let hasHiddenLists = false;
      let baseOffset = 0;

      // Modify the order if needed
      const orderToUse = getOrderToUse ? getOrderToUse(order) : order;

      // allow these as hook params or callback params
      const ordersByGroupToUse = ordersByGroup || _ordersByGroup || {};
      const groupTotalCountsToUse = groupTotalCounts || _groupTotalCounts || {};

      const lists = orderToUse.reduce<Record<string | number, ListType>>(
        (acc, orderId) => {
          const listId = serializeId({
            itemType: listDescription,
            ids: [orderId],
            id: undefined // TODO
          });

          const totalLoadableCount = groupTotalCountsToUse[orderId] || 0;
          const subOrder = ordersByGroupToUse[orderId] || [];

          const listItems = listItemsBuilder({
            order: subOrder,
            parentGroupId: orderId,
            ...additionalListItemsBuilderParams
          });

          const isListOpen = getIsOpen(listId);

          const partialList = {
            id: listId,
            sectionId: listId,
            listItems,
            isList: true,
            isFullyLoaded: subOrder.length === totalLoadableCount,
            totalCount: totalLoadableCount,
            addEmptyRow: isListOpen,
            baseOffset,
            fetchOffset: baseOffset + subOrder.length,
            toggleCollapse: () => toggleCollapse(listId),
            isOpen: isListOpen
          } as PartialList<ListType>;

          // Add missing/custom/override properties
          const list = formatList({
            orderId,
            partialList,
            parentGroupId,
            ...additionalFormatListParams
          });

          // Skip the list if it is null
          if (!list) {
            hasHiddenLists = true;
            return acc;
          }

          // add group's total loadable sub-count here so next list's baseOffset is correct
          baseOffset += totalLoadableCount;
          acc[orderId] = list as ListType;
          return acc;
        },
        {}
      );

      const orderedLists = order.map((id) => lists[id]);
      return (
        hasHiddenLists ? orderedLists.filter((list) => list) : orderedLists
      ) as ListType[];
    },
    [
      listDescription,
      formatList,
      getIsOpen,
      groupTotalCounts,
      listItemsBuilder,
      ordersByGroup,
      toggleCollapse,
      getOrderToUse
    ]
  );

  return groupedListsBuilder;
};
