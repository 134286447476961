import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeamId, getAuthToken } from 'selectors';
import { archiveUnarchiveProject, fetchAllProjects } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';
import SimpleConfirmModal, {
  Body,
  StyledModal
} from 'components/Modals/SimpleConfirmModal';

const MutedText = styled.span`
  color: ${theme.colors.colorCalendarGray};
`;
const StyleWrapper = styled(StyledModal)`
  &.modal-dialog {
    top: 25%;
  }
  .modal-content {
    width: 505px;
  }
  ${Body} {
    margin-bottom: 25px;
  }
`;

const ArchiveProjectModal = ({ project, isOpen, toggle, confirmCallback }) => {
  const teamId = useSelector(getSelectedTeamId);
  const token = useSelector(getAuthToken);
  const dispatch = useDispatch();

  const onConfirm = () => {
    const permissions = {
      projectId: project.id,
      teamId: teamId,
      boardId: project.board_id
    };
    const payload = {
      token,
      projectId: project.id,
      isActive: false,
      position: project.position || 0, //
      permissions
    };
    dispatch(archiveUnarchiveProject(payload));
    dispatch(fetchAllProjects({ projectIds: [project.id] }));
    if (confirmCallback) {
      confirmCallback();
    }
  };

  return (
    <SimpleConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      header="Archive Project"
      body={
        <>
          Are you sure you want to Archive this Project?
          <br />{' '}
          <MutedText>
            This Project will be moved to Archived on Portfolios, removed from
            all members 'My Projects' list, and new time entries cannot be added
            to Timesheets.
          </MutedText>
        </>
      }
      onConfirm={onConfirm}
      stylesWrapper={StyleWrapper}
      submitButtonColor={theme.colors.colorMediumOrange2}
      confirmOnLeft
      confirmLabel="Archive"
    />
  );
};

export default ArchiveProjectModal;
