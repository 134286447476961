import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getGroupsHash, getAllGroups, getPersonalBoards } from 'selectors';
import { Board } from 'models/board';

/**
 * aka groups aka portfolios
 */
export const useBoards = () => {
  const boardHash = useAppSelector(getGroupsHash) as Record<number, Board>;

  const nonPersonalBoards = useAppSelector(getAllGroups) as Board[];

  const personalBoards = useAppSelector(getPersonalBoards) as Board[];

  // All boards are loaded and there is always at least 1 board, so if any boards exist then they are loaded
  const isLoaded = nonPersonalBoards.length + personalBoards.length > 0;

  const getBoardLabel = useCallback(
    (boardId: number | string) => {
      return boardHash[+boardId]?.name ?? 'No Portfolio';
    },
    [boardHash]
  );

  return {
    boardHash,
    getBoardLabel,
    isLoaded,
    nonPersonalBoards,
    personalBoards
  };
};
