import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type GuestIconProps = Pick<SvgIconProps, 'width' | 'height'>;

const GuestIcon = ({ width = 14, height = 14 }: GuestIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 14 14" fill="none">
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        fill="white"
        stroke="#CCCCCC"
        strokeWidth="0.8"
      />
      <path
        d="M6.48535 6.99121H9.31738V10.6924C8.94303 10.8161 8.55404 10.9154 8.15039 10.9902C7.74674 11.0618 7.28939 11.0977 6.77832 11.0977C6.06868 11.0977 5.46647 10.9577 4.97168 10.6777C4.47689 10.3978 4.10091 9.98438 3.84375 9.4375C3.58659 8.89062 3.45801 8.21842 3.45801 7.4209C3.45801 6.6722 3.60124 6.02441 3.8877 5.47754C4.17741 4.93066 4.59896 4.50749 5.15234 4.20801C5.70898 3.90853 6.3877 3.75879 7.18848 3.75879C7.56608 3.75879 7.9388 3.79948 8.30664 3.88086C8.67448 3.96224 9.00814 4.06803 9.30762 4.19824L8.80469 5.40918C8.58659 5.2985 8.33757 5.20573 8.05762 5.13086C7.77767 5.05599 7.4847 5.01855 7.17871 5.01855C6.73926 5.01855 6.35677 5.11947 6.03125 5.32129C5.70898 5.52311 5.45833 5.80632 5.2793 6.1709C5.10352 6.53223 5.01562 6.95866 5.01562 7.4502C5.01562 7.91569 5.0791 8.3291 5.20605 8.69043C5.33301 9.0485 5.5332 9.33008 5.80664 9.53516C6.08008 9.73698 6.43652 9.83789 6.87598 9.83789C7.09082 9.83789 7.27148 9.82812 7.41797 9.80859C7.56771 9.78581 7.70768 9.76302 7.83789 9.74023V8.25098H6.48535V6.99121Z"
        fill="#828282"
      />
    </SvgIcon>
  );
};

export default GuestIcon;
