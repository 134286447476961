import { createReducer, Draft } from '@reduxjs/toolkit';
import { CurrencyState } from '../types';
import { fetchEntityOptionsActionCreatorsMap } from 'EntityOptionsModule/actionCreators';
import { updateEntityDefaultCurrencyActionCreatorsMap } from 'CurrencyModule/actionCreators';

export const initialState: CurrencyState = {
  defaultCurrency: {}
};

const handleFetchDefaultCurrencySuccess = (
  state: Draft<CurrencyState>,
  action: ReturnType<typeof fetchEntityOptionsActionCreatorsMap.success>
) => {
  const { response, initialPayload } = action.payload;

  const entityType = response.entity_type;
  const currency = response.currency;
  const entityId = initialPayload.entity_id;

  if (entityId) {
    state.defaultCurrency[entityType] = {
      ...state.defaultCurrency[entityType],
      [entityId]: currency
    };
  } else {
    const teamId = initialPayload.team_id;

    state.defaultCurrency.selectedTeam = {
      ...state.defaultCurrency.selectedTeam,
      [teamId]: currency
    };
  }
};

const currency = createReducer(initialState, (builder) => {
  builder.addCase(
    fetchEntityOptionsActionCreatorsMap.success,
    handleFetchDefaultCurrencySuccess
  );

  builder.addCase(
    updateEntityDefaultCurrencyActionCreatorsMap.success,
    handleFetchDefaultCurrencySuccess
  );
});

export default currency;
