import { ModalHeader } from 'reactstrap';
import React from 'react';
import {
  ModalHeaderWrapper,
  ModalHeaderLeft,
  ModalHeaderRight
} from 'BudgetModule/components/styles';

const renderDefaultCopy = (id, copy) =>
  `${
    id ? 'Edit' : copy === 'Milestone' ? 'Create Standard' : 'Create New'
  } ${copy}`;
const GlobalAddEditHeader = ({ id, isRemove, copy, renderCopy }) => (
  <ModalHeader>
    <ModalHeaderWrapper>
      {isRemove ? (
        ' '
      ) : (
        <>
          <ModalHeaderLeft>
            {renderCopy ? renderCopy() : renderDefaultCopy(id, copy)}
          </ModalHeaderLeft>
          <ModalHeaderRight />
        </>
      )}
    </ModalHeaderWrapper>
  </ModalHeader>
);

export default GlobalAddEditHeader;
