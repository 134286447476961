import { SplitDividerVariant } from './types';

export const SPLIT_SCREEN_Z_INDICES = {
  Divider: 98,
  CloseButton: 99
};

export const THROTTLE_DELAY = 50;

export const DIVIDER_HEIGHT_BY_VARIANT: Record<SplitDividerVariant, number> = {
  [SplitDividerVariant.Compact]: 8,
  [SplitDividerVariant.Thick]: 24
};
