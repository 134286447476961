import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface LockIconProps extends SvgIconProps {
  fill?: string;
}

const LockIcon = ({
  width = '10',
  height = '14',
  fill = '#fff',
  ...svgIconProps
}: LockIconProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 10 14" {...svgIconProps}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.793 8.878a.75.75 0 0 1-.385.645v1.64h-.77v-1.64a.748.748 0 0 1-.385-.645c0-.42.344-.761.77-.761.424 0 .77.34.77.761zM10 6.965v5.393A1.15 1.15 0 0 1 8.845 13.5h-7.69A1.15 1.15 0 0 1 0 12.358V6.965c0-.63.518-1.143 1.155-1.143h.018V4.308C1.173 2.208 2.9.5 5.023.5c2.122 0 3.85 1.708 3.85 3.808v1.518A1.147 1.147 0 0 1 10 6.965zM1.943 5.822V4.308c0-1.68 1.381-3.046 3.08-3.046 1.698 0 3.08 1.366 3.08 3.046v1.514h-6.16z"
    />
  </SvgIcon>
);

export default LockIcon;
