import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { ToggleContainer, ToggleOption } from 'components/styles';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import cn from 'classnames';

const StyledToggleContainer = styled(ToggleContainer)`
  width: fit-content;
  border: 1px solid ${theme.colors.colorLightGray9};
  border-radius: 4px;
  margin: 9px 0 12px 1px;
`;
const StyledToggleOption = styled(ToggleOption)`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
  padding: 3px 8px;
  white-space: nowrap;
  color: ${({ isActive }) =>
    isActive ? 'white' : theme.colors.colorCalendarGray};
  background: ${({ isActive, notActiveBackground }) =>
    isActive ? theme.colors.colorCalendarBlue : notActiveBackground || 'white'};
  &:hover {
    color: ${({ isActive }) =>
      isActive ? 'white' : theme.colors.colorCalendarGray};
  }
`;

const ViewToggle = ({
  currentValue,
  onSelect,
  options,
  optionToFlag,
  notActiveBackground = '',
  className = ''
}) => {
  const { isLoaded, ...enabledFlags } = useFeatureFlags();

  // if feature flags are provided, filter out options accordingly
  const filteredOptions = optionToFlag
    ? options.filter((option) => {
        const flagName = optionToFlag[option.value];
        return !flagName || (isLoaded && enabledFlags[flagName]);
      })
    : options;

  const renderOption = ({ label, value, dataTip = '' }) => (
    <StyledToggleOption
      key={value}
      isActive={currentValue === value}
      onClick={() => onSelect(value)}
      className="view-toggle-option"
      notActiveBackground={notActiveBackground}
      data-testid={`view-toggle-${value}`}
      data-for="app-tooltip"
      data-effect="solid"
      data-html
      data-class="center"
      data-tip={dataTip}
    >
      {label}
    </StyledToggleOption>
  );

  return (
    <StyledToggleContainer className={cn('view-toggle-container', className)}>
      {filteredOptions.map(renderOption)}
    </StyledToggleContainer>
  );
};

export default React.memo(ViewToggle);
