import { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectHash, getFetchedProjectIds } from 'selectors';
import { fetchAllProjects } from 'actionCreators';
import { mergedThrottle } from 'appUtils/mergedThrottle';

/**
 * Provides a function that can be used to throttle fetch projects
 *
 * Also see useFetchUnloadedProjects for a different way of loading projects
 */
export const useLoadProjects = () => {
  const dispatch = useDispatch();
  const projectHash = useSelector(getProjectHash);
  const fetchedProjectIds = useSelector(getFetchedProjectIds);

  const throttledFetchProjects = useRef(
    mergedThrottle<number[], number[]>({
      delay: 300,
      initialValue: [],
      merge: ({ value, mergedValue }) => {
        return [...mergedValue, ...value];
      },
      func: ({ mergedValue }) => {
        dispatch(
          fetchAllProjects({ projectIds: Array.from(mergedValue), all: true })
        );
      }
    })
  ).current;

  /**
   * When this function is called multiple times, the ids will be accumulated and fetched
   * in throttled batches
   */
  const loadProjects = useCallback(
    (idOrIds: number[] | number) => {
      const idsToFetch = (Array.isArray(idOrIds) ? idOrIds : [idOrIds]).filter(
        (id: number) => !(fetchedProjectIds[id] || projectHash[id])
      );

      if (idsToFetch.length > 0) {
        throttledFetchProjects({ value: idsToFetch });
      }
    },
    [fetchedProjectIds, projectHash, throttledFetchProjects]
  );

  return { loadProjects };
};
