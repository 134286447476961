import { useState } from 'react';
import cn from 'classnames';
import { CommentRow, GetCommentsPermission } from './CommentRow';
import { Comment } from 'models/comment';
import { useDispatch } from 'react-redux';
import { createEntityComment, updateEntityComment } from 'actionCreators';
import { ReplyCommentContainer } from './ReplyCommentContainer';
import { CommentFormValues } from './CommentForm';

interface CommentThreadContainerProps {
  comment: Comment;
  onDelete: ({
    id,
    parentCommentId
  }: {
    id: number;
    parentCommentId?: number;
  }) => void;
  getCommentsPermission: GetCommentsPermission;
  editCommentDisabledTooltip: string;
  deleteCommentDisabledTooltip: string;
}
export const CommentThreadContainer = ({
  comment,
  onDelete,
  getCommentsPermission,
  editCommentDisabledTooltip,
  deleteCommentDisabledTooltip
}: CommentThreadContainerProps) => {
  const dispatch = useDispatch();
  const [isReplyOpen, setIsReplyOpen] = useState(!!comment.replies.length);

  const openReplyInput = () => {
    setIsReplyOpen(true);
  };

  const handleUpdate = ({
    id,
    description,
    files
  }: {
    id: number;
  } & CommentFormValues) => {
    dispatch(
      updateEntityComment({
        id,
        description,
        files
      })
    );
  };

  const handleReply = ({ description, files }: CommentFormValues) => {
    dispatch(
      createEntityComment({
        parentEntityId: comment.parent_entity_id,
        parentEntityType: comment.parent_entity_type,
        parentCommentId: comment.id,
        description,
        files
      })
    );
  };

  const handleCancel = () => {
    if (!comment.replies.length) {
      setIsReplyOpen(false);
    }
  };

  const { canEditComment } = getCommentsPermission({
    accountId: comment.account.id
  });

  return (
    <div className="comment-thread-container">
      <div
        className={cn('comment-thread', {
          'rounded-bottom':
            comment.replies && !comment.replies.length && !isReplyOpen
        })}
      >
        <CommentRow
          comment={comment}
          onUpdate={handleUpdate}
          onDelete={onDelete}
          openReplyInput={openReplyInput}
          replyInputOpen={isReplyOpen}
          getCommentsPermission={getCommentsPermission}
          editCommentDisabledTooltip={editCommentDisabledTooltip}
          deleteCommentDisabledTooltip={deleteCommentDisabledTooltip}
        />
      </div>
      {isReplyOpen && (
        <ReplyCommentContainer
          onReply={handleReply}
          isDisabled={!canEditComment}
          formTooltip={editCommentDisabledTooltip}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};
