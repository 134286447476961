import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActivityPhaseHash,
  getScopeHash,
  getAllActivityRowInfo,
  getFlatPhasesHash
} from 'selectors';
import { fetchScopes, navigateToScopeModal } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';

import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ModalBody } from 'reactstrap';
import { PlaceholderCard } from './shared/PlaceholderCard';
import { ScopeCard } from './shared/ScopeCard';
import ProjectsThenPhasesSelector from './ProjectsThenPhasesSelector';
import NewScopeModal from 'components/Scope/modals/create-edit/ScopeModal/NewScopeModal';
import SideBarBackArrowIcon from 'icons/SideBarBackArrowIcon';
import ReactTooltip from 'react-tooltip';
import {
  PlusCircleIconHoverable,
  MinusCircleIconHoverable
} from 'icons/PlusMinusCircleIcon';

export const AddScopeStep = ({
  bar,
  isNew,
  projectId,
  accountId,
  phaseId,
  activityPhaseId,
  activityId,
  onGoBack,
  scopes,
  onAddScope,
  onRemoveScope
}) => {
  const dispatch = useDispatch();
  const [isNewScopeModalOpen, setIsNewScopeModalOpen] = useState(false);

  // assuming that already have activities and activityPhases
  const activitiesHash = useSelector(getAllActivityRowInfo);
  const activityPhaseHash = useSelector(getActivityPhaseHash);
  const scopeHash = useSelector(getScopeHash);
  const phaseHash = useSelector(getFlatPhasesHash);

  const activity = activitiesHash[activityId];

  const activityPhaseScopeOrder = useMemo(
    () => activityPhaseHash[activityPhaseId]?.project_scope_order,
    [activityPhaseHash, activityPhaseId]
  );

  const orderedActivityPhaseScopes = useMemo(
    () =>
      activityPhaseScopeOrder
        ?.map((id) => scopeHash[id])
        .filter((item) => item),
    [activityPhaseScopeOrder, scopeHash]
  );

  const filteredActivityPhaseScopes = useMemo(() => {
    const ids = scopes.map(({ id }) => id);
    return orderedActivityPhaseScopes?.filter(({ id }) => !ids.includes(id));
  }, [orderedActivityPhaseScopes, scopes]);

  const phase = phaseHash[phaseId];
  const phaseTitle =
    phase && (phase.is_default_phase || phase.is_main ? null : phase.name);

  const scopeListTitle =
    activity && (activity.is_default ? phaseTitle : activity.title);

  const shouldDisplayTitle = !!scopeListTitle;

  const handleAddClick = (scope) => (event) => {
    ReactTooltip.hide();
    onAddScope && onAddScope(scope);
  };
  const handleRemoveClick = (scope) => () => {
    ReactTooltip.hide();
    onRemoveScope && onRemoveScope(scope);
  };

  const handleClickNewScope = () => {
    setIsNewScopeModalOpen(true);
  };

  const handleClickScope = (scopeId) => () => {
    // require route setup to open up scope modal
    dispatch(
      navigateToScopeModal({
        scopeId
      })
    );
  };

  const handleCreateScopeSuccess = (scope) => {
    onAddScope(scope);
  };

  useEffect(() => {
    if (activityPhaseId)
      dispatch(fetchScopes({ all: true, activityPhaseIds: [activityPhaseId] }));
  }, [activityPhaseId, dispatch]);

  useEffect(() => {
    rebuildTooltip();
  }, [scopes, filteredActivityPhaseScopes]);

  return (
    <>
      <RootContainer>
        <ScopeModalHeader>
          <TitleContainer>Add Scope</TitleContainer>
          <BackButton onClick={onGoBack}>
            <SideBarBackArrowIcon
              fill={theme.colors.colorCalendarBlue}
              className="sidebar-backarrow-icon"
            />
          </BackButton>
        </ScopeModalHeader>
        <ScrollContainer>
          <ProjectContainer>
            <ProjectsThenPhasesSelector
              projectId={projectId}
              accountId={accountId}
              phaseId={phaseId}
              activityId={activityId}
              projectStepEnabled
              renderPhaseDates
              selectionDisabled
              pickerLocation="add-scope-color-picker"
            />
          </ProjectContainer>
          <ModalBody>
            <ScopeListContainer>
              <ListItemContainer>
                <LeftContainer />
                <PlaceholderCard
                  onClick={handleClickNewScope}
                  title="New Scope"
                />
                <RightContainer />
              </ListItemContainer>
              {scopes?.map((scope) => (
                <ListItemContainer key={scope.id}>
                  <LeftContainer />
                  <ScopeCard
                    scope={scope}
                    onClick={handleClickScope(scope.id)}
                  ></ScopeCard>
                  <RightContainer>
                    <ClickableContainer
                      data-for="app-tooltip"
                      data-tip="Remove from work plan"
                      data-effect="solid"
                      onClick={handleRemoveClick(scope)}
                    >
                      <MinusCircleIconHoverable height={22} width={22} />
                    </ClickableContainer>
                  </RightContainer>
                </ListItemContainer>
              ))}
            </ScopeListContainer>
            {!!filteredActivityPhaseScopes?.length && (
              <>
                {shouldDisplayTitle && (
                  <ListSubHeader>{scopeListTitle}</ListSubHeader>
                )}
                <ScopeListContainer>
                  {filteredActivityPhaseScopes?.map((scope) => (
                    <ListItemContainer key={scope.id}>
                      <LeftContainer />
                      <ScopeCard
                        key={scope.id}
                        scope={scope}
                        onClick={handleClickScope(scope.id)}
                      ></ScopeCard>
                      <RightContainer>
                        <ClickableContainer
                          data-for="app-tooltip"
                          data-tip="Add to work plan"
                          data-effect="solid"
                          onClick={handleAddClick(scope)}
                        >
                          <PlusCircleIconHoverable height={22} width={22} />
                        </ClickableContainer>
                      </RightContainer>
                    </ListItemContainer>
                  ))}
                </ScopeListContainer>
              </>
            )}
          </ModalBody>
          <Spacer />
        </ScrollContainer>
      </RootContainer>
      <NewScopeModal
        isNew={isNew}
        bar={bar}
        projectId={projectId}
        phaseId={phaseId}
        activityId={activityId}
        activityPhaseId={activityPhaseId}
        toggle={() => setIsNewScopeModalOpen(false)}
        isOpen={isNewScopeModalOpen}
        onCreateSuccess={{
          successAction: handleCreateScopeSuccess,
          selector: (payload, response) => response.scope
        }}
      />
    </>
  );
};

const RootContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ScopeModalHeader = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  background: ${theme.colors.colorPureWhite};
  padding: 30px 30px 20px;
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const BackButton = styled.button`
  position: absolute;
  border: none;
  background: none;
  left: 24px;
  margin: auto;
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 26px;
  width: 100%;
  font-weight: 600;
`;
const ProjectContainer = styled.div`
  height: 111px;
  width: 100%;
  padding: 10px 59px;
  background: ${theme.colors.colorPureWhite};
  margin-bottom: 30px;
`;

const ScopeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-bottom: 30px;
`;

const ListSubHeader = styled.div`
  position: relative;
  left: 60px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ListItemContainer = styled.div`
  display: flex;
  min-height: 60px;
`;

const BaseSideContainer = styled.div`
  flex-shrink: 0;
  margin: auto;
`;

const LeftContainer = styled(BaseSideContainer)`
  width: 79px;
`;
const RightContainer = styled(BaseSideContainer)`
  width: 84px;
`;

const Spacer = styled.div`
  width: 100%;
  height: 50px;
`;

const ClickableContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 7px;
`;
