import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isNumeric } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportRate
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingRatesByRateDescription: Record<string, CsvImportRate>;
  newRateDescriptions: React.MutableRefObject<Record<string, boolean>>;
  newRateDescriptionAmountHashes: React.MutableRefObject<
    Record<string, string>
  >;
}

export const rateDescriptionValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingRatesByRateDescription,
    newRateDescriptions,
    newRateDescriptionAmountHashes
  } = additionalParams;

  const { enteredRateAmount, enteredRateDescription } =
    getRowValuesForEntityType(row, entityType);

  if (!enteredRateDescription) return true;
  else if (enteredRateDescription === TRAINING_DATA_REQUIRED_STRING)
    return false;
  const existingMatchedRateByDescription =
    existingRatesByRateDescription[enteredRateDescription];
  const newRateAmountMatchedByDescription =
    newRateDescriptionAmountHashes.current[enteredRateDescription];
  const enteredRateDescriptionIsNewOption =
    !existingMatchedRateByDescription &&
    !newRateAmountMatchedByDescription &&
    !newRateDescriptions.current[enteredRateDescription];
  const enteredRateAmountDoesNotMatchDescription =
    existingMatchedRateByDescription?.rate !== enteredRateAmount &&
    newRateAmountMatchedByDescription !== enteredRateAmount;
  if (
    enteredRateAmount &&
    enteredRateAmount !== TRAINING_DATA_REQUIRED_STRING
  ) {
    // check if there exists rate matched by entered rate description
    if (
      !existingMatchedRateByDescription &&
      !newRateAmountMatchedByDescription
    ) {
      if (isNumeric(enteredRateAmount))
        // rate amount + rate description is new combination, only add into hash if rate amount is valid number
        newRateDescriptionAmountHashes.current[enteredRateDescription] =
          enteredRateAmount;
    } else if (
      // there exists matched rate amount but it does not match entered rate amount
      enteredRateAmountDoesNotMatchDescription
    ) {
      return ERROR_MESSAGES.rateDoesNotMatchDescription;
    }
  } else if (
    // there is no entered rate amount and rate description is new
    enteredRateDescriptionIsNewOption
  )
    newRateDescriptions.current[enteredRateDescription] = true;
  return true;
};
