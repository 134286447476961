import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

export const StyledViewMore = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
`;

const ViewMoreButton = ({ numNotVisible }) => {
  return <StyledViewMore>View {numNotVisible} More</StyledViewMore>;
};

export default ViewMoreButton;
