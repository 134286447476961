import { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import theme from 'theme';
import moment from 'moment';
import {
  DateDiv,
  DateProgress,
  DateDash,
  DependencyLinkIconContainer
} from './styles';
import {
  isStartDateDependency,
  isEndDateDependency,
  generateNewDependencyInfos,
  mapNewPhaseDependencies
} from 'appUtils/newDependencies';

import {
  getRemainingWorkDays,
  getPhaseProgressTooltipContent,
  getPhaseProgress,
  isPhaseArchived
} from 'appUtils/phaseDisplayUtils';
import {
  predictWorkdaysAndUpdatePhase,
  fetchUserActivitiesForActionable
} from 'actionCreators';
import {
  DEPENDENCY_STRINGS_DISPLAY,
  DEPENDENCY_TYPES
} from 'appConstants/workload';
import NewDependencyDateRangeCalendar from 'components/DateRangeCalendar/NewDependencyDateRangeCalendar';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import { getFlatPhasesHash } from 'selectors';
import { nullifyInvalidDate } from 'appUtils/momentUtils';
import { DATE_DISPLAY_TINY } from 'appConstants/date';
import {
  EDIT_MILESTONE_DATES_TIP,
  EDIT_PHASE_DATES_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import cn from 'classnames';
import { actionableTypesHash } from 'appConstants/userActivities';
import noop from 'lodash/noop';
import { useCanEditPhaseDates } from 'PermissionsModule/SpaceLevelPermissions/hooks/project/useCanEditPhaseDates';
import { useAppSelector } from 'reduxInfra/hooks';
import { getPhasesByProjectHash } from 'ProjectsModule/phases/selectors';
import {
  dependencyItemType,
  parentChildKeyMap
} from 'components/Dependency/DependencyV2/constants';
import { mapEntityDependencies } from 'components/Dependency/DependencyV2/components/utils';
import { useDependencyFormState } from 'components/Dependency/DependencyV2/hooks/useDependencyFormState';

const MilestoneTableStartDateCell = (props) => {
  const dispatch = useDispatch(null);
  const [newWorkDays, setNewWorkDays] = useState(null);
  const phasesHash = useSelector(getFlatPhasesHash);

  const {
    phase,
    row,
    project: { id: projectId }
  } = props;

  const orderedPhases = row.original.orderedPhases;
  const { total_work_days } = phase || {};
  const displayWorkDays =
    newWorkDays !== null ? newWorkDays : phase ? total_work_days : '';
  const remainingWorkDays = getRemainingWorkDays(phase);
  const currentProgressPercent =
    ((displayWorkDays - remainingWorkDays) / displayWorkDays) * 100 || 0;

  const canEditPhaseDates = useCanEditPhaseDates({ projectId });

  const onSuccessActionsForDatesUpdate = [
    {
      selector: noop,
      successAction: () => {
        if (phase?.project_id) {
          dispatch(
            fetchUserActivitiesForActionable({
              project_ids: [phase.project_id],
              actionable_type: actionableTypesHash.Phase,
              limit: 500,
              keepCurrentActivitiesOnTrigger: true
            })
          );
        }
      }
    }
  ];

  const handlePredictionAndUpdateStartDate = ({
    startDate,
    dependency,
    dependencyItem
  }) => {
    const formattedStartDate = nullifyInvalidDate(startDate, 'MM/DD/YYYY');

    const dependencyInfos = generateNewDependencyInfos({
      item: phase,
      dependency,
      dependencyItem,
      dependableType: DEPENDENCY_TYPES.PHASE
    });
    dispatch(
      predictWorkdaysAndUpdatePhase({
        phase,
        startDate: formattedStartDate,
        endDate: !isPhase ? formattedStartDate : phase.end_date,
        dependencyInfos,
        meta: {
          onSuccess: onSuccessActionsForDatesUpdate
        }
      })
    );
  };

  const handlePredictionAndUpdateDates = ({
    startDate,
    endDate,
    dependency,
    dependencyItem
  }) => {
    const formattedStartDate = nullifyInvalidDate(startDate, 'MM/DD/YYYY');
    const formattedEndDate = nullifyInvalidDate(endDate, 'MM/DD/YYYY');

    const dependencyInfos = generateNewDependencyInfos({
      item: phase,
      dependency,
      dependencyItem,
      dependableType: DEPENDENCY_TYPES.PHASE
    });

    dispatch(
      predictWorkdaysAndUpdatePhase({
        phase,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        dependencyInfos: dependencyInfos,
        meta: {
          onSuccess: onSuccessActionsForDatesUpdate
        }
      })
    );
  };

  const isPhase = phase.is_budget;
  const isArchived = isPhaseArchived(phase);
  const phaseProgress = getPhaseProgress(phase);
  const noDependencyTooltip = getPhaseProgressTooltipContent(phaseProgress);

  const isDisabled = !canEditPhaseDates || isArchived;

  const nonArchivedPhases = useMemo(() => {
    return orderedPhases
      ? orderedPhases
          .filter(
            (orderedPhase) =>
              orderedPhase &&
              !orderedPhase.is_archived &&
              orderedPhase.is_budget &&
              orderedPhase.id !== phase.id &&
              orderedPhase.start_date
          )
          .map((orderedPhase) => ({
            dependableId: orderedPhase.id,
            dependableType: 'Phase',
            label: orderedPhase.name,
            startDate: orderedPhase.start_date,
            endDate: orderedPhase.end_date
          }))
      : [];
  }, [orderedPhases, phase]);

  const { initialDependency, initialDependencyItem } = mapNewPhaseDependencies({
    phase,
    phasesHash
  });

  const { id: phaseId } = phase;

  /**
   * parentDependencies: Current Phase blocking / matches
   * childDependencies: Current Phase waiting / matches
   */

  const parentDependencies = useMemo(
    () =>
      mapEntityDependencies({
        entityDependencies: phase.child_dependencies,
        keyMap: parentChildKeyMap.parent
      }),
    [phase.child_dependencies]
  );

  const childDependencies = useMemo(
    () =>
      mapEntityDependencies({
        entityDependencies: phase.dependencies,
        keyMap: parentChildKeyMap.child
      }),
    [phase.dependencies]
  );

  const parentChildDependencies = useMemo(
    () => [...parentDependencies, ...childDependencies],
    [parentDependencies, childDependencies]
  );

  const flexibleDependencyFormState = useDependencyFormState({
    dependencies: parentChildDependencies,
    baseItemProps: {
      baseItemId: phaseId,
      baseItemType: phase.is_budget
        ? dependencyItemType.phase
        : dependencyItemType.milestone
    }
  });

  const getDependencyTooltip = () => {
    const startDependencyName = isStartDateDependency(initialDependency.start)
      ? `Phase Start Dependent on ${
          DEPENDENCY_STRINGS_DISPLAY[initialDependency.start]
        } of ${initialDependencyItem.start.name}`
      : undefined;
    const endDependencyName = isEndDateDependency(initialDependency.end)
      ? `Phase End Dependent ${
          DEPENDENCY_STRINGS_DISPLAY[initialDependency.end]
        } of ${initialDependencyItem.end.name}`
      : undefined;

    const dependencyText = [startDependencyName, endDependencyName]
      .filter(Boolean)
      .join(`<br /> <br />`);

    return `${dependencyText}`;
  };

  const singleDateCustomInput = (startDate, endDate, handleOpen) => (
    <InputDiv
      data-for="app-tooltip"
      data-effect="solid"
      data-tip={
        !canEditPhaseDates
          ? EDIT_MILESTONE_DATES_TIP
          : isArchived
          ? 'Unable to set dates for archived phases.'
          : ''
      }
      data-class="mw-250"
      data-offset="{ 'top': -25 }"
      data-html
      isSelected={!!startDate}
      onClick={!isDisabled ? handleOpen : undefined}
      className={cn({ disabled: isDisabled }, 'single-date-input')}
      noHover={isDisabled}
    >
      {phase.dependencies.length > 0 && (
        <DependencyLinkIconContainer isMilestone>
          <DependencyLinkIcon fillColor="#333333" />
        </DependencyLinkIconContainer>
      )}
      <DateDiv
        className={cn({ disabled: isDisabled }, 'start-date-div')}
        isSetDate={!startDate}
        onClick={!isDisabled ? handleOpen.bind(this) : undefined}
      >
        {startDate ? moment(startDate).format(DATE_DISPLAY_TINY) : 'Plan'}
      </DateDiv>
    </InputDiv>
  );

  return (
    <Container>
      {isPhase && phase?.start_date && phase?.end_date ? (
        <InputDiv
          data-for="app-tooltip"
          data-tip={
            !canEditPhaseDates
              ? EDIT_PHASE_DATES_TIP
              : isArchived
              ? 'Unable to set dates for archived phases.'
              : phase.dependencies.length > 0
              ? getDependencyTooltip()
              : noDependencyTooltip
          }
          data-class="mw-250"
          data-html
          data-effect="solid"
          data-offset="{ 'top': -25 }"
          isPhase={isPhase}
          isSelected={phase?.start_date}
          className={cn({ disabled: isDisabled })}
          noHover={isDisabled}
        >
          <NewDependencyDateRangeCalendar
            showInputs
            itemStartDate={phase.start_date}
            itemEndDate={phase.end_date}
            customInput={(startDate, endDate, handleOpen) => (
              <DateLabelContainer
                onClick={!isDisabled ? handleOpen : undefined}
              >
                <DateDiv>
                  {!!initialDependency.start && (
                    <DependencyLinkIconContainer>
                      <DependencyLinkIcon fillColor="#333333" />
                    </DependencyLinkIconContainer>
                  )}
                  <span>
                    {startDate
                      ? moment(startDate).format(DATE_DISPLAY_TINY)
                      : 'Start Date'}
                  </span>
                </DateDiv>
                <DateDash>
                  <span>-</span>
                </DateDash>
                <DateDiv>
                  {!!initialDependency.end && (
                    <DependencyLinkIconContainer isEndDate>
                      <DependencyLinkIcon fillColor="#333333" />
                    </DependencyLinkIconContainer>
                  )}
                  <span>
                    {endDate
                      ? moment(endDate).format(DATE_DISPLAY_TINY)
                      : 'End Date'}
                  </span>
                </DateDiv>
              </DateLabelContainer>
            )}
            onSubmit={handlePredictionAndUpdateDates}
            onClear={handlePredictionAndUpdateDates}
            phases={nonArchivedPhases}
            isPhaseCalendar
            showWorkdays
            shouldRenderFooter
            showClear
            initialDependency={initialDependency}
            itemWorkDays={phase.total_work_days}
            item={phase}
            itemType={DEPENDENCY_TYPES.PHASE}
            initialDependencyItem={initialDependencyItem}
            initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
            calendarClassNames={'phase-calendar'}
            flexibleDependencyFormState={flexibleDependencyFormState}
          />
          <DateProgress
            currentProgress={currentProgressPercent}
            customStyles={DateProgressCustomStyles}
            showBorder={currentProgressPercent === 0}
          />
        </InputDiv>
      ) : isPhase ? (
        <InputDiv
          data-for="app-tooltip"
          data-effect="solid"
          data-tip={!canEditPhaseDates ? EDIT_PHASE_DATES_TIP : undefined}
          data-tip-disable={canEditPhaseDates}
          data-class="mw-250"
          data-offset="{ 'top': -25 }"
          data-html
          className={cn({ disabled: isDisabled })}
          noHover={isDisabled}
        >
          <NewDependencyDateRangeCalendar
            showInputs
            itemStartDate={phase?.start_date}
            itemEndDate={phase?.end_date}
            customInput={(startDate, endDate, handleOpen) => (
              <DateLabelContainer
                onClick={!isDisabled ? handleOpen : undefined}
              >
                <DateDiv
                  isSetDate
                  className={cn({ disabled: isDisabled }, 'set-date-phase')}
                >
                  Plan
                </DateDiv>
              </DateLabelContainer>
            )}
            onSubmit={handlePredictionAndUpdateDates}
            onClear={handlePredictionAndUpdateDates}
            phases={nonArchivedPhases}
            isPhaseCalendar
            showWorkdays
            shouldRenderFooter
            showClear
            itemType={DEPENDENCY_TYPES.PHASE}
            item={phase}
            initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
            calendarClassNames={'phase-calendar'}
            flexibleDependencyFormState={flexibleDependencyFormState}
          />
        </InputDiv>
      ) : (
        <NewDependencyDateRangeCalendar
          itemStartDate={phase.start_date}
          labels={{ start: 'DATE' }}
          showInputs
          onSubmit={handlePredictionAndUpdateStartDate}
          onClear={handlePredictionAndUpdateStartDate}
          showClear
          isSingleDay
          customInput={singleDateCustomInput}
          shouldRenderFooter
          phases={nonArchivedPhases}
          isMilestoneCalendar
          itemType={DEPENDENCY_TYPES.MILESTONE}
          item={phase}
          initialDependency={initialDependency}
          initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
          initialDependencyItem={initialDependencyItem}
          calendarClassNames={'phase-calendar'}
          flexibleDependencyFormState={flexibleDependencyFormState}
        />
      )}
    </Container>
  );
};

export default MilestoneTableStartDateCell;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .popover-ref-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const InputDiv = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: relative;
  color: ${({ isSelected, theme }) =>
    !isSelected ? theme.colors.colorRoyalBlue : ``};

  &:not(.disabled):hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
    ${DependencyLinkIconContainer} {
      path {
        stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }
    span {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }

    ${DateDiv} {
      &.start-date-div {
        color: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
`;

const DateLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const DateProgressCustomStyles = css`
  height: 21px;
  top: 23px;
`;
