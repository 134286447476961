import React from 'react';
import ProjectThreeDotMenuTrigger from './ProjectThreeDotMenuTrigger';
import ProjectThreeDotMenuFlyout from './ProjectThreeDotMenuFlyout';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
class ProjectThreeDotMenu extends React.Component {
  state = {
    isOpen: false
  };

  componentDidUpdate(prevProps) {
    rebuildTooltip();

    if (!prevProps.shouldClose && this.props.shouldClose) {
      this.handleClose();
      this.setState({ isOpen: false });
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = (e) => {
    const { modalStep, closeModalStep, isModalOpen, onCloseCallback } =
      this.props;
    if (onCloseCallback) {
      onCloseCallback(e);
      this.setState({ isOpen: false });
    }
    if (modalStep) {
      closeModalStep(e);
    } else if (!isModalOpen) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { isOpen } = this.state;
    const {
      children,
      vertical = false,
      align = 'left',
      initialColor,
      threeDotClassName,
      projectTitle,
      phaseTitle,
      memberName,
      testIdPrefix,
      size,
      threeDotHoverBackgroundColor
    } = this.props;
    return (
      <ProjectThreeDotMenuTrigger
        handleClick={this.handleOpen}
        open={isOpen}
        vertical={vertical}
        initialColor={initialColor}
        threeDotClassName={threeDotClassName}
        projectTitle={projectTitle}
        memberName={memberName}
        testIdPrefix={testIdPrefix}
        phaseTitle={phaseTitle}
        size={size}
        threeDotHoverBackgroundColor={threeDotHoverBackgroundColor}
      >
        {isOpen && (
          <ProjectThreeDotMenuFlyout
            handleClose={this.handleClose}
            align={align}
          >
            {children}
          </ProjectThreeDotMenuFlyout>
        )}
      </ProjectThreeDotMenuTrigger>
    );
  }
}

export default ProjectThreeDotMenu;
