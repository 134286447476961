import React, { useState, useRef, useMemo, useCallback } from 'react';
import uuid from 'uuid';
import mapValues from 'lodash/mapValues';
import {
  BatchActionMenuWrapper,
  BatchActionMenuItem,
  BatchActionMenuIcon
} from './styles';
import { SelectBoardDropdownContainer } from 'views';
import MoveIcon from 'icons/MoveIcon';
import CopyBatchIcon from 'icons/CopyBatchIcon';
import TrashBatchIcon from 'icons/TrashBatchIcon';
import Popover from 'components/Popover';
import { useDispatch, useSelector } from 'react-redux';
import {
  moveProjectsToBoard,
  unselectAllProjects,
  cloneProjects,
  archiveUnarchiveProject,
  batchArchiveUnarchiveProject,
  deleteProject
} from 'actionCreators';
import {
  getSelectedProjectIds,
  getSelectedBoard,
  getGroups,
  getAuth,
  getSelectedTeamId,
  getHasTimeEntries
} from 'selectors';
import { hasAssociatedTime } from 'appUtils/budgetUtils';
import ProjectCloneModal from './projectCloneModal/ProjectCloneModal';
import { DeleteModal } from '..';
import { ArchiveIcon } from 'icons/ArchiveIcon';
import { useCheckPermission } from 'hocs/withPermissionsCheck';
import useCan from 'appUtils/hooks/useCan';
import {
  deletePortfolioProject,
  archivePortfolioProject
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import {
  DELETE_PROJECT_TIP,
  ARCHIVE_PROJECT_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';

const ProjectBatchActionMenu = ({ statusId }) => {
  const selectedProjectIds = useSelector(getSelectedProjectIds);
  const boards = useSelector(getGroups);
  const selectedBoard = useSelector(getSelectedBoard);
  const hasTimeEntries = useSelector(getHasTimeEntries);
  const dispatch = useDispatch();
  const [openMoveMenu, setOpenMoveMenu] = useState(false);
  const [openCloneMenu, setOpenCloneMenu] = useState(false);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [cloneDestination, setCloneDestination] = useState(null);
  const teamId = useSelector(getSelectedTeamId);
  const cloneTarget = useRef(null);
  const auth = useSelector(getAuth);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const checkPermission = useCheckPermission();

  const handleClearAll = () => dispatch(unselectAllProjects());

  const permissions = useMemo(() => {
    return {
      boardId: selectedBoard?.id,
      teamId: teamId
    };
  }, [selectedBoard?.id, teamId]);

  const showDelete = useMemo(() => {
    return !Object.keys(selectedProjectIds).find((projectId) => {
      const project = selectedProjectIds[projectId];
      return hasTimeEntries?.find(
        (hasTimeEntry) =>
          hasTimeEntry.project_id === project.id &&
          hasAssociatedTime(hasTimeEntry)
      );
    });
  }, [selectedProjectIds, hasTimeEntries]);

  const canDeleteProject = useCan(deleteProject, permissions);
  const canDeletePortfolioProject = useCan(deletePortfolioProject, permissions);

  const canDelete = canDeleteProject && canDeletePortfolioProject;

  const onDeleteMenuClick = () => {
    if (canDelete) {
      setDeleteIsOpen(true);
    }
  };

  const renderDeleteBody = () => {
    return (
      <>
        Are you sure you want to <b>permanently</b> delete the selected
        Projects? You can’t restore a deleted Project. Projects with time
        entries will not be deleted.
      </>
    );
  };

  const deleteOnClick = () => {
    Object.keys(selectedProjectIds).forEach((projectId) => {
      const project = selectedProjectIds[projectId];
      dispatch(
        deleteProject({
          projectId: project.id,
          isActive: !project.archived_at,
          isPersonal: project.is_personal,
          groupId: selectedBoard?.id,
          groupSlug: selectedBoard?.slug,
          permissions
        })
      );
    });
    handleClearAll();
    setDeleteIsOpen(false);
  };

  const renderDeleteConfirm = () => {
    return <button onClick={deleteOnClick}>Yes</button>;
  };

  const canArchiveProject = useCan(archiveUnarchiveProject, permissions);
  const canArchivePortfolioProject = useCan(
    archivePortfolioProject,
    permissions
  );

  const canArchive = canArchiveProject && canArchivePortfolioProject;

  const handleBatchArchive = () => {
    if (canArchive) {
      dispatch(
        batchArchiveUnarchiveProject({
          token: auth.token,
          projects: selectedProjectIds,
          isActive: statusId !== 0,
          permissions
        })
      );
      handleClearAll();
    }
  };

  const handleMove = (destinationBoard) => {
    setOpenMoveMenu(false);
    const projectIds = mapValues(selectedProjectIds, (project) => project.id);
    const params = {
      projectIds: projectIds,
      boardId: destinationBoard.id
    };
    dispatch(moveProjectsToBoard(params));
  };

  const handleSelectDestination = (destinationBoard) => {
    setCloneDestination(destinationBoard);
    setOpenCloneMenu(false);
    setOpenCloneModal(true);
  };

  const handleClone = (flags) => {
    setOpenCloneModal(false);
    const projectIds = mapValues(selectedProjectIds, (project) => project.id);
    const params = {
      projectIds: projectIds,
      targetBoard: cloneDestination.id,
      flags,
      batchId: uuid()
    };
    dispatch(cloneProjects(params));
  };

  const selectedProjectIdsLength = Object.keys(selectedProjectIds).length;

  return (
    selectedProjectIdsLength > 0 && (
      <BatchActionMenuWrapper>
        <BatchActionMenuItem onClick={handleClearAll}>
          Clear Selected {selectedProjectIdsLength}
        </BatchActionMenuItem>
        <BatchActionMenuItem onClick={setOpenMoveMenu} id="MoveItem">
          <BatchActionMenuIcon>
            <MoveIcon />
          </BatchActionMenuIcon>
          Move
          <Popover
            isOpen={openMoveMenu}
            target="MoveItem"
            closePopover={() => setOpenMoveMenu(false)}
            placement="bottom"
          >
            <SelectBoardDropdownContainer
              isOpen
              groupList={boards}
              group={selectedBoard}
              changeSelectedGroup={handleMove}
            />
          </Popover>
        </BatchActionMenuItem>
        {/*   <BatchActionMenuItem
          onClick={() => setOpenCloneMenu(true)}
          ref={cloneTarget}
        >
          <BatchActionMenuIcon>
            <CopyBatchIcon />
          </BatchActionMenuIcon>
          Clone
          <Popover
            isOpen={openCloneMenu}
            target={cloneTarget}
            closePopover={() => setOpenCloneMenu(false)}
            placement="bottom"
          >
            <SelectBoardDropdownContainer
              isOpen
              groupList={boards}
              group={selectedBoard}
              changeSelectedGroup={handleSelectDestination}
            />
          </Popover>
          {openCloneModal && (
            <ProjectCloneModal
              handleClone={handleClone}
              handleCancel={() => setOpenCloneModal(false)}
            />
          )}
        </BatchActionMenuItem> */}
        <BatchActionMenuItem
          data-for={'app-tooltip'}
          data-tip={ARCHIVE_PROJECT_TIP}
          data-tip-disable={canArchive}
          data-effect="solid"
          onClick={handleBatchArchive}
          ref={cloneTarget}
        >
          <BatchActionMenuIcon>
            <ArchiveIcon width={12} height={12} fill={'#0074d9'} />
          </BatchActionMenuIcon>
          {statusId === 0 ? 'Archive' : 'Unarchive'}
        </BatchActionMenuItem>
        {showDelete && (
          <BatchActionMenuItem
            onClick={onDeleteMenuClick}
            ref={cloneTarget}
            data-for={'app-tooltip'}
            data-tip={DELETE_PROJECT_TIP}
            data-tip-disable={canDelete}
            data-effect="solid"
          >
            <BatchActionMenuIcon>
              <TrashBatchIcon />
            </BatchActionMenuIcon>
            Delete
            <DeleteModal
              isOpen={deleteIsOpen}
              toggle={() => setDeleteIsOpen(false)}
              deleteOnClick={deleteOnClick}
              renderBody={renderDeleteBody}
              renderDelete={renderDeleteConfirm}
              component={'project'}
            />
          </BatchActionMenuItem>
        )}
      </BatchActionMenuWrapper>
    )
  );
};

export default ProjectBatchActionMenu;
