import React, { useState, useCallback } from 'react';
import {
  MemberCapacityCell,
  DayHeader,
  StyledMemberCapacityInput
} from './styles';
import styled from 'styled-components';
import theme from 'theme';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const StandardsCapacityInput = styled(StyledMemberCapacityInput)`
  background: ${({ hour }) =>
    hour ? theme.colors.colorPureWhite : 'transparent'};
  color: ${theme.colors.colorSemiDarkGray6};
  font-weight: ${({ isTotal }) => (isTotal ? 'bold' : 'normal')};
`;

export const dayOfWeekFormats = {
  M: {
    name: 'monday',
    label: 'M'
  },
  T: {
    name: 'tuesday',
    label: 'T'
  },
  W: {
    name: 'wednesday',
    label: 'W'
  },
  TH: {
    name: 'thursday',
    label: 'T'
  },
  F: {
    name: 'friday',
    label: 'F'
  },
  SA: {
    name: 'saturday',
    label: 'S'
  },
  SU: {
    name: 'sunday',
    label: 'S'
  }
};

const CapacityDay = ({
  day,
  capacity = {},
  handleChange,
  handleOnBlur,
  isTotal = false,
  workloadSettings
}) => {
  const [showUnit, setShowUnit] = useState(true);
  const totalHours = Object.values(dayOfWeekFormats).reduce((acc, day) => {
    const hour = Number(capacity[day.name]);
    return isNaN(hour) ? acc : (acc += hour);
  }, 0);

  const dayOfWeek = dayOfWeekFormats[day];
  const longFormatDay = isTotal ? 'TOTAL' : dayOfWeek.name;

  const hourToUse = isTotal ? totalHours : capacity[dayOfWeek.name];

  const handleValueChange = useCallback(
    (values, sourceInfo) => {
      if (sourceInfo.event) {
        handleChange({ value: values.value, event: sourceInfo.event });
      }
    },
    [handleChange]
  );

  return (
    <MemberCapacityCell>
      <DayHeader>{dayOfWeek?.label || day}</DayHeader>
      <StandardsCapacityInput
        value={hourToUse ? formatNumWithMaxTwoDecimals(hourToUse) : ''}
        name={longFormatDay}
        hour={hourToUse}
        isTotal={isTotal}
        disabled={isTotal}
        onFocus={() => setShowUnit(false)}
        onBlur={() => {
          setShowUnit(true);
          handleOnBlur();
        }}
        decimalScale={2}
        allowNegative={false}
        isNumericString
        suffix={showUnit ? 'h' : undefined}
        onValueChange={handleValueChange}
      />
    </MemberCapacityCell>
  );
};

export default CapacityDay;
