import moment from 'moment';

class TaskDateUtilities {
  static isValid(date) {
    return moment(date).isValid;
  }

  static isOverdue(date) {
    return moment().isAfter(date, 'day');
  }

  static isToday(date) {
    return moment().isSame(date, 'day');
  }
}

export default TaskDateUtilities;
