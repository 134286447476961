import React from 'react';
import { makeDateFromDateTime } from 'appUtils/momentUtils';
import styled from 'styled-components';

import {
  TaskCalendarToggle,
  ToggleIconWrapper,
  ToggleTextWrapper
} from './styles/components';
import TaskDateUtilities from 'appUtils/TaskDateUtilities';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';

const TaskRangePickerInput = ({
  startDate,
  endDate,
  taskDateType,
  taskIsCreating,
  taskIsEditing,
  alwaysRenderInput,
  icon,
  bulk,
  currentFilter,
  taskEditProperty,
  isTaskCompleted,
  projectDetailView,
  isTaskModal,
  openCalendar,
  hasStartDependency,
  hasEndDependency
}) => {
  const generateTaskDateStatus = () => {
    const datesToCheck = [];
    if (startDate) {
      datesToCheck.push(startDate);
    }
    if (endDate) {
      datesToCheck.push(endDate);
    }
    const isOverDue = datesToCheck.every(TaskDateUtilities.isOverdue);
    const isToday =
      datesToCheck.length &&
      TaskDateUtilities.isToday(datesToCheck[datesToCheck.length - 1]);

    if (!datesToCheck.every((date) => !date) || isTaskCompleted)
      return 'past_scheduled';
    if (isOverDue) return 'past_scheduled';
    if (isToday) return 'today';
    return 'future';
  };

  const taskStatus = generateTaskDateStatus();
  // TODO: Test and refactor if neccassary
  const generateTaskDateText = () => {
    if (bulk) {
      return 'All';
    }

    if (startDate) {
      if (taskStatus === 'today') return 'Today';
      return makeDateFromDateTime(startDate).format('MMM D');
    }

    return 'Schedule';
  };
  const generateEndText = () => {
    if (bulk) {
      return '';
    }

    if (endDate) {
      return ` - ${makeDateFromDateTime(endDate).format('MMM D')}`;
    }

    return '';
  };

  const text = generateTaskDateText() + generateEndText();

  return (
    <TaskCalendarToggle
      taskStatus={taskStatus}
      taskDateType={taskDateType}
      selectedDate={startDate}
      taskIsCreating={taskIsCreating}
      alwaysRenderInput={alwaysRenderInput}
      isTaskModal={isTaskModal}
      currentFilter={currentFilter}
      bulk={bulk}
      taskIsEditing={taskIsEditing}
      taskEditProperty={taskEditProperty}
      projectDetailView={projectDetailView}
      onClick={openCalendar}
      isRange
      hasDependency={hasStartDependency || hasEndDependency}
    >
      {icon && (
        <ToggleIconWrapper
          taskIsEditing={taskIsEditing}
          taskIsCreating={taskIsCreating}
          taskStatus={taskStatus}
          taskDateType={taskDateType}
        >
          {icon && React.cloneElement(icon, { taskStatus })}
        </ToggleIconWrapper>
      )}
      {hasStartDependency && (
        <DependencyLinkIconContainer>
          <DependencyLinkIcon />
        </DependencyLinkIconContainer>
      )}
      <ToggleTextWrapper bulk={bulk}>{text}</ToggleTextWrapper>

      {hasEndDependency && (
        <DependencyLinkIconContainer>
          <DependencyLinkIcon />
        </DependencyLinkIconContainer>
      )}
    </TaskCalendarToggle>
  );
};

const DependencyLinkIconContainer = styled.div`
  margin-top: -3px;
`;

export default TaskRangePickerInput;
