import {
  makeVerification,
  makeAuthorBasedVerification
} from '../makeVerification';

// Set project managers
export const verifyCreateProjectManagers = makeVerification(
  'create_project_managers'
);

// Currently commented out in the BE
// export const verifyCreateProjects = makeVerification('create_projects');

// No known applicable_permissions for edit_project_privacy in the BE
// export const verifyEditProjectPrivacy = makeVerification(
//   'edit_project_privacy'
// );

export const verifyArchiveProjects = makeVerification('archive_projects');

// Currently commented out in the BE
// export const verifyDeleteProjects = makeVerification('delete_projects');

export const verifyInviteGuestsToProjects = makeVerification('create_guests');

export const verifyCreateProjectMemberships = makeVerification(
  'create_project_memberships'
);

export const verifyEditProjectMemberships = makeVerification(
  'edit_project_memberships'
);

export const verifyDeleteProjectMemberships = makeVerification(
  'delete_project_memberships'
);

export const verifyEditProjectComments = makeAuthorBasedVerification(
  'edit_project_comments',
  'edit_project_comments_other'
);

export const verifyEditTaskComments = makeAuthorBasedVerification(
  'edit_task_comments',
  'edit_task_comments_other'
);

export const verifyEditWorkPlanComments = makeAuthorBasedVerification(
  'edit_workplan_comment',
  'edit_workplan_comment_other'
);

export const verifyDeleteProjectComments = makeAuthorBasedVerification(
  'delete_project_comments',
  'delete_project_comments_other'
);

export const verifyDeleteTaskComments = makeAuthorBasedVerification(
  'delete_task_comments',
  'delete_task_comments_other'
);

export const verifyDeleteWorkPlanComments = makeAuthorBasedVerification(
  'delete_workplan_comment',
  'delete_workplan_comment_other'
);

export const verifyDeleteTasks = makeAuthorBasedVerification(
  'delete_tasks',
  'delete_tasks_other'
);

// Currently commented out in the BE
// export const verifyCloneProjects = makeVerification('clone_projects');

export const verifyEditProjectViews = makeVerification('edit_project_views');

export const verifyEditProjectColors = makeVerification('edit_project_colors');

export const verifyEditProjectDates = makeVerification('edit_project_dates');

export const verifyEditPhaseDates = makeVerification('edit_phase_dates');
// Commented out in the applicable_permissions array for `edit_schedules` in the BE
// export const verifyEditWorkPlanDates = makeVerification(
//   'edit_activity_phase_schedule_bar_dates'
// );

// Currently commented out in the BE
// export const verifyReadProjectBudgets = makeVerification('read_budget_ui');

export const verifyReadBudgetCostRate = makeVerification(
  'read_budget_by_cost_rate'
);
