import { reducerMap } from '../reducers';
import budgetSaga from '../sagas';

export function getBudgetModule() {
  return {
    id: 'budget',
    reducerMap,
    sagas: [budgetSaga]
    // Actions to fire when this module is added/removed
    //initialActions: []
    // finalActions: []
  };
}
