import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openModulesModal } from 'actionCreators';
import styled from 'styled-components';

const AddModuleListItem = ({ openModulesModal }) => (
  <Add onClick={openModulesModal}>Add View</Add>
);

const Add = styled.li`
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`;

const mapDispatchToProps = {
  openModulesModal
};
export default withRouter(connect(null, mapDispatchToProps)(AddModuleListItem));
