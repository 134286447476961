import { select } from 'redux-saga/effects';
import { getAuthToken } from 'AuthenticationModule/selectors';
import { fetchEntity } from 'sagas/generics';
import {
  updateEntityDefaultCurrency,
  updateEntityDefaultCurrencyActionCreatorsMap
} from '../actionCreators';
import * as api from '../api';

export function* updateEntityDefaultCurrencyWorker(
  action: ReturnType<typeof updateEntityDefaultCurrency>
) {
  const token = yield select(getAuthToken);
  const { team_id, entity_type, entity_id, currency } = action.payload;

  yield fetchEntity(
    updateEntityDefaultCurrencyActionCreatorsMap,
    api.updateEntityDefaultCurrency,
    null,
    [token, { team_id, entity_id, entity_type, currency }],
    action
  );
}
