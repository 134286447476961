export const ESTIMATED_TOOLTIP =
  'Target is the target fee<br />after subtracting profit';
export const ESTIMATED_HRS_TOOLTIP = 'Estimated Hours';
export const SPENT_TOOLTIP =
  'Spent are hours entered on <br/> Timesheets. If Timesheets are off, <br/> Spent are past Work Plan hours.';
export const PLANNED_TOOLTIP =
  'Planned hours are Work Plan <br/> hours from today going forward.';
export const REMAINING_TOOLTIP = 'Remaining = <br/> Budget - Spent - Planned';
export const BILLING_ESTIMATE_TOOLTIP =
  'Billing Estimate is used <br/> in Forecast Reports';
export const MEMBERS_REMAINING_TOOLTIP =
  'Remaining = <br/> Budgeted - Spent - Planned';

export const SCOPE_ENABLED_MEMBER_ESTIMATION_DISABLED_TOOLTIP =
  'Add estimated time from <br/> scope on the left menu.';

export const BUDGET_TOOLTIP =
  'Budget is used to account for <br/> profit and to front or backload fees.';

export const PERCENT_OF_FEE_TOOLTIP = 'Percent of phase budget';

export const TIME_MEMBER_BUDGET_TOOLTIP = 'Time budgeted for this member';
