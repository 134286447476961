import React from 'react';
import SvgIcon from 'components/SvgIcon';

const BudgetPhaseMilestoneIcon = ({
  height = '14',
  width = '15',
  className,
  fillColor = 'white',
  strokeColor = '#828282',
  dollarSignColor = '#b3b3b3',
  strokeWidth
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 14"
    >
      <path
        d="M6.99859 0.707107L13.2929 7.00143L7.00141 13.2929L0.707107 6.99857L6.99859 0.707107Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill="transparent"
        className="milestone-icon-box"
      />
    </SvgIcon>
  );
};

export default BudgetPhaseMilestoneIcon;
