import React from 'react';
import cn from 'classnames';
import { getOnHomePlanner } from 'selectors';
import { connect } from 'react-redux';

const UnscheduledSlideoutButton = ({ isOpen, onOpen, isOnHomePlanner }) => (
  <div
    className={cn('unscheduled-slideout-button', { hide: isOpen })}
    onClick={onOpen}
  >
    Show Unscheduled
  </div>
);

const mapStateToProps = (state) => ({
  isOnHomePlanner: getOnHomePlanner(state)
});

export default connect(mapStateToProps)(React.memo(UnscheduledSlideoutButton));
