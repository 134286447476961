import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { createSelector } from 'reselect';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import { getFlatTimesheets } from './timesheet';
import { getTimesheetsState } from './core';
import flatten from 'lodash/flatten';
import { initialFilterState as timesheetInitialFilterState } from '../reducers/timesheets';
import { getCurrentAccount } from 'AuthenticationModule/selectors';

const moment = extendMoment(Moment);
const emptyArray = [];

const monday = getMondayOfWeek(moment());
const nextMonday = monday.clone().add(1, 'week');
const thePreviousMonday = monday.clone().add(-1, 'week');
const thisWeekDates = [monday, nextMonday.clone().add(-1, 'day')];
const lastWeekDates = [thePreviousMonday, monday.clone().add(-1, 'day')];

const filterTimeEntriesByDateRange = (start, end) => (timeEntries) => {
  const rangeToInclude = moment.range(start, end);
  const timeEntriesToUse = timeEntries.filter((timeEntry) =>
    moment(timeEntry.date).within(rangeToInclude)
  );

  return timeEntriesToUse;
};

export const getMyTimesheets = createSelector(
  getFlatTimesheets,
  getCurrentAccount,
  (timesheets, currentAccount) =>
    timesheets.filter(
      (timesheet) => timesheet.account_id === currentAccount?.id
    )
);

const getOwnFilterStateId = (state, ownProps) =>
  ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id;

export const makeGetTimeEntriesFilterState = () =>
  createSelector(
    getOwnFilterStateId,
    getTimesheetsState,
    (filterStateId, state) =>
      state.filterStates[filterStateId] || timesheetInitialFilterState
  );

export const makeGetTimesheetsByFilter = () =>
  createSelector(makeGetTimeEntriesFilterState(), (state) => state.timesheets);

export const makeGetMyTimesheetsByFilter = () =>
  createSelector(makeGetTimesheetsByFilter(), (timesheets) =>
    flatten(
      Object.values(timesheets).map((timesheetsUnderDescription) =>
        Object.values(timesheetsUnderDescription)
      )
    )
  );

export const getThisWeekTimeEntries = createSelector(
  getMyTimesheets,
  filterTimeEntriesByDateRange(...thisWeekDates)
);

export const getLastWeekTimeEntries = createSelector(
  getMyTimesheets,
  filterTimeEntriesByDateRange(...lastWeekDates)
);

export const makeGetThisWeekTimeEntriesByFilter = () =>
  createSelector(
    makeGetMyTimesheetsByFilter(),
    filterTimeEntriesByDateRange(...thisWeekDates)
  );

export const makeGetLastWeekTimeEntriesByFilter = () =>
  createSelector(
    makeGetMyTimesheetsByFilter(),
    filterTimeEntriesByDateRange(...lastWeekDates)
  );

export const makeGetIsFetchingTimeEntriesByFiler = () =>
  createSelector(
    makeGetTimeEntriesFilterState(),
    (state) => state.isFetchingTimesheet
  );
