import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState, ReducerName } from 'reduxInfra/shared';
import { initialState } from 'reducers/filters';
import { FiltersState } from './types';
import { makeAccountFiltersByPageNameThenName } from './utils';
import { Page, StateAccountFilter } from 'models/filter';

const emptyObj = {};

const getFiltersState: Selector<RootState, FiltersState> = (state) =>
  state[ReducerName.Filters] || initialState;

export const getAccountFilters = createSelector(
  getFiltersState,
  (state) => state.accountFilters
);

/**
 * 'original' means unmodified ie. the filter should match its counterpart in the DB. If the filter
 * is not in this hash, then it has not been modified.
 */
export const getOriginalAccountFilters = createSelector(
  getFiltersState,
  (state) => state.originalAccountFilters
);

/**
 * {
 *   'Timesheet Report': {
 *      'members': {
 *         {
 *            id: 151,
 *            page_name: 'Timesheet Report',
 *            name: 'members',
 *            ...
 *         }
 *      }
 *   }
 * }
 */
export const getAccountFiltersByPageNameThenName = createSelector(
  getAccountFilters,
  (filters) => makeAccountFiltersByPageNameThenName(filters)
);

const getOwnPageName = (
  _: RootState,
  ownProps: {
    pageName: Page | undefined;
  }
) => ownProps.pageName;

export const makeGetAccountFiltersByNameForPageName = () =>
  createSelector<
    [
      Selector<
        RootState,
        ReturnType<typeof getAccountFiltersByPageNameThenName>
      >,
      typeof getOwnPageName
    ],
    Record<string, StateAccountFilter> | undefined
  >(
    getAccountFiltersByPageNameThenName,
    getOwnPageName,
    (accountFiltersByPageNameThenName, pageName) =>
      pageName ? accountFiltersByPageNameThenName[pageName] : emptyObj
  );

export const getAccountFiltersFetched = createSelector(
  getFiltersState,
  (state) => state.fetched
);
export const getIsFetchingAccountFilters = createSelector(
  getFiltersState,
  (state) => state.isFetching
);
