import cn from 'classnames';
import styled from 'styled-components';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { MemberGroup } from 'TimelinesModule/types/memberGroup';
import { MemberInitials } from 'views';
import LastSentTimeStamp from 'views/projectPlanner/LastSentTimeStamp';
import { GroupRenderer } from '../types';
import { MouseEvent } from 'react';
import { CollapseIconButton } from '../CollapseButton';
import EllipsisText, { TooltipBehavior } from 'components/EllipsisText';

type MemberGroupRendererProps = {
  onRoleNameClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onCapacityClick?: (event: MouseEvent<HTMLDivElement>) => void;
  showMemberRole?: boolean;
  showMemberCapacity?: boolean;
};

export const MemberGroupRenderer: GroupRenderer<
  MemberGroup,
  MemberGroupRendererProps
> = ({
  group,
  onRoleNameClick,
  onCapacityClick,
  onToggleCollpase,
  isOpen,
  showMemberRole,
  showMemberCapacity
}) => {
  const shouldShowArchivedText = group.isArchived;
  const shouldShowLastSentTime =
    !group.isArchived && group.workloadScheduledEvent;
  const shouldShowRoleName = showMemberRole && group.roleName;
  const shouldShowTotalCapacity =
    showMemberCapacity &&
    !group.isArchived &&
    group.totalCapacity !== undefined;
  const shouldShowCollapseIcon = !group.isCollapseDisabled;

  return (
    <MemberGroupContainer onClick={onToggleCollpase}>
      <InfoAreaColumn>
        {shouldShowArchivedText && <ArchivedText>ARCHIVED</ArchivedText>}
        {shouldShowLastSentTime && (
          <StyledLastSentTimeStamp
            lastSent={group.workloadScheduledEvent}
            hideWeekOf
            hideTime={!isOpen}
          />
        )}
      </InfoAreaColumn>
      {/* Middle Row */}
      <ArrowAreaColumn className="middle-row">
        {shouldShowCollapseIcon && <CollapseIconButton isCollapsed={!isOpen} />}
      </ArrowAreaColumn>
      <InfoAreaColumn>
        <MemberInitials
          member={{ id: group.accountId }}
          classes="regular-member-no-hover"
        />
        <MemberAttributeContainer>
          <Name className="app-cues-styled-name-container">{group.name}</Name>
          <AbsolutePositionContainer>
            {shouldShowRoleName && (
              <RoleName
                className="app-cues-styled-role"
                onClick={onRoleNameClick}
              >
                {group.roleName}
              </RoleName>
            )}
            {shouldShowTotalCapacity && (
              <CapacityName
                className="app-cues-styled-capacity"
                onClick={onCapacityClick}
              >
                {formatNumWithMaxTwoDecimals(group.totalCapacity)}h/wk
              </CapacityName>
            )}
          </AbsolutePositionContainer>
        </MemberAttributeContainer>
      </InfoAreaColumn>
    </MemberGroupContainer>
  );
};

const StyledLastSentTimeStamp = styled(LastSentTimeStamp)`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 10px;
`;

const ArrowAreaColumn = styled.div`
  grid-row: 1/4;

  &.middle-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const InfoAreaColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MemberGroupContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content 1fr;
  grid-template-columns: 24px minmax(0, 1fr);
  grid-column-gap: 4px;

  line-height: 1.3;
  cursor: pointer;
  height: 100%;

  &:not(:hover) {
    ${StyledLastSentTimeStamp} {
      display: none;
    }
  }

  &:hover {
    ${ArrowAreaColumn} {
      background: ${({ theme }) => theme.colors.colorLightGray19};
    }
  }
`;

const ArchivedText = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray7};
  font-size: 10px;
  font-weight: 600;
`;

const MemberAttributeContainer = styled.div`
  flex: 1;
  position: relative;
  min-width: 0;
  margin-left: 8px;
`;

const Name = styled(EllipsisText).attrs({
  tooltipBehavior: TooltipBehavior.AlwaysDisabled
})`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
`;

const MemberAttributeText = styled(EllipsisText).attrs(({ onClick }) => ({
  className: cn({ clickable: onClick })
}))`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  &.clickable:hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

const AbsolutePositionContainer = styled.div`
  position: absolute;
  top: 93%;
  left: 0;
  right: 0;
  max-width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

const RoleName = styled(MemberAttributeText)``;
const CapacityName = styled(MemberAttributeText)``;
