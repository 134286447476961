import type {
  LeanApiSchema,
  FetchLeanApiSuccessResponse,
  LeanApiSchemaBuilder,
  ValueCounts
} from 'LeanApiModule/types';
import {
  FIELD_NAMES,
  FIELD_RENAME_MAP,
  ORDER_BY
} from 'LeanApiModule/constants';
import {
  buildProjectWhereClause,
  defaultProjectWhereClauses,
  buildProjectSelectClause,
  projectSelectClauses,
  buildProjectOrderClause,
  formatProjectOrderClause
} from './utils';
import { SortDirection } from 'FilterModule/constants';

export type BaseGroupedProjectResponse<
  RecordFields extends Record<string, unknown> = Record<never, never>,
  ValueCountsFields extends Record<string, unknown> = Record<never, never>
> = PickAndRequire<
  FetchLeanApiSuccessResponse<
    {
      [groupEntityId: string]: Array<
        {
          [FIELD_NAMES.id]: number;
        } & RecordFields
      >;
    },
    ValueCounts & ValueCountsFields
  >,
  'records' | 'session_id' | 'value_counts'
>;

/* --------------------------- Grouped by Portfolio -------------------------- */

export const projectsByPortfolioSchemaBuilder: LeanApiSchemaBuilder = ({
  searchText,
  filterParams
}) => {
  const where = buildProjectWhereClause({
    filterParams,
    searchText,
    initialValue: defaultProjectWhereClauses.nonAdministrativeProjects
  });

  const select = buildProjectSelectClause({
    filterParams,
    initialValue: {
      ...projectSelectClauses.boardId,
      ...projectSelectClauses.archivedAt,
      ...projectSelectClauses.description,
      ...projectSelectClauses.title,
      ...projectSelectClauses.projectNumber
    }
  });

  const order = buildProjectOrderClause({
    initialValue: formatProjectOrderClause({
      orderBy: ORDER_BY.PORTFOLIO_NAME,
      direction: SortDirection.asc
    })
  });

  const schema: LeanApiSchema = {
    entity_type: 'project',
    where,
    select,
    order,
    group: {
      [FIELD_NAMES.project]: [FIELD_RENAME_MAP.board_id]
    },
    exclude_records: false,
    exclude_value_counts: false,
    all: true
  };

  return schema;
};

export type ProjectsByPortfolioResponse = BaseGroupedProjectResponse<
  {
    [FIELD_RENAME_MAP.board_id]: number;
    [FIELD_NAMES.archived_at]: Nullable<string>;
    [FIELD_NAMES.description]: string;
    [FIELD_NAMES.title]: string;
    [FIELD_NAMES.project_number]: string;
  },
  {
    [FIELD_RENAME_MAP.board_id]: { [boardId: string]: number };
  }
>;
