import React from 'react';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import { TaskCommentRow, TaskReplyCommentContainer } from '../..';
class TaskCommentThreadContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReplyOpen: false
    };
    bindAll(this, ['openReplyInput', 'closeReplyInput']);
  }

  componentDidMount() {
    const { commentThread } = this.props;

    if (
      commentThread &&
      commentThread.replies &&
      commentThread.replies.length
    ) {
      this.setState({ isReplyOpen: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { commentThread } = this.props;

    // both cases below guard against the possibility
    // that the current conditions and the next conditions
    // may be the same, but the expected action has not
    // yet occurred.

    if (
      (commentThread.replies &&
        commentThread.replies.length &&
        !nextProps.commentThread.replies.length) ||
      (commentThread.replies &&
        !commentThread.replies.length &&
        !nextProps.commentThread.replies.length)
    ) {
      this.setState({ isReplyOpen: false });
    } else if (
      ((!commentThread.replies ||
        (commentThread.replies && !commentThread.replies.length)) &&
        nextProps.commentThread.replies &&
        nextProps.commentThread.replies.length) ||
      (commentThread.replies &&
        commentThread.replies.length &&
        nextProps.commentThread.replies.length)
    ) {
      this.setState({ isReplyOpen: true });
    }
  }

  openReplyInput() {
    this.setState({ isReplyOpen: true });
  }

  closeReplyInput() {
    this.setState({ isReplyOpen: false });
  }

  render() {
    const {
      me,
      task,
      commentThread,
      isCreatingReply,
      isDeletingReply,
      isCreatingComment,
      createComment,
      updateComment,
      deleteComment,
      teamMembers,
      selectedTeamId,
      selectedProject,
      teamMembersHash
    } = this.props;
    const { isReplyOpen } = this.state;

    return (
      <div
        id={`thread-container-${commentThread.id}`}
        className="comment-thread-container"
      >
        <div
          className={cn('comment-thread', {
            'rounded-bottom':
              commentThread.replies &&
              !commentThread.replies.length &&
              !isReplyOpen
          })}
        >
          <TaskCommentRow
            me={me}
            task={task}
            commentThread={commentThread}
            replies={commentThread.replies}
            isCreatingReply={isCreatingReply}
            isDeletingReply={isDeletingReply}
            isCreatingComment={isCreatingComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            openReplyInput={this.openReplyInput}
            replyInputOpen={isReplyOpen}
            teamMembers={teamMembers}
            selectedTeamId={selectedTeamId}
            selectedProject={selectedProject}
            permissionProps={{
              accountId: commentThread?.account?.id
            }}
          />
        </div>
        {isReplyOpen && (
          <TaskReplyCommentContainer
            me={me}
            task={task}
            commentThread={commentThread}
            createComment={createComment}
            isReplyOpen={isReplyOpen}
            closeReplyInput={this.closeReplyInput}
            selectedTeamId={selectedTeamId}
            teamMembersHash={teamMembersHash}
          />
        )}
      </div>
    );
  }
}

export default TaskCommentThreadContainer;
