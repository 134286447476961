import React from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import FlyoutHeader from './FlyoutHeader';
import FlyoutStickyRow from './FlyoutStickyRow';
import FlyoutFooter from './FlyoutFooter';
import FlyoutList from './FlyoutList';
import VirtualFlyoutList from './VirtualFlyoutList';
import FlyoutEdit from './FlyoutEdit';
import FlyoutSearch from './FlyoutSearch';
import ModalContext from '../../containers/ModalContext';
import { Modal } from 'reactstrap';
import cn from 'classnames';

import { FlyoutContainer } from './styles';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import Popover from 'components/Popover';
import { isInAnyPopover } from 'appUtils/popoverClicks';
import styled from 'styled-components';
import { SCROLLBAR_WIDTH } from 'appConstants/scrollbar';

const noop = () => {};
const defaultGetIdKey = (item) => item && item.id;
class MultiStepFlyout extends React.Component {
  state = {
    editMode: this.props.editMode,
    addMode: false,
    editingId: null,
    search: ''
  };

  static contextType = ModalContext;

  static defaultProps = {
    editMode: false
  };

  componentDidMount() {
    const { items, loadInitialItems, searchTextOnOpen = '' } = this.props;
    (!items || !items.length) &&
      loadInitialItems &&
      loadInitialItems({ search: searchTextOnOpen });
    document.addEventListener('mousedown', this.makeHandleClick(), false);
    if (searchTextOnOpen) {
      this.setState({ search: searchTextOnOpen }, this.loadOnSearch);
    }
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (items && prevProps.items && items.length !== prevProps.items.length) {
      this.clearEditMode();
      this.clearMemo();
    }
    if (prevProps.editMode !== this.props.editMode) {
      this.setState({ editMode: this.props.editMode });
    }
    if (prevProps.addMode !== this.props.addMode) {
      this.setState({ addMode: this.props.addMode });
    }
  }

  clearMemo = () => (this.searchMemo = {});
  setEditMode = () => {
    const { editDisabled } = this.props;
    if (!editDisabled) {
      this.setState({ editMode: true });
    }
  };

  clearEditMode = () =>
    this.setState({
      editMode: false || this.props.editMode,
      editingId: null,
      addMode: false
    });

  setAddMode = () => this.setState({ addMode: true });
  clearAddMode = () => this.setState({ addMode: false });

  setEditing = (id) => {
    const { setEditingCallback, editDisabled } = this.props;
    if (setEditingCallback) {
      setEditingCallback(id);
    }
    if (!editDisabled) {
      this.setState({ editingId: id });
    }
  };

  getItemId = (index, data) => {
    return this.getItemIdFromItem(data.items[index]);
  };

  getItemIdFromItem = (item) => {
    const { idKey } = this.props;

    if (typeof idKey === 'string') {
      return item[idKey];
    }
    if (typeof idKey === 'function') {
      return idKey(item);
    }
  };

  editingItem = () => {
    const { items } = this.props;
    const { editingId } = this.state;
    if (!editingId) {
      return null;
    }
    const item = items.find(
      (item) => item && this.getItemIdFromItem(item) === editingId
    );
    return item;
  };

  clearEditingId = () => {
    this.setState({ editingId: null });
  };

  handleSubmit = (e) => {
    const { handleSubmit, editMode } = this.props;
    const { addMode } = this.state;
    if (handleSubmit) {
      handleSubmit(e, {
        item: this.editingItem(),
        isNew: addMode,
        clearEditingId: this.clearEditingId,
        submitCallback: editMode ? noop : this.clearEditMode
      });
    }
  };

  handleStickyClick = (e) => {
    const { onStickyClick } = this.props;
    if (onStickyClick) {
      onStickyClick(e);
    } else {
      this.setAddMode();
    }
  };

  searchMemo = {};

  getItems = () => {
    const { items, searchEnabled, disableSearchMemo } = this.props;
    const { search } = this.state;
    if (!search.length || !searchEnabled) {
      return items;
    }
    if (disableSearchMemo) {
      return this.filterItems();
    }
    if (!this.searchMemo[search]) {
      this.searchMemo[search] = this.filterItems();
    }
    return this.searchMemo[search];
  };

  filterItems = () => {
    const { search } = this.state;
    const { itemFilter, items } = this.props;
    const searchWords = search.split(' ').filter((str) => str !== '-');
    return items.filter((item) => itemFilter(item, searchWords));
  };

  handleChange = (e) => {
    const { onSearchChange } = this.props;
    const search = e.target.value;
    this.setState({ search }, this.loadOnSearch);
    if (onSearchChange) {
      onSearchChange(search);
    }
  };

  loadOnSearch = debounce(() => this._loadOnSearch(), 300);
  _loadOnSearch = () => {
    const { search } = this.state;
    const { loadInitialItems } = this.props;
    loadInitialItems && loadInitialItems({ search });
  };

  onDragEnd = (result) => {
    const { source, destination } = result;
    const { items, reorder, dragEnabled, orderableItems } = this.props;
    const itemsToUse = orderableItems || items;
    if (!reorder || !dragEnabled) {
      console.error(`at least one of the following props is not defined:`, {
        reorder,
        dragEnabled
      });
    }
    if (!reorder || !dragEnabled || !destination || !source) return;

    const newOrder = itemsToUse.map(this.getItemIdFromItem);
    const id = newOrder[source.index];
    newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, id);
    reorder(newOrder);
  };

  setNodeRef = (ref) => (this.node = ref);
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  makeHandleClick = () => {
    const modal = this.context.getModal();
    const { handleClose } = this.props;
    this.handleClick = (e) => {
      if (
        this.popoverRef?.componentNode?.contains?.(e.target) ||
        this.node?.contains?.(e.target) ||
        this.node?._element?.contains?.(e.target) ||
        modal?.contains?.(e.target) ||
        isInAnyPopover(e)
      ) {
        return;
      }
      handleClose && handleClose(e);
      this.handleClickOutside(e);
    };
    return this.handleClick;
  };

  handleClickOutside = (e) => {
    const { handleClose } = this.props;
    if (handleClose) {
      handleClose(e);
    }
  };

  selectCallback = () => {
    const { selectCallback, handleClose, canMultiSelect } = this.props;
    if (selectCallback) {
      selectCallback();
    } else if (this.state.editMode) {
      this.clearEditMode();
    } else if (!canMultiSelect && handleClose) {
      handleClose();
    }
  };

  shouldRenderStickyRow = () => {
    const { stickyRow, isGlobal } = this.props;
    const { addMode, editMode } = this.state;
    if (isGlobal) {
      return !!(addMode || editMode) && stickyRow;
    } else return stickyRow;
  };

  isEmpty = () => {
    const { items } = this.props;
    return !items || !items.length;
  };

  renderLoading = () => {
    const { copy, listHeight, listWidth } = this.props;
    return (
      copy.loadingState || (
        <SkeletonLoader
          numLoaders={3}
          style={{
            margin: 0,
            padding: 20,
            paddingBottom: 0,
            height: listHeight,
            width: listWidth
          }}
          loaderStyle={{ height: 70, rx: 4 }}
        />
      )
    );
  };

  // to avoid missing display name issue with React.forwardRef
  // use named function instead of arrow function
  // don't forget that it couldn't access to parent with 'this'
  // see: https://reactjs.org/docs/forwarding-refs.html#displaying-a-custom-name-in-devtools
  Container = React.forwardRef(function Container(props, ref) {
    const { noFlyout, isGlobal, isAlwaysGlobal } = props.parentProps;
    const { editMode, addMode } = props.parentState;
    return noFlyout ? (
      <FlyoutContainer ref={ref} {...props} />
    ) : (isGlobal && (editMode || addMode)) || isAlwaysGlobal ? (
      <Modal ref={ref} {...props} />
    ) : (
      <Popover
        ref={ref}
        isOpen={true}
        closePopover={props.parentProps.closePopover}
        target={props.parentProps.target}
        stopPropagation
        boundariesElement="window"
        offset={props.parentProps.popoverOffset}
        className={cn('flyout-modal', {
          [props.parentProps.popoverClassName]:
            props.parentProps.popoverClassName
        })}
        insideAnotherPopover={props.parentProps.insideAnotherPopover ?? false}
        style={{ border: '0', boxShadow: '0 0 4px rgba(0,0,0,0.25)' }}
        placement={props.popoverPlacement}
      >
        {props.children}
      </Popover>
    );
  });

  isItemLoaded = (index) => {
    const items = this.getItems();
    const { hasNextPage, onScroll } = this.props;
    if (onScroll) {
      onScroll();
    }
    return !hasNextPage || index < items.length;
  };

  loadMoreItems = () => {
    const { search } = this.state;
    const { loadMoreItems } = this.props;
    if (loadMoreItems) {
      loadMoreItems({ search });
    }
  };

  clearSearch = () => {
    this.clearMemo();
    this.setState({ search: '' });
  };

  renderFooter = () => {
    const { copy, onFooterClick, hideFooter, isInvalid, onCancel } = this.props;
    const { editMode, addMode } = this.state;

    return hideFooter ? null : (
      <FlyoutFooter
        copy={copy}
        editMode={editMode}
        addMode={addMode}
        clearEditMode={this.clearEditMode}
        setEditMode={this.setEditMode}
        editingItem={this.editingItem()}
        handleSubmit={this.handleSubmit}
        onFooterClick={onFooterClick}
        isInvalid={isInvalid}
        onCancel={onCancel}
        clearEditingId={this.clearEditingId}
      />
    );
  };

  render() {
    const {
      copy,
      renderItem,
      handleSelect = noop,
      renderEdit,
      renderHeader,
      renderHeaderButton,
      searchEnabled,
      dragEnabled,
      isWhite,
      isInCentralModal,
      isGlobal,
      unSelectable,
      isItemUnSelectable,
      editDisabled,
      getIdKey,
      handleClose = noop,
      listItemContainerStyle,
      // used for virtualization calculations in VirtualFlyoutList only
      listHeight = 240,
      listWidth = 276 - SCROLLBAR_WIDTH,
      itemHeight = 54,
      getItemHeight,
      totalCount,
      threshold,
      phases,
      dragInitial,
      isItemDraggable,
      hideEditIcon,
      isItemEditable,
      placeholderSize,
      isItemInactive,
      globalClassName,
      renderStickyRow,
      isAlwaysGlobal,
      renderEditHeaderText,
      renderEditStickyRow,
      renderTableBodyHeader,
      noHeader,
      stickyBelowSearch,
      hideClose = false,
      styleWrapper,
      showCloseInSearch,
      emptyContainerClassName,
      isLoading,
      zIndex = 2000,
      headerTitle,
      noMinWidth,
      currentlySelectedId,
      popoverPlacement,

      // Include this property to include the height of the footer in the height
      // of the list, `listHeight`. This allows the dropdown to maintain the
      // same height whether a footer is present or not. By default, the height
      // of the footer is excluded from the height of the list.
      includeFooterInListHeight,

      // If this property is set, the dropdown list will shrink to fit the list
      // items, to a minimum of `minListHeight`, if the list is shorter than
      // `listHeight`.
      minListHeight
    } = this.props;
    const { editMode, addMode, editingId } = this.state;

    const items = this.getItems();
    const editingItem = this.editingItem();
    const Flyout = dragEnabled ? FlyoutList : VirtualFlyoutList;
    const StyleWrapper = styleWrapper || React.Fragment;

    return (
      <this.Container
        parentProps={this.props}
        parentState={this.state}
        ref={this.setNodeRef}
        onClick={(e) => e.stopPropagation()}
        isInCentralModal={isInCentralModal}
        isOpen={true}
        zIndex={zIndex}
        backdropClassName="global-flyout-modal"
        className={
          isAlwaysGlobal && globalClassName
            ? `${globalClassName} always-global-modal`
            : isGlobal
            ? 'global-modal'
            : 'flyout-container-modal'
        }
        popoverPlacement={popoverPlacement}
      >
        <StyleWrapper {...(styleWrapper ? { listHeight, itemHeight } : {})}>
          {!noHeader && (
            <FlyoutHeader
              renderHeader={renderHeader}
              renderEditHeaderText={renderEditHeaderText}
              renderHeaderButton={renderHeaderButton}
              copy={copy}
              editingId={editingId}
              addMode={addMode}
              isWhite={isWhite}
              isGlobal={isGlobal}
              editMode={editMode}
              handleClose={handleClose}
              hideClose={hideClose}
              headerTitle={headerTitle}
              noMinWidth={noMinWidth}
              clearSearch={this.clearSearch}
            />
          )}
          {(addMode || editingId) && !editDisabled ? (
            <>
              <FlyoutEdit
                renderEdit={renderEdit}
                addMode={addMode}
                submitCallback={this.clearEditMode}
                clearEditingId={this.clearEditingId}
                editingItem={editingItem}
              />
              {this.renderFooter()}
            </>
          ) : (
            <>
              {this.shouldRenderStickyRow() && !stickyBelowSearch && (
                <FlyoutStickyRow
                  copy={copy}
                  handleClick={this.handleStickyClick}
                  renderStickyRow={renderStickyRow}
                  editingId={editingId}
                  editMode={editMode}
                  renderEditStickyRow={renderEditStickyRow}
                />
              )}
              {searchEnabled && (
                <FlyoutSearch
                  value={this.state.search}
                  handleChange={this.handleChange}
                  copy={copy}
                  placeholderSize={placeholderSize}
                  showCloseInSearch={showCloseInSearch}
                  handleClose={handleClose}
                />
              )}
              {this.shouldRenderStickyRow() && stickyBelowSearch && (
                <FlyoutStickyRow
                  copy={copy}
                  handleClick={this.handleStickyClick}
                  renderStickyRow={renderStickyRow}
                  editingId={editingId}
                  editMode={editMode}
                  renderEditStickyRow={renderEditStickyRow}
                />
              )}
              <ListAndFooter
                $listHeight={includeFooterInListHeight ? listHeight : undefined}
                className="list-and-footer"
              >
                <Flyout
                  items={items}
                  renderItem={renderItem}
                  handleSelect={handleSelect}
                  editMode={editMode}
                  setEditing={this.setEditing}
                  editingId={editingId}
                  hideEditIcon={hideEditIcon}
                  isItemEditable={isItemEditable}
                  getItemId={this.getItemId}
                  onDragEnd={this.onDragEnd}
                  dragEnabled={dragEnabled}
                  unSelectable={unSelectable}
                  isItemUnSelectable={isItemUnSelectable}
                  selectCallback={this.selectCallback}
                  isEmpty={this.isEmpty()}
                  copy={copy}
                  phases={phases}
                  listItemContainerStyle={listItemContainerStyle}
                  listHeight={
                    includeFooterInListHeight ? undefined : listHeight
                  }
                  minListHeight={minListHeight}
                  listWidth={listWidth}
                  itemHeight={itemHeight}
                  getItemHeight={getItemHeight}
                  loadMoreItems={this.loadMoreItems}
                  isItemLoaded={this.isItemLoaded}
                  totalCount={totalCount}
                  threshold={threshold}
                  getIdKey={getIdKey || defaultGetIdKey}
                  dragInitial={dragInitial}
                  isItemDraggable={isItemDraggable}
                  isInactive={isItemInactive}
                  renderTableBodyHeader={renderTableBodyHeader}
                  isLoading={isLoading}
                  emptyContainerClassName={emptyContainerClassName}
                  renderLoading={this.renderLoading}
                  clearSearch={this.clearSearch}
                  currentlySelectedId={currentlySelectedId}
                />
                {this.renderFooter()}
              </ListAndFooter>
            </>
          )}
        </StyleWrapper>
      </this.Container>
    );
  }
}

const ListAndFooter = styled.div.attrs(({ $listHeight }) => ({
  style: {
    height: $listHeight
  }
}))`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > first-child {
    flex: 1;
  }

  &:not(:first-child) {
    border-top: 1px solid #f0f0f0;
  }
`;

MultiStepFlyout.propTypes = {
  idKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  items: PropTypes.array.isRequired,

  // Type:
  // {
  //   addConfirm?: ReactNode;
  //   editConfirm?: ReactNode;
  //   emptyState?: ReactNode;
  //   footerEdit?: ReactNode;
  //   footerInitial?: ReactNode;
  //   headerAdd?: ReactNode;
  //   headerEdit?: ReactNode;
  //   headerInitial?: ReactNode;
  //   loadingState?: ReactNode;
  //   searchPlaceholder?: ReactNode;
  //   sticky?: ReactNode;
  // }
  copy: PropTypes.object.isRequired,
  renderEdit: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};

export default MultiStepFlyout;
