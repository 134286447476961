import { createRequestTypes } from 'appUtils';

export const FETCH_DISCIPLINES = createRequestTypes('FETCH_DISCIPLINES');
export const CREATE_DISCIPLINE = createRequestTypes('CREATE_DISCIPLINE');
export const UPDATE_DISCIPLINE = createRequestTypes('UPDATE_DISCIPLINE');
export const DELETE_DISCIPLINE = createRequestTypes('DELETE_DISCIPLINE');

export const UPDATE_DISCIPLINE_ENTITIES = createRequestTypes(
  'UPDATE_DISCIPLINE_ENTITIES'
);
