import moment from 'appUtils/momentConfig';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import countBy from 'lodash/countBy';

const MsPerDay = 1000 * 60 * 60 * 24;

const stripTimeFromDateString = (dateString) =>
  dateString && dateString.split ? dateString.split('T')[0] : dateString;
export const makeDateFromDateTime = (date) =>
  moment(stripTimeFromDateString(date));

export const isSameDay = (momentA, momentB) => {
  return moment(momentA).isSame(momentB, 'day');
};

export const isToday = (momentDate) => {
  if (!momentDate) {
    // apparently moment().isSame(undefined, 'day') is true...
    return false;
  }
  return moment().isSame(momentDate, 'day');
};

export const isWeekend = (momentDate) =>
  ['Sunday', 'Saturday'].includes(momentDate.format('dddd'));

export const nullifyInvalidDate = (date, format) => {
  const isValid = moment(date).isValid();
  if (!isValid) {
    return null;
  }
  if (!format) {
    return moment(date);
  }
  return moment(date).format(format);
};

export const isTodayOn = (momentDate) => {
  if (!momentDate) {
    // apparently moment().isSame(undefined, 'day') is true...
    return false;
  }
  return moment().subtract(1, 'days').isBefore(momentDate, 'day');
};

// a and b are javascript Date objects
const dateDiffInDays = (a, b) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MsPerDay);
};

export const daysFromToday = (momentDate) => {
  if (!momentDate) {
    // apparently moment().isSame(undefined, 'day') is true...
    return false;
  }
  const properMomentDate = new Date(momentDate);
  return dateDiffInDays(new Date(), properMomentDate);
};

export const getMondayOfWeek = (momentDate) =>
  momentDate.clone().startOf('week');

export const findMostRecent = (items, predicateFunc) =>
  items.reduce((mostRecent, item) =>
    moment(predicateFunc(mostRecent)).isBefore(moment(predicateFunc(item)))
      ? item
      : mostRecent
  );

export const getMaxDate = (...items) =>
  moment.max(items.filter((item) => !!item).map((item) => moment(item)));

export const defaultEventTypeComparator = (item) => `${item.actor?.id}`;
const defaultEventDataOverWriter = ({ project, display_data }) => ({
  project,
  display_data
});
export const defaultEventStreamThrottleConfig = {
  intervalType: 'minutes',
  intervalAmount: 5,
  eventTypeComparator: defaultEventTypeComparator,
  recentEventDataOverWriter: defaultEventDataOverWriter // default differentiate on actor only, but not event type
};

// Filter events out of an array if they occur within a specific time frame of the preceding event.
// This is effectivly a read time debounce. Ex: With default config - if one action occurs, and actions continue to occur exactly every 14 minutes and 59 seconds, only the most recent action will show as every action is considering "recent" when compared to the preceding action.
export const throttleEventStream = (
  events,
  timeSelector,
  throttleConfig = defaultEventStreamThrottleConfig
) => {
  const {
    intervalType,
    intervalAmount,
    eventTypeComparator = defaultEventTypeComparator,
    recentEventDataOverWriter = defaultEventDataOverWriter,
    keepFirst
  } = throttleConfig;
  // step one, group events by event type
  // step two reduce similar events - first event timestamp and action type w/ last event entity data
  // step three merge events back into one list by timestamp

  const reduceSimilarEvents = (similarEvents) => {
    const { foldedEventStream } = similarEvents.reduce(
      (acc, currentEvent, index) => {
        const isLastEvent = index === similarEvents.length - 1;
        const eventTime = timeSelector(currentEvent);
        if (!acc.firstEventForWindow) {
          if (!index && keepFirst) {
            acc.foldedEventStream.push(currentEvent);
            return acc;
          }
          acc.mostRecentEventForWindow = currentEvent;
          acc.firstEventForWindow = currentEvent;
          acc.timestamp = eventTime;
          if (!isLastEvent) {
            return acc;
          }
        }

        const timeDiff = moment(eventTime).diff(
          moment(acc.timestamp),
          intervalType
        );
        const newEventWindow = Math.abs(timeDiff) > intervalAmount;

        if (newEventWindow && isLastEvent) {
          // if last event is outside of the window, add the most
          // recent event, as well as last event (do not merge)
          acc.foldedEventStream.push(acc.mostRecentEventForWindow);
          acc.foldedEventStream.push(currentEvent);
          return acc;
        } else if (newEventWindow || isLastEvent) {
          // merge current window to log if start of new window or end of overall stream
          const overwriteEvent = isLastEvent
            ? currentEvent
            : acc.mostRecentEventForWindow;
          const mergedEvent = {
            ...acc?.firstEventForWindow,
            ...(acc?.firstEventForWindow !== overwriteEvent &&
              recentEventDataOverWriter(overwriteEvent))
          };
          acc.foldedEventStream.push(mergedEvent);
          acc.firstEventForWindow = currentEvent;
        }

        acc.timestamp = eventTime;
        acc.mostRecentEventForWindow = currentEvent;

        return acc;
      },
      {
        mostRecentEventForWindow: null,
        firstEventForWindow: null,
        timestamp: null,
        foldedEventStream: []
      }
    );
    return foldedEventStream;
  };

  const eventsByType = groupBy(events.slice().reverse(), eventTypeComparator);

  const allEvents = flatten(
    Object.values(eventsByType).map(reduceSimilarEvents)
  ).sort(
    (a, b) =>
      moment(timeSelector(b)).valueOf() - moment(timeSelector(a)).valueOf()
  );

  return allEvents;
};

export const throttleUserActivities = (userActivities, throttleConfig) =>
  throttleEventStream(
    userActivities,
    (userActivity) => userActivity?.timestamp,
    throttleConfig
  );

// strictly for time unit (sometimes the unit can be 'h' for hour or 'min' for 'minute')
export const pluralizeTimeUnit = ({ unit, value, doNotPluralize }) => {
  if (value <= 1 || !value || doNotPluralize) return unit;

  return `${unit}s`;
};

// Based on moment's hour getter criteria https://momentjs.com/docs/#/get-set/hour/
// 00 -> 11:59
const isAm = (hour) => hour >= 0 && hour <= 11;

// 12 -> 23:59
const isPm = (hour) => hour >= 12 && hour <= 23;

// const startDate (ISO):  '2022-11-11T13:02:00.000-05:00';
// const endDate (ISO): '2023-01-10T17:40:00.000-05:00';
// => 01:02pm-05:40pm
export const formatStartToEndDateTime = ({ startDate, endDate }) => {
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const startDateHour = startDateMoment.hour();
  const endDateHour = endDateMoment.hour();
  const isBothAm = isAm(startDateHour) && isAm(endDateHour);
  const isBothPm = isPm(startDateHour) && isPm(endDateHour);

  if (isBothAm || isBothPm) {
    // Only show am/pm on the second number (end time)
    // Specs on MemberModalCalendar
    // i.e: 09:00-11:00am or 09:00:-11:00pm
    const startTime = startDateMoment.format('hh:mm');
    const endTime = endDateMoment.format('hh:mma');
    return `${startTime}-${endTime}`;
  }

  const startTime = startDateMoment.format('hh:mma');
  const endTime = endDateMoment.format('hh:mma');
  return `${startTime}-${endTime}`; // This is whne both isAm and isPm
};

export const isTomorrow = (startDateString, endDateString) => {
  const startDateMoment = moment(startDateString).add(1, 'days');

  const endDateMoment = moment(endDateString);

  return startDateMoment.isSame(endDateMoment, 'day');
};

export const hasPassed = (startDateString, endDateString) =>
  moment(endDateString).isBefore(moment(startDateString));

export const isHappening = (
  dateStringToCheckIfBetween,
  startDateString,
  endDateString
) => {
  return moment(dateStringToCheckIfBetween).isBetween(
    moment(startDateString),
    moment(endDateString)
  );
};

export const makeUtcDate = (date) => moment(date).utc().format();

/**
 * @param {Object} dates
 * @param {string} dates.startDate
 * @param {string} dates.endDate
 * @returns {number}
 */
export const calculateWeekdays = ({ startDate, endDate }) => {
  const range = Array.from(moment.range(startDate, endDate).by('day'));
  return countBy(range, (date) => !isWeekend(date)).true; // result = { true: numberOfWeekDayOccurance }
};
