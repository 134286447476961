import theme from 'theme';
import StatusDropdown from 'components/StatusDropdown';
import { SelectPhase } from '../../workloadBarModal/PhaseSelector';
import { DescriptionField } from './DescriptionField';
import ProjectsThenPhasesSelector from '../../workloadBarModal/ProjectsThenPhasesSelector';
import { useWorkPlanForm } from '../WorkPlanFormProvider';
import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

export const ProjectFields = () => {
  const {
    form: { workplan, showErrors, hasError, isRequest },
    member: { accountId },
    description: { value, onUpdate },
    projectAndPhase: {
      project,
      phase,
      isPTO,
      onSelectProjectOrPhase,
      hasDefaultPhase
    },
    permissions: { canEditWorkplan }
  } = useWorkPlanForm();

  const shouldRenderPhaseSelector =
    !!workplan.project_id && !workplan.phase_id && !hasDefaultPhase;

  const renderPhaseSelector = ({ openDropdownToPhases }) => {
    return (
      <div
        onClick={(e) => openDropdownToPhases(e, project.id)}
        style={{ padding: '5px 0px' }}
      >
        <SelectPhase showError={showErrors && hasError(['missing_phase_id'])} />
      </div>
    );
  };

  return (
    <>
      <ProjectsThenPhasesSelector
        projectId={workplan.project_id}
        accountId={accountId}
        phaseId={workplan.phase_id}
        activityId={workplan.activity_id}
        handleSelect={onSelectProjectOrPhase}
        isInvalid={
          showErrors &&
          hasError([
            'missing_project_id',
            'missing_phase_id',
            'missing_activity_id',
            'selected_archived_phase'
          ])
        }
        renderPhaseSelector={
          shouldRenderPhaseSelector ? renderPhaseSelector : undefined
        }
        renderPhaseDates
        selectionDisabled={isRequest || !canEditWorkplan}
        // TODO: find out why selectionDisabled is not disabling phase selection
        phaseAndActivitySelectionDisabled={isRequest || !canEditWorkplan}
        disabledTooltip={
          isRequest
            ? "You can't change Project when<br/>approving Work Plan Request."
            : "You don't have permissions to edit Work Plans. <br/> Contact the Work Plan creator shown in activity <br/> or Admins to delete"
        }
        pickerLocation="workplan-form-color-picker"
      />
      {project && phase && !hasDefaultPhase && !isPTO && (
        <StatusDropdown
          status={phase?.budget_status}
          project={undefined}
          phase={phase}
          projectId={undefined}
          background={theme.colors.colorTranslucentGray4}
          dropdownToggleClassName={'workload-bar-modal-toggle'}
          isPhaseStatusDropdown
          showDropdownIcon={false}
          isDisabled={isRequest || !canEditWorkplan}
        />
      )}
      {workplan.project_id && (
        <DescriptionField
          value={value}
          onUpdate={onUpdate}
          isDisabled={!canEditWorkplan}
          disabledTooltip={EDIT_WORK_PLANS_TIP}
        />
      )}
    </>
  );
};
