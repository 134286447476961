import React from 'react';
import styled from 'styled-components';
import { StyledColorPicker } from 'components/ColorPicker/styles';
import { isInAnyPopover, isInAnyModal } from 'appUtils/popoverClicks';

export const FlyoutMenuWrapper = styled.div`
  position: absolute;
  /* align == left or right */
  ${(props) => `${props.align}: 0px;`}
  background-color: ${({ theme }) => ` ${theme.colors.colorPureWhite}`};
  top: 36px;
  padding: 0px;
  z-index: 200;
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledColorPicker} {
    justify-content: flex-start;
  }
  .project-menu-item-container {
    width: 100%;

    padding: 5px 0px;
  }
  .project-menu-item {
    font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
    font-weight: normal;
    color: ${({ theme }) => ` ${theme.colors.colorSemiDarkGray3}`};
    height: 34px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .delete-grey-icon {
      display: flex;
    }

    .delete-red-icon {
      display: none;
    }

    :hover {
      background-color: ${({ theme }) =>
        ` ${theme.colors.colorTranslucentGray4}`};

      .delete-grey-icon {
        display: none;
      }

      .delete-red-icon {
        display: flex;
      }
    }
  }
`;

class ProjectThreeDotMenuFlyout extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (
      this.node?.contains?.(e.target) ||
      e?.target?.offsetParent?.className?.includes?.('modal-content') ||
      e?.target?.offsetParent?.offsetParent?.className?.includes?.(
        'modal-content'
      ) ||
      isInAnyPopover(e) ||
      isInAnyModal(e)
    ) {
      return;
    }
    this.handleClickOutside(e);
  };

  handleClickOutside = (e) => {
    const { handleClose } = this.props;
    handleClose(e);
  };

  setNodeRef = (ref) => (this.node = ref);

  render() {
    const { children, align } = this.props;
    return (
      <FlyoutMenuWrapper ref={this.setNodeRef} align={align}>
        {children}
      </FlyoutMenuWrapper>
    );
  }
}

export default ProjectThreeDotMenuFlyout;
