import styled from 'styled-components';
import theme from 'theme';

export const AddEntryRow = styled.div`
  padding: 20px 16px;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray5};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
