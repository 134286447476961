import integrities from './integrities.json';

const loadStripe = () => {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://js.stripe.com/v3/';
  script.integrity = integrities.stripe;
  script.crossOrigin = 'anonymous';
  body.appendChild(script);
};

export const initStripe = () => {
  loadStripe();
};
