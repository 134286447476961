import { reducerMap } from 'SuggestionModule/reducers';
import suggestionSaga from 'SuggestionModule/sagas';

export function getSuggestionModule() {
  return {
    id: 'suggestion',
    reducerMap,
    sagas: [suggestionSaga]
  };
}
