import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { moveScopes } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';
import { Modal } from 'reactstrap';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import withCheckIfOpen from 'hocs/withCheckIfOpen';
import { formatOnSuccess } from 'appUtils/formatUtils';

const MoveScopeModal = ({
  id,
  ids,
  nextActivityPhaseId,
  projectId, // for refetching
  onSuccess,
  onConfirm,
  onClose,
  additionalMoveParams
}) => {
  const dispatch = useDispatch();
  // states for preventing modal changes if eg. table rows move around and modal is rendered from a cell
  const [_id] = useState(id);
  const [_ids] = useState(ids);

  const handleConfirmMove = () => {
    dispatch(
      moveScopes({
        activityPhaseId: nextActivityPhaseId,
        ids: _id !== undefined ? [_id] : _ids,
        onSuccess: formatOnSuccess(onSuccess),
        projectId,
        ...additionalMoveParams
      })
    );
    onConfirm && onConfirm();
  };

  const isPlural = ids?.length > 1;

  return (
    <SimpleConfirmModal
      isOpen
      header={`Move Scope${isPlural ? 's' : ''}`}
      body={
        isPlural ? (
          <div>
            Scopes are tied to Phase & Work Category and will be removed from
            any existing Work Plans. Are you sure you want to <b>move</b> these
            scopes?
          </div>
        ) : (
          <div>
            Scope is tied to Phase & Work Category and will be removed from any
            existing Work Plans. Are you sure you want to <b>move</b> this
            scope?
          </div>
        )
      }
      toggle={onClose}
      onConfirm={handleConfirmMove}
      confirmLabel="Move"
      cancelLabel="Cancel"
      submitButtonColor={theme.colors.colorRoyalBlue}
      stylesWrapper={StyledModal}
    />
  );
};

export default withCheckIfOpen(MoveScopeModal);

/* ------------------------------------ - ----------------------------------- */

const StyledModal = styled(Modal)`
  width: 400px;
  color: black;
  .modal-content {
    padding: 30px;
    border-radius: 6px !important;
    .confirm-modal-body {
      font-size: 13px;
      margin-bottom: 20px;
    }
    button {
      font-size: 14px;
      &.confirm-modal-confirm-btn {
        font-weight: 600;
      }
    }
  }
`;
