import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DarkWorkloadIcon from 'images/workload-dark.svg';
import WorkloadIcon from 'images/workload.svg';
import {
  Img,
  DarkImg,
  ImageContainer,
  SpaceDescription,
  WorkloadLink as WorkloadLinkStyled
} from '../styledComponents';
import { getOnWorkloadView } from 'selectors';
import { viewWorkloadPlanner } from 'actionCreators';
const emptyObj = {};

class WorkloadLink extends React.Component {
  getPermissions = () => {
    const { team } = this.props;
    return team ? { teamId: team.id } : emptyObj;
  };

  onWorkloadClick = () => {
    const { navWorkloadPage, checkPermission, viewWorkloadPlanner } =
      this.props;
    const permissions = this.getPermissions();
    const workloadPermission = checkPermission(viewWorkloadPlanner, {
      permissions
    });
    if (workloadPermission) {
      navWorkloadPage();
    }
  };

  render() {
    const { isOnWorkloadPage, isExpanded, canAccessWorkload } = this.props;
    return (
      // workload-planner flag on LaunchDarkly
      <WorkloadLinkStyled
        onClick={this.onWorkloadClick}
        isOnWorkloadPage={isOnWorkloadPage}
        data-for="app-tooltip"
        data-tip={
          canAccessWorkload
            ? ''
            : "You don't have access to this feature. <br/> Contact your Admin(s)."
        }
        data-place="right"
        data-effect="solid"
        data-html
        data-tip-disable={isExpanded}
        data-testid="sidemenu-workload"
      >
        <ImageContainer
          isModuleSelected={isOnWorkloadPage}
          data-testid="workload-navbutton"
        >
          <Img
            src={WorkloadIcon}
            isModuleSelected={isOnWorkloadPage}
            style={{ marginLeft: '-2px' }}
          />
          <DarkImg
            src={DarkWorkloadIcon}
            isModuleSelected={isOnWorkloadPage}
            style={{ marginLeft: '-2px' }}
          />
          <SpaceDescription isModuleSelected={isOnWorkloadPage}>
            Workload
          </SpaceDescription>
        </ImageContainer>
      </WorkloadLinkStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  isOnWorkloadPage: getOnWorkloadView(state),
  team: state.teams.allTeams && state.teams.allTeams[0]
});
const mapDispatchToProps = {
  viewWorkloadPlanner
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkloadLink)
);
