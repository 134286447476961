import styled from 'styled-components';
import StandardGeneralContent from 'SettingsModule/components/StandardSettings/General/StandardGeneralContent/StandardGeneralContainer';
import { ColorScaleSetting } from '../ColorScaleSetting';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import BudgetTrackingSetting from 'SettingsModule/components/Budget/BudgetTrackingSetting';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import {
  DefaultTimeFormatSetting,
  DateFormatSetting,
  StartOfWeekSetting
} from '../TimeDateSettings';
import { TimesheetsSettings } from '../TimesheetsSettings';
import { LanguageSetting, TeamCurrencySetting } from '../LocalizationSettings';
import {
  WorkplanHoursDisplaySetting,
  WorkplanLockSetting
} from '../WorkplanSettings';
import { FullTimeEquivalentSetting } from '../FullTimeEquivalentSettings';
import { PhaseReferenceSetting } from '../PhaseSettings';
import { Field, Label } from 'SettingsModule/components/styles';
import { DeleteAccountButton } from '../Buttons';

const Form = styled.form`
  align-items: center;
  display: grid;
  font-size: 13px;
  gap: 46px 31px;
  grid-template-columns: max-content auto;
  margin: 52px 0 23px;
`;

/*
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${theme.colors.colorSemiDarkGray6};

  input {
    margin-right: 5px;
  }
  label {
    margin: 0;
  }
`;

const OuterInputButton = styled.div`
  border: 2px solid ${theme.colors.colorLightGray6};
  width: 13px;
  height: 13px;
  background-color: ${theme.colors.colorPureWhite};
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '25%')};
  border-width: thin;
  margin-right: 6px;
`;

const InnerInputButton = styled.div`
  width: 9px;
  height: 9px;
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '25%')};
  background-color: ${({ checked }) =>
    checked ? theme.colors.colorRoyalBlue : theme.colors.colorPureWhite};
  transform: translate(11%, 11%);
`;

const fieldsNameValueAndOptions = {
  timesheet: {
    name: 'timesheet',
    1: {
      value: true,
      label: 'Enabled',
      dataTip:
        "Mosaic's Timesheets can be turned off <br /> for using your ERP or third party <br /> Timesheet tool. Disabling will hide it <br /> from the left main menu"
    },
    2: {
      value: false,
      label: 'Disabled'
    }
  },
  manage_time: {
    name: 'manage_time',
    1: {
      value: 'hours',
      label: 'Hours'
    },
    2: {
      value: 'bill_rates',
      label: 'Bill Rates'
    },
    3: {
      value: 'cost_rates',
      label: 'Cost Rates'
    }
  },
  time_format: {
    name: 'time_format',
    1: {
      value: true,
      label: 'AM/PM 1:30'
    },
    2: {
      value: false,
      label: '24HR 13:30'
    }
  },
  date_format: {
    name: 'date_format',
    1: {
      value: 'mm/dd/yy',
      label: 'Month/Day/Year'
    },
    2: {
      value: 'yy/mm/dd',
      label: 'Year/Month/Day'
    },
    3: {
      value: 'yy/dd/mm',
      label: 'Year/Day/Month'
    }
  }
};
*/

const OrganizationSettingsForm = ({ organizationSettings, onInputSelect }) => {
  const teamId = useAppSelector(getSelectedTeamId);

  /*
  const getFieldValue = (field, option) =>
    fieldsNameValueAndOptions[field][option].value;

  const getFieldName = (field) => fieldsNameValueAndOptions[field].name;

  const getIsChecked = (field, option, type) => {
    if (type === 'checkbox') {
      return organizationSettings[field]?.includes(
        getFieldValue(field, option)
      );
    }
    return organizationSettings[field] === getFieldValue(field, option);
  };

  const getFieldLabel = (field, option) =>
    fieldsNameValueAndOptions[field][option].label;

  const getDataTip = (field, option) =>
    fieldsNameValueAndOptions[field][option]?.dataTip || '';

  const renderButtonField = ({ field, option, type = 'radio' }) => {
    return (
      <ButtonField
        type={type}
        value={getFieldValue(field, option)}
        checked={getIsChecked(field, option, type)}
        name={getFieldName(field)}
        dataTip={getDataTip(field, option)}
        onClick={onInputSelect}
        label={getFieldLabel(field, option)}
      />
    );
  };
  */

  const {
    teamCurrencySetting,
    customHeatmapSetting,
    timeFormatFlag,
    dateFormatFlag,
    teamLanguageFlag,
    startOfWeekFlag,
    fullTimeEquivalentFlag,
    phaseReferenceNumberFlag,
    workPlanHoursDisplayDefaultFlag,
    workPlanLockingTeamDefaultFlag,
    deleteAccountFlag,
    teamBudgetSettingsFlag
  } = useFeatureFlags();

  const { isHoursOnly } = useIsHoursOnly();

  return (
    <Form>
      {/* <FormRow>
        <Label>TIMESHEET </Label>
        <Fields>
          {[1, 2].map((option) =>
            renderButtonField({
              field: 'timesheet',
              option
            })
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Label>MANAGE TIME</Label>
        <Fields>
          {[1, 2, 3].map((option) =>
            renderButtonField({
              field: 'manage_time',
              option,
              type: 'checkbox'
            })
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Label>TIME FORMAT</Label>
        <Fields>
          {[1, 2].map((option) =>
            renderButtonField({
              field: 'time_format',
              option
            })
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Label>DATE FORMAT</Label>
        <Fields>
          {[1, 2, 3].map((option) =>
            renderButtonField({
              field: 'date_format',
              option
            })
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Label>CURRENCY</Label>
        <Field>
          {' '}
          <select id="cars" name="cars">
            <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
            <option value="fiat">Fiat</option>
            <option value="audi">Audi</option>
          </select>
        </Field>
      </FormRow>
      <FormRow>
        <Label>LANGUAGE</Label>
        <Field>
          {' '}
          <select id="cars" name="cars">
            <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
            <option value="fiat">Fiat</option>
            <option value="audi">Audi</option>
          </select>
        </Field>
      </FormRow>
      <FormRow>
        <Label>
          START OF WEEK{' '}
          <FloatHelpIcon dataTip="Select the day of the week to view <br /> start of week for work planning" />
        </Label>
        <Field>
          {' '}
          <select id="cars" name="cars">
            <option value="volvo">Saturday</option>
            <option value="saab">Sunday</option>
            <option value="fiat">Monday</option>
          </select>
        </Field>
      </FormRow> */}
      {timeFormatFlag && <DefaultTimeFormatSetting />}
      {dateFormatFlag && <DateFormatSetting />}
      <TimesheetsSettings />
      {!teamBudgetSettingsFlag && <BudgetTrackingSetting />}
      {teamCurrencySetting &&
        !teamBudgetSettingsFlag &&
        teamId &&
        !isHoursOnly && <TeamCurrencySetting teamId={teamId} />}
      {teamLanguageFlag && <LanguageSetting />}
      {startOfWeekFlag && <StartOfWeekSetting />}
      <StandardGeneralContent />
      {customHeatmapSetting && (
        <>
          <Label>Capacity Heatmap Settings</Label>
          <Field>
            <ColorScaleSetting />
          </Field>
        </>
      )}
      {fullTimeEquivalentFlag && <FullTimeEquivalentSetting />}
      {phaseReferenceNumberFlag && <PhaseReferenceSetting />}
      {workPlanHoursDisplayDefaultFlag && <WorkplanHoursDisplaySetting />}
      {workPlanLockingTeamDefaultFlag && <WorkplanLockSetting />}
      {deleteAccountFlag && <DeleteAccountButton />}
    </Form>
  );
};

export default OrganizationSettingsForm;
