import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useMount } from 'react-use';
import { fetchCapacityGroupMemberships } from 'CapacityModule/actionCreators/capacityGroup';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import {
  MouseEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { makeGetOrderedMemberCapacityPolicies } from 'CapacityModule/selectors/capacityGroup';
import { CapacityGroupMembership } from 'CapacityModule/models';
import {
  StyledCell,
  StyledDate,
  DeleteIconContainer,
  StyledDeleteIcon,
  HeaderContainer,
  StyledMemberName,
  StyledRowContainer,
  AddRateContainer,
  StyledHeaderCell,
  MiniHeaderContainer,
  CloseIconStyle,
  EmptyCell
} from 'BudgetModule/components/BudgetModal/styles';
import { DoneButton } from 'CapacityModule/components/WorkloadModal/styles';
import { MemberInitials } from 'views';
import { ValueOf } from 'type-fest';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import moment, { Moment } from 'moment';
import { capacityGroupMembershipUtils } from 'CapacityModule/utils/capacityGroupMembership';
import { getMe } from 'UsersModule/selectors';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { capacityGroupFormatUtils } from 'CapacityModule/utils/capacityGroup';
import { Space } from 'components/Space';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}
`;

const startLabels = {
  start: 'START'
};

const endLabels = {
  start: 'END'
};

const colWidths = {
  delete: 34
};
const modalColWidths = `${colWidths.delete}px 420px 80px 20px 80px`;

const StartDateCell = ({
  item,
  onDateChange
}: CapacityMemberPolicyDropdownCellProps) => {
  const isStartOfTime = !item.start_date;

  return (
    <StyledDate className="startDate" center>
      <DateRangeCalendar
        isSingleDay
        showInputs
        labels={startLabels}
        onSubmit={({ startDate }) => {
          if (startDate?.isValid?.()) {
            onDateChange({ item, date: startDate, dateType: 'startDate' });
          }
        }}
        itemStartDate={item.start_date}
        customInput={(startDate, endDate, handleOpen) => (
          <div onClick={handleOpen}>
            {isStartOfTime ? 'Start' : moment(startDate).format('M/DD/YY')}
          </div>
        )}
      />
    </StyledDate>
  );
};

const EndDateCell = ({
  item,
  onDateChange
}: CapacityMemberPolicyDropdownCellProps) => {
  const isOngoing = capacityGroupMembershipUtils.isOngoing(item);

  return (
    <StyledDate className="endDate" center isDisabled={isOngoing}>
      <DateRangeCalendar
        isSingleDay
        showInputs
        labels={endLabels}
        onSubmit={({ startDate: endDate }) => {
          if (endDate?.isValid?.()) {
            onDateChange({ item, date: endDate, dateType: 'endDate' });
          }
        }}
        itemStartDate={item.end_date}
        customInput={(startDate, endDate, handleOpen) => (
          <div onClick={isOngoing ? undefined : handleOpen}>
            {isOngoing ? 'Present' : moment(item.end_date).format('M/DD/YY')}
          </div>
        )}
      />
    </StyledDate>
  );
};

const DateSeparatorCell = () => <StyledCell center>-</StyledCell>;

const PolicyCell = ({
  onEdit,
  item
}: CapacityMemberPolicyDropdownCellProps) => {
  return (
    <StyledCell onClick={(event) => onEdit({ event, item })}>
      <span style={{ width: 30 }}>
        {formatNumWithMaxTwoDecimals(item.capacity_group.hours)}h
      </span>
      <Space value={15} />
      <span>{capacityGroupFormatUtils.getName(item.capacity_group)}</span>
    </StyledCell>
  );
};

const DeleteCell = ({
  item,
  isOnlyPolicy,
  onDelete
}: CapacityMemberPolicyDropdownCellProps) => {
  const isDeleteDisabled = isOnlyPolicy;

  return (
    <DeleteIconContainer
      {...defaultTooltipProps}
      data-tip={isDeleteDisabled ? 'Unable to delete only policy.' : ''}
      onClick={isDeleteDisabled ? undefined : () => onDelete(item)}
    >
      <StyledDeleteIcon />
    </DeleteIconContainer>
  );
};

const columnNames = {
  delete: 'delete',
  policy: 'policy',
  startDate: 'startDate',
  dateSeparator: 'dateSeparator',
  endDate: 'endDate'
} as const;

type ColumnNames = ValueOf<typeof columnNames>;
type ColumnFields = { name: ColumnNames; label: string };

const columnsHash: Record<ColumnNames, ColumnFields> = {
  delete: { name: 'delete', label: '' },
  policy: { name: 'policy', label: 'Policy' },
  startDate: { name: 'startDate', label: 'Start' },
  dateSeparator: { name: 'dateSeparator', label: '' },
  endDate: { name: 'endDate', label: 'End' }
};

const cells: Record<
  ColumnNames,
  (props: CapacityMemberPolicyDropdownCellProps) => JSX.Element
> = {
  delete: DeleteCell,
  policy: PolicyCell,
  startDate: StartDateCell,
  dateSeparator: DateSeparatorCell,
  endDate: EndDateCell
};

const HeaderCell = ({
  className,
  label
}: CapacityMemberPolicyDropdownHeaderCellProps) => (
  <StyledHeaderCell className={className}>{label}</StyledHeaderCell>
);

const headerCells: Record<
  ColumnNames,
  (props: CapacityMemberPolicyDropdownHeaderCellProps) => JSX.Element
> = {
  delete: EmptyCell,
  policy: HeaderCell,
  startDate: HeaderCell,
  dateSeparator: HeaderCell,
  endDate: HeaderCell
};

const columns: ColumnFields[] = [
  columnsHash.delete,
  columnsHash.policy,
  columnsHash.startDate,
  columnsHash.dateSeparator,
  columnsHash.endDate
];

const listWidths = 634;

export interface CapacityMemberPolicyDropdownProps {
  anchorEl: HTMLElement;
  accountId: number;
  teamMembershipId: number;
  parentRequestStatusId: string;
  onStickyClick: MouseEventHandler<HTMLDivElement>;
  onClose: () => void;
  onEdit: ({
    item,
    event
  }: {
    item: CapacityGroupMembership;
    event: MouseEvent<HTMLDivElement>;
  }) => void;
  onDateChange: ({
    item,
    date,
    dateType
  }: {
    item: CapacityGroupMembership;
    date: Moment;
    dateType: 'startDate' | 'endDate';
  }) => void;
  onDelete: (item: CapacityGroupMembership) => void;
}

export const CapacityMemberPolicyDropdown = ({
  anchorEl,
  teamMembershipId,
  accountId,
  parentRequestStatusId,
  onStickyClick,
  onClose,
  onEdit,
  onDateChange,
  onDelete
}: CapacityMemberPolicyDropdownProps) => {
  const dispatch = useAppDispatch();

  const getMemberCapacityPolicies = useMemo(
    makeGetOrderedMemberCapacityPolicies,
    []
  );

  const memberPolicies = useAppSelector((state) =>
    getMemberCapacityPolicies(state, { teamMembershipId })
  );

  const me = useAppSelector(getMe);

  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const teamMember = teamMembersHash[accountId];

  const { MultiStepFlyout, openFlyout, closeFlyout } = useMultiStepFlyout();

  const fetchCapacityGroupMembershipsRequestStatusId = `fetchCapacityGroupMemberships__${parentRequestStatusId}`;

  const { status: fetchCapacityGroupMembershipsRequestStatus } =
    useRequestStatus({
      requestStatusId: fetchCapacityGroupMembershipsRequestStatusId
    });

  useMount(() => {
    if (!fetchCapacityGroupMembershipsRequestStatus?.isSuccess) {
      dispatch(
        fetchCapacityGroupMemberships({
          teamMembershipId,
          meta: {
            requestStatusId: fetchCapacityGroupMembershipsRequestStatusId
          }
        })
      );
    }

    openFlyout({ target: anchorEl });
  });

  const renderHeader = () => {
    if (!teamMember) return <></>;
    return (
      <HeaderContainer style={{ paddingLeft: colWidths.delete }}>
        {/* TODO: Confirm we don't need to support unassigned member like in rates */}
        <MemberInitials
          member={teamMember}
          size="medium"
          classes={`sidebar-member-initials selected ${
            me?.id === teamMember.account?.id ? 'logged' : 'regular'
          }-member-no-hover`}
        />
        <StyledMemberName
          style={{ maxWidth: listWidths - colWidths.delete - 32 - 30 }}
        >
          {teamMember.account.first_name}&apos;s Capacity Policy
        </StyledMemberName>
      </HeaderContainer>
    );
  };

  const renderItem = useCallback(
    ({ item }: { item: CapacityGroupMembership }) => {
      const isOnlyPolicy = memberPolicies.length === 1;

      const props: CapacityMemberPolicyDropdownCellProps = {
        item,
        isOnlyPolicy,
        onEdit,
        onDelete,
        onDateChange
      };

      return (
        <StyledRowContainer colWidths={modalColWidths}>
          {columns.map((column, index) => {
            const Cell = cells[column.name];
            return <Cell {...props} key={index} />;
          })}
        </StyledRowContainer>
      );
    },
    [memberPolicies, onDateChange, onDelete, onEdit]
  );

  const renderTableBodyHeader = () => {
    return (
      <div style={{ width: listWidths }}>
        <MiniHeaderContainer colWidths={modalColWidths}>
          {columns.map((column, index) => {
            const Cell = headerCells[column.name];
            return (
              <Cell label={column.label} className={column.name} key={index} />
            );
          })}
        </MiniHeaderContainer>
        <AddRateContainer
          onClick={onStickyClick}
          emptyList={!memberPolicies.length}
          style={{
            width: `calc(100% - ${colWidths.delete}px)`,
            marginLeft: colWidths.delete
          }}
        >
          + Add New
        </AddRateContainer>
      </div>
    );
  };

  const _closeFlyout = () => {
    closeFlyout();
    onClose();
  };

  const renderHeaderButton = () => (
    <CloseIconStyle onClick={_closeFlyout}>
      <DoneButton>Done</DoneButton>
    </CloseIconStyle>
  );

  useEffect(() => {
    rebuildTooltip();
  }, []);

  if (!teamMember) return <></>;

  return (
    <MultiStepFlyout
      copy={{}}
      items={memberPolicies}
      idKey="id"
      renderHeader={renderHeader}
      renderItem={renderItem}
      handleClose={_closeFlyout}
      stickyRow
      hideFooter
      listWidth={listWidths}
      isWhite
      isAlwaysGlobal
      listHeight={200}
      listItemContainerStyle={listItemContainerStyle}
      renderTableBodyHeader={renderTableBodyHeader}
      globalClassName="capacity-member-policy-dropdown"
      renderHeaderButton={renderHeaderButton}
    />
  );
};

interface CapacityMemberPolicyDropdownCellProps
  extends Pick<
    CapacityMemberPolicyDropdownProps,
    'onDateChange' | 'onEdit' | 'onDelete'
  > {
  item: CapacityGroupMembership;
  isOnlyPolicy: boolean;
}

interface CapacityMemberPolicyDropdownHeaderCellProps {
  label: string;
  className: string;
}
