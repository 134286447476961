import React, { useCallback, useMemo } from 'react';
import TaskBatchActionsModal from 'views/Home/TaskBatchActions/TaskBatchActionsModal';
import {
  getRemoveMemberModalOpen,
  getRemoveMemberModalMemberBudget,
  getModalProjectInfo
} from 'BudgetModule/selectors';
import { getPhaseNamesByPhaseId, getSelectedTeamId } from 'selectors';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  deleteMemberFromProject,
  archivePhaseMember,
  fetchPhasesByProjectIds,
  archiveActivityMember
} from 'actionCreators';
import {
  closeRemoveMemberModal,
  fetchMemberBudgets,
  deleteMemberBudget
} from 'BudgetModule/actionCreators';

const RemoveMemberModal = () => {
  const isRemoveMemberModalOpen = useSelector(getRemoveMemberModalOpen);
  const removeModalMember = useSelector(getRemoveMemberModalMemberBudget);

  const ownPropsMemo = useMemo(
    () => ({ projectId: removeModalMember?.project_id }),
    [removeModalMember]
  );
  const projectSelector = useCallback(
    (state) => getModalProjectInfo(state, ownPropsMemo),
    [ownPropsMemo]
  );
  const project = useSelector(projectSelector);
  const phaseNames = useSelector(getPhaseNamesByPhaseId);
  const currentPhaseName = phaseNames[removeModalMember?.phaseId];
  const selectedTeamId = useSelector(getSelectedTeamId);
  const dispatch = useDispatch();

  const permissions = useMemo(
    () => ({
      projectId: removeModalMember?.project_id,
      teamId: selectedTeamId
    }),
    [removeModalMember?.project_id, selectedTeamId]
  );

  const handleConfirmation = useCallback(() => {
    if (removeModalMember) {
      if (!removeModalMember.isUnassigned) {
        batch(() => {
          dispatch(
            deleteMemberFromProject({
              projectId: project.id,
              accountId: removeModalMember.project_membership?.account_id,
              phaseId: removeModalMember.phaseId,
              memberBudgetId: removeModalMember.memberBudgetId,
              memberIsLoggedUser: false,
              phaseMembershipId: removeModalMember.phaseMembershipId,
              permissions
            })
          );
          dispatch(fetchMemberBudgets({ projectId: project.id }));

          dispatch(
            fetchPhasesByProjectIds({
              projectIds: [project.id]
            })
          );
        });
      } else {
        // Remove unassigned member from activity phase
        if (removeModalMember.activityMembershipId) {
          dispatch(
            archiveActivityMember({
              projectId: project.id,
              activityPhaseMemberId: removeModalMember.activityMembershipId,
              archive: true
            })
          );
        } else if (removeModalMember.phaseId) {
          // remove unassigned member from phase
          dispatch(
            archivePhaseMember({
              id: removeModalMember.phaseMembershipId,
              phaseId: removeModalMember.phaseId,
              projectId: project.id,
              archive: true
            })
          );
        } else {
          // Remove from project
          dispatch(
            deleteMemberBudget({
              projectId: project.id,
              id: removeModalMember.id,
              permissions,
              onSuccess: removeModalMember.onRemoveUnassignedRoleSuccess
            })
          );
        }
      }
    }
    dispatch(closeRemoveMemberModal());
  }, [dispatch, permissions, project.id, removeModalMember]);

  const toggle = useCallback(() => {
    dispatch(closeRemoveMemberModal());
  }, [dispatch]);

  const renderModalContent = (
    <div>
      {removeModalMember?.isUnassigned ? (
        <div>
          Are you sure you want to
          <span style={{ fontWeight: '600' }}> permanently remove </span> the
          role from
          <span style={{ fontWeight: '600' }}>
            {' '}
            {removeModalMember.activity?.title ||
              currentPhaseName ||
              project?.title}
          </span>
          ?
        </div>
      ) : (
        <div>
          Are you sure you want to
          <span style={{ fontWeight: '600' }}> remove </span> this member from
          <span style={{ fontWeight: '600' }}> {project?.title}</span>?
        </div>
      )}
      <div className="delete-modal-subtext">
        {`Planned and Estimated time will be deleted. Spent time will be archived
        until the time is deleted or moved to another ${
          removeModalMember?.isUnassigned
            ? removeModalMember.activity
              ? 'work category'
              : removeModalMember.phaseId
              ? 'phase'
              : 'project'
            : 'project'
        }.`}
      </div>
    </div>
  );

  const ButtonRow = (
    <div className="delete-modal-footer">
      <div className="delete-modal-footer-cancel">
        <button className=" remove-cancel-button" onClick={toggle}>
          Cancel
        </button>
      </div>
      <div className="delete-modal-footer-yes">
        <button className="remove-confirm-button" onClick={handleConfirmation}>
          Remove
        </button>
      </div>
    </div>
  );

  return (
    <TaskBatchActionsModal
      hideClose
      isOpen={isRemoveMemberModalOpen}
      title={
        removeModalMember?.isUnassigned
          ? removeModalMember.activity
            ? 'Remove Member from Work Category'
            : removeModalMember.phaseId
            ? 'Remove Role from Phase'
            : 'Remove Role from Project'
          : 'Remove Member from Project'
      }
      content={renderModalContent}
      handleConfirmation={handleConfirmation}
      toggle={toggle}
      buttonRow={ButtonRow}
      titleClassName={!removeModalMember?.isUnassigned ? 'pure-black-font' : ''}
      modalClassName="remove-member-modal"
    />
  );
};

export default RemoveMemberModal;
