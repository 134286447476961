import ReactTooltip from 'react-tooltip';
import debounce from 'lodash/debounce';

export const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

export const hideTooltip = debounce(() => ReactTooltip.hide(), 200, {
  leading: true,
  trailing: false
});

export const defaultTooltipProps = {
  'data-for': 'app-tooltip',
  'data-effect': 'solid',
  'data-class': 'mw-40vw',
  'data-html': true
};
