import styled from 'styled-components';
import Timeline, {
  Id,
  ReactCalendarTimelineProps,
  SidebarHeader as ReactCalendarTimelineSidebarHeader,
  SidebarHeaderChildrenFnProps,
  TimelineHeaders,
  TimelineMarkers
} from 'react-calendar-timeline';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import { ItemRendererByType } from '../ItemRenderers/ItemRendererByType';
import { BaseTimelineProps } from 'TimelinesModule/types/timeline';
import { TimelineConfigProvider } from './providers/TimelineConfigProvider';
import { WorkPlanItemMenuProvider } from '../ItemRenderers/WorkPlanItemRenderer/WorkPlanItemMenuProvider';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Groups } from 'TimelinesModule/types/groups';
import { Items } from 'TimelinesModule/types/items';
import { getSnappedDate } from 'appUtils/projectPlannerUtils';
import {
  DATE_HEADER_HEIGHT,
  dayInMilliseconds,
  defaultTimelineConfig,
  ROW_HEIGHTS,
  TIMELINE_GROUP_TYPES
} from 'TimelinesModule/components/constants';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import CustomTodayMarker from 'views/projectPlanner/projectTimeline/Markers/TodayMarker';
import { PrimaryDateHeader } from '../TimelineHeader/DateHeaders/PrimaryDateHeader';
import { SecondaryDateHeader } from '../TimelineHeader/DateHeaders/SecondaryDateHeader';
import { GhostDateHeader } from '../TimelineHeader/DateHeaders/GhostDateHeader';
import { TimelineHeader } from '../TimelineHeader/TimelineHeader';
import { LeftSidebarHeader } from '../TimelineSidebar/LeftSidebarHeader';
import { GroupRendererByType } from '../GroupRenderers/GroupRendererByType';
import { RowRendererByType } from '../RowRenderers/RowRendererByType';
import { useThrottle } from 'react-use';
import useCollapse from 'appUtils/hooks/useCollapse';

const buffer = dayInMilliseconds * 30;
const throttleDelay = 1500; // ms
export const BaseTimeline = <
  Item extends Items = Items,
  Group extends Groups = Groups
>({
  initialVisibleTimeStart,
  initialVisibleTimeEnd,
  timelineConfig: timelineConfigProps = {},
  onItemClick,
  onItemMove,
  onItemContextMenu,
  onItemDoubleClick,
  onItemResize,
  onItemSelect,
  onTimeChange,
  onGroupExpand,
  onGroupCollapse,
  groupRenderersByType,
  rowRenderersByType,
  itemRenderersByType,
  onThrottledTimeChange,
  sidebarHeader,
  ...rest
}: BaseTimelineProps<Item, Group>) => {
  const { items, groups } = rest;
  const [{ visibleTimeStart, visibleTimeEnd }, setVisibleTime] = useState({
    visibleTimeStart: initialVisibleTimeStart,
    visibleTimeEnd: initialVisibleTimeEnd
  });

  const timeRanges = useMemo(
    () => ({
      bufferedTimeStart: visibleTimeStart - buffer,
      bufferedTimeEnd: visibleTimeEnd + buffer,
      visibleTimeStart,
      visibleTimeEnd
    }),
    [visibleTimeEnd, visibleTimeStart]
  );

  const throttledTimeRanges = useThrottle(timeRanges, throttleDelay);

  const {
    bufferedTimeStart: throttledBufferedTimeStart,
    bufferedTimeEnd: throttledBufferedTimeEnd
  } = throttledTimeRanges;

  useEffect(() => {
    onThrottledTimeChange(throttledTimeRanges);
  }, [onThrottledTimeChange, throttledTimeRanges]);

  const timelineConfig = merge({}, defaultTimelineConfig, timelineConfigProps);

  const { zoom, shouldShowDateHeader, rowSize, readOnly } = timelineConfig;

  const visibleRangeDistance = visibleTimeEnd - visibleTimeStart;

  const itemHash = useMemo(() => keyBy(items, 'id'), [items]);

  const itemIds = useMemo(() => items.map(({ id }) => id), [items]);

  const handleItemMove: ReactCalendarTimelineProps<
    Item,
    Group
  >['onItemMove'] = (itemId, ...args) => {
    const item = itemHash[itemId];
    if (item) {
      onItemMove?.(item, ...args);
    }
  };

  const handleItemReszie: ReactCalendarTimelineProps<
    Item,
    Group
  >['onItemResize'] = (itemId, ...args) => {
    const item = itemHash[itemId];
    if (item) {
      onItemResize?.(item, ...args);
    }
  };

  const handleItemClick: ReactCalendarTimelineProps<
    Item,
    Group
  >['onItemClick'] = (itemId, ...args) => {
    const item = itemHash[itemId];
    if (item) {
      onItemClick?.(item, ...args);
    }
  };

  const handleItemSelect: ReactCalendarTimelineProps<
    Item,
    Group
  >['onItemSelect'] = (itemId, ...args) => {
    const item = itemHash[itemId];
    if (item) {
      onItemSelect?.(item, ...args);
    }
  };

  const handleItemDoubleClick: ReactCalendarTimelineProps<
    Item,
    Group
  >['onItemDoubleClick'] = (itemId, ...args) => {
    const item = itemHash[itemId];
    if (item) {
      onItemDoubleClick?.(item, ...args);
    }
  };

  const handleItemContextMenu: ReactCalendarTimelineProps<
    Item,
    Group
  >['onItemContextMenu'] = (itemId, ...args) => {
    const item = itemHash[itemId];
    if (item) {
      onItemContextMenu?.(item, ...args);
    }
  };

  const handleMoveResizeValidator: ReactCalendarTimelineProps<
    Item,
    Group
  >['moveResizeValidator'] = useCallback(
    (...args) => getSnappedDate(timelineConfig.zoom, ...args),
    [timelineConfig.zoom]
  );

  const baseTimelineContainerRef = useRef<HTMLDivElement>(null);
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleScrollTransitionStyle = useCallback(() => {
    baseTimelineContainerRef.current?.classList.add('scroll-transition');

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = setTimeout(() => {
      clearTransitionStyle();
    }, 1500);
  }, []);

  const clearTransitionStyle = () => {
    baseTimelineContainerRef.current?.classList.remove('scroll-transition');
  };

  const handleTimeChangeOnNav: ComponentProps<
    typeof TimelineHeader
  >['onTimeChange'] = useCallback(
    (values) => {
      handleScrollTransitionStyle();
      setVisibleTime(values);
    },
    [handleScrollTransitionStyle]
  );

  const scrollRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();

  const handleTimeChange: NonNullable<
    ReactCalendarTimelineProps<Item, Group>['onTimeChange']
  > = useCallback(
    (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
      clearTransitionStyle();
      onTimeChange?.(visibleTimeStart, visibleTimeEnd, updateScrollCanvas);
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
      setVisibleTime({
        visibleTimeStart,
        visibleTimeEnd
      });

      const scrollRefScrollLeft = scrollRef.current?.scrollLeft;
      const headerRefScrollLeft = headerRef.current?.scrollLeft;

      if (scrollRef.current && scrollRefScrollLeft !== headerRefScrollLeft) {
        scrollRef.current.scrollLeft = headerRefScrollLeft ?? 0;
      }
    },
    [onTimeChange]
  );

  const handleGroupExpand = useCallback(
    (groupId: Id) => {
      const group = groups.find((group) => group.id === groupId);
      if (group) {
        onGroupExpand?.(group);
      }
    },
    [groups, onGroupExpand]
  );

  const handleGroupCollapse = useCallback(
    (groupId: Id) => {
      const group = groups.find((group) => group.id === groupId);
      if (group) {
        onGroupCollapse?.(group);
      }
    },
    [groups, onGroupCollapse]
  );

  const renderLeftSidebarHeader = useCallback(
    (props: SidebarHeaderChildrenFnProps<never>) => (
      <LeftSidebarHeader {...props}>{sidebarHeader}</LeftSidebarHeader>
    ),
    [sidebarHeader]
  );

  const topLevelCollapseCount = groups.filter(
    ({ type }) => type !== TIMELINE_GROUP_TYPES.WORK_PLAN
  ).length;

  const { toggleCollapse, getIsOpen } = useCollapse({
    defaultAllOpen: false,
    topLevelCollapseCount,
    toggleCallback: undefined
  });

  // toggleCallback in useCollapse doesn't support passing in parameters
  // so below is the workaround to pass the key in the onToggle callback
  // until useCollapse is updated to support passing in parameters
  const handleToggleCollapse = useCallback(
    (groupId: Id) => () => {
      const isOpen = getIsOpen(groupId);
      if (isOpen) {
        handleGroupCollapse(groupId);
      } else {
        handleGroupExpand(groupId);
      }
      toggleCollapse(groupId);
    },
    [getIsOpen, handleGroupCollapse, handleGroupExpand, toggleCollapse]
  );

  const groupRenderer: ReactCalendarTimelineProps<
    Item,
    Group
  >['groupRenderer'] = useCallback(
    (props) => (
      <GroupRendererByType
        {...props}
        onToggleCollpase={handleToggleCollapse(props.group.id)}
        isOpen={getIsOpen(props.group.id)}
        customRenderers={groupRenderersByType}
      />
    ),
    [getIsOpen, groupRenderersByType, handleToggleCollapse]
  );

  const rowRenderer: ReactCalendarTimelineProps<Item, Group>['rowRenderer'] =
    useCallback(
      (props) => (
        <RowRendererByType
          {...props}
          customRenderers={rowRenderersByType}
          bufferedTimeStart={throttledBufferedTimeStart}
          bufferedTimeEnd={throttledBufferedTimeEnd}
          containerRef={timelineContainerRef.current}
        />
      ),
      [rowRenderersByType, throttledBufferedTimeStart, throttledBufferedTimeEnd]
    );

  const itemRenderer: ReactCalendarTimelineProps<Item, Group>['itemRenderer'] =
    useCallback(
      (props) => (
        <ItemRendererByType {...props} customRenderers={itemRenderersByType} />
      ),
      [itemRenderersByType]
    );

  return (
    <BaseTimelineContainer
      className="project-planner-timeline-container"
      ref={baseTimelineContainerRef}
    >
      <TimelineConfigProvider config={timelineConfig}>
        <WorkPlanItemMenuProvider>
          <TimelineHeader
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            zoom={zoom}
            onTimeChange={handleTimeChangeOnNav}
          />
          <TimelineContainer ref={timelineContainerRef}>
            <SidebarBackground />
            <Timeline
              groups={groups}
              items={items}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              rowRenderer={rowRenderer}
              resizeDetector={containerResizeDetector}
              // forcing the timeline zoom to be controlled by the zoom level
              // and preventing the timeline zoom from being changed by the scroll wheel
              minZoom={visibleRangeDistance}
              maxZoom={visibleRangeDistance}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
              selected={itemIds as unknown as number[]}
              canChangeGroup={!readOnly}
              canMove={!readOnly}
              canResize={!readOnly ? 'both' : false}
              stackItems
              lineHeight={ROW_HEIGHTS[rowSize]}
              itemHeightRatio={44 / 50}
              minResizeWidth={0}
              useResizeHandle
              sidebarWidth={275}
              clickTolerance={5}
              onItemMove={handleItemMove}
              onItemResize={handleItemReszie}
              onItemClick={handleItemClick}
              onItemSelect={handleItemSelect}
              onItemDoubleClick={handleItemDoubleClick}
              onItemContextMenu={handleItemContextMenu}
              moveResizeValidator={handleMoveResizeValidator}
              onTimeChange={handleTimeChange}
              scrollRef={(ref) => (scrollRef.current = ref)}
              headerRef={(ref) => (headerRef.current = ref)}
            >
              <TimelineMarkers>
                <CustomTodayMarker />
              </TimelineMarkers>
              <TimelineHeaders>
                <ReactCalendarTimelineSidebarHeader>
                  {renderLeftSidebarHeader}
                </ReactCalendarTimelineSidebarHeader>
                {shouldShowDateHeader ? (
                  <>
                    <PrimaryDateHeader zoom={zoom} />
                    <SecondaryDateHeader zoom={zoom} />
                  </>
                ) : (
                  // It has an issue when shift + mouse wheel scroll without the below dateHeader
                  // It is needed to render invisible dateHeader to prevent the issue
                  <GhostDateHeader zoom={zoom} />
                )}
              </TimelineHeaders>
            </Timeline>
            <FloatingArea>
              <ReadOnlyTag>Read-Only</ReadOnlyTag>
            </FloatingArea>
          </TimelineContainer>
        </WorkPlanItemMenuProvider>
      </TimelineConfigProvider>
    </BaseTimelineContainer>
  );
};

const TimelineContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;

  .react-calendar-timeline {
    user-select: none;
    flex: 1;
    padding-top: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rct-outer {
    white-space: nowrap;
  }

  .rct-header-root {
    display: flex;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: auto;
    background: ${({ theme }) => theme.colors.colorTranslucentGray4};
    border-bottom: none;

    .is-white & {
      background: white !important;
    }
  }

  .rct-calendar-header {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.colorLightGray9};
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorLightGray9};
    & > *:first-child {
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorLightGray9};
    }
  }

  .rct-horizontal-lines {
    .rct-hl-even,
    .rct-hl-odd {
      // border-box makes the height as same as the line height that passed in as props
      // important will be removed once the className project-planner-timeline-container is removed
      box-sizing: border-box !important;
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray5};
    }
  }

  .rct-vertical-lines .rct-vl {
    visibility: hidden;
  }

  .rct-sidebar {
    border-right: none;
    /* margin-top to adjust alignment of sidebar by border width of child elements */
    margin-top: -1px;

    .rct-sidebar-row {
      padding: 0;
      margin: 0;
      background: white;
      border-bottom: none;
      border-right: none;
      overflow: visible;

      & > :not(.no-top-border) {
        border-top: 1px solid ${({ theme }) => theme.colors.colorLightGray12};
      }
    }
  }

  &.scroll-transition {
    .rct-label,
    .rct-label-group,
    .rct-vl,
    .rct-item,
    .rct-hl-even,
    .rct-hl-odd {
      transition: ease 1s;
    }
  }
`;

const BaseTimelineContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SidebarBackground = styled.div`
  background: white;
  position: absolute;
  width: 275px;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);
`;

const FloatingArea = styled.div`
  position: absolute;
  top: 0;
  right: 18px;
  height: ${DATE_HEADER_HEIGHT.Primary}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReadOnlyTag = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 4px;
  border: 1px solid ${({ theme }) => theme.colors.colorCalendarBlue};
  background: ${({ theme }) => theme.colors.colorPureWhite};
  color: ${({ theme }) => theme.colors.colorCalendarBlue};
  border-radius: 3px;
  font-size: 11px;
  user-select: none;
`;
