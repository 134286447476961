import {
  makeVerification,
  makeAuthorBasedVerification
} from './makeVerification';

export const verifyCreate = makeVerification('create_task_comments');

export const verifyEdit = makeAuthorBasedVerification(
  'edit_task_comments',
  'edit_task_comments_other'
);

export const verifyDelete = makeAuthorBasedVerification(
  'delete_task_comments',
  'delete_task_comments_other'
);
