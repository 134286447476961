const authenticatedSelector = (state) => state.auth.isAuthenticated;

// requireAuthentication only redirects if it blocks a routing attempt further down the component tree. If it is applied on a route that is a leaf, there is no redirect effect and users see blank pages.

export const requireAuthentication = (element) => {
  // returns either the requireAuthentication HOC or just the plain element if we are testing (requireAuthentication breaks the test suite right now)
  if (!process.env.TEST) {
    const authWrapperMod = require('redux-auth-wrapper/history4/redirect');
    return authWrapperMod.connectedRouterRedirect({
      authenticatedSelector,
      redirectPath: '/login',
      wrapperDisplayName: 'UserIsJWTAuthenticated'
    })(element);
  } else {
    return element;
  }
};
