import React from 'react';
import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import reject from 'lodash/reject';
import find from 'lodash/find';
import {
  editGroup,
  closeEditGroupModal,
  openArchiveBoardModal,
  archiveGroup,
  createBoard,
  closeAddGroupModal,
  createBoardPreference,
  updateBoardPreference,
  deleteBoardPreference,
  navigateToBoard
} from 'actionCreators';
import { AddEditBoardModal } from '..';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import {
  getSelectedGroup,
  getShouldStayOnViewAfterCreate,
  getGroupsState,
  getGroups,
  getAuth,
  getMe,
  getProjectsState,
  getTeamName
} from 'selectors';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import {
  archivePortfolio,
  makePortfolioPrivate,
  editPortfolio
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import withPermissionsCheck from 'hocs/withPermissionsCheck';

class AddEditBoardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boardName: '',
      isPrivate: false,
      projectsInGroup: 0,
      color: null
    };
    bindAll(this, [
      'handleChange',
      'handleCheckChange',
      'formSubmitAdd',
      'formSubmitEdit',
      'toggleArchiveModal',
      'archiveGroupOnClick',
      'toggleWrapper'
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // ADD
    if (nextProps.groups.isAddModalOpen !== this.props.groups.isAddModalOpen) {
      this.setState({ boardName: '', isPrivate: false });
    }

    // EDIT
    if (
      nextProps.groups.editingGroup &&
      nextProps.groups.editingGroup !== this.props.groups.editingGroup
    ) {
      const { selectedBoard } = this.props;
      this.setState({
        boardName: selectedBoard.name,
        isPrivate: selectedBoard.is_private,
        projectsInGroup: this.props.projects.totalProjects
      });
    }
  }

  toggleArchiveModal(event) {
    this.props.openArchiveBoardModal(!this.props.isOpenArchiveBoardModal);
    if (event) {
      event.preventDefault();
    }
  }

  archiveGroupOnClick(event) {
    const { history, navigateToBoard, boardList } = this.props;
    this.props.openArchiveBoardModal(false);
    this.props.archiveGroup(this.props.groups.editingGroup);
    this.props.closeEditGroupModal();
    // Find the next board and redirect to it
    const otherGroups = reject(
      boardList,
      (group) => group.id === this.props.groups.editingGroup
    );

    // If there are otherGroups, redirect to the first one
    if (otherGroups.length > 0) {
      const [board] = otherGroups;
      const params = {
        teamSlug: board.team_slug,
        boardSlug: board.slug,
        boardId: board.id
      };
      navigateToBoard(params);
    } else {
      history.push('/');
    }
    event.preventDefault();
  }

  handleChange(event) {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  handleCheckChange(event) {
    const name = event.target.name;
    this.setState({ [name]: !this.state[name] });
  }

  formSubmitAdd(event) {
    const {
      createBoard,
      addEditProjectModalIsOpen,
      shouldStayOnViewAfterCreate
    } = this.props;
    createBoard(
      this.state.boardName,
      this.state.isPrivate,
      addEditProjectModalIsOpen || shouldStayOnViewAfterCreate,
      this.state.color
    );
    event.preventDefault();
  }

  formSubmitEdit(event) {
    const { editGroup, groups, closeEditGroupModal } = this.props;

    editGroup(groups.editingGroup, this.state.boardName, this.state.isPrivate);
    event.preventDefault();
    closeEditGroupModal();
  }

  toggleWrapper() {
    const { groups, closeAddGroupModal, closeEditGroupModal, createBoard } =
      this.props;

    if (groups.isAddModalOpen) {
      if (this.state.boardName && this.state.boardName != '') {
        createBoard(
          this.state.boardName,
          this.state.isPrivate,
          null,
          this.state.color
        );
      } else {
        closeAddGroupModal();
      }
    } else {
      closeEditGroupModal();
    }
  }

  createColor = ({ id, color }) => {
    const {
      createBoardPreference,
      groups: { isAddModalOpen }
    } = this.props;
    if (isAddModalOpen) {
      this.setState({ color });
    } else {
      this.setState({ color: null });
      createBoardPreference({ id, color });
    }
  };

  getCanArchivePortfolio = () => {
    const { checkPermission, archivePortfolio, teamId } = this.props;

    if (teamId) {
      return checkPermission(archivePortfolio, {
        permissions: {
          teamId
        }
      });
    }
    return false;
  };

  getCanTogglePrivacy = () => {
    const { checkPermission, makePortfolioPrivate, teamId } = this.props;

    if (teamId) {
      return checkPermission(makePortfolioPrivate, {
        permissions: {
          teamId
        }
      });
    }
    return false;
  };

  // This permission is only being used for portfolio title right now
  getCanEditPortfolio = () => {
    const { checkPermission, editPortfolio, teamId } = this.props;

    if (teamId) {
      return checkPermission(editPortfolio, {
        permissions: {
          teamId
        }
      });
    }
    return false;
  };

  updateColor = this.props.updateBoardPreference;
  deleteColor = this.props.deleteBoardPreference;

  setColor = {
    board: {
      create: this.createColor,
      update: this.updateColor,
      delete: this.deleteColor
    }
  };

  render() {
    const {
      groups,
      closeAddGroupModal,
      closeEditGroupModal,
      disableSave,
      selectedBoard,
      teamName
    } = this.props;

    const teamId = groups.selectedBoard && groups.selectedBoard.team_id;

    const userCanArchive = this.getCanArchivePortfolio();
    const userCanTogglePrivacy = this.getCanTogglePrivacy();
    const userCanEditPortfolio = this.getCanEditPortfolio();

    return (
      <AddEditBoardModal
        userCanTogglePrivacy={userCanTogglePrivacy}
        userCanArchive={userCanArchive}
        userCanEditPortfolio={userCanEditPortfolio}
        isOpen={groups.isAddModalOpen || groups.isEditModalOpen}
        toggleModal={this.toggleWrapper}
        errorMessage={groups.statusText}
        boardName={this.state.boardName}
        isPrivate={this.state.isPrivate}
        disableSave={disableSave}
        handleChange={this.handleChange}
        handleCheckChange={this.handleCheckChange}
        handleSubmit={
          groups.editingGroup ? this.formSubmitEdit : this.formSubmitAdd
        }
        isEditModal={!!groups.editingGroup}
        toggleArchiveModal={this.toggleArchiveModal}
        archiveModalIsOpen={this.props.isOpenArchiveBoardModal}
        projectsInGroup={this.state.projectsInGroup}
        archiveGroupOnClick={this.archiveGroupOnClick}
        closeEditGroupModal={closeEditGroupModal}
        closeAddGroupModal={closeAddGroupModal}
        isCreatingGroup={this.props.isCreatingGroup}
        boardId={selectedBoard && selectedBoard.id}
        setColor={this.setColor}
        tempColor={this.state.color}
        teamName={teamName}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  groups: getGroupsState(state),
  boardList: getGroups(state),
  auth: getAuth(state),
  me: getMe(state),
  teamName: getTeamName(state),
  disableSave: state.groups.disableSave,
  isOpenArchiveBoardModal: state.groups.isOpenArchiveBoardModal,
  selectedBoard: getSelectedGroup(state) || state.groups.selectedBoard,
  projects: getProjectsState(state),
  shouldStayOnViewAfterCreate: getShouldStayOnViewAfterCreate(state),
  isCreatingGroup: state.groups.isCreatingGroup,
  addEditProjectModalIsOpen:
    state.projects.isAddModalOpen || state.projects.isEditModalOpen,
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  createBoard,
  editGroup,
  closeEditGroupModal,
  closeAddGroupModal,
  openArchiveBoardModal,
  archiveGroup,
  createBoardPreference,
  updateBoardPreference,
  deleteBoardPreference,
  navigateToBoard,
  archivePortfolio,
  makePortfolioPrivate,
  editPortfolio
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createBoard: (...args) => {
    dispatchProps.createBoard(stateProps.auth.token, ...args);
  },
  editGroup: (...args) =>
    dispatchProps.editGroup(stateProps.auth.token, ...args)
});

export default withPermissionsCheck(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(AddEditBoardContainer)
);
