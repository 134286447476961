import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewNotesIcon from 'images/addviewnotesicon.svg';
import AddViewMeetingIcon from 'images/addviewmeetingicon.svg';
import AddViewPlannerIcon from 'images/addviewplannericon.svg';
import AddViewTimelinesIcon from 'images/addviewtimelinesicon.svg';

export const moduleTextHash = {
  1: 'Members',
  2: 'Clients',
  3: 'Projects',
  4: 'Timesheets',
  5: 'Sync Log'
};

export const moduleHash = {
  1: 'members',
  2: 'clients',
  3: 'projects',
  4: 'timesheets',
  5: 'synclog'
};

export const defaultNavModuleIds = [1, 2, 3, 4, 5];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'Manage Members.',
  2: 'View the latest timesheet submissions and manage approvals for teams that track time.',
  3: `Keep up on your teams workload at a glance with a view of everyone's planned tasks.`,
  4: `Keep up on your teams workload at a glance with a heatmap view of everyone's planned hours.`
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#3db489',
  3: '#DAAD0D',
  4: '#E16AA5'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewNotesIcon}) `,
  3: `url(${AddViewTimelinesIcon}) `,
  4: `url(${AddViewPlannerIcon}) `
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
