import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import withRouterParams from './WithRouterParams';
import { getMatchedRouteParams } from 'selectors';
import { MemberModal } from 'views';
import MemberModalNavigationTracker from 'views/Navigation/MemberModalNavigationTracker';
import ScopeModalRouter from './ScopeModalRouter';

class MemberModalRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      match.url === nextLocation.pathname;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    if (!match.params.memberId || !match.params.memberViewType) {
      return null;
    }
    const memberId = match.params.memberId;
    const memberViewType = match.params.memberViewType;
    return (
      <>
        <MemberModalNavigationTracker />
        <MemberModal
          accountId={memberId}
          memberViewType={memberViewType}
          isOpen={!!match.params.memberId}
        />
        <Route
          path={`${match.url}/scope/:scopeId`}
          component={ScopeModalRouter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberModalRouter)),
  true
);
