import styled from 'styled-components';
import { Modal, ModalHeader } from 'reactstrap';

interface MfaSetupModalProps {
  isOpen: boolean;
  onToggle?: () => void;
}

export const MfaSetupModal = ({ isOpen, onToggle }: MfaSetupModalProps) => {
  return (
    <StyledModal isOpen={isOpen}>
      <ModalHeader toggle={onToggle} />
      <MfaSetupModalIframe
        src={`${process.env.MOSAIC_AUTH_DOMAIN}/login?notRedirectIfAuthenticated=true`}
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: 962px;
  .modal-content {
    width: 962px;
    height: 900px;
    -webkit-border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    border-radius: 16px !important;
    overflow: hidden;
  }
`;

const MfaSetupModalIframe = styled.iframe`
  width: 962px;
  height: 900px;
  border: none;
`;
