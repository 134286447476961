import * as entityActions from '../actions';
import { select } from 'redux-saga/effects';
import { getAuthToken } from 'selectors';
import { fetchEntity } from './generics';
import { api } from '../service';

const { fetchPriorities } = entityActions;

export function* fetchPrioritiesWorker(action) {
  const token = yield select(getAuthToken);
  yield fetchEntity(
    fetchPriorities,
    api.fetchPriorities,
    undefined,
    [token],
    action
  );
}
