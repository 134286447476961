import styled from 'styled-components';
import theme from 'theme';

import SkinnyArrowIcon from 'icons/SkinnyArrowIcon';
import { TextButtonWithBorder } from 'components/styles';

import {
  InputDiv,
  InfoPanelContainer
} from 'components/DateRangeCalendar/styles';

export const KaratLeft = styled(SkinnyArrowIcon)`
  ${(props) => props.isHidden && 'visibility: hidden;'}
  cursor: pointer;
  &:hover {
    path {
      stroke: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const KaratRight = styled(KaratLeft)`
  transform: rotate(180deg);
`;

export const ClearButton = styled(TextButtonWithBorder)`
  border-color: transparent;
  ${(props) =>
    props.isHidden &&
    `
    visibility: hidden;
    pointer-events: none;
  `}
  &:hover {
    border-color: ${theme.colors.colorLightGray6};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  padding: 0 18px;
`;

export const Footer = styled.div`
  position: relative;
  width: 92%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MonthBg = styled.div`
  position: absolute;
  width: 40px;
  height: 32px;
  border-radius: 20px;
  visibility: hidden;
  ${(props) =>
    props.isSelected &&
    `
    background: ${theme.colors.colorCalendarBlue};
    visibility: visible;
  `}
`;

export const MonthsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const Month = styled.div`
  width: 25%;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray7};
  ${(props) =>
    !props.isDisabled &&
    !props.isSelected &&
    `
    cursor: pointer;
    &:hover ${MonthBg} {
      filter: brightness(96%);
    }
  `}
  ${(props) =>
    props.isSelected &&
    `
    font-weight: 600;
    font-size: 16px;
    color: ${theme.colors.colorPureWhite};
  `}
  ${(props) => props.isDisabled && `color: ${theme.colors.colorCalendarGray};`}
  ${(props) => props.isCurrent && `color: ${theme.colors.colorCalendarOrange};`}
  span {
    position: relative;
  }
`;

export const Year = styled.div`
  text-align: center;
  width: 66px;
  font-weight: 700;
  font-size: 24px;
  color: black;
  position: relative;
  bottom: 2px;
`;

export const MonthPickerContainer = styled.div`
  width: 293px;
  height: ${({ height }) => height || '364px'};
  padding: 20px 15px;
  background: white;

  /* ButtonPanel */
  ${InfoPanelContainer} {
    padding-left: 15px;
    margin-bottom: 3px;
    ${InputDiv} {
      margin: 0;
      &:first-child {
        margin-right: 20px;
      }
      .label {
        color: ${theme.colors.colorCalendarGray};
        font-size: 12px;
        margin-bottom: 5px;
      }
      input {
        padding-left: 10px;
        height: 28px;
        width: 106px !important;
        border-color: ${theme.colors.colorLightGray12} !important;
        &:disabled {
          width: 106px !important;
          pointer-events: none;
          color: ${theme.colors.colorLightGray15} !important;
          border: none;
          background: transparent;
        }
      }
    }
  }
`;
