import React from 'react';
import { connect } from 'react-redux';
import {
  updateProjectStage,
  deleteProjectStage,
  createProjectStage
} from 'actionCreators';
import { getMatchedRouteParams } from 'selectors';
import { withRouter } from 'react-router-dom';
import OptionsSelectFlyoutListItem from 'components/OptionsSelectFlyout/OptionsSelectFlyoutListItem';

import {
  StyledStageContainer,
  StyledStageIcon,
  StyledStageText,
  StyledEmptyStageIcon
} from './styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const staticNewStage = { title: 'New Stage', color: '#b3b3b3', id: 'new' };
const unselectStage = { title: 'No Stage', color: '#b3b3b3', id: null };

const stageCopy = {
  inputPlaceholder: 'New Stage',
  addHeader: 'Add Stage',
  editHeader: 'Edit Stage'
};

class ProjectStageListItem extends React.Component {
  handleSave = (updatedState) => {
    const {
      createProjectStage,
      updateProjectStage,
      handleEditClear,
      listItem,
      isNew,
      matchedParams: { boardId }
    } = this.props;
    const payload = {
      ...listItem,
      board_id: boardId,
      ...updatedState
    };
    const apiCall = isNew ? createProjectStage : updateProjectStage;
    apiCall(payload);
    handleEditClear();
  };

  renderListItem = (item) => (
    <StyledStageContainer>
      {item === unselectStage ? (
        <StyledEmptyStageIcon />
      ) : (
        <StyledStageIcon color={item.color} fillcolor={item.color} />
      )}

      <StyledStageText> {item.title}</StyledStageText>
    </StyledStageContainer>
  );

  render() {
    const { deleteProjectStage, isUnselect } = this.props;
    return (
      <OptionsSelectFlyoutListItem
        handleSave={this.handleSave}
        handleDelete={deleteProjectStage}
        colorPickerOriginType={ORIGIN_TYPE_STRINGS.PROJECT_STAGE}
        staticNewListItem={isUnselect ? unselectStage : staticNewStage}
        copy={stageCopy}
        renderListItem={this.renderListItem}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateProjectStage,
  deleteProjectStage,
  createProjectStage
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectStageListItem)
);
