import React from 'react';
import ReactDOM from 'react-dom';
import {
  StyledListItemWrapper,
  PencilContainer,
  StatusEditHeader,
  SaveButton,
  StatusEditInput,
  DeleteButton,
  TaskStatusDragGripPanel,
  DeleteButtonContainer,
  DropdownItemComponent
} from 'views/Home/Tasks/styles';
import { defaultTaskPropertyColors } from 'appUtils/styleUtils';
import SmallOutlinedPencil from 'icons/SmallOutlinedPencil';
import ColorPicker from 'components/ColorPicker';
import DragGripIcon from 'icons/DragGripIcon';
import { portal } from 'appUtils/portal';

const randomToEight = () => Math.floor(Math.random()) * 9;
const defaultRenderListItem = (item) => item.title;

class OptionsSelectFlyoutListItem extends React.Component {
  state = {
    title: this.props.listItem ? this.props.listItem.title : '',
    color: this.props.listItem
      ? this.props.listItem.color
      : defaultTaskPropertyColors[randomToEight()]
  };

  componentDidUpdate(prevProps) {
    const { listItem } = this.props;
    if (prevProps.listItem !== listItem) {
      this.setState({ title: listItem.title });
    }
  }

  handleDelete = () => {
    const { handleEditClear, handleDelete, listItem, isNew } = this.props;
    handleEditClear();
    if (!isNew) {
      handleDelete(listItem);
    }
  };

  handleSubmit = () => {
    const { setSelectedItem, listItem, isNew } = this.props;
    if (!isNew) {
      setSelectedItem(listItem);
    }
  };

  handleEdit = () => {
    const { setEditing, listItem = this.props.staticNewListItem } = this.props;
    setEditing(listItem);
  };

  handleSave = () => {
    const { handleEditClear, handleSave } = this.props;
    handleSave(this.state);
    handleEditClear();
  };

  handleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  handleColorChange = ({ color }) => {
    this.setState({ color });
  };

  makeColorChangeProps = () => {
    if (!this.colorChange) {
      this.colorChange = {
        [this.props.colorPickerOriginType]: {
          create: this.handleColorChange,
          update: this.handleColorChange,
          delete: this.handleColorChange
        }
      };
    }
    return this.colorChange;
  };

  render() {
    const {
      listItem = this.props.staticNewListItem,
      isEditing,
      isNew,
      editMode,
      provided = {},
      index,
      colorPickerOriginType,
      copy,
      renderListItem = defaultRenderListItem,
      snapshot = {}
    } = this.props;
    const { title } = this.state;
    const usePortal = snapshot.isDragging;
    const child = (
      <StyledListItemWrapper
        isEditing={isEditing}
        editMode={editMode}
        index={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        onClick={
          !isEditing
            ? editMode
              ? this.handleEdit
              : this.handleSubmit
            : undefined
        }
      >
        {!isEditing ? (
          <>
            {editMode && (
              <TaskStatusDragGripPanel>
                <DragGripIcon />
              </TaskStatusDragGripPanel>
            )}
            <DropdownItemComponent isInDropdown newStatus={isNew}>
              {renderListItem(listItem, index)}
            </DropdownItemComponent>
            {editMode && (
              <PencilContainer
                onClick={this.handleEdit}
                editMode={editMode}
                isEditing={isEditing}
              >
                <SmallOutlinedPencil />
              </PencilContainer>
            )}
          </>
        ) : (
          <div>
            <StatusEditHeader>
              <div>{isNew ? copy.addHeader : copy.editHeader}</div>
              <SaveButton onClick={this.handleSave}>Save</SaveButton>
            </StatusEditHeader>
            <StatusEditInput
              value={title}
              onChange={this.handleChange}
              placeholder={copy.inputPlaceHolder}
            />
            <ColorPicker
              id={listItem.id}
              preference={this.state.color || listItem.color}
              originType={colorPickerOriginType}
              shouldHide={false}
              className="color-picker-container"
              // ref={this.setColorPickerRef}
              apiProps={this.makeColorChangeProps()}
              row
              alwaysOpen
            >
              <div />
            </ColorPicker>
            <DeleteButtonContainer>
              <DeleteButton newStatus={isNew} onClick={this.handleDelete}>
                {isNew ? 'Cancel' : 'Delete'}
              </DeleteButton>
            </DeleteButtonContainer>
          </div>
        )}
      </StyledListItemWrapper>
    );
    return usePortal ? ReactDOM.createPortal(child, portal) : child;
  }
}

export default OptionsSelectFlyoutListItem;
