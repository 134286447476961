import React, { useMemo } from 'react';
import styled from 'styled-components';
import { calculateBorderColorPreference } from 'appUtils/styleUtils';
import theme from 'theme';
import {
  getUserTheme,
  makeGetBoardById,
  getUserIsWorkloadManager
} from 'selectors';
import moment from 'moment';
import { connect } from 'react-redux';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import SmallWorkCategoryIcon from 'icons/SmallWorkCategoryIcon';

const StyledDateContainer = styled.div`
  font-size: 10px;
  margin-top: 2px;
  color: ${theme.colors.colorMediumGray9};
  text-shadow: none;
  display: flex;
  align-items: center;
  font-weight: 400;
`;

const StyledPhase = styled.div`
  white-space: nowrap;
  line-height: 1.1;
  color: ${theme.colors.colorMediumGray9};
`;

const StyledBudgetPhaseMilestoneIcon = styled(BudgetPhaseMilestoneIcon)`
  height: 16px;
  width: 16px;
  margin-right: 5px;
  .milestone-icon-box {
    stroke: ${theme.colors.colorMediumGray9};
  }
  .dollar-sign-in-icon {
    fill: ${theme.colors.colorPureWhite};
  }
`;

const StyledWorkCategoryBar = styled.div`
  background-color: ${theme.colors.colorPureWhite};
  border: 2px solid ${calculateBorderColorPreference};
  opacity: ${(props) => (props.dragging ? 0.4 : 1)};
  height: 32px;
  margin: 22px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px 0px 8px;
  border-radius: 100px;
`;

const StyledWorkCategoryBarContainer = styled.div`
  position: relative;
`;

const StyledSchedulePhaseNameContainer = styled.div`
  font-size: 10px;
  margin-top: 2px;
  color: ${theme.colors.colorMediumGray9};
  text-shadow: none;
  font-weight: 400;
`;

const noStyle = {
  borderColor: '',
  background: 'transparent',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer'
};
const draggingStyle = {
  borderColor: '',
  border: 'none',
  backgroundColor: `${theme.colors.colorIceBlue}`,
  cursor: 'grabbing'
  // background: 'transparent',
  // backgroundColor: 'transparent'
  // cursor: 'ew-resize'
};

const SmallWorkCategoryIconContainer = styled.div`
  margin-right: 3px;
  margin-top: -1px;
`;

const WorkloadPlannerWorkCategoryItemRenderer = (props) => {
  const {
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    startDateRef,
    endDateRef,
    userTheme
  } = props;

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle: {
      cursor: 'w-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      top: '21px',
      left: '0px',
      height: '33px',
      width: '33px',
      paddingLeft: '5px'
    },
    rightStyle: {
      cursor: 'e-resize',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      top: '21px',
      right: '0px',
      height: '33px',
      width: '33px',
      paddingRight: '5px'
    }
  });

  return (
    <div
      {...getItemProps({
        ...item.itemProps,
        className: 'mosaic-milestone-bar',
        style: itemContext.dragging || item.resizing ? draggingStyle : noStyle
      })}
    >
      <div {...leftResizeProps}>
        <>
          <div className="drag-handle-bar left" />
          <div className="drag-handle-bar left" />
        </>
      </div>
      {(itemContext.dragging || itemContext.resizing) && (
        <StyledWorkCategoryBar
          styleId={item.project_id.split('--')[1]}
          dragging
          originType="project"
          color={'#808080'}
          theme={userTheme.theme}
        >
          <div
            ref={startDateRef}
            style={{ position: 'absolute', left: 0, bottom: 0 }}
          />
          <div
            ref={endDateRef}
            style={{ position: 'absolute', right: 0, bottom: 0 }}
          />
          {item.name}
        </StyledWorkCategoryBar>
      )}
      {!itemContext.dragging && !itemContext.resizing && (
        <StyledWorkCategoryBarContainer>
          <StyledWorkCategoryBar
            color={'#808080'}
            originType="project"
            styleId={item.project_id.split('--')[1]}
            theme={userTheme.theme}
            data-testid={`work-category-bar-${item.id}`}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                left: '0px',
                overflow: 'hidden'
              }}
            >
              <StyledBudgetPhaseMilestoneIcon
                styleId={item.project_id.split('--')[1]}
                originType="project"
                theme={userTheme.theme}
              />

              <StyledPhase>
                {item.phase?.name}
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <SmallWorkCategoryIconContainer>
                    <SmallWorkCategoryIcon height={7} width={6} />
                  </SmallWorkCategoryIconContainer>
                  <StyledSchedulePhaseNameContainer
                    data-testid={`work-category-bar-title-${item.id}`}
                  >
                    {item.title}
                  </StyledSchedulePhaseNameContainer>
                  <div style={{ margin: '0px 3px' }}>|</div>
                  <StyledDateContainer
                    data-testid={`work-category-bar-date-${item.id}`}
                  >
                    {moment(item.start_date_label).format('MMM D ')} -
                    {moment(item.end_date_label).format(' MMM D')}
                  </StyledDateContainer>
                </div>
              </StyledPhase>
            </div>
          </StyledWorkCategoryBar>
        </StyledWorkCategoryBarContainer>
      )}
      <div {...rightResizeProps}>
        <>
          <div className="drag-handle-bar right" />
          <div className="drag-handle-bar right" />
        </>
      </div>
    </div>
  );
};

const boardIdGetter = (state, ownProps) => {
  return ownProps.item && ownProps.item.project?.board_id;
};
const makeMapStateToProps = () => {
  const getBoardById = makeGetBoardById({ boardIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    board: getBoardById(state, ownProps),
    userTheme: getUserTheme(state),
    userIsWorkloadManager: getUserIsWorkloadManager(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  WorkloadPlannerWorkCategoryItemRenderer
);
