import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import MilestoneHeaderTitle from './MilestoneHeaderTitle';
import MilestoneHeaderStartDate from './MilestoneHeaderStartDate';
import MilestoneHeaderWorkdays from './MilestoneHeaderWorkdays';
import MilestoneHeaderMembers from './MilestoneHeaderMembers';
import MilestoneHeaderStatus from './MilestoneHeaderStatus';
import MilestoneCreateRow from './MilestoneCreateRow';
import MilestoneShowArchivedRow from './MilestoneShowArchivedRow';
import PhaseUserActivityRow from './PhaseUserActivityRow';
import { MilestoneTableStyler } from './styles';
import { SORT_BY, SORT_ORDER } from 'appConstants/filters';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

import { getActionableUserActivityHash, getIsLoadingPhases } from 'selectors';
import { MilestoneModalContext } from 'contexts/MilestoneModalContext';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { PHASE_BUDGET_STATUS_SORT_VALUES } from 'appConstants/budgetStatuses';
import { getProjectBudgetSettingsHash } from 'BudgetModule/selectors';

const emptyArray = [];
const EmptyDiv = () => <div />;

const createRow = {
  createRow: true,
  itemHeight: 70,
  rowType: 'createRow',
  draggableId: 1
};

const columnWidths = {
  // Accounting for the drag-and-drop handle on the left side of the table.
  leftPadding: 35,
  grip: 22,
  title: 225,
  // members: 145,
  status: 140,
  startDate: 132,
  workdays: 94,
  delete: 0,
  menu: 19,
  // Accounting for the three dots menu on the right side of the table.
  rightPadding: 43.4
};

const totalWidth = Object.values(columnWidths).reduce(
  (sum, cur) => (sum += cur),
  0
);

const milestoneColumnNames = [
  'grip',
  'title',
  // 'members',
  'status',
  'startDate',
  'workdays',
  // 'delete',
  'menu'
];

const milestoneColumns = milestoneColumnNames.map((milestoneColumnName) => {
  return {
    headerType: milestoneColumnName,
    accessor: (row) => row.id,
    align: 'center',
    id: milestoneColumnName
  };
});

const handleNameSort = (oldRows, sortMultiplier) => {
  const sorted = [...oldRows].sort((a, b) => {
    let sortVal = 0;
    if (a.phase.name.toLowerCase() > b.phase.name.toLowerCase()) {
      sortVal = 1;
    } else {
      sortVal = -1;
    }
    return sortVal * sortMultiplier;
  });
  return sorted;
};

const handleDateSort = (oldRows, sortMultiplier) => {
  const sorted = [...oldRows].sort((a, b) => {
    if (!a.phase.start_date && !b.phase.start_date) return 0;
    else if (!a.phase.start_date) {
      return sortMultiplier;
    } else if (!b.phase.start_date) {
      return -sortMultiplier;
    }
    const date1 = new Date(a.phase.start_date);
    const date2 = new Date(b.phase.start_date);
    return sortMultiplier * (date1.getTime() - date2.getTime());
  });
  return sorted;
};

const handleWorkDaysSort = (oldRows, sortMultiplier) => {
  const sorted = [...oldRows].sort((a, b) => {
    const sortVal = b.phase.total_work_days - a.phase.total_work_days;
    return sortVal * sortMultiplier;
  });
  return sorted;
};

const handleStatusSort = (oldRows, sortMultiplier) => {
  const sorted = [...oldRows].sort((a, b) => {
    const sortVal =
      PHASE_BUDGET_STATUS_SORT_VALUES[b.phase.budget_status] -
      PHASE_BUDGET_STATUS_SORT_VALUES[a.phase.budget_status];
    return sortVal * sortMultiplier;
  });

  return sorted;
};

const handleSort = (oldRows, sortType, sortDirection) => {
  if (!sortType || sortType === SORT_BY.sort_rank) {
    return oldRows;
  }
  const sortMultiplier = sortDirection === SORT_ORDER.asc ? 1 : -1;
  let sortedRows = [];
  if (sortType === SORT_BY.name) {
    sortedRows = handleNameSort(oldRows, sortMultiplier);
  } else if (sortType === SORT_BY.start_date) {
    sortedRows = handleDateSort(oldRows, sortMultiplier);
  } else if (sortType === SORT_BY.total_work_days) {
    sortedRows = handleWorkDaysSort(oldRows, sortMultiplier);
  } else if (sortType === SORT_BY.budget_status) {
    sortedRows = handleStatusSort(oldRows, sortMultiplier);
  }
  return sortedRows;
};

const MilestoneTable = ({
  Cell = () => <div />,
  orderedPhases,
  handleDragEnd,
  isShowingArchived,
  setIsShowingArchived,
  numArchivedPhases,
  maxHeight,
  handleOpenHardDeletePhaseModal,
  project
}) => {
  const [isOpen, setIsOpen] = useState({});
  const { addingMilestone, addingPhase } = useContext(MilestoneModalContext);
  const isLoading = useSelector(getIsLoadingPhases);
  const projectBudgetSettingsHash = useSelector(getProjectBudgetSettingsHash);
  const projectBudgetSettings = useMemo(
    () => projectBudgetSettingsHash[project?.id],
    [project, projectBudgetSettingsHash]
  );

  const [sortType, setSortType] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const userActivitiesHash = useSelector(getActionableUserActivityHash);

  useEffect(() => {
    const phaseDefaultSort = projectBudgetSettings?.phase_default_sort;
    if (phaseDefaultSort && !sortType && !sortDirection) {
      if (phaseDefaultSort.length) {
        const newSortAttribute = phaseDefaultSort[0].attribute ?? null;
        const newSortDirection = phaseDefaultSort[0].direction ?? null;
        if (newSortAttribute !== SORT_BY.sort_rank) {
          setSortType(newSortAttribute);
          setSortDirection(newSortDirection);
        }
      }
    }
  }, [projectBudgetSettings]);

  const handleSetSort = useCallback(
    (newSortType) => {
      if (sortType !== newSortType) {
        setSortType(newSortType);
        setSortDirection(SORT_ORDER.asc);
      } else {
        setSortType(sortDirection === SORT_ORDER.desc ? null : newSortType);
        setSortDirection(
          sortDirection === SORT_ORDER.desc ? null : SORT_ORDER.desc
        );
      }
    },
    [sortDirection, sortType]
  );
  const hasArchivedPhases = numArchivedPhases > 0;

  const handleSetIsOpen = useCallback(
    ({ id, value }) => {
      setIsOpen({ ...isOpen, [id]: value });
      rebuildTooltip();
    },
    [isOpen]
  );

  const onBeforeCapture = useCallback(() => {
    setIsOpen({});
  }, []);

  const columnHeaderComponents = useMemo(() => {
    return {
      grip: EmptyDiv,
      title: (
        <MilestoneHeaderTitle
          isActive={sortType === SORT_BY.name}
          sortDirection={sortDirection}
          handleSetSort={handleSetSort}
        />
      ),
      members: <MilestoneHeaderMembers />,
      status: (
        <MilestoneHeaderStatus
          isActive={sortType === SORT_BY.budget_status}
          sortDirection={sortDirection}
          handleSetSort={handleSetSort}
        />
      ),
      startDate: (
        <MilestoneHeaderStartDate
          isActive={sortType === SORT_BY.start_date}
          sortDirection={sortDirection}
          handleSetSort={handleSetSort}
        />
      ),
      dash: EmptyDiv,
      endDate: EmptyDiv,
      workdays:
        addingMilestone || addingPhase ? (
          EmptyDiv
        ) : (
          <MilestoneHeaderWorkdays
            isActive={sortType === SORT_BY.total_work_days}
            sortDirection={sortDirection}
            handleSetSort={handleSetSort}
          />
        ),
      // delete: EmptyDiv,
      menu: EmptyDiv
    };
  }, [sortType, sortDirection, addingMilestone, addingPhase, handleSetSort]);

  const columns = useMemo(
    () =>
      milestoneColumns.map((header) => ({
        ...header,
        Header: columnHeaderComponents[header.headerType],
        phaseRow: Cell,
        phaseUserActivityRow: PhaseUserActivityRow,
        createRow: MilestoneCreateRow,
        archivedRow: MilestoneShowArchivedRow,
        width: columnWidths[header.headerType],
        minWidth: 0
      })),
    [Cell, columnHeaderComponents]
  );

  const listRef = useRef(null);

  const rows = useMemo(() => {
    const unsortedRows = orderedPhases.map((phase) => ({
      phase,
      rowType: 'phaseRow',
      draggableId: `${phase?.id}`,
      isDragDisabled: !!phase?.archived || !!phase?.is_main,
      userActivities: userActivitiesHash[phase?.id] || [],
      sortType,
      orderedPhases,
      handleSetIsOpen,
      handleOpenHardDeletePhaseModal,
      isOpen: isOpen[phase?.id]
    }));
    const sortedPhases = handleSort(unsortedRows, sortType, sortDirection);
    const displayRows = sortedPhases.reduce((acc, phase) => {
      acc.push(phase);
      if (phase.isOpen && phase.userActivities.length) {
        phase.userActivities.forEach((userActivity) => {
          // Replace with right information here.
          if (
            userActivity?.display_data.start_date &&
            userActivity?.display_data.end_date
          ) {
            acc.push({
              userActivity,
              phase,
              rowType: 'phaseUserActivityRow',
              itemHeight: 70,
              draggableId: `${userActivity?.user_activity_id}`,
              isDragDisabled: true
            });
          }
        });
      }
      return acc;
    }, []);
    const phaseRows = [
      ...(addingMilestone || addingPhase ? [createRow] : emptyArray),
      ...displayRows
    ];
    if (!isShowingArchived && hasArchivedPhases) {
      phaseRows.push({
        rowType: 'archivedRow',
        isDragDisabled: true,
        draggableId: 'archivedRow',
        setIsShowingArchived,
        numArchivedPhases
      });
    }
    return phaseRows;
  }, [
    orderedPhases,
    sortType,
    sortDirection,
    addingMilestone,
    addingPhase,
    isShowingArchived,
    hasArchivedPhases,
    userActivitiesHash,
    handleSetIsOpen,
    handleOpenHardDeletePhaseModal,
    isOpen,
    setIsShowingArchived,
    numArchivedPhases
  ]);

  if (isLoading) {
    return (
      <div style={{ width: '80%', margin: 'auto', marginTop: -55 }}>
        <SkeletonLoader
          numLoaders={2}
          style={{}}
          loaderStyle={{ height: 100, rx: 0 }}
          heightBuffer={0}
        />
      </div>
    );
  }

  return (
    <MilestoneTableStyler>
      <Table
        columns={columns}
        data={rows}
        virtual
        maxHeight={maxHeight || 525}
        itemHeight={100}
        getItemSize={() => 70}
        isVariableSizeTable
        listRef={listRef}
        isDragDisabled={!!sortType}
        columnMaxWidth={650}
        dragContainerClassName={'milestone-row-clone'}
        onDragEnd={handleDragEnd}
        onBeforeCapture={onBeforeCapture}
        totalColumnsWidthOverride={totalWidth}
      />
    </MilestoneTableStyler>
  );
};

export default MilestoneTable;
