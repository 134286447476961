import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class ForgotPasswordForm extends React.Component {
  state = {
    email: null,
    headerText: 'Reset Password',
    messageSent: "We'll send you an email with a link to reset your password.",
    reminderMessage: null,
    validationError: null
  };

  handleChange = (event) => {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  };

  formSubmit = (event) => {
    event.preventDefault();
    this.setState({ validationError: null });
    if (!this.state.email) {
      this.setState({ validationError: 'Cannot enter a blank email address.' });
      return;
    }
    const { handleSubmit } = this.props;
    handleSubmit({ email: this.state.email });

    setTimeout(() => {
      if (!this.props.statusText) {
        this.setState({
          headerText: 'Email Sent',
          messageSent: 'Email with reset link has been sent.',
          reminderMessage: 'Please check your spam folder.'
        });
      }
    }, 1000);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { statusText, emailSent } = this.props;
    if (!statusText && nextProps.statusText) {
      this.setState({
        validationError: 'Not a valid email address'
      });
    }

    if (!emailSent && nextProps.emailSent) {
      this.setState({
        headerText: 'Email Sent',
        messageSent: 'Email with reset link has been sent.',
        reminderMessage: 'Please check your spam folder.'
      });
    }
  }

  render() {
    const { errorMessage } = this.props;
    const isMobile = this.props.width < 500;
    return (
      <div className="input-forgot">
        <h2 className="forgot-header">{this.state.headerText}</h2>
        <p className="forgot-tagline"> {this.state.messageSent}</p>
        <p className="password-reset-reminder">{this.state.reminderMessage}</p>
        <div className="auth-modal-error">
          <span className="auth-modal-error-message">
            {this.state.validationError || errorMessage}
          </span>
        </div>
        {!errorMessage ? (
          <div className="form-group">
            <form onSubmit={this.formSubmit}>
              <div className={cn({ 'has-danger': errorMessage })}>
                <input
                  type="email"
                  defaultValue={this.state.email}
                  name="email"
                  onChange={this.handleChange}
                  placeholder="Enter your email address"
                  required
                  className={cn('user-input', {
                    'form-control-danger': errorMessage
                  })}
                />
              </div>
              {!this.state.reminderMessage ? (
                <div className="forgot-submit set-password-modal-actions">
                  <button type="submit">
                    {isMobile ? 'Submit' : 'Request Reset Link'}
                  </button>
                  <br />
                  <br />
                  <a id="login-link" onClick={this.props.loginClick}>
                    Log in
                  </a>
                </div>
              ) : (
                <div className="forgot-resend set-password-modal-actions">
                  <a id="forgot-password-button" onClick={this.formSubmit}>
                    Resend
                  </a>
                  <br />
                  <br />
                  <button
                    id="goto-login-button"
                    onClick={this.props.loginClick}
                  >
                    LOG IN
                  </button>
                </div>
              )}
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  loginClick: PropTypes.func.isRequired
};

export default ForgotPasswordForm;
