import { useMemo } from 'react';
import styled from 'styled-components';
import { ColorScalePreview } from '../ColorScalePreview';
import { buildColorScaleId } from '../utils';
import { ColorScaleColors } from '../types';
import cn from 'classnames';
import { Radio } from 'components/Radio';
import theme from 'theme';
import { SCROLLBAR_WIDTH } from 'appConstants/scrollbar';

export interface ColorScaleColorsInputProps {
  /**
   * The class of the container for this component.
   */
  className?: string;

  /**
   * The default color scale colors.
   */
  defaultColors?: ColorScaleColors;

  /**
   * The label to choose the default color scale colors.
   */
  defaultColorsLabel?: string;

  /**
   * The maximum number of items visible before scrolling begins.
   */
  maxItemsVisible?: number;

  /**
   * Event handler when a color scale is clicked.
   */
  onSelect: (colors: Nullable<ColorScaleColors>) => void;

  /**
   * The color scales to display.
   */
  options: Array<ColorScaleColors>;

  /**
   * The currently selected color scale. If this does not match one of the
   * color scales in `colorScales`, no color scale will be selected.
   */
  value?: Nullable<ColorScaleColors>;
}

/**
 * A color scale with an identifier, to ease comparisons.
 */
interface ColorScaleWithId {
  /**
   * The color scale associated with the name.
   */
  colors: ColorScaleColors;

  /**
   * The constructed id of the color scale.
   */
  id: string;
}

const ITEM_HEIGHT = 18;
const ITEM_PADDING = 9;
const ITEM_SPACING = 8;

/**
 * Input to select the colors of a color scale.
 */
export const ColorScaleColorsInput = ({
  className,
  defaultColors,
  defaultColorsLabel,
  maxItemsVisible,
  onSelect,
  options,
  value
}: ColorScaleColorsInputProps) => {
  const currentScaleId = useMemo(
    () => (value ? buildColorScaleId(value) : value),
    [value]
  );

  const colorScalesWithIds = useMemo(
    () =>
      options.map((colors) => ({
        colors,
        id: buildColorScaleId(colors)
      })),
    [options]
  );

  const handleClick =
    (colorScaleWithId: Nullable<ColorScaleWithId>) => (): void => {
      onSelect(colorScaleWithId ? colorScaleWithId.colors : null);
    };

  return (
    <Container
      className={cn(className, { scrollbar: maxItemsVisible })}
      style={{
        maxHeight: maxItemsVisible
          ? maxItemsVisible * (ITEM_HEIGHT + 2 * ITEM_PADDING)
          : undefined
      }}
    >
      {defaultColorsLabel && (
        <Item onClick={handleClick(null)}>
          <Radio checked={currentScaleId === null} />
          <Default>
            {defaultColorsLabel}
            {defaultColors && (
              <ColorScalePreview
                colorScale={defaultColors}
                size={ITEM_HEIGHT}
                spacing={ITEM_SPACING}
              />
            )}
          </Default>
        </Item>
      )}
      {colorScalesWithIds.map((colorScaleWithId) => (
        <Item key={colorScaleWithId.id} onClick={handleClick(colorScaleWithId)}>
          <Radio checked={currentScaleId === colorScaleWithId.id} />
          <ColorScalePreview
            colorScale={colorScaleWithId.colors}
            size={ITEM_HEIGHT}
            spacing={ITEM_SPACING}
          />
        </Item>
      ))}
    </Container>
  );
};

const Item = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: ${ITEM_PADDING}px;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const Default = styled.div`
  align-items: center;
  color: ${theme.colors.colorRoyalBlue};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-height: ${ITEM_HEIGHT};
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${4 * ITEM_HEIGHT + 3 * ITEM_SPACING}px;
`;

const Container = styled.ul`
  display: inline-block;
  margin: 0;
  overflow: hidden auto;
  padding: 0;
  width: fit-content;

  &.scrollbar {
    scrollbar-gutter: stable;

    ${Item} {
      // Adjust the padding to account for the scrollbar.
      padding: ${ITEM_PADDING}px ${ITEM_PADDING + SCROLLBAR_WIDTH}px;

      // Adjust the width to keep the list centered.
      width: calc(100% + ${SCROLLBAR_WIDTH}px);
    }
  }
`;
