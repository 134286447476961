import styled from 'styled-components';
import theme from 'theme';
import { Styles } from 'components/Table/styles';
import { StyledCapacityReportTable } from 'ReportsModule/components/Utilization/UtilizationTableV2/styles';

export const NoDataText = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 14px;
  margin-top: 45px;
  margin-left: 31px;
  font-style: italic;
`;

export const StyledUtilizationTableContainer = styled.div`
  min-width: 903px;
  flex: 1 1 0%;
  min-height: 0px;
  ${Styles}, ${StyledCapacityReportTable}, .table, .table-body {
    height: 100%;
  }
  .variable-size-list {
    height: 100% !important;
  }
  .table {
    padding-bottom: 27px;
  }
  .project-breakdown-tooltip {
    padding: 5px 8px !important;
    opacity: 1;
    border-radius: 5px;
  }
`;
