import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getNotesModule } from 'NoteModule/package/noteModule';

const ProjectNotesContainer = lazy(() => import('./ProjectNotesContainer'));
const LoadProjectNotesContainer = () => (
  <DynamicModuleLoader modules={[getNotesModule()]}>
    <Suspense fallback={<div />}>
      <ProjectNotesContainer />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadProjectNotesContainer;
