import settings from './settings';
import { disciplinesReducer as disciplines } from './disciplines';
import { regionsReducer as regions } from './regions';
import { officesReducer as offices } from './offices';
import { locationsReducer as locations } from './locations';
import { ptoReducer as pto } from './ptos';
import { teamPermissionsReducer } from 'PermissionsModule/reducers/teamPermissions';
import { ReducerName } from 'reduxInfra/shared';

export const reducerMap = {
  settings,
  [ReducerName.TeamPermissions]: teamPermissionsReducer,
  disciplines,
  regions,
  offices,
  locations,
  pto
};
