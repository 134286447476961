import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

/**
 * Converts a React element that produces an SVG element into a data URI.
 */
export const svgToDataURI = (svgElement: ReactElement): string => {
  const svgString = renderToStaticMarkup(svgElement);
  return svgString
    ? `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
    : '';
};
