import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getSelectedTeamId,
  getSkillsArray,
  getAreSkillsLoaded,
  getTeamSlug
} from 'selectors';
import { fetchSkills, navigateToMembersSkillsSettings } from 'actionCreators';
import theme from 'theme';
import { TextButton } from 'components/styles';
import {
  ItemRow,
  StyledItemName,
  Level,
  LevelsContainer,
  CloseIconContainer,
  AddRow,
  SectionHeader
} from './styles';

import ReactTooltip from 'react-tooltip';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import SkillRow from './SkillRow';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import LevelIndicator from 'components/LevelIndicator';
import {
  SKILL_LEVELS,
  NUM_SKILLS,
  SKILL_LEVEL_TO_COLOR
} from 'appConstants/skills';
import Popover from 'components/Popover';
import DeleteXIcon from 'icons/DeleteXIcon';

const Footer = (
  <LockWithTooltip
    text="Manage Skills"
    tooltipContent="Only Admins can Add or Edit Skills"
  />
);

const copy = {
  headerInitial: 'Skills',
  headerEdit: '',
  headerAdd: '',
  sticky: '',
  footerInitial: Footer,
  footerEdit: Footer,
  addConfirm: '',
  editConfirm: '',
  searchPlaceholder: 'Type Skill or select below'
};

const SECTIONS = {
  skills: 'skills',
  levels: 'levels'
};

/* ------------------------------------ - ----------------------------------- */
const selectedTeamSkillAndLevelInitialState = {
  team_skill_id: null,
  skillName: '',
  level: null
};

const makeSkills = (addedSkills) => {
  const { skillsToUpdate, skillsToCreate } = Object.values(addedSkills).reduce(
    (acc, skill) => {
      if (skill.position_skill_id) {
        acc.skillsToUpdate.push(skill);
      } else {
        acc.skillsToCreate.push(skill);
      }
      return acc;
    },
    {
      skillsToUpdate: [],
      skillsToCreate: []
    }
  );
  return { skillsToCreate, skillsToUpdate };
};

const RequirementsDropdown = ({
  selectedSkills,
  teamSkills,
  skillsHash,
  onClose,
  targetRef,
  teamId,
  fetchSkills,
  levelSelectionTargetRef,
  skillsLoaded
}) => {
  const dispatch = useDispatch();
  const [addedSkills, setAddedSkills] = useState({ ...selectedSkills });
  const teamSlug = useSelector(getTeamSlug);
  /** Local state to allow user interaction with skill dropdown */
  const [selectedTeamSkillAndLevel, setSelectedTeamSkillAndLevel] = useState(
    selectedTeamSkillAndLevelInitialState
  );
  const [isLevelSelectionOpen, setIsLevelSelectionOpen] = useState(false);

  const listItems = useMemo(() => {
    const skillList = [];

    teamSkills.forEach((teamSkill) => {
      const skillRow = {
        ...teamSkill,
        section: SECTIONS.skills,
        itemHeight: 40
      };
      skillList.push(skillRow);
    });
    return skillList;
  }, [teamSkills]);

  useEffect(() => {
    if (teamId) {
      fetchSkills({ teamId });
    }
  }, [fetchSkills, teamId]);

  /* ------------------------------ interactions ------------------------------ */

  const addTeamSkill = (teamSkillAndLevelToAddOrUpdate) => {
    const { team_skill_id } = teamSkillAndLevelToAddOrUpdate;
    const addedSkillsNextState = { ...addedSkills };
    addedSkillsNextState[team_skill_id] = teamSkillAndLevelToAddOrUpdate;
    const { skillsToUpdate, skillsToCreate } = makeSkills(addedSkillsNextState);
    onClose(skillsToUpdate, skillsToCreate, addedSkillsNextState);
  };

  const onSearchChange = () => {
    if (isLevelSelectionOpen) {
      resetSelectedTeamSkillAndLevel();
    }
  };

  const itemFilter = (item, searchWords) => {
    if (item.section === SECTIONS.levels) {
      return (
        item.team_skill_id === selectedTeamSkillAndLevel.team_skill_id &&
        filterItemWithWhiteSpace({
          searchWords,
          item: selectedTeamSkillAndLevelInitialState,
          filterKeysArray: ['skillName']
        })
      );
    }

    return (
      item.isSectionHeader ||
      filterItemWithWhiteSpace({
        searchWords,
        item,
        filterKeysArray: ['name']
      })
    );
  };

  const handleSelect = (e, { item }) => {
    e.preventDefault();
    if (!item?.id && !item?.team_skill_id) {
      return;
    }
    if (item.section === SECTIONS.skills) {
      handleSelectTeamSkill(item);
    }
  };

  const resetSelectedTeamSkillAndLevel = () => {
    setSelectedTeamSkillAndLevel(selectedTeamSkillAndLevelInitialState);
    closeLevelsSelection();
  };

  const handleClose = () => {
    if (onClose) {
      const { skillsToUpdate, skillsToCreate } = makeSkills(addedSkills);
      onClose(skillsToUpdate, skillsToCreate, addedSkills);
    }
  };

  /* ------------------------------ Skill ------------------------------ */

  const handleSelectTeamSkill = (teamSkill) => {
    if (teamSkill.id === selectedTeamSkillAndLevel.team_skill_id) {
      resetSelectedTeamSkillAndLevel();
    } else {
      setSelectedTeamSkillAndLevel({
        ...addedSkills?.[teamSkill.id],
        team_skill_id: teamSkill.id,
        skillName: teamSkill.name,
        level: addedSkills?.[teamSkill.id]?.level || null
      });
      openLevelsSelection();
    }
  };

  /* ------------------------------ Level ------------------------------ */

  const closeLevelsSelection = () => {
    setIsLevelSelectionOpen(false);
  };

  const openLevelsSelection = () => {
    setIsLevelSelectionOpen(true);
  };

  const isCurrentLevelSelected = (currentLevel) => {
    const { team_skill_id } = selectedTeamSkillAndLevel;
    if (addedSkills[team_skill_id] && addedSkills[team_skill_id].level) {
      return addedSkills[team_skill_id].level === currentLevel;
    }
    return selectedTeamSkillAndLevel.level === currentLevel;
  };

  const handleSelectLevel = (level) => {
    if (level !== selectedTeamSkillAndLevel.level) {
      const selectedTeamSkillAndLevelNextState = {
        ...selectedTeamSkillAndLevel,
        level
      };
      addTeamSkill(selectedTeamSkillAndLevelNextState);
    }
  };

  const onFooterClick = () => {
    // TODO
    ReactTooltip.hide();
    handleClose();
    dispatch(
      navigateToMembersSkillsSettings({
        teamSlug,
        openInNewWindow: true
      })
    );
  };

  /* --------------------------------- render --------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;

    if (item.isSectionHeader) {
      return <SectionHeader>{item.label}</SectionHeader>;
    }
    if (item.isAddRow) {
      return <AddRow>Add Custom {item.label}</AddRow>;
    }

    return (
      <SkillRow
        item={item}
        addedSkills={addedSkills}
        selectedTeamSkillAndLevel={selectedTeamSkillAndLevel}
      />
    );
  };

  const renderHeaderButton = () => {
    return (
      <CloseIconContainer onClick={handleClose}>
        <DeleteXIcon width={17} height={20} />
      </CloseIconContainer>
    );
  };

  return (
    <>
      <MultiStepFlyout
        copy={copy}
        target={targetRef}
        items={listItems}
        idKey="id"
        renderHeaderButton={renderHeaderButton}
        renderItem={renderItem}
        handleSelect={handleSelect}
        isWhite
        itemFilter={itemFilter}
        handleClose={handleClose}
        onFooterClick={onFooterClick}
        popoverClassName="add-skills-dropdown"
        listWidth={237}
        listHeight={222}
        onSearchChange={onSearchChange}
        searchEnabled
        isItemUnSelectable={(item) => item.isSectionHeader}
        canMultiSelect
        currentlySelectedId={selectedTeamSkillAndLevel.team_skill_id}
        isLoading={!skillsLoaded}
      />
      <Popover
        target={levelSelectionTargetRef}
        isOpen={isLevelSelectionOpen}
        placement="bottom-start"
        className="requirements-dropdown-popover"
        closePopover={closeLevelsSelection}
      >
        <LevelsContainer>
          {Object.entries(SKILL_LEVELS).map(([key, value]) => {
            const integerKey = parseInt(key);
            return (
              <Level
                onClick={() => handleSelectLevel(integerKey)}
                key={value}
                isSelected={isCurrentLevelSelected(integerKey)} //
              >
                <span className={`level-text ${value.toLowerCase()}`}>
                  {value}
                </span>
                <span className={`level-shapes ${value.toLowerCase()}`}>
                  <LevelIndicator
                    level={key}
                    size="9"
                    numLevels={NUM_SKILLS}
                    levelToColor={SKILL_LEVEL_TO_COLOR}
                  />
                </span>
              </Level>
            );
          })}
        </LevelsContainer>
      </Popover>
    </>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamId: getSelectedTeamId(state),
    teamSkills: getSkillsArray(state),
    skillsLoaded: getAreSkillsLoaded(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchSkills
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(RequirementsDropdown);
