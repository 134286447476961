import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import keyBy from 'lodash/keyBy';
import {
  AddPlusContainer,
  StyledAddPlusIcon,
  PlusMemberContainer
} from './styles';
import { MemberInitials } from 'views';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import { createPhaseMembers } from 'actionCreators';
import { getTeamMembershipsByAccountId } from 'selectors';

const MilestoneTableMembersCell = (props) => {
  const dispatch = useDispatch();
  const teamMembershipsByAccountId = useSelector(getTeamMembershipsByAccountId);
  const {
    me,
    selectedProject,
    row: { original }
  } = props;
  const { phase } = original;

  const handleDone = (members) => {
    const accountIds = members.map((member) => member.account.id);
    dispatch(
      createPhaseMembers({
        projectId: phase.project_id,
        phaseId: phase.id,
        accountIds
      })
    );
  };

  const memberFilter = (members) => {
    const phaseMembershipsByAccountId = keyBy(
      phase?.phase_memberships?.filter((member) => !member.discarded_at),
      (item) => item.account_id
    );
    return members.filter(
      (member) => !phaseMembershipsByAccountId[member?.account?.id]
    );
  };

  const memberList = phase.phase_memberships
    .filter((member) => !member.discarded_at)
    .map((phaseMember) => teamMembershipsByAccountId[phaseMember.account_id])
    .filter((teamMember) => !!teamMember);

  return (
    <div
      className={cn('milestone-members', { 'is-disabled': phase?.archived })}
    >
      {memberList.length > 3 ? (
        <PlusMemberContainer className="member-initials">
          +{memberList.length - 3}
        </PlusMemberContainer>
      ) : (
        ''
      )}
      {memberList.slice(0, 3).map((teamMember, idx) => (
        <MemberInitials
          key={teamMember.account.id}
          member={teamMember}
          idx={teamMember.account.id}
          size="small"
          classes={cn(
            'regular-member selected',
            {
              selected: false
            },
            { 'logged-member': teamMember.account.id === me?.id }
          )}
          onClick={() => {}}
        />
      ))}
      <BulkMemberSelector
        projectId={selectedProject?.id}
        phaseId={phase.id}
        memberFilter={memberFilter}
        renderSelect={({ onClick }) =>
          phase?.archived ? (
            <AddPlusContainer style={{ visibility: 'hidden' }} />
          ) : (
            <AddPlusContainer onClick={onClick}>
              <StyledAddPlusIcon />
            </AddPlusContainer>
          )
        }
        handleDone={handleDone}
        membershipLevel={'phase'}
        footerInitialCopy="Add All Project Members"
        bulkActionAffectedMemberIds={
          selectedProject?.selectedProject_membership?.map(
            (member) => member?.account?.id
          ) ?? []
        }
      />
    </div>
  );
};

export default MilestoneTableMembersCell;
