import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import HeaderTooltipIcon from 'icons/HeaderTooltipIcon';
import NewCloseIcon from 'icons/NewCloseIcon';
import {
  DropdownContainer,
  Header,
  Content,
  Row,
  MenuItemText,
  CheckBox,
  HelpText,
  CloseIconContainer,
  NotificationHelper,
  HeaderContainer
} from './styles';

const StyledHeaderText = styled.div`
  font-size: 17px;
  color: #4a4a4a;
  font-weight: 600;
`;
const MenuItem = ({ isSelected, isIndented, ...props }) => {
  const children = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { isSelected })
  );

  return (
    <Row isIndented={isIndented} {...props}>
      {children}
    </Row>
  );
};

class ProjectNotificationMenu extends Component {
  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      menuOptions,
      notificationSettings,
      onCommentModal,
      onProjectDetail,
      onProjectRow,
      isProjectMember,
      left,
      closeModal,
      top
    } = this.props;

    return (
      <DropdownContainer
        leftAlign={onCommentModal || onProjectDetail || onProjectRow}
        onClick={this.stopPropagation}
        left={left}
        top={top}
      >
        <ReactTooltip
          className="notification-tooltip"
          effect="solid"
          id="notification"
          place="right"
        />
        <ReactTooltip
          className="notification-tooltip-options"
          effect="solid"
          id="notification-options"
          place="right"
        />
        <Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <HeaderContainer>
              <StyledHeaderText> Notifications </StyledHeaderText>

              <NotificationHelper
                data-for="notification"
                data-tip="You will receive notifications of tasks <br> you created and are assigned by default. <br> Select additional personal notifications below."
                data-multiline={true}
              >
                <HeaderTooltipIcon />
              </NotificationHelper>
            </HeaderContainer>
            <CloseIconContainer onClick={closeModal}>
              <NewCloseIcon />
            </CloseIconContainer>
          </div>
        </Header>
        <Content showHelpText={!isProjectMember}>
          {isProjectMember ? (
            menuOptions.map((option) => {
              return (
                <MenuItem
                  key={option.key}
                  isSelected={notificationSettings[option.key]}
                  isIndented={option.isIndented}
                  onClick={option.handleSelect}
                >
                  <CheckBox />
                  <MenuItemText>{option.key}</MenuItemText>

                  <NotificationHelper
                    data-for="notification-options"
                    data-tip={option.tooltipText}
                    data-multiline={true}
                  >
                    <HeaderTooltipIcon />
                  </NotificationHelper>
                </MenuItem>
              );
            })
          ) : (
            <HelpText>
              You should be a member of this project to set notifications
            </HelpText>
          )}
        </Content>
      </DropdownContainer>
    );
  }
}

ProjectNotificationMenu.propTypes = {
  isProjectMember: PropTypes.bool,
  menuOptions: PropTypes.array,
  notificationSettings: PropTypes.object,
  onCommentModal: PropTypes.bool,
  onProjectDetail: PropTypes.bool,
  onProjectRow: PropTypes.bool
};

ProjectNotificationMenu.defaultProps = {
  isProjectMember: false,
  onCommentModal: false,
  onProjectDetail: false,
  onProjectRow: false
};

export default ProjectNotificationMenu;
