import React from 'react';
import SvgIcon from 'components/SvgIcon';

const LinkIcon = ({
  className,
  height = '10',
  width = '10',
  color = '#4f4f4f'
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fillRule="none"
    className={className}
  >
    <path
      d="M6.82223 4.41389C6.70371 4.14804 6.53831 3.8976 6.32 3.67997C6.10169 3.46234 5.85191 3.29626 5.58608 3.17773L5.25661 3.5072L5.0276 3.73555L4.78988 3.97327C4.74568 4.01746 4.70818 4.06501 4.67537 4.11523C4.99076 4.13599 5.28542 4.26322 5.51108 4.48822C5.74279 4.71993 5.86332 5.01859 5.88341 5.32193C5.89479 5.49068 5.87404 5.65944 5.8198 5.82149C5.75953 6.00163 5.6631 6.17172 5.52247 6.31635C5.51778 6.32037 5.51577 6.32573 5.51176 6.33042L5.24189 6.59961L3.60931 8.23219C3.36288 8.47862 3.03609 8.6139 2.68788 8.6139C2.34034 8.6139 2.01288 8.47796 1.76645 8.23219C1.52069 7.98643 1.38542 7.65897 1.38542 7.31143C1.38542 6.96323 1.52069 6.63577 1.76712 6.39001L2.67784 5.47862C2.6256 5.31724 2.58877 5.15185 2.56534 4.9851C2.54859 4.86054 2.53654 4.73533 2.53654 4.6101C2.53453 4.37973 2.56333 4.14938 2.6169 3.92371L0.958857 5.58175C0.00394859 6.53666 0.00394859 8.08547 0.958857 9.0405C1.43632 9.51796 2.06243 9.75702 2.68789 9.75702C3.31399 9.75702 3.94012 9.51796 4.4176 9.0405L6.32012 7.13798C6.46141 6.99669 6.57926 6.84133 6.67837 6.67794C6.83439 6.42146 6.94086 6.14423 6.99376 5.85762C7.02122 5.71029 7.03595 5.56096 7.03595 5.41163C7.03662 5.22949 7.01385 5.04734 6.97367 4.86787C6.93818 4.71319 6.88796 4.56117 6.82233 4.41386L6.82223 4.41389Z"
      fill={color}
    />
    <path
      d="M9.03998 0.959441C8.56251 0.481977 7.93641 0.24292 7.31095 0.24292C6.68484 0.24292 6.05872 0.481977 5.58124 0.959441L3.67941 2.86127C3.53811 3.00257 3.42026 3.15793 3.32116 3.32132C3.16513 3.57779 3.05867 3.85503 3.00508 4.14164C2.97762 4.28896 2.96289 4.43829 2.96289 4.58763C2.96289 4.76977 2.98499 4.95191 3.02517 5.13138C3.05999 5.28674 3.11021 5.43875 3.17651 5.58606C3.29503 5.85191 3.46043 6.10168 3.67874 6.31998C3.89704 6.53828 4.14683 6.70369 4.41266 6.82222L4.74146 6.49342L4.97047 6.26507L5.20752 6.02802C5.25172 5.98383 5.28922 5.93628 5.32203 5.88606C5.00664 5.8653 4.71199 5.73874 4.48632 5.51306C4.25395 5.28136 4.13341 4.98203 4.11332 4.67735C4.10261 4.50927 4.12337 4.34119 4.17761 4.17914C4.23721 3.999 4.3343 3.82891 4.47493 3.68428C4.47895 3.68026 4.48163 3.6749 4.48632 3.67022L6.38883 1.7677C6.63526 1.52127 6.96205 1.386 7.31026 1.386C7.65846 1.386 7.98526 1.52194 8.23102 1.7677C8.73927 2.27595 8.73927 3.10231 8.23169 3.60987L7.32031 4.52126C7.37254 4.68331 7.40937 4.84803 7.43281 5.01545C7.45022 5.14001 7.4616 5.26522 7.4616 5.39045C7.46361 5.62082 7.43481 5.85117 7.38124 6.07684L9.03995 4.41879C9.99486 3.46322 9.99486 1.91439 9.03995 0.959363L9.03998 0.959441Z"
      fill={color}
    />
  </SvgIcon>
);

export default LinkIcon;
