import AdminListItem from './AdminListItem';
import { StyledAdminList } from './styles';
import { getAdmins } from 'TeamsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { TeamMemberWrapper } from 'TeamsModule/types';

interface AdminListProps {
  ListItemComponent?: (props: { admin: TeamMemberWrapper }) => JSX.Element;
  inTooltip?: boolean;
}

const AdminList = ({
  ListItemComponent = AdminListItem,
  inTooltip
}: AdminListProps) => {
  const admins = useAppSelector(getAdmins);
  return (
    <StyledAdminList inTooltip={inTooltip}>
      {admins.map((admin) => (
        <ListItemComponent admin={admin} key={admin.account.id} />
      ))}
    </StyledAdminList>
  );
};

export default AdminList;
