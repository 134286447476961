import { RootState } from 'reduxInfra/shared';
import { reducerMap } from '../reducers';
import capacitySaga from '../sagas';
import { ISagaModule } from 'redux-dynamic-modules-saga';

export function getCapacitiesModule(): ISagaModule<Partial<RootState>> {
  return {
    id: 'capacities',
    reducerMap,
    sagas: [capacitySaga]
    // Actions to fire when this module is added/removed
    // initialActions: []
    // finalActions: []
  };
}
