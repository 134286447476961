import styled from 'styled-components';
import theme from 'theme';

export const TaskBatchActionMenuWrapper = styled.div`
  position: absolute;
  left: 100%;
  top: ${({ isVirtualList }) => (isVirtualList ? '67px' : '0px')};
  min-width: 162px;
  display: flex;
  flex-flow: column nowrap;
  z-index: 100;
  background: ${({ isOnTeamProject }) =>
    isOnTeamProject ? theme.colors.colorTranslucentGray4 : ''};
`;

export const TaskBatchActionsAnchor = styled.div`
  position: relative;
`;

export const TaskBatchActionOption = styled.div`
  position: relative;
  height: 20px;
  margin: 8px 0px;
  color: ${theme.colors.colorRoyalBlue};
  display: flex;
  align-items: center;

  cursor: pointer;
  opacity: 1;

  &.disabled {
    cursor: auto;
    opacity: 0.6;
  }
`;

export const TaskBatchActionOptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  margin-right: 9px;
`;

export const TaskBatchActionOptionText = styled.div`
  font-size: 13px;
  ${TaskBatchActionOption}:hover:not(.disabled) & {
    font-weight: bold;
  }
`;

export const LineBreak = styled.div`
  width: 80%;
  height: 1px;
  margin: 2px 0;
  background-color: ${({ theme }) => theme.colors.colorPaleGray5};
`;

export const CloseIconContainer = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  cursor: pointer;
  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const DeleteModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const DeleteModalTitle = styled.h3`
  margin: 0px;
  font-weight: 600;
`;
