import { fetchEntity, changeEntity } from './generics';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';

export function* fetchTeamMembersByBoard(action) {
  const { boardId } = action.payload;
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchTeamMembersByBoard,
    api.fetchTeamMembersByBoard,
    undefined,
    [token, boardId],
    action
  );
}

export function* fetchAllTeamMembersByBoard(action) {
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchAllTeamMembersByBoard,
    api.fetchAllTeamMembersByBoard,
    undefined,
    [token],
    action
  );
}

export function* fetchAllProjectMembers(action) {
  const token = yield select(getAuthToken);
  const {
    teamId,
    limit,
    offset,
    all,
    project_ids,
    searchText,
    flexible_filter,
    account_ids,
    budget_statuses,
    is_administrative,
    is_personal,
    is_archived
  } = action.payload;

  const params = {
    team_id: teamId,
    limit,
    offset,
    all,
    project_ids,
    account_ids,
    search_text: searchText,
    flexible_filter,
    budget_statuses,
    is_administrative,
    is_personal,
    is_archived
  };
  yield fetchEntity(
    entityActions.fetchAllProjectMembers,
    api.fetchAllProjectMembers,
    undefined,
    [token, params],
    action
  );
}

export function* updateTeamMembership(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTeamMembership,
    api.updateTeamMembership,
    [token, id, payload],
    action
  );
}

export function* updateTeamMembershipProfile(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTeamMembershipProfile,
    api.updateTeamMembership,
    [token, id, payload],
    action
  );
}

export function* updateTeamMemberEmailNotification(action) {
  const { accountId, isEmailNotificationOn } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTeamMemberEmailNotification,
    api.emailNotification,
    [token, accountId, isEmailNotificationOn],
    action,
    action.payload
  );
}

export function* fetchTeamMemberWorker(action) {
  const { teamMemberId, onSuccess } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    entityActions.fetchTeamMemberDetail,
    api.fetchTeamMemberProfile,
    undefined,
    [teamMemberId, token],
    action
  );

  if (!error) {
    onSuccess?.forEach(({ successAction, selector }) => {
      successAction(selector && selector(action.payload, response));
    });
  }
}
