import { DependencyList } from './DependencyList';
import { DepedencyListProps } from 'components/Dependency/types';
import { Task } from 'models/task';
import useFetchTaskDependableItems from './hooks/useFetchTaskDependableItems';
import { DEPENDABLE_LABELS, DEPENDABLE_TYPES } from './constants';

interface BaseTaskDependencyListProps {
  task: Task;
  dependencyListProps: Omit<
    DepedencyListProps,
    'dependableItems' | 'baseDependableTypeLabel' | 'targetDependableTypeLabel'
  >;
}

export const BaseTaskDependencyList = ({
  task,
  dependencyListProps
}: BaseTaskDependencyListProps) => {
  const { project_id: projectId, id: taskId } = task;
  const { dependableItems } = useFetchTaskDependableItems({
    projectId,
    taskId
  });

  return (
    <DependencyList
      dependableItems={dependableItems}
      baseDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.TASK]}
      targetDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.TASK]}
      {...dependencyListProps}
    />
  );
};
