import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { makeGetTaskGroup } from 'selectors';

import { calculateColorPref } from 'appUtils/styleUtils';
import theme from 'theme';

const StyledTooltip = styled(ReactTooltip)`
  position: absolute !important;
  top: calc(50% - 50px) !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: fit-content;
`;

const StyledNameContainer = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${calculateColorPref};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;

const TeamMemberCell = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
`;

const MemberGroupRenderer = ({ group, taskGroup }) => {
  return (
    <TeamMemberCell className="team-member-cell" showBorder>
      <StyledNameContainer>
        <StyledName originType={'taskGroup'} taskGroupId={taskGroup.id}>
          {taskGroup.name}
        </StyledName>
      </StyledNameContainer>
    </TeamMemberCell>
  );
};

const taskGroupIdGetter = (state, ownProps) => ownProps.group?.id;
const makeMapStateToProps = () => {
  const getTaskGroup = makeGetTaskGroup({ taskGroupIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    taskGroup: getTaskGroup(state, ownProps)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MemberGroupRenderer);
