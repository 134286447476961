import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchDisciplines } from 'SettingsModule/actionCreators/settings/discipline';
import {
  getIsDisciplinesLoaded,
  getIsLoadingDisciplines
} from 'SettingsModule/selectors/disciplines';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const emptyObj = {};

export const useLoadDisciplines = ({
  isOff,
  shouldAutoLoad = true
}: {
  isOff?: boolean;
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const isLoaded = useAppSelector(getIsDisciplinesLoaded);
  const isLoading = useAppSelector(getIsLoadingDisciplines);

  const loadDisciplines = useCallback(() => {
    if (!isLoaded && !isLoading && teamId) {
      dispatch(fetchDisciplines({ teamId }));
    }
  }, [dispatch, isLoaded, teamId, isLoading]);

  useEffect(() => {
    if (!isOff && shouldAutoLoad) {
      loadDisciplines();
    }
  }, [isOff, loadDisciplines, shouldAutoLoad]);

  return {
    isLoaded,
    isLoading,
    loadDisciplines
  };
};
