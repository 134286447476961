import { select, put } from 'redux-saga/effects';
import { fetchEntity } from './generics';
import { api } from '../service';
import * as actionCreators from 'actionCreators';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';
import { RequestPriorityGroup } from 'appConstants';
const { userPermissionsFetch } = entityActions;

export function* fetchUserPermissions(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    userPermissionsFetch,
    api.fetchUserPermissions,
    undefined,
    [token],
    action,
    RequestPriorityGroup.Startup
  );
}

export function* clearDenyPermission(action) {
  yield put(actionCreators.clearDenyPermission());
}
