import * as constants from 'SettingsModule/constants/location';
import { createAction } from '@reduxjs/toolkit';
import {
  FetchLocationsParams,
  FetchLocationsSuccessResponse,
  FetchEntityLocationsParams,
  FetchEntityLocationsSuccessResponse,
  CreateOrUpdateEntityLocationParams,
  DeleteEntityLocationParams,
  AssociateEntityToLocationParams,
  AssociateEntityToLocationSuccessResponse
} from 'SettingsModule/api/location/types';
import { createRequestTypes } from 'appUtils';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { ValueOf } from 'type-fest';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { ACTION_META_KEY } from 'appConstants/actions';

export const fetchLocationsByTeamMembership =
  createAction<FetchLocationsByTeamMembershipInitialParams>(
    constants.FETCH_LOCATIONS_BY_TEAM_MEMBERSHIP.TRIGGER
  );

const FETCH_LOCATIONS = createRequestTypes('FETCH_LOCATIONS');

export const fetchLocations = createAction<FetchLocationsParams>(
  FETCH_LOCATIONS.TRIGGER
);

export const fetchLocationsActionCreatorsMap = createActionCreatorsMap<
  FetchLocationsParams,
  FetchLocationsSuccessResponse
>(FETCH_LOCATIONS);

const FETCH_ENTITY_LOCATIONS = createRequestTypes('FETCH_ENTITY_LOCATIONS');

export const fetchEntityLocations = createAction<FetchEntityLocationsParams>(
  FETCH_ENTITY_LOCATIONS.TRIGGER
);

export const fetchEntityLocationsActionCreatorsMap = createActionCreatorsMap<
  FetchEntityLocationsParams,
  FetchEntityLocationsSuccessResponse
>(FETCH_ENTITY_LOCATIONS);

export const createOrUpdateEntityLocation =
  createAction<CreateOrUpdateEntityLocationInitialParams>(
    constants.CREATE_OR_UPDATE_ENTITY_LOCATION.TRIGGER
  );

const DELETE_ENTITY_LOCATION = createRequestTypes('DELETE_ENTITY_LOCATION');

export const deleteEntityLocation = createAction(
  DELETE_ENTITY_LOCATION.TRIGGER,
  ({ meta, ...payload }: DeleteEntityLocationInitialParams) => ({
    payload,
    meta
  })
);

export const deleteEntityLocationActionCreatorsMap = createActionCreatorsMap<
  DeleteEntityLocationInitialParams,
  undefined,
  ValueOf<Pick<ReturnType<typeof deleteEntityLocation>, typeof ACTION_META_KEY>>
>(DELETE_ENTITY_LOCATION);

const ASSOCIATE_ENTITY_TO_LOCATION = createRequestTypes(
  'ASSOCIATE_ENTITY_TO_LOCATION'
);

export const associateEntityToLocation =
  createAction<AssociateEntityToLocationParams>(
    ASSOCIATE_ENTITY_TO_LOCATION.TRIGGER
  );

export const associateEntityToLocationActionCreatorsMap =
  createActionCreatorsMap<
    AssociateEntityToLocationParams,
    AssociateEntityToLocationSuccessResponse
  >(ASSOCIATE_ENTITY_TO_LOCATION);

interface FetchLocationsByTeamMembershipInitialParams {
  teamId: number;
}

export type CreateOrUpdateEntityLocationInitialParams =
  CreateOrUpdateEntityLocationParams & {
    requestStatusId?: string;
  };

export type DeleteEntityLocationInitialParams = DeleteEntityLocationParams & {
  meta: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
    requestStatusId?: string;
  };
};
