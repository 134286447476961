import styled from 'styled-components';
import theme from 'theme';
const { colors } = theme;
const { colorMediumGray8, colorLightGray4 } = colors;

const MemberCircle = styled.div`
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  line-height: ${({ size }) => size};
  text-align: center;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${colors.colorPureWhite};
  background-color: ${({ isLoggedInUser }) =>
    isLoggedInUser ? colorMediumGray8 : colorLightGray4};
  cursor: pointer;
`;

export default MemberCircle;
