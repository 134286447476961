import { FilterContextProvider } from 'FilterModule/FilterContextProvider';
import { Page } from 'models/filter';
import { Ref } from 'react';
import { initialFilterLevelSchemas } from './filterConfig';
import {
  MemberAvailabilityTimelineFilterRef,
  MemberAvailabilityTimelineWithFilter
} from './MemberAvailabilityTimelineWithFilter';

interface MemberAvailabilityTimelineWithFilterContextProps {
  filterRef: Ref<MemberAvailabilityTimelineFilterRef>;
}

/**
 * use this component to render the timeline with side filter
 */
export const MemberAvailabilityTimelineWithFilterContext = ({
  filterRef
}: MemberAvailabilityTimelineWithFilterContextProps) => {
  const filterContextProviderProps: React.ComponentProps<
    typeof FilterContextProvider
  > = {
    filterSchemaParams: {
      pageName: Page.MemberAvailabilitySplitView,
      level2: 'members',
      initialFilterLevelSchemas
    }
  };

  return (
    <FilterContextProvider {...filterContextProviderProps}>
      <MemberAvailabilityTimelineWithFilter ref={filterRef} />
    </FilterContextProvider>
  );
};
