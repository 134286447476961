import { useRef, useCallback, useMemo, useContext } from 'react';
import DevProps from 'components/dev/DevProps';
import { ContainerDiv, StyledTable } from './styles';
import { getTableConfig, FindPeopleTableVariantType } from './tableConfigs';
import { VariableSizeList } from 'react-window';
import { TableV2, TableProps } from 'components/Table/TableV2';
import { BaseTableList } from 'components/Table/types';
import useNestedCollapse, {
  defaultParentCollapseState
} from 'appUtils/hooks/useNestedCollapse/useNestedCollapse';
import { usePhasesGroupedListsBuilder } from './helpers';
import { useMergeBudgetModal } from 'BudgetModule/hooks/useMergeBudgetModal';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';
import Header from './Header';

/* -------------------------------------------------------------------------- */

const emptyArray = [];
const emptyObj = {};

export interface FilterListsTableProps {
  variant: FindPeopleTableVariantType;
}

const parentGroupId = 'main';

const FindPeopleTable = ({ variant }: FilterListsTableProps) => {
  const {
    activeProjectPhasesOrder: phaseIds,
    selectedProjectId: projectId,
    selectedUnassignedMemberBudgetId: unassignedMemberBudgetId,
    isFetchingMemberSuggestionForPhase,
    showDemoSuggestions,
    activePhasesNum,
    openAddRemoveUnassignedRoleFromPhasesDropdown,
    resetUnassignedRoleAndSelectedPhaseIds,
    openBudgetModal,
    toggleAvailabilityView,
    selectedAvailabilityViewTriggerSource
  } = useContext(FindPeopleModalContext);

  const isLoading = !!isFetchingMemberSuggestionForPhase;

  const { handleOpenMergeBudgetModal, MergeBudgetModal } =
    useMergeBudgetModal();

  const orderedPhases = useMemo(() => {
    return phaseIds;
  }, [phaseIds]);

  const listRef = useRef<VariableSizeList>(null);

  const tableConfig = useMemo(() => {
    return getTableConfig({ variant });
  }, [variant]);

  /* ------------------------------ Interactions ------------------------------ */

  // Reset table height when collapse
  const collapseCallback = useCallback(() => {
    if (listRef.current?.resetAfterIndex) {
      listRef.current?.resetAfterIndex(0);
    }
  }, []);

  const {
    setParentCollapseState,
    getIsOpen,
    getCurrentParentCollapseState,
    toggleCollapse,
    toggleCollapseAll
  } = useNestedCollapse({
    ...(!tableConfig.isListsExpandedByDefault && {
      initialParentCollapseStates: {
        root: { ...defaultParentCollapseState, mode: 'OPENING' }
      }
    }),
    toggleCallback: collapseCallback
  });

  const topLevelCollapseState = getCurrentParentCollapseState(parentGroupId);

  const allCollapsed = !!topLevelCollapseState?.allCollapsed;

  // Building phase grouped list
  const phasesGroupedListsBuilder = usePhasesGroupedListsBuilder({
    getIsOpen,
    toggleCollapse,
    setParentCollapseState,
    getCurrentParentCollapseState,
    toggleCollapseAll,
    projectId,
    toggleAvailabilityView,
    selectedAvailabilityViewTriggerSource
  });

  const mainList: BaseTableList = useMemo(() => {
    const listItems = unassignedMemberBudgetId
      ? phasesGroupedListsBuilder({
          order: orderedPhases,
          parentGroupId,
          showDemoSuggestions,
          unassignedMemberBudgetId
        })
      : [];

    const list: BaseTableList = {
      listItems,
      isList: true,
      id: 'main',
      isOpen: true,
      isFullyLoaded: true,
      skipHeader: true
    };
    return list;
  }, [
    orderedPhases,
    phasesGroupedListsBuilder,
    showDemoSuggestions,
    unassignedMemberBudgetId
  ]);

  const customRowProps = useMemo(
    () => ({
      tableConfig,
      handleOpenMergeBudgetModal,
      projectId,
      unassignedMemberBudgetId,
      resetUnassignedRoleAndSelectedPhaseIds,
      openBudgetModal
    }),
    [
      tableConfig,
      handleOpenMergeBudgetModal,
      projectId,
      unassignedMemberBudgetId,
      resetUnassignedRoleAndSelectedPhaseIds,
      openBudgetModal
    ]
  );

  /* --------------------------------- Render --------------------------------- */

  const tableProps: TableProps = {
    mainList,
    listRef,
    customRowProps,
    shouldHideTooltipOnRowChanges: true,
    ...tableConfig.tableProps
  };

  return (
    <ContainerDiv variant={variant} isLoading={isLoading}>
      <SkeletonLoader isLoading={isLoading} count={10}>
        <>
          <Header
            openAddRemoveUnassignedRoleFromPhasesDropdown={
              openAddRemoveUnassignedRoleFromPhasesDropdown
            }
            openBudgetModal={openBudgetModal}
            activePhasesNum={activePhasesNum}
            toggleCollapseAll={() =>
              toggleCollapseAll(
                parentGroupId,
                allCollapsed ? 'expand' : 'collapse'
              )
            }
            allCollapsed={allCollapsed}
          />
          {activePhasesNum ? (
            <StyledTable variant={variant}>
              <DevProps {...{ mainList }} />
              <TableV2 {...tableProps} />
            </StyledTable>
          ) : (
            <div className="default-table-text">
              Click + Phase above to add Role to Phases.
            </div>
          )}
        </>
      </SkeletonLoader>
      <MergeBudgetModal />
    </ContainerDiv>
  );
};

export default FindPeopleTable;
