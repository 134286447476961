import React from 'react';
import SvgIcon from 'components/SvgIcon';

const DropdownMenuIcon = () => (
  <SvgIcon width="10" height="6" viewBox="0 0 10 6">
    <g id="Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Phase-6-Home" transform="translate(-1247.000000, -33.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1440" height="900" />
        <g
          id="Group-31"
          transform="translate(15.000000, 3.000000)"
          fill="#4A4A4A"
        >
          <g id="Group-2">
            <g id="Group-48">
              <g id="top-header" transform="translate(1137.000000, 16.000000)">
                <g id="Group-25">
                  <g id="Group-6">
                    <g id="Group-50">
                      <g id="Group-37">
                        <polygon
                          id="Shape"
                          transform="translate(100.000000, 17.000000) scale(-1, 1) rotate(-90.000000) translate(-100.000000, -17.000000) "
                          points="97.9910057 16.9996817 102.999299 21.5498822 102.503796 22 97 16.9996817 102.504497 12 103 12.4501178 97.9910057 16.9996817"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DropdownMenuIcon;
