import React from 'react';
import BulkHeaderCell from '../HeaderCells/BulkHeaderCell';

const PhaseBulkHeaderCell = (props) => {
  if (
    props.row?.original?.phase?.activity_order?.length &&
    props.row?.original?.isOpen
  ) {
    return <div />;
  }
  return <BulkHeaderCell {...props} />;
};
export default PhaseBulkHeaderCell;
