import { select, put } from 'redux-saga/effects';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import * as capacityActionCreators from 'CapacityModule/actionCreators';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import { getTeamCapacityId } from 'CapacityModule/selectors';

const {
  fetchHolidays,
  createHoliday,
  updateHoliday,
  deleteHoliday,
  fetchHolidayGroups,
  createHolidayGroup,
  updateHolidayGroup,
  deleteHolidayGroup
} = entityActions;

export function* fetchHolidaysWorker(action) {
  const token = yield select(getAuthToken);
  const capacityId = yield select(getTeamCapacityId);
  yield fetchEntity(
    fetchHolidays,
    api.fetchHolidays,
    capacityId,
    [token],
    action
  );
}

export function* createHolidayWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const capacityId = yield select(getTeamCapacityId);
  const { holiday, holiday_group_id } = action.payload;

  const { error, response } = yield changeEntity(
    createHoliday,
    api.createHoliday,
    [token, { capacity_id: capacityId, ...holiday, holiday_group_id }]
  );

  if (!error) {
    yield put(capacityActionCreators.fetchHolidayGroups({ teamId }));
  }
}

export function* updateHolidayWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { holiday, add_holiday_group_ids, remove_holiday_group_ids } =
    action.payload;
  const { error, response } = yield changeEntity(
    updateHoliday,
    api.updateHoliday,
    [token, holiday, add_holiday_group_ids, remove_holiday_group_ids]
  );

  if (!error) {
    yield put(capacityActionCreators.fetchHolidayGroups({ teamId }));
  }
}

export function* deleteHolidayWorker(action) {
  const token = yield select(getAuthToken);
  const { holiday } = action.payload;
  const { error, response } = yield changeEntity(
    deleteHoliday,
    api.deleteHoliday,
    [token, holiday.id]
  );
  if (!error) {
    yield put(capacityActionCreators.fetchHolidays());
  }
}

export function* fetchHolidayGroupsWorker(action) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(fetchHolidayGroups, api.fetchHolidayGroups, [
    token,
    teamId
  ]);
}

export function* createHolidayGroupWorker(action) {
  const token = yield select(getAuthToken);
  const { name, teamId } = action.payload;
  const { error, response } = yield changeEntity(
    createHolidayGroup,
    api.createHolidayGroup,
    [token, name, teamId]
  );

  if (!error) {
    yield put(capacityActionCreators.fetchHolidayGroups({ teamId }));
  }
}

export function* updateHolidayGroupWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { name, id, add_holiday_ids, remove_holiday_ids } = action.payload;
  const { error, response } = yield changeEntity(
    updateHolidayGroup,
    api.updateHolidayGroup,
    [token, id, name, add_holiday_ids, remove_holiday_ids]
  );

  if (!error) {
    yield put(capacityActionCreators.fetchHolidayGroups({ teamId }));
  }
}

export function* deleteHolidayGroupWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { id } = action.payload;
  const { error, response } = yield changeEntity(
    deleteHolidayGroup,
    api.deleteHolidayGroup,
    [token, id]
  );

  if (!error) {
    yield put(capacityActionCreators.fetchHolidayGroups({ teamId }));
  }
}
