import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SortUpIcon = ({
  color = '#0074d9',
  height = '17',
  className = '',
  width = '9'
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 17 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.514648 0H8.51465H16.5146L8.51465 9L0.514648 0Z" fill={color} />
  </SvgIcon>
);

export default SortUpIcon;
