import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { getMe, getTeamMembersHash, getAttachmentHash } from 'selectors';
import { MemberInitials } from '..';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FILE_STATUS } from 'appConstants';
import Linkify from 'linkifyjs/react';
import { formatMentionedNames } from 'appUtils/mentions';
import { CommentForm, CommentFormValues } from './CommentForm';

import moment from 'appUtils/momentConfig';
import { MomentInput } from 'moment';
import { Comment } from 'models/comment';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

export type GetCommentsPermission = ({ accountId }: { accountId: number }) => {
  canEditComment: boolean;
  canDeleteComment: boolean;
};

interface CommentRowProps {
  comment: Comment;
  isReply?: boolean;
  replyInputOpen?: boolean;
  openReplyInput?: () => void;
  onDelete: (values: { id: number; parentCommentId?: number }) => void;
  onUpdate: (
    values: {
      id: number;
    } & CommentFormValues
  ) => void;
  getCommentsPermission: GetCommentsPermission;
  editCommentDisabledTooltip: string;
  deleteCommentDisabledTooltip: string;
}
export const CommentRow = ({
  comment,
  isReply = false,
  replyInputOpen = false,
  openReplyInput,
  onUpdate,
  onDelete,
  getCommentsPermission,
  editCommentDisabledTooltip,
  deleteCommentDisabledTooltip
}: CommentRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { replies } = comment;

  const me = useSelector(getMe);
  const teamMembers = useSelector(getTeamMembersHash);
  const attachmentHash = useSelector(getAttachmentHash);

  const { canEditComment, canDeleteComment } = getCommentsPermission({
    accountId: comment.account.id
  });

  const handleUpdate = ({ description, files }: CommentFormValues) => {
    onUpdate({
      id: comment.id,
      description,
      files
    });
    setIsEditing(false);
  };
  const handleDeleteComment = useCallback(() => {
    onDelete({
      id: comment.id,
      parentCommentId: comment.parent_comment_id ?? undefined
    });
  }, [comment.id, comment.parent_comment_id, onDelete]);

  const renderEditableComment = () => {
    return (
      <CommentForm
        description={comment.description}
        attachments={comment.attachments}
        commentType="EDIT"
        isReply={isReply}
        isExpanded
        isEditingComment
        onSave={handleUpdate}
        onCancel={() => setIsEditing(false)}
        onClick={() => setIsEditing(true)}
      />
    );
  };

  const renderCommentRow = useCallback(() => {
    const formattedTime = formatCreatedTime(comment.created_at);
    const member = teamMembers[comment?.account?.id];

    return (
      <div
        className={cn('comment-row', {
          isReply,
          'rounded-bottom':
            !replyInputOpen && comment.replies && !comment.replies.length,
          'shorten-grid-row':
            isReply || (replies && replies.length) || replyInputOpen
        })}
      >
        <div />
        <div className="comment-metadata">
          <div className="comment-left">
            <div className="comment-author">{comment.account.name}</div>
            <div className="comment-timestamp">{formattedTime}</div>
          </div>
          <>
            <div className="comment-right">
              <div
                className={cn({ disabled: !canEditComment }, 'edit-asset')}
                onClick={canEditComment ? () => setIsEditing(true) : undefined}
                data-for="app-tooltip"
                data-effect="solid"
                data-tip={
                  !canEditComment ? editCommentDisabledTooltip : 'Edit Comment'
                }
              />
              <div className="comment-icon-separator" />
              <div
                className={cn({ disabled: !canDeleteComment }, 'delete-asset')}
                onClick={canDeleteComment ? handleDeleteComment : undefined}
                data-for="app-tooltip"
                data-effect="solid"
                data-tip={
                  !canDeleteComment
                    ? deleteCommentDisabledTooltip
                    : 'Delete Comment'
                }
              />
            </div>
          </>
        </div>
        <div className="initials-col">
          <MemberInitials
            member={member}
            originType="teamMembership"
            classes={cn('regular-member-no-hover selected', {
              'logged-member-no-hover': comment.account.id === me.id
            })}
          />
        </div>
        <div className="comment-description">
          <Linkify options={{ nl2br: true }}>
            {formatMentionedNames(comment.description)}
          </Linkify>
        </div>

        {comment.attachments?.map((attachment) => {
          const attachmentInfo = attachmentHash[attachment.id] || attachment;
          if (attachmentInfo.deleted) {
            return null;
          }
          return (
            <div
              key={attachmentInfo.file_url}
              className="file-attachment-container"
              onClick={() => {
                if (attachmentInfo.file_url) {
                  window.open(attachmentInfo.file_url, '_blank');
                }
              }}
            >
              <i className="file-type" />
              <span
                className="file-name"
                data-for="app-tooltip"
                data-effect="solid"
                data-tip={
                  attachmentInfo?.status === FILE_STATUS.PENDING
                    ? 'Scanning for Malware'
                    : ''
                }
              >
                {attachmentInfo.file_name}
              </span>
            </div>
          );
        })}
        <div
          className={cn('reply-button', {
            hidden: isReply || (replies && replies.length) || replyInputOpen,
            disabled: !canEditComment
          })}
          onClick={canEditComment ? openReplyInput : undefined}
          data-for="app-tooltip"
          data-effect="solid"
          data-tip={editCommentDisabledTooltip}
        >
          <div>
            <i className="reply-arrow" />
          </div>
          <div>Reply</div>
        </div>
      </div>
    );
  }, [
    comment.created_at,
    comment.account.id,
    comment.account.name,
    comment.replies,
    comment.description,
    comment.attachments,
    teamMembers,
    isReply,
    replyInputOpen,
    replies,
    handleDeleteComment,
    me.id,
    openReplyInput,
    attachmentHash,
    canEditComment,
    canDeleteComment,
    editCommentDisabledTooltip,
    deleteCommentDisabledTooltip
  ]);

  const renderReplies = () => {
    return replies.map((reply) => (
      <CommentRow
        key={`reply ${reply.id}`}
        comment={reply}
        isReply
        onDelete={onDelete}
        onUpdate={onUpdate}
        getCommentsPermission={getCommentsPermission}
        editCommentDisabledTooltip={editCommentDisabledTooltip}
        deleteCommentDisabledTooltip={deleteCommentDisabledTooltip}
      />
    ));
  };

  useEffect(() => {
    rebuildTooltip();
    if (isEditing) {
      ReactTooltip.hide();
    }
  }, [isEditing]);

  return (
    <RootContainer>
      {isEditing ? renderEditableComment() : renderCommentRow()}
      {!!replies?.length && renderReplies()}
    </RootContainer>
  );
};
const RootContainer = styled.div``;

export const formatCreatedTime = (createdTime: MomentInput): string => {
  if (moment().startOf('day').diff(moment(createdTime), 'days') > 2) {
    return moment(createdTime).format('M/D/YY h:mm A');
  }
  if (moment(createdTime).isSame(moment(), 'day')) {
    return 'Today ' + moment(createdTime).format('h:mm A');
  }

  return 'Yesterday ' + moment(createdTime).format('h:mm A');
};
