import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TwoMembersIcon = ({
  height = '17',
  width = '13',
  color = '#4f4f4f',
  viewBox = '0 0 15 11'
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} fill="none">
      <circle
        cx="4.3678"
        cy="4.53675"
        r="1.68421"
        stroke={color}
        strokeWidth="0.8"
      />
      <circle
        cx="9.33769"
        cy="3.10526"
        r="2.10526"
        stroke={color}
        strokeWidth="0.8"
      />
      <path
        d="M1 10.7693C1 8.90897 2.50809 7.40088 4.36842 7.40088C5.03748 7.40088 5.66098 7.59594 6.18515 7.93231"
        stroke={color}
        strokeWidth="0.8"
      />
      <path
        d="M13.632 10.7681C13.632 8.44273 11.7469 6.55762 9.42147 6.55762C7.09605 6.55762 5.21094 8.44273 5.21094 10.7681"
        stroke={color}
        strokeWidth="0.8"
      />
    </SvgIcon>
  );
};

export default TwoMembersIcon;
