import { BillableChartTooltipContainer } from '../../styles';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

const CapacitySummaryCustomTooltip = ({
  active,
  payload,
  planned,
  accountCapacitySum
}) => {
  if (active) {
    const segment = payload[0]?.payload; // library format
    if (!segment) {
      return null;
    }

    return (
      <BillableChartTooltipContainer className="custom-tooltip">
        {`${planned} hours planned out of ${formatNumWithMaxOneDecimal(
          accountCapacitySum
        )} total capacity.`}
      </BillableChartTooltipContainer>
    );
  }

  return null;
};

export default CapacitySummaryCustomTooltip;
