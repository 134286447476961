import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MembersIcon = ({
  height = '12',
  width = '9',
  currentColor = '#808080'
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 9 12">
      <path
        d="M1.06445 11.9359C1.06445 10.5798 1.06445 9.78832 1.06445 9.56148C1.06445 7.55721 3.86421 6.70534 4.69385 6.70534C5.52349 6.70534 8.32325 7.55721 8.32325 9.56148C8.32325 9.78832 8.32325 10.5798 8.32325 11.9359"
        stroke={currentColor}
        fill="transparent"
      />
      <path
        d="M6.70558 3.32062C6.70558 4.56683 5.69531 5.5771 4.4491 5.5771C3.2029 5.5771 2.19263 4.56683 2.19263 3.32062C2.19263 2.07442 3.2029 1.06415 4.4491 1.06415C5.69531 1.06415 6.70558 2.07442 6.70558 3.32062Z"
        stroke={currentColor}
        fill="transparent"
      />
    </SvgIcon>
  );
};

export default MembersIcon;
