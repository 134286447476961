import React from 'react';
import { StyledTooltip, TooltipMessage } from './styles';
import AdminList from './AdminList';

class AdminTooltip extends React.Component {
  state = {
    message: ''
  };

  setMessage = (message) => {
    if (message !== this.state.message) {
      this.setState({ message });
    }
  };

  render() {
    return (
      <StyledTooltip
        effect="solid"
        id="admin-tooltip"
        place="bottom"
        getContent={this.setMessage}
      >
        <TooltipMessage>{this.state.message}</TooltipMessage>
        <AdminList inTooltip />
      </StyledTooltip>
    );
  }
}
export default AdminTooltip;
