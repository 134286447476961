import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { EditMemberList } from '..';
import {
  fetchTeamMembers,
  openTeamMemberModal,
  closeAddProjectModal,
  closeEditProjectModalMaintainEditingProject,
  fetchBoardMembers,
  addMemberToProject,
  toggleMemberInput,
  toggleInviteButton,
  toggleInviteForm,
  inviteTeamMember,
  deleteMemberFromProject,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  openAddMembersForm,
  addBulkMembersToProject
} from 'actionCreators';
import {
  getEditingProject,
  getEditingProjectId,
  getAuthToken,
  getMe,
  getAllTeamMembers,
  getInviteFormOpen,
  showInviteButton,
  getProjectGroupMemberInputOpen,
  getAddMembersForm
} from 'selectors';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

class EditProjectMemberListContainer extends React.Component {
  componentDidMount() {
    const { fetchTeamMembers, fetchBoardMembers, groups } = this.props;
    fetchTeamMembers();
    if (groups.selectedGroupID || groups.selectedBoard) {
      fetchBoardMembers(
        groups.selectedGroupID || groups.selectedBoard.id,
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchBoardMembers, groups } = this.props;
    if (
      (!prevProps.groups.selectedGroupID && groups.selectedGroupID) ||
      (!prevProps.groups.selectedBoard && groups.selectedBoard)
    ) {
      fetchBoardMembers(
        groups.selectedGroupID || groups.selectedBoard.id,
        true
      );
    }
  }

  render() {
    const {
      memberList,
      allMemberList,
      editingProject,
      clearMemberFilter,
      openTeamMemberModal,
      me,
      showInput,
      toggleMemberInput,
      showInviteButton,
      toggleInviteButton,
      showInviteForm,
      toggleInviteForm,
      inviteTeamMember,
      changeInputValue,
      inputValue,
      addMemberToLocalList,
      addMemberToProject,
      deleteMemberFromLocalList,
      roles,
      changeRoleFromLocalList,
      isEditForm,
      closeAddProjectModal,
      closeEditProjectModalMaintainEditingProject,
      boardMembers,
      projectId,
      addMembersForm,
      deleteMemberFromProject,
      updateProjectMemberRole,
      openBulkAddMembersForm,
      closeBulkAddMembersForm,
      openAddMembersForm,
      bulkAddIsOpen,
      populateBulkAddMembers,
      emptyBulkAddMembers,
      addBulkMembersToProject,
      isContained,
      showSuggestions,
      setSuggestions,
      inBudgetModal,
      onClose,
      onCloseModal
    } = this.props;

    const boardMemberAccountIds = map(
      boardMembers,
      (member) => member.account.id
    );
    // allMember elements are ProjectTeam objects whereas
    // boardMembers are BoardMember objects, so we'll match them based
    // on their related Account id, not their object ids
    const membersInProjectAccountIds = map(
      memberList,
      (member) => member.account.id
    );
    const membersNotInProject = filter(
      allMemberList,
      (member) =>
        (member.account.id === me.id ||
          boardMemberAccountIds.includes(member.account.id)) &&
        !membersInProjectAccountIds.includes(member.account.id)
    );
    const membersNotInBoard = filter(
      allMemberList,
      (member) =>
        !boardMemberAccountIds.includes(member.account.id) &&
        member.account.id !== me.id &&
        !membersInProjectAccountIds.includes(member.account.id)
    );

    const members = membersNotInProject.concat(membersNotInBoard);
    return addMembersForm.isOpen ? (
      <EditMemberList
        membersNotInGroup={members}
        memberList={memberList}
        clearMemberFilter={clearMemberFilter}
        addMemberToProject={addMemberToProject}
        showInput={showInput}
        toggleMemberInput={toggleMemberInput}
        showInviteButton={showInviteButton}
        toggleInviteButton={toggleInviteButton}
        showInviteForm={showInviteForm}
        toggleInviteForm={toggleInviteForm}
        changeInputValue={changeInputValue}
        inputValue={inputValue}
        allMemberList={members}
        addMemberToSelection={addMemberToProject}
        addBulkMembersToSelection={addBulkMembersToProject}
        selection={addMembersForm.project}
        deleteMemberFromSelection={deleteMemberFromProject}
        closeModal={
          isEditForm
            ? closeEditProjectModalMaintainEditingProject
            : closeAddProjectModal
        }
        openTeamMemberModal={openTeamMemberModal}
        me={me}
        project={addMembersForm.project}
        projectId={addMembersForm.project.id}
        roles={roles}
        changeRoleFromLocalList={updateProjectMemberRole}
        membersNotInBoard={membersNotInBoard}
        boardMembers={boardMembers}
        inviteTeamMember={inviteTeamMember}
        addMembersForm={addMembersForm}
        bulkAddIsOpen={bulkAddIsOpen}
        openBulkAddMembersForm={openBulkAddMembersForm}
        closeBulkAddMembersForm={closeBulkAddMembersForm}
        populateBulkAddMembers={populateBulkAddMembers}
        emptyBulkAddMembers={emptyBulkAddMembers}
        openAddMembersForm={() =>
          openAddMembersForm(MODAL_TYPE.PROJECT, addMembersForm.project)
        }
        isContained={isContained}
        showSuggestions={showSuggestions}
        setSuggestions={setSuggestions}
        inBudgetModal={inBudgetModal}
        onClose={onClose}
        onCloseModal={onCloseModal}
      />
    ) : (
      <EditMemberList
        memberList={memberList}
        clearMemberFilter={clearMemberFilter}
        addMemberToProject={addMemberToProject}
        showInput={showInput}
        toggleMemberInput={toggleMemberInput}
        showInviteButton={showInviteButton}
        toggleInviteButton={toggleInviteButton}
        showInviteForm={showInviteForm}
        toggleInviteForm={toggleInviteForm}
        changeInputValue={changeInputValue}
        inputValue={inputValue}
        allMemberList={members}
        addMemberToSelection={addMemberToLocalList}
        selection={editingProject}
        deleteMemberFromSelection={deleteMemberFromLocalList}
        closeModal={
          isEditForm
            ? closeEditProjectModalMaintainEditingProject
            : closeAddProjectModal
        }
        openTeamMemberModal={openTeamMemberModal}
        me={me}
        projectId={projectId}
        roles={roles}
        changeRoleFromLocalList={changeRoleFromLocalList}
        membersNotInBoard={membersNotInBoard}
        inviteTeamMember={inviteTeamMember}
        addMembersForm={addMembersForm}
        showSuggestions={showSuggestions}
        setSuggestions={setSuggestions}
        inBudgetModal={inBudgetModal}
        onClose={onClose}
        onCloseModal={onCloseModal}
      />
    );
  }
}

EditProjectMemberListContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  memberList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  allMemberList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  editingProject: PropTypes.object,
  closeAddProjectModal: PropTypes.func.isRequired,
  closeEditProjectModalMaintainEditingProject: PropTypes.func.isRequired,
  fetchTeamMembers: PropTypes.func.isRequired,
  openTeamMemberModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  boardMembers: PropTypes.array,
  showInput: PropTypes.bool.isRequired,
  toggleMemberInput: PropTypes.func.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  addMemberToLocalList: PropTypes.func.isRequired,
  deleteMemberFromLocalList: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array.isRequired,
  changeRoleFromLocalList: PropTypes.func.isRequired,
  isEditForm: PropTypes.bool.isRequired,
  fetchBoardMembers: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  me: getMe(state),
  boardMembers: state.groups.memberList,
  allMemberList: getAllTeamMembers(state),
  editingProject: getEditingProject(state),
  projectId: getEditingProjectId(state),
  groups: state.groups,
  showInput: getProjectGroupMemberInputOpen(state),
  showInviteButton: showInviteButton(state),
  showInviteForm: getInviteFormOpen(state),
  addMembersForm: getAddMembersForm(state)
});

const mapDispatchToProps = {
  fetchTeamMembers,
  closeAddProjectModal,
  closeEditProjectModalMaintainEditingProject,
  fetchBoardMembers,
  openTeamMemberModal,
  addMemberToProject,
  addBulkMembersToProject,
  toggleMemberInput,
  toggleInviteButton,
  toggleInviteForm,
  inviteTeamMember,
  deleteMemberFromProject,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  openAddMembersForm
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  fetchTeamMembers: () => dispatchProps.fetchTeamMembers(stateProps.token),
  addMemberToProject: (projectId, memberId) =>
    dispatchProps.addMemberToProject(
      projectId,
      memberId,
      3,
      stateProps.addMembersForm.isOpen
        ? stateProps.addMembersForm.project.board_id
        : stateProps.editingProject.board_id
    ),
  addBulkMembersToProject: (projectId, memberIds, permissions) =>
    dispatchProps.addBulkMembersToProject({
      projectId,
      memberIds,
      projectRole: 3,
      boardId: stateProps.addMembersForm.isOpen
        ? stateProps.addMembersForm.project.board_id
        : stateProps.editingProject.board_id,
      permissions
    }),
  deleteMemberFromProject: (member, selection, me, permissions) =>
    dispatchProps.deleteMemberFromProject({
      projectId: stateProps.addMembersForm.project.id,
      accountId: member.account.id,
      memberIsLoggedUser: member.account.id === stateProps.me.id,
      permissions
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditProjectMemberListContainer);
