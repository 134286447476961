import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ColorPickerCircle from './ColorPickerCircle';
import ColorPickerSquare from './ColorPickerSquare';
import ColorPickerCustom from './ColorPickerCustom';
import ColorPickerMemberInitial from './ColorPickerMemberInitial';
import { colorActions } from 'actionCreators';
import {
  StyledColorPickerInput,
  StyledPickerHeader,
  StyledPickerFooter,
  StyledInputContainer,
  StyledMemberNameHeader
} from './styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
const visibleToText = 'Color is visible to all project members.';
const oldOriginTypes = ['projectStage', 'projectStatus', 'taskStatus'];

const ColorPickerInput = (props) => {
  const {
    colors,
    id,
    originType,
    alwaysOpen,
    showInPopover,
    isOpen,
    headerText,
    PopoverWrapper,
    handleSelect,
    handleClose,
    preference, // for items that carry own color independent of userTheme
    memberName,
    memberInitials
  } = props;
  const getApiCalls = () => {
    const {
      originType,
      createProjectColorPreference,
      updateProjectColorPreference,
      deleteProjectColorPreference,
      createBoardPreference,
      updateBoardPreference,
      deleteBoardPreference,
      createTaskGroupPreference,
      updateTaskGroupPreference,
      deleteTaskGroupPreference,
      createTeamMembershipPreference,
      updateTeamMembershipPreference,
      deleteTeamMembershipPreference,
      createTeamPreference,
      updateTeamPreference,
      updateTaskStatus,
      createTagPreference,
      updateTagPreference,
      deleteTagPreference,
      apiProps
    } = props;
    const apiCalls = {
      [ORIGIN_TYPE_STRINGS.PROJECT]: {
        create: createProjectColorPreference,
        update: updateProjectColorPreference,
        delete: deleteProjectColorPreference
      },
      [ORIGIN_TYPE_STRINGS.PROJECT_MANAGE_MEMBERS]: {
        create: createProjectColorPreference,
        update: updateProjectColorPreference,
        delete: deleteProjectColorPreference
      },
      [ORIGIN_TYPE_STRINGS.BOARD]: {
        create: createBoardPreference,
        update: updateBoardPreference,
        delete: deleteBoardPreference
      },
      [ORIGIN_TYPE_STRINGS.TASK_GROUP]: {
        create: createTaskGroupPreference,
        update: updateTaskGroupPreference,
        delete: deleteTaskGroupPreference
      },
      [ORIGIN_TYPE_STRINGS.TEAM_MEMBERSHIP]: {
        create: createTeamMembershipPreference,
        update: updateTeamMembershipPreference,
        delete: deleteTeamMembershipPreference
      },
      [ORIGIN_TYPE_STRINGS.TEAM]: {
        create: createTeamPreference,
        update: updateTeamPreference,
        delete: updateTeamPreference
      },
      [ORIGIN_TYPE_STRINGS.TASK_STATUS]: {
        create: updateTaskStatus,
        update: updateTaskStatus,
        delete: updateTaskStatus
      },
      [ORIGIN_TYPE_STRINGS.TAG]: {
        create: createTagPreference,
        update: updateTagPreference,
        delete: deleteTagPreference
      },
      ...apiProps
    };

    return apiCalls[originType];
  };

  const [isCustomColorModalOpen, setIsCustomColorModalOpen] = useState(false);
  const [color, setColor] = useState('#7F7E6E');

  const isPopover = !alwaysOpen || showInPopover;

  const toggleCustomColorWindow = () => {
    setIsCustomColorModalOpen(!isCustomColorModalOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsCustomColorModalOpen(false);
    }
  }, [isOpen]);

  const useOldPicker = oldOriginTypes.includes(originType);
  const teamMembershipColorPicker = originType === 'teamMembership';
  const ColorContainer = useOldPicker
    ? ColorPickerSquare
    : teamMembershipColorPicker
    ? ColorPickerMemberInitial
    : ColorPickerCircle;

  const PickerHeader =
    (!alwaysOpen || headerText) && !isCustomColorModalOpen ? (
      teamMembershipColorPicker ? (
        <StyledMemberNameHeader>{memberName}</StyledMemberNameHeader>
      ) : (
        <StyledPickerHeader $useOldPicker={useOldPicker}>
          <span>{headerText || 'Select Color'}</span>
          {isPopover && <i className="close-icon-dark" onClick={handleClose} />}
        </StyledPickerHeader>
      )
    ) : null;

  const preventCustomClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const Picker = (
    <StyledInputContainer
      $alwaysOpen={alwaysOpen && !showInPopover}
      $teamMembership={teamMembershipColorPicker}
      $useOldPicker={useOldPicker}
      className="color-input-container"
    >
      {PickerHeader}
      {!isCustomColorModalOpen && (
        <StyledColorPickerInput
          $numColors={colors.length}
          $teamMembership={teamMembershipColorPicker}
          $useOldPicker={useOldPicker}
        >
          {colors.map((color, index) => (
            <ColorContainer
              key={color}
              color={color}
              id={id}
              originType={originType}
              isDefaultColor={useOldPicker ? index === 0 : false}
              preference={preference}
              handleSelect={handleSelect}
              apiCall={getApiCalls()}
              memberInitials={memberInitials}
            />
          ))}
          {!useOldPicker && !teamMembershipColorPicker && (
            <ColorPickerCircle
              key="colorSwirl"
              color="colorSwirl"
              id={id}
              isDefaultColor="false"
              preference={preference}
              handleSelect={toggleCustomColorWindow}
              isCustomColor="true"
            />
          )}
        </StyledColorPickerInput>
      )}
      {useOldPicker && <StyledPickerFooter>{visibleToText}</StyledPickerFooter>}
      {isCustomColorModalOpen && !useOldPicker && (
        <div onClick={preventCustomClose}>
          <ColorPickerCustom
            id={id}
            color={color}
            setColor={setColor}
            originType={originType}
            handleSelect={handleSelect}
            apiCall={getApiCalls()}
            preference={preference}
            toggle={toggleCustomColorWindow}
          />
        </div>
      )}
    </StyledInputContainer>
  );
  return isPopover ? PopoverWrapper(Picker) : Picker;
};

const mapDispatchToProps = {
  ...colorActions
};

export default connect(null, mapDispatchToProps)(ColorPickerInput);
