import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
export const LoadMoreRowCell = ({ row: { original } }) => {
  const { customRowProps = {}, groupData = {}, lastItemIndex } = original;

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      customRowProps.groupedLoadMoreItems &&
        customRowProps.groupedLoadMoreItems({ groupData, lastItemIndex });
    }
  }, [inView]);
  return <div ref={ref} />;
};
