import { permissionActions } from './permissionActions';
const verifyPermission = (action, permissions) => {
  const { allPermissions, roles, roleTemplates, oneOffPermissions } =
    permissions;
  const permissionResolver = permissionActions[action.type];
  if (!permissionResolver) {
    console.error(`
      The action ${action.type} does not exist as a key in the permissionActions hash. In order to check permissions for this action you need add a resolver function at ${action.type} to the permissionsActions hash.
     The permission middleware skips checking actions whose types do not exist in the hash.
    `);
    return true;
  }
  const permitted = permissionResolver(
    allPermissions,
    roles,
    roleTemplates,
    oneOffPermissions,
    action
  );
  if (!permitted) {
    console.error('Action denied:', action);
  }
  return permitted;
};

export default verifyPermission;
