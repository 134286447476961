import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import CollapseAllButton from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';
import useCollapse from 'appUtils/hooks/useCollapse';
import {
  ContainerDiv,
  StyledMembersPermissionsTable,
  TableHeaderContainer
} from './styles';
import { membersPermissionsTableHelpers } from './helpers/layout';
import {
  getSelectedTeam,
  getTeamMembersHashByTeamMembership
} from 'TeamsModule/selectors';
import noop from 'lodash/noop';
import { useMemberGroupedListsBuilder } from './helpers/useMemberPermissionListItemsBuilder';
import { useMemberListItemsBuilder } from './helpers/useMemberListItemsBuilder';
import { BaseTableList } from 'components/Table/types';
import { TableV2, TableProps } from 'components/Table/TableV2';
import {
  EditMemberMetadataModalParams,
  MemberPermissionListItemsBuilder,
  SetMemberAccessFlyoutParams
} from './types';
import { EditMemberMetadataModal } from 'TeamsModule/components/EditMemberMetadataModal';
import { AccessLevelsDropdown } from 'PermissionsModule/components/AccessLevelsDropdown';
import { AccessRoles } from 'PermissionsModule/types';
import { updateTeamMembersPermission } from 'PermissionsModule/actionCreators';
import { toggleRemoveOverlay } from 'actionCreators';
import RemoveMemberModal from 'views/personalSettings/MemberManagementTable/RemoveMemberModal';
import { AccessLevelSummaryList } from 'PermissionsModule/components/AccessLevelSummaryList';
import { permissionConstants } from 'PermissionsModule/constants';
import { useMembershipTableData } from '../DataProvider';
import { MembershipPermissionTableBatchActionsMenu } from '../BatchActionsMenu';
import { PermissionInfoModalToggle } from 'PermissionsModule/components/PermissionsSections/shared/PermissionInfoModalToggle';

const {
  columnWidths,
  totalColumnWidths,
  allColumns,
  ROW_TYPES,
  rowToCells,
  stickyHeaderCells,
  emptyRow,
  headersToIgnore,
  maxTotalWidth
} = membersPermissionsTableHelpers;

const { EMPLOYMENT_TYPES } = permissionConstants;

export const MembersPermissionsTable = () => {
  const [editMemberMetadataModalParams, setEditMemberMetadataModalParams] =
    useState<EditMemberMetadataModalParams>();
  const [memberAccessFlyoutParams, setMemberAccessFlyoutParams] =
    useState<SetMemberAccessFlyoutParams>();

  const dispatch = useAppDispatch();

  const teamMembersHash = useAppSelector(getTeamMembersHashByTeamMembership);
  const selectedTeam = useAppSelector(getSelectedTeam);

  const {
    groupTotalCounts,
    ordersByGroup,
    selectedTeamMemberIds,
    clearAllSelectedTeamMembers,
    selectedTeamMembersArchiveStatus
  } = useMembershipTableData();

  const teamMembersFromMemberAccessFlyoutParams = useMemo(() => {
    return memberAccessFlyoutParams?.teamMembershipIds
      ? memberAccessFlyoutParams?.teamMembershipIds.flatMap(
          (id) => teamMembersHash[id] ?? []
        )
      : undefined;
  }, [memberAccessFlyoutParams?.teamMembershipIds, teamMembersHash]);

  const handleRequestArchiveMembers = useCallback(() => {
    const accountIds = teamMembersFromMemberAccessFlyoutParams
      ? teamMembersFromMemberAccessFlyoutParams.map(
          (member) => member.account.id
        )
      : selectedTeamMemberIds.flatMap(
          (id) => teamMembersHash[id]?.account.id ?? []
        );

    if (accountIds.length) {
      if (teamMembersFromMemberAccessFlyoutParams) {
        setMemberAccessFlyoutParams(undefined);
      }

      dispatch(
        toggleRemoveOverlay({
          showRemoveOverlay: true,
          accountIds,
          removeOverlayIsUnarchive:
            selectedTeamMembersArchiveStatus === 'archived'
        })
      );

      clearAllSelectedTeamMembers();
    }
  }, [
    clearAllSelectedTeamMembers,
    dispatch,
    selectedTeamMemberIds,
    selectedTeamMembersArchiveStatus,
    teamMembersFromMemberAccessFlyoutParams,
    teamMembersHash
  ]);

  const handleSelectNewRole = useCallback(
    (teamMembershipIds: number[]) => (role: AccessRoles) => {
      setMemberAccessFlyoutParams(undefined);

      if (selectedTeam?.id && teamMembersFromMemberAccessFlyoutParams) {
        dispatch(
          updateTeamMembersPermission({
            teamId: selectedTeam.id,
            teamMembershipIds,
            teamRole: role
          })
        );

        clearAllSelectedTeamMembers();
      }
    },
    [
      clearAllSelectedTeamMembers,
      dispatch,
      selectedTeam?.id,
      teamMembersFromMemberAccessFlyoutParams
    ]
  );

  const AccessLevelsDropdownRenderer = useMemo(() => {
    return memberAccessFlyoutParams &&
      teamMembersFromMemberAccessFlyoutParams ? (
      <AccessLevelsDropdown
        isOpen
        popoverTarget={memberAccessFlyoutParams.anchorEl}
        teamMembershipIds={teamMembersFromMemberAccessFlyoutParams.map(
          (member) => member.id
        )}
        onClose={() => setMemberAccessFlyoutParams(undefined)}
        onRequestArchiveMember={handleRequestArchiveMembers}
        onSelect={handleSelectNewRole(
          memberAccessFlyoutParams.teamMembershipIds
        )}
      />
    ) : null;
  }, [
    handleRequestArchiveMembers,
    handleSelectNewRole,
    memberAccessFlyoutParams,

    setMemberAccessFlyoutParams,
    teamMembersFromMemberAccessFlyoutParams
  ]);

  const {
    toggleCollapse,
    toggleCollapseAll,
    getIsOpen,
    allCollapsed: isAllCollapsed
  } = useCollapse({
    toggleCallback: noop,
    defaultAllOpen: true,
    topLevelCollapseCount: [
      EMPLOYMENT_TYPES.member,
      EMPLOYMENT_TYPES.externalProjectContractor,
      EMPLOYMENT_TYPES.internalContractor,
      EMPLOYMENT_TYPES.projectGuest
    ].length
  });

  const memberGroupedListsBuilder =
    useMemberGroupedListsBuilder<MemberPermissionListItemsBuilder>({
      getIsOpen,
      toggleCollapse,
      ordersByGroup,
      groupTotalCounts,
      listItemsBuilder: useMemberListItemsBuilder({
        onRequestEditMemberAccess: setMemberAccessFlyoutParams,
        onRequestEditMemberMetadata: setEditMemberMetadataModalParams
      })
    });

  const mainList: BaseTableList = useMemo(() => {
    const listItems = memberGroupedListsBuilder({
      order: [
        EMPLOYMENT_TYPES.member,
        EMPLOYMENT_TYPES.internalContractor,
        EMPLOYMENT_TYPES.externalProjectContractor,
        EMPLOYMENT_TYPES.projectGuest
      ],
      parentGroupId: 'main'
    });

    const list: BaseTableList = {
      listItems,
      isList: true,
      id: 'main',
      isOpen: true,
      isFullyLoaded: true,
      skipHeader: true,
      addEmptyRow: true
    };

    return list;
  }, [memberGroupedListsBuilder]);

  const tableProps: TableProps = {
    mainList,
    totalColumnsWidthOverride: totalColumnWidths,
    columnWidthsByHeaderType: columnWidths,
    tableColumns: allColumns,
    rowTypesHash: ROW_TYPES,
    rowTypeToCellsByHeaderType: rowToCells,
    stickyHeaderCells,
    isStickyHeaderHidden: false,
    hasStickyHeader: true,
    maxHeight: window.innerHeight,
    showHeader: false,
    emptyRow,
    headersToIgnore
  };

  const handleBulkModifyAccessLevelRequest: MouseEventHandler<HTMLElement> =
    useCallback(
      (event) => {
        setMemberAccessFlyoutParams({
          anchorEl: event.currentTarget,
          teamMembershipIds: selectedTeamMemberIds
        });
      },
      [selectedTeamMemberIds]
    );

  return (
    <>
      <ContainerDiv $width={maxTotalWidth + 270}>
        <StyledMembersPermissionsTable>
          <TableHeaderContainer>
            <CollapseAllButton
              onToggle={toggleCollapseAll}
              isCollapsed={isAllCollapsed}
              className="collapse-all"
            />
            <PermissionInfoModalToggle />
          </TableHeaderContainer>
          <TableV2 {...tableProps} />

          <div style={{ marginLeft: 50, marginTop: 55, width: '100%' }}>
            {selectedTeamMemberIds.length > 0 ? (
              <MembershipPermissionTableBatchActionsMenu
                onRequestBulkModifyAccessLevel={
                  handleBulkModifyAccessLevelRequest
                }
                onRequestBulkToggleArchiveTeamMembers={
                  handleRequestArchiveMembers
                }
              />
            ) : (
              <AccessLevelSummaryList />
            )}
          </div>
        </StyledMembersPermissionsTable>
      </ContainerDiv>
      {editMemberMetadataModalParams && (
        <EditMemberMetadataModal
          teamMembershipId={editMemberMetadataModalParams.teamMembershipId}
          onToggle={() => setEditMemberMetadataModalParams(undefined)}
        />
      )}
      {AccessLevelsDropdownRenderer}
      <RemoveMemberModal />
    </>
  );
};
