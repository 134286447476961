import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { isInAnyPopover } from 'appUtils/popoverClicks';

/* this is a modified third party component taken from: https://github.com/hokaccha/react-micro-flyout */

export default class Flyout extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickDocument = this.handleClickDocument.bind(this);
  }

  componentDidMount() {
    if (this.props.open) {
      this.attachEvent();
    }
  }

  componentWillUnmount() {
    this.detachEvent();
  }

  UNSAFE_componentWillReceiveProps({ open }) {
    if (open === this.props.open) return;

    if (open) {
      this.attachEvent();
    } else {
      this.detachEvent();
    }
  }

  attachEvent() {
    document.addEventListener('click', this.handleClickDocument);
  }

  detachEvent() {
    document.removeEventListener('click', this.handleClickDocument);
  }

  handleClickDocument(e) {
    if (isInAnyPopover(e)) {
      return;
    }
    const dom = ReactDOM.findDOMNode(this);
    if (
      dom &&
      !dom.contains(e.target) &&
      this.props.open &&
      e.target.tagName !== 'BUTTON'
    ) {
      this.props.onRequestClose(e);
    }
  }

  render() {
    const style = this.props.style || {};

    if (!this.props.open) {
      style.display = 'none';
    }

    const { children, onRequestClose, ...props } = this.props;
    return <div {...props}>{children}</div>;
  }
}

Flyout.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired
};
