import { CurrencyDropdown } from 'CurrencyModule/components/CurrencyDropdown';
import { getSelectedCurrencyString } from 'CurrencyModule/utils';
import {
  SelectDropdownProps,
  SelectInput,
  SelectInputVariant
} from 'RatesModule/components/SelectInput/SelectInput';
import { useTeamCurrency } from 'CurrencyModule/hooks/useTeamCurrency';

interface CurrencySelect {
  currencyCode: string;
}

export const CurrencyField = () => {
  const { currencyCode, updateCurrencyCode } = useTeamCurrency();

  const handleChange = ({ currencyCode }: CurrencySelect) =>
    updateCurrencyCode(currencyCode);

  const dropdownRenderer = (props: SelectDropdownProps<CurrencySelect>) => (
    <CurrencyDropdown
      customHeader="Default Currency"
      value={currencyCode}
      {...props}
    />
  );

  return (
    <SelectInput
      onSelect={handleChange}
      renderDropdown={dropdownRenderer}
      variant={SelectInputVariant.White}
    >
      {getSelectedCurrencyString({ currencyCode })}
    </SelectInput>
  );
};
