import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import EllipsisText from 'components/EllipsisText';
import styled, { useTheme } from 'styled-components';
import { EntityRateCellProps, TableCellProps } from '../types';
import { isEntityRateCell } from '../utils';
import cn from 'classnames';
import { EditIcon } from './EditIcon';

export const DescriptionCell = (props: TableCellProps) =>
  isEntityRateCell(props) ? <DescriptionCellWithEntityRate {...props} /> : null;

export const DescriptionCellWithEntityRate = (props: EntityRateCellProps) => {
  const {
    row: {
      original: { billRate, editEntityRates, isRateGroupArchived, rateEntity }
    }
  } = props;

  const theme = useTheme();

  const isEntityRateArchived = !!rateEntity?.archived;

  const handleEditRate = () => editEntityRates(false);

  const { cellProps, pencilIconColor } =
    isRateGroupArchived || isEntityRateArchived
      ? {
          cellProps: {
            $disabled: true,
            ...defaultTooltipProps,
            'data-tip': 'Unarchive to edit.'
          },
          pencilIconColor: theme.colors.colorMediumGray9
        }
      : {
          cellProps: {
            onClick: handleEditRate
          },
          pencilIconColor: theme.colors.colorRoyalBlue
        };

  return (
    <Cell {...cellProps}>
      <EllipsisText>{billRate?.description}</EllipsisText>
      {billRate?.description && <EditIcon fill={pencilIconColor} />}
    </Cell>
  );
};

const Cell = styled.div.attrs<{ $disabled?: boolean }>(({ $disabled }) => ({
  className: cn({ disabled: $disabled })
}))<{ $disabled?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  height: 100%;

  &:not(.disabled):hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }

  &.disabled {
    cursor: not-allowed;
  }
`;
