import { createAction } from '@reduxjs/toolkit';

/* -------------------------- toggle remove overlay ------------------------- */

export const toggleRemoveOverlay = createAction<ToggleRemoveOverlayPayload>(
  'TOGGLE_REMOVE_OVERLAY'
);

interface ToggleRemoveOverlayPayload {
  showRemoveOverlay: boolean;
  accountIds: number[];
  willHardDeleteArchivedMember?: boolean;
  removeOverlayIsUnarchive?: boolean;
}
