import React from 'react';
import cn from 'classnames';
import CalendarIcon from 'icons/CalendarIcon';
import theme from 'theme';
import { connect } from 'react-redux';
import { getOnHomePlanner } from 'selectors';
const {
  colors: { colorDeleteRed }
} = theme;

const weekendHash = {
  Sat: true,
  Sun: true
};

const CustomHeader = ({
  title,
  isToday,
  unscheduled,
  index,
  onClose,
  pastScheduledTasksCount,
  toggleBulkUpdateModal,
  isOnHomePlanner
}) => {
  const formattedDayNum = title.format('DD');
  const formattedDayOfWeek = title.format('ddd');
  const weekend = weekendHash[formattedDayOfWeek];
  const month = title.format('MMM');

  return (
    <div
      className={cn('lane-header', {
        today: isToday,
        unscheduled,
        weekend,
        hide: index !== -1 && !unscheduled
      })}
    >
      {formattedDayNum === '01' && <div className="date-month">{month}</div>}
      <div className={cn('date-number', { unscheduled })}>
        {unscheduled ? 'Unscheduled' : formattedDayNum}
      </div>
      {!unscheduled && <div className="date-abbrev">{formattedDayOfWeek}</div>}
      {unscheduled && <div className="date-abbrev close" onClick={onClose} />}
      {pastScheduledTasksCount > 0 && isToday && (
        <div
          className="past-schedule-tasks-container"
          onClick={toggleBulkUpdateModal}
        >
          <CalendarIcon width={12} height={12} fill={colorDeleteRed} />
          <span className="count"> {pastScheduledTasksCount} </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isOnHomePlanner: getOnHomePlanner(state)
});

export default connect(mapStateToProps)(React.memo(CustomHeader));
