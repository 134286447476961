import * as constants from 'appConstants';
import produce from 'immer';
import keyBy from 'lodash/keyBy';
import _groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import mapKeys from 'lodash/mapKeys';
import flatten from 'lodash/flatten';
import { SortAttribute } from 'FilterModule/constants';
import { NO_GROUPING_GROUP_BYS } from 'views/unplanned/UnplannedTable/constants'; // defaults, but also scope request table specific

export const initialState = {
  // filter states keyed by a state id, see initialFilterState below
  filterStates: {},
  // scope data keyed by id
  scopeHash: {},
  // temporary uuids that get added on CREATE_SCOPE.TRIGGER, and cleared on CREATE_SCOPE.FAILURE/SUCCESS
  temporaryUuids: {},
  // cleared on any update action failure/success
  updatingIds: {},

  // projects/phases/activityPhases that have had their scopes fetched for.
  // added on fetch trigger only when all: true present
  projectsThatHaveFetchedScopes: {},
  phasesThatHaveFetchedScopes: {},
  activityPhasesThatHaveFetchedScopes: {},
  // ids are added on trigger only when all: true present, id param used (single id),
  // or on success of any fetch calls will not always match scopeHash
  fetchedScopeIds: {}
};

export const scopes = (state = initialState, action) => {
  const payload = action.payload;

  return produce(state, (nextState) => {
    switch (action.type) {
      case constants.LOGOUT_USER: {
        nextState = initialState;
        break;
      }

      /* ------------------------------ FETCH_SCOPES ------------------------------ */

      case constants.FETCH_SCOPES.TRIGGER: {
        const {
          filterStateId,
          all,
          projectIds = [],
          phaseIds = [],
          activityPhaseIds = [],
          ids = [],
          id,
          initial
        } = payload;

        if (filterStateId) {
          nextState.filterStates[filterStateId] = {
            ...(initial || !state.filterStates[filterStateId]
              ? initialFilterState
              : state.filterStates[filterStateId]),
            isFetching: true
          };
        }

        updateFetchedIds({
          all,
          id,
          projectIds,
          phaseIds,
          activityPhaseIds,
          ids,
          nextState
        });

        break;
      }

      case constants.FETCH_SCOPES.FAILURE: {
        const { filterStateId, id } = payload.requestPayload;
        if (filterStateId) {
          nextState.filterStates[filterStateId].isFetching = false;
        }

        if (id) {
          // for knowing that this specific scope could not be loaded. used by ScopeModalRouter
          nextState.scopeHash[id] = null;
        }
        break;
      }

      case constants.FETCH_SCOPES.SUCCESS: {
        const {
          filterStateId,
          id,
          ids,
          all,
          groupBy,
          getGroupId = defaultGetGroupId
        } = payload.requestPayload;
        const { scopes, total_count, group_count, group_keys } =
          payload.response;

        scopes.forEach((scope) => {
          // add scope data to hash
          nextState.scopeHash[scope.id] = scope;
          // flag scope as fetched
          nextState.fetchedScopeIds[scope.id] = true;
        });

        // set scopeHash values for ids that were not found to null
        if (id && !nextState.scopeHash[id]) {
          nextState.scopeHash[id] = null;
        }
        if (all && ids?.length && ids.length !== scopes.length) {
          ids.forEach((id) => {
            if (!nextState.scopeHash[id]) {
              nextState.scopeHash[id] = null;
            }
          });
        }

        if (filterStateId) {
          const nextFilterState = nextState.filterStates[filterStateId];
          nextFilterState.isFetching = false;
          nextFilterState.totalCount = total_count;
          const ordersByGroup = nextFilterState.ordersByGroup;

          // Convert null to string for consistency
          const formattedGroupKeys = group_keys.map((group) => ({
            ...group,
            group_key: group.group_key === null ? 'null' : group.group_key
          }));

          // BE sends back group_keys, which is array of { group_key, scopes }
          // for special cases when the same scope may be under multiple groups eg. when
          // grouping by assignees
          const useGroupKey = formattedGroupKeys.length > 0;

          if (groupBy) {
            if (group_count) {
              nextFilterState.groupTotalCounts = mapKeys(
                group_count,
                (_, key) => (key === '' ? null : key) // BE sends back '' for null group key. match to null
              );
            }

            if (useGroupKey) {
              // see note above for useGroupKey
              const newOrdersByGroup = formattedGroupKeys;
              // Append to each group's order
              newOrdersByGroup.forEach(({ group_key, project_scopes }) => {
                // 'scopes' here are scope ids
                ordersByGroup[group_key] = Array.from(
                  new Set([
                    ...(ordersByGroup[group_key] || []),
                    ...project_scopes
                  ])
                );
              });
            } else {
              // same as above, but we are creating our own groupings/group keys. Keeping the logic
              // separate for easier understanding

              // group the scopes by some group id
              const newOrdersByGroup = _groupBy(scopes, (scope) =>
                getGroupId(scope, groupBy)
              );
              // Append to each group's order
              Object.keys(newOrdersByGroup).forEach((groupId) => {
                ordersByGroup[groupId] = Array.from(
                  new Set([
                    ...(ordersByGroup[groupId] || []),
                    ...newOrdersByGroup[groupId].map((scope) => scope.id)
                  ])
                );
              });
              // special case for when grouped by project:
              // since group id is 'projectId-phaseId-activityId-activityPhaseId',
              // and BE sends group_count using activityPhaseId as key, update groupTotalCounts to match
              // (groupTotalKeys will also be keyed by 'projectId-phaseId-...')
              if (
                groupBy === SortAttribute.ActivityPhase &&
                getGroupId === defaultGetGroupId
              ) {
                const activityPhaseIdToGroupId = [
                  ...nextFilterState.topLevelOrder,
                  ...Object.keys(newOrdersByGroup)
                ].reduce((acc, groupId) => {
                  const [, , , activityPhaseId] = groupId.split('-');
                  acc[activityPhaseId] = groupId;
                  return acc;
                }, {});

                nextFilterState.groupTotalCounts = mapKeys(
                  nextFilterState.groupTotalCounts,
                  (_, activityPhaseId) => {
                    // we converted '' to null above, so covering that case since activityPhaseIdToGroupId will have the key '', not null
                    return activityPhaseIdToGroupId[
                      activityPhaseId === 'null' ? '' : activityPhaseId
                    ];
                  }
                );
              }

              // When grouped by approver, make groupTotalCounts keyed by 'approver-${accountId}'
              if (groupBy === SortAttribute.Approver) {
                nextFilterState.groupTotalCounts = mapKeys(
                  nextFilterState.groupTotalCounts,
                  (_, accountId) =>
                    accountId !== 'null' ? `approver-${accountId}` : 'null'
                );
              }
            }
          }

          // this will be an array of groupIds, or scopeIds when no groupBy
          nextFilterState.topLevelOrder = Array.from(
            new Set([
              ...nextFilterState.topLevelOrder,
              ...(useGroupKey
                ? formattedGroupKeys.map((groups) => groups.group_key)
                : scopes.map((scope) => getGroupId(scope, groupBy)))
            ])
          );

          updateTotalFetchedCount(nextFilterState, groupBy);
        }

        break;
      }

      /* ------------------------------ CREATE_SCOPE ------------------------------ */

      case constants.CREATE_SCOPE.TRIGGER: {
        const { uuid } = payload;
        if (uuid) {
          nextState.temporaryUuids[uuid] = true;
        }
        break;
      }

      case constants.CREATE_SCOPE.FAILURE: {
        const { uuid } = payload.requestPayload;
        delete nextState.temporaryUuids[uuid];
        break;
      }

      case constants.CREATE_SCOPE.SUCCESS: {
        const {
          uuid,
          filterStateId,
          groupBy,
          getGroupId = defaultGetGroupId
        } = payload.requestPayload;
        const { scope } = payload.response;

        delete nextState.temporaryUuids[uuid];
        nextState.scopeHash[scope.id] = scope;
        nextState.fetchedScopeIds[scope.id] = true;

        // update filterState if necessary, adding to orders, increasing counts, etc
        if (filterStateId && groupBy) {
          const nextFilterState =
            nextState.filterStates[filterStateId] || initialFilterState;

          const groupIds = []; // grouping by assignee can result in multiple group ids per scope
          if (groupBy !== SortAttribute.Assignee) {
            groupIds.push(getGroupId(scope, groupBy));
          } else {
            if (scope.assignees.length) {
              groupIds.push(...scope.assignees);
            } else {
              groupIds.push('null'); // for adding to No Assignee group
            }
          }

          const existingGroupIds = new Set(nextFilterState.topLevelOrder);

          // check if any of the group ids are new (not in topLevelOrder yet). If so, try to initialize them
          if (groupIds.some((groupId) => !existingGroupIds.has(groupId))) {
            // If a groupId (scopeId when no groupings) does not exist yet in topLevelOrder,
            // we can only add it if the list is already fully loaded (note: since we don't have
            // FE sort, the list could be out of order)
            if (
              nextFilterState.totalCount === nextFilterState.totalFetchedCount
            ) {
              groupIds.forEach((groupId) => {
                // initialize the group (or append scopeId when no groupings)
                if (!existingGroupIds.has(groupId)) {
                  nextFilterState.topLevelOrder = Array.from(
                    new Set([...nextFilterState.topLevelOrder, groupId])
                  );
                  if (!NO_GROUPING_GROUP_BYS[groupBy]) {
                    nextFilterState.ordersByGroup[groupId] = [];
                    nextFilterState.groupTotalCounts[groupId] = 0;
                  } else {
                    nextFilterState.totalFetchedCount++; // if no groupings, we added the scopeId to topLevelOrder
                  }
                }
              });
            } else {
              // if the list is not fully loaded, we do not know the actual offset of the created scope/group
              // so we have to reset the entire list
              triggerRefetchFilterState(nextState.filterStates, filterStateId);
              break;
            }
          }

          // update group if we can
          if (!NO_GROUPING_GROUP_BYS[groupBy]) {
            groupIds.forEach((groupId) => {
              // We can only add the scope to its group's order if the group is fully loaded, since we don't know
              // accurate offset. **This may still result in incorrect indices**
              const groupIsFullyLoaded =
                nextFilterState.ordersByGroup[groupId].length ===
                nextFilterState.groupTotalCounts[groupId];
              if (groupIsFullyLoaded) {
                nextFilterState.ordersByGroup[groupId] = Array.from(
                  new Set([
                    ...(nextFilterState.ordersByGroup[groupId] || []),
                    scope.id
                  ])
                );
                nextFilterState.totalFetchedCount++;
                nextFilterState.groupTotalCounts[groupId]++;
              } else {
                triggerRefetchGroup(nextFilterState, groupId, 1);
              }
            });
          }

          nextFilterState.totalCount++;
        }
        break;
      }

      /* ------------------------------ UPDATE_SCOPE ------------------------------ */

      case constants.UPDATE_SCOPE.TRIGGER: {
        const { id } = payload;
        if (id) {
          nextState.updatingIds[id] = true;
        }
        break;
      }

      case constants.UPDATE_SCOPE.FAILURE: {
        const { id } = payload.requestPayload;
        delete nextState.updatingIds[id];
        break;
      }

      case constants.UPDATE_SCOPE.SUCCESS: {
        const { filterStateId, id } = payload.requestPayload;
        const { scope: updatedScope } = payload.response;

        delete nextState.updatingIds[id];
        nextState.scopeHash[id] = updatedScope;

        if (filterStateId) {
          // todo similar to work plan requests
        }
        break;
      }

      /* ------------------------------ DELETE_SCOPE ------------------------------ */

      case constants.DELETE_SCOPE.TRIGGER: {
        const { id, ids } = payload;
        const scopeIds = id !== undefined ? [id] : ids;
        scopeIds.forEach((id) => {
          nextState.updatingIds[id] = true;
        });
        break;
      }

      case constants.DELETE_SCOPE.FAILURE: {
        const { id, ids } = payload.requestPayload;
        const scopeIds = id !== undefined ? [id] : ids;
        scopeIds.forEach((id) => {
          delete nextState.updatingIds[id];
        });
        break;
      }

      case constants.DELETE_SCOPE.SUCCESS: {
        const { filterStateId, parentGroupId, groupBy, id, ids } =
          payload.requestPayload;
        const scopeIds = id !== undefined ? [id] : ids;

        scopeIds.forEach((id) => {
          delete nextState.updatingIds[id];
          // set to null so we know it doesn't exist vs. has not been fetched
          nextState.scopeHash[id] = null;
        });

        if (filterStateId) {
          const nextFilterState = nextState.filterStates[filterStateId]; // filterState should exist if this action is being used
          const scopeIdsBeingRemoved = new Set(scopeIds);

          const getIsNotBeingDeleted = (scopeId) =>
            !scopeIdsBeingRemoved.has(scopeId);

          if (NO_GROUPING_GROUP_BYS[groupBy]) {
            // no grouping -> topLevelOrder contains the scope ids
            nextFilterState.topLevelOrder =
              nextFilterState.topLevelOrder.filter(getIsNotBeingDeleted);
          } else {
            const groupsToCheck =
              parentGroupId !== undefined // could be null
                ? [parentGroupId]
                : Object.keys(nextFilterState.ordersByGroup);

            groupsToCheck.forEach((groupId) => {
              nextFilterState.ordersByGroup[groupId] =
                nextFilterState.ordersByGroup[groupId].filter(
                  getIsNotBeingDeleted
                );
              const numRemoved =
                state.filterStates[filterStateId].ordersByGroup[groupId]
                  .length - nextFilterState.ordersByGroup[groupId].length; // original - post removal counts
              nextFilterState.groupTotalCounts[groupId] -= numRemoved;
              // Remove the parent group id if its order is empty now
              removeParentGroupIfNecessary(nextFilterState, groupId);
            });
          }

          nextFilterState.totalFetchedCount -= scopeIds.length;
          nextFilterState.totalCount -= scopeIds.length;
          updateSelectedState(nextFilterState, scopeIds, false);
        }

        break;
      }

      /* ------------------------------- MOVE_SCOPES ------------------------------ */

      case constants.MOVE_SCOPES.SUCCESS: {
        const { isCopy, id, ids, filterStateId, groupBy } =
          payload.requestPayload;

        const scopeIds = id !== undefined ? [id] : ids;

        if (!scopeIds.length) break;

        if (
          filterStateId &&
          groupBy &&
          (isCopy || groupBy === SortAttribute.ActivityPhase)
        ) {
          // Refetch the entire list. Could be improved in the future
          triggerRefetchFilterState(nextState.filterStates, filterStateId);
        }

        break;
      }

      /* --------------------------------- ASSIGN --------------------------------- */

      case constants.ASSIGN_SCOPE_MEMBERS.SUCCESS: {
        const { scopeId, members, filterStateId, groupBy } =
          payload.requestPayload;
        const scope = state.scopeHash[scopeId];

        // We only care about updating UI if grouped by assignees
        // when grouped by assignees, groupId is accountId or null
        if (scope && filterStateId && groupBy === SortAttribute.Assignee) {
          const nextFilterState = nextState.filterStates[filterStateId]; // should exist if this action is being used and filterStateId provided
          const memberGroups = _groupBy(members, (member) =>
            member.unassign ? 'unassign' : 'assign'
          );

          // Handle cases where we no longer have assignees, or have added assignees
          // to unassigned scope
          if (memberGroups.unassign) {
            // all assignees have been unassigned from the scope, so we want to add it
            // to null group
            const unassignedAccountIds = new Set(
              memberGroups.unassign.map(({ account_id }) => account_id)
            );
            const addToNullGroup = scope.assignees.every((assignee) =>
              unassignedAccountIds.has(assignee)
            );

            if (addToNullGroup) {
              if (!memberGroups.assign) {
                memberGroups.assign = [];
              }
              memberGroups.assign.push({ account_id: 'null' });
            }
          }

          if (memberGroups.assign) {
            // assignees have been added to a scope that had no assignees, so we want to remove it
            // from the null group
            const removeFromNullGroup =
              memberGroups.assign.length > 0 &&
              nextFilterState.ordersByGroup.null?.includes(scopeId);

            if (removeFromNullGroup) {
              if (!memberGroups.unassign) {
                memberGroups.unassign = [];
              }
              memberGroups.unassign.push({ account_id: 'null' });
            }
          }

          // ASSIGN TO SCOPE -------------------------------

          // if any of the target assignees (groups) do not exist, reset the entire list
          // since we don't know their actual offset
          const existingAssignees = new Set(nextFilterState.topLevelOrder);
          if (
            memberGroups.assign?.some(
              ({ account_id }) =>
                !existingAssignees.has(
                  account_id === 'null' ? account_id : `account-${account_id}`
                )
            )
          ) {
            triggerRefetchFilterState(nextState.filterStates, filterStateId);
            break;
          }

          // otherwise, reset groups individually
          memberGroups.assign?.forEach(({ account_id }) => {
            const groupId = `account-${account_id}`;
            if (groupId in nextFilterState.ordersByGroup) {
              // we don't know the actual offset of the scope, so naive solution is to
              // force the group to refetch by resetting it
              nextFilterState.groupTotalCounts[groupId]++; // we know accurate total count
              nextFilterState.totalCount++;
              nextFilterState.totalFetchedCount =
                nextFilterState.totalFetchedCount -
                nextFilterState.ordersByGroup[groupId].length;
              nextFilterState.ordersByGroup[groupId] = [];
            }
          });

          // UNASSIGN FROM SCOPE -------------------------------
          memberGroups.unassign?.forEach(({ account_id }) => {
            const groupId = `account-${account_id}`;
            if (groupId in nextFilterState.ordersByGroup) {
              // remove the scopeId from this account's order since it is no longer assigned to the account
              if (nextFilterState.ordersByGroup[groupId].includes(scopeId)) {
                nextFilterState.ordersByGroup[groupId] =
                  nextFilterState.ordersByGroup[groupId].filter(
                    (id) => id !== scopeId
                  );
                nextFilterState.groupTotalCounts[groupId]--;
                nextFilterState.totalFetchedCount--;
                nextFilterState.totalCount--;
                removeParentGroupIfNecessary(nextFilterState, groupId);
              }
            }
          });
        }
        break;
      }

      // UNASSIGN_SCOPE_MEMBERS
      // UPDATE_SCOPE_MEMBERSHIP

      /* --------------------------------- FE only -------------------------------- */

      /* -------------------- Selection (filterState necessary) ------------------- */

      case constants.SELECT_SCOPES: {
        const { filterStateId, id, ids, isSelect } = payload;
        if (filterStateId) {
          const nextFilterState = nextState.filterStates[filterStateId]; // should exist if this action is being used
          const idsToUse = id ? [id] : ids;
          updateSelectedState(nextFilterState, idsToUse, isSelect);
        }
        break;
      }

      case constants.CLEAR_SELECTED_SCOPES: {
        const { filterStateId } = payload;
        if (filterStateId) {
          const nextFilterState = nextState.filterStates[filterStateId]; // should exist if this action is being used
          nextFilterState.selectedIds = {};
          nextFilterState.numSelected = 0;
        }
        break;
      }

      case constants.SELECT_ALL_SCOPES: {
        // For when topLevelOrder is [scopeId]
        const { filterStateId } = payload;
        if (filterStateId) {
          const nextFilterState = nextState.filterStates[filterStateId]; // should exist if this action is being used
          nextFilterState.selectedIds = keyBy(
            nextFilterState.topLevelOrder,
            (scopeId) => scopeId
          );
          nextFilterState.numSelected = nextFilterState.topLevelOrder.length;
        }
        break;
      }

      case constants.SELECT_ALL_GROUPS_SCOPES: {
        // For when topLevelOrder is [groupId], will add all ordersByGroup scope Ids to selectedIds
        const { filterStateId } = payload;
        if (filterStateId) {
          const nextFilterState = nextState.filterStates[filterStateId]; // should exist if this action is being used
          const allScopeIdsFromEveryGroup =
            getAllScopeIdsFromEveryGroup(nextFilterState);

          nextFilterState.selectedIds = keyBy(
            allScopeIdsFromEveryGroup,
            (scopeId) => scopeId
          );
          nextFilterState.numSelected = allScopeIdsFromEveryGroup.length;
        }
        break;
      }

      /* ------------------------- DELETE_SCOPE_ATTACHMENT ------------------------ */

      case constants.DELETE_SCOPE_ATTACHMENT.TRIGGER: {
        // optimistic update. success or failure will both refetch the scope
        const { attachmentId, id } = payload;
        const scope = state.scopeHash[id];
        if (scope) {
          // scope should exist in hash if this action is being called, but check anyway
          nextState.scopeHash[id].attachments = scope.attachments.filter(
            (attachment) => attachment.id !== attachmentId
          );
        }
        break;
      }
    }
  });
};

export default scopes;

/* ------------------------------------ - ----------------------------------- */

/**
 * use a filterState as necessary eg. when maintaining some list of scopes that is based on
 * specific api params and has pagination
 */
export const initialFilterState = {
  //    eg. { projectId: [scopeIds]}
  //    eg. from workloadPlanner reducer
  //    when there is more nesting (grouped by date then member)
  //   {
  //     'july': ['july-5319', 'july-5312'],
  //     'july-5319': [workplanIds],
  //     'july-5312': [workplanIds]
  //   }
  ordersByGroup: {},
  // eg. ['july', 'aug'] - could also just be [scopeIds] when no groupings
  topLevelOrder: [],
  isFetching: false,
  totalCount: 0,
  totalFetchedCount: 0,
  // total number of scopes per group
  groupTotalCounts: {},
  selectedIds: {},
  numSelected: 0,
  shouldReset: false // for triggering an initial fetch
};

/**
 * updates
 *  state.projectsThatHaveFetchedScopes,
 *  phasesThatHaveFetchedScopes,
 *  activityPhasesThatHaveFetchedScopes &&
 *  fetchedScopeIds
 */
const updateFetchedIds = ({
  all,
  id,
  projectIds,
  phaseIds,
  activityPhaseIds,
  ids,
  nextState
}) => {
  if (id) {
    nextState.fetchedScopeIds[id] = true;
  }
  if (all) {
    projectIds.forEach((projectId) => {
      nextState.projectsThatHaveFetchedScopes[projectId] = true;
    });
    phaseIds.forEach((phaseId) => {
      nextState.phasesThatHaveFetchedScopes[phaseId] = true;
    });
    activityPhaseIds.forEach((activityPhaseId) => {
      nextState.activityPhasesThatHaveFetchedScopes[activityPhaseId] = true;
    });
    ids.forEach((scopeId) => {
      nextState.fetchedScopeIds[scopeId] = true;
    });
  }
};

const updateSelectedState = (nextFilterState, ids, isSelect = true) => {
  if (isSelect) {
    nextFilterState.selectedIds = {
      ...nextFilterState.selectedIds,
      ...ids.reduce((acc, id) => {
        acc[id] = id;
        return acc;
      }, {})
    };
  } else {
    // the ids may or may not be selected.
    // performing the work here vs. checking first doesn't make much difference
    nextFilterState.selectedIds = omit(nextFilterState.selectedIds, ids);
  }
  nextFilterState.numSelected = Object.keys(nextFilterState.selectedIds).length;
};

const removeParentGroupIfNecessary = (nextFilterState, parentGroupId) => {
  if (nextFilterState.groupTotalCounts[parentGroupId] === 0) {
    nextFilterState.topLevelOrder = nextFilterState.topLevelOrder.filter(
      (groupId) => groupId !== parentGroupId
    );
    delete nextFilterState.groupTotalCounts[parentGroupId];
    delete nextFilterState.ordersByGroup[parentGroupId];
  }
};

/**
 * When there are groupings, total fetched count is the sum of counts of every group's order
 * When no groupings, is topLevelOrder.length
 * Remember to use after nextFilterState has updated orders
 */
const updateTotalFetchedCount = (nextFilterState, groupBy) => {
  if (!groupBy || NO_GROUPING_GROUP_BYS[groupBy]) {
    nextFilterState.totalFetchedCount = nextFilterState.topLevelOrder.length;
  } else {
    const allScopeIdsFromEveryGroup =
      getAllScopeIdsFromEveryGroup(nextFilterState);
    nextFilterState.totalFetchedCount = allScopeIdsFromEveryGroup.length;
  }
};

/**
 * Reset to initialFilterState and set flag to trigger refetch
 */
const triggerRefetchFilterState = (nextFilterStates, filterStateId) => {
  nextFilterStates[filterStateId] = {
    ...initialFilterState,
    shouldReset: true
  };
};

/**
 * When adding scopes to groups that are not fully loaded, since we don't know their correct offset,
 * reset their ordersByGroup to trigger refetch. groupTotalCounts can be accurately updated
 */
const triggerRefetchGroup = (
  nextFilterState,
  groupId,
  numToAddToTotalCount = 0
) => {
  nextFilterState.groupTotalCounts[groupId] += numToAddToTotalCount;
  nextFilterState.ordersByGroup[groupId] = [];
};

const getAllScopeIdsFromEveryGroup = (nextFilterState) => [
  ...new Set(Object.values(nextFilterState.ordersByGroup).flat())
];

// used by scope request table
export const defaultGetGroupId = (scope, groupBy) => {
  switch (groupBy) {
    case SortAttribute.Approver: {
      const approver = scope.project_scope_request_info.approver_id;
      return approver
        ? `approver-${scope.project_scope_request_info.approver_id}`
        : 'null';
    }

    // `projectId-phaseId-activityId-activityPhaseId`
    case SortAttribute.ActivityPhase:
      return `${scope.project_id || ''}-${scope.phase_id || ''}-${
        scope.activity_id || ''
      }-${scope.activity_phase_id || ''}`;

    case SortAttribute.CreatedDate:
      return scope.id;

    // only used when creating target parent group ids. otherwise BE response group_key is used for this groupBy (see fetch success)
    // this is the only case that returns an array of group Ids **
    case SortAttribute.Assignee:
      return scope.assignees;

    default:
      return scope[groupBy || 'id'];
  }
};
