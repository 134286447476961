import { CustomMarker } from 'react-calendar-timeline';
import moment from 'moment';
import theme from 'theme';

const CustomTodayMarker = () => (
  <CustomMarker date={moment().startOf('day').valueOf()}>
    {({ styles, date }) => (
      <div
        className={'today-marker-custom'}
        style={{
          ...styles,
          left: '0px',
          width: styles.left,
          zIndex: '40',
          background: 'rgba(255,255,255,0.3)',
          borderRight: `2px solid ${theme.colors.colorCalendarOrange}`
        }}
      />
    )}
  </CustomMarker>
);

export default CustomTodayMarker;
