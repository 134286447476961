import { ReactNode } from 'react';
import { Collapse } from 'reactstrap';
import { FeatureContainer, FeatureHeader, StyledKaratRight } from './styles';

interface MosaicCollapseProps {
  header: ReactNode;
  isWhite?: boolean;
  isOpen?: boolean;
  toggleOpen: () => void;
  headerStyle?: string;
  headerContainerStyle?: string;
  children: ReactNode;
  showKarat?: ReactNode;
}

const MosaicCollapse = ({
  header,
  children,
  isOpen = false,
  toggleOpen,
  isWhite,
  headerStyle,
  headerContainerStyle,
  showKarat = true
}: MosaicCollapseProps) => {
  return (
    <FeatureContainer
      isOpen={isOpen}
      headerContainerStyle={headerContainerStyle}
    >
      <FeatureHeader
        isWhite={isWhite || isOpen}
        onClick={toggleOpen}
        headerStyle={headerStyle}
      >
        {showKarat && <StyledKaratRight isOpen={isOpen} />}
        {header}
      </FeatureHeader>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </FeatureContainer>
  );
};
export default MosaicCollapse;
