import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { triggerCSVImportDataLoad, clearCSVImportData } from 'actionCreators';

const LoadImportDataButton = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.csvImport.loading);
  const errors = useSelector((state) => state.csvImport.errors);
  const loadData = useCallback(() => {
    dispatch(triggerCSVImportDataLoad());
  }, [dispatch]);
  const clearData = () => {
    dispatch(clearCSVImportData());
  };
  useEffect(() => {
    loadData();
  }, [loadData]);

  if (errors.length) {
    return (
      <div onClick={clearData}>
        {errors.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </div>
    );
  }
  return (
    <div disabled={loading} onClick={loadData}>
      {loading ? '' : 'Retry Data Preload'}
    </div>
  );
};
export default LoadImportDataButton;
