import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'moment';

import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import {
  DateInputDiv,
  DateDiv,
  DateLabelContainer,
  DateDash,
  DateProgress
} from '../styles';
import { getRemainingWorkDays } from 'appUtils/phaseDisplayUtils';

const PhaseUserActivityStartDateCell = (props) => {
  const { userActivity, phase } = props.row.original;

  const totalWorkDays = userActivity.display_data.total_work_days;
  const remainingWorkDays = getRemainingWorkDays(phase.phase);

  const currentProgressPercent =
    ((totalWorkDays - remainingWorkDays) / totalWorkDays) * 100 || 0;

  return (
    <div className="milestone-date-range">
      {/* {totalWorkDays} - {remainingWorkDays} */}
      <DateInputDiv isPhase={true} noHover>
        <DateRangeCalendar
          showInputs
          itemStartDate={userActivity.display_data.start_date}
          itemEndDate={userActivity.display_data.end_date}
          customInput={(startDate, endDate) => (
            <DateLabelContainer>
              <DateDiv>
                <span>
                  {startDate
                    ? moment(startDate).format('M/D/YY')
                    : 'Start Date'}
                </span>
              </DateDiv>
              <DateDash>
                <span>-</span>
              </DateDash>
              <DateDiv>
                <span>
                  {endDate ? moment(endDate).format('M/D/YY') : 'End Date'}
                </span>
              </DateDiv>
            </DateLabelContainer>
          )}
          shouldRenderFooter
          itemWorkDays={totalWorkDays}
        />
        {/* <DateProgress currentProgress={currentProgressPercent} /> */}
      </DateInputDiv>
    </div>
  );
};

export default PhaseUserActivityStartDateCell;
