import * as constants from 'appConstants';

export const initialState = {
  actionDenied: null,
  errorMessage: null,
  isPermissionError: null,
  isFeError: null,
  isUserFriendlyError: null,
  isRefreshRequiredError: null,
  allPermissions: [],
  roles: {},
  roleTemplates: {},
  oneOffPermissions: {},
  userPermissionsLoaded: false
};

const userPermissions = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.DENY_PERMISSION: {
      return {
        ...state,
        actionDenied: payload.type,
        errorMessage: payload.errorMessage,
        isPermissionError: true
      };
    }
    case constants.HANDLE_ERROR_MESSAGE: {
      if (state.isRefreshRequiredError) return state; // major errors that should not be overwritten
      return {
        ...state,
        actionDenied: payload.type,
        errorMessage: payload.errorMessage,
        isRefreshRequiredError: payload.isRefreshRequiredError || false,
        isFeError: payload.isFeError || false,
        isUserFriendlyError: payload.isUserFriendlyError || false,
        isPermissionError: false
      };
    }
    case constants.CLEAR_DENY_PERMISSION: {
      return {
        ...state,
        actionDenied: null,
        errorMessage: null,
        isRefreshRequiredError: null,
        isFeError: null,
        isUserFriendlyError: null,
        isPermissionError: null
      };
    }
    case constants.FETCH_USER_PERMISSIONS.SUCCESS: {
      const {
        all_permissions,
        roles,
        team_role_templates,
        one_off_permissions
      } = payload.response;
      return {
        ...state,
        allPermissions: all_permissions,
        roles,
        roleTemplates: team_role_templates,
        oneOffPermissions: one_off_permissions,
        userPermissionsLoaded: true
      };
    }
    case constants.WS_TEAM_MEMBER: {
      const { userId, permission, role, deleted } = payload;
      if (!permission || !role) {
        return state;
      }
      const filteredPermissions = state.allPermissions.filter(
        (perm) => perm.id !== permission.id
      );
      const allPermissions = deleted
        ? filteredPermissions
        : [permission, ...filteredPermissions];

      const roles = {
        ...state.roles,
        [role.id]: role
      };
      return {
        ...state,
        allPermissions,
        roles
      };
    }
    default: {
      return state;
    }
  }
};

export default userPermissions;
