import theme from 'theme';
import styled from 'styled-components';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';

interface PhaseInfoProps {
  phaseName: string;
}
export const PhaseInfo = ({ phaseName }: PhaseInfoProps) => (
  <PhaseInfoContainer>
    <StyledPhaseMilestoneIcon height={14} width={14} strokeWidth={1} />
    <Title>{phaseName}</Title>
  </PhaseInfoContainer>
);

const PhaseInfoContainer = styled.div`
  display: flex;
  gap: 2px;
  overflow: hidden;
`;

const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon).attrs({
  strokeColor: theme.colors.colorSemiDarkGray1,
  fillColor: 'transparent'
})`
  flex: none;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
