import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import styled from 'styled-components';
import { setWorkloadEventRange } from 'actionCreators';
import { StyledDates } from 'ReportsModule/components/styles';
import moment from 'appUtils/momentConfig';

const DateRangeContainer = styled.div`
  cursor: default;

  ${StyledDates} {
    font-weight: 600;
    font-size: 14px;

    cursor: pointer;
  }
`;

const MemberHeader = () => {
  const dispatch = useDispatch();
  const workloadRangeStart = useSelector(
    (state) => state.workloadEvents.workloadRangeStart
  );
  const [start, setStart] = useState(
    workloadRangeStart || moment().startOf('week')
  );
  const end = useMemo(() => start.clone().endOf('week'), [start]);

  const handleTimeChange = ({ startDate, endDate }) => {
    dispatch(
      setWorkloadEventRange({
        start: startDate,
        end: endDate || startDate
      })
    );
    setStart(startDate);
  };

  return (
    <DateRangeContainer>
      <DateRangeCalendar
        itemStartDate={start}
        itemEndDate={end}
        isWeekly
        onSubmit={handleTimeChange}
      />
    </DateRangeContainer>
  );
};

export default MemberHeader;
