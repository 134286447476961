import styled from 'styled-components';
import theme from 'theme';
import { makeShowHiddenItemsRow } from '../tableConfigs';

export const ShowHiddenItemsCell = ({
  row
}: {
  row: {
    original: ReturnType<typeof makeShowHiddenItemsRow>;
  };
}) => {
  const { numHidden } = row.original;

  return (
    <StyledShowdHiddenItemsCell>
      {numHidden} Hidden by other Filters
    </StyledShowdHiddenItemsCell>
  );
};

// -----------------------------------------------------------------------------

const StyledShowdHiddenItemsCell = styled.div<{
  isShowingArchived?: boolean;
}>`
  height: 100%;
  color: ${theme.colors.colorCalendarGray};
  font-size: 11px;
  display: flex;
  align-items: center;
`;
