import React, { useRef } from 'react';
import theme from 'theme';
import { ListHeader } from '../../styles';
import styled from 'styled-components';
import DepartmentHeaderContent from './DepartmentHeaderContent';
import { TaskGroupHeaderContainer } from 'views/Home/TaskList/styles';
import { StyledTaskGroupName } from 'views/Home/TaskList/GroupHeader';
import {
  IconsContainer,
  CollapseIconContainer
} from 'views/Home/TaskList/styles.js';

const ArchivedText = styled.span`
  position: absolute;
  bottom: 0px;
  left: 29px;
  font-size: 10px;
  color: ${theme.colors.colorLightGray10};
`;

export const StyledDepartmentHeaderCell = styled.div`
  padding: 4px 0px;
  font-size: 16px;
  color: ${theme.colors.colorCalendarGray};
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  height: 100%;
  ${(props) =>
    props.isStickyHeader &&
    `
    ${ArchivedText} {
      left: 83px;
    }
  `}

  ${TaskGroupHeaderContainer} {
    position: relative;
    right: 33px;
    width: calc(100% + 33px);
  }
  ${StyledTaskGroupName} {
    position: relative;
    left: -10px;
    font-size: 15px;
    color: ${theme.colors.colorSemiDarkGray6};
    padding-left: 0;
  }
  ${IconsContainer} {
    margin-left: 0;
  }
  ${CollapseIconContainer} {
    margin-right: 0;
  }
  .three-dot-menu-wrapper {
    transform: rotate(90deg);
    left: -3px;
    svg {
      height: 22px;
    }
  }
  .group-header-text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const DepartmentHeaderCell = ({ row }) => {
  const addMemberDropdownRef = useRef(null);
  return (
    <StyledDepartmentHeaderCell
      ref={addMemberDropdownRef}
      isStickyHeader={row?.original?.isStickyHeader}
    >
      <DepartmentHeaderContent
        group={row?.original}
        dropdownTarget={addMemberDropdownRef}
      />
      {row?.original?.discarded_at && <ArchivedText>Archived</ArchivedText>}
    </StyledDepartmentHeaderCell>
  );
};

export default DepartmentHeaderCell;
