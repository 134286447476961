import { ReactNode } from 'react';
import styled from 'styled-components';
import { Scope } from 'models/scope';
import { ScopeCard } from 'views/projectPlanner/workloadBarModal/shared/ScopeCard';

interface ScopeListProps {
  scopes: Array<Scope>;
  onClickScope?: (scope: Scope) => void;
  renderTrailing?: (scope: Scope) => ReactNode;
}

export const ScopeList = ({
  scopes,
  onClickScope,
  renderTrailing
}: ScopeListProps) => {
  const handleClickScope = (scope: Scope) => () => {
    onClickScope && onClickScope(scope);
  };

  return (
    <ScopeListContainer>
      {scopes.map((scope) => (
        <ScopeCardContainer key={scope.id}>
          <ScopeCard onClick={handleClickScope(scope)} scope={scope} />
          {renderTrailing && renderTrailing(scope)}
        </ScopeCardContainer>
      ))}
    </ScopeListContainer>
  );
};

const ScopeListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ScopeCardContainer = styled.div`
  flex: 1;
`;
