/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState
} from 'react';
import { connect } from 'react-redux';
import {
  getSelectedTeamId,
  getTeamMembers,
  getSkillsCount,
  getAreSkillsLoaded
} from 'selectors';
import {
  getSkillColumnHeaders,
  makeGetFormattedSkillsWithMembers
} from 'SettingsModule/selectors';
import SkillsTableOptions from './SkillsTableOptions';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import Table from 'components/Table';
import { StyledSkillsTable, StyledSeparator } from './styles';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import HeaderCell from 'SettingsModule/components/Budget/HeaderCell';
import StickyHeader from 'components/Table/TableStickyHeader';

import { SORT_BY } from 'appConstants/filters';
import { VIEW_BY } from 'appConstants/workload';
import * as MemberRowCells from './MemberRowCells';
import * as HeaderRowCells from './HeaderRowCells';
import { serializeBar, deserializeBar } from 'appUtils/projectPlannerUtils';

const emptyRow = { rowType: 'emptyRow', isDragDisabled: true, rowHeight: 35 };

const buildHeader = ({ list, customRowProps }) => {
  const {
    id,
    uid,
    headerType,
    isOpen,
    headerHeight,
    renderHeader,
    headerData
  } = list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    renderHeader,
    customRowProps,
    isDragDisabled: list.isDragDisabled,
    draggableId: uid
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: item.rowHeight || list.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps,
  isDragDisabled:
    typeof item === 'object' && 'isDragDisabled' in item
      ? item.isDragDisabled
      : list.isDragDisabled,
  draggableId: item.uid
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps,
  isDragDisabled: true
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true,
      headerType
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      listItems.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
      if (!isFullyLoaded) {
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }
  if (lists.every((list) => list.isFullyLoaded || !list.isOpen)) {
    flatList.push(
      buildCustomItem({
        list: lists[lists.length - 1] || { getItemId: (item) => item?.id },
        customRowProps,
        item: emptyRow
      })
    );
  }
  return flatList;
};

const EmptyDiv = () => <div />;
const SeparatorDiv = ({ isStickyHeader, row }) => (
  <StyledSeparator isStickyHeader={isStickyHeader}>
    <span>{row?.original?.list?.renderHeader()}</span>
  </StyledSeparator>
);

const headerRowCells = {
  collapse: EmptyDiv,
  [SORT_BY.member]: HeaderRowCells.MemberCell,
  [SORT_BY.email]: HeaderCell,
  [SORT_BY.skillLevel]: HeaderCell,
  padding: EmptyDiv
};

const memberRowCells = {
  [SORT_BY.member]: MemberRowCells.MemberCell,
  [SORT_BY.email]: MemberRowCells.EmailCell,
  [SORT_BY.skillLevel]: MemberRowCells.SkillCell,
  separatorRow: SeparatorDiv
};

const separatorCells = {
  collapse: EmptyDiv,
  [SORT_BY.member]: SeparatorDiv,
  [SORT_BY.email]: EmptyDiv,
  [SORT_BY.skillLevel]: EmptyDiv,
  padding: EmptyDiv
};

const columnWidths = {
  collapse: 30,
  [SORT_BY.member]: 289,
  [SORT_BY.email]: 300,
  [SORT_BY.skillLevel]: 200,
  padding: 30
};

const ITEM_HEIGHT = 62;
const HEADER_FOOTER_HEIGHT = 45;

const noop = () => {};

const SkillsTable = ({
  loadMoreItems,
  formattedSkills,
  teamId,
  columnHeaders,
  totalMembersCount,
  skillsCount,
  skillsLoaded
}) => {
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false);
  const [stateIsScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const currentDragItem = useRef(null);
  const isLoadingInitial = totalMembersCount === null;

  const columns = useMemo(() => {
    return columnHeaders.map((skillsColumnHeader, index) => ({
      showAdd: !index,
      ...skillsColumnHeader,
      emptyRow: EmptyDiv,
      memberRow: memberRowCells[skillsColumnHeader.headerType] || EmptyDiv,
      memberRowArchived:
        memberRowCells[skillsColumnHeader.headerType] || EmptyDiv,
      separatorRow: separatorCells[skillsColumnHeader.headerType] || EmptyDiv,
      headerRow: headerRowCells[skillsColumnHeader.headerType] || EmptyDiv,
      headerRowArchived:
        headerRowCells[skillsColumnHeader.headerType] || EmptyDiv,
      Header: headerRowCells[skillsColumnHeader?.headerType] || EmptyDiv,
      width: columnWidths[skillsColumnHeader.headerType]
    }));
  }, [columnHeaders]);

  const memberTypeGroupedRowBuilder = useCallback(
    ({ formattedSkills }) => {
      const skillOrder = formattedSkills.map((skill) => skill.id);
      const skillLists = formattedSkills.reduce((acc, cur, index) => {
        const groupIsOpen = allCollapsed
          ? false
          : isOpen[cur.id] === undefined || isOpen[cur.id];
        acc[cur.id] = {
          listItems:
            [...cur.formattedSkillMembers].map((member) => ({
              ...member,
              skillMembership: cur.skillMembersHash[member?.account.id],
              uid: serializeBar({
                itemId: member?.id,
                itemType: 'member'
              }),
              isDragDisabled: true
            })) || [],
          closedItems: [],
          customItems: [],
          headerData: { ...cur, collapsed: !groupIsOpen },
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: cur.archived ? 'memberRowArchived' : 'memberRow',
          headerType: cur.archived ? 'headerRowArchived' : 'headerRow',
          headerHeight: HEADER_FOOTER_HEIGHT,
          id: cur.id,
          skipEmptyGroups: false,
          isOpen: groupIsOpen && cur.formattedSkillMembers.length > 0, // 'false' if empty list
          isArchived: cur.archived,
          isEmpty: false,
          isFullyLoaded: true,
          renderHeader: () => cur.name,
          summaryNoun: 'member',
          createRowData: {},
          renderSummaryItem: (item) => item,
          uid: serializeBar({
            itemId: cur.id,
            itemType: 'skill'
          }),
          isDragDisabled: cur.archived
        };
        return acc;
      }, {});

      const archivedSeparator = {
        listItems: [],
        closedItems: [],
        customItems: [],
        headerData: {},
        rowType: 'memberRow',
        headerType: 'separatorRow',
        headerHeight: ITEM_HEIGHT,
        id: 'archived_separator',
        skipEmptyGroups: false,
        isOpen: false,
        isEmpty: false,
        isFullyLoaded: true,
        renderHeader: () => 'Archived Skills',
        summaryNoun: 'member',
        createRowData: {},
        isDragDisabled: true
      };

      const currentSkills = [];
      const archivedSkills = [];

      skillOrder.forEach((id) => {
        const list = skillLists[id];

        if (list) {
          const listToPush = list.isArchived ? archivedSkills : currentSkills;
          listToPush.push(list);

          if (list?.isOpen) {
            list.listItems.push(emptyRow);
          }
        }
      });

      const listsInOrder = [
        ...currentSkills,
        ...(!currentDragItem.current && archivedSkills.length
          ? [archivedSeparator, ...archivedSkills]
          : [])
      ];
      return listsInOrder;
    },
    [allCollapsed, isOpen]
  );

  const listBuilders = useMemo(
    () => ({
      [VIEW_BY.MEMBER_TYPES]: memberTypeGroupedRowBuilder
    }),
    [memberTypeGroupedRowBuilder]
  );

  const lists = useMemo(() => {
    const listBuilder = listBuilders[VIEW_BY.MEMBER_TYPES];
    if (!listBuilder) {
      return [];
    }
    return listBuilder({
      formattedSkills,
      customRowProps: {}
    });
  }, [formattedSkills, listBuilders]);

  /* ----------------------------- Collapse logic ----------------------------- */

  const handleSetIsOpen = useCallback(
    ({ name: id, value }) => {
      const nextIsOpen = {
        ...isOpen,
        [id]: value
      };

      const getIsAllCollapsed = () => {
        return lists.every((list) => {
          if (['headerRowArchived', 'headerRow'].includes(list.headerType)) {
            return !list.isOpen || list.id === id;
          }
          return true; // lists without items are not open by default
        });
      };

      if (!value) {
        if (getIsAllCollapsed()) {
          setAllCollapsed(true);
        }
      } else if (allCollapsed) {
        setAllCollapsed(false);
      }
      listRef.current?.resetAfterIndex(0);
      setIsOpen(nextIsOpen);
    },
    [allCollapsed, isOpen, lists]
  );

  const handleCollapseAll = useCallback(() => {
    setIsOpen(
      formattedSkills.reduce((acc, cur) => {
        acc[cur.id] = false;
        return acc;
      }, {})
    );
    setAllCollapsed(true);
  }, [formattedSkills]);

  const handleExpandAll = useCallback(() => {
    setIsOpen({});
    setAllCollapsed(false);
  }, []);

  const toggleCollapseAll = useCallback(() => {
    if (allCollapsed) {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
  }, [allCollapsed, handleCollapseAll, handleExpandAll]);

  /* ------------------------------------ - ----------------------------------- */

  const scrollToTop = useCallback(() => {
    listRef.current.scrollToItem(0);
  }, []);

  const handleResetHeightCache = useCallback(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  const customRowProps = useMemo(
    () => ({
      handleSetIsOpen,
      isOpen,
      allCollapsed,
      currentDragItem
    }),
    [allCollapsed, handleSetIsOpen, isOpen]
  );

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps,
      engaged: {},
      search: {},
      emptyRow
    });
  }, [customRowProps, lists]);

  useEffect(() => {
    handleResetHeightCache();
  }, [handleResetHeightCache, rows]);

  const firstListId = useMemo(() => lists[0]?.id, [lists]);

  var resetOnClose = useCallback(
    ({ value, uid }) => {
      if (!value) {
        const index = rows.findIndex((row) => row.uid === uid);
        if (index !== -1) {
          listRef.current.scrollToItem(index - 1);
        }
      }
    },
    [rows]
  );

  /* ----------------------------------- DND ---------------------------------- */

  const onBeforeCapture = useCallback(
    ({ draggableId }) => {
      const { itemType, itemId } = deserializeBar(draggableId);
      if (!currentDragItem.current) {
        currentDragItem.current = +itemId;
      }
      if (itemType === 'skill') {
        // listRef.current?.scrollToItem(
        //   rows.findIndex(item => item.draggableId === draggableId)
        // );
        handleCollapseAll();
      }
      setIsDragging(true);
    },
    [handleCollapseAll]
  );

  const onDragEnd = useCallback(
    ({ draggableId, destination, source }) => {
      currentDragItem.current = null;
      setIsDragging(false);
      handleExpandAll();

      if (!destination) {
        return;
      }

      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;
      const { itemId, itemType } = deserializeBar(draggableId);
    },
    [handleExpandAll]
  );

  /* ------------------------------------ - ----------------------------------- */

  const isLoading = !skillsLoaded;
  const getItemSize = useCallback(
    (index) => rows[index]?.itemHeight || ITEM_HEIGHT,
    [rows]
  );

  const handleScroll = ({ visibleStartIndex }) => {
    const isScrolled = visibleStartIndex !== 0;
    if (!isScrolled && !currentDragItem.current) {
      setActiveSection(null);
      setIsScrolled(false);
      return;
    }
    const item = rows[visibleStartIndex];
    if (!item) {
      return;
    }
    if (item.listType !== activeSection && !currentDragItem.current) {
      setActiveSection(item.list.isOpen ? item.listType : null);
    }
    if (isScrolled !== stateIsScrolled && !currentDragItem.current) {
      setIsScrolled(isScrolled);
    }
  };

  const activeList = lists.find((list) => list.id === activeSection);
  const headerItem =
    activeList && buildHeader({ list: activeList, customRowProps });

  const stickyRow = {
    customRowProps,
    list: { listItems: [] },
    isStickyHeader: true,
    ...headerItem
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <SkillsTableOptions
        toggleCollapseAll={toggleCollapseAll}
        allCollapsed={allCollapsed}
        scrollToTop={scrollToTop}
      />
      {isLoading && (
        <SkeletonLoader numLoaders={5} loaderStyle={{ height: 70, rx: 4 }} />
      )}
      <StyledSkillsTable>
        {activeList && (
          <Droppable droppableId="table-header">
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef}>
                <Draggable
                  index={0}
                  draggableId={serializeBar({
                    itemId: activeList.id,
                    itemType: 'skill'
                  })}
                  key={serializeBar({
                    itemId: activeList.id,
                    itemType: 'skill'
                  })}
                  isDragDisabled={true}
                >
                  {(draggableProvided, snapshot) => (
                    <StickyHeader
                      row={{
                        original: stickyRow
                      }}
                      columnWidths={columnWidths}
                      totalWidth={900}
                      cells={
                        stickyRow.rowType === 'separatorRow'
                          ? separatorCells
                          : headerRowCells
                      }
                      columns={columnHeaders}
                      provided={draggableProvided}
                      isHidden={
                        (currentDragItem.current &&
                          currentDragItem.current !== activeSection) ||
                        snapshot.isDragging
                      }
                    />
                  )}
                </Draggable>
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        <Table
          columns={columns}
          data={!isLoading ? rows : []}
          onDragEnd={noop}
          virtual
          itemHeight={ITEM_HEIGHT}
          getItemSize={getItemSize}
          handleScroll={handleScroll}
          maxHeight={window.innerHeight}
          loadMoreItems={loadMoreItems}
          isVariableSizeTable
          listRef={listRef}
          isDragDisabled
          isInDragContext
          showHeader={false}
          dragContainerClassName={'skills-table-clone'}
        />
      </StyledSkillsTable>
    </DragDropContext>
  );
};

const makeMapStateToProps = () => {
  const getFormattedSkillsWithMembers = makeGetFormattedSkillsWithMembers();

  const mapStateToProps = (state) => {
    return {
      teamId: getSelectedTeamId(state),
      columnHeaders: getSkillColumnHeaders(state),
      totalMembersCount: getTeamMembers(state)?.length,
      skillsCount: getSkillsCount(state),
      formattedSkills: getFormattedSkillsWithMembers(state, {
        isExcludeArchivedMembers: true
      }),
      skillsLoaded: getAreSkillsLoaded(state)
    };
  };

  return mapStateToProps;
};
const mapDispatchToProps = {};

export default connect(makeMapStateToProps, mapDispatchToProps)(SkillsTable);
