import { useAccountCapacitySum } from 'DashboardModule/hooks/useAccountCapacityTotals';
import React, { useMemo } from 'react';
import UtilizationSummaryWidget from 'views/Home/Widgets/Widgets/UtilizationSummaryWidget/UtilizationSummaryWidget';
import {
  GENERATE_UTILIZATION_RANGE,
  UTILIZATION_LABELS
} from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS, VIEW_BY } from 'appConstants/workload';
import withFilter from 'hocs/withFilter';
import theme from 'theme';

const emptyArray = [];

const summaryChartFractionStyle = `
  font-size: 42px;
  color: ${theme.colors.colorMediumGray9};
`;

const summaryChartLabelStyle = `
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  bottom: 38%;
`;

const UtilizationWidget = ({ activeFilter, teamId, widgetConfig }) => {
  const fetchParams = useMemo(
    () => ({
      ...GENERATE_UTILIZATION_RANGE[
        activeFilter.custom?.range !== undefined
          ? activeFilter.custom.range
          : ZOOM_LEVELS.WEEK
      ]?.({
        start: activeFilter.custom?.start_date,
        end: activeFilter.custom?.end_date
      }),
      team_id: teamId,
      account_ids: activeFilter.account_ids || emptyArray,
      filterStateId: activeFilter.id,
      initial: true
    }),
    [activeFilter.account_ids, activeFilter.custom, activeFilter.id, teamId]
  );
  const accountCapacitySum = useAccountCapacitySum({
    accountIds: activeFilter.account_ids,
    fetchParams
  });
  return (
    <UtilizationSummaryWidget
      viewBy={VIEW_BY.MEMBERS}
      activeFilter={activeFilter}
      accountCapacitySum={accountCapacitySum}
      chartOnly
      chartWidth={260}
      chartHeight={130}
      innerRadius={110}
      outerRadius={124}
      cy={130}
      containerClassName={'dashboard-widget'}
      chartLabel={UTILIZATION_LABELS[activeFilter.custom?.range]}
      isV2
      isSelfLoading
      fetchParams={fetchParams}
      widgetConfig={widgetConfig}
      summaryChartFractionStyle={summaryChartFractionStyle}
      summaryChartLabelStyle={summaryChartLabelStyle}
    />
  );
};

export default withFilter(UtilizationWidget);
