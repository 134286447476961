import React, { useMemo, useEffect } from 'react';
import { Button, Modal } from 'reactstrap';
import { getSelectedTeamId } from 'selectors';
import { useDispatch, useSelector } from 'react-redux';
import { checkHasTimeEntries } from 'actionCreators';
import {
  hasAssociatedTime,
  getAssociatedTimeMessage
} from 'appUtils/budgetUtils';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
const DeleteArchivedBoardModal = ({
  board,
  isOpen,
  closeDeleteModal,
  deleteBoard
}) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  useEffect(() => {
    if (isOpen && teamId && board) {
      dispatch(
        checkHasTimeEntries({
          board_ids: [board.id],
          team_id: teamId,
          permissions: {
            teamId,
            mine: false
          }
        })
      );
    }
  }, [board, dispatch, isOpen, teamId]);

  const loadingHasTimeEntries = useSelector(
    (state) => state.timesheets.loadingHasTimeEntries
  );
  const hasTimeEntries = useSelector(
    (state) => state.timesheets.hasTimeEntries
  );

  const boardHasTimeEntries = useMemo(() => {
    return (
      board &&
      hasTimeEntries?.find(
        (hasTimeEntry) =>
          hasTimeEntry.board_id === board.id && hasAssociatedTime(hasTimeEntry)
      )
    );
  }, [board, hasTimeEntries]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={closeDeleteModal}
        className="delete-archived-board-modal"
      >
        <div className="delete-modal-header">
          <div className="danger-point">!</div>
          <div className="header-text">
            {' '}
            {boardHasTimeEntries
              ? 'Cannot Delete Portfolio'
              : 'Delete Porfolio'}
          </div>
        </div>
        <div className="warning-text">
          {loadingHasTimeEntries ? (
            <div />
          ) : boardHasTimeEntries ? (
            getAssociatedTimeMessage(boardHasTimeEntries, MODAL_TYPE.BOARD)
          ) : (
            `  Are you sure you want to delete this Portfolio? This action will
          permanently delete all projects and information in the portfolio and cannot
          be undone.`
          )}
        </div>
        {!loadingHasTimeEntries && (
          <div className="delete-actions">
            <Button
              className="cancel-delete-modal-button"
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>
            <Button
              className={
                boardHasTimeEntries
                  ? 'cannot-delete-modal-button'
                  : 'delete-modal-button'
              }
              onClick={boardHasTimeEntries ? closeDeleteModal : deleteBoard}
            >
              {boardHasTimeEntries ? 'Ok' : 'Delete'}
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};
export default DeleteArchivedBoardModal;
