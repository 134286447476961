import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
const byId = (item) => item.id;

export const initialState = {
  searches: {}
};

const search = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.QB_SEARCH_CHANGE: {
      const { section, value } = payload;
      return {
        ...state,
        searches: {
          ...state.searches,
          [section]: value
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default search;
