import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SkinnyArrowIcon = ({
  className,
  color = '#4F4F4F',
  fill = 'none',
  width = 19,
  height = 18,
  style,
  onClick
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 19 18"
    style={style}
    onClick={onClick}
  >
    <path
      d="M9.34961 16.4502L1.54961 8.6502L9.34961 0.850195"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M18.4502 8.65039L1.5502 8.65039"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default SkinnyArrowIcon;
