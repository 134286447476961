import React from 'react';
import SvgIcon from 'components/SvgIcon';

const DateNavArrowIcon = ({
  fill = '#4F4F4F',
  stroke = '#4F4F4F',
  className = '',
  width = '10',
  height = '17'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.680428 7.97922L8.2666 0.361643L9.68864 1.76551L2.80396 8.68082L9.68864 15.5961L8.2666 17L0.680428 9.38243L0 8.6809L0.680428 7.97922Z"
        fill="#4F4F4F"
      />
    </SvgIcon>
  );
};

export default DateNavArrowIcon;
