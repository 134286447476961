import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CloseXIconBig = ({ width = '18', height = '18' }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8937 0L8.98125 7.90313L1.10625 0.0375L0 1.14375L7.875 9L0 16.8656L1.10625 17.9625L8.98125 10.1063L16.8937 18L18 16.9031L10.0875 9L18 1.10625L16.8937 0Z"
        fill="#333333"
      />
    </SvgIcon>
  );
};

export default CloseXIconBig;
