import MultiStepFlyout from './MultiStepFlyout';
import { useCallback, PropsWithChildren } from 'react';
import { usePopperState } from 'appUtils/hooks/usePopperState';

export const useMultiStepFlyout = () => {
  const { isOpen, anchorElement, open, close } = usePopperState();

  const renderMenu = useCallback(
    ({ children, ...multiStepFlyoutProps }: PropsWithChildren<any>) => {
      if (!anchorElement) return null;
      return (
        <MultiStepFlyout
          isOpen={isOpen}
          target={anchorElement}
          handleClose={close}
          {...multiStepFlyoutProps}
        >
          {children}
        </MultiStepFlyout>
      );
    },
    [anchorElement, close, isOpen]
  );

  return {
    MultiStepFlyout: renderMenu,
    openFlyout: open,
    closeFlyout: close,
    isOpen
  };
};
