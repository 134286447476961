import { useCallback } from 'react';
import { ROW_TYPES } from '../tableConfigs';
import { BaseTableListsBuilder } from 'components/Table/types';
import type {
  PermissionList,
  PermissionListItemsBuilder,
  PermissionSectionOrder
} from '../types';
import { sectionPermissionIdentifierHash } from 'PermissionsModule/constants';

const emptyArray = [];
export const useSectionGroupedListsBuilder = ({
  getIsOpen,
  toggleCollapse,
  listItemsBuilder,
  setParentCollapseState,
  getCurrentParentCollapseState
}: {
  getIsOpen: (id: string | number) => boolean | undefined;
  toggleCollapse: (id: string | number) => void;
  listItemsBuilder: PermissionListItemsBuilder;
  setParentCollapseState: any; // FIXME
  getCurrentParentCollapseState: any; // FIXME
}) => {
  const sectionGroupedListsBuilder: BaseTableListsBuilder<PermissionList> =
    useCallback(
      ({ order }) => {
        const lists = (order as PermissionSectionOrder).flatMap(
          (permissionSectionId) => {
            const permissionIdsOrder =
              sectionPermissionIdentifierHash[permissionSectionId];
            if (permissionIdsOrder && permissionIdsOrder.length) {
              const listItems = listItemsBuilder({
                order: permissionIdsOrder,
                parentGroupId: permissionSectionId
              });

              const isListOpen = getIsOpen(permissionSectionId);

              const list: PermissionList = {
                // duplicate ids here are intentional
                id: permissionSectionId,
                sectionId: permissionSectionId,
                permissionSectionId,
                listItems,
                isList: true,
                isFullyLoaded: true,
                addEmptyRow: isListOpen,
                toggleCollapse: () => toggleCollapse(permissionSectionId),
                isOpen: Boolean(isListOpen),
                rowHeight: 50,
                rowType: ROW_TYPES.permissionsSectionHeaderRow
              };

              return list;
            }

            return [];
          }
        );

        // collapse is not used - leaving here for future ref
        // Root (top level) collapse state
        // const collapseState = getCurrentParentCollapseState();

        // if (collapseState.totalNumToggles !== orderedLists.length) {
        //   setParentCollapseState({
        //     values: {
        //       totalNumToggles: orderedLists.length
        //     }
        //   });
        // }

        return lists;
      },
      [getIsOpen, listItemsBuilder, toggleCollapse]
    );

  return sectionGroupedListsBuilder;
};
