import produce from 'immer';
import {
  TOGGLE_APP_SIDER_MENU,
  SET_APP_SIDER_MENU_VISIBILITY,
  SET_FROM_BOARD_VIEW_NAV_TEXT,
  CLEAR_FROM_BOARD_VIEW_NAV_TEXT,
  SET_RELOADER_SCREEN,
  OPEN_GLOBAL_ADD,
  CLOSE_GLOBAL_ADD,
  OPEN_TASK_GLOBAL_ADD,
  CLOSE_TASK_GLOBAL_ADD,
  LOGOUT_USER,
  START_ONLINE_MODE,
  START_OFFLINE_MODE
} from 'appConstants';

export const initialState = {
  appSiderMenuIsVisible: false,
  fromBoardViewText: false,
  reloaderScreen: false,
  globalAddOpen: false,
  taskGlobalAddOpen: false,
  isOfflineMode: false
};

const global = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_APP_SIDER_MENU: {
        draft.appSiderMenuIsVisible = !state.appSiderMenuIsVisible;
        break;
      }
      case SET_APP_SIDER_MENU_VISIBILITY: {
        draft.appSiderMenuIsVisible = action.payload.appSiderMenuIsVisible;
        break;
      }
      case SET_FROM_BOARD_VIEW_NAV_TEXT: {
        draft.fromBoardViewText = true;
        break;
      }
      case CLEAR_FROM_BOARD_VIEW_NAV_TEXT: {
        draft.fromBoardViewText = false;
        break;
      }
      case SET_RELOADER_SCREEN: {
        draft.reloaderScreen = true;
        break;
      }
      case OPEN_GLOBAL_ADD: {
        draft.globalAddOpen = true;
        break;
      }
      case CLOSE_GLOBAL_ADD: {
        draft.globalAddOpen = false;
        break;
      }
      case OPEN_TASK_GLOBAL_ADD: {
        draft.taskGlobalAddOpen = true;
        break;
      }
      case CLOSE_TASK_GLOBAL_ADD: {
        draft.taskGlobalAddOpen = false;
        break;
      }
      case START_OFFLINE_MODE: {
        draft.isOfflineMode = true;
        break;
      }
      case LOGOUT_USER:
      case START_ONLINE_MODE: {
        draft.isOfflineMode = false;
        break;
      }
    }
  });

export default global;
