import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { getSortedTeamMembers } from 'selectors';
import {
  DisciplinesState,
  initialState
} from 'SettingsModule/reducers/disciplines';

const getDisciplinesState: Selector<RootState, DisciplinesState> = (state) =>
  state.disciplines || initialState;

export const getIsDisciplinesLoaded = createSelector(
  getDisciplinesState,
  (state) => state.isLoaded
);

export const getIsLoadingDisciplines = createSelector(
  getDisciplinesState,
  (state) => state.isLoading
);

export const getDisciplines = createSelector(
  getDisciplinesState,
  (state) => state.disciplinesHash
);

export const getDisciplineIdsArray = createSelector(
  getDisciplines,
  (disciplineHash) => Object.keys(disciplineHash).map(Number)
);

export const getAlphabeticalDisciplinesIdArray = createSelector(
  getDisciplineIdsArray,
  getDisciplines,
  (disciplineIds, disciplineHash) =>
    sortBy(disciplineIds, (id) => disciplineHash[id]?.name)
);

export const getDisciplinesArray = createSelector(
  getDisciplines,
  (disciplines) =>
    Object.keys(disciplines)
      .sort()
      .flatMap((id) => disciplines[+id] ?? [])
);

export const getFormattedDisciplinesWithMembers = createSelector(
  getSortedTeamMembers,
  getDisciplinesArray,
  (members, disciplinesArray) => {
    const disciplinesWithHashMemberships = disciplinesArray.map(
      (discipline) => ({
        ...discipline,
        disciplineMembersHash: keyBy(
          discipline.team_memberships,
          (membership) => membership.account_id
        )
      })
    );

    const formattedDisciplines = disciplinesWithHashMemberships.map(
      (discipline) => {
        const formattedDisciplineMembers = members.filter(
          (member) => discipline.disciplineMembersHash[member.account.id]
        );

        return {
          ...discipline,
          formattedDisciplineMembers
        };
      }
    );

    return formattedDisciplines;
  }
);
