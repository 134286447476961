import React from 'react';
import styled from 'styled-components';

const StyledAddProjectButton = styled.div`
  color: ${(props) => props.theme.colors.colorPureWhite};
  background: ${(props) => props.theme.colors.colorRoyalBlue};
  border-radius: 100px;
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  transition: color 0.2s, background-color, 0.2s;
`;

const AddProjectButton = () => (
  <StyledAddProjectButton data-testid="add-project-button">
    <span className="add-project-text">New Project</span>
  </StyledAddProjectButton>
);

export default React.memo(AddProjectButton);
