import { ComponentType, Suspense, useCallback, useState } from 'react';

export const useOpener = <
  ComponentPropsType extends Record<string, unknown>,
  OnCloseProp extends keyof ComponentPropsType = 'onClose'
>(
  Component: ComponentType<
    Omit<ComponentPropsType, OnCloseProp> & Record<OnCloseProp, () => void>
  >,
  params?: {
    onClose?: () => void;
    onCloseProperty?: OnCloseProp;
    onOpen?: () => void;
    onToggle?: () => void;
  }
): {
  close: () => void;
  isOpen: boolean;
  Component: (props: Omit<ComponentPropsType, OnCloseProp>) => JSX.Element;
  open: () => void;
  toggle: () => void;
} => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = params?.onClose;
  const close = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  const onOpen = params?.onOpen;
  const open = useCallback(() => {
    setIsOpen(true);
    onOpen?.();
  }, [onOpen]);

  const onToggle = params?.onToggle;
  const toggle = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
    onToggle?.();
  }, [onToggle]);

  const PartialComponent = useCallback(
    (props: Omit<ComponentPropsType, OnCloseProp>) =>
      isOpen ? (
        <Suspense fallback={null}>
          <Component
            {...props}
            {...({ [params?.onCloseProperty ?? 'onClose']: close } as Record<
              OnCloseProp,
              () => void
            >)}
          />
        </Suspense>
      ) : (
        <></>
      ),
    [Component, close, isOpen, params?.onCloseProperty]
  );

  return {
    close,
    isOpen,
    Component: PartialComponent,
    open,
    toggle
  };
};
