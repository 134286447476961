import {
  StyledSelectToggleContainer,
  StyledSelectToggle,
  ClickableArea
} from 'components/BatchActions/styles';
import { MemberPermissionListItem } from '../types';
import { useMembershipTableData } from 'PermissionsModule/components/settingsViews/members/DataProvider';

export const MembersPermissionsTableSelectCell = ({ row }) => {
  const { teamMember } = row.original as MemberPermissionListItem;
  const { getIsTeamMemberSelected, toggleTeamMembershipSelectedState } =
    useMembershipTableData();

  const isSelected = getIsTeamMemberSelected({
    teamMembershipId: teamMember.id
  });

  return (
    <StyledSelectToggleContainer>
      <ClickableArea
        onClick={() =>
          toggleTeamMembershipSelectedState({
            teamMembershipId: teamMember.id
          })
        }
      />
      <StyledSelectToggle isChecked={isSelected} size={14} />
    </StyledSelectToggleContainer>
  );
};
