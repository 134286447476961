import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { DragDropContext } from 'react-beautiful-dnd';
import InfiniteScroller from './InfiniteScroller';
import * as Sentry from '@sentry/browser';

import {
  getTaskPlannerMemberIds,
  getCurrentFilter,
  getAuthToken,
  getEditingTask,
  getTaskStatusOrder,
  getTaskPriorityOrder,
  getIsCreatingTask,
  getMatchedRouteParams,
  getProjectTaskGroups,
  getIsOnProjectView,
  getProjectTaskGroupOrder,
  getHomeTaskObj,
  getMyUserId
} from 'selectors';
import moment from 'moment';
import {
  onClearSearch,
  triggerTaskPositionResort,
  updateDayPlanner,
  updateProjectModules,
  moveTaskToGroup,
  reorderProjectTaskGroups
} from 'actionCreators';

const taskPositionKeys = {
  'My Tasks': 'assigned_task_position',
  'Assigned To Me': 'tasks_assigned_to_positions',
  'Assigned By Me': 'tasks_assigned_by_positions'
};

const InfiniteScrollWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  width: fit-content;
`;

class InfiniteScrollContainer extends Component {
  state = {
    dragType: null,
    collapsedForDrag: false
  };

  handleTaskStatusOrderUpdate = (sourceIndex, destinationIndex) => {
    const {
      taskStatusOrder,
      updateProjectModules,
      matchedParams: { projectId }
    } = this.props;
    const newOrder = [...taskStatusOrder];
    const id = newOrder[sourceIndex];
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, id);

    updateProjectModules({
      projectId,
      taskStatusOrder: newOrder
    });
    this.setState({ dragType: null });
  };

  handleTaskPriorityOrderUpdate = (sourceIndex, destinationIndex) => {
    const {
      taskPriorityOrder,
      updateProjectModules,
      matchedParams: { projectId }
    } = this.props;
    const newOrder = [...taskPriorityOrder];
    const id = newOrder[sourceIndex];
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, id);

    updateProjectModules({
      projectId,
      taskPriorityOrder: newOrder
    });
    this.setState({ dragType: null });
  };

  handleTaskGroupOrderUpdate = (sourceIndex, destinationIndex) => {
    const {
      taskGroupOrder,
      reorderProjectTaskGroups,
      matchedParams: { projectId }
    } = this.props;
    const newOrder = [...taskGroupOrder[projectId]];
    const id = newOrder[sourceIndex];
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, id);

    reorderProjectTaskGroups({
      projectId,
      order: newOrder
    });
    this.setState({ dragType: null });
  };

  onDragStart = (result) => {
    this.setState({ dragType: result.type });
  };

  onDragEnd = (result) => {
    const {
      token,
      handleTaskPositionResort,
      updateDayPlanner,
      allTasks,
      matchedParams: { projectId },
      currentFilter,
      isOnProjectView,
      moveTaskToGroup,
      myId
    } = this.props;
    const {
      draggableId: unparsedDraggableId,
      source,
      destination,
      type
    } = result;
    const draggableId = unparsedDraggableId.startsWith('task-group')
      ? unparsedDraggableId.split('--')[1]
      : unparsedDraggableId;
    this.setState({ collapsedForDrag: false });
    if (!destination) {
      return;
    }
    if (type === 'task-statuses') {
      return this.handleTaskStatusOrderUpdate(source.index, destination.index);
    }
    if (type === 'task-priorities') {
      return this.handleTaskPriorityOrderUpdate(
        source.index,
        destination.index
      );
    }
    if (type === 'task-groups') {
      return this.handleTaskGroupOrderUpdate(source.index, destination.index);
    }
    // draggableId can be a temp UUID - if so we want to retain instead of converting to number
    const taskId = Number.isInteger(draggableId) ? +draggableId : draggableId;

    // dropped outside the list
    if (
      currentFilter.section === 'My Tasks' &&
      currentFilter.subSection === 'scheduled' &&
      currentFilter.scope !== 'project'
    ) {
      const newDateKey = moment(destination.droppableId).format('YYYY-MM-DD');
      const oldDateKey = moment(source.droppableId).format('YYYY-MM-DD');

      if (!moment(newDateKey).isValid()) {
        try {
          Sentry.withScope((scope) => {
            scope.setExtra('destinationDroppableId', destination.droppableId);
            scope.setExtra('sourceDroppableId', source.droppableId);
            scope.setExtra('unparsedDraggableId', unparsedDraggableId);
            scope.setExtra('currentFilter', currentFilter);
            Sentry.captureException(
              'Drag And Drop Error on Home Schedule View'
            );
          });
        } catch {}
      }

      const params = {
        taskId,
        position: destination.index,
        date: newDateKey,
        laneId: newDateKey,
        dateKey: oldDateKey,
        accountIds: [myId],
        currentAccountId: myId
      };
      updateDayPlanner(token, params);
    } else if (isOnProjectView) {
      moveTaskToGroup({
        sourceTaskGroupId: source.droppableId,
        destinationTaskGroupId: destination.droppableId,
        taskId,
        index: destination.index,
        groupType: 'taskGroup'
      });
    } else {
      const homeView = taskPositionKeys[currentFilter.section];
      handleTaskPositionResort({
        token,
        params: {
          allTasks,
          taskSourceIndex: source.index,
          taskDestinationIndex: destination.index,
          homeView,
          projectId,
          source,
          destination
        }
      });
    }
    this.setState({ dragType: null });
  };

  onBeforeCapture = ({ draggableId }) => {
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md#onbeforecapture - read warnings about modifying position of dragging element before implementing any element repositioning logic.
    if (draggableId.startsWith('task-group')) {
      this.setState({
        collapsedForDrag: true
      });
    }
  };

  render() {
    const {
      toggleTaskBatchAction,
      shouldRenderCreateTaskButton,
      scheduledGroups,
      plannerGroups,
      alwaysRenderDue,
      alwaysRenderSchedule,
      taskFilter,
      isOnTeamProject,
      isOnPersonalProject,
      isOnHomeView,
      allTasks,
      isLazyLoading,
      taskIsEditing,
      isCreatingTask,
      isDayPlannerView,
      plannerDate
    } = this.props;

    const { dragType, collapsedForDrag, plannerViewType } = this.state;

    if (isOnTeamProject) {
      return (
        <InfiniteScroller
          shouldRenderCreateTaskButton={shouldRenderCreateTaskButton}
          toggleTaskBatchAction={toggleTaskBatchAction}
          scheduledGroups={scheduledGroups}
          alwaysRenderSchedule={alwaysRenderSchedule}
          alwaysRenderDue={alwaysRenderDue}
          taskFilter={taskFilter}
          isOnTeamProject={isOnTeamProject}
          isOnHomeView={isOnHomeView}
          taskIds={allTasks}
          isLazyLoading={isLazyLoading}
          dragType={dragType}
          collapsedForDrag={collapsedForDrag}
          handleAdditionalDragTypes={this.onDragEnd}
        />
      );
    }

    return (
      <>
        <InfiniteScrollWrapper
          taskIsEditing={taskIsEditing}
          isOnTeamProject={isOnTeamProject}
          isCreatingTask={isCreatingTask}
        >
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}
            onBeforeCapture={this.onBeforeCapture}
          >
            <InfiniteScroller
              shouldRenderCreateTaskButton={shouldRenderCreateTaskButton}
              toggleTaskBatchAction={toggleTaskBatchAction}
              scheduledGroups={scheduledGroups}
              plannerGroups={plannerGroups}
              alwaysRenderSchedule={alwaysRenderSchedule}
              alwaysRenderDue={alwaysRenderDue}
              taskFilter={taskFilter}
              isCreatingTask={isCreatingTask}
              isOnTeamProject={isOnTeamProject}
              isOnPersonalProject={isOnPersonalProject}
              isOnHomeView={isOnHomeView}
              taskIds={allTasks}
              isLazyLoading={isLazyLoading}
              dragType={dragType}
              collapsedForDrag={collapsedForDrag}
              isDayPlannerView={isDayPlannerView}
              plannerDate={plannerDate}
            />
          </DragDropContext>
        </InfiniteScrollWrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: getAuthToken(state),
  myId: getMyUserId(state),
  currentFilter: getCurrentFilter(state),
  accountIds: getTaskPlannerMemberIds(state, ownProps),
  taskIsEditing: !!getEditingTask(state),
  taskStatusOrder: getTaskStatusOrder(state),
  taskPriorityOrder: getTaskPriorityOrder(state),
  isCreatingTask: getIsCreatingTask(state),
  matchedParams: getMatchedRouteParams(state),
  taskGroupsHash: getProjectTaskGroups(state),
  isOnProjectView: getIsOnProjectView(state),
  taskGroupOrder: getProjectTaskGroupOrder(state),
  taskHash: getHomeTaskObj(state)
});

const mapDispatchToProps = {
  handleTaskPositionResort: triggerTaskPositionResort,
  handleOnClearSearch: onClearSearch,
  updateDayPlanner,
  updateProjectModules,
  reorderProjectTaskGroups,
  moveTaskToGroup
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfiniteScrollContainer)
);
