import { useContext } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { renderItem } from 'SuggestionModule/components/FindPeople/List/constants';
import useSimpleStaticList from 'appUtils/hooks/useSimpleStaticList/useSimpleStaticList';
import useProjectUnassignedRolesListBuilder from './helpers/useProjectUnassignedRolesListBuilder';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import { LIST_ITEM_TYPES } from '../constants';
import {
  ProjectUnassignedMemberBudgetWithRequirementsListItem,
  ProjectMembershipRequirementListItem,
  AddProjectMembershipRequirementListItem
} from './types';

const calculateItemHeight = (
  item:
    | ProjectMembershipRequirementListItem
    | AddProjectMembershipRequirementListItem
    | ProjectUnassignedMemberBudgetWithRequirementsListItem
) => {
  const { itemType, isOpen } = item;
  if (itemType === LIST_ITEM_TYPES.ProjectMembershipRequirement) {
    return 40;
  } else if (
    itemType === LIST_ITEM_TYPES.ProjectUnassignedMemberBudgetWithRequirements
  ) {
    const hasRequirements = item.requirementCounts > 0;

    return isOpen ? 40 : hasRequirements ? 60 : 45;
  } else if (itemType === LIST_ITEM_TYPES.AddProjectMembershipRequirement) {
    return 35;
  } else if (itemType === LIST_ITEM_TYPES.EmptyItem) {
    return 20;
  }
  return 65;
};

const UnassignedRolesListBody = () => {
  const {
    selectedProjectId,
    isFetchingEntityRequirements,
    handleSelectUnassignedRole,
    onDeleteRequirement,
    onAddRequirementsClick
  } = useContext(FindPeopleModalContext);

  const { List } = useSimpleStaticList({
    useInitialListBuilder: useProjectUnassignedRolesListBuilder,
    renderItem,
    itemHeight: calculateItemHeight,
    initialListBuilderArgs: {
      projectId: selectedProjectId,
      isFetchingEntityRequirements,
      handleSelectUnassignedRole,
      onDeleteRequirement,
      onAddRequirementsClick
    }
  });

  return <StyledListContainer>{List}</StyledListContainer>;
};

export default UnassignedRolesListBody;

const StyledListContainer = styled.div`
  height: 100%;
  width: 270px;

  .add-requirements-item-project-membership-list-item-container,
  .project-membership-requirement-list-item-container {
    &.open {
      background: white;
    }
  }

  .main-list {
    overflow-x: hidden !important;
  }

  .unassigned-member-budget-list-item-container {
    border-top: 1px solid ${theme.colors.colorSemiDarkGrey11};

    &.open {
      background-color: ${theme.colors.colorPureWhite};
    }
  }

  .is-hovering-on-group {
    border-left: 2px solid ${theme.colors.colorCalendarBlue};

    &.unassigned-member-budget-list-item-container {
      .requirement-name {
        color: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;
