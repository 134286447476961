import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';

export const ThreeDotMenuListItem = ({
  icon,
  label,
  onClick,
  disabled,
  tooltip
}) => {
  return (
    <RootContainer
      {...defaultTooltipProps}
      className={cn({ disabled })}
      data-tip={tooltip}
      onClick={onClick && onClick}
    >
      <LeadingIconContainer>{icon}</LeadingIconContainer>
      <LabelText>{label}</LabelText>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0 24px 0 8px;
  cursor: pointer;
  gap: 8px;

  :hover:not(.disabled) {
    background-color: #ececec;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const LeadingIconContainer = styled.div`
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;
