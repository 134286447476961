import React from 'react';
import cn from 'classnames';

const BasicMemberCircle = ({
  initials,
  isLoggedInUser,
  onClick,
  className = ''
}) => {
  return (
    <div
      className={cn('basic-member-circle', className, {
        'is-logged-in-user': isLoggedInUser
      })}
      onClick={onClick}
    >
      {initials}
    </div>
  );
};

export default BasicMemberCircle;
