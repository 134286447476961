import React from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import { SectionTitle } from './styles';
import { TaskCommentsContainer } from 'views';

const CommentsSection = ({
  entity,
  noTitle,
  isUserActivitiesHidden = true
}) => {
  const me = useSelector(getMe);

  return (
    <StyledCommentsSection>
      {!noTitle && <SectionTitle>COMMENTS</SectionTitle>}
      <TaskCommentsContainer
        task={entity}
        me={me}
        isUserActivitiesHidden={isUserActivitiesHidden}
      />
    </StyledCommentsSection>
  );
};

export default CommentsSection;

/* ------------------------------------ - ----------------------------------- */

const StyledCommentsSection = styled.div`
  padding-top: 20px;
  position: relative;
  svg {
    margin: 0 9px 1px 4px;
  }

  .comments-container {
    .add-comment-container {
      .member-initials {
        display: none;
      }
    }
    .add-comment-container,
    .comment-thread-container {
      margin-left: 0;
      margin-right: 0;
    }
    .add-comment-header {
      display: none; // hide default header
    }
  }
`;
