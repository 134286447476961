import React, { Component } from 'react';
import TaskNoteBox from '../../taskDisplay/taskModalComponents/TaskNoteBox';
import theme from 'theme';

import { StyledTaskContentMiddle } from './styles';

import { connect } from 'react-redux';
import { triggerTasksAttributesUpdate } from 'actionCreators';

import NoteIcon from 'icons/NoteIcon';
import styled from 'styled-components';

const StyledNoteIcon = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    height: 14px;
    width: 14px;
    position: relative;
    top: 1px;
  }
  padding: 0px 6px 0px 25px;
`;

const StyledHorizontalBreak = styled.div`
  display: ${(props) => (props.isTaskModal ? 'flex' : 'none')};
  height: 1px;
  margin-left: 34px;
  margin-right: 18px;

  background: ${theme.colors.colorPaleGray5};
`;

class TaskContentLowerMiddle extends Component {
  render() {
    const {
      taskIsEditing,
      isOnTeamProject,
      shouldRenderAssignee,
      task,
      handleTasksAttributesUpdate,
      isSelectedHomeTask,
      beingEdited,
      isTaskEditProperty,
      checkForTyping,
      handleNoteChange,
      noteText,
      isTaskModal
    } = this.props;
    return (
      <StyledTaskContentMiddle
        isSelectedHomeTask={isSelectedHomeTask}
        taskIsEditing={taskIsEditing}
        isOnTeamProject={isOnTeamProject}
        isTaskEditProperty={isTaskEditProperty}
        beingEdited={beingEdited}
        isTaskModal={isTaskModal}
      >
        <StyledHorizontalBreak isTaskModal={isTaskModal} />
        {isSelectedHomeTask || (taskIsEditing && isTaskEditProperty) ? (
          <div className="content-middle-task-note-container">
            <StyledNoteIcon isOnTeamProject={isOnTeamProject}>
              <NoteIcon color={theme.colors.colorMediumGray5} />
            </StyledNoteIcon>
            <div className="content-middle-container scroll">
              <TaskNoteBox
                shouldRenderAssignee={shouldRenderAssignee}
                task={task}
                handleTasksAttributesUpdate={handleTasksAttributesUpdate}
                isInlineTask={taskIsEditing && !isSelectedHomeTask}
                checkForTyping={checkForTyping}
                handleNoteChange={handleNoteChange}
                noteText={noteText}
              />
            </div>
          </div>
        ) : null}
      </StyledTaskContentMiddle>
    );
  }
}

const mapDispatchToProps = {
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate
};

export default connect(null, mapDispatchToProps)(TaskContentLowerMiddle);
