import styled from 'styled-components';
import cn from 'classnames';
import theme from 'theme';
import PhaseDiamondIcon from 'icons/BudgetPhaseMilestoneIcon';
import { PhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import MilestoneProgress from 'BudgetModule/components/BudgetTable/MilestoneProgress';
import EllipsisText from 'components/EllipsisText';
import { AvailabilityButton } from 'SuggestionModule/components/AvailabilityButton';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const PhaseTitleCell = ({ row }: PhaseGroupRowProps) => {
  const {
    customRowProps: { projectId },
    phase,
    toggleCollapse,
    allSuggestedMembers,
    isOpen,
    isAvailabilityViewActive,
    onToggleViewAvailability
  } = row.original;
  const { id, name, start_date, end_date, is_like_default } = phase;

  const { teamBuilderSplitScreenFlag } = useFeatureFlags();

  const phaseName = is_like_default ? 'Project Schedule' : name;

  const numOfSuggestions = allSuggestedMembers?.length || 0;
  const numOfSuggestionsText = `${numOfSuggestions} Suggestion${
    numOfSuggestions > 1 ? 's' : ''
  }`;

  return (
    <StyledContainer>
      <ColumnContainer>
        <StyledNameContainer onClick={toggleCollapse}>
          <PhaseDiamondIcon
            strokeColor={theme.colors.colorMediumGray9}
            strokeWidth="1.5px"
            width="12"
            height="14"
            className={undefined}
          />{' '}
          <PhaseNameContainer>
            <EllipsisText maxWidth={178} className="name" tooltip={phaseName}>
              {phaseName}
            </EllipsisText>
            {numOfSuggestions && !isOpen ? (
              <EllipsisText
                maxWidth={178}
                className="suggestionsNum"
                tooltip={numOfSuggestionsText}
              >
                {numOfSuggestionsText}
              </EllipsisText>
            ) : (
              <></>
            )}
          </PhaseNameContainer>
        </StyledNameContainer>
        {teamBuilderSplitScreenFlag && numOfSuggestions > 0 && (
          <StyledAvailabilityButton
            active={isAvailabilityViewActive}
            onClick={onToggleViewAvailability}
          />
        )}
      </ColumnContainer>
      <StyledDateSectionContainer>
        <StyledTitleContainer>PLANNED DATES</StyledTitleContainer>
        <StyledDateContainer>
          <MilestoneProgress
            phaseId={id}
            projectId={projectId}
            phase={phase}
            isDefaultPhase={is_like_default}
            calendarClassName="team-builder-phase-calendar"
          />
        </StyledDateContainer>
      </StyledDateSectionContainer>
    </StyledContainer>
  );
};

export default PhaseTitleCell;

const StyledNameContainer = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-top: 6px;
  padding-left: 1px;
  display: flex;
  align-items: baseline;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.colorCalendarBlue};

    path {
      stroke: ${theme.colors.colorCalendarBlue};
    }
  }
`;

const StyledAvailabilityButton = styled(AvailabilityButton).attrs(
  ({ active }) => ({
    className: cn({ active })
  })
)`
  margin-left: 19px;
  &.active {
    margin-left: 6px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const StyledContainer = styled.div`
  display: inline-flex;
  width: 100%;
  margin-left: 4px;
  height: 100%;
  padding-top: 26px;
`;

const StyledDateContainer = styled.div`
  color: ${theme.colors.colorCalendarBlue};

  .date-progress {
    background: none !important;
  }

  .start-date,
  .end-date,
  .app-cues-milestone-progress-bar {
    color: ${theme.colors.colorCalendarBlue};
    font-weight: 600;
  }

  .date-dash {
    position: relative;
    top: -1px;
    color: ${theme.colors.colorCalendarBlue};
  }

  .app-cues-milestone-progress-bar {
    padding-left: 0;
  }

  .budget-phase-progress-dates-container {
    border-radius: 3px;
    &:hover {
      background: ${theme.colors.colorLightGray17};
    }
  }

  .budget-phase-progress-dates {
    background: none !important;
    border: none;
  }

  .add-button {
    width: 123px;
    font-weight: 600;
  }

  &:hover {
    .add-button {
      border-radius: 3px;
      background: ${theme.colors.colorLightGray17};
    }
  }
`;

const PhaseNameContainer = styled.div`
  padding-left: 5px;

  .name {
    height: 26px;
  }

  .suggestionsNum {
    font-size: 12px;
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const StyledTitleContainer = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorLightGray15};
`;

const StyledDateSectionContainer = styled.div`
  text-align: center;
  padding-top: 2px;
  padding-left: 22px;
  padding-right: 22px;
`;
