import { ChangeEventHandler, CSSProperties } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import theme from 'theme';

interface RadioProps {
  /**
   * Flag indicating whether the radio button is checked.
   */
  checked: boolean;

  /**
   * The classes of the radio button.
   */
  className?: string;

  /**
   * Flag indicating whether the radio button is disabled.
   */
  disabled?: boolean;

  /**
   * The ratio of the size of the interactive area relative to the size of the
   * radio button. A value of 1 means the interactive area is the same size as
   * that of the radio button.
   */
  hitSlopRatio?: number;

  /**
   * The name of the radio button.
   */
  name?: string;

  /**
   * The callback that is invoked if the radio button is selected.
   */
  onChange?: ChangeEventHandler<HTMLInputElement>;

  /**
   * The size of the radio button.
   */
  size?: number;

  /**
   * The value that is returned via the `onChange` event if the radio button is
   * selected.
   */
  value?: string;
}

const DEFAULT_SIZE_IN_PIXELS = 14;

export const Radio = ({
  checked,
  className,
  disabled,
  hitSlopRatio = 1.5,
  name,
  onChange,
  size = DEFAULT_SIZE_IN_PIXELS,
  value
}: RadioProps) => (
  <RootContainer
    className={cn(className, {
      checked,
      disabled,
      'no-hit-slop': hitSlopRatio <= 1
    })}
    style={
      {
        '--size': `${size}px`,
        '--hit-slop-size':
          hitSlopRatio > 1 ? `${hitSlopRatio * size}px` : undefined
      } as CSSProperties
    }
  >
    <OriginalInput
      checked={checked}
      disabled={disabled}
      name={name}
      onChange={onChange}
      readOnly={!onChange}
      type="radio"
      value={value}
    />
  </RootContainer>
);

const OriginalInput = styled.input`
  // Removes the original input.
  display: none;
`;

// This must be a label for the `onChange` event to fire.
//
// Any changes to the styling of this component should also be reflected in the
// styling of the `Radio` component.
const RootContainer = styled.label`
  --scale: calc(var(--size) / ${DEFAULT_SIZE_IN_PIXELS});

  align-items: center;
  background: transparent;
  border: calc(1 * var(--scale)) solid ${theme.colors.colorLightGray24};
  border-radius: 50%;
  cursor: pointer;
  flex: none;
  display: inline-flex;
  height: var(--size);
  justify-content: center;
  margin: 0;
  padding: calc(1 * var(--scale));
  position: relative;
  width: var(--size);

  // This invisible pseudo-element increases the interactive area of the
  // component by extending beyond the borders of the container.
  &:not(.no-hit-slop)::before {
    border-radius: 50%;
    content: '';
    height: var(--hit-slop-size);
    position: absolute;
    width: var(--hit-slop-size);
  }

  // This pseudo-element renders the part indicating selection.
  &::after {
    content: '';
    background: ${theme.colors.colorTranslucentGray3};
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  // Styling for the checked state.
  &.checked::after {
    background: ${theme.colors.colorRoyalBlue};
  }

  // Styling for the disabled state. Since these styles follow those of the
  // checked state, these take precedence.
  &.disabled {
    background: ${theme.colors.colorLightGray6};
    cursor: default;

    &::after {
      background: transparent;
    }
  }
`;
