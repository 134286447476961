import React from 'react';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import getCaretCoordinates from 'textarea-caret';
import { CommentInputContainer } from '../..';
import { commentTypes } from 'appConstants/commentConstants';

class ProjectAddCommentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentBody: '',
      isExpanded: false,
      suggestionListLeft: 0,
      tempFiles: []
    };
    bindAll(this, [
      'toggleExpansion',
      'handleCommentChange',
      'handleCommentSubmit',
      'handleCancel',
      'handleKeyPress',
      'handleInputClick',
      'handleFiles',
      'removeFile'
    ]);
  }
  getPermissions = () => {
    const {
      selectedTeamId,
      project: { id }
    } = this.props;
    const permissions = {
      teamId: selectedTeamId,
      projectId: id
    };
    return permissions;
  };

  toggleExpansion() {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded
    }));
  }

  handleCommentChange(event) {
    this.setState({ commentBody: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key == 'Enter' && this.state.commentBody.length) {
      event.target.blur();
      event.preventDefault();
      this.handleCommentSubmit(true);
    }

    if (event.key == '@') {
      const commentInput = document.querySelector(
        '.add-comment-input .input textarea'
      );
      const suggestionListLeft = getCaretCoordinates(
        commentInput,
        commentInput.selectionEnd
      ).left;
      this.setState({ suggestionListLeft });
    }
  }

  handleCommentSubmit() {
    const { createComment, me, project } = this.props;
    const { tempFiles, commentBody } = this.state;
    const permissions = this.getPermissions();
    if (commentBody.length || tempFiles.length) {
      createComment({
        projectId: project.id,
        description: commentBody,
        account: me,
        files: tempFiles,
        permissions
      });

      this.toggleExpansion();
      this.setState({
        commentBody: '',
        tempFiles: []
      });
    }
  }

  handleCancel() {
    this.setState({ commentBody: '' });
    this.toggleExpansion();
  }

  handleInputClick(e) {
    // clicking input box should only ever open/expand
    if (!this.state.isExpanded) {
      this.toggleExpansion();
    }
  }

  handleFiles(files) {
    this.setState({ tempFiles: Array.from(files) });
  }

  removeFile(file) {
    this.setState((prevState) => ({
      tempFiles: prevState.tempFiles.filter((stateFile) => stateFile !== file)
    }));
  }

  render() {
    // const { taskHasCommentsOrMeta } = this.props;
    const { commentBody, isExpanded, suggestionListLeft, tempFiles, project } =
      this.state;

    return (
      <div
        className={cn('add-comment-container', {
          'has-comments': this.props.hasComments
        })}
      >
        <div className="add-comment-header">Updates</div>
        <CommentInputContainer
          className="add-comment-input"
          commentType={commentTypes.ADD}
          commentBody={commentBody}
          isExpanded={isExpanded}
          suggestionListLeft={suggestionListLeft}
          project={project}
          projectId={project?.id}
          onCancel={this.handleCancel}
          onClick={this.handleInputClick}
          onChange={this.handleCommentChange}
          onSubmit={this.handleCommentSubmit}
          onKeyPress={this.handleKeyPress}
          handleFiles={this.handleFiles}
          unsavedFiles={tempFiles}
          removeFile={this.removeFile}
        />
      </div>
    );
  }
}

export default ProjectAddCommentContainer;
