import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const fetchSkills = (params) =>
  createAction(constants.FETCH_SKILLS.TRIGGER, params);
export const fetchSkill = (params) =>
  createAction(constants.FETCH_SKILL.TRIGGER, params);

export const createSkill = (params) =>
  createAction(constants.CREATE_SKILL.TRIGGER, params);
export const updateSkill = (params) =>
  createAction(constants.UPDATE_SKILL.TRIGGER, params);
export const archiveSkill = (params) => {
  return createAction(constants.ARCHIVE_SKILL.TRIGGER, params);
};
export const unarchiveSkill = (params) => {
  return createAction(constants.UNARCHIVE_SKILL.TRIGGER, params);
};
export const deleteSkill = (params) => {
  return createAction(constants.DELETE_SKILL.TRIGGER, params);
};

export const createSkillMembership = (params) =>
  createAction(constants.CREATE_SKILL_MEMBERSHIP.TRIGGER, params);
export const updateSkillMembership = (params) =>
  createAction(constants.UPDATE_SKILL_MEMBERSHIP.TRIGGER, params);
export const deleteSkillMembership = (params) => {
  return createAction(constants.DELETE_SKILL_MEMBERSHIP.TRIGGER, params);
};

/* ------------------------------ Skills Report ----------------------------- */
export const fetchEntitySkillsAndSkillsReport = (params) =>
  createAction(constants.FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT.TRIGGER, params);

export const fetchSkillsReport = (params) =>
  createAction(constants.FETCH_SKILLS_REPORT.TRIGGER, params);
export const updateSkillLevelOnSkillsReportLocal = (params) =>
  createAction(constants.UPDATE_SKILL_LEVEL_ON_SKILLS_REPORT_LOCAL, params);

/* ------------------------------ Entity Skills ----------------------------- */
export const fetchEntitySkills = (params) =>
  createAction(constants.FETCH_ENTITY_SKILLS.TRIGGER, params);
export const createEntitySkill = (params) =>
  createAction(constants.CREATE_ENTITY_SKILL.TRIGGER, params);
export const updateEntitySkill = (params) =>
  createAction(constants.UPDATE_ENTITY_SKILL.TRIGGER, params);
export const deleteEntitySkill = (params) =>
  createAction(constants.DELETE_ENTITY_SKILL.TRIGGER, params);
