import theme from 'theme';
import styled from 'styled-components';
import { useAppDispatch } from 'reduxInfra/hooks';
import { updateEntityDefaultCurrency } from 'CurrencyModule/actionCreators';
import { ValueOf } from 'type-fest';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { CurrencyDropdown } from './CurrencyDropdown';

export const ProjectCurrencyDropdown = ({
  isOpen,
  target,
  onClose,
  currencyDropdownProps
}: {
  isOpen: boolean;
  target: HTMLElement | null;
  onClose: () => void;
  currencyDropdownProps: {
    teamId: number;
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  };
}) => {
  const dispatch = useAppDispatch();
  const { teamId, entityId, entityType } = currencyDropdownProps;

  const HeaderButton = <StyledDoneButton>Close</StyledDoneButton>;

  const handleProjectCurrencySelect = ({
    currencyCode
  }: {
    currencyCode: string;
  }) => {
    dispatch(
      updateEntityDefaultCurrency({
        team_id: teamId,
        entity_id: entityId,
        entity_type: entityType,
        currency: currencyCode
      })
    );
  };

  return (
    <CurrencyDropdown
      isOpen={isOpen}
      target={target}
      onClose={onClose}
      onSelect={handleProjectCurrencySelect}
      HeaderButton={HeaderButton}
    />
  );
};

const StyledDoneButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};

  &:hover {
    filter: brightness(95%);
  }
`;
