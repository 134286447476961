import { Draft, createReducer } from '@reduxjs/toolkit';
import { Entries } from 'type-fest';
import keyBy from 'lodash/keyBy';
import {
  togglePermissionOpen,
  toggleAllPermissionsOpen,
  fetchTeamPermissionsActionCreatorsMap,
  updateTeamPermissionsActionCreatorsMap
} from 'PermissionsModule/actionCreators';
import { permissionsActionsHash } from 'PermissionsModule/constants';
import { PermissionsActions } from 'PermissionsModule/types';
import {
  fetchTeamRoleTemplatesActionCreatorsMap,
  updateTeamRoleTemplateActionCreatorsMap
} from 'RoleTemplatesModule/actionCreators';
import { TeamRoleTemplateOld } from 'RoleTemplatesModule/models/teamRoleTemplate';
import { TeamPermissionsState } from './types';
import { TeamRoleTemplate } from 'PermissionsModule/models/teamPermissions';

const permissionsActionsExpandedInitialState = (
  Object.keys(permissionsActionsHash) as Array<
    keyof typeof permissionsActionsHash
  >
).reduce<Record<PermissionsActions, boolean>>((acc, cur) => {
  acc[cur] = false;
  return acc;
}, {} as Record<PermissionsActions, boolean>);

const byIdOld = (item: TeamRoleTemplateOld) => item.id;
const byId = (item: TeamRoleTemplate) => item.id;

const initialState: TeamPermissionsState = {
  teamRoleTemplatesOld: {},
  teamRoleTemplates: {},
  expanded: permissionsActionsExpandedInitialState,
  oneOffPermissionersMap: {}
};

const handleFetchTeamRoleTemplatesSuccess = (
  state: Draft<TeamPermissionsState>,
  action: ReturnType<typeof fetchTeamRoleTemplatesActionCreatorsMap.success>
) => {
  const { team_role_templates } = action.payload.response;
  state.teamRoleTemplatesOld = keyBy(team_role_templates, byIdOld);
};

const handleUpdateTeamRoleTemplateSuccess = (
  state: Draft<TeamPermissionsState>,
  action: ReturnType<typeof updateTeamRoleTemplateActionCreatorsMap.success>
) => {
  const { team_role_template } = action.payload.response;
  state.teamRoleTemplatesOld[team_role_template.id] = team_role_template;
};

const handleTogglePermissionOpen = (
  state: Draft<TeamPermissionsState>,
  action: ReturnType<typeof togglePermissionOpen>
) => {
  const { actionType } = action.payload;
  state.expanded[actionType] = !state.expanded[actionType];
};

const handleToggleAllPermissionsOpen = (
  state: Draft<TeamPermissionsState>,
  action: ReturnType<typeof toggleAllPermissionsOpen>
) => {
  const { open } = action.payload;
  state.expanded = (
    Object.keys(state.expanded) as Array<keyof typeof state.expanded>
  ).reduce<Record<PermissionsActions, boolean>>((acc, cur) => {
    acc[cur] = open;
    return acc;
  }, {} as Record<PermissionsActions, boolean>);
};

// *************************** FETCH TEAM PERMISSIONS ****************************

const handleFetchTeamPermissionsSuccess = (
  state: Draft<TeamPermissionsState>,
  action: ReturnType<typeof fetchTeamPermissionsActionCreatorsMap.success>
) => {
  const { team_role_templates, one_off_permissioners_map } =
    action.payload.response;
  state.teamRoleTemplates = keyBy(team_role_templates, byId);
  state.oneOffPermissionersMap = one_off_permissioners_map;
};

// *************************** UPDATE TEAM PERMISSIONS ****************************

const handleUpdateTeamPermissionsSuccess = (
  state: Draft<TeamPermissionsState>,
  action: ReturnType<typeof updateTeamPermissionsActionCreatorsMap.success>
) => {
  const { role_templates, one_off_permission } = action.payload.response;

  Object.entries(role_templates).forEach(([id, role_template]) => {
    const permissionActions = Object.keys(role_template) as Array<
      keyof typeof role_template
    >;
    permissionActions.forEach((actionType) => {
      state.teamRoleTemplates[id][actionType] = {
        ...state.teamRoleTemplates[id][actionType],
        permission_value: role_template[actionType]
      };
    });
  });

  /**
   * One off permissions only contains the action types that are true.
   * We have to update one off mapping to keep this true.
   * Remove one off permissions that are false, and add ones that are true.
   */

  // Sample Response
  // const sample = {
  //   one_off_permission: {
  //     delete_boards: {
  //       true: [
  //         {
  //           account_id: 1,
  //           team_membership_id: 10
  //         },
  //         {
  //           account_id: 2,
  //           team_membership_id: 20
  //         }
  //       ],
  //       false: [
  //         {
  //           account_id: 3,
  //           team_membership_id: 30
  //         }
  //       ]
  //     },
  //     create_team_memberships: {
  //       true: [
  //         {
  //           account_id: 2,
  //           team_membership_id: 20
  //         }
  //       ],
  //       false: []
  //     }
  //   }
  // };

  (
    Object.entries(one_off_permission) as Entries<typeof one_off_permission>
  ).forEach(([actionType, { true: addedMembers, false: removedMembers }]) => {
    const existingOneOffPermissionInstances =
      state.oneOffPermissionersMap[actionType];

    if (existingOneOffPermissionInstances) {
      addedMembers.forEach((addedMember) => {
        if (
          !existingOneOffPermissionInstances.find(
            (member) =>
              member.team_membership_id === addedMember.team_membership_id
          )
        ) {
          existingOneOffPermissionInstances.push(addedMember);
        }
      });

      const removedMembersIds = removedMembers.map(
        (removedMember) => removedMember.team_membership_id
      );

      const oneOffPermissionsAfterRemove =
        existingOneOffPermissionInstances.filter(
          (member) => !removedMembersIds.includes(member.team_membership_id)
        );

      state.oneOffPermissionersMap[actionType] = oneOffPermissionsAfterRemove;
    }
  });
};

export const teamPermissionsReducer = createReducer(initialState, (builder) => {
  // Remove these, when we stop using legacy API.
  builder.addCase(
    fetchTeamRoleTemplatesActionCreatorsMap.success,
    handleFetchTeamRoleTemplatesSuccess
  );
  builder.addCase(
    updateTeamRoleTemplateActionCreatorsMap.success,
    handleUpdateTeamRoleTemplateSuccess
  );
  // ************ New grouped permission ************ //
  builder.addCase(togglePermissionOpen, handleTogglePermissionOpen);
  builder.addCase(toggleAllPermissionsOpen, handleToggleAllPermissionsOpen);
  // ************ Fetch team permissions ************ //
  builder.addCase(
    fetchTeamPermissionsActionCreatorsMap.success,
    handleFetchTeamPermissionsSuccess
  );
  // ************ Update team permissions ************ //
  builder.addCase(
    updateTeamPermissionsActionCreatorsMap.success,
    handleUpdateTeamPermissionsSuccess
  );
});
