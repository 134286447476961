import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { StyledDropdownItem } from 'BudgetModule/components/BudgetTable/styles';
import styled from 'styled-components';
import theme from 'theme';
import { getWidgetDetailModalId } from 'DashboardModule/selectors';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { noop } from 'appUtils';

const defaultStyles = { marginLeft: '5px' };
const emptyArray = [];

const DropdownItem = styled(StyledDropdownItem)`
  padding: 10px 12px;
  line-height: 1;
  ${({ disabled }) =>
    disabled ? `color: ${theme.colors.colorLightGray16};` : ''};
`;

const WidgetThreeDotMenu = ({
  menuItems = emptyArray,
  styles,
  className,
  placement,
  icon,
  disabled,
  tooltip
}) => {
  const widgetId = useSelector(getWidgetDetailModalId);
  const isNewWidget = widgetId === 'newWidget';

  const renderMenuItems = useMemo(() => {
    return menuItems.map((menu) => (
      <DropdownItem
        key={menu.name}
        onClick={menu.disabled ? noop : menu.onClick}
        disabled={menu.disabled}
        data-tip={menu.tooltip || ''}
        data-for={'app-tooltip'}
        data-effect={'solid'}
      >
        {menu.label}
      </DropdownItem>
    ));
  }, [menuItems]);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  if (isNewWidget) return null;

  return (
    <div
      data-for="app-tooltip"
      data-effect="solid"
      data-class="center"
      data-tip={tooltip || ''}
    >
      <ThreeDotMenu
        vertical={false}
        slide={false}
        style={styles || defaultStyles}
        className={className}
        placement={placement}
        overrideIsOpen={disabled ? false : undefined}
      >
        {renderMenuItems}
      </ThreeDotMenu>
    </div>
  );
};

export default WidgetThreeDotMenu;
