import React from 'react';
import {
  TertText,
  CellColorContainer,
  CellHeader,
  StyledIssue,
  CellHeaderColorContainer,
  ColorDot
} from './styles';
import CircledDot from 'icons/CircledDot';

import theme from 'theme';
const calcColor = {
  estimated: theme.colors.colorMediumGray2,
  spent: theme.colors.colorBudgetGreen,
  planned: theme.colors.colorPlannedHours,
  remaining: theme.colors.colorBudgetLightGreen
};
const BarBudget = ({
  name,
  value,
  header,
  dataTip,
  showOverdue = false,
  tooltipClass = '',
  showZero = false,
  hideHeader = false,
  onMembersDropdown = undefined
}) => {
  return (
    <CellHeaderColorContainer
      data-for="app-tooltip"
      data-tip={dataTip}
      data-effect="solid"
      data-delay-show={750}
      data-html
      data-class={tooltipClass || ''}
    >
      {!hideHeader && <CellHeader>{header} </CellHeader>}
      <CellColorContainer onMembersDropdown={onMembersDropdown}>
        {header === 'estimated' ? (
          <CircledDot style={{ marginRight: '4px' }} />
        ) : (
          <ColorDot cellColor={calcColor[header]} />
        )}
        {value || value === 0 ? `${value.toFixed(1)}`.replace(/\.0+$/, '') : ''}
        <TertText>
          {' h'}
          {value < 0 && showOverdue ? <StyledIssue>!</StyledIssue> : ''}
        </TertText>
      </CellColorContainer>
    </CellHeaderColorContainer>
  );
};

export default BarBudget;
