import React from 'react';
import theme from 'theme';
import SvgIcon from 'components/SvgIcon';

const StarIcon = ({
  className,
  isActive,
  fill = 'none',
  width = 17,
  height = 17,
  style
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 17 17"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2623 6.09645L11.0609 5.45173L9.17802 1.45719C9.03981 1.1659 8.76208 1 8.46479 1C8.1675 1 7.88976 1.18753 7.75156 1.45719L5.86872 5.45173L1.66729 6.09645C1.37 6.13751 1.11208 6.36749 1.0336 6.65767C0.954056 6.94923 1.01404 7.28186 1.23179 7.51073L4.28423 10.6317L3.57074 15.0215C3.51101 15.3336 3.65028 15.6457 3.88785 15.8333C4.12515 16.0205 4.46261 16.0413 4.71973 15.896L8.4854 13.8153L12.2511 15.896C12.3697 15.9589 12.4884 16 12.6279 16C12.7867 16 12.9643 15.9373 13.1038 15.8333C13.3411 15.6457 13.4608 15.3336 13.4207 15.0215L12.7074 10.6317L15.7596 7.51073C15.9776 7.28186 16.0569 6.94923 15.9578 6.65767C15.7969 6.34557 15.5596 6.13751 15.2623 6.09645Z"
      fill={isActive ? theme.colors.colorDarkDijonYellow : 'transparent'}
      stroke={
        isActive
          ? theme.colors.colorMediumYellow1
          : theme.colors.colorMediumGray9
      }
      strokeWidth={isActive ? '0.63' : '0.8'}
    />
  </SvgIcon>
);

export default StarIcon;
