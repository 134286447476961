import {
  OptionsDropdown,
  OptionHash
} from 'components/options/OptionsDropdownV2';
import { FilterField } from 'FilterModule/constants';
import { FilterListTypeDropdown } from 'FilterModule/types';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import invert from 'lodash/invert';

/**
 * Field will be treated as a boolean field.
 * ie. currentFilter[field] = true means archived, false means not archived, and undefined means all
 */
export const ArchivedStatusFilterDropdown: FilterListTypeDropdown = ({
  renderToggle,
  field = FilterField.archivedStatus
}) => {
  const { currentFilter } = useFilterContext();

  const handleSelect = (option: keyof typeof ARCHIVED_OPTIONS) => {
    currentFilter.update({
      [field]: optionToValueTranslation[option]
    });
  };

  const currentFilterValue = currentFilter[field];

  if (currentFilterValue === null || typeof currentFilterValue === 'boolean') {
    const translatedValue =
      valueToOptionTranslation[String(currentFilterValue)];

    return (
      <OptionsDropdown
        currentValue={translatedValue}
        options={options}
        optionHash={optionHash}
        renderToggle={({ isOpen }) =>
          renderToggle({
            isOpen,
            customLabel:
              translatedValue && (optionHash[translatedValue]?.label as string)
          })
        }
        sharedHandleClick={handleSelect}
      />
    );
  }

  return <></>;
};

/* ------------------------------------ - ----------------------------------- */

const ARCHIVED_OPTIONS = {
  ARCHIVED: 'ARCHIVED',
  ACTIVE: 'ACTIVE',
  ALL: 'ALL'
} as const;

const optionToValueTranslation = {
  [ARCHIVED_OPTIONS.ARCHIVED]: true,
  [ARCHIVED_OPTIONS.ACTIVE]: false,
  [ARCHIVED_OPTIONS.ALL]: null
};

const valueToOptionTranslation = invert(optionToValueTranslation);

const optionHash: OptionHash = {
  [ARCHIVED_OPTIONS.ARCHIVED]: {
    label: 'Archived'
  },
  [ARCHIVED_OPTIONS.ACTIVE]: {
    label: 'Active'
  },
  [ARCHIVED_OPTIONS.ALL]: {
    label: 'All'
  }
};

const options = Object.keys(ARCHIVED_OPTIONS);
