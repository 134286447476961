import * as constants from '../constants';
import { action } from 'appUtils';

export const fetchSpentHoursByDay = {
  request: () => action(constants.FETCH_SPENT_HOURS_BY_DAY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SPENT_HOURS_BY_DAY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_SPENT_HOURS_BY_DAY.FAILURE, { payload: { error } })
};
export const fetchPlannedHoursByDay = {
  request: () => action(constants.FETCH_PLANNED_HOURS_BY_DAY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PLANNED_HOURS_BY_DAY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PLANNED_HOURS_BY_DAY.FAILURE, { payload: { error } })
};

export const fetchMemberBudgets = {
  request: () => action(constants.FETCH_MEMBER_BUDGETS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_MEMBER_BUDGETS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_MEMBER_BUDGETS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const fetchMemberBudgetTotals = {
  request: () => action(constants.FETCH_MEMBER_BUDGET_TOTALS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_BUDGET_TOTALS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_BUDGET_TOTALS.FAILURE, { payload: { error } })
};
export const fetchMemberBudgetPhase = {
  request: () => action(constants.FETCH_MEMBER_BUDGET_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_BUDGET_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_BUDGET_PHASE.FAILURE, { payload: { error } })
};
export const createMemberBudget = {
  request: () => action(constants.CREATE_MEMBER_BUDGET.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_MEMBER_BUDGET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_MEMBER_BUDGET.FAILURE, { payload: { error } })
};
export const updateMemberBudget = {
  request: () => action(constants.UPDATE_MEMBER_BUDGET.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_MEMBER_BUDGET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_MEMBER_BUDGET.FAILURE, { payload: { error } })
};
export const assignMemberBudget = {
  request: () => action(constants.ASSIGN_MEMBER_BUDGET.REQUEST),
  success: (response, requestPayload, sagaPayload) =>
    action(constants.ASSIGN_MEMBER_BUDGET.SUCCESS, {
      payload: { response, requestPayload, sagaPayload }
    }),
  failure: (error, response, sagaPayload) =>
    action(constants.ASSIGN_MEMBER_BUDGET.FAILURE, {
      payload: { error, sagaPayload }
    })
};
export const deleteMemberBudget = {
  request: () => action(constants.DELETE_MEMBER_BUDGET.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_MEMBER_BUDGET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_BUDGET.FAILURE, { payload: { error } })
};

export const archiveMemberBudgetPhase = {
  request: () => action(constants.ARCHIVE_MEMBER_BUDGET_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_MEMBER_BUDGET_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_MEMBER_BUDGET_PHASE.FAILURE, {
      payload: { error }
    })
};
export const unarchiveMemberBudgetPhase = {
  request: () => action(constants.UNARCHIVE_MEMBER_BUDGET_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UNARCHIVE_MEMBER_BUDGET_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNARCHIVE_MEMBER_BUDGET_PHASE.FAILURE, {
      payload: { error }
    })
};

export const createPhaseEstimation = {
  request: () => action(constants.CREATE_PHASE_ESTIMATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_PHASE_ESTIMATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_PHASE_ESTIMATION.FAILURE, { payload: { error } })
};
export const updatePhaseEstimation = {
  request: () => action(constants.UPDATE_PHASE_ESTIMATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_PHASE_ESTIMATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PHASE_ESTIMATION.FAILURE, { payload: { error } })
};
export const deletePhaseEstimation = {
  request: () => action(constants.DELETE_PHASE_ESTIMATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_PHASE_ESTIMATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PHASE_ESTIMATION.FAILURE, { payload: { error } })
};

export const fetchAllTeamRates = {
  request: () => action(constants.FETCH_ALL_TEAM_RATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ALL_TEAM_RATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ALL_TEAM_RATES.FAILURE, {
      payload: { error }
    })
};

export const fetchMemberTeamRates = {
  request: () => action(constants.FETCH_MEMBER_TEAM_RATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_TEAM_RATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_TEAM_RATES.FAILURE, { payload: { error } })
};

export const createMemberTeamRate = {
  request: () => action(constants.CREATE_MEMBER_TEAM_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_MEMBER_TEAM_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_MEMBER_TEAM_RATE.FAILURE, {
      payload: { error }
    })
};

export const updateMemberTeamRate = {
  request: () => action(constants.UPDATE_MEMBER_TEAM_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_MEMBER_TEAM_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_MEMBER_TEAM_RATE.FAILURE, { payload: { error } })
};

export const deleteMemberTeamRate = {
  request: () => action(constants.DELETE_MEMBER_TEAM_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_MEMBER_TEAM_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_TEAM_RATE.FAILURE, { payload: { error } })
};

export const fetchRates = {
  request: () => action(constants.FETCH_RATES.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_RATES.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_RATES.FAILURE, { payload: { error }, meta })
};

export const fetchTeamMembersRates = {
  request: () => action(constants.FETCH_TEAM_MEMBERS_RATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_MEMBERS_RATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_MEMBERS_RATES.FAILURE, { payload: { error } })
};
export const updateTeamMemberRate = {
  request: () => action(constants.UPDATE_TEAM_MEMBER_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_TEAM_MEMBER_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_MEMBER_RATE.FAILURE, { payload: { error } })
};
export const createRate = {
  request: () => action(constants.CREATE_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_RATE.FAILURE, { payload: { error } })
};
export const updateRate = {
  request: () => action(constants.UPDATE_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_RATE.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const archiveRate = {
  request: () => action(constants.ARCHIVE_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_RATE.FAILURE, { payload: { error } })
};
export const unarchiveRate = {
  request: () => action(constants.UNARCHIVE_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UNARCHIVE_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UNARCHIVE_RATE.FAILURE, { payload: { error } })
};
// export const deleteRate = {
//   request: () => action(constants.DELETE_RATE.REQUEST),
//   success: (response, id, requestPayload) =>
//     action(constants.DELETE_RATE.SUCCESS, {
//       payload: { response, requestPayload }
//     }),
//   failure: error =>
//     action(constants.DELETE_RATE.FAILURE, { payload: { error } })
// };

export const fetchMemberRates = {
  request: () => action(constants.FETCH_MEMBER_RATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_RATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_RATES.FAILURE, { payload: { error } })
};
export const createMemberRate = {
  request: () => action(constants.CREATE_MEMBER_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_MEMBER_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_MEMBER_RATE.FAILURE, { payload: { error } })
};
export const updateMemberRate = {
  request: () => action(constants.UPDATE_MEMBER_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_MEMBER_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_MEMBER_RATE.FAILURE, { payload: { error } })
};
export const deleteMemberRate = {
  request: () => action(constants.DELETE_MEMBER_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_MEMBER_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_RATE.FAILURE, { payload: { error } })
};

/* --------------------------------- Salary --------------------------------- */
export const fetchAllTeamSalaries = {
  request: () => action(constants.FETCH_ALL_TEAM_SALARIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ALL_TEAM_SALARIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ALL_TEAM_SALARIES.FAILURE, { payload: { error } })
};
export const fetchMemberSalaries = {
  request: () => action(constants.FETCH_MEMBER_SALARIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_SALARIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_SALARIES.FAILURE, { payload: { error } })
};
export const createMemberSalary = {
  request: () => action(constants.CREATE_MEMBER_SALARY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_MEMBER_SALARY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_MEMBER_SALARY.FAILURE, { payload: { error } })
};
export const updateMemberSalary = {
  request: () => action(constants.UPDATE_MEMBER_SALARY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_MEMBER_SALARY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_MEMBER_SALARY.FAILURE, { payload: { error } })
};
export const deleteMemberSalary = {
  request: () => action(constants.DELETE_MEMBER_SALARY.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_MEMBER_SALARY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_SALARY.FAILURE, { payload: { error } })
};

/* -------------------------------- Overhead -------------------------------- */
export const fetchOverhead = {
  request: () => action(constants.FETCH_OVERHEAD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_OVERHEAD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_OVERHEAD.FAILURE, { payload: { error } })
};
export const createOverhead = {
  request: () => action(constants.CREATE_OVERHEAD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_OVERHEAD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_OVERHEAD.FAILURE, { payload: { error } })
};

export const fetchTeamRoles = {
  request: () => action(constants.FETCH_TEAM_ROLES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_ROLES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_ROLES.FAILURE, { payload: { error } })
};
export const createTeamRole = {
  request: () => action(constants.CREATE_TEAM_ROLE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_TEAM_ROLE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TEAM_ROLE.FAILURE, { payload: { error } })
};
export const updateTeamRole = {
  request: () => action(constants.UPDATE_TEAM_ROLE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_TEAM_ROLE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_ROLE.FAILURE, { payload: { error } })
};
// export const deleteTeamRole = {
//   request: () => action(constants.DELETE_TEAM_ROLE.REQUEST),
//   success: (response, id, requestPayload) =>
//     action(constants.DELETE_TEAM_ROLE.SUCCESS, {
//       payload: { response, requestPayload }
//     }),
//   failure: error =>
//     action(constants.DELETE_TEAM_ROLE.FAILURE, { payload: { error } })
// };

export const fetchProjectBudgetViewSettings = {
  request: () => action(constants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.FAILURE, {
      payload: { error }
    })
};

export const updateProjectBudgetViewSettings = {
  request: () => action(constants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchTeamBudgetViewSettings = {
  request: () => action(constants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.FAILURE, {
      payload: { error }
    })
};

export const updateTeamBudgetViewSettings = {
  request: () => action(constants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.FAILURE, {
      payload: { error }
    })
};

export const bulkAssignActivityEstimations = {
  request: () => action(constants.BULK_ASSIGN_ACTIVITY_ESTIMATIONS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.BULK_ASSIGN_ACTIVITY_ESTIMATIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_ASSIGN_ACTIVITY_ESTIMATIONS.FAILURE, {
      payload: { error }
    })
};
export const bulkAssignActivityScheduleBars = {
  request: () => action(constants.BULK_ASSIGN_ACTIVITY_SCHEDULE_BARS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.BULK_ASSIGN_ACTIVITY_SCHEDULE_BARS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_ASSIGN_ACTIVITY_SCHEDULE_BARS.FAILURE, {
      payload: { error }
    })
};
export const bulkAssignDescriptions = {
  request: () => action(constants.BULK_ASSIGN_DESCRIPTIONS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.BULK_ASSIGN_DESCRIPTIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_ASSIGN_DESCRIPTIONS.FAILURE, {
      payload: { error }
    })
};

export const bulkDeleteActivityScheduleBars = {
  request: () => action(constants.BULK_DELETE_ACTIVITY_SCHEDULE_BARS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.BULK_DELETE_ACTIVITY_SCHEDULE_BARS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_DELETE_ACTIVITY_SCHEDULE_BARS.FAILURE, {
      payload: { error }
    })
};

export const updateActivityPhase = {
  request: () => action(constants.UPDATE_ACTIVITY_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_ACTIVITY_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_ACTIVITY_PHASE.FAILURE, { payload: { error } })
};

export const hardDeleteActivityPhase = {
  request: () => action(constants.HARD_DELETE_ACTIVITY_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.HARD_DELETE_ACTIVITY_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.HARD_DELETE_ACTIVITY_PHASE.FAILURE, { payload: { error } })
};

/* --------------------------------- Billing -------------------------------- */
export const fetchProjectBillingCategories = {
  request: () => action(constants.FETCH_PROJECT_BILLING_CATEGORIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_BILLING_CATEGORIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_BILLING_CATEGORIES.FAILURE, {
      payload: { error }
    })
};

export const fetchProjectInvoices = {
  request: () => action(constants.FETCH_PROJECT_INVOICES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_INVOICES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_INVOICES.FAILURE, { payload: { error } })
};

export const createProjectInvoice = {
  request: () => action(constants.CREATE_PROJECT_INVOICE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_PROJECT_INVOICE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_INVOICE.FAILURE, { payload: { error } })
};

export const updateProjectInvoice = {
  request: () => action(constants.UPDATE_PROJECT_INVOICE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_PROJECT_INVOICE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_PROJECT_INVOICE.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const deleteProjectInvoice = {
  request: () => action(constants.DELETE_PROJECT_INVOICE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_PROJECT_INVOICE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_INVOICE.FAILURE, {
      payload: { error }
    })
};

/* ----------------------------- Budget records ----------------------------- */
export const fetchBudgetRecords = {
  request: () => action(constants.FETCH_BUDGET_RECORDS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_BUDGET_RECORDS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_BUDGET_RECORDS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};
export const fetchProjectBudgetRecord = {
  request: () => action(constants.FETCH_PROJECT_BUDGET_RECORD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_BUDGET_RECORD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_PROJECT_BUDGET_RECORD.FAILURE, {
      payload: { error, requestPayload }
    })
};

/* ----------------------------- Positions ----------------------------- */

export const fetchPositions = {
  request: () => action(constants.FETCH_POSITIONS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_POSITIONS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_POSITIONS.FAILURE, { payload: { error }, meta })
};
export const fetchPosition = {
  request: () => action(constants.FETCH_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_POSITION.FAILURE, { payload: { error } })
};
export const createPosition = {
  request: () => action(constants.CREATE_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_POSITION.FAILURE, { payload: { error } })
};
export const updatePosition = {
  request: () => action(constants.UPDATE_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_POSITION.FAILURE, { payload: { error } })
};
export const deletePosition = {
  request: () => action(constants.DELETE_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_POSITION.FAILURE, { payload: { error } })
};
export const archivePosition = {
  request: () => action(constants.ARCHIVE_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_POSITION.FAILURE, { payload: { error } })
};

/* ----------------------------- Team Positions ----------------------------- */

export const fetchTeamPositions = {
  request: () => action(constants.FETCH_TEAM_POSITIONS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_POSITIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_POSITIONS.FAILURE, { payload: { error } })
};
export const createTeamPosition = {
  request: () => action(constants.CREATE_TEAM_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_TEAM_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TEAM_POSITION.FAILURE, { payload: { error } })
};
export const updateTeamPosition = {
  request: () => action(constants.UPDATE_TEAM_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_TEAM_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_POSITION.FAILURE, { payload: { error } })
};
export const deleteTeamPosition = {
  request: () => action(constants.DELETE_TEAM_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_TEAM_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_TEAM_POSITION.FAILURE, { payload: { error } })
};

/* ----------------------------- Member Positions ----------------------------- */

export const fetchMemberPositions = {
  request: () => action(constants.FETCH_MEMBER_POSITIONS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_POSITIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_POSITIONS.FAILURE, { payload: { error } })
};
export const createMemberPosition = {
  request: () => action(constants.CREATE_MEMBER_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_MEMBER_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_MEMBER_POSITION.FAILURE, { payload: { error } })
};
export const updateMemberPosition = {
  request: () => action(constants.UPDATE_MEMBER_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_MEMBER_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_MEMBER_POSITION.FAILURE, { payload: { error } })
};
export const deleteMemberPosition = {
  request: () => action(constants.DELETE_MEMBER_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_MEMBER_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_POSITION.FAILURE, { payload: { error } })
};

/* ---------------------------- Account Positions --------------------------- */

export const fetchAccountPositions = {
  request: () => action(constants.FETCH_ACCOUNT_POSITIONS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ACCOUNT_POSITIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ACCOUNT_POSITIONS.FAILURE, { payload: { error } })
};
export const createAccountPosition = {
  request: () => action(constants.CREATE_ACCOUNT_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_ACCOUNT_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_ACCOUNT_POSITION.FAILURE, { payload: { error } })
};
export const updateAccountPosition = {
  request: () => action(constants.UPDATE_ACCOUNT_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_ACCOUNT_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_ACCOUNT_POSITION.FAILURE, { payload: { error } })
};
export const deleteAccountPosition = {
  request: () => action(constants.DELETE_ACCOUNT_POSITION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_ACCOUNT_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_ACCOUNT_POSITION.FAILURE, { payload: { error } })
};

/* ----------------------------- Position Rates ----------------------------- */

export const fetchPositionRates = {
  request: () => action(constants.FETCH_POSITION_RATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_POSITION_RATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_POSITION_RATES.FAILURE, { payload: { error } })
};
export const createPositionRate = {
  request: () => action(constants.CREATE_POSITION_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_POSITION_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_POSITION_RATE.FAILURE, { payload: { error } })
};
export const updatePositionRate = {
  request: () => action(constants.UPDATE_POSITION_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_POSITION_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_POSITION_RATE.FAILURE, { payload: { error } })
};
export const deletePositionRate = {
  request: () => action(constants.DELETE_POSITION_RATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_POSITION_RATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_POSITION_RATE.FAILURE, { payload: { error } })
};

export const updatePositionSkills = {
  request: () => action(constants.UPDATE_POSITION_SKILLS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_POSITION_SKILLS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_POSITION_SKILLS.FAILURE, { payload: { error } })
};

export const createPositionSkills = {
  request: () => action(constants.CREATE_POSITION_SKILLS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_POSITION_SKILLS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_POSITION_SKILLS.FAILURE, { payload: { error } })
};

export const fetchPositionSkills = {
  request: () => action(constants.FETCH_POSITION_SKILLS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_POSITION_SKILLS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_POSITION_SKILLS.FAILURE, { payload: { error } })
};

export const deletePositionSkills = {
  request: () => action(constants.DELETE_POSITION_SKILLS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_POSITION_SKILLS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_POSITION_SKILLS.FAILURE, { payload: { error } })
};

export const updatePositionLocationRange = {
  request: () => action(constants.UPDATE_POSITION_LOCATION_RANGE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_POSITION_LOCATION_RANGE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_POSITION_LOCATION_RANGE.FAILURE, {
      payload: { error }
    })
};

export const deletePositionLocationRange = {
  request: () => action(constants.DELETE_POSITION_LOCATION_RANGE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_POSITION_LOCATION_RANGE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_POSITION_LOCATION_RANGE.FAILURE, {
      payload: { error }
    })
};
