import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import DateNavRightArrowIcon from 'icons/DateNavRightArrowIcon';
import DateNavLeftArrowIcon from 'icons/DateNavLeftArrowIcon';

const DateNavContainer = styled.div`
  z-index: 100;
  display: flex;
  margin-left: 2px;
`;

const TodayButton = styled.div<{ isTodayOnScreen: boolean }>`
  font-size: 12px;
  padding: 0 8px;
  cursor: pointer;
  color: ${(props) =>
    props.isTodayOnScreen
      ? theme.colors.colorLightGray11
      : theme.colors.colorCalendarOrange};
  border: 1px solid transparent;
  border-radius: 4px;
  margin-top: -5px;
  font-weight: 400;
  margin-left: -8px;
 ${({ isTodayOnScreen }) =>
   !isTodayOnScreen &&
   `
    &:hover {
      background:  ${theme.colors.colorCalendarOrange};
      color : white;
    }
 `}
}
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  margin-right: 8px;
`;

const TopRow = styled.div`
  display: flex;
`;

const ArrowContainer = styled.div`
  cursor: pointer;
  margin: 0 6px;
  &:hover {
    path {
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
`;

const TodayButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

interface TimesheetDateNavProps {
  scrollBack: () => void;
  scrollForward: () => void;
  scrollToToday: () => void;
  dateRenderer: () => JSX.Element;
  isTodayOnScreen: boolean;
}

const TimesheetDateNav = ({
  scrollBack,
  scrollForward,
  scrollToToday,
  dateRenderer,
  isTodayOnScreen
}: TimesheetDateNavProps) => {
  return (
    <DateNavContainer className="date-navigator">
      <div>
        <TopRow>
          <HeaderText>{dateRenderer()}</HeaderText>
          <ArrowContainer onClick={scrollBack}>
            <DateNavLeftArrowIcon height="15" width="10" />
          </ArrowContainer>
          <ArrowContainer onClick={scrollForward}>
            <DateNavRightArrowIcon height="15" width="10" />
          </ArrowContainer>
        </TopRow>
        <TodayButtonContainer>
          <TodayButton
            onClick={scrollToToday}
            isTodayOnScreen={isTodayOnScreen}
          >
            Today
          </TodayButton>
        </TodayButtonContainer>
      </div>
    </DateNavContainer>
  );
};

export default TimesheetDateNav;
