import { createSelector } from 'reselect';
import { getOnWorkloadView } from './navigations';
import { getMyTeamMembership } from 'TeamsModule/selectors';

export const getMyWorkloadSettings = createSelector(
  getMyTeamMembership,
  (teamMembership) => {
    const workloadSettings = teamMembership?.work_load_settings;
    if (workloadSettings?.display_member_capacity === true) {
      return {
        ...workloadSettings,
        display_member_capacity: 'percent'
      };
    }
    return workloadSettings;
  }
);

export const getMyPlannerSettings = createSelector(
  getMyTeamMembership,
  (teamMembership) => {
    const plannerSettings = teamMembership?.planner_settings;
    return {
      ...plannerSettings,
      display_member_capacity: plannerSettings?.work_plan_display
    };
  }
);
export const getMyWorkPlanSettings = createSelector(
  getOnWorkloadView,
  getMyWorkloadSettings,
  getMyPlannerSettings,
  (isWorkloadView, workloadSettings, plannerSettings) => {
    return isWorkloadView
      ? workloadSettings
      : {
          ...plannerSettings,
          // adding missing setting property will fix issue temporarly
          // FIXME: should compose two kinds of settings and return general setting
          // for WorkloadPlannerTimelineContainer component
          display_member_capacity:
            workloadSettings?.display_member_capacity === 'percent'
              ? 'percent'
              : 'total_hours',
          capacity_heat_map_color: workloadSettings?.capacity_heat_map_color,
          // include_weekends is only defined in workloadSettings
          // this fixes type issue
          include_weekends: false
        };
  }
);
