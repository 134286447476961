import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MoveIcon = () => (
  <SvgIcon width="16" height="11" viewBox="0 0 16 11">
    <path
      d="M8.1834 1.04502C8.11067 1.09947 8.08083 1.19454 8.1144 1.27946L9.12143 3.79374H0.706067C0.593242 3.79374 0.5 3.88604 0.5 3.99772V5.50561V7.01349C0.5 7.12425 0.593244 7.21009 0.706067 7.21009H9.12143L8.1144 9.72437C8.08083 9.80836 8.10973 9.90436 8.1834 9.95881C8.25613 10.0133 8.35496 10.0142 8.42768 9.95881L14.5 5.5C14.5504 5.46216 14.5839 5.39663 14.5839 5.33387C14.5839 5.2711 14.5504 5.21295 14.5 5.17511L8.42768 1.04523C8.39132 1.01754 8.34936 1 8.30553 1C8.26265 0.999791 8.21976 1.01825 8.1834 1.04502Z"
      fill="transparent"
      stroke="#0074D9"
    />
  </SvgIcon>
);

export default MoveIcon;
