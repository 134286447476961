import {
  StyledAdminName,
  StyledAdminRow,
  StyledAdminEmail,
  RemoveText
} from './styles';
import { updateTeamMembersPermission } from 'PermissionsModule/actionCreators';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { TeamMemberWrapper } from 'TeamsModule/types';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const AdminsText = ({ admin }: { admin: TeamMemberWrapper }) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const removeAdmin = () => {
    if (teamId) {
      dispatch(
        updateTeamMembersPermission({
          teamMembershipIds: [admin.id],
          teamRole: ACCESS_ROLES.TEAM_MEMBER,
          teamId
        })
      );
    }
  };

  return (
    <StyledAdminRow>
      {/* <MemberInitials member={admin.account.id} /> */}
      <StyledAdminName>{admin.account.name}</StyledAdminName>
      <StyledAdminEmail>{admin.account.email}</StyledAdminEmail>
      <RemoveText onClick={removeAdmin}>Remove</RemoveText>
    </StyledAdminRow>
  );
};
export default AdminsText;
