import * as entityActions from '../actions';
import { changeEntity } from './generics';
import { api } from '../service';

const { taskAttachmentDelete, projectAttachmentDelete } = entityActions;

export function* deleteTaskAttachment(action) {
  const { token, attachmentId } = action.payload;
  const { error, response } = yield changeEntity(
    taskAttachmentDelete,
    api.deleteAttachment,
    [token, attachmentId]
  );
}

export function* deleteProjectAttachment(action) {
  const { token, attachmentId } = action.payload;
  const { error, response } = yield changeEntity(
    projectAttachmentDelete,
    api.deleteAttachment,
    [token, attachmentId]
  );
}
