import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import * as constants from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { SupportedMfaStatus } from 'AuthenticationModule/types';

const UPDATE_TEAM_AUTH_SETTINGS = createRequestTypes(
  'UPDATE_TEAM_AUTH_SETTINGS'
);

export const fetchOrganizationSettings = createAction(
  constants.FETCH_ORGANIZATION_SETTINGS.TRIGGER
);

export const updateOrganizationSettings = createAction(
  constants.UPDATE_ORGANIZATION_SETTINGS.TRIGGER
);

type UpdateTeamAuthSettingsPayload = ActionParamsWithMeta<
  UpdateTeamAuthSettingsInitialParams,
  IMeta<() => void>
>;

export const updateTeamAuthSettings = createAction(
  UPDATE_TEAM_AUTH_SETTINGS.TRIGGER,
  ({ meta, ...payload }: UpdateTeamAuthSettingsPayload) => ({ meta, payload })
);

export const updateTeamAuthSettingsActionCreatorsMap =
  createActionCreatorsMap<UpdateTeamAuthSettingsInitialParams>(
    UPDATE_TEAM_AUTH_SETTINGS
  );

type GetAuthMfaStatusPerAccountPayload = ActionParamsWithMeta<
  GetAuthMfaStatusPerAccountInitialParams,
  IMeta<() => void>
>;

export const getAuthMfaStatusPerAccount = createAction(
  constants.GET_AUTH_MFA_STATUS_PER_ACCOUNT.TRIGGER,
  ({ meta, ...payload }: GetAuthMfaStatusPerAccountPayload) => ({
    meta,
    payload
  })
);

type ToggleIndividualMfaPayload = ActionParamsWithMeta<
  ToggleIndividualMfaInitialParams,
  IMeta<() => void>
>;

export const toggleIndividualMfa = createAction(
  constants.TOGGLE_INDIVIDUAL_MFA.TRIGGER,
  ({ meta, ...payload }: ToggleIndividualMfaPayload) => ({ meta, payload })
);

export const toggleMfaReset = createAction<ToggleMfaResetInitialParams>(
  constants.TOGGLE_MFA_RESET.TRIGGER
);

interface UpdateTeamAuthSettingsInitialParams {
  teamMfaSetting: SupportedMfaStatus;
}

interface GetAuthMfaStatusPerAccountInitialParams {
  accountIds: number[];
}

interface ToggleIndividualMfaInitialParams {
  isEnableMfa: boolean;
  accountIds: number[];
  mfaType: SupportedMfaStatus;
}

interface ToggleMfaResetInitialParams {
  email: string;
  targetMfa: SupportedMfaStatus;
  onSuccess?: [
    {
      successAction: (selectorValue) => void;
      selector?: (payload, response) => void;
    }
  ];
}
