import { MemberRowLeadingCell } from '../Cells/MemberRowLeadingCell';

export const ROW_TYPES = {
  Header: 'Header', // sticky header for FlatTableTemplate
  inlineCreateGroupRow: 'inlineCreateGroupRow', // only for GroupedTable
  groupRow: 'groupRow', // only for GroupedTable
  memberRow: 'memberRow',
  footerRow: 'footerRow', // having actionable elements for each group
  emptyRow: 'emptyRow',
  loadMoreRow: 'loadMoreRow', // only for GroupedTable to observe element to determine lazyload
  loadingRow: 'loadingRow' // show skelentons with this row
};

export const tableWidthColumn = {
  headerType: 'tableWidth',
  headerLabel: '',
  id: 'tableWidth'
};

export const groupHeaderColumn = {
  headerType: 'groupHeader',
  headerLabel: '',
  id: 'groupHeader'
};

export const leadingColumnProp = {
  id: 'leadingColumn',
  label: '',
  width: 40,
  rowCells: {
    memberRow: MemberRowLeadingCell
  }
};
