import { getAuthToken, getMe } from 'selectors';
import { configMap } from './configMap';
import * as constants from 'appConstants';
import { mosaicAPIClients } from 'appCore/API/apiClients';
import { getRealmId } from 'AuthenticationModule/selectors';
import { buildRealmURL } from 'appCore/API/utils';

const findSubscription = (id, channel) => (subscription) =>
  JSON.stringify({ channel, id }) === subscription.identifier;

const makeConfigs = (action, { dispatch, getState }) => {
  const configs = configMap[action.type];
  return configs.map((config) => ({
    ...config,
    handleReceive: (data) => {
      const me = getMe(getState());
      const userId = me && me.id;
      return dispatch(config.handleReceive({ ...data, userId }));
    }
  }));
};

const createChannel = (config) => (id) => ({ channel: config.channel, id });

const handleReceived = (config) => (data) => config.handleReceive(data);

const createOptions = (config) => ({ received: handleReceived(config) });

const makeCreateSubscription = (config) => (cable) => (id) =>
  !cable.subscriptions.subscriptions.find(findSubscription(id, config.channel))
    ? cable.subscriptions.create(
        createChannel(config)(id),
        createOptions(config)
      )
    : null;

let cable;
const createSocketsMiddleware =
  (makeCable) => (store) => (next) => (action) => {
    if (typeof action === 'function') {
      return next(action);
    }

    if (!cable) {
      const token = getAuthToken(store.getState());
      const realmId = getRealmId(store.getState());
      if (token) {
        const baseURL = mosaicAPIClients.mosaicWS.config.baseURL;
        // when the user login in the sandbox,
        // realmId in the APIClients will be updated after the store is created.
        // so should build the baseURL with the realmId while the store is created.
        const urlToUse = realmId
          ? buildRealmURL({ baseURL, realmId })
          : baseURL;
        cable = makeCable(urlToUse, token);
      }
    }
    if (
      action.type === constants.LOGOUT_USER &&
      cable?.subscriptions?.subscriptions
    ) {
      cable.subscriptions.subscriptions.forEach((subscription) =>
        cable.subscriptions.remove(subscription)
      );
      return next(action);
    }

    const shouldSetUpSubscriptions = !!configMap[action.type];
    const shouldCleanSubscriptions = action.payload && action.payload.channels;
    if (!shouldSetUpSubscriptions && !shouldCleanSubscriptions) {
      return next(action);
    }
    if (shouldSetUpSubscriptions && cable) {
      const configs = makeConfigs(action, store);
      configs.forEach((config) => {
        const createSubscription = makeCreateSubscription(config)(cable);
        config.getIds(action).forEach(createSubscription);
      });
    }
    if (shouldCleanSubscriptions) {
      const { channels, ids } = action.payload;
      channels.forEach((channel) => {
        // often array of 1, mapped for flexibility to extend
        ids.forEach((id) => {
          // often array of 1, mapped for flexibility to extend
          const subscription = cable.subscriptions.subscriptions.find(
            findSubscription(id, channel)
          );
          if (subscription) {
            return cable.subscriptions.remove(subscription);
          }
        });
      });
    }

    return next(action);
  };

export default createSocketsMiddleware;
