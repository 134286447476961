import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CollapseAllIcon = ({ fill = '#a4a4a4a' }) => {
  return (
    <SvgIcon width="10" height="11" viewBox="0 0 10 11">
      <path
        d="M0.711516 0.00324949L2.80864 2.10038L4.99717 4.28841L7.37524 1.91034L9.28565 -7.12767e-05L10 0.714763L5 5.71476L-3.12433e-08 0.714763L0.711516 0.00324949Z"
        fill={fill}
      />
      <path
        d="M0.711516 5.00325L2.80864 7.10038L4.99717 9.28841L7.37524 6.91034L9.28565 4.99993L10 5.71476L5 10.7148L-3.12433e-08 5.71476L0.711516 5.00325Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default CollapseAllIcon;
