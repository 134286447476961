import * as constants from 'appConstants';

export const initialState = {
  isOpen: false,
  modalCapacityId: null,
  modalCapacityType: null
};

const workload = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.OPEN_WORKLOAD_MODAL: {
      const { id, type } = payload;
      return {
        isOpen: true,
        modalCapacityId: id,
        modalCapacityType: type
      };
    }
    case constants.CLOSE_WORKLOAD_MODAL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default workload;
