import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type Props = Pick<SvgIconProps, 'className' | 'width' | 'height' | 'fill'>;

export const BlockingIcon = ({
  width = 11,
  height = 11,
  className,
  fill = '#A4A4A4'
}: Props) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 11.5 11.5"
    fill="none"
  >
    <path
      d="M6 0.5C2.96829 0.5 0.5 2.96829 0.5 6C0.5 9.03171 2.96829 11.5 6 11.5C9.03171 11.5 11.5 9.03171 11.5 6C11.5 2.96829 9.03171 0.5 6 0.5ZM10.6951 6C10.6951 7.07317 10.3195 8.06585 9.70244 8.87073L2.99512 2.40488C3.8 1.70732 4.87317 1.30488 6 1.30488C8.57561 1.30488 10.6951 3.42439 10.6951 6ZM1.30488 6C1.30488 4.84634 1.73415 3.8 2.40488 2.96829L9.13902 9.46098C8.30732 10.2122 7.20732 10.6951 5.97317 10.6951C3.42439 10.6951 1.30488 8.57561 1.30488 6Z"
      fill={fill}
    />
  </SvgIcon>
);
