import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamSlug, makeGetProjectBasicInfoById } from 'selectors';
import { navigateToProject } from 'actionCreators';

const useGoToProject = ({ projectId, openInNewTab = true }) => {
  const dispatch = useDispatch();
  const getProject = useSelector(makeGetProjectBasicInfoById);
  const project = useSelector((state) => getProject(state, { projectId }));
  const teamSlug = useSelector(getTeamSlug);

  const navToProject = useCallback(() => {
    if (project) {
      dispatch(
        navigateToProject({
          teamSlug: teamSlug,
          projectId,
          projectSlug: project.slug,
          openInNewWindow: openInNewTab
        })
      );
    }
  }, [dispatch, project, projectId, teamSlug, openInNewTab]);

  return {
    navToProject
  };
};

export default useGoToProject;
