import React, { DragEvent, useState, useCallback } from 'react';
import styled from 'styled-components';

const useAttachments = ({
  onFilesDrop
}: {
  onFilesDrop: (files: File[]) => void;
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  // const [dataUrlHash, setDataUrlHash] = useState({}); eg. if we ever want thumbnails

  const handleFileDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const files: File[] = [];
      if (e.dataTransfer.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          const item = e.dataTransfer.items[i];
          if (item && item?.kind === 'file') {
            const file = item.getAsFile();
            file && files.push(file);
          }
        }
      } else {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const file = e.dataTransfer.files[i];
          file && files.push(file);
        }
      }
      // files.map(file => {
      //   if (file.type.includes('image')) {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(file);
      //     reader.onload = () => {
      //       setDataUrlHash(currentDataUrlHash => ({
      //         ...currentDataUrlHash,
      //         [file.name]: reader.result
      //       }));
      //     };
      //   }
      // });
      onFilesDrop(files);
      setIsDraggingOver(false);
    },
    [onFilesDrop]
  );

  const onDragEnter = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDraggingOver) {
        setIsDraggingOver(true);
      }
    },
    [isDraggingOver]
  );

  // Necessary and prevents bug like behaviour
  const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const onDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
  }, []);

  const DragOverArea = () => {
    if (!isDraggingOver) return null;
    return (
      <StyledDragOverArea
        className="drag-over-area"
        onDrop={handleFileDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      />
    );
  };

  return {
    isDraggingOver,
    // dataUrlHash,
    handleFileDrop,
    onDragEnter,
    onDragOver,
    onDragLeave,
    DragOverArea
  };
};

export default useAttachments;

/* ------------------------------------ - ----------------------------------- */

const StyledDragOverArea = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.3;
  z-index: 1;
`;
