import styled from 'styled-components';
import theme from 'theme';

export const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.div`
  cursor: pointer;
  width: 70px;
  height: 32.32.px;
  background-color: ${({ secondary }) => (secondary ? '#ffffff' : '#0074d9')};
  color: ${({ secondary }) => (secondary ? '#828282' : '#ffffff')};
  border-radius: 5px;
  ${({ secondary }) =>
    secondary && `border: 1px solid ${theme.colors.colorMediumGray3};`}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    background-color: ${theme.colors.colorLightGray11};
    cursor: not-allowed;
  `}
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: #333333;
  margin-bottom: -6px;
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #4f4f4f;
`;

export const ButtonDiv = styled.div`
  display: flex;
  height: 32px;
  justify-content: space-evenly;
  ${(props) => props.style};
`;

export const ConfirmationMsg = styled.div`
  padding: 0 20px 20px;
`;

export const DateContainer = styled.div`
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorCalendarBlue};
`;

export const DateText = styled.div`
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
