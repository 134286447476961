import { lazy } from 'react';

const BudgetModalBodyAdapter = lazy(() =>
  import('BudgetModule/components/BudgetModal/BudgetModalBodyAdapter')
);
const BudgetTimeTab = lazy(() =>
  import('BudgetModule/components/BudgetModal/TimeTab')
);
const ProfitTab = lazy(() =>
  import('ReportsModule/components/Profit/Charts/ProfitTab')
);
const BillingTableContainer = lazy(() =>
  import('BudgetModule/components/BillingTable/BillingTableContainer')
);
const VarianceTab = lazy(() =>
  import('ReportsModule/components/Budget/VarianceTab')
);
const ScopeTab = lazy(() => import('components/Scope/ScopeTable/ScopeTab'));

export const BudgetModalViews = {
  time: BudgetTimeTab,
  budget: BudgetModalBodyAdapter,
  billing: BillingTableContainer,
  profit: ProfitTab,
  variance: VarianceTab,
  scopes: ScopeTab
};
