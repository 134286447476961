import produce from 'immer';
import { PropsWithChildren } from 'react';
import { ItemRendererGetResizePropsReturnType } from 'react-calendar-timeline';
import styled from 'styled-components';

interface ResizeHandleProps {
  // 'left' and 'right' from getResizeProps has same type
  resizeProps: ItemRendererGetResizePropsReturnType['left'];
  isNarrow?: boolean;
}
export const ResizeHandle = ({
  resizeProps,
  isNarrow,
  children
}: PropsWithChildren<ResizeHandleProps>) => {
  const adjustedResizeProps = isNarrow
    ? produce(resizeProps, (draft) => {
        delete draft?.style?.height;
        delete draft?.style?.top;
      })
    : resizeProps;

  return (
    <ResizeHandleContainer {...adjustedResizeProps}>
      {children}
    </ResizeHandleContainer>
  );
};

const ResizeHandleContainer = styled.div``;
