import { createSelector } from 'reselect';
import {
  initialState as budgetRecordsInitialState,
  initialFilterState as budgetRecordsInitialFilterState
} from '../reducers/budgetRecords';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import { serializeId } from 'appUtils';
import moment from 'moment';

const emptyObj = {};

export const getBudgetRecordsState = (state) =>
  state.budgetRecords || budgetRecordsInitialState;

export const getModalBudgetRecordsState = (state) =>
  state.modalBudgetRecords || budgetRecordsInitialState;

const getIsInModal = (state, ownProps) => ownProps?.isInModal;

export const makeGetBudgetRecordsState = () =>
  createSelector(
    getBudgetRecordsState,
    getModalBudgetRecordsState,
    getIsInModal,
    (budgetRecords, modalBudgetRecords, isInModal) => {
      return isInModal ? modalBudgetRecords : budgetRecords;
    }
  );

export const makeGetBudgetRecordsAccountProjectsTotals = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) => state.totalCounts[BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT]
  );

export const makeGetProjectBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.PROJECT] || {}
  );

export const makeGetPhaseBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.PHASE] || {}
  );

export const makeGetPhaseActivityBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.PHASE_ACTIVITY] ||
      {}
  );

export const makeGetAccountPhaseActivityBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[
        BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE_ACTIVITY
      ] || {}
  );

export const makeGetAccountBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.ACCOUNT] || {}
  );

export const makeGetAccountProjectBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT] ||
      {}
  );

export const makeGetAccountPhaseBudgetRecords = () =>
  createSelector(
    makeGetBudgetRecordsState(),
    (state) =>
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE] || {}
  );

const getOwnFilterStateId = (state, ownProps) =>
  ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id;

export const makeGetBudgetRecordsByFilterStateId = () =>
  createSelector(
    getOwnFilterStateId,
    makeGetBudgetRecordsState(),
    (filterStateId, state) =>
      state.filterStates[filterStateId] || budgetRecordsInitialFilterState
  );

export const makeGetProjectBudgetRecordsByFilterStateId = () =>
  createSelector(makeGetBudgetRecordsByFilterStateId(), (state) => {
    return (
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.PROJECT] || emptyObj
    );
  });

export const makeGetAccountPhaseBudgetRecordsByFilterStateId = () =>
  createSelector(makeGetBudgetRecordsByFilterStateId(), (state) => {
    return (
      state.recordsByAggregateType[BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE] ||
      emptyObj
    );
  });

export const makeGetIsFetchingBudgetRecordsByFilterStateId = () =>
  createSelector(
    makeGetBudgetRecordsByFilterStateId(),
    (state) => state.isFetching
  );

export const getBudgetRecordsData = (state) => state.budgetRecords.data;

export const makeGetBillableHoursList = () =>
  createSelector(
    makeGetBudgetRecordsByFilterStateId(),
    getBudgetRecordsData,
    (filterState, data) => {
      const topLevelOrder = filterState.topLevelOrder;
      const projectData = {};
      topLevelOrder.forEach((accountId) => {
        const uid = serializeId({
          ids: [accountId],
          itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT
        });

        const projectOrders = filterState.ordersByGroup[uid] || [];
        projectOrders.forEach((projectId) => {
          const accountProjectUid = serializeId({
            ids: [accountId, projectId],
            itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT
          });

          if (!projectData[uid]) {
            projectData[projectId] = {
              project_id: projectId,
              billable: 0
            };
          }

          projectData[projectId].billable +=
            +data[accountProjectUid]?.spent_hours_billable || 0;
        });
      });

      return Object.values(projectData);
    }
  );

export const makeGetProjectDataByAccounts = () =>
  createSelector(
    makeGetBudgetRecordsByFilterStateId(),
    getBudgetRecordsData,
    (filterState, data) => {
      const topLevelOrder = filterState.topLevelOrder;
      const accountProjectData = {};
      topLevelOrder.forEach((accountId) => {
        const uid = serializeId({
          ids: [accountId],
          itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT
        });

        const projectOrders = filterState.ordersByGroup[uid] || [];
        projectOrders.forEach((projectId) => {
          const accountProjectUid = serializeId({
            ids: [accountId, projectId],
            itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT
          });

          if (!accountProjectData[uid]) {
            accountProjectData[uid] = {};
          }

          accountProjectData[uid][projectId] = data[accountProjectUid];
        });
      });

      return accountProjectData;
    }
  );

export const makeGetProjectIdsByAccounts = () =>
  createSelector(makeGetBudgetRecordsByFilterStateId(), (filterState) => {
    const topLevelOrder = filterState.topLevelOrder;
    const projectIds = [];
    topLevelOrder.forEach((accountId) => {
      const uid = serializeId({
        ids: [accountId],
        itemType: BUDGET_RECORD_DATA_TYPES.ACCOUNT
      });

      const projectOrders = filterState.ordersByGroup[uid] || [];
      projectOrders.forEach((projectId) => {
        projectIds.push(projectId);
      });
    });
    return [...new Set(projectIds)];
  });

export const makeGetBudgetRecordsIntervalData = () =>
  createSelector(
    makeGetBudgetRecordsByFilterStateId(),
    (filterState) => filterState.intervalRecords
  );

export const makeGetBudgetRecordsIntervalDataWithMaxValue = () =>
  createSelector(makeGetBudgetRecordsIntervalData(), (intervalRecords) =>
    intervalRecords.map((record) => ({
      ...record,
      // coerce string types to numbers. BarChart from 'recharts' can read stringified numbers with no problem
      // but LineChart can't read some of the values when they are stringified numbers for some reason.
      planned_hours: +record.planned_hours,
      spent_hours: +record.spent_hours,
      total_capacity: +record.total_capacity,
      maxValue: Math.max(
        +record.planned_hours,
        +record.spent_hours,
        +record.spent_hours_forecast || 0
      )
    }))
  );
