import React from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { Drawer } from 'antd';
import 'antd/lib/drawer/style/css';
import { getIsActivitySideMenuOpen } from 'selectors';
import { closeActivitySideMenu } from 'actionCreators';
import ActivitySideMenu from 'views/layoutStructuralComponents/ActivitySideMenu';

const GlobalActivityDrawer = () => {
  const dispatch = useAppDispatch();

  const isActivitySideMenuOpen = useAppSelector(getIsActivitySideMenuOpen);

  const handleClose = () => {
    dispatch(closeActivitySideMenu());
  };

  return (
    <Drawer
      onClose={handleClose}
      placement="right"
      visible={isActivitySideMenuOpen}
      closable={false}
      width={419}
      mask={false}
    >
      {isActivitySideMenuOpen && <ActivitySideMenu />}
    </Drawer>
  );
};

export default GlobalActivityDrawer;
