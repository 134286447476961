import { put, select } from 'redux-saga/effects';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import { fetchEntity, changeEntity } from 'sagas/generics';
import {
  fetchLocationsByTeamMembership,
  fetchEntityLocations,
  fetchEntityLocationsActionCreatorsMap,
  createOrUpdateEntityLocation,
  deleteEntityLocation,
  deleteEntityLocationActionCreatorsMap,
  associateEntityToLocation,
  associateEntityToLocationActionCreatorsMap,
  fetchLocations,
  fetchLocationsActionCreatorsMap
} from 'SettingsModule/actionCreators/settings/location';
import {
  fetchLocationsByTeamMembershipActionCreatorsMap,
  createOrUpdateEntityLocationActionCreatorsMap
} from 'SettingsModule/entityActions/location';
import { locationsApi } from 'SettingsModule/api/location';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import * as actionCreators from 'actionCreators';

const entityHelper = changeEntity;

function* fetchLocationsWorker(action: ReturnType<typeof fetchLocations>) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchLocationsActionCreatorsMap,
    locationsApi.fetchLocations,
    [token, action.payload],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

function* fetchLocationsByTeamMembershipWorker(
  action: ReturnType<typeof fetchLocationsByTeamMembership>
) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    fetchLocationsByTeamMembershipActionCreatorsMap,
    locationsApi.fetchLocationsByTeamMembership,
    null,
    [token, { teamId }],
    action,
    action.payload
  );
}

function* fetchEntityLocationsWorker(
  action: ReturnType<typeof fetchEntityLocations>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchEntityLocationsActionCreatorsMap,
    locationsApi.fetchEntityLocations,
    [token, action.payload],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

function* createOrUpdateEntityLocationWorker(
  action: ReturnType<typeof createOrUpdateEntityLocation>
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { requestStatusId, ...rest } = action.payload;
  const token = yield select(getAuthToken);

  const { response } = yield changeEntity(
    createOrUpdateEntityLocationActionCreatorsMap,
    locationsApi.createOrUpdateEntityLocation,
    [token, rest],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );

  if (response) {
    yield put(fetchLocationsByTeamMembership({ teamId: rest.teamId }));
  }
}

function* deleteEntityLocationWorker(
  action: ReturnType<typeof deleteEntityLocation>
) {
  const { payload, meta } = action;
  const { entityId, entityType } = meta;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  const { response } = yield changeEntity(
    deleteEntityLocationActionCreatorsMap,
    locationsApi.deleteEntityLocation,
    [token, payload],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    payload
  );

  if (response) {
    if (entityType === ENTITY_TYPES.teamMembership) {
      yield put(fetchLocationsByTeamMembership({ teamId }));
    } else if (entityType === ENTITY_TYPES.project) {
      yield put(actionCreators.fetchAllProjects({ projectIds: [entityId] }));
      yield put(actionCreators.fetchProjectById(entityId));
    }
  }
}

function* associateEntityToLocationWorker(
  action: ReturnType<typeof associateEntityToLocation>
) {
  const token = yield select(getAuthToken);
  const { entityId, entityType, teamId } = action.payload;

  const { response } = yield changeEntity(
    associateEntityToLocationActionCreatorsMap,
    locationsApi.associateEntityToLocation,
    [token, action.payload],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );

  if (response) {
    // yield put(fetchLocations)
    yield put(fetchEntityLocations({ teamId, entityId, entityType }));
    if (entityType === ENTITY_TYPES.project) {
      yield put(actionCreators.fetchAllProjects({ projectIds: [entityId] }));
      yield put(actionCreators.fetchProjectById(entityId));
    }
  }
}

export default {
  fetchLocationsWorker,
  fetchLocationsByTeamMembershipWorker,
  fetchEntityLocationsWorker,
  createOrUpdateEntityLocationWorker,
  deleteEntityLocationWorker,
  associateEntityToLocationWorker
};
