import { DISPLAY_MEMBER_CAPACITY } from 'appConstants/workload';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { DisplayMemberCapacityOption } from 'TimelinesModule/types/timelineSettings';
import {
  HoverState,
  NonHoverState
} from 'views/projectPlanner/WorkPlanItemRenderers/styles';
import { TimeText } from 'views/projectPlanner/WorkPlanItemRenderers/SubComponents/TimeText';
import { BarPropertiesText } from './styles';

interface DailyPlannedTextProps {
  displayMemberCapacity?: DisplayMemberCapacityOption;
  allDay?: boolean;
  averageCapacity: number;
  dailyHours: number;
  workdayPercent: number;
  startTime?: string;
  endTime?: string;
}

const units = { hoursPerDay: 'h/d', percent: '%' } as const;
export const DailyPlannedText = ({
  displayMemberCapacity,
  allDay,
  averageCapacity,
  dailyHours,
  workdayPercent,
  startTime,
  endTime
}: DailyPlannedTextProps) => {
  const hoursPerDayToUse = formatNumWithMaxTwoDecimals(
    allDay ? averageCapacity : dailyHours
  );
  const hoursPerDayText = `${hoursPerDayToUse} ${units.hoursPerDay}`;

  const percentToUse = (allDay ? 100 : workdayPercent).toFixed(0);
  const percentText = `${percentToUse} ${units.percent}`;

  const attributeTextOrder = [hoursPerDayText, percentText];

  const [hoverText, nonHoverText] =
    displayMemberCapacity === DISPLAY_MEMBER_CAPACITY.PERCENT
      ? attributeTextOrder
      : attributeTextOrder.reverse();

  return (
    <BarPropertiesText>
      <HoverState className="flex">{hoverText}</HoverState>
      <NonHoverState className="flex">
        {nonHoverText}
        {startTime && endTime && (
          <TimeText
            values={{
              startTime,
              endTime
            }}
          />
        )}
      </NonHoverState>
    </BarPropertiesText>
  );
};
