import React from 'react';
import { connect } from 'react-redux';
import {
  makeGetColumnIsSelected,
  getDisplayedDescriptions,
  getTimesheetDateKeys,
  getAreTimeEntriesStillCreating
} from 'selectors';
import { submitDates } from 'actionCreators';
import TimesheetColumnTotal from 'views/timesheet/Timesheet/TimesheetColumnTotal';
import {
  TimesheetFooterValue,
  TimesheetArrowContainer,
  StyledTimesheetSubmitArrowIcon,
  HideOnHover,
  ShowOnHover
} from './../styles';
import {
  makeCheckDateForNotSubmitted,
  makeCheckDateForSubmitted
} from 'appUtils/timesheetUtils';
import flatten from 'lodash/flatten';
import { ReactComponent as UnsubmitAllIcon } from 'icons/timesheetUnsubmitIcon.svg';

class TimesheetDay extends React.Component {
  onSubmit = () => {
    const { submitDates, areTimeEntriesStillCreating } = this.props;
    if (!areTimeEntriesStillCreating) {
      submitDates({
        dates: this.getDateKeys(),
        status: 'submitted'
      });
    }
  };

  onUnsubmit = () => {
    const { submitDates, areTimeEntriesStillCreating } = this.props;
    if (!areTimeEntriesStillCreating) {
      submitDates({
        dates: this.getDateKeys(),
        status: 'not_submitted'
      });
    }
  };

  getDateKeys = () => {
    const {
      column: { id },
      dateKeys
    } = this.props;
    return id === 'total' ? dateKeys : [id];
  };

  render() {
    const { activityRows, data, column } = this.props;

    const id = column.id;

    const displayedTimesheets = flatten(
      data
        .filter((row) => row.timesheetsByDate)
        .map((row) => Object.values(row.timesheetsByDate))
    ).filter((timesheet) => timesheet?.id);
    const dateCheckers = this.getDateKeys().map((dateKey) =>
      makeCheckDateForNotSubmitted(dateKey)
    );
    const dateCheckerForSubmitted = this.getDateKeys().map((dateKey) =>
      makeCheckDateForSubmitted(dateKey)
    );
    const isSelected = dateCheckers.some((dateChecker) =>
      dateChecker(displayedTimesheets)
    );
    const hasSubmitted = dateCheckerForSubmitted.some((dateChecker) =>
      dateChecker(displayedTimesheets)
    );
    const isWaiting = displayedTimesheets.some(
      (timesheet) => timesheet.id === 'new' && !timesheet.error
    );
    return (
      <div>
        <TimesheetColumnTotal
          dateKeys={this.getDateKeys()}
          activityRows={activityRows}
          timesheetColumnIndex={this.props.column?.index}
        />
        <TimesheetFooterValue>
          <TimesheetArrowContainer
            onClick={() => {
              isSelected ? this.onSubmit() : this.onUnsubmit();
            }}
            data-for={isSelected || hasSubmitted ? 'app-tooltip' : ''}
            data-effect="solid"
            data-html
            data-tip={
              isSelected
                ? 'Submit all Time <br/> Entries for this day.'
                : 'Unsubmit all Time <br/> Entries for this day.'
            }
            data-testid={`timesheet-column-arrow ${this.props.column?.index}`}
          >
            {!isSelected && hasSubmitted ? (
              <>
                <HideOnHover>
                  <StyledTimesheetSubmitArrowIcon
                    isSelected={isSelected}
                    isDisabled={isWaiting}
                    isWaiting={isWaiting}
                    // separate style rule for waiting cursor
                  />
                </HideOnHover>
                <ShowOnHover>
                  <UnsubmitAllIcon />
                </ShowOnHover>
              </>
            ) : (
              <StyledTimesheetSubmitArrowIcon
                isSelected={isSelected}
                isDisabled={isWaiting}
                isWaiting={isWaiting}
                // separate style rule for waiting cursor
              />
            )}
          </TimesheetArrowContainer>
        </TimesheetFooterValue>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getColumnIsSelected = makeGetColumnIsSelected();
  const mapStateToProps = (state, ownProps) => ({
    isSelected: getColumnIsSelected(state, ownProps),
    activityRows: getDisplayedDescriptions(state),
    dateKeys: getTimesheetDateKeys(state),
    areTimeEntriesStillCreating: getAreTimeEntriesStillCreating(state)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  submitDates
};
const ConnectedTimesheetDay = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TimesheetDay);

const TimesheetDayAdapter = (props) => <ConnectedTimesheetDay {...props} />;

export default TimesheetDayAdapter;
