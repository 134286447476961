import { fetchEntity, changeEntity } from './generics';
import { put, select, call } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken, getScopeHash, getSelectedTeamId } from 'selectors';
import * as actionCreators from 'actionCreators';
import { fetchMemberBudgets } from 'BudgetModule/actionCreators';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import { fetchUnloadedProjects } from './helpers';
import { GENERIC_ACTION, REFRESH_REQUIRED_ERROR_MESSAGE } from 'appConstants';

export function* fetchScopesWorker(action) {
  const token = yield select(getAuthToken);
  const {
    scheduleStart,
    scheduleEnd,
    limit, // defaults to 20 on BE
    offset, // defaults to 0
    all, // use as necessary based on above
    projectIds,
    projectId,
    ids,
    id, // just for preventing having to write ids: [scopeId] when fetching single id
    phaseIds,
    activityPhaseIds,
    accountIds,
    excludeNonRequests,
    includeRequests,
    groupDeterminant,
    sortAttributes,
    includeApprovedRequestInfo,
    excludeScopesWithWorkPlan
  } = action.payload;

  const scopeIds = id !== undefined ? [id] : ids;

  const queryParams = convertKeysToSnakeCase({
    scheduleStart,
    scheduleEnd,
    limit,
    offset,
    all,
    projectIds: projectId !== undefined ? [projectId] : projectIds,
    scopeIds,
    phaseIds,
    activityPhaseIds,
    accountIds,
    excludeNonRequests,
    excludeScopesWithWorkPlan,
    includeRequests:
      includeRequests ?? (scopeIds?.length > 0 ? true : undefined), // include requests by default if we're fetching for exact ids
    groupDeterminant,
    sortAttributes,
    includeApprovedRequestInfo
  });

  const { response } = yield fetchEntity(
    entityActions.fetchScopes,
    api.fetchScopes,
    null,
    [token, queryParams],
    action
  );

  if (response?.scopes?.length) {
    const toFetchIds = response.scopes
      .map((scope) => scope.project_id)
      .filter((projectId) => projectId);
    yield call(fetchUnloadedProjects, {
      projectIds: toFetchIds
    });
  }
}

export function* createScopeWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const {
    activityPhaseId,
    description = '',
    note,
    files,
    estimatedHours,
    scheduleStart,
    scheduleEnd,
    parentScopeId,
    isRequest,
    requestedToId,
    openModalOnSuccess,
    onSuccess = [],
    assignees, // account ids
    dependencyInfos
  } = action.payload;

  const body = convertKeysToSnakeCase({
    activityPhaseId,
    description,
    note,
    estimatedHours,
    scheduleStart,
    scheduleEnd,
    parentScopeId,
    isRequest,
    requestedToId,
    teamId,
    dependencyInfos
  });

  const { error, response } = yield changeEntity(
    entityActions.createScope,
    api.createScope,
    [token, body, files],
    action,
    action.payload
  );

  if (!error && response) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
    if (response.scope) {
      // add scope assignees after creation
      if (assignees?.length) {
        yield put(
          actionCreators.assignScopeMembers({
            scopeId: response.scope.id,
            members: assignees.map((accountId) => ({ account_id: accountId }))
          })
        );
      }
      // refresh activity phase scope orders
      if (response.scope.project_id) {
        yield put(
          actionCreators.fetchPhases({ projectId: response.scope.project_id })
        );
      }
      if (openModalOnSuccess) {
        yield put(
          actionCreators.navigateToScopeModal({ scopeId: response.scope.id })
        );
      }
    }
  }
}

export function* updateScopeWorker(action) {
  const token = yield select(getAuthToken);
  const {
    id,
    description,
    note,
    estimatedHours,
    scheduleStart,
    scheduleEnd,
    files,
    parentScopeId,
    requestedToId,
    isRequest // not used currently, for requesting to update existing scopes
  } = action.payload;

  const body = convertKeysToSnakeCase({
    description,
    note,
    estimatedHours,
    scheduleStart,
    scheduleEnd,
    parentScopeId,
    requestedToId,
    isRequest
  });

  const { error, response } = yield changeEntity(
    entityActions.updateScope,
    api.updateScope,
    [token, id, body, files],
    action,
    action.payload
  );
}

export function* deleteScopeWorker(action) {
  const token = yield select(getAuthToken);
  const scopeHash = yield select(getScopeHash);
  const { id, ids } = action.payload;
  const scopeIds = id !== undefined ? [id] : ids;

  // phases should be refetched since activityPhase.scope_orders data comes from them
  const projectsThatShouldRefetchPhases = [];
  scopeIds.forEach((scopeId) => {
    const scope = scopeHash[scopeId];
    const projectId = scope.project_id;
    if (projectId) {
      projectsThatShouldRefetchPhases.push(projectId);
    }
  });

  const { error, response } = yield changeEntity(
    entityActions.deleteScope,
    ids ? api.batchDeleteScopes : api.deleteScope,
    ids ? [token, { scope_ids: scopeIds }] : [token, id],
    action,
    action.payload
  );

  if (!error && response && projectsThatShouldRefetchPhases.length) {
    yield put(
      actionCreators.fetchPhasesByProjectIds({
        projectIds: projectsThatShouldRefetchPhases
      })
    );
  }
}

export function* moveScopesWorker(action) {
  const token = yield select(getAuthToken);
  const {
    activityPhaseId,
    isCopy: copy,
    id,
    ids,
    projectId, // for refetching only
    filterStateId,
    onSuccess = []
  } = action.payload;

  const scopeIds = id !== undefined ? [id] : ids;

  const body = convertKeysToSnakeCase({
    activityPhaseId,
    copy,
    scopeIds
  });

  const { error, response } = yield changeEntity(
    entityActions.moveScopes,
    api.moveScopes,
    [token, body],
    action,
    action.payload
  );

  if (!error && response) {
    // note: move scope call may not have come from selected ids, but reset anyway
    yield put(
      actionCreators.selectScopes({
        isSelect: false,
        ids: scopeIds,
        filterStateId
      })
    );
    if (projectId) {
      yield put(actionCreators.fetchPhases({ projectId }));
      yield put(actionCreators.fetchProjectById(projectId));
      yield put(
        fetchMemberBudgets({
          projectId
        })
      );
    }
    const scopesToFetch = response.map(({ id }) => id);
    yield put(actionCreators.fetchScopes({ ids: scopesToFetch, all: true }));

    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
}

/* ------------------------------- Scope order ------------------------------ */

export function* updateScopePositionWorker(action) {
  const token = yield select(getAuthToken);
  const {
    id, // scope id
    moveBehindId, // id of scope to move behind (when not provided, will move to top)
    activityPhaseId // parent id
  } = action.payload;

  const body = {
    project_scope_id: id,
    move_behind_scope_id: moveBehindId
  };

  const { error, response } = yield changeEntity(
    entityActions.updateScopePosition,
    api.updateScopePosition,
    [token, activityPhaseId, body],
    action,
    action.payload
  );

  if (error || !response) {
    // Display error message on error since we are optimistically updating the
    // activity phase's project_scope_order in phases reducer on TRIGGER.
    // Refetching for the phase would work only if we could debounce the refetch, since
    // fetch_phase successes can overwrite optimistic updates

    yield put(
      actionCreators.handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        isRefreshRequiredError: true,
        errorMessage: REFRESH_REQUIRED_ERROR_MESSAGE
      })
    );
  }
}

/* ---------------------------- Scope membership (assignment) ---------------------------- */

// see actions/scopes.js for payload
export function* assignScopeMembersWorker(action) {
  const token = yield select(getAuthToken);
  const scopeHash = yield select(getScopeHash);
  const { scopeId, members, onSuccess = [] } = action.payload;
  const scope = scopeHash[scopeId];

  const body = convertKeysToSnakeCase({
    members
  });

  const { error, response } = yield changeEntity(
    entityActions.assignScopeMembers,
    api.assignScopeMembers,
    [token, scopeId, body],
    action,
    action.payload
  );

  if (!error && response) {
    // refetch scope (update scope assignees)
    yield put(actionCreators.fetchScopes({ id: scopeId }));

    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
    // refetch member budgets
    const refetchMemberBudgetIds = [
      ...response.assigned_members,
      ...response.unassigned_members
    ]
      .filter((member) => member) // necessary to remove 'null'
      .map((member) => member.member_budget_id);

    if (scope.project_id && refetchMemberBudgetIds.length) {
      yield put(
        fetchMemberBudgets({
          projectId: scope.project_id,
          memberBudgetIds: refetchMemberBudgetIds
        })
      );
      // update project memberships
      yield put(actionCreators.fetchProjectById(scope.project_id));
    }
  }
}
/**
 * Same as assignScopeMembersWorker, but adds 'unassign: true' to all members in the payload
 */
export function* unassignScopeMembersWorker(action) {
  const token = yield select(getAuthToken);
  const { scopeId, members, onSuccess = [] } = action.payload;

  const formattedMembers = members.map((member) => ({
    ...member,
    unassign: true
  }));

  const body = convertKeysToSnakeCase({
    members: formattedMembers
  });

  const { error, response } = yield changeEntity(
    entityActions.assignScopeMembers,
    api.assignScopeMembers,
    [token, scopeId, body],
    action,
    action.payload
  );

  if (!error && response) {
    // refetch scope (update scope assignees)
    yield put(actionCreators.fetchScopes({ id: scopeId }));

    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
}

export function* updateScopeMembershipWorker(action) {
  const token = yield select(getAuthToken);
  const {
    scopeId,
    memberBudgetId,
    startDate,
    endDate,
    percentComplete,
    onSuccess = []
  } = action.payload;

  const body = convertKeysToSnakeCase({
    startDate,
    endDate,
    percentComplete
  });

  const { error, response } = yield changeEntity(
    entityActions.updateScopeMembership,
    api.updateScopeMembership,
    [token, scopeId, memberBudgetId, body],
    action,
    action.payload
  );

  if (!error && response) {
    // refetch scope (update scope percent complete)
    yield put(actionCreators.fetchScopes({ id: scopeId }));

    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
}

/* ------------------------------- Attachments ------------------------------ */

export function* deleteScopeAttachmentWorker(action) {
  const token = yield select(getAuthToken);
  const { attachmentId, id } = action.payload;

  yield changeEntity(
    entityActions.deleteScopeAttachment,
    api.deleteAttachment,
    [token, attachmentId],
    action,
    action.payload
  );

  // refetch on error or success (since FE is optimistically updating)
  yield put(actionCreators.fetchScopes({ id }));
}
