import React, { useState, useEffect, useMemo } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import XTickValue from '../shared/XTickValue';
import { Dot } from './Dot';
import useHasStateChanged from 'appUtils/hooks/useHasStateChanged';
import { CAPACITY_KEY } from 'UtilizationModule/constants';
import { getTooltipContent } from 'ReportsModule/components/Utilization/utils';
import LegendStyleTooltip from 'components/Tooltips/LegendStyleTooltip';

const UtilizationAreaChart = ({ data, zoomLevel, utlizationIconInfo }) => {
  const [tooltipProps, setTooltipProps] = useState({});
  const hideTooltip = () => setTooltipProps({});
  const [zoomLevelToUse, setZoomLevelToUse] = useState(zoomLevel);
  const hasDataChanged = useHasStateChanged({ data });

  useEffect(() => {
    if (hasDataChanged) {
      setZoomLevelToUse(zoomLevel);
    }
  }, [hasDataChanged, zoomLevel]);

  const formattedData = useMemo(
    () =>
      data.map((singleData) => ({
        ...singleData,
        totalPercent:
          (Math.max(singleData.totalHours, 0) /
            singleData[CAPACITY_KEY.CAPACITY_FOR_PERCENTAGE]) *
          100
      })),
    [data]
  );

  const formattedTooltipData = useMemo(
    () => formattedData.map((singleData) => getTooltipContent(singleData)),
    [formattedData]
  );

  const handleSetTooltipProps = (props) => {
    if (tooltipProps.index !== props.index) {
      setTooltipProps({
        data: formattedTooltipData[props.index].data,
        summary: formattedTooltipData[props.index].summary,
        showBorder: true,
        index: props.index
      });
    }
  };

  const color = theme.colors.colorConnectedGreen;

  return (
    <AreaChartStyleWrapper>
      <AreaChart
        width={792}
        height={473}
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 40
        }}
      >
        <CartesianGrid
          stroke={theme.colors.colorLightGray6}
          strokeDasharray="3 3"
          vertical={false}
        />
        <defs>
          <linearGradient id="area-chart-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={1} />
            <stop offset="110%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="start_date"
          interval={0}
          tickSize={12}
          tick={<XTickValue zoomLevel={zoomLevelToUse} data={data} />}
          tickLine={false}
        />
        <YAxis
          tickFormatter={(value) => value + '%'}
          tickCount={12}
          tickLine={false}
          type="number"
          domain={[0, 100]}
        />

        <Tooltip
          cursor={false}
          content={() => {
            if (tooltipProps.data) {
              return (
                <LegendStyleTooltip
                  className="utilization-report-tooltip"
                  isReactTooltip={false}
                  {...tooltipProps}
                />
              );
            }
          }}
          isAnimationActive={false}
          wrapperStyle={{
            visibility: 'visible'
          }}
        />

        <Area
          dataKey="billablePercent"
          stroke={color}
          fill="url(#area-chart-gradient)"
          activeDot={false}
          isAnimationActive={false} // disabled to avoid having missing dot - known issue: https://github.com/recharts/recharts/issues/804#issuecomment-461251128
          dot={(props) => {
            return (
              <Dot
                key={props.key}
                {...props}
                onHover={() => handleSetTooltipProps(props)}
                onHoverOut={hideTooltip}
                utlizationIconInfo={utlizationIconInfo}
              />
            );
          }}
        />
      </AreaChart>
    </AreaChartStyleWrapper>
  );
};

export default React.memo(UtilizationAreaChart);

const AreaChartStyleWrapper = styled.div`
  .recharts-area-curve {
    stroke-width: 3px;
  }
`;
