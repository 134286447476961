const adminOption = {
  text: 'Admins',
  name: 'Admin',
  checked: true,
  default: true
};
const financialManagerOption = {
  text: 'Financial Managers',
  name: 'Financial Manager'
};
const boardManagerOption = {
  text: 'Portfolio Managers',
  name: 'Board Manager'
};
const projectManagerOption = {
  text: 'Project Managers',
  name: 'Project Manager'
};
const allOption = { text: 'All Organization Members', name: 'Team Member' };

export const flatOptions = [
  adminOption,
  boardManagerOption,
  projectManagerOption,
  allOption
];

export const flatOptionsWithFinancialManager = [
  adminOption,
  financialManagerOption,
  boardManagerOption,
  projectManagerOption,
  allOption
];
