import React from 'react';
import { daysOfWeek } from 'appConstants/dayPlannerNavDays';
import { isSameDay, isToday } from 'appUtils/momentUtils';
import { DayNavTab } from '../..';

const DayPlannerWeek = ({ weekArray, selectedDate, handleDayClick }) => {
  return (
    <div className="week-container">
      {weekArray.map((momentDate, idx) => {
        return (
          <DayNavTab
            key={`day ${momentDate._d}`}
            dayOfWeek={daysOfWeek[idx]}
            dateNum={momentDate.date()}
            handleClick={handleDayClick(momentDate)}
            isSelected={isSameDay(selectedDate, momentDate)}
            isToday={isToday(momentDate)}
          />
        );
      })}
    </div>
  );
};

export default DayPlannerWeek;
