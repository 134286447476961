import { put, select, take, call, all, fork, cancel } from 'redux-saga/effects';
import * as actionCreators from 'actionCreators';
import { fetchMemberBudgets } from 'BudgetModule/actionCreators';
import {
  getProjectHash,
  getPhasesByProjectHash,
  getIsLoadingPhases,
  getIsFetchingProjects
} from 'selectors';
const emptyObj = {};

export function* waitFor(selector, actions = '*') {
  if (yield select(selector)) return;

  while (true) {
    yield take(actions);
    if (yield select(selector)) return;
  }
}

export function* fetchUnloadedProjectMemberBudgets(projectIds = []) {
  const fetchedMemberBudgetProjectIds = yield select(
    (state) => state.memberBudgets?.fetchedMemberBudgetProjectIds || emptyObj
  );
  const unloadedMemberBudgetProjectIds = Array.from(
    new Set(projectIds.filter((id) => !fetchedMemberBudgetProjectIds[id]))
  );

  if (unloadedMemberBudgetProjectIds.length) {
    yield all(
      unloadedMemberBudgetProjectIds.map((projectId) =>
        put(fetchMemberBudgets({ projectId }))
      )
    );
  }
}

/**
 * Checks if given list of project IDs have been fetched for
 * and will fetch the ones that haven't. Will also fetch for their phases
 * unless shouldFetchPhases === false
 */
export function* fetchUnloadedProjects({
  projectIds = [],
  shouldFetchPhases = true,
  shouldFetchMemberBudgets
}) {
  const projectHash = yield select(getProjectHash);
  const phasesByProjectHash = yield select(getPhasesByProjectHash);

  const unloadedPhasesProjectIds = shouldFetchPhases
    ? projectIds.filter((id) => !phasesByProjectHash[id])
    : [];

  const unloadedProjectIds = projectIds.filter((id) => !projectHash[id]);

  // Fetch projects
  if (unloadedProjectIds.length) {
    yield call(waitFor, (state) => !getIsFetchingProjects(state));
    yield put(
      actionCreators.fetchAllProjects({ projectIds: unloadedProjectIds })
    );
  }
  // Fetch phases
  if (shouldFetchPhases) {
    const toFetchIds = Array.from(
      new Set([...unloadedProjectIds, ...unloadedPhasesProjectIds])
    );
    if (toFetchIds.length) {
      yield call(waitFor, (state) => !getIsLoadingPhases(state));
      yield put(
        actionCreators.fetchPhasesByProjectIds({
          projectIds: toFetchIds
        })
      );
    }
  }
  // Fetch member budgets
  if (shouldFetchMemberBudgets && unloadedProjectIds.length) {
    yield call(fetchUnloadedProjectMemberBudgets, unloadedProjectIds);
  }
}

/**
 * Behaves like takeEvery, unless checkCondition(action) is met, in which case
 * a buffer is created (using fieldsToCompare as key) that will hold the last task
 * that was run. If an existing task with that key is running, it will be cancelled.
 */
export const conditionalTakeLatest = (
  patternOrChannel,
  saga,
  checkCondition = (action) =>
    action.payload.initial || action.payload.takeLatest,
  fieldsToCompare = ['takeLatest'] // default => just one buffer { true: task }
) =>
  fork(function* () {
    // for clearing all tasks on cancelAllBefore
    // Holds the last 20 tasks that did not pass checkCondition.
    // Number held can be small since we shouldn't expect so many calls to be
    // pending anyway. May hold stale completed tasks
    const takeEveryTasks = Array(20);
    let takeEveryTasksIndex = 0;
    // keyed by values of fieldsToCompare
    let lastTasks = {};
    while (true) {
      const action = yield take(patternOrChannel);
      if (checkCondition(action)) {
        const key = fieldsToCompare
          .map((field) => action.payload[field] || action.meta?.[field] || '')
          .join('');
        if (action.payload.cancelAllBefore || action.meta?.cancelAllBefore) {
          // cancel all previous and pending calls, regardless of whether or not
          // they would have passed checkCondition (behave like a true takeLatest)
          const tasksToCancel = [
            ...Object.values(lastTasks),
            ...takeEveryTasks.filter((task) => task)
          ];
          if (tasksToCancel.length) {
            yield cancel(...tasksToCancel);
          }
          lastTasks = {};
          takeEveryTasksIndex = 0;
        } else if (lastTasks[key]) {
          yield cancel(lastTasks[key]);
          delete lastTasks[key];
        }
        lastTasks[key] = yield fork(saga, action);
      } else {
        // Behave like takeEvery
        const takeEveryTask = yield fork(saga, action);
        takeEveryTasks[takeEveryTasksIndex] = takeEveryTask;
        takeEveryTasksIndex = (takeEveryTasksIndex + 1) % 20;
      }
    }
  });
