import React from 'react';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import theme from 'theme';

const InviteTeamMemberFormConfirmCancelModal = ({
  isOpen,
  handleConfirmation,
  toggleShowConfirmCancel
}) => {
  return (
    <Modal isOpen={isOpen} autoFocus={false}>
      <ConfirmCancelModalContainer isOpen={isOpen}>
        <StyledCancelModalTitle>
          Invitations have not been sent
        </StyledCancelModalTitle>
        <StyledCancelModalContent>
          Are you sure you want to close without sending?
          <StyledCancelModalSubContent>
            Filled information will be deleted.
          </StyledCancelModalSubContent>
        </StyledCancelModalContent>
        <CancelModalButtonContainer>
          <CancelModalGoBackButton onClick={toggleShowConfirmCancel}>
            Go Back
          </CancelModalGoBackButton>
          <CancelModalCancelButton onClick={handleConfirmation}>
            Yes, Cancel
          </CancelModalCancelButton>
        </CancelModalButtonContainer>
      </ConfirmCancelModalContainer>
    </Modal>
  );
};

export default InviteTeamMemberFormConfirmCancelModal;

const ConfirmCancelModalContainer = styled.div`
  width: 461px;
  height: 200px;
  padding: 34px;
`;

const StyledCancelModalTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: ${theme.colors.colorMediumGray7};
`;

const StyledCancelModalContent = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin: 16px 0 10px 0;
`;

const StyledCancelModalSubContent = styled.div`
  color: ${theme.colors.colorCalendarGray};
`;

const CancelModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelModalCancelButton = styled.button`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: 32px;
  width: 98px;
  border-radius: 5px;
  border: 0;
  color: ${theme.colors.colorMediumGray6};
  outline: none;
  border: 1px solid ${theme.colors.colorLightGray4};
  margin-right: 15px;
  cursor: pointer;
  background-color: ${theme.colors.colorPureWhite};

  :hover {
    background-color: ${theme.colors.colorLightGray19};
  }
`;

const CancelModalGoBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.colorRoyalBlue};
  border: none;
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  width: 81px;
  height: 32px;
  margin-right: 10px;
  padding-left: 5px;
  color: ${theme.colors.colorPureWhite};

  &:hover {
    background-color: #0059a6;
  }

  &:disabled {
    background: ${theme.colors.colorMediumGray1};
    border-color: ${theme.colors.colorMediumGray1};
  }
`;
