import React from 'react';

import {
  TaskPropertyCell,
  NewTaskProperty,
  StyledTaskPriorityContainer,
  SelectedTaskPriority
} from './styles';
import { connect } from 'react-redux';
import { getOrderedTaskPriorities, makeGetOwnTaskPriority } from 'selectors';
import { StyledPriorityIcon } from '../TaskList/styles';
import TaskPriorityListItem from './TaskPriorityListItem';
import OptionsSelectFlyout from 'components/OptionsSelectFlyout/OptionsSelectFlyout';
import theme from 'theme';

const copy = {};

class TaskPriority extends React.PureComponent {
  state = {
    isDropdownOpen: false
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
      editMode: false,
      editingId: null
    }));
  };

  renderSelectedItem = (item) =>
    item.color && (
      <SelectedTaskPriority>
        <StyledTaskPriorityContainer>
          <StyledPriorityIcon currentColor={item.color} />
        </StyledTaskPriorityContainer>
      </SelectedTaskPriority>
    );

  render() {
    const {
      isNewTask,
      onSubmit,
      taskId,
      taskPriorities,
      taskIsEditing,
      priority = {}
    } = this.props;

    const { isDropdownOpen } = this.state;

    return (
      <TaskPropertyCell onClick={this.toggleDropdown}>
        <OptionsSelectFlyout
          selectedItem={priority}
          listItems={taskPriorities}
          rowId={taskId}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={this.toggleDropdown}
          onSubmit={onSubmit}
          ListItemComponent={TaskPriorityListItem}
          copy={copy}
          droppableType="task-priorities"
          renderSelectedItem={this.renderSelectedItem}
        />
        {!priority.title && (
          <NewTaskProperty
            $isNewTask={isNewTask}
            $taskIsEditing={taskIsEditing}
          >
            <StyledPriorityIcon
              className="no-priority"
              stroke={theme.colors.colorMediumGray1}
              currentColor="none"
            />
          </NewTaskProperty>
        )}
      </TaskPropertyCell>
    );
  }
}

const makeMapStateToProps = () => {
  const getOwnTaskPriority = makeGetOwnTaskPriority();
  const mapStateToProps = (state, ownProps) => ({
    priority: getOwnTaskPriority(state, ownProps),
    taskPriorities: getOrderedTaskPriorities(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(TaskPriority);
