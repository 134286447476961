import React from 'react';
import { StyledColumnLabel } from './styles';

const PhaseDateCell = ({ row }) => {
  const { list, isOpen } = row.original;
  const hasNoRole = list.listItems.length <= 1; // The only row is phaseBottomRow for adding new role
  return (
    <StyledColumnLabel className="date">
      {hasNoRole || !isOpen ? '' : 'DATES REQUIRED'}
    </StyledColumnLabel>
  );
};

export default PhaseDateCell;
