import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BoardMembersContainer } from 'views';
import ModuleList from 'components/ViewFactory/ModuleList';
import {
  getInstalledBoardModuleIds,
  getSelectedProjectIsPersonal,
  getMatchedRouteParams,
  getIsOnProjectView,
  getOnBoardView
} from 'selectors';
import {
  clearCachedProjects,
  clearSelectedBoard,
  fetchProjectsListView,
  fetchBoardMembers,
  fetchSelectedBoard,
  navigateToBoard
} from 'actionCreators';
import cn from 'classnames';
import styled from 'styled-components';

const Layer = styled.div`
  height: 12px;
  width: 100%;
  background: #f3f3f3;
  position: relative;
  top: 10px;
  z-index: 10;
  @media print {
    background: #fff;
  }
`;

class BoardContainer extends React.Component {
  componentWillUnmount() {
    const { clearCachedProjects, clearSelectedBoard } = this.props;
    clearCachedProjects();
    clearSelectedBoard();
  }

  componentDidMount() {
    const {
      fetchProjectsListView,
      fetchBoardMembers,
      fetchSelectedBoard,
      matchedParams: { boardId }
    } = this.props;
    boardId && fetchSelectedBoard(boardId);
    boardId && fetchProjectsListView({ boardId });
    boardId && fetchBoardMembers(boardId);
  }

  changeViewDisplay = (viewType) => () => {
    const {
      matchedParams: { teamSlug, boardSlug, boardId },
      navigateToBoard
    } = this.props;
    const params = {
      teamSlug,
      boardSlug,
      boardId,
      viewType
    };
    navigateToBoard(params);
  };

  renderHeader = () => {
    const { selectedProjectIsPersonal, installedModuleIds, isOnBoardView } =
      this.props;
    if (!isOnBoardView) {
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '0 15px',
          position: `relative`,
          top: '-8px'
        }}
      >
        {!selectedProjectIsPersonal && <BoardMembersContainer />}
        <ModuleList
          currentTab={this.props.matchedParams.viewType}
          changeTabs={this.changeViewDisplay}
          installedModuleIds={installedModuleIds}
          viewType={'board'}
        />
      </div>
    );
  };

  render() {
    const { children, selectedProjectIsPersonal, isOnProjectView } = this.props;

    return (
      <>
        {this.renderHeader()}
        <Layer />
        {/*
          The Border spacing issue on the table causes a space between the th and the thead in the table header.
          There isn't a direct way to override this. Hence, we're using this div to cover that spacing.
      */}
        <div
          className={cn('project-list-wrapper', {
            expanded: isOnProjectView,
            personal: selectedProjectIsPersonal
          })}
        >
          {children}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  installedModuleIds: getInstalledBoardModuleIds(state, ownProps),
  selectedProjectIsPersonal: getSelectedProjectIsPersonal(state, ownProps),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  isOnBoardView: getOnBoardView(state)
});

const mapDispatchToProps = {
  clearCachedProjects,
  clearSelectedBoard,
  fetchProjectsListView,
  fetchBoardMembers,
  fetchSelectedBoard,
  navigateToBoard
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardContainer)
);
