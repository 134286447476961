import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';

// formats currency so that it only shows decimals if there is a decimal vaue
// ex: 12345 => $ 12,345  and 1234.56789 => $ 1234.56

/*
  @deprecated -- USE useFormatCurrency hook
*/
export const formatCurrency = (currencyString) => {
  if (!currencyString) return '$0';
  if (Number.isNaN(+currencyString)) return '';

  const withDecimals = parseFloat(currencyString).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD'
  });
  const decimalIndex = withDecimals.indexOf('.');
  return withDecimals.substr(decimalIndex, 3) === '.00'
    ? withDecimals.substring(0, decimalIndex)
    : withDecimals;
};

export const serializeBillingCategoryPhaseId = ({
  billingCategoryId,
  phaseId
}) => `${billingCategoryId}-${phaseId}`;

export const serializeBudgetRecord = (record) => {
  switch (record.aggregate_type) {
    case BUDGET_RECORD_DATA_TYPES.PROJECT:
      return `${record.project_id}`;

    case BUDGET_RECORD_DATA_TYPES.PHASE:
      return `${record.phase_id}`;

    case BUDGET_RECORD_DATA_TYPES.PHASE_ACTIVITY:
      return `${record.phase_id}-${record.activity_id}`;

    case BUDGET_RECORD_DATA_TYPES.ACCOUNT:
      return `${record.account_id}`;

    case BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT:
      return `${record.account_id}-${record.project_id}`;

    case BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE:
      return `${record.account_id}-${record.phase_id}`;

    case BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE_ACTIVITY:
      return `${record.account_id}-${record.phase_id}-${record.activity_id}`;

    case BUDGET_RECORD_DATA_TYPES.POSITION:
      return `${record.position_id}`;

    case BUDGET_RECORD_DATA_TYPES.POSITION_PROJECT:
      return `${record.position_id}-${record.project_id}`;

    case BUDGET_RECORD_DATA_TYPES.POSITION_PHASE:
      return `${record.position_id}-${record.phase_id}`;

    case BUDGET_RECORD_DATA_TYPES.POSITION_PHASE_ACTIVITY:
      return `${record.position_id}-${record.phase_id}-${record.activity_id}`;

    default:
      return '';
  }
};

export const calcPercentageOf = (value, total) => {
  return +total && +value ? (value / total) * 100 : 0;
};

export const formatPercentage = (value, numDecimalPlaces = 1) => {
  return `${(+value || 0).toFixed(numDecimalPlaces).replace('.0', '')}%`;
};

export const formatHour = (value, numDecimalPlaces = 1) => {
  return `${(+value || 0).toFixed(numDecimalPlaces).replace('.0', '')}h`;
};

export const formatDay = (value, numDecimalPlaces = 1) => {
  return `${(+value || 0).toFixed(numDecimalPlaces).replace('.0', '')}d`;
};

/**
 *
 * @param {*} value i.e 0.234
 * @returns {number} i.e. 23
 */
export const formatBarPercent = (value) => {
  const v = +value || 0;
  return Math.round(v * 100);
};

export const formatTotal = (type, total, currencyFormatter = undefined) => {
  switch (type) {
    case 'none':
      return total;
    case 'currency':
      return currencyFormatter?.format ? currencyFormatter.format(total) : '';
    case 'percentage':
      return formatPercentage(total);
    case 'hour':
      return formatHour(total);
    case 'day':
      return formatDay(total);
    default:
  }
};
