import { DATE_FNS_SHORT_USA_DATE } from 'appConstants/date';
import { getFormattedDateWithDateFns } from 'appUtils/dateUtils';
import cn from 'classnames';
import MilestoneIcon from 'icons/MilestoneIcon';
import PhaseMilestoneIconNarrow from 'icons/PhaseMilestoneIconNarrow';
import styled from 'styled-components';
import { dependencyItemType } from '../../constants';
import { DependableItem, TargetDependableItemRenderer } from '../../types';
import {
  MenuItemContainer,
  StrokeIconStyles,
  StyledKaratRight
} from './styles';

export const PhaseTargetDependableItemRenderer: TargetDependableItemRenderer =
  ({
    dependableItem,
    disabled,
    selected
  }: {
    dependableItem: DependableItem;
    disabled: boolean;
    selected: boolean;
  }) => {
    const DependableItemIcon = dependableItemIconMap[dependableItem.itemType];
    return (
      <StyledMenuItemContainer className={cn({ disabled, selected })}>
        <MenuItemTextContainer>
          <MenuItemText>
            <DependableItemIcon className={cn({ disabled, selected })} />
            {dependableItem.name}
          </MenuItemText>
          {!disabled && <StyledKaratRight />}
        </MenuItemTextContainer>
        {dependableItem.startDate && (
          <MenuItemSubText>
            {getFormattedDateWithDateFns(
              dependableItem.startDate,
              DATE_FNS_SHORT_USA_DATE
            )}
            {dependableItem.endDate
              ? `-${getFormattedDateWithDateFns(
                  dependableItem.endDate,
                  DATE_FNS_SHORT_USA_DATE
                )}`
              : undefined}
          </MenuItemSubText>
        )}
      </StyledMenuItemContainer>
    );
  };

const MenuItemTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuItemText = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

const MenuItemSubText = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  margin-left: 14px;
`;

const StyledMenuItemContainer = styled(MenuItemContainer)`
  padding: 8px 21px;
  display: flex;
  flex-direction: column;
  width: 220px;
`;

const StyledPhaseMilestoneIconNarrow = styled(PhaseMilestoneIconNarrow)`
  ${StrokeIconStyles};
`;

const StyledMilestoneIcon = styled(MilestoneIcon)`
  width: 11px;
  margin-left: 2px;
  ${StrokeIconStyles}
`;

const dependableItemIconMap = {
  [dependencyItemType.phase]: StyledPhaseMilestoneIconNarrow,
  [dependencyItemType.milestone]: StyledMilestoneIcon
};
