/* eslint-disable no-unused-expressions */
import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState
} from 'react';
import { connect } from 'react-redux';
import {
  getSelectedTeamId,
  getTeamMembers,
  getTeamMembersHash,
  getWorkGroupsWithMembers
} from 'selectors';
import ContentLoader from 'react-content-loader';
import Table from 'components/Table';
import { StyledDepartmentsTable, StyledSeparator } from './styles';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import {
  getDepartmentColumnHeaders,
  getFormattedWorkGroupSettings
} from 'SettingsModule/selectors';
import HeaderCell from 'SettingsModule/components/Budget/HeaderCell';
import CollapseAllButton from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';
import StickyHeader from 'components/Table/TableStickyHeader';

import { SORT_BY } from 'appConstants/filters';
import { VIEW_BY } from 'appConstants/workload';
import { fetchWorkGroups } from 'actionCreators';
import * as Cells from './Cells';
import { serializeBar, deserializeBar } from 'appUtils/projectPlannerUtils';
/*
Cells handle actions differently for the create row - update redux createRowData with correct key/value pairs. Data gets serialized like other timesheets via selectors so we can re-use existing timesheet components.
*/

const emptyRow = { rowType: 'emptyRow', isDragDisabled: true, rowHeight: 35 };

const buildHeader = ({ list, customRowProps }) => {
  const {
    id,
    uid,
    headerType,
    isOpen,
    headerHeight,
    renderHeader,
    headerData
  } = list;
  return {
    ...headerData,
    list,
    isHeader: true,
    id,
    rowType: headerType,
    isOpen,
    itemHeight: headerHeight || ITEM_HEIGHT,
    listType: id,
    renderHeader,
    customRowProps,
    isDragDisabled: list.isDragDisabled,
    draggableId: uid
  };
};
const buildRow = ({ list, item, customRowProps }) => ({
  ...item,
  isRow: true,
  row: item,
  list,
  id: list.getItemId(item),
  isFirstRow: item === list?.listItems?.[0],
  itemHeight: item.rowHeight || list.rowHeight || ITEM_HEIGHT,
  rowType: item.rowType || list.rowType,
  listType: list.id,
  handleClick: list.handleClick,
  customRowProps,
  isDragDisabled:
    typeof item === 'object' && 'isDragDisabled' in item
      ? item.isDragDisabled
      : list.isDragDisabled,
  draggableId: item.uid
});

const buildCustomItem = ({ list, item, customRowProps }) => ({
  row: item,
  id: item.name + list.id,
  itemHeight: item.rowHeight || ITEM_HEIGHT,
  isCustom: true,
  rowType: item.rowType,
  listType: list.id,
  list,
  customRowProps,
  isDragDisabled: true
});

const buildFlatList = ({
  lists,
  engaged,
  search,
  customRowProps,
  emptyRow
}) => {
  const flatList = [];
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      customItems,
      closedItems = [],
      skipHeader,
      isFullyLoaded,
      skipEmptyGroups = true,
      headerType
    } = list;
    if (!listItems.length && skipEmptyGroups) {
      continue;
    }
    if (!skipHeader) {
      flatList.push(buildHeader({ list, customRowProps }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, customRowProps }))
      );

      listItems.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
      if (!isFullyLoaded) {
        break;
      }
    } else {
      closedItems?.forEach((item) =>
        flatList.push(buildRow({ list, item, customRowProps }))
      );
    }
  }
  if (lists.every((list) => list.isFullyLoaded || !list.isOpen)) {
    flatList.push(
      buildCustomItem({
        list: lists[lists.length - 1] || { getItemId: (item) => item?.id },
        customRowProps,
        item: emptyRow
      })
    );
  }
  return flatList;
};

const EmptyDiv = () => <div />;
const SeparatorDiv = ({ isStickyHeader, row }) => (
  <StyledSeparator isStickyHeader={isStickyHeader}>
    <span>{row?.original?.list?.renderHeader()}</span>
  </StyledSeparator>
);

const departmentComponents = {
  collapse: EmptyDiv,
  [SORT_BY.member]: Cells.DepartmentHeaderCell,
  [SORT_BY.email]: HeaderCell,
  padding: EmptyDiv
};

const headerComponents = {
  collapse: EmptyDiv,
  [SORT_BY.email]: HeaderCell
};

const cellComponents = {
  collapse: EmptyDiv,
  [SORT_BY.member]: Cells.MemberCell,
  [SORT_BY.email]: Cells.EmailCell,
  padding: EmptyDiv,
  separatorRow: SeparatorDiv
};

const stickyHeaderComponents = {
  collapse: EmptyDiv,
  departmentRow: Cells.DepartmentHeaderCell,
  departmentRowArchived: Cells.DepartmentHeaderCell,
  padding: EmptyDiv,
  separatorRow: SeparatorDiv
};

const addMemberRowComponents = {
  [SORT_BY.member]: Cells.AddMemberCell
};

const separatorComponents = {
  collapse: EmptyDiv,
  [SORT_BY.member]: SeparatorDiv,
  padding: EmptyDiv
};

const columnWidths = {
  collapse: 30,
  [SORT_BY.member]: 270,
  [SORT_BY.email]: 570,
  padding: 30
};

const ITEM_HEIGHT = 62;
const HEADER_FOOTER_HEIGHT = 45;
const MAX_TABLE_HEIGHT_BUFFER = 90 + 73 + 72;

const noop = () => {};

const DepartmentsTable = ({
  loadMoreItems,
  formattedMemberBudgetSettings,
  teamId,
  budgetSettingsColumnHeaders,
  totalMembersCount,
  workGroupCount
}) => {
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false);
  const [stateIsScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const currentDragItem = useRef(null);
  const isLoadingInitial = totalMembersCount === null;

  useEffect(
    () => {
      // do something when departments change
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, formattedMemberBudgetSettings]
  );
  const columns = useMemo(() => {
    return budgetSettingsColumnHeaders.map(
      (departmentsColumnHeader, index) => ({
        showAdd: !index,
        ...departmentsColumnHeader,
        emptyRow: EmptyDiv,
        memberRow:
          cellComponents[departmentsColumnHeader.headerType] || EmptyDiv,
        memberRowArchived:
          cellComponents[departmentsColumnHeader.headerType] || EmptyDiv,
        addMemberRow:
          addMemberRowComponents[departmentsColumnHeader.headerType] ||
          EmptyDiv,
        separatorRow:
          separatorComponents[departmentsColumnHeader.headerType] || EmptyDiv,
        departmentRow: departmentComponents[departmentsColumnHeader.headerType],
        departmentRowArchived:
          departmentComponents[departmentsColumnHeader.headerType] || EmptyDiv,
        Cell: cellComponents[departmentsColumnHeader.headerType] || EmptyDiv,
        Header:
          headerComponents[departmentsColumnHeader?.headerType] || EmptyDiv,
        width: columnWidths[departmentsColumnHeader.headerType]
      })
    );
  }, [budgetSettingsColumnHeaders]);

  const memberTypeGroupedRowBuilder = useCallback(
    ({ customRowProps, formattedWorkGroups }) => {
      const workGroupOrder = formattedWorkGroups.map(
        (workGroup) => workGroup.id
      );
      const workGroupLists = formattedWorkGroups.reduce((acc, cur, index) => {
        const groupIsOpen = allCollapsed
          ? false
          : isOpen[cur.id] === undefined || isOpen[cur.id];
        acc[cur.id] = {
          listItems:
            [...cur.formattedWorkGroupMembers].map((member) => ({
              ...member,
              uid: serializeBar({
                itemId: member.id,
                itemType: 'member'
              }),
              isDragDisabled: true
            })) || [],
          closedItems: [],
          customItems: [],
          headerData: { ...cur, collapsed: !groupIsOpen },
          getItemId: (item) => item.id,
          handleClick: (item) => console.log(item),
          rowType: cur.discarded_at ? 'memberRowArchived' : 'memberRow',
          headerType: cur.discarded_at
            ? 'departmentRowArchived'
            : 'departmentRow',
          headerHeight: HEADER_FOOTER_HEIGHT,
          id: cur.id,
          skipEmptyGroups: false,
          isOpen: groupIsOpen && cur.formattedWorkGroupMembers.length > 0, // false if empty list
          isArchived: !!cur.discarded_at,
          isEmpty: false,
          isFullyLoaded: true,
          renderHeader: () => cur.name,
          summaryNoun: 'member',
          createRowData: {},
          renderSummaryItem: (item) => item,
          uid: serializeBar({
            itemId: cur.id,
            itemType: 'department'
          }),
          isDragDisabled: workGroupOrder.length < 2
        };
        return acc;
      }, {});

      const archivedSeparator = {
        listItems: [],
        closedItems: [],
        customItems: [],
        headerData: {},
        rowType: 'memberRow',
        headerType: 'separatorRow',
        headerHeight: ITEM_HEIGHT,
        id: 'archived_separator',
        skipEmptyGroups: false,
        isOpen: false,
        isEmpty: false,
        isFullyLoaded: true,
        renderHeader: () => 'Archived Departments',
        summaryNoun: 'member',
        createRowData: {},
        isDragDisabled: true
      };

      const currentDepartments = [];
      const archivedDepartments = [];

      workGroupOrder.forEach((id) => {
        const list = workGroupLists[id];

        if (list) {
          const listToPush = list.isArchived
            ? archivedDepartments
            : currentDepartments;
          listToPush.push(list);

          if (list?.isOpen) {
            list.listItems.push(emptyRow);
          }
        }
      });

      const listsInOrder = [
        ...currentDepartments,
        ...(!currentDragItem.current && archivedDepartments.length
          ? [archivedSeparator, ...archivedDepartments]
          : [])
      ];

      // if (listsInOrder.length) {
      //   const [firstList] = listsInOrder;
      //   firstList.skipHeader = true;
      //   firstList.customItems = [];
      // }
      return listsInOrder;
    },
    [allCollapsed, isOpen]
  );

  const listBuilders = useMemo(
    () => ({
      [VIEW_BY.MEMBER_TYPES]: memberTypeGroupedRowBuilder
    }),
    [memberTypeGroupedRowBuilder]
  );

  const lists = useMemo(() => {
    const listBuilder = listBuilders[VIEW_BY.MEMBER_TYPES];
    if (!listBuilder) {
      return [];
    }
    return listBuilder({
      formattedWorkGroups: formattedMemberBudgetSettings,
      customRowProps: {}
    });
  }, [formattedMemberBudgetSettings, listBuilders]);

  /* ----------------------------- Collapse logic ----------------------------- */

  const handleSetIsOpen = useCallback(
    ({ name: id, value }) => {
      const nextIsOpen = {
        ...isOpen,
        [id]: value
      };

      const getIsAllCollapsed = () => {
        return lists.every((list) => {
          if (
            ['departmentRowArchived', 'departmentRow'].includes(list.headerType)
          ) {
            return !list.isOpen || list.id === id;
          }
          return true; // lists without items are not open by default
        });
      };

      if (!value) {
        if (getIsAllCollapsed()) {
          setAllCollapsed(true);
        }
      } else if (allCollapsed) {
        setAllCollapsed(false);
      }
      listRef.current?.resetAfterIndex(0);
      setIsOpen(nextIsOpen);
    },
    [allCollapsed, isOpen, lists]
  );

  const handleCollapseAll = useCallback(() => {
    setIsOpen(
      formattedMemberBudgetSettings.reduce((acc, cur) => {
        acc[cur.id] = false;
        return acc;
      }, {})
    );
    setAllCollapsed(true);
  }, [formattedMemberBudgetSettings]);

  const handleExpandAll = useCallback(() => {
    setIsOpen({});
    setAllCollapsed(false);
  }, []);

  const toggleCollapseAll = useCallback(() => {
    if (allCollapsed) {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
  }, [allCollapsed, handleCollapseAll, handleExpandAll]);

  /* ------------------------------------ - ----------------------------------- */

  const handleResetHeightCache = useCallback(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  const customRowProps = useMemo(
    () => ({
      handleSetIsOpen,
      isOpen,
      allCollapsed,
      currentDragItem
    }),
    [allCollapsed, handleSetIsOpen, isOpen]
  );

  const rows = useMemo(() => {
    return buildFlatList({
      lists,
      customRowProps,
      engaged: {},
      search: {},
      emptyRow
    });
  }, [customRowProps, lists]);

  useEffect(() => {
    handleResetHeightCache();
  }, [handleResetHeightCache, rows]);

  const firstListId = useMemo(() => lists[0]?.id, [lists]);

  var resetOnClose = useCallback(
    ({ value, uid }) => {
      if (!value) {
        const index = rows.findIndex((row) => row.uid === uid);
        if (index !== -1) {
          listRef.current.scrollToItem(index - 1);
        }
      }
    },
    [rows]
  );

  /* ----------------------------------- DND ---------------------------------- */

  const onBeforeCapture = useCallback(
    ({ draggableId }) => {
      const { itemType, itemId } = deserializeBar(draggableId);
      if (!currentDragItem.current) {
        currentDragItem.current = draggableId;
      }
      if (itemType === 'department') {
        listRef.current?.scrollToItem(
          rows.findIndex((item) => item.draggableId === draggableId)
        );
        handleCollapseAll();
      }
      setIsDragging(true);
    },
    [handleCollapseAll, rows]
  );

  const onDragEnd = useCallback(
    ({ draggableId, destination, source }) => {
      currentDragItem.current = null;
      setIsDragging(false);
      handleExpandAll();

      if (!destination) {
        return;
      }

      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;
      const { itemId, itemType } = deserializeBar(draggableId);
    },
    [handleExpandAll]
  );

  /* ------------------------------------ - ----------------------------------- */

  const isLoading = !totalMembersCount;
  const getItemSize = useCallback(
    (index) => rows[index]?.itemHeight || ITEM_HEIGHT,
    [rows]
  );

  const handleScroll = ({ visibleStartIndex }) => {
    const isScrolled = visibleStartIndex !== 0;
    if (!isScrolled) {
      setActiveSection(null);
      setIsScrolled(false);
      return;
    }
    const item = rows[visibleStartIndex - 1];
    if (!item) {
      return;
    }
    if (
      item.listType !== activeSection &&
      item.list?.headerType !== 'separatorRow'
    ) {
      setActiveSection(item.list.isOpen ? item.listType : null);
    }
    if (isScrolled !== stateIsScrolled) {
      setIsScrolled(isScrolled);
    }
  };

  if (!workGroupCount) return <EmptyDiv />;

  const activeList = lists.find((list) => list.id === activeSection);
  const StickyEl = stickyHeaderComponents[activeList?.headerType];
  const headerItem =
    activeList && buildHeader({ list: activeList, customRowProps });

  const stickyRow = {
    customRowProps,
    list: { listItems: [] },
    isStickyHeader: true,
    ...headerItem
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <CollapseAllButton
        onToggle={toggleCollapseAll}
        isCollapsed={allCollapsed}
        style={{
          left: 24,
          position: 'absolute'
        }}
      />
      <StyledDepartmentsTable>
        {activeList && (
          <>
            <Droppable droppableId="table-header">
              {(droppableProvided) => (
                <div ref={droppableProvided.innerRef}>
                  <Draggable
                    index={0}
                    draggableId={serializeBar({
                      itemId: activeList.id,
                      itemType: 'department'
                    })}
                    key={serializeBar({
                      itemId: activeList.id,
                      itemType: 'department'
                    })}
                    isDragDisabled
                  >
                    {(draggableProvided) => (
                      <StickyHeader
                        row={{
                          original: stickyRow
                        }}
                        columnWidths={columnWidths}
                        totalWidth={900}
                        cells={departmentComponents}
                        columns={budgetSettingsColumnHeaders}
                        provided={draggableProvided}
                      />
                    )}
                  </Draggable>
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </>
        )}
        <Table
          columns={columns}
          data={!isLoading ? rows : []}
          onDragEnd={noop}
          virtual
          itemHeight={ITEM_HEIGHT}
          getItemSize={getItemSize}
          handleScroll={handleScroll}
          maxHeight={window.innerHeight - MAX_TABLE_HEIGHT_BUFFER}
          loadMoreItems={loadMoreItems}
          isVariableSizeTable
          listRef={listRef}
          isDragDisabled
          isInDragContext
          showHeader={false}
        />

        {isLoading && (
          <div style={{ width: '100%' }}>
            <ContentLoader
              height="200"
              primaryColor="#ddd"
              secondaryColor="#eee"
              style={{ width: '640px', margin: '-10px 20px 0px' }}
            >
              <rect x="0" y="5" rx="2" ry="2" width="100%" height="15" />
              <rect x="0" y="25" rx="2" ry="2" width="100%" height="15" />
              <rect x="0" y="45" rx="2" ry="2" width="100%" height="15" />
              <rect x="0" y="66" rx="2" ry="2" width="100%" height="15" />
            </ContentLoader>
          </div>
        )}
      </StyledDepartmentsTable>
    </DragDropContext>
  );
};

const mapStateToProps = (state) => {
  return {
    teamId: getSelectedTeamId(state),
    budgetSettingsColumnHeaders: getDepartmentColumnHeaders(state),
    totalMembersCount: getTeamMembers(state)?.length,
    workGroupCount: state.workGroups.workGroupsOrder.length
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsTable);
