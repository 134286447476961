import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MilestoneIcon = ({ height = '30', width = '24', color = '#27AE60' }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 12C23.5 13.5224 22.8043 15.3283 21.6937 17.2215C20.5902 19.1026 19.1118 21.0125 17.6224 22.729C16.1346 24.4436 14.6453 25.9544 13.5272 27.0371C12.9684 27.5781 12.5031 28.0116 12.1779 28.3093C12.1129 28.3689 12.0534 28.423 12 28.4715C11.9466 28.423 11.8871 28.3689 11.8221 28.3093C11.4969 28.0116 11.0316 27.5781 10.4728 27.0371C9.35472 25.9544 7.86544 24.4436 6.37765 22.729C4.88819 21.0125 3.40976 19.1026 2.30627 17.2215C1.1957 15.3283 0.5 13.5224 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
        fill={color}
        stroke={color}
      />
      <g transform="translate(6,6)">
        <path
          d="M11.25 6.17647C11.25 6.83329 10.952 7.67327 10.4109 8.62291C9.87957 9.55527 9.16098 10.5128 8.42655 11.384C7.69449 12.2525 6.96029 13.0193 6.40802 13.5698C6.25677 13.7206 6.11948 13.8548 6 13.9701C5.88052 13.8548 5.74323 13.7206 5.59198 13.5698C5.03971 13.0193 4.30551 12.2525 3.57345 11.384C2.83902 10.5128 2.12043 9.55527 1.58913 8.62291C1.04797 7.67327 0.75 6.83329 0.75 6.17647C0.75 3.15887 3.12085 0.75 6 0.75C8.87915 0.75 11.25 3.15887 11.25 6.17647Z"
          fill={color}
          stroke="white"
          strokeWidth="1.5"
        />
      </g>
    </SvgIcon>
  );
};

export default MilestoneIcon;
