import {
  FetchTeamRoleTemplatesParams,
  UpdateTeamRoleTemplateParams
} from './types';
import { callApi } from 'appUtils/api';

const fetchTeamRoleTemplates = (
  token: string,
  { teamId }: FetchTeamRoleTemplatesParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`teams/${teamId}/team_role_templates`, token, fetchInit);
};

const updateTeamRoleTemplate = (
  token: string,
  { id, actionType, checked }: UpdateTeamRoleTemplateParams
) => {
  const body = { [actionType]: checked };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`team_role_templates/${id}`, token, fetchInit);
};

export const roleTemplatesApi = {
  fetchTeamRoleTemplates,
  updateTeamRoleTemplate
};
