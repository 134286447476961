import React, { useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getIsOnScheduleView,
  getOnWorkloadView,
  getSplitFlags,
  getTeamSlug
} from 'selectors';
import { viewWorkloadPlanner } from 'actionCreators';
import { fetchDashboards } from 'DashboardModule/actionCreators/dashboard';
import styled from 'styled-components';
import theme from 'theme';
import { MemberInitials } from 'views';
import cn from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import QBDownArrow from 'icons/QBDownArrow';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import ContentLoader from 'react-content-loader';

import { generatePath } from 'react-router';
import { ModalRoutes, SectionRoutes } from 'appConstants/navigation';
import { MEMBER_DISPLAY_TABS, MEMBER_DISPLAY_SIDE_MENU } from './constants';
import useCan from 'appUtils/hooks/useCan';

const MenuContainer = styled.div`
  width: 279px;
  position: absolute;
  margin-left: 40px;
  z-index: 1;
  pointer-events: none; // It allows clicking through this element to the underlying element
  > * {
    pointer-events: auto;
  }
`;

const MemberName = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-weight: 600;
  font-size: 20px;
`;

const MemberEmail = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray7};
  font-weight: 400;
  line-height: 16px;
`;
const MenuListContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
`;

const MenuItem = styled(NavLink)`
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorMediumGray9 : theme.colors.colorCalendarGray};
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  font-size: 15px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;

  &:before {
    content: '';
    ${(props) =>
      props.isActive &&
      `
      border-radius: 4px;
      background-color: ${theme.colors.colorBudgetBlue};
      width: 4px;
      height: 18px;
      position: absolute;
      left: 0px;
      margin-top: 2px;
      `}
  }
`;

const StyledDownArrow = styled.span`
  margin-left: 8px;
  line-height: 30px;

  path {
    fill: ${theme.colors.colorMediumGray9};
  }
`;

const NameContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const MemberInfoContainer = styled.div`
  display: flex;
  margin-top: 28px;
  margin-bottom: 22px;
  height: fit-content;
  align-items: center;

  .regular-member {
    width: 54px;
    height: 54px;
    flex-basis: 54px;

    .initial-div {
      font-size: 20px;
    }
  }
`;

const MemberContainer = styled.div`
  margin-left: 12px;
`;

const getTabs = (filterList) =>
  MEMBER_DISPLAY_SIDE_MENU.filter((menu) => !filterList[menu.name]);

const MemberModalSidebar = ({ activeTab, memberInfo }) => {
  const memberDropdownRef = useRef(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const history = useHistory();

  const teamSlug = useSelector(getTeamSlug);
  const isOnWorkloadView = useSelector(getOnWorkloadView);
  const isOnScheduleView = useSelector(getIsOnScheduleView);
  const location = useLocation();
  const query = location.search;

  const handleCloseModal = () => {
    setDropdownIsOpen(false);
  };

  const handleDone = (members) => {
    const member = members[0];
    const accountId = member?.account?.id;
    if (accountId) {
      const route = generatePath(ModalRoutes.MEMBER, {
        memberId: accountId,
        memberViewType: activeTab
      });
      history.push(`./../../../${route}${query}`);
    }
    handleCloseModal();
  };

  const isActivitySection = activeTab === MEMBER_DISPLAY_TABS.ACTIVITY;
  const isLoading = !memberInfo;

  const {
    memberModalWorkSchedule,
    memberModalWorkPlanner,
    memberModalOverview,
    memberModalProjects,
    memberModalCalendar,
    memberModalProfile
  } = useSelector(getSplitFlags);

  const canViewWorkPlanTab = useCan(viewWorkloadPlanner, {
    accountId: memberInfo?.account.id,
    isReady: !isLoading
  });
  const canViewOverViewTab = useCan(fetchDashboards, {
    accountId: memberInfo?.account.id,
    isReady: !isLoading
  });

  const shouldOpenNewTab = isOnWorkloadView || isOnScheduleView;

  const tabs = useMemo(() => {
    const filterList = {
      [MEMBER_DISPLAY_TABS.OVERVIEW]:
        !memberModalOverview || !canViewOverViewTab,
      [MEMBER_DISPLAY_TABS.SCHEDULE]: !memberModalWorkSchedule,
      [MEMBER_DISPLAY_TABS.WORKPLANS]:
        !memberModalWorkPlanner || !canViewWorkPlanTab,
      [MEMBER_DISPLAY_TABS.PROJECTS]: !memberModalProjects,
      [MEMBER_DISPLAY_TABS.CALENDAR]: !memberModalCalendar,
      [MEMBER_DISPLAY_TABS.PROFILE]: !memberModalProfile
    };
    const tabs = getTabs(filterList);

    return tabs.map((tab) => {
      // it is temporary fix because planner/workload doesn't support multiple instance to show them in the same screen.
      // when clicking this tab, open a new tab with the modal in the team tab.
      if (
        tab.name === MEMBER_DISPLAY_TABS.WORKPLANS &&
        shouldOpenNewTab &&
        teamSlug
      ) {
        const sectionRoute = generatePath(SectionRoutes.MEMBERS, {
          teamSlug,
          membersViewType: 'members'
        });
        const ModalRoute = generatePath(ModalRoutes.MEMBER, {
          memberId: memberInfo?.account.id,
          memberViewType: MEMBER_DISPLAY_TABS.WORKPLANS
        });

        return {
          ...tab,
          to: `${sectionRoute}/${ModalRoute}`,
          target: '_blank',
          disabled: !memberInfo?.account.id || !teamSlug
        };
      } else {
        return tab;
      }
    });
  }, [
    canViewOverViewTab,
    canViewWorkPlanTab,
    memberInfo?.account.id,
    memberModalCalendar,
    memberModalOverview,
    memberModalProfile,
    memberModalProjects,
    memberModalWorkPlanner,
    memberModalWorkSchedule,
    shouldOpenNewTab,
    teamSlug
  ]);

  return (
    <MenuContainer>
      <MemberInfoContainer>
        {isLoading ? (
          <div
            style={{
              width: '175px',
              height: '54px'
            }}
          >
            <ContentLoader
              height="54"
              primaryColor="#efefef"
              secondaryColor="#fff"
              viewBox="0 0 175 54"
            >
              <rect x="0" y="0" rx="50%" ry="50%" width="54" height="54" />
              <rect x="63" y="6" rx="2" ry="2" width="112" height="24" />
              <rect x="63" y="36" rx="2" ry="2" width="112" height="12" />
            </ContentLoader>
          </div>
        ) : (
          <>
            <MemberInitials
              key={memberInfo?.account?.id}
              member={memberInfo}
              idx={memberInfo?.account?.id}
              size="medium"
              nonBaseMemberRoleTop="36px"
              nonBaseMemberRoleLeft="36px"
              classes={cn('regular-member selected', {
                selected: false
              })}
            />
            <MemberContainer>
              {memberInfo?.account?.name && (
                <NameContainer
                  ref={memberDropdownRef}
                  onClick={() => setDropdownIsOpen(true)}
                >
                  <MemberName>{memberInfo?.account?.name}</MemberName>
                  <StyledDownArrow className="styled-down-arrow">
                    <QBDownArrow />
                  </StyledDownArrow>
                </NameContainer>
              )}
              <MemberEmail>{memberInfo?.account?.email}</MemberEmail>
            </MemberContainer>
          </>
        )}
      </MemberInfoContainer>
      <MenuListContainer>
        {tabs.map(({ title, to, target, disabled }) => (
          <MenuItem
            key={to}
            tag={Link}
            isActivitySection={isActivitySection}
            isActive={activeTab === to}
            to={to}
            target={target}
            disabled={disabled}
          >
            {title}
          </MenuItem>
        ))}
      </MenuListContainer>

      {dropdownIsOpen && (
        <BulkMemberSelector
          alwaysOpen
          renderSelect={() => null}
          singleSelect
          hideFooter
          target={memberDropdownRef}
          onClose={handleCloseModal}
          handleDone={handleDone}
          closeOnSelect
          renderHeader={() => 'Select Member'}
        />
      )}
    </MenuContainer>
  );
};

export default MemberModalSidebar;
