import React from 'react';
import { StyledRateMultiplierCell, RateMultiplierContainer } from './styles';

const EmailCell = ({ cell, row }) => {
  const onClickFocus = () => {};

  if (!row.original.member) {
    return <div> </div>;
  }

  return (
    <StyledRateMultiplierCell
      data-for="app-tooltip"
      data-delay-show={500}
      data-effect="solid"
      onClick={onClickFocus}
    >
      <RateMultiplierContainer className="left-align">
        {row.original.email || ''}
      </RateMultiplierContainer>
    </StyledRateMultiplierCell>
  );
};

export default EmailCell;
