const Project = 'Project';
const ProjectTask = 'ProjectTask';
const ProjectNote = 'ProjectNote';
const ProjectStatus = 'ProjectStatus';
const ProjectScope = 'ProjectScope';
const ProjectScopeRequest = 'ProjectScopeRequest';
const Rate = 'Rate';
const TaskComment = 'TaskComment';
const Milestone = 'Milestone';
const Phase = 'Phase';
const PhaseEstimation = 'PhaseEstimation';
const MemberBudget = 'MemberBudget';
const MemberBudgetPhase = 'MemberBudgetPhase';
const MemberRate = 'MemberRate';
const ProjectScheduleBar = 'ProjectScheduleBar';
const ActivityPhaseScheduleBar = 'ActivityPhaseScheduleBar';
const ActivityPhaseScheduleBarRequest = 'ActivityPhaseScheduleBarRequest';
const TimeEntry = 'TimeEntry';
const TaskGroup = 'TaskGroup';

export const actionableTypesHash = {
  Project,
  ProjectTask,
  ProjectNote,
  ProjectStatus,
  ProjectScope,
  ProjectScopeRequest,
  Rate,
  TaskComment,
  Milestone,
  Phase,
  PhaseEstimation,
  MemberBudget,
  MemberBudgetPhase,
  MemberRate,
  ProjectScheduleBar,
  ActivityPhaseScheduleBar,
  ActivityPhaseScheduleBarRequest,
  TimeEntry,
  TaskGroup
};
export const actionableType = [
  Project,
  ProjectTask,
  ProjectNote,
  ProjectStatus,
  ProjectScope,
  Rate,
  TaskComment,
  Milestone,
  Phase,
  PhaseEstimation,
  MemberBudget,
  MemberBudgetPhase,
  MemberRate,
  ProjectScheduleBar,
  ActivityPhaseScheduleBar,
  ActivityPhaseScheduleBarRequest,
  TimeEntry,
  TaskGroup
];

// Should match BE
export const userActivityTypes = {
  1: 'DUMMY_ACTION',
  2: 'TASK_EDIT_DESCRIPTION',
  3: 'TASK_SET_DUE_DATE',
  4: 'TASK_SET_SCHEDULE_DATE',
  5: 'TASK_SET_ESTIMATION',
  6: 'TASK_ASSIGN',
  7: 'TASK_ACCEPT',
  8: 'TASK_CHANGE_PROJECT',
  9: 'TASK_FOLLOW',
  10: 'TASK_UNFOLLOW',
  11: 'TASK_COMPLETE',
  12: 'PROJECT_CREATE',
  13: 'PROJECT_ARCHIVE',
  14: 'PROJECT_ADD_MEMBER',
  15: 'TASK_COMMENT',
  16: 'TASK_REPLY',
  17: 'TASK_MENTION',
  18: 'NOTE_CREATION',
  19: 'TASK_INCOMPLETE',
  20: 'PROJECT_REMOVE_MEMBER',
  21: 'TASK_CREATE',
  22: 'PROJECT_STATUS',
  23: 'MILESTONE_CREATE',
  24: 'MILESTONE_EDIT',
  25: 'PROJECT_GET',
  26: 'PROJECT_REPOSITION',
  27: 'NOTE_EDIT',
  28: 'NOTE_DELETE',
  29: 'TASK_REPOSITION',
  30: 'TASK_DELETE',
  31: 'TASK_COPY',
  32: 'TASK_MOVE_BULK',
  33: 'NOTE_GET',
  34: 'PROJECT_STAR',
  35: 'PROJECT_NOTIFICATIONS_SETTINGS_UPDATE',
  36: 'TASK_COMMENT_EDIT',
  37: 'TASK_REPLY_EDIT',
  38: 'CREATE_UNASSIGNED_MEMBER_BUDGET',
  39: 'UPDATE_UNASSIGNED_MEMBER_BUDGET',
  40: 'SET_MEMBER_BUDGET_ROLE',
  41: 'CREATE_PHASE_ESTIMATION',
  42: 'SET_PHASE_ESTIMATION_ESTIMATED_HOURS',
  43: 'SET_PHASE_ESTIMATION_ESTIMATED_COST',
  44: 'CREATE_MEMBER_RATE',
  45: 'UPDATE_MEMBER_RATE',
  46: 'CREATE_ACTIVITY_PHASE_SCHEDULE_BAR',
  47: 'UPDATE_ACTIVITY_PHASE_SCHEDULE_BAR',
  48: 'DELETE_ACTIVITY_PHASE_SCHEDULE_BAR',
  49: 'SET_PROJECT_TASK_PRIORITY',
  50: 'SET_PROJECT_TASK_STATUS',
  51: 'SET_PROJECT_TASK_ESTIMATED_HOURS',
  52: 'SET_PROJECT_TASK_TASK_GROUP',
  53: 'SET_PROJECT_TASK_PHASE',
  54: 'SET_PROJECT_STAGE',
  55: 'SET_PROJECT_STATUS',
  56: 'SET_PROJECT_PRIORITY',
  57: 'CREATE_TASK_GROUP',
  58: 'UPDATE_TASK_GROUP',
  59: 'CREATE_PHASE',
  60: 'UPDATE_PHASE',
  61: 'ARCHIVE_PHASE',
  62: 'DELETE_PHASE',
  63: 'ARCHIVE_MEMBER_BUDGET',
  64: 'DELETE_MEMBER_BUDGET',
  65: 'DELETE_MEMBER_RATE',
  66: 'CREATE_RATE',
  67: 'UPDATE_RATE',
  68: 'DELETE_RATE',
  69: 'ARCHIVE_RATE',
  70: 'UNARCHIVE_RATE'
};

// Can be edited for FE user friendly labels
export const actionableTypeDescription = [
  'Project',
  'Task',
  'Note',
  'Project Status',
  'Scope',
  'Company Rate',
  'Task Comment',
  'Milestone',
  'Phase',
  'Phase Estimation',
  'Member Budget',
  'Member Budget Phase',
  'Member Rate',
  'Workload Plan',
  'Timesheet Entry',
  'Task List'
];
// Can be edited for FE user friendly labels

//   | Groups         | Activity Ids                                                           |
//   | -------------- | ---------------------------------------------------------------------- |
// 1 | Project Edit   | 14, 20                                                                 |
// 2 | Task Edit      | 2, 3, 4, 5, 30, 31, 32, 57, 58, 36, 37, 49, 50, 51, 52, 53, 54, 55, 56 |
// 3 | Task comments  | 15, 16                                                             |
// 4 | Budget Edits   | 38,39,40,41,42,43,44,45,63,64,65,66,67,68,69,70                        |
// 5 | Schedule Edits | 46,47,48,59,60,61,62                                                   |
export const getUserActivityGroupId = (id) => {
  switch (id) {
    case 14:
    case 20:
      return 'project_edit';
    case 2:
    case 3:
    case 4:
    case 5:
    case 30:
    case 31:
    case 32:
    case 57:
    case 58:
    case 36:
    case 37:
    case 49:
    case 50:
    case 51:
    case 52:
    case 53:
    case 54:
    case 55:
    case 56:
      return 'task_edit';
    case 15:
    case 16:
      return 'task_comment';
    case 38:
    case 39:
    case 40:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 63:
    case 64:
    case 65:
    case 66:
    case 67:
    case 68:
    case 69:
    case 70:
      return 'budget_edit';
    case 46:
    case 47:
    case 48:
    case 59:
    case 60:
    case 61:
    case 62:
      return 'schedule_edit';

    default:
      return id;
  }
};
export const userActivityTypesDescriptions = {
  task_edit: (activityCount) =>
    `${activityCount === 1 ? '1 Task Edit' : `${activityCount} Tasks Edited`}`,

  11: (activityCount) =>
    `${
      activityCount === 1
        ? '1 Task Completed'
        : `${activityCount} Tasks Completed`
    }`,
  12: (activityCount) => 'Created the Project',
  13: (activityCount) => 'Archived the Project',
  project_edit: (activityCount) =>
    `${
      activityCount === 1 ? '1 Project Edit' : `${activityCount} Project Edits`
    }`,
  task_comment: (activityCount) =>
    `${
      activityCount === 1 ? '1 Task Comment ' : `${activityCount} Task Comments`
    }`,
  18: (activityCount) =>
    `${
      activityCount === 1 ? '1 Note Created ' : `${activityCount} Notes Created`
    }`,
  21: (activityCount) =>
    `${
      activityCount === 1 ? '1 Task Created ' : `${activityCount} Tasks Created`
    }`,
  22: (activityCount) =>
    `${
      activityCount > 1
        ? '1 Project Status Update'
        : `${activityCount} Project Status Updates`
    }`,
  23: (activityCount) =>
    `${
      activityCount === 1
        ? '1 Schedule Created '
        : `${activityCount} Schedules Created`
    }`,
  24: (activityCount) =>
    `${
      activityCount === 1
        ? '1 Schedule Updated '
        : `${activityCount} Schedule Updates`
    }`,
  27: (activityCount) =>
    `${
      activityCount === 1 ? '1 Note Edited ' : `${activityCount} Notes Edited`
    }`,
  28: (activityCount) => '1 Note Edited ',
  budget_edit: (activityCount) =>
    `${
      activityCount === 1 ? '1 Budget Edit ' : `${activityCount} Budget Edits`
    }`,
  schedule_edit: (activityCount) =>
    `${
      activityCount === 1
        ? '1 Schedule Edit '
        : `${activityCount} Schedule Edits`
    }`
};
