import React from 'react';
import cn from 'classnames';
import InviteToMosaicWithPlusIcon from 'icons/InviteToMosaicWithPlusIcon';
import styled from 'styled-components';

const InviteToMosaicIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding-top: 3px;
  margin-right: 11px;
`;

const InviteRow = ({ toggleInviteForm, borderTop, tooltip }) => (
  <li
    className={cn('invite-row', { 'border-top': borderTop })}
    onClick={() => toggleInviteForm({ open: true, origin: 'InviteRow' })}
    data-html
    data-for="app-tooltip"
    data-effect="solid"
    data-class="center"
    data-tip={tooltip}
  >
    <InviteToMosaicIconContainer>
      <InviteToMosaicWithPlusIcon />
    </InviteToMosaicIconContainer>
    <span>Invite to Mosaic</span>
  </li>
);

export default InviteRow;
