import * as constants from 'appConstants';

const {
  FETCH_TEAM_MEMBERS_BY_BOARD,
  FETCH_ALL_TEAM_MEMBERS_BY_BOARD,
  FETCH_ALL_PROJECT_MEMBERS,
  FETCH_TEAM_MEMBER_DETAIL
} = constants;

export const initialState = {
  boards: {},
  team_members: [],
  project_members: [],
  teamMemberDetailHash: {}
};

const teamMembers = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_TEAM_MEMBERS_BY_BOARD.SUCCESS: {
      const { response } = payload;
      const { board_id } = response;
      if (board_id) {
        const {
          team: { team_members }
        } = response;
        return {
          ...state,
          boards: {
            ...state.boards,
            [board_id]: team_members
          }
        };
      } else {
        return {
          ...state,
          team_members: [action.payload.response.teams[0].team_members]
        };
      }
    }
    case FETCH_ALL_TEAM_MEMBERS_BY_BOARD.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        team_members: response
      };
    }
    case FETCH_ALL_PROJECT_MEMBERS.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        project_members: response.project_memberships
      };
    }
    case FETCH_TEAM_MEMBER_DETAIL.SUCCESS: {
      const { team_member } = payload.response;
      return {
        ...state,
        teamMemberDetailHash: {
          ...state.teamMemberDetailHash,
          [team_member.id]: team_member
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default teamMembers;
