import { Task } from 'models/task';
import moment from 'appUtils/momentConfig';
import { MOMENT_USA_DATE } from 'appConstants/date';

export const isOverlapPlanTask = ({
  task,
  scheduleStart,
  scheduleEnd
}: {
  task: Task;
  scheduleStart: string;
  scheduleEnd: string;
}): boolean => {
  if (task.schedule_start) {
    const scheduleRange = moment.range(
      moment(scheduleStart, MOMENT_USA_DATE),
      moment(scheduleEnd, MOMENT_USA_DATE)
    );
    const taskRange = moment.range(
      moment(task.schedule_start),
      moment(task.schedule_end ?? task.schedule_start)
    );
    return scheduleRange.overlaps(taskRange, { adjacent: true });
  }

  return false;
};
