import React from 'react';
import SvgIcon from 'components/SvgIcon';

export const AddTaskPlus = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#0074D9"
          stroke="#0074D9"
          strokeWidth=".5"
          d="M17 12.352h-4.688V17h-.664v-4.648H7v-.665h4.648V7h.665v4.688H17z"
        />
      </g>
    </SvgIcon>
  );
};

export const AddTaskPlusGray = ({ width = '24', height = '24' }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#808080"
          stroke="#808080"
          strokeWidth=".5"
          d="M17 12.352h-4.688V17h-.664v-4.648H7v-.665h4.648V7h.665v4.688H17z"
        />
      </g>
    </SvgIcon>
  );
};
