import { fetchEntity, changeEntity } from './generics';
import { put, select } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';
import * as entityActions from '../actions';
import {
  fetchTeamMembers as fetchTeamMembersActionCreator,
  addMemberToGroup as addMemberToGroupActionCreator,
  addMemberToProject as addMemberToProjectActionCreator,
  editGroup as editGroupActionCreator,
  editProject as editProjectActionCreator,
  triggerMemberListRefresh as triggerMemberListRefreshActionCreator,
  setMemberInputValue,
  toggleInviteButton,
  toggleMemberInput,
  toggleInviteForm,
  updateErrorMessage,
  handleErrorMessage,
  startOfflineMode
} from 'actionCreators';
import {
  calculateHighestTeamMembershipRoleId,
  roleIdToStringHash
} from 'appUtils/roleDisplay';
import {
  GENERIC_ACTION,
  APP_OFFLINE_MESSAGE,
  RequestPriorityGroup
} from 'appConstants';
import { api } from '../service';
import * as qbAPI from 'QuickbooksModule/api';
import {
  syncQbMembers,
  fetchSyncedMembers
} from 'QuickbooksModule/entityActions';
import { getAuthToken, getSelectedTeamId } from 'selectors';

const { user, inviteMember, teamMembers } = entityActions;

export function* fetchUser(action) {
  /*
    condition prevents fetch of null user on nav to invite
    during active session
  */
  const token = yield select(getAuthToken);

  if (!window.location.href.includes('/invite/')) {
    const { error, response } = yield fetchEntity(
      user,
      api.fetchMe,
      undefined,
      [token],
      action,
      RequestPriorityGroup.Startup
    );
    if (error || !response) {
      yield put(startOfflineMode());
      yield put(
        handleErrorMessage({
          type: GENERIC_ACTION,
          isUserFriendlyError: true,
          errorMessage: APP_OFFLINE_MESSAGE
        })
      );
      return;
    }
    const me = response?.account;
    const selectedMember = me?.default_team?.team_members.filter(
      (member) => member.id === me.team_membership_id
    )[0];
    const highestRoleId = selectedMember
      ? calculateHighestTeamMembershipRoleId(selectedMember)
      : null;
    if (
      me &&
      me.default_team &&
      !window.location.href.includes('http://localhost')
    ) {
      const userId = me.id;
      const mixpanelPerson = {
        company_id: me.default_team.id,
        company_name: me.default_team.name,
        $email: me.email,
        id: userId,
        access_role: highestRoleId
          ? roleIdToStringHash[highestRoleId]
          : '' || '',
        salesForceId: me.default_team?.sales_force_id || '',
        activeUsers: me.default_team?.actives_count ?? 0,
        $name: me.name
      };
      mixpanel.identify(userId);
      mixpanel.people.set(mixpanelPerson);
    }

    if (action.meta?.onSuccess) {
      action.meta.onSuccess();
    }
  }
}

export function* postInviteTeamMember(action) {
  const { invitees, group, project, inviteeRole } = action.payload;
  const token = yield select(getAuthToken);
  for (const invitee of invitees) {
    if (invitee && invitee.firstName && invitee.lastName && invitee.email) {
      yield* postIndividualInvite(
        invitee.firstName,
        invitee.lastName,
        invitee.email,
        token,
        inviteeRole,
        project,
        group
      );
    }
  }
}

export function* postQBInvites(action) {
  const { invites, inviteeRole, project, group } = action.payload;
  const token = yield select(getAuthToken);
  const syncMembers = [];
  for (const member of invites) {
    const { error, response } = yield changeEntity(
      inviteMember,
      api.postInviteTeamMember,
      [member.email, member.firstName, member.lastName, token, inviteeRole]
    );
    if (!error) {
      syncMembers.push({
        employee_id: member.employeeId,
        mosaic_account_id: response.id
      });
      if (project) {
        const newMember = { account: response, project_role: 3 };
        const memberList = [newMember, ...project.project_membership];
        yield put(
          addMemberToProjectActionCreator(project.id, response.id, 3, group.id)
        );
        yield put(
          editProjectActionCreator(
            token,
            project.id,
            project.title,
            project.description,
            project.client,
            project.project_number,
            project.board_id,
            project.project_priority,
            project.board_id,
            memberList,
            false
          )
        );
        yield put(triggerMemberListRefreshActionCreator(true));
      } else if (group) {
        yield put(addMemberToGroupActionCreator(token, group.id, response));
      }
      if (group) {
        yield put(
          editGroupActionCreator(token, group.id, group.name, group.is_private)
        );
        yield put(toggleInviteForm({ open: false }));
        yield put(toggleInviteButton(false));
        yield put(setMemberInputValue(''));
        yield put(toggleMemberInput(false));
      } else {
        yield put(fetchTeamMembersActionCreator());
      }
    }
  }
  const teamId = yield select(getSelectedTeamId);

  const body = {
    records: syncMembers
  };

  const { errorQB, responseQB } = yield changeEntity(
    syncQbMembers,
    qbAPI.syncQbMembers,
    [token, teamId, body],
    action
  );
  yield fetchEntity(
    fetchSyncedMembers,
    qbAPI.fetchSyncedMembers,
    teamId,
    [token],
    action
  );
}

function* postIndividualInvite(
  firstName,
  lastName,
  email,
  token,
  inviteeRole,
  project,
  group
) {
  const { error, response } = yield changeEntity(
    inviteMember,
    api.postInviteTeamMember,
    [email, firstName, lastName, token, inviteeRole]
  );
  if (!error) {
    if (project) {
      const newMember = { account: response, project_role: 3 };
      const memberList = [newMember, ...project.project_membership];
      yield put(
        addMemberToProjectActionCreator(project.id, response.id, 3, group.id)
      );
      // action is not being dispatched according to the shape of the action creator params, thus this is a non-useful action dispatched
      yield put(
        editProjectActionCreator(
          token,
          project.id,
          project.title,
          project.description,
          project.client,
          project.project_number,
          project.board_id,
          project.project_priority,
          project.board_id,
          memberList,
          false
        )
      );
      // non-useful action dispatched
      yield put(triggerMemberListRefreshActionCreator(true));
    } else if (group) {
      yield put(addMemberToGroupActionCreator(token, group.id, response));
    }
    if (group) {
      yield put(
        editGroupActionCreator(token, group.id, group.name, group.is_private)
      );
      yield put(toggleInviteForm({ open: false }));
      yield put(toggleInviteButton(false));
      yield put(setMemberInputValue(''));
      yield put(toggleMemberInput(false));
    } else {
      yield put(fetchTeamMembersActionCreator());
    }
  }
}

export function* fetchTeamMembers(action) {
  const token = yield select(getAuthToken);
  const { isGetTeamMemberMfa = false } = action.payload;
  yield fetchEntity(
    teamMembers,
    api.fetchTeamMembers,
    undefined,
    [token, { isGetTeamMemberMfa }],
    action
  );
}

export function* updateAccountsDefaultFollowUpMessage(action) {
  const { token, body } = action.payload;
  yield changeEntity(
    entityActions.updateAccountDefaultMessage,
    api.patchAccountDefaultFollowUpMessage,
    [token, body]
  );
}
