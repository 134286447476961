import styled from 'styled-components';
import { useState, ReactNode } from 'react';
import theme from 'theme';
import DropdownMenuIcon from 'icons/DropdownMenuIcon';
import cn from 'classnames';

type OptionsSubMenuRowProps = {
  label: string;
  status: string;
  subMenuExpandedComponent: ReactNode;
  isDisabled: boolean;
  tooltipText?: string;
};

const OptionsSubMenuRow = ({
  label,
  status,
  subMenuExpandedComponent,
  isDisabled,
  tooltipText
}: OptionsSubMenuRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggleMenu = !isDisabled
    ? () => setIsOpen((prev) => !prev)
    : undefined;

  return (
    <>
      <OptionsSubMenuRowContainer
        data-for="app-tooltip"
        data-tip={tooltipText}
        data-effect="solid"
        onClick={handleToggleMenu}
        className={cn({ disabled: isDisabled })}
      >
        <OptionsSubMenuRowLabel>{label}</OptionsSubMenuRowLabel>
        <OptionsSubMenuRowStatusContainer>
          <OptionsSubMenuRowValue className={cn({ disabled: isDisabled })}>
            {status}
          </OptionsSubMenuRowValue>
          <DropdownMenuIconContainer isOpen={isOpen}>
            <DropdownMenuIcon />
          </DropdownMenuIconContainer>
        </OptionsSubMenuRowStatusContainer>
      </OptionsSubMenuRowContainer>

      {isOpen && (
        <OptionsSubMenuRowExpandedContainer>
          {subMenuExpandedComponent}
        </OptionsSubMenuRowExpandedContainer>
      )}
    </>
  );
};

const DropdownMenuIconContainer = styled.div<{ isOpen: boolean }>`
  margin-left: 8px;
  svg {
    transform: ${({ isOpen }) => (!isOpen ? 'rotate(270deg)' : undefined)};
  }
`;

const OptionsSubMenuRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 15px;
  font-size: 13px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    color: ${theme.colors.colorCalendarBlue};
    ${DropdownMenuIconContainer} {
      g {
        fill: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;

const OptionsSubMenuRowExpandedContainer = styled.div``;

const OptionsSubMenuRowLabel = styled.div``;

const OptionsSubMenuRowValue = styled.div`
  &:not(.disabled) {
    color: ${theme.colors.colorCalendarBlue};
  }
`;

const OptionsSubMenuRowStatusContainer = styled.div`
  display: flex;
`;

export default OptionsSubMenuRow;
