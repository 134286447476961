import { createSelector } from 'reselect';
const emptyObj = {};
export const getNotes = (state) => state.notes || emptyObj;
export const getActivityFeedNotes = createSelector(
  getNotes,
  (notes) => notes.activityFeedNotes
);
export const getNotesSelectedAccountIds = createSelector(
  getNotes,
  (notes) => notes.selectedAccountIds
);
export const getNotesSelectedTagIds = createSelector(
  getNotes,
  (notes) => notes.selectedTagIds
);
