import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembersHash } from 'selectors';
import { FilterField } from 'FilterModule/constants';
import { useGroupedFilter } from '.';
import { GenericFilterListTypeHookWithGroupings } from 'FilterModule/types';

export const useGroupedMembersFilter: GenericFilterListTypeHookWithGroupings =
  ({
    isOff,
    config,
    isoStateId,
    shouldUseDraft,
    filterListType,
    field = FilterField.account_ids
  }) => {
    const memberHash = useAppSelector((state) =>
      isOff ? emptyObj : getTeamMembersHash(state)
    ) as Record<
      number,
      {
        name: string;
        initials: string;
        isLoggedInUser: boolean;
        is_archived: boolean;
        account: { id: number };
      }
    >; // FIXME

    const getIsItemArchived = useCallback(
      (accountId: number) => {
        return memberHash[accountId]?.is_archived ?? false;
      },
      [memberHash]
    );

    const groupedFilterValues = useGroupedFilter({
      isOff,
      config,
      isoStateId,
      filterListType,
      field,
      getIsItemArchived,
      labelKey,
      shouldUseDraft
    });

    const onlySelectedItemLabel =
      groupedFilterValues.selectedItems.length === 1
        ? memberHash[groupedFilterValues.selectedItems[0] as number]?.name
        : undefined;

    return {
      ...groupedFilterValues,
      onlySelectedItemLabel,
      itemHash: memberHash,
      labelKey,
      filterLabel: config?.filterLabel
    };
  };

// -----------------------------------------------------------------------------

const emptyObj = {};

const labelKey = 'name';
