import { select, all, call, put } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import * as actionCreators from 'actionCreators';
import { getAuthToken } from 'selectors';

export function* fetchClients(action) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    entityActions.fetchClients,
    api.fetchClients,
    teamId,
    [token],
    action
  );
}

export function* fetchClient(action) {
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    entityActions.fetchClient,
    api.fetchClient,
    undefined,
    [token],
    action
  );
}

export function* createClient(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.createClient,
    api.createClient,
    [token, id, payload],
    action
  );
}

export function* updateClient(action) {
  const { name, id } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    entityActions.updateClient,
    api.updateClient,
    [token, id, name],
    action
  );
}
