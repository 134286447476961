import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeamId, getSelectedTeamViewSettings } from 'selectors';
import { updateTeamViewSettings } from 'actionCreators';
import cn from 'classnames';

const EmailSettings = () => {
  const dispatch = useDispatch();
  const teamSettings = useSelector(getSelectedTeamViewSettings);
  const teamId = useSelector(getSelectedTeamId);
  const [settings, setSettings] = useState(teamSettings);
  useEffect(() => {
    setSettings(teamSettings);
  }, [teamSettings]);

  const handleChangeSettings = () => {
    const viewSettings = {
      ...settings,
      disable_emails: !settings.disable_emails
    };
    setSettings(viewSettings);
    dispatch(updateTeamViewSettings({ viewSettings, teamId }));
  };
  const isEmailNotificationOn = !settings?.disable_emails;

  return (
    <div className="personal-settings-container team-settings section-header-email">
      <div className="email-label"> Notification Email </div>
      <div className="email-button">
        <div className="email-switch">
          <label className="switch" htmlFor="is-private">
            <input
              type="checkbox"
              checked={isEmailNotificationOn}
              name="isPrivate"
              onChange={handleChangeSettings}
              id="is-private"
            />
            <div
              className={cn('slider round', {
                private: isEmailNotificationOn
              })}
            />
          </label>
        </div>
      </div>
      <div className="email-value">
        {isEmailNotificationOn ? (
          <label id="yes-email"> On </label>
        ) : (
          <label id="no-email"> Off </label>
        )}
      </div>
    </div>
  );
};

export default EmailSettings;
