import { useSelector } from 'react-redux';
import cn from 'classnames';
import { getMe } from 'selectors';
import styled from 'styled-components';
import { RowRendererProps } from '../types';
import UnTypedMemberInitials from 'views/memberDisplay/MemberInitials';

const MemberInitials = UnTypedMemberInitials as unknown as (
  props: any
) => JSX.Element;

export const MemberRowNameCell = ({ row: { original } }: RowRendererProps) => {
  const me = useSelector(getMe);
  const { teamMember } = original.rowData;
  return (
    <NameCellContainer>
      <MemberInfo>
        <MemberInitials
          projectMembers={true}
          member={teamMember}
          classes={cn('item-account selected', {
            'small-logged-member': teamMember.account?.id === me.id,
            'small-regular-member': teamMember.account?.id !== me.id
          })}
          idx={'idx-placeholder'}
          isOnTaskModal
        />
        <StyledNameContainer className="app-cues-styled-name-container">
          <StyledName>{teamMember.account?.name}</StyledName>
        </StyledNameContainer>
      </MemberInfo>
    </NameCellContainer>
  );
};

const NameCellContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const MemberInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const StyledNameContainer = styled.div`
  display: flex;
  min-width: 0;
`;

const StyledName = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
