import { ModalBody } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';

import {
  getSubcustomerPrefillText,
  getSubcustomerTobeSynced,
  getCreatingNewSubSubFlag,
  getTobeChangedSyncedProperty,
  getSubcustomerAlreadyAvailableItems
} from 'QuickbooksModule/selectors';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  CheckBoxText,
  CheckBoxContainer
} from 'BudgetModule/components/styles';
import CheckBox from 'views/projectPlanner/CheckBox';
import PhaseOfWorkTemplateInput from 'BudgetModule/components/PhaseOfWorkTemplateInput';
import {
  closeSubcustomerModal,
  syncSubcustomersToProjects,
  syncSubSubCustomers
} from 'QuickbooksModule/actionCreators';
import { ErrorCopy } from 'QuickbooksModule/components/styles';

class SubcustomerModalBody extends React.Component {
  state = {
    title: this.props.title || this.props.preFillText,
    error: '',
    checked: !!this.props.checked
  };

  handleChange = (e) => {
    this.setState({
      title: e.target.value,
      error: ''
    });
  };

  onCancel = () => {
    this.handleClose();
  };

  onSubmit = (params) => {
    this.apiCall(params);
  };

  apiCall = (params) => {
    const {
      toBeSynced,
      isCreatingNewSubSub,
      toBeChangedProperty,
      syncSubSubCustomers,
      syncSubcustomersToProjects,
      alreadyAvailableItems
    } = this.props;
    if (
      alreadyAvailableItems &&
      alreadyAvailableItems.length > 0 &&
      alreadyAvailableItems.includes(params.name.trim().toLowerCase())
    ) {
      this.setState({
        error: 'With this name a subcustomer already exists!'
      });
      return;
    }
    const newSynced = {
      ...toBeSynced,
      [toBeChangedProperty]: params.name.trim()
    };
    const syncAPICall = isCreatingNewSubSub
      ? syncSubSubCustomers
      : syncSubcustomersToProjects;
    syncAPICall({
      body: {
        records: [newSynced]
      }
    });
    this.handleClose();
  };

  onConfirm = () => {
    const { title, checked } = this.state;
    this.apiCall({ name: title });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.preFillText && this.props.preFillText) {
      this.setState({
        name: this.props.preFillText
      });
    }
  }

  handleClose = () => {
    const { closeSubcustomerModal } = this.props;
    closeSubcustomerModal({});
  };

  toggleCheckBox = () =>
    this.setState((prevState) => ({ checked: !prevState.checked }));

  renderInitialButtons = () => {
    const isGray = this.props.preFillText ? false : !this.state.title;
    return (
      <>
        <Left />
        <Right>
          <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
          <ConfirmButton isGray={isGray} onClick={this.onConfirm}>
            {'Create'}
          </ConfirmButton>
        </Right>
      </>
    );
  };

  renderButtons = () => {
    return this.renderInitialButtons();
  };

  render() {
    const { preFillText } = this.props;
    const { checked, error } = this.state;

    return (
      <ModalBody>
        <>
          <PhaseOfWorkTemplateInput
            handleSubmit={this.onSubmit}
            placeholder={'Type Phase Name'}
            onChangeCallback={this.handleChange}
            preFillText={preFillText}
            name={this.state.title}
            isInModal={true}
          />
          {error && <ErrorCopy>{error}</ErrorCopy>}
          <CheckBoxContainer checked={checked}>
            <CheckBox toggle={this.toggleCheckBox} checked={checked} />

            <CheckBoxText>Billing</CheckBoxText>
          </CheckBoxContainer>
        </>
        <ModalButtonsRow>{this.renderButtons()}</ModalButtonsRow>
      </ModalBody>
    );
  }
}

SubcustomerModalBody.propTypes = {};

const mapStateToProps = (state) => ({
  preFillText: getSubcustomerPrefillText(state),
  toBeSynced: getSubcustomerTobeSynced(state),
  isCreatingNewSubSub: getCreatingNewSubSubFlag(state),
  toBeChangedProperty: getTobeChangedSyncedProperty(state),
  alreadyAvailableItems: getSubcustomerAlreadyAvailableItems(state)
});

const mapDispatchToProps = {
  closeSubcustomerModal,
  syncSubcustomersToProjects,
  syncSubSubCustomers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubcustomerModalBody);
