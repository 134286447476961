import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useMemberBudget from 'appUtils/hooks/budget/useMemberBudget';
import { TaskAssigneeCircle } from 'components/TaskAssigneeDropdown/styles';
import theme from 'theme';
import MemberModalRowThreeDotMenu from './MemberModalRowThreeDotMenu';
import { ThreeDotIconContainer } from './Styles';
import useCurrentRates from 'BudgetModule/hooks/useCurrentRates';
import {
  openRemoveMemberModal,
  fetchMemberRates
} from 'BudgetModule/actionCreators';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

import RateSelection from 'BudgetModule/components/BudgetTable/RateSelection';

const UnassignedLine = styled.li`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    ${ThreeDotIconContainer} {
      opacity: 1;
    }
  }
`;

const UnassignedName = styled.span`
  margin-left: 18px;
  color: ${theme.colors.colorMediumGray9};
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: auto;
  &:hover {
    font-weight: bold;
  }
`;

const SubText = styled.div`
  position: absolute;
  top: 33px;
  left: 66px;
  font-size: 11px;
  color: #b3b3b3;
  margin-right: 3px;
`;

const RightLabelText = styled.div`
  float: right;
  margin-right: 23px;
  font-size: 11px;
  color: ${theme.colors.colorMediumGray9};
`;

const UnassignedRoleLine = ({ memberBudget, projectId, listRef }) => {
  const { memberRate } = useCurrentRates({ memberBudget });
  const dispatch = useDispatch();
  const [isRateSelectionModalOpen, setIsRateSelectionModalOpen] =
    useState(false);
  const { name } = useMemberBudget({
    memberBudgetId: memberBudget.id,
    projectId
  });

  const handleDelete = () => {
    dispatch(
      openRemoveMemberModal({
        memberBudget: { ...memberBudget, isUnassigned: true }
      })
    );
  };

  /** ======== For Modify Role Option on menu ======== */

  const handleRateSelectionClick = () => {
    fetchMemberRates({ memberBudgetId: memberBudget.id });
  };

  const handleCloseRatesModal = () => {
    setIsRateSelectionModalOpen(false);
  };

  const handleOpenRateSelectionModal = () => {
    setIsRateSelectionModalOpen(true);
  };

  /** ================================================ */

  return (
    <>
      <UnassignedLine key={memberBudget.id}>
        <MemberModalRowThreeDotMenu
          handleDelete={handleDelete}
          handleModify={handleOpenRateSelectionModal}
          memberBudget={memberBudget}
          isUnassigned
          listRef={listRef}
          projectId={projectId}
        />
        <TaskAssigneeCircle style={{ marginLeft: 0 }} />
        <UnassignedName
          data-for="member-line-tooltip"
          data-tip={name}
          data-testid={`unassign-role-${name}`}
        >
          {name}
        </UnassignedName>
        <SubText>
          {memberRate
            ? `$${formatNumWithMaxTwoDecimals(memberRate.rate)}/hr`
            : 'Unassigned'}
        </SubText>
        <RightLabelText>Unassigned</RightLabelText>
      </UnassignedLine>
      {/* Don't render it conditionally. It needs to see openRateSelectionType change in order to update properly */}
      <RateSelection
        handleClick={() => {
          handleRateSelectionClick();
        }}
        handleClose={() => {
          handleCloseRatesModal();
        }}
        memberBudget={memberBudget}
        memberBudgetId={memberBudget && memberBudget.id}
        key={'unassigned-rate'}
        inTableCell
        inMemberModal
        openRateSelectionType={isRateSelectionModalOpen ? 'rates' : null}
        memberName={name || 'Unassigned Member'}
      />
    </>
  );
};

export default UnassignedRoleLine;
