import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NewTabIcon = ({
  width = '12',
  height = '11',
  color = '#4F4F4F',
  className = ''
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 11"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0835 6.66651V9.58355C10.0835 10.2278 9.56124 10.75 8.91648 10.75H1.91648C1.27226 10.75 0.75 10.2278 0.75 9.58355V2.58355C0.75 1.93878 1.27227 1.41652 1.91648 1.41652H4.83352V2.58355H1.91648V9.58355H8.91648V6.66651H10.0835ZM10.0819 2.24181L5.82713 6.49599L5.00244 5.67076L9.25662 1.41658H6.58192V0.250099H11.2484V4.91658H10.0819L10.0819 2.24181Z"
      fill={color}
    />
  </SvgIcon>
);

export default NewTabIcon;
