import SvgIcon from 'components/SvgIcon';

const NewBudgetPhaseMilestoneIcon = ({
  className,
  color = '',
  fill = 'white',
  fillColor = null, // Old phase milestone icon naming convention
  width = '10',
  height = '11',
  viewBox = '0 0 10 11',
  stroke = '#828282',
  strokeColor = null, // Old phase milestone icon naming convention,
  strokeWidth = '0.791667'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox={viewBox}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28125 5.50107L5.2803 1L1.28125 5.49893L5.2822 10L9.28125 5.50107Z"
      stroke={strokeColor || stroke}
      fill={fillColor || fill}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default NewBudgetPhaseMilestoneIcon;
