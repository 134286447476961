import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import withRouterParams from './WithRouterParams';
import { getMatchedRouteParams, getSplitFlags } from 'selectors';
import LoadIntegrationsContainer from 'IntegrationsModule/components/LoadIntegrationsContainer';
import IntegrationsNavigationTracker from 'views/Navigation/IntegrationsNavigationTracker';

const Integration = lazy(() =>
  import('IntegrationsModule/components/Integration')
);
const SelectIntegrations = lazy(() =>
  import('IntegrationsModule/components/SelectIntegrations')
);
const IntegrationsTab = lazy(() =>
  import('SettingsModule/components/Integrations/IntegrationsTab')
);

class IntegrationsRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match, shouldUseQBV2 } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation,
      shouldUseQBV2: nextShouldUseQBV2
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      match.url === nextLocation.pathname ||
      shouldUseQBV2 !== nextShouldUseQBV2;
    return shouldUpdate;
  }

  render() {
    const { match, shouldUseQBV2 } = this.props;
    return (
      <LoadIntegrationsContainer>
        <IntegrationsNavigationTracker />
        <Suspense fallback={<div />}>
          <Switch>
            {!shouldUseQBV2 && (
              <Route
                exact
                path={`${match.url}/quickbooks`}
                component={IntegrationsTab}
              />
            )}
            {!shouldUseQBV2 && (
              <Route
                exact
                path={`${match.url}/quickbooks-online`}
                component={IntegrationsTab}
              />
            )}
            <Redirect
              exact
              from={`${match.url}/:integrationId`}
              to={`${match.url}/:integrationId/members`}
            />
            <Route
              path={`${match.url}/:integrationId/:integrationTab`}
              component={Integration}
            />
            <Route component={SelectIntegrations} />
          </Switch>
        </Suspense>
      </LoadIntegrationsContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  shouldUseQBV2: getSplitFlags(state).QB_V2_WhiteList
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(IntegrationsRouter))
);
