import React from 'react';
import { connect } from 'react-redux';
import { getUsers } from 'selectors';
import StandardSettingsTabNav from './StandardSettingsTabNav';
import ContentLoader from 'react-content-loader';

const StandardSettingsTab = ({ team, users }) => {
  return (
    <div
      className="team-settings-modal"
      style={{
        flexDirection: 'column',
        display: 'flex',
        overflow: 'visible',
        paddingLeft: 24,
        marginBottom: 20
      }}
    >
      {!users.me || !team ? (
        <ContentLoader
          height="140"
          primaryColor="#ddd"
          secondaryColor="#eee"
          style={{ width: 300, paddingLeft: 9 }}
        >
          <rect x="0" y="5" rx="2" ry="2" width="400" height="50" />
          <rect x="0" y="90" rx="2" ry="2" width="300" height="30" />
        </ContentLoader>
      ) : (
        <div>
          <div
            className="team-settings-modal-header"
            style={{ borderBottom: 'none', paddingLeft: 7 }}
          >
            Organization Standards
          </div>
          <StandardSettingsTabNav />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: getUsers(state),
  team: state.teams.allTeams[0]
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardSettingsTab);
