import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';
import { RowValidator } from 'CsvImportsModule/types';

export const phaseNumberValidator: RowValidator = ({ row, entityType }) => {
  const { enteredPhaseNumber } = getRowValuesForEntityType(row, entityType);

  if (enteredPhaseNumber === TRAINING_DATA_REQUIRED_STRING) {
    return false;
  }
  return true;
};
