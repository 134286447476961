import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Modals } from 'appConstants/navigation';

class MemberModalNavigationTracker extends React.Component {
  render() {
    return <NavigationTracker modal={Modals.MEMBER} />;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberModalNavigationTracker)
);
