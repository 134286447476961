import React from 'react';

import {
  fetchArchivedTeamBoards,
  deleteBoard,
  unarchiveBoard
} from 'actionCreators';
import { isUserTeamAdmin } from 'appUtils';
import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';

import { DeleteArchivedBoardModal, ArchivedBoardRow } from '..';
import { getMe, getAuth, getSelectedTeamId } from 'selectors';
import buildAdminNamesString from 'SettingsModule/utils/buildTeamAdminsString';

class TeamArchivedBoardsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      boardToDelete: null
    };
    bindAll(this, [
      'openDeleteModal',
      'closeDeleteModal',
      'unarchiveBoard',
      'deleteBoard'
    ]);
  }

  componentDidMount() {
    const { teamId, fetchArchivedTeamBoards } = this.props;
    teamId && fetchArchivedTeamBoards(teamId);
  }

  componentDidUpdate(prevProps) {
    const { teamId, fetchArchivedTeamBoards } = this.props;
    if (prevProps.teamId !== teamId) {
      fetchArchivedTeamBoards(teamId);
    }
  }

  openDeleteModal(board) {
    this.setState({
      isDeleteModalOpen: true,
      boardToDelete: board
    });
  }

  closeDeleteModal() {
    this.setState({
      isDeleteModalOpen: false,
      boardToDelete: null
    });
  }

  unarchiveBoard(boardID) {
    this.props.unarchiveBoard(boardID);
  }

  deleteBoard(boardId) {
    const teamId = this.props.teams[0].team_id;
    this.props.deleteBoard(boardId, teamId);
    this.closeDeleteModal();
  }

  render() {
    const { me, team = { team_members: [] } } = this.props;

    const userIsAdmin = isUserTeamAdmin(me.id, team);
    const adminNames = buildAdminNamesString(team);

    return (
      <div className="team-settings-modal">
        <div className="archived-boards-header">Archived Portfolios</div>
        <div className="archived-boards-tab">
          {!userIsAdmin ? (
            <div className="not-admin-message">
              Contact the admin(s), {adminNames} for any required account
              changes. When a team member gets assigned as Admin (from Member
              tab), he/she will be able to see the Portfolio tab.
            </div>
          ) : (
            [
              <DeleteArchivedBoardModal
                key="modal"
                board={this.state.boardToDelete}
                isOpen={this.state.isDeleteModalOpen}
                closeDeleteModal={this.closeDeleteModal}
                deleteBoard={() =>
                  this.deleteBoard(this.state.boardToDelete.id)
                }
              />,
              this.props.archivedBoards.map((board) => {
                return (
                  <ArchivedBoardRow
                    board={board}
                    key={board.id}
                    openDeleteModal={this.openDeleteModal}
                    unarchiveBoard={() => this.unarchiveBoard(board.id)}
                  />
                );
              })
            ]
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  teams: state.users.teams,
  me: getMe(state),
  auth: getAuth(state),
  teamId: getSelectedTeamId(state),
  archivedBoards: state.groups.archivedList,
  team: state.teams.allTeams[0]
});

const mapDispatchToProps = {
  fetchArchivedTeamBoards,
  deleteBoard,
  unarchiveBoard
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  fetchArchivedTeamBoards: (...args) =>
    dispatchProps.fetchArchivedTeamBoards(stateProps.auth.token, ...args)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TeamArchivedBoardsTab);
