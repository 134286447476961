import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type Props = Pick<SvgIconProps, 'className' | 'width' | 'height' | 'fill'>;

export const MatchesIcon = ({
  width = 11,
  height = 5,
  className,
  fill = '#A4A4A4'
}: Props) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 11 5"
    fill="none"
  >
    <path d="M0 0V1H11V0H0Z" fill={fill} />
    <path d="M0 4V5H11V4H0Z" fill={fill} />
  </SvgIcon>
);
