import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getWorkGroupMemberships, getWorkGroups } from 'selectors';
import { useNavToSettings } from 'SettingsModule/hooks';
import {
  DepartmentMembership,
  Department
} from 'SettingsModule/models/department';
import { useLoadDepartments } from './useLoadDepartments';

const emptyObj = {};

export const useDepartments = ({
  isOff,
  shouldAutoLoad
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  /** default is true */
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const membershipsByDepartment = useAppSelector(
    getWorkGroupMemberships
  ) as Record<number, DepartmentMembership[]>;

  const departmentHash = useAppSelector(getWorkGroups) as Record<
    number,
    Department
  >;

  const getDepartmentLabel = useCallback(
    (departmentId: number | string) => {
      return departmentHash[+departmentId]?.name ?? 'No Department';
    },
    [departmentHash]
  );

  const { handleNavToSettings: handleNavToDepartmentsPage } = useNavToSettings({
    viewType: 'members',
    tab: 'departments'
  });

  const { isLoaded, isLoading, loadDepartments } = useLoadDepartments({
    isOff,
    shouldAutoLoad
  });

  return {
    isLoaded,
    isLoading,
    loadDepartments,
    membershipsByDepartment,
    departmentHash,
    handleNavToDepartmentsPage,
    getDepartmentLabel
  };
};
