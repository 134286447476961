import qs from 'qs';
import { callApi } from 'appUtils/api';
import * as EventTypes from 'EventsModule/types';

/* --------------------------------- Google Calendar Events --------------------------------- */

export const fetchGoogleCalendarEvents = (
  teamId: number,
  token: string,
  params: Partial<EventTypes.FetchGoogleCalendarEventsRequestPayload>
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    ...params,
    team_id: teamId
  };
  const url = `calendar_events?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(url, token, fetchInit);
};

export const updateGoogleCalendarEvents = (
  token: string,
  params: Pick<
    EventTypes.UpdateGoogleCalendarEventsRequestPayload,
    'calendar_event_id'
  >,
  body: Partial<EventTypes.UpdateGoogleCalendarEventsRequestPayload>
) => {
  const { calendar_event_id } = params;
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`calendar_events/${calendar_event_id}`, token, fetchInit);
};
