import { useMemo, useRef, useState } from 'react';
import { ColorScalePreview } from 'components/ColorScaleModal/ColorScalePreview';
import {
  COLOR_SCALES_COLORS_DEFAULT,
  COLOR_SCALE_COLORS_DEFAULT,
  COLOR_SCALE_MINIMA_DEFAULT
} from 'components/ColorScaleModal/constants';
import { buildColorScaleIntervals } from 'components/ColorScaleModal/utils';
import { ColorScaleIntervalWithMax } from 'components/ColorScaleModal/types';
import styled from 'styled-components';
import {
  ColorScaleDropdown,
  OnChangeReturn
} from 'components/ColorScaleModal/ColorScaleDropdown';
import { getSelectedTeamViewSettings } from 'selectors';
import { updateTeamViewSettings } from 'actionCreators';
import produce from 'immer';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamId } from 'TeamsModule/selectors';

/**
 * This is used to vertically offset the Edit button the same distance as the
 * percentages above the interval colors.
 */
const Offset = styled.div`
  &::before {
    content: '\\200b';
    display: block;
    font-size: 11px;
    line-height: 19px;
    pointer-events: none;
    cursor: default;
  }
`;

const Button = styled.div`
  color: ${({ theme }) => theme.colors.colorRoyalBlue};
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const ColorScaleSetting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  const dispatch = useAppDispatch();
  const settings = useAppSelector(getSelectedTeamViewSettings);
  const teamId = useAppSelector(getSelectedTeamId);

  const colors =
    settings.work_load?.capacity_heat_map_colors ?? COLOR_SCALE_COLORS_DEFAULT;
  const minima =
    settings.work_load?.capacity_heat_map_intervals ??
    COLOR_SCALE_MINIMA_DEFAULT;
  const colorScale = useMemo(
    () =>
      buildColorScaleIntervals({
        colors,
        minima,
        minimaDefaults: COLOR_SCALE_MINIMA_DEFAULT
      }),
    [colors, minima]
  );

  const handleChange = (values: OnChangeReturn) => {
    setIsOpen(false);
    dispatch(
      updateTeamViewSettings({
        viewSettings: produce(settings, (draft) => {
          const workload = (draft.work_load = draft.work_load || {});
          values.colors && (workload.capacity_heat_map_colors = values.colors);
          values.minima &&
            (workload.capacity_heat_map_intervals = values.minima);
        }),
        teamId
      })
    );
  };

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const getLabel = (interval: ColorScaleIntervalWithMax) =>
    interval.max !== undefined
      ? `${interval.min}–${interval.max}%`
      : `>${interval.min}%`;

  return (
    <Container>
      <ColorScalePreview
        colorScale={colorScale}
        getLabel={getLabel}
        size={50}
        spacing={5}
      />
      <Offset>
        <Button ref={buttonRef} onMouseDown={handleToggle}>
          Edit
        </Button>
        <ColorScaleDropdown
          colors={colors}
          colorScaleTitle="Default Heatmap"
          colorsOptions={COLOR_SCALES_COLORS_DEFAULT}
          minima={minima}
          isOpen={isOpen}
          onCancel={handleCancel}
          onChange={handleChange}
          target={buttonRef?.current}
        />
      </Offset>
    </Container>
  );
};
