import React, { useState } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

/* --------------------------------- Tooltip -------------------------------- */

const TooltipContainer = styled.div`
  padding: 6px 8px;
  background: white;
  border: 1px solid ${theme.colors.colorLightGray21};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;
const TooltipRow = styled.tr`
  font-size: 12px;
  line-height: 1.3;
  color: ${theme.colors.colorMediumGray9};
  td:first-child {
    font-weight: 600;
  }
  td:not(:first-child) {
    padding-left: 7px;
  }
`;

const tooltipFields = ['billable_hours', 'nonbillable_hours', 'totalHours'];
const tooltipFieldToLabel = {
  billable_hours: 'Billable',
  nonbillable_hours: 'Not Billable',
  totalHours: 'Total'
};
const CustomTooltip = ({ payload }) => {
  const renderRow = (field) => (
    <TooltipRow key={field}>
      <td>{formatNumWithMaxOneDecimal(payload[field])}h</td>
      <td>{tooltipFieldToLabel[field]}</td>
    </TooltipRow>
  );
  if (!payload) return null;
  return (
    <TooltipContainer>
      <table style={{ borderSpacing: 4 }}>
        <tbody>{tooltipFields.map(renderRow)}</tbody>
      </table>
    </TooltipContainer>
  );
};

/* ----------------------------------- Dot ---------------------------------- */

const GraphDot = styled.circle`
  stroke: ${theme.colors.colorConnectedGreen};
  fill: ${theme.colors.colorConnectedGreen};
  fill-opacity: 1;
`;
const DotHoverArea = styled.rect`
  cursor: pointer;
`;

const Dot = (props) => {
  return (
    <>
      <GraphDot
        r={3}
        stroke={props.stroke}
        strokeWidth={props.strokeWidth}
        fill={props.fill}
        width={props.width}
        height={props.height}
        cx={props.cx}
        cy={props.cy}
        key={props.key}
      />

      {/* Hover area */}
      <DotHoverArea
        stroke="transparent"
        fill="transparent"
        x={props.cx - 8}
        y={props.cy - 8}
        width="16"
        height="16"
        onMouseOver={() => {
          if (!props.isHoveringDot) props.setIsHoveringDot(true);
        }}
        onMouseOut={() => {
          if (props.isHoveringDot) props.setIsHoveringDot(false);
        }}
      />
    </>
  );
};

/* --------------------------------- Widget --------------------------------- */

const UtilizationAreaChartWidget = ({ data }) => {
  const [isHoveringDot, setIsHoveringDot] = useState(false);

  return (
    <ResponsiveContainer>
      <AreaChart
        width={315}
        height={220}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="start_date"
          tickFormatter={(date) => moment(date).format('MMM')}
          tickLine={false}
        />
        <YAxis
          tickFormatter={(value) => value + '%'}
          tickCount={6}
          tickLine={false}
          type="number"
          domain={[0, 100]}
        />

        <Tooltip
          cursor={false}
          content={(props) => {
            if (isHoveringDot) {
              return <CustomTooltip payload={props.payload[0]?.payload} />;
            }
          }}
          isAnimationActive={false}
          wrapperStyle={{
            visibility: 'visible'
          }}
        />

        <Area
          dataKey="billablePercent"
          stroke={theme.colors.colorConnectedGreen}
          fill="url(#utilization-area-chart-gradient)"
          activeDot={false}
          dot={(props) => {
            return (
              <Dot
                {...props}
                setIsHoveringDot={setIsHoveringDot}
                isHoveringDot={isHoveringDot}
              />
            );
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default UtilizationAreaChartWidget;
