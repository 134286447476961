import { useCallback, useRef } from 'react';
import { DateRangeManager } from 'views/projectPlanner/DateRangeManager';
import { DateRange } from 'views/projectPlanner/DateRangeManager/DateRange';
import { DateStringObject } from 'views/projectPlanner/DateRangeManager/type';

export const useDateRangeManager = () => {
  const dateRangeManagerByIdRef = useRef<{
    [id: number]: DateRangeManager;
  }>({});

  const getOutOfRangeIds = useCallback(
    ({
      ids,
      dateRange
    }: {
      ids: number[];
      dateRange: DateRange | DateStringObject;
    }): number[] => {
      if (!ids.length) return [];

      return ids.reduce<number[]>((outOfRangeIds, id) => {
        const dateRangeManager = dateRangeManagerByIdRef.current[id];

        // initialize new DateRangeManager
        if (!dateRangeManager) {
          dateRangeManagerByIdRef.current[id] = new DateRangeManager(dateRange);
          outOfRangeIds.push(id);
          return outOfRangeIds;
        }

        // if the date range is out of ranges in manager
        // add the date range to the manager
        // and add the id to outOfRangeIds
        if (!dateRangeManager.isWithinRange(dateRange)) {
          dateRangeManager.addRanges(dateRange);
          outOfRangeIds.push(id);
        }
        return outOfRangeIds;
      }, []);
    },
    []
  );

  const resetRangeManager = useCallback(() => {
    dateRangeManagerByIdRef.current = {};
  }, []);

  return {
    getOutOfRangeIds,
    resetRangeManager
  };
};
