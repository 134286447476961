import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import SimpleConfirmModal, {
  Body,
  StyledModal
} from 'components/Modals/SimpleConfirmModal';
import { setNavigationBlocked } from 'actionCreators';

const StyleWrapper = styled(StyledModal)`
  .modal-content {
    width: 505px;
  }
  ${Body} {
    margin-bottom: 25px;
  }
`;

const UnsavedChangesModal = ({
  isOpen,
  toggle,
  onConfirm,
  shouldBlock,
  body = 'You have unsaved changes. Are you sure you want to leave?',
  shouldSetIsClosingOnClose = true
}) => {
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState('');
  const [show, setShow] = useState(false);
  const unblock = useRef(null);
  const dispatch = useDispatch();

  const unblockNavigation = useCallback(() => {
    unblock.current && unblock.current();
    unblock.current = null;
    dispatch(setNavigationBlocked({ isBlocked: false }));
  }, [dispatch]);

  const handleConfirm = () => {
    unblockNavigation();
    if (onConfirm) {
      onConfirm();
    } else {
      history.push(nextLocation);
    }
    setShow(false);
  };

  const hideModal = () => {
    toggle && toggle();
    setShow(false);
  };

  // show browser's confirm page leave prompt (on browser related actions eg. refresh, close tab)
  useEffect(() => {
    if (shouldBlock) {
      window.addEventListener('beforeunload', setBrowserConfirmLeave);
    } else {
      removeBrowserConfirmLeave();
    }
    return () => {
      removeBrowserConfirmLeave();
    };
  }, [shouldBlock]);

  // show our confirm page leave prompt (on navigation actions within the app)
  useEffect(() => {
    if (shouldBlock) {
      unblock.current = history.block((location) => {
        // Save the blocked navigation path and show the modal
        setNextLocation(location.pathname);
        setShow(true);
        // Return false to block the navigation
        return false;
      });
      dispatch(setNavigationBlocked({ isBlocked: true }));
    } else {
      unblockNavigation();
    }
    return unblockNavigation;
  }, [dispatch, history, shouldBlock, unblockNavigation]);

  return (
    <SimpleConfirmModal
      isOpen={isOpen || show}
      toggle={hideModal}
      body={body}
      onConfirm={handleConfirm}
      stylesWrapper={StyleWrapper}
      shouldSetIsClosingOnClose={shouldSetIsClosingOnClose}
    />
  );
};

export default UnsavedChangesModal;

/* ------------------------------------ - ----------------------------------- */

const setBrowserConfirmLeave = (e) => (e.returnValue = '');
const removeBrowserConfirmLeave = () =>
  window.removeEventListener('beforeunload', setBrowserConfirmLeave);
