import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTeamMembersHash } from 'selectors';
import { getWidgetDetailModalId } from 'DashboardModule/selectors';
import { getAccountCapacities } from 'CapacityModule/selectors';
import {
  makeGetMemberTimesheetStatusTotalsByDate,
  makeGetIsFetchingUtilizationReport,
  makeGetMemberUtilizationsOrder
} from 'UtilizationModule/selectors';
import { StyledMiniTimesheetStatusTable, Row } from './styles';
import DateCell from './Cells/DateCell';
import MemberCell from './Cells/MemberCell';
import HeaderCell from './Cells/HeaderCell';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import moment from 'moment';

const pastSunday = moment().startOf('week').isoWeekday(0);

const last4Sundays = [
  pastSunday.clone().add(-3, 'week'),
  pastSunday.clone().add(-2, 'week'),
  pastSunday.clone().add(-1, 'week'),
  pastSunday
];

const EmptyDiv = () => <div></div>;

const memberRowCells = {
  date: DateCell,
  member: MemberCell
};
const columnWidths = {
  date: 52,
  member: 62
};

const MiniTimesheetStatusTable = ({
  data,
  accountOrder,
  teamMembersHash,
  accountCapacities,
  statusView,
  isFetching,
  hasData
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);

  const columns = useMemo(() => {
    const memberColumn = {
      headerType: 'member',
      headerLabel: '',
      id: 'member',
      align: 'center',
      accessor: (row) => row
    };
    const dateColumn = {
      headerType: 'date',
      headerLabel: '',
      id: 'date',
      align: 'center',
      accessor: (row) => row
    };

    const tableColumns = [
      memberColumn,
      ...Array(4)
        .fill(0)
        .map((_, index) => {
          const date = last4Sundays[index];
          return {
            ...dateColumn,
            index,
            headerLabel: date.format('M/D'),
            id: dateColumn.id + index,
            accessor: (row) => row.data[date.format('YYYY-MM-DD')]
          };
        })
    ];

    return tableColumns.map((columnHeader) => ({
      ...columnHeader,
      memberRow: memberRowCells[columnHeader.headerType] || EmptyDiv,
      width: columnWidths[columnHeader.headerType],
      Cell: <div></div>
    }));
  }, []);

  const totalWidth = useMemo(() => {
    return columns.reduce((acc, cur) => {
      acc += cur.width;
      return acc;
    }, 0);
  }, [columns]);

  const rows = useMemo(() => {
    // There's no fetch when account_ids is empty, so orderedMemberUtilizations
    // may have stale data
    return (hasData ? accountOrder : []).map((accountId) => {
      const member = teamMembersHash[accountId];
      return {
        member,
        data: data[accountId],
        rowType: 'memberRow',
        rowHeight: 50,
        accountCapacity: accountCapacities[accountId] || {}
      };
    });
  }, [accountCapacities, accountOrder, data, hasData, teamMembersHash]);

  useEffect(() => {
    if (!isFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFetching]);

  return (
    <StyledMiniTimesheetStatusTable
      // 6px is scroll bar width to preserve horizontal position when more than 4 rows
      marginLeft={rows.length > 4 ? '6px' : 0}
      centerContent={rows.length < 4}
      style={{ width: totalWidth }}
    >
      {!hasInitiallyLoaded || isFetching ? (
        <SkeletonLoader
          numLoaders={4}
          style={{ paddingLeft: 30, margin: 0, marginTop: '10%', height: 200 }}
          loaderStyle={{ height: 70, rx: 4 }}
        />
      ) : (
        <>
          <Row>
            {columns.map((column, index) => {
              return (
                <HeaderCell
                  column={column}
                  key={index}
                  style={{ width: column.width }}
                />
              );
            })}
          </Row>
          {rows.map((row, index) => {
            return (
              <Row key={index} style={{ height: row.rowHeight }}>
                {columns.map((column, index) => {
                  const Cell = column[row.rowType];
                  return (
                    <div style={{ width: column.width }} key={index}>
                      <Cell
                        row={row}
                        column={column}
                        value={column.accessor(row)}
                        style={{ width: column.width }}
                        statusView={statusView}
                      />
                    </div>
                  );
                })}
              </Row>
            );
          })}
        </>
      )}
    </StyledMiniTimesheetStatusTable>
  );
};

const makeMapStateToProps = () => {
  const getMemberTimesheetStatusTotalsByDate =
    makeGetMemberTimesheetStatusTotalsByDate();
  const getIsFetchingUtilizationReport = makeGetIsFetchingUtilizationReport();
  const getMemberUtilizationsOrder = makeGetMemberUtilizationsOrder();
  const mapStateToProps = (state, ownProps) => {
    const isFetching =
      getIsFetchingUtilizationReport(state, ownProps) &&
      !(ownProps.isDashboardWidget && getWidgetDetailModalId(state));
    return {
      accountCapacities: getAccountCapacities(state),
      teamMembersHash: getTeamMembersHash(state),
      data: getMemberTimesheetStatusTotalsByDate(state, ownProps),
      isFetching,
      accountOrder: getMemberUtilizationsOrder(state, ownProps)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MiniTimesheetStatusTable);
