import React from 'react';
import SvgIcon from 'components/SvgIcon';

const LightBulbIcon = ({ width = '14', height = '14', fill = '#4f4f4f' }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.64062 12.1406H5.35938C5.29922 12.1406 5.25 12.1898 5.25 12.25V12.6875C5.25 12.9295 5.44551 13.125 5.6875 13.125H8.3125C8.55449 13.125 8.75 12.9295 8.75 12.6875V12.25C8.75 12.1898 8.70078 12.1406 8.64062 12.1406ZM7 0.875C4.52402 0.875 2.51562 2.8834 2.51562 5.35938C2.51562 7.01914 3.41797 8.46836 4.75781 9.24355V10.8281C4.75781 11.0701 4.95332 11.2656 5.19531 11.2656H8.80469C9.04668 11.2656 9.24219 11.0701 9.24219 10.8281V9.24355C10.582 8.46836 11.4844 7.01914 11.4844 5.35938C11.4844 2.8834 9.47598 0.875 7 0.875ZM8.74863 8.3918L8.25781 8.67617V10.2812H5.74219V8.67617L5.25137 8.3918C4.17539 7.76973 3.5 6.62402 3.5 5.35938C3.5 3.42617 5.0668 1.85938 7 1.85938C8.9332 1.85938 10.5 3.42617 10.5 5.35938C10.5 6.62402 9.82461 7.76973 8.74863 8.3918Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default LightBulbIcon;
