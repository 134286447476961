import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { validateInvite, updatePassword } from 'actionCreators';
import ResetPasswordForm from './ResetPasswordForm';
import logo from 'images/mosaic-logo.png';
import close from 'images/close-to-login.svg';
import appStoreBtn from 'images/app-store-button.svg';
import googlePlayBtn from 'images/google-play-button.svg';
import { getAuth, getMatchedRouteParams } from 'selectors';

class ResetPasswordContainer extends React.Component {
  state = {
    width: window.innerWidth,
    showMobileRedirect: false
  };

  componentDidMount() {
    const { inviteToken } = this.props.matchedParams;

    if (inviteToken) {
      this.props.validateInvite(inviteToken);
    }

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  redirectIfLoggedIn = (props) => {
    const { auth, redirectRoute } = props;
    if (auth.isAuthenticated) {
      window.location.href = redirectRoute;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    const isMobile = this.state.width < 500;
    if (nextProps.invite.passwordIsSet && !isMobile) {
      history.push('/');
    }
  }

  setPassword = (password, firstName, lastName) => {
    const isMobile = this.state.width < 500;

    this.props.updatePassword(
      this.props.auth.token,
      password,
      firstName,
      lastName
    );

    if (isMobile) {
      this.setState({
        showMobileRedirect: true
      });
    }
  };

  redirectMobile = () => {
    window.location.href = 'mosaicmobile://login';
  };

  render() {
    const isMobile = this.state.width < 500;
    const { showMobileRedirect } = this.state;

    if (isMobile && showMobileRedirect) {
      return (
        <div className="mobile-redirect">
          <img className="logo" src={logo} />
          <p className="tagline">Mosaic Manages Teamwork</p>
          <button onClick={this.redirectMobile} className="redirect-button">
            Open in Mosaic app
          </button>
          <div className="store-btns">
            <img className="app-store-btn" src={appStoreBtn} />
            <img className="google-play-btn" src={googlePlayBtn} />
          </div>
          <a className="view-desktop-link">View desktop site</a>
        </div>
      );
    } else {
      return (
        <div className="auth-page">
          {this.state.width > 500 ? (
            <img src={logo} className="login-logo" onClick={this.loginClick} />
          ) : null}
          {this.state.width > 500 ? (
            <img
              src={close}
              className="close-button"
              onClick={this.loginClick}
            />
          ) : null}
          <ResetPasswordForm
            width={this.state.width}
            isInvite={this.props.location.pathname.indexOf('reset') > -1}
            handleSubmit={this.setPassword}
            errorMessage={
              this.props.invite.error
                ? 'Sorry! The activation link you clicked is either invalid or has expired.'
                : null
            }
          />
        </div>
      );
    }
  }
}

const redirectRoute = '/';
const mapStateToProps = (state) => ({
  redirectRoute,
  auth: getAuth(state),
  invite: state.invite,
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = { validateInvite, updatePassword };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)
);
