import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteScope } from 'actionCreators';
import { DeleteScopesModalUi } from './DeleteScopesModalUi';
import { DeleteScopeModalProps } from './types';

const DeleteScopesModal = ({
  id,
  ids,
  handleClose,
  onConfirm,
  additionalDeleteParams
}: DeleteScopeModalProps) => {
  const dispatch = useDispatch();
  const idsToDelete = (ids || [id]) as number[];
  const isMultiple = idsToDelete?.length > 1;

  const handleDeleteConfirm = () => {
    dispatch(deleteScope({ ids: idsToDelete, ...additionalDeleteParams }));
    onConfirm && onConfirm();
  };

  return (
    <DeleteScopesModalUi
      isMultiple={isMultiple}
      handleClose={handleClose}
      handleDeleteConfirm={handleDeleteConfirm}
    />
  );
};

export default DeleteScopesModal;
