import styled from 'styled-components';
import theme from 'theme';
import { StyledSelectToggle } from 'components/BatchActions/styles';
import type {
  FilterListTypeList,
  FilterListsTableCustomRowProps,
  FilterListsTableVariant
} from '../types';
import { TableRow } from 'components/Table/types';
import { makeBatchActionsRow } from '../tableConfigs';
import CollapseAllIcon from 'icons/CollapseAllIcon';

export const BatchActionsCell = ({
  row,
  customRowProps
}: {
  row: {
    original: TableRow<
      ReturnType<typeof makeBatchActionsRow>,
      FilterListTypeList
    >;
  };
  customRowProps: FilterListsTableCustomRowProps;
}) => {
  const {
    parentList: {
      clearSelectedItems,
      selectAllItems,
      isAllSelected,
      numSelectedItems,
      searchText,
      isUnableToSelectMoreItems,
      selectionLimit
    },
    toggleCollapseAll,
    isAllCollapsed
  } = row.original;

  const {
    tableConfig: { variant }
  } = customRowProps;

  return (
    <StyledBatchActionsCell>
      {selectAllItems && !searchText && !isUnableToSelectMoreItems && (
        <StyledSelectAllContainer
          className="select-all-container"
          onClick={selectAllItems}
        >
          <StyledSelectToggle isChecked={!!isAllSelected} size={14} />
          <span>Select All</span>
        </StyledSelectAllContainer>
      )}

      {clearSelectedItems && numSelectedItems > 0 && (
        <StyledClearAllContainer
          onClick={clearSelectedItems}
          data-for="app-tooltip"
          data-tip={
            isUnableToSelectMoreItems
              ? `Max ${numSelectedItems}/${selectionLimit} Selected.`
              : ''
          }
          data-effect="solid"
        >
          <span>
            Clear {numSelectedItems}
            {selectionLimit ? `/${selectionLimit}` : ''}
          </span>
        </StyledClearAllContainer>
      )}

      {toggleCollapseAll && (
        <StyledCollapseAllContainer
          isCollapsed={Boolean(isAllCollapsed)}
          onClick={toggleCollapseAll}
        >
          <CollapseAllIcon fill={theme.colors.colorSemiDarkGray1} />
        </StyledCollapseAllContainer>
      )}
    </StyledBatchActionsCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledCollapseAllContainer = styled.div<{ isCollapsed: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 244px;
  height: 30px;
  width: 30px;
  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }
  ${(props) => props.isCollapsed && 'transform: rotate(-90deg);'}
  transition: 0.275s;
`;

const StyledBatchActionsCell = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
  overflow: hidden; // collapse all icon overflows. this prevents unnecessary scrollbar in parent
`;

const StyledClearAllContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 30px;

  span {
    &:hover {
      filter: brightness(86%);
    }
  }
`;

const StyledSelectAllContainer = styled(StyledClearAllContainer)`
  margin-left: unset;
  span {
    margin-left: 10px;
    font-weight: 600;
  }
`;
