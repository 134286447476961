import {
  TRAINING_DATA_REQUIRED_STRING,
  CURRENCY_CODES_HASH,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const currencyValidator: RowValidator = ({ row, entityType }) => {
  const { enteredCurrency } = getRowValuesForEntityType(row, entityType);
  if (!enteredCurrency) return true;
  else if (enteredCurrency === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (CURRENCY_CODES_HASH[enteredCurrency]) return true;
  else return ERROR_MESSAGES.invalidCurrencyCode;
};
