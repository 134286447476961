import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { getMe } from 'selectors';
import { MemberThreeDotMenuContainer } from 'BudgetModule/components/BudgetTable/MemberCells/styles.js';
import { deleteWorkGroupMembership } from 'actionCreators';
import NewCloseIcon from 'icons/NewCloseIcon';

const StyledNameContainer = styled.div`
  padding-left: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.colorSemiDarkGray1};
  line-height: 1.3;
`;
const MemberInfo = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const TeamMemberCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  cursor: default;
  &:hover {
    ${MemberThreeDotMenuContainer} {
      width: 28px;
      margin-right: 0;
      visibility: visible;
    }
  }
`;

export const DeleteIconContainer = styled.div`
  opacity: 0;
  position: absolute;
  left: -33px;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  circle {
    visibility: hidden;
  }
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
  &:hover {
    path {
      fill: ${theme.colors.colorCalendarRed};
    }
    circle {
      visibility: visible;
      fill: white;
    }
  }
`;

const MemberCell = ({ row = {} }) => {
  const member = row.original?.member;
  const dispatch = useDispatch();
  const me = useSelector(getMe);

  const handleRemoveMember = useCallback(() => {
    dispatch(
      deleteWorkGroupMembership({
        accountId: member?.account?.id,
        workGroupId: row.original?.list?.id
      })
    );
  }, [dispatch, member, row.original]);

  if (!row || !row.original || !row.original.member) return <div />;
  return (
    <TeamMemberCell className="team-member-cell app-cues-team-member-cell">
      <DeleteIconContainer onClick={handleRemoveMember}>
        <NewCloseIcon />
      </DeleteIconContainer>
      <MemberInfo>
        <MemberInitials
          projectMembers={true}
          member={member}
          classes={cn('item-account selected', {
            'small-logged-member': member.account?.id === me.id,
            'small-regular-member': member.account?.id !== me.id
          })}
          idx={'idx-placeholder'}
          isOnTaskModal
        />
        <StyledNameContainer className="app-cues-styled-name-container">
          <StyledName>{member.account?.name}</StyledName>
        </StyledNameContainer>
      </MemberInfo>
    </TeamMemberCell>
  );
};

export default MemberCell;
