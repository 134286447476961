import React from 'react';

const ProjectActivityBody = ({ displayData, project }) => (
  <div className="project-activity-body">
    <div className="project-title">{project.title}</div>
    <div className="board-name">{displayData.name}</div>
  </div>
);

export default ProjectActivityBody;
