import 'isomorphic-fetch';
import { callApi } from 'appUtils/api';
import qs from 'qs';

export const fetchPhaseTotals = (projectId, token, body = {}) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = { ...body };
  if (projectId) {
    queryParams.project_ids = [projectId];
  }

  return callApi(
    `budgets/project_accounts?${qs.stringify(queryParams, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
export const fetchPhaseTotalsByBoard = (boardId, token, projectIds) => {
  // can fetch array of project ids - useful for user defined filtered search
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {};
  if (boardId) {
    queryParams.board_id = boardId;
  }
  if (projectIds) {
    queryParams.project_ids = projectIds;
  }

  return callApi(
    `budgets/project_accounts?${qs.stringify(queryParams, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
export const fetchPhaseTotalsBudgetReport = (_, token, body = {}) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `budgets/project_accounts?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const fetchPhasesByProjectIds = (
  projectIds,
  token,
  { all, limit, offset, search_text, budget_account_id } = {}
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (all) {
    const queryParams = {
      limit,
      offset,
      search_text,
      budget_account_id
    };
    return callApi(
      `project_phases/all?${qs.stringify(queryParams)}`,
      token,
      fetchInit
    );
  } else {
    const queryParams = {
      project_ids: projectIds,
      budget_account_id
    };
    return callApi(
      `project_phases?${qs.stringify(queryParams, {
        arrayFormat: 'brackets'
      })}`,
      token,
      fetchInit
    );
  }
};

export const fetchPhases = (projectId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`project/${projectId}/phases`, token, fetchInit);
};

export const fetchPhaseNames = (team_id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id
  };
  return callApi(
    `team_phase_names?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const fetchFilteredPhases = (
  team_id,
  token,
  {
    all,
    limit,
    offset,
    start_start_date,
    start_end_date,
    end_start_date,
    end_end_date,
    account_ids,
    project_ids,
    is_default,
    import: _import
  } = {}
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id,
    start_start_date,
    start_end_date,
    end_start_date,
    end_end_date,
    is_default,
    limit,
    offset,
    all,
    account_ids,
    project_ids,
    import: _import
  };
  return callApi(
    `phases?${qs.stringify(queryParams, { arrayFormat: 'brackets' })}`,
    token,
    fetchInit
  );
};

export const createPhase = (
  token,
  projectId,
  name,
  isBudget,
  startDate,
  endDate,
  isMain
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      project_id: projectId,
      name,
      is_budget: isBudget,
      start_date: startDate,
      end_date: endDate,
      is_main: isMain
    })
  };
  return callApi(`project/${projectId}/phases`, token, fetchInit);
};

export const predictPhase = (token, projectId, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`project/${projectId}/phase_prediction`, token, fetchInit);
};
export const updatePhase = (
  token,
  {
    project_id,
    id,
    member_budget_orders,
    total,
    name,
    start_date,
    end_date,
    total_work_days,
    complete,
    fee_type,
    activity_order,
    billable,
    baseline_user_activity_id,
    estimated_cost,
    profit_percentage,
    budget_status,
    phase_number,
    dependency_infos,
    custom_fields,
    custom_field_internal_label,
    custom_field_value,
    budget_phase_by,
    estimated_hours,
    is_archived,
    budget_fixed_fee_with,
    budget_hourly_with,
    budget_internal_with
  }
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id,
      member_budget_orders,
      total,
      name,
      start_date,
      end_date,
      total_work_days,
      complete,
      fee_type,
      activity_order,
      billable,
      baseline_user_activity_id,
      estimated_cost,
      profit_percentage,
      budget_status,
      phase_number,
      dependency_infos,
      custom_fields,
      custom_field_internal_label,
      custom_field_value,
      budget_phase_by,
      estimated_hours,
      is_archived,
      budget_fixed_fee_with,
      budget_hourly_with,
      budget_internal_with
    })
  };
  return callApi(`project/${project_id}/phases`, token, fetchInit);
};

export const convertPhaseToDefault = (token, projectId, phase_id) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phase_id
    })
  };
  return callApi(
    `/project/${projectId}/convert_phase_to_default`,
    token,
    fetchInit
  );
};
export const deletePhase = (token, projectId, id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id
    })
  };
  return callApi(`project/${projectId}/phases`, token, fetchInit);
};
export const hardDeletePhase = (token, projectId, id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`project_phases/${id}/hard_destroy`, token, fetchInit);
};

export const archivePhase = (token, projectId, id) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id
    })
  };
  return callApi(`project/${projectId}/archive_phases`, token, fetchInit);
};
export const fetchPhaseTemplates = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`teams/${teamId}/phase_templates`, token, fetchInit);
};

export const createPhaseTemplate = (token, teamId, name) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  };
  return callApi(`teams/${teamId}/phase_templates`, token, fetchInit);
};
export const updatePhaseTemplate = (token, id, name) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  };
  return callApi(`phase_templates/${id}`, token, fetchInit);
};
export const createMilestoneTemplate = (token, teamId, name) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name,
      is_budget: false
    })
  };
  return callApi(`teams/${teamId}/phase_templates`, token, fetchInit);
};

export const reorderMilestoneTemplates = (
  token,
  teamId,
  milestoneTemplateOrder
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      milestone_template_order: milestoneTemplateOrder
    })
  };
  return callApi(`teams/${teamId}/phase_templates/reorder`, token, fetchInit);
};

export const deletePhaseTemplate = (token, id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`phase_templates/${id}`, token, fetchInit);
};

export const reorderPhaseTemplates = (token, teamId, phaseTemplateOrder) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phase_template_order: phaseTemplateOrder
    })
  };
  return callApi(`teams/${teamId}/phase_templates/reorder`, token, fetchInit);
};

// not used
export const deleteMemberFromPhase = (token, memberBudgetId, phaseId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  };
  return callApi(`member_budgets/${memberBudgetId}`, token, fetchInit);
};

export const fetchPhaseMembers = (phaseId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`phase_members/${phaseId}/`, token, fetchInit);
};

export const createPhaseMembers = (
  token,
  phaseId,
  account_ids,
  member_budget_ids
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      account_ids,
      member_budget_ids
    })
  };
  return callApi(`phase_members/${phaseId}`, token, fetchInit);
};

export const deletePhaseMember = (token, phaseMemberId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`phase_membership/${phaseMemberId}`, token, fetchInit);
};

export const updatePhaseMembership = (token, phaseMembershipId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`phase_membership/${phaseMembershipId}`, token, fetchInit);
};

export const archivePhaseMember = (token, phaseMemberId, archive) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      archive
    })
  };
  return callApi(
    `archive_phase_membership/${phaseMemberId}/`,
    token,
    fetchInit
  );
};
