const taskNonModalClasses = [
  'due-date',
  'search-bar-input',
  'ico-drag-task',
  'react-date-picker__nav-bar-date',
  'react-flex-item',
  'react-date-picker__footer-button',
  'react-flex',
  'editing',
  'react-date-picker__month-view-day-text',
  'priority-dot-container',
  'toggle-container',
  'priority-dropdown',
  'priority-dot',
  'dropdown-item',
  'pencil-icon',
  'check-circle-img',
  'check-circle',
  'pill-container',
  'project-menu-button'
];

export default taskNonModalClasses;
