import { createReducer, Draft } from '@reduxjs/toolkit';
import {
  clearEntityRateHistory,
  clearRateEntities,
  clearRateGroups,
  clearRates,
  fetchEntityRateHistoryApiActions,
  fetchRateEntitiesApiActions,
  fetchRateGroupsApiActions,
  fetchRatesApiActions
} from '../ratesActionCreators';
import { EntityId, EntityRate, EntityRateId } from '../models/EntityRate';
import mapKeys from 'lodash/mapKeys';
import { DATE_NULL } from 'appConstants/date';
import {
  EntityRateByEntity,
  TeamRatesEntitiesState,
  TeamRatesEntityRatesState,
  TeamRatesRateGroupsState,
  TeamRatesState
} from './types';
import { Rate, RateId } from 'RatesModule/models/Rate';
import { ReducerName } from 'reduxInfra/shared';
import { RateGroupId } from 'RatesModule/models/RateGroup';
import {
  endOfDay,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  parseISO
} from 'date-fns';

export const initialState: TeamRatesState = {};

/* -------------------------------- reducers -------------------------------- */

const handleFetchRatesSuccess = (
  state: Draft<TeamRatesState>,
  action: ReturnType<typeof fetchRatesApiActions.success>
) => {
  const {
    payload: {
      response: { rates: newRates }
    }
  } = action;

  const { rateHash, rateOrder } = newRates.reduce<{
    rateHash: Record<RateId, Rate>;
    rateOrder: RateId[];
  }>(
    (acc, rate) => {
      const { rateHash, rateOrder } = acc;
      const rateId = rate.id;

      rateHash[rateId] = rate;
      rateOrder.push(rateId);

      return acc;
    },
    { rateHash: {}, rateOrder: [] }
  );

  let rates = state.rates;
  if (!rates) {
    rates = { hash: {}, order: [] };
    state.rates = rates;
  }

  rates.hash = rateHash;
  rates.order = rateOrder;
};

const handleClearRates = (state: Draft<TeamRatesState>) => {
  delete state.rates;
};

const handleFetchRateGroupsSuccess = (
  state: Draft<TeamRatesState>,
  action: ReturnType<typeof fetchRateGroupsApiActions.success>
) => {
  const {
    payload: {
      initialPayload: { active_entity_type },
      response: { records }
    }
  } = action;

  const {
    entityRateHash,
    entityRateByRateGroup,
    rateGroupHash,
    rateGroupOrder
  } = (records ?? []).reduce<{
    entityRateHash: TeamRatesEntityRatesState['hash'];
    entityRateByRateGroup: TeamRatesEntityRatesState['byRateGroup'];
    rateGroupHash: TeamRatesRateGroupsState['hash'];
    rateGroupOrder: RateGroupId[];
  }>(
    (acc, rateGroupData) => {
      const {
        entityRateHash,
        entityRateByRateGroup,
        rateGroupHash,
        rateGroupOrder
      } = acc;

      const { entity_rates, ...rateGroup } = rateGroupData;
      const rateGroupId = rateGroup.id;

      // Add the rate group to the mapping.
      rateGroupHash[rateGroupId] = rateGroup;

      // Add the rate group to the ordering.
      rateGroupOrder.push(rateGroupId);

      // Get the entity rates by entity for the rate group.
      let entityRateByEntity = entityRateByRateGroup[rateGroupId];
      if (!entityRateByEntity) {
        entityRateByEntity = {};
        entityRateByRateGroup[rateGroupId] = entityRateByEntity;
      }

      // For each rate group, add their entity rates.
      entity_rates.reduce<{
        entityRateByEntity: Record<EntityId, EntityRateByEntity>;
        entityRateHash: typeof entityRateHash;
      }>(
        (acc, entityRateData) => {
          const { entityRateByEntity, entityRateHash } = acc;

          const entityRate = mapKeys(entityRateData, (_value, key) =>
            key.replace(/^er_/, '')
          ) as unknown as EntityRate;
          const { entity_id, id, is_cost_rate } = entityRate;

          // Change the start-of-time date to a `null` value.
          if (entityRate.start_date === DATE_NULL) entityRate.start_date = null;

          // Add the entity rate to the mapping.
          entityRateHash[id] = entityRate;

          // Get the entity rates for the entity.
          let entityRateSet = entityRateByEntity[entity_id];
          if (!entityRateSet) {
            entityRateSet = {};
            entityRateByEntity[entity_id] = entityRateSet;
          }

          // Add the entity rate to the entity.
          if (is_cost_rate) entityRateSet.currentCostRate = id;
          else entityRateSet.currentBillRate = id;

          return acc;
        },
        { entityRateByEntity, entityRateHash }
      );

      return acc;
    },
    {
      entityRateHash: {},
      entityRateByRateGroup: {},
      rateGroupHash: {},
      rateGroupOrder: []
    }
  );

  let entityRates = state.entityRates;
  if (!entityRates) {
    entityRates = { byRateGroup: {}, hash: {} };
    state.entityRates = entityRates;
  }

  entityRates.hash = entityRateHash;
  entityRates.byRateGroup = entityRateByRateGroup;

  let rateGroups = state.rateGroups;
  if (!rateGroups) {
    rateGroups = {
      hash: {},
      order: {}
    };
    state.rateGroups = rateGroups;
  }

  rateGroups.hash = rateGroupHash;
  rateGroups.order[active_entity_type] = rateGroupOrder;
};

const handleClearRateGroups = (state: Draft<TeamRatesState>) => {
  delete state.entityRates;
  delete state.rateGroups;
};

const handleFetchEntityRateHistorySuccess = (
  state: Draft<TeamRatesState>,
  action: ReturnType<typeof fetchEntityRateHistoryApiActions.success>
) => {
  const {
    payload: {
      initialPayload: { entity_id, is_cost_rate = false, rate_group_id },
      response: { records }
    }
  } = action;

  let entityRatesHistory = state.entityRatesHistory;
  if (!entityRatesHistory) {
    entityRatesHistory = { byRateGroup: {}, hash: {} };
    state.entityRatesHistory = entityRatesHistory;
  }

  const byRateGroup = entityRatesHistory.byRateGroup;
  let byEntity = byRateGroup[rate_group_id];
  if (!byEntity) {
    byEntity = {};
    byRateGroup[rate_group_id] = byEntity;
  }

  let history = byEntity[entity_id];
  if (!history) {
    history = {};
    byEntity[entity_id] = history;
  }

  const hash = entityRatesHistory.hash;

  const now = new Date();

  const { currentRate, ratesOrder } = (records ?? []).reduce<{
    currentRate?: EntityRate;
    hash: typeof hash;
    ratesOrder: EntityRateId[];
  }>(
    (acc, { entity_rates }) => {
      entity_rates.reduce((acc, entityRate) => {
        const { hash, ratesOrder } = acc;
        const entityRateId = entityRate.id;

        if (
          entityRate.rate_group_id !== rate_group_id ||
          entityRate.entity_id !== entity_id ||
          entityRate.is_cost_rate !== is_cost_rate
        )
          return acc;

        // Change the start-of-time date to a `null` value.
        if (entityRate.start_date === DATE_NULL) entityRate.start_date = null;

        // Store the current rate.
        const startDateToCheck = parseISO(entityRate.start_date ?? '');
        const endDateToCheck = endOfDay(parseISO(entityRate.end_date ?? ''));
        const isStartDateSet = isValid(startDateToCheck);
        const isEndDateSet = isValid(endDateToCheck);
        const isCurrentRate =
          isStartDateSet && isEndDateSet
            ? isWithinInterval(now, {
                start: startDateToCheck,
                end: endDateToCheck
              })
            : isStartDateSet
            ? !isBefore(now, startDateToCheck)
            : isEndDateSet
            ? !isAfter(now, endDateToCheck)
            : false;
        if (!isCurrentRate) acc.currentRate = entityRate;

        hash[entityRateId] = entityRate;
        ratesOrder.push(entityRateId);

        return acc;
      }, acc);

      return acc;
    },
    {
      hash,
      ratesOrder: []
    }
  );

  // Update the current entity rates if they are loaded.
  const entityRates = state.entityRates;
  if (currentRate && entityRates) {
    const currentRateId = currentRate.id;
    entityRates.hash[currentRateId] = currentRate;

    const byRateGroup = entityRates.byRateGroup;

    let byEntity = byRateGroup[rate_group_id];
    if (!byEntity) {
      byEntity = {};
      byRateGroup[rate_group_id] = byEntity;
    }

    let entityRateSet = byEntity[entity_id];
    if (!entityRateSet) {
      entityRateSet = {};
      byEntity[entity_id] = entityRateSet;
    }

    if (is_cost_rate) entityRateSet.currentCostRate = currentRateId;
    else entityRateSet.currentBillRate = currentRateId;
  }

  if (is_cost_rate) history.costRates = ratesOrder;
  else history.billRates = ratesOrder;
};

const handleClearEntityRateHistory = (state: Draft<TeamRatesState>) => {
  delete state.entityRatesHistory;
};

const handleFetchRateEntitiesSuccess = (
  state: Draft<TeamRatesState>,
  action: ReturnType<typeof fetchRateEntitiesApiActions.success>
) => {
  const {
    payload: {
      initialPayload: { active_entity_type },
      response
    }
  } = action;

  const newEntities =
    'activities' in response
      ? Object.values(response.activities)
          .map(({ archived, billable, id, title }) => ({
            archived,
            billable,
            id,
            name: title
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : response.records?.map(({ archived_at, id, name }) => ({
          archived: !!archived_at,
          id,
          name
        })) ?? [];

  const { entityOrder, entityHash } = newEntities.reduce<{
    entityHash: TeamRatesEntitiesState['hash'];
    entityOrder: TeamRatesEntitiesState['order'];
  }>(
    (acc, entity) => {
      const { entityOrder, entityHash } = acc;
      const entityId = entity.id;

      entityOrder.push(entityId);
      entityHash[entityId] = { ...entity, active_entity_type };

      return acc;
    },
    { entityOrder: [], entityHash: {} }
  );

  let entities = state.entities;
  if (!entities) {
    entities = {
      hash: {},
      order: []
    };
    state.entities = entities;
  }

  entities.order = entityOrder;
  entities.hash = entityHash;
};

const handleClearRateEntities = (state: Draft<TeamRatesState>) => {
  delete state.entities;
};

export const ratesReducer = createReducer(initialState, (builder) => {
  // Rates - Fetch
  builder.addCase(fetchRatesApiActions.success, handleFetchRatesSuccess);
  builder.addCase(clearRates, handleClearRates);

  // Rate Groups - Fetch
  builder.addCase(
    fetchRateGroupsApiActions.success,
    handleFetchRateGroupsSuccess
  );
  builder.addCase(clearRateGroups, handleClearRateGroups);

  // Entity Rates - Fetch
  builder.addCase(
    fetchEntityRateHistoryApiActions.success,
    handleFetchEntityRateHistorySuccess
  );
  builder.addCase(clearEntityRateHistory, handleClearEntityRateHistory);

  // Entities - Fetch
  builder.addCase(
    fetchRateEntitiesApiActions.success,
    handleFetchRateEntitiesSuccess
  );
  builder.addCase(clearRateEntities, handleClearRateEntities);
});

export const reducerMap = {
  [ReducerName.TeamRates]: ratesReducer
};
