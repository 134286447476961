import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { WorkloadPlannerGroupRenderer } from 'views';
import { CellWrapper } from './styles';
import {
  updateWorkloadPlannerMembers,
  fetchTeamMemberProfile
} from 'actionCreators';
import {
  getTaskPlannerMembers,
  getNonPlannerMembersArray,
  getPlannerRowHeights,
  getMe,
  getAuthToken
} from 'selectors';
import { AddMemberRowHeight } from '../projectPlanner/WorkloadPlannerGroupRenderer';
const wrapperStyleMaker = (isUnscheduledOpen) => ({
  left: isUnscheduledOpen ? `355px` : `75px`
});
class MembersColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMemberDropdownOpen: false
    };
  }

  openAddMemberDropdown = () => {
    this.setState({ addMemberDropdownOpen: true });
  };

  closeAddMemberDropdown = () => {
    this.setState({ addMemberDropdownOpen: false });
  };

  handleCrossIconClick = (group) => {
    const { updateWorkloadPlannerMembers, plannerMembers } = this.props;
    const newPlannerMemberIds = plannerMembers
      .map((member) => member.account_id)
      .filter((id) => id != group.account_id);
    updateWorkloadPlannerMembers({
      team_planner_members: newPlannerMemberIds
    });
  };

  handleViewProfile = (group) => {
    const { fetchTeamMemberProfile } = this.props;
    fetchTeamMemberProfile({
      teamMemberId: group.id
    });
  };

  getFormattedPlannerMembers = () => {
    // supplies click handling and 'add member,' see WorkloadPlannerTimelineContainer
    const { me, plannerMembers, shouldAddMemberRow } = this.props;
    const { addMemberDropdownOpen } = this.state;

    const memberUtility = {
      handleCrossIconClick: this.handleCrossIconClick,
      handleViewProfile: this.handleViewProfile,
      currentUserId: me ? me.id : null
    };

    const addMemberObj = {
      addMember: true,
      addMemberDropdownOpen,
      openAddMemberDropdown: this.openAddMemberDropdown,
      closeAddMemberDropdown: this.closeAddMemberDropdown,
      className: 'add-member-sidebar'
    };

    const formattedPlannerMembers = plannerMembers.map((member) => ({
      ...member,
      ...memberUtility
    }));

    const rowData = shouldAddMemberRow
      ? [...formattedPlannerMembers, addMemberObj]
      : formattedPlannerMembers;

    return plannerMembers && me ? rowData : [];
  };
  componentDidUpdate() {
    this.columnWrapperRef.scrollTo &&
      this.columnWrapperRef.scrollTo(0, this.props.scrollTop);
  }
  onScroll = () => {
    this.props.scrollTop !== this.columnWrapperRef.scrollTop &&
      this.props.handleScroll(this.columnWrapperRef.scrollTop);
  };

  render() {
    const { isUnscheduledOpen, plannerRowHeights, me } = this.props;
    return (
      <div
        ref={(el) => (this.columnWrapperRef = el)}
        className="team-planner"
        style={wrapperStyleMaker(isUnscheduledOpen)}
        onScroll={this.onScroll}
      >
        {this.getFormattedPlannerMembers().map((member) => (
          <CellWrapper
            key={member.account_id}
            rowHeight={
              plannerRowHeights[member.account_id] || AddMemberRowHeight
            }
          >
            <WorkloadPlannerGroupRenderer
              key={member.account_id}
              group={member}
              currentUserId={me ? me.id : null}
            />
          </CellWrapper>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  me: getMe(state),
  token: getAuthToken(state),
  plannerMembers: getTaskPlannerMembers(state, ownProps),
  shouldAddMemberRow: getNonPlannerMembersArray(state).length,
  plannerRowHeights: getPlannerRowHeights(state)
});

const mapDispatchToProps = {
  updateWorkloadPlannerMembers,
  fetchTeamMemberProfile
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MembersColumn)
);
