import React from 'react';
import { Modal } from 'reactstrap';

interface DeleteModalProps {
  isOpen: boolean;
  component: string;
  toggle: () => void;
  deleteOnClick: () => void;
}

export const DeleteModal = ({
  isOpen,
  toggle,
  deleteOnClick,
  component
}: DeleteModalProps) => (
  <div>
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="delete-modal"
      autoFocus={false}
    >
      <div className="delete-modal-title">
        <h3>Delete {component.charAt(0).toUpperCase() + component.slice(1)}</h3>
        <button type="button" aria-label="Close" onClick={toggle}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="delete-modal-body">
        Are you sure you want to permanently delete this {component}? You can’t
        restore a deleted {component}.
      </div>
      <div className="delete-modal-footer">
        <div className="delete-modal-footer-yes">
          <button onClick={deleteOnClick}>Yes</button>
        </div>
        <div className="delete-modal-footer-cancel">
          <button onClick={toggle}>Cancel</button>
        </div>
      </div>
    </Modal>
  </div>
);

export default DeleteModal;
