import { createSelector, Selector } from 'reselect';
import { EventsState } from 'EventsModule/types';
import { initialState } from 'EventsModule/reducers/events';
import { RootState } from 'reduxInfra/shared';

const getEventsState: Selector<RootState, EventsState> = (state) =>
  state.events || initialState;

export const getGoogleCalendarEvents = createSelector(
  getEventsState,
  (eventsState) => eventsState.googleCalendarEvents
);

export const getGoogleCalendarEventsArray = createSelector(
  getGoogleCalendarEvents,
  (googleCalendarEventsHash) => Object.values(googleCalendarEventsHash)
);
