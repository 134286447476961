import { Field, Label } from 'SettingsModule/components/styles';
import { LanguageFlyout } from './LanguageFlyout';

const languages = [{ label: 'English' }];

export const LanguageSetting = () => {
  return (
    <>
      <Label>Language</Label>
      <Field>
        <LanguageFlyout languages={languages} />
      </Field>
    </>
  );
};
