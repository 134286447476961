import theme from 'theme';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

export const getTooltipContent = (data) => {
  const {
    billable_hours,
    billablePercent,
    nonbillable_hours,
    nonbillablePercent,
    pto_hours,
    ptoPercent = 0, // undefined when the option is off
    holiday_hours,
    holidayPercent = 0, // undefined when the option is off
    remaining_capacity,
    remaining_capacity_percentage,
    // totalHours, totalPercent do not include remaining
    totalHours,
    totalPercent
  } = data;
  const maxWidthForPercent =
    Math.max(
      billablePercent.toString().length,
      nonbillablePercent.toString().length,
      remaining_capacity_percentage.toString().length,
      ptoPercent.toString().length,
      holidayPercent.toString().length,
      totalPercent.toString().length
    ) *
      7.5 +
    10;
  const tooltipContents = {
    data: [
      {
        name: 'billable',
        label: 'BILLABLE',
        value: formatNumWithMaxOneDecimal(billable_hours),
        percentValue: formatNumWithMaxOneDecimal(billablePercent),
        percentWidth: maxWidthForPercent,
        color: theme.colors.colorConnectedGreen,
        border: `1px solid ${theme.colors.colorPaleGray12}`
      },
      {
        name: 'nonbillable',
        label: 'NOT BILLABLE',
        value: formatNumWithMaxOneDecimal(nonbillable_hours),
        percentValue: formatNumWithMaxOneDecimal(nonbillablePercent),
        percentWidth: maxWidthForPercent,
        color: theme.colors.colorLightGray20,
        border: `1px solid ${theme.colors.colorPaleGray12}`
      },
      ...(remaining_capacity >= 0
        ? [
            {
              name: 'remainingCapacity',
              label: 'CAPACITY',
              value: formatNumWithMaxOneDecimal(
                Math.max(remaining_capacity, 0)
              ),
              percentValue: formatNumWithMaxOneDecimal(
                Math.max(remaining_capacity_percentage, 0)
              ),
              percentWidth: maxWidthForPercent,
              color: theme.colors.colorPaleGray5,
              border: `1px solid ${theme.colors.colorPaleGray12}`
            }
          ]
        : []),
      ...(pto_hours > 0
        ? [
            {
              name: 'ptoHours',
              label: 'PTO',
              value: formatNumWithMaxOneDecimal(pto_hours),
              percentValue: formatNumWithMaxOneDecimal(ptoPercent),
              percentWidth: maxWidthForPercent,
              color: theme.gradients.pto,
              border: `1px solid ${theme.colors.colorPaleGray12}`
            }
          ]
        : []),
      ...(holiday_hours > 0
        ? [
            {
              name: 'holidays',
              label: 'HOLIDAYS',
              value: formatNumWithMaxOneDecimal(holiday_hours),
              percentValue: formatNumWithMaxOneDecimal(holidayPercent),
              percentWidth: maxWidthForPercent,
              color: theme.gradients.holiday,
              border: `1px solid ${theme.colors.colorPaleGray12}`
            }
          ]
        : [])
    ],
    summary: [
      {
        name: 'total',
        label: 'Total Hours',
        value: formatNumWithMaxOneDecimal(totalHours),
        percentValue: formatNumWithMaxOneDecimal(totalPercent),
        percentWidth: maxWidthForPercent,
        color: 'tranparent'
      }
    ]
  };
  return tooltipContents;
};
