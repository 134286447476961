import {
  useGenericGroupedListsBuilder,
  GenericGroupedListsBuilderSharedParams,
  FormatList
} from 'components/Table/common/useGenericGroupedListsBuilder';
import { BaseTableList } from 'components/Table/types';
import { useCallback } from 'react';
import { membersCapacityTableHelpers } from './layout';
import { MemberCapacityGroupListItemsBuilder } from '../types';

const { ROW_TYPES } = membersCapacityTableHelpers;

export const useMemberGroupedListsBuilder = ({
  listItemsBuilder,
  getIsOpen,
  toggleCollapse,
  ordersByGroup,
  groupTotalCounts
}: GenericGroupedListsBuilderSharedParams<MemberCapacityGroupListItemsBuilder>) => {
  const formatList: FormatList<BaseTableList> = useCallback(
    ({ partialList }) => {
      const list: BaseTableList = {
        ...partialList,
        rowType: ROW_TYPES.memberHeaderRow,
        rowHeight: 26
      };

      return list;
    },
    []
  );

  const memberGroupedListsBuilder = useGenericGroupedListsBuilder({
    listItemsBuilder,
    getIsOpen,
    toggleCollapse,
    ordersByGroup,
    groupTotalCounts,
    listDescription: 'member',
    formatList
  });

  return memberGroupedListsBuilder;
};
