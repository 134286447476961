import { BucketType, HoursDisplayType } from './types';
import { UtilizationBreakdown } from 'UtilizationModule/types';
import { unit } from 'TimelinesModule/components/constants';

export const getDisplayValuesByHoursType = ({
  hoursDisplayType,
  utilizationBreakdown
}: {
  hoursDisplayType: HoursDisplayType;
  utilizationBreakdown: UtilizationBreakdown;
}): {
  defaultValue: number;
  hourTypeOnHoverText: 'Available' | 'Planned';
  hoverValue: number;
  PTOValue: number;
  unit: string;
} => {
  switch (hoursDisplayType) {
    case HoursDisplayType.TotalHours:
      return {
        defaultValue: utilizationBreakdown.committed,
        hoverValue: utilizationBreakdown.available,
        hourTypeOnHoverText: 'Available',
        PTOValue: utilizationBreakdown.PTO,
        unit: unit.hour
      };

    case HoursDisplayType.AvailableHours:
      return {
        defaultValue: utilizationBreakdown.available,
        hoverValue: utilizationBreakdown.committed,
        hourTypeOnHoverText: 'Planned',
        PTOValue: utilizationBreakdown.PTO,
        unit: unit.hour
      };

    case HoursDisplayType.PercentageOfCapacity: {
      const hourTypeOnHoverText = 'Available';
      if (utilizationBreakdown.capacity === 0) {
        return {
          defaultValue: 0,
          hoverValue: 0,
          hourTypeOnHoverText,
          PTOValue: 0,
          unit: unit.percent
        };
      }
      return {
        defaultValue:
          (utilizationBreakdown.committed / utilizationBreakdown.capacity) *
          100,
        hoverValue:
          (utilizationBreakdown.available / utilizationBreakdown.capacity) *
          100,
        hourTypeOnHoverText,
        PTOValue:
          (utilizationBreakdown.PTO / utilizationBreakdown.capacity) * 100,
        unit: unit.percent
      };
    }
  }
};

export const getFullPTOValuesByHoursType = ({
  hoursDisplayType,
  utilizationBreakdown
}: {
  hoursDisplayType: HoursDisplayType;
  utilizationBreakdown: UtilizationBreakdown;
}): {
  PTOValue: number;
  unit: string;
} => {
  switch (hoursDisplayType) {
    case HoursDisplayType.TotalHours:
      return {
        // committed value include PTO and planned hours
        // PTOValues = PTO + planned
        PTOValue: utilizationBreakdown.committed,
        unit: unit.hour
      };

    case HoursDisplayType.AvailableHours:
      return {
        PTOValue: utilizationBreakdown.committed,
        unit: unit.hour
      };

    case HoursDisplayType.PercentageOfCapacity: {
      if (utilizationBreakdown.capacity === 0) {
        return {
          PTOValue: 0,
          unit: unit.percent
        };
      }
      return {
        PTOValue:
          (utilizationBreakdown.committed / utilizationBreakdown.capacity) *
          100,
        unit: unit.percent
      };
    }
  }
};

export const getDisplayBucketType = ({
  PTO,
  holiday,
  capacity
}: {
  PTO: number;
  holiday: number;
  capacity: number;
}): BucketType => {
  const isFullPTO = PTO > 0 && PTO >= capacity;
  const isHoliday = holiday > 0;

  if (isHoliday) {
    return BucketType.Holiday;
  }

  if (isFullPTO) {
    return BucketType.FullPTO;
  }

  return BucketType.Availability;
};
