import React from 'react';
import cn from 'classnames';
import { Modal, Button } from 'reactstrap';

const HandleTrialExpiryModal = ({
  setModal,
  logoutUser,
  billing,
  extendTrial
}) => {
  const isAdmin = billing.trialExpiryProcess.isAdmin;
  const trialExtended = billing.trialExpiryProcess.trialExtended;

  let p1, p3, primaryText, secondaryText, onPrimaryClick, onSecondaryClick;
  let p2 = "Don't worry, all of team's data is safe.";

  if (isAdmin && trialExtended) {
    p1 = 'Your Extended Trial has expired.';
    p3 = 'This trial has already been extended. Please sign up for Team now.';
    primaryText = 'Sign Up for Team';
    onPrimaryClick = () => setModal('payment-plan-modal', billing.currentModal);
  } else if (isAdmin && !trialExtended) {
    p1 = 'Your 30-day trial has expired.';
    p3 = 'You can sign up for Team now, or extend your trial for 14 more days.';
    primaryText = 'Sign Up for Team';
    secondaryText = 'Extend Trial';
    onPrimaryClick = () => setModal('payment-plan-modal', billing.currentModal);
    onSecondaryClick = () =>
      setModal('extend-trial-modal', billing.currentModal);
  } else if (!isAdmin) {
    p1 = 'Thanks for trying Mosaic Team! Your 30-day trial has expired.';
    p3 =
      'Contact your Admin for upgrading to the team plan to keep using Mosaic.';
    primaryText = 'OK';
    onPrimaryClick = logoutUser;
  }

  const ExpiryModal = () => (
    <div>
      <Modal
        isOpen={billing.currentModal === 'base-expiry-modal'}
        toggle={logoutUser}
        className={cn(null, {
          'expiration-non-admin': !isAdmin,
          'expiration-admin': isAdmin
        })}
      >
        <div className="title">
          <p>{p1}</p>
        </div>
        <div className="notification">
          <p>{p2}</p>
          <p>{p3}</p>
        </div>
        <Button
          color="primary"
          className="primary-btn"
          onClick={onPrimaryClick}
        >
          {primaryText}
        </Button>
        {isAdmin && !trialExtended ? (
          <Button
            color="link"
            className="secondary-btn"
            onClick={onSecondaryClick}
          >
            {secondaryText}
          </Button>
        ) : null}
      </Modal>
    </div>
  );

  const ExtendModal = () => (
    <div>
      <Modal
        isOpen={billing.currentModal === 'extend-trial-modal'}
        toggle={logoutUser}
        className="extend-trial"
      >
        <div className="title">
          <p>Haven't made up your mind yet? No worries!</p>
        </div>
        <div className="notification">
          <p>
            You can continue using Mosaic Team for 14 more days. You can always
          </p>
          <p>upgrade to Mosaic Team from 'Upgrade' button.</p>
        </div>
        <Button
          color="primary"
          className="primary-btn"
          onClick={() => extendTrial()}
        >
          Extend Trial
        </Button>
        <Button
          color="link"
          className="secondary-btn"
          onClick={() => setModal('payment-plan-modal', billing.currentModal)}
        >
          Sign Up for Team Now
        </Button>
      </Modal>
    </div>
  );

  if (billing.currentModal === 'base-expiry-modal') {
    return <ExpiryModal />;
  } else if (billing.currentModal === 'extend-trial-modal') {
    return <ExtendModal />;
  } else {
    return null;
  }
};

export default HandleTrialExpiryModal;
