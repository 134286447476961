import React from 'react';
// key provided by getHeaderGroupProps and getHeaderProps
/* eslint-disable react/jsx-key */
const TableHeader = ({
  headerGroups,
  headersToIgnore = [],
  totalColumnsWidthOverride,
  customRowProps
}) => {
  return (
    <div className="tables-header-sticky">
      {headerGroups.map((headerGroup) => {
        return (
          <div
            className="table-headers-container"
            {...headerGroup.getHeaderGroupProps()}
            {...(totalColumnsWidthOverride && {
              style: { width: `${totalColumnsWidthOverride}px` }
            })}
          >
            {headerGroup.headers.map((column) => {
              if (headersToIgnore.includes(column.id) || !column.Header)
                return null;
              return (
                <div
                  className={`table-column-header ${column.id}`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header', { customRowProps })}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableHeader;
