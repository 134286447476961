import HelpIcon from 'icons/HelpIcon';
import styled from 'styled-components';

export const InlineHelpIcon = styled(HelpIcon).attrs(({ theme }) => ({
  circleColor: theme.colors.colorPaleGray10,
  fillColor: theme.colors.colorPaleGray10,
  height: 8,
  questionMarkColor: theme.colors.colorPureBlack,
  width: 8
}))`
  align-self: text-top;
  margin-left: 2px;
  vertical-align: text-top;
`;
