import {
  makeVerification,
  makeAuthorBasedVerification
} from '../makeVerification';

// View and Edit Budget
export const verifyViewAndEditBudget = makeVerification('manage_budgets');

// View budget by cost rate
export const verifyViewBudgetByCostRate = makeVerification(
  'read_budget_by_cost_rate'
);

// TODO: Add to permission Actions
