import React from 'react';
import WorkloadCapacity from './WorkloadCapacity';

class AccountWorkloadSettings extends React.Component {
  render() {
    const { capacity, handleChange } = this.props;

    return <WorkloadCapacity capacity={capacity} handleChange={handleChange} />;
  }
}

export default AccountWorkloadSettings;
