import { ComponentProps, useCallback, useEffect } from 'react';
import { useLifecycles } from 'react-use';
import { MfaSetupModal } from './MfaSetupModal';

export const useMfaSetupModal = ({
  onMfaVerifySuccess
}: {
  onMfaVerifySuccess: () => void;
}) => {
  const renderModal = useCallback(
    (props: ComponentProps<typeof MfaSetupModal>) => {
      return <MfaSetupModal {...props} />;
    },
    []
  );

  const messageListener = useCallback(
    (event: MessageEvent) => {
      if (event.origin === process.env.MOSAIC_AUTH_DOMAIN) {
        try {
          const eventData = JSON.parse(event.data);

          if (typeof eventData === 'object' && eventData.type) {
            const coercedEventData: {
              type: 'mfaVerifySuccess';
            } = eventData;

            if (coercedEventData.type === 'mfaVerifySuccess') {
              onMfaVerifySuccess();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [onMfaVerifySuccess]
  );

  useLifecycles(
    () => {
      window.addEventListener('message', messageListener);
    },
    () => {
      window.removeEventListener('message', messageListener);
    }
  );

  return {
    MfaSetupModal: renderModal
  };
};
