import React from 'react';
import { useSelector } from 'react-redux';
import { getTeamMembersHash } from 'selectors/team';
import styled from 'styled-components';
import moment from 'appUtils/momentConfig';
import { getDisplayDataRange } from 'appUtils/workplanDisplayUtils';
import { IconContainer } from 'components/GlobalAdd/styles';
import SendIcon from 'images/arrow-send.svg';

const LastSentContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledIconContainer = styled(IconContainer)`
  width: auto;
  margin-right: 4px;
`;

const LastSentTimeStamp = ({
  className = '',
  lastSent = {},
  hideWeekOf = false,
  hideTime = false,
  hideIcon = false
}) => {
  const teamMembers = useSelector(getTeamMembersHash);

  if (!lastSent.date_range || !lastSent.last_sent_time) return null;

  const sentTime = moment(lastSent.last_sent_time).format('h:mma');
  const sentDate = moment(lastSent.last_sent_time).format('M/DD/YY');
  const sender = teamMembers?.[lastSent?.actor_id];

  return (
    <LastSentContainer
      data-for="app-tooltip"
      data-class="center"
      data-tip={`Last Sent ${
        sender?.name ? `by ${sender.name}` : ''
      }<br/> on ${sentDate} at ${sentTime}`}
      data-html
      className={className}
    >
      {!hideIcon && (
        <StyledIconContainer>
          <img src={SendIcon} height="11px" />
        </StyledIconContainer>
      )}
      {!hideWeekOf && lastSent.date_range && (
        <span>
          Wk of{' '}
          {moment(getDisplayDataRange(lastSent.date_range)).format('MMM. Do')}{' '}
          Sent{' '}
        </span>
      )}
      <span>
        {sentDate +
          (!hideTime
            ? `, ${sentTime} ${sender?.initials ? `by ${sender.initials}` : ''}`
            : '')}
      </span>
    </LastSentContainer>
  );
};

export default LastSentTimeStamp;
