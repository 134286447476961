import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import styled from 'styled-components';
import theme from 'theme';

const Label = styled.div`
  font-weight: 600;
  font-size: 36px;
  color: ${theme.colors.colorMediumGray9};
`;
const LabelContainer = styled.div`
  position: absolute;
  padding-bottom: 2px;
`;
const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SimplePieChart = ({
  data,
  innerData,
  label,
  style,
  customTooltip: CustomTooltip
}) => {
  return (
    <ChartContainer style={style}>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <PieChart width={142} height={142}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={61}
          outerRadius={71}
          fill="#8884d8"
          dataKey="value"
          isAnimationActive={false}
          startAngle={90}
          endAngle={450}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {innerData && (
          <Pie
            data={innerData}
            cx="50%"
            cy="50%"
            innerRadius={49}
            outerRadius={57}
            fill="#8884d8"
            dataKey="value"
            isAnimationActive={false}
            startAngle={90}
            endAngle={450}
          >
            {innerData.map((entry, index) => (
              <Cell key={`inner-cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        )}
        {CustomTooltip && <Tooltip content={<CustomTooltip />} />}
      </PieChart>
    </ChartContainer>
  );
};

export default SimplePieChart;
