import React, { ComponentType, PropsWithChildren } from 'react';
import * as appModuleUtils from 'appUtils/appModuleUtils';
import * as boardModuleUtils from 'appUtils/boardModuleUtils';
import * as membersModuleUtils from 'appUtils/membersModuleUtils';
import * as teamSettingsModuleUtils from 'appUtils/teamSettingsModuleUtils';
import * as membersSettingsModuleUtils from 'appUtils/membersSettingsModuleUtils';
import { permissionsModuleConstants } from 'appCore/navigation/permissions/constants';
import * as standardSettingsModuleUtils from 'appUtils/standardSettingsModuleUtils';
import * as reportsModuleUtils from 'appUtils/reportsModuleUtils';
import * as budgetModalModuleUtils from 'appUtils/budgetModalModuleUtils';
import { withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  getSelectedProject,
  getSelectedBoard,
  getActiveProjectModules,
  getActiveBoardModules,
  getActiveMembersModules,
  getIsOnProjectView,
  getIsOnTeamSettingsView
} from 'selectors';
import { RouteComponentProps } from 'react-router';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';

export type PropsFromViewChecker = PropsFromRedux &
  RouteComponentProps &
  PropsWithChildren<{
    viewKey: string;
    moduleType: string;
    ignoreViewKey?: boolean;
  }>;

const withViewChecker = (
  Comp: ComponentType<React.PropsWithChildren<any | string>>
) =>
  class extends React.Component<Omit<PropsFromViewChecker, 'viewKey'>> {
    displayName = `ViewChecker`;

    shouldComponentUpdate(nextProps) {
      const { location } = this.props;
      const { location: nextLocation } = nextProps;
      const shouldUpdate = location.pathname !== nextLocation.pathname;
      return shouldUpdate;
    }

    componentDidMount() {
      const { history } = this.props;
      if (!this.getViewKey()) {
        history.push(this.getRedirectView());
      }
    }

    componentDidUpdate(prevProps) {
      const { history } = this.props;
      if (this.props.moduleType === 'projectDetailView') {
        if (
          !this.getViewKey() ||
          (prevProps.selectedProject && prevProps.selectedProject.id) !==
            (this.props.selectedProject && this.props.selectedProject.id)
        ) {
          const { location } = this.props;
          if (location.pathname !== this.getRedirectView()) {
            history.push(this.getRedirectView());
          }
        }
      } else if (
        this.props.moduleType === 'boardView' ||
        this.props.moduleType === 'teamSettingsView' ||
        this.props.moduleType === 'membersSettingsView' ||
        this.props.moduleType === 'permissionsSettingsView' ||
        this.props.moduleType === 'standardSettingsView' ||
        this.props.moduleType === 'reportsView'
      ) {
        if (!this.getViewKey()) {
          const { location } = this.props;
          if (location.pathname !== this.getRedirectView()) {
            history.push(this.getRedirectView());
          }
        }
      }
    }

    getViewKey = () => {
      const {
        matchedParams,
        activeProjectModules,
        activeBoardModules,
        activeMembersModules,
        activeTeamSettingsModules,
        activeMembersSettingsModules,
        activeStandardSettingsModules,
        activeReportsModules,
        activeBudgetModalModules,
        moduleType,
        activePermissionsSettingsModules
      } = this.props;
      if (moduleType === 'membersView') {
        const keyValue = Object.entries(membersModuleUtils.moduleHash).find(
          ([key, value]) =>
            membersModuleUtils.moduleHash[key] === matchedParams.membersViewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeMembersModules.find((appModule) => appModule === +key) && value
        );
      }
      if (moduleType === 'projectDetailView') {
        const keyValue = Object.entries(appModuleUtils.moduleHash).find(
          ([key, value]) =>
            appModuleUtils.moduleHash[key] === matchedParams.projectViewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeProjectModules.find((appModule) => appModule === +key) && value
        );
      }
      if (moduleType === 'boardView') {
        const keyValue = Object.entries(boardModuleUtils.moduleHash).find(
          ([key, value]) =>
            boardModuleUtils.moduleHash[key] === matchedParams.viewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeBoardModules.find((appModule) => appModule === +key) && value
        );
      }
      if (moduleType === 'membersSettingsView') {
        const keyValue = Object.entries(
          membersSettingsModuleUtils.moduleHash
        ).find(
          ([key, value]) =>
            membersSettingsModuleUtils.moduleHash[key] ===
            matchedParams.settingsViewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;

        return (
          activeMembersSettingsModules.find(
            (appModule) => appModule === +key
          ) && value
        );
      }
      if (moduleType === 'permissionsSettingsView') {
        const keyValue = Object.entries(
          permissionsModuleConstants.moduleHash
        ).find(([key, value]) => value === matchedParams.settingsViewType);

        if (!keyValue) {
          return false;
        }

        const [key, value] = keyValue;

        return activePermissionsSettingsModules.find(
          (appModule) => appModule === +key
        )
          ? value
          : false;
      }
      if (moduleType === 'standardSettingsView') {
        const keyValue = Object.entries(
          standardSettingsModuleUtils.moduleHash
        ).find(
          ([key, value]) =>
            standardSettingsModuleUtils.moduleHash[key] ===
            matchedParams.settingsViewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeStandardSettingsModules.find(
            (appModule) => appModule === +key
          ) && value
        );
      }
      if (moduleType === 'teamSettingsView') {
        const keyValue = Object.entries(
          teamSettingsModuleUtils.moduleHash
        ).find(
          ([key, value]) =>
            teamSettingsModuleUtils.moduleHash[key] === matchedParams.viewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeTeamSettingsModules.find((appModule) => appModule === +key) &&
          value
        );
      }
      if (moduleType === 'reportsView') {
        const keyValue = Object.entries(reportsModuleUtils.moduleHash).find(
          ([key, value]) =>
            reportsModuleUtils.moduleHash[key] === matchedParams.viewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeReportsModules.find((appModule) => appModule === +key) && value
        );
      }
      if (moduleType === 'budgetModalView') {
        const keyValue = Object.entries(budgetModalModuleUtils.moduleHash).find(
          ([key, value]) =>
            budgetModalModuleUtils.moduleHash[key] ===
            matchedParams.budgetViewType
        );
        if (!keyValue) {
          return false;
        }
        const [key, value] = keyValue;
        return (
          activeBudgetModalModules.find((appModule) => appModule === +key) &&
          value
        );
      }
    };

    getRedirectView = () => {
      const {
        matchedParams: { boardId },
        activeTeamSettingsModules,
        activeMembersSettingsModules,
        activePermissionsSettingsModules,
        activeProjectModules,
        activeBoardModules,
        isOnProjectView,
        isOnTeamSettingsView,
        moduleType
      } = this.props;
      let redirectModule;

      if (isOnProjectView) {
        redirectModule =
          appModuleUtils.moduleHash[activeProjectModules[0]] || 'tasks';
      } else if (isOnTeamSettingsView) {
        if (moduleType === 'membersSettingsView') {
          redirectModule =
            membersSettingsModuleUtils.moduleHash[
              activeMembersSettingsModules[0]
            ];
        } else if (moduleType === 'permissionsSettingsView') {
          redirectModule = activePermissionsSettingsModules[0]
            ? permissionsModuleConstants.moduleHash[
                activePermissionsSettingsModules[0]
              ]
            : undefined;
        } else {
          redirectModule =
            teamSettingsModuleUtils.moduleHash[activeTeamSettingsModules[0]];
        }
      } else if (boardId) {
        redirectModule =
          boardModuleUtils.moduleHash[activeBoardModules[0]] || 'status';
      }
      return `${redirectModule}`;
    };

    render() {
      const viewKey = this.getViewKey();
      return viewKey ? (
        <Comp viewKey={viewKey} {...this.props}>
          {this.props.children}
        </Comp>
      ) : null;
    }
  };

const mapStateToProps = (state) => ({
  selectedProject: getSelectedProject(state),
  selectedBoard: getSelectedBoard(state),
  activeProjectModules: getActiveProjectModules(state),
  activeBoardModules: getActiveBoardModules(state),
  activeMembersModules: getActiveMembersModules(state),
  activeMembersSettingsModules: membersSettingsModuleUtils.defaultNavModuleIds,
  activeTeamSettingsModules: teamSettingsModuleUtils.defaultNavModuleIds,
  activeStandardSettingsModules: standardSettingsModuleUtils.defaultModuleIds,
  activeReportsModules: reportsModuleUtils.defaultNavModuleIds,
  activeBudgetModalModules: budgetModalModuleUtils.defaultNavModuleIds,
  activePermissionsSettingsModules: permissionsModuleConstants.moduleIds,
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  isOnTeamSettingsView: getIsOnTeamSettingsView(state)
});
const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedViewChecker = (Comp) =>
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withViewChecker(Comp))
  );

export default ConnectedViewChecker;
