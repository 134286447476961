import styled from 'styled-components';
import theme from 'theme';
import {
  StyledReportHeader,
  StyledSortIconContainer
} from 'ReportsModule/components/styles';
import { Styles } from 'components/Table/styles';
import { DeleteIconContainer } from './MemberRowCells/MemberCell';
import {
  StyledStickyHeader,
  StyledStickyCellContainer
} from 'components/Table/TableStickyHeader';

export const StyledSkillsTable = styled.div`
  flex: 1;
  min-height: 0;

  ${StyledReportHeader} {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    align-items: flex-end;
    padding-bottom: 6px;
    &:hover {
      background: none;
    }
    ${StyledSortIconContainer} {
      position: relative;
      right: -14px;
      top: -3px;
    }
  }

  .variable-size-list {
    height: 100% !important;
  }

  .table,
  .table-body {
    height: 100%;
  }

  .tables-header-sticky {
    border-bottom: 2px solid ${theme.colors.colorLightGray3};
    height: 45px; // match HEADER_FOOTER_HEIGHT
    margin-left: 37px;
    max-width: 863px;
    .table-headers-container {
      height: 100%;
      .table-column-header {
        &.member {
          width: 288px !important;
        }
        &.email {
          width: 575px !important;
        }
      }
    }
  }
  .table-column-header {
    z-index: 1;
  }

  .td {
    height: 100%;
    flex-shrink: 0;
    &.padding {
      flex-shrink: 1;
    }
  }

  .tr:not(.addMemberRow):not(.emptyRow) {
    color: ${theme.colors.colorSemiDarkGray1};
    .time-reports-pencil-icon {
      display: none;
      height: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
    }
    .styled-member-name {
      font-size: 13px;
    }
    &:hover {
      .time-reports-pencil-icon {
        display: inline-flex;
      }
    }
    &:last-child {
      margin-bottom: 30px !important;
    }
    .billable-switch-container {
      display: none;
    }
    .billable-text {
      display: flex;
    }
    .td {
      &:not(:first-child) {
        border-bottom: 1px solid ${theme.colors.colorLightGray12};
      }
      &.headerRow,
      &.headerRowArchived,
      &.separatorRow {
        &:not(:first-child) {
          border-bottom: 2px solid ${theme.colors.colorLightGray3};
        }
      }
    }
  }
  .tr:hover {
    .label-container {
      justify-content: center;
    }
    .billable-switch-container {
      display: flex;
      justify-content: center;
    }
    .billable-text {
      display: none;
    }
  }

  .memberRow {
    &:hover {
      ${DeleteIconContainer} {
        opacity: 1;
      }
    }
  }

  .memberRowArchived,
  .headerRowArchived {
    opacity: 0.8;
  }

  .team-settings-modal .team-members-tab & form {
    padding: 0;
  }

  ${Styles} {
    position: relative;
    padding: 0;
    height: 100%;
  }

  ${StyledStickyCellContainer} {
    background: ${theme.colors.colorTranslucentGray4};
    &:not(:first-child) {
      border-bottom: 2px solid ${theme.colors.colorLightGray3} !important;
    }
  }
  ${StyledStickyHeader} {
    padding-left: 0;
    position: absolute;
    z-index: 2;
  }
`;

export const StyledSeparator = styled.div`
  height: 100%;
  margin-left: 10px;

  font-size: 15px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray6};

  ${(props) =>
    props.isStickyHeader &&
    `
    margin-left: 64px;
    position: absolute;
    top: 39px;
    height: auto;
  `}
  span {
    position: relative;
    top: 29px;
  }
`;
