import { MembersPermissionsTableCells } from 'PermissionsModule/components/settingsViews/members/MembersPermissionsTable/Cells';
import { ComponentProps } from 'react';
import { MemberPermissionTableCellContainer } from '../styles';

const { NameCell } = MembersPermissionsTableCells;

// Wrapper component to style the Member Permission Name Cell with transparent background colour
export const MembersTimesheetSettingsTableNameCell = (
  props: ComponentProps<typeof NameCell>
) => (
  <MemberPermissionTableCellContainer>
    <NameCell {...props} />
  </MemberPermissionTableCellContainer>
);
