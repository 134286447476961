import { ArchiveIcon } from 'icons/ArchiveIcon';
import FilledArrowV2 from 'icons/FilledArrowV2';
import styled, { useTheme } from 'styled-components';
import { RateArchiveCellProps, TableCellProps } from '../types';
import { isRateArchiveCell } from '../utils';

export const RateArchiveCell = (props: TableCellProps) =>
  isRateArchiveCell(props) ? (
    <RateArchiveCellWithArchiveProps {...props} />
  ) : null;

const RateArchiveCellWithArchiveProps = (props: RateArchiveCellProps) => {
  const {
    row: {
      original: {
        isArchivedVisible,
        numArchivedRates,
        rateGroupId,
        toggleArchived
      }
    }
  } = props;

  const theme = useTheme();

  const handleClick = () => toggleArchived(rateGroupId);

  return (
    <Cell onClick={handleClick}>
      <ArchiveIcon height={12} width={14} />
      <Label>{numArchivedRates} Archived</Label>
      <FilledArrowV2
        transform={isArchivedVisible ? 'rotate(180)' : ''}
        color={theme.colors.colorMediumGray9}
      />
    </Cell>
  );
};

const Cell = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  cursor: pointer;
  display: flex;
  height: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.colorBudgetBlue};

    path {
      fill: ${({ theme }) => theme.colors.colorBudgetBlue};
    }
  }
`;

const Label = styled.div`
  padding: 0 0.5em 0 9px;
`;
