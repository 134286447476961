export const ancestorsContainClass = (domNode, classes) => {
  if (!domNode.parentNode) return false;
  const classInParents = classes.some((classString) =>
    domNode?.parentNode?.classList?.contains(classString)
  );
  return classInParents || ancestorsContainClass(domNode.parentNode, classes);
};

// this is used in places we are making the assumption that any click into a popover should not be trigger handleClickOutside on other existing popovers...

const makeIsInAnyAncestor = (className) => (e) => {
  const allClassNameMatches = Array.from(document.querySelectorAll(className));
  const isInAnyMatch = allClassNameMatches.some((match) =>
    match.contains(e.target)
  );
  const hasnAnAncestorMatch = ancestorsContainClass(e.target, [className]);
  return isInAnyMatch || hasnAnAncestorMatch;
};

export const isInAnyPopover = makeIsInAnyAncestor('.popover');
export const isInAnyModal = makeIsInAnyAncestor('.modal');
export const isInTimePicker = makeIsInAnyAncestor('.calendar-time-picker');
