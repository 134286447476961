import { useState, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import DailyCheckinItem from './DailyCheckinItem';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import {
  fetchCheckinsHours,
  fetchPhasesByProjectIds,
  fetchCheckins,
  createCheckin,
  fetchAllProjects,
  setSelectedWorkloadEvent,
  fetchAccess,
  createWorkloadPlannerEvent,
  bulkCreateCheckin
} from 'actionCreators';
import {
  getCheckinsHours,
  getCheckins,
  getMe,
  getPlannerDateHeader,
  getWorkloadEventsSelectedEvent,
  getWorkloadEventsSelectedEventId,
  makeSuggestedProjectHash,
  makeSuggestedPhaseHash,
  makeSuggestedActivityHash,
  getAllCheckins,
  getMergedTimers,
  makeGetCheckinsByFilter,
  makeGetCheckinsHoursByFilter,
  makeGetAllCheckinsByFilter,
  getTimerDate
} from 'selectors';
import moment from 'moment';
import { buildAccessIdentifier } from 'appUtils/access';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import ConfirmEventModal from 'views/projectPlanner/WorkloadEventsModal/ConfirmEventModal';
import { ProjectCommentModal } from 'views';
import { calculateCheckInTotalHours } from 'appUtils/checkInUtils';
import TimerProject from 'views/layoutStructuralComponents/TimerSidebarContainer/TimerProject';

export const DailyCheckinContainer = styled.div`
  width: ${({ containerWidth }) => containerWidth}px;
  text-align: left;
  margin: 8px auto;
  min-height: 205px;
  margin-bottom: 16px;
  position: relative;
`;

const SendButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.colorCalendarBlue};
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 4px;
  &:hover {
    color: white;
    background: ${theme.colors.colorCalendarBlue};
  }
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  width: 64px;
  height: 23px;
  position: absolute;
  right: 0px;
  top: -27px;
`;

const Divider = styled.div`
  height: 1px;
  background: #dddddd;
  margin-top: 1px;
  margin-bottom: 15px;
`;

const DropdownTarget = styled.div`
  height: 1px;
  margin-top: -16px;
  margin-left: -25px;
`;

const StyledInputTrigger = styled.div`
  height: 24px;
  color: ${theme.colors.colorRoyalBlue};
  font-weight: 400;
  font-size: 15px;
  margin-right: 80px;
  margin-left: 3px;
`;

const InputTriggerContainer = styled.div`
  display: flex;
  margin-top: 5px;
  height: 50px;
  align-items: center;
  margin-bottom: -15px;
  cursor: pointer;
  &:hover {
    ${StyledInputTrigger} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const StyledList = styled.div`
  width: 100%;
  margin-top: 20px;
  max-height: ${({ isTimerSidebar }) => !isTimerSidebar && '65vh'};
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const TotalRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 30px 0 50px;
`;

const WorkedHours = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  text-align: right;
`;

const HoursDivider = styled.div`
  color: ${theme.colors.colorLightGray15};
`;

const TotalHours = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 400;
`;

const HoursContainer = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-right: 50px;
`;

const SendArrowTooltipContent = `<div>
  <div>Send Check-in</div>
  <div>to Teammates</div>
</div>`;

const DailyCheckin = ({
  footerButtonClassName = '',
  showInLineWorkCategory,
  isTimerSidebar = false,
  hideHeader = false,
  hideFooter = false,
  containerWidth = 566,
  restartCounter = undefined,
  counter = undefined,
  accountId,
  // pass in if you want to use a different date than plannerDateHeader
  customPlannerDate
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const dispatch = useDispatch();
  const me = useSelector(getMe);
  const getCheckins = useMemo(makeGetCheckinsByFilter, []);
  const plannerDateHeader = useSelector(getPlannerDateHeader);
  // use customPlannerDate if exists, otherwise use plannerDateHeader
  const date = customPlannerDate || plannerDateHeader;
  const unformattedTimerDate = useSelector(getTimerDate);
  const timerDate = moment(unformattedTimerDate);
  const filterStateId = isTimerSidebar
    ? timerDate.format('YYYY-MM-DD')
    : accountId !== undefined
    ? `${accountId}-${date.format('YYYY-MM-DD')}`
    : date.format('YYYY-MM-DD');
  const checkins = useSelector((state) =>
    getCheckins(state, { filterStateId })
  );

  const getCheckinsHours = useMemo(makeGetCheckinsHoursByFilter, []);
  const checkinsHours = useSelector((state) =>
    getCheckinsHours(state, { filterStateId })
  );

  const getAllCheckinsByFilter = useMemo(makeGetAllCheckinsByFilter, []);
  const allCheckins = useSelector((state) =>
    getAllCheckinsByFilter(state, {
      filterStateId,
      isTimerSidebar: isTimerSidebar
    })
  );

  const [sendModalIsOpen, setSendModalIsOpen] = useState(false);
  const teamId = useMemo(() => me?.default_team?.id, [me]);
  const selectedEventId = useSelector(getWorkloadEventsSelectedEventId);
  const selectedEvent = useSelector(getWorkloadEventsSelectedEvent);
  const suggestedProjectHash = useSelector(makeSuggestedProjectHash);
  const suggestedPhaseHash = useSelector(makeSuggestedPhaseHash);
  const suggestedActivityHash = useSelector(makeSuggestedActivityHash);

  const mergedTimers = useSelector(getMergedTimers);

  const isUserActionsDisabled = accountId !== undefined && accountId !== me?.id;

  const accountIdForFetch = accountId ?? me?.id;

  useEffect(() => {
    if (me?.default_team?.id && !isTimerSidebar) {
      dispatch(
        fetchCheckins({
          body: {
            date: date.format('YYYY-MM-DD'),
            account_ids: [accountIdForFetch]
          },
          filterStateId
        })
      );
      const body = {
        account_ids: [accountIdForFetch],
        offset: 0,
        limit: 50,
        team_id: me?.default_team?.id,
        start_date: date.format('MM/DD/YYYY'),
        end_date: date.format('MM/DD/YYYY')
      };
      dispatch(
        fetchCheckinsHours({
          body: body,
          initial: true,
          filterStateId
        })
      );
    }
  }, [dispatch, me, isTimerSidebar, filterStateId, accountIdForFetch, date]);

  useEffect(() => {
    dispatch(
      fetchAllProjects({
        projectIds: checkins.map((checkin) => checkin.project_id)
      })
    );
  }, [checkins, dispatch]);

  useEffect(() => {
    dispatch(
      fetchPhasesByProjectIds({
        all: true,
        budgetAccountId: accountIdForFetch
      })
    );
  }, [dispatch, accountIdForFetch]);

  const totalHoursArray = useMemo(
    () =>
      allCheckins.map((checkin) =>
        calculateCheckInTotalHours(checkin, checkinsHours, accountIdForFetch)
      ),
    [allCheckins, checkinsHours, accountIdForFetch]
  );

  const targetRef = useRef(null);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleCloseProjectDropdown = () => {
    setInputVisible(false);
  };

  const handleSelect = ({ item }) => {
    let newCheckin;
    if (item.is_activity) {
      newCheckin = {
        activity_id: item.id,
        phase_id: item.phase_id,
        project_id: item.project.id
      };
    } else if (item.is_phase) {
      newCheckin = {
        activity_id: null,
        phase_id: item.id,
        project_id: item.project.id
      };
    } else {
      newCheckin = {
        activity_id: null,
        phase_id: item.phases.length > 0 ? item.phases[0] : null,
        project_id: item.id
      };
    }
    handleCreateCheckin(newCheckin);
    handleCloseProjectDropdown();
  };

  const handleCreateCheckin = (checkinParams) => {
    dispatch(
      createCheckin({
        title: '',
        estimated_hours: 0,
        date: date.format('YYYY-MM-DD'),
        account_id: me?.id,
        ...checkinParams,
        filterStateId
      })
    );
  };

  const workedHoursValue = useMemo(
    () =>
      checkins.reduce(
        (total, checkin) =>
          (checkin.submittal_status !== 'deleted'
            ? Number(checkin.estimated_hours)
            : 0) + total,
        0
      ),
    [checkins]
  );

  const totalHoursValue = useMemo(() => {
    return totalHoursArray.reduce(
      (total, itemTotalHours) => itemTotalHours + total,
      0
    );
  }, [totalHoursArray]);

  const createEvent = () => {
    dispatch(
      createWorkloadPlannerEvent({
        event_type: 'checkin',
        send_time: moment().add(10, 'minutes').format('YYYY-MM-DD'),
        recipient_account_ids: selectedEvent?.metadata?.follower_ids,
        follower_ids: [],
        date_range: date.format('MM/DD/YYYY'),
        team_id: teamId,
        sendNow: true
      })
    );
    dispatch(
      bulkCreateCheckin({
        account_id: me?.id,
        date: moment().format('YYYY-MM-DD'),
        all: true
      })
    );
  };

  const setSelectedEvent = (eventId) => {
    dispatch(setSelectedWorkloadEvent({ eventId }));
  };

  const sendEvent = (eventId) => {
    if (eventId === 'new') {
      createEvent();
    }
    setSelectedEvent(null);
  };

  useEffect(() => {
    if (teamId && !isTimerSidebar) {
      dispatch(
        fetchAccess({
          actableId: teamId,
          actableType: 'Team',
          actionType: 'activity_phase_schedule_bars_other'
        })
      );
    }
    dispatch(setSelectedWorkloadEvent({ eventId: 'new' }));
  }, [dispatch, teamId, isTimerSidebar]);

  const onEventConfirm = () => {
    setSendModalIsOpen(false);
    sendEvent(selectedEventId);
    setSelectedEvent('new');
  };

  const onEventCancel = () => {
    setSendModalIsOpen(false);
  };

  const onSendModalOpen = () => {
    setSendModalIsOpen(true);
  };

  const accessIdentifier = !isTimerSidebar
    ? buildAccessIdentifier({
        actableType: 'Team',
        actableId: teamId,
        actionType: 'activity_phase_schedule_bars_other'
      })
    : null;

  return (
    <DailyCheckinContainer containerWidth={containerWidth}>
      {!hideHeader && (
        <>
          {!isUserActionsDisabled && (
            <SendButton
              onClick={onSendModalOpen}
              data-for={'app-tooltip'}
              data-tip={SendArrowTooltipContent}
              data-html
            >
              <span>Send</span>
            </SendButton>
          )}
          <Divider />
          <DropdownTarget ref={targetRef} />
          <ProjectsThenPhasesSelector
            target={targetRef}
            onClose={handleCloseProjectDropdown}
            handleSelect={handleSelect}
            renderSelector={false}
            accountId={me.id}
            suggestedProjectHash={suggestedProjectHash}
            suggestedPhaseHash={suggestedPhaseHash}
            suggestedActivityHash={suggestedActivityHash}
            isWide
            isOpen={inputVisible}
            hidePTO
          />
          {!isUserActionsDisabled && (
            <InputTriggerContainer onClick={showInput}>
              {/* <AddPlusIcon fill="#828282" /> */}
              <StyledInputTrigger>+ Project</StyledInputTrigger>
            </InputTriggerContainer>
          )}
        </>
      )}
      <StyledList isTimerSidebar={isTimerSidebar}>
        {allCheckins.map((item, index) =>
          isTimerSidebar ? (
            <TimerProject
              checkin={item}
              showInLineWorkCategory={showInLineWorkCategory}
              totalHours={totalHoursArray[index]}
              key={item.id}
              handleCreateCheckin={handleCreateCheckin}
              showButtonTopRight
              footerButtonClassName={footerButtonClassName}
              timers={mergedTimers[item.id] || {}}
              restartCounter={restartCounter}
              counter={counter}
              filterStateId={filterStateId}
            />
          ) : (
            <DailyCheckinItem
              checkin={item}
              showInLineWorkCategory={showInLineWorkCategory}
              totalHours={totalHoursArray[index]}
              key={item.id}
              handleCreateCheckin={handleCreateCheckin}
              showButtonTopRight
              footerButtonClassName={footerButtonClassName}
              filterStateId={filterStateId}
              isNotification={isUserActionsDisabled}
            />
          )
        )}
      </StyledList>
      {(allCheckins?.length ?? 0) > 0 && !hideFooter && (
        <>
          <TotalRow>
            <div>Total</div>
            <HoursContainer>
              <WorkedHours data-testid="total-checkin-hour">
                {formatNumWithMaxTwoDecimals(workedHoursValue)}h
              </WorkedHours>
              <HoursDivider>|</HoursDivider>
              <TotalHours
                data-for={'app-tooltip'}
                data-html
                data-effect="solid"
                data-tip={'Work Plan Hours'}
                data-testid="total-workplan-hour"
              >
                {' '}
                {totalHoursValue}h
              </TotalHours>
            </HoursContainer>
          </TotalRow>
        </>
      )}
      {!isTimerSidebar && (
        <ConfirmEventModal
          selectedEvent={selectedEvent}
          isOpen={sendModalIsOpen}
          onConfirm={onEventConfirm}
          onCancel={onEventCancel}
          recipientIds={selectedEvent?.metadata?.follower_ids ?? []}
          followerIds={selectedEvent?.metadata?.follower_ids ?? []}
          accessIdentifier={accessIdentifier}
          start={moment()}
          end={moment().clone().endOf('week')}
          isSendCheckin
          modalTitle="Send Check-in"
        />
      )}
      <ProjectCommentModal />
    </DailyCheckinContainer>
  );
};

export default DailyCheckin;
