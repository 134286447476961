import theme from 'theme';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
`;

export const StyledMiniTimesheetStatusTable = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft || 0};

  ${({ centerContent }) =>
    centerContent &&
    `
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      top: -18px;
  `}

  .recharts-sector[fill='white'] {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorLightGray1};
  }
`;
