import { lazy, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';

import { getIsSplitLoaded, getUserSlug } from 'selectors';
import { navigateToHome } from 'actionCreators';

import DevProps from 'components/dev/DevProps';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const entityType = 'Position';

const CheckInsReportContainer = lazy(() => import('./CheckInsReportContainer'));

const CheckInsReportTab = () => {
  const { checkInsReportFlag } = useFeatureFlags();
  const isSplitLoaded = useSelector(getIsSplitLoaded);
  const userSlug = useSelector(getUserSlug);
  const { filterId, viewBy, pageName, activeFilter, filterSections } =
    useReportsViewFilter();
  const dispatch = useDispatch();

  // redirect user if feature flag is off
  useEffect(() => {
    if (!checkInsReportFlag && isSplitLoaded) {
      dispatch(navigateToHome({ userSlug }));
    }
  }, [dispatch, isSplitLoaded, userSlug, checkInsReportFlag]);

  if (!checkInsReportFlag) return null;

  return (
    <DynamicModuleLoader>
      <DevProps
        data={{
          filterId,
          viewBy,
          pageName,
          activeFilter,
          filterSections,
          checkInsReportFlag
        }}
      />
      <Suspense fallback={<div />}>
        <CheckInsReportContainer
          activeFilter={activeFilter}
          filterStateId="check-in-report"
          viewBy={viewBy}
          filterSections={filterSections}
          entityType={entityType}
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};

export default CheckInsReportTab;
