import {
  SelectDropdownProps,
  SelectInput,
  SelectInputVariant
} from 'RatesModule/components/SelectInput/SelectInput';
import { BudgetTrackingType } from 'BudgetModule/models/BudgetViewSettings';
import { useTeamBudgetViewSettings } from 'BudgetModule/hooks/useTeamBudgetViewSettings';
import {
  BudgetTrackingDropdown,
  BUDGET_TRACKING_COPY
} from 'BudgetModule/components/BudgetModal/BudgetSettingsModal/Fields/BudgetTrackingDropdown';

export const BudgetTrackingField = () => {
  const {
    budgetViewSettings: { budget_tracking_type: budgetTracking },
    updateBudgetViewSettings
  } = useTeamBudgetViewSettings();

  const handleChange = (value: BudgetTrackingType) =>
    updateBudgetViewSettings({ budget_tracking_type: value });

  const dropdownRenderer = (props: SelectDropdownProps<BudgetTrackingType>) => (
    <BudgetTrackingDropdown value={budgetTracking} {...props} />
  );

  return (
    <SelectInput
      onSelect={handleChange}
      renderDropdown={dropdownRenderer}
      variant={SelectInputVariant.White}
    >
      {BUDGET_TRACKING_COPY[budgetTracking].label}
    </SelectInput>
  );
};
