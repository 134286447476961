import React from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  closeAndClearMemberModal,
  flushSelectedHomeTask,
  setEditTaskId,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  fetchSingleTask,
  fetchAllProjects,
  fetchProjectById
} from 'actionCreators';
import {
  makeGetOwnTaskInfo,
  getSelectedProject,
  makeGetTeamMembershipByAccountId
} from 'selectors';
import MemberModalSideBar from './MemberModalSideBar';
import MemberModalHeader from './MemberModalHeader';
import MemberModalBody from './MemberModalBody';

const coloredBackgroundViews = new Set([
  'activity',
  'schedule',
  'calendar',
  'profile'
]);

class MemberModal extends React.Component {
  componentDidMount() {}

  componentWillUnmount() {}

  toggle = (event) => {
    const { closeAndClearMemberModal } = this.props;
    // ignore calendar clicks
    if (
      (event &&
        event.target.className &&
        event.target.className.includes &&
        event.target.className.includes('rc-calendar-date')) ||
      (event &&
        event.target.offsetParent &&
        event.target.offsetParent.className &&
        event.target.offsetParent.className.includes &&
        (event.target.offsetParent.className.includes('modal-content') ||
          event.target.offsetParent.className.includes('rc-trigger-popup')))
    ) {
      return;
    }
    closeAndClearMemberModal({
      ignoreQueryParams: true
    });
  };

  render() {
    const {
      isOpen,
      closeAndClearMemberModal,
      teamMembershipByAccountId,
      memberViewType,
      me,
      accountId
    } = this.props;
    return (
      isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className={`member-modal ${
            coloredBackgroundViews.has(memberViewType)
              ? 'background-color-member-modal'
              : ''
          }`}
        >
          <MemberModalSideBar
            activeTab={memberViewType}
            memberInfo={teamMembershipByAccountId}
            me={me}
          />
          <MemberModalHeader
            handleClose={() =>
              closeAndClearMemberModal({ ignoreQueryParams: true })
            }
          />
          {/* should wait for teamMembership to get permission, otherwise it is redirected to default tab */}
          {!!teamMembershipByAccountId && (
            <MemberModalBody activeTab={memberViewType} accountId={accountId} />
          )}
        </Modal>
      )
    );
  }
}

const makeMapStateToProps = () => {
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();
  const getTask = makeGetOwnTaskInfo();
  const mapStateToProps = (state, ownProps) => ({
    teamMembershipByAccountId: getTeamMembershipByAccountId(state, ownProps),
    me: state.users.me,
    task: getTask(state, ownProps),
    selectedProject: getSelectedProject(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  setEditTaskId,
  fetchCommentsAndMetadata,
  fetchSingleTask,
  closeAndClearMemberModal,
  flushSelectedHomeTask,
  fetchTaskGroups,
  fetchAllProjects,
  fetchProjectById
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(MemberModal)
);
