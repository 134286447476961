import { VIEW_BY } from 'appConstants/workload';
import { AccountId } from 'models/account';
import { Task, TaskId } from 'models/task';
import { TaskGroupId } from 'models/taskGroup';
import { UUID } from 'models/uuid';
import { ProjectId } from 'ProjectsModule/models/project';
import { ValueOf } from 'type-fest';
import * as taskRemoveTypes from 'appConstants/taskRemoveTypes';
import { TeamId } from 'TeamsModule/models/team';
import { TagId } from 'models/tag';
import { ProjectMembership } from 'ProjectsModule/models/projectMembership';
import { SortDirection } from 'FilterModule/constants';
import { WorkPlanId } from 'views/projectPlanner/WorkplanModal/models/workPlan';

export enum TaskSortFields {
  ActivityPhase = 'activity_phase',
  Assigned = 'assigned',
  CompletedAt = 'completed_at',
  Description = 'description',
  DueAt = 'due_at',
  EstimatedHours = 'estimated_hours',
  PhaseId = 'phase_id',
  Assignee = 'primary_assignee',
  Priority = 'priority',
  ScheduleStart = 'schedule_start',
  Selection = 'selection',
  Status = 'status',
  TaskGroupId = 'task_group_id',
  Timer = 'timer'
}

export interface ActiveTaskFilter {
  assignee_ids: AccountId[];
  complete?: boolean;
  scheduled_start?: boolean;
  search?: string;
  sort_attributes: {
    attribute: TaskSortFields;
    direction: SortDirection;
  }[];
  tag_ids?: TagId[];
  team_id?: TeamId;
}

export enum TaskFilterScope {
  Accepted = 'accepted',
  AssignedByMe = 'assigned_by_me',
  Default = 'default',
  Profile = 'profile',
  Project = 'project',
  Today = 'today'
}

export enum TaskFilterCompletionState {
  Completed = 'completed',
  Default = 'default',
  Incomplete = 'incomplete'
}

export enum TaskFilterSection {
  MyTasks = 'My Tasks',
  AssignedByMe = 'Assigned By Me'
}

export enum TaskFilterSubSection {
  Inbox = 'inbox',
  CheckIns = 'checkins',
  Scheduled = 'scheduled',
  Notifications = 'notifications',
  Completed = 'completed',
  Time = 'time',
  Assigned = 'assigned'
}

export enum TaskFilterView {
  Default = '',
  Day = 'DAY',
  Week = 'WEEK'
}

export interface TaskFilter {
  scope: TaskFilterScope;
  // incomplete is default, default is all tasks
  state: TaskFilterCompletionState;
  section: TaskFilterSection;
  subSection: TaskFilterSubSection;
  viewType: TaskFilterView;
}

export enum MemberModalTaskFilterState {
  Incomplete = 'incomplete',
  Overdue = 'overdue',
  Unscheduled = 'unscheduled',
  Today = 'today',
  Scheduled = 'scheduled',
  Next7Days = 'next7Days',
  Completed = 'completed'
}

export interface MemberModalTaskFilter {
  state: MemberModalTaskFilterState;
}

export interface MemberModalTaskCounts {
  completed: number;
  unscheduled: number;
  scheduled: number;
  overdue: number;
  today: number;
}

export enum TaskEditProperty {
  Assigned = 'assigned',
  Assignee = 'assignee',
  CompletedAt = 'completed_at',
  Description = 'description',
  DueAt = 'due_at',
  EstimatedHours = 'estimated_hours',
  PhaseId = 'phase_id',
  Priority = 'priority',
  ScheduleStart = 'schedule_start',
  Selection = 'selection',
  Status = 'status',
  TaskGroupId = 'task_group_id',
  Timer = 'timer'
}

export interface TaskFilterState {
  isFetchingCounts: {
    today: boolean;
    overdue: boolean;
    endsBeforeTomorrow: boolean;
    endsBeforeNext7Days: boolean;
  };
  memberModalTaskCounts: {
    today: number;
    overdue: number;
    endsBeforeTomorrow: number;
    endsBeforeNext7Days: number;
  };
}

export interface HomeTasksState {
  activeTaskFilter: ActiveTaskFilter | Record<string, never>;
  allTasks: TaskId[];
  batchSelectedTaskIds: Record<TaskId, boolean>;
  dependencyTaskHash: Record<TaskId, Task>;
  editedTasks: Record<TaskId, Task>;
  pastDueCount: Nullable<number>;
  pastDueTasks: TaskId[];
  pastScheduledCount: Nullable<number>;
  pastScheduledTasks: TaskId[];
  projectMembershipHash: Record<TaskId, ProjectMembership[]>;
  scheduledForToday: Nullable<number>;
  taskHash: Record<TaskId, Task>;
  timerTaskHash: Record<TaskId, Task>;

  /**
   * Whatever tasks are grouped by, not necessarily task groups.
   */
  batchSelectedGroupIds: Record<TaskGroupId | number, boolean>;

  isFetchingTasks: number;
  isFetchingPastDue: boolean;
  isFetchingPastScheduled: boolean;
  isLazyLoading: boolean;
  totalPages: number;
  currentPage: number;
  limit: number;
  currentFilter: TaskFilter;
  memberModalTaskFilter: MemberModalTaskFilter;
  memberModalTaskCounts: MemberModalTaskCounts;
  selectedTask: Nullable<TaskId | UUID>;
  sort: Nullable<TaskSortFields>;
  viewBy: ValueOf<typeof VIEW_BY>;
  selectedAccountIds: AccountId[];
  isCreatingTask: boolean;
  flaggedTasksModalOpen: boolean;
  pastScheduledModalOpen: boolean;
  taskEditProperty: Nullable<TaskEditProperty>;
  confirmCompleteModalTaskId: Nullable<TaskId>;
  confirmCompleteModalIsOpen: boolean;
  tempId: Nullable<UUID>;
  tempIds: Record<UUID, UUID>;
  createdTasksToTempIds: Record<TaskId, UUID>;

  /**
   * @deprecated Unused field.
   */
  createRowData: Record<string, unknown>;

  offset: number;
  taskCount: Nullable<number>;

  /**
   * Whatever tasks are grouped by, not necessarily task groups.
   */
  taskGroupCounts: Record<TaskGroupId | number, number>;

  taskRemovals: Record<TaskId, ValueOf<typeof taskRemoveTypes>>;
  filterStates: Record<number | string, TaskFilterState>;
  fetchedProjectIds: Record<ProjectId, true>;

  /**
   * The estimated hours on a work plan-task association.
   */
  workPlanTaskHours?: Record<WorkPlanId, Record<TaskId, Nullable<number>>>;
}
