import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import theme from 'theme';

export const AdminCircle = styled.div`
  height: 6px;
  width: 6px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.colorDarkDijonYellow};
`;

export const StyledAdminList = styled.div<{ inTooltip?: boolean }>`
  padding: 0px 25px;
  ${(props) =>
    !props.inTooltip && `background: ${theme.colors.colorPureWhite};`}
`;

export const StyledAdminListItem = styled.div`
  line-height: 16px;
  font-size: 11px;
  display: flex;
  align-items: center;
`;
export const StyledTooltip = styled(ReactTooltip)`
  font-size: 11px;
  font-weight: normal;
  width: 170px;
  white-space: normal;
  text-align: left;
  padding: 12px !important;
`;

export const TooltipMessage = styled.div`
  font-size: 13px;
  line-height: 20px;
`;
