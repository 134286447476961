import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bindAll from 'lodash/bindAll';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import get from 'lodash/get';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import DateNav from './DateNav';
import {
  HomePlannerScrollableContainer,
  TaskModal,
  BulkUpdateModalPlannerConnector
} from 'views';
import taskNonModalClasses from 'appConstants/taskNonModalClasses';
import taskCardClasses from 'appConstants/taskCardClasses';
import UnscheduledSlideoutButton from './UnscheduledSlideoutButton';
import {
  fetchPlanners,
  setEditTaskId,
  fetchCommentsAndMetadata,
  closeAndClearTaskViewModal,
  toggleBulkUpdateModal,
  setSelectedTask,
  setPlannerDateHeader,
  flushSelectedHomeTask
} from 'actionCreators';
import { LANE_WIDTH, WEEK_FETCH_OFFSET, TODAY } from 'appConstants/planners';
import {
  calcNumDaysScrolled,
  getLaneQuerySelector,
  isTodayOnScreen,
  getDateFromClassName,
  getNumLanesOnScreen
} from 'appUtils/plannerUtils';
import {
  getTaskPlannerMemberIds,
  getAuth,
  getHomePlanner,
  getTaskDetail,
  getMe,
  getNavigationHistory
} from 'selectors';
import { isOnTeamTaskPlanner } from 'appUtils/views';
import cn from 'classnames';

const moment = extendMoment(Moment);

const FORWARD = 'forward';
const BACKWARD = 'backward';

const noop = () => {};

class HomePlannerContainer extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'toggleTaskModal',
      'scrollToToday',
      'scrollTo',
      'handleScroll',
      'scrollPlannerScreenWidth',
      'onOpenUnscheduled',
      'onCloseUnscheduled',
      'clearEditTask'
    ]);

    this.state = {
      localDateHeader: TODAY,
      unscheduledOpen: false,
      isScrolling: false
    };

    this.ticking = false;
  }

  componentDidMount() {
    const { homePlanner, memberIds } = this.props;
    if (memberIds && memberIds.length) {
      this.fetchPlanners({
        baseDate: moment(homePlanner.dateHeader),
        rightOffset: WEEK_FETCH_OFFSET,
        leftOffset: WEEK_FETCH_OFFSET,
        shouldUpdateDateRange: true
      });
      this.fetchPlanners({
        baseDate: moment(homePlanner.dateHeader),
        rightOffset: WEEK_FETCH_OFFSET,
        leftOffset: WEEK_FETCH_OFFSET,
        unscheduled: true
      });
    }
    setTimeout(() => this.scrollToToday('instant'));
  }

  componentDidUpdate(prevProps, prevState) {
    const { memberIds, homePlanner } = this.props;
    if (prevProps.memberIds && prevProps.memberIds.length < memberIds.length) {
      const refreshedPlannerPage = prevProps.memberIds.length === 0;
      this.fetchPlanners({
        baseDate: moment(homePlanner.dateHeader),
        rightOffset: WEEK_FETCH_OFFSET,
        leftOffset: WEEK_FETCH_OFFSET,
        shouldUpdateDateRange: refreshedPlannerPage
      });
      if (refreshedPlannerPage) {
        this.fetchPlanners({
          baseDate: moment(homePlanner.dateHeader),
          rightOffset: WEEK_FETCH_OFFSET,
          leftOffset: WEEK_FETCH_OFFSET,
          unscheduled: true
        });
      }
    }

    this.handleUpdateScrolling(prevProps);
  }

  handleUpdateScrolling = (prevProps) => {
    const newEarliestDateFetched =
      !prevProps.homePlanner.earliestDateFetched.isSame(
        this.props.homePlanner.earliestDateFetched,
        'd'
      );
    const justMounted =
      !prevProps.homePlanner.dateRange.length &&
      this.props.homePlanner.dateRange.length;

    if (newEarliestDateFetched) {
      if (justMounted) {
        setTimeout(() => this.scrollToToday('instant'));
      } else {
        const scrollableWindow = document.querySelector('.scrollable-dnd');
        scrollableWindow.scrollLeft += LANE_WIDTH * WEEK_FETCH_OFFSET * 7;
      }
    }
  };

  fetchPlanners = (params) =>
    this.props.fetchPlanners(this.props.auth.token, {
      ...params,
      accountIds: this.props.memberIds
    });

  fetchCommentsAndMetadata = (params) =>
    this.props.fetchCommentsAndMetadata(this.props.auth.token, params);

  onOpenUnscheduled() {
    this.setState({
      unscheduledOpen: true
    });
  }

  onCloseUnscheduled() {
    this.setState({
      unscheduledOpen: false
    });
  }

  scrollToToday(behavior) {
    this.scrollTo(TODAY, behavior);
  }

  setPlannerDateHeader = debounce(this.props.setPlannerDateHeader, 500);

  tick = (column) => () => {
    if (column) {
      const numDaysScrolled = calcNumDaysScrolled(
        column,
        this.state.unscheduledOpen
      );
      const firstLaneDate = moment(getDateFromClassName(column));
      const newDateHeader = firstLaneDate.add(numDaysScrolled, 'd');
      this.setPlannerDateHeader({
        dateHeader: newDateHeader
      });
      this.setState({
        localDateHeader: newDateHeader
      });
    } else {
      console.error('Column ref could not be found!');
    }
    this.ticking = false;
  };

  handleScroll(e) {
    if (this.state.isScrolling) {
      e.preventDefault();
      return;
    }

    const column = get(e, 'target.children[0].children[1]');
    if (!this.ticking) {
      window.requestAnimationFrame(this.tick(column));
      this.ticking = true;
    }
  }

  scrollTo(date, behavior = 'smooth') {
    const scrollDate = moment(date);
    const scrollableWindow = document.querySelector('.scrollable-dnd');
    const scrollDateLane = document.querySelector(
      getLaneQuerySelector(scrollDate)
    );

    if (scrollDateLane) {
      this.setPlannerDateHeader({
        dateHeader: scrollDate
      });
      this.setState({
        localDateHeader: scrollDate,
        isScrolling: true
      });
      scrollableWindow.scrollTo &&
        scrollableWindow.scrollTo({
          behavior,
          left: scrollDateLane.offsetLeft
        });
      const checkScrolling = setInterval(() => {
        const acceptableDistanceFromEdge = isOnTeamTaskPlanner(
          this.props.navigationHistory
        )
          ? 200
          : 100;
        const laneDistanceFromEdge = Math.abs(
          scrollDateLane.getBoundingClientRect().left
        );
        const isScrollComplete =
          laneDistanceFromEdge < acceptableDistanceFromEdge;
        if (isScrollComplete) {
          this.setState({ isScrolling: false });
          clearInterval(checkScrolling);
        }
      }, 100);
    }
  }

  scrollPlannerScreenWidth(direction) {
    const { localDateHeader, unscheduledOpen } = this.state;
    const scrollDate =
      direction === FORWARD
        ? localDateHeader.clone().add(getNumLanesOnScreen(unscheduledOpen), 'd')
        : localDateHeader
            .clone()
            .subtract(getNumLanesOnScreen(unscheduledOpen), 'd');
    this.scrollTo(scrollDate);
  }

  scrollPlannerScreenWidthForward = () =>
    this.scrollPlannerScreenWidth(FORWARD);

  scrollPlannerScreenWidthBackward = () =>
    this.scrollPlannerScreenWidth(BACKWARD);

  toggleTaskModal(isOpen, taskId, e) {
    const {
      setEditTaskId,
      flushSelectedHomeTask,
      closeAndClearTaskViewModal,
      setSelectedTask,
      selectedTask
    } = this.props;

    if (e && e.target.classList.contains('check-circle-img')) return;
    closeAndClearTaskViewModal();
    flushSelectedHomeTask();
    setSelectedTask(null);

    const beingEdited = taskId === selectedTask.id && isOpen;
    const openEditTask = !!(
      e && intersection(e.target.classList, taskNonModalClasses).length
    );
    if (beingEdited || openEditTask || taskId === 'top') return;
    setEditTaskId(null);
  }

  clearEditTask(e) {
    if (
      !intersection(e.target.classList, taskCardClasses).length &&
      // if an arrow was click in date picker
      !(
        e.target.tagName === 'svg' ||
        e.target.tagName === 'path' ||
        e.target.tagName === 'SPAN' ||
        e.target.tagName === 'LI'
      )
    ) {
      this.props.setEditTaskId(null);
    }
  }

  render() {
    const { toggleBulkUpdateModal, homePlanner, navigationHistory } =
      this.props;

    const isBulkUpdateModalOpen = homePlanner.bulkUpdateModal.isOpen;
    return (
      <div
        className={cn('home-planner-container', {
          'team-tasks': isOnTeamTaskPlanner(navigationHistory)
        })}
        onClick={this.clearEditTask}
      >
        <div className="planner-date-header">
          <UnscheduledSlideoutButton
            onOpen={this.onOpenUnscheduled}
            isOpen={this.state.unscheduledOpen}
          />
          <DateNav
            scrollBack={this.scrollPlannerScreenWidthBackward}
            scrollForward={this.scrollPlannerScreenWidthForward}
            scrollToToday={this.scrollToToday}
            header={this.state.localDateHeader}
            isTodayOnScreen={isTodayOnScreen()}
          />
        </div>
        <HomePlannerScrollableContainer
          handleScroll={this.handleScroll}
          unscheduledOpen={this.state.unscheduledOpen}
          onCloseUnscheduled={this.onCloseUnscheduled}
          toggleBulkUpdateModal={toggleBulkUpdateModal}
          localDateHeader={this.state.localDateHeader}
          fetchPlanners={this.fetchPlanners}
        />
        <BulkUpdateModalPlannerConnector
          isOpen={isBulkUpdateModalOpen}
          toggle={toggleBulkUpdateModal}
        />
      </div>
    );
  }
}

const emptyObj = {};
const mapStateToProps = (state, ownProps) => {
  const path = ownProps.location.pathname;
  return {
    path,
    auth: getAuth(state),
    me: getMe(state),
    homePlanner: getHomePlanner(state),
    selectedTask: emptyObj,
    taskDetail: getTaskDetail(state),
    memberIds: getTaskPlannerMemberIds(state, ownProps),
    navigationHistory: getNavigationHistory(state)
  };
};

const mapDispatchToProps = {
  fetchPlanners,
  setEditTaskId,
  fetchCommentsAndMetadata,
  closeAndClearTaskViewModal,
  setSelectedTask,
  toggleBulkUpdateModal,
  setPlannerDateHeader,
  flushSelectedHomeTask
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePlannerContainer)
);
