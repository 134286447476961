import {
  ERROR_MESSAGES,
  TRAINING_DATA_REQUIRED_STRING
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const taskScopeDescriptionValidator: RowValidator = ({
  row,
  entityType
}) => {
  const { enteredTaskScopeDescription } = getRowValuesForEntityType(
    row,
    entityType
  );

  // required, can not be empty
  if (enteredTaskScopeDescription === TRAINING_DATA_REQUIRED_STRING) {
    return false;
  } else if (!enteredTaskScopeDescription) {
    return ERROR_MESSAGES.emptyDescription;
  }
  return true;
};
