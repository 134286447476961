import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  getSelectedProject,
  makeGetProjectManagersByAccountId,
  makeGetMemberByAssigneeId,
  getTaskAccountFilter,
  makeGetProjectBasicInfoById,
  getCurrentPage,
  getOwnProjectUnassignedCounts,
  getSelectedProjectAccountsByProjectMembershipId,
  getTaskProjectMembershipsHash
} from 'selectors';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { getMyUserId } from 'UsersModule/selectors';
import {
  getContractorsByAccountId,
  getGuestByAccountId
} from 'PermissionsModule/selectors';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import keyBy from 'lodash/keyBy';
import differenceBy from 'lodash/differenceBy';
import { getMemberBudgets } from 'BudgetModule/selectors';

import { getPositions } from 'BudgetModule/selectors/positions';

import MemberSelector from 'views/projectPlanner/workloadBarModal/MemberSelector';

import isEmpty from 'lodash/isEmpty';

import { withRouter } from 'react-router-dom';

import PMDot from 'views/memberDisplay/PMDot';

import { RoleIndicator, Wrapper, TaskAssigneeCircle } from './styles';
import { NewTaskProperty } from 'views/Home/Tasks/styles';

import { formatUnassignedRoleName } from 'appUtils/hooks/budget/useMemberBudgetName';
import isEqual from 'lodash/isEqual';
import GuestIcon from 'icons/GuestIcon';
import ContractorIcon from 'icons/ContractorIcon';

const emptyArray = [];
const getCircleLeft = (numAssignees, index, isSelectedHomeTask) => {
  if (isSelectedHomeTask) {
    // left aligned
    return index * 22;
  }
  if (numAssignees === 1 || numAssignees === 0) {
    return 30;
  } else if (numAssignees === 2) {
    return 30 + (index === 1 ? 11 : -11);
  } else {
    return 30 + (index === 1 ? 0 : index === 2 ? 22 : -22);
  }
};

const ModalWrapper = (props) =>
  props.isSelectedHomeTask ? (
    <>{props.children} </>
  ) : (
    <NewTaskProperty
      $taskIsEditing={props.isEditing}
      $isTaskModal={props.isSelectedHomeTask}
      $showOnHover={!props.projectDetailView && !props.isAssignedByMeView}
      style={{ display: 'block', opacity: 1 }}
    >
      {props.children}
    </NewTaskProperty>
  );

class TaskAssigneeDropdown extends Component {
  /**
   * Information returned from BE for task updates/create does not directly contain infroamtion
   * regarding the project membership ids that are associated with the said task.
   *
   * In get project membership ids on a task, you must iterate through the assignments on the task.
   * If the assignee is an account it will ahve both an assignee_id and also a project membership id.
   */
  constructor(props) {
    super(props);
    const { task, isNewTask } = this.props;
    this.state = {
      isAssignMultiple: isNewTask
        ? task.assignProjectMembershipIds?.length > 1
        : task.assignments?.length > 1,
      selectedMembershipIds: isNewTask
        ? keyBy(this.props.task?.assignProjectMembershipIds)
        : keyBy(
            task.assignments?.map(
              (assignment) => assignment.project_membership_id
            )
          ),
      idOrder: this.getOrderedProjectMembershipId(),
      isOpen: false,
      assigneeInfoHash: this.getAssigneeInformationHash(),
      projectMemberships: this.getProjectMemberships()
    };
  }

  componentDidUpdate(prevProps) {
    const { task, isNewTask } = this.props;
    const currentMembershipIds = this.getOrderedProjectMembershipId();
    const prevMembershipIds = this.getOrderedProjectMembershipId(
      prevProps.task
    );
    if (!this.state.isOpen) {
      if (
        task.assignProjectMembershipIds?.length !==
          prevProps.task.assignProjectMembershipIds?.length ||
        task.assignments?.length !== prevProps.task.assignments?.length ||
        !isEqual(currentMembershipIds, prevMembershipIds)
      ) {
        this.setState({
          isAssignMultiple: isNewTask
            ? task.assignProjectMembershipIds?.length > 1
            : task.assignments?.length > 1,
          selectedMembershipIds: isNewTask
            ? keyBy(task?.assignProjectMembershipIds)
            : keyBy(
                task.assignments?.map(
                  (assignment) => assignment.project_membership_id
                )
              ),
          idOrder: currentMembershipIds,
          assigneeInfoHash: this.getAssigneeInformationHash(),
          projectMemberships: this.getProjectMemberships()
        });
      }
    }
  }

  getProjectMemberships = () => {
    const { taskProjectMembershipsHash, task } = this.props;
    return keyBy(
      taskProjectMembershipsHash?.[task?.id],
      (projectMembership) => projectMembership?.id
    );
  };

  getOrderedProjectMembershipId = (previousTask = null) => {
    const { task, isNewTask } = this.props;
    const desiredTask = previousTask || task;
    return isNewTask
      ? isNewTask?.assignee_ids ||
          desiredTask?.assignProjectMembershipIds ||
          emptyArray
      : desiredTask?.assignments
          ?.slice()
          .sort((a, b) => a.id - b.id)
          .filter((assignment) => assignment.project_membership_id)
          .map((assignment) => assignment.project_membership_id) ?? emptyArray;
  };

  getAssigneeInformationHash = () => {
    const { task } = this.props;
    return keyBy(
      task.assignee_project_membership_information,
      (assignmentInfo) => assignmentInfo.project_membership_id
    );
  };

  getSelectedAssigneeProperty = (projectMembershipId, key) => {
    const {
      project,
      projectUnassignedRoleCounts,
      memberBudgetHash,
      positionHash,
      teamMembersHash
    } = this.props;

    const { assigneeInfoHash, projectMemberships } = this.state;
    // Project > ProjectMembership > Memberbudget > Name

    // Need to get the name of the role associated with project membership id.
    const projectMembership = project ? project.project_membership : null;
    const projectMembershipsHash = keyBy(
      projectMembership,
      (projectMembership) => projectMembership.id
    );
    if (
      projectMembershipId === null ||
      (isEmpty(projectMembershipsHash[projectMembershipId]) &&
        isEmpty(
          teamMembersHash[assigneeInfoHash[projectMembershipId]?.account_id]
        ) &&
        isEmpty(projectMemberships[projectMembershipId]) &&
        isEmpty(assigneeInfoHash[projectMembershipId]))
    )
      return null;
    const selectedAssignee =
      projectMembershipsHash[projectMembershipId] ||
      teamMembersHash[assigneeInfoHash[projectMembershipId]?.account_id] ||
      projectMemberships[projectMembershipId] ||
      assigneeInfoHash[projectMembershipId];

    // Trying to get name of an unassigned role, need to use memberBudget
    // to get position and also use unassigned count to get name
    if (!selectedAssignee?.account && key === 'name') {
      // Get the name from
      const unassignedMemberBudget =
        memberBudgetHash[selectedAssignee.member_budget_id];
      if (unassignedMemberBudget) {
        const position = positionHash[unassignedMemberBudget.position_id];
        if (position) {
          const name = formatUnassignedRoleName({
            memberBudget: unassignedMemberBudget,
            position,
            unassignedRoleCounts: projectUnassignedRoleCounts
          });
          return name;
        }
      }
    }
    return get(selectedAssignee, `account.${key}`);
  };

  handleBatchSelect = (items, value) => {
    const batchSelectedMembershipIds = items.reduce((acc, cur) => {
      acc[cur.memberBudget.project_membership.id] = value;
      return acc;
    }, {});

    const selectedMembershipIds = {
      ...this.state.selectedMembershipIds,
      ...batchSelectedMembershipIds
    };

    const idOrder = value
      ? Array.from(
          new Set([
            ...this.state.idOrder,
            ...items.map((item) => {
              return item.memberBudget.project_membership.id;
            })
          ])
        )
      : this.state.idOrder.filter((item) => {
          return !selectedMembershipIds[
            item.memberBudget.project_membership.id
          ];
        });

    this.setState({
      selectedMembershipIds,
      idOrder
    });
  };

  handleBatchClear = () =>
    this.setState({ selectedMembershipIds: {}, idOrder: [] });

  handleSelect = (name, value) => {
    const { onMultiAssignDone, task } = this.props;
    const { isAssignMultiple } = this.state;
    // Value is the project member ship id
    if (isAssignMultiple) {
      this.setState({
        selectedMembershipIds: {
          ...this.state.selectedMembershipIds,
          [value]: !this.state.selectedMembershipIds[value]
        },
        idOrder: this.state.selectedMembershipIds[value]
          ? this.state.idOrder.filter((id) => id !== value)
          : [...this.state.idOrder, value]
      });
    } else if (value === null) {
      // Assign unassign to task
      onMultiAssignDone({
        assignProjectMembershipIds: [],
        unassignProjectMembershipIds: task.assignments?.map(
          (assignment) => assignment.project_membership_id
        ),
        assigneeIds: [],
        unassigneeIds: task.assignee_ids
      });
    } else {
      // Switch assignee
      const assigneeParam = {
        ...(name === 'account_id'
          ? { assigneeIds: [value] }
          : { assignProjectMembershipIds: [value] }),
        unassignProjectMembershipIds: task.assignments?.map(
          (assignment) => assignment.project_membership_id
        )
      };
      onMultiAssignDone(assigneeParam);
    }
    this.setState({ isOpen: false });
  };

  handleDone = () => {
    const { onMultiAssignDone, task } = this.props;
    const { idOrder } = this.state;
    const assignProjectMembershipIds = idOrder;
    const unassignProjectMembershipIds = differenceBy(
      task.assignments?.map((assignment) => assignment.project_membership_id),
      assignProjectMembershipIds,
      (id) => +id
    );
    onMultiAssignDone({
      assignProjectMembershipIds,
      unassignProjectMembershipIds
    });
    this.setState({ isOpen: false });
  };

  getTooltipContent = (projectMembershipId) => {
    if (Array.isArray(projectMembershipId)) {
      return `Assigned to${projectMembershipId
        .slice(0, -1)
        .map(
          (id) => ` ${this.getSelectedAssigneeProperty(id, 'name')}`
        )} and ${this.getSelectedAssigneeProperty(
        projectMembershipId[projectMembershipId.length - 1],
        'name'
      )}`;
    }
    const name = this.getSelectedAssigneeProperty(projectMembershipId, 'name');
    if (name) {
      return `Assigned to ${name}`;
    } else {
      return 'Assign Task';
    }
  };

  getMyProjectMembershipId = () => {
    const { myId, project, memberBudgetHash } = this.props;

    // If it exists then i need to Find the member budget associated with it and then the project membershipid will be in there.
    const myMemberBudgetId =
      project && project.project_membership
        ? project.project_membership.find(
            (projectMembership) => projectMembership.account?.id === myId
          )?.member_budget_id
        : null;

    if (!myMemberBudgetId) {
      return null;
    }

    const myProjectMembershipId =
      memberBudgetHash[myMemberBudgetId]?.project_membership.id;

    return myProjectMembershipId;
  };

  renderMember = ({ onClick }) => {
    const {
      selectedAccountIds,
      teamMembersHash,
      isSelectedHomeTask,
      projectManagersByAccountIds,
      guestsByAccountIds,
      contractorsByAccountIds,
      selectedProjectAccountsByProjectMembershipId
    } = this.props;

    const { assigneeInfoHash, projectMemberships } = this.state;
    const assignProjectMembershipIds = this.getOrderedProjectMembershipId();
    const numAssignees = assignProjectMembershipIds.length;
    const myProjectMembershipId = this.getMyProjectMembershipId();
    const hasShowMoreAssignees = numAssignees > 3;
    const showMeSecond =
      hasShowMoreAssignees &&
      assignProjectMembershipIds.includes(myProjectMembershipId) &&
      !assignProjectMembershipIds.slice(0, 2).includes(myProjectMembershipId);

    const openDropdown = () => {
      const { projectInfo } = this.props;

      if (!projectInfo?.is_personal) {
        onClick();
        this.setState({ isOpen: true });
      }
    };
    if (numAssignees) {
      const projectMembershipIdsShown = {};
      return assignProjectMembershipIds
        .slice(0, 3)
        .map((projectMembershipId, index) => {
          const id =
            showMeSecond && index === 1
              ? myProjectMembershipId
              : projectMembershipId;
          projectMembershipIdsShown[id] = !(
            hasShowMoreAssignees && index === 2
          );
          const isAssignedToMe = id === myProjectMembershipId;
          const accountId =
            selectedProjectAccountsByProjectMembershipId[id]?.account?.id ||
            assigneeInfoHash[id]?.account_id ||
            projectMemberships[id]?.account?.id;

          // TeamMembership gives you the color.
          const teamMembership = teamMembersHash[accountId];
          const taskAssigneeIconContent = this.getSelectedAssigneeProperty(
            id,
            'initials'
          );
          const memberIsPM = projectManagersByAccountIds[accountId];
          const memberIsGuest = guestsByAccountIds[accountId];
          const memberIsContractor = contractorsByAccountIds[accountId];
          const IndicatorComponent = memberIsGuest
            ? GuestIcon
            : memberIsContractor
            ? ContractorIcon
            : undefined;
          if (index === 2 && hasShowMoreAssignees) {
            const idsForTooltip = assignProjectMembershipIds.filter(
              (projectMembershipId) =>
                !projectMembershipIdsShown[projectMembershipId]
            );
            return (
              <TaskAssigneeCircle
                preventHover
                key={id}
                taskAssigneeIconContent
                onClick={openDropdown}
                isAssignedToMe={isAssignedToMe}
                data-tip={this.getTooltipContent(idsForTooltip)}
                data-effect="solid"
                data-for={`app-tooltip`}
                data-class="task-assignee-tooltip"
                originType="teamMembership"
                selected
                isMultiAssign
                style={{
                  position: 'absolute',
                  left: getCircleLeft(numAssignees, index, isSelectedHomeTask),
                  zIndex: numAssignees - index
                }}
              >
                <span style={{ fontSize: 13 }}>+{numAssignees - 2}</span>
              </TaskAssigneeCircle>
            );
          } else {
            return (
              <TaskAssigneeCircle
                preventHover
                key={id}
                taskAssigneeIconContent={taskAssigneeIconContent}
                onClick={openDropdown}
                isAssignedToMe={isAssignedToMe}
                data-tip={this.getTooltipContent(id)}
                data-effect="solid"
                data-for={`app-tooltip`}
                data-class="task-assignee-tooltip"
                originType="teamMembership"
                id={teamMembership?.id}
                selected={selectedAccountIds.includes(id)}
                isMultiAssign
                style={{
                  position: 'absolute',
                  left: getCircleLeft(numAssignees, index, isSelectedHomeTask),
                  zIndex: numAssignees - index
                }}
              >
                {memberIsPM && <PMDot />}
                {taskAssigneeIconContent}
                {IndicatorComponent && (
                  <RoleIndicator>
                    <IndicatorComponent width={13} height={13} />
                  </RoleIndicator>
                )}
              </TaskAssigneeCircle>
            );
          }
        });
    } else {
      const teamMembership = {};
      const taskAssigneeIconContent = this.getSelectedAssigneeProperty(
        assignProjectMembershipIds?.[0],
        'initials'
      );
      return (
        <TaskAssigneeCircle
          preventHover
          taskAssigneeIconContent={taskAssigneeIconContent}
          onClick={openDropdown}
          data-tip={this.getTooltipContent()}
          data-effect="solid"
          data-for={`app-tooltip`}
          data-class="task-assignee-tooltip"
          originType="teamMembership"
          id={teamMembership.id}
          style={{
            position: 'absolute',
            left: getCircleLeft(numAssignees, 0, isSelectedHomeTask)
          }}
        >
          {taskAssigneeIconContent}
        </TaskAssigneeCircle>
      );
    }
  };

  onFooterClick = (e) => {
    this.setState({ isAssignMultiple: true });
  };

  handleCancel = () => {
    this.setState({
      isAssignMultiple: this.props.isNewTask
        ? this.props.task.assignProjectMembershipIds?.length > 1
        : this.props.task.assignments?.length > 1,
      selectedMembershipIds: this.props.isNewTask
        ? keyBy(this.props.task?.assignProjectMembershipIds)
        : keyBy(
            this.props.task.assignments?.map(
              (assignment) => assignment.project_membership_id
            )
          ),
      idOrder: this.getOrderedProjectMembershipId(),
      isOpen: false
    });
  };

  render() {
    const {
      isOnTaskModal = false,
      project,
      projectInfo,
      projectId,
      task,
      isEditing,
      projectDetailView,
      isSelectedHomeTask,
      currentPage
    } = this.props;
    const { isAssignMultiple, selectedMembershipIds } = this.state;

    const idOrder = this.getOrderedProjectMembershipId();
    return (
      <ModalWrapper
        isEditing={isEditing}
        isSelectedHomeTask={isSelectedHomeTask}
        projectDetailView={projectDetailView}
        isAssignedByMeView={currentPage.isAssignedByMe}
      >
        <Wrapper
          isOnTaskModal={isOnTaskModal}
          style={{
            width:
              isOnTaskModal || isSelectedHomeTask
                ? (Math.max(task?.assignee_ids?.slice(0, 3).length, 1) - 1) *
                    22 +
                  28
                : '100%',
            height: '100%'
          }}
        >
          <MemberSelector
            renderMember={this.renderMember}
            renderSelect={this.renderMember}
            handleSelect={this.handleSelect}
            projectId={projectId}
            boardId={projectInfo?.board_id || project?.board_id}
            isListItem
            key={task?.id}
            joinModalText="assign a task"
            includeUnassigned={Object.keys(selectedMembershipIds).length > 0}
            includeUnassignedRoles
            hideFooter={isAssignMultiple}
            customCopy={{
              headerInitial: 'Assign',
              footerInitial: isAssignMultiple
                ? 'Add Member to Project'
                : 'Assign to Multiple'
            }}
            primaryMemberText={'Task Manager'}
            onFooterClick={this.onFooterClick}
            isAssignMultiple={isAssignMultiple}
            selectedIds={selectedMembershipIds}
            idOrder={idOrder}
            handleDone={this.handleDone}
            handleCancel={this.handleCancel}
            handleClose={this.handleCancel}
            handleBatchSelect={this.handleBatchSelect}
            handleBatchClear={this.handleBatchClear}
            isEditing={isEditing}
            popoverClassName="member-select-dropdown"
            isUsingProjectMembershipId
          />
        </Wrapper>
      </ModalWrapper>
    );
  }
}

const projectIdGetter = (state, ownProps) => ownProps.project?.id;
const makeMapStateToProps = () => {
  const getProjectManagerAccountIds = makeGetProjectManagersByAccountId({
    projectIdGetter
  });
  const getMemberByAssigneeId = makeGetMemberByAssigneeId();
  const getProjectInfo = makeGetProjectBasicInfoById();

  const mapStateToProps = (state, ownProps) => ({
    myId: getMyUserId(state),
    project: getSelectedProject(state),
    projectInfo: getProjectInfo(state, ownProps),
    teamMembersHash: getTeamMembersHash(state),
    projectManagersByAccountIds: getProjectManagerAccountIds(state, ownProps),
    guestsByAccountIds: getGuestByAccountId(state),
    contractorsByAccountIds: getContractorsByAccountId(state),
    selectedAccountIds: getTaskAccountFilter(state),
    teamMembership: getMemberByAssigneeId(state, ownProps),
    matchedParams: getMatchedRouteParams(state),
    currentPage: getCurrentPage(state),
    projectUnassignedRoleCounts: getOwnProjectUnassignedCounts(state, {
      projectId: ownProps.projectId
    }),
    memberBudgetHash: getMemberBudgets(state),
    positionHash: getPositions(state),
    selectedProjectAccountsByProjectMembershipId:
      getSelectedProjectAccountsByProjectMembershipId(state),
    taskProjectMembershipsHash: getTaskProjectMembershipsHash(state)
  });
  return mapStateToProps;
};

export default withRouter(connect(makeMapStateToProps)(TaskAssigneeDropdown));
