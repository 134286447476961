import React from 'react';
import SvgIcon from 'components/SvgIcon';

const HelpQuestionIcon = ({
  height = '16',
  className,
  width = '10',
  fill = 'white',
  stroke = '#3D4A5E'
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.39534 8.091L7.39484 8.09136C7.02067 8.36014 6.71877 8.6105 6.49271 8.84249C6.26627 9.07485 6.08809 9.31549 5.96204 9.56499C5.83615 9.81417 5.75651 10.0795 5.72307 10.3598C5.69118 10.627 5.67558 10.9332 5.67558 11.2772V11.629H4.18618V11.211C4.18618 10.6328 4.23534 10.1493 4.33032 9.75745C4.42553 9.36462 4.57124 9.01336 4.76626 8.70204C4.96256 8.38868 5.21475 8.09401 5.52462 7.81841C5.84133 7.53674 6.22789 7.22804 6.68519 6.89224C7.16125 6.54267 7.53037 6.16443 7.785 5.75534C8.04846 5.33206 8.17235 4.79931 8.17235 4.17103C8.17235 3.47035 7.92073 2.86134 7.42403 2.35389L7.42411 2.35382L7.41945 2.34936C7.13722 2.07905 6.79879 1.87038 6.40731 1.72195C6.01483 1.57316 5.59899 1.49931 5.16129 1.49931C4.63227 1.49931 4.15839 1.58761 3.74425 1.77008C3.33678 1.9496 2.99067 2.19342 2.70924 2.50262C2.42888 2.81062 2.21935 3.16943 2.08031 3.57677C1.96297 3.92054 1.89586 4.28025 1.87836 4.65517H0.204069C0.225434 4.13151 0.330967 3.61882 0.521153 3.11631C0.734481 2.55266 1.0465 2.05711 1.45775 1.62805C1.86676 1.20133 2.38078 0.855479 3.00401 0.592548C3.62005 0.332651 4.33774 0.2 5.16129 0.2C5.78636 0.2 6.38381 0.292599 6.95459 0.477192C7.52437 0.661466 8.01619 0.925871 8.43278 1.26888C8.84886 1.61147 9.18104 2.02726 9.42992 2.51804C9.67489 3.0011 9.8 3.55783 9.8 4.1931C9.8 5.04139 9.59022 5.75795 9.17886 6.3524C8.75704 6.96196 8.16448 7.5421 7.39534 8.091ZM3.88664 15.8V13.7724H5.92903V15.8H3.88664Z"
      fill={fill}
      stroke={stroke}
      strokeWidth="0.4"
    />
  </SvgIcon>
);

export default HelpQuestionIcon;
