import { getAuthToken } from 'AuthenticationModule/selectors';
import { select } from 'redux-saga/effects';
import { changeEntity } from 'sagas/generics';
import { updateWorkPlanTasksApiRequest } from 'TaskModule/api/workPlanTaskAssociationApi';
import {
  updateWorkPlanTasks,
  updateWorkPlanTasksApiActions
} from 'TaskModule/actionCreators/workPlanTaskAssociationActionCreators';

export function* updateWorkPlanTasksWorker(
  action: ReturnType<typeof updateWorkPlanTasks>
) {
  const payload = action.payload;

  const token = (yield select(getAuthToken)) as Nullable<string> | undefined;

  yield changeEntity(
    updateWorkPlanTasksApiActions,
    updateWorkPlanTasksApiRequest,
    [token, payload],
    action
  );
}
