import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchPhaseTotalsWorker,
  fetchPhaseTotalsByBoardWorker,
  fetchPhaseTotalsBudgetReportWorker,
  fetchPhasesByProjectIdsWorker,
  fetchPhasesWorker,
  fetchPhaseNamesWorker,
  fetchFilteredPhasesWorker,
  createPhaseWorker,
  predictPhaseWorker,
  updatePhaseWorker,
  convertPhaseToDefaultWorker,
  deletePhaseWorker,
  hardDeletePhaseWorker,
  archivePhaseWorker,
  fetchPhaseTemplatesWorker,
  createPhaseTemplateWorker,
  createMilestoneTemplateWorker,
  reorderMilestoneTemplatesWorker,
  updatePhaseTemplateWorker,
  deletePhaseTemplateWorker,
  reorderPhaseTemplatesWorker,
  deleteMemberFromPhaseWorker,
  createBulkPhasesWorker,
  fetchPhaseMembersWorker,
  createPhaseMembersWorker,
  archivePhaseMemberWorker,
  deletePhaseMemberWorker,
  predictWorkdaysAndUpdatePhaseWorker,
  updatePhaseMembershipWorker
} from './phases';
import * as constants from 'appConstants';
import { updateActivityPhaseMembershipWorker } from 'ActivityPhaseModule/sagaWorker/activityPhaseMembership';
import { updateActivityPhaseMembership } from 'ActivityPhaseModule/actionCreators/activityPhaseMembership';
export const phaseSagas = [
  fork(takeEvery, constants.FETCH_PHASE_TOTALS.TRIGGER, fetchPhaseTotalsWorker),
  fork(
    takeEvery,
    constants.FETCH_PHASE_TOTALS_BY_BOARD.TRIGGER,
    fetchPhaseTotalsByBoardWorker
  ),
  fork(
    takeLatest,
    constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.TRIGGER,
    fetchPhaseTotalsBudgetReportWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_PHASES_BY_PROJECT_IDS.TRIGGER,
    fetchPhasesByProjectIdsWorker
  ),
  fork(takeEvery, constants.FETCH_PHASES.TRIGGER, fetchPhasesWorker),
  fork(takeEvery, constants.FETCH_PHASE_NAMES.TRIGGER, fetchPhaseNamesWorker),
  fork(
    takeEvery,
    constants.FETCH_FILTERED_PHASES.TRIGGER,
    fetchFilteredPhasesWorker
  ),
  fork(takeEvery, constants.CREATE_BULK_PHASES.TRIGGER, createBulkPhasesWorker),
  fork(takeEvery, constants.CREATE_PHASE.TRIGGER, createPhaseWorker),
  fork(takeEvery, constants.PREDICT_PHASE.TRIGGER, predictPhaseWorker),
  fork(takeEvery, constants.UPDATE_PHASE.TRIGGER, updatePhaseWorker),
  fork(
    takeEvery,
    constants.CONVERT_PHASE_TO_DEFAULT.TRIGGER,
    convertPhaseToDefaultWorker
  ),
  fork(takeEvery, constants.DELETE_PHASE.TRIGGER, deletePhaseWorker),
  fork(takeEvery, constants.HARD_DELETE_PHASE.TRIGGER, hardDeletePhaseWorker),
  fork(takeEvery, constants.ARCHIVE_PHASE.TRIGGER, archivePhaseWorker),
  fork(
    takeEvery,
    constants.FETCH_PHASE_TEMPLATES.TRIGGER,
    fetchPhaseTemplatesWorker
  ),
  fork(
    takeEvery,
    constants.REORDER_PHASE_TEMPLATES.TRIGGER,
    reorderPhaseTemplatesWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_PHASE_TEMPLATE.TRIGGER,
    createPhaseTemplateWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_PHASE_TEMPLATE.TRIGGER,
    updatePhaseTemplateWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_MILESTONE_TEMPLATE.TRIGGER,
    createMilestoneTemplateWorker
  ),
  fork(
    takeEvery,
    constants.REORDER_MILESTONE_TEMPLATES.TRIGGER,
    reorderMilestoneTemplatesWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_PHASE_TEMPLATE.TRIGGER,
    deletePhaseTemplateWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_MEMBER_FROM_PHASE.TRIGGER,
    deleteMemberFromPhaseWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_PHASE_MEMBERS.TRIGGER,
    fetchPhaseMembersWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_PHASE_MEMBERS.TRIGGER,
    createPhaseMembersWorker
  ),
  fork(
    takeEvery,
    constants.ARCHIVE_PHASE_MEMBER.TRIGGER,
    archivePhaseMemberWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_PHASE_MEMBER.TRIGGER,
    deletePhaseMemberWorker
  ),
  fork(
    takeEvery,
    constants.PREDICT_WORKDAYS_AND_UPDATE_PHASE.TRIGGER,
    predictWorkdaysAndUpdatePhaseWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_PHASE_MEMBERSHIP.TRIGGER,
    updatePhaseMembershipWorker
  ),
  fork(
    takeEvery,
    updateActivityPhaseMembership,
    updateActivityPhaseMembershipWorker
  )
];

export default phaseSagas;
