import { SetRequired } from 'type-fest';
import { Comment } from 'models/comment';
import { Phase, PhaseId } from 'ProjectsModule/phases/models/phase';
import { ProjectBudgetTotals } from 'ProjectsModule/models/projectTotals';

/**
 * An ID for a project.
 */
export type ProjectId = number;

/**
 * The priority ID for a project. These are received from `api/priorities`.
 */
export type ProjectPriorityId = number;

export interface Project {
  id: ProjectId;
  board_id: number;
  project_membership: any[]; // Fix me when project membership interface added
  project_number: Nullable<string>;
  description?: string;
  title: string;
  client?: any; // Fix me when client type added
  is_personal: boolean;
  general_tags: Array<unknown>; // Fix me
  comments: Comment[];
  location_ids: number[];
  office_ids: number[];
  region_ids: number[];
  discipline_ids: number[];
  custom_fields: ProjectCustomFields;
  is_administrative: boolean;
  due_date_enabled: boolean;
  total: string;
  budget_status?: string;
  main_phase_id?: number;
  is_default: false;
  phases: PhaseId[];
  priority_id?: number;
  status_id?: number;
  stage_id?: number;
  task_groups_count: number;
  billable: boolean;
  start_date?: string;
  end_date?: string;
  administrative_flag?: unknown; // Fix me
  task_group_order: number[];
  phase_orders: number[];
  task_priority_order: number[];
  task_priorities: Array<{
    color: string;
    created_at: string;
    id: number;
    title: string;
    updated_at: Nullable<string>;
  }>;
  task_status_order: number[];
  task_statuses: Array<{
    color: string;
    created_at: string;
    id: number;
    project_id: number;
    title: string;
    updated_at: Nullable<string>;
  }>;
  app_modules: number[];
  origin?: unknown; // Fix me
  added: false;
  task_columns: string[];
  project_priority: ProjectPriorityId;
  position: number;
  archived_at?: string;
  is_starred: boolean;
  note_count: number;
  completed_task_count: number;
  incomplete_task_count: number;
  overdue_task_count: number;
  team_slug: string;
  slug: string;
  group_slug: string;
  members_to_notify?: any; // Fix me, assuming number[]
  status: any[]; // Fix me when Status interface added
  budget_sub_status: Nullable<number | string>;
  has_default_phase?: boolean;
  defaultOrMainPhaseId?: number | undefined;
  member_account_ids?: number[];
}

export type PartialProject = SetRequired<Partial<Project>, 'id'> & {
  isPartial: boolean;
};

export const getIsPartialProject = (
  project: Project | PartialProject
): project is PartialProject => 'isPartial' in project && project.isPartial;

export interface ProjectCustomFields {
  profit_center: string; // Add to me
  require_vaccination: false;
}

export interface ProjectPhases {
  budget_totals: Nullable<ProjectBudgetTotals>;
  id: ProjectId;
  phase_orders: Array<PhaseId>;
  phases: Array<Phase>;
}
