import { useState, useRef, useEffect } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  SaveButton,
  Header,
  HeaderTitle,
  HeaderTitleRow
} from 'components/Modals/themes/TwoPaneModal/styles';
import { useWorkPlanForm } from './WorkPlanFormProvider';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import Popover from 'components/Popover';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import DeleteIcon from 'icons/DeleteIcon';
import { useSidebarPanel } from './SidebarPanel/SidebarPanelProvider';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import {
  DELETE_WORK_PLANS_TIP,
  EDIT_WORK_PLANS_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import CircleSpinner from 'components/Spinners/CircleSpinner';
import cn from 'classnames';

export const WorkPlanModalHeader = () => {
  const {
    form: {
      isNew,
      isPredicting,
      hasError,
      onCancel,
      onSubmit,
      isDirty,
      onSetDeleteConfirmModalOpen
    },
    permissions: { canDeleteWorkplan, canEditWorkplan }
  } = useWorkPlanForm();
  const [isOpen, setIsOpen] = useState(false);
  const { currentStep } = useSidebarPanel();

  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const threeDotMenuRef = useRef<HTMLDivElement>(null);

  const renderMenu = () => {
    return (
      <>
        <Popover
          isOpen
          target={threeDotMenuRef.current}
          closePopover={handleCloseMenu}
          className="styled-member-popover"
        >
          <StyledDropdownItem
            onClick={
              canDeleteWorkplan
                ? () => onSetDeleteConfirmModalOpen(true)
                : undefined
            }
            className={cn({ disabled: !canDeleteWorkplan })}
          >
            <OptionsContainer>
              {canDeleteWorkplan ? (
                <>
                  <DeleteIconContainer>
                    <DeleteIcon
                      className={undefined}
                      onClick={undefined}
                      testId={undefined}
                    />
                  </DeleteIconContainer>
                  Delete Work Plan
                </>
              ) : (
                <LockWithTooltip
                  style={undefined}
                  text="Delete"
                  tooltipContent={DELETE_WORK_PLANS_TIP}
                  isDisabled={undefined}
                />
              )}
            </OptionsContainer>
          </StyledDropdownItem>
        </Popover>
      </>
    );
  };

  const isValid = !hasError(['selected_archived_phase_member'], {
    inverse: true
  });
  const isMissingHours = hasError([
    'invalid_total_hours',
    'invalid_daily_hours'
  ]);
  const isMissingProject = hasError(['missing_project_id']);
  const disabledSubmit = isPredicting || !isValid || Boolean(currentStep);

  const tooltip = !canEditWorkplan
    ? EDIT_WORK_PLANS_TIP
    : isPredicting
    ? "The work plan's hours<br />are being updated."
    : isMissingProject
    ? 'Select a project'
    : isMissingHours
    ? 'Enter Hours'
    : undefined;

  const isTooltipDisabled = !tooltip;

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (tooltip) rebuildTooltip();
    else if (saveButtonRef.current) ReactTooltip.hide(saveButtonRef.current);
  }, [tooltip]);

  return (
    <Header>
      <HeaderTitleRow>
        <HeaderTitle>
          <h1>Work Plan</h1>
          {/* Three dot menu */}
          {!isNew && (
            <ThreeDotContainer ref={threeDotMenuRef} onClick={handleOpenMenu}>
              <ProjectThreeDotMenu
                shouldClose={!isOpen}
                onCloseCallback={handleCloseMenu}
              >
                {renderMenu()}
              </ProjectThreeDotMenu>
            </ThreeDotContainer>
          )}
        </HeaderTitle>
        {/* Done button */}
        <ButtonsContainer>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <StyledSaveButton
            color="white"
            backgroundColor={
              disabledSubmit
                ? theme.colors.colorLightGray8
                : theme.colors.colorRoyalBlue
            }
            data-class="center"
            data-effect="solid"
            data-for="app-tooltip"
            data-html
            data-place="bottom"
            data-tip={tooltip}
            data-tip-disable={isTooltipDisabled}
            fontWeight={600}
            isSaveDisabled={disabledSubmit}
            onClick={onSubmit}
            ref={saveButtonRef}
          >
            {isPredicting && <CircleSpinner fadeIn="none" />}
            <SubmitLabel>
              {isNew ? 'Create' : isDirty ? 'Update' : 'Done'}
            </SubmitLabel>
          </StyledSaveButton>
        </ButtonsContainer>
      </HeaderTitleRow>
    </Header>
  );
};

const SubmitLabel = styled.span``;

const StyledSaveButton = styled(SaveButton)`
  .sk-spinner {
    position: absolute;

    & + ${SubmitLabel} {
      visibility: hidden;
    }
  }
`;

const ThreeDotContainer = styled.div`
  transform: translateX(5px);
`;

const DeleteIconContainer = styled.div`
  margin-right: 7px;
  margin-top: -2px;
`;

const StyledDropdownItem = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${theme.colors.colorSemiDarkGray3};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
`;

const OptionsContainer = styled.div`
  padding: 4px 16px;
  display: flex;
  align-items: center;
  .lock-text {
    svg {
      margin-top: 3px;
      path {
        fill: ${theme.colors.colorLightGray15};
      }
    }
    .lock-text-label {
      color: ${theme.colors.colorLightGray15} !important;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 8px;
`;

const CancelButton = styled(SaveButton)`
  margin-right: 10px;
  background: none;
  font-weight: normal;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray5};
  &:hover {
    background: ${theme.colors.colorLightGray22};
    color: ${theme.colors.colorMediumGray9};
    border: 1px solid ${theme.colors.colorLightGray12};
  }
`;
