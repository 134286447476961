import { useState } from 'react';
import { VIEW_BY } from 'appConstants/workload';
import ViewToggle from 'components/options/ViewToggle';
import { useAppSelector } from 'reduxInfra/hooks';
import { getSplitFlags } from 'selectors';
import { MemberRatesTab } from './Member/MemberRatesTab';
import { RoleRatesTab } from './Role/RoleRatesTab';
import { WorkCategoryRatesTab } from './WorkCategory/WorkCategoryRatesTab';
import styled from 'styled-components';

const viewOptions = [
  {
    value: VIEW_BY.MEMBERS,
    label: 'Member'
  },
  {
    value: VIEW_BY.POSITIONS,
    label: 'Role'
  },
  {
    value: VIEW_BY.ACTIVITY,
    label: 'Work Category'
  }
] as const;

const viewToFlag = {
  [VIEW_BY.MEMBERS]: 'newMemberRatesFlag',
  [VIEW_BY.POSITIONS]: 'roleRateGroupFlag',
  [VIEW_BY.ACTIVITY]: 'workCategoryRateGroupFlag'
} as const;

const viewToComponent = {
  [VIEW_BY.MEMBERS]: MemberRatesTab,
  [VIEW_BY.POSITIONS]: RoleRatesTab,
  [VIEW_BY.ACTIVITY]: WorkCategoryRatesTab
} as const;

type Views =
  | typeof VIEW_BY.MEMBERS
  | typeof VIEW_BY.POSITIONS
  | typeof VIEW_BY.ACTIVITY;

export const RatesTab = () => {
  const splitFlags = useAppSelector(getSplitFlags);

  const [currentView, setCurrentView] = useState<Views>(
    viewOptions.find(({ value }) => splitFlags[viewToFlag[value] ?? -1])
      ?.value ?? viewOptions[0].value
  );

  const handleSelect = (view: Views) => setCurrentView(view);

  const SubtabContents = viewToComponent[currentView];

  return (
    <>
      <StyledViewToggle
        options={viewOptions}
        currentValue={currentView}
        onSelect={handleSelect}
        optionToFlag={viewToFlag}
      />
      <SubtabContents />
    </>
  );
};

const StyledViewToggle = styled(ViewToggle)`
  margin-left: 0;
  margin-top: 0;
`;
