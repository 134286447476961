import { createSelector } from 'reselect';
import { initialState, initialFilterState } from '../reducers/utilizations';
import {
  getActiveWorkloadPlannerFilter,
  getOOOProject,
  makeGetPlannerProjectMemberAccountIds
} from 'selectors';
import flatMap from 'lodash/flatMap';
import flatMapDeep from 'lodash/flatMapDeep';
import mergeWith from 'lodash/mergeWith';
import sumBy from 'lodash/sumBy';
import zipObject from 'lodash/zipObject';
import {
  getAccountCapacities,
  getHolidayISODatesHash,
  getHolidaysArray
} from 'CapacityModule/selectors';
import {
  DATA_KEY,
  DATA_KEY_TO_PERCENT,
  DATA_KEY_FOR_SUMMARY_WIDGET,
  CAPACITY_KEY,
  CHART_STACK_ORDER,
  CHART_STACK_ORDER_WITH_CAPACITY
} from '../constants';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import { DATE_FNS_ISO_DATE, DATE_FNS_USA_DATE } from 'appConstants/date';
import {
  getCapacityByDate,
  getUtilizationsBreakdown,
  utilizationBreakdownMerger
} from './utils';

const emptyArray = [];
const emptyObj = {};

export const getUtilizationsState = (state) =>
  state.utilizations || initialState;

export const getUtilizations = createSelector(
  getUtilizationsState,
  (state) => state.utilizations
);

export const getOwnAccountIds = (state, ownProps) => ownProps.accountIds ?? [];

// it returns utilizations breakdown per member without capping
// it is calculating breakdown on Frontend side
// but it will be replaced with the data from new API
export const makeGetUtilizationsBreakdownByMember = () =>
  createSelector(
    getOwnAccountIds,
    getUtilizations,
    getAccountCapacities,
    getOOOProject,
    getHolidayISODatesHash,
    (accountIds, utilizations, capacities, OOOProject, holidayISODatesHash) =>
      accountIds.reduce((acc, accountId) => {
        const accountUtilization = utilizations[accountId];
        const accountCapacity = capacities[accountId];

        if (accountUtilization && accountCapacity) {
          acc[accountId] = getUtilizationsBreakdown({
            accountUtilization,
            accountCapacity,
            PTOs: getDailyHoursFromScheduleBars({
              accountCapacity,
              scheduleBars: accountCapacity.activity_phase_schedule_bars.filter(
                (bar) => bar.project_id === OOOProject?.id
              )
            }),
            holidays: holidayISODatesHash
          });
        }

        return acc;
      }, {})
  );

export const makeGetSummaryUtilizationsBreakdown = () =>
  createSelector(
    makeGetUtilizationsBreakdownByMember(),
    (utilizationsBreakdownByMember) =>
      Object.values(utilizationsBreakdownByMember).reduce(
        (acc, memberUtilizations) =>
          mergeWith(acc, memberUtilizations, utilizationBreakdownMerger),
        {}
      )
  );

// interface CappedAccountUtilizations {
//   PTO: Record<ISODateString, number>;
//   [key: ISODateString]: number;
// }
// (utilizations: Record<number, Utilization>, accountCapacities: Record<string, AccountTeamCapacity>, accountIds?: Array<number>, OOOProjectId?: number, holidays?: Array<Holiday>) => Record<string, CappedAccountUtilizations>
/**
 * Produces a mapping of account IDs to mappings of dates to capped utilization
 * hours.
 */
export const getCappedUtilizations = (
  utilizations,
  accountCapacities,
  accountIds = emptyArray,
  OOOProjectId,
  holidays = emptyArray
) =>
  accountIds.reduce((cappedUtilizations, accountId) => {
    const accountCapacity = accountCapacities[accountId];
    const accountUtilizations = utilizations[accountId];

    // If there is no account capacity, the account mapping is full of zeros.
    if (accountCapacity) {
      const ptoCapacity = {
        PTO: sumAccountPto(accountCapacity, OOOProjectId, holidays)
      };

      // If there are no account utilizations, only return the PTO, including
      // holidays.
      cappedUtilizations[accountId] = accountUtilizations
        ? Object.entries(accountUtilizations).reduce(
            (accountUtilization, [dateKey, utilization]) => {
              const date = parseISO(dateKey);
              // Cap the utilization, including PTO and holidays, to the
              // capacity of the day.
              accountUtilization[dateKey] = Math.min(
                utilization + (accountUtilization.PTO[dateKey] ?? 0),
                getCapacityByDate(date, accountCapacity) ?? 0
              );

              return accountUtilization;
            },
            ptoCapacity
          )
        : ptoCapacity;
    }

    return cappedUtilizations;
  }, {});

// (args: { scheduleBars: Array<ActivityPhaseScheduleBar>, accountCapacity: AccountTeamCapacity }) => Record<ISODateString, number>
/**
 * Produces a mapping of dates to hours based on the provided schedule bars.
 */
const getDailyHoursFromScheduleBars = ({ accountCapacity, scheduleBars }) =>
  mergeWith(
    {},
    ...scheduleBars.flatMap(
      ({ bars, daily_hours: dailyHours, all_day: allDay }) =>
        bars.map(({ start_date: startDate, end_date: endDate }) =>
          Object.fromEntries(
            eachDayOfInterval({
              start: parseISO(startDate),
              end: parseISO(endDate)
            }).map((date) => [
              // Formatted date
              lightFormat(date, DATE_FNS_ISO_DATE),

              // Hours
              allDay
                ? getCapacityByDate(date, accountCapacity)
                : Number(dailyHours)
            ])
          )
        )
    ),
    (objValue, srcValue) => (objValue ?? 0) + (srcValue ?? 0)
  );

// (args: { accountCapacity: AccountTeamCapacity, holidays: Array<Holiday> }) => Record<ISODateString, number>
/**
 * Produces a mapping of dates to hours based on the provided holiday hours.
 */
export const getDailyHolidayHours = ({ holidays, accountCapacity }) =>
  mergeWith(
    {},
    ...holidays.map(({ start_date: startDate, end_date: endDate }) =>
      Object.fromEntries(
        eachDayOfInterval({
          start: parse(startDate, DATE_FNS_USA_DATE, Date.now()),
          end: parse(endDate, DATE_FNS_USA_DATE, Date.now())
        }).map((date) => [
          lightFormat(date, DATE_FNS_ISO_DATE),
          getCapacityByDate(date, accountCapacity)
        ])
      )
    ),
    (objValue, srcValue) => (objValue ?? 0) + (srcValue ?? 0)
  );

// (accountCapacity: AccountTeamCapacity, OOOProjectId?: number, holidays: Array<Holiday>) => Record<ISODateString, number>
/**
 * Produces a mapping of dates to PTO hours, including holidays hours.
 */
const sumAccountPto = (
  accountCapacity,
  OOOProjectId,
  holidays = emptyArray
) => {
  // Get the PTO hours by date.
  const ptoHours = getDailyHoursFromScheduleBars({
    accountCapacity,
    scheduleBars:
      accountCapacity?.activity_phase_schedule_bars?.filter(
        (bar) => bar.project_id === OOOProjectId
      ) ?? emptyArray
  });

  // Get the holiday hours by date.
  const holidayHours = getDailyHolidayHours({
    accountCapacity,
    holidays
  });

  // Combine the PTO and holiday hours by date.
  return mergeWith({}, ptoHours, holidayHours, (a, b, date) =>
    Math.min(
      (a ?? 0) + (b ?? 0),
      getCapacityByDate(parseISO(date), accountCapacity)
    )
  );
};

// (utilizations: Record<number, Utilization>, accountCapacities: Record<string, AccountTeamCapacity>, accountIds?: Array<number>, OOOProjectId: number) => Record<string, CappedAccountUtilizations>
/**
 * Sum the utilization hours, including PTO and holidays, of a list of accounts
 * by date.
 */
export const sumUtilizations = (
  utilizations,
  accountCapacities,
  accountIds = emptyArray,
  OOOProjectId
) => {
  // Sum the PTO hours for each member into a single mapping of dates to PTO
  // hours, including holidays hours.
  const summedPto = mergeWith(
    {},
    ...accountIds
      .map((accountId) => accountCapacities[accountId])
      .filter(Boolean)
      .map((accountCapacity) => sumAccountPto(accountCapacity, OOOProjectId)),
    (objValue, srcValue) => (objValue ?? 0) + (srcValue ?? 0)
  );

  // Sum the utilization hours for each member into a single mapping of dates
  // to hours, with the PTO time.
  return mergeWith(
    { PTO: summedPto },
    ...Object.values(utilizations),
    (objValue, srcValue) => (objValue ?? 0) + (srcValue ?? 0)
  );
};

export const makeGetCappedUtilizationsByMember = () =>
  createSelector(
    getUtilizations,
    getAccountCapacities,
    makeGetPlannerProjectMemberAccountIds(),
    getOOOProject,
    getHolidaysArray,
    (utilizations, capacities, accountIds, OOOProject, holidaysArray) =>
      getCappedUtilizations(
        utilizations,
        capacities,
        accountIds,
        OOOProject?.id,
        holidaysArray
      )
  );

export const getUtilizationSummary = createSelector(
  getUtilizations,
  getAccountCapacities,
  getActiveWorkloadPlannerFilter,
  getOOOProject,

  (utilizations, accountCapacities, filter, OOOProject) => ({
    id: 'summary',
    activity_phase_schedule_bars: flatMap(
      Object.values(accountCapacities),
      (capacity) => capacity.activity_phase_schedule_bars
    ),
    ...sumUtilizations(
      utilizations,
      accountCapacities,
      filter.account_ids,
      OOOProject?.id
    )
  })
);
export const getUtilizationsWithSummary = createSelector(
  getUtilizations,
  getUtilizationSummary,
  (accountUtilizations, utilizationSummary) => ({
    ...accountUtilizations,
    summary: utilizationSummary
  })
);

const getUtilizationReportOrder = createSelector(
  getUtilizationsState,
  (state) => state.utilizationReportOrder
);
const getUtilizationReportsHash = createSelector(
  getUtilizationsState,
  (state) => state.utilizationReport
);

export const getUtilizationsReport = createSelector(
  getUtilizationReportOrder,
  getUtilizationReportsHash,
  (order, hash) => order.map((id) => hash[id])
);

export const getUtilizationsReportProjects = createSelector(
  getUtilizationsReport,
  (report) =>
    flatMapDeep(report, (accountTotals) =>
      accountTotals.project_totals.map((project) => project.project_id)
    )
);

const getOwnIsShowingPto = (state, ownProps) =>
  // temporarily hides PTO
  // ownProps?.activeFilter?.custom?.showPto;
  false;
const getOwnIsShowingHolidays = (state, ownProps) =>
  // temporarily hides holidays
  // ownProps?.activeFilter?.custom?.showHolidays;
  false;

// Adds percentages and removes PTO/holidays if necessary
const formatData = (totals, hidePto, hideHolidays) => {
  return totals.map((total) => {
    const totalHours = Object.values(DATA_KEY)
      .filter(
        (dataKey) =>
          !(hidePto && dataKey === DATA_KEY.PTO) &&
          !(hideHolidays && dataKey === DATA_KEY.HOLIDAY)
      )
      .reduce((sumHours, dataKey) => {
        sumHours += +total[dataKey] || 0;
        return sumHours;
      }, 0);

    const formattedTotal = {
      ...total,
      [DATA_KEY.BILLABLE]: +total[DATA_KEY.BILLABLE],
      [DATA_KEY.NONBILLABLE]: +total[DATA_KEY.NONBILLABLE],
      [DATA_KEY.PTO]: +(total[DATA_KEY.PTO] ?? 0),
      [DATA_KEY.PTO_FOR_DISPLAY]: +(total[DATA_KEY.PTO] ?? 0),
      [DATA_KEY.HOLIDAY]: +(total[DATA_KEY.HOLIDAY] ?? 0),
      [DATA_KEY.HOLIDAY_FOR_DISPLAY]: +(total[DATA_KEY.HOLIDAY] ?? 0),
      totalHours,
      [DATA_KEY.BILLABLE_PERCENT]: totalHours
        ? (total[DATA_KEY.BILLABLE] / totalHours) * 100
        : 0,
      [DATA_KEY.NONBILLABLE_PERCENT]: totalHours
        ? (total[DATA_KEY.NONBILLABLE] / totalHours) * 100
        : 0,
      ...(!hidePto && {
        [DATA_KEY.PTO_PERCENT]: totalHours
          ? ((total[DATA_KEY.PTO] ?? 0) / totalHours) * 100
          : 0
      }),
      ...(!hideHolidays && {
        [DATA_KEY.HOLIDAY_PERCENT]: totalHours
          ? ((total[DATA_KEY.HOLIDAY] ?? 0) / totalHours) * 100
          : 0
      })
    };

    if (hidePto) {
      delete formattedTotal[DATA_KEY.PTO];
    }
    if (hideHolidays) {
      delete formattedTotal[DATA_KEY.HOLIDAY];
    }
    return formattedTotal;
  });
};

export const getTotalHoursByKeys = (data, keys) =>
  sumBy(keys, (key) => Math.max(+data[key], 0));

export const calcPercentBySumOfKeys = (
  data,
  keysToSumAsDenominator,
  keysToFormat
) => {
  // denominator can be either total hours based (exclude remaining capacity) or capacity based
  const denominator = getTotalHoursByKeys(data, keysToSumAsDenominator);
  // total should not include remaining capacity
  const totalHours = getTotalHoursByKeys(
    data,
    keysToFormat.filter((key) => key !== CAPACITY_KEY.REMAINING_CAPACITY)
  );

  // when denominator is negative (the only case is when we use capacity as denominator and capacity <= 0),
  // percentages of the values should be based on totalHours which will make totalPercent 100% (this is spec)
  const denominatorToUse = denominator > 0 ? denominator : totalHours;

  return {
    ...data,
    ...zipObject(
      keysToFormat.map((key) => DATA_KEY_TO_PERCENT[key]),
      keysToFormat.map(
        (key) => (Math.max(+data[key], 0) / denominatorToUse) * 100 || 0 // prevents 0 / 0 becomes NaN
      )
    ),
    totalHours
  };
};

// format utilization data by different denominators
const formatDataV2 = (
  totals,
  hidePto,
  hideHolidays,
  useCapacityAsDenominator, // use available capacity for percentage calculation instead of totalHours
  includeRemainingCapacity // include remaining capacity to calculation
) => {
  const keysToFormat = (
    includeRemainingCapacity
      ? CHART_STACK_ORDER_WITH_CAPACITY
      : CHART_STACK_ORDER
  ).filter(
    (key) =>
      !(hidePto && key === DATA_KEY.PTO) &&
      !(hideHolidays && key === DATA_KEY.HOLIDAY)
  );
  const keysToSumAsDenominator = useCapacityAsDenominator
    ? [CAPACITY_KEY.CAPACITY_FOR_PERCENTAGE]
    : keysToFormat;

  return totals.map((total) => {
    const formattedTotal = {
      ...total,
      ...calcPercentBySumOfKeys(total, keysToSumAsDenominator, keysToFormat),
      [DATA_KEY.PTO_FOR_DISPLAY]: +total[DATA_KEY.PTO] || 0,
      [DATA_KEY.HOLIDAY_FOR_DISPLAY]: +total[DATA_KEY.HOLIDAY] || 0
    };

    if (hidePto) {
      delete formattedTotal[DATA_KEY.PTO];
    }
    if (hideHolidays) {
      delete formattedTotal[DATA_KEY.HOLIDAY];
    }
    return formattedTotal;
  });
};

const getUtilizationDataTotals = (data) => {
  let total = 0;

  const totalValues = data.reduce((acc, cur) => {
    CHART_STACK_ORDER.forEach((dataKey) => {
      const value = +cur[dataKey] || 0;
      acc[dataKey] = acc[dataKey] !== undefined ? acc[dataKey] + value : value;
      total += value;
    });
    // calculating pto_hours and holiday_hours for display in summary widget when filter option is off because the key value pair
    // in data for PTO/Holiday gets emitted if filter option is off.
    DATA_KEY_FOR_SUMMARY_WIDGET.forEach((dataKey) => {
      const value = +cur[dataKey] || 0;
      acc[dataKey] = acc[dataKey] !== undefined ? acc[dataKey] + value : value;
    });
    return acc;
  }, {});

  // Calculate percentages of total
  Object.keys(totalValues)
    .filter((dataKey) => !DATA_KEY_FOR_SUMMARY_WIDGET.includes(dataKey))
    .forEach((dataKey) => {
      totalValues[DATA_KEY_TO_PERCENT[dataKey]] = total
        ? (totalValues[dataKey] / total) * 100
        : 0;
    });
  return {
    ...totalValues,
    total
  };
};

const getOwnFilterStateId = (state, ownProps) => {
  return (
    ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id
  );
};

const getOwnFormatOptions = (_, ownProps) => {
  const { useCapacityAsDenominator, includeRemainingCapacity } = ownProps;
  return { useCapacityAsDenominator, includeRemainingCapacity };
};

export const makeGetUtilizationFilterState = () =>
  createSelector(
    getUtilizationsState,
    getOwnFilterStateId,
    (state, filterStateId) => {
      return state.filterStates[filterStateId] || initialFilterState;
    }
  );

// api: /utilization_report without interval params and optional show_status_totals
//      for timesheet hours
// returns format { start_date, end_date, billable_hours, ... }
export const makeGetTeamUtilizationSummaryData = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(getUtilizationFilterState, (state) => {
    return state.teamTotals[0] || emptyObj;
  });
};

export const makeGetTeamHistoricalUtilization = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(getUtilizationFilterState, (state) => {
    return state.teamHistoricalUtilization || emptyObj;
  });
};

export const makeGetTeamUtilizationChartData = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(
    getUtilizationFilterState,
    getOwnIsShowingPto,
    getOwnIsShowingHolidays,
    getOwnFormatOptions,
    (
      state,
      showPto,
      showHolidays,
      { useCapacityAsDenominator, includeRemainingCapacity }
    ) => {
      const formatDataToUse =
        useCapacityAsDenominator || includeRemainingCapacity
          ? formatDataV2
          : formatData;
      return formatDataToUse(
        state.teamTotals,
        !showPto,
        !showHolidays,
        useCapacityAsDenominator,
        includeRemainingCapacity
      );
    }
  );
};

export const makeGetTeamUtilizationChartDataTotals = () =>
  createSelector(makeGetTeamUtilizationChartData(), getUtilizationDataTotals);

export const makeGetOrderedMemberUtilizations = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(
    getUtilizationFilterState,
    getOwnIsShowingPto,
    getOwnIsShowingHolidays,
    getOwnFormatOptions,
    (
      state,
      showPto,
      showHolidays,
      { useCapacityAsDenominator, includeRemainingCapacity }
    ) => {
      const order = state.memberUtilizationsOrder;
      const utilizations = state.memberUtilizations;
      const formatDataToUse =
        useCapacityAsDenominator || includeRemainingCapacity
          ? formatDataV2
          : formatData;
      return formatDataToUse(
        order.map((accountId) => ({
          account_id: accountId,
          ...utilizations[accountId].totals[0] // no interval so just one total
        })),
        !showPto,
        !showHolidays,
        useCapacityAsDenominator,
        includeRemainingCapacity
      );
    }
  );
};

export const makeGetMemberUtilizationTotals = () =>
  createSelector(makeGetOrderedMemberUtilizations(), getUtilizationDataTotals);

export const makeGetIsFetchingUtilizationReport = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(getUtilizationFilterState, (state) => state.isFetching);
};

export const makeGetMemberUtilizationsOrder = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(
    getUtilizationFilterState,
    (state) => state.memberUtilizationsOrder
  );
};

export const makeGetMemberUtiliztionsTotalCounts = () =>
  createSelector(makeGetUtilizationFilterState(), (state) => state.totalCounts);

// Returns format of { [account id]: { [start date]: totals }}
export const makeGetMemberTimesheetStatusTotalsByDate = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(getUtilizationFilterState, (state) => {
    const memberUtilizations = state.memberUtilizations;
    return Object.keys(memberUtilizations).reduce((res, accountId) => {
      res[accountId] = memberUtilizations[accountId].totals.reduce(
        (acc, total) => {
          let totalTimesheetHours = [
            DATA_KEY.APPROVED,
            DATA_KEY.SUBMITTED,
            DATA_KEY.NOT_SUBMITTED,
            DATA_KEY.REJECTED
          ].reduce((sum, cur) => {
            return sum + (+total[cur] || 0);
          }, 0);
          [DATA_KEY.PTO, DATA_KEY.HOLIDAY].forEach((datakey) => {
            totalTimesheetHours -= +total[datakey] || 0;
          });

          acc[total.start_date] = {
            ...total,
            totalTimesheetHours: Math.max(totalTimesheetHours, 0)
          };
          return acc;
        },
        {}
      );
      return res;
    }, {});
  });
};

export const makeGetProjectUtilizationReports = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(
    getUtilizationFilterState,
    (state) => state.projectUtilizationReports
  );
};
export const makeGetPhaseUtilizationReports = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();
  return createSelector(
    getUtilizationFilterState,
    (state) => state.phaseUtilizationReports
  );
};
