import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PresentationToolIcon = ({
  width = '28',
  height = '28',
  fill = '#FE69B3'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill={fill} />
      <path
        d="M22 7V8.40625H21.0588V17.7812C21.0588 18.558 20.3812 19.1875 19.5464 19.1875H16.2518L18.7609 22H16.872L14.3624 19.1875H14.1073L11.5981 22H9.70965L12.2184 19.1875H8.45365C7.61882 19.1875 6.94118 18.558 6.94118 17.7812V8.40625H6V7H22ZM11.6471 13.5625H10.2353V14.9688H11.6471V13.5625ZM17.7647 13.5625H12.5882V14.9688H17.7647V13.5625ZM17.7647 11.2188H12.5882V12.625H17.7647V11.2188ZM11.6471 11.2188H10.2353V12.625H11.6471V11.2188Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default PresentationToolIcon;
