import { callGalaxyServerApi } from 'appUtils/api';
import { UpdateOrttoUserInitialPayload } from '../actionCreators/types';

export const updateOrttoUser = (
  token: string,
  params: UpdateOrttoUserInitialPayload
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  return callGalaxyServerApi(`ortto/user`, token, fetchInit);
};
