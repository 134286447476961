import { TIMELINE_VIEW_TYPES } from 'TimelinesModule/components/constants';
import { useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  addTimelineView,
  removeTimelineView,
  updateTimelineView,
  UpdateTimelineViewParams
} from 'TimelinesModule/actionCreators/timelineViews';
import { v4 } from 'uuid';
import { useMount, useUnmount } from 'react-use';
import { getTimelineViewById } from 'TimelinesModule/selectors';

interface useTimelineViewProps {
  type: TIMELINE_VIEW_TYPES;
}
export const useTimelineView = ({ type }: useTimelineViewProps) => {
  const dispatch = useAppDispatch();

  const { current: timelineId } = useRef(v4());

  const timelineView = useAppSelector((state) =>
    getTimelineViewById(state, { id: timelineId })
  );

  const handleUpdateTimelineView = useCallback(
    (updateTimelineViewParams: Omit<UpdateTimelineViewParams, 'id'>) => {
      dispatch(
        updateTimelineView({
          id: timelineId,
          ...updateTimelineViewParams
        })
      );
    },
    [dispatch, timelineId]
  );

  useMount(() => {
    dispatch(
      addTimelineView({
        id: timelineId,
        type
      })
    );
  });

  // TODO: handle the case when the timeline view should be persisted on unmount
  useUnmount(() => {
    dispatch(removeTimelineView({ id: timelineId }));
  });

  return {
    timelineView,
    updateTimelineView: handleUpdateTimelineView
  };
};
