import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getCurrentPage,
  getCurrentFilter,
  getPastScheduledTasks,
  getPastDueTasks
} from 'selectors';
import WorkCategoryIcon from 'icons/WorkCategoryIcon';

import TasksGroupHeader from 'views/Home/TaskList/TasksGroupHeader';

const emptyObj = {};

class CompleteCellContent extends PureComponent {
  renderIcon = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 2
        }}
      >
        <WorkCategoryIcon height={18} width={18} />
      </div>
    );
  };

  render() {
    const {
      isOnHomeView,
      group,
      groupIndex,
      setIsOpen,
      draggableProvided = emptyObj,
      allCollapsed,
      isOpen,
      numTasks
    } = this.props;
    return (
      <div
        ref={draggableProvided?.innerRef}
        {...draggableProvided.draggableProps}
      >
        <TasksGroupHeader
          index={groupIndex}
          projectId={group.project_id}
          group={group}
          id={group.id}
          originType={'activityPhase'} // necessary to determine color picker open/close state
          pickerLocation={'flyout-menu'}
          isOnHomeView={isOnHomeView}
          isOnProjectView={true}
          taskIds={group.task_order}
          provided={draggableProvided}
          // snapshot={snapshot}
          collapsedForDrag={false}
          isOnlyTaskGroup={this.props.isOnlyTaskGroup}
          setIsOpen={setIsOpen}
          key={group?.id}
          hideSort
          isVirtualTaskList
          tableAllCollapsed={allCollapsed}
          renderIcon={this.renderIcon}
          editDisabled
          dragDisabled
          isTaskGroup={false}
          isOpen={isOpen}
          numTasks={numTasks}
        />
      </div>
    );
  }
}

const mapStateToPRops = (state) => ({
  currentPage: getCurrentPage(state),
  currentFilter: getCurrentFilter(state),
  pastDueTasks: getPastDueTasks(state),
  pastScheduledTasks: getPastScheduledTasks(state)
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToPRops, mapDispatchToProps)(CompleteCellContent)
);
