import { useGroupedProjectsFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { useBoards } from 'appUtils/hooks/useBoards';

export const useProjectsByPortfolioFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  shouldUseDraft
} = {}) => {
  const { boardHash, getBoardLabel } = useBoards();

  const groupedMembersFilterValues = useGroupedProjectsFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'projectsByPortfolioFilter',
    filterListType: FilterListType.ProjectsByPortfolio,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    groupEntityHash: boardHash,
    getGroupEntityLabel: getBoardLabel
  };
};
