import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState, ReducerName } from 'reduxInfra/shared';
import { makeGetIsoState } from 'IsoStatesModule/selectors';
import { initialState, initialIsoState } from './reducers/leanApiReducer';
import { LeanApiState, LeanApiIsoState } from './types';

const getLeanApiState: Selector<RootState, LeanApiState> = (state) =>
  state[ReducerName.LeanApi] || initialState;

export const makeGetLeanApiIsoState = () =>
  createSelector(
    makeGetIsoState<LeanApiIsoState>(ReducerName.LeanApi, initialIsoState),
    (isoState) => isoState
  );

export const makeGetLeanApiIsoStatePartialProjectsHash = () =>
  createSelector(
    makeGetLeanApiIsoState(),
    (isoState) => isoState.dataHash.partialProjects
  );
