import cn from 'classnames';
import { ChangeEvent, useEffect, useCallback, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import { AccessRoles, EmploymentTypes, Invitee } from 'PermissionsModule/types';
import { permissionsPresentationUtils } from 'PermissionsModule/components/utils';
import { permissionsUtils } from 'PermissionsModule/utils';
import theme from 'theme';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import QBDownArrow from 'icons/QBDownArrow';
import {
  EmploymentTypeDropdown,
  EmploymentTypeDropdownProps
} from 'PermissionsModule/components/EmploymentTypeDropdown';
import { AccessLevelsDropdown } from 'PermissionsModule/components/AccessLevelsDropdown';
import { usePopperState } from 'appUtils/hooks/usePopperState';
import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { manageMemberRole } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import useCan from 'appUtils/hooks/useCan';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { getTeamMembersHashByEmail } from 'TeamsModule/selectors';
import { MANAGE_MEMBER_ROLE_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { isValidEmail as _isValidEmail } from 'appUtils/formatUtilsTyped';

const {
  employmentTypeToOptionContent,
  accessRoleToContent,
  getSecondaryContent
} = permissionsPresentationUtils;

const {
  getIsBaseMember,
  getIsInternalContractor,
  getIsExternalProjectContractor,
  getIsProjectGuest
} = permissionsUtils;

export interface MemberEditFormFieldsProps
  extends Pick<EmploymentTypeDropdownProps, 'employmentTypeOptions'> {
  formFields: Invitee;
  isDisabled?: boolean;
  onChange: (fields: {
    event: ChangeEvent<HTMLInputElement>;
    field: keyof Invitee;
  }) => void;
  onChangeTeamRole: (role: AccessRoles) => void;
  onChangeEmploymentType: (employmentType: EmploymentTypes) => void;
  onChangeAllFields?: (invitee: Invitee) => void;
}

export const MemberEditFormFields = ({
  formFields,
  isDisabled = false,
  employmentTypeOptions,
  onChange,
  onChangeTeamRole,
  onChangeEmploymentType,
  onChangeAllFields
}: MemberEditFormFieldsProps) => {
  const {
    anchorElement: accessLevelDropdownAnchorElement,
    open: openAccessLevelDropdown,
    close: closeAccessLevelDropdown
  } = usePopperState();

  const {
    anchorElement: employmentTypeDropdownAnchorElement,
    open: openEmploymentTypeDropdown,
    close: closeEmploymentTypeDropdown
  } = usePopperState();

  const teamMembersHashByEmail = useAppSelector(getTeamMembersHashByEmail);

  const isInternalContractorAvailable =
    employmentTypeOptions[EMPLOYMENT_TYPES.internalContractor].isAvailable ??
    false;
  const isExternalProjectContractorAvailable =
    employmentTypeOptions[EMPLOYMENT_TYPES.externalProjectContractor]
      .isAvailable ?? false;
  const isProjectGuestAvailable =
    employmentTypeOptions[EMPLOYMENT_TYPES.projectGuest].isAvailable ?? false;

  const {
    email,
    firstName,
    lastName,
    referenceNumber,
    employmentType,
    teamRole
  } = formFields;
  const accessLevelMetadata = permissionsUtils.getAccessLevelMetadata({
    teamRole: teamRole
  });

  const { accessRoleType } = accessLevelMetadata;

  const AccessRoleContent = accessRoleToContent[accessRoleType];
  const SecondaryContent = getSecondaryContent({
    metadata: accessLevelMetadata
  });

  const { newPermissionSettings, projectLevelPermissionFlag } =
    useFeatureFlags();

  const canEditMemberRole = useCan(manageMemberRole);

  const isValidEmail = useMemo(
    () => !email || _isValidEmail({ email }),
    [email]
  );

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const handleSelectNewRole = useCallback(
    (newRole: AccessRoles) => {
      onChangeTeamRole(newRole);
      closeAccessLevelDropdown();
    },
    [closeAccessLevelDropdown, onChangeTeamRole]
  );

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!onChangeAllFields) {
      onChange({ event, field: 'email' });
      return;
    }

    const teamMemberForEmail = teamMembersHashByEmail[value];

    if (teamMemberForEmail && teamMemberForEmail.role_ids[0]) {
      const isMembershipTypeOfFoundTeamMemberAllowedToSelect =
        getIsBaseMember(teamMemberForEmail) ||
        (isInternalContractorAvailable &&
          getIsInternalContractor(teamMemberForEmail)) ||
        (isExternalProjectContractorAvailable &&
          getIsExternalProjectContractor(teamMemberForEmail)) ||
        (isProjectGuestAvailable && getIsProjectGuest(teamMemberForEmail));

      if (isMembershipTypeOfFoundTeamMemberAllowedToSelect) {
        const { first_name, last_name } = teamMemberForEmail.account;

        onChangeAllFields({
          email: value,
          firstName: first_name,
          lastName: last_name,
          referenceNumber: teamMemberForEmail.employee_number,
          employmentType: teamMemberForEmail.employment_type,
          teamRole: teamMemberForEmail.role_ids[0]
        });
      } else {
        onChange({ event, field: 'email' });
      }
    } else {
      onChange({ event, field: 'email' });
    }
  };
  return (
    <>
      <RootContainer>
        <NameInputContainer>
          <div style={{ width: '49.5%' }}>
            <InputLabel>FIRST NAME</InputLabel>
            <Input
              type="text"
              value={firstName}
              disabled={isDisabled}
              placeholder="Type First Name"
              autoComplete="off"
              onChange={(event) => onChange({ event, field: 'firstName' })}
            />
          </div>
          <div style={{ width: '49.5%' }}>
            <InputLabel>LAST NAME</InputLabel>
            <Input
              type="text"
              value={lastName}
              disabled={isDisabled}
              placeholder="Type Last Name"
              autoComplete="off"
              onChange={(event) => onChange({ event, field: 'lastName' })}
            />
          </div>
        </NameInputContainer>
        <div>
          <InputLabel>WORK EMAIL ADDRESS</InputLabel>
          <Input
            type="email"
            value={email}
            disabled={isDisabled}
            placeholder="Type Work Email Address"
            autoComplete="off"
            className={cn({ error: !isValidEmail })}
            onChange={handleEmailChange}
          />
        </div>

        {newPermissionSettings && (
          <>
            <div>
              <InputLabel>REFERENCE NUMBER</InputLabel>
              <Input
                value={referenceNumber}
                disabled={isDisabled}
                placeholder="Type Reference Number (Optional)"
                autoComplete="off"
                onChange={(event) =>
                  onChange({ event, field: 'referenceNumber' })
                }
              />
            </div>
            <div>
              <InputLabel>MEMBERSHIP</InputLabel>
              <FormFieldTrigger
                onClick={(event) => {
                  if (!isDisabled) {
                    openEmploymentTypeDropdown({
                      event
                    });
                  }
                }}
              >
                <FormFieldTriggerContentContainer>
                  <FormFieldTriggerText>
                    {employmentTypeToOptionContent[employmentType]}
                  </FormFieldTriggerText>
                  <FormFieldTriggerIcon>
                    <QBDownArrow />
                  </FormFieldTriggerIcon>
                </FormFieldTriggerContentContainer>
              </FormFieldTrigger>
            </div>
            {(employmentType === EMPLOYMENT_TYPES.member ||
              employmentType === EMPLOYMENT_TYPES.internalContractor) && (
              <div>
                <InputLabel>ACCESS</InputLabel>
                <FormFieldTrigger
                  onClick={(event) => {
                    if (!isDisabled && canEditMemberRole) {
                      openAccessLevelDropdown({ event });
                    }
                  }}
                  data-for="app-tooltip"
                  data-effect="solid"
                  data-class="center"
                  data-tip={MANAGE_MEMBER_ROLE_TIP}
                  data-tip-disable={canEditMemberRole}
                >
                  <FormFieldTriggerContentContainer>
                    <FormFieldTriggerText>
                      {AccessRoleContent}
                      {projectLevelPermissionFlag && ` | ${SecondaryContent}`}
                    </FormFieldTriggerText>
                    <FormFieldTriggerIcon>
                      <QBDownArrow />
                    </FormFieldTriggerIcon>
                  </FormFieldTriggerContentContainer>
                </FormFieldTrigger>
              </div>
            )}
          </>
        )}
      </RootContainer>
      <AccessLevelsDropdown
        isOpen
        isSummaryView
        popoverTarget={accessLevelDropdownAnchorElement}
        isExcludeAdmin={employmentType !== EMPLOYMENT_TYPES.member}
        onSelect={handleSelectNewRole}
        onClose={closeAccessLevelDropdown}
      />
      <EmploymentTypeDropdown
        isOpen
        anchorEl={employmentTypeDropdownAnchorElement}
        employmentTypeOptions={employmentTypeOptions}
        onSelect={(employmentType) => {
          onChangeEmploymentType(employmentType);
          closeEmploymentTypeDropdown();
        }}
        onClose={() => {
          closeEmploymentTypeDropdown();
        }}
      />
    </>
  );
};

const RootContainer = styled.div`
  display: grid;
  gap: 14px;
`;

const InputLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${theme.colors.colorLightGray15};
`;

const Input = styled.input`
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorPaleGray8};
  padding: 5px 0px 5px 10px;
  width: 100%;
  font-size: 13px;
  line-height: 2;

  &::placeholder {
    color: ${theme.colors.colorLightGray15};
  }

  &:hover {
    border-color: ${theme.colors.colorRoyalBlue};
  }

  &.error {
    border-color: ${theme.colors.colorCalendarRed};
  }
`;

const NameInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormFieldTrigger = styled.div`
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  height: 35px;
  align-items: center;
  width: 100%;
  font-size: 13px;
  background-color: white;
  border: 0.25px solid ${theme.colors.colorPaleGray2};
  border-radius: 6px;
`;

const FormFieldTriggerContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const FormFieldTriggerText = styled.div`
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FormFieldTriggerIcon = styled.div``;
