import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const NewSaveIcon = ({
  width = '17',
  height = '16',
  strokeWidth = '1.2',
  color = theme.colors.colorBudgetBlue
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0.5H8.51937C8.67127 0.5 8.81492 0.569045 8.90981 0.687652L10.3904 2.53843C10.4614 2.62709 10.5 2.73725 10.5 2.85078V5V9C10.5 9.27614 10.2761 9.5 10 9.5H1C0.723858 9.5 0.5 9.27614 0.5 9V1C0.5 0.723858 0.723858 0.5 1 0.5H2.2H7.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M2.2002 0.5V3C2.2002 3.27614 2.42405 3.5 2.7002 3.5H7.0002C7.27634 3.5 7.5002 3.27614 7.5002 3V0.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default NewSaveIcon;
