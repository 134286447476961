import React from 'react';
import { StyledModalHeader, DoneButton } from './styles';
const AccessModalHeader = ({ renderTitle, title, closeModal }) => (
  <StyledModalHeader>
    {renderTitle ? renderTitle() : <div>{title}</div>}
    <DoneButton onClick={closeModal}>Done</DoneButton>
  </StyledModalHeader>
);

export default AccessModalHeader;
