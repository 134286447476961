import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Autocomplete from 'react-autocomplete';
import cn from 'classnames';
import styled from 'styled-components';
import { SelectBoardDropdownContainer } from '..';
import AdminTooltip from 'components/Tooltips/ContactAdminsTooltip';
import ProjectColorDot from 'components/ProjectColorDot';
import LoadingWheel from 'icons/LoadingWheel';
import { ARCHIVE_PROJECT_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const TooltipMessage = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

const ProjectNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const clientMenuStyle = {
  borderRadius: '0',
  background: '#fdfdfd',
  overflow: 'auto',
  top: '310px',
  left: '46px',
  zIndex: '50',
  maxHeight: '150px',
  width: '477px',
  minWidth: '477px',
  listStyle: 'none',
  padding: '10px 10px',
  fontSize: '14px',
  lineHeight: '28px',
  fontWeight: '400',
  boxShadow: '0 2px 4px 2px rgba(0, 0, 0, 0.15)',
  position: 'absolute'
};

const LoadingWheelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArchivedProjectButtonsContainer = styled.div`
  display: flex;
`;

class AddEditProjectForm extends React.Component {
  state = {
    clientList: this.props.clients
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  };

  sortClientList = (a, b, value) => {
    const aLower = a.client.toLowerCase();
    const bLower = b.client.toLowerCase();
    return aLower < bLower ? -1 : 1;
  };

  autoFillClient = () => {
    const { client } = this.props;

    const currentList = this.state.clientList
      .filter((listItem) => listItem.startsWith(client))
      .sort();
    if (client && currentList.length) {
      if (client.length !== currentList[0].length) {
        return currentList[0];
      }
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    const { closeAllModals } = this.props;
    closeAllModals();
  };

  handleDelete = (e) => {
    const { userIsAdmin, deleteModal } = this.props;
    if (userIsAdmin) {
      deleteModal(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  renderEditButtons = () => {
    const {
      isArchived,
      handleArchive,
      deleteModal,
      modalProjectIsPersonal,
      userIsAdmin,
      canArchiveProject
    } = this.props;

    if (isArchived) {
      return (
        <ArchivedProjectButtonsContainer>
          <button type="button" key="archive" onClick={handleArchive}>
            Unarchive
          </button>
          <button
            type="button"
            key="delete"
            className="delete"
            onClick={this.handleDelete}
            data-for="admin-tooltip"
            data-tip={`Only Admin(s) can delete projects`}
          >
            Delete
          </button>
          {!userIsAdmin && (
            <AdminTooltip>
              <TooltipMessage>
                {`Only Admin(s) can delete projects`}
              </TooltipMessage>
            </AdminTooltip>
          )}
        </ArchivedProjectButtonsContainer>
      );
    } else if (modalProjectIsPersonal) {
      return (
        <button
          type="button"
          key="delete"
          className="personal-delete"
          onClick={deleteModal}
        >
          Delete
        </button>
      );
    } else {
      return (
        <div
          data-for="app-tooltip"
          data-class="center"
          data-tip={ARCHIVE_PROJECT_TIP}
          data-tip-disable={canArchiveProject}
          data-effect="solid"
          style={{ width: 'fit-content' }}
        >
          <button
            type="button"
            onClick={handleArchive}
            disabled={!canArchiveProject}
          >
            Archive
          </button>
        </div>
      );
    }
  };

  renderTextOnDoneButton = () =>
    this.props.isEditForm
      ? this.props.isAnythingChanged && this.props.isAnythingChanged()
        ? 'Save'
        : 'Done'
      : 'Create';

  render() {
    const {
      isEditForm,
      handleSubmit,
      title,
      description,
      number,
      handleChange,
      group,
      groupList,
      changeSelectedGroup,
      disableSave,
      modalProjectIsPersonal,
      handleClientSelect,
      jobNumbers,
      client,
      projectId,
      isNewProject,
      isCreatingOrUpdatingProject
    } = this.props;

    const jobs = jobNumbers.map((n) => n.toString().concat(' <br>')).join(' ');
    const tooltipText = 'Recently used <br>'.concat(jobs);

    const fillStyle = {
      zIndex: '50',
      position: 'fixed',
      top: '167.505px',
      color: 'rgba(0, 0, 0, 0.15)',
      left: '51.05px'
    };
    return (
      <form onSubmit={handleSubmit}>
        <div className="add-edit-project-modal-project">
          <div style={{ display: 'flex' }}>
            {!modalProjectIsPersonal && (
              <div className="form-project-group">
                <label className="folder-label">Portfolio</label>
                <SelectBoardDropdownContainer
                  group={group}
                  groupList={groupList}
                  changeSelectedGroup={changeSelectedGroup}
                />
              </div>
            )}
            <div
              className={cn('form-project-title', {
                personal: modalProjectIsPersonal
              })}
            >
              {!modalProjectIsPersonal && (
                <label className="project-label">Project</label>
              )}
              <ProjectNameContainer>
                {!isNewProject && (
                  <ProjectColorDot
                    size={10}
                    hasColorPicker
                    projectId={projectId}
                    pickerLocation="add-edit-project-form"
                  />
                )}
                <input
                  type="text"
                  defaultValue={title}
                  name="title"
                  onChange={handleChange}
                  placeholder="Project Title"
                  required
                  pattern=".*\S+.*"
                  onKeyPress={this.handleKeyPress}
                  autoComplete="off"
                  autoFocus={!isEditForm}
                />
              </ProjectNameContainer>
            </div>
          </div>
          <hr />
          <div>
            <ReactTooltip
              effect="solid"
              id="job-number-list"
              place="bottom"
              type="light"
              class="tooltip-theme job-number-list"
            />
            <input
              type="text"
              defaultValue={description}
              name="description"
              onChange={handleChange}
              placeholder="Description"
              className="form-project-description"
              onKeyPress={this.handleKeyPress}
              autoComplete="off"
              autoFocus={isEditForm}
            />
            <hr />
            <input
              type="text"
              defaultValue={number}
              autoComplete="off"
              name="number"
              onChange={handleChange}
              placeholder="Project ID/Number"
              className="form-project-number"
              onKeyPress={this.handleKeyPress}
              data-tip={tooltipText}
              data-for="job-number-list"
              data-multiline={true}
              data-event="focus"
              data-event-off="blur"
              data-offset="{ 'top': -10 }"
            />
            <hr />
            {!modalProjectIsPersonal && (
              <>
                <Autocomplete
                  wrapperProps={{ className: 'autocomplete-wrapper' }}
                  getItemValue={(item) => item.client}
                  ref={(el) => (this.clientInput = el)}
                  items={this.state.clientList.map((client, index) => ({
                    client,
                    index
                  }))}
                  value={client}
                  inputProps={{
                    placeholder: 'Client',
                    className: 'client-input',
                    onKeyPress: this.handleKeyPress
                  }}
                  shouldItemRender={({ client }, value) =>
                    client.toLowerCase().startsWith(value.toLowerCase())
                  }
                  renderItem={({ client, index }, isHighlighted) => (
                    <div
                      style={{
                        background: isHighlighted ? '#f2f2f2' : '#fdfdfd',
                        paddingLeft: '15px'
                      }}
                      key={client + index}
                    >
                      {client}
                    </div>
                  )}
                  sortItems={this.sortClientList}
                  onChange={(e, val) => {
                    handleClientSelect(val);
                  }}
                  onSelect={(val) => {
                    handleClientSelect(val);
                    this.autoFillClient();
                  }}
                  menuStyle={
                    this.state.clientList.length
                      ? clientMenuStyle
                      : { display: 'none' }
                  }
                />
                {this.clientInput ? (
                  <span className="client-autofill" style={fillStyle}>
                    {this.state.client}
                  </span>
                ) : null}
                <hr />
                {/* <AddFieldText>Add field</AddFieldText> hide this for now */}
              </>
            )}
          </div>
        </div>
        <div
          className="add-edit-project-modal-footer"
          style={{ display: 'flex' }}
        >
          <div className="add-edit-project-modal-footer-delete">
            {isEditForm ? this.renderEditButtons() : null}
          </div>
          <div className="add-edit-project-modal-footer-submit">
            <button
              type="button"
              className="cancel-button"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
            <button
              className="submit-button"
              type="submit"
              disabled={
                (!group && !modalProjectIsPersonal) ||
                (isEditForm && disableSave)
              }
            >
              {isCreatingOrUpdatingProject ? (
                <LoadingWheelContainer>
                  <LoadingWheel width={18} height={18} />
                </LoadingWheelContainer>
              ) : (
                this.renderTextOnDoneButton()
              )}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

AddEditProjectForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isEditForm: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  changeSelectedGroup: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groupList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  group: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  deleteModal: PropTypes.func
};

export default AddEditProjectForm;
