import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getMe } from 'selectors';
import { getActiveTeamSalaries, getOverhead } from 'BudgetModule/selectors';
import {
  createMemberSalary,
  updateMemberSalary
} from 'BudgetModule/actionCreators';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer
} from 'components/styles';
import {
  StyledModal,
  Header,
  Body,
  DoneButton,
  LabelText
} from './shared/styles';
import Input from './shared/Input';
import PencilIcon from 'icons/SmallOutlinedPencil';
import moment from 'moment';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { FormattedCurrencyByTeam } from 'components/FormatCurrency';
import { formatCurrencySymbol } from 'components/FormatCurrency/utils';
import { useCurrencyFormatterByTeam } from 'appUtils/hooks/useFormatCurrency';
import { useAppSelector } from 'reduxInfra/hooks';

const InputsContainer = styled.div`
  display: flex;
`;

const DivideSign = styled.div`
  width: 20px;
  align-self: flex-end;
  padding-bottom: 7px;
  text-align: center;
`;

const Table = styled.table`
  table-layout: auto;
  width: 95%;
  margin: auto;
  td {
    color: ${theme.colors.colorMediumGray9};
  }
  td:not(:first-child) {
    text-align: center;
  }
  .header-labels th {
    color: ${theme.colors.colorSemiDarkGray2};
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }
  .input-row td:not(:first-child) {
    padding-top: 6px;
  }
  .overhead-row {
    height: 57px;
    td:last-child {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .total-row {
    height: 40px;
    font-size: 15px;
    color: ${theme.colors.colorMediumGray9};
    font-weight: 600;
  }
`;

const Divider = styled.hr`
  width: 300px;
  border-width: 2px;
  position: absolute;
  bottom: 165px;
`;

const InfoText = styled.span`
  color: ${theme.colors.colorLightGray15};
  font-size: 11px;
  position: absolute;
  bottom: 12px;
`;

const Pencil = styled(PencilIcon)`
  margin-left: 5px;
  margin-top: 2px;
  position: absolute;
  cursor: pointer;
`;

/* ------------------------------------------------------------------------ */

const SalaryModal = ({ isOpen, toggle, member = {}, handleOverheadClick }) => {
  const overheadFactor = useAppSelector(getOverhead).overhead_factor || 1;
  const activeTeamSalaries = useAppSelector(getActiveTeamSalaries);
  const currentSalary = activeTeamSalaries[member.id];
  const [salary, setSalary] = useState(currentSalary?.annual_rate || 0);
  const [hours, setHours] = useState(currentSalary?.hours_per_year || 0);
  const [actualHourlyCost, setActualHourlyCost] = useState(
    currentSalary?.actual_hourly_rate || ''
  );
  const hourlyRate = (salary / hours).toFixed(2);
  const costRate = hourlyRate * overheadFactor;
  const me = useAppSelector(getMe);
  const actualHourlyInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleSalaryChange = (values) => {
    setSalary(values.value);
  };

  const handleHoursChange = (values) => {
    setHours(values.value);
  };

  const handleActualHourlyCostChange = (values) => {
    setActualHourlyCost(values.value);
  };

  const hasNewValues =
    +salary !== +currentSalary?.annual_rate ||
    +hours !== +currentSalary?.hours_per_year ||
    +actualHourlyCost !== +currentSalary?.actual_hourly_rate;
  const isValid = !!actualHourlyCost;

  const handleConfirm = () => {
    if (isValid) {
      const currentSalary = activeTeamSalaries[member.id];
      const startDate = moment().format('YYYY-MM-DD');
      const payload = {
        activeRateType: 'annual',
        actualHourlyRate: actualHourlyCost,
        annualRate: salary,
        description: '',
        hourlyRate: hourlyRate,
        hoursPerYear: hours,
        startDate,
        overheadFactor: overheadFactor
      };
      if (currentSalary?.start_date === startDate) {
        dispatch(
          updateMemberSalary({
            ...payload,
            id: currentSalary.id,
            teamMembershipId: member.id
          })
        );
      } else {
        dispatch(
          createMemberSalary({
            ...payload,
            teamMembershipId: member.id
          })
        );
      }
    }
    toggle();
  };

  const resetActualHourlyInputOutline = () => {
    if (actualHourlyInputRef.current) {
      actualHourlyInputRef.current.style.outline = '';
    }
  };

  const handleError = () => {
    actualHourlyInputRef.current.style.outline = `1px solid ${theme.colors.colorCalendarRed}`;
    setTimeout(() => {
      resetActualHourlyInputOutline();
    }, 2000);
  };

  useEffect(() => {
    rebuildTooltip();
  }, []);

  // formatting currency symbol needs to use the currency formatter hook directly
  // using the currency formatter component results in symbol displayed as [object]
  const currencySymbolPrefixFormatter = useCurrencyFormatterByTeam({});

  const currencySymbolPrefix = formatCurrencySymbol(
    currencySymbolPrefixFormatter,
    0
  );

  return (
    <StyledModal isOpen={isOpen} toggle={toggle}>
      <Header>
        <MemberInitials
          member={member}
          size="medium"
          classes={`sidebar-member-initials selected ${
            me?.id === member.account?.id ? 'logged' : 'regular'
          }-member-no-hover`}
          id={member.id}
        />
        <span style={{ marginLeft: 9 }}>{member.account?.name}</span>
      </Header>
      <Body>
        <Table>
          <tbody>
            <tr className="header-labels">
              <th>Yearly Pay</th>
              <th />
              <th>Hours/Year</th>
              <th />
              <th>Pay Rate</th>
            </tr>
            <tr className="input-row">
              <td colSpan={3}>
                <InputsContainer>
                  <Input
                    id="salary"
                    width={96}
                    value={salary}
                    onValueChange={handleSalaryChange}
                    prefix={currencySymbolPrefix}
                  />
                  <DivideSign>÷</DivideSign>
                  <Input
                    id="hours"
                    width={81}
                    value={hours}
                    suffix="h"
                    onValueChange={handleHoursChange}
                  />
                </InputsContainer>
              </td>
              <td>=</td>
              <td>
                {salary / hours !== Infinity && !isNaN(salary / hours) ? (
                  <>
                    <FormattedCurrencyByTeam currencyValue={salary / hours} />/
                    / h
                  </>
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr className="overhead-row">
              <LabelText as="td" colSpan={3}>
                Overhead Factor
              </LabelText>
              <td>x</td>
              <td>
                {overheadFactor}
                <Pencil size={12} onClick={handleOverheadClick} />
              </td>
            </tr>
            <Divider />
            <tr className="total-row">
              <LabelText as="td" colSpan={3}>
                Total Hourly Cost
              </LabelText>
              <td>=</td>
              <td>
                {costRate !== Infinity && !isNaN(costRate) ? (
                  <>
                    <FormattedCurrencyByTeam currencyValue={costRate} />/ h
                  </>
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr className="total-row">
              <LabelText as="td" colSpan={3}>
                Enter Total Hourly Cost
              </LabelText>
              <td colSpan={2}>
                <Input
                  id="actualHours"
                  width={81}
                  value={actualHourlyCost}
                  suffix="/h"
                  prefix={currencySymbolPrefix}
                  onFocus={resetActualHourlyInputOutline}
                  onValueChange={handleActualHourlyCostChange}
                  getInputRef={(ref) => (actualHourlyInputRef.current = ref)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Body>
      <ButtonContainer width={152} style={{ margin: '20px 0 12px' }}>
        <TextButtonWithBorder
          isHidden={!hasNewValues}
          onClick={toggle}
          fontWeight={400}
        >
          Cancel
        </TextButtonWithBorder>
        <BlueSubmitButton
          data-for="app-tooltip"
          data-tip={
            hasNewValues && !isValid ? 'Please enter a total hourly cost' : ''
          }
          data-effect="solid"
          opacity={hasNewValues && !isValid ? '0.6' : undefined}
          onClick={
            !hasNewValues ? toggle : isValid ? handleConfirm : handleError
          }
          fontWeight={400}
          padding="0.4rem 0.8rem"
        >
          {hasNewValues ? 'Save' : 'Done'}
        </BlueSubmitButton>
      </ButtonContainer>
      <InfoText>
        Profit is excluded and calculated separately on project budgets.
      </InfoText>
    </StyledModal>
  );
};

export default SalaryModal;
