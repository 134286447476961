import {
  makeAuthorBasedVerification,
  makeVerification
} from '../makeVerification';

export const verifyEditWorkPlans = makeAuthorBasedVerification(
  'edit_activity_phase_schedule_bars',
  'edit_activity_phase_schedule_bars_other'
);

export const verifyPersonalEditWorkPlans = makeVerification(
  'edit_activity_phase_schedule_bars'
);
