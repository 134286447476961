import { fetchEntity, changeEntity } from './generics';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';

export function* fetchTaskPriorities(action) {
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchTaskPriorities,
    api.fetchTaskPriorities,
    undefined,
    [token],
    action
  );
}

// these are defunct, remove
export function* createTaskPriority(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.createTaskPriority,
    api.createTaskPriority,
    [token, id, payload],
    action
  );
}
export function* updateTaskPriority(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTaskPriority,
    api.updateTaskPriority,
    [token, id, payload],
    action
  );
}

export function* deleteTaskPriority(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.deleteTaskPriority,
    api.deleteTaskPriority,
    [token, id],
    action
  );
}
