import React from 'react';
import BoardListItem from './BoardListItem';
import { Draggable } from 'react-beautiful-dnd';

const DraggableBoardList = ({
  list,
  selectedGroupId,
  onClickListItem,
  editGroup
}) => {
  return list
    ? list.map((board, index) => {
        const isSelected = selectedGroupId === board && board.id;
        if (!board) {
          return null;
        }
        return (
          <Draggable key={board.id} draggableId={`${board.id}`} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style
                }}
              >
                <BoardListItem
                  key={board.id}
                  board={board}
                  index={index}
                  isSelected={isSelected}
                  onClick={onClickListItem}
                  editGroup={editGroup}
                />
              </div>
            )}
          </Draggable>
        );
      })
    : null;
};

export default React.memo(DraggableBoardList);
