import { useCallback } from 'react';
import uniq from 'lodash/uniq';
import {
  getProjectIdsThatMatchTitleAndNumber,
  getRowValuesForEntityType
} from './utils';

export const usePhaseOptionsGetter = ({
  existingPhases,
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  newPhaseNames,
  entityType
}) => {
  return useCallback(
    (row) =>
      getPhaseOptions({
        row,
        existingPhases,
        existingProjectsHashByTitle,
        existingProjectsHashByNumber,
        newPhaseNames,
        entityType
      }),
    [
      existingPhases,
      existingProjectsHashByNumber,
      existingProjectsHashByTitle,
      newPhaseNames,
      entityType
    ]
  );
};

/* ------------------------------------ - ----------------------------------- */

export const getPhaseOptions = ({
  row,
  existingPhases,
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  newPhaseNames,
  entityType
}) => {
  const { enteredProjectTitle, enteredProjectNumber, enteredPhaseNumber } =
    getRowValuesForEntityType(row, entityType);

  if (!(enteredProjectTitle && enteredProjectNumber)) {
    return [];
  }

  const projectIdsThatMatchTitleAndNumber =
    getProjectIdsThatMatchTitleAndNumber({
      existingProjectsHashByTitle,
      existingProjectsHashByNumber,
      enteredProjectTitle,
      enteredProjectNumber
    });

  const validPhaseOptions = uniq([
    // user input names under new project title and project number combination
    '',
    ...Object.keys(
      newPhaseNames.current[enteredProjectTitle]?.[enteredProjectNumber] || {}
    ),
    // phase names that match project title and project number and phase number (if entered)
    ...(Object.keys(projectIdsThatMatchTitleAndNumber).length
      ? existingPhases
          .filter(
            (phase) =>
              projectIdsThatMatchTitleAndNumber?.[phase.project_id] &&
              (!enteredPhaseNumber || phase.phase_number === enteredPhaseNumber)
          )
          .map((phase) => phase.name)
      : [])
  ]);

  return validPhaseOptions;
};
