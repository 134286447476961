import * as constants from '../constants';
import keyBy from 'lodash/keyBy';

export const initialState = {
  groupsHash: {},
  groupsOrder: [],
  isFetching: false
};

const byId = (item) => item && item.id;
const holidayGroups = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_HOLIDAY_GROUPS.TRIGGER: {
      return {
        ...state,
        isFetching: true
      };
    }
    case constants.FETCH_HOLIDAY_GROUPS.SUCCESS: {
      const { holiday_groups, holiday_group_order } = payload.response;
      return {
        ...state,
        groupsHash: keyBy(holiday_groups, byId),
        groupsOrder: holiday_group_order,
        isFetching: false
      };
    }
    case constants.FETCH_HOLIDAY_GROUPS.FAILURE: {
      return {
        ...state,
        isFetching: false
      };
    }
    default:
      return state;
  }
};

export default holidayGroups;
