import { FILTER_SECTIONS } from 'appConstants/filters';

export const DISPLAY_FORMAT = {
  HOURS: 'HOURS', // To match with DISPLAY_FORMAT constants of Budget Report
  DOLLARS: 'DOLLARS'
};

export const BUDGET_BY = {
  TOTAL_HOURS: 'total hours',
  MEMBER_TIME_BUDGET: 'member estimates'
};

export const PROJECT = 'project';
export const PHASE = 'phase';
export const SCOPE = 'scope';
export const WORKCATEGORY = 'workcategory';

export const TABLE_TYPES = {
  TABLE: 'table',
  CHART: 'chart'
};

export const TABLE_TYPES_HASH = {
  [TABLE_TYPES.TABLE]: { value: TABLE_TYPES.TABLE, label: 'Table' }
  // [TABLE_TYPES.CHART]: {
  //   value: TABLE_TYPES.CHART,
  //   label: 'Chart',
  //   dataTip: 'View Only<br/> Edit from Table View'
  // }
};

export const TABLE_TYPES_ARRAY = [TABLE_TYPES.TABLE, TABLE_TYPES.CHART];

export const BUDGET_MODAL_FILTER_SECTIONS_BY_TABLE_TYPE = {
  [TABLE_TYPES.CHART]: [
    // Future todo, once BE API ready, can remove comments to show filters for chart view
    // FILTER_SECTIONS.PHASES,
    // FILTER_SECTIONS.MEMBERS,
    // FILTER_SECTIONS.BILLABLE
  ]
};

export const CONTRACT_TYPES = {
  NOT_BILLABLE: 'Not Billable',
  HOURLY: 'Hourly',
  FIXED_FEE: 'Fixed Fee',
  BILLABLE: 'Billable',
  INTERNAL: 'Internal'
};
