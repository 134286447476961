import { FilterField, FilterListType } from 'FilterModule/constants';
import {
  FilterContextProvider,
  useFilterContext
} from 'FilterModule/FilterContextProvider';
import {
  defaultNumberArrayFieldSchema,
  makeDefaultBooleanFieldSchema,
  makeDefaultFilterOrderFieldSchema
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import {
  CurrentFilter,
  FilterFieldSchema,
  FilterLevelSchemas
} from 'FilterModule/types';
import { Page } from 'models/filter';
import { ComponentProps } from 'react';

export const plannerPageViewFilterLevelName = serializeFilterLevelName({
  level1: Page.WorkloadPlanner,
  level3: 'pageView'
});

type PlannerPageViewFilterFieldSchemas = {
  [FilterField.account_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.position_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.work_group_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.office_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.discipline_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.isProjectsWithUnassignedRoles]: ReturnType<
    typeof makeDefaultBooleanFieldSchema
  >;

  [FilterField.filterOrder]: FilterFieldSchema<
    FilterListType,
    FilterListType[],
    { fieldOverride?: string }
  >;
};

const plannerPageViewFilterFieldSchemas: PlannerPageViewFilterFieldSchemas = {
  [FilterField.account_ids]: defaultNumberArrayFieldSchema,
  [FilterField.position_ids]: defaultNumberArrayFieldSchema,
  [FilterField.work_group_ids]: defaultNumberArrayFieldSchema,
  [FilterField.office_ids]: defaultNumberArrayFieldSchema,
  [FilterField.discipline_ids]: defaultNumberArrayFieldSchema,
  [FilterField.isProjectsWithUnassignedRoles]: makeDefaultBooleanFieldSchema({
    defaultValue: null
  }),

  // filterOrder
  [FilterField.filterOrder]: makeDefaultFilterOrderFieldSchema({
    defaultValue: [],
    optionsArray: [
      FilterListType.Members,
      FilterListType.Positions,
      FilterListType.Departments,
      FilterListType.Offices,
      FilterListType.Disciplines,
      FilterListType.ShowProjectsWithUnassignedRoles
    ]
  })
};

const initialPlannerPageViewFilterLevelSchemas: FilterLevelSchemas = {
  [plannerPageViewFilterLevelName]: {
    fieldSchemas: plannerPageViewFilterFieldSchemas
  }
};

export const pageViewFilterContextProviderProps: ComponentProps<
  typeof FilterContextProvider
> = {
  filterSchemaParams: {
    pageName: Page.WorkloadPlanner,
    level3: 'pageView',
    initialFilterLevelSchemas: initialPlannerPageViewFilterLevelSchemas
  }
};

type PlannerPageViewCurrentFilterType =
  CurrentFilter<PlannerPageViewFilterFieldSchemas>;
export const usePlannerPageViewFilterContext = () =>
  useFilterContext<PlannerPageViewCurrentFilterType>();
