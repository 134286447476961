import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { Page } from 'models/filter';
import { FilterLevelSchemas, CurrentFilter } from 'FilterModule/types';
import {
  makeDefaultMemberCrossFiltersFieldSchemas,
  makeDefaultProjectCrossFiltersFieldSchemas,
  MembersCrossFilterFieldSchemas,
  ProjectsCrossFilterFieldSchemas
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { VIEW_BY } from 'appConstants/workload';

const workloadFilterName = serializeFilterLevelName({
  level1: Page.WorkloadPlanner,
  level2: VIEW_BY.MEMBERS
});

export const plannerFilterName = serializeFilterLevelName({
  level1: Page.WorkloadPlanner,
  level2: VIEW_BY.PROJECTS
});

// -----------------------------------------------------------------------------
//                              workloadFieldSchemas
// -----------------------------------------------------------------------------

/**
 * With guaranteed filter fields for Workload
 */
type WorkloadFieldSchemas = MembersCrossFilterFieldSchemas;

// schema
const workloadFieldSchemas: WorkloadFieldSchemas = {
  ...makeDefaultMemberCrossFiltersFieldSchemas({
    selectionLimit: 50
  })
};

// -----------------------------------------------------------------------------
//                              plannerFieldSchemas
// -----------------------------------------------------------------------------

/**
 * With guaranteed filter fields for Planner
 */
type PlannerFieldSchemas = ProjectsCrossFilterFieldSchemas;

// note: Planner currently uses old infra for the project selector (VirtualFilter).
// therefore the stacked filters are not crossFieldDependencies, and there is no mainFilterListType here

const plannerFieldSchemas: PlannerFieldSchemas =
  makeDefaultProjectCrossFiltersFieldSchemas();

/* ------------------------ initialFilterLevelSchemas ----------------------- */

export const initialFilterLevelSchemas: FilterLevelSchemas = {
  // Workload (member view)
  [workloadFilterName]: {
    fieldSchemas: workloadFieldSchemas
  },
  [plannerFilterName]: {
    fieldSchemas: plannerFieldSchemas
  }
};

/* ---------------------------- CurrentFilterType --------------------------- */

export type WorkloadCurrentFilterType = CurrentFilter<WorkloadFieldSchemas>;
export const useWorkloadFilterContext = () =>
  useFilterContext<WorkloadCurrentFilterType>();

export type PlannerCurrentFilterType = CurrentFilter<PlannerFieldSchemas>;
export const usePlannerFilterContext = () =>
  useFilterContext<PlannerCurrentFilterType>();
