import { createSelector, Selector } from '@reduxjs/toolkit';
import { BudgetSettingsState } from 'BudgetModule/reducers/budgetSettings';
import { ReducerName, RootState } from 'reduxInfra/shared';
import { initialState } from '../reducers/budgetSettings';

export const getBudgetSettingsState: Selector<
  RootState,
  BudgetSettingsState
> = (state) => state[ReducerName.BudgetSettings] ?? initialState;

export const getTeamBudgetSettingsHash = createSelector(
  getBudgetSettingsState,
  (budgetSettingsState) => budgetSettingsState.teamSettings
);
