import React from 'react';
import BulkActivitiesDropdown from './BulkActivitiesDropdown';

const PhaseActivitiesDropdown = ({
  target,
  handleSelect,
  handleClose,
  renderHeader,
  activities,
  phase,
  className
}) => {
  return (
    <BulkActivitiesDropdown
      target={target}
      handleSelect={(item) => handleSelect({ activity: item })}
      handleClose={handleClose}
      renderHeader={renderHeader}
      parentActivities={activities}
      phase={phase}
      listWidth={200}
      searchPlaceholder={'Type or select Work Category'}
      popoverClassName="activities-dropdown"
      placeholderSize={'12px'}
      className={className}
    />
  );
};

export default PhaseActivitiesDropdown;
