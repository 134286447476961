import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadDashboardContainer from 'DashboardModule/components/LoadDashboardContainer';
import withRouterParams from './WithRouterParams';
import DashboardNavigationTracker from 'views/Navigation/DashboardNavigationTracker';

class DashboardRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    return (
      <LoadDashboardContainer {...this.props}>
        <DashboardNavigationTracker />
      </LoadDashboardContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardRouter))
);
