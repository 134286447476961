import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import { UPDATE_ACTIVITY_PHASE_MEMBERSHIP } from 'ActivityPhaseModule/actionCreators/activityPhaseMembership';
import { formatNextActivityPhaseMembershipOfActivityPhase } from 'ActivityPhaseModule/reducers/utils';
export const initialState = {
  phaseIds: [],
  phaseOrder: [],
  phases: {},
  phaseHash: {},
  phaseTotals: {},
  projectTotals: {},
  phasesByProject: {},
  fetchedPhasesProjectIds: {},
  fetchedTotalsProjectIds: {},
  phaseIdsByFilter: {},
  phaseNames: [],
  phasesByProjectIdsOrder: [],
  fetching: false,
  boardFetching: false,
  offset: 0,
  totalsOffset: 0,
  totalProjectCount: null,
  reportTotals: {},
  reportTotalsOrder: [],
  reportTotalsCount: null,
  reportTotalsOffset: 0,
  isLoadingReportTotals: true,
  phaseTemplateDropdownOpen: false,
  phaseTemplateModalOpen: false,
  modalPhaseTemplateId: null,
  phaseModalOpen: false,
  modalPhaseId: null,
  isCustomPhase: false,
  phaseProjectId: null,
  phaseName: null,
  isArchived: false,
  modalProjectId: null,
  modalPhaseName: null,
  isEditInfoModal: false,
  isDeletePhase: false,
  prevModal: null,
  predictedPhase: null,
  predictedPhaseId: null,
  isAllPhases: null,
  isAddPhase: false,
  isAddMilestone: false,
  isFetchingPhaseNames: false,
  hasFetchedPhaseNames: false,
  isEditActivityPhaseModalOpen: false,
  activityPhaseModalOpen: false
};
const byId = (item) => item.id;
const phases = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_PHASE_TOTALS.TRIGGER: {
      const { projectIds, filterStateId } = action.payload;
      // when filterStateId is given, handled in projectAccounts reducer
      if (filterStateId) return state;

      return {
        ...state,
        fetchedTotalsProjectIds: {
          ...state.fetchedTotalsProjectIds,
          ...keyBy(projectIds)
        }
      };
    }
    case constants.FETCH_PHASE_TOTALS.SUCCESS: {
      const { initial, filterStateId } = action.payload.requestPayload;
      // when filterStateId is given, handled in projectAccounts reducer
      if (filterStateId) return state;

      const { budgets } = action.payload.response;
      const [budget] = budgets;
      const { phases, ...rest } = budget || {};
      const { projectId } = action.payload.requestPayload;

      const phaseTotalsForOtherProjects = pickBy(
        state.phaseTotals,
        (phase) => phase.project_id !== projectId
      );
      // for clearing the budget table when being filtered and no results
      if (!budget && initial) {
        return {
          ...state,
          phaseTotals: phaseTotalsForOtherProjects,
          projectTotals: {
            ...state.projectTotals,
            [projectId]: {
              estimated: 0,
              total: 0,
              ...state.projectTotals[projectId],
              spent: {},
              planned: {},
              estimated_sum: {},
              account_totals: [],
              fee: {},
              over_budget: [],
              phases: []
            }
          }
        };
      }

      return {
        ...state,
        phaseTotals: {
          ...(initial ? phaseTotalsForOtherProjects : state.phaseTotals),
          ...keyBy(
            phases.map((phase) => ({ ...phase, project_id: projectId })),
            (item) => item && item.phase_id
          )
        },
        projectTotals: {
          ...state.projectTotals,
          [projectId]: rest
        }
      };
    }
    case constants.FETCH_PHASE_TOTALS_BY_BOARD.TRIGGER: {
      const { offset, limit, projectIds } = action.payload;

      const newState = {
        ...state,
        boardFetching: true,
        totalsOffset:
          (offset !== undefined ? offset : state.offset) + (limit || 0),
        fetchedTotalsProjectIds: {
          ...state.fetchedTotalsProjectIds,
          ...keyBy(projectIds)
        }
      };
      return newState;
    }
    case constants.FETCH_PHASE_TOTALS_BY_BOARD.SUCCESS: {
      const { budgets } = action.payload.response;
      const projectTotals = budgets.reduce((hash, cur) => {
        const { phases, ...projectTotal } = cur;
        hash[cur.project_id] = projectTotal;
        return hash;
      }, {});

      const phaseTotals = flatten(
        budgets.map((project) => project.phases)
      ).reduce((hash, cur) => {
        hash[cur.phase_id] = cur;
        return hash;
      }, {});
      return {
        ...state,
        boardFetching: false,
        projectTotals: {
          ...state.projectTotals,
          ...projectTotals
        },
        phaseTotals: {
          ...state.phaseTotals,
          ...phaseTotals
        }
      };
    }
    case constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.TRIGGER: {
      const { initial } = action.payload;
      return {
        ...state,
        isLoadingReportTotals: true,
        reportTotals: initial ? {} : state.reportTotals,
        reportTotalsCount: initial ? null : state.reportTotalsCount,
        reportTotalsOrder: initial ? [] : state.reportTotalsOrder
      };
    }
    case constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.SUCCESS: {
      const { offset, limit } = action.payload.requestPayload;
      const { budgets, project_count } = action.payload.response;
      const projectTotals = budgets.reduce((hash, cur) => {
        const { phases, ...projectTotal } = cur;
        hash[cur.project_id] = projectTotal;
        return hash;
      }, {});

      const phaseTotals = flatten(
        budgets.map((project) => project.phases)
      ).reduce((hash, cur) => {
        hash[cur.phase_id] = cur;
        return hash;
      }, {});
      return {
        ...state,
        boardFetching: false,
        projectTotals: {
          ...state.projectTotals,
          ...projectTotals
        },
        phaseTotals: {
          ...state.phaseTotals,
          ...phaseTotals
        },
        reportTotalsOrder: Array.from(
          new Set([
            ...state.reportTotalsOrder,
            ...budgets.map((budget) => budget.project_id)
          ])
        ),
        reportTotals: {
          ...state.reportTotals,
          ...keyBy(budgets, (budget) => budget.project_id)
        },
        reportTotalsOffset:
          (offset !== undefined ? offset : state.reportTotalsOffset) +
          (limit || 0),
        reportTotalsCount: project_count,
        isLoadingReportTotals: false
      };
    }
    case constants.FETCH_PHASES_BY_PROJECT_IDS.TRIGGER: {
      const { offset, limit, projectIds, all } = action.payload;
      return {
        ...state,
        fetching: all || projectIds?.length,
        offset: (offset !== undefined ? offset : state.offset) + (limit || 0),
        fetchedPhasesProjectIds: {
          ...state.fetchedPhasesProjectIds,
          ...keyBy(projectIds)
        }
      };
    }
    case constants.FETCH_PHASES_BY_PROJECT_IDS.SUCCESS: {
      const { projects, total_count } = action.payload.response;
      const { projectIds } = action.payload.requestPayload; // if exists, not a call to all, should not populate all order or affect offset/limit

      const projectsById = keyBy(projects, byId);
      const flatPhases = flatten(
        Object.values(projectsById).map((project) => project.phases)
      );
      const newPhasesByProjectIdOrder =
        projectIds && projectIds.length
          ? state.phasesByProjectIdsOrder
          : Array.from(
              new Set([
                ...state.phasesByProjectIdsOrder,
                ...projects.map((project) => project.id)
              ])
            );
      return {
        ...state,
        fetchedPhasesProjectIds: {
          ...state.fetchedPhasesProjectIds,
          ...projectsById
        },
        phasesByProject: {
          ...state.phasesByProject,
          ...projectsById
        },
        phaseHash: {
          ...state.phaseHash,
          ...keyBy(flatPhases, byId)
        },
        phasesByProjectIdsOrder: newPhasesByProjectIdOrder,
        fetching: false,
        totalProjectCount: total_count || state.totalProjectCount
      };
    }
    case constants.FETCH_PHASES.TRIGGER: {
      return {
        ...state,
        fetching: true
      };
    }
    case constants.FETCH_PHASES.SUCCESS: {
      const { phases, phase_orders } = action.payload.response;
      const { projectId } = action.payload.requestPayload;
      const nextStatePhases = keyBy(phases, byId);

      return {
        ...state,
        phaseIds: phases.map(byId),
        phaseOrder: phase_orders,
        phases: nextStatePhases,
        phaseHash: {
          ...state.phaseHash,
          ...nextStatePhases
        },
        fetchedPhasesProjectIds: {
          ...state.fetchedPhasesProjectIds,
          [projectId]: projectId
        },
        phasesByProject: {
          ...state.phasesByProject,
          [projectId]: {
            id: projectId,
            phases,
            phase_orders
          }
        },
        fetching: false
      };
    }
    case constants.FETCH_FILTERED_PHASES.TRIGGER: {
      return {
        ...state,
        fetching: true
      };
    }
    case constants.FETCH_FILTERED_PHASES.FAILURE: {
      return {
        ...state,
        fetching: false
      };
    }
    case constants.FETCH_FILTERED_PHASES.SUCCESS: {
      const { response, requestPayload = {} } = action.payload;
      const { filterId } = requestPayload;
      const { phases } = response;

      if (filterId) {
        return {
          ...state,
          phaseHash: {
            ...state.phaseHash,
            ...keyBy(phases, byId)
          },
          phaseIdsByFilter: {
            ...state.phaseIdsByFilter,
            [filterId]: phases.map((phase) => phase.id)
          },
          fetching: false
        };
      } else {
        return {
          ...state,
          phases: keyBy(phases, byId),
          phaseHash: {
            ...state.phaseHash,
            ...keyBy(phases, byId)
          },
          fetching: false
        };
      }
    }

    case constants.FETCH_PHASE_NAMES.TRIGGER: {
      return {
        ...state,
        isFetchingPhaseNames: true
      };
    }
    case constants.FETCH_PHASE_NAMES.FAILURE: {
      return {
        ...state,
        isFetchingPhaseNames: false
      };
    }
    case constants.FETCH_PHASE_NAMES.SUCCESS: {
      const phaseNames = action.payload.response;
      return {
        ...state,
        phaseNames,
        isFetchingPhaseNames: false,
        hasFetchedPhaseNames: true
      };
    }

    case constants.WS_PHASE: {
      const phase = action.payload;
      const emptyProjectPhases = {
        id: phase.id,
        phases: [],
        phase_orders: []
      };
      const projectPhases =
        state.phasesByProject[phase.project_id] || emptyProjectPhases;
      const hasPhase = projectPhases.phases.some(
        (projectPhase) => projectPhase.id == phase.id
      );
      const hasPhaseInOrder = projectPhases.phase_orders.some(
        (id) => id == phase.id
      );
      if (phase.deleted) {
        if (projectPhases === emptyProjectPhases) {
          return state;
        }
        return {
          ...state,
          phasesByProject: {
            ...state.phasesByProject,
            [phase.project_id]: {
              ...projectPhases,
              phases: hasPhase
                ? projectPhases.phases.filter(
                    (projectPhase) => projectPhase.id != phase.id
                  )
                : projectPhases.phases,
              phase_orders: hasPhaseInOrder
                ? projectPhases.phase_orders.filter((id) => id != phase.id)
                : projectPhases.phase_orders
            },
            phaseHash: omit(state.phaseHash, phase.id)
          }
        };
      } else {
        return {
          ...state,
          phasesByProject: {
            ...state.phasesByProject,
            [phase.project_id]: {
              ...projectPhases,
              phases: hasPhase
                ? projectPhases.phases.map((projectPhase) =>
                    projectPhase.id == phase.id ? phase : projectPhase
                  )
                : uniqBy([phase, ...projectPhases.phases], (phase) => phase.id),
              phase_orders: hasPhaseInOrder
                ? projectPhases.phase_orders
                : uniq([phase.id, ...projectPhases.phase_orders])
            }
          }
        };
      }
    }
    case constants.CREATE_PHASE.SUCCESS: {
      const phase = action.payload.response;
      const emptyProjectPhases = {
        id: phase.id,
        phases: [],
        phase_orders: []
      };
      const projectPhases =
        state.phasesByProject[phase.project_id] || emptyProjectPhases;
      const hasPhase = projectPhases.phases.some(
        (projectPhase) => projectPhase.id == phase.id
      );
      const hasPhaseInOrder = projectPhases.phase_orders.some(
        (id) => id == phase.id
      );

      return {
        ...state,
        phaseIds: [phase.id, ...state.phaseIds.filter((id) => id != phase.id)],
        phaseOrder: [
          phase.id,
          ...state.phaseOrder.filter((id) => id != phase.id)
        ],
        phases: {
          ...state.phases,
          [phase.id]: phase
        },
        phaseHash: {
          ...state.phaseHash,
          [phase.id]: phase
        },
        phasesByProject: {
          ...state.phasesByProject,
          [phase.project_id]: {
            ...projectPhases,
            phases: hasPhase
              ? projectPhases.phases.map((projectPhase) =>
                  projectPhase.id == phase.id ? phase : projectPhase
                )
              : uniqBy([phase, ...projectPhases.phases], (phase) => phase.id),
            phase_orders: hasPhaseInOrder
              ? projectPhases.phase_orders
              : uniq([phase.id, ...projectPhases.phase_orders])
          }
        },
        predictedPhase: null,
        predictedPhaseId: null
      };
    }
    case constants.UPDATE_PHASE.TRIGGER: {
      const { id, memberBudgetOrder } = action.payload;
      const phase = state.phases[id];
      if (!phase) {
        return state;
      }
      return {
        ...state,
        phases: {
          ...state.phases,
          [id]: {
            ...phase,
            member_budget_orders:
              memberBudgetOrder || phase.member_budget_orders
          }
        },
        phaseHash: {
          ...state.phaseHash,
          [id]: {
            ...phase,
            member_budget_orders:
              memberBudgetOrder || phase.member_budget_orders
          }
        }
      };
    }
    case constants.UPDATE_PHASE.SUCCESS: {
      const phase = action.payload.response;
      return {
        ...state,
        phases: {
          ...state.phases,
          [phase.id]: phase
        },
        phaseHash: {
          ...state.phaseHash,
          [phase.id]: phase
        },
        predictedPhase: null,
        predictedPhaseId: null
      };
    }

    case constants.HARD_DELETE_PHASE.TRIGGER:
    case constants.DELETE_PHASE.TRIGGER: {
      const { id } = action.payload;
      return {
        ...state,
        phases: {
          ...state.phases,
          [id]: {
            ...state.phases[id],
            archived: true
          }
        },
        phaseHash: {
          ...state.phaseHash,
          [id]: {
            ...state.phaseHash[id],
            archived: true
          }
        },
        phaseIds: state.phaseIds.filter((phaseId) => phaseId !== id),
        phaseOrder: state.phaseOrder.filter((phaseId) => phaseId !== id)
      };
    }

    case constants.HARD_DELETE_PHASE.SUCCESS:
    case constants.DELETE_PHASE.SUCCESS: {
      const { id } = action.payload.requestPayload;
      return {
        ...state,
        phases: {
          ...state.phases,
          [id]: {
            ...state.phases[id],
            archived: true
          }
        },
        phaseHash: {
          ...state.phaseHash,
          [id]: {
            ...state.phaseHash[id],
            archived: true
          }
        },
        phaseIds: state.phaseIds.filter((phaseId) => phaseId !== id),
        phaseOrder: state.phaseOrder.filter((phaseId) => phaseId !== id)
      };
    }
    case constants.UPDATE_PROJECT_MODULES.TRIGGER: {
      const { phaseOrder, projectId } = action.payload;
      if (!phaseOrder) {
        return state;
      }
      return {
        ...state,
        phaseOrder,
        phasesByProject: {
          ...state.phasesByProject,
          [projectId]: {
            ...(state.phasesByProject[projectId] || {}),
            phase_orders: phaseOrder
          }
        }
      };
    }
    case constants.OPEN_PHASE_TEMPLATE_DROPDOWN: {
      return {
        ...state,
        phaseTemplateDropdownOpen: true
      };
    }
    case constants.CLOSE_PHASE_TEMPLATE_DROPDOWN: {
      return {
        ...state,
        phaseTemplateDropdownOpen: false
      };
    }
    case constants.OPEN_PHASE_TEMPLATE_MODAL: {
      const { isCustomPhase, id, name } = action.payload;
      return {
        ...state,
        phaseTemplateModalOpen: true,
        modalPhaseTemplateId: id,
        ...(name && { phaseName: name }),
        ...(isCustomPhase && { isCustomPhase })
      };
    }
    case constants.CLOSE_PHASE_TEMPLATE_MODAL: {
      return {
        ...state,
        phaseTemplateModalOpen: false,
        modalPhaseTemplateId: null,

        ...(state.phaseName && { phaseName: null }),
        ...(state.isCustomPhase && { isCustomPhase: false })
      };
    }
    case constants.OPEN_PHASE_MODAL: {
      const {
        id,
        projectId,
        name,
        isDeletePhase = false,
        isArchived,
        isAllPhases,
        isEditInfoModal,
        prevModal = null,
        isAddPhase = true,
        isAddMilestone = false
      } = action.payload;
      return {
        ...state,
        phaseModalOpen: true,
        modalPhaseId: id,
        modalProjectId: projectId,
        modalPhaseName: name,
        isDeletePhase,
        isArchived,
        isAllPhases: !!isAllPhases,
        isEditInfoModal,
        prevModal,
        isAddPhase,
        isAddMilestone
      };
    }
    case constants.CLOSE_PHASE_MODAL: {
      return {
        ...state,
        phaseModalOpen: false,
        modalPhaseId: null,
        modalProjectId: null,
        modalPhaseName: null,
        isDeletePhase: false,
        isArchived: false,
        isAllPhases: null,
        isEditInfoModal: false,
        prevModal: null,
        isAddPhase: false,
        isAddMilestone: false
      };
    }
    case constants.OPEN_ACTIVITY_PHASE_MODAL: {
      const { phaseId, activityId, isEditActivityPhaseModalOpen } =
        action.payload;
      return {
        ...state,
        activityPhaseModalOpen: true,
        activityPhaseModalPhaseId: phaseId,
        activityPhaseModalActivityId: activityId,
        isEditActivityPhaseModalOpen
      };
    }
    case constants.CLOSE_ACTIVITY_PHASE_MODAL: {
      return {
        ...state,
        activityPhaseModalOpen: false,
        activityPhaseModalPhaseId: null,
        activityPhaseModalActivityId: null,
        isEditActivityPhaseModalOpen: false
      };
    }
    case constants.PREDICT_PHASE.TRIGGER: {
      return {
        ...state,
        predictedPhaseId: action.payload.id
      };
    }
    case constants.PREDICT_PHASE.SUCCESS: {
      return {
        ...state,
        predictedPhase: action.payload.response
      };
    }

    // optimistically update an activity phase's scope order
    // FE currently relies on phase.activity_phases for scope order
    // FAILURE is currently only handled by phase refetch.
    case constants.UPDATE_SCOPE_POSITION.TRIGGER: {
      const {
        id: scopeId,
        moveBehindId,
        phaseId,
        activityPhaseId
      } = action.payload;
      const phase = state.phaseHash[phaseId];
      if (!(scopeId && phaseId && activityPhaseId && phase)) return state;

      const getUpdatedScopeOrder = (currentScopeOrder) => {
        const scopeIndex = currentScopeOrder.indexOf(scopeId);
        const moveBehindIndex = currentScopeOrder.indexOf(moveBehindId);

        const newScopeOrder = [...currentScopeOrder];
        newScopeOrder.splice(scopeIndex, 1);
        newScopeOrder.splice(moveBehindIndex, 0, scopeId);

        return newScopeOrder;
      };

      return {
        ...state,
        phaseHash: {
          ...state.phaseHash,
          [phaseId]: {
            ...phase,
            activity_phases: phase.activity_phases.map((activityPhase) => {
              if (activityPhase.id === activityPhaseId) {
                return {
                  ...activityPhase,
                  project_scope_order: getUpdatedScopeOrder(
                    activityPhase.project_scope_order
                  )
                };
              }
              return activityPhase;
            })
          }
        }
      };
    }

    case constants.UPDATE_SCOPE_POSITION.SUCCESS: {
      const { activity_phase } = action.payload.response;
      const phase = state.phaseHash[activity_phase.phase_id];
      if (!phase) return state;

      return {
        ...state,
        phaseHash: {
          ...state.phaseHash,
          [phase.id]: {
            ...phase,
            activity_phases: phase.activity_phases.map((activityPhase) => {
              if (activityPhase.id === activity_phase.id) {
                return {
                  ...activityPhase,
                  project_scope_order: activity_phase.project_scope_order
                };
              }
              return activityPhase;
            })
          }
        }
      };
    }

    case constants.UPDATE_PHASE_MEMBERSHIP.SUCCESS: {
      const { response } = action.payload;
      const { id, phase_id } = response;
      const phaseHashPhase = state.phaseHash[phase_id];
      const phase = state.phases[phase_id] || phaseHashPhase;

      return {
        ...state,
        phases: {
          ...state.phases,
          [phase_id]: {
            ...phase,
            phase_memberships: phase.phase_memberships.map((phase_membership) =>
              phase_membership.id === id
                ? { ...phase_membership, ...response }
                : phase_membership
            )
          }
        },
        phaseHash: {
          ...state.phaseHash,
          [phase_id]: {
            ...phaseHashPhase,
            phase_memberships: phaseHashPhase.phase_memberships.map(
              (phase_membership) =>
                phase_membership.id === id
                  ? { ...phase_membership, ...response }
                  : phase_membership
            )
          }
        }
      };
    }

    case UPDATE_ACTIVITY_PHASE_MEMBERSHIP.SUCCESS: {
      const { response } = action.payload;
      const {
        activity_phase_membership: { id, phase_id, activity_phase_id }
      } = response;
      const phaseHashPhase = state.phaseHash[phase_id];

      const phase = state.phases[phase_id] || phaseHashPhase;

      // In case phase is not defined (not expected in most cases because user shouldn't be able to update membership without seeing the phase)
      if (!phase) return state;

      const projectId = phase.project_id;

      const nextPhase = {
        ...phase,
        activity_phases: phase.activity_phases.map((activityPhase) =>
          activityPhase.id === activity_phase_id
            ? formatNextActivityPhaseMembershipOfActivityPhase(
                activityPhase,
                response.activity_phase_membership
              )
            : activityPhase
        )
      };

      const phasesOfThisProject = state.phasesByProject[projectId];

      return {
        ...state,
        phases: {
          ...state.phases,
          [phase_id]: nextPhase
        },
        phasesByProject: {
          ...state.phasesByProject,
          // In case there is no record of this project's phases
          ...(phasesOfThisProject
            ? {
                [projectId]: {
                  ...phasesOfThisProject,
                  phases: phasesOfThisProject.phases.map((currentPhase) =>
                    nextPhase.id === currentPhase.id ? nextPhase : currentPhase
                  )
                }
              }
            : {})
        },
        phaseHash: {
          ...state.phaseHash,
          [phase_id]: nextPhase
        }
      };
    }

    default:
      return state;
  }
};

export default phases;
