import SvgIcon from 'components/SvgIcon';
import { ComponentProps } from 'react';
import styled from 'styled-components';

const PlusMinusCircleIcon = ({
  backgroundColor,
  fill = '#0056FF',
  height = 20,
  minus,
  pathStroke = '#0074d9',
  stroke = '#0074D9B2',
  strokeWidth = 0.5,
  width = 20,
  ...svgIconProps
}: ComponentProps<typeof SvgIcon> & {
  backgroundColor?: string;
  minus?: boolean;
  pathStroke?: string;
  strokeWidth?: number;
}) => (
  <SvgIcon height={height} viewBox="0 0 20 20" width={width} {...svgIconProps}>
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="9.5" fill={backgroundColor} stroke={stroke} />
      <path
        fill={fill}
        stroke={pathStroke}
        strokeWidth={strokeWidth}
        d={
          minus
            ? 'M15 10.352H5v-.665H15z'
            : 'M15 10.352h-4.688V15h-.664v-4.648H5v-.665h4.648V5h.665v4.688H15z'
        }
      />
    </g>
  </SvgIcon>
);

interface PlusMinusCircleIconHoverableProps {
  $disabled?: boolean;
}

export const PlusMinusCircleIconHoverable = styled(
  PlusMinusCircleIcon
).attrs<PlusMinusCircleIconHoverableProps>(({ $disabled }) => ({
  style: {
    cursor: !$disabled ? 'pointer' : undefined
  }
}))<PlusMinusCircleIconHoverableProps>`
  border-radius: 50%;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

    circle {
      fill: ${({ theme }) => theme.colors.colorRoyalBlue};
    }

    path {
      fill: ${({ theme }) => theme.colors.colorPureWhite};
      stroke: ${({ theme }) => theme.colors.colorPureWhite};
    }
  }
`;

export const PlusCircleIcon = (
  props: Omit<ComponentProps<typeof PlusMinusCircleIcon>, 'minus'>
) => <PlusMinusCircleIcon {...props} />;

export const MinusCircleIcon = (
  props: Omit<ComponentProps<typeof PlusMinusCircleIcon>, 'minus'>
) => <PlusMinusCircleIcon {...props} minus />;

export const PlusCircleIconHoverable = (
  props: Omit<ComponentProps<typeof PlusMinusCircleIcon>, 'minus'>
) => <PlusMinusCircleIconHoverable {...props} />;

export const MinusCircleIconHoverable = (
  props: Omit<ComponentProps<typeof PlusMinusCircleIcon>, 'minus'>
) => <PlusMinusCircleIconHoverable {...props} minus />;

export default PlusMinusCircleIcon;
