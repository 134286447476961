import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getHomeTasksEditedTasks, getHomeModalTasks, getAuth } from 'selectors';
import { bulkEditHomeTasks, closeHomeTaskModal } from 'actionCreators';
import { BulkUpdateModal } from 'views';
const noop = () => {};
class BulkUpdateModalHomeTasksConnector extends React.Component {
  state = {
    bulkUpdateDisplayDate: null
  };

  updateBulkUpdateDisplayDate = (newDate = null) => {
    this.setState({ bulkUpdateDisplayDate: newDate });
  };

  updateTasks = () => {
    const { auth, bulkEditHomeTasks, editedTasks } = this.props;

    bulkEditHomeTasks({
      token: auth.token,
      tasks: editedTasks
    });
  };

  render() {
    const { closeHomeTaskModal } = this.props;
    return (
      <BulkUpdateModal
        {...this.props}
        updateTasks={this.updateTasks}
        toggle={closeHomeTaskModal}
        close={closeHomeTaskModal}
        homeTasksView={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  editedTasks: getHomeTasksEditedTasks(state),
  tasks: getHomeModalTasks(state)
});

const mapDispatchToProps = {
  bulkEditHomeTasks,
  closeHomeTaskModal
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BulkUpdateModalHomeTasksConnector)
);
