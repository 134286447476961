import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  BatchActionToggleWrapper,
  BatchActionToggleIcon
} from 'components/BatchActions/styles';

//Fix the click area of the checkbox
const StyledBatchActionToggleWrapper = styled(BatchActionToggleWrapper)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  &:hover {
    ${BatchActionToggleIcon} {
      border: 1px solid #0074d9;
      background: 'white';
    }
  }
`;

class TaskBatchActionToggle extends PureComponent {
  wrappedHandleBatchActionToggleClick = (e) => {
    const { handleBatchActionToggleClick, isNewTask } = this.props;
    e.stopPropagation();
    !isNewTask && handleBatchActionToggleClick();
  };

  render() {
    const {
      taskBatchActionIsToggled,
      showBatchActionToggles,
      taskIsEditing,
      isOnTeamProject,
      isCreatingTask,
      isTaskModal,
      isNewTask,
      testIdPrefix
    } = this.props;

    return (
      <StyledBatchActionToggleWrapper
        onClick={this.wrappedHandleBatchActionToggleClick}
        taskBatchActionIsToggled={taskBatchActionIsToggled}
        beingEdited={taskIsEditing}
        isOnTeamProject={isOnTeamProject}
        isBatchSelected={taskBatchActionIsToggled}
        isCreatingTask={isCreatingTask}
        isTaskModal={isTaskModal}
        isTaskColumnsWrapper
        isNewTask={isNewTask}
        data-testid={`${testIdPrefix}-select-task`}
      >
        <BatchActionToggleIcon
          onClick={this.wrappedHandleBatchActionToggleClick}
          isBatchSelected={taskBatchActionIsToggled}
          showBatchActionToggles={showBatchActionToggles}
        />
      </StyledBatchActionToggleWrapper>
    );
  }
}

export default TaskBatchActionToggle;
