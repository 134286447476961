import { MemberCapacityGroupListItem } from '../types';
import { useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';
import theme from 'theme';
import { capacityGroupFormatUtils } from 'CapacityModule/utils/capacityGroup';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

export const MembersCapacityTablePolicyPickerCell = ({ row }) => {
  const { accountId, openCapacityPolicyMemberTimeline, capacityGroup } =
    row.original as MemberCapacityGroupListItem;
  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const teamMember = teamMembersHash[accountId];

  if (!teamMember) return <></>;

  return (
    <RootContainer
      $isArchived={teamUtils.getIsArchived(teamMember)}
      onClick={(event) =>
        openCapacityPolicyMemberTimeline({
          teamMembershipId: teamMember.id,
          accountId: teamMember.account.id,
          anchorEl: event.currentTarget
        })
      }
    >
      <ContentContainer>
        <PolicyText>
          {formatNumWithMaxTwoDecimals(capacityGroup.hours)}h |{' '}
          {capacityGroupFormatUtils.getName(capacityGroup)}
        </PolicyText>
      </ContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled.div<{ $isArchived: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  border-left: 1px solid ${theme.colors.colorLightGray6};
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};

  :hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;

const PolicyText = styled.div`
  // make 2 lines of text fit in the cell
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
`;
