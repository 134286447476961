import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ThreeDot2 from 'icons/ThreeDot2';

export const StyledThreeDot = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.isOpen && 'visibility: visible !important;'}
`;
export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  &:hover {
    transform: scale(1.05);
    background: ${theme.colors.colorCalendarBlue};
    path {
      fill: ${theme.colors.colorPureWhite} !important;
    }
  }
  svg {
    position: relative;
    top: 3px;
    left: 2px;
    width: 20px;
    height: 20px;
    stroke-width: 0;
    path {
      fill: ${theme.colors.colorCalendarGray};
    }
  }
`;

const ThreeDot = ({ threeDotRef, isOpen }) => {
  return (
    <StyledThreeDot ref={threeDotRef} isOpen={isOpen}>
      <IconContainer>
        <ThreeDot2 />
      </IconContainer>
    </StyledThreeDot>
  );
};

export default ThreeDot;
