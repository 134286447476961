import React from 'react';
import { BillableChartTooltipContainer } from '../../styles';

const UtilizationSummaryCustomTooltip = ({
  active,
  payload,
  billable,
  total
}) => {
  if (active) {
    const segment = payload[0]?.payload; // library format
    if (!segment) {
      return null;
    }

    return (
      <BillableChartTooltipContainer className="custom-tooltip">
        {`${(+billable)
          .toFixed(1)
          .replace('.0', '')} hours billable out of ${(+total)
          .toFixed(1)
          .replace('.0', '')} total.`}
      </BillableChartTooltipContainer>
    );
  }

  return null;
};

export default UtilizationSummaryCustomTooltip;
