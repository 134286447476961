import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import styled, { css } from 'styled-components';
import theme from 'theme';

interface AllMemberConfirmationModalProps {
  closeModal: () => void;
  uncheckSpecificFlag: (name: string) => void;
}

const AllMemberConfirmationModal = ({
  closeModal,
  uncheckSpecificFlag
}: AllMemberConfirmationModalProps) => {
  const handleUncheckMemberBudget = () => {
    uncheckSpecificFlag('budget_member_budget');
    closeModal();
  };

  return (
    <Modal
      isOpen
      toggle={closeModal}
      className="project-cloning-all-member-confirm-modal"
    >
      <StyledModalHeader>Unchecking All Members</StyledModalHeader>
      <StyledModalBody>
        All members must be selected to <b>Clone Member</b>
        <br />
        <b>Budgets</b>. Do you want to <b>unselect Member Budgets</b>?
      </StyledModalBody>
      <StyledModalFooter>
        <StyledButtonContainer>
          <StyledButton
            onClick={closeModal}
            buttonVariant={BUTTON_VARIANT_OPTIONS.Secondary}
          >
            No, Clone all Members
          </StyledButton>
          <StyledButton
            onClick={handleUncheckMemberBudget}
            buttonVariant={BUTTON_VARIANT_OPTIONS.Primary}
          >
            Yes, Unselect
          </StyledButton>
        </StyledButtonContainer>
      </StyledModalFooter>
    </Modal>
  );
};

export default AllMemberConfirmationModal;

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    font-size: 22px;
    color: ${theme.colors.colorPureBlack};
    font-weight: 600;
    line-height: unset;
  }
  border: none;
  padding: 0;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 18px 0px 24px 0px;
  font-size: 13px;
  color: ${theme.colors.colorPureBlack};
`;

const StyledModalFooter = styled(ModalFooter)`
  border: none;
  padding: 0px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 0px;
`;

type ButtonVariant = 'Primary' | 'Secondary';

const BUTTON_VARIANT_OPTIONS: Record<ButtonVariant, ButtonVariant> = {
  Primary: 'Primary',
  Secondary: 'Secondary'
};

const StyledButtonStylesByVariant: Record<
  ButtonVariant,
  ReturnType<typeof css>
> = {
  Primary: css`
    color: ${theme.colors.colorPureWhite};
    background: ${theme.colors.colorCalendarBlue};
    border-radius: 5px;
    padding: 8px 10px;
    font-weight: 600;
  `,

  Secondary: css`
    color: ${theme.colors.colorCalendarGray};
    background: ${theme.colors.colorPureWhite};
    border-radius: 6px;
    border: 1px solid ${theme.colors.colorMediumGray12};
    padding: 7px 10px;
  `
};

const StyledButton = styled.button<{
  buttonVariant: ButtonVariant;
}>`
  font-size: 14px;
  border: none;
  ${({ buttonVariant }) => StyledButtonStylesByVariant[buttonVariant]};
  &:hover {
    filter: brightness(90%);
  }
`;
