import { createSelector } from 'reselect';
const emptyObj = {};

/**
 * @deprecated Use the typesafe version (`getAuthState`) from
 * `AuthenticationModule/selectors`.
 */
export const getAuth = (state) => state.auth;

export const getTeamsState = (state) => state.teams;
export const getTimesheetsState = (state) => state.timesheets;
export const getTimesheetActivityModalState = (state) =>
  state.timesheetActivityModal;

/**
 * @deprecated Use the typesafe version from `UsersModule/selectors`.
 */
export const getMe = (state) => (state.users && state.users.me) || emptyObj;

export const getSplitState = (state) => state.splitio || emptyObj;

/**
 * Provides a mapping of split flags to boolean values indicating whether the
 * feature should be active or not. Add new split flags to `splitFlags` inside
 * `assets/js/constants/splitFlags.ts` for it to be picked up.
 */
export const getSplitFlags = createSelector(getSplitState, (splitState) => {
  const splitFlags = {};
  if (splitState.isReady && splitState.treatments) {
    Object.keys(splitState.treatments).forEach((flagName) => {
      splitFlags[flagName] =
        splitState.treatments[flagName].flag.treatment === 'on';
    });
  }

  return splitFlags;
});

export const getIsSplitLoaded = createSelector(
  getSplitState,
  (splitState) => splitState.isReady
);

export const getIsRightSideFilterEnabled = createSelector(
  getSplitFlags,
  (splitFlags) => splitFlags?.isRightSideFilterEnabled
);
