import { Draft, createReducer, PayloadAction } from '@reduxjs/toolkit';
import * as constants from 'appConstants';
import { serializeId } from 'appUtils';
import keyBy from 'lodash/keyBy';
import { Comment } from 'models/comment';
import { EntityType } from 'models/entity';

export interface CommentsState {
  commentsHash: Record<number, Comment>;
  commentCountsHash: Record<
    string, // serializedId
    {
      loadedCount: number;
      totalCount: number;
    }
  >;
}

const initialState: CommentsState = {
  commentsHash: {},
  commentCountsHash: {}
};

export const parentEntitySerializeId = ({
  parentEntityId,
  parentEntityType
}: {
  parentEntityId: number;
  parentEntityType: EntityType;
}): string =>
  serializeId({
    id: [parentEntityId],
    ids: undefined,
    itemType: parentEntityType
  });

const handleFetchEntityCommentsSuccess = (
  state: Draft<CommentsState>,
  action: PayloadAction<{ response: FetchEntityCommentsSuccessResponse }>
) => {
  const { response } = action.payload;
  state.commentsHash = {
    ...state.commentsHash,
    ...keyBy(response.comments, ({ id }) => id)
  };
  const parentEntitySerializedId = parentEntitySerializeId({
    parentEntityId: response.parent_entity_id,
    parentEntityType: response.parent_entity_type
  });

  state.commentCountsHash[parentEntitySerializedId] = {
    loadedCount: response.loaded_count, // this returend loaded_count value is same as offeset
    totalCount: response.total_count
  };
};

const handleCreateEntityCommentSuccess = (
  state: Draft<CommentsState>,
  action: PayloadAction<{ response: CreateEntityCommentSuccessResponse }>
) => {
  const { response } = action.payload;
  const existingComment =
    response.parent_comment_id &&
    state.commentsHash[response.parent_comment_id];
  if (existingComment) {
    existingComment.replies = [...existingComment.replies, response];
  } else {
    state.commentsHash[response.id] = response;
  }

  if (!response.parent_comment_id) {
    const parentEntitySerializedId = parentEntitySerializeId({
      parentEntityId: response.parent_entity_id,
      parentEntityType: response.parent_entity_type
    });
    state.commentCountsHash[parentEntitySerializedId] = {
      loadedCount:
        (state.commentCountsHash[parentEntitySerializedId]?.loadedCount ?? 0) +
        1,
      totalCount:
        (state.commentCountsHash[parentEntitySerializedId]?.totalCount ?? 0) + 1
    };
  }
};

const handleUpdateEntityCommentSuccess = (
  state: Draft<CommentsState>,
  action: PayloadAction<{ response: UpdateEntityCommentSuccessResponse }>
) => {
  const { response } = action.payload;
  const existingComment =
    response.parent_comment_id &&
    state.commentsHash[response.parent_comment_id];
  if (existingComment) {
    existingComment.replies = existingComment.replies.map((reply) =>
      reply.id === response.id ? response : reply
    );
  } else {
    state.commentsHash[response.id] = {
      ...response,
      replies: state.commentsHash[response.id]?.replies ?? []
    };
  }
};

const handleDeleteEntityCommentSuccess = (
  state: Draft<CommentsState>,
  action: PayloadAction<{ requestPayload: any }> // [token, {id, parentCommentId}]
) => {
  const { requestPayload } = action.payload;
  const requestParams = requestPayload[1];
  if (requestParams.parentCommentId) {
    const existingComment = state.commentsHash[requestParams.parentCommentId];
    if (existingComment) {
      existingComment.replies = existingComment.replies.filter(
        (reply) => reply.id !== requestParams.id
      );
    }
  } else {
    const existingComment = state.commentsHash[requestParams.id];
    if (existingComment) {
      const parentEntitySerializedId = parentEntitySerializeId({
        parentEntityId: existingComment.parent_entity_id,
        parentEntityType: existingComment.parent_entity_type
      });

      delete state.commentsHash[requestParams.id];
      const existingCounts = state.commentCountsHash[parentEntitySerializedId];
      if (existingCounts) {
        existingCounts.loadedCount = existingCounts.loadedCount - 1;
        existingCounts.totalCount = existingCounts.totalCount - 1;
      }
    }
  }
};

const comments = createReducer(initialState, (builder) => {
  builder.addCase(
    constants.FETCH_ENTITY_COMMENTS.SUCCESS,
    handleFetchEntityCommentsSuccess
  );
  builder.addCase(
    constants.CREATE_ENTITY_COMMENT.SUCCESS,
    handleCreateEntityCommentSuccess
  );
  builder.addCase(
    constants.UPDATE_ENTITY_COMMENT.SUCCESS,
    handleUpdateEntityCommentSuccess
  );
  builder.addCase(
    constants.DELETE_ENTITY_COMMENT.SUCCESS,
    handleDeleteEntityCommentSuccess
  );
});

interface FetchEntityCommentsSuccessResponse {
  parent_entity_type: EntityType;
  parent_entity_id: number;
  loaded_count: number;
  total_count: number;
  comments: Array<Comment>;
}
type CreateEntityCommentSuccessResponse = Comment;
type UpdateEntityCommentSuccessResponse = Comment;

export default comments;
