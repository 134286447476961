import React from 'react';
import styled from 'styled-components';
import CollapseArrow from 'components/CollapseArrow';

export const StyledCollapseCell = styled.div`
  height: 100%;
  display: flex;
  height: fit-content;
`;
export const CollapseArrowContainer = styled.div`
  position: relative;
  left: -9px;
`;

const CollapseCell = ({ row }) => {
  const {
    uid,
    list: { isOpen },
    customRowProps: { handleSetIsOpen, handleLoadLevel }
  } = row.original;

  const toggleIsOpen = () => {
    handleSetIsOpen({ uid, value: !isOpen });
    if (!isOpen) {
      handleLoadLevel(uid);
    }
  };

  return (
    <StyledCollapseCell>
      <CollapseArrowContainer>
        <CollapseArrow
          testId={`${uid}-collapse`}
          toggleCollapse={toggleIsOpen}
          isCollapsed={!isOpen}
          noBackground={true}
        />
      </CollapseArrowContainer>
    </StyledCollapseCell>
  );
};

export default CollapseCell;
