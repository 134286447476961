import React from 'react';
import CompleteCell from './CompleteCell';
// import DragCell from './DragCell';
// import DescriptionCell from './DescriptionCell';
// import DueCell from './DueCell';
// import ScheduleCell from './ScheduleCell';
// import StatusCell from './StatusCell';
// import PriorityCell from './PriorityCell';
// import PhaseCell from './PhaseCell';
// import AssigneeCell from './AssigneeCell';
// import BulkCell from './BulkCell';
import TotalEstHoursCell from './TotalEstHoursCell';

const EmptyDiv = () => <div></div>;

const TaskGroupCells = {
  CompleteCell,
  DragCell: EmptyDiv,
  DescriptionCell: EmptyDiv,
  DueCell: EmptyDiv,
  ScheduleCell: EmptyDiv,
  StatusCell: EmptyDiv,
  PriorityCell: EmptyDiv,
  PhaseCell: EmptyDiv,
  AssigneeCell: EmptyDiv,
  BulkCell: EmptyDiv,
  timerCell: EmptyDiv,
  // Footer cells,
  TotalEstHoursCell
};

export default TaskGroupCells;
