import { useConfirmStopTimerModal } from 'views/Home/Tasks/useConfirmStopTimerModal';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { batch } from 'react-redux';
import {
  createTaskAndOpenModal,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  navigateToTaskModal,
  openConfirmCompleteTaskModal,
  setSelectedTask,
  triggerTaskCompletionToggle,
  triggerTasksAttributesUpdate,
  triggerBatchDeleteTasks,
  updateTaskPosition
} from 'actionCreators';
import {
  getAuthToken,
  getIsFetchingTasks,
  getShouldTriggerTaskConfirmCompleteModal,
  getActiveTimer
} from 'selectors';
import moment from 'moment';
import uuid from 'uuid';
import { Task } from 'models/task';
import {
  getSelectedTeamId,
  getShouldTriggerTaskCompleteConfirmStopTimerModal
} from 'TeamsModule/selectors';
import { TasksV2UpdateParams } from 'TaskModule/api/types';
import { useRef, useCallback } from 'react';

// TODO: move to task module
export const useTasks = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthToken);
  const teamId = useAppSelector(getSelectedTeamId);
  const activeTimer = useAppSelector(getActiveTimer);
  const shouldTriggerTaskConfirmCompleteModal = useAppSelector(
    getShouldTriggerTaskConfirmCompleteModal
  );
  const isFetchingTasks = useAppSelector(getIsFetchingTasks);
  const shouldTriggerTaskCompleteConfirmStopTimerModal = useAppSelector(
    getShouldTriggerTaskCompleteConfirmStopTimerModal
  );

  const selectedCompletedTask = useRef<Nullable<Task>>(null);

  const handleOpenTaskModal = useCallback(
    (task: Task) => {
      batch(() => {
        dispatch(fetchTaskGroups({ taskGroupIds: [task.task_group_id] }));

        dispatch(
          navigateToTaskModal({
            taskId: task.id
          })
        );
        dispatch(setSelectedTask(task.id));
        dispatch(
          fetchCommentsAndMetadata({
            taskId: task.id,
            taskType: 'projects',
            offset: 0,
            limit: 4
          })
        );
      });
    },
    [dispatch]
  );

  const markTaskComplete = useCallback(
    (task: Task) => {
      selectedCompletedTask.current = null;

      const isCompleted = !!task.completed_at;
      if (!isFetchingTasks) {
        const isOverdue = task.due_at && task.due_at < Date();
        if (
          task.assignee_ids?.length > 1 &&
          shouldTriggerTaskConfirmCompleteModal
        ) {
          dispatch(openConfirmCompleteTaskModal({ taskId: task.id }));
          return;
        }

        dispatch(
          triggerTaskCompletionToggle({
            taskId: task.id,
            isCompleting: !isCompleted,
            doNotRemoveTask: undefined,
            permissions: {
              teamId
            }
          })
        );
        dispatch(
          updateTaskPosition({
            token,
            taskId: task.id,
            projectId: task.project_id,
            taskType: 'projects',
            isCompleted: !isCompleted,
            wasCompleted: isCompleted,
            isOverdue,
            taskPosition: task.position,
            oldTaskPosition: task.position,
            circleClicked: true,
            permissions: {
              teamId
            }
          })
        );
      }
    },
    [
      dispatch,
      isFetchingTasks,
      shouldTriggerTaskConfirmCompleteModal,
      teamId,
      token
    ]
  );

  const handleCloseStopTimerModal = useCallback(() => {
    if (selectedCompletedTask?.current) {
      markTaskComplete(selectedCompletedTask.current);
    }
  }, [markTaskComplete]);

  const { ConfirmStopTimerModal, open: openConfirmStopTimerModal } =
    useConfirmStopTimerModal({ onClose: handleCloseStopTimerModal });

  const handleMarkTaskComplete = useCallback(
    (task: Task) => {
      if (
        activeTimer?.task_id === task.id &&
        !task.completed_at &&
        shouldTriggerTaskCompleteConfirmStopTimerModal
      ) {
        openConfirmStopTimerModal();
        selectedCompletedTask.current = task;
      } else {
        markTaskComplete(task);
      }
    },
    [
      activeTimer?.task_id,
      markTaskComplete,
      openConfirmStopTimerModal,
      shouldTriggerTaskCompleteConfirmStopTimerModal
    ]
  );

  const handleUpdateTasks = useCallback(
    (body: TasksV2UpdateParams) => {
      dispatch(
        triggerTasksAttributesUpdate({
          token,
          body,
          permissions: {
            teamId
          },
          options: undefined
        })
      );
    },
    [dispatch, teamId, token]
  );

  const handleCreateTaskAndOpenModal = useCallback(
    ({
      projectId,
      phaseId,
      activityId,
      activityPhaseId,
      assigneeIds,
      startDate,
      endDate,
      taskListType
    }: {
      projectId: number;
      phaseId: number;
      activityId?: number;
      activityPhaseId?: number;
      assigneeIds?: Array<number>;
      startDate?: string;
      endDate?: string;
      taskListType?: string;
    }) => {
      dispatch(
        createTaskAndOpenModal({
          token,
          body: {
            description: '',
            project_id: projectId,
            phase_id: phaseId,
            activity_id: activityId,
            activity_phase_id: activityPhaseId,
            assignee_ids: assigneeIds ?? [],
            schedule_start: startDate
              ? moment(startDate).format('YYYY-MM-DD')
              : '',
            schedule_end: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            id: uuid()
          },
          taskListType
        })
      );
    },
    [dispatch, token]
  );

  const handleDeleteTasks = useCallback(
    (taskIds: Array<number>) => {
      dispatch(
        triggerBatchDeleteTasks({
          token,
          body: {
            taskIds
          }
        })
      );
    },
    [dispatch, token]
  );

  return {
    createTaskAndOpenModal: handleCreateTaskAndOpenModal,
    markComplete: handleMarkTaskComplete,
    openTaskModal: handleOpenTaskModal,
    updateTasks: handleUpdateTasks,
    deleteTasks: handleDeleteTasks,
    ConfirmStopTimerModal
  };
};
