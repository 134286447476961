import styled from 'styled-components';
import theme from 'theme';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';

export const StyledActivityTimesheetIcon = styled(ActivityTimesheetIcon)``;
export const ActivityIconContainer = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  height: 15px;
  flex: 0 0 15px;
  width: 15px;
  font-size: 9px;
  color: ${theme.colors.colorMediumGray5};
  border-radius: 100px;
  ${({ outline }) =>
    outline ? `border: 1px solid ${theme.colors.colorLightGray8}` : ''};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    background: ${theme.colors.colorPaleGray8};
    ${StyledActivityTimesheetIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;
