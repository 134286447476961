export const makeSubcustomerSearchablePhases = (
  phasesBySubcustomerId,
  subcustomerId
) =>
  (phasesBySubcustomerId &&
    phasesBySubcustomerId[subcustomerId] &&
    phasesBySubcustomerId[subcustomerId]
      .map((phase) => phase.phase_name)
      .join(' ')) ||
  '';

export const makeProjectSearchablePhases = (
  projectId,
  allPhases,
  phaseNames
) => {
  const phase_orders =
    projectId && allPhases[projectId] && allPhases[projectId].phase_orders;
  if (!phase_orders) {
    return '';
  }
  return phase_orders.map((phaseId) => phaseNames[phaseId]).join(' ');
};

export const makeSearchableTeamMember = (teamMember) => {
  if (!teamMember || !teamMember.account) {
    return '';
  }
  return `${teamMember.account.initials} ${teamMember.account.full_name} ${teamMember.account.email}`;
};
