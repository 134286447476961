import React from 'react';
import { CreateButtonContainer, DropdownContainer } from './styles';
import TaskListSortDropdown from './TaskListSortDropdown';
import TaskSortOptions from 'views/projectPlanner/projectTimeline/Options/TaskListSortOptions';

const TaskListGroupsHeader = (props) => {
  const { isOnPersonalProject, dragHandleProps } = props;
  return (
    <CreateButtonContainer
      isOnPersonalProject={isOnPersonalProject}
      {...dragHandleProps}
    >
      <>
        <TaskSortOptions />
        <DropdownContainer>
          <TaskListSortDropdown />
        </DropdownContainer>
      </>
    </CreateButtonContainer>
  );
};

export default TaskListGroupsHeader;
