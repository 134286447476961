import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CloseXIcon = ({
  fill = '#B3B3B3',
  width = '18',
  height = '18',
  onClick
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9805 5.49521L12.5048 4.01951L9 7.5243L5.49521 4.01951L4.01951 5.49521L7.5243 9L4.01951 12.5048L5.49521 13.9805L9 10.4757L12.5048 13.9805L13.9805 12.5048L10.4757 9L13.9805 5.49521Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default CloseXIcon;
