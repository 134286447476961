import PermissionsSection from './PermissionsSection';
import CheckBoxContainer from '../../../CheckboxContainer';
import Collapse from '../../../Collapse';
import { flatOptions } from 'SettingsModule/utils/checkboxOptions';
import buildPermittedRoles from 'SettingsModule/utils/buildPermittedRolesString';
import { permissionsActionsHash } from 'PermissionsModule/constants';
import TimesheetIcon from 'icons/TimesheetIcon';
import { HeaderContainer, PermissionText } from './styles';
import { PermissionsActions } from 'PermissionsModule/types';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getAllPermissionsByActions,
  getPermissionActionsExpanded
} from 'RoleTemplatesModule/selectors';

interface TimesheetPermissionsProps {
  makeTogglePermission: (actionType: PermissionsActions) => void;
  toggleOpen: (actionType: PermissionsActions) => void;
}

const TimesheetPermissions = ({
  makeTogglePermission,
  toggleOpen
}: TimesheetPermissionsProps) => {
  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const expandedPermissions = useAppSelector(getPermissionActionsExpanded);

  const renderHeader = (permissionAction: PermissionsActions) => {
    const roleTemplatesHash = permissionsByAction[permissionAction];
    const permissionData = permissionsActionsHash[permissionAction];

    return (
      <HeaderContainer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>{permissionData ? permissionData.question : ''}</div>
        {roleTemplatesHash ? (
          <PermissionText>
            {buildPermittedRoles(roleTemplatesHash)}
          </PermissionText>
        ) : null}
      </HeaderContainer>
    );
  };

  return (
    <PermissionsSection headerText={'Timesheets'} Icon={TimesheetIcon}>
      <Collapse
        header={renderHeader('read_time_entries_other')}
        isOpen={expandedPermissions.read_time_entries_other}
        toggleOpen={() => toggleOpen('read_time_entries_other')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.read_time_entries_other}
          onCheck={makeTogglePermission('read_time_entries_other')}
        />
      </Collapse>
    </PermissionsSection>
  );
};

// class TimesheetPermissions2 extends React.Component {
//   renderHeader = (permissionAction) => {
//     const { permissionsByAction } = this.props;
//     return (
//       <HeaderContainer
//         style={{ display: 'flex', justifyContent: 'space-between' }}
//       >
//         <div>{permissionsActionsHash[permissionAction]}</div>
//         <PermissionText>
//           {buildPermittedRoles(permissionsByAction[permissionAction])}
//         </PermissionText>
//       </HeaderContainer>
//     );
//   };

//   render() {
//     const {
//       permissionsByAction,
//       makeTogglePermission,
//       expandedPermissions,
//       toggleOpen
//     } = this.props;
//     return (
//       <PermissionsSection headerText={'Timesheets'} Icon={TimesheetIcon}>
//         <Collapse
//           header={this.renderHeader('read_time_entries_other')}
//           isOpen={expandedPermissions.read_time_entries_other}
//           toggleOpen={() => toggleOpen('read_time_entries_other')}
//         >
//           <CheckBoxContainer
//             dataList={flatOptions}
//             dataValues={permissionsByAction.read_time_entries_other}
//             onCheck={makeTogglePermission('read_time_entries_other')}
//           />
//         </Collapse>
//       </PermissionsSection>
//     );
//   }
// }

export default TimesheetPermissions;
