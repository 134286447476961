import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
export const initialState = {
  milestoneTemplateIds: [],
  milestoneTemplateOrder: [],
  milestoneTemplates: {},
  fetching: false
};
const byId = (item) => item.id;
const milestoneTemplates = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_PHASE_TEMPLATES.TRIGGER: {
      return {
        ...state,
        fetching: true
      };
    }
    case constants.FETCH_PHASE_TEMPLATES.SUCCESS: {
      const {
        milestone_templates,
        milestone_template_order,
        default_milestone_template_id,
        custom_milestone_template_id
      } = action.payload.response;
      return {
        ...state,
        milestoneTemplateIds: milestone_templates.map(byId),
        milestoneTemplateOrder: milestone_template_order,
        milestoneTemplates: keyBy(milestone_templates, byId),
        fetching: false
      };
    }
    case constants.CREATE_MILESTONE_TEMPLATE.SUCCESS: {
      const milestone_template = action.payload.response;
      return {
        ...state,
        milestoneTemplateIds: [
          milestone_template.id,
          ...state.milestoneTemplateIds
        ],
        milestoneTemplateOrder: [
          milestone_template.id,
          ...state.milestoneTemplateOrder
        ],
        milestoneTemplates: {
          ...state.milestoneTemplates,
          [milestone_template.id]: milestone_template
        }
      };
    }
    case constants.UPDATE_PHASE_TEMPLATE.SUCCESS: {
      const milestone_template = action.payload.response;
      if (!milestone_template.is_budget) {
        return {
          ...state,
          milestoneTemplates: {
            ...state.milestoneTemplates,
            [milestone_template.id]: milestone_template
          }
        };
      } else {
        return state;
      }
    }
    case constants.REORDER_MILESTONE_TEMPLATES.TRIGGER: {
      const { milestoneTemplateOrder } = action.payload;
      if (!milestoneTemplateOrder) {
        return state;
      }
      return {
        ...state,
        milestoneTemplateOrder
      };
    }
    default:
      return state;
  }
};

export default milestoneTemplates;
