import React from 'react';
import cn from 'classnames';
import withHovered from 'hocs/withHovered';
import ReactTooltip from 'react-tooltip';
import bellGrayDash from 'images/bell-outline-dash.svg';
import bellBlue from 'images/bell-blue.svg';

const getTooltipContent = (isFollowedByMe) =>
  isFollowedByMe
    ? `
        Notifications ON
      `
    : `
        Notifications OFF
      `;

const TaskModalFollowedBell = ({
  isFollowedByMe,
  onFollowClick,
  toggleHoveredOn,
  toggleHoveredOff,
  isHovered
}) => {
  const getSrc = () => {
    if (isHovered) return bellBlue;
    if (isFollowedByMe) return bellBlue;
    return bellGrayDash;
  };

  return (
    <div
      className={cn('circular-border', {
        blue: isHovered || isFollowedByMe
      })}
      onMouseEnter={toggleHoveredOn}
      onMouseLeave={toggleHoveredOff}
      onClick={onFollowClick}
      data-for="task-following-tooltip"
      data-tip={getTooltipContent(isFollowedByMe)}
      data-multiline={true}
    >
      <img
        id="task-modal-followed"
        style={{ verticalAlign: 'center', height: 16 }}
        src={getSrc()}
        title={
          isFollowedByMe
            ? 'Click to unfollow this task'
            : 'Click to follow this task'
        }
      />
      <ReactTooltip effect="solid" id="task-following-tooltip" place="right" />
    </div>
  );
};

export default withHovered(TaskModalFollowedBell);
