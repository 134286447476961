import React from 'react';
import CapacityDay from './CapacityDay';
import theme from 'theme';
import {
  MemberCapacityTableContainer,
  TotalsContainer,
  TotalsHeader,
  TotalsValue,
  SubTitle,
  SubTitleSubText,
  HelpIconContainer
} from './styles';
import {
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY
} from 'appConstants/workload';
import HelpIcon from 'icons/HelpIcon';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const daysOfWeek = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

class WorkloadCapacity extends React.Component {
  render() {
    const { capacity, handleChange, workloadSettings } = this.props;

    const capacityTotal = capacity
      ? CAPACITY_DATE_KEYS.reduce((total, day) => +total + +capacity[day], 0)
      : DEFAULT_WEEKLY_CAPACITY;
    return (
      <>
        <SubTitle>
          Member Capacity
          <HelpIconContainer
            data-effect="solid"
            data-for="app-tooltip"
            data-html
            data-tip="This is the default work capacity for organization<br />members. An individual's work capacity can be<br />modified from the member's profile"
          >
            <HelpIcon
              circleColor={theme.colors.colorPaleGray10}
              fillColor={theme.colors.colorPaleGray10}
              height={8}
              questionMarkColor={theme.colors.colorPureBlack}
              width={8}
            />
          </HelpIconContainer>
        </SubTitle>
        <SubTitleSubText>Organization Default</SubTitleSubText>
        <MemberCapacityTableContainer>
          {daysOfWeek.map((day) => (
            <CapacityDay
              key={day}
              day={day}
              capacity={capacity}
              handleChange={handleChange}
              workloadSettings={workloadSettings}
            />
          ))}
          <TotalsContainer>
            <TotalsHeader>TOTAL</TotalsHeader>
            <TotalsValue>
              {formatNumWithMaxTwoDecimals(capacityTotal)}h
            </TotalsValue>
          </TotalsContainer>
        </MemberCapacityTableContainer>
      </>
    );
  }
}

export default WorkloadCapacity;
