import { memo, Ref, SVGProps } from 'react';
import styled from 'styled-components';

export interface SvgIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
  'data-testid'?: string;
  ref?: Ref<SVGSVGElement>;
}

const _SvgIcon = ({ color, title, children, ...rest }: SvgIconProps) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" $color={color} {...rest}>
      <title>{title}</title>
      {children}
    </Svg>
  );
};

interface SvgProps {
  $color?: string;
}

const Svg = styled.svg.attrs<SvgProps>(
  ({ $color }) => $color && { style: { fill: $color } }
)<SvgProps>`
  flex: none;
  font-weight: 400;
  vertical-align: middle;
`;

export const SvgIcon = memo(_SvgIcon);

export default SvgIcon;
