import CellContainer from './CellContainer';
import TaskTimerCell from 'views/Home/Tasks/TaskTimerCell';

const TimerCell = ({ row }) => {
  const task = row.original;

  return (
    <CellContainer
      taskProperty="bulk"
      row={row}
      styles={{ background: 'transparent' }}
    >
      <TaskTimerCell task={task} />
    </CellContainer>
  );
};
export default TimerCell;
