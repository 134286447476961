import React from 'react';
import cn from 'classnames';

const DayNavTab = ({
  dayOfWeek,
  dateNum,
  handleClick,
  isSelected,
  isToday
}) => {
  return (
    <div
      className={cn('day', {
        weekend: dayOfWeek === 'S',
        selected: isSelected,
        today: isToday
      })}
      onClick={handleClick}
    >
      <div className="day-name">{dayOfWeek}</div>
      <div className="date-num">{dateNum}</div>
    </div>
  );
};

export default DayNavTab;
