import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  ComponentRef
} from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
/* -------------------------------------------------------------------------- */
import { getActivePhasesByProject, getTeamSlug } from 'selectors';
import { getProjectHash } from 'ProjectsModule/selectors';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import { getSuggestionModule } from 'SuggestionModule/package/module';
import { getSelectedTeamId } from 'TeamsModule/selectors';
/* -------------------------------------------------------------------------- */
import { fetchMemberBudgets } from 'BudgetModule/actionCreators';
import {
  fetchPhaseTotals,
  navigateToProject,
  fetchPhases,
  fetchAllProjects,
  fetchBoardMembers
} from 'actionCreators';
import {
  useLoadOffices,
  useLoadRegions,
  useLoadDisciplines,
  useLoadSkills
} from 'SettingsModule/hooks';
/* -------------------------------------------------------------------------- */
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { useLoadPositions } from 'BudgetModule/hooks';
/* -------------------------------------------------------------------------- */
import { useToggle, useMount } from 'react-use';
import useUnassignedRolesByProjectAndPhases, {
  ProjectUnassignedMemberBudgetWithPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import {
  FilterStateIds,
  RequestStatusIds,
  requirementsSelectorPortalId,
  teamBuilderIsoStateId
} from './constants';
import { getCreatePhaseMembersRefetchActions } from './utils';
import {
  FindPeopleModalContextValuesType,
  AvailabilityViewTriggerSource,
  OnAddRequirementsClickArgs,
  OnDeleteRequirementArgs
} from 'SuggestionModule/components/FindPeople/contexts/types';
import {
  fetchEntityRequirements,
  updateEntityRequirements
} from 'RequirementsModule/actionCreators';
import { getRequirementsModule } from 'RequirementsModule/package';
import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';
import { getRequirementAssociationsByEntityIdHash } from 'RequirementsModule/selectors/entityRequirements';
import { makeEntitiesArrayForEntityRequirementFetch } from 'RequirementsModule/utils';
import { Modals, REPORT_VIEW_TYPE } from 'appConstants/navigation';
import { useFindPeopleFilterContext } from './filterConfig';
import useHasStateChanged from 'appUtils/hooks/useHasStateChanged';
import { FilterField } from 'FilterModule/constants';
import useActivityPhases from 'appUtils/hooks/useActivityPhases/useActivityPhases';
import {
  checkPhaseHasActivityPhase,
  getDefaultActivityPhase
} from 'ProjectsModule/utils/phaseDisplay';
import { fetchAccountsSuggestionForActivityPhaseMemberships } from 'SuggestionModule/actionCreators';
import { SuggestionsType } from 'SuggestionModule/constants';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import { FindPeople } from './FindPeople';
import { SplitScreen } from 'TimelinesModule/components/SplitScreen/SplitScreen';
import useAddRemoveUnassignedRoleFromPhasesDropdown from 'components/roles/dropdowns/UnassignedRolesDropdown/useAddRemoveUnassignedRoleFromPhasesDropdown';
import { AccountId } from 'models/account';
import styled from 'styled-components';
import { MemberAvailabilityTimelineFilterRef } from 'TimelinesModule/components/Timelines/MemberAvailabilityTimeline/MemberAvailabilityTimelineWithFilter';
import { MemberAvailabilityTimelineWithFilterContext } from 'TimelinesModule/components/Timelines/MemberAvailabilityTimeline/MemberAvailabilityTimelineWithFilterContext';
import { SplitDividerVariant } from 'TimelinesModule/components/SplitScreen/types';

const emptyObj = {};
const emptyArr = [];

/**
 * Return a list of phase ids that has the unassigned role
 */
const getActivePhasesOrder = ({
  phaseIds,
  getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
  phasesHash,
  memberBudgetId
}: {
  phaseIds: number[];
  getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId;
  phasesHash: ReturnType<typeof getFlatPhasesHash>;
  memberBudgetId: Nullable<number>;
}) => {
  return phaseIds.filter((id) => {
    const phase = phasesHash[id];

    return (
      phase &&
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
        {
          phaseId: id,
          memberBudgetId
        }
      )
    );
  });
};

const checkIfUnassignedRoleExistInProject = ({
  memberBudgetId,
  unassignedMemberBudgetsWithPositionForProjectHash
}: {
  memberBudgetId: Nullable<number>;
  unassignedMemberBudgetsWithPositionForProjectHash: Record<
    number,
    ProjectUnassignedMemberBudgetWithPosition
  >;
}) => {
  return memberBudgetId
    ? !!unassignedMemberBudgetsWithPositionForProjectHash[memberBudgetId]
    : false;
};

interface FindPeopleContainerProps {
  toggleModal: () => void;
  $projectId?: Nullable<number>;
  disableChangingProject?: boolean;
  $unassignedMemberBudgetId?: Nullable<number>;
}

const FindPeopleContainer = ({
  toggleModal,
  $projectId,
  disableChangingProject,
  $unassignedMemberBudgetId // Unused right now
}: FindPeopleContainerProps) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();

  const { currentFilter } = useFindPeopleFilterContext();

  /* --------------------------------- Project -------------------------------- */

  const [isProjectSelectorOpen, toggleIsProjectSelectorOpen] = useToggle(false);

  const selectedProjectId = currentFilter.project_ids[0] || null;

  /* ----------------------------- Unassigned Role ---------------------------- */
  const [
    selectedUnassignedMemberBudgetId,
    setSelectedUnassignedMemberBudgetId
  ] = useState<Nullable<number>>(null);

  const {
    showAISuggestionsMemberModalDemo: showDemoSuggestions,
    teamBuilderSplitScreenFlag
  } = useFeatureFlags();
  const projectHash = useAppSelector(getProjectHash);
  const phasesHash = useAppSelector(getFlatPhasesHash);
  const activePhasesByProject = useAppSelector(getActivePhasesByProject);
  const teamId = useAppSelector(getSelectedTeamId);
  const teamSlug = useAppSelector(getTeamSlug);
  const requirementAssociationsByProjectMembershipIdHash = useAppSelector(
    (state) =>
      getRequirementAssociationsByEntityIdHash(state, {
        entityRequirementType: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
      })
  );

  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
    getUnassignedMemberBudgetWithPositionForProject,
    unassignedMemberBudgetsWithPositionForProject,
    unassignedMemberBudgetsWithPositionForProjectHash
  } = useUnassignedRolesByProjectAndPhases({
    projectId: selectedProjectId
  });
  const { getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId } =
    useActivityPhases({ projectId: selectedProjectId });

  const { project, allProjectPhasesOrder, activeProjectPhasesOrder } =
    useMemo(() => {
      const project =
        selectedProjectId !== null ? projectHash[selectedProjectId] : undefined;

      const { allPhasesOrder = emptyArr } =
        selectedProjectId !== null
          ? activePhasesByProject[selectedProjectId] || emptyObj
          : emptyObj;

      return {
        project,
        allProjectPhasesOrder: (allPhasesOrder as number[]) || emptyArr,
        activeProjectPhasesOrder: getActivePhasesOrder({
          phaseIds: allPhasesOrder,
          phasesHash,
          getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
          memberBudgetId: selectedUnassignedMemberBudgetId
        })
      };
    }, [
      activePhasesByProject,
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
      phasesHash,
      projectHash,
      selectedProjectId,
      selectedUnassignedMemberBudgetId
    ]);

  const { status: fetchMemberSuggestionForPhasesRequestStatus } =
    useRequestStatus({
      requestStatusId: RequestStatusIds.fetchMemberSuggestionForPhases
    });

  const { status: fetchEntityRequirementsRequestStatus } = useRequestStatus({
    requestStatusId: RequestStatusIds.fetchEntityRequirements
  });

  const { status: fetchPhaseTotalsRequestStatus } = useRequestStatus({
    requestStatusId: RequestStatusIds.fetchPhaseTotals
  });

  const isFetchingMemberSuggestionForPhase =
    fetchMemberSuggestionForPhasesRequestStatus?.isLoading;

  const isFetchingEntityRequirements =
    fetchEntityRequirementsRequestStatus?.isExecuting;

  const isFetchingPhaseTotals = fetchPhaseTotalsRequestStatus?.isExecuting;

  /* --------------------------- Interactions --------------------------- */

  const resetUnassignedRoleAndSelectedPhaseIds = useCallback(() => {
    setSelectedUnassignedMemberBudgetId(null);
  }, []);

  const openBudgetModal = useCallback(() => {
    dispatch(
      navigateToProject({
        projectId: selectedProjectId,
        teamSlug,
        projectSlug: project?.slug,
        viewType: REPORT_VIEW_TYPE.BUDGET,
        openInNewWindow: true,
        modal: Modals.BUDGET,
        budgetViewType: REPORT_VIEW_TYPE.BUDGET
      })
    );
  }, [dispatch, project?.slug, selectedProjectId, teamSlug]);

  // /** Fetch entities and requirements */
  useLoadOffices({ shouldAutoLoad: true });
  useLoadRegions({ shouldAutoLoad: true });
  useLoadSkills({ shouldAutoLoad: true });
  useLoadPositions({ shouldAutoLoad: true });
  useLoadDisciplines({ shouldAutoLoad: true });

  const triggerFetchMembersSuggestion = useCallback(
    ({
      memberBudgetId,
      shouldShowSkeletonLoader
    }: {
      memberBudgetId: Nullable<number>;
      shouldShowSkeletonLoader?: boolean;
    }) => {
      const activityPhaseMembershipIds = allProjectPhasesOrder.reduce(
        (acc: number[], phaseId) => {
          const phase = phasesHash[phaseId];
          const hasActivityPhase = checkPhaseHasActivityPhase(phase);
          const activityPhases = hasActivityPhase
            ? phase?.activity_phases || []
            : [getDefaultActivityPhase(phase)];

          activityPhases.forEach((activityPhase) => {
            if (activityPhase && memberBudgetId) {
              const activityPhaseMembership =
                getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId({
                  activityPhaseId: activityPhase.id,
                  memberBudgetId
                });

              if (activityPhaseMembership) {
                if (hasActivityPhase) {
                  // Phase having activity phase means we only want the suggestion for the non-default activity phases
                  !activityPhase.is_default &&
                    acc.push(activityPhaseMembership.id);
                } else {
                  acc.push(activityPhaseMembership.id);
                }
              }
            }
          });

          return acc;
        },
        []
      );

      if (
        memberBudgetId &&
        activityPhaseMembershipIds.length &&
        selectedProjectId
      ) {
        dispatch(
          fetchAccountsSuggestionForActivityPhaseMemberships({
            activity_phase_membership_ids: activityPhaseMembershipIds,
            suggestion_type: SuggestionsType.Account_ActivityPhaseMembership,
            meta: {
              requestStatusId: RequestStatusIds.fetchMemberSuggestionForPhases,
              isoStateId: teamBuilderIsoStateId,
              isTakeLatest: true,
              ...(shouldShowSkeletonLoader
                ? {
                    isInitialFetch: true
                  }
                : {})
            }
          })
        );
      }
      setSelectedUnassignedMemberBudgetId(() => {
        const isSelectedUnassignedRoleExistOnProject =
          checkIfUnassignedRoleExistInProject({
            memberBudgetId,
            unassignedMemberBudgetsWithPositionForProjectHash
          });

        return isSelectedUnassignedRoleExistOnProject ? memberBudgetId : null;
      });
    },
    [
      allProjectPhasesOrder,
      dispatch,
      getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
      phasesHash,
      selectedProjectId,
      unassignedMemberBudgetsWithPositionForProjectHash
    ]
  );
  const { status: refetchAfterAddRoleToPhasesStatus } = useRequestStatus({
    requestStatusId: RequestStatusIds.refetchAfterAddRoleToPhases
  });

  // Refetch actions chain for adding roles to phases success
  const handleUnassignedRoleFromPhaseChange = useCallback(() => {
    /**
     * Adding 1 unassigned role to multiple phase/activity phase needs to be done as seperate action
     * because BE endpoint does not support bulk operations. (BE only supports bulk add multiple unassigned role to 1 phase/activity phase)
     * After every successful creation, there are several refetches that will be performed.
     * Imagine there is 10 different calls, we don't want to refetch everytime, which may cause unncessary call to BE
     * This logic is here to make sure to only perform those refetches after all the creation calls finished, and also to support isoState for phaseTotals
     */
    const actions = getCreatePhaseMembersRefetchActions({
      projectId: selectedProjectId as number
    });
    dispatchChain([actions], {
      chainId: RequestStatusIds.refetchAfterAddRoleToPhases,
      continueOnFailure: true,
      continueOnCancellation: true,
      cleanupChainStatuses: true
    });
  }, [selectedProjectId, dispatchChain]);

  const {
    AddRemoveUnassignedRoleFromPhasesDropdown,
    openAddRemoveUnassignedRoleFromPhasesDropdown,
    isAddingUnassignedRoleToPhases,
    addUnassignedRoleToPhasesSuccess
  } = useAddRemoveUnassignedRoleFromPhasesDropdown({
    projectId: selectedProjectId,
    unassignedMemberBudgetId: selectedUnassignedMemberBudgetId,
    shouldCreatePhaseMembersActionRefetch: false,
    phasesOrder: allProjectPhasesOrder
  });

  useEffect(() => {
    if (!isAddingUnassignedRoleToPhases && addUnassignedRoleToPhasesSuccess) {
      handleUnassignedRoleFromPhaseChange();
    }
  }, [
    isAddingUnassignedRoleToPhases,
    addUnassignedRoleToPhasesSuccess,
    handleUnassignedRoleFromPhaseChange
  ]);

  // Refetch suggestions after the above refetching action is success
  useEffect(() => {
    if (
      !refetchAfterAddRoleToPhasesStatus?.isExecuting &&
      refetchAfterAddRoleToPhasesStatus?.isSuccess
    ) {
      triggerFetchMembersSuggestion({
        memberBudgetId: selectedUnassignedMemberBudgetId
      });
    }
  }, [
    refetchAfterAddRoleToPhasesStatus?.isExecuting,
    refetchAfterAddRoleToPhasesStatus?.isSuccess,
    selectedUnassignedMemberBudgetId,
    triggerFetchMembersSuggestion
  ]);

  // When the unassigned role is removed from the project (no longer a membership of the project), then deselect it
  useEffect(() => {
    if (
      !checkIfUnassignedRoleExistInProject({
        memberBudgetId: selectedUnassignedMemberBudgetId,
        unassignedMemberBudgetsWithPositionForProjectHash
      })
    ) {
      setSelectedUnassignedMemberBudgetId(null);
    }
  }, [
    selectedUnassignedMemberBudgetId,
    unassignedMemberBudgetsWithPositionForProjectHash
  ]);

  /* -------------------------- Project Selector ------------------------- */
  const triggerFetchNewSelectedProjectData = useCallback(
    ({ projectId }) => {
      if (projectId) {
        dispatch(
          fetchMemberBudgets({
            projectId,
            onSuccess: [
              {
                successAction: ({ payload, response }) => {
                  if (response && response.length) {
                    const unassignedMemberBudgets = response.filter(
                      (mb) => !mb.account_id
                    );
                    const entities = makeEntitiesArrayForEntityRequirementFetch(
                      unassignedMemberBudgets,
                      ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
                    );
                    if (entities.length) {
                      dispatch(
                        fetchEntityRequirements({
                          entities,
                          team_id:
                            teamId ||
                            (unassignedMemberBudgets[0]?.team_id as number),
                          meta: {
                            requestStatusId:
                              RequestStatusIds.fetchEntityRequirements
                          }
                        })
                      );
                    }
                  }
                },
                selector: (payload, response) => ({ payload, response })
              }
            ]
          })
        );

        dispatch(fetchPhases({ projectId }));

        // Fetching the numbers to show on when assigning an unassigned role to a phase
        dispatch(
          fetchPhaseTotals({
            projectId,
            initial: true,
            filterStateId: FilterStateIds.fetchPhaseTotals,
            requestStatusId: RequestStatusIds.fetchPhaseTotals
          })
        );

        dispatch(
          fetchAllProjects({
            projectIds: [projectId],
            onSuccess: [
              {
                successAction: ({ payload, response }) => {
                  const project = response?.projects?.[0];
                  if (project) {
                    dispatch(fetchBoardMembers(project.board_id));
                  }
                },
                selector: (payload, response) => ({ payload, response })
              }
            ]
          })
        );
      }
    },
    [dispatch, teamId]
  );

  const hasSelectedProjectIdChanged = useHasStateChanged(selectedProjectId);

  /**
   * When new project is selected
   * - Reset the unassigned role and phase, so user has to rechoose
   * - Refetch member budgets and phase totals (budget data) for this project
   */
  useEffect(() => {
    if (hasSelectedProjectIdChanged) {
      triggerFetchNewSelectedProjectData({ projectId: selectedProjectId });
      resetUnassignedRoleAndSelectedPhaseIds();
    }
  }, [
    dispatch,
    resetUnassignedRoleAndSelectedPhaseIds,
    selectedProjectId,
    triggerFetchNewSelectedProjectData,
    currentFilter,
    hasSelectedProjectIdChanged
  ]);

  /**
   * If project id is given, overwrite the selected project in the filter and save
   * immediately to BE to store as the last selected project
   */
  useMount(() => {
    if ($projectId && selectedProjectId !== $projectId) {
      currentFilter.update(
        { project_ids: [$projectId] },
        { fieldsToSaveAfterUpdate: [FilterField.project_ids] }
      );
    }
  });

  /* ------------------------ Unassigned Role Selector ------------------------ */

  /**
   * Use this if you know the member budget id of the unassigned role
   * Will trigger a new member suggestion fetch
   */
  const handleSelectUnassignedRole = useCallback(
    ({ memberBudgetId }: { memberBudgetId: number }) => {
      if (memberBudgetId !== selectedUnassignedMemberBudgetId) {
        triggerFetchMembersSuggestion({
          memberBudgetId,
          shouldShowSkeletonLoader: true
        });
      }
    },
    [selectedUnassignedMemberBudgetId, triggerFetchMembersSuggestion]
  );

  // Specifically for selecting an item in the unassigned role dropdown
  const handleSelectUnassignedRoleFromDropdown = useCallback(
    (item: ProjectUnassignedMemberBudgetWithPosition) => {
      const newSelectedUnassignedMemberBudgetId = item.id;
      handleSelectUnassignedRole({
        memberBudgetId: newSelectedUnassignedMemberBudgetId as number
      });
    },
    [handleSelectUnassignedRole]
  );

  /* ------------------------------ Requirements ------------------------------ */

  const [isRequirementsSelectorOpen, toggleRequirementsSelector] =
    useToggle(false);

  const onDeleteRequirement = useCallback(
    ({ association_updates }: OnDeleteRequirementArgs) => {
      dispatch(
        updateEntityRequirements({
          team_id: teamId as number,
          association_updates
        })
      );
    },
    [dispatch, teamId]
  );

  const onAddRequirementsClick = useCallback(
    ({ memberBudgetId, projectMembershipId }: OnAddRequirementsClickArgs) => {
      toggleRequirementsSelector(true);
    },
    [toggleRequirementsSelector]
  );

  /* ---------------------------------- Variables ---------------------------------- */
  const numOfProjectUnassignedRoles =
    unassignedMemberBudgetsWithPositionForProject.length;

  const selectedUnassignedMemberBudget =
    getUnassignedMemberBudgetWithPositionForProject({
      memberBudgetId: selectedUnassignedMemberBudgetId
    });

  const [isAvailabilityViewActive, setSplitting] = useState(false);
  const splitScreenRef = useRef<ComponentRef<typeof SplitScreen>>(null);
  const handleSplitChange = useCallback((isSplitting: boolean) => {
    // clear selected selected ids
    if (!isSplitting) {
      setselectedAvailabilityViewTriggerSource(undefined);
      setSelectedAccountIdsToViewAvailability([]);
    }

    setSplitting(isSplitting);
  }, []);

  const [
    selectedAvailabilityViewTriggerSource,
    setselectedAvailabilityViewTriggerSource
  ] = useState<AvailabilityViewTriggerSource>();

  const [
    selectedAccountIdsToViewAvailability,
    setSelectedAccountIdsToViewAvailability
  ] = useState<AccountId[]>([]);

  const memberAvailabilityFilterRef =
    useRef<MemberAvailabilityTimelineFilterRef>(null);

  const toggleAvailabilityView: FindPeopleModalContextValuesType['toggleAvailabilityView'] =
    useCallback(
      ({ triggerSource, accountIds }) => {
        // split view when split screen is not active
        if (!selectedAvailabilityViewTriggerSource) {
          splitScreenRef.current?.split();
          setselectedAvailabilityViewTriggerSource(triggerSource);
          setSelectedAccountIdsToViewAvailability(accountIds);
          return;
        }

        // select another phase when split screen is active
        if (
          selectedAvailabilityViewTriggerSource.type !== triggerSource.type ||
          selectedAvailabilityViewTriggerSource.id !== triggerSource.id
        ) {
          setselectedAvailabilityViewTriggerSource(triggerSource);
          setSelectedAccountIdsToViewAvailability(accountIds);
          return;
        }

        // close split view when split screen is active
        splitScreenRef.current?.collapse();
        setselectedAvailabilityViewTriggerSource(undefined);
        setSelectedAccountIdsToViewAvailability([]);
      },
      [selectedAvailabilityViewTriggerSource]
    );

  useEffect(() => {
    memberAvailabilityFilterRef.current?.updateFilter({
      account_ids: selectedAccountIdsToViewAvailability
    });
  }, [selectedAccountIdsToViewAvailability]);

  const activePhasesNum = activeProjectPhasesOrder.length;

  const contextValues: FindPeopleModalContextValuesType = useMemo(
    () => ({
      project,
      selectedProjectId,
      selectedUnassignedMemberBudgetId,
      numOfProjectUnassignedRoles,
      activeProjectPhasesOrder,
      allProjectPhasesOrder,
      phasesHash,
      showDemoSuggestions,
      isProjectSelectorOpen,
      isRequirementsSelectorOpen,
      isFetchingMemberSuggestionForPhase,
      isFetchingEntityRequirements,
      isFetchingPhaseTotals,
      requirementAssociationsByProjectMembershipIdHash,
      teamId,
      teamSlug,
      activePhasesNum,
      selectedUnassignedMemberBudget,
      disableChangingProject,
      toggleRequirementsSelector,
      handleSelectUnassignedRole,
      handleSelectUnassignedRoleFromDropdown,
      resetUnassignedRoleAndSelectedPhaseIds,
      triggerFetchMembersSuggestion,
      onDeleteRequirement,
      onAddRequirementsClick,
      openBudgetModal,
      toggleIsProjectSelectorOpen,
      openAddRemoveUnassignedRoleFromPhasesDropdown,
      toggleModal,
      toggleAvailabilityView,
      isAvailabilityViewActive,
      selectedAvailabilityViewTriggerSource
    }),
    [
      teamId,
      project,
      selectedProjectId,
      selectedUnassignedMemberBudgetId,
      numOfProjectUnassignedRoles,
      activeProjectPhasesOrder,
      allProjectPhasesOrder,
      phasesHash,
      showDemoSuggestions,
      isRequirementsSelectorOpen,
      isFetchingEntityRequirements,
      isFetchingMemberSuggestionForPhase,
      requirementAssociationsByProjectMembershipIdHash,
      selectedUnassignedMemberBudget,
      isFetchingPhaseTotals,
      teamSlug,
      activePhasesNum,
      disableChangingProject,
      toggleRequirementsSelector,
      handleSelectUnassignedRole,
      handleSelectUnassignedRoleFromDropdown,
      resetUnassignedRoleAndSelectedPhaseIds,
      triggerFetchMembersSuggestion,
      onDeleteRequirement,
      onAddRequirementsClick,
      openBudgetModal,
      isProjectSelectorOpen,
      toggleIsProjectSelectorOpen,
      openAddRemoveUnassignedRoleFromPhasesDropdown,
      toggleModal,
      toggleAvailabilityView,
      isAvailabilityViewActive,
      selectedAvailabilityViewTriggerSource
    ]
  );

  return (
    <DynamicModuleLoader
      modules={[
        getBudgetModule(),
        getSettingsModule(),
        getRequirementsModule(),
        getSuggestionModule()
      ]}
    >
      <FindPeopleModalContext.Provider value={contextValues}>
        <div id={requirementsSelectorPortalId} />
        {teamBuilderSplitScreenFlag ? (
          <StyledSplitScreen
            ref={splitScreenRef}
            mainView={
              <>
                <FindPeople />
                {AddRemoveUnassignedRoleFromPhasesDropdown}
              </>
            }
            splitView={
              <MemberAvailabilityTimelineWithFilterContext
                filterRef={memberAvailabilityFilterRef}
              />
            }
            onSplitChange={handleSplitChange}
            splitScreenConfig={{
              minHeights: {
                mainView: 80
              },
              splitDividerVariant: SplitDividerVariant.Thick,
              showCloseButton: true
            }}
          />
        ) : (
          <>
            <FindPeople />
            {AddRemoveUnassignedRoleFromPhasesDropdown}
          </>
        )}
      </FindPeopleModalContext.Provider>
    </DynamicModuleLoader>
  );
};

export default FindPeopleContainer;

const StyledSplitScreen = styled(SplitScreen)`
  .mainViewContainer {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
`;
