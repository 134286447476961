import { currencyFormatter } from 'appUtils/hooks/useFormatCurrency/utils';

export const calculateBudgetDisplayPercentages = ({
  spent,
  planned,
  total
}) => {
  const isOverBudget = spent + planned > total;
  const residual = total - spent - planned;
  const spentPercent = total ? ((spent * 100) / total).toFixed(0) : 100;
  const plannedPercent = total ? ((planned * 100) / total).toFixed(0) : 0;
  const residualPercent = total ? ((residual * 100) / total).toFixed(0) : 0;
  return {
    isOverBudget,
    residual,
    spentPercent,
    plannedPercent,
    residualPercent
  };
};
const getTooltipValueForPermissionLevel = (
  value,
  isWorkloadManager,
  isDisplayHours = false,
  currencyCode
) => {
  return isWorkloadManager
    ? ''
    : `
${
  !isDisplayHours
    ? currencyCode
      ? currencyFormatter({
          currencyCode,
          options: { maximumFractionDigits: 0 }
        }).format(value)
      : ''
    : value.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
      })
}${isDisplayHours ? 'h' : ''}
`;
};

export const getBudgetTooltipContent = ({
  spent = 0,
  planned = 0,
  total = 0,
  text = 'Phase Fee',
  startDate,
  endDate,
  isDefaultPhase,
  isProject,
  isWorkloadManager,
  hideRemaining,
  currencyCode,
  isDisplayHours = false
}) => {
  const {
    isOverBudget,
    residual,
    spentPercent,
    plannedPercent,
    residualPercent
  } = calculateBudgetDisplayPercentages({
    spent,
    planned,
    total
  });

  const isPhaseNameLong = text?.length > 15;

  return `
    <div class='budget-modal-main-tooltip${
      isPhaseNameLong ? ' wide-tooltip' : ' normal-tooltip'
    }'style='border:1px solid #CDCDCD; border-radius: 3px;'>
    <div class='budget-tooltip-row main'>

    <div class='main-budget-text'>
      ${
        !isDefaultPhase && !isProject
          ? `<div class="phase-milestone-icon"></div>`
          : `<div></div>`
      }
      <div class="title-dates-container">
       ${
         !isDefaultPhase
           ? `<div class="title">${text}</div>`
           : `<div class="title">Total Budget</div>`
       }
       ${
         startDate && endDate && !isDefaultPhase
           ? `<div class="date">${startDate} - ${endDate} </div>`
           : ''
       }
       </div>
    </div>
    <div class='budget-dot budget-tooltip-dot'></div>
    <div class='budget-tooltip-percent'></div>
    <div class='budget-tooltip-cost main-budget-cost'> ${getTooltipValueForPermissionLevel(
      total || 0,
      isWorkloadManager,
      isDisplayHours,
      currencyCode
    )}</div>

    </div>
      <div class='budget-tooltip-row'>
      <div class='spent-dot budget-tooltip-dot'></div>
      <div class='budget-tooltip-percent'>${spentPercent}%</div>
      <div class='budget-type'>Spent</div>
      <div class='budget-tooltip-cost'>
        ${getTooltipValueForPermissionLevel(
          spent,
          isWorkloadManager,
          isDisplayHours,
          currencyCode
        )}
      </div>

      </div>

    <div class='budget-tooltip-row'>
        <div class='planned-dot budget-tooltip-dot'></div>
        <div class='budget-tooltip-percent'>${plannedPercent}%</div>
        <div class='budget-type'>Planned</div>
        <div class='budget-tooltip-cost'>
          ${getTooltipValueForPermissionLevel(
            planned,
            isWorkloadManager,
            isDisplayHours,
            currencyCode
          )}
        </div>

      </div>

    ${
      hideRemaining
        ? ''
        : isOverBudget
        ? ` <div class='budget-tooltip-row'>
          <div class='overdue-dot budget-tooltip-dot'></div>
          <div class='budget-tooltip-percent'>${-residualPercent}%</div>
          <div class='budget-type'>Overbudget</div>
          <div class='budget-tooltip-cost'>
            ${getTooltipValueForPermissionLevel(
              residual,
              isWorkloadManager,
              isDisplayHours,
              currencyCode
            )}
          </div>

          </div>
          </div>
      `
        : ` <div class='budget-tooltip-row'>
        <div class='remaining-dot budget-tooltip-dot'></div>
        <div class='budget-tooltip-percent'>${residualPercent}%</div>
        <div class='budget-type'>Remaining</div>
        <div class='budget-tooltip-cost'>
          ${getTooltipValueForPermissionLevel(
            residual,
            isWorkloadManager,
            isDisplayHours,
            currencyCode
          )}
        </div>

        </div>
        </div>
    `
    }
    </div>
   `;
};

export const getBudgetProgressBarTooltipContent = ({
  spentCostOrHours,
  plannedCostOrHours,
  total = 0,
  estimatedCostOrHours,
  budgetPercent,
  headerTitle,
  isWorkloadManager = false,
  isDisplayHours = false,
  currencyCode,
  hideBudget = false,
  showFeeHeader = true
}) => {
  const {
    isOverBudget,
    residual,
    spentPercent,
    plannedPercent,
    residualPercent
  } = calculateBudgetDisplayPercentages({
    spent: spentCostOrHours,
    planned: plannedCostOrHours,
    total: estimatedCostOrHours
  });

  const isTitleLong = headerTitle?.length > 15;

  return `
    <div class='budget-modal-main-tooltip${
      isTitleLong ? ' wide-tooltip' : ' normal-tooltip'
    }'style='border:1px solid #CDCDCD; border-radius: 3px;'>
      <div class='budget-tooltip-row main'>
        <div class='main-budget-text'>
          <div class="title-container with-dot">
            ${
              showFeeHeader
                ? !hideBudget
                  ? `<div class='fee-dot budget-tooltip-dot'></div>`
                  : `<div class='tooltip-budget-icon'></div>`
                : ''
            }
            <div class="title">${headerTitle}</div>
          </div>
        </div>
        <div class="budget-tooltip-cost main-budget-cost">
          ${getTooltipValueForPermissionLevel(
            hideBudget ? estimatedCostOrHours : total,
            isWorkloadManager,
            isDisplayHours,
            currencyCode
          )}
        </div>
      </div>
      ${
        !hideBudget
          ? `<div class="budget-tooltip-row budget-row">
              <div class='tooltip-budget-icon'></div>
              <div class="budget-container">
                <div>Budget</div>
                <div class='bar'>|</div>
                <div>${budgetPercent}%</div>
              </div>
              <div class="budget-tooltip-cost">
                ${getTooltipValueForPermissionLevel(
                  estimatedCostOrHours,
                  isWorkloadManager,
                  isDisplayHours,
                  currencyCode
                )}
              </div>
           </div>`
          : ''
      }  
      

      <div class='budget-tooltip-row bar-tooltip first'>
        <div class='spent-dot budget-tooltip-dot'></div>
        <div class='budget-tooltip-percent'>${spentPercent}%</div>
        <div class='budget-type'>Spent</div>
        <div class='budget-tooltip-cost'>
          ${getTooltipValueForPermissionLevel(
            spentCostOrHours,
            isWorkloadManager,
            isDisplayHours,
            currencyCode
          )}
        </div>
      </div>

      <div class='budget-tooltip-row bar-tooltip planned'>
          <div class='planned-dot budget-tooltip-dot'></div>
          <div class='budget-tooltip-percent'>${plannedPercent}%</div>
          <div class='budget-type'>Planned</div>
          <div class='budget-tooltip-cost'>
            ${getTooltipValueForPermissionLevel(
              plannedCostOrHours,
              isWorkloadManager,
              isDisplayHours,
              currencyCode
            )}
          </div>
      </div>

      <div class='budget-tooltip-row bar-tooltip remaining-row'>
        ${
          isOverBudget
            ? `<div class='overdue-dot budget-tooltip-dot'></div>`
            : `<div class="remaining-dot budget-tooltip-dot"></div>`
        }
        <div class='budget-tooltip-percent'>${
          isOverBudget ? -residualPercent : residualPercent
        }%</div>
        <div class='budget-type'>${
          isOverBudget ? 'Overbudget' : 'Remaining'
        }</div>
        <div class='budget-tooltip-cost'>
          ${getTooltipValueForPermissionLevel(
            isOverBudget ? -residual : residual,
            isWorkloadManager,
            isDisplayHours,
            currencyCode
          )}
        </div>
      </div>
    </div>
   `;
};

/**
 * Calculates formatted currency with currencyFormatter
 * @param {Object} budgetFeeInput inputs required to get formatted currency
 * @param {string} budgetFeeInput.fee - formatted fee for display
 * @param {string} budgetFeeInput.budget - formatted budget for display
 * @param {number|string} budgetFeeInput.percent - the percentage of budget by fee
 * @returns {string} the html string for tooltip
 */

export const getBudgetFeeTooltip = ({ fee, budget, percent }) => {
  return `
  <div class='budget-modal-main-tooltip budget-fee-tooltip' style='border:1px solid #CDCDCD; border-radius: 3px;'>
    <div class='budget-fee-tooltip-row'>
      <div class='tooltip-budget-icon'></div>
      <div class='label'>Budget</div>
      <div class='fee-budget-value'>${budget}</div>
      <div class='bar-percent'>
        <div>|</div>
        <div>${percent}%</div>
      </div>
    </div>
    <div class='budget-fee-tooltip-row'>
      <div class='budget-tooltip-dot fee-dot'></div>
      <div class='label'>Fee</div>
      <div class='fee-budget-value'>${fee}</div>
    </div>
  </div>
        `;
};
