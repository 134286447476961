import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
`;

const HoursCell = ({ row }) => {
  const { userActivity } = row.original;
  const hours = userActivity?.display_data?.time_entry?.hours;
  return (
    <StyledText>{!isNaN(parseFloat(hours)) ? `${hours}h` : ''}</StyledText>
  );
};

export default HoursCell;
