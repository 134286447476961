import { put, call, take, actionChannel, fork } from 'redux-saga/effects';
import { buffers, channel } from 'redux-saga';

/** For queueing actions */
export function* createChannel(type, worker, buffer = buffers.expanding(20)) {
  const requestChan = yield actionChannel(type, buffer);
  while (true) {
    const action = yield take(requestChan);
    yield call(
      typeof worker === 'object' ? worker[action.type] : worker,
      action
    );
  }
}

function* handleRequest(requestChan) {
  while (true) {
    const { action, worker } = yield take(requestChan);
    yield call(worker, action);
  }
}

/** For queueing actions, with max {numThreads} actions running in parallel */
export function* createThreadedChannel(
  type,
  worker,
  numThreads = 3,
  buffer = buffers.expanding(20)
) {
  const requestChan = yield call(channel, buffer);
  for (var i = 0; i < numThreads; i++) {
    yield fork(handleRequest, requestChan);
  }

  while (true) {
    const action = yield take(type);
    const payload = {
      action,
      worker: typeof worker === 'object' ? worker[action.type] : worker
    };
    yield put(requestChan, payload);
  }
}
