import styled from 'styled-components';
import {
  Header,
  ScrollContainer,
  StyledTwoPaneModal
} from 'components/Modals/themes/TwoPaneModal';

export const StyledScopeModal = styled(StyledTwoPaneModal)`
  ${(props) =>
    props.$isProjectViewScope &&
    `
    width: 533px;
    position: fixed;
    right: 0;
    top: 0;
    .modal-content {
      max-height: unset;
      height: 100vh;
    }
  `}

  ${Header} {
    padding-top: 32px;
    padding-bottom: 30px;
  }
  ${Header},
  ${ScrollContainer} {
    padding-left: 45px;
    padding-right: 45px;
  }
`;
