import { DEFAULT_TASK_STATUSES } from 'CsvImportsModule/constants';
import {
  getRowValuesForEntityType,
  getProjectIdsThatMatchTitleAndNumber,
  enteredFieldIsTrue
} from './utils';

import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportProject
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingProjectsById: Record<number, CsvImportProject>;
  existingProjectsHashByTitle: any;
  existingProjectsHashByNumber: any;
  newTaskStatuses: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [taskStatus: string]: string };
    };
  }>;
}

export const taskStatusOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingProjectsById,
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    newTaskStatuses
  } = additionalParams;

  const { enteredProjectTitle, enteredProjectNumber, enteredIsScope } =
    getRowValuesForEntityType(row, entityType);

  if (
    !(enteredProjectTitle && enteredProjectNumber) ||
    enteredFieldIsTrue(enteredIsScope)
  )
    return [''];

  const projectIdsObjectThatMatchTitleAndNumber =
    getProjectIdsThatMatchTitleAndNumber({
      existingProjectsHashByTitle,
      existingProjectsHashByNumber,
      enteredProjectTitle,
      enteredProjectNumber
    });

  const projectIdsThatMatchTitleAndNumber = Object.keys(
    projectIdsObjectThatMatchTitleAndNumber
  );

  const rowHasMatchingProjects = projectIdsThatMatchTitleAndNumber.length > 0;

  // a combined list of all task statuses for the projects that match the title and number
  // starts with empty string, followed by the new task statuses matched by project title and number,
  // then the matched task statuses that already exists for the project title and number, this is
  // default task statuses if the project entered is new
  const taskStatuses = new Set<string>(['']);
  const matchedNewTaskStatuesByEnteredProjectTitleAndNumber =
    newTaskStatuses.current[enteredProjectTitle]?.[enteredProjectNumber];
  if (matchedNewTaskStatuesByEnteredProjectTitleAndNumber)
    Object.keys(matchedNewTaskStatuesByEnteredProjectTitleAndNumber).forEach(
      (taskStatus) => taskStatuses.add(taskStatus)
    );
  if (rowHasMatchingProjects)
    projectIdsThatMatchTitleAndNumber.forEach((projectId) => {
      existingProjectsById[+projectId]?.task_statuses?.forEach((taskStatus) =>
        taskStatuses.add(taskStatus)
      );
    });
  // new project gets the default task statuses
  else
    DEFAULT_TASK_STATUSES.forEach((taskStatus) => taskStatuses.add(taskStatus));
  return Array.from(taskStatuses);
};
