import styled from 'styled-components';
import theme from 'theme';
import LinkIcon from 'icons/LinkIcon';
import BluePencilIcon from 'icons/PencilIcon';
import TaskPencilBlueIcon from 'icons/TaskPencilBlueIcon';
import AddPlusIcon from 'icons/AddPlusIcon';

const { colors } = theme;

export const Dropdown = styled.div`
  width: 235px;
  font-weight: 500;
`;

export const PlaceholderText = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: ${({ isGray }) =>
    isGray ? theme.colors.colorTranslucentGray3 : theme.colors.colorPureWhite};
  padding: 15px 15px 5px;
`;

export const HeaderText = styled.div`
  font-size: 16px;
  color: ${colors.colorSemiDarkGray1};
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 340px;
  width: 100%;
  background-color: ${({ isGray }) =>
    isGray ? theme.colors.colorTranslucentGray3 : theme.colors.colorPureWhite};
  padding: 0px 12px;
`;

export const ActivityList = styled.div`
  width: 100%;
  min-height: 48px;
  border: solid 1px ${colors.colorPaleGray2};
`;

export const ActivityItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0px 20px;
  background-color: ${colors.colorPureWhite};
  font-size: 14px;
  transition: 0.3s all ease;
  cursor: pointer;

  &:hover {
    background-color: ${colors.colorTranslucentGray4};
  }
`;

export const AddActivity = styled(ActivityItem)`
  display: flex;
  align-items: center;
  color: ${({ isFilterResultEmpty, isAdmin }) =>
    isFilterResultEmpty && isAdmin
      ? colors.colorRoyalBlue
      : colors.colorMediumGray1};
  transition: 0.2s all ease;

  &:hover {
    background-color: ${colors.colorPureWhite};
    color: ${(props) =>
      props.isAdmin ? colors.colorRoyalBlue : colors.colorMediumGray1};
  }
`;

export const Input = styled.input`
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 5px;
  border: solid 1px ${colors.colorPaleGray2};
  font-size: 14px;
  color: ${colors.colorSemiDarkGray1};

  &::-webkit-input-placeholder {
    color: ${colors.colorLightGray3};
  }
`;

export const ActivityTitle = styled.input`
  font-size: 14px;
  color: ${colors.colorSemiDarkGray2};
  flex: 1;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const PencilIcon = styled(BluePencilIcon)`
  justify-self: flex-end;
  transition: all 0.15s ease;
  opacity: 0;

  ${ActivityItem}:hover & {
    opacity: ${(props) => (props.isAdmin ? 1 : 0.65)};
  }
`;

export const CloseIcon = styled.img`
  position: ${({ position }) => position || 'relative'};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'inline-block')};
  width: 8px;
  height: 8px;
  margin-left: auto;
  align-self: center;
`;

export const HelpIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px 5px;
`;

export const DeleteRow = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background-color: ${colors.colorSemiDarkGray1};
`;

export const DeleteText = styled.span`
  height: 14px;
  color: ${colors.colorPureWhite};
  font-size: 10px;
  margin: 0px 5px;
`;

export const ScrollContainer = styled.div`
  border-bottom: solid 1px #e9e9e9;
  overflow-y: auto;
`;

export const FieldHeader = styled.div`
  font-weight: 600;
  font-size: 10px;
  margin-top: 15px;
  text-transform: uppercase;
  color: ${theme.colors.colorMediumGray4};
`;

export const FieldDropdown = styled.div`
  font-size: 13px;
  border-radius: 2px;
  color: ${theme.colors.colorSemiDarkGray1};
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.invalid
        ? props.theme.colors.colorDeleteRed
        : props.theme.colors.colorPaleGray6};
  background: ${theme.colors.colorPureWhite};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer !important;
  justify-content: space-between;
  transition: border ${(props) => (props.invalid ? 0 : 150)}ms ease-in-out;
`;

export const DropdownSelection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  justify-content: space-between;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    #karat-right {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const TextArea = styled.textarea`
  border: 0;
  resize: none;
  width: 100%;
  outline: none;
  border-top: 1px solid ${theme.colors.colorLightGray5};
  padding: 8px 12px;
  color: ${theme.colors.colorSemiDarkGray1};

  &::placeholder {
    color: ${theme.colors.colorMediumGray1};
  }
`;

export const EmptyTextContainer = styled.div`
  font-size: 12px;
  padding: 5px;
  line-height: 1.2;
`;

export const StyledRowItemPOW = styled.div`
  padding: 0 12px;
  width: 100%;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  font-size: 12px;
  ${(props) => props.color && `color: ${props.color};`}
`;

export const ArchivedText = styled.div`
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 5px 15px;
  border: none;
  font-size: 14px;
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid ${theme.colors.colorRoyalBlue};
  }
`;

export const BackKaratContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  width: 30px;
  height: 100%;

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;
export const CloseIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 30px;
  height: 100%;
  justify-content: flex-end;
  cursor: pointer;
  visibility: ${(isHidden) => (isHidden ? 'hidden' : 'visible')};
  &:hover {
    path {
      stroke: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const HeaderContainerStep = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
`;

export const LinkedContainer = styled.div`
  color: ${theme.colors.colorMediumGray1};
`;
export const StyledLinkIcon = styled(LinkIcon)`
  margin-right: 4px;
  path {
    fill: ${theme.colors.colorMediumGray1};
  }
`;

export const StandardActivitiesHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const DoneButton = styled.div`
  background: ${({ theme }) => theme.colors.colorRoyalBlue};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.colorPureWhite};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.colorDeeperRoyalBlue};
  }

  .disabled {
    background: ${({ theme }) => theme.colors.colorMediumGray1};
  }
`;

export const ActivityListContainer = styled.div`
  max-width: 420px;
  margin: 0 auto;
`;

export const ActivityListHeader = styled.div`
  font-family: 'Proxima-nova';
  color: ${theme.colors.colorCalendarGray};
  font-size: 13px;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid ${theme.colors.colorMediumGray4};
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const StyledItemContainer = styled.div`
  font-size: 12px;
  padding: 10px 10px;
  border-bottom: ${(props) =>
    props.isDragging ? 'none' : `1px solid ${theme.colors.colorPaleGray5}`};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActivitiesSectionContainer = styled.div`
  min-height: 50px;
`;
export const NewActivityContainer = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  padding: 10px 0px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledAddPlusIcon = styled(AddPlusIcon)`
  margin: 0px 2px;
`;

export const StyledEditIcon = styled(TaskPencilBlueIcon)`
  justify-self: flex-end;
  transition: all 0.15s ease;
  opacity: 1;
`;

export const StyledDiamondIconContainer = styled.span`
  padding-right: 3px;
`;

export const StandardLabel = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 11px;
  color: ${theme.colors.colorLightGray15};
`;
