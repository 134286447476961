import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { createMemberBudget } from 'BudgetModule/actionCreators';
import { fetchProjectById, addBulkMembersToProject } from 'actionCreators';
import Popover from 'components/Popover';
import BulkPositionDropdown from 'components/roles/dropdowns/BulkPositionDropdown';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import AddMembersIconNoClipPath from 'icons/AddMembersIconNoClipPath';
import ManWithQuestionMarkIcon from 'icons/ManWithQuestionMarkIcon';
import ThreePeopleIcon from 'icons/ThreePeopleIcon';
import { serializeRoleId } from 'components/roles/utils.js';

import {
  makeGetProjectMemberships,
  getSelectedTeamId,
  makeGetProjectById
} from 'selectors';
import keyBy from 'lodash/keyBy';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const AddMembersDropdown = ({
  group,
  projectId,
  children,
  handleOpenAddMembersForm,
  handleOpenFindPeopleModal,
  setShowMemberSuggestionsOnOpen,

  // Selectors
  projectMemberships,
  selectedTeamId,
  project,

  // Action creators
  fetchProjectById,
  createMemberBudget,
  addBulkMembersToProject
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [rolesDropdownOpen, setRolesDropdownOpen] = useState(false);
  const [membersDropdownOpen, setMembersDropdownOpen] = useState(false);
  const { newMembersSuggestionTableFlag } = useFeatureFlags();
  const dropdownRef = useRef(null);

  const openDropdownMenu = () => {
    fetchProjectById(projectId);
    setDropdownOpen(true);
  };
  const closeDropdownMenu = () => {
    setDropdownOpen(false);
  };
  const openRolesDropdown = () => {
    setDropdownOpen(false);
    setRolesDropdownOpen(true);
  };
  const closeRolesDropdown = () => {
    setRolesDropdownOpen(false);
  };
  const openMembersDropdown = () => {
    setDropdownOpen(false);
    setMembersDropdownOpen(true);
  };
  const closeMembersDropdown = () => {
    setMembersDropdownOpen(false);
  };
  const openAddMembersModal = () => {
    handleOpenAddMembersForm(group);
    closeDropdownMenu();
  };
  const openSuggestionsModal = () => {
    if (newMembersSuggestionTableFlag) {
      handleOpenFindPeopleModal({ projectId });
    } else {
      setShowMemberSuggestionsOnOpen();
      handleOpenAddMembersForm(group);
      closeDropdownMenu();
    }
  };

  const memberFilter = (members) => {
    const membershipsByAccountId = keyBy(
      projectMemberships,
      (item) => item.account?.id
    );
    return members.filter(
      (member) => !membershipsByAccountId[member?.account?.id]
    );
  };

  const handleAddMembers = (members) => {
    if (members.length) {
      const memberIds = members.map((member) => member.account.id);
      const boardId = project.board_id;
      const permissions = { projectId, teamId: selectedTeamId };
      addBulkMembersToProject({
        projectId,
        memberIds,
        boardId,
        permissions
      });
    }
  };

  const handleAddUnassignedRoles = (
    roles,
    unassignedRoleSelectedRates = {}
  ) => {
    const newRoles = roles.map((role) => {
      const uniqueRoleId = serializeRoleId(role);
      if (!unassignedRoleSelectedRates[uniqueRoleId]) {
        return role;
      }
      return { ...role, rate_id: unassignedRoleSelectedRates[uniqueRoleId].id };
    });

    const memberBudgets = newRoles.map((role) => {
      return {
        position_id: role.id,
        // When adding multiple roles, may have to apply rate to the memberBudget.
        ...(role.rate_id && { rate_id: role.rate_id })
      };
    });

    createMemberBudget({
      projectId,
      memberBudgets: memberBudgets
    });
    closeRolesDropdown();
  };

  return (
    <AddMembersDropdownContainer onClick={openDropdownMenu}>
      <AddMemberBox ref={dropdownRef}>{children}</AddMemberBox>
      {dropdownOpen && (
        <Popover
          isOpen
          target={dropdownRef}
          closePopover={closeDropdownMenu}
          className="planner-add-member-dropdown with-border-radius"
        >
          <StyledDropdownItem onClick={openMembersDropdown}>
            <div className="icon-container">
              <AddMembersIconNoClipPath width="14" height="14" />
            </div>
            Add Project Members
          </StyledDropdownItem>
          <StyledDropdownItem onClick={openRolesDropdown}>
            <div className="icon-container">
              <ManWithQuestionMarkIcon width="12" height="12" />
            </div>
            Add Unassigned Roles
          </StyledDropdownItem>
          <StyledDropdownItem onClick={openSuggestionsModal}>
            <div className="icon-container">
              <ThreePeopleIcon width="14" height="14" />
            </div>
            Team Builder
          </StyledDropdownItem>
        </Popover>
      )}
      {rolesDropdownOpen && (
        <BulkPositionDropdown
          onClose={closeRolesDropdown}
          onAdd={handleAddUnassignedRoles}
          targetRef={dropdownRef}
          projectId={projectId}
          excludeExisiting
          closeOnSelect
          isOnPlanner
          headerTitle="Add Roles"
        />
      )}
      {membersDropdownOpen && (
        <BulkMemberSelector
          alwaysOpen
          excludeProjectMembers
          handleDone={handleAddMembers}
          memberFilter={memberFilter}
          membershipLevel={'project'}
          renderSelect={() => null}
          hideFooter
          target={dropdownRef}
          onClose={closeMembersDropdown}
          isOnPlanner
        />
      )}
    </AddMembersDropdownContainer>
  );
};

const projectIdGetter = (state, ownProps) => ownProps.projectId;
const makeMapStateToProps = () => {
  const getProjectMemberships = makeGetProjectMemberships({ projectIdGetter });
  const getProjectById = makeGetProjectById({ projectIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    projectMemberships: getProjectMemberships(state, ownProps),
    selectedTeamId: getSelectedTeamId(state),
    project: getProjectById(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchProjectById,
  createMemberBudget,
  addBulkMembersToProject
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AddMembersDropdown);

const AddMembersDropdownContainer = styled.div`
  color: ${theme.colors.colorMediumGray9};
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  height: 100%;
  cursor: pointer;
`;

const AddMemberBox = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDropdownItem = styled.div`
  font-size: 13px;
  padding: 6px 12px;
  color: ${theme.colors.colorMediumGray9};
  margin: 1px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .icon-container {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }

  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;
