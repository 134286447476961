import * as Sentry from '@sentry/browser';
import verifyDomain from './verifyDomain';

const isPermissionMask = (role) => !!role.project_mask;
const isPermissionAdmin = (role) => !!role.is_admin;

const makePermissionFlags = () => ({
  adminPermissionRequired: false,
  adminPermissionMet: false,
  permissionMet: false,
  maskPermissionBlocked: false
});
const getRoleToCheck = (role, roleTemplates) =>
  typeof role === 'string' || typeof role === 'number'
    ? roleTemplates[role]
    : role;
const checkOneOffPermissions = (oneOffPermissions, actionType) => {
  if (oneOffPermissions) {
    return Object.entries(oneOffPermissions).some(
      ([, permission]) => permission[actionType]
    );
  }
  return false;
};

const reducePermissions = (
  actionType,
  permissions,
  roles,
  roleTemplates,
  oneOffPermissions
) =>
  permissions.reduce((permissionFlags, permission) => {
    // default to an empty object if no role is found - has no impact on what the result from other permissions will be.
    // the role should always exist but there have been cases where stale permissions are returned to FE with no corresponding role.

    // New permissions identifiers are not on user permission end point yet.
    // permissionRequirementsMet === undefined occurs when the permission is not yet implemented.
    // if the permission identifier doesnt exist in the response just assume we have permission by default.
    const role = roles[permission.role_id] || {};
    const roleToCheck = getRoleToCheck(role, roleTemplates) || {};
    const permissionRequirementsMet = roleToCheck[actionType];
    const isMask = isPermissionMask(roleToCheck);
    const isAdmin = isPermissionAdmin(roleToCheck);

    // See if permission comes from one off.
    if (!permissionFlags.permissionMet) {
      permissionFlags.permissionMet = checkOneOffPermissions(
        oneOffPermissions,
        actionType
      );
    }

    if (isAdmin) {
      permissionFlags.adminPermissionRequired = true;
      if (
        permissionRequirementsMet ||
        permissionRequirementsMet === undefined
      ) {
        permissionFlags.adminPermissionMet = true;
      }
    }
    if (isMask) {
      if (permissionRequirementsMet) {
        permissionFlags.maskPermissionBlocked = true;
      }
    } else if (permissionRequirementsMet) {
      permissionFlags.permissionMet = true;
    }
    return permissionFlags;
  }, makePermissionFlags());

export const makeVerification =
  (actionType) =>
  (permissions, roles, roleTemplates, oneOffPermissions, action) => {
    if (!permissions) {
      Sentry.withScope((scope) => {
        scope.setExtra('action type', action.payload.type);
        Sentry.captureException(
          'missing permissions payload on action that requires it'
        );
      });
      return true;
    }
    const domainRelevantPermissions = permissions.filter((permission) =>
      verifyDomain(permission, action.payload.permissions)
    );
    const {
      adminPermissionRequired,
      adminPermissionMet,
      permissionMet,
      maskPermissionBlocked
    } = reducePermissions(
      actionType,
      domainRelevantPermissions,
      roles,
      roleTemplates,
      oneOffPermissions
    );
    if (adminPermissionRequired) {
      return adminPermissionMet;
    }
    return permissionMet && !maskPermissionBlocked;
  };

export const makeAuthorBasedVerification =
  (selfActionType, otherActionType) =>
  (permissions, roles, roleTemplates, oneOffPermissions, action) => {
    if (action.payload?.permissions?.mine) {
      return true; // for now logic is FE always lets user edit own items.
    }
    const actionType = action.payload?.permissions?.mine
      ? selfActionType
      : otherActionType;

    return makeVerification(actionType)(
      permissions,
      roles,
      roleTemplates,
      oneOffPermissions,
      action
    );
  };
