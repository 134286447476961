import React from 'react';
import styled from 'styled-components';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';

import theme from 'theme';

const StyledNameContainer = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;
const StyledPriorityFlagIcon = styled(PriorityFlagIcon)`
  margin-right: 5px;
`;

const TeamMemberCell = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
`;

const TaskPriorityGroupRenderer = ({ group }) => {
  return (
    <TeamMemberCell className="team-member-cell" showBorder>
      <StyledNameContainer>
        <StyledPriorityFlagIcon currentColor={group.color} />
        <StyledName>{group.title}</StyledName>
      </StyledNameContainer>
    </TeamMemberCell>
  );
};

export default TaskPriorityGroupRenderer;
