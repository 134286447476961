import React from 'react';
import styled from 'styled-components';
import ProjectColorDot from 'components/ProjectColorDot';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import MilestoneIcon from 'icons/MilestoneIcon';
import MilestoneProgress from 'BudgetModule/components/BudgetTable/MilestoneProgress';
import { formatPhaseScheduleTooltip } from './PhaseScheduleTooltip';

export const PhaseScheduleTimelineItem = ({ event }) => {
  const { phase, project } = event.data;
  if (!phase || !project) {
    return <></>;
  }

  const isPhase = phase.is_budget;

  return (
    <RootContainer>
      <InfoColumn>
        <TooltipArea
          data-for="phase-schedule-tooltip"
          data-tip={formatPhaseScheduleTooltip({ phase, project })}
          data-effect="solid"
        >
          <Row>
            <DotContainer>
              <ProjectColorDot size={9} projectId={project.id} hasColorPicker />
            </DotContainer>
            <ProjectInfoContainer>
              <ProjectName>{project.title}</ProjectName>
              {project.description && (
                <Description>-{project.description}</Description>
              )}
            </ProjectInfoContainer>
          </Row>
          <PhaseContainer>
            <IconContainer>
              {isPhase ? (
                <PhaseMilestoneIcon width="12" height="12" />
              ) : (
                <MilestoneIcon width="12" height="12" />
              )}
            </IconContainer>
            <PhaseName>{phase.name}</PhaseName>
          </PhaseContainer>
        </TooltipArea>
      </InfoColumn>
      <ProgressColumn>
        <ProgressBarContainer>
          <MilestoneProgress
            isDisabled
            phaseId={phase.id}
            projectId={project.id}
            phase={phase}
          ></MilestoneProgress>
        </ProgressBarContainer>
      </ProgressColumn>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const BaseColumn = styled.div`
  flex: 0 1 50%;
  min-width: 0;
`;

export const InfoColumn = styled(BaseColumn)``;
export const ProgressColumn = styled(BaseColumn)``;

export const TooltipArea = styled.div`
  max-width: fit-content;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
`;

export const ProjectInfoContainer = styled(Row)`
  align-items: baseline;
`;

// TODO: share common style with project table
export const ProjectName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Description = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 4px;
`;

export const SPACING = '16px';

export const DotContainer = styled.div`
  width: ${SPACING};
  position: relative;
`;

export const PhaseContainer = styled(Row)`
  padding-left: ${SPACING};
  width: 100%;
  gap: 2px;
`;

export const PhaseName = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
`;

export const IconContainer = styled.div`
  min-width: 12px;

  &.location {
    padding-left: 1px;
  }
`;

export const ProgressBarContainer = styled.div`
  & > div {
    padding: 0;
    padding-left: 0;
    height: 20px;
  }
  & .budget-phase-progress-dates {
    width: 120px;
    height: 20px;
    // style for date range text 7/30/18
    & > div {
      height: 20px !important;
      font-size: 12px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }
  }
`;
