import styled from 'styled-components';
import { calculateColorPref, generatePageOverlay } from 'appUtils/styleUtils';
import colorSwirl from 'icons/color-swirl.svg';
import cn from 'classnames';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const BeforeElement = generatePageOverlay({ zindex: 199 });

export const StyledSquare = styled.div.attrs(({ $color }) => ({
  style: {
    backgroundColor: $color
  }
}))`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 3px 3px;
  width: 40px;
  z-index: 203;
`;

export const StyledColorCircle = styled(StyledSquare)`
  border-radius: 10px;
  height: 20px;
  width: 20px;
  margin: 5px;
`;

export const StyledColorCircleMemberInitials = styled(StyledSquare)`
  border-radius: 15px;
  height: 30px;
  width: 30px;
  margin: 7px;
`;

export const StyledCustomColorCircle = styled(StyledColorCircle)`
  background: url(${colorSwirl}) no-repeat center;
  transform: scale(1.67);
`;

export const SelectedColorBorder = styled.div.attrs(({ $memberInitials }) => ({
  className: cn({ memberInitials: $memberInitials })
}))`
  border-radius: 50px;
  border: 2px solid ${({ theme }) => theme.colors.colorBudgetBlue};
  height: 26px;
  position: absolute;
  width: 26px;

  &.memberInitials {
    height: 36px;
    width: 36px;
  }
`;

export const StyledInputContainer = styled.div.attrs(
  ({ $alwaysOpen, $teamMembership, $useOldPicker }) => ({
    className: cn({
      openable: !$alwaysOpen,
      oldPicker: $useOldPicker,
      teamMembership: $teamMembership
    })
  })
)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;

  &.oldPicker {
    width: 160px;
  }

  &.openable {
    background-color: white;
    box-shadow: 0 1px 15px rgba(178, 178, 178, 0.5);
    z-index: 2001;
    ${BeforeElement};
  }

  &.teamMembership {
    padding: 20px 20px;
  }
`;

export const StyledColorPickerInput = styled.div.attrs(
  ({ $numColors, $teamMembership, $useOldPicker }) => ({
    className: cn({
      oldPicker: $useOldPicker,
      teamMembership: $teamMembership
    }),
    style: { '--columns': Math.ceil($numColors / 3) }
  })
)`
  display: flex;
  flex-wrap: wrap;
  width: calc(var(--columns) * 30px);
  z-index: 201;

  &.teamMembership {
    width: calc(var(--columns) * 44px);
  }

  &.oldPicker {
    justify-content: center;
    width: 140px;
  }
`;

export const StyledColorPicker = styled.div.attrs(
  ({ $open, $preferenceExists, $row }) => ({
    className: cn({
      'is-open': $open,
      showOnHover: !$row && !$preferenceExists && !$open
    })
  })
)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;

  &.showOnOpen {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  &.is-open {
    z-index: 2000;
  }
`;

export const ColorPickerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
`;

export const ColorPickerHoverArea = styled.div``;

export const StyledCircle = styled.div.attrs((props) => {
  const {
    $alwaysShow,
    $color,
    $isDisabled,
    $isOpen,
    $originType,
    ...otherProps
  } = props;

  const color = calculateColorPref({
    ...otherProps,
    color: $color,
    originType: $originType
  });

  return {
    className: cn({
      colorSwirl: $color === 'colorSwirl',
      disabled: $isDisabled,
      isOpen: $isOpen,
      showOnHover: !$alwaysShow,
      swirlOnHover:
        $originType === ORIGIN_TYPE_STRINGS.TEAM_MEMBERSHIP && !color
    }),
    style: {
      '--color': color
    }
  };
})`
  border-radius: 50%;
  height: 10px;
  transform-origin: center center;
  transition: 0.2s all linear;
  width: 10px;
  z-index: 1;

  &.showOnHover {
    opacity: 0;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:not(.disabled) {
    background-color: var(--color);

    &.colorSwirl {
      background: url(${colorSwirl}) no-repeat center;
    }

    &:hover {
      box-shadow: 0 1px 15px rgba(178, 178, 178, 0.5);
      opacity: 1;
      transform: scale(1.2);
    }

    &.isOpen {
      background: url(${colorSwirl}) no-repeat center;
      opacity: 1;
      transform: scale(1.2);
    }

    ${ColorPickerHoverArea}:hover & {
      opacity: 1;

      /* temporary fix for temporary UI in Team Member Card */
      &.swirlOnHover {
        background: url(${colorSwirl}) no-repeat center;
      }
    }
  }
`;

export const StyledColorPickerButton = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 12px;
  justify-content: center;
  width: 12px;
  z-index: 1;
`;

export const StyledPickerHeader = styled.div.attrs(({ $useOldPicker }) => ({
  className: cn({ oldPicker: $useOldPicker })
}))`
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  font-size: 14px;
  width: 100%;

  &.oldPicker {
    font-size: 15px;
  }

  &:not(.oldPicker) {
    font-weight: 400;
    line-height: 20px;

    // The horizontal margin accounts for the horizontal margin of the color
    // buttons.
    padding: 0 5px 5px;
  }

  span {
    flex: 1;
    z-index: 203;
  }

  .close-icon-dark {
    z-index: 2002;
  }
`;

export const StyledPickerFooter = styled.div`
  align-items: center;
  color: #828282;
  display: flex;
  font-size: 12px;
  line-height: 1;
  padding: 5px 0px;
  text-align: left;
  white-space: pre-wrap;
  width: 100%;

  span {
    z-index: 203;
  }
`;

export const StyledDoneButton = styled.div`
  align-items: center;
  align-self: center;
  background: ${({ theme }) => theme.colors.colorRoyalBlue};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.colorPureWhite};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  margin-right: 20px;
  padding: 2px 10px;

  &:hover {
    filter: brightness(93%);
  }
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  margin-left: 15px;
`;

export const StyledMemberInitial = styled.div`
  color: ${({ theme }) => theme.colors.colorPureWhite};
  font-size: 14px;
  font-weight: 600;
  line-height: 0;
`;

export const StyledMemberNameHeader = styled.div`
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  font-size: 30px;
  font-weight: 600;

  // The horizontal margin accounts for the horizontal margin of the color
  // buttons.
  margin: 0 7px 8px;
`;

export const CustomColorPickerContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.colorPureWhite};
  border: 1px solid ${({ theme }) => theme.colors.colorLightGray1};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  height: 205px;
  justify-content: center;
  left: 0;
  line-height: 1.5;
  position: absolute;
  top: 0;
  width: 286px;
`;

export const CustomColorHeader = styled.div`
  display: flex;
  height: 35px;
  justify-content: space-between;
  width: 100%;
`;

export const CustomColorFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-evenly;
  width: 100%;
`;

export const SaturationContainer = styled.div`
  float: left;
  height: 120px;
  position: relative;
  width: 100%;
`;

export const HueContainer = styled.div`
  height: 10px;
  position: relative;
  width: 95px;
`;

export const HexInputContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 95px;
`;
