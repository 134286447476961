import { createContext, ReactNode, useContext, useState } from 'react';

export enum Step {
  addScope,
  addTask
}

interface SidebarPanelContextValues {
  currentStep: Step | undefined;
  openStep: (step: Step) => void;
  closeStep: () => void;
}

const SidebarPanelContext = createContext({} as SidebarPanelContextValues);

export const SidebarPanelProvider = ({ children }: { children: ReactNode }) => {
  const [currentStep, setCurrentStep] = useState<Step | undefined>();

  const openStep = (step: Step) => {
    setCurrentStep(step);
  };

  const closeStep = () => {
    setCurrentStep(undefined);
  };

  return (
    <SidebarPanelContext.Provider
      value={{
        currentStep,
        openStep,
        closeStep
      }}
    >
      {children}
    </SidebarPanelContext.Provider>
  );
};

export const useSidebarPanel = () => useContext(SidebarPanelContext);
