import styled from 'styled-components';
import theme from 'theme';
import { Modal } from 'reactstrap';

import { MOSAIC_MEMBERSHIP_SECTION } from './MosaicMembershipTable/constants';
import { lazy } from 'react';

const MosaicMembershipTable = lazy(() => import('./MosaicMembershipTable'));

interface MosaicMembershipInfoModalProps {
  open: boolean;
  toggle: () => void;
}

export const MosaicMembershipInfoModal = ({
  open,
  toggle
}: MosaicMembershipInfoModalProps) => {
  const ACCESS_PAGE_SECTIONS = [
    MOSAIC_MEMBERSHIP_SECTION.ACCESS,
    MOSAIC_MEMBERSHIP_SECTION.MEMBERS
  ];

  return (
    <StyledModal
      isOpen={open}
      toggle={toggle}
      className="membership-info-modal"
    >
      <HeaderContainer>
        <Title>Membership</Title>
        <SubTitle>Access, Visibility, and Settings</SubTitle>
        <SubText>You can filter people in Mosaic by their membership</SubText>
      </HeaderContainer>
      <MosaicMembershipTable
        sectionOrder={ACCESS_PAGE_SECTIONS}
        isLoading={false}
      />
    </StyledModal>
  );
};

const HeaderContainer = styled.div`
  margin-top: 30px;
  margin-left: 40px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: ${theme.colors.colorSemiDarkGray1};
`;

const SubTitle = styled.div`
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
  margin-top: -5px;
`;

const SubText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
`;

const StyledModal = styled(Modal)`
  .modal-content {
    height: min(95vh, 900px);
    padding-bottom: 10px;
    width: 1031px;
  }
  .modal-body {
    padding: 0;
  }
`;
