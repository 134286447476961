import React from 'react';
import SvgIcon from 'components/SvgIcon';

const WFHIcon = ({
  className = '',
  height = '16',
  width = '16',
  fill = '#4f4f4f'
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        d="M8.34736 1.73333L14.0154 7.40133C14.1755 7.56276 14.2657 7.78064 14.2667 8.008V13.8667H11.6667V10.2667C11.6667 10.0368 11.5754 9.81637 11.4129 9.65384C11.2503 9.49131 11.0299 9.4 10.8 9.4H5.60002C5.37017 9.4 5.14973 9.49131 4.9872 9.65384C4.82467 9.81637 4.73336 10.0368 4.73336 10.2667V13.8667H2.13336V8.008C2.13432 7.78064 2.22458 7.56276 2.38469 7.40133L8.05269 1.73333H8.34736ZM8.71136 0H7.68869C7.46133 0.000957339 7.24345 0.0912217 7.08202 0.251333L1.16269 6.17067C0.674957 6.65779 0.400631 7.31867 0.400024 8.008V14.7333C0.400024 14.9632 0.491334 15.1836 0.653865 15.3462C0.816397 15.5087 1.03684 15.6 1.26669 15.6H5.60002C5.82988 15.6 6.05032 15.5087 6.21285 15.3462C6.37538 15.1836 6.46669 14.9632 6.46669 14.7333V11.35C6.46669 11.2925 6.48952 11.2374 6.53015 11.1968C6.57078 11.1562 6.62589 11.1333 6.68336 11.1333H9.71669C9.77416 11.1333 9.82927 11.1562 9.8699 11.1968C9.91053 11.2374 9.93336 11.2925 9.93336 11.35V14.7333C9.93336 14.9632 10.0247 15.1836 10.1872 15.3462C10.3497 15.5087 10.5702 15.6 10.8 15.6H15.1334C15.3632 15.6 15.5837 15.5087 15.7462 15.3462C15.9087 15.1836 16 14.9632 16 14.7333V8.008C15.9994 7.31867 15.7251 6.65779 15.2374 6.17067L9.31802 0.251333C9.1566 0.0912217 8.93872 0.000957339 8.71136 0Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default WFHIcon;
