import React, { lazy, Suspense } from 'react';

const App = lazy(() => import('./App'));

class LoadApp extends React.PureComponent {
  render() {
    return (
      <Suspense fallback={<div />}>
        <App {...this.props} />
      </Suspense>
    );
  }
}
export default LoadApp;
