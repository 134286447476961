import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TimerIcon = ({
  className,
  width = '21',
  height = '22',
  stroke = '#A4A4A4',
  strokeWidth = '0.733333',
  fill = '#A4A4A4'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_6843_72914)">
        <path
          d="M17.7995 5.80168L19.0566 4.58084C19.1585 4.47986 19.2156 4.3441 19.2156 4.2027C19.2156 4.06131 19.1585 3.92555 19.0566 3.82457L18.2667 3.05749C18.215 3.00686 18.1535 2.96668 18.0857 2.93925C18.0179 2.91183 17.9452 2.89771 17.8718 2.89771C17.7983 2.89771 17.7256 2.91183 17.6579 2.93925C17.5901 2.96668 17.5286 3.00686 17.4768 3.05749L16.242 4.32155C15.0722 3.39962 13.7032 2.74595 12.2371 2.40926V1.08039C12.2371 0.79385 12.1199 0.51905 11.9113 0.316438C11.7027 0.113826 11.4197 0 11.1247 0H8.89973C8.60469 0 8.32173 0.113826 8.1131 0.316438C7.90447 0.51905 7.78727 0.79385 7.78727 1.08039V2.40926C5.5762 2.89868 3.60171 4.10257 2.18702 5.82387C0.772334 7.54516 0.00125098 9.68188 0 11.8843C0.0292216 14.4062 1.05422 16.821 2.86346 18.6303C4.6727 20.4397 7.12811 21.5055 9.72296 21.6077C11.6324 21.6574 13.5165 21.1756 15.1532 20.2192C16.7898 19.2628 18.1106 17.8717 18.9597 16.21C19.8087 14.5483 20.1506 12.6855 19.9451 10.8413C19.7395 8.99702 18.995 7.24838 17.7995 5.80168ZM10.0122 19.447C8.47202 19.447 6.96643 19.0034 5.68583 18.1724C4.40522 17.3414 3.4071 16.1603 2.8177 14.7784C2.2283 13.3965 2.07409 11.8759 2.37456 10.4088C2.67504 8.94182 3.4167 7.59428 4.50577 6.53661C5.59484 5.47895 6.9824 4.75867 8.49298 4.46686C10.0036 4.17505 11.5693 4.32482 12.9923 4.89722C14.4152 5.46963 15.6314 6.43896 16.4871 7.68264C17.3428 8.92632 17.7995 10.3885 17.7995 11.8843C17.7995 13.89 16.979 15.8136 15.5186 17.2319C14.0582 18.6502 12.0775 19.447 10.0122 19.447Z"
          fill={fill}
        />
        <path
          d="M19.4961 11.7227C19.4961 16.9694 15.2428 21.2227 9.99609 21.2227C4.74939 21.2227 0.496094 16.9694 0.496094 11.7227C0.496094 6.47595 4.74939 2.22266 9.99609 2.22266C15.2428 2.22266 19.4961 6.47595 19.4961 11.7227Z"
          fill="white"
          stroke={stroke}
        />
        <path
          d="M8.19674 8.01232L14.26 11.9873L8.19674 15.9624V8.01232Z"
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </g>
      <defs>
        <clipPath id="clip0_6843_72914">
          <rect width="20.5333" height="22" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TimerIcon;
