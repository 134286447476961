import {
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import assert from 'assert';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  clearLazyItemsLastUpdated,
  clearLazyLoadingContext
} from 'actions/tasks';
import { LazyListRef, List } from 'components/List';
import TaskGroupCollapseIcon from 'icons/TaskGroupCollapseIcon';
import { AnyAction } from 'redux';
import produce from 'immer';
import { RootState } from 'reduxInfra/shared';
import { LAZY_LOAD_ITEMS } from 'appConstants/taskListTypes';
import cn from 'classnames';
import theme from 'theme';
import {
  flattenHierarchy,
  isEmptyList,
  isGroupHeading,
  isGroupItemsLazyLoader as isGroupLazyLoader,
  isItemLazyLoader,
  ListItem,
  ListNode
} from './utils';
import {
  LazyLoadingContextId,
  LazyLoadingGroupId,
  LazyLoadingHash,
  makeGetHasLazyItemsGroupLoading
} from 'TaskModule/selectors';

/**
 * The height of the lazy-loading bars.
 */
const LAZY_LOADER_HEIGHT = 60;

/**
 * The number of items that can be requested at once.
 */
const ITEMS_PER_LAZY_LOAD_REQUEST = 32;

export interface LazyLoadingListRef {
  clearItemCache: () => void;
}

interface LazyLoadingListProps<LoadedItem, OtherItem = never> {
  /**
   * Callback invoked to get the Redux action that will fetch the data for the
   * requested group. The `limit` and `offset` members will be added to the
   * action in the `body` member.
   */
  getFetchAction: (groupId: string) => AnyAction;

  /**
   * Callback invoked to get the ID of an item.
   */
  getItemId: (item: LoadedItem | OtherItem) => string;

  /**
   * A hierarchy of items to produce the list.
   */
  hierarchy: Array<ListNode<OtherItem>> | ListNode<OtherItem>;

  /**
   * The CSS class for the list items. This is useful for adding common
   * styling, such as margins, to all items.
   */
  itemClassName?: string;

  /**
   * The distance, in pixel, between the lazy-loading placeholders for initial
   * group loading.
   */
  lazyLoaderGap: number;

  /**
   * Callback invoked to render an empty list.
   */
  renderEmptyList?: (groupId: string) => ReactNode;

  /**
   * Callback invoked to render a group heading. Defaults to the group ID.
   */
  renderGroupHeading?: (groupId: string) => ReactNode;

  /**
   * Callback invoked to render an item other than an empty list and a group
   * heading.
   */
  renderItem: (item: LoadedItem | OtherItem, index: number) => ReactNode;

  /**
   * Callback invoked to get the Redux selector that produces the grouped
   * lazily-loaded items.
   */
  itemSelector: (ownProps: {
    lazyLoadingId: LazyLoadingContextId;
  }) => (state: RootState) => LazyLoadingHash<LoadedItem>;
}

const LazyLoadingListInner = <LoadedItem, OtherItem>(
  {
    getFetchAction,
    getItemId,
    hierarchy,
    itemClassName,
    itemSelector,
    lazyLoaderGap,
    renderEmptyList,
    renderGroupHeading,
    renderItem
  }: LazyLoadingListProps<LoadedItem, OtherItem>,
  ref: ForwardedRef<LazyLoadingListRef>
) => {
  const dispatch = useAppDispatch();

  // Collapse toggling for the groups.
  const [isOpen, setIsOpen] = useState(new Map<string, boolean>());

  // A reference to the list component.
  const listRef = useRef<LazyListRef>(null);

  const [lazyLoadingId] = useState(uuid());

  // Allow consumers of this component to clear the cache of lazily-loaded
  // items.
  useImperativeHandle(ref, () => ({
    clearItemCache: () => dispatch(clearLazyLoadingContext({ lazyLoadingId }))
  }));

  // When the component unmounts, clear the lazy-loading context.
  useEffect(
    () => () => {
      dispatch(clearLazyLoadingContext({ lazyLoadingId }));
    },
    [dispatch, lazyLoadingId]
  );

  const groupedItems = useAppSelector(itemSelector({ lazyLoadingId }));

  // Flatten the hierarchy of items and insert any lazily-loaded items into the
  // appropriate groups.
  const { list, newlyLoadedIndex } = useMemo(
    () =>
      flattenHierarchy<LoadedItem, OtherItem>({
        getIsOpen: (id: string) => isOpen.get(id),
        groupedItems,
        hierarchy: Array.isArray(hierarchy) ? hierarchy : [hierarchy]
      }),
    [isOpen, groupedItems, hierarchy]
  );

  // Since a group may have many items, the next group in the list may be
  // pushed out of view once the item list of the current group loads. For that
  // reason, the component must detect whether any group in the list is
  // performing an initial load.
  //
  // The mutable reference is used as a flag to indicate whether a group is
  // loading its initial item list. A reference is required because it will
  // need to be updated between state updates in the case that multiple groups
  // try to load simultaneously.
  const hasGroupInitiallyLoading = useAppSelector(
    makeGetHasLazyItemsGroupLoading({
      lazyLoadingId
    })
  );
  const loadNoMoreGroups = useRef(false);
  loadNoMoreGroups.current = hasGroupInitiallyLoading;

  // When the list of items changes, the heights of the items and their loaded
  // state need to be recomputed.
  useEffect(() => {
    listRef.current?.resetHeightsAfterIndex();
    listRef.current?.resetLoadedItemsCache();
  }, [hierarchy]);

  // When an item is loaded, the height of its element needs to be recomputed.
  useEffect(() => {
    if (newlyLoadedIndex !== undefined) {
      listRef.current?.resetHeightsAfterIndex(newlyLoadedIndex);
      listRef.current?.resetLoadedItemsCache();
      dispatch(clearLazyItemsLastUpdated({ lazyLoadingId }));
    }
  }, [dispatch, newlyLoadedIndex, lazyLoadingId]);

  const handleGetItemId = useCallback(
    (item: ListItem<LoadedItem, OtherItem>): string =>
      isGroupHeading(item)
        ? `${item.groupId}-heading`
        : isGroupLazyLoader(item)
        ? `${item.groupId}-loader`
        : isEmptyList(item)
        ? `${item.groupId}-empty`
        : isItemLazyLoader(item)
        ? `${item.groupId}-item-${item.index}-loader`
        : getItemId(item),
    [getItemId]
  );

  const handleIsItemLoaded = useCallback(
    (item: ListItem<LoadedItem, OtherItem>): boolean =>
      (!isItemLazyLoader(item) && !isGroupLazyLoader(item)) || item.isLoading,
    []
  );

  const handleLoadMoreItems = useCallback(
    async (
      items: Array<ListItem<LoadedItem, OtherItem>>,
      startIndex: number,
      stopIndex: number
    ): Promise<void> => {
      const firstItem = items[0];
      if (!firstItem) return;

      // Consumes an action and adds the members related to lazy-loading. This
      // assumes the following action structure is accepted.
      // ```
      // {
      //   body: {
      //     limit: number;
      //     offset: number;
      //     ...
      //   }
      //   lazyLoading: { ... }
      //   itemListType: LAZY_LOAD_ITEMS;
      //   ...
      // }
      const extendFetchAction = (
        action: AnyAction,
        groupId: LazyLoadingGroupId,
        offset: number,
        limit: number
      ): AnyAction =>
        produce(action, (draft) => {
          const payload = (draft.payload = draft.payload || {});
          payload.lazyLoading = {
            groupId,
            id: lazyLoadingId,
            type: LAZY_LOAD_ITEMS
          };
          payload.itemListType = LAZY_LOAD_ITEMS;

          const body = (payload.body = payload.body || {});
          body.limit = limit;
          body.offset = offset;
        });

      // We assume that multiple types of lazy-loading items could be requested
      // within the requested range. We only process the type of the first
      // item. The `List` component will make follow-up requests if some of the
      // items in the range are not marked as loading.
      let fetchAction: AnyAction | undefined;
      if (isGroupLazyLoader(firstItem)) {
        const groupId = firstItem.groupId;

        // Prevent multiple groups from loading simultaneously.
        if (!loadNoMoreGroups.current) {
          loadNoMoreGroups.current = true;

          fetchAction = extendFetchAction(
            getFetchAction(groupId),
            groupId,
            0,
            ITEMS_PER_LAZY_LOAD_REQUEST
          );
        }
      } else if (isItemLazyLoader(firstItem)) {
        const groupId = firstItem.groupId;

        // We assume that we may have a mixture of different types of
        // lazy-loading items. For example, an unloaded list without a heading
        // may immediately follow a loaded list with unloaded items.
        //
        // Since we are loading items from a list, it does not matter if we
        // request items beyond the end of a list because the returned list
        // will simply be cut off.
        fetchAction = extendFetchAction(
          getFetchAction(groupId),
          groupId,
          firstItem.index,
          Math.min(stopIndex - startIndex, ITEMS_PER_LAZY_LOAD_REQUEST)
        );
      } else {
        // This should never happen, but it is included so issues can be caught
        // in developement.
        assert.fail(
          'Items that are not `GroupItemsLazyLoader` or `ItemLazyLoader` should never need loading.'
        );
      }

      if (fetchAction) dispatch(fetchAction);

      return Promise.resolve();
    },
    [dispatch, getFetchAction, lazyLoadingId]
  );

  // Memoized sub-components that can be reused multiple times in the list.
  const itemLazyLoader = useMemo(
    () => <ItemLazyLoader className={itemClassName} />,
    [itemClassName]
  );
  const groupItemsLazyLoader = useMemo(
    () => (
      <GroupItemsLazyLoader className={itemClassName} gap={lazyLoaderGap} />
    ),
    [itemClassName, lazyLoaderGap]
  );
  const emptyList = useMemo(
    () => <EmptyList className={itemClassName} />,
    [itemClassName]
  );

  const handleRenderItem = useCallback(
    (item: ListItem<LoadedItem, OtherItem>, index: number): ReactNode =>
      isGroupHeading(item) ? (
        <GroupHeading
          className={cn(`level-${item.level}`, itemClassName, item.className, {
            first: index === 0,
            isCollapsible: item.isCollapsible
          })}
          onClick={
            item.isCollapsible
              ? () => {
                  setIsOpen((isOpen) =>
                    produce(isOpen, (draft) => {
                      draft.set(item.groupId, item.isCollapsed);
                    })
                  );

                  // When a group is opened or closed, the heights of the items and
                  // their loaded state need to be recomputed.
                  listRef.current?.resetHeightsAfterIndex(index);
                  listRef.current?.resetLoadedItemsCache();
                }
              : undefined
          }
        >
          {item.isCollapsible && (
            <GroupCollapseIcon
              className={cn({ isCollapsed: item.isCollapsed })}
              fill={theme.colors.colorSemiDarkGray1}
              height="32px"
              width="32px"
            />
          )}
          <GroupHeaderTitle>
            {renderGroupHeading?.(item.groupId) ?? item.groupId}
          </GroupHeaderTitle>
        </GroupHeading>
      ) : isGroupLazyLoader(item) ? (
        groupItemsLazyLoader
      ) : isEmptyList(item) ? (
        renderEmptyList?.(item.groupId) ?? emptyList
      ) : isItemLazyLoader(item) ? (
        itemLazyLoader
      ) : (
        renderItem(item, index)
      ),
    [
      emptyList,
      groupItemsLazyLoader,
      itemClassName,
      itemLazyLoader,
      renderEmptyList,
      renderGroupHeading,
      renderItem
    ]
  );

  return (
    <Container>
      <List
        getItemId={handleGetItemId}
        isItemLoaded={handleIsItemLoaded}
        items={list}
        // We are unable to extend the `List` component because of a typing
        // issue: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/57684
        // This is done as a workaround.
        listClassName="scrollbar list"
        loadMoreItems={handleLoadMoreItems}
        minimumBatchSize={ITEMS_PER_LAZY_LOAD_REQUEST}
        ref={listRef}
        renderItem={handleRenderItem}
      />
    </Container>
  );
};

// Redeclare the `forwardRef` types to allow the generics required for `GroupedItemList`.
declare module 'react' {
  function forwardRef<T, P>(
    render: (props: P, ref: Ref<T>) => ReactElement | null
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

export const LazyLoadingList = forwardRef(LazyLoadingListInner);

const ItemLazyLoaderUnmemoed = ({ className }: { className?: string }) => (
  <ContentLoader
    className={cn('skeleton-loader', className)}
    style={{ height: LAZY_LOADER_HEIGHT }}
    viewBox={`0 0 100 ${LAZY_LOADER_HEIGHT}`}
  >
    <rect height={LAZY_LOADER_HEIGHT} x="0" width="100%" />
  </ContentLoader>
);
const ItemLazyLoader = memo(ItemLazyLoaderUnmemoed);

const GroupItemsLazyLoaderUnmemoed = ({
  className,
  gap
}: {
  className?: string;
  gap: number;
}) => {
  const height = 3 * LAZY_LOADER_HEIGHT + 2 * gap;

  return (
    <ContentLoader
      className={cn('skeleton-loader', className)}
      height={height}
      style={{ height }}
      viewBox={`0 0 100 ${height}`}
    >
      {new Array(3).fill(0).map((_, index) => (
        <rect
          key={index}
          height={LAZY_LOADER_HEIGHT}
          x="0"
          y={index * (LAZY_LOADER_HEIGHT + gap)}
          width="100%"
        />
      ))}
    </ContentLoader>
  );
};
const GroupItemsLazyLoader = memo(GroupItemsLazyLoaderUnmemoed);

const EmptyText = styled.div`
  color: ${({ theme }) => theme.colors.colorLightGray15};
  font-weight: 600;
  text-align: center;
`;
const EmptyListUnmemoed = ({ className }: { className?: string }) => (
  <EmptyText className={className}>No items to show</EmptyText>
);
const EmptyList = memo(EmptyListUnmemoed);

// We are unable to extend the `List` component because of a typing issue:
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/57684
// This is done as a workaround.
const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  .list {
    padding-bottom: 2rem;

    // For the scrollbar to be overlayed instead of taking up space. A fallback
    // is provided because overlay only works in some browsers.
    overflow: auto !important;
    overflow: overlay !important;
  }

  .skeleton-loader {
    flex: none;
    display: block;
    line-height: 0;
  }
`;

const GroupHeaderTitle = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const GroupHeading = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  display: flex;
  font-size: 15px;
  font-weight: 600;
  padding-top: 25px;
  word-break: break-word;

  &.first {
    margin-top: 0;
  }

  &.isCollapsible {
    cursor: pointer;
  }
`;

const GroupCollapseIcon = styled(TaskGroupCollapseIcon)`
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &.isCollapsed {
    transform: rotate(-90deg);
  }

  &:not(:hover) rect {
    stroke: transparent;
    fill: transparent;
  }

  ${GroupHeading}:hover & path {
    fill: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;
