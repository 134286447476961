const loadOsmos = () => {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://app.osmos.io/button/embed/v1/42LayersButton.js';
  body.appendChild(script);
};

export const initOsmos = () => {
  loadOsmos();
};
