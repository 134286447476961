import { useState } from 'react';
import {
  MemberCapacityCell,
  DayHeader,
  StyledMemberCapacityInput
} from './styles';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const dayOfWeekFormats = {
  MO: 'monday',
  TU: 'tuesday',
  WE: 'wednesday',
  TH: 'thursday',
  FR: 'friday',
  SA: 'saturday',
  SU: 'sunday'
};
const CapacityDay = ({
  day,
  capacity = {},
  handleChange,
  workloadSettings
}) => {
  const [showUnit, setShowUnit] = useState(true);
  const longFormatDay = dayOfWeekFormats[day];

  return (
    <MemberCapacityCell>
      <DayHeader>{day}</DayHeader>
      <StyledMemberCapacityInput
        value={
          formatNumWithMaxTwoDecimals(capacity[longFormatDay]) +
          (showUnit ? 'h' : '')
        }
        name={longFormatDay}
        onChange={handleChange}
        onFocus={() => setShowUnit(false)}
        onBlur={() => setShowUnit(true)}
      />
    </MemberCapacityCell>
  );
};

export default CapacityDay;
