import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { triggerTasksAttributesUpdate } from 'actionCreators';
import { getSelectedTeamId, getAuthToken } from 'selectors';
import {
  calculateTaskSaveDateRange,
  calculateSelectedDateRange
} from 'appUtils/dateRangeHelpers';
import DateRangePicker from 'components/DatePicker/DateRangePicker';
import CalendarIcon from 'icons/CalendarIcon';
import theme from 'theme';

const {
  colors: { colorDeleteRed }
} = theme;

const bindToDay = (date) =>
  date ? date.startOf('day').format('YYYY-MM-DD') : null;

class BulkUpdateModalDateRangeSelector extends React.Component {
  state = {
    adding: false,
    newDates: null
  };

  getPermissions = () => {
    const {
      task: { project_id },
      selectedTeamId
    } = this.props;
    const permissions = {
      projectId: project_id,
      teamId: selectedTeamId
    };
    return permissions;
  };

  handleDateSelection = (values) => {
    const { task } = this.props;
    const newDates = calculateSelectedDateRange({
      newValues: values,
      start: task.schedule_start,
      end: task.schedule_end,
      itemExists: !!task
    });

    this.setState({
      newDates
    });
  };

  clearDates = () => {
    this.setState({
      newDates: []
    });
  };

  saveDates = () => {
    const { task, triggerTasksAttributesUpdate, token } = this.props;
    const { newDates } = this.state;

    const [startDate, endDate] = calculateTaskSaveDateRange({ task, newDates });

    const datesObj = {
      schedule_start: startDate ? bindToDay(moment(startDate)) : null,
      schedule_end: endDate ? bindToDay(moment(endDate)) : null
    };
    const body = {
      task_ids: [task.id],
      ...datesObj
    };
    const permissions = this.getPermissions();
    triggerTasksAttributesUpdate({ token, body, permissions });
    this.setState({ adding: false });
  };

  renderIcon = () => {
    return <CalendarIcon width={14} height={14} fill={colorDeleteRed} />;
  };

  render() {
    const { newDates } = this.state;
    const { task } = this.props;
    return (
      <div style={{ width: 105 }} ref={(ref) => (this.target = ref)}>
        <DateRangePicker
          taskIsEditing={false}
          selectedValue={newDates}
          icon={this.renderIcon()}
          taskDateType={'schedule'}
          handleDateSelection={this.handleDateSelection}
          showDateInput
          task={task}
          newDates={newDates}
          saveDates={this.saveDates}
          target={this.target}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  selectedTeamId: getSelectedTeamId(state)
});
export default connect(mapStateToProps, { triggerTasksAttributesUpdate })(
  BulkUpdateModalDateRangeSelector
);
