import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { TextButton, ButtonContainer } from 'components/styles';
import TextareaAutosize from 'react-textarea-autosize';
import { blurOnEnter } from 'appUtils/userInteractions';

const TextAreaWithSave = ({
  text = '',
  onSave,
  onChange,
  testId = 'TextArea',
  autoFocus,
  disabled,
  maxRows,
  maxCharLength = 200,
  placeholder,
  error,
  noConfirmButtons,
  shouldBlurOnEnter,
  forceInput,
  forwardedRef,
  preventLeadingWhitespace = true
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState(text);
  // due to order of onBlur and onClick (onBlur is called before onClick), we need to keep the button
  // visible in order to stopPropagation in cases where parent component container click focuses the input
  const [isDoneButtonBeingClicked, setIsDoneButtonBeingClicked] =
    useState(false);
  const inputRef = useRef(null);

  // allow parent to call focus on the input
  useImperativeHandle(forwardedRef, () => ({
    focus: () => inputRef.current.focus()
  }));

  const handleChange = (e) => {
    const formattedValueStep1 = forceInput
      ? e.target.value.replace(/[\r\n\v]+/g, '')
      : e.target.value;
    const formattedValueStep2 = preventLeadingWhitespace
      ? formattedValueStep1.trimStart()
      : formattedValueStep1;

    setTextValue(formattedValueStep2);
    onChange && onChange(formattedValueStep2);
  };

  const handleSubmit = (e) => {
    if (forceInput && !textValue.trim()) {
      inputRef.current.focus();
      return;
    }
    // See above - we need to know when function is called by click of the Done button
    if (e.relatedTarget?.name === 'done-button') {
      setIsDoneButtonBeingClicked(true);
    }
    setIsEditing(false);
    onSave && onSave(textValue);
  };

  useEffect(() => {
    if (!text && autoFocus && inputRef.current) {
      inputRef.current.focus();
      setIsEditing(true);
    }
  }, [autoFocus, text]);

  const isDoneButtonHidden =
    noConfirmButtons || (!isEditing && !isDoneButtonBeingClicked) || error;

  return (
    <StyledTextAreaContainer>
      <StyledTextArea
        inputRef={(ref) => (inputRef.current = ref)}
        data-testid={`${testId}-input`}
        className={`${testId} text-area`}
        value={textValue}
        onFocus={() => setIsEditing(true)}
        onChange={handleChange}
        onKeyDown={shouldBlurOnEnter && blurOnEnter(inputRef)}
        onBlur={handleSubmit}
        placeholder={placeholder}
        disabled={disabled}
        maxRows={maxRows}
        maxLength={maxCharLength}
        $hasError={!!error}
      />
      {error && <Error>{error}</Error>}

      <ButtonContainer isHidden={isDoneButtonHidden}>
        {/* Button does nothing special. same result as click out */}
        <DoneButton
          fontSize={13}
          backgroundColor={theme.colors.colorRoyalBlue}
          onClick={(e) => {
            e.stopPropagation();
            setIsDoneButtonBeingClicked(false);
          }}
          color="white"
          padding="2px 7px"
          data-testid={`${testId}-submit`}
          name="done-button"
        >
          Done
        </DoneButton>
      </ButtonContainer>
    </StyledTextAreaContainer>
  );
};

export default React.memo(TextAreaWithSave);

/* ------------------------------------ - ----------------------------------- */

const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 14px;
  line-height: 1.4;
  outline: none;
  resize: none;
  border: 1px solid transparent;
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
    background: ${theme.colors.colorLightGray22};
  }
  overflow: auto;
  &:disabled {
    background-color: initial;
  }
  &::placeholder {
    color: ${theme.colors.colorBudgetGrey};
  }
  ${(props) =>
    props.$hasError && `border: 1px solid ${theme.colors.colorCalendarRed};`}
  ${(props) => !props.maxRows && 'overflow: hidden;'}
`;

const StyledTextAreaContainer = styled.div`
  position: relative;
  ${TextButton} {
    margin-top: 7px;
    margin-bottom: 5px;
    font-size: 13px;
  }
  ${ButtonContainer} {
    min-height: 20px;
    position: relative;
    justify-content: flex-end;
    right: 0;
    bottom: 5px;
  }
`;

const Error = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorCalendarRed};
`;

const DoneButton = styled.button`
  background: none;
  border: none;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 15px;
  margin-right: 10px;
  margin-top: 2px;
  &:hover {
    font-weight: 600;
    background: ${theme.colors.colorTranslucentGray3};
  }
`;
