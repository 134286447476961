import { Modal } from 'reactstrap';
import theme from 'theme';
import styled from 'styled-components';
import { TextButtonWithBorder } from 'components/styles';

/* --------------------------------- Header --------------------------------- */

export const Header = styled.div``;

export const HeaderTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  z-index: 1;
`;

/* --------------------------------- Labels --------------------------------- */

export const OptionalLabel = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorLightGray15};
  cursor: default;
`;

export const SectionTitle = styled.div<{ fontSize?: number }>`
  display: flex;
  align-items: center;
  color: ${theme.colors.colorLightGray15};
  font-size: ${(props) => props.fontSize || 12}px;
  font-weight: 600;
  margin-bottom: 2px;
  cursor: default;
`;

/* --------------------------------- Buttons -------------------------------- */

export const FullWidthButton = styled.button<{ isDisabled?: boolean }>`
  font-size: 13px;
  height: 40px;
  width: 100%;
  background: white;
  margin-bottom: 20px;
  ${(props) =>
    !props.isDisabled &&
    `
      &:hover {
        filter: brightness(95%);
      }
  `}
`;

export const SaveButton = styled(TextButtonWithBorder)<{
  isSaveDisabled?: boolean;
}>`
  ${(props) =>
    props.isSaveDisabled &&
    `
    cursor: not-allowed !important;
  `}
`;

export const SelectButton = styled.div`
  color: ${theme.colors.colorBudgetGrey};
  font-size: 14px;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

/* ------------------------------- Containers ------------------------------- */

export const PaddingContainer = styled.div`
  padding: 0px 12px 30px;
`;

export const ScrollContainer = styled.div.attrs({ className: 'scrollbar' })<{
  backgroundColor?: string;
}>`
  position: relative;
  overflow-y: auto;
  background-color: ${(props) => props.backgroundColor || 'white'};
`;

export const ModalBodyContainer = styled.div<{ hasSideBody?: boolean }>`
  display: flex;
  flex: 1;
  min-height: 0;
  overflow: hidden;
  ${ScrollContainer} {
    width: 100%;
  }
  ${(props) =>
    props.hasSideBody &&
    `
    & > div {
      width: 48%;
      &:first-child {
        width: 52%;
      }
    }
  `}
`;

export const MainBody = styled(ScrollContainer)`
  display: flex;
  flex-direction: column;
  & > * {
    flex-shrink: 0;
  }
`;

export const SelectContainer = styled.div`
  cursor: pointer;
  .down-arrow {
    margin-left: 5px;
    visibility: hidden;
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
  ${OptionalLabel} {
    visibility: hidden;
  }
  &:hover {
    ${OptionalLabel},
    .down-arrow {
      visibility: visible;
    }
    ${SelectButton} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

/* ---------------------------- Base modal styles --------------------------- */

export const StyledTwoPaneModal = styled(Modal)<{
  $hasSideBody?: boolean;
  $isNew?: boolean;
  $isProjectViewScope?: boolean;
}>`
  max-width: none;
  margin: auto;
  width: ${(props) =>
    !props.$hasSideBody ? '533px' : '1013px'}; // edit modal has side body

  color: ${theme.colors.colorMediumGray7};
  .modal-content {
    height: min(95vh, 900px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ${(props) => props.$isNew && 'padding-bottom: 10px;'}
  }

  .modal-section {
    padding-bottom: 18px;
  }
`;
