import SvgIcon from 'components/SvgIcon';

const AddColumnIcon = ({
  strokecolor = '#0074d9',
  fillcolor = '',
  strokewidth = '1.5',
  width = '16',
  height = '16'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7Z"
        stroke={fillcolor || strokecolor}
        fill={fillcolor}
      />
      <path d="M7 3V11" strokeWidth={strokewidth} stroke={strokecolor} />
      <path d="M3 7H11" strokeWidth={strokewidth} stroke={strokecolor} />
    </SvgIcon>
  );
};

export default AddColumnIcon;
