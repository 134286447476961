import React from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { connect } from 'react-redux';

import MemberInitials from 'views/memberDisplay/MemberInitials';
import theme from 'theme';

const StyledTooltip = styled(ReactTooltip)`
  position: absolute !important;
  top: calc(50% - 50px) !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: fit-content;
`;

const StyledNameContainer = styled.div`
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
`;

const TeamMemberCell = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
`;

const MemberGroupRenderer = ({ group, currentUserId }) => {
  return (
    <TeamMemberCell
      className="team-member-cell app-cues-team-member-cell"
      data-for={'app-tooltip'}
      data-place="top"
      data-effect="solid"
      data-tip={group.account.name}
      data-offset="{'top': -25}"
      data-delay-show="250"
      showBorder
    >
      <MemberInitials
        member={group}
        classes={cn('item-account selected', {
          'logged-member-no-hover': group.account.id === currentUserId,
          'regular-member-no-hover': group.account.id !== currentUserId
        })}
        idx={'idx-placeholder'}
        isOnTaskModal
      />
      <StyledNameContainer className="app-cues-styled-name-container">
        <StyledName>{group.account.name}</StyledName>
      </StyledNameContainer>
      <StyledTooltip
        id={group.account.name}
        place="top"
        effect="solid"
        delayShow={200}
      />
    </TeamMemberCell>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberGroupRenderer);
