import { useRef } from 'react';
import cn from 'classnames';
import { useAppDispatch } from 'reduxInfra/hooks';
import { ActivityPhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { StyledColumnLabel } from 'SuggestionModule/components/FindPeople/Table/styles';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import {
  createPhaseEstimation,
  updatePhaseEstimation
} from 'BudgetModule/actionCreators';
import NumberFormat from 'react-number-format';
import { StyledNumberFormatInput } from '../shared/style';

const ActivityPhaseHoursCell = ({ row }: ActivityPhaseGroupRowProps) => {
  const {
    phase,
    accountTotals,
    activity,
    customRowProps: { projectId, unassignedMemberBudgetId }
  } = row.original;
  const dispatch = useAppDispatch();

  const { id: activityId } = activity;

  const { activity_phase_estimation_id, hours: unassignedRoleBudgetHours } =
    accountTotals?.estimated || {};

  const hoursRef = useRef<NumberFormat<unknown>>(null);

  const onBlur = () => {
    if (hoursRef?.current) {
      const { numAsString } = hoursRef.current.state;
      const newHours = isNaN(parseFloat(numAsString))
        ? 0
        : parseFloat(numAsString);
      if (unassignedRoleBudgetHours !== newHours) {
        if (activity_phase_estimation_id) {
          dispatch(
            updatePhaseEstimation({
              project_id: projectId,
              id: activity_phase_estimation_id,
              activity_id: activityId,
              estimated_hours: newHours,
              member_budget_id: unassignedMemberBudgetId,
              phase_id: phase.id,
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        } else {
          dispatch(
            createPhaseEstimation({
              project_id: projectId,
              estimated_hours: newHours,
              member_budget_id: unassignedMemberBudgetId,
              phase_id: phase.id,
              activity_id: activityId,
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        }
      }
    }
  };

  return (
    <StyledColumnLabel className={cn('hour activity-phase')}>
      <StyledNumberFormatInput
        defaultValue={unassignedRoleBudgetHours}
        placeholder={'0h'}
        onBlur={onBlur}
        decimalScale={2}
        ref={hoursRef}
        allowNegative={false}
        thousandSeparator
        isNumericString
        allowLeadingZeros={false}
        suffix="h"
        className="hour-input"
      />
    </StyledColumnLabel>
  );
};

export default ActivityPhaseHoursCell;
