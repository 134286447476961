import styled from 'styled-components';
import theme from 'theme';

export const NoResultsCell = () => {
  return <StyledNoResultsCell>No Results</StyledNoResultsCell>;
};

// -----------------------------------------------------------------------------

const StyledNoResultsCell = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;
`;
