import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import ColorPicker from 'components/ColorPicker';
import {
  ColorPickerHoverArea,
  ColorPickerContainer
} from 'components/ColorPicker/styles';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const InputContainer = styled(ColorPickerHoverArea)`
  background-color: ${(props) => props.theme.colors.colorPureWhite};
  outline: 1px solid #dedede;
  display: flex;
  flex: auto;
`;

const StyledInput = styled.input`
  border: 0;
  color: ${calculateColorPref};

  &:focus {
    &::placeholder {
      size: 14px;
      color: ${theme.colors.colorLightGray6};
    }
  }
`;

export const StyledTaskGroupName = styled.div`
  display: flex;
  color: ${calculateColorPref};
  min-width: 175px;
  padding-left: 4px;
  cursor: pointer;
  font-size: 20px;
  white-space: nowrap;
  display: flex;
`;
const Form = styled.form`
  display: flex;
  width: 100%;
`;

const GroupHeader = (props) => {
  const {
    group,
    onChange,
    name,
    namePlaceholderText,
    editing,
    handleSubmit,
    inputRef,
    handleBlur,
    toggleCollapse,
    forceColorPickerOpen,
    originType = ORIGIN_TYPE_STRINGS.TASK_GROUP,
    colorPickerEnabled = true,
    colorPickerHeaderText = 'Select Task Group Color',
    editDisabled,
    renderIcon,
    renderAfterName,
    emptyNameText = ''
  } = props;

  const focusRef = useRef(null);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return editing && !editDisabled ? (
    <InputContainer ref={focusRef}>
      <Form onSubmit={handleSubmit}>
        {colorPickerEnabled && (
          <ColorPickerContainer>
            <ColorPicker
              id={group.id}
              originType={originType}
              pickerLocation="flyout-menu"
              row
              handleSelect={handleSubmit}
              className="color-picker-container"
              headerText={colorPickerHeaderText}
              alwaysShow
              alwaysOpen={forceColorPickerOpen}
              showInPopover
            />
          </ColorPickerContainer>
        )}

        <StyledInput
          value={name || ''}
          name={'name'}
          onChange={onChange}
          id={group.id}
          originType={originType}
          ref={inputRef}
          onBlur={handleBlur}
          placeholder={namePlaceholderText}
        />
      </Form>
    </InputContainer>
  ) : (
    <StyledTaskGroupName
      onClick={toggleCollapse}
      id={group.id}
      originType={originType}
      isNameEmpty={!group.name}
    >
      {renderIcon?.()}
      <span
        className="group-header-text-ellipsis"
        data-for={'app-tooltip'}
        data-html
        data-effect="solid"
        data-class="mw-250"
        data-tip={group.name || emptyNameText}
      >
        {group.name || emptyNameText}
      </span>
      {renderAfterName?.()}
    </StyledTaskGroupName>
  );
};

export default GroupHeader;
