import React from 'react';
import { connect } from 'react-redux';
import {
  TaskPropertyCell,
  StyledMilestone,
  NewTaskProperty,
  EmptyPropertyContainer
} from './styles';
import { noop } from 'appUtils';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import { getMatchedRouteParams, getAllActivityRowInfo } from 'selectors';
import { fetchPhasesByProjectIds, openMilestoneModal } from 'actionCreators';
import EllipsisText from 'components/EllipsisText';
import styled from 'styled-components';

const noPhase = { id: null, order_number: null, is_complete: null };
const noActivity = { id: null };
class TaskMilestone extends React.PureComponent {
  state = {
    isDropdownOpen: false,
    phase: this.props.phaseId
      ? this.props.projectPhases.find((m) => m.id === this.props.phaseId) ||
        noPhase
      : noPhase,
    activity: this.props.activityId
      ? this.props.activities[this.props.activityId]
      : noActivity
  };

  handleProjectOrPhaseSelect = ({
    projectId,
    phaseId,
    activityId,
    activityPhaseId
  }) => {
    const phase = this.props.projectPhases.find((m) => m.id === phaseId);
    const activity = this.props.activities[activityId];

    this.setState({
      phase,
      activity,
      activityPhaseId
    });
  };

  handleSubmit = () => {
    const { phase, activity, activityPhaseId } = this.state;

    this.props.onSubmit({
      phase_id: phase?.id,
      activity_id: activity?.id,
      activity_phase_id: activityPhaseId
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };

  openDropdown = () => this.setState({ isDropdownOpen: true });
  closeDropdown = () => this.setState({ isDropdownOpen: false });

  componentDidUpdate(prevProps, prevState) {
    const wasDropdownClosed =
      prevState.isDropdownOpen && !this.state.isDropdownOpen;
    const phaseChanged = this.state.phase?.id !== this.props.phaseId;
    if (wasDropdownClosed && phaseChanged) {
      this.handleSubmit();
    }
    if (this.props.activities !== prevProps.activities) {
      this.setState({ activity: this.props.activities[this.state.activityId] });
    }
  }

  handleOpenModalClick = () => {
    const {
      fetchPhasesByProjectIds,
      openMilestoneModal,
      matchedParams: { projectId }
    } = this.props;
    fetchPhasesByProjectIds({ projectIds: [projectId] });
    openMilestoneModal();
  };

  handleCloseMilestoneDropdown = () => {
    this.setState({
      isDropdownOpen: false
    });
  };

  shouldRenderPhaseSelector = () => true;
  renderPhaseSelector = ({ openDropdownToPhases }) => {
    const { isNewTask, taskIsEditing, projectId } = this.props;
    const { isDropdownOpen, phase, activity } = this.state;
    const { name, is_like_default: isLikeDefault } = phase || {};
    const { title, is_default: isDefault } = activity || {};
    const handleClick = (e) => {
      openDropdownToPhases(e, projectId);
    };
    return !name ? (
      <NewTaskProperty
        $isDropdownOpen={isDropdownOpen}
        $isNewTask={isNewTask}
        $showOnHover
        $taskIsEditing={taskIsEditing}
        onClick={handleClick}
      >
        <EmptyPropertyContainer>Phase</EmptyPropertyContainer>
      </NewTaskProperty>
    ) : (
      <>
        <StyledMilestone isDropdownOpen={isDropdownOpen} onClick={handleClick}>
          <EllipsisText width={95}>{isLikeDefault ? '' : name}</EllipsisText>
          <EllipsisText width={95}>{isDefault ? '' : title || ''}</EllipsisText>
        </StyledMilestone>
      </>
    );
  };

  isInvalid = () => false;

  render() {
    const { projectId } = this.props;
    const { phase } = this.state;

    return (
      <TaskPropertyCell
        onClick={this.state.isDropdownOpen ? noop : this.openDropdown}
      >
        <StyledProjectsThenPhasesSelector
          handleSelect={this.handleProjectOrPhaseSelect}
          projectId={projectId}
          phaseId={phase?.id}
          isInvalid={this.isInvalid()}
          nameStyle={'font-size: 16px; '}
          renderPhaseSelector={this.renderPhaseSelector}
          dropdownRef={(ref) => (this.target = ref)}
          projectStepEnabled={false}
          onClose={this.closeDropdown}
          showBudgetInformation={false}
        />
      </TaskPropertyCell>
    );
  }
}

const StyledProjectsThenPhasesSelector = styled(ProjectsThenPhasesSelector)`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 0;
`;

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  activities: getAllActivityRowInfo(state)
});
const mapDispatchToProps = {
  fetchPhasesByProjectIds,
  openMilestoneModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskMilestone);
