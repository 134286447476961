import styled from 'styled-components';

export const Card = styled.div`
  background: white;
  border: 0.5px solid ${({ theme }) => theme.colors.colorLightGray6};
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  min-height: 60px;
  min-width: 0;
  padding: 4px 18px;
  width: 100%;

  &.isClickable {
    cursor: pointer;
  }

  &:hover {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }
`;
