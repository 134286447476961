import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';
import { getRowValuesForEntityType } from './utils';
import uniq from 'lodash/uniq';
interface AdditionalParams {
  existingProjectClients: string[];
  projectClientOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectClientOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingProjectClients, projectClientOption } = additionalParams;
  const { enteredProjectTitle, enteredProjectNumber } =
    getRowValuesForEntityType(row, entityType);
  const matchedClientByProjectTitleAndNumber =
    projectClientOption.current[enteredProjectTitle]?.[enteredProjectNumber];

  const newClientOptionsByProjectNumberArray = Object.values(
    projectClientOption.current
  );
  // store all new client options in an array
  const newClientOptions: string[] = [];
  newClientOptionsByProjectNumberArray.forEach((clientOptionByProjectNumber) =>
    Object.values(clientOptionByProjectNumber).forEach((clientOption) =>
      newClientOptions.push(clientOption)
    )
  );
  // return all possible options, specifically putting the matched client as the second option if exists
  // including '' so that user can go back to empty field
  return uniq([
    '',
    matchedClientByProjectTitleAndNumber ?? '',
    ...newClientOptions,
    ...existingProjectClients
  ]);
};
