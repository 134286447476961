import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Region } from 'SettingsModule/models/region';
import {
  getRegionsArray,
  makeGetRegionsByIds
} from 'SettingsModule/selectors/regions';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';
import theme from 'theme';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';

const copy = {
  headerInitial: 'Select Region',
  searchPlaceholder: 'Type name or select below'
};

const byRegionId = (item: Region) => item.id;

interface BulkRegionSelectorProps {
  isOpen: boolean;
  target: MutableRefObject<null>;
  regionFilter: (regions: Region[]) => Region[];
  initialAddedRegionsIds?: number[];
  onDone: ({
    addedRegions,
    removedRegions
  }: {
    addedRegions: Region[];
    removedRegions: Region[];
  }) => void;
  onClose: () => void;
}

export const BulkRegionSelector = ({
  target,
  isOpen,
  regionFilter,
  initialAddedRegionsIds = [],
  onDone,
  onClose
}: BulkRegionSelectorProps) => {
  const [addedRegions, setAddedRegions] = useState<Region[]>([]);
  const [removedRegions, setRemovedRegions] = useState<Region[]>([]);

  const regions = useAppSelector(getRegionsArray);

  const getRegionsByIds = useMemo(makeGetRegionsByIds, []);

  const addedRegionsFromIds = useAppSelector((state) =>
    initialAddedRegionsIds.length
      ? getRegionsByIds(state, { ids: initialAddedRegionsIds })
      : undefined
  );

  const handleSelect = (_, { item }: { item: Region }) => {
    if (addedRegions.find((region) => region.id === item.id)) {
      removeFromAddedRegions(item);
      addToRemovedRegions(item);
    } else {
      removeFromRemovedRegions(item);
      addToAddedRegions(item);
    }
  };

  const resetLocalState = () => {
    setAddedRegions([]);
    setRemovedRegions([]);
  };

  useEffect(() => {
    if (isOpen) {
      resetLocalState();

      if (initialAddedRegionsIds && addedRegionsFromIds) {
        setAddedRegions(addedRegionsFromIds);
      }
    }
  }, [addedRegionsFromIds, initialAddedRegionsIds, isOpen]);

  useEffect(() => {
    rebuildTooltip();
  });

  const addToAddedRegions = (region: Region) => {
    setAddedRegions([...addedRegions, region]);
  };

  const removeFromAddedRegions = (region: Region) => {
    setAddedRegions(
      addedRegions.filter((addedRegion) => addedRegion.id !== region.id)
    );
  };

  const addToRemovedRegions = (region: Region) => {
    setRemovedRegions([...removedRegions, region]);
  };

  const removeFromRemovedRegions = (region: Region) => {
    setRemovedRegions(
      removedRegions.filter((removedRegion) => removedRegion.id !== region.id)
    );
  };

  const handleFilterRegion = (item: Region, searchWords: string[]) =>
    filterItemWithWhiteSpace({ searchWords, item, filterKeysArray: ['name'] });

  const handleDone = () => {
    const initialAddedRegionsIdsHash = keyBy(initialAddedRegionsIds);

    const newlyAddedRegions = addedRegions.filter(
      (region) => !initialAddedRegionsIdsHash[region.id]
    );

    const newlyRemovedRegions = removedRegions.filter(
      (region) => !!initialAddedRegionsIdsHash[region.id]
    );

    onDone({
      addedRegions: newlyAddedRegions,
      removedRegions: newlyRemovedRegions
    });

    resetLocalState();
  };

  const addedRegionsById = keyBy(addedRegions, byRegionId);

  const getSortedRegions = useCallback(() => {
    const otherRegions = regions.filter(
      (region) => !addedRegionsById[region.id]
    );

    const sortedRegions = [...addedRegions, ...otherRegions];

    return regionFilter(sortedRegions);
  }, [addedRegions, addedRegionsById, regionFilter, regions]);

  const renderItem = ({ item }: { item: Region }) => {
    const isAdded = !!addedRegionsById[item.id];
    return (
      <ItemContainer $isAdded={isAdded}>
        <RegionText {...defaultTooltipProps} data-tip={item.name}>
          {item.name}
        </RegionText>
        <RegionAddRemoveText $isAdded={isAdded}>
          {isAdded ? 'Remove' : 'Add'}
        </RegionAddRemoveText>
      </ItemContainer>
    );
  };

  const renderHeaderButton = () => {
    return (
      <HeaderButtonContainer>
        <StyledDoneButton onClick={handleDone}>Done</StyledDoneButton>
      </HeaderButtonContainer>
    );
  };

  return isOpen ? (
    <MultiStepFlyout
      copy={copy}
      items={getSortedRegions()}
      idKey="id"
      renderItem={renderItem}
      target={target}
      hideFooter
      editDisabled
      itemHeight={48}
      listWidth={300}
      isWhite
      searchEnabled
      renderHeaderButton={renderHeaderButton}
      handleClose={onClose}
      itemFilter={handleFilterRegion}
      popoverClassName="bulk-region-selector"
      handleSelect={handleSelect}
    />
  ) : null;
};

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledDoneButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};

  &:hover {
    filter: brightness(95%);
  }
`;

const RegionText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray2};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const RegionAddRemoveText = styled.div<{ $isAdded: boolean }>`
  font-size: 12px;
  color: ${({ $isAdded, theme }) =>
    $isAdded ? theme.colors.colorCalendarRed : theme.colors.colorCalendarBlue};
`;

const ItemContainer = styled.div<{ $isAdded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  width: 100%;

  :not(:hover) {
    ${RegionAddRemoveText} {
      ${(props) => (!props.$isAdded ? 'opacity: 0' : '')};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;
