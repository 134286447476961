export const blurOnEnter = (ref) => (e) => {
  if (ref?.current && e.key === 'Enter') {
    ref.current.blur();
  }
};

export const clearValueWhenZero = (value, setState) => {
  if (!+value) {
    setState('');
  }
};
