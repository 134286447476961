import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { ValueOf } from 'type-fest';
import { useGoToMemberModal } from 'appUtils/hooks/navigation/useGoToMemberModal';
import { OptionsDropdown } from 'components/options/OptionsDropdownV2';
import { MEMBER_VIEW_TYPE } from 'appConstants/navigation';
import { MemberProfile, WorkSchedule } from './MemberThreeDotMenuItems';
import ThreeDotHorizontal from 'icons/ThreeDotHorizontal';

// navigateToMemberModal: requires MemberModalRouter
// when adding new items, add them to optionHash and only pass options array to differentiate the menus
export const MemberThreeDotMenu = ({
  options,
  accountId,
  shouldRotateIcon = true
}: {
  options: MemberThreeMenuOption[];
  accountId: number;
  shouldRotateIcon?: boolean;
}) => {
  const { navigateToMemberModal } = useGoToMemberModal();

  const optionHash = useMemo(
    () => ({
      [MenuOptions.memberProfile]: {
        label: <MemberProfile />,
        handleClick: () => {
          navigateToMemberModal({ accountId, tab: MEMBER_VIEW_TYPE.OVERVIEW });
        }
      },
      [MenuOptions.workSchedule]: {
        label: <WorkSchedule />,
        handleClick: () => {
          navigateToMemberModal({
            accountId,
            tab: MEMBER_VIEW_TYPE.SCHEDULE
          });
        },
        tooltip: 'Only Admins can edit Member Capacity.'
      }
    }),
    [navigateToMemberModal, accountId]
  );

  const renderToggle = useCallback(
    ({ isActive }) => (
      <StyledThreeDotIconContainer
        rotateDeg={shouldRotateIcon ? 90 : 0}
        isActive={isActive}
      >
        <ThreeDotHorizontal
          className="member-three-dot"
          active={isActive}
          strokeWidth={0}
          size={24}
        />
      </StyledThreeDotIconContainer>
    ),
    [shouldRotateIcon]
  );

  return (
    <StyledOptionsDropdownContainer>
      <OptionsDropdown
        options={options}
        optionHash={optionHash}
        renderToggle={renderToggle}
      />
    </StyledOptionsDropdownContainer>
  );
};

const MenuOptions = {
  memberProfile: 'memberProfile',
  workSchedule: 'workSchedule'
} as const;

export type MemberThreeMenuOption = ValueOf<typeof MenuOptions>;

const StyledThreeDotIconContainer = styled.div<{
  rotateDeg: number;
  isActive?: boolean;
}>`
  height: fit-content;

  .member-three-dot {
    transform: ${({ rotateDeg }) => `rotate(${rotateDeg}deg)`};
    cursor: pointer;
    transition: 0.1s ease-in;

    path {
      fill: ${({ isActive }) =>
        isActive
          ? theme.colors.colorCalendarBlue
          : theme.colors.colorCalendarGray};
      stroke: ${({ isActive }) =>
        isActive
          ? theme.colors.colorCalendarBlue
          : theme.colors.colorCalendarGray};
    }

    &:hover {
      transform: ${({ rotateDeg }) => `rotate(${rotateDeg}deg) scale(1.1)`};
      path {
        fill: ${theme.colors.colorCalendarBlue};
        stroke: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;

const StyledOptionsDropdownContainer = styled.div`
  .options-dropdown-menu {
    padding: 0;

    .options-dropdown-option {
      padding: 0;
    }
  }
`;
