import React, { useRef } from 'react';
import CellContainer from './CellContainer';
import TaskPriority from '../TaskPriority';
import { makeNoop } from 'appUtils/noop';

const PriorityCell = ({ row }) => {
  const target = useRef(null);

  const {
    task_priority_id,
    id,
    handlePropertySelect = makeNoop,
    taskProps = {}
  } = row.original;
  const { taskIsEditing, isNewTask } = taskProps;
  return (
    <CellContainer
      taskProps={taskProps}
      target={target}
      taskProperty="priority"
      row={row}
    >
      <TaskPriority
        target={target}
        isNewTask={isNewTask}
        priorityId={task_priority_id}
        onSubmit={handlePropertySelect('task_priority_id')}
        taskId={id}
        taskIsEditing={taskIsEditing}
      />
    </CellContainer>
  );
};
export default PriorityCell;
