import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TaskSortIndicator,
  TaskSortWrapper,
  TaskSortText,
  ClearSort,
  SortContent,
  PlaceholderHeaderCell
} from './styles';
import SortIconUp from 'icons/SortUpIcon';
import {
  triggerFetchInitialTasks,
  updateTaskSortOrder,
  triggerTaskStoreFlush
} from 'actionCreators';
import {
  getSortOrder,
  getCurrentFilter,
  getBatchSelectedTaskIds,
  getMatchedRouteParams,
  getIsOnProjectView
} from 'selectors';
import ReactTooltip from 'react-tooltip';
import { noop } from 'appUtils';
class TaskSort extends Component {
  sortTasks = () => {
    const {
      taskProperty,
      updateTaskSortOrder,
      triggerFetchInitialTasks,
      triggerTaskStoreFlush,
      currentSort,
      isOnProjectView
    } = this.props;

    const isSorted = taskProperty === currentSort;
    const isSelection = taskProperty === 'selection';
    const sort = isSorted ? null : taskProperty;

    if (taskProperty !== 'completed_at' && !isSelection) {
      triggerTaskStoreFlush();
      updateTaskSortOrder({ sort });
      !isOnProjectView && triggerFetchInitialTasks({});
    }
  };

  getSortContent = (taskProperty) => {
    const { batchSelectedTaskIds, currentFilter } = this.props;
    const textMap = {
      due_at: 'Due',
      schedule_start: 'Planned',
      estimated_hours: 'Time Est.',
      status: 'Status',
      priority: 'Priority',
      phase_id: 'Phase',
      completed_at: 'Completed',
      assignee: 'Assignees',
      selection:
        batchSelectedTaskIds && Object.keys(batchSelectedTaskIds).length
          ? ''
          : 'Select'
    };
    return textMap[taskProperty] || '';
  };

  render() {
    const { taskProperty, currentSort, currentFilter } = this.props;
    const isSorted = taskProperty === currentSort;
    const text = this.getSortContent(taskProperty);
    const isSelection = taskProperty === 'selection';
    const isCompleted = taskProperty === 'completed_at';
    const isDisabled = text === 'Assignees' || taskProperty === 'assigned';

    if (taskProperty === 'timer') {
      return <PlaceholderHeaderCell />;
    }

    return (
      <TaskSortWrapper
        onClick={isDisabled ? noop : this.sortTasks}
        isSelection={isSelection}
        isSorted={isSorted}
        currentFilter={currentFilter}
        taskProperty={taskProperty}
        data-for={!isCompleted && 'sortby'}
        isDisabled={isDisabled}
        data-offset="{ 'top': -15 }"
        data-tip={
          text === 'Time Est.'
            ? 'Sort by Time Estimated'
            : isSelection
            ? text === 'Select'
              ? 'Multi-task select'
              : ''
            : `Sort by ${text}`
        }
      >
        {!isSelection && (
          <ReactTooltip
            effect="solid"
            id="sortby"
            class="task-sort-tooltips"
            place="top"
          />
        )}
        <SortContent>
          {!isSelection && <ClearSort />}
          <TaskSortText isSorted={isSorted}>{text}</TaskSortText>
          {!isCompleted && !isSelection && (
            <TaskSortIndicator isSorted={isSorted}>
              <SortIconUp />
            </TaskSortIndicator>
          )}
        </SortContent>
      </TaskSortWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSort: getSortOrder(state),
  currentFilter: getCurrentFilter(state),
  batchSelectedTaskIds: getBatchSelectedTaskIds(state),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state)
});

const mapDispatchToProps = {
  triggerFetchInitialTasks,
  updateTaskSortOrder,
  triggerTaskStoreFlush
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskSort)
);
