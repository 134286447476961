import { zoomToTopIntervalHash } from 'appUtils/projectPlannerUtils';
import { DateHeader } from 'react-calendar-timeline';
import { BaseDateHeaderProps } from './types';
import { PrimaryIntervalRenderer } from './PrimaryIntervalRenderer';
import { DATE_HEADER_HEIGHT } from 'TimelinesModule/components/constants';

export const PrimaryDateHeader = ({ zoom }: BaseDateHeaderProps) => {
  return (
    <DateHeader
      height={DATE_HEADER_HEIGHT.Primary}
      unit={zoomToTopIntervalHash[zoom]}
      intervalRenderer={PrimaryIntervalRenderer}
      headerData={{ zoom }}
    />
  );
};
