import { StyledCircle } from './styles';

const ColorPickerCircleIcon = ({
  color,
  id,
  isOpen,
  originType,
  alwaysShow,
  isDisabled
}) => (
  <StyledCircle
    $alwaysShow={alwaysShow}
    $color={color}
    $isDisabled={isDisabled}
    $isOpen={isOpen}
    $originType={originType}
    className="color-picker-circle-swirl"
    id={id}
  />
);

export default ColorPickerCircleIcon;
