import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  fetchSelectedBoard,
  addBulkMembersToProjectPermissionCheck
} from 'actionCreators';
import {
  makeGetTeamMembershipByAccountId,
  getMemberListByAccountId,
  getSelectedTeamId
} from 'selectors';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton
} from 'BudgetModule/components/styles';
import styled from 'styled-components';
import theme from 'theme';

import withPermissionsCheck from 'hocs/withPermissionsCheck';

const JoinProjectHeader = styled.div`
  font-size: 22px;
  margin-bottom: 16px;
  font-weight: 600;
`;

const JoinProjectText = styled.div`
  font-size: 13px;
`;

const JoinProjectButtonRow = styled(ModalButtonsRow)`
  position: relative;
  right: -10px;
  margin-top: 24px;
`;

const JoinCancelButton = styled(CancelButton)`
  border: 1px solid ${theme.colors.colorLightGray9};
  color: ${theme.colors.colorMediumGray5};
  width: 66px;
  height: 33px;
  padding: 0px;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    color: ${theme.colors.colorSemiDarkGray1};
  }
`;

const JoinConfirmButton = styled(ConfirmButton)`
  font-size: 14px;
  width: 64px;
  height: 33px;
  font-weight: 600;
  padding: 1px 9px 1px 9px;
`;

class JoinProjectModal extends React.Component {
  componentDidMount() {
    const { fetchSelectedBoard, selectedProject } = this.props;
    if (selectedProject?.board_id) {
      fetchSelectedBoard(selectedProject.board_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchSelectedBoard, selectedProject } = this.props;

    if (
      prevProps.selectedProject?.board_id !== selectedProject?.board_id &&
      selectedProject?.board_id
    ) {
      fetchSelectedBoard(selectedProject.board_id);
    }
    if (
      !prevProps.open !== this.props.open ||
      prevProps.selectedProject?.board_id !== selectedProject?.board_id ||
      prevProps.selectedProject?.id !== selectedProject?.id
    ) {
      this.canAddMember = undefined;
    }
  }

  checkAddPermission = () => {
    if (!this.props.open) {
      return;
    }
    if (this.canAddMember === undefined) {
      const {
        checkPermission,
        selectedProject,
        selectedTeamId,
        addBulkMembersToProjectPermissionCheck
      } = this.props;
      this.canAddMember = checkPermission(
        addBulkMembersToProjectPermissionCheck,
        {
          permissions: {
            projectId: selectedProject?.id,
            boardId: selectedProject?.board_id,
            teamId: selectedTeamId
          }
        }
      );
    }
    return this.canAddMember;
  };

  renderHasPermission = () => {
    const {
      selectedProject,
      text = 'time entry',
      accountId,
      currentUserId,
      teamMembership,
      memberList
    } = this.props;
    const isTeamMember = memberList && memberList[accountId];
    const boardName = selectedProject?.board?.name;
    const projectTitle = selectedProject?.title;
    return (
      <>
        {accountId && currentUserId && `${accountId}` === `${currentUserId}` ? (
          <span>
            <span style={{ fontWeight: '600' }}>You </span>need
          </span>
        ) : (
          <span>
            <span style={{ fontWeight: '600' }}>
              {teamMembership?.account?.name ?? 'This member '}
            </span>{' '}
            needs
          </span>
        )}{' '}
        to be a project member to {text}. Do you want to add this member to{' '}
        {accountId && !isTeamMember ? (
          <span style={{ fontWeight: '600' }}>
            {boardName} Portfolio and {projectTitle} ?
          </span>
        ) : (
          <span style={{ fontWeight: '600' }}>{projectTitle} ? </span>
        )}
      </>
    );
  };

  renderNoPermission = () => {
    const {
      selectedProject,

      accountId,
      currentUserId,
      teamMembership
    } = this.props;

    const projectTitle = selectedProject?.title;
    const userDisplayName =
      accountId && currentUserId && `${accountId}` === `${currentUserId}`
        ? 'yourself'
        : teamMembership?.account?.name ?? 'members';
    return (
      <>
        You do not have permission to add {userDisplayName} to{' '}
        <span style={{ fontWeight: '600' }}>{projectTitle}</span>.
        <br />
        Contact your Admins.
      </>
    );
  };

  render() {
    const { open, toggle, onConfirm } = this.props;
    const hasPermission = this.checkAddPermission();
    return (
      <Modal
        toggle={toggle}
        isOpen={open}
        className="join-project-modal-timesheet"
        zIndex={1070}
      >
        <ModalBody>
          <>
            <JoinProjectHeader>Add Member</JoinProjectHeader>
            <JoinProjectText>
              {hasPermission
                ? this.renderHasPermission()
                : this.renderNoPermission()}
            </JoinProjectText>
          </>
          <JoinProjectButtonRow>
            <Left />
            <Right>
              {hasPermission ? (
                <>
                  <JoinCancelButton onClick={toggle}>Cancel</JoinCancelButton>
                  <JoinConfirmButton onClick={onConfirm}>Add</JoinConfirmButton>
                </>
              ) : (
                <JoinConfirmButton onClick={toggle}>Okay</JoinConfirmButton>
              )}
            </Right>
          </JoinProjectButtonRow>
        </ModalBody>
      </Modal>
    );
  }
}

const makeMapStateToProps = () => {
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();

  const mapStateToProps = (state, ownProps) => ({
    teamMembership: getTeamMembershipByAccountId(state, ownProps),
    memberList: getMemberListByAccountId(state),
    selectedTeamId: getSelectedTeamId(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchSelectedBoard,
  addBulkMembersToProjectPermissionCheck
};

export default withPermissionsCheck(
  connect(makeMapStateToProps, mapDispatchToProps)(JoinProjectModal)
);
