import { makeVerification } from '../makeVerification';

// Manage Integrations Setting
export const verifyManageIntegrationSettings = makeVerification(
  'manage_integrations'
);

// Manage Integration Linking
export const verifyManageIntegrationLinking = makeVerification(
  'manage_integration_link'
);

// TODO: Add to permission Actions
