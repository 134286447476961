import { ProjectUnassignedMemberBudgetWithRequirementsListItem } from '../types';
import EllipsisText from 'components/EllipsisText';
import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';
import cn from 'classnames';

export const ProjectUnassignedMemberBudgetWithRequirements = (
  item: ProjectUnassignedMemberBudgetWithRequirementsListItem
) => {
  const {
    memberBudget: { position },
    toggleCollapse,
    isOpen,
    requirementCounts
  } = item;
  const { nameWithCount, name } = position || {};

  const nameToUse = nameWithCount || name;

  const requirementsText =
    requirementCounts > 0
      ? `${requirementCounts} ${
          requirementCounts > 1 ? 'Requirements' : 'Requirement'
        }`
      : '';

  return (
    <StyledItemContainer
      data-testid="project-unassigned-member-budget-with-requirements-item-container"
      className={'project-unassigned-member-budget-with-requirements'}
    >
      <div
        className={cn('text-container', {
          isOpen
        })}
        data-testid="text-container"
        onClick={isOpen ? undefined : toggleCollapse}
      >
        <EllipsisText
          data-testid="requirement-name"
          className="requirement-name"
          maxWidth={230}
        >
          {nameToUse}
        </EllipsisText>
        {!isOpen && (
          <div data-testid="requirement-counts" className="requirement-counts">
            {requirementsText}
          </div>
        )}
      </div>
    </StyledItemContainer>
  );
};
