import { CurrencyFormatterProps } from '.';

export const currencyFormatter = ({
  languageCode = 'en-US', // eventually will be replaced by multi-language supoort
  currencyCode,

  options = {}
}: CurrencyFormatterProps): Intl.NumberFormat => {
  // default minimumFractionDigits will prevent app from crahsing
  // since the oldeer version of browsers don't support it
  // while throwing an error of out range error by misaligned options
  const {
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    ...restOptions
  } = options;
  return new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
    // maximumFractionDigits should be always greater or equal to minimumFractionDigits
    maximumFractionDigits: Math.max(
      minimumFractionDigits,
      maximumFractionDigits
    ),
    ...restOptions
  });
};
