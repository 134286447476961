import { serializeId } from 'appUtils';
import { TeamMember } from 'TeamsModule/models/teamMember';
import { TIMELINE_GROUP_TYPES } from 'TimelinesModule/components/constants';
import { GroupId } from 'TimelinesModule/types/baseGroup';
import { EmptyGroup } from 'TimelinesModule/types/emtpyGroup';
import { MemberGroup } from 'TimelinesModule/types/memberGroup';
import { v4 } from 'uuid';

export const serializeGroupId = ({
  id,
  groupType
}: {
  id: GroupId;
  groupType: TIMELINE_GROUP_TYPES;
}) =>
  serializeId({
    id,
    itemType: groupType,
    ids: undefined
  });

export const mapTeamMemberToMemberGroup = ({
  teamMember,
  totalCapacity
}: {
  teamMember: TeamMember;
  totalCapacity?: number;
}): MemberGroup => {
  const { account, is_archived } = teamMember;

  const groupId = serializeGroupId({
    id: account.id,
    groupType: TIMELINE_GROUP_TYPES.MEMBER
  });

  return {
    id: groupId,
    title: '',
    type: TIMELINE_GROUP_TYPES.MEMBER,
    accountId: account.id,
    name: account.name,
    isArchived: is_archived,
    totalCapacity
  };
};

export const generateEmptyGroups = ({
  count
}: {
  count: number;
}): EmptyGroup[] =>
  Array.from({ length: count }, () => ({
    id: v4(),
    title: '',
    type: TIMELINE_GROUP_TYPES.EMPTY
  }));
