import React from 'react';
import SvgIcon from 'components/SvgIcon';

const InviteToMosaicWithPlusIcon = ({
  width = '18',
  height = '13',
  fillColor = '#2F80ED'
}) => (
  <SvgIcon width={width} height={height} viewBox="0 0 18 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.4625 0H14.1375C14.9452 0 15.6 0.671573 15.6 1.5V6.5H14.625V1.615L8.08275 6.405C7.91351 6.52859 7.68649 6.52859 7.51725 6.405L0.975001 1.615V10.5C0.975001 10.7761 1.19326 11 1.4625 11H8.38992V12H1.4625C0.654785 12 0 11.3284 0 10.5V1.5C0 0.671573 0.654785 0 1.4625 0ZM1.81348 1.00002L7.79998 5.38502L13.7865 1.00002H1.81348Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1376 0H1.4626C0.654881 0 9.72748e-05 0.671573 9.72748e-05 1.5V6.5H0.975098V1.615L7.51735 6.405C7.68659 6.52859 7.91361 6.52859 8.08285 6.405L14.6251 1.615V10.5C14.6251 10.7761 14.4068 11 14.1376 11H7.21018V12H14.1376C14.9453 12 15.6001 11.3284 15.6001 10.5V1.5C15.6001 0.671573 14.9453 0 14.1376 0ZM13.7866 1.00002L7.8001 5.38502L1.8136 1.00002H13.7866Z"
      fill={fillColor}
    />
    <circle cx="15" cy="10" r="3" fill={fillColor} />
    <path
      d="M15.5 8H14.5V9.5H13V10.5H14.5V12H15.5V10.5H17V9.5H15.5V8Z"
      fill="white"
    />
  </SvgIcon>
);

export default InviteToMosaicWithPlusIcon;
