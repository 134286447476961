import React from 'react';
import styled from 'styled-components';

const passwordPolicy = [
  { label: 'At least 14 characters' },
  { label: 'At least one number' },
  { label: 'At least one upper case letter' },
  { label: 'At least one lower case letter' },
  { label: 'At least one special character' }
];

export const PasswordPolicyList = () => {
  return (
    <PasswordPolicyListContainer>
      {passwordPolicy.map((item) => (
        <ListItem key={item.label}>
          <LeadingContainer>
            <LeadingIcon />
          </LeadingContainer>
          <PolicyText>{item.label}</PolicyText>
        </ListItem>
      ))}
    </PasswordPolicyListContainer>
  );
};

const PasswordPolicyListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const LeadingContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const LeadingIcon = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.colorLightGray15};
  border-radius: 8px;
`;

const PolicyText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorLightGray15};
`;
