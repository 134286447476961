import { ENTITY_DESCRIPTIONS } from 'SettingsModule/constants';
import theme from 'theme';
import { useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  getMe,
  getSelectedTeamId,
  getGroups,
  getSelectedProject,
  getSelectedProjectBoardInfo,
  getSelectedAccountIds
} from 'selectors';
import {
  editProject,
  openAddMembersForm,
  openProjectModal,
  fetchProjectById
} from 'actionCreators';
import withPermissionsCheck from 'hocs/withPermissionsCheck';

import SelectBoardDropdownContainer from '../../projectSettings/SelectBoardDropdownContainer';
import ProjectColorDot from 'components/ProjectColorDot';
import { ProjectDetailUpdates, ProjectMembers } from 'views';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { BulkRegionSelector } from 'RegionsModule/components/BulkRegionSelector';
import { BulkOfficeSelector } from 'OfficesModule/components/BulkOfficeSelector';
import { ProjectCurrencyDropdown } from 'CurrencyModule/components/ProjectCurrencyDropdown';
import { Office } from 'SettingsModule/models/office';
import { Region } from 'SettingsModule/models/region';
import { updateRegionEntities } from 'SettingsModule/actionCreators/settings/region';
import { makeGetRegionsByIds } from 'SettingsModule/selectors/regions';
import { makeGetOfficesByIds } from 'SettingsModule/selectors/offices';
import { batch } from 'react-redux';
import { updateOfficeEntities } from 'SettingsModule/actionCreators/settings/office';
import {
  makeGetEntityLocationsHash,
  makeGetLocationsByIds
} from 'SettingsModule/selectors/locations';
import { EntityLocation } from 'SettingsModule/models/location';
import { BulkLocationSelector } from 'LocationsModule/BulkLocationSelector';
import {
  associateEntityToLocation,
  deleteEntityLocation
} from 'SettingsModule/actionCreators/settings/location';
import { getSelectedCurrencyString } from 'CurrencyModule/utils';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { makeGetDefaultCurrencyCodeById } from 'CurrencyModule/selectors';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import { ProjectTagsList } from 'ProjectsModule/core/components/ProjectTagsList';

const ProjectInfoTabWrapper = styled.div`
  display: flex;
`;

const ProjectInfoFormWrapper = styled.div`
  padding-left: 208px;
`;

const ProjectInfoWidgetsWrapper = styled.div`
  width: 430px;
  padding-left: 74px;
`;

const WidgetWrapper = styled.div`
  width: 430px;
  padding-bottom: 27px;
`;

const InputFieldContainer = styled.div<{ $withoutFixedWidth?: boolean }>`
  padding-top: 30px;

  input {
    ${({ $withoutFixedWidth }) => !$withoutFixedWidth && 'width: 386px;'}
    height: 38px;
    border-radius: 3px;

    border-width: 0px;

    padding: 8px;
    padding-left: 17px;
  }
`;

const InfoHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;

  color: #4f4f4f;
`;

const ProjectTitleContainer = styled.div`
  position: relative;

  input {
    width: 386px;
    height: 38px;

    padding-left: 29px;
    border-width: 0px;
    border-radius: 3px;
  }
`;

const ProjectNameContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const ProjectPortfolioContainer = styled.div`
  padding-bottom: 30px;
`;

const DotWrapper = styled.div`
  position: absolute;
  left: 14px;
`;

const DropdownWrapper = styled.div`
  background-color: white;
  width: 386px;
  height: 38px;

  padding-top: 3px;
  border-radius: 3px;
`;

const SelectorWrapper = styled(DropdownWrapper)`
  padding: 6px 12px;
  cursor: pointer;
  display: flex;
`;

const SelectorTriggerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const SelectorTriggerTextContainer = styled.div`
  width: 98%;
  display: -webkit-box;
`;

const SelectorTriggerTextContent = styled.div<{
  $hasOverflowItems?: boolean;
  $hasAnyItems?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  max-width: ${(props) => (props.$hasOverflowItems ? 89 : 98)}%;
  ${(props) =>
    !props.$hasAnyItems && `color: ${theme.colors.colorMediumGray1};`}
`;

const SelectorTriggerOverflowText = styled.div`
  max-width: 20%;
`;

const ProjectDetailUpdatesContainer = styled.div`
  margin-top: -20px;
`;

const ProjectInfoTab = () => {
  const me = useAppSelector(getMe);
  const dispatch = useAppDispatch();
  const teamID = useAppSelector(getSelectedTeamId);
  const group = useAppSelector(getSelectedProjectBoardInfo);
  const project = useAppSelector(getSelectedProject);
  const groupList = useAppSelector(getGroups);
  const getRegionsByIds = useMemo(makeGetRegionsByIds, []);
  const getOfficesByIds = useMemo(makeGetOfficesByIds, []);
  const getLocationsByIds = useMemo(makeGetLocationsByIds, []);
  const getCurrencyCode = useMemo(makeGetDefaultCurrencyCodeById, []);
  const { projectCurrencySetting } = useFeatureFlags();
  const { isHoursOnly } = useIsHoursOnly();

  const projectRegions = useAppSelector((state) =>
    getRegionsByIds(state, { ids: project.region_ids })
  );

  const projectOffices = useAppSelector((state) =>
    getOfficesByIds(state, { ids: project.office_ids })
  );

  const projectLocations = useAppSelector((state) =>
    getLocationsByIds(state, { ids: project.location_ids })
  );

  const currencyCode = useAppSelector((state) =>
    getCurrencyCode(state, {
      entityType: ENTITY_TYPES.project,
      entityId: project.id
    })
  );

  const projectSelectedAccountIds = useAppSelector(getSelectedAccountIds);
  const isProfitCenterEnabled =
    project?.custom_fields?.profit_center !== undefined;

  const [title, setTitle] = useState(project?.title);
  const [selectedGroup, setSelectedGroup] = useState(group);
  const [isRegionSelectorOpen, setIsRegionSelectorOpen] = useState(false);
  const [isOfficeSelectorOpen, setIsOfficeSelectorOpen] = useState(false);
  const [isLocationSelectorOpen, setIsLocationSelectorOpen] = useState(false);
  const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState(false);
  const [description, setDescription] = useState(project?.description || '');
  const [client, setClient] = useState(project?.client || '');
  const [projectID, setProjectID] = useState(project?.project_number || '');
  const [profitCenter, setProfitCenter] = useState(
    project?.custom_fields?.profit_center || ''
  );

  const getEntityLocationsHash = useMemo(makeGetEntityLocationsHash, []);

  const entityLocationsHash = useAppSelector((state) =>
    getEntityLocationsHash(state, {
      entityType: ENTITY_TYPES.project,
      entityId: project.id
    })
  );

  const regionSelectorRef = useRef(null);
  const officeSelectorRef = useRef(null);
  const locationSelectorRef = useRef(null);
  const currencyDropdownRef = useRef(null);

  const getPermissions = () => {
    const permissions = {
      projectId: project?.id,
      boardId: group?.id,
      teamId: teamID
    };
    return permissions;
  };

  const editProjectWithPermissions = (values) => {
    const permissions = getPermissions();
    dispatch(editProject({ ...values, id: project?.id, permissions }));
  };

  const changeSelectedGroup = (selectedGroup) => {
    editProjectWithPermissions({ projectGroupId: selectedGroup.id });
    setSelectedGroup(selectedGroup);
  };

  const handleAddRemoveRegions = ({
    addedRegions,
    removedRegions
  }: {
    addedRegions: Region[];
    removedRegions: Region[];
  }) => {
    batch(() => {
      addedRegions.forEach((region) => {
        dispatch(
          updateRegionEntities({
            id: region.id,
            addEntities: [
              { entityId: project.id, entityType: ENTITY_TYPES.project }
            ]
          })
        );
      });

      removedRegions.forEach((region) => {
        dispatch(
          updateRegionEntities({
            id: region.id,
            removeEntities: [
              { entityId: project.id, entityType: ENTITY_TYPES.project }
            ]
          })
        );
      });
    });

    setIsRegionSelectorOpen(false);
  };

  const handleAddRemoveOffices = ({
    addedOffices,
    removedOffices
  }: {
    addedOffices: Office[];
    removedOffices: Office[];
  }) => {
    batch(() => {
      addedOffices.forEach((office) => {
        dispatch(
          updateOfficeEntities({
            id: office.id,
            addEntities: [
              { entityId: project.id, entityType: ENTITY_TYPES.project }
            ]
          })
        );
      });

      removedOffices.forEach((office) => {
        dispatch(
          updateOfficeEntities({
            id: office.id,
            removeEntities: [
              { entityId: project.id, entityType: ENTITY_TYPES.project }
            ]
          })
        );
      });
    });

    setIsOfficeSelectorOpen(false);
  };

  const handleAddRemoveLocations = ({
    addedLocations,
    removedLocations
  }: {
    addedLocations: EntityLocation[];
    removedLocations: EntityLocation[];
  }) => {
    if (teamID) {
      batch(() => {
        addedLocations.forEach((location) => {
          dispatch(
            associateEntityToLocation({
              locationId: location.id,
              teamId: teamID,
              entityId: project.id,
              entityType: ENTITY_TYPES.project,
              description: ENTITY_DESCRIPTIONS.projectLocation
            })
          );
        });

        removedLocations.forEach((location) => {
          const entityLocationOfLocation = entityLocationsHash[location.id];

          if (entityLocationOfLocation) {
            dispatch(
              deleteEntityLocation({
                entityLocationId: entityLocationOfLocation.id,
                meta: {
                  entityType: ENTITY_TYPES.project,
                  entityId: project.id
                }
              })
            );
          }
        });
      });
    }

    setIsLocationSelectorOpen(false);
  };

  const regionFilter = (regions: Region[]) => regions;
  const officeFilter = (offices: Office[]) => offices;
  const locationFilter = (locations: EntityLocation[]) => locations;

  const hasMultipleRegions = projectRegions.length > 1;
  const regionTrailingContent = hasMultipleRegions
    ? `, +${projectRegions.length - 1}`
    : undefined;

  const hasMultipleOffices = projectOffices.length > 1;
  const officeTrailingContent = hasMultipleOffices
    ? `, +${projectOffices.length - 1}`
    : undefined;

  const hasMultipleLocations = projectLocations.length > 1;
  const locationTrailingContent = hasMultipleLocations
    ? `, +${projectLocations.length - 1}`
    : undefined;

  const renderProjectInfoField = (
    header,
    placeholder,
    value,
    setState,
    projectField,
    isCustom
  ) => {
    return (
      <InputFieldContainer>
        <InfoHeader>{header}</InfoHeader>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setState(e.target.value);
          }}
          onBlur={() => {
            isCustom
              ? editProjectWithPermissions({
                  custom_fields: [{ label: projectField, value }]
                })
              : editProjectWithPermissions({ [projectField]: value });
          }}
        />
      </InputFieldContainer>
    );
  };
  return (
    <>
      <ProjectInfoTabWrapper>
        <ProjectInfoFormWrapper>
          {group ? (
            <ProjectPortfolioContainer>
              <InfoHeader>PORTFOLIO</InfoHeader>
              <DropdownWrapper>
                <SelectBoardDropdownContainer
                  group={group}
                  groupList={groupList}
                  changeSelectedGroup={changeSelectedGroup}
                />
              </DropdownWrapper>
            </ProjectPortfolioContainer>
          ) : null}
          <ProjectTitleContainer>
            <InfoHeader>PROJECT</InfoHeader>
            <ProjectNameContainer>
              <DotWrapper>
                <ProjectColorDot
                  size={10}
                  hasColorPicker
                  projectId={project?.id}
                  pickerLocation="add-edit-project-form"
                />
              </DotWrapper>
              <input
                type="text"
                defaultValue={title}
                name="title"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                placeholder="Project Title"
                required
                autoComplete="off"
                autoFocus={false}
                onBlur={() => {
                  editProjectWithPermissions({ title });
                }}
              />
            </ProjectNameContainer>
          </ProjectTitleContainer>
          {renderProjectInfoField(
            'DESCRIPTION',
            'Enter a description...',
            description,
            setDescription,
            'description',
            false
          )}
          {group
            ? renderProjectInfoField(
                'CLIENT',
                'Enter a client...',
                client,
                setClient,
                'client',
                false
              )
            : null}
          <InputFieldContainer $withoutFixedWidth>
            <InfoHeader>TAG</InfoHeader>
            <ProjectTagsList
              tags={project?.general_tags ?? []}
              onAddTag={(tag) =>
                editProjectWithPermissions({ addGeneralTags: [tag] })
              }
              onRemoveTag={(tag) =>
                editProjectWithPermissions({ removeGeneralTags: [tag] })
              }
            />
          </InputFieldContainer>
          {renderProjectInfoField(
            'PROJECT ID/NUMBER',
            'Enter a project id...',
            projectID,
            setProjectID,
            'number',
            false
          )}
          {isProfitCenterEnabled
            ? renderProjectInfoField(
                'PROFIT CENTER',
                'Enter a profit center...',
                profitCenter,
                setProfitCenter,
                'profit_center',
                true
              )
            : null}
        </ProjectInfoFormWrapper>
        <ProjectInfoWidgetsWrapper>
          <WidgetWrapper>
            <InfoHeader>PROJECT UPDATE</InfoHeader>
            {project.status?.length ? (
              <ProjectDetailUpdatesContainer>
                <ProjectDetailUpdates project={project} />
              </ProjectDetailUpdatesContainer>
            ) : (
              <SelectorWrapper
                onClick={() => {
                  dispatch(
                    openProjectModal({
                      projectId: project.id
                    })
                  );

                  dispatch(
                    fetchProjectById(project.id, [], null, null, null, true)
                  );
                }}
              >
                <SelectorTriggerContainer>
                  <SelectorTriggerTextContent>
                    + Update
                  </SelectorTriggerTextContent>
                </SelectorTriggerContainer>
              </SelectorWrapper>
            )}
          </WidgetWrapper>
          <WidgetWrapper>
            <InfoHeader>REGION</InfoHeader>
            <SelectorWrapper
              ref={regionSelectorRef}
              onClick={() => setIsRegionSelectorOpen(true)}
            >
              <SelectorTriggerContainer>
                <SelectorTriggerTextContainer>
                  <SelectorTriggerTextContent
                    $hasOverflowItems={hasMultipleRegions}
                    $hasAnyItems={!!projectRegions.length}
                  >
                    {projectRegions.length
                      ? projectRegions[0].name
                      : 'Select region(s)'}
                  </SelectorTriggerTextContent>
                  <SelectorTriggerOverflowText>
                    {regionTrailingContent}
                  </SelectorTriggerOverflowText>
                </SelectorTriggerTextContainer>
                <i className="fa fa-angle-down" />
              </SelectorTriggerContainer>
            </SelectorWrapper>
          </WidgetWrapper>
          <WidgetWrapper>
            <InfoHeader>OFFICE</InfoHeader>
            <SelectorWrapper
              ref={officeSelectorRef}
              onClick={() => setIsOfficeSelectorOpen(true)}
            >
              <SelectorTriggerContainer>
                <SelectorTriggerTextContainer>
                  <SelectorTriggerTextContent
                    $hasOverflowItems={hasMultipleOffices}
                    $hasAnyItems={!!projectOffices.length}
                  >
                    {projectOffices.length
                      ? projectOffices[0].name
                      : 'Select office(s)'}
                  </SelectorTriggerTextContent>
                  <SelectorTriggerOverflowText>
                    {officeTrailingContent}
                  </SelectorTriggerOverflowText>
                </SelectorTriggerTextContainer>
                <i className="fa fa-angle-down" />
              </SelectorTriggerContainer>
            </SelectorWrapper>
          </WidgetWrapper>
          {false && (
            <WidgetWrapper>
              <InfoHeader>LOCATION</InfoHeader>
              <SelectorWrapper
                ref={locationSelectorRef}
                onClick={() => setIsLocationSelectorOpen(true)}
              >
                <SelectorTriggerContainer>
                  <SelectorTriggerTextContainer>
                    <SelectorTriggerTextContent
                      $hasOverflowItems={hasMultipleLocations}
                      $hasAnyItems={!!projectLocations.length}
                    >
                      {projectLocations[0]
                        ? projectLocations[0].full_address
                        : 'Select location(s)'}
                    </SelectorTriggerTextContent>
                    <SelectorTriggerOverflowText>
                      {locationTrailingContent}
                    </SelectorTriggerOverflowText>
                  </SelectorTriggerTextContainer>
                  <i className="fa fa-angle-down" />
                </SelectorTriggerContainer>
              </SelectorWrapper>
            </WidgetWrapper>
          )}
          {projectCurrencySetting && !isHoursOnly && (
            <WidgetWrapper>
              <InfoHeader>CURRENCY</InfoHeader>
              <SelectorWrapper
                ref={currencyDropdownRef}
                onClick={() => setIsCurrencyDropdownOpen(true)}
              >
                <SelectorTriggerContainer>
                  <SelectorTriggerTextContainer>
                    <SelectorTriggerTextContent $hasAnyItems={!!currencyCode}>
                      {currencyCode
                        ? getSelectedCurrencyString({ currencyCode })
                        : 'Select currency'}
                    </SelectorTriggerTextContent>
                  </SelectorTriggerTextContainer>
                  <i className="fa fa-angle-down" />
                </SelectorTriggerContainer>
              </SelectorWrapper>
            </WidgetWrapper>
          )}
          {group ? (
            <WidgetWrapper>
              <InfoHeader>MEMBERS</InfoHeader>
              <ProjectMembers
                isFloatLeft
                maxPerRow={12}
                openAddMembersForm={(modalType, project) =>
                  dispatch(openAddMembersForm(modalType, project))
                }
                me={me}
                selectedAccountIds={projectSelectedAccountIds}
                project={project}
                projectId={project?.id}
                handleMemberClick={(event, id) => {
                  /* noop */
                }}
                startShown={true}
              />
            </WidgetWrapper>
          ) : null}
        </ProjectInfoWidgetsWrapper>
      </ProjectInfoTabWrapper>
      <BulkRegionSelector
        isOpen={isRegionSelectorOpen}
        target={regionSelectorRef}
        regionFilter={regionFilter}
        initialAddedRegionsIds={project.region_ids}
        onDone={handleAddRemoveRegions}
        onClose={() => setIsRegionSelectorOpen(false)}
      />
      <BulkOfficeSelector
        isOpen={isOfficeSelectorOpen}
        target={officeSelectorRef}
        officeFilter={officeFilter}
        initialAddedOfficesIds={project.office_ids}
        onDone={handleAddRemoveOffices}
        onClose={() => setIsOfficeSelectorOpen(false)}
      />
      {false && (
        <BulkLocationSelector
          isOpen={isLocationSelectorOpen}
          target={locationSelectorRef}
          locationFilter={locationFilter}
          initialAddedLocationsIds={project.location_ids}
          onDone={handleAddRemoveLocations}
          onClose={() => setIsLocationSelectorOpen(false)}
        />
      )}
      {!isHoursOnly && (
        <ProjectCurrencyDropdown
          isOpen={isCurrencyDropdownOpen}
          target={currencyDropdownRef?.current}
          onClose={() => setIsCurrencyDropdownOpen(false)}
          currencyDropdownProps={{
            teamId: teamID,
            entityId: project.id,
            entityType: ENTITY_TYPES.project
          }}
        />
      )}
    </>
  );
};

export default withPermissionsCheck(ProjectInfoTab);
