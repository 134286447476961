import { createResponsiveStateReducer } from 'redux-responsive';
import { breakPoints } from 'theme';

const browser = createResponsiveStateReducer(breakPoints);
export const bootstrappedBrowserState = {
  _responsiveState: true,
  lessThan: {},
  greaterThan: {},
  is: {},
  mediaType: '',
  orientation: '',
  breakpoints: breakPoints
};

export default browser;
