import React from 'react';
import theme from 'theme';
import styled from 'styled-components';

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
  text-align: center;
`;

const HeaderCell = ({ column, style }) => {
  return <StyledHeader style={style}>{column.headerLabel}</StyledHeader>;
};

export default HeaderCell;
