import { CurrencyCode } from 'CurrencyModule/types';
import { DateISOString } from 'models/date';
import { EntityRate } from 'RatesModule/models/EntityRate';
import { Rate } from 'RatesModule/models/Rate';

export interface NewDateParams {
  date: DateISOString;
  entityRate: EntityRate;
  isStartDate: boolean;
}

export interface CellContentsProps {
  currencyCode: CurrencyCode;
  entityRate: EntityRate;
  rate: Rate;
  setDate: (params: NewDateParams) => void;
}

export enum EntityRateModalType {
  BillRate,
  CostRate
}

export enum EntityRateModalColumn {
  DateSeparator = 'dateSeparatorColumn',
  Delete = 'deleteColumn',
  Description = 'descriptionColumn',
  EndDate = 'endDateColumn',
  Rate = 'rateColumn',
  StartDate = 'startDateColumn'
}
