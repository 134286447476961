import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportPtoPolicy
} from 'CsvImportsModule/types';
import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import uniq from 'lodash/uniq';
import { getRowValuesForEntityType } from './utils';

interface AdditionalParams {
  existingPtoPoliciesGroupedByHours: Record<string, CsvImportPtoPolicy[]>;
  existingPtoPoliciesByDescription: Record<string, CsvImportPtoPolicy>;
  newPtoDescriptions: React.MutableRefObject<Record<string, boolean>>;
  newPtoDescriptionHoursHashes: React.MutableRefObject<Record<string, string>>;
}

export const ptoDescriptionOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingPtoPoliciesGroupedByHours,
    existingPtoPoliciesByDescription,
    newPtoDescriptions,
    newPtoDescriptionHoursHashes
  } = additionalParams;
  const { enteredPtoHours } = getRowValuesForEntityType(row, entityType);
  // case 1: entered pto hours is empty or training required string, return all possible pto descriptions
  if (!enteredPtoHours || enteredPtoHours === TRAINING_DATA_REQUIRED_STRING) {
    // return all possible pto descriptions
    return uniq([
      ...Object.keys(newPtoDescriptions.current),
      ...Object.keys(newPtoDescriptionHoursHashes.current),
      ...Object.keys(existingPtoPoliciesByDescription)
    ]);
  } else if (
    // case 2: entered pto hours is non empty
    enteredPtoHours &&
    enteredPtoHours !== TRAINING_DATA_REQUIRED_STRING
  ) {
    const existingPtoPoliciesMatchedByEnteredPtoHours =
      existingPtoPoliciesGroupedByHours[enteredPtoHours];
    const newMatchedPtoDescriptions: string[] = [];
    // case 2.1: put all matched pto descriptions matched by entered pto hours into newMatchedPtoDescriptions
    // return all pto descriptions matched by entered pto hours
    // key is description, value is hours matched by the description
    Object.entries(newPtoDescriptionHoursHashes.current).forEach(
      ([key, value]) => {
        if (value === enteredPtoHours) newMatchedPtoDescriptions.push(key);
      }
    );
    if (existingPtoPoliciesMatchedByEnteredPtoHours) {
      return [
        ...newMatchedPtoDescriptions,
        ...existingPtoPoliciesMatchedByEnteredPtoHours.map(
          (ptoPolicy) => ptoPolicy.name
        )
      ];
    } else {
      return newMatchedPtoDescriptions;
    }
  }

  return [];
};
