import { ReactNode } from 'react';
import styled from 'styled-components';
import { BaseCard } from './BaseCard';

interface PlaceholderCardProps {
  /**
   * The classname for the element wrapping the card.
   */
  className?: string;

  /**
   * Callback invoked when the card is clicked.
   */
  onClick?: () => void;

  /**
   * Label on the card.
   */
  title: ReactNode;

  /**
   * Tooltip for the card.
   */
  tooltip?: string;
}

export const PlaceholderCard = ({
  className,
  onClick,
  title,
  tooltip
}: PlaceholderCardProps) => {
  return (
    <BaseCard className={className} onClick={onClick} tooltip={tooltip}>
      <PlaceholderContainer>{title}</PlaceholderContainer>
    </BaseCard>
  );
};

const PlaceholderContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.colorLightGray15};
`;
