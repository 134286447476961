import {
  ERROR_MESSAGES,
  TRAINING_DATA_REQUIRED_STRING
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, enteredFieldIsTrue } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportTaskPriority
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingTaskPrioritiesByTitle: Record<string, CsvImportTaskPriority>;
}

export const taskPriorityValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingTaskPrioritiesByTitle } = additionalParams;

  const { enteredTaskPriority, enteredIsScope } = getRowValuesForEntityType(
    row,
    entityType
  );

  // skip validation of task priority if user is actually trying to import scope
  if (enteredFieldIsTrue(enteredIsScope)) return true;

  // not required, has to be one of the task priority options
  if (enteredTaskPriority === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (!enteredTaskPriority) return true;
  else if (!existingTaskPrioritiesByTitle[enteredTaskPriority])
    return ERROR_MESSAGES.invalidTaskPriority;
  else return true;
};
