import { serializeId } from 'appUtils';
import { useCallback } from 'react';
import { ROW_TYPES } from './layout';

export const useEmptyRowBuilder = () => {
  const emptyRowBuilder = useCallback((id = 0) => {
    return {
      id: serializeId({
        itemType: 'empty',
        id: id.toString(),
        ids: undefined,
        delimiter: undefined
      }),
      rowType: ROW_TYPES.emptyRow,
      rowHeight: 35
    };
  }, []);

  return emptyRowBuilder;
};
