import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  setHomeOrigin,
  fetchMyProjects,
  fetchAllProjects,
  updateMyProject,
  handleProjectItemState,
  saveSearchState,
  addMemberToProject,
  toggleSidebarSearch,
  toggleSidebarProjects,
  navigateToProject,
  navigateBack,
  openSidebarProjects,
  closeSidebarProjects,
  updateSidebarProjectsPinnedState,
  navigateToHome,
  closeColorPicker
} from 'actionCreators';
import {
  getOnBoardView,
  getOnProjectDetail,
  getOnHomeView,
  getSearchWords,
  getAuthToken,
  getAuth,
  getMe,
  getSearch,
  getSearchText,
  getNavigationHistory,
  getIsMyProjectsFullyLoaded,
  getAllNotMyProjectIds,
  getSidebarProjects,
  getProjectSearch,
  getMyUnstarredProjects,
  getMyStarredProjects,
  rawGetMyProjects,
  getIsOnProjectView,
  getSidebarProjectsOpen,
  getUserSidebarProjectsIsPinned,
  getUserSlug
} from 'selectors';
import ProjectFilter from 'views/projectPlanner/ProjectFilter';
import { FILTER_PAGE_NAMES } from 'appConstants/workload';
import { FilterContainer, StyledFilter } from 'views/projectPlanner/styles';
import NewCloseIcon from 'icons/NewCloseIcon';
import ProjectRow from './ProjectRow';
import BoardRow from './BoardRow';
import SearchIcon from 'icons/SearchIcon';
import MosaicLogo from 'images/mosaic-logo-full-2021.svg';
import HeaderExpandIcon from 'icons/HeaderExpandIcon';
import HamburgerBackIcon from 'images/hamburger-back-icon.svg';
import { CollapseAllContainer } from 'ReportsModule/components/Filter/styles';
import SidebarCloseIcon from 'icons/SidebarCloseIcon';
import PinIcon from 'icons/PinIcon';
import PinnedIcon from 'images/pinned-icon.svg';
import GripIcon from 'images/sidebar-grip-icon.svg';
import usePrevious from 'appUtils/usePrevious';
import { Sections } from 'appConstants/navigation';
import SideBarBackArrowIcon from 'icons/SideBarBackArrowIcon';
import { newFilter } from 'appUtils/filters';
import { getNavigatingTo } from 'appCore/navigation/selectors';

const SidebarCloseIconContainer = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: -4px;
  right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${theme.colors.colorLightGray22};
    .sidebar-close-icon {
      path {
        fill: ${theme.colors.colorCalendarBlue};
      }
      line {
        stroke: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;
const LogoContainer = styled.div`
  position: absolute;
  width: 157px;
  height: 32px;
  left: 56px;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
  }
`;
const LogoHeader = styled.div`
  height: 45px;
  width: 100%;
  text-align: center;
  position: relative;
  & > img {
    margin-right: 18px;
  }
`;
const CustomHeaderContainer = styled.div`
  position: relative;
  padding-bottom: 12px;
  padding-top: 18px;
`;
const StyledSearchInput = styled.input`
  font-size: 14px;
  border: none;
  outline: none;
  padding: 4px 5px 4px 34px;
  width: 254px;

  caret-color: ${theme.colors.colorCalendarBlue};

  ::placeholder {
    color: ${theme.colors.colorLightGray15};
  }
  &:active,
  &:focus {
    & + svg path {
      fill: ${theme.colors.colorCalendarBlue};
    }
    &::placeholder {
      color: transparent;
    }
  }
`;

const CloseIconContainer = styled.div`
  right: 36px;
  top: 0;
  position: absolute;
  display: flex;
  cursor: pointer;
  align-items: center;

  circle {
    fill: ${theme.colors.colorPureWhite};
  }

  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray5};
    }
  }
`;
const StyledSearchContainer = styled.div`
  position: relative;
  width: fit-content;
  margin-left: 18px;
  svg {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 3px;
    path {
      fill: ${theme.colors.colorMediumGray5};
    }
  }

  ${StyledSearchInput} {
    border-bottom: ${({ isActive }) =>
      `1px solid ${
        isActive ? theme.colors.colorCalendarBlue : theme.colors.colorLightGray9
      }`};
  }

  ${CloseIconContainer} {
    path {
      fill: ${({ isActive }) =>
        isActive ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray7};
    }
  }
  .search-icon {
    path {
      fill: ${({ isActive }) =>
        isActive ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray5};
    }
  }
`;

const StyledSidebar = styled(StyledFilter)`
  left: 0;
  border-right: 1px solid ${theme.colors.colorMediumGray12};
  ${CollapseAllContainer} {
    right: 13px;
    top: -11px;
    transform-origin: center 16px;
    transition: 0.275s;
  }
  ${(props) =>
    props.hideContent &&
    `
    .project-filter-container {
      display: none;
    }`}
`;
const BackArrowContainer = styled.div`
  position: absolute;
  top: -4px;
  left: 15px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.onClick && 'cursor: pointer;'}
  border-radius: 50%;
  &:hover {
    background-color: ${theme.colors.colorLightGray22};
  }
  .sidebar-backarrow-icon {
    path {
      fill: ${theme.colors.colorDarkBlue3};
    }
  }
`;
const PinContainer = styled.div`
  position: absolute;
  top: -4px;
  right: 41px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: ${theme.colors.colorLightGray22};
    path {
      fill: ${theme.colors.colorDarkBlue3};
    }
  }
`;
const NoTriggerHoverArea = styled.div`
  position: fixed;
  top: 0;
  left: 273px;
  height: 67px;
  width: 14px;
  z-index: 1000;
`;
export const StyledGrip = styled.img`
  margin: 0 7px;
  position: absolute;
  left: 270px;
  top: calc(50% - 7px);
`;
const SidebarContainer = styled.div`
  // Larger for hover out area
  width: ${(props) => (props.isOpen && !props.isPinned ? '319px' : '286px')};
  height: 100vh;
  top: 0;
  left: -300px;
  position: absolute;
  z-index: 100001;
  transition: 0.2s;
  transform: ${(props) =>
    props.isOpen
      ? 'translateX(300px)'
      : props.isOnProjectView
      ? 'translateX(27px)'
      : 'none'};
  .sticky-header {
    ${(props) => !props.isOpen && 'box-shadow: none !important;'}
  }
`;

const projectFilterTabsOverride = [
  { value: 'projectsByBoard', label: 'All Projects' }
];

/* ------------------------------------------------------------------------ */

const ProjectsSideBar = ({
  isOnProjectView,
  isOnHomeView,
  navigateBack,
  navigateToHome,
  userSlug,
  openSidebarProjects,
  closeSidebarProjects,
  updateSidebarProjectsPinnedState,
  isOpen,
  navigatingTo,
  isPinned, // 'Pinned' means always open on project view unless user closes
  closeColorPicker
}) => {
  const prevIsOnProjectView = usePrevious(isOnProjectView);
  const prevIsPinned = usePrevious(isPinned);
  const [delayTimeout, setDelayTimeout] = useState(null);

  const handleMouseLeave = () => {
    if (delayTimeout) {
      clearTimeout(delayTimeout);
      setDelayTimeout(null);
    }
    if (
      !isOpen ||
      ((isOnProjectView || navigatingTo.section === Sections.PROJECT) &&
        isPinned)
    ) {
      return;
    }
    closeSidebarProjects();
    closeColorPicker();
  };

  const delayedOpenSidebar = () => {
    setDelayTimeout(setTimeout(() => openSidebarProjects(), 300));
  };

  const renderCustomHeader = useCallback(
    ({ search, clearSearch, handleSearchFocus, isFocused, handleChange }) => {
      const handleBackArrowClick = () => {
        if (isOnProjectView) {
          navigateBack();
        } else {
          navigateToHome({ userSlug });
        }
        closeSidebarProjects();
      };
      const toggleLocked = (e) => {
        e.stopPropagation();
        updateSidebarProjectsPinnedState({ isPinned: !isPinned });
      };
      return (
        <CustomHeaderContainer>
          <LogoHeader>
            {isOnProjectView && (
              <BackArrowContainer onClick={handleBackArrowClick}>
                <SideBarBackArrowIcon className="sidebar-backarrow-icon" />
              </BackArrowContainer>
            )}
            <LogoContainer
              onClick={
                isOnHomeView ? closeSidebarProjects : handleBackArrowClick
              }
            >
              <img src={MosaicLogo} />
            </LogoContainer>
            {isOnProjectView && (
              <PinContainer onClick={toggleLocked}>
                {isPinned ? <img src={PinnedIcon} /> : <PinIcon />}
              </PinContainer>
            )}
            {isOpen && (
              <SidebarCloseIconContainer onClick={closeSidebarProjects}>
                <SidebarCloseIcon className="sidebar-close-icon" />
              </SidebarCloseIconContainer>
            )}
          </LogoHeader>
          <StyledSearchContainer isActive={isFocused || !!search.length}>
            <StyledSearchInput
              onChange={handleChange}
              name="search"
              value={search}
              placeholder="Search All Projects"
              autoComplete="off"
              onFocus={() => handleSearchFocus(true)}
              onBlur={() => handleSearchFocus(false)}
              className="workload-filter"
            />
            <SearchIcon className="search-icon" />
            {search !== '' || isFocused ? (
              <CloseIconContainer onClick={clearSearch}>
                <NewCloseIcon />
              </CloseIconContainer>
            ) : (
              ''
            )}
          </StyledSearchContainer>
        </CustomHeaderContainer>
      );
    },
    [
      closeSidebarProjects,
      isOnHomeView,
      isOnProjectView,
      isOpen,
      isPinned,
      navigateBack,
      navigateToHome,
      updateSidebarProjectsPinnedState,
      userSlug
    ]
  );

  // If pinned, open the sidebar on navigation to project view
  useEffect(() => {
    if (
      ((isOnProjectView && !prevIsOnProjectView) ||
        isPinned !== prevIsPinned) &&
      isPinned &&
      isOnProjectView &&
      !isOpen
    ) {
      openSidebarProjects();
    }
  }, [
    isOnProjectView,
    isOpen,
    isPinned,
    openSidebarProjects,
    prevIsOnProjectView,
    prevIsPinned
  ]);

  return (
    <SidebarContainer
      className="my-projects-sidebar-container"
      onMouseLeave={handleMouseLeave}
      isOpen={isOpen}
      isPinned={isPinned}
      isOnProjectView={isOnProjectView}
    >
      <FilterContainer isOnWorkloadView>
        <StyledSidebar
          isOnProjectView={isOnProjectView}
          onMouseEnter={
            isOnProjectView && !isOpen ? delayedOpenSidebar : undefined
          }
          hideContent={isOnProjectView && !isOpen}
        >
          <div className="project-filter-container">
            <ProjectFilter
              activeFilter={newFilter} // filter isn't actually used for the sidebar, but VirtualFilter requires it
              projectFilterTabsOverride={projectFilterTabsOverride}
              customProjectRowComponent={ProjectRow}
              customBoardRowComponent={BoardRow}
              innerHeightAdjustment={105}
              showSubStickyHeader
              hideFilterTabs
              renderCustomHeader={renderCustomHeader}
              listWidth={286}
              enableDrag
              isProjectsSidebar
              filterListId="projects-sidebar"
            />
          </div>
          {isOnProjectView && !isOpen && <StyledGrip src={GripIcon} />}
        </StyledSidebar>
        {isOnProjectView && !isOpen && (
          <NoTriggerHoverArea onMouseEnter={(e) => e.stopPropagation()} />
        )}
      </FilterContainer>
    </SidebarContainer>
  );
};

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  auth: getAuth(state),
  home: state.home,
  me: getMe(state),
  myUnstarredProjects: getMyUnstarredProjects(state),
  myStarredProjects: getMyStarredProjects(state),
  myProjectsLength: rawGetMyProjects(state).length,
  allProjects: getSidebarProjects(state),
  isMovingProject: state.home.isMovingProject,
  openSidebar: state.home.openSidebar,
  search: getSearch(state),
  projectSearchResults: getProjectSearch(state),
  searchText: getSearchText(state),
  searchWords: getSearchWords(state),
  isOnProjectDetail: getOnProjectDetail(state),
  isOnHomeView: getOnHomeView(state),
  isOnBoardView: getOnBoardView(state),
  navigationHistory: getNavigationHistory(state),
  isMyProjectsFullyLoaded: getIsMyProjectsFullyLoaded(state),
  allNotMyProjectIds: getAllNotMyProjectIds(state),
  isOnProjectView: getIsOnProjectView(state),
  isOpen: getSidebarProjectsOpen(state),
  isPinned: getUserSidebarProjectsIsPinned(state),
  userSlug: getUserSlug(state),
  navigatingTo: getNavigatingTo(state)
});

const mapDispatchToProps = {
  setHomeOrigin,
  fetchMyProjects,
  fetchAllProjects,
  updateMyProject,
  handleProjectItemState,
  saveSearchState,
  addMemberToProject,
  toggleSidebarSearch,
  toggleSidebarProjects,
  navigateToProject,
  navigateBack,
  openSidebarProjects,
  closeSidebarProjects,
  updateSidebarProjectsPinnedState,
  navigateToHome,
  closeColorPicker
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectsSideBar)
);
