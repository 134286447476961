import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import { CommentsState, parentEntitySerializeId } from 'reducers/comments';
import moment from 'appUtils/momentConfig';
import { EntityType } from 'models/entity';

const getCommentsState: Selector<RootState, CommentsState> = (state) =>
  state.comments;

const getCommentsHash = createSelector(
  getCommentsState,
  (state) => state.commentsHash
);

const getCommentCountsHash = createSelector(
  getCommentsState,
  (state) => state.commentCountsHash
);

const getOwnParentEntityType = (
  state,
  ownProps: {
    parentEntityType: EntityType;
  }
) => ownProps.parentEntityType;

const getOwnParentEntityId = (
  state,
  ownProps: {
    parentEntityId: number;
  }
) => ownProps.parentEntityId;

export const makeGetCommentsHashByParentEntity = () =>
  createSelector(
    getCommentsHash,
    getOwnParentEntityType,
    getOwnParentEntityId,
    (commentHash, parentEntityType, parentEntityId) => {
      return Object.values(commentHash).filter(
        (item) =>
          item.parent_entity_type === parentEntityType &&
          item.parent_entity_id === parentEntityId
      );
    }
  );

export const makeSortedCommentsByParentEntity = () =>
  createSelector(makeGetCommentsHashByParentEntity(), (commentsHash) =>
    [...Object.values(commentsHash)].sort((a, b) => {
      return -moment(a.created_at).diff(moment(b.created_at));
    })
  );

export const makeGetCommentCountsByParentEntity = () =>
  createSelector(
    getCommentCountsHash,
    getOwnParentEntityType,
    getOwnParentEntityId,
    (commentCountsHash, parentEntityType, parentEntityId) => {
      const parentEntitySerializedId = parentEntitySerializeId({
        parentEntityId,
        parentEntityType
      });
      return commentCountsHash[parentEntitySerializedId];
    }
  );
