'use strict';

exports.__esModule = true;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _createReactClass = require('create-react-class');

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _CalendarHeader = require('../calendar/CalendarHeader');

var _CalendarHeader2 = _interopRequireDefault(_CalendarHeader);

var _DateTable = require('../date/DateTable');

var _DateTable2 = _interopRequireDefault(_DateTable);

var _DateInput = require('../date/DateInput');

var _DateInput2 = _interopRequireDefault(_DateInput);

var _index = require('../util/index');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var CalendarPart = (0, _createReactClass2['default'])({
  displayName: 'CalendarPart',

  propTypes: {
    prefixCls: _propTypes2['default'].string,
    value: _propTypes2['default'].any,
    hoverValue: _propTypes2['default'].any,
    selectedValue: _propTypes2['default'].any,
    direction: _propTypes2['default'].any,
    locale: _propTypes2['default'].any,
    showDateInput: _propTypes2['default'].bool,
    showTimePicker: _propTypes2['default'].bool,
    format: _propTypes2['default'].any,
    placeholder: _propTypes2['default'].any,
    disabledDate: _propTypes2['default'].any,
    timePicker: _propTypes2['default'].any,
    disabledTime: _propTypes2['default'].any,
    onInputSelect: _propTypes2['default'].func,
    timePickerDisabledTime: _propTypes2['default'].object,
    enableNext: _propTypes2['default'].any,
    enablePrev: _propTypes2['default'].any
  },
  render: function render() {
    var props = this.props;
    var prefixCls = props.prefixCls,
      value = props.value,
      hoverValue = props.hoverValue,
      selectedValue = props.selectedValue,
      mode = props.mode,
      direction = props.direction,
      locale = props.locale,
      format = props.format,
      placeholder = props.placeholder,
      disabledDate = props.disabledDate,
      timePicker = props.timePicker,
      disabledTime = props.disabledTime,
      timePickerDisabledTime = props.timePickerDisabledTime,
      showTimePicker = props.showTimePicker,
      onInputSelect = props.onInputSelect,
      enablePrev = props.enablePrev,
      enableNext = props.enableNext;

    var shouldShowTimePicker = showTimePicker && timePicker;
    var disabledTimeConfig =
      shouldShowTimePicker && disabledTime
        ? (0, _index.getTimeConfig)(selectedValue, disabledTime)
        : null;
    var rangeClassName = prefixCls + '-range';
    var newProps = {
      locale: locale,
      value: value,
      prefixCls: prefixCls,
      showTimePicker: showTimePicker
    };
    var index = direction === 'left' ? 0 : 1;
    var timePickerEle =
      shouldShowTimePicker &&
      _react2['default'].cloneElement(
        timePicker,
        (0, _extends3['default'])(
          {
            showHour: true,
            showMinute: true,
            showSecond: true
          },
          timePicker.props,
          disabledTimeConfig,
          timePickerDisabledTime,
          {
            onChange: onInputSelect,
            defaultOpenValue: value,
            value: selectedValue[index]
          }
        )
      );

    var dateInputElement =
      props.showDateInput &&
      _react2['default'].createElement(_DateInput2['default'], {
        format: format,
        locale: locale,
        prefixCls: prefixCls,
        timePicker: timePicker,
        disabledDate: disabledDate,
        placeholder: placeholder,
        disabledTime: disabledTime,
        value: value,
        showClear: false,
        selectedValue: selectedValue[index],
        onChange: onInputSelect
      });

    return _react2['default'].createElement(
      'div',
      {
        className: rangeClassName + '-part ' + rangeClassName + '-' + direction
      },
      dateInputElement,
      _react2['default'].createElement(
        'div',
        { style: { outline: 'none' } },
        _react2['default'].createElement(
          _CalendarHeader2['default'],
          (0, _extends3['default'])({}, newProps, {
            mode: mode,
            enableNext: enableNext,
            enablePrev: enablePrev,
            onValueChange: props.onValueChange,
            onPanelChange: props.onPanelChange,
            disabledMonth: props.disabledMonth
          })
        ),
        showTimePicker
          ? _react2['default'].createElement(
              'div',
              { className: prefixCls + '-time-picker' },
              _react2['default'].createElement(
                'div',
                { className: prefixCls + '-time-picker-panel' },
                timePickerEle
              )
            )
          : null,
        _react2['default'].createElement(
          'div',
          { className: prefixCls + '-body' },
          _react2['default'].createElement(
            _DateTable2['default'],
            (0, _extends3['default'])({}, newProps, {
              hoverValue: hoverValue,
              selectedValue: selectedValue,
              dateRender: props.dateRender,
              onSelect: props.onSelect,
              onDayHover: props.onDayHover,
              disabledDate: disabledDate,
              showWeekNumber: props.showWeekNumber
            })
          )
        )
      )
    );
  }
});

exports['default'] = CalendarPart;
module.exports = exports['default'];
