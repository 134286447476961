import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  margin-top: 75px;
`;

const TimesheetColumnHeader = (props) => (
  <HeaderContainer>
    {props.column.id === 'activity' ? 'Work Category' : props.column.id}
  </HeaderContainer>
);

export default TimesheetColumnHeader;
