// Views/routes
export const Sections = {
  HOME: 'HOME',
  TEAMS: 'TEAMS',
  HOME_PLANNER: 'HOME_PLANNER',
  TEAM_PROJECT_PLANNER: 'TEAM_PROJECT_PLANNER',
  TEAM_TASK_PLANNER: 'TEAM_TASK_PLANNER',
  PROFILE_TASKS: 'PROFILE_TASKS',
  TIMESHEET: 'TIMESHEET',
  UPDATES: 'UPDATES',
  PLANNER: 'PLANNER',
  WORKLOAD: 'WORKLOAD',
  PLANNER_SPLIT_SCREEN: 'PLANNER_SPLIT_SCREEN',
  WORKLOAD_SPLIT_SCREEN: 'WORKLOAD_SPLIT_SCREEN',
  SCHEDULE: 'SCHEDULE',
  MEMBERS: 'MEMBERS',
  PROJECT: 'PROJECT',
  SETTINGS: 'SETTINGS',
  TEAM_SETTINGS: 'TEAM_SETTINGS',
  REPORTS: 'REPORTS',
  DASHBOARD: 'DASHBOARD',
  INTEGRATIONS: 'INTEGRATIONS'
};
export const Views = {
  STATUS: 'STATUS',
  TIMELINE: 'TIMELINE',
  TASKS: 'TASKS',
  NOTES: 'NOTES',
  PLANNER: 'PLANNER',
  WORKLOAD: 'WORKLOAD',
  SCHEDULE: 'SCHEDULE',
  BUDGET: 'BUDGET',
  MEMBER: 'MEMBER',
  MEMBERS: 'MEMBERS',
  KANBAN: 'KANBAN',
  MEETINGS: 'MEETINGS',
  SETTINGS: 'SETTINGS',
  PROJECTS: 'PROJECTS',
  TIMESHEET: 'TIMESHEET',
  PROJECT_PLANNER: 'PROJECT-PLANNER',
  TASK_PLANNER: 'TASK-PLANNER',
  TIME: 'TIME',
  INTEGRATIONS: 'INTEGRATIONS',
  CAPACITY: 'CAPACITY',
  DEMAND: 'DEMAND',
  STANDARDS: 'STANDARDS',
  PERMISSIONS: 'PERMISSIONS',
  BUDGET_SETTINGS: 'BUDGET_SETTINGS'
};
export const Modals = {
  TASK: 'TASK',
  SETTINGS: 'SETTINGS',
  BUDGET: 'BUDGET',
  REPORT: 'REPORT',
  MEMBER: 'MEMBER',
  UNPLANNED: 'UNPLANNED',
  SCOPE: 'SCOPE'
};

const HOME = '/:userSlug/home/:homeViewType';
const TEAMS = '/:teamSlug/boards/:boardSlug/:boardId/:viewType';
// const HOME_PLANNER =
// const TEAM_PROJECT_PLANNER =
// const TEAM_TASK_PLANNER =
// const PROFILE_TASKS =
// const TIMESHEET =
const PLANNER = '/:teamSlug/planner/:plannerViewType';
const UPDATES = '/:userSlug/activity-feed';
const WORKLOAD = '/:teamSlug/workload/members';
const PLANNER_SPLIT_SCREEN =
  '/:teamSlug/planner/workplans/split/:splitScreenProjectId?';
const WORKLOAD_SPLIT_SCREEN =
  '/:teamSlug/workload/members/split/:splitScreenType?/:splitScreenAccountId?';

const WORKLOAD_PHASE_SPLIT_SCREEN =
  '/:teamSlug/workload/:workloadViewType/split/phase/:splitScreenAccountId';
const SCHEDULE = '/:teamSlug/schedule';
const MEMBERS = '/:teamSlug/members/:membersViewType/:teamMemberId?/views';
const PROJECT = '/:teamSlug/project/:projectSlug/:projectId/:viewType';
const SETTINGS = '/settings';
const TEAM_SETTINGS = '/:teamSlug/settings/:viewType/:tab?';
const INTEGRATIONS =
  '/:teamSlug/settings/integrations/:integrationId?/:integrationTab?';
const DASHBOARD = '/:teamSlug/dashboard/:dashboardId?';
const REPORTS = '/:teamSlug/reports/:viewType?/:reportViewType?';

export const SectionRoutes = {
  HOME,
  TEAMS,
  // HOME_PLANNER,
  // TEAM_PROJECT_PLANNER,
  // TEAM_TASK_PLANNER,
  // PROFILE_TASKS,
  // TIMESHEET,
  PLANNER,
  UPDATES,
  WORKLOAD,
  PLANNER_SPLIT_SCREEN,
  WORKLOAD_SPLIT_SCREEN,
  WORKLOAD_PHASE_SPLIT_SCREEN,
  SCHEDULE,
  MEMBERS,
  PROJECT,
  SETTINGS,
  TEAM_SETTINGS,
  REPORTS,
  DASHBOARD,
  INTEGRATIONS
};
// const INTEGRATIONS = '/integrations';

export const ViewPaths = {
  INTEGRATIONS: INTEGRATIONS
};
export const ModalRoutes = {
  TASK: 'task/:taskId',
  BUDGET:
    'budgetmodal/:projectId/view/:budgetViewType/:budgetSecondaryViewType?',
  REPORT: 'report/:reportId/:modalReportViewType?',
  MEMBER: 'member/:memberId/view/:memberViewType',
  UNPLANNED: 'unplanned/:unplannedViewType/:unplannedSubViewType?',
  SCOPE: 'scope/:scopeId'
};

export const VIEW_TYPE = {
  HOME: 'homeViewType',
  PROJECT: 'projectViewType',
  TEAM_SETTINGS: 'settingsViewType',
  TEAMS: 'viewType', // board view type
  MEMBERS: 'memberViewType'
};

export const HOME_VIEW_TYPE = {
  MY_TASKS: 'tasks',
  PLANNER: 'planner',
  TODAY: 'today',
  ASSIGNED_TO_ME: 'assigned-to-me',
  ASSIGNED_BY_ME: 'assigned-by-me',
  COMPLETED: 'completed',
  TIME: 'time',
  NOTIFICATIONS: 'notifications',
  WORK_PLANS: 'workplans',
  DASHBOARD: 'dashboard',
  CHECK_IN: 'check-in',
  CALENDAR: 'calendar',
  PROJECTS: 'projects'
};

export const viewTypesHash = {
  [Sections.HOME]: {
    [HOME_VIEW_TYPE.MY_TASKS]: HOME_VIEW_TYPE.MY_TASKS,
    [HOME_VIEW_TYPE.PLANNER]: HOME_VIEW_TYPE.PLANNER,
    [HOME_VIEW_TYPE.TODAY]: HOME_VIEW_TYPE.TODAY,
    [HOME_VIEW_TYPE.ASSIGNED_BY_ME]: HOME_VIEW_TYPE.ASSIGNED_BY_ME,
    [HOME_VIEW_TYPE.COMPLETED]: HOME_VIEW_TYPE.COMPLETED,
    [HOME_VIEW_TYPE.TIME]: HOME_VIEW_TYPE.TIME,
    [HOME_VIEW_TYPE.NOTIFICATIONS]: HOME_VIEW_TYPE.NOTIFICATIONS,
    [HOME_VIEW_TYPE.WORK_PLANS]: HOME_VIEW_TYPE.WORK_PLANS,
    [HOME_VIEW_TYPE.DASHBOARD]: HOME_VIEW_TYPE.DASHBOARD,
    [HOME_VIEW_TYPE.CHECK_IN]: HOME_VIEW_TYPE.CHECK_IN,
    [HOME_VIEW_TYPE.CALENDAR]: HOME_VIEW_TYPE.CALENDAR,
    [HOME_VIEW_TYPE.PROJECTS]: HOME_VIEW_TYPE.PROJECTS
  }
};

export const defaultViewTypes = {
  [Sections.HOME]: HOME_VIEW_TYPE.DASHBOARD
};

export const sectionsWithLastLocation = {
  [Sections.HOME]: true
};

export const lastLocationUpdateTimeFormat = 'YYYY-MM-DD HH:mm';

export const MEMBER_VIEW_TYPE = {
  OVERVIEW: 'overview',
  TASKS: 'tasks',
  ACTIVITY: 'activity',
  SCHEDULE: 'schedule',
  WORK_PLANS: 'workplans',
  DUE_DATES: 'calendar',
  PROJECTS: 'projects',
  CHECK_IN: 'check-in',
  PROFILE: 'profile'
};

export const PROJECT_VIEW_TYPE = {
  TASKS: 'tasks',
  NOTES: 'notes',
  SCHEDULE: 'schedule',
  BUDGET: 'budget'
};

export const BOARD_VIEW_TYPE = {
  STATUS: 'status'
};

export const SEARCH_WHITE_LIST = {
  [Sections.HOME]: {
    [HOME_VIEW_TYPE.MY_TASKS]: true,
    [HOME_VIEW_TYPE.PLANNER]: true,
    [HOME_VIEW_TYPE.ASSIGNED_BY_ME]: true,
    [HOME_VIEW_TYPE.COMPLETED]: true
  },
  [Sections.PROJECT]: {
    [PROJECT_VIEW_TYPE.TASKS]: true,
    [PROJECT_VIEW_TYPE.NOTES]: true
  },
  [Sections.TEAMS]: {
    [BOARD_VIEW_TYPE.STATUS]: true // default viewType for teams (board page)
  }
};

export const REPORT_VIEW_TYPE = {
  BUDGET: 'budget',
  WORKLOAD: 'capacity',
  ACTIVITY: 'activity',
  PROFIT: 'profit',
  VARIANCE: 'variance',
  TIMESHEET: 'time',
  TIMESHEET_STATUS: 'timesheetstatus',
  UTILIZATION: 'utilization',
  BUDGET_VARIANCE: 'budgetvariance',
  SCHEDULE_VARIANCE: 'schedulevariance'
};

export const HOME_VIEW_TYPE_TO_MENU = {
  [HOME_VIEW_TYPE.MY_TASKS]: 'All Tasks',
  [HOME_VIEW_TYPE.PLANNER]: 'Planner'
};

export enum MemberSpaceView {
  members = 'members',
  department = 'department'
}
