import { Groups } from 'TimelinesModule/types/groups';
import { BaseGroup, GroupId } from 'TimelinesModule/types/baseGroup';
import { Items } from 'TimelinesModule/types/items';
import { BufferedTimeRange } from 'TimelinesModule/types/timelineView';
import { UtilizationBreakdownsByDate } from 'UtilizationModule/types';
import { TIMELINE_GROUP_TYPES } from '../constants';
import { ReactCalendarTimelineProps } from 'react-calendar-timeline';
import { DateISOString } from 'models/date';
import { WorkPlanId } from 'views/projectPlanner/WorkplanModal/models/workPlan';

export type BaseRowRendererProps<
  Group extends BaseGroup<TIMELINE_GROUP_TYPES>
> = Parameters<
  NonNullable<ReactCalendarTimelineProps<Items, Group>['rowRenderer']>
>[0] &
  BufferedTimeRange;

export type RowRenderer<
  Group extends BaseGroup<TIMELINE_GROUP_TYPES> = Groups,
  ExtraProps extends Record<string, unknown> = Record<never, never>
> = (props: BaseRowRendererProps<Group> & ExtraProps) => JSX.Element | null;

export enum HoursDisplayType {
  TotalHours,
  AvailableHours,
  PercentageOfCapacity
}

export type UtilizationBreakdownsByGroup = Record<
  GroupId,
  UtilizationBreakdownsByDate
>;

export enum BucketType {
  Availability,
  FullPTO,
  Holiday,
  Placeholder
}

export type WorkFromHomesByGroup = Record<GroupId, WorkFromHomesByDate>;

export type WorkFromHomesByDate = Record<
  DateISOString,
  {
    id: WorkPlanId;
  }
>;
