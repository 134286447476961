import styled from 'styled-components';
import theme from 'theme';

import MembersIcon from 'icons/MembersIcon';
import LockIcon from 'icons/LockIcon';
import CapacityIcon from 'icons/CapacityIcon';

export const MemberProfile = () => (
  <MenuListItem>
    <IconContainer style={{ marginRight: 9, marginBottom: '12px' }}>
      <MembersIcon
        currentColor={theme.colors.colorMediumGray9}
        height="13px"
        width="13px"
      />
    </IconContainer>
    Member Profile
  </MenuListItem>
);

export const WorkSchedule = () => (
  <MenuListItem>
    <IconContainer style={{ marginRight: 9, marginBottom: '10px' }}>
      <CapacityIcon />
    </IconContainer>
    Edit Member Capacity
    <IconContainer style={{ marginLeft: 9, marginRight: 9, marginBottom: 11 }}>
      <LockIcon fill={theme.colors.colorRoyalBlue} width="13" height="13" />
    </IconContainer>
  </MenuListItem>
);

const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  min-width: 190px;
  color: ${theme.colors.colorSemiDarkGray3};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  padding-left: 7px;
  line-height: 34px;
  width: 100%;

  :hover {
    background-color: ${theme.colors.colorTranslucentGray4};
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  margin: 7px;
`;
