import { fork, takeLatest, all } from 'redux-saga/effects';
import { fetchCsvImportStatus } from 'CsvImportsModule/actionCreators';
import { fetchCsvImportStatusWorker } from './importLog';

export default function* csvImportSaga() {
  const sagas = [
    fork(takeLatest, fetchCsvImportStatus, fetchCsvImportStatusWorker)
  ];
  yield all(sagas);
}
