import moment from 'moment';
import omit from 'lodash/omit';
import { makeIdHash } from './index';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';

export const keyifyTimesheetDate = (date) => {
  if (!date || date === 'unscheduled') {
    return 'unscheduled';
  }

  const dateKey = moment(date);
  return dateKey.isValid() ? dateKey.format('MM/DD/YYYY') : 'unscheduled';
};
const keyifyUtilizationDate = (date) => {
  if (!date || date === 'unscheduled') {
    return 'unscheduled';
  }

  const dateKey = moment(date);
  return dateKey.isValid() ? dateKey.format('YYYY-MM-DD') : 'unscheduled';
};

const timesheetOffset = [0, 1, 2, 3, 4, 5, 6];

const makeDayOffsetter = (date) => (num) =>
  keyifyTimesheetDate(date.clone().add(num, 'days'));
const makePlannerDayOffsetter = (date) => (num) =>
  keyifyUtilizationDate(date.clone().add(num, 'days'));

export const createTimesheetWeekKeys = (startDate) =>
  timesheetOffset.map(makeDayOffsetter(startDate));

export const createPlannerWeekKeys = (startDate) =>
  timesheetOffset.map(makePlannerDayOffsetter(startDate));

export const omitDaysFromActivities = (dates, activityIds, activitiesObj) => {
  const activityIdsHash = makeIdHash(activityIds);

  return Object.keys(activitiesObj).reduce((acc, activityId) => {
    if (activityIdsHash[activityId]) {
      acc[activityId] = omit(activitiesObj[activityId], dates);
    } else {
      acc[activityId] = activitiesObj[activityId];
    }
    return acc;
  }, {});
};

export const makeGetTimesheetIdsToDelete = (dateKeys) => (timesheets) =>
  dateKeys
    .map((dateKey) => timesheets[dateKey])
    .filter((timesheet) => timesheet !== undefined)
    .map((timesheet) => timesheet.id);

const isDate = (date) => (item) => item.date === date;
export const isStatus = (status) => (item) => item.status === status;
export const isNotStatus = (status) => (item) => item.status !== status;
export const checkTimesheetsForSubmitted = (timesheets) =>
  Object.values(timesheets).some(isStatus(TIMESHEET_STATUSES.SUBMITTED));
export const checkTimesheetsForNotSubmitted = (timesheets) =>
  Object.values(timesheets).some(isStatus(TIMESHEET_STATUSES.NOT_SUBMITTED));
export const checkTimesheetsForApproved = (timesheets) =>
  Object.values(timesheets).some(isStatus(TIMESHEET_STATUSES.APPROVED));
export const checkTimesheetsForAllNotSubmittedOrRejected = (timesheets) =>
  Object.values(timesheets).every((timesheet) =>
    [TIMESHEET_STATUSES.NOT_SUBMITTED, TIMESHEET_STATUSES.REJECTED].includes(
      timesheet.status
    )
  );

export const makeCheckDateForSubmitted = (date) => (allTimesheets) =>
  checkTimesheetsForSubmitted(allTimesheets.filter(isDate(date)));
export const makeCheckDateForNotSubmitted = (date) => (allTimesheets) =>
  checkTimesheetsForNotSubmitted(allTimesheets.filter(isDate(date)));
export const makeCheckDateForApproved = (date) => (allTimesheets) =>
  checkTimesheetsForApproved(allTimesheets.filter(isDate(date)));
export const makeCheckDateForAllNotSubmittedOrRejected =
  (date) => (allTimesheets) =>
    checkTimesheetsForAllNotSubmittedOrRejected(
      allTimesheets.filter(isDate(date))
    );

export const transformCreateRowData = ({
  activity_id,
  description_id,
  project_id,
  phase_id,
  account_id,
  description_title,
  date,
  ...rest
}) => ({
  activityId: activity_id,
  descriptionId: description_id,
  projectId: project_id,
  phaseId: phase_id,
  accountId: account_id,
  title: description_title,
  day: date || moment().startOf('day').format('MM/DD/YYYY'),
  ...rest
});
