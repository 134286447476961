import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { requireAuthentication } from 'appUtils/authentication';
import WorkloadPlannerContainer from 'views/Schedule/ScheduleContainer';
import ScheduleNavigationTracker from 'views/Navigation/ScheduleNavigationTracker';
import withRouterParams from './WithRouterParams';
import BudgetModalRouter from './BudgetModalRouter';
import TaskModalRouter from './TaskModalRouter';
import MemberModalRouter from './MemberModalRouter';
import ScopeModalRouter from './ScopeModalRouter';
import { ModalRoutes } from 'appConstants/navigation';

const UnplannedModal = lazy(() => import('views/unplanned/UnplannedModal'));

const AuthedWorkloadPlannerContainer = withRouter(
  requireAuthentication(WorkloadPlannerContainer)
);
class ScheduleRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    return (
      <>
        <AuthedWorkloadPlannerContainer />
        <ScheduleNavigationTracker />
        <Switch>
          <Route
            path={[
              `${match.url}/budgetmodal/:projectId/view/:budgetViewType/:budgetSecondaryViewType`,
              `${match.url}/budgetmodal/:projectId/view/:budgetViewType`
            ]}
            component={BudgetModalRouter}
          />

          <Route
            path={`${match.url}/task/:taskId`}
            component={TaskModalRouter}
          />
          <Route
            path={`${match.url}/member/:memberId/view/:memberViewType`}
            component={MemberModalRouter}
          />
          <Route
            path={`${match.url}/scope/:scopeId`}
            component={ScopeModalRouter}
          />
          <Route path={`${match.url}/${ModalRoutes.UNPLANNED}`}>
            <Suspense fallback={null}>
              <UnplannedModal />
            </Suspense>
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleRouter))
);
