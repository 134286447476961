import { fork, takeEvery, takeLatest, all } from 'redux-saga/effects';

import * as constants from '../constants';
import { fetchProfitReportWorker } from './workers';

export default function* profitReportSaga() {
  const capacitySagas = [
    yield fork(
      takeLatest,
      constants.FETCH_PROFIT_REPORT.TRIGGER,
      fetchProfitReportWorker
    )
  ];
  yield all(capacitySagas);
}
