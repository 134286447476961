import 'isomorphic-fetch';
import qs from 'qs';
import { callApi } from 'appUtils/api';

export const fetchTeamCapacity = (team_id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`team_capacity_for_team/${team_id}`, token, fetchInit);
};

export const updateTeamCapacity = (token, id, capacity) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      capacity
    })
  };
  return callApi(`team_capacity/${id}`, token, fetchInit);
};

export const fetchCapacities = (team_id, token, account_ids) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ team_id, account_ids })
  };

  return callApi('account_team_capacities/post_index', token, fetchInit);
};

export const updateCapacity = (
  token,
  id,
  add_one_off_capacities,
  remove_one_off_capacities,
  capacity
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      add_one_off_capacities,
      remove_one_off_capacities,
      capacity
    })
  };
  return callApi(`account_team_capacity/${id}`, token, fetchInit);
};

export const fetchHolidays = (capacity_id, token, start_date, end_date) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    capacity_id
  };
  if (start_date && end_date) {
    queryParams.start_date = start_date;
    queryParams.end_date = end_date;
  } // api has potential to query by date range

  return callApi(`holidays/?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const createHoliday = (
  token,
  { capacity_id, start_date, end_date, all_day = true, name, holiday_group_id }
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      capacity_id,
      start_date,
      end_date,
      all_day,
      name,
      holiday_group_id
    })
  };
  return callApi(`holidays`, token, fetchInit);
};

export const updateHoliday = (
  token,
  { capacity_id, start_date, end_date, all_day = true, name, id },
  add_holiday_group_ids,
  remove_holiday_group_ids
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      capacity_id,
      start_date,
      end_date,
      all_day,
      name,
      add_holiday_group_ids,
      remove_holiday_group_ids
    })
  };
  return callApi(`holidays/${id}`, token, fetchInit);
};

export const deleteHoliday = (token, id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`holidays/${id}`, token, fetchInit);
};

export const fetchHolidayGroups = (token, teamId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };

  return callApi(
    `holiday_groups?${qs.stringify(queryParams)}`,
    token,
    fetchInit
  );
};

export const createHolidayGroup = (token, name, teamId) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name,
      team_id: teamId
    })
  };

  return callApi(`holiday_group`, token, fetchInit);
};

export const updateHolidayGroup = (
  token,
  id,
  name,
  add_holiday_ids,
  remove_holiday_ids
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name,
      add_holiday_ids,
      remove_holiday_ids
    })
  };

  return callApi(`holiday_group/${id}`, token, fetchInit);
};

export const deleteHolidayGroup = (token, id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`holiday_group/${id}`, token, fetchInit);
};

export const fetchCapacityReport = (params, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `demand_report?${qs.stringify(params, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
