import { useState, useRef } from 'react';
import styled from 'styled-components';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { BaseLocationFieldStyles } from './styles';
import QBDownArrow from 'icons/QBDownArrow';
import { countryNames } from 'appConstants/locations';
import { filterItemWithWhiteSpace } from 'appUtils/search';

const copy = {
  searchPlaceholder: 'Type country name or select below'
};

interface CountryDropdownProps {
  selectedCountry: string;
  hasError: boolean;
  onSelect: (country: string) => void;
}

interface CountryDropdownOption {
  label: string;
}

const countryNamesOptions: CountryDropdownOption[] = countryNames.map(
  (country) => ({ label: country })
);

export const CountryDropdown = ({
  selectedCountry,
  hasError,
  onSelect
}: CountryDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (_, { item }: { item: CountryDropdownOption }) => {
    onSelect(item.label);
    setIsOpen(false);
  };

  const handleFilterCountry = (item: string, searchWords: string[]) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['label']
    });

  const renderItem = ({
    item,
    selectCallback
  }: {
    item: CountryDropdownOption;
    selectCallback: (item: string) => void;
  }) => {
    return (
      <ItemContainer
        key={item.label}
        onClick={() => selectCallback(item.label)}
      >
        <ItemText>{item.label}</ItemText>
      </ItemContainer>
    );
  };

  return (
    <>
      <Trigger
        $hasError={hasError}
        ref={dropdownRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <TriggerContentContainer>
          <TriggerText>{selectedCountry}</TriggerText>
          <TriggerIcon>
            <QBDownArrow className={undefined} />
          </TriggerIcon>
        </TriggerContentContainer>
      </Trigger>

      {isOpen && (
        <MultiStepFlyout
          items={countryNamesOptions}
          getItemId={(item) => item}
          idKey="id"
          renderItem={renderItem}
          copy={copy}
          noHeader
          hideFooter
          target={dropdownRef}
          editDisabled
          itemHeight={48}
          listWidth={404}
          searchEnabled
          itemFilter={handleFilterCountry}
          popoverClassName={'country-dropdown'}
          handleSelect={handleSelect}
        />
      )}
    </>
  );
};

const Trigger = styled.div<{ $hasError: boolean }>`
  ${(props) => BaseLocationFieldStyles({ $hasError: props.$hasError })};
  padding: 10px;
  cursor: pointer;
  height: 35px;
  align-items: center;
  width: 100%;
`;

const TriggerContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TriggerText = styled.div`
  width: 90%;
`;
const TriggerIcon = styled.div``;

const ItemContainer = styled.div`
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;

const ItemText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray2};
`;
