import {
  makeVerification,
  makeAuthorBasedVerification
} from '../makeVerification';

// Create portfolios
export const verifyCreatePortfolio = makeVerification('create_public_boards');

// Archive portfolios
export const verifyArchivePortfolio = makeVerification(
  'create_archived_boards'
);

// Delete portfolios
export const verifyDeletePortfolio = makeVerification('delete_boards');

// Edit portfolio
export const verifyEditPortfolio = makeVerification('edit_public_boards');

export const verifyMakePortfolioPrivate = makeVerification(
  'create_private_boards'
);

// Add/Remove members in portfolios - replaces 'create_public_board_memberships'
export const verifyCreatePublicPortfolioMembership = makeVerification(
  'create_public_board_memberships'
);
export const verifyCreatePrivatePortfolioMembership = makeVerification(
  'create_private_board_memberships'
);
export const verifyDeletePublicPortfolioMembership = makeVerification(
  'delete_public_board_memberships'
);
export const verifyDeletePrivatePortfolioMembership = makeVerification(
  'delete_private_board_memberships'
);
export const verifyEditPublicPortfolioMembership = makeVerification(
  'edit_public_board_memberships'
);
export const verifyEditPrivatePortfolioMembership = makeVerification(
  'edit_private_board_memberships'
);
// Set Portfolio Managers - replaces 'edit_public_board_memberships
export const verifyCreatePortfolioManager = makeVerification(
  'create_board_managers'
);

// Delete project in portfolio duplicates with 'delete_projects'
export const verifyDeletePortfolioProject = makeVerification('delete_projects');

// Archive project in portfolio duplicates with 'archive_projects'
export const verifyArchivePortfolioProject =
  makeVerification('archive_projects');
