import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SidebarCloseIcon = ({
  className,
  color = '#A4A4A4',
  fill = 'none',
  width = 16,
  height = 16,
  style,
  onClick
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 16 16"
    style={style}
    onClick={onClick}
  >
    <line
      x1="14.5603"
      x2="14.5603"
      y2="15.2981"
      stroke={color}
      strokeWidth="1.29"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17798 3.60773L5.10216 2.54932L-8.15392e-05 7.56899L5.10216 12.5887L6.17798 11.5303L2.15156 7.56899L6.17798 3.60773Z"
      fill={color}
    />
    <line
      y1="-0.823741"
      x2="8.23741"
      y2="-0.823741"
      transform="matrix(-1 0 0 1 10.0911 8.59863)"
      stroke={color}
      strokeWidth="1.64748"
    />
  </SvgIcon>
);

export default SidebarCloseIcon;
