import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import {
  HOME_VIEW_TYPE,
  HOME_VIEW_TYPE_TO_MENU
} from 'appConstants/navigation';
import { navigateToHome } from 'actionCreators';
import { getUserSlug } from 'selectors';

const StyledHomeTopMenuContainer = styled.div`
  display: flex;
  width: fit-content;
  position: absolute;
  top: 60px;
  margin-left: 60px;
`;

const StyledHomeMenu = styled.div`
  display: flex;
  position: relative;
  left: -15px;
`;
const StyledHomeMenuItem = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${({ isActive }) =>
    isActive
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorCalendarGray};
  height: 34px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  cursor: pointer;
  z-index: 100;

  ${(props) =>
    props.isActive &&
    `
    text-decoration: 2px solid ${theme.colors.colorRoyalBlue} underline;
    text-underline-position: under;
    text-underline-offset: 3px;
  `}
  padding: 5px 15px;
`;

const MENU_LIST = [HOME_VIEW_TYPE.MY_TASKS, HOME_VIEW_TYPE.PLANNER];

const HomeTopMenu = ({ homeViewType }) => {
  const dispatch = useDispatch();
  const userSlug = useSelector(getUserSlug);

  const handleNavigateToHomeSection = useCallback(
    (homeViewType) => {
      dispatch(
        navigateToHome({
          homeViewType,
          useLastLocation: false,
          userSlug
        })
      );
    },
    [dispatch, userSlug]
  );

  const renderedMenu = useMemo(
    () => (
      <StyledHomeMenu>
        {MENU_LIST.map((menu) => (
          <StyledHomeMenuItem
            key={menu}
            isActive={menu === homeViewType}
            onClick={() => handleNavigateToHomeSection(menu)}
          >
            {HOME_VIEW_TYPE_TO_MENU[menu]}
          </StyledHomeMenuItem>
        ))}
      </StyledHomeMenu>
    ),
    [handleNavigateToHomeSection, homeViewType]
  );
  return (
    <StyledHomeTopMenuContainer>{renderedMenu}</StyledHomeTopMenuContainer>
  );
};

export default HomeTopMenu;
