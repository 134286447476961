import { useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getFlatPhasesAndMilestonesHash } from 'ProjectsModule/phases/selectors';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';
import { getProjectHash } from 'ProjectsModule/selectors';
import { dependencyItemType } from '../constants';
import { DependableItem } from '../types';
import { PhaseId } from 'ProjectsModule/phases/models/phase';
import { ProjectId } from 'ProjectsModule/models/project';

type UsePhaseDependableItems = ({
  projectId,
  phaseId
}: {
  projectId: ProjectId;
  phaseId: PhaseId;
}) => {
  dependableItems: DependableItem[];
};

export const usePhaseDependableItems: UsePhaseDependableItems = ({
  projectId,
  phaseId
}) => {
  const projectHash = useAppSelector(getProjectHash);
  const phasesHash = useAppSelector(getFlatPhasesAndMilestonesHash);

  const project = projectHash[projectId];
  const { phases: projectPhases } = project ?? { phases: [] };

  const dependableItems = useMemo(
    () =>
      projectPhases
        .filter((phase) => {
          const phaseHash = phasesHash[phase];
          return phaseHash && !phaseHash.archived && phaseHash.id !== phaseId;
        })
        .map((filteredPhase) => {
          const filteredPhaseHash = phasesHash[
            filteredPhase
          ] as PhaseWithDefault;
          return {
            id: filteredPhaseHash.id,
            name: filteredPhaseHash.name,
            itemType: dependencyItemType.phase,
            startDate: filteredPhaseHash.start_date ?? null,
            endDate: filteredPhaseHash.end_date ?? null
          };
        }),
    [projectPhases, phasesHash, phaseId]
  );

  return {
    dependableItems
  };
};
