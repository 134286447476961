import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';
interface AdditionalParams {
  existingProjectBudgetStatusOptions: string[];
}

export const projectBudgetStatusOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingProjectBudgetStatusOptions } = additionalParams;

  // always return all the possible budget status options
  return existingProjectBudgetStatusOptions;
};
