import React, { useState, useEffect } from 'react';
import { PermissionsOptions } from 'PermissionsModule/components/PermissionsSections/shared/PermissionsOptions';
import { ProjectPermissionEdit } from 'PermissionsModule/components/settingsViews/ProjectTabViews/ProjectPermissionEdit';
import { usePermissionsEdit } from 'PermissionsModule/hooks/usePermissionsEdit';
import { PermissionsTable } from '../PermissionsTable';
import { PERMISSION_SECTION } from 'PermissionsModule/constants';
import { fetchTeamPermissions } from 'PermissionsModule/actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
// Permission sections in the project tab
const PROJECT_PAGE_SECTIONS = [PERMISSION_SECTION.PROJECTS];

export const PermissionsSettingsProjectsTab = () => {
  // Uncomment when we need expand all permissions
  // const allExpanded = useAppSelector(getAllPermissionActionsExpanded);

  const teamId = useAppSelector(getSelectedTeamId);
  const dispatch = useAppDispatch();

  const fetchTeamPermissionsRequestStatusId = `${fetchTeamPermissions.type}-projects-tab`;

  const {
    isEditing,
    setIsEditing,
    makeEditPermission,
    handleSavePermissions,
    handleCancelEdit,
    permissionEdits,
    isSpecificMemberCheckedByPermissionAction,
    isLoading
  } = usePermissionsEdit({ fetchTeamPermissionsRequestStatusId });

  useEffect(() => {
    if (teamId) {
      dispatch(
        fetchTeamPermissions({
          teamId,
          meta: {
            requestStatusId: fetchTeamPermissionsRequestStatusId
          }
        })
      );
    }
  }, [dispatch, fetchTeamPermissionsRequestStatusId, teamId]);
  const { newPermissionSettings } = useFeatureFlags();

  if (!newPermissionSettings) {
    return <></>;
  }

  return (
    <>
      <PermissionsOptions
        isEditing={isEditing}
        toggleEditing={setIsEditing}
        onSave={handleSavePermissions}
        onCancel={handleCancelEdit}
        variant="projects"
      />
      {/* Use read/write view. */}
      {isEditing ? (
        <ProjectPermissionEdit
          makeEditPermission={makeEditPermission}
          unSavedEdits={permissionEdits}
          sectionOrder={PROJECT_PAGE_SECTIONS}
          isSpecificMemberCheckedByPermissionAction={
            isSpecificMemberCheckedByPermissionAction
          }
        />
      ) : (
        <div style={{ height: '80%' }}>
          <PermissionsTable
            variant="projects"
            isLoading={isLoading}
            sectionOrder={PROJECT_PAGE_SECTIONS}
          />
        </div>
      )}
    </>
  );
};
