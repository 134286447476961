import React from 'react';
import styled from 'styled-components';

const Percentage = styled.div`
  padding-right: 18px;
  text-align: right;
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 600;
`;

const PercentageCell = ({ row }) => {
  return <Percentage>{row?.original?.percentCommitted || 0}%</Percentage>;
};

export default PercentageCell;
