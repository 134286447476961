import React from 'react';
import PropTypes from 'prop-types';
import { preventDefault, formatStatusDate } from 'appUtils';
import cn from 'classnames';
import iconClear from 'images/clear-search.svg';
import { Row, Col } from 'reactstrap';

const EditProjectStatusForm = ({
  formSubmit,
  handleChange,
  updateShowItem,
  clearDescription,
  description,
  counter,
  account,
  date,
  setEditTrue,
  classes,
  handleBlur
}) => (
  <Row className="edit-project-status-form">
    <div className="col-wrapper">
      <Col sm={1} className="member-initials">
        <div className="status-account" onClick={preventDefault}>
          <button className="status-member member-initials edit-form">
            {account.initials}
          </button>
        </div>
      </Col>

      <Col sm={10} className="input-col">
        <form
          onSubmit={formSubmit}
          className={cn('status-form-edit', classes)}
          onFocus={setEditTrue}
          onBlur={(e) => handleBlur(e)}
        >
          <input
            type="text"
            value={description}
            name="description"
            onChange={handleChange}
            autoFocus
            className="form-description-edit"
          />
          <div className="status-delete">
            <div className="char-count-edit">{counter}</div>
            <button
              type="button"
              className="status-delete clickable"
              onClick={clearDescription}
            >
              <img
                src={iconClear}
                className="clickable"
                onClick={clearDescription}
              />
            </button>
          </div>

          <div className="status-bottom">
            <span className="original-date">{formatStatusDate(date)}</span>
            <button
              type="button"
              className="status-cancel clickable"
              onClick={updateShowItem}
            >
              Cancel
            </button>
            <button type="submit" className="button-submit clickable">
              Done
            </button>
          </div>
        </form>
      </Col>
    </div>
  </Row>
);

EditProjectStatusForm.propTypes = {
  updateShowItem: PropTypes.func.isRequired,
  clearDescription: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.object.isRequired,
  date: PropTypes.string
};

export default EditProjectStatusForm;
