import * as constants from 'appConstants';
import * as reportsConstants from '../constants';

export const initialState = {};

const time = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    default:
      return state;
  }
};

export default time;
