export enum WorkPlanHourDisplay {
  TotalHrs = 'total_hours',
  AvailableHrs = 'available_hours',
  PercentCapacity = 'percent' // default
}

export enum WorkPlanLock {
  HoursPerDay = 'hours_per_day', // default
  PercentWorkday = 'percentage_of_workday',
  TotalHrs = 'total_hours'
}

export const WorkPlanHourDisplayLabel = {
  [WorkPlanHourDisplay.TotalHrs]: 'Total Hrs',
  [WorkPlanHourDisplay.AvailableHrs]: 'Available Hrs',
  [WorkPlanHourDisplay.PercentCapacity]: '% of Capacity'
};

export const WorkPlanLockLabel = {
  [WorkPlanLock.TotalHrs]: 'Total Hrs',
  [WorkPlanLock.HoursPerDay]: 'Hrs/Day',
  [WorkPlanLock.PercentWorkday]: '% of Workday'
};
