import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { predictWorkdays, handleErrorMessage } from 'actionCreators';
import { getCalendarPrediction, getSelectedTeamId, getMe } from 'selectors';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from 'moment';
import Popover from 'components/Popover';
import {
  ReportsDateContainer,
  StyledDates,
  StyledCalendarIcon
} from 'ReportsModule/components/styles';
import { NavLeft, NavRight, renderMonthElement } from './Nav';
import Input from './Input';
import {
  CalendarContainer,
  InfoPanelContainer,
  FormContainer,
  SetRangeButton,
  DoneButton,
  CancelButton,
  SmallOptionsContainer,
  CustomCheckBoxContainer,
  CustomCheckBox,
  SmallIconContainer,
  WorkplanInputContainer,
  OverCapacityLabel
} from './styles';
import {
  DEPENDENCY_STRINGS,
  DEPENDENCY_TYPES,
  WORK_PLAN_CALENDAR_OPTION_TYPES,
  WORK_PLAN_CALENDAR_MENU_TYPES
} from 'appConstants/workload';
import { fetchUtilizations } from 'UtilizationModule/actionCreators';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { isInTimePicker } from 'appUtils/popoverClicks';
import { isStartDateDependency, isEndDateDependency } from 'appUtils/';
import DependencySetter from './DependencySetter.jsx';
import { getDay } from 'date-fns';
import { GENERIC_ACTION } from 'appConstants';
import WorkplanCalendarSidebar from './WorkplanCalendarSidebar';
import { calculateWeeklyDateRange } from './utils';
import { isValidDateRange } from './validations';
import cn from 'classnames';
import { DATE_DISPLAY_SHORT } from 'appConstants/date';

const noOp = () => {};

const dayRenderer = (day, modifier) => {
  const classNames = [];
  if (
    modifier.has('selected-start') ||
    modifier.has('selected-end') ||
    modifier.has('hovered-offset') ||
    modifier.has('hovered')
  ) {
    classNames.push('circleContainer');
  }

  if (getDay(day) === 6 || getDay(day) === 0) {
    classNames.push('weekend');
  }

  const className = classNames?.reduce(
    (acc, cur, index) => acc + `${index ? ' ' : ''}${cur}`,
    ''
  );

  return <div className={className}>{day.format('D')}</div>;
};

const getTooltipContent = (phaseStartDate, phaseEndDate) => {
  const tooltip = {
    firstLine: 'Dates are outside of Phase Dates',
    secondLine: ''
  };

  const topDiv = `<div>${tooltip.firstLine}</div>`;

  tooltip.secondLine =
    phaseStartDate && phaseEndDate ? `${phaseStartDate} - ${phaseEndDate}` : '';

  const bottomDiv =
    tooltip.secondLine !== ''
      ? `<div
            class="second-line"
          >
            ${tooltip.secondLine}
          </div>`
      : `<div></div>`;

  return `
    <div
    class="date-range-tooltip"
    >
      ${topDiv}
      ${bottomDiv}
    </div>
  `;
};

export const ButtonPanel = ({
  startText,
  endText,
  handleStartInput,
  handleEndInput,
  handleWorkdaysInput,
  handleFormSubmit,
  workDays,
  showWorkdays,
  currentFocus,
  showSetRange,
  onSetRange,
  showInputs,
  isSingleDay,
  labels = {},
  endDisabled,
  endPlaceholder,
  isWorkplanCalendar,
  isPhaseCalendar,
  dependency,
  phaseStartDate,
  phaseEndDate
}) => {
  const tooltipContent = useMemo(
    () => getTooltipContent(phaseStartDate, phaseEndDate),
    [phaseStartDate, phaseEndDate]
  );

  const startAndEndInputs = (
    <>
      <Input
        label={labels.start || 'START'}
        placeholder="Select"
        value={startText !== 'Invalid date' ? startText : ''}
        onChange={handleStartInput}
        onBlur={handleFormSubmit}
        isFocused={currentFocus === START_DATE}
        isWorkplanCalendar={isWorkplanCalendar}
        isPhaseCalendar={isPhaseCalendar}
        hasDependency={
          isStartDateDependency(dependency) ||
          dependency === DEPENDENCY_STRINGS.START_AND_END
        }
        dependency={dependency}
        showWarningIcon={
          (isWorkplanCalendar &&
            moment(startText, 'MM/DD/YYYY').isBefore(
              moment(phaseStartDate, 'MM/DD/YYYY')
            )) ||
          moment(startText, 'MM/DD/YYYY').isAfter(
            moment(phaseEndDate, 'MM/DD/YYYY')
          )
        }
        toolTipContent={tooltipContent}
      />
      {(isWorkplanCalendar || isPhaseCalendar) && (
        <div style={{ margin: '5px 0px', marginLeft: '-8px' }}> - </div>
      )}
      <Input
        label={labels.end || 'END'}
        placeholder={
          endPlaceholder ||
          (startText !== '' && startText !== 'Invalid date'
            ? startText
            : 'Select')
        }
        value={endText !== 'Invalid date' ? endText : ''}
        onChange={handleEndInput}
        onBlur={handleFormSubmit}
        isFocused={currentFocus === END_DATE}
        styles={{ visibility: isSingleDay ? 'hidden' : 'visible' }}
        isWorkplanCalendar={isWorkplanCalendar}
        isPhaseCalendar={isPhaseCalendar}
        hasDependency={
          isEndDateDependency(dependency) ||
          dependency === DEPENDENCY_STRINGS.START_AND_END
        }
        dependency={dependency}
        disabled={endDisabled}
        showWarningIcon={
          (isWorkplanCalendar &&
            moment(endText, 'MM/DD/YYYY').isAfter(
              moment(phaseEndDate, 'MM/DD/YYYY')
            )) ||
          moment(endText, 'MM/DD/YYYY').isBefore(
            moment(phaseStartDate, 'MM/DD/YYYY')
          )
        }
        toolTipContent={tooltipContent}
      />
    </>
  );
  return (
    <InfoPanelContainer>
      {showInputs && (
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <div
            style={{
              transform: 'translateY(-10px)',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: isPhaseCalendar
                ? '10px'
                : isWorkplanCalendar
                ? '30px'
                : ''
            }}
          >
            {isWorkplanCalendar || isPhaseCalendar ? (
              <WorkplanInputContainer dependency={dependency}>
                {startAndEndInputs}
              </WorkplanInputContainer>
            ) : (
              startAndEndInputs
            )}

            {showWorkdays && (
              <Input
                style={{ width: '75px' }}
                label="WORKDAYS"
                value={workDays}
                onChange={handleWorkdaysInput}
                isWorkdays
                disabled={dependency === DEPENDENCY_STRINGS.START_AND_END}
                isWorkplanCalendar={isWorkplanCalendar}
                isPhaseCalendar={isPhaseCalendar}
                dependency={dependency}
                hasDependency={dependency === DEPENDENCY_STRINGS.START_AND_END}
              />
            )}
          </div>
          <button type="submit" style={{ visibility: 'hidden' }} />
        </FormContainer>
      )}
      {showSetRange && (
        <SetRangeButton onClick={onSetRange}>Set Range</SetRangeButton>
      )}
    </InfoPanelContainer>
  );
};

const DateRangeCalendar = ({
  itemStartDate,
  itemEndDate,
  itemWorkDays = 0,
  onTimeChange = noOp,
  onSubmit = noOp,
  isWeekly = false,
  isSingleDay = false,
  showWorkdays = false,
  customInput,
  showClear = false,
  onClear,
  onSetRange,
  showSetRange,
  onClose,
  hideInputs = false,
  startOpen = false,
  target = null,
  showInputs,
  labels,
  customLabel,
  shouldRenderFooter = false,
  workplanAllDay,
  workplanUseWeeklyPlanning,
  workplanIncludeWeekends,
  workplanTentative,
  workplanStartTime,
  workplanEndTime,
  workplanDailyHours,
  isWorkplanCalendar,
  isPhaseCalendar,
  onWorkdaysChange = noOp,
  endDateFocus = false,
  disableDependency,
  phaseStartDate,
  phaseEndDate,
  initialDependency = null,
  initialDependencyItem = null,
  initialDependencyItemType = null,
  initialDependencyStepItem = null,
  phaseId,
  isOOO,
  accountId,
  phases,
  accountCapacities = {},
  accountUtilizations = {},
  additionalDailyHours = 0,
  isTaskCalendar,
  item,
  itemType,
  isScopeCalendar,
  isMilestoneCalendar,
  calendarClassNames
}) => {
  // setup State hooks

  // format props
  const momentStart = moment(itemStartDate).isValid()
    ? moment(itemStartDate)
    : null;
  const momentEnd = isSingleDay
    ? null
    : moment(itemEndDate).isValid()
    ? moment(itemEndDate)
    : null;

  // calendar modal open state
  const [isOpen, setIsOpen] = useState(startOpen);

  const dispatch = useDispatch();

  // calendar states
  // used as selected value but is not "saved" until done button is clicked
  const [startDate, setStartDate] = useState(momentStart);
  const [endDate, setEndDate] = useState(momentEnd);
  const [focusedInput, setFocusedInput] = useState(
    endDateFocus ? END_DATE : START_DATE
  );

  // input states
  const [startText, setStartText] = useState(
    momentStart ? momentStart.format(DATE_DISPLAY_SHORT) : ''
  );
  const [endText, setEndText] = useState(
    momentEnd ? momentEnd.format(DATE_DISPLAY_SHORT) : ''
  );
  const [workDays, setWorkDays] = useState(itemWorkDays);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeChanged, setTimechanged] = useState(false);
  const [dependency, setDependency] = useState(null);
  const [dependencyItem, setDependencyItem] = useState(null);
  const [dependencyItemType, setDependencyItemType] = useState(null);
  const [dependencyStepItem, setDependencyStepItem] = useState(null);
  const calendarPrediction = useSelector(getCalendarPrediction);
  const teamId = useSelector(getSelectedTeamId);
  const popoverTarget = useRef();

  const [predictUpdate, setPredictUpdate] = useState(null);
  const [allDay, setAllDay] = useState(false);
  const [includeWeekends, setIncludeWeekends] = useState(false);
  const [isTentative, setIsTentative] = useState(false);
  const [isWeeklyPlanning, setIsWeeklyPlanning] = useState(false);

  const me = useSelector(getMe);

  // Setters and Getters
  const setStartDateAndString = (startDate) => {
    setStartDate(startDate);
    setStartText(startDate ? startDate.format(DATE_DISPLAY_SHORT) : '');
  };

  const setEndDateAndString = (endDate) => {
    setEndDate(endDate);
    setEndText(endDate ? endDate.format(DATE_DISPLAY_SHORT) : '');
  };

  const handleStartInput = (e) => {
    setStartText(e.target.value);
    setPredictUpdate('work_days');
  };

  const handleEndInput = (e) => {
    setEndText(e.target.value);
    setPredictUpdate('work_days');
  };

  const handleWorkdaysInput = (e) => {
    setWorkDays(e.target.value);
    setPredictUpdate(
      dependency === DEPENDENCY_STRINGS.END ? 'start_date' : 'end_date'
    );
  };

  const getWeeklyStartOffset = (day) => day.startOf('week');
  const getWeeklyEndOffset = (day) => day.endOf('week');

  const getWeeklyPlanningStartOffset = (day) =>
    focusedInput === START_DATE
      ? day.clone().startOf('week')
      : startDate.clone().add(2, 'days') || day;
  const getWeeklyPlanningEndOffset = (day) =>
    focusedInput === END_DATE
      ? day
          .clone()
          .endOf('week')
          .add(includeWeekends ? 0 : -2, 'days')
      : endDate?.clone() || day;

  const isWeeklyProps = isWeekly
    ? {
        startDateOffset: getWeeklyStartOffset,
        endDateOffset: getWeeklyEndOffset
      }
    : {};

  const isWeeklyPlanningProps = isWeeklyPlanning
    ? {
        startDateOffset: getWeeklyPlanningStartOffset,
        endDateOffset: getWeeklyPlanningEndOffset
      }
    : {};

  const getInitialMonth = () =>
    startDate && startDate.isValid() ? startDate : moment();

  const shouldRenderButtonPanel =
    showInputs || (!isSingleDay && !isWeekly) || showSetRange;

  const getIsDateValid = (date) => moment(date).isValid();

  // Workplan Calendar useEffects / state

  useEffect(() => {
    if (isOpen) {
      setDependency(initialDependency);
      setDependencyItem(initialDependencyItem);
      setDependencyItemType(initialDependencyItemType);
      setDependencyStepItem(initialDependencyStepItem);
      setIncludeWeekends(workplanIncludeWeekends);
      setAllDay(workplanAllDay);
      setIsTentative(workplanTentative);
      setIsWeeklyPlanning(workplanUseWeeklyPlanning);

      if (
        workplanStartTime &&
        workplanStartTime !== ' ' &&
        isWorkplanCalendar
      ) {
        const formattedStartTime = moment(
          workplanStartTime.substring(0, 5),
          'HH:mm A'
        );
        setStartTime(formattedStartTime);
      }
      if (workplanEndTime && workplanEndTime !== ' ' && isWorkplanCalendar) {
        const formattedEndTime = moment(
          workplanEndTime.substring(0, 5),
          'HH:mm A'
        );
        setEndTime(formattedEndTime);
      }
      setTimechanged(false);
    }
  }, [
    initialDependency,
    workplanIncludeWeekends,
    workplanAllDay,
    workplanTentative,
    initialDependencyItem,
    workplanStartTime,
    workplanEndTime,
    isOpen,
    isWorkplanCalendar,
    workplanUseWeeklyPlanning,
    initialDependencyItemType,
    initialDependencyStepItem
  ]);

  useEffect(() => {
    if (
      isOpen &&
      isWorkplanCalendar &&
      startDate &&
      endDate &&
      teamId &&
      accountId
    ) {
      dispatch(
        fetchUtilizations({
          startDate: startDate.format('MM/DD/YYYY'),
          endDate: endDate.format('MM/DD/YYYY'),
          accountIds: [accountId],
          teamId: teamId
        })
      );
    }
  }, [
    isWorkplanCalendar,
    startDate,
    endDate,
    dispatch,
    teamId,
    accountId,
    isOpen
  ]);

  const overCapacityDates = useMemo(() => {
    if (
      startDate &&
      endDate &&
      isOpen &&
      isWorkplanCalendar &&
      Object.keys(accountCapacities).length > 0 &&
      Object.keys(accountUtilizations).length > 0
    ) {
      const dateRange = moment.range(startDate, endDate);
      const formattedDateRange = Array.from(dateRange.by('day')).map((day) =>
        day.format('YYYY-MM-DD')
      );
      const newOverCapacityDates = {};
      formattedDateRange.forEach((date) => {
        const weekday = moment(date).format('dddd').toLowerCase();
        if (
          includeWeekends ||
          (weekday !== 'saturday' && weekday !== 'sunday')
        ) {
          const currentAvailability =
            accountCapacities[weekday] - accountUtilizations[date];
          if (currentAvailability - additionalDailyHours < 0) {
            newOverCapacityDates[date] = true;
          }
        }
      });
      return newOverCapacityDates;
    } else {
      return {};
    }
  }, [
    startDate,
    endDate,
    accountCapacities,
    accountUtilizations,
    isOpen,
    additionalDailyHours,
    includeWeekends,
    isWorkplanCalendar
  ]);

  useEffect(() => {
    if (
      (isWorkplanCalendar || isPhaseCalendar) &&
      !!startDate &&
      !!endDate &&
      startDate?.isValid?.() &&
      isOpen &&
      predictUpdate
    ) {
      dispatch(
        predictWorkdays({
          team_id: teamId,
          account_id: accountId || me?.id,
          start_date:
            predictUpdate !== 'start_date'
              ? startDate.format('MM/DD/YYYY')
              : undefined,
          end_date:
            predictUpdate !== 'end_date'
              ? endDate.isBefore(startDate)
                ? startDate.format('MM/DD/YYYY')
                : endDate.format('MM/DD/YYYY')
              : undefined,
          work_days: predictUpdate !== 'work_days' ? workDays : undefined,
          include_weekends: includeWeekends,
          dependency_info: dependency
            ? {
                dependable_type: dependencyItemType,
                dependable_id: dependencyItem?.id,
                dependency_type: dependency
              }
            : undefined,
          is_pto: isOOO
        })
      );
    }
  }, [
    dispatch,
    startDate,
    endDate,
    isWorkplanCalendar,
    workDays,
    includeWeekends,
    dependency,
    phaseId,
    allDay,
    isOOO,
    teamId,
    isOpen,
    predictUpdate,
    accountId,
    isPhaseCalendar,
    me,
    dependencyItem,
    dependencyItemType
  ]);

  useEffect(() => {
    if (
      (isWorkplanCalendar || isPhaseCalendar) &&
      !!calendarPrediction &&
      isOpen
    ) {
      if (
        predictUpdate === 'start_date' &&
        !moment(calendarPrediction.start_date).isSame(moment(startDate))
      ) {
        setStartDateAndString(moment(calendarPrediction.start_date));
      }
      if (
        predictUpdate === 'end_date' &&
        !moment(calendarPrediction.end_date).isSame(moment(endDate))
      ) {
        setEndDateAndString(moment(calendarPrediction.end_date));
      }
      if (
        predictUpdate === 'work_days' &&
        calendarPrediction.work_days !== workDays
      ) {
        setWorkDays(calendarPrediction.work_days);
      }
    }
  }, [
    calendarPrediction,
    endDate,
    isWorkplanCalendar,
    startDate,
    workDays,
    includeWeekends,
    isOpen,
    predictUpdate,
    isPhaseCalendar,
    isWeeklyPlanning
  ]);

  // Effects
  // call props.onTimeChange() anytime there is an update to the start date, end date, or workdays
  useEffect(() => {
    if (
      !moment(startDate).isSame(moment(itemStartDate), 'day') ||
      !moment(endDate).isSame(moment(itemEndDate), 'day') ||
      workDays !== itemWorkDays
    ) {
      onTimeChange({ startDate, endDate, workDays });
    }
  }, [
    startDate,
    endDate,
    workDays,
    itemStartDate,
    itemEndDate,
    itemWorkDays,
    onTimeChange
  ]);

  useEffect(() => {
    if (isWorkplanCalendar) {
      setIsOpen(startOpen);
    }
  }, [startOpen, isWorkplanCalendar]);

  // update state when recieve new props
  useEffect(() => {
    setStartDateAndString(
      getIsDateValid(itemStartDate) ? moment(itemStartDate) : null
    );
    setEndDateAndString(
      getIsDateValid(itemEndDate) ? moment(itemEndDate) : null
    );
    setWorkDays(itemWorkDays);
  }, [itemStartDate, itemEndDate, itemWorkDays]);

  useEffect(() => {
    if (isSingleDay) {
      setFocusedInput(START_DATE);
    } else if (isStartDateDependency(dependency)) {
      setFocusedInput(END_DATE);
    } else if (isEndDateDependency(dependency)) {
      setFocusedInput(START_DATE);
    } else if (dependency === DEPENDENCY_STRINGS.START_AND_END) {
      setFocusedInput(null);
    } else {
      setFocusedInput(START_DATE);
    }
  }, [isSingleDay, dependency]);

  useEffect(() => {
    if (!initialDependency) {
      setFocusedInput(endDateFocus ? END_DATE : START_DATE);
    }
  }, [endDateFocus, isOpen, initialDependency]);

  // action Handler

  // callback for calendar when a selection is made.
  const handleDatesChange = ({
    startDate: newStartDate,
    endDate: newEndDate
  }) => {
    if (isSingleDay) {
      // Don't allow date change when there's a dependency
      if (!dependency) {
        setStartDateAndString(newStartDate);
        setEndDateAndString(newStartDate);
      }
    } else if (isWeekly) {
      setStartDateAndString(newStartDate);
      setEndDateAndString(newEndDate);
    } else if (isWeeklyPlanning) {
      setPredictUpdate('work_days');
      if (focusedInput === START_DATE) {
        const hasInvalidRange =
          newStartDate && endDate && newStartDate.isAfter(endDate);
        // when it has invalid range, correct end date
        const calculatedRange = calculateWeeklyDateRange({
          startDate: newStartDate,
          endDate: hasInvalidRange ? newStartDate : endDate
        });

        setStartDateAndString(calculatedRange.startDate);
        setEndDateAndString(calculatedRange.endDate);
        setFocusedInput(END_DATE);
      } else if (focusedInput === END_DATE) {
        const hasInvalidRange =
          startDate && newEndDate && newEndDate.isBefore(startDate);
        // when it has invalid range, correct start date
        const calculatedRange = calculateWeeklyDateRange({
          startDate: hasInvalidRange ? newEndDate : startDate,
          endDate: newEndDate
        });

        setStartDateAndString(calculatedRange.startDate);
        setEndDateAndString(calculatedRange.endDate);
        setFocusedInput(START_DATE);
      }
    } else if (focusedInput === START_DATE) {
      setPredictUpdate('work_days');
      if (isEndDateDependency(dependency)) {
        // prevent end date from changing with start_date when dependency is set
        setEndDateAndString(null);
      }

      setStartDateAndString(newStartDate);

      // when it has invalid range, reset end date
      if (endDate && moment(newStartDate).isAfter(endDate)) {
        setEndDateAndString(undefined);
      }
    } else if (focusedInput === END_DATE) {
      setPredictUpdate('work_days');
      if (isStartDateDependency(dependency)) {
        // prevent start date from changing with end_date when dependency is set
        setStartDateAndString(newStartDate);
      }

      // when selected end date is before start date, only newStartDate has value
      if (newStartDate) {
        setStartDateAndString(newStartDate);
        setEndDateAndString(undefined);
      }
      if (newEndDate) {
        setEndDateAndString(newEndDate);
      }
    }
  };

  // handles focus for the calendar. Determins what value the next click will update
  const handleFocusChange = (newFocusedInput) => {
    const isDependencySet =
      dependency && dependency !== DEPENDENCY_STRINGS.START_AND_END;

    setFocusedInput(
      isWeeklyPlanning
        ? focusedInput === START_DATE
          ? END_DATE
          : START_DATE
        : isSingleDay
        ? START_DATE
        : isDependencySet
        ? isStartDateDependency(dependency)
          ? END_DATE
          : START_DATE
        : newFocusedInput || START_DATE
    );
  };

  // handler when the user clicks teh done button.
  // calls props.OnSubmit()
  const handleDone = () => {
    setIsOpen(startOpen);

    if (isSingleDay) {
      onSubmit({ startDate, dependency, dependencyItem, dependencyItemType });
    } else if (!startDate && !endDate) {
      // Ref: https://mosaicapp.slack.com/archives/C016FC1D63H/p1657397397735589?thread_ts=1657396836.945079&cid=C016FC1D63H
      // When both date is empty, just close calendar without posting the toast error
      handleClose();
    } else if (isValidDateRange({ startDate, endDate, allowNull: showClear })) {
      onSubmit({
        startDate,
        endDate: endDate && !endDate.isValid() ? null : endDate,
        dependency,
        dependencyItem,
        dependencyItemType,
        includeWeekends,
        allDay,
        isTentative,
        startTime,
        endTime,
        timeRange,
        timeChanged,
        useWeeklyPlanning: isWeeklyPlanning
      });
    } else {
      handleClose();
      dispatch(
        handleErrorMessage({
          type: GENERIC_ACTION,
          isFeError: true,
          errorMessage: 'Start Date must be before End Date'
        })
      );
    }
  };

  // handles updates in the buttons panel inputs
  const handleFormSubmit = () => {
    const startMoment = isWeeklyPlanning
      ? moment(startText).startOf('week')
      : moment(startText);
    const startIsValid = startMoment.isValid;

    const endMoment = isWeeklyPlanning
      ? moment(endText)
          .endOf('week')
          .add(includeWeekends ? 0 : -2, 'days')
      : moment(endText);
    const endIsValid = endMoment.isValid && endMoment.isAfter(startMoment);

    if (!startIsValid) {
      setStartDateAndString(null);
      setEndDateAndString(null);
    } else {
      setStartDateAndString(startIsValid ? startMoment : null);
      setEndDateAndString(endIsValid ? endMoment : null);
    }
  };

  // resets modal state and closes the modal.
  // also calls props.onClose()
  const handleClose = (e) => {
    if (e && isInTimePicker(e)) {
      return;
    }
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
    setStartDateAndString(momentStart);
    setEndDateAndString(momentEnd);
    setWorkDays(itemWorkDays);
    setDependency(null);
  };

  // opens the modal
  const handleOpen = () => {
    setIsOpen(true);
  };

  // calls props.onClear
  const handleClear = () => {
    setStartDate(moment());
    if (onClear) {
      if (isSingleDay) {
        onClear({ startDate: null });
      } else {
        onClear({ startDate: null, endDate: null, workDays: null });
      }
    }
    handleClose();
  };

  // render helpers
  const formatDate = (date) => date?.clone?.().format?.('MMM DD, YYYY');
  const formatDateNoYear = (date) => date?.clone?.().format?.('MMM DD');

  const getButtonPanel = () =>
    shouldRenderButtonPanel && (
      <div>
        <ButtonPanel
          startText={startText}
          endText={endText}
          workDays={workDays}
          handleDone={handleDone}
          handleCancel={handleClose}
          handleStartInput={handleStartInput}
          handleEndInput={handleEndInput}
          handleWorkdaysInput={handleWorkdaysInput}
          handleFormSubmit={handleFormSubmit}
          isWeekly={isWeekly}
          isSingleDay={isSingleDay}
          showWorkdays={showWorkdays}
          currentFocus={focusedInput}
          showSetRange={showSetRange}
          onSetRange={onSetRange}
          showInputs={showInputs || (!isSingleDay && !isWeekly && !hideInputs)}
          labels={labels}
          isWorkplanCalendar={isWorkplanCalendar}
          isPhaseCalendar={isPhaseCalendar}
          onWorkdaysChange={onWorkdaysChange}
          dependency={dependency}
          phaseStartDate={phaseStartDate}
          phaseEndDate={phaseEndDate}
        />
        {shouldRenderFooter && getFooter()}
      </div>
    );

  // --------Workplan Calendar functions-------

  const handleSetDependency = (value, itemStartDate, itemEndDate, item) => {
    if (!disableDependency) {
      if (isStartDateDependency(value)) {
        setStartDateAndString(
          moment(
            value === DEPENDENCY_STRINGS.START ? itemStartDate : itemEndDate
          )
        );
        setPredictUpdate('end_date');
      } else if (isEndDateDependency(value)) {
        setEndDateAndString(
          moment(value === DEPENDENCY_STRINGS.END ? itemEndDate : itemStartDate)
        );
        setPredictUpdate('start_date');
      } else if (value === DEPENDENCY_STRINGS.START_AND_END) {
        setStartDateAndString(moment(itemStartDate));
        setEndDateAndString(moment(itemEndDate));
        setPredictUpdate('work_days');
      }
      setDependency(value);
      setDependencyItem(item);
      setDependencyStepItem(null);
    }
  };

  const clearDependency = () => {
    handleSetDependency(null, null, null, null);
  };

  const getWeekendsPredictConstant = () => {
    if (!dependency || isStartDateDependency(dependency)) {
      return 'end_date';
    } else if (isEndDateDependency(dependency)) {
      return 'start_date';
    } else {
      return 'work_days';
    }
  };

  const setWeekendsPredictConstant = () => {
    setPredictUpdate(getWeekendsPredictConstant());
  };

  const startTimeSelect = (time) => {
    setStartTime(time);
    setTimechanged(true);
  };

  const endTimeSelect = (time) => {
    setEndTime(time);
    setTimechanged(true);
  };

  const timeRange = useMemo(() => {
    if (!startTime || !endTime) {
      return undefined;
    }
    const duration = moment.duration(startTime.diff(endTime)).asHours() * -1;
    const hours = formatNumWithMaxTwoDecimals(
      duration + (duration < 0 ? 24 : 0)
    );
    return hours;
  }, [startTime, endTime]);

  const workplanCalendarOptionSetterTypes = {
    [WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY]: setAllDay,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS]: setIncludeWeekends,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE]: setIsTentative,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING]: setIsWeeklyPlanning,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.SET_WEEKENDS_PREDICT_CONSTANT]:
      setWeekendsPredictConstant
  };

  const workplanCalendarOptionValues = {
    [WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY]: allDay,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS]: includeWeekends,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE]: isTentative,
    [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING]: isWeeklyPlanning
  };

  const workplanCalendarCollapsableMenuProps = {
    [WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME]: {
      startTime,
      endTime,
      startTimeSelect,
      endTimeSelect,
      dailyHours: workplanDailyHours
    }
  };

  const getFooter = () => {
    if (isTaskCalendar) {
      return (
        <div style={{ height: 60 }}>
          <DependencySetter
            item={item}
            itemType={DEPENDENCY_TYPES.TASK}
            disableDependency={disableDependency}
            dependency={dependency}
            dependencyStepItem={dependencyStepItem}
            dependencyItem={dependencyItem}
            dependencyItemType={DEPENDENCY_TYPES.TASK}
            setDependency={setDependency}
            setDependencyItemType={setDependencyItemType}
            setDependencyStepItem={setDependencyStepItem}
            setDependencyItem={setDependencyItem}
            handleSetDependency={handleSetDependency}
            dropdownStyles={{ width: '262px' }}
            clearDependency={clearDependency}
          />
        </div>
      );
    } else if (isScopeCalendar) {
      return (
        <div style={{ height: 60 }}>
          <DependencySetter
            item={item}
            itemType={DEPENDENCY_TYPES.SCOPE}
            fixedDependency={true}
            disableDependency={disableDependency}
            dependency={dependency}
            dependencyStepItem={initialDependencyStepItem}
            dependencyItem={dependencyItem}
            dependencyItemType={DEPENDENCY_TYPES.WORK_PLAN}
            setDependency={setDependency}
            setDependencyItemType={setDependencyItemType}
            setDependencyStepItem={setDependencyStepItem}
            setDependencyItem={setDependencyItem}
            handleSetDependency={handleSetDependency}
            dropdownStyles={{ width: '262px' }}
            clearDependency={clearDependency}
          />
        </div>
      );
    } else if (isWorkplanCalendar) {
      return (
        <div style={{ height: 60, marginTop: -5 }}>
          <DependencySetter
            item={item}
            itemType={DEPENDENCY_TYPES.WORK_PLAN}
            fixedDependency={true}
            disableDependency={disableDependency}
            dependency={dependency}
            dependencyStepItem={initialDependencyStepItem}
            dependencyItem={dependencyItem}
            dependencyItemType={DEPENDENCY_TYPES.PHASE}
            setDependency={setDependency}
            setDependencyItemType={setDependencyItemType}
            setDependencyStepItem={setDependencyStepItem}
            setDependencyItem={setDependencyItem}
            handleSetDependency={handleSetDependency}
            clearDependency={clearDependency}
          />
        </div>
      );
    } else if (isPhaseCalendar || isMilestoneCalendar) {
      return (
        <div style={{ height: 60 }}>
          <DependencySetter
            item={item}
            itemType={itemType}
            disableDependency={disableDependency}
            dependency={dependency}
            dependencyStepItem={dependencyStepItem}
            dependencyItem={dependencyItem}
            dependencyItemType={dependencyItemType}
            setDependency={setDependency}
            setDependencyItemType={setDependencyItemType}
            setDependencyStepItem={setDependencyStepItem}
            setDependencyItem={setDependencyItem}
            handleSetDependency={handleSetDependency}
            dropdownStyles={isMilestoneCalendar ? { width: '262px' } : {}}
            phases={phases}
            clearDependency={clearDependency}
          />
        </div>
      );
    }
  };

  const isDayHighlighted = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return overCapacityDates[formattedDate];
  };

  return (
    <>
      <div ref={popoverTarget}>
        {customInput ? (
          customInput(itemStartDate, itemEndDate, handleOpen)
        ) : (
          <ReportsDateContainer onClick={handleOpen}>
            <StyledCalendarIcon height="12px" width="12px" />
            <StyledDates className="styled-dates">
              {customLabel ||
                (isSingleDay
                  ? momentStart.format('MM/DD')
                  : isWeekly
                  ? `${formatDateNoYear(momentStart)} - ${formatDate(
                      momentEnd
                    )}`
                  : `${formatDate(momentStart)} - ${formatDate(momentEnd)}`)}
            </StyledDates>
          </ReportsDateContainer>
        )}
      </div>
      <Popover
        isOpen={isOpen}
        target={target || popoverTarget}
        closePopover={handleClose}
        className={cn('calendar-popover', calendarClassNames, {
          'workplan-calendar':
            isWorkplanCalendar || isPhaseCalendar || isMilestoneCalendar,
          'task-calendar': isTaskCalendar,
          'phase-calendar': isPhaseCalendar || isMilestoneCalendar
        })}
      >
        <CalendarContainer
          isSingleDay={isSingleDay}
          isWeekly={isWeekly}
          shouldRenderButtonPanel={shouldRenderButtonPanel}
          isWorkplanOrPhaseCalendar={!!isWorkplanCalendar || !!isPhaseCalendar}
          isTaskCalendar={isTaskCalendar}
          isWorkplanCalendar={isWorkplanCalendar}
        >
          {Object.keys(overCapacityDates).length > 0 && (
            <OverCapacityLabel> Exceeds Work Schedule </OverCapacityLabel>
          )}

          {isWorkplanCalendar && (
            <WorkplanCalendarSidebar
              isOOO={isOOO}
              workplanCalendarCollapsableMenuProps={
                workplanCalendarCollapsableMenuProps
              }
              workplanCalendarOptionSetterTypes={
                workplanCalendarOptionSetterTypes
              }
              workplanCalendarOptionValues={workplanCalendarOptionValues}
            />
          )}

          <CancelButton
            onClick={handleClear}
            showClear={showClear}
            isWorkplanOrPhaseCalendar={
              !!isWorkplanCalendar || !!isPhaseCalendar
            }
          >
            Clear
          </CancelButton>
          <DoneButton onClick={handleDone}>Done</DoneButton>
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            initialVisibleMonth={getInitialMonth}
            renderMonthElement={renderMonthElement}
            navPrev={<NavLeft />}
            navNext={<NavRight />}
            renderDayContents={dayRenderer}
            renderCalendarInfo={getButtonPanel}
            hideKeyboardShortcutsPanel
            enableOutsideDays
            firstDayOfWeek={isWeekly ? 1 : 0}
            isDayHighlighted={isDayHighlighted}
            {...isWeeklyProps}
            {...isWeeklyPlanningProps}
          />
        </CalendarContainer>
      </Popover>
    </>
  );
};

export default DateRangeCalendar;
