import React from 'react';
import SvgIcon from 'components/SvgIcon';

const UnfollowIcon = () => {
  return (
    <SvgIcon width="13" height="14" viewBox="0 0 13 14">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#0074D9"
          d="M9.134 5.97c0-1.678-.948-3.143-2.28-3.751.104-.211.168-.447.168-.701 0-.025-.005-.047-.007-.072C6.98.643 6.358 0 5.593 0c-.73 0-1.325.584-1.412 1.333-.007.061-.018.121-.018.185 0 .215.044.419.12.604a3.584 3.584 0 0 0-1.26.793 4 4 0 0 0-1.196 2.87v2.186A2.85 2.85 0 0 1 1 9.987v.736c2.992 1.107 6.017 1.132 9 .071v-.767a2.836 2.836 0 0 1-.866-2.056V5.97zM3.881 12.24A1.833 1.833 0 0 0 5.593 13.5c.766 0 1.41-.53 1.645-1.259"
        />
        <path
          fillRule="nonzero"
          stroke="#0074D9"
          strokeLinecap="square"
          d="M.5 1.5l10 11"
        />
        <path
          fillRule="nonzero"
          stroke="#FFF"
          strokeLinecap="square"
          d="M.5.5L12 13"
        />
      </g>
    </SvgIcon>
  );
};

export default UnfollowIcon;
