import { Color, ColorScaleMinima, ColorScaleColors } from './types';

/**
 * Defines the minimum values of the default intervals.
 * - 0%: No capacity utilised (COLOR_SCALE_NULL_COLOR)
 * - 0-50%: Little capacity utilised (color 1)
 * - 50-75%: Some capacity utilised (color 2)
 * - 75-100%: Most capacity utilised (color 3)
 * - 100-100%: Full capacity utilised (color 4)
 * - 100-120%: Some overcapacity (color 4, with small indicator)
 * - 120%+: Ample overcapacity (color 4, with large indicator)
 */
export const COLOR_SCALE_MINIMA_DEFAULT: ColorScaleMinima = [
  0, 0, 50, 75, 100, 100, 120
];

/**
 * The default color for 0% fulfillment of capacity.
 */
export const COLOR_SCALE_NULL_COLOR: Color = '#F7E4E4';

/**
 * The default color for 0% fulfillment of capacity of a reversed color scale.
 */
export const COLOR_SCALE_REVERSED_NULL_COLOR: Color = '#E7F8EE';

/**
 * The default color scale colors.
 */
export const COLOR_SCALE_COLORS_DEFAULT: ColorScaleColors = [
  '#F2DADB',
  '#FFE4CC',
  '#FFEDBD',
  '#DCF5E7'
];

/**
 * The grayscale color scale colors.
 */
export const COLOR_SCALE_GRAYSCALE: ColorScaleColors = [
  '#D5DADF',
  '#BFC8CF',
  '#ACB5BF',
  '#97A3AF'
];

/**
 * A list of the predefined color scales.
 */
export const COLOR_SCALES_COLORS_DEFAULT: Array<ColorScaleColors> = [
  // Traffic light
  COLOR_SCALE_COLORS_DEFAULT,
  // Grayscale
  COLOR_SCALE_GRAYSCALE,
  // Blue
  ['#CEE4F8', '#B7D6F4', '#9DCAF1', '#5CA6E7'],
  // Green
  ['#BBEBCE', '#97E0B6', '#74D69E', '#46C97D'],
  // Purple
  ['#E4DAFF', '#D6C7FF', '#B59CFF', '#A484FF'],
  // Pink
  ['#FDE7F1', '#FBCEE2', '#FBB6D7', '#F99DCA'],
  // Orange
  ['#FCEBDC', '#FAD6B6', '#F6C292', '#F5AD6E'],
  // Red
  ['#F6E7E6', '#F0D0D0', '#E8B9B8', '#E0A1A1']
];
