import { callApi } from 'appUtils/api';
import qs from 'qs';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import {
  CreatePTOPolicyParams,
  FetchPTOPoliciesParams,
  FetchPTOPolicyParams,
  FetchPTOPolicyPerMemberParams,
  UpdatePTOPolicyParams,
  ArchivePTOPolicyParams,
  CreatePTOGroupMembershipParams,
  FetchPTOGroupMembershipsParams,
  UpdatePTOGroupMembershipParams,
  DeletePTOGroupMembershipParams
} from './types';

const fetchPTOPolicyPerMember = (
  token: string,
  { teamId }: FetchPTOPolicyPerMemberParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify({ team_id: teamId });

  return callApi(
    `pto_groups/policy_per_members?${stringifiedQueryParams}`,
    token,
    fetchInit
  );
};

const fetchPTOPolicies = (
  token: string,
  { teamId, isCustom, isIncludeArchived }: FetchPTOPoliciesParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = qs.stringify(
    convertKeysToSnakeCase({
      teamId,
      isCustom,
      includeArchived: isIncludeArchived
    })
  );

  return callApi(`pto_groups?${queryParams}`, token, fetchInit);
};

const fetchPTOPolicy = (token: string, { id }: FetchPTOPolicyParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`pto_group/${id}`, token, fetchInit);
};

const createPTOPolicy = (
  token: string,
  { hours, name, isAccrued, teamId }: CreatePTOPolicyParams
) => {
  const body = {
    name,
    hours,
    ...convertKeysToSnakeCase({ teamId, isAccrued })
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('pto_group', token, fetchInit);
};

const updatePTOPolicy = (
  token: string,
  { id, hours, name, isAccrued }: UpdatePTOPolicyParams
) => {
  const body = { hours, name, ...convertKeysToSnakeCase({ isAccrued }) };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`pto_group/${id}`, token, fetchInit);
};

const archivePTOPolicy = (token: string, { id }: ArchivePTOPolicyParams) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`pto_group/${id}/archive`, token, fetchInit);
};

const fetchPTOGroupMemberships = (
  token: string,
  { teamMembershipId }: FetchPTOGroupMembershipsParams
) => {
  const queryParams = qs.stringify({ team_membership_id: teamMembershipId });

  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`pto_group_memberships?${queryParams}`, token, fetchInit);
};

const createPTOGroupMembership = (
  token: string,
  { id, teamMembershipIds }: CreatePTOGroupMembershipParams
) => {
  const body = convertKeysToSnakeCase({ teamMembershipIds });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`pto_group/${id}/add_members`, token, fetchInit);
};

const updatePTOGroupMembership = (
  token: string,
  { id, startDate, endDate, ptoGroupId }: UpdatePTOGroupMembershipParams
) => {
  const body = convertKeysToSnakeCase({
    startDate,
    endDate,
    ptoGroupId
  });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`pto_group_membership/${id}`, token, fetchInit);
};

const deletePTOGroupMembership = (
  token: string,
  { id }: DeletePTOGroupMembershipParams
) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`pto_group_membership/${id}`, token, fetchInit);
};

export const ptoApi = {
  fetchPTOPolicyPerMember,
  fetchPTOPolicies,
  fetchPTOPolicy,
  createPTOPolicy,
  updatePTOPolicy,
  archivePTOPolicy,
  createPTOGroupMembership,
  fetchPTOGroupMemberships,
  updatePTOGroupMembership,
  deletePTOGroupMembership
};
