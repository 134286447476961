import styled from 'styled-components';
import theme from 'theme';
import SubmitCheck from 'icons/SubmitCheck';
import QBDownArrow from 'icons/QBDownArrow';
import TimesheetArrowSubmitIcon from 'icons/TimesheetArrowSubmitIcon';
import { Styles } from 'components/Table/styles';
import {
  StyledProjectTitle,
  StyledProjectDescription,
  StyledProjectInfo
} from './TimesheetProject/styles';

const mapStatusToColor = {
  submitted: theme.colors.colorRemainingTeal,
  not_submitted: theme.colors.colorRoyalBlue,
  approved: theme.colors.colorBudgetGreen
};

export const HideOnHover = styled.div``;

export const ShowOnHover = styled.div`
  display: none;
`;

export const TimesheetArrowContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  justify-content: center;
  &.approve-all {
    padding: 5px 0;
  }
  &:hover {
    ${HideOnHover} {
      display: none;
    }
    ${ShowOnHover} {
      display: block;
    }
  }
`;

export const StyledTimesheetSubmitArrowIcon = styled(TimesheetArrowSubmitIcon)`
  path {
    stroke: ${({ isSelected, isDisabled }) =>
      isSelected && !isDisabled ? theme.colors.colorPureWhite : ''};
  }
  circle {
    fill: ${({ isSelected }) =>
      isSelected ? theme.colors.colorRemainingTeal : ''};
    stroke: ${({ isSelected }) =>
      isSelected ? theme.colors.colorRemainingTeal : ''};
  }
  cursor: ${(props) => (props.isWaiting ? 'wait' : 'pointer')};
`;

export const TimesheetStyledCell = styled.div`
  background: ${theme.colors.colorPureWhite};
  padding: 2px 16px;
  color: ${theme.colors.colorSemiDarkGray1};
  border: 1px solid
    ${({ isBlue }) =>
      isBlue ? theme.colors.colorRoyalBlue : theme.colors.colorPaleGray8};
  border-radius: 0px;
  height: 60px;
  margin: 2px 1px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ isRed }) =>
    isRed
      ? `@keyframes blink { 50% { border-color:red ; }  } animation: blink 0.5s;
    animation-iteration-count: 3;`
      : ''}

  &:hover {
    border: 1px solid
      ${({ isBlue }) =>
        isBlue ? theme.colors.colorRoyalBlue : theme.colors.colorPaleGray6};
    .content-editable-description {
      &[data-placeholder]:empty:before {
        color: ${theme.colors.colorRoyalBlue};
      }
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  &:focus-within {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }

  /* Don't convert the following code into styled components
  it creates cursor issues during text input
  */
  .content-editable-description {
    border: none;
    overflow: auto;
    max-height: 100%;
    font-weight: 400;
    width: 100%;
    background: ${theme.colors.colorPureWhite};
    white-space: normal;
    resize: none;
    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &[data-placeholder]:empty:before {
      content: attr(data-placeholder);
      color: ${theme.colors.colorMediumGray3};
    }
  }
`;

export const StyledDayInput = styled.input`
  border: none;
  text-align: right;
  margin-left: 2px;
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray5};
  background: ${theme.colors.colorPureWhite};
  &:focus {
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 400;
  }
  ::placeholder {
    color: ${theme.colors.colorLightGray6};
  }
`;

export const StyledDayInputContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
`;

export const TimesheetStyledDayCell = styled(TimesheetStyledCell)`
  padding: 5px;
  position: relative;
  &:hover {
    ${StyledDayInputContainer} {
      .show-on-row-hover {
        color: ${theme.colors.colorRoyalBlue} !important;
        opacity: 1;
      }
    }
  }
`;

export const StyledTeamName = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  margin-left: 4px;
  color: ${theme.colors.colorMediumGray5};
`;

export const TimesheetStyledProjectCell = styled(TimesheetStyledCell)`
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  justify-content: flex-start;

  ${StyledProjectInfo} {
    width: 290px;
  }
  ${StyledProjectTitle} {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: none;
  }
  ${StyledProjectDescription} {
    width: unset;
    flex-grow: 1;
  }
  .project-three-dot-icon {
    opacity: 1;
  }
`;

export const DayTimesheetContainer = styled.div`
  position: relative;
`;

export const SubmitStatus = styled.div`
  position: absolute;
  top: -19px;
  right: -17px;
  cursor: pointer;
  &:hover {
    ${HideOnHover} {
      display: none;
    }
    ${ShowOnHover} {
      display: block;
    }
  }
`;

export const SyncStatus = styled.div`
  position: absolute;
  top: -20px;
  left: -9px;
`;

export const RowTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 13px;

  // Ensures there is space between each row so that the tooltip disappears
  // between rows.
  margin: 10px 0;
  height: calc(100% - 20px);
`;

export const TimesheetFooter = styled.div`
  font-weight: 800;
  padding-right: 10px;
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 0;
  padding-right: 8px;
  .dropdown-menu {
    min-width: 88px;
    padding: 2px;
  }
`;
export const TimesheetFooterValue = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  &.approve-all {
    justify-content: flex-end;
  }
`;
export const StyledDropdownItem = styled.div`
  font-size: 12px;
  padding: ${(props) => props.padding || `4px 10px`};
  cursor: pointer;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
  display: flex;
  align-items: center;
`;

export const StyledSelectActivityArrow = styled(QBDownArrow)`
  margin-left: 6px;
  path {
    fill: ${({ show }) =>
      show ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray5};
  }
`;

export const ActivitySelectionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ disableHoverEffect }) =>
    !disableHoverEffect &&
    `
      cursor: pointer;
  `}
  ${StyledSelectActivityArrow} {
    ${({ disableHoverEffect }) =>
      disableHoverEffect &&
      `
        path {
          fill: ${theme.colors.colorPureWhite} !important;
        }
    `}
  }
  justify-content: space-between;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledSelectActivity = styled.div`
  border: none;
  color: ${({ show }) =>
    show ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray3};
`;

export const TimesheetMainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1560px) {
    margin-left: ${({ marginLeft }) => marginLeft || '65px'};
  }

  ${Styles} {
    flex: 1;
    min-height: 0;
    padding: 0;
    margin-bottom: 1rem;
  }

  .table {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .table-body {
    max-height: calc(100% - 93px - 68px); // - header - footer

    // the table doesn't use VariableSizeTable, so this targeted div is the variable-size-list equivalent
    & > div {
      height: 100% !important;
      padding-bottom: 2px;
    }
  }

  .timesheet-reports-pencil-icon {
    display: none;
  }
  .tables-header-sticky {
    position: sticky;
    top: 0;
    z-index: 4; // 5 is home sub navigation
  }
  .table-headers-container {
    > div {
      border-bottom: 1px solid ${theme.colors.colorLightGray9};
    }
    .total {
      border-bottom: none;
    }

    background: ${theme.colors.colorTranslucentGray4};
  }

  .table-column-header {
    font-size: 11px;
    text-transform: uppercase;
    color: ${theme.colors.colorMediumGray5};
    font-weight: 400;
    font-family: 'Proxima-nova';

    &.description,
    &.activity,
    &.project {
      padding-left: 15px;
    }
    .center-header {
      text-align: center;
      padding-left: 0px;
    }
    .today {
      color: ${theme.colors.colorCalendarOrange};
      font-weight: 600;
    }
  }

  // table column resizer - not used
  .resizer {
    display: none !important;
  }

  .table-body .tr:first-child {
    min-width: 1076px;
    border-radius: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    // Following is for add entry row so that last 'total' cell doesn't have a border/bgColor
    .td {
      background-color: white;
      border-top: 1px solid ${theme.colors.colorPaleGray8};
      border-bottom: 1px solid ${theme.colors.colorPaleGray8};
      &:first-child {
        border-left: 1px solid ${theme.colors.colorPaleGray8};
      }
      &:nth-last-child(2) {
        border-right: 1px solid ${theme.colors.colorPaleGray8};
      }
      &:last-child {
        background-color: transparent;
        border: none;
      }
    }
  }
  .tr:hover {
    ${TimesheetStyledCell}:hover {
      ${ActivitySelectionContainer} {
        color: ${theme.colors.colorRoyalBlue};
      }
      ${StyledSelectActivity} {
        color: ${theme.colors.colorRoyalBlue};
      }
      ${StyledSelectActivityArrow} {
        path {
          fill: ${theme.colors.colorRoyalBlue};
        }
      }
    }
  }

  .td {
    flex-shrink: 0;

    &.rightPadding {
      flex-shrink: 1;
    }
  }
`;

export const StyledSubmitCheck = styled(SubmitCheck)`
  path {
    fill: ${({ id, status }) =>
      id ? theme.colors.colorMediumGray5 : mapStatusToColor[status]};
  }
`;

export const StyledSubmitButton = styled.div`
  color: ${theme.colors.colorMediumGray1};
  cursor: pointer;
  padding: 10px 0px 10px 8px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 2px;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledQBDownArrow = styled(QBDownArrow)`
  margin-left: 5px;
  margin-top: 2px;
  path {
    fill: ${theme.colors.colorPureWhite};
  }
`;

export const CheckBoxDiv = styled.div`
  margin: 8px 0px;
`;

export const StyledModalHeader = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: #333333;
`;

export const StyledHeaderDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const DoneButton = styled.div`
  cursor: pointer;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 4px;
  &:hover {
    background: ${theme.colors.colorDeeperRoyalBlue};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  height: 28px;
  padding: 0px 9px;
`;

export const SettingDiv = styled.div`
  padding-left: 10px;
  width: 100%;
  margin-top: 18px;
  .title {
    font-weight: 600;
    font-size: 13px;
    color: #4f4f4f;
  }
  .description {
    width: 220px;
    font-size: 10px;
    color: #828282;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const SwitcherButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  padding: 4px 10px;

  cursor: pointer;
  flex-direction: column;
  background: ${({ isActive }) =>
    isActive ? theme.colors.colorRoyalBlue : theme.colors.colorPureWhite};

  color: ${({ isActive }) =>
    isActive ? theme.colors.colorPureWhite : theme.colors.colorMediumGray5};

  &:hover {
    color: ${({ isActive }) =>
      isActive ? theme.colors.colorPureWhite : theme.colors.colorRoyalBlue};
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
export const SwitchContainer = styled.div`
  border-radius: 5px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${theme.colors.colorPaleGray5};
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  max-width: ${({ maxWidth }) =>
    maxWidth || 'calc(100vw - 280px)'}; // home sidebar and sidemenu
  position: relative;

  &.timesheets-container .project-planner-container {
    width: 100%;
  }
`;

export const SmallIconContainer = styled.span`
  position: relative;
  bottom: 5px;
`;

export const SelectedWorkCategoryContainer = styled.div`
  padding-top: 24px;
  max-width: 100px;
  width: 100px;
  height: 65px;
`;

export const SelectedWorkCategory = styled.span`
  display: inline-block;
  padding-left: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
`;

export const IssueIconContainer = styled.div`
  color: ${theme.colors.colorDeleteRed};
  font-size: 15px;
  font-weight: 800;
  margin-left: 2px;
`;
