import theme from 'theme';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getAdmins } from 'TeamsModule/selectors';
import Popover from 'components/Popover';
import MemberInitials, {
  StyledMemberCircle
} from 'views/memberDisplay/MemberInitials';

const AdminListContainer = styled.div`
  width: 204px;
  height: 260px;
  background: white;
  padding: 15px;
  padding-bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AdminListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  span {
    font-size: 15px;
    font-weight: 600;
  }
  .close-icon {
    cursor: pointer;
  }
`;

const AdminList = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const AdminRow = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  ${StyledMemberCircle} {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
  }
  span {
    color: ${theme.colors.colorSemiDarkGray1};
    font-size: 13px;
  }
`;

const AdminListFlyout = ({ target, onMouseEnter, toggle, offset }) => {
  const admins = useSelector(getAdmins);

  return (
    <Popover
      isOpen
      target={target}
      closePopover={toggle}
      className="admin-list-popover"
      offset={offset}
    >
      <AdminListContainer onMouseEnter={onMouseEnter}>
        <AdminListHeader>
          <span>Admins</span>
          <div className="close-icon" onClick={toggle} />
        </AdminListHeader>
        <AdminList>
          {admins.map((admin, index) => {
            return (
              <AdminRow key={index}>
                <MemberInitials
                  member={admin}
                  size="medium"
                  classes={`sidebar-member-initials selected regular-member-no-hover`}
                  id={admin.id}
                />
                <span>{admin.name}</span>
              </AdminRow>
            );
          })}
        </AdminList>
      </AdminListContainer>
    </Popover>
  );
};

export default AdminListFlyout;
