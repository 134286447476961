import React from 'react';
import theme from 'theme';
import { connect } from 'react-redux';
import { makeGetEditOrViewIdsHash, getIsOnScheduleView } from 'selectors';

import {
  MembersContainer,
  StyledVisibilityIcon,
  VisibilityText
} from 'views/homePlanner/styles';
class WorkloadVisibilityIndicator extends React.Component {
  render() {
    const { handleClick, editOrViewIds, isOnScheduleView } = this.props;
    const visibilityText = Object.keys(editOrViewIds).length;
    const isPlural = visibilityText === 0 ? true : visibilityText > 1;
    return (
      <MembersContainer
        data-for="app-tooltip"
        data-html
        data-arrow-color={theme.colors.colorDarkBlueTooltip}
        data-effect="solid"
        data-tip={`Click to view the ${visibilityText} ${
          isPlural ? 'members' : 'member'
        } that can view the ${isOnScheduleView ? 'Planner' : 'Workload'}.`}
        onClick={handleClick}
        data-testid="member-visibility"
        className="workload-visibility-indicator"
      >
        <StyledVisibilityIcon fill={theme.colors.colorMediumGray9} />
        <VisibilityText>{visibilityText}</VisibilityText>
      </MembersContainer>
    );
  }
}

const makeMapStateToProps = () => {
  const getEditOrViewIdsHash = makeGetEditOrViewIdsHash();
  const mapStateToProps = (state, ownProps) => ({
    editOrViewIds: getEditOrViewIdsHash(state, ownProps),
    isOnScheduleView: getIsOnScheduleView(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(WorkloadVisibilityIndicator);
