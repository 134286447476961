import { getMatchedRouteParams, getSplitFlags, getSplitState } from 'selectors';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OnboardModalContainer } from 'views';
import withRouterParams from './WithRouterParams';

class SetupRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match, splitState, splitFlags } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      splitState: nextSplitState,
      splitFlags: nextSplitFlags
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      splitState.isReady !== nextSplitState.isReady ||
      splitFlags.shouldRedirectToOnboard !==
        nextSplitFlags.shouldRedirectToOnboard;
    return shouldUpdate;
  }

  render() {
    const { splitFlags } = this.props;
    const { inviteToken } = this.props.matchedParams;
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email') ?? undefined;
    const tempPassword = searchParams.get('temp_password') ?? undefined;
    const errorMessage = searchParams.get('error_message') ?? undefined;
    const hasTeam = searchParams.get('has_team') ?? undefined;

    if (splitFlags.shouldRedirectToOnboard) {
      return (
        <OnboardModalContainer
          inviteToken={inviteToken}
          email={email}
          tempPassword={tempPassword}
          errorMessage={errorMessage}
          hasTeam={hasTeam}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  splitState: getSplitState(state),
  splitFlags: getSplitFlags(state),
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SetupRouter))
);
