import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { portal } from 'appUtils/portal';
import {
  StyledTaskColumnDraggable,
  StyledTaskMilestoneIcon,
  StyledDragGripIcon,
  LeftDropdownItemContent,
  RightDropdownItemContent,
  TaskColumnIconContainer
} from 'views/Home/TaskList/styles';

import NoteIcon from 'icons/NoteIcon';
import TaskIcon from 'icons/TaskIcon';
import UpdatesIcon from 'icons/UpdatesIcon';
import MembersIcon from 'icons/MembersIcon';
import ClientIcon from 'icons/ClientIcon';
import TaskStatusIcon from 'icons/TaskStatusIcon';
import PriorityIcon from 'icons/PriorityIcon';
import StageIcon from 'icons/StageIcon';
import ProjectNumberIcon from 'icons/ProjectNumberIcon';
class BoardColumnDraggable extends Component {
  getSortContent = (taskProperty) => {
    switch (taskProperty) {
      case 'tasks':
        return {
          text: 'Progress',
          icon: <TaskIcon height="13" width="13" color="#808080" />
        };
      case 'remaining_tasks':
        return {
          text: 'Tasks',
          icon: <TaskIcon height="13" width="13" color="#808080" />
        };
      case 'notes':
        return {
          text: 'Notes',
          icon: <NoteIcon height="13" width="13" color="#808080" />
        };
      case 'client':
        return {
          text: 'Client',
          icon: <ClientIcon height="14" width="14" />
        };
      case 'updates':
        return {
          text: 'Updates',
          icon: <UpdatesIcon height="14" width="18" />
        };
      case 'number':
        return {
          text: 'Project ID',
          icon: <ProjectNumberIcon />
        };
      case 'members':
        return {
          text: 'Members',
          icon: <MembersIcon height="14" width="14" />
        };
      case 'status':
        return {
          text: 'Status',
          icon: <TaskStatusIcon />
        };
      case 'stage':
        return {
          text: 'Stage',
          icon: <StageIcon height="14" width="18" />
        };
      case 'priority':
        return {
          text: 'Priority',
          icon: <PriorityIcon />
        };
      default:
        return {
          text: '',
          icon: ''
        };
    }
  };

  handleClick = (e) => {
    const { handleClick, index } = this.props;
    handleClick(index);
  };

  render() {
    const { index, column, provided, isActive, snapshot = {} } = this.props;
    const { icon, text } = this.getSortContent(column);
    const usePortal = snapshot.isDragging;
    const child = (
      <StyledTaskColumnDraggable
        index={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isActive={isActive}
        onClick={this.handleClick}
      >
        <LeftDropdownItemContent>
          <StyledDragGripIcon />
          <TaskColumnIconContainer>{icon}</TaskColumnIconContainer>
          <div className="task-column-text">{text}</div>
        </LeftDropdownItemContent>
        <RightDropdownItemContent isActive={isActive}>
          {isActive ? (
            <div data-testid={`hide-column-${text}`}>Hide</div>
          ) : (
            <div data-testid={`show-column-${text}`}>Show</div>
          )}
        </RightDropdownItemContent>
      </StyledTaskColumnDraggable>
    );
    return usePortal ? ReactDOM.createPortal(child, portal) : child;
  }
}

export default BoardColumnDraggable;
