import React from 'react';
import { connect } from 'react-redux';
import {
  makeGetActivityByDescriptionId,
  getFlickerActivityCellByProjectId,
  makeGetProjectBasicInfoById
} from 'selectors';
import { makeGetOwnPhase } from 'BudgetModule/selectors';
import { TimesheetStyledCell } from './../styles';
import { updateDescription } from 'actionCreators';
import TimesheetActivity from '.';
class WeeklyTimesheetActivity extends React.Component {
  handleSelect = (item) => {
    const {
      updateDescription,
      date,
      phaseId,
      title,
      id,
      projectId,
      accountId,
      isTimesheetsDisabled
    } = this.props;
    if (isTimesheetsDisabled) {
      return;
    }
    updateDescription({
      date,
      activityId: item.id,
      phaseId,
      projectId,
      accountId,
      title,
      id
    });
  };

  render() {
    const {
      activity,
      phase,
      projectId,
      project,
      flickerActivity,
      id,
      isActivityDropdownOpen,
      onActivityDropdownClose
    } = this.props;
    const isRed = flickerActivity[id];
    return (
      <TimesheetActivity
        ContainerEl={TimesheetStyledCell}
        handleSelect={this.handleSelect}
        onActivityDropdownClose={onActivityDropdownClose}
        isRed={isRed}
        activity={activity}
        projectId={projectId}
        project={project}
        phase={phase}
        isActivityDropdownOpen={isActivityDropdownOpen}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getActivity = makeGetActivityByDescriptionId();
  const getProjectInfo = makeGetProjectBasicInfoById();
  const getPhase = makeGetOwnPhase();
  const mapStateToProps = (state, ownProps) => ({
    project: getProjectInfo(state, ownProps),
    activity: getActivity(state, ownProps),
    phase: getPhase(state, ownProps),
    flickerActivity: getFlickerActivityCellByProjectId(state)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  updateDescription
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WeeklyTimesheetActivity);
