import { Draft, createReducer } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import {
  fetchLocationsActionCreatorsMap,
  fetchEntityLocationsActionCreatorsMap
} from 'SettingsModule/actionCreators/settings/location';
import * as constants from 'SettingsModule/constants/location';
import {
  EntityLocation,
  TeamMemberLocationMembership,
  RealEntityLocationType
} from 'SettingsModule/models/location';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { ValueOf } from 'type-fest';

type EntityTypes = ValueOf<typeof ENTITY_TYPES>;
type EntityId = number;
type LocationIdOfEntityLocation = number;

export interface LocationsState {
  teamMembersLocationHash: Record<number, TeamMemberLocationMembership>;
  locationsHash: Record<number, EntityLocation>;
  entityLocationsByEntityTypeHash: Partial<
    Record<
      EntityTypes,
      Record<
        EntityId,
        Record<LocationIdOfEntityLocation, RealEntityLocationType>
      >
    >
  >;
}

const initialState: LocationsState = {
  teamMembersLocationHash: {},
  locationsHash: {},
  entityLocationsByEntityTypeHash: {}
};

const handleFetchLocationsByTeamMembershipSuccess = (
  state: Draft<LocationsState>,
  action
) => {
  const { response } = action.payload as {
    response: FetchLocationsByTeamMembershipSuccessResponse;
  };

  state.teamMembersLocationHash = keyBy(
    response.team_memberships,
    (membership) => membership.account_id
  );
};

const handleFetchLocationsSuccess = (
  state: Draft<LocationsState>,
  action: ReturnType<typeof fetchLocationsActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.locationsHash = keyBy(response, (location) => location.id);
};

const handleFetchEntityLocationsSuccess = (
  state: Draft<LocationsState>,
  action: ReturnType<typeof fetchEntityLocationsActionCreatorsMap.success>
) => {
  const {
    initialPayload: { entityId, entityType },
    response
  } = action.payload;

  if (!state.entityLocationsByEntityTypeHash[entityType]) {
    state.entityLocationsByEntityTypeHash[entityType] = {};
  }

  const entityLocationsByEntityType =
    state.entityLocationsByEntityTypeHash[entityType];

  if (entityLocationsByEntityType && !entityLocationsByEntityType[entityId]) {
    entityLocationsByEntityType[entityId] = {};
  }

  const entityLocationsForEntity =
    state.entityLocationsByEntityTypeHash[entityType]?.[entityId];

  if (entityLocationsForEntity) {
    response.entity_locations.forEach(
      (entityLocation) =>
        (entityLocationsForEntity[entityLocation.location_id] = entityLocation)
    );
  }
};

export const locationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    constants.FETCH_LOCATIONS_BY_TEAM_MEMBERSHIP.SUCCESS,
    handleFetchLocationsByTeamMembershipSuccess
  );
  builder.addCase(
    fetchLocationsActionCreatorsMap.success,
    handleFetchLocationsSuccess
  );
  builder.addCase(
    fetchEntityLocationsActionCreatorsMap.success,
    handleFetchEntityLocationsSuccess
  );
});

interface FetchLocationsByTeamMembershipSuccessResponse {
  team_memberships: TeamMemberLocationMembership[];
}
