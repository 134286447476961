import { useCallback, useEffect, useMemo, useState } from 'react';
import { getHomeTaskObj } from 'selectors';
import { fetchTasksV2 } from 'actionCreators';
import { WorkPlanId } from '../models/workPlan';
import { Task, TaskId } from 'models/task';
import produce from 'immer';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getWorkPlanTaskHours } from 'TaskModule/selectors';

interface UseWorkPlanTasksProps {
  taskOrder: Array<TaskId>;
  workplanId?: WorkPlanId;
}

export const useWorkPlanTasks = ({
  taskOrder: initialTaskOrder,
  workplanId
}: UseWorkPlanTasksProps) => {
  const dispatch = useAppDispatch();
  const [taskOrder, setTaskOrder] = useState(initialTaskOrder);

  const taskHash = useAppSelector(getHomeTaskObj) as Record<TaskId, Task>;

  useEffect(() => {
    setTaskOrder(initialTaskOrder);
  }, [initialTaskOrder]);

  const tasks = useMemo(() => {
    if (!taskOrder) return [];

    return taskOrder
      .flatMap((taskId) => {
        const task = taskHash[taskId];
        return task?.description ? [task] : [];
      })
      .sort((a, b) => {
        if (!a.completed_at === !b.completed_at) {
          return 0;
        } else if (!b.completed_at) {
          return 1;
        }
        return -1;
      });
  }, [taskHash, taskOrder]);

  // currently it fetches tasks by workplan schedule instead of task orders
  const getTasks = useCallback(
    ({ taskOrder }: { taskOrder: Array<number> }) => {
      dispatch(
        fetchTasksV2({
          body: {
            all: true,
            // Fetches the task hours.
            activity_phase_schedule_bar_id: workplanId,
            task_ids: taskOrder
          }
        })
      );
    },
    [dispatch, workplanId]
  );

  // React dependencies are reference-based for arrays, such as
  // `initialTaskOrder`. The tasks need only be fetched if the set of tasks
  // changes; a change in array reference or order are not important. By sorting
  // the IDs and converting the array into a string, both problmes are avoided.
  const initialTaskOrderString = JSON.stringify(
    initialTaskOrder.slice().sort()
  );
  useEffect(() => {
    if (initialTaskOrder.length) {
      getTasks({ taskOrder: initialTaskOrder });
    }
    // See comment above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTasks, initialTaskOrderString]);

  const handleUpdateTaskIds = (taskIds: Array<TaskId>) => {
    setTaskOrder(taskIds);
  };

  /* ------------------------------ task hours ------------------------------ */

  const [tasksHours, setTasksHours] = useState<
    Map<TaskId, { hours: Nullable<number>; isModified?: boolean }>
  >(new Map());
  const workPlanTaskHours = useAppSelector(getWorkPlanTaskHours);

  // Update the task hours entries when task order or initial task hours change.
  useEffect(() => {
    setTasksHours((prev) =>
      produce(prev, (draft) => {
        const taskOrderSet = new Set(taskOrder);
        const hoursByTask = workplanId
          ? workPlanTaskHours?.[workplanId] ?? {}
          : {};

        draft.forEach(({ isModified }, taskId, tasksHours) => {
          if (!taskOrderSet.has(taskId)) {
            // Remove task hours for tasks that were removed.
            tasksHours.delete(taskId);
          } else {
            // Remove tasks that already have entries from the list of tasks
            // left to process.
            taskOrderSet.delete(taskId);

            // Update the entry if it was not previously modified with the
            // current initial value.
            if (!isModified) {
              const hours = hoursByTask[taskId] ?? null;
              tasksHours.set(taskId, { hours });
            }
          }
        });

        // Add entries for tasks that do not have one.
        taskOrderSet.forEach((taskId) => {
          const hours = hoursByTask[taskId] ?? null;
          draft.set(taskId, { hours });
        });
      })
    );
  }, [taskHash, taskOrder, workPlanTaskHours, workplanId]);

  const handleUpdateTaskHours = useCallback(
    (updatedTaskHours: Map<TaskId, Nullable<number>>) =>
      setTasksHours((prev) =>
        produce(prev, (draft) => {
          updatedTaskHours.forEach((hours, taskId) => {
            draft.set(taskId, {
              hours,
              isModified:
                !workplanId ||
                workPlanTaskHours?.[workplanId]?.[taskId] !== hours
            });
          });
        })
      ),
    [workPlanTaskHours, workplanId]
  );

  return {
    tasksHours,
    tasks,
    taskOrder,
    onUpdateTaskIds: handleUpdateTaskIds,
    onUpdateTaskHours: handleUpdateTaskHours
  };
};
