import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ExclamationMarkInsideCloudIcon = ({
  width = '28',
  height = '28',
  fill = '#eb5757',
  className
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0Z"
      fill={fill}
    />
    <path
      d="M19.6 8H8.4C7.63 8 7.00699 8.63 7.00699 9.4L7 22L9.8 19.2H19.6C20.37 19.2 21 18.57 21 17.8V9.4C21 8.63 20.37 8 19.6 8ZM14.7 14.3H13.3V10.1H14.7V14.3ZM14.7 17.1H13.3V15.7H14.7V17.1Z"
      fill="white"
    />
  </SvgIcon>
);

export default ExclamationMarkInsideCloudIcon;
