import integrities from './integrities.json';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { IGNORED_ERROR_ACTIONS_MESSAGES } from 'appConstants/ignoredErrors';
import { configureLogRocketOnSentry } from 'thirdPartyModuleConfigs/logRocketConfig';

const loadSentry = () => {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://browser.sentry-cdn.com/6.18.1/bundle.min.js';
  script.integrity = integrities.sentry;
  script.crossOrigin = 'anonymous';
  body.appendChild(script);
};

const initAndConfigureSentry = () => {
  if (!window.location.href.includes('localhost')) {
    Sentry.init({
      environment: process.env.UI_ENV,
      dsn: process.env.SENTRY_DSN,
      ignoreErrors: IGNORED_ERROR_ACTIONS_MESSAGES,
      normalizeDepth: 4,
      release: process.env.UI_ENV + process.env.npm_package_version,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: process.env.SENTRY_TRACE_LEVEL,
      beforeSend(event, hint) {
        const { name, email, isUserFeedback } = hint.captureContext || {
          name: 'Mosaic User',
          email: 'Not found',
          isUserFeedback: false
        };
        if (isUserFeedback) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              name,
              email
            },
            labelSubmit: 'Submit Report'
          });
        }
        return event;
      }
    });
    Sentry.configureScope((scope) => {
      scope.addEventProcessor(async (event) => {
        return event;
      });

      configureLogRocketOnSentry(scope);
    });
  }
};

export const initSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    loadSentry();
    initAndConfigureSentry();
  }
};
