import React from 'react';
import range from 'lodash/range';
import styled from 'styled-components';
import theme from 'theme';

const AxisLabel = styled.div`
  text-align: center;
  color: ${theme.colors.colorCalendarGray};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UtilBarHeader = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => '1fr '.repeat(props.numDivisions + 1)};
  align-items: center;
  width: ${(props) => `calc(100% + 100% / ${props.numDivisions})`};
  margin-left: ${(props) => `calc(100% / ${props.numDivisions} / -2)`};
`;

const NUM_DIVISIONS = 13;

const formatHours = (value) => {
  let amount = Math.round(value);
  let suffix = '';
  if (value > 999) {
    const numDecimalsToRemove = Math.floor(Math.log10(value) / 3) * 3;
    amount = (value / Math.pow(10, numDecimalsToRemove))
      .toFixed(1)
      .replace('.0', '');
    suffix = 'k';
  }
  if (value > 999999) {
    suffix = 'm';
  }
  return `${amount}${suffix}`;
};

const GraphHeaderCell = ({ rows }) => {
  if (!rows[0]) return null;

  const row = rows[0].original;
  const maxHours = row.maxHours;

  if (!maxHours) return null;

  const values = range(0, maxHours + 1, maxHours / NUM_DIVISIONS).map((num) =>
    Math.round(num)
  );

  return (
    <UtilBarHeader data-testid="util-bar-header" numDivisions={NUM_DIVISIONS}>
      {values.map((value, index) => (
        <AxisLabel
          key={index}
          data-testid={`util-bar-header-axis-label-${index}`}
        >
          {formatHours(value)}h
        </AxisLabel>
      ))}
    </UtilBarHeader>
  );
};
export default GraphHeaderCell;
