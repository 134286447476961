import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getProfitReportModule } from 'ProfitReportModule/package/profitReportModule';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import { SectionRoutes } from 'appConstants/navigation';

const ProfitReportTableContainer = lazy(() =>
  import('./ProfitReportTableContainer')
);

const ProfitReportTab = ({ isDashboardWidgetModal, widgetType }) => {
  const { filterId, viewBy, pageName, activeFilter, widget, filterSections } =
    useReportsViewFilter({
      ...(isDashboardWidgetModal && {
        matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType/:reportViewType`,

        widgetType
      })
    });

  return (
    <DynamicModuleLoader modules={[getProfitReportModule()]}>
      <Suspense fallback={<div />}>
        <ProfitReportTableContainer
          activeFilter={activeFilter}
          viewBy={viewBy}
          pageName={pageName}
          filterSections={filterSections}
        />
      </Suspense>
    </DynamicModuleLoader>
  );
};

export default ProfitReportTab;
