import { put, select, call, all } from 'redux-saga/effects';
import { delay } from 'redux-saga'; // this import must be updated when upgrading redux-saga to v1
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import {
  clearProjectsMappingSteps,
  clearActivitiesMappingSteps,
  clearAccountsMappingSteps,
  clearClientsMappingSteps,
  clearBatchSelectedIntegrationActivities,
  clearBatchSelectedIntegrationAccounts,
  clearMovedIntegrationProjects,
  clearBatchSelectedIntegrationClients,
  fetchMappedMosaicAccountIds,
  fetchMappedMosaicClientIds,
  fetchMappedMosaicProjectIds,
  fetchMappedMosaicPhaseIds,
  fetchMappedMosaicActivityIds,
  refetchIntegrationProject,
  fetchIntegration,
  fetchIntegrations,
  fetchMosaicTimeEntryMappingStatusesByIntegration,
  fetchAutoLinkChildrenByPhaseName,
  fetchMappingStatuses,
  fetchMappingStatusesActionCreatorsMap
} from 'IntegrationsModule/actionCreators';
import { workspaceActionsCreatorsMap } from 'IntegrationsModule/actionCreators/workspace';
import {
  fetchPhasesByProjectIds,
  fetchActivities,
  navigateToIntegrations,
  handleErrorMessage,
  fetchTeams
} from 'actionCreators';
import { getIntegrationIds } from 'IntegrationsModule/selectors';
import * as constants from 'IntegrationsModule/constants';

import { getSplitFlags, getTeamSlug } from 'selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getAuthToken } from 'AuthenticationModule/selectors';

export function* initializeIntegrationWorker(action) {
  const { integrationId, integrationLabel, teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.initializeIntegration,
    api.initializeIntegration,
    teamId,
    [token, integrationId, integrationLabel],
    action
  );
}
export function* fetchIntegrationAccountsWorker(action) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchIntegrationAccounts,
    api.fetchIntegrationAccounts,
    teamId,
    [token, integrationId, body],
    action
  );
}
export function* mapAccountsWorker(action) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);
  for (const mappingPayload of body) {
    const { original, uuid, ...payload } = mappingPayload;
    yield changeEntity(
      entityActions.mapAccounts,
      api.mapAccounts,
      [token, teamId, integrationId, payload],
      action,
      { ...mappingPayload, integrationId, teamId }
    );
  }

  yield put(
    clearAccountsMappingSteps({
      integrationId
    })
  );
  yield put(
    clearBatchSelectedIntegrationAccounts({
      integrationId
    })
  );
  yield delay(6000);
  yield put(
    fetchMappedMosaicAccountIds({ integrationId, teamId, dataType: 'accounts' })
  );
}
export function* fetchMappedMosaicAccountIdsWorker(action) {
  const { integrationId, teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchMappedMosaicAccountIds,
    api.fetchMappedMosaicAccountIds,
    teamId,
    [token, integrationId],
    action
  );
}

export function* fetchIntegrationActivitiesWorker(action) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchIntegrationActivities,
    api.fetchIntegrationActivities,
    teamId,
    [token, integrationId, body],
    action
  );
}
export function* mapActivitiesWorker(action) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);
  for (const mappingPayload of body) {
    const { original, uuid, ...payload } = mappingPayload;
    yield changeEntity(
      entityActions.mapActivities,
      api.mapActivities,
      [token, teamId, integrationId, payload],
      action,
      { ...mappingPayload, integrationId, teamId }
    );
  }
  yield put(
    clearActivitiesMappingSteps({
      integrationId
    })
  );
  yield put(
    clearBatchSelectedIntegrationActivities({
      integrationId
    })
  );
  // activities dropdown uses selectedTeam activity orders
  yield put(fetchTeams());
  yield put(fetchActivities({}));
  yield delay(6000);
  yield put(
    fetchMappedMosaicActivityIds({
      integrationId,
      teamId,
      dataType: 'activities'
    })
  );
}
export function* fetchMappedMosaicActivityIdsWorker(action) {
  const { integrationId, teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchMappedMosaicActivityIds,
    api.fetchMappedMosaicActivityIds,
    teamId,
    [token, integrationId],
    action
  );
}

export function* fetchIntegrationClientsWorker(action) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchIntegrationClients,
    api.fetchIntegrationClients,
    teamId,
    [token, integrationId, body],
    action
  );
}
export function* mapClientsWorker(action) {
  const { integrationId, teamId, body, isDataTypeChange } = action.payload;
  const token = yield select(getAuthToken);
  for (const mappingPayload of body) {
    const { original, uuid, ...payload } = mappingPayload;
    yield changeEntity(
      entityActions.mapClients,
      api.mapClients,
      [token, teamId, integrationId, payload],
      action,
      { ...mappingPayload, integrationId, teamId, isDataTypeChange }
    );
  }

  yield put(
    clearClientsMappingSteps({
      integrationId
    })
  );
  yield put(
    clearBatchSelectedIntegrationClients({
      integrationId
    })
  );
  yield delay(6000);
  yield put(
    fetchMappedMosaicClientIds({ integrationId, teamId, dataType: 'clients' })
  );
}
export function* fetchMappedMosaicClientIdsWorker(action) {
  const { integrationId, teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchMappedMosaicClientIds,
    api.fetchMappedMosaicClientIds,
    teamId,
    [token, integrationId],
    action
  );
}

export function* fetchIntegrationProjectsWorker(action) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchIntegrationProjects,
    api.fetchIntegrationProjects,
    teamId,
    [token, integrationId, body],
    action
  );
}
export function* refetchIntegrationProjectWorker(action) {
  const { integrationId, teamId, uuid } = action.payload;
  const token = yield select(getAuthToken);
  const body = {
    filterAttributes: [
      {
        filterAttribute: 'uuid',
        filterValue: uuid
      }
    ]
  };
  yield fetchEntity(
    entityActions.refetchIntegrationProject,
    api.fetchIntegrationProjects,
    teamId,
    [token, integrationId, body],
    action
  );
}
export function* mapProjectsWorker(action) {
  const { integrationId, teamId, isUpdate, isDataTypeChange } = action.payload;
  const token = yield select(getAuthToken);
  const { body } = action.payload;
  for (const project of body) {
    const { uuid, ...payload } = project;
    yield changeEntity(
      entityActions.mapProjects,
      api.mapProjects,
      [token, teamId, integrationId, payload],
      action,
      { integrationId, teamId, isUpdate, isDataTypeChange, ...project }
    );
  }
  yield put(
    clearProjectsMappingSteps({
      integrationId
    })
  );

  yield delay(1500);
  yield put(
    clearMovedIntegrationProjects({
      integrationId
    })
  );

  yield delay(4100);
  yield put(
    fetchMappedMosaicProjectIds({
      integrationId,
      teamId,
      dataType: 'projects'
    })
  );
}
export function* mapAllPhasesWorker(action) {
  const { integrationId, teamId, projectUuid, body } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    entityActions.mapAllPhases,
    api.mapAllPhases,
    [token, teamId, integrationId, body],
    action
  );

  yield delay(6000);
  yield put(
    refetchIntegrationProject({
      integrationId,
      teamId,
      uuid: projectUuid
    })
  );
}
export function* fetchMappedMosaicProjectIdsWorker(action) {
  const { integrationId, teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchMappedMosaicProjectIds,
    api.fetchMappedMosaicProjectIds,
    teamId,
    [token, integrationId],
    action
  );
}

export function* mapPhasesWorker(action) {
  const {
    integrationId,
    teamId,
    body,
    projectUuid,
    projectId,
    mosaicProjectId,
    mappingStatus,
    isDataTypeChange
  } = action.payload;
  const token = yield select(getAuthToken);
  for (const mappingPayload of body) {
    const { original, ...payload } = mappingPayload;
    yield changeEntity(
      entityActions.mapPhases,
      api.mapPhases,
      [token, teamId, integrationId, payload],
      action,
      { ...mappingPayload, integrationId, teamId, projectId }
    );
  }
  yield delay(6000);
  // Fetch for newly created Mosaic phases
  if (mappingStatus === constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC) {
    yield put(fetchPhasesByProjectIds({ projectIds: [mosaicProjectId] }));
  }
  yield put(
    fetchMappedMosaicPhaseIds({ integrationId, teamId, dataType: 'phases' })
  );
  if (isDataTypeChange) {
    yield delay(10000);
  }

  yield put(
    refetchIntegrationProject({
      integrationId,
      teamId,
      uuid: projectUuid
    })
  );
}

export function* fetchMappedMosaicPhaseIdsWorker(action) {
  const { integrationId, teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchMappedMosaicPhaseIds,
    api.fetchMappedMosaicPhaseIds,
    teamId,
    [token, integrationId],
    action
  );
}

export function* mapAllActivitiesWorker(action) {
  const { integrationId, teamId, projectUuid, body } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    entityActions.mapAllActivities,
    api.mapAllActivities,
    [token, teamId, integrationId, body],
    action
  );

  yield delay(6000);
  yield put(
    refetchIntegrationProject({
      integrationId,
      teamId,
      uuid: projectUuid
    })
  );
}

export function* mapActivityPhasesWorker(action) {
  const { integrationId, teamId, body, projectId, projectUuid } =
    action.payload;
  const token = yield select(getAuthToken);
  for (const mappingPayload of body) {
    const { original, ...payload } = mappingPayload;
    yield changeEntity(
      entityActions.mapActivityPhases,
      api.mapActivityPhases,
      [token, teamId, integrationId, payload],
      action,
      { ...mappingPayload, integrationId, teamId, projectId }
    );
  }
  yield delay(6000);
  yield put(
    refetchIntegrationProject({
      integrationId,
      teamId,
      uuid: projectUuid
    })
  );
  yield put(fetchActivities({}));
}

export function* fetchMosaicTimeEntryMappingStatusesByIntegrationWorker(
  action
) {
  const { integrationId, teamId, body } = action.payload;
  const token = yield select(getAuthToken);
  yield fetchEntity(
    entityActions.fetchMosaicTimeEntryMappingStatusesByIntegration,
    api.fetchIntegrationTimeEntries,
    teamId,
    [token, integrationId, body],
    action
  );
}

export function* fetchMosaicTimeEntryMappingStatusesWorker(action) {
  const { timeEntryIds, teamId } = action.payload;
  const selectedTeamId = yield select(getSelectedTeamId);
  const integrationIds = yield select(getIntegrationIds);

  for (const integrationId of integrationIds) {
    yield put(
      fetchMosaicTimeEntryMappingStatusesByIntegration({
        integrationId,
        teamId: teamId || selectedTeamId,
        body: {
          filterAttributes: [
            { filterAttribute: 'mosaicId', filterValue: timeEntryIds },
            {
              filterAttribute: 'mappingStatus',
              filterValue: constants.ACTIVE_MAPPING_STATUSES
            }
          ],
          limit: timeEntryIds.length
        }
      })
    );
  }
}

/* ------------------------------------ - ----------------------------------- */

export function* fetchIntegrationsWorker(action) {
  const integration = 'quickbooks';
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const { shouldUseIntegrationsV2 } = yield select(getSplitFlags);
  const config = {
    teamId,
    integration
  };
  yield put(fetchIntegration({ integration, config }));

  if (shouldUseIntegrationsV2) {
    const { error } = yield fetchEntity(
      entityActions.fetchIntegrations,
      api.fetchIntegrationsV2,
      teamId,
      [token],
      action
    );
    yield fetchEntity(
      entityActions.fetchAllIntegrationDomains,
      api.fetchAllIntegrationDomains,
      teamId,
      [token],
      action
    );
    if (!error) {
      yield call(fetchMappedMosaicEntityIdsWorker);
    }
  }
}

export function* fetchIntegrationWorker(action) {
  const { integration, config } = action.payload;
  const token = yield select(getAuthToken);
  let i = 0;
  let success = false;
  while (i < 5 && !success) {
    const { error, response } = yield changeEntity(
      entityActions.fetchIntegration,
      api.fetchIntegrations[integration],
      [{ ...config, token }],
      action,
      action.payload
    );
    if (response && !error) {
      success = true;
    }
    i++;
    yield delay(400);
  }
}

export function* disconnectIntegrationWorker(action) {
  const { integration } = action.payload;
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.disconnectIntegration,
    api.deleteIntegration[integration],
    [teamId, token],
    action,
    action.payload
  );
  const config = {
    teamId,
    integration
  };
  yield put(fetchIntegration({ integration, config }));
}

export function* disconnectIntegrationV2Worker(action) {
  const { targetServiceId } = action.payload;
  const teamId = yield select(getSelectedTeamId);
  const token = yield select(getAuthToken);
  const teamSlug = yield select(getTeamSlug);

  yield put(navigateToIntegrations({ teamSlug }));
  const { error } = yield changeEntity(
    entityActions.disconnectIntegrationV2,
    api.disconnectIntegrationV2,
    [teamId, token, targetServiceId],
    action,
    action.payload
  );
  // integration is removed from redux on trigger. re-fetch on failure
  if (error) {
    yield put(fetchIntegrations({}));
  }
}

export function* fetchIntegrationAuthStatusWorker(action) {
  const { targetServiceId, teamId, targetService } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchIntegrationAuthStatus,
    api.fetchIntegrationAuthStatus,
    teamId,
    [token, targetServiceId, targetService],
    action
  );
}

export function* provisionIntegrationWorker(action) {
  const {
    onSuccess = [],
    redirectToIntegrationOnSuccess = true,
    shouldFetchIntegrationsOnSuccessProvision = true,
    ...body
  } = action.payload;
  const token = yield select(getAuthToken);
  const teamSlug = yield select(getTeamSlug);

  const { error, response } = yield changeEntity(
    entityActions.provisionIntegration,
    api.provisionIntegration,
    [token, body],
    action,
    action.payload
  );

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
    if (shouldFetchIntegrationsOnSuccessProvision) {
      yield put(fetchIntegrations({}));
    }
    if (redirectToIntegrationOnSuccess && response.targetServiceId) {
      yield put(
        navigateToIntegrations({
          teamSlug,
          integrationId: response.targetServiceId
        })
      );
    }
  } else {
    if (response?.status >= 400) {
      console.error(
        'Provision Failed with following body, response and errors',
        {
          error,
          response,
          body
        }
      );
    }
    yield put(
      handleErrorMessage({
        type: action.type,
        errorMessage:
          'Failed to connect to integration. Please check your credentials and try again.'
      })
    );
  }
}

export function* updateIntegrationConfigWorker(action) {
  const { targetServiceId, body } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateIntegrationConfig,
    api.updateIntegrationConfig,
    [token, targetServiceId, body],
    action,
    action.payload
  );
}

export function* fetchMappedMosaicEntityIdsWorker() {
  yield put(fetchMappingStatuses({ dataType: constants.DATA_TYPES.EMPLOYEES }));
  yield put(fetchMappingStatuses({ dataType: constants.DATA_TYPES.PROJECTS }));
}

export function* fetchMappingStatusesWorker(
  action: ReturnType<typeof fetchMappingStatuses>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  const params = {
    ...action.payload,
    teamId
  };

  yield fetchEntity(
    fetchMappingStatusesActionCreatorsMap,
    api.fetchMappingStatuses,
    null,
    [token, params],
    action
  );
}

export function* fetchIntegrationEntityStatsWorker(action) {
  const { integrationId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchIntegrationEntityStats,
    api.fetchIntegrationEntityStats,
    token,
    [integrationId],
    action
  );
}

export function* fetchIntegrationSettingsSchemaWorker(action) {
  const { targetServiceId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchIntegrationSettingsSchema,
    api.fetchIntegrationSettingsSchema,
    token,
    [targetServiceId],
    action
  );
}

export function* fetchAutoLinkPhasesWorker(action) {
  const { targetServiceId, excludedPhases } = action.payload;
  const token = yield select(getAuthToken);
  const { response, error } = yield fetchEntity(
    entityActions.fetchAutoLinkPhases,
    api.fetchAutoLinkPhases,
    token,
    [targetServiceId],
    action
  );

  if (!error) {
    if (excludedPhases) {
      let i = 0;
      while (i < excludedPhases.length) {
        yield put(
          fetchAutoLinkChildrenByPhaseName({
            targetServiceId,
            parentPhaseName: excludedPhases[i]
          })
        );
        i++;
      }
    }
  }
}

export function* fetchAutoLinkChildrenByPhaseNameWorker(action) {
  const { targetServiceId, parentPhaseName } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    entityActions.fetchAutoLinkChildrenByPhaseName,
    api.fetchAutoLinkChildrenByPhaseName,
    token,
    [targetServiceId, parentPhaseName],
    action
  );
}

export function* fetchSyncLogWorker(action) {
  const {
    integrationId,
    dataType,
    offset,
    limit,
    dateAfter,
    dateBefore,
    account_ids,
    project_ids,
    // phase_names,
    activity_ids
  } = action.payload;
  const token = yield select(getAuthToken);

  // IS filter requirements
  // If want to filter itself by data type (i.e: On project tab, want to filter by project), change the routingField key to mosaicId
  // Currently for accountId and projectId
  const accountFilter =
    dataType === constants.DATA_TYPES.EMPLOYEES
      ? { mosaicId: account_ids }
      : { accountId: account_ids };
  const projectFilter =
    dataType === constants.DATA_TYPES.PROJECTS
      ? { mosaicId: project_ids }
      : { projectId: project_ids };

  const body = {
    dataType,
    offset,
    limit,
    dateAfter, // start
    dateBefore, // end
    routingFields: {
      ...accountFilter,
      ...projectFilter,
      activityId: activity_ids
    }
  };

  yield changeEntity(
    entityActions.fetchSyncLog,
    api.fetchSyncLog,
    [token, integrationId, body],
    action,
    action.payload
  );
}

export function* fetchWorkspacesWorker(action) {
  const { apiKey, endpointPath } = action.payload;
  const { onSuccess, onFailure } = action.meta;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    workspaceActionsCreatorsMap.fetchWorkspaces,
    api.fetchWorkspaces,
    null,
    [token, { apiKey, endpointPath }],
    action
  );

  if (!error) {
    onSuccess && onSuccess({ error, response });
  } else {
    onFailure && onFailure({ error, response });
  }
}
