import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ClientIcon = ({ height = '12', width = '11' }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 11 12">
      <rect
        x="1.8562"
        y="2.31328"
        width="1.54494"
        height="0.512194"
        stroke="#9b9b9b"
        strokeWidth="0.512194"
        fill="transparent"
      />
      <rect
        x="4.48512"
        y="2.31328"
        width="1.54494"
        height="0.512194"
        stroke="#9b9b9b"
        strokeWidth="0.512194"
        fill="transparent"
      />
      <rect
        x="1.8562"
        y="4.64633"
        width="1.54494"
        height="0.512194"
        stroke="#9b9b9b"
        strokeWidth="0.512194"
        fill="transparent"
      />
      <rect
        x="1.8562"
        y="6.98778"
        width="1.54494"
        height="0.512194"
        stroke="#9b9b9b"
        strokeWidth="0.512194"
        fill="transparent"
      />
      <path
        d="M7.5 3L7.5 0.5H0.5L0.5 9.5H4"
        stroke="#9b9b9b"
        fill="transparent"
      />
      <path
        d="M4.5 11.5C4.5 11.5 4.5 8.5 7.5 8.5C10.5 8.5 10.5 11.5 10.5 11.5"
        stroke="#9b9b9b"
        fill="transparent"
      />
      <circle cx="7.5" cy="5.5" r="1.5" stroke="#9b9b9b" fill="transparent" />
    </SvgIcon>
  );
};

export default ClientIcon;
