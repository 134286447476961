import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  closeWorkloadEventsModal,
  fetchWorkloadPlannerEvents,
  fetchWorkloadPlannerEvent,
  createWorkloadPlannerEvent,
  setSelectedWorkloadEvent
} from 'actionCreators';
import { fetchCapacities } from 'CapacityModule/actionCreators';
import {
  getSelectedTeamId,
  getWorkloadEventsSelectedEventId,
  getWorkloadEventsSelectedEvent,
  getWorkloadEventsModalIsOpen,
  getMe,
  getWorkloadEventIsHomeWorkplan
} from 'selectors';
import WorkloadEventDetail from './WorkloadEventDetail';
import CheckinEventDetail from './CheckinEventDetail';
import moment from 'appUtils/momentConfig';
import ConfirmEventModal from './ConfirmEventModal';
import { SubmitButton, TextButton, ButtonContainer } from './styles';
import { buildAccessIdentifier } from 'appUtils/access';
import SentNotificationHeader from './SentNotificationHeader';
import HeaderTooltipIcon from 'icons/HeaderTooltipIcon';
import ArrowUpIcon from 'icons/ArrowUpIcon';
import ReactTooltip from 'react-tooltip';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';

const StyledModalHeader = styled(ModalHeader)`
  .modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    font-size: 20px;
    font-weight: 600;
    color: #4f4f4f;
  }
  border: none;
  padding: 35px 46px 5px 46px;
  cursor: default;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 174px;
  font-size: 18px;
`;

const HelpIcon = styled(HeaderTooltipIcon)`
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
  svg {
    fill: #f3f3f3;
  }
`;

const RightArrowIcon = styled(ArrowUpIcon)`
  position: absolute;
  right: 4px;

  path {
    stroke: #fff;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }
`;

const ConfirmButton = styled(SubmitButton)`
  font-size: 13px;
  span {
    position: relative;
    margin-right: 10px;
    top: -1px;
  }
`;

const SubHeaderText = styled.span`
  position: absolute;
  width: max-content;
  top: 25px;
  left: 0;
  color: ${theme.colors.colorMediumGray10};
  font-size: 11px;
  font-weight: 400;
`;

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

const tooltipContent = (selectedEvent) =>
  selectedEvent?.event_type === 'checkin'
    ? `
<div class="workplan-events-tooltip">
Check-ins are high-level updates to the team of what you are working on today.
</div>
`
    : `
  <div  class="workplan-events-tooltip" >
    Work Plans appear on each member's home screen and are sent through Mosaic’s Notification system to the member's email address.
  </div>
`;

const WorkloadEventsModal = ({ selectionDisabled }) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const me = useSelector(getMe);
  const selectedEventId = useSelector(getWorkloadEventsSelectedEventId);
  const selectedEvent = useSelector(getWorkloadEventsSelectedEvent);
  const setSelectedEvent = (eventId) => {
    dispatch(setSelectedWorkloadEvent({ eventId }));
  };
  const isOpen = useSelector(getWorkloadEventsModalIsOpen);
  const workloadRangeStart = useSelector(
    (state) => state.workloadEvents.workloadRangeStart
  );
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const isHomeWorkplan = useSelector(getWorkloadEventIsHomeWorkplan);

  const [closing, setClosing] = useState(false);

  const handleClose = () => {
    setClosing(true);
  };

  const handleClosed = () => {
    // only handle if explicit closed
    if (closing) {
      dispatch(closeWorkloadEventsModal());
      setSelectedEvent('new');
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setClosing(false);
      ReactTooltip.hide();
    }
  }, [isOpen]);

  const handleEventSelect = (id) => {
    setSelectedEvent(id);
    if (id !== 'new') {
      dispatch(
        fetchWorkloadPlannerEvent({
          event_id: id
        })
      );
    }
  };

  const createEvent = () => {
    dispatch(
      createWorkloadPlannerEvent({
        send_time: moment().add(10, 'minutes').format('YYYY-MM-DD'),
        recipient_account_ids: isHomeWorkplan
          ? [me?.account_id]
          : selectedEvent?.recipient_account_ids,
        follower_ids: selectedEvent?.metadata?.follower_ids,
        date_range: formatFetchDate(workloadRangeStart),
        team_id: teamId,
        sendNow: true
      })
    );
  };

  const updateEvent = (id) => {
    console.log('id');
  };
  // use for update as well
  const sendEvent = (eventId) => {
    if (eventId === 'new') {
      createEvent();
    } else {
      createEvent();
      updateEvent(eventId);
    }
    setSelectedEvent(null);
  };

  const onEventConfirm = () => {
    sendEvent(selectedEventId);
    dispatch(closeWorkloadEventsModal());
    setSelectedEvent('new');
    setConfirmModalIsOpen(false);
  };

  const onEventCancel = () => {
    setConfirmModalIsOpen(false);
    if (isHomeWorkplan) {
      dispatch(closeWorkloadEventsModal());
    }
  };

  useEffect(() => {
    if (teamId) {
      dispatch(fetchWorkloadPlannerEvents({ team_id: teamId }));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (selectedEventId && selectedEventId !== 'new' && isOpen)
      dispatch(
        fetchWorkloadPlannerEvent({
          event_id: selectedEventId
        })
      );
  }, [dispatch, isOpen, selectedEventId]);

  useEffect(() => {
    if (selectedEvent?.recipient_account_ids && teamId) {
      dispatch(
        fetchCapacities({
          accountIds: selectedEvent.recipient_account_ids,
          teamId
        })
      );
    }
  }, [dispatch, selectedEvent, teamId]);

  const detailHeader = (
    <ButtonContainer>
      <TextButton onClick={handleClose}>Cancel</TextButton>
      <ConfirmButton onClick={() => setConfirmModalIsOpen(true)}>
        <span>Confirm </span>
        <RightArrowIcon height={14} width={14} />
      </ConfirmButton>
    </ButtonContainer>
  );

  const accessIdentifier = buildAccessIdentifier({
    actableType: 'Team',
    actableId: teamId,
    actionType: 'activity_phase_schedule_bars_other'
  });

  return (
    <DynamicModuleLoader modules={[getCapacitiesModule()]}>
      {(isOpen || closing) && !isHomeWorkplan ? (
        <Modal
          isOpen={isOpen && !confirmModalIsOpen && !closing}
          toggle={handleClose}
          onClosed={handleClosed}
          className={'workload-events-modal'}
        >
          <StyledModalHeader>
            <HeaderTitle
              data-for="app-tooltip"
              data-tip={tooltipContent(selectedEvent)}
              data-html
            >
              {selectionDisabled ? (
                <SentNotificationHeader />
              ) : (
                <div style={{ position: 'relative' }}>
                  <span style={{ color: `${theme.colors.colorPureBlack}` }}>
                    Send Work Plans
                  </span>
                  <SubHeaderText>Check boxes below</SubHeaderText>
                </div>
              )}
              <div>
                <HelpIcon />
              </div>
            </HeaderTitle>
            {selectedEvent && !selectionDisabled && detailHeader}
          </StyledModalHeader>
          <ModalBody>
            {selectedEvent &&
              (selectedEvent.event_type === 'checkin' ? (
                <CheckinEventDetail
                  selectedEvent={selectedEvent}
                  selectionDisabled={selectionDisabled}
                />
              ) : (
                <WorkloadEventDetail
                  selectedEvent={selectedEvent}
                  selectionDisabled={selectionDisabled}
                />
              ))}

            {/* --------- Future scope
{selectedEvent ? (
<WorkloadEventDetail selectedEvent={selectedEvent} />
) : (
<EventList events={events} handleClick={handleEventSelect} />
)} */}
          </ModalBody>
        </Modal>
      ) : null}
      <ConfirmEventModal
        selectedEvent={selectedEvent}
        isOpen={confirmModalIsOpen || isHomeWorkplan}
        isHomeWorkplan={isHomeWorkplan}
        onConfirm={onEventConfirm}
        onCancel={onEventCancel}
        recipientIds={
          isHomeWorkplan ? [] : selectedEvent?.recipient_account_ids ?? []
        }
        followerIds={selectedEvent?.metadata?.follower_ids ?? []}
        accessIdentifier={accessIdentifier}
        start={workloadRangeStart}
        end={moment(workloadRangeStart).clone().endOf('week')}
        modalTitle="Send Work Plan"
        cancelButtonText="Cancel"
      />
    </DynamicModuleLoader>
  );
};

export default WorkloadEventsModal;
