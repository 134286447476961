import {
  Sections,
  Views,
  Modals,
  VIEW_TYPE,
  HOME_VIEW_TYPE,
  SEARCH_WHITE_LIST
} from 'appConstants/navigation';

// takes navigationHistory object passed from <Route /> component or withRouter
// navigationHistory is an array of matched navigationHistory, with the last element being
// the most specific/final matched route

const emptyObject = {};

/**
 * @deprecated Use the typesafe version from `appCore/navigation/selectors`.
 */
const getNavigationState = (navigationHistory, index = 0) =>
  (navigationHistory && navigationHistory[index] && navigationHistory[index]) ||
  emptyObject;

export const isOnTeamsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.TEAMS;
};
export const isOnProjectView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.PROJECT;
};

/**
 * @deprecated Use the typesafe version from `appCore/navigation/selectors`.
 */
export const isOnTeamSettingsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.TEAM_SETTINGS;
};
export const isOnMembersSettingsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  const isOnTeamSettings = isOnTeamSettingsView(navigationHistory, index);
  return isOnTeamSettings && navigationState.view === Views.MEMBERS;
};
export const isOnStandardSettingsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  const isOnTeamSettings = isOnTeamSettingsView(navigationHistory, index);
  return isOnTeamSettings && navigationState.view === Views.STANDARDS;
};

export const isOnIntegrationsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.view === Sections.INTEGRATIONS;
};
export const isOnTeamProjectsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.TEAMS &&
    navigationState.view === Views.PROJECTS
  );
};
export const isOnTeamBudgetView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.TEAMS &&
    navigationState.view === Views.BUDGET
  );
};
export const isOnTeamTimelineView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.TEAMS &&
    navigationState.view === Views.TIMELINE
  );
};
export const isOnProjectsTasksView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.PROJECT &&
    navigationState.view === Views.TASKS
  );
};
export const isOnProjectsBudgetView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.PROJECT &&
    navigationState.view === Views.BUDGET
  );
};
export const isOnProjectsNotesView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.PROJECT &&
    navigationState.view === Views.NOTES
  );
};
export const isOnProjectsPlannerView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.PROJECT &&
    navigationState.view === Views.PLANNER
  );
};
export const isOnHomePlanner = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.HOME_PLANNER;
};
export const isOnHomeTasks = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.HOME &&
    navigationState.view === Views.TASKS
  );
};
export const isOnHomeNotifications = (
  isOnHomeView,
  matchedParams,
  index = 0
) => {
  return (
    isOnHomeView &&
    matchedParams[VIEW_TYPE.HOME] === HOME_VIEW_TYPE.NOTIFICATIONS
  );
};
export const isOnHomeCompletedTasks = (
  isOnHomeView,
  matchedParams,
  index = 0
) => {
  return (
    isOnHomeView && matchedParams[VIEW_TYPE.HOME] === HOME_VIEW_TYPE.COMPLETED
  );
};
export const isOnProjectPlanner = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.MEMBERS &&
    navigationState.view === Views.PROJECT_PLANNER
  );
};
export const isOnTeamTaskPlanner = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.MEMBERS &&
    navigationState.view === Views.TASK_PLANNER
  );
};
export const isOnMembersView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.MEMBERS;
};

export const isOnWorkloadView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.WORKLOAD;
};

export const isOnScheduleView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.SCHEDULE;
};

export const isOnReportsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.REPORTS;
};

export const isOnReportsCapacityView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.REPORTS &&
    navigationState.view === Views.DEMAND
  );
};

export const isOnDashboardView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.DASHBOARD;
};

export const isOnTeamMembersTasksView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.MEMBERS &&
    navigationState.view === Views.TASKS
  );
};

export const isOnTeamMembersTimesheetView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.MEMBERS &&
    navigationState.view === Views.TIMESHEET
  );
};

export const isOnTeamMembersProfileView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.MEMBERS &&
    (navigationState.view === Views.TIMESHEET ||
      navigationState.view === Views.TASKS)
  );
};

export const isOnUpdatesView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.UPDATES;
};

export const isOnSettingsView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.SETTINGS;
};

export const isOnMembersSettings = (
  isOnSettingsView,
  matchedParams,
  index = 0
) => {
  return (
    isOnSettingsView &&
    matchedParams.viewType === 'members' &&
    matchedParams[VIEW_TYPE.TEAM_SETTINGS] === 'members'
  );
};

export const isOnHomeView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.section === Sections.HOME;
};

export const isOnOwnTimesheets = (isOnHomeView, matchedParams, index = 0) => {
  return isOnHomeView && matchedParams[VIEW_TYPE.HOME] === HOME_VIEW_TYPE.TIME;
};

export const isTaskModalOpen = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return navigationState.modal === Modals.TASK;
};

export const isMemberModalOpen = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.modal === Modals.MEMBER ||
    (navigationState.modal === Modals.TASK &&
      !!navigationState.match.params.memberViewType)
  );
};

export const isOnTasksView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.SCHEDULE &&
    navigationState?.match?.params?.plannerViewType === 'tasks'
  );
};

export const isOnWorkplansView = (navigationHistory, index = 0) => {
  const navigationState = getNavigationState(navigationHistory, index);
  return (
    navigationState.section === Sections.SCHEDULE &&
    navigationState?.match?.params?.plannerViewType === 'workplans'
  );
};

export const isOnSearchablePageOrTab = (
  navigationHistory,
  matchedParams,
  index = 0
) => {
  const section = getNavigationState(navigationHistory, index)?.section;
  const viewType = VIEW_TYPE[section];

  return SEARCH_WHITE_LIST[section]?.[matchedParams?.[viewType]];
};
