import { useState } from 'react';
import styled from 'styled-components';
import { ChipsList } from 'components/ChipsList';
import theme from 'theme';
import { Space } from 'components/Space';

interface ProjectTagsListProps {
  tags: string[];
  canAddNewTag?: boolean;
  onAddTag?: (tag: string) => void;
  onRemoveTag?: (tag: string) => void;
}

export const ProjectTagsList = ({
  tags,
  onAddTag,
  onRemoveTag,
  canAddNewTag = true
}: ProjectTagsListProps) => {
  const chipsFromTags = tags.map((tag) => ({ label: tag, value: tag }));
  const [tagText, setTagText] = useState('');

  const handleAddTag = () => {
    if (onAddTag && tagText.length > 0) {
      onAddTag(tagText);
      setTagText('');
    }
  };

  return (
    <RootContainer>
      {canAddNewTag && (
        <InputContainer>
          <TagsInput
            placeholder="Enter Tag"
            value={tagText}
            onBlur={(e) => setTagText(e.currentTarget.value.trim())}
            onChange={(e) => setTagText(e.target.value)}
          />

          <StyledDoneButton onClick={handleAddTag}>Done</StyledDoneButton>
        </InputContainer>
      )}
      {chipsFromTags.length > 0 && <Space vertical value={15} />}
      <ChipsList
        chips={chipsFromTags}
        maxWidth={386}
        onDelete={onRemoveTag && ((params) => onRemoveTag(params.value))}
      />
    </RootContainer>
  );
};

const RootContainer = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputContainer = styled(Row)`
  gap: 10px;
`;

const TagsInput = styled.input`
  width: 120px;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 14px;
  border: 1px solid transparent;

  &:focus {
    border-radius: 3px;
    background-color: white;
  }

  &:focus + button,
  &:not(:placeholder-shown) + button {
    display: block;
  }

  &::placeholder {
    color: ${theme.colors.colorLightGray15};
  }
`;

const StyledDoneButton = styled.button`
  display: none;
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};

  &:hover {
    filter: brightness(95%);
  }
`;
