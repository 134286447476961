import orderBy from 'lodash/orderBy';
import seedrandom from 'seedrandom';
import keyBy from 'lodash/keyBy';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import random from 'lodash/random';
import range from 'lodash/range';

const moment = extendMoment(Moment);

export const SuggestionTableRequestStatusesIds = {
  FetchEntities: 'suggestion-table-fetch-entities',
  FetchMemberBudgetsAndSuggestions:
    'suggestion-table-fetch-member-budgets-and-suggestions'
};

export const reasonsHash = {
  board_membership_amplitude: 'A member of the portfolio',
  project_membership_amplitude: 'Project member',
  phase_membership_amplitude: 'Phase member',
  position_match_amplitude: 'Position match',
  matching_skills_amplitude: 'Skills match',
  time_availability_amplitude: 'Available time',
  budget_availability_amplitude: 'Available budget',
  past_engagement_workplan_amplitude: 'Past engagements & workplan match',
  past_engagement_timesheet_amplitude: 'Worked on similar projects',
  collaboration_potential_amplitude: 'Has worked with project members',
  remaining_work_amplitude: 'Availability during dates required',
  region_match_amplitude: 'Region match',
  office_match_amplitude: 'Office match',
  discipline_match_amplitude: 'Discipline match',
  account_starred_project_amplitude: 'Account starred project as favourite'
};

const phaseMemo = {};

const getExactMemberList = (members, positionPhaseMembershipId) => {
  return members.filter((member) => {
    const rng = seedrandom(positionPhaseMembershipId + member.account.id);
    return rng() > 0.5;
  });
};

export const generateReasons = (id) => {
  const keys = Object.keys(reasonsHash);
  const reasons = {};

  keys.forEach((key, index) => {
    const rng = seedrandom(id * (index + 1)); // Avoid multiplying by zero, may result in duplicate or data that are not consistent
    reasons[key] = rng();
  });

  return reasons;
};

const getSampleStartDates = ({ start_date, end_date }) =>
  Array.from(
    moment
      .range(
        moment(start_date || moment().format('YYYY-MM-DD')),
        moment(end_date || moment().add(1, 'month').format('YYYY-MM-DD'))
      )
      .by('day')
  );

const generateSampleEndDate = (date, endDate) => {
  const randomEndDate = moment(date).add(random(7, 28), 'days');
  if (!endDate) {
    return randomEndDate;
  }
  return moment(endDate).isBefore(randomEndDate)
    ? moment(endDate)
    : randomEndDate;
};
const generateDates = (phase) => {
  if (phaseMemo[phase.id]) {
    return phaseMemo[phase.id];
  } else {
    const { start_date, end_date } = phase;
    const startDate = start_date
      ? moment(start_date).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    phaseMemo[phase.id] = {
      start_date: startDate,
      end_date: generateSampleEndDate(startDate, end_date).format('YYYY-MM-DD')
    };
    return phaseMemo[phase.id];
  }
};

/**
 * Sample AI response for member
 * 
   end_date: "2022-10-21"
   reasons: {position_match_amplitude: 1, discipline_match_amplitude: 0.5}
   schedule: {,…}
   score: 0.2085420144685587
   start_date: "2022-03-25"
   suggestion_id: "20220326_023153:239175"
   total_available_hours: "1208.0"} param0
*/

const SUGGESTIONS_LIMIT = 5;
const SCORE_RANDOMIZATION_LIMIT = 5;
const DEMO_MAX_SCORE_DECREMENT_PER_MEMBER = 0.06;

const makeScores = (maxScore) => {
  const a = [maxScore];

  for (let i = 0; i < SCORE_RANDOMIZATION_LIMIT - 1; i++) {
    const nextScore = a[i] - 0.01;
    a.push(nextScore);
  }

  return a;
};

const maxScore = 0.99;

const scoresByIndex = range(SUGGESTIONS_LIMIT).reduce((acc, n) => {
  acc[n] = makeScores(maxScore - n * DEMO_MAX_SCORE_DECREMENT_PER_MEMBER);
  return acc;
}, {});

const getRandomNumberInclusive = (rand, max, min) =>
  Math.floor(rand * (max - min + 1) + min);

/**
 * For demo, the equation to calculate suggested member hours is
 * Math.floor(randomNumberBetween1And6 * the difference of this suggested member's date range) * 0.7
 *
 * */

const getDemoMemberAvailableHour = ({
  phaseMembershipHour,
  positionPhaseMembershipId,
  startDate,
  endDate,
  accountId,
  memberEmail
}) => {
  const emailRngVal = seedrandom(memberEmail)();
  const totalAvailableHoursRng = seedrandom(
    accountId + positionPhaseMembershipId + emailRngVal
  );

  // If date range is not provided, default difference to 7
  const dateRangeDiff =
    startDate && endDate ? moment(endDate).diff(moment(startDate), 'day') : 7;
  const rand = getRandomNumberInclusive(totalAvailableHoursRng(), 6, 1);
  const totalAvailableHour = Math.floor(rand * dateRangeDiff * 0.7);

  return Math.max(Math.min(totalAvailableHour, phaseMembershipHour), 1); // In case both variables are <= 0
};

// For demo, the randomization of start and end date of the member
// depends on the Position phase membership's start and end date (or Phase date if position phase membership date is falsy)
const getDemoMemberStartAndEndDate = ({
  startDate,
  endDate,
  accountId,
  positionPhaseMembershipId,
  memberEmail
}) => {
  // Assuming the phase must have both start and end date, otherwise default to 5 days difference
  const phaseStartEndDateDayDiff =
    startDate && endDate ? moment(endDate).diff(moment(startDate), 'day') : 5;
  const emailRngVal = seedrandom(memberEmail)();
  const rng = seedrandom(accountId + positionPhaseMembershipId + emailRngVal);
  const maxDay = phaseStartEndDateDayDiff < 5 ? phaseStartEndDateDayDiff : 5;
  const memberStartDateAddDay = getRandomNumberInclusive(rng(), maxDay, 1);

  /**
   * Case 1: If one of phase/positon start and end date does not exist,
   * - memberStartDate = Today
   * - memberEndDate = Today + arbitrary number between 1-5
   *
   *
   * Case 2: If both phase/positon start and end date exist
   * - memberStartDate = Start date of phase/position + an arbitrary number between 1-5
   * - memberEndDate = memberStartDate + subtractor
   *    - If difference between memberStartDate and phase/position end date is more than 5 days
   *      -> subtractor = an arbitrary number between 1-5
   *      -> else substractor = a minimum between 0 and diff^ - 1 (This is to avoid the memberEndDate being less than the memberStartDate))
   */
  const memberStartDate =
    startDate && endDate
      ? moment(startDate).add(memberStartDateAddDay, 'day').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');

  const memberStartDateAndPhaseEndDateDiff = moment(endDate).diff(
    moment(memberStartDate),
    'day'
  );

  const memberEndDateSubtractor =
    memberStartDateAndPhaseEndDateDiff >= 5
      ? getRandomNumberInclusive(rng(), 5, 1)
      : Math.min(memberStartDateAndPhaseEndDateDiff - 1, 0);

  const memberEndDate =
    startDate && endDate
      ? moment(endDate)
          .subtract(memberEndDateSubtractor, 'day')
          .format('YYYY-MM-DD')
      : moment(memberStartDate)
          .add(getRandomNumberInclusive(rng(), 5, 1), 'day')
          .format('YYYY-MM-DD');

  return {
    start_date: memberStartDate,
    end_date: memberEndDate
  };
};

const makeDemoMember = ({
  phase,
  position,
  memberEmail,
  accountId,
  positionPhaseMembershipId
}) => {
  const { start_date: phaseStartDate, end_date: phaseEndDate } = phase;
  const { phaseMembership } = position;
  const phaseMembershipHour = +phaseMembership?.required_hours ?? 20;
  const { start_date: memberStartDate, end_date: memberEndDate } =
    getDemoMemberStartAndEndDate({
      startDate: phaseMembership?.start_date ?? phaseStartDate, // position start date -> phase start date -> today
      endDate: phaseMembership?.end_date ?? phaseEndDate,
      accountId,
      positionPhaseMembershipId,
      memberEmail
    });

  return {
    total_available_hours: getDemoMemberAvailableHour({
      phaseMembershipHour,
      startDate: memberStartDate,
      endDate: memberEndDate,
      positionPhaseMembershipId,
      accountId,
      memberEmail
    }),
    start_date: memberStartDate,
    end_date: memberEndDate,
    reasons: generateReasons(accountId + positionPhaseMembershipId),
    account_id: accountId,
    id: accountId
  };
};

const makeDemoSuggestedMembersList = ({
  phase,
  position,
  positionPhaseMembershipId,
  boardMembers
}) => {
  const demoSuggestions = [];

  const phaseMembershipAccountIds = keyBy(
    phase.phase_memberships.map((pm) => pm.account_id)
  );
  const membersNotInPhase = boardMembers.filter(
    (member) => !phaseMembershipAccountIds[member.account.id]
  );

  const membersNotInPhaseSample = getExactMemberList(
    membersNotInPhase,
    positionPhaseMembershipId
  );

  if (!membersNotInPhaseSample.length) return [];

  for (let i = 0; i < SUGGESTIONS_LIMIT; i++) {
    const member = membersNotInPhaseSample[i];
    if (member) {
      const accountId = member.account.id;
      const memberEmail = member.account.email;
      demoSuggestions.push({
        ...makeDemoMember({
          phase,
          position,
          accountId,
          positionPhaseMembershipId,
          memberEmail
        })
      });
    }
  }
  return demoSuggestions;
};
export const makeSuggestedMembersList = ({
  realSuggestionsByPhaseMemberships,
  positionPhaseMembershipId,
  showDemoSuggestions,
  boardMembers,
  phase,
  position
}) => {
  const rawList = showDemoSuggestions
    ? makeDemoSuggestedMembersList({
        boardMembers,
        phase,
        positionPhaseMembershipId,
        position
      })
    : realSuggestionsByPhaseMemberships[
        positionPhaseMembershipId
      ]?.suggestions?.map((member) => ({
        ...member,
        id: member.account_id
      }));

  if (!rawList) return [];

  if (!showDemoSuggestions) {
    // AI data
    const sortedList = orderBy(rawList, 'score', 'desc');

    return sortedList.length < SUGGESTIONS_LIMIT
      ? sortedList
      : sortedList.slice(0, SUGGESTIONS_LIMIT);
  } else {
    // Demo - Sort by availablity (total available hours) to give higher % match
    const sortedList = orderBy(rawList, 'total_available_hours', 'desc');
    const formattedSortedList = sortedList.reduce((acc, member, idx) => {
      const rng = seedrandom(
        positionPhaseMembershipId + member.id + member.total_available_hours
      );
      const rngValue = rng();
      const suggestionIdx = Math.floor(rngValue * SCORE_RANDOMIZATION_LIMIT);

      const scores = scoresByIndex[idx];
      acc.push({
        ...member,
        score: scores[suggestionIdx]
      });
      return acc;
    }, []);

    return formattedSortedList;
  }
};
