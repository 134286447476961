import { useState } from 'react';
import { MosaicMembershipInfoModal } from 'PermissionsModule/components/settingsViews/members/MembersPermissionsTable/MosaicMembershipInfoModal';
import { InfoModalToggle, InfoIconContainer } from './styles';
import InfoIcon from 'icons/InfoIcon';
import theme from 'theme';

export const PermissionInfoModalToggle = ({
  className = ''
}: {
  className?: string;
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleOpenInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const handleCloseInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  return (
    <>
      <InfoModalToggle className={className} onClick={handleOpenInfoModal}>
        <InfoIconContainer>
          <InfoIcon color={theme.colors.colorRoyalBlue} />
        </InfoIconContainer>
        Learn about Mosaic’s Membership Options
      </InfoModalToggle>

      <MosaicMembershipInfoModal
        open={isInfoModalOpen}
        toggle={handleCloseInfoModal}
      />
    </>
  );
};
