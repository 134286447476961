import styled from 'styled-components';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';
import { Styles } from 'components/Table/styles';
import { StyledReportHeader } from 'ReportsModule/components/styles';

export const StyledCapacityReportTable = styled.div`
  ${Styles} {
    padding: 0;
  }
  ${StyledReportHeader} {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    pointer-events: none;
    color: ${theme.colors.colorMediumGray};
  }
  .member ${StyledReportHeader} {
    justify-content: flex-end;
    padding-right: 6px;
  }

  .tables-header-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    ${(props) => !props.loaded && `visibility: hidden`}
  }
  .variable-size-list {
  }
  .table {
    margin-bottom: 0;
  }
  .table-column-header {
    z-index: 1;
    &:not(.leftPadding) {
      border-bottom: 2px solid ${theme.colors.colorLightGray6};
    }
  }

  .empty {
    visibility: hidden;
  }

  .tr.memberRowProjectView,
  .tr.phaseRow,
  .tr.memberRow {
    display: flex;
    align-items: flex-end;
    color: ${theme.colors.colorSemiDarkGray1};

    & > *:not(.member) {
      padding-top: 20px;
    }
    & > .member,
    .collapse {
      padding-top: 13px;
    }

    .graph {
      border-left: 1px solid ${theme.colors.colorLightGray6};
      background: linear-gradient(
          ${(props) => (props.isDashboardWidgetModal ? 'white' : '#f3f3f3')} 3px,
          transparent 0
        ),
        linear-gradient(
          90deg,
          transparent 5px,
          transparent 5px,
          ${theme.colors.colorLightGray6} 5px,
          ${theme.colors.colorLightGray6} 6px,
          transparent 7px,
          transparent 13px
        );
      background-size: 4px 4px, 494px 494px;
      background-position: 0px 33px, 487px 0px;
    }
    &:hover {
      .three-dot-container {
        visibility: visible;
      }
    }
  }
  .tr.projectRow {
    display: flex;
    align-items: flex-end;
    color: ${theme.colors.colorSemiDarkGray1};

    .graph {
      border-left: 1px solid ${theme.colors.colorLightGray6};
      background: linear-gradient(
          ${(props) => (props.isDashboardWidgetModal ? 'white' : '#f3f3f3')} 3px,
          transparent 0
        ),
        linear-gradient(
          90deg,
          transparent 5px,
          transparent 5px,
          ${theme.colors.colorLightGray6} 5px,
          ${theme.colors.colorLightGray6} 6px,
          transparent 7px,
          transparent 13px
        );
      background-size: 4px 4px, 494px 494px;
      background-position: 0px 33px, 487px 0px;
    }
    &:hover {
      .three-dot-container {
        visibility: visible;
      }
    }
  }
  .last-full-row {
    .td:not(.leftPadding) {
      border-bottom: 1px solid ${theme.colors.colorLightGray6};
    }
  }

  .variable-size-list {
  }
  .td {
    height: 100%;
    flex-shrink: 0;
    &.rightPadding {
      flex-shrink: 1;
    }
  }
`;

export const StyledTooltip = styled(ReactTooltip)`
  font-size: 11px;
  font-weight: normal;
  padding: 8px;
  text-align: center;
  z-index: 1000;
  max-width: 240px;
`;
