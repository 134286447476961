import theme from 'theme';
import Popover from 'components/Popover';
import PhaseIcon from 'icons/PhaseMilestoneIcon';
import HelpQuestionIcon from 'icons/HelpQuestionIcon';
import MilestoneIcon from 'icons/MilestoneIcon';
import {
  StyledDropdownItem,
  StyledPhaseIconContainer,
  StyledMilestoneIconContainer,
  StyledQuestionIconContainer
} from './styles';
const AddMenu = (props) => {
  const {
    showAddMenu,
    targetRef,
    clearShowAddMenu,
    handleAddingMilestone,
    setAddingPhase
  } = props;

  return (
    <Popover
      isOpen={showAddMenu}
      target={targetRef}
      closePopover={clearShowAddMenu}
      className="milestone-modal-add-dropdown"
    >
      <StyledDropdownItem onClick={setAddingPhase}>
        <StyledPhaseIconContainer>
          <PhaseIcon height={16} width={16} />
        </StyledPhaseIconContainer>
        <div>Add Phase</div>
        <StyledQuestionIconContainer
          data-for={'app-tooltip'}
          data-tip={'Phases are date ranges,<br/>connected to project budgets.'}
          data-html
        >
          <HelpQuestionIcon
            height={8}
            width={8}
            stroke={theme.colors.colorMediumGray5}
            fill={theme.colors.colorMediumGray5}
          />
        </StyledQuestionIconContainer>
      </StyledDropdownItem>
      <StyledDropdownItem onClick={handleAddingMilestone}>
        <StyledMilestoneIconContainer>
          <MilestoneIcon height={14} width={14} />
        </StyledMilestoneIconContainer>
        <div>Add Milestone</div>
        <StyledQuestionIconContainer
          data-for={'app-tooltip'}
          data-tip={'Milestones are single dates,<br/>independent of budgets.'}
          data-html
        >
          <HelpQuestionIcon
            height={8}
            width={8}
            stroke={theme.colors.colorMediumGray5}
            fill={theme.colors.colorMediumGray5}
          />
        </StyledQuestionIconContainer>
      </StyledDropdownItem>
    </Popover>
  );
};

export default AddMenu;
