import { makeVerification } from '../makeVerification';

export const verifyAccessBudgetReport = makeVerification('read_budget_report');
export const verifyAccessWorkloadReport = makeVerification(
  'read_workload_report'
);
export const verifyAccessTimesheetReport = makeVerification(
  'read_timesheet_report'
);
export const verifyAccessUtilizationReport = makeVerification(
  'read_utilization_report'
);
export const verifyAccessProfitReport = makeVerification('read_profit_report');
export const verifyAccessTimeVarianceReport = makeVerification(
  'read_time_variance_report'
);
export const verifyAccessTimesheetStatusReport = makeVerification(
  'read_timesheet_status_report'
);
export const verifyAccessDemandReport = makeVerification('read_demand_report');
export const verifyAccessWorkloadForecastReport = makeVerification(
  'read_workload_forecast_report'
);
export const verifyAccessScheduleVarianceReport = makeVerification(
  'read_schedule_variance_report'
);
export const verifyAccessSkillsReport = makeVerification('read_skills_report');

export const verifyAccessBudgetVarianceReport = makeVerification(
  'read_budget_variance_report'
);

export const verifyAccessCheckInsReport = makeVerification(
  'read_check_ins_report'
);

export const verifyAccessPlannedTimeReport = makeVerification(
  'read_planned_time_report'
);

export const verifyAccessProjectsReport = makeVerification(
  'read_projects_report'
);

export const verifyAccessSpentTimeReport = makeVerification(
  'read_spent_time_report'
);

export const verifyAccessTimeProjectionReport = makeVerification(
  'read_time_projection_report'
);

export const verifyAccessTimesheetApprovalReport = makeVerification(
  'read_timesheet_approval_report'
);

export const verifyAccessOrgChartReport = makeVerification(
  'read_org_chart_report'
);

// Export Reports (Not implemented yet)
export const verifyExportReportData = makeVerification('export_data');
