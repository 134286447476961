'use strict';

exports.__esModule = true;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _en_US = require('../locale/en_US');

var _en_US2 = _interopRequireDefault(_en_US);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function noop() {}

exports['default'] = {
  propTypes: {
    className: _propTypes2['default'].string,
    locale: _propTypes2['default'].object,
    style: _propTypes2['default'].object,
    visible: _propTypes2['default'].bool,
    onSelect: _propTypes2['default'].func,
    prefixCls: _propTypes2['default'].string,
    onChange: _propTypes2['default'].func,
    onOk: _propTypes2['default'].func
  },

  getDefaultProps: function getDefaultProps() {
    return {
      locale: _en_US2['default'],
      style: {},
      visible: true,
      prefixCls: 'rc-calendar',
      className: '',
      onSelect: noop,
      onChange: noop,
      onClear: noop,
      renderFooter: function renderFooter() {
        return null;
      },
      renderSidebar: function renderSidebar() {
        return null;
      }
    };
  },
  shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
    return this.props.visible || nextProps.visible;
  },
  getFormat: function getFormat() {
    var format = this.props.format;
    var _props = this.props,
      locale = _props.locale,
      timePicker = _props.timePicker;

    if (!format) {
      if (timePicker) {
        format = locale.dateTimeFormat;
      } else {
        format = locale.dateFormat;
      }
    }
    return format;
  },
  focus: function focus() {
    if (this.rootInstance) {
      this.rootInstance.focus();
    }
  },
  saveRoot: function saveRoot(root) {
    this.rootInstance = root;
  }
};
module.exports = exports['default'];
