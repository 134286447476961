import React from 'react';
import { connect } from 'react-redux';

import {
  getUserIsAdmin,
  makeGetProjectMembershipsByAccountId,
  getMyUserId,
  makeGetIsCurrentUser
} from 'selectors';
import { addMemberToProject } from 'actionCreators';
import ReactContentEditable from 'react-contenteditable';
import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';
import { EDIT_TIMESHEET_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

function getText(element) {
  if (!element.firstChild) {
    return '';
  }
  const firstTag = element.firstChild.nodeName;

  const keyTag = new RegExp(
    firstTag === '#text' ? '<br' : '</' + firstTag,
    'i'
  );
  const tmp = document.createElement('p');
  tmp.innerHTML = element.innerHTML
    .replace(/<[^>]+>/g, (m, i) => (keyTag.test(m) ? '{ß®}' : ''))
    .replace(/{ß®}$/, '');
  return tmp.innerText.replace(/{ß®}/g, '\n');
}

/* TODO: Refactor Join Project Modal + success callback to not have to be rendered/handled in individual timesheet cells. See TimesheetDay, TimesheetDescription, TimesheetActivity, and TimesheetProject -> ProjectsAndPhasesDropdown */
class TimesheetDescription extends React.Component {
  state = {
    title: this.props.title || '',
    selectedProject: null,
    joinModalOpen: false
  };

  myTextAreaRef = React.createRef();
  componentDidUpdate(prevProps) {
    const { title } = this.props;
    if (title !== prevProps.title) {
      this.setState({ title });
    }
  }

  onSubmit = () => {
    const { accountId, projectMembersByAccountId, project } = this.props;
    const text = this.myTextAreaRef?.current?.htmlEl?.innerText;
    if (text.length > 1000) {
      this.setState({ title: text.substring(0, 1000) });
    }
    if (projectMembersByAccountId[accountId] || !accountId) {
      this.updateDescription();
      this.setState({ focused: false });
    } else {
      this.setJoinProject(project);
    }
  };

  updateDescription = () => {
    const { title } = this.state;
    const { handleSubmit } = this.props;
    const text = this.myTextAreaRef?.current?.htmlEl?.innerText;
    if (text.length > 1000) {
      this.setState({ title: text.substring(0, 1000) });
    }
    const plainText = getText(this.myTextAreaRef?.current?.htmlEl);

    handleSubmit(plainText?.length ? plainText : title);
  };

  setJoinProject = (project) => {
    this.setState({
      selectedProject: project,
      joinModalOpen: true
    });
  };

  joinProject = () => {
    const { addMemberToProject, accountId } = this.props;
    const { selectedProject } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: this.updateDescription,
        selector: () => {}
      }
    ];
    addMemberToProject(
      selectedProject.id,
      accountId,
      basicMemberRole,
      selectedProject.board_id,
      onSuccess
    );
    this.clearJoinProject();
  };

  clearJoinProject = () =>
    this.setState({ selectedProject: null, joinModalOpen: false });

  handleChange = (e) => {
    if (this.props.isTimesheetsDisabled) {
      return;
    }
    this.setState({ title: e.target.value });
  };

  handleEnterPress = (e) => {
    const text = this.myTextAreaRef?.current?.htmlEl?.innerText;
    if (text.length >= 1000 && event.keyCode !== 8) {
      event.preventDefault();
    }
    if (e.keyCode === 13) {
      // eslint-disable-next-line no-unused-expressions
      this.myTextAreaRef?.current?.htmlEl?.blur?.();
    }
  };

  onClickFocus = () => {
    if (this.props.isTimesheetsDisabled) {
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    this.myTextAreaRef?.current?.htmlEl?.focus();
  };

  render() {
    const {
      disableEdit,
      activity,
      isRed,
      ContainerEl,
      accountId,
      myId,
      placeholder,
      isTimesheetsDisabled
    } = this.props;

    const { title, joinModalOpen, selectedProject } = this.state;
    const placeholderToRender =
      placeholder ||
      `Enter ${
        activity ? (activity.require_title ? ' Required ' : ' Optional ') : ''
      }Description`;
    const triggerRerender = `${
      activity
        ? activity.require_title
          ? 'no-activity'
          : 'required'
        : 'optional'
    }`;

    return (
      <ContainerEl
        onClick={this.onClickFocus}
        style={{ cursor: 'pointer' }}
        showDescriptionPlaceholder={!!activity}
        isRed={isRed}
        data-for="app-tooltip"
        data-effect="solid"
        data-class="center"
        data-tip={EDIT_TIMESHEET_TIP}
        data-tip-disable={!disableEdit}
      >
        <ReactContentEditable
          className={`content-editable-description ${triggerRerender}`}
          html={title}
          onKeyDown={this.handleEnterPress}
          onChange={this.handleChange}
          ref={this.myTextAreaRef}
          onBlur={this.onSubmit}
          data-placeholder={placeholderToRender}
          disabled={isTimesheetsDisabled || disableEdit}
          // disabled={!isEditible} // BE does not handle timesheet creation correctly
        />
        <JoinProjectModal
          open={joinModalOpen}
          toggle={this.clearJoinProject}
          selectedProject={selectedProject}
          onConfirm={this.joinProject}
          text={'edit the description'}
          accountId={accountId}
          currentUserId={myId}
        />
      </ContainerEl>
    );
  }
}
const makeMapStateToProps = () => {
  const getProjectMembersByAccountId = makeGetProjectMembershipsByAccountId();
  const getIsCurrentUser = makeGetIsCurrentUser();
  const mapStateToProps = (state, ownProps) => ({
    isAdmin: getUserIsAdmin(state),
    isCurrentUser: getIsCurrentUser(state, ownProps),
    projectMembersByAccountId: getProjectMembersByAccountId(state, ownProps),
    myId: getMyUserId(state)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  addMemberToProject
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TimesheetDescription);
