export const makeDefaultInitial = (unformattedInitial?: string) => {
  if (!unformattedInitial) return '';

  // Remove all number and special character
  const unformattedInitialWithoutSpecialCharacterAndNumbers =
    unformattedInitial.replace(/[^A-Za-z]+/g, ' ');

  const initialPartsArray = unformattedInitialWithoutSpecialCharacterAndNumbers
    .replace(/\s+/g, ' ') // Remove all white space
    .trim()
    .split(' '); // remove unnecessary white space

  let result = '';

  if (!initialPartsArray.length) return result;

  const maxIdx = initialPartsArray.length >= 3 ? 3 : initialPartsArray.length;

  for (let i = 0; i < maxIdx; i++) {
    const partOfLabelStr = initialPartsArray[i];

    if (!partOfLabelStr) return result;

    const firstLetter = partOfLabelStr[0]?.toLocaleUpperCase();

    result += firstLetter;
  }

  return result;
};
