import { ReducerName } from 'reduxInfra/shared';
import teamCapacities from './teamCapacities';
import accountCapacities from './accountCapacities';
import holidays from './holidays';
import holidayGroups from './holidayGroups';
import capacityReport from './capacityReport';
import { capacityGroupsReducer } from './capacityGroup';

export const reducerMap = {
  teamCapacities,
  accountCapacities,
  holidays,
  holidayGroups,
  capacityReport,
  [ReducerName.CapacityGroups]: capacityGroupsReducer
};
