import { TeamMember } from 'models/teamMember';
import { PhaseListItem } from '../types';
import { useAppDispatch } from 'reduxInfra/hooks';
import { createPhaseMembers } from 'actionCreators';
import { getCreatePhaseMembersRefetchActions } from 'SuggestionModule/components/FindPeople/utils';
import {
  StyledKaratContainer,
  StyledItemContainer,
  StyledIcon
} from 'SuggestionModule/components/FindPeople/List/styles';
import PhaseDiamondIcon from 'icons/PhaseDiamondIcon';
import KaratRight from 'icons/KaratRight';
import EllipsisText from 'components/EllipsisText';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import theme from 'theme';
import keyBy from 'lodash/keyBy';
import { formatPhaseEntityNameWithCount } from '../helpers/utils';

// On phase level, only show members that are not discarded
const phaseMembersFilter = (
  members: TeamMember[],
  phase: PhaseListItem['phase']
) => {
  const membershipsByAccountId = keyBy(
    phase?.phase_memberships?.filter((member) => !member.discarded_at),
    (item) => item.account_id
  );

  return members.filter(
    (member) => !membershipsByAccountId[member?.account?.id]
  );
};

export const Phase = (phaseItem: PhaseListItem) => {
  const dispatch = useAppDispatch();
  const {
    phase,
    isOpen,
    toggleCollapse,
    isEmpty,
    projectId,
    visibleMembersCount,
    hasActivityPhases,
    openBudgetModal
  } = phaseItem;
  const { name, id: phaseId } = phase;

  const handleDone = (members: TeamMember[]) => {
    const accountIds = members.map((member) => member.account.id);

    dispatch(
      createPhaseMembers({
        projectId,
        phaseId,
        accountIds,
        // Default refetch does not support isoState
        // getCreatePhaseMembersRefetchActions returns all the actions that needs to be performed when successfully create the phase member, with isoState
        shouldRefetch: false,
        onSuccess: [
          {
            successAction: () => {
              const refetchActions = getCreatePhaseMembersRefetchActions({
                projectId
              });
              refetchActions.forEach((action) => dispatch(action));
            },
            selector: (payload, response) => ({ payload, response })
          }
        ]
      })
    );
  };

  const shouldShowCount = !!(visibleMembersCount && !isOpen);

  const phaseNameWithCount = formatPhaseEntityNameWithCount({
    shouldShowCount,
    name,
    visibleMembersCount
  });

  return (
    <StyledItemContainer
      data-testid="phase-item-container"
      onClick={toggleCollapse}
      className="phase"
    >
      <StyledKaratContainer
        isEmpty={false} // Spec: Showing arrow even phase has no members
        isOpen={isOpen}
      >
        <KaratRight />
      </StyledKaratContainer>
      <PhaseDiamondIcon
        strokeColor={theme.colors.colorMediumGray9}
        width="13"
        height="13"
        className={undefined}
      />{' '}
      <EllipsisText
        tooltip={phaseNameWithCount}
        width={undefined}
        maxWidth={220}
        data-testid="phase-name"
      >
        {`${name}`}
      </EllipsisText>{' '}
      {shouldShowCount ? (
        <span data-testid="total-member-count" className="total-member-count">
          | {visibleMembersCount}
        </span>
      ) : (
        <></>
      )}
      {!hasActivityPhases && (
        <BulkMemberSelector
          projectId={projectId}
          shouldUseMemberFilter
          shouldNotLoadBoardMembers
          phaseId={phaseId}
          renderSelect={({ onClick }) =>
            isOpen && (
              <StyledIcon
                data-testid="add-member-toggle"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
              >
                <PlusCircleIcon height={15} width={15} />
              </StyledIcon>
            )
          }
          handleDone={handleDone}
          membershipLevel={'phase'}
          hideFooter
          memberFilter={(members: TeamMember[]) =>
            phaseMembersFilter(members, phase)
          }
          searchPlaceholder="Type name or select below"
        />
      )}
    </StyledItemContainer>
  );
};
