import React from 'react';
import bindAll from 'lodash/bindAll';
import intersection from 'lodash/intersection';

import { TaskCard, OutsideClickWrapper } from '..';
import dailyTaskPreventModalClasses from 'appConstants/dailyTaskPreventModalClasses';

class AddTaskCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCreatingTask: false
    };
    bindAll(this, ['toggleCreateTask', 'closeAddTaskCard']);
  }

  closeAddTaskCard(e) {
    if (
      e &&
      !intersection(e.target.classList, dailyTaskPreventModalClasses).length
    ) {
      this.setState({ isCreatingTask: false });
    }
  }

  toggleCreateTask() {
    this.setState((prevState) => ({
      isCreatingTask: !prevState.isCreatingTask
    }));
  }

  render() {
    const { isCreatingTask } = this.state;
    return (
      <div>
        {isCreatingTask ? (
          <OutsideClickWrapper handleOutsideClick={this.closeAddTaskCard}>
            <TaskCard
              {...this.props}
              isNewPlannerTask
              toggleCreateTask={this.toggleCreateTask}
            />
          </OutsideClickWrapper>
        ) : (
          <div className="planner-add-task" onClick={this.toggleCreateTask}>
            <div className="add-task-icon" />
          </div>
        )}
      </div>
    );
  }
}

export default AddTaskCard;
