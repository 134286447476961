import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const NewSaveIconSolid = ({
  width = '17',
  height = '16',
  strokeWidth = '1.2',
  strokeColor = theme.colors.colorBudgetBlue,
  fillColor = theme.colors.colorPureWhite
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 17 16"
      strokeWidth="1.2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5909 0.6H13.418C13.5419 0.6 13.6588 0.657397 13.7346 0.755421L16.3165 4.09677L16.7913 3.7299L16.3165 4.09677C16.3706 4.16681 16.4 4.25283 16.4 4.34135V8V15C16.4 15.2209 16.2209 15.4 16 15.4H1C0.779086 15.4 0.6 15.2209 0.6 15V1C0.6 0.779086 0.779086 0.6 1 0.6H3.4H11.5909Z"
        fill="#0074D9"
        stroke="#0074D9"
        strokeWidth={strokeWidth}
      />
      <path
        d="M11.5908 0V4.95828C11.5908 5.23442 11.367 5.45828 11.0908 5.45828H3.8999C3.62376 5.45828 3.3999 5.23442 3.3999 4.95828V2.90909V0"
        stroke="white"
        strokeWidth={strokeWidth}
      />
    </SvgIcon>
  );
};

export default NewSaveIconSolid;
