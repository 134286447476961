import { useEffect } from 'react';
import { DragSourceMonitor, useDrag, useDragLayer, XYCoord } from 'react-dnd';
import theme from 'theme';
import DragGripIcon from 'icons/DragGripIcon';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { SPLIT_SCREEN_Z_INDICES } from './constants';

export type DragCustomHandler = (values: {
  deltaY: number;
  initialSourceClientOffset: XYCoord | null;
}) => void;

interface CustomDragLayerProps {
  handleDrag?: DragCustomHandler;
}

function CustomDragLayer({ handleDrag }: CustomDragLayerProps) {
  useDragLayer((monitor) => {
    const initialSourceClientOffset = monitor.getInitialSourceClientOffset();
    const currentOffset = monitor.getSourceClientOffset();
    const difference = monitor.getDifferenceFromInitialOffset();
    const deltaY = difference?.y || 0;
    monitor.isDragging() && handleDrag?.({ deltaY, initialSourceClientOffset });
    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: initialSourceClientOffset,
      currentOffset,
      isDragging: monitor.isDragging(),
      deltaY
    };
  });
  return null;
}

function SplitScreenDivider({
  dragRef,
  leftOffset = 0,
  height = 8 // px
}) {
  return (
    <SplitScreenDividerContainer
      ref={dragRef}
      $height={height}
      className="split-screen-divider"
    >
      <div
        style={{
          position: 'absolute',
          zIndex: SPLIT_SCREEN_Z_INDICES.Divider,
          background: theme.colors.colorLightGray9,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'grab',
          userSelect: 'none',
          height
        }}
      >
        <div
          style={{
            background: theme.colors.colorLightGray9,
            position: 'absolute',
            height: 24,
            width: 24,
            paddingTop: 2,
            marginLeft: leftOffset,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            userSelect: 'none'
          }}
        >
          <DragGripIcon />
        </div>
      </div>
    </SplitScreenDividerContainer>
  );
}

interface DraggableSplitScreenDividerProps {
  handleDrag: DragCustomHandler;
  handleDragEnd: (monitor: DragSourceMonitor) => void;
  handleDragStart: () => void;
  /**
   * divider height in px
   */
  height?: number;
}
function DraggableSplitScreenDivider({
  handleDrag,
  handleDragEnd,
  handleDragStart,
  height = 8
}: DraggableSplitScreenDividerProps) {
  const [{ isDragging }, dragRef, preview] = useDrag({
    item: { type: 'split-screen' },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    },
    end: (_, monitor) => {
      handleDragEnd(monitor);
    },
    begin: () => {
      handleDragStart();
    }
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [isDragging, preview]);
  return (
    <>
      <SplitScreenDivider dragRef={dragRef} leftOffset={0} height={height} />
      <CustomDragLayer handleDrag={isDragging ? handleDrag : undefined} />
    </>
  );
}

export default DraggableSplitScreenDivider;

const SplitScreenDividerContainer = styled.div.attrs<{ $height: number }>(
  ({ $height }) => ({
    style: { height: $height }
  })
)<{ $height: number }>`
  position: relative;
`;
