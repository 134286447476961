import { ChangeEvent } from 'react';
import { BarForm } from './BarForm';
import BarDuration from './BarDuration';
import { useWorkPlanForm } from '../WorkPlanFormProvider';
import moment from 'appUtils/momentConfig';
import { BUDGET_STATUSES } from 'appConstants/budgetStatuses';
import { noop } from 'appUtils';
import OptionsMenu from '../optionsMenu/OptionsMenu';
import { OptionsMenuValueProps, OptionsMenuSetterProps } from '../types';
import { ActiveOptions } from './ActiveOptions';
import { generateNewDependencyInfos } from 'appUtils/newDependencies';

export const PredictableFields = () => {
  const {
    member: {
      memberDailyCapacity,
      memberUtilizations,
      memberPTODates,
      holidayDates
    },
    projectAndPhase: { isPTO, phase, disableDependency },
    form: {
      onChangePredictableFields,
      initialWorkplan,
      workplan,
      shouldDisableInputs,
      isMissingInfo,
      hasError,
      showErrors,
      workDays,
      workdayPercent,
      datesArray,
      onUpdateIsTentative,
      initialDependency,
      initialDependencyItem,
      onUpdateIsAllDay
    },
    calendar: { isOpen, handleSetWorkplanCalendarOpen },
    optionsMenu: {
      isOpen: isOptionsMenuOpen,
      handleSetWorkplanMoreOptionsOpen
    },
    moreOptions: { weeklyPlan },
    permissions: { canEditWorkplan }
  } = useWorkPlanForm();

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.select) {
      e.target.select();
    }
  };

  const handleDateChange = (
    startDate,
    endDate,
    dependency,
    dependencyItem,
    dependencyItemType,
    includeWeekends,
    useWeeklyPlanning,
    allDay,
    // to be deprecated in the future when tentative is moved out of calendar into more options menu
    isTentativeOld,
    startTime,
    endTime,
    timeRange
  ) => {
    onChangePredictableFields('reset', {
      start_date: moment(startDate).format('MM/DD/YYYY'),
      end_date: moment(endDate).format('MM/DD/YYYY'),
      ...(timeRange ? { daily_hours: timeRange } : {}),
      dependency_infos: generateNewDependencyInfos({
        item: workplan,
        dependency,
        dependencyItem,
        dependableType: dependencyItemType
      }),
      include_weekends: includeWeekends,
      // to be deprecated in the future when tentative is moved out of calendar into more options menu
      budget_status: isTentativeOld
        ? BUDGET_STATUSES.PROPOSAL
        : BUDGET_STATUSES.ACTIVE,
      all_day: allDay,
      start_time: startTime ? startTime.format('h:mma') : undefined,
      end_time: endTime ? endTime.format('h:mma') : undefined,
      use_weekly_planning: useWeeklyPlanning
    });
  };

  const handleBlur = noop;

  const additionalDailyHours =
    initialWorkplan?.daily_hours && workplan?.daily_hours
      ? (Number(workplan.daily_hours) || 0) -
        (Number(workplan.daily_hours) || 0)
      : workplan.daily_hours;

  const optionsMenuValueProps: OptionsMenuValueProps = {
    isTentative: workplan.budget_status === BUDGET_STATUSES.PROPOSAL,
    startTime: workplan.start_time,
    endTime: workplan.end_time,
    dailyHours: workplan.daily_hours,
    isShowTimeSetter: !(isPTO && workplan.all_day),
    isAllDay: workplan.all_day,
    isShowAllDayCheckbox: isPTO
  };

  const optionsMenuSetterProps: OptionsMenuSetterProps = {
    onUpdateIsTentative,
    onUpdateIsAllDay,
    onChangePredictableFields,
    canEditWorkplan
  };

  return (
    <div>
      <BarForm
        values={{
          all_day: workplan.all_day,
          total_hours: workplan.total_hours,
          daily_hours: workplan.daily_hours,
          lock_hour: workplan.lock_hour,
          workday_percent: workdayPercent,
          datesArray,
          dailyCapacity: memberDailyCapacity,
          weeklyPlan
        }}
        handleChanges={onChangePredictableFields}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        onUpdateIsAllDay={onUpdateIsAllDay}
        disableAll={shouldDisableInputs || !canEditWorkplan}
        canEditWorkplan={canEditWorkplan}
        showErrors={
          showErrors
            ? {
                workdayPercent: hasError(['invalid_workday_percent']),
                dailyHours: hasError(['invalid_daily_hours']),
                totalHours: hasError(['invalid_total_hours'])
              }
            : undefined
        }
      />
      <BarDuration
        bar={workplan}
        workDays={workDays}
        handleChanges={onChangePredictableFields}
        handleDateChange={handleDateChange}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        disabled={shouldDisableInputs || !canEditWorkplan}
        isMissingInfo={isMissingInfo}
        isOOO={isPTO}
        initialDependency={initialDependency}
        initialDependencyItem={initialDependencyItem}
        barPhase={phase}
        disableDependency={disableDependency}
        workplanCalendarOpen={isOpen}
        handleSetWorkplanCalendarOpen={handleSetWorkplanCalendarOpen}
        accountUtilizations={memberUtilizations}
        accountCapacities={memberDailyCapacity}
        accountPTODates={memberPTODates}
        additionalDailyHours={additionalDailyHours}
        holidayDates={holidayDates}
        canEditWorkplan={canEditWorkplan}
      />
      <ActiveOptions />
      <OptionsMenu
        optionsMenuValueProps={optionsMenuValueProps}
        optionsMenuSetterProps={optionsMenuSetterProps}
        initialIsOpen={isOptionsMenuOpen}
        handleSetIsOpen={handleSetWorkplanMoreOptionsOpen}
      />
    </div>
  );
};
