import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCommentsAndMetadata } from 'actionCreators';
import { StyledScopeModal } from './styles';
import {
  ParentInfo,
  DescriptionSection,
  ProgressSection,
  ScopeModalHeader
} from '../components';
import {
  ModalSideBody,
  NoteSection,
  CommentsSection,
  AttachmentsSection,
  DeleteButton,
  DatesSection,
  MainBody,
  PaddingContainer,
  ModalBodyContainer,
  SelectMembersSection
} from 'components/Modals/themes/TwoPaneModal';
import DeleteScopesModal from 'components/Scope/modals/delete/DeleteScopesModal';
import MoveScopeModal from 'components/Scope/modals/MoveScopeModal';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import useScopeModal from './useScopeModal';
import { getProjectHash } from 'ProjectsModule/selectors';

/**
 *  Required modules: [BudgetModule]
 *  Scope not editable from project view version
 */
const ScopeModal = ({
  scope,
  toggle,
  isProjectViewScope,
  filterStateId,
  groupBy
}) => {
  const dispatch = useDispatch();
  const [isMoveConfirmModalOpen, setIsMoveConfirmModalOpen] = useState(false);
  const [nextActivityPhaseId, setNextActivityPhaseId] = useState(null);
  const [
    isProjectPhaseActivitySelectorOpen,
    setIsProjectPhaseActivitySelectorOpen
  ] = useState(false);
  const projectPhaseActivitySelectorRef = useRef(null);
  const projectHash = useSelector(getProjectHash);

  // useScopeModal
  const {
    errors,
    checkIfDescriptionValid,
    isValid,
    isDeleteConfirmOpen,
    setIsDeleteConfirmOpen,

    handleClose,
    setIsClosing,

    handleDescriptionChange,
    flushUpdateScopeDescription,

    handleNotesChange,
    flushUpdateScopeNotes,

    addAttachmentsToScope,
    deleteAttachmentFromScope,

    assignMembersToScope,

    updateScopeDates
  } = useScopeModal({
    scopeId: scope.id,
    toggle,
    filterStateId,
    groupBy
  });

  /* ------------------ Change project, phase, activityPhase ------------------ */

  const handleChangeActivityPhase = ({ activityPhaseId }) => {
    if (activityPhaseId && +activityPhaseId !== +scope.activity_phase_id) {
      setIsMoveConfirmModalOpen(true);
      setNextActivityPhaseId(activityPhaseId);
    }
  };

  /* -------------------------------- Assignees ------------------------------- */

  const handleUpdateAssignees = (members) => {
    const accountIds = members.map((member) => member.account.id);

    assignMembersToScope({
      assignedAccountIds: accountIds,
      filterStateId,
      groupBy
    });
  };

  /* ------------------------------------ - ----------------------------------- */

  const handleDeleteClick = useCallback(() => {
    setIsDeleteConfirmOpen(true);
  }, [setIsDeleteConfirmOpen]);

  // fetch first page of comments
  useEffect(() => {
    dispatch(
      fetchCommentsAndMetadata({
        taskId: scope.id,
        offset: 0,
        limit: 4
      })
    );
  }, [dispatch, scope.id]);

  /* --------------------------------- Render --------------------------------- */

  const additionalParams = {
    filterStateId,
    groupBy
  };

  return (
    <>
      {!isDeleteConfirmOpen ? (
        <StyledScopeModal
          isOpen
          $isProjectViewScope={isProjectViewScope}
          $hasSideBody={!isProjectViewScope}
          wrapClassName="scope-modal-wrapper"
        >
          {/* Header */}
          <ScopeModalHeader
            scope={scope}
            handleDeleteClick={handleDeleteClick}
            isValid={isValid}
            handleClose={handleClose}
            handleDone={handleClose}
            isProjectViewScope={isProjectViewScope}
          />

          <ModalBodyContainer hasSideBody={!isProjectViewScope}>
            {/* Main body */}
            <MainBody>
              {/* Project/phase/activity titles */}
              <ParentInfo
                scope={scope}
                targetRef={projectPhaseActivitySelectorRef}
                onClick={
                  !isProjectViewScope
                    ? () => setIsProjectPhaseActivitySelectorOpen(true)
                    : undefined
                }
              />

              {/* Scope description */}
              <DescriptionSection
                text={scope.description}
                onSave={() => {
                  checkIfDescriptionValid();
                  flushUpdateScopeDescription();
                }}
                onChange={handleDescriptionChange}
                error={errors.description}
                autoFocus={!scope.description}
                disabled={isProjectViewScope}
              />

              <PaddingContainer>
                {/* Notes */}
                <NoteSection
                  text={scope.note}
                  onChange={handleNotesChange}
                  onSave={() => flushUpdateScopeNotes()}
                  disabled={isProjectViewScope}
                />

                {/* Attachments */}
                <AttachmentsSection
                  attachments={scope.attachments}
                  handleNewAttachments={addAttachmentsToScope}
                  handleDeleteAttachment={deleteAttachmentFromScope}
                />

                {/* Dates */}
                <DatesSection
                  startDate={scope.schedule_start}
                  endDate={scope.schedule_end}
                  handleDatesChange={updateScopeDates}
                  disabled={isProjectViewScope}
                />

                {/* Assignees */}
                <SelectMembersSection
                  sectionTitle="ASSIGNED"
                  idOrder={scope.assignees}
                  isAssignMultiple
                >
                  <BulkMemberSelector
                    projectId={scope.project_id}
                    phaseId={scope.phase_id}
                    activityPhaseId={scope.activity_phase_id}
                    scope={scope}
                    // renderSelect -> provided by SelectMembersSection
                    handleDone={handleUpdateAssignees}
                    membershipLevel="scope"
                    footerInitialCopy="Add All Project Members"
                    addedAccountIds={scope.assignees}
                    bulkActionAffectedMemberIds={
                      projectHash[scope.project_id]?.member_account_ids || []
                    }
                  />
                </SelectMembersSection>

                {/* Progress */}
                <ProgressSection percentComplete={scope.percent_complete} />

                {/* Comments */}
                {isProjectViewScope && <CommentsSection entity={scope} />}
              </PaddingContainer>

              {/* Delete button */}
              {!isProjectViewScope && (
                <DeleteButton onClick={handleDeleteClick} />
              )}
            </MainBody>

            {/* Comments / Activities body */}
            {!isProjectViewScope && <ModalSideBody entity={scope} />}
          </ModalBodyContainer>
        </StyledScopeModal>
      ) : (
        // Delete confirmation modal
        <DeleteScopesModal
          isOpen
          id={scope.id}
          handleClose={() => setIsDeleteConfirmOpen(false)}
          onConfirm={() => {
            toggle();
            setIsClosing(true);
          }}
          additionalDeleteParams={additionalParams}
        />
      )}

      <ProjectsThenPhasesSelector
        handleSelect={handleChangeActivityPhase}
        target={projectPhaseActivitySelectorRef}
        onClose={() => setIsProjectPhaseActivitySelectorOpen(false)}
        isOpen={isProjectPhaseActivitySelectorOpen}
        showBudgetInformation={false}
        renderSelector={false}
        hidePTO
      />

      {/* Move confirm modal */}
      {isMoveConfirmModalOpen && (
        <MoveScopeModal
          isOpen
          onClose={() => setIsMoveConfirmModalOpen(false)}
          onConfirm={() => setNextActivityPhaseId(null)}
          nextActivityPhaseId={nextActivityPhaseId}
          id={scope.id}
          additionalMoveParams={additionalParams}
          projectId={scope.project_id}
          // refetch the original project, since we are probably looking at that project
          // (will be improved with triggers)
        />
      )}
    </>
  );
};

export default ScopeModal;
