import { getQBActionOrDummyType } from 'appConstants/qbFetchActionType';
import * as constants from '../constants';
import { createAction } from 'appUtils';

export const toggleQuickbooksOpen = (params) =>
  createAction(constants.TOGGLE_QUICKBOOKS_OPEN, params);

export const linkQuickbooksSection = (params) =>
  createAction(constants.LINK_QUICKBOOKS_SECTION, params);

export const disconnectQuickbooksSection = (params) =>
  createAction(constants.DISCONNECT_QUICKBOOKS_SECTION, params);

export const fetchSyncSettings = (params) =>
  createAction(
    getQBActionOrDummyType(constants.FETCH_SYNC_SETTINGS.TRIGGER),
    params
  );

export const updateSyncSettings = (params) =>
  createAction(constants.UPDATE_SYNC_SETTINGS.TRIGGER, params);

export const fetchQbMembers = (params) =>
  createAction(constants.FETCH_QB_MEMBERS.TRIGGER, params);

export const fetchQbServices = (params) =>
  createAction(constants.FETCH_SERVICES.TRIGGER, params);

export const fetchImportedSubcustomers = (params) =>
  createAction(constants.FETCH_SUBCUSTOMERS.TRIGGER, params);

export const syncQbMembers = (params) =>
  createAction(constants.SYNC_MEMBERS.TRIGGER, params);

export const syncSubcustomersToProjects = (params) =>
  createAction(constants.SYNC_SUBCUSTOMERS_TO_PROJECTS.TRIGGER, params);

export const syncTimeEntries = (params) =>
  createAction(constants.SYNC_TIME_ENTRIES.TRIGGER, params);

export const syncServices = (params) =>
  createAction(constants.SYNC_ACTIVITIES.TRIGGER, params);

export const fetchSyncedMembers = (params) =>
  createAction(constants.FETCH_SYNCED_MEMBERS.TRIGGER, params);

export const fetchSyncedSubcustomers = (params) =>
  createAction(constants.FETCH_SYNCED_SUBCUSTOMERS.TRIGGER, params);

export const fetchSyncedServices = (params) =>
  createAction(constants.FETCH_SYNCED_SERVICE.TRIGGER, params);

export const updateSyncStatus = (params) =>
  createAction(constants.UPDATE_SYNC_STATUS.TRIGGER, params);

export const deleteSyncedMembers = (params) =>
  createAction(constants.DELETE_SYNCED_EMPLOYEES.TRIGGER, params);

export const deleteSyncedSubcustomers = (params) =>
  createAction(constants.DELETE_SYNCED_SUBCUSTOMERS.TRIGGER, params);

export const deleteSyncedServices = (params) =>
  createAction(constants.DELETE_SYNCED_ACTIVITIES.TRIGGER, params);

export const fetchSubSubCustomers = (params) =>
  createAction(constants.FETCH_SUB_SUB_CUSTOMERS.TRIGGER, params);

export const fetchSyncedSubSubCustomers = (params) =>
  createAction(constants.FETCH_SYNCED_SUB_SUB_CUSTOMERS.TRIGGER, params);

export const syncSubSubCustomers = (params) =>
  createAction(constants.SYNC_SUB_SUB_CUSTOMERS.TRIGGER, params);

export const fetchCustomers = (params) =>
  createAction(constants.FETCH_CUSTOMERS.TRIGGER, params);

export const setConfigDirQbToMosaic = (qbToMosaic) => ({
  type: constants.SET_CONFIG_DIR_QB_TO_MOSAIC,
  qbToMosaic
});

export const toggleSyncedMembers = (isShowOnlySynced) => ({
  type: constants.TOGGLE_SYNCED_MEMBERS,
  isShowOnlySynced
});

export const toggleSyncedServices = (isShowOnlySynced) => ({
  type: constants.TOGGLE_SYNCED_SERVICES,
  isShowOnlySynced
});

export const toggleSyncedSubCustomers = (isShowOnlySynced) => ({
  type: constants.TOGGLE_SYNCED_PROJECTS,
  isShowOnlySynced
});

export const openSubcustomerModal = (params) =>
  createAction(constants.OPEN_SUBCUSTOMER_MODAL, params);

export const closeSubcustomerModal = (params) =>
  createAction(constants.CLOSE_SUBCUSTOMER_MODAL, params);

export const toggleShowPhases = ({ id }) =>
  createAction(constants.TOGGLE_SHOW_PHASES, { id });

export const showPhases = ({ ids }) =>
  createAction(constants.SHOW_PHASES, { ids });

export const setHiddenCustomers = (customers) => ({
  type: constants.SET_HIDDEN_CUSTOMERS,
  customers
});

export const setHiddenTeams = (teams) => ({
  type: constants.SET_HIDDEN_TEAMS,
  teams
});

export const qbSearchChangeTrigger = ({ section, value }) =>
  createAction(constants.QB_SEARCH_CHANGE_TRIGGER, { section, value });

export const qbSearchChange = ({ section, value }) =>
  createAction(constants.QB_SEARCH_CHANGE, { section, value });

export const qbToggleBatchSelect = ({ itemType, items, value, isQbToMosaic }) =>
  createAction(constants.QB_TOGGLE_BATCH_SELECT, {
    itemType,
    items,
    value,
    isQbToMosaic
  });
export const qbFlushBatchSelect = ({ itemType, isQbToMosaic }) =>
  createAction(constants.QB_FLUSH_BATCH_SELECT, { itemType, isQbToMosaic });
export const toggleMappingFilter = ({ section, mappingFilter }) =>
  createAction(constants.TOGGLE_MAPPING_FILTER, { section, mappingFilter });

export const createNewActivitiesInBatch = (params) =>
  createAction(constants.CREATE_NEW_ACTIVITIES_IN_BATCH.TRIGGER, params);

export const preFillCreateNewRows = (projects) => ({
  type: constants.PREFILL_CREATE_NEW_ROWS,
  projects
});

export const flushPrefillCreateNewRows = () => ({
  type: constants.FLUSH_PREFILL_CREATE_NEW_ROWS
});

export const toggleActivitiesLoader = (isLoading) => ({
  type: constants.TOGGLE_SERVICES_LOADING,
  isLoading
});

export const initialFetchSyncedMembers = (isInitialFetching) => ({
  type: constants.INITIAL_FETCH_SYNC_MEMBERS,
  isInitialFetching
});

export const initialFetchSyncedSubCustomers = (isInitialFetching) => ({
  type: constants.INITIAL_FETCH_SYNC_PROJECTS,
  isInitialFetching
});

export const toggleMembersLoader = (isLoading) => ({
  type: constants.TOGGLE_MEMBERS_LOADING,
  isLoading
});

export const toggleProjectsLoader = (isLoading) => ({
  type: constants.TOGGLE_PROJECTS_LOADING,
  isLoading
});

export const qbSortChange = ({ section, sortColumn, order }) =>
  createAction(constants.QB_SORT_CHANGE, { section, sortColumn, order });

export const cancelSyncStatus = (params) =>
  createAction(constants.CANCEL_SYNC_STATUS, params);

export const undoPendingActivities = (params) =>
  createAction(constants.UNDO_PENDING_ACTIVITIES.TRIGGER, params);

export const undoPendingPhases = (params) =>
  createAction(constants.UNDO_PENDING_PHASES.TRIGGER, params);

export const undoPendingMembers = (params) =>
  createAction(constants.UNDO_PENDING_MEMBERS.TRIGGER, params);

export const undoPendingSubcustomers = (params) =>
  createAction(constants.UNDO_PENDING_SUBCUSTOMERS.TRIGGER, params);

export const deletePhaseMapping = (params) =>
  createAction(constants.DELETE_PHASE_MAPPING.TRIGGER, params);

export const toggleIsDefaultTeamSelected = (isDefaultTeamSelected) => ({
  type: constants.TOGGLE_DEFAULT_TEAM_ERROR,
  isDefaultTeamSelected
});
