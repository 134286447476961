import moment from 'moment';

export const getProjectPhaseTooltipContent = ({
  projectColor,
  projectName,
  projectDescription,
  phaseName,
  isDefaultPhase,
  activityName,
  isDefaultActivity,
  isBudgetPhase,
  startDate,
  endDate,
  totals,
  projectNumber,
  boardName,
  hideBottomRow = false,
  isTentative
}) => {
  return `
  <div class="right-box phase-tooltip-content">
  ${
    projectNumber || boardName
      ? `<div class="tooltip-row row-0">
      ${
        projectNumber
          ? `<div class="tooltip-bar-project-number">${projectNumber}</div>`
          : ''
      }
      ${
        boardName
          ? `<div class="tooltip-bar-board-name"><div class="tooltiip-bar-team-folder-icon"></div>${boardName}</div>`
          : ''
      }</div>`
      : ''
  }
    <div class="tooltip-row row-1">
      <div class="tooltip-bar-projectname">${projectName} </div>
      ${
        projectDescription
          ? ` <div class="tooltip-bar-description"> - ${projectDescription}</div>`
          : ''
      }
    </div>
    <div>
    ${
      hideBottomRow
        ? ''
        : `<div class="tooltip-bar-description phase-container">
    ${
      !isDefaultPhase && phaseName !== undefined
        ? `<div class="phase-milestone-icon"></div>${phaseName}`
        : ''
    }
    ${
      isDefaultActivity === false // only show id passed as an argument
        ? //only display | if phaseName is actually defined
          `${phaseName !== undefined ? `|` : ''} ${activityName}`
        : ''
    }
    </div>`
    }

    </div>
    ${
      isTentative
        ? `<div class="tooltip-row row-1">
           ${'Tentative Plan'}
          </div>
        `
        : ''
    }
    <div class="tooltip-row row-3" >
    <div class="tooltip-date-container">${
      startDate && endDate
        ? `${moment(startDate).format('MMM D')} -
      ${moment(endDate).format('MMM D')}`
        : ''
    }

    </div>
    <div class="tooltip-totals-container">
    ${totals}
    </div>
    </div>
  </div>
  `;
};
