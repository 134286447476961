import styled from 'styled-components';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';
import { Styles } from 'components/Table/styles';
import { StyledReportHeader } from 'ReportsModule/components/styles';

export const ActivityTableStyler = styled.div`
  height: 100%;
  ${Styles} {
    padding: 0;
    height: ${(props) => (props.isLoading ? 0 : '100%')};
  }
  .table {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table-body {
    min-height: 0;
  }
  .variable-size-list {
    height: 100% !important;
  }

  .tr.activityRow {
    .threeDotMenu {
      visibility: hidden;
    }
    .actor {
      border-right: 1px solid ${theme.colors.colorLightGray6};
    }
    border-bottom: 1px solid ${theme.colors.colorPaleGray7};
    &:hover {
      .threeDotMenu {
        visibility: visible;
      }
    }
  }
`;

export const StyledCapacityReportTable = styled.div`
  ${Styles} {
    padding: 0;
  }
  ${StyledReportHeader} {
    font-size: 12px;
    text-transform: uppercase;
    pointer-events: none;
    color: ${theme.colors.colorMediumGray};
  }
  .member ${StyledReportHeader} {
    justify-content: flex-end;
    padding-right: 6px;
  }

  .tables-header-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${theme.colors.colorTranslucentGray4};
    ${(props) => !props.loaded && `visibility: hidden`}
  }
  .variable-size-list {
  }
  .table {
    margin-bottom: 0;
  }
  .table-column-header {
    z-index: 1;
    border-bottom: 1px solid ${theme.colors.colorLightGray6};
  }

  .empty {
    visibility: hidden;
  }

  .tr.memberRow {
    display: flex;
    align-items: flex-end;
    color: ${theme.colors.colorSemiDarkGray1};

    .graph {
      border-right: 1px solid ${theme.colors.colorLightGray6};
      border-left: 1px solid ${theme.colors.colorLightGray6};
    }
    .show-on-hover {
      visibility: hidden;
      margin-top: -40px;
    }

    :hover {
      .show-on-hover {
        color: #4f4f4f;
        visibility: visible;
      }
    }
  }
  .tr.emptyRow {
    background: ${theme.colors.colorTranslucentGray4};
  }
  .last-full-row {
    border-bottom: 1px solid ${theme.colors.colorLightGray6};
  }

  .variable-size-list {
    background: linear-gradient(#f3f3f3 4px, transparent 0),
      linear-gradient(
        90deg,
        transparent 5px,
        transparent 5px,
        ${theme.colors.colorLightGray6} 5px,
        ${theme.colors.colorLightGray6} 6px,
        transparent 7px,
        transparent 13px
      ),
      linear-gradient(
        0deg,
        transparent 8px,
        transparent 6px,
        ${theme.colors.colorLightGray6} 6px,
        #cdcdcd 7px,
        transparent 8px,
        transparent 59px
      ),
      #f3f3f3;
    background-size: 9px 9px, ${(props) => (props.isPercent ? 60 : 50)}px 109px,
      100% 6px, 109px 109px, 109px 109px;
    background-position: 56px 55px,
      ${(props) => (props.isPercent ? 54 : 24)}px 0px, 0px 0px, 0px 0px, 0px 0px;
  }
  .td {
    height: 100%;
  }
  .td.member,
  .td.total {
    background: #f3f3f3;
  }
`;

export const StyledTooltip = styled(ReactTooltip)`
  font-size: 11px;
  font-weight: normal;
  padding: 8px;
  text-align: center;
  z-index: 1000;
  max-width: 240px;
`;
