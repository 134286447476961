import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { useProjectBudgetStatusFilter } from 'FilterModule/hooks';
import { FilterListTypeDropdown } from 'FilterModule/types';

export const ProjectBudgetStatusFilterDropdown: FilterListTypeDropdown = ({
  renderToggle,
  field
}) => {
  const {
    updateSelectedItems,
    optionsArray,
    itemHash,
    selectedItems,
    labelKey
  } = useProjectBudgetStatusFilter({
    field,
    shouldExcludeArchivedOption: true
  });

  return (
    <SimpleFilterDropdown
      itemHash={itemHash}
      labelKey={labelKey!}
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isBatchClearOnly
      itemHeight={55}
      listWidth={250}
      listHeight={230}
      renderToggle={renderToggle}
      isSearchEnabled={false}
    />
  );
};
