import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PlayVideoIcon = ({ width = '28', height = '28', fill = '#FE69B3' }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0Z"
        fill={fill}
      />
      <path
        d="M22.1 18.5002V9.5002C22.1 8.5102 21.29 7.7002 20.3 7.7002H8.59999C7.60999 7.7002 6.79999 8.5102 6.79999 9.5002V18.5002C6.79999 19.4902 7.60999 20.3002 8.59999 20.3002H20.3C21.29 20.3002 22.1 19.4902 22.1 18.5002ZM12.2 17.6002V10.4002L17.6 14.0002L12.2 17.6002Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default PlayVideoIcon;
