import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addParsedRouteParams, removeParsedRouteParams } from 'actionCreators';

const withRouterParams = (Component, skipUnmount) => {
  class WithRouterParams extends React.Component {
    constructor(props) {
      super(props);
      const { addParsedRouteParams, match } = props;
      addParsedRouteParams({ ...match.params });
    }

    shouldComponentUpdate(nextProps, nextState) {
      const { auth, match, addParsedRouteParams } = this.props;
      const { auth: nextAuth, match: nextMatch } = nextProps;
      const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
      if (shouldUpdate) {
        addParsedRouteParams({ ...nextProps.match.params });
      }
      return shouldUpdate;
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({});

  const mapDispatchToProps = {
    addParsedRouteParams,
    removeParsedRouteParams
  };

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WithRouterParams)
  );
};
export default withRouterParams;
