import React from 'react';
import {
  ColumnPropertyCell,
  EmptyStateContainer,
  StyledProjectPriorityContainer,
  StyledProjectPriorityIcon
} from './styles';
import { connect } from 'react-redux';
import { getProjectPriorities, getProjectPrioritiesHash } from 'selectors';
import OptionsSelectFlyout from 'components/OptionsSelectFlyout/OptionsSelectFlyout';
import ProjectPriorityListItem from './ProjectPriorityListItem';
import { StyledPriorityIcon } from 'views/Home/TaskList/styles';
import theme from 'theme';

const copy = {};

class ProjectPriority extends React.PureComponent {
  state = {
    isDropdownOpen: false
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };

  renderSelectedItem = (item) =>
    item.color && (
      <>
        <StyledProjectPriorityContainer>
          <StyledProjectPriorityIcon currentColor={item.color} />
        </StyledProjectPriorityContainer>
      </>
    );

  render() {
    const {
      projectId,
      priorityId,
      onSubmit,
      outerPopoverTarget,
      //
      priorities,
      prioritiesHash
    } = this.props;
    const { isDropdownOpen } = this.state;
    const priority = prioritiesHash[priorityId] ?? {};

    return (
      <ColumnPropertyCell onClick={this.toggleDropdown}>
        <OptionsSelectFlyout
          selectedItem={priority}
          listItems={priorities}
          rowId={projectId}
          outerPopoverTarget={outerPopoverTarget}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={this.toggleDropdown}
          onSubmit={onSubmit}
          ListItemComponent={ProjectPriorityListItem}
          copy={copy}
          droppableType="priorityOrder"
          renderSelectedItem={this.renderSelectedItem}
        />
        {!priority.title && (
          <EmptyStateContainer show isBlue={isDropdownOpen}>
            <StyledPriorityIcon
              className="no-priority"
              stroke={theme.colors.colorMediumGray1}
              currentColor="none"
            />
          </EmptyStateContainer>
        )}
      </ColumnPropertyCell>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    priorities: getProjectPriorities(state),
    prioritiesHash: getProjectPrioritiesHash(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ProjectPriority);
