import {
  FilterListType,
  memberFilterListTypes,
  FilterField,
  crossFilterFieldOverrideHash
} from 'FilterModule/constants';
import { FilterFieldSchema, CurrentFilter } from 'FilterModule/types';
import {
  makeDefaultFilterListTypeFieldSchema,
  defaultNumberArrayFieldSchema,
  defaultStringArrayFieldSchema
} from '.';

// this file contains schemas necessary for cross filtering of members (when using stackedFilterOrder and mainFilterListType)

const emptyObj = {};

// poor name but filter field will be returned as [] for the associated mainFilterListType
const filterFieldToUnrelatedMainFilterListType = {
  [FilterField.work_group_ids_cf]: FilterListType.MembersByDepartment,
  [FilterField.office_ids_cf]: FilterListType.MembersByOffice,
  [FilterField.region_ids_cf]: FilterListType.MembersByRegion,
  [FilterField.position_ids_cf]: FilterListType.MembersByPosition,
  [FilterField.skillLevels_cf]: FilterListType.MembersBySkill,
  [FilterField.board_ids_cf]: FilterListType.MembersByPortfolio,
  [FilterField.discipline_ids_cf]: FilterListType.MembersByDiscipline
};

/**
 * Purpose is to return [] for fields that are being filtered out in
 * stackedFilterOrder's getValidatedFieldValue
 */
const getValidatedFieldValueBasedOnMainFilterListType = <
  T extends number | string
>({
  currentFilter,
  originalFieldValue,
  field
}: {
  currentFilter: CurrentFilter;
  originalFieldValue: T[];
  field: FilterField;
}) => {
  if (
    currentFilter.mainFilterListType ===
    filterFieldToUnrelatedMainFilterListType[field]
  ) {
    return [];
  }
  return originalFieldValue;
};

const defaultMemberCrossFieldFilterListTypes = [
  FilterListType.Departments,
  FilterListType.Disciplines,
  FilterListType.EmploymentType,
  FilterListType.Offices,
  FilterListType.Portfolios,
  FilterListType.Positions,
  FilterListType.Regions,
  FilterListType.SkillLevels
];

const defaultMemberCrossFieldDependencies =
  defaultMemberCrossFieldFilterListTypes.flatMap(
    (filterListType) => crossFilterFieldOverrideHash[filterListType] || []
  );

const groupedFilterListTypeToParentEntityFilterListType = {
  [FilterListType.MembersByDepartment]: FilterListType.Departments,
  [FilterListType.MembersByDiscipline]: FilterListType.Disciplines,
  [FilterListType.MembersByOffice]: FilterListType.Offices,
  [FilterListType.MembersByPortfolio]: FilterListType.Portfolios,
  [FilterListType.MembersByPosition]: FilterListType.Positions,
  [FilterListType.MembersByRegion]: FilterListType.Regions,
  [FilterListType.MembersBySkill]: FilterListType.SkillLevels
};

const getMemberCrossFieldDependenciesWithout = (removedField: FilterField) =>
  defaultMemberCrossFieldDependencies.filter((field) => field !== removedField);

const makeDefaultMemberFilterListTypesFieldSchema = ({
  defaultValue = FilterListType.Members,
  isSaveable,
  ...additionalOptionHashParams
}: {
  defaultValue?: FilterListType;
  isSaveable?: false;
  selectionLimit?: number;
  isSingleSelect?: boolean;
} = emptyObj): FilterFieldSchema<FilterListType> => {
  return makeDefaultFilterListTypeFieldSchema<{
    isSingleSelect?: boolean;
    crossFieldDependencies?: string[];
    selectionLimit?: number | null;
  }>({
    defaultValue,
    isSaveable,
    optionsArray: memberFilterListTypes,
    optionHash: {
      [FilterListType.Members]: {
        crossFieldDependencies: defaultMemberCrossFieldDependencies,
        ...additionalOptionHashParams
      },
      [FilterListType.MembersByDepartment]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.work_group_ids_cf
        ),
        ...additionalOptionHashParams
      },
      [FilterListType.MembersByPosition]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.position_ids_cf
        ),
        ...additionalOptionHashParams
      },
      [FilterListType.MembersBySkill]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.skillLevels_cf
        ),
        ...additionalOptionHashParams
      },
      [FilterListType.MembersByOffice]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.office_ids_cf
        ),
        ...additionalOptionHashParams
      },
      [FilterListType.MembersByRegion]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.region_ids_cf
        ),
        ...additionalOptionHashParams
      },
      [FilterListType.MembersByDiscipline]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.discipline_ids_cf
        ),
        ...additionalOptionHashParams
      },
      [FilterListType.MembersByPortfolio]: {
        crossFieldDependencies: getMemberCrossFieldDependenciesWithout(
          FilterField.board_ids_cf
        ),
        ...additionalOptionHashParams
      }
    }
  });
};

export type MembersCrossFilterFieldSchemas = {
  [FilterField.mainFilterListType]: FilterFieldSchema<
    FilterListType,
    FilterListType,
    {
      isSingleSelect?: boolean;
      crossFieldDependencies?: string[];
      selectionLimit?: number;
    }
  >;
  [FilterField.stackedFilterOrder]: FilterFieldSchema<
    FilterListType,
    FilterListType[],
    {
      filterLabel?: string;
    }
  >;
  [FilterField.work_group_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.account_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.office_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.region_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.position_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.skillLevels_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.discipline_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.board_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.employmentType_cf]: typeof defaultStringArrayFieldSchema;
};

export const makeDefaultMemberCrossFiltersFieldSchemas = ({
  selectionLimit
}: {
  selectionLimit?: number;
} = emptyObj): MembersCrossFilterFieldSchemas => {
  return {
    [FilterField.mainFilterListType]:
      makeDefaultMemberFilterListTypesFieldSchema({
        selectionLimit
      }),

    [FilterField.account_ids]: defaultNumberArrayFieldSchema,

    [FilterField.employmentType_cf]: defaultStringArrayFieldSchema,

    [FilterField.office_ids_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.office_ids_cf
        });
      }
    },

    [FilterField.region_ids_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.region_ids_cf
        });
      }
    },

    [FilterField.position_ids_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.position_ids_cf
        });
      }
    },

    [FilterField.skillLevels_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.skillLevels_cf
        });
      }
    },

    [FilterField.discipline_ids_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.discipline_ids_cf
        });
      }
    },

    [FilterField.work_group_ids_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.work_group_ids_cf
        });
      }
    },

    [FilterField.board_ids_cf]: {
      defaultValue: [],
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        return getValidatedFieldValueBasedOnMainFilterListType({
          currentFilter,
          originalFieldValue,
          field: FilterField.board_ids_cf
        });
      }
    },

    [FilterField.stackedFilterOrder]: {
      // spec: alphabetical by label
      defaultValue: defaultMemberCrossFieldFilterListTypes,
      // spec: for grouped mainFilterListType, filter out the entity filter from stackedFilterOrder
      // eg. members grouped by office -> filter out office filter
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        const mainFilterListType = currentFilter.mainFilterListType;

        if (
          mainFilterListType &&
          mainFilterListType in
            groupedFilterListTypeToParentEntityFilterListType
        ) {
          return originalFieldValue.filter(
            (filterListType) =>
              filterListType !==
              groupedFilterListTypeToParentEntityFilterListType[
                mainFilterListType
              ]
          );
        }
        return originalFieldValue;
      }
    }
  };
};
