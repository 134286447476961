import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CompletedIcon = ({
  height = '18',
  width = '18',
  strokeWidth = '0',
  className,
  color = '#0074d9'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      className={className}
    >
      <g fill="none" fillRule="evenodd" transform="translate(2.5 2.5)">
        <circle cx="7.5" cy="7.5" r="7.5" fill="transparent" stroke={color} />
        <path
          fill={color}
          d="M6.521 10.775L3.75 8.004l.781-.782 1.99 1.985L10.728 5l.781.787z"
          strokeWidth={strokeWidth}
          stroke={color}
        />
      </g>
    </SvgIcon>
  );
};
export default CompletedIcon;
