import { Draft } from '@reduxjs/toolkit';
import {
  StateWithIsoStates,
  MetaWithIsoState,
  SuccessIsoStateModifier,
  FailureIsoStateModifier
} from '../types';
import { ValueOf } from 'type-fest';
export * from './defaultIsoStateUtils';

/**
 * Infer types using MetaType. Remove undefined from the type in order to handle meta? within the function
 * and have proper types (vs. checking for undefined before passing into the function)
 */
type HandleModifyIsoStateParams<StateType, MetaType, ModifierType> =
  NonNullable<MetaType> extends
    | MetaWithIsoState<
        StateType,
        infer IsoStateType,
        infer MetaParamsType,
        infer PayloadType,
        infer ResponseType,
        infer ErrorType,
        infer FailureResponseType,
        infer IsoStateModifiersType
      >
    | undefined
    ? ModifierType extends ValueOf<IsoStateModifiersType>
      ? ModifierType extends SuccessIsoStateModifier<
          StateType,
          IsoStateType,
          PayloadType,
          MetaType,
          ResponseType
        >
        ? {
            modifier: ModifierType;
            state: StateType;
            initialPayload: PayloadType;
            response?: ResponseType;
            meta?: MetaType;
            error?: never;
          }
        : ModifierType extends FailureIsoStateModifier<
            StateType,
            IsoStateType,
            PayloadType,
            MetaType,
            ErrorType,
            FailureResponseType
          >
        ? {
            modifier: ModifierType;
            state: StateType;
            initialPayload: PayloadType;
            response?: FailureResponseType;
            error?: ErrorType;
            meta?: MetaType;
          }
        : {
            modifier: ModifierType;
            state: StateType;
            initialPayload: PayloadType;
            response?: unknown;
            error?: unknown;
            meta?: MetaType;
          }
      : never
    : never;

/**
 * For mutating draft isoStates
 */
export const handleModifyIsoState = <
  StateType extends Draft<StateWithIsoStates>,
  MetaType extends MetaWithIsoState,
  ModifierType
>({
  modifier,
  state,
  meta,
  initialPayload,
  response,
  error
}: HandleModifyIsoStateParams<StateType, MetaType, ModifierType>) => {
  if (meta) {
    const { isoStateId } = meta;
    const isoState = isoStateId ? state.isoStates[isoStateId] : undefined;
    if (isoState && modifier) {
      modifier({
        initialPayload,
        meta,
        state,
        isoState,
        response,
        error
      });
    }
  }
};
