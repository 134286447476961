import styled from 'styled-components';
import theme from 'theme';

export const PillContainer = styled.div`
  width: 100px;
  background-color: ${theme.colors.colorPaleGray2};
  display: flex;
  align-items: center;
`;

export const StyledProgressPill = styled.div`
  flex-basis: ${(props) => props.barWidth}%;
  background-color: #7ed321;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 18px;
  padding: 0px;
`;

export const ProgressPillIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 1px;
`;
