import React from 'react';
import { createPortal } from 'react-dom';
import { Editor } from 'slate-react';
import RichTextToolbar from './RichTextToolbar';
import { FloatingToolbar } from './styles';
import { plugins } from 'appUtils/slate';

const defaultEditorStyle = {
  height: '100%'
};

class SlateEditor extends React.Component {
  schema = {
    blocks: {
      image: {
        isVoid: true
      }
    }
  };

  menu = React.createRef();

  componentDidMount = () => {
    this.updateHoverMenu();
  };

  componentDidUpdate = () => {
    this.updateHoverMenu();
  };

  updateHoverMenu = () => {
    const menu = this.menu.current;
    if (!menu) return;

    const value = this.props.value;
    const { fragment, selection } = value;

    if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
      menu.removeAttribute('style');
      return;
    }

    const native = window.getSelection();
    const range = native.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    menu.style.opacity = 1;
    menu.style.top = `${rect.top - window.pageYOffset - menu.offsetHeight}px`;

    menu.style.left = `${
      rect.left - window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2
    }px`;
  };

  renderEditor = (props, editor, next) => {
    const { readOnly } = props;
    const children = next();

    if (readOnly) {
      return <>{children}</>;
    } else {
      const root = document.getElementById('root');
      return (
        <>
          {children}
          {createPortal(
            <FloatingToolbar ref={this.menu}>
              <RichTextToolbar editor={editor} />
            </FloatingToolbar>,
            root
          )}
        </>
      );
    }
  };

  renderNode = (props, editor, next) => {
    const { attributes, children, node, isFocused } = props;

    switch (node.type) {
      case 'paragraph':
        return (
          <p style={{ marginBottom: '0' }} {...attributes}>
            {children}
          </p>
        );
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'code':
        return (
          <pre>
            <code {...attributes}>{children}</code>
          </pre>
        );
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>;
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>;
      case 'heading-three':
        return <h3 {...attributes}>{children}</h3>;
      case 'heading-four':
        return <h4 {...attributes}>{children}</h4>;
      case 'heading-five':
        return <h5 {...attributes}>{children}</h5>;
      case 'heading-six':
        return <h6 {...attributes}>{children}</h6>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'link': {
        const { data } = node;
        const href = data.get('href');
        return (
          <a href={href} {...attributes}>
            {children}
          </a>
        );
      }
      case 'image': {
        const src = node.data.get('src');
        return <Image src={src} selected={isFocused} {...attributes} />;
      }

      default: {
        return next();
      }
    }
  };

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'code':
        return <code {...attributes}>{children}</code>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underlined':
        return <u {...attributes}>{children}</u>;
      default:
        return next();
    }
  };

  render() {
    const {
      setEditorRef,
      onChange,
      readOnly,
      value,
      placeholder,
      editorStyle = defaultEditorStyle
    } = this.props;
    return (
      <Editor
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        ref={setEditorRef}
        schema={this.schema}
        renderEditor={this.renderEditor}
        renderNode={this.renderNode}
        renderMark={this.renderMark}
        plugins={plugins}
        placeholder={placeholder}
        style={editorStyle}
      />
    );
  }
}

export default SlateEditor;
