import theme from 'theme';
import { setBarContextMenuId } from 'actionCreators';
import Popover from 'components/Popover';
import ScheduleBarContextMenu from '../../ScheduleBarContextMenu';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import cn from 'classnames';
import { useAppDispatch } from 'reduxInfra/hooks';
import { MENU_SIZE } from '../constants';
import styled from 'styled-components';

export const ThreeDotMenu = ({
  barProps: {
    activateTaskSplitScreen,
    canCreate,
    canDelete,
    canEdit,
    handleCloseContextMenu,
    handleScheduleBarCopy,
    isMenuOpenDate,
    item,
    openDeleteModal,
    popoverTarget,
    setIsMenuOpen,
    setSplitScheduleBar,
    zoom
  },
  startDate
}) => {
  const dispatch = useAppDispatch();
  const isMenuOpen = isMenuOpenDate === startDate;

  return (
    <Menu
      className={cn({ open: isMenuOpen }, 'three-dot-menu')}
      onClick={(e) => {
        e.stopPropagation();
        setIsMenuOpen(startDate);
        dispatch(setBarContextMenuId({ itemId: item.id }));
      }}
      ref={isMenuOpen ? popoverTarget : null}
    >
      <ProjectThreeDotMenu
        initialColor={theme.colors.colorRoyalBlue}
        onCloseCallback={() => setIsMenuOpen(null)}
        threeDotClassName="project-three-dot-icon"
      />
      {isMenuOpen && (
        <Popover
          isOpen={true}
          closePopover={handleCloseContextMenu}
          target={popoverTarget}
          className="schedule-bar-popover"
        >
          <ScheduleBarContextMenu
            scheduleBar={item}
            handleScheduleBarCopy={handleScheduleBarCopy}
            setSplitScheduleBar={setSplitScheduleBar}
            openDeleteModal={openDeleteModal}
            onSelect={handleCloseContextMenu}
            handleCloseContextMenu={handleCloseContextMenu}
            activateTaskSplitScreen={activateTaskSplitScreen}
            zoom={zoom}
            canDelete={canDelete}
            canEdit={canEdit}
            canCreate={canCreate}
          />
        </Popover>
      )}
    </Menu>
  );
};

const Menu = styled.div`
  border-radius: 50%;
  display: none;

  // Compensate for the zero-height three-dot icon so that the menu is
  // displayed below the three-dot icon.
  align-items: center;
  align-self: stretch;

  &.open {
    display: inline-flex;

    .project-three-dot-icon {
      background-color: ${theme.colors.colorPaleGray2};
    }
  }

  // Prevent the three-dot icon from affecting the vertical layout.
  .three-dot-menu-wrapper {
    height: 0;
  }

  // Make the background of the three-dot icon a little smaller than typical so
  // that it fits into the bar more cleanly.
  .project-three-dot-icon {
    border-radius: ${(MENU_SIZE - 12) / 2}px;
    height: ${MENU_SIZE - 12}px;
    width: ${MENU_SIZE}px;

    &:hover {
      background-color: ${theme.colors.colorPaleGray2};
    }
  }
`;
