import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  OptionsDropdown,
  DoneButton,
  DropdownScrollableContainer,
  NewStatusText,
  DropdownItemComponent
} from 'views/Home/Tasks/styles';

import { stopPropagation } from 'appUtils';

import Popover from 'components/Popover';
const defaultRenderSelectItem = (item) => item.title;

class OptionsSelectFlyout extends React.PureComponent {
  state = {
    editMode: false,
    isDropdownOpen: false,
    editingId: null,
    selectedItem: this.props.selectedItem
  };

  componentDidUpdate(prevProps) {
    const { selectedItem } = this.props;
    if (!prevProps.selectedItem !== selectedItem) {
      this.setState({ selectedItem });
    }
  }

  toggleEditing = () => this.setState({ editMode: !this.state.editMode });
  setEditing = () => this.setState({ editMode: true });
  setSelectedItem = (selectedItem) => {
    this.setState({ selectedItem }, this.handleSubmit);
  };

  unselectItem = () => this.setSelectedItem({ id: null });

  setNewStatusEditing = () => {
    this.setState({ editingId: 'new' });
  };

  handleEditClick = (selectedItem) => {
    this.setState({ editingId: selectedItem.id });
  };

  handleEditClear = () => this.setState({ editingId: null });

  handleSubmit = () => {
    const { selectedItem } = this.state;
    const { onSubmit } = this.props;
    onSubmit(selectedItem.id);
    this.handleEditClear();
    this.toggleDropdown();
  };

  toggleDropdown = () => {
    const { toggleDropdown } = this.props;
    toggleDropdown();
    this.setState({
      editMode: false,
      editingId: null
    });
  };

  renderDropdownOption = (listItem, index) => {
    if (!listItem) {
      return null;
    }
    const { editingId, editMode } = this.state;
    const { ListItemComponent } = this.props;
    if (editingId && editingId !== listItem.id) {
      return null;
    }
    return (
      <Draggable
        draggableId={`${listItem.id}`}
        index={index}
        key={listItem.id}
        isDragDisabled={!editMode && !editingId}
      >
        {(provided, snapshot) => (
          <ListItemComponent
            setSelectedItem={this.setSelectedItem}
            setEditing={this.handleEditClick}
            isEditing={editingId === listItem.id}
            editMode={editMode}
            listItem={listItem}
            key={listItem.id}
            handleEditClear={this.handleEditClear}
            provided={provided}
            index={index}
            innerRef={provided.innerRef}
            snapshot={snapshot}
          />
        )}
      </Draggable>
    );
  };

  renderAddStatusOption = () => {
    const { editingId, editMode } = this.state;
    const { ListItemComponent, copy } = this.props;
    return editingId !== 'new' ? (
      <NewStatusText onClick={this.setNewStatusEditing}>
        {copy && copy.createNew}
      </NewStatusText>
    ) : (
      <ListItemComponent
        setSelectedItem={this.setSelectedItem}
        handleEditClear={this.handleEditClear}
        isEditing={editingId === 'new'}
        editMode={editMode}
        setEditing={this.handleEditClick}
        isNew
      />
    );
  };

  renderUnselectOption = () => {
    const { ListItemComponent } = this.props;
    return <ListItemComponent setSelectedItem={this.unselectItem} isUnselect />;
  };

  renderOptionsDropdown = () => {
    const { editingId } = this.state;
    return (
      <>
        {editingId === 'new'
          ? this.renderAddStatusOption()
          : this.renderTaskStatusList()}
      </>
    );
  };

  renderTaskStatusList = () => {
    const { listItems, rowId, droppableType } = this.props;
    const { editingId, editMode } = this.state;
    return (
      <>
        {!editingId && this.renderAddEdit()}
        <DropdownScrollableContainer>
          <Droppable
            droppableId={`${droppableType}-${rowId}`}
            type={droppableType}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ padding: '10px 0 0 0' }}
              >
                {listItems.map(this.renderDropdownOption)}
                {provided.placeHolder}
              </div>
            )}
          </Droppable>
          {!editingId && !editMode && this.renderUnselectOption()}
        </DropdownScrollableContainer>
      </>
    );
  };

  renderDoneButton = () => {
    const { editingId, editMode } = this.state;
    const { copy, isOnProjectView } = this.props;
    if (editingId) return null;
    const buttonText = editMode ? 'Done' : copy && copy.addEdit;
    return (
      buttonText && (
        <DoneButton
          isBold={editMode}
          onClick={this.toggleEditing}
          isBlueButton={isOnProjectView && editMode}
        >
          {buttonText}
        </DoneButton>
      )
    );
  };

  renderAddEdit = () => {
    const { editMode } = this.state;
    if (editMode) return this.renderAddStatusOption();
    return null;
  };

  render() {
    const { selectedItem = {}, editingId } = this.state;
    const {
      isDropdownOpen,
      renderSelectedItem = defaultRenderSelectItem,
      shouldNotCenter,
      outerPopoverTarget
    } = this.props;
    return (
      <DropdownItemComponent
        isDropdownOpen={isDropdownOpen}
        hasTitle={selectedItem.title}
        ref={(ref) => (this.popoverTarget = ref)}
        shouldNotCenter={shouldNotCenter}
      >
        {renderSelectedItem(selectedItem)}

        <Popover
          isOpen={isDropdownOpen}
          target={outerPopoverTarget || this.popoverTarget}
          togglePopover={this.toggleDropdown}
        >
          <div style={{ boxShadow: '0px 1px 4px rgba(82, 82, 82, 0.5)' }}>
            <OptionsDropdown onClick={stopPropagation}>
              {editingId === 'new'
                ? this.renderAddStatusOption()
                : this.renderOptionsDropdown()}
            </OptionsDropdown>
            {this.renderDoneButton()}
          </div>
        </Popover>
      </DropdownItemComponent>
    );
  }
}

export default OptionsSelectFlyout;
