import {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import escape from 'lodash/escape';

/**
 * The behavior of the tooltip for the `EllipsisText` component.
 */
export enum TooltipBehavior {
  /**
   * The tooltip will be disabled regardless of the width of the text container
   * element relative to the text width.
   */
  AlwaysDisabled,

  /**
   * The tooltip will be enabled regardless of the width of the text container
   * element relative to the text width.
   */
  AlwaysEnabled,

  /**
   * The tooltip will be enabled if the width of the text container element
   * is insufficient to contain the text.
   */
  EnabledOnEllipsis
}

/**
 * Truncates text that exceeds a single line of text in the available width and
 * adds a trailing ellipsis. By default, a tooltip will be enabled when the text
 * is truncated.
 */
const EllipsisText = ({
  children,
  className,
  maxWidth,
  style,
  tooltip,
  tooltipBehavior = TooltipBehavior.EnabledOnEllipsis,
  width,
  ...divProps
}: PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    /**
     * The maximum width of the text container element. Defaults to `none`.
     */
    maxWidth?: string | number;

    /**
     * Sets the tooltip text. Defaults to the text contents of `children`.
     */
    tooltip?: string | number;

    /**
     * Flag indicating the behavior of the tooltip. This is useful when the
     * tooltip and the text have differing content or if no tooltip should be
     * shown. Defaults to `TooltipBehavior.EnableOnEllipsis`.
     */
    tooltipBehavior?: TooltipBehavior;

    /**
     * The width of the text container element. Defaults to `auto`.
     */
    width?: string | number;
  }
>) => {
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(false);
  const [tooltipContents, setTooltipContents] = useState<string>();

  const containerRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver>();

  useEffect(() => {
    setIsTooltipEnabled(tooltipBehavior === TooltipBehavior.AlwaysEnabled);

    // Disconnect the observer if one was already set (from a change in value of
    // `alwaysShowTooltip`).
    resizeObserverRef.current?.disconnect();

    // If the tooltip is always in a single state, there is no need to track the
    // size of the text container.
    if (tooltipBehavior !== TooltipBehavior.EnabledOnEllipsis) return;

    // Observe the size of the text container so that the tooltip can be enabled
    // or disabled accordingly. The tooltip must be enabled if the size of the
    // container becomes smaller than the size of the text.
    resizeObserverRef.current ??= new ResizeObserver(() => {
      const container = containerRef.current;
      setIsTooltipEnabled(
        container !== null && container.offsetWidth < container.scrollWidth
      );
    });

    const resizeObserver = resizeObserverRef.current;
    const container = containerRef.current;

    // Start the observer when the component mounts.
    if (container && resizeObserver) resizeObserver.observe(container);

    // Stop the observer when the component dismounts.
    return () => resizeObserver.disconnect();
  }, [tooltipBehavior]);

  // Prepare the tooltip contents.
  useEffect(() => {
    setTooltipContents(
      !tooltip ? escape(containerRef.current?.innerText) : tooltip.toString()
    );
  }, [children, tooltip]);

  // Update the tooltip accordingly.
  useEffect(() => {
    if (isTooltipEnabled) rebuildTooltip();
    else ReactTooltip.hide(containerRef.current ?? undefined);
  }, [isTooltipEnabled]);

  return (
    <Text
      {...defaultTooltipProps}
      $maxWidth={maxWidth}
      $width={width}
      className={className}
      data-class="center mw-250"
      data-tip-disable={!isTooltipEnabled}
      data-tip={tooltipContents}
      ref={containerRef}
      style={style}
      {...divProps}
    >
      {children}
    </Text>
  );
};

interface TextProps {
  $maxWidth?: string | number;
  $width?: string | number;
}
const Text = styled.div.attrs<TextProps>(({ $maxWidth, $width }) => ({
  style: { maxWidth: $maxWidth, width: $width }
}))<TextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default EllipsisText;
