import styled from 'styled-components';

export const BaseFlatHeaderCell = ({ column }) => {
  if (!column.headerLabel) {
    return <></>;
  }
  return (
    <HeaderCellContainer>
      <ColumnHeadersContainer>
        <ColumnHeaderText>{column.headerLabel}</ColumnHeaderText>
      </ColumnHeadersContainer>
    </HeaderCellContainer>
  );
};

const HeaderCellContainer = styled.div`
  height: 100%;
`;

const ColumnHeadersContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const ColumnHeaderText = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  text-transform: uppercase;
`;
