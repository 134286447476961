import React from 'react';
import cn from 'classnames';
import 'react-date-picker/index.css';
import { TaskDatePicker } from '../..';
import dueDateFlag from 'images/due-date-flag.svg';
import { isToday } from 'appUtils/momentUtils';
import flagBlue from 'images/flag-solid-blue.svg';
import flagGray from 'images/flag-solid-gray.svg';
export function stopEvent() {
  return (event) => event.stopPropagation();
}

const getDateText = ({ dueToday, scheduledToday, date, calendarText }) => {
  if (dueToday || scheduledToday) {
    return 'Today';
  } else if (date) {
    return date;
  } else {
    return calendarText;
  }
};

const TaskDateDisplay = (props) => {
  const {
    date,
    overdue,
    openCalendar,
    isOnTaskModal,
    beingEdited,
    datePickerOpen,
    updateDueAt,
    updateScheduleStart,
    datePickerDate,
    task,
    calendarText,
    setEditTaskId,
    isDailyTask,
    closeCalendar,
    isCreatingNewTask,
    isOnHome,
    shouldDisplayFlag,
    style,
    mouseX,
    mouseY
  } = props;

  const isDueDateButton = calendarText === 'Due Date';
  const isScheduleButton = calendarText === 'Schedule';
  const dueToday = isDueDateButton && isToday(task.due_at);
  const scheduledToday = isScheduleButton && isToday(task.schedule_start);
  const chooseNewDate = isDueDateButton ? updateDueAt : updateScheduleStart;
  const calendarType = isDueDateButton ? 'due_at' : 'schedule_start';
  const inStyle = style || {};

  const shouldPillWrapText =
    isOnTaskModal ||
    isScheduleButton ||
    (isCreatingNewTask && calendarType === 'schedule_start');

  const flag = date ? (
    <img
      className={cn('flag', { 'show-flag': shouldDisplayFlag })}
      src={flagGray}
    />
  ) : (
    <img
      className={cn('flag', { 'show-flag': shouldDisplayFlag })}
      src={flagBlue}
    />
  );

  return (
    <div
      className={cn('task-date-container', {
        'is-adding-on-home': isCreatingNewTask && isOnHome,
        'due-container': isDueDateButton,
        'schedule-container': isScheduleButton
      })}
      style={inStyle}
    >
      <div className="task-date">
        {isDueDateButton && flag}
        <a
          data-tip={'Completed Date'}
          onMouseDown={openCalendar}
          className={cn('due-date', {
            overdue,
            'priority-text-white': calendarType === 'due_at',
            'no-date': !date,
            'pill-container': shouldPillWrapText,
            'is-being-edited':
              isScheduleButton && beingEdited && datePickerOpen,
            'adding-task': isCreatingNewTask,
            today: dueToday || scheduledToday
          })}
        >
          {isDueDateButton ? (
            <img className="due-date-flag" src={dueDateFlag} />
          ) : null}
          {getDateText({
            dueToday,
            scheduledToday,
            date,
            calendarText
          })}
        </a>
      </div>
      {beingEdited && datePickerOpen ? (
        <TaskDatePicker
          date={datePickerDate}
          chooseNewDate={chooseNewDate}
          datePickerOpen={datePickerOpen}
          task={task}
          setEditTaskId={setEditTaskId}
          calendarType={calendarType}
          scheduleDate={props.scheduleDate}
          isDailyTask={isDailyTask}
          closeCalendar={closeCalendar}
          mouseX={mouseX}
          mouseY={mouseY}
        />
      ) : null}
    </div>
  );
};

export default TaskDateDisplay;
