import { PureComponent } from 'react';
import {
  TaskPropertyCell,
  StyledTaskCompleted,
  NewTaskProperty
} from './styles';
import { StyledTaskCompletedIcon } from '../TaskList/styles';
import moment from 'moment';
import theme from 'theme';

class TaskCompleted extends PureComponent {
  render() {
    const { isNewTask, date } = this.props;
    return (
      <TaskPropertyCell>
        {isNewTask && <NewTaskProperty />}
        {date && (
          <StyledTaskCompleted>
            <StyledTaskCompletedIcon color={theme.colors.colorSemiDarkGray1} />
            {moment(date).format('MMM D')}
          </StyledTaskCompleted>
        )}
      </TaskPropertyCell>
    );
  }
}

export default TaskCompleted;
