import { memo, ComponentProps } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  SimpleFilterDropdown,
  SimpleFilterDropdownStyleWrapper
} from 'FilterModule/components/FilterDropdowns/SimpleFilterDropdown';
import { AddableFiltersToggle } from './AddableFiltersToggle';
import { FilterListType } from 'FilterModule/constants';
import { TextButton } from 'components/styles';

export interface AddableFiltersDropdownProps {
  /** The current filters being used */
  filterOrder: FilterListType[];

  /** Overrides the heading of the filter selection dropdown. */
  dropdownHeaderOverride?: JSX.Element;

  optionsArray: FilterListType[];

  /** FilterListType to config */
  optionHash?: Partial<
    Record<
      FilterListType,
      { [key: string]: unknown; label?: string; fieldOverride?: string }
    >
  >;

  /** Overrides the label of the toggle. If `null`, the label is hidden. */
  toggleLabelOverride?: ComponentProps<
    typeof AddableFiltersToggle
  >['labelOverride'];

  updateFilterOrder: (nextOrder: FilterListType[]) => void;
}

/**
 * To override default labels, use: filterDropdownLabel
 */
export const AddableFiltersDropdown = memo(
  ({
    filterOrder,
    dropdownHeaderOverride,
    optionsArray,
    optionHash,
    toggleLabelOverride,
    updateFilterOrder
  }: AddableFiltersDropdownProps) => {
    const renderToggle = ({ isOpen }) => {
      return (
        <AddableFiltersToggle
          isOpen={isOpen}
          labelOverride={toggleLabelOverride}
        />
      );
    };

    const optionHashToUse = optionsArray.reduce((acc, cur) => {
      acc[cur] = {
        ...optionHash?.[cur],
        label:
          optionHash?.[cur]?.filterDropdownLabel ??
          defaultLabelsByFilterListType[cur] ??
          optionHash?.[cur]?.label
      };
      return acc;
    }, {} as Record<FilterListType, Record<string, unknown>>);

    const renderHeaderButton = ({
      handleClose
    }: {
      handleClose: () => void;
    }) => {
      return (
        <TextButton
          fontWeight={600}
          backgroundColor={theme.colors.colorRoyalBlue}
          color="white"
          padding="0.25rem 0.5rem"
          lineHeight={1.4}
          onClick={handleClose}
        >
          Done
        </TextButton>
      );
    };

    const labels = dropdownHeaderOverride
      ? { ...dropdownLabels, headerInitial: dropdownHeaderOverride }
      : dropdownLabels;

    return (
      <SimpleFilterDropdown
        items={optionsArray}
        itemHash={optionHashToUse}
        labelKey="label"
        selectedItems={filterOrder}
        updateSelectedItems={updateFilterOrder}
        renderToggle={renderToggle}
        renderHeaderButton={renderHeaderButton}
        styleWrapper={StyledAddableFiltersDropdown}
        hasNoHeader={false}
        labels={labels}
        itemHeight={48}
        listWidth={235}
        listHeight={356}
        isBatchActionsHidden
      />
    );
  }
);

AddableFiltersDropdown.displayName = 'AddableFiltersDropdown';

/* ------------------------------------ - ----------------------------------- */

const defaultLabelsByFilterListType = {
  [FilterListType.Members]: 'People'
};

const dropdownLabels = {
  headerInitial: 'Add Filters',
  searchPlaceholder: 'Type name or select below'
};

const StyledAddableFiltersDropdown = styled(SimpleFilterDropdownStyleWrapper)`
  .styled-header-copy {
    color: black;
    font-size: 24px;
  }
  .flyout-list-item-container {
    border-top: 1px solid ${theme.colors.colorPaleGray9};
  }
  .item-label {
    font-size: 13px;
    line-height: 2;
    color: ${theme.colors.colorSemiDarkGray2};
  }
`;
