import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';
import { WEEK_FETCH_OFFSET, WAYPOINT_DISTANCE } from 'appConstants/planners';
import { noop } from 'appUtils';

const HomePlannerWaypoint = ({
  before,
  after,
  fetchPlanners,
  dateHeader,
  isAlreadyFetching
}) => {
  const topOffset = before ? WAYPOINT_DISTANCE : undefined;
  const bottomOffset = after ? WAYPOINT_DISTANCE : undefined;
  const rightOffset = after ? WEEK_FETCH_OFFSET : 0;
  const leftOffset = before ? WEEK_FETCH_OFFSET : 0;
  const baseDate = moment(dateHeader);
  const plannerFetch = () => {
    if (!isAlreadyFetching) {
      fetchPlanners({
        baseDate,
        rightOffset,
        leftOffset,
        shouldUpdateDateRange: true
      });
    }
  };

  return (
    <Waypoint
      horizontal
      onEnter={plannerFetch}
      onLeave={noop /* will bring in virtualization later */}
      topOffset={topOffset}
      bottomOffset={bottomOffset}
    />
  );
};

export default React.memo(HomePlannerWaypoint);
