import * as constants from 'appConstants';

const selectedTaskTemplate = {
  id: null,
  project_id: null,
  description: null,
  assignee_id: null,
  assignee_ids: [],
  assigner_ids: [],
  assignee_name: null,
  due_at: null,
  schedule_start: null,
  schedule_end: null,
  completed_at: null,
  followed_ids: [],
  follower_names: [],
  position: null,
  initials: null,
  task_project_position: null,
  note: null,
  project: {
    id: null,
    title: null,
    project_membership: [],
    board_id: null,
    slug: null,
    team_slug: null,
    group_slug: null,
    description: null
  }
};

const replaceSelectedTask = (selectedTask, serverData) => {
  if (serverData && selectedTask.id === serverData.id) {
    if (!serverData.position) serverData.position = selectedTask.position;
    if (!serverData.project) serverData.project = selectedTaskTemplate.project;
    return serverData;
  } else return selectedTask;
};

export const initialState = {
  isFetchingTasks: null,
  isCompleted: false,
  editTaskId: null,
  editDescription: false,
  selectedTask: selectedTaskTemplate
};

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_TASK.SUCCESS:
      return {
        ...state,
        selectedTask: {
          ...action.payload.response.task,
          isPersonal: action.payload.requestPayload.params.isPersonal
        }
      };
    case constants.MOVE_HOME_TASK.SUCCESS:
    case constants.MOVE_TASK.SUCCESS: {
      const movedTask = action.payload.response.task;
      const selectedTask = replaceSelectedTask(state.selectedTask, movedTask);

      if (movedTask.isCompleted || selectedTask) {
        return {
          ...state,
          selectedTask
        };
      } else {
        return {
          ...state
        };
      }
    }
    case constants.TASK_UPDATE_POSITION: {
      const { taskId, isCompleted, wasCompleted } = action.payload.params;
      const selectedTask = { ...state.selectedTask };
      if (taskId === selectedTask.id) {
        if (wasCompleted && !isCompleted) {
          selectedTask.completed_at = null;
        } else if (!wasCompleted && isCompleted) {
          selectedTask.completed_at = new Date();
        }
      }
      return {
        ...state,
        selectedTask,
        isMovingTask: true
      };
    }

    case constants.SET_EDIT_TASK:
      return {
        ...state,
        editTaskId: action.payload,
        editDescription: !!action.payload
      };
    default:
      return state;
  }
};

export default tasks;
