import React from 'react';
import theme from 'theme';
import moment from 'moment';
import { ZOOM_TO_STEP_VALUES, ZOOM_STRINGS } from 'appConstants/workload';

const XTickValue = ({ x, y, payload, zoomLevel, data }) => {
  const startDate = moment(payload.value);
  const endDate = moment(data[payload.index]?.end_date);

  const isMonths = ZOOM_TO_STEP_VALUES[zoomLevel] === ZOOM_STRINGS.MONTH;
  const isWeeks = ZOOM_TO_STEP_VALUES[zoomLevel] === ZOOM_STRINGS.WEEK;

  // - Show month name when viewing by months or viewing by weeks
  //   (and start/end date same month)
  // - Show month with date when viewing by weeks and start/end date different months
  //   or just month when same month
  const topLabel =
    isMonths || (isWeeks && startDate.isSame(endDate, 'month'))
      ? startDate.format('MMM')
      : startDate.format('MMM D');

  // - Show year when viewing by months and first month on the chart or January
  // - Show end date when viewing by weeks and start/end date different months,
  //   or both dates when same month
  const bottomLabel =
    isMonths && (startDate.month() === 0 || payload.index === 0)
      ? startDate.format('YYYY')
      : isWeeks && startDate.isSame(endDate, 'month')
      ? `${startDate.format('D')} - ${endDate.format('D')}`
      : isWeeks && '- ' + endDate.format('MMM D');

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor="middle"
        fill={theme.colors.colorMediumGray5}
      >
        {topLabel}
      </text>
      {bottomLabel && (
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill={theme.colors.colorMediumGray5}
        >
          {bottomLabel}
        </text>
      )}
    </g>
  );
};

export default XTickValue;
