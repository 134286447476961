import theme from 'theme';
import {
  VIEW_BY,
  DISPLAY_FORMAT,
  ESTIMATED_OR_PLANNED,
  VARIANCE_TYPE,
  DISPLAY_FORMAT_QUANTITY,
  toolTipFormatType,
  varianceToolTipFormatType
} from './constants';
import {
  isCostOverBudget,
  isHoursOverBudget
} from 'BudgetModule/utils/isOverBudget';
import { formatTotal, formatCurrency } from 'BudgetModule/utils';
import { FILTER_PAGES, ZOOM_LEVELS } from 'appConstants/workload';
import { replaceNegativeAggValues } from 'appUtils';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import {
  formatDateRange,
  generateDateRange,
  DATE_RANGES
} from 'appUtils/dateRangeHelpers';
import moment from 'moment';

const leftPaddingColumn = {
  headerType: 'leftPadding',
  accessor: (row) => row,
  id: 'leftPadding',
  align: 'left'
};

const infoColumn = {
  headerType: 'info',
  accessor: (row) => row,
  id: 'info',
  align: 'left'
};
const budgetBarColumn = {
  headerType: 'budgetBar',
  accessor: (row) => row.totals,
  id: 'budgetBar',
  align: 'left'
};
const varianceBarColumn = {
  headerType: 'varianceBar',
  accessor: (row) => row.totals,
  id: 'varianceBar',
  align: 'left'
};
const spentColumn = {
  headerType: 'spent',
  accessor: (row) => row,
  id: 'spent',
  align: 'left'
};
const estimatedColumn = {
  headerType: 'estimated',
  accessor: (row) => row,
  id: 'estimated',
  align: 'left'
};
const spentPlannedColumn = {
  headerType: 'spentPlanned',
  accessor: (row) => row,
  id: 'spentPlanned',
  align: 'left'
};
const plannedColumn = {
  headerType: 'planned',
  accessor: (row) => row,
  id: 'planned',
  align: 'left'
};
const totalPlannedColumn = {
  headerType: 'total_planned',
  accessor: (row) => row,
  id: 'total_planned',
  align: 'left'
};
const feeColumn = {
  headerType: 'fee',
  accessor: (row) => row,
  id: 'fee',
  align: 'left'
};
const targetColumn = {
  headerType: 'target',
  accessor: (row) => row,
  id: 'target',
  align: 'left'
};
const varianceColumn = {
  headerType: 'variance',
  accessor: (row) => row,
  id: 'variance',
  align: 'left'
};
const scrollPaddingColumn = {
  headerType: 'scrollPadding',
  accessor: (row) => row,
  id: 'scrollPadding',
  align: 'left'
};
const baselineColumn = {
  headerType: 'baseline',
  accessor: (row) => row.row,
  id: 'baseline',
  align: 'left'
};
const currentColumn = {
  headerType: 'current',
  accessor: (row) => row.row,
  id: 'current',
  align: 'left'
};

const remainingColumn = {
  headerType: 'remaining',
  accessor: (row) => row.row,
  id: 'remaining',
  align: 'left'
};

const plannedRemainingColumn = {
  headerType: 'plannedRemaining',
  accessor: (row) => row.row,
  id: 'plannedRemaining',
  align: 'left'
};

export const getAmountAccessor = (column, currentView) => {
  const valueForColumn = {
    [feeColumn.headerType]: 'fee',
    [targetColumn.headerType]: 'target',
    [baselineColumn.headerType]: 'baseline',
    [currentColumn.headerType]: 'current'
  };

  return (
    valueForColumn[column] ||
    column + '_' + (currentView === DISPLAY_FORMAT.DOLLARS ? 'cost' : 'hours')
  );
};

export const getRowTypeTarget = (rowType, totals) => {
  const rowTypeToTargetKey = {
    projectRow: 'projectTarget',
    projectHeaderRow: 'projectTarget',
    phaseRow: 'phaseTarget',
    activityRow: 'activityPhaseTarget',
    memberRow: 'estimated_cost'
  };
  return +totals[rowTypeToTargetKey[rowType]] || 0;
};

export const columnWidths = {
  [VIEW_BY.PROJECTS]: {
    leftPadding: 0,
    info: 293,
    budgetBar: 390,
    spent: 90,
    estimated: 90,
    spentPlanned: 100,
    planned: 90,
    fee: 153,
    scrollPadding: 15,
    remaining: 100,
    plannedRemaining: 130
  },
  [VIEW_BY.MEMBERS]: {
    leftPadding: 0,
    info: 293,
    budgetBar: 390,
    spent: 90,
    estimated: 90,
    spentPlanned: 100,
    remaining: 100,
    planned: 90,
    fee: 153,
    scrollPadding: 15,
    plannedRemaining: 130
  },
  variance: {
    leftPadding: 0,
    info: 293,
    varianceBar: 350,
    estimated: 100,
    spent: 80,
    planned: 100,
    variance: 70,
    scrollPadding: 30
  },
  budgetVariance: {
    leftPadding: 0,
    info: 293,
    varianceBar: 350,
    estimated: 100,
    fee: 100,
    target: 100,
    spent: 80,
    planned: 100,
    variance: 70,
    scrollPadding: 30
  },
  scheduleVariance: {
    leftPadding: 0,
    info: 293,
    varianceBar: 350,
    current: 80,
    baseline: 80,
    variance: 70,
    scrollPadding: 30
  },
  projectViewBudgetReport: {
    info: 250,
    budgetBar: 490,
    spentPlanned: 80,
    scrollPadding: 15
  }
};

export const budgetColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  spentPlannedColumn,
  remainingColumn,
  feeColumn,
  scrollPaddingColumn
];

export const spentTimeReportColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  spentColumn,
  scrollPaddingColumn
];

export const plannedTimeReportColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  plannedColumn,
  scrollPaddingColumn
];

export const unspentBudgetReportColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  plannedRemainingColumn,
  scrollPaddingColumn
];

export const timeProjectionReportColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  spentPlannedColumn,
  scrollPaddingColumn
];

export const varianceColumns = [
  leftPaddingColumn,
  infoColumn,
  varianceBarColumn,
  estimatedColumn,
  plannedColumn,
  spentColumn,
  varianceColumn,
  scrollPaddingColumn
];

export const scheduleVarianceColumns = [
  leftPaddingColumn,
  infoColumn,
  varianceBarColumn,
  baselineColumn,
  currentColumn,
  varianceColumn,
  scrollPaddingColumn
];

export const budgetVarianceColumns = [
  leftPaddingColumn,
  infoColumn,
  varianceBarColumn,
  estimatedColumn,
  plannedColumn,
  feeColumn,
  targetColumn,
  spentColumn,
  varianceColumn,
  scrollPaddingColumn
];

export const budgetTableChartTypeColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  remainingColumn,
  feeColumn,
  scrollPaddingColumn
];

export const projectDetailsBudgetColumns = [
  leftPaddingColumn,
  infoColumn,
  budgetBarColumn,
  spentPlannedColumn,
  scrollPaddingColumn
];

const groupByProjectsColumnWidths = columnWidths[VIEW_BY.PROJECTS];
const groupByMembersColumnWidths = columnWidths[VIEW_BY.MEMBERS];
export const varianceColumnWidths = columnWidths.variance;
export const budgetVarianceColumnWidths = columnWidths.budgetVariance;
export const scheduleVarianceColumnWidths = columnWidths.scheduleVariance;
export const projectViewBudgetReport = columnWidths.projectViewBudgetReport;

export const columnGridTemplate = {
  [VIEW_BY.PROJECTS]: `${groupByProjectsColumnWidths.info}px ${groupByProjectsColumnWidths.budgetBar}px ${groupByProjectsColumnWidths.spentPlanned}px ${groupByProjectsColumnWidths.fee}px ${groupByProjectsColumnWidths.scrollPadding}px`,
  [VIEW_BY.MEMBERS]: `${groupByMembersColumnWidths.info}px ${groupByMembersColumnWidths.budgetBar}px ${groupByMembersColumnWidths.spentPlanned}px ${groupByProjectsColumnWidths.fee}px ${groupByMembersColumnWidths.scrollPadding}px`,
  variance: `${varianceColumnWidths.info}px ${varianceColumnWidths.varianceBar}px ${varianceColumnWidths.estimated}px ${varianceColumnWidths.spent}px ${varianceColumnWidths.variance}px ${varianceColumnWidths.scrollPadding}px`,
  budgetVariance: `${budgetVarianceColumnWidths.info}px ${budgetVarianceColumnWidths.varianceBar}px ${budgetVarianceColumnWidths.planned}px ${budgetVarianceColumnWidths.spent}px ${varianceColumnWidths.variance}px ${budgetVarianceColumnWidths.scrollPadding}px`,
  scheduleVariance: `${scheduleVarianceColumnWidths.info}px ${scheduleVarianceColumnWidths.varianceBar}px ${scheduleVarianceColumnWidths.baseline}px ${scheduleVarianceColumnWidths.current}px ${varianceColumnWidths.variance}px ${scheduleVarianceColumnWidths.scrollPadding}px`,
  projectViewBudgetReport: `${projectViewBudgetReport.info}px ${projectViewBudgetReport.budgetBar}px ${projectViewBudgetReport.spentPlanned}px ${projectViewBudgetReport.scrollPadding}px 0px 0px`,
  timeProjectionReport: `${groupByProjectsColumnWidths.info}px ${groupByProjectsColumnWidths.budgetBar}px ${groupByProjectsColumnWidths.spentPlanned}px ${groupByProjectsColumnWidths.scrollPadding}px`,
  spentTimeReport: `${groupByProjectsColumnWidths.info}px ${groupByProjectsColumnWidths.budgetBar}px ${groupByProjectsColumnWidths.spent}px ${groupByProjectsColumnWidths.scrollPadding}px`,
  plannedTimeReport: `${groupByProjectsColumnWidths.info}px ${groupByProjectsColumnWidths.budgetBar}px ${groupByProjectsColumnWidths.planned}px ${groupByProjectsColumnWidths.scrollPadding}px`,
  unspentBudgetReport: `${groupByProjectsColumnWidths.info}px ${groupByProjectsColumnWidths.budgetBar}px ${groupByProjectsColumnWidths.plannedRemaining}px ${groupByProjectsColumnWidths.scrollPadding}px`
};

const columnFilters = {
  [ESTIMATED_OR_PLANNED.PLANNED]: (column) => column !== estimatedColumn,
  [ESTIMATED_OR_PLANNED.ESTIMATED]: (column) =>
    ![totalPlannedColumn, plannedColumn].includes(column)
};

const customColumnsFilterByPage = {
  [FILTER_PAGES.BUDGET_VARIANCE]: {
    [ESTIMATED_OR_PLANNED.ESTIMATED]: (column) =>
      ![totalPlannedColumn, plannedColumn, feeColumn, targetColumn].includes(
        column
      ),
    [ESTIMATED_OR_PLANNED.PLANNED]: (column) =>
      ![estimatedColumn, targetColumn, feeColumn].includes(column),

    [ESTIMATED_OR_PLANNED.FEE]: (column) =>
      ![estimatedColumn, plannedColumn, targetColumn].includes(column),

    [ESTIMATED_OR_PLANNED.TARGET]: (column) =>
      ![estimatedColumn, plannedColumn, feeColumn].includes(column)
  },
  [FILTER_PAGES.BUDGET]: {
    [ESTIMATED_OR_PLANNED.REMAINING]: (column) =>
      ![spentPlannedColumn].includes(column),
    [ESTIMATED_OR_PLANNED.SPENT_PLANNED]: (column) =>
      ![remainingColumn].includes(column)
  }
};

const columnsByPage = {
  [FILTER_PAGES.BUDGET]: budgetColumns,
  [FILTER_PAGES.VARIANCE]: varianceColumns,
  [FILTER_PAGES.BUDGET_VARIANCE]: budgetVarianceColumns,
  [FILTER_PAGES.SCHEDULE_VARIANCE]: scheduleVarianceColumns,
  [FILTER_PAGES.BUDGET_TABLE]: budgetTableChartTypeColumns,
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: timeProjectionReportColumns,
  [FILTER_PAGES.PROJECT_DETAIL_BUDGET]: budgetColumns,
  [FILTER_PAGES.SPENT_TIME_REPORT]: spentTimeReportColumns,
  [FILTER_PAGES.PLANNED_TIME_REPORT]: plannedTimeReportColumns,
  [FILTER_PAGES.UNSPENT_BUDGET_REPORT]: unspentBudgetReportColumns,
  [FILTER_PAGES.PROJECT_DETAIL_BUDGET]: projectDetailsBudgetColumns,
  BUDGET_MODAL_VARIANCE: varianceColumns,
  BUDGET_MODAL_BUDGET_VARIANCE: varianceColumns
};

export const getColumns = (estimatedOrPlanned, pageName, viewBy) => {
  const columnsToUse = columnsByPage[pageName] || [];
  const columnFiltersToUse =
    customColumnsFilterByPage[pageName]?.[estimatedOrPlanned] ||
    columnFilters[estimatedOrPlanned];

  return columnsToUse.filter(columnFiltersToUse);
  // .filter(columnFilters[viewBy]);
};

export const formatTotals = ({
  totals: originalTotals = {},
  fee = 0,
  parentFee = 0,
  parentTarget = 0,
  estimatedCost,
  isUsingCostRate,
  phaseHours,
  projectHours,
  activityPhaseHours,
  ...rest // will directly get added to final totals object
}) => {
  const totals = replaceNegativeAggValues(originalTotals);

  // Totals hours
  const totalHoursForHoursOnly =
    projectHours ?? phaseHours ?? activityPhaseHours;

  // Hours
  const estimatedHours =
    (totalHoursForHoursOnly !== undefined
      ? +totalHoursForHoursOnly
      : +totals.estimated_hours) || 0;

  const spentHours = +totals.spent_hours || 0;
  const plannedHours = +totals.planned_hours || 0;
  const totalPlannedHours = +totals.total_planned_hours || 0;

  // We should only show negative hours when estimatedHours is !== 0 (because there must be budgetted time, otherwise
  // negative hours doesn't mean anything)
  const remainingHours =
    estimatedHours !== 0 ? estimatedHours - spentHours - plannedHours : 0;

  // Cost
  const spentCost = +totals.spent_expense || 0;
  const costRateSpentCost = +totals.spent_expense_cost_rate || 0;
  const plannedCost = +totals.planned_expense || 0;
  const costRatePlannedCost = +totals.planned_expense_cost_rate || 0;
  const costEstimate =
    (![undefined, null].includes(estimatedCost)
      ? +estimatedCost
      : +totals.estimated_expense) || 0;
  // Calculations are based off estimated cost if it exists, otherwise spent + planned
  const total =
    costEstimate ||
    (isUsingCostRate
      ? costRateSpentCost + costRatePlannedCost
      : spentCost + plannedCost);
  const remainingCost = total - plannedCost - spentCost;
  const costRateRemainingCost = total - costRatePlannedCost - costRateSpentCost;

  // Percent Cost
  const estimatedPercent = +fee ? ((costEstimate / fee) * 100).toFixed(1) : 0;
  const spentPercent = total ? ((spentCost / total) * 100).toFixed(1) : 0;
  const costRateSpentPercent = total
    ? ((costRateSpentCost / total) * 100).toFixed(1)
    : 0;
  const plannedPercent = total ? ((plannedCost / total) * 100).toFixed(1) : 0;

  // Percent hours
  const plannedPercentHours = estimatedHours
    ? ((plannedHours / estimatedHours) * 100).toFixed(1)
    : ((plannedHours / (spentHours + plannedHours)) * 100).toFixed(1);
  const spentPercentHours = estimatedHours
    ? ((spentHours / estimatedHours) * 100).toFixed(1)
    : ((spentHours / (spentHours + plannedHours)) * 100).toFixed(1);

  // If no budgetted time (then show 0)
  const remainingPercentHours = estimatedHours
    ? ((remainingHours / estimatedHours) * 100).toFixed(1)
    : 0;

  //
  const costRatePlannedPercent = total
    ? ((costRatePlannedCost / total) * 100).toFixed(1)
    : 0;
  const remainingPercent = total
    ? ((remainingCost / total) * 100).toFixed(1)
    : 0;
  const costRateRemainingPercent = total
    ? ((costRateRemainingCost / total) * 100).toFixed(1)
    : 0;

  // Days - used for schedule variance
  const baseline = +totals.baseline?.total_work_days || null;
  // current/work_days is null if start_date is not defined on the project or phase
  const current = totals?.work_days;

  return {
    // Hours
    remaining_hours: remainingHours,
    total_planned_hours: totalPlannedHours,
    estimated_hours: estimatedHours,
    spent_hours: spentHours,
    planned_hours: plannedHours,
    // Cost
    estimated_cost: costEstimate,
    spent_cost: isUsingCostRate ? costRateSpentCost : spentCost,
    planned_cost: isUsingCostRate ? costRatePlannedCost : plannedCost,
    remaining_cost: isUsingCostRate ? costRateRemainingCost : remainingCost,

    // Percent
    // Estimated cost as % of fee
    estimated_percent: estimatedPercent,
    spent_percent: isUsingCostRate ? costRateSpentPercent : spentPercent,
    planned_percent: isUsingCostRate ? costRatePlannedPercent : plannedPercent,
    remaining_percent: isUsingCostRate
      ? costRateRemainingPercent
      : remainingPercent,
    remaining_percent_hour: remainingPercentHours,
    planned_percent_hour: plannedPercentHours,
    spent_percent_hour: spentPercentHours,

    fee,
    total,
    feePercentOfParent:
      +parentFee && fee !== undefined
        ? ((fee / parentFee) * 100).toFixed(1)
        : 0,
    // target / parent's target
    targetPercentOfParent: +parentTarget
      ? ((costEstimate / parentTarget) * 100).toFixed(1).replace('.0', '')
      : 0,
    baseline,
    current,
    phaseHours,
    projectHours,
    original: originalTotals,
    ...rest
  };
};

// No Budget means spent + planned + fee = 0 on project view
// on member view spent + planned + estimates = 0
export const hasBudget = (totals, viewBy) => {
  return (
    totals.spent_hours ||
    totals.planned_hours ||
    (viewBy === VIEW_BY.PROJECTS ? totals.fee : totals.estimated_hours)
  );
};

export const hasVariance = (totals, estimatedOrPlanned, varianceType) => {
  const divisor =
    estimatedOrPlanned === ESTIMATED_OR_PLANNED.ESTIMATED
      ? totals.estimated_hours || totals.estimated_cost
      : totals.planned_hours || totals.planned_cost;

  if (varianceType === VARIANCE_TYPE.SCHEDULE) {
    return (
      totals.current && totals.baseline && totals.current !== totals.baseline
    );
  }

  if (varianceType === VARIANCE_TYPE.BUDGET) {
    switch (estimatedOrPlanned) {
      case ESTIMATED_OR_PLANNED.FEE: {
        return totals.fee && totals.spent_cost !== +totals.fee;
      }

      case ESTIMATED_OR_PLANNED.TARGET: {
        return totals.target && totals.spent_cost !== totals.target;
      }
    }
  }

  return (
    (totals.spent_hours && divisor && totals.spent_hours !== divisor) ||
    (totals.spent_cost && divisor && totals.spent_cost !== divisor)
  );
};

export const getShouldDisplayTarget = (totals, currentView) => {
  const estimatedPercet = +totals.estimated_percent || 0;
  const estimatedHours = +totals.estimated_hours || 0;

  return currentView === DISPLAY_FORMAT.DOLLARS
    ? estimatedPercet < 100 && estimatedPercet > 0
    : estimatedHours > 0;
};

export const formatHours = (hours) =>
  hours.toFixed(1).toLocaleString().replace('.0', '') + 'h';

export const formatPercentage = (value) =>
  value.toFixed(1).replace('.0', '') + '%';

/* ------------------------------- Date range ------------------------------- */

export const DEFAULT_VARIANCE_DATE_RANGE = 'All Time';

export const VARIANCE_DATE_RANGES = {
  [ZOOM_LEVELS.ALL_TIME]: {
    value: ZOOM_LEVELS.ALL_TIME,
    label: 'All Time',
    toggleLabel: 'All Time' // override toggleFormatter value
  },
  [ZOOM_LEVELS.PREV_WEEK]: {
    value: ZOOM_LEVELS.PREV_WEEK,
    label: 'Last Week'
  },
  [ZOOM_LEVELS.PREV_MONTH]: {
    value: ZOOM_LEVELS.PREV_MONTH,
    label: 'Last Month'
  },
  custom: { value: 'custom', label: 'Custom' }
};

export const VARIANCE_DATE_RANGE_OPTIONS = [
  VARIANCE_DATE_RANGES[ZOOM_LEVELS.ALL_TIME],
  VARIANCE_DATE_RANGES[ZOOM_LEVELS.PREV_WEEK],
  VARIANCE_DATE_RANGES[ZOOM_LEVELS.PREV_MONTH],
  VARIANCE_DATE_RANGES.custom
];

export const GENERATE_VARIANCE_DATE_RANGE = {
  [ZOOM_LEVELS.ALL_TIME]: () =>
    generateDateRange({ range: DATE_RANGES.ALL_PAST }),
  [ZOOM_LEVELS.PREV_WEEK]: () =>
    generateDateRange({ range: DATE_RANGES.LAST_WEEK, startOfWeek: 1 }),
  [ZOOM_LEVELS.PREV_MONTH]: () =>
    generateDateRange({ range: DATE_RANGES.LAST_MONTH }),
  [ZOOM_LEVELS.THIS_WHOLE_MONTH]: () =>
    generateDateRange({ range: DATE_RANGES.THIS_WHOLE_MONTH }),
  [ZOOM_LEVELS.THIS_WHOLE_WEEK]: () =>
    generateDateRange({ range: DATE_RANGES.THIS_WHOLE_WEEK }),
  [ZOOM_LEVELS.LAST_3_MONTHS]: () =>
    generateDateRange({ range: DATE_RANGES.LAST_3_MONTHS }),
  [ZOOM_LEVELS.YEAR_TO_DATE]: () =>
    generateDateRange({ range: DATE_RANGES.YEAR_TO_DATE }),
  custom: ({ start, end }) =>
    generateDateRange({ range: DATE_RANGES.CUSTOM, start, end })
};

export const GENERATE_PLANNED_TIME_REPORT_DATE_RANGE_OPTIONS = {
  [ZOOM_LEVELS.THIS_WEEK]: () =>
    generateDateRange({ range: DATE_RANGES.THIS_WHOLE_WEEK }),
  [ZOOM_LEVELS.NEXT_WEEK]: () =>
    generateDateRange({ range: DATE_RANGES.NEXT_WEEK }),
  [ZOOM_LEVELS.MONTH]: () =>
    generateDateRange({ range: DATE_RANGES.NEXT_30_DAYS }),
  [ZOOM_LEVELS.QUARTER]: () =>
    generateDateRange({ range: DATE_RANGES.NEXT_3_MONTHS }),
  [ZOOM_LEVELS.CUSTOM]: ({ start, end }) =>
    generateDateRange({ range: DATE_RANGES.CUSTOM, start, end })
};

export const DEFAULT_TIME_PROJECTION_REPORT_DATE_RANGE =
  ZOOM_LEVELS.THIS_WHOLE_MONTH;

export const TIME_PROJECTION_REPORT_DATE_RANGE = {
  [ZOOM_LEVELS.THIS_WHOLE_MONTH]: {
    value: ZOOM_LEVELS.THIS_WHOLE_MONTH,
    toggleLabel: moment().format('MMM YYYY'),
    label: 'This Month'
  },
  [ZOOM_LEVELS.THIS_WHOLE_WEEK]: {
    value: ZOOM_LEVELS.THIS_WHOLE_WEEK,
    label: 'This Week'
  },
  [ZOOM_LEVELS.CUSTOM]: {
    value: ZOOM_LEVELS.CUSTOM,
    label: 'Custom'
  }
};

export const TIME_PROJECTION_REPORT_DATE_RANGE_OPTIONS = [
  TIME_PROJECTION_REPORT_DATE_RANGE[ZOOM_LEVELS.THIS_WHOLE_MONTH],
  TIME_PROJECTION_REPORT_DATE_RANGE[ZOOM_LEVELS.THIS_WHOLE_WEEK],
  TIME_PROJECTION_REPORT_DATE_RANGE[ZOOM_LEVELS.CUSTOM]
];

/* ---------------------------- Spent Time Report --------------------------- */

export const DEFAULT_SPENT_TIME_REPORT_DATE_RANGE = ZOOM_LEVELS.ALL_TIME;

export const SPENT_TIME_REPORT_DATE_RANGE = {
  [ZOOM_LEVELS.PREV_WEEK]: {
    value: ZOOM_LEVELS.PREV_WEEK,
    label: 'Last Week'
  },
  [ZOOM_LEVELS.PREV_MONTH]: {
    value: ZOOM_LEVELS.PREV_MONTH,
    label: 'Last Month'
  },
  [ZOOM_LEVELS.LAST_3_MONTHS]: {
    value: ZOOM_LEVELS.LAST_3_MONTHS,
    label: 'Last 3 Months'
  },
  [ZOOM_LEVELS.YEAR_TO_DATE]: {
    value: ZOOM_LEVELS.YEAR_TO_DATE,
    label: 'Year to Date'
  },
  [ZOOM_LEVELS.ALL_TIME]: {
    value: ZOOM_LEVELS.ALL_TIME,
    toggleLabel: 'All Time',
    label: 'All Time'
  },
  [ZOOM_LEVELS.CUSTOM]: {
    value: ZOOM_LEVELS.CUSTOM,
    label: 'Custom'
  }
};

export const SPENT_TIME_REPORT_DATE_RANGE_OPTIONS = Object.values(
  SPENT_TIME_REPORT_DATE_RANGE
);

/* --------------------------- Planned Time Report -------------------------- */
export const DEFAULT_PLANNED_TIME_REPORT_DATE_RANGE = ZOOM_LEVELS.THIS_WEEK;

export const PLANNED_TIME_REPORT_DATE_RANGE = {
  [ZOOM_LEVELS.THIS_WEEK]: {
    value: ZOOM_LEVELS.THIS_WEEK,
    label: 'This Week'
  },
  [ZOOM_LEVELS.NEXT_WEEK]: {
    value: ZOOM_LEVELS.NEXT_WEEK,
    label: 'Next Week'
  },
  [ZOOM_LEVELS.MONTH]: {
    value: ZOOM_LEVELS.MONTH,
    label: 'Next 30 Days'
  },
  [ZOOM_LEVELS.QUARTER]: {
    value: ZOOM_LEVELS.QUARTER,
    label: 'Next 3 Months'
  },
  [ZOOM_LEVELS.CUSTOM]: {
    value: ZOOM_LEVELS.CUSTOM,
    label: 'Custom'
  }
};

// ZOOM_LEVELS has numeric values which will not guarantee the order of the properties when used for Object.values
export const PLANNED_TIME_REPORT_DATE_RANGE_OPTIONS = [
  PLANNED_TIME_REPORT_DATE_RANGE[ZOOM_LEVELS.THIS_WEEK],
  PLANNED_TIME_REPORT_DATE_RANGE[ZOOM_LEVELS.NEXT_WEEK],
  PLANNED_TIME_REPORT_DATE_RANGE[ZOOM_LEVELS.MONTH],
  PLANNED_TIME_REPORT_DATE_RANGE[ZOOM_LEVELS.QUARTER],
  PLANNED_TIME_REPORT_DATE_RANGE[ZOOM_LEVELS.CUSTOM]
];

/* -------------------------------------------------------------------------- */

export const formatVarianceReportDateRange = (args) => {
  return formatDateRange({
    ...args,
    generator: GENERATE_VARIANCE_DATE_RANGE
  });
};

export const formatPlannedTimeReportDateRange = (args) => {
  return formatDateRange({
    ...args,
    generator: GENERATE_PLANNED_TIME_REPORT_DATE_RANGE_OPTIONS
  });
};

export const dateRangeFormatterByReportPage = {
  [FILTER_PAGES.VARIANCE]: formatVarianceReportDateRange,
  [FILTER_PAGES.BUDGET_VARIANCE]: formatVarianceReportDateRange,
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: formatVarianceReportDateRange,
  [FILTER_PAGES.SPENT_TIME_REPORT]: formatVarianceReportDateRange,
  [FILTER_PAGES.PLANNED_TIME_REPORT]: formatPlannedTimeReportDateRange
};

export const getBudgetModalTablePosition = (project) => {
  if (!project) {
    return 0;
  }

  const hasProjectNumber = !!project.project_number;
  const hasProjectDescription = !!project.description;

  return (hasProjectNumber ? -22 : 0) + (hasProjectDescription ? -22 : 0);
};

export const dateGeneratorByReportPage = {
  [FILTER_PAGES.VARIANCE]: GENERATE_VARIANCE_DATE_RANGE,
  [FILTER_PAGES.BUDGET_VARIANCE]: GENERATE_VARIANCE_DATE_RANGE,
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: GENERATE_VARIANCE_DATE_RANGE,
  [FILTER_PAGES.SPENT_TIME_REPORT]: GENERATE_VARIANCE_DATE_RANGE,
  [FILTER_PAGES.PLANNED_TIME_REPORT]:
    GENERATE_PLANNED_TIME_REPORT_DATE_RANGE_OPTIONS,
  BUDGET_MODAL_VARIANCE: GENERATE_VARIANCE_DATE_RANGE
};

/* ------------------------ Budget bar tooltip utils ------------------------ */

export const getTooltipData = ({
  displayFormat,
  totals,
  rowTooltipCustomProps,
  isHoursOnly,
  headerType,
  tooltipShowHours,
  viewBy
}) => {
  // For some reason when displayformat is hours we want the tooltips to be a percentage
  // For hours only always hours.
  const toolTipDisplayFormat =
    tooltipShowHours ?? (displayFormat === DISPLAY_FORMAT.HOURS && !isHoursOnly)
      ? DISPLAY_FORMAT.PERCENTAGE
      : displayFormat;

  return JSON.stringify({
    totals,
    formatType: toolTipFormatType[toolTipDisplayFormat],
    isOverBudget:
      tooltipShowHours || isHoursOnly
        ? isHoursOverBudget(totals)
        : isCostOverBudget(totals),
    rowTooltipCustomProps,
    headerType,
    viewBy
  });
};

const varianceBarTooltipGetters = {
  [VARIANCE_TYPE.SCHEDULE]: {
    getPlanned: ({ totals }) => totals.baseline,
    getSpent: ({ totals }) => totals.current,
    getSecondRowTooltipName: () => 'actual'
  },
  [VARIANCE_TYPE.BUDGET]: {
    getPlanned: ({ totals, displayFormat }) =>
      +totals[getAmountAccessor('planned', DISPLAY_FORMAT[displayFormat])],
    getSpent: ({ totals, displayFormat }) =>
      +totals[getAmountAccessor('spent', DISPLAY_FORMAT[displayFormat])],
    getSecondRowTooltipName: () => 'spent'
  },
  [VARIANCE_TYPE.TIME]: {
    getPlanned: ({ totals, displayFormat }) =>
      +totals[getAmountAccessor('planned', DISPLAY_FORMAT[displayFormat])],
    getSpent: ({ totals, displayFormat }) =>
      +totals[getAmountAccessor('spent', DISPLAY_FORMAT[displayFormat])],
    getSecondRowTooltipName: () => 'spent'
  }
};

const varianceBarTooltipComparisonOptionsGetters = {
  [ESTIMATED_OR_PLANNED.ESTIMATED]: {
    getTotal: ({ spent, estimatedOrPlanned, totals, planned, displayFormat }) =>
      spent +
      +totals[getAmountAccessor('estimated', DISPLAY_FORMAT[displayFormat])],
    getFirstRowTooltipName: () => 'estimated',
    getFirstRowTooltip: ({ totals, displayFormat }) =>
      +totals[getAmountAccessor('estimated', DISPLAY_FORMAT[displayFormat])]
  },
  [ESTIMATED_OR_PLANNED.PLANNED]: {
    getTotal: ({ planned, spent }) => spent + planned,
    getFirstRowTooltip: ({ planned }) => planned,
    getFirstRowTooltipName: () => 'planned'
  },
  [ESTIMATED_OR_PLANNED.FEE]: {
    getTotal: ({ totals }) => totals.total,
    getFirstRowTooltip: ({ totals }) => +totals.fee,
    getFirstRowTooltipName: () => 'fee'
  },
  [ESTIMATED_OR_PLANNED.TARGET]: {
    getTotal: ({ totals }) => totals.total,
    getFirstRowTooltip: ({ totals }) => totals.target,
    getFirstRowTooltipName: () => 'budget',
    /** Only for budget variance */
    getTooltipDotBackground: () => `repeating-linear-gradient(
      135deg,
      ${theme.colors.colorCalendarBlue},
      ${theme.colors.colorCalendarBlue} 2px,
      ${theme.colors.colorLightGray6} 4px
    )`
  }
};

export const getVarianceBarTooltipData = ({
  varianceType,
  displayFormat,
  estimatedOrPlanned,
  totals,
  currencyFormatter
}) => {
  const { getPlanned, getSpent, getSecondRowTooltipName } =
    varianceBarTooltipGetters[varianceType] || {};
  const {
    getTotal,
    getFirstRowTooltipName,
    getFirstRowTooltip,
    getTooltipDotBackground
  } = varianceBarTooltipComparisonOptionsGetters[estimatedOrPlanned] || {};

  const planned = getPlanned
    ? getPlanned({ totals, displayFormat })
    : totals[`planned_${DISPLAY_FORMAT_QUANTITY[displayFormat]}`];

  const spent = getSpent
    ? getSpent({ totals, displayFormat })
    : +totals[`spent_${DISPLAY_FORMAT_QUANTITY[displayFormat]}`];

  const total = getTotal
    ? getTotal({
        spent,
        estimatedOrPlanned,
        totals,
        displayFormat,
        planned
      })
    : spent + planned;

  const firstRowTooltipName = getFirstRowTooltipName
    ? getFirstRowTooltipName()
    : 'planned';

  const secondRowTooltipName = getSecondRowTooltipName
    ? getSecondRowTooltipName()
    : 'spent';

  const customDisplayData = [
    {
      formattedTotal: formatTotal(
        varianceToolTipFormatType[displayFormat],
        getFirstRowTooltip
          ? getFirstRowTooltip({ totals, total, displayFormat, planned })
          : planned,
        currencyFormatter
      ),
      tooltipDotBackground: getTooltipDotBackground
        ? getTooltipDotBackground()
        : null,
      name: firstRowTooltipName,
      formattedName: firstRowTooltipName.toUpperCase()
    },
    {
      formattedTotal: formatTotal(
        varianceToolTipFormatType[displayFormat],
        spent,
        currencyFormatter
      ),
      name: secondRowTooltipName,
      formattedName: secondRowTooltipName.toUpperCase()
    }
  ];

  return JSON.stringify({ customDisplayData });
};

export const makeBudgetRowTooltipProps = ({
  varianceType,
  displayFormat,
  totals,
  estimatedOrPlanned,
  currencyFormatter,
  barsProps,
  isHoursOnly,
  headerType,
  viewBy
}) => {
  const { rowTooltipCustomProps } = barsProps;
  const { dataOffset = "{'bottom': 25}", tooltipShowHours } =
    rowTooltipCustomProps || {};

  return {
    'data-for': 'budget-bar-tooltip',
    'data-effect': 'solid',
    'data-offset': dataOffset,
    'data-tip': !varianceType
      ? getTooltipData({
          displayFormat,
          totals,
          rowTooltipCustomProps,
          isHoursOnly,
          headerType,
          tooltipShowHours,
          viewBy
        })
      : getVarianceBarTooltipData({
          varianceType,
          displayFormat,
          estimatedOrPlanned,
          totals,
          currencyFormatter,
          headerType
        })
  };
};

export const makeBarContainerTooltipProps = ({
  rowTooltipCustomProps = {},
  headerType,
  entity // Can be phase, activityPhase
}) => {
  const { shouldShowBarContainerTooltip } = rowTooltipCustomProps;
  const phaseHasChildren =
    headerType === 'phaseRow' && entity?.phase_memberships?.length > 0;
  const activityPhaseHasChildren =
    headerType === 'activityRow' &&
    entity?.activity_phase_memberships?.length > 0;

  if (
    shouldShowBarContainerTooltip &&
    (phaseHasChildren ||
      activityPhaseHasChildren ||
      headerType === 'projectHeaderRow')
  ) {
    return {
      'data-tip': 'Clicking will expand row',
      'data-for': 'app-tooltip',
      'data-effect': 'solid',
      'data-place': 'bottom',
      'data-offset': "{'top': 30, 'right': 20}"
    };
  }
  return undefined;
};

export const getBudgetRecordsMaxSpentValue = ({
  records = [],
  showHours,
  isViewingCostRate
}) => {
  let maxSpentValue = 0;

  Object.values(records).forEach((record) => {
    if (!showHours) {
      const spentCost = +record.spent_expense || 0;
      const costRateSpentCost = +record.spent_expense_cost_rate || 0;
      const spentValueToCompare = isViewingCostRate
        ? costRateSpentCost
        : spentCost;
      maxSpentValue = Math.max(maxSpentValue, spentValueToCompare);
    } else {
      maxSpentValue = Math.max(maxSpentValue, +record.spent_hours || 0);
    }
  });
  return maxSpentValue;
};

export const getBudgetRecordsMaxPlannedValue = ({
  records = [],
  showHours,
  isViewingCostRate
}) => {
  let maxPlannedValue = 0;

  Object.values(records).forEach((record) => {
    if (!showHours) {
      const plannedCost = +record.planned_expense || 0;
      const costRatePlannedCost = +record.planned_expense_cost_rate || 0;
      const plannedValueToCompare = isViewingCostRate
        ? costRatePlannedCost
        : plannedCost;
      maxPlannedValue = Math.max(maxPlannedValue, plannedValueToCompare);
    } else {
      maxPlannedValue = Math.max(maxPlannedValue, +record.planned_hours || 0);
    }
  });
  return maxPlannedValue;
};

/**
 *
 * Non Member Rows:
 *  - amount = estimate (target) OR fee
 *  - percent = target / fee
 * Member Row:
 *  - amount = estimate
 *  - percent = targetPercentOfParent (only has $ value, not hours)
 * Specific Use case: Project View FeeCell of Time Tracking Report
 * @returns { amount,formattedAmount, percent }
 */
export const getProjectViewFeeCellData = ({
  totals,
  showHours,
  rowType,
  isHoursOnly
}) => {
  const type = showHours ? DISPLAY_FORMAT.HOURS : DISPLAY_FORMAT.DOLLARS;
  const estimate = isHoursOnly
    ? +totals.estimated_hours
    : +totals[getAmountAccessor('estimated', type)];

  if (rowType === 'memberRow') {
    // Special case for Member Row
    return {
      formattedAmount: estimate
        ? showHours || isHoursOnly
          ? formatHours(estimate)
          : formatCurrency(estimate)
        : null,
      amount: estimate ?? null,
      percent: !showHours && estimate ? totals.targetPercentOfParent : null
    };
  }

  // Other rows

  // Fee
  const fee = +totals.fee;

  // Target
  const target = showHours || isHoursOnly ? estimate : estimate || fee; // Target (or Fee when no target)

  const formattedTarget = target
    ? showHours || isHoursOnly
      ? formatHours(target)
      : formatCurrency(target)
    : null;

  // Target Percent Of Fee
  const targetPercentageOfFee =
    target && fee && !showHours
      ? formatNumWithMaxOneDecimal((target / fee) * 100)
      : null;
  return {
    amount: target,
    formattedAmount: formattedTarget,
    percent: targetPercentageOfFee
  };
};

/**
 *
 * Non Member Rows:
 *  - amount = estimate (target) OR fee
 *  - percent = target / fee
 * Member Row:
 *  - amount = N/A
 *  - percent = N/A
 * Specific Use case: Member View FeeCell of Time Tracking Report
 * @returns { amount, formattedAmount,percent }
 */
export const getMemberViewFeeCellData = ({ totals, showHours, rowType }) => {
  const type = showHours ? DISPLAY_FORMAT.HOURS : DISPLAY_FORMAT.DOLLARS;

  if (rowType === 'memberHeaderRow') {
    return {
      amount: null,
      formattedAmount: null,
      percent: null
    };
  }

  // Estimate
  const estimate = +totals[getAmountAccessor('estimated', type)];
  const formattedEstimate = estimate
    ? showHours
      ? formatHours(estimate)
      : formatCurrency(estimate)
    : null;
  // Target
  const target = getRowTypeTarget(rowType, totals);
  const formattedTarget = target
    ? showHours
      ? formatHours(target)
      : formatCurrency(target)
    : null;

  // Percent
  const memberEstimatePercentOfTarget =
    showHours || !target ? null : formatPercentage((estimate / target) * 100);

  return {
    percent:
      estimate && target && memberEstimatePercentOfTarget
        ? memberEstimatePercentOfTarget
        : null,
    amount: estimate || null,
    formattedAmount: estimate && formattedEstimate ? formattedEstimate : null
  };
};

/**
 * Check to see if this entity has budgetted time (hour)
 */
export const hasBudgettedTime = ({ totals }) => {
  return +totals.remaining_percent_hour !== 0; // 0 means no budgetted hours (or basically estimatedHours = 0)
};

export const hasBudgettedDollar = ({ totals }) => {};
