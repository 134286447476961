import { createSelector, Selector } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { TeamPermissionsState } from 'PermissionsModule/reducers/types';
import { RootState } from 'reduxInfra/shared';
import { permissionsActionsArray } from 'PermissionsModule/constants';
import { PermissionsActions } from 'PermissionsModule/types';
import { roleTemplates } from './constants';
import { RoleTemplates } from './types';

const getTeamPermissionsState: Selector<RootState, TeamPermissionsState> = (
  state
) => state.teamPermissions;

export const getTeamRoleTemplatesOld = createSelector(
  getTeamPermissionsState,
  (state) => state.teamRoleTemplatesOld
);

export const getTeamRoleTemplatesByName = createSelector(
  getTeamRoleTemplatesOld,
  (teamRoleTemplates) =>
    keyBy(Object.values(teamRoleTemplates), (item) => item.name)
);

export const getPermissionActionsExpanded = createSelector(
  getTeamPermissionsState,
  (state) => state.expanded
);

export const getAllPermissionActionsExpanded = createSelector(
  getPermissionActionsExpanded,
  (permissionActions) =>
    Object.values(permissionActions).every((action) => !!action)
);

export const getAllPermissionsByActions = createSelector(
  getTeamRoleTemplatesByName,
  (teamRoleTemplates) =>
    permissionsActionsArray.reduce<
      Partial<Record<PermissionsActions, Record<RoleTemplates, boolean>>>
    >((actionHash, permissionAction) => {
      actionHash[permissionAction] = roleTemplates.reduce<
        Record<RoleTemplates, boolean>
      >((acc, cur: RoleTemplates) => {
        const teamRoleTemplate = teamRoleTemplates[cur];

        if (teamRoleTemplate) {
          acc[cur] = teamRoleTemplate[permissionAction];
        }
        return acc;
      }, {} as Record<RoleTemplates, boolean>);
      return actionHash;
    }, {})
);
