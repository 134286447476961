import { Page } from 'models/filter';
import {
  FilterLevelSchemas,
  FilterFieldSchema,
  CurrentFilter
} from 'FilterModule/types';
import {
  defaultNumberArrayFieldSchema,
  defaultStringArrayFieldSchema
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { FilterListType, FilterField } from 'FilterModule/constants';
import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';

// NOTE: this page does not actually use filters. Using filter schema to be able to reuse existing filter components,
// but these will not interact with real AccountFilters

const filterLevelName = serializeFilterLevelName({
  level1: Page.FindPeople,
  level2: 'requirements'
});

type FindPeopleFieldSchemas = {
  [FilterField.stackedFilterOrder]: FilterFieldSchema<
    FilterListType,
    FilterListType[]
  >;
  [FilterField.skillLevels]: typeof defaultStringArrayFieldSchema;
  [FilterField.region_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.discipline_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.office_ids]: typeof defaultNumberArrayFieldSchema;
};

/* -------------------------- chartViewFieldSchemas ------------------------- */

const fieldSchemas: FindPeopleFieldSchemas = {
  [FilterField.skillLevels]: defaultStringArrayFieldSchema,

  [FilterField.region_ids]: defaultNumberArrayFieldSchema,

  [FilterField.discipline_ids]: defaultNumberArrayFieldSchema,

  [FilterField.office_ids]: defaultNumberArrayFieldSchema,

  [FilterField.stackedFilterOrder]: {
    defaultValue: [
      FilterListType.SkillLevels,
      FilterListType.Disciplines,
      FilterListType.Offices,
      FilterListType.Regions
    ],
    getValidatedFieldValue: null
  }
};

/* ------------------------ initialFilterLevelSchemas ----------------------- */

export const initialFilterLevelSchemas: FilterLevelSchemas = {
  [filterLevelName]: {
    fieldSchemas
  }
};

/* ---------------------------- CurrentFilterType --------------------------- */

export type FindPeopleRequirementsCurrentFilterType =
  CurrentFilter<FindPeopleFieldSchemas>;
