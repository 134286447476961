import { useAppSelector } from 'reduxInfra/hooks';
import { useCallback, useState } from 'react';
import {
  MemberCapacityGroupListItem,
  ArchivedSectionHeaderListItem,
  MemberCapacityGroupListItemsBuilder
} from '../types';
import { membersCapacityTableHelpers } from './layout';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';
import { getCapacityGroupsByAccountHash } from 'CapacityModule/selectors/capacityGroup';

const { ROW_TYPES } = membersCapacityTableHelpers;

type UseMemberListItemsBuilderParams = Pick<
  MemberCapacityGroupListItem,
  'openCapacityPolicyMemberTimeline' | 'requestCreateCustomPolicy'
>;

export const useMemberListItemsBuilder = ({
  openCapacityPolicyMemberTimeline,
  requestCreateCustomPolicy
}: UseMemberListItemsBuilderParams) => {
  const [isArchivedMembersVisible, setIsArchivedMembersVisible] =
    useState(false);

  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const capacityGroupsByAccountHash = useAppSelector(
    getCapacityGroupsByAccountHash
  );

  const memberListRowBuilder: MemberCapacityGroupListItemsBuilder = useCallback(
    ({ order }) => {
      const archivedMemberIds: number[] = [];
      const nonArchivedMemberIds: number[] = [];

      const memberCapacityGroupListItemsBuilder = (
        accountIds: number[]
      ): MemberCapacityGroupListItem[] => {
        return accountIds.flatMap((accountId) => {
          const capacityGroup = capacityGroupsByAccountHash[accountId];

          if (!capacityGroup) return [];

          const item: MemberCapacityGroupListItem = {
            accountId,
            openCapacityPolicyMemberTimeline,
            requestCreateCustomPolicy,
            capacityGroup,
            rowType: ROW_TYPES.memberCapacityRow,
            rowHeight: 55
          };

          return [item];
        });
      };

      const archivedSectionHeaderListItemBuilder =
        (): ArchivedSectionHeaderListItem => {
          const value: ArchivedSectionHeaderListItem = {
            rowType: ROW_TYPES.archivedSectionHeaderRow,
            rowHeight: 35,
            archivedCount: archivedMemberIds.length,
            isShowingArchivedPolicies: isArchivedMembersVisible,
            onClick: () => {
              setIsArchivedMembersVisible(!isArchivedMembersVisible);
            }
          };
          return value;
        };

      (order as number[]).forEach((accountId) => {
        const teamMember = teamMembersHash[accountId];

        if (teamMember) {
          if (teamUtils.getIsArchived(teamMember)) {
            archivedMemberIds.push(teamMember.account.id);
          } else {
            nonArchivedMemberIds.push(teamMember.account.id);
          }
        }
      });

      const nonArchivedMemberCapacityGroupListItems =
        memberCapacityGroupListItemsBuilder(nonArchivedMemberIds);
      const archivedMemberCapacityGroupListItems =
        memberCapacityGroupListItemsBuilder(archivedMemberIds);
      const archivedSectionHeaderListItem =
        archivedSectionHeaderListItemBuilder();

      let items: Array<
        MemberCapacityGroupListItem | ArchivedSectionHeaderListItem
      > = [];

      if (!archivedMemberIds.length) {
        items = nonArchivedMemberCapacityGroupListItems;
      } else {
        items.push(...nonArchivedMemberCapacityGroupListItems);

        if (!isArchivedMembersVisible) {
          items.push(archivedSectionHeaderListItem);
        } else {
          items.push(...archivedMemberCapacityGroupListItems);
          items.push(archivedSectionHeaderListItem);
        }
      }

      return items;
    },
    [
      capacityGroupsByAccountHash,
      isArchivedMembersVisible,
      openCapacityPolicyMemberTimeline,
      requestCreateCustomPolicy,
      teamMembersHash
    ]
  );

  return memberListRowBuilder;
};
