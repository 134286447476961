import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import {
  createTeamPosition,
  updateTeamPosition
} from 'BudgetModule/actionCreators';
import { getPositions } from 'BudgetModule/selectors/positions';
import { Modal } from 'reactstrap';
import UpdateRows from './RoleOverrideModal/UpdateRows';
import {
  YesButton,
  NoButton,
  ButtonRow,
  RateConfirmRow,
  RateConfirmBox,
  RateDateContainer,
  RateMemberDescription,
  CopyContainer,
  CopyHeader,
  CopyParagraph,
  StyledDateChangeConfirmation,
  AffectedDate,
  OverrideHeader
} from 'SettingsModule/components/Budget/styles';
import KaratLeft from 'icons/KaratLeft';
import ProcessStartedModalBody from 'components/ProcessStartedModal/ProcessStartedModalBody';
import moment from 'moment';

const copyHash = {
  endDate: 'start date for the next',
  startDate: 'end date for the prior'
};

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

const RoleOverrideModal = ({
  onClose,
  roleMembershipBeingEdited,
  selectedNewDateProps,
  selectedNewRole,
  roleHistory,
  accountId,
  isTeamRole
}) => {
  const dispatch = useDispatch();
  const roleHash = useSelector(getPositions);
  const teamId = useSelector(getSelectedTeamId);
  const [showProcessStarted, setShowProcessStarted] = useState(false);

  /**
   * @param {boolean} shouldOverride whether or not existing projects will be updated with the new role
   */
  const handleSubmit = (shouldOverride) => {
    // Update existing TeamPosition
    if (roleMembershipBeingEdited) {
      if (isTeamRole) {
        dispatch(
          updateTeamPosition({
            id: roleMembershipBeingEdited.id,
            // update role
            ...(selectedNewRole && { positionId: selectedNewRole.id }),
            // update dates
            ...(selectedNewDateProps?.date &&
              selectedNewDateProps?.endOrStart && {
                [selectedNewDateProps.endOrStart]: formatDate(
                  selectedNewDateProps.date
                )
              }),
            overrideMemberPositions: shouldOverride,
            accountId
          })
        );
      }
    } else if (teamId && accountId && selectedNewRole) {
      if (isTeamRole) {
        // Create new TeamPosition
        dispatch(
          createTeamPosition({
            teamId,
            accountId,
            positionId: selectedNewRole.id,
            overrideMemberPositions: shouldOverride,
            startDate: formatDate(moment()),
            endDate: null
          })
        );
      }
    }
    if (shouldOverride) {
      setShowProcessStarted(true);
    } else {
      onClose();
    }
  };

  return (
    <Modal isOpen>
      <StyledDateChangeConfirmation>
        {showProcessStarted ? (
          <ProcessStartedModalBody
            handleOk={onClose}
            title="Updating Role"
            processStartedMessage="Process initiated. Updating role across all projects may take a few minutes"
          />
        ) : (
          <>
            <OverrideHeader>
              <div className="karat-container" onClick={onClose}>
                <KaratLeft height={15} className="karat-icon" />
              </div>
              Confirm Role Change
            </OverrideHeader>

            {roleMembershipBeingEdited &&
            selectedNewDateProps?.date &&
            selectedNewDateProps.endOrStart ? (
              // Updating existing member <-> role date
              <UpdateRows
                selectedDate={selectedNewDateProps.date}
                selectedDateType={selectedNewDateProps.endOrStart}
                roleHistory={roleHistory}
                roleMembershipBeingEdited={roleMembershipBeingEdited}
              />
            ) : (
              <RateConfirmBox>
                <RateConfirmRow>
                  <RateMemberDescription>
                    {roleHash[roleMembershipBeingEdited?.id]?.name ||
                      selectedNewRole.name}
                  </RateMemberDescription>
                  <RateDateContainer>
                    <AffectedDate>{moment().format('M/DD/YY')}</AffectedDate> -{' '}
                    <div>Present</div>
                  </RateDateContainer>
                </RateConfirmRow>
              </RateConfirmBox>
            )}

            <CopyContainer>
              <CopyHeader>
                Do you want to push this updated role to all projects?
              </CopyHeader>
              <CopyParagraph>
                &rarr; If you select yes, this cannot be undone.
              </CopyParagraph>
              <CopyParagraph>
                &rarr; If you click no, this role will only apply to new
                projects and the role must be manually updated on all existing
                projects.
              </CopyParagraph>
              {roleMembershipBeingEdited &&
                selectedNewDateProps?.endOrStart && (
                  <CopyParagraph>
                    The change will also update the{' '}
                    {copyHash[selectedNewDateProps?.endOrStart]} role as shown
                    above.
                  </CopyParagraph>
                )}
            </CopyContainer>

            <ButtonRow>
              <NoButton onClick={() => handleSubmit(false)}>
                No, New Only
              </NoButton>
              <YesButton onClick={() => handleSubmit(true)}>
                Yes, Apply to All
              </YesButton>
            </ButtonRow>
          </>
        )}
      </StyledDateChangeConfirmation>
    </Modal>
  );
};

export default RoleOverrideModal;
