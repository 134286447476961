import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';

const defaultBarSectionWidth = '0%';
const defaultBarSectionHeight = '7px';

type BarSection = {
  width: string;
  height: string;
  sectionBackground?: string;
};

type Props = {
  /** Main sections of the bar */
  sections: BarSection[];

  /** Total width and height of the entire bar, default to both 100% */
  totalWidth?: string;
  totalHeight?: string;

  barClassName?: string;

  /** Custom props for the empty bar when sections[] is empty
   */
  emptyBarProps?: {
    emptySectionWidth: string;
    emptySectionHeight?: string;
    emptySectionBackground?: string;
    emptyBarClassName?: string;
    emptyBarSectionClassName?: string;
  };
};

const StyledBar = styled.div<{
  height: string;
  width: string;
}>`
  display: flex;
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  background: ${theme.colors.colorLightGray12};
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
`;

const StyledBarSection = styled.div<BarSection>`
  background: ${({ sectionBackground }) =>
    sectionBackground || theme.colors.colorLightGray12};
  width: ${({ width }) => width || defaultBarSectionWidth};
  height: ${({ height }) => height || defaultBarSectionHeight};

  &.first-section {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

const SimpleBar = ({
  totalWidth = '100%',
  totalHeight = '100%',
  sections = [],
  barClassName = '',
  emptyBarProps
}: Props) => {
  if (!sections?.length) {
    const {
      emptySectionHeight = defaultBarSectionHeight,
      emptySectionWidth = defaultBarSectionWidth,
      emptySectionBackground = 'transparent',
      emptyBarClassName = 'empty-bar',
      emptyBarSectionClassName = 'empty-bar-section'
    } = emptyBarProps || {};
    return (
      <StyledBar
        className={emptyBarClassName}
        width={totalWidth}
        height={totalHeight}
      >
        <StyledBarSection
          className={emptyBarSectionClassName}
          width={emptySectionWidth}
          height={emptySectionHeight}
          sectionBackground={emptySectionBackground}
        />
      </StyledBar>
    );
  }

  return (
    <StyledBar className={barClassName} width={totalWidth} height={totalHeight}>
      {sections.map((section, index) => (
        <StyledBarSection
          key={index}
          className={cn({
            'first-section': index === 0
          })}
          {...section}
        />
      ))}
    </StyledBar>
  );
};

export default SimpleBar;
