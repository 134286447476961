import React from 'react';
import SvgIcon from '../../components/SvgIcon';

const RichBoldIcon = () => (
  <SvgIcon width="7" height="10" viewBox="0 0 7 10">
    <path
      fill="gray"
      fillRule="nonzero"
      d="M5.642 4.695A2.88 2.88 0 0 0 6.3 2.857C6.3 1.282 5.044 0 3.5 0H0v10H4.2C5.744 10 7 8.718 7 7.143a2.87 2.87 0 0 0-1.358-2.448zM2 1h1c.551 0 1 .673 1 1.5S3.551 4 3 4H2V1zm1.8 8H2V6h1.8C4.462 6 5 6.673 5 7.5S4.462 9 3.8 9z"
    />
  </SvgIcon>
);

export default RichBoldIcon;
