import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import {
  updateProjectMemberRole,
  updateBoardMemberRole,
  handleProjectItemState,
  toggleMemberInput,
  toggleInviteButton,
  toggleInviteForm,
  inviteTeamMember,
  setMemberInputValue,
  openAddMembersForm,
  closeAddMembersForm,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  populateBulkAddMembers,
  emptyBulkAddMembers,
  deleteMemberFromGroup,
  deleteMemberFromProject,
  fetchSuggestions
} from 'actionCreators';

import {
  fetchMemberBudgets,
  fetchPositions,
  fetchRates
} from 'BudgetModule/actionCreators';
import { fetchAccountsSuggestionForProjects } from 'SuggestionModule/actionCreators';

import {
  getAuth,
  getMe,
  getGroupsState,
  getAddMembersFormLocation,
  getAddMembersFormModalType,
  getAddMembersFormProject,
  getBulkAddIsPopulated,
  getBulkAddIsOpen,
  getAddMembersFormRoles,
  showInviteButton,
  getInviteFormOpen,
  getMatchedRouteParams,
  getIsOnProjectView,
  getSplitFlags,
  getSelectedTeamId
} from 'selectors';
import { AddMembersForm } from '..';
import { Modal } from 'reactstrap';
import memberInputClasses from 'appConstants/memberInputClasses';
import { isInAnyPopover, ancestorsContainClass } from 'appUtils/popoverClicks';
import validAddMembersFormClasses from 'appConstants/validAddMembersFormClasses';
import intersection from 'lodash/intersection';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import RemoveMemberModal from 'BudgetModule/components/BudgetModal/RemoveMemberModal';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { getSuggestionModule } from 'SuggestionModule/package/module';
import { SuggestionsType } from 'SuggestionModule/constants';

const StyledModal = styled(Modal)`
  &.workload-access-modal.modal-dialog {
    max-width: 478px;

    &.with-footer {
      max-width: 600px;
    }
  }
  .modal-content {
    height: 510px;
  }
`;

class AddMembersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
    bindAll(this, [
      'calcMemberFormTop',
      'onOutsideClick',
      'checkIfInputAllowed'
    ]);
  }

  defaultState() {
    return {
      memberList: [],
      formPositionStyles: {},
      showSuggestions: this.props.showSuggestionsOnOpen
    };
  }

  componentWillUnmount() {
    const { closeBulkAddMembersForm, closeAddMembersForm } = this.props;

    closeBulkAddMembersForm();
    closeAddMembersForm();
  }

  componentDidMount() {
    const {
      fetchPositions,
      fetchRates,
      project,
      isOpenMembersModal,
      teamId,
      fetchAccountsSuggestionForProjects,
      modalType
    } = this.props;

    if (isOpenMembersModal && project) {
      if (modalType === MODAL_TYPE.PROJECT) {
        fetchAccountsSuggestionForProjects({
          requests: [
            {
              project_id: project.id
            }
          ],
          suggestion_type: SuggestionsType.Account_Project,
          isoStateId: project.id
        });
      }
    }
    if (isOpenMembersModal && teamId) {
      fetchPositions({ teamId });
      fetchRates({ teamId });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchMemberBudgets,
      fetchPositions,
      fetchRates,
      isOpenMembersModal,
      project,
      teamId,
      fetchAccountsSuggestionForProjects,
      modalType
    } = this.props;

    if (teamId && prevProps.project !== project && isOpenMembersModal) {
      if (project) {
        if (modalType === MODAL_TYPE.PROJECT) {
          fetchAccountsSuggestionForProjects({
            requests: [
              {
                project_id: project.id
              }
            ],
            suggestion_type: SuggestionsType.Account_Project,
            isoStateId: project.id
          });
        }

        fetchMemberBudgets({ projectId: project.id });
      }
      fetchPositions({ teamId });
      fetchRates({ teamId });
    }
  }

  checkIfInputAllowed(e) {
    const {
      toggleMemberInput,
      setMemberInputValue,
      toggleInviteButton,
      toggleInviteForm
    } = this.props;

    if (!e) {
      toggleMemberInput(false);
      setMemberInputValue('');
      toggleInviteButton(false);
      toggleInviteForm({ open: false });
    } else if (
      (!intersection(e.target.classList, memberInputClasses).length &&
        !(
          e.target.tagName === 'SPAN' ||
          e.target.tagName === 'INPUT' ||
          e.target.tagName === 'BUTTON' ||
          e.target.tagName === 'FIELDSET' ||
          e.target.className === 'add-plus'
        )) ||
      e.target.className === 'form-project-title'
    ) {
      toggleMemberInput(false);
      setMemberInputValue('');
      toggleInviteButton(false);
      toggleInviteForm({ open: false });
    }
  }

  calcMemberFormTop(top, modalType) {
    const offsetFromIconTop = modalType === MODAL_TYPE.BOARD ? 40 : 20;
    return `${top + offsetFromIconTop}px`;
  }

  calcMemberFormLeft(right, modalType) {
    const { isOnProjectDetail } = this.props;
    const projectDetailOffset = isOnProjectDetail ? 350 : 0;
    const formWidth = modalType === MODAL_TYPE.BOARD ? 313 : 450;
    const additionalOffset = modalType === MODAL_TYPE.BOARD ? 1 : 300;
    return modalType === MODAL_TYPE.BOARD
      ? `${right - formWidth - additionalOffset}px`
      : `${right - additionalOffset - projectDetailOffset}px`;
  }

  onOutsideClick(e) {
    /*
      Prevents form from closing when clicking certain elements INSIDE form.
      must check target ancestors recursively starting from e.target: list
      is already out of DOM/cannot be queried by the time this runs.
    */
    if (this.props.bulkAddIsPopulated || this.props.inviteFormOpen) {
      return;
    }
    if (isInAnyPopover(e)) {
      return;
    }
    if (e.target.classList) {
      const clickedNodeHasClass = validAddMembersFormClasses.some(
        (classString) => e.target.classList.contains(classString)
      );
      if (clickedNodeHasClass) {
        return;
      }
    }
    if (ancestorsContainClass(e.target, validAddMembersFormClasses)) {
      return;
    }
    this.onModalClose(e);
  }

  onModalClose(e) {
    const {
      closeAddMembersForm,
      closeBulkAddMembersForm,
      toggleInviteForm,
      toggleMemberInput,
      bulkAddIsOpen,
      closeModalStep
    } = this.props;
    toggleInviteForm({ open: false });
    if (closeModalStep) {
      closeModalStep(e);
    }
    if (bulkAddIsOpen) {
      closeBulkAddMembersForm();
    } else {
      closeAddMembersForm();
      this.setState({ formPositionStyles: {} });
    }
    toggleMemberInput(false);
  }

  closeModal = (e) => {
    const { closeAddMembersForm, closeModalStep, closeMembersModal } =
      this.props;
    if (closeMembersModal) {
      closeMembersModal();
    }
    if (closeModalStep) {
      closeModalStep(e);
    }
    closeAddMembersForm(e);
  };

  setSuggestions = (newValue) => {
    this.setState({ showSuggestions: newValue });
  };

  render() {
    const {
      groups,
      roles,
      inviteTeamMember,
      bulkAddIsOpen,
      modalType,
      project,
      updateProjectMemberRole,
      updateBoardMemberRole,
      populateBulkAddMembers,
      closeBulkAddMembersForm,
      emptyBulkAddMembers,
      deleteMeFromGroup,
      deleteMeFromProject,
      me,
      isOnProjectDetail,
      handleProjectItemState,
      path,
      openFormLocation,
      formLocation,
      history,
      isOpenMembersModal,
      isContained,
      inBudgetModal,
      noFooter,
      onClose
    } = this.props;

    const { showSuggestions } = this.state;

    const boardMemberList = groups.editingGroupMemberList;
    const projectMemberList =
      (project && project.project_membership?.filter((pm) => !!pm.account)) ||
      [];
    const Wrapper = isContained ? React.Fragment : StyledModal;
    return (
      <DynamicModuleLoader modules={[getSuggestionModule()]}>
        <Wrapper
          isOpen={isOpenMembersModal}
          className={cn('workload-access-modal', { 'with-footer': !noFooter })}
          toggle={this.closeModal}
          showSuggestions={showSuggestions}
        >
          <RemoveMemberModal />
          <AddMembersForm
            isOpen={isOpenMembersModal}
            bulkAddIsOpen={bulkAddIsOpen}
            modalType={modalType}
            onOutsideClick={this.onOutsideClick}
            memberList={
              modalType === MODAL_TYPE.BOARD
                ? boardMemberList
                : projectMemberList
            }
            roles={roles}
            inviteTeamMember={inviteTeamMember}
            closeModal={this.closeModal}
            closeBulkAddMembersForm={closeBulkAddMembersForm}
            checkIfInputAllowed={this.checkIfInputAllowed}
            updateProjectMemberRole={updateProjectMemberRole}
            updateBoardMemberRole={updateBoardMemberRole}
            populateBulkAddMembers={populateBulkAddMembers}
            emptyBulkAddMembers={emptyBulkAddMembers}
            deleteMeFromGroup={deleteMeFromGroup}
            deleteMeFromProject={deleteMeFromProject}
            me={me}
            history={history}
            isOnProjectDetail={isOnProjectDetail}
            handleProjectItemState={handleProjectItemState}
            project={project}
            path={path}
            openFormLocation={openFormLocation}
            formLocation={formLocation}
            isContained={isContained}
            showSuggestions={showSuggestions}
            setSuggestions={this.setSuggestions}
            inBudgetModal={inBudgetModal}
            noFooter={noFooter}
            onClose={onClose}
          />
        </Wrapper>
      </DynamicModuleLoader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  bulkAddIsPopulated: getBulkAddIsPopulated(state),
  bulkAddIsOpen: getBulkAddIsOpen(state),
  openFormLocation: getAddMembersFormLocation(state),
  modalType: ownProps.modalType || getAddMembersFormModalType(state),
  project: ownProps.project || getAddMembersFormProject(state),
  auth: getAuth(state),
  me: getMe(state),
  groups: getGroupsState(state),
  roles: getAddMembersFormRoles(state),
  showInviteButton: showInviteButton(state),
  showInviteForm: getInviteFormOpen(state),
  isOnProjectDetail: getIsOnProjectView(state),
  path: ownProps.location.pathname,
  matchedParams: getMatchedRouteParams(state),
  inviteFormOpen: getInviteFormOpen(state),
  isOnProjectView: getIsOnProjectView(state),

  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  updateProjectMemberRole,
  updateBoardMemberRole,
  handleProjectItemState,
  toggleMemberInput,
  toggleInviteButton,
  toggleInviteForm,
  setMemberInputValue,
  inviteTeamMember,
  openAddMembersForm,
  closeAddMembersForm,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  populateBulkAddMembers,
  emptyBulkAddMembers,
  deleteMemberFromGroup,
  deleteMemberFromProject,
  fetchSuggestions,
  fetchMemberBudgets,
  fetchPositions,
  fetchRates,
  fetchAccountsSuggestionForProjects
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  updateProjectMemberRole: (member, role, projectId, permissions) => {
    dispatchProps.updateProjectMemberRole(stateProps.auth.token, {
      accountId: member.account.id,
      projectRole: role.id,
      projectId,
      permissions
    });
  },
  updateBoardMemberRole: (member, role, boardId, permissions) => {
    dispatchProps.updateBoardMemberRole(stateProps.auth.token, {
      accountId: member.account.id,
      roleId: role.id,
      id: member.id,
      boardId,
      permissions
    });
  },
  deleteMeFromGroup: (membership) =>
    dispatchProps.deleteMemberFromGroup(
      stateProps.auth.token,
      membership.account.id,
      stateProps.groups.selectedBoard.id
    ),
  deleteMeFromProject: (accountId) =>
    dispatchProps.deleteMemberFromProject({
      projectId: stateProps.project.id,
      accountId,
      memberIsLoggedUser: true
    })
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddMembersContainer)
);
