import {
  CHART_STACK_ORDER,
  DATA_KEY,
  DATA_KEY_TO_PERCENT,
  CAPACITY_COLUMN_TYPE,
  CAPACITY_ROW_TYPE
} from './constants';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import { serializeId } from 'appUtils';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

const emptyObj = {};
const emptyArray = [];

export const getTopDataField = (payload) =>
  CHART_STACK_ORDER.find((dataField) => +payload[dataField]);

export const formatData = (data, hidePto, hideHolidays) => {
  const baseForPercentage =
    hidePto && hideHolidays
      ? +data[DATA_KEY.TOTAL_AVAILABLE_CAPACITY]
      : +data[DATA_KEY.TOTAL_CAPACITY];

  const formattedTotal = {
    ...data,
    [DATA_KEY.DEMAND]: +data[DATA_KEY.DEMAND],
    [DATA_KEY.CAPACITY]:
      +data[DATA_KEY.CAPACITY] > 0 ? +data[DATA_KEY.CAPACITY] : 0,
    [DATA_KEY.PTO]: +data[DATA_KEY.PTO],
    [DATA_KEY.PTO_FOR_DISPLAY]: +data[DATA_KEY.PTO],
    [DATA_KEY.HOLIDAY]: +data[DATA_KEY.HOLIDAY],
    [DATA_KEY.HOLIDAY_FOR_DISPLAY]: +data[DATA_KEY.HOLIDAY],
    [DATA_KEY.TOTAL_AVAILABLE_CAPACITY]:
      +data[DATA_KEY.TOTAL_AVAILABLE_CAPACITY],
    [DATA_KEY.PERCENTAGE_OF_AVAILABLE_CAPACITY_PLANNED]: baseForPercentage
      ? (Math.max(+data[DATA_KEY.DEMAND], 0) / baseForPercentage) * 100
      : 0,
    [DATA_KEY.CAPACITY_PERCENT]: baseForPercentage
      ? (Math.max(+data[DATA_KEY.CAPACITY], 0) / baseForPercentage) * 100
      : 0,
    [DATA_KEY.TENTATIVE_PERCENTAGE_OF_AVAILABLE_CAPACTIY_PLANNED]:
      baseForPercentage
        ? (Math.max(+data[DATA_KEY.DEMAND_TENTATIVE], 0) / baseForPercentage) *
          100
        : 0,
    [DATA_KEY.NON_TENTATIVE_PERCENTAGE_OF_AVAILABLE_CAPACTIY_PLANNED]:
      baseForPercentage
        ? (Math.max(+data[DATA_KEY.DEMAND_NON_TENTATIVE], 0) /
            baseForPercentage) *
          100
        : 0,
    ...(!hidePto && {
      [DATA_KEY.PTO_PERCENT]:
        (+data[DATA_KEY.PTO] / +data[DATA_KEY.TOTAL_CAPACITY]) * 100 || 0
    }),
    ...(!hideHolidays && {
      [DATA_KEY.HOLIDAY_PERCENT]:
        (+data[DATA_KEY.HOLIDAY] / +data[DATA_KEY.TOTAL_CAPACITY]) * 100 || 0
    })
  };

  if (hidePto) {
    delete formattedTotal[DATA_KEY.PTO];
  }
  if (hideHolidays) {
    delete formattedTotal[DATA_KEY.HOLIDAY];
  }
  return formattedTotal;
};

export const getPercentTotal = (data, hidePto, hideHolidays) => {
  const newData = {
    [DATA_KEY.DEMAND]: +data[DATA_KEY_TO_PERCENT[DATA_KEY.DEMAND]],
    [DATA_KEY.CAPACITY]:
      +data[DATA_KEY_TO_PERCENT[DATA_KEY.CAPACITY]] > 0
        ? +data[DATA_KEY_TO_PERCENT[DATA_KEY.CAPACITY]]
        : 0,
    [DATA_KEY.PTO]: hidePto ? 0 : +data[DATA_KEY_TO_PERCENT[DATA_KEY.PTO]],
    [DATA_KEY.HOLIDAY]: hideHolidays
      ? 0
      : +data[DATA_KEY_TO_PERCENT[DATA_KEY.HOLIDAY]]
  };

  return Math.round(
    Object.entries(newData).reduce((acc, curr) => acc + curr[1], 0)
  );
};

export const getIsOverCapacity = (rowType, columnType, capacity) => {
  const overCapacityTable = {
    [CAPACITY_ROW_TYPE.POSITION_ROW]: true,
    [CAPACITY_ROW_TYPE.MEMBER_HEADER_ROW]: true,
    [CAPACITY_ROW_TYPE.MEMBER_ROW]: true
  };

  return (
    overCapacityTable[rowType] &&
    columnType === CAPACITY_COLUMN_TYPE.TOTAL &&
    +capacity < 0
  );
};

export const getChartTooltip = (value, dataKey) => {
  const tooltipHash = {
    [DATA_KEY.DEMAND]: 'hrs are Planned',
    [DATA_KEY.DEMAND_TENTATIVE]: 'hrs are Tentative',
    [DATA_KEY.DEMAND_NON_TENTATIVE]: 'hrs are Planned',
    [DATA_KEY.PTO]: 'hrs are PTO',
    [DATA_KEY.HOLIDAY]: 'hrs are Holidays',
    [DATA_KEY.CAPACITY]: 'hrs are Capacities'
  };
  const formattedValue = formatNumWithMaxOneDecimal(value);
  const suffix = tooltipHash[dataKey] ?? '';

  return `${formattedValue}${suffix}`;
};
