import styled from 'styled-components';
import { useWorkPlanForm } from '../../WorkPlanFormProvider';
import ActivityLog from 'views/projectPlanner/workloadBarModal/ActivityLog';
import { useWorkPlanRequestSidePanelForm } from 'WorkplanRequestsModule/components/WorkplanRequestSidePanelProvider';
import { PanelScroller } from '../shared/PanelScroller';

export const ActivityLogsMenuTabPanel = ({ providerType }) =>
  providerType === 'WorkPlan' ? (
    <WorkplanActivityLogsMenuTabPanel />
  ) : (
    <WorkplanRequestActivityLogsMenuTabPanel />
  );

const WorkplanActivityLogsMenuTabPanel = () => {
  const {
    activityLog: { activityLogs }
  } = useWorkPlanForm();

  return <ActivityLogsMenuTabRenderPart activityLogs={activityLogs} />;
};

const WorkplanRequestActivityLogsMenuTabPanel = () => {
  const {
    activityLog: { activityLogs }
  } = useWorkPlanRequestSidePanelForm();

  return <ActivityLogsMenuTabRenderPart activityLogs={activityLogs} />;
};

const ActivityLogsMenuTabRenderPart = ({ activityLogs }) => {
  return (
    <PanelScroller>
      <PanelContainer>
        <ActivityLog userActivities={activityLogs} />
      </PanelContainer>
    </PanelScroller>
  );
};

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
