import React from 'react';
import { useSelector } from 'react-redux';
import { getIsOnProjectView } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import DevProps from 'components/dev/DevProps';
import ProjectNumberAndBoard from 'components/projects/ProjectNumberAndBoard';
import useEntities from 'appUtils/hooks/useEntities';

const ParentInfo = ({
  scope,
  projectId,
  phaseId,
  activityId,
  targetRef,
  onClick
}) => {
  const isOnProjectView = useSelector(getIsOnProjectView);
  const { project, phase, activity, board } = useEntities({
    projectId: projectId || scope?.project_id,
    phaseId: phaseId || scope?.phase_id,
    activityId: activityId || scope?.activity_id
  });

  return (
    <StyledProjectInfo ref={targetRef}>
      <DevProps
        project={project}
        phase={phase}
        activity={activity}
        board={board}
      />
      {!project ? null : (
        <>
          <ProjectNumberAndBoard
            boardName={board?.name}
            project={project}
            showNavToProject={!isOnProjectView}
          />
          <ProjectAndPhase onClick={onClick}>
            <ProjectName>{project.title}</ProjectName>
            {project.description && ` - ${project.description}`}
            {phase && !phase.is_like_default && ` > ${phase.name}`}
            {activity && !activity?.is_default && ` > ${activity.title}`}
          </ProjectAndPhase>
        </>
      )}
    </StyledProjectInfo>
  );
};

export default ParentInfo;

/* ------------------------------------ - ----------------------------------- */

const StyledProjectInfo = styled.div`
  margin-bottom: 16px;
  min-height: 20px;
  cursor: default;
`;

const ProjectAndPhase = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(75%);
    }
  `}
`;

const ProjectName = styled.span`
  min-height: 20px;
`;
