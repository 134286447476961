import styled from 'styled-components';
import theme from 'theme';
import { CONTRACT_TYPES } from 'BudgetModule/components/BudgetTable/constants';
import { getArchivedPhaseEditTooltipProps } from 'BudgetModule/components/BudgetTable/utils';

const ToggleContainer = styled.div`
  border: 0.5px solid ${theme.colors.colorLightGray9};
  display: flex;
  height: 36px;
  font-size: 14px;
  margin-bottom: 15px;
  border-radius: 4px;
`;

const OptionContainer = styled.div<{ isDisabled: boolean; selected?: boolean }>`
  border-radius: 4px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.colorCalendarGray};
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `
    background-color: ${theme.colors.colorLightGray15};
    color: ${theme.colors.colorPureWhite};
  `}
  ${({ isDisabled }) =>
    !isDisabled &&
    `
    cursor: pointer;
  `}
`;

const noop = () => {};

/* ----------------------------------------------------------------------- */

const BillableToggle = ({
  setBillable,
  isBillable,
  isDisabled
}: {
  setBillable: (isBillable: boolean) => void;
  isBillable: boolean;
  isDisabled: boolean;
}) => {
  const handleToggle = (value: boolean) => {
    if (value === isBillable) {
      return;
    }
    setBillable(value);
  };
  return (
    <ToggleContainer>
      <OptionContainer
        {...getArchivedPhaseEditTooltipProps({
          isTooltipDisabled: !isDisabled
        })}
        isDisabled={isDisabled}
        selected={isBillable}
        onClick={isDisabled ? undefined : () => handleToggle(true)}
      >
        Billable
      </OptionContainer>
      <OptionContainer
        {...getArchivedPhaseEditTooltipProps({
          isTooltipDisabled: !isDisabled
        })}
        isDisabled={isDisabled}
        selected={!isBillable}
        onClick={isDisabled ? undefined : () => handleToggle(false)}
      >
        Not Billable
      </OptionContainer>
    </ToggleContainer>
  );
};

export default BillableToggle;
