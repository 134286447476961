import { useEffect, useMemo, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { fetchEntityComments } from 'actionCreators';
import { EntityType } from 'models/entity';
import {
  makeGetCommentCountsByParentEntity,
  makeSortedCommentsByParentEntity
} from 'selectors';

interface UseCommentsProps {
  parentEntityType: EntityType;
  parentEntityId: number | undefined;
}

const defaultInitialLimit = 5;
const defaultLazyLoadLimit = 15;
const emptyArray = [];
export const useComments = ({
  parentEntityType,
  parentEntityId
}: UseCommentsProps) => {
  const dispatch = useAppDispatch();

  const getSortedCommentsByParentEntity = useMemo(
    () => makeSortedCommentsByParentEntity(),
    []
  );
  const getCommentCounts = useMemo(
    () => makeGetCommentCountsByParentEntity(),
    []
  );
  const comments = useAppSelector((state) =>
    parentEntityId
      ? getSortedCommentsByParentEntity(state, {
          parentEntityType,
          parentEntityId
        })
      : emptyArray
  );

  const commentCounts = useAppSelector((state) =>
    parentEntityId
      ? getCommentCounts(state, {
          parentEntityType,
          parentEntityId
        })
      : undefined
  );

  const commentCountsRef = useRef<{
    loadedCount: number;
    totalCount: number;
  }>();
  useEffect(() => {
    commentCountsRef.current = commentCounts;
  }, [commentCounts]);

  const loadMoreComments = useCallback(
    ({ isInitialFetch }: { isInitialFetch?: boolean } = {}) => {
      if (parentEntityId) {
        const offset = commentCountsRef.current?.loadedCount ?? 0;
        dispatch(
          fetchEntityComments({
            parentEntityType,
            parentEntityId,
            offset: offset,
            limit:
              offset === 0 || isInitialFetch
                ? defaultInitialLimit
                : defaultLazyLoadLimit
          })
        );
      }
    },
    [dispatch, parentEntityId, parentEntityType]
  );

  useEffect(() => {
    loadMoreComments({ isInitialFetch: true });
  }, [parentEntityType, parentEntityId, dispatch, loadMoreComments]);

  const loadedCount = commentCounts?.loadedCount ?? 0;
  const totalCount = commentCounts?.totalCount ?? 0;
  const allCommentsLoaded = loadedCount >= totalCount;

  return {
    comments,
    loadMoreComments,
    loadedCount,
    totalCount,
    allCommentsLoaded
  };
};
