import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import intersection from 'lodash/intersection';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { DailyTaskDescription, TaskDateDisplay, OutsideClickWrapper } from '..';
import dailyTaskPreventModalClasses from 'appConstants/dailyTaskPreventModalClasses';
import {
  updateTaskPosition,
  fetchSingleTask,
  setEditTaskId,
  fetchCommentsAndMetadata,
  closeAndClearTaskViewModal,
  setHomeOrigin,
  onClearSearch,
  flushSelectedHomeTask,
  navigateToTaskModal
} from 'actionCreators';

import {
  getCurrentUserId,
  makeGetPlannerTask,
  makeSafeHomePlannerGetProjects,
  getTeamMembersHash,
  getAuthToken,
  getOnMembersView,
  getOnHomeView,
  getMe,
  getSearch
} from 'selectors';
import { NO_PROJECT } from 'appConstants/planners';
import { keyifyDate } from 'appUtils/plannerUtils';
import { isSameDay, makeDateFromDateTime } from 'appUtils/momentUtils';
import { TaskCardAssignee } from './styles';
import OpenModalIcon from 'icons/OpenModalIcon';

const taskDateDisplayStyle = { position: 'relative', left: '-45px' };

const moment = extendMoment(Moment);
const emptyArray = [];

// This is not currently used anywhere. Before using refactor to not use HOCS that required task to have project + all project info stuffed onto the task. Also, reimplement updateTask with correct v2 api.
class TaskCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingDueDate: false,
      scheduleDueDateOpen: false,
      mouseX: 0,
      mouseY: 0,
      scheduleStartCalendarOpen: false,
      newTaskDueDate: null,
      newTaskDescription: ''
    };
    bindAll(this, [
      'markTaskCompletion',
      'updateDescription',
      'handleClick',
      'toggleTaskModal',
      'scheduleDueDateCalendar',
      'scheduleStartCalendar',
      'closeCalendar',
      'updateDueAt',
      'convertDatePickerDate',
      'getDate',
      'updateScheduleStart'
    ]);
  }

  /*  TASK MODAL AND PROJECT SELECTION RELATED FUNCTIONS  */
  toggleTaskModal(isOpen, taskId, e) {
    const {
      setEditTaskId,
      fetchCommentsAndMetadata,
      closeAndClearTaskViewModal,
      flushSelectedHomeTask,
      isNewPlannerTask
    } = this.props;

    const isClickingTaskEditElement = (e) =>
      e &&
      (intersection(e.target.classList, dailyTaskPreventModalClasses).length ||
        e.target.tagName === 'LI');
    if (!isNewPlannerTask) {
      if (isClickingTaskEditElement(e)) return;
      if (!isOpen) {
        isOpen = false;
        closeAndClearTaskViewModal();
        flushSelectedHomeTask();
      }

      if (isOpen) {
        fetchCommentsAndMetadata({
          taskId,
          taskType: 'projects',
          offset: 0,
          limit: 4
        });
      }

      setEditTaskId(null);
    }
  }

  updateDescription(data) {
    const {
      task,
      taskId,
      isNewPlannerTask,
      laneId,
      token,
      currentAccountId,
      toggleCreateTask,
      newTaskProjectId,
      currentPosition,
      accountId,
      isOnHome,
      isOnMembers
    } = this.props;
    const isCompleted = task.completed_at;
    const projectId = task.project_id;
    const taskType = 'projects';
    const scheduleStartDate = moment(laneId, 'YYYY-MM-DD');
    if (isNewPlannerTask) {
      if (data.description.length) {
        // create task
      }
      toggleCreateTask();
    } else if (data.description.length) {
      // update task
    }
  }

  updateNewTaskDescription = (e) => {
    if (this.props.isNewPlannerTask) {
      this.setState({ newTaskDescription: e.target.value });
    }
  };

  markTaskCompletion() {
    const {
      updateTaskPosition,
      token,
      task,
      taskId,
      laneId,
      isNewPlannerTask,
      currentPosition,
      listLength
    } = this.props;

    const taskType = 'projects';
    const isCompleted = task.completed_at;
    const dateKey = laneId === null ? 'unscheduled' : laneId;
    const position = isCompleted ? currentPosition : listLength;
    if (!isNewPlannerTask) {
      updateTaskPosition({
        token,
        taskId,
        projectId: task.project_id,
        taskType,
        isCompleted: !isCompleted,
        wasCompleted: isCompleted,
        laneId,
        dateKey,
        plannerPosition: position
      });
    }
  }

  getProjectDisplay(is_personal, projectName) {
    if (is_personal) {
      return 'Personal';
    } else if (!projectName) {
      return 'No Project';
    } else {
      return projectName;
    }
  }

  handleClick(e) {
    const { task, navigateToTaskModal } = this.props;
    navigateToTaskModal({ taskId: task.id });
    this.props.fetchSingleTask({
      taskId: task.id
    });
  }

  handleOutsideClick = (e) => {
    const shouldSave =
      e &&
      !intersection(e.target.classList, dailyTaskPreventModalClasses).length;

    if (
      shouldSave &&
      this.props.isNewPlannerTask &&
      this.state.newTaskDescription
    ) {
      this.updateDescription({ description: this.state.newTaskDescription });
    }
  };

  /*  DUE DATE AND SCHEDULE CALENDAR RELATED FUNCTIONS */
  scheduleDueDateCalendar(e) {
    e.stopPropagation();
    this.setState({
      scheduleDueDateOpen: true,
      mouseX: e.clientX,
      mouseY: e.clientY,
      isEditingDueDate: true
    });
  }

  scheduleStartCalendar(e) {
    e.stopPropagation();
    this.setState({
      scheduleStartCalendarOpen: true,
      mouseX: e.clientX,
      mouseY: e.clientY,
      isEditingDueDate: true
    });
  }

  closeCalendar() {
    setTimeout(() => {
      const container = document.querySelector('#modal');
      container.style.left = ``;
      container.style.top = ``;
      container.style.right = '';
      this.setState({
        scheduleDueDateOpen: false,
        scheduleStartCalendarOpen: false,
        mouseX: 0,
        mouseY: 0,
        isEditingDueDate: false
      });
    }, 250);
  }

  updateDueAt(newDate, dateObj, e) {
    const { isNewPlannerTask, task, taskId } = this.props;
    const dueDate = moment(newDate);

    if (isNewPlannerTask) {
      this.setState({ newTaskDueDate: dueDate });
      this.closeCalendar();
    } else {
      const taskType = 'projects';
      const isCompleted = task.completed_at;
      const projectId = task.project_id;
      if (!isSameDay(task.due_at, newDate)) {
        // update task
        this.closeCalendar();
      }
    }
  }

  updateScheduleStart(newDate) {
    const { task, taskId } = this.props;
    const taskType = 'projects';
    const isCompleted = task.completed_at;
    const projectId = task.project_id;
    const scheduleStartDate = moment(newDate);
    if (!isSameDay(task.schedule_start, newDate)) {
      // update task
    }
    this.closeCalendar();
  }

  convertDatePickerDate(date) {
    if (date) {
      return makeDateFromDateTime(date).format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  getDate(date) {
    if (date) {
      return makeDateFromDateTime(date);
    } else {
      return null;
    }
  }

  /*  RENDER  */

  render() {
    const {
      task,
      isNewPlannerTask,
      setEditTaskId,
      unscheduled,
      isEditingDescription,
      editTaskId,
      taskDetail,
      teamMembersHash
    } = this.props;
    const today = moment();
    const dueDate = task.due_at && makeDateFromDateTime(task.due_at);
    const scheduleDate =
      task.schedule_start && makeDateFromDateTime(task.schedule_start);

    let displayDate = 'Date';
    if (this.state.newTaskDueDate) {
      displayDate = this.state.newTaskDueDate.format('MMM D');
    } else if (task.due_at) {
      displayDate = makeDateFromDateTime(task.due_at).format('MMM D');
    }
    const overdue =
      dueDate && dueDate.isBefore(today, 'd') && !task.completed_at;
    const scheduleOverdue =
      scheduleDate && scheduleDate.isBefore(today, 'd') && !task.completed_at;
    const isEditingCurrentTask = task.id === editTaskId && isEditingDescription;
    const assignee = task.assignee_id && teamMembersHash[task.assignee_id];

    return (
      <OutsideClickWrapper handleOutsideClick={this.handleOutsideClick}>
        <div
          className={cn('daily-task', {
            completed: task.completed_at,
            editing: isEditingCurrentTask || this.state.isEditingDueDate,
            'new-task': isNewPlannerTask,
            'no-project': task.project_name === NO_PROJECT
          })}
          id={`daily-task-${task.id}`}
          onClick={(e) => {
            if (
              !isNewPlannerTask &&
              editTaskId !== task.id &&
              e.target.className !== 'comment-bubble'
            ) {
              setEditTaskId(task.id);
            }
          }}
          // style={{ height: 104 }}
        >
          <div
            className={cn('custom-card-content', {
              'new-task': isNewPlannerTask
            })}
          >
            <div className="top-row">
              {(isNewPlannerTask || isEditingCurrentTask || task.project_id) &&
                this.props.renderProjectMenu &&
                this.props.renderProjectMenu()}
              {(isNewPlannerTask || isEditingCurrentTask || task.due_at) && (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    right: '10px'
                  }}
                >
                  <div
                    className="due-date"
                    onClick={this.scheduleDueDateCalendar}
                  >
                    {!task.due_at && 'Due '}
                    <TaskDateDisplay
                      date={displayDate}
                      overdue={overdue}
                      calendarText="Due Date"
                      openCalendar={this.scheduleDueDateCalendar}
                      closeCalendar={this.closeCalendar}
                      showCursor={false}
                      beingEdited={this.state.isEditingDueDate}
                      datePickerOpen={this.state.scheduleDueDateOpen}
                      mouseX={this.state.mouseX + 60}
                      mouseY={this.state.mouseY}
                      updateDueAt={this.updateDueAt}
                      datePickerDate={this.convertDatePickerDate(task.due_at)}
                      setEditTaskId={setEditTaskId}
                      taskId={task.id}
                      task={task}
                      scheduleDate={this.getDate(task.due_at)}
                      shouldDisplayFlag={task.due_at}
                    />
                  </div>
                  {(isNewPlannerTask || isEditingCurrentTask) && (
                    <div
                      className="due-date"
                      onClick={this.handleClick}
                      style={{ marginLeft: '10px' }}
                    >
                      <OpenModalIcon />
                    </div>
                  )}
                </div>
              )}
            </div>
            <DailyTaskDescription
              task={task}
              description={task.description}
              updateDescription={this.updateDescription}
              onChange={this.updateNewTaskDescription}
              isEditingDescription={
                isNewPlannerTask ||
                (isEditingCurrentTask && !taskDetail.taskDetailModalOpen)
              }
              setEditTaskId={setEditTaskId}
              searchWords={emptyArray}
              toggleTaskModal={this.handleClick}
            />
            <div className={cn('bottom-row', { unscheduled })}>
              <div
                className={cn('check-circle', { completed: task.completed_at })}
                onClick={this.markTaskCompletion}
              />
              {unscheduled && (
                <TaskCardAssignee
                  isAssigned={!!assignee}
                  assignedToMe={assignee && assignee.isLoggedInUser}
                >
                  {assignee ? assignee.initials : ''}
                </TaskCardAssignee>
              )}
              {!isNewPlannerTask && !unscheduled && (
                <div
                  className={cn('schedule-date', {
                    overdue: scheduleOverdue
                  })}
                  onClick={this.scheduleStartCalendar}
                >
                  <TaskDateDisplay
                    date={null}
                    overdue={scheduleOverdue}
                    calendarText={''}
                    openCalendar={this.scheduleStartCalendar}
                    closeCalendar={this.closeCalendar}
                    showCursor={false}
                    beingEdited={this.state.isEditingDueDate}
                    datePickerOpen={this.state.scheduleStartCalendarOpen}
                    updateScheduleStart={this.updateScheduleStart}
                    datePickerDate={this.convertDatePickerDate(
                      task.schedule_start
                    )}
                    setEditTaskId={setEditTaskId}
                    taskId={task.id}
                    task={task}
                    mouseX={this.state.mouseX + 25}
                    mouseY={this.state.mouseY - 30}
                    scheduleDate={this.getDate(task.schedule_start)}
                    style={taskDateDisplayStyle}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

const newTask = { id: 'new' };

const makeMapStateToProps = () => {
  const getPlannerTask = makeGetPlannerTask();
  const safeGetMyProjects = makeSafeHomePlannerGetProjects();
  const mapStateToProps = (state, ownProps) => {
    return {
      token: getAuthToken(state),
      me: getMe(state),
      currentAccountId: getCurrentUserId(state),
      search: getSearch(state),
      editTaskId: state.tasks.editTaskId,
      isEditingDescription: state.tasks.editDescription,
      myProjects: safeGetMyProjects(state, ownProps.taskId),
      taskDetail: state.taskDetail,
      task: !ownProps.isNewPlannerTask
        ? getPlannerTask(state, ownProps.taskId)
        : newTask,
      teamMembersHash: getTeamMembersHash(state),
      isOnHome: getOnHomeView(state),
      isOnMembers: getOnMembersView(state)
    };
  };

  return mapStateToProps;
};

const updateTask = () => {};
const mapDispatchToProps = {
  updateTaskPosition,
  fetchSingleTask,
  fetchCommentsAndMetadata,
  setEditTaskId,
  closeAndClearTaskViewModal,
  setHomeOrigin,
  onClearSearch,
  updateTask,
  flushSelectedHomeTask,
  navigateToTaskModal
};

export default connect(makeMapStateToProps, mapDispatchToProps)(TaskCard);
