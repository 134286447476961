import { select } from 'redux-saga/effects';
import { changeEntity as entityHelper } from 'sagas/generics';
import { getAuthToken } from 'AuthenticationModule/selectors';
import {
  generateTeamSamlInfo,
  generateTeamSamlInfoActionCreatorsMap,
  associateSamlIdentity,
  associateSamlIdentityActionCreatorsMap,
  setAuthMethod,
  setAuthMethodActionCreatorsMap,
  resetPassword,
  resetPasswordActionCreatorsMap,
  setAuthTokenFetchSuccessDetails
} from './actionCreators';
import { authenticationApi } from './api';
import { mosaicAPIClients } from 'appCore/API/apiClients';

function* setAuthTokenFetchSuccessDetailsWorker(
  action: ReturnType<typeof setAuthTokenFetchSuccessDetails>
) {
  const { realmId, skipUpdateAPIClients } = action.payload;

  if (skipUpdateAPIClients) return;

  // still needed to set API base url if realmId is provided when impersonating
  if (realmId) {
    mosaicAPIClients.useRealm(realmId);
  } else {
    mosaicAPIClients.resetToDefaultBaseURL();
  }
}

function* generateTeamSamlInfoWorker(
  action: ReturnType<typeof generateTeamSamlInfo>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    generateTeamSamlInfoActionCreatorsMap,
    authenticationApi.generateTeamSamlInfo,
    [token, action.payload],
    action
  );
}

function* associateSamlIdentityWorker(
  action: ReturnType<typeof associateSamlIdentity>
) {
  const { payload, meta } = action;
  const token = yield select(getAuthToken);

  const { response } = yield entityHelper(
    associateSamlIdentityActionCreatorsMap,
    authenticationApi.associateSamlIdentity,
    [token, payload],
    action
  );

  if (response && meta?.onSuccess) {
    meta.onSuccess();
  }
}

function* setAuthMethodWorker(action: ReturnType<typeof setAuthMethod>) {
  const { payload, meta } = action;
  const token = yield select(getAuthToken);

  const { response } = yield entityHelper(
    setAuthMethodActionCreatorsMap,
    authenticationApi.setAuthMethod,
    [token, payload],
    action
  );

  if (response && meta?.onSuccess) {
    meta.onSuccess();
  }
}

function* resetPasswordWorker(action: ReturnType<typeof resetPassword>) {
  yield entityHelper(
    resetPasswordActionCreatorsMap,
    authenticationApi.resetPassword,
    [action.payload],
    action
  );
}

export default {
  setAuthTokenFetchSuccessDetailsWorker,
  generateTeamSamlInfoWorker,
  associateSamlIdentityWorker,
  setAuthMethodWorker,
  resetPasswordWorker
};
