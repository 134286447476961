import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserIsAdmin, getUserSlug, getUserIsLoaded } from 'selectors';
import { navigateToHome } from 'actionCreators';
import checkCondition from 'appUtils/checkCondition';
const emptyObj = {};

const Nothing = () => null;
const useRenderIf = ({
  Component = Nothing,
  props,
  renderCondition,
  FallbackComponent = Nothing,
  fallbackProps,
  LoadingComponent = Nothing,
  loadingCondition,
  passThrough,
  children,
  navToHomeIfCantRender
}) => {
  const dispatch = useDispatch();
  const userSlug = useSelector(getUserSlug);

  const shouldRender = useMemo(
    () => checkCondition(renderCondition),
    [renderCondition]
  );

  const isLoading = useMemo(
    () => checkCondition(loadingCondition),
    [loadingCondition]
  );

  useEffect(() => {
    if (navToHomeIfCantRender && userSlug && !shouldRender && !isLoading) {
      dispatch(navigateToHome({ userSlug }));
    }
  }, [dispatch, isLoading, navToHomeIfCantRender, shouldRender, userSlug]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (passThrough) {
    return children(!!shouldRender);
  }

  if (shouldRender) {
    if (children) {
      return children;
    } else {
      return <Component {...props} />;
    }
  } else {
    return <FallbackComponent {...fallbackProps} />;
  }
};

export const useRenderIfAdmin = ({
  Component,
  FallbackComponent = Nothing,
  LoadingComponent = Nothing,
  fallbackProps = emptyObj,
  props = emptyObj,
  passThrough = false,
  children
}) => {
  const userIsAdmin = useSelector(getUserIsAdmin);
  const userIsLoading = !useSelector(getUserIsLoaded);

  return useRenderIf({
    props,
    Component,
    fallbackProps,
    FallbackComponent,
    LoadingComponent,
    renderCondition: userIsAdmin,
    loadingCondition: userIsLoading,
    passThrough,
    children
  });
};

export default useRenderIf;
