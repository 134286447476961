/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  openRemoveMemberModal,
  updatePositionSkills,
  createPositionSkills,
  fetchPositionSkills,
  deletePositionSkills,
  updatePositionLocationRange,
  deletePositionLocationRange
} from 'BudgetModule/actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';
import { ThreeDotMenu, ThreeDotMenuContainer } from 'components/ThreeDotMenu';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import { StyledDropdownItem as BudgetStyledDropdownItem } from 'BudgetModule/components/BudgetTable/styles';
import DeleteIcon from 'icons/DeleteIcon';
import DeleteXIcon from 'icons/DeleteXIcon';
import ListIcon from 'images/list-icon.svg';
import ToolIcon from 'images/tool-icon.svg';
import DisciplineIcon from 'images/discipline-icon.svg';
import RegionIcon from 'images/region-icon.svg';
import BookIcon from 'images/book-icon.svg';
import Initial from 'components/Initial/Initial';

import MapIcon from 'images/map-icon.svg';
// import AddRequirementsDropdown from 'BudgetModule/components/BudgetModal/AddRequirementsDropdown';
import RequirementsDropdown from 'components/dropdowns/RequirementsDropdown/RequirementsDropdown';
import DistanceDropdown from 'components/dropdowns/DistanceDropdown';
import SuggestionsTableDropdown from 'components/dropdowns/SuggestionsTableDropdown';
import MultilevelDropdown, {
  MOUSE_EVENTS
} from 'components/MultiLevelDropdown';
import Popover from 'components/Popover';

import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import { getAreSkillsLoaded, getSkills, getTeamSlug } from 'selectors';
import useMemberBudget from 'appUtils/hooks/budget/useMemberBudget';
import useSettingsCategory from 'SettingsModule/hooks/useSettingsCategory';
import { updateDisciplineEntities } from 'SettingsModule/actionCreators/settings/discipline';
import { updateOfficeEntities } from 'SettingsModule/actionCreators/settings/office';
import { updateRegionEntities } from 'SettingsModule/actionCreators/settings/region';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import {
  navigateToMembersRegionSettings,
  navigateToMembersDisciplineSettings,
  navigateToMembersOfficeSettings
} from 'actionCreators';
import * as constants from 'appConstants';

/** Utils */

const makeInitialSelectedItemsIds = (items, phaseMembershipIdOfPosition) => {
  const selectedItemsIds = [];

  items.forEach((item) => {
    const phaseMembershipIds = item.phase_membership_ids || [];
    let i = 0;
    while (i < phaseMembershipIds.length) {
      const cur = phaseMembershipIds[i];
      if (phaseMembershipIdOfPosition === cur) {
        selectedItemsIds.push(item.id);
        break;
      }
      i++;
    }
  });

  return selectedItemsIds;
};

const StyledDropdownItem = styled(BudgetStyledDropdownItem)`
  padding-left: 0;
`;

const StyledKaratContainer = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-left: -5px;
  margin-top: 1px;
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
`;

const StyledNameContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  margin-left: 4px;
  cursor: pointer;
  overflow: hidden;
  max-width: 210px;
  white-space: pre;
  text-overflow: ellipsis;
`;

const StyledRoleNameContainer = styled.div`
  display: flex;
  align-items: center;
  .position-three-dot-menu {
    path {
      stroke: ${theme.colors.colorMediumGray9};
      fill: ${theme.colors.colorMediumGray9};
    }
  }
  &:hover {
    ${StyledNameContainer} {
      color: ${theme.colors.colorRoyalBlue};
    }
    .position-three-dot-menu {
      path {
        stroke: ${theme.colors.colorRoyalBlue};
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: -4px;
  margin-left: 5px;

  .add-skills-text {
    color: ${theme.colors.colorCalendarBlue};
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 46px;
  }
  ${ThreeDotMenuContainer} {
    width: 28px;
    margin-right: 0;
    visibility: visible;
    opacity: 1;
  }
`;

const IconContainer = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  svg {
    width: 9px;
  }

  &.karat-container {
    position: relative;
    left: 20px;
  }
`;

const RequirementsContainer = styled.div`
  display: flex;
  width: 137%;
  margin-left: 90px;
  margin-top: 5px;
  float: left;
  align-items: center;
  position: relative;
  bottom: 3px;
  z-index: 1;
`;

const TagsContainer = styled.div`
  display: block;
  text-align: left;
  padding-top: 7px;
`;

const TagContainer = styled.span`
  display: inline-block;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  max-width: 80px;
  height: 25px;

  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 12px;
  margin-right: 5px;

  padding-left: 5px;
  padding-right: 5px;

  border-radius: 5px;
  background-color: #e4e4e4;

  cursor: pointer;

  .tag {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 4px;
  }
`;
const DROPDOWN_MENU_HASH = {
  REMOVE_ROLE: 'removeRole',
  ADD_REQUIRMENTS: 'addRequirements',
  MANAGE_SKILLS: 'manageSkills',
  MANAGE_DISTANCE: 'manageDistance',
  MANAGE_DISCIPLINE: 'manageDiscipline',
  MANAGE_OFFICE: 'manageOffice',
  MANAGE_REGION: 'manageRegion'
};

const { MANAGE_DISCIPLINE, MANAGE_OFFICE, MANAGE_REGION } = DROPDOWN_MENU_HASH;

const CATEGORIES = [MANAGE_DISCIPLINE, MANAGE_OFFICE, MANAGE_REGION];

const DeleteIconContainer = styled.span`
  padding-left: 5px;
  display: inline-block;
  overflow: hidden;
`;

const DropdownText = styled.span`
  font-size: 13px;
`;

const MENU_CONTENT = {
  items: [
    {
      name: DROPDOWN_MENU_HASH.ADD_REQUIRMENTS,
      label: (
        <StyledDropdownItem onClick={() => {}}>
          <IconContainer>
            <img src={ListIcon} height={18} width={18} />
          </IconContainer>
          <DropdownText>Add Requirements</DropdownText>
          <IconContainer className="karat-container">
            <KaratRight height={12} width={12} />
          </IconContainer>
        </StyledDropdownItem>
      ),
      subMenu: {
        items: [
          {
            name: DROPDOWN_MENU_HASH.MANAGE_SKILLS,
            label: (
              <StyledDropdownItem onClick={() => {}}>
                <IconContainer>
                  <img src={ToolIcon} height={18} width={18} />
                </IconContainer>
                <DropdownText>Skills</DropdownText>
                {/* <IconContainer>
              <KaratRight height={12} width={12} />
            </IconContainer> */}
              </StyledDropdownItem>
            )
          },
          {
            name: DROPDOWN_MENU_HASH.MANAGE_DISTANCE,
            label: (
              <StyledDropdownItem onClick={() => {}}>
                <IconContainer>
                  <img src={MapIcon} height={18} width={18} />
                </IconContainer>
                <DropdownText>Distance</DropdownText>
                {/* <IconContainer>
              <KaratRight height={12} width={12} />
            </IconContainer> */}
              </StyledDropdownItem>
            )
          },
          {
            name: DROPDOWN_MENU_HASH.MANAGE_REGION,
            label: (
              <StyledDropdownItem onClick={() => {}}>
                <IconContainer>
                  <img src={RegionIcon} height={18} width={14} />
                </IconContainer>
                <DropdownText>Region</DropdownText>
              </StyledDropdownItem>
            )
          },
          {
            name: DROPDOWN_MENU_HASH.MANAGE_DISCIPLINE,
            label: (
              <StyledDropdownItem onClick={() => {}}>
                <IconContainer>
                  <img src={BookIcon} height={18} width={12} />
                </IconContainer>
                <DropdownText>Discipline</DropdownText>
              </StyledDropdownItem>
            )
          },
          {
            name: DROPDOWN_MENU_HASH.MANAGE_OFFICE,
            label: (
              <StyledDropdownItem onClick={() => {}}>
                <IconContainer>
                  <img src={DisciplineIcon} height={18} width={12} />
                </IconContainer>
                <DropdownText>Office</DropdownText>
              </StyledDropdownItem>
            )
          }
        ]
      }
    },
    {
      name: DROPDOWN_MENU_HASH.REMOVE_ROLE,
      label: (
        <StyledDropdownItem fontSize={13}>
          <IconContainer>
            <DeleteIcon />
          </IconContainer>
          <DropdownText>Remove Role</DropdownText>
        </StyledDropdownItem>
      )
    }
  ]
};

const AddRequirementText = styled.span`
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};
  white-space: nowrap;
  position: relative;

  &.add {
    bottom: 5px;
  }
`;

const PositionTitleCell = ({ row }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const teamSlug = useSelector(getTeamSlug);
  const [threeDotIsOpen, setThreeDotIsOpen] = useState(false);
  const [showSkillsDropdown, setShowSkillsDropdown] = useState(false);
  const [showDistanceDropdown, setShowDistanceDropdown] = useState(false);

  /** Generic dropdown for office, region and discipline */
  const [selectedDropdown, setSelectedDropdown] = useState('');

  const {
    officeHash,
    offices,
    regionHash,
    regions,
    disciplineHash,
    disciplines
  } = useSettingsCategory();

  const skillsHash = useSelector(getSkills);
  const skillsLoaded = useSelector(getAreSkillsLoaded);
  const {
    id,
    customRowProps: {
      handleSetIsOpen,
      handleSetRowHeight,
      rowHeights,
      showDemoSuggestions
    },
    isOpen,
    rowType,
    position: {
      activityPhaseMembershipId,
      memberBudget,
      phaseMembershipId,
      activity
    },
    phase
  } = row.original;

  const skillTagsContainerRef = useRef(null);

  useEffect(() => {
    if (skillTagsContainerRef.current) {
      const { height } = skillTagsContainerRef.current.getBoundingClientRect();
      handleSetRowHeight({ id, rowType, height });
    }
  }, [
    memberBudget,
    rowHeights,
    disciplineHash,
    disciplines,
    offices,
    officeHash,
    regions,
    regionHash
  ]);

  useEffect(() => {
    if (memberBudget?.id) {
      dispatch(fetchPositionSkills({ member_budget_id: memberBudget?.id }));
    }
  }, [dispatch, memberBudget?.id]);

  useEffect(() => {
    rebuildTooltip();
  }, [
    memberBudget,
    disciplineHash,
    disciplines,
    offices,
    officeHash,
    regions,
    regionHash
  ]);

  const handleOpenRemoveMemberModal = useCallback(() => {
    dispatch(
      openRemoveMemberModal({
        memberBudget: {
          ...memberBudget,
          phaseId: phase.id,
          activity,
          phaseMembershipId: phaseMembershipId,
          activityMembershipId: activityPhaseMembershipId,
          memberBudgetId: memberBudget.id,
          isUnassigned: true
        }
      })
    );
    setThreeDotIsOpen(false);
  }, [
    activity,
    activityPhaseMembershipId,
    dispatch,
    memberBudget,
    phase.id,
    phaseMembershipId
  ]);

  const handleRemovePositionSkill = (member_budget_id, position_skill_id) => {
    dispatch(
      deletePositionSkills({
        member_budget_id,
        position_skill_id
      })
    );
    ReactTooltip.hide();
  };

  const handleCreatePositionSkills = (member_budget_id, skillsToCreate) => {
    if (skillsToCreate.length === 0) return;
    dispatch(
      createPositionSkills({
        member_budget_id,
        skills: skillsToCreate.map((skill) => ({
          team_skill_id: skill.team_skill_id,
          level: skill.level
        }))
      })
    );
  };

  const handleUpdatePositionSkills = (member_budget_id, skillsToUpdate) => {
    if (skillsToUpdate.length === 0) return;
    skillsToUpdate.map((skill) =>
      dispatch(
        updatePositionSkills({
          member_budget_id,
          position_skill_id: skill.position_skill_id,
          level: skill.level
        })
      )
    );
  };

  const handleSelectSkills = useCallback(
    (skillsToUpdate, skillsToCreate, skillsToAddAndUpdateHash) => {
      handleUpdatePositionSkills(memberBudget.id, skillsToUpdate); // Update skills needs position skill Id
      handleCreatePositionSkills(memberBudget.id, skillsToCreate); // Create skills does NOT need position skill id
    },
    [handleRemovePositionSkill, memberBudget.id, memberBudget.skills]
  );

  const handleSelectLocationRange = useCallback(
    (newRadius) => {
      if (!newRadius) return;

      if (newRadius === memberBudget.radius) {
        dispatch(
          deletePositionLocationRange({ member_budget_id: memberBudget.id })
        );
      } else {
        dispatch(
          updatePositionLocationRange({
            member_budget_id: memberBudget.id,
            range_id: newRadius
          })
        );
      }
    },
    [dispatch, memberBudget.id, memberBudget.radius]
  );

  const dropdownProps = useMemo(
    () => ({
      [DROPDOWN_MENU_HASH.MANAGE_DISCIPLINE]: {
        dropdownType: DROPDOWN_MENU_HASH.MANAGE_DISCIPLINE,
        items: disciplines,
        itemsHash: disciplineHash,
        selectedItemsIds: makeInitialSelectedItemsIds(
          disciplines,
          phaseMembershipId
        ),
        headerText: 'Discipline',
        subHeaderText: 'Discipline of Project',
        copy: {
          headerInitial: 'Discipline',
          headerEdit: '',
          headerAdd: '',
          sticky: '',
          footerInitial: (
            <LockWithTooltip
              text={`Manage Discipline`}
              tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Discipline."
            />
          ),
          addConfirm: '',
          editConfirm: ''
        },
        onFooterClick: () => {
          dispatch(
            navigateToMembersDisciplineSettings({
              teamSlug,
              openInNewWindow: true
            })
          );
        },
        removeEntities: (disciplineId) =>
          disciplineRemoveEntities(disciplineId),
        onDropdownUpdate: (addOrRemoveIdsHash) => {
          Object.entries(addOrRemoveIdsHash).map(([disciplineId, value]) => {
            value
              ? disciplineAddEntities(disciplineId)
              : disciplineRemoveEntities(disciplineId);
          });
          setSelectedDropdown('');
        }
      },
      [DROPDOWN_MENU_HASH.MANAGE_OFFICE]: {
        dropdownType: DROPDOWN_MENU_HASH.MANAGE_OFFICE,
        headerText: 'Office',
        subHeaderText: 'Office of Project',
        items: offices,
        itemsHash: officeHash,
        selectedItemsIds: makeInitialSelectedItemsIds(
          offices,
          phaseMembershipId
        ),
        copy: {
          headerInitial: 'Office',
          headerEdit: '',
          headerAdd: '',
          sticky: '',
          footerInitial: (
            <LockWithTooltip
              text={`Manage Office`}
              tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Office."
            />
          ),
          addConfirm: '',
          editConfirm: ''
        },
        removeEntities: (officeId) => officeRemoveEntities(officeId),
        onFooterClick: () => {
          dispatch(
            navigateToMembersOfficeSettings({ teamSlug, openInNewWindow: true })
          );
        },
        onDropdownUpdate: (addOrRemoveIdsHash) => {
          Object.entries(addOrRemoveIdsHash).map(([officeId, value]) => {
            value
              ? officeAddEntities(officeId)
              : officeRemoveEntities(officeId);
          });
          setSelectedDropdown('');
        }
      },
      [DROPDOWN_MENU_HASH.MANAGE_REGION]: {
        dropdownType: DROPDOWN_MENU_HASH.MANAGE_REGION,
        headerText: 'Region',
        subHeaderText: 'Region of Project',
        items: regions,
        itemsHash: regionHash,
        selectedItemsIds: makeInitialSelectedItemsIds(
          regions,
          phaseMembershipId
        ),
        copy: {
          headerInitial: 'Region',
          headerEdit: '',
          headerAdd: '',
          sticky: '',
          footerInitial: (
            <LockWithTooltip
              text={`Manage Region`}
              tooltipContent="Only Admins and Financial</br>Managers can Add or Edit Region."
            />
          ),
          addConfirm: '',
          editConfirm: ''
        },
        removeEntities: (regionId) => regionRemoveEntities(regionId),
        onFooterClick: () => {
          dispatch(
            navigateToMembersRegionSettings({ teamSlug, openInNewWindow: true })
          );
        },
        onDropdownUpdate: (addOrRemoveIdsHash) => {
          Object.entries(addOrRemoveIdsHash).map(([regionId, value]) => {
            value
              ? regionAddEntities(regionId)
              : regionRemoveEntities(regionId);
          });
          setSelectedDropdown('');
        }
      }
    }),
    [disciplineHash, disciplines, offices, officeHash, regions, regionHash]
  );

  const THREE_DOT_ONCLICK_HASH = useMemo(
    () => ({
      [DROPDOWN_MENU_HASH.MANAGE_DISTANCE]: () => {
        setShowDistanceDropdown(true);
        setThreeDotIsOpen(false);
      },
      [DROPDOWN_MENU_HASH.MANAGE_SKILLS]: () => {
        setShowSkillsDropdown(true);
        setThreeDotIsOpen(false);
      },
      [DROPDOWN_MENU_HASH.MANAGE_DISCIPLINE]: () => {
        setSelectedDropdown(DROPDOWN_MENU_HASH.MANAGE_DISCIPLINE);
        setThreeDotIsOpen(false);
      },
      [DROPDOWN_MENU_HASH.MANAGE_OFFICE]: () => {
        setSelectedDropdown(DROPDOWN_MENU_HASH.MANAGE_OFFICE);
        setThreeDotIsOpen(false);
      },
      [DROPDOWN_MENU_HASH.MANAGE_REGION]: () => {
        setSelectedDropdown(DROPDOWN_MENU_HASH.MANAGE_REGION);
        setThreeDotIsOpen(false);
      },
      [DROPDOWN_MENU_HASH.REMOVE_ROLE]: handleOpenRemoveMemberModal
    }),
    [handleOpenRemoveMemberModal]
  );

  const disciplineAddEntities = (disciplineId) => {
    dispatch(
      updateDisciplineEntities({
        id: disciplineId,
        addEntities: [
          {
            entityId: phaseMembershipId,
            entityType: ENTITY_TYPES.phaseMembership
          }
        ]
      })
    );
  };

  const disciplineRemoveEntities = (disciplineId) => {
    dispatch(
      updateDisciplineEntities({
        id: disciplineId,
        removeEntities: [
          {
            entityId: phaseMembershipId,
            entityType: ENTITY_TYPES.phaseMembership
          }
        ]
      })
    );
  };

  const officeAddEntities = (officeId) => {
    dispatch(
      updateOfficeEntities({
        id: officeId,
        addEntities: [
          {
            entityId: phaseMembershipId,
            entityType: ENTITY_TYPES.phaseMembership
          }
        ]
      })
    );
  };

  const officeRemoveEntities = (officeId) => {
    dispatch(
      updateOfficeEntities({
        id: officeId,
        removeEntities: [
          {
            entityId: phaseMembershipId,
            entityType: ENTITY_TYPES.phaseMembership
          }
        ]
      })
    );
  };

  const regionAddEntities = (regionId) => {
    dispatch(
      updateRegionEntities({
        id: regionId,
        addEntities: [
          {
            entityId: phaseMembershipId,
            entityType: ENTITY_TYPES.phaseMembership
          }
        ]
      })
    );
  };

  const regionRemoveEntities = (regionId) => {
    dispatch(
      updateRegionEntities({
        id: regionId,
        removeEntities: [
          {
            entityId: phaseMembershipId,
            entityType: ENTITY_TYPES.phaseMembership
          }
        ]
      })
    );
  };

  /* ---------------------------------- render ------------------------- */
  const renderTags = () => {
    const isNothingSelected =
      !memberBudget?.skills &&
      !memberBudget.radius &&
      CATEGORIES.every(
        (category) =>
          getSelectedDropdownProps(category).selectedItemsIds.length === 0
      );

    if (isNothingSelected) {
      return (
        <AddRequirementText onClick={() => setThreeDotIsOpen(true)}>
          + Filter
        </AddRequirementText>
      );
    }

    return (
      <TagsContainer ref={skillTagsContainerRef}>
        {skillsLoaded &&
          memberBudget.skills?.map((skill) => {
            const skillName = skillsHash[skill.team_skill_id]?.name;
            const skillLevel = skill.level;
            const skillNameAndLevel = `${skillName} ${
              skillLevel ? `| ${skillLevel}` : ''
            }`;

            return (
              <Tag
                key={id}
                skill={skill}
                id={skill.team_skill_id}
                dataTip={skillNameAndLevel}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSkillsDropdown(true);
                }}
                name={skillNameAndLevel}
                onDelete={(e) => {
                  e.stopPropagation();
                  handleRemovePositionSkill(memberBudget.id, skill.id);
                }}
              />
            );
          })}
        {constants.LOCATION_DISTANCES.map((distance) => {
          if (memberBudget.radius === distance) {
            return (
              <Tag
                key={id}
                id={distance}
                dataTip={`${distance} Miles`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDistanceDropdown(true);
                }}
                name={`${distance} Miles`}
                onDelete={(e) => {
                  e.stopPropagation();
                  dispatch(
                    deletePositionLocationRange({
                      member_budget_id: memberBudget.id
                    })
                  );
                }}
              />
            );
          }
        })}
        {CATEGORIES.map((dropdownType) => {
          const { itemsHash, selectedItemsIds, removeEntities } =
            getSelectedDropdownProps(dropdownType);

          return selectedItemsIds.map((id) => {
            const name = itemsHash[id]?.name || '';
            return (
              <Tag
                key={id}
                id={id}
                dataTip={name}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDropdown(dropdownType);
                }}
                name={name}
                onDelete={(e) => {
                  e.stopPropagation();
                  removeEntities(id);
                }}
              />
            );
          });
        })}
        <AddRequirementText
          className="add"
          onClick={() => setThreeDotIsOpen(true)}
        >
          + Add
        </AddRequirementText>
      </TagsContainer>
    );
  };

  const levelSelectionTargetRef = useRef(null);

  const renderSkillsRequirements = () => {
    return (
      showSkillsDropdown && (
        <>
          <RequirementsDropdown
            levelSelectionTargetRef={levelSelectionTargetRef}
            selectedSkills={
              memberBudget.skills
                ? memberBudget.skills.reduce((acc, skill) => {
                    acc[skill.team_skill_id] = {
                      team_skill_id: skill.team_skill_id,
                      position_skill_id: skill.id,
                      level: skill.level
                    };
                    return acc;
                  }, {})
                : {}
            }
            skillsHash={skillsHash}
            onClose={(
              skillsToUpdate,
              skillsToCreate,
              skillsToAddAndUpdateHash
            ) => {
              setShowSkillsDropdown(false);
              handleSelectSkills(
                skillsToUpdate,
                skillsToCreate,
                skillsToAddAndUpdateHash
              );
            }}
            targetRef={dropdownRef}
          />{' '}
        </>
      )
    );
  };

  const renderDistanceRequirements = () => {
    return (
      showDistanceDropdown && (
        <DistanceDropdown
          selectedRange={memberBudget.radius}
          onClose={(id) => {
            handleSelectLocationRange(id);
            setShowDistanceDropdown(false);
          }}
          targetRef={dropdownRef}
        />
      )
    );
  };

  /** Non-specific dropdown */

  const getSelectedDropdownProps = (dropdownType) =>
    dropdownProps[dropdownType];

  const onGenericDropdownUpdate = (addOrRemoveIdsHash, dropdownType) => {
    const { onDropdownUpdate } = getSelectedDropdownProps(dropdownType);
    onDropdownUpdate(addOrRemoveIdsHash);
  };

  const onGenericDropdownClose = () => setSelectedDropdown('');

  const onGenericFooterClick = (dropdownType) => {
    const { onFooterClick } = getSelectedDropdownProps(dropdownType);
    onFooterClick();
  };

  const renderGenericDropdown = () => {
    if (!selectedDropdown) return null;
    const {
      selectedItemsIds,
      items,
      copy,
      dropdownType,
      headerText,
      subHeaderText
    } = getSelectedDropdownProps(selectedDropdown);

    const dropdownPropsToUse = {
      targetRef: dropdownRef,
      phaseMembershipId,
      initialSelectedItemsIds: selectedItemsIds,
      items,
      copy,
      dropdownType,
      headerText,
      subHeaderText,
      onFlyoutMenuFooterClick: onGenericFooterClick,
      onUpdate: onGenericDropdownUpdate,
      onClose: onGenericDropdownClose
    };
    return <SuggestionsTableDropdown {...dropdownPropsToUse} />;
  };

  const renderMenu = useCallback(() => {
    return (
      <Popover
        isOpen={threeDotIsOpen}
        target={dropdownRef}
        closePopover={() => setThreeDotIsOpen(false)}
      >
        <MultilevelDropdown
          menuContent={MENU_CONTENT}
          onClickHash={THREE_DOT_ONCLICK_HASH}
          openMethod={MOUSE_EVENTS.CLICK}
        />
      </Popover>
    );
  }, [THREE_DOT_ONCLICK_HASH, threeDotIsOpen]);

  const { name } = useMemberBudget({
    memberBudget,
    projectId: phase.project_id
  });

  const displayName = name;

  return (
    <>
      <StyledTitleContainer ref={dropdownRef}>
        <StyledKaratContainer
          isOpen={isOpen || false}
          className="app-cues-styled-karat"
          onClick={() => {
            handleSetIsOpen({ name: id, value: !isOpen });
          }}
        >
          <KaratRight />
        </StyledKaratContainer>
        <StyledRoleNameContainer>
          <ThreeDotMenu
            className="position-three-dot-menu"
            overrideIsOpen={threeDotIsOpen}
            overrideSetIsOpen={setThreeDotIsOpen}
            color={theme.colors.colorRoyalBlue}
          ></ThreeDotMenu>
          {renderMenu()}
          <Initial
            dataTip={`Unassigned ${displayName} Role`}
            value={displayName}
          />
          <StyledNameContainer onClick={() => setThreeDotIsOpen(true)}>
            {displayName}
          </StyledNameContainer>
        </StyledRoleNameContainer>
      </StyledTitleContainer>
      <span
        style={{ position: 'absolute', visibility: 'hidden' }}
        ref={levelSelectionTargetRef}
      >
        Invisible span used as a target for level flyout
      </span>
      <RequirementsContainer>
        {renderTags()}
        {renderSkillsRequirements()}
        {renderDistanceRequirements()}
        {renderGenericDropdown()}
      </RequirementsContainer>
    </>
  );
};

const Tag = ({ id, onClick, onDelete, dataTip, name }) => {
  return (
    <TagContainer
      data-for="app-tooltip"
      data-effect="solid"
      data-class="mw-40vw word-break-all"
      data-tip={dataTip}
      onClick={onClick}
    >
      <span className="tag">{name}</span>
      <DeleteIconContainer onClick={onDelete}>
        <DeleteXIcon color={theme.colors.colorMediumGray9} />
      </DeleteIconContainer>
    </TagContainer>
  );
};

export default PositionTitleCell;
