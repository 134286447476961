import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TimeIcon = ({
  className,
  height = '16',
  width = '16',
  currentColor = '#A4A4A4'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="transparent"
  >
    <path
      d="M10.3968 1.4922C9.58535 1.16562 8.7265 1 7.84353 1C4.39472 1 1.47559 3.57649 1.05209 6.99398C1.01737 7.27313 1 7.55964 1 7.84415C1 11.6174 4.0701 14.6875 7.84341 14.6875C11.6174 14.6875 14.6875 11.6174 14.6875 7.84415C14.6868 5.03377 13.0035 2.54007 10.3968 1.4922ZM7.84353 14.1739C4.35266 14.1739 1.51303 11.3343 1.51303 7.8435C1.51303 7.5797 1.52906 7.31456 1.56178 7.05611C1.95248 3.89593 4.65326 1.51297 7.84355 1.51297C8.66033 1.51297 9.45508 1.66591 10.205 1.96778C12.616 2.9375 14.1741 5.24422 14.1741 7.84345C14.1741 11.3343 11.3344 14.1739 7.84353 14.1739Z"
      fill={currentColor}
    />
    <path
      d="M11.5134 7.58705H8.09998V3.35165M7.58707 3.35165V7.8435C7.58707 7.98508 7.70194 8.09995 7.84353 8.09995H11.5134M10.3968 1.4922C9.58535 1.16562 8.7265 1 7.84353 1C4.39472 1 1.47559 3.57649 1.05209 6.99398C1.01737 7.27313 1 7.55964 1 7.84415C1 11.6174 4.0701 14.6875 7.84341 14.6875C11.6174 14.6875 14.6875 11.6174 14.6875 7.84415C14.6868 5.03377 13.0035 2.54007 10.3968 1.4922ZM7.84353 14.1739C4.35267 14.1739 1.51303 11.3343 1.51303 7.8435C1.51303 7.5797 1.52906 7.31456 1.56178 7.05611C1.95248 3.89593 4.65326 1.51297 7.84355 1.51297C8.66033 1.51297 9.45508 1.66591 10.205 1.96778C12.616 2.9375 14.1741 5.24422 14.1741 7.84345C14.1741 11.3343 11.3344 14.1739 7.84353 14.1739Z"
      stroke={currentColor}
      strokeWidth="0.904959"
    />
  </SvgIcon>
);

export default TimeIcon;
