import { useEffect, useMemo } from 'react';
import theme from 'theme';
import {
  closeActivitySideMenu,
  fetchActivityLogUserActivities,
  updateAccountFilterLocal
} from 'actionCreators';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import ActivityIcon from 'icons/ActivityTimesheetIcon';
import CloseIcon from 'icons/NewCloseIcon';

import moment from 'moment';
import { actionableTypesHash } from 'appConstants/userActivities';
import {
  ActivityLogCard,
  CardContainer,
  FirstColumn
} from 'views/projectPlanner/workloadBarModal/ActivityLog';
import {
  getTeamMembershipsByAccountId,
  makeGetActivitiesByFilter,
  makeGetIsFetchingActivitiesByFilter,
  makeGetUserActivitiesOffsetByFilter,
  makeGetUserActivitiesAllFetchedByFilter,
  makeGetActiveWorkloadPlannerFilter
} from 'selectors';
import { Waypoint } from 'react-waypoint';
import Spinner from 'react-spinkit';
import ActivitySideMenuFooter from './ActivitySideMenuFooter';
import ActivitySideMenuProjectInfo, {
  ActivityLogCardProjectInfo
} from './ActivitySideMenuProjectInfo';
import ActivitySideMenuMemberInfo from './ActivitySideMenuMemberInfo';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import Filters from 'components/filters/Filters';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { FILTER_SECTIONS } from 'appConstants/filters';

const filterSections = [
  // FILTER_SECTIONS.MEMBERS,  The BE does not support this yet
  FILTER_SECTIONS.PROJECTS,
  FILTER_SECTIONS.EDITORS
];

const ActivitySideMenu = () => {
  const dispatch = useAppDispatch();

  const getActiveFilter = useMemo(makeGetActiveWorkloadPlannerFilter, []);

  const activeFilter = useAppSelector((state) =>
    getActiveFilter(state, {
      filterId: 'activitySideMenu',
      pageName: 1
    })
  );

  const getIsFetching = useMemo(makeGetIsFetchingActivitiesByFilter, []);
  const isFetchingUserActivities = useAppSelector((state) =>
    getIsFetching(state, { filterStateId: 'activitySideMenu' })
  );

  const getUserActivities = useMemo(makeGetActivitiesByFilter, []);
  const userActivities = useAppSelector((state) =>
    getUserActivities(state, { filterStateId: 'activitySideMenu' })
  );

  const getUserActivitiesOffset = useMemo(
    makeGetUserActivitiesOffsetByFilter,
    []
  );
  const offset = useAppSelector((state) =>
    getUserActivitiesOffset(state, { filterStateId: 'activitySideMenu' })
  );

  const getUserActivitiesAllFetched = useMemo(
    makeGetUserActivitiesAllFetchedByFilter,
    []
  );
  const userActivitiesAllFetched = useAppSelector((state) =>
    getUserActivitiesAllFetched(state, { filterStateId: 'activitySideMenu' })
  );

  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const projectIds = useMemo(
    () =>
      Array(
        new Set(
          userActivities.map(
            (userActivity) =>
              userActivity.display_data?.schedule_bar?.project_id
          )
        )
      ).filter((id) => id),
    [userActivities]
  );

  useFetchUnloadedProjects({ projectIds });

  useEffect(() => {
    dispatch(
      fetchActivityLogUserActivities({
        actionable_type: actionableTypesHash.ActivityPhaseScheduleBar,
        start_date: activeFilter.custom.startDate
          ? activeFilter.custom.startDate.format('YYYY-MM-DD')
          : undefined,
        end_date: activeFilter.custom.endDate
          ? activeFilter.custom.endDate.format('YYYY-MM-DD')
          : undefined,
        offset: 0,
        limit: 50,
        initial: true,
        filterStateId: 'activitySideMenu',
        project_ids: activeFilter.project_ids,
        actor_ids: activeFilter.custom.editors
      })
    );
  }, [
    dispatch,
    activeFilter.custom.startDate,
    activeFilter.custom.endDate,
    activeFilter.project_ids,
    activeFilter.custom.editors
  ]);

  const handleClose = () => {
    dispatch(closeActivitySideMenu());
  };

  const lazyLoad = (waypointEvent) => {
    if (isFetchingUserActivities || userActivitiesAllFetched) {
      return;
    }
    dispatch(
      fetchActivityLogUserActivities({
        actionable_type: actionableTypesHash.ActivityPhaseScheduleBar,
        offset: offset,
        start_date: activeFilter.custom.startDate
          ? activeFilter.custom.startDate.format('YYYY-MM-DD')
          : undefined,
        end_date: activeFilter.custom.endDate
          ? activeFilter.custom.endDate.format('YYYY-MM-DD')
          : undefined,
        limit: 50,
        initial: false,
        filterStateId: 'activitySideMenu',
        project_ids: activeFilter.project_ids,
        actor_ids: activeFilter.custom.editors
      })
    );
  };

  const getActivityCardProjectAndMemberInfo = (userActivity) => (
    <>
      <ActivitySideMenuMemberInfo
        accountId={userActivity.display_data?.schedule_bar?.account_id}
      />
      <ActivitySideMenuProjectInfo
        scheduleBar={{
          ...(userActivity.display_data?.schedule_bar ?? {})
        }}
        userActivityId={userActivity.user_activity_id}
      />
    </>
  );

  const getActivityCardFooter = (userActivity) => (
    <ActivitySideMenuFooter
      scheduleBar={{
        ...(userActivity.display_data?.schedule_bar ?? {})
      }}
    />
  );

  const onDateSubmit = ({ startDate, endDate }) => {
    const end = endDate || moment(startDate);
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        custom: {
          startDate,
          endDate: end
        }
      })
    );
  };

  const handleClearDates = () => {
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        custom: {
          startDate: null,
          endDate: null
        }
      })
    );
  };

  return (
    <ActivitySideMenuContainer className="activity-side-menu-filter">
      <CloseIconContainer>
        <CloseIcon
          height={'30'}
          width={'30'}
          noCircle={true}
          onClick={handleClose}
          fillColor={theme.colors.colorPureBlack}
        />
      </CloseIconContainer>
      <Header>
        <ActivityIconContainer>
          <ActivityIcon
            height={'19'}
            width={'19'}
            color={theme.colors.colorPureBlack}
            className=""
          />
        </ActivityIconContainer>
        Activity Log
      </Header>
      {/* <CalendarContainer>
        <DateRangeCalendar
          itemStartDate={activeFilter.custom.startDate}
          itemEndDate={activeFilter.custom.endDate}
          // @ts-expect-error incorrect inferred types since component written in JS
          onSubmit={onDateSubmit}
          isWeekly={false}
          showClear
          customLabel={
            !(activeFilter.custom.startDate && activeFilter.custom.endDate)
              ? 'Select Date'
              : undefined
          }
          onClear={handleClearDates}
          customInput={undefined}
          onSetRange={undefined}
          showSetRange={undefined}
          onClose={undefined}
          showInputs={undefined}
          labels={undefined}
          workplanAllDay={undefined}
          workplanUseWeeklyPlanning={undefined}
          workplanIncludeWeekends={undefined}
          workplanTentative={undefined}
          workplanStartTime={undefined}
          workplanEndTime={undefined}
          workplanDailyHours={undefined}
          isWorkplanCalendar={undefined}
          isPhaseCalendar={undefined}
          disableDependency={undefined}
          phaseStartDate={undefined}
          phaseEndDate={undefined}
          phaseId={undefined}
          isOOO={undefined}
          accountId={undefined}
          phases={undefined}
          isTaskCalendar={undefined}
          item={undefined}
          itemType={undefined}
          isScopeCalendar={undefined}
          isMilestoneCalendar={undefined}
        />
      </CalendarContainer> */}
      <FilterContainer>
        <Filters
          sections={filterSections}
          activeFilter={activeFilter}
          useNewIconWithText
        />
      </FilterContainer>
      <ActivitySideMenuListContainer>
        {isFetchingUserActivities && userActivities.length === 0 ? (
          <SkeletonLoader
            numLoaders={4}
            loaderStyle={{ height: 70, rx: 4, width: '90%' }}
          />
        ) : userActivities.length ? (
          userActivities.map((userActivity) => {
            const actor = userActivity.actor?.is_integration_account
              ? { account: userActivity.actor }
              : teamMembershipsByAccountId[userActivity.actor?.id];
            const assignedMember =
              userActivity.display_data?.schedule_bar?.account_id;
            const footer = getActivityCardFooter(userActivity);
            const projectAndMemberInfo =
              getActivityCardProjectAndMemberInfo(userActivity);

            return (
              <ActivityLogCardContainer key={userActivity.id}>
                <ActivityLogCard
                  userActivity={userActivity}
                  actor={actor}
                  assignedMember={assignedMember}
                  displayData={userActivity.display_data}
                  actionableType={userActivity.actionable_type}
                  hideBottomRow={false}
                  footer={footer}
                  memberRow={projectAndMemberInfo}
                />
              </ActivityLogCardContainer>
            );
          })
        ) : (
          <NoActivityText> No Activities to Show </NoActivityText>
        )}
        {!!userActivities.length && !userActivitiesAllFetched && (
          <Waypoint onEnter={(waypointEvent) => lazyLoad(waypointEvent)}>
            <div style={{ textAlign: 'center' }}>
              <Spinner fadeIn="none" name="ball-beat" />
            </div>
          </Waypoint>
        )}
      </ActivitySideMenuListContainer>
    </ActivitySideMenuContainer>
  );
};

// ************* Styling *********** //

const ActivitySideMenuContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  color: ${theme.colors.colorPureBlack};
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

const ActivityIconContainer = styled.div`
  margin-right: 10px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  cursor: pointer;
`;

const ActivitySideMenuListContainer = styled.div`
  height: 90%;
  width: 100%;
  overflow: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  ${CardContainer} {
    border-bottom: none;
    .member-line {
      &.member-header {
        ${FirstColumn} {
          margin-top: -9px !important;
        }
      }
    }
  }
`;

const ActivityLogCardContainer = styled.div`
  margin-bottom: 25px;
  ${ActivityLogCardProjectInfo} {
    margin: 10px 0px;
    margin-left: -5px;
  }
`;

const NoActivityText = styled.div`
  text-align: center;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
`;

const CalendarContainer = styled.div`
  margin-left: 17px;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
`;

export default ActivitySideMenu;
