import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import Popover from 'components/Popover';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { InlineHelpIcon } from '../styles';
import { SelectDropdownProps } from 'RatesModule/components/SelectInput/SelectInput';
import {
  MenuItem,
  MenuList
} from 'SettingsModule/components/BudgetSettings/Menu';
import { BudgetTrackingType } from 'BudgetModule/models/BudgetViewSettings';

export const BUDGET_TRACKING_COPY: Record<
  BudgetTrackingType,
  { label: string; tooltip: string }
> = {
  [BudgetTrackingType.Hours]: {
    label: 'Hours',
    tooltip: 'Time will be tracked in hours, not currency.'
  },
  [BudgetTrackingType.Currency]: {
    label: 'Currency',
    tooltip:
      'Time will be billed to clients. Note: Individual projects can be modified in budget settings to track time only.'
  }
} as const;

export const BudgetTrackingDropdown = ({
  isOpen,
  onClose,
  onSelect,
  target,
  value
}: SelectDropdownProps<BudgetTrackingType> & {
  value?: BudgetTrackingType;
}) => {
  const handleClose = () => {
    ReactTooltip.hide();
    onClose();
  };

  const handleItemClick = (id: BudgetTrackingType) => () => {
    onSelect(id);
    handleClose();
  };

  useEffect(() => () => {
    ReactTooltip.hide();
  });
  useEffect(() => {
    if (isOpen) rebuildTooltip();
    else ReactTooltip.hide();
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      stopPropagation
      target={target}
      togglePopover={handleClose}
    >
      <MenuList>
        {Object.entries(BUDGET_TRACKING_COPY).map(
          ([itemValue, { label, tooltip }]) => (
            <MenuItem
              {...defaultTooltipProps}
              data-class="center mw-250 w-100vw"
              data-tip={tooltip}
              key={itemValue}
              onClick={handleItemClick(itemValue as BudgetTrackingType)}
              selected={itemValue === value}
            >
              {label}
              <InlineHelpIcon />
            </MenuItem>
          )
        )}
      </MenuList>
    </Popover>
  );
};
