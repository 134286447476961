import BudgetContainer from '../Budget/BudgetContainer';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';

const SpentTimeReportTab = ({
  isDashboardWidgetModal,
  widgetDetailModalThreeDotMenuItems
}) => {
  const {
    viewBy,
    viewType,
    pageName,
    activeFilter,
    widgetConfig,
    filterSections
  } = useReportsViewFilter();

  return (
    <BudgetContainer
      activeFilter={activeFilter}
      pageName={pageName}
      viewBy={viewBy}
      filterSections={filterSections}
      isInModal={false}
      varianceType={false}
      isProjectViewReport={false}
      widgetDetailModalThreeDotMenuItems={widgetDetailModalThreeDotMenuItems}
      widgetConfig={widgetConfig}
      isDashboardWidgetModal={isDashboardWidgetModal}
      viewType={viewType}
    />
  );
};

export default SpentTimeReportTab;
