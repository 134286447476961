import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import ColorPicker from 'components/ColorPicker';
import { calculateTagColorPreference } from 'appUtils/styleUtils';
import { ColorPickerHoverArea } from 'components/ColorPicker/styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const TagContainer = styled(ColorPickerHoverArea)`
  display: flex;
  width: fit-content;
`;

const calcBackgroundColor = (props) => {
  if (props.selected) {
    return (
      calculateTagColorPreference(props) || props.theme.colors.colorRoyalBlue
    );
  } else {
    return '#e5e5e5';
  }
};
const calcBorderColor = (props) => {
  return calculateTagColorPreference(props) || 'transparent';
};
const TagPill = styled.div`
  background-color: ${calcBackgroundColor} !important;
  border-color: ${calcBorderColor} !important;
  border: 1px solid;
  border-radius: 4px;
`;

class Tag extends React.PureComponent {
  filterTasks = () => {
    const { tag, filterByTag } = this.props;
    filterByTag(tag.id, tag.title);
  };

  render() {
    const { tag, isActiveFilter } = this.props;
    return (
      <TagContainer>
        <ColorPicker
          id={tag.id}
          originType={ORIGIN_TYPE_STRINGS.TAG}
          row
          shouldHide={false}
          className="color-picker-container"
          // ref={setColorPickerRef}
        />
        <TagPill
          id={`tag-${tag.id}`}
          title="Click to filter by tag"
          className={cn('tag-item', {
            filter: isActiveFilter
          })}
          onClick={this.filterTasks}
          color={tag.color}
          selected={isActiveFilter}
          tagId={tag.id}
        >
          {tag.title}
        </TagPill>
      </TagContainer>
    );
  }
}

export default Tag;
