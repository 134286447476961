import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CollapseAllArrows = ({
  fill = '#4a4a4a',
  height = '12',
  width = '12'
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 12 12">
      <g clipPath="url(#clip0)">
        <path
          d="M3.58502 9.59436L1.17938 7.18872L2.34845 6.01872L5.99909 9.66936L9.64973 6.01872L10.8188 7.18872L8.41315 9.59436C7.08991 10.9176 6.00331 12 5.99815 12C5.99299 12 4.90596 10.9177 3.58315 9.59436H3.58502ZM3.58502 3.57564L1.17938 1.17L2.34845 0L4.17377 1.82532L6.00005 3.65112L7.82537 1.82532L9.65117 0L10.2357 0.585L10.8207 1.17L8.41506 3.57564C7.09182 4.89888 6.00522 5.98128 6.00006 5.98128C5.9949 5.98128 4.90787 4.89894 3.58506 3.57564H3.58502Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CollapseAllArrows;
