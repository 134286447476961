import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getOnProjectDetail,
  getAuth,
  getGroupsState,
  getMatchedRouteParams
} from 'selectors';
import { stopPropagation, noop } from 'appUtils';
import {
  handleProjectItemState,
  useSearchState,
  saveSearchState,
  onClearSearch,
  filterByMembers,
  clearSelectedProject,
  openAddEditGroupModal,
  changeBoardPosition,
  switchBoards,
  editGroup,
  navigateToBoard
} from 'actionCreators';
import styled from 'styled-components';
import CreateFolderIcon from 'icons/CreateFolderIcon';

import DraggableBoardList from './DraggableBoardList';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { CREATE_PORTFOLIO_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const StyledAddFolderIcon = styled(CreateFolderIcon)`
  height: 19px;
  width: 28px;
  margin-right: 3px;
  path {
    stroke-width: 0.8;
  }
`;
export class BoardList extends React.Component {
  state = {
    dragging: false
  };

  handleAddBoardClick = () => {
    const { openAddEditGroupModal } = this.props;
    openAddEditGroupModal({ isEdit: false, id: null });
  };

  selectGroup = (groupId, teamSlug, groupSlug) => {
    const {
      onClearSearch,
      filterByMembers,
      history,
      clearSelectedProject,
      switchBoards,
      handleProjectItemState,
      toggleIsOpenBoardMenu,
      groups,
      isOnHome,
      isOnProjectDetail,
      isOnSettings,
      navigateToBoard
    } = this.props;
    const selectedGroupId = groups.selectedGroupID;

    if (
      groupId !== selectedGroupId ||
      isOnHome ||
      (isOnProjectDetail && groupId !== selectedGroupId) ||
      isOnSettings
    ) {
      onClearSearch();
      filterByMembers(false, []);
      toggleIsOpenBoardMenu({ isOpen: false });
      handleProjectItemState(
        null,
        ['detailVisible', 'expanded', 'fixed'],
        [false, false, false]
      );
      clearSelectedProject();
      switchBoards();
      navigateToBoard({
        teamSlug,
        boardSlug: groupSlug,
        boardId: groupId
      });
    }
  };

  onClickListItem = (boardId, teamSlug, groupSlug) => {
    const { useSearchState, saveSearchState, onClickListItem } = this.props;

    if (onClickListItem) {
      onClickListItem();
    }

    useSearchState({ home: false, board: false });
    saveSearchState({ home: '', board: '' });
    this.selectGroup(boardId, teamSlug, groupSlug);
  };

  onDragEnd = (result) => {
    const { destination, draggableId } = result;
    const { boards } = this.props;
    if (!destination) {
      return;
    }
    const board = boards.find((board) => board && board.id === draggableId);
    const newList = boards.filter((board) => board && board.id !== draggableId);
    newList.splice(destination.index, 0, board);

    this.props.changeBoardPosition(draggableId, destination.index);
  };

  getDropdownStyles = () => {
    const { navbar } = this.props;
    const boardName = document.querySelector('h3.board-name');
    let width = null;
    if (boardName) {
      boardName.getBoundingClientRect().width > 137
        ? (width = boardName.getBoundingClientRect().width)
        : (width = null);
    }
    if (this.props.showTitle) {
      width = 'auto';
    }

    return {
      width,
      zIndex: 1000,
      left: navbar && 'unset',
      right: navbar && '-60px'
    };
  };

  render() {
    const {
      teamBoardMenuOpen,
      showTitle,
      navbar,
      groups,
      boards,
      canCreatePortfolio
    } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable
          droppableId={`board-list-${navbar ? 'navbar' : 'board-view'}`}
        >
          {(provided, snapshot) =>
            teamBoardMenuOpen ? (
              <div
                style={this.getDropdownStyles()}
                className="boards-section scroll-box"
                onClick={stopPropagation}
              >
                {showTitle && <div className="section-header">BOARDS</div>}
                <div className="group-menu-actions">
                  <div className="group-menu-actions-add">
                    <div
                      className="add-board-button"
                      onClick={
                        canCreatePortfolio ? this.handleAddBoardClick : noop
                      }
                      data-testid="option-new"
                      data-for="app-tooltip"
                      data-effect="solid"
                      data-class="center"
                      data-tip={CREATE_PORTFOLIO_TIP}
                      data-tip-disable={canCreatePortfolio}
                    >
                      <StyledAddFolderIcon />
                      New Portfolio
                    </div>
                  </div>
                </div>
                <ul
                  ref={provided.innerRef}
                  className={`${
                    this.props.showTitle ? 'group' : 'list'
                  } group-menu-list`}
                >
                  <DraggableBoardList
                    list={boards.filter((board) => !!board)}
                    selectedGroupId={groups.selectedGroupID}
                    onClickListItem={this.onClickListItem}
                    editGroup={this.props.editGroup}
                  />
                  {provided.placeholder}
                </ul>
              </div>
            ) : null
          }
        </Droppable>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state, params) => ({
  path: params.location.pathname,
  isOnHome: state.router.location.pathname.includes('home'),
  isOnProjectDetail: getOnProjectDetail(state),
  isOnSettings: state.router.location.pathname.includes('settings'),
  boards:
    state.users.teams[0] && state.users.teams[0].boards
      ? state.users.teams[0].boards.filter((board) => !board.is_administrative)
      : [],
  groups: getGroupsState(state),
  auth: getAuth(state)
});

const mapDispatchToProps = {
  useSearchState,
  saveSearchState,
  onClearSearch,
  filterByMembers,
  clearSelectedProject,
  handleProjectItemState,
  openAddEditGroupModal,
  changeBoardPosition,
  switchBoards,
  editGroup,
  navigateToBoard
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardList)
);
