import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createRequestTypes } from 'appUtils';
import {
  CreateRateGroupError,
  CreateRateGroupFailureResponse,
  CreateRateGroupRequestParams,
  CreateRateGroupSuccessResponse,
  DeleteRateGroupError,
  DeleteRateGroupFailureResponse,
  DeleteRateGroupSuccessResponse,
  FetchRateEntitiesError,
  FetchRateEntitiesFailureResponse,
  FetchRateEntitiesRequestParams,
  FetchRateEntitiesSuccessResponse,
  FetchRateGroupsError,
  FetchRateGroupsFailureResponse,
  FetchRateGroupsRequestParams,
  FetchRateGroupsSuccessResponse,
  FetchRatesError,
  FetchRatesFailureResponse,
  FetchRatesRequestParams,
  FetchRatesSuccessResponse,
  UpdateRateGroupError,
  UpdateRateGroupFailureResponse,
  UpdateRateGroupRequestParams,
  UpdateRateGroupSuccessResponse,
  FetchEntityRateHistoryRequestParams,
  FetchEntityRateHistorySuccessResponse,
  FetchEntityRateHistoryError,
  FetchEntityRateHistoryFailureResponse,
  CreateEntityRateRequestParams,
  CreateEntityRateSuccessResponse,
  CreateEntityRateError,
  CreateEntityRateFailureResponse,
  UpdateEntityRateSuccessResponse,
  UpdateEntityRateError,
  UpdateEntityRateFailureResponse,
  DeleteEntityRateFailureResponse,
  DeleteEntityRateError,
  DeleteEntityRateSuccessResponse,
  DeleteRateGroupRequestParams,
  UpdateEntityRateRequestParams,
  DeleteEntityRateRequestParams,
  FetchRatesActionParams,
  CreateRateGroupActionParams,
  UpdateRateGroupActionParams,
  DeleteRateGroupActionParams,
  CreateEntityRateActionParams,
  UpdateEntityRateActionParams,
  DeleteEntityRateActionParams
} from './api/types';
import { createActionWithMeta } from 'reduxInfra/utils/createActionWithMeta';

/* ------------------------------ fetch rates ------------------------------- */

const FETCH_RATES = createRequestTypes('FETCH_RATES_V2');

export const fetchRates = createActionWithMeta<FetchRatesActionParams>(
  FETCH_RATES.TRIGGER
);

export const fetchRatesApiActions = createActionCreatorsMap<
  ActionParamsWithMeta<FetchRatesRequestParams>,
  FetchRatesSuccessResponse,
  IMeta,
  FetchRatesError,
  FetchRatesFailureResponse
>(FETCH_RATES);

/* ------------------------------ clear rates ------------------------------- */

const CLEAR_RATES = 'CLEAR_RATES';

export const clearRates = createAction(CLEAR_RATES);

/* --------------------------- fetch rate groups ---------------------------- */

const FETCH_RATE_GROUPS = createRequestTypes('FETCH_RATE_GROUPS');

export const fetchRateGroups =
  createActionWithMeta<FetchRateGroupsRequestParams>(FETCH_RATE_GROUPS.TRIGGER);

export const fetchRateGroupsApiActions = createActionCreatorsMap<
  ActionParamsWithMeta<FetchRateGroupsRequestParams>,
  FetchRateGroupsSuccessResponse,
  IMeta,
  FetchRateGroupsError,
  FetchRateGroupsFailureResponse
>(FETCH_RATE_GROUPS);

/* ---------------------------- clear rate data ----------------------------- */

const CLEAR_RATE_GROUPS = 'CLEAR_RATE_GROUPS';

export const clearRateGroups = createAction(CLEAR_RATE_GROUPS);

/* --------------------------- create rate group ---------------------------- */

const CREATE_RATE_GROUP = createRequestTypes('CREATE_RATE_GROUP');

export const createRateGroup = createAction<CreateRateGroupActionParams>(
  CREATE_RATE_GROUP.TRIGGER
);

export const createRateGroupApiActions = createActionCreatorsMap<
  CreateRateGroupRequestParams,
  CreateRateGroupSuccessResponse,
  undefined,
  CreateRateGroupError,
  CreateRateGroupFailureResponse
>(CREATE_RATE_GROUP);

/* ---------------------------- update rate group --------------------------- */

const UPDATE_RATE_GROUP = createRequestTypes('UPDATE_RATE_GROUP');

export const updateRateGroup = createAction<UpdateRateGroupActionParams>(
  UPDATE_RATE_GROUP.TRIGGER
);

export const updateRateGroupApiActions = createActionCreatorsMap<
  UpdateRateGroupRequestParams,
  UpdateRateGroupSuccessResponse,
  undefined,
  UpdateRateGroupError,
  UpdateRateGroupFailureResponse
>(UPDATE_RATE_GROUP);

/* ---------------------------- delete rate group --------------------------- */

const DELETE_RATE_GROUP = createRequestTypes('DELETE_RATE_GROUP');

export const deleteRateGroup = createAction<DeleteRateGroupActionParams>(
  DELETE_RATE_GROUP.TRIGGER
);

export const deleteRateGroupApiActions = createActionCreatorsMap<
  DeleteRateGroupRequestParams,
  DeleteRateGroupSuccessResponse,
  undefined,
  DeleteRateGroupError,
  DeleteRateGroupFailureResponse
>(DELETE_RATE_GROUP);

/* ----------------------- fetch entity rate history ------------------------ */

const FETCH_ENTITY_RATES_HISTORY = createRequestTypes(
  'FETCH_ENTITY_RATES_HISTORY'
);

export const fetchEntityRateHistory =
  createActionWithMeta<FetchEntityRateHistoryRequestParams>(
    FETCH_ENTITY_RATES_HISTORY.TRIGGER
  );

export const fetchEntityRateHistoryApiActions = createActionCreatorsMap<
  ActionParamsWithMeta<FetchEntityRateHistoryRequestParams>,
  FetchEntityRateHistorySuccessResponse,
  IMeta,
  FetchEntityRateHistoryError,
  FetchEntityRateHistoryFailureResponse
>(FETCH_ENTITY_RATES_HISTORY);

/* ----------------------- clear entity rate history ------------------------ */

const CLEAR_ENTITY_RATE_HISTORY = 'CLEAR_ENTITY_RATE_HISTORY';

export const clearEntityRateHistory = createAction(CLEAR_ENTITY_RATE_HISTORY);

/* --------------------------- create entity rate --------------------------- */

const CREATE_ENTITY_RATE = createRequestTypes('CREATE_ENTITY_RATE');

export const createEntityRate = createAction<CreateEntityRateActionParams>(
  CREATE_ENTITY_RATE.TRIGGER
);

export const createEntityRateApiActions = createActionCreatorsMap<
  CreateEntityRateRequestParams,
  CreateEntityRateSuccessResponse,
  IMeta,
  CreateEntityRateError,
  CreateEntityRateFailureResponse
>(CREATE_ENTITY_RATE);

/* --------------------------- update entity rate --------------------------- */

const UPDATE_ENTITY_RATE = createRequestTypes('UPDATE_ENTITY_RATE');

export const updateEntityRate = createAction<UpdateEntityRateActionParams>(
  UPDATE_ENTITY_RATE.TRIGGER
);

export const updateEntityRateApiActions = createActionCreatorsMap<
  UpdateEntityRateRequestParams,
  UpdateEntityRateSuccessResponse,
  IMeta,
  UpdateEntityRateError,
  UpdateEntityRateFailureResponse
>(UPDATE_ENTITY_RATE);

/* --------------------------- delete entity rate --------------------------- */

const DELETE_ENTITY_RATE = createRequestTypes('DELETE_ENTITY_RATE');

export const deleteEntityRate = createAction<DeleteEntityRateActionParams>(
  DELETE_ENTITY_RATE.TRIGGER
);

export const deleteEntityRateApiActions = createActionCreatorsMap<
  DeleteEntityRateRequestParams,
  DeleteEntityRateSuccessResponse,
  IMeta,
  DeleteEntityRateError,
  DeleteEntityRateFailureResponse
>(DELETE_ENTITY_RATE);

/* -------------------------- fetch rate entities --------------------------- */

const FETCH_RATE_ENTITIES = createRequestTypes('FETCH_RATE_ENTITIES');

export const fetchRateEntities =
  createActionWithMeta<FetchRateEntitiesRequestParams>(
    FETCH_RATE_ENTITIES.TRIGGER
  );

export const fetchRateEntitiesApiActions = createActionCreatorsMap<
  FetchRateEntitiesRequestParams,
  FetchRateEntitiesSuccessResponse,
  IMeta,
  FetchRateEntitiesError,
  FetchRateEntitiesFailureResponse
>(FETCH_RATE_ENTITIES);

/* -------------------------- clear rate entities --------------------------- */

const CLEAR_RATE_ENTITIES = 'CLEAR_RATE_ENTITIES';

export const clearRateEntities = createAction(CLEAR_RATE_ENTITIES);
