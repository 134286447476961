import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  TaskContentWrapper,
  TaskContentMain,
  TaskContentScroller
} from './styles';
import {
  setEditTaskId,
  setSelectedHomeTask,
  fetchCommentsAndMetadata
} from 'actionCreators';
import TaskContentTop from './TaskContentTop';
import TaskContentLowerMiddle from './TaskContentLowerMiddle';
import TaskContentUpperMiddle from './TaskContentUpperMiddle';
import TaskContentBottom from './TaskContentBottom';

const noop = () => {};

class TaskContent extends PureComponent {
  handleSetTaskAsEditing = () => {
    const {
      triggerSetEditTaskId,
      taskId,
      handleContentClick,
      triggerFetchCommentsAndMetadata,
      isSelectedHomeTask
    } = this.props;
    triggerSetEditTaskId(taskId);
    const params = {
      taskId,
      taskType: 'projects',
      offset: 0,
      limit: 4
    };
    if (!isSelectedHomeTask) {
      triggerFetchCommentsAndMetadata(params);
    }
    if (handleContentClick) {
      handleContentClick();
    }
  };

  handleClick = (event) => {
    const { handleContentClick, isSelectedHomeTask } = this.props;
    if (isSelectedHomeTask) {
      this.handleSetTaskAsEditing();
    }
    if (handleContentClick) {
      handleContentClick();
    }
    event.stopPropagation();
  };

  render() {
    const {
      taskIsEditing,
      openTaskModal,
      markTaskCompletion,
      renderProjectPopoverMenuItem,
      handleProjectMenuSelection,
      task,
      shouldRenderAssignee,
      taskId,
      handleTasksAttributesUpdate,
      handleDescriptionTextAreaEvent,
      handleDescriptionSubmit,
      currentDescriptionText,
      isOnTeamProject,
      isCreatingTask,
      isSelectedHomeTask,
      beingEdited,
      isComplete,
      isBatchSelected,
      handleTaskEditClick,
      handleContentClick,
      isTaskEditProperty,
      checkForTyping,
      handleNoteChange,
      noteText,
      rowNumber,
      selectedProject,
      handleBlur,
      setEditingHeight = noop,
      index,
      isVirtualList,
      onDelete,
      testIdPrefix,
      handleClose
    } = this.props;
    const displayTopAndBottom =
      isSelectedHomeTask || (taskIsEditing && isTaskEditProperty);
    return (
      <TaskContentWrapper
        isSelectedHomeTask={isSelectedHomeTask}
        isTaskModal={isSelectedHomeTask}
        isOnTeamProject={isOnTeamProject}
        isCreatingTask={isCreatingTask}
        isComplete={isComplete}
        isBatchSelected={isBatchSelected}
        beingEdited={beingEdited}
        isTaskEditProperty={isTaskEditProperty}
        isVirtualList={isVirtualList}
        onClick={this.handleClick}
      >
        <TaskContentMain
          isSelectedHomeTask={isSelectedHomeTask}
          isTaskModal={isSelectedHomeTask}
          isOnTeamProject={isOnTeamProject}
          isCreatingTask={isCreatingTask}
          isComplete={isComplete}
          isBatchSelected={isBatchSelected}
          beingEdited={beingEdited}
          isTaskEditProperty={isTaskEditProperty}
        >
          <TaskContentTop
            isTaskModal={isSelectedHomeTask}
            taskIsEditing={taskIsEditing}
            openTaskModal={openTaskModal}
            handleTasksAttributesUpdate={handleTasksAttributesUpdate}
            taskId={taskId}
            isSelectedHomeTask={isSelectedHomeTask}
            isOnTeamProject={isOnTeamProject}
            isBatchSelected={isBatchSelected}
            isCreatingTask={isCreatingTask}
            handleTaskEditClick={handleTaskEditClick}
            task={task}
            taskGroupId={task.task_group_id}
            isTaskEditProperty={isTaskEditProperty}
            show={displayTopAndBottom}
            markTaskCompletion={markTaskCompletion}
            rowNumber={rowNumber}
            renderProjectPopoverMenuItem={renderProjectPopoverMenuItem}
            handleProjectMenuSelection={handleProjectMenuSelection}
            onDelete={onDelete}
            handleClose={handleClose}
          />
          <TaskContentUpperMiddle
            testIdPrefix={testIdPrefix}
            markTaskCompletion={markTaskCompletion}
            isComplete={isComplete}
            taskIsEditing={taskIsEditing}
            isOnTeamProject={isOnTeamProject}
            task={task}
            taskId={taskId}
            renderProjectPopoverMenuItem={renderProjectPopoverMenuItem}
            handleProjectMenuSelection={handleProjectMenuSelection}
            handleDescriptionTextAreaEvent={handleDescriptionTextAreaEvent}
            handleDescriptionSubmit={handleDescriptionSubmit}
            currentDescriptionText={currentDescriptionText}
            isSelectedHomeTask={isSelectedHomeTask}
            handleContentClick={handleContentClick}
            isTaskEditProperty={isTaskEditProperty}
            beingEdited={beingEdited}
            handleBlur={handleBlur}
            setEditingHeight={setEditingHeight}
            index={index}
          />
          <TaskContentScroller isSelectedHomeTask={isSelectedHomeTask}>
            <TaskContentLowerMiddle
              taskIsEditing={taskIsEditing}
              isOnTeamProject={isOnTeamProject}
              shouldRenderAssignee={shouldRenderAssignee}
              task={task}
              taskId={taskId}
              isSelectedHomeTask={isSelectedHomeTask}
              isTaskEditProperty={isTaskEditProperty}
              beingEdited={beingEdited}
              checkForTyping={checkForTyping}
              handleNoteChange={handleNoteChange}
              noteText={noteText}
              isTaskModal={isSelectedHomeTask}
            />
            {displayTopAndBottom ? (
              <TaskContentBottom
                task={task}
                isSelectedHomeTask={isSelectedHomeTask}
                isTaskModal={isSelectedHomeTask}
                selectedProject={selectedProject}
              />
            ) : null}
          </TaskContentScroller>
        </TaskContentMain>
      </TaskContentWrapper>
    );
  }
}

const mapDispatchToProps = {
  triggerSetEditTaskId: setEditTaskId,
  handleSetSelectedHomeTask: setSelectedHomeTask,
  triggerFetchCommentsAndMetadata: fetchCommentsAndMetadata
};

export default connect(null, mapDispatchToProps)(TaskContent);
