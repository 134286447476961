import { useEffect, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchActivities } from 'actionCreators';
import {
  getOrderedAllActivitiesWithArchived,
  getIsActivitiesLoaded,
  getAllActivityRowInfo
} from 'selectors';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Activity } from 'models/activity';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';

const emptyArray = [];
const emptyObj = {};

export const useActivitiesFilter = ({
  isOff,
  shouldUseDraft,
  config,
  resultCountHash,
  isResultsLoading,
  field = FilterField.activity_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const dispatch = useAppDispatch();

  const activities = useAppSelector((state) =>
    isOff ? emptyArray : getOrderedAllActivitiesWithArchived(state)
  ) as Activity[];

  /** Array of selectable activity ids */
  const activityIdsArray = useMemo(
    () => (isOff ? emptyArray : activities.map((activity) => activity.id)),
    [activities, isOff]
  );

  const activityHash = useAppSelector((state) =>
    isOff ? emptyObj : getAllActivityRowInfo(state)
  ) as Record<number, Partial<Activity>>;

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: activityIdsArray,
    itemHash: activityHash,
    itemSearchableKeys: searchableKeys,
    isOff,
    resultCountHash,
    config,
    shouldUseDraft,
    labelKey
  });

  /* --------------------------------- loading -------------------------------- */

  const isLoaded = useAppSelector(getIsActivitiesLoaded) as boolean;

  // load activities if necessary
  useEffect(() => {
    if (!isOff && !isLoaded) {
      dispatch(fetchActivities({}));
    }
  }, [dispatch, isLoaded, isOff]);

  /* ------------------------------------ - ----------------------------------- */

  return {
    ...arrayFilterFieldValues,
    isLoading: !isLoaded || isResultsLoading,
    itemHash: activityHash,
    labelKey,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const searchableKeys: (keyof Activity)[] = ['title'];
const labelKey = 'title';
