import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import { isSameOrBetween } from 'appUtils/projectPlannerUtils';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import moment from 'moment';
import {
  setVisibleWeek,
  openTimesheetSettingsModal,
  openTimesheetIncompleteModal,
  navigateToMemberModal
} from 'actionCreators';
import { getIncompleteTimesheetDates, getMyUserId } from 'selectors';
import { DateNavigatorContainer } from './styles';
import TimesheetTitle from '../TimesheetTitle';
import TimesheetDateNav from './TimesheetDateNav';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenuTrigger';
import Popover from 'components/Popover';

const IncompleteTimesheetLabel = styled.div`
  color: ${theme.colors.colorCalendarRed};
  z-index: 100;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
  padding: 4px 8px;
`;

const ThreeDotContainer = styled.div`
  width: 24px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
`;
const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  min-width: 190px;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 7px;
  line-height: 34px;
  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    cursor: pointer;
  }
  font-weight: 400;
`;

class TimesheetDateNavigator extends React.Component {
  state = {
    isThreeDotMenuOpen: false
  };

  threeDotTarget = React.createRef();

  onNavLeft = () => {
    const { setVisibleWeek, visibleTimeStart, visibleTimeEnd } = this.props;
    setVisibleWeek({
      visibleTimeStart: visibleTimeStart.clone().subtract(1, 'week'),
      visibleTimeEnd: visibleTimeEnd.clone().subtract(1, 'week')
    });
  };

  onNavRight = () => {
    const { setVisibleWeek, visibleTimeStart, visibleTimeEnd } = this.props;
    setVisibleWeek({
      visibleTimeStart: visibleTimeStart.clone().add(1, 'week'),
      visibleTimeEnd: visibleTimeEnd.clone().add(1, 'week')
    });
  };

  onNavToday = () => {
    const { setVisibleWeek } = this.props;
    setVisibleWeek({
      visibleTimeStart: getMondayOfWeek(moment()),
      visibleTimeEnd: getMondayOfWeek(moment().add(1, 'week'))
    });
  };

  handleIncompleteClick = () => {
    this.props.openTimesheetIncompleteModal();
  };

  dateRenderer = () => {
    const { visibleTimeStart, visibleTimeEnd } = this.props;
    const startDate = moment(visibleTimeStart).format('MMM D');
    const endDate = moment(visibleTimeEnd)
      .clone()
      .add(-1, 'days')
      .format('MMM D');
    const year = moment(visibleTimeEnd).format('YYYY');
    return (
      <>
        {startDate} - {endDate} {year}
      </>
    );
  };

  getIsTodayRendered = () => {
    const { visibleTimeStart, visibleTimeEnd } = this.props;
    return isSameOrBetween(moment(), visibleTimeStart, visibleTimeEnd);
  };

  handleMenuOpen = () => {
    this.setState({
      isThreeDotMenuOpen: true
    });
  };

  handleMenuClose = () => {
    this.setState({
      isThreeDotMenuOpen: false
    });
  };

  goToWorkSchedule = () => {
    const { myId, navigateToMemberModal } = this.props;
    navigateToMemberModal({
      memberId: myId,
      memberViewType: 'schedule'
    });
    this.handleMenuClose();
  };

  render() {
    const { isThreeDotMenuOpen } = this.state;
    const { visibleTimeStart, incompleteTimesheetDatesByAccount, myId } =
      this.props;

    const incompleteTimesheetDates =
      incompleteTimesheetDatesByAccount[myId] ?? {};
    const incompleteTimesheetDatesLength = Object.keys(
      incompleteTimesheetDates
    ).length;

    return (
      <>
        <TimesheetTitle />
        <DateNavigatorContainer>
          <TimesheetDateNav
            scrollBack={this.onNavLeft}
            scrollForward={this.onNavRight}
            scrollToToday={this.onNavToday}
            dateRenderer={this.dateRenderer}
            isTodayOnScreen={this.getIsTodayRendered()}
            timeStart={moment(visibleTimeStart).format('MMM D')}
          />
          <ThreeDotContainer
            onClick={this.handleMenuOpen}
            ref={this.threeDotTarget}
          >
            <ProjectThreeDotMenu
              align="left"
              onCloseCallback={this.handleMenuClose}
              handleClick={this.handleMenuOpen}
              size="24"
            >
              <Popover
                isOpen={isThreeDotMenuOpen}
                target={this.threeDotTarget}
                closePopover={this.handleMenuClose}
              >
                <MenuListItem onClick={this.goToWorkSchedule}>
                  Work Schedule
                </MenuListItem>
              </Popover>
            </ProjectThreeDotMenu>
          </ThreeDotContainer>
          {incompleteTimesheetDatesLength > 0 && (
            <IncompleteTimesheetLabel
              data-for={'app-tooltip'}
              data-html
              data-effect="solid"
              data-tip={` ${
                incompleteTimesheetDatesLength +
                (incompleteTimesheetDatesLength === 1
                  ? ' Timesheet is incomplete'
                  : ' Timesheets are incomplete')
              } <br/> in the past 30 days`}
              onClick={this.handleIncompleteClick}
            >
              {`${incompleteTimesheetDatesLength} Incomplete`}
            </IncompleteTimesheetLabel>
          )}
        </DateNavigatorContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myId: getMyUserId(state),
  visibleTimeStart: state.projectPlannerModal.visibleTimeStart,
  visibleTimeEnd: state.projectPlannerModal.visibleTimeEnd,
  incompleteTimesheetDatesByAccount: getIncompleteTimesheetDates(state)
});

const mapDispatchToProps = {
  setVisibleWeek,
  openTimesheetSettingsModal,
  openTimesheetIncompleteModal,
  navigateToMemberModal
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetDateNavigator);
