export const calculateCheckInTotalHours = (
  checkin = {},
  checkinsHours = {},
  accountId
) => {
  const checkinProject = checkinsHours[accountId]?.project_totals?.find(
    (project) => project.project_id === parseInt(checkin.project_id)
  );

  let totalHours;
  if (checkin.activity_id) {
    totalHours = checkinProject?.phases
      ?.find((phase) => phase.phase_id === checkin.phase_id)
      ?.activities?.find(
        (activity) => activity.activity_id === checkin.activity_id
      )?.total_hours;
  } else if (checkin.phase_id) {
    totalHours = checkinProject?.phases?.find(
      (phase) => phase.phase_id === checkin.phase_id
    )?.total_hours;
  } else {
    totalHours = checkinProject?.total_hours;
  }
  return totalHours || 0;
};
