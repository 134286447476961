import { useState } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import cn from 'classnames';
import { getSelectedProject } from 'selectors';
import { updatePhase } from 'actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisText, { TooltipBehavior } from 'components/EllipsisText';

const MilestoneInput = ({ phase }) => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(getSelectedProject);
  // is_complete needed to grey out milestone when implementing that feature
  const { name } = phase || {};
  const [phaseName, setPhaseName] = useState(name);

  const handleBlur = () => {
    dispatch(
      updatePhase({
        id: phase.id,
        projectId: selectedProject.id,
        name: phaseName
      })
    );
  };

  const tooltip = `${
    phase.is_like_default ? 'Project Schedule' : name
  } <br /> <br />This phase is part of the project budget and the title must be edited there.`;

  const checkForEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  return phase.is_budget ? (
    <EllipsisText
      maxWidth={245}
      style={{ fontSize: 13, color: theme.colors.colorMediumGray9 }}
      tooltip={tooltip}
      tooltipBehavior={TooltipBehavior.AlwaysEnabled}
    >
      {phase.is_like_default ? 'Project Schedule' : name}
    </EllipsisText>
  ) : (
    <MilestoneInputContainer
      className={cn('milestone-input milestone-title-input', {
        'is-complete': false
      })}
      type="text"
      onKeyDown={checkForEnterPress}
      onChange={(e) => setPhaseName(e.target.value)}
      onBlur={handleBlur}
      value={phaseName}
    />
  );
};

const MilestoneInputContainer = styled.input`
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 245px;
  color: ${theme.colors.colorMediumGray9};
`;

export default MilestoneInput;
