import styled from 'styled-components';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';
export const leftValue = '30px';
export const StyledFilterListContainer = styled.div`
  position: relative;
  ${(props) => props.$width && `width: ${props.$width}px;`}
  .variable-size-list {
    padding-top: ${({ headerHeight }) => `${headerHeight}px`};
    padding-bottom: 35px;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  .filter-list-item-container {
    width: 100%;
  }
`;
export const StyledHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.colorSemiDarkGray1};
  position: relative;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  padding-left: 10px;
  ${({ isOpen }) =>
    !isOpen &&
    `
    border-bottom-color: ${theme.colors.colorPaleGray7};
  `}
  &:hover {
    background: white;
  }
`;

export const StyledSummary = styled.div`
  font-size: 12px;
  color: ${(props) =>
    props.isActive
      ? theme.colors.colorCalendarBlue
      : theme.colors.colorLightGray15};

  position: absolute;
  bottom: 4px;
  padding-left: 7px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledClear = styled.div`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};
`;
export const StyledKaratRight = styled(KaratRight)`
  transform-origin: center center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : '')};
  path {
    fill: ${({ color }) => color || theme.colors.colorSemiDarkGray1};
  }
  transition: transform 0.275s ease-in-out;
  height: ${({ height }) => height || 13}px;
  width: 13px;
  margin-right: 6px;
`;

export const StyledSearchField = styled.input`
  border: none;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorPaleGray7};
  border-radius: 3px;
  position: relative;
  font-size: 14px;
  margin: 0;
  padding: 3px 8px;
  caret-color: ${theme.colors.colorRoyalBlue};
  width: 100%;
  &:focus {
    color: ${theme.colors.colorRoyalBlue};
  }
  ${({ isActive }) =>
    isActive &&
    `
    border: 1px solid ${theme.colors.colorRoyalBlue};
  `}
  &::placeholder {
    color: ${theme.colors.colorMediumGray3};
  }
`;

export const StyledFilterTab = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  &.active {
    font-weight: bold;
  }
`;

export const StyledFilterTabs = styled.div`
  display: flex;
  align-items: center;
  height: 28px;

  font-size: 12px;
  justify-content: space-around;

  &.workload-planner {
    background: ${theme.colors.colorPaleGray7};
    height: 29px; // uneven padding otherwise for some reason
    border-radius: 8px;
    margin: 8px;
    padding: 1px;

    ${StyledFilterTab} {
      height: 100%;
      padding: 6px;
      &.active {
        background: white;
        border-radius: 8px;
        border: 1px solid ${theme.colors.colorLightGray6};
      }
    }
  }

  &.virtual-filter {
    ${StyledFilterTab} {
      margin-right: 20px;
      &.active {
        border-bottom: 2px solid ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const StyledFilterMenu = styled.div`
  display: flex;
  align-items: flex-start !important;
  justify-content: space-around;
  flex-direction: column;

  &.workload-planner {
    padding: 8px;
  }
`;

export const StyledFilterMenuItem = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding: 16px 16px;
  font-size: 13px;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
  &.active {
    font-weight: bold;
  }

  ${({ isEmpty }) =>
    isEmpty &&
    `
    color: ${theme.colors.colorMediumGray5};
    &:hover {
      background: none;
    }
  `}
`;

export const FilterTrigger = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 15px;
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(80%);
    }
    `}
`;

export const FilterText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  margin-left: 4px;
  font-weight: 600;
`;

export const CollapseAllContainer = styled.div`
  position: absolute;
  right: 13px;
  top: -11px;
  z-index: 100;
  cursor: pointer;
  ${(props) => props.isCollapsed && 'transform: rotate(-90deg);'}
  transform-origin: center 17px;
  transition: 0.275s;

  path {
    fill: ${theme.colors.colorLightGray15};
  }
  .rct-header-root & {
    top: -28px;
    transform-origin: center 14px;
  }
`;

export const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  color: ${theme.colors.colorPureBlack};
`;
