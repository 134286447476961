import { ZOOM_LEVELS } from 'appConstants/workload';
import { dayInMilliseconds } from 'TimelinesModule/components/constants';
import { TimelineZoomLevelValues } from 'TimelinesModule/types/timeline';

export const getBufferedTimeRange = ({
  startTime,
  endTime,
  zoom
}: {
  startTime: EpochTimeStamp;
  endTime: EpochTimeStamp;
  zoom: TimelineZoomLevelValues;
}): {
  startTime: EpochTimeStamp;
  endTime: EpochTimeStamp;
} => {
  if (zoom === ZOOM_LEVELS.QUARTER || zoom === ZOOM_LEVELS.YEAR) {
    return {
      startTime,
      endTime
    };
  }

  const twoWeeksInMilliseconds = dayInMilliseconds * 14;

  return {
    startTime: startTime - twoWeeksInMilliseconds,
    endTime: endTime + twoWeeksInMilliseconds
  };
};
