import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { MemberListItem } from '../types';

export const MembersTimesheetSettingsTableReminderSettingCell = ({
  row
}: {
  row: {
    original: MemberListItem;
  };
}) => {
  const { teamMember } = row.original;

  return <></>;
};
