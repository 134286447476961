import { MembersCapacityTableMemberTypeSectionHeaderCell as MemberTypeSectionHeaderCell } from './MemberTypeSectionHeaderCell';
import { StandardCapacityTableHeaderCell as HeaderCell } from 'SettingsModule/components/StandardSettings/Capacity/StandardCapacityTable/Cells/HeaderCell';
import { MembersCapacityTableMemberCell as MemberCell } from './MemberCell';
import { MembersCapacityTablePolicyPickerCell as PolicyPickerCell } from './PolicyPickerCell';
import { MembersCapacityTableCapacityCells as CapacityCells } from './PolicyCapacityCell';
import { MembersCapacityTableTotalCell as TotalCell } from './TotalCell';
import { StandardPTOTableArchivedSectionHeaderCell as ArchivedSectionHeaderCell } from 'SettingsModule/components/StandardSettings/PTO/StandardPTOTable/Cells/ArchivedSectionHeaderCell';

export const MembersCapacityTableCells = {
  MemberTypeSectionHeaderCell,
  HeaderCell,
  MemberCell,
  PolicyPickerCell,
  CapacityCells,
  TotalCell,
  ArchivedSectionHeaderCell
};
