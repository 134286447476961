import { loadState } from 'service/localStorage';

const denyHostList = [
  'localhost',
  'staging-api.mosaicapp.com',
  'pilot-api.mosaicapp.com',
  'prod-api.mosaicapp.com'
];

const isRealmAvailable = (baseURL: string) => {
  const { hostname } = new URL(baseURL);
  return !denyHostList.includes(hostname);
};

/**
 * it builds realm baseURL from baseURL and realmId
 * by replacing the realmId with the host of the baseURL
 * expected example output: [protocol]//[realmid].api.[region].[env].mosaicapp.com
 */
export const buildRealmURL = ({
  baseURL,
  realmId
}: {
  baseURL: string;
  realmId: string;
}) => {
  if (!isRealmAvailable(baseURL)) return baseURL;

  const { protocol, host, pathname } = new URL(baseURL);

  // should replace [realmid] in the host with passed realmId
  const hostToUse = host
    .split('.')
    .map((val, i) => (i === 0 ? realmId : val))
    .join('.');
  return protocol + '//' + hostToUse + pathname;
};

export const getStoredRealmId = (): string | undefined => {
  return loadState()?.auth?.realm?.id;
};

// For backend rails
const mosaicBackEndDefaultRealmByEnvironment = {
  demo: 'demo',
  staging: 'staging',
  party: 'party',
  pilot: 'pilot',
  prod: 'prod'
};

export const getIsDefaultRealm = (realmId: string): boolean => {
  const env = process.env.UI_ENV;
  if (!env) return false;
  return realmId === mosaicBackEndDefaultRealmByEnvironment[env];
};
