import React from 'react';
import { BasicMemberCircle, MemberName } from '..';

const MemberCircleNameRow = ({ name, initials, isLoggedInUser, onClick }) => {
  return (
    <li className="member-row" onClick={onClick}>
      <BasicMemberCircle initials={initials} isLoggedInUser={isLoggedInUser} />
      <MemberName name={name} />
    </li>
  );
};

export default MemberCircleNameRow;
