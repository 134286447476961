import { useRef } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getIsSplitLoaded, getSelectedBoardName } from 'selectors';
import styled from 'styled-components';
import {
  useEmailValidator,
  portfolioValidator,
  portfolioOptionsGetter,
  useProjectTitleValidator,
  useProjectNumberValidator,
  usePhaseValidator,
  usePhaseOptionsGetter,
  useActivityValidator,
  useActivityOptionsGetter,
  useMemberValidator,
  useCsvImportData,
  dateValidator,
  hoursValidator,
  hoursPerDayValidator,
  totalHoursValidator,
  startDateValidator,
  endDateValidator,
  makeRowsValidator,
  makeOptionsGetter,
  phaseNumberValidator,
  accessLevelValidator,
  accessLevelOptionsGetter,
  projectBudgetStatusValidator,
  projectBudgetStatusOptionsGetter,
  projectClientValidator,
  projectClientOptionsGetter,
  projectPriorityValidator,
  projectPriorityOptionsGetter,
  phaseBudgetStatusValidator,
  phaseBudgetStatusOptionsGetter,
  phaseContractTypeValidator,
  phaseContractTypeOptionsGetter,
  phaseFeeTargetValidator,
  phaseFeeTargetOptionsGetter,
  activityFeeTargetValidator,
  activityFeeTargetOptionsGetter,
  firstNameValidator,
  lastNameValidator,
  regionValidator,
  regionOptionsGetter,
  roleValidator,
  roleOptionsGetter,
  officeValidator,
  officeOptionsGetter,
  disciplineValidator,
  disciplineOptionsGetter,
  departmentValidator,
  departmentOptionsGetter,
  skillsValidator,
  ptoHoursValidator,
  ptoHoursOptionsGetter,
  ptoDescriptionValidator,
  ptoDescriptionOptionsGetter,
  currencyValidator,
  currencyOptionsGetter,
  rateAmountValidator,
  rateAmountOptionsGetter,
  rateDescriptionValidator,
  rateDescriptionOptionsGetter,
  projectOfficeValidator,
  projectOfficeOptionsGetter,
  projectDisciplineValidator,
  projectDisciplineOptionsGetter,
  projectRegionValidator,
  projectRegionOptionsGetter,
  memberImportEmailValidator,
  activityStartDateValidator,
  activityEndDateValidator,
  taskStatusValidator,
  taskStatusOptionsGetter,
  taskPriorityValidator,
  taskPriorityOptionsGetter,
  taskScopeDescriptionValidator
} from 'CsvImportsModule/validation';
import uuid from 'uuid';
import CircleSpinner from 'components/Spinners/CircleSpinner';
import {
  usableImportTokens,
  CsvImportEntity,
  CsvImportEntityToLabel,
  CsvImportFieldName,
  BILLABLE_PHASE_CONTRACT_TYPES,
  BILLABLE_PHASE_CONTRACT_TYPES_HASH,
  NON_BILLABLE_PHASE_CONTRACT_TYPES,
  NON_BILLABLE_PHASE_CONTRACT_TYPES_HASH,
  MEMBER_ACCESS_LEVELS,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { configuratorsByEntity } from 'CsvImportsModule/configs';

interface ImportButtonProps {
  isCsvImportDataReady: boolean;
  entityType: CsvImportEntity;
  buttonLabel?: string;
  importSessionId?: string;
  successfulUploadCallbackFn?: () => void;
  shouldUploadPortfolioNames?: boolean;
  disabled?: boolean;
}
interface CompletionHandlerArgs {
  originalFileUrl: string;
  reachedTransformStep: boolean;
  successfullySubmittedData: boolean;
  numRecordsSubmitted: number;
  skippedRowNumbers: Array<any>;
}

const ImportButton = ({
  isCsvImportDataReady,
  entityType,
  buttonLabel,
  importSessionId,
  successfulUploadCallbackFn,
  shouldUploadPortfolioNames = false,
  disabled
}: ImportButtonProps) => {
  // eslint-disable-next-line dot-notation
  const FortyTwoLayers = window['FortyTwoLayers'];
  const isFeatureFlagsLoaded = useAppSelector(getIsSplitLoaded);
  const selectedPortfolioName = useAppSelector(getSelectedBoardName);

  const {
    existingProjectIdToPortfolio,
    existingPortfoliosHashByName,
    existingPortfolioNames,
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingProjectBudgetStatusOptions,
    existingProjectBudgetStatusOptionsByBudgetStatus,
    existingProjectPrioritiesByTitle,
    existingProjectClients,
    existingProjectsById,
    existingPhases,
    existingPhaseBudgetStatusOptions,
    existingPhaseBudgetStatusOptionsByBudgetStatus,
    existingPhasesGroupedByName,
    existingPhasesGroupedByProjectId,
    existingActivities,
    existingActivitiesById,
    existingEmails,
    existingPtoPoliciesGroupedByHours,
    existingPtoPoliciesByDescription,
    existingOfficesByName,
    existingRegionsByName,
    existingRolesByName,
    existingDisciplinesByName,
    existingDepartmentsByName,
    existingRates,
    existingRatesGroupedByRateAmount,
    existingRatesByRateDescription,
    existingTaskPrioritiesByTitle,
    teamId,
    myId
  } = useCsvImportData();
  // these refs are used to present options that user has associated with new projects / phases
  // eg. for a new project title and number combination -> if user has entered a phase name on one of the rows,
  // we want to present the phase name as an option for the user to select for other rows with same combination
  // temporary refs gets resetted after validating the last row
  const newPhaseNames = useRef({});
  const newActivityTitles = useRef({});
  const temporaryProjectBudgetStatusOption = useRef<
    Record<string, Record<string, string>>
  >({});
  const temporaryProjectPriorityOption = useRef<
    Record<string, Record<string, string>>
  >({});
  const projectClientOption = useRef<Record<string, Record<string, string>>>(
    {}
  );
  const temporaryProjectClientOption = useRef<
    Record<string, Record<string, string>>
  >({});
  const projectDisciplineOption = useRef<
    Record<string, Record<string, string>>
  >({});
  const temporaryProjectDisciplineOption = useRef<
    Record<string, Record<string, string>>
  >({});
  const projectRegionOption = useRef<Record<string, Record<string, string>>>(
    {}
  );
  const temporaryProjectRegionOption = useRef<
    Record<string, Record<string, string>>
  >({});
  const projectOfficeOption = useRef<Record<string, Record<string, string>>>(
    {}
  );
  const temporaryProjectOfficeOption = useRef<
    Record<string, Record<string, string>>
  >({});
  // { projectTitle: { projectNumber: { phaseName: phaseBudgetStatus } } }
  const temporaryPhaseBudgetStatusOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>({});
  // { projectTitle: { projectNumber: { phaseName: phaseContractType } } }
  const temporaryPhaseContractTypeOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>({});
  // { projectTitle: { projectNumber: { phaseName: fee } } }
  const phaseFeeOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>({});
  const temporaryPhaseFeeOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>({});
  // { projectTitle: { projectNumber: { phaseName: target } } }
  const phaseTargetOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>({});
  const temporaryPhaseTargetOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>({});

  // { projectTitle: { projectNumber: { phaseName: { activityTitle: fee } } } }
  const activityFeeOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>({});
  const temporaryActivityFeeOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>({});
  // { projectTitle: { projectNumber: { phaseName: { activityTitle: target } } } }
  const activityTargetOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>({});
  const temporaryActivityTargetOption = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: {
        [phaseName: string]: { [activityTitle: string]: string };
      };
    };
  }>({});
  const newRegions = useRef<Record<string, boolean>>({});
  const newRoles = useRef<Record<string, boolean>>({});
  const newOffices = useRef<Record<string, boolean>>({});
  const newDisciplines = useRef<Record<string, boolean>>({});
  const newDepartments = useRef<Record<string, boolean>>({});
  const newPtoHours = useRef<Record<string, boolean>>({});
  const newPtoDescriptions = useRef<Record<string, boolean>>({});
  const newPtoDescriptionHoursHashes = useRef<Record<string, string>>({});
  const newRateAmounts = useRef<Record<string, boolean>>({});
  const newRateDescriptions = useRef<Record<string, boolean>>({});
  const newRateDescriptionAmountHashes = useRef<Record<string, string>>({});
  const newTaskStatuses = useRef<{
    [projectTitle: string]: {
      [projectNumber: string]: { [taskStatus: string]: string };
    };
  }>({});
  const newOfficeNames = useRef<Record<string, boolean>>({});

  // Required: must match existing
  const emailValidator = useEmailValidator({ existingEmails, entityType });
  // Required: non-empty
  const projectValidator = useProjectTitleValidator({ entityType });
  // Required: non-empty
  const projectNumberValidator = useProjectNumberValidator({ entityType });
  // Required to match if project matching title and number exists. Otherwise non-empty valid
  const phaseValidator = usePhaseValidator({
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingPhasesGroupedByName,
    existingPhasesGroupedByProjectId,
    newPhaseNames,
    entityType
  });
  // Required: non-empty
  const activityValidator = useActivityValidator({
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingPhasesGroupedByName,
    existingActivitiesById,
    newActivityTitles,
    entityType
  });
  // Required: non-empty
  const memberValidator = useMemberValidator({ entityType });
  // If no existing project title + number combination, will show options of new phase names
  // related to the combination. Otherwise, will show options of existing phases
  const phaseOptionsGetter = usePhaseOptionsGetter({
    existingPhases,
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    newPhaseNames,
    entityType
  });
  // If no existing project title + number & phase combination, will show options of new phase names
  // related to the combination + existing activities.
  // Otherwise, if matching phase has restrictions, will show those as options and if not, will show existing activities
  const activityOptionsGetter = useActivityOptionsGetter({
    existingActivities,
    existingProjectsHashByTitle,
    existingProjectsHashByNumber,
    existingPhasesGroupedByName,
    existingActivitiesById,
    newActivityTitles,
    entityType
  });

  const handleFortyTwoLayersClick = () => {
    const sessionId = importSessionId ?? uuid();
    const importInitiatedAt = new Date().toISOString();

    FortyTwoLayers.configure({
      completionHandler: async ({
        originalFileUrl,
        reachedTransformStep,
        successfullySubmittedData,
        numRecordsSubmitted,
        skippedRowNumbers
      }: CompletionHandlerArgs) => {
        if (successfullySubmittedData) successfulUploadCallbackFn?.();
      },
      // Include all validators/optionGetters here for every entity type
      ...configuratorsByEntity[entityType]({
        // portfolio validator
        requiredPortfolioValidator: makeRowsValidator({
          entityType,
          rowValidator: portfolioValidator,
          additionalParams: {
            existingPortfoliosHashByName,
            existingProjectIdToPortfolio,
            existingProjectsHashByNumber,
            existingProjectsHashByTitle,
            isRequired: true
          }
        }),
        nullablePortfolioValidator: makeRowsValidator({
          entityType,
          rowValidator: portfolioValidator,
          additionalParams: {
            existingPortfoliosHashByName,
            existingProjectIdToPortfolio,
            existingProjectsHashByNumber,
            existingProjectsHashByTitle,
            isRequired: false
          }
        }),
        portfolioOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: portfolioOptionsGetter,
          additionalParams: {
            existingProjectsHashByTitle,
            existingProjectsHashByNumber,
            existingProjectIdToPortfolio,
            existingPortfolioNames
          }
        }),
        startDateValidator: makeRowsValidator({
          entityType,
          rowValidator: startDateValidator,
          additionalParams: {
            isRequired: entityType === CsvImportEntity.WorkPlan
          }
        }),
        endDateValidator: makeRowsValidator({
          entityType,
          rowValidator: endDateValidator,
          additionalParams: {
            isRequired: entityType === CsvImportEntity.WorkPlan
          }
        }),
        projectValidator,
        projectNumberValidator,
        projectBudgetStatusValidator: makeRowsValidator({
          entityType,
          rowValidator: projectBudgetStatusValidator,
          additionalParams: {
            existingProjectBudgetStatusOptionsByBudgetStatus,
            temporaryProjectBudgetStatusOption
          }
        }),
        projectBudgetStatusOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: projectBudgetStatusOptionsGetter,
          additionalParams: {
            existingProjectBudgetStatusOptions
          }
        }),
        projectClientValidator: makeRowsValidator({
          entityType,
          rowValidator: projectClientValidator,
          additionalParams: {
            projectClientOption,
            temporaryProjectClientOption
          }
        }),
        projectClientOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: projectClientOptionsGetter,
          additionalParams: {
            existingProjectClients,
            projectClientOption
          }
        }),
        projectPriorityValidator: makeRowsValidator({
          entityType,
          rowValidator: projectPriorityValidator,
          additionalParams: {
            existingProjectPrioritiesByTitle,
            temporaryProjectPriorityOption
          }
        }),
        projectPriorityOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: projectPriorityOptionsGetter,
          additionalParams: {
            existingProjectPrioritiesByTitle
          }
        }),
        dateValidator: makeRowsValidator({
          entityType,
          rowValidator: dateValidator
        }),
        hoursValidator: makeRowsValidator({
          entityType,
          rowValidator: hoursValidator
        }),
        // currently hoursPerDay and totalHours are used for Work plan import
        // and only one field must be entered
        hoursPerDayValidator: makeRowsValidator({
          entityType,
          rowValidator: hoursPerDayValidator
        }),
        totalHoursValidator: makeRowsValidator({
          entityType,
          rowValidator: totalHoursValidator
        }),
        phaseValidator,
        phaseNumberValidator: makeRowsValidator({
          entityType,
          rowValidator: phaseNumberValidator
        }),
        phaseStartDateValidator: makeRowsValidator({
          entityType,
          rowValidator: startDateValidator,
          additionalParams: {
            isRequired: true,
            startDateFieldName: CsvImportFieldName.EnteredPhaseStartDate,
            endDateFieldName: CsvImportFieldName.EnteredPhaseEndDate
          }
        }),
        phaseEndDateValidator: makeRowsValidator({
          entityType,
          rowValidator: endDateValidator,
          additionalParams: {
            isRequired: true,
            startDateFieldName: CsvImportFieldName.EnteredPhaseStartDate,
            endDateFieldName: CsvImportFieldName.EnteredPhaseEndDate
          }
        }),
        phaseBudgetStatusValidator: makeRowsValidator({
          entityType,
          rowValidator: phaseBudgetStatusValidator,
          additionalParams: {
            existingPhaseBudgetStatusOptionsByBudgetStatus,
            temporaryPhaseBudgetStatusOption
          }
        }),
        phaseBudgetStatusOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: phaseBudgetStatusOptionsGetter,
          additionalParams: {
            existingPhaseBudgetStatusOptions
          }
        }),
        phaseContractTypeValidator: makeRowsValidator({
          entityType,
          rowValidator: phaseContractTypeValidator,
          additionalParams: {
            billablePhaseContractTypesByType:
              BILLABLE_PHASE_CONTRACT_TYPES_HASH,
            nonBillablePhaseContractTypesByType:
              NON_BILLABLE_PHASE_CONTRACT_TYPES_HASH,
            temporaryPhaseContractTypeOption
          }
        }),
        phaseContractTypeOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: phaseContractTypeOptionsGetter,
          additionalParams: {
            billablePhaseContractTypes: BILLABLE_PHASE_CONTRACT_TYPES,
            nonBillablePhaseContractTypes: NON_BILLABLE_PHASE_CONTRACT_TYPES
          }
        }),
        phaseFeeValidator: makeRowsValidator({
          entityType,
          rowValidator: phaseFeeTargetValidator,
          additionalParams: {
            phaseFeeTargetOption: phaseFeeOption,
            temporaryPhaseFeeTargetOption: temporaryPhaseFeeOption,
            enteredFieldName: CsvImportFieldName.EnteredPhaseFee,
            notMatchingFeeTargetErrorMessage:
              ERROR_MESSAGES.phaseFeeDoesNotMatch
          }
        }),
        phaseFeeOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: phaseFeeTargetOptionsGetter,
          additionalParams: {
            phaseFeeTargetOption: phaseFeeOption
          }
        }),
        phaseTargetValidator: makeRowsValidator({
          entityType,
          rowValidator: phaseFeeTargetValidator,
          additionalParams: {
            phaseFeeTargetOption: phaseTargetOption,
            temporaryPhaseFeeTargetOption: temporaryPhaseTargetOption,
            enteredFieldName: CsvImportFieldName.EnteredPhaseTarget,
            notMatchingFeeTargetErrorMessage:
              ERROR_MESSAGES.phaseTargetDoesNotMatch
          }
        }),
        phaseTargetOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: phaseFeeTargetOptionsGetter,
          additionalParams: {
            phaseFeeTargetOption: phaseTargetOption
          }
        }),
        activityStartDateValidator: makeRowsValidator({
          entityType,
          rowValidator: activityStartDateValidator
        }),
        activityEndDateValidator: makeRowsValidator({
          entityType,
          rowValidator: activityEndDateValidator
        }),
        activityFeeValidator: makeRowsValidator({
          entityType,
          rowValidator: activityFeeTargetValidator,
          additionalParams: {
            activityFeeTargetOption: activityFeeOption,
            temporaryActivityFeeTargetOption: temporaryActivityFeeOption,
            enteredFieldName: CsvImportFieldName.EnteredActivityFee,
            notMatchingFeeTargetErrorMessage:
              ERROR_MESSAGES.activityFeeDoesNotMatch
          }
        }),
        activityFeeOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: activityFeeTargetOptionsGetter,
          additionalParams: {
            activityFeeTargetOption: activityFeeOption
          }
        }),
        activityTargetValidator: makeRowsValidator({
          entityType,
          rowValidator: activityFeeTargetValidator,
          additionalParams: {
            activityFeeTargetOption: activityTargetOption,
            temporaryActivityFeeTargetOption: temporaryActivityTargetOption,
            enteredFieldName: CsvImportFieldName.EnteredActivityTarget,
            notMatchingFeeTargetErrorMessage:
              ERROR_MESSAGES.activityTargetDoesNotMatch
          }
        }),
        activityTargetOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: activityFeeTargetOptionsGetter,
          additionalParams: {
            activityFeeTargetOption: activityTargetOption
          }
        }),
        accessLevelValidator: makeRowsValidator({
          entityType,
          rowValidator: accessLevelValidator
        }),
        accessLevelOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: accessLevelOptionsGetter,
          additionalParams: {
            memberAccessLevels: MEMBER_ACCESS_LEVELS
          }
        }),
        firstNameValidator: makeRowsValidator({
          entityType,
          rowValidator: firstNameValidator
        }),
        lastNameValidator: makeRowsValidator({
          entityType,
          rowValidator: lastNameValidator
        }),
        regionValidator: makeRowsValidator({
          entityType,
          rowValidator: regionValidator,
          additionalParams: {
            existingRegionsByName,
            newRegions
          }
        }),
        regionOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: regionOptionsGetter,
          additionalParams: {
            existingRegionsByName,
            newRegions
          }
        }),
        roleValidator: makeRowsValidator({
          entityType,
          rowValidator: roleValidator,
          additionalParams: {
            existingRolesByName,
            newRoles
          }
        }),
        roleOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: roleOptionsGetter,
          additionalParams: {
            existingRolesByName,
            newRoles
          }
        }),
        officeValidator: makeRowsValidator({
          entityType,
          rowValidator: officeValidator,
          additionalParams: {
            existingOfficesByName,
            newOfficeNames
          }
        }),
        officeOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: officeOptionsGetter,
          additionalParams: {
            existingOfficesByName,
            newOfficeNames
          }
        }),
        disciplineValidator: makeRowsValidator({
          entityType,
          rowValidator: disciplineValidator,
          additionalParams: {
            existingDisciplinesByName,
            newDisciplines
          }
        }),
        disciplineOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: disciplineOptionsGetter,
          additionalParams: {
            existingDisciplinesByName,
            newDisciplines
          }
        }),
        departmentValidator: makeRowsValidator({
          entityType,
          rowValidator: departmentValidator,
          additionalParams: {
            existingDepartmentsByName,
            newDepartments
          }
        }),
        departmentOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: departmentOptionsGetter,
          additionalParams: {
            existingDepartmentsByName,
            newDepartments
          }
        }),
        skillsValidator: makeRowsValidator({
          entityType,
          rowValidator: skillsValidator
        }),
        ptoHoursValidator: makeRowsValidator({
          entityType,
          rowValidator: ptoHoursValidator,
          additionalParams: {
            existingPtoPoliciesGroupedByHours,
            existingPtoPoliciesByDescription,
            newPtoHours,
            newPtoDescriptionHoursHashes
          }
        }),
        ptoHoursOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: ptoHoursOptionsGetter,
          additionalParams: {
            existingPtoPoliciesGroupedByHours,
            existingPtoPoliciesByDescription,
            newPtoHours,
            newPtoDescriptionHoursHashes
          }
        }),
        ptoDescriptionValidator: makeRowsValidator({
          entityType,
          rowValidator: ptoDescriptionValidator,
          additionalParams: {
            existingPtoPoliciesByDescription,
            newPtoDescriptions,
            newPtoDescriptionHoursHashes
          }
        }),
        ptoDescriptionOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: ptoDescriptionOptionsGetter,
          additionalParams: {
            existingPtoPoliciesGroupedByHours,
            existingPtoPoliciesByDescription,
            newPtoDescriptions,
            newPtoDescriptionHoursHashes
          }
        }),
        currencyValidator: makeRowsValidator({
          entityType,
          rowValidator: currencyValidator
        }),
        currencyOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: currencyOptionsGetter
        }),
        rateAmountValidator: makeRowsValidator({
          entityType,
          rowValidator: rateAmountValidator,
          additionalParams: {
            existingRatesGroupedByRateAmount,
            existingRatesByRateDescription,
            newRateAmounts,
            newRateDescriptionAmountHashes
          }
        }),
        rateAmountOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: rateAmountOptionsGetter,
          additionalParams: {
            existingRatesGroupedByRateAmount,
            existingRatesByRateDescription,
            newRateAmounts,
            newRateDescriptionAmountHashes
          }
        }),
        rateDescriptionValidator: makeRowsValidator({
          entityType,
          rowValidator: rateDescriptionValidator,
          additionalParams: {
            existingRatesByRateDescription,
            newRateDescriptions,
            newRateDescriptionAmountHashes
          }
        }),
        rateDescriptionOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: rateDescriptionOptionsGetter,
          additionalParams: {
            existingRatesGroupedByRateAmount,
            existingRatesByRateDescription,
            newRateDescriptions,
            newRateDescriptionAmountHashes
          }
        }),
        projectOfficeValidator: makeRowsValidator({
          entityType,
          rowValidator: projectOfficeValidator,
          additionalParams: {
            projectOfficeOption,
            temporaryProjectOfficeOption
          }
        }),
        projectOfficeOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: projectOfficeOptionsGetter,
          additionalParams: {
            existingOfficesByName,
            projectOfficeOption
          }
        }),
        projectRegionValidator: makeRowsValidator({
          entityType,
          rowValidator: projectRegionValidator,
          additionalParams: {
            projectRegionOption,
            temporaryProjectRegionOption
          }
        }),
        projectRegionOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: projectRegionOptionsGetter,
          additionalParams: {
            existingRegionsByName,
            projectRegionOption
          }
        }),
        projectDisciplineValidator: makeRowsValidator({
          entityType,
          rowValidator: projectDisciplineValidator,
          additionalParams: {
            projectDisciplineOption,
            temporaryProjectDisciplineOption
          }
        }),
        projectDisciplineOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: projectDisciplineOptionsGetter,
          additionalParams: {
            existingDisciplinesByName,
            projectDisciplineOption
          }
        }),
        memberImportEmailValidator: makeRowsValidator({
          entityType,
          rowValidator: memberImportEmailValidator,
          additionalParams: {
            existingEmails
          }
        }),
        taskStatusValidator: makeRowsValidator({
          entityType,
          rowValidator: taskStatusValidator,
          additionalParams: {
            newTaskStatuses
          }
        }),
        taskStatusOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: taskStatusOptionsGetter,
          additionalParams: {
            existingProjectsById,
            existingProjectsHashByTitle,
            existingProjectsHashByNumber,
            newTaskStatuses
          }
        }),
        taskPriorityValidator: makeRowsValidator({
          entityType,
          rowValidator: taskPriorityValidator,
          additionalParams: {
            existingTaskPrioritiesByTitle
          }
        }),
        taskPriorityOptionsGetter: makeOptionsGetter({
          entityType,
          optionsGetter: taskPriorityOptionsGetter,
          additionalParams: {
            existingTaskPrioritiesByTitle
          }
        }),
        taskScopeDescriptionValidator: makeRowsValidator({
          entityType,
          rowValidator: taskScopeDescriptionValidator
        }),
        phaseOptionsGetter,
        activityValidator,
        activityOptionsGetter,
        memberValidator,
        emailValidator,
        teamId,
        sessionId,
        importInitiatedAt,
        myId,
        selectedPortfolioName,
        shouldUploadPortfolioNames
      })
    });
    FortyTwoLayers.handleClick(usableImportTokens[entityType]);
  };

  return (
    <StyledImportButton
      className="ftl-button"
      disabled={
        disabled ||
        !(
          FortyTwoLayers &&
          isCsvImportDataReady &&
          isFeatureFlagsLoaded &&
          configuratorsByEntity[entityType]
        )
      }
      height={
        entityType === CsvImportEntity.ProjectPhaseActivityPhase ? '52px' : null
      }
      onClick={
        disabled
          ? undefined
          : () => {
              // reset our 'state'
              newPhaseNames.current = {};
              newActivityTitles.current = {};
              temporaryProjectBudgetStatusOption.current = {};
              projectClientOption.current = {};
              temporaryProjectClientOption.current = {};
              temporaryProjectPriorityOption.current = {};
              temporaryPhaseBudgetStatusOption.current = {};
              temporaryPhaseContractTypeOption.current = {};
              phaseFeeOption.current = {};
              temporaryPhaseFeeOption.current = {};
              phaseTargetOption.current = {};
              temporaryPhaseTargetOption.current = {};
              activityFeeOption.current = {};
              temporaryActivityFeeOption.current = {};
              activityTargetOption.current = {};
              temporaryActivityTargetOption.current = {};
              newRegions.current = {};
              newRoles.current = {};
              newOffices.current = {};
              newDisciplines.current = {};
              newDepartments.current = {};
              newPtoHours.current = {};
              newPtoDescriptions.current = {};
              newPtoDescriptionHoursHashes.current = {};
              newRateAmounts.current = {};
              newRateDescriptions.current = {};
              newRateDescriptionAmountHashes.current = {};
              projectDisciplineOption.current = {};
              temporaryProjectDisciplineOption.current = {};
              projectRegionOption.current = {};
              temporaryProjectRegionOption.current = {};
              projectOfficeOption.current = {};
              temporaryProjectOfficeOption.current = {};
              newTaskStatuses.current = {};
              newOfficeNames.current = {};
              handleFortyTwoLayersClick();
            }
      }
    >
      {isCsvImportDataReady && FortyTwoLayers ? (
        buttonLabel ?? `Upload ${CsvImportEntityToLabel[entityType]}`
      ) : (
        <CircleSpinner />
      )}
    </StyledImportButton>
  );
};

export default ImportButton;

/* ------------------------------------ - ----------------------------------- */

const StyledImportButton = styled.button<{ height: Nullable<string> }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 225px;
  ${({ height }) =>
    height &&
    `
    height: ${height};
    `}
`;
