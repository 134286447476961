/* eslint-disable react/display-name */
import { useMemo } from 'react';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import styled, { css } from 'styled-components';
import theme from 'theme';
import { AccessLevelSizeIndicator } from '../AccessLevelSizeIndicator';
import MultilevelDropdown, {
  MenuItemFields,
  MenuContent
} from 'components/MultiLevelDropdown';
import CloseXIcon from 'icons/CloseXIcon';
import { AccessRoles } from 'PermissionsModule/types';
import DateNavRightArrowIcon from 'icons/DateNavRightArrowIcon';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const FOOTER_NAME = 'footer';

interface AccessLevelsDropdownContentProps {
  isSummaryView: boolean;
  isIncludeAdmin: boolean;
  isIncludeBudgetManager: boolean;
  isIncludeWorkPlanner: boolean;
  teamMembershipIds: number[];
  onSelect: (role: AccessRoles) => void;
  onClose?: () => void;
  onRequestArchiveMember?: () => void;
}

export const AccessLevelsDropdownContent = ({
  isSummaryView,
  isIncludeAdmin,
  isIncludeBudgetManager,
  isIncludeWorkPlanner,
  teamMembershipIds,
  onSelect,
  onClose,
  onRequestArchiveMember
}: AccessLevelsDropdownContentProps) => {
  const { projectLevelPermissionFlag, restrictAccessToProjectsFlag } =
    useFeatureFlags();

  const menuContent: MenuContent = useMemo(() => {
    const items = [
      {
        name: ACCESS_ROLES.TEAM_MEMBER.toString(),
        role: ACCESS_ROLES.TEAM_MEMBER,
        roleName: 'Base Member',
        descriptionText: (
          <>
            Base member permission level with access to all public teams and
            projects as well as any they are added to as permitted by permission
            settings.
          </>
        ),
        subMenu: projectLevelPermissionFlag
          ? {
              items: [
                {
                  name: ACCESS_ROLES.TEAM_MEMBER.toString(),
                  label: 'All Projects (Default)'
                },
                { name: ACCESS_ROLES.LOGIN.toString(), label: 'No Access' }
              ]
            }
          : undefined
      },
      {
        name: ACCESS_ROLES.WORKLOAD_MANAGER.toString(),
        role: ACCESS_ROLES.WORKLOAD_MANAGER,
        roleName: 'Work Planner',
        descriptionText: (
          <>
            All permissions above, plus access to the Work Planner space on the
            left navigation to{' '}
            <BoldFont>schedule all phases and work plans.</BoldFont> Can view
            and edit hours on budgets,{' '}
            <BoldFont>except personal projects</BoldFont> they do not have
            access to.
          </>
        ),
        subMenu: projectLevelPermissionFlag
          ? restrictAccessToProjectsFlag
            ? {
                items: [
                  {
                    name: ACCESS_ROLES.WORKLOAD_MANAGER_VIEW_ONLY.toString(),
                    renderItem: () => (
                      <LabelWithRightArrowIcon label="View Only" />
                    ),
                    subMenu: {
                      items: [
                        {
                          name: ACCESS_ROLES.WORKLOAD_MANAGER_VIEW_ONLY.toString(),
                          label: 'All Projects'
                        },
                        {
                          name: ACCESS_ROLES.WORKLOAD_PLANNER_VIEW_ONLY_BASE.toString(),
                          label: 'Projects they are a member'
                        }
                      ]
                    }
                  },
                  {
                    name: ACCESS_ROLES.WORKLOAD_MANAGER.toString(),
                    renderItem: () => (
                      <LabelWithRightArrowIcon label="Can Edit" />
                    ),
                    subMenu: {
                      items: [
                        {
                          name: ACCESS_ROLES.WORKLOAD_MANAGER.toString(),
                          label: 'All Projects'
                        },
                        {
                          name: ACCESS_ROLES.WORKLOAD_PlANNER_BASE.toString(),
                          label: 'Projects they are a member'
                        }
                      ]
                    }
                  }
                ]
              }
            : {
                items: [
                  {
                    name: ACCESS_ROLES.WORKLOAD_MANAGER_VIEW_ONLY.toString(),
                    label: 'View Only'
                  },
                  {
                    name: ACCESS_ROLES.WORKLOAD_MANAGER.toString(),
                    label: 'Can Edit'
                  }
                ]
              }
          : undefined
      },
      {
        name: ACCESS_ROLES.FINANCIAL_MANAGER.toString(),
        role: ACCESS_ROLES.FINANCIAL_MANAGER,
        roleName: 'Budget Manager',
        descriptionText: (
          <>
            All permissions above, plus the Budget Manager{' '}
            <BoldFont>
              can view the project name and budget of all projects, except
              personal projects{' '}
            </BoldFont>
            they do not have access to.
          </>
        ),
        subMenu: projectLevelPermissionFlag
          ? restrictAccessToProjectsFlag
            ? {
                items: [
                  {
                    name: ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY.toString(),
                    renderItem: () => (
                      <LabelWithRightArrowIcon label="View Only" />
                    ),
                    subMenu: {
                      items: [
                        {
                          name: ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY.toString(),
                          label: 'All Projects'
                        },
                        {
                          name: ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY_BASE.toString(),
                          label: 'Projects they are a member'
                        }
                      ]
                    }
                  },
                  {
                    name: ACCESS_ROLES.FINANCIAL_MANAGER.toString(),
                    renderItem: () => (
                      <LabelWithRightArrowIcon label="Can Edit" />
                    ),
                    subMenu: {
                      items: [
                        {
                          name: ACCESS_ROLES.FINANCIAL_MANAGER.toString(),
                          label: 'All Projects'
                        },
                        {
                          name: ACCESS_ROLES.FINANCIAL_MANAGER_BASE.toString(),
                          label: 'Projects they are a member'
                        }
                      ]
                    }
                  }
                ]
              }
            : {
                items: [
                  {
                    name: ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY.toString(),
                    label: 'View Only'
                  },
                  {
                    name: ACCESS_ROLES.FINANCIAL_MANAGER.toString(),
                    label: 'Can Edit'
                  }
                ]
              }
          : undefined
      },
      {
        name: ACCESS_ROLES.ADMIN.toString(),
        role: ACCESS_ROLES.ADMIN,
        roleName: 'Administrator',
        descriptionText: (
          <>
            All permissions above, plus access to this Organization settings
            space. Admin has view and edit access to all projects and teams,
            <BoldFont> including private teams.</BoldFont>
          </>
        )
      }
    ]
      .filter((roleInfo) => {
        if (roleInfo.role === ACCESS_ROLES.ADMIN) {
          return isIncludeAdmin;
        }

        if (roleInfo.role === ACCESS_ROLES.FINANCIAL_MANAGER) {
          return isIncludeBudgetManager;
        }

        if (roleInfo.role === ACCESS_ROLES.WORKLOAD_MANAGER) {
          return isIncludeWorkPlanner;
        }

        return true;
      })
      .map((roleInfo) => {
        const value: MenuItemFields = {
          name: roleInfo.name,
          renderItem: () => (
            <RoleItemContainer
              style={{ cursor: !roleInfo.subMenu ? 'pointer' : undefined }}
              $isSummaryView={isSummaryView}
            >
              <RoleItemHeaderContainer>
                {!isSummaryView && (
                  <AccessLevelSizeIndicator role={roleInfo.role} />
                )}
                <RoleItemHeaderTextAndIconContainer>
                  <RoleName $isSummaryView>{roleInfo.roleName}</RoleName>
                  {roleInfo.subMenu && (
                    <DateNavRightArrowIcon
                      fill={theme.colors.colorSemiDarkGray1}
                      height={15}
                      width={7}
                    />
                  )}
                </RoleItemHeaderTextAndIconContainer>
              </RoleItemHeaderContainer>
              {!isSummaryView && (
                <DescriptionText>{roleInfo.descriptionText}</DescriptionText>
              )}
            </RoleItemContainer>
          ),
          subMenu: roleInfo.subMenu
        };

        return value;
      });

    if (onRequestArchiveMember) {
      const isMultipleMembers = teamMembershipIds.length > 1;

      items.push({
        name: FOOTER_NAME,
        renderItem: () => (
          <Footer onClick={onRequestArchiveMember}>
            <div>
              <RemoveMemberActionText>
                Archive Member{isMultipleMembers ? 's' : ''}
              </RemoveMemberActionText>
              <RemoveMemberText>
                {`${
                  isMultipleMembers ? "These Members'" : "This Member's"
                } project data will remain in Mosaic.`}
              </RemoveMemberText>
            </div>
          </Footer>
        )
      });
    }

    const value = {
      header: !isSummaryView ? (
        <HeaderContainer>
          <Header>
            <HeaderText>Select Access Level</HeaderText>
            <CloseIconContainer>
              <CloseXIcon
                onClick={onClose}
                fill="black"
                width="25"
                height="25"
              />
            </CloseIconContainer>
          </Header>
        </HeaderContainer>
      ) : undefined,
      items
    };

    return value;
  }, [
    isIncludeAdmin,
    isIncludeBudgetManager,
    isIncludeWorkPlanner,
    isSummaryView,
    onClose,
    onRequestArchiveMember,
    projectLevelPermissionFlag,
    restrictAccessToProjectsFlag,
    teamMembershipIds.length
  ]);

  const onClickHash = useMemo(() => {
    const value = {
      [ACCESS_ROLES.TEAM_MEMBER]: () => {
        onSelect(ACCESS_ROLES.TEAM_MEMBER);
      },
      [ACCESS_ROLES.LOGIN]: () => {
        onSelect(ACCESS_ROLES.LOGIN);
      },
      [ACCESS_ROLES.WORKLOAD_MANAGER_VIEW_ONLY]: () => {
        onSelect(ACCESS_ROLES.WORKLOAD_MANAGER_VIEW_ONLY);
      },
      [ACCESS_ROLES.WORKLOAD_PLANNER_VIEW_ONLY_BASE]: () => {
        onSelect(ACCESS_ROLES.WORKLOAD_PLANNER_VIEW_ONLY_BASE);
      },
      [ACCESS_ROLES.WORKLOAD_MANAGER]: () => {
        onSelect(ACCESS_ROLES.WORKLOAD_MANAGER);
      },
      [ACCESS_ROLES.WORKLOAD_PlANNER_BASE]: () => {
        onSelect(ACCESS_ROLES.WORKLOAD_PlANNER_BASE);
      },
      [ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY]: () => {
        onSelect(ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY);
      },
      [ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY_BASE]: () => {
        onSelect(ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY_BASE);
      },
      [ACCESS_ROLES.FINANCIAL_MANAGER]: () => {
        onSelect(ACCESS_ROLES.FINANCIAL_MANAGER);
      },
      [ACCESS_ROLES.FINANCIAL_MANAGER_BASE]: () => {
        onSelect(ACCESS_ROLES.FINANCIAL_MANAGER_BASE);
      },
      [ACCESS_ROLES.ADMIN]: () => {
        onSelect(ACCESS_ROLES.ADMIN);
      },
      [FOOTER_NAME]: () => {
        onRequestArchiveMember?.();
      }
    };

    return value;
  }, [onRequestArchiveMember, onSelect]);

  return (
    <MultilevelDropdown
      menuContent={menuContent}
      onClickHash={onClickHash}
      containerCustomStyle={dropdownContainerCustomStyle}
    />
  );
};

const LabelWithRightArrowIcon = ({ label }) => (
  <StyledLabel className="menu-label">
    <SubMenuText>
      {label}
      <DateNavRightArrowIcon
        fill={theme.colors.colorSemiDarkGray1}
        height={15}
        width={7}
      />
    </SubMenuText>
  </StyledLabel>
);

const dropdownContainerCustomStyle = `
  .nested:hover > .nested-dropdown {
    padding: 10px 0;
    top: 10px;
    left: 102%;
  }
  .nested-expand:hover > .nested-dropdown {
    padding: 10px 0;
    top: -10px;
    left: 106%;
  }

  .menu-item {
    :hover {
      background: ${theme.colors.colorTranslucentGray4};
    }
  }
`;

const StyledLabel = styled.div``;

const SubMenuText = styled.li`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.colorMediumGray9};
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;

  svg {
    margin-left: 15px;
    align-self: flex-end;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoldFont = styled.span`
  font-weight: 600;
`;

const DescriptionText = styled.div`
  word-break: break-word;
  white-space: pre-line;
`;

const ItemContainerStyles = css`
  padding: 0px 35px;
  width: 100%;
`;

const RoleItemContainer = styled.div<{ $isSummaryView: boolean }>`
  ${ItemContainerStyles}
  padding: ${({ $isSummaryView }) => ($isSummaryView ? 4 : 12)}px 0px;
  margin: 0px ${({ $isSummaryView }) => ($isSummaryView ? 16 : 35)}px;
  border-bottom: ${({ $isSummaryView }) =>
    !$isSummaryView && `1px solid ${theme.colors.colorLightGray6}`};
`;

const RoleItemHeaderContainer = styled.div``;

const RoleItemHeaderTextAndIconContainer = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

const RoleName = styled.div<{ $isSummaryView: boolean }>`
  font-size: ${({ $isSummaryView }) => ($isSummaryView ? 13 : 15)}px;
  color: ${theme.colors.colorMediumGray7};
  ${({ $isSummaryView }) => !$isSummaryView && `font-weight: 600;`}
`;

const Footer = styled.footer`
  ${ItemContainerStyles}
  padding-top: 12px;
  cursor: pointer;
  padding-bottom: 62px;

  display: flex;
  align-items: center;
  color: ${theme.colors.colorDeleteRed};
`;

const RemoveMemberActionText = styled.div`
  font-weight: 600;
  font-size: 15px;
`;

const RemoveMemberText = styled.div`
  font-size: 13px;
`;

const HeaderContainer = styled.div`
  padding: 25px 20px 12px 35px;
`;

const Header = styled(Row)`
  font-size: 22px;
  font-weight: 600;
  color: black;
  justify-content: space-between;
`;

const HeaderText = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
`;
