import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import noAssigneeIcon from 'images/empty-member.svg';
import PMDot from 'views/memberDisplay/PMDot';
import theme from 'theme';

export const RoleIndicator = styled.div`
  position: absolute;
  top: 12px;
  left: 22px;
`;
export const Wrapper = styled.span`
  position: relative;
  width: 28px;
  box-sizing: border-box;
  display: flex;
  align-items: ${({ isOnTaskModal }) =>
    isOnTaskModal ? 'flex-end' : 'center'};
  justify-content: center;
  margin: 0px 8px 0 0;
`;

const calcMemberBackgroundColor = (props) => {
  if (props.selected) {
    return calculateColorPref(props) || props.theme.colors.colorRoyalBlue;
  } else {
    return props.isAssignedToMe
      ? theme.colors.colorMediumGray5
      : props.taskAssigneeIconContent
      ? theme.colors.colorMediumGray1
      : 'transparent';
  }
};

export const calcMemberBorder = (props) => {
  if (props.selected) {
    return 'solid 1px transparent';
  } else if (props.isMultiAssign) {
    return `solid 1px ${theme.colors.colorPureWhite}`;
  } else {
    return `solid 1px ${calculateColorPref(props) || `transparent`}`;
  }
};

export const TaskAssigneeCircle = styled.span`
  height: 28px;
  width: 28px;
  min-width: 28px;
  box-sizing: border-box;
  background: ${({ taskAssigneeIconContent, isAssignedToMe }) =>
    taskAssigneeIconContent
      ? isAssignedToMe
        ? theme.colors.colorMediumGray5
        : theme.colors.colorMediumGray1
      : `url(${noAssigneeIcon}) no-repeat 50%`};
  border: ${calcMemberBorder};
  background-color: ${calculateColorPref};
  border-radius: 50%;
  font-size: 12px;
  line-height: 28px;
  font-weight: 600;
  font-family: 'proxima-nova';
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    ${(props) => !props.preventHover && `border: 1px solid #0074d9`};
  }

  ${PMDot} {
    top: -1px;
    right: -1px;
  }
`;
