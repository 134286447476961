import React, { lazy, Suspense } from 'react';

const containerStyle = {
  paddingLeft: 32,
  flex: 1,
  minHeight: 0
};

const StandardRolesTableContainer = lazy(() =>
  import('./StandardRolesTable/StandardRolesTableContainer')
);

const StandardRolesTab = () => {
  return (
    <div style={containerStyle}>
      <Suspense fallback={<div />}>
        <StandardRolesTableContainer />
      </Suspense>
    </div>
  );
};

export default StandardRolesTab;
