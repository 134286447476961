import { makeAuthorBasedVerification } from './makeVerification';

export const verifyCreateMineOrOthers = makeAuthorBasedVerification(
  'create_dashboards',
  'create_dashboards_other'
);
export const verifyUpdateMineOrOthers = makeAuthorBasedVerification(
  'edit_dashboards',
  'edit_dashboards_other'
);
export const verifyFetchMineOrOthers = makeAuthorBasedVerification(
  'read_dashboards',
  'read_dashboards_other'
);
export const verifyDeleteMineOrOthers = makeAuthorBasedVerification(
  'delete_dashboards',
  'delete_dashboards_other'
);
