import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const SearchIcon = ({
  className = '',
  fill = theme.colors.colorSemiDarkGray1,
  size = 15
}) => (
  <SvgIcon
    width={size}
    height={size}
    viewBox="0 0 14.5 14.5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25939 11.812C4.69696 11.8192 3.19608 11.2026 2.08757 10.098C0.979061 8.9934 0.352934 7.49146 0.348657 5.92325C0.344194 4.3556 0.961023 2.85011 2.06302 1.739C3.16558 0.6277 4.66274 0.00224579 6.22518 6.02887e-06C7.78706 -0.00223373 9.28626 0.619674 10.3912 1.72798C11.4968 2.83555 12.1171 4.33955 12.1171 5.9072C12.1186 7.46962 11.5025 8.96858 10.4043 10.0754C9.30672 11.182 7.81606 11.8069 6.25939 11.812ZM6.25981 1.72368C5.15279 1.71566 4.0878 2.15092 3.30044 2.93241C2.51309 3.71409 2.06846 4.77704 2.06344 5.8889C2.05823 7.00001 2.49412 8.06744 3.27422 8.85621C4.05451 9.64442 5.11504 10.0892 6.22281 10.0914C7.32982 10.0942 8.39259 9.65469 9.17641 8.86946C9.96004 8.08424 10.4004 7.01904 10.4004 5.90719C10.4032 4.80187 9.96878 3.74022 9.1924 2.95574C8.41583 2.17126 7.36107 1.72798 6.25981 1.72368ZM13.2706 14L9.76939 10.7098L10.8485 9.62503L14.3485 12.9153L13.2706 14Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="14.5"
          height="14.5"
          fill="white"
          transform="translate(0.348633)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SearchIcon;
