import { StyledRateMultiplierCell, RateMultiplierContainer } from './styles';
import PermissionLevelCellContent from './PermissionLevelCellContent';

const noop = () => {};

const PermissionLevelCell = ({ cell, row }) => {
  if (!row.original.member) {
    return <div> </div>;
  }

  return (
    <StyledRateMultiplierCell
      data-for="app-tooltip"
      data-delay-show={500}
      data-effect="solid"
      onClick={noop}
    >
      <RateMultiplierContainer className="left-align">
        <PermissionLevelCellContent
          member={row.original.member}
          isDisabled={row.original.list.id === 'archived'}
        />
      </RateMultiplierContainer>
    </StyledRateMultiplierCell>
  );
};

export default PermissionLevelCell;
