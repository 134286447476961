import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { MemberListItem } from 'views';

class FilterableAddMemberList extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      inputValue: inputValuePrev,
      showInviteButton: showInviteButtonPrev,
      showInviteForm: showInviteFormPrev,
      allMemberList: allMemberListPrev
    } = prevProps;

    const { inputValue, toggleInviteButton, toggleInviteForm } = this.props;
    const filteredMembersNotEmpty = allMemberListPrev.some((member) =>
      this.isMemberMatching(member, inputValue)
    );

    if (inputValue && filteredMembersNotEmpty) {
      showInviteButtonPrev ? toggleInviteButton(false) : () => {};
      showInviteFormPrev ? toggleInviteForm({ open: false }) : () => {};
    } else if (inputValue && !filteredMembersNotEmpty) {
      !showInviteButtonPrev ? toggleInviteButton(true) : () => {};
    }

    if (inputValuePrev && !inputValue) {
      showInviteFormPrev ? toggleInviteForm({ open: false }) : () => {};
    }
  }

  shouldShowDropDown = () => {
    const {
      inputValue,
      allMemberList,
      addMembersForm,
      showInviteForm,
      showInput
    } = this.props;

    if (addMembersForm && addMembersForm.isOpen) {
      if (showInviteForm) return false;
      return !!showInput;
    }

    if (inputValue) {
      if (
        allMemberList.filter((member) =>
          this.isMemberMatching(member, inputValue)
        ).length
      ) {
        return true;
      } else {
        return false;
      }
    } else if (!allMemberList.length) {
      return false;
    } else {
      return true;
    }
  };

  isMemberMatching = (member, value) => {
    const { name, initials } = member.account;

    return (
      (name && name.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
      (initials && initials.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    );
  };

  handleKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
    }
  };

  renderInviteRow = () => {
    const { toggleInviteForm } = this.props;
    return (
      <li
        className="invite-row"
        onClick={() =>
          toggleInviteForm({ open: true, origin: 'FilterableAddMemberList' })
        }
      >
        <div className="invite-to-mosaic-circle">
          <i />
        </div>
        <span>Invite To Mosaic</span>
      </li>
    );
  };

  render() {
    const {
      inputValue,
      clearMemberFilter,
      membersNotInBoard,
      changeInputValue,
      allMemberList,
      addMemberToSelection,
      addMemberToProject,
      me,
      groupId,
      projectId,
      toggleInviteForm,
      showInviteForm,
      inviteTeamMember,
      addMembersForm,
      toggleMemberInput
    } = this.props;

    return (
      <div className="form-field-full">
        <input
          type="text"
          name="name"
          placeholder="Type name and select to add members"
          autoFocus={true}
          autoComplete="off"
          onChange={changeInputValue}
          onKeyPress={this.handleKeyPress}
          value={inputValue}
          onFocus={() => toggleMemberInput(true)}
        />
        {inputValue ? (
          <>
            <i className="close-icon" onClick={clearMemberFilter} />
            <button
              className="member-invite-button"
              onClick={(e) => {
                e.preventDefault();
                toggleInviteForm({
                  open: true,
                  origin: 'FilterableAddMemberList'
                });
              }}
            >
              Invite
            </button>
          </>
        ) : null}
        {this.shouldShowDropDown() ? (
          <div
            className={cn('insert-delete-member-list-auto-complete', {
              'not-new-project': projectId || groupId
            })}
          >
            <ul>
              {addMembersForm && addMembersForm.isOpen
                ? this.renderInviteRow()
                : null}
              {!inputValue
                ? allMemberList.map((member, idx) => {
                    const notInBoard =
                      membersNotInBoard &&
                      membersNotInBoard.indexOf(member) !== -1 &&
                      member.account.id !== me.id;
                    return (
                      <MemberListItem
                        key={member.account.id}
                        me={me}
                        member={member}
                        notInBoard={notInBoard}
                        addMemberToSelection={addMemberToSelection}
                        addMemberToProject={addMemberToProject}
                        changeInputValue={changeInputValue}
                        groupId={groupId}
                        projectId={projectId}
                      />
                    );
                  })
                : allMemberList
                    .filter((member) =>
                      this.isMemberMatching(member, inputValue)
                    )
                    .map((member) => {
                      const notInBoard =
                        membersNotInBoard &&
                        membersNotInBoard.indexOf(member) !== -1 &&
                        member.account.id !== me.id;
                      return (
                        <MemberListItem
                          key={member.account.id}
                          me={me}
                          member={member}
                          notInBoard={notInBoard}
                          addMemberToSelection={addMemberToSelection}
                          addMemberToProject={addMemberToProject}
                          changeInputValue={changeInputValue}
                          groupId={groupId}
                          projectId={projectId}
                        />
                      );
                    })}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

FilterableAddMemberList.propTypes = {
  inputValue: PropTypes.string.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allMemberList: PropTypes.array,
  addMemberToSelection: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object,
  openTeamMemberModal: PropTypes.func,
  closeModal: PropTypes.func
};

export default FilterableAddMemberList;
