import { Dispatch, SetStateAction } from 'react';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import {
  ProjectMembershipRequirementListItem,
  ProjectMembershipRequirementListItemDataObject,
  AddProjectMembershipRequirementListItem
} from '../types';
import { ProjectUnassignedMemberBudgetWithPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import cn from 'classnames';
import { FindPeopleModalContextValuesType } from 'SuggestionModule/components/FindPeople/contexts/types';

const useProjectMembershipRequirementsItemsBuilder = ({
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState
}: UseNestedCollapseHookReturnedValues) => {
  const projectMembershipRequirementsItemsBuilder = ({
    isParentOpen,
    parentId,
    order,
    projectMembership,
    memberBudget,
    isFetchingEntityRequirements,
    isHoveringOnGroup,
    onAddRequirementsClick,
    setHoveredUnassignedMemberBudgetGroupId
  }: {
    isParentOpen?: boolean;
    parentId: string;
    order: ProjectMembershipRequirementListItemDataObject[] | never[];
    projectMembership: ProjectUnassignedMemberBudgetWithPosition['project_membership'];
    memberBudget: ProjectUnassignedMemberBudgetWithPosition;
    isFetchingEntityRequirements: FindPeopleModalContextValuesType['isFetchingEntityRequirements'];
    isHoveringOnGroup: boolean;
    setHoveredUnassignedMemberBudgetGroupId: Dispatch<
      SetStateAction<Nullable<string>>
    >;
    onAddRequirementsClick: AddProjectMembershipRequirementListItem['onAddRequirementsClick'];
  }) => {
    const list: ProjectMembershipRequirementListItem[] = [];

    /* ----------------------------- Requirements ---------------------------- */
    order.forEach(
      (
        requirementDataObject: ProjectMembershipRequirementListItemDataObject
      ) => {
        const projectMembershipRequirementListItem: ProjectMembershipRequirementListItem =
          {
            id: requirementDataObject.serializedId,
            memberBudget,
            projectMembership,
            requirement: requirementDataObject,
            itemType: LIST_ITEM_TYPES.ProjectMembershipRequirement,
            toggleId: undefined,
            isOpen: true,
            isParentOpen,
            containerClassName: cn(
              'project-membership-requirement-list-item-container open',
              { 'is-hovering-on-group': isHoveringOnGroup }
            ),
            toggleCollapse: undefined,
            parentId,
            onMouseEnterContainer: () => {
              setHoveredUnassignedMemberBudgetGroupId(parentId);
            },
            onMouseLeaveContainer() {
              setHoveredUnassignedMemberBudgetGroupId(null);
            },
            onAddRequirementsClick
          };

        list.push(projectMembershipRequirementListItem);
      }
    );

    return list;
  };

  return projectMembershipRequirementsItemsBuilder;
};

export default useProjectMembershipRequirementsItemsBuilder;
