import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import Popover from 'components/Popover';
import NumberFormat from 'react-number-format';
import { blurOnEnter } from 'appUtils/userInteractions';
import {
  BUDGET_STATUSES,
  PROJECT_BUDGET_STATUSES_DISPLAY,
  PROJECT_BUDGET_STATUS_OPTIONS
} from 'appConstants/budgetStatuses';
import KaratRight from 'icons/KaratRight';
import {
  fetchEntityOptions,
  clearEntityOptions
} from 'EntityOptionsModule/actionCreators';
import { Project } from 'ProjectsModule/models/project';
import {
  makeGetOwnProjectBudgetStatuses,
  makeGetOwnBudgetProposalOptions,
  getFetchedEntityOptionSerializeIds
} from 'EntityOptionsModule/selectors';
import { getSelectedTeamId } from 'selectors';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { serializeId } from 'appUtils';

// 10, 20, ..., 100
// Get entityOptions by entityType (project) and entityId (project.id)
const defaultProposalOptions = [...Array(10)].map(
  (_, index) => (index + 1) * 10
);

const emptyArray = [];
interface BudgetStatusDropdownProps {
  isOpen: boolean;
  target: EventTarget;
  closePopover: () => void;
  project: Project;
  handleProjectStatusChange: (params: {
    id: number;
    budget_status: string | undefined;
    budget_sub_status?: Nullable<string | number>;
  }) => void;
}

const BudgetStatusDropdown = ({
  isOpen,
  target,
  closePopover,
  project,
  handleProjectStatusChange
}: BudgetStatusDropdownProps) => {
  const dispatch = useAppDispatch();
  const [isProposalMenuOpen, setIsProposalMenuOpen] = useState(false);
  const [isEnteringCustom, setIsEnteringCustom] = useState(false);
  const [customPercentage, setCustomPercentage] = useState('');
  const currentPercentage = project?.budget_sub_status || null;
  const currentStatus = project?.budget_status || BUDGET_STATUSES.ACTIVE;
  const customInputRef = useRef(null);
  const proposalMenuRef = useRef(null);
  const teamId = useAppSelector(getSelectedTeamId);
  const fetchedEntityOptionSerializeIds = useAppSelector(
    getFetchedEntityOptionSerializeIds
  );

  useEffect(() => {
    if (project && project.id && teamId) {
      const entityOptionUid = serializeId({
        id: [project.id],
        ids: undefined,
        itemType: ENTITY_TYPES.project
      });
      if (!fetchedEntityOptionSerializeIds[entityOptionUid] && isOpen) {
        dispatch(
          fetchEntityOptions({
            team_id: teamId,
            entity_id: project.id,
            entity_type: ENTITY_TYPES.project
          })
        );
      }
    }
  }, [dispatch, fetchedEntityOptionSerializeIds, isOpen, project, teamId]);

  useEffect(
    () => () => {
      if (project) {
        dispatch(
          clearEntityOptions({
            entity_id: project.id,
            entity_type: ENTITY_TYPES.project
          })
        );
      }
    },
    [dispatch, project]
  );

  const getOwnProjectBudgetStatuses = useMemo(
    makeGetOwnProjectBudgetStatuses,
    []
  );
  const getOwnBudgetProposalOptions = useMemo(
    makeGetOwnBudgetProposalOptions,
    []
  );

  const projectBudgetStatuses = useAppSelector((state) =>
    project
      ? getOwnProjectBudgetStatuses(state, {
          entityId: project.id,
          entityType: 'Project'
        })
      : emptyArray
  );

  const budgetProposalOptions = useAppSelector((state) =>
    project
      ? getOwnBudgetProposalOptions(state, {
          entityId: project.id,
          entityType: 'Project'
        })
      : emptyArray
  );

  const proposalOptions = budgetProposalOptions.length
    ? budgetProposalOptions
    : defaultProposalOptions;

  const defaultMenuItems = [
    <StyledMenuItem
      key={BUDGET_STATUSES.PROPOSAL}
      onClick={() => setIsProposalMenuOpen(true)}
    >
      {PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.PROPOSAL]}{' '}
      <IconContainer className="karat-container" ref={proposalMenuRef}>
        <KaratRight className={undefined} />
      </IconContainer>
    </StyledMenuItem>,
    ...PROJECT_BUDGET_STATUS_OPTIONS.slice(1).map(({ value, label }) => (
      <StyledMenuItem
        key={value}
        onClick={() => onSelect({ newStatus: value })}
      >
        {label}
      </StyledMenuItem>
    ))
  ];

  const menuItemsFromEntityOptions = projectBudgetStatuses.map((status) => {
    if (status === BUDGET_STATUSES.PROPOSAL) {
      return (
        <StyledMenuItem
          key={BUDGET_STATUSES.PROPOSAL}
          onClick={() => setIsProposalMenuOpen(true)}
        >
          {PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.PROPOSAL]}{' '}
          <IconContainer className="karat-container" ref={proposalMenuRef}>
            <KaratRight className={undefined} />
          </IconContainer>
        </StyledMenuItem>
      );
    } else {
      return (
        <StyledMenuItem
          key={status}
          onClick={() => onSelect({ newStatus: status })}
        >
          {PROJECT_BUDGET_STATUSES_DISPLAY[status]}
        </StyledMenuItem>
      );
    }
  });

  const menuItems = menuItemsFromEntityOptions.length
    ? menuItemsFromEntityOptions
    : defaultMenuItems;

  const onSelect = ({
    newStatus,
    budgetSubStatus
  }: {
    newStatus: string | undefined;
    budgetSubStatus?: Nullable<number | string>;
  }) => {
    if (currentStatus !== newStatus || currentPercentage !== budgetSubStatus) {
      handleProjectStatusChange({
        id: project?.id,
        budget_status: newStatus,
        ...(budgetSubStatus
          ? { budget_sub_status: budgetSubStatus }
          : { budget_sub_status: null })
      });
      // dispatch(
      //   editProject({
      //     id: project.id,
      //     budget_status: newStatus,
      //     ...(budgetSubStatus
      //       ? { budget_sub_status: budgetSubStatus }
      //       : { budget_sub_status: null })
      //   })
      // );
    }
    setIsProposalMenuOpen(false);
    closePopover();
  };

  const closeProposalMenu = () => {
    setIsProposalMenuOpen(false);
    if (isEnteringCustom) {
      setIsEnteringCustom(false);
      setCustomPercentage('');
    }
  };

  const renderProposalMenu = () => {
    const handleUpdatePercentage = (newPercentage: string | number) => {
      onSelect({
        newStatus: BUDGET_STATUSES.PROPOSAL,
        ...(newPercentage !== currentPercentage
          ? { budgetSubStatus: newPercentage }
          : {})
      });
      closeProposalMenu();
    };

    const handleCustomPercentageChange = (values) => {
      setCustomPercentage(values.value);
    };

    const handleCustomPercentageSubmit = () => {
      onSelect({
        newStatus: BUDGET_STATUSES.PROPOSAL,
        budgetSubStatus: customPercentage
      });
      setIsEnteringCustom(false);
      setCustomPercentage('');
    };

    const withValueLimit = ({ value }) => ~~value <= 100;

    const proposalMenuOptions = [
      <CustomInputContainer key="custom">
        {isEnteringCustom ? (
          <StyledCustomPercentageInput
            data-testid={`custom-%-input`}
            value={customPercentage}
            placeholder={'0%'}
            onValueChange={handleCustomPercentageChange}
            getInputRef={(ref) => (customInputRef.current = ref)}
            onKeyDown={blurOnEnter(customInputRef)}
            onBlur={handleCustomPercentageSubmit}
            suffix="%"
            allowNegative={false}
            isNumericString
            allowLeadingZeros={false}
            autoFocus
            isAllowed={withValueLimit}
          />
        ) : (
          <StyledAddCustomButton onClick={() => setIsEnteringCustom(true)}>
            Custom
          </StyledAddCustomButton>
        )}
      </CustomInputContainer>,
      <div key="none" onClick={() => handleUpdatePercentage(0)}>
        None
      </div>,
      ...proposalOptions.map((percentage) => (
        <div
          key={percentage}
          onClick={() => handleUpdatePercentage(parseInt(percentage))}
        >
          {parseFloat(percentage)}%
        </div>
      ))
    ];

    return (
      <Popover
        target={proposalMenuRef}
        isOpen={isProposalMenuOpen}
        placement="right-start"
        closePopover={() => closeProposalMenu()}
        className="proposal-percentage-popover"
      >
        <ProbabilityHeader>Probability</ProbabilityHeader>
        <StyledDropdown>{proposalMenuOptions}</StyledDropdown>
      </Popover>
    );
  };

  return (
    <>
      <Popover
        isOpen={isOpen}
        target={target}
        closePopover={() => closePopover()}
        className="styled-budget-project-status-popover"
      >
        <StatusHeader>Status</StatusHeader>
        {menuItems}
      </Popover>
      {isProposalMenuOpen && renderProposalMenu()}
    </>
  );
};

export default BudgetStatusDropdown;

/* ----------------------------- Add custom row ----------------------------- */

const StyledAddCustomButton = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCustomPercentageInput = styled(NumberFormat)`
  width: 60%;
  height: 100%;
  text-align: center;
  outline: none !important;
  border: 1px solid transparent;
  color: ${theme.colors.colorMediumGray9};
  &:focus {
    border-color: ${theme.colors.colorRoyalBlue};
  }
`;

const CustomInputContainer = styled.div`
  padding: 8px 0px;
`;

const StyledMenuItem = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 16px;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;

const IconContainer = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  svg {
    width: 6px;
  }

  &.karat-container {
    position: relative;
    left: 7px;
    top: 1px;
  }
`;

const StatusHeader = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 13px 16px;
`;

const ProbabilityHeader = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 16px 14px;
`;

const StyledDropdown = styled.div`
  text-align: center;
  height: 181px;
  overflow: auto;
  & > * {
    &:hover {
      background: ${theme.colors.colorTranslucentGray4};
    }
    padding: 8px 0px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.colorMediumGray5};
    border-radius: 25px;
  }
`;
