import { BETimeEntryAutogenMode, TimeEntrySettingOption } from './types';

/**
 *  BE time entry setting values -> FE time entry setting values
 */
export const TimeEntryAutogenToSettingMap: Record<
  BETimeEntryAutogenMode,
  TimeEntrySettingOption
> = {
  [BETimeEntryAutogenMode.ConvertExact]: TimeEntrySettingOption.PastPlannedTime,
  [BETimeEntryAutogenMode.ConvertNonMatched]:
    TimeEntrySettingOption.PastPlannedTime,
  [BETimeEntryAutogenMode.OffKeepAutogeneratedTime]:
    TimeEntrySettingOption.ManualTimeEntry,
  [BETimeEntryAutogenMode.OffDeleteAutogeneratedTime]:
    TimeEntrySettingOption.ManualTimeEntry
};

/**
 * FE time entry setting values -> BE time entry setting values
 */
export const TimeEntrySettingToAutogenMap: Record<
  TimeEntrySettingOption,
  BETimeEntryAutogenMode
> = {
  [TimeEntrySettingOption.ManualTimeEntry]:
    BETimeEntryAutogenMode.OffKeepAutogeneratedTime,
  [TimeEntrySettingOption.PastPlannedTime]: BETimeEntryAutogenMode.ConvertExact
};

export const labelsMap: Record<TimeEntrySettingOption, string> = {
  [TimeEntrySettingOption.ManualTimeEntry]: 'Manual Time Entry',
  [TimeEntrySettingOption.PastPlannedTime]: 'Past Planned Time'
};
