import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { makeGetCustomFieldState } from 'CustomFieldsModule/selectors';
import { fetchCustomFieldAllValues } from 'CustomFieldsModule/actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { useArrayFilterField } from '.';
import { FilterListTypeHook } from 'FilterModule/types';
import { FilterField } from 'FilterModule/constants';
import { Merge } from 'type-fest';
import { INTERNAL_LABELS } from 'appConstants/customFields';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';

type CustomFieldsFilterListTypeHook = (
  params: Merge<
    Required<Parameters<FilterListTypeHook>>[0],
    { field: FilterField }
  >
) => ReturnType<FilterListTypeHook>;

export const useCustomFieldsFilter: CustomFieldsFilterListTypeHook = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field,
  isoStateId
}) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  // Since custom field updates are not tracked in redux, must use local isLoaded state. This way we will at least
  // fetch for new data whenever this hook remounts.
  // ie. if we store isLoaded in redux and don't make updates to the state, we will never fetch for updated data.
  const [isLoaded, setIsLoaded] = useState(false);

  const internalLabel = filterFieldToInternalLabel[field];

  const getCustomFieldState = useMemo(makeGetCustomFieldState, []);

  const customFieldState = useAppSelector((state) =>
    isOff || !internalLabel
      ? undefined
      : getCustomFieldState(state, {
          internalLabel: internalLabel
        })
  );

  const items = customFieldState?.allValues || emptyArray;

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items,
    isOff,
    config,
    resultCountHash,
    shouldUseDraft
  });

  /* --------------------------------- loading -------------------------------- */

  const requestStatusId = `${isoStateId}-${internalLabel}`;

  const { status: fetchStatus } = useRequestStatus({
    requestStatusId
  });

  useEffect(() => {
    if (!isOff && internalLabel && teamId && !isLoaded) {
      dispatch(
        fetchCustomFieldAllValues({
          meta: {
            requestStatusId
          },
          teamId,
          internalLabel
        })
      );
    }
  }, [internalLabel, isOff, teamId, dispatch, requestStatusId, isLoaded]);

  useEffect(() => {
    if (!isLoaded && fetchStatus?.isSuccess) {
      setIsLoaded(true);
    }
  }, [fetchStatus?.isSuccess, isLoaded]);

  return {
    ...arrayFilterFieldValues,
    isLoading: customFieldState?.isFetchingAllValues || isResultsLoading,
    resultCountHash,
    filterLabel: config?.filterLabel,
    isOff: isOff || !internalLabel || !items.length
  };
};

/* ------------------------------------ - ----------------------------------- */

const emptyArray = [];

const filterFieldToInternalLabel = {
  [FilterField.profit_center]: INTERNAL_LABELS.PROFIT_CENTER,
  [FilterField.profit_center_cf]: INTERNAL_LABELS.PROFIT_CENTER
};
