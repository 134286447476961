import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteTeamPosition } from 'BudgetModule/actionCreators';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';

const MemberRoleDeleteConfirmationModal = ({
  onClose,
  roleMembership,
  isTeamRole
}) => {
  const dispatch = useDispatch();

  const onConfirm = () => {
    if (roleMembership) {
      if (isTeamRole) {
        dispatch(
          deleteTeamPosition({
            id: roleMembership.id,
            accountId: roleMembership.account_id
          })
        );
      }
    }
  };

  return (
    <SimpleConfirmModal
      isOpen
      toggle={onClose}
      body="Are you sure you want to delete this role?"
      onConfirm={onConfirm}
    />
  );
};

export default MemberRoleDeleteConfirmationModal;
