import styled from 'styled-components';
import CloseIcon from 'icons/NewCloseIcon';
import cn from 'classnames';
import { DividerProps, MenuItem } from './types';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';

interface MenusProps {
  onClose: () => void;
  menus: MenuItem[];
}

export const MenuDivider: DividerProps = {
  type: 'divider'
};
export const Menus = ({ onClose, menus }: MenusProps) => {
  return (
    <MenuContainer>
      <MenuCloseIconContainer onClick={onClose}>
        <CloseIcon height="30" width="30" stroke="transparent" />
      </MenuCloseIconContainer>
      {menus.map((menu) => {
        if (menu.type === 'divider') {
          return <Divider />;
        } else {
          const { label, onClick, disabled, disabledTooltip, tooltip, icon } =
            menu;
          return (
            <MenuItemContainer
              key={label}
              onClick={onClick}
              className={cn({ disabled: disabled })}
              {...defaultTooltipProps}
              data-tip={disabled ? disabledTooltip : tooltip}
            >
              <IconContainer>{icon}</IconContainer>
              {label}
            </MenuItemContainer>
          );
        }
      })}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.colorPaleGray5};
  border-radius: 5px;
  position: absolute;
  background: ${({ theme }) => theme.colors.colorPureWhite};
  white-space: nowrap;
  padding: 10px;
`;

const MenuCloseIconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 5px;
  cursor: pointer;
`;

const MenuItemContainer = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  padding: 4px 12px 4px 4px;
  cursor: pointer;

  &.disabled {
    color: grey;
  }

  &:hover:not(.disabled) {
    background: ${({ theme }) => theme.colors.colorPaleGray5};
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  width: 107px;
  height: 1px;
  background: ${({ theme }) => theme.colors.colorLightGray6};
  margin: 2px auto;
`;
