import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';

export const initialState = {
  teamRoleIds: [],
  teamRoles: {}
};
const byId = (item) => item.id;
const byTeamRoleId = (item) => item.team_role_id;

const teamRoles = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_TEAM_ROLES.SUCCESS: {
      const { team_roles } = action.payload.response;
      return {
        ...state,
        teamRoleIds: team_roles.map(byTeamRoleId),
        teamRoles: {
          ...state.teamRoles,
          ...keyBy(team_roles, byTeamRoleId)
        }
      };
    }
    case budgetConstants.CREATE_TEAM_ROLE.SUCCESS: {
      const teamRole = action.payload.response;
      return {
        ...state,
        teamRoleIds: [...state.teamRoleIds, teamRole.team_role_id],
        teamRoles: {
          ...state.teamRoles,
          [teamRole.team_role_id]: teamRole
        }
      };
    }
    case budgetConstants.UPDATE_TEAM_ROLE.SUCCESS: {
      const team_role = action.payload.response;
      return {
        ...state,
        teamRoles: {
          ...state.teamRoles,
          [team_role.team_role_id]: team_role
        }
      };
    }
    default:
      return state;
  }
};

export default teamRoles;
