import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect
} from 'react';
import {
  BeforeCapture,
  DragDropContext,
  DropResult,
  ResponderProvided,
  Droppable,
  Draggable,
  DraggableProvided
} from 'react-beautiful-dnd';
import VariableSizeTable from './VariableSizeTable';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useCallbackEffect } from 'appUtils/hooks/useCallbackEffect';
import { TableState, ActionType, TableInstance } from 'react-table';
import StickyHeader from 'components/Table/TableStickyHeader';
import useTableBuilder from 'appUtils/hooks/useTableBuilder';
import {
  BaseTableColumn,
  BaseTableList,
  EmptyRowListItem,
  TableRow
} from './types';
import { hideTooltip } from 'appUtils/tooltipUtils';

export interface TableProps<
  CustomRowPropsType extends Record<string, unknown> = Record<string, unknown>
> {
  mainList: BaseTableList;
  customRowProps?: CustomRowPropsType;
  tableColumns: BaseTableColumn[];
  columnWidthsByHeaderType: Record<string, number>;
  customColumnWidthsByHeaderType?: Record<string, Record<string, number>>;
  /** the total width of the table, including rightPadding (if table has it) */
  totalColumnsWidthOverride?: number;
  /** eg. { 'phaseRow': 'phaseRow' } */
  rowTypesHash: Record<string, string>;
  /**
   * eg. { 'phaseRow': { 'nameColumn': PhaseNameCell } }
   *    or
   *     { 'phaseRow': SomeComponent } <- same component for all columns
   */
  rowTypeToCellsByHeaderType: Record<
    string,
    | Record<string, (props: unknown) => JSX.Element>
    | ((props: unknown) => JSX.Element)
  >;
  emptyRow?: EmptyRowListItem;
  stickyHeaderCells?: Record<string, (...args: unknown[]) => JSX.Element>;
  headersToIgnore?: string[];
  showFooter?: boolean;
  showHeader?: boolean;
  isVirtual?: boolean;
  hideDevProps?: boolean;
  tableStateRef?: React.RefObject<TableState>;
  shouldHideTooltipOnRowChanges?: boolean;
  /**
   * When true, isScrolling prop will be available to cells
   */
  shouldUseIsScrolling?: boolean;

  // sticky header ----------------------------- */

  /** When true, table will track sticky header section */
  hasStickyHeader?: boolean;
  /** When true, sticky header will be hidden. Not the same as hasStickyHeader, but depends on it */
  isStickyHeaderHidden?: boolean;

  // drag ---------------------------------- */

  isDragDisabled?: boolean;
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
  onBeforeCapture?: (before: BeforeCapture) => void;
  dragContainerClassName?: string;

  // react-window-infinite-loader ---------------------- */

  infiniteLoaderRef?: React.RefObject<InfiniteLoader | null>;
  /** VariableSizeTable provides a default value for this */
  itemCount?: number;
  numberOfAdditionalRowsForThreshold?: number;
  loadMoreItems?: (item: TableRow) => void | Promise<void>;

  // react-window ------------------------------ */

  /** VariableSizeTable provides a default value for this */
  overscanCount?: number;
  /** VariableSizeTable provides a default value when calculating VariableSizeList height, but this should be explicitly set */
  maxHeight: number;
  defaultRowHeight?: number;
  getItemSize?: (index: number) => number;
  customHandleScroll?: ({
    visibleStartIndex
  }: {
    visibleStartIndex: number;
  }) => void;
  listRef?: React.RefObject<VariableSizeList | null>;

  // react-table ------------------------------ */

  stateReducer?: (
    newState: TableState,
    action: ActionType,
    previousState: TableState,
    instance?: TableInstance
  ) => TableState;
  /** VariableSizeTable uses a default value for these */
  columnMaxWidth?: number;
  columnMinWidth?: number;
  autoResetRowState?: boolean;
  classNames?: { list?: string };
}

/* ------------------------------------ - ----------------------------------- */

export const TableV2 = ({
  mainList,
  customRowProps = emptyObj,
  tableColumns,
  columnWidthsByHeaderType,
  customColumnWidthsByHeaderType,
  totalColumnsWidthOverride,
  rowTypesHash,
  rowTypeToCellsByHeaderType,
  emptyRow,
  stickyHeaderCells, // TODO: remove
  isDragDisabled = true,
  onDragEnd,
  onBeforeCapture,
  numberOfAdditionalRowsForThreshold,
  defaultRowHeight,
  maxHeight,
  showFooter,
  showHeader = true,
  headersToIgnore,
  stateReducer,
  columnMaxWidth = 2000, // some arbitrarily high number
  columnMinWidth = 0, // these defaults are just to override the not-so-great defaults in VariableSizeTable
  dragContainerClassName,
  overscanCount,
  itemCount,
  isVirtual = true,
  hasStickyHeader,
  isStickyHeaderHidden,
  autoResetRowState,
  loadMoreItems,
  customHandleScroll,
  getItemSize,
  hideDevProps,
  listRef,
  infiniteLoaderRef,
  tableStateRef,
  shouldHideTooltipOnRowChanges,
  shouldUseIsScrolling,
  classNames
}: TableProps) => {
  const fallbackListRef = useRef<VariableSizeList>(null);
  const listRefToUse = listRef || fallbackListRef;

  const [stickySection, setStickySection] = useState(null);

  const {
    columns,
    rows,
    stickyList,
    subStickyLists,
    handleScroll,
    headerItem,
    headerItem2
  } = useTableBuilder({
    listRef: listRefToUse,
    mainList,
    tableColumns,
    columnWidths: columnWidthsByHeaderType,
    customColumnWidths: customColumnWidthsByHeaderType,
    rowTypes: rowTypesHash,
    rowToCells: rowTypeToCellsByHeaderType,
    emptyRow,
    customRowProps,
    stickySection: hasStickyHeader ? stickySection : undefined,
    setStickySection
  });

  useEffect(() => {
    if (shouldHideTooltipOnRowChanges) {
      hideTooltip();
    }
  }, [shouldHideTooltipOnRowChanges, rows]);

  const _handleScroll = useCallback(
    ({ visibleStartIndex }: { visibleStartIndex: number }) => {
      if (customHandleScroll && !hasStickyHeader) {
        // customHandleScroll behaves as an override
        customHandleScroll({ visibleStartIndex });
      } else if (hasStickyHeader) {
        handleScroll({ visibleStartIndex });
        if (customHandleScroll) {
          // customHandleScroll behaves as a callback that happens on top of default behaviour
          customHandleScroll({ visibleStartIndex });
        }
      }
    },
    [customHandleScroll, handleScroll, hasStickyHeader]
  );

  const _loadMoreItems = useCallbackEffect(loadMoreItems);

  const handleLoadMoreItems = useCallback(
    (startIndex: number) => {
      const item = rows[startIndex - 1] as TableRow | undefined;
      if (item) {
        _loadMoreItems(item);
      }
    },
    [_loadMoreItems, rows]
  );

  const _numberOfAdditionalRowsForThreshold = useMemo(() => {
    return (
      numberOfAdditionalRowsForThreshold ??
      rows.filter((row) => !row.isRow).length // default value
    );
  }, [numberOfAdditionalRowsForThreshold, rows]);

  const _getItemSize = useCallback(
    (index: number): number =>
      getItemSize
        ? getItemSize(index)
        : rows[index]?.itemHeight || defaultRowHeight || DEFAULT_ROW_HEIGHT,
    [defaultRowHeight, getItemSize, rows]
  );

  const renderStickyHeader = ({
    draggableProvided,
    rowItem
  }: {
    draggableProvided?: DraggableProvided;
    rowItem: TableRow;
  }) => {
    return (
      <StickyHeader
        key={rowItem.id}
        row={{ original: rowItem }}
        columnWidths={columnWidthsByHeaderType}
        totalWidth={totalColumnsWidthOverride}
        cells={rowTypeToCellsByHeaderType[rowItem.rowType]}
        columns={columns}
        rowClassName={`${rowItem.rowType} ${rowItem.className || ''}`}
        customRowProps={customRowProps}
        provided={draggableProvided}
        isHidden={isStickyHeaderHidden}
        templateCols={undefined}
        backgroundColor={undefined}
        marginTop={undefined}
        height={undefined}
      />
    );
  };

  const render = (
    <>
      {hasStickyHeader &&
        stickyList?.isOpen &&
        !headerItem.skipHeader &&
        (isDragDisabled ? (
          renderStickyHeader({ rowItem: headerItem })
        ) : (
          <Droppable droppableId="sticky-header">
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef}>
                <Draggable
                  index={0}
                  draggableId={stickyList.id}
                  key={stickyList.id}
                  isDragDisabled={isDragDisabled}
                >
                  {(draggableProvided) =>
                    renderStickyHeader({
                      draggableProvided,
                      rowItem: headerItem
                    })
                  }
                </Draggable>
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        ))}

      {subStickyLists.map((item) => renderStickyHeader({ rowItem: item }))}

      {headerItem2 &&
        !headerItem2.skipHeader &&
        renderStickyHeader({ rowItem: headerItem2 })}

      <VariableSizeTable
        columns={columns}
        data={rows}
        itemHeight={defaultRowHeight} // technically not necessary to provide since rows should all have rowHeight property
        maxHeight={maxHeight}
        showFooter={showFooter}
        virtual={isVirtual}
        loadMoreItems={handleLoadMoreItems as () => void} // fix at some point
        handleScroll={_handleScroll as () => void} // ""
        getItemSize={_getItemSize as () => number} // ""
        customRowProps={customRowProps}
        numberOfAdditionalRowsForThreshold={_numberOfAdditionalRowsForThreshold}
        listRef={listRefToUse}
        infiniteLoaderRef={infiniteLoaderRef}
        showHeader={showHeader}
        isDragDisabled={isDragDisabled}
        autoResetRowState={autoResetRowState}
        stateReducer={stateReducer || defaultStateReducer}
        tableStateRef={tableStateRef}
        columnMaxWidth={columnMaxWidth}
        dragContainerClassName={dragContainerClassName}
        overscanCount={overscanCount}
        itemCount={itemCount}
        columnMinWidth={columnMinWidth}
        totalColumnsWidthOverride={totalColumnsWidthOverride}
        headersToIgnore={headersToIgnore}
        hideDevProps={hideDevProps}
        shouldUseIsScrolling={shouldUseIsScrolling}
        classNames={classNames}
      />
    </>
  );

  return isDragDisabled ? (
    render
  ) : (
    <DragDropContext
      onDragEnd={onDragEnd || noop}
      onBeforeCapture={onBeforeCapture}
    >
      {render}
    </DragDropContext>
  );
};

/* ------------------------------------ - ----------------------------------- */

const DEFAULT_ROW_HEIGHT = 50;

const noop = () => undefined;
const emptyObj = {};
const emptyArray = [];

const defaultStateReducer = (newState) => newState;
