import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';
import theme from 'theme';

import { ProjectDetailBlock, MilestoneDetailList } from '../..';

import { openMilestoneModal, fetchPhasesByProjectIds } from 'actionCreators';
import { getActivePhasesByProject } from 'selectors';
import PhaseDiamondIcon from 'icons/PhaseDiamondIcon';

const PhaseDiamondIconContainer = styled.span`
  position: relative;
  bottom: 1px;
  padding: 0 2px 0 0;

  path {
    stroke: ${theme.colors.colorCalendarBlue};
  }
`;

const ProjectDetailMilestones = ({ project }) => {
  const dispatch = useDispatch();

  const phasesByProject = useSelector(getActivePhasesByProject);

  if (!project) {
    return null;
  }

  const phaseInfo = phasesByProject[project?.id] || {};
  const { phases = [] } = phaseInfo;

  const orderedPhases = phases.filter(
    (phase) => !!phase && !phase.is_like_default
  );

  const fetchAndOpenMilestoneModal = () => {
    dispatch(fetchPhasesByProjectIds({ projectIds: [project.id] }));
    dispatch(openMilestoneModal());
  };

  return (
    <ProjectDetailBlock
      className="project-milestones"
      handleClick={fetchAndOpenMilestoneModal}
      title={'Click to Add/Edit Phases'}
    >
      <header>
        <div className="header-title">
          <PhaseDiamondIconContainer>
            <PhaseDiamondIcon width={12} height={11} />
          </PhaseDiamondIconContainer>
          <span
            data-tip="Click to add <br/> or edit Phases"
            data-for="app-tooltip"
            data-place="top"
            data-effect="solid"
            data-html
            className="schedule"
          >
            {phases?.length && `${phases.length} `}Phase
            {phases?.length > 1 ? 's' : ''}
          </span>
        </div>
      </header>
      {orderedPhases.length ? (
        <MilestoneDetailList milestones={orderedPhases} />
      ) : null}
    </ProjectDetailBlock>
  );
};

export default ProjectDetailMilestones;
