import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Row, Col } from 'reactstrap';
import { MemberInitials } from '..';
import { stopPropagation } from 'appUtils';

const AddProjectStatusForm = ({
  formSubmit,
  handleChange,
  description,
  counter,
  statusCount,
  me,
  setEditTrue,
  classes,
  handleBlur
}) => (
  <Row className="add-project-status-form">
    <div className="col-wrapper">
      <Col sm={1} className="member-initials">
        <div>
          <MemberInitials
            member={me}
            classes="small-logged-member status-member selected"
            onClick={stopPropagation}
          />
        </div>
      </Col>
      <Col sm={10} className="input-col">
        <form
          onSubmit={formSubmit}
          className={cn(classes, {
            'add-status-form': true,
            'border-bottom': statusCount
          })}
          onFocus={setEditTrue}
          onBlur={(e) => handleBlur(e)}
        >
          <input
            type="text"
            value={description}
            name="description"
            onChange={handleChange}
            placeholder="Add new update"
            className="form-description new-status"
            required
            autoComplete="off"
          />
          <div className="status-bottom">
            <span className="char-count">{counter}</span>
            <button
              type="submit"
              className={cn('button-submit clickable', {
                disabled: description.length === 0
              })}
            >
              Done
            </button>
          </div>
        </form>
      </Col>
    </div>
  </Row>
);

AddProjectStatusForm.propTypes = {
  formSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired
};

export default AddProjectStatusForm;
