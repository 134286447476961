import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ProjectsIcon = ({ className, height = '22', width = '22' }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 22 22"
  >
    <path
      d="M5 20H18.5V6.8375L13.6625 2H5V20ZM14 3.9125L16.5875 6.5H14V3.9125ZM6.125 3.125H12.875V7.625H17.375V18.875H6.125V3.125Z"
      fill="#4F4F4F"
    />
    <path d="M10.625 5.375H7.25V6.5H10.625V5.375Z" fill="#4F4F4F" />
    <path d="M14 8.75H7.25V9.875H14V8.75Z" fill="#4F4F4F" />
    <path d="M15.125 11H7.25V12.125H15.125V11Z" fill="#4F4F4F" />
    <path d="M12.875 13.25H7.25V14.375H12.875V13.25Z" fill="#4F4F4F" />
  </SvgIcon>
);

export default ProjectsIcon;
