import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BulkUpdateModalHomeTasksConnector } from 'views';
import { closeHomeTaskModal } from 'actionCreators';
import {
  getFlaggedTasksModalOpen,
  getSelectedDate,
  getSelectedFlag
} from 'selectors';

export class HomeTaskListModalContainer extends Component {
  render() {
    const { isOpen, selectedFlag, selectedDate } = this.props;
    return (
      <BulkUpdateModalHomeTasksConnector
        isOpen={isOpen}
        selectedFlag={selectedFlag}
        selectedDate={selectedDate}
        toggle={closeHomeTaskModal}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: getFlaggedTasksModalOpen(state),
  selectedFlag: getSelectedFlag(state),
  selectedDate: getSelectedDate(state)
});

const mapDispatchToProps = {
  closeHomeTaskModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeTaskListModalContainer);
