import ViewFactory from 'components/ViewFactory/ViewFactory';
import withRouterParams from 'containers/Routers/WithRouterParams';
import { memo } from 'react';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import NavigationTracker from 'views/Navigation/NavigationTracker';
import { Views } from 'appConstants/navigation';
import MemberModalRouter from 'containers/Routers/MemberModalRouter';

type PermissionsSettingsRouterProps = RouteComponentProps;

const UnmemoizedPermissionsSettingsRouter = withRouterParams((props) => {
  const { match } = props;
  return (
    <ViewFactory moduleType={'permissionsSettingsView'}>
      <NavigationTracker view={Views.PERMISSIONS} />
      <Route
        path={`${match.url}/member/:memberId/view/:memberViewType`}
        component={MemberModalRouter}
      />
    </ViewFactory>
  );
});

const propsAreEqual = (
  prevProps: PermissionsSettingsRouterProps,
  nextProps: PermissionsSettingsRouterProps
) => {
  const { match: nextMatch, location: nextLocation } = nextProps;

  const areEqual =
    prevProps.match.url === nextMatch.url &&
    prevProps.match.url === nextLocation.pathname;

  return areEqual;
};

export const PermissionsSettingsRouter = memo(
  UnmemoizedPermissionsSettingsRouter,
  propsAreEqual
);
