import { createBrowserHistory } from 'history';
import { reducerMap } from './reducers';
import rootSaga from './sagas';
import { calculateResponsiveState } from 'redux-responsive';

export const history = createBrowserHistory();

export function getRootModule() {
  return {
    id: 'root',
    reducerMap: reducerMap(history),
    sagas: [rootSaga],
    // Actions to fire when this module is added/removed
    initialActions: [calculateResponsiveState(window)]
    // finalActions: []
  };
}
