import * as budgetConstants from '../constants';
import produce from 'immer';
import { ProjectId } from 'ProjectsModule/models/project';
import { TeamId } from 'TeamsModule/models/team';
import { BudgetViewSettings } from 'BudgetModule/models/BudgetViewSettings';

export interface BudgetSettingsState {
  settings: Record<ProjectId, BudgetViewSettings>;
  teamSettings: Record<TeamId, BudgetViewSettings>;
}

export const initialState: BudgetSettingsState = {
  settings: {},
  teamSettings: {}
};

export default (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case budgetConstants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.SUCCESS: {
        const { response } = action.payload;
        draft.settings[response.viewable_id] = response;
        break;
      }
      case budgetConstants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER: {
        const { budgetSettings, projectId } = action.payload;
        draft.settings[projectId] = {
          ...state.settings[projectId],
          ...budgetSettings
        };
        break;
      }
      case budgetConstants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.FAILURE: {
        const { original, projectId } = action.payload.requestPayload;
        if (original) {
          draft.settings[projectId] = original;
        }
        break;
      }
      case budgetConstants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.SUCCESS: {
        const { response } = action.payload;
        draft.settings[response.viewable_id] = response;
        break;
      }
      case budgetConstants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.SUCCESS:
      case budgetConstants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.SUCCESS: {
        const { response } = action.payload;
        // viewable_id is team_id
        draft.teamSettings[response.viewable_id] = response;
        break;
      }
    }
  });
};
