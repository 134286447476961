import cn from 'classnames';
import { MembersToNotify } from '../..';
import { connect } from 'react-redux';
import { getDefaultMembersToNotify } from 'selectors';
import { withRouter } from 'react-router-dom';
import { usePopover } from 'components/Popover/usePopover';
import styled, { css } from 'styled-components';
import { ReactComponent as FileAttachmentIcon } from 'images/file-attachment.svg';
import { ReactComponent as BlueFileAttachmentIcon } from 'images/file-attachment-blue.svg';
import uniq from 'lodash/uniq';

const CommentControls = ({
  isExpanded,
  isEditingComment,
  commentBody,
  onCancel,
  onSubmit,
  unsavedFiles,
  attachedFiles,
  isOnPersonalProject,
  uploadFileDisabled = false,
  mentionedMembers,
  handleFiles,
  me,
  //
  membersToNotify
}) => {
  const { Popover, openPopover, closePopover, isOpen } = usePopover();
  const submitText = isEditingComment ? 'Save' : 'Post';
  const enableSubmit =
    commentBody.trim().length ||
    unsavedFiles.length ||
    (attachedFiles && attachedFiles.length);

  const getMembersToNotify = () => {
    const uniqueNames = uniq([...mentionedMembers, ...membersToNotify]);
    return uniqueNames.filter((name) => name !== me.name);
  };

  const openFileOptions = (event) => {
    openPopover({ event });
  };

  const handleChange = (event) => {
    handleFiles(event.target.files);
    closePopover();
  };

  return (
    <ExpandedCommentBox
      className={cn('expanded-comment-box', {
        hide: !isExpanded && !isOpen,
        personal: isOnPersonalProject
      })}
    >
      {!isOnPersonalProject && (
        <MembersToNotify members={getMembersToNotify()} />
      )}
      <ActionWrapper className="actions-wrapper">
        {!uploadFileDisabled && (
          <IconContainer onClick={openFileOptions}>
            {isOpen ? <BlueFileAttachmentIcon /> : <FileAttachmentIcon />}
          </IconContainer>
        )}
        <Popover showArrow>
          <FileOptions>
            <OptionContainer>
              <OptionHeader>Attach File</OptionHeader>
              <OptionItemContainer>
                <OptionLabel htmlFor="local-file">Local Drive</OptionLabel>
                <input
                  className="local-file-input"
                  type="file"
                  name="local-file"
                  id="local-file"
                  multiple
                  onChange={handleChange}
                />
              </OptionItemContainer>
            </OptionContainer>
          </FileOptions>
        </Popover>
        <CancelButton className="cancel" onClick={onCancel}>
          Cancel
        </CancelButton>
        <SubmitButton
          className="submit-button"
          disabled={!enableSubmit}
          onClick={onSubmit}
        >
          {submitText}
        </SubmitButton>
      </ActionWrapper>
    </ExpandedCommentBox>
  );
};

const mapStateToProps = (state) => ({
  membersToNotify: getDefaultMembersToNotify(state)
});

export default withRouter(connect(mapStateToProps)(CommentControls));

const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlainButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
`;

const ExpandedCommentBox = styled.div`
  position: relative;
  height: 40px;
  border-top: solid 2px ${({ theme }) => theme.colors.colorTranslucentGray4};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 7px;

  &.personal {
    justify-content: flex-end;
  }

  &.hide {
    display: none;
  }

  .mentions-list-wrapper {
    position: absolute;
    top: 5px;
  }

  .mention {
    position: relative;
    display: inline-block;
    float: left;
    color: ${({ theme }) => theme.colors.colorMediumGray4};

    font-size: 14px;
    padding-left: 2px;

    .mention-count {
      position: relative;
      display: inline-block;
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }

  .mention-member {
    width: max-content;

    &.add-others {
      color: white;
      opacity: 0.7;
    }

    &:nth-child(2) {
      margin-top: 5px;
    }
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const IconContainer = styled(PlainButton)`
  height: 28px;
  width: 28px;
  margin-right: 6px;
  ${FlexCenter}
  flex-direction: row;
`;

const FileOptions = styled.div`
  width: 125px;
  background-color: white;
`;

const OptionContainer = styled.div`
  padding: 8px 0px;

  .local-file-input {
    display: none;
  }
`;

const OptionHeader = styled.div`
  padding-bottom: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorMediumGray4};
  padding-left: 12px;
`;

const OptionLabel = styled.label`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  margin: 0;
  cursor: pointer;
`;

const OptionItemContainer = styled.div`
  cursor: pointer;
  padding-left: 12px;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray17};
    ${OptionLabel} {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }
`;

const ButtonTextStyle = css`
  font-size: 12px;
`;

const CancelButton = styled(PlainButton)`
  color: ${({ theme }) => theme.colors.colorMediumGray4};
  cursor: pointer;
  ${ButtonTextStyle}
`;

const SubmitButton = styled.button`
  position: relative;
  ${FlexCenter}
  height: 32px;
  width: 57px;
  margin-left: 10px;
  border-radius: 30px;
  ${ButtonTextStyle}

  background-color: ${({ theme }) => theme.colors.colorRoyalBlue};
  color: white;
  border: 1px solid ${({ theme }) => theme.colors.colorRoyalBlue};

  &:disabled {
    background-color: white;
    color: ${({ theme }) => theme.colors.colorMediumGray4};
    border: 1px solid ${({ theme }) => theme.colors.colorLightGray6};
  }
`;
