import React from 'react';
import cn from 'classnames';
import SimpleModalSlideshow from 'react-simple-modal-slideshow';
import groupNav from 'images/group-view-navigating-help@2x.png';
import groupAdd from 'images/group-view-adding-help@2x.png';
import groupEdit from 'images/group-view-editing-help@2x.png';
import scheduleNav from 'images/schedule-view-navigating-help@2x.png';
import scheduleAdd from 'images/schedule-view-adding-help@2x.png';
import scheduleEdit from 'images/schedule-view-editing-help@2x.png';
import taskNav from 'images/detail-view-task-navigating-help@2x.png';
import taskAdd from 'images/detail-view-task-adding-help@2x.png';
import taskEdit from 'images/detail-view-task-editing-help@2x.png';
import noteNav from 'images/detail-view-notes-navigating-help@2x.png';
import noteAdd from 'images/detail-view-notes-adding-help@2x.png';
import noteEdit from 'images/detail-view-notes-editing-help@2x.png';
import homeNav from 'images/home-view-navigating-help@2x.png';
import homeAdd from 'images/home-view-adding-help@2x.png';
import homeEdit from 'images/home-view-editing-help@2x.png';
import settingsHelp from 'images/settings-view-help@2x.png';

const boardSlides = [
  {
    media: <img src={groupNav} />
  },
  {
    media: <img src={groupAdd} />
  },
  {
    media: <img src={groupEdit} />
  }
];

const scheduleSlides = [
  {
    media: <img src={scheduleNav} />
  },
  {
    media: <img src={scheduleAdd} />
  },
  {
    media: <img src={scheduleEdit} />
  }
];

const taskSlides = [
  {
    media: <img src={taskNav} />
  },
  {
    media: <img src={taskAdd} />
  },
  {
    media: <img src={taskEdit} />
  }
];

const noteSlides = [
  {
    media: <img src={noteNav} />
  },
  {
    media: <img src={noteAdd} />
  },
  {
    media: <img src={noteEdit} />
  }
];

const homeSlides = [
  {
    media: <img src={homeNav} />
  },
  {
    media: <img src={homeAdd} />
  },
  {
    media: <img src={homeEdit} />
  }
];

const settingsSlides = [
  {
    media: <img src={settingsHelp} />
  },
  {
    media: <img src={settingsHelp} />
  },
  {
    media: <img src={settingsHelp} />
  }
];

class HelpOverlayModal extends React.Component {
  state = {
    helpIndex: 0
  };

  handleClose = () => {
    this.modalSlideshow.close();
    this.setState({ helpIndex: 0 });
    this.props.toggleHelpMenu(false);
    // window.Intercom('update', {
    //   hide_default_launcher: true,
    //   update_last_request_at: true
    // });
    // window.Intercom('hide');
  };

  navigateToIndex = (index) => {
    this.setState({ helpIndex: index });
    this.modalSlideshow.goTo(index);
  };

  handleNext = (index) => {
    this.setState({ helpIndex: index });
    this.modalSlideshow.goToNext();
  };

  handlePrev = (index) => {
    this.setState({ helpIndex: index });
    this.modalSlideshow.goToPrev();
  };

  openHelpCenter = () => {
    window.open('https://mosaicapp.com/helpcenter.html', '_blank');
  };

  render() {
    const {
      isOnHomeView,
      isOnBoardView,
      isOnNotesView,
      isOnTaskView,
      isOnScheduleView,
      isOnSettingsView
    } = this.props;

    const generalContent = {
      content: (
        <div className="help-buttons">
          <button
            className={cn({ 'selected-navigate': this.state.helpIndex === 0 })}
            onClick={() => this.navigateToIndex(0)}
          >
            Navigate
          </button>
          <button
            className={cn({ 'selected-add': this.state.helpIndex === 1 })}
            onClick={() => this.navigateToIndex(1)}
          >
            Adding
          </button>
          <button
            className={cn({ 'selected-edit': this.state.helpIndex === 2 })}
            onClick={() => this.navigateToIndex(2)}
          >
            Editing
          </button>
          <button className="help-center" onClick={this.openHelpCenter}>
            Help Center
          </button>
          <button className="close-help" onClick={this.handleClose} />
        </div>
      )
    };

    const settingsContent = {
      content: (
        <div className="help-buttons">
          <button
            className={cn({ 'selected-settings': false })}
            onClick={() => {}}
            style={{ visibility: 'hidden' }}
          >
            Something
          </button>
          <button
            className={cn({ 'selected-settings': true })}
            onClick={() => {}}
          >
            Settings
          </button>
          <button
            className={cn({ 'selected-settings': false })}
            onClick={() => {}}
            style={{ visibility: 'hidden' }}
          >
            Something
          </button>
          <button className="help-center" onClick={this.openHelpCenter}>
            Help Center
          </button>
          <button className="close-help" onClick={this.handleClose} />
        </div>
      )
    };

    let slides;
    switch (true) {
      case isOnHomeView:
        slides = homeSlides;
        break;
      case isOnTaskView:
        slides = taskSlides;
        break;
      case isOnNotesView:
        slides = noteSlides;
        break;
      case isOnBoardView:
        slides = boardSlides;
        break;
      case isOnScheduleView:
        slides = scheduleSlides;
        break;
      case isOnSettingsView:
        slides = settingsSlides;
        break;
      default:
        slides = [];
        break;
    }

    const content = isOnSettingsView ? settingsContent : generalContent;

    slides.forEach((slide) => {
      slide = Object.assign(slide, content);
    });

    return (
      <SimpleModalSlideshow
        classNamePrefix="help-overlay-modal"
        ref={(ref) => (this.modalSlideshow = ref)}
        enableApi={true}
        slides={slides}
        onClose={this.handleClose}
        onNext={this.handleNext}
        onPrev={this.handlePrev}
      />
    );
  }
}

export default HelpOverlayModal;
