import {
  getMyUserId,
  getSortedAlphabeticalTeamMembers,
  getWorkloadEventIsPersonal,
  getWorkloadEventScheduleBars,
  makeGetFilteredMembersIds,
  getWorkloadViewBy
} from 'selectors';
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkloadEventTable from './WorkloadEventTable';
import { FILTER_PAGES } from 'appConstants/workload';
import {
  fetchWorkloadPlanner,
  fetchAllProjects,
  fetchPhasesByProjectIds,
  fetchActivities
} from 'actionCreators';

import moment from 'appUtils/momentConfig';

import keyBy from 'lodash/keyBy';

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

const staticProps = {
  members: {
    viewBy: 'members',
    pageName: FILTER_PAGES.WORKLOAD_PLANNER,
    plannerType: 'workload'
  },
  projects: {
    viewBy: 'projects',
    pageName: FILTER_PAGES.WORKLOAD_PLANNER,
    plannerType: 'workload'
  }
};

const getFilteredMembersIds = makeGetFilteredMembersIds();

const EventDetail = ({ selectedEvent, selectionDisabled }) => {
  const dispatch = useDispatch();
  const workloadViewBy = useSelector(getWorkloadViewBy);
  const isPersonal = useSelector(getWorkloadEventIsPersonal);
  const teamMembers = useSelector(getSortedAlphabeticalTeamMembers);
  const workloadRangeStart = useSelector(
    (state) => state.workloadEvents.workloadRangeStart
  );
  const scheduleBars = useSelector(getWorkloadEventScheduleBars);
  const fetchedPhasesProjectIds = useSelector(
    (state) => state.phases.fetchedPhasesProjectIds
  );

  const fetchedProjectIds = useSelector(
    (state) => state.projects.fetchedProjectIds
  );
  const isFetchingProjects = useSelector(
    (state) => state.projects.isFetchingProjects
  );
  const isFetchingPhases = useSelector((state) => state.phases.fetching);
  const [start, setStart] = useState(
    workloadRangeStart || moment().startOf('week')
  );
  const myId = useSelector(getMyUserId);

  const filteredWorkloadMemberIds = useSelector((state) =>
    getFilteredMembersIds(state, staticProps[workloadViewBy])
  );
  const filteredWorkloadMemberIdsHash = useMemo(
    () => keyBy(filteredWorkloadMemberIds),
    [filteredWorkloadMemberIds]
  );
  const recipientIdsHash = useMemo(
    () => keyBy(selectedEvent?.recipient_account_ids ?? []),
    [selectedEvent]
  );

  useEffect(() => {
    const projectIds = Array.from(
      new Set(scheduleBars.map((bar) => bar.project_id))
    );
    const unloadedProjectIds = projectIds.filter(
      (projectId) => !fetchedProjectIds[projectId]
    );
    const unloadedPhaseProjectIds = projectIds.filter(
      (projectId) => !fetchedPhasesProjectIds[projectId]
    );
    if (unloadedProjectIds.length && !isFetchingProjects) {
      dispatch(
        fetchAllProjects({
          projectIds: unloadedProjectIds.slice(0, 15)
        })
      );
    }
    if (unloadedPhaseProjectIds.length && !isFetchingPhases) {
      dispatch(
        fetchPhasesByProjectIds({
          projectIds: unloadedPhaseProjectIds.slice(0, 15)
        })
      );
    }
  }, [
    dispatch,
    fetchedPhasesProjectIds,
    fetchedProjectIds,
    isFetchingPhases,
    isFetchingProjects,
    scheduleBars
  ]);

  useEffect(() => {
    if (myId) {
      dispatch(fetchActivities({}));
    }
  }, [dispatch, myId]);

  const orderedTeamMembers = useMemo(() => {
    if (isPersonal) {
      return teamMembers.filter((member) => member.account.id === myId);
    } else if (selectionDisabled || selectedEvent?.initialRecipientsOnly) {
      return teamMembers.filter(
        (member) => recipientIdsHash[member.account.id]
      );
    } else if (filteredWorkloadMemberIds?.length) {
      return teamMembers.filter(
        (member) => filteredWorkloadMemberIdsHash[member.account.id]
      );
    } else {
      return teamMembers;
    }
  }, [
    filteredWorkloadMemberIds,
    filteredWorkloadMemberIdsHash,
    isPersonal,
    myId,
    recipientIdsHash,
    selectedEvent,
    selectionDisabled,
    teamMembers
  ]);

  useEffect(() => {
    if (myId) {
      dispatch(
        fetchWorkloadPlanner({
          startDate: formatFetchDate(start),
          endDate: formatFetchDate(moment(start).add(1, 'week')),
          accountId: myId,
          team_member_ids: orderedTeamMembers.map(
            (member) => member.account.id
          ),
          all: true
        })
      );
    }
  }, [start, orderedTeamMembers, myId, dispatch]);

  return (
    <div>
      <div>
        <WorkloadEventTable
          selectedEvent={selectedEvent}
          orderedTeamMembers={orderedTeamMembers}
          selectionDisabled={selectionDisabled}
        />
      </div>
    </div>
  );
};

export default EventDetail;
