import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import Popover from 'components/Popover';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { InlineHelpIcon } from '../styles';
import { SelectDropdownProps } from 'RatesModule/components/SelectInput/SelectInput';
import {
  MenuItem,
  MenuList
} from 'SettingsModule/components/BudgetSettings/Menu';
import {
  BillableContractType,
  ContractType,
  NonBillableContractType
} from 'BudgetModule/models/BudgetViewSettings';

export const CONTRACT_TYPE_COPY: Record<
  ContractType,
  { label: string; tooltip?: string }
> = {
  [BillableContractType.FixedFee]: {
    label: 'Fixed Fee'
  },
  [BillableContractType.Hourly]: {
    label: 'Hourly'
  },
  [NonBillableContractType.Internal]: {
    label: 'Internal',
    tooltip: 'Internal projects are not billable.'
  }
} as const;

export const ContractTypeDropdown = ({
  isOpen,
  onClose,
  onSelect,
  target,
  value
}: SelectDropdownProps<ContractType> & { value?: ContractType }) => {
  const handleClose = () => {
    ReactTooltip.hide();
    onClose();
  };

  const handleItemClick = (id: ContractType) => () => {
    onSelect(id);
    handleClose();
  };

  useEffect(() => () => {
    ReactTooltip.hide();
  });
  useEffect(() => {
    if (isOpen) rebuildTooltip();
    else ReactTooltip.hide();
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      stopPropagation
      target={target}
      togglePopover={handleClose}
    >
      <MenuList>
        {Object.entries(CONTRACT_TYPE_COPY).map(
          ([itemValue, { label, tooltip }]) => (
            <MenuItem
              {...defaultTooltipProps}
              data-class="center mw-250 w-100vw"
              data-tip={tooltip}
              key={itemValue}
              onClick={handleItemClick(itemValue as ContractType)}
              selected={itemValue === value}
            >
              {label}
              {tooltip && <InlineHelpIcon />}
            </MenuItem>
          )
        )}
      </MenuList>
    </Popover>
  );
};
