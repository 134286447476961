import { Moment } from 'moment';

export const isValidDateRange = ({
  startDate,
  endDate,
  allowNull = false
}: {
  startDate: Moment | null;
  endDate: Moment | null;
  allowNull?: boolean;
}): boolean => {
  if (!startDate && !endDate) {
    return allowNull;
  } else if (startDate && !endDate) {
    return true;
  } else if (startDate && endDate && !startDate.isAfter(endDate)) {
    return true;
  }
  return false;
};
