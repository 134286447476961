import React, { useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToReport } from 'actionCreators';
import styled from 'styled-components';
import { DashboardWidgetContext } from 'appUtils/hooks/useDashboardWidget';
import theme from 'theme';

import {
  ReportsHeader,
  MainHeaderContainer,
  ChartAndButtonsContainer
} from 'ReportsModule/components/styles';
import { ToggleContainer, ToggleOption } from 'components/styles';

import UtilizationRangeOptions from 'views/projectPlanner/projectTimeline/Options/UtilizationRangeOptions';

import UtilizationSummaryWidget from 'views/Home/Widgets/Widgets/UtilizationSummaryWidget/UtilizationSummaryWidget';
import SaveFilterOption from 'components/SaveFilterOption';
import OpenFilterOption from 'components/OpenFilterOption';
import ReportFilteredTotalPill from 'components/ReportFilteredTotalPill';

import { VIEW_BY, VIEW_BY_DISPLAY, ZOOM_LEVELS } from 'appConstants/workload';
import { CUSTOM_FILTER_VALUES } from 'appConstants/filters';
import WidgetThreeDotMenu from 'DashboardModule/components/Widgets/WidgetThreeDotMenu';
import Filters from 'components/filters/Filters';
import { getTeamSlug, getSelectedTeamId } from 'selectors';
import ChartTypeOption from './ChartTypeOption';
import { CHART_TYPE } from 'ReportsModule/constants';
import { GENERATE_UTILIZATION_RANGE } from 'appUtils/projectPlannerUtils';
import { UtilizationThreeDot } from './UtilizationThreeDot';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const emptyArray = [];

const StyledToggleContainer = styled(ToggleContainer)`
  width: fit-content;
  border: 1px solid ${theme.colors.colorLightGray9};
  border-radius: 4px;
  margin: 9px 0 12px 1px;
`;
const StyledToggleOption = styled(ToggleOption)`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
  padding: 3px 8px;
  color: ${({ isActive }) =>
    isActive ? 'white' : theme.colors.colorCalendarGray};
  background: ${({ isActive }) =>
    isActive ? theme.colors.colorCalendarBlue : 'white'};
  &:hover {
    color: ${({ isActive }) =>
      isActive ? 'white' : theme.colors.colorCalendarGray};
  }
`;

/**
 * Notice that the second and third object (Team and Org) seems to have
 * conflicting information between label and value
 * This is intentional.
 * We want to see the data for Organization under Team label and vice versa.
 */
const viewByOptions = [
  {
    label: 'Member',
    value: VIEW_BY.MEMBERS
  },
  {
    label: 'Team',
    value: VIEW_BY.ORGANIZATION
  },
  {
    label: 'Org',
    value: VIEW_BY.TEAM
  }
];

const TableOptions = ({
  activeFilter,
  viewBy,
  pageName,
  isV2,
  isDashboardWidgetModal,
  widgetDetailModalThreeDotMenuItems,
  widgetConfig,
  filterStateId,
  filterSections,
  useCapacityAsDenominator,
  includeRemainingCapacity,
  handlePrint
}) => {
  const { printReportFlag } = useFeatureFlags();
  const dispatch = useDispatch();
  const teamSlug = useSelector(getTeamSlug);
  const teamId = useSelector(getSelectedTeamId);
  const { renderWidgetInfo } = useContext(DashboardWidgetContext);

  const setViewBy = (view) => {
    dispatch(
      navigateToReport({
        teamSlug,
        viewType: 'utilization',
        reportViewType: view
      })
    );
  };

  const isMembersView = viewBy === VIEW_BY.MEMBERS;

  const fetchParams = useMemo(
    () => ({
      ...GENERATE_UTILIZATION_RANGE[
        activeFilter.custom?.range !== undefined
          ? activeFilter.custom.range
          : ZOOM_LEVELS.WEEK
      ]?.({
        start: activeFilter.custom?.start_date,
        end: activeFilter.custom?.end_date
      }),
      show_pto: activeFilter.custom?.showPto,
      show_holidays: activeFilter.custom?.showHolidays,
      team_id: teamId,
      account_ids: activeFilter.account_ids || emptyArray,
      filterStateId: `${filterStateId}-widget`,
      initial: true
    }),
    [
      activeFilter.account_ids,
      activeFilter.custom?.end_date,
      activeFilter.custom.range,
      activeFilter.custom?.showHolidays,
      activeFilter.custom?.showPto,
      activeFilter.custom?.start_date,
      filterStateId,
      teamId
    ]
  );

  return (
    <MainHeaderContainer
      unsetWidths
      style={{ paddingLeft: viewBy === VIEW_BY.MEMBERS ? 29 : 12 }}
    >
      <ReportsHeader>
        <div className="row">
          <div className="title" style={{ marginRight: 15 }}>
            Utilization
          </div>
          <ReportFilteredTotalPill
            value={activeFilter.account_ids.length}
            dataType="members"
            style={{ marginRight: 12, marginTop: 2 }}
            showOnlyTotal={viewBy === VIEW_BY.TEAM}
          />
          {/* <HeaderTooltipIcon /> */}
          {!isDashboardWidgetModal && printReportFlag && (
            <UtilizationThreeDot handlePrint={handlePrint} />
          )}
          {isDashboardWidgetModal && (
            <WidgetThreeDotMenu
              menuItems={widgetDetailModalThreeDotMenuItems}
            />
          )}
          <Filters sections={filterSections} activeFilter={activeFilter} />
        </div>
        {renderWidgetInfo && renderWidgetInfo()}

        {isV2 && !isDashboardWidgetModal && (
          <StyledToggleContainer>
            {viewByOptions.map((option) => (
              <StyledToggleOption
                key={option.value}
                isActive={viewBy === option.value}
                onClick={() => setViewBy(option.value)}
              >
                {option.label}
              </StyledToggleOption>
            ))}
          </StyledToggleContainer>
        )}

        {/* view options + filters */}
        <div className="row spaced">
          <div className="row" style={{ paddingLeft: 2, gap: 18 }}>
            {(viewBy === VIEW_BY.MEMBERS ||
              (viewBy === VIEW_BY.ORGANIZATION && !isDashboardWidgetModal)) && (
              <OpenFilterOption
                dataType={VIEW_BY.MEMBERS}
                selectedItems={activeFilter.account_ids.length}
                style={{ paddingTop: 3 }}
              />
            )}
            {(viewBy === VIEW_BY.ORGANIZATION || viewBy === VIEW_BY.TEAM) &&
              !widgetConfig?.disabledOptions?.chartType && (
                <ChartTypeOption
                  activeFilter={activeFilter}
                  viewBy={viewBy}
                  pageName={pageName}
                  value={activeFilter.custom.chartType ?? CHART_TYPE.BAR}
                ></ChartTypeOption>
              )}

            <UtilizationRangeOptions
              activeFilter={activeFilter}
              viewBy={viewBy}
              value={activeFilter.custom.range ?? ZOOM_LEVELS.WEEK}
              isDisabled={
                widgetConfig?.disabledOptions?.[CUSTOM_FILTER_VALUES.range]
              }
            />

            {!(viewBy === VIEW_BY.ORGANIZATION && isDashboardWidgetModal) && (
              <SaveFilterOption
                className="utilization-save-filter"
                activeFilter={activeFilter}
                viewBy={viewBy}
                pageName={pageName}
                isDisabled={
                  activeFilter?.id === 'new' && isDashboardWidgetModal
                }
              />
            )}
          </div>
        </div>
      </ReportsHeader>
      <ChartAndButtonsContainer>
        <div
          className="widgets-container"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            // minHeight: `${isDashboardWidgetModal ? '112px' : 'unset'}`
            minHeight: '124px'
          }}
        >
          <UtilizationSummaryWidget
            activeFilter={activeFilter}
            viewBy={viewBy}
            pageName={pageName}
            isV2={isV2}
            widgetConfig={widgetConfig}
            isDashboardWidgetModal={isDashboardWidgetModal}
            // utilization widget is fetching its own data. need to be different than the one used for table
            filterStateId={
              isMembersView ? `${filterStateId}-widget` : filterStateId
            }
            // secondary is the one shared across utilization report
            {...(isMembersView && {
              secondaryFilterStateId: activeFilter.custom
                .showUtilizationByProjects
                ? filterStateId
                : undefined,
              fetchParams,
              isSelfLoading: true
            })}
            useCapacityAsDenominator={useCapacityAsDenominator}
            includeRemainingCapacity={includeRemainingCapacity}
          />
        </div>
      </ChartAndButtonsContainer>
    </MainHeaderContainer>
  );
};

export default TableOptions;
