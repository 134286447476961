import { useMemo, useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getAccountFiltersFetched } from 'selectors';

import styled from 'styled-components';
import DevProps from 'components/dev/DevProps';
import { FilterListsTable } from './FilterListsTable';
import { StackedFiltersHeader } from './StackedFiltersHeader';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { useFilterData } from 'FilterModule/hooks';
import {
  FilterListType,
  filterListTypeToField,
  FilterField
} from 'FilterModule/constants';
import { isExistenceCheckOptionHash } from 'FilterModule/filterSchemas/utils';

export type StackedFiltersVariant = 'SideFilter' | 'Standalone';

type StackedFiltersContainerProps = {
  handleClose: () => void;
  /**
   * For standalone stacked filters that have Done/Cancel buttons
   */
  handleDone?: () => void;
  headerTitle?: string;
  numStackedFiltersUsed?: number;
  /** indicates when the stacked filters are open. useful for preventing unnecessary behaviours like fetching */
  isVisible?: boolean;
  variant: StackedFiltersVariant;
  shouldUseDraft?: boolean;
  isStickyHeaderHidden?: boolean;
  handleClearAllFiltersCallback?: () => void;
  isoStateIdPrefix?: string;
  shouldShowCancelButton?: boolean;
} & Pick<
  Parameters<typeof useFilterData>[0],
  'resultsMetaData' | 'filterListTypeToFieldOverrideHash'
>;

export const StackedFiltersContainer = ({
  handleClose,
  handleDone,
  headerTitle,
  numStackedFiltersUsed,
  resultsMetaData,
  isVisible,
  filterListTypeToFieldOverrideHash,
  variant,
  shouldUseDraft = true,
  isStickyHeaderHidden = false,
  handleClearAllFiltersCallback,
  isoStateIdPrefix = 'StackedFilters',
  shouldShowCancelButton
}: StackedFiltersContainerProps) => {
  const { currentFilter, draftFilter, currentFilterSchema } =
    useFilterContext();

  const { stackedFilterOrder } = currentFilter;

  const filtersUsedSet = useMemo(
    () => new Set(stackedFilterOrder),
    [stackedFilterOrder]
  );

  const getIsFilterUsed = useCallback(
    (filterListType: FilterListType) => {
      // minor optimization - prevent filter hook behaviours when this component is not visible
      return !!isVisible && filtersUsedSet.has(filterListType);
    },
    [filtersUsedSet, isVisible]
  );

  const getFilterListTypeConfig = useCallback(
    (filterListType: FilterListType) => {
      const optionHash =
        currentFilterSchema.fields[FilterField.stackedFilterOrder]
          ?.optionsConfig?.optionHash;

      if (optionHash && !isExistenceCheckOptionHash(optionHash)) {
        const filterListTypeConfig = optionHash[filterListType];
        return filterListTypeConfig;
      }
    },
    [currentFilterSchema.fields]
  );

  const { filterValuesHash } = useFilterData({
    getIsFilterUsed,
    getFilterListTypeConfig,
    isoStateIdPrefix,
    resultsMetaData,
    filterListTypeToFieldOverrideHash,
    shouldUseDraft
  });

  const handleClearAllFilters = useCallback(() => {
    if (stackedFilterOrder) {
      const updatedFieldValues = stackedFilterOrder.reduce((acc, cur) => {
        const fieldOrFields = (filterListTypeToFieldOverrideHash ||
          (filterListTypeToField as Partial<
            Record<FilterListType, FilterField | FilterField[]>
          >))[cur];

        if (fieldOrFields) {
          const fieldsToClear = Array.isArray(fieldOrFields)
            ? fieldOrFields
            : [fieldOrFields];

          fieldsToClear.forEach((field) => {
            acc[field] = currentFilterSchema.initialValues[field];
          });
        }

        return acc;
      }, {});

      if (shouldUseDraft) {
        draftFilter.update(updatedFieldValues);
      }

      handleClearAllFiltersCallback && handleClearAllFiltersCallback();
    }
  }, [
    stackedFilterOrder,
    shouldUseDraft,
    handleClearAllFiltersCallback,
    filterListTypeToFieldOverrideHash,
    currentFilterSchema.initialValues,
    draftFilter
  ]);

  /* --------------------------------- Render --------------------------------- */

  const filtersFetched = useAppSelector(getAccountFiltersFetched);
  const isPageReady = filtersFetched;

  if (!stackedFilterOrder) return null;

  return (
    <DevProps currentFilter={currentFilter}>
      <StyledStackedFiltersContainer isHidden={isVisible === false}>
        <StackedFiltersHeader
          handleClose={handleClose}
          handleDone={handleDone}
          headerTitle={headerTitle}
          numStackedFiltersUsed={
            numStackedFiltersUsed === 999 ? 0 : numStackedFiltersUsed || 0
          }
          variant={variant}
          shouldShowCancelButton={shouldShowCancelButton}
        />
        <FilterListsTable
          isLoading={!isPageReady}
          filters={stackedFilterOrder}
          filterValuesHash={filterValuesHash}
          handleClearAllFilters={handleClearAllFilters}
          numFiltersUsed={numStackedFiltersUsed}
          variant="StackedFilters"
          resultsFilterListType={resultsMetaData?.filterListType}
          shouldResetCollapseState={!isVisible}
          isStickyHeaderHidden={isStickyHeaderHidden}
        />
      </StyledStackedFiltersContainer>
    </DevProps>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledStackedFiltersContainer = styled.div<{ isHidden?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${(props) => props.isHidden && 'visibility: hidden;'}

  transition: visibility 0.2s;
`;
