/**
 * The width of the border of the left side of the first schedule bar item.
 */
export const SCHEDULE_BAR_FIRST_CHILD_BORDER = 4;

/**
 * The width of the border of the right side of the last schedule bar item.
 */
export const SCHEDULE_BAR_LAST_CHILD_BORDER = 2;

/**
 * The width of the handle for resizing bars in timelines.
 */
export const DOUBLE_HANDLE_WIDTH = 8;

/**
 * The minimum width of the handle for resizing bars in timelines.
 */
export const HANDLE_MIN_WIDTH = 2;

/**
 * The size of Three dot menu
 */
export const MENU_SIZE = 27;
