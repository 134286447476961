import { useEffect } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getGroupsHash } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import { TableRow } from 'components/Table/types';
import {
  ProjectFilterListItem,
  BaseFilterList,
  FilterListsTableCustomRowProps
} from 'FilterModule/components/FilterListsTable/types';
import ProjectDropdown from 'views/layoutStructuralComponents/MyProjectSideBarContainer/ProjectDropdown';
import ProjectColorDot from 'components/ProjectColorDot';
import StarIcon from 'icons/StarIcon';
import { StyledFolderIcon } from 'ReportsModule/components/styles';
import FolderIcon from 'images/white-folder-icon.svg';
import { Board } from 'models/board';
import { PERSONAL_BOARD_NAMES } from 'appConstants';
import {
  Project,
  PartialProject,
  getIsPartialProject
} from 'ProjectsModule/models/project';
import { useVisibility } from 'appUtils/hooks/useVisibility';
import LoadingWheel from 'icons/LoadingWheel';

export const ProjectLabel = ({
  rowData,
  customRowProps,
  isScrolling
}: {
  rowData: TableRow<ProjectFilterListItem, BaseFilterList>;
  customRowProps: FilterListsTableCustomRowProps;
  isScrolling: boolean;
}) => {
  const { isVisible, visibilityRef } = useVisibility();

  const { loadProjects } = customRowProps;

  const boardHash: Record<number, Board> = useAppSelector(getGroupsHash);

  const { item: project, filterListType, parentList } = rowData;

  const isPartialProject = getIsPartialProject(project);

  const board = project.board_id ? boardHash[project.board_id] : undefined;

  // since partial project data is used, load the full project data when this project becomes visible
  // so it is in the real project hash
  useEffect(() => {
    if (isPartialProject && isVisible && !isScrolling) {
      loadProjects(project.id);
    }
  }, [isPartialProject, isVisible, loadProjects, project.id, isScrolling]);

  return (
    <>
      <StyledLabel
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={getTooltip({ project, boardName: board?.name })}
        data-delay-show={500}
        data-scroll-hide={true}
        data-html
        ref={visibilityRef}
      >
        <ProjectColorDot
          size={8}
          projectId={project.id}
          hasColorPicker
          pickerLocation={`${filterListType}-${parentList.id}-${project.id}`} // note: this may not be unique enough; fine for now
        />
        <ProjectInfo>
          {project.archived_at ? (
            <ProjectArchivedText>ARCHIVED</ProjectArchivedText>
          ) : (
            <ProjectNumberAndBoard>
              {project.project_number ? (
                <ProjectNumber className="no-text-overflow">
                  {project.project_number}
                </ProjectNumber>
              ) : null}
              {board && (
                <>
                  <BoardIcon
                    boardId={undefined}
                    className={undefined}
                    id={undefined}
                    color={undefined}
                  />
                  <BoardName className="no-text-overflow">
                    {board.name}
                  </BoardName>
                </>
              )}
            </ProjectNumberAndBoard>
          )}
          <ProjectTitle>{project.title}</ProjectTitle>
          {project.description && (
            <ProjectDescription>{project.description}</ProjectDescription>
          )}
        </ProjectInfo>

        <StarContainer
          isVisible={Boolean(
            project.is_starred &&
              board?.name !== PERSONAL_BOARD_NAMES['Starred Projects']
          )}
        >
          <StarIcon isActive className={undefined} style={undefined} />
        </StarContainer>
      </StyledLabel>

      <StyledDropdownContainer className="board-dropdown-container">
        {isPartialProject ? (
          <LoadingWheel width={20} height={20} />
        ) : (
          <ProjectDropdown project={project} isProjectsSidebar={undefined} />
        )}
      </StyledDropdownContainer>
    </>
  );
};

/* ------------------------------------ - ----------------------------------- */

const getTooltip = ({
  project,
  boardName
}: {
  project: Project | PartialProject;
  boardName?: string;
}) => {
  return `
    <div class="project-row-tooltip">
      <div class="top-row">
        ${
          project.project_number
            ? `<div class="project-number">${project.project_number}</div>`
            : ''
        }
        ${
          boardName
            ? `
        <img
          class="board-icon"
          src=${FolderIcon}
        />
        <div>${boardName}</div>`
            : ''
        }
      </div>
      <div
        class="project-title break-word-tooltips"
      >
        ${project.title}
      </div>
      ${
        project.description
          ? `<div
              class="project-description break-word-tooltips"
            >
              ${project.description}
            </div>`
          : ''
      }
    </div>
  `;
};

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const BoardIcon = styled(StyledFolderIcon)`
  width: 9px;
  height: 8px;
  position: relative;
  bottom: 1px;
  path {
    stroke: ${theme.colors.colorLightGray18};
    fill: transparent !important;
  }
`;

const BoardName = styled.div`
  flex: 1;
`;

const ProjectNumber = styled.div`
  max-width: 50%;
`;

const ProjectArchivedText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  top: 28px;
  left: 56px;
  font-weight: 600;
  font-size: 10px;
  left: 0;
  top: 3px;
`;

const ProjectTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray4};
  font-size: 13px;
  padding: 10px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 400;
  color: ${theme.colors.colorMediumGray9};
`;

const ProjectDescription = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 11px;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0px;
  margin-left: 12px;
  white-space: nowrap;
  margin-left: 16px;
  max-width: 250px;
  color: ${theme.colors.colorLightGray18};
  margin: 0;
  max-width: 100%;
`;

const ProjectNumberAndBoard = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  position: absolute;
  top: 0px;
  color: ${theme.colors.colorLightGray18};
  font-size: 10px;
  visibility: hidden;
  & > * {
    margin-right: 5px;
  }

  svg {
    flex-shrink: 0;
  }

  .label-cell:hover & {
    visibility: visible;
  }
`;

const ProjectInfo = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 0;
`;

const StarContainer = styled.div<{ isVisible: boolean }>`
  padding-bottom: 5px;
  padding-left: 6px;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};

  .my-star-col {
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

const StyledDropdownContainer = styled.div<{ parentRowClassName?: string }>`
  position: absolute;
  right: -3px;
  visibility: hidden;

  svg:not(.loading-wheel) {
    top: 1px !important;
  }

  .label-cell:hover & {
    visibility: visible;
  }

  .loading-wheel {
    position: relative;
    right: 5px;
    top: -2px;
  }
`;
