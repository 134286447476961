import * as constants from 'appConstants';
import * as capacityConstants from '../constants';

const { FETCH_TEAM_CAPACITY, UPDATE_TEAM_CAPACITY } = capacityConstants;

export const initialState = {
  capacity: {},
  isFetching: false
};

const capacities = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case FETCH_TEAM_CAPACITY.TRIGGER: {
      return {
        ...state,
        isFetching: true
      };
    }
    case FETCH_TEAM_CAPACITY.SUCCESS: {
      const { team_capacity } = payload.response;
      return {
        ...state,
        capacity: team_capacity,
        isFetching: false
      };
    }
    case UPDATE_TEAM_CAPACITY.SUCCESS: {
      const { team_capacity } = payload.response;
      return {
        ...state,
        capacity: team_capacity
      };
    }
    default:
      return state;
  }
};

export default capacities;
