import { createReducer, Draft } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import {
  fetchPTOPolicyPerMemberActionCreatorsMap,
  fetchPTOPoliciesActionCreatorsMap,
  createPTOPolicyActionCreatorsMap,
  updatePTOPolicyActionCreatorsMap,
  fetchPTOPolicyActionCreatorsMap,
  archivePTOPolicyActionCreatorsMap,
  fetchPTOGroupMembershipsActionCreatorsMap,
  createPTOGroupMembershipActionCreatorsMap,
  updatePTOGroupMembershipActionCreatorsMap,
  deletePTOGroupMembershipActionCreatorsMap
} from 'SettingsModule/actionCreators/settings/pto';
import { PTOGroup, PTOGroupMembership } from 'SettingsModule/models/pto';

const byPtoGroupId = (item: PTOGroup) => item.id;

export interface PTOState {
  ptoHash: Record<number, PTOGroup>;
  ptoIdsOrder: number[];
  ptoByAccountHash: Record<number, Nullable<PTOGroup>>;
  secondaryMembershipByAccountHash: Record<
    number,
    {
      regionIds: number[];
      officeIds: number[];
    }
  >;
  ptoMembershipByAccountHash: Record<number, PTOGroupMembership[]>;
}

const initialState: PTOState = {
  ptoHash: {},
  ptoIdsOrder: [],
  ptoByAccountHash: {},
  secondaryMembershipByAccountHash: {},
  ptoMembershipByAccountHash: {}
};

const handleFetchPTOPolicyPerMemberSuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof fetchPTOPolicyPerMemberActionCreatorsMap.success>
) => {
  const { response } = action.payload;

  response.forEach((item) => {
    state.ptoByAccountHash[item.account_id] = item.pto_group;
    state.secondaryMembershipByAccountHash[item.account_id] = {
      regionIds: item.region,
      officeIds: item.office
    };
  });
};

const handleFetchPTOPoliciesSuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof fetchPTOPoliciesActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.ptoHash = keyBy(response.pto_groups, byPtoGroupId);
  state.ptoIdsOrder = response.pto_groups.map(byPtoGroupId);
};

const handleFetchPTOPolicySuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof fetchPTOPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.ptoHash[response.id] = response;
};

const handleCreatePTOPolicySuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof createPTOPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.ptoHash[response.id] = response;
  state.ptoIdsOrder.push(response.id);
};

const handleUpdatePTOPolicySuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof updatePTOPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.ptoHash[response.id] = response;
};

const handleArchivePTOPolicySuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof archivePTOPolicyActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.ptoHash[response.id] = response;
};

const handleFetchPTOGroupMembershipsSuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof fetchPTOGroupMembershipsActionCreatorsMap.success>
) => {
  const { teamMembershipId } = action.payload.initialPayload;
  const { response } = action.payload;
  state.ptoMembershipByAccountHash[teamMembershipId] = [];

  const membershipHashForAccount =
    state.ptoMembershipByAccountHash[teamMembershipId];

  if (membershipHashForAccount) {
    membershipHashForAccount.push(...response);
  }
};

const handleCreatePTOGroupMembershipSuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof createPTOGroupMembershipActionCreatorsMap.success>
) => {
  const { response } = action.payload;
  state.ptoHash[response.id] = response;
};

const handleUpdatePTOGroupMembershipSuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof updatePTOGroupMembershipActionCreatorsMap.success>
) => {
  const { response } = action.payload;

  state.ptoMembershipByAccountHash[response.team_membership.id] = (
    state.ptoMembershipByAccountHash[response.team_membership.id] ?? []
  ).map((item) => (item.id === response.id ? response : item));
};

const handleDeletePTOPolicySuccess = (
  state: Draft<PTOState>,
  action: ReturnType<typeof deletePTOGroupMembershipActionCreatorsMap.success>
) => {
  const {
    initialPayload: {
      id,
      meta: { teamMembershipId }
    }
  } = action.payload;

  state.ptoMembershipByAccountHash[teamMembershipId] = (
    state.ptoMembershipByAccountHash[teamMembershipId] ?? []
  ).filter((item) => item.id === id);
};

export const ptoReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    fetchPTOPolicyPerMemberActionCreatorsMap.success,
    handleFetchPTOPolicyPerMemberSuccess
  );
  builder.addCase(
    fetchPTOPoliciesActionCreatorsMap.success,
    handleFetchPTOPoliciesSuccess
  );
  builder.addCase(
    fetchPTOPolicyActionCreatorsMap.success,
    handleFetchPTOPolicySuccess
  );
  builder.addCase(
    createPTOPolicyActionCreatorsMap.success,
    handleCreatePTOPolicySuccess
  );
  builder.addCase(
    updatePTOPolicyActionCreatorsMap.success,
    handleUpdatePTOPolicySuccess
  );
  builder.addCase(
    archivePTOPolicyActionCreatorsMap.success,
    handleArchivePTOPolicySuccess
  );
  builder.addCase(
    fetchPTOGroupMembershipsActionCreatorsMap.success,
    handleFetchPTOGroupMembershipsSuccess
  );
  builder.addCase(
    createPTOGroupMembershipActionCreatorsMap.success,
    handleCreatePTOGroupMembershipSuccess
  );
  builder.addCase(
    updatePTOGroupMembershipActionCreatorsMap.success,
    handleUpdatePTOGroupMembershipSuccess
  );
  builder.addCase(
    deletePTOGroupMembershipActionCreatorsMap.success,
    handleDeletePTOPolicySuccess
  );
});
