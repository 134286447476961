import styled from 'styled-components';
import theme from 'theme';
import { reasonsHash } from 'views/boardDisplay/SuggestionsTable/util';
import { ReasonRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const ReasonTitleCell = ({ row }: ReasonRowProps) => {
  const { original } = row;
  const { reasonId } = original;
  const reasonText = reasonsHash[reasonId];

  if (!reasonText) return <></>;

  return (
    <StyledTitleContainer>
      <StyledTextContainer>
        <StyledText> {reasonText} </StyledText>
      </StyledTextContainer>
    </StyledTitleContainer>
  );
};

export default ReasonTitleCell;

const StyledTextContainer = styled.ul`
  list-style: outside;
`;

const StyledText = styled.li`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  position: absolute;
  max-width: 65%;
`;
const StyledTitleContainer = styled.div`
  display: flex;
  margin-left: 44px;
`;
