import { createRequestTypes } from 'appUtils';

export const FETCH_REGIONS = createRequestTypes('FETCH_REGIONS');
export const CREATE_REGION = createRequestTypes('CREATE_REGION');
export const UPDATE_REGION = createRequestTypes('UPDATE_REGION');
export const DELETE_REGION = createRequestTypes('DELETE_REGION');

export const UPDATE_REGION_ENTITIES = createRequestTypes(
  'UPDATE_REGION_ENTITIES'
);
