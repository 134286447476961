import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PersonalSettingsContainer } from 'views';
import withRouterParams from './WithRouterParams';
import SettingsNavigationTracker from 'views/Navigation/SettingsNavigationTracker';

class SettingsRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    return (
      <>
        <SettingsNavigationTracker />
        <PersonalSettingsContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsRouter))
);
