import { combineReducers } from 'redux';
import makeAccounts from './integrationAccounts';
import makeActivities from './integrationActivities';
import makeClients from './integrationClients';
import makeProjects from './integrationProjects';
import makePhases from './integrationPhases';
import makeSyncLog from './integrationSyncLog';

const makeReducerMap = (integration) =>
  combineReducers({
    accounts: makeAccounts(integration),
    activities: makeActivities(integration),
    clients: makeClients(integration),
    projects: makeProjects(integration),
    phases: makePhases(integration),
    syncLog: makeSyncLog(integration)
  });

export default makeReducerMap;
