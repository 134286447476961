import styled from 'styled-components';
import theme from 'theme';
import { MemberPermissionListItem } from '../types';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { ThreeDotMenuListItem } from 'views/personalSettings/SettingTableTemplate/shared/ThreeDotMenuListItem';
import MemberIcon from 'images/member.svg';
import DeleteIcon from 'icons/DeleteIcon';
import { teamUtils } from 'TeamsModule/utils';
import { toggleRemoveOverlay, navigateToMemberModal } from 'actionCreators';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getTeamSlug } from 'selectors';
import { Sections } from 'appConstants/navigation';
import useCan from 'appUtils/hooks/useCan';
import { deleteMemberFromTeam } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { DELETE_MEMBER_FROM_TEAM_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';
import { useCallback } from 'react';
import { ReactivateMemberIcon } from 'icons/ReactivateMemberIcon';
import { ArchiveIcon } from 'icons/ArchiveIcon';

export const MembersPermissionsThreeDotCell = ({ row }) => {
  const dispatch = useAppDispatch();
  const teamSlug = useAppSelector(getTeamSlug);

  const { teamMember } = row.original as MemberPermissionListItem;

  const canDeleteFromTeam = useCan(deleteMemberFromTeam);

  const viewMemberProfile = useCallback(() => {
    if (teamSlug) {
      dispatch(
        navigateToMemberModal({
          teamSlug,
          section: Sections.MEMBERS,
          memberId: teamMember.account.id,
          memberViewType: 'profile',
          membersViewType: 'members'
        })
      );
    }
  }, [dispatch, teamMember.account.id, teamSlug]);

  const archiveMember = useCallback(() => {
    dispatch(
      toggleRemoveOverlay({
        showRemoveOverlay: true,
        accountIds: [teamMember.account.id]
      })
    );
  }, [dispatch, teamMember.account.id]);

  const reactivateMember = useCallback(() => {
    dispatch(
      toggleRemoveOverlay({
        showRemoveOverlay: true,
        removeOverlayIsUnarchive: true,
        accountIds: [teamMember.account.id]
      })
    );
  }, [dispatch, teamMember.account.id]);

  const deleteMember = useCallback(() => {
    dispatch(
      toggleRemoveOverlay({
        showRemoveOverlay: true,
        removeOverlayIsUnarchive: true,
        willHardDeleteArchivedMember: true,
        accountIds: [teamMember.account.id]
      })
    );
  }, [dispatch, teamMember.account.id]);

  return (
    <RootContainer>
      <ThreeDotMenu
        overrideIsOpen
        color={theme.colors.colorRoyalBlue}
        style={{ justifyContent: 'center' }}
      >
        <ThreeDotMenuListItem
          label="View Member Profile"
          icon={<img src={MemberIcon} />}
          onClick={viewMemberProfile}
        />
        {!teamUtils.getIsArchived(teamMember) ? (
          <ThreeDotMenuListItem
            label="Archive Member"
            icon={<ArchiveIcon width={13} height={12} />}
            onClick={archiveMember}
          />
        ) : (
          <>
            <ThreeDotMenuListItem
              label="Reactivate Member"
              icon={<ReactivateMemberIcon />}
              onClick={reactivateMember}
            />
            <ThreeDotMenuListItem
              label="Delete Member"
              icon={<DeleteIcon />}
              onClick={canDeleteFromTeam ? deleteMember : noop}
              tooltip={!canDeleteFromTeam ? DELETE_MEMBER_FROM_TEAM_TIP : ''}
            />
          </>
        )}
      </ThreeDotMenu>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
`;
