import React from 'react';
import { connect } from 'react-redux';

import Timeline, {
  DateHeader,
  TimelineHeaders,
  SidebarHeader,
  TimelineMarkers
} from 'react-calendar-timeline';
import withFindPeopleModal from 'SuggestionModule/hocs/withFindPeopleModal';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import moment from 'moment';
import TodayMarker from './projectTimeline/Markers/TodayMarker';
import {
  fetchWorkloadPlanner,
  updateWorkloadPlanner,
  createWorkloadPlanner,
  deleteWorkloadPlanner,
  openProjectPlannerModal,
  updateWorkloadPlannerMembers,
  fetchTeamMemberProfile,
  fetchMemberProjects,
  setVisibleDates,
  openWorkloadModal,
  setZoom,
  navigateToProject,
  setScheduleViewBy,
  fetchPhaseTotals,
  fetchPhases,
  fetchPhasesByProjectIds,
  openMilestoneModal,
  updatePhase,
  openAccessModal,
  closeMilestoneModal,
  setSelectedProject,
  fetchProjectById,
  toggleTaskSidebarIsOpen,
  fetchTasksV2,
  fetchTaskGroups,
  navigateToTaskModal,
  setSelectedTask,
  fetchCommentsAndMetadata,
  triggerTasksAttributesUpdate,
  navigateToSchedule,
  setCondensedZoomLevel,
  setWorkloadViewType,
  openAddMembersForm,
  handleErrorMessage
} from 'actionCreators';
import Popover from 'components/Popover';
import TaskSidebarContainer from './projectTimeline/Sidebars/TaskSidebarContainer';
import ScheduleSortOptions from './projectTimeline/Options/ScheduleSortOptions';

import MilestoneItemRenderer from './WorkloadPlannerMilestoneItemRenderer';
import TaskItemRenderer from './projectTimeline/Items/TaskItemRenderer';
import ScheduleTaskItemRenderer from './projectTimeline/Items/ScheduleTaskItemRenderer';
import WorkloadPlannerWorkCategoryItemRenderer from './WorkloadPlannerWorkCategoryItemRenderer';

import { MilestoneModal, AddMembersContainer } from 'views';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import WorkloadPlannerGroupProjectRenderer from './WorkloadPlannerGroupProjectRenderer';
import WorkloadPlannerGroupRenderer from './WorkloadPlannerGroupRenderer';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import PlannerRowRenderer from './WorkloadPlannerRowRenderer';
import Filter from './Filter';
import {
  StyledContextualMenu,
  StyledMenuItem,
  StyledDeleteIcon,
  StyledGoToProjectIcon,
  StyledPhaseMilestoneIcon,
  StyledButtonContainer,
  PlannerFilterOption,
  PlannerFilterContainer,
  StyledLeftOptions
} from './styles';
import {
  addTimezoneOffsetToDate,
  calcDayChange,
  deserializeBar,
  getVisibleTimeRange,
  getWeeksFromZoom,
  getSnappedDate,
  zoomToIntervalFormat,
  zoomToIntervalHash,
  zoomToTopIntervalFormat,
  zoomToTopIntervalHash
} from 'appUtils/projectPlannerUtils';
import { LEFT, BOTH } from 'appConstants/projectPlanner';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import {
  getPlannerMemberIds,
  getMe,
  getProjectPlannerSteps,
  getZoom,
  getTeamSlug,
  getUserTheme,
  makeGetScheduleRows,
  makeGetScheduleItems,
  getAllMilestonesHash,
  getSelectedTeamId,
  getSelectedTeamViewSettings,
  getProjectHash,
  getIsMilestoneModalOpen,
  makeGetScheduleItemIds,
  getVisibleTimeStart,
  getVisibleTimeEnd,
  getTaskSidebarProjectId,
  getHomeTaskObj,
  getIsLazyLoadingTasks,
  getAuthToken,
  getFlatPhasesAndMilestones,
  getFlatPhasesHash,
  getMatchedRouteParams,
  getActivityPhaseHash,
  getWorkloadViewType,
  getCondensedZoomLevel,
  getMyWorkPlanSettings,
  getSplitFlags,
  getSelectedProject
} from 'selectors';
import { getUtilizations } from 'UtilizationModule/selectors';
import DateNav from '../homePlanner/DateNav';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import { getTeamCapacity, getHolidayDatesHash } from 'CapacityModule/selectors';
import { fetchTeamCapacity } from 'CapacityModule/actionCreators';
import {
  CONDENSED_VIEW_ROW_HEIGHTS,
  CONDENSED_ZOOM_LEVELS,
  FILTER_PAGES,
  ITEM_TYPES,
  scheduleTimelineKeys,
  VIEW_BY_DISPLAY,
  VIEW_BY,
  VIEW_TYPE,
  ZOOM_LEVELS,
  ZOOM_STRINGS,
  ZOOM_TO_SNAP_VALUES,
  ZOOM_TO_STEP_VALUES
} from 'appConstants/workload';

import {
  openBudgetModal,
  fetchMemberBudgets,
  updateMemberBudget,
  updateActivityPhase
} from 'BudgetModule/actionCreators';
import DeleteModal from '../taskDisplay/taskUtilityComponents/DeleteModal';
import { isPhaseArchived } from 'appUtils/phaseDisplayUtils';
import { buildAccessIdentifier } from 'appUtils/access';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import noop from 'lodash/noop';
import { FilterContext } from 'FilterModule/FilterContextProvider';

import NewProjectLink from './NewProjectLink';
import { OpenSideFilterOption } from 'FilterModule/components/SideFilter';
import { GENERIC_ACTION } from 'appConstants';
import {
  EDIT_MILESTONE_DATES_TIP,
  EDIT_PHASE_DATES_TIP,
  EDIT_WORK_CATEGORY_DATES_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { editPhaseDates } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';

const emptyObj = {};
const bindToDay = (date) => (date ? date.format('YYYY-MM-DD') : null);

const FORWARD = 'forward';
const BACKWARD = 'backward';

const defaultTimeStart = getMondayOfWeek(moment());
const defaultTimeEnd = getMondayOfWeek(moment()).add(7, 'day');
const subHeaderLabelFormats = {
  dayLong: 'dd',
  dayMediumLong: 'dd',
  dayMedium: 'dd',
  dayShort: 'dd'
};

const StyledTooltip = styled(ReactTooltip)`
  font-weight: normal;
  text-align: center;
  max-width: 240px;
`;

const SidebarBackground = styled.div`
  background: white;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 100px;
  width: 250px;
  box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);
`;

const initialState = {
  contextMenuItemId: null,
  contextMenuTimestamp: null,
  contextMenuX: null,
  contextMenuY: null,
  itemTime: null,
  idToDelete: null,
  deleteModalOpen: false,
  mouseDownX: null,
  mouseDownY: null,
  isOpenAddMembersModal: false
};
const stubGroup = ({ group }) => <div>{group.id}</div>;

/**
 * Planner - Tasks tab
 */
class SchedulePlannerTimelineContainer extends React.PureComponent {
  static contextType = FilterContext;

  constructor(props) {
    super(props);
    this.state = initialState;
    this.groupRenderers = {
      [VIEW_BY.NONE]: stubGroup,
      [VIEW_BY.PROJECTS]: this.projectGroupRenderer,
      [VIEW_BY.MEMBERS]: this.memberGroupRenderer
    };
    this.itemMoveHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseMove,
      [ITEM_TYPES.TASK]: this.handleTaskMove,
      [ITEM_TYPES.WORK_CATEGORY]: this.handleWorkCategoryMove
    };
    this.itemResizeHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseResize,
      [ITEM_TYPES.TASK]: this.handleTaskResize,
      [ITEM_TYPES.WORK_CATEGORY]: this.handleWorkCategoryResize
    };
    this.itemClickHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseClick,
      [ITEM_TYPES.TASK]: this.handleTaskClick,
      [ITEM_TYPES.WORK_CATEGORY]: noop
    };
    this.itemContextMenuHandlers = {
      // [ITEM_TYPES.PHASE]: this.handlePhaseContextMenu,
      [ITEM_TYPES.TASK]: this.handleTaskContextMenu
    };
    this.itemGetters = {
      [ITEM_TYPES.PHASE]: this.getPhaseByItemId,
      [ITEM_TYPES.TASK]: this.getTaskByItemId,
      [ITEM_TYPES.WORK_CATEGORY]: this.getWorkCategoryByItemId
    };
    // different item renders depending on viewBy prop
    this.itemRenderers = {
      [ITEM_TYPES.PHASE]: this.phaseRenderer,
      [ITEM_TYPES.TASK]: this.taskRenderer,
      [ITEM_TYPES.SCHEDULE_BAR]: this.phaseRenderer,
      [ITEM_TYPES.WORK_CATEGORY]: this.workCategoryRenderer
    };
  }

  componentDidUpdate(prevProps) {
    rebuildTooltip();
    const { activeFilter, setCondensedZoomLevel, setWorkloadViewType } =
      this.props;
    if (prevProps.viewBy !== this.props.viewBy) {
      // eslint-disable-next-line no-unused-expressions
      window.dispatchEvent?.(new Event('resize'));
    }
    if (prevProps.activeFilter?.custom?.zoom !== activeFilter?.custom?.zoom) {
      const zoom =
        activeFilter?.custom?.zoom || activeFilter?.custom?.zoom === 0
          ? activeFilter.custom.zoom
          : 66;
      this.setZoom(zoom);
    }
    if (
      prevProps.activeFilter?.custom?.condensedZoomLevel !==
      activeFilter?.custom?.condensedZoomLevel
    ) {
      setCondensedZoomLevel({
        condensedZoomLevel: activeFilter?.custom?.condensedZoomLevel
      });
    }
    if (
      prevProps.activeFilter?.custom?.workloadViewType !==
      activeFilter?.custom?.workloadViewType
    ) {
      setWorkloadViewType({
        viewType: activeFilter?.custom?.workloadViewType || VIEW_TYPE.NORMAL
      });
    }
  }

  componentDidMount() {
    rebuildTooltip();
    const { activeFilter, setCondensedZoomLevel, setWorkloadViewType } =
      this.props;
    if (activeFilter) {
      const zoom =
        activeFilter?.custom?.zoom || activeFilter?.custom?.zoom === 0
          ? activeFilter.custom.zoom
          : 66;
      this.setZoom(zoom);
      setCondensedZoomLevel({
        condensedZoomLevel: activeFilter?.custom?.condensedZoomLevel
      });
      setWorkloadViewType({
        viewType: activeFilter?.custom?.workloadViewType || VIEW_TYPE.NORMAL
      });
    }
  }

  handleItemMove = (serializedId, calendarTime, newGroupId) => {
    ReactTooltip.hide();

    const { itemType, itemId } = deserializeBar(serializedId);
    const handleMove = this.itemMoveHandlers[itemType];
    handleMove(itemId, calendarTime, newGroupId);
    this.setState({ itemTime: null });
  };

  handleItemResize = (serializedId, calendarTime, edge) => {
    ReactTooltip.hide();

    const { itemType, itemId } = deserializeBar(serializedId);
    const handleResize = this.itemResizeHandlers[itemType];
    handleResize(itemId, calendarTime, edge);
    this.setState({ itemTime: null });
  };

  handleItemClick = (serializedId, e, time) => {
    const adjustedTime = addTimezoneOffsetToDate(time);
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleClick = this.itemClickHandlers[itemType];
    handleClick(itemId, e, adjustedTime);
  };

  handleItemDrag = (itemDragObject) => {
    this.setState({ itemTime: itemDragObject.time });
    ReactTooltip.show(this.startDateRef);
    ReactTooltip.show(this.endDateRef);
  };

  handleWorkCategoryResize = (itemId, calendarTime, edge) => {
    const { updateActivityPhase } = this.props;
    const activityPhase = this.getWorkCategoryByItemId(itemId);

    if (
      !this.checkEditPhaseDatesPermission({
        projectId: activityPhase.project_id,
        errorMessage: EDIT_WORK_CATEGORY_DATES_TIP
      })
    )
      return;

    const propertyToUpdate = edge === LEFT ? 'startDate' : 'endDate';
    const propertyToPreserve = edge === LEFT ? 'endDate' : 'startDate';
    const buffer = edge === LEFT ? 1 : -1;
    const body = {
      id: activityPhase.id,
      projectId: activityPhase.project_id,
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]:
        activityPhase[
          propertyToPreserve === 'startDate' ? 'start_date' : 'end_date'
        ]
    };
    if (moment(body.endDate).isBefore(moment(body.startDate))) {
      return;
    }

    updateActivityPhase({
      id: activityPhase.id,
      projectId: activityPhase.project_id,
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]: activityPhase[propertyToPreserve]
    });
  };

  handleWorkCategoryMove = (itemId, calendarTime) => {
    const { updateActivityPhase } = this.props;
    const activityPhase = this.getWorkCategoryByItemId(itemId);

    if (
      !this.checkEditPhaseDatesPermission({
        projectId: activityPhase.project_id,
        errorMessage: EDIT_WORK_CATEGORY_DATES_TIP
      })
    )
      return;

    const diffInDays = calcDayChange(
      bindToDay(moment(calendarTime)),
      activityPhase.start_date
    );
    const newStartDate = moment(activityPhase.start_date).add(
      diffInDays,
      'days'
    );

    const newEndDate = moment(
      activityPhase.end_date || moment(activityPhase.start_date).add(2, 'days')
    ).add(diffInDays, 'days');

    updateActivityPhase({
      id: activityPhase.id,
      projectId: activityPhase.project_id,
      startDate: moment(newStartDate).format('YYYY-MM-DD'),
      endDate: moment(newEndDate).format('YYYY-MM-DD')
    });
  };

  onItemContextMenu = (serializedId, e, time) => {
    const adjustedTime = addTimezoneOffsetToDate(time);
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleContextMenu = this.itemContextMenuHandlers[itemType];
    if (handleContextMenu) {
      handleContextMenu(itemId, e, adjustedTime);
    }
  };

  getPermissions = () => {
    const { teamId } = this.props;
    return {
      teamId
    };
  };

  getPhaseByItemId = (itemId) =>
    this.props.allPhasesAndMilestones.find((p) => p.id === +itemId);

  getTaskByItemId = (itemId) => this.props.taskHash[itemId];
  getGroupRenderer = () => this.groupRenderers[this.props.viewBy];
  getWorkCategoryByItemId = (itemId) => this.props.activityPhaseHash[itemId];

  // schedule bar handlers

  // only use in onMouseDown to block clicks that are far enough away from mouse down to be considered drag attempts
  trackClickStart = (e) => {
    this.setState({ mouseDownX: e.clientX, mouseDownY: e.clientY });
  };

  resetClickStart = () => this.setState({ mouseDownX: null, mouseDownY: null });

  isDragAttempt = (e) => {
    const { mouseDownX, mouseDownY } = this.state;
    const { clientX, clientY } = e;
    const isDragAttempt =
      mouseDownX &&
      mouseDownY &&
      (Math.abs(mouseDownX - clientX) > 5 ||
        Math.abs(mouseDownY - clientY) > 5);

    return isDragAttempt;
  };

  checkEditPhaseDatesPermission = ({ projectId, errorMessage }) => {
    const {
      checkPermission,
      editPhaseDates,
      teamId,
      handleErrorMessage,
      updatePhase
    } = this.props;

    const canEditPhase = checkPermission(updatePhase, {
      permissions: {
        projectId,
        teamId
      }
    });

    const canEditPhaseDates =
      canEditPhase ||
      checkPermission(editPhaseDates, {
        permissions: {
          projectId,
          teamId
        }
      });

    if (!canEditPhaseDates) {
      handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage
      });
    }
    return canEditPhaseDates;
  };

  // phase/milestone handlers
  handlePhaseMove = (itemId, calendarTime) => {
    const { updatePhase, zoom } = this.props;
    const phase = this.getPhaseByItemId(itemId);

    if (
      !phase ||
      isPhaseArchived(phase) ||
      !this.checkEditPhaseDatesPermission({
        projectId: phase.project_id,
        errorMessage: !phase.is_budget
          ? EDIT_MILESTONE_DATES_TIP
          : EDIT_PHASE_DATES_TIP
      })
    ) {
      return;
    }

    const adjustedCalendarTimeForSnap =
      ZOOM_TO_STEP_VALUES[zoom] !== ZOOM_STRINGS.DAY
        ? moment(calendarTime).startOf('week').valueOf()
        : calendarTime;

    const diffInDays = calcDayChange(
      adjustedCalendarTimeForSnap,
      phase.start_date
    );
    const newStartDate = moment(phase.start_date)
      .add(diffInDays, 'days')
      .format('MM/DD/YYYY');

    const newEndDate = moment(phase.end_date)
      .add(diffInDays, 'days')
      .format('MM/DD/YYYY');

    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      startDate: newStartDate,
      endDate: newEndDate,
      name: phase.name
    });
  };

  handleTaskMove = (itemId, calendarTime, newGroupId) => {
    const { triggerTasksAttributesUpdate, token } = this.props;
    const task = this.getTaskByItemId(itemId);
    const { itemGroupKey } = this.getKeys();
    const oldGroupId = task[itemGroupKey];
    const diffInDays = calcDayChange(
      bindToDay(moment(calendarTime)),
      task.schedule_start
    );
    const newStartDate = moment(task.schedule_start).add(diffInDays, 'days');

    const newEndDate = moment(
      task.schedule_end || moment(task.schedule_start).add(2, 'days')
    ).add(diffInDays, 'days');

    const permissions = this.getPermissions();

    const body = {
      task_ids: [task.id],
      schedule_start: newStartDate,
      schedule_end: newEndDate
    };
    if (+oldGroupId !== +newGroupId) {
      body[itemGroupKey] = +newGroupId;
    }
    triggerTasksAttributesUpdate({ token, body, permissions });
  };

  handlePhaseResize = (itemId, calendarTime, edge) => {
    const { updatePhase } = this.props;
    const phase = this.getPhaseByItemId(itemId);
    if (
      !phase ||
      isPhaseArchived(phase) ||
      !this.checkEditPhaseDatesPermission({
        projectId: phase.project_id,
        errorMessage: !phase.is_budget
          ? EDIT_MILESTONE_DATES_TIP
          : EDIT_PHASE_DATES_TIP
      })
    ) {
      return;
    }

    const propertyToUpdate = edge === LEFT ? 'startDate' : 'endDate';
    const buffer = edge === LEFT ? 1 : -1;

    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      startDate: phase.start_date,
      endDate: phase.end_date,
      name: phase.name,
      [propertyToUpdate]: moment(calendarTime)
        .add(buffer, 'minute')
        .format('MM/DD/YYYY')
    });
  };

  handleTaskResize = (itemId, calendarTime, edge) => {
    const { triggerTasksAttributesUpdate, token } = this.props;
    const task = this.getTaskByItemId(itemId);
    const propertyToUpdate = edge === LEFT ? 'schedule_start' : 'schedule_end';
    const propertyToPreserve =
      edge === LEFT ? 'schedule_end' : 'schedule_start';
    const buffer = edge === LEFT ? 1 : -1;
    const body = {
      task_ids: [task.id],
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]: task[propertyToPreserve]
    };
    if (moment(body.schedule_end).isBefore(moment(body.schedule_start))) {
      return;
    }

    const permissions = this.getPermissions();
    triggerTasksAttributesUpdate({
      token,
      body,
      permissions
    });
  };

  handlePhaseClick = (itemId, e) => {
    if (this.isDragAttempt(e)) {
      this.resetClickStart();
    }
    const phase = this.getPhaseByItemId(itemId);
    if (phase?.project_id) {
      this.handleOpenPhaseModal(phase.project_id);
    }
  };

  handleTaskClick = (itemId) => {
    const {
      fetchTaskGroups,
      navigateToTaskModal,
      setSelectedTask,
      fetchCommentsAndMetadata
    } = this.props;
    const task = this.getTaskByItemId(itemId);
    fetchTaskGroups({ taskGroupIds: [task.task_group_id] });
    navigateToTaskModal({
      taskId: task.id
    });
    setSelectedTask(task.id);
    fetchCommentsAndMetadata({
      taskId: task.id,
      taskType: 'projects',
      offset: 0,
      limit: 4
    });
  };

  handleOpenPhaseModal = (projectId) => {
    const {
      fetchPhases,
      fetchPhasesByProjectIds,
      openMilestoneModal,
      setSelectedProject,
      fetchProjectById
    } = this.props;
    fetchProjectById(projectId);
    setSelectedProject({ projectId });
    openMilestoneModal();
    fetchPhases({ projectId });
    fetchPhasesByProjectIds({ projectIds: [projectId] });
  };

  handleOpenUnscheduledTasks = (projectId) => {
    const { toggleTaskSidebarIsOpen, taskSidebarProjectId } = this.props;
    const newProjectId = projectId === taskSidebarProjectId ? null : projectId;
    const isOpen = projectId !== taskSidebarProjectId;
    toggleTaskSidebarIsOpen({ projectId: newProjectId, isOpen });
  };

  // timeline renderers

  projectGroupRenderer = ({ group }) => {
    const { workloadViewType, condensedZoomLevel } = this.props;
    if (!group.shouldRenderRow) {
      return null;
    }
    return (
      <WorkloadPlannerGroupProjectRenderer
        group={group}
        key={group.id}
        isOnTeamSchedules
        viewPhasesClick={this.handleOpenPhaseModal}
        workloadViewType={workloadViewType}
        condensedZoomLevel={condensedZoomLevel}
        handleOpenAddMembersForm={this.handleOpenAddMembersForm}
      />
    );
  };

  memberGroupRenderer = ({ group }) => {
    if (!group.shouldRenderRow || group.isSummary) {
      return null;
    }
    return (
      <WorkloadPlannerGroupRenderer
        group={group}
        key={group.id}
        isOnTeamSchedules
        viewPhasesClick={this.handleOpenPhaseModal}
        isOnScheduleView
      />
    );
  };

  /*
    - itemRenderer cannot be determined by props.viewBy because multiple items types exist per view
    - item is only guaranteed to rerender if input parameters here change. It is not aware of prop changes to WorkloadPlannerTimelineContainer due to react-calendar-timeline performance strategies that ignore updates.
    */
  itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps
  }) => {
    const { zoom, userTheme, me, workloadViewType, condensedZoomLevel } =
      this.props;
    const ItemRenderer = this.itemRenderers[deserializeBar(item.id)?.itemType];

    return (
      <ItemRenderer
        item={item}
        zoom={zoom}
        timelineContext={timelineContext}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        userTheme={userTheme}
        time={this.state.itemTime}
        startDateRef={(ref) => (this.startDateRef = ref)}
        endDateRef={(ref) => (this.endDateRef = ref)}
        me={me}
        isCondensedView={workloadViewType === VIEW_TYPE.CONDENSED}
        condensedZoomLevel={condensedZoomLevel}
        isOnTeamSchedules
      />
    );
  };

  phaseRenderer = (props) => {
    const { viewBy } = this.props;
    return (
      <MilestoneItemRenderer
        {...props}
        showProjectTitle={viewBy !== VIEW_BY.PROJECTS} // show project information instead of imply project information
      />
    );
  };

  taskRenderer = (props) => {
    const { viewBy, workloadViewType } = this.props;
    if (
      viewBy === VIEW_BY.PROJECTS ||
      workloadViewType === VIEW_TYPE.CONDENSED
    ) {
      return <TaskItemRenderer {...props} viewBy={viewBy} />;
    } else {
      return <ScheduleTaskItemRenderer {...props} />;
    }
  };

  workCategoryRenderer = (props) => {
    return <WorkloadPlannerWorkCategoryItemRenderer {...props} />;
  };

  groupRenderer = ({ group }) => {
    const { viewBy, activeFilter } = this.props;
    if (!group.isNewProjectLink) {
      const GroupRenderer = this.groupRenderers[viewBy];
      return <GroupRenderer group={group} key={group.id} isOnTeamSchedules />;
    } else if (viewBy === VIEW_BY.PROJECTS) {
      return <NewProjectLink activeFilter={activeFilter} />;
    } else {
      // we shouldnt get here but prevents 'no component rendered' crash if some future change makes us get here
      return null;
    }
  };

  renderButton = (phasesLength, isPhaseDefault, projectTitle) => {
    const { workloadViewType, condensedZoomLevel } = this.props;
    const phaseText = isPhaseDefault
      ? 'Phases'
      : phasesLength === 1
      ? '1 Phase'
      : `${phasesLength} Phases`;
    return workloadViewType === VIEW_TYPE.CONDENSED &&
      condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL ? (
      <> </>
    ) : (
      <StyledButtonContainer data-testid={`phases-button ${projectTitle}`}>
        {isPhaseDefault && '+'}
        <StyledPhaseMilestoneIcon
          strokeColor="#0074d9"
          height="12"
          width="12"
          fillColor="transparent"
          marginLeft={isPhaseDefault ? '4px' : undefined}
        />
        {phaseText}
      </StyledButtonContainer>
    );
  };

  rowRenderer = ({ getLayerRootProps, group }) => {
    const {
      steps,
      teamCapacity,
      zoom,
      viewBy,
      holidayDatesHash,
      setInView,
      phaseHash
    } = this.props;

    if (!group.shouldRenderRow) {
      return null;
    }
    const capacity = teamCapacity;
    const phasesLength = group?.phases?.length;
    const phaseToEvaluate = phaseHash[group?.phases?.[0]];
    const isPhaseDefault =
      phasesLength === 1 &&
      phaseToEvaluate?.is_default_phase &&
      !phaseToEvaluate?.is_like_default;

    return (
      <PlannerRowRenderer
        utilizations={emptyObj}
        capacity={capacity}
        steps={steps}
        getLayerRootProps={getLayerRootProps}
        group={group}
        zoom={zoom}
        onCanvasClick={this.onCanvasClick}
        onRootCanvasClick={this.onRootCanvasClick}
        keys={this.getKeys()}
        workloadViewBy={viewBy}
        holidayDatesHash={holidayDatesHash}
        rowActionText={'View Phases'}
        renderButton={this.renderButton}
        phasesLength={phasesLength}
        isOnTeamSchedules
        handleRowSecondClick={this.handleOpenUnscheduledTasks}
        setInView={setInView}
        isPhaseDefault={isPhaseDefault}
        hasDroppableLayer
      />
    );
  };

  topIntervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const { interval } = intervalContext;
    const { zoom } = this.props;
    const formatInterval = zoomToTopIntervalFormat[zoom];
    return (
      <div {...getIntervalProps()} onClick={noop} className="top-interval">
        <div className="find-me" />
        <div
          className={cn('styled-header-date-container', {
            showBorder: zoom === ZOOM_LEVELS.DAY
          })}
        >
          {formatInterval(interval.startTime)}
        </div>
      </div>
    );
  };

  checkToday = (date) => {
    const { zoom } = this.props;
    const stepValue = zoomToIntervalHash[zoom];
    return moment(date).range(stepValue).contains(moment());
  };

  intervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const { interval } = intervalContext;
    const { zoom } = this.props;
    const formatInterval = zoomToIntervalFormat[zoom];
    const stepValue = zoomToIntervalHash[zoom];

    return (
      <div {...getIntervalProps()} onClick={noop}>
        <div className="find-me" />
        <div
          className={cn('styled-header-day-container', {
            today: this.checkToday(interval.startTime),
            left: zoom === ZOOM_LEVELS.DAY || zoom === ZOOM_LEVELS.WEEK,
            singleDay: stepValue === ZOOM_STRINGS.DAY
          })}
        >
          {formatInterval(interval.startTime)}
        </div>
      </div>
    );
  };

  getWidgetConfig = () => {
    const { splitFlags } = this.props;
    const { workloadSelectionLimitFlag } = splitFlags;
    if (workloadSelectionLimitFlag) {
      return {
        limits: {
          project_ids: 50
        }
      };
    }
    return {};
  };

  renderLeftSidebarHeader = ({ getRootProps, data }) => {
    const { viewBy, workloadSettings, splitFlags } = this.props;
    const { isRightSideFilterEnabled, crossFieldFilterOnPlannerFlag } =
      splitFlags;

    const { currentFilter, currentFilterSchema, draftFilter } =
      this.context || {};

    return (
      <div {...getRootProps()}>
        {viewBy !== VIEW_BY.NONE && (
          <>
            <ScheduleSortOptions />

            <div
              className="left-sidebar-toggle-container schedule-view"
              style={{ marginTop: '-37px' }}
            >
              <Filter
                viewBy={viewBy}
                pageName={FILTER_PAGES.WORKLOAD_PLANNER}
                filterListId="workload"
                filterWidth={250}
                filterStyling={
                  'padding-left: 11px; border:none; margin-top: -30px; height: 20px; flex:1;'
                }
                filterContainerClass={
                  isRightSideFilterEnabled &&
                  workloadSettings?.project_selector_positions === 'right'
                    ? 'workload-planner-filter'
                    : undefined
                }
                crossFieldDependencies={
                  crossFieldFilterOnPlannerFlag && viewBy === VIEW_BY.PROJECTS
                    ? currentFilter?.stackedFilterOrder
                    : undefined
                }
                widgetConfig={this.getWidgetConfig()}
                currentFilterSchema={currentFilterSchema}
                draftFilter={draftFilter}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  openDeleteModal = (idToDelete) =>
    this.setState({
      idToDelete,
      deleteModalOpen: true,
      contextMenuItemId: null,
      contextMenuTimestamp: null,
      contextMenuX: null,
      contextMenuY: null
    });

  closeDeleteModal = () =>
    this.setState({ idToDelete: null, deleteModalOpen: false });

  handleDeleteConfirm = () => {
    const { idToDelete } = this.state;
    const { deleteWorkloadPlanner } = this.props;
    const bar = this.getScheduleBarByItemId(idToDelete);
    deleteWorkloadPlanner(bar);
    this.setState({ idToDelete: null, deleteModalOpen: false });
  };

  renderContextMenu = () => {
    const { contextMenuX, contextMenuY, contextMenuItemId } = this.state;
    const { navigateToProject, teamSlug } = this.props;
    const item = {};
    const project = {};
    return contextMenuItemId && item ? (
      <Popover
        isOpen={true}
        closePopover={() => this.setState(initialState)}
        target={document.body}
        placement="top-start"
        offset={`${contextMenuX} ${contextMenuY}`}
      >
        <StyledContextualMenu style={{ top: contextMenuY }}>
          <StyledMenuItem
            onClick={() => {
              navigateToProject({
                teamSlug,
                projectSlug: project?.slug ?? 'project',
                projectId: item.project_id
              });
            }}
          >
            <StyledGoToProjectIcon />
            Go To Project
          </StyledMenuItem>
          <StyledMenuItem onClick={() => this.openDeleteModal(item.id)}>
            <StyledDeleteIcon />
            Delete {/* name of item type */}
          </StyledMenuItem>
        </StyledContextualMenu>
      </Popover>
    ) : null;
  };

  // scroll / range handling
  handleTimelineScroll = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    this.props.setVisibleDates({
      visibleTimeStart: moment(visibleTimeStart),
      visibleTimeEnd: moment(visibleTimeEnd),
      plannerType: this.props.plannerType
    });
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    // fixes issue where header and scroll body don't match up (can happen after scrolling fast horizontally)
    const scrollRefScrollLeft = this.scrollRef?.scrollLeft;
    const headerRefScrollLeft = this.headerRef?.scrollLeft;
    if (
      this.scrollRef &&
      this.headerRef &&
      scrollRefScrollLeft !== headerRefScrollLeft
    ) {
      this.scrollRef.scrollLeft = headerRefScrollLeft;
    }
  };

  isTodayOnScreen = () => {
    const { visibleTimeStart, visibleTimeEnd } = this.props;
    const today = moment();
    return (
      today.isAfter(visibleTimeStart, 'd') &&
      today.isBefore(visibleTimeEnd, 'd')
    );
  };

  scrollPlannerHalfTimelineWidth = (direction) => {
    const timeline = this.timelineContainer.current;
    timeline.classList.add('scroll-transition');
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(
      () => timeline.classList.remove('scroll-transition'),
      1500
    );

    const { visibleTimeStart, visibleTimeEnd, setVisibleDates, zoom } =
      this.props;

    const weeks = getWeeksFromZoom(zoom) / 2;
    const operation = direction === FORWARD ? 'add' : 'subtract';

    if (zoom === ZOOM_LEVELS.DAY) {
      setVisibleDates({
        visibleTimeStart: visibleTimeStart.clone()[operation](2, 'day'), // +- 2 days on Day zoom level
        visibleTimeEnd: visibleTimeEnd.clone()[operation](2, 'day'),
        plannerType: this.props.plannerType
      });
      return;
    }

    setVisibleDates({
      visibleTimeStart: visibleTimeStart.clone()[operation](weeks, 'week'),
      visibleTimeEnd: visibleTimeEnd.clone()[operation](weeks, 'week'),
      plannerType: this.props.plannerType
    });
  };

  scrollToToday = () => {
    const { zoom, setVisibleDates } = this.props;

    this.timelineContainer.current.classList.remove('scroll-transition');
    const today = moment().startOf('day');

    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart: today
    });

    setVisibleDates({
      visibleTimeStart,
      visibleTimeEnd,
      plannerType: this.props.plannerType
    });
  };

  headerRange = () => getWeeksFromZoom(this.props.zoom) / 2;

  setZoom = (zoom) => {
    const {
      visibleTimeStart: timeStart,
      setVisibleDates,
      setZoom,
      plannerType
    } = this.props;
    setZoom({ zoom, plannerType });
    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart
    });

    setVisibleDates({
      visibleTimeStart,
      visibleTimeEnd,
      plannerType
    });
  };

  validateMoveResize = (...props) => getSnappedDate(this.props.zoom, ...props);

  getGroupFromGroupId = (groupId) => {
    const { plannerRows } = this.props;

    const { groupIdKey } = this.getKeys();
    const group = plannerRows.find((row) => row[groupIdKey] === groupId);

    return group;
  };

  onCanvasClick = (groupId, time, e) => {
    if (this.isDragAttempt(e)) {
      this.resetClickStart();
      return;
    }
    e.stopPropagation();

    const group = this.getGroupFromGroupId(groupId);

    if (group?.project_id) {
      return this.handleOpenPhaseModal(group.project_id);
    }
  };

  handleOpenAddMembersForm = (group) => {
    const { openAddMembersForm } = this.props;
    const projectId = group.project_id;
    const boardId = group.board_id;
    openAddMembersForm(
      MODAL_TYPE.PROJECT,
      {
        id: projectId,
        board_id: boardId
      },
      'project-menu-item-container'
    );
    this.setState({
      isOpenAddMembersModal: true
    });
  };

  onRootCanvasClick = (groupId, time, e) => {
    if (this.isDragAttempt(e)) {
      this.resetClickStart();
      return;
    }
    e.stopPropagation();

    // disabling rootCanvas click effects, will permanently remove when that decision is permanent.

    // const { viewBy } = this.props;
    // if (viewBy === VIEW_BY.PROJECTS) {
    //   this.handleOpenPhaseModal(groupId);
    // } else {
    //   this.onCanvasClick(groupId, time, e);
    // }
  };

  setScrollRef = (ref) => (this.scrollRef = ref);
  setHeaderRef = (ref) => (this.headerRef = ref);

  timelineContainer = React.createRef();

  getKeys = () => scheduleTimelineKeys[this.props.viewBy];

  changePlannerTabs = (viewType) => {
    const { teamSlug, navigateToSchedule } = this.props;
    navigateToSchedule({
      teamSlug,
      plannerViewType: viewType
    });
  };

  buildAccessIdentifier = () => {
    const { teamId } = this.props;
    return buildAccessIdentifier({
      actableType: 'Team',
      actableId: teamId,
      actionType: 'activity_phase_schedule_bars_other'
    });
  };

  onAccessClick = () => {
    const { openAccessModal } = this.props;
    openAccessModal({
      modalIdentifier: this.buildAccessIdentifier()
    });
  };

  render() {
    const {
      handleLazyLoad,
      selectedItemIds,
      visibleTimeStart,
      visibleTimeEnd,
      utilizations,
      zoom,
      isOnWorkloadView,
      viewBy,
      plannerRows,
      plannerItems,
      isMilestoneModalOpen,
      closeMilestoneModal,
      matchedParams,
      workloadViewType,
      condensedZoomLevel,
      activeFilter,
      selectedProject,
      FindPeopleModal,
      handleOpenFindPeopleModal
    } = this.props;
    const { deleteModalOpen, contextMenuItemId, isOpenAddMembersModal } =
      this.state;

    const preventZoom = visibleTimeEnd.valueOf() - visibleTimeStart.valueOf();

    return (
      <div
        className={cn(
          'timeline-container project-planner-timeline-container schedule-timeline-container view-hours-default',
          {
            'workload-view': isOnWorkloadView,
            'quarterly-view': zoomToTopIntervalHash[zoom] === 'month'
          }
        )}
        onMouseDown={this.trackClickStart}
        ref={this.timelineContainer}
      >
        {contextMenuItemId && this.renderContextMenu()}
        {viewBy !== VIEW_BY.NONE && <SidebarBackground />}
        <TaskSidebarContainer />
        <StyledTooltip id="task-bar" place="top" multiline={true} />
        <DateNav
          scrollBack={() => this.scrollPlannerHalfTimelineWidth(BACKWARD)}
          scrollForward={() => this.scrollPlannerHalfTimelineWidth(FORWARD)}
          scrollToToday={() => this.scrollToToday()}
          header={[visibleTimeStart, visibleTimeEnd]}
          isTodayOnScreen={this.isTodayOnScreen()}
          showZoom
          zoom={zoom}
          SHOW_DEMO_VIEWERS_COMPONENT
          setZoom={this.setZoom}
          isOnScheduleView
          accessIdentifier={this.buildAccessIdentifier()}
          onAccessClick={this.onAccessClick}
          filter={activeFilter}
          condensedZoomLevel={condensedZoomLevel}
          workloadViewType={workloadViewType}
          handleOpenFindPeopleModal={handleOpenFindPeopleModal}
        >
          {/* {viewBy === VIEW_BY.NONE && (
            <Filter
              viewBy={viewBy}
              pageName={FILTER_PAGES.WORKLOAD_PLANNER}
              filterLimits={FILTER_LIMITS}
              filterStyling={filterStyling}
              filterContainerClass={
                isRightSideFilterEnabled ? 'workload-planner-filter' : undefined
              }
            />
          )} */}
          <StyledLeftOptions>
            <PlannerFilterContainer>
              <PlannerFilterOption
                onClick={() => this.changePlannerTabs(VIEW_BY.WORK_PLANS)}
                isSelected={
                  matchedParams?.plannerViewType === VIEW_BY.WORK_PLANS
                }
                data-testid="workplans-tab"
              >
                {VIEW_BY_DISPLAY[VIEW_BY.WORK_PLANS].label}
              </PlannerFilterOption>
              <PlannerFilterOption
                onClick={() => this.changePlannerTabs(VIEW_BY.TASKS)}
                isSelected={matchedParams?.plannerViewType === VIEW_BY.TASKS}
                data-testid="tasks-tab"
              >
                {VIEW_BY_DISPLAY[VIEW_BY.TASKS].label}
              </PlannerFilterOption>
            </PlannerFilterContainer>
          </StyledLeftOptions>
        </DateNav>
        {/* {loading && (
          <div style={{ position: 'absolute', zIndex: 100, left: 0 }}>
            <LoadingWheel
              height={20}
              width={20}
              fill={userTheme.theme.colors.colorRoyalBlue}
            />
          </div>
        )} */}
        <Timeline
          resizeDetector={containerResizeDetector}
          groups={[...plannerRows, { isNewProjectLink: true }]}
          items={plannerItems}
          selected={selectedItemIds}
          itemRenderer={this.itemRenderer}
          groupRenderer={this.groupRenderer}
          dragSnap={60 * 60 * 1000 * 24 * ZOOM_TO_SNAP_VALUES[zoom]}
          canChangeGroup={false}
          stackItems={true}
          keys={this.getKeys()}
          lineHeight={
            workloadViewType === VIEW_TYPE.NORMAL
              ? 74
              : CONDENSED_VIEW_ROW_HEIGHTS[condensedZoomLevel]
          }
          itemHeightRatio={44 / 74}
          defaultTimeStart={defaultTimeStart}
          defaultTimeEnd={defaultTimeEnd}
          visibleTimeStart={visibleTimeStart.valueOf()}
          visibleTimeEnd={visibleTimeEnd.valueOf()}
          onTimeChange={this.handleTimelineScroll}
          minZoom={preventZoom}
          maxZoom={preventZoom}
          subHeaderLabelFormats={subHeaderLabelFormats}
          onBoundsChange={handleLazyLoad}
          onCanvasClick={this.onCanvasClick}
          onItemClick={this.handleItemClick}
          onItemSelect={this.handleItemClick}
          onItemMove={this.handleItemMove}
          onItemResize={this.handleItemResize}
          onItemDrag={this.handleItemDrag}
          canResize={BOTH}
          useResizeHandle
          infoLabel={false}
          scrollRef={this.setScrollRef}
          headerRef={this.setHeaderRef}
          rowRenderer={this.rowRenderer}
          rowData={utilizations}
          moveResizeValidator={this.validateMoveResize}
          onItemContextMenu={this.onItemContextMenu}
          sidebarWidth={viewBy === VIEW_BY.NONE ? 0 : 250}
          clickTolerance={5}
          key={
            condensedZoomLevel + workloadViewType
          } /* This is necessary for condensed views to rerender properly until we find a better way */
        >
          <TimelineMarkers>
            <TodayMarker />
          </TimelineMarkers>
          <TimelineHeaders>
            <SidebarHeader>{this.renderLeftSidebarHeader}</SidebarHeader>
            <DateHeader
              height={35}
              unit={zoomToTopIntervalHash[zoom]}
              intervalRenderer={this.topIntervalRenderer}
            />
            <DateHeader
              height={29}
              unit={zoomToIntervalHash[zoom]}
              intervalRenderer={this.intervalRenderer}
            />
          </TimelineHeaders>
        </Timeline>
        <MilestoneModal
          isOpen={isMilestoneModalOpen}
          toggle={closeMilestoneModal}
        />
        <DeleteModal
          isOpen={deleteModalOpen}
          toggle={this.closeDeleteModal}
          deleteOnClick={this.handleDeleteConfirm}
          component={'Work Plan'}
        />
        <StyledTooltip
          effect="solid"
          id="schedule-bar"
          place="top"
          multiline={true}
        />
        <StyledTooltip
          effect="solid"
          id="start-date-tooltip"
          place="top"
          multiline={true}
        />
        <StyledTooltip
          effect="solid"
          id="end-date-tooltip"
          place="top"
          multiline={true}
        />
        <FindPeopleModal />
        {isOpenAddMembersModal && (
          <AddMembersContainer
            isOpenMembersModal={isOpenAddMembersModal}
            closeMembersModal={() =>
              this.setState({
                isOpenAddMembersModal: false
              })
            }
            project={selectedProject}
            modalType={MODAL_TYPE.PROJECT}
          />
        )}
        {/* <div
          className="timeline-footer"
          style={{
            height: '40px'
          }}
        /> */}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getScheduleItemIds = makeGetScheduleItemIds();
  const getScheduleRows = makeGetScheduleRows();
  const getScheduleItems = makeGetScheduleItems();

  const mapStateToProps = (state, ownProps) => ({
    me: getMe(state),
    token: getAuthToken(state),
    projectHash: getProjectHash(state),
    selectedItemIds: getScheduleItemIds(state, ownProps), // necessary to allow drag before click
    visibleTimeStart: getVisibleTimeStart(state, ownProps),
    visibleTimeEnd: getVisibleTimeEnd(state, ownProps),
    plannerMemberIds: getPlannerMemberIds(state),
    utilizations: getUtilizations(state),
    steps: getProjectPlannerSteps(state, ownProps),
    teamCapacity: getTeamCapacity(state),
    isOnWorkloadView: true,
    zoom: getZoom(state, ownProps),
    teamSlug: getTeamSlug(state),
    userTheme: getUserTheme(state),
    plannerRows: getScheduleRows(state, ownProps),
    plannerItems: getScheduleItems(state, ownProps),
    milestones: getAllMilestonesHash(state),
    allPhasesAndMilestones: getFlatPhasesAndMilestones(state),
    teamId: getSelectedTeamId(state),
    viewSettings: getSelectedTeamViewSettings(state),
    access: state.access.access,
    holidayDatesHash: getHolidayDatesHash(state),
    isMilestoneModalOpen: getIsMilestoneModalOpen(state),
    taskSidebarProjectId: getTaskSidebarProjectId(state),
    taskHash: getHomeTaskObj(state),
    isFetchingPhases: state.phases.fetching,
    isFetchingTasks: getIsLazyLoadingTasks(state),
    phaseHash: getFlatPhasesHash(state),
    matchedParams: getMatchedRouteParams(state),
    activityPhaseHash: getActivityPhaseHash(state),
    condensedZoomLevel: getCondensedZoomLevel(state),
    workloadViewType: getWorkloadViewType(state),
    workloadSettings: getMyWorkPlanSettings(state),
    splitFlags: getSplitFlags(state),
    selectedProject: getSelectedProject(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchWorkloadPlanner,
  updateWorkloadPlanner,
  createWorkloadPlanner,
  deleteWorkloadPlanner,
  openProjectPlannerModal,
  updateWorkloadPlannerMembers,
  fetchMemberProjects,
  fetchTeamMemberProfile,
  setVisibleDates,
  openWorkloadModal,
  fetchTeamCapacity,
  setZoom,
  navigateToProject,
  setScheduleViewBy,
  openBudgetModal,
  fetchPhaseTotals,
  fetchPhases,
  fetchMemberBudgets,
  fetchPhasesByProjectIds,
  openMilestoneModal,
  updatePhase,
  openAccessModal,
  updateMemberBudget,
  closeMilestoneModal,
  setSelectedProject,
  fetchProjectById,
  toggleTaskSidebarIsOpen,
  fetchTasksV2,
  fetchTaskGroups,
  navigateToTaskModal,
  navigateToSchedule,
  setSelectedTask,
  fetchCommentsAndMetadata,
  triggerTasksAttributesUpdate,
  updateActivityPhase,
  setCondensedZoomLevel,
  setWorkloadViewType,
  openAddMembersForm,
  editPhaseDates,
  handleErrorMessage
};

export default withPermissionsCheck(
  withFindPeopleModal(
    connect(
      makeMapStateToProps,
      mapDispatchToProps
    )(SchedulePlannerTimelineContainer)
  )
);
