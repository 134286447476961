import { useEffect, useMemo, useCallback } from 'react';
import { useAppDispatch } from 'reduxInfra/hooks';
import { FilterListType } from 'FilterModule/constants';
import { useArrayFilterField, useCrossFieldDependencies } from '.';
import { GenericFilterListTypeHookWithGroupings } from 'FilterModule/types';
import mapValues from 'lodash/mapValues';
import { useSetState } from 'react-use';
import { leanApiSchemaVariant } from 'LeanApiModule/common';
import { ValueOf, SetRequired } from 'type-fest';
import { useLeanApi } from 'LeanApiModule/utils';

export const useGroupedFilter = ({
  isOff,
  config,
  isoStateId,
  filterListType,
  field,
  getIsItemArchived,
  labelKey,
  shouldUseDraft
}: SetRequired<
  Parameters<GenericFilterListTypeHookWithGroupings>[0],
  'field'
> & {
  getIsItemArchived?: (id: number | string) => boolean;
  labelKey?: string;
}) => {
  const dispatch = useAppDispatch();

  const { dependencyFilter, numCrossFieldDependencyFiltersBeingUsed } =
    useCrossFieldDependencies({
      crossFieldDependencies: config?.crossFieldDependencies ?? [],
      isOff,
      shouldUseDraft
    });

  const {
    debouncedFetchLeanApi,
    dispatchClearLeanApiIsoState,
    leanApiIsoState,
    fetchStatus
  } = useLeanApi({ isoStateId });

  const [isArchivedShowingHash, setIsArchivedShowingHash] = useSetState<
    Record<string, boolean>
  >({});

  const { ordersByGroup, groupTotalCounts, topLevelOrder } = leanApiIsoState;

  const filteredIds = useMemo(() => {
    return isOff
      ? emptyArray
      : Array.from(new Set(Object.values(ordersByGroup).flat()));
  }, [ordersByGroup, isOff]);

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: filteredIds,
    isOff,
    shouldMaintainOrder: true, // prevent unnecessary sorting since it's being done below
    isFeSearchDisabled: true,
    config,
    labelKey,
    shouldUseDraft
  });

  const {
    isUnableToSelectMoreItems,
    selectionLimit,
    getIsSelected,
    updateSelectedItems,
    selectedItems,
    searchText
  } = arrayFilterFieldValues;

  /* -------------------------------- Archived -------------------------------- */

  const hasArchivedItems = !!getIsItemArchived;

  const nonArchivedItemsByGroup: Record<string | number, number[]> =
    useMemo(() => {
      if (!hasArchivedItems || isOff) return emptyObj;
      return mapValues(ordersByGroup, (idOrder) => {
        return idOrder.filter((id) => !getIsItemArchived(id));
      });
    }, [getIsItemArchived, ordersByGroup, hasArchivedItems, isOff]);

  const numArchivedByGroup: Record<string | number, number> = useMemo(() => {
    if (!hasArchivedItems || isOff) return emptyObj;
    return mapValues(ordersByGroup, (idOrder, groupId) => {
      return idOrder.length - (nonArchivedItemsByGroup[groupId] ?? []).length;
    });
  }, [nonArchivedItemsByGroup, ordersByGroup, hasArchivedItems, isOff]);

  const toggleIsArchivedShowing = useCallback(
    (groupId: string) => {
      setIsArchivedShowingHash((prev) => ({ [groupId]: !prev[groupId] }));
    },
    [setIsArchivedShowingHash]
  );

  /* -------------------------------- Grouping -------------------------------- */

  // groupings calculations involving both ordersByGroup and selectedItemsSet
  const { formattedOrdersByGroup, isAllSelectedByGroup, numSelectedByGroup } =
    useMemo(() => {
      const formattedOrdersByGroup: typeof ordersByGroup = {};
      const isAllSelectedByGroup: Record<string, boolean> = {};
      const numSelectedByGroup: Record<string, number> = {};

      if (isOff)
        return {
          formattedOrdersByGroup,
          isAllSelectedByGroup,
          numSelectedByGroup
        };

      Object.entries(ordersByGroup).forEach(([groupId, idOrder]) => {
        // numSelectedByGroup
        numSelectedByGroup[groupId] = idOrder.filter((id) =>
          getIsSelected(id)
        ).length;

        // isAllSelectedByGroup:
        //    checks if all active (not archived) items are selected
        const nonArchivedItemsForGroup = nonArchivedItemsByGroup[groupId] ?? [];

        const hasActiveItems =
          !hasArchivedItems || nonArchivedItemsForGroup.length > 0;

        isAllSelectedByGroup[groupId] =
          (hasActiveItems && nonArchivedItemsForGroup.every(getIsSelected)) ||
          (!hasActiveItems && idOrder.every(getIsSelected));

        // formattedOrdersByGroup:
        //    sort selected items to the top of each group and filter out archived based on isArchivedShowing
        const filteredIdOrder =
          !hasArchivedItems || isArchivedShowingHash[groupId]
            ? idOrder
            : nonArchivedItemsByGroup[groupId] ?? [];

        formattedOrdersByGroup[groupId] = filteredIdOrder
          .slice()
          .sort((a, b) => {
            const aIsSelected = getIsSelected(a);
            const bIsSelected = getIsSelected(b);
            if (aIsSelected && !bIsSelected) return -1;
            if (bIsSelected && !aIsSelected) return 1;
            return 0;
          });
      });

      return {
        formattedOrdersByGroup,
        isAllSelectedByGroup,
        numSelectedByGroup
      };
    }, [
      isArchivedShowingHash,
      nonArchivedItemsByGroup,
      ordersByGroup,
      getIsSelected,
      hasArchivedItems,
      isOff
    ]);

  const toggleSelectGroup = useCallback(
    (groupId: string) => {
      const groupOrderSet = new Set(ordersByGroup[groupId] ?? []);
      if (isAllSelectedByGroup[groupId] || isUnableToSelectMoreItems) {
        // deselect all items in group
        updateSelectedItems(
          selectedItems.filter((item) => !groupOrderSet.has(item))
        );
      } else {
        // if archived not showing, only select non-archived items. otherwise, select all items in group
        const itemsToSelect =
          (!hasArchivedItems || isArchivedShowingHash[groupId]
            ? ordersByGroup[groupId]
            : nonArchivedItemsByGroup[groupId]) ?? [];

        const nextSelectedItems = Array.from(
          new Set([...selectedItems, ...itemsToSelect])
        );

        // select all items in group
        updateSelectedItems(
          selectionLimit
            ? nextSelectedItems.slice(0, selectionLimit)
            : nextSelectedItems
        );
      }
    },
    [
      isAllSelectedByGroup,
      isArchivedShowingHash,
      nonArchivedItemsByGroup,
      ordersByGroup,
      selectedItems,
      updateSelectedItems,
      selectionLimit,
      isUnableToSelectMoreItems,
      hasArchivedItems
    ]
  );

  /**
   * modified groupTotalCounts - (doesn't include archived items) TODO
   */
  const labelGroupCounts = useMemo(() => {}, []);

  /* --------------------------------- loading -------------------------------- */

  // fetch
  useEffect(() => {
    if (!isOff) {
      const schemaVariant = filterListTypeToSchemaVariant[filterListType];

      if (schemaVariant) {
        debouncedFetchLeanApi({
          meta: {
            isInitialFetch: true,
            isTakeLatest: true
          },
          schemaVariant,
          searchText,
          filterParams: dependencyFilter,
          // limit and order for paginated schemaVariants. Can ignore these in the LeanApiSchemaBuilder
          offset: 0,
          limit: 20
        });
      } else {
        console.error(`Missing schemaVariant mapping for ${filterListType}`);
      }
    }
  }, [
    isOff,
    searchText,
    dispatch,
    debouncedFetchLeanApi,
    dependencyFilter,
    filterListType
  ]);

  // clear isoState if turned off to avoid stale state (if turned back on) since filter params may have changed
  useEffect(() => {
    if (isOff) {
      dispatchClearLeanApiIsoState();
    }
  }, [dispatchClearLeanApiIsoState, isOff]);

  return {
    ...arrayFilterFieldValues,
    isGrouped: true as Extract<boolean, true>,
    ordersByGroup: formattedOrdersByGroup,
    groupTotalCounts,
    topLevelOrder,
    isLoading: !fetchStatus || fetchStatus.isLoading,
    hasFilterParams:
      !!searchText || numCrossFieldDependencyFiltersBeingUsed > 0,

    toggleSelectGroup,
    numSelectedByGroup,
    numArchivedByGroup,
    isArchivedShowingHash,
    toggleIsArchivedShowing,
    isAllSelectedByGroup,
    // TODO: hidden items by group - future spec
    numHiddenItems: 0,
    isSingleSelect: false // maybe TODO at some point
  };
};

// -----------------------------------------------------------------------------

const emptyObj = {};
const emptyArray = [];

const filterListTypeToSchemaVariant: Partial<
  Record<FilterListType, ValueOf<typeof leanApiSchemaVariant>>
> = {
  [FilterListType.MembersByDepartment]:
    leanApiSchemaVariant.membersByDepartment,
  [FilterListType.MembersByPosition]: leanApiSchemaVariant.membersByPosition,
  [FilterListType.MembersBySkill]: leanApiSchemaVariant.membersBySkill,
  [FilterListType.MembersByOffice]: leanApiSchemaVariant.membersByOffice,
  [FilterListType.MembersByRegion]: leanApiSchemaVariant.membersByRegion,
  [FilterListType.MembersByDiscipline]:
    leanApiSchemaVariant.membersByDiscipline,
  [FilterListType.MembersByPortfolio]: leanApiSchemaVariant.membersByPortfolio,

  [FilterListType.ProjectsByPortfolio]: leanApiSchemaVariant.projectsByPortfolio
};
