import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import moment from 'moment';

import { BodyColor } from '..';
import SchedulePlannerTimelineContainer from './SchedulePlannerTimelineContainer';
import GlobalPhaseTemplateDropdown from 'BudgetModule/components/GlobalPhaseTemplateDropdown/GlobalPhaseTemplateDropdown';
import GlobalActivityDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/GlobalActivityDropdown';
import ActivityModal from './ActivityModal';

import PhaseTemplateModal from 'BudgetModule/components/PhaseTemplateModal/PhaseTemplateModal';
import PhaseModal from 'BudgetModule/components/PhaseModal/PhaseModal';
import ActivityPhaseModal from 'BudgetModule/components/ActivityPhaseModal/ActivityPhaseModal';
import {
  fetchMemberProjects,
  fetchPhaseTemplates,
  fetchPhasesByProjectIds,
  fetchPhaseTotalsByBoard,
  fetchAllProjects,
  fetchTasksV2,
  setVisibleIndices,
  fetchWorkloadPlanner,
  fetchFilteredPhases,
  fetchAccess
} from 'actionCreators';
import {
  getSelectedTeamId,
  makeGetFilteredProjectsArray,
  makeGetFilterProjectsIds,
  makeGetScheduleRows,
  getVisibleTimeStart,
  getVisibleTimeEnd,
  getActiveWorkloadPlannerFilter,
  getFilteredMembersIds,
  getCurrentUserId,
  getFlatPhasesAndMilestones
} from 'selectors';
import { VIEW_BY } from 'appConstants/workload';
import { buildAccessIdentifier } from 'appUtils/access';
import WorkloadAccessModal from 'components/Permissions/Access/WorkloadAccessModal';
import GlobalActivityDrawer from 'views/Global/GlobalActivityDrawer';

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');
const dateRange = 28;

/**
 * Planner - Tasks tab
 */
class SchedulePlannerContainer extends Component {
  state = {
    earliestDate: moment().add(-dateRange, 'days'),
    latestDate: moment().add(365, 'days'),
    inViewIds: {}
  };

  componentDidMount() {
    const {
      fetchPhaseTemplates,
      fetchMemberProjects,
      teamId,
      projects,
      fetchedPhasesProjectIds,

      fetchAllProjects,
      fetchPhaseTotalsByBoard,
      fetchTasksV2,
      visibleTimeStart,
      visibleTimeEnd,
      plannerMemberIds,
      viewBy,
      fetchFilteredPhases,
      fetchAccess
    } = this.props;
    const startDate = formatFetchDate(
      moment(visibleTimeStart).add(-28, 'days')
    );
    const endDate = formatFetchDate(moment(visibleTimeEnd).add(365, 'days'));
    fetchMemberProjects({});
    if (teamId) {
      fetchPhaseTemplates({ teamId });
      fetchAccess({
        actableId: teamId,
        actableType: 'Team',
        actionType: 'activity_phase_schedule_bars_other'
      });
      if (viewBy === VIEW_BY.MEMBERS) {
        fetchTasksV2({
          body: {
            assignee_ids: plannerMemberIds,
            schedule_start_start_date: startDate,
            schedule_start_end_date: endDate,
            all: true
          }
        });
        fetchFilteredPhases({
          teamId: teamId,
          all: true,
          startDate: startDate,
          endDate: endDate,
          accountIds: plannerMemberIds
        });
      }
    }
    if (projects) {
      const projectIds = projects
        .map((project) => project?.id)
        .filter((id) => !!id);
      const unloadedPhasesProjectIds = projectIds.filter(
        (projectId) => !fetchedPhasesProjectIds[projectId]
      );
      if (projectIds.length) {
        fetchTasksV2({
          body: {
            project_ids: projectIds,
            schedule_start_start_date: formatFetchDate(this.state.earliestDate),
            schedule_start_end_date: formatFetchDate(this.state.latestDate),
            all: true
          }
        });
      }

      if (unloadedPhasesProjectIds.length) {
        fetchAllProjects({
          projectIds: unloadedPhasesProjectIds
        });
        fetchPhasesByProjectIds({
          projectIds: unloadedPhasesProjectIds
        });
        fetchPhaseTotalsByBoard({ projectIds: unloadedPhasesProjectIds });
      }
    }
  }

  getPermissions = () => {
    const { teamId } = this.props;
    return {
      teamId
    };
  };

  componentDidUpdate(prevProps) {
    const {
      teamId,
      fetchPhaseTemplates,
      projects,
      fetchedPhasesProjectIds,
      fetchPhasesByProjectIds,
      fetchPhaseTotalsByBoard,
      fetchAllProjects,
      fetchTasksV2,
      setVisibleIndices,
      viewBy,
      offset,
      activeFilter,
      isFetchingProjects,
      plannerRows,
      plannerMemberIds,
      visibleTimeStart,
      visibleTimeEnd,
      fetchFilteredPhases,
      phases,
      fetchAccess,
      fetchedProjectIds,
      fetchedTaskProjectIds
    } = this.props;
    if (!prevProps.teamId && teamId) {
      fetchPhaseTemplates({ teamId });
      fetchAccess({
        actableId: teamId,
        actableType: 'Team',
        actionType: 'activity_phase_schedule_bars_other'
      });
    }
    if (
      viewBy === VIEW_BY.PROJECTS &&
      this.shouldRenderRow(plannerRows.length) &&
      activeFilter &&
      !activeFilter.project_ids?.length &&
      !isFetchingProjects
    ) {
      // uncomment if/when 0 projects selected === all projects selected
      // fetchAllProjects({ offset, limit: 15 });
    } else if (
      prevProps.projects !== projects ||
      plannerRows !== prevProps.plannerRows
    ) {
      const unloadedPhasesProjectIds = [
        ...projects.map((project) => project && project.id)
      ]
        .filter((projectId) => projectId && !fetchedPhasesProjectIds[projectId])
        .slice(0, 15)
        .filter((id) => id);

      if (unloadedPhasesProjectIds.length) {
        fetchAllProjects({
          projectIds: unloadedPhasesProjectIds
        });
        fetchPhasesByProjectIds({
          projectIds: unloadedPhasesProjectIds
        });
        fetchPhaseTotalsByBoard({ projectIds: unloadedPhasesProjectIds });
      }
      const unloadedTaskProjectIds = [
        ...projects.map((project) => project && project.id)
      ]
        .filter((projectId) => projectId && !fetchedTaskProjectIds[projectId])
        .slice(0, 15)
        .filter((id) => id);
      if (unloadedTaskProjectIds.length) {
        fetchTasksV2({
          body: {
            project_ids: Array.from(
              new Set([...unloadedTaskProjectIds, ...this.getProjectIds()])
            ),
            schedule_start_start_date: formatFetchDate(this.state.earliestDate),
            schedule_start_end_date: formatFetchDate(this.state.latestDate),
            all: true
          }
        });
      }
    }
    if (viewBy === VIEW_BY.MEMBERS && prevProps.phases !== phases) {
      const unloadedBarProjectIds = Array.from(
        new Set(
          phases
            .map((phase) => phase.project_id)
            .filter(
              (projectId) => projectId && !fetchedPhasesProjectIds[projectId]
            )
        )
      );
      if (unloadedBarProjectIds.length > 0) {
        fetchAllProjects({
          projectIds: unloadedBarProjectIds
        });
        fetchPhasesByProjectIds({
          projectIds: unloadedBarProjectIds
        });
      }
    }
    if (viewBy !== prevProps.viewBy) {
      setVisibleIndices({ visibleIndices: [0, 0] });
      this.setState({ inViewIds: {} });
    }
    if (
      plannerMemberIds !== prevProps.plannerMemberIds &&
      viewBy === VIEW_BY.MEMBERS
    ) {
      const startDate = formatFetchDate(
        moment(visibleTimeStart).add(-28, 'days')
      );
      const endDate = formatFetchDate(moment(visibleTimeEnd).add(365, 'days'));
      fetchTasksV2({
        body: {
          assignee_ids: plannerMemberIds,
          schedule_start_start_date: startDate,
          schedule_start_end_date: endDate,
          all: true
        }
      });
      fetchFilteredPhases({
        teamId: teamId,
        all: true,
        startDate: startDate,
        endDate: endDate,
        accountIds: plannerMemberIds
      });
    }
  }

  getProjectIds = () => {
    const { plannerRows, viewBy } = this.props;
    if (viewBy !== VIEW_BY.PROJECTS) {
      return [];
    }
    const projectIds = Array.from(
      new Set(plannerRows.map((row) => row.project_id))
    );
    return projectIds.filter(
      (id) => !!id && !['empty--1', 'empty--2'].includes(id)
    );
  };

  setInView = ({ id, index, inView }) => {
    const { visibleTimeStart, visibleTimeEnd, inViewIndices, plannerRows } =
      this.props;
    let { earliestDate, latestDate, inViewIds } = this.state;

    const [originalStart = 0, originalStop = 0] = inViewIndices;
    let start = originalStart;
    let stop = originalStop;

    if (inView) {
      if (index < start) {
        start = index;
      }
      if (index > stop) {
        stop = index;
      }
    } else {
      if (index <= start) {
        start = index + 1;
      } else if (index > originalStop && inViewIds[id]) {
        // only set to this row -1 if row was previously in view
        stop = index - 1;
      } else if (index < originalStop) {
        // only trim bottom if all nearby rows are also not in view.
        // Prevents case where unexpected rows leaving cause subsequent rows to fail to render
        if (
          plannerRows
            .slice(index, index + 10)
            .every((row) => !inViewIndices[row.id])
        ) {
          stop = index;
        }
      }
    }
    if (start < originalStart || stop > originalStop) {
      // only reset dates for fetch window if range increases
      earliestDate = formatFetchDate(visibleTimeStart.clone().add(-14, 'days'));
      latestDate = formatFetchDate(visibleTimeEnd.clone().add(14, 'days'));
      this.setState({
        earliestDate,
        latestDate
      });
    }

    // always update inViewIds and visibleIndices
    this.setState((prevState) => ({
      inViewIds: { ...prevState.inViewIds, [id]: inView }
    }));

    if (start !== originalStart || stop !== originalStop) {
      this.props.setVisibleIndices({ visibleIndices: [start, stop] });
    }
  };

  shouldRenderRow = (index) => {
    const [start, stop = 0] = this.props.inViewIndices;
    return index - stop <= 10;
  };

  handleLazyLoad = (calendarTimeStart, calendarTimeEnd) => {
    const { earliestDate, latestDate } = this.state;
    const loadingEarlier = moment(calendarTimeStart).isBefore(earliestDate);
    const loadingLater = moment(latestDate).isBefore(moment(calendarTimeEnd));

    if (loadingEarlier && loadingLater) {
      this.lazyLoad({
        startDate: moment(calendarTimeStart),
        endDate: moment(calendarTimeEnd)
      });
      this.setState({
        earliestDate: moment(calendarTimeStart),
        latestDate: moment(calendarTimeEnd)
      });
    } else if (loadingEarlier) {
      const startDate = formatFetchDate(moment(earliestDate).add(-14, 'days'));
      this.lazyLoad({
        startDate,
        endDate: earliestDate
      });
      this.setState({
        earliestDate: startDate
      });
    } else if (loadingLater) {
      const endDate = formatFetchDate(moment(latestDate).add(14, 'days'));
      this.lazyLoad({
        startDate: latestDate,
        endDate
      });
      this.setState({
        latestDate: endDate
      });
    }
  };

  lazyLoad = ({ startDate, endDate }) => {
    const {
      fetchTasksV2,
      viewBy,
      plannerMemberIds,
      fetchFilteredPhases,
      teamId
    } = this.props;
    const projectIds = this.getProjectIds();
    if (viewBy === VIEW_BY.MEMBERS) {
      fetchTasksV2({
        body: {
          assignee_ids: plannerMemberIds,
          schedule_start_start_date: formatFetchDate(startDate),
          schedule_start_end_date: formatFetchDate(endDate),
          all: true
        }
      });
      fetchFilteredPhases({
        teamId: teamId,
        all: true,
        startDate: formatFetchDate(startDate),
        endDate: formatFetchDate(endDate),
        accountIds: plannerMemberIds
      });
    } else {
      fetchTasksV2({
        body: {
          project_ids: projectIds,
          schedule_start_start_date: formatFetchDate(startDate),
          schedule_start_end_date: formatFetchDate(endDate),
          all: true
        }
      });
    }
  };

  render() {
    const {
      teamId,
      pageName,
      viewBy,
      projectIds,
      inViewIndices,
      activeFilter
    } = this.props;
    return (
      <DynamicModuleLoader modules={[getBudgetModule()]}>
        {teamId && (
          <div className="project-planner-container">
            <BodyColor isGray />
            <SchedulePlannerTimelineContainer
              handleLazyLoad={this.handleLazyLoad}
              projectIds={projectIds}
              pageName={pageName}
              viewBy={viewBy}
              plannerType="schedule"
              setInView={this.setInView}
              shouldRenderRow={this.shouldRenderRow}
              inViewIndices={inViewIndices}
              activeFilter={activeFilter}
            />
          </div>
        )}
        <GlobalPhaseTemplateDropdown />
        <GlobalActivityDropdown />
        <PhaseTemplateModal />
        <PhaseModal />
        <ActivityPhaseModal />
        <ActivityModal />
        <WorkloadAccessModal
          accessIdentifier={buildAccessIdentifier({
            actableType: 'Team',
            actableId: teamId,
            actionType: 'activity_phase_schedule_bars_other'
          })}
        />
        <GlobalActivityDrawer />
      </DynamicModuleLoader>
    );
  }
}

const makeMapStateToProps = () => {
  const getFilteredProjectsArray = makeGetFilteredProjectsArray();
  const getFilterProjectsIds = makeGetFilterProjectsIds();
  const getScheduleRows = makeGetScheduleRows();

  const mapStateToProps = (state, ownProps) => ({
    projects: getFilteredProjectsArray(state, ownProps),
    projectIds: getFilterProjectsIds(state, ownProps),
    fetchedPhasesProjectIds: state.phases.fetchedPhasesProjectIds,

    teamId: getSelectedTeamId(state),
    plannerRows: getScheduleRows(state, ownProps),
    phases: getFlatPhasesAndMilestones(state),
    inViewIndices: state.workloadPlanner.visibleIndices || [0, 0],
    isFetchingProjects: state.projects.isFetchingProjects,
    fetchedTaskProjectIds: state.homeTasks.fetchedProjectIds,
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps),
    offset: state.projects.offset,
    visibleTimeStart: getVisibleTimeStart(state, ownProps),
    visibleTimeEnd: getVisibleTimeEnd(state, ownProps),
    plannerMemberIds: getFilteredMembersIds(state, ownProps),
    my_id: getCurrentUserId(state),
    fetchedProjectIds: state.projects.fetchedProjectIds
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchMemberProjects,
  fetchPhaseTemplates,
  fetchPhasesByProjectIds,
  fetchPhaseTotalsByBoard,
  fetchAllProjects,
  fetchTasksV2,
  setVisibleIndices,
  fetchWorkloadPlanner,
  fetchFilteredPhases,
  fetchAccess
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SchedulePlannerContainer);
