import {
  fetchAllTeamRates,
  fetchAllTeamSalaries
} from 'BudgetModule/actionCreators';
import {
  getActiveTeamSalaries,
  getDefaultTeamRates
} from 'BudgetModule/selectors';
import FetchManager from 'classes/FetchManager';
import { AccountId } from 'models/account';
import { PositionId } from 'models/position';
import { ActivityPhaseId } from 'ProjectsModule/phases/models/phase';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import { Rate } from 'RatesModule/models/Rate';
import { RateGroupId } from 'RatesModule/models/RateGroup';
import { Salary } from 'RatesModule/models/Salary';
import { fetchRateGroups, fetchRates } from 'RatesModule/ratesActionCreators';
import {
  getDefaultRoleRateGroupId,
  getDefaultWorkCategoryRateGroupId,
  getEntityRatesByRateGroup,
  getEntityRatesHash,
  getIsRatesLoaded,
  getIsRoleRateGroupsLoaded,
  getIsWorkCategoryRateGroupsLoaded,
  getRatesHash
} from 'RatesModule/ratesSelectors';
import { useCallback, useMemo } from 'react';
import { useUnmount } from 'react-use';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamId, getTeamMembersHash } from 'TeamsModule/selectors';
import { v4 as uuid } from 'uuid';

export const useTeamRates = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const defaultTeamRates = useAppSelector(getDefaultTeamRates);
  const ratesHash = useAppSelector(getRatesHash);
  const defaultRoleRateGroupId = useAppSelector(getDefaultRoleRateGroupId);
  const defaultWorkCategoryRateGroupId = useAppSelector(
    getDefaultWorkCategoryRateGroupId
  );
  const entityRatesByRateGroup = useAppSelector(getEntityRatesByRateGroup);
  const entityRatesHash = useAppSelector(getEntityRatesHash);
  const activeTeamSalaries = useAppSelector(getActiveTeamSalaries);
  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const isRoleRateGroupsLoaded = useAppSelector(getIsRoleRateGroupsLoaded);
  const isWorkCategoryRateGroupsLoaded = useAppSelector(
    getIsWorkCategoryRateGroupsLoaded
  );
  const isRatesLoaded = useAppSelector(getIsRatesLoaded);

  const getTeamMemberBillRate = useCallback(
    (accountId: AccountId): Rate | undefined =>
      ratesHash?.[defaultTeamRates[accountId]?.rate_id],
    [defaultTeamRates, ratesHash]
  );

  const getTeamMemberCostRate = useCallback(
    (accountId: AccountId): Salary =>
      activeTeamSalaries[teamMembersHash[accountId]?.id ?? -1],
    [activeTeamSalaries, teamMembersHash]
  );

  const getTeamRoleBillRate = useCallback(
    (roleId: PositionId, rateGroupId?: RateGroupId): Rate | undefined =>
      ratesHash?.[
        entityRatesHash?.[
          entityRatesByRateGroup?.[
            rateGroupId ?? defaultRoleRateGroupId ?? -1
          ]?.[roleId]?.currentBillRate ?? -1
        ]?.rate_id ?? -1
      ],
    [defaultRoleRateGroupId, entityRatesByRateGroup, entityRatesHash, ratesHash]
  );

  const getTeamRoleCostRate = useCallback(
    (roleId: PositionId, rateGroupId?: RateGroupId): Rate | undefined =>
      ratesHash?.[
        entityRatesByRateGroup?.[rateGroupId ?? defaultRoleRateGroupId ?? -1]?.[
          roleId
        ]?.currentCostRate ?? -1
      ],
    [defaultRoleRateGroupId, entityRatesByRateGroup, ratesHash]
  );

  const getTeamWorkCategoryBillRate = useCallback(
    (
      workCategoryId: ActivityPhaseId,
      rateGroupId?: RateGroupId
    ): Rate | undefined =>
      ratesHash?.[
        entityRatesByRateGroup?.[
          rateGroupId ?? defaultWorkCategoryRateGroupId ?? -1
        ]?.[workCategoryId]?.currentBillRate ?? -1
      ],
    [defaultWorkCategoryRateGroupId, entityRatesByRateGroup, ratesHash]
  );

  // Cleanup on unmount.
  const abortId = useMemo(() => `useTeamRates>${uuid()}`, []);
  useUnmount(() => FetchManager.abort(abortId));

  const fetchTeamMemberCostRates = useCallback(
    (forceRefetch?: boolean): void => {
      if (teamId && (!Object.keys(activeTeamSalaries).length || forceRefetch))
        dispatch(fetchAllTeamSalaries({ teamId, meta: { abortId } }));
    },
    [abortId, activeTeamSalaries, dispatch, teamId]
  );

  const fetchTeamRates = useCallback(
    (forceRefetch?: boolean): void => {
      if (teamId && (!isRatesLoaded || forceRefetch))
        dispatch(fetchRates({ team_id: teamId, meta: { abortId } }));
    },
    [abortId, dispatch, isRatesLoaded, teamId]
  );

  const fetchTeamMemberBillRates = useCallback(
    (forceRefetch?: boolean): void => {
      fetchTeamRates(forceRefetch);
      if (teamId && (!Object.keys(defaultTeamRates).length || forceRefetch))
        dispatch(fetchAllTeamRates({ teamId, meta: { abortId } }));
    },
    [abortId, defaultTeamRates, dispatch, fetchTeamRates, teamId]
  );

  const fetchTeamRolesRates = useCallback(
    (forceRefetch?: boolean): void => {
      fetchTeamRates(forceRefetch);
      if (teamId && (!isRoleRateGroupsLoaded || forceRefetch))
        dispatch(
          fetchRateGroups({
            active_entity_type: EntityRateType.Role,
            team_id: teamId,
            meta: { abortId }
          })
        );
    },
    [abortId, dispatch, fetchTeamRates, isRoleRateGroupsLoaded, teamId]
  );

  const fetchTeamWorkCategoryRates = useCallback(
    (forceRefetch?: boolean): void => {
      fetchTeamRates(forceRefetch);
      if (teamId && (!isWorkCategoryRateGroupsLoaded || forceRefetch))
        dispatch(
          fetchRateGroups({
            active_entity_type: EntityRateType.WorkCategory,
            team_id: teamId,
            meta: { abortId }
          })
        );
    },
    [abortId, dispatch, fetchTeamRates, isWorkCategoryRateGroupsLoaded, teamId]
  );

  return {
    getTeamMemberBillRate,
    getTeamMemberCostRate,
    getTeamRoleBillRate,
    getTeamRoleCostRate,
    getTeamWorkCategoryBillRate,
    fetchTeamMemberBillRates,
    fetchTeamMemberCostRates,
    fetchTeamRates,
    fetchTeamRolesRates,
    fetchTeamWorkCategoryRates
  };
};
