import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportTaskPriority
} from 'CsvImportsModule/types';

import { getRowValuesForEntityType, enteredFieldIsTrue } from './utils';

interface AdditionalParams {
  existingTaskPrioritiesByTitle: Record<string, CsvImportTaskPriority>;
}

export const taskPriorityOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingTaskPrioritiesByTitle } = additionalParams;

  const { enteredIsScope } = getRowValuesForEntityType(row, entityType);

  // only give user the empty option if user is actually trying to import scope
  if (enteredFieldIsTrue(enteredIsScope)) return [''];
  // return all possible task priorities options if importing task
  return ['', ...Object.keys(existingTaskPrioritiesByTitle)];
};
