import React, { useMemo, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import Table from './Table';
import VariableSizeTable from './VariableSizeTable';
import { Styles } from './styles';
import reorder from 'appUtils/reorder';

const DEFAULT_ROW_HEIGHT = 50;

const noop = () => {};

const columns = [];

const defaultStateReducer = (newState, action, prevState) => newState;

const DragDropContextWrapper = (props) =>
  props.isInDragContext ? (
    <>{props.children}</>
  ) : (
    <DragDropContext
      onDragEnd={props.onDragEnd}
      onBeforeCapture={props.onBeforeCapture || noop}
    >
      {props.children}
    </DragDropContext>
  );

function App(props) {
  const [data, setData] = React.useState([]);
  const isDragDisabled =
    props.isDragDisabled === undefined ? true : props.isDragDisabled;

  const onDragEnd = React.useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const newData = reorder(
        data,
        result.source.index,
        result.destination.index
      );

      setData(newData);
    },
    [data]
  );

  const numberOfAdditionalRowsForThreshold = useMemo(() => {
    return (
      props.numberOfAdditionalRowsForThreshold ??
      (props.data || data).filter((row) => !row.isRow).length // default value
    );
  }, [data, props.data, props.numberOfAdditionalRowsForThreshold]);

  const getItemSize = useCallback(
    (index) =>
      (props.data || data)[index]?.itemHeight ||
      props.defaultRowHeight ||
      DEFAULT_ROW_HEIGHT,
    [data, props.data, props.defaultRowHeight]
  );

  const TableComponent = props.isVariableSizeTable ? VariableSizeTable : Table;

  return (
    <Styles className={props.className}>
      <DragDropContextWrapper
        isInDragContext={props.isInDragContext}
        onDragEnd={props.onDragEnd || onDragEnd}
        onBeforeCapture={props.onBeforeCapture}
      >
        <TableComponent
          columns={props.columns || columns}
          data={props.data || data}
          itemHeight={props.itemHeight}
          maxHeight={props.maxHeight}
          showFooter={props.showFooter}
          virtual={props.virtual}
          loadMoreItems={props.loadMoreItems}
          // only used for variableSize tables
          handleScroll={props.handleScroll}
          getItemSize={props.getItemSize || getItemSize}
          customRowProps={props.customRowProps}
          numberOfAdditionalRowsForThreshold={
            numberOfAdditionalRowsForThreshold
          }
          listRef={props.listRef}
          infiniteLoaderRef={props.infiniteLoaderRef}
          showHeader={props.showHeader === undefined ? true : props.showHeader}
          isDragDisabled={isDragDisabled}
          autoResetRowState={props.autoResetRowState}
          stateReducer={props.stateReducer || defaultStateReducer}
          tableStateRef={props.tableStateRef}
          columnMaxWidth={props.columnMaxWidth}
          dragContainerClassName={props.dragContainerClassName}
          overscanCount={props.overscanCount}
          itemCount={props.itemCount}
          columnMinWidth={props.columnMinWidth}
          totalColumnsWidthOverride={props.totalColumnsWidthOverride}
          headersToIgnore={props.headersToIgnore}
          hideDevProps={props.hideDevProps}
          classNames={props.classNames}
        />
      </DragDropContextWrapper>
    </Styles>
  );
}

export default App;
