import { createSelector } from 'reselect';
import { getUserSlug } from './user';
import {
  isOnSettingsView,
  isOnUpdatesView,
  isOnProjectPlanner,
  isOnTeamTaskPlanner,
  isOnTeamsView,
  isOnProjectsTasksView,
  isOnProjectsNotesView,
  isOnProjectView,
  isOnTeamSettingsView,
  isOnMembersSettings,
  isOnIntegrationsView,
  isOnMembersView,
  isOnTeamMembersProfileView,
  isOnTeamMembersTimesheetView,
  isOnTeamMembersTasksView,
  isTaskModalOpen,
  isMemberModalOpen,
  isOnHomeView,
  isOnHomeNotifications,
  isOnHomeCompletedTasks,
  isOnOwnTimesheets,
  isOnWorkloadView,
  isOnScheduleView,
  isOnReportsView,
  isOnDashboardView,
  isOnSearchablePageOrTab,
  isOnReportsCapacityView,
  isOnStandardSettingsView,
  isOnMembersSettingsView,
  isOnTasksView,
  isOnWorkplansView
} from 'appUtils/views';
import { getSelectedProject } from './projects';

const emptyArray = [];
const emptyObj = {};
const emptyLocation = { pathname: '' };

export const getRouter = (state) => state.router;
/**
 * @deprecated Use the typesafe version from ``appCore/navigation/selectors`
 */
export const getRouteHistoryState = (state) => state.routeHistory;

/**
 * @deprecated Use the typesafe version from `appCore/navigation/selectors`.
 */
export const getMatchedRouteParams = (state) =>
  (state.routeHistory && state.routeHistory.matchedParams) || emptyObj;

export const getRouterLocation = (state) =>
  state.router.location || emptyLocation;
export const getRouterLocationQuery = createSelector(
  getRouterLocation,
  (location) => new URLSearchParams(location.search)
);

/**
 * @deprecated Use the typesafe version from `appCore/navigation/selectors`.
 */
export const getNavigationHistory = (state) =>
  (state.routeHistory && state.routeHistory.navigationHistory) || emptyArray;

export const getLastLocationsState = (state) =>
  state.routeHistory.lastLocations || emptyObj;

export const getTrackedNavigations = (state) =>
  (state.routeHistory && state.routeHistory.navigation) || emptyArray;

export const getPrevMatchedRouteParams = (state) =>
  (state.routeHistory && state.routeHistory.prevMatchedParams) || emptyObj;

export const getIsNavigationBlocked = createSelector(
  getRouteHistoryState,
  (state) => state.isNavigationBlocked
);

export const getOnProjectDetail = createSelector(
  getNavigationHistory,
  isOnProjectView
);

export const getOnBoardView = createSelector(
  getNavigationHistory,
  isOnTeamsView
);
export const getIsOnProjectView = createSelector(
  getNavigationHistory,
  isOnProjectView
);

export const getIsOnTeamSettingsView = createSelector(
  getNavigationHistory,
  isOnTeamSettingsView
);
export const getIsOnMembersSettingsView = createSelector(
  getNavigationHistory,
  isOnMembersSettingsView
);
export const getIsOnStandardSettingsView = createSelector(
  getNavigationHistory,
  isOnStandardSettingsView
);
export const getIsOnIntegrations = createSelector(
  getNavigationHistory,
  isOnIntegrationsView
);

export const getOnNotesView = createSelector(
  getNavigationHistory,
  isOnProjectsNotesView
);

export const getOnTaskView = createSelector(
  getNavigationHistory,
  isOnProjectsTasksView
);

export const getOnMembersView = createSelector(
  getNavigationHistory,
  isOnMembersView
);

export const getOnWorkloadView = createSelector(
  getNavigationHistory,
  isOnWorkloadView
);
export const getIsOnScheduleView = createSelector(
  getNavigationHistory,
  isOnScheduleView
);
export const getIsOnReportsView = createSelector(
  getNavigationHistory,
  isOnReportsView
);

export const getIsOnReportsCapacityView = createSelector(
  getNavigationHistory,
  isOnReportsCapacityView
);

export const getIsOnDashboardView = createSelector(
  getNavigationHistory,
  isOnDashboardView
);
export const getOnHomeView = createSelector(getNavigationHistory, isOnHomeView);

export const getIsOnHomeNotifications = createSelector(
  getOnHomeView,
  getMatchedRouteParams,
  isOnHomeNotifications
);

export const getIsOnHomeCompletedTasks = createSelector(
  getOnHomeView,
  getMatchedRouteParams,
  isOnHomeCompletedTasks
);

export const getOnScheduleView = (state) =>
  !getRouterLocation(state).pathname.includes('/home/') &&
  getRouterLocation(state).pathname.split('/').length === 6 &&
  getRouterLocation(state).pathname.split('/')[5] === 'schedule';
export const isOnTeamMemberProfile = createSelector(
  getNavigationHistory,
  isOnTeamMembersProfileView
);

export const getOnProfile = createSelector(
  getNavigationHistory,
  isOnTeamMembersProfileView
);

export const getOnProjectPlanner = createSelector(
  getNavigationHistory,
  isOnProjectPlanner
);
export const getOnTeamPlannerTasks = createSelector(
  getNavigationHistory,
  isOnTeamTaskPlanner
);

export const getIsOnPersonalProject = createSelector(
  getOnProjectDetail,
  getSelectedProject,
  (isOnProjectDetail, selectedProject) =>
    isOnProjectDetail && selectedProject?.is_personal === true
);
export const getIsOnTeamProject = createSelector(
  getOnProjectDetail,
  getSelectedProject,
  (isOnProjectDetail, selectedProject) =>
    isOnProjectDetail && selectedProject?.is_personal === false
);

export const getOnActivityFeed = createSelector(
  getNavigationHistory,
  isOnUpdatesView
);

export const getOnSettings = createSelector(
  getNavigationHistory,
  isOnSettingsView
);
export const getIsOnMembersSettings = createSelector(
  getIsOnTeamSettingsView,
  getMatchedRouteParams,
  isOnMembersSettings
);

export const getIsTaskModalOpen = createSelector(
  getNavigationHistory,
  isTaskModalOpen
);
export const getIsMemberModalOpen = createSelector(
  getNavigationHistory,
  isMemberModalOpen
);
export const getIsOnOwnTimesheets = createSelector(
  getOnHomeView,
  getMatchedRouteParams,
  isOnOwnTimesheets
);

export const isOnTeamMembersTimesheet = createSelector(
  getNavigationHistory,
  isOnTeamMembersTimesheetView
);

export const isOnTeamMembersTasks = createSelector(
  getNavigationHistory,
  isOnTeamMembersTasksView
);

export const getCurrentLocation = createSelector(
  getNavigationHistory,
  (navigationHistory) => navigationHistory.length && navigationHistory[0]
);

export const getIsOnSearchablePageOrTab = createSelector(
  getNavigationHistory,
  getMatchedRouteParams,
  isOnSearchablePageOrTab
);

export const getIsOnTasksView = createSelector(
  getNavigationHistory,
  isOnTasksView
);

export const getIsOnWorkplansView = createSelector(
  getNavigationHistory,
  isOnWorkplansView
);

export const getSection = createSelector(
  getNavigationHistory,
  (navigationHistory) =>
    navigationHistory.length && navigationHistory[0].section
);
