import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import { skillSuggestionsStub } from './mockData/skills';

const byId = (item) => item.id;

const { WS_TEAM_SKILL, WS_TEAM_MEMBER_SKILL } = constants;
const emptyArray = [];

export const initialState = {
  loaded: false,
  isLoading: false,
  newSkillId: null,
  skills: {},
  skillsOrder: [],

  /**
   *  entitySkills: {
   *    [entityType]: {
   *      [entityId]: [] // this is not entitySkills id but id that is associated with the entity
   *    }
   *  }
   */
  entitySkills: {},
  entitySkillsHash: {}, // hashed by entitySkillIds
  isFetchingEntitySkills: false,

  /**
   *  skillsReport: {
   *    [positionId]: {
   *      accountProficiencies: {},
   *      skillProficiencies: {},
   *      teamMemberSkills: [
   *        // stored in 2d array. rows are grouped by team_skill_ids
   *        // columns are account_ids under same team_skill_id per row
   *      ]
   *    }
   *  }
   */
  skillsReport: {},
  isFetchingSkillsReport: false,

  // Skills suggestion by position
  skillSuggestions: {}
};

const skills = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_SKILLS.TRIGGER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case constants.FETCH_SKILLS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        loaded: true
      };
    }

    case constants.FETCH_SKILLS.SUCCESS: {
      const skills = payload.response;
      return {
        ...state,
        loaded: true,
        skillsOrder: skills.map(byId),
        skills: {
          ...state.skills,
          ...keyBy(skills, byId)
        }
      };
    }
    case constants.CREATE_SKILL.SUCCESS: {
      const newSkill = payload.response;
      return {
        ...state,
        newSkillId: newSkill.id,
        skillsOrder: [newSkill.id, ...state.skillsOrder],
        skills: {
          ...state.skills,
          [newSkill.id]: newSkill
        }
      };
    }
    case WS_TEAM_SKILL: {
      if (payload.deleted) {
        return {
          ...state,
          skills: omit(state.skills, payload.id),
          skillsOrder: state.skillsOrder.filter((id) => id != payload.id)
        };
      } else {
        return {
          ...state,
          skills: {
            ...state.skills,
            [payload.id]: payload
          },
          skillsOrder: state.skills[payload.id]
            ? state.skillsOrder
            : [...state.skillsOrder, payload.id]
        };
      }
    }
    case constants.DELETE_SKILL.TRIGGER:
    case constants.UPDATE_SKILL.TRIGGER: {
      return {
        ...state,
        newSkillId: null
      };
    }
    case constants.UPDATE_SKILL.SUCCESS: {
      const updatedSkill = payload.response;
      return {
        ...state,
        skills: {
          ...state.skills,
          [updatedSkill.id]: updatedSkill
        }
      };
    }
    case constants.ARCHIVE_SKILL.SUCCESS: {
      const { id } = payload.requestPayload;
      return {
        ...state,
        skills: {
          ...state.skills,
          [id]: {
            ...state.skills[id],
            archived: true
          }
        }
      };
    }
    case constants.UNARCHIVE_SKILL.SUCCESS: {
      const { id } = payload.requestPayload;
      return {
        ...state,
        skills: {
          ...state.skills,
          [id]: {
            ...state.skills[id],
            archived: false
          }
        }
      };
    }

    case constants.CREATE_SKILL_MEMBERSHIP.SUCCESS: {
      const newMembership = payload.response;
      const skillId = newMembership.team_skill_id;
      return {
        ...state,
        skills: {
          ...state.skills,
          [skillId]: {
            ...state.skills[skillId],
            team_member_skills: [
              newMembership,
              ...state.skills[skillId].team_member_skills
            ]
          }
        }
      };
    }

    case WS_TEAM_MEMBER_SKILL: {
      const newMembership = action.payload;
      const skillId = newMembership.team_skill_id;
      if (action.payload.deleted) {
        return {
          ...state,
          skills: {
            ...state.skills,
            [skillId]: {
              ...state.skills[skillId],
              team_member_skills:
                state.skills[skillId]?.team_member_skills?.filter(
                  (membership) => membership.id !== newMembership.id
                ) ?? []
            }
          }
        };
      } else {
        return {
          ...state,
          skills: {
            ...state.skills,
            [skillId]: {
              ...state.skills[skillId],
              team_member_skills: [
                newMembership,
                ...(state.skills[skillId]?.team_member_skills ?? [])
              ]
            }
          }
        };
      }
    }

    case constants.UPDATE_SKILL_MEMBERSHIP.SUCCESS: {
      const updatedMembership = payload.response;
      const skillId = updatedMembership.team_skill_id;
      return {
        ...state,
        skills: {
          ...state.skills,
          [skillId]: {
            ...state.skills[skillId],
            team_member_skills: state.skills[skillId].team_member_skills.map(
              (membership) =>
                membership.id === updatedMembership.id
                  ? updatedMembership
                  : membership
            )
          }
        }
      };
    }

    case constants.DELETE_SKILL_MEMBERSHIP.SUCCESS: {
      const { skillMembershipId, skillId } = payload.requestPayload;
      return {
        ...state,
        skills: {
          ...state.skills,
          [skillId]: {
            ...state.skills[skillId],
            team_member_skills: state.skills[skillId].team_member_skills.filter(
              (membership) => membership.id !== skillMembershipId
            )
          }
        }
      };
    }

    /* ------------------------------ Skills Report ----------------------------- */

    case constants.FETCH_SKILLS_REPORT.TRIGGER: {
      return { ...state, isFetchingSkillsReport: true };
    }
    case constants.FETCH_SKILLS_REPORT.FAILURE: {
      return { ...state, isFetchingSkillsReport: false };
    }
    case constants.FETCH_SKILLS_REPORT.SUCCESS: {
      const { response, requestPayload } = payload;
      const {
        params: { account_ids, position_ids, skill_ids }
      } = requestPayload;
      const { team_member_skills, account_proficiencies, skill_proficiencies } =
        response;
      const positionId = position_ids[0];
      const columnLength = Object.keys(account_proficiencies).length;
      const rowLength = Object.keys(skill_proficiencies).length;

      /**
       * BE sends a long array with account's team skill data which will be in a specific order
       * this will create 2d array which will represent the order for 2d table.
       * columns will contain team skill data of each account
       * and rows will be an array of those columns which will be under the same team_skill_id per row
       * [
       *   [ {...account1's skill id 1}, {...account2's skill id 1}, {...account3's skill id 1} ],
       *   [ {...account1's skill id 2}, {...account2's skill id 2}, {...account3's skill id 2} ],
       *   [ {...account1's skill id 3}, {...account2's skill id 3}, {...account3's skill id 3} ],
       * ]
       */
      const teamMemberSkills = Array(rowLength)
        .fill(0)
        .map((_, index) =>
          team_member_skills.slice(
            index * columnLength,
            (index + 1) * columnLength
          )
        );

      return {
        ...state,
        isFetchingSkillsReport: false,
        skillsReport: {
          ...state.skillsReport,
          [positionId]: {
            accountProficiencies: account_proficiencies,
            skillProficiencies: skill_proficiencies,
            teamMemberSkills
          }
        }
      };
    }

    case constants.UPDATE_SKILL_LEVEL_ON_SKILLS_REPORT_LOCAL: {
      const {
        columnIndex,
        rowIndex,
        nextLevel,
        prevLevel,
        isNewSkill,
        shouldDeleteSkill,
        accountId,
        teamMemberSkillId, // response from create/updateSkillMemberShip
        teamSkillId,
        positionId
      } = payload;
      const teamMemberSkills = state.skillsReport[positionId]?.teamMemberSkills;
      const accountProficiencies =
        state.skillsReport[positionId].accountProficiencies;
      const skillProficiencies =
        state.skillsReport[positionId].skillProficiencies;
      const selectedRow = teamMemberSkills[rowIndex];
      const selectedColumn = selectedRow[columnIndex];

      // do not update state if these values don't match
      if (
        selectedColumn.team_skill_id !== teamSkillId ||
        selectedColumn.account_id !== accountId
      ) {
        return state;
      }

      const nextColumnState = {
        ...selectedColumn,
        level: shouldDeleteSkill ? null : nextLevel,
        ...((isNewSkill || shouldDeleteSkill) && {
          id: shouldDeleteSkill ? null : teamMemberSkillId
        })
      };

      // replace the column that has the same account id
      const nextRowState = [
        ...selectedRow.slice(0, columnIndex),
        nextColumnState,
        ...selectedRow.slice(columnIndex + 1)
      ];

      // replace the row that has the same skill id
      const nextTeamMemberSkills = [
        ...teamMemberSkills.slice(0, rowIndex),
        nextRowState,
        ...teamMemberSkills.slice(rowIndex + 1)
      ];

      const levelDifference = nextLevel - prevLevel;

      // update accountProficiencies
      const nextSkillLevelByAccountId =
        accountProficiencies[accountId].skill_level + levelDifference;
      const nextSkillCapByAccountId =
        accountProficiencies[accountId].skill_cap +
        (isNewSkill ? 5 : shouldDeleteSkill ? -5 : 0);
      const nextSkillAvgByAccountId =
        nextSkillLevelByAccountId / nextSkillCapByAccountId;
      const nextAccountProficiencies = {
        ...accountProficiencies,
        [accountId]: {
          ...accountProficiencies[accountId],
          skill_level: nextSkillLevelByAccountId || 0,
          skill_cap: nextSkillCapByAccountId || 0,
          skill_average: nextSkillAvgByAccountId || 0
        }
      };

      // update skillProficiencies
      const nextSkillLevelBySkillTotal =
        skillProficiencies[teamSkillId].skill_level + levelDifference;
      const nextSkillCapBySkillTotal =
        skillProficiencies[teamSkillId].skill_cap +
        (isNewSkill ? 5 : shouldDeleteSkill ? -5 : 0);
      const nextSkillAvgBySkillTotal =
        nextSkillLevelBySkillTotal / nextSkillCapBySkillTotal;
      const nextSkillProficiencies = {
        ...skillProficiencies,
        [teamSkillId]: {
          ...skillProficiencies[teamSkillId],
          skill_level: nextSkillLevelBySkillTotal || 0,
          skill_cap: nextSkillCapBySkillTotal || 0,
          skill_average: nextSkillAvgBySkillTotal || 0
        }
      };
      return {
        ...state,
        skillsReport: {
          ...state.skillsReport,
          [positionId]: {
            accountProficiencies: nextAccountProficiencies,
            skillProficiencies: nextSkillProficiencies,
            teamMemberSkills: nextTeamMemberSkills
          }
        }
      };
    }

    /* ------------------------------ Entity Skills ----------------------------- */

    case constants.FETCH_ENTITY_SKILLS.TRIGGER: {
      const {
        params: { entity_type, attach_suggestions }
      } = payload;
      const initial = !state.entitySkills[entity_type];

      return {
        ...state,
        isFetchingEntitySkills: true,
        ...(initial && {
          entitySkills: {
            ...state.entitySkills,
            [entity_type]: {}
          }
        }),
        ...(attach_suggestions && { skillSuggestions: {} })
        // Clear suggestion when new ones are fetched
      };
    }
    case constants.FETCH_ENTITY_SKILLS.FAILURE: {
      return { ...state, isFetchingEntitySkills: false };
    }
    case constants.FETCH_ENTITY_SKILLS.SUCCESS: {
      const { response, requestPayload } = payload;
      const { entity_type, attach_suggestions } = requestPayload.params;

      const nextState = { ...state, isFetchingEntitySkills: false };

      nextState.entitySkillsHash = {
        ...nextState.entitySkillsHash,
        ...keyBy(response.entity_skills, (entitySkill) => entitySkill.id)
      };
      nextState.entitySkills = {
        ...nextState.entitySkills,
        [entity_type]: {
          ...nextState.entitySkills[entity_type],
          ...groupBy(
            response.entity_skills,
            (entitySkill) => entitySkill.entity_id
          )
        }
      };
      nextState.skillSuggestions = attach_suggestions
        ? response.suggestions
        : state.skillSuggestions; // Can use skillSuggestionsStub to see stub suggestions (but Post Call won't work)

      return nextState;
    }

    case constants.CREATE_ENTITY_SKILL.SUCCESS: {
      return state;
    }

    default:
      return state;
  }
};

export default skills;
