import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cn from 'classnames';

import {
  StyledFlyoutList,
  StyledListItem,
  StyledItemRowContainer,
  PencilIconContainer,
  DragGripsContainer,
  EmptyStateContainer
} from './styles';
import PencilIconBlue from 'icons/PencilIconBlue';
import DragGripIcon from 'icons/DragGripIcon';
import { portal } from 'appUtils/portal';

const emptyArray = [];
const byId = (item) => item.id;
const alwaysDraggable = () => true;

class FlyoutList extends React.Component {
  static defaultProps = {
    idKey: byId,
    items: emptyArray
  };

  setEditing = (e, id) => {
    e.stopPropagation();
    const { setEditing } = this.props;
    setEditing(id);
  };

  renderItem = (item, index) => {
    const {
      renderItem,
      editMode,
      getItemId,
      dragEnabled,
      handleSelect,
      selectCallback,
      unSelectable,
      isItemUnSelectable = () => {},
      listItemContainerStyle = {},
      phases = false,
      dragInitial = false,
      hideEditIcon = false,
      isItemEditable = () => true,
      isInactive = () => {},
      isItemDraggable = alwaysDraggable // only use if prop exists, otherwise return true
    } = this.props;
    const id = (item && getItemId(index, this.props)) || 'new';
    const isDragDisabled = !editMode || !dragEnabled || !isItemDraggable(item);

    return (
      <Draggable
        draggableId={`${id}`}
        key={id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => {
          const usePortal = snapshot.isDragging;
          const child = (
            <StyledListItem
              unSelectable={unSelectable || isItemUnSelectable(item)}
              key={id}
              ref={provided.innerRef}
              listItemContainerStyle={listItemContainerStyle}
              isAlreadySelected={item && item.isAlreadySelected}
              firstOnetoBeAdded={item && item.firstOnetoBeAdded}
              isPhase={phases}
              isInactive={isInactive(item)}
              onClick={(e) => handleSelect(e, { item, selectCallback, index })}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={editMode ? 'edit-mode' : ''}
            >
              <StyledItemRowContainer className="flyout-item-row-container">
                <DragGripsContainer
                  show={
                    !isDragDisabled &&
                    (dragInitial || (editMode && dragEnabled))
                  }
                >
                  <DragGripIcon />
                </DragGripsContainer>
                {renderItem({
                  item,
                  selectCallback,
                  index,
                  onSetItemEditing: (e) => this.setEditing(e, id)
                })}
              </StyledItemRowContainer>
              <PencilIconContainer
                show={editMode && !hideEditIcon && isItemEditable(item)}
                onClick={(e) => this.setEditing(e, id)}
              >
                <PencilIconBlue />
              </PencilIconContainer>
            </StyledListItem>
          );
          return usePortal ? ReactDOM.createPortal(child, portal) : child;
        }}
      </Draggable>
    );
  };

  renderEmpty = () => {
    const { copy } = this.props;
    return <EmptyStateContainer>{copy.emptyState}</EmptyStateContainer>;
  };

  render() {
    const {
      isEmpty,
      isLoading,
      items = [],
      listHeight,
      minListHeight,
      onDragEnd,
      renderLoading,
      renderTableBodyHeader
    } = this.props;

    return (
      <>
        {renderTableBodyHeader && renderTableBodyHeader()}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="flyout" className="styled-flyout-list">
            {(provided, _snapshot) => (
              <StyledFlyoutList
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={cn('styled-flyout-list-container scrollbar', {
                  fixedHeight: listHeight !== undefined
                })}
                $listHeight={listHeight}
                $minListHeight={minListHeight}
              >
                {isLoading
                  ? renderLoading()
                  : isEmpty
                  ? this.renderEmpty()
                  : items.map(this.renderItem)}
                {provided.placeholder}
              </StyledFlyoutList>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }
}

FlyoutList.propTypes = {
  setEditing: PropTypes.func.isRequired,
  getIdKey: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired
};

export default FlyoutList;
