import React, { Component } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { TaskModalFollowedBell } from 'views';
import TaskColumns from './TaskColumns';
import TaskMoveMenuContainer from './TaskMoveMenuContainer';
import ProjectSelectionPopover from 'components/ProjectSelectionPopover';
import { TaskModalTimer } from './TaskModalTimer';
import {
  StyledTaskContentTop,
  TopContentContainer,
  TaskRowNumber,
  TaskProjectTitle,
  TaskProjectDescription,
  SelectedTaskList,
  TaskProjectTitleGroup,
  StyledKaratRight,
  GoToProjectText,
  TaskColumnBox
} from './styles';
import {
  ToggleTextWrapper,
  TaskCalendarToggle
} from 'components/DatePicker/styles/components';
import {
  getAuthToken,
  getMe,
  makeGetIsFollowedByMe,
  makeGetTaskGroup,
  getTeamSlug,
  getIsOnProjectView,
  makeGetProjectBasicInfoById
} from 'selectors';
import { navigateToProject } from 'actionCreators';

const popupAlign = {
  points: ['tl', 'bl'],
  offset: [-10, 32]
};

class TaskContentTop extends Component {
  onFollowClick = () => {
    const { handleTasksAttributesUpdate, token, taskId, me } = this.props;
    handleTasksAttributesUpdate({
      token,
      body: {
        task_ids: [taskId],
        follower_id: me.id
      }
    });
  };

  openTaskModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openTaskModal();
  };

  onMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  getNotificationText = () =>
    `Notifications ${this.props.isFollowedByMe ? 'On' : 'Off'}`;

  navigateToProject = () => {
    const { navigateToProject, teamSlug, task } = this.props;
    const { project } = task;
    const { slug, id, is_personal } = project;

    const navTeamSlug = is_personal ? 'personal' : teamSlug;
    navigateToProject({
      projectSlug: slug,
      projectId: id,
      teamSlug: navTeamSlug
    });
  };

  taskGroupTriggerRef = React.createRef();

  render() {
    const {
      taskIsEditing,
      isFollowedByMe,
      isSelectedHomeTask,
      handleTasksAttributesUpdate,
      isOnTeamProject,
      isBatchSelected,
      isCreatingTask,
      handleTaskEditClick,
      taskEditProperty,
      task,
      isTaskModal,
      show,
      markTaskCompletion,
      rowNumber,
      taskGroup,
      isOnProjectView,
      handleProjectMenuSelection,
      project,
      onDelete,
      handleClose
    } = this.props;
    const showProjectSelector =
      (!isOnProjectView && project) || taskIsEditing || isSelectedHomeTask;
    return (
      <StyledTaskContentTop
        taskIsEditing={taskIsEditing}
        isSelectedHomeTask={isSelectedHomeTask}
        isTaskModal={isTaskModal}
      >
        {!isTaskModal && <TaskRowNumber>{rowNumber}</TaskRowNumber>}
        {show && (
          <>
            {isTaskModal && (
              <TopContentContainer isTaskModal={isTaskModal}>
                <TaskModalFollowedBell
                  isFollowedByMe={isFollowedByMe}
                  onFollowClick={this.onFollowClick}
                />
                <ProjectSelectionPopover
                  target={this.containerRef}
                  disabled={!showProjectSelector}
                  task={task}
                  onSelect={handleProjectMenuSelection}
                  header={null}
                  headerText={'Select Project'}
                  showDeselect={false}
                  popupAlign={popupAlign}
                  isSelectedHomeTask={isSelectedHomeTask}
                  taskGroupTriggerRef={this.taskGroupTriggerRef}
                  project={project}
                  taskGroup={taskGroup}
                >
                  {() => (
                    <TaskProjectTitleGroup>
                      <TaskProjectTitle
                        ref={(ref) => (this.containerRef = ref)}
                        isEmpty={!project?.title}
                        data-for="app-tooltip"
                        data-html="true"
                        data-class="project-info-tooltips"
                        data-tip={
                          project?.title
                            ? `${project?.title} <br /> ${
                                project.description
                                  ? `${project.description}`
                                  : ''
                              }`
                            : ''
                        }
                      >
                        {project?.title || 'Select Project'}
                        <TaskProjectDescription>
                          {project?.description
                            ? ` - ${project.description}`
                            : ''}
                        </TaskProjectDescription>
                      </TaskProjectTitle>

                      {taskGroup?.name && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <StyledKaratRight />
                          </div>
                          <SelectedTaskList ref={this.taskGroupTriggerRef}>
                            {taskGroup.name}
                          </SelectedTaskList>
                        </>
                      )}
                      {project?.id && (
                        <GoToProjectText
                          show={!isOnProjectView}
                          onClick={
                            !isOnProjectView ? this.navigateToProject : ''
                          }
                        >
                          Go to Project
                        </GoToProjectText>
                      )}
                    </TaskProjectTitleGroup>
                  )}
                </ProjectSelectionPopover>
                <ReactTooltip
                  className="notification-tooltip"
                  effect="solid"
                  id={`notification-${task.id}`}
                  place="right"
                />
              </TopContentContainer>
            )}
            <StyledTopContentContainer>
              {isSelectedHomeTask && (
                <>
                  <TaskColumns
                    taskIsEditing={taskIsEditing}
                    task={task}
                    handleTasksAttributesUpdate={handleTasksAttributesUpdate}
                    isOnTeamProject={isOnTeamProject}
                    isBatchSelected={isBatchSelected}
                    isCreatingTask={isCreatingTask}
                    handleTaskEditClick={handleTaskEditClick}
                    taskEditProperty={taskEditProperty}
                    isTaskModal={isTaskModal}
                    alwaysRenderInput
                  />
                  <TaskModalTimer task={task} />
                  <TaskMoveMenuContainer
                    markTaskCompletion={markTaskCompletion}
                    taskId={task.id}
                    onDelete={onDelete}
                  />
                  <DoneButton onClick={handleClose}> Done </DoneButton>
                </>
              )}
            </StyledTopContentContainer>
          </>
        )}
      </StyledTaskContentTop>
    );
  }
}

const StyledTopContentContainer = styled(TopContentContainer)`
  margin-top: 20px;
  ${TaskColumnBox} {
    margin: 0;
    width: unset;
    margin: 0 4px;
    &:hover {
      background: ${theme.colors.colorTranslucentGray4};
    }
    border-radius: 5px;
    height: fit-content;
    padding: 7px 11px;
    margin-top: 5px;
  }
  ${TaskCalendarToggle} {
    min-width: unset;
    height: unset;
    color: ${theme.colors.colorCalendarBlue};
    &:hover {
      color: ${theme.colors.colorCalendarBlue};
    }
  }
  ${ToggleTextWrapper} {
    min-width: unset;
    font-size: 14px;
    cursor: pointer;
  }
`;

const DoneButton = styled.div`
  background: ${theme.colors.colorRoyalBlue};
  font-weight: 600;
  font-size: 15px;
  color: ${theme.colors.colorPureWhite};
  border-radius: 5px;
  padding: 5px 15px;
  margin: 0 20px;
`;

const projectIdGetter = (state, ownProps) => ownProps.task?.project_id;
const makeMapStateToProps = () => {
  const getIsFollowedByMe = makeGetIsFollowedByMe();
  const getTaskGroup = makeGetTaskGroup();
  const getProject = makeGetProjectBasicInfoById({ projectIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    isFollowedByMe: getIsFollowedByMe(state, ownProps),
    token: getAuthToken(state),
    me: getMe(state),
    taskGroup: getTaskGroup(state, ownProps),
    teamSlug: getTeamSlug(state),
    isOnProjectView: getIsOnProjectView(state),
    project: getProject(state, ownProps)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps, { navigateToProject })(
  TaskContentTop
);
