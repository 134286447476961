import styled, { css } from 'styled-components';
import theme from 'theme';
import { StyledStickyHeader } from 'components/Table/TableStickyHeader';
import type { PermissionsTableVariant } from './types';

const ContainerDivStylesByTableVariant: Record<
  PermissionsTableVariant,
  ReturnType<typeof css>
> = {
  access: css``,
  projects: css``
};

export const ContainerDiv = styled.div<{
  tableVariant: PermissionsTableVariant;
}>`
  /* base shared styles */
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  /* table variant specific styles */
  ${({ tableVariant }) => ContainerDivStylesByTableVariant[tableVariant]}
`;

const StyledPermissionsTableStylesByTableVariant: Record<
  PermissionsTableVariant,
  ReturnType<typeof css>
> = {
  access: css``,
  projects: css``
};

export const StyledPermissionsTable = styled.div<{
  tableVariant: PermissionsTableVariant;
}>`
  /* base shared styles */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .table,
  .table-body {
    margin-bottom: 0;
    height: 100%;
    width: max-content;
  }

  .variable-size-list {
    height: 100% !important;
    padding-bottom: 20px;
    overflow-x: hidden !important;
  }

  .td {
    height: 100%;
    flex-shrink: 0;

    &.rightPadding {
      flex-shrink: 1;
    }
  }

  ${StyledStickyHeader} {
    padding: 0;
    .td {
      background: ${theme.colors.colorTranslucentGray4};
    }
  }

  .permissionsSectionHeaderRow.tr {
    border-bottom: 1px solid ${theme.colors.colorLightGray15};
  }

  .permissionRow.tr {
    &:not(:first-child) {
      border-top: 1px solid ${theme.colors.colorPaleGray9};
    }
  }

  /* table variant specific styles */
  ${({ tableVariant }) =>
    StyledPermissionsTableStylesByTableVariant[tableVariant]}
`;
