import React from 'react';

import { connect } from 'react-redux';
import { clearResetPasswordForm } from 'actionCreators';
import { ForgotPasswordForm } from '..';
import logo from 'images/mosaic-logo.png';
import close from 'images/close-to-login.svg';
import { getAuthState } from 'AuthenticationModule/selectors';
import { resetPassword } from 'AuthenticationModule/actionCreators';
class ForgotPasswordContainer extends React.Component {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    this.props.clearResetPasswordForm();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  loginClick = () => {
    const { history } = this.props;
    const isMobile = this.state.width < 500;

    if (isMobile) {
      window.location.href = 'mosaicmobile://login';
    } else {
      history.push('/login');
    }
  };

  render() {
    return (
      <div className="auth-page">
        {this.state.width > 500 ? (
          <img src={logo} className="login-logo" onClick={this.loginClick} />
        ) : null}
        {this.state.width > 500 ? (
          <img src={close} className="close-button" onClick={this.loginClick} />
        ) : null}
        <ForgotPasswordForm
          width={this.state.width}
          emailSent={this.props.auth.resetPasswordEmailSent}
          handleSubmit={(fields) => {
            this.props.resetPassword(fields);
          }}
          errorMessage={
            this.props.error
              ? 'Sorry! The activation link you clicked is either invalid or has expired.'
              : null
          }
          loginClick={this.loginClick}
          passwordSent={this.props.emailSent}
          statusText={this.props.statusText}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuthState(state),
  emailSent: state.auth.resetPasswordEmailSent,
  statusText: state.auth.statusText
});

const mapDispatchToProps = {
  resetPassword,
  clearResetPasswordForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
