import { useCallback } from 'react';
import { serializeId } from 'appUtils';
import { ROW_TYPES } from '../tableConfigs';
import type { PermissionListItem, PermissionListItemsBuilder } from '../types';
import { PermissionsActions } from 'PermissionsModule/types';

export const usePermissionListItemsBuilder = () => {
  const permissionListItemsBuilder: PermissionListItemsBuilder = useCallback(
    ({ order, parentGroupId }) => {
      const listItems = (order as PermissionsActions[]).map((permissionId) => {
        const id = serializeId({
          itemType: parentGroupId,
          ids: [permissionId],
          id: undefined
        });

        const filterListItem: PermissionListItem = {
          permissionId,
          rowType: ROW_TYPES.permissionRow,
          rowHeight: 50,
          id
        };

        return filterListItem;
      });
      return listItems;
    },
    []
  );

  return permissionListItemsBuilder;
};
