import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import { serializeBillingCategoryPhaseId } from 'BudgetModule/utils';
import omit from 'lodash/omit';

export const initialState = {
  billingTableView: constants.BILLING_TABLE_VIEWS.ESTIMATES,
  billingCategoriesHash: {},
  billingCategoryOrderByProject: {},
  invoicesHash: {},
  invoicesByBillingCategoryAndPhase: {},
  estimatesByBillingCategoryAndPhase: {},
  isFetchingProjectBillingCategories: false,
  isFetchingProjectInvoices: false
};
const byId = (item) => item.id;

const budgetBilling = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    case budgetConstants.FETCH_PROJECT_BILLING_CATEGORIES.TRIGGER: {
      return {
        ...state,
        isFetchingProjectBillingCategories: true
      };
    }

    case budgetConstants.FETCH_PROJECT_BILLING_CATEGORIES.SUCCESS: {
      const billingCategories = action.payload.response;
      const { projectId } = action.payload.requestPayload;
      return {
        ...state,
        isFetchingProjectBillingCategories: false,
        billingCategoryOrderByProject: {
          ...state.billingCategoryOrderByProject,
          [projectId]: billingCategories.map(
            (billingCategory) => billingCategory.id
          )
        },
        billingCategoriesHash: {
          ...state.billingCategoriesHash,
          ...keyBy(billingCategories, byId)
        }
      };
    }

    case budgetConstants.FETCH_PROJECT_BILLING_CATEGORIES.FAILURE: {
      return {
        ...state,
        isFetchingProjectBillingCategories: false
      };
    }

    case budgetConstants.FETCH_PROJECT_INVOICES.TRIGGER: {
      return {
        ...state,
        isFetchingProjectInvoices: true
      };
    }

    case budgetConstants.FETCH_PROJECT_INVOICES.SUCCESS: {
      const invoices = action.payload.response;
      const isBillingEstimates = action.payload.requestPayload.is_estimate;
      const nextInvoicesOrders = {};

      invoices.forEach((invoice) => {
        const groupId = serializeBillingCategoryPhaseId({
          billingCategoryId: invoice.billing_category_id,
          phaseId: invoice.phase_id
        });
        if (nextInvoicesOrders[groupId]) {
          nextInvoicesOrders[groupId].push(invoice.id);
        } else {
          nextInvoicesOrders[groupId] = [invoice.id];
        }
      });

      const orderHash = isBillingEstimates
        ? 'estimatesByBillingCategoryAndPhase'
        : 'invoicesByBillingCategoryAndPhase';

      return {
        ...state,
        invoicesHash: {
          ...state.invoicesHash,
          ...keyBy(invoices, byId)
        },
        isFetchingProjectInvoices: false,
        [orderHash]: nextInvoicesOrders
      };
    }

    case budgetConstants.FETCH_PROJECT_INVOICES.FAILURE: {
      return {
        ...state,
        isFetchingProjectInvoices: false
      };
    }

    case budgetConstants.CREATE_PROJECT_INVOICE.SUCCESS: {
      const invoice = action.payload.response;
      const isBillingEstimate = invoice.is_estimate;
      const groupId = serializeBillingCategoryPhaseId({
        billingCategoryId: invoice.billing_category_id,
        phaseId: invoice.phase_id
      });
      const orderHash = isBillingEstimate
        ? 'estimatesByBillingCategoryAndPhase'
        : 'invoicesByBillingCategoryAndPhase';

      return {
        ...state,
        invoicesHash: {
          ...state.invoicesHash,
          [invoice.id]: invoice
        },
        [orderHash]: {
          ...state[orderHash],
          [groupId]: [...(state[orderHash][groupId] || []), invoice.id]
        }
      };
    }

    case budgetConstants.UPDATE_PROJECT_INVOICE.TRIGGER: {
      const { body, id } = action.payload;
      return {
        ...state,
        invoicesHash: {
          ...state.invoicesHash,
          [id]: {
            ...state.invoicesHash[id],
            ...body
          }
        }
      };
    }

    case budgetConstants.UPDATE_PROJECT_INVOICE.FAILURE: {
      const { original, id } = action.payload.requestPayload;
      return {
        ...state,
        invoicesHash: {
          ...state.invoicesHash,
          [id]: original
        }
      };
    }

    case budgetConstants.UPDATE_PROJECT_INVOICE.SUCCESS: {
      const invoice = action.payload.response;
      return {
        ...state,
        invoicesHash: {
          ...state.invoicesHash,
          [invoice.id]: invoice
        }
      };
    }

    case budgetConstants.DELETE_PROJECT_INVOICE.SUCCESS: {
      const { billingCategoryId, invoiceId, phaseId, isBillingEstimate } =
        action.payload.requestPayload;
      const orderHash = isBillingEstimate
        ? 'estimatesByBillingCategoryAndPhase'
        : 'invoicesByBillingCategoryAndPhase';
      const groupId = serializeBillingCategoryPhaseId({
        billingCategoryId,
        phaseId
      });
      const nextInvoiceOrderHash = { ...state[orderHash] };
      if (nextInvoiceOrderHash[groupId]) {
        nextInvoiceOrderHash[groupId] = nextInvoiceOrderHash[groupId].filter(
          (id) => id !== invoiceId
        );
      }

      return {
        ...state,
        invoicesHash: omit(state.invoicesHash, invoiceId),
        [orderHash]: nextInvoiceOrderHash
      };
    }

    case budgetConstants.SET_BILLING_TABLE_VIEW: {
      return {
        ...state,
        billingTableView: action.payload.view
      };
    }

    default:
      return state;
  }
};

export default budgetBilling;
