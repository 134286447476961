import { useCallback, useContext, useState, MutableRefObject } from 'react';
import { useAppDispatch } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import EllipsisText from 'components/EllipsisText';
import QBDownArrow from 'icons/QBDownArrow';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle
} from 'components/BatchActions/styles';
import { SimpleFilterDropdown } from 'FilterModule/components/FilterDropdowns/SimpleFilterDropdown';
import { phaseSelectorDoneBtnId } from './constants';
import { BlueSubmitButton } from 'components/styles';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { openMilestoneModal } from 'actionCreators';

const noop = () => undefined;

const PhasesDropdown = ({
  handleDone,
  target,
  toggle,
  open,
  initialSelectedPhases = []
}: {
  handleDone: ({ phaseIds }: { phaseIds: number[] }) => void;
  toggle: () => void;
  target: MutableRefObject<HTMLElement | null>;
  open: boolean;
  initialSelectedPhases?: number[];
}) => {
  const { phasesHash, activeProjectPhasesOrder, project } = useContext(
    FindPeopleModalContext
  );

  const [selectedPhases, setSelectedPhases] = useState<number[]>(
    initialSelectedPhases
  );

  const dispatch = useAppDispatch();

  const renderPhaseItem = useCallback(
    ({ id, isSelected }) => {
      const phase = phasesHash[id];

      return (
        <StyledPhaseContainer>
          <StyledSelectToggleContainer>
            <StyledSelectToggle isChecked={isSelected} />
          </StyledSelectToggleContainer>

          <BudgetPhaseMilestoneIcon
            className={undefined}
            strokeColor={theme.colors.colorMediumGray9}
            strokeWidth={undefined}
            width={'13'}
            height={'11'}
          />
          <EllipsisText maxWidth={180}>{phase?.name}</EllipsisText>
        </StyledPhaseContainer>
      );
    },
    [phasesHash]
  );

  const renderPhaseToggle = useCallback(() => <></>, []);

  const renderHeaderButton = useCallback(
    ({ handleClose }) => {
      return (
        <StyledPhaseDoneButton
          id={phaseSelectorDoneBtnId}
          onClick={(e) => {
            handleDone({ phaseIds: selectedPhases });
            handleClose();
          }}
        >
          Done
        </StyledPhaseDoneButton>
      );
    },
    [handleDone, selectedPhases]
  );

  const onFooterClick = ({ e, handleClose }) => {
    if (project) {
      handleClose(e);
      dispatch(openMilestoneModal({ project }));
    }
  };

  return (
    <SimpleFilterDropdown
      items={activeProjectPhasesOrder}
      parentOpen={open}
      parentToggle={toggle}
      parentRef={target}
      itemHash={phasesHash}
      selectedItems={selectedPhases}
      updateSelectedItems={(items: number[]) => setSelectedPhases(items)}
      renderToggle={renderPhaseToggle}
      customRenderItem={renderPhaseItem}
      renderHeaderButton={renderHeaderButton}
      listWidth={250}
      itemFilterableKeys={['name']}
      hasNoHeader={false}
      onClose={undefined}
      labels={{
        searchPlaceholder: 'Search phase or select below',
        headerInitial: 'Select Phases'
        // footerInitial: (
        //   <StyledFooterContainer>
        //     <BudgetPhaseMilestoneIcon
        //       className={undefined}
        //       strokeWidth={undefined}
        //     />
        //     Manage Project Phases
        //   </StyledFooterContainer>
        // )
      }}
      labelKey={'title'}
      popoverClassName={'team-builder-phases-dropdown'}
    />
  );
};

export default PhasesDropdown;

const StyledPhaseContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
`;

const StyledPhaseToggle = styled.div`
  width: fit-content;
  border: 1px solid ${theme.colors.colorCalendarBlue};
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

const StyledPhaseDoneButton = styled(BlueSubmitButton)``;

const StyledFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  path {
    stroke: ${theme.colors.colorRoyalBlue};
  }
`;
