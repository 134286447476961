import { Phase } from 'ProjectsModule/phases/models/phase';
import { ActivityPhase } from 'ActivityPhaseModule/models/activityPhase';
import { ActivityPhaseMembership } from 'ActivityPhaseModule/models/activityPhaseMembership';
import { PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { serializeId } from 'appUtils';
import { getSuggestionsByPhaseMembership } from 'selectors';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import { initialState as initialBudgetRecordsState } from 'BudgetModule/reducers/budgetRecords';
import orderBy from 'lodash/orderBy';
import { generateReasons } from 'views/boardDisplay/SuggestionsTable/util';
import {
  fetchPhaseTotals,
  fetchPhasesByProjectIds,
  fetchAllProjects,
  fetchProjectById,
  fetchProjectTeam,
  fetchTeamMembers
} from 'actionCreators';
import { reasonsToNotShowOnUI } from './constants';
import { fetchMemberBudgets } from 'BudgetModule/actionCreators';
import { FetchAccountsSuggestionForActivityPhaseMembershipsSuccessResponseInstance } from 'SuggestionModule/types';
import { SuggestedMemberForOpenPosition } from './types';
import moment from 'moment';

/**
 * A getter for phase dates
 *
 * Scenarios
 * 1) If phase does not have activities (OR Has only Default Activity Phase)
 *  => phaseEntity will be Phase, activityPhaseMembership will belong to the default activity phase
 * 2) If phase has at least 1 activities
 *  => phaseEntity will be Activity Phase, activityPhaseMembership will belong to this non-default activity phase
 *
 *
 * On Members level, phaseEntity can be defined by checking whether the activity phase is default or not
 * Again, the activity phase being Default means that the phase does NOT have non-default activities (activity_order.length = 0)
 */
export const getPhaseDatesData = ({
  phaseEntity,
  activityPhaseMembership
}: {
  phaseEntity: ActivityPhase | Phase | undefined;
  activityPhaseMembership: ActivityPhaseMembership | undefined;
}) => {
  const phaseEntityStartDate = phaseEntity?.start_date;
  const phaseEntityEndDate = phaseEntity?.end_date;
  const phaseEntityHasDates = !!phaseEntityStartDate && !!phaseEntityEndDate;

  const activityPhaseMembershipStartDate = activityPhaseMembership?.start_date;
  const activityPhaseMembershipEndDate = activityPhaseMembership?.end_date;
  const activityPhaseMembershipHasDates =
    !!activityPhaseMembershipStartDate && !!activityPhaseMembershipEndDate;

  // Spec - Default to activity phase or parent phase start and end date if activity phase membership does not have date
  const startDateToUse = activityPhaseMembershipHasDates
    ? activityPhaseMembershipStartDate
    : phaseEntityStartDate;
  const endDateToUse = activityPhaseMembershipHasDates
    ? activityPhaseMembershipEndDate
    : phaseEntityEndDate;

  return {
    startDateToUse,
    endDateToUse,

    phaseEntityStartDate,
    phaseEntityEndDate,
    phaseEntityHasDates,

    /* -------------------------------------------------------------------------- */
    activityPhaseMembershipEndDate,
    activityPhaseMembershipStartDate,
    activityPhaseMembershipHasDates
  };
};

export const fetchBudgetRecordsForPhasesFilterStateModifier = ({
  state,
  action,
  filterStateId
}: {
  state: typeof initialBudgetRecordsState;
  action: {
    payload: {
      response: {
        records: {
          member_budget_id: number;
          phase_id: number;
        }[];
        total: number;
      };
      requestPayload: {
        params: {
          data_type: typeof BUDGET_RECORD_DATA_TYPES['ACCOUNT_PHASE'];
          member_budget_ids: number[];
          phase_ids: number[];
          project_ids: number[];
          start_date: string;
          end_date: string;
        };
      };
    };
  };
  filterStateId: typeof FilterStateIds['fetchBudgetRecordsForPhases'];
}) => {
  const { records = [], total } = action.payload.response;
  const {
    data_type,
    member_budget_ids = [],
    phase_ids = [],
    project_ids = [],
    start_date,
    end_date
  } = action.payload.requestPayload.params || {};
  // Individual api call where ids = [entityId], due to each phase has different start and end date
  const projectId = project_ids[0];
  const phaseId = phase_ids[0];
  const memberBudgetId = member_budget_ids[0];

  const nextState = {
    ...state
  };

  if (!phaseId || !memberBudgetId) return nextState;

  const uniqueRecordData = records.find(
    (record) =>
      record.member_budget_id === memberBudgetId && record.phase_id === phaseId
  );

  const uid = serializeId({
    itemType: data_type,
    id: undefined,
    ids: [phaseId, memberBudgetId]
  });

  /**
   * {
   *   recordsByAggregateType: {
   *    [data_type]: {
   *       [memberBudgetId]: {
   *         [uid]: data
   *        }
   *     }
   *   }
   * }
   *
   */

  const nextFilterState = {
    ...nextState.filterStates[filterStateId],
    recordsByAggregateType: {
      ...nextState.filterStates[filterStateId]?.recordsByAggregateType,
      [data_type]: {
        ...nextState.filterStates[filterStateId]?.recordsByAggregateType?.[
          data_type
        ],
        [uid]: uniqueRecordData
      }
    }
  };

  nextState.filterStates = {
    ...nextState.filterStates,
    [filterStateId]: nextFilterState
  };

  return nextState;
};

const SUGGESTIONS_LIMIT = 5;

const REASON_DISPLAY_LIMIT = 5;

/**
 * FE util to create mock reasons based on phase membership id
 */
const generateSortedReasons = (id: number) => {
  const reasonsObj = generateReasons(id);
  const sortedReasons = orderBy(
    Object.entries(reasonsObj).map(([key, value]) => ({ key, value })),
    'value',
    'desc'
  )
    .slice(0, REASON_DISPLAY_LIMIT)
    .reduce((acc, reason) => {
      acc[reason.key] = reason.value;
      return acc;
    }, {});
  return sortedReasons;
};

/**
 * Deprecated: Only use for fetching suggestion by phase membership. The endpoint is deprecated
 */
export const makeSuggestedMembersList = ({
  realSuggestionsByPhaseMemberships,
  phaseMembershipId,
  isShowingAllMembers,
  teamMembershipsByAccountId
}: {
  realSuggestionsByPhaseMemberships: ReturnType<
    typeof getSuggestionsByPhaseMembership
  >;
  phaseMembershipId?: number;
  isShowingAllMembers: boolean;
  teamMembershipsByAccountId: ReturnType<typeof getTeamMembershipsByAccountId>;
}) => {
  const rawList = phaseMembershipId
    ? realSuggestionsByPhaseMemberships[phaseMembershipId]?.suggestions?.reduce(
        (acc, member) => {
          if (teamMembershipsByAccountId[member.account_id]) {
            acc.push(member);
          }

          return acc;
        },
        []
      )
    : undefined;

  if (!rawList)
    return {
      suggestedMembersToShow: [],
      allSuggestedMembers: [],
      numOfRemainingSuggestedMembers: 0,
      shouldShowMemberAlternatesRow: false,
      memberIdsOrder: []
    };

  /**
   * Future: When member rejection is supported
   * - Split the list into two lists:
   *  1) Suggested members that are not rejected
   *  2) Suggested members that are rejected
   * - Sort the first list
   * - Then at the end [suggestedMembersToShow,...rejectedOnes]
   */
  const sortedAllMembers = orderBy(rawList, 'score', 'desc');
  const topSuggestedMembersInLimit = sortedAllMembers.slice(
    0,
    SUGGESTIONS_LIMIT
  );

  // Only showing member alternates row if number of all suggested members equals to number of top suggested members
  const shouldShowMemberAlternatesRow =
    topSuggestedMembersInLimit.length < sortedAllMembers.length;

  /**
   * Case 1: If we need to show member alternates row
   *  - Check toggle state (isShowingAllMembers) to see whether
   *   - If toggle is on, show all members
   *   - If toggle is off, show top suggested members
   * Case 2: If we don't need to show member alternates row
   *  - Then we know that we should be showing all members
   */
  const suggestedMembersToShow = shouldShowMemberAlternatesRow
    ? isShowingAllMembers
      ? sortedAllMembers
      : topSuggestedMembersInLimit
    : sortedAllMembers;

  const numOfRemainingSuggestedMembers = shouldShowMemberAlternatesRow
    ? isShowingAllMembers
      ? 0
      : sortedAllMembers.length - suggestedMembersToShow.length
    : sortedAllMembers.length;

  const memberIdsOrder = suggestedMembersToShow.map(
    (member) => member.account_id
  );

  return {
    suggestedMembersToShow,
    numOfRemainingSuggestedMembers,
    allSuggestedMembers: sortedAllMembers,
    shouldShowMemberAlternatesRow,
    memberIdsOrder
  };
};

export const makeSuggestedMemberListFromActivityPhaseMembershipSuggestions = ({
  accountSuggestionsByActivityPhaseMemberships,
  activityPhaseMembershipId,
  isShowingAllMembers,
  teamMembershipsByAccountId
}: {
  accountSuggestionsByActivityPhaseMemberships: Record<
    number,
    FetchAccountsSuggestionForActivityPhaseMembershipsSuccessResponseInstance
  >;
  activityPhaseMembershipId?: number;
  isShowingAllMembers: boolean;
  teamMembershipsByAccountId: ReturnType<typeof getTeamMembershipsByAccountId>;
}) => {
  const rawList = activityPhaseMembershipId
    ? accountSuggestionsByActivityPhaseMemberships[
        activityPhaseMembershipId
      ]?.suggestions?.reduce(
        (acc: SuggestedMemberForOpenPosition[], member) => {
          if (teamMembershipsByAccountId[member.account_id]) {
            acc.push(member);
          }

          return acc;
        },
        []
      )
    : undefined;

  if (!rawList)
    return {
      suggestedMembersToShow: [],
      allSuggestedMembers: [],
      numOfRemainingSuggestedMembers: 0,
      shouldShowMemberAlternatesRow: false,
      memberIdsOrder: []
    };

  /**
   * Future: When member rejection is supported
   * - Split the list into two lists:
   *  1) Suggested members that are not rejected
   *  2) Suggested members that are rejected
   * - Sort the first list
   * - Then at the end [suggestedMembersToShow,...rejectedOnes]
   */
  const sortedAllMembers = orderBy(rawList, 'score', 'desc');
  const topSuggestedMembersInLimit = sortedAllMembers.slice(
    0,
    SUGGESTIONS_LIMIT
  );

  // Only showing member alternates row (To click on to show all the remaining suggestion rows) if number of all suggested members larger than number of top suggested members
  // No point to show the alternate row otherwise, because there is nothing else to show
  const shouldShowMemberAlternatesRow =
    topSuggestedMembersInLimit.length < sortedAllMembers.length;

  /**
   * Case 1: If we need to show member alternates row
   *  - Check toggle state (isShowingAllMembers) to see whether
   *   - If toggle is on, show all members
   *   - If toggle is off, show top suggested members
   * Case 2: If we don't need to show member alternates row
   *  - Then we know that we should be showing all members
   */
  const suggestedMembersToShow = shouldShowMemberAlternatesRow
    ? isShowingAllMembers
      ? sortedAllMembers
      : topSuggestedMembersInLimit
    : sortedAllMembers;

  const numOfRemainingSuggestedMembers = shouldShowMemberAlternatesRow
    ? isShowingAllMembers
      ? 0
      : sortedAllMembers.length - suggestedMembersToShow.length
    : sortedAllMembers.length;

  const memberIdsOrder = suggestedMembersToShow.map(
    (member) => member.account_id
  );

  return {
    suggestedMembersToShow,
    numOfRemainingSuggestedMembers,
    allSuggestedMembers: sortedAllMembers,
    shouldShowMemberAlternatesRow,
    memberIdsOrder
  };
};

export const serializeMemberAlternatesRowId = ({ id }) => {
  return `member-alternates-row-${id}`;
};

/**
 *
 * Get a list of follow-up actions as seen in createPhaseMembersWorkers
 * @returns AnyActions[]
 */
export const getCreatePhaseMembersRefetchActions = ({
  projectId,
  filterStateId = FilterStateIds.fetchPhaseTotals
}: {
  projectId: number;
  filterStateId?: string;
}) => {
  return [
    fetchPhasesByProjectIds({ projectIds: [projectId] }),
    fetchPhaseTotals({
      projectId,
      initial: true,
      filterStateId
    }),
    fetchAllProjects({
      projectIds: [projectId]
    }),
    fetchProjectById(projectId),
    fetchProjectTeam(projectId),
    fetchTeamMembers()
  ];
};

export const getAssignMemberBudgetRefetchActions = ({
  projectId,
  memberBudgetId,
  filterStateId = FilterStateIds.fetchPhaseTotals
}: {
  projectId: number;
  memberBudgetId: number;
  filterStateId?: string;
}) => {
  return [
    fetchPhasesByProjectIds({ projectIds: [projectId] }),
    fetchPhaseTotals({
      projectId,
      initial: true,
      filterStateId
    }),
    fetchMemberBudgets({ projectId, memberBudgetIds: [memberBudgetId] })
  ];
};

/**
 * Filter out reasons that we don't want to show on the UI
 */
export const filterReasons = (reasons: string[]) => {
  return reasons.filter((reason) => !reasonsToNotShowOnUI.has(reason));
};

/**
 * Calculating this suggested member total availability, total capacity
 * within the date range of startDate and endDate
 */
export const calculateSuggestedMemberHours = ({
  member,
  startDate,
  endDate
}: {
  member: SuggestedMemberForOpenPosition;
  startDate: Nullable<string> | undefined;
  endDate: Nullable<string> | undefined;
}) => {
  const memberSchedule = member.schedule || {};
  const scheduleDateKeys = Object.keys(memberSchedule);
  let totalCapacity = 0;
  let totalAvailability = 0;

  scheduleDateKeys.forEach((date) => {
    // Get the available hours and capacity between the selected start and end dates inclusively
    if (moment(date).isBetween(startDate, endDate, 'day', '[]')) {
      const { remaining_capacity, total_capacity } = memberSchedule[date] || {};
      totalCapacity += total_capacity ?? 0;
      totalAvailability += remaining_capacity ?? 0;
    }
  });

  // Formatting (there is a case where these numbers can bee in long decimals)
  totalAvailability = Math.round(totalAvailability);
  totalCapacity = Math.round(totalCapacity);

  return {
    totalAvailability,
    totalCapacity,
    availabilityToCapacityRatio: Math.round(
      Math.min(
        totalCapacity ? (totalAvailability / totalCapacity) * 100 : 0,
        100
      )
    )
  };
};
