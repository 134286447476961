import * as Sentry from '@sentry/browser';
import { put, select, take } from 'redux-saga/effects';
import { generatePath } from 'react-router';
import { history } from 'store/configureStore';
import {
  calcBackDestination,
  calcUpDestinationIndex,
  getLastLocation
} from 'appUtils/navigation';
import {
  getNavigationHistory,
  getIsNavigationBlocked,
  getMatchedRouteParams,
  getCurrentLocation,
  getSplitFlags,
  getLastLocationsState
} from 'selectors';

import {
  SectionRoutes,
  ModalRoutes,
  Sections,
  Views,
  Modals
} from 'appConstants/navigation';
import * as constants from 'appConstants';
import * as actionCreators from 'actionCreators';
import { stringify } from 'query-string';

const checkFeatureFlags = (section, view, modal, flags) => {
  // add features that should be disabled here
  return true;
};
const fallbackPayload = {
  userSlug: 'user',
  teamSlug: 'company'
};

export function* navigateUp(action) {
  const payload = action.payload;
  if (payload && payload.skipNavigateUp) {
    return;
  }
  const ignoreQueryParams = payload?.ignoreQueryParams;
  const navigationHistory = yield select(getNavigationHistory);
  const matchedParams = yield select(getMatchedRouteParams);
  const index = calcUpDestinationIndex(navigationHistory);
  yield put(actionCreators.navigateUpCleanup(payload));
  const query = window.location.search;
  let path = `/${matchedParams.userSlug}/home/tasks`;
  if (index < navigationHistory.length) {
    path =
      navigationHistory[index].match.url + (ignoreQueryParams ? '' : query);
  }
  history.push(path);
}

export function* navigateBack(action) {
  const payload = action.payload;
  if (payload && payload.skipNavigateBack) {
    return;
  }
  // This function will send you back to one these
  // - the first non-project section you were on
  // - home
  const navigationHistory = yield select(getNavigationHistory);
  const isNavigationBlocked = yield select(getIsNavigationBlocked);
  const { destination } = calcBackDestination(navigationHistory);
  if (!isNavigationBlocked) {
    yield put(actionCreators.navigateBackCleanup(payload));
  }
  let path = `/`;
  if (destination) {
    path = `${destination.match.url}`;
  }
  history.push(path);
}

export function* navigateTo(action) {
  const { payload } = action;

  const mergePayload = {};
  const flags = yield select(getSplitFlags);
  const lastLocationsState = yield select(getLastLocationsState);
  const {
    section,
    viewType,
    modal,
    queryParams,
    usePath,
    openInNewWindow,
    useLastLocation
  } = payload;
  const isFeatureEnabled = checkFeatureFlags(section, viewType, modal, flags);
  let route;
  let path;

  const lastLocation =
    useLastLocation && getLastLocation({ lastLocationsState, section });

  try {
    if (section) {
      route = SectionRoutes[section];
    } else {
      const currentLocation = yield select(getCurrentLocation);
      const { match } = currentLocation;
      route = `${match[usePath ? 'path' : 'url']}`;
    }
    if (modal) {
      const modalRoute = ModalRoutes[modal];
      if (!modalRoute) {
        // TODO log bad modal param to error reporting service
      }
      route += `/${modalRoute}`;
    }
    path = generatePath(route, {
      ...fallbackPayload /*
         prevent errors when navigating before slug is known.
         Slug does not defined data or behavior.
         cases:
         1. payload key/values are not required - generatePath ignores them
         2. payload key/values are required and provided already, keys get overridden by provided values
         3. payload key/values are required and not provided - error prevents and fallback url displayed (ex: "home/user/tasks" as fallback instead of "home/my_special_user_slug/tasks" )
         4. payload key/values are optional and presence affects how route is parsed - this case is not supported by this fix, but is not present in the application.
    */,
      ...payload,
      ...mergePayload,
      // Remove this once split flag for personal dashboard is not necessary
      ...(useLastLocation && { homeViewType: lastLocation })
    });
  } catch (error) {
    path = '/';
    Sentry.captureException(error);
    if (__DEV__) {
      console.error(error);
    }
  }

  if (queryParams) {
    path += `?${stringify(queryParams)}`;
  }

  if (isFeatureEnabled) {
    if (openInNewWindow) {
      window.open(path, '_blank');
    } else {
      history.push(path);
    }
  } else {
    Sentry.withScope((scope) => {
      scope.setExtra('flags', flags);
      scope.setExtra('path', path);
      Sentry.captureException('feature disabled');
    });
    if (__DEV__) {
      console.error({ isFeatureEnabled });
    }
  }
}

export function* navigationChange(action) {
  const { payload } = action;
  const flags = yield select(getSplitFlags);
  const { section, view, modal } = payload;
  const isFeatureEnabled = checkFeatureFlags(section, view, modal, flags);
  if (!isFeatureEnabled) {
    yield put(actionCreators.navigateBack(action.payload));
  }
}

export function* closeAndClearTaskViewModal(action) {
  yield put(actionCreators.navigateUp(action.payload));
}

export function* closeAndClearMemberModal(action) {
  yield put(actionCreators.navigateUp(action.payload));
}
