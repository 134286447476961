import theme from 'theme';
import styled from 'styled-components';
import SearchIcon from 'icons/SearchIcon';
import KaratRight from 'icons/KaratRight';

export const CancelButton = styled.button`
  color: ${theme.colors.colorMediumGray1};
  border: 1px solid ${theme.colors.colorPaleGray5};
  font-size: 15px;
  background: transparent;
  border-radius: 5px;
  padding: 3px 10px;
  margin: 0px 5px;
  font-weight: 600;
  &:hover {
    background: ${theme.colors.colorPaleGray5};
    border: 1px solid ${theme.colors.colorPaleGray5};
    color: ${theme.colors.colorMediumGray5};
  }
`;

export const ConfirmButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 15px;
  color: ${theme.colors.colorPureWhite};
  padding: 3px 12px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};
  font-weight: 600;

  &:hover {
    filter: brightness(95%);
  }
`;

export const CustomButton = styled.button`
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 5px;
  padding: 3px 12px;
  color: ${theme.colors.colorCalendarBlue};
  font-weight: 600;
  font-size: 15px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
  position: relative;
  top: -2px;
`;

export const IconContainer = styled.div``;
export const HeaderText = styled.div`
  font-size: 20px;
  margin-left: 10px;
  font-weight: 600;
`;
export const TooltipIconContainer = styled.div`
  display: flex;
  margin-left: 4px;
  align-items: center;
`;
export const TooltipIcon = styled.div``;
export const HeaderContainer = styled.div`
  font-size: 13px;
`;

export const PermissionHeaderTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PermissionHeaderSubTextContainer = styled.div`
  margin-left: 13px;
`;

export const PermissionText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: 400;
`;

export const PermissionHeaderKaratRight = styled(KaratRight)`
  transition: 0.2s ease-in-out;
  margin-right: 6px;

  path {
    stroke: ${({ theme }) => theme.colors.colorMediumGray9};
    fill: ${({ theme }) => theme.colors.colorMediumGray9};
    stroke-width: 0.7px;
  }
  transform: rotate(0deg);

  &.open {
    transform: rotate(90deg);
  }
`;

export const PermissionHeaderKaratRightHoverSstyle = `
  &:hover {
    ${PermissionHeaderKaratRight} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const ExplanationText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-weight: 13px;
  font-weight: 400;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${theme.colors.colorMediumGray5};
  padding-bottom: 4px;
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;
export const Container = styled.div``;

export const StyledAdminInitials = styled.div`
  flex: 1;
`;

export const StyledAdminName = styled.div`
  font-size: 12px;
  font-weight: 600;
  flex: 1;
`;
export const RemoveText = styled.div`
  flex: 1;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorDeleteRed};
  visibility: hidden;
`;

export const StyledAdminRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  background: ${theme.colors.colorPureWhite};
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};

  &:last-child {
    border-bottom: transparent;
  }

  &:hover {
    ${RemoveText} {
      visibility: visible;
    }
  }
`;

export const StyledAdminEmail = styled.div`
  font-size: 12px;
  flex: 3;
`;
export const StyledGraphicDisplay = styled.div`
  width: 300px;
  display: flex;
  margin-top: 150px;
  justify-content: center;
`;

export const PermissionsHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  position: sticky;
  z-index: 5;
  top: 0px;
  background: ${theme.colors.colorTranslucentGray4};
  border-bottom: 2px solid ${theme.colors.colorMediumGray5};
  padding-bottom: 10px;
  margin-bottom: 30px;
`;

export const CollapeExpandContainer = styled.div`
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
`;
