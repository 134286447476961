import { useCallback, useState, useMemo, useEffect } from 'react';
import {
  FilterLevelSchemas,
  FilterInstanceSchema,
  PageFilterSchema
} from 'FilterModule/types';
import {
  parseFilterInstanceSchema,
  parsePageFilterSchema,
  serializeFilterLevelName
} from 'FilterModule/filterSchemas/utils';

interface PageFilterParams<F> {
  initialFilterLevelSchemas?: F;
  level2?: Nullable<string>;
  level3?: Nullable<string>;
  level4?: Nullable<string>;
  filterInstanceSchema?: never;
}
interface FilterInstanceParams<I> {
  filterInstanceSchema: I;
  initialFilterLevelSchemas?: never;
  level2?: never;
  level3?: never;
  level4?: never;
}

export type UseFilterSchemaParams<
  F = FilterLevelSchemas,
  I = FilterInstanceSchema
> = {
  pageName: string;
} & (F extends FilterLevelSchemas
  ? PageFilterParams<F>
  : I extends FilterInstanceSchema
  ? FilterInstanceParams<I>
  : never);

export const useFilterSchema = <F, I>({
  pageName,
  filterInstanceSchema,
  initialFilterLevelSchemas,
  level2: propLevel2,
  level3: propLevel3,
  level4: propLevel4
}: UseFilterSchemaParams<F, I>) => {
  const useFilterLevels = !filterInstanceSchema;

  /* ------------------------------ filterLevels ------------------------------ */

  const [level1] = useState(pageName); // no case where pageName would change
  const [level2, setLevel2] = useState(propLevel2);
  const [level3, setLevel3] = useState(propLevel3);
  const [level4, setLevel4] = useState(propLevel4);

  const currentFilterLevelName = useMemo(() => {
    return useFilterLevels
      ? serializeFilterLevelName({
          level1,
          level2,
          level3,
          level4
        })
      : null;
  }, [level1, level2, level3, level4, useFilterLevels]);

  // Used for constructing the PageFilterSchema, which will be transformed into the currentFilterSchema
  const [registeredLevelSchemas, setRegisteredLevelSchemas] = useState({
    ...initialFilterLevelSchemas
  });

  /**
   * Add levelSchemas to the PageFilterSchema and levels for knowing the currentFilterLevelName
   */
  const registerFilterSchema = useCallback(
    ({
      levelSchemas,
      level2: registeredLevel2,
      level3: registeredLevel3,
      level4: registeredLevel4
    }: {
      levelSchemas?: FilterLevelSchemas;
      level2?: Nullable<string>;
      level3?: Nullable<string>;
      level4?: Nullable<string>;
    }) => {
      if (levelSchemas) {
        setRegisteredLevelSchemas((current) => ({
          ...current,
          ...levelSchemas
        }));
      }
      if (registeredLevel2 !== undefined) {
        setLevel2((curr) =>
          registeredLevel2 !== curr ? registeredLevel2 : curr
        );
      }
      if (registeredLevel3 !== undefined) {
        setLevel3((curr) =>
          registeredLevel3 !== curr ? registeredLevel3 : curr
        );
      }
      if (registeredLevel4 !== undefined) {
        setLevel4((curr) =>
          registeredLevel4 !== curr ? registeredLevel4 : curr
        );
      }
    },
    []
  );

  // update level states if they are passed in as props (vs. being registered)
  useEffect(() => {
    if (propLevel2 !== undefined) {
      setLevel2((curr) => (propLevel2 !== curr ? propLevel2 : curr));
    }
  }, [propLevel2]);

  useEffect(() => {
    if (propLevel3 !== undefined) {
      setLevel3((curr) => (propLevel3 !== curr ? propLevel3 : curr));
    }
  }, [propLevel3]);

  useEffect(() => {
    if (propLevel4 !== undefined) {
      setLevel4((curr) => (propLevel4 !== curr ? propLevel4 : curr));
    }
  }, [propLevel4]);

  /**
   * When using filterLevels, this is the final pre-parsed filter schema for the page
   * Could be defined in currentFilterSchema useMemo; defined in its own variable for debug purpose only
   */
  const pageFilterSchema: Nullable<PageFilterSchema> = useMemo(() => {
    return useFilterLevels
      ? {
          pageName,
          levelSchemas: registeredLevelSchemas
        }
      : null;
  }, [pageName, registeredLevelSchemas, useFilterLevels]);

  /* ------------------- parsed result: currentFilterSchema ------------------- */

  /**
   * The result of parsing a PageFilterSchema (page with levels of filtering) or FilterInstanceSchema (a single filter -
   * eg. a dashboard widget filter).
   */
  const currentFilterSchema = useMemo(() => {
    if (pageFilterSchema) {
      return parsePageFilterSchema({
        filterSchema: pageFilterSchema,
        filterLevelName: currentFilterLevelName as string
      });
    } else {
      return parseFilterInstanceSchema({
        // if pageFilterSchema exists, then useFilterLevels is true, which means !filterInstanceSchema. Therefore this else case is when !!filterInstanceSchema
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        filterInstanceSchema: filterInstanceSchema!
      });
    }
  }, [filterInstanceSchema, pageFilterSchema, currentFilterLevelName]);

  return {
    pageFilterSchema,
    filterInstanceSchema,
    currentFilterSchema,
    registerFilterSchema
  };
};
