import React, { useState, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { editProject, updatePhase } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import noop from 'lodash/noop';

import BudgetStatusDropdown from 'BudgetModule/components/BudgetModal/BudgetStatusDropdown';

import Popover from 'components/Popover';
import KaratLeft from 'icons/KaratLeft';
import {
  BUDGET_STATUSES,
  PROJECT_BUDGET_STATUSES_DISPLAY,
  PROJECT_BUDGET_STATUS_OPTIONS,
  PHASE_BUDGET_STATUS_DISPLAY,
  PHASE_BUDGET_STATUS_OPTIONS
} from 'appConstants/budgetStatuses';
import { makeGetProjectById } from 'selectors';

export const DropdownIcon = styled(KaratLeft)`
  transform: rotate(-90deg);
  margin-left: 8px;
`;
export const StyledMenuItem = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: ${theme.colors.colorMediumGray9};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 13px 17px;
  text-transform: capitalize;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;
export const StyledDropdownToggle = styled.div`
  min-width: ${(props) => (props.isPhaseStatusDropdown ? '82px' : '68px')};
  height: 13px;
  background: ${(props) =>
    props.isPhaseStatusDropdown
      ? props.isCompleted
        ? theme.colors.colorLightGray15
        : theme.colors.colorCalendarBlue
      : theme.colors.colorPureWhite};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  color: ${(props) =>
    props.isPhaseStatusDropdown
      ? theme.colors.colorPureWhite
      : theme.colors.colorCalendarGray};
  cursor: pointer;
  flex-shrink: 0;

  svg {
    margin-left: 3px;
  }
  path {
    fill: white;
  }

  ${(props) =>
    !props.isPhaseStatusDropdown &&
    `
      &:hover {
        background: ${theme.colors.colorCalendarBlue};
        color: ${theme.colors.colorPureWhite};
      }
    `}
  &.workload-bar-modal-toggle {
    background-color: ${theme.colors.colorTranslucentGray4};
    color: ${theme.colors.colorSemiDarkGrey10} !important;
    width: fit-content;
    padding: 0 4px;
    margin-left: 36px;
  }
`;

const StatusDropdown = ({
  status,
  projectId,
  background = '#ffffff',
  style = {},
  phase,
  project,
  isPhaseStatusDropdown,
  dropdownToggleClassName,
  showDropdownIcon = true,
  editProject,
  updatePhase,
  isDisabled = false
}) => {
  const target = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = () => setIsOpen(false);
  const budgetSubStatus = project ? project.budget_sub_status : null;

  const onChange = (newStatus) => {
    if (isPhaseStatusDropdown) {
      if (phase) {
        updatePhase({
          ...phase,
          projectId: phase.project_id,
          budgetStatus: newStatus
        });
      }
    } else {
      if (projectId) {
        editProject(newStatus);
      }
    }
    closeDropdown();
  };

  const budgetStatusLabel = isPhaseStatusDropdown
    ? PHASE_BUDGET_STATUS_DISPLAY[status] ||
      status ||
      PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.ACTIVE]
    : `${
        PROJECT_BUDGET_STATUSES_DISPLAY[status] ||
        status ||
        PROJECT_BUDGET_STATUSES_DISPLAY[BUDGET_STATUSES.ACTIVE]
      }${
        budgetSubStatus &&
        budgetSubStatus > 0 &&
        status === BUDGET_STATUSES.PROPOSAL
          ? ` - ${budgetSubStatus}%`
          : ``
      }`;

  const budgetStatusOptions = isPhaseStatusDropdown
    ? PHASE_BUDGET_STATUS_OPTIONS
    : PROJECT_BUDGET_STATUS_OPTIONS;

  return (
    <StyledDropdownToggle
      isCompleted={status === BUDGET_STATUSES.COMPLETE}
      onClick={isDisabled ? noop : () => setIsOpen(true)}
      ref={target}
      data-testid={`project-status-dropdown-toggle-${projectId}`}
      style={style}
      background={background}
      isPhaseStatusDropdown={isPhaseStatusDropdown}
      className={dropdownToggleClassName}
    >
      <span
        data-testid={`project-status-${projectId}`}
        style={{ cursor: isDisabled ? 'default' : 'pointer' }}
      >
        {budgetStatusLabel}
        {isPhaseStatusDropdown && showDropdownIcon && (
          <DropdownIcon height={10} width={10} />
        )}
      </span>
      {/* Menu */}
      {isPhaseStatusDropdown ? (
        <Popover isOpen={isOpen} target={target} closePopover={closeDropdown}>
          {budgetStatusOptions.map(({ value, label }, index) => {
            return (
              <StyledMenuItem
                onClick={() => onChange(value)}
                key={index}
                data-testid={`option-${label}`}
              >
                {label}
              </StyledMenuItem>
            );
          })}
        </Popover>
      ) : (
        <BudgetStatusDropdown
          isOpen={isOpen}
          target={target}
          project={project}
          closePopover={closeDropdown}
          handleProjectStatusChange={onChange}
        />
      )}
    </StyledDropdownToggle>
  );
};

const projectIdGetter = (state, ownProps) => ownProps.projectId;
const makeMapStateToProps = () => {
  const getProjectById = makeGetProjectById({ projectIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    project: getProjectById(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updatePhase,
  editProject
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(React.memo(StatusDropdown));
