import { useCallback } from 'react';
import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

// Required: non-empty
export const useProjectTitleValidator = ({ entityType }) => {
  return useCallback((rows) => validateRows(rows, entityType), [entityType]);
};

/* ------------------------------------ - ----------------------------------- */

export const validateRows = (rows, entityType) => {
  return rows.map((row) => {
    const { enteredProjectTitle, enteredProjectNumber } =
      getRowValuesForEntityType(row, entityType);

    // project title mandatory
    if (
      !enteredProjectTitle ||
      enteredProjectTitle === TRAINING_DATA_REQUIRED_STRING
    ) {
      return false;
    }
    // Tie project number validation to project title validation
    // to avoid having to change the table schema
    if (!enteredProjectNumber) {
      return 'Project number is required';
    }

    return true;
  });
};
