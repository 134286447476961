import { fetchTimers } from 'actionCreators';
import { ComponentProps, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getMyUserId } from 'UsersModule/selectors';
import ConfirmStopTimerModal from './ConfirmStopTimerModal';
import moment from 'moment';

export const useConfirmStopTimerModal = ({
  onClose
}: {
  onClose?: () => void;
} = {}) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(getMyUserId);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (accountId) {
      dispatch(
        fetchTimers({
          body: {
            account_id: accountId,
            date: moment().format('YYYY-MM-DD')
          }
        })
      );
      setIsOpen(true);
    }
  }, [accountId, dispatch]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  const Modal = useCallback(
    ({
      overrideOnClose
    }: {
      overrideOnClose?: ComponentProps<typeof ConfirmStopTimerModal>['onClose'];
    }) => {
      return (
        <ConfirmStopTimerModal
          isOpen={isOpen}
          onClose={overrideOnClose ?? handleClose}
        />
      );
    },
    [handleClose, isOpen]
  );

  return {
    ConfirmStopTimerModal: Modal,
    open: handleOpen,
    close: handleClose,
    isOpen
  };
};
