import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import FilledArrow from 'icons/FilledArrow';

const StyledContainer = styled.div`
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  font-size: 11px;
  border-bottom: 1px solid ${theme.colors.colorLightGray6};
  padding-left: ${(props) => (props.headerType === 'date' ? '33px' : '0px')};

  svg {
    transform: ${({ sortDirection }) =>
      sortDirection === 'asc' ? 'rotate(180deg)' : 'unset'};
  }
`;

const SortArrowContainer = styled.span``;

const noop = () => {};

const headerTypeHash = {
  actor: {
    label: 'ACTIVITY'
  },
  date: {
    label: 'DATE'
  },
  member: {
    label: 'MEMBER'
  },
  project: {
    label: 'PROJECT'
  },
  workCategory: {
    label: 'WORK CATEGORY'
  },
  description: {
    label: 'DESCRIPTION'
  },
  rate: {
    label: 'RATE'
  },
  hours: {
    label: 'HRS'
  },
  billable: {
    label: 'BILL'
  },
  approve: {
    label: 'APPROVE'
  },
  status: {
    label: 'STATUS'
  },
  email: {
    label: 'EMAIL'
  },
  client: {
    label: 'CLIENT'
  },
  projectNumber: {
    label: 'PROJECT #'
  }
};

const ActivityHeaderCell = ({
  column,
  customRowProps: { customHeaderProps }
}) => {
  const { headerType } = column;
  return (
    <StyledContainer
      className={`header-${headerType} header-container`}
      sortDirection={customHeaderProps.sortAttributes.sortDirection}
      headerType={headerType}
      // onClick={
      //   customHeaderProps.onHeaderClick
      //     ? customHeaderProps.onHeaderClick(headerType)
      //     : noop
      // }
    >
      {headerTypeHash[headerType]?.label || ''}
      {/* Comment out when sorting on sync log is ready */}

      {/* {customHeaderProps.sortAttributes.headerType === headerType && (
        <SortArrowContainer>
          <FilledArrow width={10} height={10} />
        </SortArrowContainer>
      )} */}
    </StyledContainer>
  );
};
export default ActivityHeaderCell;
