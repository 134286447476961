import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

type Props = Pick<SvgIconProps, 'className' | 'width' | 'height' | 'fill'>;

const CapacityIcon = ({
  className = '',
  fill = '#4F4F4F',
  width = '13',
  height = '13'
}: Props) => (
  <SvgIcon
    className={className}
    height={height}
    width={width}
    viewBox="0 0 13 13"
    fill="none"
  >
    <path
      d="M6.5 0.8125C4.53822 0.8125 1.21875 1.15456 1.21875 2.4375V10.493C1.21875 11.7756 4.53822 12.118 6.5 12.118C8.46138 12.118 11.7812 11.7756 11.7812 10.493V2.4375C11.7812 1.15456 8.46138 0.8125 6.5 0.8125ZM2.03125 5.811C3.13991 6.33588 5.14394 6.5 6.5 6.5C7.85687 6.5 9.86253 6.33547 10.9708 5.80978L10.972 7.69194C10.8209 7.99459 9.20481 8.53125 6.5 8.53125C3.80738 8.53125 2.19456 7.99947 2.03125 7.69641V5.811ZM6.5 5.6875C3.80738 5.6875 2.19456 5.15572 2.03125 4.85266V3.37391C3.13991 3.89838 5.14394 4.0625 6.5 4.0625C7.85647 4.0625 9.86091 3.89838 10.9692 3.37309L10.97 4.85063C10.8119 5.15369 9.19791 5.6875 6.5 5.6875ZM6.5 1.625C9.12641 1.625 10.727 2.13119 10.9562 2.4375C10.727 2.74381 9.12641 3.25 6.5 3.25C3.87359 3.25 2.27297 2.74381 2.04425 2.4375C2.27297 2.13119 3.87359 1.625 6.5 1.625ZM6.5 11.3055C3.78869 11.3055 2.17019 10.766 2.03125 10.493V8.65475C3.13991 9.17963 5.14394 9.34375 6.5 9.34375C7.85769 9.34375 9.86497 9.17881 10.9728 8.65272L10.974 10.4642C10.8294 10.766 9.21131 11.3055 6.5 11.3055Z"
      fill={fill}
    />
  </SvgIcon>
);

export default CapacityIcon;
