import React from 'react';
import { connect } from 'react-redux';
import { makeGetHoursByDateKeys } from 'selectors';
import cn from 'classnames';
import { TimesheetFooterValue } from './../styles';

const sum = (value, sum) => value + sum;
const sumRowHours = (row) => {
  // items in the row are of the from:
  // id: 1592
  // date: "01/08/2020"
  // hours: "8.0"
  // description_id: 1022
  // billable: null
  // status: "not_submitted"
  if (!row) {
    return 0;
  }
  const hours = Object.values(row)
    .map((cell) => (cell && +cell.hours) || 0)
    .reduce(sum, 0);
  return hours;
};

const sumTableHours = (activityRows) => {
  if (!activityRows) {
    return 0;
  }
  const hours = activityRows.map((row) => sumRowHours(row)).reduce(sum, 0);
  return hours;
};

export const TimesheetColumnTotal = (props) => {
  const totalHours = props.data // the total column
    ? sumTableHours(props.data.map((data) => data.timesheetsByDate))
    : +props.totalHours;

  return (
    <TimesheetFooterValue
      className={cn('timesheet-day timesheet-total-row', {
        sum: props.sum
      })}
      data-testid={`timesheet-column-total ${props.timesheetColumnIndex}`}
    >
      {`${totalHours.toFixed(2)}`.replace(/\.*0+$/, '') +
        `${props.capacity !== undefined ? '' : 'h'}` || 0}
      {props.capacity !== undefined && (
        <span
          style={{
            fontSize: '10px',
            fontWeight: 'normal',
            color: '#a4a4a4',
            marginTop: '1px'
          }}
        >
          /{props.capacity}h
        </span>
      )}

      <span className={cn('timesheet-day timesheet-total-row')}></span>
    </TimesheetFooterValue>
  );
};

const makeMapStateToProps = () => {
  const getHoursByDateKeys = makeGetHoursByDateKeys();
  const mapStateToProps = (state, ownProps) => ({
    totalHours: getHoursByDateKeys(state, ownProps)
  });
  return mapStateToProps;
};

const ConnectedTimesheetColumnTotal =
  connect(makeMapStateToProps)(TimesheetColumnTotal);

const TimesheetColumnTotalAdapter = (props) => (
  <ConnectedTimesheetColumnTotal {...props} />
);

export default TimesheetColumnTotalAdapter;
