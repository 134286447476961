export const MFA_STATUS = {
  totp: 'SOFTWARE_TOKEN_MFA',
  sms: 'SMS_MFA',
  noPreference: 'no MFA preference',
  disabled: 'disabled'
} as const;

export const preferredAuthMethods = {
  default: 'default',
  saml: 'SAML'
} as const;
