import { forwardRef } from 'react';
import moment, { MomentInput } from 'moment';
import styled from 'styled-components';

type DateTooltipType = 'start' | 'end';
interface ItemDatesTooltipRendererProps {
  type: 'start' | 'end';
  date: MomentInput;
}

const formatTooltipDate = (date: MomentInput) =>
  moment(date).startOf('day').format('MMM D');

export const ItemDatesTooltipRenderer = forwardRef<
  HTMLDivElement,
  ItemDatesTooltipRendererProps
>(function ItemDatesTooltipRenderer({ type, date }, ref) {
  return (
    <DatesTooltipContainer
      ref={ref}
      data-tip={formatTooltipDate(date)}
      data-for={`${type}-date-tooltip`}
      data-place={type === 'start' ? 'left' : 'right'}
      $type={type}
    />
  );
});

const DatesTooltipContainer = styled.div.attrs<{ $type: DateTooltipType }>(
  ({ $type }) => ({
    style: {
      left: $type === 'start' ? 0 : 'auto',
      right: $type === 'start' ? 'auto' : 0
    }
  })
)<{ $type: DateTooltipType }>`
  position: absolute;
  bottom: 0;
  top: 0;
`;
