import { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch, batch } from 'react-redux';
import GenericGroupHeader from 'views/Home/TaskList/GenericGroupHeader';
import {
  createSkill,
  updateSkill,
  deleteSkill,
  archiveSkill,
  unarchiveSkill,
  createSkillMembership
} from 'actionCreators';
import { getSelectedTeamId } from 'selectors';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import SmallPencilIcon from 'icons/small-pencil.svg';
import ArchiveIcon from 'icons/archive.svg';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import DeleteIcon from 'icons/delete.svg';
import DeleteIconRed from 'icons/delete-red.svg';
import {
  StyledDropdownItem,
  IconContainer
} from 'BudgetModule/components/BudgetTable/styles';
import { AddPlusClickableArea } from 'views/personalSettings/styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const wrapperStyles = {};

const deleteModalProps = {
  title: `Delete Skill?`,
  content:
    'Are you sure you want to delete this Skill? You will be unable to restore it.'
};
const archiveModalProps = {
  title: 'Archive Skill?',
  content:
    'Are you sure you want to archive this Skill? You can unarchive from the archived skills section'
};

// Dropdown labels ------------------
const labels = {
  edit: 'Edit Skill Name',
  archive: 'Archive Skill',
  unarchive: 'Unarchive Skill',
  delete: 'Delete Skill'
};

const StyledIconContainer = styled(IconContainer)`
  margin-right: 7px;
`;

const archiveIcon = (
  <StyledIconContainer>
    <img className="grey-icon" src={ArchiveIcon} height="18px" />
  </StyledIconContainer>
);

const DropdownItem = styled(StyledDropdownItem)`
  padding: 0;
  &:hover {
    background-color: initial;
  }
`;

const icons = {
  edit: (
    <StyledIconContainer>
      <img className="grey-icon" src={SmallPencilIcon} height="18px" />
    </StyledIconContainer>
  ),
  archive: archiveIcon,
  unarchive: archiveIcon,
  delete: (
    <StyledIconContainer>
      <img className="delete-gray" src={DeleteIcon} height="14px" />
      <img className="delete-red" src={DeleteIconRed} height="14px" />
    </StyledIconContainer>
  )
};

const DropdownLabel = ({ action }) => (
  <DropdownItem>
    {icons[action]} {labels[action]}
  </DropdownItem>
);

// --------------------------------

const HeaderContent = ({ group, dropdownTarget }) => {
  const {
    id,
    customRowProps: { handleSetIsOpen },
    isOpen,
    name,
    formattedSkillMembers
  } = group;
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const newGroupId = useSelector((state) => state.skills.newSkillId);
  const [isArchiving, setIsArchiving] = useState(false);
  const [addMemberDropdownIsOpen, setAddMemberDropdownIsOpen] = useState(false);

  const handleSubmit = ({ name: newName }) => {
    dispatch(
      updateSkill({
        id,
        name: newName || name
      })
    );
  };

  const handleInsertList = () => {
    dispatch(
      createSkill({
        teamId,
        name: 'New Skill'
      })
    );
  };

  const confirmDelete = () => {
    if (isArchiving) {
      dispatch(archiveSkill({ id }));
    } else {
      dispatch(
        deleteSkill({
          id
        })
      );
    }
    setIsArchiving(false);
  };

  const handleUnarchive = useCallback(() => {
    dispatch(unarchiveSkill({ id }));
    setIsArchiving(false);
  }, [dispatch, id]);

  const options = useMemo(() => {
    const editOption = {
      label: <DropdownLabel action="edit" />,
      actionType: 'setEditing'
    };
    const archiveOption = {
      label: <DropdownLabel action="archive" />,
      onClick: () => setIsArchiving(true),
      actionType: 'setDeleting'
    };
    const unarchiveOption = {
      label: <DropdownLabel action="unarchive" />,
      onClick: handleUnarchive,
      actionType: 'clearThreeDotMenuOpen'
    };
    const deleteOption = {
      label: <DropdownLabel action="delete" />,
      actionType: 'setDeleting'
    };

    return [
      editOption,
      group.archived ? unarchiveOption : archiveOption,
      deleteOption
    ];
  }, [group.archived, handleUnarchive]);

  const handleAdd = (members) => {
    batch(() =>
      members.map((member) => {
        dispatch(
          createSkillMembership({
            teamMembershipId: member.id,
            skillId: id,
            level: 1
          })
        );
      })
    );
    setAddMemberDropdownIsOpen(false);
  };

  const memberFilter = useCallback(
    (members) =>
      members.filter((member) => !group.skillMembersHash[member.account.id]),
    [group.skillMembersHash]
  );

  const renderAdd = useCallback(() => {
    const onClick = (e) => {
      e.stopPropagation();
      setAddMemberDropdownIsOpen(true);
    };
    return (
      <AddPlusClickableArea onClick={onClick}>
        <PlusCircleIcon height={15} width={15} />
      </AddPlusClickableArea>
    );
  }, []);

  return (
    <>
      <GenericGroupHeader
        key={id}
        group={group}
        isNewGroup={newGroupId === id}
        handleSubmit={handleSubmit}
        handleInsertList={handleInsertList}
        toggleCollapse={() => handleSetIsOpen({ name: id, value: !isOpen })}
        collapsed={!isOpen}
        id={id}
        originType={ORIGIN_TYPE_STRINGS.TASK_GROUP}
        pickerLocation="flyout-menu"
        wrapperStyles={wrapperStyles}
        deleteModalProps={isArchiving ? archiveModalProps : deleteModalProps}
        onConfirmDelete={confirmDelete}
        dropdownOptions={options}
        clearIsDeleting={() => setIsArchiving(false)}
        colorPickerEnabled={false}
        alwaysShowDotMenu
        namePlaceholderText="Type Skill Name"
        preventEmptyName={false} // allow update, handleSubmit uses original name if empty
        renderAfterName={renderAdd}
        hideCollapse={!formattedSkillMembers.length}
      />
      {addMemberDropdownIsOpen && (
        <BulkMemberSelector
          handleDone={handleAdd}
          memberFilter={memberFilter}
          shouldUseMemberFilter
          alwaysOpen
          target={dropdownTarget}
          renderSelect={() => null}
          onClose={() => setAddMemberDropdownIsOpen(false)}
          hideFooter={true}
        />
      )}
    </>
  );
};

export default HeaderContent;
