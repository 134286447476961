'use strict';

exports.__esModule = true;
exports['default'] = TimePickerButton;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames2 = require('classnames');

var _classnames3 = _interopRequireDefault(_classnames2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function TimePickerButton(_ref) {
  var _classnames;

  var prefixCls = _ref.prefixCls,
    locale = _ref.locale,
    showTimePicker = _ref.showTimePicker,
    onOpenTimePicker = _ref.onOpenTimePicker,
    onCloseTimePicker = _ref.onCloseTimePicker,
    timePickerDisabled = _ref.timePickerDisabled;

  var className = (0, _classnames3['default'])(
    ((_classnames = {}),
    (_classnames[prefixCls + '-time-picker-btn'] = true),
    (_classnames[prefixCls + '-time-picker-btn-disabled'] = timePickerDisabled),
    _classnames)
  );
  var onClick = null;
  if (!timePickerDisabled) {
    onClick = showTimePicker ? onCloseTimePicker : onOpenTimePicker;
  }
  return _react2['default'].createElement(
    'a',
    {
      className: className,
      role: 'button',
      onClick: onClick
    },
    showTimePicker ? locale.dateSelect : locale.timeSelect
  );
}
module.exports = exports['default'];
