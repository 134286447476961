import EllipsisText from 'components/EllipsisText';
import styled from 'styled-components';
import { RateGroupToggleCellProps, TableCellProps } from '../../types';
import { isRateGroupToggleCell } from '../../utils';
import cn from 'classnames';
import { PlusCircleIconHoverable } from 'icons/PlusMinusCircleIcon';
import { MouseEvent } from 'react';

export const NameHeaderCell = (props: TableCellProps) =>
  isRateGroupToggleCell(props) ? (
    <NameHeaderCellWithRateGroupToggleProps {...props} />
  ) : null;

const NameHeaderCellWithRateGroupToggleProps = (
  props: RateGroupToggleCellProps
) => {
  const {
    row: {
      original: {
        rateGroup: { archived_at, name },
        toggleAddEntityMenu
      }
    }
  } = props;

  const handlePlusClick = (event: MouseEvent<SVGElement>) =>
    toggleAddEntityMenu(event.currentTarget);

  return (
    <Cell>
      <Name $isArchived={!!archived_at}>{name}</Name>
      <PlusCircleIconHoverable
        height={15}
        onClick={handlePlusClick}
        width={15}
      />
    </Cell>
  );
};

const Name = styled(EllipsisText).attrs<{ $isArchived: boolean }>(
  ({ $isArchived }) => ({ className: cn({ archived: $isArchived }) })
)<{ $isArchived: boolean }>`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 16px;
  font-weight: 600;
  margin-right: 6px;
  min-width: 0;

  &.archived {
    color: ${({ theme }) => theme.colors.colorCalendarGray};
  }
`;

const Cell = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;
