import styled from 'styled-components';
import theme from 'theme';
import LockIcon from 'icons/LockIcon';
import AddPlusIcon from 'icons/AddPlusIcon';
import QBDownArrow from 'icons/QBDownArrow';

export const StyledModalHeader = styled.div`
  padding: 30px 35px 20px;
  font-size: 22px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledModalBody = styled.div`
  padding: 0px 35px 40px;
  flex: 1;
  overflow: auto;
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  &:hover {
    circle {
      fill: ${theme.colors.colorTranslucentGray3};
    }
  }
`;

export const InfoContainer = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray1};
`;

export const StyledLockIcon = styled(LockIcon)`
  path {
    fill: ${theme.colors.colorRoyalBlue};
  }
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-top: -2px;
`;
export const StyledAddPlusIcon = styled(AddPlusIcon)`
  height: 30px;
  width: 30px;
  margin-right: 8px;
  background: ${theme.colors.colorTranslucentGray3};
  border-radius: 100px;
`;
export const ActionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  padding: 20px 20px 10px;

  &:hover {
    ${StyledAddPlusIcon} {
      background: ${theme.colors.colorRoyalBlue};
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export const DoneButton = styled.div`
  padding: 6px 21px;
  cursor: pointer;
  align-self: flex-start;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 5px;
  &:hover {
    filter: brightness(93%);
  }
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
`;

export const MemberName = styled.div`
  font-size: 14px;
  margin-left: 8px;
  color: ${theme.colors.colorSemiDarkGray1};
  line-height: 1;
`;

export const StyledDownArrow = styled(QBDownArrow)`
  height: 5px;
  width: 10px;
  margin-left: 6px;
  path {
    fill: ${theme.colors.colorMediumGray5};
  }
`;
export const StyledRightArrow = styled(StyledDownArrow)`
  transform-origin: center center;
  transform: rotate(-90deg) translateY(5px);
`;

export const StyledDropdownToggle = styled.div`
  font-size: 11px;
  min-width: 100px;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    ${StyledDownArrow} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const VisibilityText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: fit-content;
`;

export const StyledEyeIcon = styled.img`
  margin: 0 2px;
`;

export const StyledDropdownItem = styled.div`
  font-size: 12px;
  padding: 4px 10px;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
  }
`;
export const StyledCloseIcon = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: -20px;
  visibility: hidden;

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const VisibilityMemberRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 20px;
  margin-left: 8px;
  .visibility-options-dropdown {
    min-width: 100px;
    outline: none;
  }
  &:hover {
    ${StyledCloseIcon} {
      visibility: visible;
      padding: 5px;
    }
    background: ${theme.colors.colorTranslucentGray3};
  }
`;

export const HeaderContainer = styled.div`
  position: relative;
`;

export const Subtext = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray1};
`;
