import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getPositions } from 'BudgetModule/selectors/positions';
import { useNavToSettings } from 'SettingsModule/hooks';
import { useLoadPositions } from './useLoadPositions';

const emptyObj = {};

/**
 * aka roles
 */
export const usePositions = ({
  isOff,
  shouldAutoLoad
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  /** default is true */
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const positionHash = useAppSelector(getPositions);

  const getPositionLabel = useCallback(
    (positionId: number | string) => {
      return positionHash[+positionId]?.name ?? 'No Role';
    },
    [positionHash]
  );

  const { handleNavToSettings: handleNavToStandardPositionsPage } =
    useNavToSettings({
      viewType: 'standards',
      tab: 'roles'
    });

  /* --------------------------------- loading -------------------------------- */

  const { isLoaded, loadPositions } = useLoadPositions({
    isOff,
    shouldAutoLoad
  });

  return {
    isLoaded,
    loadPositions,
    positionHash,
    handleNavToStandardPositionsPage,
    getPositionLabel
  };
};
