import React from 'react';
import cn from 'classnames';

const MyStar = ({ starType, hovered, position }) => (
  <>
    <div className="my-star-col">
      <i
        className={cn(`my-${starType}-star`, { hovered })}
        data-for={'app-tooltip'}
        data-tip={`Starred projects show up at the top <br/> of your "My Projects" list on Home.`}
        data-delay-show="500"
        data-effect="solid"
        data-place={'top'}
        data-html={true}
      />
    </div>
  </>
);

export default MyStar;
