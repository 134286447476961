import React from 'react';
import SvgIcon from 'components/SvgIcon';

const LoadingWheel = ({ width = 40, height = 40, fill = '#DFDFDF' }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 40 40"
    className="loading-wheel"
  >
    <g fill={fill} fillRule="evenodd">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M20.04 40c-5.507 0-10.859-2.323-14.61-6.332l2.313-2.163c3.194 3.366 7.585 5.37 12.216 5.37 9.263 0 16.766-7.534 16.766-16.834S29.222 3.207 19.96 3.207c-9.262 0-16.766 7.534-16.766 16.834H0C0 8.977 8.941 0 19.96 0 30.977 0 40 8.896 40 19.959 40 31.023 31.059 40 20.04 40z"
      />
    </g>
  </SvgIcon>
);

export default LoadingWheel;
