import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface ReportsIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  fill?: string;
}

export const ReportsIcon = ({
  fill = '#4F4F4F',
  width = '18',
  height = '18'
}: ReportsIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 18 " fill="none">
      <path
        d="M4.69231 17H5.19231C5.19231 17.2761 4.96845 17.5 4.69231 17.5V17ZM1 17V17.5C0.723858 17.5 0.5 17.2761 0.5 17H1ZM7.15424 8.17935L6.65424 8.17937L7.15424 8.17935ZM10.8461 8.1792L10.3461 8.17922L10.8461 8.1792ZM10.8465 17L11.3465 17C11.3465 17.1326 11.2938 17.2598 11.2 17.3535C11.1063 17.4473 10.9791 17.5 10.8465 17.5V17ZM7.1546 17V17.5C6.87846 17.5 6.65461 17.2762 6.6546 17L7.1546 17ZM16.9996 17H17.4996C17.4996 17.2761 17.2757 17.5 16.9996 17.5V17ZM13.3073 17V17.5C13.0312 17.5 12.8073 17.2761 12.8073 17H13.3073ZM5.19231 12.4462V17H4.19231V12.4462H5.19231ZM2.84615 10.1C4.1419 10.1 5.19231 11.1504 5.19231 12.4462H4.19231C4.19231 11.7027 3.58961 11.1 2.84615 11.1V10.1ZM0.5 12.4462C0.5 11.1504 1.55041 10.1 2.84615 10.1V11.1C2.10269 11.1 1.5 11.7027 1.5 12.4462H0.5ZM0.5 17V12.4462H1.5V17H0.5ZM4.69231 17.5H1V16.5H4.69231V17.5ZM9.00018 6.83333C8.25682 6.83333 7.65421 7.43596 7.65424 8.17933L6.65424 8.17937C6.65419 6.88371 7.70452 5.83333 9.00018 5.83333V6.83333ZM10.3461 8.17922C10.3461 7.4359 9.7435 6.83333 9.00018 6.83333V5.83333C10.2958 5.83333 11.3461 6.88359 11.3461 8.17918L10.3461 8.17922ZM10.3465 17L10.3461 8.17922L11.3461 8.17918L11.3465 17L10.3465 17ZM7.1546 16.5H10.8465V17.5H7.1546V16.5ZM7.65424 8.17933L7.6546 17L6.6546 17L6.65424 8.17937L7.65424 8.17933ZM16.4996 17V2.84615H17.4996V17H16.4996ZM13.3073 16.5H16.9996V17.5H13.3073V16.5ZM13.8073 2.84615V17H12.8073V2.84615H13.8073ZM15.1534 1.5C14.41 1.5 13.8073 2.10269 13.8073 2.84615H12.8073C12.8073 1.55041 13.8577 0.5 15.1534 0.5V1.5ZM16.4996 2.84615C16.4996 2.10269 15.8969 1.5 15.1534 1.5V0.5C16.4492 0.5 17.4996 1.55041 17.4996 2.84615H16.4996Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ReportsIcon;
