import { createSelector } from '@reduxjs/toolkit';
import { makeGetActiveBudgetPhases } from 'BudgetModule/selectors';
import { RootState } from 'reduxInfra/shared';
import { Selector } from 'react-redux';

const getPhaseOrder: Selector<
  RootState,
  Array<number>,
  { isAllPhases: boolean; projectId: number }
> = createSelector(
  makeGetActiveBudgetPhases() as any,
  (orderedPhases: Array<unknown>): Array<number> =>
    orderedPhases.map(({ id }) => id)
);
export const makeGetPhaseOrder =
  (ownProps: Parameters<typeof getPhaseOrder>[1]) => (state: RootState) =>
    getPhaseOrder(state, ownProps);
