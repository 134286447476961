// container to standardize notifications split by day, headers by day

import React from 'react';
import { ActivityFeedItem } from 'views';
import moment from 'moment';

const ActivityFeedDay = ({
  day: { date, notifications },
  markAsRead,
  markAllRead,
  index,
  accountId,
  isActivityMemberModal
}) => {
  const momentDate = moment(date);
  const yesterdayMomentDate = moment().subtract(1, 'days');
  const isToday = moment().isSame(momentDate, 'day');
  const isYesterday = yesterdayMomentDate.isSame(momentDate, 'day');

  const renderPrimaryDate = isToday
    ? 'Today'
    : isYesterday
    ? 'Yesterday'
    : moment(date).format('MMM D');

  const renderSecondaryDate =
    (isToday || isYesterday) && moment(date).format('MMM D');

  // Removes notifications with no display_data
  const displayNotifications = notifications
    .filter((notification) => notification.display_data)
    .map((notification) => (
      <ActivityFeedItem
        key={notification.id}
        markAsRead={markAsRead}
        isNew={notification.is_new}
        type={notification.type}
        time={notification.timestamp}
        isRead={notification.is_read}
        id={notification.id}
        displayData={notification.display_data}
        project={notification.project}
        projectUrl={notification.project_url}
        actor={notification.actor}
        actionType={notification.action_type}
        actionableId={notification.actionable_id}
        actionableType={notification.actionable_type}
        isActivityMemberModal={isActivityMemberModal}
        accountId={accountId}
      />
    ));

  return displayNotifications.length ? (
    <div className="activity-feed-day">
      <div className="day-title">
        <span
          className={`primary-date ${
            renderPrimaryDate === 'Today' ? 'today-date' : ''
          }`}
        >
          {renderPrimaryDate}
        </span>
        <span className="secondary-date">{renderSecondaryDate}</span>
        {index === 0 && !isActivityMemberModal && (
          <div className="mark-all-read" onClick={markAllRead}>
            Mark All Read
          </div>
        )}
      </div>
      {displayNotifications}
    </div>
  ) : (
    <></>
  );
};

export default ActivityFeedDay;
