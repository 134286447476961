import React from 'react';
import SvgIcon from 'components/SvgIcon';

const HomeCompletedIcon = ({
  width = 18,
  height = 18,
  className,
  currentColor = '#A4A4A4'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="transparent"
  >
    <circle cx="9" cy="9" r="8.5" fill={currentColor} stroke={currentColor} />
    <path
      d="M5.70509 9.62213L6.47461 8.84871L8.00269 10.3846L8.35759 10.7413L8.71205 10.3842L12.5253 6.54164L13.2956 7.31813L8.35705 12.2912L5.70509 9.62213Z"
      fill="white"
      stroke="white"
    />
  </SvgIcon>
);

export default HomeCompletedIcon;
