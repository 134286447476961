import EllipsisText, { TooltipBehavior } from 'components/EllipsisText';
import { ReactNode } from 'react';
import styled from 'styled-components';
import theme from 'theme';

const OrganizationSettingsDropdown = ({
  width = 170,
  label,
  showEllipsisTooltip = true
}: {
  width?: number;
  label: ReactNode;
  showEllipsisTooltip?: boolean;
}) => {
  return (
    <OrganizationSettingDropdownContainer width={width}>
      <EllipsisText
        width={width}
        tooltipBehavior={
          !showEllipsisTooltip
            ? TooltipBehavior.AlwaysDisabled
            : TooltipBehavior.EnabledOnEllipsis
        }
      >
        {label}
      </EllipsisText>
      <StyledAngleDown className="fa fa-angle-down" />
    </OrganizationSettingDropdownContainer>
  );
};

const OrganizationSettingDropdownContainer = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  height: 27px;
  border: 1px solid ${theme.colors.colorLightGray12};
  border-radius: 2px;
  background-color: ${theme.colors.colorPureWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  cursor: pointer;
`;

const StyledAngleDown = styled.i`
  flex: none;
`;

export default OrganizationSettingsDropdown;
