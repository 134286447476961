import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import PhaseTemplatesDropdown from 'BudgetModule/components/BudgetModal/PhaseTemplatesDropdown';
import { openPhaseModal, createPhase } from 'actionCreators';
import { getSelectedProject } from 'selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { DateDiv, DateInputDiv, SetDateLabel } from './styles';
import CloseEditStateIcon from 'icons/NewCloseIcon';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import ReactTooltip from 'react-tooltip';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { MilestoneModalContext } from 'contexts/MilestoneModalContext';
import intersection from 'lodash/intersection';
import { createPhaseValidator } from 'appUtils/phaseCreateValidatorUtils';

const CreateRowContainer = styled.div`
  height: 65px;
  width: 605px;
  margin-left: -22px;
  color: ${theme.colors.colorLightGray15};
  font-size: 15px;
  cursor: pointer;
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${theme.colors.colorMediumGray9};
`;

const CloseModalIconContainer = styled.div`
  margin-right: 25px;
  width: 23px;
  height: 23px;
  line-height: 18px;
  text-align: center;
  border-radius: 90px;
  svg {
    width: 28px;
    height: 28px;
  }
  circle {
    stroke: transparent;
  }
  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray1};
    }
  }
`;

const CloseEditIconContainer = styled(CloseModalIconContainer)`
  position: absolute;
  right: -52px;
  circle {
    stroke: transparent;
  }
  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray1};
    }
  }
  svg {
    width: 28px;
    height: 28px;
  }
`;

const CreatePhaseInput = styled.input`
  border: none;
  height: 65px;
  padding-left: 22px;
  width: 570px;
  font-size: 13px;

  &::placeholder {
    color: ${theme.colors.colorCalendarBlue};
  }
`;

const EditPhaseInput = styled.input`
  height: 65px;
  width: 365px;
  border: none;
  margin-left: 22px;
  padding-left: 0;
  &::placeholder {
    color: ${theme.colors.colorLightGray15};
  }
`;

const DatePhaseInput = styled.div`
  height: 65px;
  width: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.showDateWarning &&
    `
  ${DateDiv} {
    color: red;
  }
  `};
`;

const AddButtonContainer = styled.div`
  height: 65px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
`;

const AddPhaseButton = styled.button`
  background: ${(props) =>
    props.allowCreate
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorLightGray1};
  color: ${theme.colors.colorPureWhite};
  font-size: 18px;
  width: 58px;
  height: 38px;
  border-radius: 20px;
  border: none;
  margin-left: -1px;
`;

class MilestoneCreateRow extends React.Component {
  state = {
    isEditing: false,
    newPhaseName: '',
    newPhaseStartDate: null,
    newPhaseEndDate: null,
    newPhaseMembers: [],
    showDateWarning: false
  };

  static contextType = MilestoneModalContext;

  componentDidUpdate(prevProps, prevState) {
    if (prevState === this.state) {
      return;
    }
    if (
      Object.keys(this.state).some((key) => this.state[key] !== prevState[key])
    ) {
      ReactTooltip.hide();
    }
    rebuildTooltip();
  }

  grip = () => <div />;
  members = () => <div />;
  startDate = () => <div />;
  dash = () => <div />;
  endDate = () => <div />;
  workdays = () => <div />;
  status = () => <div />;
  // delete = () => <div />;
  menu = () => <div />;

  handleIsEditing = () => {
    this.setState({
      isEditing: true
    });
  };

  setAddingMilestone = () => {
    const { setMilestoneCreateRowState } = this.context;
    setMilestoneCreateRowState({
      showAddMenu: false,
      addingMilestone: true
    });
  };

  setAddingPhase = () => {
    const { setAddingPhase } = this.context;
    setAddingPhase();
  };

  clearShowAddMenu = () => {
    const { clearShowAddMenu } = this.context;
    clearShowAddMenu();
  };

  clearAddingMilestone = () => {
    const { setMilestoneCreateRowState } = this.context;
    setMilestoneCreateRowState({
      addingMilestone: false
    });
  };

  clearAddingPhase = () => {
    const { setMilestoneCreateRowState } = this.context;
    setMilestoneCreateRowState({
      addingPhase: false,
      addingMilestone: false
    });
  };

  clearIsEditing = () => {
    this.setState({
      isEditing: false,
      newPhaseName: '',
      newPhaseStartDate: null,
      newPhaseEndDate: null,
      newPhaseMembers: [],
      showDateWarning: false
    });
  };

  clearAll = () => {
    this.clearAddingMilestone();
    this.clearAddingPhase();
    this.clearIsEditing();
  };

  handleAddingMilestone = () => {
    const { handleAddingMilestone } = this.context;
    handleAddingMilestone();
  };

  handleAddingPhase = () => {
    const { setMilestoneCreateRowState } = this.context;
    setMilestoneCreateRowState({
      showAddMenu: false,
      addingPhase: true
    });
  };

  handleNewPhaseNameChange = (e) => {
    this.setState({
      newPhaseName: e.target.value
    });
  };

  handleNewDatechange = ({ startDate, endDate }) => {
    if (startDate?.isValid?.()) {
      this.setState({
        newPhaseStartDate: startDate,
        newPhaseEndDate: endDate,
        showDateWarning: false
      });
    }
  };

  handleSubmit = () => {
    const {
      newPhaseName,
      newPhaseStartDate,
      newPhaseEndDate,
      newPhaseMembers
    } = this.state;

    const { addingPhase, addingMilestone } = this.context;

    const createPhaseValidatorErrors = createPhaseValidator({
      phaseName: newPhaseName,
      phaseStartDate: newPhaseStartDate,
      phaseEndDate: newPhaseEndDate,
      addingMilestone
    });

    const dateErrorKeys = ['invalid_end_date', 'invalid_start_date'];
    const dateErrors = intersection(createPhaseValidatorErrors, dateErrorKeys);

    if (dateErrors.length !== 0) this.setState({ showDateWarning: true });

    if (createPhaseValidatorErrors.length !== 0) {
      return;
    }

    const { createPhase, selectedProject } = this.props;

    const startDate = newPhaseStartDate
      ? moment(newPhaseStartDate).format('MM/DD/YYYY')
      : null;
    const endDate = newPhaseEndDate
      ? moment(newPhaseEndDate).format('MM/DD/YYYY')
      : null;
    const newPhaseMemberIds = newPhaseMembers.map((member) => member.id);

    if (newPhaseName.length > 0) {
      createPhase({
        projectId: selectedProject.id,
        name: newPhaseName,
        isBudget: addingPhase,
        startDate: startDate,
        endDate: endDate,
        newPhaseMemberIds: newPhaseMemberIds
      });
    }
    this.clearAll();
  };

  handleMemberDone = (members) => {
    const newMembers = members.map((member) => {
      return {
        id: member.account.id,
        initials: member.account.initials
      };
    });
    this.setState({
      newPhaseMembers: [...this.state.newPhaseMembers, ...newMembers]
    });
  };

  memberFilter = (members) => {
    const { newPhaseMembers } = this.state;
    const newPhaseMemberIds = newPhaseMembers.map((member) => member.id);
    return members.filter(
      (member) => !newPhaseMemberIds.includes(member.account.id)
    );
  };

  singleDateCustomInput = (startDate, endDate, handleOpen) => {
    return (
      <DateInputDiv isSelected={!!startDate}>
        <DateDiv onClick={handleOpen.bind(this)}>
          {startDate ? moment(startDate).format('M/D/YY') : 'Select'}
        </DateDiv>
      </DateInputDiv>
    );
  };

  doubleDateCustomInput = (startDate, endDate, handleOpen) => {
    return (
      <DateInputDiv isSelected={!!startDate}>
        <DateDiv onClick={handleOpen.bind(this)}>
          {startDate ? moment(startDate).format('M/D/YY') : 'Start'}
        </DateDiv>
        <div>-</div>
        <DateDiv onClick={handleOpen.bind(this)}>
          {endDate ? moment(endDate).format('M/D/YY') : 'End'}
        </DateDiv>
      </DateInputDiv>
    );
  };

  handleDateClear = () => {
    this.setState({
      newPhaseStartDate: null,
      newPhaseEndDate: null
    });
  };

  title = () => {
    const { selectedProject } = this.props;
    const {
      isEditing,
      newPhaseName,
      newPhaseStartDate,
      newPhaseEndDate,
      showDateWarning
    } = this.state;
    const { showAddMenu, addingMilestone, addingPhase } = this.context;

    const phaseValidatorErrors = createPhaseValidator({
      phaseName: newPhaseName,
      phaseStartDate: newPhaseStartDate,
      phaseEndDate: newPhaseEndDate,
      addingMilestone
    });

    const hasPhaseValidatorError = phaseValidatorErrors.length;

    let createRowContent;
    if (showAddMenu) {
      createRowContent = (
        <InputContainer>
          <span style={{ paddingLeft: '25px', fontWeight: 400 }}>Select</span>
          <CloseModalIconContainer data-testid="close-modal-icon">
            <CloseEditStateIcon />
          </CloseModalIconContainer>
        </InputContainer>
      );
    } else if (addingPhase || addingMilestone) {
      createRowContent = !isEditing ? (
        <InputContainer onClick={this.handleIsEditing}>
          <CreatePhaseInput
            id="createPhaseInput"
            placeholder={`Add Custom ${
              addingMilestone ? 'Milestone' : 'Phase'
            }`}
          />
          <CloseModalIconContainer onClick={this.clearAll}>
            <CloseEditStateIcon />
          </CloseModalIconContainer>
        </InputContainer>
      ) : (
        <InputContainer>
          <EditPhaseInput
            value={newPhaseName}
            onChange={(e) => this.handleNewPhaseNameChange(e)}
            placeholder={`${
              addingPhase ? `Enter phase name` : `Enter milestone name`
            }`}
            autoFocus
          />
          {/* <MemberPhaseInput>
            <div className="milestone-members">
              {newPhaseMembers.length > 3 ? (
                <PlusMemberContainer className="member-initials">
                  +{newPhaseMembers.length - 3}
                </PlusMemberContainer>
              ) : (
                ''
              )}
              {newPhaseMembers.slice(0, 3).map((teamMember, idx) => (
                <MemberInitials
                  key={teamMember.id}
                  member={teamMember}
                  idx={teamMember.id}
                  size="small"
                  classes={cn('regular-member selected', {
                    selected: false
                  })}
                  onClick={() => {}}
                />
              ))}
              <BulkMemberSelector
                projectId={selectedProject?.id}
                renderSelect={({ onClick }) => (
                  <AddPlusContainer onClick={onClick}>
                    <MemberAddPlusIcon />
                  </AddPlusContainer>
                )}
                handleDone={this.handleMemberDone}
                membershipLevel={'phase'}
                footerInitialCopy="Add All Project Members"
                memberFilter={this.memberFilter}
                bulkActionAffectedMemberIds={
                  selectedProject?.selectedProject_membership?.map(
                    member => member?.account?.id
                  ) ?? []
                }
              />
            </div>
          </MemberPhaseInput> */}
          <DatePhaseInput showDateWarning={showDateWarning}>
            <DateRangeCalendar
              itemStartDate={newPhaseStartDate}
              itemEndDate={newPhaseEndDate}
              onSubmit={this.handleNewDatechange}
              showClear
              onClear={this.handleDateClear}
              isSingleDay={!addingPhase}
              customInput={
                addingPhase
                  ? !newPhaseStartDate && !newPhaseEndDate
                    ? (startDate, endDate, handleOpen) => (
                        <SetDateLabel onClick={handleOpen.bind(this)}>
                          Plan
                        </SetDateLabel>
                      )
                    : this.doubleDateCustomInput
                  : this.singleDateCustomInput
              }
            />
          </DatePhaseInput>
          <AddButtonContainer>
            <AddPhaseButton
              allowCreate={!hasPhaseValidatorError}
              onClick={this.handleSubmit}
            >
              Add
            </AddPhaseButton>
            <CloseEditIconContainer onClick={this.clearAll}>
              <CloseEditStateIcon />
            </CloseEditIconContainer>
          </AddButtonContainer>
        </InputContainer>
      );
    } else {
      // const { setShowAddMenu } = this.context;
      // createRowContent = (
      //   <AddPlusIconContainer onClick={setShowAddMenu}>
      //     <StyledAddPlusIcon height={20} width={20} />
      //     Add New Phase/Milestone
      //   </AddPlusIconContainer>
      // );
    }

    const dropdownRef = React.createRef();

    return (
      <CreateRowContainer ref={dropdownRef}>
        {createRowContent}
        {/* {showAddMenu && (
          <AddMenu
            showAddMenu={showAddMenu}
            targetRef={this.addPhaseButtonRef}
            clearShowAddMenu={this.clearShowAddMenu}
            handleAddingMilestone={this.handleAddingMilestone}
            setAddingPhase={this.setAddingPhase}
          />
        )} */}
        {(addingPhase || addingMilestone) && !isEditing && (
          <PhaseTemplatesDropdown
            handleClose={this.clearAddingPhase}
            target={dropdownRef}
            isNew
            projectId={selectedProject?.id}
            isAllPhases
            customStyleClass={'milestone-modal-dropdown'}
            hideAddCustomPhase
            noHeader
            isEditing={isEditing}
            isMilestone={addingMilestone}
          />
        )}
      </CreateRowContainer>
    );
  };

  render() {
    const { column } = this.props;
    const { headerType } = column;
    const CellComponent = this[headerType];
    return <CellComponent />;
  }
}

const mapStateToProps = (state) => ({
  selectedProject: getSelectedProject(state)
});

const mapDispatchToProps = {
  openPhaseModal,
  createPhase
};

export default withRouter(
  withPermissionsCheck(
    connect(mapStateToProps, mapDispatchToProps)(MilestoneCreateRow)
  )
);
