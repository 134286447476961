import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';

const byId = (item) => item.id;

export const initialState = {
  isLoading: false,
  loaded: false,
  newClientId: null,
  clients: {},
  clientsOrder: []
};

const clients = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_CLIENTS.TRIGGER: {
      return {
        ...state,
        isLoading: true
      };
    }
    case constants.FETCH_CLIENTS.FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case constants.FETCH_CLIENTS.SUCCESS: {
      const { clients } = payload.response;
      return {
        ...state,
        isLoading: false,
        loaded: true,
        clientsOrder: clients.map(byId),
        clients: {
          ...state.clients,
          ...keyBy(clients, byId)
        }
      };
    }
    case constants.FETCH_CLIENT.SUCCESS: {
      const client = payload.response.client;
      return {
        ...state,
        clients: { ...state.clients, [client.id]: client }
      };
    }
    case constants.CREATE_CLIENT.SUCCESS: {
      const { client } = payload.response;
      return {
        ...state,
        newClientId: client.id,
        clientsOrder: [client.id, ...state.clientsOrder],
        clients: {
          ...state.clients,
          [client.id]: client
        }
      };
    }
    case constants.UPDATE_CLIENT.TRIGGER: {
      return {
        ...state,
        newGroupId: null
      };
    }
    case constants.UPDATE_CLIENT.SUCCESS: {
      const { client } = payload.response;
      return {
        ...state,
        clients: {
          ...state.clients,
          [client.id]: client
        }
      };
    }

    default:
      return state;
  }
};

export default clients;
