import styled from 'styled-components';
import { useScheduleCalendar } from './ScheduleCalendarProvider';
import moment from 'appUtils/momentConfig';
import { isToday } from 'appUtils/momentUtils';

const emptyObj = {};

/**
 *
 * @typedef {Object} DateTileProps
 * @property {Object} dateObj
 */

/**
 * @param {DateTileProps} props
 */
export const DateTile = ({ dateObj }) => {
  const { date } = dateObj;
  const {
    renderDateItem,
    getBackgroundRenderOptionByDate,
    renderBackground,
    getDataByDate,
    viewType
  } = useScheduleCalendar();

  const backgroundRenderOption = getBackgroundRenderOptionByDate(date);
  const hasBackgroundRender = !!backgroundRenderOption;
  const shouldRenderItem =
    !backgroundRenderOption || backgroundRenderOption.eventOverlap;
  const item = getDataByDate(date) || emptyObj;

  return (
    <DateTileContainer data-testid={`calendar-date-tile-${date}`}>
      {!!hasBackgroundRender && (
        <BackgroundEventContainer
          isVisible={backgroundRenderOption.visible}
          $offset={backgroundRenderOption.offset}
          $length={backgroundRenderOption.length}
        >
          {renderBackground({
            backgroundEvent: backgroundRenderOption.event,
            renderOption: backgroundRenderOption
          })}
        </BackgroundEventContainer>
      )}
      <OverflowSafeContainer>
        <DateTileContent data-testid={`calendar-date-content-${date}`}>
          {shouldRenderItem && renderDateItem(dateObj)}
        </DateTileContent>
        <DateDisplayContainer>
          <DateDisplayText
            isToday={isToday(moment(date))}
            isWeekend={dateObj.isWeekend}
            isCorrectMonth={dateObj.isCorrectMonth}
          >
            {Number(date.substr(8))}
          </DateDisplayText>
        </DateDisplayContainer>
        <StyledOffText shouldDisplayTextAtBottom={item.events?.length || 0}>
          {/*
            // Members > Schedule uses viewType 'year'. Schedule page has its own logic for displaying PTO and OFF
            // This one only cares about Calendar
           */}
          {(viewType === 'month' &&
            backgroundRenderOption?.event &&
            DAY_OFF_TEXT_MAP[backgroundRenderOption.event.type]) ||
            ''}
        </StyledOffText>
      </OverflowSafeContainer>
    </DateTileContainer>
  );
};

const DAY_OFF_TEXT_MAP = {
  PTO: 'PTO',
  holiday: 'OFF'
};

const AbsoluteFillContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DateTileContainer = styled.div`
  flex: 1;
  display: flex;
  min-height: 40px; // FIXME: use value from provider
  width: 51px;
`;

const OverflowSafeContainer = styled.div`
  flex: 1;
  /* overflow: hidden; */
  position: relative;
`;

const DateTileContent = styled(AbsoluteFillContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const DateDisplayContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 3px;
  left: 2px;
  line-height: 6.5px;
  pointer-events: none;
`;

const DateDisplayText = styled.span`
  font-size: 10px;
  user-select: none;
  font-weight: ${({ isToday }) => (isToday ? '600' : 'inherit')};
  color: ${({ isWeekend, isToday, isCorrectMonth, theme }) => {
    if (!isCorrectMonth) return theme.calendarColors.dateText.differentMonth;
    if (isToday) return theme.calendarColors.dateText.today;
    if (isWeekend) return theme.calendarColors.dateText.weekend;
    return theme.calendarColors.dateText.default;
  }};
`;

const StyledOffText = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.colorLightGray15};
  z-index: 1;
  pointer-events: none;

  ${({ shouldDisplayTextAtBottom }) =>
    shouldDisplayTextAtBottom
      ? `
    top: 85%;`
      : ''}
`;

const BackgroundEventContainer = styled(AbsoluteFillContainer)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  left: ${({ $offset }) => `calc(100% / 7 * ${$offset})`};
  right: ${({ $length, $offset }) =>
    `calc(100% / 7 * ${7 - $offset - $length})`};
`;
