import React from 'react';
import { HeaderText, HeaderContainer } from '../styles';
const Header = ({ text, children }) => (
  <HeaderContainer>
    <HeaderText>{text}</HeaderText>
    {children}
  </HeaderContainer>
);

export default Header;
