import { createSelector } from 'reselect';

import {
  initialState as notificationsInitialState,
  initialFilterState as notificationsInitialFilterState
} from '../reducers/notifications';

export const getNotificationsState = (state) =>
  state.notifications || notificationsInitialState;

const getOwnFilterStateId = (state, ownProps) =>
  ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id;

export const makeGetNotificationsStateByFilter = () =>
  createSelector(
    getNotificationsState,
    getOwnFilterStateId,
    (state, filterStateId) =>
      state.filterStates[filterStateId] || notificationsInitialFilterState
  );

export const makeGetUnreadNotificationsCountByProjects = () =>
  createSelector(
    makeGetNotificationsStateByFilter(),
    (state) => state.unreadCountsList
  );

export const makeGetTotalUnreadNotificationsListByFilter = () =>
  createSelector(
    makeGetNotificationsStateByFilter(),
    (state) => state.totalUnreadCount
  );

export const makeGetIsFetchingNotificationsByFilter = () =>
  createSelector(
    makeGetNotificationsStateByFilter(),
    (state) => state.isLoading
  );

export const makeGetProjectIdsByUnreadNotifications = () =>
  createSelector(makeGetNotificationsStateByFilter(), (state) =>
    state.unreadCountsList.reduce((acc, cur) => {
      acc.push(cur.project_id);
      return acc;
    }, [])
  );
