import { useSelector } from 'react-redux';
import {
  getPositions,
  getAccountToTeamPosition
} from 'BudgetModule/selectors/positions';
import { RequireAtLeastOne } from 'type-fest';
import { MemberBudget } from 'models/memberBudget';

type UseCurrentRolesProps = RequireAtLeastOne<{
  memberBudget?: MemberBudget;
  accountId?: number;
}>;

const useCurrentRoles = ({ memberBudget, accountId }: UseCurrentRolesProps) => {
  const roleHash = useSelector(getPositions);
  const currentTeamRoles = useSelector(getAccountToTeamPosition);

  const memberRole = memberBudget?.position_id
    ? roleHash[memberBudget.position_id]
    : undefined;

  const teamRole = accountId
    ? roleHash[currentTeamRoles[accountId]?.position_id]
    : memberBudget?.account_id
    ? roleHash[currentTeamRoles[memberBudget.account_id]?.position_id]
    : undefined;

  const teamRoleName = !teamRole || teamRole.is_default ? '' : teamRole.name;

  return { memberRole, teamRole, roleHash, currentTeamRoles, teamRoleName };
};

export default useCurrentRoles;
