import * as constants from '../constants/location';
import { action } from 'appUtils';

export const fetchLocationsByTeamMembershipActionCreatorsMap = {
  request: (id, requestPayload) =>
    action(constants.FETCH_LOCATIONS_BY_TEAM_MEMBERSHIP.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_LOCATIONS_BY_TEAM_MEMBERSHIP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_LOCATIONS_BY_TEAM_MEMBERSHIP.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const createOrUpdateEntityLocationActionCreatorsMap = {
  request: (requestPayload) =>
    action(constants.CREATE_OR_UPDATE_ENTITY_LOCATION.REQUEST, {
      payload: { requestPayload }
    }),
  success: (response, body, requestPayload) =>
    action(constants.CREATE_OR_UPDATE_ENTITY_LOCATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.CREATE_OR_UPDATE_ENTITY_LOCATION.FAILURE, {
      payload: { error, requestPayload }
    })
};
