import { callApi } from 'appUtils/api';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import { UpdateUserParams } from './types';

const updateUser = (token: string, { isTOSCompliant }: UpdateUserParams) => {
  const body = convertKeysToSnakeCase({ account: { isTOSCompliant } });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('settings', token, fetchInit);
};

export const usersApi = { updateUser };
