import styled, { css } from 'styled-components';
import theme from 'theme';
import CheckIcon from 'icons/CheckIcon';
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle
} from 'reactstrap';

export const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 2px 0px 10px;
`;

export const StyledDropdown = styled(Dropdown)`
  ${({ $dropdownButtonStyle }) => $dropdownButtonStyle}
  ${(props) =>
    props.$isDisabled &&
    `
    cursor: not-allowed;
    filter: grayscale(1);
    .btn-secondary {
      pointer-events: none;
    }
  `}
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  font-size: 13px;
  &.btn-secondary,
  &.btn-secondary:active,
  &.btn-secondary:focus,
  &.btn-secondary:hover {
    color: ${theme.colors.colorRoyalBlue};
  }

  .nested-dropdown & {
    font-size: 12px;
    display: block;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &.btn-secondary,
    &.btn-secondary:active,
    &.btn-secondary:focus {
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }
`;

const dropdownMenuStyles = css`
  font-size: 12px;
  display: flex;
  align-items: center;
  background: transparent;
  color: ${theme.colors.colorSemiDarkGray1};
  padding: 6px 20px;
  ${(props) =>
    props.$isDisabled &&
    `
    opacity: 0.7;
  `}
  &.active {
    color: ${theme.colors.colorSemiDarkGray1};
    background: transparent;
    position: relative;
  }

  &:hover {
    ${(props) =>
      props.$isDisabled
        ? `
          background: transparent !important;
          cursor: not-allowed !important;
          color: unset;
        `
        : `
          background: ${theme.colors.colorPaleGray1};
          color: ${theme.colors.colorSemiDarkGray1};
    `}
  }
`;
export const StyledDropdownMenuItem = styled(DropdownItem)`
  ${dropdownMenuStyles}
`;

export const StyledDropdownMenuNonItem = styled.div`
  ${dropdownMenuStyles}
`;

export const StyledDropdownHeader = styled(StyledDropdownMenuItem)`
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  margin: 5px 0 2px;
  cursor: default;
  padding: 6px 10px;
  &:hover {
    background: transparent;
  }
`;

export const CheckIconContainer = styled.div`
  flex: none;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  height: 100%;
  width: 20px;
`;

export const StyledCheckIcon = styled(CheckIcon)``;
