import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, useDispatch } from 'react-redux';
import { sortBudgetSettings } from 'actionCreators';
import {
  StyledReportHeader,
  StyledSortIcon,
  StyledSortIconContainer
} from 'ReportsModule/components/styles';
import { SORT_ORDER, OPPOSITE_SORT_ORDER, SORT_BY } from 'appConstants/filters';

const headerStyle = { right: -5, position: 'relative' };
const StyledHeader = styled(StyledReportHeader)`
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorCalendarBlue : theme.colors.colorCalendarGray};
  ${({ isActive }) => isActive && 'font-weight: 700;'}
`;

const HeaderCell = ({ column }) => {
  const { headerLabel, headerType, align } = column;
  const dispatch = useDispatch();
  const { value, order } = useSelector(
    (state) => state.settings.adminBudgetTableSort
  );

  const isActive = value === headerType;

  const showAsActive =
    isActive && !(headerType === SORT_BY.member && order === SORT_ORDER.desc);

  const setSortState = () => {
    const newOrder = isActive ? OPPOSITE_SORT_ORDER[order] : SORT_ORDER.desc;

    const newValue =
      order === SORT_ORDER.asc && isActive ? SORT_BY.member : headerType;

    dispatch(sortBudgetSettings({ order: newOrder, value: newValue }));
  };

  if (!headerLabel) {
    return <div />;
  }

  return (
    <StyledHeader align={align} onClick={setSortState} isActive={showAsActive}>
      {headerLabel}

      <StyledSortIconContainer
        isActive={showAsActive}
        order={order}
        style={headerStyle}
      >
        <StyledSortIcon />
      </StyledSortIconContainer>
    </StyledHeader>
  );
};

export default HeaderCell;
