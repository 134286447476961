import React, { ComponentType } from 'react';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const withIsHoursOnly = (
  Component: ComponentType<React.PropsWithChildren<any>>
) => {
  const WithIsHoursOnly = ({ ...props }) => {
    const { isHoursOnly } = useIsHoursOnly();

    return <Component {...props} isHoursOnly={isHoursOnly} />;
  };

  return WithIsHoursOnly;
};

export default withIsHoursOnly;
