// Copied from Recharts
export function getRectanglePath(x, y, width, height, radius) {
  var maxRadius = Math.min(Math.abs(width) / 2, Math.abs(height) / 2);
  var ySign = height >= 0 ? 1 : -1;
  var xSign = width >= 0 ? 1 : -1;
  var clockWise =
    (height >= 0 && width >= 0) || (height < 0 && width < 0) ? 1 : 0;
  var path;

  if (maxRadius > 0 && radius instanceof Array) {
    var newRadius = [];

    for (var i = 0, len = 4; i < len; i++) {
      newRadius[i] = radius[i] > maxRadius ? maxRadius : radius[i];
    }

    path = 'M'.concat(x, ',').concat(y + ySign * newRadius[0]);

    if (newRadius[0] > 0) {
      path += 'A '
        .concat(newRadius[0], ',')
        .concat(newRadius[0], ',0,0,')
        .concat(clockWise, ',')
        .concat(x + xSign * newRadius[0], ',')
        .concat(y);
    }

    path += 'L '.concat(x + width - xSign * newRadius[1], ',').concat(y);

    if (newRadius[1] > 0) {
      path += 'A '
        .concat(newRadius[1], ',')
        .concat(newRadius[1], ',0,0,')
        .concat(clockWise, ',\n        ')
        .concat(x + width, ',')
        .concat(y + ySign * newRadius[1]);
    }

    path += 'L '
      .concat(x + width, ',')
      .concat(y + height - ySign * newRadius[2]);

    if (newRadius[2] > 0) {
      path += 'A '
        .concat(newRadius[2], ',')
        .concat(newRadius[2], ',0,0,')
        .concat(clockWise, ',\n        ')
        .concat(x + width - xSign * newRadius[2], ',')
        .concat(y + height);
    }

    path += 'L '.concat(x + xSign * newRadius[3], ',').concat(y + height);

    if (newRadius[3] > 0) {
      path += 'A '
        .concat(newRadius[3], ',')
        .concat(newRadius[3], ',0,0,')
        .concat(clockWise, ',\n        ')
        .concat(x, ',')
        .concat(y + height - ySign * newRadius[3]);
    }

    path += 'Z';
  } else if (maxRadius > 0 && radius === +radius && radius > 0) {
    var _newRadius = Math.min(maxRadius, radius);

    path = 'M '
      .concat(x, ',')
      .concat(y + ySign * _newRadius, '\n            A ')
      .concat(_newRadius, ',')
      .concat(_newRadius, ',0,0,')
      .concat(clockWise, ',')
      .concat(x + xSign * _newRadius, ',')
      .concat(y, '\n            L ')
      .concat(x + width - xSign * _newRadius, ',')
      .concat(y, '\n            A ')
      .concat(_newRadius, ',')
      .concat(_newRadius, ',0,0,')
      .concat(clockWise, ',')
      .concat(x + width, ',')
      .concat(y + ySign * _newRadius, '\n            L ')
      .concat(x + width, ',')
      .concat(y + height - ySign * _newRadius, '\n            A ')
      .concat(_newRadius, ',')
      .concat(_newRadius, ',0,0,')
      .concat(clockWise, ',')
      .concat(x + width - xSign * _newRadius, ',')
      .concat(y + height, '\n            L ')
      .concat(x + xSign * _newRadius, ',')
      .concat(y + height, '\n            A ')
      .concat(_newRadius, ',')
      .concat(_newRadius, ',0,0,')
      .concat(clockWise, ',')
      .concat(x, ',')
      .concat(y + height - ySign * _newRadius, ' Z');
  } else {
    path = 'M '
      .concat(x, ',')
      .concat(y, ' h ')
      .concat(width, ' v ')
      .concat(height, ' h ')
      .concat(-width, ' Z');
  }

  return path;
}

export const getRowsToShow = (data) => {
  const billingToShow = data.isFuture
    ? 'futureEstimatedExpense'
    : 'estimatedExpense';
  const showBilling = !!data[billingToShow];
  const costToShow = data.isFuture ? 'futurePlannedExpense' : 'spentExpense';
  const showCost = !!data[costToShow];
  const showProfit = showBilling && showCost;
  const showPlanned = !showProfit && !!data.plannedExpense;

  const rows = [];

  if (showBilling) {
    rows.push(billingToShow);
  }
  if (showCost) {
    rows.push(costToShow);
  }
  if (showPlanned) {
    rows.push('plannedExpense');
  }
  if (showProfit) {
    rows.push('profit');
  }
  return rows;
};
