import styled from 'styled-components';
import { NumberFormatInput } from 'components/styles';
import theme from 'theme';

export const StyledKaratContainer = styled.div<{
  isOpen: boolean;
  isHidden?: boolean;
}>`
  height: 24px;
  width: 24px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  position: relative;
  left: 10px;
  top: 34px;
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
    path {
      stroke: ${theme.colors.colorCalendarBlue};
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};

  &.activity-phase {
    top: 3px;
    left: 33px;
  }

  &.member {
    height: 20px;
    width: 20px;
    position: relative;
    top: 0;
    left: -4px;
  }

  ${(props) =>
    props.isHidden &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
`;

export const StyledNumberFormatInput = styled(NumberFormatInput)`
  border-radius: 3px;
  height: 24px;
  cursor: pointer;

  &:focus,
  &:hover {
    background: ${theme.colors.colorLightGray17};
  }
`;

export const StyledPlusIcon = styled.div`
  cursor: pointer;

  &.reject-icon {
    circle {
      stroke: ${theme.colors.colorCalendarGray};
    }

    path {
      fill: ${theme.colors.colorCalendarGray};
      stroke: transparent;
    }

    &:hover {
      circle {
        fill: ${theme.colors.colorCalendarGray};
      }

      path {
        fill: ${theme.colors.colorPureWhite};
        stroke: transparent;
      }
    }
  }

  &.add-icon {
    &:hover {
      circle {
        fill: ${theme.colors.colorRoyalBlue};
      }

      path {
        stroke-width: 1px;
        stroke: ${theme.colors.colorPureWhite};
      }
    }
  }
`;
