import {
  PhaseTotals,
  AccountTotals
} from 'ProjectsModule/models/projectTotals';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';
import { checkPhaseHasActivityPhase } from 'ProjectsModule/utils/phaseDisplay';

export type FormattedPhaseTotals = Record<number, FormattedPhaseTotal>;

type FormattedPhaseTotal = PhaseTotals & {
  activitiesHash: {
    [activityId: number | string]: {
      activityAccountTotalsByActivityPhaseMembershipId?: {
        [activityPhaseMembershipId: number]: AccountTotals;
      };
    };
  };
};

/**
 * This function only works properly if `shouldFormatData = true` flag is added to makeGetOwnBudgetTotals
 * Get the accountTotals object that contains budget data for this particular membership in the phase/activity phase
 */
export const getAccountTotalsFromFormattedPhaseTotals = ({
  formattedPhaseTotals,
  phase,
  activityId,
  activityPhaseMembershipId
}: {
  phase: PhaseWithDefault | Phase;
  formattedPhaseTotals: FormattedPhaseTotals;
  activityId: number;
  activityPhaseMembershipId: number;
}) => {
  const phaseTotal = phase ? formattedPhaseTotals[phase.id] : undefined;
  const hasActivityPhases = checkPhaseHasActivityPhase(phase);

  const accountTotalsByActivityPhaseMembershipId =
    phaseTotal?.activitiesHash[hasActivityPhases ? activityId : 'null']
      ?.activityAccountTotalsByActivityPhaseMembershipId;

  const accountTotals =
    accountTotalsByActivityPhaseMembershipId?.[activityPhaseMembershipId];

  return accountTotals;
};
