import React from 'react';
import SvgIcon from 'components/SvgIcon';

const StageIcon = ({ height = '13', width = '13' }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 13 13">
      <line
        x1="0.5"
        y1="-0.5"
        x2="12.03"
        y2="-0.5"
        transform="matrix(0.478852 -0.877896 0.853282 0.52145 1.64966 12.0448)"
        stroke="#979797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="12.03"
        y2="-0.5"
        transform="matrix(0.478852 -0.877896 0.853282 0.52145 5.64966 12.0448)"
        stroke="#979797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="0.701904"
        y1="8.50674"
        x2="9.7019"
        y2="8.50674"
        stroke="#979797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="2.7019"
        y1="4.27286"
        x2="11.7019"
        y2="4.27286"
        stroke="#979797"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default StageIcon;
