import { createSelector } from 'reselect';
import { initialState as downloadFileInitialState } from 'reducers/downloadFile';

const getDownloadFileState = (state) =>
  state.downloadFile || downloadFileInitialState;

export const getDownloadUrl = createSelector(
  getDownloadFileState,
  (state) => state.downloadUrl
);
