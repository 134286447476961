// Replaces timestamp in hover state, has click event that sends mark as read to back-end

import React from 'react';

const MarkAsRead = ({ onClick, isRead }) => (
  <div className="mark-as-read" onClick={onClick}>
    {isRead ? 'Mark as unread' : 'Mark as Read'}
  </div>
);

export default MarkAsRead;
