import { useWorkPlanForm } from '../WorkPlanFormProvider';
import { SimpleConfirmModal } from 'components/Modals';

export const ReassignWorkplansConfirmModal = () => {
  const {
    member: {
      isReassignConfirmModalOpen,
      member,
      unassignedMemberBudget,
      onConfirmToMerge,
      onSetReassignConfirmModalOpen
    }
  } = useWorkPlanForm();

  const getConfirmMessage = () => {
    return (
      <div>
        Are you sure you want to <i> reassign all Work Plans </i> from{' '}
        {unassignedMemberBudget?.position?.name} to{' '}
        <b>{member?.account?.name}?</b>
      </div>
    );
  };

  return (
    <SimpleConfirmModal
      isOpen={isReassignConfirmModalOpen}
      onConfirm={onConfirmToMerge}
      toggle={() => {
        onSetReassignConfirmModalOpen(false);
      }}
      confirmLabel="Reassign"
      body={getConfirmMessage()}
      header={undefined}
      confirmOnly={undefined}
      stylesWrapper={undefined}
      hasDoNotShowAgain={undefined}
      doNotShowAgainSettingName={undefined}
      submitButtonColor={undefined}
      confirmOnLeft={undefined}
      onCancel={undefined}
      preventClickOut={undefined}
      className={undefined}
    />
  );
};
