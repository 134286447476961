import styled from 'styled-components';
import theme from 'theme';
import { deserializeId } from 'appUtils';
import CollapseArrow from 'components/CollapseArrow';
import { BaseTableList } from 'components/Table/types';
import HelpIcon from 'icons/HelpIcon';
import { permissionConstants } from 'PermissionsModule/constants';
import { EmploymentTypes } from 'PermissionsModule/types';
import { permissionsModuleConstants } from 'PermissionsModule/components/constants';

const { employmentTypeDescriptions } = permissionsModuleConstants;

const { EMPLOYMENT_TYPES } = permissionConstants;

const employmentTypeToLabelText: Record<
  EmploymentTypes,
  { primaryText: string; secondaryText?: string; tooltipText?: string }
> = {
  [EMPLOYMENT_TYPES.member]: { primaryText: 'MEMBER' },
  [EMPLOYMENT_TYPES.projectGuest]: {
    primaryText: 'PROJECT GUEST',
    tooltipText: employmentTypeDescriptions[EMPLOYMENT_TYPES.projectGuest]
  },
  [EMPLOYMENT_TYPES.internalContractor]: {
    primaryText: 'CONTRACTOR',
    secondaryText: '- INTERNAL',
    tooltipText: employmentTypeDescriptions[EMPLOYMENT_TYPES.internalContractor]
  },
  [EMPLOYMENT_TYPES.externalProjectContractor]: {
    primaryText: 'PROJECT CONTRACTOR',
    secondaryText: '- EXTERNAL',
    tooltipText:
      employmentTypeDescriptions[EMPLOYMENT_TYPES.externalProjectContractor]
  }
};

export const MembersCapacityTableMemberTypeSectionHeaderCell = ({ row }) => {
  const { id, toggleCollapse, isOpen, totalCount } =
    row.original as BaseTableList;
  const { itemId: employmentType } = deserializeId(id);

  const { primaryText, secondaryText, tooltipText } =
    employmentTypeToLabelText[employmentType as EmploymentTypes];

  return (
    <RootContainer>
      <CollapseArrow toggleCollapse={toggleCollapse} isCollapsed={!isOpen} />
      <TooltipContainer
        data-effect="solid"
        data-for="app-tooltip"
        data-html
        data-tip={tooltipText}
        data-class="mw-250 center"
      >
        <PrimaryText>{primaryText}</PrimaryText>
        {secondaryText && (
          <>
            <span>&nbsp;</span>
            <SecondaryText>{secondaryText}</SecondaryText>
          </>
        )}
        {!isOpen && totalCount !== undefined && (
          <TotalCountText>| {totalCount}</TotalCountText>
        )}
        {tooltipText && (
          <IconContainer>
            <HelpIcon width={8} height={8} />
          </IconContainer>
        )}
      </TooltipContainer>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
`;

const TooltipContainer = styled.div`
  position: relative;
`;

const PrimaryText = styled.span``;

const SecondaryText = styled.span`
  color: ${theme.colors.colorCalendarGray};
`;

const IconContainer = styled.span`
  position: absolute;
  top: -10px;
`;

const TotalCountText = styled.span`
  color: ${theme.colors.colorLightGray15};
`;
