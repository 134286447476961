import styled from 'styled-components';

export const FloatingContainer = styled.div`
  position: absolute;
  top: 75%;
  display: flex;
`;
export const CenterValueText = styled.span`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

export const PTOValueText = styled.span`
  font-size: clamp(10px, 1vw, 13px);
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  white-space: nowrap;
  font-weight: 600;
`;

export const CenterContainer = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const BackgroundContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PTOHatchBackground = styled(BackgroundContainer)`
  background: repeating-linear-gradient(
    135deg,
    #ffffff,
    #ffffff 3px,
    #d4e2f6,
    #d4e2f6 7px
  );
`;

export const HolidayHatchBackground = styled(BackgroundContainer)`
  background: repeating-linear-gradient(
    135deg,
    #ffffff,
    #ffffff 3px,
    #ebebeb,
    #ebebeb 7px
  );
`;

export const HeatmapContainer = styled(BackgroundContainer).attrs<{
  $capacityColor?: string;
  $summaryBackgroundHeight: number;
  $overflowSize?: number;
}>(({ $capacityColor, $summaryBackgroundHeight, $overflowSize, theme }) => ({
  style: {
    background: `
    ${
      $overflowSize !== undefined
        ? `linear-gradient(135deg, #CA6778 ${$overflowSize}px, transparent ${$overflowSize}px),`
        : ''
    }
    ${
      $capacityColor
        ? `linear-gradient(
          to top,
          ${$capacityColor} ${$summaryBackgroundHeight}%,
          ${$capacityColor}A0 ${$summaryBackgroundHeight}%),`
        : ''
    }
    ${theme.colors.colorPureWhite}`
  }
}))<{
  $capacityColor?: string;
  $summaryBackgroundHeight: number;
  $overflowSize?: number;
}>``;
