import React from 'react';
import { Modal } from 'reactstrap';
import AddEditNoteForm from './AddEditNoteForm';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import { StaticToolbar } from './styles';
import RichTextToolbar from './RichTextToolbar.js';
import CloseModalIcon from 'components/Modals/CloseModalIcon';
import { CloseModalIconContainer } from 'views/Home/Tasks/styles';

class AddEditNoteModal extends React.Component {
  state = {
    isEditingTitle: false,
    isEditingBody: false,
    deleteNoteMenuOpen: false
  };

  setEditorRef = (editor) => {
    this.slateEditor = editor;
  };

  editTitle = (e) => {
    this.setState({
      isEditingTitle: true,
      isEditingBody: false
    });
  };

  blurTitle = () => {
    this.setState({ isEditingTitle: false });
  };

  editBody = () => {
    if (!this.state.isEditingTitle) {
      this.setState({
        isEditingBody: true,
        isEditingTitle: false
      });
    }
  };

  blurBody = () => {
    this.setState({ isEditingBody: false });
  };

  render() {
    const {
      isOpen,
      closeModal,
      isEditModal,
      handleSubmit,
      deleteNoteOnClick,
      title,
      openDeleteNoteModal,
      deleteNoteModalIsOpen,
      toggleDeleteNoteModal,
      slateBody,
      handleTitleChange,
      handleBodyChange,
      projectName,
      allTags
    } = this.props;

    return (
      <div>
        {isEditModal ? (
          <DeleteModal
            isOpen={!!deleteNoteModalIsOpen}
            toggle={toggleDeleteNoteModal}
            deleteOnClick={deleteNoteOnClick}
            component={'note'}
          />
        ) : null}

        <Modal
          isOpen={isOpen && !deleteNoteModalIsOpen}
          toggle={closeModal}
          className="add-edit-note-modal"
        >
          <CloseModalIconContainer
            data-testid="close-modal-icon"
            onClick={closeModal}
          >
            <CloseModalIcon />
          </CloseModalIconContainer>
          <AddEditNoteForm
            deleteNote={deleteNoteOnClick}
            handleTitleChange={handleTitleChange}
            handleBodyChange={handleBodyChange}
            title={title}
            slateBody={slateBody}
            deleteNoteMenuOpen={this.state.deleteNoteMenuOpen}
            openDeleteNoteModal={openDeleteNoteModal}
            isEditModal={isEditModal}
            handleSubmit={handleSubmit}
            isEditingTitle={this.state.isEditingTitle}
            isEditingBody={this.state.isEditingBody}
            editTitle={this.editTitle}
            blurTitle={this.blurTitle}
            editBody={this.editBody}
            blurBody={this.blurBody}
            projectName={projectName}
            allTags={allTags}
            setEditorRef={this.setEditorRef}
            editor={this.slateEditor}
          />
          {this.slateEditor && (
            <StaticToolbar>
              <RichTextToolbar editor={this.slateEditor} />
            </StaticToolbar>
          )}
        </Modal>
      </div>
    );
  }
}

export default AddEditNoteModal;
