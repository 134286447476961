import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOverhead } from 'BudgetModule/selectors';
import { createOverhead } from 'BudgetModule/actionCreators';
import { getSelectedTeamId } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import {
  StyledModal,
  Header,
  Body,
  DoneButton,
  LabelText
} from './shared/styles';
import Input, { StyledInput } from './shared/Input';
import { addCommasToNum, removeCommas } from 'appUtils/budgetUtils';
import HelpIcon from 'images/help-icon-2.svg';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const InputsContainer = styled.div`
  display: flex;
`;

const DivideSign = styled.div`
  width: 20px;
  align-self: flex-end;
  padding-bottom: 7px;
  text-align: center;
`;

const InputRow = styled.div`
  display: flex;
  align-items: flex-end;
  ${(props) =>
    props.disabled &&
    `
      color: ${theme.colors.colorLightGray15};
      ${StyledInput} {
        opacity: 0.8;
        color: ${theme.colors.colorLightGray15};
      }
    `}
`;

const StyledBody = styled(Body)`
  color: ${theme.colors.colorMediumGray9};
  margin-bottom: 32px;
  .override-row {
    display: flex;
    align-items: center;
    float: right;
    margin: 15px 5px 0 0;
    ${LabelText} {
      margin-right: 15px;
    }
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${theme.colors.colorLightGray6};
  font-size: 15px;
  font-weight: 600;
  color: ${theme.colors.colorLightGray15};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 46px;
  cursor: pointer;
  background: white;
  &:hover {
    filter: brightness(98%);
  }
`;

const EqualSign = styled.div`
  padding-bottom: 5px;
  position: relative;
  left: -11px;
`;

const Overhead = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 58px;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.colorMediumGray9};
  ${(props) =>
    props.disabled &&
    `
    color: ${theme.colors.colorLightGray15};
  `}
`;

/* ------------------------------------------------------------------------ */

const OverheadModal = ({ toggle, onOverheadUpdateSuccess }) => {
  const teamId = useSelector(getSelectedTeamId);
  const teamOverhead = useSelector(getOverhead);
  const [expense, setExpense] = useState(teamOverhead.total_expense);
  const [payroll, setPayroll] = useState(teamOverhead.total_payroll);
  const [override, setOverride] = useState(
    teamOverhead.overhead_factor &&
      !+teamOverhead.total_payroll &&
      !+teamOverhead.total_expense
      ? teamOverhead.overhead_factor
      : ''
  );
  const [isEditingOverride, setIsEditingOverride] = useState(false);
  const [overheadFactor, setOverheadFactor] = useState(
    teamOverhead.overhead_factor
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setOverheadFactor(
      isNaN(expense / payroll) || Infinity === expense / payroll
        ? 0
        : Math.min(9.9, (expense / payroll).toFixed(1))
    );
  }, [expense, payroll]);

  const handleExpenseChange = (e) => {
    const nextValue = removeCommas(e.target.value)
      .replace('Err', '')
      .replace('$', '');
    setExpense(nextValue);
  };

  const handlePayrollChange = (e) => {
    const nextValue = removeCommas(e.target.value)
      .replace('Err', '')
      .replace('$', '');
    setPayroll(nextValue);
  };

  const handleOverrideBlur = (e) => {
    let nextValue = Number(e.target.value);
    if (nextValue) {
      if (Math.round(nextValue) !== nextValue) {
        nextValue = nextValue.toFixed(1);
      }
      setOverride(Math.min(9.9, nextValue));
    } else {
      setOverride('');
    }
    setIsEditingOverride(false);
  };

  const handleConfirm = () => {
    const onSuccess = onOverheadUpdateSuccess
      ? [
          {
            successAction: onOverheadUpdateSuccess,
            selector: () => {}
          }
        ]
      : [];
    if (override && override !== teamOverhead.overhead_factor) {
      dispatch(
        createOverhead({
          teamId,
          totalPayroll: 0,
          totalExpense: 0,
          overheadFactor: override,
          onSuccess
        })
      );
    } else if (
      payroll &&
      expense &&
      overheadFactor &&
      overheadFactor !== teamOverhead.overhead_factor
    ) {
      dispatch(
        createOverhead({
          teamId,
          totalPayroll: payroll,
          totalExpense: expense,
          overheadFactor: overheadFactor,
          onSuccess
        })
      );
    }
    toggle();
  };

  // Delete / backspace clears Err
  const handleResetOnError = (e) => {
    if (
      e.target.value === 'Err' &&
      (e.key === 'Backspace' || e.key === 'Delete')
    ) {
      const targetId = e.target.id;
      if (targetId === 'expense') setExpense(0);
      else if (targetId === 'payroll') setPayroll(0);
    }
  };

  const prepareValue = (value) =>
    !isNaN(value) ? '$' + addCommasToNum(value) : 'Err';

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const inputsDisabled = !!override || isEditingOverride;

  return (
    <StyledModal isOpen toggle={toggle}>
      <Header>
        Overhead Factor
        <img
          data-for="app-tooltip"
          data-tip="Company Wide based on Total <br/> Payroll Expense divided by Total </br> Overhead expenses."
          data-html
          data-class="center"
          src={HelpIcon}
          style={{ marginLeft: 5 }}
        />
        <DoneButton onClick={handleConfirm}>Done</DoneButton>
      </Header>
      <StyledBody>
        <InputRow disabled={inputsDisabled}>
          <InputsContainer>
            <Input
              id="expense"
              label={
                <div>
                  Total Expense{' '}
                  <img
                    data-for="app-tooltip"
                    data-tip="Includes project & <br/> non-project labor"
                    data-html
                    data-class="center"
                    src={HelpIcon}
                    style={{
                      width: 12,
                      marginTop: 6,
                      marginLeft: 2,
                      position: 'absolute'
                    }}
                  />
                </div>
              }
              width={116}
              value={prepareValue(expense)}
              onChange={handleExpenseChange}
              onKeyDown={handleResetOnError}
              onFocus={() => setOverride('')}
            />
            <DivideSign>÷</DivideSign>
            <Input
              id="payroll"
              label="Total Direct Payroll"
              width={116}
              value={prepareValue(payroll)}
              onChange={handlePayrollChange}
              onKeyDown={handleResetOnError}
              onFocus={() => setOverride('')}
            />
          </InputsContainer>
          <EqualSign>=</EqualSign>
          <Overhead disabled={inputsDisabled}>{overheadFactor}</Overhead>
        </InputRow>
        <div className="override-row">
          <LabelText>Overhead Factor</LabelText>
          <Input
            id="override"
            width={43}
            value={override}
            onChange={(e) => setOverride(e.target.value)}
            onBlur={handleOverrideBlur}
            onFocus={() => setIsEditingOverride(true)}
          />
        </div>
      </StyledBody>
      {/* <Footer>Learn More</Footer> */}
    </StyledModal>
  );
};

export default OverheadModal;
