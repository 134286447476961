import { ModalBody } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';

import {
  getPhaseTemplateModalId,
  getModalPhaseTemplateName,
  getModalMilestoneTemplateName,
  getLowerCaseExistingPhaseTemplates,
  getLowerCaseExistingMilestoneTemplates,
  getSelectedTeamId,
  getPhaseName
} from 'selectors';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  RemoveButton,
  MainDeleteButton
} from 'BudgetModule/components/styles';
import PhaseOfWorkTemplateInput from 'BudgetModule/components/PhaseOfWorkTemplateInput';
import {
  createPhaseTemplate,
  createMilestoneTemplate,
  updatePhaseTemplate,
  deletePhaseTemplate,
  closePhaseTemplateModal
} from 'actionCreators';
import { getBudgetModalProjectId } from 'BudgetModule/selectors';

class PhaseModalBody extends React.Component {
  state = {
    name: this.props.name || ''
  };

  handleChange = (e) => {
    this.setState({ name: e.target.value });
  };

  onCancel = () => {
    this.handleClose();
  };

  onSubmit = (params) => {
    this.apiCall(params);
    this.handleClose();
  };

  onConfirm = () => {
    const { teamId, id, projectId } = this.props;

    const { name } = this.state;

    this.apiCall({
      teamId,
      name,
      id,
      projectId
    });

    this.handleClose();
  };

  handleClose = () => {
    const { closePhaseTemplateModal } = this.props;
    closePhaseTemplateModal({});
  };

  apiCall = (params) => {
    const {
      isMilestone,
      isNew,
      updatePhaseTemplate,
      createPhaseTemplate,
      createMilestoneTemplate,
      deletePhaseTemplate,
      isRemove,
      lowerCaseExistingPhaseTemplates,
      lowerCaseExistingMilestoneTemplates
    } = this.props;

    const hasName =
      (isMilestone &&
        lowerCaseExistingMilestoneTemplates[params.name.toLowerCase()]) ||
      (!isMilestone &&
        lowerCaseExistingPhaseTemplates[params.name.toLowerCase()]);
    if (hasName && !isRemove) {
      console.error(
        `this ${isMilestone ? 'milestone' : 'phase'} name is already taken`
      );
      return;
    }
    const apiCall = isNew
      ? isMilestone
        ? createMilestoneTemplate
        : createPhaseTemplate
      : isRemove
      ? deletePhaseTemplate
      : updatePhaseTemplate;

    apiCall(params);
  };

  renderRemoveButtons = () => {
    const { clearRemove } = this.props;
    return (
      <>
        <Left />
        <Right>
          <MainDeleteButton onClick={this.onConfirm}>Delete</MainDeleteButton>
          <CancelButton onClick={clearRemove}>Cancel</CancelButton>
        </Right>
      </>
    );
  };

  renderInitialButtons = () => {
    const { isNew, setRemove } = this.props;
    return (
      <>
        <Left>
          {!isNew && <RemoveButton onClick={setRemove}>Delete</RemoveButton>}
        </Left>
        <Right>
          <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
          <ConfirmButton isGray={!this.state.name} onClick={this.onConfirm}>
            {isNew ? 'Create' : 'Save'}
          </ConfirmButton>
        </Right>
      </>
    );
  };

  renderButtons = () => {
    const { isRemove } = this.props;
    return isRemove ? this.renderRemoveButtons() : this.renderInitialButtons();
  };

  renderBody = () => {
    const {
      name,
      milestoneTemplateName,
      id,
      isMilestone,
      isRemove,
      phaseName
    } = this.props;
    return isRemove ? (
      <div>
        <div className="remove-header">
          Are you sure you want to <b>permanently delete</b>{' '}
          {name || milestoneTemplateName}?
        </div>
        <div className="remove-text">
          This {isMilestone ? 'Milestone' : 'Phase'} will only be deleted from
          the Standard {isMilestone ? 'Milestone' : 'Phases'} list, not from any
          project(s) it was previously added to.
        </div>
      </div>
    ) : (
      <>
        <PhaseOfWorkTemplateInput
          isMilestone={isMilestone}
          handleSubmit={this.onSubmit}
          name={this.props.name || milestoneTemplateName || phaseName}
          onChangeCallback={this.handleChange}
          id={id}
          isInModal={true}
        />
      </>
    );
  };

  render() {
    return (
      <ModalBody>
        {this.renderBody()}
        <ModalButtonsRow>{this.renderButtons()}</ModalButtonsRow>
      </ModalBody>
    );
  }
}

const mapStateToProps = (state) => ({
  teamId: getSelectedTeamId(state),
  id: getPhaseTemplateModalId(state),
  isNew: !getPhaseTemplateModalId(state),
  projectId: getBudgetModalProjectId(state),
  name: getModalPhaseTemplateName(state),
  milestoneTemplateName: getModalMilestoneTemplateName(state),
  phaseName: getPhaseName(state),
  lowerCaseExistingPhaseTemplates: getLowerCaseExistingPhaseTemplates(state),
  lowerCaseExistingMilestoneTemplates:
    getLowerCaseExistingMilestoneTemplates(state)
});

const mapDispatchToProps = {
  createMilestoneTemplate,
  updatePhaseTemplate,
  createPhaseTemplate,
  deletePhaseTemplate,
  closePhaseTemplateModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PhaseModalBody);
