import { fork, takeLatest, takeEvery, all } from 'redux-saga/effects';
import {
  phaseSuggestionsActions,
  accountsSuggestionActions
} from 'SuggestionModule/actionCreators';
import { phaseSuggestionsWorkers } from 'SuggestionModule/sagas/phase';
import { accountsSuggestionWorker } from 'SuggestionModule/sagas/account';
import { conditionalTakeLatest } from 'sagas/helpers';

export default function* suggestionSaga() {
  const sagas = [
    fork(
      takeLatest,
      phaseSuggestionsActions.fetchPhaseSuggestionsForTimesheetAccounts,
      phaseSuggestionsWorkers.fetchPhaseSuggestionsForTimesheetAccounts
    ),
    fork(
      takeEvery,
      accountsSuggestionActions.fetchAccountsSuggestionForProjects,
      accountsSuggestionWorker.fetchAccountsSuggestionForProjects
    ),
    fork(
      conditionalTakeLatest,
      accountsSuggestionActions.fetchAccountsSuggestionForPhases,
      accountsSuggestionWorker.fetchAccountsSuggestionForPhases,
      (action) => action.meta.isTakeLatest,
      ['requestStatusId']
    ),
    fork(
      conditionalTakeLatest,
      accountsSuggestionActions.fetchAccountsSuggestionForActivityPhaseMemberships,
      accountsSuggestionWorker.fetchAccountsSuggestionForActivityPhaseMemberships,
      (action) => action.meta.isTakeLatest,
      ['requestStatusId']
    )
  ];
  yield all(sagas);
}
