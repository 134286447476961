import React from 'react';
import { connect } from 'react-redux';
import { getTagsByTitle } from 'selectors';
import { StyledTag } from './styles';

class DescriptionTag extends React.Component {
  render() {
    const { children, tagsByTitle, activeTagId } = this.props;
    const tag = tagsByTitle[children.toLowerCase()] || {};
    return (
      <StyledTag isSelected={tag.id === activeTagId} tagId={tag.id}>
        {children}
      </StyledTag>
    );
  }
}
const mapStateToTagProps = (state) => ({
  tagsByTitle: getTagsByTitle(state),
  activeTagId: state.tags.filterTagId
});
export const ConnectedDescriptionTag =
  connect(mapStateToTagProps)(DescriptionTag);
