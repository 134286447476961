import { fork, takeLatest, takeEvery, all } from 'redux-saga/effects';

import * as constants from '../constants';
import {
  updateMemberBudgetOrderWorker,
  fetchSpentHoursByDayWorker,
  fetchPlannedHoursByDayWorker,
  fetchMemberBudgetTotalsWorker,
  fetchMemberBudgetsWorker,
  fetchMemberBudgetPhaseWorker,
  createMemberBudgetWorker,
  updateMemberBudgetWorker,
  assignMemberBudgetWorker,
  deleteMemberBudgetWorker,
  archiveMemberBudgetPhaseWorker,
  unarchiveMemberBudgetPhaseWorker,
  createPhaseEstimationWorker,
  updatePhaseEstimationWorker,
  deletePhaseEstimationWorker,
  fetchRatesWorker,
  createRateWorker,
  updateRateWorker,
  archiveRateWorker,
  unarchiveRateWorker,
  fetchMemberRatesWorker,
  createMemberRateWorker,
  updateMemberRateWorker,
  fetchTeamRolesWorker,
  createTeamRoleWorker,
  updateTeamRoleWorker,
  fetchProjectBudgetViewSettingsWorker,
  updateProjectBudgetViewSettingsWorker,
  fetchTeamBudgetViewSettingsWorker,
  updateTeamBudgetViewSettingsWorker,
  deleteMemberRateWorker,
  fetchTeamMembersRatesWorker,
  updateTeamMemberRateWorker,
  openBudgetModalWorker,
  closeBudgetModalWorker,
  bulkDeleteTimeAndRemoveActivityWorker,
  bulkMoveTimeAndRemoveActivityWorker,
  fetchAllTeamRatesWorker,
  fetchMemberTeamRatesWorker,
  createMemberTeamRateWorker,
  updateMemberTeamRateWorker,
  deleteMemberTeamRateWorker,
  fetchAllTeamSalariesWorker,
  fetchMemberSalariesWorker,
  createMemberSalaryWorker,
  updateMemberSalaryWorker,
  deleteMemberSalaryWorker,
  fetchOverheadWorker,
  createOverheadWorker,
  updateActivityPhaseWorker,
  hardDeleteActivityPhaseWorker,
  fetchProjectBillingCategoriesWorker,
  fetchProjectInvoicesWorker,
  createProjectInvoiceWorker,
  updateProjectInvoiceWorker,
  deleteProjectInvoiceWorker,
  fetchBudgetRecordsWorker,
  fetchProjectBudgetRecordWorker,
  fetchPositionsWorker,
  fetchPositionWorker,
  createPositionWorker,
  updatePositionWorker,
  deletePositionWorker,
  archivePositionWorker,
  fetchTeamPositionsWorker,
  createTeamPositionWorker,
  updateTeamPositionWorker,
  deleteTeamPositionWorker,
  fetchMemberPositionsWorker,
  createMemberPositionWorker,
  updateMemberPositionWorker,
  deleteMemberPositionWorker,
  fetchAccountPositionsWorker,
  createAccountPositionWorker,
  updateAccountPositionWorker,
  deleteAccountPositionWorker,
  fetchPositionRatesWorker,
  createPositionRateWorker,
  updatePositionRateWorker,
  deletePositionRateWorker,
  updatePositionSkillsWorker,
  createPositionSkillsWorker,
  fetchPositionSkillsWorker,
  deletePositionSkillsWorker,
  fetchPositionLocationRangeWorker,
  updatePositionLocationRangeWorker,
  deletePositionLocationRangeWorker
} from './budget';

export default function* budgetSaga() {
  const budgetSagas = [
    yield fork(takeEvery, constants.OPEN_BUDGET_MODAL, openBudgetModalWorker),
    yield fork(takeEvery, constants.CLOSE_BUDGET_MODAL, closeBudgetModalWorker),
    yield fork(
      takeEvery,
      constants.UPDATE_MEMBER_BUDGET_ORDER,
      updateMemberBudgetOrderWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_SPENT_HOURS_BY_DAY.TRIGGER,
      fetchSpentHoursByDayWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_PLANNED_HOURS_BY_DAY.TRIGGER,
      fetchPlannedHoursByDayWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_BUDGET_TOTALS.TRIGGER,
      fetchMemberBudgetTotalsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_BUDGETS.TRIGGER,
      fetchMemberBudgetsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_BUDGET_PHASE.TRIGGER,
      fetchMemberBudgetPhaseWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_MEMBER_BUDGET.TRIGGER,
      createMemberBudgetWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_MEMBER_BUDGET.TRIGGER,
      updateMemberBudgetWorker
    ),
    yield fork(
      takeEvery,
      constants.ASSIGN_MEMBER_BUDGET.TRIGGER,
      assignMemberBudgetWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_MEMBER_BUDGET.TRIGGER,
      deleteMemberBudgetWorker
    ),
    yield fork(
      takeEvery,
      constants.ARCHIVE_MEMBER_BUDGET_PHASE.TRIGGER,
      archiveMemberBudgetPhaseWorker
    ),
    yield fork(
      takeEvery,
      constants.UNARCHIVE_MEMBER_BUDGET_PHASE.TRIGGER,
      unarchiveMemberBudgetPhaseWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_PHASE_ESTIMATION.TRIGGER,
      createPhaseEstimationWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_PHASE_ESTIMATION.TRIGGER,
      updatePhaseEstimationWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_PHASE_ESTIMATION.TRIGGER,
      deletePhaseEstimationWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_ACTIVITY_PHASE.TRIGGER,
      updateActivityPhaseWorker
    ),
    yield fork(
      takeEvery,
      constants.HARD_DELETE_ACTIVITY_PHASE.TRIGGER,
      hardDeleteActivityPhaseWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_ALL_TEAM_RATES.TRIGGER,
      fetchAllTeamRatesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_TEAM_RATES.TRIGGER,
      fetchMemberTeamRatesWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_MEMBER_TEAM_RATE.TRIGGER,
      createMemberTeamRateWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_MEMBER_TEAM_RATE.TRIGGER,
      updateMemberTeamRateWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_MEMBER_TEAM_RATE.TRIGGER,
      deleteMemberTeamRateWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_MEMBERS_RATES.TRIGGER,
      fetchTeamMembersRatesWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_TEAM_MEMBER_RATE.TRIGGER,
      updateTeamMemberRateWorker
    ),
    yield fork(takeEvery, constants.FETCH_RATES.TRIGGER, fetchRatesWorker),
    yield fork(takeEvery, constants.CREATE_RATE.TRIGGER, createRateWorker),
    yield fork(takeEvery, constants.UPDATE_RATE.TRIGGER, updateRateWorker),
    yield fork(takeEvery, constants.ARCHIVE_RATE.TRIGGER, archiveRateWorker),
    yield fork(
      takeEvery,
      constants.UNARCHIVE_RATE.TRIGGER,
      unarchiveRateWorker
    ),
    // yield fork(takeEvery, constants.DELETE_RATE.TRIGGER, deleteRateWorker),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_RATES.TRIGGER,
      fetchMemberRatesWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_MEMBER_RATE.TRIGGER,
      createMemberRateWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_MEMBER_RATE.TRIGGER,
      updateMemberRateWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_MEMBER_RATE.TRIGGER,
      deleteMemberRateWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_ALL_TEAM_SALARIES.TRIGGER,
      fetchAllTeamSalariesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_SALARIES.TRIGGER,
      fetchMemberSalariesWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_MEMBER_SALARY.TRIGGER,
      createMemberSalaryWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_MEMBER_SALARY.TRIGGER,
      updateMemberSalaryWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_MEMBER_SALARY.TRIGGER,
      deleteMemberSalaryWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_OVERHEAD.TRIGGER,
      fetchOverheadWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_OVERHEAD.TRIGGER,
      createOverheadWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_ROLES.TRIGGER,
      fetchTeamRolesWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_TEAM_ROLE.TRIGGER,
      createTeamRoleWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_TEAM_ROLE.TRIGGER,
      updateTeamRoleWorker
    ),
    // yield fork(takeEvery, constants.DELETE_TEAM_ROLE.TRIGGER, deleteTeamRoleWorker),
    yield fork(
      takeEvery,
      constants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER,
      fetchProjectBudgetViewSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER,
      updateProjectBudgetViewSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.TRIGGER,
      fetchTeamBudgetViewSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.TRIGGER,
      updateTeamBudgetViewSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.BULK_MOVE_TIME_AND_REMOVE_ACTIVITY,
      bulkMoveTimeAndRemoveActivityWorker
    ),
    yield fork(
      takeEvery,
      constants.BULK_DELETE_TIME_AND_REMOVE_ACTIVITY,
      bulkDeleteTimeAndRemoveActivityWorker
    ),
    // Billing
    yield fork(
      takeEvery,
      constants.FETCH_PROJECT_BILLING_CATEGORIES.TRIGGER,
      fetchProjectBillingCategoriesWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_PROJECT_INVOICES.TRIGGER,
      fetchProjectInvoicesWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_PROJECT_INVOICE.TRIGGER,
      createProjectInvoiceWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_PROJECT_INVOICE.TRIGGER,
      updateProjectInvoiceWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_PROJECT_INVOICE.TRIGGER,
      deleteProjectInvoiceWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_BUDGET_RECORDS.TRIGGER,
      fetchBudgetRecordsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_PROJECT_BUDGET_RECORD.TRIGGER,
      fetchProjectBudgetRecordWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_POSITIONS.TRIGGER,
      fetchPositionsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_POSITION.TRIGGER,
      fetchPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_POSITION.TRIGGER,
      createPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_POSITION.TRIGGER,
      updatePositionWorker
    ),

    // yield fork(
    //   takeEvery,
    //   constants.DELETE_POSITION.TRIGGER,
    //   deletePositionWorker
    // ),
    yield fork(
      takeEvery,
      constants.ARCHIVE_POSITION.TRIGGER,
      archivePositionWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_POSITIONS.TRIGGER,
      fetchTeamPositionsWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_TEAM_POSITION.TRIGGER,
      createTeamPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_TEAM_POSITION.TRIGGER,
      updateTeamPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_TEAM_POSITION.TRIGGER,
      deleteTeamPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_MEMBER_POSITIONS.TRIGGER,
      fetchMemberPositionsWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_MEMBER_POSITION.TRIGGER,
      createMemberPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_MEMBER_POSITION.TRIGGER,
      updateMemberPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_MEMBER_POSITION.TRIGGER,
      deleteMemberPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_ACCOUNT_POSITIONS.TRIGGER,
      fetchAccountPositionsWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_ACCOUNT_POSITION.TRIGGER,
      createAccountPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_ACCOUNT_POSITION.TRIGGER,
      updateAccountPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_ACCOUNT_POSITION.TRIGGER,
      deleteAccountPositionWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_POSITION_RATES.TRIGGER,
      fetchPositionRatesWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_POSITION_RATE.TRIGGER,
      createPositionRateWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_POSITION_RATE.TRIGGER,
      updatePositionRateWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_POSITION_RATE.TRIGGER,
      deletePositionRateWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_POSITION_SKILLS.TRIGGER,
      updatePositionSkillsWorker
    ),
    yield fork(
      takeEvery,
      constants.CREATE_POSITION_SKILLS.TRIGGER,
      createPositionSkillsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_POSITION_SKILLS.TRIGGER,
      fetchPositionSkillsWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_POSITION_SKILLS.TRIGGER,
      deletePositionSkillsWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_POSITION_LOCATION_RANGE.TRIGGER,
      updatePositionLocationRangeWorker
    ),
    yield fork(
      takeEvery,
      constants.DELETE_POSITION_LOCATION_RANGE.TRIGGER,
      deletePositionLocationRangeWorker
    )
  ];
  yield all(budgetSagas);
}
