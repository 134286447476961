import { MouseEvent } from 'react';
import styled from 'styled-components';
import theme from 'theme';

interface SingleLineDependencyItemRowProps {
  dependableItemType: string;
  baseLabel: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SingleLineDependencyItemRow = ({
  dependableItemType,
  baseLabel,
  onClick
}: SingleLineDependencyItemRowProps) => {
  return (
    <SingleLineDependencyItem onClick={onClick}>
      {dependableItemType} {baseLabel}
    </SingleLineDependencyItem>
  );
};

const SingleLineDependencyItem = styled.button`
  width: fit-content;
  border: none;
  outline: none;
  color: ${theme.colors.colorCalendarBlue};
  padding: 0 6px;
  border-radius: 3px;
  background-color: ${theme.colors.colorPaleGray7};
  margin-top: 2px;
`;
