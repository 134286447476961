import React from 'react';
import PropTypes from 'prop-types';

const noop = () => {};

class FlyoutEdit extends React.Component {
  static defaultProps = {
    submitCallback: noop
  };

  submitCallback = () => {
    const { submitCallback } = this.props;
    submitCallback();
  };

  render() {
    const { addMode, editingItem, renderEdit, clearEditingId } = this.props;
    return (
      <div>
        {renderEdit({
          item: addMode ? null : editingItem,
          isNew: addMode,
          submitCallback: this.submitCallback,
          clearEditingId
        })}
      </div>
    );
  }
}

FlyoutEdit.propTypes = {
  submitCallBack: PropTypes.func.isRequired,
  renderEdit: PropTypes.func.isRequired,
  addMode: PropTypes.bool.isRequired,
  editingItem: PropTypes.object
};

export default FlyoutEdit;
