import {
  useSkills,
  useOffices,
  useDisciplines,
  useRegions
} from 'SettingsModule/hooks';

const useRequirementsHash = () => {
  const { skillHash } = useSkills();
  const { regionHash } = useRegions();
  const { officeHash } = useOffices();
  const { disciplineHash } = useDisciplines();

  return {
    skillsHash: skillHash,
    regionsHash: regionHash,
    officesHash: officeHash,
    disciplinesHash: disciplineHash
  };
};

export default useRequirementsHash;
