import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type RemoveWithCircleIconIconProps = Pick<
  SvgIconProps,
  'width' | 'height' | 'className' | 'viewBox' | 'stroke' | 'strokeWidth'
>;

const RemoveWithCircleIcon = ({
  className,
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  stroke = '#4F4F4F',
  strokeWidth = '0.914286'
}: RemoveWithCircleIconIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
  >
    <path
      d="M11.1666 4.83301L4.83325 11.1663M4.83325 4.83301L11.1666 11.1663"
      stroke={stroke}
    />
    <circle
      cx="8"
      cy="8"
      r="7.54286"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default RemoveWithCircleIcon;
