import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import { UsersState } from './types';

const emptyObj = {};

export const getUsersState: Selector<RootState, UsersState> = (state) =>
  state.users;

export const getMe = createSelector(getUsersState, (state) => state.me);

export const getMyUserId = createSelector(getMe, (me) => me?.id);

export const getPlannerMembersInfo = createSelector(
  getMe,
  (me) => me?.team_planner_members || emptyObj
);
