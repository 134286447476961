import { UseDependencyFormStateReturnType } from '../types';

// Placeholder components for now, will be updated in a future PR
export const SingleLineDependency = ({
  dependencyFormState
}: {
  dependencyFormState: UseDependencyFormStateReturnType;
}) => {
  return <div>SingleLineDependency</div>;
};
