import { useState, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import { Field, Label } from 'SettingsModule/components/styles';
import NumberFormat, {
  NumberFormatValues,
  SourceInfo
} from 'react-number-format';
import styled from 'styled-components';
import theme from 'theme';
import {
  fetchTeamBudgetViewSettings,
  updateTeamBudgetViewSettings
} from 'BudgetModule/actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getTeamBudgetSettingsHash } from 'BudgetModule/selectors';
import { useToggle } from 'react-use';
import { blurOnEnter } from 'appUtils/userInteractions';
import { handleErrorMessage } from 'actionCreators';
import { GENERIC_ACTION } from 'appConstants';

export const FullTimeEquivalentSetting = () => {
  const [fteValue, setFteValue] = useState<Nullable<number>>(null);
  const [showSuffix, toggleShowSuffix] = useToggle(true);
  const teamId = useAppSelector(getSelectedTeamId);
  const dispatch = useAppDispatch();
  const teamBudgetSettingsHash = useAppSelector(getTeamBudgetSettingsHash);
  const teamBudgetSettings = teamId && teamBudgetSettingsHash[teamId];
  const customInputRef = useRef(null);
  const isDirty = fteValue && fteValue !== teamBudgetSettings?.fte_week_hours;
  const isOverMaxValue = fteValue && fteValue > 168;

  const handleChangeFTE = (
    numberFormatValues: NumberFormatValues,
    sourceInfo: SourceInfo
  ) => {
    const floatValue = numberFormatValues.floatValue;

    if (floatValue && sourceInfo.source === 'event') {
      setFteValue(floatValue);
    }
  };

  const handleUpdateFTE = () => {
    if (isDirty && teamId) {
      dispatch(
        updateTeamBudgetViewSettings({
          body: {
            fte_week_hours: fteValue
          },
          teamId
        })
      );
    }
  };

  const handleBlur = () => {
    toggleShowSuffix();
    if (fteValue) {
      if (!isOverMaxValue) {
        handleUpdateFTE();
      } else {
        dispatch(
          handleErrorMessage({
            type: GENERIC_ACTION,
            isFeError: true,
            errorMessage: "FTE can't be over 168 hours per week"
          })
        );
        setFteValue(teamBudgetSettings.fte_week_hours);
      }
    }
  };

  useEffect(() => {
    if (teamBudgetSettings) {
      setFteValue(teamBudgetSettings.fte_week_hours);
    }
  }, [teamBudgetSettings]);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamBudgetViewSettings({ teamId }));
    }
  }, [teamId, dispatch]);

  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Mosaic uses a weekly full-time equivalent<br/>
            hours for understanding demand<br/>
            and hiring requirements."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Full-time Equivalent
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <HoursInputContainer>
          <HoursInput
            decimalScale={1}
            allowNegative={false}
            isNumericString
            value={fteValue}
            onFocus={toggleShowSuffix}
            onBlur={handleBlur}
            getInputRef={(ref) => (customInputRef.current = ref)}
            onKeyDown={blurOnEnter(customInputRef)}
            onValueChange={handleChangeFTE}
            suffix={showSuffix ? 'h' : undefined}
          />
          = 1 Week
        </HoursInputContainer>
      </Field>
    </>
  );
};

const HoursInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HoursInput = styled(NumberFormat)`
  width: 53px;
  height: 30px;
  border: 1px solid ${theme.colors.colorLightGray12};
  margin-right: 8px;
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }
  text-align: center;
`;
