'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _createReactClass = require('create-react-class');

var _createReactClass2 = _interopRequireDefault(_createReactClass);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var DateInput = (0, _createReactClass2['default'])({
  displayName: 'DateInput',

  propTypes: {
    prefixCls: _propTypes2['default'].string,
    timePicker: _propTypes2['default'].object,
    value: _propTypes2['default'].object,
    disabledTime: _propTypes2['default'].any,
    format: _propTypes2['default'].string,
    locale: _propTypes2['default'].object,
    disabledDate: _propTypes2['default'].func,
    onChange: _propTypes2['default'].func,
    onClear: _propTypes2['default'].func,
    placeholder: _propTypes2['default'].string,
    onSelect: _propTypes2['default'].func,
    selectedValue: _propTypes2['default'].object
  },

  getInitialState: function getInitialState() {
    var selectedValue = this.props.selectedValue;
    return {
      str: (selectedValue && selectedValue.format(this.props.format)) || '',
      invalid: false
    };
  },
  componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
    this.cachedSelectionStart = this.dateInputInstance.selectionStart;
    this.cachedSelectionEnd = this.dateInputInstance.selectionEnd;
    // when popup show, click body will call this, bug!
    var selectedValue = nextProps.selectedValue;
    this.setState({
      str: (selectedValue && selectedValue.format(nextProps.format)) || '',
      invalid: false
    });
  },
  componentDidUpdate: function componentDidUpdate() {
    if (!this.state.invalid) {
      this.dateInputInstance.setSelectionRange(
        this.cachedSelectionStart,
        this.cachedSelectionEnd
      );
    }
  },
  onInputChange: function onInputChange(event) {
    var str = event.target.value;
    this.setState({
      str: str
    });
    var value = void 0;
    var _props = this.props,
      disabledDate = _props.disabledDate,
      format = _props.format,
      onChange = _props.onChange;

    if (str) {
      var parsed = (0, _moment2['default'])(str, format, true);
      if (!parsed.isValid()) {
        this.setState({
          invalid: true
        });
        return;
      }
      value = this.props.value.clone();
      value
        .year(parsed.year())
        .month(parsed.month())
        .date(parsed.date())
        .hour(parsed.hour())
        .minute(parsed.minute())
        .second(parsed.second());

      if (value && (!disabledDate || !disabledDate(value))) {
        var originalValue = this.props.selectedValue;
        if (originalValue && value) {
          if (!originalValue.isSame(value)) {
            onChange(value);
          }
        } else if (originalValue !== value) {
          onChange(value);
        }
      } else {
        this.setState({
          invalid: true
        });
        return;
      }
    } else {
      onChange(null);
    }
    this.setState({
      invalid: false
    });
  },
  onClear: function onClear() {
    this.setState({
      str: ''
    });
    this.props.onClear(null);
  },
  getRootDOMNode: function getRootDOMNode() {
    return _reactDom2['default'].findDOMNode(this);
  },
  focus: function focus() {
    if (this.dateInputInstance) {
      this.dateInputInstance.focus();
    }
  },
  saveDateInput: function saveDateInput(dateInput) {
    this.dateInputInstance = dateInput;
  },
  render: function render() {
    var props = this.props;
    var _state = this.state,
      invalid = _state.invalid,
      str = _state.str;
    var locale = props.locale,
      prefixCls = props.prefixCls,
      placeholder = props.placeholder;

    var invalidClass = invalid ? prefixCls + '-input-invalid' : '';
    return _react2['default'].createElement(
      'div',
      { className: prefixCls + '-input-wrap' },
      _react2['default'].createElement(
        'div',
        { className: prefixCls + '-date-input-wrap' },
        _react2['default'].createElement('input', {
          ref: this.saveDateInput,
          className: prefixCls + '-input ' + invalidClass,
          value: str,
          disabled: props.disabled,
          placeholder: placeholder,
          onChange: this.onInputChange
        })
      ),
      props.showClear
        ? _react2['default'].createElement('a', {
            className: prefixCls + '-clear-btn',
            role: 'button',
            title: locale.clear,
            onClick: this.onClear
          })
        : null
    );
  }
});

exports['default'] = DateInput;
module.exports = exports['default'];
