import React, { useEffect } from 'react';

import { fetchTeams } from 'actionCreators';
import { getRegularTeamMembers } from 'selectors';
import { MemberCardsSectionWrapper } from './styles';
import TeamMembersSection from './TeamMembersSection';
import {
  getGuests,
  getInternalContractors,
  getExternalProjectContractors
} from 'PermissionsModule/selectors';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const TeamMembersContainer = () => {
  const dispatch = useAppDispatch();
  const regularTeamMembers = useAppSelector(getRegularTeamMembers);
  const guests = useAppSelector(getGuests);
  const internalContractors = useAppSelector(getInternalContractors);
  const externalProjectContractors = useAppSelector(
    getExternalProjectContractors
  );

  const { newPermissionSettings } = useFeatureFlags();

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  return (
    <MemberCardsSectionWrapper>
      <TeamMembersSection teamMembers={regularTeamMembers} />
      {newPermissionSettings && (
        <>
          <TeamMembersSection
            teamMembers={internalContractors}
            header="Contractor - Internal"
          />
          <TeamMembersSection
            teamMembers={externalProjectContractors}
            header="Project Contractor - External"
          />
        </>
      )}
      <TeamMembersSection teamMembers={guests} header="Project Guest" />
    </MemberCardsSectionWrapper>
  );
};

export default TeamMembersContainer;
