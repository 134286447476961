import styled from 'styled-components';
import { teamUtils } from 'TeamsModule/utils';
import theme from 'theme';

export const EditableEmailCell = ({ row }) => {
  const { member, onRequestEditMemberMetadata } = row.original;
  return (
    <BaseCellRootContainer
      className="shared-table-cell"
      onClick={() =>
        onRequestEditMemberMetadata({ teamMembershipId: member.id })
      }
    >
      <ContentContainer>
        <TextContainer
          $isArchived={teamUtils.getIsArchived(member)}
          className="text-container"
        >
          {member.account.email}
        </TextContainer>
      </ContentContainer>
    </BaseCellRootContainer>
  );
};

const TextContainer = styled.div<{ $isArchived: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const BaseCellRootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  color: ${theme.colors.colorSemiDarkGray1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  &:hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;
