import React from 'react';
import { connect } from 'react-redux';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { isUserTeamAdmin } from 'appUtils';
import buildAdminNamesString from 'SettingsModule/utils/buildTeamAdminsString';
import {
  fetchTeamMembers,
  clearResendInvite,
  clearErrorMessage
} from 'actionCreators';
import { getAuth, getUsers } from 'selectors';

import MemberManagementTable from './MemberManagementTable/MemberManagementTable';

class MemberManagementTab extends React.Component {
  state = {
    resetInviteForm: false
  };

  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  componentWillUnmount() {
    this.setState({
      resetInviteForm: false
    });
    this.props.clearResendInvite();
    this.props.clearErrorMessage();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.team || !this.props.team.team_members) {
      return;
    }
    const receivingTeamMembers =
      this.props.team.team_members.length !==
      nextProps.team.team_members.length;
    if (receivingTeamMembers) {
      this.setState({
        resetInviteForm: true
      });
    }
  }

  render() {
    const { team, users } = this.props;

    if (!users.me || !team) {
      return '';
    }

    const userIsAdmin = isUserTeamAdmin(users.me.id, team);
    const adminNames = buildAdminNamesString(team);

    return (
      <DynamicModuleLoader modules={[getBudgetModule()]}>
        <div className="team-settings-modal" style={{ overflow: 'hidden' }}>
          <div className="team-members-tab">
            {!userIsAdmin ? (
              <div className="not-admin-message">
                Contact the admin(s), {adminNames} for any required account
                changes. When a team member gets assigned as Admin (from Member
                tab), he/she will be able to see the Members tab.
              </div>
            ) : (
              <div>
                <div className="team-members-container">
                  <MemberManagementTable />
                </div>
              </div>
            )}
          </div>
        </div>
      </DynamicModuleLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  users: getUsers(state),
  team: state.teams.allTeams[0],
  invite: state.invite,
  teams: state.teams,
  errorMessage: state.groups.errorMessage
});

const mapDispatchToProps = {
  fetchTeamMembers,
  clearResendInvite,
  clearErrorMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberManagementTab);
