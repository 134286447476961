import React from 'react';
import cn from 'classnames';

const FollowedBell = ({
  isOnProjectDetail,
  currentlyFollowed,
  onFollowClick,
  shouldHideFollowedBell
}) => {
  return (
    <div
      className={cn(
        'followed-icon',
        {
          'force-invisible': shouldHideFollowedBell
        },
        {
          'show-followed': isOnProjectDetail && currentlyFollowed
        }
      )}
      onClick={onFollowClick}
      title={
        currentlyFollowed
          ? 'Click to unfollow this task'
          : 'Click to follow this task'
      }
    />
  );
};

export default FollowedBell;
