import React, { Component } from 'react';
import TaskAssigneeDropdown from 'components/TaskAssigneeDropdown';
import ProjectSelectionPopover from 'components/ProjectSelectionPopover';
import TextArea from 'components/TextArea';
import TaskCircle from './TaskCircle';

import {
  TaskContentLeft,
  StyledTaskContentMiddle,
  TaskDescriptionAndProjectContainer
} from './styles';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setEditTaskId, triggerTasksAttributesUpdate } from 'actionCreators';
import {
  getAuthToken,
  getSearchText,
  getSelectedTeamId,
  getMatchedRouteParams,
  getIsOnProjectView,
  getOnHomeView
} from 'selectors';

const popupAlign = {
  points: ['tl', 'bl'],
  offset: [0, 10]
};
class TaskContentUpperMiddle extends Component {
  getPermissions = () => {
    const {
      task: { project_id },
      projectId,
      selectedTeamId
    } = this.props;
    const permissions = {
      projectId: project_id || projectId,
      teamId: selectedTeamId
    };
    return permissions;
  };

  handleMultiAssignDone = ({
    assigneeIds = [],
    unassigneeIds = [],
    assignProjectMembershipIds = [],
    unassignProjectMembershipIds = []
  }) => {
    const {
      token,
      handleTasksAttributesUpdate,
      task: { id },
      setEditTaskId
    } = this.props;

    const body = {
      task_ids: [id],
      assignee_ids: assigneeIds,
      assign_project_membership_ids: assignProjectMembershipIds,
      unassignee_ids: unassigneeIds,
      unassign_project_membership_ids: unassignProjectMembershipIds
    };

    const permissions = this.getPermissions();
    handleTasksAttributesUpdate({ token, body, permissions });
    setEditTaskId(null);
  };

  render() {
    const {
      markTaskCompletion,
      isComplete,
      taskIsEditing,
      isOnTeamProject,
      task,
      taskId,
      searchText,
      handleProjectMenuSelection,
      renderProjectPopoverMenuItem,
      handleDescriptionTextAreaEvent,
      handleDescriptionSubmit,
      currentDescriptionText,
      handleContentClick,
      isSelectedHomeTask,
      beingEdited,
      isTaskEditProperty,
      isOnProjectView,
      handleBlur,
      setEditingHeight,
      index,
      isOnHomeView,
      testIdPrefix
    } = this.props;

    const showProjectSelector =
      (!isOnProjectView && task.project_id) ||
      taskIsEditing ||
      isSelectedHomeTask;
    const isDefaultPersonalProject =
      task?.project?.is_default && task?.project?.is_personal;

    return (
      <StyledTaskContentMiddle
        isSelectedHomeTask={isSelectedHomeTask}
        taskIsEditing={taskIsEditing}
        isOnTeamProject={isOnTeamProject}
        isTaskEditProperty={isTaskEditProperty}
        beingEdited={beingEdited}
      >
        <div className="content-middle-container">
          <TaskContentLeft>
            {isSelectedHomeTask && (
              <>
                <TaskCircle
                  isSelectedHomeTask={isSelectedHomeTask}
                  markComplete={markTaskCompletion}
                  isCompleted={isComplete}
                  isEditing={taskIsEditing}
                  isOnTeamProject={isOnTeamProject}
                />

                <TaskAssigneeDropdown
                  task={task}
                  projectId={task?.project_id}
                  onMultiAssignDone={this.handleMultiAssignDone}
                  isSelectedHomeTask={isSelectedHomeTask}
                />
              </>
            )}
          </TaskContentLeft>
          <TaskDescriptionAndProjectContainer
            isSelectedHomeTask={isSelectedHomeTask}
            ref={(ref) => (this.containerRef = ref)}
            isProjectDetail={isOnProjectView}
            isTaskEditProperty={isTaskEditProperty}
            beingEdited={beingEdited}
            taskIsEditing={taskIsEditing}
            isOnTeamProject={isOnTeamProject}
            isTaskModal={isSelectedHomeTask}
            isDefaultPersonalProject={isDefaultPersonalProject}
            isOnHomeView={isOnHomeView}
          >
            <TextArea
              testIdPrefix={testIdPrefix}
              taskId={taskId}
              searchText={searchText}
              currentDescriptionText={currentDescriptionText || ''}
              onChange={handleDescriptionTextAreaEvent}
              onSubmit={handleDescriptionSubmit}
              taskIsEditing={taskIsEditing}
              noteText={task.note || ''}
              numTaskComments={
                task.task_comments?.length || task.task_comment_ids?.length
              }
              taskGroupId={task.task_group_id}
              handleContentClick={handleContentClick}
              isSelectedHomeTask={isSelectedHomeTask}
              isTaskModal={isSelectedHomeTask}
              handleBlur={handleBlur}
              setEditingHeight={setEditingHeight}
              index={index}
            />
            <ProjectSelectionPopover
              target={this.containerRef}
              disabled={!showProjectSelector}
              task={task}
              onSelect={handleProjectMenuSelection}
              header={null}
              headerText={'Select Project'}
              showDeselect={false}
              popupAlign={popupAlign}
              isSelectedHomeTask={isSelectedHomeTask}
              taskIsEditing={taskIsEditing}
              testIdPrefix={testIdPrefix}
            >
              {renderProjectPopoverMenuItem}
            </ProjectSelectionPopover>
          </TaskDescriptionAndProjectContainer>
        </div>
      </StyledTaskContentMiddle>
    );
  }
}

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  searchText: getSearchText(state),
  selectedTeamId: getSelectedTeamId(state),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  isOnHomeView: getOnHomeView(state)
});
const mapDispatchToProps = {
  setEditTaskId,
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskContentUpperMiddle)
);
