import { SimpleConfirmModal } from 'components/Modals';
import { NewDateParams } from './types';

export const DateChangeOvertakeConfirmationModal = ({
  newDateParams,
  onClose,
  onConfirm
}: {
  newDateParams: NewDateParams;
  onClose: () => void;
  onConfirm: (params: NewDateParams) => void;
}) => (
  <SimpleConfirmModal
    body="Updating this date will overwrite and delete other rates. Do you want to change this date?"
    confirmLabel="Change"
    header="Change Date"
    isOpen
    onConfirm={() => onConfirm(newDateParams)}
    shouldSetIsClosingOnClose={false}
    toggle={onClose}
  />
);
