import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getHomeTasksSortState, getIsOnProjectView } from 'selectors';
import { updateTaskSortOrder } from 'actionCreators';
import {
  StyledReportHeader,
  StyledSortIcon,
  StyledSortIconContainer,
  StyledHeaderLabel
} from 'ReportsModule/components/styles';
import { SORT_ORDER, OPPOSITE_SORT_ORDER } from 'appConstants/filters';
import TaskColumnsDropdown from 'views/Home/TaskList/TaskColumnsDropdown';
import { StyledTaskHeader } from '../HeaderCells/styles';

const disabledHeaders = {};

// set Timesheetstable/Cells/HeaderCell for add functionality pattern if/when added here.

const rowTypesToOriginTypes = {
  taskGroupRow: 'taskGroup',
  phaseRow: 'phase',
  activityPhaseRow: 'activityPhase'
};

const HeaderCell = ({ column, row, rows }) => {
  const { headerLabel, headerType, align, index } = column;

  const rowToUse =
    row?.original ||
    rows?.find(
      (row) =>
        row.original &&
        row.original?.list?.id === row.original?.customRowProps?.activeSection
    )?.original;
  const dispatch = useDispatch();
  const { value, order } = useSelector(getHomeTasksSortState);

  const isActive = value === headerType;
  const isDisabled = disabledHeaders[headerType] || !rowToUse?.list.isOpen;
  const isHideOwnColor =
    useSelector(getIsOnProjectView) && !rowToUse?.list.isOpen;

  const showAsActive = isActive && !isDisabled;

  const setSortState = () => {
    if (isDisabled) {
      return;
    }
    const newOrder = isActive ? OPPOSITE_SORT_ORDER[order] : SORT_ORDER.desc;

    const newValue = order === SORT_ORDER.asc && isActive ? null : headerType;

    dispatch(updateTaskSortOrder({ sort: newValue, direction: newOrder }));
  };

  if (!headerLabel) {
    return (
      <StyledTaskHeader
        isDisabled
        originType={rowTypesToOriginTypes[rowToUse?.rowType] || 'taskGroup'}
        taskGroupId={rowToUse?.list.id}
        color="#ccc"
        className="styled-report-header"
      />
    );
  }
  if (row?.original && !row.original.isOpen) {
    return (
      <StyledTaskHeader
        align={align}
        onClick={setSortState}
        isActive={showAsActive}
        isDisabled={isDisabled}
        originType={rowTypesToOriginTypes[rowToUse?.rowType] || 'taskGroup'}
        taskGroupId={rowToUse?.list.id}
        color="#ccc"
        className="styled-report-header"
        isHideOwnColor={isHideOwnColor}
      >
        {/* <StyledHeaderLabel>
      {headerLabel}

      <StyledSortIconContainer
        isActive={showAsActive}
        order={order}
        isDisabled={isDisabled}
      >
        <StyledSortIcon />
      </StyledSortIconContainer>
    </StyledHeaderLabel> */}
      </StyledTaskHeader>
    );
  }

  return (
    <StyledTaskHeader
      align={align}
      onClick={setSortState}
      isActive={showAsActive}
      isDisabled={isDisabled}
      originType={rowTypesToOriginTypes[rowToUse?.rowType] || 'taskGroup'}
      isHideOwnColor={isHideOwnColor}
      taskGroupId={rowToUse?.list.id}
      color="#ccc"
      className="styled-report-header"
    >
      <StyledHeaderLabel>
        {headerLabel}

        <StyledSortIconContainer
          isActive={showAsActive}
          order={order}
          isDisabled={isDisabled}
        >
          <StyledSortIcon className="styled-sort-icon" />
        </StyledSortIconContainer>
      </StyledHeaderLabel>
    </StyledTaskHeader>
  );
};

export default HeaderCell;

export const TimerHeaderCell = ({ column, row, rows }) => {
  const isOnProjectView = useSelector(getIsOnProjectView);

  return isOnProjectView ? <TaskColumnsDropdown show={true} /> : null;
};

export const BorderBottomCell = ({ column, row, rows }) => {
  const { headerLabel, headerType, align, index } = column;

  const rowToUse =
    row?.original ||
    rows?.find(
      (row) =>
        row.original &&
        row.original?.list?.id === row.original?.customRowProps?.activeSection
    )?.original;

  const isHideOwnColor =
    useSelector(getIsOnProjectView) && !rowToUse?.list.isOpen;

  return (
    <StyledTaskHeader
      isDisabled
      style={{ height: '100%' }}
      originType={rowTypesToOriginTypes[rowToUse?.rowType] || 'taskGroup'}
      taskGroupId={rowToUse?.list.id}
      color="#ccc"
      isHideOwnColor={isHideOwnColor}
    />
  );
};

export const EmptyHeaderCell = ({ children }) => (
  <StyledReportHeader
    style={{ pointerEvents: 'none' }}
    className="styled-report-header"
  >
    <StyledHeaderLabel style={{ visibility: 'hidden' }}>.</StyledHeaderLabel>
    {children}
  </StyledReportHeader>
);

export const HeaderIfOpenCell = (props) => {
  if (props.row.original.isOpen) {
    return null;
  }
  return <EmptyHeaderCell {...props} />;
};
