import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import withRouterParams from './WithRouterParams';
import { getMatchedRouteParams, getSelectedTeamId } from 'selectors';

import BudgetModalNavigationTracker from 'views/Navigation/BudgetModalNavigationTracker';
import BudgetModal from 'BudgetModule/components/BudgetModal/BudgetModal';
import BudgetModalViewsRouter from './BudgetModalViewsRouter';
import ScopeModalRouter from './ScopeModalRouter';
import TaskModalRouter from './TaskModalRouter';
import MemberModalRouter from './MemberModalRouter';

class BudgetModalRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match, location } = this.props;
    const {
      auth: nextAuth,
      match: nextMatch,
      location: nextLocation
    } = nextProps;
    const shouldUpdate =
      auth !== nextAuth ||
      match.url !== nextMatch.url ||
      location.pathname !== nextLocation.pathname;
    return shouldUpdate;
  }

  render() {
    const { match, teamId } = this.props;
    if (!match.params.budgetViewType) {
      return null;
    }
    return (
      <>
        <BudgetModalNavigationTracker />
        <BudgetModal isOpen projectId={match.params.projectId}>
          <Route
            path={`${match.url}/scope/:scopeId`}
            component={ScopeModalRouter}
          />
          <Route
            path={`${match.url}/task/:taskId`}
            component={TaskModalRouter}
          />{' '}
          <Route
            path={[`${match.url}/:budgetSecondaryViewType`, `${match.url}`]}
            component={BudgetModalViewsRouter}
          />
          <Route
            path={`${match.url}/member/:memberId/view/:memberViewType`}
            component={MemberModalRouter}
          />
        </BudgetModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  teamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetModalRouter)),
  true
);
