import { createSelector } from 'reselect';
import { makeIdHash, getLength } from 'appUtils';
import { initialState as budgetInitialState } from '../reducers/budget';
import { initialState as memberBudgetsInitialState } from '../reducers/memberBudgets';
import { initialState as ratesInitialState } from '../reducers/rates';
import { initialState as memberRatesInitialState } from '../reducers/memberRates';
import { initialState as teamRolesInitialState } from '../reducers/teamRoles';
import { initialState as hoursInitialState } from '../reducers/hours';
import { initialState as salariesInitialState } from '../reducers/salaries';
import { getQBBudgetState } from 'QuickbooksModule/selectors';
import keyBy from 'lodash/keyBy';
import { formatTotals, isPhaseInactive } from 'appUtils/phaseDisplayUtils';
import * as constants from 'appConstants';

import {
  getMemberProjectsHash,
  getSelectedTeamRateOrder,
  getSelectedTeamRoleOrder,
  getSelectedAccountIds,
  getTeamMembershipsByAccountId,
  getAllActivityRowInfo,
  makeGetDescriptionFromId,
  getSelectedProject,
  getPlannedPhasesHash,
  getPhases,
  getPhaseIds,
  getPhaseTotals,
  getAllProjectTotals,
  getBudgetReportProjectTotals,
  getPhasesByProjectHash,
  getPhaseOrder,
  getIsLoadingPhases,
  getPhaseTemplates,
  getOrderedPhaseTemplates,
  getIsLoadingPhaseTemplates,
  getPhasesOrder,
  getFlatPhasesHash,
  getMatchedRouteParams,
  getMilestonesByProjectHash,
  makeGetActiveWorkloadPlannerFilter,
  sortPhasesByPhaseOrder
} from 'selectors';

import moment from 'moment';
import partition from 'lodash/partition';
import { getIsUnassignedMemberBudget } from 'appUtils/budgetUtils';
import { getPositions } from './positions';

import { SORT_BY } from 'appConstants/filters';
import { endOfDay, isWithinInterval, parseISO } from 'date-fns';

const emptyObj = {};
const emptyArray = [];
const keyById = (item) => item && item.id;

export const makeGetFilteredPositionIds = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (filter) => filter.position_ids || emptyArray
  );

/**
 * @deprecated — Use the typesafe version from BudgetsModule/selectors.
 */
export const getBudgetState = (state) => state.budget || budgetInitialState;

export const getMemberBudgetsState = (state) =>
  state.memberBudgets || memberBudgetsInitialState;
export const getRatesState = (state) => state.rates || ratesInitialState;
export const getTeamRolesState = (state) =>
  state.teamRoles || teamRolesInitialState;
export const getMemberRatesState = (state) =>
  state.memberRates || memberRatesInitialState;
export const getHoursState = (state) => state.hours || hoursInitialState;
export const getSalariesState = (state) =>
  state.salaries || salariesInitialState;

/**
 * @deprecated — Use the typesafe version from BudgetsModule/selectors.
 */
const getOwnProjectId = (state, ownProps) => ownProps?.projectId;

export const getBudgetModalOpen = createSelector(
  getBudgetState,
  (state) => state.modalOpen
);

export const getRemoveMemberModalOpen = createSelector(
  getBudgetState,
  (state) => state.removeMemberModalOpen
);
export const getRemoveMemberModalMemberBudget = createSelector(
  getBudgetState,
  (state) => state.removeMemberModalMemberBudget
);
export const getArchiveMemberModalOpen = createSelector(
  getBudgetState,
  (state) => state.archiveMemberModalOpen
);
export const getArchiveMemberModalMemberBudget = createSelector(
  getBudgetState,
  (state) => state.archiveMemberModalMemberBudget
);
export const getArchiveMemberModalActivity = createSelector(
  getBudgetState,
  (state) => state.archiveMemberModalActivity
);
export const getArchiveMemberModalActivityMembership = createSelector(
  getBudgetState,
  (state) => state.archiveMemberModalActivityMembership
);

export const getProjectPhaseDetailId = createSelector(
  getBudgetState,
  (state) => state.projectPhaseDetailsId
);
export const makeGetProjectPhaseDetailsOpen = () =>
  createSelector(
    getOwnProjectId,
    getProjectPhaseDetailId,
    (ownId, projectPhaseDetailId) => ownId === projectPhaseDetailId
  );

export const getIsNewBudget = createSelector(
  getBudgetState,
  (state) => state.newBudget
);

// export const getBudgetModalMemberId = createSelector(
//   getBudgetState,
//   state => state.modalMemberId
// );

/**
 * @deprecated — Use the typesafe version from BudgetsModule/selectors.
 */
export const getBudgetModalProjectId = createSelector(
  getBudgetState,
  getOwnProjectId,
  getMatchedRouteParams,
  (state, ownProjectId, matchedParams) =>
    ownProjectId || state.modalProjectId || matchedParams.projectId
);

export const getActivityDropdownOpen = createSelector(
  getBudgetState,
  (state) => state.activityDropdownOpen
);

export const getActivityModalOpen = createSelector(
  getBudgetState,
  getQBBudgetState,
  (budgetState, qbBudgetState) => {
    if (qbBudgetState && qbBudgetState.hasOwnProperty('activityModalOpen')) {
      return qbBudgetState.activityModalOpen;
    }
    return budgetState.activityModalOpen;
  }
);
export const getActivityModalCustom = createSelector(
  getBudgetState,
  (state) => state.isCustomActivityModal
);
export const getTobeAddedActivityId = createSelector(
  getQBBudgetState,
  (qbBudgetState) => qbBudgetState.toBeAddedActivityId
);

export const getActivityModalPreFillText = createSelector(
  getQBBudgetState,
  (qbBudgetState) => qbBudgetState.preFillText
);

export const getActivityModalPreFillBillable = createSelector(
  getBudgetState,
  (budgetState) => budgetState.preFillBillable
);

export const getActivityModalId = createSelector(
  getBudgetState,
  (state) => state.modalActivityId
);

export const makeGetUnformattedBudgetTotals = () =>
  createSelector(
    getAllProjectTotals,
    getOwnProjectId,
    (projectTotals, projectId) => projectTotals[projectId] || emptyObj
  );
export const makeGetBudgetTotals = () =>
  createSelector(makeGetUnformattedBudgetTotals(), (projectTotals) => {
    if (!projectTotals || projectTotals === emptyObj) {
      return emptyObj;
    }

    const { project_id } = projectTotals;

    const projectNumbers = { ...formatTotals(projectTotals, project_id) };

    return projectNumbers;
  });

const projectHasBudgetKeys = [
  'spent_cost',
  'spent_hours',
  'estimated_hours',
  'total'
];

export const projectHasBudget = (project) =>
  project && projectHasBudgetKeys.some((key) => project[key] != 0);

const getOwnProjectIds = (state, ownProps) => ownProps.projectIds;

export const getBudgetedProjectIds =
  createSelector(
    getOwnProjectIds,
    getBudgetReportProjectTotals,
    (projectIds, projectTotals) =>
      projectIds?.filter((id) => projectHasBudget(projectTotals[id]))
  ) || emptyArray;

export const getNoBudgetProjectIds =
  createSelector(
    getOwnProjectIds,
    getBudgetReportProjectTotals,
    (projectIds, projectTotals) =>
      projectIds?.filter((id) => !projectHasBudget(projectTotals[id]))
  ) || emptyArray;

const makeGetOwnPhaseId = () => (state, ownProps) => ownProps.phaseId;

export const getBudgetModalProjectTotal = createSelector(
  getPhaseOrder,
  getPhases,
  (phaseOrder, phases) =>
    phaseOrder.reduce(
      (acc, cur) => acc + (phases[cur] ? phases[cur].total : 0),
      0
    )
);
export const getShouldShowProjectBudgetTotal = createSelector(
  getPhaseOrder,
  getPhases,
  (phaseOrder, phases) =>
    phaseOrder.reduce(
      (numberOfPhasesWithTotal, phaseId) =>
        numberOfPhasesWithTotal +
        (phases[phaseId] && phases[phaseId].total > 0 ? 1 : 0),
      0
    ) > 0
);

const getIsAllPhases = (state, ownProps) => ownProps.isAllPhases;

export const makeGetAllPhasesForProject = () =>
  createSelector(
    getOwnProjectId,
    getPhasesByProjectHash,
    (projectId, phasesByProject) =>
      phasesByProject[projectId]?.phases ?? emptyArray
  );

export const makeGetAllMilestonesForProject = () =>
  createSelector(
    getOwnProjectId,
    getMilestonesByProjectHash,
    (projectId, milestonesByProject) =>
      milestonesByProject[projectId]?.phases ?? emptyArray
  );

export const makeGetNonBudgetPhases = () =>
  createSelector(makeGetAllMilestonesForProject(), (phases) =>
    phases.filter((phase) => {
      return !phase.is_budget;
    })
  );
export const makeGetSelectedPhases = () =>
  createSelector(
    makeGetAllPhasesForProject(),
    getIsAllPhases,
    (phases, isAllPhases) => {
      return isAllPhases ? phases : phases.filter((phase) => phase.is_budget);
    }
  );

export const makeGetSelectedMilestones = () =>
  createSelector(makeGetAllMilestonesForProject(), (phases) => {
    return phases.filter((phase) => !phase.is_budget);
  });

export const makeGetSelectedPhasesOrder = () =>
  createSelector(
    getOwnProjectId,
    getPhasesByProjectHash,
    (projectId, phasesByProject) =>
      (phasesByProject[projectId] && phasesByProject[projectId].phase_orders) ||
      emptyArray
  );

export const makeGetSelectedPhasesByPhaseId = () =>
  createSelector(makeGetSelectedPhases(), (projectPhases) =>
    keyBy(projectPhases, keyById)
  );

export const makeGetArchivedPhases = () =>
  createSelector(makeGetSelectedPhases(), (projectPhases) =>
    projectPhases.filter((phase) => phase.archived)
  );

export const makeGetArchivedMilestones = () =>
  createSelector(makeGetSelectedMilestones(), (projectMilestones) =>
    projectMilestones.filter((phase) => phase.archived)
  );

export const makeGetArchivedPhasesByPhaseId = () =>
  createSelector(makeGetArchivedPhases(), (projectPhases) =>
    keyBy(projectPhases, keyById)
  );

export const makeGetActivePhases = () =>
  createSelector(makeGetSelectedPhases(), (projectPhases) =>
    projectPhases.filter((phase) => !phase.archived)
  );

export const makeGetActiveMilestones = () =>
  createSelector(makeGetAllMilestonesForProject(), (projectMilestones) =>
    projectMilestones.filter((milestone) => !milestone.archived)
  );

export const makeGetInactivePhases = () =>
  createSelector(makeGetSelectedPhases(), (projectPhases) =>
    projectPhases.filter(isPhaseInactive)
  );

export const makeGetActiveBudgetPhases = () =>
  createSelector(
    makeGetActivePhases(),
    makeGetSelectedPhasesOrder(),
    (activePhases, phasesOrder) => {
      const activePhasesById = keyBy(activePhases, keyById);
      return phasesOrder
        .filter((id) => activePhasesById[id])
        .map((id) => activePhasesById[id]);
    }
  );

export const makeGetActiveNonBudgetPhases = () =>
  createSelector(
    makeGetActiveMilestones(),
    makeGetSelectedPhasesOrder(),
    (activeMilestones, phasesOrder) => {
      const activeMilestonesById = keyBy(activeMilestones, keyById);
      return phasesOrder
        .filter((id) => activeMilestonesById[id])
        .map((id) => activeMilestonesById[id]);
    }
  );

export const makeGetActivePhaseIds = () =>
  createSelector(makeGetActivePhases(), (phases) =>
    phases.map((phase) => phase.id)
  );

const mapToPhaseTemplateId = (phases) =>
  phases.map((phase) => phase.phase_template_id);

export const makeGetArchivedPhaseIds = () =>
  createSelector(makeGetArchivedPhases(), (phases) =>
    phases.map((phase) => phase.id)
  );
export const makeGetSelectedPhaseTemplateIdsArray = () =>
  createSelector(makeGetSelectedPhases(), mapToPhaseTemplateId);
export const makeGetActivePhaseTemplateIdsArray = () =>
  createSelector(makeGetActivePhases(), mapToPhaseTemplateId);
export const makeGetArchivedPhaseTemplateIdsArray = () =>
  createSelector(makeGetArchivedPhases(), mapToPhaseTemplateId);

const createPhaseTemplateIdToPhaseIdHash = (phases) =>
  phases.reduce((acc, cur) => {
    acc[cur.phase_template_id] = cur.id;
    return acc;
  }, {});

export const makeGetSelectedPhaseTemplateIds = () =>
  createSelector(makeGetSelectedPhases(), createPhaseTemplateIdToPhaseIdHash);
export const makeGetActivePhaseTemplateIds = () =>
  createSelector(makeGetActivePhases(), createPhaseTemplateIdToPhaseIdHash);
export const makeGetArchivedPhaseTemplateIds = () =>
  createSelector(makeGetArchivedPhases(), createPhaseTemplateIdToPhaseIdHash);
export const makeGetProjectOnlyHasDefaultPhase = () =>
  createSelector(makeGetActiveBudgetPhases(), (phases) => {
    if (!phases || !phases.length) {
      return false;
    }
    if (phases && phases.length > 1) {
      return false;
    }
    const phase = phases[0];
    return phase && phase.is_like_default;
  });
export const makeGetProjectOnlyHasOnePhase = () =>
  createSelector(makeGetActiveBudgetPhases(), (phases) => phases?.length <= 1);

export const getOrderedPhases = createSelector(
  getPhaseIds,
  getPhases,
  (ids, phases) => ids.map((id) => phases[id])
);

export const getOrderedActivePhases = createSelector(
  getOrderedPhases,
  (phases) => phases.filter((phase) => !phase.archived)
);
export const getOrderedActiveUnplannedPhases = createSelector(
  getOrderedActivePhases,
  getPlannedPhasesHash,
  (activePhases, plannedPhasesHash) =>
    activePhases.filter((phase) => !plannedPhasesHash[phase.id])
);

export const makeGetUnformattedPhaseTotal = () =>
  createSelector(
    makeGetOwnPhaseId(),

    getPhaseTotals,
    (phaseId, phaseTotals) => phaseTotals[phaseId] || emptyObj
  );

export const makeGetPhaseTotal = () =>
  createSelector(makeGetUnformattedPhaseTotal(), (phaseTotal) => {
    if (!phaseTotal || phaseTotal === emptyObj) {
      return emptyObj;
    }

    const { phase_id } = phaseTotal;
    const numbers = formatTotals(phaseTotal);
    return { ...numbers, phase_id };
  });

export const getOwnUnformattedActivityTotal = (state, ownProps) =>
  ownProps.activityTotal;
export const getOwnActivityPhase = (state, ownProps) => ownProps.activityPhase;

export const makeGetActivityTotal = () =>
  createSelector(
    getOwnUnformattedActivityTotal,
    getOwnActivityPhase,
    (activityTotal, activityPhase) => {
      if (!activityTotal || activityTotal === emptyObj) {
        return emptyObj;
      }
      const activityFee = +activityPhase?.total || 0;

      const numbers = formatTotals({
        ...activityTotal,
        total: activityFee
      });
      const estimatedPercentAllocated = activityFee
        ? numbers.estimated_cost / activityFee
        : 0;

      const displayEstimatedPercentAllocated =
        estimatedPercentAllocated === 0
          ? 0
          : (estimatedPercentAllocated * 100).toFixed(2);

      const { activity_id } = activityTotal;
      return {
        ...numbers,
        estimated_percent: displayEstimatedPercentAllocated,
        fee_type: activityPhase?.fee_type,
        activity_id
      };
    }
  );

export const makeGetActivityArchivedMembersTotal = () =>
  createSelector(
    getOwnUnformattedActivityTotal,
    (_, ownProps) => ownProps?.phaseTotal,
    getOwnActivityPhase,
    makeGetOwnPhase(),
    (activityTotal, phaseTotal, activityPhase, phase) => {
      if (!activityTotal || activityTotal === emptyObj) {
        return emptyObj;
      }
      const phaseArchivedMembers =
        phase?.phase_memberships?.filter(
          (phaseMembership) => phaseMembership.discarded_at
        ) ?? [];
      const activityPhaseArchivedMembers =
        activityPhase?.activity_phase_memberships?.filter(
          (activityPhaseMembership) => activityPhaseMembership.discarded_at
        ) ?? [];

      const archivedAccountIdsHash = keyBy(
        [...phaseArchivedMembers, ...activityPhaseArchivedMembers],
        (item) => item.account_id
      );

      const archivedTotals = activityTotal?.accounts?.reduce(
        (totals, accountTotal) => {
          if (!archivedAccountIdsHash[accountTotal.account_id]) {
            return totals;
          }
          totals.estimated.hours += accountTotal.estimated.hours;
          totals.estimated.expense += accountTotal.estimated.expense;
          totals.estimated.remaining.expense +=
            accountTotal.estimated.remaining?.expense ?? 0;
          totals.estimated.remaining.hours +=
            accountTotal.estimated.remaining?.hours ?? 0;
          totals.spent.hours += accountTotal.spent.hours;
          totals.spent.expense += accountTotal.spent.expense;
          totals.planned.hours += accountTotal.planned.hours;
          totals.planned.expense += accountTotal.planned.expense;
          return totals;
        },
        {
          estimated: {
            hours: 0,
            expense: 0,
            remaining: { hours: 0, expense: 0 }
          },
          spent: { hours: 0, expense: 0 },
          planned: { hours: 0, expense: 0 }
        }
      );

      const numbers = formatTotals(archivedTotals);
      const totalCost = phaseTotal?.total;
      const percentAllocated = totalCost
        ? numbers.estimated_cost / totalCost
        : 0;
      const displayPercentAllocated =
        percentAllocated === 0 ? 0 : (percentAllocated * 100).toFixed(2);

      const { activity_id } = activityTotal;
      return {
        ...numbers,
        estimated_percent: displayPercentAllocated,
        activity_id
      };
    }
  );

export const makeGetRemainingTotalHoursForPhase = () =>
  createSelector(
    makeGetOwnPhaseId(),
    getMemberBudgets,
    (phaseId, memberBudgets) => {
      let remainingTotalHours = 0;
      Object.values(memberBudgets).forEach((memberBudget) => {
        const { numbers } = memberBudget;
        if (numbers && numbers.phases && numbers.phases[phaseId]) {
          const { estimated_hours, remaining_hours } = numbers.phases[phaseId];
          if (parseInt(estimated_hours) > 0) {
            remainingTotalHours += parseInt(remaining_hours);
          }
        }
      });
      return remainingTotalHours;
    }
  );

export const makeGetRemainingTotalPercentageForPhase = () =>
  createSelector(makeGetUnformattedPhaseTotal(), (phaseTotal) => {
    if (!phaseTotal || (phaseTotal && phaseTotal.total === 0)) {
      return 0;
    }
    const totalEstimatedCost = +phaseTotal.estimated?.expense;
    return ((totalEstimatedCost / phaseTotal.total) * 100).toFixed(2);
  });

export const makeGetOwnPhase = () => {
  const getOwnPhaseId = makeGetOwnPhaseId();
  return createSelector(
    getFlatPhasesHash,
    getOwnPhaseId,
    (phases, phaseId) => phases[phaseId]
  );
};

export const getActivityModalName = createSelector(
  getAllActivityRowInfo,
  getActivityModalId,
  (activities, id) => activities && activities[id] && activities[id].title
);
export const getActivityModalById = createSelector(
  getAllActivityRowInfo,
  getActivityModalId,
  (activities, id) => activities[id] || {}
);
export const getActivityModalIsTitleRequired = createSelector(
  getAllActivityRowInfo,
  getActivityModalId,
  (activities, id) =>
    activities && activities[id] && activities[id].require_title
);
export const getActivityModalBillable = createSelector(
  getAllActivityRowInfo,
  getActivityModalId,
  getActivityModalPreFillBillable,
  (activities, id, preFillBillable) =>
    (activities && activities[id] && activities[id].billable) || preFillBillable
);
export const makeGetPartitionedPhaseTemplates = () =>
  createSelector(
    getOrderedPhaseTemplates,
    makeGetSelectedPhaseTemplateIds(),
    makeGetArchivedPhaseTemplateIds(),
    (phaseTemplates, selectedPhaseTemplateIds, archivedPhaseTemplateIds) => {
      const [addedPhases, nonAddedPhases] = partition(
        phaseTemplates,
        (phase) =>
          selectedPhaseTemplateIds[phase.id] &&
          !archivedPhaseTemplateIds[phase.id]
      );
      let lastAdded = addedPhases[getLength(addedPhases) - 1];
      if (lastAdded) {
        lastAdded = {
          ...lastAdded,
          isLastItemInPartition: true
        };
      }
      const [nonArchivedPhases, archivedPhases] = partition(
        nonAddedPhases,
        (phase) => !archivedPhaseTemplateIds[phase.id]
      );
      return [...addedPhases, ...nonArchivedPhases, ...archivedPhases];
    }
  );

export const getMemberBudgets = createSelector(
  getMemberBudgetsState,
  (state) => state.memberBudgets
);

export const getMemberBudgetTotals = createSelector(
  getMemberBudgetsState,
  (state) => state.budgetTotals
);

export const getUnassignedMemberBudgets = createSelector(
  getMemberBudgets,
  (memberBudgetsHash) =>
    Object.values(memberBudgetsHash).filter(
      (memberBudget) => memberBudget.account_id === null
    )
);
export const getUnassignedMemberBudgetSequence = createSelector(
  getUnassignedMemberBudgets,
  (memberBudgets) =>
    memberBudgets
      .slice()
      .sort((a, b) => a.id - b.id)
      .reduce((acc, cur, index) => {
        acc[cur.id] = index + 1;
        return acc;
      }, {})
);

/**
 * @deprecated — Use the typesafe version from BudgetsModule/selectors.
 */
export const getModalProjectInfo = createSelector(
  getSelectedProject,
  getBudgetModalProjectId,
  getMemberProjectsHash,
  (selectedProject, id, hash) => selectedProject || hash[id] || emptyObj
);

export const getMemberBudgetIds = createSelector(
  getMemberBudgetsState,
  (state) => state.memberBudgetIds
);

const getOwnPhaseMemberBudgetOrder = (state, ownProps) => {
  if (ownProps.phase) {
    return ownProps.phase.phase_memberships
      ?.filter((member) => !member.discarded_at)
      ?.map((phaseMember) => phaseMember.member_budget_id);
  }
  if (ownProps.phaseId) {
    const phase = state.phases.phases[ownProps.phaseId];
    if (phase) {
      return phase.phase_memberships
        ?.filter((member) => !member.discarded_at)
        .map((phaseMember) => phaseMember.member_budget_id);
    }
  }
  return emptyArray;
};

const isArchivedMemberBudgetPhase = (memberBudgetPhase, phaseId) =>
  memberBudgetPhase.is_archived && memberBudgetPhase.phase_id === phaseId;

const isHideMemberBudgetPhase = (memberBudgetPhase, phaseId) =>
  memberBudgetPhase.is_hide && memberBudgetPhase.phase_id === phaseId;

const getMemberBudgetPhaseHours = (memberTotalsHash, memberBudget) => {
  const accountTotal = memberTotalsHash?.[memberBudget.id];
  if (!accountTotal) {
    return -1;
  }
  const { planned, spent, estimated = {} } = accountTotal;
  const { remaining } = estimated;
  if (!planned || !spent || !remaining) {
    return -1;
  }
  return +planned.hours + +spent.hours + Math.max(+remaining.hours, 0);
};
const getMemberBudgetIsAssigned = (memberBudget) =>
  !getIsUnassignedMemberBudget(memberBudget);

const makeMemberBudgetsSorterByPhase = (memberTotalsHash) => (a, b) => {
  const aHours = getMemberBudgetPhaseHours(memberTotalsHash, a);
  const bHours = getMemberBudgetPhaseHours(memberTotalsHash, b);
  const aAssigned = getMemberBudgetIsAssigned(a);
  const bAssigned = getMemberBudgetIsAssigned(b);
  if (aAssigned === bAssigned) {
    return bHours - aHours;
  } else {
    return aAssigned ? -1 : 1;
  }
};

const getOwnAccountIds = (state, ownProps) => ownProps.accountIds;

export const makeGetFilteredMemberBudgets = () =>
  createSelector(
    getMemberBudgets,
    getOwnPhaseMemberBudgetOrder,
    getSelectedAccountIds,
    getOwnAccountIds,
    makeGetOwnPhaseId(),
    makeGetUnformattedPhaseTotal(),
    (memberBudgets, order, accountIds, ownAccountIds, phaseId, phaseTotal) => {
      const accountIdsToUse = ownAccountIds?.length
        ? ownAccountIds
        : accountIds;
      const idHash = makeIdHash(accountIdsToUse);
      const memberTotalsHash = keyBy(
        phaseTotal?.account_totals,
        (accountTotal) => accountTotal.member_budget_id
      );
      return order
        .map((id) => memberBudgets[id])
        .filter(
          (memberBudget) =>
            memberBudget?.id &&
            !memberBudget.activity_phase_member_budgets?.find(
              (memberBudgetPhase) =>
                isArchivedMemberBudgetPhase(memberBudgetPhase, phaseId)
            ) &&
            !memberBudget.activity_phase_member_budgets?.find(
              (memberBudgetPhase) =>
                isHideMemberBudgetPhase(memberBudgetPhase, phaseId)
            ) &&
            (!accountIdsToUse.length ||
              (memberBudget.project_membership &&
                memberBudget.project_membership.account_id !== null &&
                idHash[memberBudget.project_membership.account_id]))
        )
        .sort(makeMemberBudgetsSorterByPhase(memberTotalsHash));
    }
  );

export const makeGetArchivedMemberBudgets = () =>
  createSelector(
    getMemberBudgets,
    getOwnPhaseMemberBudgetOrder,
    getSelectedAccountIds,
    makeGetOwnPhaseId(),
    (memberBudgets, order, accountIds, phaseId) => {
      const idHash = makeIdHash(accountIds);
      return order
        .map((id) => memberBudgets[id])
        .filter(
          (memberBudget) =>
            memberBudget &&
            memberBudget.id &&
            memberBudget.activity_phase_member_budgets?.find(
              (memberBudgetPhase) =>
                isArchivedMemberBudgetPhase(memberBudgetPhase, phaseId)
            ) &&
            (!accountIds.length ||
              (memberBudget?.project_membership &&
                memberBudget?.project_membership.account_id !== null &&
                idHash[memberBudget.project_membership.account_id]))
        );
    }
  );

export const makeGetFilteredMemberBudgetIds = () =>
  createSelector(makeGetFilteredMemberBudgets(), (memberBudgets) =>
    memberBudgets.map((memberBudget) => memberBudget.id)
  );
export const makeGetArchivedMemberBudgetIds = () =>
  createSelector(makeGetArchivedMemberBudgets(), (memberBudgets) =>
    memberBudgets.map((memberBudget) => memberBudget.id)
  );
export const makeGetArchivedMemberBudgetsCount = () =>
  createSelector(
    makeGetArchivedMemberBudgets(),
    (memberBudgets) => memberBudgets.length
  );
export const makeGetFilteredAssignedBudgets = () =>
  createSelector(makeGetFilteredMemberBudgets(), (memberBudgets) =>
    memberBudgets.filter((memberBudget) =>
      getIsUnassignedMemberBudget(memberBudget)
    )
  );
export const makeGetFilteredAssignedBudgetIds = () =>
  createSelector(makeGetFilteredAssignedBudgets(), (memberBudgets) =>
    memberBudgets.map((memberBudget) => memberBudget.id)
  );
export const getOwnMemberBudgetId = (state, ownProps) =>
  ownProps.memberBudgetId;

export const makeGetMemberBudget = () =>
  createSelector(
    getMemberBudgets,
    getOwnMemberBudgetId,
    getPositions,
    (memberBudgets, id, positions = emptyObj) =>
      memberBudgets[id] && {
        ...memberBudgets[id],
        position: positions[memberBudgets[id].position_id]
      }
  );

export const makeGetMemberBudgetAssignment = () => {
  const getMemberBudget = makeGetMemberBudget();
  return createSelector(getMemberBudget, (memberBudget) => memberBudget);
};

export const makeGetTeamMembershipByMemberBudgetId = () =>
  createSelector(
    makeGetMemberBudgetAssignment(),
    getTeamMembershipsByAccountId,
    (memberBudget, teamMemberships) =>
      memberBudget &&
      memberBudget.project_membership &&
      memberBudget.project_membership.account_id !== null &&
      teamMemberships &&
      teamMemberships[memberBudget.project_membership.account_id]
  );

const getOwnMemberTotals = (state, ownProps) => ownProps.memberTotal;
export const makeGetMemberBudgetNumbers = () => {
  return getOwnMemberTotals;
};

const emptyPhaseNumbers = {
  estimated_hours: 0,
  spent_hours: 0,
  planned_hours: 0,
  remaining_hours: 0,
  estimated_cost: 0,
  spent_cost: 0,
  planned_cost: 0,
  remaining_cost: 0
};
export const makeGetMemberBudgetNumbersByPhase = () => {
  const getMemberBudgetNumbers = makeGetMemberBudgetNumbers();
  return createSelector(
    getMemberBudgetNumbers,
    getProjectBudgetSettings,
    (numbers, budgetSettings) => {
      if (!numbers) {
        return emptyPhaseNumbers;
      }
      const { estimated } = numbers;
      const { activity_phase_estimation_id } = estimated;

      const phaseNumbers = {
        ...formatTotals(numbers),
        phase_estimation_id: activity_phase_estimation_id
      };

      const memberTotals = {
        hours:
          +phaseNumbers.planned_hours +
          +phaseNumbers.remaining_hours +
          +phaseNumbers.spent_hours,
        cost:
          +phaseNumbers.planned_cost +
          +phaseNumbers.remaining_cost +
          +phaseNumbers.spent_cost
      };

      const costOrHours = [
        'planned_cost',
        'remaining_cost',
        'spent_cost'
      ].every((column) => budgetSettings[column])
        ? 'cost'
        : 'hours';

      const memberTotal = memberTotals[costOrHours];

      const generatePercent = () => ({
        estimated_percent: 0,
        // member estimated cost % of target of either activity phase or phase
        estimated_percent_of_target: numbers.estimated?.percentage || 0,
        planned_percent:
          (parseFloat(phaseNumbers[`planned_${costOrHours}`]) * 100) /
            memberTotal || 0,
        remaining_percent:
          (parseFloat(phaseNumbers[`remaining_${costOrHours}`]) * 100) /
            memberTotal || 0,
        spent_percent:
          (parseFloat(phaseNumbers[`spent_${costOrHours}`]) * 100) /
            memberTotal || 0
      });
      return { ...phaseNumbers, ...generatePercent() };
    }
  );
};

export const getTeamMembersRates = createSelector(
  getRatesState,
  (state) => state.teamMemberRates
);
// team standard rates
export const getTeamRateIds = createSelector(
  getRatesState,
  (state) => state.rateIds
);
export const getTeamRates = createSelector(
  getRatesState,
  (state) => state.rates
);
export const getTeamRatesByDescriptionHash = createSelector(
  getTeamRates,
  (rates) => keyBy(rates, (rate) => rate.description)
);
export const getIsFetchingTeamStandardRates = createSelector(
  getRatesState,
  (state) => state.isFetchingRates
);

// Default team rates is the company wide rate set in the settings view for members
export const getDefaultTeamRates = createSelector(
  getRatesState,
  (state) => state.teamRates
);

export const getAllMembersTeamRates = createSelector(
  getRatesState,
  (state) => state.memberTeamRates
);

export const getOrderedTeamRates = createSelector(
  getSelectedTeamRateOrder,
  getTeamRates,
  (ids, rates) => ids.map((id) => rates[id]) ?? []
);

export const getUnorderedTeamRates = createSelector(
  getTeamRateIds,
  getTeamRates,
  (ids, rates) => ids.map((id) => rates[id]) ?? []
);

export const getOrderedCurrentTeamRates = createSelector(
  getUnorderedTeamRates,
  (rates) => rates.filter((rate) => rate && !rate.archived && !rate.is_default)
);

export const getArchivedTeamRates = createSelector(
  getUnorderedTeamRates,
  (rates) => rates.filter((rate) => rate && rate.archived)
);

export const getOrderedArchivedTeamRates = createSelector(
  getTeamRates,
  (ratesObj) =>
    Object.values(ratesObj)
      .filter((rate) => rate.archived)
      .sort((a, b) =>
        a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
      )
);

export const getOwnTeamRoleId = (state, ownProps) => ownProps.teamRoleId;

export const getTeamRoleIds = createSelector(
  getTeamRolesState,
  (state) => state.teamRoleIds
);

export const getTeamRoles = createSelector(
  getTeamRolesState,
  (state) => state.teamRoles
);

export const getOrderedTeamRoles = createSelector(
  getSelectedTeamRoleOrder,
  getTeamRoles,
  (ids, roles) => ids.map((id) => roles[id])
);

export const makeGetTeamRole = () =>
  createSelector(
    getOwnTeamRoleId,
    getTeamRoles,
    (id, teamRoles) => id && teamRoles && teamRoles[id]
  );

export const getMemberRateIds = createSelector(
  getMemberRatesState,
  (state) => state.memberRateIds
);

export const getMemberRates = createSelector(
  getMemberRatesState,
  (state) => state.memberRates
);

export const makeGetMemberRatesByMemberBudget = () =>
  createSelector(
    makeGetMemberBudgetAssignment(),
    (assign) => assign && assign.member_rates
  );

export const makeGetOrderedRatesByMemberBudget = () =>
  createSelector(makeGetMemberRatesByMemberBudget(), (rates) =>
    rates && rates.length
      ? [...rates].sort((a, b) => moment(b.start_date) - moment(a.start_date))
      : emptyArray
  );

/**
 * returns the active bill rate for a member budget
 */
export const makeGetActiveRateByMemberBudget = () =>
  createSelector(makeGetOrderedRatesByMemberBudget(), (orderedRates) =>
    orderedRates.find(
      (rate) =>
        !rate.is_cost_rate &&
        // Final ongoing rate. (This works because the rates are ordered and this
        // will be the final rate, which means that no other rate has matched.)
        (!rate.end_date ||
          // Rate that contains today's date.
          isWithinInterval(new Date(), {
            start: parseISO(rate.start_date),
            end: endOfDay(parseISO(rate.end_date))
          }))
    )
  );

export const makeGetOrderedMemberRates = () =>
  createSelector(getMemberRateIds, getMemberRates, (ids, rates) =>
    ids
      .map((id) => rates[id])
      .sort((a, b) => moment(b.start_date) - moment(a.start_date))
  );

/**
 * Returns the rates for a member budget, sorted by start date
 * adds rate data to the member_rates of a member budget, same as a fetch for member_rates would do
 * ignores is_cost_rate: true member rates, which can exist for unassigned positions. those rates
 * won't exist in the hash
 */
export const makeGetOwnSortedMemberBudgetRates = () =>
  createSelector(
    (_, ownProps) => ownProps?.memberBudgetRates,
    getTeamRates,
    (memberRates = [], rateHash) =>
      memberRates
        .filter((memberRate) => !memberRate.is_cost_rate)
        .map((memberRate) => ({
          ...memberRate,
          rate: rateHash[memberRate.rate_id]
        }))
        .sort((a, b) => moment(b.start_date) - moment(a.start_date))
  );

const getOwnTeamMemberId = (state, ownProps) => ownProps.teamMembershipId;

export const makeGetOrderedMemberTeamRates = () =>
  createSelector(
    getAllMembersTeamRates,
    getOwnTeamMemberId,
    (_, ownProps) => ownProps?.rates,
    (rates, membershipId, overrideRates) => {
      if (overrideRates) return overrideRates;
      const memberTeamRates = rates[membershipId];
      if (memberTeamRates) {
        return memberTeamRates
          .slice()
          .sort((a, b) => moment(b.start_date) - moment(a.start_date));
      }
      return [];
    }
  );

export const getOwnSelectedMemberRate = (state, ownProps) =>
  ownProps.selectedMemberRate;
export const getOwnSelectedMemberTeamRate = (state, ownProps) =>
  ownProps.selectedMemberTeamRate;
export const getOwnDateType = (state, ownProps) => ownProps.dateType;

export const getEarliestTeamRate = createSelector(
  makeGetOrderedMemberTeamRates(),
  (memberTeamRates) => memberTeamRates[memberTeamRates.length - 1]
);

export const getEarlierTeamRate = createSelector(
  getOwnSelectedMemberTeamRate,
  getOwnDateType,
  makeGetOrderedMemberTeamRates(),
  (memberTeamRate, dateType, memberTeamRates) => {
    if (dateType === 'endDate') {
      return memberTeamRate;
    } else {
      // already selected rate is later, get rate before it
      const memberRateIndex = memberTeamRates.findIndex(
        (item) => item.id === memberTeamRate?.id
      );
      return memberTeamRates[memberRateIndex + 1];
    }
  }
);
export const getLaterTeamRate = createSelector(
  getOwnSelectedMemberTeamRate,
  getOwnDateType,
  makeGetOrderedMemberTeamRates(),
  (memberTeamRate, dateType, memberTeamRates) => {
    if (dateType === 'startDate') {
      return memberTeamRate;
    } else {
      // already selected rate is earlier, get rate after it
      const memberRateIndex = memberTeamRates.findIndex(
        (item) => item.id === memberTeamRate?.id
      );
      return memberTeamRates[memberRateIndex - 1];
    }
  }
);

export const getEarliestRate = createSelector(
  makeGetOrderedMemberRates(),
  (memberRates) => memberRates[memberRates.length - 1]
);

export const getEarlierRate = createSelector(
  getOwnSelectedMemberRate,
  getOwnDateType,
  makeGetOrderedMemberRates(),
  (memberRate, dateType, memberRates) => {
    if (dateType === 'end_date') {
      return memberRate;
    } else {
      // already selected rate is later, get rate before it
      const memberRateIndex = memberRates.findIndex(
        (item) => item.id === memberRate.id
      );
      return memberRates[memberRateIndex + 1];
    }
  }
);
export const getLaterRate = createSelector(
  getOwnSelectedMemberRate,
  getOwnDateType,
  makeGetOrderedMemberRates(),
  (memberRate, dateType, memberRates) => {
    if (dateType === 'start_date') {
      return memberRate;
    } else {
      // already selected rate is earlier, get rate after it
      const memberRateIndex = memberRates.findIndex(
        (item) => item.id === memberRate.id
      );
      return memberRates[memberRateIndex - 1];
    }
  }
);

export const getActiveMemberRate = createSelector(
  makeGetOrderedMemberRates(),
  (memberRates) => memberRates.length && memberRates[0]
);

export const getPlannedHours = createSelector(
  getHoursState,
  (state) => state.plannedHours
);

export const getSpentHours = createSelector(
  getHoursState,
  (state) => state.spentHours
);

/* hours shape is same for planned and sorted hours - we can reuse output selector logic
sortDates transforms from { '05/20/2019': '3.0' } to [{date: '05/20/2019', hour: '3.0'}], sorted by date */
const sortDates = (hours) =>
  Object.keys(hours)
    .sort((a, b) => moment(a, 'MM/DD/YYYY') - moment(b, 'MM/DD/YYYY'))
    .map((date) => ({
      date,
      hours: hours[date]
    }));

export const getSortedPlannedHours = createSelector(getPlannedHours, sortDates);
export const getSortedSpentHours = createSelector(getSpentHours, sortDates);

export const getIsLoadingPhasesOrPhaseTemplates = createSelector(
  getIsLoadingPhases,
  getIsLoadingPhaseTemplates,
  (loadingPhases, loadingTemplates) => loadingPhases || loadingTemplates
);

export const getHiddenProjectTotals = createSelector(
  getBudgetModalProjectId,
  getAllProjectTotals,
  (id, projectTotals) => projectTotals && projectTotals[id]
);

export const makeGetOwnDescription = () => (state, ownProps) =>
  ownProps.description;

const getPlannerProjectId = (state, ownProps) =>
  ownProps.projectId || (ownProps.group && ownProps.group.id);
export const makeGetPlannerProjectPhases = () =>
  createSelector(
    getPlannerProjectId,
    getPhasesByProjectHash,
    (projectId, phasesByProject) =>
      phasesByProject[projectId] && phasesByProject[projectId].phases
  );
export const makeGetPlannerProjectPhase = () =>
  createSelector(
    makeGetPlannerProjectPhases(),
    makeGetOwnPhaseId(),
    (phases, phaseId) => phases && phases.find((phase) => phase.id === phaseId)
  );
export const makeGetPlannerProjectHasMoreThanOnePhase = () =>
  createSelector(
    makeGetPlannerProjectPhases(),
    (phases) => phases && phases.length > 1
  );

export const makeGetPlannerProjectOnlyHasDefaultPhase = () =>
  createSelector(makeGetPlannerProjectPhases(), (phases) => {
    if (!phases || !phases.length) {
      return false;
    }
    if (phases && phases.length > 1) {
      return false;
    }
    const phase = phases[0];
    return phase && phase.is_like_default;
  });

export const makeGetPlannerProjectFirstPhase = () =>
  createSelector(
    makeGetPlannerProjectPhases(),
    (phases) => phases && phases.length && phases[0]
  );
export const makeGetPlannerProjectFirstPhaseTemplate = () =>
  createSelector(
    makeGetPlannerProjectFirstPhase(),
    getPhaseTemplates,
    (phase, phaseTemplates) => phase && phaseTemplates[phase.phase_template_id]
  );
export const makeGetPlannerProjectFirstPhaseName = () =>
  createSelector(makeGetPlannerProjectFirstPhaseTemplate(), (phaseTemplate) => {
    return phaseTemplate && phaseTemplate.name;
  });

export const makeStubDefaultPhaseName = () =>
  createSelector(
    makeGetPlannerProjectOnlyHasDefaultPhase(),
    makeGetPlannerProjectHasMoreThanOnePhase(),
    makeGetPlannerProjectFirstPhaseName(),
    (onlyHasDefaultPhase, hasMoreThanOnePhase, phaseName) => {
      if (onlyHasDefaultPhase) {
        return '';
      }
      if (!hasMoreThanOnePhase) {
        return phaseName;
      }
    }
  );

export const makeGetPhaseIdFromDescriptionId = () =>
  createSelector(
    makeGetDescriptionFromId(),
    makeGetPlannerProjectFirstPhase(),
    (description, phase) =>
      (description && description.phase_id) || (phase && phase.id)
  );

export const makeGetPhaseByDescription = () =>
  createSelector(
    makeGetOwnDescription(),
    makeStubDefaultPhaseName(),
    makeGetPlannerProjectHasMoreThanOnePhase(),
    getPhasesByProjectHash,
    (description, firstPhaseName, hasMoreThanOnePhase, phasesByProject) => {
      if (!hasMoreThanOnePhase) {
        return firstPhaseName;
      }
      if (!description || !description.project_id || !description.phase_id) {
        return null;
      }
      const { project_id, phase_id } = description;
      const phase = phasesByProject[project_id].phases.find(
        (phase) => phase.id == phase_id
      );
      return phase;
    }
  );

/**
 * @deprecated — Use the typesafe version `getBudgetSettingsState` from
 * BudgetsModule/selectors.
 */
const getBudgetSettings = (state) => state.budgetSettings;

export const getProjectBudgetSettingsHash = createSelector(
  getBudgetSettings,
  (budgetSettingsState) => budgetSettingsState?.settings
);

/**
 * @deprecated — Use the typesafe version from BudgetsModule/selectors.
 */
export const getTeamBudgetSettingsHash = createSelector(
  getBudgetSettings,
  (budgetSettingsState) => budgetSettingsState.teamSettings
);

/**
 * @deprecated — Use the typesafe version from BudgetsModule/selectors.
 */
export const getProjectBudgetSettings = createSelector(
  getModalProjectInfo,
  getBudgetSettings,
  (project, projectBudgetSettings) => {
    // Masking the settings to only let $ or hours rendered
    const budgetSettings =
      projectBudgetSettings?.settings[project.id] || emptyObj;
    let cost =
      budgetSettings.estimated_cost ||
      budgetSettings.spent_cost ||
      budgetSettings.planned_cost;
    const hours =
      (budgetSettings.estimated_hours ||
        budgetSettings.spent_hours ||
        budgetSettings.planned_hours) &&
      !cost;
    if (!hours && !cost) {
      cost = true; // default is cost
    }
    const percent =
      (budgetSettings.estimated_percent ||
        budgetSettings.spent_percent ||
        budgetSettings.planned_percent) &&
      false;
    return {
      ...budgetSettings,
      rate: false,
      estimated_hours: hours,
      planned_hours: hours,
      remaining_hours: hours,
      spent_hours: hours,
      estimated_cost: cost,
      planned_cost: cost,
      remaining_cost: cost,
      spent_cost: cost,
      remaining_percent: percent,
      estimated_percent: true,
      spent_percent: percent,
      planned_percent: percent,
      decimals: false
    };
  }
);

export const getSortedSelectedProjectPhases = createSelector(
  getProjectBudgetSettings,
  getModalProjectInfo,
  getPhasesByProjectHash,
  (projectBudgetSettings, project, phasesByProject) => {
    const projectPhases = phasesByProject[project?.id];
    const phaseDefaultSort = projectBudgetSettings?.phase_default_sort;
    if (
      phaseDefaultSort &&
      phaseDefaultSort.length &&
      phaseDefaultSort[0].attribute !== SORT_BY.sort_rank
    ) {
      const { phases = [] } = projectPhases || emptyObj;
      const filteredPhases = phases.filter((phase) => phase.is_budget);
      const [activePhases, archivedPhases] = partition(
        filteredPhases,
        (phase) => !phase.archived
      );
      return [...activePhases, ...archivedPhases];
    }
    return sortPhasesByPhaseOrder(projectPhases);
  }
);

const gripColumn = {
  headerType: 'grip',
  // headerLabel: SORT_BY.date,
  accessor: (row) => row.id,
  id: 'grip'
  // align: 'left'
};

const thingColumn = {
  headerType: 'thing',
  // headerLabel: SORT_BY.member,
  accessor: (row) => row.item,
  id: 'thing',
  align: 'left'
};

const estimatedHoursPercentColumn = {
  headerType: 'estimated_hours_percent',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.estimated_percent,
  id: 'estimated__hours_percent',
  align: 'left'
};

const estimatedHoursColumn = {
  headerType: 'estimated_hours',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.estimated_hours,
  id: 'estimated_hours',
  align: 'left'
};

const spentHoursColumn = {
  headerType: 'spent_hours',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.spent_hours,
  id: 'spent_hours',
  align: 'left'
};

const plannedHoursColumn = {
  headerType: 'planned_hours',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.planned_hours,
  id: 'planned_hours',
  align: 'left'
};

const remainingHoursColumn = {
  headerType: 'remaining_hours',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.remaining_hours,
  id: 'remaining_hours',
  align: 'left'
};

const totalHoursColumn = {
  headerType: 'totals',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.totals,
  id: 'totals',
  align: 'left'
};

const estimatedCostPercentColumn = {
  headerType: 'estimated_cost_percent',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.estimated_percent,
  id: 'estimated_cost_percent',
  align: 'left'
};

const estimatedCostColumn = {
  headerType: 'estimated_cost',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.estimated_cost,
  id: 'estimated_cost',
  align: 'left'
};

const spentCostColumn = {
  headerType: 'spent_cost',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.spent_cost,
  id: 'spent_cost',
  align: 'left'
};

const plannedCostColumn = {
  headerType: 'planned_cost',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.planned_cost,
  id: 'planned_cost',
  align: 'left'
};

const remainingCostColumn = {
  headerType: 'remaining_cost',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.remaining_cost,
  id: 'remaining_cost',
  align: 'left'
};

const totalCostColumn = {
  headerType: 'totals',
  // headerLabel: SORT_BY.project,
  accessor: (row) => row.totals?.totals,
  id: 'totals',
  align: 'left'
};

const budgetTableHoursColumns = [
  gripColumn,
  thingColumn,
  estimatedHoursPercentColumn,
  estimatedHoursColumn,
  spentHoursColumn,
  plannedHoursColumn,
  remainingHoursColumn,
  totalHoursColumn
];

const budgetTableCostColumns = [
  gripColumn,
  thingColumn,
  estimatedCostPercentColumn,
  estimatedCostColumn,
  spentCostColumn,
  plannedCostColumn,
  remainingCostColumn,
  totalCostColumn
];

export const getBudgetColumnHeaders = createSelector(
  getProjectBudgetSettings,
  (budgetSettings) =>
    budgetSettings?.planned_hours
      ? budgetTableHoursColumns
      : budgetTableCostColumns
);

export const getShowProjectBudgetPercents = createSelector(
  getProjectBudgetSettings,
  (budgetSettings) => {
    if (!budgetSettings) {
      return emptyObj;
    }
    const spent_percent =
      budgetSettings.spent_percent &&
      (budgetSettings.spent_cost || budgetSettings.spent_hours);
    const planned_percent =
      budgetSettings.planned_percent &&
      (budgetSettings.planned_cost || budgetSettings.planned_hours);
    const remaining_percent =
      budgetSettings.remaining_percent &&
      (budgetSettings.remaining_cost || budgetSettings.remaining_hours);
    return {
      spent_percent,
      planned_percent,
      remaining_percent
    };
  }
);
export const getDecimalsVisible = createSelector(
  getProjectBudgetSettings,
  (budgetSettings) => budgetSettings.decimals
);
export const makeGetProjectBudgetColumns = () =>
  createSelector(getProjectBudgetSettings, (budgetSettings) => {
    const budgetArray = [
      {
        title: 'estimated',
        percent: budgetSettings.estimated_percent,
        numColumns: 1,
        key: 'estimated_percent'
      },
      {
        title: 'estimated',
        hours: budgetSettings.estimated_hours,
        numColumns: budgetSettings.estimated_hours ? 1 : 0,
        key: 'estimated_hours'
      },
      {
        title: 'estimated',
        cost: budgetSettings.estimated_cost,
        numColumns: budgetSettings.estimated_cost ? 1 : 0,
        key: 'estimated_cost'
      },
      {
        title: 'spent',
        cost: budgetSettings.spent_cost,
        hours: budgetSettings.spent_hours,
        percent: budgetSettings.spent_percent,
        numColumns: 1,
        tooltip: constants.SPENT_TOOLTIP,
        key: 'spent'
      },
      {
        title: 'planned',
        cost: budgetSettings.planned_cost,
        hours: budgetSettings.planned_hours,
        percent: budgetSettings.planned_percent,
        numColumns: 1,
        tooltip: constants.PLANNED_TOOLTIP,
        key: 'planned'
      },
      {
        title: 'remaining',
        cost: budgetSettings.remaining_cost,
        hours: budgetSettings.remaining_hours,
        percent: budgetSettings.remaining_percent,
        numColumns: 1,
        tooltip: constants.REMAINING_TOOLTIP,
        key: 'remaining'
      }
    ];
    return budgetArray;
  });

const getArchiveMemberModalPhaseId = createSelector(
  getBudgetState,
  (state) => state.archiveMemberModalPhaseId
);
export const getArchiveMemberModalPhase = createSelector(
  getArchiveMemberModalPhaseId,
  getPhases,
  (phaseId, phases) => phases[phaseId]
);

export const makeGetMemberBudgetMaxByPhase = () =>
  createSelector(
    makeGetUnformattedPhaseTotal(),
    (phaseTotal) =>
      phaseTotal?.account_totals?.reduce(
        (max, accountTotal) =>
          Math.max(
            +accountTotal.spent?.hours +
              +accountTotal.planned?.hours +
              Math.max(+accountTotal.estimated?.remaining?.hours, 0),
            max
          ),
        1
      ) ?? 1
  );

export const makeGetMemberBudgetIdsByProjectBudgetSettings = () =>
  createSelector(
    getProjectBudgetSettings,
    makeGetFilteredMemberBudgetIds(),
    makeGetFilteredAssignedBudgetIds(),
    (budgetSettings, memberBudgetIds, assignedMemberBudgetIds) =>
      budgetSettings.estimated_hours ? memberBudgetIds : assignedMemberBudgetIds
  );

export const getPhaseMembersOrderByPhaseId = createSelector(
  getBudgetState,
  (budgetState) => budgetState.phaseMembersOrderByPhaseId
);

export const getPhaseId = (state, props) => props.phase && props.phase.id;

export const makeGetMemberBudgetInitialOrder = () =>
  createSelector(
    getPhaseId,
    getPhaseMembersOrderByPhaseId,
    (phaseId, phaseMembers) => phaseMembers[phaseId]
  );
export const getActivePhaseWithOrder = () =>
  createSelector(
    getPhasesOrder,
    makeGetArchivedPhaseIds(),
    (phasesOrder, archivePhaseIds) => {
      return phasesOrder.filter(
        (phaseId) => !archivePhaseIds.includes(phaseId)
      );
    }
  );

export const getIsRateEditingFlag = createSelector(
  getMemberRatesState,
  (memberRateState) => memberRateState.isEditMemberRate
);

export const getEditMemberRateItem = createSelector(
  getMemberRatesState,
  (memberRateState) => memberRateState.editMemberRateItem
);

export const getActiveTeamSalaries = createSelector(
  getSalariesState,
  (state) => state.activeTeamSalaries
);

export const getMemberSalaries = createSelector(
  getSalariesState,
  (state) => state.memberSalaries
);

export const getOverhead = createSelector(
  getSalariesState,
  (state) => state.overhead
);

export * from './budgetBilling';
export * from './budgetRecords';
export * from './projectAccounts';
// export * from './positions';
