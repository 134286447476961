import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SendNoteEmailIcon = ({ strokecolor = '#0074d9', fillcolor = '' }) => {
  return (
    <SvgIcon
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="a"
          width="106%"
          height="108.3%"
          x="-3%"
          y="-2.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.443850978 0 0 0 0 0.443850978 0 0 0 0 0.443850978 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        fill="#4A4A4A"
        fillRule="evenodd"
        d="M10.559 5.786H6.934a.44.44 0 0 0-.441.438.44.44 0 0 0 .44.437h3.185v8.464H.882V6.66h3.184a.44.44 0 0 0 .441-.437.44.44 0 0 0-.44-.438H.44A.44.44 0 0 0 0 6.224v9.338A.44.44 0 0 0 .441 16H10.56a.44.44 0 0 0 .441-.438V6.224a.44.44 0 0 0-.441-.438zM3.448 4.093l1.61-1.598v8.704a.44.44 0 0 0 .442.438.44.44 0 0 0 .441-.438V2.495l1.611 1.598a.442.442 0 0 0 .624 0 .435.435 0 0 0 0-.619L5.812 1.13a.442.442 0 0 0-.624 0L2.824 3.474a.435.435 0 0 0 0 .62.443.443 0 0 0 .624 0z"
        filter="url(#a)"
        transform="translate(2 -1)"
      />
    </SvgIcon>
  );
};

export default SendNoteEmailIcon;
