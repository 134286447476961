import { MemberRowEmailCell } from './Cells/MemberRowEmailCell';
import { MemberRowNameCell } from './Cells/MemberRowNameCell';
import { SettingTableTemplate } from './SettingTableTemplate';
import { FlatTableTemplateProps } from './types';
import {
  GroupedSettingTableWithMemberDropdown,
  GroupedSettingTableWithMemberDropdownProps
} from './GroupedSettingTableWithMemberDropdown';

const baseColumns = [
  {
    id: 'name',
    label: 'name',
    width: 280,
    rowCells: {
      memberRow: MemberRowNameCell
    }
  },
  {
    id: 'email',
    label: 'email',
    width: 300,
    rowCells: {
      memberRow: MemberRowEmailCell
    }
  }
];

type SettingTableTemplateWithMemberCellsProps<Data> =
  | FlatTableTemplateProps<Data>
  | GroupedSettingTableWithMemberDropdownProps<Data>;
export const SettingTableTemplateWithMemberCells = <Data,>({
  useGroup,
  columns,
  footerRowProps,
  ...restProps
}: SettingTableTemplateWithMemberCellsProps<Data>) => {
  const columnsToUse = [...baseColumns, ...(columns ?? [])];
  return (
    <>
      {useGroup ? (
        <GroupedSettingTableWithMemberDropdown
          columns={columnsToUse}
          {...(restProps as GroupedSettingTableWithMemberDropdownProps<Data>)}
        />
      ) : (
        <SettingTableTemplate
          columns={[...baseColumns, ...(columns ?? [])]}
          {...(restProps as FlatTableTemplateProps<Data>)}
        />
      )}
    </>
  );
};
