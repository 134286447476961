import styled from 'styled-components';
import theme from 'theme';
import SyncedIcon from 'icons/SyncedIcon';

export const Header = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SyncedStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px 2px 2px;
  width: 130px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid
    ${({ isSynced }) =>
      isSynced
        ? theme.colors.colorConnectedGreen
        : theme.colors.colorCalendarRed};
`;
export const StyledTextContainer = styled.div`
  font-size: 14px;
  color: ${({ isSynced }) =>
    isSynced
      ? theme.colors.colorConnectedGreen
      : theme.colors.colorCalendarRed};
`;

export const StyledIconContainer = styled.div`
  padding-right: 4px;
  padding-left: ${(props) => (props.isDisconnected ? 4 : 0)}px;
  display: flex;
`;
export const LinkButton = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray5x};
  padding: 5px 10px;
  border: 1px solid ${theme.colors.colorPaleGray5};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    border: 1px solid ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorPureWhite};
  }
`;

export const LinkTableBody = styled.div`
  max-height: 500px;
  overflow: auto;
`;

export const ErrorCopy = styled.div`
  font-size: 0.8rem;
  color: ${theme.colors.colorCrimsonRed};
`;

export const RowNumber = styled.span`
  position: absolute;
  display: none;
  font-size: 0.85rem;
  color: ${theme.colors.colorMediumGray8};
`;

export const NoResultsCopy = styled.div`
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 600;
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DropdownSelectedValue = styled.div`
  padding-left: 15px;
`;

export const StyledSyncedIcon = styled(SyncedIcon)`
  path {
    fill: ${theme.colors.colorConnectedGreen};
  }
  height: 14px;
  width: 14px;
  margin-left: 8px;
  -webkit-animation: spin 3s linear 0s forwards;
  -moz-animation: spin 3s linear 0s forwards;
  animation: spin 3s linear 0s forwards;
  transform-origin: center center;

  @-moz-keyframes spin {
    0% {
      opacity: 1;
      visibility: visible;
    }
    99% {
      opacity: 1;
      visibility: visible;
    }

    100% {
      opacity: 0;
      visibility: hidden;
      -moz-transform: rotate(1800deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      opacity: 1;
      visibility: visible;
    }
    99% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      -webkit-transform: rotate(1800deg);
    }
  }
  @keyframes spin {
    0% {
      opacity: 1;
      visibility: visible;
    }
    99% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      -webkit-transform: rotate(1800deg);
      transform: rotate(1800deg);
    }
  }
`;
