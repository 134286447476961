import { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Popover as ReactstrapPopover } from 'reactstrap';
import { isInAnyPopover } from 'appUtils/popoverClicks';
import cn from 'classnames';
/* UncontrolledPopover props
  "placement={string}" and
  "modifiers{{ flip: behavior: [string]}}
  must be same value to prevent occasional rendering loop due to infinite state
  flipping in library component
  see https://github.com/reactstrap/reactstrap/issues/1488 and
  see https://github.com/reactstrap/reactstrap/issues/1482
*/

class Popover extends Component {
  handleClickOutside = (e) => {
    const {
      isOpen,
      togglePopover,
      closePopover,
      stopPropagation,
      insideAnotherPopover
    } = this.props;
    if (isInAnyPopover(e) && !insideAnotherPopover) {
      return;
    }
    if (isOpen) {
      if (togglePopover) togglePopover(false);
      // toggles may require a bool to decide open/close
      if (stopPropagation) e && e.stopPropagation && e.stopPropagation();
      closePopover && closePopover(e); // close requests may require a click event
    }
  };

  stopPropagation = (e) => e.stopPropagation();
  render() {
    const {
      isOpen,
      children,
      target,
      showArrow,
      placement,
      boundariesElement,
      className,
      offset = ''
    } = this.props;
    return (
      <ReactstrapPopover
        placement={placement || 'bottom-start'}
        className={cn('popover-wrapper', className)}
        isOpen={isOpen}
        target={target || null} // you have to explicitly pass null because there's a === check for null, undefined target crashes the app
        boundariesElement={boundariesElement || 'window'}
        hideArrow={!showArrow}
        delay={0}
        modifiers={{
          flip: { behavior: [placement || 'bottom-start'] },
          offset: { offset }
        }}
      >
        <div onClick={this.stopPropagation}>{children}</div>
      </ReactstrapPopover>
    );
  }
}
export default onClickOutside(Popover);
