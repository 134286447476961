import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import {
  getRowValuesForEntityType,
  resetTempRef,
  enteredFieldIsTrue,
  isValidBooleanString
} from './utils';

import { RowValidatorWithRequiredAdditionalParams } from 'CsvImportsModule/types';

interface AdditionalParams {
  billablePhaseContractTypesByType: Record<string, string>;
  nonBillablePhaseContractTypesByType: Record<string, string>;
  // { projectTitle: { projectNumber: { phaseName: phaseContractType } } }
  temporaryPhaseContractTypeOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>;
}

export const phaseContractTypeValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const {
    billablePhaseContractTypesByType,
    nonBillablePhaseContractTypesByType,
    temporaryPhaseContractTypeOption
  } = additionalParams;

  const {
    enteredProjectTitle,
    enteredProjectNumber,
    enteredPhaseName,
    enteredPhaseContractType,
    enteredPhaseBillable
  } = getRowValuesForEntityType(row, entityType);

  let res: string | boolean = true;

  const invalidPhaseBillable = !isValidBooleanString(enteredPhaseBillable);

  if (
    !enteredProjectTitle ||
    !enteredProjectNumber ||
    !enteredPhaseContractType ||
    invalidPhaseBillable
  ) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else if (!enteredProjectNumber) res = 'Project number is required';
    else if (invalidPhaseBillable) res = ERROR_MESSAGES.phaseBillableRequired;
    else res = ERROR_MESSAGES.phaseContractTypeRequired;
    resetTempRef(temporaryPhaseContractTypeOption, isLastRow);
    return res;
  }

  const enteredPhaseContractTypeIsBillable =
    billablePhaseContractTypesByType[enteredPhaseContractType];
  const enteredPhaseContractTypeIsNonBillable =
    nonBillablePhaseContractTypesByType[enteredPhaseContractType];

  const invalidPhaseContractType =
    !enteredPhaseContractTypeIsBillable &&
    !enteredPhaseContractTypeIsNonBillable;

  if (enteredPhaseContractType === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else if (invalidPhaseContractType) {
    res = ERROR_MESSAGES.invalidPhaseContractType;
  } else {
    const enteredPhaseIsBillable = enteredFieldIsTrue(enteredPhaseBillable);
    const isValidPhaseContractTypeBasedOnBillable =
      (!enteredPhaseBillable &&
        (enteredPhaseContractTypeIsBillable ||
          enteredPhaseContractTypeIsNonBillable)) ||
      (enteredPhaseIsBillable && enteredPhaseContractTypeIsBillable) ||
      (!enteredPhaseIsBillable && enteredPhaseContractTypeIsNonBillable);
    // first checks if it is valid phase contract type based on phaseBillable
    if (isValidPhaseContractTypeBasedOnBillable) {
      const matchedContractTypeInTempHash =
        temporaryPhaseContractTypeOption.current[enteredProjectTitle]?.[
          enteredProjectNumber
        ]?.[enteredPhaseName];
      // This is the first row of this project number + project title + phase name combination
      // set this enteredPhaseContractType to be the only option for this combination.
      if (matchedContractTypeInTempHash === undefined) {
        temporaryPhaseContractTypeOption.current = {
          ...temporaryPhaseContractTypeOption.current,
          [enteredProjectTitle]: {
            ...temporaryPhaseContractTypeOption.current[enteredProjectTitle],
            [enteredProjectNumber]: {
              ...temporaryPhaseContractTypeOption.current[
                enteredProjectTitle
              ]?.[enteredProjectNumber],
              [enteredPhaseName]: enteredPhaseContractType
            }
          }
        };
        // This combination already has a contract type set above, check if enteredPhaseContractType matches
      } else if (matchedContractTypeInTempHash !== enteredPhaseContractType) {
        res = ERROR_MESSAGES.phaseContractTypeDoesNotMatch;
      }
    } else {
      // enteredPhaseContractType is not valid based on phaseBillable
      res = ERROR_MESSAGES.invalidPhaseContractTypeBasedOnBillable;
    }
  }
  resetTempRef(temporaryPhaseContractTypeOption, isLastRow);
  return res;
};
