import { createReducer, Draft } from '@reduxjs/toolkit';
import * as constants from 'appConstants';
import {
  generateTeamSamlInfoActionCreatorsMap,
  resetPasswordActionCreatorsMap,
  setAuthTokenFetchSuccessDetails,
  setIsAcceptedTerms
} from 'AuthenticationModule/actionCreators';
import { GenerateTeamSamlInfoSuccessResponse } from 'AuthenticationModule/api/types';
import { AccountMfaStatus, Realm } from 'AuthenticationModule/types';
import every from 'lodash/every';
import * as settingsConstants from 'SettingsModule/constants';

export interface AuthState {
  token: Nullable<string>;
  account: Nullable<{
    id: number;
    slug: string;
    email: string;
  }>;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isImpersonating: boolean;
  isExchangingToken: boolean;
  wasAuthenticated: boolean;
  statusText: Nullable<string>;
  trialExpired: boolean;
  resetPasswordEmailSent: Nullable<boolean>;
  accountsMfaStatus: Record<number, AccountMfaStatus>;
  teamSamlInfo: Nullable<GenerateTeamSamlInfoSuccessResponse>;
  realm: Nullable<Realm>;
  isAcceptedTerms: boolean;
}

const initialState: AuthState = {
  token: null,
  account: null,
  isAuthenticated: false,
  isAuthenticating: false,
  isImpersonating: false,
  isExchangingToken: false,
  wasAuthenticated: false,
  statusText: null,
  trialExpired: false,
  resetPasswordEmailSent: false,
  accountsMfaStatus: {},
  teamSamlInfo: null,
  realm: null,
  isAcceptedTerms: true
};

const handleLogout = () => {
  return initialState;
};

const handleLoginRequest = (state: Draft<AuthState>) => {
  state.isAuthenticating = true;
  state.statusText = null;
};

const handleInviteValidationSuccess = (state: Draft<AuthState>, action) => {
  state.token = action.payload.response.auth_token;
  state.isAuthenticated = false;
  state.account = action.payload.response.account;
};

const handleLoginSuccess = (state: Draft<AuthState>, action) => {
  state.isAuthenticating = false;
  state.isAuthenticated = true;
  state.wasAuthenticated = true;
  state.token = action.payload.response.auth_token;
  state.account = action.payload.response.account;
  state.statusText = null;
};

const handleLoginFailure = (state: Draft<AuthState>, action) => {
  const { error } = action.payload;
  state.isAuthenticating = false;
  state.isAuthenticated = false;
  state.token = null;
  state.account = null;
  state.realm = null;
  state.statusText =
    error || `That email and password combination isn't valid..`;
};

const handlePasswordForgotSuccess = (state: Draft<AuthState>) => {
  state.resetPasswordEmailSent = true;
};

const handlePasswordForgotFailure = (state: Draft<AuthState>, action) => {
  state.statusText = action.payload.error;
};

const handlePasswordForgotFormReset = (state: Draft<AuthState>) => {
  state.resetPasswordEmailSent = null;
};

const handlePasswordSettingsUpdateSuccess = (
  state: Draft<AuthState>,
  action
) => {
  state.token = action.payload.response.auth_token;
  state.account = action.payload.response.account;
};

const handleProfileUpdateSuccess = (state: Draft<AuthState>, action) => {
  const { account, hasCompany } = action.payload.response;

  state.isAuthenticated = every([
    state.token,
    account.first_name,
    account.last_name,
    hasCompany
  ]);
};

const handleSetAuthTokenFetchSuccessDetails = (
  state: Draft<AuthState>,
  action: ReturnType<typeof setAuthTokenFetchSuccessDetails>
) => {
  const { isImpersonating, token, realmId } = action.payload;

  state.account = null;
  state.isAuthenticating = false;
  state.isAuthenticated = true;
  state.wasAuthenticated = true;
  state.isImpersonating = isImpersonating;
  state.isExchangingToken = false;
  state.token = token;

  // building realm URL with realmId is happened in the saga
  if (realmId) {
    state.realm = {
      id: realmId
    };
  } else {
    state.realm = null;
  }
};

const handleExchangeAccessToken = (state: Draft<AuthState>) => {
  state.isExchangingToken = true;
};

const handleFetchUserSuccess = (state: Draft<AuthState>, action) => {
  const { account } = action.payload.response;

  state.account = {
    id: account.id,
    email: account.email,
    slug: account.slug
  };
};

const handleGetAuthMfaStatusPerAccountSuccess = (
  state: Draft<AuthState>,
  action
) => {
  const { accounts_mfa_status } = action.payload.response;

  state.accountsMfaStatus = {
    ...state.accountsMfaStatus,
    ...accounts_mfa_status
  };
};

const handleGenerateSamlInfoSuccess = (
  state: Draft<AuthState>,
  action: ReturnType<typeof generateTeamSamlInfoActionCreatorsMap.success>
) => {
  state.teamSamlInfo = action.payload.response;
};

const handleSetIsAcceptedTerms = (
  state: Draft<AuthState>,
  action: ReturnType<typeof setIsAcceptedTerms>
) => {
  state.isAcceptedTerms = action.payload;
};

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(constants.LOGOUT_USER_INVITE, handleLogout);
  builder.addCase(constants.LOGOUT_USER, handleLogout);
  builder.addCase(constants.LOGIN.REQUEST, handleLoginRequest);
  builder.addCase(
    constants.INVITE_VALIDATION.SUCCESS,
    handleInviteValidationSuccess
  );
  builder.addCase(constants.LOGIN.SUCCESS, handleLoginSuccess);
  builder.addCase(constants.LOGIN.FAILURE, handleLoginFailure);
  builder.addCase(
    resetPasswordActionCreatorsMap.success,
    handlePasswordForgotSuccess
  );
  builder.addCase(
    resetPasswordActionCreatorsMap.failure,
    handlePasswordForgotFailure
  );
  builder.addCase(
    constants.PASSWORD_FORGOT_FORM_RESET,
    handlePasswordForgotFormReset
  );
  builder.addCase(
    constants.PASSWORD_SETTINGS_UPDATE.SUCCESS,
    handlePasswordSettingsUpdateSuccess
  );
  builder.addCase(constants.PROFILE_UPDATE.SUCCESS, handleProfileUpdateSuccess);
  builder.addCase(
    setAuthTokenFetchSuccessDetails,
    handleSetAuthTokenFetchSuccessDetails
  );
  builder.addCase(constants.EXCHANGE_ACCESS_TOKEN, handleExchangeAccessToken);
  builder.addCase(constants.USER.SUCCESS, handleFetchUserSuccess);
  builder.addCase(
    settingsConstants.GET_AUTH_MFA_STATUS_PER_ACCOUNT.SUCCESS,
    handleGetAuthMfaStatusPerAccountSuccess
  );
  builder.addCase(
    generateTeamSamlInfoActionCreatorsMap.success,
    handleGenerateSamlInfoSuccess
  );
  builder.addCase(setIsAcceptedTerms, handleSetIsAcceptedTerms);
});
