import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';

const byId = (item) => item.id;

export const initialState = {
  loaded: false,
  isLoading: false,
  newGroupId: null,
  workGroups: {},
  workGroupsOrder: [],
  workGroupMemberships: {}
};

const workGroups = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_WORK_GROUPS.TRIGGER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case constants.FETCH_WORK_GROUPS.FAILURE: {
      return {
        ...state,
        loaded: true,
        isLoading: false
      };
    }

    case constants.FETCH_WORK_GROUPS.SUCCESS: {
      const { work_groups } = payload.response;
      return {
        ...state,
        loaded: true,
        isLoading: false,
        workGroupsOrder: work_groups.map(byId),
        workGroups: {
          ...state.workGroups,
          ...keyBy(work_groups, byId)
        },
        workGroupMemberships: {
          ...state.workGroupMemberships,
          ...work_groups.reduce((acc, cur) => {
            acc[cur.id] = cur.work_group_memberships;
            return acc;
          }, {})
        }
      };
    }
    case constants.FETCH_WORK_GROUP.SUCCESS: {
      const { work_group } = payload.response;
      return {
        ...state,
        workGroups: { ...state.workGroups, [work_group.id]: work_group },
        workGroupMemberships: {
          ...state.workGroupMemberships,
          [work_group.id]: work_group.work_group_memberships
        }
      };
    }
    case constants.CREATE_WORK_GROUP.SUCCESS: {
      const { work_group } = payload.response;
      return {
        ...state,
        newGroupId: work_group.id,
        workGroupsOrder: [work_group.id, ...state.workGroupsOrder],
        workGroups: {
          ...state.workGroups,
          [work_group.id]: work_group
        }
      };
    }
    case constants.DELETE_WORK_GROUP.TRIGGER: {
      return {
        ...state,
        newGroupId: null
      };
    }
    case constants.UPDATE_WORK_GROUP.TRIGGER: {
      const { id, ...rest } = payload;
      return {
        ...state,
        newGroupId: null,
        workGroups: {
          ...state.workGroups,
          [id]: {
            ...state.workGroups[id],
            ...rest
          }
        }
      };
    }

    case constants.UPDATE_WORK_GROUP.SUCCESS: {
      const { work_group } = payload.response;
      return {
        ...state,
        workGroups: {
          ...state.workGroups,
          [work_group.id]: work_group
        }
      };
    }

    default:
      return state;
  }
};

export default workGroups;
