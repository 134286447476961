import { DefaultIsoState } from './types';

/**
 * Initial value for an isolated state that handles nested grouped records
 */
export const defaultInitialIsoState: DefaultIsoState = {
  dataHash: {},
  ordersByGroup: {},
  topLevelOrder: [],
  totalCount: 0,
  totalFetchedCount: 0,
  groupTotalCounts: {},
  selectedIds: {},
  numSelected: 0,
  shouldReset: false
};
