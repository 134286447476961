import React from 'react';
import { connect } from 'react-redux';
import {
  updateProjectPriority,
  deleteProjectPriority,
  createProjectPriority
} from 'actionCreators';
import { getMatchedRouteParams } from 'selectors';
import { withRouter } from 'react-router-dom';
import OptionsSelectFlyoutListItem from 'components/OptionsSelectFlyout/OptionsSelectFlyoutListItem';
import {
  StyledProjectPriorityContainer,
  StyledProjectPriorityIcon,
  StyledProjectPriorityText,
  StyledProjectPriorityHeaderIcon
} from './styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
const staticNewPriority = {
  title: 'New Priority',
  color: '#cccccc',
  id: 'new'
};
const unselectPriority = {
  title: 'None',
  color: '#D9D9D9',
  id: null
};

const priorityCopy = {
  inputPlaceholder: 'New Priority',
  addHeader: 'Add Priority',
  editHeader: 'Edit Priority'
};

class ProjectPriorityListItem extends React.Component {
  handleSave = (updatedState) => {
    const {
      createProjectPriority,
      updateProjectPriority,
      handleEditClear,
      listItem,
      isNew,
      matchedParams: { boardId }
    } = this.props;
    const payload = {
      ...listItem,
      board_id: boardId,
      ...updatedState
    };
    const apiCall = isNew ? createProjectPriority : updateProjectPriority;
    apiCall(payload);
    handleEditClear();
  };

  renderListItem = (item) => (
    <>
      <StyledProjectPriorityContainer>
        {item === unselectPriority ? (
          <StyledProjectPriorityHeaderIcon currentColor={'#808080'} />
        ) : (
          <StyledProjectPriorityIcon currentColor={item.color} />
        )}

        <StyledProjectPriorityText>
          {item.title === 'Medium' ? 'Med' : item.title}
        </StyledProjectPriorityText>
      </StyledProjectPriorityContainer>
    </>
  );

  render() {
    const { deleteProjectPriority, isUnselect } = this.props;
    return (
      <OptionsSelectFlyoutListItem
        handleSave={this.handleSave}
        handleDelete={deleteProjectPriority}
        colorPickerOriginType={ORIGIN_TYPE_STRINGS.PROJECT_PRIORITY}
        staticNewListItem={isUnselect ? unselectPriority : staticNewPriority}
        copy={priorityCopy}
        renderListItem={this.renderListItem}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateProjectPriority,
  deleteProjectPriority,
  createProjectPriority
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectPriorityListItem)
);
