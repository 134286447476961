import integrities from './integrities.json';

const loadCalendly = () => {
  const body = document.getElementsByTagName('body')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.integrity = integrities.calendly;
  script.crossOrigin = 'anonymous';
  body.appendChild(script);
};

export const initCalendly = () => {
  if (process.env.NODE_ENV === 'production') {
    loadCalendly();
  }
};
