import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { useClientNamesFilter } from 'FilterModule/hooks/useClientNamesFilter';
import { FilterListTypeDropdown } from 'FilterModule/types';

export const ClientNamesFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const { updateSelectedItems, optionsArray, selectedItems, isLoading } =
    useClientNamesFilter();

  return (
    <SimpleFilterDropdown
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isBatchClearOnly
      labels={labels}
      itemHeight={55}
      listWidth={250}
      listHeight={220}
      renderToggle={renderToggle}
      isLoading={isLoading}
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

const labels = {
  searchPlaceholder: 'Type Phase or select below'
};
