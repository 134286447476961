import React, { Component } from 'react';
import moment from 'moment';
import CalendarTimePicker from './CalendarTimePicker';

const TimeSelectContainer = ({
  handleChange,
  initialTime,
  placeholder,
  width = 80
}) => {
  const handleTimeChange = (value) => {
    handleChange(value);
  };

  return (
    <div style={{ width: width }}>
      <CalendarTimePicker
        className="calendar-time-picker "
        value={initialTime}
        placeholder={placeholder}
        onChange={handleTimeChange}
      />
    </div>
  );
};

export default TimeSelectContainer;
