import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchRegions } from 'SettingsModule/actionCreators/settings/region';
import {
  getIsRegionsLoaded,
  getIsLoadingRegions
} from 'SettingsModule/selectors/regions';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const emptyObj = {};

export const useLoadRegions = ({
  isOff,
  shouldAutoLoad = true
}: {
  isOff?: boolean;
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const isLoaded = useAppSelector(getIsRegionsLoaded);
  const isLoading = useAppSelector(getIsLoadingRegions);

  const loadRegions = useCallback(() => {
    if (!isLoaded && !isLoading && teamId) {
      dispatch(fetchRegions({ teamId }));
    }
  }, [dispatch, isLoaded, teamId, isLoading]);

  useEffect(() => {
    if (!isOff && shouldAutoLoad) {
      loadRegions();
    }
  }, [isOff, loadRegions, shouldAutoLoad]);

  return {
    isLoaded,
    isLoading,
    loadRegions
  };
};
