import { fork, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { conditionalTakeLatest } from 'sagas/helpers';

import * as constants from '../constants';
import {
  fetchUtilizationsWorker,
  fetchUtilizationsReportWorker,
  fetchUtilizationsReportTotalsWorker,
  fetchMembersUtilizationReportWorker,
  fetchTeamUtilizationReportWorker,
  fetchProjectUtilizationReportWorker,
  fetchPhaseUtilizationReportWorker,
  fetchTeamHistoricalUtilizationWorker
} from './utilizations';

export default function* capacitySaga() {
  const capacitySagas = [
    yield fork(
      takeEvery,
      constants.FETCH_UTILIZATIONS.TRIGGER,
      fetchUtilizationsWorker
    ),
    yield fork(
      takeLatest,
      constants.FETCH_UTILIZATIONS_REPORT.TRIGGER,
      fetchUtilizationsReportWorker
    ),

    yield fork(
      takeEvery,
      constants.FETCH_UTILIZATIONS_REPORT_TOTALS.TRIGGER,
      fetchUtilizationsReportTotalsWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_UTILIZATION_REPORT.TRIGGER,
      fetchTeamUtilizationReportWorker
    ),
    yield fork(
      conditionalTakeLatest,
      constants.FETCH_MEMBERS_UTILIZATION_REPORT.TRIGGER,
      fetchMembersUtilizationReportWorker,
      undefined, // use default
      ['filterStateId']
    ),
    yield fork(
      takeEvery,
      constants.FETCH_PROJECT_UTILIZATION_REPORT.TRIGGER,
      fetchProjectUtilizationReportWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_PHASE_UTILIZATION_REPORT.TRIGGER,
      fetchPhaseUtilizationReportWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_TEAM_HISTORICAL_UTILIZATION.TRIGGER,
      fetchTeamHistoricalUtilizationWorker
    )
  ];
  yield all(capacitySagas);
}
