import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import { NO_PROJECT } from 'appConstants/planners';
import { makeGetPlannerTask, getTeamMembersHash } from 'selectors';
import { TaskCardAssignee } from './styles';
import { ProjectTitleCircle } from 'views/Home/Tasks/styles';
import { getTruncatedDescription } from 'appUtils';
import { isOverdue, isPastDueDate } from 'appUtils/plannerUtils';
import { isToday } from 'appUtils/momentUtils';
import flagGray from 'images/flag-solid-gray.svg';
import dueDateFlag from 'images/due-date-flag.svg';
import TaskCardIcons from 'views/taskDisplay/dayPlannerComponents/TaskCardIcons';

const ReadOnlyProjectTitle = ({ task }) => (
  <div
    className={cn('project-menu-container', {
      show: !!task.project_name
    })}
  >
    <div>
      <ProjectTitleCircle projectId={task.project_id} />
      <span className="project-menu-button">{task.project_name}</span>
      <div
        className={cn('dropdown-arrow', {
          show: false
        })}
      />
    </div>
  </div>
);

const ReadOnlyDateDisplay = ({ task }) => {
  const overdue = isPastDueDate(task);
  const dateDisplayText = isToday(task.due_at)
    ? 'Today'
    : moment(task.due_at).format('MMM D');
  return (
    <div className="task-date-container due-container">
      <div className="task-date">
        <img className="flag show-flag" src={flagGray} />
        <a
          data-tip={'Completed Date'}
          className={cn('due-date', {
            overdue,
            'no-date': !task.due_at,
            today: isToday(task.due_at)
          })}
        >
          <img className="due-date-flag" src={dueDateFlag} />
          {dateDisplayText}
        </a>
      </div>
    </div>
  );
};

const ReadOnlyDescription = ({ task }) => {
  const { truncatedDescription } = getTruncatedDescription({
    fullText: task.description,
    singleLineCutoff: 35,
    lastLineCutoff: 20,
    numLines: 3
  });

  return (
    <div className="task-description">
      <div className="description-wrapper">
        {truncatedDescription}
        <TaskCardIcons task={task} className="task-icons-container" />
      </div>
    </div>
  );
};

const ReadOnlyTaskCard = ({
  task,
  teamMembersHash,
  unscheduled,
  enableEditing
}) => {
  const assignee = task.assignee_id && teamMembersHash[task.assignee_id];
  const scheduleOverdue = isOverdue(task);
  const wrapperClass = cn('daily-task', {
    completed: task.completed_at,
    'no-project': task.project_name === NO_PROJECT
  });

  return (
    <div
      className={wrapperClass}
      id={`read-only-daily-task-${task.id}`}
      onMouseEnter={enableEditing}
    >
      <div className="custom-card-content">
        <div className="top-row">
          {task.project_name && <ReadOnlyProjectTitle task={task} />}
          {task.due_at && (
            <div className="due-date">
              {!task.due_at && 'Due '}
              <ReadOnlyDateDisplay task={task} />
            </div>
          )}
        </div>
        <ReadOnlyDescription task={task} />
        <div className={cn('bottom-row', { unscheduled })}>
          <div
            className={cn('check-circle', { completed: task.completed_at })}
          />
          {unscheduled && (
            <TaskCardAssignee
              isAssigned={!!assignee}
              assignedToMe={assignee && assignee.isLoggedInUser}
            >
              {assignee ? assignee.initials : ''}
            </TaskCardAssignee>
          )}
          {!unscheduled && (
            <div
              className={cn('schedule-date', { overdue: scheduleOverdue })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const makeMapStateToProps = () => {
  const getPlannerTask = makeGetPlannerTask();
  const mapStateToProps = (state, ownProps) => {
    return {
      task: getPlannerTask(state, ownProps.taskId),
      teamMembersHash: getTeamMembersHash(state)
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(React.memo(ReadOnlyTaskCard));
