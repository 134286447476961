import { Draft, createReducer } from '@reduxjs/toolkit';
import { TimelinesState } from './types';
import { TimelineView, VisibleTimeRange } from '../types/timelineView';
import {
  addTimelineView,
  removeTimelineView,
  updateTimelineView
} from '../actionCreators/timelineViews';
import { getInitialVisibleRanges } from './utils';

const initialState: TimelinesState = {
  timelineViews: {}
};

const handleAddTimelineView = (
  state: Draft<TimelinesState>,
  action: ReturnType<typeof addTimelineView>
) => {
  const visibleTimeRange: VisibleTimeRange = getInitialVisibleRanges({
    visibleTimeStart: action.payload.initialVisibleTimeStart
  });

  const newTimeline: TimelineView = {
    id: action.payload.id,
    type: action.payload.type,
    visibleTimeRange
  };

  state.timelineViews[action.payload.id] = newTimeline;
};

const handleRemoveTimelineView = (
  state: Draft<TimelinesState>,
  action: ReturnType<typeof removeTimelineView>
) => {
  delete state.timelineViews[action.payload.id];
};

const handleUpdateTimelineView = (
  state: Draft<TimelinesState>,
  action: ReturnType<typeof updateTimelineView>
) => {
  const timelineView = state.timelineViews[action.payload.id];

  if (timelineView) {
    Object.assign(timelineView, action.payload);
  }
};

export const timelines = createReducer(initialState, (builder) => {
  builder.addCase(addTimelineView, handleAddTimelineView);
  builder.addCase(removeTimelineView, handleRemoveTimelineView);
  builder.addCase(updateTimelineView, handleUpdateTimelineView);
});
