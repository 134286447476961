import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
// https://momentjs.com/docs/#/customization/
moment.updateLocale('en', {
  week: {
    dow: 1 // use monday for start of week instead of english default sunday
  }
});

export default moment;
