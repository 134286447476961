import React from 'react';
import { Modal } from 'reactstrap';
import CloseIcon from 'icons/CloseIcon';
import {
  CloseIconContainer,
  DeleteModalTitleContainer,
  DeleteModalTitle
} from './styles';

const TaskBatchActionsModal = ({
  isOpen,
  toggle,
  handleConfirmation,
  title,
  content,
  renderModalTitle,
  hideClose,
  buttonRow,
  titleClassName = '',
  modalClassName = ''
}) => {
  const defaultButtonRow = (
    <div className="delete-modal-footer">
      <div className="delete-modal-footer-yes">
        <button onClick={handleConfirmation}>Yes</button>
      </div>
      <div className="delete-modal-footer-cancel">
        <button onClick={toggle}>Cancel</button>
      </div>
    </div>
  );
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`delete-modal ${modalClassName}`}
        autoFocus={false}
      >
        <DeleteModalTitleContainer className="delete-modal-title">
          <DeleteModalTitle className={titleClassName}>
            {title || renderModalTitle?.()}
          </DeleteModalTitle>
          <CloseIconContainer onClick={toggle} isHidden={hideClose}>
            <CloseIcon height="10px" width="10px" fill="#b3b3b3" />
          </CloseIconContainer>
        </DeleteModalTitleContainer>
        <div className="delete-modal-body">{content}</div>
        {buttonRow || defaultButtonRow}
      </Modal>
    </div>
  );
};

export default TaskBatchActionsModal;
