import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import { makeGetIsFetchingUtilizationReport } from 'UtilizationModule/selectors';
import TimesheetStatusWidgetPieChart from './TimesheetStatusWidgetPieChart';
import { generateDateRange, DATE_RANGES } from 'appUtils/dateRangeHelpers';
import withFilter from 'hocs/withFilter';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { WidgetChartContainer } from '../../styles';

const emptyArray = [];

const TimesheetStatusWidgetTwo = ({
  activeFilter,
  widgetConfig,
  isDashboardWidget
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const teamId = useSelector(getSelectedTeamId);

  const getIsFetchingUtilizationReport = useMemo(
    () => makeGetIsFetchingUtilizationReport(),
    []
  );
  const isFirstFetching = useSelector((state) =>
    getIsFetchingUtilizationReport(state, {
      filterStateId: `${activeFilter.id}--first-range`
    })
  );
  const isSecondFetching = useSelector((state) =>
    getIsFetchingUtilizationReport(state, {
      filterStateId: `${activeFilter.id}--second-range`
    })
  );

  const sharedFetchParams = useMemo(
    () => ({
      account_ids: activeFilter.account_ids || emptyArray,
      show_status_totals: true,
      team_id: teamId,
      initial: true
    }),
    [activeFilter.account_ids, teamId]
  );

  // last week
  const fetchParams1 = useMemo(
    () => ({
      ...sharedFetchParams,
      ...generateDateRange({ range: DATE_RANGES.LAST_WEEK }),
      filterStateId: `${activeFilter.id}--first-range`
    }),
    [activeFilter.id, sharedFetchParams]
  );

  // this week
  const fetchParams2 = useMemo(
    () => ({
      ...sharedFetchParams,
      ...generateDateRange({ range: DATE_RANGES.START_OF_WEEK_TO_TODAY }),
      filterStateId: `${activeFilter.id}--second-range`
    }),
    [activeFilter.id, sharedFetchParams]
  );

  useEffect(() => {
    if (!isFirstFetching && !isSecondFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFirstFetching, isSecondFetching]);

  const isLoading =
    (!hasInitiallyLoaded || isFirstFetching || isSecondFetching) &&
    isDashboardWidget;

  return (
    <div className="workload-widget-wrapper has-custom-description">
      {isLoading && (
        <SkeletonLoader
          numLoaders={1}
          style={{
            margin: 0,
            position: 'relative',
            ...(widgetConfig
              ? {
                  width: '100%',
                  marginTop: '-18px'
                }
              : {
                  paddingLeft: 12,
                  width: '97px',
                  right: '6px',
                  height: '28px'
                })
          }}
          loaderStyle={{ height: 200, rx: 4 }}
        />
      )}
      <WidgetChartContainer display={isLoading ? 'none' : 'flex'}>
        <TimesheetStatusWidgetPieChart
          header="Last Week"
          fetchParams={fetchParams1}
        />
        <TimesheetStatusWidgetPieChart
          header="This Week"
          fetchParams={fetchParams2}
        />
      </WidgetChartContainer>
    </div>
  );
};

export default withFilter(React.memo(TimesheetStatusWidgetTwo));
