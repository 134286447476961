import * as constants from '../constants';
import { createAction } from 'appUtils';

export const createHoliday = (params) =>
  createAction(constants.CREATE_HOLIDAY.TRIGGER, params);
export const fetchHolidays = (params) =>
  createAction(constants.FETCH_HOLIDAYS.TRIGGER, params);
export const updateHoliday = (params) =>
  createAction(constants.UPDATE_HOLIDAY.TRIGGER, params);
export const deleteHoliday = (params) =>
  createAction(constants.DELETE_HOLIDAY.TRIGGER, params);

export const openHolidayModal = (params) =>
  createAction(constants.OPEN_HOLIDAY_MODAL, params);
export const closeHolidayModal = () =>
  createAction(constants.CLOSE_HOLIDAY_MODAL);
