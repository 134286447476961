import { callApi } from 'appUtils/api';
import { LeanApiSchema } from './types';

const BASE_ENDPOINT = 'lean_api';

export const fetchLeanApi = (token: string, body: LeanApiSchema) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`${BASE_ENDPOINT}`, token, fetchInit);
};
