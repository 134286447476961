import React, { useState, useRef, useMemo, useEffect } from 'react';
import theme from 'theme';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  DependencyOption,
  DependencyDropdownContainer,
  DependencyMenuHeader,
  StyledBackKaratContainer,
  DependencyItemName,
  DependencyItemDate
} from './styles';
import { getDependencyTaskArray } from 'selectors';
import EllipsisText from 'components/EllipsisText';
import BackKaratIcon from 'icons/BackKarat';
import { DEPENDENCY_STRINGS_DISPLAY } from 'appConstants/workload';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { fetchTasksV2 } from 'actionCreators';
import ContentLoader from 'react-content-loader';
import DependencyOptions from './DependencyOptions';
import FilledArrow from 'icons/FilledArrow';
import DependencyRow from './DependencyRow';

const CompletedTasksToggle = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
  cursor: pointer;
  margin: auto;
  margin-left: 15px;
`;

const ArrowContainer = styled.div`
  transform: ${(props) =>
    props.showCompletedTasks ? 'rotate(180deg)' : 'none'};
  margin-right: ${(props) => (props.showCompletedTasks ? '7px' : '0px')};
  margin-left: ${(props) => (props.showCompletedTasks ? '-12px' : '-2px')};
`;

const TaskDependencySetter = ({
  item,
  disableDependency,
  dependency,
  dependencyStepItem,
  dependencyItem,
  dependencyItemType,
  setDependencyStepItem,
  handleSetDependency,
  listItemContainerStyle = {},
  dropdownStyles = {},
  clearDependency
}) => {
  const byId = (item) => item.id;
  const [dependencyMenuIsOpen, setDependencyMenuIsOpen] = useState(false);
  const dependencyPopoverTarget = useRef(null);
  const dispatch = useDispatch();
  const taskCount = useSelector((state) => state.homeTasks.taskCount);
  const tasks = useSelector(getDependencyTaskArray);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);

  const noop = () => {};

  useEffect(() => {
    dispatch(
      fetchTasksV2({
        body: {
          scheduled_start: true,
          project_ids: [item.project_id],
          all: true,
          archived: false
        },
        taskListType: 'dependency'
      })
    );
  }, [item.project_id, dispatch]);

  const nonArchivedTasks = useMemo(() => {
    return tasks
      ? tasks
          .filter(
            (task) =>
              task &&
              !task.is_archived &&
              task.schedule_start &&
              task.project_id === item.project_id &&
              (showCompletedTasks || !task.is_completed)
          )
          .sort((a, b) => {
            // put completed tasks at the bottom of the list if showing
            return a.is_completed && b.is_completed ? 1 : -1;
          })
      : [];
  }, [tasks, item, showCompletedTasks]);

  const loadingState = (
    <div style={{ width: '100%' }}>
      <ContentLoader
        height="340"
        primaryColor="#f3f3f3"
        secondaryColor="#eee"
        style={{ width: '100%', marginTop: '-20px' }}
        viewBox="0 0 400 340"
      >
        <rect x="0" y="5" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="70" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="135" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="200" rx="2" ry="2" width="100%" height="60" />
        <rect x="0" y="265" rx="2" ry="2" width="100%" height="60" />
      </ContentLoader>
    </div>
  );

  const getCopy = () => {
    return {
      headerInitial: 'Select Task',
      searchPlaceholder: 'Search or Select',
      emptyState: loadingState
    };
  };

  const renderItem = (props) => {
    const { item } = props;
    if (item.id === 'completedTasksToggle') {
      return renderCompletedTasksToggle();
    }
    const key = item.id;
    const isDisabled = !item.schedule_start && !item.isClear;
    const text = item.description;
    const description = item.description;
    const isValidStart = !!item.schedule_start;
    const date = `${item.schedule_start}
      ${item.schedule_end ? ' - ' + item.schedule_end : ''}`;
    return (
      <DependencyRow
        key={key}
        isDisabled={isDisabled}
        text={text}
        description={description}
        isValidStart={isValidStart}
        date={date}
      />
    );
  };

  const renderHeader = () => {
    const copy = getCopy();
    return <DependencyMenuHeader>{copy.headerInitial}</DependencyMenuHeader>;
  };

  const renderCompletedTasksToggle = () => {
    return (
      <CompletedTasksToggle>
        <ArrowContainer showCompletedTasks={showCompletedTasks}>
          <FilledArrow />
        </ArrowContainer>
        {showCompletedTasks ? 'Hide ' : 'Show '} Completed Tasks
      </CompletedTasksToggle>
    );
  };

  const handleSelect = (e, { item, selectCallback }) => {
    if (item.isClear) {
      clearDependency();
      closeDependencyMenu();
    } else if (item.isCompletedTasksToggle) {
      setShowCompletedTasks(!showCompletedTasks);
    } else {
      setDependencyStepItem(item);
    }
  };
  const closeDependencyMenu = () => {
    setDependencyMenuIsOpen(false);
  };

  const getItemHeight = () => {
    return 58;
  };

  const itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: [
        'title',
        'description',
        'project_title',
        'project_description',
        'project_number',
        'client',
        'phaseTitles'
      ]
    });

  const itemHeight = 81;
  const offset = nonArchivedTasks.length;

  const hasNextPage = () => {
    return offset < taskCount;
  };

  const noDependencyItem = {
    id: 'noDependency',
    description: 'No Dependency',
    isClear: true
  };

  const completedTasksToggle = {
    id: 'completedTasksToggle',
    description: '',
    isCompletedTasksToggle: true
  };

  return (
    <>
      <DependencyDropdownContainer
        onClick={() => {
          setDependencyMenuIsOpen(true);
        }}
        ref={dependencyPopoverTarget}
        data-testid="calendar-dependency-dropdown"
        style={dropdownStyles}
      >
        <EllipsisText width={245}>
          {!dependencyItem || !dependency
            ? 'No Dependency'
            : `${DEPENDENCY_STRINGS_DISPLAY[dependency]} of ${dependencyItem.description}`}
        </EllipsisText>
      </DependencyDropdownContainer>
      {dependencyMenuIsOpen && !dependencyStepItem && (
        <MultiStepFlyout
          copy={getCopy()}
          items={[
            noDependencyItem,
            ...nonArchivedTasks.filter((task) => task.id !== item.id),
            completedTasksToggle
          ]}
          getItemId={byId}
          idKey="id"
          renderItem={renderItem}
          renderHeader={renderHeader}
          handleSelect={handleSelect}
          selectCallback={closeDependencyMenu}
          handleSubmit={null}
          itemFilter={itemFilter}
          getItemHeight={getItemHeight}
          isWhite
          searchEnabled={true}
          editDisabled
          top={0}
          target={dependencyPopoverTarget}
          handleClose={closeDependencyMenu}
          loadMoreItems={noop}
          loadInitialItems={noop}
          hasNextPage={hasNextPage}
          totalCount={taskCount}
          hideFooter={true}
          itemHeight={itemHeight}
          listItemContainerStyle={listItemContainerStyle}
          emptyContainerClassName="projects-then-phases-dropdown-empty-container"
          popoverClassName={'dependency-list'}
          listWidth="324"
          listHeight={280}
        />
      )}
      {dependencyMenuIsOpen && dependencyStepItem && (
        <DependencyOptions
          disableDependency={disableDependency}
          dependency={dependency}
          dependencyStepItemStartDate={dependencyStepItem.schedule_start}
          dependencyStepItemEndDate={dependencyStepItem.schedule_end}
          dependencyStepItemName={dependencyStepItem.description}
          dependencyStepItem={dependencyStepItem}
          setDependencyStepItem={setDependencyStepItem}
          handleSetDependency={handleSetDependency}
          dependencyMenuIsOpen={dependencyMenuIsOpen}
          dependencyPopoverTarget={dependencyPopoverTarget}
          closeDependencyMenu={closeDependencyMenu}
        />
      )}
    </>
  );
};

export default TaskDependencySetter;
