import { Modal } from 'reactstrap';
import styled from 'styled-components';
import { Space } from 'components/Space';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { TextButtonWithBorder, BlueSubmitButton } from 'components/styles';
import { CountryDropdown } from './CountryDropdown';
import { LocationAttributes } from 'SettingsModule/models/location';
import { BaseLocationFieldStyles } from './styles';
import { OfficeSelectDropdown } from './OfficeSelectDropdown';
import { Office } from 'SettingsModule/models/office';
import { LocationAttributesUtils } from 'SettingsModule/utils/locationUtils';
import { useAppSelector } from 'reduxInfra/hooks';
import { getOfficesArray } from 'SettingsModule/selectors/offices';

type CreateUpdateEntityLocationModalProps = {
  headerText: string;
  failureText?: string;
  initialLocationAttributes?: LocationAttributes;
  initialOfficeName?: string;
  onToggle: () => void;
  onDelete?: () => void;
  onLocationAttributesChanged: () => void;
} & (
  | {
      officeNameInputMethod: 'input';
      onSubmit: ({
        locationAttributes,
        officeName
      }: {
        locationAttributes: LocationAttributes;
        officeName: string;
      }) => void;
    }
  | {
      officeNameInputMethod: 'select';
      onSubmit: ({
        locationAttributes,
        officeId
      }: {
        locationAttributes: LocationAttributes;
        officeId: number | undefined;
      }) => void;
    }
);

export const CreateUpdateEntityLocationModal = (
  props: CreateUpdateEntityLocationModalProps
) => {
  const {
    headerText,
    failureText,
    initialLocationAttributes,
    initialOfficeName,
    onToggle,
    onDelete,
    onLocationAttributesChanged
  } = props;

  const [isAttemptedSubmit, setIsAttemptedSubmit] = useState(false);
  const [officeName, setOfficeName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [aptSuite, setAptSuite] = useState('');
  const [country, setCountry] = useState('United States');
  const [city, setCity] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zipCode, setZipCode] = useState('');
  const officesArray = useAppSelector(getOfficesArray);

  const setLocationAttributes = useCallback(
    (locationAttributes: LocationAttributes) => {
      const { address_1, address_2, country, city, state, zip } =
        locationAttributes;

      if (address_1) setStreetAddress(address_1);
      if (address_2) setAptSuite(address_2);
      if (country) setCountry(country);
      if (city) setCity(city);
      if (state) setStateValue(state);
      if (zip) setZipCode(zip);
    },
    []
  );

  const setOfficeFieldsFromOffice = useCallback(
    (office: Office) => {
      if (office.location) {
        setLocationAttributes(office.location);
      }

      if (office.name) setOfficeName(office.name);
    },
    [setLocationAttributes]
  );

  useEffect(() => {
    if (initialLocationAttributes) {
      setLocationAttributes(initialLocationAttributes);
    }

    if (initialOfficeName) {
      setOfficeName(initialOfficeName);
    }
  }, [
    initialLocationAttributes,
    initialOfficeName,
    setLocationAttributes,
    setOfficeFieldsFromOffice
  ]);

  const officeMatchingInputtedLocationAttributes = useMemo(() => {
    const locationBeingInputted: LocationAttributes = {
      address_1: streetAddress,
      address_2: aptSuite,
      city,
      country,
      state: stateValue,
      zip: zipCode
    };

    const officeMatchingLocationAttributes = officesArray.find((office) =>
      office.location
        ? LocationAttributesUtils.isTwoLocationAttributesSame(
            office.location,
            locationBeingInputted
          )
        : false
    );

    return officeMatchingLocationAttributes;
  }, [
    aptSuite,
    city,
    country,
    officesArray,
    stateValue,
    streetAddress,
    zipCode
  ]);

  const inputValidations = useMemo(() => {
    return {
      officeName: {
        isValid:
          props.officeNameInputMethod === 'select' ||
          officeName.trim().length > 0
      },
      city: {
        isValid: city.trim().length > 0
      },
      stateValue: {
        isValid: stateValue.trim().length > 0
      },
      zipCode: {
        isValid: zipCode.trim().length > 0
      }
    };
  }, [city, officeName, props.officeNameInputMethod, stateValue, zipCode]);

  const isValid = useMemo(() => {
    const { officeName, city, stateValue, zipCode } = inputValidations;
    return (
      officeName.isValid &&
      city.isValid &&
      stateValue.isValid &&
      zipCode.isValid
    );
  }, [inputValidations]);

  const handleSubmit = () => {
    setIsAttemptedSubmit(true);

    if (isValid) {
      const locationAttributes = {
        address_1: streetAddress.trim(),
        address_2: aptSuite.trim(),
        city: city.trim(),
        country: country.trim(),
        state: stateValue.trim(),
        zip: zipCode.trim()
      };
      if (props.officeNameInputMethod === 'input') {
        props.onSubmit({
          locationAttributes,
          officeName
        });
      } else {
        props.onSubmit({
          locationAttributes,
          officeId: officeMatchingInputtedLocationAttributes?.id
        });
      }
    }
  };

  useEffect(() => {
    if (props.officeNameInputMethod === 'select') {
      setOfficeName(
        officeMatchingInputtedLocationAttributes
          ? officeMatchingInputtedLocationAttributes.name
          : ''
      );
    }
  }, [officeMatchingInputtedLocationAttributes, props.officeNameInputMethod]);

  const handleInputValueChange = (
    event: ChangeEvent<HTMLInputElement>,
    source: 'officeName' | 'address' | 'suite' | 'city' | 'state' | 'zipCode'
  ) => {
    switch (source) {
      case 'officeName': {
        setOfficeName(event.target.value);
        break;
      }
      case 'address': {
        setStreetAddress(event.target.value);
        break;
      }
      case 'suite': {
        setAptSuite(event.target.value);
        break;
      }
      case 'city': {
        setCity(event.target.value);
        break;
      }
      case 'state': {
        setStateValue(event.target.value);
        break;
      }
      case 'zipCode': {
        setZipCode(event.target.value);
        break;
      }
    }

    setIsAttemptedSubmit(false);
    onLocationAttributesChanged();
  };

  const handleSelectCountry = (country: string) => {
    setCountry(country);
    setIsAttemptedSubmit(false);
    onLocationAttributesChanged();
  };

  const handleSelectOffice = (office: Office) => {
    setIsAttemptedSubmit(false);
    setOfficeFieldsFromOffice(office);
  };

  return (
    <StyledModal isOpen toggle={onToggle}>
      <HeadingText>{headerText}</HeadingText>

      <Space value={30} vertical />

      <InputLabel>Office</InputLabel>
      {props.officeNameInputMethod === 'input' ? (
        <LocationFieldInput
          value={officeName}
          placeholder="Type new office name"
          $hasError={
            isAttemptedSubmit ? !inputValidations.officeName.isValid : false
          }
          onChange={(e) => handleInputValueChange(e, 'officeName')}
        />
      ) : (
        <OfficeSelectDropdown
          selectedOfficeName={officeName}
          onSelect={handleSelectOffice}
        />
      )}

      <Space value={14} vertical />

      <InputLabel>Street Address</InputLabel>
      <LocationFieldInput
        value={streetAddress}
        placeholder="Type Street Address"
        $hasError={false}
        onChange={(e) => handleInputValueChange(e, 'address')}
      />

      <Space value={14} vertical />

      <InputLabel>Apt, Suite, Etc</InputLabel>
      <LocationFieldInput
        value={aptSuite}
        placeholder="Type Apt, Suite, etc (Optional)"
        $hasError={false}
        onChange={(e) => handleInputValueChange(e, 'suite')}
      />

      <Space value={14} vertical />

      <InputLabel>Country</InputLabel>
      <CountryDropdown
        selectedCountry={country}
        hasError={false}
        onSelect={handleSelectCountry}
      />

      <Space value={14} vertical />

      <Row>
        <Column>
          <InputLabel>City</InputLabel>
          <CityZipCodeInput
            value={city}
            $hasError={
              isAttemptedSubmit ? !inputValidations.city.isValid : false
            }
            onChange={(e) => handleInputValueChange(e, 'city')}
          />
        </Column>
        <Space value={10} />
        <Column>
          <InputLabel>State</InputLabel>
          <StateInput
            value={stateValue}
            $hasError={
              isAttemptedSubmit ? !inputValidations.stateValue.isValid : false
            }
            onChange={(e) => handleInputValueChange(e, 'state')}
          />
        </Column>
        <Space value={10} />
        <Column>
          <InputLabel>Zip Code</InputLabel>
          <CityZipCodeInput
            value={zipCode}
            $hasError={
              isAttemptedSubmit ? !inputValidations.zipCode.isValid : false
            }
            onChange={(e) => handleInputValueChange(e, 'zipCode')}
          />
        </Column>
      </Row>
      {failureText && (
        <>
          <Space value={10} vertical />
          <ErrorText>{failureText}</ErrorText>
        </>
      )}

      <Space value={40} vertical />

      <Row style={{ justifyContent: 'space-between' }}>
        <DeleteButton
          $canDelete={!!initialLocationAttributes && !!onDelete}
          onClick={onDelete}
        >
          {initialLocationAttributes && onDelete ? 'Delete' : ''}
        </DeleteButton>
        <Row>
          <TextButtonWithBorder onClick={onToggle}>Cancel</TextButtonWithBorder>
          <Space value={8} />
          <BlueSubmitButton onClick={handleSubmit}>
            {initialLocationAttributes ? 'Done' : 'Create'}
          </BlueSubmitButton>
        </Row>
      </Row>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  height: 550px;
  width: 500px;

  .modal-content {
    padding: 40px 48px 48px 48px;
  }
`;

const HeadingText = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

const InputLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorMediumGray1};
  text-transform: uppercase;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const LocationFieldInput = styled.input<{ $hasError: boolean }>`
  ${(props) => BaseLocationFieldStyles({ $hasError: props.$hasError })};
`;

const CityZipCodeInput = styled(LocationFieldInput)`
  width: 150px;
`;

const StateInput = styled(LocationFieldInput)`
  width: 80px;
`;

const ErrorText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorCalendarRed};
`;

const DeleteButton = styled.div<{ $canDelete: boolean }>`
  ${(props) => !props.$canDelete && 'opacity: 0'};
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  font-size: 14px;
  ${(props) => props.$canDelete && 'cursor: pointer'};
  border-radius: 5px;
  border: 0.5px solid transparent;

  :hover {
    color: ${({ theme }) => theme.colors.colorCrimsonRed};
    border: 0.5px solid ${({ theme }) => theme.colors.colorCrimsonRed};
  }
`;
