import { MFA_STATUS } from 'AuthenticationModule/constants';
import { getTeamAuthSettings } from 'AuthenticationModule/selectors';
import { SupportedMfaStatus } from 'AuthenticationModule/types';
import { Radio } from 'components/Radio';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import {
  getAuthMfaStatusPerAccount,
  toggleIndividualMfa,
  updateTeamAuthSettings
} from 'SettingsModule/actionCreators/settings';
import { getMyUserId } from 'UsersModule/selectors';
import { fetchTeamMembers } from 'actions/actionCreators';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { MfaSetupModal } from 'AuthenticationModule/components/MfaSetupModal';

export const MfaSection = () => {
  const [isMfaSetupModalOpen, setIsMfaSetupModalOpen] = useState(false);
  const [isChangingToNewTeamMfaStatus, setIsChangingToNewTeamMfaStatus] =
    useState(false);

  const [isSetupMfaSuccess, setIsSetupMfaSuccess] = useState(false);

  const [
    isMfaToggleConfirmationModalOpen,
    setIsMfaToggleConfirmationModalOpen
  ] = useState(false);

  const [newTeamMfaStatus, setNewTeamMfaStatus] = useState<
    SupportedMfaStatus | undefined
  >(undefined);

  const dispatch = useAppDispatch();

  const { useSmsMfa } = useFeatureFlags();

  const authSettings = useAppSelector(getTeamAuthSettings);
  const myId = useAppSelector(getMyUserId);

  const handleChangeTeamMfaStatus = (
    newTeamMfaStatusRequested: SupportedMfaStatus
  ) => {
    setNewTeamMfaStatus(newTeamMfaStatusRequested);
    setIsMfaToggleConfirmationModalOpen(true);
  };

  const getTeamInformation = useCallback(() => {
    dispatch(fetchTeamMembers({ isGetTeamMemberMfa: true }));
  }, [dispatch]);

  const getOwnAuthMfaStatus = useCallback(() => {
    if (myId) {
      dispatch(
        getAuthMfaStatusPerAccount({
          accountIds: [myId]
        })
      );
    }
  }, [dispatch, myId]);

  const handleCloseMfaSetupModalRequest = useCallback(() => {
    getOwnAuthMfaStatus();
    setIsMfaSetupModalOpen(false);
  }, [getOwnAuthMfaStatus]);

  /**
   * we are using this useEffect to update the team-wide MFA status on successful setup of MFA of the logged in user
   * first since trying to update the team-wide MFA status in the `successMfaSetupVerifyEvent` listener on success of
   * setup of MFA of the logged in user has the value of `isTurningOnTeamWideMfa` to be undefined even though trying to
   * console.log the value of `isTurningOnTeamWideMfa` always shows either `true` or `false` upon closing of the modal,
   * which is weird
   */
  useEffect(() => {
    if (isSetupMfaSuccess && !!newTeamMfaStatus) {
      dispatch(
        updateTeamAuthSettings({
          teamMfaSetting: newTeamMfaStatus,
          meta: {
            onSuccess: () => {
              getTeamInformation();
              getOwnAuthMfaStatus();

              // reset to default values
              setIsSetupMfaSuccess(false);
              setNewTeamMfaStatus(undefined);
              setIsChangingToNewTeamMfaStatus(false);
            }
          }
        })
      );
    }
  }, [
    dispatch,
    getOwnAuthMfaStatus,
    getTeamInformation,
    isSetupMfaSuccess,
    newTeamMfaStatus
  ]);

  const successMfaSetupVerifyEvent = useCallback(
    (event: MessageEvent) => {
      if (event.origin === process.env.MOSAIC_AUTH_DOMAIN) {
        try {
          const eventData = JSON.parse(event.data);

          if (typeof eventData === 'object' && eventData.type) {
            const coercedEventData: {
              type: 'mfaVerifySuccess';
            } = eventData;

            if (coercedEventData.type === 'mfaVerifySuccess') {
              handleCloseMfaSetupModalRequest();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [handleCloseMfaSetupModalRequest]
  );

  useEffect(() => {
    window.addEventListener('message', successMfaSetupVerifyEvent);

    return () => {
      window.removeEventListener('message', successMfaSetupVerifyEvent);
    };
  }, [successMfaSetupVerifyEvent]);

  const accountsMfaStatus = useAppSelector(
    (state) => state.auth.accountsMfaStatus
  );

  const mfaStatusInfoOfCurrentUser = myId ? accountsMfaStatus[myId] : undefined;

  const isUserMfaDisabled =
    mfaStatusInfoOfCurrentUser?.user_mfa_status === MFA_STATUS.disabled;

  const isRequireSetupMFA =
    (mfaStatusInfoOfCurrentUser?.team_preferred_mfa === MFA_STATUS.totp &&
      mfaStatusInfoOfCurrentUser?.require_totp_reset === true) ||
    (mfaStatusInfoOfCurrentUser?.team_preferred_mfa === MFA_STATUS.sms &&
      mfaStatusInfoOfCurrentUser?.require_sms_reset === true);

  const isUserMfaNotFullyActive = isUserMfaDisabled || isRequireSetupMFA;

  const confirmationModalBodyText = `By continuing, you agree that you wish to change the multi-factor authentication team-wide status.${
    isUserMfaNotFullyActive
      ? ` Before you can do this, by continuing, ${
          isUserMfaDisabled
            ? 'your MFA will first be turned on.'
            : 'you will setup MFA for yourself first and you will be prompted to do so upon confirming.'
        }`
      : ''
  }`;

  const handleConfirmToggleTeamWideMfa = () => {
    setIsMfaToggleConfirmationModalOpen(false);
    setIsChangingToNewTeamMfaStatus(true);

    const onKnowUserMfaEnabled = () => {
      const onKnowReadyToSwitchToNewNonDisabledTeamMfaType = () => {
        // if check to satisfy TS even though is guaranteed to be true as of this writing
        if (newTeamMfaStatus) {
          dispatch(
            updateTeamAuthSettings({
              teamMfaSetting: newTeamMfaStatus,
              meta: {
                onSuccess: () => {
                  getTeamInformation();
                  setIsChangingToNewTeamMfaStatus(false);
                }
              }
            })
          );
        }
      };

      if (isRequireSetupMFA) {
        setIsMfaSetupModalOpen(true);
      } else if (newTeamMfaStatus) {
        // if the user not trying to turn off team-wide MFA and is instead trying to switch to one of the non-disabled team-wide MFA options, we must first disable MFA for the team before we can switch to the new non-disabled team-wide MFA option
        if (
          authSettings?.toggled_mfa !== MFA_STATUS.disabled &&
          newTeamMfaStatus !== MFA_STATUS.disabled
        ) {
          dispatch(
            updateTeamAuthSettings({
              teamMfaSetting: MFA_STATUS.disabled,
              meta: {
                onSuccess: onKnowReadyToSwitchToNewNonDisabledTeamMfaType
              }
            })
          );
        } else {
          onKnowReadyToSwitchToNewNonDisabledTeamMfaType();
        }
      }
    };

    if (isUserMfaDisabled && newTeamMfaStatus && myId) {
      dispatch(
        toggleIndividualMfa({
          accountIds: [myId],
          isEnableMfa: true,
          mfaType: newTeamMfaStatus,
          meta: {
            onSuccess: onKnowUserMfaEnabled
          }
        })
      );
    } else {
      onKnowUserMfaEnabled();
    }
  };

  const handleCancelToggleTeamWideMfa = () => {
    setNewTeamMfaStatus(undefined);
    setIsMfaToggleConfirmationModalOpen(false);
  };

  return (
    <>
      <RootContainer>
        <Title>Team-wide Multi-factor authentication</Title>

        <Column>
          <Row>
            <Radio
              value={MFA_STATUS.disabled}
              size={18}
              disabled={isChangingToNewTeamMfaStatus}
              checked={authSettings?.toggled_mfa === MFA_STATUS.disabled}
              onChange={() => handleChangeTeamMfaStatus(MFA_STATUS.disabled)}
            />
            <OptionLabelContainer>Disabled</OptionLabelContainer>
          </Row>
          <Row>
            <Radio
              value={MFA_STATUS.totp}
              size={18}
              disabled={isChangingToNewTeamMfaStatus}
              checked={authSettings?.toggled_mfa === MFA_STATUS.totp}
              onChange={() => handleChangeTeamMfaStatus(MFA_STATUS.totp)}
            />
            <OptionLabelContainer>
              {useSmsMfa ? 'Authentication App' : 'Enabled'}
            </OptionLabelContainer>
          </Row>
          {useSmsMfa && (
            <>
              <Row>
                <Radio
                  value={MFA_STATUS.sms}
                  size={18}
                  disabled={isChangingToNewTeamMfaStatus}
                  checked={authSettings?.toggled_mfa === MFA_STATUS.sms}
                  onChange={() => handleChangeTeamMfaStatus(MFA_STATUS.sms)}
                />
                <OptionLabelContainer>SMS</OptionLabelContainer>
              </Row>
              <Row>
                <Radio
                  value={MFA_STATUS.noPreference}
                  size={18}
                  disabled={isChangingToNewTeamMfaStatus}
                  checked={
                    authSettings?.toggled_mfa === MFA_STATUS.noPreference
                  }
                  onChange={() =>
                    handleChangeTeamMfaStatus(MFA_STATUS.noPreference)
                  }
                />
                <OptionLabelContainer>
                  Enabled with no type preference
                </OptionLabelContainer>
              </Row>
            </>
          )}
        </Column>
      </RootContainer>

      <SimpleConfirmModal
        isOpen={isMfaToggleConfirmationModalOpen}
        header={`Change Multi-Factor Authentication Team-Wide Status`}
        body={confirmationModalBodyText}
        shouldSetIsClosingOnClose={false}
        onConfirm={handleConfirmToggleTeamWideMfa}
        onCancel={handleCancelToggleTeamWideMfa}
      />

      <MfaSetupModal
        isOpen={isMfaSetupModalOpen}
        onToggle={handleCloseMfaSetupModalRequest}
      />
    </>
  );
};

const RootContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
  display: flex;
  align-items: center;
  width: 300px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const OptionLabelContainer = styled.span`
  margin-left: 5px;
`;
