import * as constants from 'appConstants';

export const initialState = {
  predictions: {}
};

const predictionHours = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case constants.FETCH_PREDICTION_HOURS.SUCCESS: {
      return {
        ...state,
        predictions: {
          ...state.predictions,
          ...payload.response.predictions
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default predictionHours;
