import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';

import theme from 'theme';
import Header from '../../WidgetParts/Header';
import Body from '../../WidgetParts/Body';
import UtilizationSummaryChart from './UtilizationSummaryChart';
import {
  getTheme,
  getMyUserId,
  getSelectedTeamId,
  getAccountFiltersFetched
} from 'selectors';
import { makeGetBillableHoursList } from 'BudgetModule/selectors';
import {
  makeGetTeamUtilizationChartDataTotals,
  makeGetMemberUtilizationTotals,
  makeGetIsFetchingUtilizationReport
} from 'UtilizationModule/selectors';
import { fetchMembersUtilizationReport } from 'UtilizationModule/actionCreators';
import {
  TimesheetChartContainer,
  TimesheetLegend,
  LegendContainer,
  projectGradient,
  LegendDivider
} from '../../styles';
import { UtilLegendBox, LegendText, Toggle, ToggleContainer } from './styles';

import pickBy from 'lodash/pickBy';

import {
  BILLABLE_FILTER_API_VALUES,
  CUSTOM_FILTER_VALUES
} from 'appConstants/filters';
import { GENERATE_UTILIZATION_RANGE } from 'appUtils/projectPlannerUtils';
import {
  fetchTimesheetsUtilization,
  updateAccountFilterLocal
} from 'actionCreators';
import { ZOOM_LEVELS, VIEW_BY } from 'appConstants/workload';
import { DATA_KEY } from 'UtilizationModule/constants';
import { defaultAddableWidgetsHash } from 'DashboardModule/constants/widgets';

import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

const emptyArray = [];

const getLegend = ({ showUtilizationByProjects, showPto, showHolidays }) => [
  {
    name: 'Billable',
    color: showUtilizationByProjects ? projectGradient : '#219653',
    value: 'billable'
  },
  {
    name: 'Not Billable',
    color: '#CDCDCD',
    value: 'nonbillable'
  },
  // Temporarily disables showing PTO and Holidays
  // {
  //   name: 'PTO',
  //   value: 'ptoForDisplay',
  //   color: showPto ? theme.gradients.pto : theme.gradients.disabledHatch
  // },
  // {
  //   name: 'Holidays',
  //   value: 'holidayForDisplay',
  //   color: showHolidays
  //     ? theme.gradients.holiday
  //     : theme.gradients.disabledHatch
  // },
  {
    name: 'Total Hours',
    color: 'transparent',
    value: 'total'
  }
];

const nameToShowParam = {
  PTO: 'showPto',
  Holidays: 'showHolidays'
};

const sumArray = (arr) => +arr.reduce((acc, cur) => +acc + +cur, 0).toFixed(2);
const getActiveFilterParams = (filter) =>
  pickBy(filter, (value) => Array.isArray(value) && value.length);

const getCustomFilterParams = (filter = {}) => {
  const { custom = {} } = filter;
  let customFilter = {};
  const {
    billable,
    range,
    showPto,
    showHolidays,
    showUtilizationByProjects,
    ...otherFilters
  } = custom;
  if (billable?.length === 1) {
    customFilter.billable = BILLABLE_FILTER_API_VALUES[billable[0]];
  }

  customFilter = {
    ...customFilter,
    show_pto: showPto,
    show_holidays: showHolidays,
    showUtilizationByProjects,
    ...GENERATE_UTILIZATION_RANGE[
      filter.custom?.range !== undefined
        ? filter.custom.range
        : ZOOM_LEVELS.WEEK
    ]?.({
      start: filter?.custom?.start_date,
      end: filter?.custom?.end_date
    })
  };
  return customFilter;
};

const UtilizationSummaryWidget = ({
  teamTotals,
  activeFilter,
  accountCapacitySum,
  chartOnly,
  chartWidth,
  chartHeight,
  innerRadius,
  outerRadius,
  cy,
  containerClassName,
  viewBy,
  pageName,
  isV2,
  chartLabel,
  widgetConfig,
  myId,
  teamId,
  utilizations,
  projectColors,
  billableHoursList,
  filtersFetched,
  isSelfLoading, // v2
  fetchParams, // v2
  isFetching, // v2,
  isDashboardWidgetModal,
  isFetchingProjectBreakdown,
  summaryChartFractionStyle,
  summaryChartLabelStyle
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);
  const dispatch = useDispatch();

  const { showPto, showHolidays } = activeFilter?.custom;

  const filterParams = useMemo(() => {
    const activeFilterParams = getActiveFilterParams(activeFilter);
    const customFilterParams = getCustomFilterParams(activeFilter);
    return { ...activeFilterParams, ...customFilterParams };
  }, [activeFilter]);

  // todo - figure out why this is rerendering
  const filterEquality = useMemo(
    () => JSON.stringify(filterParams),
    [filterParams]
  );

  const { showUtilizationByProjects } = filterParams;
  const isDashboardWidget = widgetConfig || isDashboardWidgetModal;
  const shouldUseProjectBreakdown =
    showUtilizationByProjects && !isDashboardWidget;

  useEffect(() => {
    if (
      isV2 &&
      isSelfLoading &&
      filtersFetched &&
      fetchParams.account_ids.length
    ) {
      dispatch(fetchMembersUtilizationReport(fetchParams));
    }
    // do not include fetchParams
  }, [dispatch, filterEquality, filtersFetched, isSelfLoading, isV2]);

  useEffect(() => {
    if (myId && filtersFetched && !isV2) {
      const body = {
        all: true,
        ...filterParams
      };
      dispatch(
        fetchTimesheetsUtilization({
          body
        })
      );
    }
    // refetch when timesheetHash changes to account for delete
    // do not include fetchParams
  }, [dispatch, myId, filterEquality, filtersFetched, isV2]);

  const getGroups = (utilizations) => {
    const billable = sumArray(
      utilizations.map((utilization) => utilization.billable)
    );
    const nonbillable = sumArray(
      utilizations.map((utilization) => utilization.nonbillable)
    );
    const total = billable + nonbillable;
    return {
      billable,
      nonbillable,
      total
    };
  };

  const getGroupsV2 = (teamTotals) => {
    const hasNoData =
      viewBy !== VIEW_BY.TEAM && // viewBy team is being used for Organization which doesn't need account_ids
      !(viewBy === VIEW_BY.ORGANIZATION && isDashboardWidgetModal) && // This is specific for Org Utilization widgets not to read account_ids
      !activeFilter.account_ids.length;

    return {
      billable: hasNoData ? 0 : teamTotals[DATA_KEY.BILLABLE] || 0,
      nonbillable: hasNoData ? 0 : teamTotals[DATA_KEY.NONBILLABLE] || 0,
      pto: hasNoData ? 0 : teamTotals[DATA_KEY.PTO] || 0,
      ptoForDisplay: hasNoData ? 0 : teamTotals[DATA_KEY.PTO_FOR_DISPLAY] || 0,
      holiday: hasNoData ? 0 : teamTotals[DATA_KEY.HOLIDAY] || 0,
      holidayForDisplay: hasNoData
        ? 0
        : teamTotals[DATA_KEY.HOLIDAY_FOR_DISPLAY] || 0,
      total: hasNoData ? 0 : teamTotals.total || 0
    };
  };

  const groups = useMemo(() => {
    return isV2 ? getGroupsV2(teamTotals) : getGroups(utilizations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeFilter.account_ids.length,
    isV2,
    teamTotals,
    utilizations,
    viewBy,
    showUtilizationByProjects,
    billableHoursList
  ]);

  const getItems = useCallback(() => {
    const dataGroups = [
      { name: 'pto', value: groups.pto, color: 'white' },
      { name: 'billable', value: groups.billable, color: '#219653' },
      { name: 'nonbillable', value: groups.nonbillable, color: '#CDCDCD' },
      { name: 'holiday', value: groups.holiday, color: 'white' }
    ];
    return dataGroups;
  }, [groups.billable, groups.holiday, groups.nonbillable, groups.pto]);

  const getItemsByProjects = useCallback(() => {
    const hasNoData = !activeFilter.account_ids.length;
    const dataGroups = billableHoursList
      .filter((accountProejct) => +accountProejct.billable > 0)
      .map((accountProject) => {
        const { billable, project_id } = accountProject;
        const project = {
          name: project_id,
          value: billable,
          color: projectColors[project_id] || '#CDCDCD'
        };

        return project;
      });

    return [
      {
        name: 'holiday',
        value: groups.holiday,
        color: 'white'
      },
      ...(hasNoData ? emptyArray : dataGroups),
      {
        name: 'nonbillable',
        value: groups.nonbillable,
        color: '#CDCDCD'
      },
      {
        name: 'pto',
        value: groups.pto,
        color: 'white'
      }
    ];
  }, [
    billableHoursList,
    groups.holiday,
    groups.pto,
    groups.nonbillable,
    projectColors,
    activeFilter.account_ids.length
  ]);

  const toggleFilter = (param) => {
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        name: viewBy,
        page: pageName,
        custom: {
          ...activeFilter.custom,
          [param]: !activeFilter.custom[param]
        }
      })
    );
  };

  const Legend = useMemo(
    () =>
      getLegend({
        showUtilizationByProjects: shouldUseProjectBreakdown,
        showPto,
        showHolidays
      }),
    [shouldUseProjectBreakdown, showPto, showHolidays]
  );

  const items = useMemo(
    () => (shouldUseProjectBreakdown ? getItemsByProjects() : getItems()),
    [getItems, getItemsByProjects, shouldUseProjectBreakdown]
  );

  const styles = containerClassName
    ? undefined
    : { display: 'flex', alignItems: 'flex-end' };

  useEffect(() => {
    if (!isFetching && !isFetchingProjectBreakdown && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFetching, isFetchingProjectBreakdown]);

  const isLoading = isV2
    ? isFetching || isFetchingProjectBreakdown
    : utilizations.length === 0 || false;

  return (
    <div style={styles} className={containerClassName}>
      <Body>
        <TimesheetChartContainer
          style={{
            padding: '0px 19px 30px',
            ...(widgetConfig && !isDashboardWidgetModal && { width: '100%' })
          }}
        >
          {!hasInitiallyLoaded || isLoading ? (
            <SkeletonLoader
              numLoaders={1}
              style={{
                margin: 0,
                position: 'relative',
                ...(widgetConfig && !isDashboardWidgetModal
                  ? {
                      width: '100%'
                    }
                  : {
                      paddingLeft: 12,
                      width: '97px',
                      right: '6px',
                      height: '28px'
                    })
              }}
              loaderStyle={{ height: 200, rx: 4 }}
            />
          ) : (
            <UtilizationSummaryChart
              items={items}
              percentBillable={
                Math.round((groups.billable / (groups.total || 1)) * 100) || 0
              }
              total={groups.total}
              billable={groups.billable}
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              cy={cy}
              chartLabel={chartLabel}
              shouldShowPaddingAngle={shouldUseProjectBreakdown}
              fractionStyle={summaryChartFractionStyle}
              labelStyle={summaryChartLabelStyle}
              isDashboardWidget={chartOnly}
            />
          )}
        </TimesheetChartContainer>
        {!chartOnly && (
          <TimesheetLegend
            style={{ flexDirection: 'column', alignItems: 'stretch' }}
          >
            {Legend.filter(
              (legend) =>
                (isV2 &&
                  !(
                    legend.value === 'pto' &&
                    widgetConfig?.disabledOptions?.[
                      CUSTOM_FILTER_VALUES.showPto
                    ]
                  ) &&
                  !(
                    legend.value === 'holiday' &&
                    widgetConfig?.disabledOptions?.[
                      CUSTOM_FILTER_VALUES.showHolidays
                    ]
                  )) ||
                (legend.value !== 'holiday' && legend.value !== 'pto')
            ).map(({ name, color, value }, index) => (
              <div key={index}>
                {value === 'total' && <LegendDivider />}
                <LegendContainer
                  key={name}
                  style={{
                    margin: '3px 0',
                    alignItems: 'stretch',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <>
                      <UtilLegendBox color={color} />
                      <LegendText
                        data-testid={`summary-value-${name}`}
                        style={{
                          textTransform: 'none',
                          fontWeight: 600,
                          color: `${
                            (name === 'PTO' && !showPto) ||
                            (name === 'Holidays' && !showHolidays)
                              ? '#828282'
                              : '#4f4f4f'
                          }`
                        }}
                      >
                        {groups[value]?.toLocaleString?.() ?? groups[value]}h
                      </LegendText>
                    </>
                  </div>
                  <div style={{ width: 91, display: 'flex' }}>
                    <LegendText
                      data-testid={`summary-label-${name}`}
                      uppercase
                      style={{ marginLeft: '10px' }}
                    >
                      <span>{name}</span>
                      {nameToShowParam[name] && (
                        <ToggleContainer
                          isOn={activeFilter.custom[nameToShowParam[name]]}
                          onClick={() => toggleFilter(nameToShowParam[name])}
                          data-testid={`${nameToShowParam[name]}-toggle`}
                        >
                          <Toggle />
                        </ToggleContainer>
                      )}
                    </LegendText>
                  </div>
                </LegendContainer>
              </div>
            ))}
          </TimesheetLegend>
        )}
      </Body>
    </div>
  );
};

const makeMapStateToProps = () => {
  const getIsFetching = makeGetIsFetchingUtilizationReport();
  const getBillableHourslist = makeGetBillableHoursList();

  const getDataByViewBy = {
    [VIEW_BY.MEMBERS]: makeGetMemberUtilizationTotals(),
    [VIEW_BY.ORGANIZATION]: makeGetTeamUtilizationChartDataTotals(),
    [VIEW_BY.TEAM]: makeGetTeamUtilizationChartDataTotals()
  };

  const mapStateToProps = (state, ownProps) => {
    const getTotalUtilization = getDataByViewBy[ownProps.viewBy];
    return {
      myId: getMyUserId(state),
      teamId: getSelectedTeamId(state),
      utilizations: state.timesheets.utilization,
      // billableHoursList should use the same filterStateId used for fetching Project breakdown view
      billableHoursList: getBillableHourslist(state, {
        filterStateId: ownProps.secondaryFilterStateId
      }),
      filtersFetched: getAccountFiltersFetched(state),
      teamTotals: getTotalUtilization(state, ownProps),
      isFetching: getIsFetching(state, ownProps),
      isFetchingProjectBreakdown: getIsFetching(state, {
        filterStateId: ownProps.secondaryFilterStateId
      }),
      projectColors: getTheme(state)?.projectColors
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(UtilizationSummaryWidget);
