import React, { useState } from 'react';
import styled from 'styled-components';
import {
  CancelButton,
  ConfirmButton,
  CustomButton,
  StyledSearchIcon
} from 'PermissionsModule/components/styles';
import PencilIconBlue from 'icons/PencilIconBlue';
import { PermissionsTableVariant } from 'PermissionsModule/components/PermissionsTable/types';
import theme from 'theme';

const PermissionsOptionsContainer = styled.div<{ width: number }>`
  margin: 20px 0;
  display: flex;
  width: ${(props) => props.width}px;
  justify-content: space-between;
`;

const PermissionsOptionsLeft = styled.div`
  display: flex;
  align-items: center;
`;
const PermissionsOptionsRight = styled.div``;

const FindButton = styled.div`
  padding: 0px 10px;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 13px;
`;

interface PermissionOptionsProps {
  isEditing: boolean;
  toggleEditing: (isEditing: boolean) => void;
  onSave: () => void;
  onCancel: () => void;
  variant?: PermissionsTableVariant;
}

export const PermissionsOptions = ({
  isEditing,
  toggleEditing,
  onSave,
  onCancel,
  variant
}: PermissionOptionsProps) => {
  return (
    <PermissionsOptionsContainer width={variant === 'projects' ? 900 : 800}>
      <PermissionsOptionsLeft>
        {/* Uncomment when building find and custom access levels */}
        {/* <FindButton>
          <StyledSearchIcon className="search-icon" /> Find
        </FindButton>
        <CustomButton>Create Access Level</CustomButton> */}
      </PermissionsOptionsLeft>
      <PermissionsOptionsRight>
        {!isEditing ? (
          <CustomButton onClick={() => toggleEditing(!isEditing)}>
            <PencilIconBlue
              className="permission-edit"
              fill={theme.colors.colorCalendarBlue}
            />{' '}
            Edit
          </CustomButton>
        ) : (
          <>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
            <ConfirmButton onClick={onSave}>Save</ConfirmButton>
          </>
        )}
      </PermissionsOptionsRight>
    </PermissionsOptionsContainer>
  );
};
