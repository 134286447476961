import styled from 'styled-components';
import BarBudget from 'views/projectPlanner/workloadBarModal/BarBudget';
import {
  SPENT_TOOLTIP,
  PLANNED_TOOLTIP,
  MEMBERS_REMAINING_TOOLTIP,
  TIME_MEMBER_BUDGET_TOOLTIP
} from 'appConstants';
import { BudgetTotals } from 'models/budgetTotals';
import {
  CellHeaderColorContainer,
  CellColorContainer,
  TertText
} from 'views/projectPlanner/workloadBarModal/styles';

interface BarBudgetInfoProps {
  budgetTotals: BudgetTotals;
  hourDiff: number;
}
export const BarBudgetContainer = ({
  budgetTotals,
  hourDiff
}: BarBudgetInfoProps) => {
  return (
    <BarBudgetInfoContainer>
      <BarBudget
        value={+budgetTotals.estimated_hours || 0}
        name="estimated_hours"
        header={'estimated'}
        data-html
        dataTip={TIME_MEMBER_BUDGET_TOOLTIP}
        hideHeader
      />
      <BarBudget
        value={+budgetTotals.spent_hours || 0}
        name="spent_hours"
        header={'spent'}
        data-html
        dataTip={SPENT_TOOLTIP}
        hideHeader
      />
      <BarBudget
        value={(+budgetTotals.planned_hours || 0) + hourDiff}
        name={'planned_hours'}
        header={'planned'}
        dataTip={PLANNED_TOOLTIP}
        hideHeader
      />
      <BarBudget
        value={
          +budgetTotals.remaining_hours
            ? +budgetTotals.remaining_hours - hourDiff
            : 0
        }
        showOverdue={+budgetTotals.estimated_hours > 0}
        name={'remaining_hours'}
        header={'remaining'}
        tooltipClass="work-plan-box-remaining-tooltip"
        dataTip={
          +budgetTotals.remaining_hours >= 0 || !budgetTotals.remaining_hours
            ? MEMBERS_REMAINING_TOOLTIP
            : 'Planned + Spent Time is over time estimated.'
        }
        hideHeader
      />
    </BarBudgetInfoContainer>
  );
};

const BarBudgetInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  ${CellColorContainer}, ${TertText} {
    font-size: 12px;
    min-height: 0;
    line-height: normal;
    margin: 0;
  }

  ${CellHeaderColorContainer} {
    padding-bottom: 0;
  }
`;
