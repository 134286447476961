import { put, select } from 'redux-saga/effects';
import { getAuthToken } from 'AuthenticationModule/selectors';
import {
  fetchCapacityPolicies,
  fetchCapacityPoliciesActionCreatorsMap,
  createCapacityPolicy,
  createCapacityPolicyActionCreatorsMap,
  updateCapacityPolicy,
  updateCapacityPolicyActionCreatorsMap,
  archiveCapacityPolicy,
  archiveCapacityPolicyActionCreatorsMap,
  fetchCapacityPoliciesPerMember,
  fetchCapacityPoliciesPerMemberActionCreatorsMap,
  fetchCapacityGroupMemberships,
  fetchCapacityGroupMembershipsActionCreatorsMap,
  createCapacityGroupMembership,
  createCapacityGroupMembershipActionCreatorsMap,
  updateCapacityGroupMembership,
  updateCapacityGroupMembershipActionCreatorsMap,
  deleteCapacityGroupMembership,
  deleteCapacityGroupMembershipActionCreatorsMap
} from 'CapacityModule/actionCreators/capacityGroup';
import { changeEntity as entityHelper } from 'sagas/generics';
import { capacityGroupApi } from 'CapacityModule/api/capacityGroup';
import { getSelectedTeamId } from 'TeamsModule/selectors';

function* fetchCapacityPoliciesWorker(
  action: ReturnType<typeof fetchCapacityPolicies>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchCapacityPoliciesActionCreatorsMap,
    capacityGroupApi.fetchCapacityPolicies,
    [token, action.payload],
    action
  );
}

function* createCapacityPolicyWorker(
  action: ReturnType<typeof createCapacityPolicy>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    createCapacityPolicyActionCreatorsMap,
    capacityGroupApi.createCapacityPolicy,
    [token, action.payload],
    action
  );
}

function* updateCapacityPolicyWorker(
  action: ReturnType<typeof updateCapacityPolicy>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    updateCapacityPolicyActionCreatorsMap,
    capacityGroupApi.updateCapacityPolicy,
    [token, action.payload],
    action
  );
}

function* archiveCapacityPolicyWorker(
  action: ReturnType<typeof archiveCapacityPolicy>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    archiveCapacityPolicyActionCreatorsMap,
    capacityGroupApi.archiveCapacityPolicy,
    [token, action.payload],
    action
  );
}

function* fetchCapacityPoliciesPerMemberWorker(
  action: ReturnType<typeof fetchCapacityPoliciesPerMember>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchCapacityPoliciesPerMemberActionCreatorsMap,
    capacityGroupApi.fetchCapacityPoliciesPerMember,
    [token, action.payload],
    action
  );
}

function* fetchCapacityGroupMembershipsWorker(
  action: ReturnType<typeof fetchCapacityGroupMemberships>
) {
  const token = yield select(getAuthToken);

  yield entityHelper(
    fetchCapacityGroupMembershipsActionCreatorsMap,
    capacityGroupApi.fetchCapacityGroupMemberships,
    [token, action.payload],
    action
  );
}

function* createCapacityGroupMembershipWorker(
  action: ReturnType<typeof createCapacityGroupMembership>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { payload } = action;
  const { teamMembershipIds } = payload;

  yield entityHelper(
    createCapacityGroupMembershipActionCreatorsMap,
    capacityGroupApi.createCapacityGroupMembership,
    [token, payload],
    action
  );

  yield teamMembershipIds.map((teamMembershipId) =>
    put(fetchCapacityGroupMemberships({ teamMembershipId }))
  );

  if (teamId) {
    yield put(fetchCapacityPoliciesPerMember({ teamId }));
  }
}

function* updateCapacityGroupMembershipWorker(
  action: ReturnType<typeof updateCapacityGroupMembership>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { payload, meta } = action;

  yield entityHelper(
    updateCapacityGroupMembershipActionCreatorsMap,
    capacityGroupApi.updateCapacityGroupMembership,
    [token, payload],
    action
  );

  if (meta) {
    yield put(
      fetchCapacityGroupMemberships({ teamMembershipId: meta.teamMembershipId })
    );
  }

  if (teamId) {
    yield put(fetchCapacityPoliciesPerMember({ teamId }));
  }
}

function* deleteCapacityGroupMembershipWorker(
  action: ReturnType<typeof deleteCapacityGroupMembership>
) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { payload, meta } = action;

  yield entityHelper(
    deleteCapacityGroupMembershipActionCreatorsMap,
    capacityGroupApi.deleteCapacityGroupMembership,
    [token, payload],
    action
  );

  if (meta) {
    yield put(
      fetchCapacityGroupMemberships({ teamMembershipId: meta.teamMembershipId })
    );
  }

  if (teamId) {
    yield put(fetchCapacityPoliciesPerMember({ teamId }));
  }
}

export default {
  fetchCapacityPoliciesWorker,
  createCapacityPolicyWorker,
  updateCapacityPolicyWorker,
  archiveCapacityPolicyWorker,
  fetchCapacityPoliciesPerMemberWorker,
  fetchCapacityGroupMembershipsWorker,
  createCapacityGroupMembershipWorker,
  updateCapacityGroupMembershipWorker,
  deleteCapacityGroupMembershipWorker
};
