import styled from 'styled-components';
import theme from 'theme';
import { RefObject } from 'react';
import useUnassignedRolesByProjectAndPhases, {
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition,
  ProjectUnassignedMemberBudgetWithPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import EllipsisText from 'components/EllipsisText';
import UnassignedMemberIcon from 'icons/UnassignedMemberIcon';

const emptyArr = [];

const emptyObj = {};

export type AddNewRow = {
  isAddNewRow: boolean;
  id: 'new';
};

const itemFilter = (
  item:
    | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
    | ProjectUnassignedMemberBudgetWithPosition,
  searchWords: string[]
) =>
  filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: ['position.nameWithCount']
  });

type Props = {
  targetRef: RefObject<unknown>;
  projectId: Nullable<number>;
  phaseId?: Nullable<number>;
  showPhaseUnassignedRoles?: boolean;
  handleSelect: (
    _,
    {
      item
    }: {
      item:
        | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
        | ProjectUnassignedMemberBudgetWithPosition
        | AddNewRow;
    }
  ) => void;
  handleClose: () => void;
};

const Item = ({
  item
}: {
  item:
    | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
    | ProjectUnassignedMemberBudgetWithPosition;
}) => {
  const { nameWithCount } = item.position;

  return (
    <StyledItemContainer>
      <UnassignedMemberIcon />
      <EllipsisText className="unassigned-role-name" maxWidth={300}>
        {nameWithCount}
      </EllipsisText>
    </StyledItemContainer>
  );
};

const renderHeader = () => {
  return (
    <HeaderContainer>
      <div>Select Unassigned Role</div>
    </HeaderContainer>
  );
};

const UnassignedRolesDropdown = ({
  projectId,
  phaseId,
  targetRef,
  showPhaseUnassignedRoles,
  handleSelect,
  handleClose
}: Props) => {
  const {
    unassignedMemberBudgetsWithPositionForProject,
    phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
  } = useUnassignedRolesByProjectAndPhases({
    projectId
  });

  const itemsToUse =
    (showPhaseUnassignedRoles
      ? Object.values(
          phaseMembershipsWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId[
            phaseId as number
          ] || emptyObj
        )
      : unassignedMemberBudgetsWithPositionForProject) || emptyArr;

  const renderItem = ({
    item
  }: {
    item:
      | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
      | ProjectUnassignedMemberBudgetWithPosition
      | AddNewRow;
  }) => {
    if ((item as AddNewRow).isAddNewRow) {
      return (
        <StyledItemContainer className="add-new-row">
          Add New
        </StyledItemContainer>
      );
    }

    if (phaseId && showPhaseUnassignedRoles) {
      return (
        <Item
          item={
            item as PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
          }
        />
      );
    }

    return <Item item={item as ProjectUnassignedMemberBudgetWithPosition} />;
  };

  const formattedItemsToUse = [{ isAddNewRow: true, id: 'new' }, ...itemsToUse];

  return (
    <MultiStepFlyout
      copy={{
        searchPlaceholder: 'Search unassigned role or select below'
      }}
      target={targetRef}
      items={formattedItemsToUse}
      idKey="id"
      renderHeader={renderHeader}
      renderItem={renderItem}
      handleSelect={handleSelect}
      handleClose={handleClose}
      hideFooter
      listWidth={400}
      isWhite
      listHeight={200}
      itemFilter={itemFilter}
      searchEnabled
      hideClose
      popoverClassName="unassigned-role-for-project-dropdown"
    />
  );
};

export default UnassignedRolesDropdown;

const HeaderContainer = styled.div``;

const StyledItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  .unassigned-role-name {
    padding-left: 10px;
  }

  &.add-new-row {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
