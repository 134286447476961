import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const memberImportEmailValidator: RowValidator = ({
  row,
  entityType
}) => {
  // required
  // can be any email
  const { enteredEmail } = getRowValuesForEntityType(row, entityType);

  if (enteredEmail === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (!enteredEmail) return ERROR_MESSAGES.emailIsRequired;
  else return true;
};
