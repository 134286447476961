import { useState } from 'react';
import styled from 'styled-components';
import { useSecurity } from '../hooks/useSecurity';
import {
  PreferredMfaMethodModal,
  mfaMethodContentMap
} from 'AuthenticationModule/components/PreferredMfaMethodModal';
import { SharedUiSection } from './SharedUiSection';

export const MfaSection = () => {
  const [isPreferredMfaMethodModalOpen, setIsPreferredMfaMethodModalOpen] =
    useState(false);

  const {
    isMfaActive,
    teamWideMfaSetting,
    disableMfaTeamwide,
    isChangingTeamMfaSetting,
    isMfaForciblyDisabled
  } = useSecurity();

  const mfaMethodContent = teamWideMfaSetting
    ? mfaMethodContentMap[teamWideMfaSetting]
    : undefined;

  const openPreferredMfaMethodModal = () => {
    setIsPreferredMfaMethodModalOpen(true);
  };

  return (
    <>
      <RootContainer>
        <SharedUiSection
          headerText="Multi-factor Authentication (MFA)"
          descriptionText="Protect your organization by requiring users to identify themselves by more than a password."
          confirmDeleteModalContextString="multi-factor authentication (MFA)"
          isForciblyDisabled={isMfaForciblyDisabled}
          isActive={isMfaActive}
          forciblyDisabledTooltip="Can't enable multi-factor authentication. Disable SAML 2.0 Single Sign On first."
          isUserActionDisabled={isChangingTeamMfaSetting}
          onRequestDisable={disableMfaTeamwide}
          onRequestEnable={openPreferredMfaMethodModal}
          onRequestEdit={openPreferredMfaMethodModal}
        />

        {mfaMethodContent && !isMfaForciblyDisabled && (
          <BodyContainer>
            <SelectedOptionHeader>
              {mfaMethodContent.header}
            </SelectedOptionHeader>
            <SelectedOptionBody>{mfaMethodContent.subtitle}</SelectedOptionBody>
          </BodyContainer>
        )}
      </RootContainer>
      <PreferredMfaMethodModal
        isOpen={isPreferredMfaMethodModalOpen}
        onToggle={() => setIsPreferredMfaMethodModalOpen(false)}
      />
    </>
  );
};

const RootContainer = styled.div`
  display: grid;
  gap: 24px;
`;

const BodyContainer = styled.div`
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.colorLightGray12};
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SelectedOptionHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const SelectedOptionBody = styled.div`
  font-size: 13px;
`;
