import { Draft, createReducer } from '@reduxjs/toolkit';
import { logoutUser } from 'actionCreators';
import { LeanApiIsoState, LeanApiState } from '../types';
import {
  fetchLeanApi,
  fetchLeanApiActionCreatorsMap,
  clearLeanApiIsoState
} from '../actionCreators';
import { defaultInitialIsoState } from 'IsoStatesModule/constants';
import { handleModifyIsoState } from 'IsoStatesModule/utils';
import omit from 'lodash/omit';
import { defaultIsoStateModifiers } from '../common/defaultIsoStateModifiers';

export const initialState: LeanApiState = {
  isoStates: {}
};

export const initialIsoState: LeanApiIsoState = {
  ...omit(defaultInitialIsoState, ['selectedIds', 'numSelected']),
  selectedAttributeCounts: {},
  dataHash: {
    partialProjects: {},
    partialPhases: {}
  }
};

/* ---------------------------------- Fetch --------------------------------- */

const handleFetchLeanApiTrigger = (
  state: Draft<LeanApiState>,
  action: ReturnType<typeof fetchLeanApi>
) => {
  const { isoStateId, isInitialFetch } = action.meta || {};

  if (isoStateId) {
    const isoState = state.isoStates[isoStateId];
    // Reset the isolated state if it's an initial fetch, or initialize if it doesn't exist yet
    if ((isoState && isInitialFetch) || !isoState) {
      state.isoStates[isoStateId] = initialIsoState;
    }
  }
};

const handleFetchWorkplanRequestsSuccess = (
  state: Draft<LeanApiState>,
  action: ReturnType<typeof fetchLeanApiActionCreatorsMap.success>
) => {
  const { schemaVariant } = action.payload.initialPayload;

  handleModifyIsoState({
    modifier:
      action.meta?.isoStateModifiers?.fetchLeanApiSuccess ||
      (schemaVariant &&
        defaultIsoStateModifiers.fetchLeanApiSuccess[schemaVariant]),
    state,
    meta: action.meta,
    initialPayload: action.payload.initialPayload,
    response: action.payload.response
  });
};

/* ----------------------------- Clear isoState ----------------------------- */

const handleClearLeanApiIsoState = (
  state: Draft<LeanApiState>,
  action: ReturnType<typeof clearLeanApiIsoState>
) => {
  const { isoStateId } = action.payload;

  delete state.isoStates[isoStateId];
};

/* -------------------------------- Builders -------------------------------- */

export const leanApiReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutUser, () => initialState);

  /* ---------------------------------- fetch --------------------------------- */
  builder.addCase(fetchLeanApi, handleFetchLeanApiTrigger);
  builder.addCase(
    fetchLeanApiActionCreatorsMap.success,
    handleFetchWorkplanRequestsSuccess
  );

  /* ---------------------------------- clear --------------------------------- */
  builder.addCase(clearLeanApiIsoState, handleClearLeanApiIsoState);
});
