import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MyTasksIcon = () => (
  <SvgIcon width="13" height="13" viewBox="0 0 13 13">
    <path
      fill="#0074D9"
      fillRule="evenodd"
      d="M12.144 1.428C11.005.34 9.858.966 8.747 2.024l-.671.648-.685.645.004-.014-.327.32-6.064 5.77c-1.112 1.057-.97 3.565-.97 3.565s2.63.132 3.741-.925l7.743-7.369c1.112-1.058 1.78-2.144.626-3.236zM9.468 5.289L8.089 3.971l.671-.648 1.393 1.322-.685.644zM10.82 4.01L9.44 2.692c.732-.708 1.265-1.348 2.036-.63.768.733.089 1.244-.657 1.948z"
    />
  </SvgIcon>
);

export default MyTasksIcon;
