import React from 'react';
import SvgIcon from 'components/SvgIcon';

const SelectIcon = ({ currentColor = '#9b9b9b', className }) => (
  <SvgIcon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="5.5" y="1.5" width="2" height="2" stroke={currentColor} />
    <rect x="5.5" y="5.5" width="2" height="2" stroke={currentColor} />
    <rect x="5.5" y="9.5" width="2" height="2" stroke={currentColor} />
  </SvgIcon>
);

export default SelectIcon;
