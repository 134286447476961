import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import { AddEditStatusContainer, StatusItem } from '..';

class StatusItemContainer extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, ['handleClick']);
  }

  handleClick(e) {
    this.props.setEditStatusId(this.props.status.id);
  }

  render() {
    const {
      status,
      projectId,
      me,
      canEdit,
      editStatusId,
      setEditStatusId,
      isOnProjectDetail,
      statusCount
    } = this.props;

    if (status.id === editStatusId) {
      return (
        <AddEditStatusContainer
          isAddForm={false}
          projectId={projectId}
          status={status}
          description={status.description}
          account={status.account}
          date={status.creation_date}
          statusId={status.id}
          updateShowItem={() => setEditStatusId(null)}
        />
      );
    } else {
      return (
        <StatusItem
          projectId={projectId}
          status={status}
          handleClick={this.handleClick}
          me={me}
          canEdit={canEdit}
          isOnProjectDetail={isOnProjectDetail}
          statusCount={statusCount}
        />
      );
    }
  }
}

StatusItemContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  status: PropTypes.object.isRequired,
  me: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
  editStatusId: PropTypes.number,
  setEditStatusId: PropTypes.func
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusItemContainer);
