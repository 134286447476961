import { createSelector, Selector } from '@reduxjs/toolkit';
import { CurrencyState } from '../types';
import { RootState, ReducerName } from 'reduxInfra/shared';
import { ValueOf } from 'type-fest';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const getCurrencyState: Selector<RootState, CurrencyState> = (state) =>
  state[ReducerName.Currency];

export const getDefaultCurrency = createSelector(
  getCurrencyState,
  (state) => state.defaultCurrency
);

/**
 * @deprecated Use the `useTeamCurrency` hook.
 */
export const getTeamCurrencyCodeByTeamId = createSelector(
  getDefaultCurrency,
  getSelectedTeamId,
  (defaultCurrency, teamId) => {
    const teamCurrencies = defaultCurrency.selectedTeam;

    if (!teamCurrencies || !teamId) return;

    const selectedTeamCurrency = teamCurrencies[teamId];
    return selectedTeamCurrency;
  }
);

const getOwnEntityInfo = (
  state,
  {
    entityType,
    entityId
  }: { entityType: ValueOf<typeof ENTITY_TYPES>; entityId: number }
) => ({
  entityType,
  entityId
});

/**
 * @deprecated Use the `useEntityCurrencyWithTeamFallback` hook.
 *
 * Deprecation note: This will also deprecate the above `getOwnEntityInfo`
 * selector.
 */
export const makeGetDefaultCurrencyCodeById = () => {
  return createSelector(
    getDefaultCurrency,
    getOwnEntityInfo,
    getTeamCurrencyCodeByTeamId,
    (defaultCurrency, entityInfo, teamCurrencyCode) => {
      const { entityType, entityId } = entityInfo;

      const entityTypeDefaultCurrencies = defaultCurrency[entityType];

      // If there is no currency set for this entity type, return the team currency
      if (!entityTypeDefaultCurrencies) return teamCurrencyCode;

      const entityDefaultCurrency = entityTypeDefaultCurrencies[entityId];
      return entityDefaultCurrency ?? teamCurrencyCode;
    }
  );
};
