import { ReactElement, cloneElement } from 'react';
import DownArrow from 'icons/QBDownArrow';
import MemberCircles from 'components/members/MemberCircles';
import MemberSelector from 'views/projectPlanner/workloadBarModal/MemberSelector';
import {
  SectionTitle,
  SelectContainer,
  OptionalLabel,
  SelectButton
} from './styles';
import { Project } from 'ProjectsModule/models/project';
import { MergeExclusive } from 'type-fest';

const noop = () => undefined;
const emptyArray = [];

interface DefaultDropdownProps {
  onSelect: (accountId: number) => void;
  onDone?: () => void;
  onBatchClear?: () => void;
  selectedIds?: Record<number, boolean>;
  accountId?: Nullable<number>;
  nameStyle?: string;
  project?: Pick<Project, 'id' | 'board_id'>;
}

// When not using the default dropdown component
interface OverrideDropdownProps {
  children: ReactElement;
}

type SelectMembersSectionProps = {
  sectionTitle: string;
  idOrder?: number[];
  isAssignMultiple?: boolean;
} & MergeExclusive<DefaultDropdownProps, OverrideDropdownProps>;

const SelectMembersSection = ({
  sectionTitle,
  onSelect,
  onDone = noop,
  onBatchClear,
  idOrder,
  selectedIds,
  isAssignMultiple,
  accountId,
  nameStyle,
  project,
  children
}: SelectMembersSectionProps) => {
  const isNoneSelected = isAssignMultiple
    ? !idOrder?.length
    : !accountId && accountId !== 0;

  const renderSelect = ({ onClick }: { onClick?: () => void }) => {
    return (
      <div onClick={onClick}>
        {isNoneSelected ? (
          <SelectButton>
            Select <DownArrow className="down-arrow" />
          </SelectButton>
        ) : (
          <MemberCircles accountIds={idOrder || emptyArray} maxVisible={7} />
        )}
      </div>
    );
  };

  return (
    <div className="modal-section">
      {sectionTitle && <SectionTitle>{sectionTitle}</SectionTitle>}
      <SelectContainer>
        {children ? (
          cloneElement(children, { renderSelect })
        ) : (
          <MemberSelector
            accountId={accountId}
            renderSelect={renderSelect}
            handleSelect={(_, accountId: number) => {
              onSelect && onSelect(accountId);
            }}
            projectId={project?.id}
            boardId={project?.board_id}
            joinModalText="assign to scope"
            includeUnassigned={!isNoneSelected}
            hideFooter
            isAssignMultiple={isAssignMultiple}
            selectedIds={selectedIds}
            idOrder={idOrder}
            handleBatchClear={onBatchClear}
            hideSelectAll
            nameStyle={nameStyle}
            handleDone={onDone}
            handleClose={onDone}
          />
        )}
        {isNoneSelected && <OptionalLabel>Optional</OptionalLabel>}
      </SelectContainer>
    </div>
  );
};

export default SelectMembersSection;
