import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { formatCurrencySymbol } from 'components/FormatCurrency/utils';
import { currencyFormatter } from 'appUtils/hooks/useFormatCurrency/utils';
import moment from 'moment';
// formats currency so that it only shows decimals if there is a decimal vaue
// ex: 12345 => $ 12,345  and 1234.56789 => $ 1234.56

/*
  @deprecated -- USE useFormatCurrency hook
*/
export const formatCurrency = (currencyString) => {
  if (!currencyString) return '$0';
  if (Number.isNaN(+currencyString)) return '';

  const withDecimals = parseFloat(currencyString).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD'
  });
  const decimalIndex = withDecimals.indexOf('.');
  return withDecimals.substr(decimalIndex, 3) === '.00'
    ? withDecimals.substring(0, decimalIndex)
    : withDecimals;
};

export const formatHours = (hours) => {
  const value = !hours || isNaN(hours) ? '0' : hours;
  return formatNumWithMaxOneDecimal(value) + 'h';
};

/**
 * @typedef {Object} CurrencyUtilResult
 * @property {string} currencyForDisplay - formatted currency for display
 * @property {string} currencyForTooltip - formatted currency for display in tooltip if currencyForDisplay is in compact notation
 * @property {boolean} showCurrencyInTooltip - boolean for displaying the currency in tooltip
 */

/**
 * Calculates formatted currency with currencyFormatter
 * @param {Object} currencyUtilInput inputs required to get formatted currency
 * @param {string} currencyUtilInput.currencyCode - 3 letter currency code
 * @param {number} currencyUtilInput.currency - currency that you want to format.
 * @param {number} currencyUtilInput.maxTextLength - max length of formatted currency string before compact notation.
 * @returns {CurrencyUtilResult} the formatted currency
 */

export const formatCurrencyByLength = ({
  currencyCode,
  currency,
  maxTextLength
}) => {
  const standardCurrencyFormatter = currencyFormatter({
    currencyCode,
    options: { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  });
  const currencyForTooltip = standardCurrencyFormatter.format(currency);

  let currencyForDisplay = currencyForTooltip;
  let showCurrencyInTooltip = false;

  if (currencyForTooltip.length > maxTextLength) {
    const compactCurrencyFormatter = currencyFormatter({
      currencyCode,
      options: {
        notation: 'compact'
      }
    });
    currencyForDisplay = compactCurrencyFormatter.format(currency);
    showCurrencyInTooltip = true;
  }

  return { currencyForDisplay, currencyForTooltip, showCurrencyInTooltip };
};

/**
 *
 * @param {string} 3 letter currency code
 * @returns {string} currency symbol of the given currency
 */

export const getCurrencySymbol = (currencyCode) => {
  const standardCurrencyFormatter = currencyFormatter({ currencyCode });

  return formatCurrencySymbol(standardCurrencyFormatter, 0);
};

const formatFetchDate = (date) => moment(date).format('YYYY-MM-DD');

/**
 * @param {Object} activityChartDateRangeInput inputs required to get date range for budget report api
 * @param {number} activityChartDateRangeInput.numOfSpentBars - number of spent bars to display
 * @param {number} activityChartDateRangeInput.numOfPlannedBars - number of planned bars to display
 * @returns {{start_date:string, end_date: string}} date range of past {numOfSpentBars} weeks, this week, and next {numOfPlannedBars - 1} weeks, in YYYY-MM-DD format for budget records
 */
export const getActivityChartDateRangeForFetch = ({
  numOfSpentBars,
  numOfPlannedBars
}) => {
  return {
    start_date: formatFetchDate(
      moment().subtract(numOfSpentBars, 'weeks').startOf('week')
    ),
    end_date: formatFetchDate(
      moment()
        .add(numOfPlannedBars - 1, 'weeks')
        .endOf('week')
    )
  };
};

/**
 * @param {Object} input input requried to get tooltip prop
 * @param {boolean} input.isTooltipDisabled disable tooltip
 * @param {string=} input.customTooltipContent custom tooltip content to override default tooltip content
 * @returns {Object} tooltip props for showing on uneditable fields when phase is archived
 */
export const getArchivedPhaseEditTooltipProps = ({
  isTooltipDisabled,
  customTooltipContent
}) => ({
  'data-for': 'app-tooltip',
  'data-effect': 'solid',
  'data-tip': customTooltipContent ?? 'Unarchive to Edit Phase',
  'data-html': true,
  'data-tip-disable': isTooltipDisabled
});
