import SvgIcon from 'components/SvgIcon';

const SmallDiamondIcon = ({
  className = '',
  width = '5',
  height = '6',
  viewBox = '0 0 5 6',
  fillColor = '#4F4F4F'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 2.62562L2.24946 0L0 2.62438L2.25054 5.25L4.5 2.62562Z"
      fill={fillColor}
    />
  </SvgIcon>
);

export default SmallDiamondIcon;
