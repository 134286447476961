import React from 'react';
import { connect } from 'react-redux';
import { getResponsiveStateGreaterThan } from 'selectors';

const ShowOnLargest = (props) =>
  (props.responsiveStateGreaterThan.large &&
    !!props.activeTaskColumns &&
    props.activeTaskColumns < 4) ||
  props.responsiveStateGreaterThan.extraLarge
    ? props.children
    : null;

const mapStateToProps = (state) => ({
  responsiveStateGreaterThan: getResponsiveStateGreaterThan(state)
});

export default connect(mapStateToProps)(React.memo(ShowOnLargest));
