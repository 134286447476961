import React from 'react';

import { StyledKaratRight, StyledKaratLeft, KaratContainer } from './styles';

const DateNavigator = ({ onClickLeft, onClickRight, children }) => {
  return (
    <div className="date-navigator">
      <KaratContainer onClick={onClickLeft}>
        <StyledKaratLeft height="25" width="16" />
      </KaratContainer>
      {children}
      <KaratContainer onClick={onClickRight}>
        <StyledKaratRight height="25" width="16" />
      </KaratContainer>
    </div>
  );
};

export default DateNavigator;
