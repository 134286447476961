import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import styled, { createGlobalStyle } from 'styled-components';
import { DoneButton } from 'CapacityModule/components/WorkloadModal/styles';
import EllipsisText from 'components/EllipsisText';
import { ComponentProps, MouseEvent, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import {
  formatISO,
  Interval,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfToday
} from 'date-fns';
import { Rate } from '../../models/Rate';
import { EntityId, EntityRate, EntityRateId } from '../../models/EntityRate';
import { RateGroupId } from '../../models/RateGroup';
import {
  clearEntityRateHistory,
  createEntityRate,
  fetchEntityRateHistory,
  updateEntityRate
} from 'RatesModule/ratesActionCreators';
import {
  getEntityRatesHistoryByRateGroup,
  getEntityRatesHistoryHash,
  getRateEntitiesHash,
  getRatesHash
} from 'RatesModule/ratesSelectors';
import FetchManager from 'classes/FetchManager';
import { CurrencyCode } from 'CurrencyModule/types';
import uuid from 'uuid';
import {
  COLUMN_CONFIG,
  DELETE_COLUMN_WIDTH,
  TABLE_CONFIG
} from './tableConfigs';
import { AddRateCell } from './Cells/AddRateCell';
import { BodyCell } from './Cells/BodyCell';
import {
  CellContentsProps,
  EntityRateModalColumn,
  EntityRateModalType
} from './types';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { useOpenerWithProps } from 'RatesModule/hooks/useOpenerWithProps';
import { DeleteEntityRateModal } from '../DeleteEntityRateModal';
import RatesDropdown from 'BudgetModule/components/BudgetModal/RatesDropdown';
import { fetchRates } from 'BudgetModule/actionCreators';
import { DATE_MAX, DATE_MIN } from 'appConstants/date';
import { DateChangeOvertakeConfirmationModal } from './DateChangeOvertakeConfirmationModal';
import { NewRateOvertakeConfirmationModal } from './NewRateOvertakeConfirmationModal';
import { useOpener } from 'RatesModule/hooks/useOpener';

const MODAL_CLASS = 'AddEditEntityRateModal';

const handleRenderHeaderButton = ({
  handleClose
}: {
  handleClose: () => void;
}) => <DoneButton onClick={handleClose}>Done</DoneButton>;

const isNewDateIntervalOvertakingOtherRate = (
  rates: ListItem[],
  newDateInterval: Interval,
  newEntityRateId?: EntityRateId
) =>
  rates.some(
    ({ entityRate: { id, end_date, start_date } }) =>
      id !== newEntityRateId &&
      isWithinInterval(
        start_date ? parseISO(start_date) : DATE_MIN,
        newDateInterval
      ) &&
      isWithinInterval(
        end_date ? parseISO(end_date) : DATE_MAX,
        newDateInterval
      )
  );

interface ListItem {
  entityRate: EntityRate;
  rate: Rate;
}

export const AddEditEntityRateModal = ({
  currencyCode,
  entityId,
  isCostRate,
  onClose,
  rateGroupId
}: {
  currencyCode: CurrencyCode;
  entityId: EntityId;
  isCostRate?: boolean;
  onClose: () => void;
  rateGroupId: RateGroupId;
}) => {
  const dispatch = useAppDispatch();

  const entityRatesHistoryByRateGroup = useAppSelector(
    getEntityRatesHistoryByRateGroup
  );
  const entityRatesHistoryHash = useAppSelector(getEntityRatesHistoryHash);
  const rateEntitiesHash = useAppSelector(getRateEntitiesHash);
  const ratesHash = useAppSelector(getRatesHash);
  const teamId = useAppSelector(getSelectedTeamId);

  const rates = useMemo(() => {
    const ratesByType =
      entityRatesHistoryByRateGroup?.[rateGroupId]?.[entityId];
    const ratesOrder =
      (isCostRate ? ratesByType?.costRates : ratesByType?.billRates) ?? [];

    return ratesOrder.reduce<ListItem[]>((acc, entityRateId) => {
      const entityRate = entityRatesHistoryHash?.[entityRateId];
      if (entityRate) {
        const rate = ratesHash?.[entityRate?.rate_id];
        if (rate) acc.push({ entityRate, rate });
      }

      return acc;
    }, []);
  }, [
    entityId,
    entityRatesHistoryByRateGroup,
    entityRatesHistoryHash,
    isCostRate,
    rateGroupId,
    ratesHash
  ]);

  const rateEntity = rateEntitiesHash?.[entityId];

  const { addLabel, columns, width } =
    TABLE_CONFIG[
      isCostRate ? EntityRateModalType.CostRate : EntityRateModalType.BillRate
    ];
  const columnWidths = columns.map(({ width }) => width);

  const handleRenderHeader = () => (
    <Header>
      <RateType>{isCostRate ? 'Cost Rate' : 'Bill Rate'} —&nbsp;</RateType>
      <EllipsisText>{rateEntity?.name ?? ''}</EllipsisText>
    </Header>
  );

  const {
    close: closeRatesModal,
    Component: RateModalComponent,
    isOpen: isRatesModalOpen,
    open: openRatesModal
  } = useOpenerWithProps<
    ComponentProps<typeof RatesDropdown>,
    'handleSelect',
    'handleClose'
  >(RatesDropdown, { onCloseProperty: 'handleClose' });

  const {
    Component: DeleteEntityRateModalComponent,
    isOpen: isDeleteEntityRateModalOpen,
    open: openDeleteEntityRateModal
  } = useOpenerWithProps<
    ComponentProps<typeof DeleteEntityRateModal>,
    'entityRate'
  >(DeleteEntityRateModal);

  const {
    Component: DateChangeConfirmationModalComponent,
    isOpen: isDateChangeConfirmationModalOpen,
    open: openDateChangeConfirmationModal
  } = useOpenerWithProps<
    ComponentProps<typeof DateChangeOvertakeConfirmationModal>,
    'newDateParams'
  >(DateChangeOvertakeConfirmationModal);

  const {
    Component: NewRateConfirmationModalComponent,
    isOpen: isNewRateConfirmationModalOpen,
    open: openNewRateConfirmationModal
  } = useOpener<ComponentProps<typeof NewRateOvertakeConfirmationModal>>(
    NewRateOvertakeConfirmationModal
  );

  const handleItemClick = ({
    columnId,
    entityRate
  }: {
    columnId: EntityRateModalColumn;
    entityRate: EntityRate;
  }) => {
    if (columnId === EntityRateModalColumn.Delete) {
      openDeleteEntityRateModal({ entityRate });
    } else if (
      ![
        EntityRateModalColumn.StartDate,
        EntityRateModalColumn.EndDate
      ].includes(columnId)
    ) {
      dispatch(fetchRates({ teamId }));

      openRatesModal({
        handleSelect: (...params: [MouseEvent, { item: Rate }]) =>
          handleSelectModalSelect(entityRate.id, ...params)
      });
    }
  };

  const handleAddRateClick = () => {
    dispatch(fetchRates({ teamId }));

    openRatesModal({
      handleSelect: (...params: [MouseEvent, { item: Rate }]) =>
        handleSelectModalSelect(undefined, ...params)
    });
  };

  const handleAddRateClickAttempt = () => {
    const newDateInterval = { start: startOfToday(), end: DATE_MAX };
    const isOvertaking = isNewDateIntervalOvertakingOtherRate(
      rates,
      newDateInterval
    );

    if (isOvertaking) openNewRateConfirmationModal();
    else handleAddRateClick();
  };

  const handleRenderTableBodyHeader = () => (
    <>
      <MiniHeaderContainer $columnWidths={columnWidths}>
        {columns.map(({ id }, index) => {
          const { header: Cell, label } = COLUMN_CONFIG[id];
          return (
            <Cell
              className={id}
              isCostRate={isCostRate}
              key={index}
              label={label}
            />
          );
        })}
      </MiniHeaderContainer>
      <AddRateCell
        onClick={handleAddRateClickAttempt}
        $isLastItem={!rates.length}
        $width={width}
      >
        {addLabel}
      </AddRateCell>
    </>
  );

  const handleSelectModalSelect = (
    entityRateId: EntityRateId | undefined,
    _e: MouseEvent,
    { item: { id } }: { item: Rate }
  ) => {
    if (entityRateId) {
      dispatch(
        updateEntityRate({
          entity_id: entityId,
          entity_rate_id: entityRateId,
          rate_group_id: rateGroupId,
          rate_id: id
        })
      );
    } else {
      if (teamId && rateEntity) {
        const startDate = formatISO(new Date(), { representation: 'date' });
        dispatch(
          createEntityRate({
            entity_id: entityId,
            entity_type: rateEntity.active_entity_type,
            is_cost_rate: false,
            rate_group_id: rateGroupId,
            rate_id: id,
            start_date: startDate,
            team_id: teamId
          })
        );
      }
    }

    closeRatesModal();
  };

  const handleSetDate: CellContentsProps['setDate'] = ({
    date,
    entityRate,
    isStartDate
  }) => {
    dispatch(
      updateEntityRate({
        entity_id: entityId,
        entity_rate_id: entityRate.id,
        rate_group_id: rateGroupId,
        ...(isStartDate ? { start_date: date } : { end_date: date })
      })
    );
  };

  const handleSetDateAttempt: CellContentsProps['setDate'] = (
    newDateParams
  ) => {
    const { date, entityRate, isStartDate } = newDateParams;

    const newDateTimestamp = parseISO(date);
    const newDateInterval = isStartDate
      ? { start: newDateTimestamp, end: DATE_MAX }
      : { start: DATE_MIN, end: newDateTimestamp };
    const isOvertaking = isNewDateIntervalOvertakingOtherRate(
      rates,
      newDateInterval,
      entityRate.id
    );

    if (isOvertaking) openDateChangeConfirmationModal({ newDateParams });
    else handleSetDate(newDateParams);
  };

  const handleRenderItem = ({ item }: { item: ListItem }) => {
    const { entityRate, rate } = item;
    const isLastItem = item === rates[rates.length - 1];
    const isSoleRate = rates.length === 1;

    return (
      <StyledRowContainer $columnWidths={columnWidths} $isLastItem={isLastItem}>
        {columns.map(({ id }) => {
          const { cell: cellContents } = COLUMN_CONFIG[id];
          const isDeleteDisabled =
            isSoleRate && id === EntityRateModalColumn.Delete;
          if (isDeleteDisabled) rebuildTooltip();

          return (
            <BodyCell
              columnId={id}
              entityRate={entityRate}
              isSoleRate={isSoleRate}
              key={id}
              onClick={isDeleteDisabled ? undefined : handleItemClick}
              disabledTooltip={
                isDeleteDisabled ? 'Unable to delete only rate.' : undefined
              }
            >
              {cellContents({
                currencyCode,
                entityRate,
                rate,
                setDate: handleSetDateAttempt
              })}
            </BodyCell>
          );
        })}
      </StyledRowContainer>
    );
  };

  const abortId = useMemo(() => `AddEditEntityRateModal>${uuid()}`, []);
  useEffect(() => {
    dispatch(
      fetchEntityRateHistory({
        entity_id: entityId,
        is_cost_rate: isCostRate,
        rate_group_id: rateGroupId,
        meta: { abortId }
      })
    );

    return () => {
      FetchManager.abort(abortId);
      dispatch(clearEntityRateHistory());
    };
  }, [abortId, dispatch, entityId, isCostRate, rateGroupId]);

  return (
    <>
      {!isRatesModalOpen &&
        !isDeleteEntityRateModalOpen &&
        !isDateChangeConfirmationModalOpen &&
        !isNewRateConfirmationModalOpen && (
          <MultiStepFlyout
            copy={{}}
            globalClassName={MODAL_CLASS}
            handleClose={onClose}
            hideFooter
            idKey="id"
            isAlwaysGlobal
            isOpen
            isWhite
            items={rates}
            listHeight={240}
            listWidth={width}
            renderHeader={handleRenderHeader}
            renderHeaderButton={handleRenderHeaderButton}
            renderItem={handleRenderItem}
            renderTableBodyHeader={handleRenderTableBodyHeader}
            styleWrapper={StyleWrapper}
          />
        )}
      <RateModalComponent headerText="Select New Bill Rate" showKarat />
      <DeleteEntityRateModalComponent />
      <DateChangeConfirmationModalComponent onConfirm={handleSetDate} />
      <NewRateConfirmationModalComponent onConfirm={handleAddRateClick} />
      <ModalStyleOverrides />
    </>
  );
};

const ModalStyleOverrides = createGlobalStyle`
  .${MODAL_CLASS} {
    max-width: none;
    width: fit-content;
  }
`;

const StyleWrapper = styled.div`
  display: inline-block;
  font-size: 15px;
  padding: 40px;

  .styled-header-modal {
    gap: 5px;
    min-width: 100%;
    padding: 0 0 15px;
    width: 0;
  }

  .styled-header-copy {
    font-size: 22px;
    min-width: 0;
  }

  .list-and-footer {
    border-top: none;
    margin: 0 -${DELETE_COLUMN_WIDTH}px;
  }

  .empty-list-container {
    border-top: none;
    margin: 0 ${DELETE_COLUMN_WIDTH}px;
    width: auto;
  }

  .flyout-list-item-container {
    border-bottom: none;
    padding: 0;

    &:hover {
      background: none;
    }
  }
`;

const Header = styled.div`
  display: flex;
`;

const RateType = styled.div`
  flex: none;
`;

const MiniHeaderContainer = styled.div.attrs<{ $columnWidths: number[] }>(
  ({ $columnWidths }) => ({
    style: {
      gridTemplateColumns: $columnWidths.map((width) => `${width}px`).join(' ')
    }
  })
)<{ $columnWidths: number[] }>`
  align-items: center;
  display: grid;
  margin-right: ${DELETE_COLUMN_WIDTH}px;
  text-transform: uppercase;
`;

const StyledRowContainer = styled.div.attrs<{
  $columnWidths: number[];
  $isLastItem: boolean;
}>(({ $columnWidths, $isLastItem }) => ({
  className: cn({ lastItem: $isLastItem }),
  style: {
    gridTemplateColumns: $columnWidths.map((width) => `${width}px`).join(' ')
  }
}))<{ $columnWidths: number[]; $isLastItem: boolean }>`
  align-items: stretch;
  display: grid;
  height: 100%;
  width: fit-content;

  :not(.lastItem) > :not(.${EntityRateModalColumn.Delete}) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  }

  &:hover {
    .${EntityRateModalColumn.Delete} {
      visibility: visible;
    }

    > :not(.${EntityRateModalColumn.Delete}) {
      background-color: ${({ theme }) => theme.colors.colorTranslucentGray4};
    }
  }
`;
