import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import { BudgetSettingsTabs } from './BudgetSettingsTabs';
import { Header } from 'SettingsModule/components/styles';
import { SettingsTab } from './Settings/SettingsTab';
import { RatesTab } from './Rates/RatesTab';
import { useEffect } from 'react';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { budgetSettingsModuleConstants } from './constants';
import { Redirect, useRouteMatch } from 'react-router';
import { getRatesModule } from 'RatesModule/package/ratesModule';

const { BudgetSettingsViewTypes } = budgetSettingsModuleConstants;

const settingViewTypeToComponentHash = {
  [BudgetSettingsViewTypes.settings]: SettingsTab,
  [BudgetSettingsViewTypes.rates]: RatesTab
} as const;

const dynamicModules = [getBudgetModule(), getRatesModule()];

const BudgetSettingsTab = () => {
  const { teamSlug } = useAppSelector(getMatchedRouteParams);
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);
  const route = useRouteMatch<{ viewType: string }>();
  const viewType = route.params.viewType;
  const BodyComponent = settingViewTypeToComponentHash[viewType];

  // Get the currency information.
  useEffect(() => {
    if (BodyComponent && teamId)
      dispatch(
        fetchEntityOptions({
          team_id: teamId,
          entity_type: ENTITY_TYPES.project,
          entity_id: null
        })
      );
  }, [BodyComponent, dispatch, teamId]);

  return BodyComponent ? (
    <DynamicModuleLoader modules={dynamicModules}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Header>Budgets</Header>
        <BudgetSettingsTabs settingsViewType={viewType} teamSlug={teamSlug} />
        {BodyComponent && <BodyComponent />}
      </div>
    </DynamicModuleLoader>
  ) : (
    <Redirect to={route.url.replace(`/${viewType}`, '')} />
  );
};

export default BudgetSettingsTab;
