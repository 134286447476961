import { Space } from 'components/Space';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import {
  ACCESS_ROLES,
  PROJECT_VISIBILITY_TYPES
} from 'PermissionsModule/constants';
import styled from 'styled-components';
import theme from 'theme';
import { AccessLevelSizeIndicator } from 'PermissionsModule/components/AccessLevelSizeIndicator';
import { permissionsPresentationUtils } from 'PermissionsModule/components/utils';

const { accessRoleToContent, projectVisibilityToContent } =
  permissionsPresentationUtils;
const { TEAM_MEMBER, WORKLOAD_MANAGER, FINANCIAL_MANAGER, ADMIN } =
  ACCESS_ROLES;

export const AccessLevelSummaryList = () => {
  const { projectLevelPermissionFlag } = useFeatureFlags();

  const accessLevelsInfoArray = [
    {
      role: TEAM_MEMBER,
      projectVisibilities: [
        { value: PROJECT_VISIBILITY_TYPES.ALL, isDefault: true },
        { value: PROJECT_VISIBILITY_TYPES.NO_ACCESS }
      ]
    },
    {
      role: WORKLOAD_MANAGER,
      projectVisibilities: [
        { value: PROJECT_VISIBILITY_TYPES.ALL },
        { value: PROJECT_VISIBILITY_TYPES.PROJECTS_MEMBER_OF }
      ]
    },
    {
      role: FINANCIAL_MANAGER,
      projectVisibilities: [
        { value: PROJECT_VISIBILITY_TYPES.ALL },
        { value: PROJECT_VISIBILITY_TYPES.PROJECTS_MEMBER_OF }
      ]
    },
    { role: ADMIN }
  ];
  return (
    <RootContainer>
      <Header>Member Access Levels</Header>
      <Space vertical value={10} />
      <RoleItemsList>
        {accessLevelsInfoArray.map(({ role, projectVisibilities }) => (
          <RoleItemContainer key={role}>
            <AccessLevelSizeIndicator role={role} />
            <RoleNameText>{accessRoleToContent[role]}</RoleNameText>

            {projectLevelPermissionFlag &&
              projectVisibilities?.map((projectVisibility) => (
                <RoleProjectVisibilityText key={projectVisibility.value}>
                  • {projectVisibilityToContent[projectVisibility.value]}
                  {projectVisibility.isDefault && ' (Default)'}
                </RoleProjectVisibilityText>
              ))}
          </RoleItemContainer>
        ))}
      </RoleItemsList>
    </RootContainer>
  );
};

const RootContainer = styled.div``;

const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
`;

const RoleItemsList = styled.div`
  display: grid;
  gap: 20px;
`;

const RoleItemContainer = styled.div``;

const RoleNameText = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
`;

const RoleProjectVisibilityText = styled.div`
  font-size: 13px;
  margin-left: 5px;
  color: ${theme.colors.colorCalendarGray};
`;
