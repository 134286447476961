import { changeEntity } from './generics';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';

export function* createProjectPriority(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.createProjectPriority,
    api.createProjectPriority,
    [token, id, payload],
    action
  );
}
export function* updateProjectPriority(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateProjectPriority,
    api.updateProjectPriority,
    [token, id, payload],
    action
  );
}

export function* deleteProjectPriority(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.deleteProjectPriority,
    api.deleteProjectPriority,
    [token, id],
    action
  );
}
