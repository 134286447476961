import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeGetActiveWorkloadPlannerFilter,
  getSelectedTeamId,
  getHasFetchedPhaseNames,
  getTimesheetViewBy
} from 'selectors';
import { getWidgetsByFilterId } from 'DashboardModule/selectors';
import { closeWorkloadFilter, fetchPhaseNames } from 'actionCreators';
import { useRouteMatch } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import { FILTER_PAGES, VIEW_BY } from 'appConstants/workload';
import { SectionRoutes } from 'appConstants/navigation';
import { getFilterFromQueryParams } from 'appUtils/filters';
import {
  FILTER_QUERY_PARAMS_CONFIG,
  FILTER_SECTIONS,
  CUSTOM_FILTER_VALUES
} from 'appConstants/filters';
import {
  defaultAddableWidgetsHash,
  defaultAddableWidgetsHashByUid
} from 'DashboardModule/constants/widgets';

const emptyObj = {};

const pageNames = {
  time: FILTER_PAGES.TIMESHEET,
  budget: FILTER_PAGES.BUDGET,
  capacity: FILTER_PAGES.CAPACITY,
  utilization: FILTER_PAGES.UTILIZATION,
  profit: FILTER_PAGES.PROFIT,
  variance: FILTER_PAGES.VARIANCE,
  activity: FILTER_PAGES.ACTIVITY,
  timesheetstatus: FILTER_PAGES.TIMESHEET_STATUS_REPORT,
  demand: FILTER_PAGES.DEMAND_REPORT,
  budgetvariance: FILTER_PAGES.BUDGET_VARIANCE,
  workloadforecast: FILTER_PAGES.WORKLOAD_FORECAST,
  schedulevariance: FILTER_PAGES.SCHEDULE_VARIANCE,
  skills: FILTER_PAGES.SKILLS_REPORT,
  checkins: FILTER_PAGES.CHECK_INS_REPORT,
  allprojects: FILTER_PAGES.ALL_PROJECTS_REPORT,
  timeprojection: FILTER_PAGES.TIME_PROJECTION_REPORT,
  spenttime: FILTER_PAGES.SPENT_TIME_REPORT,
  plannedtime: FILTER_PAGES.PLANNED_TIME_REPORT,
  unspentbudget: FILTER_PAGES.UNSPENT_BUDGET_REPORT
};

export const FILTER_SECTIONS_PER_PAGE = {
  [FILTER_PAGES.TIMESHEET]: [
    FILTER_SECTIONS.MEMBERS,
    FILTER_SECTIONS.PROJECTS,
    FILTER_SECTIONS.PHASES,
    FILTER_SECTIONS.ACTIVITIES,
    FILTER_SECTIONS.BILLABLE,
    FILTER_SECTIONS.STATUSES,
    FILTER_SECTIONS.CLIENTS
  ],
  [FILTER_PAGES.CAPACITY]: [],
  [FILTER_PAGES.DEMAND_REPORT]: [],
  [FILTER_PAGES.PROFIT]: [],
  [FILTER_PAGES.UTILIZATION]: [],
  [FILTER_PAGES.ACTIVITY]: [
    FILTER_SECTIONS.MEMBERS,
    FILTER_SECTIONS.PROJECTS,
    FILTER_SECTIONS.PHASES
  ],
  [FILTER_PAGES.TIMESHEET_STATUS_REPORT]: [],
  [FILTER_PAGES.BUDGET_TABLE]: [
    FILTER_SECTIONS.PHASES,
    FILTER_SECTIONS.ACTIVITIES,
    FILTER_SECTIONS.MEMBERS,
    FILTER_SECTIONS.PROFIT_CENTERS,
    FILTER_SECTIONS.BILLABLE,
    FILTER_SECTIONS.POSITIONS,
    FILTER_SECTIONS.PHASE_BUDGET_STATUSES,
    FILTER_SECTIONS.PHASE_FEE_TYPES
  ],
  [FILTER_PAGES.CHECK_INS_REPORT]: [],
  [FILTER_PAGES.ALL_PROJECTS_REPORT]: []
};

const FILTER_SECTIONS_BY_PAGE_VIEWBY = {
  [FILTER_PAGES.BUDGET]: {
    [VIEW_BY.MEMBERS]: [
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ],
    [VIEW_BY.PROJECTS]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ]
  },
  [FILTER_PAGES.VARIANCE]: {
    [VIEW_BY.MEMBERS]: [
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ],
    [VIEW_BY.PROJECTS]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ]
  },
  [FILTER_PAGES.TIMESHEET]: {
    [VIEW_BY.NONE]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.ACTIVITIES,
      FILTER_SECTIONS.BILLABLE,
      FILTER_SECTIONS.STATUSES,
      FILTER_SECTIONS.CLIENTS
    ],
    [VIEW_BY.MEMBERS]: [
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.ACTIVITIES,
      FILTER_SECTIONS.BILLABLE,
      FILTER_SECTIONS.STATUSES,
      FILTER_SECTIONS.CLIENTS
    ],
    [VIEW_BY.PROJECTS]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.ACTIVITIES,
      FILTER_SECTIONS.BILLABLE,
      FILTER_SECTIONS.STATUSES,
      FILTER_SECTIONS.CLIENTS
    ],
    [VIEW_BY.ACTIVITY]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES
    ]
  },
  [FILTER_PAGES.BUDGET_VARIANCE]: {
    [VIEW_BY.MEMBERS]: [
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ],
    [VIEW_BY.PROJECTS]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ]
  },
  [FILTER_PAGES.SCHEDULE_VARIANCE]: {
    [VIEW_BY.PROJECTS]: [FILTER_SECTIONS.PHASES]
  },
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: {
    [VIEW_BY.MEMBERS]: [
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ],
    [VIEW_BY.PROJECTS]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ]
  },
  [FILTER_PAGES.SPENT_TIME_REPORT]: {
    [VIEW_BY.MEMBERS]: [FILTER_SECTIONS.PROJECTS, FILTER_SECTIONS.PHASES],
    [VIEW_BY.PROJECTS]: [FILTER_SECTIONS.MEMBERS, FILTER_SECTIONS.PHASES]
  },
  [FILTER_PAGES.PLANNED_TIME_REPORT]: {
    [VIEW_BY.MEMBERS]: [FILTER_SECTIONS.PROJECTS, FILTER_SECTIONS.PHASES],
    [VIEW_BY.PROJECTS]: [FILTER_SECTIONS.MEMBERS, FILTER_SECTIONS.PHASES]
  },
  [FILTER_PAGES.UNSPENT_BUDGET_REPORT]: {
    [VIEW_BY.MEMBERS]: [
      FILTER_SECTIONS.PROJECTS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ],
    [VIEW_BY.PROJECTS]: [
      FILTER_SECTIONS.MEMBERS,
      FILTER_SECTIONS.PHASES,
      FILTER_SECTIONS.BILLABLE
    ]
  }
};

const PAGE_HAS_SELECTION_FILTER = {
  [FILTER_PAGES.PROFIT]: true,
  [FILTER_PAGES.BUDGET]: true,
  [FILTER_PAGES.VARIANCE]: true,
  [FILTER_PAGES.TIMESHEET_STATUS_REPORT]: true,
  [FILTER_PAGES.CAPACITY]: true,
  [FILTER_PAGES.DEMAND_REPORT]: true,
  [FILTER_PAGES.BUDGET_VARIANCE]: true,
  [FILTER_PAGES.SCHEDULE_VARIANCE]: true,
  [FILTER_PAGES.CHECK_INS_REPORT]: true,
  [FILTER_PAGES.ALL_PROJECTS_REPORT]: true,
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: true,
  [FILTER_PAGES.WORKLOAD_FORECAST]: true,
  [FILTER_PAGES.SPENT_TIME_REPORT]: true,
  [FILTER_PAGES.PLANNED_TIME_REPORT]: true,
  [FILTER_PAGES.UNSPENT_BUDGET_REPORT]: true
};
const PAGE_VIEWBY_HAS_SELECTION_FILTER = {
  [FILTER_PAGES.UTILIZATION]: {
    [VIEW_BY.MEMBERS]: true,
    [VIEW_BY.ORGANIZATION]: true
  },
  [FILTER_PAGES.TIMESHEET]: {
    [VIEW_BY.MEMBERS]: true,
    [VIEW_BY.PROJECTS]: true
  }
};
// TODO: WHAT FILTERS FOR SCHEDULE VARIANCE?

const listOfDifferentSelectorsByGroupBy = {
  [FILTER_PAGES.VARIANCE]: {
    [VIEW_BY.MEMBERS]: true
  },
  [FILTER_PAGES.BUDGET_VARIANCE]: {
    [VIEW_BY.MEMBERS]: true
  }
};

const FILTER_ENTITIES = {
  [FILTER_PAGES.TIMESHEET]: {
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS,
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS
  },
  [FILTER_PAGES.BUDGET]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS
  },
  [FILTER_PAGES.CAPACITY]: {
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS,
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.CLIENTS]: FILTER_SECTIONS.CLIENTS,
    [VIEW_BY.ORGANIZATION]: FILTER_SECTIONS.MEMBERS
  },
  [FILTER_PAGES.UTILIZATION]: {
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS,
    [VIEW_BY.ORGANIZATION]: FILTER_SECTIONS.MEMBERS // This is under 'Team' on Utilization Report
  },
  [FILTER_PAGES.PROFIT]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS
  },
  [FILTER_PAGES.VARIANCE]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: {
      MEMBERS: FILTER_SECTIONS.MEMBERS,
      POSITIONS: FILTER_SECTIONS.POSITIONS
    }
  },
  [FILTER_PAGES.BUDGET_VARIANCE]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: {
      MEMBERS: FILTER_SECTIONS.MEMBERS,
      POSITIONS: FILTER_SECTIONS.POSITIONS
    }
  },
  [FILTER_PAGES.TIMESHEET_STATUS_REPORT]: {
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS
  },
  [FILTER_PAGES.DEMAND_REPORT]: {
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.POSITIONS,
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.POSITIONS,
    [VIEW_BY.TEAM]: FILTER_SECTIONS.POSITIONS
  },
  [FILTER_PAGES.SCHEDULE_VARIANCE]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS
  },
  [FILTER_PAGES.TIME_PROJECTION_REPORT]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS
  },
  [FILTER_PAGES.SPENT_TIME_REPORT]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS
    // TODO: Add Client tab when has grouped by client
  },
  [FILTER_PAGES.PLANNED_TIME_REPORT]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS
    // TODO: Add Client tab when has grouped by client
  },
  [FILTER_PAGES.UNSPENT_BUDGET_REPORT]: {
    [VIEW_BY.PROJECTS]: FILTER_SECTIONS.PROJECTS,
    [VIEW_BY.MEMBERS]: FILTER_SECTIONS.MEMBERS
    // TODO: Add Client tab when has grouped by client
  },
  [FILTER_PAGES.WORKLOAD_FORECAST]: {
    [VIEW_BY.POSITIONS]: FILTER_SECTIONS.POSITIONS
  }
};

const ADDITIONAL_FILTER_OPTIONS = {
  [FILTER_PAGES.WORKLOAD_FORECAST]: {
    [VIEW_BY.POSITIONS]: {
      includeEntireOrg: true,
      isSingleSelect: true,
      shouldKeepOrder: true // do not sort the list items
    }
  }
};

/* ------------------------------------ - ----------------------------------- */

const useReportsViewFilter = ({
  matchUrl,
  widgetType,
  filterInit,
  pageName
} = emptyObj) => {
  const getActiveWorkloadPlannerFilter = useMemo(() => {
    return makeGetActiveWorkloadPlannerFilter();
  }, []);
  const teamId = useSelector(getSelectedTeamId);
  const hasFetchedPhaseNames = useSelector(getHasFetchedPhaseNames);

  const dispatch = useDispatch();
  const {
    params: { viewType, reportViewType, budgetSecondaryViewType }
  } = useRouteMatch(matchUrl || SectionRoutes.REPORTS) || { params: {} };

  // pageName should be given when viewType is not unique and matches
  // a report viewType
  // eg. budget table budgetViewType = 'budget', budget report viewType = 'budget'
  const pageNameToUse = pageName || pageNames[viewType];

  const [query] = useQueryParams(FILTER_QUERY_PARAMS_CONFIG);
  const { filter_id: filterId } = query;

  const queryParamFilter = useMemo(() => {
    const { filter_id, ...params } = query;
    return getFilterFromQueryParams(params); // null if no params
  }, [query]);

  const widgetsByFilterId = useSelector(getWidgetsByFilterId);
  const selectionFilterIsOpen = useSelector(
    (state) => state.workloadPlannerFilter.isOpen
  );

  const widget = useMemo(
    () => widgetsByFilterId[filterId],
    [filterId, widgetsByFilterId]
  );

  const viewBys = useMemo(
    () => ({
      [FILTER_PAGES.TIMESHEET]: reportViewType || VIEW_BY.NONE,
      [FILTER_PAGES.BUDGET]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.UTILIZATION]: reportViewType || VIEW_BY.MEMBERS,
      [FILTER_PAGES.CAPACITY]: reportViewType || VIEW_BY.MEMBERS,
      [FILTER_PAGES.VARIANCE]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.BUDGET_VARIANCE]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.SCHEDULE_VARIANCE]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.ACTIVITY]: VIEW_BY.MEMBERS,
      [FILTER_PAGES.PROFIT]: VIEW_BY.PROJECTS,
      [FILTER_PAGES.TIMESHEET_STATUS_REPORT]: VIEW_BY.MEMBERS,
      [FILTER_PAGES.DEMAND_REPORT]: reportViewType || VIEW_BY.MEMBERS,
      [FILTER_PAGES.BUDGET_TABLE]: budgetSecondaryViewType || VIEW_BY.PHASES,
      [FILTER_PAGES.SKILLS_REPORT]: VIEW_BY.POSITIONS,
      [FILTER_PAGES.CHECK_INS_REPORT]: VIEW_BY.MEMBERS,
      [FILTER_PAGES.ALL_PROJECTS_REPORT]: VIEW_BY.PROJECTS,
      [FILTER_PAGES.PROJECT_DETAILS_TABLE]: VIEW_BY.MEMBERS,
      [FILTER_PAGES.TIME_PROJECTION_REPORT]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.SPENT_TIME_REPORT]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.PLANNED_TIME_REPORT]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.UNSPENT_BUDGET_REPORT]: reportViewType || VIEW_BY.PROJECTS,
      [FILTER_PAGES.WORKLOAD_FORECAST]: reportViewType, // TODO: add default value once we update all the env with role selector
      [FILTER_PAGES.PROJECT_DETAIL_BUDGET]: VIEW_BY.PROJECTS // TODO: add default value once we update all the env with role selector
    }),
    [budgetSecondaryViewType, reportViewType]
  );
  const viewBy = viewBys[pageNameToUse];

  const filterSections = useMemo(
    () =>
      FILTER_SECTIONS_BY_PAGE_VIEWBY[pageNameToUse]?.[viewBy] ||
      FILTER_SECTIONS_PER_PAGE[pageNameToUse] ||
      FILTER_SECTIONS_PER_PAGE.time,
    [pageNameToUse, viewBy]
  );

  // special case for phase names. must be loaded in order for active filter
  // to be returned with them in makeGetActiveWorkloadPlannerFilter due to the intersection()
  useEffect(() => {
    if (teamId && !hasFetchedPhaseNames) {
      if (filterSections?.includes(FILTER_SECTIONS.PHASES)) {
        dispatch(fetchPhaseNames({ teamId }));
      }
    }
  }, [dispatch, filterSections, hasFetchedPhaseNames, teamId]);

  // defaultAddableWidgetsHashByUid when adding a new widget.
  // for widgets that are created already, getActiveWorkloadPlannerFilter returns its own filter stored
  const widgetConfig = widget
    ? defaultAddableWidgetsHash[widget.name]
    : defaultAddableWidgetsHashByUid[widgetType];
  const activeFilter = useSelector((state) =>
    getActiveWorkloadPlannerFilter(state, {
      viewBy,
      pageName: pageNameToUse,
      filterId,
      ...(filterInit ||
        (widgetConfig && { filterInit: widgetConfig.filterInit }))
    })
  );

  const filterEntity = useMemo(() => {
    const filterEntities = FILTER_ENTITIES[pageNameToUse];

    if (filterEntities) {
      // groupBy is for secondary grouping in a certain viewBy where it needs to use a different type of side selector
      // make sure to add a nested object under viewBy in FILTER_ENTITIES with secondary grouping name if report page filter has it in custom
      // the first condition will check if it should use different selectors by groupBy values.
      const groupBy =
        listOfDifferentSelectorsByGroupBy[pageNameToUse]?.[viewBy] &&
        activeFilter.custom[CUSTOM_FILTER_VALUES.groupBy];

      return groupBy ? filterEntities[viewBy][groupBy] : filterEntities[viewBy];
    }
  }, [activeFilter.custom, pageNameToUse, viewBy]);

  const additionalFilterOptions =
    ADDITIONAL_FILTER_OPTIONS[pageNameToUse]?.[viewBy] || emptyObj;

  // Close the selection filter if it's open and new page/viewBy
  // doesn't have it
  useEffect(() => {
    if (
      selectionFilterIsOpen &&
      !(
        PAGE_HAS_SELECTION_FILTER[pageNameToUse] ||
        PAGE_VIEWBY_HAS_SELECTION_FILTER[pageNameToUse]?.[viewBy]
      )
    ) {
      dispatch(closeWorkloadFilter());
    }
  });

  return {
    filterId,
    filterSections,
    filterEntity,
    viewBy,
    pageName: pageNameToUse,
    viewType,
    // replace the id, page and page_name so changes are made to the original/existing filter
    // this is because some widgets have different page and page_name that is used for report pages
    activeFilter:
      queryParamFilter && !activeFilter.filterChanged // allow local changes without having to change the url
        ? {
            ...queryParamFilter,
            id: activeFilter.id,
            page: activeFilter.page,
            page_name: activeFilter.page_name,
            filterChanged: true
          }
        : activeFilter,
    widget,
    widgetConfig,
    additionalFilterOptions,
    isUsingQueryParamFilter: !!queryParamFilter
  };
};

export default useReportsViewFilter;
