import { ComponentProps, useCallback, useState } from 'react';
import { AccountId } from 'models/account';
import { MemberAvailabilityTimeline } from './MemberAvailabilityTimeline';
import { TimelineView } from 'TimelinesModule/types/timelineView';
import { useMemberAvailabilityTimelineData } from './useMemberAvailabilityTimelineData';

interface MemberAvailabilityTimelineContainerProps
  extends Pick<
    ComponentProps<typeof MemberAvailabilityTimeline>,
    'sidebarHeader'
  > {
  accountIds: AccountId[];
  timelineView: TimelineView;
  onTimelineViewChange: (value: Pick<TimelineView, 'visibleTimeRange'>) => void;
}

export const MemberAvailabilityTimelineContainer = ({
  accountIds,
  timelineView: {
    visibleTimeRange: { visibleTimeStart, visibleTimeEnd }
  },
  onTimelineViewChange,
  sidebarHeader
}: MemberAvailabilityTimelineContainerProps) => {
  const [openAccountIds, setOpenAccountIds] = useState<AccountId[]>([]);

  const {
    groups,
    items,
    utilizationsBreakdownByGroup,
    workFromHomesByGroup,
    timelineConfig
  } = useMemberAvailabilityTimelineData({
    accountIds,
    openAccountIds,
    startTime: visibleTimeStart,
    endTime: visibleTimeEnd
  });

  const handleGroupExpand: ComponentProps<
    typeof MemberAvailabilityTimeline
  >['onGroupExpand'] = useCallback((group) => {
    setOpenAccountIds((prev) => [...prev, group.accountId]);
  }, []);

  const handleGroupCollapse: ComponentProps<
    typeof MemberAvailabilityTimeline
  >['onGroupExpand'] = useCallback((group) => {
    setOpenAccountIds((prev) => prev.filter((id) => id !== group.accountId));
  }, []);

  const handleThrottledTimeChange: ComponentProps<
    typeof MemberAvailabilityTimeline
  >['onThrottledTimeChange'] = useCallback(
    ({ visibleTimeStart, visibleTimeEnd }) => {
      onTimelineViewChange({
        visibleTimeRange: {
          visibleTimeStart,
          visibleTimeEnd
        }
      });
    },
    [onTimelineViewChange]
  );

  return (
    <MemberAvailabilityTimeline
      groups={groups}
      items={items}
      initialVisibleTimeStart={visibleTimeStart}
      initialVisibleTimeEnd={visibleTimeEnd}
      utilizationBreakdownsByGroup={utilizationsBreakdownByGroup}
      workFromHomesByGroup={workFromHomesByGroup}
      onGroupExpand={handleGroupExpand}
      onGroupCollapse={handleGroupCollapse}
      onThrottledTimeChange={handleThrottledTimeChange}
      timelineConfig={timelineConfig}
      sidebarHeader={sidebarHeader}
    />
  );
};
