import * as constants from 'appConstants';
import omit from 'lodash/omit';
import { archiveTeamMembersActionCreatorsMap } from 'TeamsModule/actionCreators';

const byId = (item) => item.id;
export const initialState = {
  isAddModalOpen: false,
  isEditModalOpen: false,
  isTeamMemberModalOpen: false,
  isOpenArchiveBoardModal: false,
  isFetchingGroup: false,
  statusTextGroup: null,
  editingGroup: null,
  groupList: [],
  personalBoards: [],
  archivedList: [],
  memberList: [],
  allMemberList: [],
  editingGroupMemberList: [],
  selectedGroupID: undefined,
  selectedBoard: null,
  roles: [
    {
      id: 1,
      name: 'Portfolio Manager'
    },
    {
      id: 2,
      name: 'Portfolio Member'
    }
  ],
  errorMessage: null,
  disableSave: false,
  recentBoard: {},
  boardsSkeletons: {},
  projectCounts: {},
  isFetchingBoardProjects: {},
  sortProperty: null,
  shouldStayOnViewAfterCreate: false
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.DELETE_BOARD.TRIGGER: {
      const { boardId } = action.payload;
      const recentBoard =
        boardId !== state.recentBoard.id ? state.recentBoard : {};
      const selectedBoard =
        boardId !== (state.selectedBoard && state.selectedBoard.id)
          ? state.selectedBoard
          : null;
      const selectedGroupID =
        boardId !== state.selectedGroupID ? state.selectedGroupID : null;
      const groupList = state.groupList.filter((group) => group.id !== boardId);

      return {
        ...state,
        recentBoard,
        selectedBoard,
        selectedGroupID,
        groupList
      };
    }
    case constants.ARCHIVE_BOARD.TRIGGER: {
      const { id, archive } = action.payload;
      if (!archive) {
        return state;
      }
      const recentBoard = id !== state.recentBoard.id ? state.recentBoard : {};
      const selectedBoard =
        id !== state.selectedBoard.id ? state.selectedBoard : null;
      const selectedGroupID =
        id !== state.selectedGroupID ? state.selectedGroupID : null;
      const groupList = state.groupList.filter((group) => group.id !== id);

      return {
        ...state,
        recentBoard,
        selectedBoard,
        selectedGroupID,
        groupList
      };
    }
    case constants.LOCATION_CHANGE:
      return {
        ...state,
        isAddModalOpen: false,
        isEditModalOpen: false,
        isTeamMemberModalOpen: false,
        isOpenArchiveBoardModal: false
      };
    case constants.GROUP_CREATION.TRIGGER:
      return {
        ...state,
        isCreatingGroup: true
      };
    case constants.GROUP_CREATION.REQUEST:
      return {
        ...state,
        isAddModalOpen: !action.payload.notFromModal,
        isFetchingGroup: true,
        statusTextGroup: null
      };
    case constants.GROUP_CREATION.SUCCESS:
      return {
        ...state,
        isCreatingGroup: false,
        isAddModalOpen: false,
        isFetchingGroup: false,
        statusTextGroup: null
      };
    case constants.GROUP_CREATION.FAILURE:
      return {
        ...state,
        isCreatingGroup: false,
        isAddModalOpen: true,
        isFetchingGroup: false,
        statusTextGroup: 'Error'
      };
    case constants.GROUP_EDITION.SUCCESS:
      return {
        ...state,
        statusTextGroup: null
      };
    case constants.GROUP_EDITION.FAILURE:
      return {
        ...state,
        statusTextGroup: 'Error'
      };
    case constants.GROUPS.REQUEST:
      return {
        ...state
      };
    case constants.GROUPS.SUCCESS: {
      return {
        ...state,
        groupList: action.payload.response.teams[0]?.boards || [],
        personalBoards: (action.payload.response.teams[1]?.boards || []).map(
          (board) => ({
            ...board,
            isPersonal: true
          })
        )
      };
    }
    case constants.GROUPS.FAILURE:
      return {
        ...state
      };
    case constants.FETCH_ARCHIVED_BOARDS.SUCCESS:
      return {
        ...state,
        archivedList: action.payload.response.boards
      };
    case constants.CLOSE_ADD_GROUP_MODAL:
      return {
        ...state,
        isAddModalOpen: false,
        statusTextGroup: null,
        shouldStayOnViewAfterCreate: false
      };
    case constants.OPEN_ADD_EDIT_GROUP_MODAL: {
      const {
        isEdit,
        id,
        shouldStayOnViewAfterCreate = false
      } = action.payload;
      return {
        ...state,
        isEditModalOpen: isEdit,
        isAddModalOpen: !isEdit,
        editingGroup: id || null,
        isOpenArchiveBoardModal: false,
        shouldStayOnViewAfterCreate
      };
    }
    case constants.CLOSE_EDIT_GROUP_MODAL:
      return {
        ...state,
        isEditModalOpen: false,
        statusTextGroup: null,
        editingGroup: null
      };
    case constants.GROUP_MEMBERS.REQUEST:
      if (action.initialActionPayload.editingGroup) {
        return { ...state };
      }
      return { ...state };
    case constants.GROUP_MEMBERS.SUCCESS: {
      return {
        ...state,
        editingGroupMemberList: action.payload.response,
        memberList: action.payload.response
      };
    }
    case constants.GROUP_MEMBERS.FAILURE:
      return state;
    case constants.ALL_GROUP_MEMBERS.REQUEST:
      return state;
    case constants.ALL_GROUP_MEMBERS.SUCCESS:
      return {
        ...state,
        allMemberList: action.payload.response
      };
    case constants.ALL_GROUP_MEMBERS.FAILURE:
      return state;
    case constants.OPEN_INVITE_TEAM_MEMBER_MODAL:
      return {
        ...state,
        isTeamMemberModalOpen: true
      };
    case constants.CLOSE_INVITE_TEAM_MEMBER_MODAL:
      return {
        ...state,
        isTeamMemberModalOpen: false,
        errorMessage: null
      };
    case constants.CLOSE_BULK_ADD_MEMBERS_MODAL:
      return {
        ...state,
        errorMessage: null
      };
    // TODO: move INVITE_MEMBER to reducers/users.js
    case constants.INVITE_MEMBER.REQUEST:
      return {
        ...state
      };
    case constants.INVITE_MEMBER.SUCCESS:
      return {
        ...state,
        isTeamMemberModalOpen: false
      };
    case constants.INVITE_MEMBER.FAILURE:
      return state;
    case constants.UPDATE_ERROR_MESSAGE: {
      const { newErrorMessage } = action.payload;
      return {
        ...state,
        errorMessage: newErrorMessage
      };
    }
    case constants.FETCH_PROJECTS_BY_GROUP:
      return {
        ...state,
        selectedGroupID:
          action.payload.params.shouldSelectGroup === false
            ? undefined
            : parseInt(action.payload.params.groupId, 10)
      };
    case constants.TOGGLE_ARCHIVE_GROUP_MODAL:
      return {
        ...state,
        isOpenArchiveBoardModal: action.payload.modalState
      };
    case constants.ADD_MEMBER_TO_LOCAL_GROUP:
      return {
        ...state,
        editingGroupMemberList: [...state.editingGroupMemberList, action.member]
      };
    case constants.ADD_BULK_MEMBERS_TO_LOCAL_GROUP:
      return {
        ...state,
        editingGroupMemberList: [
          ...state.editingGroupMemberList,
          ...action.members
        ]
      };
    case constants.ADD_MEMBER_TO_GROUP.REQUEST:
    case constants.ADD_BULK_MEMBERS_TO_GROUP.REQUEST:
    case constants.DELETE_MEMBER_FROM_GROUP.REQUEST:
      return {
        ...state,
        disableSave: true
      };
    case constants.ADD_MEMBER_TO_GROUP.SUCCESS:
    case constants.ADD_BULK_MEMBERS_TO_GROUP.SUCESS:
    case constants.DELETE_MEMBER_FROM_GROUP.SUCCESS:
      return {
        ...state,
        disableSave: false
      };
    case constants.DELETE_MEMBER_FROM_LOCAL_GROUP: {
      const list = state.editingGroupMemberList.filter(
        (member) => member !== action.member
      );
      return {
        ...state,
        editingGroupMemberList: list
      };
    }
    case constants.FETCH_SELECTED_BOARD.SUCCESS:
      return {
        ...state,
        selectedBoard: action.payload.response,
        recentBoard: action.payload.response
      };
    case constants.SET_SELECTED_BOARD: {
      return {
        ...state,
        selectedBoard: action.payload.board,
        selectedGroupID: action.payload.board.id
      };
    }
    case constants.CLEAR_SELECTED_BOARD: {
      return {
        ...state,
        selectedBoard: null,
        selectedGroupID: null
      };
    }
    case constants.PROJECT.SUCCESS:
      return {
        ...state,
        selectedGroupID: action.payload.response.projects[0].board_id
      };
    case archiveTeamMembersActionCreatorsMap.success.type: {
      const { teamMembershipIds } = action.payload.initialPayload;

      return {
        ...state,
        memberList: state.memberList.filter(
          (member) => !teamMembershipIds.includes(member.id)
        )
      };
    }
    case constants.SET_BOARD_SORT_PROPERTY: {
      const { sortProperty } = action.payload;
      return {
        ...state,
        sortProperty
      };
    }
    case constants.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null
      };

    case constants.GROUP_EDITION.TRIGGER:
      if (state.selectedBoard && action.payload.id === state.selectedBoard.id) {
        const editedBoard = {
          ...state.selectedBoard,
          is_private: action.payload.isPrivate
        };
        return {
          ...state,
          selectedBoard: editedBoard
        };
      } else return state;
    case constants.UPDATE_BOARD_MODULES.TRIGGER: {
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          board_modules:
            action.payload.appModules || state.selectedBoard.board_modules,
          column_order:
            action.payload.columnOrder || state.selectedBoard.column_order,
          stage_order:
            action.payload.stageOrder || state.selectedBoard.stage_order,
          priority_order:
            action.payload.priorityOrder || state.selectedBoard.priority_order,
          status_order:
            action.payload.statusOrder || state.selectedBoard.status_order
        }
      };
    }
    case constants.UPDATE_BOARD_MODULES.SUCCESS: {
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          board_modules: action.payload.response.board_modules,
          column_order: action.payload.response.column_order,
          stage_order: action.payload.response.stage_order,
          priority_order: action.payload.response.priority_order,
          status_order: action.payload.response.status_order
        }
      };
    }
    case constants.FETCH_PROJECTS_LIST_VIEW.TRIGGER: {
      const { boardId } = action.payload;
      return {
        ...state,
        isFetchingBoardProjects: {
          ...state.isFetchingBoardProjects,
          [boardId]: true
        }
      };
    }
    case constants.FETCH_PROJECTS_LIST_VIEW.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { projects, project_count } = response;
      return {
        ...state,
        boardsSkeletons: {
          ...state.boardsSkeletons,
          [requestPayload]: projects.filter((project) => !!project).map(byId)
        },
        projectCounts: {
          ...state.projectCounts,
          [requestPayload]: project_count
        },
        isFetchingBoardProjects: {
          ...state.isFetchingBoardProjects,
          [requestPayload]: false
        }
      };
    }
    case constants.CLEAR_BOARD_PROJECTS: {
      const { boardId } = action.payload;
      return {
        ...state,
        boardsSkeletons: omit(state.boardsSkeletons, boardId),
        projectCounts: omit(state.projectCounts, boardId)
      };
    }
    case constants.UPDATE_PROJECT_STAGE.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          stages: state.selectedBoard.stages.map((stage) =>
            stage.id === response.id ? response : stage
          ),
          stage_order: state.selectedBoard.stage_order.map((stageId) =>
            stageId === response.id ? response.id : stageId
          )
        }
      };
    }
    case constants.CREATE_PROJECT_STAGE.SUCCESS: {
      const { response } = action.payload;
      if (response && response.error) return state;

      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          stages: [...state.selectedBoard.stages, response],
          stage_order: [...state.selectedBoard.stage_order, response.id]
        }
      };
    }
    case constants.DELETE_PROJECT_STAGE.TRIGGER: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          stages: state.selectedBoard.stages.filter((stage) => stage.id !== id),
          stage_order: state.selectedBoard.stage_order.filter(
            (stageId) => stageId !== id
          )
        }
      };
    }
    case constants.UPDATE_PROJECT_STATUS.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          statuses: state.selectedBoard.statuses.map((status) =>
            status.id === response.id ? response : status
          ),
          status_order: state.selectedBoard.status_order.map((statusId) =>
            statusId === response.id ? response.id : statusId
          )
        }
      };
    }
    case constants.CREATE_PROJECT_STATUS.SUCCESS: {
      const { response } = action.payload;
      if (response && response.error) return state;

      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          statuses: [...state.selectedBoard.statuses, response],
          status_order: [...state.selectedBoard.status_order, response.id]
        }
      };
    }
    case constants.DELETE_PROJECT_STATUS.TRIGGER: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          statuses: state.selectedBoard.statuses.filter(
            (status) => status.id !== id
          ),
          status_order: state.selectedBoard.status_order.filter(
            (statusId) => statusId !== id
          )
        }
      };
    }
    case constants.UPDATE_PROJECT_PRIORITY.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          priorities: state.selectedBoard.priorities.map((priority) =>
            priority.id === response.id ? response : priority
          ),
          priority_order: state.selectedBoard.priority_order.map((priorityId) =>
            priorityId === response.id ? response.id : priorityId
          )
        }
      };
    }
    case constants.CREATE_PROJECT_PRIORITY.SUCCESS: {
      const { response } = action.payload;
      if (response && response.error) return state;

      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          priorities: [...state.selectedBoard.priorities, response],
          priority_order: [...state.selectedBoard.priority_order, response.id]
        }
      };
    }
    case constants.DELETE_PROJECT_PRIORITY.TRIGGER: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        selectedBoard: {
          ...state.selectedBoard,
          priorities: state.selectedBoard.priorities.filter(
            (priority) => priority.id !== id
          ),
          priority_order: state.selectedBoard.priority_order.filter(
            (priorityId) => priorityId !== id
          )
        }
      };
    }
    default:
      return state;
  }
};

export default groups;
