import { useDebugValue } from 'react';
import { useSelector } from 'react-redux';
import { getMemberBudgets } from 'BudgetModule/selectors';
import { getPositions } from 'BudgetModule/selectors/positions';
import useMemberBudgetName from 'appUtils/hooks/budget/useMemberBudgetName';
import { getIsUnassignedMemberBudget } from 'appUtils/budgetUtils';

const useMemberBudget = ({
  memberBudgetId,
  memberBudget: givenMemberBudget,
  projectId
}) => {
  const memberBudgetHash = useSelector(getMemberBudgets);
  const positionHash = useSelector(getPositions);
  const memberBudget = givenMemberBudget || memberBudgetHash[memberBudgetId];

  const isUnassigned = getIsUnassignedMemberBudget(memberBudget);
  const name = useMemberBudgetName({
    memberBudget,
    projectId
  });

  useDebugValue(name);
  useDebugValue({ memberBudget, projectId });
  return {
    memberBudget,
    isUnassigned,
    position: positionHash[memberBudget?.position_id],
    name
  };
};

export default useMemberBudget;
