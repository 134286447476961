import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';
import styled from 'styled-components';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import moment from 'appUtils/momentConfig';
import { FlexRow } from 'components/styles';
import { StyledCalendarIcon } from 'ReportsModule/components/styles';
import OptionsDropdown, { Arrow } from './OptionsDropdown';
import { MonthPicker } from 'components/MonthPicker';

const DateRangeOptions = ({
  activeFilter,
  currentValue,
  defaultValue, // when given/selected, will have same effect as clear
  options,
  optionsHash,
  toggleFormatter,
  onOverrideHandleSelect,
  showMonthPickerForCustom,
  headerText = 'Display',
  fetchDateFormat = 'MM/DD/YYYY',
  isWeekly,
  isDisabled,
  unhideArrowOnHover
}) => {
  const calendarTarget = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [monthPickerOpen, setMonthPickerOpen] = useState(false);
  const dispatch = useDispatch();
  const skipAccountFilterUpdate = !!onOverrideHandleSelect;

  const handleSelect = (range) => {
    if (range === 'custom') {
      showMonthPickerForCustom
        ? setMonthPickerOpen(true)
        : setCalendarOpen(true);
      return;
    }
    if (range === defaultValue) {
      handleClear({ keepStartAndEndDate: true });
      return;
    }

    if (onOverrideHandleSelect) {
      onOverrideHandleSelect(range);
    }
    if (skipAccountFilterUpdate) {
      return;
    }

    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        custom: {
          ...activeFilter.custom,
          range
        }
      })
    );
  };

  const handleCustomRangeSubmit = ({ startDate, endDate }) => {
    if (!startDate?.isValid?.() && !endDate?.isValid?.()) {
      return;
    }
    setCalendarOpen(false);
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        custom: {
          ...activeFilter.custom,
          range: 'custom',
          start_date: moment(startDate).format?.(fetchDateFormat),
          end_date: moment(endDate?.isValid?.() ? endDate : startDate).format?.(
            fetchDateFormat
          )
        }
      })
    );
  };

  const handleClear = ({ keepStartAndEndDate = false } = {}) => {
    const { range, start_date, end_date, ...nextCustom } = activeFilter.custom;
    dispatch(
      updateAccountFilterLocal({
        ...activeFilter,
        ...(keepStartAndEndDate
          ? { custom: { start_date, end_date, ...nextCustom } }
          : { custom: nextCustom })
      })
    );
  };

  const label =
    optionsHash?.[currentValue]?.toggleLabel ||
    toggleFormatter?.({
      range: currentValue,
      customStart: activeFilter.custom.start_date,
      customEnd: activeFilter.custom.end_date
    }) ||
    optionsHash?.[currentValue]?.label;

  const renderToggle = () => {
    return (
      <StyledFlexRow
        unhideArrowOnHover={unhideArrowOnHover}
        onClick={isWeekly ? () => setCalendarOpen(true) : undefined}
      >
        <StyledCalendarIcon height="12px" width="12px" />
        <span data-label={label}>{label}</span>
        <Arrow className="options-dropdown-arrow" />
      </StyledFlexRow>
    );
  };

  return (
    <div ref={calendarTarget}>
      <OptionsDropdown
        currentValue={currentValue}
        onSelect={handleSelect}
        options={options}
        headerText={headerText}
        renderToggle={renderToggle}
        disableDropdown={isWeekly}
        disableToggle={isDisabled}
        testId="date-range"
      />
      {monthPickerOpen && (
        <MonthPicker
          isOpen
          toggle={() => setMonthPickerOpen(false)}
          startDate={
            activeFilter.custom?.range === 'custom'
              ? activeFilter.custom.start_date
              : undefined
          }
          onClear={() => {
            setMonthPickerOpen(false);
            handleClear({ keepStartAndEndDate: false });
          }}
          onSubmit={handleCustomRangeSubmit}
          targetRef={calendarTarget}
        />
      )}
      {calendarOpen && (
        <DateRangeCalendar
          customInput={() => null}
          startOpen
          target={calendarTarget}
          onClose={() => setCalendarOpen(false)}
          onSubmit={handleCustomRangeSubmit}
          isWeekly={isWeekly}
          itemStartDate={activeFilter.custom.start_date}
          itemEndDate={activeFilter.custom.end_date}
          showClear={
            (activeFilter.custom.range || activeFilter.custom.range === 0) &&
            activeFilter.custom.start_date &&
            activeFilter.custom.end_date
          }
          onClear={handleClear}
        />
      )}
    </div>
  );
};

export default React.memo(DateRangeOptions);

const StyledFlexRow = styled(FlexRow)`
  ${({ unhideArrowOnHover }) =>
    unhideArrowOnHover &&
    `
  span::before {
    display: block;
    content: attr(data-label);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  .options-dropdown-arrow {
    visibility: hidden;
  }
  &:hover {
    font-weight: 600;
    .options-dropdown-arrow {
      visibility: visible;
    }
  }
  `}
`;
