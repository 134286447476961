import styled from 'styled-components';
import theme from 'theme';
import Toggle, { ToggleHandle, ToggleLabel } from 'components/Toggle';
export interface VisibilitySettingsParams {
  options: {
    icon: JSX.Element;
    title: string;
    settingName: string;
    isChecked: boolean;
  }[];
  handleChange: (settingName: string, value: boolean) => void;
}

const VisibilitySettings = (props: VisibilitySettingsParams) => {
  const { options, handleChange } = props;

  return (
    <Container>
      <Title>Display Settings</Title>
      <SubTitle> Show/Hide </SubTitle>
      {options.map((setting, index) => (
        <Row key={index}>
          <SettingName>
            <IconContainer>{setting.icon}</IconContainer>
            {setting.title}
          </SettingName>
          <Toggle
            value={setting.isChecked}
            setValue={() =>
              handleChange(setting.settingName, !setting.isChecked)
            }
          />
        </Row>
      ))}
    </Container>
  );
};
const Container = styled.div`
  width: 242px;
  padding: 20px;
`;

const Title = styled.div`
  color: ${theme.colors.colorPureBlack};
  font-size: 16px;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorLightGray15};
  padding-bottom: 5px;
  margin-top: -4px;
  margin-left: 1px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  ${ToggleLabel} {
    width: 25px;
    height: 13px;
    &::after {
      width: 11px;
      height: 11px;
      margin-left: 1px;
      margin-top: 1px;
    }
  }
  ${ToggleHandle} {
    width: 25px;
    height: 13px;
    &:checked + ${ToggleLabel} {
      &::after {
        width: 11px;
        height: 11px;
        margin-top: 1px;
        margin-left: 13px;
      }
    }
  }
`;

const SettingName = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0px;
`;

const IconContainer = styled.div`
  margin-right: 8px;
  margin-top: -2px;
`;

export default VisibilitySettings;
