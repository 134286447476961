import { put, call, select, race, cancelled, take } from 'redux-saga/effects';
import includes from 'lodash/includes';

import { statusCodeMessage } from 'appConstants/httpStatusCodes';
import { request, success, failure, abort } from './actions';

const HOST = process.env.GATEWAY_HOST;

const controller = new AbortController();

const checkStatus = async (response, method) => {
  if (response.status >= 200 && response.status < 300) {
    if (method === 'DELETE' || response.status === 204) {
      const text = await response.text();
      const result = {
        response,
        data: text
      };
      return result;
    }
    const json = await response.json();
    const result = {
      response,
      data: json
    };
    return result;
  }

  const errortext = statusCodeMessage[response.status] || response.statusText;
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  error.errortext = errortext;
  throw error;
};

export function callApiV2(token, endpoint, options) {
  const apiUrl = process.env.MOSAIC_API_URL;
  const url = endpoint.indexOf(apiUrl) === -1 ? apiUrl + endpoint : endpoint;

  const defaultOptions = {
    headers: {
      signal: controller.signal,
      credentials: 'include',
      Authorization: `Bearer ${token}`,
      mode: 'cors',
      'Accept-Version': 'v2',
      'mosaic-host': HOST
    }
  };

  const newOptions = { ...defaultOptions, ...options };
  const { method } = newOptions;
  if (includes(['POST', 'PUT', 'PATCH', 'DELETE'], method)) {
    if (!(newOptions.body instanceof window.FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...newOptions.headers
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // Request body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers
      };
    }
  }
  return fetch(url, newOptions).then((response) =>
    checkStatus(response, method)
  );
}

export default function* apiRequestSaga(endpoint, options, action) {
  const getToken = (state) => state.auth.token;
  const token = yield select(getToken);

  yield put({
    type: request(action.type),
    payload: { request: { endpoint, options } }
  });

  try {
    const { response, cancel } = yield race({
      response: call(callApiV2, token, endpoint, options),
      cancel: take('LOGOUT')
    });
    yield put({
      type: success(action.type),
      payload: {
        data: response.data,
        meta: {
          action,
          response: response.response
        }
      }
    });
  } catch (error) {
    const status = error.name;
    if (status === 401) {
      yield put({ type: 'LOGOUT' });
    }
    yield put({
      type: failure(action.type),
      payload: {
        error,
        action
      }
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: abort(action.type)
      });
    }
  }
}
