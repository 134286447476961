import React from 'react';
import { connect } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { updateAccountFilterLocal } from 'actionCreators';
import styled from 'styled-components';
import OptionsDropdown from '../options/OptionsDropdown';

// used for is_archived
export const PROJECT_STATUSES = {
  ALL: 2,
  ACTIVE: 1,
  ARCHIVED: 0
};

const PROJECT_STATUS_LABELS = {
  [PROJECT_STATUSES.ALL]: 'All',
  [PROJECT_STATUSES.ACTIVE]: 'Active',
  [PROJECT_STATUSES.ARCHIVED]: 'Archived'
};

export const PROJECT_STATUS_PARAM_VALUES = {
  [PROJECT_STATUSES.ALL]: undefined,
  [PROJECT_STATUSES.ACTIVE]: false,
  [PROJECT_STATUSES.ARCHIVED]: true
};
const PROJECT_STATUS_OPTIONS = [
  {
    label: PROJECT_STATUS_LABELS[PROJECT_STATUSES.ALL],
    value: PROJECT_STATUSES.ALL
  },
  {
    label: PROJECT_STATUS_LABELS[PROJECT_STATUSES.ACTIVE],
    value: PROJECT_STATUSES.ACTIVE
  },
  {
    label: PROJECT_STATUS_LABELS[PROJECT_STATUSES.ARCHIVED],
    value: PROJECT_STATUSES.ARCHIVED
  }
];

const options = PROJECT_STATUS_OPTIONS;

const getCurrentValue = (activeFilter) => {
  if (activeFilter.custom?.projectStatus === true) {
    return PROJECT_STATUSES.ARCHIVED;
  }
  if (activeFilter.custom?.projectStatus === false) {
    return PROJECT_STATUSES.ACTIVE;
  }
  return PROJECT_STATUSES.ALL;
};

const ProjectStatusOptionsDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  isHidden
}) => {
  const currentValue = getCurrentValue(activeFilter);

  const handleSelect = (nextValue) => {
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        projectStatus: PROJECT_STATUS_PARAM_VALUES[nextValue]
      }
    });
  };

  const renderToggle = () => {
    return <StyledToggle>{PROJECT_STATUS_LABELS[currentValue]}</StyledToggle>;
  };

  if (isHidden) return null;

  return (
    <OptionsDropdownContainer>
      <OptionsDropdown
        currentValue={currentValue}
        onSelect={handleSelect}
        options={options}
        renderToggle={renderToggle}
      />
    </OptionsDropdownContainer>
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ProjectStatusOptionsDropdown);

const dimOnHoverStyle = 'filter: brightness(98%);';

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;
  background: ${({ theme }) => theme.colors.colorPaleGray2};
  padding: 3px 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorMediumGray9};

  border-radius: 6px;
  &:hover {
    ${dimOnHoverStyle}
  }
  ${(props) => props.isOpen && dimOnHoverStyle}
  cursor: pointer;
`;

const OptionsDropdownContainer = styled.div`
  margin-right: 4px;
  margin-bottom: 2px;
  display: flex;
  height: 100%;
  & > .options-dropdown {
    display: flex;
    height: 100%;
  }
`;
