import styled from 'styled-components';
import theme from 'theme';
const { colors } = theme;

const taskListBufferSpace = '180px';

export const Page = styled.div`
  width: 100%;
  height: ${({ currentView }) =>
    currentView === 'tasks' ? `calc(100vh - ${taskListBufferSpace})` : '100%'};
  background-color: ${colors.colorTranslucentGray4};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding-left: 45px;
`;

export const MemberName = styled.span`
  color: ${colors.colorSemiDarkGray1};
  font-size: 15px;
  font-weight: bold;
  margin: 0px 10px;
`;
