import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTimerDate } from 'actionCreators';
import { getTimerDate } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import DateNavLeftArrowIcon from 'icons/DateNavLeftArrowIcon';
import DateNavRightArrowIcon from 'icons/DateNavRightArrowIcon';
import moment from 'moment';

const TimerDateNav = () => {
  const dispatch = useDispatch();
  const unformattedTimerDate = useSelector(getTimerDate);
  const timerDate = moment(unformattedTimerDate);

  const prevDay = () => {
    const newDate = timerDate.clone().add(-1, 'days');
    dispatch(
      setTimerDate({
        timerDate: newDate.format('YYYY-MM-DD')
      })
    );
  };

  const nextDay = () => {
    const newDate = timerDate.clone().add(1, 'days');
    dispatch(
      setTimerDate({
        timerDate: newDate.format('YYYY-MM-DD')
      })
    );
  };

  const displayDate = timerDate.clone().format('ll');

  return (
    <DateNavContainer>
      <StyledTitle>{displayDate}</StyledTitle>
      <ArrowIconsContainer>
        <ArrowContainer onClick={prevDay} data-testid="date-nav-prev">
          <DateNavLeftArrowIcon />
        </ArrowContainer>
        <ArrowContainer onClick={nextDay} data-testid="date-nav-next">
          <DateNavRightArrowIcon />
        </ArrowContainer>
      </ArrowIconsContainer>
    </DateNavContainer>
  );
};

/* --------------------Styling--------------- */

const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${({ isToday }) =>
    isToday ? `#EB8429` : theme.colors.colorMediumGray9};
  line-height: 24px;
  min-width: fit-content;
  cursor: pointer;
`;

const DateNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  min-width: fit-content;
  width: 175px;
  margin-left: 41px;
  margin-top: -5px;
`;

const ArrowIconsContainer = styled.div`
  display: flex;
`;

const ArrowContainer = styled.div`
  width: 28px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.colorLightGray19};
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export default TimerDateNav;
