import React from 'react';
import { connect } from 'react-redux';
import {
  Left,
  Right,
  ModalButtonsRow,
  CancelButton,
  ConfirmButton,
  RateConfirmRow,
  RateConfirmBox,
  RateDateContainer,
  RateMemberDescription,
  CopyContainer,
  CopyHeader,
  CopyParagraph,
  StyledDateChangeConfirmation,
  AffectedDate
} from 'BudgetModule/components/styles';
import {
  getEarlierRate,
  getLaterRate,
  getEarliestRate,
  getTeamRates
} from 'BudgetModule/selectors';
import { EARLIEST_RATE_START_DISPLAY_PROJECT } from 'BudgetModule/constants';

const copyHash = {
  end_date: 'start date for the next',
  start_date: 'end date for the prior'
};

class DateChangeConfirmation extends React.Component {
  handleCancel = () => this.props.handleCancel();
  handleConfirm = () => this.props.handleConfirm();

  formatSelectedDate = () => this.props.selectedDate.clone().format('M/DD/YY');
  renderSelectedDate = () => (
    <AffectedDate affected>{this.formatSelectedDate()}</AffectedDate>
  );

  renderRateDescription = (item) => (
    <RateMemberDescription>
      ${item.rate.rate} - {item.rate.description}
    </RateMemberDescription>
  );

  renderLaterRate = () => {
    const { laterRate } = this.props;
    return laterRate ? (
      <RateConfirmRow>
        {this.renderRateDescription(laterRate)}
        <RateDateContainer>
          {this.renderSelectedDate()} -<div>{laterRate.end_date}</div>
        </RateDateContainer>
      </RateConfirmRow>
    ) : null;
  };

  renderEarlierRate = () => {
    const { earlierRate, earliestRate } = this.props;
    const isEarliestRate = earlierRate === earliestRate;

    const earlierRateDisplayDate = isEarliestRate
      ? EARLIEST_RATE_START_DISPLAY_PROJECT
      : earlierRate.start_date;
    return earlierRate ? (
      <RateConfirmRow>
        {this.renderRateDescription(earlierRate)}
        <RateDateContainer>
          <AffectedDate>{earlierRateDisplayDate}</AffectedDate>-
          {this.renderSelectedDate()}
        </RateDateContainer>
      </RateConfirmRow>
    ) : null;
  };

  renderItems = () => (
    <RateConfirmBox>
      {this.renderEarlierRate()}
      {this.renderLaterRate()}
    </RateConfirmBox>
  );

  multipleRatesAffected = () => this.props.earlierRate && this.props.laterRate;

  renderCopy = () => (
    <CopyContainer>
      <CopyHeader>
        Are you sure you want to edit the date for this rate?
      </CopyHeader>
      {this.multipleRatesAffected() && (
        <CopyParagraph>
          The change will also update the {copyHash[this.props.dateType]} rate
          as shown above.
        </CopyParagraph>
      )}
      <CopyParagraph>
        When dates are edited for rates, previously recorded time on timesheets
        will not be affected.
      </CopyParagraph>
    </CopyContainer>
  );

  renderButtons = () => {
    return (
      <ModalButtonsRow>
        <Left />
        <Right>
          <CancelButton onClick={this.handleCancel}>Cancel</CancelButton>
          <ConfirmButton onClick={this.handleConfirm}>Confirm</ConfirmButton>
        </Right>
      </ModalButtonsRow>
    );
  };

  render() {
    return (
      <StyledDateChangeConfirmation>
        {this.renderItems()}
        {this.renderCopy()}
        {this.renderButtons()}
      </StyledDateChangeConfirmation>
    );
  }
}

DateChangeConfirmation.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  rates: getTeamRates(state),
  earliestRate: getEarliestRate(state, ownProps),
  earlierRate: getEarlierRate(state, ownProps),
  laterRate: getLaterRate(state, ownProps)
});

export default connect(mapStateToProps)(DateChangeConfirmation);
