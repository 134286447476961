import { put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as entityActions from '../actions/phaseEntityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../service/api/phases';
import * as budgetAPI from 'BudgetModule/api';
import { getAuthToken, getSplitFlags, getSelectedTeamId } from 'selectors';
import * as actionCreators from 'actionCreators';
import { fetchMemberBudgets } from 'BudgetModule/entityActions';
import { deletePhaseMapping } from 'QuickbooksModule/entityActions';
import * as qbAPI from 'QuickbooksModule/api';
import * as BudgetModuleActionCreators from 'BudgetModule/actionCreators';
import pick from 'lodash/pick';
import { GENERIC_ACTION } from 'appConstants';
import { EDIT_PHASE_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const {
  fetchPhasesByProjectIds,
  fetchPhases,
  fetchPhaseNames,
  fetchFilteredPhases,
  fetchPhaseTotals,
  fetchPhaseTotalsByBoard,
  fetchPhaseTotalsBudgetReport,
  createPhase,
  predictPhase,
  updatePhase,
  convertPhaseToDefault,
  deletePhase,
  archivePhase,
  fetchPhaseTemplates,
  createPhaseTemplate,
  createMilestoneTemplate,
  reorderMilestoneTemplates,
  updatePhaseTemplate,
  deletePhaseTemplate,
  reorderPhaseTemplates,
  deleteMemberFromPhase,
  fetchPhaseMembers,
  createPhaseMembers,
  archivePhaseMember,
  deletePhaseMember,
  hardDeletePhase,
  predictWorkdaysAndUpdatePhase,
  updatePhaseMembership
} = entityActions;

export function* fetchPhaseTotalsWorker(action) {
  const token = yield select(getAuthToken);
  const splitFlags = yield select(getSplitFlags);
  const { projectId, filterStateId, initial, ...body } = action.payload;

  yield fetchEntity(
    fetchPhaseTotals,
    api.fetchPhaseTotals,
    projectId,
    [token, !splitFlags.scope ? { ...body, is_scope_enabled: false } : body], // todo remove once flag removed
    action
  );
}
export function* fetchPhaseTotalsByBoardWorker(action) {
  const token = yield select(getAuthToken);
  const { boardId, projectIds } = action.payload;

  yield fetchEntity(
    fetchPhaseTotalsByBoard,
    api.fetchPhaseTotalsByBoard,
    boardId,
    [token, projectIds],
    action
  );
}
export function* fetchPhaseTotalsBudgetReportWorker(action) {
  const token = yield select(getAuthToken);
  const body = action.payload;

  yield fetchEntity(
    fetchPhaseTotalsBudgetReport,
    api.fetchPhaseTotalsBudgetReport,
    undefined,
    [token, body],
    action
  );
}

export function* fetchPhasesByProjectIdsWorker(action) {
  const token = yield select(getAuthToken);
  const { projectIds, all, limit, offset, search, budgetAccountId } =
    action.payload;
  let length = 0;
  if (all) {
    yield fetchEntity(
      fetchPhasesByProjectIds,
      api.fetchPhasesByProjectIds,
      undefined,
      [
        token,
        {
          all,
          limit,
          offset,
          search_text: search,
          budget_account_id: budgetAccountId
        }
      ],
      action
    );
  } else {
    while (length <= projectIds.length && projectIds.length > 0) {
      const batchedProjectIds = projectIds.slice(length, length + 25);
      if (batchedProjectIds.length) {
        yield fetchEntity(
          fetchPhasesByProjectIds,
          api.fetchPhasesByProjectIds,
          batchedProjectIds,
          [token, { budget_account_id: budgetAccountId }],
          action
        );
      }
      length = length + 25;
    }
  }
}
export function* fetchPhaseNamesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;
  yield fetchEntity(
    fetchPhaseNames,
    api.fetchPhaseNames,
    teamId,
    [token],
    action
  );
}

export function* fetchFilteredPhasesWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamId,
    projectIds,
    accountIds,
    all,
    limit,
    offset,
    isDefault,
    startDateFrom,
    startDateTo,
    endDateFrom,
    endDateTo
  } = action.payload;
  yield fetchEntity(
    fetchFilteredPhases,
    api.fetchFilteredPhases,
    teamId,
    [
      token,
      {
        all,
        limit,
        offset,
        start_start_date: startDateFrom,
        start_end_date: startDateTo,
        end_start_date: endDateFrom,
        end_end_date: endDateTo,
        project_ids: projectIds,
        account_ids: accountIds,
        is_default: isDefault
      }
    ],
    action
  );
}

export function* fetchPhasesWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId } = action.payload;
  yield fetchEntity(fetchPhases, api.fetchPhases, projectId, [token], action);
}
export function* createPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    name,
    isBudget = true,
    startDate = null,
    endDate = null,
    newPhaseMemberIds = [],
    isMain,
    onSuccess = []
  } = action.payload;

  const { error, response } = yield changeEntity(
    createPhase,
    api.createPhase,
    [token, projectId, name, isBudget, startDate, endDate, isMain],
    action
  );
  if (!error && response && newPhaseMemberIds.length > 0) {
    yield changeEntity(
      createPhaseMembers,
      api.createPhaseMembers,
      [token, response.id, newPhaseMemberIds],
      action
    );
  }
  yield delay(500);
  yield put(
    actionCreators.fetchAllProjects({
      projectIds: projectId
    })
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

const availableFieldsForEditPhaseDatesPermission = [
  'id',
  'project_id',
  'start_date',
  'end_date',
  'total_work_days'
];

export function* predictWorkdaysAndUpdatePhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { startDate, endDate, phase, dependencyInfos } = action.payload;
  const { meta } = action;

  const teamId = yield select(getSelectedTeamId);

  const permissionCheckParams = {
    permissions: {
      teamId: teamId,
      projectId: phase?.project_id
    },
    UI_CHECK: true
  };

  const editPhasePermission = yield put(
    actionCreators.updatePhase(permissionCheckParams)
  );

  // user can have permission to update phase partially
  const editPhaseDatesPermission = yield put(
    actionCreators.predictWorkdaysAndUpdatePhase(permissionCheckParams)
  );

  if (!editPhasePermission && !editPhaseDatesPermission) {
    // has no permission to edit phase
    yield put(
      actionCreators.handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: EDIT_PHASE_TIP
      })
    );
    return;
  }

  const { error, response } = yield changeEntity(
    predictWorkdaysAndUpdatePhase,
    api.predictPhase,
    [
      token,
      phase?.project_id,
      {
        ...phase,
        start_date: startDate,
        end_date: endDate,
        total_work_days: undefined
      }
    ],
    action
  );

  if (error) {
    // if prediction is failed, should skip below steps
    return;
  }

  let payloadToUse = {
    ...pick(
      phase,
      'id',
      'name',
      'total',
      'is_complete',
      'fee_type',
      'activity_order',
      'project_id',
      'member_budget_orders'
    ),
    start_date: response.start_date,
    end_date: response.end_date,
    total_work_days: response.total_work_days,
    dependency_infos: dependencyInfos
  };

  // if the member has only permission to edit phase date, filter out not allowed fields
  if (!editPhasePermission && editPhaseDatesPermission) {
    payloadToUse = pick(
      payloadToUse,
      ...availableFieldsForEditPhaseDatesPermission
    );
  }

  yield changeEntity(
    updatePhase,
    api.updatePhase,
    [token, payloadToUse],
    action
  );

  yield fetchEntity(
    fetchPhases,
    api.fetchPhases,
    phase.project_id,
    [token],
    action
  );

  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [phase.project_id],
    [token],
    action
  );

  if (!error && response && meta?.onSuccess) {
    meta.onSuccess.forEach(({ successAction, selector }) => {
      successAction(selector(action.payload, response));
    });
  }
}

export function* predictPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { project_id } = action.payload;
  yield changeEntity(
    predictPhase,
    api.predictPhase,
    [token, project_id, action.payload],
    action
  );
}
export function* updatePhaseWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    id,
    memberBudgetOrder,
    total,
    name,
    startDate,
    endDate,
    workDays,
    complete,
    feeType,
    activityOrder,
    billable,
    estimatedCost,
    profitPercentage,
    budgetStatus,
    phaseNumber,
    onSuccess = [],
    baselineUserActivityId,
    custom_fields,
    custom_field_internal_label,
    custom_field_value,
    budget_phase_by,
    estimated_hours,
    isArchived,
    budgetFixedFeeWith,
    budgetHourlyWith,
    budgetInternalWith,
    dependencyInfos
  } = action.payload;
  const teamId = yield select(getSelectedTeamId);

  const permissionCheckParams = {
    permissions: {
      teamId: teamId,
      projectId
    },
    UI_CHECK: true
  };

  const editPhasePermission = yield put(
    actionCreators.updatePhase(permissionCheckParams)
  );

  // user can have permission to update phase partially
  const editPhaseDatesPermission = yield put(
    actionCreators.predictWorkdaysAndUpdatePhase(permissionCheckParams)
  );

  if (!editPhasePermission && !editPhaseDatesPermission) {
    // has no permission to edit phase
    yield put(
      actionCreators.handleErrorMessage({
        type: GENERIC_ACTION,
        isUserFriendlyError: true,
        errorMessage: EDIT_PHASE_TIP
      })
    );
    return;
  }

  let payloadToUse = {
    project_id: projectId,
    id,
    member_budget_orders: memberBudgetOrder,
    total,
    name,
    start_date: startDate,
    end_date: endDate,
    total_work_days: workDays,
    complete,
    fee_type: feeType,
    activity_order: activityOrder,
    billable,
    baseline_user_activity_id: baselineUserActivityId,
    estimated_cost: estimatedCost,
    profit_percentage: profitPercentage,
    budget_status: budgetStatus,
    phase_number: phaseNumber,
    dependency_infos: dependencyInfos,
    custom_fields,
    custom_field_internal_label,
    custom_field_value,
    budget_phase_by,
    estimated_hours,
    is_archived: isArchived,
    budget_fixed_fee_with: budgetFixedFeeWith,
    budget_hourly_with: budgetHourlyWith,
    budget_internal_with: budgetInternalWith
  };

  if (!editPhasePermission && editPhaseDatesPermission) {
    payloadToUse = pick(
      payloadToUse,
      ...availableFieldsForEditPhaseDatesPermission
    );
  }

  const { error, response } = yield changeEntity(
    updatePhase,
    api.updatePhase,
    [token, payloadToUse],
    action
  );
  // Note placement of onSuccess here is before refetches
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
  if (total !== undefined || activityOrder) {
    yield put(
      actionCreators.fetchPhaseTotals({
        projectId
      })
    );
  }

  yield fetchEntity(fetchPhases, api.fetchPhases, projectId, [token], action);

  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
}
export function* convertPhaseToDefaultWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, id } = action.payload;

  yield changeEntity(
    convertPhaseToDefault,
    api.convertPhaseToDefault,
    [token, projectId, id],
    action
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield fetchEntity(fetchPhases, api.fetchPhases, projectId, [token], action);
}
export function* deletePhaseWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { projectId, id, onSuccess = [] } = action.payload;
  const { error, response } = yield changeEntity(
    deletePhase,
    api.deletePhase,
    [token, projectId, id],
    action,
    action.payload
  );
  // Note placement of onSuccess here is before refetches
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }

  yield changeEntity(
    deletePhaseMapping,
    qbAPI.deletePhaseMapping,
    [token, teamId, id],
    action,
    action.payload
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield put(
    actionCreators.fetchAllProjects({
      projectIds: projectId
    })
  );
  // yield put(
  //   actionCreators.fetchMemberBudgets({
  //     projectId
  //   })
  // );
}

export function* hardDeletePhaseWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { projectId, id, onSuccess = [] } = action.payload;
  const { error, response } = yield changeEntity(
    hardDeletePhase,
    api.hardDeletePhase,
    [token, projectId, id],
    action,
    action.payload
  );
  // Note placement of onSuccess here is before refetches
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }

  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield put(
    actionCreators.fetchAllProjects({
      projectIds: projectId
    })
  );
  yield put(
    BudgetModuleActionCreators.fetchMemberBudgets({
      projectId
    })
  );
}

export function* archivePhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, id } = action.payload;
  yield changeEntity(
    archivePhase,
    api.archivePhase,
    [token, projectId, id],
    action,
    action.payload
  );
  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  // yield put(
  //   actionCreators.fetchMemberBudgets({
  //     projectId
  //   })
  // );
}
export function* fetchPhaseTemplatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchPhaseTemplates,
    api.fetchPhaseTemplates,
    teamId,
    [token],
    action
  );
}

export function* createPhaseTemplateWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, name } = action.payload;

  yield changeEntity(
    createPhaseTemplate,
    api.createPhaseTemplate,
    [token, teamId, name],
    action
  );
  yield put(
    actionCreators.fetchPhaseTemplates({
      teamId
    })
  );
}
export function* updatePhaseTemplateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, name } = action.payload;

  yield changeEntity(
    updatePhaseTemplate,
    api.updatePhaseTemplate,
    [token, id, name],
    action
  );
}
export function* createMilestoneTemplateWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, name } = action.payload;

  yield changeEntity(
    createMilestoneTemplate,
    api.createMilestoneTemplate,
    [token, teamId, name],
    action
  );
  yield put(
    actionCreators.fetchPhaseTemplates({
      teamId
    })
  );
}
export function* reorderMilestoneTemplatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, milestoneTemplateOrder } = action.payload;

  yield changeEntity(
    reorderMilestoneTemplates,
    api.reorderMilestoneTemplates,
    [token, teamId, milestoneTemplateOrder],
    action
  );
}
export function* deletePhaseTemplateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, teamId } = action.payload;

  yield changeEntity(
    deletePhaseTemplate,
    api.deletePhaseTemplate,
    [token, id],
    action
  );
  yield put(actionCreators.fetchPhaseTemplates({ teamId }));
}

export function* reorderPhaseTemplatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, phaseTemplateOrder } = action.payload;

  yield changeEntity(
    reorderPhaseTemplates,
    api.reorderPhaseTemplates,
    [token, teamId, phaseTemplateOrder],
    action
  );
}

export function* deleteMemberFromPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { memberBudgetId, phaseId, projectId } = action.payload;
  yield changeEntity(
    deleteMemberFromPhase,
    api.deleteMemberFromPhase,
    [token, memberBudgetId, phaseId],
    action,
    action.payload
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield fetchEntity(
    fetchPhaseTotals,
    api.fetchPhaseTotals,
    projectId,
    [token],
    action
  );
  yield fetchEntity(
    fetchMemberBudgets,
    budgetAPI.fetchMemberBudgets,
    projectId,
    [token],
    action
  );
}

export function* createBulkPhasesWorker(action) {
  const token = yield select(getAuthToken);

  const {
    addedPhaseNames,
    convertedPhases,
    projectId,
    orderedActivePhases, // this is not an actual phase_order from project where it's passed
    isBudget = true
  } = action.payload;
  const phaseOrder = orderedActivePhases.map((phase) => phase.id);
  for (const name of addedPhaseNames) {
    const { error, response } = yield changeEntity(
      createPhase,
      api.createPhase,
      [token, projectId, name, isBudget],
      action
    );
    if (response && !error) {
      phaseOrder.unshift(response.id);
    }
  }
  for (const phase of convertedPhases) {
    const { error, response } = yield changeEntity(
      updatePhase,
      api.updatePhase,
      [
        token,
        {
          project_id: phase.project_id,
          id: phase.id,
          member_budget_orders: phase.member_budget_orders,
          total: phase.total,
          name: phase.name,
          start_date: phase.start_date,
          end_date: phase.end_date,
          total_work_days: phase.total_work_days,
          complete: phase.is_complete,
          fee_type: phase.fee_type
        }
      ],
      action
    );
    if (response && !error) {
      phaseOrder.unshift(response.id);
    }
  }

  yield put(
    actionCreators.updateProjectModules({
      projectId
      // leaving this to give context when necessary.
      // phaseOrder: Array.from(new Set(phaseOrder))
    })
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield fetchEntity(
    fetchPhaseTotals,
    api.fetchPhaseTotals,
    projectId,
    [token],
    action
  );
}

export function* fetchPhaseMembersWorker(action) {
  const token = yield select(getAuthToken);
  const { phaseId } = action.payload;
  yield fetchEntity(
    fetchPhaseMembers,
    api.fetchPhaseMembers,
    phaseId,
    [token],
    action
  );
}
export function* createPhaseMembersWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    phaseId,
    accountIds,
    memberBudgetIds,
    onSuccess = [],
    shouldRefetch = true // default behaviour will refetch
  } = action.payload;

  const { error, response } = yield changeEntity(
    createPhaseMembers,
    api.createPhaseMembers,
    [token, phaseId, accountIds, memberBudgetIds],
    action,
    action.payload
  );

  if (shouldRefetch) {
    yield fetchEntity(
      fetchPhasesByProjectIds,
      api.fetchPhasesByProjectIds,
      [projectId],
      [token],
      action
    );
    yield put(actionCreators.fetchPhaseTotals({ projectId }));
    yield put(
      actionCreators.fetchAllProjects({
        projectIds: [projectId]
      })
    );
    yield put(actionCreators.fetchProjectById(projectId));
    yield put(actionCreators.fetchProjectTeam(projectId));
    yield put(actionCreators.fetchAllProjects({ projectIds: [projectId] })); // Why is this action called twice? Seems unnecessary
    yield put(actionCreators.fetchTeamMembers());
  }

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* archivePhaseMemberWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, id, archive, onSuccess = [] } = action.payload;
  const { error, response } = yield changeEntity(
    archivePhaseMember,
    api.archivePhaseMember,
    [token, id, archive],
    action,
    action.payload
  );

  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield put(
    actionCreators.fetchAllProjects({
      projectIds: [projectId]
    })
  );
  yield put(actionCreators.fetchProjectById(projectId));
  yield put(
    BudgetModuleActionCreators.fetchMemberBudgets({
      projectId
    })
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* deletePhaseMemberWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, id } = action.payload;
  yield changeEntity(
    deletePhaseMember,
    api.deletePhaseMember,
    [token, id],
    action,
    action.payload
  );

  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield fetchEntity(
    fetchPhasesByProjectIds,
    api.fetchPhasesByProjectIds,
    [projectId],
    [token],
    action
  );
  yield put(
    actionCreators.fetchAllProjects({
      projectIds: [projectId]
    })
  );
  yield put(actionCreators.fetchProjectById(projectId));
  // yield put(
  //   actionCreators.fetchMemberBudgets({
  //     projectId
  //   })
  // );
}
export function* updatePhaseMembershipWorker(action) {
  const token = yield select(getAuthToken);
  const { required_hours, start_date, end_date, phaseMembershipId } =
    action.payload;

  const body = {
    start_date,
    end_date,
    required_hours
  };

  yield changeEntity(updatePhaseMembership, api.updatePhaseMembership, [
    token,
    phaseMembershipId,
    body
  ]);
}
