import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteCheckin,
  updateCheckin,
  createCheckin,
  fetchProjectById,
  setSelectedProject,
  openProjectModal,
  startTimer,
  endTimer
} from 'actionCreators';
import {
  getProjectHash,
  getFlatPhasesHash,
  getAllActivityRowInfo,
  getMe,
  getPlannerDateHeader,
  getActiveTimer
} from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import TextareaAutosize from 'react-textarea-autosize';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';
import FolderIcon from 'icons/FolderIcon';
import CopyIcon from 'icons/CopyIcon';
import ThinCloseIcon from 'icons/ThinCloseIcon';
import UpdateProjectIcon from 'icons/UpdateProjectIcon';
import moment from 'moment';
import { isToday } from 'appUtils/momentUtils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ReactComponent as PlayButton } from 'icons/checkinplaybutton.svg';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import NumberFormat from 'react-number-format';

const StyledFolderIcon = styled(FolderIcon)`
  margin-left: 5px;
  margin-right: 2px;
  margin-bottom: 2px;
  width: 10px;
  height: 10px;
  path {
    stroke: #808080;
    stroke-width: 3;
    fill: transparent;
  }
`;

const PhaseNameRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.colorCalendarGray};
  margin-left: 9px;
  min-height: 16px;

  visibility: ${({ shouldHide }) => (shouldHide ? 'hidden' : 'visible')};
`;

const PhaseRowText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
  margin-left: 6px;
  font-size: 12px;
  line-height: 17px;
  max-width: 175px;

  &.work-category-inline {
    display: ${({ showInLineWorkCategory }) =>
      showInLineWorkCategory ? 'inline' : 'none'};
    margin-left: 1px;
  }
`;

const ItemText = styled(TextareaAutosize)`
  margin-left: 4px;
  display: inline-block;
  margin-top: 15px;
  border: none;
  resize: none;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  padding: 0px 10px;
  padding-top: 5px;
  background: ${({ isEditing }) =>
    isEditing ? `white` : theme.colors.colorLightGray19};
  border: ${({ isEditing }) =>
    isEditing
      ? `0.5px solid ${theme.colors.colorRoyalBlue}`
      : `0.5px solid ${theme.colors.colorLightGray19}`};
  &::placeholder {
    color: ${({ isNotification, isEditing }) =>
      !isNotification && !isEditing && theme.colors.colorRoyalBlue};
    font-size: 14px;
  }
  overflow: hidden;
  min-height: 33px;
`;

const IconContainer = styled.div`
  height: fit-content;
  margin-right: 9px;
  cursor: pointer;
`;

const PlayIconContainer = styled(IconContainer)`
  margin-right: 8px;
  margin-top: 1px;
  path {
    fill: ${({ isActive }) =>
      isActive ? theme.colors.colorCalendarBlue : 'none'};
    stroke: ${({ isActive }) =>
      isActive
        ? theme.colors.colorCalendarBlue
        : theme.colors.colorMediumGray9};
  }
`;

const BoardSettings = styled.div`
  display: flex;
  justify-content: space-around;
  top: 5px;
  right: 0px;
  position: absolute;
  .threeDotMenu {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.colorPureWhite};
    path {
      fill: ${theme.colors.colorLightGray15};
      stroke: ${theme.colors.colorLightGray15};
    }
  }
`;

const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  color: ${(props) => props.theme.colors.colorSemiDarkGray3};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const BoardText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarGray};
  min-height: 13px;
  display: flex;
  align-items: center;
  margin-left: 13px;
  justify-content: space-between;
`;

const HoverOptions = styled.div`
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 25px;
  right: 35px;
  position: absolute;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: fit-content;
  padding: 9px 0 12px 7px;
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  background: ${theme.colors.colorLightGray19};
  border-radius: 6px;
  padding: 12px 35px;
  &:hover {
    ${BoardSettings} {
      .threeDotMenu {
        path {
          fill: ${theme.colors.colorCalendarBlue};
          stroke: ${theme.colors.colorCalendarBlue};
        }
        &:hover {
          background: ${theme.colors.colorCalendarBlue};
          path {
            fill: ${theme.colors.colorPureWhite};
            stroke: ${theme.colors.colorPureWhite};
          }
        }
      }
    }
    ${BoardText} {
      visibility: visible;
    }
    ${HoverOptions} {
      visibility: ${({ isEditing }) => !isEditing && 'visible'};
    }
  }
`;

const ProjectNameRow = styled.div`
  display: flex;
  align-items: center;
  flex: auto;
`;

const ProjectInfoContainer = styled.div`
  display: flex;
  max-width: 87%;
  flex: auto;
`;

const ProjectNameContainer = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
`;

const ProjectDescription = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

const ProjectDot = styled.div`
  background: ${calculateProjectColorPreference};
  height: 9px;
  width: 9px;
  border-radius: 90px;
  margin-right: 5.5px;
  flex-shrink: 0;
`;

const ProjectName = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ProjectDash = styled.div`
  margin: 0px 3px;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorCalendarGray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ListItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`;

const ListItemFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
`;
const ButtonContainer = styled.div`
  display: flex;
  margin-left: 5px;
  margin-top: 5px;
  min-width: 131px;
  justify-content: space-between;
`;

const HoursContainer = styled.div`
  min-width: 13%;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: ${({ isEditing }) => (isEditing ? '2px' : '0px')};
  position: relative;
  top: 20px;
  margin-left: auto;
`;

const WorkedHours = styled.input`
  color: ${({ isNotification }) =>
    !isNotification
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  width: ${({ isEditing }) => (isEditing ? `45px` : `80px`)};
  text-align: right;
  background: ${({ isEditing }) =>
    isEditing ? `white` : theme.colors.colorLightGray19};
  border: ${({ isEditing }) =>
    isEditing
      ? `0.5px solid ${theme.colors.colorRoyalBlue}`
      : `0.5px solid ${theme.colors.colorLightGray19}`};
  ${({ isEditing }) =>
    isEditing &&
    `padding: 0px 3px;
    margin-right: 3px;
    `}
`;

const HoursDivider = styled.div`
  color: ${theme.colors.colorLightGray15};
`;

const TotalHours = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
`;

const FooterButton = styled.div`
  width: 40px;
  height: 13px;
  font-size: 14px;
  color: ${({ isCancel }) =>
    isCancel ? theme.colors.colorMediumGray9 : theme.colors.colorRoyalBlue};
  cursor: pointer;

  &.check-ins-view-buttons.save-button {
    text-align: center;
    background: ${theme.colors.colorBudgetBlue};
    border: 1px solid ${theme.colors.colorBudgetBlue};
    box-sizing: border-box;
    border-radius: 4px;
    width: 50px;
    color: ${theme.colors.colorPureWhite};
    height: auto;
  }
`;

const DeleteButton = styled.div`
  width: 70px;
  height: 33px;
  border-radius: 3px;
  padding: 4px 12px 4px 12px;
  background: #db3939;
  font-size: 15px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MilestoneIconContainer = styled.div`
  transform: translate(5px, -3px);
  margin-right: 1px;
`;

const StyledWorkCategoryIconContainer = styled.span`
  padding: 0 2px 1px 4px;
  position: relative;
  bottom: 2px;
  visibility: ${({ showIcon }) => (showIcon ? 'visible' : 'hidden')};
`;

const tooltipProps = {
  'data-for': 'app-tooltip',
  'data-html': true,
  'data-class': 'project-info-tooltips break-word-tooltips'
};

const DailyCheckinItem = ({
  checkin,
  totalHours,
  isNotification,
  handleCreateCheckin,
  showButtonTopRight,
  footerButtonClassName,
  showInLineWorkCategory = false,
  filterStateId
}) => {
  const {
    id,
    project_id: projectId,
    phase_id: phaseId,
    activity_id: activityId,
    estimated_hours: estimatedHours,
    title,
    date,
    isWorkplan
  } = checkin;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(title);
  const [newHours, setNewHours] = useState(Number(estimatedHours || 0));
  const dispatch = useDispatch();
  const me = useSelector(getMe);
  const projectHash = useSelector(getProjectHash);
  const phasesHash = useSelector(getFlatPhasesHash);
  const activities = useSelector(getAllActivityRowInfo);
  const plannerDateHeader = useSelector(getPlannerDateHeader);
  const project = projectHash[projectId];
  const projectNumber = project?.project_number;
  const boardName = project?.board?.name;
  const projectDescription = project?.description;
  const projectName = project?.title;
  const phase = phaseId ? phasesHash[phaseId] : null;
  const activityName =
    activityId && !activities[activityId]?.is_default
      ? activities[activityId]?.title
      : '';
  const activeTimer = useSelector(getActiveTimer);
  const isActiveCheckin = activeTimer?.check_in_id === id;

  useEffect(() => {
    setIsEditing(false);
    setNewText(title);
    setNewHours(Number(estimatedHours || 0));
  }, [estimatedHours, id, title]);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const handleSubmit = () => {
    if (isWorkplan) {
      handleCreateCheckin({
        activity_id: activityId,
        project_id: projectId,
        phase_id: phaseId,
        title: newText,
        estimated_hours: newHours,
        isWorkplan: true
      });
    } else {
      dispatch(
        updateCheckin({
          id: id,
          title: newText,
          estimated_hours: isNaN(newHours) ? 0 : newHours, // If the user does not enter a valid number, it will default to 0
          date: date,
          filterStateId
        })
      );
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setNewText(title);
    setNewHours(Number(estimatedHours || 0));
    setIsEditing(false);
  };

  const handleCopy = () => {
    dispatch(
      createCheckin({
        account_id: me?.id,
        project_id: projectId,
        phase_id: phaseId,
        activity_id: activityId,
        title: title,
        estimated_hours: estimatedHours,
        date: moment().format('YYYY-MM-DD'),
        isCopy: true,
        filterStateId
      })
    );
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const deleteOnClick = () => {
    setShowDeleteModal(false);
    if (isWorkplan) {
      // to delete a workplan based checkin suggestion
      dispatch(
        createCheckin({
          account_id: me?.id,
          project_id: projectId,
          phase_id: phaseId,
          activity_id: activityId,
          title: '',
          estimated_hours: 0.0,
          date: moment().format('YYYY-MM-DD'),
          submittal_status: 'deleted',
          filterStateId
        })
      );
    }
    dispatch(
      deleteCheckin({
        date: date,
        id: id,
        filterStateId
      })
    );
  };

  const deleteConfirm = ({ deleteOnClick }) => (
    <DeleteButton onClick={deleteOnClick}>Delete</DeleteButton>
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
      e.target.blur();
      e.stopPropagation();
    }
  };

  const openProjectComments = (id) => {
    dispatch(fetchProjectById(id));
    dispatch(setSelectedProject({ projectId: id }));
    dispatch(
      openProjectModal({
        projectId: id
      })
    );
  };

  const handleTimerToggle = () => {
    if (isActiveCheckin) {
      dispatch(
        endTimer({
          account_id: me.id
        })
      );
    } else {
      dispatch(
        startTimer({
          account_id: me.id,
          activity_id: activityId,
          phase_id: phaseId,
          project_id: projectId,
          check_in_id: isWorkplan ? undefined : id,
          date: moment().format('YYYY-MM-DD'),
          auto_generate_check_in: isWorkplan
        })
      );
    }
  };

  const BoardTextChildrenContainer = styled.div`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;

  const playButtonColor = isActiveCheckin
    ? theme.colors.colorCalendarBlue
    : theme.colors.colorMediumGray9;

  return (
    <ListItemContainer isEditing={isEditing}>
      <ListItemHeader>
        <BoardText>
          <BoardTextChildrenContainer>
            <span {...tooltipProps} data-tip={projectNumber}>
              {' '}
              {projectNumber || ''}{' '}
            </span>
            {boardName && <StyledFolderIcon />}
            <span {...tooltipProps} data-tip={boardName}>
              {' '}
              {boardName || ''}{' '}
            </span>
          </BoardTextChildrenContainer>
          {showButtonTopRight && isEditing && (
            <ButtonContainer>
              <FooterButton
                className={`cancel-button ${footerButtonClassName}`}
                isCancel
                onClick={handleCancel}
                data-testid={`${checkin.id}-${projectName}-${phase?.name}-cancel-button`}
              >
                Cancel
              </FooterButton>
              <FooterButton
                className={`save-button ${footerButtonClassName}`}
                onClick={() => handleSubmit(newText)}
                data-testid={`${checkin.id}-${projectName}-${phase?.name}-save-button`}
              >
                Save
              </FooterButton>
            </ButtonContainer>
          )}
        </BoardText>
        <ProjectNameRow>
          <ProjectInfoContainer>
            <ProjectNameContainer>
              <ProjectDot id={projectId} />
              <ProjectName {...tooltipProps} data-tip={projectName}>
                {' '}
                {projectName || ''}
              </ProjectName>
            </ProjectNameContainer>
            <ProjectDescription>
              <ProjectDash> {projectDescription && '-'} </ProjectDash>
              <DescriptionText {...tooltipProps} data-tip={projectDescription}>
                {projectDescription && projectDescription}
              </DescriptionText>
            </ProjectDescription>
          </ProjectInfoContainer>

          <HoursContainer isEditing={isEditing}>
            <WorkedHours
              onClick={() => {
                setIsEditing(true);
              }}
              as={NumberFormat}
              suffix={'h'}
              isZero={!isEditing && Number(estimatedHours) === 0}
              isEditing={isEditing}
              value={`${
                isEditing
                  ? newHours || ''
                  : formatNumWithMaxTwoDecimals(Number(estimatedHours))
              }`}
              onKeyPress={handleKeyPress}
              onValueChange={(values) => setNewHours(values.value)}
              disabled={isNotification}
              isNotification={isNotification}
              placeholder="0h"
              data-testid={`${checkin.id}-${projectName}-${phase?.name}-hour`}
            />
            {!isEditing && <HoursDivider>|</HoursDivider>}
            <TotalHours
              data-for={'app-tooltip'}
              data-html
              data-effect="solid"
              data-tip={'Work Plan Hours'}
              data-testid={`${checkin.id}-${projectName}-${phase?.name}-workplan-hour`}
            >
              {' '}
              {totalHours || 0}h{' '}
            </TotalHours>
          </HoursContainer>
        </ProjectNameRow>
        <PhaseNameRow shouldHide={!phase || phase.is_like_default}>
          <MilestoneIconContainer>
            <PhaseMilestoneIcon
              height="12"
              width="10"
              fillColor={theme.colors.colorLightGray19}
            />
          </MilestoneIconContainer>
          <PhaseRowText {...tooltipProps} data-tip={phase?.name}>
            {' '}
            {phase?.name || ''}
          </PhaseRowText>
          <StyledWorkCategoryIconContainer
            showIcon={showInLineWorkCategory && activityId && activityName}
          >
            <SmallDiamondIcon />
          </StyledWorkCategoryIconContainer>
          <PhaseRowText
            showInLineWorkCategory={
              showInLineWorkCategory && activityId && activityName
            }
            className="work-category-inline"
          >
            {activityName || ''}
          </PhaseRowText>
        </PhaseNameRow>
        <PhaseNameRow shouldHide={!activityId || showInLineWorkCategory}>
          <PhaseRowText>{activityName || ''}</PhaseRowText>
        </PhaseNameRow>
        {!isNotification && (
          <>
            <HoverOptions>
              {isToday(moment(plannerDateHeader, 'YYYY-MM-DD'), 'day') && (
                <MenuListItem
                  onClick={handleTimerToggle}
                  data-for={'app-tooltip'}
                  data-html
                  data-effect="solid"
                  data-tip={isActiveCheckin ? 'Stop Timer' : 'Start Timer'}
                >
                  <PlayIconContainer isActive={isActiveCheckin}>
                    <PlayButton color={playButtonColor} />
                  </PlayIconContainer>
                </MenuListItem>
              )}
              {!isToday(moment(plannerDateHeader, 'YYYY-MM-DD'), 'day') && (
                <MenuListItem
                  onClick={handleCopy}
                  data-for={'app-tooltip'}
                  data-html
                  data-effect="solid"
                  data-tip={'Copy to Today'}
                >
                  <IconContainer>
                    <CopyIcon width={12} height={18} />
                  </IconContainer>
                </MenuListItem>
              )}
              <MenuListItem
                onClick={() => openProjectComments(projectId)}
                data-for={'app-tooltip'}
                data-html
                data-effect="solid"
                data-tip={'Update Project'}
              >
                <IconContainer>
                  <UpdateProjectIcon />
                </IconContainer>
              </MenuListItem>
              <MenuListItem
                onClick={handleOpenDeleteModal}
                data-for={'app-tooltip'}
                data-html
                data-effect="solid"
                data-tip={'Remove Check-in'}
              >
                <IconContainer>
                  <ThinCloseIcon width={10} height={10} />
                </IconContainer>
              </MenuListItem>
            </HoverOptions>
          </>
        )}
      </ListItemHeader>
      <ItemText
        isEditing={isEditing}
        onClick={() => setIsEditing(true)}
        onKeyPress={handleKeyPress}
        onChange={(e) => setNewText(e.target.value)}
        value={isEditing ? newText : title}
        disabled={isNotification}
        placeholder={!isNotification ? 'Add Description' : ''}
        isNotification={isNotification}
        data-testid={`${checkin.id}-${projectName}-${phase?.name}-description`}
      />
      {!showButtonTopRight && (
        <ListItemFooter>
          <ButtonContainer>
            {isEditing && (
              <>
                <FooterButton
                  className={`${checkin.id}-cancel-button ${footerButtonClassName}`}
                  isCancel
                  onClick={handleCancel}
                >
                  Cancel
                </FooterButton>
                <FooterButton
                  className={`${checkin.id}-save-button ${footerButtonClassName}`}
                  onClick={() => handleSubmit(newText)}
                >
                  Save
                </FooterButton>
              </>
            )}
          </ButtonContainer>
        </ListItemFooter>
      )}
      <DeleteModal
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(false)}
        deleteOnClick={deleteOnClick}
        component={'Check-in'}
        renderDelete={deleteConfirm}
      />
    </ListItemContainer>
  );
};

export default DailyCheckinItem;
