import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { requireAuthentication } from 'appUtils/authentication';
import Home from 'views/Home';
import HomeTasksNavigationTracker from 'views/Navigation/HomeTasksNavigationTracker';
import withRouterParams from './WithRouterParams';
import TaskModalRouter from './TaskModalRouter';
import MemberModalRouter from './MemberModalRouter';
import ScopeModalRouter from './ScopeModalRouter';

const HomeAuthenticated = withRouter(requireAuthentication(Home));
// const HomePlannerAuthenticated = withRouter(
//   requireAuthentication(HomePlannerContainer)
// );

class HomeRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url; // we need this for nav ups to home
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;

    return (
      <>
        <HomeTasksNavigationTracker />
        <HomeAuthenticated>
          <Route
            path={`${match.url}/task/:taskId`}
            component={TaskModalRouter}
          />
          <Route
            path={`${match.url}/member/:memberId/view/:memberViewType`}
            component={MemberModalRouter}
          />
          <Route
            path={`${match.url}/scope/:scopeId`}
            component={ScopeModalRouter}
          />
        </HomeAuthenticated>
        {/* <Route
          path={`${match.url}/planner`}
          component={HomePlannerNavigationTracker}
        />
        <Route
          path={`${match.url}/planner`}
          component={HomePlannerAuthenticated}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeRouter))
);
