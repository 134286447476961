import styled from 'styled-components';
import theme from 'theme';
import {
  StyledProjectTitle,
  StyledProjectDescription,
  StyledProjectNumber,
  StyledFolderIcon,
  StyledTeamName
} from 'components/ProjectsAndPhasesDropdown/styles';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle
} from 'components/BatchActions/styles';
import {
  SimpleFilterDropdown,
  SimpleFilterDropdownStyleWrapper
} from './SimpleFilterDropdown';
import Row from 'components/ProjectsAndPhasesDropdown/Row';
import { useProjectsFilter } from 'FilterModule/hooks/useProjectsFilter';
import {
  FilterListTypeDropdown,
  FilterListTypeDropdownUIProps
} from 'FilterModule/types';

export const ProjectsFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const {
    hasNextPage,
    loadInitialItems,
    loadMoreItems,
    updateSelectedItems,
    itemHash,
    optionsArray,
    selectedItems
  } = useProjectsFilter();

  return (
    <ProjectsFilterDropdownUI
      itemHash={itemHash}
      optionsArray={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      loadMoreItems={loadMoreItems}
      loadInitialItems={loadInitialItems}
      hasNextPage={hasNextPage}
      renderToggle={renderToggle}
    />
  );
};

export const ProjectsFilterDropdownUI = ({
  itemHash,
  optionsArray,
  selectedItems,
  updateSelectedItems,
  loadMoreItems,
  loadInitialItems,
  hasNextPage,
  renderToggle
}: FilterListTypeDropdownUIProps<typeof useProjectsFilter>) => {
  const customRenderItem = ({
    id,
    isSelected
  }: {
    id: number;
    isSelected: boolean;
  }) => {
    const project = itemHash[id];
    if (!project) return <></>;
    return (
      <RowContainer>
        <StyledSelectToggleContainer>
          <StyledSelectToggle isChecked={isSelected} size={14} />
        </StyledSelectToggleContainer>
        <ProjectListItem hasDescription={!!project.description}>
          <Row item={project} />
        </ProjectListItem>
      </RowContainer>
    );
  };

  return (
    <SimpleFilterDropdown
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      isBatchClearOnly
      labels={labels}
      labelKey="title"
      itemHeight={55}
      listWidth={250}
      listHeight={220}
      itemHash={itemHash}
      itemFilterableKeys={['title', 'project_number']}
      customRenderItem={customRenderItem}
      loadMoreItems={loadMoreItems}
      loadInitialItems={loadInitialItems}
      hasNextPage={hasNextPage}
      renderToggle={renderToggle}
      styleWrapper={StyledProjectsFiltersDropdown}
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

const labels = {
  searchPlaceholder: 'Type Project or select below'
};

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${StyledSelectToggleContainer} {
    margin-left: 10px;
  }
`;

const ProjectListItem = styled.div<{ hasDescription: boolean }>`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: 100%;
  flex: 1;
  min-width: 0;
  padding-top: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray4};
  }
  ${StyledProjectTitle} {
    font-size: 12px;
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    ${(props) => props.hasDescription && 'flex-shrink: 0;'}
  }
  ${StyledTeamName} {
    font-size: 10px;
    color: ${theme.colors.colorBudgetGrey};
  }
  ${StyledProjectNumber} {
    font-size: 9px;
    color: ${theme.colors.colorMediumGray5};
  }
  ${StyledFolderIcon} {
    width: 9px;
  }
  ${StyledProjectDescription} {
    &::before {
      content: ' - ';
    }
  }
`;

const StyledProjectsFiltersDropdown = styled(SimpleFilterDropdownStyleWrapper)`
  .flyout-list-item-container:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.colorPaleGray9};
  }
`;
