import React, { useMemo, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getMosaicMappingStatuses,
  getIntegrationsV2,
  getFetchedMappingStatusMosaicIds
} from 'IntegrationsModule/selectors';
import {
  getUserIsAdmin,
  getTeamSlug,
  getSidebarProjectsOpen,
  getSelectedTeamId
} from 'selectors';
import { navigateToIntegrations, closeSidebarProjects } from 'actionCreators';
import { fetchMosaicTimeEntryMappingStatuses } from 'IntegrationsModule/actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import {
  DATA_TYPES,
  integrationsHash,
  MAPPING_STATUS,
  MAPPING_STATUS_SORT_RANKS
} from 'IntegrationsModule/constants';
import LinkIcon from 'icons/LinkIcon2';
import LinkBrokenIcon from 'icons/LinkBrokenIcon2';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import AdminListFlyout from 'components/AdminListFlyout';

const Div = ({ children, ...props }) => <div {...props}>{children}</div>;

const TooltipHoverText = styled.span`
  text-decoration: underline;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    cursor: pointer;
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  font-size: 12px;
  max-width: 300px;
  cursor: default;
  text-align: center;
  background-color: rgba(82, 90, 103, 0.85);

  &.place-top::after {
    border-top-color: rgba(82, 90, 103, 0.85);
    border-bottom-color: rgba(82, 90, 103, 0.85);
    bottom: -5px;
  }

  &.place-right::after {
    border-right-color: rgba(82, 90, 103, 0.85);
  }

  &.keep-tooltip-open {
    visibility: visible !important;
    opacity: 0.9 !important;
  }
`;

const StyleWrapper = styled.div``;

const DefaultActiveIcon = ({ iconProps }) => (
  <LinkIcon
    height={12}
    width={12}
    color={theme.colors.colorLightGray15}
    {...iconProps}
  />
);

const DefaultUnmappedIcon = ({ iconProps }) => (
  <LinkBrokenIcon
    height={12}
    width={12}
    color={theme.colors.colorLightGray6}
    {...iconProps}
  />
);

const DEFAULT_MAPPING_STATUS_ICONS = {
  [MAPPING_STATUS.ACTIVE]: DefaultActiveIcon,
  [MAPPING_STATUS.UPDATE_ON_TARGET]: DefaultActiveIcon,
  [MAPPING_STATUS.CREATE_NEW_ON_TARGET]: DefaultActiveIcon,
  [MAPPING_STATUS.NULL]: DefaultUnmappedIcon
};

const dataTypeToLabel = {
  [DATA_TYPES.PROJECTS]: 'Project',
  [DATA_TYPES.TIME_ENTRIES]: 'Time entry'
};

const mappingStatusTooltipText = {
  [MAPPING_STATUS.ACTIVE]: 'Linked '
};

const getTooltipText = (dataType, status) => {
  return `${dataTypeToLabel[dataType]} ${
    mappingStatusTooltipText[status.mappingStatus] || 'is syncing '
  } with ${
    status.service + (status.customLabel ? ` ${status.customLabel}` : '')
  }.`;
};

/* ------------------------------------ - ----------------------------------- */

const useMappingStatus = ({
  id,
  dataType,
  iconProps,
  Container,
  containerClass,
  customIcons = {},
  selfLoad = false,
  tooltipPlacement = 'top'
}) => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(getSidebarProjectsOpen);
  const mosaicMappingStatuses = useSelector(getMosaicMappingStatuses);
  const fetchedMappingStatusMosaicIds = useSelector(
    getFetchedMappingStatusMosaicIds
  );
  const integrationsById = useSelector(getIntegrationsV2);
  const isAdmin = useSelector(getUserIsAdmin);
  const teamSlug = useSelector(getTeamSlug);
  const teamId = useSelector(getSelectedTeamId);

  const [keepTooltipOpen, setKeepTooltipOpen] = useState(false);
  const [keepAdminListOpen, setKeepAdminListOpen] = useState(false);
  const [showAdminList, setShowAdminList] = useState(false);
  const adminListTarget = useRef(null);

  const mappingStatuses = useMemo(() => {
    const statuses = [];
    const integrationIds = Object.keys(integrationsById);
    if (integrationIds.length) {
      integrationIds.forEach((integrationId) => {
        const integration = integrationsById[integrationId];
        const mappingStatus =
          mosaicMappingStatuses[integration.targetService]?.[integrationId]?.[
            dataType
          ][id];
        if (mappingStatus) {
          statuses.push({
            service: integrationsHash[integration.targetService]?.label || '',
            customLabel: integration.settings?.label || '',
            targetService: integration.targetService,
            integrationId,
            mappingStatus
          });
        }
      });
    }
    return statuses;
  }, [dataType, id, integrationsById, mosaicMappingStatuses]);

  const navToIntegration = (integrationId) => {
    if (sidebarOpen) dispatch(closeSidebarProjects());
    dispatch(navigateToIntegrations({ teamSlug, integrationId }));
  };

  const getLinkedIcon = () => {
    // can only display one icon, order is arbitrary
    const mappingStatusToShow = mappingStatuses
      .map((status) => status.mappingStatus)
      .sort(
        (a, b) => MAPPING_STATUS_SORT_RANKS[b] - MAPPING_STATUS_SORT_RANKS[a]
      )[0];
    return (
      customIcons[mappingStatusToShow] ||
      DEFAULT_MAPPING_STATUS_ICONS[mappingStatusToShow] ||
      Div
    );
  };

  const getUnmappedIcon = () => {
    return (
      customIcons[MAPPING_STATUS.NULL] ||
      DEFAULT_MAPPING_STATUS_ICONS[MAPPING_STATUS.NULL] ||
      Div
    );
  };

  const renderMappingStatusIcon = () => {
    if (!Object.keys(integrationsById).length) return null;
    const ContainerComponent = Container || Div;
    const UnmappedIcon = getUnmappedIcon();

    if (!mappingStatuses.length)
      return (
        <ContainerComponent className={containerClass}>
          <UnmappedIcon {...iconProps} />
        </ContainerComponent>
      );

    const LinkedIcon = getLinkedIcon();

    return (
      <StyleWrapper keepTooltipOpen={keepTooltipOpen}>
        <ContainerComponent
          data-for={`mapping-status-tooltip-${id}`}
          data-tip
          className={containerClass}
        >
          <LinkedIcon {...iconProps} />
          {createPortal(
            <StyledTooltip
              id={`mapping-status-tooltip-${id}`}
              effect="solid"
              clickable={true}
              delayShow={500}
              delayHide={500}
              place={tooltipPlacement}
            >
              <div ref={adminListTarget}>
                {mappingStatuses.map((status, index) => {
                  return (
                    <div
                      key={index}
                      style={
                        index !== mappingStatuses.length - 1
                          ? { marginBottom: 5 }
                          : {}
                      }
                    >
                      {getTooltipText(dataType, status)}
                      <br />
                      {isAdmin ? (
                        <TooltipHoverText
                          onClick={(e) => {
                            e.stopPropagation();
                            navToIntegration(status.integrationId);
                          }}
                        >
                          Click to View integration.
                        </TooltipHoverText>
                      ) : (
                        <>
                          Contact your{' '}
                          <TooltipHoverText
                            onMouseEnter={() => setShowAdminList(true)}
                            onMouseLeave={() => setShowAdminList(false)}
                            onClick={(e) => {
                              e.stopPropagation();
                              setKeepAdminListOpen(true);
                              setKeepTooltipOpen(true);
                            }}
                          >
                            Admins
                          </TooltipHoverText>{' '}
                          about the link.
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </StyledTooltip>,
            document.getElementsByClassName('tooltip-portal')[0]
          )}
        </ContainerComponent>
        {(showAdminList || keepAdminListOpen) && (
          <AdminListFlyout
            target={adminListTarget}
            toggle={
              showAdminList
                ? undefined
                : () => {
                    setShowAdminList(false);
                    setKeepAdminListOpen(false);
                    setKeepTooltipOpen(false);
                  }
            }
            offset="80 15"
          />
        )}
      </StyleWrapper>
    );
  };

  useEffect(() => {
    if (
      dataType === DATA_TYPES.TIME_ENTRIES &&
      !fetchedMappingStatusMosaicIds[dataType][id] &&
      teamId &&
      selfLoad
    ) {
      dispatch(
        fetchMosaicTimeEntryMappingStatuses({
          timeEntryIds: [id],
          teamId
        })
      );
    }
  }, [dataType, dispatch, fetchedMappingStatusMosaicIds, id, selfLoad, teamId]);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return {
    mappingStatuses,
    renderMappingStatusIcon,
    isMapped: mappingStatuses.length > 0
  };
};

export default useMappingStatus;
