import React from 'react';
import { PlannerModalButton } from '../..';
import cn from 'classnames';

const TimeEntryInlineDeleteConfirmation = ({
  handleWeekDeleteCancel,
  handleWeekDeleteConfirm,
  error,
  className = '',
  style = {}
}) => (
  <div className="modal-delete-confirmation" style={style}>
    <div
      className={cn(
        'confirmation-text',
        { 'has-error': error.length > 0 },
        { 'has-no-error': error.length === 0 },
        className
      )}
    >
      {error.length ? error : `Delete Time Entry from this week's Timesheet?`}
    </div>
    <div
      className={cn('confirmation-buttons-row', {
        'has-no-error': error.length === 0,
        className
      })}
      style={{
        justifyContent: error.length ? 'center' : ''
      }}
    >
      {!error.length && (
        <PlannerModalButton
          className="cancel-button"
          onClick={handleWeekDeleteCancel}
          text={'Cancel'}
        />
      )}
      <PlannerModalButton
        className="background-red"
        isBlue={!!error.length}
        onClick={
          error.length ? handleWeekDeleteCancel : handleWeekDeleteConfirm
        }
        text={error.length ? 'OK' : 'Delete'}
      />
    </div>
  </div>
);

export default TimeEntryInlineDeleteConfirmation;
