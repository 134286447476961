import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const lastNameValidator: RowValidator = ({ row, entityType }) => {
  const { enteredLastName } = getRowValuesForEntityType(row, entityType);

  return !!enteredLastName && enteredLastName !== TRAINING_DATA_REQUIRED_STRING;
};
