import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { navigateToReport } from 'actionCreators';
import { getTeamSlug, getSplitFlags } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import {
  defaultNavModuleIds,
  moduleHash,
  DOLLAR_ONLY_REPORTS_IDS
} from 'appUtils/reportsModuleUtils';
import BudgetThumbnail from 'images/reports/budget-thumbnail.svg';
import ProfitThumbnail from 'images/reports/profit-thumbnail.svg';
import TimeThumbnail from 'images/reports/time-thumbnail.svg';
import UtilizationThumbnail from 'images/reports/utilization-thumbnail.svg';
import VarianceThumbnail from 'images/reports/variance-thumbnail.svg';
import WorkloadThumbnail from 'images/reports/workload-thumbnail.svg';
import TimesheetStatusThumbnail from 'images/reports/timesheet-status-thumbnail.svg';
import WorkloadForecastThumbnail from 'images/reports/workload-forecast-thumbnail.svg';
import SkillsThumbnail from 'images/reports/skills-thumbnail.svg';
import CheckInsThumbnail from 'images/reports/check-ins-thumbnail.svg';
import AllProjectsThumbnail from 'images/reports/all-projects-thumbnail.svg';
import TimeProjectionThumbnail from 'images/reports/time-projection-thumbnail.svg';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import { defaultRoleDisplay } from 'appUtils/roleDisplay';
import ReportsPermissionCheck from './ReportsPermissionCheck';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import { useReportPermissions } from 'appUtils/hooks/useReportPermissions';
import { fetchTeamPermissions } from 'PermissionsModule/actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { REPORT_NAMES } from 'ReportsModule/constants';

const THREE_ACROSS_BREAKPOINT = '1650px';

const ScrollContainer = styled.div`
  overflow: auto;
  width: 100%;
`;

export const ContainerDiv = styled.div`
  margin: 0 auto;
  max-width: 920px;

  @media screen and (max-width: 1100px) {
    padding-left: 50px;
    min-width: 970px;
  }
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    max-width: 1047px;
  }
`;
const Card = styled.div`
  display: grid;
  grid-template-columns: 200px 230px;
  background: white;
  box-shadow: 0px 2px 14px rgba(116, 116, 116, 0.15);
  border-radius: 6px;
  position: relative;
  width: 430px;
  height: 170px;
  margin-right: 22px;
  margin-bottom: 22px;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border-color: ${theme.colors.colorRoyalBlue};
  }
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    grid-template-columns: 152px 175px;
    width: 327px;
    height: 130px;
    margin-right: 17px;
    margin-bottom: 17px;
  }
`;
const CardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 100%;
`;
const Description = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  max-height: 49px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '10px'};
  margin-top: 4px;
  padding-left: 1px;
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    max-height: 35px;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: ${({ marginBottom }) => marginBottom || '11px'};
  }
`;
const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  margin-bottom: -4px;
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    font-size: 14px;
    margin-bottom: -6px;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    img {
      width: 157px;
    }
  }
`;
const Dot = styled.div`
  background: ${(props) => props.color};
  opacity: 0.5;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    width: 6px;
    height: 6px;
  }
`;
const Tag = styled.span`
  padding: 1px 7px;
  background: ${theme.colors.colorPaleGray7};
  font-size: 10px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  margin-right: 4px;
  width: fit-content;
  border-radius: 4px;
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    font-size: 8px;
    padding: 1px 5px;
  }
`;

const RoleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  span {
    color: ${theme.colors.colorMediumGray9};
    font-size: 12px;
    @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
      font-size: 9px;
    }
  }
`;
const ReportDetails = styled.div`
  padding: 8px;
  padding-left: 27px;
  @media screen and (min-width: ${THREE_ACROSS_BREAKPOINT}) {
    padding: 6px;
    padding-left: 13px;
  }
`;

/* ------------------------------------------------------------------------ */

const { ADMIN, WORKLOAD_MANAGER, FINANCIAL_MANAGER, TIMESHEET_MANAGER } =
  ACCESS_ROLES;

const roleToColor = {
  [ADMIN]: theme.colors.colorCalendarBlue,
  [FINANCIAL_MANAGER]: theme.colors.colorMediumPurple2,
  [WORKLOAD_MANAGER]: theme.colors.colorLightOrange2,
  [TIMESHEET_MANAGER]: theme.colors.colorLightGreen2 // I dont know which color
};

const TAGS = {
  time: 'Time',
  financial: 'Financial'
};

// Permissions is used as a fall back if the permission for the report is not controlled in settings.
const reports = {
  time: {
    label: REPORT_NAMES.TIMESHEET,
    description: '',
    tags: [TAGS.time],
    image: TimeThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  budget: {
    label: REPORT_NAMES.BUDGET,
    description: '',
    tags: [TAGS.financial],
    image: BudgetThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  capacity: {
    label: REPORT_NAMES.WORKLOAD,
    description: '',
    tags: [TAGS.time],
    image: WorkloadThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  utilization: {
    label: REPORT_NAMES.UTILIZATION,
    description: '',
    tags: [TAGS.time],
    image: UtilizationThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  // activity: {
  //   label: 'Timesheet Activity',
  //   description: "Overview of team members' Timesheet Activity Logs.",
  //   tags: [TAGS.time],
  //   image: ActivityThumbnail,
  //   permissions: [FINANCIAL_MANAGER, ADMIN]
  // },
  profit: {
    label: REPORT_NAMES.PROFIT,
    description: '',
    tags: [TAGS.financial],
    image: ProfitThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  variance: {
    label: REPORT_NAMES.TIME_VARIANCE,
    description: '',
    tags: [TAGS.time],
    image: VarianceThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  timesheetstatus: {
    label: REPORT_NAMES.TIMESHEET_STATUS,
    description: '',
    tags: [TAGS.time],
    image: TimesheetStatusThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  demand: {
    label: REPORT_NAMES.DEMAND,
    description: '',
    tags: [TAGS.time],
    image: WorkloadThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  budgetvariance: {
    label: REPORT_NAMES.BUDGET_VARIANCE,
    description: '',
    tags: [TAGS.financial],
    image: BudgetThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  workloadforecast: {
    label: REPORT_NAMES.WORKLOAD_FORECAST,
    description: '',
    tags: [TAGS.time],
    image: WorkloadForecastThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  billingforecast: {
    label: REPORT_NAMES.BILLING_FORECAST,
    description: '',
    tags: [TAGS.time],
    image: UtilizationThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  schedulevariance: {
    label: REPORT_NAMES.SCHEDULE_VARIANCE,
    description: '',
    tags: [TAGS.time], // DONT KNOW
    image: BudgetThumbnail, // DONT KNOW
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  skills: {
    label: REPORT_NAMES.SKILLS,
    description: '',
    tags: [TAGS.time],
    image: SkillsThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  checkins: {
    label: REPORT_NAMES.CHECKINS,
    description: '',
    tags: [TAGS.time],
    image: CheckInsThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  allprojects: {
    label: REPORT_NAMES.ALL_PROJECTS,
    description: '',
    tags: [TAGS.time],
    image: AllProjectsThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  pto: {
    label: REPORT_NAMES.PTO,
    description: '',
    tags: [TAGS.time],
    image: AllProjectsThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  timeprojection: {
    label: REPORT_NAMES.TIME_PROJECTION,
    description: '',
    tags: [TAGS.financial],
    image: TimeProjectionThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  spenttime: {
    label: REPORT_NAMES.SPENT_TIME,
    description: '',
    tags: [],
    image: TimeProjectionThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  plannedtime: {
    label: REPORT_NAMES.PLANNED_TIME,
    description: '',
    tags: [],
    image: TimeProjectionThumbnail, // FIXME
    permissions: [ADMIN, FINANCIAL_MANAGER, WORKLOAD_MANAGER]
  },
  unspentbudget: {
    label: REPORT_NAMES.UNSPENT_BUDGET,
    description: '',
    tags: [],
    image: TimeProjectionThumbnail, // FIXME
    permissions: [ADMIN, FINANCIAL_MANAGER]
  },
  default: {
    label: 'Testing',
    description: 'Test report section.',
    tags: [],
    image: TimesheetStatusThumbnail,
    permissions: [ADMIN, FINANCIAL_MANAGER]
  }
};

const customMarginBottomByViewType = {};

/* ------------------------------------------------------------------------ */
//
const ReportsLanding = () => {
  const dispatch = useAppDispatch();
  const teamSlug = useAppSelector(getTeamSlug);
  const teamId = useAppSelector(getSelectedTeamId);

  const { isHoursOnly } = useIsHoursOnly();
  const { reportPermissionHash, allowedRoles } = useReportPermissions();
  const { roleForecastReportFlag } = useAppSelector(getSplitFlags);

  const fetchTeamPermissionsRequestStatusId = `${fetchTeamPermissions.type}-report-permissions`;

  useEffect(() => {
    if (teamId) {
      dispatch(
        fetchTeamPermissions({
          teamId,
          meta: {
            requestStatusId: fetchTeamPermissionsRequestStatusId
          }
        })
      );
    }
  }, [dispatch, fetchTeamPermissionsRequestStatusId, teamId]);

  const navToReport = (report) => {
    dispatch(
      navigateToReport({
        teamSlug,
        viewType: report,
        // this should go into report router in the end. temporary redirect to roles view for workloadForecast report
        ...(roleForecastReportFlag &&
          report === 'workloadforecast' && { reportViewType: 'roles' })
      })
    );
  };

  return (
    <ScrollContainer>
      <ContainerDiv className="report-page">
        {/* <MainHeaderContainer unsetWidths>
        <ReportsHeader>
          <div className="row">
            <div className="title">Select Report</div>
          </div>
        </ReportsHeader>
      </MainHeaderContainer> */}

        <CardsContainer>
          {defaultNavModuleIds.map((id) => {
            const viewType = moduleHash[id];
            const permissionToAccessReport = reportPermissionHash[viewType];

            // reportPermissionHash checks splitflag of report.
            if (!permissionToAccessReport) return null;

            const report = reports[viewType] || reports.default;

            // Reports that only include dollar amounts
            if (DOLLAR_ONLY_REPORTS_IDS.includes(id) && isHoursOnly)
              return null;

            const rolesAllowedForReport = allowedRoles({ viewType });

            return (
              <Card
                key={id}
                onClick={() => navToReport(viewType)}
                data-testid={`report-${report.label}`}
              >
                <ReportDetails>
                  <Label>{report.label}</Label>
                  {/* temporarily hides all report's descriptions and tags
                    {report.tags.map((tag, index) => (
                      <Tag key={index}>{tag}</Tag>
                    ))} */}
                  <Description
                    marginBottom={customMarginBottomByViewType[viewType]}
                  >
                    {report.description}
                  </Description>
                  {(rolesAllowedForReport.length
                    ? rolesAllowedForReport
                    : report.permissions
                  ).map((role) => {
                    return (
                      <RoleRow key={role}>
                        <Dot color={roleToColor[role]} />
                        <span>{defaultRoleDisplay[role]}</span>
                      </RoleRow>
                    );
                  })}
                </ReportDetails>
                <ImageContainer>
                  <img src={report.image} />
                </ImageContainer>
              </Card>
            );
          })}
        </CardsContainer>
      </ContainerDiv>
    </ScrollContainer>
  );
};

export default ReportsLanding;
