import type {
  LeanApiSchema,
  FetchLeanApiPayload,
  OrderBy
} from 'LeanApiModule/types';
import {
  FIELD_NAMES,
  FIELD_RENAME_MAP,
  ORDER_BY
} from 'LeanApiModule/constants';
import { SortDirection } from 'FilterModule/constants';
import set from 'lodash/set';
import merge from 'lodash/merge';
import { getFilterParamValues } from '..';

// =============================================================================
//                                   WHERE
// =============================================================================

export const defaultProjectWhereClauses = {
  // portfolio: {
  //   [FIELD_NAMES.board]: {
  //     [FIELD_NAMES.archived_at]: null
  //   }
  // },
  nonAdministrativeProjects: {
    administrative_flag: null
  }
};

const addProjectFilterParams = ({
  filterParams,
  where
}: {
  filterParams?: FetchLeanApiPayload['filterParams'];
  where: NonNullable<LeanApiSchema['where']>;
}) => {
  if (filterParams) {
    const { boardIds, isProjectsWithUnassignedRoles } = getFilterParamValues({
      filterParams
    });

    // where.board
    if (boardIds?.length) {
      set(where, FIELD_NAMES.board, {
        [FIELD_NAMES.id_in]: boardIds
      });
    }

    // where.project_memberships
    if (isProjectsWithUnassignedRoles) {
      set(where, FIELD_NAMES.project_memberships, {
        [FIELD_NAMES.account_id]: null
      });
    }
  }
};

const addProjectNameSearchParam = ({
  searchText,
  where
}: {
  searchText?: string;
  where: NonNullable<LeanApiSchema['where']>;
}) => {
  if (searchText) {
    // TODO: make this search on project number also
    set(where, FIELD_NAMES.title_like, `%${searchText}%`);
  }
};

/**
 * Returns the WHERE clause for a project entity schema using the given filterParams and searchText
 */
export const buildProjectWhereClause = ({
  filterParams,
  searchText,
  initialValue
}: {
  filterParams?: FetchLeanApiPayload['filterParams'];
  searchText?: string;
  initialValue?: LeanApiSchema['where'];
}) => {
  const where: LeanApiSchema['where'] = {};

  if (searchText || filterParams) {
    addProjectNameSearchParam({ searchText, where });
    addProjectFilterParams({ filterParams, where });
  }

  if (initialValue) {
    merge(where, initialValue);
  }

  return where;
};

// =============================================================================
//                                   SELECT
// =============================================================================

export const projectSelectClauses = {
  id: {
    [FIELD_NAMES.id]: FIELD_NAMES.id
  },
  title: {
    [FIELD_NAMES.title]: FIELD_NAMES.title
  },
  description: {
    [FIELD_NAMES.description]: FIELD_NAMES.description
  },
  archivedAt: {
    [FIELD_NAMES.archived_at]: FIELD_NAMES.archived_at
  },
  projectNumber: {
    [FIELD_NAMES.project_number]: FIELD_NAMES.project_number
  },
  boardId: {
    [FIELD_NAMES.board]: {
      [FIELD_NAMES.id]: FIELD_RENAME_MAP.board_id
    }
  }
};

/**
 * Returns the SELECT clause for a project entity schema using the given filterParams
 */
export const buildProjectSelectClause = ({
  filterParams,
  initialValue
}: {
  filterParams?: FetchLeanApiPayload['filterParams'];
  initialValue?: LeanApiSchema['select'];
}) => {
  let select: LeanApiSchema['select'] = projectSelectClauses.id;

  if (filterParams) {
    const { boardIds } = getFilterParamValues({
      filterParams
    });

    if (boardIds) {
      select = {
        ...select,
        ...projectSelectClauses.boardId
      };
    }
  }

  if (initialValue) {
    merge(select, initialValue);
  }

  return select;
};

// =============================================================================
//                                   ORDER
// =============================================================================

export const formatProjectOrderClause = ({
  orderBy,
  direction
}: {
  orderBy: OrderBy;
  direction: SortDirection;
}) => {
  const clauses: Partial<Record<OrderBy, LeanApiSchema['order']>> = {
    [ORDER_BY.PORTFOLIO_NAME]: {
      [FIELD_NAMES.board]: {
        [FIELD_NAMES.name]: direction
      }
    },
    [ORDER_BY.PROJECT_ARCHIVED]: {
      [FIELD_NAMES.archived_at]: direction
    },
    [ORDER_BY.PROJECT_TITLE]: {
      [FIELD_NAMES.title]: direction
    }
  };
  return clauses[orderBy];
};

/**
 * Returns the ORDER clause for a team_membership entity schema
 */
export const buildProjectOrderClause = ({
  initialValue
}: {
  initialValue?: LeanApiSchema['order'];
} = {}) => {
  const order: LeanApiSchema['order'] = {
    ...initialValue,
    ...formatProjectOrderClause({
      orderBy: ORDER_BY.PROJECT_ARCHIVED,
      direction: SortDirection.desc
    }),
    ...formatProjectOrderClause({
      orderBy: ORDER_BY.PROJECT_TITLE,
      direction: SortDirection.asc
    })
  };

  return order;
};
