import React from 'react';
import theme from 'theme';
import { getRectanglePath } from 'ReportsModule/components/Profit/Charts/utils';
import { DATA_KEY, PERCENT_KEY_TO_DEFAULT } from 'UtilizationModule/constants';
import { getTopDataField } from 'UtilizationModule/utils';
import { formatNumberWithSymbol } from 'appUtils/formatUtils';

const topRoundedRadius = [5, 5, 0, 0];

const margin = 5;
const minBarHeightForLabel = 15;

/* ------------------------------------ - ----------------------------------- */

const CustomBar = ({
  showLabelOnlyHover = false,
  invertLabel = false,
  disableLabel = false,
  labelColor = theme.colors.colorPureBlack,
  type,
  includeRemainingCapacity,
  ...props
}) => {
  const isTop =
    props.dataKey ===
    getTopDataField(props.payload, type, includeRemainingCapacity);
  const isNonBillable = props.dataKey === DATA_KEY.NONBILLABLE;
  const shouldShowLabel = disableLabel
    ? false
    : showLabelOnlyHover
    ? props.isHoveringBar
    : true;
  const percentValue = Math.round(props[props.dataKey]);

  return (
    <>
      {/* Bar */}
      <path
        d={getRectanglePath(
          props.x,
          props.y,
          props.width,
          props.height,
          isTop ? topRoundedRadius : undefined
        )}
        onMouseOver={props.onHover}
        onMouseOut={props.onHoverOut}
        fill={
          props.dataKey === DATA_KEY.PTO ||
          props.dataKey === DATA_KEY.PTO_PERCENT
            ? 'url(#ptoGradient)'
            : props.dataKey === DATA_KEY.HOLIDAY ||
              props.dataKey === DATA_KEY.HOLIDAY_PERCENT
            ? 'url(#holidayGradient)'
            : props.fill
        }
        {...((props.dataKey === DATA_KEY.HOLIDAY ||
          props.dataKey === DATA_KEY.HOLIDAY_PERCENT) && {
          strokeOpacity: 0.4,
          stroke: props.fill
        })}
      />

      {/* Value label */}
      {shouldShowLabel && percentValue && props.height > minBarHeightForLabel && (
        <text
          x={props.x + props.width / 2}
          y={isNonBillable ? props.y + props.height - margin : props.y + margin}
          width={props.width}
          height={props.height}
          onMouseOver={props.onHover}
          onMouseOut={props.onHoverOut}
          textAnchor="middle"
          className="bar-value-label"
          fill={labelColor}
        >
          <tspan
            x={props.x + props.width / 2}
            dy={isNonBillable ? '0em' : '0.71em'}
          >
            {percentValue}%
          </tspan>
        </text>
      )}
    </>
  );
};

export default CustomBar;
