import {
  CenterContainer,
  CenterValueText,
  HolidayHatchBackground
} from './styles';

export const HolidayBucket = () => {
  return (
    <HolidayHatchBackground>
      <CenterContainer className="show-on-hover">
        <CenterValueText>OFF</CenterValueText>
      </CenterContainer>
    </HolidayHatchBackground>
  );
};
