import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface IntegrationIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  fill?: string;
}

export const IntegrationIcon = ({
  fill = '#4F4F4F',
  width = '28',
  height = '28'
}: IntegrationIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M16.5325 12.7419C15.6815 13.5782 14.5978 14.1506 13.4167 14.3876C12.2355 14.6247 11.0092 14.516 9.89082 14.075C8.77239 13.634 7.81134 12.8803 7.12757 11.9079C6.4438 10.9356 6.06759 9.78756 6.04587 8.60716C6.02415 7.42676 6.35789 6.26623 7.00544 5.27037C7.653 4.2745 8.5857 3.4874 9.68718 3.00726C10.7887 2.52711 12.0101 2.37519 13.1992 2.57045C14.3883 2.7657 15.4924 3.29949 16.3736 4.10521"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <line
        x1="0.65"
        y1="8.58291"
        x2="5.46303"
        y2="8.58291"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <line
        x1="15.9325"
        y1="8.58291"
        x2="19.9814"
        y2="8.58291"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M15.3966 8.48468C15.3966 10.179 13.99 11.5758 12.2259 11.5758C10.4619 11.5758 9.05527 10.179 9.05527 8.48468C9.05527 6.79035 10.4619 5.39359 12.2259 5.39359C13.99 5.39359 15.3966 6.79035 15.3966 8.48468Z"
        stroke={fill}
        strokeWidth="1.3"
      />
    </SvgIcon>
  );
};

export default IntegrationIcon;
