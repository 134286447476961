import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

export const LoadingRowCell = () => {
  return (
    <SkeletonLoader
      numLoaders={1}
      style={{ margin: 0, marginTop: 4, width: '100%' }}
      loaderStyle={{ height: 72, rx: 4 }}
    />
  );
};
