import * as appConstants from 'appConstants';
import * as constants from '../constants';
import createIntegrationReducer from './createIntegrationReducer';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import { makeIdHash } from 'appUtils';

const byId = (item) => item.uuid;

export const initialState = {
  integrationClients: {},
  integrationClientsOrder: [], // client filter in projects table
  clientsByMappingStatus: {},
  mappedMosaicIds: {},
  selectedClients: {},
  rowStates: {},
  counts: {},
  isLoading: false,
  isSearchLoading: false,
  initiallyLoaded: false,
  offset: 0,
  mappingSteps: {},
  // Used to correct fetch offsets when selected clients are held at the top of import
  // list (which may or may not belong given the fetch params)
  offsetAdjustment: 0
};
export const clients = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_INTEGRATION_CLIENTS.TRIGGER: {
      const {
        body: { initial }
      } = action.payload;
      const nextState = { ...state, isLoading: true };

      // Reset selected ids and list mapping steps when initial fetch (except for import list)
      if (initial) {
        const nextRowStates = { ...state.rowStates };
        const nextSelectedClients = { ...state.selectedClients };
        Object.keys(state.selectedClients)
          .filter((list) => list !== constants.MEMBERS_LISTS.FROM_INTEGRATION)
          .forEach((list) => {
            state.selectedClients[list].forEach((uuid) => {
              delete nextRowStates[uuid];
            });
            delete nextSelectedClients[list];
          });
        nextState.selectedClients = nextSelectedClients;
        nextState.rowStates = nextRowStates;
        nextState.mappingSteps = {
          [constants.MEMBERS_LISTS.FROM_INTEGRATION]:
            state.mappingSteps[constants.MEMBERS_LISTS.FROM_INTEGRATION]
        };
      }

      return nextState;
    }
    case constants.FETCH_INTEGRATION_CLIENTS.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const {
        body: { offset, limit, initial }
      } = requestPayload;
      const { entities, count } = response;

      const hasSelectedIds = Object.values(state.selectedClients).some(
        (list) => list.length
      );

      const newEntities = keyBy(entities, byId);
      const entityIdOrder = entities.map(byId);

      const groupedByMappingStatus = groupBy(
        entityIdOrder,
        (id) => newEntities[id].mappingStatus
      );

      let nextClientsByMappingStatus = groupedByMappingStatus;

      // Adding to the lists of clients (by mapping status) on page fetches
      // or if selected IDs exist (keep them at top of list)
      if (!initial || hasSelectedIds) {
        // Technically at the moment only the import list will keep selected clients through
        // initial fetches
        const selectedIdsHash = Object.values(state.selectedClients).reduce(
          (acc, list) => {
            list.forEach((id) => (acc[id] = true));
            return acc;
          },
          {}
        );
        nextClientsByMappingStatus = { ...state.clientsByMappingStatus };
        Object.keys(groupedByMappingStatus).forEach((mappingStatus) => {
          nextClientsByMappingStatus[mappingStatus] = Array.from(
            new Set([
              // Keep selected ids on filter changes
              ...((hasSelectedIds && initial
                ? state.clientsByMappingStatus[mappingStatus]?.filter(
                    (id) => selectedIdsHash[id]
                  )
                : state.clientsByMappingStatus[mappingStatus]) || []),
              ...groupedByMappingStatus[mappingStatus]
            ])
          );
        });
      }

      let nextOffsetAdjustment = state.offsetAdjustment;
      if (hasSelectedIds) {
        // Count the number of selected clients that are going to be kept in the list
        // that don't belong (didn't come from the fetch) - this number will be used
        // for correcting future fetch offsets
        if (initial) {
          let numDontBelong = 0;
          (
            state.selectedClients[constants.MEMBERS_LISTS.FROM_INTEGRATION] ||
            []
          ).forEach((id) => {
            if (!newEntities[id]) {
              numDontBelong = numDontBelong + 1;
            }
          });
          nextOffsetAdjustment = numDontBelong;
        } else {
          // When not initial, we need to decrement offsetAdjustment if a selected client
          // is in this fetch's response, since it now belongs in the list
          (
            state.selectedClients[constants.MEMBERS_LISTS.FROM_INTEGRATION] ||
            []
          ).forEach((id) => {
            if (newEntities[id]) {
              nextOffsetAdjustment = nextOffsetAdjustment - 1;
            }
          });
        }
      } else {
        nextOffsetAdjustment = 0;
      }

      return {
        ...state,
        integrationClients:
          initial && !hasSelectedIds
            ? newEntities
            : { ...state.integrationClients, ...newEntities },
        clientsByMappingStatus: nextClientsByMappingStatus,
        integrationClientsOrder: initial
          ? entityIdOrder
          : Array.from(
              new Set([...state.integrationClientsOrder, ...entityIdOrder])
            ),
        offset: (offset !== undefined ? offset : state.offset) + (limit || 0),
        isLoading: false,
        initiallyLoaded: true,
        isSearchLoading: false,
        counts: initial ? count : state.counts,
        offsetAdjustment: nextOffsetAdjustment
      };
    }
    case constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        mappedMosaicIds: makeIdHash(
          [
            ...(response[constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_TARGET] || []),
            ...(response[constants.MAPPING_STATUS.ACTIVE] || [])
          ].filter((id) => id)
        )
      };
    }
    case constants.BATCH_SELECT_INTEGRATION_CLIENTS: {
      const { value, integration_ids, section } = payload;
      return {
        ...state,
        selectedClients: {
          // ...state.selectedClients,
          [section]: {
            ...state.selectedClients[section],
            ...integration_ids.reduce((acc, cur) => {
              acc[cur] = value;
              return acc;
            }, {})
          }
        }
      };
    }
    case constants.CLEAR_BATCH_SELECTED_INTEGRATION_CLIENTS: {
      return {
        ...state,
        selectedClients: {},
        rowStates: {}
      };
    }

    case constants.SET_SELECTED_INTEGRATION_CLIENTS: {
      const { ids, section, rowState, isSelected, isBatch } = payload;

      const getDefaultRowState = (id) => {
        const integrationClient = state.integrationClients[id];

        if (integrationClient) {
          return {
            mosaicId: integrationClient.mosaicId,
            name: `${integrationClient.targetData?.title || ''}`
          };
        }
        return {};
      };

      // Deselect - clear relevant row states and filter out the ids
      if (!isSelected) {
        const toRemoveIds = new Set(ids);
        const nextSelectedClients = {
          ...state.selectedClients,
          [section]: (state.selectedClients[section] || []).filter(
            (id) => !toRemoveIds.has(id)
          )
        };
        const nextRowStates = { ...state.rowStates };
        ids.forEach((id) => delete nextRowStates[id]);
        return {
          ...state,
          selectedClients: nextSelectedClients,
          rowStates: nextRowStates
        };
      }

      return {
        ...state,
        selectedClients: {
          // ...state.selectedClients, <- if we allow selection in different sections
          [section]: [
            ...(isBatch && state.selectedClients[section]
              ? state.selectedClients[section]
              : []),
            ...ids
          ]
        },
        rowStates: {
          ...(isBatch && { ...state.rowStates }),
          ...ids.reduce((acc, cur) => {
            acc[cur] = rowState || getDefaultRowState(cur);
            return acc;
          }, {})
        }
      };
    }

    case constants.SET_CLIENT_ROW_STATE: {
      return {
        ...state,
        rowStates: {
          ...state.rowStates,
          [payload.id]: {
            ...state.rowStates[payload.id],
            ...payload.rowState
          }
        }
      };
    }

    case constants.SET_CLIENT_MAPPING_STEP: {
      return {
        ...state,
        mappingSteps: {
          // ...state.mappingSteps,
          [payload.section]: payload.mappingStep
        }
      };
    }
    case constants.CLEAR_CLIENTS_MAPPING_STEPS: {
      return {
        ...state,
        mappingSteps: {}
      };
    }

    case constants.SET_INTEGRATION_CLIENTS_SEARCH_LOADING: {
      return {
        ...state,
        isSearchLoading: payload.value
      };
    }
    case constants.RESET_INTEGRATION_CLIENTS: {
      return initialState;
    }

    case constants.MAP_CLIENTS.TRIGGER: {
      const { body, isDataTypeChange } = action.payload;

      const nextIntegrationClients = { ...state.integrationClients };
      const mappedIds = new Set();

      body.forEach((client) => {
        const { mappingStatus, uuid, mosaicData } = client;
        mappedIds.add(uuid);
        if (isDataTypeChange) {
          delete nextIntegrationClients[uuid];
        } else {
          nextIntegrationClients[uuid] = {
            ...nextIntegrationClients[uuid],
            mappingStatus:
              mappingStatus === constants.MAPPING_STATUS.NULL
                ? constants.MAPPING_STATUS.NULL
                : constants.MAPPING_STATUS.PENDING,
            ...(mosaicData && { mosaicData })
          };
        }
      });

      return {
        ...state,
        integrationClients: nextIntegrationClients,
        clientsByMappingStatus: !isDataTypeChange
          ? state.clientsByMappingStatus
          : {
              ...state.clientsByMappingStatus,
              [constants.MAPPING_STATUS.NULL]: state.clientsByMappingStatus[
                constants.MAPPING_STATUS.NULL
              ].filter((id) => !mappedIds.has(id))
            },
        counts: !isDataTypeChange
          ? state.counts
          : {
              ...state.counts,
              mappingStatus: {
                ...state.counts.mappingStatus,
                [constants.MAPPING_STATUS.NULL]:
                  state.counts.mappingStatus[constants.MAPPING_STATUS.NULL] -
                  mappedIds.size
              }
            }
      };
    }
    case constants.MAP_CLIENTS.SUCCESS: {
      const updatedClient = action.payload.response;
      const { uuid, original, isArchived, isDataTypeChange } =
        action.payload.requestPayload;

      if (isDataTypeChange) {
        return state;
      }
      // Update the hash
      const nextIntegrationClients = { ...state.integrationClients };
      nextIntegrationClients[updatedClient.uuid] = updatedClient;
      // Update the counts
      const nextCounts = {
        ...state.counts,
        mappingStatus: {
          ...state.counts.mappingStatus
        }
      };
      nextCounts.mappingStatus[original.mappingStatus]--;
      nextCounts.mappingStatus[updatedClient.mappingStatus] =
        (+nextCounts.mappingStatus[updatedClient.mappingStatus] || 0) + 1;

      const wasLinked = constants.ACTIVE_MAPPING_STATUSES.includes(
        original.mappingStatus
      );
      const wasNotLinked = !constants.ACTIVE_MAPPING_STATUSES.includes(
        original.mappingStatus
      );
      const noLongerLinked = !constants.ACTIVE_MAPPING_STATUSES.includes(
        updatedClient.mappingStatus
      );
      const isNowLinked = constants.ACTIVE_MAPPING_STATUSES.includes(
        updatedClient.mappingStatus
      );

      if ((wasLinked && noLongerLinked) || (wasNotLinked && isNowLinked)) {
        const list = isArchived ? 'mosaicIsArchived' : 'mosaicIsNotArchived';
        nextCounts[constants.MAPPING_STATUS.ACTIVE] = {
          ...state.counts[constants.MAPPING_STATUS.ACTIVE],
          [list]:
            +(state.counts[constants.MAPPING_STATUS.ACTIVE][list] || 0) +
            (wasLinked ? -1 : 1)
        };
      }

      return {
        ...state,
        integrationClients: nextIntegrationClients,
        ...(original.mappingStatus !== updatedClient.mappingStatus && {
          clientsByMappingStatus: {
            ...state.clientsByMappingStatus,
            [original.mappingStatus]: (
              state.clientsByMappingStatus[original.mappingStatus] || []
            ).filter((clientId) => clientId !== uuid),
            [updatedClient.mappingStatus]: [
              updatedClient.uuid,
              ...(state.clientsByMappingStatus[updatedClient.mappingStatus] ||
                [])
            ]
          }
        }),
        counts: nextCounts,
        offsetAdjustment:
          original.mappingStatus === null
            ? Math.max(0, state.offsetAdjustment - 1)
            : state.offsetAdjustment
      };
    }
    case constants.MAP_CLIENTS.FAILURE: {
      const { uuid, original } = action.payload.requestPayload;

      return {
        ...state,
        integrationClients: {
          ...state.integrationClients,
          [uuid]: original
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default createIntegrationReducer(clients, initialState);
