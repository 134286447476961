import styled from 'styled-components';
import theme from 'theme';
import { makeDefaultInitial } from './utils';

const StyledCircle = styled.button<{ customStyle?: string }>`
  background-color: ${theme.colors.colorLightGray7};
  color: ${theme.colors.colorCalendarGray};
  border: solid 1px transparent;
  border-radius: 50%;
  font-weight: 600;
  height: 37px;
  width: 38px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ customStyle }) => customStyle || ''};
`;

type Props = {
  value?: string;
  initial?: string;
  dataTip?: string;
  customStyle?: string;
};

/**
 *
 * Use `initial` if the parent already formatted the initial and only wants to display it
 * Use `value` if wants the component to format it by default.
 *
 */
const Initial = ({ initial, value, dataTip, customStyle }: Props) => {
  const initialToUse = initial ?? makeDefaultInitial(value);

  return (
    <StyledCircle
      data-tip={dataTip}
      data-for={'app-tooltip'}
      data-effect="solid"
      data-html
      data-class="center"
      customStyle={customStyle}
    >
      {initialToUse}
    </StyledCircle>
  );
};

export default Initial;
