import { createSelector, Selector } from '@reduxjs/toolkit';
import { LeanApiIsoState } from 'LeanApiModule/types';
import { useLeanApi } from 'LeanApiModule/utils';
import { RootState } from 'reduxInfra/shared';
import { PlannerPageViewFilter } from './types';
import { isPlannerPageViewFilterActiveByParts } from './utils';

const getOwnPageViewFilter: Selector<
  RootState,
  PlannerPageViewFilter | undefined
> = (_state, { pageViewFilter }: { pageViewFilter?: PlannerPageViewFilter }) =>
  pageViewFilter;

const getOwnLeanApiIsoState: Selector<
  RootState,
  LeanApiIsoState | undefined
> = (_state, { leanApi }: { leanApi?: ReturnType<typeof useLeanApi> }) =>
  leanApi?.leanApiIsoState;

export const makeGetPlannerPageViewFilterResults = () =>
  createSelector(
    getOwnPageViewFilter,
    getOwnLeanApiIsoState,
    (pageViewFilter, leanApiIsoState) => {
      const isFilterPartsActive =
        isPlannerPageViewFilterActiveByParts(pageViewFilter);

      return isFilterPartsActive.hasAccountsFilter ||
        isFilterPartsActive.hasUnassignedOrAssignedFilter
        ? {
            accountIds: isFilterPartsActive.hasAccountsFilter
              ? (leanApiIsoState?.topLevelOrder as number[] | undefined)
              : undefined,
            isProjectsWithUnassignedRoles:
              pageViewFilter?.custom.isProjectsWithUnassignedRoles
          }
        : undefined;
    }
  );
