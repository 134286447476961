import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';

import { getTeamCapacity } from 'CapacityModule/selectors';

import {
  fetchTeamCapacity,
  updateTeamCapacity
} from 'CapacityModule/actionCreators';

import StandardGeneralContent from './StandardGeneralContent';
import { getSelectedTeamId, getSelectedTeamViewSettings } from 'selectors';

const StandardGeneralContainer = () => {
  const dispatch = useDispatch();
  const capacity = useSelector(getTeamCapacity);
  const teamId = useSelector(getSelectedTeamId);
  const viewSettings = useSelector(getSelectedTeamViewSettings);

  const [localCapacity, setLocalCapacity] = useState(capacity);

  useEffect(() => {
    if (!capacity || capacity.monday === undefined) {
      dispatch(fetchTeamCapacity({ teamId }));
    }
  }, [capacity, dispatch, teamId]);

  useEffect(() => {
    setLocalCapacity(capacity);
  }, [capacity]);

  const handleOnBlur = () => {
    if (capacity !== localCapacity) {
      dispatch(updateTeamCapacity({ capacity: localCapacity }));
    }
  };

  const handleChange = useCallback(
    ({ value, event }) => {
      if (!isNaN(value))
        setLocalCapacity({
          ...localCapacity,
          [event.target.name]: value
        });
    },
    [localCapacity]
  );

  return (
    <DynamicModuleLoader modules={[getCapacitiesModule()]}>
      <StandardGeneralContent
        capacity={localCapacity}
        handleChange={handleChange}
        handleOnBlur={handleOnBlur}
        workloadSettings={viewSettings}
      />
    </DynamicModuleLoader>
  );
};

export default StandardGeneralContainer;
