import theme from 'theme';
import { getTeamSlug, getProjectHash, getZoom, getAuthToken } from 'selectors';
import { navigateToProject, createTaskAndOpenModal } from 'actionCreators';
import { ZOOM_LEVELS } from 'appConstants/workload';

import {
  StyledContextualMenu,
  StyledMenuItem,
  StyledDeleteIcon,
  StyledCutIconContainer,
  StyledGoToProjectIcon,
  StyledMenuDivider,
  MenuCloseIconContainer,
  StyledTaskIconContainer,
  StyledQuestionIconContainer,
  StyledInsertIconContainer
} from './styles';
import CopyIcon from 'icons/CopyIcon';
import InsertIcon from 'icons/InsertIcon';
import CutIcon from 'icons/CutIcon';
import HelpIcon from 'icons/HelpIcon';
import CloseIcon from 'icons/NewCloseIcon';
import TaskIcon from 'icons/TaskIcon';
import { deserializeBar } from 'appUtils/projectPlannerUtils';
import uuid from 'uuid';
import moment from 'moment';
import { PLANNER_MODAL } from 'appConstants/taskListTypes';
import { useWorkplanPermission } from 'PermissionsModule/SpaceLevelPermissions/permissionProviders/workplan';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import {
  COPY_WORK_PLANS_TIP,
  SPLIT_WORK_PLANS_TIP,
  DELETE_WORK_PLANS_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import cn from 'classnames';

const noop = () => {};
const ScheduleBarContextMenu = ({
  styles = { top: 0, left: 0, padding: '10px' },
  scheduleBar,
  handleScheduleBarCopy = noop,
  setSplitScheduleBar = noop,
  openDeleteModal = noop,
  onSelect = noop,
  handleCloseContextMenu = noop,
  activateTaskSplitScreen = noop,
  zoom = null,
  canDelete,
  canEdit,
  canCreate
}) => {
  const dispatch = useAppDispatch();
  const teamSlug = useAppSelector(getTeamSlug);
  const projectHash = useAppSelector(getProjectHash);
  const token = useAppSelector(getAuthToken);
  const project = projectHash[scheduleBar.project_id];
  const deserializedId = deserializeBar(scheduleBar.id)?.itemId;

  const { getCanCopyWorkPlan, getCanSplitWorkPlan, getCanDeleteWorkPlan } =
    useWorkplanPermission();

  const canCopyWorkplan = getCanCopyWorkPlan({
    accountId: scheduleBar?.account_id
  });
  const canSplitWorkplan = getCanSplitWorkPlan({
    accountId: scheduleBar?.account_id
  });
  const canDeleteWorkplan = getCanDeleteWorkPlan({
    accountId: scheduleBar?.account_id
  });

  const handleCreateTask = () => {
    const body = {
      description: scheduleBar.description || '',
      project_id: scheduleBar.project_id,
      assignee_ids: [scheduleBar.account_id],
      schedule_start: moment(scheduleBar.start_date).format('YYYY-MM-DD'),
      schedule_end: moment(scheduleBar.end_date).format('YYYY-MM-DD'),
      id: uuid()
    };
    if (scheduleBar.phase_id) {
      body.phase_id = scheduleBar.phase_id;
    }
    if (scheduleBar.activity_id) {
      body.activity_id = scheduleBar.activity_id;
    }
    if (scheduleBar.activity_phase_id) {
      body.activity_phase_id = scheduleBar.activity_phase_id;
    }
    dispatch(
      createTaskAndOpenModal({
        token,
        body,
        taskListType: PLANNER_MODAL
      })
    );
  };
  const isOneDayBar = moment(scheduleBar.start_date).isSame(
    moment(scheduleBar.end_date),
    'day'
  );

  return (
    <StyledContextualMenu style={styles}>
      <MenuCloseIconContainer top="4" onClick={handleCloseContextMenu}>
        <CloseIcon height="30" width="30" stroke="transparent" />
      </MenuCloseIconContainer>
      {canCreate !== false && (
        <StyledMenuItem
          style={{ marginTop: '5px' }}
          onClick={
            canCopyWorkplan
              ? () => {
                  handleScheduleBarCopy(deserializedId || scheduleBar.id);
                  onSelect();
                }
              : undefined
          }
          data-for="app-tooltip"
          data-tip={COPY_WORK_PLANS_TIP}
          data-effect="solid"
          data-tip-disable={canCopyWorkplan}
          className={cn({ disabled: !canCopyWorkplan })}
        >
          <StyledCutIconContainer />
          <CopyIcon />
          <StyledCutIconContainer />
          Copy
        </StyledMenuItem>
      )}
      {!isOneDayBar && canCreate !== false && canEdit !== false && (
        <StyledMenuItem
          onClick={
            canSplitWorkplan
              ? () => {
                  setSplitScheduleBar(deserializedId || scheduleBar.id);
                  onSelect();
                }
              : undefined
          }
          data-for="app-tooltip"
          data-tip={SPLIT_WORK_PLANS_TIP}
          data-effect="solid"
          data-tip-disable={canSplitWorkplan}
          className={cn({ disabled: !canSplitWorkplan })}
        >
          <StyledCutIconContainer />
          <CutIcon />
          <StyledCutIconContainer />
          <span style={{ marginTop: '2px' }}>Split</span>
          <StyledQuestionIconContainer
            data-for={'app-tooltip'}
            data-tip={'Split a work plans <br /> into two work plans'}
            data-html
            data-class="center"
            data-effect="solid"
            data-tip-disable={!canSplitWorkplan}
          >
            <HelpIcon
              questionMarkColor={theme.colors.colorPureBlack}
              width={8}
              fillColor={theme.colors.colorPaleGray10}
              className="work-plans-split-help"
              circleColor=""
            />
          </StyledQuestionIconContainer>
        </StyledMenuItem>
      )}

      {/* INSERT below condition is not correct, jsut place holder */}
      {/* {!isOneDayBar && canCreate !== false && canEdit !== false && (
        <StyledMenuItem
          onClick={() => {
            setSplitScheduleBar(deserializedId || scheduleBar.id);
            onSelect();
          }}
        >
          <StyledInsertIconContainer />
          <InsertIcon />
          <StyledInsertIconContainer />
          <span style={{ marginTop: '1px' }}>Insert</span>
          <StyledQuestionIconContainer
            data-for={'app-tooltip'}
            data-tip={
              'Split a work plan and insert <br/> a new work plan in between'
            }
            data-html
            data-class="center"
            data-effect="solid"
            // data-offset="{ 'top': -5 }"
          >
            <HelpIcon
              questionMarkColor={theme.colors.colorMediumGray9}
              circleColor={theme.colors.colorMediumGray5}
            />
          </StyledQuestionIconContainer>
        </StyledMenuItem>
      )} */}

      {canDelete !== false && (
        <StyledMenuItem
          onClick={
            canDeleteWorkplan
              ? () => {
                  openDeleteModal(deserializedId || scheduleBar.id);
                  onSelect();
                }
              : undefined
          }
          data-for="app-tooltip"
          data-tip={DELETE_WORK_PLANS_TIP}
          data-effect="solid"
          data-tip-disable={canDeleteWorkplan}
          className={cn({ disabled: !canDeleteWorkplan })}
        >
          <StyledDeleteIcon />
          Delete
        </StyledMenuItem>
      )}
      <StyledMenuDivider />
      {!!scheduleBar.account_id && canEdit !== false && (
        <StyledMenuItem
          onClick={() => {
            handleCreateTask();
            onSelect();
            handleCloseContextMenu();
          }}
        >
          <StyledTaskIconContainer>
            <TaskIcon
              height={12}
              width={12}
              color={theme.colors.colorMediumGray9}
            />
          </StyledTaskIconContainer>
          <span style={{ marginTop: '1px' }}> Plan Task </span>
        </StyledMenuItem>
      )}
      <StyledMenuItem
        onClick={() => {
          dispatch(
            navigateToProject({
              teamSlug,
              projectSlug: project?.slug ?? 'project',
              projectId: scheduleBar.project_id,
              openInNewWindow: true
            })
          );
        }}
      >
        <StyledGoToProjectIcon color={theme.colors.colorMediumGray9} />
        Go To Project
      </StyledMenuItem>
    </StyledContextualMenu>
  );
};
export default ScheduleBarContextMenu;
