import { useCallback, useState, useEffect } from 'react';
import { SetReturnType } from 'type-fest';

/**
 * Mix of useCallback and useEffect. The callback will be called after render, just like useEffect.
 *
 * eg. use case - table groupBy changes which triggers an initial fetch, but InfiniteLoader calls loadMoreItems during
 * the render cycle, which means loadMoreItems is called with the current list items + new groupBy value => bug
 */
export const useCallbackEffect = <T extends (...args: any[]) => any>(
  callbackFunc?: T
) => {
  const [args, setArgs] = useState<Nullable<any[]>>(null);

  const dummyCallback = useCallback((...args) => {
    setArgs(args);
  }, []);

  useEffect(() => {
    if (args && callbackFunc) {
      callbackFunc(...args);
      setArgs(null);
    }
  }, [args, callbackFunc]);

  return dummyCallback as SetReturnType<T, void>;
};
