import styled from 'styled-components';
import theme from 'theme';

export const LabelWithIcon = ({
  icon,
  label,
  fontSize = 13,
  fontColor = theme.colors.colorMediumGray9,
  iconSize = 16,
  gapSize = 6,
  shouldEllipsis = true,
  iconTopAdjustment = 0,
  labelTopAdjustment = 0
}: {
  icon: React.ReactNode;
  label: string;
  fontSize?: number;
  fontColor?: string;
  iconSize?: number;
  gapSize?: number;
  shouldEllipsis?: boolean;
  iconTopAdjustment?: number;
  labelTopAdjustment?: number;
}) => (
  <StyledRow iconSize={iconSize} gapSize={gapSize}>
    <StyledIconContainer iconSize={iconSize} top={iconTopAdjustment}>
      {icon}
    </StyledIconContainer>
    <StyledLabel
      fontSize={fontSize}
      color={fontColor}
      className={shouldEllipsis ? 'no-text-overflow' : ''}
      top={labelTopAdjustment}
    >
      {label}
    </StyledLabel>
  </StyledRow>
);

/* ------------------------------------ - ----------------------------------- */

const StyledIconContainer = styled.div<{
  iconSize: number;
  top: number;
}>`
  position: relative;
  top: ${({ top }) => top}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ iconSize }) => iconSize}px;
  height: ${({ iconSize }) => iconSize}px;
`;

const StyledLabel = styled.div<{
  fontSize: number;
  color: string;
  top: number;
}>`
  display: flex;
  align-items: center;
  position: relative;
  top: ${({ top }) => top}px;
  height: 100%;
  font-size: ${(props) => props.fontSize}px;
  color: ${({ color }) => color};
`;

const StyledRow = styled.div<{ iconSize: number; gapSize: number }>`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) =>
    `${props.iconSize + props.gapSize}px 1fr`};
`;
