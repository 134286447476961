import React from 'react';
import { PlannerColumn } from '..';
import { keyifyDate } from 'appUtils/plannerUtils';

const PlannerColumnRange = ({ dateRange, rowIndex, ...props }) => {
  return dateRange.map((date, colIndex) => (
    <PlannerColumn
      {...props}
      key={keyifyDate(date)}
      date={keyifyDate(date)}
      rowIndex={rowIndex}
    />
  ));
};

// planner performance hinges on this memo- change with caution
export default React.memo(PlannerColumnRange);
