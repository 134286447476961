import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getOfficesHash,
  getMembershipsByOffice,
  getAccountIdToOfficeHash
} from 'SettingsModule/selectors/offices';
import { useNavToSettings } from 'SettingsModule/hooks';
import { useLoadOffices } from './useLoadOffices';

const emptyObj = {};

export const useOffices = ({
  isOff,
  shouldAutoLoad
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  /** default is true */
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const membershipsByOffice = useAppSelector((state) =>
    isOff ? emptyObj : getMembershipsByOffice(state)
  ) as ReturnType<typeof getMembershipsByOffice>;

  const accountIdToOfficeHash = useAppSelector((state) =>
    isOff ? emptyObj : getAccountIdToOfficeHash(state)
  ) as ReturnType<typeof getAccountIdToOfficeHash>;

  const officeHash = useAppSelector(getOfficesHash);

  const getOfficeLabel = useCallback(
    (officeId: number | string) => {
      return officeHash[+officeId]?.name ?? 'No Office';
    },
    [officeHash]
  );

  const { handleNavToSettings: handleNavToOfficesPage } = useNavToSettings({
    viewType: 'members',
    tab: 'offices'
  });

  const { isLoaded, isLoading, loadOffices } = useLoadOffices({
    shouldAutoLoad,
    isOff
  });

  return {
    isLoaded,
    isLoading,
    loadOffices,
    membershipsByOffice,
    officeHash,
    accountIdToOfficeHash,
    handleNavToOfficesPage,
    getOfficeLabel
  };
};
