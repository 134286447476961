import { select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import { getAuthToken } from 'selectors';

const { fetchNoteTags } = entityActions;

export function* fetchNoteTagsByBoard(action) {
  const token = yield select(getAuthToken);
  const { boardId } = action.payload;

  yield fetchEntity(
    fetchNoteTags,
    api.fetchNoteTagsByBoard,
    undefined,
    [token, boardId],
    action
  );
}

export function* updateTag(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTag,
    api.updateTag,
    [token, id, payload],
    action
  );
}
