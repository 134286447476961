export const INVOICE_TYPES = {
  FIXED_FEE: 'Fixed Fee',
  PERCENTAGE: 'Percentage'
};

export const BILLING_TABLE_VIEWS = {
  ESTIMATES: 'estimates',
  INVOICES: 'invoices'
};

export const DEFER_BILLING_SETUP = 'DEFER_BILLING_SETUP';
