import { lazy, Suspense } from 'react';
import { SIDE_FILTER_PORTAL_IDS } from 'FilterModule/components/SideFilter/SideFilter';

const OrgReportPageContainer = lazy(
  () => import('ReportsModule/components/OrgReport/OrgReportPageContainer')
);

const OrgChartTab = () => {
  return (
    <>
      <div id={SIDE_FILTER_PORTAL_IDS.reportSideFilterPortal} />
      <Suspense fallback={<div />}>
        <OrgReportPageContainer />
      </Suspense>
    </>
  );
};

export default OrgChartTab;
