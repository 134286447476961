import styled from 'styled-components';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';
import { StyledStickyHeader } from 'components/Table/TableStickyHeader';

export const NoDataText = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 14px;
  margin-top: 32px;
  margin-left: 33px;
`;

export const ContainerDiv = styled.div<{
  $width: number;
  hasBatchActionsMenu: boolean;
}>`
  margin: 0 auto;
  ${(props) =>
    props.hasBatchActionsMenu &&
    'left: 110px;'} // due to visibility hidden batch action menu, keeps table centered
  position: relative;
  width: ${(props) => props.$width}px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const StyledUnplannedTable = styled.div`
  display: flex; // for showing batch actions menu on right
  position: relative;
  margin: 25px auto 0;
  flex: 1;
  min-height: 0;

  .collapse-arrow {
    path {
      fill: ${theme.colors.colorMediumGray9};
    }
  }
  .collapse-all {
    z-index: 2;
    left: -8px;
    top: -9px;
    position: absolute;
    background: transparent;
    border: none;
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: ${theme.colors.colorMediumGray9};
      }
    }
  }

  .table {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table-body {
    height: 100%;
    flex: 1;
    min-height: 0;
  }

  .variable-size-list {
    height: 100% !important;
    padding-bottom: 30px;
  }

  .empty {
    visibility: hidden;
  }

  .td {
    height: 100%;
    flex-shrink: 0;
    // default cell font
    font-size: 13px;
    color: ${theme.colors.colorSemiDarkGray1};

    &.rightPadding {
      flex-shrink: 1;
    }

    &.phaseRow {
      &.entity {
        border-right: 1px solid ${theme.colors.colorLightGray6};
      }
    }
  }

  /* ---------------------------------- Rows ---------------------------------- */
  .projectHeaderRow {
    .td {
      &.collapse {
        padding-top: 8px;
      }
    }
  }

  .scopeRow,
  .scopeRequestRow,
  .workplanRequestRow {
    .td {
      &:not(.rightPadding):not(.select) {
        background: white;
        border-bottom: 2px solid ${theme.colors.colorLightGray19};
      }
    }

    .project-placeholder-text {
      visibility: hidden;
    }

    &:hover {
      .project-placeholder-text {
        color: ${theme.colors.colorMediumGray1};
        visibility: visible;
      }
    }
  }

  ${StyledStickyHeader} {
    padding: 0;
    top: 0px;
    .td {
      background: ${theme.colors.colorLightGray19};
      &.rightPadding {
        opacity: 0;
      }
    }
  }
`;

export const StyledTooltip = styled(ReactTooltip)`
  font-size: 11px;
  font-weight: normal;
  padding: 8px;
  text-align: center;
  z-index: 1000;
  max-width: 240px;
`;

export const RoundedOutlinedButton = styled.button`
  border: 1px solid ${theme.colors.colorCalendarBlue};
  background: white;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
  font-weight: 600;
  padding: 2px 13px;
  border-radius: 20px;
  position: relative;
  white-space: nowrap;
  left: 10px;
  &:hover {
    filter: brightness(98%);
  }
`;
