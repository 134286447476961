import {
  fetchTeamPermissions,
  updateTeamPermissions
} from 'PermissionsModule/actionCreators';
import { fork, takeEvery } from 'redux-saga/effects';
import workers from '../sagaWorkers';

export const permissionsSagas = [
  fork(takeEvery, fetchTeamPermissions, workers.fetchTeamPermissionsWorker),
  fork(takeEvery, updateTeamPermissions, workers.updateTeamPermissionsWorker)
];

export default permissionsSagas;
