import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NewGroupIcon = ({ fill = '#4f4f4f' }) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M1 13.5V12.5H13V13.5H1ZM1 9V8H13V9H1ZM1 4.5V3.5H6.5V4.5H1Z"
        fill={fill}
      />
      <path d="M9 4.7H11.8H13.2H14.6V3.3H13.2H11.8H9V4.7Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4.00004L15.0789 3.11508L12.7872 0.913244C12.6995 0.828917 12.557 0.828917 12.4689 0.913244L11.8661 1.49236C11.778 1.57659 11.778 1.71358 11.8661 1.79781L14.1578 3.99965L11.8661 6.20187C11.778 6.28649 11.778 6.42309 11.8661 6.50742L12.4689 7.08654C12.557 7.17115 12.6991 7.17115 12.7872 7.08654L15.0789 4.88499L16 4.00004Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default NewGroupIcon;
