import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';

interface SwitchProps {
  disabled?: boolean;
  isChecked: boolean;
  checkedText?: string;
  uncheckedText?: string;
  onChange: () => void;
}

export const Switch = ({
  disabled,
  isChecked,
  checkedText = 'On',
  uncheckedText = 'Off',
  onChange
}: SwitchProps) => {
  return (
    <RootContainer $disabled={disabled}>
      <ContentContainer $isActive={isChecked} onClick={onChange}>
        <TextContainer>{checkedText}</TextContainer>
      </ContentContainer>
      <ContentContainer $isActive={!isChecked} onClick={onChange}>
        <TextContainer>{uncheckedText}</TextContainer>
      </ContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled.span.attrs<{ $disabled?: boolean }>(
  ({ $disabled }) => ({ className: cn({ disabled: $disabled }) })
)<{ $disabled?: boolean }>`
  display: inline-flex;
  border-radius: 10px;
  border: 1px solid ${theme.colors.colorLightGray6};
  max-width: fit-content;
  height: 20px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;

    .active {
      background-color: ${({ theme }) => theme.colors.colorCalendarGray};
    }
  }
`;

interface ContentContainerProps {
  $isActive: boolean;
}

const ContentContainer = styled.div.attrs<ContentContainerProps>(
  ({ $isActive }) => ({
    className: cn({ active: $isActive })
  })
)<ContentContainerProps>`
  align-items: center;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  display: flex;
  padding: 2px 6px;

  &.active {
    background-color: ${({ theme }) => theme.colors.colorQbTooltipGreen};
    color: ${({ theme }) => theme.colors.colorPureWhite};
  }
`;

const TextContainer = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
`;
