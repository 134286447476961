import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';
import { RowValidator } from 'CsvImportsModule/types';
import moment from 'moment';

export const activityStartDateValidator: RowValidator = ({
  row,
  entityType
}) => {
  const {
    enteredActivityStartDate,
    enteredActivityEndDate,
    enteredActivityTitle
  } = getRowValuesForEntityType(row, entityType);

  const momentStartDate = moment(enteredActivityStartDate, 'YYYY-MM-DD', true);
  const momentEndDate = moment(enteredActivityEndDate);

  if (enteredActivityStartDate === TRAINING_DATA_REQUIRED_STRING) {
    return false;
  } else if (!enteredActivityStartDate) {
    return true;
  } else if (!enteredActivityTitle) {
    return ERROR_MESSAGES.nonEmptyFieldForDefaultActivity;
  } else if (!momentStartDate.isValid()) {
    return ERROR_MESSAGES.invalidDateFormat;
  } else if (momentEndDate.isBefore(momentStartDate)) {
    return ERROR_MESSAGES.endDateBeforeStartDate;
  } else if (!enteredActivityEndDate) {
    return ERROR_MESSAGES.endDateRequired;
  } else if (enteredActivityEndDate) {
    return true;
  }
  return false;
};
