import { select } from 'redux-saga/effects';
import { getAuthToken } from 'AuthenticationModule/selectors';
import {
  phaseSuggestionsActionCreatorsMap,
  phaseSuggestionsActions
} from 'SuggestionModule/actionCreators';
import { phaseSuggestionsApis } from 'SuggestionModule/api';
import { fetchEntity } from 'sagas/generics';

function* fetchPhaseSuggestionsForTimesheetAccounts(
  action: ReturnType<
    typeof phaseSuggestionsActions.fetchPhaseSuggestionsForTimesheetAccounts
  >
) {
  const token = yield select(getAuthToken);

  yield fetchEntity(
    phaseSuggestionsActionCreatorsMap.fetchPhaseSuggestionsForTimesheetAccounts,
    phaseSuggestionsApis.fetchPhaseSuggestionsForTimesheetAccounts,
    null,
    [token, action.payload],
    action
  );
}

/* -------------------------------------------------------------------------- */
export const phaseSuggestionsWorkers = {
  fetchPhaseSuggestionsForTimesheetAccounts
};
