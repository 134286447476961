import React, { useMemo } from 'react';
import { PermissionsActions } from 'PermissionsModule/types';
import { PermissionActionHash } from 'PermissionsModule/components/PermissionsTable/types';
import {
  IconContainer,
  HeaderText,
  Header,
  Section,
  Container
} from 'PermissionsModule/components/styles';

import GearIcon from 'icons/GearIcon';

interface PermissionSectionProps {
  headerText: string;
  Icon?: (props: unknown) => JSX.Element;
  permissions: Partial<PermissionActionHash>;
  renderPermissionRow: (permissionAction: PermissionsActions) => JSX.Element;
}

export const PermissionSection = ({
  headerText,
  Icon = GearIcon,
  permissions,
  renderPermissionRow
}: PermissionSectionProps) => {
  const permissionOrder = useMemo(
    () => (Object.keys(permissions) as Array<keyof typeof permissions>) || [],
    [permissions]
  );
  return (
    <Container>
      <Header>
        <IconContainer>
          <Icon />
        </IconContainer>
        <HeaderText>{headerText}</HeaderText>
      </Header>
      <Section>
        {permissionOrder.map((permissionAction) => {
          return renderPermissionRow(permissionAction);
        })}
      </Section>
    </Container>
  );
};
