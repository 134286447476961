import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { Modal } from 'reactstrap';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';

interface DeleteScopesModalUiProps {
  handleClose: () => void;
  handleDeleteConfirm: () => void;
  isMultiple?: boolean;
  isRequest?: boolean;
}

/**
 * Delete modal UI - for both scopes and scope requests
 */
export const DeleteScopesModalUi = ({
  isMultiple,
  isRequest,
  handleClose,
  handleDeleteConfirm
}: DeleteScopesModalUiProps) => {
  return (
    <SimpleConfirmModal
      isOpen
      header="Delete Scope"
      body={
        <p>
          Are you sure you want to{' '}
          <span style={{ fontWeight: 700 }}>permanently delete</span>{' '}
          {isMultiple ? 'these ' : 'this '}Scope
          {isRequest ? ' request' : ''}
          {isMultiple ? 's' : ''}?
        </p>
      }
      toggle={handleClose}
      onConfirm={handleDeleteConfirm}
      confirmLabel="Delete"
      cancelLabel="Cancel"
      submitButtonColor={theme.colors.colorCalendarRed}
      stylesWrapper={StyledDeleteModal}
    />
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledDeleteModal = styled(Modal)`
  width: 388px;
  .modal-content {
    width: 100%;
    height: 180px;
    padding: 30px 35px;
    color: black;
    border-radius: 6px !important;
    .confirm-modal-header {
      font-size: 22px;
      margin-bottom: 12px;
    }
    .confirm-modal-body {
      margin: 0;
      font-size: 13px;
    }
  }
`;
