import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';
import theme from 'theme';

interface HorizontalDashIconProps
  extends Pick<SvgIconProps, 'className' | 'width' | 'height'> {
  fill?: string;
}

export const HorizontalDashIcon = ({
  width = '16',
  height = '12',
  fill = theme.colors.colorLightGray15,
  className
}: HorizontalDashIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 7"
  >
    <path
      d="M-9.80566e-05 3.13327L5.84724 3.1529L5.88649 3.1529L8.15306 3.1529L13.9999 3.13328L13.9999 0.866709L8.15306 0.847084L8.11381 0.847085L5.84724 0.847084L-9.83095e-05 0.866706L-9.80566e-05 3.13327Z"
      fill={fill}
    />
  </SvgIcon>
);
