import React, { Suspense, lazy, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import styled from 'styled-components';
import { PageContainer, PageContainerProps } from 'views/PageContainer';
import DefaultViewPermissionCheck from 'containers/DefaultViewPermissionCheck';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import { LegacyFilterAdapter } from 'FilterModule/components/LegacyFilterAdapter';
import { Page } from 'models/filter';
import { makeInitialFilterLevelSchemas } from './filterConfig';
import { VIEW_BY } from 'appConstants/workload';

const ProjectDetailsTableContainer = lazy(
  () => import('./ProjectDetailsTableContainer')
);

// only 1 view for now on this page
const view = VIEW_BY.PROJECTS;

/* -------------------------------- component ------------------------------- */

interface AllProjectsReportPageContainerProps {
  accountId?: number;
  shouldIncludePersonalProjects?: boolean;
}

const AllProjectsReportPageContainer = ({
  accountId,
  shouldIncludePersonalProjects
}: AllProjectsReportPageContainerProps) => {
  const isReport = !accountId;

  const filterStateId = isReport
    ? 'all-projects-report'
    : 'member-profile-projects';

  const initialFilterLevelSchemas = useMemo(() => {
    return makeInitialFilterLevelSchemas({ isReport });
  }, [isReport]);

  const pageName = isReport ? Page.AllProjectsReport : Page.MemberModalProjects;

  const getActiveFilter = useMemo(makeGetActiveWorkloadPlannerFilter, []);

  const activeFilter = useAppSelector((state) =>
    getActiveFilter(state, { viewBy: view, pageName })
  );

  const pageContainerProps: PageContainerProps = {
    pageContextProviderProps: {
      currentView: view,
      pageName: pageName
    },
    filterContextProviderProps: {
      filterSchemaParams: {
        pageName: pageName,
        initialFilterLevelSchemas,
        level2: view
      },
      // project selector still uses old infra, so this is to make sure the same filter is used
      matchingFiltersOverride: [activeFilter]
    }
  };

  return (
    // Page context
    <PageContainer {...pageContainerProps}>
      {/* permission check */}
      <DefaultViewPermissionCheck
        condition={true} // TODO space permission check
        // viewToFlag={}
      >
        {/* page container */}
        <StyledPageContainer>
          <LegacyFilterAdapter filterListId={filterStateId} />

          {/* main view component */}
          <Suspense
            fallback={
              <SkeletonLoader numLoaders={1} loaderStyle={{ height: 400 }} />
            }
          >
            <ProjectDetailsTableContainer
              viewBy={view}
              pageName={pageName}
              accountId={accountId}
              projectFilterListId={pageName}
              filterStateId={filterStateId}
              isReport={isReport}
              shouldIncludePersonalProjects={shouldIncludePersonalProjects}
            />
          </Suspense>
        </StyledPageContainer>
      </DefaultViewPermissionCheck>
    </PageContainer>
  );
};

export default React.memo(AllProjectsReportPageContainer);

/* ------------------------------------ - ----------------------------------- */

const StyledPageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
