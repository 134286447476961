import { put, select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import {
  getUserSlug,
  getAuthToken,
  getGroupsHash,
  getMemberListByAccountId
} from 'selectors';
import { getMe } from 'UsersModule/selectors';
import {
  fetchGroups as fetchGroupsAction,
  fetchBoardMembers as fetchBoardMembersAction,
  fetchArchivedTeamBoards as fetchArchivedTeamBoardsAction,
  fetchMyProjects as fetchMyProjectsActionCreator,
  fetchTeamMembers,
  closeEditGroupModal,
  openArchiveBoardModal,
  createBoardPreference,
  navigateToHome,
  navigateToBoard,
  downloadUrl
} from 'actionCreators';
import { createResponseEntityHandler } from 'appUtils';
import * as constants from 'appConstants';
const {
  groupCreation,
  groupEdition,
  changeBoardPosition,
  groups,
  groupMembers,
  allGroupMembers,
  fetchTeams,
  putArchiveBoard,
  putUnarchiveBoard,
  selectedBoard,
  projectsListView,
  projectsListViewExport,
  archivedBoards,
  deleteDeleteBoard,
  boardModulesUpdate
} = entityActions;
const addMemberToGroupEntity = entityActions.addMemberToGroup;
const deleteMemberFromGroupEntity = entityActions.deleteMemberFromGroup;
const addBulkMembersToGroupEntity = entityActions.addBulkMembersToGroup;

export function* postBoard(action) {
  const {
    boardName,
    isPrivate,
    token,
    stayOnView,
    color,
    onSuccess = []
  } = action.payload;
  const { error, response } = yield changeEntity(
    groupCreation,
    api.postBoard,
    [boardName, isPrivate, token],
    action,
    action.payload
  );
  if (!error) {
    yield put(fetchGroupsAction(token));
    if (color) {
      const { id } = response;
      yield put(createBoardPreference({ id, color }));
    }
    if (!stayOnView) {
      yield put(
        navigateToBoard({
          teamSlug: response.team_slug,
          boardSlug: response.slug,
          boardId: response.id
        })
      );
    }
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* editGroup(action) {
  const { id, boardName } = action.payload;
  const token = yield select(getAuthToken);
  let { isPrivate } = action.payload;

  if (isPrivate !== undefined && isPrivate === true) {
    isPrivate = 'true';
  } else if (isPrivate !== undefined && isPrivate === false) {
    isPrivate = 'false';
  }

  const { error } = yield changeEntity(groupEdition, api.putEditBoard, [
    id,
    boardName,
    isPrivate,
    null,
    null,
    token
  ]);
  if (!error) {
    yield put(fetchGroupsAction(token));
  }
}

export function* archiveBoard(action) {
  const { id, archive } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(putArchiveBoard, api.putEditBoard, [
    id,
    null,
    null,
    archive,
    null,
    token
  ]);
  if (!error) {
    yield put(closeEditGroupModal());
    yield put(openArchiveBoardModal(false));
    yield put(fetchMyProjectsActionCreator(token));
    yield put(fetchGroupsAction(token));
  }
}

export function* unarchiveBoard(action) {
  const { boardId, unarchive } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(putUnarchiveBoard, api.putEditBoard, [
    boardId,
    null,
    null,
    null,
    unarchive,
    token
  ]);
  if (!error) {
    yield put(fetchGroupsAction(token));
    yield put(fetchMyProjectsActionCreator(token));
  }
}

export function* updateBoardPosition(action) {
  const { boardId, position } = action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    changeBoardPosition,
    api.putBoardPosition,
    [token, boardId, position]
  );
  if (!error) {
    yield put(fetchGroupsAction(token));
  }
}

export function* fetchGroups(action) {
  yield fetchEntity(
    groups,
    api.getBoards,
    undefined,
    [action.payload.token],
    action
  );
}

export function* fetchBoardMembers(action) {
  const { boardId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    groupMembers,
    api.fetchBoardMembers,
    undefined,
    [boardId, token],
    action
  );
}

export function* fetchAllGroupMembers(action) {
  const { token } = action.payload;
  yield fetchEntity(
    allGroupMembers,
    api.fetchBoardMembers,
    undefined,
    [null, token],
    action
  );
}

export function* fetchSelectedBoard(action) {
  const { boardId } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    selectedBoard,
    api.fetchSelectedBoard,
    undefined,
    [boardId, token],
    action
  );

  if (!error) {
    yield put(fetchBoardMembersAction(boardId));
  } else if (error) {
    const userSlug = yield select(getUserSlug) || null;
    yield put(navigateToHome({ userSlug }));
  }
}
export function* fetchProjectsListView(action) {
  const { boardId, isArchived } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectsListView,
    api.fetchProjectsListView,
    boardId,
    [token, isArchived],
    action
  );
}
export function* exportProjectsListView(action) {
  const { boardId, isArchived, exportParam } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectsListViewExport,
    api.fetchProjectsListView,
    boardId,
    [token, isArchived, exportParam],
    action
  );
  if (response.url) {
    yield put(downloadUrl({ url: response.url }));
  }
}

export function* fetchArchivedBoards(action) {
  const { token, teamId, get_archived } = action.payload;
  const { error, response } = yield fetchEntity(
    archivedBoards,
    api.fetchArchivedBoardsForTeam,
    undefined,
    [get_archived, token, teamId],
    action
  );
  if (!error) {
    yield put(fetchGroupsAction(token));
  }
}

export function* deleteBoard(action) {
  const token = yield select(getAuthToken);
  const { boardId, teamId } = action.payload;
  const { error } = yield changeEntity(deleteDeleteBoard, api.deleteBoard, [
    boardId,
    token
  ]);
  if (!error) {
    yield put(fetchGroupsAction(token));
    yield put(fetchArchivedTeamBoardsAction(token, teamId));
    yield put(fetchMyProjectsActionCreator(token));
  }
}

export function* addMemberToGroup(action) {
  const { token, groupId, accountId, roleId } = action.payload;
  const { error } = yield changeEntity(
    addMemberToGroupEntity,
    api.postAddMemberToBoard,
    [groupId, accountId, roleId, token],
    action
  );

  if (!error) {
    yield put(fetchBoardMembersAction(groupId));
    yield put(fetchTeamMembers());
  }
}

export function* addBulkMembersToGroup(action) {
  const { token, groupId, members, roleId } = action.payload;

  const { error } = yield changeEntity(
    addBulkMembersToGroupEntity,
    api.postAddMembersToBoard,
    [groupId, members, roleId, token],
    action
  );

  if (!error) {
    yield put(fetchBoardMembersAction(groupId));
    yield put(fetchTeamMembers());
  }
}

export function* updateBoardMember(action) {
  const { token, params } = action.payload;
  const { id, roleId, boardId } = params;

  const { error } = yield changeEntity(
    createResponseEntityHandler(constants.UPDATE_BOARD_MEMBER_ROLE),
    api.updateBoardMember,
    [id, roleId, token],
    action
  );

  if (!error) {
    yield put(fetchBoardMembersAction(boardId));
  }
}

export function* deleteMemberFromGroup(action) {
  const { token, memberAccountId, groupId } = action.payload;

  const groupHash = yield select(getGroupsHash);
  const me = yield select(getMe);
  const memberList = yield select(getMemberListByAccountId);

  const group = groupHash[groupId];

  const userHasBeenRemovedFromBoard =
    me && memberAccountId === me.id && group?.is_private;

  const boardMembershipId = memberList[memberAccountId]?.id;

  if (boardMembershipId) {
    const { error } = yield changeEntity(
      deleteMemberFromGroupEntity,
      api.deleteMemberFromBoard,
      [boardMembershipId, token],
      action
    );
    if (!error) {
      if (userHasBeenRemovedFromBoard) {
        const userSlug = yield select(getUserSlug) || null;
        yield put(fetchGroupsAction(token));
        yield put(navigateToHome({ userSlug }));
      }

      yield put(fetchBoardMembersAction(group && group.id, true));
      yield put(fetchTeamMembers());
    }
  }
}

export function* fetchUserTeams(action) {
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    fetchTeams,
    api.fetchAllTeams,
    [token],
    action
  );
}

export function* updateBoardModules(action) {
  const {
    boardId,
    appModules,
    columnOrder,
    stageOrder,
    priorityOrder,
    statusOrder
  } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    boardModulesUpdate,
    api.updateBoardModules,
    [
      token,
      boardId,
      appModules,
      columnOrder,
      stageOrder,
      priorityOrder,
      statusOrder
    ],
    action
  );
}
