import { put, select } from 'redux-saga/effects';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import { fetchEntity, changeEntity } from 'sagas/generics';
import {
  fetchDisciplines,
  createDiscipline,
  updateDiscipline,
  deleteDiscipline,
  updateDisciplineEntities
} from 'SettingsModule/actionCreators/settings/discipline';
import {
  fetchDisciplinesActionCreatorsMap,
  createDisciplineActionCreatorsMap,
  updateDisciplineActionCreatorsMap,
  deleteDisciplineActionCreatorsMap,
  updateDisciplineEntitiesActionCreatorsMap
} from 'SettingsModule/entityActions/discipline';
import * as api from 'SettingsModule/api/discipline';

export function* fetchDisciplinesWorker(
  action: ReturnType<typeof fetchDisciplines>
) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    fetchDisciplinesActionCreatorsMap,
    api.fetchDisciplines,
    null,
    [token, { teamId }],
    action
  );
}

export function* createDisciplineWorker(
  action: ReturnType<typeof createDiscipline>
) {
  const { teamId, name } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    createDisciplineActionCreatorsMap,
    api.createDiscipline,
    [token, { teamId, name }],
    action
  );
}

export function* updateDisciplineWorker(
  action: ReturnType<typeof updateDiscipline>
) {
  const { id, name } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    updateDisciplineActionCreatorsMap,
    api.updateDiscipline,
    [token, { id, name }],
    action
  );
}

export function* deleteDisciplineWorker(
  action: ReturnType<typeof deleteDiscipline>
) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  yield changeEntity(
    deleteDisciplineActionCreatorsMap,
    api.deleteDiscipline,
    [token, { id }],
    action
  );

  yield put(fetchDisciplines({ teamId }));
}

export function* updateDisciplineEntitiesWorker(
  action: ReturnType<typeof updateDisciplineEntities>
) {
  const { id, addEntities, removeEntities } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    updateDisciplineEntitiesActionCreatorsMap,
    api.updateDisciplineEntities,
    [token, { id, addEntities, removeEntities }],
    action
  );
}
