import { DependencyList } from './DependencyList';
import {
  DepedencyListProps,
  DependableItem
} from 'components/Dependency/types';
import { PhaseDependencyItemRenderer } from './PhaseDependencyItemRenderer';
import {
  AVAILABLE_OPTIONS,
  DEPENDABLE_LABELS,
  DEPENDABLE_TYPES
} from './constants';
import styled from 'styled-components';

interface BaseWorkPlanDependencyListProps {
  dependencyListProps: Omit<
    DepedencyListProps,
    'dependableItems' | 'baseDependableTypeLabel' | 'targetDependableTypeLabel'
  >;
  dependableItems: Array<DependableItem>;
  lockTargetOption?: boolean;
  defaultTargetItem?: DependableItem;
}

export const BaseWorkPlanDependencyList = ({
  dependencyListProps,
  dependableItems,
  lockTargetOption = false,
  defaultTargetItem
}: BaseWorkPlanDependencyListProps) => {
  return (
    <BaseWorkPlanDependencyListContainer>
      <DependencyList
        dependableItems={dependableItems}
        baseDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE]}
        targetDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE]}
        availableBaseOptions={[
          AVAILABLE_OPTIONS.start,
          AVAILABLE_OPTIONS.end,
          AVAILABLE_OPTIONS.startEnd
        ]}
        renderDependableItem={PhaseDependencyItemRenderer}
        lockTargetOption={lockTargetOption}
        defaultTargetItem={defaultTargetItem}
        maxNumDependenciesOverride={1}
        addButtonTooltipContent={
          !defaultTargetItem ? 'Select a Phase to Set Dependency' : undefined
        }
        shouldUseSingleLineSetter
        {...dependencyListProps}
      />
    </BaseWorkPlanDependencyListContainer>
  );
};

const BaseWorkPlanDependencyListContainer = styled.div`
  width: 100%;
`;
