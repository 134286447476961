import { useState, useCallback, useMemo } from 'react';
import DraggableSplitScreenDivider, {
  DragCustomHandler
} from './SplitScreenDivider';
import throttle from 'lodash/throttle';
import clamp from 'lodash/clamp';
import { SplitDividerVariant, SplitScreenConfig } from './types';
import { DragSourceMonitor } from 'react-dnd';
import { DIVIDER_HEIGHT_BY_VARIANT, THROTTLE_DELAY } from './constants';

interface UseSplitScreenDividerProps extends SplitScreenConfig {
  initialSplitState?: boolean;
  boundaryRef?: HTMLDivElement | null;
  onSplitChange?: (value: boolean) => void;
}

export const useSplitScreenDivider = ({
  initialSplitState = false,
  boundaryRef,
  onSplitChange,
  minHeights,
  initialSplitDividerPosition: initialSplitDividerPositionProp = '50%',
  shouldPullToDismiss = true,
  pullToDismissTriggerHeight = 150,
  splitDividerVariant: variant = SplitDividerVariant.Compact
}: UseSplitScreenDividerProps = {}) => {
  const [initialSplitDividerPosition, setInitialSplitDividerPosition] =
    useState<string>(initialSplitDividerPositionProp);
  const [isSplitting, setIsSplitting] = useState(initialSplitState);
  const [isDragging, setIsDragging] = useState(false);
  const [{ currentOffsetY, dragOffsetY }, setOffsetY] = useState({
    dragOffsetY: 0,
    currentOffsetY: 0
  });

  const dividerHeight = DIVIDER_HEIGHT_BY_VARIANT[variant];

  const { baseTop, baseBottom } = useMemo(() => {
    if (!boundaryRef) {
      return { baseTop: 0, baseBottom: 0 };
    }
    const { top, bottom, height } = boundaryRef.getBoundingClientRect();
    const halfHeight = 0.5 * (height - dividerHeight);
    const baseTop = top + Math.min(minHeights?.mainView ?? 0, halfHeight);
    const baseBottom =
      bottom - Math.min(minHeights?.splitView ?? 0, halfHeight) - dividerHeight;
    return { baseTop, baseBottom };
  }, [boundaryRef, dividerHeight, minHeights?.mainView, minHeights?.splitView]);

  const getClampedDeltaY = useCallback(
    ({
      deltaY,
      initialSourceClientOffset
    }: Parameters<DragCustomHandler>[0]) => {
      if (!boundaryRef || !initialSourceClientOffset) return deltaY;

      const clampedDeltaY = clamp(
        deltaY,
        baseTop - initialSourceClientOffset.y,
        baseBottom - initialSourceClientOffset.y
      );
      return clampedDeltaY;
    },
    [baseBottom, baseTop, boundaryRef]
  );

  const handleSplitChange = useCallback(
    (value) => {
      setIsSplitting(value);
      onSplitChange && onSplitChange(value);
    },
    [onSplitChange]
  );

  const handleSplit = useCallback(
    ({
      splitDividerPosition
    }: {
      splitDividerPosition?: string;
    } = {}) => {
      setOffsetY({
        dragOffsetY: 0,
        currentOffsetY: 0
      });

      if (splitDividerPosition) {
        setInitialSplitDividerPosition(splitDividerPosition);
      }

      handleSplitChange(true);
    },
    [handleSplitChange]
  );

  const handleCollapse = useCallback(() => {
    handleSplitChange(false);
  }, [handleSplitChange]);

  const handleDrag = useCallback(
    ({
      deltaY,
      initialSourceClientOffset
    }: Parameters<DragCustomHandler>[0]) => {
      setOffsetY((prev) => ({
        dragOffsetY: getClampedDeltaY({ deltaY, initialSourceClientOffset }),
        currentOffsetY: prev.currentOffsetY
      }));
    },
    [getClampedDeltaY]
  );

  const throttledHandleDrag = useMemo(
    () => throttle(handleDrag, THROTTLE_DELAY),
    [handleDrag]
  );

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(
    (monitor: DragSourceMonitor) => {
      setIsDragging(false);
      setOffsetY((prev) => ({
        dragOffsetY: 0,
        currentOffsetY: prev.dragOffsetY + prev.currentOffsetY
      }));

      // If the user has dragged the divider far enough to trigger collapse,
      // then collapse the split.
      // there is known issue with delay when dragging is ended
      // it will take 0.5-1s to trigger dragEnd
      // see: https://github.com/react-dnd/react-dnd/issues/3115
      const clientOffset = monitor.getClientOffset();
      if (shouldPullToDismiss && boundaryRef && clientOffset) {
        const { bottom } = boundaryRef.getBoundingClientRect();

        const pullToDismissThreshold = bottom - pullToDismissTriggerHeight;
        if (clientOffset.y > pullToDismissThreshold) {
          handleCollapse();
        }
      }
    },
    [
      pullToDismissTriggerHeight,
      boundaryRef,
      handleCollapse,
      shouldPullToDismiss
    ]
  );

  const offsetY = dragOffsetY + currentOffsetY;

  const mainViewContainerStyle = useMemo(
    () => ({
      height: isSplitting
        ? `calc(${initialSplitDividerPosition} + ${offsetY}px)`
        : '100%'
    }),
    [offsetY, initialSplitDividerPosition, isSplitting]
  );
  const splitViewContainerStyle = useMemo(
    () => ({
      height: `calc(100% - ${initialSplitDividerPosition} - ${
        offsetY + dividerHeight
      }px)`
    }),
    [initialSplitDividerPosition, offsetY, dividerHeight]
  );

  const renderSplitScreenDivider = useCallback(
    () => (
      <DraggableSplitScreenDivider
        handleDrag={throttledHandleDrag}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
        height={dividerHeight}
      />
    ),
    [dividerHeight, handleDragEnd, handleDragStart, throttledHandleDrag]
  );

  return {
    // SplitScreenDividerComponent
    SplitScreenDivider: renderSplitScreenDivider,
    // divider states
    offsetY,
    isDragging,
    // split states
    isSplitting,
    split: handleSplit,
    collapse: handleCollapse,
    //
    dividerHeight: dividerHeight,
    mainViewContainerStyle,
    splitViewContainerStyle
  };
};
