import { Draft, createReducer } from '@reduxjs/toolkit';
import keyBy from 'lodash/keyBy';
import { UpdateOfficeLocationInitialParams } from 'SettingsModule/actionCreators/settings/office';
import * as constants from 'SettingsModule/constants/office';
import { EntityLocation } from 'SettingsModule/models/location';
import { Office } from 'SettingsModule/models/office';

export interface OfficesState {
  officesHash: Record<number, Office>;
  isLoaded: boolean;
  isLoading: boolean;
}

export const initialState: OfficesState = {
  officesHash: {},
  isLoaded: false,
  isLoading: false
};

const handleFetchOfficesTrigger = (state: Draft<OfficesState>) => {
  state.isLoading = true;
};

const handleFetchOfficesFailure = (state: Draft<OfficesState>) => {
  state.isLoading = false;
  state.isLoaded = true;
};

const handleFetchOfficesSuccess = (state: Draft<OfficesState>, action) => {
  const { response } = action.payload as {
    response: FetchOfficesSuccessResponse;
  };

  state.officesHash = keyBy(response.offices, (office) => office.id);

  state.isLoaded = true;
  state.isLoading = false;
};

const handleCreateUpdateOfficeSuccess = (
  state: Draft<OfficesState>,
  action
) => {
  const { response } = action.payload as {
    response: CreateUpdateOfficeSuccessResponse;
  };

  state.officesHash[response.id] = response;
};

const handleUpdateOfficeEntitiesSuccess = (
  state: Draft<OfficesState>,
  action
) => {
  const { response } = action.payload as {
    response: UpdateOfficeEntitiesSuccessResponse;
  };

  state.officesHash[response.id] = response;
};

const handleUpdateOfficeLocationSuccess = (
  state: Draft<OfficesState>,
  action
) => {
  const { response, requestPayload } = action.payload as {
    response: UpdateOfficeLocationSuccessResponse;
    requestPayload: UpdateOfficeLocationInitialParams;
  };

  const officeToUpdate = state.officesHash[requestPayload.meta.officeId];

  if (officeToUpdate) {
    officeToUpdate.location = response;
  }
};

export const officesReducer = createReducer(initialState, (builder) => {
  builder.addCase(constants.FETCH_OFFICES.TRIGGER, handleFetchOfficesTrigger);
  builder.addCase(constants.FETCH_OFFICES.FAILURE, handleFetchOfficesFailure);
  builder.addCase(constants.FETCH_OFFICES.SUCCESS, handleFetchOfficesSuccess);
  builder.addCase(
    constants.CREATE_OFFICE.SUCCESS,
    handleCreateUpdateOfficeSuccess
  );
  builder.addCase(
    constants.UPDATE_OFFICE_METADATA.SUCCESS,
    handleCreateUpdateOfficeSuccess
  );
  builder.addCase(
    constants.UPDATE_OFFICE_LOCATION.SUCCESS,
    handleUpdateOfficeLocationSuccess
  );
  builder.addCase(
    constants.UPDATE_OFFICE_ENTITIES.SUCCESS,
    handleUpdateOfficeEntitiesSuccess
  );
});

interface FetchOfficesSuccessResponse {
  offices: Office[];
}

type CreateUpdateOfficeSuccessResponse = Office;
type UpdateOfficeEntitiesSuccessResponse = Office;

type UpdateOfficeLocationSuccessResponse = EntityLocation;
