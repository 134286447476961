import React from 'react';
import SvgIcon from 'components/SvgIcon';

const QuestionMarkIcon = ({
  width = '28',
  height = '28',
  fill = '#3357E8',
  className
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0Z"
      fill={fill}
    />
    <path
      d="M18 11.1448C18 11.8069 17.8249 12.3752 17.4747 12.8497C17.1244 13.3241 16.6359 13.771 16.0092 14.1903C15.7143 14.389 15.4808 14.571 15.3088 14.7366C15.1367 14.9021 15.0046 15.0703 14.9124 15.2414C14.8203 15.4124 14.7619 15.5945 14.7373 15.7876C14.7128 15.9807 14.7005 16.2041 14.7005 16.4579V16.8717H13.1889V16.4083C13.1889 15.9669 13.2289 15.5917 13.3088 15.2828C13.3886 14.9738 13.5115 14.6952 13.6774 14.4469C13.8433 14.1986 14.0553 13.9669 14.3134 13.7517C14.5714 13.5366 14.8848 13.3021 15.2535 13.0483C15.6221 12.7945 15.9017 12.5241 16.0922 12.2372C16.2826 11.9503 16.3779 11.5807 16.3779 11.1283C16.3779 10.6428 16.1936 10.2235 15.8249 9.87035C15.616 9.68276 15.3641 9.53655 15.0691 9.43172C14.7742 9.3269 14.4608 9.27448 14.129 9.27448C13.7235 9.27448 13.3671 9.33793 13.0599 9.46483C12.7527 9.59172 12.4946 9.76276 12.2857 9.97793C12.0768 10.1931 11.9201 10.4441 11.8157 10.731C11.7112 11.0179 11.659 11.3214 11.659 11.6414H10C10 11.1779 10.0891 10.7255 10.2673 10.2841C10.4455 9.84276 10.7066 9.4538 11.0507 9.11724C11.3948 8.78069 11.8249 8.51035 12.341 8.30621C12.8571 8.10207 13.4531 8 14.129 8C14.6452 8 15.1398 8.07172 15.6129 8.21517C16.086 8.35862 16.4977 8.56552 16.8479 8.83586C17.1982 9.10621 17.4777 9.43448 17.6866 9.82069C17.8955 10.2069 18 10.6483 18 11.1448ZM12.9493 20V18.1793H14.9032V20H12.9493Z"
      fill="white"
    />
  </SvgIcon>
);

export default QuestionMarkIcon;
