import { NonNegative } from 'type-fest';
import { Project } from 'ProjectsModule/models/project';

/**
 * Check if this project is a PTO project or not
 */
export const isPtoProject = (project: Project) =>
  project && project.administrative_flag === 1;

/**
 * Make a hash, with key = number or string , value = index of that string or number in the original list
 * @params
 *  @stringOrNumberList = [5,1,3]
 * 
   @returns { 5: 0, 1:1, 3:2 }
  */

export const mapStringsOrNumbersToIndex = (
  stringOrNumberList: (string | number)[]
): Record<string | number, number> => {
  const itemToIndexHash = {};
  stringOrNumberList.forEach(
    (stringOrNumber, index) => (itemToIndexHash[stringOrNumber] = index)
  );

  return itemToIndexHash;
};

/** 
 * Filter out the ids that exist on @suggestionHash
   @params 
    @itemsOrder = [2,1,4,3] 
    @suggestionHash = { 1: 'hey' }

   @returns [2,4,3]
 *  */
export const makeIdsOrderListNotInSuggestion = <T>(
  itemsOrder: number[],
  suggestionHash: Record<number, T>
) => itemsOrder.filter((id) => !suggestionHash[id]);

/**
 * Split a list into two given, given index >= 0
 * 
 * @params 
 *  @list = [5,1,3], 
 *  @index = 1
 * 
   @returns { firstHalf: [5], secondHalf: [1,3] }
  */
export const splitListIntoTwo = <S, R>(
  list: (S | R)[],
  index: NonNegative<number>
) => {
  return {
    firstHalf: list.slice(0, index) as S[],
    secondHalf: list.slice(index) as R[]
  };
};

/**
 * A util function that filters out all suggested items that belong to @suggestionHash
 * then reoreder @itemListNotInOrder to match the ordering of ids in @itemIdsOrder
 *
 * @params
 *  @itemIdsOrder [4,2,3,1] - Id of item ID in order intended
 *  @suggestionHash  { 3: Item3 } - A hash of suggested item keyed by item ID
 *  @itemListNotInOrder [Item1,Item3, Item4, Item2] (Each with key [id]: x) -  List of item not in order
 *
 * @returns [Item4, Item2, Item1]
 */
export const makeNonSuggestedItemsInOrder = ({
  itemIdsOrder,
  suggestionHash,
  itemListNotInOrder
}: {
  itemIdsOrder: number[];
  suggestionHash: Record<number, unknown>;
  itemListNotInOrder: { id: number }[];
}) => {
  const idsOrderListNotInSuggestion = makeIdsOrderListNotInSuggestion(
    itemIdsOrder,
    suggestionHash
  );
  const idToIndexHash = mapStringsOrNumbersToIndex(idsOrderListNotInSuggestion);

  const itemListInOrder = Array(itemListNotInOrder.length);

  itemListNotInOrder.forEach((item) => {
    const indexToInsertItem = idToIndexHash[item.id];
    itemListInOrder[indexToInsertItem as number] = item;
  });

  // Make sure to not include empty space
  return itemListInOrder.filter(Boolean);
};

export * from './phase';
export * from './account';
