import {
  createProjectManagers,
  deleteTasks,
  editProjectColors,
  editProjectComments,
  deleteProjectComments,
  editTaskComments,
  deleteTaskComments,
  editWorkPlanComments,
  deleteWorkPlanComments,
  editProjectViews,
  createProjectMemberships,
  editProjectMemberships,
  deleteProjectMemberships
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';
import useCan from 'appUtils/hooks/useCan';
import { useAppSelector } from 'reduxInfra/hooks';
import { getProjectHash } from 'ProjectsModule/selectors';
import { ProjectId } from 'ProjectsModule/models/project';

interface UseProjectPermissionState {
  accountId?: number;
  projectId?: ProjectId;
}

export const useProjectPermissionState = ({
  projectId,
  accountId
}: UseProjectPermissionState) => {
  const projectHash = useAppSelector(getProjectHash);
  const isPersonalProject = projectId
    ? projectHash[projectId]?.is_personal
    : false;

  const boardId = projectId ? projectHash[projectId]?.board_id : undefined;

  /* ---------- Create Project Managers ---------- */
  const canCreateProjectManagers = useCan(createProjectManagers, {
    projectId,
    accountId,
    boardId
  });

  /* ---------- Delete Tasks ---------- */
  const canDeleteTasks =
    useCan(deleteTasks, { projectId }) || isPersonalProject;

  /* ---------- Edit project colors ---------- */
  const canEditProjectColors = useCan(editProjectColors) || isPersonalProject;

  /*  ---------- Project Comments ---------- */
  const canEditProjectComments = useCan(editProjectComments, { accountId });
  const canDeleteProjectComments = useCan(deleteProjectComments, { accountId });

  /*  ---------- Task Comments ---------- */
  const canEditTaskComments = useCan(editTaskComments, { accountId });
  const canDeleteTaskComments = useCan(deleteTaskComments, { accountId });

  /*  ---------- Work Plan Comments ---------- */
  const canEditWorkPlanComments = useCan(editWorkPlanComments, { accountId });
  const canDeleteWorkPlanComments = useCan(deleteWorkPlanComments, {
    accountId
  });

  /* ---------- Scope Comments ---------- */
  // Currently no direct permissions for scope comments, use work plan for now
  // Jira Ticket to track this feature: https://mosaic-ai.atlassian.net/browse/WEB-3966
  const canEditScopeComments = canEditWorkPlanComments;
  const canDeleteScopeComments = canDeleteWorkPlanComments;

  /* ---------- Edit project views (columns) ---------- */
  const canEditProjectViews =
    useCan(editProjectViews, { projectId }) || isPersonalProject;

  /* ---------- Add/Edit/Remove Project Members ---------- */
  const canAddProjectMembership = useCan(createProjectMemberships, {
    projectId
  });
  const canEditProjectMembership = useCan(editProjectMemberships, {
    projectId
  });
  const canDeleteProjectMembership = useCan(deleteProjectMemberships, {
    projectId
  });

  /* ---------- ---------- */

  return {
    canCreateProjectManagers,
    canDeleteTasks,
    canEditProjectColors,
    canEditProjectComments,
    canDeleteProjectComments,
    canEditTaskComments,
    canDeleteTaskComments,
    canEditWorkPlanComments,
    canDeleteWorkPlanComments,
    canEditScopeComments,
    canDeleteScopeComments,
    canEditProjectViews,
    canAddProjectMembership,
    canEditProjectMembership,
    canDeleteProjectMembership
  };
};
