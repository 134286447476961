import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState, ReducerName } from 'reduxInfra/shared';
import {
  EntityRequirementsState,
  initialState
} from '../reducers/entityRequirements';
import { ENTITY_REQUIREMENT_TYPES } from '../constants';
import { ValueOf } from 'type-fest';

const emptyObj = {};

const getEntityRequirementsState: Selector<
  RootState,
  EntityRequirementsState
> = (state) => state[ReducerName.EntityRequirements] || initialState;

const getOwnEntityRequirementType = (
  _,
  ownProps: { entityRequirementType: ValueOf<typeof ENTITY_REQUIREMENT_TYPES> }
) => ownProps.entityRequirementType;

export const getRequirementAssociationsByEntityIdHash = createSelector(
  getEntityRequirementsState,
  getOwnEntityRequirementType,
  (state, ownEntityRequirementType) =>
    state.requirementAssociationsByEntity[ownEntityRequirementType] || emptyObj
);
