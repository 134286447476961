import { fork, takeEvery, all } from 'redux-saga/effects';

import * as constants from '../constants';
import roleTemplatesWorkers from 'RoleTemplatesModule/sagaWorkers';
import {
  fetchTeamRoleTemplates,
  updateTeamRoleTemplate
} from 'RoleTemplatesModule/actionCreators';
import {
  fetchOrganizationSettingsWorker,
  updateOrganizationSettingsWorker
} from './settings';

export default function* settingsSaga() {
  const settingsSagas = [
    yield fork(
      takeEvery,
      fetchTeamRoleTemplates,
      roleTemplatesWorkers.fetchTeamRoleTemplatesWorker
    ),
    yield fork(
      takeEvery,
      updateTeamRoleTemplate,
      roleTemplatesWorkers.updateTeamRoleTemplateWorker
    ),
    yield fork(
      takeEvery,
      constants.FETCH_ORGANIZATION_SETTINGS.TRIGGER,
      fetchOrganizationSettingsWorker
    ),
    yield fork(
      takeEvery,
      constants.UPDATE_ORGANIZATION_SETTINGS.TRIGGER,
      updateOrganizationSettingsWorker
    )
  ];
  yield all(settingsSagas);
}
