import { createSelector, Selector } from '@reduxjs/toolkit';
import { ReducerName, RootState } from 'reduxInfra/shared';
import { TimelinesState } from 'TimelinesModule/reducers/types';
import { TimelineViewId } from 'TimelinesModule/types/timelineView';

const getTimelines: Selector<RootState, TimelinesState> = (state: RootState) =>
  state[ReducerName.Timelines];

const getOwnTimelineViewId: Selector<
  RootState,
  TimelineViewId,
  [{ id: TimelineViewId }]
> = (_state, ownProps) => ownProps.id;

const getTimelineViews = createSelector(
  getTimelines,
  (state) => state.timelineViews
);

export const getTimelineViewById = createSelector(
  getTimelineViews,
  getOwnTimelineViewId,
  (timelineViews, id) => timelineViews[id]
);

export const makeGetTimelineVisibleRange = () =>
  createSelector(getTimelineViewById, (state) => state?.visibleTimeRange);
