import moment from 'moment';
import styled from 'styled-components';

export const TimeText = ({ values: { startTime, endTime } }) => {
  const startMinutes = startTime.substring(3, 5);
  const endMinutes = endTime.substring(3, 5);

  /**
   * it should omit timezone info from start and end times
   * since it is not correct
   *
   * current response example
   * {
   *  "startTime": "06:00 EST",
   *  "endTime": "08:00 EST"
   * }
   */
  const formattedStartTime = moment(startTime.substring(0, 5), 'hh:mm').format(
    startMinutes === '00' ? 'ha' : 'h:mma'
  );

  const formattedEndTime = moment(endTime.substring(0, 5), 'hh:mm').format(
    endMinutes === '00' ? 'ha' : 'h:mma'
  );

  return (
    <Bold>
      &nbsp;| {formattedStartTime} - {formattedEndTime}
    </Bold>
  );
};

const Bold = styled.span`
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;
