import { createRequestTypes } from 'appUtils';

export const CREATE_CUSTOM_FIELD = createRequestTypes('CREATE_CUSTOM_FIELD');
export const FETCH_CUSTOM_FIELD = createRequestTypes('FETCH_CUSTOM_FIELD');
export const UPDATE_CUSTOM_FIELD = createRequestTypes('UPDATE_CUSTOM_FIELD');
export const ARCHIVE_CUSTOM_FIELD = createRequestTypes('ARCHIVE_CUSTOM_FIELD');
export const UNARCHIVE_CUSTOM_FIELD = createRequestTypes(
  'UNARCHIVE_CUSTOM_FIELD'
);
export const FETCH_CUSTOM_FIELD_ALL_VALUES = createRequestTypes(
  'FETCH_CUSTOM_FIELD_ALL_VALUES'
);
