import * as constants from './index.js';
import * as integrationConstants from 'IntegrationsModule/constants';
import * as qbConstants from 'QuickbooksModule/constants';
import * as officeConstants from 'SettingsModule/constants/office';
import keyBy from 'lodash/keyBy';

export const makeErrorString = (actionType) =>
  `Error - actionType ${actionType}`;
const makeErrorStrings = (errorStrings) => errorStrings.map(makeErrorString);

export const CANCELLED_REQUEST_ERROR = 'Request cancelled';

export const IGNORED_ERROR_RESPONSES = {
  "Title can't be blank": true,
  [CANCELLED_REQUEST_ERROR]: true
};

const DEFUALT_IGNORED_ERROR_ACTIONS_ARRAY = [
  constants.FETCH_TIMESHEETS_PREDICTIONS.TRIGGER,
  constants.DELETE_BOARD_PREFERENCE.TRIGGER,
  qbConstants.FETCH_SYNC_SETTINGS.TRIGGER,
  integrationConstants.FETCH_INTEGRATION.TRIGGER,
  integrationConstants.FETCH_INTEGRATIONS.TRIGGER,
  officeConstants.CREATE_OFFICE.TRIGGER,
  officeConstants.UPDATE_OFFICE_METADATA.TRIGGER,
  officeConstants.UPDATE_OFFICE_LOCATION.TRIGGER
];

// set up different ignore constraints per env
const IGNORED_ERROR_ACTIONS_BY_DOMAIN = {
  staging: DEFUALT_IGNORED_ERROR_ACTIONS_ARRAY,
  demo: DEFUALT_IGNORED_ERROR_ACTIONS_ARRAY
};

// pick correct env
export const IGNORED_ERROR_ACTIONS_ARRAY =
  IGNORED_ERROR_ACTIONS_BY_DOMAIN[process.env.UI_ENV] ||
  DEFUALT_IGNORED_ERROR_ACTIONS_ARRAY;

// build messages to ignore based on env
export const IGNORED_ERROR_ACTIONS_MESSAGES = [
  'Existing connection must be closed before opening', // action-cable-react-jwt uses an older version of action cable, which still throws on this message even when a successful reconnection occurs.
  'Object Not Found Matching Id', // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/35 - error that pings sentry recursively, has no known root cause, and is not from within our application.
  ...makeErrorStrings(IGNORED_ERROR_ACTIONS_ARRAY)
];

// build actions to ignore in 'captureError' based on env
export const IGNORED_ERROR_ACTIONS = keyBy(IGNORED_ERROR_ACTIONS_ARRAY);
