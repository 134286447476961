import React from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
  getAllTeamMembers,
  getSelectedTeamMember,
  getMe,
  getIsOnOwnTimesheets,
  getNavigationHistory,
  getMatchedRouteParams,
  isOnTeamMembersTimesheet
} from 'selectors';
import {
  setSelectedTeamMember,
  setTaskAccountFilter,
  setProjectPlannerMember,
  fetchMemberProjects
} from 'actionCreators';
import { MemberSelector } from '..';
import { Page, Header, MemberName } from './styles';
import MemberCircle from 'components/MemberCircle';
import DropdownArrow from 'components/DropdownArrow';

class TeamMemberProfile extends React.Component {
  componentDidUpdate(prevProps) {
    const { matchedParams, teamMembers } = this.props;
    const { teamMemberId } = matchedParams;
    if (
      !teamMemberId ||
      (+teamMemberId === +prevProps.matchedParams.teamMemberId &&
        (teamMembers && teamMembers.length) ===
          (prevProps.teamMembers && prevProps.teamMembers.length))
    ) {
      return;
    }
    const member = this.getMember();
    this.updateData(member);
  }

  onSelectMember = (member) => {
    const { history, matchedParams } = this.props;
    const { teamSlug, membersViewType } = matchedParams;
    history.push(`/${teamSlug}/members/${membersViewType}/${member.id}/views`);
  };

  getMember = () => {
    const { matchedParams, teamMembers } = this.props;
    const { teamMemberId } = matchedParams;
    const member =
      teamMembers && teamMembers.find((member) => member.id === +teamMemberId);
    return member;
  };

  updateData = (member) => {
    const {
      fetchMemberProjects,
      setSelectedTeamMember,
      setTaskAccountFilter,
      setProjectPlannerMember
    } = this.props;
    if (!member) {
      return;
    }
    setSelectedTeamMember({
      selectedMemberId: member.id,
      selectedAccountId: member.account.id
    });
    setTaskAccountFilter({ selectedAccountIds: [member.account.id] });
    setProjectPlannerMember({ memberId: member.account.id });
    fetchMemberProjects({ account_id: member.account.id });
  };

  render() {
    const { selectedTeamMember, teamMembers, me } = this.props;
    const isLoggedInUser =
      selectedTeamMember && me && selectedTeamMember.account.id === me.id;
    const currentView = this.props.isOnTeamMembersTimesheet
      ? 'timesheet'
      : 'tasks';

    return (
      <Page className="team-member-profile" currentView={currentView}>
        {selectedTeamMember && (
          <>
            <Header style={{ paddingLeft: '0' }}>
              <MemberSelector
                pinIdToTop={me && me.team_membership_id}
                selectedMember={selectedTeamMember}
                availableMembers={teamMembers}
                onSelect={this.onSelectMember}
              >
                <MemberCircle
                  size="40px"
                  fontSize="14px"
                  isLoggedInUser={isLoggedInUser}
                >
                  {selectedTeamMember.account.initials}
                </MemberCircle>
                <MemberName>{selectedTeamMember.account.name}</MemberName>
                <DropdownArrow />
              </MemberSelector>
            </Header>
            {this.props.children}
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  me: getMe(state),
  selectedTeamMember: getSelectedTeamMember(state),
  teamMembers: getAllTeamMembers(state),
  isOnOwnTimesheets: getIsOnOwnTimesheets(state),
  navigationHistory: getNavigationHistory(state),
  matchedParams: getMatchedRouteParams(state),
  isOnTeamMembersTimesheet: isOnTeamMembersTimesheet(state)
});

const mapDispatchToProps = {
  setSelectedTeamMember,
  setTaskAccountFilter,
  setProjectPlannerMember,
  fetchMemberProjects
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamMemberProfile)
);
