import {
  ERROR_MESSAGES,
  TRAINING_DATA_REQUIRED_STRING
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, resetTempRef } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportProjectPriority
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingProjectPrioritiesByTitle: Record<string, CsvImportProjectPriority>;
  temporaryProjectPriorityOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectPriorityValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams, isLastRow }) => {
  const { existingProjectPrioritiesByTitle, temporaryProjectPriorityOption } =
    additionalParams;

  const { enteredProjectTitle, enteredProjectNumber, enteredProjectPriority } =
    getRowValuesForEntityType(row, entityType);

  let res: string | boolean = true;

  if (!enteredProjectTitle || !enteredProjectNumber) {
    if (!enteredProjectTitle) res = 'Project title is required';
    else res = 'Project number is required';
    resetTempRef(temporaryProjectPriorityOption, isLastRow);
    return res;
  }

  if (enteredProjectPriority === TRAINING_DATA_REQUIRED_STRING) {
    res = false;
  } else if (
    enteredProjectPriority &&
    !existingProjectPrioritiesByTitle[enteredProjectPriority]
    // entered project priority can only be one of the option in the existing project priority hash
  ) {
    res = ERROR_MESSAGES.invalidProjectPriority;
  } else {
    const matchedProjectPriorityInTempHash =
      temporaryProjectPriorityOption.current[enteredProjectTitle]?.[
        enteredProjectNumber
      ];
    // if there were no option stored for this project title + project number combination, then add this entered value as option to both hashes.
    if (matchedProjectPriorityInTempHash === undefined) {
      temporaryProjectPriorityOption.current = {
        ...temporaryProjectPriorityOption.current,
        [enteredProjectTitle]: {
          ...temporaryProjectPriorityOption.current[enteredProjectTitle],
          [enteredProjectNumber]: enteredProjectPriority
        }
      };
      // if there was an option and it does not match to the entered value, return an error message.
    } else if (matchedProjectPriorityInTempHash !== enteredProjectPriority) {
      res = ERROR_MESSAGES.projectPriorityDoesNotMatch;
    }
  }
  resetTempRef(temporaryProjectPriorityOption, isLastRow);
  return res;
};
