import cn from 'classnames';
import Popover from 'components/Popover';
import { MouseEvent, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import { MENU_SIZE } from 'views/projectPlanner/WorkPlanItemRenderers/constants';

interface ThreeDotMenuProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  /**
   * pass menu component to be rendered
   */
  children: ReactNode;
}

export const ThreeDotMenu = ({
  isOpen,
  onOpen,
  onClose,
  children
}: ThreeDotMenuProps) => {
  const MenuRef = useRef<HTMLDivElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onOpen();
  };

  return (
    <Menu
      className={cn({ open: isOpen }, 'three-dot-menu')}
      onClick={handleOpenMenu}
      ref={MenuRef}
    >
      <ProjectThreeDotMenu
        initialColor={theme.colors.colorRoyalBlue}
        onCloseCallback={onClose}
        threeDotClassName="project-three-dot-icon"
      />
      <Popover
        target={MenuRef.current}
        isOpen={isOpen}
        closePopover={onClose}
        hideArrow
        className="schedule-bar-popover"
      >
        {children}
      </Popover>
    </Menu>
  );
};

const Menu = styled.div`
  border-radius: 50%;
  display: none;

  // Compensate for the zero-height three-dot icon so that the menu is
  // displayed below the three-dot icon.
  align-items: center;
  align-self: stretch;

  &.open {
    display: inline-flex;

    .project-three-dot-icon {
      background-color: ${({ theme }) => theme.colors.colorPaleGray2};
    }
  }

  // Prevent the three-dot icon from affecting the vertical layout.
  .three-dot-menu-wrapper {
    height: 0;
  }

  // Make the background of the three-dot icon a little smaller than typical so
  // that it fits into the bar more cleanly.
  .project-three-dot-icon {
    border-radius: ${(MENU_SIZE - 12) / 2}px;
    height: ${MENU_SIZE - 12}px;
    width: ${MENU_SIZE}px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.colorPaleGray2};
    }
  }
`;
