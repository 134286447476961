import styled from 'styled-components';
import theme from 'theme';
import FilterIcon from 'icons/WideFilterIcon';
import { FilterListTypeDropdown } from 'FilterModule/components/FilterListTypeDropdown';
import { FilterField } from 'FilterModule/constants';
import CircleSpinner from 'components/Spinners/CircleSpinner';

interface SideFilterHeaderProps {
  width: number;
  isStackedFiltersOpen: boolean;
  toggleIsStackedFiltersOpen: () => void;
  numStackedFiltersUsed: number;
  hasStackedFilters: boolean;
  hasChanges: boolean;
  entityLabel: string;
  handleCancel: () => void;
  handleDone: () => void;
  filterListTypeKey: FilterField;
  isSaving: boolean;
}

export const SideFilterHeader = ({
  width,
  isStackedFiltersOpen,
  toggleIsStackedFiltersOpen,
  numStackedFiltersUsed,
  hasStackedFilters,
  handleCancel,
  handleDone,
  hasChanges,
  entityLabel,
  filterListTypeKey,
  isSaving
}: SideFilterHeaderProps) => {
  return (
    <>
      {/* Header row */}
      <StyledHeaderRow $width={width}>
        <StyledTitle isHidden={isStackedFiltersOpen}>{entityLabel}</StyledTitle>
        <StyledConfirmButtonRow>
          <StyledCancelButton onClick={handleCancel}>
            Cancel{' '}
          </StyledCancelButton>
          <StyledSaveButton onClick={handleDone}>
            {isSaving ? (
              <CircleSpinner color="white" fadeIn="none" />
            ) : hasChanges ? (
              'Save'
            ) : (
              'Done'
            )}
          </StyledSaveButton>
        </StyledConfirmButtonRow>
      </StyledHeaderRow>

      <StyledOptionsRow>
        {/* Button for opening cross filters */}
        {hasStackedFilters && !isStackedFiltersOpen && (
          <FilterTrigger onClick={toggleIsStackedFiltersOpen}>
            <FilterIcon
              height={16}
              color={theme.colors.colorCalendarBlue}
              className="filter-icon"
            />
            <FilterText>
              {numStackedFiltersUsed || ''} Filter
              {numStackedFiltersUsed > 1 ? 's' : ''}
            </FilterText>
          </FilterTrigger>
        )}

        <FilterListTypeDropdown filterListTypeKey={filterListTypeKey} />
      </StyledOptionsRow>
    </>
  );
};

/* ------------------------------------ - ----------------------------------- */

const FilterText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  margin-left: 4px;
  font-weight: 600;
`;

const FilterTrigger = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-left: 2px;
  margin-right: 10px;
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(80%);
    }
    `}
`;

const StyledOptionsRow = styled.div`
  display: flex;
  padding-left: 15px;
`;

const StyledConfirmButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.div<{ isHidden: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: black;
  ${(props) => props.isHidden && 'visibility: hidden;'}
`;

const StyledHeaderRow = styled.div<{ $width: number }>`
  align-self: flex-end;
  padding: 15px;
  padding-right: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ $width }) => $width}px;
`;

const StyledSaveButton = styled.button`
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: none;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  padding: 5px 6px;
  font-size: 14px;
  border-radius: 3px;

  &:hover {
    filter: brightness(94%);
  }
`;

const StyledCancelButton = styled(StyledSaveButton)`
  background: none;
  color: ${theme.colors.colorMediumGray5};
  margin-right: 5px;
  font-weight: normal;
  &:hover {
    background: none;
    color: ${theme.colors.colorSemiDarkGray1};
  }
`;
