import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  createWorkGroup,
  createWorkGroupMembership,
  navigateToTeamSettings
} from 'actionCreators';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import {
  StyledAddPlusIcon,
  StyledAddPlusContainer
} from 'ReportsModule/components/styles';
import { StyledAddMember } from './styles';
import styled from 'styled-components';
import {
  useUserPermissions,
  useCheckPermission
} from 'hocs/withPermissionsCheck';
import { getSelectedTeamId, getActiveTeam } from 'selectors';
import { ThreeDotMenu, ThreeDotMenuContainer } from 'components/ThreeDotMenu';
import { StyledDropdownItem } from 'BudgetModule/components/BudgetTable/styles';
import theme from 'theme';

const AddPlusContainer = styled(StyledAddPlusContainer)`
  display: flex;
  margin: 0 10px;
  position: static;
`;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  cursor: default;

  &:hover {
    ${ThreeDotMenuContainer} {
      width: 28px;
      margin-right: 0;
      visibility: visible;
      opacity: 1;
    }
  }
`;

const WorkGroupHeader = ({ workGroup }) => {
  const dispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(null);
  const userPermissions = useUserPermissions();
  const checkPermission = useCheckPermission();
  const teamId = useSelector(getSelectedTeamId);
  const team = useSelector(getActiveTeam);

  useEffect(() => {
    const permission = dispatch(
      checkPermission(createWorkGroup, { permissions: { teamId } })
    );
    if (permission !== hasPermission) {
      setHasPermission(permission);
    }
  }, [checkPermission, dispatch, hasPermission, teamId, userPermissions]);

  const memberFilter = useCallback(
    (members) =>
      members.filter(
        (member) => !workGroup.workGroupMembersHash[member.account.id]
      ),
    [workGroup.workGroupMembersHash]
  );
  const handleAdd = (members) => {
    batch(() =>
      members.map((member) => {
        dispatch(
          createWorkGroupMembership({
            accountId: member.account.id,
            workGroupId: workGroup.id
          })
        );
      })
    );
  };

  const handleNavigateToAdminPanel = () => {
    if (hasPermission) {
      dispatch(
        navigateToTeamSettings({
          teamSlug: team && team?.slug,
          viewType: 'members',
          tab: 'departments'
        })
      );
    }
  };

  const renderMenu = () => {
    return (
      <>
        <StyledDropdownItem onClick={handleNavigateToAdminPanel}>
          Edit in Team Settings
        </StyledDropdownItem>
      </>
    );
  };

  return (
    <HeaderContainer>
      <ThreeDotMenu color={theme.colors.colorRoyalBlue}>
        {renderMenu()}
      </ThreeDotMenu>
      {workGroup.name}
      <BulkMemberSelector
        handleDone={handleAdd}
        memberFilter={memberFilter}
        shouldUseMemberFilter
        renderSelect={({ onClick }) => (
          <StyledAddMember
            isDisabled={!hasPermission}
            onClick={(e) => {
              if (hasPermission) {
                onClick(e);
              }
            }}
            data-for="app-tooltip"
            data-class="center"
            data-tip="Only Admins can <br/> manage Departments"
            data-html
          >
            <AddPlusContainer>
              <StyledAddPlusIcon />
            </AddPlusContainer>
          </StyledAddMember>
        )}
      />
    </HeaderContainer>
  );
};
export default WorkGroupHeader;
