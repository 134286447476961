import styled from 'styled-components';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import theme from 'theme';

const Container = styled.div`
  padding-left: 4rem;
  padding-top: 2rem;
`;

const Row = styled.div`
  &.first-row {
    display: flex;
  }
  &.second-row {
    margin-top: -0.8rem;
  }
`;

const Col = styled.div`
  padding: 7px 0px 0px 70px;
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};

  &.hour {
    position: relative;
    right: 28px;
  }
`;

const SkeletonLoaderTableBody = ({ showLoaderColumns = true }) => {
  return (
    <Container>
      <Row className="first-row">
        {' '}
        <SkeletonLoader
          numLoaders={1}
          style={{
            margin: '0 0 -11px 1rem',
            width: '230px'
          }}
          loaderStyle={{ height: 50, rx: 0 }}
        />
        {showLoaderColumns && (
          <>
            <Col>DATES REQUIRED</Col>
            <Col className="hour">BUDGET HRS</Col>
          </>
        )}
      </Row>
      <Row className="second-row">
        {' '}
        <SkeletonLoader
          numLoaders={2}
          style={{
            margin: '0 0 -11px 1rem',
            width: '90%'
          }}
          loaderStyle={{ height: 95, rx: 0 }}
        />
      </Row>
    </Container>
  );
};

export default SkeletonLoaderTableBody;
