import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { TeamMembersContainer } from 'views';
import MembersNavigationTracker from 'views/Navigation/MembersNavigationTracker';
import withRouterParams from './WithRouterParams';
import ComingSoonModal from 'views/teamMembers/ComingSoonModal';
import MembersViewFactoryRouter from './MembersViewFactoryRouter';

class MembersRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    const { match } = this.props;
    return (
      <TeamMembersContainer>
        <MembersNavigationTracker />
        <Switch>
          <Route
            path={`${match.url}/timesheet/:teamMemberId?/views`}
            render={() => null}
          />
          <Route
            path={`${match.url}/:membersViewType/:teamMemberId?/views`}
            component={MembersViewFactoryRouter}
          />
        </Switch>
        <Route
          path={`${match.url}/timesheet/:teamMemberId?/views`}
          component={ComingSoonModal}
        />
      </TeamMembersContainer>
    );
  }
}

export default withRouterParams(withRouter(MembersRouter));
