import React from 'react';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

import {
  getOrderedMilestoneTemplates,
  getOrderedPhaseTemplates,
  getPhaseTemplateDropdownOpen,
  getLowerCaseExistingPhaseTemplates,
  getSelectedTeamId,
  getPhaseTemplateModalOpen,
  getLowerCaseExistingMilestoneTemplates
} from 'selectors';

import {
  reorderMilestoneTemplates,
  reorderPhaseTemplates,
  openPhaseTemplateModal,
  closePhaseTemplateDropdown
} from 'actionCreators';
import { Modal } from 'reactstrap';
import { StyledRoleContainer } from '../BudgetModal/styles';

import { filterItemWithWhiteSpace } from 'appUtils/search';

const phaseCopy = {
  headerInitial: 'Add/Edit Standard Phases',
  headerEdit: 'Add/Edit Standard Phases',
  headerAdd: 'New Phase',
  sticky: 'Create New Standard Phase',
  footerInitial: 'Manage Standard Phases',
  footerEdit: 'Done',
  addConfirm: 'Add',
  editConfirm: 'Save',
  searchPlaceholder: 'Type phase or select below'
};

const milestoneCopy = {
  headerInitial: 'Add/Edit Standard Milestones',
  headerEdit: 'Add/Edit Standard Milestones',
  headerAdd: 'New Milestone',
  sticky: 'Create New Standard Milestone',
  footerInitial: 'Manage Standard Milestones',
  footerEdit: 'Done',
  addConfirm: 'Add',
  editConfirm: 'Save',
  searchPlaceholder: 'Type milestone or select below'
};

class GlobalPhaseTemplatesDropdown extends React.Component {
  renderItem = ({ item, selectCallback }) => (
    <StyledRoleContainer>{item?.name}</StyledRoleContainer>
  );

  renderHeaderButton = () => <div />;
  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    const { openPhaseTemplateModal } = this.props;
    const { id } = item;
    openPhaseTemplateModal({ id });
  };

  handleClose = () => {
    const { closePhaseTemplateDropdown, phaseTemplateModalOpen } = this.props;
    if (!phaseTemplateModalOpen) {
      closePhaseTemplateDropdown();
    }
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });

  reorder = (newOrder) => {
    const {
      isMilestone,
      reorderMilestoneTemplates,
      reorderPhaseTemplates,
      teamId
    } = this.props;
    if (isMilestone) {
      reorderMilestoneTemplates({
        teamId,
        milestoneTemplateOrder: newOrder
      });
    } else {
      reorderPhaseTemplates({
        teamId,
        phaseTemplateOrder: newOrder
      });
    }
  };

  onFooterClick = () => {
    const { closePhaseTemplateDropdown } = this.props;
    closePhaseTemplateDropdown();
  };

  onStickyClick = () => {
    const { openPhaseTemplateModal } = this.props;
    openPhaseTemplateModal({});
  };

  onItemClick = (id) => this.props.openPhaseTemplateModal({ id });

  render() {
    const { isMilestone, milestoneTemplates, phaseTemplates, isOpen } =
      this.props;
    return (
      <Modal
        className="flyout-modal"
        isOpen={isOpen}
        toggle={this.onFooterClick}
        backdropClassName="global-flyout-modal"
      >
        <MultiStepFlyout
          copy={isMilestone ? milestoneCopy : phaseCopy}
          items={isMilestone ? milestoneTemplates : phaseTemplates}
          idKey="id"
          renderItem={this.renderItem}
          handleSelect={this.handleSelect}
          itemFilter={this.itemFilter}
          reorder={this.reorder}
          handleClose={this.handleClose}
          dragEnabled
          editDisabled
          onFooterClick={this.onFooterClick}
          onStickyClick={this.onStickyClick}
          setEditingCallback={this.onItemClick}
          editMode
          isGlobal
          stickyRow
          renderHeaderButton={this.renderHeaderButton}
          isInCentralModal
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  phaseTemplates: getOrderedPhaseTemplates(state),
  milestoneTemplates: getOrderedMilestoneTemplates(state),
  isOpen: getPhaseTemplateDropdownOpen(state),
  teamId: getSelectedTeamId(state),
  lowerCaseExistingPhaseTemplates: getLowerCaseExistingPhaseTemplates(state),
  lowerCaseExistingMilestoneTemplates:
    getLowerCaseExistingMilestoneTemplates(state),
  phaseTemplateModalOpen: getPhaseTemplateModalOpen(state)
});

const mapDispatchToProps = {
  reorderPhaseTemplates,
  reorderMilestoneTemplates,
  openPhaseTemplateModal,
  closePhaseTemplateDropdown
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalPhaseTemplatesDropdown);
