import { ACCESS_ROLES } from 'PermissionsModule/constants';
import { teamUtils } from 'TeamsModule/utils';

const {
  ADMIN,
  TEAM_MEMBER,
  GUEST,
  LOGIN,
  WORKLOAD_MANAGER,
  TIMESHEET_MANAGER,
  TEAM_BUDGET_MANAGER,
  WORKLOAD_MANAGER_VIEW_ONLY,
  FINANCIAL_MANAGER,
  FINANCIAL_MANAGER_VIEW_ONLY
} = ACCESS_ROLES;

const roleLevels = {
  [ADMIN]: 0,
  [FINANCIAL_MANAGER]: 1,
  [FINANCIAL_MANAGER_VIEW_ONLY]: 2,
  [TEAM_BUDGET_MANAGER]: 3,
  [TIMESHEET_MANAGER]: 4,
  [WORKLOAD_MANAGER]: 5,
  [WORKLOAD_MANAGER_VIEW_ONLY]: 6,
  [TEAM_MEMBER]: 7,
  [GUEST]: 8,
  [LOGIN]: 9
};

const levelsToRolesHash = {
  0: ADMIN,
  1: FINANCIAL_MANAGER,
  2: FINANCIAL_MANAGER_VIEW_ONLY,
  3: TEAM_BUDGET_MANAGER,
  4: TIMESHEET_MANAGER,
  5: WORKLOAD_MANAGER,
  6: WORKLOAD_MANAGER_VIEW_ONLY,
  7: TEAM_MEMBER,
  8: GUEST,
  9: LOGIN
};

export const roleIdToStringHash = Object.entries(ACCESS_ROLES).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {}
);
export const defaultRoleDisplay = {
  [ADMIN]: 'Admin',
  [FINANCIAL_MANAGER]: 'Budget Manager',
  [WORKLOAD_MANAGER]: 'Work Planner',
  [WORKLOAD_MANAGER_VIEW_ONLY]: 'Work Planner'
};

export const defaultNonEditableRoles = {
  [ADMIN]: 'Admin',
  [FINANCIAL_MANAGER]: 'Budget Manager'
};

export const calculateHighestTeamMembershipRoleLevel = (member) => {
  const highestRoleLevel = member?.role_ids?.reduce(
    (highestRoleLevel, roleId) => {
      const currentRoleLevel = roleLevels[roleId];
      return currentRoleLevel < highestRoleLevel
        ? currentRoleLevel
        : highestRoleLevel;
    },

    LOGIN
  );

  return highestRoleLevel;
};

export const calculateHighestTeamMembershipRoleId = (member) =>
  levelsToRolesHash[calculateHighestTeamMembershipRoleLevel(member)];

export const calculateHighestTeamMembershipRoleName = (member) =>
  roleIdToStringHash[calculateHighestTeamMembershipRoleId(member)];
