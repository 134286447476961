import styled from 'styled-components';
import noop from 'lodash/noop';

export interface TableRowProps {
  name?: string;
  columns: {
    colSpan?: number;
    rowSpan?: number;
    content: React.ReactNode;
    // column click actions will have stopPropagation as default
    onClickColumn?: (rowName: string | number, columnIndex: number) => void;
  }[];
  onClickRow?: (rowName: string | number) => void;
}

enum TableRowType {
  header = 'header',
  body = 'body',
  footer = 'footer'
}

// Threre is no difference between thead, tbody and tfoot.
// They are only there to make styling easier if different styling needed.
export const HTMLTable = ({
  headerRows,
  bodyRows,
  footerRows
}: {
  headerRows?: TableRowProps[];
  bodyRows: TableRowProps[];
  footerRows?: TableRowProps[];
}) => {
  return (
    <StyledHTMLTable className="html-table">
      {headerRows && (
        <thead className="html-table-header">
          <TableRows rows={headerRows} type={TableRowType.header} />
        </thead>
      )}
      <tbody className="html-table-body">
        <TableRows rows={bodyRows} type={TableRowType.body} />
      </tbody>
      {footerRows && (
        <tfoot className="html-table-footer">
          <TableRows rows={footerRows} type={TableRowType.footer} />
        </tfoot>
      )}
    </StyledHTMLTable>
  );
};

const TableRows = ({
  rows,
  type
}: {
  rows: TableRowProps[];
  type: TableRowType;
}) => (
  <>
    {rows.map((row, rowIndex) => {
      const { columns, name, onClickRow } = row;
      const rowName = name || rowIndex;
      const className = `html-table-${type}-row-${rowName}`;

      return (
        <tr
          className={className}
          key={className}
          onClick={onClickRow ? () => onClickRow(rowName) : noop}
        >
          {columns.map((column, colIndex) => {
            const { colSpan = 1, rowSpan = 1, content, onClickColumn } = column;
            const className = `html-table-${type}-cell-${rowName}-${colIndex}`;
            return (
              <td
                key={className}
                className={className}
                colSpan={colSpan}
                rowSpan={rowSpan}
                onClick={
                  onClickColumn
                    ? (e) => {
                        e.stopPropagation();
                        onClickColumn(rowName, colIndex);
                      }
                    : noop
                }
              >
                {content}
              </td>
            );
          })}
        </tr>
      );
    })}
  </>
);

const StyledHTMLTable = styled.table`
  margin: 0 13px;
  border-collapse: separate;
  border-spacing: 3px 3px;
`;
