import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import theme from 'theme';
import { getSelectedProject, getTeamMembershipsByAccountId } from 'selectors';
import { createActivityMembers, createPhaseMembers } from 'actionCreators';
import { makeGetUnformattedBudgetTotals } from 'BudgetModule/selectors';
import { useMergeBudgetModal } from 'BudgetModule/hooks/useMergeBudgetModal';

const StyledAddButton = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  margin: auto;
  font-weight: 600;
  cursor: pointer;
  margin-top: -1px;
  margin-left: -22px;
  font-size: 17px;
  left: 35px;
  top: 11px;
  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorPureWhite};
  }
  position: relative;
`;

const AddContainer = styled.div`
  position: absolute;
  left: 4.5px;
`;

const MemberAddCell = ({ row }) => {
  const selectedProject = useSelector(getSelectedProject);
  const teamMembers = useSelector(getTeamMembershipsByAccountId);
  const getProjectTotal = useMemo(makeGetUnformattedBudgetTotals, []);
  const projectTotal = useSelector((state) =>
    getProjectTotal(state, {
      projectId: selectedProject.id
    })
  );

  const { handleOpenMergeBudgetModal, MergeBudgetModal } =
    useMergeBudgetModal();

  const addMember = () => {
    const { position, member } = row.original;
    if (member && position.memberBudget?.id) {
      const account = teamMembers[member.id]?.account;
      if (account) {
        const accountTotal = projectTotal?.account_totals?.find(
          (accountTotal) =>
            accountTotal.member_budget_id === position.memberBudget.id
        );
        const totalEstimatedHours = accountTotal?.estimated?.hours || 0;
        const totalPlannedHours = accountTotal?.planned?.hours || 0;

        handleOpenMergeBudgetModal({
          assignedAccountId: account.id,
          assignedName: account.name,
          unassignedMemberBudgetId: position.memberBudget.id,
          projectId: selectedProject.id,
          totalEstimatedHours,
          totalPlannedHours
        });
      }
    }
  };

  return (
    <StyledAddButton
      data-for="app-tooltip"
      data-tip="Add to project"
      data-html
      data-class="center"
      data-delay-show="500"
      onClick={addMember}
    >
      <AddContainer> + </AddContainer>
      <MergeBudgetModal />
    </StyledAddButton>
  );
};

export default MemberAddCell;
