import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportRegion
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingRegionsByName: Record<string, CsvImportRegion>;
  newRegions: React.MutableRefObject<Record<string, boolean>>;
}

export const regionValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingRegionsByName, newRegions } = additionalParams;

  const { enteredRegion } = getRowValuesForEntityType(row, entityType);

  const existingRegionMatchedByEnteredName =
    existingRegionsByName[enteredRegion];

  const enteredRegionNameIsNewOption =
    enteredRegion &&
    !existingRegionMatchedByEnteredName &&
    !newRegions.current[enteredRegion];

  if (enteredRegion === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (
    // if entered region name is a new name, add to new region hash
    enteredRegionNameIsNewOption
  ) {
    newRegions.current[enteredRegion] = true;
    return true;
  } else return true;
};
