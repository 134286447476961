export const CURRENCY_LIST_DETAILED = [
  {
    currencyEntity: 'AFGHANISTAN',
    currencyName: 'Afghani',
    currencyCode: 'AFN',
    numericCode: 971,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ÅLAND ISLANDS',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ALBANIA',
    currencyName: 'Lek',
    currencyCode: 'ALL',
    numericCode: 8,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ALGERIA',
    currencyName: 'Algerian Dinar',
    currencyCode: 'DZD',
    numericCode: 12,
    minorUnit: '2'
  },
  {
    currencyEntity: 'AMERICAN SAMOA',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ANDORRA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ANGOLA',
    currencyName: 'Kwanza',
    currencyCode: 'AOA',
    numericCode: 973,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ANGUILLA',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ANTIGUA AND BARBUDA',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ARGENTINA',
    currencyName: 'Argentine Peso',
    currencyCode: 'ARS',
    numericCode: 32,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ARMENIA',
    currencyName: 'Armenian Dram',
    currencyCode: 'AMD',
    numericCode: 51,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ARUBA',
    currencyName: 'Aruban Florin',
    currencyCode: 'AWG',
    numericCode: 533,
    minorUnit: '2'
  },
  {
    currencyEntity: 'AUSTRALIA',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'AUSTRIA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'AZERBAIJAN',
    currencyName: 'Azerbaijan Manat',
    currencyCode: 'AZN',
    numericCode: 944,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BAHAMAS (THE)',
    currencyName: 'Bahamian Dollar',
    currencyCode: 'BSD',
    numericCode: 44,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BAHRAIN',
    currencyName: 'Bahraini Dinar',
    currencyCode: 'BHD',
    numericCode: 48,
    minorUnit: '3'
  },
  {
    currencyEntity: 'BANGLADESH',
    currencyName: 'Taka',
    currencyCode: 'BDT',
    numericCode: 50,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BARBADOS',
    currencyName: 'Barbados Dollar',
    currencyCode: 'BBD',
    numericCode: 52,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BELARUS',
    currencyName: 'Belarusian Ruble',
    currencyCode: 'BYN',
    numericCode: 933,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BELGIUM',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BELIZE',
    currencyName: 'Belize Dollar',
    currencyCode: 'BZD',
    numericCode: 84,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BENIN',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'BERMUDA',
    currencyName: 'Bermudian Dollar',
    currencyCode: 'BMD',
    numericCode: 60,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BHUTAN',
    currencyName: 'Indian Rupee',
    currencyCode: 'INR',
    numericCode: 356,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BHUTAN',
    currencyName: 'Ngultrum',
    currencyCode: 'BTN',
    numericCode: 64,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BOLIVIA (PLURINATIONAL STATE OF)',
    currencyName: 'Boliviano',
    currencyCode: 'BOB',
    numericCode: 68,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BOLIVIA (PLURINATIONAL STATE OF)',
    currencyName: 'Mvdol',
    currencyCode: 'BOV',
    numericCode: 984,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BONAIRE, SINT EUSTATIUS AND SABA',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BOSNIA AND HERZEGOVINA',
    currencyName: 'Convertible Mark',
    currencyCode: 'BAM',
    numericCode: 977,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BOTSWANA',
    currencyName: 'Pula',
    currencyCode: 'BWP',
    numericCode: 72,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BOUVET ISLAND',
    currencyName: 'Norwegian Krone',
    currencyCode: 'NOK',
    numericCode: 578,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BRAZIL',
    currencyName: 'Brazilian Real',
    currencyCode: 'BRL',
    numericCode: 986,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BRITISH INDIAN OCEAN TERRITORY (THE)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BRUNEI DARUSSALAM',
    currencyName: 'Brunei Dollar',
    currencyCode: 'BND',
    numericCode: 96,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BULGARIA',
    currencyName: 'Bulgarian Lev',
    currencyCode: 'BGN',
    numericCode: 975,
    minorUnit: '2'
  },
  {
    currencyEntity: 'BURKINA FASO',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'BURUNDI',
    currencyName: 'Burundi Franc',
    currencyCode: 'BIF',
    numericCode: 108,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CABO VERDE',
    currencyName: 'Cabo Verde Escudo',
    currencyCode: 'CVE',
    numericCode: 132,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CAMBODIA',
    currencyName: 'Riel',
    currencyCode: 'KHR',
    numericCode: 116,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CAMEROON',
    currencyName: 'CFA Franc BEAC',
    currencyCode: 'XAF',
    numericCode: 950,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CANADA',
    currencyName: 'Canadian Dollar',
    currencyCode: 'CAD',
    numericCode: 124,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CAYMAN ISLANDS (THE)',
    currencyName: 'Cayman Islands Dollar',
    currencyCode: 'KYD',
    numericCode: 136,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CENTRAL AFRICAN REPUBLIC (THE)',
    currencyName: 'CFA Franc BEAC',
    currencyCode: 'XAF',
    numericCode: 950,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CHAD',
    currencyName: 'CFA Franc BEAC',
    currencyCode: 'XAF',
    numericCode: 950,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CHILE',
    currencyName: 'Chilean Peso',
    currencyCode: 'CLP',
    numericCode: 152,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CHILE',
    currencyName: 'Unidad de Fomento',
    currencyCode: 'CLF',
    numericCode: 990,
    minorUnit: '4'
  },
  {
    currencyEntity: 'CHINA',
    currencyName: 'Yuan Renminbi',
    currencyCode: 'CNY',
    numericCode: 156,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CHRISTMAS ISLAND',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'COCOS (KEELING) ISLANDS (THE)',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'COLOMBIA',
    currencyName: 'Colombian Peso',
    currencyCode: 'COP',
    numericCode: 170,
    minorUnit: '2'
  },
  {
    currencyEntity: 'COLOMBIA',
    currencyName: 'Unidad de Valor Real',
    currencyCode: 'COU',
    numericCode: 970,
    minorUnit: '2'
  },
  {
    currencyEntity: 'COMOROS (THE)',
    currencyName: 'Comorian Franc ',
    currencyCode: 'KMF',
    numericCode: 174,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CONGO (THE DEMOCRATIC REPUBLIC OF THE)',
    currencyName: 'Congolese Franc',
    currencyCode: 'CDF',
    numericCode: 976,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CONGO (THE)',
    currencyName: 'CFA Franc BEAC',
    currencyCode: 'XAF',
    numericCode: 950,
    minorUnit: '0'
  },
  {
    currencyEntity: 'COOK ISLANDS (THE)',
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    numericCode: 554,
    minorUnit: '2'
  },
  {
    currencyEntity: 'COSTA RICA',
    currencyName: 'Costa Rican Colon',
    currencyCode: 'CRC',
    numericCode: 188,
    minorUnit: '2'
  },
  {
    currencyEntity: "CÔTE D'IVOIRE",
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'CROATIA',
    currencyName: 'Kuna',
    currencyCode: 'HRK',
    numericCode: 191,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CUBA',
    currencyName: 'Cuban Peso',
    currencyCode: 'CUP',
    numericCode: 192,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CUBA',
    currencyName: 'Peso Convertible',
    currencyCode: 'CUC',
    numericCode: 931,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CURAÇAO',
    currencyName: 'Netherlands Antillean Guilder',
    currencyCode: 'ANG',
    numericCode: 532,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CYPRUS',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'CZECHIA',
    currencyName: 'Czech Koruna',
    currencyCode: 'CZK',
    numericCode: 203,
    minorUnit: '2'
  },
  {
    currencyEntity: 'DENMARK',
    currencyName: 'Danish Krone',
    currencyCode: 'DKK',
    numericCode: 208,
    minorUnit: '2'
  },
  {
    currencyEntity: 'DJIBOUTI',
    currencyName: 'Djibouti Franc',
    currencyCode: 'DJF',
    numericCode: 262,
    minorUnit: '0'
  },
  {
    currencyEntity: 'DOMINICA',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'DOMINICAN REPUBLIC (THE)',
    currencyName: 'Dominican Peso',
    currencyCode: 'DOP',
    numericCode: 214,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ECUADOR',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'EGYPT',
    currencyName: 'Egyptian Pound',
    currencyCode: 'EGP',
    numericCode: 818,
    minorUnit: '2'
  },
  {
    currencyEntity: 'EL SALVADOR',
    currencyName: 'El Salvador Colon',
    currencyCode: 'SVC',
    numericCode: 222,
    minorUnit: '2'
  },
  {
    currencyEntity: 'EL SALVADOR',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'EQUATORIAL GUINEA',
    currencyName: 'CFA Franc BEAC',
    currencyCode: 'XAF',
    numericCode: 950,
    minorUnit: '0'
  },
  {
    currencyEntity: 'ERITREA',
    currencyName: 'Nakfa',
    currencyCode: 'ERN',
    numericCode: 232,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ESTONIA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ESWATINI',
    currencyName: 'Lilangeni',
    currencyCode: 'SZL',
    numericCode: 748,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ETHIOPIA',
    currencyName: 'Ethiopian Birr',
    currencyCode: 'ETB',
    numericCode: 230,
    minorUnit: '2'
  },
  {
    currencyEntity: 'EUROPEAN UNION',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FALKLAND ISLANDS (THE) [MALVINAS]',
    currencyName: 'Falkland Islands Pound',
    currencyCode: 'FKP',
    numericCode: 238,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FAROE ISLANDS (THE)',
    currencyName: 'Danish Krone',
    currencyCode: 'DKK',
    numericCode: 208,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FIJI',
    currencyName: 'Fiji Dollar',
    currencyCode: 'FJD',
    numericCode: 242,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FINLAND',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FRANCE',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FRENCH GUIANA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'FRENCH POLYNESIA',
    currencyName: 'CFP Franc',
    currencyCode: 'XPF',
    numericCode: 953,
    minorUnit: '0'
  },
  {
    currencyEntity: 'FRENCH SOUTHERN TERRITORIES (THE)',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GABON',
    currencyName: 'CFA Franc BEAC',
    currencyCode: 'XAF',
    numericCode: 950,
    minorUnit: '0'
  },
  {
    currencyEntity: 'GAMBIA (THE)',
    currencyName: 'Dalasi',
    currencyCode: 'GMD',
    numericCode: 270,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GEORGIA',
    currencyName: 'Lari',
    currencyCode: 'GEL',
    numericCode: 981,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GERMANY',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GHANA',
    currencyName: 'Ghana Cedi',
    currencyCode: 'GHS',
    numericCode: 936,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GIBRALTAR',
    currencyName: 'Gibraltar Pound',
    currencyCode: 'GIP',
    numericCode: 292,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GREECE',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GREENLAND',
    currencyName: 'Danish Krone',
    currencyCode: 'DKK',
    numericCode: 208,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GRENADA',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GUADELOUPE',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GUAM',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GUATEMALA',
    currencyName: 'Quetzal',
    currencyCode: 'GTQ',
    numericCode: 320,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GUERNSEY',
    currencyName: 'Pound Sterling',
    currencyCode: 'GBP',
    numericCode: 826,
    minorUnit: '2'
  },
  {
    currencyEntity: 'GUINEA',
    currencyName: 'Guinean Franc',
    currencyCode: 'GNF',
    numericCode: 324,
    minorUnit: '0'
  },
  {
    currencyEntity: 'GUINEA-BISSAU',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'GUYANA',
    currencyName: 'Guyana Dollar',
    currencyCode: 'GYD',
    numericCode: 328,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HAITI',
    currencyName: 'Gourde',
    currencyCode: 'HTG',
    numericCode: 332,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HAITI',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HEARD ISLAND AND McDONALD ISLANDS',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HOLY SEE (THE)',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HONDURAS',
    currencyName: 'Lempira',
    currencyCode: 'HNL',
    numericCode: 340,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HONG KONG',
    currencyName: 'Hong Kong Dollar',
    currencyCode: 'HKD',
    numericCode: 344,
    minorUnit: '2'
  },
  {
    currencyEntity: 'HUNGARY',
    currencyName: 'Forint',
    currencyCode: 'HUF',
    numericCode: 348,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ICELAND',
    currencyName: 'Iceland Krona',
    currencyCode: 'ISK',
    numericCode: 352,
    minorUnit: '0'
  },
  {
    currencyEntity: 'INDIA',
    currencyName: 'Indian Rupee',
    currencyCode: 'INR',
    numericCode: 356,
    minorUnit: '2'
  },
  {
    currencyEntity: 'INDONESIA',
    currencyName: 'Rupiah',
    currencyCode: 'IDR',
    numericCode: 360,
    minorUnit: '2'
  },
  {
    currencyEntity: 'INTERNATIONAL MONETARY FUND (IMF) ',
    currencyName: 'SDR (Special Drawing Right)',
    currencyCode: 'XDR',
    numericCode: 960,
    minorUnit: 'N.A.'
  },
  {
    currencyEntity: 'IRAN (ISLAMIC REPUBLIC OF)',
    currencyName: 'Iranian Rial',
    currencyCode: 'IRR',
    numericCode: 364,
    minorUnit: '2'
  },
  {
    currencyEntity: 'IRAQ',
    currencyName: 'Iraqi Dinar',
    currencyCode: 'IQD',
    numericCode: 368,
    minorUnit: '3'
  },
  {
    currencyEntity: 'IRELAND',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ISLE OF MAN',
    currencyName: 'Pound Sterling',
    currencyCode: 'GBP',
    numericCode: 826,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ISRAEL',
    currencyName: 'New Israeli Sheqel',
    currencyCode: 'ILS',
    numericCode: 376,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ITALY',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'JAMAICA',
    currencyName: 'Jamaican Dollar',
    currencyCode: 'JMD',
    numericCode: 388,
    minorUnit: '2'
  },
  {
    currencyEntity: 'JAPAN',
    currencyName: 'Yen',
    currencyCode: 'JPY',
    numericCode: 392,
    minorUnit: '0'
  },
  {
    currencyEntity: 'JERSEY',
    currencyName: 'Pound Sterling',
    currencyCode: 'GBP',
    numericCode: 826,
    minorUnit: '2'
  },
  {
    currencyEntity: 'JORDAN',
    currencyName: 'Jordanian Dinar',
    currencyCode: 'JOD',
    numericCode: 400,
    minorUnit: '3'
  },
  {
    currencyEntity: 'KAZAKHSTAN',
    currencyName: 'Tenge',
    currencyCode: 'KZT',
    numericCode: 398,
    minorUnit: '2'
  },
  {
    currencyEntity: 'KENYA',
    currencyName: 'Kenyan Shilling',
    currencyCode: 'KES',
    numericCode: 404,
    minorUnit: '2'
  },
  {
    currencyEntity: 'KIRIBATI',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)',
    currencyName: 'North Korean Won',
    currencyCode: 'KPW',
    numericCode: 408,
    minorUnit: '2'
  },
  {
    currencyEntity: 'KOREA (THE REPUBLIC OF)',
    currencyName: 'Won',
    currencyCode: 'KRW',
    numericCode: 410,
    minorUnit: '0'
  },
  {
    currencyEntity: 'KUWAIT',
    currencyName: 'Kuwaiti Dinar',
    currencyCode: 'KWD',
    numericCode: 414,
    minorUnit: '3'
  },
  {
    currencyEntity: 'KYRGYZSTAN',
    currencyName: 'Som',
    currencyCode: 'KGS',
    numericCode: 417,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)',
    currencyName: 'Lao Kip',
    currencyCode: 'LAK',
    numericCode: 418,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LATVIA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LEBANON',
    currencyName: 'Lebanese Pound',
    currencyCode: 'LBP',
    numericCode: 422,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LESOTHO',
    currencyName: 'Loti',
    currencyCode: 'LSL',
    numericCode: 426,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LESOTHO',
    currencyName: 'Rand',
    currencyCode: 'ZAR',
    numericCode: 710,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LIBERIA',
    currencyName: 'Liberian Dollar',
    currencyCode: 'LRD',
    numericCode: 430,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LIBYA',
    currencyName: 'Libyan Dinar',
    currencyCode: 'LYD',
    numericCode: 434,
    minorUnit: '3'
  },
  {
    currencyEntity: 'LIECHTENSTEIN',
    currencyName: 'Swiss Franc',
    currencyCode: 'CHF',
    numericCode: 756,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LITHUANIA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'LUXEMBOURG',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MACAO',
    currencyName: 'Pataca',
    currencyCode: 'MOP',
    numericCode: 446,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NORTH MACEDONIA',
    currencyName: 'Denar',
    currencyCode: 'MKD',
    numericCode: 807,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MADAGASCAR',
    currencyName: 'Malagasy Ariary',
    currencyCode: 'MGA',
    numericCode: 969,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MALAWI',
    currencyName: 'Malawi Kwacha',
    currencyCode: 'MWK',
    numericCode: 454,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MALAYSIA',
    currencyName: 'Malaysian Ringgit',
    currencyCode: 'MYR',
    numericCode: 458,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MALDIVES',
    currencyName: 'Rufiyaa',
    currencyCode: 'MVR',
    numericCode: 462,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MALI',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'MALTA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MARSHALL ISLANDS (THE)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MARTINIQUE',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MAURITANIA',
    currencyName: 'Ouguiya',
    currencyCode: 'MRU',
    numericCode: 929,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MAURITIUS',
    currencyName: 'Mauritius Rupee',
    currencyCode: 'MUR',
    numericCode: 480,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MAYOTTE',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP',
    currencyName: 'ADB Unit of Account',
    currencyCode: 'XUA',
    numericCode: 965,
    minorUnit: 'N.A.'
  },
  {
    currencyEntity: 'MEXICO',
    currencyName: 'Mexican Peso',
    currencyCode: 'MXN',
    numericCode: 484,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MEXICO',
    currencyName: 'Mexican Unidad de Inversion (UDI)',
    currencyCode: 'MXV',
    numericCode: 979,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MICRONESIA (FEDERATED STATES OF)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MOLDOVA (THE REPUBLIC OF)',
    currencyName: 'Moldovan Leu',
    currencyCode: 'MDL',
    numericCode: 498,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MONACO',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MONGOLIA',
    currencyName: 'Tugrik',
    currencyCode: 'MNT',
    numericCode: 496,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MONTENEGRO',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MONTSERRAT',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MOROCCO',
    currencyName: 'Moroccan Dirham',
    currencyCode: 'MAD',
    numericCode: 504,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MOZAMBIQUE',
    currencyName: 'Mozambique Metical',
    currencyCode: 'MZN',
    numericCode: 943,
    minorUnit: '2'
  },
  {
    currencyEntity: 'MYANMAR',
    currencyName: 'Kyat',
    currencyCode: 'MMK',
    numericCode: 104,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NAMIBIA',
    currencyName: 'Namibia Dollar',
    currencyCode: 'NAD',
    numericCode: 516,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NAMIBIA',
    currencyName: 'Rand',
    currencyCode: 'ZAR',
    numericCode: 710,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NAURU',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NEPAL',
    currencyName: 'Nepalese Rupee',
    currencyCode: 'NPR',
    numericCode: 524,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NETHERLANDS (THE)',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NEW CALEDONIA',
    currencyName: 'CFP Franc',
    currencyCode: 'XPF',
    numericCode: 953,
    minorUnit: '0'
  },
  {
    currencyEntity: 'NEW ZEALAND',
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    numericCode: 554,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NICARAGUA',
    currencyName: 'Cordoba Oro',
    currencyCode: 'NIO',
    numericCode: 558,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NIGER (THE)',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'NIGERIA',
    currencyName: 'Naira',
    currencyCode: 'NGN',
    numericCode: 566,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NIUE',
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    numericCode: 554,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NORFOLK ISLAND',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NORTHERN MARIANA ISLANDS (THE)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'NORWAY',
    currencyName: 'Norwegian Krone',
    currencyCode: 'NOK',
    numericCode: 578,
    minorUnit: '2'
  },
  {
    currencyEntity: 'OMAN',
    currencyName: 'Rial Omani',
    currencyCode: 'OMR',
    numericCode: 512,
    minorUnit: '3'
  },
  {
    currencyEntity: 'PAKISTAN',
    currencyName: 'Pakistan Rupee',
    currencyCode: 'PKR',
    numericCode: 586,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PALAU',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PANAMA',
    currencyName: 'Balboa',
    currencyCode: 'PAB',
    numericCode: 590,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PANAMA',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PAPUA NEW GUINEA',
    currencyName: 'Kina',
    currencyCode: 'PGK',
    numericCode: 598,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PARAGUAY',
    currencyName: 'Guarani',
    currencyCode: 'PYG',
    numericCode: 600,
    minorUnit: '0'
  },
  {
    currencyEntity: 'PERU',
    currencyName: 'Sol',
    currencyCode: 'PEN',
    numericCode: 604,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PHILIPPINES (THE)',
    currencyName: 'Philippine Peso',
    currencyCode: 'PHP',
    numericCode: 608,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PITCAIRN',
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    numericCode: 554,
    minorUnit: '2'
  },
  {
    currencyEntity: 'POLAND',
    currencyName: 'Zloty',
    currencyCode: 'PLN',
    numericCode: 985,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PORTUGAL',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'PUERTO RICO',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'QATAR',
    currencyName: 'Qatari Rial',
    currencyCode: 'QAR',
    numericCode: 634,
    minorUnit: '2'
  },
  {
    currencyEntity: 'RÉUNION',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ROMANIA',
    currencyName: 'Romanian Leu',
    currencyCode: 'RON',
    numericCode: 946,
    minorUnit: '2'
  },
  {
    currencyEntity: 'RUSSIAN FEDERATION (THE)',
    currencyName: 'Russian Ruble',
    currencyCode: 'RUB',
    numericCode: 643,
    minorUnit: '2'
  },
  {
    currencyEntity: 'RWANDA',
    currencyName: 'Rwanda Franc',
    currencyCode: 'RWF',
    numericCode: 646,
    minorUnit: '0'
  },
  {
    currencyEntity: 'SAINT BARTHÉLEMY',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    currencyName: 'Saint Helena Pound',
    currencyCode: 'SHP',
    numericCode: 654,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAINT KITTS AND NEVIS',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAINT LUCIA',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAINT MARTIN (FRENCH PART)',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAINT PIERRE AND MIQUELON',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAINT VINCENT AND THE GRENADINES',
    currencyName: 'East Caribbean Dollar',
    currencyCode: 'XCD',
    numericCode: 951,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAMOA',
    currencyName: 'Tala',
    currencyCode: 'WST',
    numericCode: 882,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAN MARINO',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAO TOME AND PRINCIPE',
    currencyName: 'Dobra',
    currencyCode: 'STN',
    numericCode: 930,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SAUDI ARABIA',
    currencyName: 'Saudi Riyal',
    currencyCode: 'SAR',
    numericCode: 682,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SENEGAL',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'SERBIA',
    currencyName: 'Serbian Dinar',
    currencyCode: 'RSD',
    numericCode: 941,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SEYCHELLES',
    currencyName: 'Seychelles Rupee',
    currencyCode: 'SCR',
    numericCode: 690,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SIERRA LEONE',
    currencyName: 'Leone',
    currencyCode: 'SLL',
    numericCode: 694,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SINGAPORE',
    currencyName: 'Singapore Dollar',
    currencyCode: 'SGD',
    numericCode: 702,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SINT MAARTEN (DUTCH PART)',
    currencyName: 'Netherlands Antillean Guilder',
    currencyCode: 'ANG',
    numericCode: 532,
    minorUnit: '2'
  },
  {
    currencyEntity:
      'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"',
    currencyName: 'Sucre',
    currencyCode: 'XSU',
    numericCode: 994,
    minorUnit: 'N.A.'
  },
  {
    currencyEntity: 'SLOVAKIA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SLOVENIA',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SOLOMON ISLANDS',
    currencyName: 'Solomon Islands Dollar',
    currencyCode: 'SBD',
    numericCode: 90,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SOMALIA',
    currencyName: 'Somali Shilling',
    currencyCode: 'SOS',
    numericCode: 706,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SOUTH AFRICA',
    currencyName: 'Rand',
    currencyCode: 'ZAR',
    numericCode: 710,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SOUTH SUDAN',
    currencyName: 'South Sudanese Pound',
    currencyCode: 'SSP',
    numericCode: 728,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SPAIN',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    numericCode: 978,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SRI LANKA',
    currencyName: 'Sri Lanka Rupee',
    currencyCode: 'LKR',
    numericCode: 144,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SUDAN (THE)',
    currencyName: 'Sudanese Pound',
    currencyCode: 'SDG',
    numericCode: 938,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SURINAME',
    currencyName: 'Surinam Dollar',
    currencyCode: 'SRD',
    numericCode: 968,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SVALBARD AND JAN MAYEN',
    currencyName: 'Norwegian Krone',
    currencyCode: 'NOK',
    numericCode: 578,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SWEDEN',
    currencyName: 'Swedish Krona',
    currencyCode: 'SEK',
    numericCode: 752,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SWITZERLAND',
    currencyName: 'Swiss Franc',
    currencyCode: 'CHF',
    numericCode: 756,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SWITZERLAND',
    currencyName: 'WIR Euro',
    currencyCode: 'CHE',
    numericCode: 947,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SWITZERLAND',
    currencyName: 'WIR Franc',
    currencyCode: 'CHW',
    numericCode: 948,
    minorUnit: '2'
  },
  {
    currencyEntity: 'SYRIAN ARAB REPUBLIC',
    currencyName: 'Syrian Pound',
    currencyCode: 'SYP',
    numericCode: 760,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TAIWAN (PROVINCE OF CHINA)',
    currencyName: 'New Taiwan Dollar',
    currencyCode: 'TWD',
    numericCode: 901,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TAJIKISTAN',
    currencyName: 'Somoni',
    currencyCode: 'TJS',
    numericCode: 972,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TANZANIA, UNITED REPUBLIC OF',
    currencyName: 'Tanzanian Shilling',
    currencyCode: 'TZS',
    numericCode: 834,
    minorUnit: '2'
  },
  {
    currencyEntity: 'THAILAND',
    currencyName: 'Baht',
    currencyCode: 'THB',
    numericCode: 764,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TIMOR-LESTE',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TOGO',
    currencyName: 'CFA Franc BCEAO',
    currencyCode: 'XOF',
    numericCode: 952,
    minorUnit: '0'
  },
  {
    currencyEntity: 'TOKELAU',
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    numericCode: 554,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TONGA',
    currencyName: 'Pa’anga',
    currencyCode: 'TOP',
    numericCode: 776,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TRINIDAD AND TOBAGO',
    currencyName: 'Trinidad and Tobago Dollar',
    currencyCode: 'TTD',
    numericCode: 780,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TUNISIA',
    currencyName: 'Tunisian Dinar',
    currencyCode: 'TND',
    numericCode: 788,
    minorUnit: '3'
  },
  {
    currencyEntity: 'TURKEY',
    currencyName: 'Turkish Lira',
    currencyCode: 'TRY',
    numericCode: 949,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TURKMENISTAN',
    currencyName: 'Turkmenistan New Manat',
    currencyCode: 'TMT',
    numericCode: 934,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TURKS AND CAICOS ISLANDS (THE)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'TUVALU',
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    numericCode: 36,
    minorUnit: '2'
  },
  {
    currencyEntity: 'UGANDA',
    currencyName: 'Uganda Shilling',
    currencyCode: 'UGX',
    numericCode: 800,
    minorUnit: '0'
  },
  {
    currencyEntity: 'UKRAINE',
    currencyName: 'Hryvnia',
    currencyCode: 'UAH',
    numericCode: 980,
    minorUnit: '2'
  },
  {
    currencyEntity: 'UNITED ARAB EMIRATES (THE)',
    currencyName: 'UAE Dirham',
    currencyCode: 'AED',
    numericCode: 784,
    minorUnit: '2'
  },
  {
    currencyEntity:
      'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)',
    currencyName: 'Pound Sterling',
    currencyCode: 'GBP',
    numericCode: 826,
    minorUnit: '2'
  },
  {
    currencyEntity: 'UNITED STATES MINOR OUTLYING ISLANDS (THE)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'UNITED STATES OF AMERICA (THE)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'UNITED STATES OF AMERICA (THE)',
    currencyName: 'US Dollar (Next day)',
    currencyCode: 'USN',
    numericCode: 997,
    minorUnit: '2'
  },
  {
    currencyEntity: 'URUGUAY',
    currencyName: 'Peso Uruguayo',
    currencyCode: 'UYU',
    numericCode: 858,
    minorUnit: '2'
  },
  {
    currencyEntity: 'URUGUAY',
    currencyName: 'Uruguay Peso en Unidades Indexadas (UI)',
    currencyCode: 'UYI',
    numericCode: 940,
    minorUnit: '0'
  },
  {
    currencyEntity: 'URUGUAY',
    currencyName: 'Unidad Previsional',
    currencyCode: 'UYW',
    numericCode: 927,
    minorUnit: '4'
  },
  {
    currencyEntity: 'UZBEKISTAN',
    currencyName: 'Uzbekistan Sum',
    currencyCode: 'UZS',
    numericCode: 860,
    minorUnit: '2'
  },
  {
    currencyEntity: 'VANUATU',
    currencyName: 'Vatu',
    currencyCode: 'VUV',
    numericCode: 548,
    minorUnit: '0'
  },
  {
    currencyEntity: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
    currencyName: 'Bolívar Soberano',
    currencyCode: 'VES',
    numericCode: 928,
    minorUnit: '2'
  },
  {
    currencyEntity: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
    currencyName: 'Bolívar Soberano',
    currencyCode: 'VED',
    numericCode: 926,
    minorUnit: '2'
  },
  {
    currencyEntity: 'VIET NAM',
    currencyName: 'Dong',
    currencyCode: 'VND',
    numericCode: 704,
    minorUnit: '0'
  },
  {
    currencyEntity: 'VIRGIN ISLANDS (BRITISH)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'VIRGIN ISLANDS (U.S.)',
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    numericCode: 840,
    minorUnit: '2'
  },
  {
    currencyEntity: 'WALLIS AND FUTUNA',
    currencyName: 'CFP Franc',
    currencyCode: 'XPF',
    numericCode: 953,
    minorUnit: '0'
  },
  {
    currencyEntity: 'WESTERN SAHARA',
    currencyName: 'Moroccan Dirham',
    currencyCode: 'MAD',
    numericCode: 504,
    minorUnit: '2'
  },
  {
    currencyEntity: 'YEMEN',
    currencyName: 'Yemeni Rial',
    currencyCode: 'YER',
    numericCode: 886,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ZAMBIA',
    currencyName: 'Zambian Kwacha',
    currencyCode: 'ZMW',
    numericCode: 967,
    minorUnit: '2'
  },
  {
    currencyEntity: 'ZIMBABWE',
    currencyName: 'Zimbabwe Dollar',
    currencyCode: 'ZWL',
    numericCode: 932,
    minorUnit: '2'
  }
];

export const CURRENCY_LIST_UNIQUE = [
  { currencyCode: 'XUA', currencyName: 'ADB Unit of Account' },
  { currencyCode: 'AFN', currencyName: 'Afghani' },
  { currencyCode: 'DZD', currencyName: 'Algerian Dinar' },
  { currencyCode: 'ARS', currencyName: 'Argentine Peso' },
  { currencyCode: 'AMD', currencyName: 'Armenian Dram' },
  { currencyCode: 'AWG', currencyName: 'Aruban Florin' },
  { currencyCode: 'AUD', currencyName: 'Australian Dollar' },
  { currencyCode: 'AZN', currencyName: 'Azerbaijan Manat' },
  { currencyCode: 'BSD', currencyName: 'Bahamian Dollar' },
  { currencyCode: 'BHD', currencyName: 'Bahraini Dinar' },
  { currencyCode: 'THB', currencyName: 'Baht' },
  { currencyCode: 'PAB', currencyName: 'Balboa' },
  { currencyCode: 'BBD', currencyName: 'Barbados Dollar' },
  { currencyCode: 'BYN', currencyName: 'Belarusian Ruble' },
  { currencyCode: 'BZD', currencyName: 'Belize Dollar' },
  { currencyCode: 'BMD', currencyName: 'Bermudian Dollar' },
  { currencyCode: 'BOB', currencyName: 'Boliviano' },
  { currencyCode: 'VES', currencyName: 'Bolívar Soberano' },
  { currencyCode: 'VED', currencyName: 'Bolívar Soberano' },
  { currencyCode: 'BRL', currencyName: 'Brazilian Real' },
  { currencyCode: 'BND', currencyName: 'Brunei Dollar' },
  { currencyCode: 'BGN', currencyName: 'Bulgarian Lev' },
  { currencyCode: 'BIF', currencyName: 'Burundi Franc' },
  { currencyCode: 'XOF', currencyName: 'CFA Franc BCEAO' },
  { currencyCode: 'XAF', currencyName: 'CFA Franc BEAC' },
  { currencyCode: 'XPF', currencyName: 'CFP Franc' },
  { currencyCode: 'CVE', currencyName: 'Cabo Verde Escudo' },
  { currencyCode: 'CAD', currencyName: 'Canadian Dollar' },
  { currencyCode: 'KYD', currencyName: 'Cayman Islands Dollar' },
  { currencyCode: 'CLP', currencyName: 'Chilean Peso' },
  { currencyCode: 'COP', currencyName: 'Colombian Peso' },
  { currencyCode: 'KMF', currencyName: 'Comorian Franc ' },
  { currencyCode: 'CDF', currencyName: 'Congolese Franc' },
  { currencyCode: 'BAM', currencyName: 'Convertible Mark' },
  { currencyCode: 'NIO', currencyName: 'Cordoba Oro' },
  { currencyCode: 'CRC', currencyName: 'Costa Rican Colon' },
  { currencyCode: 'CUP', currencyName: 'Cuban Peso' },
  { currencyCode: 'CZK', currencyName: 'Czech Koruna' },
  { currencyCode: 'GMD', currencyName: 'Dalasi' },
  { currencyCode: 'DKK', currencyName: 'Danish Krone' },
  { currencyCode: 'MKD', currencyName: 'Denar' },
  { currencyCode: 'DJF', currencyName: 'Djibouti Franc' },
  { currencyCode: 'STN', currencyName: 'Dobra' },
  { currencyCode: 'DOP', currencyName: 'Dominican Peso' },
  { currencyCode: 'VND', currencyName: 'Dong' },
  { currencyCode: 'XCD', currencyName: 'East Caribbean Dollar' },
  { currencyCode: 'EGP', currencyName: 'Egyptian Pound' },
  { currencyCode: 'SVC', currencyName: 'El Salvador Colon' },
  { currencyCode: 'ETB', currencyName: 'Ethiopian Birr' },
  { currencyCode: 'EUR', currencyName: 'Euro' },
  { currencyCode: 'FKP', currencyName: 'Falkland Islands Pound' },
  { currencyCode: 'FJD', currencyName: 'Fiji Dollar' },
  { currencyCode: 'HUF', currencyName: 'Forint' },
  { currencyCode: 'GHS', currencyName: 'Ghana Cedi' },
  { currencyCode: 'GIP', currencyName: 'Gibraltar Pound' },
  { currencyCode: 'HTG', currencyName: 'Gourde' },
  { currencyCode: 'PYG', currencyName: 'Guarani' },
  { currencyCode: 'GNF', currencyName: 'Guinean Franc' },
  { currencyCode: 'GYD', currencyName: 'Guyana Dollar' },
  { currencyCode: 'HKD', currencyName: 'Hong Kong Dollar' },
  { currencyCode: 'UAH', currencyName: 'Hryvnia' },
  { currencyCode: 'ISK', currencyName: 'Iceland Krona' },
  { currencyCode: 'INR', currencyName: 'Indian Rupee' },
  { currencyCode: 'IRR', currencyName: 'Iranian Rial' },
  { currencyCode: 'IQD', currencyName: 'Iraqi Dinar' },
  { currencyCode: 'JMD', currencyName: 'Jamaican Dollar' },
  { currencyCode: 'JOD', currencyName: 'Jordanian Dinar' },
  { currencyCode: 'KES', currencyName: 'Kenyan Shilling' },
  { currencyCode: 'PGK', currencyName: 'Kina' },
  { currencyCode: 'HRK', currencyName: 'Kuna' },
  { currencyCode: 'KWD', currencyName: 'Kuwaiti Dinar' },
  { currencyCode: 'AOA', currencyName: 'Kwanza' },
  { currencyCode: 'MMK', currencyName: 'Kyat' },
  { currencyCode: 'LAK', currencyName: 'Lao Kip' },
  { currencyCode: 'GEL', currencyName: 'Lari' },
  { currencyCode: 'LBP', currencyName: 'Lebanese Pound' },
  { currencyCode: 'ALL', currencyName: 'Lek' },
  { currencyCode: 'HNL', currencyName: 'Lempira' },
  { currencyCode: 'SLL', currencyName: 'Leone' },
  { currencyCode: 'LRD', currencyName: 'Liberian Dollar' },
  { currencyCode: 'LYD', currencyName: 'Libyan Dinar' },
  { currencyCode: 'SZL', currencyName: 'Lilangeni' },
  { currencyCode: 'LSL', currencyName: 'Loti' },
  { currencyCode: 'MGA', currencyName: 'Malagasy Ariary' },
  { currencyCode: 'MWK', currencyName: 'Malawi Kwacha' },
  { currencyCode: 'MYR', currencyName: 'Malaysian Ringgit' },
  { currencyCode: 'MUR', currencyName: 'Mauritius Rupee' },
  { currencyCode: 'MXN', currencyName: 'Mexican Peso' },
  { currencyCode: 'MXV', currencyName: 'Mexican Unidad de Inversion (UDI)' },
  { currencyCode: 'MDL', currencyName: 'Moldovan Leu' },
  { currencyCode: 'MAD', currencyName: 'Moroccan Dirham' },
  { currencyCode: 'MZN', currencyName: 'Mozambique Metical' },
  { currencyCode: 'BOV', currencyName: 'Mvdol' },
  { currencyCode: 'NGN', currencyName: 'Naira' },
  { currencyCode: 'ERN', currencyName: 'Nakfa' },
  { currencyCode: 'NAD', currencyName: 'Namibia Dollar' },
  { currencyCode: 'NPR', currencyName: 'Nepalese Rupee' },
  { currencyCode: 'ANG', currencyName: 'Netherlands Antillean Guilder' },
  { currencyCode: 'ILS', currencyName: 'New Israeli Sheqel' },
  { currencyCode: 'TWD', currencyName: 'New Taiwan Dollar' },
  { currencyCode: 'NZD', currencyName: 'New Zealand Dollar' },
  { currencyCode: 'BTN', currencyName: 'Ngultrum' },
  { currencyCode: 'KPW', currencyName: 'North Korean Won' },
  { currencyCode: 'NOK', currencyName: 'Norwegian Krone' },
  { currencyCode: 'MRU', currencyName: 'Ouguiya' },
  { currencyCode: 'PKR', currencyName: 'Pakistan Rupee' },
  { currencyCode: 'MOP', currencyName: 'Pataca' },
  { currencyCode: 'TOP', currencyName: 'Pa’anga' },
  { currencyCode: 'CUC', currencyName: 'Peso Convertible' },
  { currencyCode: 'UYU', currencyName: 'Peso Uruguayo' },
  { currencyCode: 'PHP', currencyName: 'Philippine Peso' },
  { currencyCode: 'GBP', currencyName: 'Pound Sterling' },
  { currencyCode: 'BWP', currencyName: 'Pula' },
  { currencyCode: 'QAR', currencyName: 'Qatari Rial' },
  { currencyCode: 'GTQ', currencyName: 'Quetzal' },
  { currencyCode: 'ZAR', currencyName: 'Rand' },
  { currencyCode: 'OMR', currencyName: 'Rial Omani' },
  { currencyCode: 'KHR', currencyName: 'Riel' },
  { currencyCode: 'RON', currencyName: 'Romanian Leu' },
  { currencyCode: 'MVR', currencyName: 'Rufiyaa' },
  { currencyCode: 'IDR', currencyName: 'Rupiah' },
  { currencyCode: 'RUB', currencyName: 'Russian Ruble' },
  { currencyCode: 'RWF', currencyName: 'Rwanda Franc' },
  { currencyCode: 'XDR', currencyName: 'SDR (Special Drawing Right)' },
  { currencyCode: 'SHP', currencyName: 'Saint Helena Pound' },
  { currencyCode: 'SAR', currencyName: 'Saudi Riyal' },
  { currencyCode: 'RSD', currencyName: 'Serbian Dinar' },
  { currencyCode: 'SCR', currencyName: 'Seychelles Rupee' },
  { currencyCode: 'SGD', currencyName: 'Singapore Dollar' },
  { currencyCode: 'PEN', currencyName: 'Sol' },
  { currencyCode: 'SBD', currencyName: 'Solomon Islands Dollar' },
  { currencyCode: 'KGS', currencyName: 'Som' },
  { currencyCode: 'SOS', currencyName: 'Somali Shilling' },
  { currencyCode: 'TJS', currencyName: 'Somoni' },
  { currencyCode: 'SSP', currencyName: 'South Sudanese Pound' },
  { currencyCode: 'LKR', currencyName: 'Sri Lanka Rupee' },
  { currencyCode: 'XSU', currencyName: 'Sucre' },
  { currencyCode: 'SDG', currencyName: 'Sudanese Pound' },
  { currencyCode: 'SRD', currencyName: 'Surinam Dollar' },
  { currencyCode: 'SEK', currencyName: 'Swedish Krona' },
  { currencyCode: 'CHF', currencyName: 'Swiss Franc' },
  { currencyCode: 'SYP', currencyName: 'Syrian Pound' },
  { currencyCode: 'BDT', currencyName: 'Taka' },
  { currencyCode: 'WST', currencyName: 'Tala' },
  { currencyCode: 'TZS', currencyName: 'Tanzanian Shilling' },
  { currencyCode: 'KZT', currencyName: 'Tenge' },
  { currencyCode: 'TTD', currencyName: 'Trinidad and Tobago Dollar' },
  { currencyCode: 'MNT', currencyName: 'Tugrik' },
  { currencyCode: 'TND', currencyName: 'Tunisian Dinar' },
  { currencyCode: 'TRY', currencyName: 'Turkish Lira' },
  { currencyCode: 'TMT', currencyName: 'Turkmenistan New Manat' },
  { currencyCode: 'AED', currencyName: 'UAE Dirham' },
  { currencyCode: 'USD', currencyName: 'US Dollar' },
  { currencyCode: 'USN', currencyName: 'US Dollar (Next day)' },
  { currencyCode: 'UGX', currencyName: 'Uganda Shilling' },
  { currencyCode: 'UYW', currencyName: 'Unidad Previsional' },
  { currencyCode: 'CLF', currencyName: 'Unidad de Fomento' },
  { currencyCode: 'COU', currencyName: 'Unidad de Valor Real' },
  {
    currencyCode: 'UYI',
    currencyName: 'Uruguay Peso en Unidades Indexadas (UI)'
  },
  { currencyCode: 'UZS', currencyName: 'Uzbekistan Sum' },
  { currencyCode: 'VUV', currencyName: 'Vatu' },
  { currencyCode: 'CHE', currencyName: 'WIR Euro' },
  { currencyCode: 'CHW', currencyName: 'WIR Franc' },
  { currencyCode: 'KRW', currencyName: 'Won' },
  { currencyCode: 'YER', currencyName: 'Yemeni Rial' },
  { currencyCode: 'JPY', currencyName: 'Yen' },
  { currencyCode: 'CNY', currencyName: 'Yuan Renminbi' },
  { currencyCode: 'ZMW', currencyName: 'Zambian Kwacha' },
  { currencyCode: 'ZWL', currencyName: 'Zimbabwe Dollar' },
  { currencyCode: 'PLN', currencyName: 'Zloty' }
] as const;
