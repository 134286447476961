import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateScope,
  fetchUserActivitiesForActionable,
  fetchScopes,
  clearUserActivities
} from 'actionCreators';
import xor from 'lodash/xor';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import { DESCRIPTION_ERROR_TEXT } from '../constants';
import useScope from 'appUtils/hooks/useScope';
import { actionableTypesHash } from 'appConstants/userActivities';
import moment from 'moment';
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  UPDATE_SCOPE,
  ASSIGN_SCOPE_MEMBERS,
  MOVE_SCOPES
} from 'appConstants';
import useTrigger from 'appUtils/hooks/useTrigger';

const useScopeModal = ({ scopeId, toggle, filterStateId, groupBy }) => {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false); // prevent double clicks on save or delete
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const {
    scope,
    updatingScopeIds,
    assignMembersToScope,
    debouncedAssignMembersToScope,
    flushAssignMembersToScope,
    throttledUpdateScopeDescription,
    flushUpdateScopeDescription,
    throttledUpdateScopeNotes,
    flushUpdateScopeNotes,
    deleteAttachmentFromScope,
    addAttachmentsToScope,
    updateScopeDates
  } = useScope({
    scopeId
  });

  // For optimistic updating and comparisons vs actual data
  const [state, setState] = useState({
    activityPhaseId: scope.activity_phase_id, // optional
    requestedToId: scope.requested_to_id, // optional - the person who we want to approve this request
    assignees: scope.assignees, // optional
    description: scope.description,
    // FE only
    projectId: scope.project_id,
    phaseId: scope.phase_id,
    activityId: scope.activity_id,
    selectedAssignees: mapValues(keyBy(scope.assignees), (accountId) => true)
  });
  const [errors, setErrors] = useState({});

  const isDescriptionEdited = state.description !== scope.description;
  const isAssigneesEdited = useMemo(
    () => Boolean(xor(state.assignees, scope.assignees).length),
    [scope.assignees, state.assignees]
  );
  const isActivityPhaseEdited =
    +state.activityPhaseId !== +scope.activity_phase_id;
  const hasChanges =
    isDescriptionEdited || isAssigneesEdited || isActivityPhaseEdited;

  const isDescriptionEmpty = !state.description.trim().length;
  const isValid = !isDescriptionEmpty;

  /* --------------------------------- Submit --------------------------------- */

  const dispatchUpdateScope = useCallback(
    (newValues) => {
      dispatch(
        updateScope({
          id: scopeId,
          ...newValues
        })
      );
    },
    [dispatch, scopeId]
  );

  const checkIfDescriptionValid = () => {
    if (isDescriptionEmpty) {
      setErrors({ ...errors, description: DESCRIPTION_ERROR_TEXT });
      return false;
    }
    return true;
  };

  const checkIfValid = () => {
    return checkIfDescriptionValid();
  };

  /**
   * Close the modal and invoke any waiting debounced save calls
   */
  const handleClose = () => {
    if (!isClosing && checkIfValid()) {
      setIsClosing(true);
      if (hasChanges) {
        // invoke waiting calls, if any
        flushUpdateScopeDescription();
        flushUpdateScopeNotes();
      }
      toggle();
    }
  };

  /* ------------------------------- Description ------------------------------ */

  /**
   * Resets error
   * Auto saves description (if valid)
   */
  const handleDescriptionChange = useCallback(
    (newDescription) => {
      // reset error state if necessary
      if (errors.description && newDescription.trim().length) {
        setErrors((currErrors) => ({
          ...currErrors,
          description: ''
        }));
      }

      setState((currState) => ({
        ...currState,
        description: newDescription
      }));
      throttledUpdateScopeDescription(newDescription); // validation is handled by the function
    },
    [errors.description, throttledUpdateScopeDescription]
  );

  /* ---------------------------------- Notes --------------------------------- */

  /**
   * Auto saves notes
   */
  const handleNotesChange = useCallback(
    (newNotes) => {
      throttledUpdateScopeNotes(newNotes);
    },
    [throttledUpdateScopeNotes]
  );

  /* --------------------------------- Assign --------------------------------- */

  const handleAssigneeSelect = (accountId) => {
    const nextAssignees = state.selectedAssignees[accountId]
      ? state.assignees.filter((id) => id !== accountId)
      : [...state.assignees, accountId];
    setState((currState) => ({
      ...currState,
      selectedAssignees: {
        ...currState.selectedAssignees,
        [accountId]: !currState.selectedAssignees[accountId]
      },
      assignees: nextAssignees
    }));
    debouncedAssignMembersToScope({
      assignedAccountIds: nextAssignees,
      filterStateId,
      groupBy
    });
  };

  const handleClearAssignees = () => {
    setState((currState) => ({
      ...currState,
      selectedAssignees: {},
      assignees: []
    }));
    debouncedAssignMembersToScope({
      assignedAccountIds: [],
      filterStateId,
      groupBy
    });
  };

  /* ----------------------------- User activities ---------------------------- */

  // refetch activity log
  const { shouldTrigger: shouldRefetchActivityLog } = useTrigger({
    triggerId: scopeActivityLogRefetchTriggerId,
    triggerAfter: triggerScopeActivityLogRefetchAfter,
    // technically conditionsByAction isn't necessary since we're in a modal
    // (if these actions are triggered, they are likely to be for this scope anyway)
    // leaving as example of how to use conditionsByAction
    conditionsByAction: {
      [UPDATE_SCOPE.SUCCESS]: {
        'response.scope.id': scope.id
      },
      [ASSIGN_SCOPE_MEMBERS.SUCCESS]: {
        'requestPayload.scopeId': scope.id
      },
      [MOVE_SCOPES.SUCCESS]: {
        'requestPayload.id': scope.id
      }
    }
  });

  const fetchUserActivities = useCallback(
    (otherParams) => {
      dispatch(
        fetchUserActivitiesForActionable({
          actionable_id: scopeId,
          actionable_type: actionableTypesHash.ProjectScope,
          start_date: moment().add(-1, 'year').format('YYYY-MM-DD'),
          end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
          ...otherParams
        })
      );
    },
    [dispatch, scopeId]
  );

  useEffect(() => {
    if (shouldRefetchActivityLog) {
      fetchUserActivities({
        triggerId: scopeActivityLogRefetchTriggerId,
        keepCurrentActivitiesOnTrigger: true
      });
    }
  }, [fetchUserActivities, shouldRefetchActivityLog]);

  useEffect(() => {
    fetchUserActivities();
  }, [fetchUserActivities]);

  /* --------------------------------- Refetch -------------------------------- */

  // refetch scope triggers
  const { shouldTrigger: shouldRefetchScope } = useTrigger({
    triggerId: scopeRefetchTriggerId,
    triggerAfter: triggerScopeRefetchAfter,
    conditionsByAction: {
      [CREATE_COMMENT.SUCCESS]: {
        'response.project_task_id': scope.id
      }
    }
  });

  // Refetch scope when necessary
  useEffect(() => {
    if (shouldRefetchScope) {
      dispatch(
        fetchScopes({
          id: scope.id,
          triggerId: scopeRefetchTriggerId
        })
      );
    }
  }, [dispatch, scope.id, shouldRefetchScope]);

  /* --------------------------------- Cleanup -------------------------------- */

  useEffect(() => {
    return () => {
      dispatch(clearUserActivities());
    };
  }, [dispatch]);

  /* ------------------------------------ - ----------------------------------- */

  return {
    state,
    setState,
    errors,
    checkIfDescriptionValid,
    checkIfValid,
    isValid,
    hasChanges,

    scope,
    updatingScopeIds,
    dispatchUpdateScope,

    isDeleteConfirmOpen,
    setIsDeleteConfirmOpen,

    handleClose,
    isClosing,
    setIsClosing,

    handleAssigneeSelect,
    flushAssignMembersToScope,
    handleClearAssignees,
    assignMembersToScope,

    handleDescriptionChange,
    flushUpdateScopeDescription,

    handleNotesChange,
    flushUpdateScopeNotes,

    addAttachmentsToScope,
    deleteAttachmentFromScope,

    updateScopeDates
  };
};

export default useScopeModal;

/* ------------------------------------ - ----------------------------------- */

const scopeRefetchTriggerId = 'refetch-scope-on-scope-modal';
const triggerScopeRefetchAfter = [
  CREATE_COMMENT.SUCCESS,
  DELETE_COMMENT.SUCCESS
];

const scopeActivityLogRefetchTriggerId =
  'refetch-scope-activity-log-on-scope-modal';
const triggerScopeActivityLogRefetchAfter = [
  UPDATE_SCOPE.SUCCESS,
  ASSIGN_SCOPE_MEMBERS.SUCCESS,
  MOVE_SCOPES.SUCCESS
];
