import {
  isLazyLoadingSlot,
  LazyLoadingGroupId,
  LazyLoadingHash
} from 'TaskModule/selectors';

/**
 * Determines the behavior of a group that is loaded and contains no items.
 */
export enum EmptyBehavior {
  /**
   * Does not render the group.
   */
  HideGroup,

  /**
   * Does not render the list, but renders the rest of the group items.
   */
  HideList,

  /**
   * Renders an empty list item with the ID of the group and the rest of the
   * group items.
   */
  EmptyList
}

/**
 * Determines the initial opening state of a group.
 */
export enum GroupState {
  /**
   * The group is always expanded. It may never collapse.
   */
  AlwaysExpanded,

  /**
   * The group is initially collapsed. It may be expanded.
   */
  Collapsed,

  /**
   * The group is initially expanded. It may be collapsed.
   */
  Expanded
}

type GroupId = LazyLoadingGroupId;

interface GroupBase {
  /**
   * The class for the group item.
   */
  className?: string;

  /**
   * Determines how the group is rendered when it is loaded and contains no
   * items. Defaults to `EmptyState.HideGroup`.
   */
  emptyBehavior?: EmptyBehavior;

  /**
   * Determines whether the heading for the group is hidden.
   */
  hideHeading?: boolean;

  /**
   * The ID for the group.
   */
  id: GroupId;

  /**
   * Determines whether the group is initially expanded or not. Defaults to
   * `GroupState.Expanded`.
   */
  initialState?: GroupState;
}

/**
 * A group that is not lazy-loaded.
 */
export interface Group<OtherItem> extends GroupBase {
  /**
   * The list of items in the group.
   */
  list?: Array<ListNode<OtherItem>>;

  /**
   * A constant type indicator.
   */
  group: true;
}
const isGroup = <OtherItem, T>(
  item: Group<OtherItem> | T
): item is Group<OtherItem> => 'group' in item;

/**
 * A group that is lazy-loaded. Items may optionally be inserted before or
 * after the lazily-loaded list.
 */
export interface LazyLoadingGroup<OtherItem> extends GroupBase {
  /**
   * The list of items that follow the list of the group.
   */
  postlist?: Array<ListNode<OtherItem>>;

  /**
   * The list of items that preceed the list of the group.
   */
  prelist?: Array<ListNode<OtherItem>>;

  /**
   * A constant type indicator.
   */
  lazyLoadingGroup: true;
}
const isLazyLoadingGroup = <OtherItem, T>(
  item: LazyLoadingGroup<OtherItem> | T
): item is LazyLoadingGroup<OtherItem> => 'lazyLoadingGroup' in item;

/**
 * Indicates that an item needs to be lazy loaded.
 */
interface ItemLazyLoader {
  /**
   * The identifier for the group of the item.
   */
  groupId: string;

  /**
   * The position of the item in the group. This is required because some items
   * may be filtered out and the position of the remaining items may have
   * changed.
   */
  index: number;

  /**
   * A flag indicating whether the item is being loaded.
   */
  isLoading: boolean;

  /**
   * A constant type indicator.
   */
  itemLazyLoader: true;
}
export const isItemLazyLoader = <OtherItem, T>(
  item: ListItem<OtherItem, T>
): item is ItemLazyLoader => 'itemLazyLoader' in item;

/**
 * Indicates that the initial item list of a group needs to be lazy loaded.
 */
interface GroupLazyLoader {
  /**
   * The identifier for the group of the item.
   */
  groupId: string;

  /**
   * A flag indicating whether the group is being initially loaded.
   */
  isLoading: boolean;

  /**
   * A constant type indicator.
   */
  groupItemsLazyLoader: true;
}
export const isGroupItemsLazyLoader = <OtherItem, T>(
  item: ListItem<OtherItem, T>
): item is GroupLazyLoader => 'groupItemsLazyLoader' in item;

/**
 * A heading of a group.
 */
interface GroupHeading {
  /**
   * The class for the group item.
   */
  className?: string;

  /**
   * A flag indicating whether the group contents are visible (open) or hidden
   * (collapsed).
   */
  isCollapsed: boolean;

  /**
   * A flag indicating whether the group can be collapsed.
   */
  isCollapsible: boolean;

  /**
   * The identifier for the group of the item.
   */
  groupId: string;

  /**
   * A constant type indicator.
   */
  groupHeading: true;

  /**
   * The depth of the group heading. This allows for styling different levels
   * differently.
   */
  level: number;
}
export const isGroupHeading = <OtherItem, T>(
  item: ListItem<OtherItem, T>
): item is GroupHeading => 'groupHeading' in item;

/**
 * A placeholder for an empty list.
 */
interface EmptyList {
  /**
   * A constant type indicator.
   */
  emptyList: true;

  /**
   * The identifier for the group of the item.
   */
  groupId: string;
}
export const isEmptyList = <OtherItem, T>(
  item: ListItem<OtherItem, T>
): item is EmptyList => 'emptyList' in item;

/**
 * An item in a list produced from a collapsed hierarchy.
 */
export type ListItem<LoadedItem, OtherItem> =
  | EmptyList
  | GroupHeading
  | GroupLazyLoader
  | OtherItem
  | LoadedItem
  | ItemLazyLoader;

/**
 * An item in a hierarchy.
 */
export type ListNode<OtherItem> =
  | LazyLoadingGroup<OtherItem>
  | Group<OtherItem>
  | OtherItem;

/**
 * Flattens a hierarchy of items and inserts any grouped items into the
 * appropriate groups.
 */
export const flattenHierarchy = <LoadedItem, OtherItem>({
  getIsOpen,
  groupedItems,
  level = 0,
  hierarchy,
  accumulator
}: {
  /**
   * A function to determine whether a given group is visible (open) or hidden
   * (collapsed).
   */
  getIsOpen: (id: string) => boolean | undefined;

  /**
   * The lazy-loaded groups and items to be inserted into the flattened
   * hierarchy.
   */
  groupedItems: LazyLoadingHash<LoadedItem>;

  /**
   * The current depth of the nodes. Defaults to 0.
   */
  level?: number;

  /**
   * The hierarchy of items.
   */
  hierarchy: Array<ListNode<OtherItem>>;

  /**
   * The accumulator for the list. Defaults to an empty array.
   */
  accumulator?: {
    list: Array<ListItem<LoadedItem, OtherItem>>;
    newlyLoadedIndex?: number;
  };
}): {
  list: Array<ListItem<LoadedItem, OtherItem>>;
  newlyLoadedIndex?: number;
} =>
  hierarchy.reduce<{
    list: Array<ListItem<LoadedItem, OtherItem>>;
    newlyLoadedIndex?: number;
  }>((accumulator, item) => {
    const { list } = accumulator;

    // Items that are not groups are preserved as they are.
    if (!isGroup(item) && !isLazyLoadingGroup(item)) {
      list.push(item);
      return accumulator;
    }

    const groupId = item.id;
    const groupItem = groupedItems.get(groupId);
    const groupItems = groupItem?.items;
    const isEmptyGroup = groupItems && !groupItems.length;
    const emptyBehavior = item.emptyBehavior ?? EmptyBehavior.HideGroup;

    // If the group has no items and the hiding behavior, add nothing.
    if (isEmptyGroup && emptyBehavior === EmptyBehavior.HideGroup)
      return accumulator;

    const isCollapsible = item.initialState !== GroupState.AlwaysExpanded;
    const isCollapsed =
      isCollapsible &&
      !(getIsOpen(groupId) ?? item.initialState !== GroupState.Collapsed);

    // The heading, if desired.
    if (!item.hideHeading)
      list.push({
        className: item.className,
        groupId,
        groupHeading: true,
        isCollapsed,
        isCollapsible,
        level
      } as GroupHeading);

    // If the group is collapsed, do not include its children.
    if (isCollapsed) {
    }

    // If the group is not a lazy-loading group, load its items.
    else if (isGroup(item)) {
      if (item.list)
        flattenHierarchy<LoadedItem, OtherItem>({
          accumulator,
          getIsOpen,
          groupedItems,
          level: level + 1,
          hierarchy: item.list
        });
    }

    // If the group is a lazy-loading group, load its three parts.
    else {
      // The leading content of the group.
      if (item.prelist)
        flattenHierarchy<LoadedItem, OtherItem>({
          accumulator,
          getIsOpen,
          groupedItems,
          level: level + 1,
          hierarchy: item.prelist
        });

      // If the group does not have an item list, add the initial loading
      // indicator.
      if (!groupItems) {
        list.push({
          groupId,
          // If the group exists, but not the item list, loading has been
          // initiated.
          isLoading: !!groupItem,
          groupItemsLazyLoader: true
        } as GroupLazyLoader);
      } else {
        // Check for updates to the items.
        const updatedRange = groupItem?.updatedRange;
        if (updatedRange)
          accumulator.newlyLoadedIndex ??= updatedRange.min + list.length;

        // If the group has items, create an entry for each item.
        if (groupItems.length > 0) {
          list.push(
            ...groupItems.map((item) =>
              // If an item is not yet loaded, add a loading indicator.
              isLazyLoadingSlot(item)
                ? ({
                    groupId,
                    index: item.index,
                    isLoading: item.isLazyLoading,
                    itemLazyLoader: true
                  } as ItemLazyLoader)
                : // If an item has loaded, add it.
                  item
            )
          );
        }

        // If the group has no items and the empty list behavior, add
        // an empty list item.
        else if (emptyBehavior === EmptyBehavior.EmptyList) {
          list.push({ groupId, emptyList: true } as EmptyList);
        }

        // If the group has no items and the blank behavior, add nothing.
        else {
        }
      }

      // The trailing content of the group.
      if (item.postlist)
        flattenHierarchy<LoadedItem, OtherItem>({
          accumulator,
          getIsOpen,
          groupedItems,
          level: level + 1,
          hierarchy: item.postlist
        });
    }

    return accumulator;
  }, accumulator ?? { list: [] });
