import React, { useState, useEffect } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { ScheduleBar } from 'models/userActivities';
import { isTentativePlan } from 'views/projectPlanner/utils';

interface ActivitySideMenuFooterProps {
  scheduleBar: ScheduleBar;
}

const footerFieldNameHash = {
  budget_status: (scheduleBar) =>
    isTentativePlan(scheduleBar.budget_status) ? 'Tentative Plan' : undefined,
  use_weekly_planning: () => 'Full Weeks',
  include_weekends: () => 'Working Weekends'
};

const ActivitySideMenuFooter = ({
  scheduleBar
}: ActivitySideMenuFooterProps) => {
  const generateFooterItems = () => {
    if (!scheduleBar) {
      return [];
    }
    const footerItems: string[] = [];
    Object.keys(footerFieldNameHash).forEach((footerField) => {
      if (scheduleBar[footerField]) {
        const itemGenerator = footerFieldNameHash[footerField];
        const itemToPush = itemGenerator ? itemGenerator(scheduleBar) : null;
        if (itemToPush) {
          footerItems.push(itemToPush);
        }
      }
    });

    return footerItems;
  };

  return (
    <ActivityLogCardFooter>
      {generateFooterItems().map((footerItem, index) => (
        <>
          <ActivityFooterItem
            key={index}
          >{`- ${footerItem}`}</ActivityFooterItem>
        </>
      ))}
    </ActivityLogCardFooter>
  );
};

// ************* Styling *********** //

const ActivityLogCardFooter = styled.div``;

const ActivityFooterItem = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
`;

export default ActivitySideMenuFooter;
