import moment from 'appUtils/momentConfig';
import {
  getAccountCapacities,
  getHolidaysArray
} from 'CapacityModule/selectors';
import { createSelector } from 'reselect';
import { getOOOProject, getWFHProject } from 'selectors';
import { makeGetUtilizationFilterState } from 'UtilizationModule/selectors';
import keyBy from 'lodash/keyBy';

const emptyObj = {};
const emptyArray = [];

/**
 * already group by bar id
 */
const getScheduleBars = (state) => state.workloadPlanner.scheduleBars;

const getOwnMemberId = (_, params) => {
  return params?.memberId;
};

const getScheduleBarsByMember = createSelector(
  getScheduleBars,
  getOwnMemberId,
  (state, memberId) => {
    if (!state || !memberId) return emptyArray;
    return Object.values(state)?.filter(
      (item) => item.account_id && item.account_id?.toString() === memberId
    );
  }
);

const getOwnCalendarYear = (_, params) => params.calendarYear;

const getPTOScheduleBars = createSelector(
  getScheduleBarsByMember,
  getOOOProject,
  (bars, OOOProject) =>
    bars && OOOProject
      ? bars.filter((bar) => bar.project_id === OOOProject.id)
      : emptyArray
);

export const getPTOScheduleBarsHash = createSelector(
  getPTOScheduleBars,
  (ptoScheduleBars) => keyBy(ptoScheduleBars, ({ id }) => id)
);

const getWFHScheduleBars = createSelector(
  getScheduleBarsByMember,
  getWFHProject,
  (bars, getWFHProject) =>
    bars?.filter((bar) => bar.project_id === getWFHProject.id)
);

const getFormattedPTOs = createSelector(
  getPTOScheduleBars,
  (state) =>
    state?.reduce((res, item) => {
      const formattedBars = item.bars?.map((bar) => ({
        start: bar.start_date,
        end: bar.end_date,
        type: 'PTO',
        totalHours: Number(item.total_hours),
        dailyHours: Number(item.daily_hours),
        eventId: item.id,
        allDay: item.all_day
      }));
      return [...res, ...(formattedBars ?? emptyArray)];
    }, emptyArray) ?? emptyArray
);

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

const getFormattedHolidays = createSelector(
  getHolidaysArray,
  (state) =>
    state?.map((item) => ({
      eventId: item.id,
      start: formatDate(item.start_date),
      end: formatDate(item.end_date),
      type: 'holiday',
      allDay: true
    })) ?? emptyArray
);

const getFlatPTOs = createSelector(getScheduleBarsByMember, (state) => {
  return (
    state
      ?.map((PTO) =>
        PTO.bars.map((bar) =>
          Array.from(
            moment.range(bar.start_date, bar.end_date).by('days'),
            (m) => ({
              date: m.format('YYYY-MM-DD'),
              allDay: PTO.all_day,
              dailyHours: !!PTO.daily_hours && Number(PTO.daily_hours)
            })
          )
        )
      )
      .flat(2) ?? emptyArray
  );
});

const getFlatHolidays = createSelector(getHolidaysArray, (holidays) => {
  return (
    holidays
      ?.map((holiday) =>
        Array.from(
          moment.range(holiday.start_date, holiday.end_date).by('days'),
          (m) => ({
            date: m.format('YYYY-MM-DD')
          })
        )
      )
      .flat() ?? emptyArray
  );
});

const getTotalPTOByYear = createSelector(
  getFlatPTOs,
  getOwnCalendarYear,
  (state, year) => {
    return state.filter(({ date }) => moment(date).year() === year);
  }
);

export const getDefaultWorkSchedule = createSelector(
  getAccountCapacities,
  getOwnMemberId,
  (state, memberId) => {
    const defaultWorkSchedule = state[memberId];

    return defaultWorkSchedule || emptyObj;
  }
);

export const getTotalPlannedPTOHoursByYear = createSelector(
  getTotalPTOByYear,
  getDefaultWorkSchedule,
  (ptos, workschedule) => {
    if (!(ptos && workschedule && Object.keys(workschedule).length))
      return null;
    return ptos.reduce((res, { date, allDay, dailyHours }) => {
      if (allDay) {
        const dayOfWeek = moment(date).format('dddd').toLowerCase();
        const oneOffCapacityHours = workschedule.one_off_capacities[date];
        const defaultCapacityHours = workschedule[dayOfWeek];
        const capacityHours = oneOffCapacityHours ?? defaultCapacityHours ?? 0;

        return res + capacityHours;
      }

      return res + dailyHours;
    }, 0);
  }
);

const getTotalHolidayByYear = createSelector(
  getFlatHolidays,
  getOwnCalendarYear,
  (state, year) => {
    return state.filter(({ date }) => moment(date).year() === year);
  }
);

export const getTotalHolidayDaysByYear = createSelector(
  getTotalHolidayByYear,
  (state) => state.length
);

export const getBackgroundEvents = createSelector(
  getFormattedPTOs,
  getFormattedHolidays,
  (PTOS, holidays) => [...PTOS, ...holidays]
);
export const getTotalModifiedDaysByYear = createSelector(
  getDefaultWorkSchedule,
  getOwnCalendarYear,
  (defaultWorkschedule, year) => {
    if (!defaultWorkschedule?.one_off_capacities) return;
    return Object.keys(defaultWorkschedule.one_off_capacities).filter(
      (date) => moment(date).year() === year
    ).length;
  }
);

export const getTotalRemoteWorkDaysByYear = createSelector(
  getWFHScheduleBars,
  getOwnCalendarYear,
  (WFHSchedules, year) => {
    return WFHSchedules?.filter(
      ({ start_date: date }) => moment(date).year() === year
    ).length;
  }
);

export const getCalendarData = createSelector(
  getDefaultWorkSchedule,
  getWFHScheduleBars,
  (defaultWorkschedule, WFHSchedules) => {
    const data = new Map();
    defaultWorkschedule?.one_off_capacities &&
      Object.entries(defaultWorkschedule.one_off_capacities).forEach(
        ([date, capacity]) => {
          data.set(date, { ...(data.get(date) ?? {}), capacity });
        }
      );

    WFHSchedules?.forEach(({ id, start_date: startDate }) => {
      const date = moment(startDate).format('YYYY-MM-DD');
      data.set(date, {
        ...(data.get(date) ?? {}),
        isWFH: true,
        eventId: id,
        type: 'WFH'
      });
    });

    return Array.from(data, ([dateKey, value]) => ({
      date: dateKey,
      ...value
    }));
  }
);

export const makeGetSpentPTO = () => {
  const getUtilizationFilterState = makeGetUtilizationFilterState();

  return createSelector(
    getUtilizationFilterState,
    getOwnMemberId,
    (state, memberId) => {
      const memberUtilization = state.memberUtilizations[memberId];
      if (memberUtilization) {
        const { pto_hours: PTOHours } = memberUtilization.totals[0];
        return PTOHours;
      }
    }
  );
};
