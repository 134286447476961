import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addTrigger, removeTrigger } from 'actionCreators';
import { getShouldTrigger } from 'selectors';
import { useUnmount } from 'react-use';

// see reducers/trigger
// if running into problems, make sure to check memoization and correct props used
const useTrigger = ({
  triggerId,
  triggerIds,
  triggerAfter,
  conditions,
  conditionsByAction
}) => {
  const dispatch = useDispatch();
  const shouldTrigger = useSelector(getShouldTrigger);

  const conditionsEquality = useMemo(
    () => JSON.stringify(conditions),
    [conditions]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const conditionsMemo = useMemo(() => conditions, [conditionsEquality]);

  const conditionsByActionEquality = useMemo(
    () => JSON.stringify(conditionsByAction),
    [conditionsByAction]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const conditionsByActionMemo = useMemo(
    () => conditionsByAction,
    [conditionsByActionEquality]
  );

  useEffect(() => {
    if (triggerId || triggerIds) {
      dispatch(
        addTrigger({
          triggerId,
          triggerIds,
          triggerAfter,
          conditions: conditionsMemo,
          conditionsByAction: conditionsByActionMemo
        })
      );
    }
    return () => {
      dispatch(
        removeTrigger({
          triggerId,
          triggerIds
        })
      );
    };
  }, [
    dispatch,
    triggerAfter,
    triggerId,
    triggerIds,
    conditionsMemo,
    conditionsByActionMemo
  ]);

  useUnmount(() => {
    dispatch(
      removeTrigger({
        triggerId,
        triggerIds
      })
    );
  });

  return {
    shouldTrigger: triggerId ? shouldTrigger[triggerId] : shouldTrigger // send back the hash if more than one id given
  };
};

export default useTrigger;
