export const WS_ATTACHMENT = 'WS_ATTACHMENT';

export const SUBSCRIBE_ATTACHMENT = 'SUBSCRIBE_ATTACHMENT';
export const REMOVE_ATTACHMENT_SUBSCRIPTIONS =
  'REMOVE_ATTACHMENT_SUBSCRIPTIONS';

export const FILE_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
};
