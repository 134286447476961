import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
// import { getDashboardModule } from 'DashboardModule/package/dashboardModule';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getEventsModule } from 'EventsModule/package/eventsModule';

const PersonalDashboardPage = lazy(() => import('./PersonalDashboardPage'));

const LoadPersonalDashboardContainer = (props) => (
  <DynamicModuleLoader modules={[getCapacitiesModule(), getEventsModule()]}>
    <Suspense fallback={<div />}>
      <PersonalDashboardPage {...props} />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadPersonalDashboardContainer;
