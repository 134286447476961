import { useCallback, useMemo } from 'react';
import { buildRow } from 'appUtils/hooks/useTableBuilder/utils/buildFlatList';

const emptyArray = [];

const useStickyHeader = ({
  mainList,
  rows,
  customRowProps,
  stickySection,
  setStickySection,
  isDisabled
}) => {
  const handleScroll = useCallback(
    ({ visibleStartIndex }) => {
      const item = rows[visibleStartIndex];

      // stickyHeaderOffset may be required when there are multiple sticky headers, which
      // will make it seem like the top of the list is lower than it actually is
      const itemToUse = item?.stickyHeaderOffset
        ? rows[visibleStartIndex + item?.stickyHeaderOffset]
        : item;

      if (!itemToUse) {
        return;
      }
      if (itemToUse.sectionId && itemToUse.sectionId !== stickySection) {
        setStickySection(itemToUse.sectionId);
      }
    },
    [rows, setStickySection, stickySection]
  );

  const { stickyList, stickyList2 } = useMemo(() => {
    if (isDisabled || !stickySection) {
      return {
        stickyList: null,
        stickyList2: null
      };
    } else {
      const [stickySectionId, stickySectionId2] =
        stickySection.split(sectionIdDelimiter);

      const stickyList = mainList.listItems.find(
        (list) => list.sectionId === stickySectionId
      );

      const stickyList2 =
        stickySectionId2 && stickyList
          ? stickyList.listItems.find((list) => list.id === stickySectionId2)
          : null;

      return {
        stickyList,
        stickyList2
      };
    }
  }, [stickySection, mainList, isDisabled]);

  const { headerItem, headerItem2 } = useMemo(() => {
    return {
      headerItem:
        !isDisabled && stickyList?.isOpen
          ? buildRow({
              parentList: mainList,
              customRowProps,
              item: stickyList,
              isHeader: true,
              isStickyHeader: true
            })
          : null,
      headerItem2:
        !isDisabled && stickyList2?.isOpen
          ? buildRow({
              parentList: stickyList,
              customRowProps,
              item: stickyList2,
              isHeader: true,
              isStickyHeader: true
            })
          : null
    };
  }, [customRowProps, isDisabled, mainList, stickyList, stickyList2]);

  // to add sub sticky headers, add 'isStickyHeader' to the first listItems of the stickyList
  const subStickyLists = useMemo(() => {
    if (stickyList?.isOpen && !isDisabled) {
      const stickyLists = [];

      const listItemsToCheck = [
        ...(stickyList.customItems || emptyArray),
        ...stickyList.listItems
      ];

      for (const item of listItemsToCheck) {
        if (item?.isStickyHeader) {
          stickyLists.push(
            buildRow({
              parentList: stickyList,
              customRowProps,
              item,
              isHeader: true,
              isStickyHeader: true
            })
          );
        } else {
          break;
        }
      }

      return stickyLists;
    } else {
      return emptyArray;
    }
  }, [customRowProps, isDisabled, stickyList]);

  return {
    stickyList,
    handleScroll,
    headerItem,
    subStickyLists,
    headerItem2
  };
};

export default useStickyHeader;

const sectionIdDelimiter = '@@@';

export const serializeSectionIds = (sectionIds) =>
  sectionIds.join(sectionIdDelimiter);
