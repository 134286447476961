import React from 'react';
import { connect } from 'react-redux';
import { openWorkloadFilter, closeWorkloadFilter } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';

import OpenFilterMenuIcon from 'icons/OpenFilterMenuIcon';

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${theme.colors.colorRoyalBlue};
  text-align: center;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
  span {
    text-transform: capitalize;
  }
  // Keeps text in place on font-weight change
  span:after {
    display: block;
    content: attr(data-text);
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
  svg {
    margin-right: 5px;
    margin-bottom: 0px;
  }
`;

// Must have <Filter /> somewhere on the page
const OpenFilterOption = ({
  label,
  dataType,
  style,
  isOpen,
  openWorkloadFilter,
  closeWorkloadFilter,
  selectedItems,
  dataTestId = 'filter-select-button'
}) => {
  const handleClick = () => {
    if (isOpen) {
      closeWorkloadFilter();
    } else {
      openWorkloadFilter();
    }
  };

  const formattedDataType =
    selectedItems && selectedItems === 1
      ? dataType.slice(0, dataType.length - 1)
      : dataType;

  const formattedText = !selectedItems
    ? `Select ${formattedDataType || ''}`
    : `${selectedItems} ${formattedDataType}`;

  const text = label || formattedText;

  return (
    <StyledButton
      onClick={handleClick}
      data-testid={dataTestId}
      data-text={text}
      className="filter-select-button"
      style={style}
    >
      <OpenFilterMenuIcon />
      <span data-text={text}>{text}</span>
    </StyledButton>
  );
};

const mapStateToProps = (state) => ({
  isOpen: state.workloadPlannerFilter.isOpen
});

const mapDispatchToProps = {
  openWorkloadFilter,
  closeWorkloadFilter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OpenFilterOption));
