import React from 'react';
import { connect } from 'react-redux';
import {
  StyledColorCircleMemberInitials,
  StyledMemberInitial,
  SelectedColorBorder
} from './styles';
import { colorActions } from 'actionCreators';
import { makeGetPreference } from 'selectors';

class ColorPickerMemberInitial extends React.Component {
  // necessary due to updateTeam api call structure
  updateTeam = ({ color, id }) => {
    const { updateTeam } = this.props;
    const payload = {
      color,
      team: { id }
    };
    updateTeam(payload);
  };

  renderCheck = () => {
    const { preference, color, isDefaultColor } = this.props;
    return preference === color ||
      (isDefaultColor && preference === undefined) ? (
      <SelectedColorBorder $memberInitials />
    ) : null;
  };

  handleClick = () => {
    const { preference, color, id, isDefaultColor, handleSelect, apiCall } =
      this.props;
    const payload = { color, id };
    const api = apiCall;
    if (isDefaultColor && preference) {
      api.delete(payload);
    } else if (!isDefaultColor) {
      preference ? api.update(payload) : api.create(payload);
    }
    if (handleSelect) {
      handleSelect();
    }
  };

  render() {
    const { color, memberInitials } = this.props;
    return (
      <StyledColorCircleMemberInitials
        $color={color}
        className="color-picker-circle-member-initials"
        onClick={this.handleClick}
      >
        <StyledMemberInitial>{memberInitials}</StyledMemberInitial>
        {this.renderCheck()}
      </StyledColorCircleMemberInitials>
    );
  }
}
const makeMapStateToProps = () => {
  const getPreference = makeGetPreference();
  const mapStateToProps = (state, ownProps) => ({
    preference: getPreference(state, ownProps) || ownProps.preference // for items that carry own preference independent of userTheme
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  ...colorActions
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ColorPickerMemberInitial);
