import {
  BudgetType,
  BudgetTypeDropdown,
  BudgetTypeDropdownValue,
  BUDGET_TYPE_COPY
} from './BudgetTypeDropdown';
import { FullWidthSelectInput } from '../styles';
import { SelectDropdownProps } from 'RatesModule/components/SelectInput/SelectInput';
import {
  BudgetTrackingType,
  BudgetViewSettings
} from 'BudgetModule/models/BudgetViewSettings';
import { UpdateProjectBudgetViewSettings } from '../types';

export enum BudgetTypeVariant {
  ActivityPhase = 'activity_phase_default_budget_with',
  Phase = 'phase_default_budget_with'
}

export const BudgetTypeField = ({
  budgetViewSettings,
  updateBudgetViewSettings,
  variant
}: {
  variant: BudgetTypeVariant;
  budgetViewSettings: BudgetViewSettings;
  updateBudgetViewSettings: UpdateProjectBudgetViewSettings;
}) => {
  const budgetTracking = budgetViewSettings.budget_tracking_type;
  const budgetType: BudgetType = BudgetType.PercentOfFee;
  // const budgetType = budgetViewSettings[variant];
  const percentOfFee = 100;
  // const percentOfFee = budgetSetting[variant + '_percent'];

  const handleChange = (value: BudgetTypeDropdownValue) => {
    const type = value.type;
    const percentOfFee =
      value.type === BudgetType.PercentOfFee ? value?.percentOfFee : undefined;

    // updateBudgetViewSettings(
    //   variant === BudgetTypeVariant.ActivityPhase
    //     ? {
    //         activity_phase_default_budget_with: type,
    //         activity_phase_default_budget_with_percentage: percentOfFee
    //       }
    //     : {
    //         phase_default_budget_with: type,
    //         phase_default_budget_with_percentage: percentOfFee
    //       }
    // );
  };

  const dropdownRenderer = (
    props: SelectDropdownProps<BudgetTypeDropdownValue>
  ) => (
    <BudgetTypeDropdown
      isHours={budgetTracking === BudgetTrackingType.Hours}
      value={
        budgetType === BudgetType.PercentOfFee
          ? {
              type: budgetType,
              percentOfFee
            }
          : {
              type: budgetType
            }
      }
      {...props}
    />
  );

  return (
    <FullWidthSelectInput
      onSelect={handleChange}
      renderDropdown={dropdownRenderer}
    >
      {BUDGET_TYPE_COPY[budgetType].inputLabel(percentOfFee)}
    </FullWidthSelectInput>
  );
};
