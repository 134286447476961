import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MoveTasksToTopIcon = () => (
  <SvgIcon width="11" height="17" viewBox="0 0 11 17">
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.5 0.5L0.5 0.5"
        stroke="#0074D9"
        fillRule="nonzero"
        strokeLinecap="square"
      />
      <path
        fillRule="nonzero"
        d="M1.08701 8.8586C1.14147 8.93133 1.23653 8.96117 1.32145 8.9276L3.83573 7.92056L3.83573 15.3359C3.83573 15.4488 3.92803 15.542 4.03971 15.542H5.5476H7.05549C7.16624 15.542 7.25208 15.4487 7.25208 15.3359L7.25208 7.92056L9.76637 8.9276C9.85036 8.96116 9.94635 8.93226 10.0008 8.8586C10.0553 8.78587 10.0562 8.68703 10.0008 8.61431L5.54199 2.54199C5.50415 2.49164 5.43862 2.45807 5.37586 2.45807C5.31309 2.45807 5.25495 2.49164 5.2171 2.54199L1.08722 8.61431C1.05953 8.65067 1.04199 8.69263 1.04199 8.73646C1.04178 8.77934 1.06024 8.82223 1.08701 8.8586Z"
        fill="transparent"
        stroke="#0074D9"
      />
    </g>
  </SvgIcon>
);

export default MoveTasksToTopIcon;
