import { select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import { getAuthToken, getMyUserId } from 'selectors';

const {
  notificationsFetch,
  notificationsCountFetch,
  notificationUpdate,
  notificationsUpdate,
  readAllNotifications
} = entityActions;

export function* fetchNotifications(action) {
  const { user_id, offset, limit } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    notificationsFetch,
    api.getNotifications,
    undefined,
    [token, user_id, offset, limit],
    action
  );
}

export function* fetchNewNotificationsCount(action) {
  const token = yield select(getAuthToken);
  const { groupByProjects, accountId } = action.payload;
  if (token) {
    const user_id = yield select(getMyUserId);
    const { error, response } = yield fetchEntity(
      notificationsCountFetch,
      api.getNewNotificationsCount,
      undefined,
      [token, accountId || user_id, groupByProjects],
      action
    );
  }
}

export function* updateNotificationAsRead(action) {
  const { id, isRead } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(notificationUpdate, api.putNotification, [
    token,
    id,
    isRead
  ]);
}

export function* markAllNotificationsRead(action) {
  const token = yield select(getAuthToken);

  yield changeEntity(readAllNotifications, api.markAllNotificationsRead, [
    token
  ]);
}

export function* updateNotificationsAsOld(action) {
  const { notificationIds, isOld } = action.payload;
  const ids = Array.from(new Set(notificationIds));
  const token = yield select(getAuthToken);

  yield changeEntity(notificationsUpdate, api.putNotifications, [
    token,
    ids,
    isOld
  ]);
}
