import {
  updateUser,
  updateUserActionCreatorsMap
} from 'UsersModule/actionCreators';
import { fork, takeEvery, select } from 'redux-saga/effects';
import { changeEntity as entityHelper } from 'sagas/generics';
import { usersApi } from './api/api';
import { getAuthToken } from 'AuthenticationModule/selectors';

function* updateUserSaga(action: ReturnType<typeof updateUser>) {
  const { payload, meta } = action;
  const token = yield select(getAuthToken);
  const { response } = yield entityHelper(
    updateUserActionCreatorsMap,
    usersApi.updateUser,
    [token, payload],
    action
  );

  if (response && meta?.onSuccess) {
    meta.onSuccess();
  }
}
// @ts-expect-error broken types for current version of redux-saga
export const usersSagas = [fork(takeEvery, updateUser, updateUserSaga)];
