import styled from 'styled-components';
import CalendarTimePicker from './CalendarTimePicker';
import theme from 'theme';

const StyledTimePicker = styled(CalendarTimePicker)`
  z-index: 10000;
  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    display: none;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-size: 12px;
    cursor: pointer;
    border: none;
    padding: 0;
    margin-top: -2px;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    ::placeholder {
      color: ${theme.colors.colorMediumGray9};
    }

    color: ${theme.colors.colorMediumGray9};
  }
`;

export default StyledTimePicker;
