import styled from 'styled-components';
import HelpIcon from 'icons/HelpIcon';
import cn from 'classnames';
import {
  SelectInput,
  SelectInputVariant
} from 'RatesModule/components/SelectInput/SelectInput';

export const TextValue = styled.div.attrs(({ onClick }) => ({
  className: cn({ clickable: onClick })
}))`
  align-items: center;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  display: flex;
  font-size: 13px;
  height: ${({ theme }) => theme.sizes.inputHeight};
  padding: 0 11px;

  &.clickable {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }
`;

export const FullWidthSelectInput = styled(SelectInput).attrs({
  variant: SelectInputVariant.White
})`
  min-width: 100%;
  width: 220px;
`;

export const InlineHelpIcon = styled(HelpIcon).attrs(({ theme }) => ({
  circleColor: theme.colors.colorPaleGray10,
  fillColor: theme.colors.colorPaleGray10,
  height: 8,
  questionMarkColor: theme.colors.colorPureBlack,
  width: 8
}))`
  align-self: text-top;
  margin-left: 2px;
  vertical-align: text-top;
`;
