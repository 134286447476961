import React from 'react';
import SvgIcon from 'components/SvgIcon';

const UpdatesIcon = ({ height = '12', width = '14' }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 14 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72685 9.06063C6.09755 9.93296 5.04439 10.5511 3.56739 10.915C3.48926 10.9717 3.41114 11 3.30696 11C3.25488 11 3.20279 11 3.15071 10.9717C3.0205 10.915 2.91633 10.7451 2.91633 10.5752C3.04606 9.87776 3.10562 9.23966 3.09502 8.6609C1.85519 8.00906 1 6.62829 1 5.03031C1 2.80443 2.6593 1 4.70615 1H9.29385C11.3407 1 13 2.80443 13 5.03031C13 7.25619 11.3407 9.06063 9.29385 9.06063H6.72685Z"
        stroke="#828282"
        fill="transparent"
      />
    </SvgIcon>
  );
};

export default UpdatesIcon;
