import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import {
  GenerateTeamSamlInfoParams,
  GenerateTeamSamlInfoSuccessResponse,
  AssociateSamlIdentityParams,
  SetAuthMethodParams,
  ResetPasswordParams
} from './api/types';
import { ACTION_META_KEY } from 'appConstants/actions';
import { ValueOf } from 'type-fest';

/* ----------------------------------- generate team saml info ----------------------------------- */

const GENERATE_TEAM_SAML_INFO = createRequestTypes('GENERATE_TEAM_SAML_INFO');

export const generateTeamSamlInfo = createAction<GenerateTeamSamlInfoParams>(
  GENERATE_TEAM_SAML_INFO.TRIGGER
);

export const generateTeamSamlInfoActionCreatorsMap = createActionCreatorsMap<
  GenerateTeamSamlInfoParams,
  GenerateTeamSamlInfoSuccessResponse
>(GENERATE_TEAM_SAML_INFO);

/* ------------------------- associate saml identity ------------------------ */

const ASSOCIATE_SAML_IDENTITY = createRequestTypes('ASSOCIATE_SAML_IDENTITY');

export const associateSamlIdentity = createAction(
  ASSOCIATE_SAML_IDENTITY.TRIGGER,
  ({ meta, ...payload }: AssociateSamlIdentityInitialPayload) => ({
    meta,
    payload
  })
);

export const associateSamlIdentityActionCreatorsMap = createActionCreatorsMap<
  AssociateSamlIdentityParams,
  undefined,
  ValueOf<
    Pick<ReturnType<typeof associateSamlIdentity>, typeof ACTION_META_KEY>
  >
>(ASSOCIATE_SAML_IDENTITY);

type AssociateSamlIdentityInitialPayload = ActionParamsWithMeta<
  AssociateSamlIdentityParams,
  IMeta<() => void>
>;

/* ----------------------------- set auth method ---------------------------- */

const SET_AUTH_METHOD = createRequestTypes('SET_AUTH_METHOD');

export const setAuthMethod = createAction(
  SET_AUTH_METHOD.TRIGGER,
  ({ meta, ...payload }: SetAuthMethodInitialPayload) => ({ meta, payload })
);

export const setAuthMethodActionCreatorsMap = createActionCreatorsMap<
  SetAuthMethodInitialPayload,
  undefined,
  ValueOf<Pick<ReturnType<typeof setAuthMethod>, typeof ACTION_META_KEY>>
>(SET_AUTH_METHOD);

type SetAuthMethodInitialPayload = ActionParamsWithMeta<
  SetAuthMethodParams,
  IMeta<() => void>
>;

/* ------------------------ reset password ----------------------- */

const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD');

type ResetPasswordInitialPayload = ActionParamsWithMeta<ResetPasswordParams>;

export const resetPassword = createAction(
  RESET_PASSWORD.TRIGGER,
  ({ meta, ...payload }: ResetPasswordInitialPayload) => ({
    meta,
    payload
  })
);

export const resetPasswordActionCreatorsMap =
  createActionCreatorsMap<ResetPasswordParams>(RESET_PASSWORD);

/* ------------------ set auth token fetch success details ------------------ */

interface SetAuthTokenFetchSuccessDetailsParams {
  token: string;
  isImpersonating: boolean;
  realmId: Nullable<string>;
  skipUpdateAPIClients?: boolean;
}

export const setAuthTokenFetchSuccessDetails =
  createAction<SetAuthTokenFetchSuccessDetailsParams>(
    'SET_AUTH_TOKEN_FETCH_SUCCESS_DETAILS'
  );

/* -------------------------- set is accepted terms ------------------------- */

export const setIsAcceptedTerms = createAction<boolean>(
  'SET_IS_ACCEPTED_TERMS'
);
