import styled from 'styled-components';
import theme from 'theme';
import type { AccessLevelColumn } from '../types';

export const AccessLevelColumnHeaderCell = ({
  column
}: {
  column: AccessLevelColumn;
}) => {
  const { accessLevel } = column;

  return (
    <StyledAccessLevelColumnHeaderCell>
      {accessLevel}
    </StyledAccessLevelColumnHeaderCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledAccessLevelColumnHeaderCell = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 4px;
  display: grid;
  align-items: end;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  color: ${theme.colors.colorCalendarGray};
  text-transform: uppercase;
`;
