import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ActivityFeed, BodyColor } from 'views';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import AddEditNoteContainer from 'NoteModule/components/AddEditNoteContainer';

import { getNotesModule } from 'NoteModule/package/noteModule';
import { fetchActivityLogUserActivities } from 'actionCreators';

import {
  getUserActivitiesByDate,
  getMatchedRouteParams,
  getUserActivitiesOffset
} from 'selectors';
import WorkloadEventsModal from 'views/projectPlanner/WorkloadEventsModal/WorkloadEventsModal';

const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 650px;
`;

const noop = () => {};
const makeNoop = () => noop;
class MemberActivityFeedContainer extends React.Component {
  componentDidMount() {
    const { fetchActivityLogUserActivities, matchedParams } = this.props;
    const accountId = matchedParams?.memberId;
    if (accountId) {
      fetchActivityLogUserActivities({
        end_date: moment().add('days', 1).format('YYYY-MM-DD'),
        offset: 0,
        limit: 100,
        actor_ids: [accountId],
        initial: true,
        filter: 'member_profile'
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchActivityLogUserActivities, matchedParams } = this.props;
    const accountId = matchedParams?.memberId;
    if (prevProps.matchedParams.memberId !== accountId) {
      fetchActivityLogUserActivities({
        end_date: moment().add('days', 1).format('YYYY-MM-DD'),
        offset: 0,
        limit: 100,
        actor_ids: [accountId],
        initial: true,
        filter: 'member_profile'
      });
    }
  }

  lazyLoad = (waypointEvent) => {
    const {
      fetchActivityLogUserActivities,
      isLoading,
      matchedParams,
      userActivitiesOffset
    } = this.props;
    const accountId = matchedParams?.memberId;
    if (!isLoading && accountId) {
      fetchActivityLogUserActivities({
        end_date: moment().add('days', 1).format('YYYY-MM-DD'),
        offset: userActivitiesOffset,
        limit: 100,
        actor_ids: [accountId],
        initial: false,
        filter: 'member_profile'
      });
    }
  };

  render() {
    const { isLoading, children, userActivitiesByDate } = this.props;

    return (
      <ContainerDiv>
        <BodyColor isGray />
        <div
          className="dummy-activity-animation-container"
          id="activity-animation"
        />
        <ActivityFeed
          accountId={this.props.matchedParams?.memberId}
          allNotifications={userActivitiesByDate}
          isLoading={isLoading}
          markAsRead={makeNoop}
          lazyLoad={this.lazyLoad}
          markAllRead={noop}
          isActivityMemberModal
        />
        {children}
        <DynamicModuleLoader modules={[getNotesModule()]}>
          <AddEditNoteContainer />
        </DynamicModuleLoader>
        <WorkloadEventsModal selectionDisabled />
      </ContainerDiv>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.notifications.isLoading,
  matchedParams: getMatchedRouteParams(state),
  userActivitiesByDate: getUserActivitiesByDate(state),
  userActivitiesOffset: getUserActivitiesOffset(state)
});

const mapDispatchToProps = {
  fetchActivityLogUserActivities
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberActivityFeedContainer)
);
