import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  getIsFetchingAllProjects,
  getNumberOfAllProjects,
  getAllFetchedProjectsLength,
  makeSafeGetProjectAssignmentProjects,
  getProjectHash
} from 'selectors';
import { updateAccountFilterLocal, fetchAllProjects } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import {
  StyledProjectTitle,
  StyledProjectDescription,
  StyledProjectNumber,
  StyledFolderIcon,
  StyledTeamName,
  TimesheetTopCell
} from 'components/ProjectsAndPhasesDropdown/styles';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle
} from 'components/BatchActions/styles';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import Row from 'components/ProjectsAndPhasesDropdown/Row';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${StyledSelectToggleContainer} {
    margin-left: 10px;
  }
`;

const ProjectListItem = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: 100%;
  flex: 1;
  min-width: 0;
  padding-top: 4px;

  ${StyledProjectTitle} {
    font-size: 12px;
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 600;
    ${(props) => props.hasDescription && 'flex-shrink: 0;'}
  }
  ${StyledTeamName} {
    font-size: 10px;
    color: ${theme.colors.colorBudgetGrey};
  }
  ${StyledProjectNumber} {
    font-size: 9px;
    color: ${theme.colors.colorMediumGray5};
  }
  ${StyledFolderIcon} {
    width: 9px;
  }
  ${StyledProjectDescription} {
    &::before {
      content: ' - ';
    }
  }
  ${TimesheetTopCell} {
    margin-top: 10px;
  }
`;

const copy = {
  searchPlaceholder: 'Type project or select below'
};

const ProjectsFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  projects,
  isFetching,
  fetchAllProjects,
  fetchedProjectsCount,
  totalCount,
  projectHash,
  isHidden
}) => {
  const [projectsToFetchOnMount] = useState(activeFilter.project_ids);
  useFetchUnloadedProjects({
    projectIds: projectsToFetchOnMount,
    shouldFetchPhases: false
  });

  const setFilterItems = (nextProjects) => {
    updateAccountFilterLocal({
      ...activeFilter,
      project_ids: nextProjects
    });
  };

  const items = useMemo(
    () => projects.map((project) => project.id),
    [projects]
  );

  const loadMoreItems = useCallback(
    ({ search }) => {
      if (!isFetching) {
        fetchAllProjects({
          searchText: search,
          offset: fetchedProjectsCount,
          limit: 30
        });
      }
    },
    [fetchAllProjects, fetchedProjectsCount, isFetching]
  );

  const loadInitialItems = useCallback(
    ({ search }) => {
      fetchAllProjects({
        searchText: search,
        offset: 0,
        limit: 30
      });
    },
    [fetchAllProjects]
  );

  const hasNextPage = () => {
    return fetchedProjectsCount < totalCount;
  };

  const renderItem = (projectId, isSelected) => {
    const project = projectHash[projectId];
    if (!project) return null;
    return (
      <RowContainer>
        <StyledSelectToggleContainer>
          <StyledSelectToggle isChecked={isSelected} size={14} innerSize={10} />
        </StyledSelectToggleContainer>
        <ProjectListItem hasDescription={!!project.description}>
          <Row item={project} />
        </ProjectListItem>
      </RowContainer>
    );
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={items}
      copy={copy}
      batchClearOnly
      initialSelectedItems={activeFilter.project_ids}
      setFilterItems={setFilterItems}
      itemHash={projectHash}
      filterKeys={['title', 'project_number']}
      toggleLabel="Project"
      renderItem={renderItem}
      loadMoreItems={loadMoreItems}
      loadInitialItems={loadInitialItems}
      hasNextPage={hasNextPage}
      itemHeight={55}
      listHeight={220}
      listItemContainerStyle={{}}
      filterId={activeFilter.id}
    />
  );
};

const makeMapStateToProps = () => {
  const getSafeProjectAssignmentProjects =
    makeSafeGetProjectAssignmentProjects();
  const mapStateToProps = (state, ownProps) => ({
    projects: getSafeProjectAssignmentProjects(state, ownProps),
    isFetching: getIsFetchingAllProjects(state),
    totalCount: getNumberOfAllProjects(state),
    fetchedProjectsCount: getAllFetchedProjectsLength(state),
    projectHash: getProjectHash(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal,
  fetchAllProjects
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ProjectsFilterDropdown);
