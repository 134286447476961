import { reducerMap } from '../reducers';
import requirementsSaga from '../sagas';

export function getRequirementsModule() {
  return {
    id: 'requirements',
    reducerMap,
    sagas: [requirementsSaga]
  };
}
