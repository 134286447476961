import * as constants from 'appConstants';
import { createAction } from '@reduxjs/toolkit';
import { EntityType } from 'models/entity';
export const fetchEntityComments =
  createAction<FetchEntityCommentsInitialParams>(
    constants.FETCH_ENTITY_COMMENTS.TRIGGER
  );

export const createEntityComment =
  createAction<CreateEntityCommentInitialParams>(
    constants.CREATE_ENTITY_COMMENT.TRIGGER
  );
export const updateEntityComment =
  createAction<UpdateEntityCommentInitialParams>(
    constants.UPDATE_ENTITY_COMMENT.TRIGGER
  );
export const deleteEntityComment =
  createAction<DeleteEntityCommentInitialParams>(
    constants.DELETE_ENTITY_COMMENT.TRIGGER
  );

interface FetchEntityCommentsInitialParams {
  parentEntityType: EntityType;
  parentEntityId: number;
  limit: number;
  offset: number;
}

interface CreateEntityCommentInitialParams {
  parentEntityType: EntityType;
  parentEntityId: number;
  parentCommentId?: number;
  description: string;
  membersToNotify?: Array<any>;
  files?: Array<File>;
}

interface UpdateEntityCommentInitialParams {
  id: number;
  description: string;
  files?: Array<File>;
}

interface DeleteEntityCommentInitialParams {
  id: number;
  parentCommentId?: number;
}
