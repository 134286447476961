import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TaskStatusIcon = ({ width = '11', height = '11', className }) => (
  <SvgIcon
    className={className}
    viewBox="0 0 11 11"
    fill="none"
    width={width}
    height={height}
  >
    <circle cx="5.5" cy="5.5" r="5" stroke="#808080" />
  </SvgIcon>
);

export default TaskStatusIcon;
