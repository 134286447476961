import {
  fetchTeamBudgetViewSettings,
  updateTeamBudgetViewSettings
} from 'BudgetModule/actionCreators';
import { DEFAULT_BUDGET_VIEW_SETTINGS } from 'BudgetModule/constants/budgetSettings';
import {
  BudgetTrackingType,
  BudgetViewSettings,
  BudgetViewSettingsType
} from 'BudgetModule/models/BudgetViewSettings';
import { getTeamBudgetSettingsHash } from 'BudgetModule/selectors/budgetSettings';
import FetchManager from 'classes/FetchManager';
import { useCallback, useEffect, useMemo } from 'react';
import { useUnmount } from 'react-use';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSplitFlags } from 'selectors';
import { updateTeamViewSettings } from 'TeamsModule/actionCreators';
import { TeamId } from 'TeamsModule/models/team';
import {
  getSelectedTeamId,
  getSelectedTeamViewSettings
} from 'TeamsModule/selectors';
import { v4 as uuid } from 'uuid';

const DEFAULT_TEAM_BUDGET_VIEW_SETTINGS: BudgetViewSettings = {
  ...DEFAULT_BUDGET_VIEW_SETTINGS,
  id: 0,
  viewable_id: 0,
  viewable_type: BudgetViewSettingsType.Team
};

export const useTeamBudgetViewSettings = (
  teamId?: TeamId
): {
  budgetViewSettings: BudgetViewSettings;
  isHoursOnly: boolean;
  updateBudgetViewSettings: (
    params: Parameters<typeof updateTeamBudgetViewSettings>[0]['body']
  ) => void;
} => {
  const dispatch = useAppDispatch();
  const { hoursOnlyFlag } = useAppSelector(getSplitFlags);

  const selectedTeamId = useAppSelector(getSelectedTeamId);
  const teamBudgetViewSettingsHash = useAppSelector(getTeamBudgetSettingsHash);
  const teamViewSettings = useAppSelector(getSelectedTeamViewSettings);

  const teamIdToUse = teamId ?? selectedTeamId;
  const teamBudgetViewSettings = teamBudgetViewSettingsHash[teamIdToUse ?? -1];

  // Currently using `TeamViewSettings.is_hours_only` for budget tracking.
  // Will be removed in
  // https://mosaic-ai.atlassian.net/browse/WEB-4480
  //
  // When removed, the `useMemo` may also be removed.
  const modifiedTeamBudgetViewSettings: BudgetViewSettings = useMemo(
    () =>
      teamBudgetViewSettings
        ? {
            ...teamBudgetViewSettings,
            budget_tracking_type:
              teamViewSettings?.is_hours_only && hoursOnlyFlag
                ? BudgetTrackingType.Hours
                : BudgetTrackingType.Currency
          }
        : DEFAULT_TEAM_BUDGET_VIEW_SETTINGS,
    [hoursOnlyFlag, teamBudgetViewSettings, teamViewSettings?.is_hours_only]
  );

  // Cleanup on unmount.
  const abortId = useMemo(() => `useTeamBudgetViewSettings>${uuid()}`, []);
  useUnmount(() => FetchManager.abort(abortId));

  useEffect(() => {
    if (!teamBudgetViewSettings && teamIdToUse)
      dispatch(
        fetchTeamBudgetViewSettings({ teamId: teamIdToUse, meta: { abortId } })
      );
  }, [abortId, dispatch, teamIdToUse, teamBudgetViewSettings]);

  const updateBudgetViewSettings = useCallback(
    (
      updatedSettings: Parameters<
        typeof updateTeamBudgetViewSettings
      >[0]['body']
    ) => {
      if (teamIdToUse !== undefined) {
        dispatch(
          updateTeamBudgetViewSettings({
            teamId: teamIdToUse,
            body: updatedSettings
          })
        );

        // Make a request to both team view settings endpoints. Currently using
        // `TeamViewSettings.is_hours_only` for budget tracking. Will be removed
        // in https://mosaic-ai.atlassian.net/browse/WEB-4480
        if ('budget_tracking_type' in updatedSettings)
          dispatch(
            updateTeamViewSettings({
              viewSettings: {
                is_hours_only:
                  updatedSettings.budget_tracking_type ===
                  BudgetTrackingType.Hours
              },
              teamId: teamIdToUse
            })
          );
      }
    },
    [dispatch, teamIdToUse]
  );

  return {
    budgetViewSettings: modifiedTeamBudgetViewSettings,
    isHoursOnly:
      modifiedTeamBudgetViewSettings.budget_tracking_type ===
      BudgetTrackingType.Hours,
    updateBudgetViewSettings
  };
};
