import { useCallback } from 'react';
import {
  getProjectIdsThatMatchTitleAndNumber,
  getRowValuesForEntityType
} from './utils';

export const useActivityOptionsGetter = ({
  existingActivities,
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  existingPhasesGroupedByName,
  existingActivitiesById,
  newActivityTitles,
  entityType
}) => {
  return useCallback(
    (row) =>
      getActivityOptions({
        row,
        existingActivities,
        existingProjectsHashByTitle,
        existingProjectsHashByNumber,
        existingPhasesGroupedByName,
        existingActivitiesById,
        newActivityTitles,
        entityType
      }),
    [
      existingActivities,
      existingActivitiesById,
      existingPhasesGroupedByName,
      existingProjectsHashByNumber,
      existingProjectsHashByTitle,
      newActivityTitles,
      entityType
    ]
  );
};

/* ------------------------------------ - ----------------------------------- */

export const getActivityOptions = ({
  row,
  existingActivities,
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  existingPhasesGroupedByName,
  existingActivitiesById,
  newActivityTitles,
  entityType
}) => {
  const { enteredProjectTitle, enteredProjectNumber, enteredPhaseName } =
    getRowValuesForEntityType(row, entityType);

  if (!enteredProjectTitle || !enteredProjectNumber) {
    return [];
  }

  const projectIdsThatMatchTitleAndNumber =
    getProjectIdsThatMatchTitleAndNumber({
      existingProjectsHashByTitle,
      existingProjectsHashByNumber,
      enteredProjectTitle,
      enteredProjectNumber
    });

  // in case of empty entered phase name, we want to treat it as a default phase, thus name with 'Default 1'
  const matchingPhasesByEnteredPhaseName =
    existingPhasesGroupedByName[enteredPhaseName || 'Default 1'];
  const matchingPhase = matchingPhasesByEnteredPhaseName?.find(
    (phase) => projectIdsThatMatchTitleAndNumber[phase.project_id]
  );

  if (!matchingPhase || !matchingPhase.activity_order) {
    // no matching phase (new project title, project number, phase combination): show activity
    // titles that have been used for other rows with this same combination of project title,
    // project number and phase name + existing activities
    return Array.from(
      new Set([
        '',
        ...Object.keys(
          newActivityTitles.current[enteredProjectTitle]?.[
            enteredProjectNumber
          ]?.[enteredPhaseName] || {}
        ),
        ...existingActivities
          .map((activity) => activity.title)
          .filter((title) => title)
      ])
    );
  }

  // matching phase with no work category under it, show all the existing work category titles,
  // include '' so the user can fall back to the default work category, incase he already entered
  // something in the csv file and wants to change.
  if (!matchingPhase.activity_order.length) {
    // no restrictions, show existing activities
    return [
      '',
      ...existingActivities
        .map((activity) => activity.title)
        .filter((title) => title)
    ];
  }

  // restrict to activities that are in the matching phase
  return matchingPhase.activity_order
    .map((id) => existingActivitiesById[id]?.title)
    .filter((title) => title);
};
