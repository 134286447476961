import React from 'react';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import theme from 'theme';

import {
  getSelectedTeamId,
  getOrderedNonBillableActivities,
  getOrderedBillableActivities,
  getOrderedArchivedActivities,
  getAllActivityRowInfo,
  getAllCustomActivities
} from 'selectors';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';

import { updatePhase, openActivityPhaseModal } from 'actionCreators';
import {
  openActivityDropdown,
  openActivityModal
} from 'BudgetModule/actionCreators';
import {
  StyledPhasesOfWork,
  StyledPhaseState,
  StyledPhaseName,
  AddedText,
  RemoveText,
  DotsButtonContainer,
  MenuItem,
  CloseIconContainer,
  StyledDoneButton,
  StandardPhaseDotContainer,
  AddText
} from './styles';
import Popover from 'components/Popover';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';

import { filterItemWithWhiteSpace } from 'appUtils/search';

const SmallDiamondIconContainer = styled.span`
  padding-right: 5px;
`;

const Footer = ({}) => (
  <LockWithTooltip
    text={`Manage Standard Work Categories`}
    tooltipContent={`Only Admins and Financial</br>Managers can Add or Edit`}
  />
);

const StandardLabel = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 11px;
  color: ${theme.colors.colorLightGray15};
`;

const copy = {
  headerInitial: 'Add Work Category',
  headerEdit: 'Add Phase',
  headerAdd: 'New Phase',
  sticky: 'Add New Phase',
  footerInitial: <Footer />,
  footerEdit: <Footer />,
  addConfirm: 'Add',
  editConfirm: 'Save',
  searchPlaceholder: 'Type name or select below'
};

const listItemContainerStyle = {
  'border-bottom': '0px;'
};

/** Used to modify the phase list for a project. */

const customDivider = {
  id: 'customDivider',
  name: 'Custom',
  isCustomDivider: true
};

const addCustomRow = {
  id: 'addCustomRow',
  name: 'Add Custom Work Category',
  isAddCustomRow: true
};

class BulkActivities extends React.Component {
  state = {
    addedActivityIds: this.props.phase?.activity_order ?? [],
    openActionDropdownItemId: false
  };

  componentDidMount() {
    const { teamId } = this.props;
  }

  componentDidUpdate(prevProps, prevState) {
    const { teamId, phase } = this.props;
    if (prevProps.phase !== phase) {
      this.setState({ addedActivityIds: phase?.activity_order });
    }
    if (prevProps.teamId !== teamId && teamId) {
    }
  }

  getPermissions = () => {
    return {
      teamId: this.props.teamId
    };
  };

  toggleActionDropdown = (id) =>
    this.setState((prevState) => ({
      openActionDropdownItemId:
        prevState.openActionDropdownItemId === id ? null : id
    }));

  closeActionDropdown = () => {
    this.setState({ openActionDropdownItemId: null });
  };

  renderItem = ({ item, selectCallback }) => {
    const { phase } = this.props;
    const { addedActivityIds } = this.state;
    const isSelected = addedActivityIds.includes(item.id);
    const isArchived = item.archived;

    const isAdded = isSelected && !phase?.activity_order?.includes(item.id);

    if (item.isAddCustomRow) {
      return (
        <StyledPhasesOfWork onClick={this.handleOpenCreateActivityModal}>
          <StyledPhaseName isAdded={isSelected} style={{ color: '#0074d9' }}>
            {item.name}
            <StandardLabel>STANDARD</StandardLabel>
          </StyledPhaseName>
        </StyledPhasesOfWork>
      );
    }
    if (item.isCustomDivider) {
      return (
        <StyledPhasesOfWork>
          <StyledPhaseName style={{ color: '#0074d9' }}>
            <StandardLabel>CUSTOM</StandardLabel>
          </StyledPhaseName>
        </StyledPhasesOfWork>
      );
    }
    if (isAdded) {
      return (
        <StyledPhasesOfWork onClick={this.closeActionDropdown}>
          <StyledPhaseName isArchived={isArchived}>
            {item?.title}
          </StyledPhaseName>
          <StyledPhaseState isArchived={isArchived}>
            <AddedText>Added</AddedText>
            <RemoveText>Remove</RemoveText>
          </StyledPhaseState>
        </StyledPhasesOfWork>
      );
    }

    return (
      <StyledPhasesOfWork
        onClick={this.closeActionDropdown}
        isInEditMode={this.state.openActionDropdownItemId === item.id}
      >
        <StyledPhaseName isArchived={isArchived}>
          {' '}
          {!isAdded && !item.isAddCustomRow && (
            <SmallDiamondIconContainer>
              <SmallDiamondIcon />
            </SmallDiamondIconContainer>
          )}
          {item?.title}
        </StyledPhaseName>
        <StyledPhaseState isArchived={isArchived}>
          {isSelected ? (
            <StandardPhaseDotContainer>
              {this.renderDots(item, isArchived)}
            </StandardPhaseDotContainer>
          ) : item.archived ? (
            'Archived'
          ) : (
            <AddText>Add</AddText>
          )}
        </StyledPhaseState>
      </StyledPhasesOfWork>
    );
  };

  renderDots = (item, isArchived) => {
    const { id } = item;
    const { openActionDropdownItemId } = this.state;
    return (
      <div
        style={{ position: 'relative' }}
        ref={(ref) => (this[`popoverTarget-${id}`] = ref)}
        onClick={(e) => {
          e.stopPropagation();
          this.toggleActionDropdown(id);
        }}
      >
        <ProjectThreeDotMenu
          align="right"
          onCloseCallback={this.closeActionDropdown}
        >
          {openActionDropdownItemId === id && (
            <Popover
              isOpen
              target={this[`popoverTarget-${id}`] || null}
              closePopover={this.closeActionDropdown}
              boundariesElement={'scrollParent'}
              stopPropagation
            >
              <DotsButtonContainer onClick={this.handleClose}>
                <MenuItem onClick={() => this.removeActivity(item)}>
                  Remove
                </MenuItem>
              </DotsButtonContainer>
            </Popover>
          )}
        </ProjectThreeDotMenu>
      </div>
    );
  };

  removeActivity = (item) => {
    const { openActivityPhaseModal, phase } = this.props;

    openActivityPhaseModal({
      phaseId: phase.id,
      activityId: item.id
    });
  };

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();

    const { id } = item;

    const isSelected = this.props.phase?.activity_order?.includes(id);
    const isAdded = this.state.addedActivityIds.includes(id);
    const isNewlyAdded = !isSelected && isAdded;

    if (
      item.archived ||
      isSelected ||
      item.isAddCustomRow ||
      item.isCustomDivider
    ) {
      return;
    }
    if (isNewlyAdded) {
      this.setState({
        addedActivityIds: this.state.addedActivityIds.filter(
          (activityId) => activityId !== id
        )
      });
    } else {
      this.setState({ addedActivityIds: [id, ...this.state.addedActivityIds] });
    }
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['title']
    });

  reorder = (newOrder) => {
    this.setState({ addedActivityIds: newOrder });
  };

  isItemDraggable = (item) => {
    if (item.isAddCustomRow) return false;
    return this.state.addedActivityIds.includes(item?.id);
  };

  onFooterClick = () => {
    const { openActivityDropdown } = this.props;
    const permissions = this.getPermissions();
    openActivityDropdown({ permissions });
    this.handleClose();
  };

  handleClose = () => {
    const { handleClose } = this.props;
    if (handleClose) {
      handleClose();
    }
  };

  handleDone = () => {
    const { phase, updatePhase } = this.props;
    const { addedActivityIds } = this.state;
    updatePhase({
      activityOrder: uniq(addedActivityIds),
      id: phase.id,
      projectId: phase.project_id
    });

    this.handleClose();
  };

  renderHeader = () => {
    const { renderHeader, renderHeaderCopy } = this.props;
    return renderHeader ? renderHeader(renderHeaderCopy) : undefined;
  };

  hasRenderHeader = () => !!this.props.renderHeader;

  getOrderableActivities = () => {
    const { activitiesHash } = this.props;
    const { addedActivityIds } = this.state;

    const added = addedActivityIds.map((id) => activitiesHash[id]);

    return added.filter((activity) => !!activity);
  };

  getNonOrderableActivities = () => {
    const {
      billableActivities,
      nonBillableActivities,
      archivedActivities,
      customActivities
    } = this.props;

    const { addedActivityIds } = this.state;

    const addedIdsHash = keyBy(addedActivityIds);

    const billableMinusAdded = billableActivities.filter(
      (activity) => !addedIdsHash[activity.id]
    );
    const nonBillableMinusAdded = nonBillableActivities.filter(
      (activity) => !addedIdsHash[activity.id]
    );
    const customMinusAdded = customActivities.filter(
      (activity) => !addedIdsHash[activity.id]
    );
    const archived = archivedActivities.filter(
      (activity) => !addedIdsHash[activity.id]
    );

    const items = [
      ...billableMinusAdded,
      ...nonBillableMinusAdded,
      ...archived
    ].filter((activity) => !!activity);

    return [addCustomRow, ...items, customDivider, ...customMinusAdded];
  };

  renderHeaderButton = () => {
    return (
      <CloseIconContainer>
        <StyledDoneButton className="add-button" onClick={this.handleDone}>
          Done
        </StyledDoneButton>
      </CloseIconContainer>
    );
  };

  handleOpenCreateActivityModal = () => {
    const { openActivityModal } = this.props;
    openActivityModal({ isCustomActivityModal: true });
  };

  render() {
    const { handleClose, target, isAllPhases, className = '' } = this.props;
    const orderableActivities = this.getOrderableActivities();
    const nonOrderableActivities = this.getNonOrderableActivities();
    const items = [...orderableActivities, ...nonOrderableActivities];

    return (
      <>
        <MultiStepFlyout
          copy={copy}
          target={target}
          items={items}
          orderableItems={this.getOrderableActivities()}
          idKey="id"
          renderHeaderButton={this.renderHeaderButton}
          hideEditIcon
          renderItem={this.renderItem}
          handleSelect={this.handleSelect}
          isWhite={true}
          itemFilter={this.itemFilter}
          reorder={this.reorder}
          handleClose={handleClose}
          dragEnabled={!isAllPhases}
          isItemDraggable={this.isItemDraggable}
          searchEnabled
          editMode
          onFooterClick={this.onFooterClick}
          phases={true}
          renderHeader={this.hasRenderHeader() ? this.renderHeader : undefined}
          listItemContainerStyle={listItemContainerStyle}
          popoverClassName={`add-standard-phases-flyout ${className}`}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customActivities: getAllCustomActivities(state),
  teamId: getSelectedTeamId(state),
  activitiesHash: getAllActivityRowInfo(state),
  nonBillableActivities: getOrderedNonBillableActivities(state),
  billableActivities: getOrderedBillableActivities(state),
  archivedActivities: getOrderedArchivedActivities(state)
});

const mapDispatchToProps = {
  updatePhase,
  openActivityPhaseModal,
  openActivityDropdown,
  openActivityModal
};

/* Used to modify the list of phases for a project. */
export default connect(mapStateToProps, mapDispatchToProps)(BulkActivities);
