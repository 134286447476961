import React from 'react';
import cn from 'classnames';

class TabNavigator extends React.Component {
  render() {
    const { displayValues, currentView, handleChange } = this.props;

    return (
      <div
        className={cn('status-menu', {
          'add-margin': currentView === 'Tasks' || currentView === 'Planner'
        })}
      >
        {Object.keys(displayValues).map((key) => {
          return (
            <div
              className={cn('status-type list', {
                selected: currentView === key
              })}
              onClick={() => handleChange(key)}
              key={`navigator ${key}`}
            >
              {displayValues[key]}
            </div>
          );
        })}
      </div>
    );
  }
}

export default TabNavigator;
