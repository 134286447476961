export const MEMBER_DISPLAY_TABS = {
  OVERVIEW: 'overview',
  PROFILE: 'profile',
  TASKS: 'tasks',
  ACTIVITY: 'activity',
  SCHEDULE: 'schedule',
  WORKPLANS: 'workplans',
  PROJECTS: 'projects',
  CALENDAR: 'calendar',
  CHECK_IN: 'check-in'
};

export const MEMBER_DISPLAY_SIDE_MENU = [
  {
    name: MEMBER_DISPLAY_TABS.OVERVIEW,
    title: 'Overview',
    to: MEMBER_DISPLAY_TABS.OVERVIEW
  },
  {
    name: MEMBER_DISPLAY_TABS.PROFILE,
    title: 'Profile',
    to: MEMBER_DISPLAY_TABS.PROFILE
  },
  {
    name: MEMBER_DISPLAY_TABS.TASKS,
    title: 'Tasks',
    to: MEMBER_DISPLAY_TABS.TASKS
  },
  {
    name: MEMBER_DISPLAY_TABS.ACTIVITY,
    title: 'Activity',
    to: MEMBER_DISPLAY_TABS.ACTIVITY
  },
  {
    name: MEMBER_DISPLAY_TABS.SCHEDULE,
    title: 'Schedule',
    to: MEMBER_DISPLAY_TABS.SCHEDULE
  },
  {
    name: MEMBER_DISPLAY_TABS.WORKPLANS,
    title: 'Work Plans',
    to: MEMBER_DISPLAY_TABS.WORKPLANS
  },
  {
    name: MEMBER_DISPLAY_TABS.CHECK_IN,
    title: 'Check-in',
    to: MEMBER_DISPLAY_TABS.CHECK_IN
  },
  {
    name: MEMBER_DISPLAY_TABS.PROJECTS,
    title: 'Projects',
    to: MEMBER_DISPLAY_TABS.PROJECTS
  },
  {
    name: MEMBER_DISPLAY_TABS.CALENDAR,
    title: 'Calendar',
    to: MEMBER_DISPLAY_TABS.CALENDAR
  }
];

export const PROJECT = 'Project';
export const PORTFOLIO = 'Portfolio';
