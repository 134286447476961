import { reducerMap } from '../reducers';
import quickbooksSaga from '../sagas';

export function getQuickbooksModule() {
  return {
    id: 'quickbooks',
    reducerMap,
    sagas: [quickbooksSaga]
    // Actions to fire when this module is added/removed
    //initialActions: []
    // finalActions: []
  };
}
