import React from 'react';
import styled from 'styled-components';
import bellIcon from 'images/bell-icon.svg';
import bellIconHovered from 'images/bell-icon-unread.svg';
import theme from 'theme';

const StyledActivityBell = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: solid 1px transparent;
  background: url(${bellIcon}) no-repeat center;
  color: ${theme.colors.colorSemiDarkGray1};
  pointer-events: initial;
  cursor: pointer;
  position: relative;
  transition: all 0.275s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  &:hover {
    background: url(${bellIconHovered}) no-repeat center;
    background-color: rgba(0, 0, 0, 0.03);
    border: solid 1px transparent;
  }
`;

const NewNotificationsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.colorCalendarRed};
  color: ${theme.colors.colorPureWhite};
  height: 20px;
  border-radius: 50%;
  width: 20px;
  font-size: 10px;
  font-weight: 800;
  position: absolute;
  top: -5px;
  right: -5px;
`;

const ActivityBell = ({ handleClick, unreadNotificationsCount }) => {
  return (
    <StyledActivityBell
      className="nav-activity-icon"
      onClick={handleClick}
      unreadNotificationsCount={unreadNotificationsCount}
    >
      {unreadNotificationsCount > 0 && (
        <NewNotificationsIconContainer className="new-notifications-icon-container">
          {unreadNotificationsCount < 100 ? unreadNotificationsCount : '99+'}
        </NewNotificationsIconContainer>
      )}
    </StyledActivityBell>
  );
};

export default React.memo(ActivityBell);
