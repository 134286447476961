import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

import { getAccountFiltersFetched } from 'selectors';

import { VIEW_BY } from 'appConstants/workload';
import UtilizationTableContainerV2 from './UtilizationTableV2/UtilizationTableContainer';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import TableOptionsSkeletonLoader from 'components/SkeletonLoader/TableOptionsSkeletonLoader';
import { ContainerDiv } from 'ReportsModule/components/styles';
import UtilizationTableOptions from './Options/UtilizationTableOptions';
import UtilizationChartContainer from './Charts/UtilizationChartContainer';
import GradientsDefs from '../GradientsDefs';
import useReportsViewFilter from 'ReportsModule/hooks/useReportsViewFilter';
import DevProps from 'components/dev/DevProps';
import { SectionRoutes } from 'appConstants/navigation';
import { CUSTOM_FILTER_VALUES } from 'appConstants/filters';
import { useHandlePrint } from 'appUtils/hooks/useHandlePrint';

const UtilizationTab = ({
  isDashboardWidgetModal,
  widgetDetailModalThreeDotMenuItems,
  widgetType
}) => {
  const filtersFetched = useSelector(getAccountFiltersFetched);
  const {
    filterId,
    viewBy,
    pageName,
    activeFilter,
    widgetConfig,
    filterSections
  } = useReportsViewFilter({
    ...(isDashboardWidgetModal && {
      matchUrl: `${SectionRoutes.DASHBOARD}/report/:viewType/:reportViewType`,
      widgetType
    })
  });

  const activeFilterWithOverridenDateRange = useMemo(
    () => ({
      ...activeFilter,
      // override range value if widget config's range option is disabled
      // disabled = user can not adjust the date range themselves
      // this is being done here instead of `useReportsViewFilter` because
      // overriding values inside filter selector may cause more issues and it is hard to test,
      // the purpose is to support backward compatibility where widgets thats already being created
      // will not have the new range option.
      ...(widgetConfig?.filterInit?.custom?.range &&
        widgetConfig?.disabledOptions?.[CUSTOM_FILTER_VALUES.range] && {
          custom: {
            ...activeFilter.custom,
            range: widgetConfig.filterInit.custom.range
          }
        })
    }),
    [
      activeFilter,
      widgetConfig?.disabledOptions,
      widgetConfig?.filterInit?.custom?.range
    ]
  );

  const loaded = filtersFetched;

  const filterStateIds = {
    [VIEW_BY.MEMBERS]: 'utilization-table',
    [VIEW_BY.ORGANIZATION]: 'utilization-org-chart',
    [VIEW_BY.TEAM]: 'utilization-team-chart'
  };

  const useCapacityAsDenominator = viewBy === VIEW_BY.MEMBERS;
  const includeRemainingCapacity =
    viewBy === VIEW_BY.MEMBERS ||
    viewBy === VIEW_BY.TEAM ||
    viewBy === VIEW_BY.ORGANIZATION;

  const componentsMap = {
    [VIEW_BY.MEMBERS]: UtilizationTableContainerV2,
    [VIEW_BY.ORGANIZATION]: UtilizationChartContainer,
    [VIEW_BY.TEAM]: UtilizationChartContainer
  };
  const ComponentByViewBy = componentsMap[viewBy];

  const { handlePrint, printRef, isPrinting } = useHandlePrint();

  return (
    <ContainerDiv
      style={{ width: loaded ? 'auto' : 860, paddingLeft: 0 }}
      ref={printRef}
    >
      <DevProps data={{ filterId, viewBy, pageName, activeFilter }} />
      <GradientsDefs />
      <DynamicModuleLoader
        modules={[
          getUtilizationModule(),
          getCapacitiesModule(),
          getBudgetModule()
        ]}
      >
        {loaded ? (
          <UtilizationTableOptions
            // handleExport={() => {}}
            activeFilter={activeFilterWithOverridenDateRange}
            viewBy={viewBy}
            pageName={pageName}
            // customOptions={customOptions}
            // fetchParams={fetchParams}
            isV2
            isDashboardWidgetModal={isDashboardWidgetModal}
            widgetDetailModalThreeDotMenuItems={
              widgetDetailModalThreeDotMenuItems
            }
            widgetConfig={widgetConfig}
            filterStateId={filterStateIds[viewBy]}
            filterSections={filterSections}
            useCapacityAsDenominator={false}
            includeRemainingCapacity={false}
            handlePrint={handlePrint}
          />
        ) : (
          <TableOptionsSkeletonLoader title="Utilization" unsetWidths />
        )}
        <ComponentByViewBy
          activeFilter={activeFilterWithOverridenDateRange}
          viewBy={viewBy}
          pageName={pageName}
          isDashboardWidgetModal={isDashboardWidgetModal}
          filterStateId={filterStateIds[viewBy]}
          // percent values will be calculated by capacity when true
          // it will calculate percent values based on total hours when false
          useCapacityAsDenominator={useCapacityAsDenominator}
          includeRemainingCapacity={includeRemainingCapacity}
          isPrinting={isPrinting}
        ></ComponentByViewBy>
      </DynamicModuleLoader>
    </ContainerDiv>
  );
};

export default UtilizationTab;
