import styled from 'styled-components';
import theme from 'theme';

export const Styles = styled.div`
  padding: 0rem 1rem;

  .table {
    display: inline-block;
    border-spacing: 0;
    /* border: 1px solid black; */
    font-size: 12px;
    .tr {
      :hover {
        .content-editable-description {
          &[data-placeholder]:empty:before {
            visibility: visible;
          }
        }

        .project-three-dot-icon {
          opacity: 1;
          path {
            fill: ${theme.colors.colorRoyalBlue};
            stroke: ${theme.colors.colorRoyalBlue};
          }
        }
        .show-on-row-hover {
          opacity: 1;
          color: ${theme.colors.colorMediumGray1};
          margin-top: 1px;
        }
        .show {
          color: ${theme.colors.colorSemiDarkGray1};
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    .th,
    .td {
      flex: none;
      margin: 0;
      /* border-bottom: 1px solid black;
      border-right: 1px solid black; */
      ${
        '' /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;
      :last-child {
        border-right: 0;
      }
      ${'' /* The resizer styles! */}
      .resizer {
        display: inline-block;
        /* background: blue; */
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        &.isResizing {
          background: red;
        }
      }
    }
  }
  .show-on-row-hover {
    opacity: 0;
  }
  .show {
    visibility: visible;
    color: ${theme.colors.colorSemiDarkGray1};
  }
`;

export const StyledTableRow = styled.div`
  &:focus {
    outline: none;
  }
  ${({ hasAction }) =>
    hasAction &&
    `
    cursor: pointer;
  `}
`;

export const DragClone = styled.div`
  &.milestone-row-clone .tr.phaseRow {
    align-items: center;
    margin-left: 35px !important;
    width: 605px !important;
    border-bottom: solid 3px #f5f5f5 !important;
    background: white;
  }

  &.skills-table-clone {
    .tr.headerRow {
      .td {
        &:not(:first-child):not(:nth-child(2)) {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 700;
          height: 100%;
          & > div {
            height: 100%;
            display: flex;
            align-items: flex-end;
            padding-bottom: 6px;
          }
        }
      }
    }
  }

  .is-white & .tr.taskGroupRow {
    .td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        background: white;
      }
    }
  }

  &.budget-table-drag-clone {
    .td {
      height: 100%;
      flex-shrink: 0;
      &.totals,
      &.rightPadding {
        flex-shrink: 1;
      }
    }
    .scopeRow.tr,
    .phaseRow.tr {
      .td {
        &:not(.grip):not(.totals):not(.rightPadding):not(.select) {
          border-top: 1px solid ${theme.colors.colorLightGray6};
          border-bottom: 1px solid ${theme.colors.colorLightGray6}; // note: this differs from BudgetTable styles
          background: white;
        }
      }
      &.closed {
        .td:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.thing):not(.entity) {
          border-left: 1px solid ${theme.colors.colorLightGray6};
        }
        .td {
          &.entity,
          &.thing,
          &.select,
          &.totals {
            border-left: 1px solid ${theme.colors.colorLightGray7};
          }
        }
      }
      &:hover {
        .drag-grips {
          visibility: visible;
        }
      }
    }
  }
`;
