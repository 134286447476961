import { ComponentProps } from 'react';
import SvgIcon from 'components/SvgIcon';

interface DependencyLinkIconProps extends ComponentProps<typeof SvgIcon> {
  fillColor?: string;
  height?: number;
  width?: number;
}

const DependencyLinkIcon = ({
  width = 12,
  height = 12,
  fillColor = '#828282',
  ...svgIconProps
}: DependencyLinkIconProps) => (
  <SvgIcon
    fill="none"
    height={height}
    viewBox="0 0 12 12"
    width={width}
    {...svgIconProps}
  >
    <path
      d="M2.25 1.65665C1.92257 1.65665 1.65714 1.92209 1.65714 2.24951V3.49951C1.65714 3.82694 1.92257 4.09237 2.25 4.09237H4.75C5.07743 4.09237 5.34286 3.82694 5.34286 3.49951V2.87451V2.24951C5.34286 1.92209 5.07743 1.65665 4.75 1.65665H2.25Z"
      stroke={fillColor}
      strokeWidth="0.685714"
    />
    <path
      d="M7.25 7.53214C6.92257 7.53214 6.65714 7.79757 6.65714 8.125V9.375C6.65714 9.70243 6.92257 9.96786 7.25 9.96786H9.75C10.0774 9.96786 10.3429 9.70243 10.3429 9.375V8.75V8.125C10.3429 7.79757 10.0774 7.53214 9.75 7.53214H7.25Z"
      stroke={fillColor}
      strokeWidth="0.685714"
    />
    <path d="M5 2.87573H7" stroke={fillColor} strokeWidth="0.685714" />
    <path d="M5 5.87573H7" stroke={fillColor} strokeWidth="0.685714" />
    <path d="M5 8.87573H7" stroke={fillColor} strokeWidth="0.685714" />
    <path
      d="M6.99902 5.87573C7.82745 5.87573 8.49902 5.20416 8.49902 4.37573C8.49902 3.54731 7.82745 2.87573 6.99902 2.87573"
      stroke={fillColor}
      strokeWidth="0.685714"
    />
    <path
      d="M5 8.87573C4.17157 8.87573 3.5 8.20416 3.5 7.37573C3.5 6.54731 4.17157 5.87573 5 5.87573"
      stroke={fillColor}
      strokeWidth="0.685714"
    />
  </SvgIcon>
);

export default DependencyLinkIcon;
