import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';

const OptionsCheckboxRow = ({
  isChecked,
  label,
  handleUpdate,
  isDisabled
}: {
  isChecked: boolean;
  label?: string;
  handleUpdate: (isCheckedNew: boolean) => void;
  isDisabled: boolean;
}) => {
  const handleClick = !isDisabled ? () => handleUpdate(!isChecked) : undefined;

  return (
    <OptionsCheckboxRowContainer>
      <SmallOptionsContainer
        data-for="app-tooltip"
        data-tip={EDIT_WORK_PLANS_TIP}
        data-effect="solid"
        data-tip-disable={!isDisabled}
        onClick={handleClick}
        className={cn({ disabled: isDisabled })}
      >
        <SmallIconContainer>
          <CustomCheckBoxContainer isDisabled={isDisabled}>
            <CustomCheckBox isDisabled={isDisabled} isChecked={isChecked} />
          </CustomCheckBoxContainer>
        </SmallIconContainer>
        {label}
      </SmallOptionsContainer>
    </OptionsCheckboxRowContainer>
  );
};

const OptionsCheckboxRowContainer = styled.div``;

const CustomCheckBoxContainer = styled.div<{ isDisabled: boolean }>`
  border-radius: 3px;
  border: 1px solid ${theme.colors.colorMediumGray5};
  background: ${({ isDisabled }) =>
    isDisabled ? theme.colors.colorLightGray6 : 'transparent'};
  cursor: ${({ isDisabled }) => (isDisabled ? '' : 'pointer')};
`;

const CustomCheckBox = styled.div<{ isDisabled: boolean; isChecked: boolean }>`
  margin: 3px;
  height: 6px;
  width: 6px;
  border-radius: 2px;
  background: ${({ isChecked, isDisabled }) =>
    isDisabled
      ? 'transparent'
      : isChecked
      ? theme.colors.colorRoyalBlue
      : '#f5f5f5'};
`;

const SmallOptionsContainer = styled.div`
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  align-items: center;
  padding: 11px 15px;

  &.disabled {
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    color: ${theme.colors.colorRoyalBlue};
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
    ${CustomCheckBoxContainer} {
      border-color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const SmallIconContainer = styled.div`
  height: 14px;
  display: flex;
  align-items: center;
  width: 14px;
  margin-right: 6px;
`;

export default OptionsCheckboxRow;
