import * as constants from 'QuickbooksModule/constants';

export const makeMosaicPhase = (
  phase,
  mosaicProjectId,
  syncedSubSubCustomers
) => {
  const syncedSubSubCustomer = syncedSubSubCustomers[phase.id];
  const isPending = syncedSubSubCustomer?.status === constants.IS_PENDING;
  const synced = syncedSubSubCustomer && !syncedSubSubCustomer.dont_sync;

  const mosaicPhase = {
    isPhase: true,
    isSelectQBPhase: true,
    mosaicProjectId,
    mosaic_phase_name: phase.name,
    mosaic_phase_id: phase.id,
    selected: !!syncedSubSubCustomer,
    synced,
    isPending
  };
  if (synced) {
    mosaicPhase.qb_phase_name = syncedSubSubCustomer.phase_name;
    mosaicPhase.qb_phase_id = syncedSubSubCustomer.phase_id;
  }
  return mosaicPhase;
};

export const makeQBPhase = (
  phase,
  mosaic_phase_name,
  subcustomerId,
  syncedSubSubCustomersByPhaseId
) => {
  const syncedSubSubCustomer = syncedSubSubCustomersByPhaseId[phase.id];
  const isPending = syncedSubSubCustomer?.status === constants.IS_PENDING;
  const synced = syncedSubSubCustomer && !syncedSubSubCustomer.dont_sync;
  const qbPhase = {
    isPhase: true,
    isSelectMosaicPhase: true,
    qb_phase_name: phase.phase_name,
    qb_phase_id: phase.id,
    synced,
    subcustomer_id: subcustomerId
  };
  if (syncedSubSubCustomer) {
    qbPhase.mosaic_phase_id = syncedSubSubCustomer.mosaic_phase_id;
    qbPhase.isPending = isPending;
  }
  if (synced) {
    qbPhase.mosaic_phase_name = mosaic_phase_name;
  }
  return qbPhase;
};
