import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { userActivityTypesDescriptions } from 'appConstants';
import {
  getIsOpenTimesheetActivityModal,
  getTimesheetActivityModalData,
  getPlannerHoursForProject,
  getTaskCountActivityModal,
  getNotesCountActivityModal,
  getProjectHash,
  makeGetGroupedUserActivities,
  getPhaseNamesByPhaseId
} from 'selectors';
import {
  ActivityModalHeader,
  ActivityModalBody,
  ActivityModalContainer,
  ActivityModalRow,
  ModalTitleHeader,
  ModalContainerHeader,
  StyledActivityTimesheetIcon,
  CloseIconContainer,
  StyledProjectInfo,
  StyledProjectDescription,
  StyledPhaseName,
  StyledDot,
  StyledColorPicker
} from './styles';
import NewCloseIcon from 'icons/NewCloseIcon';
import ColorPicker from 'components/ColorPicker';
import {
  closeTimesheetActivityModalForDay,
  fetchWorkloadPlanner,
  fetchUserActivities
} from 'actionCreators';
import moment from 'moment';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';
const emptyObj = {};
class ActivityModalForDay extends React.Component {
  handleClose = () => {
    const { closeTimesheetActivityModalForDay } = this.props;
    closeTimesheetActivityModalForDay({});
  };

  componentDidUpdate(prevProps) {
    const { modalData, fetchWorkloadPlanner, open, fetchUserActivities } =
      this.props;
    if (prevProps.open !== open && open) {
      const { day, accountId, projectId, phaseId } = modalData;
      fetchUserActivities({
        accountId,
        date: day,
        projectId,
        phaseId
      });
      fetchWorkloadPlanner({
        startDate: day,
        endDate: day,
        accountId,
        project_ids: [projectId],
        all: true
      });
    }
  }

  renderActivity = () => {
    const { userActivities } = this.props;
    return Object.keys(userActivities).map((key) => {
      const activityMaker = userActivityTypesDescriptions[key];
      return (
        activityMaker &&
        userActivities[key].length > 0 && (
          <ActivityModalRow>
            {`${activityMaker(userActivities[key].length)}`}
          </ActivityModalRow>
        )
      );
    });
  };

  colorPickerRef = React.createRef();
  render() {
    const { open, modalData, plannedHours, allProjects, phaseNamesByPhaseId } =
      this.props;
    const { day, projectId, phaseId } = modalData;
    const projectName =
      projectId && allProjects[projectId] && allProjects[projectId].title;
    const projectDescription =
      projectId && allProjects[projectId] && allProjects[projectId].description;
    const plannedTime = plannedHours && parseInt(plannedHours);
    const phaseName =
      phaseId && phaseNamesByPhaseId && phaseNamesByPhaseId[phaseId];
    return (
      <Modal
        className="activity-score-modal"
        isOpen={open}
        toggle={this.handleClose}
        zIndex="2001"
      >
        {projectId && allProjects[projectId] && (
          <ActivityModalHeader>
            <ModalTitleHeader>
              <StyledProjectInfo>
                <StyledColorPicker>
                  <ColorPicker
                    projectId={projectId}
                    id={projectId}
                    originType={ORIGIN_TYPE_STRINGS.PROJECT}
                    row
                    ref={this.colorPickerRef}
                    className="color-picker-container timesheet-activity-modal"
                    alwaysShow
                  />
                </StyledColorPicker>
                <StyledDot id={projectId}></StyledDot>
                {projectName}
                {projectDescription && (
                  <StyledProjectDescription>
                    - {projectDescription}
                  </StyledProjectDescription>
                )}
              </StyledProjectInfo>

              <StyledPhaseName>{phaseName}</StyledPhaseName>
            </ModalTitleHeader>
            <CloseIconContainer onClick={this.handleClose}>
              <NewCloseIcon />
            </CloseIconContainer>
          </ActivityModalHeader>
        )}

        <ActivityModalContainer>
          <ModalContainerHeader>
            <StyledActivityTimesheetIcon color={'#4a4a4a'} />
            {day && moment.utc(day).format('dddd, MMMM D')}
            <span style={{ fontWeight: '400', paddingLeft: '12px' }}>
              {' '}
              Activity Log
            </span>
          </ModalContainerHeader>
          <ActivityModalBody>
            {!!plannedTime && (
              <ActivityModalRow>
                {`${plannedTime} ${plannedTime > 1 ? 'hours' : 'hour'} planned`}
              </ActivityModalRow>
            )}
            {this.renderActivity()}
          </ActivityModalBody>
        </ActivityModalContainer>
      </Modal>
    );
  }
}
const accountIdGetter = (state, ownProps) => {
  const { modalData } = ownProps;
  const { accountId } = modalData || emptyObj;
  return accountId;
};
const dateGetter = (state, ownProps) => {
  const { modalData } = ownProps;
  const { day } = modalData || emptyObj;
  return day;
};

const makeMapStateToProps = () => {
  const getUserActivities = makeGetGroupedUserActivities({
    accountIdGetter,
    dateGetter
  });
  const mapStateToProps = (state, ownProps) => {
    const modalData = getTimesheetActivityModalData(state);
    return {
      open: getIsOpenTimesheetActivityModal(state),
      plannedHours: getPlannerHoursForProject(state),
      allProjects: getProjectHash(state),
      phaseNamesByPhaseId: getPhaseNamesByPhaseId(state),
      userActivities: getUserActivities(state, { modalData }),
      modalData
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  closeTimesheetActivityModalForDay,
  fetchWorkloadPlanner,
  fetchUserActivities
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ActivityModalForDay);
