import React from 'react';
import { connect } from 'react-redux';
import {
  StyledColorCircle,
  StyledCustomColorCircle,
  SelectedColorBorder
} from './styles';
import { makeGetPreference } from 'selectors';

class ColorPickerCircle extends React.Component {
  // necessary due to updateTeam api call structure
  updateTeam = ({ color, id }) => {
    const { updateTeam } = this.props;
    const payload = {
      color,
      team: { id }
    };
    updateTeam(payload);
  };

  renderSelected = () => {
    const { preference, color, isDefaultColor } = this.props;
    return preference === color ||
      (isDefaultColor && preference === undefined) ? (
      <SelectedColorBorder />
    ) : null;
  };

  handleClick = () => {
    const { preference, color, id, isDefaultColor, handleSelect, apiCall } =
      this.props;
    const payload = { color, id };
    const api = apiCall;
    if (isDefaultColor && preference) {
      api.delete(payload);
    } else if (!isDefaultColor) {
      preference ? api.update(payload) : api.create(payload);
    }
    if (handleSelect) {
      handleSelect();
    }
  };

  handleCustomColorClick = () => {
    const { handleSelect, isCustomColor } = this.props;
    if (isCustomColor) {
      handleSelect();
    }
  };

  render() {
    const { color, isCustomColor } = this.props;

    return !isCustomColor ? (
      <StyledColorCircle
        $color={color}
        className="color-picker-circle"
        onClick={this.handleClick}
      >
        {this.renderSelected()}
      </StyledColorCircle>
    ) : (
      <StyledCustomColorCircle onClick={this.handleCustomColorClick} />
    );
  }
}
const makeMapStateToProps = () => {
  const getPreference = makeGetPreference();
  const mapStateToProps = (state, ownProps) => ({
    preference: getPreference(state, ownProps) || ownProps.preference // for items that carry own preference independent of userTheme
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ColorPickerCircle);
