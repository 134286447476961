import styled from 'styled-components';
import { BlueSubmitButton } from 'components/styles';
import HelpIcon from 'icons/HelpIcon';
import theme from 'theme';

const Header = ({ toggleModal }) => {
  return (
    <HeaderContainer>
      <div className="title">
        Team Builder{' '}
        <span
          data-tip="Team Builder helps you put <br /> together project teams in minutes."
          data-html
          data-for="app-tooltip"
          data-class="center"
          className="tooltip-icon"
          data-effect="solid"
        >
          <HelpIcon
            width={12}
            height={12}
            fillColor={theme.colors.colorLightGray12}
            questionMarkColor={theme.colors.colorPureBlack}
          />
        </span>
      </div>
      <StyledDoneButton onClick={toggleModal}>Done</StyledDoneButton>
    </HeaderContainer>
  );
};

const StyledDoneButton = styled(BlueSubmitButton)`
  font-weight: 600;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 26px;
    font-weight: 600;

    .tooltip-icon {
      position: relative;
      bottom: 10px;
    }
  }
`;

export default Header;
