/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import produce from 'immer';
import {
  getAllProjectSyncInfoArray,
  getQuickbooksShowPhasesProjectIds,
  getProjectSearchText,
  getFilteredQBProjectsById,
  getSyncedProjectsBySubcustomerId,
  getQbProjectSortColumn,
  getProjectMappingValue
} from 'QuickbooksModule/selectors';
import { getIsConfigDirQbToMosaic, getPhasesState } from './quickbooksCore';
import { getVisibleSelectedProjects } from './projects';
import {
  getAllFetchedProjects,
  getGroupsHash,
  getPhaseNamesByPhaseId,
  getPhasesByProjectHash,
  getActiveNonDefaultPhasesByProject
} from 'selectors';
import { searchItemsWithWhiteSpace } from 'appUtils/search';
import {
  makeMosaicPhase,
  makeQBPhase
} from 'QuickbooksModule/qbUtils/phaseShapes';
import {
  makeSubcustomerSearchablePhases,
  makeProjectSearchablePhases
} from 'QuickbooksModule/qbUtils/makeSearchableStrings';
import { getSelectedPreFillProjectsQBToMosaic } from 'QuickbooksModule/selectors/projects';
import { sortArray } from 'QuickbooksModule/qbUtils';
import * as constants from 'QuickbooksModule/constants';

const byPhaseId = (item) => item && item.phase_id;
const byId = (item) => item && item.id;
const emptyObj = {};
const emptyArray = [];

export const getOwnPhases = createSelector(
  getPhasesState,
  (state) => state.phases
);

export const getSearchableAllProjectSyncInfoArray = createSelector(
  getAllProjectSyncInfoArray,
  getPhaseNamesByPhaseId,
  getPhasesByProjectHash,
  getQbProjectSortColumn,
  (projects, phaseNames, phases, projectSortColumn) => {
    const projectList = projects.map((project) => {
      const searchablePhases = makeProjectSearchablePhases(
        project.mosaic_project_id,
        phases,
        phaseNames
      );
      return {
        ...project,
        searchableString: `${searchablePhases} ${project.subcustomer_name} ${project.customer_name} ${project.mosaic_name}`,
        searchablePhases
      };
    });
    if (projectSortColumn) {
      const { order, sortColumn } = projectSortColumn;
      return sortArray(projectList, sortColumn, order);
    } else {
      return projectList;
    }
  }
);

export const getQBPhasesState = (state) =>
  (state.quickbooks && state.quickbooks.qbSubSubCustomers) || {};

export const getQbSubSubCustomers = createSelector(
  getQBPhasesState,
  (qbSubSubCustomers) =>
    qbSubSubCustomers && qbSubSubCustomers.qbSubSubCustomers
);

export const getQbSyncedSubSubCustomers = createSelector(
  getQBPhasesState,
  (qbSubSubCustomers) =>
    qbSubSubCustomers && qbSubSubCustomers.qbSyncedSubSubCustomers
);

export const getSyncedSubSubCustomersByPhaseId = createSelector(
  getQbSyncedSubSubCustomers,
  (syncedSubSubCustomers) =>
    keyBy(Object.values(syncedSubSubCustomers || emptyObj), byPhaseId)
);

export const getQbSubSubCustomersBySubCustomerId = createSelector(
  getQbSubSubCustomers,
  (subSubCustomers) => {
    const dict = {};
    Object.values(subSubCustomers || emptyObj).forEach((phase) => {
      if (dict[phase.subcustomer_id]) {
        dict[phase.subcustomer_id].push(phase);
      } else {
        dict[phase.subcustomer_id] = [phase];
      }
    });
    return dict;
  }
);

const getSubcustomerId = (state, ownProps) => {
  const { subcustomer } = state;
  const { project } = ownProps;
  if (subcustomer && subcustomer.id) {
    return state.subcustomer.id;
  } else if (project && project.subcustomer_id) {
    return project.subcustomer_id;
  } else {
    return null;
  }
};

export const getMosaicProjectId = (state, ownProps) =>
  ownProps.project && ownProps.project.mosaic_project_id;

export const getFormattedProjectsList = createSelector(
  getFilteredQBProjectsById,
  getSyncedProjectsBySubcustomerId,
  getPhaseNamesByPhaseId,
  getPhasesByProjectHash,
  getQbSubSubCustomersBySubCustomerId,
  getAllFetchedProjects,
  getSelectedPreFillProjectsQBToMosaic,
  getGroupsHash,
  (
    qbSubCustomers,
    syncedQbSubCustomers,
    phaseNames,
    phasesByProjectHash,
    phasesBySubcustomerId,
    allProjects,
    preFillProjectsQBToMosaic,
    allGroupsHash
  ) => {
    const newSubCustomers = produce(qbSubCustomers, (draftSubCustomers) => {
      Object.keys(draftSubCustomers || emptyObj).forEach((key) => {
        const draftProject = draftSubCustomers[key];
        const syncedQbSubCustomer = syncedQbSubCustomers?.[key];
        const subcustomerSearchablePhases = makeSubcustomerSearchablePhases(
          phasesBySubcustomerId,
          draftProject.subcustomer_id
        );
        draftProject.searchableString = `${draftProject.subcustomer_name} ${draftProject.customer_name} ${subcustomerSearchablePhases}`;
        draftProject.searchablePhases = subcustomerSearchablePhases;

        if (preFillProjectsQBToMosaic && preFillProjectsQBToMosaic[key]) {
          draftProject.isCreateNew = true;
        }

        if (syncedQbSubCustomer) {
          const {
            mosaic_board_id,
            mosaic_project_id,
            subcustomer_name,
            customer_name,
            dont_sync,
            id,
            subcustomer_id,
            status,
            create_new_project,
            create_new_on_qb,
            is_qb_project,
            mosaic_project_name
          } = syncedQbSubCustomer;
          const mosaicProject = allProjects[mosaic_project_id] || {};
          const isMappingSaved = mosaic_project_id !== null;
          const boardId = mosaicProject.board_id || mosaic_board_id;
          draftProject.synced = !dont_sync;
          draftProject.mosaic_board_id = mosaic_board_id;
          draftProject.team_name =
            allGroupsHash[boardId] && allGroupsHash[boardId].name;
          draftProject.mosaic_project_id = mosaic_project_id;
          draftProject.project_name = isMappingSaved
            ? mosaicProject.title
            : mosaic_project_name;
          draftProject.dont_sync = dont_sync;
          draftProject.subcustomer_mapping_id = id;
          draftProject.is_qb_project = is_qb_project;
          draftProject.isPending = status === constants.IS_PENDING;
          draftProject.createNewProject =
            create_new_project || create_new_on_qb;
          const projectSearchablePhases = makeProjectSearchablePhases(
            mosaic_project_id,
            phasesByProjectHash,
            phaseNames
          );
          const syncedSubcustomerSearchablePhases =
            makeSubcustomerSearchablePhases(
              phasesBySubcustomerId,
              subcustomer_id
            );
          const searchablePhaseString = `${projectSearchablePhases} ${syncedSubcustomerSearchablePhases}`;
          draftProject.searchableString += `${projectSearchablePhases} ${draftProject.project_name} ${searchablePhaseString} ${subcustomer_name} ${customer_name}`;

          draftProject.searchablePhases += searchablePhaseString;
        }
      });
    });
    return newSubCustomers;
  }
);

export const getFormattedProjectsListArray = createSelector(
  getFormattedProjectsList,
  getQbProjectSortColumn,
  (projects, projectSortColumn) => {
    if (projectSortColumn) {
      const { order, sortColumn } = projectSortColumn;
      return sortArray(Object.values(projects), sortColumn, order);
    }
    return Object.values(projects);
  }
);

const getPhasesForProject = (
  subcustomerId,
  mosaicProjectId,
  allSubSubCustomers,
  phases,
  syncedSubSubCustomersByPhaseId,
  syncedSubSubCustomers
) => {
  const allPhases = [];
  const mosaicPhases = phases[mosaicProjectId]?.phases?.length
    ? keyBy(phases[mosaicProjectId].phases, byId)
    : {};

  // eslint-disable-next-line no-unused-expressions
  allSubSubCustomers?.[subcustomerId]?.forEach((phase) => {
    const mosaic_phase_name =
      mosaicPhases[syncedSubSubCustomersByPhaseId[phase.id]?.mosaic_phase_id]
        ?.name;
    allPhases.push(
      makeQBPhase(
        phase,
        mosaic_phase_name,
        subcustomerId,
        syncedSubSubCustomersByPhaseId
      )
    );
  });

  // eslint-disable-next-line no-unused-expressions
  phases?.[mosaicProjectId]?.phases?.forEach((phase) => {
    if (!phase) {
      return;
    }
    const syncedSubSubCustomer = syncedSubSubCustomers[phase.id];
    const dont_sync = syncedSubSubCustomer?.dont_sync;
    if (dont_sync || !syncedSubSubCustomer) {
      allPhases.push(
        makeMosaicPhase(phase, mosaicProjectId, syncedSubSubCustomers)
      );
    }
  });
  return allPhases;
};

export const getAllPhasesForMosaicToQBRow = createSelector(
  getSubcustomerId,
  getMosaicProjectId,
  getQbSubSubCustomersBySubCustomerId,
  getActiveNonDefaultPhasesByProject,
  getSyncedSubSubCustomersByPhaseId,
  getQbSyncedSubSubCustomers,
  getPhasesForProject
);

export const getSearchedAllProjectSyncInfoArray = createSelector(
  getSearchableAllProjectSyncInfoArray,
  getProjectSearchText,
  (searchableProjects, searchText) =>
    searchItemsWithWhiteSpace({
      searchText,
      itemList: searchableProjects,
      filterKeysArray: ['searchableString']
    })
);

export const getSearchedFormattedProjectsListArray = createSelector(
  getFormattedProjectsListArray,
  getProjectSearchText,
  (projects, searchText) =>
    searchItemsWithWhiteSpace({
      searchText,
      itemList: projects,
      filterKeysArray: ['searchableString']
    })
);
export const getFormattedProjectsAndPhasesListMosaicToQB = createSelector(
  getSearchedAllProjectSyncInfoArray,
  getQuickbooksShowPhasesProjectIds,
  getActiveNonDefaultPhasesByProject,
  getQbSubSubCustomersBySubCustomerId,
  getSyncedSubSubCustomersByPhaseId,
  getQbSyncedSubSubCustomers,
  getProjectMappingValue,
  (
    projectsList,
    showPhasesProjectIds,
    phases,
    qbSubSubCustomers,
    syncedSubSubCustomersByPhaseId,
    syncedSubSubCustomers,
    mappingValue
  ) => {
    let unlinkedPhaseProjectRowCounter = 0;
    const formattedProjects = projectsList.reduce(
      (projectsAndPhases, project, index) => {
        if (mappingValue === constants.mappingFilters.SHOW_UNLINKED_PHASES) {
          const unlinkedProjectPhases = getPhasesForProject(
            project.subcustomer_id,
            project.mosaic_project_id,
            qbSubSubCustomers,
            phases,
            syncedSubSubCustomersByPhaseId,
            syncedSubSubCustomers
          ).filter((phase) => !phase.synced);
          if (unlinkedProjectPhases.length) {
            projectsAndPhases.push({
              ...project,
              rowNumber: unlinkedPhaseProjectRowCounter + 1
            });
            unlinkedProjectPhases.forEach((phase) =>
              projectsAndPhases.push(phase)
            );
            unlinkedPhaseProjectRowCounter++;
          }
          return projectsAndPhases;
        } else {
          projectsAndPhases.push({ ...project, rowNumber: index + 1 });
          const isExpanded = showPhasesProjectIds[project.mosaic_project_id];
          if (isExpanded) {
            const projectPhases = getPhasesForProject(
              project.subcustomer_id,
              project.mosaic_project_id,
              qbSubSubCustomers,
              phases,
              syncedSubSubCustomersByPhaseId,
              syncedSubSubCustomers
            );
            projectPhases.forEach((phase) => projectsAndPhases.push(phase));
          }
        }
        return projectsAndPhases;
      },
      []
    );
    return formattedProjects;
  }
);

export const getPhasesForQBProject = (
  projectId,
  mosaicProjectId,
  allSubSubCustomers,
  phases,
  syncedSubSubCustomersByPhaseId,
  syncedSubSubCustomers
) => {
  const allPhases = [];
  const mosaicPhases = phases[mosaicProjectId]?.phases?.length
    ? keyBy(phases[mosaicProjectId].phases, byId)
    : {};

  // eslint-disable-next-line no-unused-expressions
  allSubSubCustomers?.[projectId]?.forEach((phase) => {
    const mosaic_phase_name =
      mosaicPhases[syncedSubSubCustomersByPhaseId?.[phase.id]?.mosaic_phase_id]
        ?.name;
    allPhases.push(
      makeQBPhase(
        phase,
        mosaic_phase_name,
        projectId,
        syncedSubSubCustomersByPhaseId
      )
    );
  });

  // eslint-disable-next-line no-unused-expressions
  phases?.[mosaicProjectId]?.phases?.forEach((phase) => {
    const syncedSubSubCustomer = syncedSubSubCustomers[phase.id];
    const dont_sync = syncedSubSubCustomer?.dont_sync;
    if (phase && (dont_sync || !syncedSubSubCustomer)) {
      allPhases.push(
        makeMosaicPhase(phase, mosaicProjectId, syncedSubSubCustomers)
      );
    }
  });
  return allPhases;
};

export const getFormattedProjectsAndPhasesListQBToMosaic = createSelector(
  getSearchedFormattedProjectsListArray,
  getQuickbooksShowPhasesProjectIds,
  getQbSubSubCustomersBySubCustomerId,
  getActiveNonDefaultPhasesByProject,
  getSyncedSubSubCustomersByPhaseId,
  getQbSyncedSubSubCustomers,
  getProjectMappingValue,
  (
    projectsList,
    showPhasesProjectIds,
    allSubSubCustomers,
    phases,
    syncedSubSubCustomersByPhaseId,
    syncedSubSubCustomers,
    mappingValue
  ) => {
    let unlinkedPhaseProjectRowCounter = 0;
    const formattedProjects = projectsList.reduce(
      (projectsAndPhases, project, index) => {
        if (mappingValue === constants.mappingFilters.SHOW_UNLINKED_PHASES) {
          const unlinkedProjectPhases = getPhasesForQBProject(
            project.id,
            project.mosaic_project_id,
            allSubSubCustomers,
            phases,
            syncedSubSubCustomersByPhaseId,
            syncedSubSubCustomers
          ).filter((phase) => !phase.synced);
          if (unlinkedProjectPhases.length) {
            projectsAndPhases.push({
              ...project,
              rowNumber: unlinkedPhaseProjectRowCounter + 1
            });
            unlinkedProjectPhases.forEach((phase) =>
              projectsAndPhases.push(phase)
            );
            unlinkedPhaseProjectRowCounter++;
          }

          return projectsAndPhases;
        } else {
          projectsAndPhases.push({ ...project, rowNumber: index + 1 });
          const isExpanded =
            showPhasesProjectIds[project.mosaic_project_id || project.id];
          if (isExpanded) {
            const projectPhases = getPhasesForQBProject(
              project.id,
              project.mosaic_project_id,
              allSubSubCustomers,
              phases,
              syncedSubSubCustomersByPhaseId,
              syncedSubSubCustomers
            );
            projectPhases.forEach((phase) => projectsAndPhases.push(phase));
          }
        }
        return projectsAndPhases;
      },
      []
    );
    return formattedProjects;
  }
);

export const getVisibleProjects = createSelector(
  getIsConfigDirQbToMosaic,
  getSearchedAllProjectSyncInfoArray,
  getSearchedFormattedProjectsListArray,
  (isQBToMosaic, mosaicToQBProjects, qbToMosaicProjects) =>
    isQBToMosaic ? qbToMosaicProjects : mosaicToQBProjects
);

export const getAllVisibleProjectsAreSelected = createSelector(
  getVisibleProjects,
  getVisibleSelectedProjects,
  (visibleProjects, visibleSelectedProjects) =>
    visibleProjects.every((project) => !!visibleSelectedProjects[project.id])
);

export const getQbSubSubCustomersSyncInfo = createSelector(
  getQbSubSubCustomers,
  getSyncedSubSubCustomersByPhaseId,
  (qbSubSubCustomers, syncedSubSubCustomers) => {
    return produce(qbSubSubCustomers, (draftQbSubSubCustomers) => {
      Object.values(draftQbSubSubCustomers).forEach((phase) => {
        const syncedPhase = syncedSubSubCustomers[phase.id];
        if (syncedPhase && !syncedPhase.dont_sync) {
          draftQbSubSubCustomers[phase.id].synced = true;
          draftQbSubSubCustomers[phase.id].qb_phase_id = syncedPhase.phase_id;
        }
      });
    });
  }
);

export const qbSubSubCustomersSyncInfoBySubCustomerId = createSelector(
  getQbSubSubCustomersSyncInfo,
  (subSubCustomers) => {
    const dict = {};
    Object.values(subSubCustomers || emptyObj).forEach((phase) => {
      if (dict[phase.subcustomer_id]) {
        dict[phase.subcustomer_id].push(phase);
      } else {
        dict[phase.subcustomer_id] = [phase];
      }
    });
    return dict;
  }
);

export const isPendingPhases = createSelector(
  getQbSyncedSubSubCustomers,
  (syncedSubSubCustomers) => {
    const pendingStatusPhases =
      (syncedSubSubCustomers &&
        Object.values(syncedSubSubCustomers).filter(
          (phase) => phase.status === constants.IS_PENDING
        )) ||
      [];
    return pendingStatusPhases.length > 0;
  }
);
