import { useCallback, useEffect, useState } from 'react';
import { getMe } from 'selectors';
import {
  getAuthMfaStatusPerAccount,
  toggleIndividualMfa,
  toggleMfaReset
} from 'SettingsModule/actionCreators';
import { ActionLink } from './styled';
import SimpleConfirmModal from 'components/Modals/SimpleConfirmModal';
import { MfaSetupModal } from 'AuthenticationModule/components/MfaSetupModal';
import { formatOnSuccess } from 'appUtils/formatUtils';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import isEmpty from 'lodash/isEmpty';
import { MFA_STATUS } from 'AuthenticationModule/constants';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

const currentActionContextMap = {
  resettingMfaAssociation: 'resettingMfaAssociation',
  changingMfaType: 'changingMfaType'
};

export const PersonalMfaSettings = () => {
  const [currentActionContext, setCurrentActionContext] = useState<
    string | undefined
  >(undefined);
  const [isMfaResetSuccessModalOpen, setIsMfaResetSuccessModalOpen] =
    useState(false);
  const [isChangeMfaTypeSuccessModalOpen, setIsChangeMfaTypeSuccessModalOpen] =
    useState(false);
  const [isMfaSetupModalOpen, setIsMfaSetupModalOpen] = useState(false);
  const [
    isConfirmResetMfaAssociationModalOpen,
    setIsConfirmResetMfaAssociationModalOpen
  ] = useState(false);
  const [isConfirmChangeMfaModalOpen, setIsConfirmChangeMfaModalOpen] =
    useState(false);
  const [isCurrentActionSuccess, setIsCurrentActionSuccess] = useState(false);

  const { useSmsMfa } = useFeatureFlags();

  const dispatch = useAppDispatch();
  const me = useAppSelector(getMe);

  const getOwnAuthMfaStatus = useCallback(() => {
    dispatch(
      getAuthMfaStatusPerAccount({
        accountIds: [me.id]
      })
    );
  }, [dispatch, me?.id]);

  const handleCloseMfaSetupModalRequest = useCallback(() => {
    setIsMfaSetupModalOpen(false);
    getOwnAuthMfaStatus();
  }, [getOwnAuthMfaStatus]);

  useEffect(() => {
    if (me) {
      getOwnAuthMfaStatus();
    }
  }, [dispatch, getOwnAuthMfaStatus, me]);

  const accountsMfaStatus = useAppSelector(
    (state) => state.auth.accountsMfaStatus
  );

  const mfaStatusInfoOfCurrentUser = me ? accountsMfaStatus[me.id] : undefined;
  const mfaStatusOfCurrentUser = mfaStatusInfoOfCurrentUser?.user_mfa_status;
  const isTeamMfaEnforced = mfaStatusInfoOfCurrentUser?.team_mfa_enforced;

  const successMfaSetupVerifyEvent = useCallback(
    (event: MessageEvent) => {
      if (event.origin === process.env.MOSAIC_AUTH_DOMAIN) {
        try {
          const eventData = JSON.parse(event.data);

          if (typeof eventData === 'object' && eventData.type) {
            const coercedEventData: {
              type: 'mfaVerifySuccess';
            } = eventData;

            if (coercedEventData.type === 'mfaVerifySuccess') {
              handleCloseMfaSetupModalRequest();
              setIsCurrentActionSuccess(true);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [handleCloseMfaSetupModalRequest]
  );

  useEffect(() => {
    window.addEventListener('message', successMfaSetupVerifyEvent);

    return () => {
      window.removeEventListener('message', successMfaSetupVerifyEvent);
    };
  }, [successMfaSetupVerifyEvent]);

  // use this effect to trigger opening the success modal for the current action context when that action is executed successfully
  useEffect(() => {
    if (isCurrentActionSuccess && !!currentActionContext) {
      if (
        currentActionContext === currentActionContextMap.resettingMfaAssociation
      ) {
        setIsMfaResetSuccessModalOpen(true);
      } else if (
        currentActionContext === currentActionContextMap.changingMfaType
      ) {
        setIsChangeMfaTypeSuccessModalOpen(true);
      }

      // resetting to default values
      setCurrentActionContext(undefined);
      setIsCurrentActionSuccess(false);
    }
  }, [isCurrentActionSuccess, currentActionContext]);

  // if data has not loaded yet
  if (isEmpty(accountsMfaStatus)) return null;

  const handleResetMfaAssociationRequest = () => {
    setIsConfirmResetMfaAssociationModalOpen(true);
    setCurrentActionContext(currentActionContextMap.resettingMfaAssociation);
  };

  const handleChangeMfaRequest = () => {
    setIsConfirmChangeMfaModalOpen(true);
    setCurrentActionContext(currentActionContextMap.changingMfaType);
  };

  const handleConfirmResetMfaAssociation = () => {
    setIsConfirmResetMfaAssociationModalOpen(false);

    if (me?.email && mfaStatusOfCurrentUser) {
      dispatch(
        toggleMfaReset({
          email: me.email,
          targetMfa: mfaStatusOfCurrentUser,
          onSuccess: formatOnSuccess(() => setIsMfaSetupModalOpen(true))
        })
      );
    }
  };

  const handleConfirmChangeMfa = () => {
    setIsConfirmChangeMfaModalOpen(false);

    if (me?.email) {
      const handleKnowMfaNowDisabled = () => {
        dispatch(
          toggleIndividualMfa({
            accountIds: [me.id],
            isEnableMfa: true,
            mfaType:
              mfaStatusOfCurrentUser === MFA_STATUS.sms
                ? MFA_STATUS.totp
                : MFA_STATUS.sms,
            meta: {
              onSuccess: () => setIsMfaSetupModalOpen(true)
            }
          })
        );
      };

      const handleKnowTargetMfaReset = () => {
        if (mfaStatusOfCurrentUser) {
          dispatch(
            toggleIndividualMfa({
              accountIds: [me.id],
              isEnableMfa: false,
              mfaType: mfaStatusOfCurrentUser,
              meta: {
                onSuccess: handleKnowMfaNowDisabled
              }
            })
          );
        }
      };

      /**
       * we want to first reset mfa type that the user wants to change to so that they are forced to set it up. If we
       * don't do this action first, then there is a chance that we turn off MFA for them altogether, and then the next
       * step after that (turning on MFA for their new preferred type) does not succeed, and thus they are able to
       * remain without MFA indefinitely
       */
      dispatch(
        toggleMfaReset({
          email: me.email,
          targetMfa:
            mfaStatusOfCurrentUser === MFA_STATUS.sms
              ? MFA_STATUS.totp
              : MFA_STATUS.sms,
          onSuccess: formatOnSuccess(handleKnowTargetMfaReset)
        })
      );
    }
  };

  return (
    <>
      <div>
        <ActionLink onClick={handleResetMfaAssociationRequest}>
          Reassociate MFA
        </ActionLink>
      </div>

      {!!mfaStatusOfCurrentUser &&
        (mfaStatusOfCurrentUser === MFA_STATUS.sms ||
          mfaStatusOfCurrentUser === MFA_STATUS.totp) &&
        useSmsMfa &&
        !isTeamMfaEnforced && (
          <div>
            <ActionLink onClick={handleChangeMfaRequest}>
              Switch to{' '}
              {mfaStatusOfCurrentUser === MFA_STATUS.sms ? 'TOTP' : 'SMS'} MFA
            </ActionLink>
          </div>
        )}
      <SimpleConfirmModal
        isOpen={isConfirmResetMfaAssociationModalOpen}
        header={'Reassociate MFA'}
        body={'Are you sure you wish to reassociate MFA?'}
        shouldSetIsClosingOnClose={false}
        onConfirm={handleConfirmResetMfaAssociation}
        onCancel={() => setIsConfirmResetMfaAssociationModalOpen(false)}
      />
      <SimpleConfirmModal
        isOpen={isConfirmChangeMfaModalOpen}
        header={`Switch to ${
          mfaStatusOfCurrentUser === MFA_STATUS.sms ? 'TOTP' : 'SMS'
        } MFA`}
        body={`Are you sure you wish to switch to ${
          mfaStatusOfCurrentUser === MFA_STATUS.sms ? 'TOTP' : 'SMS'
        } MFA?`}
        shouldSetIsClosingOnClose={false}
        onConfirm={handleConfirmChangeMfa}
        onCancel={() => setIsConfirmChangeMfaModalOpen(false)}
      />
      <SimpleConfirmModal
        isOpen={isMfaResetSuccessModalOpen}
        header={'MFA Reassociation Successful'}
        body={'MFA has been successfully reassociated.'}
        confirmOnly
        shouldSetIsClosingOnClose={false}
        onConfirm={() => setIsMfaResetSuccessModalOpen(false)}
      />
      <SimpleConfirmModal
        isOpen={isChangeMfaTypeSuccessModalOpen}
        header={'MFA Switch Successful'}
        body={'MFA has been successfully switched.'}
        confirmOnly
        shouldSetIsClosingOnClose={false}
        onConfirm={() => setIsChangeMfaTypeSuccessModalOpen(false)}
      />

      <MfaSetupModal
        isOpen={isMfaSetupModalOpen}
        onToggle={handleCloseMfaSetupModalRequest}
      />
    </>
  );
};
