import { lazy } from 'react';
import BudgetTab from 'ReportsModule/components/Budget/BudgetTab';
import CapacityTab from 'ReportsModule/components/Capacity/CapacityTab';
import UtilizationTab from 'ReportsModule/components/Utilization/UtilizationTab';
import ActivityTab from 'ReportsModule/components/Activity/ActivityTab';
import ProfitTab from 'ReportsModule/components/Profit/ProfitReportTable/ProfitReportTab';
import VarianceTab from 'ReportsModule/components/Budget/VarianceTab';
import TimesheetStatusTab from 'ReportsModule/components/Time/TimesheetStatusTable/TimesheetStatusReportTab';
import WorkplanRequestsTab from 'views/projectPlanner/WorkplanRequestsTable/WorkplanRequestsTab';
import BudgetVarianceTab from 'ReportsModule/components/Budget/BudgetVarianceTab';
import WorkloadForecastTab from 'ReportsModule/components/ForecastReport/WorkloadForecastTab';
import BillingForecastTab from 'ReportsModule/components/ForecastReport/BillingForecastTab';
import ScheduleVarianceTab from 'ReportsModule/components/Budget/ScheduleVarianceTab';
import SkillsReportTab from 'ReportsModule/components/Skills/SkillsReportTab';
import CheckInsReportTab from 'ReportsModule/components/CheckIns/CheckInsReportTab';
import TimeProjectionTab from 'ReportsModule/components/TimeProjection/TimeProjectionTab';
import SpentTimeReportTab from 'ReportsModule/components/SpentTime/SpentTimeReportTab';
import PlannedTimeReportTab from 'ReportsModule/components/PlannedTime/PlannedTimeReportTab';
import UnspentBudgetReportTab from 'ReportsModule/components/UnspentBudget/UnspentBudgetReportTab';

const PtoReportPageContainer = lazy(() =>
  import('ReportsModule/components/PtoReport/PtoReportPageContainer')
);

const DemandReportPageContainer = lazy(() =>
  import('ReportsModule/components/Capacity/DemandReportPageContainer')
);

const TimesheetReportPageContainer = lazy(() =>
  import('ReportsModule/components/Time/TimesheetReportPageContainer')
);

const AllProjectsReportTab = lazy(() =>
  import('components/tables/ProjectDetailsTable/AllProjectsReportPageContainer')
);

export const ReportsViews = {
  time: TimesheetReportPageContainer,
  budget: BudgetTab,
  capacity: CapacityTab,
  utilization: UtilizationTab,
  activity: ActivityTab,
  profit: ProfitTab,
  variance: VarianceTab,
  timesheetstatus: TimesheetStatusTab,
  demand: DemandReportPageContainer,
  workplanrequests: WorkplanRequestsTab,
  budgetvariance: BudgetVarianceTab,
  workloadforecast: WorkloadForecastTab,
  billingforecast: BillingForecastTab,
  schedulevariance: ScheduleVarianceTab,
  skills: SkillsReportTab,
  checkins: CheckInsReportTab,
  allprojects: AllProjectsReportTab,
  pto: PtoReportPageContainer,
  timeprojection: TimeProjectionTab,
  spenttime: SpentTimeReportTab,
  plannedtime: PlannedTimeReportTab,
  unspentbudget: UnspentBudgetReportTab
};
