import * as constants from 'appConstants';
import { createAction } from 'appUtils';

export const fetchPhaseTotals = (params) =>
  createAction(constants.FETCH_PHASE_TOTALS.TRIGGER, params);
export const fetchPhaseTotalsByBoard = (params) =>
  createAction(constants.FETCH_PHASE_TOTALS_BY_BOARD.TRIGGER, params);
export const fetchPhaseTotalsBudgetReport = (params) =>
  createAction(constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.TRIGGER, params);
export const fetchPhasesByProjectIds = (params) =>
  createAction(constants.FETCH_PHASES_BY_PROJECT_IDS.TRIGGER, params);
export const fetchPhases = (params) =>
  createAction(constants.FETCH_PHASES.TRIGGER, params);
export const fetchPhaseNames = (params) =>
  createAction(constants.FETCH_PHASE_NAMES.TRIGGER, params);
export const fetchFilteredPhases = (params) =>
  createAction(constants.FETCH_FILTERED_PHASES.TRIGGER, params);

export const createPhase = (params) =>
  createAction(constants.CREATE_PHASE.TRIGGER, params);
export const predictPhase = (params) =>
  createAction(constants.PREDICT_PHASE.TRIGGER, params);
export const createBulkPhases = (params) =>
  createAction(constants.CREATE_BULK_PHASES.TRIGGER, params);
export const updatePhase = (params) =>
  createAction(constants.UPDATE_PHASE.TRIGGER, params);
export const convertPhaseToDefault = (params) =>
  createAction(constants.CONVERT_PHASE_TO_DEFAULT.TRIGGER, params);
export const deletePhase = (params) =>
  createAction(constants.DELETE_PHASE.TRIGGER, params);
export const hardDeletePhase = (params) =>
  createAction(constants.HARD_DELETE_PHASE.TRIGGER, params);
export const archivePhase = (params) =>
  createAction(constants.ARCHIVE_PHASE.TRIGGER, params);
export const predictWorkdaysAndUpdatePhase = (params) =>
  createAction(constants.PREDICT_WORKDAYS_AND_UPDATE_PHASE.TRIGGER, params);

export const fetchPhaseTemplates = (params) =>
  createAction(constants.FETCH_PHASE_TEMPLATES.TRIGGER, params);
export const createPhaseTemplate = (params) =>
  createAction(constants.CREATE_PHASE_TEMPLATE.TRIGGER, params);
export const updatePhaseTemplate = (params) =>
  createAction(constants.UPDATE_PHASE_TEMPLATE.TRIGGER, params);
export const deletePhaseTemplate = (params) =>
  createAction(constants.DELETE_PHASE_TEMPLATE.TRIGGER, params);
export const reorderPhaseTemplates = (params) =>
  createAction(constants.REORDER_PHASE_TEMPLATES.TRIGGER, params);

export const createMilestoneTemplate = (params) =>
  createAction(constants.CREATE_MILESTONE_TEMPLATE.TRIGGER, params);
export const reorderMilestoneTemplates = (params) =>
  createAction(constants.REORDER_MILESTONE_TEMPLATES.TRIGGER, params);

export const openPhaseTemplateDropdown = (params) =>
  createAction(constants.OPEN_PHASE_TEMPLATE_DROPDOWN, params);
export const closePhaseTemplateDropdown = (params) =>
  createAction(constants.CLOSE_PHASE_TEMPLATE_DROPDOWN, params);

export const openPhaseTemplateModal = (params) =>
  createAction(constants.OPEN_PHASE_TEMPLATE_MODAL, params);
export const closePhaseTemplateModal = (params) =>
  createAction(constants.CLOSE_PHASE_TEMPLATE_MODAL, params);

export const openPhaseModal = (params) =>
  createAction(constants.OPEN_PHASE_MODAL, params);
export const closePhaseModal = (params) =>
  createAction(constants.CLOSE_PHASE_MODAL, params);
export const openActivityPhaseModal = (params) =>
  createAction(constants.OPEN_ACTIVITY_PHASE_MODAL, params);
export const closeActivityPhaseModal = (params) =>
  createAction(constants.CLOSE_ACTIVITY_PHASE_MODAL, params);

export const fetchPhaseMembers = (params) =>
  createAction(constants.FETCH_PHASE_MEMBERS.TRIGGER, params);
export const createPhaseMembers = (params) =>
  createAction(constants.CREATE_PHASE_MEMBERS.TRIGGER, params);

export const archivePhaseMember = (params) =>
  createAction(constants.ARCHIVE_PHASE_MEMBER.TRIGGER, params);
export const deletePhaseMember = (params) =>
  createAction(constants.DELETE_PHASE_MEMBER.TRIGGER, params);

export const updatePhaseMembership = (params) =>
  createAction(constants.UPDATE_PHASE_MEMBERSHIP.TRIGGER, params);
