import styled from 'styled-components';
import { AccessRoles } from 'PermissionsModule/types';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import theme from 'theme';

interface AccessLevelSizeIndicatorProps {
  role: AccessRoles;
  isShowGrayedOut?: boolean;
}

const roleToLevel: Record<number, number> = {
  [ACCESS_ROLES.TEAM_MEMBER]: 1,
  [ACCESS_ROLES.LOGIN]: 1,
  [ACCESS_ROLES.WORKLOAD_MANAGER]: 2,
  [ACCESS_ROLES.WORKLOAD_MANAGER_VIEW_ONLY]: 2,
  [ACCESS_ROLES.WORKLOAD_PLANNER_VIEW_ONLY_BASE]: 2,
  [ACCESS_ROLES.WORKLOAD_PlANNER_BASE]: 2,
  [ACCESS_ROLES.FINANCIAL_MANAGER]: 3,
  [ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY]: 3,
  [ACCESS_ROLES.FINANCIAL_MANAGER_VIEW_ONLY_BASE]: 3,
  [ACCESS_ROLES.FINANCIAL_MANAGER_BASE]: 3,
  [ACCESS_ROLES.ADMIN]: 4
} as const;

export const AccessLevelSizeIndicator = ({
  role,
  isShowGrayedOut = false
}: AccessLevelSizeIndicatorProps) => {
  const levelFromRole = roleToLevel[role];
  if (!levelFromRole) return <></>;

  return (
    <RootContainer>
      {[...Array(4)].map((e, i) =>
        i < levelFromRole ? (
          <BlueIndicator key={i} $isShowGrayedOut={isShowGrayedOut} />
        ) : (
          <GreyIndicator key={i} $isShowGrayedOut={isShowGrayedOut} />
        )
      )}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
`;

const GreyIndicator = styled.div<{ $isShowGrayedOut: boolean }>`
  height: 7px;
  margin-left: 2px;
  width: 7px;
  border-radius: 50%;
  background: ${theme.colors.colorLightGray4};
  ${(props) => props.$isShowGrayedOut && 'opacity: 0.6;'}
`;

const BlueIndicator = styled(GreyIndicator)`
  background: ${theme.colors.colorRoyalBlue};
`;
