import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${theme.colors.colorMediumGray9};
  word-break: break-all;

  hr {
    background: ${theme.colors.colorLightGray6};
    margin: 5px 0;
    width: 100%;
  }
`;

const Label = styled.div<{
  fontColor?: string;
}>`
  color: ${({ fontColor }) => fontColor || theme.colors.colorMediumGray9};
  text-align: left;
  &.bold {
    font-weight: 600;
  }

  &.center {
    margin: 0 auto;
  }
`;

const Value = styled.div`
  display: inline;
  font-weight: 600;
  text-align: right;
  flex-grow: 1;
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
`;

type GenerateAccountTooltipDataProps = {
  rows: Array<{
    label: string;
    value?: string;
    labelClassName?: string;
    shouldInsertDivider?: boolean;
    fontColor?: string;
  }>;
};

export const generateRowsTooltipData = ({
  rows = []
}: GenerateAccountTooltipDataProps) => {
  return ReactDOMServer.renderToString(
    <Container>
      <RowsContainer>
        {rows.map((row, index) => {
          const {
            label,
            shouldInsertDivider = false,
            value = undefined,
            labelClassName = '',
            fontColor = undefined
          } = row;

          return (
            <>
              <Row key={index}>
                <Label fontColor={fontColor} className={labelClassName}>
                  {label || ''}
                </Label>
                {value && <Value>{value}</Value>}
              </Row>
              {shouldInsertDivider && <hr />}
            </>
          );
        })}
      </RowsContainer>
    </Container>
  );
};

/* -------------------------------------------------------------------------- */
