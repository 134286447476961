import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  getPhaseModalOpen,
  getModalPhaseId,
  getIsDeletePhaseFlag,
  getModalPhaseHasTimeEntriesOrWorkplans,
  getIsEditInfoModalFlag,
  getFlatPhasesHash,
  getIsAddMilestone
} from 'selectors';
import { closePhaseModal, archivePhase } from 'actionCreators';
import PhaseModalBody from './PhaseModalBody';
import GlobalAddEditHeader from '../PhaseTemplateModal/GlobalAddEditHeader';
import FeeModal from 'BudgetModule/components/BudgetTable/FeeModal';

class PhaseModal extends React.Component {
  handleClose = () => {
    const {
      closePhaseModal,
      isDelete,
      hasTimeEntries,
      phasesHash,
      id,
      archivePhase
    } = this.props;

    if (isDelete && hasTimeEntries && !phasesHash[id].archived) {
      archivePhase({
        id,
        projectId: phasesHash[id].project_id
      });
    }
    closePhaseModal({});
  };

  renderCopy = () => {
    const { isDelete, isNew, hasTimeEntries, phasesHash, id, isAddMilestone } =
      this.props;

    const typeText = phasesHash[id]?.is_budget ? 'Phase' : 'Milestone';
    if (isDelete) {
      if (hasTimeEntries) {
        // if has time entries and is not archived
        return phasesHash[id]?.archived
          ? `Delete ${typeText}`
          : `${typeText} Has Been Archived`;
      }
      return `Delete/Edit ${typeText} of Work`;
    } else if (isNew) {
      return isAddMilestone ? `Add Milestone` : `Custom Phase`;
    } else {
      return `Edit ${typeText} Of Work`;
    }
  };

  render() {
    const {
      open,
      id,
      isNew,
      isEditInfoModal,
      onDeleteSuccess,
      closePhaseModal
    } = this.props;
    if (isEditInfoModal) {
      return <FeeModal phaseId={id} handleClose={this.handleClose} />;
    }
    return (
      <Modal isOpen={open} toggle={this.handleClose} className="phase-modal">
        <GlobalAddEditHeader
          handleClose={this.handleClose}
          copy="Phase of Work"
          id={id}
          renderCopy={this.renderCopy}
        />
        <PhaseModalBody
          handleClose={this.handleClose}
          onDeleteSuccess={onDeleteSuccess}
          isModalOpen={open}
          closePhaseModal={closePhaseModal}
        />
      </Modal>
    );
  }
}

PhaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closePhaseModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  open: getPhaseModalOpen(state),
  id: getModalPhaseId(state),
  isNew: !getModalPhaseId(state),
  isDelete: getIsDeletePhaseFlag(state),
  isEditInfoModal: getIsEditInfoModalFlag(state),
  hasTimeEntries: getModalPhaseHasTimeEntriesOrWorkplans(state),
  phasesHash: getFlatPhasesHash(state),
  isAddMilestone: getIsAddMilestone(state)
});

const mapDispatchToProps = {
  closePhaseModal,
  archivePhase
};

export default connect(mapStateToProps, mapDispatchToProps)(PhaseModal);
