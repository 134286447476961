import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface DeleteIconProps
  extends Pick<SvgIconProps, 'className' | 'width' | 'height' | 'onClick'> {
  testId?: string;
}

export const DeleteIcon = ({
  className,
  width = 12,
  height = 15,
  onClick,
  testId
}: DeleteIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="#808080"
    viewBox={`0 0 ${width} ${height}`}
    onClick={onClick}
    data-testid={testId}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.796889 3.98444V13.9456C0.796889 14.1655 0.975392 14.344 1.19533 14.344H10.758C10.9779 14.344 11.1564 14.1655 11.1564 13.9456V3.98444H11.5549C11.7748 3.98444 11.9533 3.80594 11.9533 3.586V1.99222C11.9533 1.77228 11.7748 1.59378 11.5549 1.59378H7.96889V0.398444C7.96889 0.178503 7.79039 0 7.57044 0H4.38289C4.16295 0 3.98444 0.178503 3.98444 0.398444V1.59378H0.398444C0.178503 1.59378 0 1.77228 0 1.99222V3.586C0 3.80594 0.178503 3.98444 0.398444 3.98444H0.796889ZM10.2214 13.3946H1.73189V4.13508H10.2214V13.3946ZM4.78133 0.796889H7.172V1.59378H4.78133V0.796889ZM0.796889 2.37976H11.1552V3.20476H0.796889V2.37976Z"
      fill="#4f4f4f"
      fillOpacity="0.983639"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.76279 11.5958V5.82083C3.76279 5.64373 3.53068 5.5 3.24468 5.5C2.95868 5.5 2.72656 5.64373 2.72656 5.82083V11.5958C2.72656 11.7729 2.95868 11.9167 3.24468 11.9167C3.53068 11.9167 3.76279 11.7729 3.76279 11.5958Z"
      fill="#4f4f4f"
      fillOpacity="0.983639"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7042 11.5958V5.82083C8.7042 5.64373 8.47208 5.5 8.18608 5.5C7.90008 5.5 7.66797 5.64373 7.66797 5.82083V11.5958C7.66797 11.7729 7.90008 11.9167 8.18608 11.9167C8.47208 11.9167 8.7042 11.7729 8.7042 11.5958Z"
      fill="#4f4f4f"
      fillOpacity="0.983639"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.15342 11.5958V5.82083C6.15342 5.64373 5.9213 5.5 5.6353 5.5C5.3493 5.5 5.11719 5.64373 5.11719 5.82083V11.5958C5.11719 11.7729 5.3493 11.9167 5.6353 11.9167C5.9213 11.9167 6.15342 11.7729 6.15342 11.5958Z"
      fill="#4f4f4f"
      fillOpacity="0.983639"
    />
  </SvgIcon>
);

export default DeleteIcon;
