import { batch } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import {
  createAccountPosition,
  deleteAccountPosition,
  archivePosition,
  createPosition,
  updatePosition
} from 'BudgetModule/actionCreators';
import { getFormattedPositionsWithMembers } from 'BudgetModule/selectors/positions';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { SettingTableTemplateWithMemberCells } from '../SettingTableTemplate/SettingTableTemplateWithMemberCells';
import { useMemo, useState } from 'react';
import { GroupData, RowDataWithGroupId } from '../SettingTableTemplate/types';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { ReactComponent as SmallPencilIcon } from 'icons/small-pencil.svg';
import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import { AccountPosition } from 'models/position';

export const RolesTable = () => {
  const [toDeleteOrArchiveGroupData, setToDeleteOrArchiveGroupData] = useState<
    { data: GroupData; action: 'delete' | 'archive' } | undefined
  >();
  const [editingGroupId, setEditingGroupId] = useState<number | undefined>();
  const [isDeleteOrArchiveModalOpen, setIsDeleteOrArchiveModalOpen] =
    useState(false);

  const teamId = useAppSelector(getSelectedTeamId);
  const formattedPositionsWithMembers = useAppSelector(
    getFormattedPositionsWithMembers
  );

  const dispatch = useAppDispatch();

  const { groupData, rowData } = useMemo(() => {
    const groupData: GroupData[] = [];
    const rowData: RowDataWithGroupId<AccountPosition>[] = [];

    formattedPositionsWithMembers.forEach(
      ({ id, name, formattedPositionMembersInfo }) => {
        groupData.push({
          id,
          label: name,
          totalCount: formattedPositionMembersInfo.length
        });

        formattedPositionMembersInfo.forEach((positionMemberInfo) =>
          rowData.push({
            data: positionMemberInfo.accountPosition,
            groupId: id,
            teamMember: positionMemberInfo.member
          })
        );
      }
    );

    return { groupData, rowData };
  }, [formattedPositionsWithMembers]);

  const handleAddNewRole = ({ label }: { label: string }) => {
    if (teamId) {
      dispatch(createPosition({ teamId, name: label }));
    }
  };

  const handleToggleRoleDelete = () => {
    setIsDeleteOrArchiveModalOpen(false);
    setToDeleteOrArchiveGroupData(undefined);
  };

  const handleConfirmDeleteRole = () => {
    setIsDeleteOrArchiveModalOpen(false);

    if (toDeleteOrArchiveGroupData) {
      const { data, action } = toDeleteOrArchiveGroupData;

      if (action === 'delete') {
        // actually deleting a position is not supported by BE yer, as per Nima, making deleting a role actually trigger archiving it
        // dispatch(deletePosition({id: data.id}));
        dispatch(archivePosition({ id: data.id }));
      } else if (action === 'archive') {
        dispatch(archivePosition({ id: data.id }));
      }
    }

    setToDeleteOrArchiveGroupData(undefined);
  };

  const handleEditGroup = ({ id, label }: { id: number; label: string }) => {
    setEditingGroupId(undefined);

    if (label.trim().length > 0) {
      dispatch(updatePosition({ id, name: label }));
    }
  };

  const handleRemoveMemberFromRole = (
    rowData: RowDataWithGroupId<AccountPosition>
  ) => {
    dispatch(
      deleteAccountPosition({
        id: rowData.data.id,
        accountId: rowData.teamMember.account.id
      })
    );
  };

  const handleSelectMembers = ({
    groupData,
    selectedMembers
  }: {
    groupData: GroupData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedMembers: any[];
  }) => {
    batch(() => {
      selectedMembers.forEach((member) =>
        dispatch(
          createAccountPosition({
            accountId: member.account.id,
            positionId: groupData.id
          })
        )
      );
    });
  };

  return (
    <>
      <RootContainer>
        <SettingTableTemplateWithMemberCells
          useGroup
          groupData={groupData}
          rowData={rowData}
          headerRowProps={{
            buttonProps: {
              label: 'Roles'
            }
          }}
          groupRowProps={{
            groupRowMenus: [
              {
                label: 'Edit Role Name',
                icon: <SmallPencilIcon />,
                onClick: (data) => setEditingGroupId(data.id)
              },
              {
                label: 'Archive Role',
                icon: <ArchiveIcon />,
                onClick: (data) => {
                  setToDeleteOrArchiveGroupData({ data, action: 'archive' });
                  setIsDeleteOrArchiveModalOpen(true);
                }
              },
              {
                label: 'Delete Role',
                icon: <DeleteIcon />,
                onClick: (data) => {
                  setToDeleteOrArchiveGroupData({ data, action: 'delete' });
                  setIsDeleteOrArchiveModalOpen(true);
                }
              }
            ],
            onAddGroupInline: handleAddNewRole,
            useInlineCreation: true,
            editingGroupId,
            onEditGroupInline: handleEditGroup
          }}
          memberRowProps={{ onRemoveRow: handleRemoveMemberFromRole }}
          onSelectMembers={handleSelectMembers}
        />
      </RootContainer>
      <DeleteModal
        isOpen={isDeleteOrArchiveModalOpen}
        component={'role'}
        isArchiveAction={toDeleteOrArchiveGroupData?.action === 'archive'}
        toggle={handleToggleRoleDelete}
        deleteOnClick={handleConfirmDeleteRole}
      />
    </>
  );
};

const RootContainer = styled.div`
  height: 100%;
`;
