import { MemberPermissionListItem } from '../types';
import { BaseCellRootContainer } from './styles';
import styled from 'styled-components';
import { teamUtils } from 'TeamsModule/utils';

export const MembersPermissionsTableEmailCell = ({ row }) => {
  const { teamMember, onRequestEditMemberMetadata } =
    row.original as MemberPermissionListItem;
  return (
    <BaseCellRootContainer
      onClick={() =>
        onRequestEditMemberMetadata({ teamMembershipId: teamMember.id })
      }
    >
      <ContentContainer>
        <TextContainer
          $isArchived={teamUtils.getIsArchived(teamMember)}
          className="text-container"
        >
          {teamMember.account.email}
        </TextContainer>
      </ContentContainer>
    </BaseCellRootContainer>
  );
};

const TextContainer = styled.div<{ $isArchived: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
