import { createReducer, current, Draft } from '@reduxjs/toolkit';
import { updateWorkPlanTasksApiActions } from 'TaskModule/actionCreators/workPlanTaskAssociationActionCreators';
import { HomeTasksState } from './types';
import homeTasks from 'reducers/homeTasks';
import { homeTasksInitialState } from './constants';

const handleUpdateWorkPlanTasksSuccess = (
  state: Draft<HomeTasksState>,
  action: ReturnType<typeof updateWorkPlanTasksApiActions.success>
) => {
  const {
    payload: {
      response: { updates }
    }
  } = action;

  const hoursByWorkPlan = state.workPlanTaskHours;
  if (hoursByWorkPlan)
    updates.reduce(
      (
        acc,
        { activity_phase_schedule_bar_id, estimated_hours, project_task_id }
      ) => {
        // Only update the estimated hours if they were previously loaded.
        const hoursByTask = hoursByWorkPlan[activity_phase_schedule_bar_id];
        if (hoursByTask && project_task_id in hoursByTask) {
          hoursByTask[project_task_id] = estimated_hours;
        }

        return acc;
      },
      state.taskHash
    );
};

export const homeTasksReducer = createReducer(
  homeTasksInitialState,
  (builder) => {
    // Work Plan-Task Association - Update
    builder.addCase(
      updateWorkPlanTasksApiActions.success,
      handleUpdateWorkPlanTasksSuccess
    );

    // Legacy reducers
    builder.addDefaultCase(homeTasks);
  }
);
