import KaratRight from 'icons/KaratRight';
import { PhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { StyledKaratContainer } from '../shared/style';

const CollapseCell = ({ row }: PhaseGroupRowProps) => {
  const { isOpen, toggleCollapse } = row.original;

  return (
    <StyledKaratContainer isOpen={isOpen} onClick={toggleCollapse}>
      <KaratRight />
    </StyledKaratContainer>
  );
};

export default CollapseCell;
