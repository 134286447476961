import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledFolderIcon } from 'ReportsModule/components/styles';
import {
  GroupedList,
  FilterListsTableCustomRowProps,
  FilterListsTableVariant
} from 'FilterModule/components/FilterListsTable/types';
import { FilterListType } from 'FilterModule/constants';
import { Board } from 'models/board';
import BoardDropdown from 'views/layoutStructuralComponents/MyProjectSideBarContainer/BoardDropdown';
import { PERSONAL_BOARD_NAMES } from 'appConstants';

export const PortfolioLabel = ({
  rowData,
  setIsHidingCounts,
  customRowProps
}: {
  rowData: GroupedList;
  setIsHidingCounts?: (value: boolean) => void;
  customRowProps?: FilterListsTableCustomRowProps;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { entity, filterListType, labelText } = rowData;

  const { tableConfig } = customRowProps || {};

  const isBoard = getIsBoard(entity, filterListType);

  useEffect(() => {
    if (setIsHidingCounts) {
      if (isDropdownOpen) {
        setIsHidingCounts(true);
      } else {
        setIsHidingCounts(false);
      }
    }
  }, [setIsHidingCounts, isDropdownOpen]);

  return (
    <>
      <BoardIcon
        boardId={entity.id}
        className={undefined}
        id={undefined}
        color={undefined}
      />
      <span className="no-text-overflow">
        {PERSONAL_BOARD_NAMES[labelText] || labelText}
      </span>

      {isBoard && (
        <StyledDropdownContainer
          className="board-dropdown-container"
          tableVariant={tableConfig?.variant}
        >
          <BoardDropdown
            board={entity}
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
          />
        </StyledDropdownContainer>
      )}
    </>
  );
};

/* ------------------------------------ - ----------------------------------- */

const boardFilterListTypesSet = new Set([
  FilterListType.MembersByPortfolio,
  FilterListType.ProjectsByPortfolio
]);

const getIsBoard = (
  entity: GroupedList['entity'],
  filterListType: FilterListType
): entity is Board =>
  'name' in entity && boardFilterListTypesSet.has(filterListType);

const BoardIcon = styled(StyledFolderIcon)`
  margin-right: 1px;
`;

const StyledDropdownContainer = styled.div<{
  tableVariant?: FilterListsTableVariant;
}>`
  position: absolute;
  right: 15px;
  visibility: hidden;

  svg {
    ${({ tableVariant }) =>
      tableVariant === 'SideFilter' && 'top: -3px !important;'}
  }

  .label-cell:hover & {
    visibility: visible;
  }
`;
