import { MouseEvent, useCallback } from 'react';
import { CheckboxItemType } from 'SettingsModule/types/checkBoxOptions';
import { PermissionCheckboxInput } from './PermissionCheckboxInput';
import { HandlePermissionSelectionCheck } from './PermissionSelectionContainer';
import { PermissionCheckbox } from './styles';

interface PermissionCheckboxContainerProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onCheck: (item: HandlePermissionSelectionCheck) => void;
  item: CheckboxItemType;
  teamMembershipIds: number[];
}

export const PermissionCheckboxContainer = ({
  isChecked,
  isDisabled = false,
  onCheck,
  item,
  teamMembershipIds
}: PermissionCheckboxContainerProps) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!isDisabled) onCheck({ item, isChecked: !isChecked });
    },
    [isDisabled, item, onCheck, isChecked]
  );

  return (
    <PermissionCheckbox>
      <PermissionCheckboxInput
        isChecked={isChecked}
        isDisabled={isDisabled}
        item={item}
        onClick={handleClick}
      />
    </PermissionCheckbox>
  );
};
