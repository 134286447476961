import 'isomorphic-fetch';
import qs from 'qs';
import { callApi } from 'appUtils/api';

export const fetchProfitReport = (params, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `profit_report?${qs.stringify(params, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
