import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

const {
  OPEN_PROJECT_MODAL,
  CLOSE_PROJECT_MODAL,
  FETCH_PROJECTS,
  EDIT_PROJECT_COMMENT,
  DELETE_PROJECT_COMMENT,
  CREATE_PROJECT_COMMENT,
  PROJECT
} = constants;

const initialState = {
  isOpen: false,
  projectId: null,
  projects: {}
};

const extractComments = (projectsArr) => {
  return projectsArr.reduce((acc, project) => {
    return {
      ...acc,
      [project.id]: {
        ...keyBy(project.status, 'id')
      }
    };
  }, {});
};

const formatComment = ({
  id,
  description,
  creation_date,
  account,
  attachments,
  replies
}) => ({
  id,
  description,
  creation_date,
  account,
  attachments,
  replies
});

const projectComments = (state = initialState, action) => {
  const { type, payload } = action;
  let { response, description, projectId, commentId } = payload || {};
  const { projects } = response || {};

  projectId = response && response.project_id ? response.project_id : projectId;
  commentId = response && response.id ? response.id : commentId;

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case OPEN_PROJECT_MODAL: {
      return {
        ...state,
        isOpen: true,
        projectId: projectId
      };
    }
    case CLOSE_PROJECT_MODAL: {
      return {
        ...state,
        isOpen: false,
        projectId: null
      };
    }
    case FETCH_PROJECTS.SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          ...extractComments(projects)
        }
      };
    }
    case PROJECT.SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          ...extractComments(projects) // single project fetch returns size 1 array haHAA
        }
      };
    }
    case DELETE_PROJECT_COMMENT.TRIGGER: {
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: omit(state.projects[projectId], [commentId])
        }
      };
    }
    case EDIT_PROJECT_COMMENT.SUCCESS:
    case CREATE_PROJECT_COMMENT.SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            [commentId]: formatComment(response)
          }
        }
      };
    }
    case constants.WS_PROJECT_COMMENT: {
      const { destroyed, ...payload } = action.payload;
      const { project_id, id } = payload;
      if (destroyed) {
        return {
          ...state,
          projects: {
            ...state.projects,
            [project_id]: omit(state.projects[project_id], [id])
          }
        };
      }
      return {
        ...state,
        projects: {
          ...state.projects,
          [project_id]: {
            ...state.projects[project_id],
            [id]: formatComment(payload)
          }
        }
      };
    }
    case constants.DELETE_PROJECT_ATTACHMENT.SUCCESS: {
      const {
        id,
        entity_id, // entity_id is comment_id
        parent_id,
        entity_type // entity_type is comment_type
      } = action.payload.response;

      // is part of reply
      if (parent_id) {
        const parentCommentId = parent_id;
        const newReplies = state.projects[projectId][parentCommentId].replies
          .map((reply) => {
            if (reply.id === entity_id) {
              const newAttachments = reply.attachments.filter(
                (attachment) => attachment.id !== id
              );

              return {
                ...reply,
                attachments: newAttachments
              };
            }
            return reply;
          })
          .filter((reply) => {
            return reply.attachments.length || reply.description;
          });

        return {
          ...state,
          projects: {
            ...state.projects,
            [projectId]: {
              ...state.projects[projectId],
              [parentCommentId]: {
                ...state.projects[projectId][parentCommentId],
                replies: newReplies
              }
            }
          }
        };
      } else {
        commentId = entity_id;
        const newAttachments = state.projects[projectId][
          commentId
        ].attachments.filter((attachment) => attachment.id !== id);

        if (
          newAttachments.length ||
          state.projects[projectId][commentId].description
        ) {
          return {
            ...state,
            projects: {
              ...state.projects,
              [projectId]: {
                ...state.projects[projectId],
                [commentId]: {
                  ...state.projects[projectId][commentId],
                  attachments: newAttachments
                }
              }
            }
          };
        } else {
          const newComments = state.projects[projectId];
          delete newComments[commentId];
          return {
            ...state,
            projects: {
              ...state.projects,
              [projectId]: newComments
            }
          };
        }
      }
    }

    default:
      return state;
  }
};

export default projectComments;
