import { makeAuthorBasedVerification } from './makeVerification';

export const verifyCreate = makeAuthorBasedVerification(
  'create_time_entries',
  'create_time_entries_other'
);
export const verifyRead = makeAuthorBasedVerification(
  'read_time_entries',
  'read_time_entries_other'
);

export const verifyEdit = makeAuthorBasedVerification(
  'edit_time_entries',
  'edit_time_entries_other'
);

export const verifyDelete = makeAuthorBasedVerification(
  'delete_time_entries',
  'delete_time_entries_other'
);
