import {
  FetchEntityRequirementsInitialPayload,
  UpdateEntityRequirementsInitialPayload
} from '../actionCreators/types';
import { callApi } from 'appUtils/api';
import qs from 'qs';

export const fetchEntityRequirements = (
  _,
  token: string,
  params: FetchEntityRequirementsInitialPayload
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const url = `entity_associations?${qs.stringify(params, {
    arrayFormat: 'brackets'
  })}`;

  return callApi(url, token, fetchInit);
};

export const updateEntityRequirements = (
  token: string,
  params: UpdateEntityRequirementsInitialPayload
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  const url = `entity_associations`;

  return callApi(url, token, fetchInit);
};
