import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Collapse from 'rc-collapse';
import {
  setFilterTagId,
  triggerFetchInitialTasks,
  triggerTaskStoreFlush
} from 'actionCreators';
import {
  getAllTaskTags,
  getAuth,
  getAuthToken,
  getSearchText,
  getIsOnProjectView,
  getMatchedRouteParams
} from 'selectors';
import theme from 'theme';
import FilterIcon from 'icons/FilterIcon';
import Tag from './Tag';
import KaratRight from 'icons/KaratRight';

const { Panel } = Collapse;

export const StyledKaratRight = styled(KaratRight)`
  height: 12px;
  width: 12px;
  transition: 0.2s ease-in-out;
  margin-left: 6px;
  margin-top: ${({ isOpen }) => (isOpen ? '2px' : '0')};
  transform: ${({ isOpen }) => (!isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};

  path {
    fill: ${theme.colors.colorMediumGray5};
  }
`;

export const TaskTagWrapper = styled.div`
  width: 100%;
  list-style: none;
  overflow: hidden;
`;

const TaskTagGhostText = styled.div`
  font-size: 12px;
  color: #b3b3b3;
  padding-left: 26px;
`;

const StyledTagHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.colorMediumGray5};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${StyledKaratRight} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  margin-right: 5px;
`;

class TaskTagsList extends Component {
  state = {
    selectedTagName: '',
    filterId: null,
    filterOpen: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      path,
      matchedParams: { projectId }
    } = this.props;
    const isChangingProjects = projectId !== nextProps.matchedParams.projectId;
    const isChangingTabs = path !== nextProps.path;
    const currentTagsIds = this.props.allTaskTags.map((tag) => tag.id);
    const nextTagsIds = nextProps.allTaskTags.map((tag) => tag.id);
    const filteredTagBeingDeleted =
      this.state.filterId &&
      currentTagsIds.length !== nextTagsIds.length &&
      currentTagsIds.includes(this.state.filterId) &&
      !nextTagsIds.includes(this.state.filterId);

    if (isChangingTabs) {
      setFilterTagId(null);
    }

    if (filteredTagBeingDeleted || isChangingProjects) {
      this.filterByTag(null, '', nextProps);
    }
  }

  componentWillUnmount() {
    const { setFilterTagId } = this.props;
    setFilterTagId(null);
  }

  mapTags = (tag) => {
    const { selectedTagName } = this.state;
    return (
      <Tag
        key={tag.id}
        tag={tag}
        filterByTag={this.filterByTag}
        isActiveFilter={selectedTagName === tag.title}
      />
    );
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      filterOpen: !prevState.filterOpen
    }));
  };

  renderTags() {
    const { allTaskTags } = this.props;
    if (allTaskTags.length > 0) {
      return allTaskTags.map(this.mapTags);
    } else {
      return <TaskTagGhostText />;
    }
  }

  renderTagsWrapper() {
    return <TaskTagWrapper>{this.renderTags()}</TaskTagWrapper>;
  }

  filterByTag = (id, title, nextProps) => {
    const { selectedTagName, filterId } = this.state;
    const {
      setFilterTagId,
      triggerTaskStoreFlush,
      isOnProjectView,
      triggerFetchInitialTasks
    } = nextProps || this.props;
    const isSelected = filterId === id || selectedTagName === title;

    this.setState({
      filterId: isSelected ? null : id,
      selectedTagName: selectedTagName === title ? '' : title
    });
    const tagToFilter = isSelected ? null : id;

    setFilterTagId(tagToFilter);
    if (isOnProjectView) triggerTaskStoreFlush();
    !isOnProjectView && triggerFetchInitialTasks({});
  };

  render() {
    return (
      <Collapse
        accordion={true}
        defaultActiveKey={'2'}
        onChange={this.toggleFilter}
      >
        <Panel
          key="1"
          headerClass="tags-header-container"
          showArrow={false}
          header={
            <StyledTagHeader
              className="tag-headers-container"
              data-for="app-tooltip"
              data-html
              data-effect="solid"
              place="top"
              data-tip={
                "Click to view Tags.<br /> Type '#' in any task <br />title to create a tag."
              }
            >
              Tags
              <StyledKaratRight isOpen={this.state.filterOpen} />
            </StyledTagHeader>
          }
        >
          {this.renderTagsWrapper()}
        </Panel>
      </Collapse>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const path = ownProps.location.pathname;
  return {
    token: getAuthToken(state),
    path,
    auth: getAuth(state),
    allTaskTags: getAllTaskTags(state),
    searchText: getSearchText(state),
    isOnHomeView: state.router.location.pathname.indexOf('/home/') > -1,
    isOnFollowed: state.router.location.pathname.indexOf('/followed') > -1,
    matchedParams: getMatchedRouteParams(state),
    isOnProjectView: getIsOnProjectView(state)
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setFilterTagId,
    triggerFetchInitialTasks,
    triggerTaskStoreFlush
  })(TaskTagsList)
);
