import styled from 'styled-components';

const PMDot = styled.div`
  height: 10px;
  width: 10px;
  border: solid 2px #fff;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  ${(props) =>
    props.overwriteDotPosition
      ? `right: -1px; top: -1px;`
      : `right: -2px; top: -10px;`}
`;
// overwrite bc in certain places with styled components it's overwritten to be -1px. need this for it to be correctly positioned in manage project members dropdown

export default PMDot;
