import LoadTasksListContainer from 'views/Home/TaskList/LoadTasksListContainer';
import LoadProjectNotesContainer from 'NoteModule/components/LoadProjectNotesContainer';
import LoadBudgetContainer from 'BudgetModule/components/LoadBudgetContainer';
import TimelineContainer from 'views/projectDisplay/TimelineContainer';
import ProjectInfoTabContainer from 'views/projectDisplay/ProjectInfoTabContainer';
import LoadScopeContainer from 'components/Scope/ScopeTable/LoadScopeContainer';

export const ProjectDetailViews = {
  tasks: LoadTasksListContainer,
  notes: LoadProjectNotesContainer,
  budget: LoadBudgetContainer,
  schedule: TimelineContainer,
  info: ProjectInfoTabContainer,
  scopes: LoadScopeContainer
};
