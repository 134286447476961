import { Constructor } from 'type-fest';
import { EventEmitter } from './EventEmitter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EventEmitterMixin = <TBase extends Constructor<any>>(
  SuperClass: TBase
) =>
  class extends SuperClass {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(args[0]);
    }

    public readonly emitter = new EventEmitter();
  };
