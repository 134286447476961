import styled from 'styled-components';
import theme from 'theme';
import FilledArrow from 'icons/FilledArrow';
import { makeShowArchivedRow } from '../tableConfigs';

export const ShowArchivedCell = ({
  row
}: {
  row: {
    original: ReturnType<typeof makeShowArchivedRow>;
  };
}) => {
  const {
    toggleIsArchivedShowing,
    numArchived,
    paddingLeft,
    isShowingArchived
  } = row.original;

  return (
    <StyledShowArchivedRow
      onClick={toggleIsArchivedShowing}
      paddingLeft={paddingLeft}
      isShowingArchived={isShowingArchived}
    >
      <FilledArrow />
      <span>
        {isShowingArchived ? 'Hide Archived' : `${numArchived} Archived`}
      </span>
    </StyledShowArchivedRow>
  );
};

// -----------------------------------------------------------------------------

const StyledShowArchivedRow = styled.div<{
  paddingLeft?: number;
  isShowingArchived?: boolean;
}>`
  height: 100%;
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft}px`};

  svg {
    margin-right: 7px;
    ${(props) => props.isShowingArchived && `transform: rotate(180deg);`};
  }

  &:hover {
    filter: brightness(85%);
  }
`;
