import React from 'react';
import theme from 'theme';
import { connect } from 'react-redux';
import { makeGetBoardById } from 'selectors';
import { addMemberToProject, fetchPhasesByProjectIds } from 'actionCreators';
import {
  StyledProjectInfo,
  StyledProjectTitle,
  StyledProjectDescription,
  StyledPhaseNameContainer,
  StyledPhaseName,
  StyledPhaseNumber,
  PhaseProjectListItem,
  StyledProjectInfoContainer,
  StyledSelectPhaseText,
  TimesheetTopCell,
  TimesheetBottomCell,
  StyledProjectNumber,
  StyledFolderIcon,
  StyledTeamName,
  StyledDot,
  StyledPhaseStatus,
  StyledInLineContainer,
  LightBulbIconContainer,
  TitleAndDescriptionContainer
} from './styles';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';
import { PHASE_BUDGET_STATUS_DISPLAY } from 'appConstants/budgetStatuses';
import LightBulbIcon from 'icons/LightBulbIcon';
class Row extends React.Component {
  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  componentWillUnmount() {
    ReactTooltip.hide();
  }

  render() {
    const {
      item,
      board,
      showSelectPhaseText,
      itemHeight,
      showPhaseStatus,
      classes,
      isSuggestedPhase
    } = this.props;

    return (
      item && (
        <PhaseProjectListItem
          data-for="app-tooltip"
          data-html="true"
          data-class="project-info-tooltips"
          data-tip={`${item.title} <br /> ${
            item.description ? `${item.description}` : ''
          }`}
          itemHeight={itemHeight}
          hasHoverBackground={
            item?.is_phase || (!item?.is_phase && item?.defaultOrMainPhaseId)
          }
          unselectable={'true'}
          className={`${classes}`}
          showPhaseStatus={showPhaseStatus}
        >
          {item.is_phase ? (
            <StyledPhaseNameContainer style={{ paddingLeft: '30px' }}>
              {item?.is_budget ? (
                <BudgetPhaseMilestoneIcon
                  strokeColor={theme.colors.colorMediumGray9}
                  strokeWidth="1.5"
                />
              ) : (
                <PhaseMilestoneIcon
                  strokeColor={theme.colors.colorMediumGray9}
                  strokeWidth="1.5"
                />
              )}
              {showPhaseStatus ? (
                <StyledInLineContainer>
                  <StyledPhaseStatus>
                    {PHASE_BUDGET_STATUS_DISPLAY[
                      item.budget_status
                    ].toUpperCase()}
                  </StyledPhaseStatus>
                  <StyledPhaseNumber>{item.phase_number}</StyledPhaseNumber>
                </StyledInLineContainer>
              ) : (
                <StyledPhaseNumber>{item.phase_number}</StyledPhaseNumber>
              )}
              <StyledPhaseName>{item.title} </StyledPhaseName>
              {isSuggestedPhase && (
                <LightBulbIconContainer
                  data-for="app-tooltip"
                  data-html="true"
                  data-tip={'Suggested Phase'}
                  className={'phase'}
                >
                  <LightBulbIcon fill={theme.colors.colorRoyalBlue} />
                </LightBulbIconContainer>
              )}
            </StyledPhaseNameContainer>
          ) : (
            <StyledProjectInfoContainer>
              <TimesheetTopCell style={{ marginLeft: '10px' }}>
                {(item.number || item.project_number) && (
                  <StyledProjectNumber>
                    {item.number || item.project_number}
                  </StyledProjectNumber>
                )}
                <StyledTeamName>
                  <StyledFolderIcon />
                  <div className="no-text-overflow">
                    {(board && board.name) || item.board?.name || 'Personal'}
                  </div>
                </StyledTeamName>
              </TimesheetTopCell>
              <StyledProjectInfo style={{ marginLeft: '10px' }}>
                <StyledDot projectId={item.id} />
                <TitleAndDescriptionContainer>
                  <StyledProjectTitle>{item.title}</StyledProjectTitle>
                  {item.description && (
                    <StyledProjectDescription>
                      {item.description}
                    </StyledProjectDescription>
                  )}
                </TitleAndDescriptionContainer>
              </StyledProjectInfo>
              <TimesheetBottomCell>
                {!item.defaultOrMainPhaseId && showSelectPhaseText && (
                  <StyledSelectPhaseText>
                    Select phase below
                  </StyledSelectPhaseText>
                )}
              </TimesheetBottomCell>
            </StyledProjectInfoContainer>
          )}
        </PhaseProjectListItem>
      )
    );
  }
}

const boardIdGetter = (state, ownProps) => {
  return ownProps.item && ownProps.item.board_id;
};
const makeMapStateToProps = () => {
  const getBoardById = makeGetBoardById({ boardIdGetter });
  const mapStateToProps = (state, ownProps) => ({
    board: getBoardById(state, ownProps)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  fetchPhasesByProjectIds,
  addMemberToProject
};
export default connect(makeMapStateToProps, mapDispatchToProps)(Row);
