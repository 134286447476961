import SvgIcon from 'components/SvgIcon';
import { ComponentProps } from 'react';

const CalendarIconV2 = ({
  color = '#808080',
  width = '17',
  height = '14',
  ...svgIconProps
}: ComponentProps<typeof SvgIcon>) => (
  <SvgIcon
    {...svgIconProps}
    fill="none"
    height={height}
    stroke={color}
    strokeWidth={0}
    viewBox="0 0 17 14"
    width={width}
  >
    <rect
      x="1.45156"
      y="1.99844"
      width="14.0994"
      height="10.9845"
      rx="0.853125"
      strokeWidth="0.91875"
      strokeLinejoin="round"
    />
    <line
      x1="1.8649975"
      y1="2.54687"
      x2="15.1375225"
      y2="2.54687"
      strokeWidth="1.3125"
      strokeLinecap="butt"
    />
    <rect
      x="4.29336"
      y="0.643701"
      width="1.98044"
      height="3.34687"
      rx="0.853125"
      fill="white"
      strokeWidth="0.91875"
      strokeLinejoin="round"
    />
    <rect
      x="10.7289"
      y="0.643701"
      width="1.98044"
      height="3.34687"
      rx="0.853125"
      fill="white"
      strokeWidth="0.91875"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CalendarIconV2;
