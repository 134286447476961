import { useAppDispatch } from 'reduxInfra/hooks';
import { MutableRefObject, ReactNode, useState } from 'react';
import { TeamMember } from 'models/teamMember';
import { RatesDropdownContainer as PTODropdownContainer } from 'BudgetModule/components/BudgetTable/styles';
import {
  fetchPTOGroupMemberships,
  createPTOGroupMembership,
  updatePTOGroupMembership,
  deletePTOGroupMembership
} from 'SettingsModule/actionCreators/settings/pto';
import { PTOTeamPolicyDropdown } from './PTOTeamPolicyDropdown';
import { PTOMemberPolicyDropdown } from './PTOMemberPolicyDropdown';
import { PTOGroup, PTOGroupMembership } from 'SettingsModule/models/pto';
import { PTOMemberPolicyDropdownCellProps } from './PTOMemberPolicyDropdown/types';
import { UpdatePTOGroupMembershipParams } from 'SettingsModule/api/pto/types';
import { DeleteModal } from 'components/Modals/DeleteModal';

type ModalType = 'memberPolicy' | 'teamPolicy';

interface PTOPolicySelectorProps {
  teamMember: TeamMember;
  target: MutableRefObject<null>;
  isDisableDeleteOnlyPolicy?: boolean;
  children: ReactNode;
}

export const PTOPolicySelector = ({
  teamMember,
  target,
  isDisableDeleteOnlyPolicy = true,
  children
}: PTOPolicySelectorProps) => {
  const [openModalType, setOpenModalType] = useState<ModalType | undefined>();
  const [itemToDelete, setItemToDelete] = useState<
    PTOGroupMembership | undefined
  >();
  const [itemToEdit, setItemToEdit] = useState<
    PTOGroupMembership | undefined
  >();

  const dispatch = useAppDispatch();

  const handleClose = () => setOpenModalType(undefined);

  const handleDeleteClose = () => {
    setItemToDelete(undefined);
    setOpenModalType('memberPolicy');
  };

  const handleOpenMemberPolicyModal = () => {
    dispatch(fetchPTOGroupMemberships({ teamMembershipId: teamMember.id }));
    setOpenModalType('memberPolicy');
  };

  const handleStickyClick = () => setOpenModalType('teamPolicy');

  const handleAddMemberToPolicy = (ptoGroup: PTOGroup) => {
    setOpenModalType('memberPolicy');

    dispatch(
      createPTOGroupMembership({
        id: ptoGroup.id,
        teamMembershipIds: [teamMember.id]
      })
    );
  };

  const handleEditPolicy = (ptoGroupMembership: PTOGroupMembership) => {
    setItemToEdit(ptoGroupMembership);
    setOpenModalType('teamPolicy');
  };

  const handleTeamPolicySelection = (ptoGroup: PTOGroup) => {
    if (itemToEdit) {
      dispatch(
        updatePTOGroupMembership({
          id: itemToEdit.id,
          ptoGroupId: ptoGroup.id,
          meta: { teamMembershipId: teamMember.id }
        })
      );

      setItemToEdit(undefined);
      setOpenModalType('memberPolicy');
    } else {
      handleAddMemberToPolicy(ptoGroup);
    }
  };

  const handleRequestDeletePolicyMembership = (
    ptoGroupMembership: PTOGroupMembership
  ) => setItemToDelete(ptoGroupMembership);

  const handleConfirmDeletePolicyMembership = () => {
    if (itemToDelete) {
      dispatch(
        deletePTOGroupMembership({
          id: itemToDelete.id,
          meta: { teamMembershipId: teamMember.id }
        })
      );
      handleDeleteClose();
    }
  };

  const handleChangePolicyDate: PTOMemberPolicyDropdownCellProps['onDateChange'] =
    ({ item: policyMembership, dateType, date }) => {
      const baseParams = {
        id: policyMembership.id,
        meta: { teamMembershipId: teamMember.id }
      };

      const params: UpdatePTOGroupMembershipParams =
        dateType === 'startDate'
          ? { ...baseParams, startDate: date.format('YYYY-MM-DD') }
          : { ...baseParams, endDate: date.format('YYYY-MM-DD') };

      dispatch(updatePTOGroupMembership(params));
    };

  return (
    <PTODropdownContainer inTableCell onClick={handleOpenMemberPolicyModal}>
      {children}
      {openModalType === 'teamPolicy' && (
        <PTOTeamPolicyDropdown
          target={target}
          onSelect={handleTeamPolicySelection}
          onClose={() => setOpenModalType('memberPolicy')}
        />
      )}
      {openModalType === 'memberPolicy' &&
        (itemToDelete ? (
          <DeleteModal
            isOpen
            component="policy"
            toggle={handleDeleteClose}
            deleteOnClick={handleConfirmDeletePolicyMembership}
          />
        ) : (
          <PTOMemberPolicyDropdown
            target={target}
            teamMember={teamMember}
            isDisableDeleteOnlyPolicy={isDisableDeleteOnlyPolicy}
            onClose={handleClose}
            onDelete={handleRequestDeletePolicyMembership}
            onEdit={handleEditPolicy}
            onStickyClick={handleStickyClick}
            onDateChange={handleChangePolicyDate}
          />
        ))}
    </PTODropdownContainer>
  );
};
