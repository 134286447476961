import { reducerMap } from '../reducers';
import customFieldsSaga from '../sagas';

export function getCustomFieldsModule() {
  return {
    id: 'customFields',
    reducerMap,
    sagas: [customFieldsSaga]
    // Actions to fire when this module is added/removed
    // initialActions: []
    // finalActions: []
  };
}
