import { Moment } from 'moment';
import Popover from 'components/Popover';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'appUtils/momentConfig';

const { range } = moment;

interface SinglePaneCalendarTimePickerProps {
  initialTime: Moment | null;
  placeholder: string;
  handleUpdateTime: (value: Moment) => void;
}

const SinglePaneCalendarTimePicker = ({
  initialTime,
  placeholder,
  handleUpdateTime
}: SinglePaneCalendarTimePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const timePopoverTarget = useRef(null);

  const dayStart = moment().startOf('day').hours(0);

  const dayEnd = moment().endOf('day');

  const day = range(dayStart, dayEnd);
  const timeOptions = Array.from(day.by('minutes', { step: 15 }));

  const togglePopover = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (time: Moment) => {
    handleUpdateTime(time);
    setIsOpen(false);
  };

  const initialTimeString = initialTime?.format('h:mm a');

  return (
    <SinglePaneCalendarTimePickerContainer
      ref={timePopoverTarget}
      onClick={togglePopover}
    >
      <div>{placeholder}</div>
      <Popover
        isOpen={isOpen}
        target={timePopoverTarget}
        closePopover={() => {
          setIsOpen(false);
        }}
        className="single-pane-time-popover"
      >
        <SinglePaneTimeOptionsContainer>
          {timeOptions.map((time: Moment) => {
            const formattedTime = time.format('h:mm a');
            const isSelectedTime = initialTimeString === formattedTime;

            return (
              <SinglePaneTimeOption
                key={formattedTime}
                onClick={() => handleSelect(time)}
                isSelectedTime={isSelectedTime}
              >
                {formattedTime}
              </SinglePaneTimeOption>
            );
          })}
        </SinglePaneTimeOptionsContainer>
      </Popover>
    </SinglePaneCalendarTimePickerContainer>
  );
};

const SinglePaneCalendarTimePickerContainer = styled.div`
  background-color: ${theme.colors.colorTranslucentGray3};
  margin-left: 6px;
  margin-bottom: 15px;
  width: 75px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.colorTranslucentGray5};
  }
`;

const SinglePaneTimeOptionsContainer = styled.div`
  height: 165px;
  width: 96px;
  border-radius: 3px;
  overflow: scroll;
`;

const SinglePaneTimeOption = styled.div<{ isSelectedTime: boolean }>`
  display: flex;
  align-items: center;
  height: 34px;
  justify-content: center;
  font-size: 12px;
  font-weight: ${({ isSelectedTime }) => (isSelectedTime ? 600 : 400)};
  cursor: pointer;

  &:hover {
    background: ${theme.colors.colorPaleGray5};
  }
`;

export default SinglePaneCalendarTimePicker;
