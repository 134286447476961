import React, { lazy, Suspense } from 'react';
// import { DynamicModuleLoader } from 'redux-dynamic-modules';
// import { getDashboardModule } from 'DashboardModule/package/dashboardModule';

const DashboardPage = lazy(() => import('./DashboardPage'));

const LoadDashboardContainer = (props) => (
  // <DynamicModuleLoader modules={[getDashboardModule()]}>
  <Suspense fallback={<div />}>
    <DashboardPage {...props} />
  </Suspense>
  // </DynamicModuleLoader>
);
export default LoadDashboardContainer;
