import PermissionsSection from './PermissionsSection';
import CheckBoxContainer from '../../../CheckboxContainer';
import Collapse from '../../../Collapse';
import {
  flatOptions,
  flatOptionsWithFinancialManager
} from 'SettingsModule/utils/checkboxOptions';
import ProjectsIcon from 'icons/ProjectsIcon';

import buildPermittedRoles from 'SettingsModule/utils/buildPermittedRolesString';
import { permissionsActionsHash } from 'PermissionsModule/constants';
import { HeaderContainer, PermissionText } from './styles';
import { PermissionsActions } from 'PermissionsModule/types';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getAllPermissionsByActions,
  getPermissionActionsExpanded
} from 'RoleTemplatesModule/selectors';

interface ProjectPermissionsProps {
  makeTogglePermission: (actionType: PermissionsActions) => void;
  toggleOpen: (actionType: PermissionsActions) => void;
}

const ProjectPermissions = ({
  makeTogglePermission,
  toggleOpen
}: ProjectPermissionsProps) => {
  const permissionsByAction = useAppSelector(getAllPermissionsByActions);
  const expandedPermissions = useAppSelector(getPermissionActionsExpanded);

  const renderHeader = (
    permissionAction: PermissionsActions,
    includeFinancialManager = false
  ) => {
    const roleTemplatesHash = permissionsByAction[permissionAction];
    const permissionData = permissionsActionsHash[permissionAction];
    return (
      <HeaderContainer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>{permissionData ? permissionData.question : ''}</div>
        {roleTemplatesHash ? (
          <PermissionText>
            {(buildPermittedRoles(roleTemplatesHash), includeFinancialManager)}
          </PermissionText>
        ) : null}
      </HeaderContainer>
    );
  };

  return (
    <PermissionsSection headerText={'Projects'} Icon={ProjectsIcon}>
      <Collapse
        header={renderHeader('create_projects')}
        isOpen={expandedPermissions.create_projects}
        toggleOpen={() => toggleOpen('create_projects')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_projects}
          onCheck={makeTogglePermission('create_projects')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('create_project_memberships')}
        isOpen={expandedPermissions.create_project_memberships}
        toggleOpen={() => toggleOpen('create_project_memberships')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_project_memberships}
          onCheck={makeTogglePermission('create_project_memberships')}
        />
        {/* <div>automatic adding</div> */}
      </Collapse>
      {/* <Collapse header={<div>Who can Invite Guests to projects?</div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={makeTogglePermission(' ')}
        />
      </Collapse>
      <Collapse header={<div>Who can set Project Managers?</div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={makeTogglePermission(' ')}
        />
      </Collapse>
  */}
      <Collapse
        header={renderHeader('archive_projects')}
        isOpen={expandedPermissions.archive_projects}
        toggleOpen={() => toggleOpen('archive_projects')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.archive_projects}
          onCheck={makeTogglePermission('archive_projects')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('delete_projects')}
        isOpen={expandedPermissions.delete_projects}
        toggleOpen={() => toggleOpen('delete_projects')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.delete_projects}
          onCheck={makeTogglePermission('delete_projects')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('clone_projects', true)}
        isOpen={expandedPermissions.clone_projects}
        toggleOpen={() => toggleOpen('clone_projects')}
      >
        <CheckBoxContainer
          dataList={flatOptionsWithFinancialManager}
          dataValues={permissionsByAction.clone_projects}
          onCheck={makeTogglePermission('clone_projects')}
        />
      </Collapse>
      <Collapse
        header={renderHeader('create_phases')}
        isOpen={expandedPermissions.create_phases}
        toggleOpen={() => toggleOpen('create_phases')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.create_phases}
          onCheck={makeTogglePermission('create_phases')}
        />
      </Collapse>

      <Collapse
        header={renderHeader('delete_phases')}
        isOpen={expandedPermissions.delete_phases}
        toggleOpen={() => toggleOpen('delete_phases')}
      >
        <CheckBoxContainer
          dataList={flatOptions}
          dataValues={permissionsByAction.delete_phases}
          onCheck={makeTogglePermission('delete_phases')}
        />
      </Collapse>
      {/*
      <Collapse header={<div>Who can Add Views to projects?</div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={makeTogglePermission(' ')}
        />
      </Collapse>
      <Collapse header={<div>Who can view project Budgets?</div>}>
        <CheckBoxContainer
          dataList={flatOptions}
          onCheck={makeTogglePermission(' ')}
        />
      </Collapse> */}
    </PermissionsSection>
  );
};

export default ProjectPermissions;
