import * as constants from 'SettingsModule/constants/office';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { createAction } from '@reduxjs/toolkit';
import { ValueOf } from 'type-fest';
import { LocationAttributes } from 'SettingsModule/models/location';

export const fetchOffices = createAction(
  constants.FETCH_OFFICES.TRIGGER,
  ({ meta, ...payload }: FetchOfficesInitialParams) => ({
    meta,
    payload
  })
);

export const createOffice = createAction<CreateOfficeInitialParams>(
  constants.CREATE_OFFICE.TRIGGER
);

export const updateOfficeMetadata =
  createAction<UpdateOfficeMetadataInitialParams>(
    constants.UPDATE_OFFICE_METADATA.TRIGGER
  );
export const updateOfficeLocation =
  createAction<UpdateOfficeLocationInitialParams>(
    constants.UPDATE_OFFICE_LOCATION.TRIGGER
  );

export const deleteOffice = createAction<DeleteOfficeInitialParams>(
  constants.DELETE_OFFICE.TRIGGER
);

export const updateOfficeEntities =
  createAction<UpdateOfficeEntitiesInitialParams>(
    constants.UPDATE_OFFICE_ENTITIES.TRIGGER
  );

interface FetchOfficesInitialParams {
  teamId: number;
  meta?: IMeta;
}

interface CreateOfficeInitialParams {
  teamId: number;
  name: string;
  locationAttributes: LocationAttributes;
  requestStatusId?: string;
}

interface UpdateOfficeMetadataInitialParams {
  id: number;
  name: string;
  requestStatusId?: string;
}

export interface UpdateOfficeLocationInitialParams {
  officeLocationId: number;
  locationAttributes: LocationAttributes;
  requestStatusId?: string;
  meta: {
    officeId: number;
  };
}

interface DeleteOfficeInitialParams {
  id: number;
}

interface UpdateOfficeEntitiesInitialParams {
  id: number;
  addEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
  removeEntities?: {
    entityId: number;
    entityType: ValueOf<typeof ENTITY_TYPES>;
  }[];
}
