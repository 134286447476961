import React from 'react';
import theme from 'theme';
import TaskMoveThreeDotIcon from 'icons/TaskMoveThreeDotIcon';
import { StyledTaskMoveMenuContainer, TaskMoveMenuPopout } from './styles';
import TaskMoveMenu from './TaskMoveMenu';
import TaskBatchActionsModal from 'views/Home/TaskBatchActions/TaskBatchActionsModal';
import { connect } from 'react-redux';
import {
  triggerBatchDeleteTasks,
  flushBatchSelectedTasks
} from 'actionCreators';
import { getAuthToken, getHomeTaskObj } from 'selectors';
import { noop } from 'appUtils/noop';

class TaskMoveMenuContainer extends React.Component {
  state = {
    open: false,
    isDeleting: false
  };

  toggleOpen = () => this.setState((prevState) => ({ open: !prevState.open }));
  setOpen = () => this.setState({ open: true });
  setClose = () => this.setState({ open: false });
  setIsDeleting = () => this.setState({ isDeleting: true });
  clearIsDeleting = () => this.setState({ isDeleting: false });
  confirmDelete = () => {
    const {
      taskId,
      triggerBatchDeleteTasks,
      flushBatchSelectedTasks,
      token,
      onDelete = noop
    } = this.props;
    const body = {
      taskIds: [taskId]
    };
    triggerBatchDeleteTasks({ token, body });
    flushBatchSelectedTasks();
    onDelete();
    this.clearIsDeleting();
  };

  markTaskComplete = () => {
    const { markTaskCompletion } = this.props;
    markTaskCompletion();
    this.toggleOpen();
  };

  render() {
    const { projectId } = this.props;
    return (
      <>
        <StyledTaskMoveMenuContainer onClick={this.toggleOpen}>
          <TaskMoveThreeDotIcon color={theme.colors.colorCalendarBlue} />
        </StyledTaskMoveMenuContainer>
        {this.state.open && (
          <TaskMoveMenuPopout top={55} right={101}>
            <TaskMoveMenu
              markTaskCompletion={this.markTaskComplete}
              handleDeleteClick={this.setIsDeleting}
              projectId={projectId}
            />
          </TaskMoveMenuPopout>
        )}

        <TaskBatchActionsModal
          isOpen={this.state.isDeleting}
          title={`Delete task?`}
          content=" Are you sure you want to delete this task? You will be unable to restore deleted tasks."
          handleConfirmation={this.confirmDelete}
          toggle={this.clearIsDeleting}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: getAuthToken(state),
  taskHash: getHomeTaskObj(state)
});
const mapDispatchToProps = {
  triggerBatchDeleteTasks,
  flushBatchSelectedTasks
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { taskId } = ownProps;
  const { taskHash, ...restStateProps } = stateProps;

  const task = taskHash[taskId] ?? {};
  const { project_id: projectId } = task;

  return {
    ...ownProps,
    ...restStateProps,
    ...dispatchProps,
    projectId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TaskMoveMenuContainer);
