import { changeEntity } from './generics';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';

export function* createTaskStatus(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.createTaskStatus,
    api.createTaskStatus,
    [token, id, payload],
    action
  );
}
export function* updateTaskStatus(action) {
  const { id, ...payload } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTaskStatus,
    api.updateTaskStatus,
    [token, id, payload],
    action
  );
}

export function* deleteTaskStatus(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.deleteTaskStatus,
    api.deleteTaskStatus,
    [token, id],
    action
  );
}
