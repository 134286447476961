import { initialState } from 'reducers/skills';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';

const emptyObj = {};
const emptyArray = [];

const getSkillsState = (state) => state.skills || initialState;

export const getSkillsOrder = createSelector(
  getSkillsState,
  (state) => state.skillsOrder
);

export const getSkillsCount = createSelector(
  getSkillsOrder,
  (skills) => skills.length
);

export const getAreSkillsLoaded = createSelector(
  getSkillsState,
  (skills) => skills.loaded
);

export const getIsLoadingSkills = createSelector(
  getSkillsState,
  (skills) => skills.isLoading
);

export const getSkills = createSelector(
  getSkillsState,
  (state) => state.skills
);

export const getTeamMemberSkills = createSelector(getSkills, (skills) =>
  Object.values(skills).reduce((acc, skill) => {
    const { team_member_skills, ...rest } = skill;
    team_member_skills.forEach((teamMemberSkill) => {
      acc[teamMemberSkill.id] = { ...teamMemberSkill, skill: rest };
    });
    return acc;
  }, {})
);

export const getAlphabeticalSkillIds = createSelector(
  getSkillsOrder,
  getSkills,
  (skillIds, skillHash) => {
    return skillIds.slice().sort((a, b) => {
      const aName = skillHash[a]?.name ?? '';
      const bName = skillHash[b]?.name ?? '';
      return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
    });
  }
);

export const getSkillsArray = createSelector(
  getSkillsOrder,
  getSkills,
  (ids = [], skills = {}) => ids.map((id) => skills[id])
);

export const getSkillsByName = createSelector(getSkillsArray, (skills) =>
  keyBy(skills, 'name')
);

export const getEntitySkills = createSelector(
  getSkillsState,
  (state) => state.entitySkills
);

export const getSkillsReport = createSelector(
  getSkillsState,
  (state) => state.skillsReport
);

export const getSkillSuggestions = createSelector(
  getSkillsState,
  (state) => state.skillSuggestions || emptyObj
);

export const getSuggestedTeamSkills = createSelector(
  getSkillSuggestions,
  (skillSuggestions) => skillSuggestions.team_skills || emptyArray
);

export const makeGetSkillsReportByPositionId = () =>
  createSelector(
    getSkillsReport,
    (state, ownProps) => ownProps.positionId,
    (report, positionId) => report[positionId] || emptyObj
  );

export const getIsFetchingEntitySkills = createSelector(
  getSkillsState,
  (state) => state.isFetchingEntitySkills
);

export const getIsFetchingSkillsReport = createSelector(
  getSkillsState,
  (state) => state.isFetchingSkillsReport
);

export const makeGetEntitySkillsByType = () =>
  createSelector(
    getEntitySkills,
    (state, ownProps) => ownProps.entityType,
    (entitySkills, entityType) => entitySkills[entityType] || emptyObj
  );

export const makeGetEntitySkillsKeyedByTeamSkillIdsGroupedByPositionId = () =>
  createSelector(
    makeGetEntitySkillsByType(),
    (state, ownProps) => ownProps.positionId,
    (entitySkillsByType, positionId) =>
      keyBy(
        entitySkillsByType[positionId] || emptyArray,
        (skill) => skill.team_skill_id
      )
  );

// accountOrder and skillOrder can't come from accountProficiencies and skillProficiencies
// since they are not sorted. 2d array from teamMemberSkills is the only one that is sorted
export const makeGetSkillsReportSkillOrderByPositionId = () =>
  createSelector(
    getSkillsReport,
    (state, ownProps) => ownProps.positionId,
    (report, positionId) =>
      report[positionId]?.teamMemberSkills?.map(
        (skillRow) => skillRow[0].team_skill_id
      ) || emptyArray
  );
export const makeGetSkillsReportAccountOrderByPositionId = () =>
  createSelector(
    getSkillsReport,
    (state, ownProps) => ownProps.positionId,
    (report, positionId) =>
      report[positionId]?.teamMemberSkills?.[0]?.map(
        (skillByAccount) => skillByAccount.account_id
      ) || emptyArray
  );
