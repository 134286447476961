import { MembershipListItem } from '../types';
import { useAppDispatch } from 'reduxInfra/hooks';
import EllipsisText from 'components/EllipsisText';
import useCurrentRoles from 'BudgetModule/hooks/useCurrentRoles';
import { useRef } from 'react';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import NumberFormat from 'react-number-format';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import {
  createPhaseEstimation,
  updatePhaseEstimation
} from 'BudgetModule/actionCreators';
import { NumberFormatInput } from 'components/styles';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';

export const Membership = (membershipItem: MembershipListItem) => {
  const dispatch = useAppDispatch();

  const {
    activityPhaseMembership,
    teamMembershipByAccountId,
    accountTotals,
    projectId,
    hasActivityPhases,
    memberBudget,
    openBudgetModal
  } = membershipItem;

  const { account_id, phase_id, member_budget_id, activity_id } =
    activityPhaseMembership;

  const { teamRole, memberRole } = useCurrentRoles({
    accountId: account_id as number,
    memberBudget
  });
  const roleToUse = memberRole || teamRole;
  const hasRole = roleToUse && !roleToUse.is_default;
  const member = teamMembershipByAccountId[account_id as number];
  const memberName = member ? member.account?.name : '';

  const { activity_phase_estimation_id, hours: accountBudgetHours } =
    accountTotals?.estimated || {};

  const hoursRef = useRef<NumberFormat<unknown>>(null);

  const onBlur = () => {
    if (hoursRef?.current) {
      const { numAsString } = hoursRef.current.state;

      const newHours = isNaN(parseFloat(numAsString))
        ? 0
        : parseFloat(numAsString);
      if (accountBudgetHours !== newHours) {
        if (activity_phase_estimation_id) {
          dispatch(
            updatePhaseEstimation({
              project_id: projectId,
              id: activity_phase_estimation_id,
              estimated_hours: newHours,
              member_budget_id,
              phase_id,
              ...(hasActivityPhases ? { activity_id } : {}),
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        } else {
          dispatch(
            createPhaseEstimation({
              project_id: projectId,
              estimated_hours: newHours,
              member_budget_id,
              phase_id,
              ...(hasActivityPhases ? { activity_id } : {}),
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        }
      }
    }
  };

  const renderHours = () => {
    return (
      <NumberFormatInput
        defaultValue={accountBudgetHours}
        placeholder={'0h'}
        ref={hoursRef}
        onBlur={onBlur}
        decimalScale={2}
        allowNegative={false}
        thousandSeparator
        allowLeadingZeros={false}
        suffix={'h'}
        className="hour-input"
        {...defaultTooltipProps}
        data-tip="Budgeted hours"
        data-testid="hour-input"
      />
    );
  };

  return (
    <StyledItemContainer
      data-testid="membership-item-container"
      className="phase-membership"
    >
      <MemberInitials
        classes="regular-member-no-hover member-initials"
        member={member}
      />
      <div>
        <EllipsisText
          tooltip={memberName}
          width={undefined}
          maxWidth={undefined}
          className="member-name"
          data-testid="member-name"
        >
          {memberName}
        </EllipsisText>
        {hasRole ? (
          <EllipsisText
            tooltip={roleToUse.name}
            width={undefined}
            maxWidth={undefined}
            className="member-role"
          >
            {roleToUse.name}
          </EllipsisText>
        ) : (
          <></>
        )}
      </div>
      <div></div>
      <div></div>
      <div className="hour">{renderHours()}</div>
    </StyledItemContainer>
  );
};
