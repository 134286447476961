import { combineReducers } from 'redux';
import qbSyncSettings from './qbSyncSettings';
import quickbooksView from './quickbooksView';
import qbServices from './qbServices';
import qbMembers from './qbMembers';
import qbSubCustomers from './qbSubCustomers';
import budget from 'BudgetModule/reducers/budget';
import qbSubSubCustomers from './qbSubSubCustomers';
import qbCustomers from './qbCustomers';
import subcustomerModal from './subcustomerModal';
import search from './search';
import sort from './sort';

const reducerMap = combineReducers({
  qbSyncSettings,
  quickbooksView,
  qbServices,
  qbMembers,
  qbSubCustomers,
  budget,
  qbSubSubCustomers,
  qbCustomers,
  subcustomerModal,
  search,
  sort
});

export default reducerMap;
