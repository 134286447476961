import theme from 'theme';

const GradientsDefs = () => (
  <svg height="0" width="0" style={{ position: 'absolute' }}>
    <defs>
      {/* ------------------------------- ptoGradient ------------------------------ */}
      {/* TODO: find a way to make it gradient hatch instaed of solid color */}
      <pattern
        id="ptoGradient"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect fill="white" y2="10" width="10" height="10" />
        <line
          stroke={theme.colors.colorPaleBlue4}
          strokeWidth="3px"
          strokeOpacity={0.4}
          y2="10"
        />
      </pattern>

      {/* ----------------------------- holidayGradient ---------------------------- */}
      {/* TODO: find a way to make it gradient hatch instaed of solid color */}
      <pattern
        id="holidayGradient"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect fill="white" y2="10" width="10" height="10" />
        <line
          stroke={theme.colors.colorPaleGray12}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
      </pattern>

      {/* ------------------------------ overGradient ------------------------------ */}
      <pattern
        id="overGradient"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect
          fill={theme.colors.colorPaleYellow4}
          y2="10"
          width="10"
          height="10"
        />
        <line
          stroke={theme.colors.colorMediumRed5}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
      </pattern>

      {/* ------------------------------ overGradient2 ----------------------------- */}
      <pattern
        id="overGradient2"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect
          fill={theme.colors.colorLightGray4}
          y2="10"
          width="10"
          height="10"
        />
        <line
          stroke={theme.colors.colorMediumRed5}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
      </pattern>

      {/* ---------------------------- tentativeGradient --------------------------- */}
      <pattern
        id="tentativeGradient"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect fill="white" y2="10" width="10" height="10" />
        <line
          stroke={theme.colors.colorPaleYellow4}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
      </pattern>

      {/* ------------------------ tentativeReversedGradient ----------------------- */}
      <pattern
        id="tentativeReversedGradient"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(-45 50 50)"
      >
        <rect fill="white" y2="10" width="10" height="10" />
        <line
          stroke={theme.colors.colorPlannedHours}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
      </pattern>

      {/* ----------------------- tentativeGradientUnassigned ---------------------- */}
      <pattern
        id="tentativeGradientUnassigned"
        width="3.2"
        height="3.2"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect fill="white" y2="10" width="10" height="10" />
        <line
          stroke={theme.colors.colorLightGray15}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
        <line
          stroke={theme.colors.colorPlannedHours}
          strokeWidth="3px"
          strokeOpacity={0.8}
          x2="10"
        />
      </pattern>

      {/* --------------------- nonTentativeGradientUnassigned --------------------- */}
      <pattern
        id="nonTentativeGradientUnassigned"
        width="3.2"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 50 50)"
      >
        <rect
          fill={theme.colors.colorPlannedHours}
          y2="10"
          width="10"
          height="10"
        />
        <line
          stroke={theme.colors.colorLightGray15}
          strokeWidth="3px"
          strokeOpacity={0.8}
          y2="10"
        />
      </pattern>
    </defs>
  </svg>
);

export default GradientsDefs;
