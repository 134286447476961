import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { getMyUserId } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import { newFilter } from 'appUtils/filters';

const PersonalWorkloadPlannerContainer = lazy(() =>
  import(
    'views/projectPlanner/PersonalWorkloadPlanner/PersonalWorkloadPlannerContainer'
  )
);

const HomeWorkloadPlannerContainer = () => {
  const myId = useSelector(getMyUserId);
  return (
    <Suspense fallback={<div />}>
      <PageContainer>
        <Title>Work Plan</Title>
        <PersonalWorkloadPlannerContainer
          pageName={1}
          viewBy="members"
          activeFilter={{
            ...newFilter,
            account_ids: [myId]
          }}
          plannerType="home"
          accountId={myId}
        />
      </PageContainer>
    </Suspense>
  );
};
export default HomeWorkloadPlannerContainer;

const PageContainer = styled.div`
  width: calc(100vw - 280px);
  padding-right: 280px;
  margin-left: auto;
  position: relative;
  height: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${theme.colors.colorMediumGray9};
  position: absolute;
  left: 40px;
  top: -9px;
`;
