import { fork, takeLatest, takeEvery } from 'redux-saga/effects';
import * as workers from './homeTasks';
import * as constants from 'appConstants';
import { taskCountRefetchActions } from 'appConstants/taskCountRefetchActions';
export const homeTasksSagas = [
  fork(
    takeLatest,
    constants.HANDLE_HOME_TASK_COMPLETION_TOGGLE,
    workers.handleHomeCompletionToggle
  ),
  fork(
    takeLatest,
    constants.CLEAR_REMOVED_TASKS_TRIGGER,
    workers.clearRemovedTasks
  ),
  fork(
    takeEvery,
    constants.TASK_LIST_LOADING_INITIAL_TASKS.TRIGGER,
    workers.fetchTaskListDataInitialDataLoadWorker
  ),

  fork(
    takeLatest,
    constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.TRIGGER,
    workers.fetchTaskListAdditionalDataLoadWorker
  ),
  fork(
    takeEvery,
    constants.OPEN_CONFIRM_COMPLETE_TASK_MODAL,
    workers.registerTaskCompleteModalVisit
  ),
  fork(
    takeEvery,
    constants.UPDATE_TASKS_ATTRIBUTES.TRIGGER,
    workers.updateTasksAttributesWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_REQUEST_TASKS_FOLLOW_UP.TRIGGER,
    workers.batchRequestTasksFollowUpWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_MOVE_TASKS_TO_PROJECT.TRIGGER,
    workers.batchMoveTasksToProjectWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_COPY_TASKS_TO_PROJECT.TRIGGER,
    workers.batchCopyTasksToProjectWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_REVERT_TASKS_TO_INCOMPLETE.TRIGGER,
    workers.batchRevertTasksToIncompleteWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_DELETE_TASKS.TRIGGER,
    workers.batchDeleteTasksWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_MOVE_INBOX_TASKS.TRIGGER,
    workers.batchAcceptInboxTasksWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_UNFOLLOW_TASKS.TRIGGER,
    workers.batchUnfollowTasksWorker
  ),

  fork(
    takeEvery,
    constants.BATCH_COMPLETE_TASKS.TRIGGER,
    workers.batchCompleteTasksWorker
  ),

  fork(
    takeEvery,
    constants.UPDATE_TASK_HOME_POSITION,
    workers.updateTasksHomePositionWorker
  ),

  fork(takeEvery, constants.MOVE_TASK_TO_GROUP, workers.moveTaskToGroupWorker),
  fork(takeEvery, constants.ADD_TASK_TO_GROUP, workers.addTaskToGroupWorker),

  fork(
    takeEvery,
    constants.CREATE_TASK_FROM_HOME.TRIGGER,
    workers.createTaskWorker
  ),
  fork(takeEvery, constants.COPY_TASKS.TRIGGER, workers.copyTasksWorker),
  fork(takeEvery, constants.MOVE_TASKS.TRIGGER, workers.moveTasksWorker),
  fork(takeEvery, constants.QUERY_AI.TRIGGER, workers.queryAIWorker),

  fork(
    takeLatest,
    constants.CREATE_TASK_AND_OPEN_MODAL,
    workers.createTaskAndOpenModalWorker
  ),

  fork(
    takeLatest,
    constants.POST_TASK_COMMENT.TRIGGER,
    workers.createTaskCommentWorker
  ),
  fork(takeLatest, taskCountRefetchActions, workers.fetchTaskCountsByAttribute)
];

export default homeTasksSagas;
