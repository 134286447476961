import SvgIcon, { SvgIconProps } from 'components/SvgIcon';
import { useTheme } from 'styled-components';

export const FilledArrowV2 = ({
  color,
  height = 4.33,
  width = 5,
  ...svgIconProps
}: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      height={height}
      viewBox="0 0 5 4.33"
      width={width}
      {...svgIconProps}
    >
      <path
        d="M 0 0 L 5 0 L 2.5 4.33 L 0 0 Z"
        fill={color ?? theme.colors.colorMediumGray9}
      />
    </SvgIcon>
  );
};

export default FilledArrowV2;
