import { createSelector } from 'reselect';
import { getUserActivityGroupId } from 'appConstants';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { initialFilterState as activityInitialFilterState } from 'reducers/userActivities';
const emptyObj = {};
const emptyArray = [];

const getOwnAccountId = (state, ownProps) => ownProps.accountId;
const getOwnDate = (state, ownProps) => ownProps.date;

export const getActivitiesState = (state) => state.activities;
export const getUserActivities = (state) => state.userActivities.userActivities;
export const getUserActivitiesState = (state) => state.userActivities;
export const getUserActivitiesByDate = createSelector(
  getUserActivities,
  (userActivities) => {
    const dateHash = groupBy(userActivities, (activity) =>
      moment(activity.timestamp).format('YYYY-MM-DD')
    );
    const activitiesByDate = Object.entries(dateHash).map(
      ([date, notifications]) => ({ date, notifications })
    );
    return activitiesByDate;
  }
);

export const getUserActivitiesOffset = (state) => state.userActivities.offset;
export const getUserActivitiesAllFetched = (state) =>
  state.userActivities.allFetched;
export const getIsFetchingUserActivities = (state) =>
  state.userActivities.isFetching;
export const makeGetUserActivities = (getters = emptyObj) =>
  createSelector(
    getActivitiesState,
    getters.accountIdGetter || getOwnAccountId,
    getters.dateGetter || getOwnDate,
    (state, accountId, date) => state.userActivities?.[accountId]?.[date]
  );

export const groupUserActivities = (activities = emptyObj) => {
  const groupedActivities = {};
  Object.keys(activities).map((key) => {
    const groupId = getUserActivityGroupId(+key);
    groupedActivities[groupId] = groupedActivities[groupId] || [];
    groupedActivities[groupId] = groupedActivities[groupId].concat(
      activities[key]
    );
  });
  return groupedActivities;
};
const flattenActivities = (dayActivities = emptyObj) => {
  let flattened = {};
  Object.values(dayActivities).map(
    (activitiesById) => (flattened = { ...flattened, ...activitiesById })
  );
  return flattened;
};
export const makeGetGroupedUserActivities = (getters = emptyObj) =>
  createSelector(
    getActivitiesState,
    getters.accountIdGetter || getOwnAccountId,
    getters.dateGetter || getOwnDate,
    (state, accountId, date) =>
      groupUserActivities(
        flattenActivities(state.userActivities?.[accountId]?.[date])
      )
  );

const getOwnFilterStateId = (state, ownProps) =>
  ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id;

const getUserActivitiesFilterStates = createSelector(
  getUserActivitiesState,
  (state) => state.filterStates
);

export const getActionableUserActivityHash = createSelector(
  getUserActivities,
  (userActivities) => {
    const actionableUserActivityHash = groupBy(
      userActivities,
      (activity) => activity.actionable_id
    );
    return actionableUserActivityHash;
  }
);

const makeGetActivityFilterState = () =>
  createSelector(
    getUserActivitiesFilterStates,
    getOwnFilterStateId,
    (state, filterStateId) => state[filterStateId] || activityInitialFilterState
  );

export const makeGetActivityCountsByProjectIds = () =>
  createSelector(makeGetActivityFilterState(), (state) => {
    const activitiesByProjectId = state.userActivities.reduce((acc, cur) => {
      if (!acc[cur.project.id]) {
        acc[cur.project.id] = {
          title: cur.project.title,
          projectId: cur.project.id,
          total: 0
        };
      }
      acc[cur.project.id].total += 1;

      return acc;
    }, {});
    return Object.values(activitiesByProjectId);
  });

export const makeGetActivitiesByFilter = () =>
  createSelector(makeGetActivityFilterState(), (state) => state.userActivities);

export const makeGetIsFetchingActivitiesByFilter = () =>
  createSelector(makeGetActivityFilterState(), (state) => state.isFetching);

export const makeGetUserActivitiesAllFetchedByFilter = () =>
  createSelector(makeGetActivityFilterState(), (state) => state.allFetched);

export const makeGetUserActivitiesOffsetByFilter = () =>
  createSelector(makeGetActivityFilterState(), (state) => state.offset);

export const makeGetProjectIdsByUserActivities = () =>
  createSelector(makeGetActivityFilterState(), (state) =>
    state.userActivities.map((activity) => activity.project.id)
  );
