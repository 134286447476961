import * as constants from '../constants';
import { createAction } from 'appUtils';

export const fetchUtilizations = (params) =>
  createAction(constants.FETCH_UTILIZATIONS.TRIGGER, params);
export const fetchUtilizationsReport = (params) =>
  createAction(constants.FETCH_UTILIZATIONS_REPORT.TRIGGER, params);
export const fetchUtilizationsReportTotals = (params) =>
  createAction(constants.FETCH_UTILIZATIONS_REPORT_TOTALS.TRIGGER, params);

export const fetchTeamUtilizationReport = (params) =>
  createAction(constants.FETCH_TEAM_UTILIZATION_REPORT.TRIGGER, params);
export const fetchMembersUtilizationReport = (params) =>
  createAction(constants.FETCH_MEMBERS_UTILIZATION_REPORT.TRIGGER, params);

export const fetchProjectUtilizationReport = (params) =>
  createAction(constants.FETCH_PROJECT_UTILIZATION_REPORT.TRIGGER, params);

export const fetchPhaseUtilizationReport = (params) =>
  createAction(constants.FETCH_PHASE_UTILIZATION_REPORT.TRIGGER, params);
export const fetchTeamHistoricalUtilization = (params) =>
  createAction(constants.FETCH_TEAM_HISTORICAL_UTILIZATION.TRIGGER, params);
