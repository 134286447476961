import { roleTemplatesHash } from 'RoleTemplatesModule/constants';
import { RoleTemplates } from 'RoleTemplatesModule/types';

const roleConversionHash: Partial<Record<RoleTemplates, string>> = {
  Admin: 'Admins',
  'Project Manager': 'Project Managers',
  'Board Manager': 'Portfolio Managers',
  'Team Member': 'All Organization Members'
};
const roleConversionHashWithFinancialManager: Partial<
  Record<RoleTemplates, string>
> = {
  ...roleConversionHash,
  'Financial Manager': 'Financial Managers'
};

const buildPermittedRoles = (
  permissionAction: Record<RoleTemplates, boolean>,
  includeFinancialManager = false
) =>
  Object.entries(permissionAction)
    .filter(
      ([key, value]) =>
        !!value &&
        (includeFinancialManager || key !== roleTemplatesHash.financialManager)
    )
    .map(([key]) =>
      includeFinancialManager
        ? roleConversionHashWithFinancialManager[key]
        : roleConversionHash[key]
    )
    .join(', ');

export default buildPermittedRoles;
