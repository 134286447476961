import { createRequestTypes } from 'appUtils';

export const OPEN_ADD_WIDGET_MODAL = 'OPEN_ADD_WIDGET_MODAL';
export const CLOSE_ADD_WIDGET_MODAL = 'CLOSE_ADD_WIDGET_MODAL';
export const OPEN_WIDGET_DETAIL_MODAL = 'OPEN_WIDGET_DETAIL_MODAL';
export const CLOSE_WIDGET_DETAIL_MODAL = 'CLOSE_WIDGET_DETAIL_MODAL';
export const SET_WIDGET_LOADED_STATE = 'SET_WIDGET_LOADED_STATE';
export const CLEAR_WIDGET_LOADED_STATE = 'CLEAR_WIDGET_LOADED_STATE';

export const CREATE_DASHBOARD = createRequestTypes('CREATE_DASHBOARD');
export const DELETE_DASHBOARD = createRequestTypes('DELETE_DASHBOARD');
export const UPDATE_DASHBOARD = createRequestTypes('UPDATE_DASHBOARD');
export const FETCH_DASHBOARDS = createRequestTypes('FETCH_DASHBOARDS');

export const CREATE_WIDGET = createRequestTypes('CREATE_WIDGET');
export const DELETE_WIDGET = createRequestTypes('DELETE_WIDGET');
export const UPDATE_WIDGET = createRequestTypes('UPDATE_WIDGET');
export const FETCH_WIDGETS = createRequestTypes('FETCH_WIDGETS');

export const ADD_WIDGET_TO_DASHBOARD = createRequestTypes(
  'ADD_WIDGET_TO_DASHBOARD'
);
export const REMOVE_WIDGET_FROM_DASHBOARD = createRequestTypes(
  'REMOVE_WIDGET_FROM_DASHBOARD'
);
export const FETCH_DASHBOARD_WIDGETS = createRequestTypes(
  'FETCH_DASHBOARD_WIDGETS'
);

export const FETCH_DASHBOARD_MEMBERSHIPS = createRequestTypes(
  'FETCH_DASHBOARD_MEMBERSHIPS'
);
export const SHARE_DASHBOARD_MEMBERSHIPS = createRequestTypes(
  'SHARE_DASHBOARD_MEMBERSHIPS'
);
export const UNSHARE_DASHBOARD_MEMBERSHIPS = createRequestTypes(
  'UNSHARE_DASHBOARD_MEMBERSHIPS'
);
export const SHARE_UNSHARE_DASHBOARD_MEMBERSHIPS = createRequestTypes(
  'SHARE_UNSHARE_DASHBOARD_MEMBERSHIPS'
);

export const WIDGET_MARGIN = 10;

// Personal Dashboard
export const PERSONAL_DASHBOARD_MAX_COLUMNS = 3;
export const DEFAULT_PERSONAL_WIDGET_WIDTH = 420;
export const DEFAULT_PERSONAL_WIDGET_HEIGHT = 250;

// Team Dashboards
export const TEAM_DASHBOARD_MAX_COLUMNS = 6;
export const DEFAULT_TEAM_WIDGET_WIDTH = 400;
export const DEFAULT_TEAM_WIDGET_HEIGHT = 335;
