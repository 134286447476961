import { useCallback } from 'react';
import { getRowValuesForEntityType } from './utils';

export const useEmailValidator = ({ existingEmails, entityType }) => {
  return useCallback(
    (rows) => validateRows(rows, existingEmails, entityType),
    [existingEmails, entityType]
  );
};

/* ------------------------------------ - ----------------------------------- */

export const validateRows = (rows, existingEmails, entityType) => {
  return rows.map((row) => {
    const { enteredEmail } = getRowValuesForEntityType(row, entityType);
    return existingEmails.has(enteredEmail.toLowerCase());
  });
};
