import { useState, useMemo, useRef, MouseEvent, MutableRefObject } from 'react';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle
} from 'components/BatchActions/styles';
import styled, {
  FlattenSimpleInterpolation,
  StyledComponent
} from 'styled-components';
import theme from 'theme';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { useToggle } from 'react-use';

type BaseProps = {
  items: Array<string | number>;
  selectedItems: Array<string | number>;
  updateSelectedItems: (items: Array<string | number>) => void;
  isLoading?: boolean;
  labels?: Partial<
    Record<
      'headerInitial' | 'searchPlaceholder' | 'footerInitial',
      string | JSX.Element
    >
  >;
  listWidth?: number;
  listHeight?: number;
  itemHeight?: number;
  isBatchClearOnly?: boolean;
  isSearchEnabled?: boolean;
  isBatchActionsHidden?: boolean;
  /** default: true */
  hasNoHeader?: boolean;
  itemFilterableKeys?: string[];
  customRenderItem?: ({
    id,
    isSelected
  }: {
    id: string | number;
    isSelected: boolean;
  }) => JSX.Element;
  renderItemLabel?: (id: string | number) => JSX.Element;
  loadMoreItems?: ({ search }: { search: string }) => void;
  loadInitialItems?: ({ search }: { search: string }) => void;
  hasNextPage?: boolean;
  listItemContainerStyle?: string | FlattenSimpleInterpolation;
  renderToggle: ({ isOpen }: { isOpen: boolean }) => JSX.Element;
  renderHeaderButton?: ({
    handleClose
  }: {
    handleClose: () => void;
  }) => JSX.Element;
  onClose?: (e: MouseEvent) => void;
  styleWrapper?: (() => JSX.Element) | StyledComponent<any, any>;
  isSingleSelect?: boolean;
  popoverClassName?: string;
  hideFooter?: boolean;
  onFooterClick?: ({ e, handleClose }) => void;
  /* -------------------------------------------------------------------------- */
  parentRef?: MutableRefObject<HTMLElement | null>;
  parentOpen?: boolean;
  parentToggle?: () => void;
};

/** eg. { 2: 'Some label' } */
type LabelItemHashProps<I> = {
  itemHash: I;
  labelKey?: never;
};

/** eg. { 2: { name: 'Some label' } } */
type ObjectItemHashProps<I> = {
  itemHash: I;
  labelKey: string;
};

export type SimpleFilterDropdownProps<I> = I extends undefined
  ? BaseProps & {
      itemHash?: I;
      labelKey?: never;
    }
  : BaseProps &
      (I extends Record<string | number, string>
        ? LabelItemHashProps<I>
        : ObjectItemHashProps<I>);

export const SimpleFilterDropdown = <
  I extends
    | Record<string | number, string>
    | Record<string | number, Record<string, any>>
    | undefined
>({
  items,
  selectedItems,
  updateSelectedItems,
  isLoading,
  labels,
  listWidth = 217,
  listHeight = 187,
  itemHeight = 30,
  isBatchClearOnly,
  isSearchEnabled = true,
  isBatchActionsHidden,
  hasNoHeader = true,
  itemHash,
  labelKey,
  itemFilterableKeys,
  customRenderItem,
  renderItemLabel,
  loadMoreItems,
  loadInitialItems,
  hasNextPage,
  listItemContainerStyle,
  styleWrapper = SimpleFilterDropdownStyleWrapper,
  renderToggle,
  renderHeaderButton,
  isSingleSelect = false,
  onClose,
  popoverClassName,
  hideFooter = true,
  onFooterClick,

  // Props for deriving and controlling open state from parent. All must be provided for parent to trigger flyout properly
  parentRef,
  parentOpen = false,
  parentToggle
}: SimpleFilterDropdownProps<I>) => {
  const toggleRef = useRef(null);
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [isDropdownOpen, _toggleIsDropdownOpen] = useToggle(parentOpen);
  // for allowing click of toggle to both open and close
  const toggleIsDropdownOpen = useRef(
    debounce(parentToggle || _toggleIsDropdownOpen, 50)
  ).current;

  const selectedItemsSet = useMemo(() => {
    return new Set(selectedItems);
  }, [selectedItems]);

  const numSelected = selectedItems.length;
  const isAllSelected = numSelected === items.length;
  const isSomeSelected = numSelected > 0;

  const listItems = useMemo(() => {
    return Array.from(new Set([...selectedItems, ...items]));
  }, [items, selectedItems]);

  const handleSelect = (e, { item }: { item: typeof items[0] }) => {
    if (isSingleSelect) {
      updateSelectedItems([item]);
      toggleIsDropdownOpen(false);
      return;
    }
    const nextSelectedItems = new Set(selectedItems);
    const isCurrentlySelected = selectedItemsSet.has(item);

    if (isCurrentlySelected) {
      nextSelectedItems.delete(item);
    } else {
      nextSelectedItems.add(item);
    }

    updateSelectedItems(Array.from(nextSelectedItems));
  };

  const handleHideBatchOnSearch = (search: string) => {
    if (search && !isSearchResults) {
      setIsSearchResults(true);
    } else if (!search && isSearchResults) {
      setIsSearchResults(false);
    }
  };

  const itemFilter = (item: typeof items[0], searchWords: string[]) => {
    const itemToUse = itemHash?.[item] || item;
    return typeof itemToUse === 'string'
      ? searchWords.every((word) =>
          itemToUse.toLowerCase().includes(word.toLowerCase().trim())
        )
      : filterItemWithWhiteSpace({
          searchWords,
          item: itemToUse,
          filterKeysArray: [
            ...(labelKey ? [labelKey] : []),
            ...(itemFilterableKeys || [])
          ]
        });
  };

  const handleClose = (e) => {
    setIsSearchResults(false);
    toggleIsDropdownOpen(false);

    onClose && onClose(e);
  };

  /* --------------------------------- Render --------------------------------- */

  const renderItem = ({ item: listItem }: { item: string | number }) => {
    const isSelected = selectedItemsSet.has(listItem);
    if (customRenderItem) return customRenderItem({ id: listItem, isSelected });

    const label = itemHash
      ? typeof itemHash[listItem] === 'string'
        ? (itemHash[listItem] as string)
        : get(itemHash[listItem], labelKey as string)
      : (listItem as string);

    // default list item style
    return (
      <StyledListItem>
        <StyledSelectToggleContainer>
          <StyledSelectToggle isChecked={isSelected} size={14} />
        </StyledSelectToggleContainer>
        {renderItemLabel ? (
          renderItemLabel(listItem)
        ) : (
          <StyledLabel title={label} className="item-label no-text-overflow">
            {label}
          </StyledLabel>
        )}
      </StyledListItem>
    );
  };

  const handleFooterClick = (e) => {
    onFooterClick && onFooterClick({ e, handleClose });
  };

  const renderBatchSelect = () => {
    if (!items.length) return null;

    const selectAll = () => {
      updateSelectedItems(items);
    };

    const clearAll = () => {
      updateSelectedItems([]);
    };

    return (
      <StyledBatchSelectContainer>
        {!isBatchClearOnly && (
          <>
            {!isAllSelected && <span onClick={selectAll}>All</span>}
            {isSomeSelected && !isAllSelected && ' | '}
          </>
        )}
        {isSomeSelected && (
          <span onClick={clearAll}>
            Clear {isAllSelected ? 'All' : numSelected}
          </span>
        )}
      </StyledBatchSelectContainer>
    );
  };

  const hasStickyBatchSelect = !(isSearchResults || isBatchActionsHidden);

  const targetRefToUse = parentRef || toggleRef;

  return (
    <>
      <StyledToggleContainer
        className="toggle-container"
        onClick={toggleIsDropdownOpen}
        ref={toggleRef}
      >
        {renderToggle({ isOpen: isDropdownOpen })}
      </StyledToggleContainer>

      {isDropdownOpen && (
        <MultiStepFlyout
          canMultiSelect
          copy={{
            ...defaultLabels,
            ...labels
          }}
          target={targetRefToUse}
          items={listItems}
          handleSelect={handleSelect}
          idKey="id"
          renderItem={renderItem}
          itemFilter={itemFilter}
          handleClose={handleClose}
          renderHeaderButton={renderHeaderButton}
          listItemContainerStyle={
            listItemContainerStyle || { 'border-bottom': '0px;' }
          }
          searchEnabled={isSearchEnabled}
          stickyRow={hasStickyBatchSelect}
          renderStickyRow={renderBatchSelect}
          onStickyClick={noop} // prevent default action
          stickyBelowSearch
          hideFooter={hideFooter}
          isWhite
          editDisabled
          popoverClassName={`filter-dropdown ${popoverClassName}`}
          listWidth={listWidth}
          listHeight={listHeight}
          minListHeight={itemHeight}
          itemHeight={itemHeight}
          loadMoreItems={loadMoreItems}
          loadInitialItems={loadInitialItems}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          onFooterClick={handleFooterClick}
          onSearchChange={handleHideBatchOnSearch}
          noHeader={hasNoHeader}
          styleWrapper={styleWrapper}
        />
      )}
    </>
  );
};

/* ------------------------------------ - ----------------------------------- */

const noop = () => undefined;

const defaultLabels = {
  searchPlaceholder: 'Search or select below'
};

export const SimpleFilterDropdownStyleWrapper = styled.div<{
  listHeight: number;
  itemHeight: number;
}>`
  .variable-size-list {
    padding-bottom: 8px;
  }
`;

const StyledToggleContainer = styled.div`
  height: 100%;
`;

const StyledListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  color: ${theme.colors.colorMediumGray9};
  font-size: 14px;
  padding-left: 13px;

  ${StyledSelectToggleContainer} {
    margin-right: 10px;
  }
`;

const StyledLabel = styled.span<{ title: string }>`
  flex: 1;
  margin-bottom: 1px;
`;

const StyledBatchSelectContainer = styled.div`
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
  margin-left: 15px;
  margin-top: 9px;
  span {
    cursor: pointer;
  }
`;
