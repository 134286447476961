import React from 'react';
import { connect } from 'react-redux';
import Highlighter from 'appUtils/highlighter';
import differenceBy from 'lodash/differenceBy';
// import ReactTooltip from 'react-tooltip';
import Tribute from 'tributejs';
import {
  getTributeTaskTags,
  getTagsByTitle,
  getIsOnProjectView
} from 'selectors';
import {
  TaskDescription,
  NoneditTaskDescriptionWrapper,
  TaskIconsContainer,
  TaskCommentIconWrapper,
  RemainingTagCount,
  NoteIconContainer,
  TaskNoteIconWrapper,
  TaskDescriptionSpan,
  TasksMetaContainer,
  ContentEditable
} from './styles';
import NoteIcon from 'icons/NoteIcon';
import {
  setEditTaskId,
  setSelectedHomeTask,
  fetchCommentsAndMetadata,
  navigateToTaskModal,
  fetchTaskGroups
} from 'actionCreators';
import { createAddNewTagTemplate } from 'appUtils/tributeUtils';
import { getTruncatedDescription } from 'appUtils';
import CommentBubbleIcon from 'icons/CommentBubbleIconV2';
import unescape from 'lodash/unescape';
import { ConnectedDescriptionTag } from './DescriptionTag';
import theme from 'theme';

const visibleStyle = { display: 'block' };
const hiddenStyle = { display: 'none' };
const noop = () => {};

const calcContentEditableStyle = (taskIsEditing, taskIsCreating) =>
  taskIsEditing || taskIsCreating ? visibleStyle : hiddenStyle;

const searchRegex = [
  /\B#([a-z0-9]{2,})(?![~!@#$%^&*()=+_`-|/'[\]{}]|[?.,]*\w)/,
  /((https?:\/\/)|(www\.))[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
];
// findLastTextNode and replaceCaret ported with slight modifications from
// https://github.com/lovasoa/react-contenteditable/blob/master/src/react-contenteditable.tsx
// library handles cursor placement fine if you are clicking into a content editable div
// however, does not handle our 'editing task' transition out of box.

const findLastTextNode = (node) => {
  if (node.tagName === 'SPAN') return null;
  if (node.nodeType === Node.TEXT_NODE) return node;
  const children = node.childNodes;
  for (let i = children.length - 1; i >= 0; i--) {
    const textNode = findLastTextNode(children[i]);
    if (textNode !== null) return textNode;
  }
  return null;
};

const replaceCaret = (htmlEl) => {
  // Place the caret at the end of the element
  const target = findLastTextNode(htmlEl);
  if (target !== null && target.nodeValue !== null) {
    var range = document.createRange();
    var sel = window.getSelection();
    range.setStart(target, target.nodeValue.length);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

class TaskDescriptionWithTags extends React.Component {
  constructor(props) {
    super(props);
    this.editable = React.createRef();
  }

  componentDidMount() {
    this.initTributeTagging();
  }

  componentDidUpdate(prevProps) {
    const { taskIsEditing, isSelectedHomeTask } = this.props;
    const isNowEditing = !prevProps.taskIsEditing && taskIsEditing;
    const refExists = this.editable.current?.htmlEl;
    if (isNowEditing && refExists && !isSelectedHomeTask) {
      this.editable.current?.htmlEl?.focus();
      this.handleSetEditingHeight();
    }
    if (prevProps.allTags.length !== this.props.allTags.length) {
      this.appendNewTagsToTribute(prevProps.allTags, this.props.allTags);
    }
  }

  initTributeTagging = () => {
    const { allTags } = this.props;
    this.tribute = new Tribute({
      selectClass: 'dropdown-highlight',
      onSelectItem: this.onSelectTag,
      noMatchTemplate: createAddNewTagTemplate,
      values: allTags
    });
    this.tribute.attach(this.editable.current.htmlEl);
  };

  appendNewTagsToTribute = (oldTags, newTags) => {
    if (this.tribute) {
      const diff = differenceBy(newTags, oldTags, 'value');
      this.tribute.append(0, diff);
    }
  };

  handleSetTaskAsEditing = () => {
    const {
      triggerSetEditTaskId,
      taskId,
      handleContentClick,
      triggerFetchCommentsAndMetadata,
      isSelectedHomeTask
    } = this.props;
    triggerSetEditTaskId(taskId);
    const params = {
      taskId,
      taskType: 'projects',
      offset: 0,
      limit: 4
    };
    if (!isSelectedHomeTask) {
      triggerFetchCommentsAndMetadata(params);
    }
    if (handleContentClick) {
      handleContentClick();
    }
  };

  handleToggleTaskModal = (e) => {
    e.stopPropagation();
    const {
      taskId,
      triggerFetchCommentsAndMetadata,
      navigateToTaskModal,
      fetchTaskGroups,
      taskGroupId
    } = this.props;
    navigateToTaskModal({ taskId });
    const params = {
      taskId,
      taskType: 'projects',
      offset: 0,
      limit: 4
    };
    triggerFetchCommentsAndMetadata(params);
    fetchTaskGroups({ taskGroupIds: [taskGroupId] });
  };

  getSearchParams = () => {
    const { searchText } = this.props;
    const searchWords = searchText.split(' ');
    return !searchWords.length ? searchRegex : [...searchRegex, ...searchWords];
  };

  getTruncatedDescription = () =>
    getTruncatedDescription({
      fullText: this.props.currentDescriptionText,
      singleLineCutoff: 60,
      lastLineCutoff: 45,
      numLines: 3
    });

  getTruncatedTags = () => {
    const { remainder } = this.getTruncatedDescription();
    if (remainder === null || remainder === undefined) {
      return [];
    }
    const hashtagRegEx = new RegExp(
      /(^|\B)#(?![0-9_]+\b)([a-zA-Z0-9_]{1,40})(\b|\r)/gi
    );

    return remainder.match(hashtagRegEx) || [];
  };

  displayNoteIcon = () => {
    const {
      taskIsEditing,
      isDragActive,
      noteText,
      isSelectedHomeTask,
      testIdPrefix
    } = this.props;
    const shouldShow = !isDragActive && !isSelectedHomeTask;

    return (
      (shouldShow || taskIsEditing) && (
        <TaskNoteIconWrapper
          role="button"
          tabIndex="-1"
          onClick={this.handleToggleTaskModal}
          onKeyPress={this.handleToggleTaskModal}
          data-testid={`${testIdPrefix}-note`}
        >
          <NoteIconContainer
            hasTaskNotes={noteText && noteText.length}
            data-for={`app-tooltip`}
            data-effect="solid"
            data-tip="Add task notes"
            data-delay-show="500"
            show={taskIsEditing}
          >
            <NoteIcon isHovered={noteText && noteText.length} />
          </NoteIconContainer>
        </TaskNoteIconWrapper>
      )
    );
  };

  displayCommentIcon = () => {
    const {
      taskIsEditing,
      isDragActive,
      numTaskComments,
      isSelectedHomeTask,
      testIdPrefix
    } = this.props;
    const shouldShow = !isDragActive && !isSelectedHomeTask;

    return (
      (shouldShow || taskIsEditing) && (
        <TaskCommentIconWrapper
          $show={numTaskComments > 0 || taskIsEditing}
          data-delay-show="500"
          data-effect="solid"
          data-for="app-tooltip"
          data-html
          data-testid={`${testIdPrefix}-comment`}
          data-tip="Add task updates & attachments"
          onClick={this.handleToggleTaskModal}
          onKeyPress={this.handleToggleTaskModal}
          role="button"
          tabIndex="-1"
        >
          <CommentBubbleIcon
            color={
              numTaskComments > 0
                ? theme.colors.colorRoyalBlue
                : theme.colors.colorMediumGray1
            }
            height={16}
            width={16}
          />
          {numTaskComments > 0 && numTaskComments}
        </TaskCommentIconWrapper>
      )
    );
  };

  moveCaretToEnd = (e) => {
    if (this.editable.current?.htmlEl) {
      replaceCaret(this.editable.current.htmlEl);
    }
  };

  handleKeyDown = (e) => {
    const { onSubmit } = this.props;
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSubmit();
    }
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    const description = this.normalizeHtml(e.target.value);
    onChange(description);
    this.handleSetEditingHeight();
  };

  prepTextForEditing = (text) => {
    return `${text}`.replace(/&nbsp;/g, ' ');
  };

  normalizeHtml = (text) => {
    return this.getNormalizeHtmlOptions().reduce((acc, option) => {
      return acc.replace(option, '');
    }, text || '');
  };

  getNormalizeHtmlOptions = () => {
    /*
      these are strings to be ignored when ContentEditable compares prev/next html
      prevents unintended rerenders + caret resets.
      within our react-contenteditable fork, we have a normalizeHtml used for shouldComponentUpdate
      any characters/strings you want ignored during prev/next comparison, place here
    */
    const zeroWidthSpace = /&#x200b;/g;
    const deserializedZeroWidthSpace = /\u200b/g;

    return [zeroWidthSpace, deserializedZeroWidthSpace];
  };

  onSelectTag = () => {
    // this hooks into the select-dropdown-item function in our forked tributejs
    // without this, attempting to submit immediately after a tag is selected will not include the tag
    const descriptionWithTag = this.normalizeHtml(
      this.editable.current?.htmlEl?.innerHTML
    );
    this.props.onChange(descriptionWithTag);
  };

  getTextToHighlight = () => {
    const { currentDescriptionText, isSelectedHomeTask } = this.props;
    if (isSelectedHomeTask) {
      return unescape(currentDescriptionText);
    } else {
      const { truncatedDescription } = this.getTruncatedDescription();

      return unescape(truncatedDescription);
    }
  };

  editingHeight = null;
  handleSetEditingHeight = () => {
    const { setEditingHeight, index } = this.props;
    if (this.editable.current) {
      const currentHeight = this.editable.current.htmlEl.offsetHeight;
      if (currentHeight !== this.editingHeight && setEditingHeight) {
        this.editingHeight = currentHeight;
        setEditingHeight({ index, height: Math.max(currentHeight + 22, 62) }); // additional non text area height on task row
      }
    }
  };

  handleBlur = () => {
    const { handleBlur = noop, setEditingHeight = noop } = this.props;
    handleBlur();
    setEditingHeight({ index: null, height: null });
    this.editingHeight = null;
  };

  render() {
    const {
      taskId,
      textAreaId,
      currentDescriptionText,
      taskIsCreating,
      taskIsEditing,
      tagsByTitle,
      activeTagId,
      isTaskModal,
      isOnProjectView,
      testIdPrefix
    } = this.props;
    const baseDescription = this.normalizeHtml(currentDescriptionText);
    const editableDescription = this.prepTextForEditing(baseDescription);
    const truncatedTags = this.getTruncatedTags();
    const truncatedTagsText = truncatedTags.map((tag) => tag.slice(1));
    const firstTruncatedTag = truncatedTagsText.length && truncatedTagsText[0];
    const activeTruncatedTag = truncatedTagsText.find((tag) => {
      const tagInfo = tagsByTitle[tag];
      return tagInfo && activeTagId && tagInfo.id === activeTagId;
    });
    const remainingTagsCountTag = activeTruncatedTag || firstTruncatedTag;
    return (
      <TaskDescription taskIsCreating={taskIsCreating}>
        <ContentEditable
          id={`task-description-field-${textAreaId || taskId}`}
          data-placeholder="Type task name"
          ref={this.editable}
          html={editableDescription}
          onClick={this.handleClick}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.moveCaretToEnd}
          onBlur={this.handleBlur}
          normalizeHtmlOptions={this.getNormalizeHtmlOptions()}
          style={calcContentEditableStyle(taskIsCreating, taskIsEditing)}
          taskiscreating={taskIsCreating}
        />
        {!taskIsEditing && !taskIsCreating && (
          <TaskDescriptionSpan data-testid={`${testIdPrefix}-task`}>
            <NoneditTaskDescriptionWrapper>
              <Highlighter
                highlightClassName="highlight"
                highlightTag={ConnectedDescriptionTag}
                searchWords={this.getSearchParams()}
                textToHighlight={this.getTextToHighlight()}
              />
              {!!truncatedTags.length && (
                <RemainingTagCount
                  color={
                    tagsByTitle[remainingTagsCountTag] &&
                    tagsByTitle[remainingTagsCountTag].color
                  }
                  isActive={!!activeTruncatedTag}
                  onClick={this.handleSetTaskAsEditing}
                >
                  +{truncatedTags.length}
                </RemainingTagCount>
              )}
            </NoneditTaskDescriptionWrapper>
          </TaskDescriptionSpan>
        )}
        <TasksMetaContainer
          taskIsCreating={taskIsCreating}
          isOnProjectView={isOnProjectView}
        >
          <TaskIconsContainer
            $hide={isTaskModal}
            $taskIsEditing={taskIsEditing}
            onClick={this.handleToggleTaskModal}
          >
            {this.displayNoteIcon()}
            {this.displayCommentIcon()}
          </TaskIconsContainer>
        </TasksMetaContainer>
      </TaskDescription>
    );
  }
}

const mapStateToProps = (state) => ({
  allTags: getTributeTaskTags(state),
  tagsByTitle: getTagsByTitle(state),
  activeTagId: state.tags.filterTagId,
  isOnProjectView: getIsOnProjectView(state)
});

export default connect(mapStateToProps, {
  triggerSetEditTaskId: setEditTaskId,
  handleSetSelectedHomeTask: setSelectedHomeTask,
  triggerFetchCommentsAndMetadata: fetchCommentsAndMetadata,
  navigateToTaskModal,
  fetchTaskGroups
})(TaskDescriptionWithTags);
