import cn from 'classnames';
import styled from 'styled-components';
import ModuleListItem from './ModuleListItem';
import AddModuleListItem from './AddModuleListItem';
import ProjectColumnsDropdown from 'views/projectDisplay/ProjectColumnsDropdown';

const ModuleList = ({
  changeTabs,
  currentTab,
  installedModuleIds,
  className = '',
  tabCounts = [],
  viewType
}) => {
  const renderModuleTab = (id) => (
    <ModuleListItem
      currentTab={currentTab}
      key={id}
      moduleId={id}
      onClick={changeTabs}
      tabCounts={tabCounts}
    />
  );

  const showAddModuleItem = false;

  return (
    <StyledModuleList
      $isNotDisplayed={viewType === 'board'}
      className={className}
    >
      {installedModuleIds.map(renderModuleTab)}
      {viewType === 'project' && <ProjectColumnsDropdown show />}
      {showAddModuleItem && (
        <AddModuleListItem moduleIds={installedModuleIds} />
      )}
    </StyledModuleList>
  );
};

const StyledModuleList = styled.ul.attrs(({ $isNotDisplayed }) => ({
  className: cn({ hidden: $isNotDisplayed })
}))`
  color: ${(props) => props.theme.colors.colorMediumGray5};
  display: flex;
  font-size: 16px;
  gap: 24px;
  justify-content: start;
  margin-top: 10px;
  padding: 0;

  &.hidden {
    display: none;
  }
`;

export default ModuleList;
