import React from 'react';
import SvgIcon from 'components/SvgIcon';

const BaselineIcon = ({
  width = '9',
  height = '9',
  className,
  color = '#A4A4A4'
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 -1 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 5.62159L0.954284 0.5H8.04572L4.5 5.62159Z" stroke={color} />
    <path d="M1 7H8" stroke={color} />
  </SvgIcon>
);

export default BaselineIcon;
