import React from 'react';
import { StyledRateMultiplierCell } from './styles';
import MemberRoleSelection from 'components/roles/MemberRoleSelection';

const RoleCell = ({ cell, row }) => {
  if (!row.original.member) {
    return <div> </div>;
  }

  return (
    <StyledRateMultiplierCell>
      <MemberRoleSelection
        accountId={row.original.member.account.id}
        isTeamRole
      />
    </StyledRateMultiplierCell>
  );
};

export default RoleCell;
