import theme from 'theme';
import styled from 'styled-components';
import { useAppDispatch } from 'reduxInfra/hooks';
import UnassignedRolesDropdown, { AddNewRow } from './UnassignedRolesDropdown';
import useUnassignedRolesByProjectAndPhases, {
  ProjectUnassignedMemberBudgetWithPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import BulkPositionDropdown from 'components/roles/dropdowns/BulkPositionDropdown';
import { sortPositionNames } from 'components/roles/utils';
import { openRemoveMemberModal } from 'BudgetModule/actionCreators';
import QBDownArrow from 'icons/QBDownArrow';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import useUnassignedRoleDropdownBoilerplate from './useUnassignedRoleDropdownBoilerplate';
import { ContainerDiv } from './styles';
import EllipsisText from 'components/EllipsisText';
import NewCloseIcon from 'icons/NewCloseIcon';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
/**
 * By default, projectId, onExistingUnassignedRoleSelect and unassignedMemberBudgetId are required
 */
const UnassignedRolesSelectorForProject = ({
  unassignedMemberBudgetId,
  projectId,
  bulkPositionsDropdownShouldFetch,
  combineExistingAndAddableUnassignedRoles = false,
  isLoading = false,
  onExistingUnassignedRoleSelect,
  onExistingUnassignedRoleDropdownClose,
  onBulkUnassignedRoleAddDropdownClose,
  customHandleDeleteExistingUnassignedRoles,
  createMemberBudgetOnSuccess
}: {
  projectId: number;
  unassignedMemberBudgetId: Nullable<number>;
  onExistingUnassignedRoleSelect:
    | ((item: ProjectUnassignedMemberBudgetWithPosition) => void)
    | undefined;
  /* -------------------------------------------------------------------------- */
  bulkPositionsDropdownShouldFetch?: boolean;
  combineExistingAndAddableUnassignedRoles?: boolean; // See example on Team Builder. If true, it will be a single select of unassigned role, or bulk add unassigned roles to project
  isLoading?: boolean;
  customHandleDeleteExistingUnassignedRoles?: (
    e: unknown,
    {
      item,
      closeBulkUnassignedRoleAddDropdown
    }: {
      item: ProjectUnassignedMemberBudgetWithPosition;
      closeBulkUnassignedRoleAddDropdown: () => void;
    }
  ) => void; // When combineExistingAndAddableUnassignedRoles = true, current existing unassigned roles is deletable
  /* -------------------------------------------------------------------------- */
  onExistingUnassignedRoleDropdownClose?: () => void;
  onBulkUnassignedRoleAddDropdownClose?: () => void;
  createMemberBudgetOnSuccess?: ({ response }) => void;
}) => {
  const dispatch = useAppDispatch();
  const {
    openBulkUnassignedRoleAddDropdown,
    closeBulkUnassignedRoleAddDropdown,
    bulkUnassignedAddDropdownRef,
    isBulkAddUnassignedRolesOpen,
    /* -------------------------------------------------------------------------- */
    closeUnassignedRoleDropdown,
    openUnassignedRoleDropdown,
    unassignedRolesSelectDropdownRef,
    isUnassignedRoleDropdownOpen,
    handleCloseUnassignedRoleDropdown,
    handleAddUnassignedRoles
  } = useUnassignedRoleDropdownBoilerplate({
    onBulkUnassignedRoleAddDropdownClose,
    onExistingUnassignedRoleDropdownClose,
    projectId,
    createMemberBudgetOnSuccess
  });

  const {
    getUnassignedMemberBudgetWithPositionForProject,
    unassignedMemberBudgetsWithPositionForProject
  } = useUnassignedRolesByProjectAndPhases({
    projectId
  });

  const handleSelectUnassignedRole = (
    _,
    {
      item
    }: {
      item: ProjectUnassignedMemberBudgetWithPosition | AddNewRow;
    }
  ) => {
    if ((item as AddNewRow).isAddNewRow) {
      openBulkUnassignedRoleAddDropdown();
      closeUnassignedRoleDropdown();
      return;
    }

    onExistingUnassignedRoleSelect &&
      onExistingUnassignedRoleSelect(
        item as ProjectUnassignedMemberBudgetWithPosition
      );

    closeUnassignedRoleDropdown();
  };

  const handleDeleteExistingUnassignedRoles = (
    e,
    item: ProjectUnassignedMemberBudgetWithPosition
  ) => {
    dispatch(
      openRemoveMemberModal({
        memberBudget: {
          ...item,
          isUnassigned: true
        }
      })
    );
    ReactTooltip.hide();
    closeBulkUnassignedRoleAddDropdown();
  };

  // See example on Team Builder
  if (combineExistingAndAddableUnassignedRoles) {
    const customRenderItem = ({ item }) => {
      rebuildTooltip();

      if (item.isExisting) {
        const { position } =
          (item as ProjectUnassignedMemberBudgetWithPosition) || {};

        const roleNameToUse = position?.nameWithCount ?? '';

        return (
          <StyledItem className="existing-project-role">
            <div
              className="delete-container"
              onClick={(e) =>
                customHandleDeleteExistingUnassignedRoles
                  ? customHandleDeleteExistingUnassignedRoles(e, {
                      item,
                      closeBulkUnassignedRoleAddDropdown
                    })
                  : handleDeleteExistingUnassignedRoles(e, item)
              }
              data-tip="Remove Unassigned Role <br /> from the project"
              {...defaultTooltipProps}
              data-class="center"
            >
              <NewCloseIcon />
            </div>
            <EllipsisText maxWidth={193}>{roleNameToUse}</EllipsisText>
          </StyledItem>
        );
      }

      return (
        <StyledItem
          className={cn(`addable-project-role`, {
            added: item.isAdded
          })}
        >
          <div>
            <EllipsisText maxWidth={210}>{item.name}</EllipsisText>
            <div className="subtitle">Add to Project</div>
          </div>
          <div className="add-container">
            {item.isAdded ? (
              'Added'
            ) : (
              <span
                data-tip="Add Unassigned Role <br /> to the project"
                {...defaultTooltipProps}
                data-class="center"
              >
                <PlusCircleIcon />
              </span>
            )}
          </div>
        </StyledItem>
      );
    };

    const customMakeBulkDropdownItems = ({
      addedRoles,
      allExistingPositions,
      filteredAllPositions
    }) => {
      return [
        ...sortPositionNames(addedRoles).map((role) => ({
          ...role,
          className: 'added-project-role-list-item',
          isAdded: true
        })),
        ...unassignedMemberBudgetsWithPositionForProject.map((role) => ({
          ...role,
          name: role.position?.nameWithCount ?? role.position?.name,
          className: 'existing-project-role-list-item',
          isExisting: true,
          unSelectable: true
        })),
        ...sortPositionNames(allExistingPositions),
        ...sortPositionNames(filteredAllPositions)
      ];
    };
    return (
      <ContainerDiv className="single-screen-project-selector">
        <div
          className="selector-text"
          ref={bulkUnassignedAddDropdownRef}
          onClick={openBulkUnassignedRoleAddDropdown}
        >
          + Role
        </div>
        {isBulkAddUnassignedRolesOpen && (
          <BulkPositionDropdown
            onClose={closeBulkUnassignedRoleAddDropdown}
            onAdd={handleAddUnassignedRoles}
            targetRef={bulkUnassignedAddDropdownRef}
            projectId={projectId}
            phaseId={undefined}
            excludeExisiting
            shouldShowCancelButton={false}
            showAddToProjectOnly
            closeOnSelect
            headerTitle="Unassigned Roles"
            shouldFetch={bulkPositionsDropdownShouldFetch}
            customMakeBulkDropdownItems={customMakeBulkDropdownItems}
            customRenderItem={customRenderItem}
            popoverClassName={'team-builder-bulk-add-positions-dropdown'}
            isParentLoading={isLoading}
          />
        )}
      </ContainerDiv>
    );
  }

  /* --------------------------------- Default: Single Select unassigned role for Project -------------------------------- */

  const unassignedRole = getUnassignedMemberBudgetWithPositionForProject({
    memberBudgetId: unassignedMemberBudgetId
  });

  return (
    <ContainerDiv>
      <div
        className="unassigned-role-name-container"
        ref={bulkUnassignedAddDropdownRef}
      >
        <div
          className="unassigned-role-name"
          ref={unassignedRolesSelectDropdownRef}
          onClick={openUnassignedRoleDropdown}
          data-tip={unassignedRole?.position.nameWithCount || ''}
          data-for="app-tooltip"
          data-effect="solid"
        >
          {!unassignedMemberBudgetId
            ? 'Select Unassigned Role'
            : unassignedRole?.position.nameWithCount || ''}
        </div>
        <span>
          <QBDownArrow fill={theme.colors.colorCalendarBlue} />
        </span>
      </div>
      {/* Selecting existing unassigned role for Project */}
      {isUnassignedRoleDropdownOpen && (
        <UnassignedRolesDropdown
          handleSelect={handleSelectUnassignedRole}
          handleClose={handleCloseUnassignedRoleDropdown}
          projectId={projectId}
          phaseId={undefined}
          targetRef={unassignedRolesSelectDropdownRef}
          showPhaseUnassignedRoles={false}
        />
      )}
      {/* Selecting new unassigned role to add to Project */}
      {isBulkAddUnassignedRolesOpen && (
        <BulkPositionDropdown
          onClose={closeBulkUnassignedRoleAddDropdown}
          onAdd={handleAddUnassignedRoles}
          targetRef={bulkUnassignedAddDropdownRef}
          projectId={projectId}
          phaseId={undefined}
          excludeExisiting
          showAddToProjectOnly
          closeOnSelect
          headerTitle="Unassigned Roles"
          shouldFetch={bulkPositionsDropdownShouldFetch}
          popoverClassName={'team-builder-bulk-add-positions-dropdown'}
          confirmButtonText={'Done'}
        />
      )}
    </ContainerDiv>
  );
};

export default UnassignedRolesSelectorForProject;

const StyledItem = styled.div<{ isAdded?: boolean }>`
  &.existing-project-role {
    display: flex;
    gap: 10px;
    padding-left: 10px;

    .delete-container {
      cursor: pointer;

      circle {
        stroke: none;
      }

      path {
        fill: ${theme.colors.colorCalendarBlue};
      }
    }
  }

  &.addable-project-role {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 44px;
    width: 100%;
    .add-container {
      padding-right: 10px;
    }

    .subtitle {
      font-size: 11px;
      font-weight: 400;
      color: ${theme.colors.colorCalendarGray};
    }

    &.added {
      .add-container {
        color: ${theme.colors.colorCalendarRed};
      }
      .subtitle {
        display: none;
      }
    }
  }
`;
