import {
  useRef,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import { ThemeProvider } from 'styled-components';
import { scheduleConfig } from './style';
import {
  dataToGroup,
  mapEventsByDate,
  getBackgroundRenderOption
} from './utils';
import { Calendar } from './Calendar';

const ScheduleCalendarContext = createContext({});

const defaultInitialValues = {
  initialDate: new Date(),
  data: [],
  onSetRange: undefined,
  backgroundEvents: [],
  initialViewType: 'year',
  renderDateItem: undefined,
  renderExtraItem: undefined,
  renderExtraFooter: undefined,
  renderBackground: undefined
};

export const ScheduleCalendarProvider = ({ initialValues = {}, children }) => {
  const {
    calendarRef,
    initialDate,
    data,
    onSetRange,
    backgroundEvents,
    initialViewType,
    renderDateItem,
    renderExtraItem,
    renderExtraFooter,
    renderBackground
  } = {
    ...defaultInitialValues,
    ...(initialValues ?? {})
  };

  const { current: calendar } = useRef(
    calendarRef ?? new Calendar({ initialViewType, initialDate: initialDate })
  );
  const [initialized, setInitilized] = useState(false);

  const [currentDate, setCurrentDate] = useState(
    calendar.getCurrentDate().date
  );

  const dataEntries = useMemo(() => dataToGroup(data ?? []), [data]);

  const backgroundEventsEntries = useMemo(
    () => mapEventsByDate(backgroundEvents ?? []),
    [backgroundEvents]
  );

  const onSetRangeListener = useCallback(
    ({ start, end, currentDate }) => {
      setCurrentDate(currentDate);
      onSetRange && onSetRange({ start, end, currentDate });
    },
    [onSetRange]
  );

  useEffect(() => {
    // initial onSetRange when the component mounted
    if (!initialized) {
      setInitilized(true);
      onSetRange &&
        onSetRange({
          currentDate: calendar.getCurrentDate().date,
          ...calendar.getRange()
        });
    }
  }, [calendar, initialized, onSetRange]);
  useEffect(() => {
    calendar.on('onSetRange', onSetRangeListener);
    return () => calendar.off('onSetRange', onSetRangeListener);
  }, [calendar, onSetRangeListener]);

  // can get only single item per day
  const getDataByDate = (dateKey) => dataEntries[dateKey];

  // only return first item since the calendar can only show one background event per day for now
  const getFirstBackgroundEventByDate = (dateKey) => {
    if (backgroundEventsEntries[dateKey]?.length) {
      return backgroundEventsEntries[dateKey][0];
    }
  };

  const getBackgroundEventsByDate = (dateKey) =>
    backgroundEventsEntries[dateKey];

  const getBackgroundRenderOptionByDate = (dateKey) => {
    const event = getFirstBackgroundEventByDate(dateKey);
    if (!event) return null;

    return getBackgroundRenderOption(event, dateKey);
  };

  const handleRenderDateItem = (dateObj) => {
    return (
      renderDateItem &&
      renderDateItem({
        dateObj,
        item: getDataByDate(dateObj.date) ?? {},
        backgroundEvent: getBackgroundRenderOptionByDate(dateObj.date) ?? {}
      })
    );
  };

  const handleRenderExtraItem = ({ index, year, month, dateObjArray }) => {
    const items = dateObjArray.map(({ date }) => getDataByDate(date));
    const backgroundEvents = dateObjArray.map(({ date }) =>
      getBackgroundEventsByDate(date)
    );
    return (
      renderExtraItem &&
      renderExtraItem({
        index,
        items,
        year,
        month,
        dateObjArray,
        backgroundEvents
      })
    );
  };

  const handleRenderExtraFooter = ({ year, month, dateRows }) => {
    // pass yearn and month as number
    // 2021 Jan -> {year: 2021, month: 0}
    const flattenDateObjArray = dateRows.flat();
    const items = flattenDateObjArray.map(({ date }) => getDataByDate(date));
    const backgroundEvents = flattenDateObjArray.map(({ date }) =>
      getBackgroundEventsByDate(date)
    );
    return (
      renderExtraFooter &&
      renderExtraFooter({
        year,
        month,
        items,
        backgroundEvents,
        dateObjArray: flattenDateObjArray
      })
    );
  };

  const handleRenderBackground = ({ backgroundEvent, renderOption }) => {
    return (
      renderBackground && renderBackground({ backgroundEvent, renderOption })
    );
  };

  const value = {
    calendar,
    viewType: calendar.getCurrentViewType(),
    currentDate: currentDate,
    getDataByDate,
    getBackgroundRenderOptionByDate: getBackgroundRenderOptionByDate,
    renderDateItem: handleRenderDateItem,
    renderExtraItem: renderExtraItem && handleRenderExtraItem,
    renderExtraFooter: renderExtraFooter && handleRenderExtraFooter,
    renderBackground: handleRenderBackground
  };

  return (
    <ScheduleCalendarContext.Provider value={value}>
      <ThemeProvider theme={scheduleConfig}>{children}</ThemeProvider>
    </ScheduleCalendarContext.Provider>
  );
};

export const useScheduleCalendar = () => useContext(ScheduleCalendarContext);
