import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Header from '../../WidgetParts/Header';
import Body from '../../WidgetParts/Body';
import TimesheetChart from './TimesheetChart';
import {
  getMyUserId,
  getThisWeekTimeEntries,
  getLastWeekTimeEntries
} from 'selectors';
import {
  TimesheetChartContainer,
  TimesheetLegend,
  LegendBox,
  LegendText,
  LegendContainer,
  FractionNum,
  FractionDen
} from 'views/Home/Widgets/styles';
import theme from 'theme';
import styled from 'styled-components';

import { fetchTimesheets } from 'actionCreators';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getAccountCapacities } from 'CapacityModule/selectors';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import {
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY
} from 'appConstants/workload';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const emptyObj = {};

const ChartContainer = styled(TimesheetChartContainer)`
  ${FractionNum} {
    color: ${theme.colors.colorDarkGray5};
    font-size: 17px;
  }
  ${FractionDen} {
    color: ${theme.colors.colorMediumGray1};
    font-size: 12px;
  }

  ${(props) => props.chartContainerCSS};
`;

const Legend = [
  {
    name: 'not_entered',
    label: 'Not Entered',
    color: theme.colors.colorPureWhite
  },
  {
    name: 'entered',
    label: 'Entered',
    color: theme.colors.colorBudgetBlue
  },
  {
    name: 'submitted',
    label: 'Submitted',
    color: theme.colors.colorRemainingTeal
  }
];

const sumArray = (arr) => arr.reduce((acc, cur) => acc + +cur, 0);

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');
const calcWeekCapacity = (capacity) => {
  if (!capacity) {
    return DEFAULT_WEEKLY_CAPACITY;
  }
  const weekCapacity = CAPACITY_DATE_KEYS.map((key) => capacity[key]).reduce(
    (a, b) => a + b
  );
  return weekCapacity || DEFAULT_WEEKLY_CAPACITY;
};

class TimesheetWidget extends React.Component {
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.myId !== prevProps.myId) {
      // props change
      this.loadData();
    }
  }

  loadData = () => {
    const { fetchTimesheets, myId } = this.props;
    if (this.props.loadData) {
      this.props.loadData();
      return;
    }
    const startDate = getMondayOfWeek(moment().add(-1, 'weeks'));
    const endDate = startDate.clone().add(2, 'weeks');
    if (myId) {
      fetchTimesheets({
        startDate: formatFetchDate(startDate),
        endDate: formatFetchDate(endDate),
        accountId: myId,
        planned: false
      });
    }
  };

  getTooltipContent = ({ data, capacity, customColorsForChart }) => {
    const notEntered =
      +data.find((item) => item.name === 'not_entered')?.value || 0;
    const entered = +data.find((item) => item.name === 'entered')?.value || 0;
    const submitted =
      +data.find((item) => item.name === 'submitted')?.value || 0;
    const tooltipContents = {
      data: [
        {
          name: 'notEntered',
          label: 'Not Entered',
          value: formatNumWithMaxTwoDecimals(notEntered),
          color:
            customColorsForChart.not_entered || theme.colors.colorPureWhite,
          border: `1px solid ${theme.colors.colorMediumGray1}`
        },
        {
          name: 'entered',
          label: 'Entered',
          value: formatNumWithMaxTwoDecimals(entered),
          color: customColorsForChart.entered || theme.colors.colorBudgetBlue,
          border: `1px solid ${theme.colors.colorPaleGray12}`
        },
        {
          name: 'submitted',
          label: 'Submitted',
          value: formatNumWithMaxTwoDecimals(submitted),
          color:
            customColorsForChart.submitted || theme.colors.colorRemainingTeal,
          border: `1px solid ${theme.colors.colorPaleGray12}`
        }
      ],
      summary: [
        {
          name: 'capacity',
          label: 'Capacity',
          value: capacity?.total && formatNumWithMaxTwoDecimals(capacity.total),
          color: 'tranparent'
        }
      ]
    };

    return tooltipContents;
  };

  getItems = (timesheets = [], customColorsForChart) => {
    const totalCapacity = this.getCapacity(); //
    const submittedTotal = sumArray(
      timesheets
        .filter(
          (timesheet) =>
            timesheet.status === 'submitted' || timesheet.status === 'approved'
        )
        .map(
          (timesheet) =>
            timesheet.hours || +timesheet.billable + +timesheet.nonbillable
        )
    );
    const unsubmittedTotal = sumArray(
      timesheets
        .filter((timesheet) => timesheet.status === 'not_submitted')
        .map(
          (timesheet) =>
            timesheet.hours || +timesheet.billable + +timesheet.nonbillable
        )
    );
    const remaining = totalCapacity - submittedTotal - unsubmittedTotal;

    const dataGroups = [
      {
        name: 'submitted',
        value: submittedTotal,
        color: customColorsForChart.submitted || theme.colors.colorRemainingTeal
      },
      {
        name: 'entered',
        value: unsubmittedTotal,
        color: customColorsForChart.entered || theme.colors.colorBudgetBlue
      }
    ];
    if (remaining > 0) {
      this.props.clockWiseChart
        ? dataGroups.reverse().splice(1, 0, {
            name: 'not_entered',
            value: remaining,
            color:
              customColorsForChart.not_entered || theme.colors.colorPureWhite
          })
        : dataGroups.unshift({
            name: 'not_entered',
            value: remaining,
            color:
              customColorsForChart.not_entered || theme.colors.colorPureWhite
          });
    }
    return dataGroups;
  };

  // may be able to re-use time entry grouper this.getItems
  getItemsFromUtilization = (timesheets, customColorsForChart) => {
    const totalCapacity = this.getCapacity();
    const billableTotal = sumArray(
      timesheets.map((timesheet) => timesheet.billable)
    );
    const nonBillableTotal = sumArray(
      timesheets.map((timesheet) => timesheet.nonbillable)
    );
    const remaining = totalCapacity - billableTotal - nonBillableTotal;

    const dataGroups = [
      {
        name: 'billable',
        value: billableTotal,
        color: customColorsForChart.billable || '#0074D9'
      },
      {
        name: 'nonbillable',
        value: nonBillableTotal,
        color: customColorsForChart.nonbillable || '#CDCDCD'
      }
    ];
    if (remaining > 0) {
      dataGroups.unshift({
        name: 'not_entered',
        value: remaining,
        color: customColorsForChart.not_entered || '#F5F5F5'
      });
    }
    return dataGroups;
  };

  getCapacity = () => {
    // only works for 1 week range
    const { accountCapacities, myId } = this.props;
    const accountIds = this.props.accountIds || [myId];
    const totalCapacity = sumArray(
      accountIds.map((id) => calcWeekCapacity(accountCapacities[id]))
    );

    return totalCapacity;
  };

  render() {
    const {
      firstRangeTimesheets = [],
      secondRangeTimesheets = [],
      chartOnly,
      firstRangeHeaderText = 'Last Week',
      secondRangeHeaderText = 'This Week',
      totalCapacity,
      shouldUseUtilization = false,
      showPercent = false,
      noHeader,
      chartStyles,
      pieStyles = emptyObj,
      fractionNumSuffix,
      fractionNumStyles,
      fractionDenStyles,
      chartHeaderCSS,
      clockWiseChart,
      chartContainerCSS,
      chartOuterContainerCSS,
      timesheetLegendCSS,
      legendBoxCSS,
      legendTextCSS,
      accountCapacities,
      showCustomTooltip,
      accountId,
      stroke,
      testIdPrefix,
      chartsDetailsClassName,
      customColorsForChart = emptyObj
    } = this.props;
    const itemGetter = shouldUseUtilization
      ? this.getItemsFromUtilization
      : this.getItems;

    const sharedProps = {
      totalCapacity: totalCapacity || this.getCapacity(),
      chartStyles: { width: 84, height: 84, ...chartStyles },
      pieStyles: { innerRadius: 32, outerRadius: 42, ...pieStyles },
      showPercent: showPercent,
      fractionNumSuffix: fractionNumSuffix,
      fractionNumStyles: fractionNumStyles,
      fractionDenStyles: fractionDenStyles,
      chartHeaderCSS: chartHeaderCSS,
      clockWiseChart: clockWiseChart,
      chartOuterContainerCSS: chartOuterContainerCSS,
      stroke: stroke,
      showCustomTooltip: showCustomTooltip,
      testIdPrefix: testIdPrefix,
      chartsDetailsClassName: chartsDetailsClassName
    };

    const itemsFirstRange = itemGetter(
      firstRangeTimesheets,
      customColorsForChart
    );
    const itemsSecondRange = itemGetter(
      secondRangeTimesheets,
      customColorsForChart
    );
    const tooltipFirstRange = this.getTooltipContent({
      data: itemsFirstRange,
      capacity: accountCapacities[accountId],
      customColorsForChart: customColorsForChart
    });
    const tooltipSecondRange = this.getTooltipContent({
      data: itemsSecondRange,
      capacity: accountCapacities[accountId],
      customColorsForChart: customColorsForChart
    });

    const firstChartHasData =
      +itemsFirstRange[0]?.value > 0 ||
      +itemsFirstRange[1]?.value > 0 ||
      +itemsFirstRange[2]?.value > 0;

    const secondChartHasData =
      +itemsSecondRange[0]?.value > 0 ||
      +itemsSecondRange[1]?.value > 0 ||
      +itemsSecondRange[2]?.value > 0;

    return (
      <DynamicModuleLoader modules={[getCapacitiesModule()]}>
        {!chartOnly && !noHeader && <Header text={'Timesheet'} />}
        <Body>
          <ChartContainer chartContainerCSS={chartContainerCSS}>
            <TimesheetChart
              items={itemsFirstRange}
              headerText={firstRangeHeaderText}
              {...sharedProps}
              tooltipContent={tooltipFirstRange}
              tooltipDataFor="legend-style-tooltip"
              hasData={firstChartHasData}
            />
            <TimesheetChart
              items={itemsSecondRange}
              headerText={secondRangeHeaderText}
              {...sharedProps}
              tooltipContent={tooltipSecondRange}
              tooltipDataFor="legend-style-tooltip"
              hasData={secondChartHasData}
            />
          </ChartContainer>
          {!chartOnly && (
            <TimesheetLegend timesheetLegendCSS={timesheetLegendCSS}>
              {Legend.map(({ name, label, color }) => (
                <LegendContainer key={name}>
                  <LegendBox
                    color={customColorsForChart[name] || color}
                    legendBoxCSS={legendBoxCSS}
                  />
                  <LegendText legendTextCSS={legendTextCSS}>{label}</LegendText>
                </LegendContainer>
              ))}
            </TimesheetLegend>
          )}
        </Body>
      </DynamicModuleLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  myId: getMyUserId(state),
  accountCapacities: getAccountCapacities(state),
  thisWeekTimesheets: getThisWeekTimeEntries(state),
  lastWeekTimesheets: getLastWeekTimeEntries(state)
});

const mapDispatchToProps = { fetchTimesheets };

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWidget);
