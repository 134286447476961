import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import styled from 'styled-components';
import { MembersPermissionsTable } from './members/MembersPermissionsTable';
import { MembershipTableDataProvider } from 'PermissionsModule/components/settingsViews/members/DataProvider';

export const PermissionsSettingsMembershipTab = () => {
  const { newPermissionSettings } = useFeatureFlags();

  if (!newPermissionSettings) {
    return <></>;
  }

  return (
    <RootContainer>
      <MembershipTableDataProvider>
        <MembersPermissionsTable />
      </MembershipTableDataProvider>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  // margin added to align start of children content with the tab nav
  overflow-y: auto;
`;
