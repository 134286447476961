import styled, { css } from 'styled-components';
import theme from 'theme';

export const CalendarContainer = styled.div<{
  shouldRenderButtonPanel: boolean;
}>`
  position: relative;
  margin-bottom: 10px;

  .DayPicker {
    border-radius: 0;
    > div {
      > div {
        width: 100% !important;
      }
    }
  }

  .DayPicker_transitionContainer {
    width: 100% !important;
  }

  .DayPicker_weekHeader {
    width: 100%;
  }

  .DayPicker_weekHeaders__horizontal {
    margin: 0;
  }

  .DayPicker_weekHeader_ul {
    display: flex;
  }

  // workaround to only show first letter of day of the week on work plan calendar
  // solution from: https://github.com/react-dates/react-dates/issues/1084#issuecomment-442380162
  .DayPicker_weekHeader_li {
    visibility: hidden;
    font-size: 0;
    width: auto !important;
    flex-grow: 1;
  }

  .DayPicker_weekHeader_li::first-letter {
    visibility: visible;
    font-size: 13px;
    font-weight: 600;
    color: ${theme.colors.colorDarkGray5};
  }

  .DayPicker,
  .CalendarMonth,
  .CalendarMonthGrid {
    background-color: ${theme.colors.colorPureWhite};
    left: 0px;
    width: 100% !important;
  }

  .CalendarMonthGrid_month__horizontal {
    width: 100%;
  }

  .CalendarMonth_table {
    width: 100%;
  }

  .weekend {
    color: ${theme.colors.colorCalendarGray};
  }

  .circleContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CalendarDay__Default,
  .CalendarDay {
    color: #383737;
    border: 0.5px solid ${theme.colors.colorLightGray6};
    background-color: ${theme.colors.colorTranslucentGray3};
    :hover {
      background-color: inherit;
      .circleContainer {
        background-color: ${theme.colors.colorPureWhite};
      }
    }
  }

  .CalendarDay__outside {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }

  .CalendarDay.CalendarDay__selected,
  .CalendarDay.CalendarDay__selected.CalendarDay.CalendarDay__hovered_offset {
    border: 2px solid ${theme.colors.colorCalendarBlue};
    opacity: 1;

    :hover {
      .circleContainer {
        ${theme.colors.colorPureWhite} !important;
      }
    }
  }

  .CalendarDay.CalendarDay__selected_span,
  .CalendarDay.CalendarDay__selected_span.CalendarDay.CalendarDay__hovered_offset {
    border-left: 1px solid #e4e7e7;
    border-right: 1px solid #e4e7e7;
    border-top: 1px double ${theme.colors.colorBudgetBlue};
    border-bottom: 1px double ${theme.colors.colorBudgetBlue};

    opacity: 1;

    :hover {
      background-color: ${theme.colors.colorPureWhite};
    }
  }

  .CalendarDay.CalendarDay__hovered_span {
    :hover {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      border: 2px solid ${theme.colors.colorBudgetBlue};
    }
  }

  .CalendarDay.CalendarDay__hovered_span,
  .CalendarDay.CalendarDay__hovered_offset {
    opacity: 1;
    background-color: ${theme.colors.colorPureWhite};

    border-left: 1px solid #e4e7e7;
    border-right: 1px solid #e4e7e7;
    border-top: 1px double ${theme.colors.colorBudgetBlue};
    border-bottom: 1px double ${theme.colors.colorBudgetBlue};

    .circleContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background-color: ${theme.colors.colorPureWhite};
      }
    }
  }

  .CalendarDay__today {
    color: #ff8520;
    font-weight: bold;
    font-size: 18px;
  }

  .CalendarDay__highlighted_calendar {
    color: ${theme.colors.colorCalendarRed} !important;
  }

  .CalendarDay__today.CalendarDay__selected,
  .CalendarDay__today.CalendarDay__selected.CalendarDay.CalendarDay__hovered_offset {
    background-color: ${theme.colors.colorTranslucentGray3};
    opacity: 1;

    .circleContainer:not(.over-capacity) {
      background-color: ${theme.colors.colorTranslucentGray3};

      color: ${theme.colors.colorCalendarOrange};
    }

    :hover {
      background-color: ${theme.colors.colorPureWhite};
      .circleContainer {
        background-color: ${theme.colors.colorPureWhite};
      }
    }
  }
  .CalendarMonth_caption {
    padding-top: 6px;
    padding-bottom: 11px;
  }
`;

export const CalendarStartDependencyLinkIconContainer = styled.div`
  position: absolute;
  left: 0;
`;

export const CalendarEndDependencyLinkIconContainer = styled.div`
  position: absolute;
  right: 0;
`;

export const CalendarDateBottomTextContainer = styled.div`
  position: absolute;
  bottom: -2px;
  font-size: 10px;
  color: ${theme.colors.colorCalendarGray};
`;

export const CalendarDayContainer = styled.div<{
  isPTO: boolean;
  isHoliday: boolean;
  capacityOnWorkPlanCalendar: boolean;
  utilizationPercentage: number;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;

  ${({
    isPTO,
    isHoliday,
    capacityOnWorkPlanCalendar,
    utilizationPercentage
  }) => {
    const type = !capacityOnWorkPlanCalendar
      ? 'hideCapacity'
      : isPTO
      ? 'PTO'
      : isHoliday
      ? 'Holiday'
      : 'default';
    return getCalendarBackgroundStyles(type, utilizationPercentage);
  }};

  :hover {
    background: ${({ isPTO, isHoliday }) =>
      !isPTO && !isHoliday && theme.colors.colorPureWhite};
  }
`;

const getCalendarBackgroundStyles = (type, utilizationPercentage) => {
  switch (type) {
    case 'hideCapacity':
      return css`
        background: ${theme.colors.colorTranslucentGray3};
      `;
    case 'PTO':
      return css`
        background: ${theme.gradients.pto};
      `;
    case 'Holiday':
      return css`
        background: ${theme.gradients.holiday};
      `;
    default:
      return css`
        background: linear-gradient(
          to top,
          ${theme.colors.colorTranslucentGray3} 0%,
          ${theme.colors.colorTranslucentGray3} ${utilizationPercentage}%,
          ${theme.colors.colorPureWhite} ${utilizationPercentage}%,
          ${theme.colors.colorPureWhite} 100%
        );
      `;
  }
};

export const DoneButton = styled.button`
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 5px;
  background: ${theme.colors.colorCalendarBlue};
  color: #ffffff;
  width: 52px;
  height: 28px;
  font-size: 13px;
  z-index: 100;
  font-weight: 600;
  position: absolute;
  top: 21px;
  right: 13px;
`;

export const CancelButton = styled.button`
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background: #f5f5f5;
  color: #828282;
  width: 52px;
  height: 28px;
  font-size: 13px;
  z-index: 100;
  font-weight: 600;
  position: absolute;
  top: 21px;
  left: 13px;
`;
