import { api } from '../service';
import { select } from 'redux-saga/effects';
import { changeEntity, fetchEntity } from './generics';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';

const {
  fetchEntityComments,
  createEntityComment,
  updateEntityComment,
  deleteEntityComment
} = entityActions;

export function* fetchEntityCommentsWorker(action) {
  const token = yield select(getAuthToken);
  const { parentEntityType, parentEntityId, limit, offset } = action.payload;

  const queryParam = convertKeysToSnakeCase({
    parentEntityType,
    parentEntityId,
    limit,
    offset
  });

  yield fetchEntity(
    fetchEntityComments,
    api.fetchEntityComments,
    undefined,
    [token, queryParam],
    action
  );
}

export function* createEntityCommentWorker(action) {
  const token = yield select(getAuthToken);
  const {
    parentEntityType,
    parentEntityId,
    parentCommentId,
    description,
    membersToNotify,
    files
  } = action.payload;
  const body = convertKeysToSnakeCase({
    parentEntityType,
    parentEntityId,
    parentCommentId,
    description,
    membersToNotify,
    files
  });
  yield changeEntity(createEntityComment, api.createEntityComment, [
    token,
    body
  ]);
}

export function* updateEntityCommentWorker(action) {
  const token = yield select(getAuthToken);
  const { id, description, files } = action.payload;

  yield changeEntity(
    updateEntityComment,
    api.updateEntityComment,
    [token, id, { description, files }],
    action
  );
}

export function* deleteEntityCommentWorker(action) {
  const token = yield select(getAuthToken);
  const { id, parentCommentId } = action.payload;
  yield changeEntity(deleteEntityComment, api.deleteEntityComment, [
    token,
    { id, parentCommentId }
  ]);
}
