import {
  billRateColumn,
  costRateColumn,
  rateColumn
} from '../../tableConfigsColumns';
import { TableCellProps } from '../../types';
import { CenteredHeaderCell } from './HeaderCell';

const headerCopy = {
  [rateColumn.headerType]: 'RATE/HR',
  [billRateColumn.headerType]: 'BILL RATE/HR',
  [costRateColumn.headerType]: 'COST RATE/HR'
} as const;

export const RateHeaderCell = ({ column: { headerType } }: TableCellProps) => (
  <CenteredHeaderCell>
    {headerCopy[headerType] ?? headerCopy[rateColumn.headerType]}
  </CenteredHeaderCell>
);
