import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';

import {
  getUserIsAdmin,
  getMemberBudgetsByProjectIdWithUnassigned,
  getTeamMembersHash
} from 'selectors';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { MemberLine, BudgetMemberLine } from '..';
import UnassignedRoleLine from './UnassignedRoleLine';
import keyBy from 'lodash/keyBy';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const UnassignedHeader = styled.div`
  padding-top: 15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  color: ${theme.colors.colorCalendarGray};
`;

const InviteMemberListContainer = styled.div`
  &.in-budget-modal {
    .member-name-container {
      display: flex;
      flex-direction: column;
    }
    .member-name {
      max-width: 150px;
    }
    .member-initials {
      overflow: unset !important;
      text-overflow: unset !important;
    }
    .member-is-guest {
      padding-left: 18px;
    }
  }
`;

const InviteMemberListHeader = styled.div`
  grid-template-columns: ${(props) =>
    props.includeRates
      ? '225px 197px 75px 183px !important'
      : '400px 260px !important'};
`;

const emptyArray = [];
const emptyObj = {};

const InviteMemberList = ({
  memberList,
  deleteMemberFromSelection,
  selection,
  me,
  roles,
  changeRoleFromLocalList,
  teamMemberModal,
  groupType,
  inBudgetModal,
  projectId,
  inputValue,
  alwaysShowThreeDot = false
}) => {
  const listRef = useRef(null);
  const userIsAdmin = useSelector(getUserIsAdmin);
  const { isHoursOnly } = useIsHoursOnly();
  const memberBudgetsByProjectId = useSelector(
    getMemberBudgetsByProjectIdWithUnassigned
  );
  const teamMembersHash = useSelector(getTeamMembersHash);
  const isMemberMatching = useCallback(
    (member) => {
      const { name, initials } = member.account || {};
      if (!inputValue?.length) {
        return true;
      }
      return (
        (name && name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) ||
        (initials &&
          initials.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
      );
    },
    [inputValue]
  );
  const isMemberBudgetMatching = useCallback(
    (memberBudget) => {
      const { account_id, position = {} } = memberBudget;
      const { name } = position || {};
      const account = teamMembersHash[account_id];
      if (!inputValue?.length) {
        return true;
      }
      return (
        (name && name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) ||
        (account && isMemberMatching(account))
      );
    },
    [inputValue, isMemberMatching, teamMembersHash]
  );
  const isUnassignedRoleMatching = useCallback(
    (memberBudget) => {
      const { position } = memberBudget;
      const name = position?.name || null;
      if (!inputValue?.length) {
        return true;
      }
      return (
        name && name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      );
    },
    [inputValue]
  );

  const MemberRowComponent = inBudgetModal ? BudgetMemberLine : MemberLine; // budget related styles in BudgetMembersModal

  const listItems = useMemo(() => {
    const memberBudgets = memberBudgetsByProjectId[projectId];
    const memberBudgetHash = memberBudgets
      ? keyBy(memberBudgets, 'id')
      : emptyObj;
    return inBudgetModal
      ? memberBudgets
          ?.filter((memberBudget) => !memberBudget['is_discarded?'])
          .filter(isMemberBudgetMatching)
          .sort((a, b) => {
            if (a.project_membership?.account_id !== me.id) {
              return 1;
            } else {
              return -1;
            }
          }) || emptyArray
      : memberList
          .filter((member) => member.account.initials)
          .filter(isMemberMatching)
          .map((member) => {
            return {
              ...member,
              memberBudget: memberBudgetHash[member.member_budget_id]
            };
          })
          .sort((a, b) => {
            // currently only sorting project managers to be at the top
            // remove this if condition and the else part when we want to also have portfolio
            // managers to always show at the top
            if (groupType === 'Project') {
              if (a.role_ids.length && a.role_ids[0] === 1) return -1;
              else {
                if (b.role_ids.length && b.role_ids[0] === 1) return 1;
                else if (a.account.id !== me.id) return 1;
                else return -1;
              }
            } else {
              if (a.account.id !== me.id) {
                return 1;
              } else {
                return -1;
              }
            }
          });
  }, [
    inBudgetModal,
    me.id,
    memberBudgetsByProjectId,
    memberList,
    projectId,
    isMemberMatching,
    isMemberBudgetMatching,
    groupType
  ]);

  const unassignedRoleListitems = useMemo(() => {
    const memberBudgets = memberBudgetsByProjectId[projectId];
    return memberBudgets
      ? memberBudgets
          ?.filter(
            (memberBudget) =>
              !memberBudget[`is_discarded?`] && !memberBudget.account_id
          )
          .filter(isUnassignedRoleMatching)
      : [];
  }, [isUnassignedRoleMatching, memberBudgetsByProjectId, projectId]);
  return (memberList && memberList.length) ||
    (unassignedRoleListitems && unassignedRoleListitems.length) ? (
    <InviteMemberListContainer
      className={`${inBudgetModal && 'in-budget-modal'}`}
    >
      {inBudgetModal && (
        <InviteMemberListHeader
          className="column-header-row"
          includeRates={!isHoursOnly}
        >
          <div className="header-label">MEMBER</div>
          <div className="header-label budget-member-header-padding">ROLE</div>
          {/* {userIsAdmin && <ColumnLabel>COST RATE</ColumnLabel>} */}
          {!isHoursOnly && (
            <div className="header-label budget-member-header-padding">
              RATE/HR
            </div>
          )}
          {!isHoursOnly && <div className="header-label">DESCRIPTION</div>}
        </InviteMemberListHeader>
      )}
      <ul className="invite-member-list" ref={listRef}>
        {listItems.map((member, index) => (
          <MemberRowComponent
            key={member.id}
            projectMembers={true}
            me={me}
            {...(inBudgetModal ? { memberBudget: member } : { member: member })}
            deleteMemberFromSelection={deleteMemberFromSelection}
            selection={selection}
            roles={roles}
            changeRoleFromLocalList={changeRoleFromLocalList}
            teamMemberModal={teamMemberModal}
            groupType={groupType}
            userIsAdmin={userIsAdmin}
            projectId={projectId}
            isFirstRow={index === 0}
            isLastRow={index === (listItems.length ? listItems.length - 1 : 0)}
            listRef={listRef}
            isHoursOnly={isHoursOnly}
            permissionProps={{ projectId }}
            alwaysShowThreeDot={alwaysShowThreeDot}
          />
        ))}
        {!inBudgetModal && unassignedRoleListitems.length ? (
          <UnassignedHeader>Unassigned</UnassignedHeader>
        ) : null}
        {!inBudgetModal
          ? unassignedRoleListitems.map((unassignedRole) => {
              return (
                <UnassignedRoleLine
                  memberBudget={unassignedRole}
                  projectId={projectId}
                  key={unassignedRole.id}
                  listRef={listRef}
                />
              );
            })
          : null}
        <ReactTooltip effect="solid" id="member-line-tooltip" place="top" />
      </ul>
    </InviteMemberListContainer>
  ) : null;
};

InviteMemberList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  memberList: PropTypes.array,
  deleteMemberFromSelection: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array,
  changeRoleFromLocalList: PropTypes.func,
  teamMemberModal: PropTypes.bool
};

export default InviteMemberList;
