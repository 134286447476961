export enum TimeEntrySettingOption {
  ManualTimeEntry = 'ManualTimeEntry',
  PastPlannedTime = 'PastPlannedTime'
}

export enum BETimeEntryAutogenMode {
  OffKeepAutogeneratedTime = 'off_keep_autogenerated_time',
  OffDeleteAutogeneratedTime = 'off_delete_autogenerated_time',
  ConvertExact = 'convert_exact',
  ConvertNonMatched = 'convert_non_matched'
}
