import { put, select } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity } from './generics';
import { api } from '../service';
import { getUserSlug, getAuthToken } from 'selectors';
import {
  fetchUser as fetchUserActionCreator,
  fetchGroups,
  fetchCurrentSubscription,
  navigateToHome,
  logoutUser,
  fetchUser
} from 'actionCreators';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';

const {
  companyUpdate,
  profileUpdate,
  toggleEmailNotification,
  passwordSettingsUpdate,
  myProjectsStateUpdate
} = entityActions;

export function* putTeam(action) {
  const { payload } = action;
  const { extendTrial, startTrial } = payload;

  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    companyUpdate,
    api.updateTeam,
    [token, payload]
  );
  if (error) {
  } else if (extendTrial || startTrial) {
    yield put(fetchUserActionCreator());
    yield put(fetchCurrentSubscription(token));
  } else {
    yield put(fetchGroups(token));
  }
}

export function* updateTeamOrderedItems(action) {
  const { payload } = action;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.updateTeamOrderedItems,
    api.updateTeamOrderedItems,
    [token, payload],
    action
  );
}

export function* updateTeamViewSettingsWorker(action) {
  const { viewSettings, teamId } = action.payload;
  const token = yield select(getAuthToken);
  const params = convertKeysToSnakeCase({
    viewSettings,
    teamId
  });
  yield changeEntity(
    entityActions.updateTeamViewSettings,
    api.updateTeamOrderedItems,
    [token, params],
    action,
    action.payload
  );
}

export function* postProfile(action) {
  const { token, formData, isInvite, isSetup } = action.payload;
  const userSlug = yield select(getUserSlug);
  const { error, response } = yield changeEntity(
    profileUpdate,
    api.updateProfile,
    [token, formData]
  );

  /*
    this condition should only be true if a user was invited
    (has team already). This should not occur for a user that
    simply signs up without being invited (does not yet have team)
  */
  if (!error && isInvite && !isSetup && response.account) {
    yield put(navigateToHome({ userSlug }));
  } else if (!error) {
    // yield put(fetchTeamMembers());
  } else if (error && isInvite && !isSetup) {
    /*
      login page will automatically redirect to mosaic-login if not authenticated.
      So instead we redirect on success/fail of the postProfile saga.
    */
    yield put(logoutUser());
  }
}

export function* updateEmailNotification(action) {
  const { token, accountId, isEmailNotificationOn } = action.payload;
  const { error, response } = yield changeEntity(
    toggleEmailNotification,
    api.emailNotification,
    [token, accountId, isEmailNotificationOn]
  );
}

/**
 * Save the locked state of projects sidebar to BE
 */
export function* updateMyProjectsState(action) {
  const { isPinned } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    myProjectsStateUpdate,
    api.putMyProjectsChoice,
    [token, isPinned]
  );
}

export function* updateSettingsPassword(action) {
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    passwordSettingsUpdate,
    api.updateSettingsPassword,
    [token, action.payload.current_password, action.payload.password],
    action
  );

  if (!error) {
    yield put(fetchUser());
  }
}
