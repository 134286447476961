import { ActivityPhase } from '../models/activityPhase';
import { ActivityPhaseMembership } from '../models/activityPhaseMembership';

export const formatNextActivityPhaseMembershipOfActivityPhase = (
  activityPhase: ActivityPhase,
  updatedActivityPhaseMembership: ActivityPhaseMembership
): ActivityPhase => {
  return {
    ...activityPhase,
    activity_phase_memberships: activityPhase.activity_phase_memberships.map(
      (activityPhaseMembership) =>
        activityPhaseMembership.id === updatedActivityPhaseMembership.id
          ? {
              ...activityPhaseMembership,
              ...updatedActivityPhaseMembership
            }
          : activityPhaseMembership
    )
  };
};
