import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTasksV2 } from 'actionCreators';
import { getDependencyTaskArray } from 'selectors';
import { DependableItem } from 'components/Dependency/types';
import { DEPENDABLE_TYPES } from '../constants';

interface useFetchDependableItemsProps {
  projectId?: number;
  taskId: number;
}

const useFetchTaskDependableItems = ({
  projectId,
  taskId
}: useFetchDependableItemsProps) => {
  const dispatch = useDispatch();
  const tasks = useSelector(getDependencyTaskArray);

  const [dependableItems, setDependableItems] = useState<Array<DependableItem>>(
    []
  );

  // hard coded for now
  const showCompletedTasks = true;

  useEffect(() => {
    dispatch(
      fetchTasksV2({
        body: {
          project_ids: [projectId],
          all: true,
          archived: false
        },
        taskListType: 'dependency'
      })
    );
  }, [projectId, dispatch]);

  useEffect(() => {
    const nonArchivedTasks = tasks
      ? tasks
          .filter(
            (task) =>
              task &&
              task.id !== taskId &&
              !task.is_archived &&
              task.project_id === projectId &&
              (showCompletedTasks || !task.is_completed)
          )
          .sort((a, b) => {
            // put completed tasks at the bottom of the list if showing
            return a.is_completed && b.is_completed ? 1 : -1;
          })
          .map(
            (nonArchivedTask): DependableItem => ({
              dependableId: nonArchivedTask.id,
              dependableType: DEPENDABLE_TYPES.TASK,
              label: nonArchivedTask.description,
              startDate: nonArchivedTask.schedule_start,
              endDate: nonArchivedTask.schedule_end
            })
          )
      : [];

    setDependableItems(nonArchivedTasks);
  }, [tasks, projectId, taskId, showCompletedTasks]);

  return { dependableItems };
};

export default useFetchTaskDependableItems;
