import {
  EditProjectMemberListContainer,
  EditBoardMemberListContainer,
  EditMemberListBehaviorContainer
} from '..';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import { AddMembersFormContainer } from 'components/styles';
import cn from 'classnames';

const findMeInMemberList = (memberList, me) =>
  memberList.find((member) => member.account.id === me.id);

const AddMembersForm = ({
  modalType,
  isOpen,
  bulkAddIsOpen,
  onOutsideClick,
  closeModal,
  closeBulkAddMembersForm,
  memberList,
  roles,
  isEditForm,
  formPositionStyles = {},
  checkIfInputAllowed,
  inviteTeamMember,
  updateProjectMemberRole,
  updateBoardMemberRole,
  populateBulkAddMembers,
  emptyBulkAddMembers,
  deleteMeFromGroup,
  deleteMeFromProject,
  isOnProjectDetail,
  me,
  history,
  project,
  handleProjectItemState,
  path,
  openFormLocation,
  formLocation,
  isContained,
  showSuggestions,
  setSuggestions,
  inBudgetModal,
  noFooter,
  onClose
}) => {
  const onClick = (e) => {
    const myMembership = findMeInMemberList(memberList, me);
    if (myMembership) {
      if (modalType === MODAL_TYPE.BOARD) {
        history.push('/');
        deleteMeFromGroup(myMembership);
      } else {
        if (isOnProjectDetail) {
          handleProjectItemState(
            null,
            ['detailVisible', 'fixed', 'expanded'],
            [false, false, false]
          );
          deleteMeFromProject(myMembership.account.id);
          const boardView = path.split('/').slice(0, 6).join('/');
          history.push(boardView);
        } else {
          deleteMeFromProject(myMembership.account.id);
        }
      }
    }
  };

  return bulkAddIsOpen ? (
    <AddMembersFormContainer
      className={cn('add-members-form', {
        'is-contained': isContained,
        suggestions: showSuggestions,
        'bulk-add': !showSuggestions
      })}
      // open={bulkAddIsOpen && openFormLocation === formLocation}
      // onRequestClose={onOutsideClick}
      // style={{ ...formPositionStyles }}
      onClick={checkIfInputAllowed}
    >
      <EditMemberListBehaviorContainer>
        {modalType === MODAL_TYPE.PROJECT ? (
          <EditProjectMemberListContainer
            closeBulkAddMembersForm={closeBulkAddMembersForm}
            memberList={memberList}
            roles={roles}
            isEditForm={isEditForm}
            inviteTeamMember={inviteTeamMember}
            updateProjectMemberRole={updateProjectMemberRole}
            bulkAddIsOpen={bulkAddIsOpen}
            populateBulkAddMembers={populateBulkAddMembers}
            emptyBulkAddMembers={emptyBulkAddMembers}
            isContained={isContained}
            showSuggestions={showSuggestions}
            setSuggestions={setSuggestions}
            inBudgetModal={inBudgetModal}
            onClose={onClose}
            onCloseModal={closeModal}
          />
        ) : (
          <EditBoardMemberListContainer
            memberList={memberList}
            roles={roles}
            isEditForm={isEditForm}
            inviteTeamMember={inviteTeamMember}
            bulkAddIsOpen={bulkAddIsOpen}
            updateBoardMemberRole={updateBoardMemberRole}
            populateBulkAddMembers={populateBulkAddMembers}
            emptyBulkAddMembers={emptyBulkAddMembers}
            onCloseModal={closeModal}
          />
        )}
      </EditMemberListBehaviorContainer>
    </AddMembersFormContainer>
  ) : (
    <AddMembersFormContainer
      className={cn('add-members-form', {
        'is-contained': isContained,
        suggestions: showSuggestions,
        'bulk-add': !showSuggestions,
        'is-member': findMeInMemberList(memberList, me)
      })}
      // open={isOpen}
      // onRequestClose={onOutsideClick}
      // style={{ ...formPositionStyles }} // FlyoutMenu mutates this prop
      onClick={checkIfInputAllowed}
    >
      {/* {!isContained && (
        <header className="add-members-header">
          <span>{`Manage Members`}</span>
          <BlueSubmitButton fontSize={16} fontWeight="600" onClick={closeModal}>
            Done
          </BlueSubmitButton>
        </header>
      )} */}
      <div className="body-wrapper">
        <EditMemberListBehaviorContainer>
          {modalType === MODAL_TYPE.PROJECT ? (
            <EditProjectMemberListContainer
              memberList={memberList}
              roles={roles}
              isEditForm={isEditForm}
              inviteTeamMember={inviteTeamMember}
              updateProjectMemberRole={updateProjectMemberRole}
              inBudgetModal={inBudgetModal}
              showSuggestions={showSuggestions}
              setSuggestions={setSuggestions}
              onCloseModal={closeModal}
            />
          ) : (
            // Adding member to a portfolio/board
            <EditBoardMemberListContainer
              memberList={memberList}
              roles={roles}
              isEditForm={isEditForm}
              inviteTeamMember={inviteTeamMember}
              updateBoardMemberRole={updateBoardMemberRole}
              onCloseModal={closeModal}
            />
          )}
        </EditMemberListBehaviorContainer>
        {memberList.length && !noFooter && !showSuggestions ? (
          <footer className="add-member-footer">
            {findMeInMemberList(memberList, me) && (
              <div className="leave-board" onClick={onClick}>
                <p className="leave-text">{`Leave ${modalType}`}</p>
              </div>
            )}
          </footer>
        ) : null}
      </div>
    </AddMembersFormContainer>
  );
};

export default AddMembersForm;
