import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isValidSkillFormat } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const skillsValidator: RowValidator = ({ row, entityType }) => {
  // not required, should not start with comma
  const { enteredSkills } = getRowValuesForEntityType(row, entityType);

  if (enteredSkills === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (!enteredSkills) return true;
  else {
    const enteredSkillsSplitByComma = enteredSkills.split(',');
    if (
      enteredSkillsSplitByComma.length > 1 &&
      enteredSkillsSplitByComma[0]?.trim() === ''
    )
      return ERROR_MESSAGES.skillsStartingWithComma;
    // every skill should be in a valid format
    // skill name follow by optional skill level e.g. JavaScript, React:1
    for (const skill of enteredSkillsSplitByComma) {
      if (!isValidSkillFormat(skill.trim())) {
        return ERROR_MESSAGES.invalidSkillFormat;
      }
    }
    return true;
  }
};
