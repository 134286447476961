import {
  DATA_KEY,
  CAPACITY_KEY,
  CHART_STACK_ORDER,
  CHART_STACK_ORDER_WITH_CAPACITY,
  CHART_STACK_ORDER_PERCENT,
  CHART_STACK_ORDER_PERCENT_WITH_CAPACITY
} from './constants';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

export const getChartTooltip = (value, dataKey) => {
  const tooltipHash = {
    [DATA_KEY.NONBILLABLE]: 'hrs are not billable',
    [DATA_KEY.PTO]: 'hrs are PTO',
    [DATA_KEY.HOLIDAY]: 'hrs are Holidays',
    [DATA_KEY.BILLABLE]: 'hrs are billable',
    [CAPACITY_KEY.REMAINING_CAPACITY]: 'hrs are remaining capacity'
  };
  const formattedValue = formatNumWithMaxOneDecimal(value);
  const suffix = tooltipHash[dataKey] ?? '';

  return `${formattedValue}${suffix}`;
};

export const getTopDataField = (
  payload,
  type,
  includeRemainingCapacity = false
) => {
  const ORDER = includeRemainingCapacity
    ? CHART_STACK_ORDER_WITH_CAPACITY
    : CHART_STACK_ORDER;
  const ORDER_PERCENT = includeRemainingCapacity
    ? CHART_STACK_ORDER_PERCENT_WITH_CAPACITY
    : CHART_STACK_ORDER_PERCENT;
  return (type === 'percent' ? ORDER_PERCENT : ORDER).find(
    (dataField) => +payload[dataField] > 0
  );
};
