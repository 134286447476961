import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ThreeDot2 = ({ color = 'white', height = 4, width = 15, ...rest }) => (
  <SvgIcon
    viewBox="0 0 15 4"
    fill="none"
    height={height}
    width={width}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6252 0.125479C13.4521 0.125479 14.125 0.796969 14.125 1.62524C14.125 2.45351 13.4521 3.125 12.6252 3.125C11.7977 3.125 11.1248 2.45351 11.1248 1.62524C11.1248 0.796969 11.7977 0.125479 12.6252 0.125479ZM7.375 0.125479C8.20259 0.125479 8.87476 0.796969 8.87476 1.62524C8.87476 2.45351 8.20257 3.125 7.375 3.125C6.54743 3.125 5.87524 2.45351 5.87524 1.62524C5.87524 0.796969 6.54743 0.125479 7.375 0.125479ZM2.12476 0.125479C2.95235 0.125479 3.62522 0.796969 3.62522 1.62524C3.62522 2.45351 2.95233 3.125 2.12476 3.125C1.29788 3.125 0.624999 2.45351 0.624999 1.62524C0.624999 0.796969 1.29789 0.125479 2.12476 0.125479Z"
      fill={color}
    />
  </SvgIcon>
);

export default ThreeDot2;
