import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import { DATA_KEY } from 'UtilizationModule/constants';

const emptyObj = {};

const StyledTotalCell = styled.div`
  display: grid;
  grid-template-columns: 65px 65px;
  align-items: ${({ alignItems }) => alignItems || 'unset'};
  height: ${({ height }) => height || 'fit-content'};
`;
const Hours = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-weight: 600;
  font-size: 13px;
  text-align: right;
`;
const Percent = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
  font-size: 13px;
`;

const TotalOffsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 67px;
`;

const StyledTotalOffs = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.colors.colorLightGray15};
  white-space: nowrap;
`;

const TotalCell = ({ row }) => {
  const {
    account: { id: accountId } = emptyObj,
    projectId, // when subrow level
    hasSubLists, // indicates that it's top level row
    data: {
      totalHours,
      totalPercent,
      accountCapacityTotal,
      [DATA_KEY.PTO_FOR_DISPLAY]: ptoHours,
      [DATA_KEY.HOLIDAY_FOR_DISPLAY]: offHours
    }
  } = row.original;

  const value = `${formatNumWithMaxOneDecimal(totalHours)}h`;
  const percent = `${formatNumWithMaxOneDecimal(totalPercent)}%`;

  return (
    <>
      <StyledTotalCell
        data-for="app-tooltip"
        data-class="center"
        data-effect="solid"
        data-html
        data-tip={
          accountCapacityTotal > 0
            ? `${percent} of ${formatNumWithMaxOneDecimal(
                accountCapacityTotal,
                true
              )}h <br/> Work Capacity`
            : `${percent} of Total Hours`
        }
      >
        <Hours data-testid={`${accountId || projectId}-total-hours`}>
          {value}&nbsp;
        </Hours>
        <Percent data-testid={`${accountId || projectId}-total-percent`}>
          | {percent}
        </Percent>
      </StyledTotalCell>
      {hasSubLists && (
        <TotalOffsContainer>
          {/* {ptoHours > 0 && (
            <StyledTotalOffs
              data-testid={`${accountId || projectId}-total-pto-hours`}
            >
              PTO {ptoHours}h
            </StyledTotalOffs>
          )}
          {offHours > 0 && (
            <StyledTotalOffs
              data-testid={`${accountId || projectId}-total-holiday-hours`}
            >
              OFF {offHours}h
            </StyledTotalOffs>
          )} */}
        </TotalOffsContainer>
      )}
    </>
  );
};
export default TotalCell;
