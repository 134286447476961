import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';

export const MilestoneModalContext = React.createContext(null);

const MilestoneModalContextProvider = ({ children }) => {
  const [milestoneCreateRowState, setState] = useState({
    showAddMenu: false,
    addingMilestone: false,
    addingPhase: false
  });

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [milestoneCreateRowState]);

  const setMilestoneCreateRowState = useCallback(
    (newState) => {
      setState({
        ...milestoneCreateRowState,
        ...newState
      });
    },
    [milestoneCreateRowState]
  );

  const setShowAddMenu = useCallback(() => {
    setMilestoneCreateRowState({
      showAddMenu: true
    });
  }, [setMilestoneCreateRowState]);

  const clearShowAddMenu = useCallback(() => {
    setMilestoneCreateRowState({
      showAddMenu: false
    });
  }, [setMilestoneCreateRowState]);

  const handleAddingMilestone = useCallback(() => {
    setMilestoneCreateRowState({
      showAddMenu: false,
      addingMilestone: true
    });
  }, [setMilestoneCreateRowState]);

  const setAddingPhase = useCallback(() => {
    setMilestoneCreateRowState({
      showAddMenu: false,
      addingPhase: true
    });
  }, [setMilestoneCreateRowState]);

  const providerValue = useMemo(
    () => ({
      ...milestoneCreateRowState,
      setMilestoneCreateRowState,
      clearShowAddMenu,
      setShowAddMenu,
      setAddingPhase,
      handleAddingMilestone
    }),
    [
      milestoneCreateRowState,
      setMilestoneCreateRowState,
      setShowAddMenu,
      clearShowAddMenu,
      handleAddingMilestone,
      setAddingPhase
    ]
  );

  return (
    <MilestoneModalContext.Provider value={providerValue}>
      {children}
    </MilestoneModalContext.Provider>
  );
};

export default MilestoneModalContextProvider;
