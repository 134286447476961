import moment from 'moment';
import { serializeBar, zoomToIntervalHash } from 'appUtils/projectPlannerUtils';
import {
  ZOOM_FIT_ITEM_TO_SCALE,
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY,
  ZOOM_LEVELS,
  CONDENSED_ZOOM_LEVELS
} from 'appConstants/workload';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import { isPhaseInactive, isPhaseArchived } from 'appUtils/phaseDisplayUtils';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { DEFAULT_DATE_FORMAT } from 'appUtils/dateRangeHelpers';

const emptyObj = {};

export const fitToScaleStart = (zoom, date, format) =>
  moment(date, format).startOf(ZOOM_FIT_ITEM_TO_SCALE[zoom]);

export const fitToScaleEnd = (zoom, date, format) =>
  moment(date, format).endOf(ZOOM_FIT_ITEM_TO_SCALE[zoom]);

const getPlannerStepsForAllBarsRange = (stepValue, scheduleBars) => {
  if (!stepValue) {
    return [];
  }
  const startDate = moment.min(
    map(scheduleBars, (bar) =>
      moment(bar.start_date, DEFAULT_DATE_FORMAT).startOf(stepValue)
    )
  );
  const endDate = moment.max(
    map(scheduleBars, (bar) =>
      moment(bar.end_date, DEFAULT_DATE_FORMAT).endOf(stepValue)
    )
  );
  const adjustedStartDate = startDate.startOf(stepValue);
  const adjustedEndDate = endDate.endOf(stepValue);
  const plannerSteps = Array.from(
    moment.range(adjustedStartDate, adjustedEndDate).by(stepValue)
  ).map((date) => date.valueOf());
  return plannerSteps;
};

const getOOORangesByAccount = (
  scheduleBarsObj,
  zoom,
  OOOProject,
  projectPlannerSteps
) => {
  // Returns PTO ranges that needed to be accounted for, by account id
  const stepValue = zoomToIntervalHash[zoom];
  const OOObars =
    !!OOOProject && zoom !== ZOOM_LEVELS.WEEK && zoom !== ZOOM_LEVELS.DAY
      ? pickBy(scheduleBarsObj, (bar) => {
          if (bar.project_id === OOOProject.id) {
            const startDate = moment(bar.start_date, 'MM/DD/YYYY');
            const endDate = moment(bar.end_date, 'MM/DD/YYYY');
            const diff = endDate.diff(startDate, 'days') + 1;
            const difftarget = zoom === ZOOM_LEVELS.YEAR ? 28 : 7; // exclude small PTO ranges if we are looking at the year
            return diff >= difftarget;
          }
          return false;
        })
      : {};
  const OOObarsByAccount = groupBy(OOObars, 'account_id');
  const OOOdatesByAccount = mapValues(OOObarsByAccount, (account) =>
    account.map((bar) =>
      moment.range(
        moment(bar.start_date, 'MM/DD/YYYY'),
        moment(bar.end_date, 'MM/DD/YYYY').add(1, 'd')
      )
    )
  );
  const OOOrangesByAccount = mapValues(OOOdatesByAccount, (account) =>
    projectPlannerSteps
      ? projectPlannerSteps.reduce((ranges, step) => {
          const stepRange = moment.range(
            moment(step),
            moment(step).endOf(stepValue)
          );
          if (account.some((range) => range.contains(stepRange))) {
            ranges.push(stepRange);
          }
          return ranges;
        }, [])
      : []
  );
  return OOOrangesByAccount;
};

const handleGetNonOOObars = (OOObars, startDate, endDate) => {
  const bars = [];
  const barStart = moment(startDate, 'MM/DD/YYYY').startOf('day');
  const barEnd = moment(endDate, 'MM/DD/YYYY').startOf('day');
  let currStart = OOObars.some((range) => range.contains(barStart))
    ? null
    : barStart;

  // go through each schedule bar and split into bars based on OOOranges
  for (let m = moment(barStart); m.isSameOrBefore(barEnd); m.add(1, 'days')) {
    const curr = m.clone();
    if (OOObars.some((range) => range.contains(curr))) {
      if (currStart) {
        bars.push({
          start_date: currStart.format('MM/DD/YYYY'),
          end_date: curr.clone().subtract(1, 'days').format('MM/DD/YYYY'),
          day_count: curr.diff(currStart, 'days')
        });
        currStart = null;
      }
    } else {
      if (!currStart) {
        currStart = curr;
      }
    }
  }

  // add in last bar if we did not complete it
  if (currStart) {
    bars.push({
      start_date: currStart.format('MM/DD/YYYY'),
      end_date: barEnd.format('MM/DD/YYYY'),
      day_count: barEnd.diff(currStart, 'days') + 1
    });
  }

  return bars;
};

const handleGetFullBar = (bar) => {
  return [
    {
      start_date: bar.start_date,
      end_date: bar.end_date,
      day_count: sumBy(bar.bars, (bar) => bar.day_count)
    }
  ];
};

const addSplitStubsToBars = (bars) => {
  return bars.reduce((acc, bar, index) => {
    acc.push(bar);
    if (index === bars.length - 1) {
      return acc;
    }
    const stubBarDate = moment(bar.end_date)
      .add(1, 'days')
      .format('YYYY-MM-DD');
    acc.push({
      start_date: stubBarDate,
      end_date: stubBarDate,
      day_count: 0
    });
    return acc;
  }, []);
};

export const formatPlannerScheduleBars = (
  plannerMembers,
  scheduleBarsObj,
  projects,
  phasesHash,
  activities,
  zoom,
  boardsHash,
  OOOProject,
  projectPlannerStepValue,
  splitId,
  contextMenuId,
  includeUnassigned = true,
  condensedZoomLevel,
  includeSplitStub = true
) => {
  // Manually create projectPlannerSteps to prevent scrolling bug on workload planner
  const plannerStepsForAllBarsRange = getPlannerStepsForAllBarsRange(
    projectPlannerStepValue,
    scheduleBarsObj
  );
  const OOORangesByAccount = getOOORangesByAccount(
    scheduleBarsObj,
    zoom,
    OOOProject,
    plannerStepsForAllBarsRange
  );

  const scheduleBars = Object.values(scheduleBarsObj).flatMap((scheduleBar) => {
    if (
      !plannerMembers[scheduleBar.account_id] &&
      (!includeUnassigned || scheduleBar.account_id)
    ) {
      return [];
    }

    const project = projects[scheduleBar.project_id] || emptyObj;
    const phase = phasesHash[scheduleBar.phase_id] || emptyObj;
    const activity = activities[scheduleBar.activity_id] || emptyObj;
    const board = boardsHash[project?.board_id] || emptyObj;
    const currOOObars = OOORangesByAccount[scheduleBar.account_id];
    // If theres no OOO bars for this account then just render regular bars
    const serializedId = serializeBar({
      itemId: scheduleBar.id,
      itemType: 'scheduleBar'
    });
    const bars = currOOObars
      ? handleGetNonOOObars(
          currOOObars,
          scheduleBar.start_date,
          scheduleBar.end_date
        )
      : // bars will be rendered accurately on day view or week view,
      // otherwise bars will rendered as full bar without gaps on non-workday
      zoom === ZOOM_LEVELS.WEEK || zoom === ZOOM_LEVELS.DAY
      ? scheduleBar.bars
      : handleGetFullBar(scheduleBar);
    const barsWithSplitStubs = includeSplitStub
      ? addSplitStubsToBars(bars)
      : bars;
    return [
      {
        ...scheduleBar,
        bars: barsWithSplitStubs,
        id: serializedId,
        initial_start_date: fitToScaleStart(
          zoom,
          scheduleBar.start_date,
          'MM/DD/YYYY'
        ),
        start_date_label: moment(scheduleBar.start_date, 'MM/DD/YYYY'),
        end_date_label: moment(scheduleBar.end_date, 'MM/DD/YYYY'),
        start_date: fitToScaleStart(zoom, scheduleBar.start_date, 'MM/DD/YYYY'),
        end_date: fitToScaleEnd(zoom, scheduleBar.end_date, 'MM/DD/YYYY'),
        render_end_date: fitToScaleEnd(
          zoom,
          scheduleBar.end_date,
          'MM/DD/YYYY'
        ),
        project_name: project.title,
        project_description: project.description,
        project_slug: project.slug,
        project_number: project.project_number,
        phase_name: phase.name,
        is_default_phase: phase.is_default_phase,
        phase_is_inactive: isPhaseInactive(phase),
        is_like_default: phase.is_like_default,
        phase_is_archived: isPhaseArchived(phase),
        member: plannerMembers[scheduleBar.account_id],
        activity_title: activity?.title,
        is_default_activity: activity?.is_default,
        board_name: board?.name,
        // should match with project_view_group_key on planner selector
        item_project_view_group_key: scheduleBar.member_budget_id,
        item_project_view_split_screen_key: `account--${scheduleBar.account_id}--split`,
        splitId,
        contextMenuId
      }
    ];
  });
  return scheduleBars;
};

export const filterBarsByDateRange = (start, end) => (bars) => {
  const rangeToInclude = moment.range(start, end);
  const barsToUse = bars.filter((bar) =>
    moment.range(bar.start_date, bar.end_date).overlaps(rangeToInclude)
  );

  return barsToUse;
};
export const accumulateBarDaysByDateRange =
  (start, end) =>
  (bars, daily_hours = 0, total = 0, end_date) => {
    const rangeToInclude = moment.range(start, end);
    const ranges = bars
      // addSplitStubsToBars adds 1 stub date with day_count 0 which will result in 8 additional commitment hours
      .filter((bar) => bar.day_count > 0)
      .map((bar) => {
        if (
          bar.start_date &&
          bar.end_date &&
          bar.start_date === bar.end_date &&
          rangeToInclude.contains(moment(bar.start_date))
        ) {
          // intersect returns null for this specific case
          return moment.range(bar.start_date, bar.end_date);
        } else {
          return moment
            .range(bar.start_date, bar.end_date)
            .intersect(rangeToInclude);
        }
      })
      .filter((bar) => bar);
    const lastRange = ranges[ranges.length - 1];
    const remainder = ((total * 100) % (daily_hours * 100)) / 100;
    const hasRemainder =
      end_date && moment(end_date).isSame(lastRange?.end, 'day') && remainder;

    const rangesWithCommitment = ranges.map((range) => ({
      range,
      committment: (range.diff('days') + 1) * daily_hours,
      daily_hours
    }));

    if (hasRemainder && lastRange) {
      const rangeWithoutRemainder = moment.range(
        lastRange.start,
        lastRange.end.clone().add(-1, 'days')
      );
      const formattedRangeWithoutRemainder = {
        range: rangeWithoutRemainder,
        committment: (rangeWithoutRemainder.diff('days') + 1) * daily_hours,
        daily_hours
      };
      const remainderRange = {
        range: moment.range(lastRange.end, lastRange.end),
        committment: remainder,
        daily_hours: remainder
      };
      rangesWithCommitment[rangesWithCommitment.length - 1] =
        formattedRangeWithoutRemainder;
      rangesWithCommitment.push(remainderRange);
    }

    const committment = sumBy(
      rangesWithCommitment,
      (range) => range.committment
    );

    return { committment, ranges: rangesWithCommitment };
  };

export const accumulateWeekCommitment = (start, end) => (bars) =>
  bars?.map((bar) => ({
    ...bar,
    ...accumulateBarDaysByDateRange(start, end)(
      bar.bars,
      bar.daily_hours,
      bar.total_hours,
      bar.end_date
    )
  }));

export const calcWeekCapacity = (capacity) => {
  if (!capacity) {
    return DEFAULT_WEEKLY_CAPACITY;
  }
  const weekCapacity = CAPACITY_DATE_KEYS.map((key) => capacity[key]).reduce(
    (a, b) => a + b
  );
  return weekCapacity || DEFAULT_WEEKLY_CAPACITY;
};

export const getDayOfWeekFromDateString = (date) =>
  moment(date).clone().startOf('day').format('dd');

export const getHrsPerDateRange = (ranges) => {
  const hrsPerDateRange = [];
  ranges.forEach((range) => {
    if (range && range.range && range.range.start && range.range.end) {
      const startDate = range.range.start;
      const endDate = range.range.end;
      const rangeString =
        getDayOfWeekFromDateString(startDate) +
        (!startDate.isSame(endDate, 'day')
          ? ` - ${getDayOfWeekFromDateString(endDate)}`
          : '');
      hrsPerDateRange.push([rangeString, +range.daily_hours]);
    }
  });

  return hrsPerDateRange;
};

export const getCommitmentTooltipContent = (hrsPerDateRange) =>
  `
    <div class="commitment-tooltip">
      ${hrsPerDateRange.reduce(
        (acc, curr) =>
          `${acc}<div class="hrs-date"><span class="right-margin">${
            curr[0]
          }</span>${' '}<span>${curr[1]}h/day</span></div>`,
        ''
      )}
    </div>
  `;
export const getDisplayDataRange = (displayData) =>
  displayData.date_range?.split('-')[0];

export const renderPercentText = (item, workdayPercent) => {
  const percentCapacity = item.all_day ? 100 : workdayPercent;

  return `${percentCapacity.toFixed(0)} %`;
};

export const renderHoursPerDayText = (item, averageCapacity) => {
  const hoursCapacity = item.all_day ? averageCapacity : item.daily_hours;

  return `${formatNumWithMaxTwoDecimals(hoursCapacity || 0)} h/d`;
};
