import isFunction from 'lodash/isFunction';
const checkCondition = (condition) => {
  if (condition === true) {
    return true;
  } else if (isFunction(condition)) {
    return condition();
  } else if (Array.isArray(condition)) {
    return condition.every(checkCondition);
  }
};

export default checkCondition;
