import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavigationTracker from './NavigationTracker';
import { Sections, Views, Modals } from 'appConstants/navigation';

class TimesheetNavigationTracker extends React.PureComponent {
  render() {
    return (
      <NavigationTracker section={Sections.MEMBERS} view={Views.TIMESHEET} />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimesheetNavigationTracker)
);
