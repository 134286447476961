import { useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { fetchWorkGroups } from 'actionCreators';
import { getIsWorkGroupsLoaded, getIsLoadingWorkGroups } from 'selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';

const emptyObj = {};

export const useLoadDepartments = ({
  isOff,
  shouldAutoLoad = true
}: {
  isOff?: boolean;
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  const isLoaded = useAppSelector(getIsWorkGroupsLoaded) as boolean;
  const isLoading = useAppSelector(getIsLoadingWorkGroups) as boolean;

  const loadDepartments = useCallback(() => {
    if (!isLoaded && !isLoading && teamId) {
      dispatch(fetchWorkGroups({ teamId, permissions: { teamId } }));
    }
  }, [dispatch, isLoaded, teamId, isLoading]);

  // load departments if necessary
  useEffect(() => {
    if (!isOff && shouldAutoLoad) {
      loadDepartments();
    }
  }, [loadDepartments, shouldAutoLoad, isOff]);

  return {
    isLoaded,
    isLoading,
    loadDepartments
  };
};
