import React from 'react';
import SvgIcon from 'components/SvgIcon';

const InsertIcon = ({
  className,
  width = '18',
  height = '18',
  stroke = '#4F4F4F'
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.5 12.9999L11.0769 9.66661L8.65385 6.33327L4.5 12.9999"
        stroke={stroke}
        strokeWidth="1.3"
      />
      <line x1="15" y1="5.5" x2="3" y2="5.5" stroke={stroke} />
    </SvgIcon>
  );
};

export default InsertIcon;
