import React from 'react';
import SvgIcon from '../../components/SvgIcon';

const RichItalicIcon = () => (
  <SvgIcon width="9" height="10" viewBox="0 0 9 10">
    <path
      fill="gray"
      fillRule="nonzero"
      d="M9 0v.714H7.5L3.75 9.286h1.5V10H0v-.714h1.5L5.25.714h-1.5V0z"
    />
  </SvgIcon>
);

export default RichItalicIcon;
