import { ComponentProps, ComponentType } from 'react';
import { useTeamRates } from './useTeamRates';

interface WithTeamRatesProps {
  teamRates: ReturnType<typeof useTeamRates>;
}

export const withTeamRates = <P extends WithTeamRatesProps>(
  WrappedComponent: ComponentType<P>
) => {
  const Hoc = (
    props: Omit<
      ComponentProps<typeof WrappedComponent>,
      keyof WithTeamRatesProps
    >
  ) => {
    const teamRates = useTeamRates();

    return (
      <WrappedComponent {...(props as unknown as P)} teamRates={teamRates} />
    );
  };

  Hoc.displayName = 'withTeamRates';

  return Hoc;
};
