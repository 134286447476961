import type {
  LeanApiSchemaVariant,
  LeanApiSchemaBuilder,
  FetchLeanApiPayload
} from 'LeanApiModule/types';
import {
  membersByDepartmentSchemaBuilder,
  membersByPositionSchemaBuilder,
  membersBySkillSchemaBuilder,
  membersByOfficeSchemaBuilder,
  membersByRegionSchemaBuilder,
  membersByDisciplineSchemaBuilder,
  membersByPortfolioSchemaBuilder,
  allMembersSchemaBuilder
} from './teamMembership/schemas';
import { projectsByPortfolioSchemaBuilder } from './project/schemas';
import { FilterField } from 'FilterModule/constants';

export const leanApiSchemaVariant = {
  membersByDepartment: 'membersByDepartment',
  membersByPosition: 'membersByPosition',
  membersBySkill: 'membersBySkill',
  membersByOffice: 'membersByOffice',
  membersByRegion: 'membersByRegion',
  membersByDiscipline: 'membersByDiscipline',
  membersByPortfolio: 'membersByPortfolio',
  allMembers: 'allMembers',

  projectsByPortfolio: 'projectsByPortfolio'
} as const;

export const leanApiSchemaVariantBuilderHash: Record<
  LeanApiSchemaVariant,
  LeanApiSchemaBuilder
> = {
  [leanApiSchemaVariant.membersByDepartment]: membersByDepartmentSchemaBuilder,
  [leanApiSchemaVariant.membersByPosition]: membersByPositionSchemaBuilder,
  [leanApiSchemaVariant.membersBySkill]: membersBySkillSchemaBuilder,
  [leanApiSchemaVariant.membersByOffice]: membersByOfficeSchemaBuilder,
  [leanApiSchemaVariant.membersByRegion]: membersByRegionSchemaBuilder,
  [leanApiSchemaVariant.membersByDiscipline]: membersByDisciplineSchemaBuilder,
  [leanApiSchemaVariant.membersByPortfolio]: membersByPortfolioSchemaBuilder,
  [leanApiSchemaVariant.allMembers]: allMembersSchemaBuilder,

  [leanApiSchemaVariant.projectsByPortfolio]: projectsByPortfolioSchemaBuilder
};

export const getFilterParamValues = ({
  filterParams
}: {
  filterParams: NonNullable<FetchLeanApiPayload['filterParams']>;
}) => {
  type NumberArray = number[] | undefined;
  type StringArray = string[] | undefined;

  const positionIds = (filterParams[FilterField.position_ids_cf] ||
    filterParams[FilterField.position_ids]) as NumberArray;

  const skillIds = (filterParams[FilterField.skill_ids_cf] ||
    filterParams[FilterField.skill_ids]) as NumberArray;

  const skillLevels = (filterParams[FilterField.skillLevels_cf] ||
    filterParams[FilterField.skillLevels]) as StringArray;

  const regionIds = (filterParams[FilterField.region_ids_cf] ||
    filterParams[FilterField.region_ids]) as NumberArray;

  const departmentIds = (filterParams[FilterField.work_group_ids_cf] ||
    filterParams[FilterField.work_group_ids]) as NumberArray;

  const officeIds = (filterParams[FilterField.office_ids_cf] ||
    filterParams[FilterField.office_ids]) as NumberArray;

  const boardIds = (filterParams[FilterField.board_ids_cf] ||
    filterParams[FilterField.board_ids]) as NumberArray;

  const disciplineIds = (filterParams[FilterField.discipline_ids_cf] ||
    filterParams[FilterField.discipline_ids]) as NumberArray;

  const employmentTypes = (filterParams[FilterField.employmentType_cf] ||
    filterParams[FilterField.employmentType]) as StringArray;

  const isProjectsWithUnassignedRoles = (filterParams[
    FilterField.isProjectsWithUnassignedRoles_cf
  ] || filterParams[FilterField.isProjectsWithUnassignedRoles]) as boolean;

  return {
    positionIds,
    skillIds,
    skillLevels,
    regionIds,
    departmentIds,
    officeIds,
    boardIds,
    disciplineIds,
    employmentTypes,
    isProjectsWithUnassignedRoles
  };
};
