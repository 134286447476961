import KaratRight from 'icons/KaratRight';
import { StyledKaratContainer } from '../shared/style';
import { ActivityPhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const CollapseCell = ({ row }: ActivityPhaseGroupRowProps) => {
  const { isOpen, toggleCollapse } = row.original;

  return (
    <StyledKaratContainer
      className="activity-phase"
      isOpen={isOpen}
      onClick={toggleCollapse}
    >
      <KaratRight />
    </StyledKaratContainer>
  );
};

export default CollapseCell;
