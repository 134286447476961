import { createSelector, Selector } from '@reduxjs/toolkit';
import { PositionsState, initialState } from 'BudgetModule/reducers/positions';
import { getSortedTeamMembers } from 'selectors';
import keyBy from 'lodash/keyBy';
import { RootState } from 'reduxInfra/shared';
import { makeGetFilteredPositionIds } from '.';
import { AccountPosition } from 'models/position';

const emptyArray = [];

const getPositionsState: Selector<RootState, PositionsState> = (state) =>
  state.positions || initialState;

export const getIsPositionsLoaded = createSelector(
  getPositionsState,
  (state) => state.isPositionsLoaded
);

export const getPositions = createSelector(
  getPositionsState,
  (state) => state.positions
);
export const getIsFetchingPositions = createSelector(
  getPositionsState,
  (state) => state.isFetchingPositions
);

/**
 * returns array of position IDs
 */
export const getAllPositions = createSelector(
  getPositionsState,
  (state) => state.positionIds
);

export const getOrderedPositions = createSelector(
  getAllPositions,
  getPositions,
  (positionIds, positionHash) =>
    positionIds.flatMap((id) => positionHash[id] ?? [])
);

/**
 * Returns list of active positions on the team, excluding default position.
 */
export const getCurrentPositions = createSelector(
  getOrderedPositions,
  (positions) =>
    positions.filter(
      (position) => position && !position.discarded_at && !position.is_default
    )
);

export const getCurrentPositionIds = createSelector(
  getCurrentPositions,
  (positions) => positions.map((position) => position.id)
);

export const getAlphabeticalCurrentPositionIds = createSelector(
  getPositions,
  getAllPositions,
  (positionHash, positionIds) => {
    return positionIds
      .filter((positionId) => {
        const position = positionHash[positionId];
        return position && !position.discarded_at && !position.is_default;
      })
      .sort((a, b) => {
        const aName = positionHash[a]?.name ?? '';
        const bName = positionHash[b]?.name ?? '';
        return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
      });
  }
);

export const getArchivedPositions = createSelector(
  getOrderedPositions,
  (positions) =>
    positions.filter((position) => position && position.discarded_at)
);

export const getPositionsNameHash = createSelector(
  getOrderedPositions,
  (positions) => keyBy(positions, (position) => position.name)
);

export const getTeamPositionsHash = createSelector(
  getPositionsState,
  (state) => state.teamPositionsHash
);

export const getIsTeamPositionsLoaded = createSelector(
  getPositionsState,
  (state) => state.isTeamPositionsLoaded
);

export const getIsFetchingTeamPositions = createSelector(
  getPositionsState,
  (state) => state.isFetchingTeamPositions
);

export const getAccountPositionsState = createSelector(
  getPositionsState,
  (state) => state.accountPositionsHash
);

// Members' team positions/roles
export const getAccountToTeamPositionId = createSelector(
  getPositionsState,
  (state) => state.accountToTeamPositionId
);

export const getAccountToTeamPosition = createSelector(
  getTeamPositionsHash,
  getAccountToTeamPositionId,
  (teamPositionHash, accountToTeamPositionId) =>
    Object.keys(accountToTeamPositionId).reduce((acc, accountId) => {
      const teamPositionId = accountToTeamPositionId[accountId];
      acc[accountId] = teamPositionHash[teamPositionId];
      return acc;
    }, {})
);

const getCurrentAccountPositions = createSelector(
  getAccountPositionsState,
  (accountPositions) =>
    Object.entries(accountPositions).reduce<Record<number, AccountPosition>>(
      (acc, [id, accountPosition]) => {
        if (!accountPosition.discarded_at) {
          acc[id] = accountPosition;
        }

        return acc;
      },
      {}
    )
);

export const getAccountPositionsByPositions = createSelector(
  getCurrentAccountPositions,
  (accountPositions) =>
    Object.values(accountPositions).reduce<Record<number, AccountPosition[]>>(
      (acc, accountPosition) => {
        const { position_id } = accountPosition;

        if (!acc[position_id]) {
          acc[position_id] = [];
        }

        acc[position_id]?.push(accountPosition);

        return acc;
      },
      {}
    )
);

export const getFormattedPositionsWithMembers = createSelector(
  getSortedTeamMembers,
  getCurrentPositions,
  getAccountPositionsByPositions,
  (members, currentPositionsArray, accountPositionsByPositionsHash) => {
    const positionsWithHashMemberships = currentPositionsArray.map(
      (position) => {
        return {
          ...position,
          accountPositionsHash: keyBy(
            accountPositionsByPositionsHash[position.id] ?? [],
            (positionMember) => positionMember.account_id
          )
        };
      }
    );

    const formattedPositions = positionsWithHashMemberships.map((position) => {
      const formattedPositionMembersInfo = members.flatMap((member) => {
        const accountPosition =
          position.accountPositionsHash[member.account.id];
        if (!accountPosition) return [];

        return [{ member, accountPosition }];
      });

      return {
        ...position,
        formattedPositionMembersInfo
      };
    });

    return formattedPositions;
  }
);

export const getAccountPositionsLoaded = createSelector(
  getPositionsState,
  (state) => state.isAccountPositionsLoaded
);

export const getTeamPositionOrderByAccount = createSelector(
  getPositionsState,
  (state) => state?.teamPositionOrderByAccount
);

/**
 * Get ordered team position history for given accountId
 * */
export const makeGetOwnTeamPositionHistory = () =>
  createSelector(
    getTeamPositionsHash,
    getTeamPositionOrderByAccount,
    (state, ownProps) => ownProps?.accountId,
    (teamPositionHash, teamPositionOrderByAccount, accountId) =>
      (teamPositionOrderByAccount[accountId] || emptyArray).map(
        (id) => teamPositionHash[id]
      )
  );

export const getMemberPositionsByMemberBudget = createSelector(
  getPositionsState,
  (state) => state.memberPositionsByMemberBudget
);

export const getPositionRatesHash = createSelector(
  getPositionsState,
  (state) => state.positionRatesHash
);

export const getCurrentPositionRateIds = createSelector(
  getPositionsState,
  (state) => state.currentPositionRates
);

export const getCurrentPositionCostRateIds = createSelector(
  getPositionsState,
  (state) => state.currentPositionCostRates
);

export const getPositionToCurrentPositionRate = createSelector(
  getPositionRatesHash,
  getCurrentPositionRateIds,
  (positionRatesHash, currentPositionRateIds) =>
    Object.keys(currentPositionRateIds).reduce((acc, positionId) => {
      const positionRateId = currentPositionRateIds[positionId];
      acc[positionId] = positionRatesHash[positionRateId];
      return acc;
    }, {})
);

export const getPositionToCurrentPositionCostRate = createSelector(
  getPositionRatesHash,
  getCurrentPositionCostRateIds,
  (positionRatesHash, currentPositionCostRateIds) =>
    Object.keys(currentPositionCostRateIds).reduce((acc, positionId) => {
      const positionRateId = currentPositionCostRateIds[positionId];
      acc[positionId] = positionRatesHash[positionRateId];
      return acc;
    }, {})
);

export const getPositionRateOrderByPosition = createSelector(
  getPositionsState,
  (state) => state.positionRateOrders
);

export const getPositionCostRateOrderByPosition = createSelector(
  getPositionsState,
  (state) => state.positionCostRateOrders
);

/**
 * Returns selector for getting ordered position rate history for given positionId
 * */
export const makeGetOwnPositionRateHistory = () =>
  createSelector(
    getPositionRatesHash,
    getPositionRateOrderByPosition,
    (_, ownProps) => ownProps?.positionId,
    (positionRatesHash, positionRateOrderByPosition, positionId) =>
      (positionRateOrderByPosition[positionId] || emptyArray).map(
        (id) => positionRatesHash[id]
      )
  );

/**
 * Returns selector for getting ordered position cost rate history for given positionId
 * */
export const makeGetOwnPositionCostRateHistory = () =>
  createSelector(
    getPositionRatesHash,
    getPositionCostRateOrderByPosition,
    (_, ownProps) => ownProps?.positionId,
    (positionRatesHash, positionCostRateOrderByPosition, positionId) =>
      (positionCostRateOrderByPosition[positionId] || emptyArray).map(
        (id) => positionRatesHash[id]
      )
  );

export const getIsFetchingPositionRates = createSelector(
  getPositionsState,
  (state) => state.isFetchingPositionRates
);

export const getIsFetchingPositionCostRates = createSelector(
  getPositionsState,
  (state) => state.isFetchingPositionCostRates
);

// Selected at the top, rest below. does not include archived or default position
export const makeGetOrderedFilterPositions = () =>
  createSelector(
    makeGetFilteredPositionIds(),
    getPositions,
    getCurrentPositions,
    (_, ownProps) => ownProps?.includeArchived,
    (_, ownProps) => ownProps?.includeDefault,
    (
      filteredPositionIds,
      positionHash,
      currentPositions // excludes archived and default
    ) => {
      const selectedFilterPositions = filteredPositionIds
        .map((id) => positionHash[id])
        .filter((position) => !!position); // filter may be stale and have positions that no longer exist, so remove those
      const orderedFilterPositions = Array.from(
        new Set([...selectedFilterPositions, ...currentPositions])
      );

      // @ts-expect-error trys to add a property to an array
      orderedFilterPositions.isAllSelected =
        selectedFilterPositions.length === currentPositions.length;
      return orderedFilterPositions;
    }
  );
