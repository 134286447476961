import { useRef, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenuTrigger';
import Popover from 'components/Popover';
import TrashBatchIcon from 'icons/TrashBatchIcon';
import HomeCompletedIcon from 'icons/CompletedIcon';
import TimerIcon from 'icons/TimerIcon';
import {
  openTimerDeleteModal,
  navigateToTaskModal,
  associateTask,
  fetchCommentsAndMetadata,
  fetchActiveTimer,
  fetchTimers
} from 'actionCreators';
import { getActiveTimer, getTimerTaskHash, getCurrentUserId } from 'selectors';
import { getSelectedTeamMaxTimerDuration } from 'TimersModule/selectors';

import FilledArrow from 'icons/FilledArrow';
import TimerTaskModal from './TimerTaskModal';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { ReactComponent as StopButton } from 'icons/stoptimer.svg';
import { ReactComponent as PlayButton } from 'icons/playtimer.svg';
import CompletedIcon from 'icons/CompletedIcon';

const TimerTask = ({ timer, restartCounter, counter, handleTimerToggle }) => {
  const threeDotTarget = useRef(null);
  const taskModalTarget = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const activeTimer = useSelector(getActiveTimer);
  const dispatch = useDispatch();
  const timerTaskHash = useSelector(getTimerTaskHash);
  const maxTimerDuration = useSelector(getSelectedTeamMaxTimerDuration);
  const [showTask, setShowTask] = useState(true);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const myId = useSelector(getCurrentUserId);

  const isActiveTimer = activeTimer && timer.timers[activeTimer.id];

  const debouncedHandleMaxTimer = useRef(
    // This may or may not stop the active timer, depending on if the stop operation has run on the BE
    // In testing it takes an average of about 6 seconds so the fetch occurs after 10s to be safe
    // the counter is restarted to account for a potential fetch where the timer is still going (the be duration will be updated so we need counter at 0)
    debounce(() => {
      dispatch(
        fetchActiveTimer({
          body: {
            account_id: myId
          }
        })
      );
      dispatch(
        fetchTimers({
          body: {
            start_date: moment(timer.date).format('YYYY-MM-DD'),
            end_date: moment(timer.date).format('YYYY-MM-DD'),
            account_id: myId
          }
        })
      );
      restartCounter();
    }, 10000)
  ).current;

  const hasTask = !!timer.task_id;

  const timerDuration = useMemo(
    () => timer.duration + (isActiveTimer ? counter : 0),
    [timer.duration, isActiveTimer, counter]
  );

  const isMaxTime = useMemo(
    () => timerDuration >= maxTimerDuration,
    [timerDuration, maxTimerDuration]
  );

  useEffect(() => {
    if (isActiveTimer && isMaxTime) {
      debouncedHandleMaxTimer();
    }
  }, [isActiveTimer, isMaxTime, debouncedHandleMaxTimer]);

  const formattedHours = Math.floor(timerDuration / 3600);
  const minutes = Math.floor((timerDuration % 3600) / 60);

  const timeLabel = `${Math.floor(formattedHours)}h ${minutes}m${
    isMaxTime ? ' (Max)' : ''
  }`;

  const handleMenuOpen = () => setIsMenuOpen(true);

  const handleMenuClose = () => setIsMenuOpen(false);

  const handleOpenTaskDetail = () => {
    dispatch(navigateToTaskModal({ taskId: timer.task_id }));
    const params = {
      taskId: timer.task_id,
      taskType: 'projects',
      offset: 0,
      limit: 4
    };
    dispatch(fetchCommentsAndMetadata(params));
  };

  const timerColor = isActiveTimer
    ? theme.colors.colorQbTooltipGreen
    : theme.colors.colorLightGray15;

  // const handleKeyPress = e => {
  //   if (e.key === 'Enter') {
  //     const timers = Object.keys(timer.timers);
  //     const timerToUpdate = timers[timers.length - 1];
  //     const newDuration = newHours * 3600 - timer.duration + timer.lastDuration;
  //     dispatch(
  //       updateTimer({
  //         account_id: timer.account_id,
  //         timer_id: timerToUpdate,
  //         user_custom_duration: newDuration
  //       })
  //     );
  //     setIsEditing(false);
  //     e.target.blur();
  //     e.stopPropagation();
  //     restartCounter();
  //   }
  // };

  const toggleShowTask = () => {
    setShowTask(!showTask);
  };

  const openTaskModal = () => {
    setShowTaskModal(true);
  };

  const closeTaskModal = () => {
    setShowTaskModal(false);
  };

  const handleSelect = (item) => {
    closeTaskModal();
    dispatch(
      associateTask({
        account_id: timer.account_id,
        date: timer.date,
        task_id: item.id,
        check_in_id: timer.check_in_id
      })
    );
    restartCounter();
  };

  const handleOpenDeleteModal = () => {
    handleMenuClose();
    dispatch(
      openTimerDeleteModal({
        timerToDelete: Object.keys(timer.timers)
      })
    );
  };

  return (
    <TimerTaskContainer>
      <TimerDetailRow>
        <TimerDetailLeft>
          <CompletedIconContainer>
            <CompletedIcon
              color={theme.colors.colorCalendarGray}
              height={9}
              width={9}
            />
          </CompletedIconContainer>
          <Time onClick={toggleShowTask}>
            {timeLabel}
            {timer.task_id && (
              <ArrowContainer showTask={showTask}>
                <FilledArrow />
              </ArrowContainer>
            )}
          </Time>
        </TimerDetailLeft>
        <HoursContainer>
          {/* <WorkedHours
            onClick={() => {
              setIsEditing(true);
              setNewHours('');
            }}
            isZero={!isEditing && Number(formattedHours) === 0}
            isEditing={isEditing}
            value={`${isEditing ? newHours : Number(formattedHours) + 'h'}`}
            onKeyPress={handleKeyPress}
            onChange={e => setNewHours(e.target.value)}
            placeholder="0h"
          /> */}
          <TotalHours>
            {`${timerTaskHash[timer.task_id]?.estimated_hours || 0}h`}
          </TotalHours>
          <TimerIconContainer
            data-for="app-tooltip"
            data-tip={
              isActiveTimer ? 'Click to Stop Timer' : 'Click to Start Timer'
            }
            data-html
            data-effect="solid"
            data-class="center"
            onClick={() => handleTimerToggle(timer)}
            isActiveTimer={isActiveTimer}
          >
            <HideOnHover>
              {isActiveTimer ? (
                <PlayButton />
              ) : (
                <TimerIcon
                  height={22}
                  width={22}
                  fill={timerColor}
                  stroke={timerColor}
                />
              )}
            </HideOnHover>
            <ShowOnHover>
              <StopButton height={22} width={22} />
            </ShowOnHover>
          </TimerIconContainer>
        </HoursContainer>
      </TimerDetailRow>
      {showTask && hasTask && (
        <TimerDescriptionRow>
          <ThreeDotContainer onClick={handleMenuOpen} ref={threeDotTarget}>
            <ProjectThreeDotMenu
              align="left"
              onCloseCallback={handleMenuClose}
              handleClick={handleMenuOpen}
              vertical
              size={16}
            >
              <Popover
                isOpen={isMenuOpen}
                target={threeDotTarget}
                closePopover={handleMenuClose}
                className="styled-member-popover"
              >
                <MenuListItem onClick={handleOpenDeleteModal}>
                  <IconContainer>
                    <TrashBatchIcon fill={theme.colors.colorMediumGray9} />
                  </IconContainer>
                  Delete Timer
                </MenuListItem>
                <MenuListItem onClick={handleOpenTaskDetail}>
                  <IconContainer>
                    <HomeCompletedIcon color={theme.colors.colorMediumGray9} />
                  </IconContainer>
                  View Task Detail
                </MenuListItem>
                {/* <MenuListItem>
                <IconContainer>
                  <PencilIconDark />
                </IconContainer>
                {`Modify Task's Project`}
              </MenuListItem> */}
              </Popover>
            </ProjectThreeDotMenu>
          </ThreeDotContainer>
          <Description>
            {timerTaskHash[timer.task_id]?.description || ''}
          </Description>
        </TimerDescriptionRow>
      )}
      {!hasTask && (
        <TimerDescriptionRow ref={taskModalTarget}>
          <AddTaskText onClick={openTaskModal}>+ Task</AddTaskText>
          {showTaskModal && (
            <TimerTaskModal
              timer={timer}
              handleClose={closeTaskModal}
              onSelect={handleSelect}
              target={taskModalTarget}
            />
          )}
        </TimerDescriptionRow>
      )}
    </TimerTaskContainer>
  );
};

/* --------------------Styling--------------- */

const TimerTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-width: fit-content;
  margin-left: 5px;
`;

const TimerDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 11px;
  width: 294px;
`;

const TimerDescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Time = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HideOnHover = styled.div``;

const ShowOnHover = styled.div`
  display: none;
  margin-left: 1px;
`;

const TimerIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  &:hover {
    ${HideOnHover} {
      display: ${(props) => (props.isActiveTimer ? 'none' : 'block')};
    }
    ${ShowOnHover} {
      display: ${(props) => (props.isActiveTimer ? 'block' : 'none')};
      margin-right: 1px;
    }
    ${(props) =>
      !props.isActiveTimer &&
      `
    path {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorRoyalBlue};
    }
    path:nth-child(2) {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorPureWhite};
    }
    `};
  }
  cursor: pointer;
  margin-left: 5px;
`;

const Description = styled.div`
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
`;

const ThreeDotContainer = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  min-width: 190px;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 7px;
  line-height: 34px;
  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    cursor: pointer;
  }
  font-weight: 400;
`;

const IconContainer = styled.div`
  width: 30px;
  margin-top: -3px;
`;

const TotalHours = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
`;

const HoursContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ArrowContainer = styled.div`
  transform: ${(props) => (props.showTask ? 'none' : 'rotate(180deg)')};
  margin-left: 4px;
`;

const AddTaskText = styled.div`
  cursor: pointer;
  margin-left: 11px;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
`;

const CompletedIconContainer = styled.div`
  margin-right: 5px;
  display: flex;
  margin-top: 4px;
  margin-left: -1px;
`;

const TimerDetailLeft = styled.div`
  display: flex;
`;

export default TimerTask;
