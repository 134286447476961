import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/SvgIcon';

interface MemberIconProps extends Pick<SvgIconProps, 'width' | 'height'> {
  fill?: string;
}

export const MemberIcon = ({
  fill = '#4F4F4F',
  width = '18',
  height = '17'
}: MemberIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 18 17" fill="none">
      <path
        d="M13.123 4.3637C13.123 6.34057 11.3392 8.05046 8.99982 8.05046C6.66049 8.05046 4.87665 6.34057 4.87665 4.3637C4.87665 2.38683 6.66049 0.676939 8.99982 0.676939C11.3392 0.676939 13.123 2.38683 13.123 4.3637Z"
        stroke={fill}
        strokeWidth="1.35388"
      />
      <path
        d="M1 16C2.00002 11.1515 5.00009 10.1818 9.00019 10.1818C13.0003 10.1818 16.0004 11.1515 17.0004 16"
        stroke={fill}
        strokeWidth="1.35388"
      />
    </SvgIcon>
  );
};

export default MemberIcon;
