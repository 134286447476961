import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedTeamId,
  getOwnProjectUnassignedMemberBudgets,
  getOwnProjectUnassignedCounts,
  getFetchedMemberBudgetProjectIds
} from 'selectors';
import {
  getCurrentPositions,
  getIsFetchingPositions,
  getPositions
} from 'BudgetModule/selectors/positions';
import {
  fetchPositions,
  fetchMemberBudgets
} from 'BudgetModule/actionCreators';

import DevProps from 'components/dev/DevProps';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';

import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { formatUnassignedRoleName } from 'appUtils/hooks/budget/useMemberBudgetName';
import { sortPositionNames } from 'components/roles/utils';

/**
 * Required modules: [BudgetModule]
 */
const withPositionsDropdownManager = (PositionsDropdownComponent) => {
  const copy = {
    headerInitial: 'Edit Role',
    headerEdit: '',
    headerAdd: '',
    sticky: 'Add New',
    footerInitial: Footer,
    footerEdit: Footer,
    addConfirm: '',
    editConfirm: '',
    searchPlaceholder: 'Type name or select below'
  };

  const WithPositionsDropdownManager = ({ projectId, ...props }) => {
    const dispatch = useDispatch();
    const teamId = useSelector(getSelectedTeamId);
    const positionHash = useSelector(getPositions);
    const isFetchingPositions = useSelector(getIsFetchingPositions);
    const positions = useSelector(getCurrentPositions); // team's standard positions, not including archived
    const fetchedMemberBudgetProjectIds = useSelector(
      getFetchedMemberBudgetProjectIds
    );
    const isProjectProvided = projectId !== undefined;
    const projectUnassignedMemberBudgets = useSelector((state) =>
      isProjectProvided
        ? getOwnProjectUnassignedMemberBudgets(state, { projectId })
        : emptyArray
    );
    const projectUnassignedRoleCounts = useSelector((state) =>
      getOwnProjectUnassignedCounts(state, {
        projectId
      })
    );
    const existingUnassignedCount = projectUnassignedMemberBudgets.length;

    const allowSelectFromExistingRoles =
      isProjectProvided && existingUnassignedCount > 0;

    const [isShowingStandardPositions, setIsShowingStandardPositions] =
      useState(!isProjectProvided); // prefer to show existing roles first

    const isLoadingPositions = isFetchingPositions && !positions.length;
    const isLoadingProjectMemberBudgets =
      isProjectProvided &&
      [undefined, 'pending'].includes(fetchedMemberBudgetProjectIds[projectId]);
    const isLoading = isLoadingPositions || isLoadingProjectMemberBudgets;

    /* ------------------------------------ - ----------------------------------- */

    // Since default behaviour when project is provided is to show existing roles,
    // if the project ends up not having any after fetching, we need to show standard roles
    useEffect(() => {
      if (
        isProjectProvided &&
        fetchedMemberBudgetProjectIds[projectId] === true &&
        !projectUnassignedMemberBudgets.length &&
        !isShowingStandardPositions
      ) {
        setIsShowingStandardPositions(true);
      }
    }, [
      fetchedMemberBudgetProjectIds,
      isProjectProvided,
      isShowingStandardPositions,
      projectId,
      projectUnassignedMemberBudgets.length
    ]);

    useEffect(() => {
      if (teamId) {
        dispatch(fetchPositions({ teamId }));
      }
    }, [dispatch, teamId]);

    useEffect(() => {
      if (isProjectProvided && !fetchedMemberBudgetProjectIds[projectId]) {
        dispatch(fetchMemberBudgets({ projectId }));
      }
    }, [dispatch, fetchedMemberBudgetProjectIds, projectId, isProjectProvided]);

    useEffect(() => {
      rebuildTooltip();
    }, []);

    /* ------------------------------------ - ----------------------------------- */

    /**
     * Array of positions when isShowingStandardPositions,
     * otherwise array of positions with memberBudget added as a property (existing roles)
     */
    const dropdownItems = useMemo(() => {
      const existingMemberBudgets = projectUnassignedMemberBudgets;
      let unsortedItems;
      if (isShowingStandardPositions) {
        unsortedItems = positions;
      } else {
        unsortedItems = existingMemberBudgets.map((existingMemberBudget) => {
          const positionId = existingMemberBudget.position_id;
          const position = positionHash[positionId];
          const name = formatUnassignedRoleName({
            memberBudget: existingMemberBudget,
            position,
            unassignedRoleCounts: projectUnassignedRoleCounts
          });
          return {
            ...position,
            memberBudget: existingMemberBudget,
            name
          };
        });
      }
      return sortPositionNames(unsortedItems);
    }, [
      isShowingStandardPositions,
      positionHash,
      positions,
      projectUnassignedMemberBudgets,
      projectUnassignedRoleCounts
    ]);

    return (
      <>
        <DevProps
          isLoadingPositions={isLoadingPositions}
          isLoadingProjectMemberBudgets={isLoadingProjectMemberBudgets}
        />
        <PositionsDropdownComponent
          dropdownItems={dropdownItems}
          setIsShowingStandardPositions={setIsShowingStandardPositions}
          showStickyRow={!isLoading && allowSelectFromExistingRoles}
          copy={Object.assign(copy, {
            sticky: isShowingStandardPositions ? 'Add Existing' : 'Add New'
          })}
          isLoading={isLoading}
          {...props}
        />
      </>
    );
  };

  return WithPositionsDropdownManager;
};

export default withPositionsDropdownManager;

/* ------------------------------------ - ----------------------------------- */

const emptyArray = [];

const Footer = (
  <LockWithTooltip
    text="Manage Standard Roles"
    tooltipContent="Only Admins can Add or Edit Standard Roles."
  />
);
