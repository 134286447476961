import React from 'react';
import RichBoldIcon from 'icons/RichBoldIcon';
import RichItalicIcon from 'icons/RichItalicIcon';
import RichBulletedListIcon from 'icons/RichBulletedListIcon';
import RichNumberedListIcon from 'icons/RichNumberedListIcon';
import { BaseToolbar, FormatButton } from './styles';

// see https://github.com/ianstormtaylor/slate/blob/master/examples/rich-text/index.js
// for the rich text example used for reference e.g. for createSelectBlock
const DEFAULT_NODE = 'paragraph';
class RichTextToolbar extends React.Component {
  markMemo = {};
  blockMemo = {};

  getFormattingOptions = () => {
    return [
      {
        type: 'bold',
        icon: <RichBoldIcon />,
        onSelect: this.createSelectMark('bold'),
        isActive: this.isMarkActive('bold')
      },
      {
        type: 'italic',
        icon: <RichItalicIcon />,
        onSelect: this.createSelectMark('italic'),
        isActive: this.isMarkActive('italic')
      },
      {
        type: 'bulleted-list',
        icon: <RichBulletedListIcon />,
        onSelect: this.createSelectBlock('bulleted-list'),
        isActive: this.isBlockActive('bulleted-list')
      },
      {
        type: 'numbered-list',
        icon: <RichNumberedListIcon />,
        onSelect: this.createSelectBlock('numbered-list'),
        isActive: this.isBlockActive('numbered-list')
      }
    ];
  };

  createSelectMark(type) {
    const { editor } = this.props;

    if (!this.markMemo[type]) {
      this.markMemo[type] = (event) => {
        event.preventDefault();
        editor.toggleMark(type);
      };
    }

    return this.markMemo[type];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editor !== this.props.editor) {
      this.markMemo = {};
      this.blockMemo = {};
    }
  }

  createSelectBlock = (type) => {
    const { editor } = this.props;
    const { value } = editor;
    const { document } = value;

    // Handle the extra wrapping required for list buttons.
    if (!this.blockMemo[type]) {
      this.blockMemo[type] = (event) => {
        event.preventDefault();
        const isList = this.isBlockActive('list-item');
        const isType = value.blocks.some((block) => {
          return !!document.getClosest(
            block.key,
            (parent) => parent.type === type
          );
        });
        if (isList && isType) {
          editor
            .setBlocks(DEFAULT_NODE)
            .unwrapBlock('bulleted-list')
            .unwrapBlock('numbered-list');
        } else if (isList) {
          editor
            .unwrapBlock(
              type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
            )
            .wrapBlock(type);
        } else {
          editor.setBlocks('list-item').wrapBlock(type);
        }
      };
    }

    return this.blockMemo[type];
  };

  isMarkActive = (type) => {
    const { editor } = this.props;
    const { value } = editor;
    return value.activeMarks.some((mark) => mark.type == type);
  };

  isBlockActive = (type) => {
    const { editor } = this.props;
    const { value } = editor;
    return value.blocks.some((node) => node.type == type);
  };

  render() {
    const { editor } = this.props;
    if (!editor) {
      return null;
    }
    return (
      <BaseToolbar>
        {this.getFormattingOptions().map((option) => (
          <FormatButton
            key={option.type}
            onMouseDown={option.onSelect}
            isActive={option.isActive}
          >
            {option.icon}
          </FormatButton>
        ))}
      </BaseToolbar>
    );
  }
}

export default RichTextToolbar;
