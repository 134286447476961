import styled from 'styled-components';
import theme from 'theme';
import { StyledTableRow } from 'components/Table/styles';

export const StyledWorkloadEventTable = styled.div`
  .table {
    vertical-align: top;
  }
  ${StyledTableRow} {
    align-items: center;

    &.memberRow {
      border-bottom: 1px solid #f0f0f0;

      &:not(.open):hover {
        background-color: #f7f7f7;
      }

      .app-cues-styled-karat:hover {
        path {
          stroke-width: 1;
          stroke: ${theme.colors.colorRoyalBlue};
        }
      }
    }
    &.projectRow {
      font-size: 12px;
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }

  .table-headers-container {
    padding: 10px 1px 6px 15px;
    border-bottom: 1px solid #f0f0f0;
  }

  .variable-size-list::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const Header = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #6a6a6a;
`;

export const ProjectCellContainer = styled.div`
  height: ${(props) => props.row?.original?.itemHeight || props.height || 50}px;
  overflow: hidden;
  border-bottom: 1px solid #f0f0f0;

  .bulk &.empty-cell {
    margin-right: 17px;
  }
`;

export const ProjectRowHeader = styled(ProjectCellContainer)`
  color: #b3b3b3;
  font-size: 12px;
  font-weight: 600;
  line-height: ${(props) => props.height}px;
`;
export const ProjectPercentageHeader = styled(ProjectRowHeader)`
  text-align: center;
`;
export const ProjectHeader = styled(ProjectRowHeader)`
  padding-left: 35px;
`;

export const ProjectRowFooter = styled(ProjectCellContainer)`
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 600;
  line-height: ${(props) => props.height}px;
`;
export const ProjectFooterLabel = styled(ProjectRowFooter)`
  padding-left: 22px;
`;
export const ProjectFooterTotal = styled(ProjectRowFooter)`
  padding-right: 17px;
  text-align: right;
`;

export const StyledSelectCellContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const StyledSelectCell = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  border: 1px solid ${theme.colors.colorMediumGray1};
  background: ${({ isDisabled }) =>
    isDisabled ? 'transparent' : theme.colors.colorPureWhite};

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 1px;
    top: 1px;
    border-radius: 2px;
    background: ${({ isChecked, isDisabled }) =>
      isDisabled
        ? 'transparent'
        : isChecked
        ? theme.colors.colorRoyalBlue
        : theme.colors.colorPureWhite};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ClickableArea = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
`;

export const StyledBarBudgetContainer = styled.div`
  margin: 10px 0px;
  padding: 5px 10px;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorPaleGray5};
  border-radius: 5px;
`;

export const TextButton = styled.button`
  position: relative;
  height: 30px;
  width: 81px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  outline: none;
  border: 1px solid ${theme.colors.colorLightGray6};
  border-radius: 4px;

  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: ${theme.colors.colorCalendarGray};
  text-decoration: none;

  &:hover {
    filter: brightness(96%);
    cursor: pointer;
  }
`;

export const SubmitButton = styled(TextButton)`
  background-color: ${theme.colors.colorRoyalBlue};
  color: #fff;

  border: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: 177px;
`;
