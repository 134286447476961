import { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import moment from 'moment';
import PartialDayIndicator from '../PartialDayIndicator';
import cn from 'classnames';
import { produce } from 'immer';
import {
  DOUBLE_HANDLE_WIDTH,
  HANDLE_MIN_WIDTH,
  MENU_SIZE,
  SCHEDULE_BAR_FIRST_CHILD_BORDER,
  SCHEDULE_BAR_LAST_CHILD_BORDER
} from './constants';
import { ProjectBarBase } from './styles';
import { HoursOrPercentText } from './SubComponents/HoursOrPercentText';
import { LockHour } from './SubComponents/LockHour';
import { TitleText } from './SubComponents/TitleText';
import { PhaseDetailsText } from './SubComponents/PhaseDetailsText';
import { ThreeDotMenu } from './SubComponents/ThreeDotMenu';
import { TotalHoursText } from './SubComponents/TotalHoursText';

const ROW_PADDING = 8;

const BigItemRenderer = (barProps) => {
  const {
    displayMemberCapacity,
    item,
    itemContext,
    startDateRef,
    endDateRef,
    zoom,
    handleScheduleBarClick,
    handleScheduleBarSplit,
    isSplitting,
    isHeatmapColored,
    itemProps,
    lastDayRemainder,
    itemFitToScaleValue,
    barTooltipContent,
    tooltipDates,
    isTentative,
    lastBarIndex,
    itemToUse,
    leftResizeProps,
    rightResizeProps,
    getLeftOffsetFromDate,
    deserializedId,
    isProjectsView,
    left,
    SplitPreview,
    averageCapacity,
    visibleTimeStart,
    visibleTimeEnd,
    workdayPercent,
    hasStartDependency,
    hasEndDependency
  } = barProps;

  const phaseDetailsComponents = useMemo(
    () => <PhaseDetailsText isProjectsView={isProjectsView} item={item} />,
    [isProjectsView, item]
  );

  const titleComponents = useMemo(
    () => <TitleText isProjectsView={isProjectsView} item={item} />,
    [isProjectsView, item]
  );

  const isMoving = itemContext.dragging || itemContext.resizing;
  const commonClassName = cn('project-schedule-bar-metadata', {
    moving: isMoving,
    inactive: !isMoving && item.phase_is_inactive,
    tentative: isTentative
  });
  const commonBarProps = {
    dataTestid: 'schedule-bar',
    // Bar coloring
    $isHeatmapColored: isHeatmapColored,
    color: isProjectsView
      ? theme.colors.colorMediumGray1
      : theme.colors.colorLightGray14,
    originType: 'project',
    styleId: item.project_id
  };

  // The total width of the bar.
  const width = parseFloat(itemProps.style.width);

  // The width of the horizontal borders of the bar.
  const bordersWidth =
    SCHEDULE_BAR_FIRST_CHILD_BORDER + SCHEDULE_BAR_LAST_CHILD_BORDER;

  // The width of the bar without the borders.
  const widthWithoutBorders = Math.max(width - bordersWidth, 0);

  // The width of the bar without the borders and the horizontal padding of the
  // bar.
  const contentWidth = Math.max(widthWithoutBorders - 2 * ROW_PADDING, 0);

  // A flag indicating whether the bar is in narrow mode. The inner width of
  // the bar is the width without the borders. Both resize handles are in this
  // area by default. If the inner width is less than the minimum width of the
  // two resize handles, the bar changes to narrow mode.
  const narrow = widthWithoutBorders < 2 * HANDLE_MIN_WIDTH;

  return (
    <div {...itemProps}>
      {isMoving ? (
        <ProjectBar
          {...commonBarProps}
          className={cn(commonClassName, { narrow })}
          key="ghost-drag"
        >
          <div
            ref={startDateRef}
            data-tip={moment(tooltipDates.startDate)
              .startOf(itemFitToScaleValue)
              .format('MMM D')}
            data-for="start-date-tooltip"
            data-place="left"
            style={{ position: 'absolute', left: 0, bottom: 0, top: 0 }}
          />
          <div
            ref={endDateRef}
            data-tip={moment(tooltipDates.endDate)
              .endOf(itemFitToScaleValue)
              .format('MMM D')}
            data-for="end-date-tooltip"
            data-place="right"
            style={{ position: 'absolute', right: 0, bottom: 0, top: 0 }}
          />

          {contentWidth > 0 && (
            <>
              <TopRow>{titleComponents}</TopRow>
              <MiddleRow>{phaseDetailsComponents}</MiddleRow>
              <BottomRow>
                <HoursOrPercentText
                  averageCapacity={averageCapacity}
                  displayMemberCapacity={displayMemberCapacity}
                  item={item}
                  workdayPercent={workdayPercent}
                />
              </BottomRow>
            </>
          )}
        </ProjectBar>
      ) : (
        itemToUse.bars.map(
          (
            { start_date: startDate, end_date: endDate, day_count: dayCount },
            index
          ) => {
            // horizontal virtualization
            if (
              moment(endDate).isBefore(
                visibleTimeStart.clone().add(-1, 'month')
              ) ||
              moment(startDate).isAfter(visibleTimeEnd.clone().add(1, 'month'))
            ) {
              return null;
            }

            const isLastRange = index === lastBarIndex;

            // The -1 pixel on `itemRight` is to exclude the right border of the cell.
            const fitStartDate = moment(startDate)
              .startOf(itemFitToScaleValue)
              .valueOf();
            const fitEndDate = moment(endDate)
              .endOf(itemFitToScaleValue)
              .valueOf();
            const itemLeft = getLeftOffsetFromDate(fitStartDate) - left;
            const itemRight = getLeftOffsetFromDate(fitEndDate) - left - 1;

            // The total width of the subbar.
            const width = itemRight - itemLeft;

            // The width of the horizontal borders of the subbar. This subbar
            // only has a left border if it is the first subbar and only has a
            // right border if it is the last subbar.
            const bordersWidth =
              (index === 0 ? SCHEDULE_BAR_FIRST_CHILD_BORDER : 0) +
              (index === lastBarIndex ? SCHEDULE_BAR_LAST_CHILD_BORDER : 0);

            // The width of the subbar without the borders.
            const widthWithoutBorders = Math.max(width - bordersWidth, 0);

            // The width of the subbar without the borders and the horizontal
            // padding of the subbar.
            const contentWidth = Math.max(
              widthWithoutBorders - 2 * ROW_PADDING,
              0
            );

            // A flag indicating whether the subbar is in narrow mode. The
            // inner width of the subbar is the width without the borders. Both
            // resize handles are in this area by default. If the inner width
            // is less than the minimum width of the two resize handles, the
            // subbar changes to narrow mode.
            const narrow = widthWithoutBorders < 2 * HANDLE_MIN_WIDTH;

            // If the subbar is in narrow mode, the resize handles receive
            // special styling that must not be overwritten by the `style`
            // attribute of their elements. This removes the relevant styles.
            const adjustedLeftResizeProps = narrow
              ? produce(leftResizeProps, (draft) => {
                  delete draft?.style?.height;
                  delete draft?.style?.top;
                })
              : leftResizeProps;
            const adjustedRightResizeProps = narrow
              ? produce(rightResizeProps, (draft) => {
                  delete draft?.style?.height;
                  delete draft?.style?.top;
                })
              : rightResizeProps;

            return dayCount === 0 ? (
              <ProjectBar
                className={cn('ghost', { narrow })}
                key={startDate}
                style={{ left: itemLeft, width }}
              >
                {index === 0 && <div {...adjustedLeftResizeProps}></div>}
                {index === lastBarIndex && (
                  <div {...adjustedRightResizeProps}></div>
                )}
                {isSplitting && (
                  <SplitPreview
                    startDate={fitStartDate}
                    endDate={fitEndDate}
                    itemFitToScaleValue={itemFitToScaleValue}
                    itemId={deserializedId}
                    handleScheduleBarSplit={handleScheduleBarSplit}
                  />
                )}
              </ProjectBar>
            ) : (
              <ProjectBar
                {...commonBarProps}
                className={cn(commonClassName, { narrow })}
                onClick={(e) => {
                  e.stopPropagation();
                  handleScheduleBarClick(deserializedId, e, moment(startDate));
                }}
                key={startDate}
                style={{ left: itemLeft, width }}
                // Tooltip
                data-class="project-bars-tooltips"
                data-delay-show={1000}
                data-effect="float"
                data-for="schedule-bar"
                data-html
                data-tip={barTooltipContent}
                data-type="light"
              >
                {
                  // Left resize handle
                  index === 0 && (
                    <div {...adjustedLeftResizeProps}>
                      {hasStartDependency ? (
                        <LeftDependencyCrossHatch
                          originType={
                            isProjectsView ? 'teamMembership ' : 'project'
                          }
                          color={
                            isProjectsView
                              ? theme.colors.colorMediumGray1
                              : theme.colors.colorLightGray14
                          }
                          styleId={
                            isProjectsView ? item.member?.id : item.project_id
                          }
                        />
                      ) : (
                        <>
                          <div className="drag-handle-bar left" />
                          {widthWithoutBorders / 2 >= DOUBLE_HANDLE_WIDTH && (
                            <div className="drag-handle-bar left" />
                          )}
                        </>
                      )}
                    </div>
                  )
                }
                {
                  // Right resize handle
                  index === lastBarIndex && (
                    <div {...adjustedRightResizeProps}>
                      {hasEndDependency ? (
                        <RightDependencyCrossHatch
                          originType={
                            isProjectsView ? 'teamMembership ' : 'project'
                          }
                          color={
                            isProjectsView
                              ? theme.colors.colorMediumGray1
                              : theme.colors.colorLightGray14
                          }
                          styleId={
                            isProjectsView ? item.member?.id : item.project_id
                          }
                        />
                      ) : (
                        <>
                          <div className="drag-handle-bar right" />
                          {widthWithoutBorders / 2 >= DOUBLE_HANDLE_WIDTH && (
                            <div className="drag-handle-bar right" />
                          )}
                        </>
                      )}
                    </div>
                  )
                }
                {isSplitting && (
                  <SplitPreview
                    startDate={fitStartDate}
                    endDate={fitEndDate}
                    itemFitToScaleValue={itemFitToScaleValue}
                    itemId={deserializedId}
                    handleScheduleBarSplit={handleScheduleBarSplit}
                  />
                )}

                {contentWidth > 0 && (
                  <>
                    <TopRow>
                      {titleComponents}
                      {contentWidth >= MENU_SIZE && (
                        <ThreeDotMenu
                          startDate={startDate}
                          barProps={barProps}
                        />
                      )}
                    </TopRow>
                    <MiddleRow>{phaseDetailsComponents}</MiddleRow>
                    <BottomRow>
                      {
                        /* Force a vertical space if nothing is rendered. */
                        (width >= 60 && (
                          <HoursOrPercentText
                            averageCapacity={averageCapacity}
                            displayMemberCapacity={displayMemberCapacity}
                            hasBold
                            item={item}
                            workdayPercent={workdayPercent}
                          />
                        )) || <>&#x200b;</>
                      }
                      <BottomRight>
                        {isLastRange && !!lastDayRemainder && (
                          <PartialDayIndicator
                            lineHeight={1}
                            remainder={lastDayRemainder}
                          />
                        )}
                        {width >= 120 && (
                          <TotalHoursText
                            zoom={zoom}
                            item={item}
                            dayCount={dayCount}
                            averageCapacity={averageCapacity}
                          />
                        )}
                        {item.lock_hour && <LockHour />}
                      </BottomRight>
                    </BottomRow>
                  </>
                )}
              </ProjectBar>
            );
          }
        )
      )}
    </div>
  );
};

export default BigItemRenderer;

const ProjectBar = styled(ProjectBarBase)`
  flex-direction: column;
  height: calc(100% - 12px);

  &:hover {
    .three-dot-menu {
      display: inline-flex;
    }
  }
`;

const RowContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 ${ROW_PADDING}px;
  position: relative;
`;

const TopRow = styled(RowContainer)`
  gap: 5px;
`;

const MiddleRow = styled(RowContainer)``;

const BottomRow = styled(RowContainer)`
  font-size: 11px;
  gap: 5px;
  justify-content: end;

  .moving & {
    justify-content: start;
  }
`;

const BottomRight = styled.div`
  align-items: center;
  display: flex;
  flex: none;
`;

const DependencyCrossHatch = styled.div`
  background-image: linear-gradient(
    180deg,
    var(--border-color) 12.5%,
    white 12.5%,
    white 50%,
    var(--border-color) 50%,
    var(--border-color) 62.5%,
    white 62.5%,
    white 100%
  );
  background-size: 8px 8px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  max-width: 20%;
  min-width: 7px;
  position: absolute;
  width: 13px;
`;

const LeftDependencyCrossHatch = styled(DependencyCrossHatch)`
  border-bottom-left-radius: 3px;
  border-right: 1px solid var(--border-color);
  border-top-left-radius: 3px;
  left: -2px;
`;

const RightDependencyCrossHatch = styled(DependencyCrossHatch)`
  border-bottom-right-radius: 3px;
  border-left: 1px solid var(--border-color);
  border-top-right-radius: 3px;
  right: 0px;
`;
