import { SyntheticEvent, ChangeEvent, useState } from 'react';
import styled from 'styled-components';

interface InlineGroupEditingFormProps {
  initialValue: string;
  onSubmit: (value: string) => void; // value will be trimmed
}
export const InlineGroupEditingForm = ({
  initialValue,
  onSubmit
}: InlineGroupEditingFormProps) => {
  const [value, setValue] = useState(initialValue);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      label: { value: string };
    };
    const label = target.label.value;
    onSubmit(label.trim());
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    onSubmit(value.trim());
  };

  return (
    <Form onSubmit={handleSubmit}>
      <StyledInput
        value={value}
        name="label"
        onChange={handleChange}
        onBlur={handleBlur}
        data-testid="inline-create-group-row-input"
        autoFocus
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    </Form>
  );
};

const Form = styled.form`
  height: 100%;
  min-height: 60px;
  padding: 8px 0;
`;
const StyledInput = styled.input`
  padding: 0 16px;
  height: 100%;
  width: 60%;
  min-width: 300px;
  border-radius: 3px;
  box-shadow: none !important;
  border: 1px solid transparent;

  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.colorMediumGray9};

  &:focus {
    &::placeholder {
      size: 14px;
      color: ${({ theme }) => theme.colors.colorLightGray6};
    }
  }

  &:focus,
  &:active {
    background: white;
    border-color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;
