import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { chainActions, removeRequestStatusId } from 'actionCreators';
import { useUnmount } from 'react-use';
const emptyObj = {};

/**
 * For dispatching sequential actions. The returned function takes an array of actions, with each
 * element (or 'layer') being either an action object or an array of actions. A layer will only be dispatched
 * if the previous layer (ie all the actions of that layer) has successfully completed.
 * Non-request actions are completed immediately, whereas request actions are completed on
 * SUCCESS, FAILURE (if continueOnFailure is true), or ABORT (if continueOnCancellation is true). If any action
 * fails or aborts and continueOnFailure and continueOnCancellation are false, the chain will be cancelled.
 *
 * EXAMPLE:
 *
 *  const dispatchChain = useDispatchChain();
 *
 *  ...
 *
 *    dispatchChain(
 *      [
 *        [ fetchSomething1({...}),fetchSomething2({ ... }) ]
 *        fetchSomething3After1and2({ ... }),
 *        doSomethingAfter3({ ... })
 *      ],
 *      {
 *        chainId: 'load-page',
 *        continueOnFailure: true,
 *        continueOnCancellation: true
 *       }
 *    );
 *
 * NOTES:
 *  - uses meta and ABORT. actions must be setup for these
 *  - If you need the response values from any of the actions, use the onSuccess pattern instead
 *  - when options.chainId is provided to dispatchChain, state.statuses.requestStatuses[chainId] will be
 *    set to isFetching: true and (if initial is true) isLoading: true on first dispatch. If the entire chain
 *    succeeds, isSuccess will be true. If there's a failure or cancellation, error will be set with a message
 *    about which action caused the chain to fail.
 */
const useDispatchChain = ({ cleanupChainStatuses = true } = emptyObj) => {
  const dispatch = useDispatch();
  const [chainIds, setChainIds] = useState([]); // will be tracked and removed on unmount if cleanupChainStatuses === true

  const dispatchChain = useCallback(
    (
      actions,
      options = {
        chainId: null,
        initial: false,
        takeLatest: false,
        continueOnFailure: false,
        continueOnCancellation: false
      }
    ) => {
      if (cleanupChainStatuses && options.chainId) {
        setChainIds((currentChainIds) =>
          Array.from(new Set([...currentChainIds, options.chainId]))
        );
      }
      dispatch(
        chainActions({
          actions,
          ...options
        })
      );
    },
    [cleanupChainStatuses, dispatch]
  );

  useUnmount(() => {
    if (cleanupChainStatuses && chainIds.length) {
      dispatch(removeRequestStatusId(chainIds));
    }
  });

  return dispatchChain;
};

export default useDispatchChain;
