import * as constants from '../constants';
import { action } from 'appUtils';
export const fetchNotes = {
  request: () => action(constants.FETCH_NOTES_BY_PROJECT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_NOTES_BY_PROJECT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_NOTES_BY_PROJECT.FAILURE, { payload: { error } })
};

export const fetchSingleNote = {
  request: () => action(constants.FETCH_NOTE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_NOTE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_NOTE.FAILURE, { payload: { error } })
};

export const noteDeletion = {
  request: () => action(constants.DELETE_NOTE.REQUEST),
  success: (response) =>
    action(constants.DELETE_NOTE.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_NOTE.FAILURE, { payload: { error } })
};

export const noteUpdate = {
  request: () => action(constants.NOTE_UPDATE.REQUEST),
  success: (response) =>
    action(constants.NOTE_UPDATE.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.NOTE_UPDATE.FAILURE, { payload: { error } })
};

export const noteCreation = {
  request: () => action(constants.NOTE_CREATION.REQUEST),
  success: (response) =>
    action(constants.NOTE_CREATION.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.NOTE_CREATION.FAILURE, { payload: { error } })
};

export const fetchProjectNotes = {
  request: () => action(constants.FETCH_PROJECT_NOTES_V2.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_NOTES_V2.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_NOTES_V2.FAILURE, { payload: { error } })
};
