import React from 'react';
import { IconContainer } from './styles';
const noop = () => {};

const GlobalAddListItem = ({
  Icon,
  onClick = noop,
  children,
  tooltip = ''
}) => (
  <div
    onClick={onClick}
    className="project-menu-item"
    data-html
    data-for="app-tooltip"
    data-effect="solid"
    data-class="center"
    data-tip={tooltip}
  >
    <IconContainer>
      <Icon />
    </IconContainer>
    {children}
  </div>
);

export default GlobalAddListItem;
