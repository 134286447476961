import useCan from 'appUtils/hooks/useCan';
import {
  inviteGuests,
  inviteMemberToTeam
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { INVITE_MEMBER_TO_TEAM_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useAppSelector } from 'reduxInfra/hooks';
import { getIsUserPermissionsLoaded, getUserIsLoaded } from 'selectors';
import { InviteMemberIcon, MemberInviteButton } from './styles';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';

export const NavMemberInviteButton = ({ onClick }: { onClick: () => void }) => {
  const isUserPermissionsLoaded = useAppSelector(getIsUserPermissionsLoaded);
  const isUserLoaded = useAppSelector(getUserIsLoaded);

  const canInviteToTeam = useCan(inviteMemberToTeam, {
    isReady: isUserPermissionsLoaded && isUserLoaded
  });
  const canInviteGuest = useCan(inviteGuests, {
    isReady: isUserPermissionsLoaded && isUserLoaded
  });

  const canInvite = canInviteToTeam || canInviteGuest;

  return (
    <MemberInviteButton
      onClick={canInvite ? onClick : undefined}
      {...defaultTooltipProps}
      data-tip={INVITE_MEMBER_TO_TEAM_TIP}
      data-tip-disable={canInvite}
    >
      <InviteMemberIcon />
      Invite Member
    </MemberInviteButton>
  );
};
