export const ORIGIN_TYPE_STRINGS = {
  BOARD: 'board',
  TEAM: 'team',
  TAG: 'tag',
  TEAM_MEMBERSHIP: 'teamMembership',
  PROJECT: 'project',
  PROJECT_MANAGE_MEMBERS: 'projectManageMembers',
  TASK_STATUS: 'taskStatus',
  TASK_PRIORITY: 'taskPriority',
  PROJECT_STATUS: 'projectStatus',
  PROJECT_STAGE: 'projectStage',
  PROJECT_PRIORITY: 'projectPriority',
  TASK_GROUP: 'taskGroup',
  BILLING_CATEGORY: 'billingCategory'
} as const;
