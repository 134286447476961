import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import {
  ColorScaleColorsInput,
  ColorScaleColorsInputProps
} from '../ColorScaleColorsInput';
import ReverseIcon from 'icons/ReverseIcon';
import { buildColorScaleId } from '../utils';
import cn from 'classnames';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';

export type ReversibleColorScaleColorsInputProps = ColorScaleColorsInputProps;

/**
 * Wrapper input that allows reversing the colors of the color scales list.
 */
export const ReversibleColorScaleColorsInput = ({
  className,
  options,
  value,
  ...otherProps
}: ReversibleColorScaleColorsInputProps) => {
  const [isReversed, setIsReversed] = useState(false);

  const currentScaleId = useMemo(
    () => (value ? buildColorScaleId(value) : undefined),
    [value]
  );

  const currentScaleReversedId = useMemo(
    () => (value ? buildColorScaleId(value.slice().reverse()) : undefined),
    [value]
  );

  const reversedColorScaleIds = useMemo(
    () => options.map((colors) => buildColorScaleId(colors.slice().reverse())),
    [options]
  );

  // Determines if the scales need to be reversed based on the provided value.
  useEffect(() => {
    setIsReversed(
      !!currentScaleId &&
        currentScaleId !== currentScaleReversedId &&
        reversedColorScaleIds.includes(currentScaleId)
    );
  }, [currentScaleId, currentScaleReversedId, reversedColorScaleIds]);

  // Reverse the color scales if required.
  const adjustedColorScales = useMemo(
    () =>
      isReversed ? options.map((colors) => colors.slice().reverse()) : options,
    [isReversed, options]
  );

  const handleReverse = () => {
    setIsReversed(!isReversed);
  };

  const defaultSet = value === null;

  return (
    <Container className={className}>
      <Reverse
        className={cn({ defaultSet })}
        {...defaultTooltipProps}
        data-tip={
          defaultSet
            ? 'Org Default is set. Select personal setting below to reverse colors.'
            : undefined
        }
        role="reverse"
        onClick={handleReverse}
      >
        <ReverseIcon
          color={defaultSet ? theme.colors.colorBudgetGrey : undefined}
          direction="left"
          scale={1.5}
        />
        Reverse Colors
        <ReverseIcon
          color={defaultSet ? theme.colors.colorBudgetGrey : undefined}
          direction="right"
          scale={1.5}
        />
      </Reverse>
      <ColorScaleColorsInput
        className="color-scale-colors-input"
        options={adjustedColorScales}
        value={value}
        {...otherProps}
      />
    </Container>
  );
};

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: fit-content;

  // Stretch the color selector input if this input has been stretched.
  & > .color-scale-colors-input {
    width: 100%;
  }
`;

export const Reverse = styled.div`
  align-items: center;
  border-radius: 6px;
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 5px;
  margin: 0 auto 4px;
  padding: 4px;
  width: fit-content;

  &:hover {
    background-color: ${theme.colors.colorTranslucentGray4};
  }

  &.defaultSet {
    color: ${theme.colors.colorBudgetGrey};
  }
`;
