import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { isToday } from 'appUtils/momentUtils';

export const TimelineGroup = ({
  date,
  events = [],
  renderItem,
  keyExtractor = (item) => item?.id
}) => {
  return (
    <RootContainer>
      <TimelineDateTitle date={date}></TimelineDateTitle>
      <TimelineListContainer>
        {events?.map((item, index) => (
          <TimelineItemContainer
            data-testid="timeline-item"
            key={keyExtractor(item)}
          >
            {renderItem && renderItem(item, index)}
          </TimelineItemContainer>
        ))}
      </TimelineListContainer>
    </RootContainer>
  );
};

const format = (date) => {
  return moment.utc(date).format('MMM D');
};
const TimelineDateTitle = ({ date }) => {
  const shouldDisplayToday = isToday(moment(date));
  return (
    <DateTitleContainer>
      {shouldDisplayToday && <Today>Today</Today>}
      <Date isPrimary={!shouldDisplayToday}>{format(date)}</Date>
    </DateTitleContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const TimelineListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: hidden;
`;
const TimelineItemContainer = styled.div``;

const DateTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 52px;
`;

const PrimaryTextStyle = css`
  font-size: 15px;
  font-weight: 600;
`;
const SecondaryTextStyle = css`
  font-size: 13px;
  text-align: right;
`;

const Today = styled.div`
  ${PrimaryTextStyle}
  color: ${({ theme }) => theme.colors.colorLightOrange2};
  text-align: right;
`;

const Date = styled.div`
  ${({ isPrimary }) => (isPrimary ? PrimaryTextStyle : SecondaryTextStyle)}
  color: ${({ theme }) => theme.colors.colorMediumGray5}
`;
