import { put, select } from 'redux-saga/effects';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { fetchNoteTagsByBoard } from 'actionCreators';
import actionCreators from '../actionCreators';
import { getAuthToken, getSelectedProject } from 'selectors';
const { fetchNotesByProject: fetchNotesByProjectActionCreator } =
  actionCreators;
const {
  fetchNotes,
  fetchProjectNotes,
  fetchSingleNote,
  noteDeletion,
  noteUpdate,
  noteCreation
} = entityActions;

export function* fetchNotesByProject(action) {
  const token = yield select(getAuthToken);

  const { projectId, limit, offset, searchText, accountIds, tagIds } =
    action.payload.params;

  yield fetchEntity(
    fetchNotes,
    api.fetchNotesByProject,
    undefined,
    [token, projectId, limit, offset, searchText, accountIds, tagIds],
    action
  );
}

export function* fetchNote(action) {
  const token = yield select(getAuthToken);
  const { id } = action.payload;

  yield fetchEntity(fetchSingleNote, api.fetchNote, id, [token], action);
}

export function* deleteNote(action) {
  const token = yield select(getAuthToken);
  const { noteId } = action.payload;

  const { error } = yield changeEntity(noteDeletion, api.deleteProjectNote, [
    token,
    noteId
  ]);
}

export function* postProjectNote(action) {
  const {
    accountId,
    title,
    body,
    accountIds,
    projectId,
    searchText,
    plainTextBody
  } = action.payload;

  const token = yield select(getAuthToken);
  const selectedProject = yield select(getSelectedProject);
  const { error } = yield changeEntity(noteCreation, api.postProjectNote, [
    token,
    accountId,
    title,
    body,
    projectId,
    plainTextBody
  ]);
  if (!error) {
    if (selectedProject) {
      yield put(fetchNoteTagsByBoard({ boardId: selectedProject.board_id }));
    }
    yield put(
      fetchNotesByProjectActionCreator({
        projectId,
        accountIds,
        searchText
      })
    );
  }
}

export function* updateNote(action) {
  const token = yield select(getAuthToken);
  const {
    noteId,
    title,
    body,
    position,
    accountIds,
    projectId,
    searchText,
    plainTextBody
  } = action.payload;
  const selectedProject = yield select(getSelectedProject);

  const { error } = yield changeEntity(noteUpdate, api.putProjectNote, [
    token,
    noteId,
    title,
    body,
    position,
    plainTextBody
  ]);

  if (!error) {
    if (selectedProject) {
      yield put(fetchNoteTagsByBoard({ boardId: selectedProject.board_id }));
    }
    /* only re-fetch projects if position is not present
      in order to work with dragula */
    if (!position) {
      yield put(
        fetchNotesByProjectActionCreator({
          projectId,
          accountIds,
          searchText
        })
      );
    }
  }
}

export function* fetchProjectNotesV2(action) {
  const token = yield select(getAuthToken);

  const { projectIds, limit, offset, searchText, accountIds, tagIds } =
    action.payload;

  yield fetchEntity(
    fetchProjectNotes,
    api.fetchProjectNotesV2,
    undefined,
    [token, projectIds, limit, offset, searchText, accountIds, tagIds],
    action
  );
}
