import React, { useState, ReactNode, useMemo } from 'react';

import styled from 'styled-components';
import theme from 'theme';

import ReactTooltip from 'react-tooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

type ListItem = {
  id: number;
  name: string;
  phase_membership_ids: number[];
  [x: string]: any;
};

type CopyProps = {
  headerInitial?: string;
  headerEdit?: string;
  headerAdd?: string;
  sticky?: string;
  footerInitial?: JSX.Element;
  footerEdit?: JSX.Element;
  addConfirm?: string;
  editConfirm?: string;
};

type SuggestionsTableDropdownProps = {
  initialSelectedItemsIds: number[];
  targetRef: ReactNode;
  items: ListItem[];
  dropdownType: string;
  onFlyoutMenuFooterClick: (dropdownType: string) => void;
  onUpdate: (
    addOrRemoveIdsHash: Record<number, boolean>,
    dropdownType: string
  ) => void;
  onClose: () => void;

  copy?: CopyProps;
  renderCustomHeader?: () => JSX.Element;
  headerText?: string;
  subHeaderText?: string;
};

const HeaderText = styled.div`
  font-size: 22px;
`;

const SubHeaderText = styled.div`
  font-size: 14px;
  font-weight: normal;

  margin-top: -5px;

  color: #828282;
`;

type ItemRowProps = {
  isSelected: boolean;
};

const ItemRow = styled.div`
  padding-left: 30px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .remove-text {
    color: ${theme.colors.colorDeleteRed};
    display: ${({ isSelected }: ItemRowProps) =>
      isSelected ? 'none' : 'flex'};
  }
  .added-text {
    color: ${theme.colors.colorCalendarBlue};
    display: ${({ isSelected }: ItemRowProps) =>
      isSelected ? 'flex' : 'none'};
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    .added-text {
      display: ${({ isSelected }: ItemRowProps) =>
        isSelected ? 'none' : 'flex'};
    }
    .remove-text {
      display: ${({ isSelected }: ItemRowProps) =>
        isSelected ? 'flex' : 'none'};
    }
  }
`;

const StyledItemName = styled.div`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 180px;
`;

const StyledHeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding-bottom: 2px;
  font-size: 14px;
  width: 52px;
  height: 28px;
  border-radius: 3px;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.colorDarkBlue4};
  }
`;

/**
 * This dropdown is strictly used with member's modal suggestion table's region/office/discipline options.
 * (See SuggestionsTable)
 */
const SuggestionsTableDropdown = ({
  initialSelectedItemsIds,
  onUpdate,
  onClose,
  onFlyoutMenuFooterClick,
  targetRef,
  items,
  copy,
  dropdownType,
  //
  renderCustomHeader,
  headerText = '',
  subHeaderText = ''
}: SuggestionsTableDropdownProps) => {
  const [addOrRemoveIdsHash, setAddOrRemoveIdsHash] = useState<
    Record<number, boolean>
  >({});

  const [selectedItemsIds, setSelectedItemsIds] = useState<number[]>(
    initialSelectedItemsIds
  );

  const selectedItemsIdsSet = useMemo(
    () => new Set(selectedItemsIds),
    [selectedItemsIds]
  );

  const listItems = useMemo(() => {
    const selectedRows: ListItem[] = [];
    const unselectedRows: ListItem[] = [];

    items.forEach((item: ListItem) => {
      if (selectedItemsIdsSet.has(item.id)) {
        selectedRows.push(item);
      } else {
        unselectedRows.push(item);
      }
    });
    return [...selectedRows, ...unselectedRows];
  }, [items, selectedItemsIdsSet]);

  const handleSelect = (e, { item }) => {
    e.preventDefault();

    const { id } = item;

    if (!id) {
      return;
    }

    onMultiSelect(id);
  };

  const deselectItem = (selectedId: number) => {
    setSelectedItemsIds(selectedItemsIds.filter((id) => selectedId !== id));
    setAddOrRemoveIdsHash({
      ...addOrRemoveIdsHash,
      [selectedId]: false
    });
  };

  const selectItem = (selectedId: number) => {
    const nextSelectedItemIds = [...selectedItemsIds];
    nextSelectedItemIds.push(selectedId);
    setSelectedItemsIds(nextSelectedItemIds);
    setAddOrRemoveIdsHash({
      ...addOrRemoveIdsHash,
      [selectedId]:
        getIsSelected(selectedId) && !addOrRemoveIdsHash[selectedId]
          ? false // Already selected initially, so set to false
          : true
    });
  };

  const onMultiSelect = (selectedId: number) => {
    if (getIsSelected(selectedId)) {
      deselectItem(selectedId);
    } else {
      selectItem(selectedId);
    }
  };

  const onFooterClick = () => {
    ReactTooltip.hide();
    onFlyoutMenuFooterClick(dropdownType);
    onUpdate(addOrRemoveIdsHash, dropdownType);
  };

  const handleUpdate = () => {
    if (onUpdate) onUpdate(addOrRemoveIdsHash, dropdownType);
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const getIsSelected = (id: number) => selectedItemsIdsSet.has(id);

  /* --------------------------------------- render ----------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;
    const isSelected = getIsSelected(item.id);

    if (isSelected) {
      return (
        <ItemRow isSelected={isSelected}>
          <StyledItemName>{item.name}</StyledItemName>
          <div>
            <div className="added-text">Selected</div>
            <div className="remove-text">Remove</div>
          </div>
        </ItemRow>
      );
    } else {
      return (
        <ItemRow isSelected={isSelected}>
          <StyledItemName>{item.name}</StyledItemName>
        </ItemRow>
      );
    }
  };

  const renderHeaderButton = () => {
    return (
      <StyledHeaderButton onClick={handleUpdate}>
        {selectedItemsIdsSet.size > 0 ? 'Add' : 'Done'}
      </StyledHeaderButton>
    );
  };

  const renderHeaderText = () => {
    if (renderCustomHeader) return renderCustomHeader();

    return (
      <>
        <HeaderText>{headerText}</HeaderText>
        <SubHeaderText>{subHeaderText}</SubHeaderText>
      </>
    );
  };

  return (
    <>
      <MultiStepFlyout
        copy={copy}
        target={targetRef}
        items={listItems}
        idKey="id"
        renderHeader={renderHeaderText}
        renderHeaderButton={renderHeaderButton}
        renderItem={renderItem}
        handleSelect={handleSelect}
        isWhite
        handleClose={handleClose}
        onFooterClick={onFooterClick}
        popoverClassName="generic-dropdown"
        listWidth={300}
        listHeight={200}
        itemHeight={48}
        isItemUnSelectable={(item) => item.isSectionHeader}
        canMultiSelect
        noMinWidth={true}
      />
    </>
  );
};

export default SuggestionsTableDropdown;
