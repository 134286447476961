import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useEffect } from 'react';
import { getSelectedTeamId } from 'selectors';
import { fetchDisciplines } from 'SettingsModule/actionCreators/settings/discipline';
import { DisciplinesTable } from './DisciplinesTable';

export const DisciplinesTableContainer = () => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector(getSelectedTeamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchDisciplines({ teamId }));
    }
  }, [dispatch, teamId]);

  return <DisciplinesTable />;
};
