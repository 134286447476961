/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { ProjectCellContainer } from 'views/projectPlanner/WorkloadEventsModal/styles.js';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  getHrsPerDateRange,
  getCommitmentTooltipContent
} from 'appUtils/workplanDisplayUtils';

const StyledProjectDaysCell = styled.div`
  padding-top: 16px;

  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ProjectDaysCell = ({ row }) => {
  const { original } = row;
  const hrsPerDateRange = useMemo(
    () => getHrsPerDateRange(original?.ranges) || [],
    [original]
  );
  const tooltipContent = useMemo(
    () => getCommitmentTooltipContent(hrsPerDateRange),
    [hrsPerDateRange]
  );

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <ProjectCellContainer
      height={row?.original?.itemHeight}
      data-for={'app-tooltip'}
      data-place="top"
      data-effect="solid"
      data-tip={hrsPerDateRange.length > 0 ? tooltipContent : undefined}
      data-offset="{'top': -20, 'right': -20}"
      data-delay-show="250"
      data-html
    >
      <StyledProjectDaysCell>
        {hrsPerDateRange.map((range) => {
          return (
            <>
              <span>{range[0]} </span>
              <span style={{ color: theme.colors.colorCalendarGray }}>
                ({range[1]}h){' '}
              </span>
            </>
          );
        })}
      </StyledProjectDaysCell>
    </ProjectCellContainer>
  );
};

export default ProjectDaysCell;
