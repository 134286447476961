import React, { useState, useRef } from 'react';
import SkillLevelIndicator from './SkillLevelIndicator';
import SkillLevelDropdown from './SkillLevelDropdown';

const SkillLevelSelector = ({ skillMembership, member }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const targetRef = useRef(null);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div>
      {/* Display the current level */}
      <SkillLevelIndicator
        onClick={toggleDropdown}
        level={skillMembership.level}
        targetRef={targetRef}
      />
      {/* Skill level selection */}
      {dropdownOpen && (
        <SkillLevelDropdown
          isOpen
          closeDropdown={toggleDropdown}
          targetRef={targetRef}
          member={member}
          skillMembership={skillMembership}
        />
      )}
    </div>
  );
};

export default SkillLevelSelector;
