import React from 'react';
import SvgIcon from 'components/SvgIcon';

const OrganizationIcon = ({ className, height = '28', width = '28' }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    fill="currentColor"
    viewBox="0 0 28 28"
  >
    <path
      d="M21.3398 5.09094V7.86743V22.5909H6.36382L6.36377 7.86743V5.09094H21.3398ZM16.7461 16.8348V21.3947H20.1436V6.28596H7.56081V21.3947H10.9583V16.8348H16.7461ZM15.5501 18.0309H12.1535V21.3946H15.5501V18.0309ZM16.7653 12.0303H18.8293V13.5194H16.7653V12.0303ZM12.8221 12.0303H14.885V13.5194H12.8221V12.0303ZM8.87776 12.0303H10.9418V13.5194H8.87776V12.0303ZM16.7665 8.74531H18.8305V10.2345H16.7665V8.74531ZM12.8233 8.74531H14.8862V10.2345H12.8233V8.74531ZM8.87892 8.74531H10.9429V10.2345H8.87892V8.74531Z"
      fill="#4F4F4F"
    />
  </SvgIcon>
);

export default OrganizationIcon;
