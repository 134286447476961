import styled from 'styled-components';
import { Nav } from 'reactstrap';
import theme from 'theme';
import NewTeamMemberWhite from 'icons/new-team-member-white.svg';

export const StyledNavTextNavItem = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  flex: 1;
  min-width: 0;

  &.hoverable {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.colorCalendarBlue};
    }
  }

  &.hasBackArrow {
    color: ${({ theme }) => theme.colors.colorCalendarBlue};
    cursor: pointer;
    padding: 0 7px;

    &:hover {
      background: ${({ theme }) => theme.colors.colorPaleGray11};
      border-radius: 6px;
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }

  &.narrowBackArrow {
    margin-left: -19px;

    .backArrow {
      margin-right: 10px;
    }
  }

  .backArrow {
    margin-right: 14px;
    margin-top: 2px;
  }
`;

export const NavLeft = styled(Nav)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: 0;
  min-width: 0;
`;

export const NavRight = styled(Nav)`
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  gap: 0.9rem;
  // To account for the padding of the last icon
  margin-right: -7px;

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-item-search-bar {
    width: 36px;
  }
`;

export const SpaceContainer = styled.div`
  flex: 1;
  max-width: fit-content;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InviteMemberIcon = styled.div`
  background-image: url(${NewTeamMemberWhite});
  background-size: contain;
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

export const MemberInviteButton = styled.div`
  cursor: pointer;
  background: ${theme.colors.colorRoyalBlue};
  color: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  padding: 8px 12px;
  border-radius: 30px;
  margin-left: 12px;
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 400;
  transition: 0.1s ease-in-out;

  &:hover {
    transform: scale(1.1);
    transform-origin: center center;
  }
`;

export const StyledLoadingContainer = styled.div`
  width: 300px;
  height: 14px;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid ${theme.colors.colorPaleGray5};
  background: ${theme.colors.colorPureWhite};
`;

export const StyledLoader = styled.div`
  height: 100%;
  border-radius: 5px;
  background: ${theme.colors.colorRoyalBlue};
  -webkit-animation: moveWidth 10s linear 0s forwards;
  -moz-animation: moveWidth 10s linear 0s forwards;
  animation: moveWidth 10s linear 0s forwards;
  transform-origin: center center;

  @-moz-keyframes moveWidth {
    0% {
      width: 0%;
      visibility: visible;
    }
    99% {
      width: 99%;
      visibility: visible;
    }

    100% {
      width: 100%;
      visibility: hidden;
    }
  }
  @-webkit-keyframes moveWidth {
    0% {
      width: 0%;
      visibility: visible;
    }
    99% {
      width: 99%;
      visibility: visible;
    }

    100% {
      width: 100%;
      visibility: hidden;
    }
  }
  @keyframes moveWidth {
    0% {
      width: 0%;
      visibility: visible;
    }
    99% {
      width: 99%;
      visibility: visible;
    }

    100% {
      width: 100%;
      visibility: hidden;
    }
  }
`;
