import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { TableV2 } from 'components/Table/TableV2';
import { BaseTableList } from 'components/Table/types';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { useRateGroupGroupedListsBuilder } from './helpers/useRateGroupGroupedListsBuilder';
import styled from 'styled-components';
import { ROW_CLASSES, ROW_TYPES } from './tableConfigs';
import {
  billRateColumn,
  costRateColumn,
  descriptionColumn,
  rateColumn
} from './tableConfigsColumns';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { RATE_GROUP_DEFAULT_NAME } from 'RatesModule/constants';
import { useTableBoilerplate } from './helpers/useTableBoilerplate';
import {
  clearRateEntities,
  clearRateGroups,
  clearRates,
  fetchRateEntities,
  fetchRateGroups,
  fetchRates
} from 'RatesModule/ratesActionCreators';
import CollapseAllButton from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';
import FetchManager from 'classes/FetchManager';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import {
  getEntityRatesByRateGroup,
  getEntityRatesHash,
  getRateEntitiesHash,
  getRateEntitiesOrder,
  getRateGroupsHash,
  getRatesHash,
  getRoleRateGroupsOrder,
  getWorkCategoryRateGroupsOrder
} from 'RatesModule/ratesSelectors';
import { AddEditEntityRateModal } from 'RatesModule/components/AddEditEntityRateModal/AddEditEntityRateModal';
import { updateActivity } from 'actionCreators';
import { AddButton } from 'SettingsModule/components/styles';
import uuid from 'uuid';
import { useOpenerWithProps } from 'RatesModule/hooks/useOpenerWithProps';
import { DeleteRateGroupModal } from 'RatesModule/components/DeleteRateGroupModal';
import { AddEditRateGroupModal } from 'RatesModule/components/AddEditRateGroupModal';
import { RateGroupId } from 'RatesModule/models/RateGroup';
import { RateGroupMenu } from './RateGroupMenu';
import { AddEntityMenu } from './AddEntityMenu';
import { RateEntity } from 'RatesModule/models/RateEntity';

const COMPONENT_NAME = 'GroupedRatesTable';

const EMPTY_RATE_GROUP_HASH = {};

export const GroupedRatesTable = ({
  activeEntityType
}: {
  activeEntityType: EntityRateType;
}) => {
  const dispatch = useAppDispatch();

  const entityRatesHash = useAppSelector(getEntityRatesHash);
  const entityRatesByRateGroup = useAppSelector(getEntityRatesByRateGroup);
  const rateEntitiesHash = useAppSelector(getRateEntitiesHash);
  const rateEntitiesOrder = useAppSelector(getRateEntitiesOrder);
  const rateGroupsHash = useAppSelector(getRateGroupsHash);
  const rateGroupsOrder = useAppSelector(
    activeEntityType === EntityRateType.Role
      ? getRoleRateGroupsOrder
      : getWorkCategoryRateGroupsOrder
  );
  const ratesHash = useAppSelector(getRatesHash);
  const teamId = useAppSelector(getSelectedTeamId);

  const abortId = useMemo(() => `${COMPONENT_NAME}>${uuid()}`, []);

  const firstRateGroupId = rateGroupsOrder?.[0];
  const firstRateGroup = firstRateGroupId
    ? rateGroupsHash?.[firstRateGroupId]
    : undefined;
  const isSystemDefaultRateGroup =
    firstRateGroup &&
    RATE_GROUP_DEFAULT_NAME.test(firstRateGroup.name) &&
    firstRateGroup.is_default;
  const hasGroups =
    (rateGroupsOrder?.length ?? 0) > 1 || !isSystemDefaultRateGroup;

  const isLoaded =
    entityRatesByRateGroup &&
    entityRatesHash &&
    rateEntitiesHash &&
    rateEntitiesOrder &&
    rateGroupsHash &&
    rateGroupsOrder &&
    ratesHash;

  const {
    allCollapsed,
    getIsOpen,
    listRef,
    tableConfig,
    toggleCollapse,
    toggleCollapseAll
  } = useTableBoilerplate({
    rateGroupsHash: rateGroupsHash ?? EMPTY_RATE_GROUP_HASH,
    variant: activeEntityType
  });

  const {
    Component: AddEditRateGroupModalComponent,
    isOpen: isAddEditRateGroupModalOpen,
    open: openAddEditRateGroupModal
  } = useOpenerWithProps<
    ComponentProps<typeof AddEditRateGroupModal>,
    'rateGroup'
  >(AddEditRateGroupModal);

  const {
    Component: DeleteRateGroupModalComponent,
    open: openDeleteRateGroupModal
  } = useOpenerWithProps<
    ComponentProps<typeof DeleteRateGroupModal>,
    'rateGroup'
  >(DeleteRateGroupModal);

  const {
    Component: AddEditEntityRateModalComponent,
    open: openAddEditEntityRateModal
  } = useOpenerWithProps<
    ComponentProps<typeof AddEditEntityRateModal>,
    'currencyCode' | 'entityId' | 'isCostRate' | 'rateGroupId'
  >(AddEditEntityRateModal);

  const [currentRateGroupMenuId, setCurrentRateGroupMenuId] =
    useState<RateGroupId>();
  const { Component: RateGroupMenuComponent, toggle: toggleRateGroupMenu } =
    useOpenerWithProps<
      ComponentProps<typeof RateGroupMenu>,
      'deleteRateGroup' | 'editRateGroup' | 'rateGroup' | 'target'
    >(RateGroupMenu, {
      onOpen: ({ rateGroup: { id } }) => setCurrentRateGroupMenuId(id),
      onClose: () => setCurrentRateGroupMenuId(undefined)
    });

  const { Component: AddEntityMenuComponent, toggle: toggleAddEntityMenu } =
    useOpenerWithProps<ComponentProps<typeof AddEntityMenu>, 'target'>(
      AddEntityMenu
    );

  const onUpdateEntity = (
    rateEntity: RateEntity,
    params: Parameters<typeof updateActivity>[0]
  ) => {
    if (rateEntity.active_entity_type === EntityRateType.WorkCategory) {
      dispatch(
        updateActivity({
          ...params,
          onSuccess: [
            {
              successAction: () =>
                dispatch(
                  fetchRateEntities({
                    active_entity_type: rateEntity.active_entity_type,
                    meta: { abortId }
                  })
                ),
              selector: () => undefined
            }
          ]
        })
      );
    }
  };

  useEffect(
    () => () => {
      // Cleanup on unmount.
      FetchManager.abort(abortId);
      dispatch(clearRates());
      dispatch(clearRateEntities());
      dispatch(clearRateGroups());
    },
    [abortId, dispatch]
  );
  useEffect(() => {
    if (teamId) {
      dispatch(fetchRates({ team_id: teamId, meta: { abortId } }));
      dispatch(
        fetchRateEntities({
          active_entity_type: activeEntityType,
          meta: { abortId }
        })
      );
    }
  }, [abortId, activeEntityType, dispatch, teamId]);
  useEffect(() => {
    if (!isAddEditRateGroupModalOpen)
      dispatch(
        fetchRateGroups({
          active_entity_type: activeEntityType,
          meta: { abortId }
        })
      );
  }, [abortId, activeEntityType, dispatch, isAddEditRateGroupModalOpen]);

  /* ---------------------------- Table building ---------------------------- */

  // Entry point to build the table (can be grouped or ungrouped list(s))
  const listBuilder = useRateGroupGroupedListsBuilder({
    getIsOpen,
    onUpdateEntity,
    openAddEditEntityRateModal,
    openAddEditRateGroupModal,
    openDeleteRateGroupModal,
    rateGroupMenuId: currentRateGroupMenuId,
    toggleCollapse,
    toggleAddEntityMenu,
    toggleRateGroupMenu
  });

  const mainList: BaseTableList = useMemo(() => {
    // A list of grouped or ungrouped lists
    const listItems = isLoaded
      ? listBuilder({
          entityRatesByRateGroup,
          entityRatesHash,
          rateEntitiesHash,
          rateEntitiesOrder,
          rateGroupsHash,
          rateGroupsOrder,
          ratesHash
        })
      : [
          {
            id: 'loading',
            rowHeight: 210,
            rowType: ROW_TYPES.loadingRow
          }
        ];
    const list: BaseTableList = {
      listItems,
      isList: true,
      id: 'main',
      isOpen: true,
      isFullyLoaded: true,
      skipHeader: true
    };

    return list;
  }, [
    isLoaded,
    listBuilder,
    entityRatesByRateGroup,
    entityRatesHash,
    rateEntitiesHash,
    rateEntitiesOrder,
    rateGroupsHash,
    rateGroupsOrder,
    ratesHash
  ]);

  /* --------------------------------- Render --------------------------------- */

  return (
    <>
      <Header>
        {hasGroups && (
          <StyledCollapseAllButton
            onToggle={toggleCollapseAll}
            isCollapsed={allCollapsed}
          />
        )}
        <AddButton onClick={() => openAddEditRateGroupModal({})}>
          + Rate Group
        </AddButton>
      </Header>
      <Container>
        <TableV2
          customHandleScroll={rebuildTooltip}
          customRowProps={{ activeEntityType }}
          listRef={listRef}
          mainList={mainList}
          {...tableConfig.tableProps}
        />
      </Container>
      <AddEditEntityRateModalComponent />
      <AddEditRateGroupModalComponent activeEntityType={activeEntityType} />
      <AddEntityMenuComponent activeEntityType={activeEntityType} />
      <DeleteRateGroupModalComponent activeEntityType={activeEntityType} />
      <RateGroupMenuComponent activeEntityType={activeEntityType} />
    </>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 13px;

  .table {
    color: inherit;
  }

  .tr {
    &.${ROW_TYPES.rateGroupHeaderRow} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorBudgetGrey};
    }

    &.${ROW_CLASSES.archivedRateEntity} {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    &.${ROW_TYPES.rateRow} {
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorLightGray25};

      &:hover {
        &:not(.${ROW_CLASSES.archivedRateGroup},
            .${ROW_CLASSES.archivedRateEntity}) {
          .${rateColumn.id},
            .${billRateColumn.id},
            .${costRateColumn.id},
            .${descriptionColumn.id} {
            color: ${({ theme }) => theme.colors.colorRoyalBlue};
          }
        }

        .editIcon {
          display: block;
        }
      }
    }
  }
`;

const StyledCollapseAllButton = styled(CollapseAllButton)`
  // The right margin centers the button with those in the table.
  margin: 0 6px 0 2px;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 18px;
`;
