import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';
const OpenFilterMenuIcon = ({ color = theme.colors.colorCalendarBlue }) => {
  return (
    <SvgIcon
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.50002H11V1.52588e-05H0V1.50002Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.50002H12V8.00002H0V9.50002Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.50002H9V4.00002H0V5.50002Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default OpenFilterMenuIcon;
