import { ScheduleBar, WorkPlan } from './WorkplanModal/models/workPlan';
import { DailyCapacity } from 'models/accountCapacity';
import moment from 'appUtils/momentConfig';
import round from 'lodash/round';
import { Moment } from 'moment';
import {
  isLegacyStartAndEndDateDependency,
  isStartDateDependency,
  isEndDateDependency
} from 'appUtils/newDependencies';
import { Task } from 'models/task';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { ZOOM_LEVELS, FORECAST_HORIZONS } from 'appConstants/workload';
import { DateISOString } from 'models/date';

export const isTentativePlan = (budgetStatus: string | null): boolean => {
  return budgetStatus === 'proposal';
};

export const getWorkDaysFromScheduleBars = (
  scheduleBars: ScheduleBar[]
): number => {
  return scheduleBars.reduce((sum, bar) => {
    sum += bar.day_count;
    return sum;
  }, 0);
};

// TODO: put it shared folder and share with ScheduleCalendar
const daysOfWeekMap = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const;

// it doesn't count one off capacity yet
export const getWorkdayHours = ({
  workdayPercent,
  dailyCapacity,
  datesArray
}: {
  workdayPercent: number;
  dailyCapacity: DailyCapacity;
  datesArray: Array<string>;
}): number => {
  if (workdayPercent === 0 || !datesArray.length || !dailyCapacity) return 0;
  const sumOfPercent = datesArray.reduce((acc, dateStr) => {
    const weekDay = moment(dateStr).isoWeekday() - 1;
    const dayOfWeek = daysOfWeekMap[weekDay];
    if (dayOfWeek && dailyCapacity[dayOfWeek] !== undefined) {
      return acc + (dailyCapacity[dayOfWeek] * workdayPercent) / 100;
    }
    return acc;
  }, 0);
  return round(sumOfPercent / datesArray.length, 2);
};

// it doesn't count one off capacity yet
export const getWorkdayPercent = ({
  dailyHours,
  dailyCapacity,
  datesArray
}: {
  dailyHours: number;
  datesArray: Array<string>;
  dailyCapacity: DailyCapacity;
}): number => {
  if (dailyHours === 0 || !datesArray.length || !dailyCapacity) return 0;
  const totalCapacities = datesArray.reduce((acc, dateStr) => {
    const weekDay = moment(dateStr).isoWeekday() - 1;
    const dayOfWeek = daysOfWeekMap[weekDay];
    if (dayOfWeek && dailyCapacity[dayOfWeek] !== undefined) {
      return acc + dailyCapacity[dayOfWeek];
    }
    return acc;
  }, 0);
  const totalHours = dailyHours * datesArray.length;
  return totalCapacities > 0
    ? round((totalHours / totalCapacities) * 100, 2)
    : 100;
};

export const getConstantCapacity = ({
  datesArray,
  dailyCapacity
}: {
  datesArray: Array<string>;
  dailyCapacity: DailyCapacity;
}): {
  isOverAllConstant: boolean;
  isWeeklyConstant: boolean;
  constantCapacities: Record<string, number> | undefined;
} => {
  const constantCapacities: Record<string, number> = {};
  let isOverAllConstant = true;
  let isWeeklyConstant = true;
  let prevValue: number | undefined;

  datesArray.forEach((dateStr) => {
    const weekDay = moment(dateStr).isoWeekday() - 1;
    const dayOfWeek = daysOfWeekMap[weekDay];

    if (!dayOfWeek) return;

    const dateCapacity = dailyCapacity[dayOfWeek];

    if (dateCapacity === undefined) return;

    if (prevValue === undefined) {
      prevValue = dateCapacity;
    } else if (prevValue !== dateCapacity) {
      isOverAllConstant = false;
    }

    const prevDayOfWeekValue = constantCapacities[dayOfWeek];
    // store initial value
    if (prevDayOfWeekValue === undefined) {
      constantCapacities[dayOfWeek] = dateCapacity;

      // compare with last value
    } else if (prevDayOfWeekValue !== dateCapacity) {
      isWeeklyConstant = false;
    }
  });
  return {
    isOverAllConstant,
    isWeeklyConstant,
    constantCapacities
  };
};

export const getWeekendsArrayFromRange = ({
  startDate,
  endDate
}: {
  startDate: string;
  endDate: string;
}) => {
  return Array.from(moment.range(startDate, endDate).by('day')).flatMap(
    (date: Moment) => {
      const dateStr = date.format('YYYY-MM-DD');
      if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
        return [dateStr];
      }
      return [];
    }
  );
};

export const getDatesExcludeDaysOff = ({
  startDate,
  endDate,
  daysOff
}: {
  startDate: string;
  endDate: string;
  daysOff: Array<string>;
}): Array<string> => {
  return Array.from(moment.range(startDate, endDate).by('day')).flatMap(
    (date: Moment) => {
      const dateStr = date.format('YYYY-MM-DD');
      if (daysOff.length) {
        if (daysOff.includes(dateStr)) {
          return [];
        }
      }
      return [dateStr];
    }
  );
};

export const getDateStringsFromBars = (
  scheduleBars: ScheduleBar[]
): DateISOString[] => {
  const dateSet = new Set<DateISOString>();
  scheduleBars.forEach(({ start_date, end_date, day_count }) => {
    if (day_count === 0) return;
    const startDate = moment(start_date);
    const endDate = moment(end_date);
    const dates = Array.from(moment.range(startDate, endDate).by('day'));
    dates.forEach((date: Moment) => {
      dateSet.add(date.format('YYYY-MM-DD'));
    });
  });

  return Array.from(dateSet);
};

export const getDependencyStatus = (item: Phase | Task | WorkPlan) => {
  const dependencyStatus = {
    hasStartDependency: false,
    hasEndDependency: false
  };

  if (!item) return dependencyStatus;

  const dependencies =
    item.dependencies?.map(({ dependency_type }) => dependency_type) ?? [];

  dependencies.forEach((dependency_type) => {
    if (isLegacyStartAndEndDateDependency(dependency_type)) {
      dependencyStatus.hasStartDependency = true;
      dependencyStatus.hasEndDependency = true;
    } else if (isStartDateDependency(dependency_type)) {
      dependencyStatus.hasStartDependency = true;
    } else if (isEndDateDependency(dependency_type)) {
      dependencyStatus.hasEndDependency = true;
    }
  });
  return dependencyStatus;
};

export const getFetchPredictionHoursParams = ({ zoom }) => {
  // mosaicapp.slack.com/archives/C011K1J2JN9/p1657569790504239?thread_ts=1657544757.755199&cid=C011K1J2JN9
  // Spec:
  // Send next_14days = On day and week view
  // Send next_calendar_month_1 = On month, quarterly and yearly view
  const zoomToUse = zoom ?? ZOOM_LEVELS.WEEK;

  const forecast_horizon =
    zoomToUse === ZOOM_LEVELS.WEEK || zoomToUse === ZOOM_LEVELS.DAY
      ? FORECAST_HORIZONS.NEXT_14_DAYS
      : FORECAST_HORIZONS.NEXT_CALENDAR_MONTH_1;

  return { forecast_horizon };
};
