import { MenuItemContainer } from './styles';
import cn from 'classnames';
import {
  dependencyDeltaType,
  dependencyDeltaTypeStrings
} from '../../constants';
import { CommonDependencyItem } from '../../types';

export const DeltaTypeRenderer = ({
  deltaType,
  selected
}: {
  deltaType: NonNullable<CommonDependencyItem['deltaType']>;
  selected: boolean;
}) => {
  return (
    <MenuItemContainer className={cn({ selected })}>
      {dependencyDeltaTypeStrings[dependencyDeltaType[deltaType]]}
    </MenuItemContainer>
  );
};
