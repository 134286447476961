import React from 'react';
import SvgIcon from 'components/SvgIcon';

const FollowUpBatchIcon = () => (
  <SvgIcon width="16" height="14" viewBox="0 0 16 14">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#0074D9"
      d="M6.733 10.135l-2.397 2.769a.543.543 0 0 1-.322.096c-.064 0-.128 0-.193-.032a.503.503 0 0 1-.289-.45 81.229 81.229 0 0 1-1.046-3.182A4.568 4.568 0 0 1 5.068 1h5.55a4.568 4.568 0 0 1 0 9.135H6.732z"
    />
  </SvgIcon>
);

export default FollowUpBatchIcon;
