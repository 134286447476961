import React from 'react';
import SvgIcon from 'components/SvgIcon';

const KaratLeft = ({
  className,
  height = '25',
  width = '16',
  fill = '#4a4a4a',
  onClick
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 16 25"
    className={className}
    fill="none"
    onClick={onClick}
  >
    <path
      d="M14.5596 3.34129L5.40036 12.5005L14.5596 21.6597L12.6002 23.6597L1.44101 12.5005L12.6002 1.34128L14.5596 3.34129Z"
      fill={fill}
    />
  </SvgIcon>
);

export default KaratLeft;
