import { createSelector } from 'reselect';
import {
  getIsConfigDirQbToMosaic,
  getQBProjectsState,
  getQuickbooksSelectedProjectsQBToMosaic,
  getQuickbooksSelectedProjectsMosaicToQB
} from './quickbooksCore';
import { getAllFetchedProjects, makeGetProjectsByBoardId } from 'selectors';
import {
  getQBSyncedProjects,
  getQBSyncedProjectsByProjectId
} from 'QuickbooksModule/selectors';

const emptyObj = {};

export const getVisibleSelectedProjects = createSelector(
  getIsConfigDirQbToMosaic,
  getQuickbooksSelectedProjectsQBToMosaic,
  getQuickbooksSelectedProjectsMosaicToQB,
  (qbToMosaic, qbToMosaicProjects, mosaicToQBProjects) =>
    qbToMosaic ? qbToMosaicProjects : mosaicToQBProjects
);

export const getVisibleSelectedProjectsLength = createSelector(
  getVisibleSelectedProjects,
  (visibleSelectedProjects) => Object.keys(visibleSelectedProjects).length
);

export const getSelectedProjectsList = createSelector(
  getVisibleSelectedProjects,
  (selectedProjects) => Object.values(selectedProjects)
);

export const getSyncedSelectedProjects = createSelector(
  getSelectedProjectsList,
  (selectedProjects) => {
    return selectedProjects.filter(
      (selectedProject) => selectedProject && selectedProject.synced
    );
  }
);

export const getNotSyncedSelectedProjects = createSelector(
  getSelectedProjectsList,
  (selectedProjects) => {
    return selectedProjects.filter(
      (selectedProject) => selectedProject && !selectedProject.synced
    );
  }
);

export const getSelectedPreFillProjectsQBToMosaic = createSelector(
  getQBProjectsState,
  (projectState) =>
    (projectState && projectState.selectedPreFillProjectsQBToMosaic) || emptyObj
);

export const getIsSyncingProjectsCompletedFlag = createSelector(
  getQBProjectsState,
  (qbSubCustomersState) => qbSubCustomersState.isFetchingSyncedSubCustomers
);

export const getSyncedSelectedBilledProjects = createSelector(
  getSyncedSelectedProjects,
  getIsConfigDirQbToMosaic,
  getAllFetchedProjects,
  (syncedProjects, isQbToMosaic, allProjects) => {
    if (isQbToMosaic) {
      return Object.values(syncedProjects)
        .filter((project) => {
          const mosaicProject = allProjects[project.mosaic_project_id];
          return mosaicProject && mosaicProject.billable;
        })
        .map((project) => {
          const mosaicProject = allProjects[project.mosaic_project_id];
          return {
            id: project && project.mosaic_project_id,
            billable: mosaicProject && mosaicProject.billable
          };
        });
    } else {
      return Object.values(syncedProjects)
        .filter((project) => project && project.billable)
        .map((project) => {
          return {
            id: project && project.id,
            billable: project && project.billable
          };
        });
    }
  }
);

export const getSyncedSelectedNotBilledProjects = createSelector(
  getSyncedSelectedProjects,
  getIsConfigDirQbToMosaic,
  getAllFetchedProjects,
  (syncedProjects, isQbToMosaic, allProjects) => {
    if (isQbToMosaic) {
      return Object.values(syncedProjects)
        .filter((project) => {
          const mosaicProject = allProjects[project.mosaic_project_id];
          return mosaicProject && !mosaicProject.billable;
        })
        .map((project) => {
          const mosaicProject = allProjects[project.mosaic_project_id];
          return {
            id: project.mosaic_project_id,
            billable: mosaicProject && mosaicProject.billable
          };
        });
    } else {
      return Object.values(syncedProjects)
        .filter((project) => project && !project.billable)
        .map((project) => {
          return {
            id: project && project.id,
            billable: project && project.billable
          };
        });
    }
  }
);

export const makeGetFilteredProjectsByBoardId = () =>
  createSelector(
    makeGetProjectsByBoardId(),
    getQBSyncedProjectsByProjectId,
    (projects, syncedProjects) =>
      projects.map((project) => {
        if (
          syncedProjects[project.id] &&
          !syncedProjects[project.id].dont_sync
        ) {
          return {
            ...project,
            isAlreadySelected: true
          };
        } else {
          return project;
        }
      })
  );

export const syncedProjectIds = createSelector(
  getQBSyncedProjects,
  (syncedProjects) => {
    return Object.values(syncedProjects).map(
      (project) => project.mosaic_project_id
    );
  }
);
