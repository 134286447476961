import React from 'react';
import PropTypes from 'prop-types';

import intersection from 'lodash/intersection';
import { connect } from 'react-redux';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { DeleteModal, ChangePriorityContainer } from '..';
import ProjectSettings from './ProjectSettings';
import {
  fetchProjectById,
  fetchPriorities,
  clearSelectedProject,
  handleProjectItemState,
  saveSearchState,
  useSearchState,
  starProject,
  toggleSidebarSearch,
  closeSidebarProjects,
  openAddMembersForm,
  openProjectModal,
  setSelectedProject,
  setFromBoardViewNavText,
  navigateToProject,
  addMemberToProject,
  archiveUnarchiveProject,
  hardDeleteProject
} from 'actionCreators';
import ColorPicker from 'components/ColorPicker';
import { withRouter } from 'react-router-dom';
import projectEditClasses from 'appConstants/projectEditClasses';
import Highlighter from 'react-highlight-words';
import {
  getIsActive,
  getSearch,
  getSearchText,
  getSearchWords,
  getSidebarProjectsOpen,
  getSidebarSearchOpen,
  getSelectedBoard,
  getAddMembersForm,
  getSelectedAccountIds,
  makeAmIOnProject,
  getMatchedRouteParams,
  getAuth,
  getProjectsState,
  getSelectedTeamId,
  getUserPermissions
} from 'selectors';
import styled from 'styled-components';
import {
  calculateProjectColorPreference,
  calculateColorPref
} from 'appUtils/styleUtils';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import theme from 'theme';
import { ColorPickerHoverArea } from 'components/ColorPicker/styles';
import { Draggable } from 'react-beautiful-dnd';
import ProjectColumns from './ProjectColumns';
import {
  hasAssociatedTime,
  getAssociatedTimeMessage
} from 'appUtils/budgetUtils';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import {
  deletePortfolioProject,
  archivePortfolioProject,
  editPortfolio
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const JoinButtonContainer = styled.div`
  align-self: center;
`;
const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -6px;
  height: 26px;
  width: 26px;
  top: 25px;
  border-radius: 100px;
  .color-picker-container {
    height: 100%;
    width: 100%;
  }

  &:hover {
    .project-color-picker {
      visibility: visible !important;
    }
  }
`;

const StyledDot = styled.div`
  position: absolute;
  height: 10px;
  top: 50%;
  border-radius: 100px;
  transform: translateY(-50%);
  width: 10px;
  background: ${calculateProjectColorPreference};
`;
const JoinButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 2px 16px;
  border-radius: 15px;
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.colorRoyalBlue};
  }
`;

const ProjectTopRowContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -24px;
  .project-settings-box {
    width: 24px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;
const StyledProjectTitle = styled.h4`
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 18px;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
`;
// const portal = document.createElement('div');
// document.body.append(portal); // may be necessary for rendering correct item if sticky scrolled

const ProjectInnerRight = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;
// total width - width of the star/notification column - padding

const ViewProjectLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const ViewProjectLink = styled.div`
  border-radius: 100px;
  height: 24px;
  width: 100px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.colorRoyalBlue};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.colorRoyalBlue};
  }
`;

const NoteCountContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
`;

const unhighlightStyle = { lineHeight: '28px' };
const projectTitleStyle = { cursor: 'pointer' };

export function stopEvent(e) {
  return e && event.stopPropagation();
}

const formatCountText = (number, word) => {
  const formattedWord = number === 1 ? word : `${word}s`;
  return `${number} ${formattedWord}`;
};

class Project extends React.Component {
  state = {
    deleteModalOpen: false
  };

  componentDidMount() {
    this.props.fetchPriorities();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userPermissions !== prevProps.userPermissions) {
      this.canArchive = undefined;
      this.canDelete = undefined;
    }
  }

  getPermissions = () => ({
    permissions: {
      projectId: this.props.project.id,
      teamId: this.props.teamId,
      boardId: this.props.project.board_id
    }
  });

  checkDeletePermission = () => {
    if (this.canDelete === undefined) {
      const { checkPermission, hardDeleteProject, deletePortfolioProject } =
        this.props;
      const canDeleteProject = checkPermission(
        hardDeleteProject,
        this.getPermissions()
      );
      const canDeletePortfolioProject = checkPermission(
        deletePortfolioProject,
        this.getPermissions()
      );
      this.canDelete = canDeleteProject && canDeletePortfolioProject;
    }
    return this.canDelete;
  };

  checkArchivePermission = () => {
    if (this.canArchive === undefined) {
      const {
        checkPermission,
        archiveUnarchiveProject,
        archivePortfolioProject
      } = this.props;
      const canArchiveProject = checkPermission(
        archiveUnarchiveProject,
        this.getPermissions()
      );
      const canArchivePortfolioProject = checkPermission(
        archivePortfolioProject,
        this.getPermissions()
      );
      this.canArchive = canArchiveProject && canArchivePortfolioProject;
    }
    return this.canArchive;
  };

  checkPortfolioEditable = () => {
    const { checkPermission, editPortfolio } = this.props;
    const canEditPortfolio = checkPermission(
      editPortfolio,
      this.getPermissions()
    );
    return canEditPortfolio;
  };

  handleModalState = (bool) => {
    if (this.checkDeletePermission()) {
      this.setState({
        deleteModalOpen: bool
      });
    }
  };

  deleteProjectTrigger = () => {
    const { project, deleteProject, selectedBoard } = this.props;
    const projectId = project.id;
    const groupId = project.board_id;
    const groupSlug = selectedBoard?.slug;
    const isActive = !project.archived_at;
    const isPersonal = project.is_personal;
    const payload = { projectId, groupId, isActive, groupSlug, isPersonal };

    deleteProject(payload);
    this.handleModalState(false);
  };

  handleExpand = (e) => {
    const {
      project,
      matchedParams,
      fetchProjectById,
      handleProjectItemState,
      searchText,
      saveSearchState,
      useSearchState,
      search,
      toggleSidebarSearch,
      setFromBoardViewNavText,
      navigateToProject
    } = this.props;

    if (search.sidebarSearchOpen) {
      toggleSidebarSearch();
      toggleSidebarSearch(null);
    }

    if (intersection(e.target.classList, projectEditClasses).length) {
      return;
    }
    if (searchText) {
      saveSearchState({ home: '', board: searchText });
      useSearchState({ home: false, board: true });
    }
    fetchProjectById(project.id);
    handleProjectItemState(project.id, ['fixed', 'expanded'], [true, true]);
    navigateToProject({
      teamSlug: matchedParams.teamSlug,
      projectSlug: project.slug,
      projectId: project.id
    });
    setFromBoardViewNavText();
  };

  starProject = (e) => {
    const { starProject, project } = this.props;
    e.stopPropagation();
    starProject({
      projectId: project.id,
      starred: !project.is_starred,
      position: project.position
    });
  };

  makeCounter = (type, count, showSeparator) => {
    const isNote = type === 'Note';
    const classType = isNote ? 'note' : 'task';
    let formattedText;
    if (type === 'Note' || type === 'Task') {
      formattedText = formatCountText(count, type);
    } else {
      formattedText = `${count} ${type}`;
    }
    return (
      <NoteCountContainer className={`count-container ${type.toLowerCase()}`}>
        <div className={`${classType}-count-text`}>{formattedText}</div>
        {showSeparator && (
          <i className={'count-separator'} onClick={this.handleExpand} />
        )}
      </NoteCountContainer>
    );
  };

  setPriorityOptionsId = () => {
    const { setPriorityOptionsId, project } = this.props;
    setPriorityOptionsId(project.id)();
  };

  editProjectOnClick = (e) => {
    const {
      project,
      openEditProjectModal,
      fetchProjectById,
      setSelectedProject
    } = this.props;
    fetchProjectById(project.id);
    setSelectedProject({ projectId: project.id });
    e.stopPropagation();
    openEditProjectModal(project.id);
  };

  joinProject = () => {
    const { me, addMemberToProject, project } = this.props;
    const basicMemberRole = 3;
    addMemberToProject(project.id, me.id, basicMemberRole, project.board_id);
  };

  archiveAndCloseModal = () => {
    const { project } = this.props;
    const isActive = !project.archived_at;
    if (this.checkArchivePermission() && isActive) {
      this.archiveProject();
    }
    this.setState({ deleteModalOpen: false });
  };

  archiveProject = () => {
    const { project, projects, auth, archiveUnarchiveProject } = this.props;
    const payload = {
      token: auth.token,
      projectId: project.id,
      isActive: !projects.isActive,
      position: project.position,
      permissions: this.getPermissions().permissions
    };
    archiveUnarchiveProject(payload);
  };

  renderDeleteBody = () => {
    const { loadingHasTimeEntries, project } = this.props;
    if (loadingHasTimeEntries) {
      return <div />;
    }
    const isActive = !project.archived_at;
    const associatedTime = this.projectHasTimeEntries();
    if (associatedTime) {
      return (
        <>
          <b>{project.title}</b>{' '}
          {getAssociatedTimeMessage(associatedTime, MODAL_TYPE.PROJECT)}
          {isActive ? ' Would you like to Archive instead?' : ''}
        </>
      );
    }
    return (
      <>
        Are you sure you want to <b>permanently</b> delete{' '}
        <b>{project.title}</b>? You can’t restore a deleted Project.
      </>
    );
  };

  renderDeleteConfirm = ({ deleteOnClick }) => {
    const projectHasTimeEntries = this.projectHasTimeEntries();
    const { project } = this.props;
    const isActive = !project.archived_at;
    return (
      <button
        onClick={
          projectHasTimeEntries ? this.archiveAndCloseModal : deleteOnClick
        }
      >
        {isActive ? 'Ok' : 'Yes'}
      </button>
    );
  };

  projectHasTimeEntries = () =>
    this.props.hasTimeEntries?.find(
      (hasTimeEntry) =>
        hasTimeEntry.project_id === this.props.project?.id &&
        hasAssociatedTime(hasTimeEntry)
    );

  render() {
    const {
      position,
      project,
      search,
      searchWords,
      showPriorityOptionsForId,
      isActive,
      toggleSidebarSearch,
      sidebarSearchOpen,
      sidebarProjectsOpen,
      isFiltering,
      closeSidebarProjects,
      searchText,
      index,
      columnOrder,
      me,
      shouldShowBatchOptions
    } = this.props;

    // to account for Safari styling discrepancy - see below in render
    let browser = window.navigator.vendor;
    if (browser === 'Apple Computer, Inc.') {
      browser = 'Safari';
    } else {
      browser = '';
    }

    const starType = project.is_starred ? 'selected' : 'unselected';

    const projectDropdownOpen = showPriorityOptionsForId === project.id;
    const canDragProject = this.checkPortfolioEditable() && !searchText.length;

    return (
      <Draggable
        draggableId={`${project.id}`}
        index={index}
        key={project.id}
        isDragDisabled={!canDragProject}
      >
        {(provided, snapshot) => {
          const item = (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <th>
                <div
                  data-project-id={project.id}
                  id={`project-${project.id}-ghost`}
                  className={cn('project-item-ghost', {
                    'force-hover': projectDropdownOpen,
                    'archived-project':
                      !isActive && showPriorityOptionsForId !== project.id
                  })}
                  title={'Click to Open Project'}
                  onClick={() => {
                    if (sidebarSearchOpen) {
                      toggleSidebarSearch();
                    }
                    if (sidebarProjectsOpen) {
                      closeSidebarProjects(null);
                    }
                  }}
                >
                  <ReactTooltip id={`add-members-${position}`} effect="solid" />
                  <div className={'project-item-wrapper'}>
                    <div
                      className={cn('project-item', {
                        'force-hover': projectDropdownOpen,
                        filtered: isFiltering
                      })}
                    >
                      <ColorPickerHoverArea
                        className={cn('project-inner', {
                          filtered: isFiltering
                        })}
                        onClick={this.handleExpand}
                      >
                        <ViewProjectLinkContainer className="view-project-container">
                          <ViewProjectLink
                            onClick={this.handleExpand}
                            data-testid={`view-${project.id}-${project.title}`}
                          >
                            View Project
                          </ViewProjectLink>
                        </ViewProjectLinkContainer>

                        {project?.project_membership?.find(
                          (member) => member?.account?.id === me?.id
                        ) ? null : (
                          <JoinButtonContainer className="join-button-container">
                            <JoinButton onClick={this.joinProject}>
                              Join
                            </JoinButton>
                          </JoinButtonContainer>
                        )}

                        <ProjectInnerRight>
                          <DeleteModal
                            isOpen={this.state.deleteModalOpen}
                            toggle={() => this.handleModalState(false)}
                            deleteOnClick={this.deleteProjectTrigger}
                            renderBody={this.renderDeleteBody}
                            renderDelete={this.renderDeleteConfirm}
                            component={'project'}
                          />
                          <ProjectTopRowContainer>
                            <ProjectSettings
                              project={project}
                              isVisible={projectDropdownOpen}
                              position={position}
                              starProject={this.starProject}
                              starType={starType}
                              onProjectRow
                            />
                            <div
                              className={cn('priority-icon edit-info', {
                                'is-visible': projectDropdownOpen
                              })}
                              onClick={this.editProjectOnClick}
                            />

                            <div
                              className={cn('ico-drag-wrapper clickable', {
                                filtered: isFiltering
                              })}
                              onClick={this.setPriorityOptionsId}
                            >
                              <div
                                className={cn('ico-drag-background', {
                                  'is-visible': projectDropdownOpen
                                })}
                                ref={(ref) => (this.priorityOptionsRef = ref)}
                              >
                                <i
                                  className={cn('ico-drag-project', {
                                    'dragula-active': search.dragulaActive
                                  })}
                                />
                              </div>
                              <ChangePriorityContainer
                                project={project}
                                showPriorityOptions={projectDropdownOpen}
                                setPriorityOptionsId={this.setPriorityOptionsId}
                                handleModalState={this.handleModalState}
                                editProjectInfo={this.editProjectOnClick}
                                popoverTarget={this.priorityOptionsRef}
                                checkArchivePermission={
                                  this.checkArchivePermission
                                }
                                checkDeletePermission={
                                  this.checkDeletePermission
                                }
                              />
                            </div>
                          </ProjectTopRowContainer>
                          <ColorPickerContainer
                            className="project-settings-box"
                            onClick={this.onColorPickerClick}
                          >
                            <StyledDot
                              style={projectTitleStyle}
                              projectId={project.id}
                              color={theme.colors.colorMediumGray1}
                            />
                            <ColorPicker
                              projectId={project.id}
                              id={project.id}
                              originType={ORIGIN_TYPE_STRINGS.PROJECT}
                              boundariesElement="window"
                              row
                              className="color-picker-container"
                              ref={this.setColorPickerRef}
                            />
                          </ColorPickerContainer>

                          <div className="item-middle single-row">
                            <StyledProjectTitle
                              id={`project-title-${project.id}`}
                              style={projectTitleStyle}
                              projectId={project.id}
                              color={theme.colors.colorSemiDarkGray4}
                              data-testid={`project-${project.id}-${project.title}`}
                            >
                              <Highlighter
                                highlightClassName="highlight"
                                highlightTag="span"
                                searchWords={searchWords}
                                textToHighlight={project && project.title}
                                unhighlightStyle={unhighlightStyle}
                              />
                            </StyledProjectTitle>
                          </div>

                          <span className="item-additional-info">
                            {project.description ? (
                              <Highlighter
                                highlightClassName="highlight"
                                highlightTag="span"
                                searchWords={searchWords}
                                textToHighlight={project && project.description}
                              />
                            ) : null}
                          </span>
                        </ProjectInnerRight>
                      </ColorPickerHoverArea>
                    </div>
                  </div>
                </div>
                <div className={canDragProject ? 'ico-drag-grip' : ''} />
              </th>
              <ProjectColumns
                handleExpand={this.handleExpand}
                editProjectOnClick={this.editProjectOnClick}
                project={project}
                columnOrder={columnOrder}
                me={me}
                isFiltering={isFiltering}
                shouldShowBatchOptions={shouldShowBatchOptions}
              />
            </tr>
          );
          // return snapshot.isDragging
          //   ? ReactDOM.createPortal(item, portal)
          //   : item
          return item;
        }}
      </Draggable>
    );
  }
}

Project.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  project: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  openEditProjectModal: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
  const getAmIOnProject = makeAmIOnProject();
  const mapStateToProps = (state, ownProps) => ({
    isActive: getIsActive(state),
    searchWords: getSearchWords(state),
    isOnProject: getAmIOnProject(state, ownProps),
    searchText: getSearchText(state),
    search: getSearch(state),
    sidebarSearchOpen: getSidebarSearchOpen(state),
    sidebarProjectsOpen: getSidebarProjectsOpen(state),
    selectedBoard: getSelectedBoard(state),
    addMembersForm: getAddMembersForm(state),
    selectedAccountIds: getSelectedAccountIds(state),
    matchedParams: getMatchedRouteParams(state),
    hasTimeEntries: state.timesheets.hasTimeEntries,
    loadingHasTimeEntries: state.timesheets.loadingHasTimeEntries,
    auth: getAuth(state),
    projects: getProjectsState(state),
    teamId: getSelectedTeamId(state),
    userPermissions: getUserPermissions(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchProjectById,
  fetchPriorities,
  clearSelectedProject,
  handleProjectItemState,
  saveSearchState,
  useSearchState,
  starProject,
  toggleSidebarSearch,
  closeSidebarProjects,
  openAddMembersForm,
  openProjectModal,
  setSelectedProject,
  setFromBoardViewNavText,
  navigateToProject,
  addMemberToProject,
  archiveUnarchiveProject,
  deletePortfolioProject,
  archivePortfolioProject,
  hardDeleteProject,
  editPortfolio
};

export default withRouter(
  withPermissionsCheck(
    connect(makeMapStateToProps, mapDispatchToProps)(Project)
  )
);
