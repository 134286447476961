import styled from 'styled-components';
import theme from 'theme';

export const Wrapper = styled.div``;

export const BatchActionToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BatchActionToggleIcon = styled.div<{ isBatchSelected: boolean }>`
  width: 18px;
  height: 18px;
  background: ${({ isBatchSelected }) =>
    isBatchSelected ? '#0074D9' : 'transparent'};
  border: ${({ isBatchSelected }) =>
    isBatchSelected ? '1px solid #0074d9' : '1px solid #b3b3b3'};
  border-radius: 3px;
  align-self: center;

  &:hover {
    border: 1px solid #0074d9;
    background: 'white';
  }

  ${Wrapper}:hover & {
    visibility: visible;
  }
  cursor: pointer;
`;

export const StyledSelectToggleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .white-background:after {
    background: ${theme.colors.colorPureWhite};
  }
`;
// Double border select
export const StyledSelectToggle = styled.div<{
  size?: number;
  borderRadius?: number;
  isDisabled?: boolean;
  isChecked: boolean;
  isPartiallyChecked?: boolean;
  showIndeterminateStateOnHover?: boolean;
}>`
  position: relative;
  height: ${(props) => props.size || 16}px;
  width: ${(props) => props.size || 16}px;
  border-radius: ${(props) => props.borderRadius || 3}px;
  border: 1px solid ${theme.colors.colorMediumGray1};
  background: ${({ isDisabled }) =>
    isDisabled ? 'transparent' : theme.colors.colorPureWhite};

  &:after {
    content: '';
    position: absolute;
    width: ${(props) => (props.size ? props.size - 4 : 12)}px;
    height: ${(props) => (props.size ? props.size - 4 : 12)}px;
    left: 1px;
    top: 1px;
    border-radius: 2px;
    background: ${({ isChecked, isDisabled, isPartiallyChecked }) =>
      isDisabled
        ? 'transparent'
        : isPartiallyChecked
        ? `linear-gradient(to bottom right, ${theme.colors.colorRoyalBlue} 50%, ${theme.colors.colorPureWhite} 50%)`
        : isChecked
        ? theme.colors.colorRoyalBlue
        : theme.colors.colorPureWhite};
  }

  &:hover {
    cursor: pointer;
  }
`;
export const ClickableArea = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  z-index: 1000;
`;
