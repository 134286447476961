/* eslint-disable no-undef */
import { useEffect, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import {
  getTeamCapacity,
  getAccountCapacities,
  getHolidaysArray
} from 'CapacityModule/selectors';
import {
  getSelectedTeamId,
  getOrderedSelectedTeamMembers,
  getOrderedFilterActiveProjects,
  getAccountFiltersFetched,
  getOOOProject
} from 'selectors';

import { BUDGET_RECORD_DATA_TYPES as DATA_TYPES } from 'appConstants';
import { SORT_BY } from 'appConstants/filters';
import { generateSortOrdersByAttribute } from 'appUtils/filters';
import { updateAccountFilterLocal } from 'actionCreators';
import {
  makeGetIsFetchingUtilizationReport,
  makeGetMemberUtilizationsOrder
} from 'UtilizationModule/selectors';
import {
  fetchBudgetRecords as fecthBudgetRecordsAction,
  clearBudgetRecordsState,
  fetchPositions,
  fetchTeamPositions
} from 'BudgetModule/actionCreators';
import { fetchMembersUtilizationReport } from 'UtilizationModule/actionCreators';
import {
  makeGetIsFetchingBudgetRecordsByFilterStateId,
  makeGetProjectIdsByAccounts,
  makeGetBudgetRecordsByFilterStateId
} from 'BudgetModule/selectors';

import AccountWorkloadSettingsModal from 'CapacityModule/components/WorkloadModal/AccountWorkloadSettingsModal';
import {
  fetchCapacities,
  fetchTeamCapacity,
  fetchHolidays
} from 'CapacityModule/actionCreators';
import { GENERATE_UTILIZATION_RANGE } from 'appUtils/projectPlannerUtils';
import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';

import { VIEW_BY, ZOOM_LEVELS } from 'appConstants/workload';

import UtilizationTable from './UtilizationTable';
import { filterBarsByDateRange } from 'appUtils/workplanDisplayUtils';

import moment from 'appUtils/momentConfig';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

import { NoDataText, StyledUtilizationTableContainer } from '../styles';
import useCustomSortAttributes from 'appUtils/hooks/useCustomSortAttributes';
import { deserializeId } from 'appUtils';
import LegendStyleTooltip from 'components/Tooltips/LegendStyleTooltip';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import {
  getIsPositionsLoaded,
  getIsFetchingPositions,
  getIsTeamPositionsLoaded,
  getIsFetchingTeamPositions
} from 'BudgetModule/selectors/positions';

const emptyArray = [];
const emptyObj = {};

// don't filter by empty arrays - no data will be returned
const getActiveFilterParams = (filter) =>
  pickBy(filter, (value) => Array.isArray(value) && value.length);

const getCustomFilterParams = (filter = {}) => {
  const { custom = {} } = filter;
  let customFilter = {};
  const {
    billable,
    showPto,
    showHolidays,
    sortOrder,
    sortAttribute,
    ...otherFilters
  } = custom;
  if (billable?.length === 1) {
    customFilter.billable = BILLABLE_FILTER_API_VALUES[billable[0]];
  }

  customFilter = {
    ...customFilter,
    // hides pto and holiday temporarily in utilization report
    // show_pto: showPto,
    // show_holidays: showHolidays,
    show_pto: false,
    show_holidays: false,
    ...GENERATE_UTILIZATION_RANGE[
      filter.custom?.range !== undefined
        ? filter.custom.range
        : ZOOM_LEVELS.WEEK
    ]({
      start: filter?.custom?.start_date,
      end: filter?.custom?.end_date
    }),
    sortOrder,
    sortAttribute
  };
  return customFilter;
};

const customSortAttributes = {
  members: {
    main: generateSortOrdersByAttribute({
      attribute: SORT_BY.billable_percentage // billable_percentage desc -> asc
    })
  }
};

/* ------------------------------------ - ----------------------------------- */

const UtilizationTableContainer = ({
  filterStateId,
  activeFilter,
  viewBy,
  pageName,
  isDashboardWidgetModal,
  teamId,
  filtersFetched,
  teamCapacity,
  accountCapacities,
  orderedTeamMembers,
  // accountIds,
  fetchCapacities,
  fetchHolidays,
  fetchTeamCapacity,
  holidays,
  OOOProject,
  sortOrder,
  sortAttribute,
  projectIdsToFetch,
  budgetRecordsFilterState,
  fetchBudgetRecords,
  clearBudgetRecordsState,
  useCapacityAsDenominator,
  includeRemainingCapacity,
  fetchPositions,
  fetchTeamPositions,
  isPositionsLoaded,
  isLoadingPositions,
  isTeamPositionsLoaded,
  isLoadingTeamPositions,
  isPrinting
}) => {
  const [topLevelFetchBlocked, setTopLevelFetchBlocked] = useState(false);
  const { isFetchingProjects } = useFetchUnloadedProjects({
    projectIds: projectIdsToFetch,
    shouldFetchPhases: false
  });

  const showUtilizationByProjects =
    activeFilter?.custom?.showUtilizationByProjects && !isDashboardWidgetModal;

  const { getSortParams, updateToNextSortOrder } = useCustomSortAttributes({
    customSortAttributes: customSortAttributes.members
  });

  const filterParams = useMemo(() => {
    const activeFilterParams = getActiveFilterParams(activeFilter);
    const customFilterParams = getCustomFilterParams(activeFilter);
    return {
      ...activeFilterParams,
      ...customFilterParams
    };
  }, [activeFilter]);

  const filterEquality = useMemo(
    () => JSON.stringify(filterParams),
    [filterParams]
  );
  const filteredAccountIds = useMemo(() => {
    return activeFilter.account_ids;
  }, [activeFilter.account_ids]);

  const updateSortAttributes = useCallback(() => {
    updateToNextSortOrder({ activeFilter });
  }, [activeFilter, updateToNextSortOrder]);

  // Dashboard container has these fetches
  useEffect(() => {
    if (!isDashboardWidgetModal && teamId) {
      fetchTeamCapacity({ teamId });
    }
  }, [fetchTeamCapacity, isDashboardWidgetModal, teamId]);
  useEffect(() => {
    if (!isDashboardWidgetModal && teamCapacity?.id) {
      fetchHolidays();
    }
  }, [fetchHolidays, isDashboardWidgetModal, teamCapacity]);
  useEffect(() => {
    if (!isDashboardWidgetModal) {
      if (teamId && filteredAccountIds.length) {
        fetchCapacities({
          accountIds: filteredAccountIds,
          teamId
        });
      } else if (teamId && !filteredAccountIds.length) {
        fetchCapacities({
          accountIds: orderedTeamMembers.map((member) => member.account?.id),
          teamId
        });
      }
    }
  }, [
    fetchCapacities,
    filteredAccountIds,
    isDashboardWidgetModal,
    orderedTeamMembers,
    teamId
  ]);

  const dispatchChain = useDispatchChain();

  const topRowRequestStatusId = `${filterStateId}-top`;
  const subRowRequestStatusId = `${filterStateId}-sub`;
  const { status: topRowRequestStatus } = useRequestStatus({
    requestStatusId: topRowRequestStatusId
  });
  const { status: subRowRequestStatus } = useRequestStatus({
    requestStatusId: subRowRequestStatusId
  });
  const {
    error: isTopRowRequestError,
    isLoading: isTopRowRequestLoading,
    isExecuting: isTopRowRequestExecuting,
    isSuccess: isTopRowRequestSuccess
  } = topRowRequestStatus || emptyObj;
  const {
    error: isSubRowRequestError,
    isLoading: isSubRowRequestLoading,
    isExecuting: isSubRowRequestExecuting,
    isSuccess: isSubRowRequestSuccess
  } = subRowRequestStatus || emptyObj;

  const budgetReportSortAttributes = useMemo(
    () => [
      {
        attribute: 'spent_hours_billable',
        direction: sortOrder
      },
      {
        attribute: 'spent_hours',
        direction: sortOrder
      }
    ],
    [sortOrder]
  );

  const fetchParams = useMemo(() => {
    const body = {
      offset: 0,
      limit: 50,
      team_id: teamId,
      ...(sortOrder &&
        sortAttribute && {
          sort_accounts: getSortParams({
            sortOrder,
            sortAttribute
          })
        }),
      all: false,
      ...filterParams
    };

    return body;
    // keep filterParams out of the array
  }, [teamId, sortOrder, sortAttribute, getSortParams, filterEquality]);

  const loadSubLevel = useCallback(
    (uid) => {
      const { itemType, itemId } = deserializeId(uid);
      const offset =
        budgetRecordsFilterState?.ordersByGroup?.[uid]?.length || 0;
      // BE pagination doesn't work when either all: true, all: false. Should omit it to have pagination
      const { all, ...fetchParamsToUse } = fetchParams;
      const fetchSubLevel = (params, options = emptyObj) => {
        fetchBudgetRecords({
          filterStateId,
          isUtilizationReport: true,
          params: {
            ...fetchParamsToUse,
            ...params,
            ...options,
            offset,
            hide_none_spent: true,
            sort_attributes: budgetReportSortAttributes
          },
          shouldFetchProjects: false,
          requestStatusId: subRowRequestStatusId
        });
      };

      // itemType is parent type
      switch (itemType) {
        case DATA_TYPES.ACCOUNT:
          // AccountProject data will be presnet if it is project breakdown view
          if (!showUtilizationByProjects) {
            fetchSubLevel({
              account_ids: [itemId],
              data_type: DATA_TYPES.ACCOUNT_PROJECT
            });
          }
          break;

        default:
          break;
      }
    },
    [
      budgetRecordsFilterState?.ordersByGroup,
      budgetReportSortAttributes,
      fetchBudgetRecords,
      fetchParams,
      filterStateId,
      showUtilizationByProjects,
      subRowRequestStatusId
    ]
  );

  // // filter out archived team memberships if not specifically using them in the filter
  const filteredTeamMembers = useMemo(() => {
    const membersToUse = orderedTeamMembers;
    const isFilteringByAccountId = filteredAccountIds.length;
    if (isFilteringByAccountId) {
      return membersToUse;
    } else {
      return membersToUse.filter((member) => !member.is_archived);
    }
  }, [filteredAccountIds.length, orderedTeamMembers]);

  const dateRangeFilter = useMemo(
    () => filterBarsByDateRange(fetchParams.start_date, fetchParams.end_date),
    [fetchParams.end_date, fetchParams.start_date]
  );

  const rangeWeekDayOccurences = useMemo(() => {
    const weekDaysInRange = Array.from(
      moment.range(fetchParams.start_date, fetchParams.end_date).by('day')
    ).map((day) => day.format('dddd').toLowerCase());
    return countBy(weekDaysInRange);
  }, [fetchParams.end_date, fetchParams.start_date]);

  const weekDayOccurencesMinusHolidays = useMemo(
    () =>
      dateRangeFilter(holidays).reduce(
        (weekDayHolidayOccurences, holiday) => {
          // usually one day but this prepares for ranged holidays
          const holidayWeekDaysInRange = Array.from(
            moment.range(holiday.start_date, holiday.end_date).by('day')
          ).map((day) => day.format('dddd').toLowerCase());

          holidayWeekDaysInRange.forEach(
            (day) => (weekDayHolidayOccurences[day] -= 1)
          );
          return weekDayHolidayOccurences;
        },
        { ...rangeWeekDayOccurences }
      ),
    [dateRangeFilter, holidays, rangeWeekDayOccurences]
  );
  const OOOProjectId = OOOProject?.id;

  const accountCapacityTotals = useMemo(
    () =>
      filteredTeamMembers.reduce((totalsHash, member) => {
        const accountCapacity = accountCapacities[member.account.id] || {};
        const OOOSubBars = dateRangeFilter(
          flatten(
            accountCapacity?.activity_phase_schedule_bars
              ?.filter((bar) => bar.project_id === OOOProjectId)
              .map((bar) => bar.bars)
          ) ?? []
        );
        const OOOweekDaysInRanges = flatten(
          OOOSubBars.map((bar) =>
            Array.from(
              moment.range(bar.start_date, bar.end_date).by('day')
            ).map((day) => day.format('dddd').toLowerCase())
          )
        );
        const outOfOfficeOccurences = countBy(OOOweekDaysInRanges);
        const accountTotalCapacity = Object.entries(
          weekDayOccurencesMinusHolidays
        ).reduce(
          (totalCapacity, [dateKey, numOccurrences]) =>
            totalCapacity +
            (numOccurrences - (outOfOfficeOccurences[dateKey] || 0) || 0) *
              (accountCapacity[dateKey] || 0),
          0
        );
        totalsHash[member.account.id] = accountTotalCapacity;
        return totalsHash;
      }, {}),
    [
      accountCapacities,
      dateRangeFilter,
      filteredTeamMembers,
      weekDayOccurencesMinusHolidays,
      OOOProjectId
    ]
  );

  const loadTopLevel = useCallback(
    (offset) => {
      const shouldFetchUtilization =
        viewBy === VIEW_BY.MEMBERS && fetchParams.account_ids?.length;
      if (shouldFetchUtilization) {
        const actionsToDispatch = [
          [
            fetchMembersUtilizationReport({
              ...fetchParams,
              shouldFetchBudgetRecords: showUtilizationByProjects,
              offset,
              filterStateId,
              initial: offset === 0
            }),
            ...(showUtilizationByProjects
              ? [
                  fecthBudgetRecordsAction({
                    params: {
                      start_date: fetchParams.start_date,
                      end_date: fetchParams.end_date,
                      team_id: teamId,
                      account_ids: fetchParams.account_ids,
                      data_type: DATA_TYPES.ACCOUNT_PROJECT,
                      all: true,
                      sort_attributes: budgetReportSortAttributes,
                      hide_none_spent: true
                    },
                    initial: offset === 0,
                    filterStateId,
                    isProjectBreakdownView: true,
                    isUtilizationReport: true,
                    shouldFetchProjects: false
                  })
                ]
              : emptyArray)
          ]
        ];

        dispatchChain(actionsToDispatch, {
          chainId: topRowRequestStatusId,
          initial: offset === 0,
          takeLatest: true,
          cleanupChainStatuses: true
        });

        setTopLevelFetchBlocked(false);
      } else {
        setTopLevelFetchBlocked(true);
      }
    },
    [
      budgetReportSortAttributes,
      dispatchChain,
      fetchParams,
      filterStateId,
      showUtilizationByProjects,
      teamId,
      topRowRequestStatusId,
      viewBy
    ]
  );

  useEffect(() => {
    // useCurrentRole used in member cell requires positions and teamPositions
    if (teamId && viewBy === VIEW_BY.MEMBERS) {
      if (!isLoadingPositions && !isPositionsLoaded) {
        fetchPositions({ teamId });
      }
      if (!isLoadingTeamPositions && !isTeamPositionsLoaded) {
        fetchTeamPositions({ teamId });
      }
    }
  }, [
    fetchPositions,
    fetchTeamPositions,
    isLoadingPositions,
    isLoadingTeamPositions,
    isPositionsLoaded,
    isTeamPositionsLoaded,
    teamId,
    viewBy
  ]);

  // Initial top-level loads and when filter changes.
  useEffect(() => {
    if (filtersFetched && teamId) {
      if (fetchParams.account_ids?.length) {
        loadTopLevel(0);
      }
    }
  }, [filtersFetched, loadTopLevel, teamId, fetchParams.account_ids?.length]);

  useEffect(() => {
    if (filterStateId && !showUtilizationByProjects) {
      // fetching budget records is a separate from fetching utilization which is used for sub rows
      // when showUtilizationByProjects is off and doesn't get cleared when options change
      clearBudgetRecordsState({ filterStateId });
    }
  }, [
    activeFilter.custom.range,
    showUtilizationByProjects,
    clearBudgetRecordsState,
    filterStateId
  ]);

  const loaded = filtersFetched;
  const isFetchingInitially =
    !loaded || (isTopRowRequestLoading && !isTopRowRequestSuccess);

  return (
    <StyledUtilizationTableContainer>
      {loaded && !isFetchingInitially && !filterParams.account_ids?.length ? (
        <NoDataText style={{ marginTop: 25 }}>Select members above</NoDataText>
      ) : (
        <UtilizationTable
          isPercent
          loaded={loaded}
          isFetching={
            isFetchingInitially ||
            isTopRowRequestExecuting ||
            // this should included to see budget report gets loaded when project breakdown is off
            isSubRowRequestLoading ||
            isSubRowRequestExecuting
          }
          isFetchingInitially={isFetchingInitially}
          accountCapacityTotals={accountCapacityTotals}
          activeFilter={activeFilter}
          isDashboardWidgetModal={isDashboardWidgetModal}
          viewBy={viewBy}
          filterStateId={filterStateId}
          loadTopLevel={loadTopLevel}
          loadSubLevel={loadSubLevel}
          updateSortAttributes={updateSortAttributes}
          sortOrder={sortOrder}
          pageName={pageName}
          totalCounts={budgetRecordsFilterState.totalCounts}
          ordersByGroup={budgetRecordsFilterState.ordersByGroup}
          showUtilizationByProjects={showUtilizationByProjects}
          useCapacityAsDenominator={useCapacityAsDenominator}
          includeRemainingCapacity={includeRemainingCapacity}
          isPrinting={isPrinting}
        />
      )}
      <AccountWorkloadSettingsModal />
      <LegendStyleTooltip />
    </StyledUtilizationTableContainer>
  );
};

const mapDispatchToProps = {
  fetchCapacities,
  fetchHolidays,
  fetchTeamCapacity,
  updateAccountFilterLocal,
  fetchBudgetRecords: fecthBudgetRecordsAction,
  clearBudgetRecordsState,
  fetchPositions,
  fetchTeamPositions
};

const makeMapStateToProps = () => {
  const getIsFetchingUtilizationReport = makeGetIsFetchingUtilizationReport();
  const getMemberUtilizationsOrder = makeGetMemberUtilizationsOrder();
  const getProjectIdsFromBudgetRecord = makeGetProjectIdsByAccounts();
  const getBudgetRecords = makeGetBudgetRecordsByFilterStateId();
  const getIsFetchingBudgetRecords =
    makeGetIsFetchingBudgetRecordsByFilterStateId();

  const mapStateToProps = (state, ownProps) => ({
    orderedTeamMembers: getOrderedSelectedTeamMembers(state, ownProps),
    orderedProjects: getOrderedFilterActiveProjects(state, ownProps),
    isFetchingUtilization: getIsFetchingUtilizationReport(state, ownProps),
    isFetchingAccountProjects: getIsFetchingBudgetRecords(state, ownProps),
    teamId: getSelectedTeamId(state),
    filtersFetched: getAccountFiltersFetched(state),
    teamCapacity: getTeamCapacity(state),
    accountCapacities: getAccountCapacities(state),
    accountIds: getMemberUtilizationsOrder(state, ownProps),
    holidays: getHolidaysArray(state),
    OOOProject: getOOOProject(state),
    sortOrder:
      ownProps.activeFilter?.custom?.sortOrder ||
      customSortAttributes.members.main[0].sortOrder,
    sortAttribute:
      ownProps.activeFilter?.custom?.sortAttribute ||
      customSortAttributes.members.main[0].sortAttribute,
    projectIdsToFetch: getProjectIdsFromBudgetRecord(state, ownProps),
    budgetRecordsFilterState: getBudgetRecords(state, ownProps),
    isPositionsLoaded: getIsPositionsLoaded(state),
    isLoadingPositions: getIsFetchingPositions(state),
    isTeamPositionsLoaded: getIsTeamPositionsLoaded(state),
    isLoadingTeamPositions: getIsFetchingTeamPositions(state)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UtilizationTableContainer);
