import EllipsisText from 'components/EllipsisText';
import styled from 'styled-components';
import { TableCellProps } from '../types';
import { isEntityRateCell } from '../utils';

export const NameCell = (props: TableCellProps) => {
  if (!isEntityRateCell(props)) return null;

  const {
    row: {
      original: { rateEntity }
    }
  } = props;

  return (
    <Cell>
      <EllipsisText>{rateEntity?.name}</EllipsisText>
    </Cell>
  );
};

const Cell = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;
