import React, { useState } from 'react';
import styled from 'styled-components';
import {
  InnerDropdownOption,
  StyledProjectDot,
  MemberName,
  StyledListItemContainer
} from 'views/projectPlanner/styles';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { getUserIsAdmin } from 'selectors';
import { useSelector } from 'react-redux';
import {
  StyledFilterRow,
  CustomCheckBoxContainer,
  CustomCheckBox,
  CheckBoxDiv,
  RowContent,
  StyledProjectDescription,
  StyledProjectTitle,
  ArchivedText,
  StyledFolderIcon,
  StyledAddRow,
  CheckboxMinusIcon,
  CircledRadioCheckBoxContainer,
  CircledRadioCheckBox
} from 'ReportsModule/components/styles';
import cn from 'classnames';
import theme from 'theme';
import { FILTER_RENDER_TEXT } from 'appConstants/timesheets';
import { Draggable } from 'react-beautiful-dnd';
import {
  StyledHeader,
  StyledKaratRight,
  leftValue,
  StyledSummary
} from './styles';
import { areEqual } from 'react-window';
import FilledArrow from 'icons/FilledArrow';
import LockIcon from 'icons/LockIcon';
import ProjectDropdown from 'views/layoutStructuralComponents/MyProjectSideBarContainer/ProjectDropdown';
import BoardDropdown from 'views/layoutStructuralComponents/MyProjectSideBarContainer/BoardDropdown';
import { StyledProjectRow } from 'views/layoutStructuralComponents/MyProjectSideBarContainer/ProjectRow';
import {
  StyledBoardRow,
  CollapseContainer,
  StyledCount
} from 'views/layoutStructuralComponents/MyProjectSideBarContainer/BoardRow';
import {
  CustomStyledSelectToggle,
  CustomClickableArea
} from 'FilterModule/components/FilterListsTable/styles';

export const KaratRight = styled(StyledKaratRight)`
  path {
    fill: ${theme.colors.colorLightGray15};
  }
  height: 11px;
  width: 8px;
  margin: 0;
`;

const Div = () => <div />;

const noop = () => {};

const sharedTooltipProps = {
  'data-delay-show': 800,
  'data-for': 'app-tooltip',
  'data-html': true,
  'data-effect': 'solid',
  'data-class': 'center mw-250'
};

const ProjectArchivedText = styled(ArchivedText)`
  font-size: 10px;
  left: 17px;
  top: 3px;
`;
const StyledShowArchivedRow = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  margin-left: ${({ marginLeft }) => marginLeft || '27px'};
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;
const StyledNoActiveProjectsRow = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-size: 12px;
  margin-left: 27px;
`;

const StyledPositionName = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  flex: 1;
`;

const DefaultRowLabel = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  margin-left: 10px;
`;

const StyledSideFilterCheckboxContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Summary = ({ list, setIsOpen }) => {
  const {
    listItems,
    renderSummaryItem,
    summaryNoun,
    numberOfSelectedItems,
    onlySelectedItemLabel
  } = list;
  if (numberOfSelectedItems === undefined) {
    return null;
  }

  let text = '';
  if (numberOfSelectedItems === 0) {
    text = 'All';
  } else if (numberOfSelectedItems === 1) {
    text =
      onlySelectedItemLabel ||
      renderSummaryItem(listItems.find((item) => list.isItemSelected?.(item)));
  } else {
    text = `${numberOfSelectedItems} ${summaryNoun}`;
  }
  return (
    <StyledSummary
      data-for="app-tooltip"
      data-tip={text}
      data-class="mw-250"
      isActive={numberOfSelectedItems !== 0}
    >
      {' '}
      {text}{' '}
    </StyledSummary>
  );
};

const Header = ({ children, index, item, setIsOpen }) => {
  return (
    <StyledHeader
      isOpen={item?.isOpen}
      onClick={() => setIsOpen({ uid: item.uid, value: !item.isOpen })}
      className="filter-row-header"
    >
      <span>{item.renderHeader?.()}</span>
      <StyledKaratRight
        isOpen={item?.isOpen}
        color={theme.colors.colorLightGray18}
        height={12}
      />

      <Summary list={item} setIsOpen={setIsOpen} />
    </StyledHeader>
  );
};

const PhaseRow = ({ children, index, item }) => {
  const { row, handleClick, isSelected } = item;
  return (
    <StyledFilterRow
      isSelected={isSelected}
      onClick={(e) => {
        handleClick(item);
      }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer isChecked={isSelected}>
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <RowContent>{row}</RowContent>
    </StyledFilterRow>
  );
};

const indeterminateStyles = {
  height: 2,
  background: '#808080'
};

const BoardRow = ({ children, index, item, setIsOpen, search }) => {
  // item is entire list
  const {
    isHeaderAllSelected,
    isHeaderSomeSelected,
    headerHandleClick,
    selectionIsDisabled,
    numSelected,
    project_count,
    displayCount
  } = item || {};
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = (dropdownOpen) => setDropdownOpen(dropdownOpen);

  return (
    <StyledBoardRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: 7 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          style={{
            height: 14,
            width: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: selectionIsDisabled ? 'hidden' : 'visible'
          }}
        >
          {!!isHeaderAllSelected && <CheckboxMinusIcon />}
          <CustomCheckBox
            isChecked={isHeaderAllSelected} // || isHeaderSomeSelected}
            // style={
            //   !isHeaderAllSelected && isHeaderSomeSelected
            //     ? indeterminateStyles
            //     : {}
            // }
          />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>

      <RowContent style={{ fontWeight: 600 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <StyledFolderIcon boardId={item.id} />
        </div>
        <span {...sharedTooltipProps} data-tip={item.name}>
          {item.name}
        </span>
      </RowContent>
      <CollapseContainer>
        <BoardDropdown
          isOpen={dropdownOpen}
          setIsOpen={toggleDropdown}
          board={item}
        />
        <StyledCount
          isHidden={search.projects?.length || dropdownOpen}
          isHighlighted={!!numSelected}
        >
          {numSelected}/{displayCount}
        </StyledCount>
        <KaratRight isOpen={item?.isOpen} />
      </CollapseContainer>
    </StyledBoardRow>
  );
};
const WorkGroupRow = ({ children, index, item, setIsOpen, search }) => {
  // item is entire list

  const {
    isHeaderAllSelected,
    isHeaderSomeSelected,
    headerHandleClick,
    numSelected,
    formattedWorkGroupMembers,
    selectionIsDisabled
  } = item || {};
  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={`${item.name}`}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: 7 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          style={{
            height: 14,
            width: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            visibility: selectionIsDisabled ? 'hidden' : 'visible'
          }}
        >
          {!!isHeaderAllSelected && <CheckboxMinusIcon />}
          <CustomCheckBox
            isChecked={isHeaderAllSelected} // || isHeaderSomeSelected}
            // style={
            //   !isHeaderAllSelected && isHeaderSomeSelected
            //     ? indeterminateStyles
            //     : {}
            // }
          />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <RowContent style={{ fontWeight: 600, marginLeft: 0 }}>
        <span>{item.name}</span>
      </RowContent>
      <div
        style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}
      >
        <span
          style={{
            margin: 4,
            visibility: search.members.length ? 'hidden' : 'visible',
            color: theme.colors.colorMediumGray5
          }}
        >
          <StyledCount isHighlighted={!!numSelected}>
            {numSelected}/{formattedWorkGroupMembers?.length}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const MemberRow = ({ children, index, item, me = {} }) => {
  const { row, handleClick, isSelected, list } = item;
  return (
    <StyledFilterRow
      isSelected={isSelected}
      onClick={(e) => {
        if (
          !list.listItemsSelectionIsDisabled ||
          (list.listItemsSelectionIsDisabled && isSelected)
        ) {
          handleClick(item);
        }
      }}
      {...sharedTooltipProps}
      data-tip={row.account?.name}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer
          isChecked={isSelected}
          isHidden={!isSelected && list.listItemsSelectionIsDisabled}
        >
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <MemberInitials
        size="medium"
        member={row}
        classes={cn('item-account selected', {
          'small-logged-member': row.account?.id === me?.id,
          'small-regular-member': row.account?.id !== me?.id
        })}
        idx={'idx-placeholder'}
        styles={{ marginLeft: 4 }}
        isOnTaskModal
      />
      <MemberName>{row.account?.name}</MemberName>
      {row?.is_archived && <ArchivedText>ARCHIVED</ArchivedText>}
    </StyledFilterRow>
  );
};

const PositionRow = ({ children, index, item, me = {} }) => {
  const {
    row,
    handleClick,
    isSelected,
    selectionIsDisabled,
    list: { isSingleSelect } = {}
  } = item;
  const CheckBoxContainer = isSingleSelect
    ? CircledRadioCheckBoxContainer
    : CustomCheckBoxContainer;
  const CheckBox = isSingleSelect ? CircledRadioCheckBox : CustomCheckBox;
  return (
    <StyledFilterRow
      isSelected={isSelected}
      onClick={(e) => {
        if (!selectionIsDisabled || (selectionIsDisabled && isSelected)) {
          handleClick(item);
        }
      }}
      {...sharedTooltipProps}
      data-tip={row?.name}
      style={{ paddingLeft: 14, paddingRight: 14 }}
    >
      <CheckBoxDiv key="1">
        <CheckBoxContainer
          isChecked={isSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          <CheckBox isChecked={isSelected} />
        </CheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName style={{ marginLeft: 10 }}>
        {row?.name}
      </StyledPositionName>
    </StyledFilterRow>
  );
};

const ClientRow = ({ children, index, item, me = {} }) => {
  const { row, handleClick, isSelected, selectionIsDisabled } = item;
  return (
    <StyledFilterRow
      isSelected={isSelected}
      onClick={(e) => {
        if (!selectionIsDisabled || (selectionIsDisabled && isSelected)) {
          handleClick(item);
        }
      }}
      {...sharedTooltipProps}
      data-tip={row?.title}
      style={{ paddingLeft: 14, paddingRight: 14 }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer
          isChecked={isSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName style={{ marginLeft: 10 }}>
        {row?.title}
      </StyledPositionName>
    </StyledFilterRow>
  );
};

const MemberByPositionRow = ({
  children,
  index,
  item,
  setIsOpen,
  me = {},
  search
}) => {
  const {
    row,
    isSelected,
    selectionIsDisabled,
    numSelected,
    isHeaderAllSelected,
    headerHandleClick,
    hasArchivedMembers
  } = item;
  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={item.name}
      style={{ paddingLeft: 14 }}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: -6 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          {!!isHeaderAllSelected && (
            <CheckboxMinusIcon style={{ left: '22px' }} />
          )}
          <CustomCheckBox isChecked={isHeaderAllSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName className="filterable-row-label">
        {item.name}
      </StyledPositionName>
      <div
        style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}
      >
        <span
          style={{
            margin: 4,
            color: theme.colors.colorMediumGray5,
            visibility: search.members.length ? 'hidden' : 'visible'
          }}
        >
          <StyledCount isHighlighted={!!numSelected}>
            {numSelected}/{item.listItems?.length - hasArchivedMembers}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const ProjectByMemberRow = ({
  item,
  setIsOpen,
  me = {},
  search,
  isProjectPhaseSelector,
  isSideFilter,
  itemsCount
}) => {
  const {
    row,
    isSelected,
    selectionIsDisabled,
    numSelected,
    isHeaderAllSelected,
    isHeaderSomeSelected,
    headerHandleClick,
    shouldHideCheckbox,
    member
  } = item;

  const isPartiallySelected = !isHeaderAllSelected && numSelected > 0;

  const itemSelected = itemsCount?.itemSelected;
  const selectionLimit = itemsCount?.selectionLimit;
  const isMaximumSelected = itemSelected && itemSelected >= selectionLimit;
  const showCheckedOnHover =
    !selectionIsDisabled &&
    (!isMaximumSelected || (isMaximumSelected && isPartiallySelected));
  const showIndeterminateStateOnHover =
    !selectionIsDisabled &&
    (isHeaderAllSelected ||
      (isPartiallySelected && itemSelected === selectionLimit));

  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={row?.name}
      style={{
        paddingLeft: 14,
        paddingRight: 14
      }}
    >
      {!isProjectPhaseSelector &&
        !shouldHideCheckbox &&
        (isSideFilter ? (
          <StyledSideFilterCheckboxContainer>
            <CustomClickableArea
              onClick={(e) => {
                e.stopPropagation();
                if (!selectionIsDisabled) {
                  headerHandleClick(item);
                }
              }}
              showIndeterminateStateOnHover={showIndeterminateStateOnHover}
              showCheckedOnHover={showCheckedOnHover}
              toggleSize={14}
              data-for="app-tooltip"
              data-tip={`Maximum of ${itemSelected} of ${selectionLimit} Selected.`}
              data-tip-disable={
                !!selectionIsDisabled ||
                Boolean(
                  !selectionLimit ||
                    !(
                      isMaximumSelected &&
                      (isPartiallySelected || !isHeaderSomeSelected)
                    )
                )
              }
            />
            <CustomStyledSelectToggle
              isChecked={isHeaderAllSelected}
              isPartiallyChecked={isPartiallySelected}
              isHidden={!!selectionIsDisabled}
              size={14}
            />
          </StyledSideFilterCheckboxContainer>
        ) : (
          <CheckBoxDiv
            key="1 "
            onClick={(e) => {
              e.stopPropagation();
              if (!selectionIsDisabled) {
                headerHandleClick(item);
              }
            }}
          >
            <CustomCheckBoxContainer
              isChecked={isSelected}
              style={{ visibility: selectionIsDisabled ? 'hidden' : 'visible' }}
            >
              {!!isHeaderAllSelected && (
                <CheckboxMinusIcon style={{ left: '18px' }} />
              )}
              <CustomCheckBox isChecked={isHeaderAllSelected} />
            </CustomCheckBoxContainer>
          </CheckBoxDiv>
        ))}

      <MemberInitials
        size="medium"
        member={member}
        classes={cn('item-account selected', {
          'small-logged-member': member?.id === me?.id,
          'small-regular-member': member?.id !== me?.id
        })}
        idx={'idx-placeholder'}
        styles={{ marginLeft: 4, marginRight: 4 }}
        isOnTaskModal
      />
      <StyledPositionName style={{ width: '108px' }}>
        {item.name}
      </StyledPositionName>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'center',
          position: 'absolute',
          right: '0px'
        }}
      >
        <span
          style={{
            margin: 4,
            color: theme.colors.colorMediumGray5,
            visibility: search.projects.length ? 'hidden' : 'visible'
          }}
        >
          <StyledCount
            isHighlighted={!!numSelected}
            style={{ visibility: selectionIsDisabled ? 'hidden' : 'visible' }}
          >
            {numSelected}/{item.fullProjectList?.length}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const SkillRow = ({ children, index, item, setIsOpen, me = {}, search }) => {
  const {
    row,
    isSelected,
    selectionIsDisabled,
    numSelected,
    isHeaderAllSelected,
    headerHandleClick,
    hasArchivedMembers
  } = item;
  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={item.name}
      style={{ paddingLeft: 14 }}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: -6 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          {!!isHeaderAllSelected && (
            <CheckboxMinusIcon style={{ left: '22px' }} />
          )}
          <CustomCheckBox isChecked={isHeaderAllSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName className="filterable-row-label">
        {item.name}
      </StyledPositionName>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            margin: 4,
            color: theme.colors.colorMediumGray5,
            visibility: search.members.length ? 'hidden' : 'visible'
          }}
        >
          <StyledCount isHighlighted={!!numSelected}>
            {numSelected}/{item.listItems?.length - hasArchivedMembers}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const OfficeRow = ({ item, setIsOpen, search }) => {
  const {
    isSelected,
    selectionIsDisabled,
    numSelected,
    isHeaderAllSelected,
    headerHandleClick
  } = item;
  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={item.name}
      style={{ paddingLeft: 14 }}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: -6 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          {!!isHeaderAllSelected && (
            <CheckboxMinusIcon style={{ left: '22px' }} />
          )}
          <CustomCheckBox isChecked={isHeaderAllSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName className="filterable-row-label">
        {item.name}
      </StyledPositionName>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            margin: 4,
            color: theme.colors.colorMediumGray5,
            visibility: search.members.length ? 'hidden' : 'visible'
          }}
        >
          <StyledCount isHighlighted={!!numSelected}>
            {numSelected}/{item.listItems?.length}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const RegionRow = ({ item, setIsOpen, search }) => {
  const {
    isSelected,
    selectionIsDisabled,
    numSelected,
    isHeaderAllSelected,
    headerHandleClick
  } = item;
  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={item.name}
      style={{ paddingLeft: 14 }}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: -6 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          {!!isHeaderAllSelected && (
            <CheckboxMinusIcon style={{ left: '22px' }} />
          )}
          <CustomCheckBox isChecked={isHeaderAllSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName className="filterable-row-label">
        {item.name}
      </StyledPositionName>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            margin: 4,
            color: theme.colors.colorMediumGray5,
            visibility: search.members.length ? 'hidden' : 'visible'
          }}
        >
          <StyledCount isHighlighted={!!numSelected}>
            {numSelected}/{item.listItems?.length}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const DisciplineRow = ({ item, setIsOpen, search }) => {
  const {
    isSelected,
    selectionIsDisabled,
    numSelected,
    isHeaderAllSelected,
    headerHandleClick
  } = item;
  return (
    <StyledFilterRow
      isSelected={isHeaderAllSelected}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      {...sharedTooltipProps}
      data-tip={item.name}
      style={{ paddingLeft: 14 }}
    >
      <CheckBoxDiv
        key="1"
        style={{ padding: 10, marginLeft: -6 }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectionIsDisabled) {
            headerHandleClick(item);
          }
        }}
      >
        <CustomCheckBoxContainer
          isChecked={isHeaderAllSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          {!!isHeaderAllSelected && (
            <CheckboxMinusIcon style={{ left: '22px' }} />
          )}
          <CustomCheckBox isChecked={isHeaderAllSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <StyledPositionName className="filterable-row-label">
        {item.name}
      </StyledPositionName>
      <div
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            margin: 4,
            color: theme.colors.colorMediumGray5,
            visibility: search.members.length ? 'hidden' : 'visible'
          }}
        >
          <StyledCount isHighlighted={!!numSelected}>
            {numSelected}/{item.listItems?.length}
          </StyledCount>
        </span>
        <StyledKaratRight isOpen={item?.isOpen} />
      </div>
    </StyledFilterRow>
  );
};

const ProjectRow = ({ children, index, item }) => {
  const { row, handleClick, isSelected, isSubList, selectionIsDisabled } = item;
  const [showToggle, setShowToggle] = useState(false);

  return (
    <StyledProjectRow
      isSelected={isSelected}
      onClick={(e) => {
        if (!selectionIsDisabled || (selectionIsDisabled && isSelected)) {
          handleClick(item);
        }
      }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer
          isChecked={isSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <InnerDropdownOption
        key="project-item"
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={`${row.title}${
          row.description ? ` - ${row.description}` : ''
        }`}
        data-delay-show={500}
        data-scroll-hide={true}
      >
        <StyledProjectDot
          color="#4a4a4a"
          id={row.id}
          style={{ margin: '0 4px' }}
        />
        <StyledProjectTitle isSelected={isSelected} isSubList={isSubList}>
          {row.is_archived && (
            <ProjectArchivedText>ARCHIVED</ProjectArchivedText>
          )}
          {row.title}
        </StyledProjectTitle>
        {row.description && (
          <StyledProjectDescription isSelected={isSelected} maxWidth={'170px'}>
            {row.description}
          </StyledProjectDescription>
        )}
      </InnerDropdownOption>
      <ProjectDropdown
        showToggle={showToggle}
        project={row}
        setShowToggle={setShowToggle}
      />
    </StyledProjectRow>
  );
};

const ActivityRow = ({ children, index, item }) => {
  const { row, handleClick, isSelected } = item;

  return (
    <StyledFilterRow
      isSelected={isSelected}
      onClick={(e) => {
        handleClick(item);
      }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer isChecked={isSelected}>
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <RowContent>
        <span>{row.title}</span>
      </RowContent>
    </StyledFilterRow>
  );
};
const BillableRow = ({ children, index, item }) => {
  const { row, handleClick, isSelected } = item;
  return (
    <StyledListItemContainer
      onClick={() => {
        handleClick(item);
      }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer isChecked={isSelected}>
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>

      <RowContent>{row}</RowContent>
    </StyledListItemContainer>
  );
};

const DefaultSelectableRow = ({ item }) => {
  const { label, handleClick, isSelected, selectionIsDisabled } = item;
  return (
    <StyledFilterRow
      isSelected={isSelected}
      onClick={(e) => {
        if (!selectionIsDisabled || (selectionIsDisabled && isSelected)) {
          handleClick(item);
        }
      }}
      {...sharedTooltipProps}
      data-tip={label}
      style={{ paddingLeft: 14, paddingRight: 14 }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer
          isChecked={isSelected}
          isHidden={!isSelected && selectionIsDisabled}
        >
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <DefaultRowLabel>{label}</DefaultRowLabel>
    </StyledFilterRow>
  );
};

const StatusRow = ({ children, index, item }) => {
  const { row, handleClick, isSelected } = item;
  return (
    <StyledListItemContainer
      onClick={() => {
        handleClick(item);
      }}
    >
      <CheckBoxDiv key="1">
        <CustomCheckBoxContainer isChecked={isSelected}>
          <CustomCheckBox isChecked={isSelected} />
        </CustomCheckBoxContainer>
      </CheckBoxDiv>
      <RowContent>
        <span>{FILTER_RENDER_TEXT[row]}</span>
      </RowContent>
    </StyledListItemContainer>
  );
};

const ShowArchivedRow = ({ children, index, item }) => {
  const { handleClick } = item;
  const isArchivedPhases = item.dataType === 'phase';
  return (
    <StyledShowArchivedRow
      onClick={() => {
        handleClick(item);
      }}
      data-testid={`row-${item.uid}`}
      marginLeft={isArchivedPhases ? '38px' : '27px'}
    >
      <FilledArrow />
      <span>
        {item.row.numArchived || 'Show'} Archived
        {isArchivedPhases ? ' Phases' : ''}
      </span>
    </StyledShowArchivedRow>
  );
};

const HideArchivedRow = ({ children, index, item }) => {
  const { handleClick } = item;
  return (
    <StyledShowArchivedRow
      onClick={() => {
        handleClick(item);
      }}
      data-testid={`row-${item.uid}`}
    >
      <div
        style={{ transform: 'rotate(180deg)', marginRight: 6, marginLeft: -6 }}
      >
        <FilledArrow />
      </div>
      <span>Hide Archived</span>
    </StyledShowArchivedRow>
  );
};

const AddRoleRow = ({ children, index, item, hasPermissionToAddStandard }) => {
  const { handleClick } = item;
  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={'Only Admins can Add or Edit Standard Roles.'}
      onClick={() => {
        hasPermissionToAddStandard ? handleClick(item) : noop();
      }}
      data-testid={`row-${item.uid}`}
    >
      <div style={{ marginRight: 10 }}> + </div>
      Add Role
      <div style={{ marginLeft: 5, marginTop: -2 }}>
        <LockIcon fill={theme.colors.colorRoyalBlue} />
      </div>
    </StyledAddRow>
  );
};

const AddDepartmentRow = ({
  children,
  index,
  item,
  hasPermissionToAddStandard
}) => {
  const { handleClick } = item;
  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={'Only Admins can Add or Edit Standard Departments.'}
      onClick={() => {
        hasPermissionToAddStandard ? handleClick(item) : noop();
      }}
      data-testid={`row-${item.uid}`}
    >
      <div style={{ marginRight: 10 }}> + </div>
      Add Department
      <div style={{ marginLeft: 5, marginTop: -2 }}>
        <LockIcon fill={theme.colors.colorRoyalBlue} />
      </div>
    </StyledAddRow>
  );
};

const AddSkillRow = ({ children, index, item, hasPermissionToAddStandard }) => {
  const { handleClick } = item;
  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={'Only Admins can Add or Edit Standard Skills.'}
      onClick={() => {
        hasPermissionToAddStandard ? handleClick(item) : noop();
      }}
      data-testid={`row-${item.uid}`}
    >
      <div style={{ marginRight: 10 }}> + </div>
      Add Skill
      <div style={{ marginLeft: 5, marginTop: -2 }}>
        <LockIcon fill={theme.colors.colorRoyalBlue} />
      </div>
    </StyledAddRow>
  );
};
const AddOfficeRow = ({
  children,
  index,
  item,
  hasPermissionToAddStandard
}) => {
  const { handleClick } = item;
  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={'Only Admins can Add or Edit Standard Offices.'}
      onClick={() => {
        hasPermissionToAddStandard ? handleClick(item) : noop();
      }}
      data-testid={`row-${item.uid}`}
    >
      <div style={{ marginRight: 10 }}> + </div>
      Add Office
      <div style={{ marginLeft: 5, marginTop: -2 }}>
        <LockIcon fill={theme.colors.colorRoyalBlue} />
      </div>
    </StyledAddRow>
  );
};

const AddRegionRow = ({
  children,
  index,
  item,
  hasPermissionToAddStandard
}) => {
  const { handleClick } = item;
  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={'Only Admins can Add or Edit Standard Regions.'}
      onClick={() => {
        hasPermissionToAddStandard ? handleClick(item) : noop();
      }}
      data-testid={`row-${item.uid}`}
    >
      <div style={{ marginRight: 10 }}> + </div>
      Add Region
      <div style={{ marginLeft: 5, marginTop: -2 }}>
        <LockIcon fill={theme.colors.colorRoyalBlue} />
      </div>
    </StyledAddRow>
  );
};

const AddDisciplineRow = ({
  children,
  index,
  item,
  hasPermissionToAddStandard
}) => {
  const { handleClick } = item;
  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={'Only Admins can Add or Edit Standard Disciplines.'}
      onClick={() => {
        hasPermissionToAddStandard ? handleClick(item) : noop();
      }}
      data-testid={`row-${item.uid}`}
    >
      <div style={{ marginRight: 10 }}> + </div>
      Add Discipline
      <div style={{ marginLeft: 5, marginTop: -2 }}>
        <LockIcon fill={theme.colors.colorRoyalBlue} />
      </div>
    </StyledAddRow>
  );
};

const NoActiveProjectsRow = ({ item }) => {
  return (
    <StyledNoActiveProjectsRow data-testid={`row-${item.uid}`}>
      <span>No Active Projects</span>
    </StyledNoActiveProjectsRow>
  );
};

export const components = {
  Header,
  MemberRow,
  PhaseRow,
  BoardRow,
  WorkGroupRow,
  ProjectRow,
  ActivityRow,
  BillableRow,
  StatusRow,
  OfficeRow,
  RegionRow,
  DisciplineRow,
  ShowArchivedRow,
  HideArchivedRow,
  NoActiveProjectsRow,
  PositionRow,
  ClientRow,
  SkillRow,
  MemberByPositionRow,
  AddRoleRow,
  AddSkillRow,
  AddOfficeRow,
  AddRegionRow,
  AddDisciplineRow,
  AddDepartmentRow,
  ProjectByMemberRow,
  ProjectBudgetStatusRow: DefaultSelectableRow
};

// eslint-disable-next-line react/display-name
export const DraggableFilterRow = React.memo(({ index, style, data }) => {
  const { items, enableDrag } = data;
  const item = items[index];
  if (!item) {
    return null;
  }
  const { draggableId, isDragDisabled, isHeader, sectionType } = item;

  if (isDragDisabled || !enableDrag) {
    return <FilterRow index={index} style={style} data={data} />;
  }

  if (__DEV__ && !draggableId && !isDragDisabled) {
    console.log(item);
    throw new Error(
      `row at index ${index} is missing a draggableId. More row infomation:
      rowId: ${item.id}
      draggableId: ${draggableId}
      `
    );
  }

  return (
    <Draggable
      index={index}
      draggableId={`${draggableId}`}
      isDragDisabled={isDragDisabled}
      key={draggableId}
    >
      {(draggableProvided) => (
        <FilterRow
          provided={draggableProvided}
          index={index}
          data={data}
          style={style}
        />
      )}
    </Draggable>
  );
}, areEqual);

const FilterRow = ({
  provided = { draggableProps: {}, dragHandleProps: {} },
  data,
  index,
  style,
  isDragging
}) => {
  const isAdmin = useSelector(getUserIsAdmin);
  const {
    items, // This is the flat list
    setIsOpen,
    setSearch,
    setEngaged,
    setFilterTabs,
    search,
    engaged,
    filterTabs,
    me,
    leftOffset,
    allCollapsed,
    showDragGrips,
    loadPhasesByProjectIds,
    isProjectPhaseSelector,
    clearSearch,
    isSideFilter,
    itemsCount
  } = data;
  const item = items[index];
  if (!item) {
    return null;
  }
  const {
    component,
    isCustom,
    itemLeft,
    headerLeftOffset,
    filterListItemContainerClass
  } = item;
  const El = isCustom ? component : components[component];
  const list = isCustom ? item.list : null; // prevent passing the entire list to many items for performance reasons
  let left = leftOffset !== undefined ? leftOffset : leftValue || `0px`;
  if (item.isHeader && headerLeftOffset !== undefined) {
    left = headerLeftOffset;
  }
  const paddingLeft = itemLeft !== undefined ? itemLeft : data.leftPadding || 0;

  const hasPermissionToAddStandard = isAdmin;

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...style,
        paddingLeft,
        left: left,
        width: `calc(100% - ${left})`,
        display: 'flex',
        alignItems: 'center',
        outline: 'none',
        ...provided?.draggableProps.style
      }}
      className={
        'filter-list-item-container' +
        (filterListItemContainerClass ? ` ${filterListItemContainerClass}` : '')
      }
    >
      <El
        setIsOpen={setIsOpen}
        setSearch={setSearch}
        setEngaged={setEngaged}
        setFilterTabs={setFilterTabs}
        search={search}
        engaged={engaged}
        filterTabs={filterTabs}
        item={item}
        me={me}
        list={list}
        allCollapsed={allCollapsed}
        showDragGrips={showDragGrips}
        isDragging={isDragging}
        loadPhasesByProjectIds={loadPhasesByProjectIds}
        isProjectPhaseSelector={isProjectPhaseSelector}
        clearSearch={clearSearch}
        hasPermissionToAddStandard={hasPermissionToAddStandard}
        isSideFilter={isSideFilter}
        itemsCount={itemsCount}
      />
    </div>
  );
};

export default React.memo(FilterRow, areEqual);
