import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { getPhaseTemplateModalOpen, getPhaseTemplateModalId } from 'selectors';
import { closePhaseTemplateModal } from 'actionCreators';
import GlobalAddEditHeader from './GlobalAddEditHeader';
import PhaseTemplateModalBody from './PhaseTemplateModalBody';

class PhaseTemplateModal extends React.Component {
  state = {
    isRemove: false
  };

  handleClose = () => {
    const { closePhaseTemplateModal } = this.props;
    closePhaseTemplateModal({});
  };

  setRemove = () => this.setState({ isRemove: true });
  clearRemove = () => this.setState({ isRemove: false });

  render() {
    const { isMilestone, open, id } = this.props;
    const { isRemove } = this.state;
    return (
      <Modal
        isOpen={open}
        toggle={this.handleClose}
        className="phase-modal"
        zIndex="2001"
        onClosed={this.clearRemove}
      >
        <GlobalAddEditHeader
          handleClose={this.handleClose}
          isRemove={isRemove}
          copy={isMilestone ? 'Milestone' : 'Phase of Work'}
          id={id}
        />
        <PhaseTemplateModalBody
          isMilestone={isMilestone}
          isRemove={isRemove}
          setRemove={this.setRemove}
          clearRemove={this.clearRemove}
        />
      </Modal>
    );
  }
}

PhaseTemplateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closePhaseModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  open: getPhaseTemplateModalOpen(state),
  id: getPhaseTemplateModalId(state)
});

const mapDispatchToProps = {
  closePhaseTemplateModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PhaseTemplateModal);
