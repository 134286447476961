import styled from 'styled-components';
import { useAppSelector } from 'reduxInfra/hooks';
import { MemberCapacityGroupListItem } from '../types';
import theme from 'theme';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';
import { Capacity } from 'CapacityModule/models';

type BaseCapacityCellProps = {
  row: {
    original: MemberCapacityGroupListItem;
  };
  dayOfWeek: keyof Capacity;
};

const BaseCapacityCell = ({ row, dayOfWeek }: BaseCapacityCellProps) => {
  const { capacityGroup, accountId, requestCreateCustomPolicy } = row.original;
  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const teamMember = teamMembersHash[accountId];

  if (!teamMember) return <></>;

  // explicitly not show capacity if value is 0
  const capacityValue = capacityGroup.capacity[dayOfWeek] || '';

  return (
    <RootContainer
      $isArchived={teamUtils.getIsArchived(teamMember)}
      onClick={() =>
        requestCreateCustomPolicy({
          accountId,
          teamMembershipId: teamMember.id
        })
      }
    >
      {capacityValue}
    </RootContainer>
  );
};

const SundayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="sunday" />;
};

const MondayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="monday" />;
};

const TuesdayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="tuesday" />;
};

const WednesdayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="wednesday" />;
};

const ThursdayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="thursday" />;
};

const FridayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="friday" />;
};

const SaturdayCell = ({ row }) => {
  return <BaseCapacityCell row={row} dayOfWeek="saturday" />;
};

export const MembersCapacityTableCapacityCells = {
  SundayCell,
  MondayCell,
  TuesdayCell,
  WednesdayCell,
  ThursdayCell,
  FridayCell,
  SaturdayCell
};

const RootContainer = styled.div<{ $isArchived: boolean }>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${theme.colors.colorLightGray25};
  font-size: 14px;
`;
