import { useCallback, useMemo } from 'react';
import { getAllActivityRowInfo } from 'selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  FormattedActivityPhase,
  GetActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
  GetFormattedActivityPhaseByPhaseIdAndActivityId,
  GetActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId
} from './types';
import keyBy from 'lodash/keyBy';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { getProjectHash } from 'ProjectsModule/selectors';
import { Activity } from 'models/activity';
import { ActivityPhaseMembership } from 'ActivityPhaseModule/models/activityPhaseMembership';

const emptyObj = {};

const useActivityPhases = ({ projectId }: { projectId: Nullable<number> }) => {
  const activityHash: Record<number, Activity> = useAppSelector(
    getAllActivityRowInfo
  );
  const projectHash = useAppSelector(getProjectHash);
  const phaseHash = useAppSelector(getFlatPhasesHash);

  const project = projectId ? projectHash[projectId] : undefined;

  const formattedActivityPhaseByPhaseIdAndActivityIdHash: Record<
    number,
    Record<number, FormattedActivityPhase>
  > = useMemo(() => {
    if (!project) return emptyObj;

    return project.phase_orders.reduce((acc, phaseId) => {
      const phase = phaseHash[phaseId];
      if (phase) {
        phase.activity_phases.forEach((activityPhase) => {
          const activity = activityHash[activityPhase.activity_id];

          if (activity) {
            acc[phaseId] = {
              ...acc[phaseId],
              [activityPhase.activity_id]: {
                ...activityPhase,
                activityTitle: activity.title
              }
            };
          }
        });
      }
      return acc;
    }, {});
  }, [activityHash, phaseHash, project]);

  const activityPhaseMembershipByActivityPhaseIdAndMemberBudgetIdHash: Record<
    number,
    Record<number, ActivityPhaseMembership>
  > = useMemo(() => {
    if (!project) return emptyObj;

    return project.phase_orders.reduce((acc, phaseId) => {
      const phase = phaseHash[phaseId];
      if (phase) {
        phase.activity_phases.forEach((activityPhase) => {
          acc[activityPhase.id] = keyBy(
            activityPhase.activity_phase_memberships,
            'member_budget_id'
          );
        });
      }
      return acc;
    }, {});
  }, [phaseHash, project]);

  const activityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipIdHash: Record<
    number,
    Record<number, ActivityPhaseMembership>
  > = useMemo(() => {
    if (!project) return emptyObj;

    return project.phase_orders.reduce((acc, phaseId) => {
      const phase = phaseHash[phaseId];
      if (phase) {
        phase.activity_phases.forEach((activityPhase) => {
          acc[activityPhase.id] = keyBy(
            activityPhase.activity_phase_memberships,
            'id'
          );
        });
      }
      return acc;
    }, {});
  }, [phaseHash, project]);

  const getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId: GetActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId =
    useCallback(
      ({ activityPhaseId, memberBudgetId }) => {
        return activityPhaseMembershipByActivityPhaseIdAndMemberBudgetIdHash[
          activityPhaseId
        ]?.[memberBudgetId];
      },
      [activityPhaseMembershipByActivityPhaseIdAndMemberBudgetIdHash]
    );

  const getActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId: GetActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId =
    useCallback(
      ({ activityPhaseId, activityPhaseMembershipId }) => {
        return activityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipIdHash[
          activityPhaseId
        ]?.[activityPhaseMembershipId];
      },
      [activityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipIdHash]
    );

  const getFormattedActivityPhaseByPhaseIdAndActivityId: GetFormattedActivityPhaseByPhaseIdAndActivityId =
    useCallback(
      ({ phaseId, activityId }) => {
        return formattedActivityPhaseByPhaseIdAndActivityIdHash[phaseId]?.[
          activityId
        ];
      },
      [formattedActivityPhaseByPhaseIdAndActivityIdHash]
    );

  return {
    getFormattedActivityPhaseByPhaseIdAndActivityId,
    getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
    getActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId,
    activityHash
  };
};

export default useActivityPhases;
