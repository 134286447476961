import { ComponentProps } from 'react';
import styled from 'styled-components';
import WorkplanCalendarPanelCheckboxRow, {
  WorkplanCalendarOptionValues,
  WorkplanCalendarHandleChange
} from './WorkplanCalendarPanelCheckboxRow';
import WorkplanCalendarPanelSelectType, {
  WorkplanCalendarPanelSelectTypeProps
} from './WorkplanCalendarPanelSelectType';
import { BaseWorkPlanDependencyList } from 'components/Dependency';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { DEPENDABLE_TYPES } from 'components/Dependency/constants';
import { DependencyList } from 'components/Dependency/DependencyList';

interface WorkplanCalendarPanelProps {
  selectTypeProps: WorkplanCalendarPanelSelectTypeProps;
  checkboxRowValueProps: WorkplanCalendarOptionValues;
  handleCalendarCheckboxChange: WorkplanCalendarHandleChange;
  dependencySetterProps: Omit<
    ComponentProps<typeof DependencyList>,
    'dependableItems' | 'baseDependableTypeLabel' | 'targetDependableTypeLabel'
  >;
  baseItem: Phase | undefined;
}

const WorkplanCalendarPanel = ({
  selectTypeProps,
  checkboxRowValueProps,
  handleCalendarCheckboxChange,
  dependencySetterProps,
  baseItem
}: WorkplanCalendarPanelProps) => {
  const phaseDependableItem = baseItem?.start_date
    ? {
        dependableId: baseItem.id,
        dependableType: DEPENDABLE_TYPES.PHASE,
        label: !baseItem.is_like_default ? baseItem.name : 'Project Schedule',
        startDate: baseItem.start_date,
        endDate: baseItem.end_date
      }
    : undefined;

  const dependableItems = phaseDependableItem ? [phaseDependableItem] : [];

  return (
    <WorkplanCalendarPanelContainer>
      <WorkplanCalendarPanelSelectType {...selectTypeProps} />
      <WorkplanCalendarPanelCheckboxRow
        checkboxRowValueProps={checkboxRowValueProps}
        handleChange={handleCalendarCheckboxChange}
      />
      <BaseWorkPlanDependencyList
        dependencyListProps={dependencySetterProps}
        dependableItems={dependableItems}
        lockTargetOption={true}
        defaultTargetItem={phaseDependableItem}
      />
    </WorkplanCalendarPanelContainer>
  );
};

const WorkplanCalendarPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -22.5px;
  align-items: center;
  padding: 0 13px;
  z-index: 1;
`;

export default WorkplanCalendarPanel;
