import React, { lazy, Suspense } from 'react';

const IntegrationsContainer = lazy(() => import('./IntegrationsContainer'));
const LoadIntegrationsContainer = (props) => (
  <Suspense fallback={<div />}>
    <IntegrationsContainer {...props} />
  </Suspense>
);

export default LoadIntegrationsContainer;
