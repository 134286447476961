import styled from 'styled-components';
import theme from 'theme';

const TMDot = styled.div`
  height: 10px;
  width: 10px;
  border: solid 2px ${theme.colors.colorPureWhite};
  border-radius: 50%;
  background-color: ${theme.colors.colorRemainingTeal};
  position: absolute;

  ${(props) =>
    props.overwriteDotPosition
      ? `right: -1px; top: -1px;`
      : `right: -2px; top: -2px;`}
`;
// overwrite bc in certain places with styled components it's overwritten to be -1px. need this for it to be correctly positioned in manage project members dropdown

export default TMDot;
