import React, { useRef, useState, useEffect, useMemo } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import QBDownArrow from 'icons/QBDownArrow';
import {
  updatePhase,
  createPhase,
  openPhaseModal,
  fetchPhaseTemplates
} from 'actionCreators';
import { getPhaseTemplatesList } from 'selectors';
import {
  Dropdown,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer,
  ToggleContainer,
  ToggleOption,
  TextButton
} from 'components/styles';
import HelpIcon from 'images/help-icon-2.svg';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import PhaseOfWorkInput from 'BudgetModule/components/PhaseOfWorkInput';
import { StyledPhaseOfWorkInput } from 'BudgetModule/components/styles';
import { makeGetOwnPhase, makeGetActivePhases } from 'BudgetModule/selectors';
import PhaseArchiveConfirmModal from './PhaseArchiveConfirmModal';
import DollarIcon from 'icons/DollarIcon';
import ReactTooltip from 'react-tooltip';
import PhasesOfWorkDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/PhasesOfWorkDropdown';
import {
  PHASE_BUDGET_STATUS_DISPLAY,
  PHASE_BUDGET_STATUS_OPTIONS
} from 'appConstants/budgetStatuses';
import { getPhaseDisplayBudgetStatus } from 'appUtils/phaseDisplayUtils';
import { CONTRACT_TYPES } from 'BudgetModule/components/BudgetTable/constants';
import BillableToggle from './BillableToggle';
import { formatCurrencySymbol } from 'components/FormatCurrency/utils';
import { FormattedCurrencyByProject } from 'components/FormatCurrency';
import { getArchivedPhaseEditTooltipProps } from 'BudgetModule/components/BudgetTable/utils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const noop = () => {};

export const StyledQBDownArrow = styled(QBDownArrow)`
  position: relative;
  top: 1px;
  path {
    fill: ${(props) =>
      props.fill ||
      (props.muted
        ? theme.colors.colorLightGray15
        : theme.colors.colorMediumGray9)} !important;
  }
`;

export const PhaseDropdownArrow = styled(QBDownArrow)`
  position: absolute;
  top: 44px;
  right: 19px;
  width: 14px;
  height: 10px;
  path {
    fill: ${theme.colors.colorMediumGray9};
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  height: 34px;
  &.btn-secondary {
    background: ${theme.colors.colorTranslucentGray4}!important;
    color: ${theme.colors.colorMediumGray9}!important;
    color: ${(props) =>
      props.muted
        ? theme.colors.colorLightGray15
        : theme.colors.colorMediumGray9}!important;
    width: 100%;
    border: 1px solid ${theme.colors.colorLightGray6}!important;
    border-radius: 6px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
  }
  &.btn-secondary:active,
  &.btn-secondary:focus {
    border: 1px solid ${theme.colors.colorMediumGray9}!important;
  }
`;

export const StyledBudgetDropdownToggle = styled(DropdownToggle)`
  &.btn-secondary {
    background: ${({ $bgColor }) =>
      $bgColor || theme.colors.colorRoyalBlue} !important;
  }
    color: ${theme.colors.colorPureWhite}!important;
    width: 100%;
    border: 1px solid ${theme.colors.colorRoyalBlue}!important;
    border-radius: 6px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
  }
  &.btn-secondary:active,
  &.btn-secondary:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue}!important;
  }
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledModalTitle = styled.div`
  font-size: 22px;
  color: black;
  font-weight: 600;
`;
const StyledModalBody = styled.div`
  position: relative;
  padding: 35px 0 23px;
  ${StyledPhaseOfWorkInput} {
    color: ${theme.colors.colorMediumGray9};
    background: ${theme.colors.colorTranslucentGray4};
    border: 1px solid ${theme.colors.colorLightGray6};
    border-radius: 6px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 7px;
    padding-left: 17px;
    ${(props) =>
      !props.nameIsEditable &&
      `
        padding-right: 36px;
        pointer-events: none;
    `}
  }
`;
const StyledModalFooter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PaddingContainer = styled.div`
  padding: 0 1px;
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(NumberFormat)`
  background: ${theme.colors.colorTranslucentGray4};
  color: ${theme.colors.colorMediumGray9};
  width: 100%;
  border: 1px solid ${theme.colors.colorLightGray6};
  border-radius: 6px;
  text-align: right;
  font-size: 15px;
  height: 34px;
  line-height: 2.5;
  text-overflow: ellipsis;
  &::placeholder {
    text-align: left;
    color: ${theme.colors.colorLightGray15};
  }
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
    z-index: 1;
  }
`;
const FeeInput = styled(NumberFormat)`
  text-align: left;
  background-color: transparent;
  width: 100%;
  border: none;
  color: ${theme.colors.colorMediumGray9};
  &::placeholder {
    color: ${theme.colors.colorMediumGray9};
  }
  &:focus::placeholder {
    color: ${theme.colors.colorMediumGray16};
  }
`;

const CurrencyInputContainer = styled.div`
  background: ${theme.colors.colorTranslucentGray4};
  width: 100%;
  border: 1px solid ${theme.colors.colorLightGray6};
  border-radius: 6px;
  font-size: 15px;
  height: 34px;
  line-height: 2.5;
  text-overflow: ellipsis;
  &::placeholder {
    text-align: left;
    color: ${theme.colors.colorLightGray15};
  }
  &:focus-within {
    border: 1px solid ${theme.colors.colorRoyalBlue};
    z-index: 2;
  }

  display: flex;
  z-index: 1;

  ${({ hasPercentage }) =>
    hasPercentage &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}
  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.65;  
  `}
`;

const PercentOfFeeInutContainer = styled.div`
  border: 1px solid ${theme.colors.colorLightGray6};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: 2px;
  &:focus-within {
    border: 1px solid ${theme.colors.colorRoyalBlue};
    z-index: 1;
  }
`;

const PhaseNumberInput = styled(StyledInput)`
  text-align: left;
  padding-left: 10px;
  color: ${theme.colors.colorLightGray15};
`;

const ProfitCenterInput = styled(StyledInput)`
  padding-left: 10px;
  text-align: left;
  margin-bottom: 20px;
`;
// const ProfitPercentageInput = styled(StyledInput)`
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   padding-right: 21px;
//   & + .percent-sign {
//     color: ${theme.colors.colorCalendarGray};
//     right: 6px;
//     top: 23px;
//     position: absolute;
//     z-index: 2;
//   }
// `;
const EstPercentageInput = styled(StyledInput)`
  border: none;
  padding: none;
  padding-right: 21px;
  height: 32px;
  & + .percent-sign {
    color: ${theme.colors.colorMediumGray9};
    right: 6px;
    top: 25px;
    position: absolute;
    z-index: 2;
  }
  &::placeholder {
    text-align: right;
    color: ${theme.colors.colorMediumGray9};
  }
  &:focus::placeholder {
    color: ${theme.colors.colorMediumGray16};
  }
  &:disabled {
    opacity: 0.65;
    & + .percent-sign {
      opacity: 0.65;
    }
  }
  &:focus {
    border: unset;
  }
`;

const EstimatedCostInput = styled(NumberFormat)`
  text-align: left;
  background-color: transparent;
  width: 100%;
  border: none;
  color: ${theme.colors.colorMediumGray9};
  &::placeholder {
    color: ${theme.colors.colorMediumGray9};
  }
  &:focus::placeholder {
    color: ${theme.colors.colorMediumGray16};
  }
`;
const BottomInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const StyledLabel = styled.label`
  color: ${theme.colors.colorCalendarGray};
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 5px;
`;
export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: ${(props) => props.width || 120}px;
  ${({ fitContent }) =>
    fitContent &&
    `
      width: auto;
      .btn-secondary {
        gap: 10px;
      }
`}
`;
export const StyledDollarSign = styled.div`
  font-size: 15px;
  color: ${theme.colors.colorCalendarGray};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
`;
const StyledDropdownItem = styled(DropdownItem)`
  padding: 5px 10px;
  font-size: 13px;
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  min-width: 120px;
`;

const DeleteButton = styled(TextButtonWithBorder)`
  border-color: transparent;
  &:hover {
    border-color: ${theme.colors.colorPaleRed};
    color: ${theme.colors.colorDeleteRed};
    filter: unset;
  }
  &:focus {
    border-color: ${theme.colors.colorPaleRed};
    color: ${theme.colors.colorDeleteRed};
    filter: unset;
  }
`;

const PhaseNameContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
`;
const DropdownArrow = styled.div``;

const FEE_TYPE_OPTIONS = [
  { value: 'Fixed Fee', label: 'Fixed Fee' },
  { value: 'Hourly', label: 'Hourly' },
  { value: 'Internal', label: 'Internal' }
];

const FIXED_BUDGET_WITH_OPTIONS = {
  spent_and_planned: 'Total Spent + Planned Time',
  work_category_estimates: 'Total Work Category Budget',
  phase_estimate: 'Enter Budget',
  member_estimates: 'Total Member Time Budget',
  phase_total: 'Use Fee as Budget'
};

const HOURLY_BUDGET_WITH_OPTIONS = {
  spent_and_planned: 'Total Spent + Planned Time',
  work_category_estimates: 'Total Work Category Budget',
  phase_estimate: 'Enter Budget',
  member_estimates: 'Total Member Time Budget'
};

const INTERNAL_BUDGET_WITH_OPTIONS = {
  spent_and_planned: 'Total Spent + Planned Time',
  work_category_estimates: 'Total Work Category Budget',
  phase_estimate: 'Enter Budget',
  member_estimates: 'Total Member Time Budget'
};

/* ----------------------------------------------------------------------- */

const FeeModal = ({
  phase,
  handleClose,
  createPhase,
  updatePhase,
  openPhaseModal,
  phaseTemplates,
  activePhasesHash
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [budgetWithDropdownOpen, setBudgetWithDropdownOpen] = useState(false);
  const [budgetStatusDropdownOpen, setBudgetStatusDropdownOpen] =
    useState(false);
  const [total, setTotal] = useState(+phase.total || '');
  const [feeType, setFeeType] = useState(
    phase.fee_type || CONTRACT_TYPES.FIXED_FEE
  );
  const [budgetStatus, setBudgetStatus] = useState(
    phase.budget_status || 'Set Status'
  );
  const [budgetWith, setBudgetWith] = useState(
    (feeType === CONTRACT_TYPES.FIXED_FEE
      ? phase.budget_fixed_fee_with
      : feeType === CONTRACT_TYPES.HOURLY
      ? phase.budget_hourly_with
      : phase.budget_internal_with) || 'phase_estimate'
  );

  useEffect(() => {
    rebuildTooltip();
  }, [feeType, budgetWith]);

  const isDisablingUserActions = phase?.archived;

  useEffect(() => {
    setBudgetWith(
      (feeType === CONTRACT_TYPES.FIXED_FEE
        ? phase.budget_fixed_fee_with
        : feeType === CONTRACT_TYPES.HOURLY
        ? phase.budget_hourly_with
        : phase.budget_internal_with) || 'phase_estimate'
    );
  }, [
    feeType,
    phase.budget_fixed_fee_with,
    phase.budget_hourly_with,
    phase.budget_internal_with
  ]);

  // AKA 'target'
  const [estimatedCost, setEstimatedCost] = useState(
    parseFloat(phase.estimated_cost) === 0 ? '' : +phase.estimated_cost
  );
  // const [profitPercentage, setProfitPercentage] = useState(
  //   phase.profit_percentage || '0'
  // );
  // estimated cost (target) percentage of fee
  const [estPercentage, setEstPercentage] = useState(
    !isNaN(phase.profit_percentage) ? 100 - phase.profit_percentage : 100
  );

  const isTotalEmpty = parseFloat(total) === 0;
  const isEstCostEmpty = parseFloat(estimatedCost) === 0;
  const isEstPercentageEmpty = parseFloat(estPercentage) === 0;

  const [phaseName, setPhaseName] = useState(phase.name);
  const [phaseNumber, setPhaseNumber] = useState(phase.phase_number || '');
  const isProfitCenterEnabled =
    phase?.custom_fields?.profit_center !== undefined;
  const [profitCenter, setProfitCenter] = useState(
    isProfitCenterEnabled ? phase?.custom_fields?.profit_center : ''
  );
  const [isShowingConfirm, setIsShowingConfirm] = useState(false);
  const [isShowingDropdown, setIsShowingDropdown] = useState(false);
  const [dirtyFlag, setDirtyFlag] = useState(false);
  const totalPhaseFee = useRef(null);
  const phaseNameRef = useRef(null);
  const phaseNumberRef = useRef(null);
  const inputRef = useRef(null);
  const profitCenterRef = useRef(null);
  // const profitPercentageInputRef = useRef(null);
  const estPercentageInputRef = useRef(null);
  const estimatedCostInputRef = useRef(null);
  // Include any valid template name in this list
  const unusedStandardPhases = useMemo(
    () =>
      phaseTemplates.filter(
        (template) =>
          (!activePhasesHash.has(template.name) ||
            (activePhasesHash.has(template.name) &&
              phase.name === template.name)) &&
          phaseName !== template.name
      ),
    [activePhasesHash, phase.name, phaseName, phaseTemplates]
  );
  const phaseIsStandard = useMemo(
    () => phaseTemplates.some((template) => phaseName === template.name),
    [phaseName, phaseTemplates]
  );
  const [nameIsEditable, setNameIsEditable] = useState(!phaseIsStandard);

  const phaseCustomFieldsProfitCenter = phase?.custom_fields?.profit_center;

  const activatePhase = () => {
    phase.archived
      ? updatePhase({
          id: phase.id,
          projectId: phase.project_id,
          isArchived: false
        })
      : updatePhase({
          id: phase.id,
          projectId: phase.project_id,
          budgetStatus: PHASE_BUDGET_STATUS_DISPLAY.active
        });
  };

  const handleUnarchive = () => {
    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      isArchived: false
    });
    handleClose();
  };

  const handleNameChange = (e) => {
    setPhaseName(e.target.value);
  };

  const handleDelete = () => {
    openPhaseModal({
      id: phase.id,
      projectId: phase.project_id,
      isDeletePhase: true
    });
  };

  const handleFeeChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    // onValueChange gets called any time the value changes, so this
    // is necessary to prevent unexpected updating (eg. all of the input values will change)
    if (value === '' || total === '' || +value !== +total) {
      setTotal(value);
      if (!isNaN(value)) {
        // const nextEstimatedCost = (value - (profitPercentage / 100) * value)
        //   .toFixed(2)
        //   .replace(/\.00$/, '');
        const nextEstimatedCost =
          (estPercentage / 100) * +value === 0
            ? ''
            : (estPercentage / 100) * +value;
        setEstimatedCost(
          parseFloat(nextEstimatedCost) === 0 ? '' : nextEstimatedCost
        );
      }
    }
  };

  // const handleProfitPercentageChange = e => {
  //   const value = +e.target.value;
  //   setProfitPercentage(value);
  //   if (!isNaN(value)) {
  //     setEstimatedCost(total - (value / 100) * total);
  //   }
  // };

  const handleEstPercentageChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    if (value === '' || estPercentage === '' || +value !== +estPercentage) {
      setEstPercentage(value);
      if (!isNaN(value)) {
        setEstimatedCost((+value / 100) * total);
      }
    }
  };

  const handleEstimatedCostChange = (values, { source }) => {
    if (source !== 'event') return;
    const value = values.value;
    if (value === '' || estimatedCost === '' || +value !== +estimatedCost) {
      setEstimatedCost(value);
      if (!isNaN(value)) {
        // const nextProfitPercentage = (((total - value) * 100) / total)
        //   .toFixed(1)
        //   .replace(/\.0$/, '');
        // setProfitPercentage(
        //   isFinite(nextProfitPercentage) && !isNaN(nextProfitPercentage)
        //     ? nextProfitPercentage
        //     : '0'
        const nextEstPercentage = ((+value * 100) / total)
          .toFixed(1)
          .replace(/\.0$/, '');
        setEstPercentage(
          isFinite(nextEstPercentage) && !isNaN(nextEstPercentage)
            ? nextEstPercentage
            : '0'
        );
      }
    }
  };

  const handleBlur = () => {
    if (dirtyFlag) {
      const phaseInfo = {
        total: +total,
        estimatedCost: +estimatedCost,
        profitPercentage: 100 - estPercentage,
        id: phase.id,
        projectId: phase.project_id,
        phaseNumber: phaseNumber || null,
        feeType,
        budgetStatus:
          budgetStatus === 'Set Status' ? phase.budget_status : budgetStatus,
        billable: feeType !== CONTRACT_TYPES.INTERNAL,
        ...(!phase.is_like_default && { name: phaseName }),
        ...(feeType === CONTRACT_TYPES.FIXED_FEE && {
          budgetFixedFeeWith: budgetWith
        }),
        ...(feeType === CONTRACT_TYPES.HOURLY && {
          budgetHourlyWith: budgetWith
        }),
        ...(feeType === CONTRACT_TYPES.INTERNAL && {
          budgetInternalWith: budgetWith
        })
      };
      if (isProfitCenterEnabled) {
        updatePhase({
          ...phaseInfo,
          custom_fields: [
            {
              label: 'profit_center',
              value: profitCenter
            }
          ]
        });
      } else {
        updatePhase(phaseInfo);
      }
    }
    clearEditing();
  };

  const setToEmptyIfZero = (valueIsZero, setState) => {
    if (valueIsZero) {
      setState('');
    }
  };

  const clearEditing = () => handleClose();

  const checkEnterPressed = (ref) => (e) => {
    if (e.keyCode === 13) {
      ref.current.blur();
    }
  };

  const toggleShowConfirm = () => setIsShowingConfirm(!isShowingConfirm);

  const toggleShowDropdown = () => setIsShowingDropdown(!isShowingDropdown);

  const setBillable = (billable) => {
    ReactTooltip.hide();
    if (billable) {
      setFeeType(CONTRACT_TYPES.FIXED_FEE);
    } else {
      setFeeType(CONTRACT_TYPES.INTERNAL);
    }
  };

  /**
   * Checks if any values have been edited by the user
   */
  useEffect(() => {
    if (
      feeType !== phase.fee_type ||
      budgetStatus !==
        (phase.budget_status
          ? phase.budget_status || 'Set Status'
          : 'Set Status') ||
      phaseName !== phase.name ||
      ((phaseNumber || phase.phase_number) &&
        phaseNumber !== phase.phase_number) ||
      +total !== +phase.total ||
      +estimatedCost !== +phase.estimated_cost ||
      // +profitPercentage !== phase.profit_percentage ||
      +estPercentage !== 100 - phase.profit_percentage ||
      (isProfitCenterEnabled &&
        profitCenter !== phaseCustomFieldsProfitCenter) ||
      (feeType === CONTRACT_TYPES.FIXED_FEE &&
        budgetWith !== phase.budget_fixed_fee_with) ||
      (feeType === CONTRACT_TYPES.HOURLY &&
        budgetWith !== phase.budget_hourly_with) ||
      (feeType === CONTRACT_TYPES.INTERNAL &&
        budgetWith !== phase.budget_internal_with)
    ) {
      setDirtyFlag(true);
    } else {
      setDirtyFlag(false);
    }
  }, [
    budgetStatus,
    budgetWith,
    estPercentage,
    estimatedCost,
    feeType,
    isProfitCenterEnabled,
    phaseCustomFieldsProfitCenter,
    phase.billable,
    phase.budget_status,
    phase.estimated_cost,
    phase.fee_type,
    phase.name,
    phase.phase_number,
    phase.profit_percentage,
    phase.total,
    phaseName,
    phaseNumber,
    profitCenter,
    total,
    phase.budget_fixed_fee_with,
    phase.budget_hourly_with,
    phase.budget_internal_with
  ]);

  const renderDropdown = () => {
    return (
      <Dropdown
        {...getArchivedPhaseEditTooltipProps({
          isTooltipDisabled: !isDisablingUserActions
        })}
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <StyledDropdownToggle disabled={isDisablingUserActions}>
          {/* Internal === Not Billable */}
          {feeType}
          <StyledQBDownArrow />
        </StyledDropdownToggle>
        <StyledDropdownMenu>
          {FEE_TYPE_OPTIONS.map((option) => (
            <StyledDropdownItem
              key={option.value}
              onClick={() => setFeeType(option.value)}
            >
              {option.label}
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    );
  };

  const renderBudgetWithDropdown = () => {
    // If any of the activity phases are budgeted by % of phase then, cannot set the phase to be the sum of work categories.
    const filterOutWorkCategoryEstimatesOption = phase?.activity_phases.some(
      (activityPhase) =>
        (activityPhase.fee_type === CONTRACT_TYPES.FIXED_FEE &&
          activityPhase.budget_fixed_fee_with ===
            'phase_estimate_percentage') ||
        (activityPhase.fee_type === CONTRACT_TYPES.HOURLY &&
          activityPhase.budget_hourly_with === 'phase_estimate_percentage') ||
        (activityPhase.fee_type === CONTRACT_TYPES.INTERNAL &&
          activityPhase.budget_internal_with === 'phase_estimate_percentage')
    );

    const options = Object.entries(
      feeType === CONTRACT_TYPES.FIXED_FEE
        ? FIXED_BUDGET_WITH_OPTIONS
        : feeType === CONTRACT_TYPES.HOURLY
        ? HOURLY_BUDGET_WITH_OPTIONS
        : INTERNAL_BUDGET_WITH_OPTIONS
    );

    const filteredOptions = filterOutWorkCategoryEstimatesOption
      ? options.filter(([key, value]) => key !== 'work_category_estimates')
      : options;

    return (
      <Dropdown
        {...getArchivedPhaseEditTooltipProps({
          isTooltipDisabled: !isDisablingUserActions
        })}
        isOpen={budgetWithDropdownOpen}
        toggle={() => setBudgetWithDropdownOpen(!budgetWithDropdownOpen)}
      >
        <StyledDropdownToggle disabled={isDisablingUserActions}>
          {(feeType === CONTRACT_TYPES.FIXED_FEE
            ? FIXED_BUDGET_WITH_OPTIONS[budgetWith]
            : HOURLY_BUDGET_WITH_OPTIONS[budgetWith]) || ''}
          <StyledQBDownArrow />
        </StyledDropdownToggle>
        <StyledDropdownMenu>
          {filteredOptions.map(([key, value]) => (
            <StyledDropdownItem key={key} onClick={() => setBudgetWith(key)}>
              {value}
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    );
  };

  const renderEstimatedCostInput = () => {
    const hasPercentage = feeType === CONTRACT_TYPES.FIXED_FEE;
    return (
      <StyledInputContainer
        width={hasPercentage ? 111 : 120}
        style={hasPercentage ? { right: -3 } : {}}
      >
        <StyledLabel>BUDGET</StyledLabel>
        <CurrencyInputContainer
          isDisabled={isDisablingUserActions}
          hasPercentage={hasPercentage}
          {...getArchivedPhaseEditTooltipProps({
            isTooltipDisabled: !isDisablingUserActions
          })}
        >
          <StyledDollarSign>{CurrencySymbolComponent}</StyledDollarSign>
          <EstimatedCostInput
            disabled={isDisablingUserActions}
            value={estimatedCost}
            onValueChange={handleEstimatedCostChange}
            getInputRef={(ref) => (estimatedCostInputRef.current = ref)}
            placeholder={'0'}
            onKeyDown={checkEnterPressed(estimatedCostInputRef)}
            onFocus={() => setToEmptyIfZero(isEstCostEmpty, setEstimatedCost)}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={true}
            isNumericString
            data-testid="phase-modal-est-cost-input"
          />
        </CurrencyInputContainer>
      </StyledInputContainer>
    );
  };

  const renderPhaseNumberInput = () => {
    return (
      <StyledInputContainer width={115}>
        <StyledLabel>PHASE NUMBER</StyledLabel>
        <PhaseNumberInput
          as="input"
          value={phaseNumber}
          onChange={(e) => setPhaseNumber(e.target.value)}
          ref={(ref) => (phaseNumberRef.current = ref)}
          placeholder={'Optional'}
          onKeyDown={checkEnterPressed(phaseNumberRef)}
          data-testid="phase-modal-number-input"
        />
      </StyledInputContainer>
    );
  };

  const renderBudgetStatusDropdown = () => {
    const shouldUseLocalBudgetStatus =
      budgetStatus && budgetStatus !== phase.budget_status;
    const localBudgetStatusDisplay =
      PHASE_BUDGET_STATUS_DISPLAY[budgetStatus] || budgetStatus;

    const displayBudgetStatus = shouldUseLocalBudgetStatus
      ? localBudgetStatusDisplay
      : getPhaseDisplayBudgetStatus(phase) || localBudgetStatusDisplay;
    return (
      <Dropdown
        {...getArchivedPhaseEditTooltipProps({
          isTooltipDisabled: !isDisablingUserActions
        })}
        isOpen={budgetStatusDropdownOpen}
        toggle={() => setBudgetStatusDropdownOpen(!budgetStatusDropdownOpen)}
        disabled={isDisablingUserActions}
      >
        <StyledBudgetDropdownToggle
          disabled={isDisablingUserActions}
          $bgColor={
            displayBudgetStatus === PHASE_BUDGET_STATUS_DISPLAY.complete
              ? theme.colors.colorMediumGreen1
              : theme.colors.colorRoyalBlue
          }
          className="budget-status"
        >
          {displayBudgetStatus}
          <span style={{ paddingLeft: 2 }}>
            <StyledQBDownArrow fill={theme.colors.colorPureWhite} />
          </span>
        </StyledBudgetDropdownToggle>
        <StyledDropdownMenu>
          {PHASE_BUDGET_STATUS_OPTIONS.map((option) => (
            <StyledDropdownItem
              key={option.value}
              onClick={() => setBudgetStatus(option.value)}
            >
              {option.label}
            </StyledDropdownItem>
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    );
  };

  const renderHeaderButton = () => {
    return (
      <BlueSubmitButton padding="3px 10px" onClick={toggleShowDropdown}>
        Done
      </BlueSubmitButton>
    );
  };

  const handleAddCustom = () => {
    setNameIsEditable(true);
    setIsShowingDropdown(false);
    inputRef.current.focus();
  };

  const handleStandardPhaseSelect = (standardPhase) => {
    setPhaseName(standardPhase.name);
  };

  const isValid = !(
    isNaN(total) ||
    // isNaN(profitPercentage) ||
    isNaN(estPercentage) ||
    isNaN(estimatedCost)
  );

  const CurrencySymbolComponent = (
    <FormattedCurrencyByProject
      currencyFormatterProps={{
        projectId: phase.project_id
      }}
      currencyValue={0}
      currencyFormatMethod={formatCurrencySymbol}
    />
  );

  const isEstimatedPercentageInputDisabled = +total === 0;

  return (
    <Modal isOpen toggle={handleClose} className="phase-fee-modal">
      <StyledModalHeader>
        <StyledModalTitle>Phase Info</StyledModalTitle>
        {phase.id && !phase.is_like_default && (
          <div style={{ display: 'flex' }}>{renderBudgetStatusDropdown()}</div>
        )}
      </StyledModalHeader>

      <PaddingContainer>
        {/* Body */}
        <StyledModalBody nameIsEditable={nameIsEditable}>
          {/* First Row */}
          <BillableToggle
            isDisabled={isDisablingUserActions}
            setBillable={setBillable}
            isBillable={feeType !== CONTRACT_TYPES.INTERNAL}
          />
          {/* Second Row */}
          {phase.id && !phase.is_default_phase && !phase.is_main && (
            <PhaseNameContainer
              ref={phaseNameRef}
              onClick={nameIsEditable ? noop : toggleShowDropdown}
            >
              <StyledLabel>TITLE</StyledLabel>
              <PhaseOfWorkInput
                handleSubmit={handleBlur}
                name={phaseName}
                onChangeCallback={handleNameChange}
                id={phase.id}
                projectId={phase.project_id}
                isInModal={true}
                inputRef={inputRef}
                isDisabled={isDisablingUserActions}
              />
              {!nameIsEditable && (
                <DropdownArrow>
                  <PhaseDropdownArrow />
                </DropdownArrow>
              )}
              {isShowingDropdown && (
                <PhasesOfWorkDropdown
                  target={phaseNameRef}
                  projectId={phase.project_id}
                  handleSelect={handleStandardPhaseSelect}
                  handleClose={toggleShowDropdown}
                  hideFooter={false}
                  renderHeaderButton={renderHeaderButton}
                  hideEditIcon
                  copy={{
                    sticky: 'Add Custom Phase'
                  }}
                  onStickyClick={handleAddCustom}
                  phases={unusedStandardPhases}
                />
              )}
            </PhaseNameContainer>
          )}
          {isProfitCenterEnabled ? (
            <StyledInputContainer width={304}>
              <StyledLabel>PROFIT CENTER</StyledLabel>
              <ProfitCenterInput
                as="input"
                value={profitCenter}
                onChange={(e) => setProfitCenter(e.target.value)}
                ref={(ref) => (profitCenterRef.current = ref)}
                placeholder={''}
                onKeyDown={checkEnterPressed(profitCenterRef)}
                data-testid="phase-modal-profit-center-input"
              />
            </StyledInputContainer>
          ) : null}
          {/* Third Row */}
          <BottomInputsContainer>
            {phaseNumber && renderPhaseNumberInput()}
            <StyledInputContainer
              width={
                (feeType === CONTRACT_TYPES.INTERNAL &&
                  budgetWith === 'phase_estimate') ||
                feeType === CONTRACT_TYPES.FIXED_FEE ||
                (feeType === CONTRACT_TYPES.HOURLY &&
                  budgetWith === 'phase_estimate')
                  ? 178
                  : 304
              }
            >
              <StyledLabel>CONTRACT TYPE</StyledLabel>
              {renderDropdown()}
            </StyledInputContainer>
            {/* Phase fee */}
            {feeType === CONTRACT_TYPES.FIXED_FEE && (
              <StyledInputContainer width={120}>
                <StyledLabel>
                  {[CONTRACT_TYPES.HOURLY, CONTRACT_TYPES.INTERNAL].includes(
                    feeType
                  )
                    ? 'EST. FEE (OPTIONAL)'
                    : 'PHASE FEE'}
                </StyledLabel>
                <CurrencyInputContainer
                  isDisabled={isDisablingUserActions}
                  {...getArchivedPhaseEditTooltipProps({
                    isTooltipDisabled: !isDisablingUserActions
                  })}
                >
                  <StyledDollarSign>{CurrencySymbolComponent}</StyledDollarSign>
                  <FeeInput
                    disabled={isDisablingUserActions}
                    value={total}
                    onValueChange={handleFeeChange}
                    getInputRef={(ref) => (totalPhaseFee.current = ref)}
                    placeholder={'0'}
                    autoFocus
                    onKeyDown={checkEnterPressed(totalPhaseFee)}
                    onFocus={() => setToEmptyIfZero(isTotalEmpty, setTotal)}
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator={true}
                    isNumericString
                    data-testid="phase-modal-fee-input"
                  />
                </CurrencyInputContainer>
              </StyledInputContainer>
            )}
            {((feeType === CONTRACT_TYPES.INTERNAL &&
              budgetWith === 'phase_estimate') ||
              (feeType === CONTRACT_TYPES.HOURLY &&
                budgetWith === 'phase_estimate')) &&
              renderEstimatedCostInput()}
          </BottomInputsContainer>
          {/* Fourth Row */}
          <BottomInputsContainer>
            <StyledInputContainer
              width={
                feeType === CONTRACT_TYPES.FIXED_FEE &&
                budgetWith === 'phase_estimate'
                  ? 178
                  : 304
              }
            >
              <StyledLabel>BUDGET WITH</StyledLabel>
              {renderBudgetWithDropdown()}
            </StyledInputContainer>
            {feeType === CONTRACT_TYPES.FIXED_FEE &&
              budgetWith === 'phase_estimate' && (
                <>
                  {/* Estimated cost */}
                  {renderEstimatedCostInput()}

                  {/* Est percentage */}
                  <StyledInputContainer width={71}>
                    <StyledLabel>
                      % OF FEE{' '}
                      <img
                        data-for="app-tooltip"
                        data-tip="Enter the % of fee to budget, <br/> which may be higher or lower <br/> than the actual fee."
                        data-html
                        data-effect="solid"
                        data-class="blue-dark-tooltip center fs-10"
                        src={HelpIcon}
                        style={{
                          marginLeft: 5,
                          width: 14,
                          position: 'absolute'
                        }}
                      />
                    </StyledLabel>
                    <PercentOfFeeInutContainer
                      {...getArchivedPhaseEditTooltipProps({
                        isTooltipDisabled: !(
                          isDisablingUserActions ||
                          isEstimatedPercentageInputDisabled
                        ),
                        customTooltipContent:
                          isEstimatedPercentageInputDisabled &&
                          !isDisablingUserActions
                            ? 'Enter Fee'
                            : undefined
                      })}
                    >
                      <EstPercentageInput
                        disabled={
                          isDisablingUserActions ||
                          isEstimatedPercentageInputDisabled
                        }
                        data-testid="phase-modal-est-percentage-input"
                        value={estPercentage}
                        placeholder={'0'}
                        onValueChange={handleEstPercentageChange}
                        getInputRef={(ref) =>
                          (estPercentageInputRef.current = ref)
                        }
                        onKeyDown={checkEnterPressed(estPercentageInputRef)}
                        onFocus={() =>
                          setToEmptyIfZero(
                            isEstPercentageEmpty,
                            setEstPercentage
                          )
                        }
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator={true}
                        isNumericString
                        $isEstPercentageEmpty={isEstPercentageEmpty}
                      />
                      <div className="percent-sign">%</div>
                    </PercentOfFeeInutContainer>
                  </StyledInputContainer>
                </>
              )}
          </BottomInputsContainer>
        </StyledModalBody>

        {/* Footer */}
        <StyledModalFooter>
          <DeleteButton
            onClick={!phase.archived ? handleDelete : handleUnarchive}
          >
            {!phase.archived ? 'Delete' : 'Unarchive'}
          </DeleteButton>
          <ButtonContainer width={152}>
            <TextButtonWithBorder
              onClick={handleClose}
              fontWeight={400}
              data-testid="phase-modal-cancel-btn"
            >
              Cancel
            </TextButtonWithBorder>
            <BlueSubmitButton
              onClick={isValid ? handleBlur : undefined}
              fontWeight={400}
              opacity={isValid ? undefined : '0.6'}
              isDisabled={!isValid}
              width={63}
              data-testid="phase-modal-submit-btn"
            >
              {dirtyFlag ? 'Save' : 'Done'}
            </BlueSubmitButton>
          </ButtonContainer>
        </StyledModalFooter>
      </PaddingContainer>

      <PhaseArchiveConfirmModal
        phase={phase}
        isOpen={isShowingConfirm}
        toggle={toggleShowConfirm}
      />
    </Modal>
  );
};

const makeMapStateToProps = () => {
  const getOwnPhase = makeGetOwnPhase();
  const getActivePhases = makeGetActivePhases();
  const mapStateToProps = (state, ownProps) => {
    const phase = getOwnPhase(state, ownProps) || {};
    const activePhasesHash = new Set(
      getActivePhases(state, {
        ...ownProps,
        projectId: phase.project_id
      }).map((phase) => phase.name)
    );
    return {
      phase,
      activePhasesHash,
      phaseTemplates: getPhaseTemplatesList(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  openPhaseModal,
  updatePhase,
  createPhase,
  fetchPhaseTemplates
};

export default connect(makeMapStateToProps, mapDispatchToProps)(FeeModal);
