import { PureComponent } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import TaskColumns from './TaskColumns';
import {
  ProjectMenuButton,
  TaskMainContentPanel,
  ProjectTitleCircle,
  CloseModalIconContainer,
  TaskLineItem
} from './styles';
import CloseModalIcon from 'components/Modals/CloseModalIcon';

import {
  getOnProjectDetail,
  getIsOnTeamProject,
  getOnMembersView,
  getOnHomeView,
  getIsOnPersonalProject,
  makeGetOwnTaskInfoFromView,
  makeGetIsSelectedHomeTask,
  getAllTasks,
  getTaskEditProperty,
  getCurrentFilter,
  getSearchText,
  getIsFetchingTasks,
  getAuthToken,
  getSelectedTeamId,
  getMatchedRouteParams,
  getIsOnProjectView,
  getIsTaskModalOpen,
  getEditingTask,
  getShouldTriggerTaskConfirmCompleteModal,
  getCreatedTasksTempIds,
  getActiveTimer
} from 'selectors';
import {
  setEditTaskId,
  triggerTasksAttributesUpdate,
  updateTaskPosition,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  closeAndClearTaskViewModal,
  setSelectedTask,
  setSelectedHomeTask,
  triggerTaskCompletionToggle,
  flushSelectedHomeTask,
  setHomeTaskEditProperty,
  navigateToTaskModal,
  openConfirmCompleteTaskModal
} from 'actionCreators';
import { keyifyDate } from 'appUtils/plannerUtils';
import TaskContent from './TaskContent';
import TaskCircle from './TaskCircle';
import moment from 'appUtils/momentConfig';
import { withConfirmStopTimerModal } from './withConfirmStopTimerModal';
import { getShouldTriggerTaskCompleteConfirmStopTimerModal } from 'TeamsModule/selectors';

class Task extends PureComponent {
  state = {
    currentDescriptionText: this.props.task.description,
    noteText: this.props.task.note || ''
  };

  componentDidUpdate(prevProps, prevState) {
    const { task, isEditingTask, isClosing } = this.props;
    const newDescription = this.props.task.description;
    const oldDescription = prevProps.task.description;
    const newNote = this.props.task.note;
    const oldNote = prevProps.task.note;
    const oldIsEditingTask = prevProps.isEditingTask;

    if (newDescription !== oldDescription) {
      this.setState({
        currentDescriptionText: newDescription
      });
    }
    if (newNote !== oldNote) {
      this.setState({
        noteText: newNote
      });
    }
    if (isClosing && isEditingTask !== oldIsEditingTask) {
      const { currentDescriptionText, noteText } = this.state;
      if (currentDescriptionText !== task.description) {
        this.handleDescriptionSubmit();
      }
      this.clearUpdateTimeout();
      if (task.note !== noteText) {
        this.handleUpdateTaskNote({
          note: noteText
        });
      }
    }
  }

  componentWillUnmount() {
    const { isTaskModalOpen, task } = this.props;
    if (isTaskModalOpen) {
      const { currentDescriptionText, noteText } = this.state;
      if (currentDescriptionText !== task.description) {
        this.handleDescriptionSubmit();
      }
      this.clearUpdateTimeout();
      if (task.note !== noteText) {
        this.handleUpdateTaskNote({
          note: noteText
        });
      }
    }
  }

  handleDescriptionTextAreaEvent = (currentDescriptionText) => {
    this.setState({
      currentDescriptionText
    });
  };

  getPermissions = () => {
    const {
      task,
      selectedTeamId,
      matchedParams: { projectId }
    } = this.props;
    const permissions = {
      projectId: task.project_id || projectId,
      teamId: selectedTeamId
    };
    return permissions;
  };

  handleDescriptionSubmit = () => {
    const { task, handleTasksAttributesUpdate, setEditTaskId, token } =
      this.props;
    const { currentDescriptionText } = this.state;
    const preparedText = currentDescriptionText
      .replace(/<br>/g, '')
      .replace(/&nbsp;/g, ' ')
      .trim();
    const permissions = this.getPermissions();
    handleTasksAttributesUpdate({
      token,
      body: {
        task_ids: [task.id],
        description: preparedText
      },
      permissions
    });
    setEditTaskId(null);
    this.setState({
      currentDescriptionText: preparedText
    });
  };

  handleTaskEditClick = (taskProperty) => {
    const { setHomeTaskEditProperty, task, setEditTaskId } = this.props;
    setEditTaskId(taskProperty && task.id);
    setHomeTaskEditProperty(taskProperty);
  };

  handleContentClick = () => {
    this.handleTaskEditClick('description');
  };

  openTaskModal = () => {
    const {
      taskId,
      fetchCommentsAndMetadata,
      fetchTaskGroups,
      setSelectedTask,
      navigateToTaskModal,
      task
    } = this.props;
    fetchTaskGroups({ taskGroupIds: [task.task_group_id] });
    navigateToTaskModal({
      taskId
    });
    setSelectedTask(taskId);
    fetchCommentsAndMetadata({
      taskId,
      taskType: 'projects',
      offset: 0,
      limit: 4
    });
  };

  closeTaskModal = (e) => {
    const {
      setSelectedTask,
      closeAndClearTaskViewModal,
      flushSelectedHomeTask,
      setEditTaskId
    } = this.props;
    closeAndClearTaskViewModal();
    flushSelectedHomeTask();
    setSelectedTask(null);
    setEditTaskId(null);
  };

  /**
   * If marked completed task has a timer, show modal asking if timer should be stopped
   * before marking task as complete. This is necessary since Task component
   * unmounts when task is marked as completed resulting in confirmation modal not showing.
   *
   * Pass handleConfirmStopTimer below as modal onClose callback to run task completion logic
   * when modal closes.
   */
  checkTimerBeforeHandleMarkTaskCompletion = () => {
    const {
      openConfirmStopTimerModal,
      activeTimer,
      task,
      shouldTriggerTaskCompleteConfirmStopTimerModal
    } = this.props;

    if (
      activeTimer?.task_id === task.id &&
      !task.completed_at &&
      shouldTriggerTaskCompleteConfirmStopTimerModal
    ) {
      openConfirmStopTimerModal();
    } else {
      this.markTaskCompletion();
    }
  };

  handleConfirmStopTimerModalClose = () => {
    const { closeConfirmStopTimerModal } = this.props;
    /**
     * Need to close here since handleConfirmStopTimerModalClose overrides the
     * onClose passed in useConfirmStopTimerModal hook.
     */
    closeConfirmStopTimerModal();
    this.markTaskCompletion();
  };

  markTaskCompletion = () => {
    const {
      setSelectedHomeTask,
      setSelectedTask,
      closeAndClearTaskViewModal,
      flushSelectedHomeTask,
      setEditTaskId,
      handleTaskCompletionToggle,
      task,
      searchText,
      token,
      handleTaskPositionUpdate,
      taskType,
      isFetchingTasks,
      index,
      currentFilter: { scope },
      listLength,
      isTaskModalOpen,
      openConfirmCompleteTaskModal,
      shouldTriggerTaskConfirmCompleteModal
    } = this.props;
    const taskId = task.id;
    const projectId = task.project_id;
    // if task is completed, un-complete - otherwise, complete it
    const isCompleted = task.completed_at !== null;
    const position = isCompleted ? index : listLength;
    if (!isFetchingTasks) {
      const isOverdue = task.due_at && task.due_at < Date();
      const permissions = this.getPermissions();
      if (
        task.assignee_ids?.length > 1 &&
        shouldTriggerTaskConfirmCompleteModal
      ) {
        openConfirmCompleteTaskModal({ taskId: task.id });
        return;
      }
      if (!isTaskModalOpen) {
        closeAndClearTaskViewModal({ skipNavigateUp: true });
        flushSelectedHomeTask();
        setSelectedHomeTask(null);
        setSelectedTask(null);
        setEditTaskId(null);
      }
      handleTaskCompletionToggle({
        taskId,
        isCompleting: !isCompleted, // used to determine if task needs to be re-ordered because the list is a day planner order
        permissions
      });
      handleTaskPositionUpdate({
        token,
        taskId,
        projectId,
        taskType,
        isCompleted: !isCompleted,
        wasCompleted: isCompleted,
        isOverdue,
        taskPosition: task.position,
        oldTaskPosition: task.position,
        selectedAccountIds: [],
        searchText,
        circleClicked: true,
        plannerPosition: position,
        dateKey: scope === 'today' ? keyifyDate(task.schedule_start) : null,
        permissions
      });
    }
  };

  handleNoteChange = (noteText) => this.setState({ noteText });
  clearUpdateTimeout = () => {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  };

  handleUpdateTaskNote = (data) => {
    const { task, handleTasksAttributesUpdate, token } = this.props;
    const permissions = this.getPermissions();
    const { note } = data;
    const body = {
      task_ids: [task.id],
      note
    };
    handleTasksAttributesUpdate({
      token,
      body,
      permissions
    });
  };

  checkForTyping = (text) => {
    // close over taskId for when modal exited before update
    this.clearUpdateTimeout();
    this.updateTimeout = setTimeout(() => {
      this.handleUpdateTaskNote({
        note: text
      });
    }, 2500);
  };

  handleProjectMenuSelection = (
    newProjectId = null,
    taskGroupId = null,
    newPhaseId = null,
    newActivityId = null,
    newActivityPhaseId = null
  ) => {
    const { handleTasksAttributesUpdate, token, task } = this.props;
    const body = {
      task_ids: [task.id],
      project_id: newProjectId
    };
    if (taskGroupId) {
      body.task_group_id = taskGroupId;
    }
    if (newPhaseId) {
      body.phase_id = newPhaseId;
    }

    if (newActivityId) {
      body.activity_id = newActivityId;
    }

    if (newActivityPhaseId) {
      body.activity_phase_id = newActivityPhaseId;
    }
    handleTasksAttributesUpdate({
      token,
      body,
      options: {}
    });
  };

  getButtonText = () => {
    const {
      taskIsBeingEdited,
      task: { project }
    } = this.props;
    if (!isEmpty(project)) {
      const { title, description } = project;
      const projectAndDescription = `${title} - ${description}`;
      const hasProjectDescription = title !== 'Select Project' && description;
      if (hasProjectDescription && taskIsBeingEdited) {
        return projectAndDescription;
      }
      return title;
    }
    return 'Select Project';
  };

  preventClicks = (e) => {
    e.stopPropagation();
  };

  renderProjectPopoverMenuItem = () => {
    const { task, taskIsEditing, isSelectedHomeTask, isOnProjectDetail } =
      this.props;
    const testIdPrefix = isEmpty(task)
      ? ''
      : `${task.project_id}-${
          moment(task.created_at).format('YYYY-MM-DD') || 'just-created'
        }-${task.description.slice(0, 10)}`;

    const showProjectSelector = !isOnProjectDetail && !isSelectedHomeTask;
    return (
      <ProjectMenuButton
        show={showProjectSelector}
        className="project-title"
        taskBeingEdited={taskIsEditing}
        hasProject={task.project_id}
        data-testid={`${testIdPrefix}-select-project`}
      >
        {!isOnProjectDetail && task.project_id && (
          <ProjectTitleCircle projectId={task.project_id} />
        )}
        {this.getButtonText() || <>&#x200b;</>}
      </ProjectMenuButton>
    );
  };

  render() {
    const {
      task,
      taskIsEditing,
      isBatchSelected,
      currentFilter,
      isOnTeamProject,
      isCreatingTask,
      isOnPersonalProject,
      isOnHomeView,
      taskId,
      handleTasksAttributesUpdate,
      isSelectedHomeTask,
      taskEditProperty,
      alwaysRenderSchedule,
      alwaysRenderDue,
      taskBatchActionIsToggled,
      showBatchActionToggles,
      fillWhite,
      isTaskModal,
      isNewTask,
      handleBatchActionToggleClick,
      isDragging,
      isLastTask,
      isSomeTaskEditing,
      rowNumber,
      isOnProjectView,
      isOnTeamPageView,
      selectedProject,
      onDelete,
      ConfirmStopTimerModal
    } = this.props;

    const { currentDescriptionText = '' } = this.state;
    const isComplete = task.completed_at !== null;
    const shouldRenderAssignee =
      (!isOnPersonalProject &&
        (currentFilter.scope === 'assigned_by_me' || isOnProjectView)) ||
      isSelectedHomeTask;
    const taskModalProps = {};
    if (isSelectedHomeTask) {
      taskModalProps.id = 'task-modal';
    }
    // isOnTeamProject only tells us what whether the selected project in redux is personal or not
    const renderAsTeamProject = isOnTeamProject && !isOnHomeView;
    const testIdPrefix = isEmpty(task)
      ? ''
      : `${task.project_id}-${
          moment(task.created_at).format('YYYY-MM-DD') || 'just-created'
        }-${currentDescriptionText.slice(0, 10)}`;

    return (
      <TaskLineItem
        beingEdited={taskIsEditing}
        isSomeTaskEditing={isSomeTaskEditing}
        isComplete={isComplete}
        isBatchSelected={isBatchSelected}
        isOnTeamProject={renderAsTeamProject}
        isOnHomeView={isOnHomeView}
        isOnPersonalProject={isOnPersonalProject}
        isCreatingTask={isCreatingTask}
        isSelectedHomeTask={isSelectedHomeTask}
        taskEditProperty={taskEditProperty}
        taskId={taskId}
        isDragging={isDragging}
        isLastTask={isLastTask}
      >
        {!isSelectedHomeTask && (
          <TaskCircle
            markComplete={this.checkTimerBeforeHandleMarkTaskCompletion}
            isCompleted={isComplete}
            isEditing={taskIsEditing}
            isOnTeamProject={renderAsTeamProject}
            isOnTeamPageView={isOnTeamPageView}
            isSelectedHomeTask={isSelectedHomeTask}
            testIdPrefix={testIdPrefix}
          />
        )}
        <TaskMainContentPanel
          className="task-main-content-panel"
          data-component="TaskMainContentPanel"
          beingEdited={taskIsEditing}
          isSomeTaskEditing={isSomeTaskEditing}
          isComplete={isComplete}
          isBatchSelected={isBatchSelected}
          isOnTeamProject={renderAsTeamProject}
          isOnHomeView={isOnHomeView}
          isOnPersonalProject={isOnPersonalProject}
          isCreatingTask={isCreatingTask}
          isSelectedHomeTask={isSelectedHomeTask}
          taskEditProperty={taskEditProperty}
          taskId={taskId}
          isMainContentPanel
          isDragging={isDragging}
          isLastTask={isLastTask}
          onClick={this.preventClicks}
          {...taskModalProps}
        >
          <TaskContent
            testIdPrefix={testIdPrefix}
            taskIsEditing={taskIsEditing}
            openTaskModal={this.openTaskModal}
            markTaskCompletion={this.checkTimerBeforeHandleMarkTaskCompletion}
            renderProjectPopoverMenuItem={this.renderProjectPopoverMenuItem}
            handleProjectMenuSelection={this.handleProjectMenuSelection}
            task={task}
            shouldRenderAssignee={shouldRenderAssignee}
            taskId={taskId}
            handleTasksAttributesUpdate={handleTasksAttributesUpdate}
            handleDescriptionTextAreaEvent={this.handleDescriptionTextAreaEvent}
            handleDescriptionSubmit={this.handleDescriptionSubmit}
            currentDescriptionText={currentDescriptionText}
            isOnTeamProject={isOnTeamProject}
            isCreatingTask={isCreatingTask}
            isSelectedHomeTask={isSelectedHomeTask}
            beingEdited={taskIsEditing}
            isComplete={isComplete}
            isBatchSelected={isBatchSelected}
            taskProperty={'description'}
            handleTaskEditClick={this.handleTaskEditClick}
            handleContentClick={this.handleContentClick}
            isTaskEditProperty={taskEditProperty === 'description'}
            checkForTyping={this.checkForTyping}
            handleNoteChange={this.handleNoteChange}
            noteText={this.state.noteText}
            rowNumber={rowNumber}
            selectedProject={selectedProject}
            onDelete={onDelete}
            handleClose={this.closeTaskModal}
          />
          {!isSelectedHomeTask && (
            <TaskColumns
              testIdPrefix={testIdPrefix}
              taskIsEditing={taskIsEditing}
              task={task}
              handleTasksAttributesUpdate={handleTasksAttributesUpdate}
              isOnTeamProject={renderAsTeamProject}
              isOnHomeView={isOnHomeView}
              isBatchSelected={isBatchSelected}
              isCreatingTask={isCreatingTask}
              handleTaskEditClick={this.handleTaskEditClick}
              taskEditProperty={taskEditProperty}
              isComplete={isComplete}
              alwaysRenderDue={alwaysRenderDue}
              alwaysRenderSchedule={alwaysRenderSchedule}
              taskBatchActionIsToggled={taskBatchActionIsToggled}
              showBatchActionToggles={showBatchActionToggles}
              fillWhite={fillWhite}
              isTaskModal={isTaskModal}
              isNewTask={isNewTask}
              handleBatchActionToggleClick={handleBatchActionToggleClick}
              openTaskModal={this.openTaskModal}
            />
          )}
        </TaskMainContentPanel>
        <ConfirmStopTimerModal
          overrideOnClose={this.handleConfirmStopTimerModalClose}
        />
      </TaskLineItem>
    );
  }
}

Task.propTypes = {};

const makeMapStateToProps = () => {
  const getOwnTaskInfo = makeGetOwnTaskInfoFromView();
  const getIsSelectedHomeTask = makeGetIsSelectedHomeTask();
  const mapStateToProps = (state, ownProps) => ({
    token: getAuthToken(state),
    isFetchingTasks: getIsFetchingTasks(state),
    searchText: getSearchText(state),
    isOnProjectDetail: getOnProjectDetail(state),
    currentFilter: getCurrentFilter(state),
    isOnTeamProject: getIsOnTeamProject(state),
    isOnTeamPageView: getOnMembersView(state),
    isOnHomeView: getOnHomeView(state),
    isOnPersonalProject: getIsOnPersonalProject(state),
    task: getOwnTaskInfo(state, ownProps),
    listLength: getAllTasks(state).length,
    isSelectedHomeTask:
      ownProps.isSelectedHomeTask || getIsSelectedHomeTask(state, ownProps),
    isEditingTask: getEditingTask(state) === ownProps.taskId,
    taskEditProperty: getTaskEditProperty(state),
    selectedTeamId: getSelectedTeamId(state),
    matchedParams: getMatchedRouteParams(state),
    isOnProjectView: getIsOnProjectView(state),
    isTaskModalOpen: getIsTaskModalOpen(state),
    createdTasksTempIds: getCreatedTasksTempIds(state),
    shouldTriggerTaskConfirmCompleteModal:
      getShouldTriggerTaskConfirmCompleteModal(state),
    activeTimer: getActiveTimer(state),
    shouldTriggerTaskCompleteConfirmStopTimerModal:
      getShouldTriggerTaskCompleteConfirmStopTimerModal(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  setSelectedTask,
  setSelectedHomeTask,
  closeAndClearTaskViewModal,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  setEditTaskId,
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate,
  handleTaskPositionUpdate: updateTaskPosition,
  handleTaskCompletionToggle: triggerTaskCompletionToggle,
  flushSelectedHomeTask,
  setHomeTaskEditProperty,
  navigateToTaskModal,
  openConfirmCompleteTaskModal
};

export default withConfirmStopTimerModal(
  connect(makeMapStateToProps, mapDispatchToProps)(Task)
);
