import React from 'react';
import cn from 'classnames';
import autosize from 'autosize';

class TaskNoteBox extends React.Component {
  state = {
    overNotes: false
  };

  onBlur = () => {
    this.setState({
      overNotes: false,
      enteringText: false
    });
  };

  onChange = (e) => {
    this.props.handleNoteChange(e.target.value);
    this.props.checkForTyping(e.target.value);
  };

  onClick = () => {
    this.setState({ enteringText: true });
  };

  onMouseEnter = () => {
    this.setState({ overNotes: true });
  };

  onMouseLeave = () => {
    if (!this.state.enteringText) {
      this.setState({ overNotes: false });
    }
  };

  setTextAreaRef = (ref) => (this.textAreaRef = ref);
  setDisplayRef = (ref) => (this.displayRef = ref);

  parentHeight = () => {
    if (!this.textAreaRef && !this.displayRef) {
      setTimeout(this.parentHeight, 100);
    } else {
      const textareaHeight = this.textAreaRef.getBoundingClientRect().height;
      const displayHeight = this.displayRef.getBoundingClientRect().height;
      return Math.max(textareaHeight, displayHeight);
    }
  };

  componentDidMount() {
    autosize(this.textAreaRef);
  }

  render() {
    const { overNotes } = this.state;
    const {
      isInlineTask,
      shouldRenderAssignee,
      noteText,
      placeholderText = 'Add Notes/Subtasks'
    } = this.props;
    return (
      <div
        className={cn('task-note-box', {
          hovered: overNotes,
          indented: shouldRenderAssignee
        })}
        onBlur={this.onBlur}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{ minHeight: this.parentHeight() }}
      >
        <textarea
          ref={this.setTextAreaRef}
          className={cn('task-note-textarea', {
            active: overNotes
          })}
          onChange={this.onChange}
          onClick={this.onClick}
          value={noteText}
          placeholder={noteText ? '' : placeholderText}
          rows={isInlineTask ? 1 : 2}
        />
        <div
          ref={this.setDisplayRef}
          className={cn('task-note-display', {
            active: overNotes
          })}
        >
          {noteText || <div className="placeholder">{placeholderText}</div>}
        </div>
      </div>
    );
  }
}

export default TaskNoteBox;
