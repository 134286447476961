import * as appConstants from 'appConstants';
import * as constants from '../constants';
import createIntegrationReducer from './createIntegrationReducer';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import { deserializeItem, makeIdHash } from 'appUtils';

const byId = (item) => item.id;

export const initialState = {
  integrationProjects: {}, // to be replaced by uuidHash
  integrationProjectOrder: [],
  mappedMosaicIds: {},
  projectMappings: {},
  selectedProjects: {},
  counts: {},
  isLoading: false,
  isSearchLoading: false,
  initiallyLoaded: false,
  count: 0,
  offset: 0,
  mappingSteps: {},
  movedProjects: {},
  uuidHash: {},
  linkingIntegrationActivity: null,
  linkingMosaicActivity: null
};
export const projects = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_INTEGRATION_PROJECTS.TRIGGER: {
      return {
        ...state,
        isLoading: true
      };
    }
    case constants.FETCH_INTEGRATION_PROJECTS.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const {
        body: { offset, limit, initial }
      } = requestPayload;

      const { entities, count } = response;

      const selectedIds = Object.keys(state.selectedProjects)
        .filter((id) => state.selectedProjects[id])
        .map((id) => +id);

      const nextUuidHash = {};
      entities.forEach((project) => {
        nextUuidHash[project.uuid] = project;
        project.phases.forEach((phase) => {
          nextUuidHash[phase.uuid] = phase;
        });
      });

      return {
        ...state,
        integrationProjectOrder:
          initial && !selectedIds.length
            ? entities.map(byId)
            : Array.from(
                new Set([
                  ...selectedIds,
                  ...(!initial ? state.integrationProjectOrder : []),
                  ...entities.map(byId)
                ])
              ),
        integrationProjects:
          initial && !selectedIds.length
            ? keyBy(entities, byId)
            : { ...state.integrationProjects, ...keyBy(entities, byId) },
        offset: (offset !== undefined ? offset : state.offset) + (limit || 0),
        isLoading: false,
        initiallyLoaded: true,
        isSearchLoading: false,
        counts: initial ? count : state.counts,
        uuidHash:
          initial && !selectedIds.length
            ? nextUuidHash
            : { ...state.uuidHash, ...nextUuidHash }
      };
    }

    // 'refetch' as it does not affect the project order array
    case constants.REFETCH_INTEGRATION_PROJECT.SUCCESS: {
      const projects = action.payload.response.entities;
      const nextUuidHash = {
        ...state.uuidHash
      };
      const nextIntegrationProjects = {
        ...state.integrationProjects
      };
      projects.forEach((project) => {
        nextUuidHash[project.uuid] = project;
        nextIntegrationProjects[project.id] = project;
        project.phases.forEach((phase) => {
          nextUuidHash[phase.uuid] = phase;
        });
      });

      return {
        ...state,
        integrationProjects: nextIntegrationProjects,
        uuidHash: nextUuidHash
      };
    }

    case constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        mappedMosaicIds: makeIdHash(
          [
            ...(response[constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_MOSAIC] || []),
            ...(response[constants.MAPPING_STATUS.UPDATE_ON_TARGET] || []),
            ...(response[constants.MAPPING_STATUS.ACTIVE] || [])
          ].filter((id) => id)
        )
      };
    }
    case constants.BATCH_SELECT_INTEGRATION_PROJECTS: {
      const { value, integration_ids } = payload;
      return {
        ...state,
        selectedProjects: {
          ...state.selectedProjects,
          ...integration_ids.reduce((acc, cur) => {
            acc[cur] = value;
            return acc;
          }, {})
        }
      };
    }
    case constants.CLEAR_BATCH_SELECTED_INTEGRATION_PROJECTS: {
      return {
        ...state,
        selectedProjects: {}
      };
    }
    case constants.MAP_PROJECTS.TRIGGER: {
      const { body, mappingStatus, isUpdate, isDataTypeChange } = payload;
      const nextMovedProjects = { ...state.movedProjects };
      const mappedIds = body.map((project) => project.id);
      const mappedIdsHash = keyBy(mappedIds);

      if (isDataTypeChange) {
        const nextUuidHash = { ...state.uuidHash };
        const nextIntegrationProjects = { ...state.integrationProjects };
        body.forEach((project) => {
          delete nextUuidHash[project.id];
          delete nextIntegrationProjects[project.id];
        });
        return {
          ...state,
          integrationProjectOrder: state.integrationProjectOrder.filter(
            (id) => !mappedIdsHash[id]
          ),
          integrationProjects: nextIntegrationProjects,
          uuidHash: nextUuidHash,
          selectedProjects: pickBy(
            state.selectedProjects,
            (_, projectId) => !mappedIdsHash[projectId]
          )
        };
      }

      const section = isUpdate
        ? constants.PROJECTS_VIEWS.NO_MOVE
        : [
            constants.MAPPING_STATUS.ACTIVE,
            constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC,
            constants.MAPPING_STATUS.UPDATE_ON_MOSAIC,
            constants.MAPPING_STATUS.UPDATE_ON_TARGET
          ].includes(mappingStatus)
        ? constants.PROJECTS_VIEWS.LINKED
        : mappingStatus === constants.MAPPING_STATUS.NULL
        ? constants.PROJECTS_VIEWS.IMPORT
        : mappingStatus === constants.MAPPING_STATUS.DO_NOT_LINK
        ? constants.PROJECTS_VIEWS.DO_NOT_LINK
        : constants.PROJECTS_VIEWS.NO_MOVE; // default

      if (!isUpdate) {
        mappedIds.forEach((id) => (nextMovedProjects[id] = section));
      }

      return {
        ...state,
        movedProjects: nextMovedProjects,
        selectedProjects: pickBy(
          state.selectedProjects,
          (_, projectId) => !mappedIdsHash[projectId]
        )
      };
    }

    case constants.MAP_PROJECTS.SUCCESS: {
      const { isDataTypeChange, isUpdate } = action.payload.requestPayload;

      // No update since project can only be mapped to phase or client
      // from Import view, so user will have to load data anyway
      if (isDataTypeChange) return state;

      const updatedProject = action.payload.response;

      return {
        ...state,
        // Not being moved, so replace the order id
        ...(isUpdate && {
          integrationProjectOrder: state.integrationProjectOrder.map((id) =>
            state.integrationProjects[id]?.uuid === updatedProject.uuid
              ? updatedProject.id
              : id
          )
        }),
        integrationProjects: {
          ...state.integrationProjects,
          [updatedProject.id]: {
            ...(isUpdate && state.uuidHash[updatedProject.uuid]), // on updates, response may not include phases
            ...updatedProject
          }
        },
        uuidHash: {
          ...state.uuidHash,
          [updatedProject.uuid]: {
            ...(isUpdate && state.uuidHash[updatedProject.uuid]),
            ...updatedProject
          }
        }
      };
    }

    case constants.SET_INTEGRATIONS_MAPPING_STEP: {
      const { id, mappingStep } = payload;
      const { itemType } = deserializeItem(id);
      if (itemType !== 'project') return state;

      return {
        ...state,
        mappingSteps: {
          // ...state.mappingSteps,  currently not allowing multiple edits
          [id]: mappingStep
        }
      };
    }
    case constants.CLEAR_INTEGRATIONS_MAPPING_STEP: {
      const { id } = payload;
      const { itemType } = deserializeItem(id);
      if (itemType !== 'project') return state;

      const nextMappingSteps = { ...state.mappingSteps };
      delete nextMappingSteps[id];
      return {
        ...state,
        mappingSteps: nextMappingSteps
      };
    }
    case constants.CLEAR_PROJECTS_MAPPING_STEPS: {
      return {
        ...state,
        mappingSteps: {}
      };
    }
    case constants.CLEAR_MOVED_INTEGRATION_PROJECTS: {
      const newOrder = state.integrationProjectOrder.filter(
        (id) => !state.movedProjects[id]
      );
      const numProjectsRemoved =
        state.integrationProjectOrder.length - newOrder.length;
      return {
        ...state,
        integrationProjectOrder: newOrder,
        offset: Math.max(0, state.offset - numProjectsRemoved),
        movedProjects: {}
      };
    }
    case constants.SET_INTEGRATION_PROJECTS_SEARCH_LOADING: {
      return {
        ...state,
        isSearchLoading: payload.value
      };
    }
    // Phases / Activity phases
    case constants.MAP_ACTIVITY_PHASES.TRIGGER:
    case constants.MAP_PHASES.TRIGGER: {
      const {
        projectId,
        body: [{ mappingStatus, id, mosaicData, newDataType }]
      } = action.payload;
      const nextProject = { ...state.integrationProjects[projectId] };

      // Converting phase to different datatype - remove from project's list of phases
      if (newDataType) {
        nextProject.phases = nextProject.phases.filter(
          (phase) => phase.id !== id
        );
        return {
          ...state,
          integrationProjects: {
            ...state.integrationProjects,
            [projectId]: nextProject
          }
        };
      }

      nextProject.phases = nextProject.phases.map((phase) =>
        phase.id === id
          ? {
              ...phase,
              mappingStatus: [
                constants.MAPPING_STATUS.NULL,
                constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC
              ].includes(mappingStatus)
                ? mappingStatus
                : constants.MAPPING_STATUS.PENDING,
              mosaicData
            }
          : phase
      );

      // Optimistic update with FE-only PENDING mapping status
      return {
        ...state,
        integrationProjects: {
          ...state.integrationProjects,
          [projectId]: nextProject
        },
        uuidHash: {
          ...state.uuidHash,
          [nextProject.uuid]: nextProject
        },
        linkingMosaicActivity: null,
        linkingIntegrationActivity: null
      };
    }
    // Undo optimistic update
    case constants.MAP_ACTIVITY_PHASES.FAILURE:
    case constants.MAP_PHASES.FAILURE: {
      const { projectId, original } = action.payload.requestPayload;
      const nextProject = { ...state.integrationProjects[projectId] };
      nextProject.phases = nextProject.phases.map((phase) =>
        phase.uuid === original.uuid ? original : phase
      );

      return {
        ...state,
        integrationProjects: {
          ...state.integrationProjects,
          [projectId]: nextProject
        },
        uuidHash: {
          ...state.uuidHash,
          [nextProject.uuid]: nextProject
        }
      };
    }

    case constants.MAP_ALL_PHASES.TRIGGER: {
      const { projectId } = action.payload;
      const currentProject = { ...state.integrationProjects[projectId] };

      // Optimistic update with CREATE_NEW mapping status
      // import all phases at project level only maps the first level of phases
      return {
        ...state,
        integrationProjects: {
          ...state.integrationProjects,
          [projectId]: {
            ...currentProject,
            phases: currentProject.phases.map((phase) =>
              phase.levelNumber === 1 &&
              phase.mappingStatus === constants.MAPPING_STATUS.NULL
                ? {
                    ...phase,
                    mappingStatus: constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC
                  }
                : phase
            )
          }
        }
      };
    }

    case constants.MAP_ALL_ACTIVITIES.TRIGGER: {
      const { projectId, target } = action.payload;
      const currentProject = { ...state.integrationProjects[projectId] };

      // Optimistic update with CREATE_NEW mapping status
      // import all entities one level below target
      return {
        ...state,
        integrationProjects: {
          ...state.integrationProjects,
          [projectId]: {
            ...currentProject,
            phases: currentProject.phases.map((phase) =>
              phase.levelNumber === target.levelNumber + 1 &&
              phase.parentUUID === target.uuid &&
              phase.mappingStatus === constants.MAPPING_STATUS.NULL
                ? {
                    ...phase,
                    mappingStatus: constants.MAPPING_STATUS.CREATE_NEW_ON_MOSAIC
                  }
                : phase
            )
          }
        }
      };
    }

    /* ----------------------------- Activity phases ---------------------------- */
    case constants.SET_LINKING_INTEGRATION_ACTIVITY_PHASE: {
      return {
        ...state,
        linkingIntegrationActivity: payload.activity
      };
    }
    case constants.SET_LINKING_MOSAIC_ACTIVITY_PHASE: {
      return {
        ...state,
        linkingMosaicActivity: payload.activity
      };
    }
    case constants.CLEAR_PHASES_MAPPING_STEPS:
    case constants.CLEAR_LINKING_ACTIVITY_PHASES: {
      return {
        ...state,
        linkingMosaicActivity: null,
        linkingIntegrationActivity: null
      };
    }

    default: {
      return state;
    }
  }
};

export default createIntegrationReducer(projects, initialState);
