import React, { useEffect } from 'react';
import { AccessPermissionEdit } from 'PermissionsModule/components/settingsViews/AccessTabViews/AccessPermissionEdit';
import { PermissionsOptions } from 'PermissionsModule/components/PermissionsSections/shared/PermissionsOptions';
import { PermissionsTable } from '../PermissionsTable';
import { usePermissionsEdit } from 'PermissionsModule/hooks/usePermissionsEdit';
import { fetchTeamPermissions } from 'PermissionsModule/actionCreators';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { PERMISSION_SECTION } from 'PermissionsModule/constants';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { UnsavedChangesModal } from 'components/Modals';
import styled from 'styled-components';
import { PermissionInfoModalToggle } from '../PermissionsSections/shared/PermissionInfoModalToggle';

// Permission sections in the access tab
const ACCESS_PAGE_SECTIONS = [
  PERMISSION_SECTION.ORGANIZATION,
  PERMISSION_SECTION.INTEGRATIONS,
  PERMISSION_SECTION.TIMESHEETS,
  PERMISSION_SECTION.WORK_PLANS,
  PERMISSION_SECTION.PROFILE,
  PERMISSION_SECTION.BUDGET,
  PERMISSION_SECTION.PORTFOLIOS,
  PERMISSION_SECTION.REPORTS,
  PERMISSION_SECTION.DASHBOARDS
];

export const PermissionsSettingsAccessTab = () => {
  // Uncomment when we need expand all permissions
  // const allExpanded = useAppSelector(getAllPermissionActionsExpanded);

  const { newPermissionSettings } = useFeatureFlags();

  const teamId = useAppSelector(getSelectedTeamId);
  const dispatch = useAppDispatch();

  const fetchTeamPermissionsRequestStatusId = `${fetchTeamPermissions.type}-access-tab`;

  const {
    isEditing,
    setIsEditing,
    makeEditPermission,
    handleSavePermissions,
    handleCancelEdit,
    hasUnsavedChanges,
    permissionEdits,
    isSpecificMemberCheckedByPermissionAction,
    isLoading
  } = usePermissionsEdit({ fetchTeamPermissionsRequestStatusId });

  useEffect(() => {
    if (teamId) {
      dispatch(
        fetchTeamPermissions({
          teamId,
          meta: {
            requestStatusId: fetchTeamPermissionsRequestStatusId
          }
        })
      );
    }
  }, [dispatch, fetchTeamPermissionsRequestStatusId, teamId]);

  if (!newPermissionSettings) {
    return <></>;
  }

  return (
    <>
      {isEditing && (
        <PermissionInfoModalToggleContainer>
          <PermissionInfoModalToggle />
        </PermissionInfoModalToggleContainer>
      )}
      <PermissionsOptions
        isEditing={isEditing}
        toggleEditing={setIsEditing}
        onSave={handleSavePermissions}
        onCancel={handleCancelEdit}
      />
      {/* Use read/write view. */}
      {isEditing ? (
        <AccessPermissionEdit
          makeEditPermission={makeEditPermission}
          unSavedEdits={permissionEdits}
          isSpecificMemberCheckedByPermissionAction={
            isSpecificMemberCheckedByPermissionAction
          }
          sectionOrder={ACCESS_PAGE_SECTIONS}
        />
      ) : (
        <div style={{ height: '85%' }}>
          <PermissionsTable
            variant="access"
            isLoading={isLoading}
            sectionOrder={ACCESS_PAGE_SECTIONS}
          />
        </div>
      )}
      <UnsavedChangesModal
        isOpen={false}
        toggle={undefined}
        onConfirm={undefined}
        shouldBlock={isEditing && hasUnsavedChanges}
        shouldSetIsClosingOnClose={false}
        body="Do you want to save your changes before you leave?"
      />
    </>
  );
};

const PermissionInfoModalToggleContainer = styled.div`
  width: 800px;
  margin-bottom: -2px;
  display: flex;
  justify-content: flex-end;
`;
