import * as constants from 'appConstants';
const {
  FETCH_USER_ACTIVITIES_FOR_ACTIONABLE,
  FETCH_ACTIVITY_LOG_USER_ACTIVITIES,
  OPEN_ACTIVITY_LOG_MODAL,
  CLOSE_ACTIVITY_LOG_MODAL,
  CLEAR_USER_ACTIVITIES,
  CLOSE_ACTIVITY_SIDE_MENU
} = constants;

export const initialFilterState = {
  userActivities: [],
  // offset, allFetched are not being used yet. If necessary, add required logic for it in reducer.
  offset: 0,
  allFetched: false,
  isFetching: false
};

const initialState = {
  userActivities: [],
  isActivityLogModalOpen: false,
  activityLogModalLocation: null,
  actionableId: null,
  actionableType: null,
  isFetching: false,
  offset: 0,
  allFetched: false,
  filterStates: {}
};

const userActivities = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.TRIGGER: {
      const { keepCurrentActivitiesOnTrigger } = payload;
      return {
        ...state,
        isFetching: true,
        ...(!keepCurrentActivitiesOnTrigger && { userActivities: [] })
      };
    }
    case FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.SUCCESS: {
      const { user_activities } = payload.response;
      return {
        ...state,
        isFetching: false,
        userActivities: user_activities
      };
    }
    case FETCH_ACTIVITY_LOG_USER_ACTIVITIES.TRIGGER: {
      const { initial, filterStateId } = payload;

      if (filterStateId) {
        const shouldUseInitial = initial || !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(shouldUseInitial
                ? initialFilterState
                : state.filterStates[filterStateId]),
              isFetching: true
            }
          }
        };
      } else {
        return {
          ...state,
          isFetching: true,
          userActivities: initial ? [] : state.userActivities,
          allFetched: initial ? false : state.allFetched
        };
      }
    }
    case FETCH_ACTIVITY_LOG_USER_ACTIVITIES.FAILURE: {
      const { requestPayload } = payload;
      const { filterStateId } = requestPayload;

      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isFetching: false
            }
          }
        };
      } else {
        return {
          ...state,
          isFetching: false
        };
      }
    }
    case FETCH_ACTIVITY_LOG_USER_ACTIVITIES.SUCCESS: {
      const { user_activities } = payload.response;
      const { requestPayload } = payload;
      const { offset, limit, filterStateId } = requestPayload;
      // when offset and limit are not provided, it means we are not paginating. replace the array not concat.
      const noPagination = offset === undefined && limit === undefined;

      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              userActivities: noPagination
                ? user_activities
                : [
                    ...(state.filterStates[filterStateId]?.userActivities ??
                      []),
                    ...user_activities
                  ],
              isFetching: false,
              offset:
                (offset !== undefined ? offset : state.offset) + (limit || 0),
              allFetched: user_activities.length < limit
            }
          }
        };
      } else {
        return {
          ...state,
          isFetching: false,
          allFetched: user_activities.length < limit,
          userActivities: [...state.userActivities, ...user_activities],
          offset: (offset !== undefined ? offset : state.offset) + (limit || 0)
        };
      }
    }
    case OPEN_ACTIVITY_LOG_MODAL: {
      return {
        ...state,
        isActivityLogModalOpen: true,
        actionableId: payload.actionableId,
        actionableType: payload.actionableType,
        activityLogModalLocation: payload.modalLocation
      };
    }
    case CLEAR_USER_ACTIVITIES: {
      return {
        ...state,
        userActivities: []
      };
    }
    case CLOSE_ACTIVITY_LOG_MODAL: {
      return {
        ...state,
        isActivityLogModalOpen: false,
        actionableId: null,
        actionableType: null,
        activityLogModalLocation: null
      };
    }
    case CLOSE_ACTIVITY_SIDE_MENU: {
      return {
        ...state,
        userActivities: []
      };
    }

    default: {
      return state;
    }
  }
};

export default userActivities;
