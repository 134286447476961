import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  makeGetActiveWorkloadPlannerFilter,
  getOrderedAllActivitiesWithArchived,
  getAllActivityRowInfo,
  getIsFetchingActivities
} from 'selectors';
import { updateAccountFilterLocal, fetchActivities } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';

const copy = {
  searchPlaceholder: 'Search work category or select below'
};

const ActivitiesFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  activities,
  activitiesHash,
  isLoading,
  isHidden
}) => {
  const setFilterItems = (nextActivities) => {
    updateAccountFilterLocal({
      ...activeFilter,
      activity_ids: nextActivities
    });
  };

  const items = useMemo(
    () => activities.map((activity) => activity.id),
    [activities]
  );

  useEffect(() => {
    fetchActivities({});
  }, []);

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={items}
      copy={copy}
      isLoading={isLoading}
      batchClearOnly
      initialSelectedItems={activeFilter.activity_ids}
      setFilterItems={setFilterItems}
      itemHash={activitiesHash}
      labelKey="title"
      toggleLabel="Work Category"
      togglePluralLabel="Work Categories"
      filterId={activeFilter.id}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps),
    activities: getOrderedAllActivitiesWithArchived(state),
    activitiesHash: getAllActivityRowInfo(state),
    isLoading: getIsFetchingActivities(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal,
  fetchActivities
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ActivitiesFilterDropdown);
