import { useState, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useAppSelector } from 'reduxInfra/hooks';

import DownArrow from 'icons/QBDownArrow';
import PositionsDropdown from 'components/roles/dropdowns/PositionsDropdown';
import { SectionTitle, SelectContainer, SelectButton } from './styles';
import { getPositions } from 'BudgetModule/selectors/positions';

const SelectStandardRoleSection = ({
  title = 'ROLE',
  onSelect,
  positionId,
  disabledTooltip = '',
  disabled = false
}: {
  title?: string;
  onSelect: (...args: any[]) => void;
  positionId?: Nullable<number>;
  disabledTooltip?: string;
  disabled?: boolean;
}) => {
  const [isSelectRoleOpen, setIsSelectRoleOpen] = useState(false);
  const targetRef = useRef(null);
  const positionsHash = useAppSelector(getPositions);
  const position = positionId && positionsHash[positionId];

  const isNoneSelected = !positionId;
  const renderRole = () => {
    return (
      <div
        ref={targetRef}
        onClick={() => {
          if (!disabled) {
            setIsSelectRoleOpen(true);
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        {!isNoneSelected && position ? (
          <RoleName
            data-for="app-tooltip"
            data-tip={disabledTooltip}
            data-effect="solid"
            data-tip-disable={!disabled}
            disabled={disabled}
            className="role-selector-role-name no-text-overflow"
          >
            {position.name}
          </RoleName>
        ) : (
          <SelectButton>
            Select <DownArrow className="down-arrow" />
          </SelectButton>
        )}
      </div>
    );
  };

  return (
    <div className="modal-section">
      <SectionTitle>{title}</SectionTitle>
      {renderRole()}
      <SelectContainer>
        {isSelectRoleOpen && (
          <PositionsDropdown
            onClose={() => {
              setIsSelectRoleOpen(false);
            }}
            onSelect={onSelect}
            targetRef={targetRef}
            closeOnSelect
            noHeader={true}
            projectId={undefined}
          />
        )}
      </SelectContainer>
    </div>
  );
};

export default SelectStandardRoleSection;

const RoleName = styled.div<{ disabled: boolean }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  width: fit-content;
  color: ${({ disabled }) =>
    disabled ? theme.colors.colorLightGray14 : theme.colors.colorSemiDarkGray1};
  cursor: pointer;
`;
