import React from 'react';
import { connect } from 'react-redux';
import {
  updateProjectStatus,
  deleteProjectStatus,
  createProjectStatus
} from 'actionCreators';
import { getMatchedRouteParams } from 'selectors';
import { withRouter } from 'react-router-dom';
import OptionsSelectFlyoutListItem from 'components/OptionsSelectFlyout/OptionsSelectFlyoutListItem';
import {
  StyledProjectStatusDot,
  StyledProjectStatusContainer,
  StyledProjectStatusText,
  StyledEmptyProjectStatusDot
} from './styles';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const staticNewStatus = { title: 'New Status', color: '#cccccc', id: 'new' };
const unselectStatus = { title: 'No Status', color: '#DEDEDE', id: null };

const statusCopy = {
  inputPlaceholder: 'New Status',
  addHeader: 'Add Status',
  editHeader: 'Edit Status'
};

class ProjectStatusListItem extends React.Component {
  renderListItem = (item) => (
    <StyledProjectStatusContainer>
      {item === unselectStatus ? (
        <StyledEmptyProjectStatusDot />
      ) : (
        <StyledProjectStatusDot color={item.color} />
      )}

      <StyledProjectStatusText>{item.title}</StyledProjectStatusText>
    </StyledProjectStatusContainer>
  );

  handleSave = (updatedState) => {
    const {
      createProjectStatus,
      updateProjectStatus,
      handleEditClear,
      listItem,
      isNew,
      matchedParams: { boardId }
    } = this.props;
    const payload = {
      ...listItem,
      board_id: boardId,
      ...updatedState
    };
    const apiCall = isNew ? createProjectStatus : updateProjectStatus;
    apiCall(payload);
    handleEditClear();
  };

  render() {
    const { deleteProjectStatus, isUnselect } = this.props;
    return (
      <OptionsSelectFlyoutListItem
        handleSave={this.handleSave}
        handleDelete={deleteProjectStatus}
        colorPickerOriginType={ORIGIN_TYPE_STRINGS.PROJECT_STATUS}
        staticNewListItem={isUnselect ? unselectStatus : staticNewStatus}
        copy={statusCopy}
        renderListItem={this.renderListItem}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  updateProjectStatus,
  deleteProjectStatus,
  createProjectStatus
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectStatusListItem)
);
