import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PinTasksIcon = () => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#0074D9"
        fillRule="evenodd"
        d="M10.061.762a.924.924 0 0 0-1.31 0 .924.924 0 0 0 0 1.31l-3.895 2.29c-1.049-.377-1.982-.342-2.52.197l3.273 3.274L.371 13.07a.372.372 0 0 0-.003.527.372.372 0 0 0 .527-.004l5.238-5.237 3.273 3.273c.539-.538.574-1.472.197-2.52l2.291-3.896a.924.924 0 0 0 1.31 0 .924.924 0 0 0 0-1.31L10.06.762z"
      />
    </g>
  </SvgIcon>
);

export default PinTasksIcon;
