import styled from 'styled-components';
import theme from 'theme';
import { Modal } from 'reactstrap';

/* ---------- AddEditStandardRatesModal, AddEditStandardRolesModal ---------- */

export const StyledModal = styled(Modal)`
  width: 468px;
  .modal-content {
    padding: 25px 30px;
    border: 1px solid ${theme.colors.colorLightGray1};
  }
`;

export const Header = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorPureBlack};
  font-size: 18px;
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  &:first-child {
    padding-right: 5px;
  }
`;

export const StyledInput = styled.input<{ isInvalid?: boolean }>`
  border: 1px solid
    ${(props) =>
      props.isInvalid ? theme.colors.colorDeleteRed : 'transparent'};
  border-bottom: 1px solid
    ${(props) =>
      props.isInvalid
        ? theme.colors.colorDeleteRed
        : theme.colors.colorPaleGray4};
  background: ${theme.colors.colorLightGray19};
  padding: 5px;
  font-size: 13px;
  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid ${theme.colors.colorRoyalBlue};
  }
  &::placeholder {
    color: ${theme.colors.colorMediumGray3};
  }
  transition: border ${(props) => (props.isInvalid ? 0 : 150)}ms ease-in-out;
`;

export const InputLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorLightGray15};
  margin-bottom: 3px;
`;

export const InputsRow = styled.div`
  display: flex;
  align-items: center;
`;

export const HelpText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  margin-top: 15px;
`;

export const Body = styled.div`
  margin-bottom: 20px;
  padding: 0 20px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

/* ------------------------------------ - ----------------------------------- */
