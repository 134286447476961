import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import CollapseAllButton from 'ReportsModule/components/Time/TimesheetsTable/CollapseAllButton';
import useCollapse from 'appUtils/hooks/useCollapse';
import { membersCapacityTableHelpers } from './helpers/layout';
import noop from 'lodash/noop';
import { useMemberListItemsBuilder } from './helpers/useMemberListItemsBuilder';
import { useMemberGroupedListsBuilder } from './helpers/useMemberCapacityGroupListItemsBuilder';
import { memberCapacitiesTableConstants } from './constants';
import {
  getSelectedTeamId,
  getTeamMembersHash,
  makeGetSortedTeamMembers
} from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';
import { BaseTableList } from 'components/Table/types';
import { TableProps, TableV2 } from 'components/Table/TableV2';
import { StyledCapacitiesTable, TableHeaderContainer } from './styles';
import {
  OpenCapacityPolicyMemberTimelineParams,
  CreateCustomPolicyParams
} from './types';
import theme from 'theme';
import { AddEditCapacityPolicyModal } from 'CapacityModule/components/AddEditCapacityPolicyModal';
import { CapacityPolicyMemberTimeline } from 'CapacityModule/components/CapacityPolicyMemberTimeline';
import { fetchCapacityPoliciesPerMember } from 'CapacityModule/actionCreators/capacityGroup';
import { permissionsUtils } from 'PermissionsModule/utils';

const {
  columnWidths,
  allColumns,
  ROW_TYPES,
  rowToCells,
  stickyHeaderCells,
  emptyRow
} = membersCapacityTableHelpers;

const { membersTypes } = memberCapacitiesTableConstants;

export const CapacitiesTable = () => {
  const [
    capacityPolicyMemberTimelineParams,
    setCapacityPolicyMemberTimelineParams
  ] = useState<OpenCapacityPolicyMemberTimelineParams | undefined>();

  const [createCustomPolicyParams, setCreateCustomPolicyParams] =
    useState<CreateCustomPolicyParams>();

  const _setCreateCustomPolicyParams = useCallback(
    (params: CreateCustomPolicyParams) => {
      setCreateCustomPolicyParams(params);
    },
    []
  );

  const dispatch = useAppDispatch();

  const getSortedTeamMembers = useMemo(makeGetSortedTeamMembers, []);

  const sortedTeamMembers = useAppSelector((state) =>
    getSortedTeamMembers(state, { isIncludeArchived: true })
  );

  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const teamId = useAppSelector(getSelectedTeamId);

  const { groupTotalCounts, ordersByGroup } = useMemo(() => {
    const regularMemberIds: number[] = [];
    const guestIds: number[] = [];

    sortedTeamMembers.forEach((member) => {
      const teamMember = teamMembersHash[member.account.id];

      if (teamMember) {
        const isProjectGuest = permissionsUtils.getIsProjectGuest(teamMember);

        if (isProjectGuest) {
          guestIds.push(teamMember.account.id);
        } else {
          regularMemberIds.push(teamMember.account.id);
        }
      }
    });

    return {
      ordersByGroup: {
        [membersTypes.regularMembers]: regularMemberIds,
        [membersTypes.guests]: guestIds
      },
      groupTotalCounts: {
        [membersTypes.regularMembers]: regularMemberIds.length,
        [membersTypes.guests]: guestIds.length
      }
    };
  }, [sortedTeamMembers, teamMembersHash]);

  const {
    toggleCollapse,
    toggleCollapseAll,
    getIsOpen,
    allCollapsed: isAllCollapsed
  } = useCollapse({
    toggleCallback: noop,
    defaultAllOpen: true,
    topLevelCollapseCount: Object.keys(membersTypes).length
  });

  const memberGroupedListsBuilder = useMemberGroupedListsBuilder({
    getIsOpen,
    toggleCollapse,
    ordersByGroup,
    groupTotalCounts,
    listItemsBuilder: useMemberListItemsBuilder({
      openCapacityPolicyMemberTimeline: setCapacityPolicyMemberTimelineParams,
      requestCreateCustomPolicy: _setCreateCustomPolicyParams
    })
  });

  const mainList: BaseTableList = useMemo(() => {
    const listItems = memberGroupedListsBuilder({
      order: [membersTypes.regularMembers, membersTypes.guests],
      parentGroupId: 'main'
    });

    const list: BaseTableList = {
      listItems,
      isList: true,
      id: 'main',
      isOpen: true,
      isFullyLoaded: true,
      skipHeader: true,
      addEmptyRow: true
    };

    return list;
  }, [memberGroupedListsBuilder]);

  const tableProps: TableProps = {
    mainList,
    columnWidthsByHeaderType: columnWidths,
    tableColumns: allColumns,
    rowTypesHash: ROW_TYPES,
    rowTypeToCellsByHeaderType: rowToCells,
    stickyHeaderCells,
    isStickyHeaderHidden: false,
    hasStickyHeader: true,
    maxHeight: window.innerHeight,
    showHeader: false,
    emptyRow
  };

  return (
    <>
      <ContainerDiv>
        <StyledCapacitiesTable>
          <TableHeaderContainer>
            <CollapseAllButton
              onToggle={toggleCollapseAll}
              isCollapsed={isAllCollapsed}
              className="collapse-all"
            />

            <TableHeaderText>WORK SCHEDULE</TableHeaderText>
          </TableHeaderContainer>
          <TableV2 {...tableProps} />
        </StyledCapacitiesTable>
      </ContainerDiv>
      {capacityPolicyMemberTimelineParams && (
        <CapacityPolicyMemberTimeline
          accountId={capacityPolicyMemberTimelineParams.accountId}
          teamMembershipId={capacityPolicyMemberTimelineParams.teamMembershipId}
          memberPolicyDropdownAnchorEl={
            capacityPolicyMemberTimelineParams.anchorEl
          }
          onClose={() => {
            setCapacityPolicyMemberTimelineParams(undefined);

            if (teamId) {
              dispatch(fetchCapacityPoliciesPerMember({ teamId }));
            }
          }}
        />
      )}

      {createCustomPolicyParams && (
        <AddEditCapacityPolicyModal
          isCustom
          teamMembershipIds={[createCustomPolicyParams.teamMembershipId]}
          onToggle={() => setCreateCustomPolicyParams(undefined)}
        />
      )}
    </>
  );
};

const ContainerDiv = styled.div`
  margin: 0 auto;
  position: relative;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const TableHeaderText = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
`;
