import { ProjectId } from 'ProjectsModule/models/project';
import { RateGroupId } from 'RatesModule/models/RateGroup';
import { TeamId } from 'TeamsModule/models/team';

export type BudgetViewSettingsId = number;

export enum BudgetViewSettingsType {
  Project = 'Project',
  Team = 'Team'
}

export enum BudgetTrackingType {
  Currency = 'currency',
  Hours = 'hours'
}

export enum PhaseBudgetType {
  Estimate = 'estimate',
  MemberEstimates = 'member_estimates'
}

export enum BillableContractType {
  FixedFee = 'Fixed Fee',
  Hourly = 'Hourly'
}

export enum NonBillableContractType {
  Internal = 'Internal'
}

export type ContractType = BillableContractType | NonBillableContractType;

export enum BillRateType {
  Activity = 'activity',
  Member = 'member',
  Position = 'position'
}

export enum BudgetCalculationTime {
  Midnight = 'midnight',
  Now = 'now'
}

export enum BudgetCalculationStatus {
  Finished = 'finished',
  NotStarted = 'not_started',
  Started = 'started'
}

export interface BudgetViewSettings {
  activity_phase_default_budget_contract_type: ContractType;
  activity_phase_default_budget_with: PhaseBudgetType;
  bill_rate_type: BillRateType;
  budget_calculation_status: Nullable<BudgetCalculationStatus>;
  budget_calculation_time: Nullable<BudgetCalculationTime>;
  budget_tracking_type: BudgetTrackingType;
  decimals: boolean;
  estimated_cost: boolean;
  estimated_hours: boolean;
  estimated_percent: boolean;
  fte_week_hours: number;
  id: BudgetViewSettingsId;
  is_scope_enabled: boolean;
  phase_default_budget_contract_type: ContractType;
  phase_default_budget_with: PhaseBudgetType;
  planned_cost: boolean;
  planned_hours: boolean;
  planned_percent: boolean;
  rate_group_id: Nullable<RateGroupId>;
  rate: boolean;
  remaining_cost: boolean;
  remaining_hours: boolean;
  remaining_percent: boolean;
  spent_cost: boolean;
  spent_hours: boolean;
  spent_percent: boolean;
  viewable_id: TeamId | ProjectId;
  viewable_type: BudgetViewSettingsType;
}
