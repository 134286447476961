import keyBy from 'lodash/keyBy';

export const integrations = [
  {
    label: 'QuickBooks',
    shortLabel: 'QuickBooks',
    id: 'quickbooks-desktop',
    member: 'Employee',
    members: 'Employees',
    client: 'Customer',
    clients: 'Customers',
    project: 'Sub-Customer',
    phase: 'Sub/sub-Customer',
    activity: 'Service',
    activities: 'Services',
    authRequired: true,
    nestingSupported: true,
    clientProjectDataChangeSupported: true,
    phaseProjectDataChangeSupported: true,
    linkAllPhasesSupported: true,
    isFinancial: true
  },
  {
    label: 'QuickBooks Online',
    shortLabel: 'QuickBooks',
    id: 'quickbooks-online',
    member: 'Employee',
    members: 'Employees',
    client: 'Customer',
    clients: 'Customers',
    project: 'Sub-Customer',
    phase: 'Sub/sub-Customer',
    activity: 'Service',
    activities: 'Services',
    authRequired: true,
    nestingSupported: true,
    clientProjectDataChangeSupported: true,
    phaseProjectDataChangeSupported: true,
    linkAllPhasesSupported: true,
    isFinancial: true
  },
  {
    label: 'Deltek Vision',
    id: 'deltek-vision-hosted',
    shortLabel: 'Deltek',
    // activities: 'LABOR CODE',
    member: 'Employee',
    members: 'Employees',
    client: 'Customer',
    clients: 'Customers',
    project: 'Project',
    phase: 'Phase',
    activity: 'Sub-phase/Task',
    activities: 'Sub-phases/Tasks',
    showPhaseNumber: true,
    nestingSupported: true,
    isFinancial: true
  },
  {
    label: 'Deltek Vision',
    id: 'deltek-vision-cloud',
    shortLabel: 'Deltek',
    // activities: 'LABOR CODE',
    member: 'Employee',
    members: 'Employees',
    client: 'Customer',
    clients: 'Customers',
    project: 'Project',
    phase: 'Phase',
    activity: 'Sub-phase/Task',
    activities: 'Sub-phases/Tasks',
    showPhaseNumber: true,
    nestingSupported: true,
    isFinancial: true
  },

  {
    label: 'Deltek Vantagepoint',
    id: 'deltek-vantagepoint-hosted',
    shortLabel: 'Deltek',
    activities: 'ACTIVITIES',
    showPhaseNumber: true,
    nestingSupported: true,
    isFinancial: true
  },
  {
    label: 'Deltek Vantagepoint',
    id: 'deltek-vantagepoint-cloud',
    shortLabel: 'Deltek',
    activities: 'ACTIVITIES',
    showPhaseNumber: true,
    nestingSupported: true,
    isFinancial: true
  },
  {
    label: 'Deltek Ajera',
    id: 'deltek-ajera-hosted',
    shortLabel: 'Deltek',
    activities: 'ACTIVITIES',
    showPhaseNumber: true,
    nestingSupported: true,
    isFinancial: true
  },
  {
    label: 'Deltek Ajera',
    id: 'deltek-ajera-cloud',
    shortLabel: 'Deltek',
    activities: 'ACTIVITIES',
    showPhaseNumber: true,
    nestingSupported: true,
    isFinancial: true
  },
  {
    label: 'Jira Cloud',
    id: 'jira-cloud',
    shortLabel: 'Jira',
    member: 'User',
    members: 'Users',
    project: 'Project',
    task: 'Issue/Story', // Not 100% sure
    phase: 'Epic', // Not 100% sure
    scope: 'Epic/Story', // Not 100% sure
    authRequired: true,
    nestingSupported: true,
    getOnChangeProjectToClientMenuTitle: () => 'Convert phases to projects',
    checkShouldShowChangeProjectToClientMenuOption: ({ selectedProjects }) => {
      // Spec: Only show this option on Jira if there is at least one of `selectedProjects` has phases
      // Note that the action will still include the projects without phases in the payload,
      // if there are mixes of project with and without phases
      // https://mosaicapp.slack.com/archives/C02AJM03REH/p1662040032508359?thread_ts=1660931864.559649&cid=C02AJM03REH
      return !!selectedProjects.find((project) => project.phases.length >= 1);
    },
    clientProjectDataChangeSupported: true,
    phaseProjectDataChangeSupported: true,
    linkAllPhasesSupported: false,
    isProjectManagement: true,
    moduleIdsToIgnore: new Set([4]) // refer to appUtils/integrationModuleUtils for list of ids that make up the tabs
  },
  {
    label: 'Jira Hosted',
    id: 'jira-hosted',
    shortLabel: 'Jira Hosted',
    member: 'User',
    members: 'Users',
    project: 'Project',
    task: 'Issue/Story',
    phase: 'Epic',
    scope: 'Epic/Story',
    authRequired: true,
    nestingSupported: true,
    getOnChangeProjectToClientMenuTitle: () => 'Convert phases to projects',
    checkShouldShowChangeProjectToClientMenuOption: ({ selectedProjects }) => {
      // Spec: Only show this option on Jira if there is at least one of `selectedProjects` has phases
      // Note that the action will still include the projects without phases in the payload,
      // if there are mixes of project with and without phases
      // https://mosaicapp.slack.com/archives/C02AJM03REH/p1662040032508359?thread_ts=1660931864.559649&cid=C02AJM03REH
      return !!selectedProjects.find((project) => project.phases.length >= 1);
    },
    clientProjectDataChangeSupported: true,
    phaseProjectDataChangeSupported: true,
    linkAllPhasesSupported: false,
    isProjectManagement: true,
    moduleIdsToIgnore: new Set([4]) // refer to appUtils/integrationModuleUtils for list of ids that make up the tabs
  },
  {
    label: 'Github',
    id: 'github',
    shortLabel: 'Github',
    member: 'Member',
    projects: 'Repository/Project',
    isProjectManagement: true,
    task: 'Issue/Project Card'
  },
  // {
  //   label: 'Asana',
  //   id: 'tray-asana',
  //   isProjectManagement: true,
  //   redirectToIntegrationOnSuccess: false, // Asana provisioning has multiple steps using Tray config wizard, so we don't want to redirect after provision call finishes
  //   shortLabel: 'Asana',
  //   moduleIdsToIgnore: new Set([2, 4]),
  //   skipLinkMembersWarningModal: true,
  //   hidePhaseImportSettings: true,
  //   shouldFetchIntegrationsOnSuccessProvision: false,
  //   hideMakeMainProjectAPhase: true
  // },
  {
    label: 'Asana',
    id: 'asana',
    isProjectManagement: true,
    shortLabel: 'Asana',
    moduleIdsToIgnore: new Set([2, 4]),
    skipLinkMembersWarningModal: true,
    hidePhaseImportSettings: true,
    hideMakeMainProjectAPhase: true,
    fetchWorkspacesEndpointPath: 'forward/asana/asana/getWorkspaces',
    provisionWithWorkspaces: true
  },
  {
    id: 'csv-agent',
    label: 'CSV Agent',
    shortLabel: 'CSV Agent',
    doNotShowIcon: true
  },
  {
    label: 'Office 365',
    shortLabel: 'Office 365',
    id: 'office365',
    project: 'Project',
    task: 'Stories/Tasks',
    phase: 'Epic',
    activities: 'Epics',
    scope: 'Stories/Epics',
    authRequired: true,
    nestingSupported: true,
    clientProjectDataChangeSupported: false,
    phaseProjectDataChangeSupported: false,
    linkAllPhasesSupported: false,
    isCalendar: true
  },
  {
    label: 'Salesforce',
    shortLabel: 'Salesforce',
    id: 'salesforce',
    project: 'Opportunity',
    projects: 'Opportunities',
    task: 'Task',
    tasks: 'Tasks',
    member: 'User',
    members: 'Users',
    client: 'Account',
    clients: 'Accounts',
    isCRM: true,
    moduleIdsToIgnore: new Set([4])
  },
  {
    label: 'Google Calendar',
    id: 'tray-google-calendar',
    shortLabel: 'Google Calendar',
    redirectToIntegrationOnSuccess: false,
    shouldFetchIntegrationsOnSuccessProvision: false,
    isCalendar: true,
    moduleIdsToIgnore: new Set([2, 3, 4])
  },
  {
    label: 'Slack',
    id: 'slack',
    comingSoon: true,
    isCommunication: true
  }
];
export const integrationsHash = keyBy(integrations, (item) => item.id);
export const provisionableIntegrations = {
  'quickbooks-online': true,
  'deltek-ajera-cloud': true,
  'deltek-vision-cloud': true,
  'deltek-vantagepoint-cloud': true,
  'jira-cloud': true,
  github: true
};

export const PROJECTS_VIEWS = {
  IMPORT: 'IMPORT',
  LINK: 'LINK',
  LINKED: 'LINKED',
  NO_MOVE: 'NO_MOVE',
  DO_NOT_LINK: 'DO_NOT_LINK'
};
export const PROJECTS_GROUPED_BY = {
  CLIENTS: 'CLIENTS',
  BOARDS: 'BOARDS',
  NONE: 'NONE'
};

export const LINK_STATUSES = {
  LINKED: 'LINKED',
  IMPORTING: 'IMPORTING',
  UPDATING: 'UPDATING',
  LINK_WITH_EXISTING: 'LINK_WITH_EXISTING',
  ADD: 'ADD',
  DISABLED: 'DISABLED',
  ARCHIVED: 'ARCHIVED',
  PARTIALLY_LINKED: 'PARTIALLY_LINKED',
  MAPPED_PROJECT_NO_TIME_SYNCED_WARNING: 'MAPPED_PROJECT_NO_TIME_SYNCED_WARNING' // eg. project mapped, phases not
};

export const agentUrlsHash = {
  'jira-cloud': 'jira',
  office365: 'office365'
};

export const buildAgentURl = (integration) =>
  agentUrlsHash[integration]
    ? `https://${process.env.AGENT_NAMESPACE}-${agentUrlsHash[integration]}-agent.mosaicapp.com`
    : '';

export const PAGE_SIZE = 45;

export const defaultServerUrls = {
  'tray-asana': 'https://tray.io/graphql',
  'tray-google-calendar': 'https://tray.io/graphql',
  office365: 'https://google.com', // These are dummy values, will be replaced by actual value from IS settings later
  github: 'https://placeholder.github.com'
};
