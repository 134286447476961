import { usePopover } from 'components/Popover/usePopover';
import { useRef, useCallback, ComponentProps } from 'react';
import { ValueOf } from 'type-fest';
import { Popover as ReactstrapPopover } from 'reactstrap';
import MultilevelDropdown, { MultilevelDropdownProps, MOUSE_EVENTS } from '.';

const useMultiLevelDropdown = () => {
  const { Popover, openPopover, closePopover } = usePopover();
  const dropdownRef = useRef<any>(null);
  const closeDropdown = useCallback(() => {
    closePopover();
  }, [closePopover]);

  const openDropdown = useCallback(() => {
    openPopover({ target: dropdownRef.current });
  }, [openPopover]);

  const MultiLevelDropdownComponent = useCallback(
    ({
      menuContent,
      onClickHash = {},
      openMethod,
      offset,
      containerCustomStyle,
      popoverProps
    }: {
      menuContent: MultilevelDropdownProps['menuContent'];
      onClickHash?: MultilevelDropdownProps['onClickHash'];
      openMethod?: ValueOf<typeof MOUSE_EVENTS>;
      offset?: ComponentProps<typeof ReactstrapPopover>;
      containerCustomStyle?: string;
      popoverProps?: ComponentProps<typeof Popover>;
    }) => {
      return (
        <Popover offset={offset} {...popoverProps}>
          <MultilevelDropdown
            onClickHash={onClickHash}
            menuContent={menuContent}
            openMethod={openMethod}
            containerCustomStyle={containerCustomStyle}
          />
        </Popover>
      );
    },
    [Popover]
  );

  return {
    dropdownRef,
    openDropdown,
    closeDropdown,
    MultiLevelDropdown: MultiLevelDropdownComponent
  };
};

export default useMultiLevelDropdown;
