import { callApi } from 'appUtils/api';
import { UpdateTeamAuthSettingsParams } from './types';

class AuthApi {
  updateTeamAuthSettings(
    token: string,
    { teamId, teamMfaSetting }: UpdateTeamAuthSettingsParams
  ) {
    const body: Record<string, unknown> = {
      team_id: teamId,
      auth_settings: {
        toggled_mfa: teamMfaSetting
      }
    };

    const fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    return callApi('team', token, fetchInit);
  }
}

export default new AuthApi();
