import React from 'react';
import { connect } from 'react-redux';
import { navigateToStandardRolesSettings } from 'actionCreators';
import { getTeamSlug, getUserIsAdmin } from 'selectors';

import styled from 'styled-components';
import theme from 'theme';
import { TextButton } from 'components/styles';

import withPositionsDropdownManager from './withPositionsDropdownManager';
import ReactTooltip from 'react-tooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

import { filterItemWithWhiteSpace } from 'appUtils/search';

const ItemDetails = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const StyledItemName = styled.div`
  color: ${(props) =>
    !props.isArchived
      ? theme.colors.colorSemiDarkGray2
      : theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding-right: 10px;
`;

const ItemRow = styled.div`
  padding-left: 15px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
`;

/* ------------------------------------ - ----------------------------------- */

/**
 * Dropdown for displaying list of positions that come from HOC withPositionsDropdownManager.
 *
 * If projectId is provided and existing project unassigned roles exist, the dropdown will first show
 * the existing roles, + a sticky row button allowing to switch the list to show standard positions.
 */
const PositionsDropdown = ({
  dropdownItems,
  copy,
  setIsShowingStandardPositions,
  isLoading,
  onClose,
  onSelect,
  closeOnSelect,
  targetRef,
  teamSlug,
  isAdmin,
  navigateToStandardRolesSettings,
  headerTitle,
  showStickyRow,
  hideFooter,
  noHeader,
  listWidth
}) => {
  const itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });

  const handleSelect = (e, { item }) => {
    e.preventDefault();
    onSelect(item);
    if (closeOnSelect) onClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const onFooterClick = () => {
    if (isAdmin) {
      navigateToStandardRolesSettings({ teamSlug, openInNewWindow: true });
      ReactTooltip.hide();
      handleClose();
    }
  };

  /* --------------------------------- render --------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;

    return (
      <ItemRow key={item.id}>
        <ItemDetails>
          <StyledItemName>{item.name}</StyledItemName>
        </ItemDetails>
      </ItemRow>
    );
  };

  const renderHeaderButton = () => {
    return (
      <TextButton
        color="white"
        padding="4px 8px"
        backgroundColor={theme.colors.colorRoyalBlue}
        onClick={handleClose}
      >
        Done
      </TextButton>
    );
  };

  return (
    <MultiStepFlyout
      copy={copy}
      target={targetRef}
      items={dropdownItems}
      idKey="id"
      renderHeaderButton={renderHeaderButton}
      renderItem={renderItem}
      handleSelect={handleSelect}
      isWhite
      itemFilter={itemFilter}
      handleClose={handleClose}
      onStickyClick={() => setIsShowingStandardPositions((curr) => !curr)}
      stickyRow={showStickyRow}
      stickyBelowSearch
      hideFooter={hideFooter}
      onFooterClick={onFooterClick}
      popoverClassName="add-positions-dropdown"
      listWidth={listWidth || 350}
      listHeight={222}
      itemHeight={48}
      searchEnabled
      canMultiSelect
      isLoading={isLoading}
      headerTitle={headerTitle}
      noHeader={noHeader}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => ({
    teamSlug: getTeamSlug(state),
    isAdmin: getUserIsAdmin(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  navigateToStandardRolesSettings
};

export default withPositionsDropdownManager(
  connect(makeMapStateToProps, mapDispatchToProps)(PositionsDropdown)
);
