import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import ErrorMessage from './ErrorMessage';
import { AddEditProjectForm, DeleteModal } from '..';
import ProjectNotificationSettings from 'components/ProjectNotificationSettings';
import BoardSelectMenu from './BoardSelectMenu';
import cn from 'classnames';
import KaratLeft from 'icons/KaratLeft';
import Popover from 'components/Popover';
import useMappingStatus from 'appUtils/useMappingStatus';
import { DATA_TYPES } from 'IntegrationsModule/constants';
import {
  PROJECT_BUDGET_STATUS_OPTIONS,
  BUDGET_STATUSES
} from 'appConstants/budgetStatuses';
import BudgetStatusDropdown from 'BudgetModule/components/BudgetModal/BudgetStatusDropdown';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const LinkedIconBackground = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 15px;
  background-color: ${theme.colors.colorTranslucentGray4};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeader = styled.h1`
  color: ${theme.colors.colorMediumGray9};
  font-size: 26px;
  font-weight: 600;
  margin: 0;
`;

const DropdownIcon = styled(KaratLeft)`
  transform: rotate(-90deg);
  margin-left: 8px;
`;

const StyledDropdownToggle = styled.div`
  padding: 0 10px;
  min-width: 96px;
  height: 30px;
  background: ${(props) =>
    props.isCompleted ? '#f3f3f3' : theme.colors.colorCalendarBlue};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: ${(props) =>
    props.isCompleted
      ? theme.colors.colorMediumGray9
      : theme.colors.colorPureWhite};
  cursor: pointer;
  path {
    fill: ${(props) =>
      props.isCompleted
        ? theme.colors.colorMediumGray9
        : theme.colors.colorPureWhite};
  }
`;

const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BillableIndicator = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 15px;
  color: ${(props) => (props.checked ? 'white' : '#979797')};
  background-color: ${(props) => (props.checked ? '#1A7D45' : '#f3f3f3')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  ${(props) =>
    !props.isDisabled &&
    ` &:hover {
    background-color: #2f80ed;
    border-color: #2f80ed;
    color: white;
    cursor: pointer;
  }`}
`;

const NotificationIconContainer = styled.div`
  display: flex;
  margin-right: 15px;
`;

const StyledMenuItem = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: ${theme.colors.colorMediumGray9};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 13px 17px;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;

const StatusText = styled.span`
  text-transform: capitalize;
`;

const AddEditProjectModal = ({
  project,
  isOpen,
  isArchived,
  isEditModal,
  toggleModal,
  handleSubmit,
  errorMessage,
  title,
  description,
  client,
  number,
  billable,
  handleChange,
  group,
  groupList,
  changeSelectedGroup,
  disableSave,
  deleteModalIsOpen,
  toggleDeleteModal,
  deleteProjectOnClick,
  jobNumbers,
  clients,
  handleClientSelect,
  handleArchive,
  modalProjectIsPersonal,
  personalBoardId,
  setSelectedBoard,
  openAddEditGroupModal,
  teamName,
  userIsAdmin,
  closing,
  finishCloseModal,
  closeAllModals,
  isAnythingChanged,
  renderDeleteModalBody,
  renderDeleteModalDelete,
  isCreatingOrUpdatingProject,
  canArchiveProject
}) => {
  const [projectStatus, setProjectStatus] = useState(null);
  const [projectSubStatus, setProjectSubStatus] = useState(null);
  const [statusDropdownIsOpen, setStatusDropdownIsOpen] = useState(false);
  const { isHoursOnly } = useIsHoursOnly();

  const { renderMappingStatusIcon } = useMappingStatus({
    id: project?.id,
    dataType: DATA_TYPES.PROJECTS,
    Container: LinkedIconBackground
  });

  useEffect(() => {
    if (project) {
      project.budget_status
        ? setProjectStatus(project.budget_status)
        : setProjectStatus(BUDGET_STATUSES.ACTIVE); // What do we want the "default" status to be if the projects budget status is null
      project.budget_sub_status
        ? setProjectSubStatus(project.budget_sub_status)
        : setProjectSubStatus(null);
    } else {
      setProjectStatus(BUDGET_STATUSES.ACTIVE);
      setProjectSubStatus(null);
    }
  }, [project]);

  const handleProjectStatusChange = (newStatus) => {
    handleChange({
      target: {
        name: 'budget_status',
        value: newStatus.budget_status
      }
    });
    handleChange({
      target: {
        name: 'budget_sub_status',
        value: newStatus.budget_sub_status
      }
    });
    setProjectStatus(newStatus.budget_status);
    setProjectSubStatus(newStatus.budget_sub_status);
  };

  const target = useRef(null);
  const isAdministrativeBoard = group?.is_administrative;

  return (
    <div>
      {isEditModal ? (
        <DeleteModal
          isOpen={!!deleteModalIsOpen}
          toggle={toggleDeleteModal}
          deleteOnClick={deleteProjectOnClick}
          renderBody={renderDeleteModalBody}
          renderDelete={renderDeleteModalDelete}
          component={'project'}
        />
      ) : null}
      <Modal
        isOpen={isOpen && !deleteModalIsOpen}
        toggle={toggleModal}
        className={cn('add-edit-project-modal center-on-page', {
          'board-select-stage': !group
        })}
        onClosed={finishCloseModal}
        backdrop="static"
      >
        {group && !isAdministrativeBoard ? (
          <>
            <ErrorMessage message={errorMessage} />
            <div className="add-edit-project-modal-title">
              <StyledHeader>
                {isEditModal
                  ? 'Project Info'
                  : modalProjectIsPersonal
                  ? 'New Personal Project'
                  : 'New Project'}
              </StyledHeader>
              <StyledActionsContainer>
                {!modalProjectIsPersonal && renderMappingStatusIcon()}
                {isEditModal && !modalProjectIsPersonal && (
                  <NotificationIconContainer>
                    <ProjectNotificationSettings
                      project={project}
                      onProjectModal
                    />
                  </NotificationIconContainer>
                )}
                {!modalProjectIsPersonal && !isHoursOnly && (
                  <BillableIndicator
                    checked={billable}
                    data-for="app-tooltip"
                    data-tip={`Click to make project ${
                      billable ? ' Not ' : ''
                    } Billable`}
                    data-html
                    data-effect="solid"
                    onClick={() =>
                      handleChange({
                        target: {
                          name: 'billable',
                          value: !billable
                        }
                      })
                    }
                  >
                    $
                  </BillableIndicator>
                )}
                {!modalProjectIsPersonal && (
                  <StyledDropdownToggle
                    isCompleted={projectStatus === BUDGET_STATUSES.COMPLETE}
                    onClick={() =>
                      setStatusDropdownIsOpen(!statusDropdownIsOpen)
                    }
                    ref={target}
                  >
                    <StatusText>
                      {' '}
                      {projectStatus}{' '}
                      {projectSubStatus ? `- ${projectSubStatus}%` : ''}{' '}
                    </StatusText>
                    <DropdownIcon height={10} width={10} />
                    <BudgetStatusDropdown
                      isOpen={statusDropdownIsOpen}
                      target={target}
                      project={project}
                      closePopover={() => setStatusDropdownIsOpen(false)}
                      handleProjectStatusChange={handleProjectStatusChange}
                    />
                  </StyledDropdownToggle>
                )}
              </StyledActionsContainer>
            </div>
            <AddEditProjectForm
              title={title}
              description={description}
              client={client}
              isAnythingChanged={isAnythingChanged}
              number={number}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isEditForm={isEditModal}
              isArchived={isArchived}
              group={group}
              groupList={groupList}
              changeSelectedGroup={changeSelectedGroup}
              disableSave={disableSave}
              deleteModal={toggleDeleteModal}
              jobNumbers={jobNumbers}
              clients={clients}
              handleClientSelect={handleClientSelect}
              handleArchive={handleArchive}
              modalProjectIsPersonal={modalProjectIsPersonal}
              personalBoardId={personalBoardId}
              userIsAdmin={userIsAdmin}
              billable={billable}
              isNewProject={!isEditModal}
              closeAllModals={closeAllModals}
              projectId={project?.id}
              isCreatingOrUpdatingProject={isCreatingOrUpdatingProject}
              canArchiveProject={canArchiveProject}
            />
          </>
        ) : closing ? null : (
          <BoardSelectMenu
            setSelectedBoard={setSelectedBoard}
            boards={groupList}
            openAddEditGroupModal={openAddEditGroupModal}
            personalBoardId={personalBoardId}
            toggleModal={toggleModal}
            teamName={teamName}
          />
        )}
      </Modal>
    </div>
  );
};

AddEditProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEditModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  changeSelectedGroup: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groupList: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  group: PropTypes.object,
  deleteModalIsOpen: PropTypes.bool,
  toggleDeleteModal: PropTypes.func,
  deleteProjectOnClick: PropTypes.func
};
export default AddEditProjectModal;
