import { put, apply, cancelled } from 'redux-saga/effects';
import { logoutUser, denyPermission, handleErrorMessage } from 'actionCreators';
import { GENERIC_ACTION } from 'appConstants';
import { history } from 'store/configureStore';
import * as Sentry from '@sentry/browser';
import {
  IGNORED_ERROR_ACTIONS,
  IGNORED_ERROR_RESPONSES,
  makeErrorString
} from 'appConstants/ignoredErrors';
import { SURFACE_403_ACTIONS } from 'appConstants/surfaceForbiddenFailures';
import fetchManager from 'classes/FetchManager';

const makePermissionDeniedMissingActionDevWarning = (
  apiFn,
  entityFnName,
  position
) =>
  console.error(
    `Permission was denied by the server, but changeEntity was not passed the action as the argument at position ${position}.
    Check the saga that calls ${apiFn.name} to ensure it passes the action to ${entityFnName}`
  );
const makePermissionDeniedMissingPermissionDevWarning = (actionType) =>
  console.error(
    `Permission was denied by the server, but the permissions middleware permitted the action.
    Check the actionCreator that creates actions with a type of ${actionType} to ensure it is checking permission before making the request`
  );

const logSentryErrorToConsole = (error, response) =>
  console.error(`in production the following Sentry Error would be caught:
    ${response ? `responseUrl: ${response.url}` : ``}
    ${error ? `error: ${error}` : ``}
`);

// https://stackoverflow.com/a/45496068/11110458 - handles cases where error has been serialized/deserialized
const checkIsError = (e) => e && e.stack && e.message;

const isFailedToFetchError = (error) => error === 'Failed to fetch';

const makeFailedToFetchError = (url) => {
  let domain = '';

  try {
    domain = new URL(url).hostname;
  } catch {
    domain = url;
  }
  return new Error('Failed to fetch: ' + domain);
};

const captureError = (error, response, actionType, url) => {
  if (window.location.href.includes('localhost')) {
    return logSentryErrorToConsole(error, response);
  } else {
    const isError = checkIsError(error);
    let errorMessage = '';
    if (error) {
      try {
        if (isError) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage =
            error === 'Error' && actionType
              ? makeErrorString(actionType)
              : error;
        } else {
          errorMessage = JSON.stringify(error);
        }
      } catch (e) {
        errorMessage = 'failed to parse error';
      }
    }

    Sentry.withScope((scope) => {
      if (response) {
        scope.setExtra('responseUrl', response.url);
      }
      if (url) {
        scope.setExtra('requestUrl', url);
      }
      if (actionType) {
        scope.setExtra('action', actionType);
      }
      if (errorMessage) {
        scope.setExtra('errorMessage', errorMessage);
      }

      const exception = isError
        ? error
        : isFailedToFetchError(error)
        ? makeFailedToFetchError(url)
        : new Error(errorMessage);

      Sentry.captureException(exception);
    });
  }
};

export function* fetchEntity(entity, apiFn, id, body, action, priority) {
  const initialActionPayload = action.payload;
  const meta = action.meta;

  const request = {
    action,
    priority,
    apiFn,
    args: [id, ...body],
    idRef: { value: '' }
  };

  // try-finally for handling saga cancellations (including those due to takeLatest)
  try {
    yield put(entity.request(id, initialActionPayload));

    // using apply here to keep compatible with existing tests
    const { response, error, isUserFriendlyError, url } = yield apply(
      fetchManager,
      'addRequest',
      [request]
    );

    if (response && response.status === 403) {
      yield put(
        denyPermission({
          type: action?.type ?? GENERIC_ACTION,
          isUserFriendlyError,
          errorMessage: error,
          apiFnName: apiFn.name
        })
      );
      if (SURFACE_403_ACTIONS[action?.type]) {
        yield put(entity.failure(error, response, initialActionPayload, meta));
      }
      if (process.env.NODE_ENV !== 'production') {
        if (!action?.type) {
          makePermissionDeniedMissingActionDevWarning(apiFn, 'fetchEntity', 4);
        } else {
          makePermissionDeniedMissingPermissionDevWarning(action?.type);
        }
      }
    } else if (response && response.status === 401) {
      yield put(logoutUser());
      // yield call(window.Intercom, 'shutdown');
      history.push('/login');
    } else if (!error) {
      yield put(entity.success(response, id, initialActionPayload, meta));
    } else if (error) {
      yield put(entity.failure(error, response, initialActionPayload, meta));
      if (
        !IGNORED_ERROR_RESPONSES[error] &&
        !IGNORED_ERROR_ACTIONS[action?.type]
      ) {
        yield put(
          handleErrorMessage({
            type: action?.type ?? GENERIC_ACTION,
            isUserFriendlyError,
            errorMessage: error,
            apiFnName: apiFn.name
          })
        );
        captureError(error, response, action?.type, url);
      }
    } else {
      yield put(entity.failure(error, response, initialActionPayload, meta));
    }
    return { error, response };
  } finally {
    if (yield cancelled()) {
      // cancel the request so it no longer holds up the queue
      fetchManager.abortRequest(request.idRef.value);
      if (entity.abort) {
        yield put(entity.abort(initialActionPayload, meta));
      }
    }
  }
}

export function* changeEntity(
  entity,
  apiFn,
  body,
  action,
  sagaPayload = null,
  priority
) {
  const meta = action?.meta;
  const initialPayload = sagaPayload || action?.payload;

  const request = {
    action,
    priority,
    apiFn,
    args: body,
    idRef: { value: '' }
  };

  try {
    yield put(entity.request(initialPayload));

    // using apply here to keep compatible with existing tests
    const { response, error, isUserFriendlyError, url } = yield apply(
      fetchManager,
      'addRequest',
      [request]
    );

    if (response && response.status === 403) {
      yield put(
        denyPermission({
          type: action?.type ?? GENERIC_ACTION,
          errorMessage: error,
          apiFnName: apiFn.name
        })
      );
      if (SURFACE_403_ACTIONS[action?.type]) {
        yield put(entity.failure(error, response, initialPayload, meta));
      }
      if (process.env.NODE_ENV !== 'production') {
        if (!action?.type) {
          makePermissionDeniedMissingActionDevWarning(apiFn, 'changeEntity', 4);
        } else {
          makePermissionDeniedMissingPermissionDevWarning(action?.type);
        }
      }
    } else if (response && response.status === 401) {
      yield put(logoutUser());
      // yield call(window.Intercom, 'shutdown');
      yield put(
        entity.failure(response.statusText, response, initialPayload, meta)
      );
    } else if (!error) {
      yield put(entity.success(response, body, initialPayload, meta));
    } else if (error) {
      yield put(entity.failure(error, response, initialPayload, meta));
      if (
        !IGNORED_ERROR_RESPONSES[error] &&
        !IGNORED_ERROR_ACTIONS[action?.type]
      ) {
        yield put(
          handleErrorMessage({
            type: action?.type ?? GENERIC_ACTION,
            isUserFriendlyError,
            errorMessage: error,
            apiFnName: apiFn.name
          })
        );
        captureError(error, response, action?.type, url);
      }
    } else {
      yield put(entity.failure(error, response, initialPayload, meta));
    }
    return { error, response };
  } finally {
    if (yield cancelled()) {
      // cancel the request so it no longer holds up the queue
      fetchManager.abortRequest(request.idRef.value);
      if (entity.abort) {
        yield put(entity.abort(initialPayload, meta));
      }
    }
  }
}
