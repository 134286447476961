import ColorPickerCircleIcon from './ColorPickerCircleIcon';
import { StyledColorPickerButton } from './styles';

const ColorPickerButton = ({
  id,
  innerRef,
  isOpen,
  originType,
  alwaysShow,
  isDisabled
}) => (
  <StyledColorPickerButton ref={innerRef} className="color-picker-button">
    <ColorPickerCircleIcon
      alwaysShow={alwaysShow}
      // temporary color fix for temporary ui on TeamMemberCard
      color={originType === 'teamMembership' ? '' : 'colorSwirl'}
      id={id}
      isDisabled={isDisabled}
      isOpen={isOpen}
      originType={originType}
    />
  </StyledColorPickerButton>
);

export default ColorPickerButton;
