import { put, select } from 'redux-saga/effects';
import { getAuthToken, getSelectedTeamId } from 'selectors';
import { fetchEntity, changeEntity } from 'sagas/generics';
import {
  fetchOffices,
  createOffice,
  updateOfficeMetadata,
  updateOfficeLocation,
  deleteOffice,
  updateOfficeEntities
} from 'SettingsModule/actionCreators/settings/office';
import {
  fetchOfficesActionCreatorsMap,
  createOfficeActionCreatorsMap,
  updateOfficeMetadataActionCreatorsMap,
  updateOfficeLocationActionCreatorsMap,
  deleteOfficeActionCreatorsMap,
  updateOfficeEntitiesActionCreatorsMap
} from 'SettingsModule/entityActions/office';
import * as actionCreators from 'actionCreators';
import * as api from 'SettingsModule/api/office';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { ValueOf } from 'type-fest';

export function* fetchOfficesWorker(action: ReturnType<typeof fetchOffices>) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);

  yield fetchEntity(
    fetchOfficesActionCreatorsMap,
    api.fetchOffices,
    null,
    [token, { teamId }],
    action
  );
}

export function* createOfficeWorker(action: ReturnType<typeof createOffice>) {
  const { teamId, name, locationAttributes } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    createOfficeActionCreatorsMap,
    api.createOffice,
    [token, { teamId, name, locationAttributes }],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

export function* updateOfficeMetadataWorker(
  action: ReturnType<typeof updateOfficeMetadata>
) {
  const { id, name } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    updateOfficeMetadataActionCreatorsMap,
    api.updateOfficeMetadata,
    [token, { id, name }],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

export function* updateOfficeLocationWorker(
  action: ReturnType<typeof updateOfficeLocation>
) {
  const { officeLocationId, locationAttributes } = action.payload;
  const token = yield select(getAuthToken);

  yield changeEntity(
    updateOfficeLocationActionCreatorsMap,
    api.updateOfficeLocation,
    [token, { officeLocationId, locationAttributes }],
    action,
    // @ts-expect-error `sagaPayload` field not typed properly
    action.payload
  );
}

export function* deleteOfficeWorker(action: ReturnType<typeof deleteOffice>) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  yield changeEntity(
    deleteOfficeActionCreatorsMap,
    api.deleteOffice,
    [token, { id }],
    action
  );

  yield put(fetchOffices({ teamId }));
}

export function* updateOfficeEntitiesWorker(
  action: ReturnType<typeof updateOfficeEntities>
) {
  const { id, addEntities, removeEntities } = action.payload;
  const token = yield select(getAuthToken);

  const { response } = yield changeEntity(
    updateOfficeEntitiesActionCreatorsMap,
    api.updateOfficeEntities,
    [token, { id, addEntities, removeEntities }],
    action
  );

  if (response) {
    const affectedEntitiesMap = [
      ...(addEntities ?? []),
      ...(removeEntities ?? [])
    ].reduce<Record<ValueOf<typeof ENTITY_TYPES>, number[]>>((acc, curr) => {
      if (!acc[curr.entityType]) {
        acc[curr.entityType] = [];
      }

      if (acc[curr.entityType]) {
        if (!acc[curr.entityType].includes(curr.entityId)) {
          acc[curr.entityType].push(curr.entityId);
        }
      }

      return acc;
    }, {} as Record<ValueOf<typeof ENTITY_TYPES>, number[]>);

    yield Object.entries(affectedEntitiesMap).flatMap(
      ([entityType, entityIds]) =>
        entityType === ENTITY_TYPES.project
          ? [
              put(actionCreators.fetchAllProjects({ projectIds: [entityIds] })),
              ...entityIds.map((id) => put(actionCreators.fetchProjectById(id)))
            ]
          : []
    );
  }
}
