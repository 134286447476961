import styled from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import FolderIcon from 'icons/FolderIcon';
import AddPhaseIcon from 'icons/AddPhaseIcon';
import InfoIcon from 'icons/InfoIcon';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import theme from 'theme';

export const StyledSelectPhaseText = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 10px;
  margin-left: 15px;
  visibility: visible;
`;
export const StyledDot = styled.div`
  background: ${theme.colors.colorMediumGray5};
  background: ${calculateProjectColorPreference};
  border-radius: 4px;
  display: inline-block;
  height: 8px;
  margin: 0 4px 2px -12px;
  position: relative;
  width: 8px;
  vertical-align: middle;
`;

export const StyledMiddleContents = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledProjectTitle = styled.span`
  color: ${theme.colors.colorMediumGray9};
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
`;

export const StyledProjectDescription = styled.span`
  color: ${theme.colors.colorMediumGray5};
  font-weight: normal;
  font-size: 12px;
  margin-left: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    display: block;
    margin: 0;
  }
`;
export const StyledProjectNumber = styled.div`
  color: ${theme.colors.colorMediumGray5};
  line-height: 18px;
  height: 18px;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMiddleCell = styled.div`
  display: flex;
`;
export const StyledTeamName = styled.div`
  align-items: center;
  color: ${theme.colors.colorMediumGray5};
  display: flex;
  font-size: 11px;
  font-weight: 400;
`;

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  ${(props) => props.leftOffset && `padding-left: ${props.leftOffset}px;`}
  width: 100%;
  &:hover {
    background: #f0f0f0;
  }
`;

export const StyledInfo = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  position: relative;
`;

export const StyledProjectInfo = styled(StyledInfo)`
  padding-left: 40px;

  &:last-child {
    padding-right: 27px;
  }
`;

export const StyledPhaseNumber = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: -5px;
`;

export const StyledPhaseName = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 14px;
  font-weight: 600;
  flex: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledPhaseDate = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 13px;
  font-weight: 400;
  margin-top: -2px;
`;

export const StyledActivityPhaseDate = styled(StyledPhaseDate)`
  font-size: 12px;
`;

export const StyledTopCell = styled.div`
  align-items: center;
  display: flex;
`;
export const StyledBottomCell = styled.div`
  ${StyledProjectDescription} {
    margin-top: -4px;
  }
`;
export const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 3px;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  path {
    stroke: #808080;
    stroke-width: 3;
    fill: transparent;
  }
`;

export const StyledAddText = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorCalendarBlue};
`;

export const StyledPhaseInfo = styled(StyledInfo)`
  color: ${theme.colors.colorMediumGray5};
  padding-left: 50px;
  padding-right: 23px;

  &.is-small {
    margin-top: 0px;
    padding-top: 12px;

    ${StyledPhaseName} {
      font-size: 12px;
    }
    ${StyledPhaseDate} {
      font-size: 11px;
    }
  }
`;

export const StyledActivityInfo = styled(StyledInfo)`
  padding-left: 50px;
  padding-right: 23px;
`;

export const PhaseProjectListItem = styled.div`
  flex: 0 0 100%;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: ${({ itemHeight }) => itemHeight}px;
  &:hover {
    ${StyledSelectPhaseText} {
      color: ${theme.colors.colorCalendarRed};
    }
    ${StyledAddText} {
      color: ${theme.colors.colorRoyalBlue};
    }
    cursor: ${({ hasHoverBackground }) =>
      hasHoverBackground ? 'pointer' : ''};
    background: ${({ hasHoverBackground }) =>
      hasHoverBackground ? theme.colors.colorTranslucentGray4 : ''};
  }
`;

export const StyledKaratContainer = styled.div`
  align-items: center;
  display: flex;
  flex: none;
  height: 100%;
  justify-content: center;
  width: 60px;
  path {
    stroke-width: 1;
    stroke: ${theme.colors.colorCalendarGray};
  }
`;

export const StyledBackKaratContainer = styled.div`
  transform: rotate(90deg);
  height: 28px;
  width: 28px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-left: 12px;
  margin-top: -1px;
  margin-right: 63px;
  path {
    stroke: ${theme.colors.colorMediumGray9};
    fill: ${theme.colors.colorMediumGray9};
    stroke-width: 1;
  }
  display: ${(props) => (props.hide ? 'none' : 'flex')};
`;

export const LockIconContainer = styled.span`
  visibility: hidden;
`;

export const StyledThreeDotMenuItem = styled.div`
  height: 36px;
  width: 152px;
  border-radius: 3px;
  line-height: 39px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorMediumGray9};
  span {
    margin: 0px 4px;
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    ${LockIconContainer} {
      visibility: visible;
    }
  }
`;

export const StyledAddActivityButtonContainer = styled.div`
  height: 39px;
  width: 150px;
  border-radius: 3px;
  line-height: 39px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
  cursor: pointer;
  span {
    margin-right: 5px;
    margin-left: 10px;
  }
`;

export const StyledThreeDotContainer = styled.div`
  display: flex;
  flex: none;
  font-size: 12px;
  height: 36px;
  justify-content: center;
  // Collapse the vertical margins so that it does not affect layout.
  margin: -18px 0;
  width: 36px;
`;

export const StyledProjectThreeDotContainer = styled(StyledThreeDotContainer)`
  display: relative;
  margin-right: 12px;
  margin-left: -40px;
  width: 28px;
`;

export const StyledPhaseThreeDotContainer = styled(StyledThreeDotContainer)`
  // Shift to the right to line up with the closing icon.
  margin-right: -11px;
  span {
    margin-right: 5px;
    margin-left: 10px;
  }
`;

export const StyledActivityNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;

export const StyledActivityName = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 12px;
  font-weight: 400;
  flex: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMilestoneIconContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top};
  left: -20px;
`;

export const StyledAddPhaseIcon = styled(AddPhaseIcon)`
  margin-left: 17px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 17px;
`;

export const StyledGoToProjectIcon = styled(GoToProjectIcon)`
  margin-left: 11px;
  margin-top: 0.5px;
`;

export const StyledPhaseNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;

export const StyledSuggestedContainer = styled.div`
  color: #2f80ed;
  font-size: 11px;
  margin-left: 4px;
`;
