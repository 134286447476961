import styled from 'styled-components';
import theme from 'theme';
import round from 'lodash/round';

// can import barebones and style if desired
export const PartialDayIndicator = ({
  className = '',
  tag = 'div',
  style = {},
  remainder = 0,
  customDataTip = ''
}) => {
  const Tag = tag;
  const roundedRemainder = round(remainder, 2);

  return (
    <Tag
      className={className}
      style={style}
      data-tip={
        customDataTip ||
        `The last day of the Work <br> Plan is only ${roundedRemainder} hour${
          roundedRemainder === 1 ? '' : 's'
        }`
      }
      data-for="app-tooltip"
      data-html
      data-effect="solid"
    >
      {/* "*"" is 5-points in sans-serif fonts and 6 in serif fonts.
        What shows in your editor may not correspond to what shows in the app.
        Requirements are 5 pointed asterisk, and current font is sans-serif.
      */}
      *
    </Tag>
  );
};
const StyledPartialDayIndicator = styled(PartialDayIndicator)`
  color: ${theme.colors.colorCalendarRed};
  font-weight: ${(props) => (props.isBold ? 800 : 400)};
  font-size: ${(props) => props.fontSize || '11px'};
  position: relative;
  top: ${(props) =>
    props.top || '2px'}; // necessary b/c asterisk is not centered
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${(props) => props.lineHeight || undefined};
  cursor: pointer;
`;

// default export is styled
export default StyledPartialDayIndicator;
