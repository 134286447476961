import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import { getSortedTeamMembers } from 'selectors';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import { LocationsState } from 'SettingsModule/reducers/locations';
import { ValueOf } from 'type-fest';
import { RealEntityLocationType } from 'SettingsModule/models/location';

const getLocationsState: Selector<RootState, LocationsState> = (state) =>
  state.locations;

export const getTeamMembersLocationsHash = createSelector(
  getLocationsState,
  (state) => state.teamMembersLocationHash
);

export const getLocationsHash = createSelector(
  getLocationsState,
  (state) => state.locationsHash
);

const getEntityLocationsByEntityTypeHash = createSelector(
  getLocationsState,
  (state) => state.entityLocationsByEntityTypeHash
);

export const getLocationsArray = createSelector(getLocationsHash, (locations) =>
  Object.keys(locations)
    .sort()
    .flatMap((id) => locations[+id] ?? [])
);

export const getFormattedTeamMembersLocationsArray = createSelector(
  getTeamMembersLocationsHash,
  getSortedTeamMembers,
  (locationsHash, membersArray) =>
    membersArray.flatMap((member) => {
      const locationMembershipForMember = locationsHash[member.account.id];
      return locationMembershipForMember
        ? [
            {
              member,
              locationMembership: locationMembershipForMember
            }
          ]
        : [];
    })
);

export const makeGetLocationsByIds = () =>
  createSelector(
    (state, { ids }: { ids: number[] }) => ids,
    getLocationsHash,
    (ids, locationsHash) => ids.flatMap((id) => locationsHash[id] ?? [])
  );

export const makeGetEntityLocationsHash = () =>
  createSelector(
    (
      state,
      {
        entityType,
        entityId
      }: {
        entityType: ValueOf<typeof ENTITY_TYPES>;
        entityId: number;
      }
    ) => ({ entityType, entityId }),
    getEntityLocationsByEntityTypeHash,
    ({ entityType, entityId }, entityLocationsByEntityTypeHash) => {
      const valueToReturn: Record<number, RealEntityLocationType> =
        entityLocationsByEntityTypeHash[entityType]?.[entityId] ?? {};

      return valueToReturn;
    }
  );
