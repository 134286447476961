import theme from 'theme';
import styled from 'styled-components';

export const GraphCellContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const Bar = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  width: ${(props) => props.width}%;
  cursor: pointer;
  .show-on-hover {
    visibility: hidden;
    position: absolute;
    top: -17px;
    color: ${theme.colors.colorMediumGray9};
  }
  &:hover {
    .show-on-hover {
      visibility: visible;
    }
  }
`;

export const BarSection = styled.div`
  height: 100%;
  width: ${(props) => props.width}%;
  min-width: fit-content;
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorPureBlack};
  display: flex;
  align-items: center;
  padding: 0 3px;
  ${(props) =>
    props.isRightmost &&
    `
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
`;

export const BillableSection = styled(BarSection)`
  background: ${({ color }) => color || theme.colors.colorConnectedGreen};
  color: white;
`;

export const NonbillableSection = styled(BarSection)`
  background: ${theme.colors.colorLightGray20};
`;

export const HolidaySection = styled(BarSection)`
  border-top: 1px solid rgba(205, 205, 205, 0.4);
  border-bottom: 1px solid rgba(205, 205, 205, 0.4);
  ${(props) =>
    props.isRightmost &&
    `
    border-right: 1px solid rgba(205, 205, 205, 0.4);
  `}
  background: repeating-linear-gradient(
    135deg,
    #ffffff,
    #ffffff 1px,
    rgba(205, 205, 205, 0.4),
    rgba(205, 205, 205, 0.4) 4px
  );
`;

export const PtoSection = styled(BarSection)`
  border-top: 1px solid rgba(137, 184, 231, 0.3);
  border-bottom: 1px solid rgba(137, 184, 231, 0.3);
  ${(props) =>
    props.isRightmost &&
    `
    border-right: 1px solid rgba(137, 184, 231, 0.3);
  `}
  background: repeating-linear-gradient(
    135deg,
    #ffffff,
    #ffffff 1px,
    rgba(137, 184, 231, 0.3),
    rgba(137, 184, 231, 0.3) 4px
  );
`;

export const RemainingCapacitySection = styled(BarSection)`
  background: ${theme.colors.colorPaleGray5};
`;

export const PercentCell = styled.span`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: ${theme.colors.colorMediumGray9};
`;
