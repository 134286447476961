import { useCallback } from 'react';
import { ROW_TYPES } from '../tableConfigs';
import {
  BaseTableListItemsBuilder,
  BaseTableListItem
} from 'components/Table/types';
import keyBy from 'lodash/keyBy';
import { makeSuggestedMemberListFromActivityPhaseMembershipSuggestions } from 'SuggestionModule/components/FindPeople//utils';

import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import { serializeId } from 'appUtils';
import {
  MembersGroupedListsBuilderArgs,
  ActivityPhaseListItem,
  ActivityPhaseGroupedListsBuilderArgs
} from './types';
import useActivityPhases from 'appUtils/hooks/useActivityPhases/useActivityPhases';
import { serializeMemberAlternatesRowId } from '../../utils';
import { getAccountTotalsFromFormattedPhaseTotals } from 'BudgetModule/utils/projectAccounts';
import {
  ToggleAvailabilityView,
  AvailabilityViewTriggerSource,
  AvailabilityViewTriggerSourceType
} from '../../contexts/types';

export const useActivityPhasesGroupedListsBuilder = ({
  getIsOpen,
  toggleCollapse,
  toggleCollapseAll,
  setParentCollapseState,
  getCurrentParentCollapseState,
  listItemsBuilder,
  projectId,
  toggleAvailabilityView,
  selectedAvailabilityViewTriggerSource
}: UseNestedCollapseHookReturnedValues & {
  listItemsBuilder: BaseTableListItemsBuilder<
    BaseTableListItem,
    MembersGroupedListsBuilderArgs
  >;
  projectId: Nullable<number>;
  toggleAvailabilityView: ToggleAvailabilityView;
  selectedAvailabilityViewTriggerSource?: AvailabilityViewTriggerSource;
}) => {
  const {
    getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
    getFormattedActivityPhaseByPhaseIdAndActivityId,
    activityHash
  } = useActivityPhases({ projectId });

  const activityPhasesGroupedListsBuilder = useCallback(
    ({
      order,
      parentGroupId,
      phase,
      unassignedMemberBudgetId,
      phaseMembershipId,
      phaseMembership,
      accountSuggestionsByActivityPhaseMemberships,
      teamMembershipsByAccountId,
      phaseTotals
    }: ActivityPhaseGroupedListsBuilderArgs) => {
      const phaseId = phase.id;
      // Spec: Show phase suggestions as suggestion for activity phases as well
      const lists = order.reduce(
        (acc: ActivityPhaseListItem[], activityId: number) => {
          const activity = activityHash[activityId];
          const activityPhase = getFormattedActivityPhaseByPhaseIdAndActivityId(
            {
              phaseId,
              activityId
            }
          );

          const activityPhaseMembership = activityPhase
            ? getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId({
                activityPhaseId: activityPhase.id,
                memberBudgetId: unassignedMemberBudgetId
              })
            : undefined;

          if (activityPhase && activity && activityPhaseMembership) {
            const { id: activityPhaseMembershipId } = activityPhaseMembership;
            const { id: activityPhaseId } = activityPhase;
            const { id: activityId } = activity;

            const accountTotals = getAccountTotalsFromFormattedPhaseTotals({
              formattedPhaseTotals: phaseTotals,
              phase,
              activityId,
              activityPhaseMembershipId
            });

            const groupId = serializeId({
              itemType: 'activity-phase-with-this-unassigned-role',
              id: undefined,
              ids: [activityPhaseId, activityPhaseMembershipId]
            });

            const isListOpen = !!getIsOpen({
              parentId: parentGroupId,
              toggleId: groupId
            });
            const isShowingAllMembers = !!getIsOpen({
              parentId: groupId,
              toggleId: serializeMemberAlternatesRowId({
                id: activityPhaseMembershipId
              })
            });

            // Spec: Show phase suggestions as suggestion for activity phases as well

            // Future: This function can also handle actual suggestion for activity phase
            const {
              suggestedMembersToShow,
              numOfRemainingSuggestedMembers,
              allSuggestedMembers,
              shouldShowMemberAlternatesRow,
              memberIdsOrder
            } = makeSuggestedMemberListFromActivityPhaseMembershipSuggestions({
              accountSuggestionsByActivityPhaseMemberships,
              activityPhaseMembershipId: activityPhaseMembership.id,
              isShowingAllMembers,
              teamMembershipsByAccountId
            });

            // Members
            const memberlistItems = listItemsBuilder({
              order: memberIdsOrder,
              parentGroupId: groupId,
              membersHash: keyBy(suggestedMembersToShow, 'account_id'),
              phase,
              phaseMembership,
              activityPhase,
              activityPhaseMembership,
              activity,
              numOfRemainingSuggestedMembers,
              isShowingAllMembers,
              shouldShowMemberAlternatesRow,
              phaseTotals
            });

            const activityPhaseList = {
              id: activityPhaseId,
              activityPhaseId,
              customItems: memberlistItems.length
                ? []
                : [
                    {
                      rowType: ROW_TYPES.noMatchRow,
                      rowHeight: 40
                    }
                  ],
              listItems: memberlistItems,
              isList: true,
              isFullyLoaded: true,
              addEmptyRow: isListOpen,
              toggleCollapse: () =>
                toggleCollapse({ parentId: parentGroupId, toggleId: groupId }),
              isOpen: isListOpen,
              rowHeight: 50,
              rowType: ROW_TYPES.activityPhaseGroupRow,
              /* -------------------------------------------------------------------------- */
              phase,
              phaseMembership,
              activityPhase,
              activityPhaseMembership,
              activity,
              accountTotals,
              allSuggestedMembers,
              isAvailabilityViewActive:
                selectedAvailabilityViewTriggerSource?.type ===
                  AvailabilityViewTriggerSourceType.ActivityPhase &&
                selectedAvailabilityViewTriggerSource?.id === activityPhaseId,
              onToggleViewAvailability: () => {
                toggleAvailabilityView({
                  triggerSource: {
                    type: AvailabilityViewTriggerSourceType.ActivityPhase,
                    id: activityPhaseId
                  },
                  accountIds: memberIdsOrder
                });
              }
            };

            acc.push(activityPhaseList);
          }

          return acc;
        },
        []
      );

      /* -------------------------------- Collapse -------------------------------- */
      const collapseState = getCurrentParentCollapseState(parentGroupId);

      if (!collapseState) {
        setParentCollapseState({
          values: {
            totalNumToggles: lists.length
          },
          id: parentGroupId
        });
      } else if (collapseState.totalNumToggles !== lists.length) {
        // update number of toggles
        setParentCollapseState({
          values: {
            totalNumToggles: lists.length
          },
          id: parentGroupId
        });
      }

      return lists;
    },
    [
      getCurrentParentCollapseState,
      activityHash,
      getFormattedActivityPhaseByPhaseIdAndActivityId,
      getActivityPhaseMembershipByActivityPhaseIdAndMemberBudgetId,
      getIsOpen,
      selectedAvailabilityViewTriggerSource,
      listItemsBuilder,
      toggleCollapse,
      toggleAvailabilityView,
      setParentCollapseState
    ]
  );

  return activityPhasesGroupedListsBuilder;
};
