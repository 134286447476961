import { getSelectedTeamId } from 'selectors';
import {
  createOffice,
  updateOfficeMetadata,
  updateOfficeLocation,
  deleteOffice,
  updateOfficeEntities
} from 'SettingsModule/actionCreators/settings/office';
import { ENTITY_TYPES } from 'SettingsModule/constants';
import {
  getFormattedOfficesWithMembers,
  getOfficesHash
} from 'SettingsModule/selectors/offices';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { SettingTableTemplateWithMemberCells } from '../SettingTableTemplate/SettingTableTemplateWithMemberCells';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GroupData, RowDataWithGroupId } from '../SettingTableTemplate/types';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { ReactComponent as SmallPencilIcon } from 'icons/small-pencil.svg';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import { CreateUpdateEntityLocationModal } from '../CreateUpdateEntityLocationModal';
import { LocationAttributes } from 'SettingsModule/models/location';
import { LocationAttributesUtils } from 'SettingsModule/utils/locationUtils';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import {
  CreateOfficeFailure,
  UpdateOfficeLocationFailure
} from 'domain/office/failures';

const createOfficeRequestId = 'createOfficeModal__createOffice';
const updateOfficeRequestId = 'createOfficeModal__updateOffice';

export const OfficesTable = () => {
  const [editingGroupId, setEditingGroupId] = useState<number | undefined>();
  const [toDeleteGroupData, setToDeleteGroupData] = useState<
    GroupData | undefined
  >();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEntityLocationModalOpen, setIsEntityLocationModalOpen] =
    useState(false);

  const officesHash = useAppSelector(getOfficesHash);
  const formattedOffices = useAppSelector(getFormattedOfficesWithMembers);
  const teamId = useAppSelector(getSelectedTeamId);

  const createOfficeRequestStatus = useRequestStatus<CreateOfficeFailure>({
    requestStatusId: createOfficeRequestId
  });
  const updateOfficeRequestStatus =
    useRequestStatus<UpdateOfficeLocationFailure>({
      requestStatusId: updateOfficeRequestId
    });

  const officeBeingEdited = useMemo(
    () => (editingGroupId ? officesHash[editingGroupId] : undefined),
    [editingGroupId, officesHash]
  );
  const dispatch = useAppDispatch();

  const { groupData, rowData } = useMemo(() => {
    const groupData: GroupData[] = [];
    const rowData: RowDataWithGroupId<unknown>[] = [];

    formattedOffices.forEach(
      ({ id, name, team_membership_count, formattedOfficeMembers }) => {
        groupData.push({ id, label: name, totalCount: team_membership_count });

        formattedOfficeMembers.forEach((member) =>
          rowData.push({
            data: member,
            groupId: id,
            teamMember: member
          })
        );
      }
    );

    return { groupData, rowData };
  }, [formattedOffices]);

  const clearRequestStatuses = useCallback(() => {
    createOfficeRequestStatus.removeStatus();
    updateOfficeRequestStatus.removeStatus();
  }, [createOfficeRequestStatus, updateOfficeRequestStatus]);

  const handleCloseEntityLocationModal = useCallback(() => {
    setIsEntityLocationModalOpen(false);
    setEditingGroupId(undefined);
    clearRequestStatuses();
  }, [clearRequestStatuses]);

  useEffect(() => {
    if (
      createOfficeRequestStatus.status?.isSuccess ||
      updateOfficeRequestStatus.status?.isSuccess
    ) {
      handleCloseEntityLocationModal();
    }
  }, [
    createOfficeRequestStatus.status?.isSuccess,
    handleCloseEntityLocationModal,
    updateOfficeRequestStatus.status?.isSuccess
  ]);

  const failureText = useMemo(() => {
    if (
      createOfficeRequestStatus.status?.error ||
      updateOfficeRequestStatus.status?.error
    ) {
      return createOfficeRequestStatus.status?.error?.type === 'invalidAddress'
        ? 'Invalid address. Please try again.'
        : 'An error occurred. Please try again.';
    }
    return undefined;
  }, [
    createOfficeRequestStatus.status?.error,
    updateOfficeRequestStatus.status?.error
  ]);

  const handleCreateOfficeRequest = () => {
    setIsEntityLocationModalOpen(true);
  };

  const handleToggleOfficeDelete = () => {
    setIsDeleteModalOpen(false);
    setToDeleteGroupData(undefined);
  };

  const handleConfirmDeleteOffice = () => {
    setIsDeleteModalOpen(false);

    if (toDeleteGroupData) {
      dispatch(deleteOffice({ id: toDeleteGroupData.id }));
    }

    setToDeleteGroupData(undefined);
  };

  const handleRemoveMemberFromOffice = (
    rowData: RowDataWithGroupId<unknown>
  ) => {
    if (teamId) {
      dispatch(
        updateOfficeEntities({
          id: rowData.groupId,
          removeEntities: [
            {
              entityId: rowData.teamMember.id,
              entityType: ENTITY_TYPES.teamMembership
            }
          ]
        })
      );
    }
  };

  const handleSelectMembers = ({
    groupData,
    selectedMembers
  }: {
    groupData: GroupData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedMembers: any[];
  }) => {
    dispatch(
      updateOfficeEntities({
        id: groupData.id,
        addEntities: selectedMembers.map((member) => ({
          entityType: ENTITY_TYPES.teamMembership,
          entityId: member.id
        }))
      })
    );
  };

  const handleSubmitEntityLocation = ({
    locationAttributes,
    officeName
  }: {
    locationAttributes: LocationAttributes;
    officeName: string;
  }) => {
    if (!officeBeingEdited) {
      dispatch(
        createOffice({
          name: officeName,
          teamId,
          locationAttributes,
          requestStatusId: createOfficeRequestId
        })
      );
    } else {
      let isOfficeNameChanged = false;
      let isOfficeLocationChanged = false;
      if (officeName !== officeBeingEdited.name) {
        isOfficeNameChanged = true;

        dispatch(
          updateOfficeMetadata({
            id: officeBeingEdited.id,
            name: officeName,
            requestStatusId: updateOfficeRequestId
          })
        );
      }

      if (officeBeingEdited.location) {
        const isAnyOfficeFieldsChanged =
          !LocationAttributesUtils.isTwoLocationAttributesSame(
            officeBeingEdited.location,
            // `locationAttributes` are the updated attributes on the office that are to be updated
            locationAttributes
          );

        if (isAnyOfficeFieldsChanged && officeBeingEdited.location?.id) {
          isOfficeLocationChanged = true;
          dispatch(
            updateOfficeLocation({
              officeLocationId: officeBeingEdited.location?.id,
              locationAttributes,
              requestStatusId: updateOfficeRequestId,
              meta: {
                officeId: officeBeingEdited.id
              }
            })
          );
        }
      }

      if (!isOfficeNameChanged && !isOfficeLocationChanged) {
        handleCloseEntityLocationModal();
      }
    }
  };

  return (
    <>
      <RootContainer>
        <SettingTableTemplateWithMemberCells
          useGroup
          groupData={groupData}
          rowData={rowData}
          headerRowProps={{
            buttonProps: {
              label: 'Office',
              onClick: handleCreateOfficeRequest
            },
            blurbProps: {
              message:
                'Offices are used to group and filter projects and members when companies have multiple offices.'
            }
          }}
          groupRowProps={{
            groupRowMenus: [
              {
                label: 'Edit Office',
                icon: <SmallPencilIcon />,
                onClick: (data) => {
                  setEditingGroupId(data.id);
                  setIsEntityLocationModalOpen(true);
                }
              },
              {
                label: 'Delete Office',
                icon: <DeleteIcon />,
                onClick: (data) => {
                  setToDeleteGroupData(data);
                  setIsDeleteModalOpen(true);
                }
              }
            ],
            useInlineCreation: false
          }}
          memberRowProps={{ onRemoveRow: handleRemoveMemberFromOffice }}
          onSelectMembers={handleSelectMembers}
        />
      </RootContainer>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        component={'office'}
        toggle={handleToggleOfficeDelete}
        deleteOnClick={handleConfirmDeleteOffice}
      />
      {isEntityLocationModalOpen && (
        <CreateUpdateEntityLocationModal
          headerText={editingGroupId ? 'Edit Office' : 'Create New Office'}
          failureText={failureText}
          officeNameInputMethod={'input'}
          initialLocationAttributes={
            officeBeingEdited?.location ? officeBeingEdited.location : undefined
          }
          initialOfficeName={officeBeingEdited?.name}
          onLocationAttributesChanged={clearRequestStatuses}
          onToggle={handleCloseEntityLocationModal}
          onSubmit={handleSubmitEntityLocation}
        />
      )}
    </>
  );
};

const RootContainer = styled.div`
  height: 100%;
`;
