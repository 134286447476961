import * as constants from 'appConstants';
import { SORT_BY, SORT_ORDER } from 'appConstants/filters';
import { toggleRemoveOverlay } from 'actionCreators';

export const initialState = {
  settingsOpen: false,
  activeKey: '1',
  projectGroupMemberInputOpen: false,
  showInviteButton: false,
  showInviteForm: false,
  inviteFormOrigin: null,
  projectGroupMemberInputValue: '',
  isHelpMenuOpen: false,
  isEmailNotificationOn: true,
  selectedMember: null,
  memberType: '',
  dropdownTop: 0, // 'top' property to position dropdown next to clicked member
  isDropdownOpen: false,
  showRemoveOverlay: false,
  removeOverlayAccountIds: null,
  removeOverlayIsUnarchive: null,
  adminBudgetTableSort: {
    value: SORT_BY.member,
    order: SORT_ORDER.desc
  }
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.SET_ACTIVE_KEY:
      return {
        ...state,
        activeKey: action.payload
      };

    case constants.TOGGLE_MEMBER_INPUT:
      return {
        ...state,
        projectGroupMemberInputOpen: action.isOpen
      };
    case constants.SORT_BUDGET_SETTINGS:
      return {
        ...state,
        adminBudgetTableSort: action.payload
      };
    case constants.TOGGLE_INVITE_BUTTON:
      return {
        ...state,
        showInviteButton: action.showInviteButton
      };

    case toggleRemoveOverlay.type:
      return {
        ...state,
        showRemoveOverlay: action.payload.showRemoveOverlay,
        removeOverlayAccountIds: action.payload.showRemoveOverlay
          ? action.payload.accountIds
          : null,
        removeOverlayIsUnarchive:
          action.payload.removeOverlayIsUnarchive ?? null,
        willHardDeleteArchivedMember:
          action.payload.willHardDeleteArchivedMember ?? null
      };

    case constants.TOGGLE_INVITE_FORM: {
      const {
        options: { open, origin }
      } = action;
      return {
        ...state,
        origin: open ? origin : null, // don't set origin if closing
        showInviteForm: open
      };
    }

    case constants.SET_MEMBER_INPUT_VALUE:
      return {
        ...state,
        projectGroupMemberInputValue: action.payload
      };

    case constants.TOGGLE_HELP_MENU:
      return {
        ...state,
        isHelpMenuOpen: action.isOpen
      };

    case constants.TOGGLE_EMAIL_NOTIFICATION.TRIGGER:
      return {
        ...state,
        isEmailNotificationOn: action.payload.isEmailNotificationOn
      };

    case constants.CLEAR_SELECTED_TEAM_MEMBER:
      return {
        ...state,
        selectedMember: null,
        memberType: ''
      };

    case constants.CLOSE_ADD_MEMBERS_MODAL:
      return {
        ...state,
        projectGroupMemberInputOpen: false
      };

    case constants.USER.SUCCESS: {
      return {
        ...state,
        isEmailNotificationOn:
          action.payload.response.account.email_notification_on
      };
    }

    default:
      return state;
  }
};

export default settings;
