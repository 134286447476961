import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'views/projectPlanner/WorkloadEventsModal/styles';
import { getWorkloadEventsSelectedEvent } from 'selectors';
import { clearBatchSelectedWorkloadEventRecipients } from 'actionCreators';

const Text = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-right: 17px;
  padding-top: 2px;
  white-space: nowrap;
  color: ${theme.colors.colorRoyalBlue};
  &:hover {
    cursor: pointer;
  }
`;

const PercentageHeader = ({ data }) => {
  const selectedEvent = useSelector(getWorkloadEventsSelectedEvent) || {};
  const numRecipients = selectedEvent?.recipient_account_ids?.length || 0;
  const dispatch = useDispatch();

  const numTeamMembers = useMemo(() => {
    data.reduce((acc, curr) => (curr.account ? acc + 1 : acc), 0);
  }, [data]);

  const clearSelected = () => {
    if (numRecipients) {
      dispatch(
        clearBatchSelectedWorkloadEventRecipients({
          eventId: selectedEvent.scheduled_event_id
        })
      );
    }
  };

  return (
    <Header>
      <Text onClick={clearSelected}>
        {numRecipients > 0
          ? `Clear ${
              numRecipients === numTeamMembers ? 'All' : numRecipients
            } Selected`
          : 'All'}
      </Text>
    </Header>
  );
};

export default PercentageHeader;
