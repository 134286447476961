import { StyledItemContainer } from 'SuggestionModule/components/FindPeople/List/styles';
import { AddMemberToPhaseListItem } from 'SuggestionModule/components/FindPeople/List/ProjectTeam/types';

export const AddMemberToPhase = (
  AddMemberToPhaseListItem: AddMemberToPhaseListItem
) => {
  return (
    <StyledItemContainer
      className="add-member-to-phase"
      data-testid="add-member-to-phase-item-container"
    >
      Click + to Add Members
    </StyledItemContainer>
  );
};
