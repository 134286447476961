import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import TaskListSortContainer from './TaskListSortContainer';
import { getIsOnTeamProject, isOnTeamMembersTasks } from 'selectors';
import { toggleTaskCreation, flushBatchSelectedTasks } from 'actionCreators';
import {
  StyledTasksIcon,
  StyledHomeCompletedIcon,
  StyledHomeAssignedToMeIcon
} from 'views/Home/Sidebar/styles';
import { HomeHeaderText } from './styles';
import theme from 'theme';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  padding-left: ${(props) => props.paddingLeft || 0}px;
  margin-left: ${(props) => props.marginLeft || 0}px;
  width: auto;
  min-height: 50px;
  max-height: 75px;
  height: ${({ isCreatingNewTask }) =>
    isCreatingNewTask ? 'fit-content' : '50px'};
  min-width: 465px;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #eaeaea')};
  margin-bottom: ${({ position }) => (position === 0 ? '0' : '30px')};
  background-color: ${({
    isCreatingNewTask,
    isOnTeamProject,
    isOnMembersTasks
  }) =>
    (isCreatingNewTask && !isOnTeamProject) ||
    (!isCreatingNewTask && (isOnTeamProject || isOnMembersTasks))
      ? '#f3f3f3'
      : '#fff'};
  box-shadow: ${({ isCreatingNewTask }) =>
    isCreatingNewTask ? '0 0 4px rgba(0,0,0,0.1)' : 'none'};

  &:hover {
    background-color: ${({ position }) => position === -1 && '#f3f3f3'};
  }
`;

const iconStyle = `
height: 20px;
flex: 0 0 20px;
margin-right: 10px;
`;
const iconStyle2 = `
height: 24px;
flex: 0 0 24px;
margin-right: 10px;
`;

const PlaceHolder = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex: auto;
  visibility: visible;
  padding-left: ${({ isOnHomeView }) => (isOnHomeView ? '0px' : '24px')};
  min-width: ${({ isOnHomeView }) => (isOnHomeView ? '574px' : '477px')};
`;
class TaskListColumnsHeader extends Component {
  state = {
    isCreatingNewTask: false,
    newTask: {}
  };

  renderIcon = () => {
    const { isHomeCompleted, isOnAssignedByMeView } = this.props;

    return isOnAssignedByMeView ? (
      <StyledHomeAssignedToMeIcon
        iconStyle={iconStyle}
        currentColor={theme.colors.colorSemiDarkGray6}
      />
    ) : isHomeCompleted ? (
      <StyledHomeCompletedIcon
        iconStyle={iconStyle2}
        currentColor={theme.colors.colorBudgetGreen}
      />
    ) : (
      <StyledTasksIcon
        iconStyle={iconStyle + `margin-left: 8px;`}
        currentColor={theme.colors.colorSemiDarkGray6}
      />
    );
  };

  renderHeader = () => {
    const {
      isHomeCompleted,
      isOnAssignedByMeView,
      isOnMembersTasks,
      isDayPlannerView
    } = this.props;

    return (
      <HomeHeaderText>
        {!isDayPlannerView && (
          <>
            {this.renderIcon()}

            {isOnMembersTasks
              ? 'Tasks'
              : isOnAssignedByMeView
              ? 'Assigned By Me'
              : isHomeCompleted
              ? 'Completed'
              : 'My Tasks'}
          </>
        )}
      </HomeHeaderText>
    );
  };

  toggleTaskCreationHeader = () => {
    const {
      flushBatchSelectedTasks,
      toggleTaskCreation,
      shouldRenderCreateTaskButton,
      toggleIsCreatingTask
    } = this.props;
    if (!shouldRenderCreateTaskButton) {
      return;
    }
    flushBatchSelectedTasks();
    toggleTaskCreation();
    if (toggleIsCreatingTask) {
      toggleIsCreatingTask();
    }
    this.setState(({ isCreatingNewTask }) => ({
      isCreatingNewTask: !isCreatingNewTask
    }));
  };

  render() {
    const {
      position = 0,
      isSortable,
      isOnTeamProject,
      isOnMembersTasks,
      isOnHomeView,
      paddingLeft = 0,
      marginLeft = 0,
      noBorder
    } = this.props;

    const { isCreatingNewTask } = this.state;

    return (
      <Wrapper
        isCreatingNewTask={isCreatingNewTask}
        position={position}
        isOnTeamProject={isOnTeamProject && !isOnHomeView}
        isOnMembersTasks={isOnMembersTasks}
        isOnHomeView={isOnHomeView}
        paddingLeft={paddingLeft}
        marginLeft={marginLeft}
        noBorder={noBorder}
      >
        <PlaceHolder isOnHomeView={isOnHomeView}>
          {this.renderHeader()}
        </PlaceHolder>
        {isSortable && (
          <TaskListSortContainer
            setSortHeaderHovered={this.props.setSortHeaderHovered}
            unsetSortHeaderHovered={this.props.unsetSortHeaderHovered}
          />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isOnTeamProject: getIsOnTeamProject(state),
  isOnMembersTasks: isOnTeamMembersTasks(state)
});

export default withRouter(
  connect(mapStateToProps, {
    toggleTaskCreation,
    flushBatchSelectedTasks
  })(TaskListColumnsHeader)
);
