import { useCallback, useMemo } from 'react';
import useUnassignedRolesByProjectAndPhases, {
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
} from './useUnassignedRolesByProjectAndPhases';
import { getProjectHash } from 'ProjectsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import partition from 'lodash/partition';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { PhaseMembership } from 'ProjectsModule/phases/models/phaseMembership';
import { Project } from 'ProjectsModule/models/project';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';

const emptyArr = [];
const emptyObj = {};

type UseBudgetProjectAndPhasesMembershipsPropsType = {
  /** Mandatory  */
  projectId: number; // To know which project are we dealing with

  /** Optional  */
  phaseIds?: number[]; // To select which phases to partition the list for. Default to all phases of the project
};

/**
 * Partitioned the phase memberships of each phase into 2 lists
 * - First List: List of phase memberships that have an account id (assigned member)
 * - Second List: List of phase memberships that have no account id (unassigned member)
 */
const partitionPhaseMemberships = (phaseMemberships: PhaseMembership[]) => {
  const partitionedList = partition(phaseMemberships, (pm) => !!pm.account_id);

  return partitionedList;
};

const getPartitionedPhaseMembershipListByPhaseIds = ({
  phaseHash,
  phaseIds,
  project
}: {
  phaseHash: Record<number, Phase | undefined>;
  phaseIds: UseBudgetProjectAndPhasesMembershipsPropsType['phaseIds'];
  project: Project | undefined;
}) => {
  const phaseIdsToUse: number[] = phaseIds || project?.phase_orders || [];
  const partitionedPhaseMembershipsByPhaseIds = phaseIdsToUse.reduce(
    (acc: Record<number, [PhaseMembership[], PhaseMembership[]]>, phaseId) => {
      const phase = phaseHash[phaseId];
      const phaseMemberships = phase?.phase_memberships || [];
      const partitionedPhaseMemberships =
        partitionPhaseMemberships(phaseMemberships);

      acc[phaseId] = partitionedPhaseMemberships;

      return acc;
    },
    {}
  );

  return partitionedPhaseMembershipsByPhaseIds;
};

/**
 * A hook to handle logic that involves budget phase memberships
 * including:
 * - Partioning phase memberships per phase of a project into 2 assigned vs unassigned members
 * - Flattened that list into 1 single list
 * - Transforming the phase membership of unassigned member role in a form with position count
 * - Having the above transformed phase memberships into a hash by its id
 *
 * Coupled with useUnassignedRolesByProjectAndPhases
 *
 */
const useBudgetProjectAndPhasesMemberships = ({
  projectId,
  phaseIds
}: UseBudgetProjectAndPhasesMembershipsPropsType) => {
  const projectHash = useAppSelector(getProjectHash);
  const phaseHash = useAppSelector(getFlatPhasesHash);

  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
  } = useUnassignedRolesByProjectAndPhases({ projectId });

  const project = useMemo(
    () => projectHash[projectId],
    [projectHash, projectId]
  );

  const partitionedPhaseMembershipListByPhaseIds = useMemo(() => {
    return getPartitionedPhaseMembershipListByPhaseIds({
      phaseHash,
      project,
      phaseIds
    });
  }, [phaseHash, phaseIds, project]);

  const getPhase = useCallback(
    (phaseId: number) => phaseHash[phaseId],
    [phaseHash]
  );

  const getPartitionedPhaseMembershipListByPhaseId = useCallback(
    ({ phaseId }: { phaseId: number }) => {
      return partitionedPhaseMembershipListByPhaseIds[phaseId] || emptyArr;
    },
    [partitionedPhaseMembershipListByPhaseIds]
  );

  const getFlattenedPartitionedPhaseMembershipListByPhaseId = useCallback(
    ({ phaseId }: { phaseId: number }) => {
      return flatten(getPartitionedPhaseMembershipListByPhaseId({ phaseId }));
    },
    [getPartitionedPhaseMembershipListByPhaseId]
  );

  const getFormattedFlattenedPartitionedPhaseMembershipListByPhaseId =
    useCallback(
      ({
        phaseId
      }: {
        phaseId: number;
      }): (
        | PhaseMembership
        | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
        | undefined
      )[] => {
        const flattenedPhaseMemberships =
          getFlattenedPartitionedPhaseMembershipListByPhaseId({ phaseId });

        return flattenedPhaseMemberships.map((phaseMembership) =>
          phaseMembership.account_id
            ? phaseMembership
            : getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
                {
                  phaseId: phaseMembership.phase_id,
                  memberBudgetId: phaseMembership.member_budget_id
                }
              )
        );
      },
      [
        getFlattenedPartitionedPhaseMembershipListByPhaseId,
        getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
      ]
    );

  const getFormattedPhaseMembershipHashOfPhase = useCallback(
    ({ phaseId }: { phaseId: number }) => {
      return keyBy(
        getFormattedFlattenedPartitionedPhaseMembershipListByPhaseId({
          phaseId
        }),
        'id'
      );
    },
    [getFormattedFlattenedPartitionedPhaseMembershipListByPhaseId]
  );

  return {
    getPhase,
    getPartitionedPhaseMembershipListByPhaseId,
    getFlattenedPartitionedPhaseMembershipListByPhaseId,
    getFormattedPhaseMembershipHashOfPhase,
    project
  };
};

export default useBudgetProjectAndPhasesMemberships;
