import styled from 'styled-components';
import theme from 'theme';
import sumBy from 'lodash/sumBy';
import cn from 'classnames';
import SimpleBar from 'components/Bars/SimpleBar';

const StyledTextContainer = styled.div`
  color: ${theme.colors.colorMediumGray9};
  width: 70%;
  height: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: auto;
  font-size: 13px;
  padding-top: 19px;

  .total-available-hours {
  }
`;

const calculateMemberTotalPlannedHours = (member) => {
  const schedule = member.schedule ? Object.values(member.schedule) : [];

  return sumBy(schedule, (date) => date.planned_hours);
};

const MemberHoursCell = ({ row }) => {
  const {
    member,
    customRowProps: { showDemoSuggestions }
  } = row.original;
  const memberTotalAvailableHours = +member.total_available_hours;

  if (!showDemoSuggestions) {
    const memberTotalPlannedHours = calculateMemberTotalPlannedHours(member);
    const plannedHoursToAvailableHoursRatio = Math.min(
      100,
      memberTotalAvailableHours
        ? (memberTotalPlannedHours / memberTotalAvailableHours) * 100
        : 0
    );
    // Not including the below text to the tooltip because we don't know what Click to view Availability will do
    // <br /> Over Dates Required. <br /> Click to view Availability
    const sections = [
      {
        sectionBackground: theme.colors.colorGoldenYellow3,
        width: `${plannedHoursToAvailableHoursRatio}%`
      }
    ];

    return (
      <StyledTextContainer
        data-html
        data-class="center"
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={`${memberTotalPlannedHours}h Planned / ${memberTotalAvailableHours}h Available`}
      >
        <div className="total-available-hours">
          {memberTotalAvailableHours}h
        </div>
        <SimpleBar sections={sections} />
      </StyledTextContainer>
    );
  }

  // This is the demo case. We don't know how to compute planned hours for demo members
  return (
    <StyledTextContainer>{memberTotalAvailableHours}h</StyledTextContainer>
  );
};

export default MemberHoursCell;
