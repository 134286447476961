import budget from './budget';
import memberBudgets from './memberBudgets';
import rates from './rates';
import memberRates from './memberRates';
import positions from './positions';
import teamRoles from './teamRoles';
import hours from './hours';
import budgetSettings from './budgetSettings';
import salaries from './salaries';
import budgetBilling from './budgetBilling';
import budgetRecords from './budgetRecords';
import projectAccounts from './projectAccounts';

export const reducerMap = {
  budget,
  memberBudgets,
  rates,
  positions,
  memberRates,
  teamRoles,
  hours,
  budgetSettings,
  salaries,
  budgetBilling,
  budgetRecords: budgetRecords({}),
  modalBudgetRecords: budgetRecords({ isInModal: true }),
  projectAccounts
};
