import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import { fetchPositions } from 'BudgetModule/actionCreators';
import {
  getPositions,
  getIsFetchingPositions,
  getCurrentPositions
} from 'BudgetModule/selectors/positions';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { AccountFilter } from 'models/filter';
import { Position } from 'models/position';

const copy = {
  searchPlaceholder: 'Search role or select below'
};

type PositionFilterDropdownParams = PropsFromRedux & {
  activeFilter: AccountFilter;
  teamId: number;
  positions: Array<Position>;
  updateAccountFilterLocal: ({ ...filters }: AccountFilter) => void;
  isLoading: boolean;
  positionsHash: Record<number, Position>;
  isHidden: boolean;
};

const PositionFilterDropdown = ({
  activeFilter,
  teamId,
  updateAccountFilterLocal,
  positions,
  isLoading,
  fetchPositions,
  positionsHash,
  isHidden
}: PositionFilterDropdownParams) => {
  const setFilterItems = (nextPositions: Array<number>) => {
    updateAccountFilterLocal({
      ...activeFilter,
      position_ids: nextPositions
    });
  };

  const items = useMemo(
    () => positions.map((position) => position.id),
    [positions]
  );

  useEffect(() => {
    if (teamId) fetchPositions({ teamId });
  }, [fetchPositions, teamId]);

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={items}
      copy={copy}
      isLoading={isLoading}
      batchClearOnly
      initialSelectedItems={activeFilter.position_ids}
      setFilterItems={setFilterItems}
      itemHash={positionsHash}
      labelKey="name"
      toggleLabel="Role"
      togglePluralLabel="Roles"
      filterId={activeFilter.id}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamId: getSelectedTeamId(state),
    positions: getCurrentPositions(state),
    positionsHash: getPositions(state),
    isLoading: getIsFetchingPositions(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal,
  filterItemWithWhiteSpace,
  fetchPositions
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const component = connector(PositionFilterDropdown);
export default component;
