import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getSelectedTeamId,
  getIsFetchingPhaseNames,
  getPhaseNames
} from 'selectors';
import { updateAccountFilterLocal, fetchPhaseNames } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import { filterItemWithWhiteSpace } from 'appUtils/search';

const copy = {
  searchPlaceholder: 'Search phase or select below'
};

const PhaseNamesFilterDropdown = ({
  activeFilter,
  teamId,
  updateAccountFilterLocal,
  phaseNames,
  isLoading,
  fetchPhaseNames,
  isHidden
}) => {
  const setFilterItems = (nextPhases) => {
    updateAccountFilterLocal({
      ...activeFilter,
      phase_names: nextPhases
    });
  };

  useEffect(() => {
    if (teamId) fetchPhaseNames({ teamId });
  }, [fetchPhaseNames, teamId]);

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={phaseNames}
      copy={copy}
      isLoading={isLoading}
      batchClearOnly
      initialSelectedItems={activeFilter.phase_names}
      setFilterItems={setFilterItems}
      toggleLabel="Phase"
      filterId={activeFilter.id}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamId: getSelectedTeamId(state),
    phaseNames: getPhaseNames(state),
    isLoading: getIsFetchingPhaseNames(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal,
  fetchPhaseNames,
  filterItemWithWhiteSpace
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(PhaseNamesFilterDropdown);
