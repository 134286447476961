import qs from 'qs';
import { callApi } from 'appUtils/api';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import {
  FetchLocationsParams,
  FetchEntityLocationsParams,
  CreateOrUpdateEntityLocationParams,
  DeleteEntityLocationParams,
  AssociateEntityToLocationParams
} from './types';

const fetchLocations = (token: string, { teamId }: FetchLocationsParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify({ team_id: teamId });
  return callApi(`locations/all?${stringifiedQueryParams}`, token, fetchInit);
};

const fetchLocationsByTeamMembership = (
  id: number,
  token: string,
  { teamId }: { teamId: number }
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify({ team_id: teamId });
  return callApi(
    `locations/by_team_membership?${stringifiedQueryParams}`,
    token,
    fetchInit
  );
};

const fetchEntityLocations = (
  token: number,
  { teamId, entityId, entityType }: FetchEntityLocationsParams
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(
    convertKeysToSnakeCase({ teamId, entityId, entityType })
  );

  return callApi(
    `entity_locations?${stringifiedQueryParams}`,
    token,
    fetchInit
  );
};

const createOrUpdateEntityLocation = (
  token: string,
  params: CreateOrUpdateEntityLocationParams
) => {
  const {
    entityId,
    entityType,
    location: { address_1, address_2, city, country, state, zip },
    teamId,
    description,
    referenceEntityDescription,
    referenceEntityId,
    referenceEntityType
  } = params;

  let body = convertKeysToSnakeCase({
    entityId,
    entityType,
    teamId,
    description
  });

  if ('referenceEntityType' in params) {
    body = {
      ...body,
      ...convertKeysToSnakeCase({
        referenceEntityDescription,
        referenceEntityId,
        referenceEntityType
      })
    };
  } else {
    body = {
      ...body,
      ...convertKeysToSnakeCase({
        address_1,
        address_2,
        city,
        country,
        state,
        zip,
        is_manual_entry: true
      })
    };
  }

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('location/create_or_update_main_location', token, fetchInit);
};

export const deleteEntityLocation = (
  token: string,
  { entityLocationId }: DeleteEntityLocationParams
) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`entity_locations/${entityLocationId}`, token, fetchInit);
};

const associateEntityToLocation = (
  token: string,
  {
    locationId,
    description,
    teamId,
    entityId,
    entityType
  }: AssociateEntityToLocationParams
) => {
  const body = convertKeysToSnakeCase({
    locationId,
    description: `${description} ${Date.now().toString()}`,
    teamId,
    entityId,
    entityType
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('entity_locations', token, fetchInit);
};

export const locationsApi = {
  fetchLocations,
  fetchLocationsByTeamMembership,
  fetchEntityLocations,
  createOrUpdateEntityLocation,
  deleteEntityLocation,
  associateEntityToLocation
};
