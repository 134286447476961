import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { FilterListTypeDropdown } from 'FilterModule/types';
import { useDuplicateCheckFilter } from 'FilterModule/hooks/useDuplicateCheckFilter';

export const DuplicateCheckFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const { optionsArray, selectedItems, toggleSelectedItem } =
    useDuplicateCheckFilter();

  return (
    <SimpleFilterDropdown
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={toggleSelectedItem}
      listWidth={180}
      isSearchEnabled={false}
      renderToggle={({ isOpen }) =>
        renderToggle({
          isOpen,
          customLabel: selectedItems.length === 1 ? selectedItems[0] : undefined
        })
      }
      isBatchActionsHidden
    />
  );
};
