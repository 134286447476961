import { useRef, useCallback, useMemo } from 'react';
import DevProps from 'components/dev/DevProps';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

import { VariableSizeList } from 'react-window';
import { TableV2, TableProps } from 'components/Table/TableV2';
import { BaseTableList } from 'components/Table/types';
import { ContainerDiv, StyledPermissionsTable } from './styles';
import useNestedCollapse from 'appUtils/hooks/useNestedCollapse/useNestedCollapse';
import {
  useSectionGroupedListsBuilder,
  usePermissionListItemsBuilder
} from './helpers';
import { getTableConfig } from './tableConfigs';
import { PermissionsTableVariant, PermissionSectionOrder } from './types';

export interface PermissionsTableProps {
  isLoading?: boolean;
  variant: PermissionsTableVariant;
  sectionOrder: PermissionSectionOrder;
}

export const PermissionsTable = ({
  isLoading,
  variant,
  sectionOrder
}: PermissionsTableProps) => {
  const listRef = useRef<VariableSizeList>(null);

  const tableConfig = useMemo(() => {
    return getTableConfig({ variant });
  }, [variant]);

  /* ------------------------------ Interactions ------------------------------ */

  // collapse isn't used .. leaving for future ref
  const collapseCallback = useCallback(() => {
    if (listRef.current?.resetAfterIndex) {
      listRef.current?.resetAfterIndex(0);
    }
  }, []);

  const {
    setParentCollapseState,
    getIsOpen,
    getCurrentParentCollapseState,
    toggleCollapse
  } = useNestedCollapse({
    toggleCallback: collapseCallback
  });

  /* ----------------------------- Table building ----------------------------- */

  const customRowProps = useMemo(
    () => ({
      tableConfig
    }),
    [tableConfig]
  );

  const permissionListItemsBuilder = usePermissionListItemsBuilder();

  const groupedPermissionListsBuilder = useSectionGroupedListsBuilder({
    getIsOpen,
    toggleCollapse,
    setParentCollapseState,
    getCurrentParentCollapseState,
    listItemsBuilder: permissionListItemsBuilder
  });

  const mainList: BaseTableList = useMemo(() => {
    const listItems =
      groupedPermissionListsBuilder({
        order: sectionOrder,
        parentGroupId: 'main'
      }) || [];

    const list: BaseTableList = {
      listItems,
      isList: true,
      id: 'main',
      isOpen: true,
      isFullyLoaded: true,
      skipHeader: true
    };
    return list;
  }, [groupedPermissionListsBuilder, sectionOrder]);

  /* --------------------------------- Render --------------------------------- */

  const tableProps: TableProps = {
    mainList,
    listRef,
    customRowProps,
    ...tableConfig.tableProps
  };

  return (
    <ContainerDiv tableVariant={variant}>
      {isLoading ? (
        <SkeletonLoader
          numLoaders={5}
          loaderStyle={{ height: 60, rx: 4 }}
          style={{
            paddingTop: 20,
            width: variant === 'projects' ? '55%' : '50%'
          }}
        />
      ) : (
        <StyledPermissionsTable tableVariant={variant}>
          <DevProps {...{ mainList }} />
          <TableV2 {...tableProps} />
        </StyledPermissionsTable>
      )}
    </ContainerDiv>
  );
};
