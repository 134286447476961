import * as constants from 'appConstants';

export const initialState = {
  projectTotals: {}, // keeping this just in case
  isFetching: false, // ""
  filterStates: {}
};

export const initialFilterState = {
  projectTotals: {},
  isFetching: false
};

const profit = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_PROJECT_TOTALS.TRIGGER: {
      const { initial, projectIds, intervalType, filterStateId } =
        action.payload;

      const nextState = { ...state };

      // Initialize filterState if necessary
      if (filterStateId && !state.filterStates[filterStateId]) {
        nextState.filterStates = {
          ...state.filterStates,
          [filterStateId]: initialFilterState
        };
      }

      const nextTotals = getNextProjectTotalsState(nextState, filterStateId);

      projectIds.forEach((projectId) => {
        if (nextTotals[projectId]?.[intervalType] && initial) {
          nextTotals[projectId] = {
            ...nextTotals[projectId],
            [intervalType]: []
          };
        }
      });

      return {
        ...state,
        isFetching: true,
        ...getNextProjectTotalsResult(nextState, filterStateId, nextTotals) // nextState since filterState may have been initialized above
      };
    }

    case constants.FETCH_PROJECT_TOTALS.SUCCESS: {
      const { isFuture, intervalType, filterStateId } = payload.requestPayload;
      let fetchedTotals = payload.response.projects;
      const nextTotals = getNextProjectTotalsState(state, filterStateId);

      fetchedTotals = fetchedTotals.reduce((acc, cur) => {
        acc[cur.project_id] = cur.budget_totals;
        return acc;
      }, {});

      // Prepend fetched data to existing totals
      Object.keys(fetchedTotals).forEach((projectId) => {
        nextTotals[projectId] = {
          ...nextTotals[projectId],
          [intervalType]: [
            ...(isFuture ? nextTotals[projectId]?.[intervalType] || [] : []),
            ...fetchedTotals[projectId],
            ...(!isFuture ? nextTotals[projectId]?.[intervalType] || [] : [])
          ]
        };
      });

      return {
        ...state,
        isFetching: false,
        ...getNextProjectTotalsResult(state, filterStateId, nextTotals)
      };
    }

    case constants.CLEAR_PROJECT_TOTALS: {
      const { projectId, filterStateId } = payload;
      const nextProjectTotalsState = getNextProjectTotalsState(
        state,
        filterStateId
      );

      if (projectId && nextProjectTotalsState[projectId]) {
        delete nextProjectTotalsState[projectId];
        return {
          ...state,
          ...getNextProjectTotalsResult(
            state,
            filterStateId,
            nextProjectTotalsState
          )
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default profit;

/* ------------------------------------ - ----------------------------------- */

const getNextProjectTotalsState = (state, filterStateId) =>
  filterStateId
    ? { ...state.filterStates[filterStateId].projectTotals }
    : { ...state.projectTotals };

const getNextProjectTotalsResult = (state, filterStateId, nextTotals) => {
  return filterStateId
    ? {
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            ...state.filterStates[filterStateId],
            projectTotals: nextTotals
          }
        }
      }
    : {
        projectTotals: nextTotals
      };
};
