import React, { useState, useRef } from 'react';
import Popover from 'components/Popover';
import {
  TextButtonWithBorder,
  BlueSubmitButton,
  ButtonContainer
} from 'components/styles';
import { ButtonPanel } from 'components/DateRangeCalendar/DateRangeCalendar';

import {
  KaratLeft,
  KaratRight,
  ClearButton,
  Header,
  Footer,
  MonthBg,
  MonthsContainer,
  Month,
  Year,
  MonthPickerContainer
} from './styles';

import { noop } from 'appUtils/noop';
import moment from 'moment';

const THIS_YEAR = moment().year();
const LAST_YEAR = moment().year() - 1;
const THIS_MONTH = moment().month();

export const getDefaultStartDate = (interval) => {
  if (interval === 'year') {
    return moment().startOf('month').add(-1, 'year');
  }
  if (interval === 'month') {
    return moment().startOf('month');
  }
};

/**
 * currently not allowing future selection
 */
const MonthPicker = ({
  isOpen,
  toggle,
  startDate,
  interval = 'year',
  onClear = noop,
  onSubmit = noop,
  targetRef,
  popoverClassName,
  hideRangePanel
}) => {
  const defaultStartDate = getDefaultStartDate(interval);
  const [selectedDate, setSelectedDate] = useState(
    startDate ? moment(startDate) : defaultStartDate
  );
  const [viewYear, setViewYear] = useState(
    startDate ? moment(startDate).year() : defaultStartDate.year()
  );
  const [startDateInput, setStartDateInput] = useState(
    startDate
      ? moment(startDate).format('M/YYYY')
      : defaultStartDate.format('M/YYYY')
  );

  const getIsRightNavDisabled = () => {
    if (interval === 'year') {
      return viewYear === LAST_YEAR;
    }
    if (interval === 'month') {
      return viewYear === THIS_YEAR;
    }
  };

  const isRightNavDisabled = getIsRightNavDisabled();

  const getMomentDate = (month, year) => {
    return moment(`${month}/1/${year}`);
  };

  const onSelect = (monthNum) => {
    const date = getMomentDate(monthNum + 1, viewYear);
    setSelectedDate(date);
    setStartDateInput(date.format('M/YYYY'));
  };

  const renderMonth = (monthNum) => {
    const isDisabled = monthNum > THIS_MONTH && isRightNavDisabled;
    const isSelected =
      viewYear === selectedDate?.year() && monthNum === selectedDate?.month();
    const isCurrent =
      monthNum === moment().month() && viewYear === moment().year();
    return (
      <Month
        key={monthNum}
        isSelected={isSelected}
        isDisabled={isDisabled}
        isCurrent={isCurrent}
        onClick={
          !isSelected && !isDisabled ? () => onSelect(monthNum) : undefined
        }
        data-testid={`${monthNum}-month-select`}
      >
        <MonthBg isSelected={isSelected} data-testid={`${monthNum}-month-bg`} />
        <span data-testid={`${monthNum}-month-label`}>
          {moment().month(monthNum).format('MMM')}
        </span>
      </Month>
    );
  };

  const onPrevYear = () => {
    setViewYear((year) => year - 1);
  };
  const onNextYear = () => {
    setViewYear((year) => year + 1);
  };

  const onStartDateChange = (e) => setStartDateInput(e.target.value);

  const onStartDateUpdate = () => {
    const values = startDateInput.split('/');
    const newDate = getMomentDate(values[0], values[1]);
    if (
      values.length !== 2 ||
      values.some((value) => isNaN(value)) ||
      !newDate.isValid() ||
      newDate.isAfter(defaultStartDate)
    ) {
      setSelectedDate(null);
      setStartDateInput('Invalid Date');
    } else {
      setSelectedDate(newDate);
    }
  };

  const onSave = () => {
    toggle();
    onSubmit({
      startDate: selectedDate,
      endDate: selectedDate.clone().add(1, interval).add(-1, 'day')
    });
  };

  const isValid = selectedDate?.isValid();
  const dirtyFlag = !selectedDate?.isSame(startDate);

  return (
    <Popover
      isOpen={isOpen}
      target={targetRef}
      closePopover={toggle}
      className={popoverClassName}
    >
      <MonthPickerContainer height={hideRangePanel ? '300px' : '364px'}>
        {/* Header */}
        <Header>
          <KaratLeft
            isHidden={viewYear === 2000}
            onClick={onPrevYear}
            data-testid="month-picker-arrow-left"
          />
          <Year data-testid="month-picker-year">{viewYear}</Year>
          <KaratRight
            isHidden={isRightNavDisabled}
            onClick={isRightNavDisabled ? undefined : onNextYear}
            data-testid="month-picker-arrow-right"
          />
        </Header>

        {/* Months */}
        <MonthsContainer>
          {[...Array(12).keys()].map((monthNum) => renderMonth(monthNum))}
        </MonthsContainer>
        {!hideRangePanel && (
          <ButtonPanel
            startText={startDateInput}
            endText={
              isValid
                ? selectedDate.clone().add(1, interval).format('M/YYYY')
                : ' '
            }
            handleStartInput={onStartDateChange}
            handleFormSubmit={onStartDateUpdate}
            showInputs
            endDisabled
            endPlaceholder=" "
          />
        )}

        {/* Footer */}
        <Footer>
          <ClearButton
            onClick={() => {
              toggle();
              onClear();
            }}
            data-testid="month-picker-clear"
            isHidden={!startDate}
          >
            Clear
          </ClearButton>
          <ButtonContainer width={152}>
            <TextButtonWithBorder
              onClick={toggle}
              fontWeight={400}
              data-testid="month-picker-cancel"
            >
              Cancel
            </TextButtonWithBorder>
            <BlueSubmitButton
              onClick={isValid ? onSave : undefined}
              opacity={isValid ? undefined : '0.6'}
              isDisabled={!isValid}
              data-testid="month-picker-submit"
            >
              {dirtyFlag ? 'Save' : 'Done'}
            </BlueSubmitButton>
          </ButtonContainer>
        </Footer>
      </MonthPickerContainer>
    </Popover>
  );
};

export default React.memo(MonthPicker);
