import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tooltipPortal } from 'appUtils/portal';
import { TaskSidebar } from './TaskSidebar';
import TaskSidebarV1 from './TaskSidebarV1';

import { toggleTaskSidebarIsOpen } from 'actionCreators';
import { getIsTaskSidebarOpen } from 'selectors';
import { StyledSidebar } from './styles';
import cn from 'classnames';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const TaskSidebarContainer = ({ showAll = false }) => {
  const dispatch = useDispatch();
  const toggleIsOpen = () => {
    dispatch(
      toggleTaskSidebarIsOpen({
        isOpen: false,
        projectId: null
      })
    );
  };
  const { taskDrawerUpdateFlag } = useFeatureFlags();

  const isTaskSidebarOpen = useSelector(getIsTaskSidebarOpen);
  return createPortal(
    <StyledSidebar
      className={cn({ isOpen: isTaskSidebarOpen, taskDrawerUpdateFlag })}
    >
      {taskDrawerUpdateFlag ? (
        <TaskSidebar showAll={showAll} toggleIsOpen={toggleIsOpen} />
      ) : (
        <TaskSidebarV1 showAll={showAll} toggleIsOpen={toggleIsOpen} />
      )}
    </StyledSidebar>,
    tooltipPortal
  );
};

export default TaskSidebarContainer;
