import { MouseEvent } from 'react';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import styled from 'styled-components';
import { ThreeDotMenuListItem } from '../shared/ThreeDotMenuListItem';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import { InlineGroupEditingForm } from './InlineGroupEditingForm';
export const BaseGroupHeaderCell = ({ row, columns, column }) => {
  const {
    isOpen,
    toggleCollapse,
    headerData,
    groupRowMenus,
    onGroupRowAddClick,
    useInlineCreation,
    onEditGroupInline,
    secondaryRowRenderer,
    secondaryRowHeight,
    isEditing
  } = row.original;

  const handleClickMenu = (onClick) => {
    return () => onClick(headerData);
  };

  const handleClickAddButton = (event: MouseEvent<HTMLButtonElement>) => {
    onGroupRowAddClick && onGroupRowAddClick({ event, groupData: headerData });
  };

  const handleEditGroup = (label: string) => {
    onEditGroupInline({
      id: headerData.id,
      label
    });
  };

  const shouldShowInlineInput = useInlineCreation !== false && isEditing;

  return (
    <GroupCellContainer style={{ width: column.width }}>
      <GroupHeaderContainer>
        {shouldShowInlineInput ? (
          <InlineGroupEditingForm
            initialValue={headerData.label}
            onSubmit={handleEditGroup}
          />
        ) : (
          <PrimaryHeaderContainer>
            {groupRowMenus?.length && (
              <ThreeDotMenu
                style={{ width: '16px', justifyContent: 'center' }}
                slide={false}
                className={undefined}
                placement={undefined}
                overrideIsOpen={undefined}
                overrideSetIsOpen={undefined}
                size={undefined}
              >
                {groupRowMenus.map(({ label, icon, onClick }) => (
                  <ThreeDotMenuListItem
                    key={label}
                    icon={icon}
                    label={label}
                    onClick={handleClickMenu(onClick)}
                  />
                ))}
              </ThreeDotMenu>
            )}
            <HeaderText onClick={toggleCollapse}>{headerData.label}</HeaderText>
            <IconButtonContainer onClick={handleClickAddButton}>
              <PlusCircleIcon height={15} width={15} />
            </IconButtonContainer>
          </PrimaryHeaderContainer>
        )}
      </GroupHeaderContainer>
      {secondaryRowRenderer && (
        <SecondaryRowRendererContainer
          style={{ height: secondaryRowHeight, width: '100%' }}
        >
          {secondaryRowRenderer(headerData)}
        </SecondaryRowRendererContainer>
      )}
      {isOpen && (
        <ColumnHeadersContainer>
          {columns
            .filter(
              (column) =>
                /**
                 * current cell is taking groupHeaderColumn
                 * to keep group label with subheader in same row,
                 * we can filter out non-subheader parts on columns
                 * and use only subheaders for each columns
                 */
                column.id !== 'groupHeader' &&
                column.id !== 'tableWidth' &&
                column.id !== 'leadingColumn'
            )
            .map(({ headerLabel, width, align }) => (
              <ColumnHeaderText
                key={headerLabel}
                style={{
                  width: width,
                  alignItems: align
                }}
              >
                {headerLabel}
              </ColumnHeaderText>
            ))}
        </ColumnHeadersContainer>
      )}
    </GroupCellContainer>
  );
};

const GroupCellContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GroupHeaderContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const PrimaryHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  gap: 8px;
`;

const HeaderText = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
`;

const ColumnHeadersContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const ColumnHeaderText = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  text-transform: uppercase;
`;

const IconButtonContainer = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
`;

const SecondaryRowRendererContainer = styled.div``;
