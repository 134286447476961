import moment from 'moment';
import {
  INACTIVE_BUDGET_STATUSES,
  PHASE_BUDGET_STATUS_DISPLAY,
  BUDGET_STATUSES
} from 'appConstants/budgetStatuses';
import { startOfDay } from 'date-fns';
import { parseDate, differenceInDays } from './dateUtils';

export const getRemainingWorkDays = (item) => {
  if (!item.bars) {
    return;
  }
  const today = startOfDay(new Date());
  const totalDays = item.bars.reduce(
    (dayTotal, { start_date, end_date, day_count }) => {
      // parse date
      const start = parseDate(start_date);
      const end = parseDate(end_date);
      // compare date with today
      if (today <= start) {
        return dayTotal + day_count;
      }
      if (today < end) {
        return dayTotal + differenceInDays(end, today) + 1;
      }
      return dayTotal;
    },
    0
  );
  return totalDays;
};

export const formatTotals = (totalsObj) => {
  const hideNegativeValues = true;
  const {
    estimated_sum,
    estimated: originalEstimated,
    planned,
    spent,
    total,
    // user input estimated cost
    estimatedCost,
    estimated_hours
  } = totalsObj;
  // totalsObj.estimated is object for member totals
  // and value otherwise (equal to user input estimated cost)
  // rails PR 2531
  const estimated = estimated_sum || originalEstimated || {};
  const { remaining = {} } = estimated;

  const getValue = (value) => {
    if (!hideNegativeValues) return +value || 0;
    return !+value || +value < 0 ? 0 : +value;
  };

  const spentExpense = getValue(spent.expense);
  const spentHours = getValue(spent.hours);
  const plannedExpense = getValue(planned.expense);
  const plannedHours = getValue(planned.hours);

  /**
   * estimated.hours === the sum of member hours
   * estimated_hours === total budget hours, is user set, not calculated.
   */
  const totalEstimatedHours = estimated_hours || 0;

  const usePercents = !total;
  const numbers = {
    total_estimated_hours: totalEstimatedHours,
    total_remaining_hours: totalEstimatedHours - spentHours - plannedHours,
    // Total of member hours
    estimated_hours: estimated.hours || 0,
    spent_hours: spentHours || 0,
    planned_hours: plannedHours,
    remaining_hours: remaining.hours || 0,
    estimated_cost: estimated.expense || 0,
    // eg. project's sum of phase estimated will be at totalsObj.estimated
    profit_calc_estimated_cost: +originalEstimated || 0,
    spent_cost: spentExpense,
    planned_cost: plannedExpense,
    remaining_cost: remaining.expense || 0,
    remaining_cost_of_total: total ? total - spentExpense - plannedExpense : 0,
    remaining_cost_of_estimated:
      +estimatedCost || +originalEstimated
        ? (estimatedCost || originalEstimated) - spentExpense - plannedExpense
        : 0,
    estimated_percent: usePercents
      ? 0
      : ((estimated.expense / total) * 100).toFixed(1),
    estimated_percent_of_target: +originalEstimated // target = user input estimated
      ? ((estimated.expense / originalEstimated) * 100).toFixed(1)
      : 0,
    spent_percent: usePercents ? 0 : ((spentExpense / total) * 100).toFixed(1),
    spent_percent_of_estimate: !+originalEstimated
      ? 0
      : ((spentExpense / originalEstimated) * 100).toFixed(1),
    planned_percent: usePercents
      ? 0
      : ((plannedExpense / total) * 100).toFixed(1),
    planned_percent_of_estimate: !+originalEstimated
      ? 0
      : ((plannedExpense / originalEstimated) * 100).toFixed(1),
    remaining_percent: usePercents
      ? 0
      : (((total - spentExpense - plannedExpense) / total) * 100).toFixed(1),
    remaining_percent_of_estimate: !+originalEstimated
      ? 0
      : (
          ((originalEstimated - spentExpense - plannedExpense) /
            originalEstimated) *
          100
        ).toFixed(1),
    total,
    original: totalsObj
  };

  return numbers;
};

export const isPhaseInactive = (phase) =>
  INACTIVE_BUDGET_STATUSES[phase?.budget_status] || phase?.archived;

export const isPhaseArchived = (phase) =>
  phase?.budget_status === 'archived' || phase?.archived;

export const getPhaseDisplayBudgetStatus = (phase) =>
  isPhaseArchived(phase)
    ? PHASE_BUDGET_STATUS_DISPLAY[BUDGET_STATUSES.ARCHIVED]
    : PHASE_BUDGET_STATUS_DISPLAY[phase?.budget_status];

/**
 * @deprecated Use the typesafe version from `ProjectsModule/utils/phaseDisplay`.
 */
export const getIsPhaseLikeDefault = (phase) =>
  phase?.is_default_phase || phase?.is_main_like_default || phase?.is_default;

const getCurrentProgressPercent = (total, remaining) =>
  Math.max(((total - remaining) / total) * 100 || 0, 0);

const getPhaseProgressStatus = (phase) => {
  const { start_date, end_date } = phase;
  const today = moment().startOf('day');
  const notStarted =
    moment.duration(today.diff(start_date, 'days'), 'days').asDays() < 0;
  const completed =
    moment.duration(today.diff(end_date, 'days'), 'days').asDays() > 0;

  return { notStarted, completed };
};

// also works for activityPhase
export const getPhaseProgress = (phase) => {
  const total = +phase.total_work_days || 0;
  const remaining = getRemainingWorkDays(phase);
  const completedPercent = getCurrentProgressPercent(total, remaining);
  const phaseStatus = getPhaseProgressStatus(phase);

  return {
    past: total - remaining || 0,
    remaining,
    total,
    completedPercent,
    ...phaseStatus
  };
};

export const getPhaseProgressTooltipContent = (phaseProgress) => {
  const { notStarted, completed, remaining, total, completedPercent } =
    phaseProgress;

  const tooltip = {
    firstLine: '',
    secondLine: ''
  };

  if (notStarted) {
    tooltip.firstLine = 'Not Started';
    tooltip.secondLine = `${total} Workday${total > 1 ? 's' : ''} Remaining`;
  } else if (!notStarted && !completed) {
    tooltip.firstLine = `${completedPercent.toFixed(0)}% Complete`;
    tooltip.secondLine = `${remaining}/${total} Workday${
      remaining > 1 ? 's' : ''
    } Remaining`;
  } else if (completed) {
    tooltip.firstLine = 'Completed';
    tooltip.secondLine = `${total} Total Workday${total > 1 ? 's' : ''}`;
  }

  return `
    <div
      class="phase-display-tooltip"
    >
      <div class="first-line">${tooltip.firstLine}</div>
      <div class="second-line">${tooltip.secondLine}</div>
    </div>
  `;
};

export const getWorkdaysWithBaselineTooltipContent = (workdayProgress) => {
  const { baseline, remainingWorkdays, totalWorkdays } = workdayProgress;

  const tooltip = {
    workdaysremaining: '',
    workdaysBaseline: '',
    baselineDifference: ''
  };

  const difference = totalWorkdays - baseline;

  tooltip.workdaysremaining = `${remainingWorkdays}/${totalWorkdays} Workdays Remaining`;
  tooltip.workdaysBaseline = `${baseline} Workdays Baseline`;

  return baseline
    ? `
    <div class="phase-display-workday-tooltip"
    >
      <div class="workdays-remaining">${tooltip.workdaysremaining} </div>
      <div class="workdays-baseline">${tooltip.workdaysBaseline} </div>
      <div class="baseline-difference">
      ${
        difference < 0
          ? '<span class="less"> -'
          : `<span class="${difference !== 0 ? 'more' : ''}">+`
      } ${Math.abs(difference)} </span>${
        difference < 0 ? 'Less' : 'Additional'
      } Days
    </div>
  `
    : `<div className="phase-display-workday-tooltip">
      <div className="workdays-remaining">${tooltip.workdaysremaining} </div>
    </div>`;
};
