import SvgIcon from 'components/SvgIcon';

const FolderIcon = ({ className }) => (
  <SvgIcon
    width="54"
    height="45"
    viewBox="0 0 54 45"
    fill="#fff"
    className={className}
  >
    <path
      id="projectfolder"
      d="M23.5607 1.93897L23.1213 1.49963H22.5H6.25C3.63407 1.49963 1.5 3.63371 1.5 6.24963V38.7496C1.5 41.3656 3.63407 43.4996 6.25 43.4996H45.25C47.8659 43.4996 50 41.3656 50 38.7496V12.7496C50 10.1337 47.8659 7.99963 45.25 7.99963H29.6213L23.5607 1.93897Z"
      stroke="#4A4A4A"
    />
  </SvgIcon>
);

export default FolderIcon;
