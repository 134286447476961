import styled from 'styled-components';
import theme from 'theme';

export const BaseCellRootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  background-color: white;
  cursor: pointer;
  color: ${theme.colors.colorSemiDarkGray1};

  &:hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;
