import { ProjectUnassignedMemberBudgetWithPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { Project } from 'ProjectsModule/models/project';
import { EntityRequirementsState } from 'RequirementsModule/reducers/entityRequirements';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getTeamSlug } from 'selectors';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { TargetElement } from 'appUtils/hooks/usePopperState';
import { AssociationUpdatesInstance } from 'RequirementsModule/actionCreators/types';
import { AccountId } from 'models/account';

export type OnDeleteRequirementArgs = {
  association_updates: AssociationUpdatesInstance[];
};

export type OnAddRequirementsClickArgs = {
  memberBudgetId: number;
  projectMembershipId: number;
};

export enum AvailabilityViewTriggerSourceType {
  Phase = 'Phase',
  ActivityPhase = 'ActivityPhase'
}
export interface AvailabilityViewTriggerSource {
  type: AvailabilityViewTriggerSourceType;
  id: number;
}

export type ToggleAvailabilityView = ({
  triggerSource,
  accountIds
}: {
  triggerSource: AvailabilityViewTriggerSource;
  accountIds: AccountId[];
}) => void;

export type FindPeopleModalContextValuesType = {
  project: Project | undefined;
  selectedProjectId: Nullable<number>;
  selectedUnassignedMemberBudgetId: Nullable<number>;
  numOfProjectUnassignedRoles: number;
  activeProjectPhasesOrder: number[];
  allProjectPhasesOrder: number[];
  isFetchingMemberSuggestionForPhase: boolean | undefined;
  showDemoSuggestions: boolean;
  phasesHash: ReturnType<typeof getFlatPhasesHash>;
  isRequirementsSelectorOpen: boolean;
  isFetchingEntityRequirements: boolean | undefined;
  isFetchingPhaseTotals: boolean | undefined;
  requirementAssociationsByProjectMembershipIdHash: EntityRequirementsState['requirementAssociationsByEntity']['entityRequirementType'];
  teamId: ReturnType<typeof getSelectedTeamId>;
  teamSlug: ReturnType<typeof getTeamSlug>;
  selectedUnassignedMemberBudget?: ProjectUnassignedMemberBudgetWithPosition;
  activePhasesNum: number;
  disableChangingProject?: boolean;
  toggleRequirementsSelector: (booleanValue?: boolean) => void;
  handleSelectUnassignedRoleFromDropdown: (
    item: ProjectUnassignedMemberBudgetWithPosition
  ) => void;
  handleSelectUnassignedRole: ({
    memberBudgetId
  }: {
    memberBudgetId: number;
  }) => void;
  resetUnassignedRoleAndSelectedPhaseIds: () => void;
  triggerFetchMembersSuggestion: (args: {
    phaseIds: number[];
    memberBudgetId: Nullable<number>;
    shouldShowSkeletonLoader?: boolean;
  }) => void;
  onDeleteRequirement: ({
    association_updates
  }: OnDeleteRequirementArgs) => void;
  onAddRequirementsClick: ({
    memberBudgetId,
    projectMembershipId
  }: OnAddRequirementsClickArgs) => void;
  openBudgetModal: () => void;
  isProjectSelectorOpen: boolean;
  toggleIsProjectSelectorOpen: () => void;
  openAddRemoveUnassignedRoleFromPhasesDropdown: (args: TargetElement) => void;
  toggleModal: () => void;
  toggleAvailabilityView: ToggleAvailabilityView;
  isAvailabilityViewActive: boolean;
  selectedAvailabilityViewTriggerSource:
    | AvailabilityViewTriggerSource
    | undefined;
};
