import { MODAL_TYPE } from 'appConstants/addMemberForm';
const baseProjectDeleteMessage = `cannot be deleted because it has`;
const basePhaseDeleteMessage = `This Phase cannot be deleted because it has`;
const baseActivityPhaseDeleteMessage = `This Work Category cannot be deleted because it has`;
const baseMilestoneDeleteMessage = `This Milestone cannot be deleted because it has`;

const baseBoardDeleteMessageStart =
  'This Portfolio cannot be deleted because there are';
const baseBoardDeleteMessageEnd = `associated with projects on it.`;

const TIME_ENTRIES = 'has_associated_time_entries';
const WORK_PLANS = 'has_associated_activity_phase_schedule_bars';
const BOTH = 'BOTH';

const timeEntriesText = 'time entries';
const workPlansText = 'work plans';
const bothText = `${timeEntriesText} and ${workPlansText}`;

export const hasAssociatedTimeTypes = {
  TIME_ENTRIES,
  WORK_PLANS,
  BOTH
};
export const deleteMessages = {
  [MODAL_TYPE.PROJECT]: {
    [TIME_ENTRIES]: `${baseProjectDeleteMessage} ${timeEntriesText}.`,
    [WORK_PLANS]: `${baseProjectDeleteMessage} ${workPlansText}.`,
    [BOTH]: `${baseProjectDeleteMessage} ${bothText}.`
  },
  [MODAL_TYPE.MILESTONE]: {
    [TIME_ENTRIES]: `${baseMilestoneDeleteMessage} ${timeEntriesText}.`,
    [WORK_PLANS]: `${baseMilestoneDeleteMessage} ${workPlansText}.`,
    [BOTH]: `${baseMilestoneDeleteMessage} ${bothText}.`
  },
  [MODAL_TYPE.PHASE]: {
    [TIME_ENTRIES]: `${basePhaseDeleteMessage} ${timeEntriesText}.`,
    [WORK_PLANS]: `${basePhaseDeleteMessage} ${workPlansText}.`,
    [BOTH]: `${basePhaseDeleteMessage} ${bothText}.`
  },
  [MODAL_TYPE.ACTIVITY_PHASE]: {
    [TIME_ENTRIES]: `${baseActivityPhaseDeleteMessage} ${timeEntriesText}.`,
    [WORK_PLANS]: `${baseActivityPhaseDeleteMessage} ${workPlansText}.`,
    [BOTH]: `${baseActivityPhaseDeleteMessage} ${bothText}.`
  },
  [MODAL_TYPE.BOARD]: {
    [TIME_ENTRIES]: `${baseBoardDeleteMessageStart} ${timeEntriesText} ${baseBoardDeleteMessageEnd}`,
    [WORK_PLANS]: `${baseBoardDeleteMessageStart} ${workPlansText} ${baseBoardDeleteMessageEnd}`,
    [BOTH]: `${baseBoardDeleteMessageStart} ${bothText} ${baseBoardDeleteMessageEnd}`
  }
};
