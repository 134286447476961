import { useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { getUserIsAdmin } from 'selectors';
import { navigateToTeamSettings } from 'actionCreators';
import {
  SettingsMemberViewTypeTab,
  SettingsStandardsViewTypeTab,
  SettingsViewType
} from 'SettingsModule/types/navigation';

export const useNavToSettings = <V extends SettingsViewType>(
  {
    openInNewWindow = true,
    viewType,
    tab
  }: HandleNavToSettingsParams<V> = {} as HandleNavToSettingsParams<V>
) => {
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(getUserIsAdmin);

  const handleNavToSettings = useCallback(() => {
    if (isAdmin) {
      dispatch(navigateToTeamSettings({ openInNewWindow, viewType, tab }));
    }
  }, [dispatch, isAdmin, openInNewWindow, viewType, tab]);

  return {
    handleNavToSettings,
    isAdmin
  };
};

/* ------------------------------------ - ----------------------------------- */

/**
 * If viewType is not provided, the default 'info' viewType will be used.
 * Otherwise, a tab must be provided along with the viewType
 */
type HandleNavToSettingsParams<V extends SettingsViewType> = {
  openInNewWindow?: boolean;
  viewType?: V;
} & ([V] extends ['members' | 'standards']
  ? {
      tab: V extends 'members'
        ? SettingsMemberViewTypeTab
        : V extends 'standards'
        ? SettingsStandardsViewTypeTab
        : never;
    }
  : { tab?: never });
