import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTimesheetReminderDay,
  setTimesheetReminderSenderId
} from 'actionCreators';
import {
  getTimesheetReminderDay,
  getTimesheetReminderSenderId
} from 'selectors';

import styled from 'styled-components';
import theme from 'theme';

import ReactTooltip from 'react-tooltip';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';

import CheckMarkNoCircleIcon from 'images/check-mark-no-circle.svg';
import CheckMarkNoCircleGreenIcon from 'images/check-mark-no-circle-green.svg';

import { TextButton } from 'components/styles';

const HeaderText = styled.div`
  font-size: 22px;
`;

const SubHeaderText = styled.div`
  font-size: 14px;
  font-weight: normal;

  margin-top: -5px;

  color: #828282;
`;

const RangeItemRow = styled.div`
  padding-left: 15px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .remove-text {
    color: ${theme.colors.colorDeleteRed};
    display: ${({ isSelected }) => (isSelected ? 'none' : 'flex')};
  }
  .added-text {
    color: ${theme.colors.colorCalendarBlue};
    display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    .added-text {
      display: ${({ isSelected }) => (isSelected ? 'none' : 'flex')};
    }
    .remove-text {
      display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
    }
  }
`;

const StyledItemName = styled.div`
  font-size: 14px;
`;

const Footer = null;

const copy = {
  headerInitial: 'Select Day',
  headerEdit: '',
  headerAdd: '',
  sticky: '',
  footerInitial: Footer,
  footerEdit: Footer,
  addConfirm: '',
  editConfirm: ''
};

const WEEKDAYS_LIST = [
  'none',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

const WEEKDAY_VALUES = {
  none: 'No Reminder',
  sunday: 'Every Sunday',
  monday: 'Every Monday',
  tuesday: 'Every Tuesday',
  wednesday: 'Every Wednesday',
  thursday: 'Every Thursday',
  friday: 'Every Friday',
  saturday: 'Every Saturday'
};

const DaysDropdown = ({ onClose, targetRef, selectedDay }) => {
  const dispatch = useDispatch();
  const listItems = useMemo(() => {
    return WEEKDAYS_LIST.map((id) => ({ id }));
  }, []);

  const handleSelect = (e, { item }) => {
    e.preventDefault();
    const { id } = item;
    if (!id) {
      return;
    }
    dispatch(
      setTimesheetReminderDay({
        newTimesheetReminderDay: id === 'none' ? null : id
      })
    );
    handleClose();
  };

  const onFooterClick = () => {
    ReactTooltip.hide();
    handleClose();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  /* --------------------------------------- render ----------------------------------- */

  const renderItem = ({ item, selectCallback }) => {
    if (!item) return null;
    const isSelected = item.id === selectedDay;
    return (
      <RangeItemRow isSelected>
        <StyledItemName>{WEEKDAY_VALUES[item.id]}</StyledItemName>
        {isSelected ? (
          <div>
            <div>
              <img src={CheckMarkNoCircleGreenIcon} />
            </div>
          </div>
        ) : (
          <div>
            <div className="added-text">
              <img src={CheckMarkNoCircleIcon} />
            </div>
            <div className="remove-text">
              <img src={CheckMarkNoCircleGreenIcon} />
            </div>
          </div>
        )}
      </RangeItemRow>
    );
  };

  const renderHeaderButton = () => {
    return (
      <TextButton
        color="white"
        padding="4px 8px"
        backgroundColor={theme.colors.colorRoyalBlue}
        onClick={handleClose}
      >
        Done
      </TextButton>
    );
  };

  return (
    <>
      <MultiStepFlyout
        copy={copy}
        target={targetRef}
        items={listItems}
        idKey="id"
        renderHeaderButton={renderHeaderButton}
        renderItem={renderItem}
        handleSelect={handleSelect}
        isWhite
        handleClose={handleClose}
        onFooterClick={onFooterClick}
        popoverClassName="add-requirements-dropdown"
        listWidth={200}
        listHeight={200}
        itemHeight={48}
        isItemUnSelectable={(item) => item.isSectionHeader}
        canMultiSelect
        noMinWidth={true}
        hideFooter={true}
      />
    </>
  );
};

export default DaysDropdown;
