import { MosaicMembershipActionHash } from './types';

const accessPermissions: Partial<MosaicMembershipActionHash> = {
  cost: {
    label: 'Cost'
  },
  integration: {
    label: 'Integration Linking'
  },
  portfolios: {
    label: 'Portfolios'
  },
  projects: {
    label: 'Projects'
  },
  timesheets: {
    label: 'Timesheets'
  },
  member_profile: {
    label: 'Member Profile'
  },
  team_builder: {
    label: 'Team Builder Option'
  },
  org_charts: {
    label: 'Display on Org Charts'
  },
  workload: {
    label: 'Workload Space'
  },
  planner: {
    label: 'Planner Space'
  },
  project_budget: {
    label: 'Project Budgets'
  },
  reports: {
    label: 'Reports'
  },
  dashboard: {
    label: 'Dashboards'
  }
};

const memberPermissions: Partial<MosaicMembershipActionHash> = {
  role: {
    label: 'Role'
  },
  billing: {
    label: 'Billing Rate'
  },
  holiday: {
    label: 'Holday Groups'
  },
  pto_tracking: {
    label: 'Paid Time Off Tracking'
  },
  work_schedule: {
    label: 'Work Schedule'
  },
  department: {
    label: 'Department'
  },
  skills: {
    label: 'Skills'
  },
  location: {
    label: 'Location'
  },
  region: {
    label: 'Region'
  },
  office: {
    label: 'Office'
  },
  discipline: {
    label: 'Discipline'
  }
};

export const mosaicMembershipConfigs = {
  accessPermissions,
  memberPermissions
};
