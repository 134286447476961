import { createSelector } from '@reduxjs/toolkit';
import { SuggestionSpace } from 'SuggestionModule/constants';
import { getSuggestedPhasesForAccountsState } from './shared';
import { getPlannerProjectsAndPhases } from 'selectors';

const getSuggestedPhasesForTimesheetAccounts = createSelector(
  getSuggestedPhasesForAccountsState,
  (suggestedPhasesForAccounts) =>
    suggestedPhasesForAccounts[SuggestionSpace.Timesheet]
);

export const getProjectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts =
  createSelector(
    getPlannerProjectsAndPhases as never, // A workaround for "Type instantiation is excessively deep and possibly infinite." error
    getSuggestedPhasesForTimesheetAccounts,
    (projectsAndPhases, suggestedPhasesForTimesheetAccountsHash) => ({
      projectsAndPhases,
      suggestedPhasesForAccountsHash: suggestedPhasesForTimesheetAccountsHash
    })
  );
