import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import { CheckBoxItemContainer } from './styles';
import {
  CheckBoxOptionText,
  CheckBoxOptionsContainer,
  CustomCheckBox,
  CustomCheckBoxContainer,
  HiddenInput
} from 'SettingsModule/components/styles';

import { filterItemWithWhiteSpace } from 'appUtils/search';

const BatchSelectContainer = styled.div`
  color: ${theme.colors.colorCalendarBlue};
  font-size: 12px;
  margin-left: 15px;
  margin-top: 9px;
  span {
    cursor: pointer;
  }
`;

class FilterDropdown extends React.Component {
  renderItem = ({ item, selectCallback }) => {
    const { hiddenItemsById, customRenderItem } = this.props;
    const isFiltered = hiddenItemsById && !!hiddenItemsById[item.id];
    if (customRenderItem) {
      return customRenderItem(item);
    }
    return (
      <CheckBoxItemContainer>
        <CheckBoxOptionsContainer>
          <HiddenInput type="checkbox" checked={!isFiltered} value={item} />
          <CustomCheckBoxContainer isChecked={!isFiltered}>
            <CustomCheckBox isChecked={!isFiltered} />
          </CustomCheckBoxContainer>
          <CheckBoxOptionText
            className="checkbox-option-text"
            isChecked={!isFiltered}
          >
            {item.name}
          </CheckBoxOptionText>
        </CheckBoxOptionsContainer>
      </CheckBoxItemContainer>
    );
  };

  getCopy = () => {
    const { renderHeaderCopy, renderFooterCopy, customCopy } = this.props;

    const copy = {
      headerInitial: renderHeaderCopy,
      footerInitial: renderFooterCopy || 'Select All',
      searchPlaceholder: 'Type to search',
      ...customCopy
    };
    return copy;
  };

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectItemCallback, hiddenItemsById } = this.props;
    const isFiltered = hiddenItemsById && hiddenItemsById[item.id];
    const wasSelected = !isFiltered;
    selectItemCallback(item, !wasSelected);
    if (selectCallback) {
      selectCallback();
    }
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });

  onFooterClick = () => {
    const { items, actionCallBackOnAllItems } = this.props;
    items && actionCallBackOnAllItems && actionCallBackOnAllItems();
  };

  renderBatchSelect = () => {
    const {
      items,
      handleBatchSelect,
      numSelected,
      batchClearOnly,
      autoClick = true // Auto click controls if you can selected all options.
    } = this.props;

    if (!items.length) return null;

    return (
      <BatchSelectContainer>
        {!(batchClearOnly && autoClick) && (
          <>
            {numSelected !== items.length && autoClick && (
              <span onClick={() => handleBatchSelect({ value: true })}>
                All
              </span>
            )}
            {autoClick &&
              numSelected > 0 &&
              numSelected !== items.length &&
              ' | '}
          </>
        )}
        {numSelected > 0 && autoClick && (
          <span onClick={() => handleBatchSelect({ value: false })}>
            Clear {numSelected === items.length ? 'All' : numSelected}
          </span>
        )}
      </BatchSelectContainer>
    );
  };

  renderHeader = () => {
    const { renderHeader, renderHeaderCopy } = this.props;
    return renderHeader ? renderHeader(renderHeaderCopy) : null;
  };

  hasRenderHeader = () => !!this.props.renderHeader;

  render() {
    const {
      items,
      handleClose,
      target,
      searchEnabled,
      stickyBatchSelect,
      listWidth,
      listItemContainerStyle,
      itemHeight,
      listHeight,
      loadMoreItems,
      loadInitialItems,
      hasNextPage,
      itemFilter,
      isLoading,
      onSearchChange,
      hideFooter,
      noHeader,
      autoClick,
      styleWrapper
    } = this.props;
    return (
      <MultiStepFlyout
        canMultiSelect
        copy={this.getCopy()}
        target={target}
        items={items}
        handleSelect={this.handleSelect}
        idKey="id"
        renderItem={this.renderItem}
        isWhite={true}
        itemFilter={itemFilter || this.itemFilter}
        handleClose={handleClose}
        editDisabled={true}
        onFooterClick={this.onFooterClick}
        renderHeader={this.hasRenderHeader() ? this.renderHeader : undefined}
        listItemContainerStyle={
          listItemContainerStyle || { 'border-bottom': '0px;' }
        }
        searchEnabled={searchEnabled}
        hideFooter={stickyBatchSelect || hideFooter}
        stickyRow={stickyBatchSelect}
        renderStickyRow={this.renderBatchSelect}
        onStickyClick={() => {}} // prevent default action
        stickyBelowSearch
        popoverClassName="filter-dropdown"
        listWidth={listWidth}
        listHeight={listHeight}
        itemHeight={itemHeight}
        loadMoreItems={loadMoreItems}
        loadInitialItems={loadInitialItems}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        onSearchChange={onSearchChange}
        noHeader={noHeader}
        styleWrapper={styleWrapper}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({});

  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(makeMapStateToProps, mapDispatchToProps)(FilterDropdown);
