import SvgIcon from 'components/SvgIcon';

const DeleteXIcon = ({
  width = 9,
  height = 9,
  className,
  color = '#EB5757',
  ...props
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 9 9"
    fill="none"
    {...props}
  >
    <path
      d="M1.65152 0.750001L4.5 3.58287L7.34848 0.750001L8.25 1.65152L5.41713 4.5L8.25 7.34848L7.34848 8.25L4.5 5.41713L1.65152 8.25L0.75 7.34848L3.58287 4.5L0.75 1.65152L1.65152 0.750001Z"
      fill={color}
    />
  </SvgIcon>
);

export default DeleteXIcon;
