import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { CapacityGroup } from 'CapacityModule/models';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { navigateToTeamSettings } from 'actionCreators';
import { getTeamSlug } from 'selectors';
import { getOrderedCurrentCapacityPolicies } from 'CapacityModule/selectors/capacityGroup';
import styled from 'styled-components';
import theme from 'theme';
import { capacityGroupFormatUtils } from 'CapacityModule/utils/capacityGroup';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const copy = {
  headerInitial: 'Set Policy',
  searchPlaceholder: 'Type name or select below',
  footerInitial: 'Edit Standard Capacity'
};

interface CapacityPolicySelectorProps {
  anchorEl: HTMLElement;
  onSelect: (item: CapacityGroup) => void;
  onRequestCreateCustomPolicy: () => void;
  onClose: () => void;
}

export const CapacityPolicySelector = ({
  anchorEl,
  onSelect,
  onRequestCreateCustomPolicy,
  onClose
}: CapacityPolicySelectorProps) => {
  const dispatch = useAppDispatch();

  const policies = useAppSelector(getOrderedCurrentCapacityPolicies);
  const { MultiStepFlyout, openFlyout, closeFlyout } = useMultiStepFlyout();

  useEffect(() => {
    if (anchorEl) {
      openFlyout({ target: anchorEl });
    }
  }, [anchorEl, openFlyout]);

  const items = useMemo(() => {
    const returnValue: ListItemTypes[] = [];

    returnValue.push({ type: 'createCustomPolicy' });
    returnValue.push(...policies);
    return returnValue;
  }, [policies]);

  const teamSlug = useAppSelector(getTeamSlug);

  const _closeFlyout = useCallback(() => {
    closeFlyout();
    onClose();
  }, [closeFlyout, onClose]);

  const handleSelect = useCallback(
    (e, { item }: { item: ListItemTypes }) => {
      if (getIsCapacityGroupListItem(item)) {
        onSelect(item);
        return;
      }

      onRequestCreateCustomPolicy();
      _closeFlyout();
    },
    [_closeFlyout, onRequestCreateCustomPolicy, onSelect]
  );

  const handleFilterItems = (item: ListItemTypes, searchWords: string[]) =>
    filterItemWithWhiteSpace({ searchWords, item, filterKeysArray: ['name'] });

  const handleFooterClick = () => {
    if (teamSlug) {
      dispatch(
        navigateToTeamSettings({
          teamSlug,
          viewType: 'standards',
          tab: 'capacity'
        })
      );
    }
  };

  const renderItem = useCallback(({ item }: { item: ListItemTypes }) => {
    const isCapacityGroupListItem = getIsCapacityGroupListItem(item);

    const label = isCapacityGroupListItem
      ? capacityGroupFormatUtils.getName(item)
      : 'Create Custom Policy';

    return (
      <ItemContainer>
        <ListItemMainText $isCapacityGroupListItem={isCapacityGroupListItem}>
          {isCapacityGroupListItem &&
            `${formatNumWithMaxTwoDecimals(item.hours)}h | `}
          {label}
        </ListItemMainText>

        <ActionText $isCapacityGroupListItem={isCapacityGroupListItem}>
          Add
        </ActionText>
      </ItemContainer>
    );
  }, []);

  const renderHeaderButton = () => {
    return (
      <HeaderButtonContainer>
        <StyledDoneButton onClick={_closeFlyout}>Done</StyledDoneButton>
      </HeaderButtonContainer>
    );
  };

  return (
    <MultiStepFlyout
      copy={copy}
      items={items}
      idKey="id"
      renderItem={renderItem}
      editDisabled
      itemHeight={48}
      listWidth={380}
      isWhite
      searchEnabled
      insideAnotherPopover
      renderHeaderButton={renderHeaderButton}
      handleClose={_closeFlyout}
      itemFilter={handleFilterItems}
      popoverClassName="capacity-policy-selector"
      handleSelect={handleSelect}
      onFooterClick={handleFooterClick}
    />
  );
};

type CreateCustomPolicyListItem = { type: 'createCustomPolicy' };
type ListItemTypes = CapacityGroup | CreateCustomPolicyListItem;

const getIsCapacityGroupListItem = (
  item: ListItemTypes
): item is CapacityGroup => 'name' in item;

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledDoneButton = styled.button`
  background: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  color: ${theme.colors.colorPureWhite};
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.colorRoyalBlue};

  &:hover {
    filter: brightness(95%);
  }
`;

const ListItemMainText = styled.div<{ $isCapacityGroupListItem: boolean }>`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) =>
    props.$isCapacityGroupListItem
      ? theme.colors.colorMediumGray9
      : theme.colors.colorCalendarBlue};
`;

const ActionText = styled(ListItemMainText)`
  ${(props) => !props.$isCapacityGroupListItem && 'opacity: 0;'}
  color: ${theme.colors.colorCalendarBlue};
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid ${theme.colors.colorPaleGray9};
  width: 100%;

  :not(:hover) {
    ${ActionText} {
      opacity: 0;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.colorLightGray19};
  }
`;
