import moment from 'moment';

export const createPhaseValidator = ({
  phaseName,
  phaseStartDate,
  phaseEndDate,
  addingMilestone
}: {
  phaseName: string;
  phaseStartDate?: string;
  phaseEndDate?: string;
  addingMilestone: boolean;
}): string[] => {
  const errors: string[] = [];

  const phaseNameError = phaseNameValidator({ phaseName });
  const phaseDateError = phaseDateValidator({
    phaseStartDate,
    phaseEndDate,
    addingMilestone
  });

  if (phaseNameError) errors.push(phaseNameError);
  if (phaseDateError) {
    errors.push(phaseDateError);
  }

  return errors;
};

const phaseNameValidator = ({
  phaseName
}: {
  phaseName: string;
}): string | undefined =>
  phaseName.trim().length === 0 ? 'empty_phase_name' : undefined;

const phaseDateValidator = ({
  phaseStartDate,
  phaseEndDate,
  addingMilestone
}: {
  phaseStartDate?: string;
  phaseEndDate?: string;
  addingMilestone: boolean;
}): string | undefined => {
  const momentStartDate = phaseStartDate ? moment(phaseStartDate) : null;
  const momentEndDate = phaseEndDate ? moment(phaseEndDate) : null;

  if (
    momentStartDate?.isValid() &&
    !momentEndDate?.isValid() &&
    !addingMilestone
  )
    return 'invalid_end_date';

  if (!momentStartDate?.isValid() && momentEndDate?.isValid())
    return 'invalid_start_date';
};
