import React from 'react';
import SvgIcon from 'components/SvgIcon';

const EditTaskGroupIcon = ({ fill = '#4f4f4f' }) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M11.4905 4.13432L5.53669 10.6263L3.17119 8.047L9.14305 1.53533L11.491 4.09547L11.5 4.10533C11.5 4.1114 11.4988 4.11802 11.4966 4.12385C11.4945 4.12949 11.4921 4.13255 11.4909 4.13389L11.4905 4.13432ZM1.50531 12.4746L2.18448 9.85414L3.90873 11.734L1.50531 12.4746Z"
        stroke={fill}
        fill="transparent"
      />
    </SvgIcon>
  );
};

export default EditTaskGroupIcon;
