import React, { ComponentProps } from 'react';
import SvgIcon from 'components/SvgIcon';

type CopyIconProps = Pick<ComponentProps<typeof SvgIcon>, 'width' | 'height'>;

const CopyIcon = ({ height = 12, width = 12 }: CopyIconProps) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 12 13">
      <g fill="none" fillRule="nonzero" stroke="#4F4F4F" strokeWidth=".25">
        <path
          d="M10.627 11.8894H9.76959H7.72425H5.23773H3.08706C2.74162 11.8894 2.38901 11.867 2.04357 11.8894H2.02874L2.39619 12.3094V11.3294V8.99158V6.14944V3.69118C2.39619 3.29634 2.4158 2.89329 2.39619 2.49845V2.4815L2.02874 2.9015H2.88612H4.93145H7.41797H9.56865C9.91409 2.9015 10.2667 2.92392 10.6121 2.9015H10.627L10.2595 2.4815V3.4615V5.79936V8.6415V11.0998C10.2595 11.4973 10.2471 11.8949 10.2595 12.2925V12.3094C10.2595 12.5276 10.4284 12.7404 10.627 12.7294C10.8255 12.7185 10.9944 12.5446 10.9944 12.3094V11.3294V8.99158V6.14944V3.69118C10.9944 3.29361 11.0069 2.89602 10.9944 2.49845V2.4815C10.9944 2.25454 10.8255 2.0615 10.627 2.0615H9.76959H7.72425H5.23773H3.08706C2.73923 2.0615 2.3914 2.04728 2.04357 2.0615H2.02874C1.83018 2.0615 1.66129 2.25454 1.66129 2.4815V3.4615V5.79936V8.6415V11.0998C1.66129 11.4973 1.64885 11.8949 1.66129 12.2925V12.3094C1.66129 12.5364 1.83018 12.7294 2.02874 12.7294H2.88612H4.93145H7.41797H9.56865C9.91647 12.7294 10.2643 12.7437 10.6121 12.7294H10.627C10.8179 12.7294 11.004 12.5364 10.9944 12.3094C10.9849 12.0825 10.8327 11.8894 10.627 11.8894Z"
          fill="#4F4F4F"
        />
        <path
          d="M2.02894 9.83426H0.372977L0.740425 10.2543V9.27426V6.9364V4.09426V1.636C0.740425 1.24116 0.760041 0.838114 0.740425 0.443272V0.426319L0.372977 0.846319H1.23036H3.27569H5.76221H7.91289C8.25832 0.846319 8.61094 0.868742 8.95638 0.846319H8.97121L8.60376 0.426319V2.48152C8.60376 2.69972 8.77265 2.91245 8.97121 2.90152C9.16977 2.89058 9.33866 2.71668 9.33866 2.48152V0.426319C9.33866 0.199365 9.16977 0.00631929 8.97121 0.00631929H8.11383H6.06849H3.58197H1.4313C1.08347 0.00631929 0.735634 -0.00789911 0.387808 0.00631929H0.372977C0.17442 0.00631929 0.00552859 0.199365 0.00552859 0.426319V1.40632V3.74418V6.58632V9.04458C0.00552859 9.44215 -0.00691074 9.83974 0.00552859 10.2373V10.2543C0.00552859 10.4812 0.17442 10.6743 0.372977 10.6743H2.02894C2.21984 10.6743 2.40596 10.4812 2.39639 10.2543C2.38634 10.0273 2.23468 9.83426 2.02894 9.83426Z"
          fill="#4F4F4F"
        />
      </g>
    </SvgIcon>
  );
};

export default CopyIcon;
