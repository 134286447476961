import { reducerMap } from '../reducers';
import profitReportSaga from '../sagas';

export function getProfitReportModule() {
  return {
    id: 'profitReport',
    reducerMap,
    sagas: [profitReportSaga]
    // Actions to fire when this module is added/removed
    // initialActions: []
    // finalActions: []
  };
}
