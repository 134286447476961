export interface SplitScreenConfig {
  /**
   * the initial position of the split divider
   * use a valid CSS length (% or px recommended)
   * i.e. '50%' or '100px'
   * default is '50%'
   */
  initialSplitDividerPosition?: string;
  /**
   * min heights in pixels for main and split views
   * if minHeights is provided, then each view will be at least this height
   */
  minHeights?: {
    mainView?: number;
    splitView?: number;
  };

  /**
   * if the user has dragged the divider far enough to trigger collapse,
   * then collapse the split screen.
   * default is true
   */
  shouldPullToDismiss?: boolean;
  /**
   * threshold height in pixels from bottom of the boundary element
   * only works if useAutoDismiss is true
   */
  pullToDismissTriggerHeight?: number;
  splitDividerVariant?: SplitDividerVariant;
  /**
   * show close button on the divider
   * it is only available when the splitDividerVariant is Thick
   */
  showCloseButton?: boolean;
}

export enum SplitDividerVariant {
  Compact = 'Compact',
  Thick = 'Thick'
}
