import SvgIcon, { SvgIconProps } from 'components/SvgIcon';
import theme from 'theme';

interface DateNavArrowIconProps
  extends Pick<SvgIconProps, 'width' | 'height' | 'className'> {
  fill?: string;
}

const DateNavArrowIcon = ({
  fill = theme.colors.colorMediumGray9,
  className = '',
  width = 11,
  height = 17
}: DateNavArrowIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.96581 9.38236L2.37964 16.9999L0.957602 15.5961L7.84228 8.68075L0.957602 1.76544L2.37964 0.361572L9.96581 7.97915L10.6462 8.68067L9.96581 9.38236Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default DateNavArrowIcon;
