import * as constants from 'appConstants';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';
import { makeFilterLevelNameFromFilter } from 'FilterModule/filterSchemas/utils';

const byId = (item) => item && item.id;
export const initialState = {
  accountFilters: {},
  originalAccountFilters: {}, // stores pre-change filter state
  fetched: false,
  isFetching: false
};

const userTheme = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_ACCOUNT_FILTER.SUCCESS: {
      // ignore sort value in fetch response as the sort being used on the FE is not persisted unless an update call is made. Using it overwrites the value being used on the FE.
      const { filter } = payload.response;
      return {
        ...state,
        accountFilters: {
          ...state.accountFilters,
          [filter.id]: {
            ...filter,
            custom: {
              ...filter.custom,
              sort:
                state.accountFilters[filter.id]?.custom?.sort ||
                filter.custom?.sort
            },
            filterChanged:
              state.accountFilters[filter.id]?.filterChanged || false
          }
        }
      };
    }

    case constants.FETCH_FILTER_BY_IDS.TRIGGER:
    case constants.FETCH_ACCOUNT_FILTERS.TRIGGER: {
      return {
        ...state,
        isFetching: true
      };
    }

    case constants.FETCH_FILTER_BY_IDS.FAILURE:
    case constants.FETCH_ACCOUNT_FILTERS.FAILURE: {
      return {
        ...state,
        isFetching: false
      };
    }

    case constants.FETCH_FILTER_BY_IDS.SUCCESS:
    case constants.FETCH_ACCOUNT_FILTERS.SUCCESS: {
      const { filters } = payload.response;
      return {
        ...state,
        accountFilters: { ...keyBy(filters, byId), ...state.accountFilters }, // local version of filter wins over backend version of filter.
        fetched: true,
        isFetching: false
      };
    }

    case constants.RESET_ACCOUNT_FILTER_LOCAL: {
      const { id } = payload;
      return {
        ...state,
        filterSortChanged: false,
        accountFilters: {
          ...state.accountFilters,
          ...(state.originalAccountFilters[id] && {
            [id]: state.originalAccountFilters[id]
          })
        },
        originalAccountFilters: omit(state.originalAccountFilters, id)
      };
    }

    // remove the filters matching the ids from the hashes
    case constants.CLEAR_ACCOUNT_FILTERS_LOCAL: {
      const { ids } = payload;
      return {
        ...state,
        accountFilters: omit(state.accountFilters, ids),
        originalAccountFilters: omit(state.originalAccountFilters, ids)
      };
    }

    case constants.UPDATE_ACCOUNT_FILTER_LOCAL: {
      const { id } = payload;

      return {
        ...state,
        filterSortChanged: true,
        accountFilters: {
          ...state.accountFilters,
          [id]: { ...payload, filterChanged: true }
        },
        // store the original filter
        ...(!state.originalAccountFilters[id] && {
          originalAccountFilters: {
            ...state.originalAccountFilters,
            [id]: state.accountFilters[id]
          }
        })
      };
    }

    case constants.UPDATE_ACCOUNT_FILTER.TRIGGER:
    case constants.CREATE_ACCOUNT_FILTER.TRIGGER: {
      const { id } = payload;
      return {
        ...state,
        filterSortChanged: false,
        accountFilters: {
          ...state.accountFilters,
          // For filter levels, new filters won't have id in the payload. They will be in accountFilters
          // hashed by their level name, which is filter.page + filter.name
          [id || makeFilterLevelNameFromFilter(payload)]: {
            ...payload,
            filterChanged: false
          }
        }
      };
    }

    case constants.UPDATE_ACCOUNT_FILTER.SUCCESS: {
      const { filter } = payload.response;
      return {
        ...state,
        accountFilters: {
          ...state.accountFilters,
          [filter.id]: filter
        },
        originalAccountFilters: omit(state.originalAccountFilters, filter.id)
      };
    }

    case constants.CREATE_ACCOUNT_FILTER.SUCCESS: {
      const { filter } = payload.response;
      return {
        ...state,
        accountFilters: {
          // omitting filter.name handles newer cases where new filters' ids match the name
          // eg. with filter levels, a new filter would have id and name = 'Unplanned -> Scopes'
          ...omit(state.accountFilters, ['new', filter.name]),
          [filter.id]: filter
        },
        originalAccountFilters: omit(state.originalAccountFilters, filter.id)
      };
    }

    case constants.DELETE_ACCOUNT_FILTER.TRIGGER: {
      const { id } = payload;
      return {
        ...state,
        accountFilters: omit(state.accountFilters, id),
        originalAccountFilters: omit(state.originalAccountFilters, id)
      };
    }

    default:
      return state;
  }
};

export default userTheme;
