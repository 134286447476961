import { put, select } from 'redux-saga/effects';
import * as actionCreators from 'actionCreators';
import {
  getResponsiveMediaType,
  getResponsiveBreakpoints,
  getResponsiveStateGreaterThan,
  getAuthToken
} from 'selectors';

const pixelThreshold = 100;
const calcMovedDown = (mediaType, breakpoints, action) => {
  const { innerWidth } = action;
  const breakpoint = breakpoints[mediaType];
  return breakpoint - innerWidth < pixelThreshold;
};

export function* calculateResponsiveState(action) {
  const mediaType = yield select(getResponsiveMediaType);
  const breakpoints = yield select(getResponsiveBreakpoints);
  const movedDown = calcMovedDown(mediaType, breakpoints, action);

  // if (mediaType === 'large' && movedDown) {
  //   yield put(actionCreators.closeSidebarSearchAndProjects());
  // }
}

export function* checkShouldCloseSidebar(action) {
  const greaterThan = yield select(getResponsiveStateGreaterThan);
  // if (!greaterThan.extraLarge) {
  //   yield put(actionCreators.closeSidebarSearchAndProjects());
  // }
}

export function* closeSidebarSearchAndProjects() {
  const token = yield select(getAuthToken);
  yield put(actionCreators.toggleSidebarProjects(null, token, false, false));
  yield put(actionCreators.closeSidebarSearch());
}
