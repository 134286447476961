import { useGroupedMembersFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { useRegions } from 'SettingsModule/hooks';

export const useMembersByRegionFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  field,
  shouldUseDraft
} = {}) => {
  const { handleNavToRegionsPage, regionHash, getRegionLabel } = useRegions({
    isOff
  });

  const groupedMembersFilterValues = useGroupedMembersFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'membersByRegionFilter',
    filterListType: FilterListType.MembersByRegion,
    field,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    handleNavToEntityPage: handleNavToRegionsPage,
    groupEntityHash: regionHash,
    getGroupEntityLabel: getRegionLabel
  };
};
