import { createRequestTypes } from 'appUtils';

/* ----------------------------- entity options ----------------------------- */

export const FETCH_ENTITY_OPTIONS = createRequestTypes('FETCH_ENTITY_OPTIONS');

export const ENTITY_TYPES = {
  project: 'Project',
  phaseMembership: 'Phase',
  activityPhase: 'ActivityPhase',
  activityPhaseScheduleBar: 'ActivityPhaseSchedulueBar',
  timeEntry: 'TimeEntry'
} as const;

export const CLEAR_ENTITY_OPTIONS = 'CLEAR_ENTITY_OPTIONS';
