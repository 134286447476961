import { useDebugValue } from 'react';
import { useSelector } from 'react-redux';
import {
  getOwnProjectUnassignedCounts,
  getTeamMembershipsByAccountId
} from 'selectors';
import { getPositions } from 'BudgetModule/selectors/positions';
import { getIsUnassignedMemberBudget } from 'appUtils/budgetUtils';

/**
 * Returns either the name of the account associated with member budget, or position name
 * if the member budget is unassigned (+ count if necessary)
 */
const useMemberBudgetName = ({ memberBudget, projectId }) => {
  const isUnassigned = getIsUnassignedMemberBudget(memberBudget);
  const teamMembershipsByAccountId = useSelector(
    isUnassigned ? getEmptyObject : getTeamMembershipsByAccountId
  );
  const teamMembership = isUnassigned
    ? null
    : teamMembershipsByAccountId[memberBudget?.account_id];

  const positionHash = useSelector(getPositions);
  const projectUnassignedRoleCounts = useSelector(
    !isUnassigned
      ? getEmptyObject
      : (state) =>
          getOwnProjectUnassignedCounts(state, {
            projectId
          })
  );

  const memberBudgetDisplayName = isUnassigned
    ? formatUnassignedRoleName({
        memberBudget,
        positionHash,
        unassignedRoleCounts: projectUnassignedRoleCounts
      })
    : teamMembership?.account.name;

  return memberBudgetDisplayName || '';
};

export default useMemberBudgetName;

/* ------------------------------------ - ----------------------------------- */

const getEmptyObject = () => ({});

/**
 * Returns the name of an unassigned role, with count included if necessary
 * eg. returns either 'Admin' or 'Admin (2)'
 */
export const formatUnassignedRoleName = ({
  memberBudget,
  unassignedRoleCounts,
  position,
  positionHash
}) => {
  const positionId = memberBudget?.position_id;
  const _position = position || positionHash?.[positionId];
  const showCount =
    memberBudget?.position_number !== null &&
    unassignedRoleCounts[positionId] > 1;
  return _position
    ? `${_position.name}${
        showCount ? ` | ${memberBudget.position_number}` : ''
      }`
    : '';
};

export const formatUnassignedRoleNameByPositionNumber = ({
  position,
  highestPositionNumberByPositionId,
  addedRoleIdCount,
  // Will return the next count, otherwise returns the current count of role in the name.
  includeNextInCount
}) => {
  if (!highestPositionNumberByPositionId || !position) return '';

  const positionCount =
    ~~highestPositionNumberByPositionId[position.id] +
    ~~addedRoleIdCount?.[position.id];

  const showCount = includeNextInCount ? positionCount : positionCount > 1;

  return `${position.name}${
    showCount
      ? ` | ${includeNextInCount ? positionCount + 1 : positionCount}`
      : ''
  }`;
};

export const getUnassignedRoleCount = ({
  memberBudget,
  unassignedRoleCounts
}) => {
  const positionId = memberBudget?.position_id;
  const showCount =
    memberBudget?.position_number !== null &&
    unassignedRoleCounts[positionId] > 1;
  return showCount ? memberBudget.position_number : undefined;
};
