import React from 'react';
import { actionDescriptionCreator } from 'appConstants/actionDescription.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const MetadataRow = ({
  metadata,
  isActivityDoneByCurrentUser,
  isAssignedToCurrentUser
}) => {
  const { actor, created_at } = metadata;
  const actionDescription = actionDescriptionCreator(
    metadata,
    isAssignedToCurrentUser
  );
  const createdTime = moment(created_at).format('L');
  const today = moment().format('L');
  let formattedTime;
  if (createdTime == today) {
    formattedTime = 'Today ' + moment(created_at).format('h:mm A');
  } else if (
    createdTime ==
    moment(moment().subtract(1, 'days').startOf('day')).format('L')
  ) {
    formattedTime = 'Yesterday ' + moment(created_at).format('h:mm A');
  } else {
    formattedTime = moment(created_at).format('M/D/YY h:mm A');
  }

  const actorName = isActivityDoneByCurrentUser ? 'You' : actor.name;

  return (
    <div className="task-metadata-row">
      <div className="task-metadata-text">
        <span className="action-creator">{actorName}</span>
        <span className="action-description">{actionDescription}</span>
        <span className="action-time-stamp">{formattedTime}</span>
      </div>
    </div>
  );
};

export default MetadataRow;
