import React from 'react';
import NewMembersIcon from 'icons/NewMembersIcon';

export const NumMembers = ({ numMembers, 'data-testid': dataTestId }) => {
  return (
    <div className="description" data-testid={dataTestId}>
      {numMembers}
      <NewMembersIcon strokeColor="#828282" strokeWidth="1.2" />
    </div>
  );
};
