import { PureComponent } from 'react';
import {
  TaskContentRight,
  TaskColumnsWrapper,
  TaskColumnBox,
  TaskColumnBoxHeightWrapper,
  TaskBatchActionTogglePanel,
  StyledNewDependencyDateRangeCalendar
} from './styles';
import moment from 'moment';
import FlagIcon from 'icons/FlagIcon';
import CalendarIcon from 'icons/CalendarIcon';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import intersection from 'lodash/intersection';
import taskEditClasses from 'appConstants/taskEditClasses';
import { calculateSelectedDateRange } from 'appUtils/dateRangeHelpers';
import EstimatedHours from './EstimatedHours';
import TaskStatus from './TaskStatus';
import TaskMilestone from './TaskMilestone';
import TaskCompleted from './TaskCompleted';
import TaskAssigneeCell from './TaskAssigneeCell';
import TaskAssignedCell from './TaskAssignedCell';
import TaskTimerCell from './TaskTimerCell';
import TaskBatchActionToggle from './TaskBatchActionToggle';
import {
  baseModalColumns,
  getTaskColumnOrderWithSelection,
  getSelectedProject,
  getCurrentFilter,
  getIsOnProjectView,
  getDependencyTaskHash
} from 'selectors';
import { getAuthState } from 'AuthenticationModule/selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import { DEPENDENCY_TYPES, DEPENDENCY_STRINGS } from 'appConstants/workload';

import TaskPriority from './TaskPriority';
import TaskDatePickerInput from 'components/DatePicker/TaskPickerInput';
import TaskRangePickerInput from 'components/DatePicker/TaskRangePickerInput';
import cn from 'classnames';

import {
  generateNewDependencyInfos,
  isStartDateDependency,
  isEndDateDependency,
  isLegacyStartAndEndDateDependency
} from 'appUtils/newDependencies';

const flagIcon = <FlagIcon width={10} height={13} />;

const calendarIcon = <CalendarIcon width={14} height={14} />;

const bindToDay = (date) =>
  date ? date.startOf('day').format('YYYY-MM-DD') : null;

class TaskColumns extends PureComponent {
  state = {
    adding: false,
    newDates: null,
    rangeCalendarActive: false
  };

  setRangeCalendarActive = () => {
    this.setState({ rangeCalendarActive: true });
  };

  clearRangeCalendarActive = (isClickOutside) => {
    this.setState({ rangeCalendarActive: false });
    isClickOutside && this.handleDateSelection([]);
  };

  columnBoxRef = {};
  getPermissions = () => {
    const {
      task: { project_id: taskProjectId },
      matchedParams: { projectId },
      selectedTeamId
    } = this.props;
    const permissions = {
      projectId: taskProjectId || projectId,
      teamId: selectedTeamId
    };
    return permissions;
  };

  handleDateSelection = (values) => {
    const { task } = this.props;
    const newDates = calculateSelectedDateRange({
      newValues: values,
      start: task?.schedule_start,
      end: task?.schedule_end,
      itemExists: !!task
    });
    this.setState({
      newDates
    });
  };

  clearDates = () => {
    this.setState({
      newDates: [],
      rangeCalendarActive: false
    });
  };

  handleRangeCalendarSelect = (datesObj) => {
    const {
      auth: { token },
      handleTasksAttributesUpdate,
      task: { id },
      handleTaskEditClick
    } = this.props;

    const permissions = this.getPermissions();

    const body = {
      task_ids: [id],
      ...datesObj
    };
    handleTasksAttributesUpdate({ token, body, permissions });
    handleTaskEditClick(null);
  };

  handleCalendarSelect =
    (key) =>
    ({ startDate: moment }) => {
      const {
        auth: { token },
        handleTasksAttributesUpdate,
        task: { id },
        handleTaskEditClick
      } = this.props;

      const permissions = this.getPermissions();

      const updatedDate = bindToDay(moment);
      const body = {
        task_ids: [id],
        [key]: updatedDate
      };
      this.clearRangeCalendarActive();
      handleTasksAttributesUpdate({ token, body, permissions });
      handleTaskEditClick(null);
    };

  handlePropertySelect = (key) => (value) => {
    const {
      auth: { token },
      handleTasksAttributesUpdate,
      task: { id },
      handleTaskEditClick
    } = this.props;

    const body = {
      task_ids: [id],
      [key]: value
    };

    const permissions = this.getPermissions();
    handleTasksAttributesUpdate({ token, body, permissions });
    handleTaskEditClick(null);
  };

  handleMultiAssignDone = ({
    assigneeIds = [],
    unassigneeIds = [],
    assignProjectMembershipIds = [],
    unassignProjectMembershipIds = []
  }) => {
    const {
      auth: { token },
      handleTasksAttributesUpdate,
      task: { id },
      handleTaskEditClick
    } = this.props;

    const body = {
      task_ids: [id],
      assignee_ids: assigneeIds,
      assign_project_membership_ids: assignProjectMembershipIds,
      unassignee_ids: unassigneeIds,
      unassign_project_membership_ids: unassignProjectMembershipIds
    };

    const permissions = this.getPermissions();
    handleTasksAttributesUpdate({ token, body, permissions });
    handleTaskEditClick(null);
  };

  handleClick = (taskProperty) => (e) => {
    const { handleTaskEditClick } = this.props;
    if (
      e &&
      e.target &&
      intersection(e.target.classList, taskEditClasses).length
    ) {
      return;
    }
    handleTaskEditClick(taskProperty);
  };

  handleChange = (taskProperty) => {
    const { rangeCalendarActive } = this.state;
    const {
      task: { schedule_end: scheduleEnd }
    } = this.props;
    switch (taskProperty) {
      case 'due_at': {
        return this.handleCalendarSelect(taskProperty);
      }
      case 'schedule_start': {
        return rangeCalendarActive || scheduleEnd
          ? this.handleRangeCalendarSelect
          : this.handleCalendarSelect(taskProperty);
      }
      case 'completed_at':
      case 'phase_id':
      case 'task_status_id':
      case 'task_priority_id':
      case 'estimated_hours':
      case 'assignee_ids': {
        return this.handlePropertySelect(taskProperty);
      }
    }
  };

  renderColumnBox = (taskProperty) => {
    const {
      taskIsEditing,
      isOnTeamProject,
      isBatchSelected,
      isCreatingTask,
      taskEditProperty,
      isTaskModal,
      isNewTask,
      isComplete,
      isOnHomeView
    } = this.props;

    return (
      <TaskColumnBox
        key={taskProperty}
        onClick={this.handleClick(taskProperty)}
        isTaskEditProperty={taskProperty === taskEditProperty}
        beingEdited={taskIsEditing}
        isOnTeamProject={isOnTeamProject}
        isComplete={isComplete}
        isBatchSelected={isBatchSelected}
        isCreatingTask={isCreatingTask}
        isTaskModal={isTaskModal}
        isOnHomeView={isOnHomeView}
        isNewTask={isNewTask}
        taskProperty={taskProperty}
        className={cn({
          hideBorder: taskProperty === 'timer' || isTaskModal
        })}
      >
        <TaskColumnBoxHeightWrapper
          isTaskModal={isTaskModal}
          ref={(ref) => (this.columnBoxRef[taskProperty] = ref)}
        >
          {this.renderColumnContent(taskProperty)}
        </TaskColumnBoxHeightWrapper>
      </TaskColumnBox>
    );
  };

  renderSelectionBox = () => {
    const {
      taskIsEditing,
      isNewTask,
      isCreatingTask,
      isTaskModal,
      taskBatchActionIsToggled,
      showBatchActionToggles,
      fillWhite,
      handleBatchActionToggleClick,
      testIdPrefix
    } = this.props;
    return (
      <TaskBatchActionTogglePanel>
        <TaskBatchActionToggle
          testIdPrefix={testIdPrefix}
          handleBatchActionToggleClick={handleBatchActionToggleClick}
          taskIsEditing={taskIsEditing}
          isCreatingTask={isCreatingTask}
          taskBatchActionIsToggled={taskBatchActionIsToggled}
          showBatchActionToggles={showBatchActionToggles}
          fillWhite={fillWhite}
          isTaskModal={isTaskModal}
          isNewTask={isNewTask}
        />
      </TaskBatchActionTogglePanel>
    );
  };

  renderColumnContent = (taskProperty) => {
    const {
      taskIsEditing,
      task,
      project,
      currentFilter,
      taskEditProperty,
      alwaysRenderInput,
      alwaysRenderDue,
      alwaysRenderSchedule,
      handleTaskEditClick,
      isNewTask,
      isOnProjectView,
      isTaskModal,
      openTaskModal,
      testIdPrefix,
      taskHash,
      isCreatingTask
    } = this.props;
    const { rangeCalendarActive } = this.state;

    const target = this.columnBoxRef[taskProperty];
    const alwaysRenderDueIn =
      alwaysRenderDue || alwaysRenderInput || !!task.due_at;
    const alwaysRenderScheduleIn =
      alwaysRenderSchedule || alwaysRenderInput || false;

    const handleDateRangeSelect = (
      startDate,
      endDate,
      handler,
      dependency,
      dependencyItem
    ) => {
      if (startDate?.isValid?.()) {
        const dependencyInfos = generateNewDependencyInfos({
          item: task,
          dependency,
          dependencyItem,
          dependableType: DEPENDENCY_TYPES.TASK
        });
        handler({
          schedule_start: startDate ? startDate.format('YYYY-MM-DD') : null,
          schedule_end: endDate ? endDate.format('YYYY-MM-DD') : null,
          dependency_infos: dependencyInfos
        });
      }
    };

    const mapDependencies = () => {
      const initialDependencyItem = { start: null, end: null };
      const initialDependency = { start: null, end: null };
      if (task.dependencies?.length > 0) {
        task.dependencies?.forEach((dependency) => {
          const dependencyTaskItem = taskHash[dependency.parent_id] ?? {
            description: '',
            start_date: '',
            end_date: ''
          };
          const isStartDependency = isStartDateDependency(
            dependency.dependency_type
          );
          const isEndDependency = isEndDateDependency(
            dependency.dependency_type
          );

          if (
            isStartDependency ||
            isLegacyStartAndEndDateDependency(dependency.dependency_type)
          ) {
            initialDependency.start = isStartDependency
              ? dependency.dependency_type
              : DEPENDENCY_STRINGS.START;

            initialDependencyItem.start = {
              ...dependencyTaskItem,
              name: dependencyTaskItem.description, // map below properties to properties read by DependencyList
              start_date: dependencyTaskItem.schedule_start,
              end_date: dependencyTaskItem.schedule_end
            };
          } else if (
            isEndDependency ||
            isLegacyStartAndEndDateDependency(dependency.dependency_type)
          ) {
            initialDependency.end = isEndDependency
              ? dependency.dependency_type
              : DEPENDENCY_STRINGS.END;

            initialDependencyItem.end = {
              ...dependencyTaskItem,
              name: dependencyTaskItem?.description, // map below properties to properties read by DependencyList
              start_date: dependencyTaskItem?.schedule_start,
              end_date: dependencyTaskItem?.schedule_end
            };
          }
        });
      }
      return { initialDependency, initialDependencyItem };
    };

    const { initialDependency, initialDependencyItem } = mapDependencies();

    const hasStartDependency = isStartDateDependency(initialDependency.start);

    const hasEndDependency = isEndDateDependency(initialDependency.end);

    switch (taskProperty) {
      case 'due_at':
        return (
          <StyledNewDependencyDateRangeCalendar
            onSubmit={this.handleChange(taskProperty)}
            itemStartDate={task[taskProperty]}
            isSingleDay
            showClear
            onClear={this.handleChange(taskProperty)}
            customInput={(startDate, endDate, openCalendar) => (
              <div
                onClick={openCalendar}
                style={{ width: '100%', height: '100%' }}
              >
                <TaskDatePickerInput
                  testIdPrefix={testIdPrefix}
                  taskIsEditing={taskIsEditing}
                  icon={this.renderIcon(taskProperty)}
                  selectedDate={moment(startDate).isValid() ? startDate : null}
                  taskDateType={taskProperty}
                  alwaysRenderInput={alwaysRenderDueIn}
                  currentFilter={currentFilter}
                  taskEditProperty={taskEditProperty}
                  isTaskCompleted={task.is_completed}
                  projectDetailView={isOnProjectView}
                  isTaskModal={isTaskModal}
                />
              </div>
            )}
            calendarClassNames={'home-task-calendar'}
          />
        );
      case 'schedule_start': {
        if (task.schedule_end) {
          return (
            <StyledNewDependencyDateRangeCalendar
              onSubmit={({ startDate, endDate, dependency, dependencyItem }) =>
                handleDateRangeSelect(
                  startDate,
                  endDate,
                  this.handleChange(taskProperty),
                  dependency,
                  dependencyItem
                )
              }
              itemStartDate={task.schedule_start}
              itemEndDate={task.schedule_end}
              showClear
              onClear={({ startDate, endDate }) =>
                handleDateRangeSelect(
                  startDate,
                  endDate,
                  this.handleChange(taskProperty)
                )
              }
              onClose={this.clearRangeCalendarActive}
              isTaskCalendar
              shouldRenderFooter={!isCreatingTask}
              item={task}
              initialDependency={initialDependency}
              initialDependencyItem={initialDependencyItem}
              initialDependencyItemType={DEPENDENCY_TYPES.TASK}
              customInput={(startDate, endDate, openCalendar) => (
                <TaskRangePickerInput
                  taskIsEditing={taskIsEditing}
                  startDate={task?.schedule_start}
                  endDate={task?.schedule_end}
                  alwaysRenderInput={alwaysRenderScheduleIn}
                  currentFilter={currentFilter}
                  taskEditProperty={taskEditProperty}
                  isTaskCompleted={task.is_completed}
                  projectDetailView={isOnProjectView}
                  isTaskModal={isTaskModal}
                  openCalendar={openCalendar}
                  hasStartDependency={hasStartDependency}
                  hasEndDependency={hasEndDependency}
                />
              )}
              calendarClassNames={'home-task-calendar'}
            />
          );
        } else {
          return rangeCalendarActive ? (
            <StyledNewDependencyDateRangeCalendar
              onSubmit={({ startDate, endDate, dependency, dependencyItem }) =>
                handleDateRangeSelect(
                  startDate,
                  endDate,
                  this.handleChange(taskProperty),
                  dependency,
                  dependencyItem
                )
              }
              itemStartDate={task.schedule_start}
              itemEndDate={task.schedule_end}
              showClear
              isTaskCalendar
              shouldRenderFooter={!isCreatingTask}
              item={task}
              initialDependency={initialDependency}
              initialDependencyItem={initialDependencyItem}
              initialDependencyItemType={DEPENDENCY_TYPES.TASK}
              onClear={({ startDate, endDate }) =>
                handleDateRangeSelect(
                  startDate,
                  endDate,
                  this.handleChange(taskProperty)
                )
              }
              onClose={this.clearRangeCalendarActive}
              customInput={(startDate, endDate, openCalendar) => (
                <TaskRangePickerInput
                  taskIsEditing={taskIsEditing}
                  startDate={task?.schedule_start}
                  endDate={task?.schedule_end}
                  alwaysRenderInput={alwaysRenderScheduleIn}
                  currentFilter={currentFilter}
                  taskEditProperty={taskEditProperty}
                  isTaskCompleted={task.is_completed}
                  projectDetailView={isOnProjectView}
                  isTaskModal={isTaskModal}
                  openCalendar={openCalendar}
                  hasStartDependency={hasStartDependency}
                  hasEndDependency={hasEndDependency}
                />
              )}
              calendarClassNames={'home-task-calendar'}
            />
          ) : (
            <StyledNewDependencyDateRangeCalendar
              onSubmit={this.handleChange(taskProperty)}
              itemStartDate={task[taskProperty]}
              isSingleDay
              showClear
              onClear={this.handleChange(taskProperty)}
              showSetRange
              onSetRange={this.setRangeCalendarActive}
              item={task}
              customInput={(startDate, endDate, openCalendar) => (
                <div
                  onClick={openCalendar}
                  style={{ width: '100%', height: '100%' }}
                >
                  <TaskDatePickerInput
                    testIdPrefix={`${testIdPrefix}-schedule`}
                    taskIsEditing={taskIsEditing}
                    selectedDate={
                      moment(startDate).isValid() ? startDate : null
                    }
                    taskDateType={taskProperty}
                    alwaysRenderInput={alwaysRenderScheduleIn}
                    currentFilter={currentFilter}
                    taskEditProperty={taskEditProperty}
                    isTaskCompleted={task.is_completed}
                    projectDetailView={isOnProjectView}
                    isTaskModal={isTaskModal}
                  />
                </div>
              )}
              calendarClassNames={'home-task-calendar'}
            />
          );

          /*
        <DatePicker
              taskIsEditing={taskIsEditing}
              selectedValue={task[taskProperty]}
              icon={this.renderIcon(taskProperty)}
              taskDateType={taskProperty}
              onChange={this.handleChange(taskProperty)}
              showDateInput
              taskProperty={taskProperty}
              taskListView
              showRangeOption
              alwaysRenderInput={alwaysRenderScheduleIn}
              taskEditProperty={taskEditProperty}
              currentFilter={currentFilter}
              isTaskCompleted={task.is_completed}
              projectDetailView={isOnProjectView}
              target={target}
              isTaskModal={isTaskModal}
              onRangeClick={this.setRangeCalendarActive}
            />
        */
        }
      }
      case 'estimated_hours': {
        return (
          <EstimatedHours
            isNewTask={isNewTask}
            hours={
              task.estimated_hours !== null
                ? parseFloat(task.estimated_hours)
                : null
            }
            isEditing={taskIsEditing && taskEditProperty === 'estimated_hours'}
            onSubmit={this.handleChange('estimated_hours')}
            handleTaskEditClick={handleTaskEditClick}
            taskIsEditing={taskIsEditing}
          />
        );
      }
      case 'status': {
        return (
          <TaskStatus
            target={target}
            isNewTask={isNewTask}
            statusId={task.task_status_id}
            onSubmit={this.handleChange('task_status_id')}
            taskId={task.id}
            taskIsEditing={taskIsEditing}
          />
        );
      }
      case 'priority': {
        return (
          <TaskPriority
            target={target}
            isNewTask={isNewTask}
            priorityId={task.task_priority_id}
            onSubmit={this.handleChange('task_priority_id')}
            taskId={task.id}
            taskIsEditing={taskIsEditing}
          />
        );
      }
      case 'phase_id': {
        return (
          <TaskMilestone
            isNewTask={isNewTask}
            target={target}
            phaseId={task.phase_id}
            onSubmit={this.handleChange('phase_id')}
            projectPhases={project.phases}
            handleTaskEditClick={handleTaskEditClick}
            taskIsEditing={taskIsEditing}
          />
        );
      }
      case 'completed_at': {
        return <TaskCompleted date={task.completed_at} />;
      }
      case 'assignee': {
        return (
          <TaskAssigneeCell
            task={task}
            handleChange={this.handleChange('assignee_ids')}
            handleMultiAssignDone={this.handleMultiAssignDone}
            isEditing={taskIsEditing && taskEditProperty === 'assignee'}
            isNewTask={isNewTask}
            projectDetailView={isOnProjectView}
          />
        );
      }
      case 'assigned': {
        return (
          <TaskAssignedCell
            task={task}
            isEditing={taskIsEditing && taskEditProperty === 'assignee'}
            projectDetailView={isOnProjectView}
            openTaskModal={openTaskModal}
          />
        );
      }
      case 'selection': {
        return this.renderSelectionBox();
      }
      case 'timer': {
        return (
          <TaskTimerCell
            task={task}
            isEditing={taskIsEditing && taskEditProperty === 'assignee'}
            projectDetailView={isOnProjectView}
            isActive={false} // change this later
          />
        );
      }
    }
  };

  renderIcon = (taskProperty) => {
    switch (taskProperty) {
      case 'due_at': {
        return flagIcon;
      }
      case 'schedule_start': {
        return calendarIcon;
      }
    }
  };

  render() {
    const {
      taskIsEditing,
      isOnTeamProject,
      isBatchSelected,
      isCreatingTask,
      isTaskModal,
      taskColumnOrder,
      isNewTask
    } = this.props;

    return (
      <TaskColumnsWrapper
        beingEdited={taskIsEditing}
        isOnTeamProject={isOnTeamProject}
        isBatchSelected={isBatchSelected}
        isCreatingTask={isCreatingTask}
        isTaskModal={isTaskModal}
        isTaskColumnsWrapper
        isNewTask={isNewTask}
      >
        <TaskContentRight>
          {isTaskModal
            ? baseModalColumns.map(this.renderColumnBox)
            : taskColumnOrder.map(this.renderColumnBox)}
        </TaskContentRight>
      </TaskColumnsWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentFilter: getCurrentFilter(state),
  auth: getAuthState(state),
  project: getSelectedProject(state),
  taskColumnOrder: getTaskColumnOrderWithSelection(state),
  selectedTeamId: getSelectedTeamId(state),
  matchedParams: getMatchedRouteParams(state),
  isOnProjectView: getIsOnProjectView(state),
  taskHash: getDependencyTaskHash(state)
});

export default withRouter(connect(mapStateToProps, null)(TaskColumns));
