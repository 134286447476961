import { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import sumBy from 'lodash/sumBy';
import { getTeamMembersHash } from 'selectors';
import { getWidgetDetailModalId } from 'DashboardModule/selectors';
import { getAccountCapacities } from 'CapacityModule/selectors';
import {
  makeGetOrderedMemberUtilizations,
  makeGetIsFetchingUtilizationReport
} from 'UtilizationModule/selectors';
import { StyledMiniUtilizationTable, Row } from './styles';
import MemberCell from './Cells/MemberCell';
import GraphCell from './Cells/GraphCell';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import {
  DATA_KEY_TO_PERCENT,
  CHART_STACK_ORDER,
  CHART_STACK_ORDER_WITH_CAPACITY
} from 'UtilizationModule/constants';

const EmptyDiv = () => <div></div>;

const memberColumn = {
  headerType: 'member',
  headerLabel: '',
  id: 'member',
  align: 'center'
};
const graphColumn = {
  headerType: 'graph',
  headerLabel: '',
  id: 'graph',
  align: 'center'
};
const tableColumns = [memberColumn, graphColumn];

const memberRowCells = {
  graph: GraphCell,
  member: MemberCell
};
const columnWidths = {
  graph: 275,
  member: 45
};

/* ------------------------------------ - ----------------------------------- */

const MiniUtilizationTable = ({
  orderedMemberUtilizations,
  teamMembersHash,
  accountCapacityTotals,
  isFetching,
  renderViewMore,
  hasData,
  includeRemainingCapacity,
  useCapacityAsDenominator
}) => {
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState(false);

  const columns = useMemo(() => {
    return tableColumns.map((columnHeader) => ({
      ...columnHeader,
      memberRow: memberRowCells[columnHeader.headerType] || EmptyDiv,
      width: columnWidths[columnHeader.headerType],
      Cell: <div></div>
    }));
  }, []);

  const totalWidth = useMemo(() => {
    return columns.reduce((acc, cur) => {
      acc += cur.width;
      return acc;
    }, 0);
  }, [columns]);

  const rows = useMemo(() => {
    const membersList = [];
    const maxHours = { value: 0 };
    const maxPercent = { value: 0 };
    // There's no fetch when account_ids is empty, so orderedMemberUtilizations
    // may have stale data
    (hasData ? orderedMemberUtilizations : []).forEach((memberUtilization) => {
      const accountId = memberUtilization.account_id;
      const member = teamMembersHash[accountId];
      const accountCapacityTotal = accountCapacityTotals[accountId];
      const billableCapacityPercent = accountCapacityTotal
        ? (memberUtilization.billable_hours / accountCapacityTotal) * 100
        : 0;
      const capacityPercent = accountCapacityTotal
        ? (memberUtilization.totalHours / accountCapacityTotal) * 100
        : 0;

      const currentItemPercent = useCapacityAsDenominator
        ? sumBy(
            includeRemainingCapacity
              ? CHART_STACK_ORDER_WITH_CAPACITY
              : CHART_STACK_ORDER,
            // percent vlues are calculated by capacity when useCapacityAsDenominator is true
            // do not include negative percent value which will make it 100% always
            (key) => +memberUtilization[DATA_KEY_TO_PERCENT[key]] || 0
          )
        : +memberUtilization.billable_percentage || 0;

      maxHours.value = Math.max(memberUtilization.totalHours, maxHours.value);
      maxPercent.value = Math.max(currentItemPercent, maxPercent.value);

      membersList.push({
        data: {
          ...memberUtilization,
          totalPercent: currentItemPercent
        },
        billableCapacityPercent,
        capacityPercent,
        maxHours,
        maxPercent,
        member,
        rowType: 'memberRow',
        rowHeight: 40
      });
    });
    return membersList.sort(
      (a, b) => b.billableCapacityPercent - a.billableCapacityPercent
    );
  }, [
    accountCapacityTotals,
    hasData,
    includeRemainingCapacity,
    orderedMemberUtilizations,
    teamMembersHash,
    useCapacityAsDenominator
  ]);

  useEffect(() => {
    if (!isFetching && !hasInitiallyLoaded) {
      setHasInitiallyLoaded(true);
    }
  }, [hasInitiallyLoaded, isFetching]);

  const showLoader = !hasInitiallyLoaded || isFetching;

  return (
    <StyledMiniUtilizationTable
      // 6px is scroll bar width to preserve horizontal position when more than 5 rows
      marginLeft={rows.length > 5 ? '6px' : 0}
      centerContent={rows.length < 5}
      style={{ width: totalWidth }}
    >
      {showLoader ? (
        <SkeletonLoader
          numLoaders={4}
          style={{ paddingLeft: 14, marginTop: 10, height: 200 }}
          loaderStyle={{ height: 70, rx: 4 }}
        />
      ) : (
        rows.map((row, index) => {
          return (
            <Row key={index} style={{ height: row.rowHeight }}>
              {columns.map((column, index) => {
                const Cell = column[row.rowType];
                return (
                  <div style={{ width: column.width }} key={index}>
                    <Cell
                      row={row}
                      column={column}
                      style={{ width: column.width }}
                      includeRemainingCapacity={includeRemainingCapacity}
                    />
                  </div>
                );
              })}
            </Row>
          );
        })
      )}
    </StyledMiniUtilizationTable>
  );
};

const makeMapStateToProps = () => {
  const getIsFetchingUtilizationReport = makeGetIsFetchingUtilizationReport();
  const getOrderedMemberUtilizations = makeGetOrderedMemberUtilizations();
  const mapStateToProps = (state, ownProps) => {
    const isFetching =
      getIsFetchingUtilizationReport(state, ownProps) &&
      !(ownProps.isDashboardWidget && getWidgetDetailModalId(state));
    const orderedMemberUtilizations = getOrderedMemberUtilizations(
      state,
      ownProps
    );
    return {
      accountCapacities: getAccountCapacities(state),
      teamMembersHash: getTeamMembersHash(state),
      orderedMemberUtilizations,
      startIndex: ownProps.startIndex || 0, // for controlling the rows that are displayed
      pageSize: ownProps.pageSize || orderedMemberUtilizations.length,
      isFetching
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MiniUtilizationTable);
