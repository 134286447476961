import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type TodayIconProps = Pick<
  SvgIconProps,
  'width' | 'height' | 'className' | 'onClick'
>;

export const TodayIcon = ({
  className,
  height = '18',
  width = '18',
  onClick
}: TodayIconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M3.50531 14.4746L4.18448 11.8541L5.90873 13.734L3.50531 14.4746ZM13.491 6.09547L13.5 6.10533C13.5 6.1114 13.4988 6.11802 13.4966 6.12385C13.4945 6.12949 13.4921 6.13255 13.4909 6.13389L13.4905 6.13432L7.53669 12.6263L5.17119 10.047L11.1431 3.53533L13.491 6.09547Z"
        stroke="#2F80ED"
      />
    </SvgIcon>
  );
};

export default TodayIcon;
