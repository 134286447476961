import styled from 'styled-components';
import theme from 'theme';
export const PlaceHolderContainer = styled.div`
  padding: 32px;
`;

export const PlaceHolderHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
`;
export const PlaceHolderText = styled.div`
  font-size: 16px;
  font-weight: 400;
  max-width: 400px;
  color: ${theme.colors.colorMediumGray5};
  margin: auto;
`;
