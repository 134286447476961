import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import KaratRight from 'icons/KaratRight';
import { MemberGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { StyledKaratContainer } from '../shared/style';

const SCORE_MINIMUM_THRESHOLD_TO_BE_VISIBLE = 0.2;

const MemberTitleCell = ({ row }: MemberGroupRowProps) => {
  const {
    teamMembershipsByAccountId,
    member: suggestedMember,
    isOpen,
    accountId,
    listItems = [],
    toggleCollapse
  } = row.original;
  const member = teamMembershipsByAccountId[accountId];
  const { score } = suggestedMember;

  return (
    <StyledTitleContainer onClick={toggleCollapse}>
      <StyledKaratContainer
        isOpen={isOpen || false}
        className="member"
        isHidden={!listItems.length}
      >
        <KaratRight />
      </StyledKaratContainer>
      <MemberInitials
        member={member}
        classes={cn('regular-member-no-hover selected')}
      />
      <StyledNameContainer>
        <div className="name">{member?.account?.name}</div>
        <StyledPercentContainer>
          {/* SPEC: Show score that is over SCORE_MINIMUM_THRESHOLD_TO_BE_VISIBLE */}
          {score > SCORE_MINIMUM_THRESHOLD_TO_BE_VISIBLE
            ? `${(score * 100).toFixed(0)}% Match`
            : ''}
        </StyledPercentContainer>
      </StyledNameContainer>
    </StyledTitleContainer>
  );
};

export default MemberTitleCell;

const StyledNameContainer = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .location-text {
    font-size: 12px;
    color: ${theme.colors.colorCalendarGray};
  }

  .name {
    font-weight: 600;
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 6px;
  cursor: pointer;
  margin-left: 5px;
`;

const StyledPercentContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
`;
