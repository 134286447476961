import { useMemo, useState, useCallback } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Header, Title, Button, ButtonDiv } from './styles';
import getDefaultCloneProjectFlags from 'appConstants/cloneProjectFlags';
import CheckBoxListContainer from 'SettingsModule/components/CheckboxContainer';
import moment from 'moment';
import styled from 'styled-components';
import {
  CheckBoxListItemsContainer,
  CheckBoxOptionsContainer,
  CheckBoxOptionText,
  CustomCheckBox,
  CustomCheckBoxContainer,
  TooltipContainer,
  HiddenInput,
  CustomSubComponentContainer
} from 'SettingsModule/components/styles';
import theme from 'theme';
import ReactDOM from 'react-dom';
import { tooltipPortal } from 'appUtils/portal';
import keyBy from 'lodash/keyBy';
import { SelectBoardDropdownContainer } from 'views';
import { getGroups } from 'selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import QBDownArrow from 'icons/QBDownArrow';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

const ProjectCloneModal = ({
  handleClone,
  handleCancel,
  showProjectSelector
}) => {
  const { isHoursOnly } = useIsHoursOnly();
  const defaultCloneProjectFlags = getDefaultCloneProjectFlags(isHoursOnly);
  const [flags, setFlags] = useState({ ...defaultCloneProjectFlags });
  const [isSubmitted, setSubmitted] = useState(false);
  const [cloneDate, setCloneDate] = useState(moment());
  const boards = useAppSelector(getGroups);
  const [isPortfolioDropdownOpen, setIsPortfolioDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSelectorWarning, setShowSelectorWarning] = useState(false);
  const selectorLabel = showProjectSelector
    ? 'Select Project'
    : 'Select Portfolio';

  const handleDateChange = (startDate) => setCloneDate(startDate);

  /**
   * This is a helper function to get formatted data list for childnodes of `item`
   */
  const getChildNodes = useCallback((flags, item) => {
    const childNodes =
      flags[item].subFlags?.map((subItem) => {
        const isRequiredBy = flags[subItem].isRequiredBy;
        const isRequired = isRequiredBy?.some(
          (requiredByFlag) => flags[requiredByFlag].value
        );
        const showConfirmationModalOnChecked =
          flags[subItem].showConfirmationModalOnChecked &&
          flags[subItem].showConfirmationModalOnChecked.every(
            (flag) => flags[flag].value
          );
        const child = {
          name: subItem,
          text: flags[subItem].name,
          default: false,
          parent: item,
          subText: flags[subItem].subText,
          tooltip: flags[subItem].tooltip,
          childNodes: flags[subItem].subFlags
            ? getChildNodes(flags, subItem)
            : [],
          isHeader: !!flags[subItem].subFlags,
          ...(flags[subItem].customSubComponent && {
            customSubComponent: flags[subItem].customSubComponent,
            handleChange: handleDateChange
          }),
          isRequired,
          requiredTooltip: flags[subItem].requiredTooltip,
          grayoutWhenChecked: flags[subItem].grayoutWhenChecked,
          noPartialSelectCheckBox: flags[subItem].noPartialSelectCheckBox,
          confirmationComponent: flags[subItem].confirmationComponent,
          showConfirmationModalOnChecked,
          className: `subItem ${flags[subItem].className ?? ''}`,
          skipRequiredCheck: flags[subItem].skipRequiredCheck
        };
        return child;
      }) ?? [];
    return childNodes;
  }, []);

  const getDataList = useCallback(
    (flags, section) => {
      const keys = Object.keys(section);
      return keys.map((item) => {
        const isRequiredBy = flags[item].isRequiredBy;
        const isRequired = isRequiredBy?.some(
          (requiredByFlag) => flags[requiredByFlag].value
        );
        const showConfirmationModalOnChecked =
          flags[item].showConfirmationModalOnChecked &&
          flags[item].showConfirmationModalOnChecked.every(
            (flag) => flags[flag].value
          );
        return {
          name: item,
          text: flags[item].name,
          default: false,
          tooltip: flags[item].tooltip,
          subText: flags[item].subText,
          childNodes: getChildNodes(flags, item),
          isHeader: true,
          isRequired,
          requiredTooltip: flags[item].requiredTooltip,
          grayoutWhenChecked: flags[item].grayoutWhenChecked,
          noPartialSelectCheckBox: flags[item].noPartialSelectCheckBox,
          confirmationComponent: flags[item].confirmationComponent,
          showConfirmationModalOnChecked,
          className: flags[item].className,
          skipRequiredCheck: flags[item].skipRequiredCheck
        };
      });
    },
    [getChildNodes]
  );

  const showSelectAll = useMemo(() => {
    return Object.values(flags).some(
      (flag) => !flag.ignoreInSelectAll && !flag.value
    );
  }, [flags]);

  const leftSectionDataList = useMemo(
    () => getDataList(flags, leftSection),
    [flags, getDataList]
  );

  const rightSectionDataList = useMemo(
    () => getDataList(flags, rightSection),
    [flags, getDataList]
  );

  /**
   * This is a helper function to traverse and check all the subflags given
   * a flagName based on the passed in checked boolean.
   * itemsKeyedByName are all the subFlags of flagName keyed by flag's name
   */
  const traverseSubFlags = (newFlags, flagName, checked, itemsKeyedByName) => {
    const flag = flags[flagName];
    if (
      flag.subFlags &&
      ((flag.onlyUncheckSubflag && !checked) || !flag.onlyUncheckSubflag)
    ) {
      flag.subFlags.forEach((subFlag) => {
        const subFlagItem = itemsKeyedByName[subFlag];
        if (
          subFlagItem &&
          (!subFlagItem.isRequired ||
            subFlagItem.skipRequiredCheck ||
            subFlagItem.childNodes.length > 0) // we want to check this subFlag's sub flags too even if this one is required, it may have sub flags thats not required
        ) {
          // skip checking this sub flag if its required or it skips require check
          if (!subFlagItem.isRequired || subFlagItem.skipRequiredCheck)
            newFlags[subFlag] = { ...flags[subFlag], value: checked };
          if (checked) checkDependencies(newFlags, subFlag);
          const subFlagChildNodesKeyedByName = keyBy(
            subFlagItem.childNodes,
            'name'
          );
          traverseSubFlags(
            newFlags,
            subFlag,
            checked,
            subFlagChildNodesKeyedByName
          );
        }
      });
    }
  };

  const dataValues = useMemo(() => getDataValues(flags), [flags]);

  const handleChange = (e, { name, checked, item }) => {
    const newFlags = { ...flags };
    const onlyRequiredSubFlagsAreChecked =
      item?.childNodes?.length !== 0 &&
      item.childNodes.every((childNode) => {
        return (
          childNode.skipRequiredCheck ||
          (!childNode.isRequired && !newFlags[childNode.name].value) ||
          (childNode.isRequired && newFlags[childNode.name].value)
        );
      });
    const checkFlag = onlyRequiredSubFlagsAreChecked ? true : checked;
    // if the item is getting checked, then check all the required flags for it.
    if (checkFlag) checkDependencies(newFlags, name);
    // item is not header or it is header but none of its subflags are required
    const isSubFlagsRequired = item?.childNodes?.some(
      (childNode) => !childNode.skipRequiredCheck && childNode.isRequired
    );
    if (!item.isHeader || (item.isHeader && !isSubFlagsRequired)) {
      newFlags[name] = { ...newFlags[name], value: checkFlag };
    }
    // checks/unchecks all children when parent is checked/unchecked
    if (item.isHeader) {
      traverseSubFlags(
        newFlags,
        name,
        checkFlag,
        keyBy(item.childNodes, 'name')
      );
    }

    // checks all the parent / parent's parent, etc. when a child is checked
    let parentFlag = item.parent;
    if (checkFlag) {
      // safe to assume that if the parent flag is checked already, then
      // all its dependency flags and parent flags are already checked.
      while (parentFlag && !newFlags[parentFlag].value) {
        newFlags[parentFlag] = {
          ...newFlags[parentFlag],
          value: true
        };
        // also make sure that the parent flag's dependencies are also checked
        checkDependencies(newFlags, parentFlag);
        parentFlag = flags[parentFlag].parent;
      }
    }
    setFlags(newFlags);
  };

  /**
   * This is a helper function to check all the required flags for a given flag name.
   */
  const checkDependencies = (newFlags, name) => {
    const itemDependencies = flags[name].dependencies;
    if (itemDependencies) {
      itemDependencies.forEach((itemDependency) => {
        // mark the dependency item and it's parent as checked
        if (!newFlags[itemDependency].value)
          newFlags[itemDependency] = { ...flags[itemDependency], value: true };
        let parentFlag = flags[itemDependency].parent;
        while (parentFlag && !newFlags[parentFlag].value) {
          newFlags[parentFlag] = { ...newFlags[parentFlag], value: true };
          checkDependencies(newFlags, parentFlag);
          parentFlag = flags[parentFlag].parent;
        }
        // set all dependency's subflags to true as well
        flags[itemDependency].subFlags?.forEach((subItem) => {
          // only if the subitem is not ignored
          if (!flags[subItem].ignoreInDependencyCheck)
            newFlags[subItem] = { ...newFlags[subItem], value: true };
        });
        checkDependencies(newFlags, itemDependency);
      });
    }
  };

  const handleSelectAll = (e) => {
    const newFlags = { ...flags };
    Object.keys(newFlags).forEach((key) => {
      if (!newFlags[key].ignoreInSelectAll)
        newFlags[key] = { ...newFlags[key], value: true };
    });
    setFlags(newFlags);
  };

  const handleClearAll = (e) => {
    const newFlags = { ...flags };
    Object.keys(newFlags).forEach((key) => {
      newFlags[key] = { ...newFlags[key], value: false };
    });
    setFlags(newFlags);
  };

  const handleDone = () => {
    if (!selectedItem) {
      setShowSelectorWarning(true);
      return;
    }
    const flagList = Object.keys(flags).filter((flag) => flags[flag].value);
    if (flagList.includes('phase_work_days'))
      handleClone(
        flagList,
        selectedItem,
        moment(cloneDate).format('MM/DD/YYYY')
      );
    else handleClone(flagList, selectedItem);
    setSubmitted(true);
  };

  const uncheckSpecificFlag = useCallback(
    (name) => {
      const newFlags = { ...flags };
      newFlags[name] = {
        ...newFlags[name],
        value: false
      };
      setFlags(newFlags);
    },
    [flags]
  );

  const openPortfolioDropdownMenu = () => setIsPortfolioDropdownOpen(true);

  const closePortfolioDowndownOpen = () => {
    setIsPortfolioDropdownOpen(false);
  };

  const handleSelectPortfolio = (selectedItem) => {
    setSelectedItem(selectedItem);
    setShowSelectorWarning(false);
    setIsPortfolioDropdownOpen(false);
  };

  const selectorLabelForDisplay = !selectedItem
    ? selectorLabel
    : selectedItem.name;

  return isSubmitted ? (
    ReactDOM.createPortal(
      <StyledConfirmModalContainer>
        <StyledConfirmModalLabel>
          Process initiated. Cloning a project may <br /> take a few minutes.
        </StyledConfirmModalLabel>
        <StyledConfirmModalButton onClick={handleCancel}>
          Okay
        </StyledConfirmModalButton>
      </StyledConfirmModalContainer>,
      tooltipPortal
    )
  ) : (
    <StyledModal isOpen toggle={handleCancel}>
      <StyledModalBody>
        <Header>
          <div>
            <Title>Clone Project</Title>
          </div>

          <ButtonDiv>
            <Button secondary onClick={handleCancel}>
              Cancel
            </Button>
            <Button isDisabled={!selectedItem} onClick={handleDone}>
              Clone
            </Button>
          </ButtonDiv>
        </Header>

        <StyledSectionHeader>CLONE TO</StyledSectionHeader>

        <StyledSelectorRowContainer>
          <StyledSelectorDropdownButton
            onClick={openPortfolioDropdownMenu}
            showSelectorWarning={showSelectorWarning}
          >
            <StyledButtonLabel data-label={selectorLabelForDisplay}>
              {selectorLabelForDisplay}
            </StyledButtonLabel>
            <QBDownArrow fill={theme.colors.colorRoyalBlue} />
          </StyledSelectorDropdownButton>
          <SelectBoardDropdownContainer
            isOpen={isPortfolioDropdownOpen}
            toggleDropdown={closePortfolioDowndownOpen}
            groupList={boards}
            changeSelectedGroup={handleSelectPortfolio}
            hideToggle
          />
        </StyledSelectorRowContainer>

        <StyledSectionHeader>ITEMS TO CLONE</StyledSectionHeader>
        <StyledSelectClearAll
          onClick={showSelectAll ? handleSelectAll : handleClearAll}
        >
          {showSelectAll ? 'Select All' : 'Clear All'}
        </StyledSelectClearAll>

        <StyledCheckBoxListContainer>
          <CheckBoxListContainer
            dataList={leftSectionDataList}
            dataValues={dataValues}
            onCheck={handleChange}
            condensed
            showPartialCheckbox
            uncheckSpecificFlag={uncheckSpecificFlag}
          />
          <CheckBoxListContainer
            dataList={rightSectionDataList}
            dataValues={dataValues}
            onCheck={handleChange}
            condensed
            showPartialCheckbox
            uncheckSpecificFlag={uncheckSpecificFlag}
          />
        </StyledCheckBoxListContainer>
      </StyledModalBody>
    </StyledModal>
  );
};

export default ProjectCloneModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 519px;
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding-bottom: 28px;
`;

const StyledCheckBoxListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${CheckBoxOptionText} {
    font-size: 13px;
    margin-left: 11px;
  }
  ${TooltipContainer} {
    position: relative;
    bottom: 9px;
    margin: 0;
  }
  ${CheckBoxListItemsContainer} {
    padding: 0;
  }
  & > ${CheckBoxListItemsContainer} {
    padding-left: 57px;
    &:last-child {
      padding-left: 9px;
    }
  }
  li ${CheckBoxListItemsContainer} {
    margin-top: -13px;
    padding-left: 26px;
  }
  li
    ${CheckBoxListItemsContainer}
    ${CheckBoxOptionsContainer}
    ${CheckBoxOptionText} {
    color: ${theme.colors.colorMediumGray9};
  }
  ${CustomCheckBox} {
    width: 10px;
    height: 10px;
    border-radius: 1px;
    margin: 1px;
  }
  ${HiddenInput} {
    width: 15px;
    height: 15px;
  }
  ${CustomCheckBoxContainer} {
    border-radius: 2px;
  }
  ${CheckBoxOptionsContainer} {
    padding-bottom: 25px;
    &.subItem {
      padding-bottom: 14px;
    }
    &.subItemWithChild {
      padding-bottom: 27px;
    }
    &.subSectionLastItem {
      padding-bottom: 20px;
    }
    &.hasCustomSubComponent {
      padding-bottom: 2px;
      ${HiddenInput} {
        margin-top: 2px;
      }
      ${CustomCheckBoxContainer} {
        margin-top: 2px;
      }
    }
  }
  ${CustomSubComponentContainer} {
    margin-top: 0px;
  }
`;

const StyledSelectClearAll = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  margin: 0px 0px 6px 20px;
  cursor: pointer;
  width: fit-content;
`;

const leftSection = {
  project_memberships: 'project_memberships',
  tasks: 'tasks',
  scope: 'scope',
  project_notes: 'project_notes'
};

const rightSection = {
  schedule: 'schedule',
  budget: 'budget',
  work_plan: 'work_plan'
};

const StyledConfirmModalContainer = styled.div`
  width: 367px;
  padding: 9px 23px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 43px;
  border: 1px solid ${theme.colors.colorRoyalBlue};
  background: ${theme.colors.colorPureWhite};
  border-radius: 3px;
  transform: translate(-50%);
  z-index: 1000;
  box-shadow: 0px 3px 5px rgba(79, 79, 79, 0.25);

  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 0.4s;

  @-webkit-keyframes example {
    from {
      top: -10px;
    }
    to {
      top: 43px;
    }
  }

  /* Standard syntax */
  @keyframes example {
    from {
      top: -10px;
    }
    to {
      top: 43px;
    }
  }
`;

const StyledConfirmModalLabel = styled.div`
  font-size: 16px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
`;

const StyledConfirmModalButton = styled.button`
  position: absolute;
  background: ${theme.colors.colorCalendarBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 3px;
  font-size: 14px;
  padding: 6px 9px 5px;
  border-width: 0px;
  right: -18%;
  top: 17px;
`;

const getDataValues = (flags) => {
  const keys = Object.keys(flags);
  const dataValues = {};
  keys.forEach((key) => (dataValues[key] = flags[key].value));
  return dataValues;
};

const StyledSectionHeader = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${theme.colors.colorMediumGray1};
  padding: 15px 0px 0px 20px;
`;

const StyledSelectorRowContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0px 0px 57px;
  .dropdown {
    left: -20px;
  }
  .board-select-dropdown {
    height: 0px;
  }
`;

const StyledSelectorDropdownButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  z-index: 1000;
  svg {
    visibility: hidden;
  }
  &:hover {
    svg {
      visibility: visible;
    }
    span {
      font-weight: 700;
    }
  }
  ${({ showSelectorWarning }) =>
    showSelectorWarning &&
    `
    ${StyledButtonLabel} {
      color: ${theme.colors.colorCalendarRed};
    }
    path {
      fill: ${theme.colors.colorCalendarRed};
    }
  `}
`;

const StyledButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.colorRoyalBlue};
  margin-right: 3px;
  &::before {
    display: block;
    content: attr(data-label);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;
