import React from 'react';
import { connect } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';
import {
  makeGetActiveWorkloadPlannerFilter,
  makeGetActiveWorkloadPlannerFilterIdHashes
} from 'selectors';
import { makeGetOrderedFilterPositions } from 'BudgetModule/selectors/positions';
import {
  InnerDropdownHeader,
  SelectorOption,
  SelectedValue,
  StyledInputContainer,
  CustomCheckBoxContainer,
  StyledInput,
  CloseIconContainer
} from './styles';

import NewCloseIcon from 'icons/NewCloseIcon';
import VirtualFilter from 'ReportsModule/components/Filter/VirtualFilter';
import { StyledFilterRow } from 'ReportsModule/components/styles';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import styled from 'styled-components';
import theme from 'theme';

const FilterStyles = styled.div`
  .filter-list-item-container,
  ${StyledFilterRow} {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${theme.colors.colorSemiDarkGray1} !important;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.colorTranslucentGray4};
      ${CustomCheckBoxContainer} {
        border-color: ${({ isDisabled }) =>
          isDisabled ? 'transparent' : theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const customItemsOverride = {
  positionList: []
};

class PositionFilter extends React.Component {
  state = {
    search: '',
    isFocused: false
  };

  componentDidUpdate(prevProps) {
    const { positions } = this.props;
    if (
      positions &&
      prevProps.positions &&
      positions.length !== prevProps.positions.length
    ) {
      this.clearMemo();
    }
  }

  searchMemo = {};
  clearMemo = () => (this.searchMemo = {});
  getItems = () => {
    const { search } = this.state;
    if (!this.searchMemo[search]) {
      this.searchMemo[search] = this.filterItems();
    }
    return this.searchMemo[search];
  };

  filterItems = () => {
    const { search } = this.state;
    const { positions } = this.props;
    const searchWords = search.split(' ').filter((str) => str !== '-');
    return positions.filter(
      (item) =>
        item.id &&
        (!search.length || this.itemFilter(item, searchWords)) &&
        this.itemIsActiveOrSelected(item)
    );
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item: item.account,
      filterKeysArray: ['name']
    });

  itemIsActiveOrSelected = (item) =>
    !item.is_archived ||
    this.props.activeFilterIdHashes?.position_ids?.[item.id];

  clearSearch = () => this.setState({ search: '' });
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSearchFocus = (isFocused) => {
    this.setState({
      ...this.state,
      isFocused: isFocused
    });
  };

  renderHeader = () => {
    const {
      filter,
      updateAccountFilterLocal,
      allSelected,
      widgetConfig,
      additionalFilterOptions = {}
    } = this.props;
    const { search, isFocused } = this.state;
    const { isSingleSelect, includeEntireOrg } = additionalFilterOptions;
    const isEntireOrgSelected = includeEntireOrg && !filter.position_ids.length;
    const positionsSelected =
      filter && (filter.position_ids.length || isEntireOrgSelected);
    const numberOfPositionsSelected = filter.position_ids.length || '';

    const filterPositionIds = filter?.position_ids ?? [];
    const bulkSelectorIds = allSelected
      ? []
      : Array.from(
          new Set([
            ...this.getItems()
              .filter(
                (position) => !position.is_default && !position.discarded_at
              )
              .map((position) => position?.id)
              .filter((id) => id),
            ...filterPositionIds
          ])
        );

    const selectionLimit = widgetConfig?.limits?.position_ids;

    return (
      <>
        <StyledInputContainer style={{ display: 'flex' }}>
          <StyledInput
            onChange={this.handleChange}
            name="search"
            value={search}
            placeholder="Search or select below"
            autoComplete="off"
            autoFocus
            onFocus={() => this.handleSearchFocus(true)}
            onBlur={() => this.handleSearchFocus(false)}
            isActive={isFocused || !!search.length}
            className="workload-filter"
          />
          {this.state.search !== '' ? (
            <CloseIconContainer
              onClick={this.clearSearch}
              className="filter-close-icon"
            >
              <NewCloseIcon />
            </CloseIconContainer>
          ) : (
            ''
          )}
        </StyledInputContainer>
        <InnerDropdownHeader style={{ padding: '6px 0px 6px 25px' }}>
          {!selectionLimit && !isSingleSelect && (
            <SelectorOption
              onClick={(e) =>
                updateAccountFilterLocal({
                  ...filter,
                  position_ids: bulkSelectorIds
                })
              }
            >
              {allSelected ? 'None \u00A0' : 'All'}
            </SelectorOption>
          )}

          {positionsSelected && !isEntireOrgSelected && (
            <SelectorOption
              onClick={(e) =>
                updateAccountFilterLocal({
                  ...filter,
                  position_ids: []
                })
              }
            >
              Clear {!isSingleSelect && 'Selected \u00A0'}
            </SelectorOption>
          )}
          {!isSingleSelect &&
            (positionsSelected ? (
              <SelectedValue isBlue={positionsSelected}>
                {numberOfPositionsSelected}
                {selectionLimit ? `/${selectionLimit}` : ''}
              </SelectedValue>
            ) : (
              <SelectedValue>0 roles selected</SelectedValue>
            ))}
          {/* To keep the height with no text when none selected for single select */}
          {isEntireOrgSelected && <div style={{ height: '18px' }} />}
        </InnerDropdownHeader>
      </>
    );
  };

  render() {
    const {
      viewType,
      viewBy,
      pageName,
      innerHeightAdjustment,
      widgetConfig,
      filter,
      additionalFilterOptions
    } = this.props;
    const { filterTab, search } = this.state;

    return (
      <>
        {this.renderHeader()}
        <FilterStyles>
          <VirtualFilter
            viewType={viewType}
            pageName={pageName}
            viewBy={viewBy}
            filterSections={['positions']}
            sectionsStartOpen
            showOnEngaged={false}
            showStickyHeader={false}
            searchOverride={{ positions: search }}
            customItems={customItemsOverride}
            leftPadding={`10px`}
            headerLeftOffset={0}
            leftOffset={'0px'}
            width={225}
            skipHeader={{ members: true }}
            innerHeightAdjustment={innerHeightAdjustment || 315}
            saveLocal
            widgetConfig={widgetConfig}
            activeFilter={filter}
            additionalFilterOptions={additionalFilterOptions}
          />
        </FilterStyles>
      </>
    );
  }
}
const makeMapStateToProps = () => {
  const getOrderedFilterPositions = makeGetOrderedFilterPositions();
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const getActiveWorkloadPlannerFilterIdHashes =
    makeGetActiveWorkloadPlannerFilterIdHashes();
  return (state, ownProps) => {
    const positions = getOrderedFilterPositions(state, ownProps);
    return {
      positions,
      filter: getActiveWorkloadPlannerFilter(state, ownProps),
      activeFilterIdHashes: getActiveWorkloadPlannerFilterIdHashes(
        state,
        ownProps
      ),
      allSelected: positions.isAllSelected
    };
  };
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(makeMapStateToProps, mapDispatchToProps)(PositionFilter);
