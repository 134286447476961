import { useCallback } from 'react';
import { ROW_TYPES } from './layout';

export const useMemberRowBuilder = ({ memberRowProps }) => {
  const memberRowBuilder = useCallback(
    ({ rowData = [] }) => {
      const plainList = rowData.map((dataItem: any) => {
        return {
          id: dataItem.id,
          rowType: ROW_TYPES.memberRow,
          rowHeight: 60,
          rowData: dataItem,
          onRemoveRow: memberRowProps?.onRemoveRow
        };
      });
      return plainList;
    },
    [memberRowProps?.onRemoveRow]
  );

  return memberRowBuilder;
};
