import { put, select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { getAuthToken } from 'selectors';

const {
  userActivitiesFetch,
  fetchUserActivitiesForActionable:
    fetchUserActivitiesForActionableEntityAction,
  fetchActivityLogUserActivities: fetchActivityLogUserActivitiesEntityAction
} = entityActions;

export function* fetchUserActivities(action) {
  const { accountId, date, projectId, phaseId } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    userActivitiesFetch,
    api.fetchUserActivities,
    undefined,
    [token, accountId, date, projectId, phaseId],
    action
  );
}
export function* fetchUserActivitiesForActionable(action) {
  const {
    actionable_id,
    actionable_type,
    project_ids,
    start_date,
    end_date,
    actor_ids,
    account_ids,
    limit
  } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    fetchUserActivitiesForActionableEntityAction,
    api.fetchUserActivitiesForActionable,
    undefined,
    [
      token,
      {
        actionable_id,
        actionable_type,
        project_ids,
        start_date,
        end_date,
        actor_ids,
        account_ids,
        limit
      }
    ],
    action
  );
}

export function* fetchActivityLogUserActivities(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    fetchActivityLogUserActivitiesEntityAction,
    api.fetchActivityLogUserActivities,
    undefined,
    [token, action.payload],
    action
  );
}
