import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { Page } from 'models/filter';
import { CurrentFilter, FilterLevelSchemas } from 'FilterModule/types';
import {
  makeDefaultMemberCrossFiltersFieldSchemas,
  MembersCrossFilterFieldSchemas
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { useFilterContext } from 'FilterModule/FilterContextProvider';

const memberAvailabilityFilterName = serializeFilterLevelName({
  level1: Page.MemberAvailabilitySplitView,
  level2: 'members'
});

type MemberAvailabilityFieldSchemas = MembersCrossFilterFieldSchemas;

const fieldSchemas: MemberAvailabilityFieldSchemas =
  makeDefaultMemberCrossFiltersFieldSchemas({
    selectionLimit: 50
  });

export const initialFilterLevelSchemas: FilterLevelSchemas = {
  [memberAvailabilityFilterName]: {
    fieldSchemas
  }
};

/* ---------------------------- CurrentFilterType --------------------------- */
export type MemberAvailabilityCurrentFilterType =
  CurrentFilter<MemberAvailabilityFieldSchemas>;
export const useMemberAvailabilityFilterContext = () =>
  useFilterContext<MemberAvailabilityCurrentFilterType>();
