import { useAppSelector } from 'reduxInfra/hooks';
import CellContainer from './CellContainer';
import TaskCircle from '../TaskCircle';
import {
  getIsOnProjectView,
  getOnMembersView,
  getIsMemberModalOpen,
  getOnHomeView
} from 'selectors';
import { useTasks } from 'appUtils/hooks/useTasks';

const CompleteCell = (props) => {
  const isOnProjectView = useAppSelector(getIsOnProjectView);
  const isMemberModalOpen = useAppSelector(getIsMemberModalOpen);
  const isOnTeamPageView = useAppSelector(getOnMembersView);
  const isOnHomeView = useAppSelector(getOnHomeView);
  const { row } = props;
  const task = row.original;
  const {
    completed_at,
    createRow,
    taskProps = {},
    customRowProps,
    list: { id: listId, createRowData, setCreating }
  } = task;
  const { taskIsEditing } = taskProps;

  const { markComplete, ConfirmStopTimerModal } = useTasks();

  const markTaskCompletion = () => {
    if (createRow) {
      return;
    }
    markComplete(task);
  };

  const onCreateRowClick = () => {
    setCreating({ id: listId, createRowData });
    row.setState({
      editingProperty: 'description',
      taskId: row.original.id || 'addTask'
    });
  };

  const renderAsTeamProject = isOnTeamPageView && !isOnHomeView;
  return (
    <CellContainer taskProps={taskProps} taskProperty="completed" row={row}>
      <TaskCircle
        markComplete={markTaskCompletion}
        isCompleted={completed_at !== null && !createRow}
        isEditing={taskIsEditing}
        onCreateRowClick={createRow ? onCreateRowClick : null}
        isOnTeamProject={renderAsTeamProject}
        isOnTeamPageView={isOnTeamPageView}
        taskCircleClass={isOnProjectView ? 'project-detail-task' : ''}
        isOnProjectView={isOnProjectView}
        key={props.task?.id}
        isCreateRow={createRow}
        rowEditing={customRowProps?.rowEditing}
        isMemberModalOpen={isMemberModalOpen}
      />
      <ConfirmStopTimerModal />
    </CellContainer>
  );
};

export default CompleteCell;
