import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportProjectPriority
} from 'CsvImportsModule/types';
interface AdditionalParams {
  existingProjectPrioritiesByTitle: Record<string, CsvImportProjectPriority>;
}

export const projectPriorityOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingProjectPrioritiesByTitle } = additionalParams;

  // always returns all the possible project priority options
  // including '' so that user can go back to empty field
  return ['', ...Object.keys(existingProjectPrioritiesByTitle)];
};
