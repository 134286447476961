import { useState, useMemo, useEffect } from 'react';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { CurrentFilter } from 'FilterModule/types';
import pick from 'lodash/pick';
import { RequireExactlyOne } from 'type-fest';

type UseCrossFieldDependenciesParams = RequireExactlyOne<
  {
    /**
     * The field that will be checked in the currentFilterSchema for crossFieldDependencies
     */
    field?: string;
    /**
     * Prevents unnecessary hook behaviour
     */
    isOff?: boolean;
    /**
     * Provided crossFieldDependencies, which is required when 'field' param is not used
     */
    crossFieldDependencies?: string[];
    shouldUseDraft?: boolean;
  },
  'field' | 'crossFieldDependencies'
>;

export const useCrossFieldDependencies = ({
  field,
  isOff,
  crossFieldDependencies,
  shouldUseDraft
}: UseCrossFieldDependenciesParams) => {
  const { currentFilterSchema, currentFilter, draftFilter } =
    useFilterContext();

  const crossFieldDependenciesToUse =
    crossFieldDependencies ??
    ((field && currentFilterSchema.fields[field]) || {}).crossFieldDependencies;

  const [dependencyFilter, setDependencyFilter] =
    useState<Partial<CurrentFilter>>();

  /**
   * currentFilter with just crossFieldDependencies fields picked
   */
  const calculatedDependencyFilter = useMemo(
    () =>
      isOff
        ? undefined
        : getCrossFieldDependencyParams({
            currentFilter,
            draftFilter,
            crossFieldDependencies: crossFieldDependenciesToUse,
            shouldUseDraft
          }),
    [
      isOff,
      currentFilter,
      draftFilter,
      crossFieldDependenciesToUse,
      shouldUseDraft
    ]
  );

  // for keeping dependencyFilter out of the useEffect dependency array
  const dependencyFilterEquality = useMemo(
    () =>
      calculatedDependencyFilter && crossFieldDependenciesToUse?.length
        ? JSON.stringify(calculatedDependencyFilter)
        : null,
    [crossFieldDependenciesToUse?.length, calculatedDependencyFilter]
  );

  useEffect(
    () => {
      setDependencyFilter(calculatedDependencyFilter);
    },
    // keep calculatedDependencyFilter out of the dependency array
    [dependencyFilterEquality]
  );

  const numCrossFieldDependencyFiltersBeingUsed = useMemo(() => {
    return isOff || !dependencyFilter
      ? 0
      : Object.values(dependencyFilter).reduce<number>((acc, val) => {
          // only handling arrays for now, and assuming empty array means not filtering
          if (Array.isArray(val) && val.length) {
            acc += 1;
          }
          return acc;
        }, 0);
  }, [dependencyFilter, isOff]);

  return {
    dependencyFilter,
    numCrossFieldDependencyFiltersBeingUsed
  };
};

/* ------------------------------------ - ----------------------------------- */

const getCrossFieldDependencyParams = ({
  currentFilter,
  draftFilter,
  crossFieldDependencies,
  shouldUseDraft
}: Pick<
  ReturnType<typeof useFilterContext>,
  'currentFilter' | 'draftFilter'
> & {
  crossFieldDependencies?: string[];
  shouldUseDraft?: boolean;
}) => {
  if (crossFieldDependencies?.length) {
    return {
      ...pick(currentFilter, crossFieldDependencies),
      ...(shouldUseDraft && { ...pick(draftFilter, crossFieldDependencies) })
    } as Partial<CurrentFilter>;
  }
};
