/**
 * @param {string} message
 * @return {Array<{match:string, key:string, placeholder:string|null}>}
 */
export const parseMessage = (message) => {
  const regex = new RegExp(/{([^}]+)}/, 'g');
  const matchedAll = [];
  let match;
  while ((match = regex.exec(message))) {
    matchedAll.push(match);
  }
  return matchedAll.map((match) => {
    const key = match[1];
    const separatorIndex = key.indexOf(':');
    const hasSeparator = separatorIndex > -1;
    return {
      match: match[0],
      key: hasSeparator ? key.substr(0, separatorIndex) : key,
      placeholder: separatorIndex > -1 ? key.substr(separatorIndex + 1) : null
    };
  });
};

/**
 * @example generateMessage('{verb:placeholder} to this project', {verb: is me ? 'joined' : 'assigned'})
 * // returns 'joined to this project' or 'assigned to this project'
 *
 * @param {string} message
 * @param {Object} handlers
 * @param {{trim:boolean}} options
 * @returns {string}
 */
export const generateMessage = (
  message,
  handlers = {},
  options = {
    trim: true
  }
) => {
  const matched = parseMessage(message);

  matched.forEach(({ match, key, placeholder }) => {
    const handled = handlers && handlers[key];
    const toReplace = typeof handled === 'string' ? handled : placeholder || '';
    message = message.replace(match, toReplace);
  });

  if (options.trim) {
    message = message.trim();
  }

  return message;
};
