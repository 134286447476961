import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import InfiniteScroll from 'react-infinite-scroll-component';
import TaskWrapper from '../Tasks/TaskWrapper';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CreateTask from './CreateTask';
import memoize from 'memoize-one';
import ContentLoader from 'react-content-loader';

import {
  getIsCreatingTask,
  getHomeTaskObj,
  getCreatedTasksTempIds,
  getSelectedHomeTask,
  getCurrentPageNumber,
  getCurrentPage,
  getTotalPages,
  getCurrentFilter,
  getBatchSelectedTaskIds,
  getBatchSelectedGroupIds,
  getNumberBatchSelectedTasks,
  getIsFetchingTasks,
  getPastScheduledTasks,
  getPastDueTasks,
  getMatchedRouteParams,
  getTaskGroups,
  getIsOnProjectView,
  getFilterAndSortStatus,
  getDateHeader,
  getIsMemberModalOpen
} from 'selectors';
import {
  triggerFetchAdditionalTasks,
  fetchProjectTaskGroups,
  fetchProjectTaskGroupTasks,
  removeTaskGroupFromFetchQueue
} from 'actionCreators';
import TaskListSectionHeader from './TaskListSectionHeader';
import theme from 'theme';
import TaskBatchActionsMenu from '../TaskBatchActions/TaskBatchActionsMenu';
import { isSameDay } from 'appUtils/momentUtils';
import TasksGroupHeader from './TasksGroupHeader';
import { Waypoint } from 'react-waypoint';
import TasksTableContainer from './TasksTableContainer';
import { TaskCircleWrapper } from 'views/Home/Tasks/styles';
import { TASK_RESCHEDULE_LABEL } from './constants';

const {
  colors: {
    colorCalendarOrange,
    colorCalendarRed,
    colorCalendarGray,
    colorSemiDarkGray1,
    colorPureWhite,
    colorMediumGray1
  }
} = theme;

const LoadingIndicatorWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderWrapper = styled.div`
  position: sticky;
  top: 0px;
  z-index: 2;
  min-width: 890px;
`;
const DroppableWrapper = styled.div`
  min-height: ${(props) => (props.isDisabled ? '' : '50px')};
  background: transparent;
`;

const GroupWrapper = styled.div`
  padding-right: 200px;
  margin-right: -200px;
  margin-bottom: 20px;
  ${(props) =>
    props.currentPage &&
    `
    ${
      props.currentPage.isAssignedByMe &&
      `
      ${TaskCircleWrapper} {
        padding-left: 10px;
      }
    `
    }
  `}
`;
const GhostText = styled.div`
  padding: 10px 0 0 55px;
  font-size: 14px;
  color: ${colorMediumGray1};
  height: 60px;
  visibility: hidden;
  ${GroupWrapper}:hover & {
    visibility: visible;
  }
`;
const noop = () => {};

const homeScheduledStyle = {
  minWidth: '850px',
  paddingRight: '200px',
  marginRight: '-200px',
  minHeight: '350px'
};

const homeScheduledFetchingStyle = {
  minWidth: '850px',
  marginRight: '-200px',
  minHeight: '350px',
  paddingRight: '200px'
};

const noStyle = {
  paddingRight: '200px',
  marginRight: '-200px',
  minHeight: '350px'
};

const InfiniteScrollDroppableContainer = styled.div`
  padding-right: 1rem;
`;

class InfiniteScroller extends PureComponent {
  state = {
    isCreatingTask: false
  };

  componentDidMount() {
    const {
      matchedParams: { projectId, memberId },
      fetchProjectTaskGroups,
      isOnProjectView,
      isMemberModalOpen
    } = this.props;
    if (isOnProjectView && projectId) {
      fetchProjectTaskGroups({ projectId });
      this.customFilterMemo[projectId] = { project_ids: [projectId] };
    } else if (isMemberModalOpen && memberId) {
      this.customFilterMemo[memberId] = {
        project_ids: [],
        assignee_ids: [memberId]
      };
    }
  }

  customFilterMemo = {};

  componentDidUpdate(prevProps) {
    const {
      matchedParams: { projectId, memberId },
      fetchProjectTaskGroups,
      isOnProjectView,
      isMemberModalOpen
    } = this.props;
    const {
      matchedParams: { projectId: prevProjectId, memberId: prevMemberId }
    } = prevProps;
    if (isOnProjectView && projectId !== prevProjectId) {
      this.customFilterMemo[projectId] = { project_ids: [projectId] };
      fetchProjectTaskGroups({ projectId });
    } else if (isMemberModalOpen && memberId !== prevMemberId) {
      this.customFilterMemo[memberId] = {
        project_ids: [],
        assignee_ids: [memberId]
      };
    }
  }

  listHasRemainingPages = memoize(
    (currentPageNumber, totalPages) => currentPageNumber < totalPages
  );

  getDueAtTasks = (due) => {
    const { taskHash } = this.props;
    return Object.values(taskHash).filter(
      (task) => !task.completed_at && task.due_at && isSameDay(task.due_at, due)
    );
  };

  toggleCreateTask = () => {
    this.setState((prevState) => ({
      isCreatingTask: !prevState.isCreatingTask
    }));
  };

  pageCalc = (currentPageNumber, totalPages) => currentPageNumber < totalPages;

  handleInfiniteLoad = (group = {}) => {
    const {
      isFetchingTasks,
      isLazyLoading,
      fetchAdditionalTasks,
      matchedParams: { projectId },
      selectedAccountIds: accountIds,
      fetchProjectTaskGroupTasks,
      isOnProjectView
    } = this.props;
    const { id, isFetching, current_page, total_pages } = group;
    if (
      (!isOnProjectView && isFetchingTasks) ||
      isLazyLoading ||
      isFetching ||
      current_page >= total_pages // greater than can happen when there are no tasks, current page is 1 and total is 0
    )
      return;
    if (isOnProjectView)
      fetchProjectTaskGroupTasks({ projectId, accountIds, groupId: id });
    else
      fetchAdditionalTasks({
        accountIds
      });
  };

  shouldRenderCreateTask(currentFilter, group) {
    const { currentPage } = this.props;
    return (
      group.title !== TASK_RESCHEDULE_LABEL &&
      (currentPage.isOnProfile ||
        currentPage.isTodayOrInbox ||
        (!currentPage.isProjectCompleted && currentFilter.scope === 'project'))
    );
  }

  toggleCreateTask = () => {
    this.setState((prevState) => ({
      isCreatingTask: !prevState.isCreatingTask
    }));
  };

  renderEndMessage = () => {
    return null;
  };

  getSectionTitleColor = (group) => {
    return group.title === 'Today'
      ? colorCalendarOrange
      : group.title === TASK_RESCHEDULE_LABEL
      ? colorCalendarRed
      : colorSemiDarkGray1;
  };

  getSectionTasksCircleBackgroundColor = (group) => {
    return group.title === 'Today'
      ? colorCalendarOrange
      : group.title === TASK_RESCHEDULE_LABEL
      ? colorCalendarRed
      : 'transparent';
  };

  getSectionTasksCircleBorderColor = (group) => {
    return group.title === 'Today'
      ? 'transparent'
      : group.title === TASK_RESCHEDULE_LABEL
      ? 'transparent'
      : colorCalendarGray;
  };

  getSectionTasksCircleColor = (group) => {
    return group.title === 'Today'
      ? colorPureWhite
      : group.title === TASK_RESCHEDULE_LABEL
      ? colorPureWhite
      : colorCalendarGray;
  };

  shouldRenderRescheduleGhost = (isFetchingTasks, group) => {
    return (
      !isFetchingTasks &&
      group.title === TASK_RESCHEDULE_LABEL &&
      (!group.task_order || !group.task_order.length)
    );
  };

  trackTaskClickUsage = (e) => {
    const { currentPage } = this.props;
    // mixpanelTrack(Mixpanel.TASK_EDIT, {
    //   ...currentPage
    // });
    e.preventDefault();
  };

  onWaypointEnter = (group) => () => {
    this.handleInfiniteLoad(group);
  };

  onWaypointLeave = (group) => () => {
    const { removeTaskGroupFromFetchQueue } = this.props;
    removeTaskGroupFromFetchQueue({ groupId: group.id });
  };

  render() {
    const {
      currentPageNumber,
      totalPages,
      currentFilter,
      currentPage,
      isCreatingTask,
      shouldRenderCreateTaskButton,
      taskIds,
      isOnHomeView,
      alwaysRenderDue,
      scheduledGroups,
      plannerGroups,
      isOnTeamProject,
      selectedHomeTask,
      isFetchingTasks,
      batchSelectedTaskIds,
      pastScheduledTasks,
      pastDueTasks,
      dragType,
      matchedParams: { projectId, memberId },
      taskGroups,
      isOnProjectView,
      isOnPersonalProject,
      filterAndSortStatus,
      collapsedForDrag,
      taskHash,
      taskToTempIds,
      taskRemovals,
      batchSelectedGroupIds,
      numberBatchSelectedTasks,
      viewBy,
      isDayPlannerView,
      dateHeader,
      handleAdditionalDragTypes,
      isMemberModalOpen
    } = this.props;

    const hasMoreTasks = isOnProjectView
      ? taskGroups.some(
          (group) =>
            group && group.current_page < group.total_pages && !group.collapsed
        )
      : this.listHasRemainingPages(currentPageNumber, totalPages);
    const defaultTaskGroups = [
      {
        dateHeader: 'all-dates',
        title: 'my-tasks',
        task_order: taskIds
      }
    ];
    const showLoader =
      (currentFilter.subSection === 'inbox' || currentPage.isHomeCompleted) &&
      !isOnProjectView &&
      !currentPage.isOnProfile &&
      isFetchingTasks;

    const taskGroupsToMap = isDayPlannerView
      ? plannerGroups
      : currentPage.isToday && !currentPage.isOnProfile
      ? scheduledGroups || defaultTaskGroups
      : projectId && isOnProjectView
      ? taskGroups
      : defaultTaskGroups;

    const tasksLength =
      (currentPage.isToday ? taskGroupsToMap.length * 2 : 0) +
      taskGroupsToMap.reduce(
        (accumulator, group) =>
          accumulator +
          (group && group.task_order ? group.task_order.length : 0),
        0
      );

    if (isOnProjectView || isMemberModalOpen) {
      const customFilter =
        isOnProjectView && projectId
          ? this.customFilterMemo[projectId]
          : isMemberModalOpen && memberId
          ? this.customFilterMemo[memberId]
          : undefined;

      return (
        <>
          <TasksTableContainer
            customFilter={customFilter}
            viewBy={viewBy}
            handleAdditionalDragTypes={handleAdditionalDragTypes}
            mergeCustomFilter
          />
          <TaskBatchActionsMenu
            key={`batchActionMenu`}
            selectedBatchTaskIds={Object.keys(batchSelectedTaskIds).map(
              (id) => (Number.isInteger(+id) ? +id : id) // coerce non tempIds to numbers
            )}
            selectedBatchGroupIds={Object.keys(batchSelectedGroupIds).map(
              (id) => (Number.isInteger(+id) ? +id : id) // coerce non tempIds to numbers}
            )}
            numTasksSelected={numberBatchSelectedTasks}
            isOnCompletedView={currentPage.isHomeCompleted}
            isOnScheduledView={currentPage.isToday && !currentPage.isOnProfile}
            isOnPersonalProject={isOnPersonalProject}
            isOnHomeView={isOnHomeView}
            isVirtualList
          />
        </>
      );
    }
    return (
      <InfiniteScroll
        height={
          '98%'
        } /* necessary due to filters on front end causing the list to believe it doesn't need to load more data */
        dataLength={tasksLength} // used in deciding whether to rerender or not
        next={this.handleInfiniteLoad}
        scrollThreshold={'250px'}
        hasMore={hasMoreTasks}
        style={
          (currentPage.isTodayOrInbox || currentPage.isHomeCompleted) &&
          !currentPage.isOnProfile
            ? isFetchingTasks
              ? homeScheduledFetchingStyle
              : homeScheduledStyle
            : noStyle
        }
        loader={
          <LoadingIndicatorWrapper key={0}>
            <Spinner fadeIn="none" name="ball-beat" />
          </LoadingIndicatorWrapper>
        }
        endMessage={this.renderEndMessage()}
      >
        {showLoader && !tasksLength && (
          <div style={{ width: '100%' }}>
            <ContentLoader
              height="100"
              primaryColor="#f3f3f3"
              secondaryColor="#fff"
              style={{ width: '860px', margin: '0 20px' }}
            >
              <rect x="0" y="5" rx="2" ry="2" width="100%" height="20" />
              <rect x="0" y="30" rx="2" ry="2" width="100%" height="20" />
              <rect x="0" y="55" rx="2" ry="2" width="100%" height="20" />
              <rect x="0" y="80" rx="2" ry="2" width="100%" height="20" />
            </ContentLoader>
          </div>
        )}
        <TaskBatchActionsMenu
          key={`batchActionMenu`}
          selectedBatchTaskIds={Object.keys(batchSelectedTaskIds).map(
            (id) => (Number.isInteger(+id) ? +id : id) // coerce non tempIds to numbers
          )}
          isOnCompletedView={currentPage.isHomeCompleted}
          isOnScheduledView={currentPage.isToday && !currentPage.isOnProfile}
          isOnHomeView={isOnHomeView}
        />
        <Droppable
          droppableId={isOnProjectView ? 'task-groups' : 'undroppable'}
          isDropDisabled={!isOnProjectView}
          type={isOnProjectView ? 'task-groups' : 'undroppable'}
        >
          {(provided, snapshot) => {
            return (
              <InfiniteScrollDroppableContainer ref={provided.innerRef}>
                {taskGroupsToMap.map((group, groupIndex) => {
                  if (!group) {
                    return null;
                  }
                  return (
                    <Draggable
                      key={`parent-div-${group.id ? group.id : group.title}`}
                      draggableId={`task-group--${group.id}`}
                      index={groupIndex}
                      isDragDisabled={
                        !isOnProjectView || taskGroupsToMap.length < 2
                      }
                    >
                      {(provided, snapshot) => {
                        return (
                          <GroupWrapper
                            key={`parent-div-${
                              group.id ? group.id : group.title
                            }`}
                            className="app-cues-tasks-group-wrapper"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            currentPage={currentPage}
                          >
                            {currentPage.isToday &&
                              !currentPage.isOnProfile &&
                              taskGroupsToMap.length &&
                              !!group.title && (
                                <HeaderWrapper>
                                  <TaskListSectionHeader
                                    listTitle={
                                      group.title === 'my-tasks'
                                        ? ''
                                        : group.title
                                    }
                                    listSubTitle={
                                      group.title === 'my-tasks'
                                        ? ''
                                        : group.dateHeader
                                    }
                                    titleColor={this.getSectionTitleColor(
                                      group
                                    )}
                                    isOnTeamProject={isOnTeamProject}
                                    isOnHomeView={isOnHomeView}
                                    isOnInboxView={currentPage.isInbox}
                                    isOnTodayView={
                                      currentPage.isToday &&
                                      !currentPage.isOnProfile
                                    }
                                    isOnCompletedView={
                                      currentPage.isHomeCompleted
                                    }
                                    tasksDue={this.getDueAtTasks(
                                      group.scheduleStart || ''
                                    )}
                                    pastDueTasks={pastDueTasks}
                                    pastScheduledTasks={pastScheduledTasks}
                                    shouldRenderGhostFlags={groupIndex === 0}
                                    taskIds={group.task_order}
                                    taskCount={
                                      group.task_order &&
                                      group.task_order.length
                                    }
                                    taskCountColor={this.getSectionTasksCircleColor(
                                      group
                                    )}
                                    taskCountBorderColor={this.getSectionTasksCircleBorderColor(
                                      group
                                    )}
                                    taskCountBackgroundColor={this.getSectionTasksCircleBackgroundColor(
                                      group
                                    )}
                                    marginTop={groupIndex > 0 ? '34px' : 0}
                                  />
                                </HeaderWrapper>
                              )}
                            {
                              <TasksGroupHeader
                                index={groupIndex}
                                projectId={projectId}
                                group={group}
                                id={group.id}
                                originType={'taskGroup'} // necessary to determine color picker open/close state
                                pickerLocation={'flyout-menu'}
                                isOnHomeView={isOnHomeView}
                                isOnProjectView={isOnProjectView}
                                taskIds={group.task_order}
                                provided={provided}
                                snapshot={snapshot}
                                collapsedForDrag={collapsedForDrag}
                                isOnlyTaskGroup={taskGroupsToMap.length < 2}
                              >
                                <>
                                  {this.shouldRenderCreateTask(
                                    currentFilter,
                                    group
                                  ) && (
                                    <CreateTask
                                      toggleTaskCreationHeader={
                                        this.toggleCreateTask
                                      }
                                      isCreatingNewTask={isCreatingTask}
                                      shouldRenderCreateTaskButton={
                                        shouldRenderCreateTaskButton &&
                                        !(showLoader && !tasksLength)
                                      }
                                      shouldRenderGhostText={true}
                                      shouldRenderFilters={false}
                                      resetSelectedTasks={noop}
                                      position={0}
                                      isOnHomeView={isOnHomeView}
                                      scheduleStart={
                                        group.scheduleStart || null
                                      }
                                      noBorder={
                                        !group.task_order ||
                                        !group.task_order.length
                                      }
                                      groupId={group.id}
                                    />
                                  )}
                                  {/* {isFetchingTasks &&
                           (!group.task_order || !group.task_order.length) ? (
                             <div
                               style={{
                                 width: '100%',
                                 height: '100px',
                                 position: 'relative',
                                 top: '15px',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center'
                               }}
                             >
                               <LoadingWheel />
                             </div>
                           ) : null} */}

                                  <Droppable
                                    key={`droppable--${
                                      group.name ? group.name : group.title
                                    }`}
                                    droppableId={`${
                                      group.scheduleStart
                                        ? group.scheduleStart
                                        : group.id
                                        ? group.id
                                        : 'my-tasks'
                                    }`}
                                    isDropDisabled={
                                      group.title === TASK_RESCHEDULE_LABEL
                                    }
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        key={`key-${
                                          group.name
                                            ? group.name
                                            : group.title + group.title
                                        }`}
                                        ref={provided.innerRef}
                                      >
                                        <DroppableWrapper
                                          isDisabled={
                                            group.title ===
                                            TASK_RESCHEDULE_LABEL
                                          }
                                          className="app-cues-task-container"
                                        >
                                          {this.shouldRenderRescheduleGhost(
                                            isFetchingTasks,
                                            group
                                          ) && (
                                            <GhostText>
                                              No tasks to replan.
                                            </GhostText>
                                          )}
                                          {group.task_order.map((id, index) => {
                                            const task = taskHash[id];
                                            const tempId =
                                              taskToTempIds[task?.id];
                                            const idToUse =
                                              tempId || task?.id || id;
                                            return (
                                              <Draggable
                                                key={`${idToUse}${
                                                  taskRemovals[id] ? index : ''
                                                }`}
                                                draggableId={`${idToUse}${
                                                  taskRemovals[id] ? index : ''
                                                }`}
                                                index={index}
                                                isDragDisabled={
                                                  dragType ===
                                                    'task-statuses' ||
                                                  id === selectedHomeTask ||
                                                  !!taskRemovals[id]
                                                }
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    className="task-wrapper-draggable-container app-cues-tasks"
                                                    key={`taskdiv-${idToUse}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      ...provided.draggableProps
                                                        .style
                                                    }}
                                                    onClick={
                                                      this.trackTaskClickUsage
                                                    }
                                                  >
                                                    <TaskWrapper
                                                      key={`taskListItem-${idToUse}${
                                                        taskRemovals[id]
                                                          ? index
                                                          : ''
                                                      }`}
                                                      title={group.title}
                                                      taskIds={group.task_order}
                                                      taskId={id}
                                                      scrollThreshold="200px"
                                                      isCreatingTask={
                                                        isCreatingTask
                                                      }
                                                      isOnHomeView={
                                                        isOnHomeView
                                                      }
                                                      alwaysRenderSchedule={
                                                        group.title ===
                                                          'Upcoming' ||
                                                        group.title ===
                                                          TASK_RESCHEDULE_LABEL
                                                      }
                                                      alwaysRenderDue={
                                                        alwaysRenderDue
                                                      }
                                                      isDragging={
                                                        snapshot.isDragging
                                                      }
                                                      isLastTask={
                                                        group.task_order
                                                          .length ===
                                                        index + 1
                                                      }
                                                      rowNumber={index + 1}
                                                    />
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          })}
                                          {provided.placeholder}
                                        </DroppableWrapper>
                                      </div>
                                    )}
                                  </Droppable>
                                </>
                                <Waypoint
                                  /* we had to add a key to remount whenever the filter/sort status changes because
                                   * if the waypoint was on the screen, despite the waypoint documentation stating that it will call
                                   * onEnter, it does not - unless we re-mount it https://github.com/civiccc/react-waypoint#usage
                                   */
                                  key={`${filterAndSortStatus}` + `${group.id}`}
                                  onEnter={this.onWaypointEnter(group)}
                                  onLeave={this.onWaypointLeave(group)}
                                />
                              </TasksGroupHeader>
                            }
                          </GroupWrapper>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </InfiniteScrollDroppableContainer>
            );
          }}
        </Droppable>
      </InfiniteScroll>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, ownProps) => ({
    currentPageNumber: getCurrentPageNumber(state),
    currentPage: getCurrentPage(state),
    totalPages: getTotalPages(state),
    currentFilter: getCurrentFilter(state),
    selectedAccountIds: state.homeTasks.selectedAccountIds,
    isCreatingTask: getIsCreatingTask(state),
    taskHash: getHomeTaskObj(state),
    taskToTempIds: getCreatedTasksTempIds(state),
    selectedHomeTask: getSelectedHomeTask(state),
    batchSelectedTaskIds: getBatchSelectedTaskIds(state),
    batchSelectedGroupIds: getBatchSelectedGroupIds(state),
    numberBatchSelectedTasks: getNumberBatchSelectedTasks(state),
    pastDueTasks: getPastDueTasks(state),
    isFetchingTasks: getIsFetchingTasks(state),
    pastScheduledTasks: getPastScheduledTasks(state),
    matchedParams: getMatchedRouteParams(state),
    taskGroups: getTaskGroups(state),
    isOnProjectView: getIsOnProjectView(state, ownProps),
    filterAndSortStatus: getFilterAndSortStatus(state),
    taskRemovals: state.homeTasks.taskRemovals,
    viewBy: state.homeTasks.viewBy,
    dateHeader: getDateHeader(state),
    isMemberModalOpen: getIsMemberModalOpen(state)
  });
};

const mapDispatchToProps = {
  fetchAdditionalTasks: triggerFetchAdditionalTasks,
  fetchProjectTaskGroups,
  fetchProjectTaskGroupTasks,
  removeTaskGroupFromFetchQueue
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(InfiniteScroller)
);
