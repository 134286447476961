import {
  makeVerification,
  makeAuthorBasedVerification
} from './makeVerification';

export const verifyCreate = makeAuthorBasedVerification(
  'create_activity_phase_schedule_bars',
  'create_activity_phase_schedule_bars_other'
);

export const verifyReadOthers = makeVerification(
  'read_activity_phase_schedule_bars_other'
);
export const verifyRead = makeVerification('read_activity_phase_schedule_bars');
export const verifyReadMineOrOthers = makeAuthorBasedVerification(
  'read_activity_phase_schedule_bars',
  'read_activity_phase_schedule_bars_other'
);

export const verifyEdit = makeAuthorBasedVerification(
  'edit_activity_phase_schedule_bars',
  'edit_activity_phase_schedule_bars_other'
);

export const verifyDelete = makeVerification(
  'delete_activity_phase_schedule_bars'
);
