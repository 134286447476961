import {
  CSSProperties,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  Ref,
  useImperativeHandle,
  useReducer,
  forwardRef,
  ForwardedRef
} from 'react';
import {
  areEqual,
  FixedSizeList,
  ListOnItemsRenderedProps,
  VariableSizeList
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import cn from 'classnames';
import DevProps from 'components/dev/DevProps';

/**
 * A positive number.
 */
export type Positive<T extends number> = T extends 0
  ? never
  : `${T}` extends `-${string}`
  ? never
  : T;

export interface StaticListRef {
  /**
   * Reset the height of the list items after a given index.
   */
  resetHeightsAfterIndex: (index?: number) => void;
}

export interface LazyListRef extends StaticListRef {
  /**
   * Reset the cache of loaded items. Accepts a flag indicating whether the
   * rows should be immediately reloaded or to wait for a scroll event to
   * occur; defaults to `true`.
   */
  resetLoadedItemsCache: (autoReload?: boolean) => void;
}

interface StaticListProps<T, S extends number = number> {
  /**
   * The class for the `autoSizer` element.
   */
  autoSizerClassName?: string;

  /**
   * Estimated height of the items. This allows an approximate scrollbar to be
   * displayed. If this is `undefined` and `itemHeight` is a number, that value
   * will be used instead. Defaults to 50 otherwise.
   *
   * The value must be greater than 0. A value of 0 will collapse all items
   * with unknown size, potentially causing the entire list to be rendered at
   * once.
   */
  estimatedItemSize?: Positive<S>;

  /**
   * Used to determine the React list `key` value of a list item.
   * - If a string is provided, the value of the corresponding attribute of
   *   the item object will be used.
   * - If a function is provided, it is invoked with the item's data and must
   *   return a unique string.
   */
  getItemId: ((item: T, index: number) => string) | string;

  /**
   * Height of the list.
   *
   * By default, the component will greedily fill the available space. When
   * using directly within a vertical flex container, nest in a block element
   * with a defined height to prevent unbounded growth.
   */
  height?: number;

  /**
   * Scroll offset for initial render. Defaults to 0.
   */
  initialScrollOffset?: number;

  /**
   * The number of items in the list. Defaults to the length of `items`.
   */
  itemCount?: number;

  /**
   * Used to determine the height of a list item.
   * - If a number is provided, all items must have that height.
   * - If a function is provided, it is invoked with the item's data and must
   *   return the item's height.
   * - If nothing is provided, the height is calculated once the component is
   *   rendered. This is the least efficient method and should be avoided if
   *   the items have known heights because the virtualization component must
   *   render the component, get the height, recompute the position of the
   *   elements, and render the components a second time.
   */
  itemHeight?: ((item: T, index: number) => number | undefined) | number;

  /**
   * An ordered list of the items to be rendered.
   */
  items: Array<T>;

  /**
   * The class for the list element.
   */
  listClassName?: string;

  /**
   * A reference to an object containing functions to manipulate the inner
   * components.
   */
  ref?: ForwardedRef<StaticListRef>;

  /**
   * Callback invoked to render a list item.
   */
  renderItem: (item: T, index: number) => ReactNode;

  /**
   * Width of the list.
   *
   * If `undefined`, the component will greedily fill the available space. When
   * using directly within a horizontal flex container, nest in a block element
   * with a defined width to prevent unbounded growth.
   */
  width?: number;
}

interface LazyListProps<T> extends StaticListProps<T> {
  /**
   * Callback invoked to determines if a list item is loaded. If an item is
   * loading, the function should return `true`; otherwise, `loadMoreItems`
   * will ask that it be loaded again.
   */
  isItemLoaded: (item: T, index: number) => boolean;

  /**
   * Callback invoked when more rows must be loaded. It must return a Promise
   * that is resolved once all data has finished loading. The items requested
   * will always be in a contiguous range.
   */
  loadMoreItems: (
    items: Array<T>,
    startIndex: number,
    endIndex: number
  ) => Promise<void>;

  /**
   * Minimum number of rows to be requested by `loadMoreItems` at a time if
   * there are a sufficient number of unloaded items extending continuously
   * from the current view. Defaults to 10.
   */
  minimumBatchSize?: number;

  /**
   * A reference to an object containing functions to manipulate the inner
   * components.
   */
  ref?: ForwardedRef<LazyListRef>;

  /**
   * More items will be requested when the user is within this many items of
   * the loaded limits. Defaults to 15.
   */
  threshold?: number;
}

type ListProps<T> = StaticListProps<T> | LazyListProps<T>;

/**
 * Determines if the properties of `List` are those for a lazily-loaded list.
 */
const isLazyList = <T,>(value: ListProps<T>): value is LazyListProps<T> =>
  'loadMoreItems' in value;

/**
 * A virtualized list. It will become lazily loaded if the appropriate
 * properties are provided.
 */
export const ListInner = <T, P extends ListProps<T> = ListProps<T>>(
  props: P,
  ref: ForwardedRef<P extends LazyListProps<T> ? LazyListRef : StaticListRef>
) => {
  const {
    autoSizerClassName,
    estimatedItemSize = 50,
    height,
    initialScrollOffset,
    itemCount: providedItemCount,
    itemHeight,
    getItemId,
    items,
    listClassName,
    renderItem,
    width
  } = props;

  const infiniteLoaderRef = useRef<Nullable<InfiniteLoader>>(null);
  const variableSizeListRef = useRef<Nullable<VariableSizeList>>(null);

  useImperativeHandle(ref, () => ({
    // Allows resetting the height of the items after a given index if they
    // have changed heights.
    resetHeightsAfterIndex: (index = 0) => {
      variableSizeListRef.current?.resetAfterIndex(index);
    },

    // Allows resetting the cache of the lazy loader if the list has changed.
    resetLoadedItemsCache: (autoReload = true) => {
      infiniteLoaderRef.current?.resetloadMoreItemsCache(autoReload);
    }
  }));

  // Use the provided item count; otherwise, use the number of items in the
  // provided list.
  const itemCount = providedItemCount ?? items.length;

  // Conditionally selects the correct method to determine item IDs.
  const itemIdHandlerHelper = useMemo(
    () =>
      typeof getItemId === 'string'
        ? (index: number, items: ListData<T>['items']): string => {
            const id = items[index]?.[getItemId];
            return id ? String(id) : index.toString();
          }
        : (index: number, items: ListData<T>['items']): string => {
            const item = items[index];
            return item ? getItemId(item, index) : index.toString();
          },
    [getItemId]
  );
  const itemIdHandler = useCallback(
    (index: number, data: ListData<T>) =>
      itemIdHandlerHelper(index, data.items),
    [itemIdHandlerHelper]
  );

  // Each item must compute its own size in case its height is dynamic. If an
  // item has unknown size, it must return `undefined`. Keep track whether each
  // size value has been used to avoid recomputing the position of following
  // items if possible.
  //
  // A reference is used instead of local state for `sizeMap` because we do not
  // want to rerender the components every time a value changes. A render is
  // triggered by calling the `forceUpdate` reducer when an item's computed
  // height has been previously used and its computed height changes.
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const sizeMap = useRef<Record<string, { size?: number; isUsed?: boolean }>>(
    {}
  );
  const getSize = useCallback(
    (index: number): number | undefined => {
      const id = itemIdHandlerHelper(index, items);
      const sizeInfo = (sizeMap.current[id] = sizeMap.current[id] || {});
      sizeInfo.isUsed = true;
      return sizeInfo.size;
    },
    [itemIdHandlerHelper, items]
  );
  const isDynamicallySized = useCallback(
    (index: number): boolean => {
      const id = itemIdHandlerHelper(index, items);
      return Boolean(sizeMap.current[id]?.isUsed);
    },
    [itemIdHandlerHelper, items]
  );

  // Conditionally select the correct method to determine item height.
  const itemHeightHandler = useMemo(
    () =>
      // If a list-wide height is not provided, either
      // - use the computed height of the item, or
      // - use the estimated height of items.
      itemHeight === undefined
        ? (index: number): number => getSize(index) ?? estimatedItemSize
        : // If a list-wide number is provided, use the list-wide height.
        typeof itemHeight === 'number'
        ? itemHeight
        : // If a list-wide function is provided, either
          // - use the list-wide function,
          // - use the computed height of the item, or
          // - use 1 to prevent infinitely many items from rendering and also giving the element a minimum height, but also allow `getSize` the estimated height of items.
          (index: number): number => {
            const item = items[index];
            return (
              (item && itemHeight(item, index)) ??
              getSize(index) ??
              estimatedItemSize
            );
          },
    [estimatedItemSize, getSize, itemHeight, items]
  );

  const setSize = useMemo(
    () =>
      typeof itemHeightHandler === 'number'
        ? undefined
        : (index: number, size: number): void => {
            const id = itemIdHandlerHelper(index, items);
            const sizeInfo = (sizeMap.current[id] = sizeMap.current[id] || {});
            const mustReflow = sizeInfo.isUsed && sizeInfo.size !== size;

            sizeInfo.size = size;

            // If the size was previously used and the new size is different, tell
            // `VariableSizeList` to clear the heights following that index is has
            // cached and to wait for the states to update before recomputing the
            // heights.
            if (mustReflow) {
              variableSizeListRef.current?.resetAfterIndex(index, false);
              forceUpdate();
            }
          },
    [itemHeightHandler, itemIdHandlerHelper, items]
  );

  interface makeListArgs {
    height: number;
    onItemsRendered?: ((props: ListOnItemsRenderedProps) => void) | undefined;
    ref?: Ref<unknown>;
    width: number;
  }

  /**
   * Creates the list base.
   */
  const makeList = ({ height, onItemsRendered, ref, width }: makeListArgs) =>
    typeof itemHeightHandler === 'number' ? (
      <FixedSizeList
        className={listClassName}
        height={height}
        initialScrollOffset={initialScrollOffset}
        itemCount={itemCount}
        itemData={{ isDynamicallySized, items, renderItem, setSize }}
        itemKey={itemIdHandler}
        itemSize={itemHeightHandler}
        onItemsRendered={onItemsRendered}
        ref={(node: FixedSizeList<ListData<unknown>>): void => {
          ref && typeof ref === 'function' && ref(node);
        }}
        width={width}
      >
        {Item}
      </FixedSizeList>
    ) : (
      <VariableSizeList
        className={listClassName}
        estimatedItemSize={
          estimatedItemSize ??
          (typeof itemHeight === 'number' ? itemHeight : undefined)
        }
        height={height}
        initialScrollOffset={initialScrollOffset}
        itemCount={itemCount}
        itemData={{ isDynamicallySized, items, renderItem, setSize }}
        itemKey={itemIdHandler}
        itemSize={itemHeightHandler}
        onItemsRendered={onItemsRendered}
        ref={(node: VariableSizeList<ListData<unknown>>): void => {
          variableSizeListRef.current = node;
          ref && typeof ref === 'function' && ref(node);
        }}
        width={width}
      >
        {Item}
      </VariableSizeList>
    );

  /**
   * Creates a lazily-loaded list if the lazy loading properties are present.
   */
  const makeLazilyLoadedList = (
    args: Pick<makeListArgs, 'height' | 'width'>
  ) => {
    if (isLazyList(props)) {
      const { isItemLoaded, loadMoreItems, minimumBatchSize, threshold } =
        props;

      /**
       * Determines whether an item at a given index is loaded or not.
       */
      const isItemLoadedHandler = (index: number): boolean => {
        const item = items[index];
        return item ? isItemLoaded(item, index) : false;
      };

      /**
       * Invokes the callback for loading more items.
       */
      const loadMoreItemsHandler = async (
        startIndex: number,
        stopIndex: number
      ): Promise<void> =>
        loadMoreItems(
          items.slice(startIndex, stopIndex + 1),
          startIndex,
          stopIndex + 1
        );

      return (
        <InfiniteLoader
          isItemLoaded={isItemLoadedHandler}
          itemCount={itemCount}
          loadMoreItems={loadMoreItemsHandler}
          minimumBatchSize={minimumBatchSize}
          ref={infiniteLoaderRef}
          threshold={threshold}
        >
          {({ onItemsRendered, ref }) =>
            makeList({ ...args, onItemsRendered, ref })
          }
        </InfiniteLoader>
      );
    } else {
      return makeList(args);
    }
  };

  const definedHeight = height !== undefined;
  const definedWidth = width !== undefined;

  // Create a fixed-size list if the height and width are defined; otherwise,
  // create a variable sized list. Note that the nesting order of the
  // components is important:
  // `AutoSizer` -> `InfiniteLoader` -> `VariableSizeList`
  return definedHeight && definedWidth ? (
    makeLazilyLoadedList({ height, width })
  ) : (
    <AutoSizer
      className={autoSizerClassName}
      defaultHeight={height ?? 100}
      defaultWidth={width ?? 100}
      disableHeight={definedHeight}
      disableWidth={definedWidth}
    >
      {({ height: autoHeight, width: autoWidth }) =>
        makeLazilyLoadedList({
          height: autoHeight ?? height,
          width: autoWidth ?? width
        })
      }
    </AutoSizer>
  );
};

// Redeclare the `forwardRef` types to allow the generics required for `List`.
declare module 'react' {
  function forwardRef<T, P>(
    render: (props: P, ref: Ref<T>) => ReactElement | null
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

/**
 * A virtualized list. It will become lazily loaded if the appropriate
 * properties are provided.
 */
export const List = forwardRef(ListInner);

interface ListData<T> extends Pick<StaticListProps<T>, 'renderItem'> {
  isDynamicallySized: (index: number) => boolean;

  /**
   * List of items with path information.
   */
  items: Array<T>;

  /**
   * Callback invoked once the size of an item is known.
   */
  setSize?: (index: number, size: number) => void;
}

interface ItemProps<T> {
  /**
   * Data provided to `VariableSizeList` via `itemData`.
   */
  data: ListData<T>;

  /**
   * Index of the item to be rendered.
   */
  index: number;

  /**
   * Additional styling information required for positioning the component.
   */
  style: CSSProperties;
}

/**
 * Renders and computes the height of an item.
 */
const NonMemoItem = <
  T extends {
    containerClassName?: string;
    onMouseEnterContainer?: () => void;
    onMouseLeaveContainer?: () => void;
  }
>(
  props: ItemProps<T>
) => {
  const { data, index, style } = props;
  const { isDynamicallySized, items, renderItem, setSize } = data;
  const item = items[index];
  const isStaticallySized = useMemo(
    () => !isDynamicallySized(index),
    [index, isDynamicallySized]
  );

  // Get informed of any changes to the size of the element by invoking the
  // sizing callback.
  const sizeObserverRef = useRef(
    new ResizeObserver((entries) => {
      const latestSizes = entries[0];

      // Only invoke the sizing callback if the element is connected to the
      // DOM.
      if (setSize && latestSizes && latestSizes.target.isConnected)
        return setSize(
          index,
          latestSizes.borderBoxSize.reduce(
            (max, box) => Math.max(max, box.blockSize),
            0
          )
        );
    })
  );

  // Keep track of the required references for sizing.
  const measuringRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sizeObserver = sizeObserverRef.current;
    const measuringElement = measuringRef.current;

    if (setSize && measuringElement) {
      // Perform an initial measurement.
      setSize(index, measuringElement.offsetHeight);

      // Attach the size observer.
      sizeObserverRef.current.observe(measuringElement);
    }

    // Unset the size observer on component unmount.
    return () => {
      if (measuringElement) sizeObserver.unobserve(measuringElement);
    };
  }, [index, setSize]);

  const { containerClassName, onMouseEnterContainer, onMouseLeaveContainer } =
    item || {};

  // Two elements are required. The outer `div` receives the height from
  // `VariableSizeList` and has a fixed height, even if content overflows. The
  // inner `div` is free to size itself according to its contents and is the
  // element that is used to measure the dimensions of the list item.
  return (
    <div
      style={style}
      className={containerClassName}
      onMouseEnter={onMouseEnterContainer}
      onMouseLeave={onMouseLeaveContainer}
    >
      <Measurer
        ref={measuringRef}
        className={cn({ 'static-size': isStaticallySized })}
      >
        <DevProps item={item} />
        {item ? renderItem(item, index) : null}
      </Measurer>
    </div>
  );
};

/**
 * Renders and computes the height of an item.
 */
const Item = memo(NonMemoItem, areEqual);

const Measurer = styled.div`
  // Prevents the margins of the children from collapsing, thereby being
  // included in the height of the item.
  padding: 0.01px;

  &.static-size {
    height: 100%;
    width: 100%;
  }
`;
