import React from 'react';
import styled from 'styled-components';
import { calcDayChange } from 'appUtils/projectPlannerUtils';
import moment from 'moment';
import DateRangeCalendar from 'components/DateRangeCalendar/WorkplanDateRangeCalendar';
import {
  StyledDateRowContainer,
  StyledDateContainer,
  StyledDateDisplay,
  CellHeader,
  DateDisplayContainer
} from 'views/projectPlanner/workloadBarModal/styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { DEPENDENCY_TYPES } from 'appConstants/workload';
import { NumberField } from './NumberField';
import { BUDGET_STATUSES } from 'appConstants/budgetStatuses';
import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import {
  hasEndDateDependency,
  hasStartDateDependency
} from 'appUtils/newDependencies';

const calcDaysTotal = (bar) =>
  calcDayChange(
    moment(bar.end_date).add(1, 'days'),
    moment(bar.start_date)
  ).asDays();
export const calcDaysOnTotal = (bar) =>
  bar.bars ? bar.bars.reduce((acc, cur) => acc + cur.day_count, 0) : 0;
const calcDaysOffTotal = (bar) => calcDaysTotal(bar) - calcDaysOnTotal(bar);

export const getDuration = (bar) =>
  Math.ceil(+bar.total_hours / (+bar.daily_hours || 1));
class BarDuration extends React.Component {
  renderDuration = () => {
    const { bar } = this.props;
    return `${getDuration(bar)} d`;
  };

  getTooltipContent = () => {
    const { bar } = this.props;
    const totalDays = calcDaysTotal(bar);
    const daysOff = calcDaysOffTotal(bar);
    return `<div>${Math.ceil(totalDays)} Total Days</div>
      <div class='tooltip-days-off-container'>
        <div>${Math.ceil(daysOff)} Weekend Days</div>
      </div>`;
  };

  componentDidUpdate() {
    rebuildTooltip();
  }

  componentDidMount() {
    rebuildTooltip();
  }

  calcTotalHourFromWorkDaysValue = (value) => {
    const { bar } = this.props;
    return Math.floor(+value) * (+bar.daily_hours || 1);
  };

  calcDailyHourFromWorkDaysValue = (value) => {
    const { bar } = this.props;
    return (+bar.total_hours || 1) / (Math.floor(+value) || 1);
  };

  handleChange = ({ floatValue }, { source, event }) => {
    if (source === 'event') {
      const key = event.target.name;
      this.props.handleChanges(key, { [key]: floatValue });
    }
  };

  handleBlur = (event) => {
    const { handleBlur, handleChanges, workDays } = this.props;

    if (!workDays) {
      const key = event.target.name;

      // If the number of work days is undefined, fallback to a minimum of 1.
      handleChanges(key, { [key]: 1 });
    }

    handleBlur(event);
  };

  calendarTarget = React.createRef();

  handleSubmit = ({
    startDate: newStartDate,
    endDate: newEndDate,
    dependency,
    dependencyItem,
    dependencyItemType,
    includeWeekends,
    useWeeklyPlanning,
    allDay,
    isTentative,
    startTime,
    endTime,
    timeRange,
    timeChanged
  }) => {
    const { handleDateChange, handleSetWorkplanCalendarOpen } = this.props;
    handleDateChange(
      newStartDate,
      newEndDate || newStartDate,
      dependency,
      dependencyItem,
      dependencyItemType,
      includeWeekends,
      useWeeklyPlanning,
      allDay,
      isTentative,
      startTime,
      endTime,
      timeRange,
      timeChanged
    );
    handleSetWorkplanCalendarOpen(false);
  };

  renderDateRangeCalendar = (customInputLabel, endDateFocus, isOpen) => {
    const {
      disableDependency,
      barPhase,
      isOOO,
      bar,
      handleSetWorkplanCalendarOpen,
      isMissingInfo,
      accountCapacities,
      accountUtilizations,
      accountPTODates,
      additionalDailyHours,
      workDays,
      initialDependency,
      initialDependencyItem,
      holidayDates,
      canEditWorkplan
    } = this.props;

    return (
      <DateRangeCalendar
        showInputs
        shouldRenderFooter
        showClear
        isWorkplanCalendar
        target={this.calendarTarget}
        customInput={(startDate, endDate, handleOpen) => (
          <StyledDateDisplay
            isDisabled={isMissingInfo || !canEditWorkplan}
            onClick={handleOpen}
          />
        )}
        onSubmit={this.handleSubmit}
        onClear={() => handleSetWorkplanCalendarOpen(false)}
        onClose={() => handleSetWorkplanCalendarOpen(false)}
        itemStartDate={bar.start_date}
        itemEndDate={bar.end_date}
        showWorkdays
        itemWorkDays={workDays}
        disableDependency={disableDependency}
        phaseStartDate={barPhase?.start_date}
        phaseEndDate={barPhase?.end_date}
        initialDependencyItem={initialDependencyItem}
        initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
        initialDependencyStepItem={barPhase}
        initialDependency={initialDependency}
        workplanIncludeWeekends={bar.include_weekends}
        workplanUseWeeklyPlanning={bar.use_weekly_planning}
        workplanAllDay={bar.all_day}
        workplanTentative={bar.budget_status === BUDGET_STATUSES.PROPOSAL}
        workplanStartTime={bar.start_time}
        workplanEndTime={bar.end_time}
        workplanDailyHours={bar.daily_hours}
        phaseId={barPhase?.id}
        phaseName={barPhase?.name}
        isOOO={isOOO}
        endDateFocus={endDateFocus}
        accountId={bar.account_id}
        startOpen={isOpen}
        accountCapacities={accountCapacities}
        accountUtilizations={accountUtilizations}
        accountPTODates={accountPTODates}
        additionalDailyHours={additionalDailyHours}
        item={barPhase}
        holidayDates={holidayDates}
      />
    );
  };

  render() {
    const {
      bar,
      handleFocus,
      disabled,
      dateFormat = 'MMM D, YYYY',
      dateInputOnly,
      workDaysInputOnly,
      workplanCalendarOpen,
      workDays,
      handleSetWorkplanCalendarOpen,
      canEditWorkplan
    } = this.props;

    const daysOff = calcDaysOffTotal(bar);
    const hasStartDependency = hasStartDateDependency(bar.dependency_infos);
    const hasEndDependency = hasEndDateDependency(bar.dependency_infos);

    return (
      <>
        <StyledDateRowContainer>
          {!workDaysInputOnly && (
            <div style={{ flex: '2 0 0' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around'
                }}
              >
                <CellHeader>START</CellHeader>
                <CellHeader>END</CellHeader>
              </div>
              <StyledDateContainer
                ref={this.calendarTarget}
                disabled={disabled}
                data-for="app-tooltip"
                data-tip={EDIT_WORK_PLANS_TIP}
                data-tip-disable={canEditWorkplan}
                data-effect="solid"
              >
                <DateDisplayContainer
                  disabled={disabled}
                  data-testid="workplan-calendar"
                  onClick={
                    !disabled
                      ? () =>
                          handleSetWorkplanCalendarOpen(!workplanCalendarOpen)
                      : undefined
                  }
                >
                  {moment(bar.start_date).format(dateFormat)}
                </DateDisplayContainer>
                <div>-</div>
                <DateDisplayContainer
                  disabled={disabled}
                  onClick={
                    !disabled
                      ? () =>
                          handleSetWorkplanCalendarOpen(!workplanCalendarOpen)
                      : undefined
                  }
                >
                  {moment(bar.end_date).format(dateFormat)}
                </DateDisplayContainer>
              </StyledDateContainer>
            </div>
          )}
          {!dateInputOnly && (
            <StyledWorkDaysContainer>
              <div
                data-for="app-tooltip"
                data-class="workload-workdays-tooltip"
                data-tip={daysOff ? this.getTooltipContent() : ''}
                data-html
                data-effect="solid"
              >
                <NumberField
                  name="work_days"
                  dataTestId="workplan-workdays"
                  label={'Workdays'}
                  value={workDays}
                  afterValue={' d'}
                  onFocus={handleFocus}
                  onBlur={this.handleBlur}
                  onValueChange={this.handleChange}
                  disabled={
                    disabled || (hasStartDependency && hasEndDependency)
                  }
                  roundedBorder={{ right: true }}
                />
              </div>
            </StyledWorkDaysContainer>
          )}
        </StyledDateRowContainer>

        {this.renderDateRangeCalendar(
          moment(bar.start_date).format(dateFormat),
          false,
          workplanCalendarOpen
        )}
      </>
    );
  }
}

export default BarDuration;

const StyledWorkDaysContainer = styled.div`
  flex: 1 0 0;
  margin-left: 4px;
  text-align: center;
`;
