import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import GuestIcon from 'icons/GuestIcon';
import ContractorIcon from 'icons/ContractorIcon';
import { AddMemberToListButton } from '..';
import { permissionsUtils } from 'PermissionsModule/utils';
import {
  createPrivatePortfolioMembership,
  createPublicPortfolioMembership
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import useCan from 'appUtils/hooks/useCan';
import { makeGetProjectBasicInfoById, getGroupsHash } from 'selectors';
import { useAppSelector } from 'reduxInfra/hooks';

const IndicatorIconContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 21px;
`;

export function AddMemberWrapper({
  addMemberToSelection,
  member,
  changeInputValue,
  groupId,
  projectId
}) {
  const entityId = groupId || projectId;
  // only for group
  addMemberToSelection(entityId, member);
  changeInputValue('');
}

const MemberListItem = ({
  me,
  member,
  notInBoard,
  addMemberToSelection,
  addMemberToProject,
  changeInputValue,
  groupId,
  projectId,
  addMemberLocally,
  removeMemberLocally,
  newMember,
  className = ''
}) => {
  const getProject = useAppSelector(makeGetProjectBasicInfoById);
  const project = useAppSelector((state) => getProject(state, { projectId }));
  const groupHash = useAppSelector(getGroupsHash);

  const onClick = (e) => {
    e.preventDefault();
    if (notInBoard && projectId && !removeMemberLocally && !addMemberLocally) {
      return addMemberToProject(projectId, member);
    } else if (removeMemberLocally || addMemberLocally) {
      return newMember ? removeMemberLocally(member) : addMemberLocally(member);
    } else {
      return AddMemberWrapper({
        addMemberToSelection,
        member,
        changeInputValue,
        groupId,
        projectId
      });
    }
  };

  const portfolio = groupHash[project?.board_id] || groupHash[groupId];

  const addToPortfolioCheck = portfolio?.is_private
    ? createPrivatePortfolioMembership
    : createPublicPortfolioMembership;

  const canAddToPortfolio = useCan(addToPortfolioCheck, {
    boardId: portfolio?.id
  });

  const isProjectGuest = permissionsUtils.getIsProjectGuest(member);
  const isContractor = permissionsUtils.getIsContractor(member);
  const isSuggestedMember = member.isSuggestedMember;
  const IndicatorComponent = isProjectGuest
    ? GuestIcon
    : isContractor
    ? ContractorIcon
    : undefined;

  /**
   * This component is used for adding to projects and also adding to portfolio.
   * When adding to project: Show if you can add to portfolio or member is in board.
   * When adding to portfolio: Show if member is not in board.
   */
  return canAddToPortfolio || !notInBoard ? (
    <li
      className={cn('clickable', 'member-line', {
        faded: addMemberLocally,
        [`${className}`]: !!className
      })}
      onClick={onClick}
    >
      <div className="member-line-contents">
        <div className="add-to-project">
          <button
            className={cn('members-initial', {
              'is-user': member.account.id === me.id
            })}
          >
            {member.account.initials}
            {IndicatorComponent && (
              <IndicatorIconContainer>
                <IndicatorComponent width={13} height={13} />
              </IndicatorIconContainer>
            )}
          </button>
          <span className={cn('member-name', { 'non-board': notInBoard })}>
            {member.account.name}
          </span>
          {(isProjectGuest || isContractor) && (
            <span
              className={cn({
                'member-is-guest': isProjectGuest,
                'member-is-contractor': isContractor,
                'non-board': notInBoard
              })}
            >
              {isProjectGuest ? 'Project Guest' : 'Contractor'}
            </span>
          )}
        </div>

        <AddMemberToListButton
          projectId={projectId}
          newMember={newMember}
          notInBoard={notInBoard}
          Guest={isProjectGuest}
          isSuggestedMember={isSuggestedMember}
        />
      </div>
    </li>
  ) : null;
};

export default MemberListItem;
