export default function buildAdminNamesString(team) {
  const teamAdminNames = team.team_members
    .filter((member) => member.is_admin)
    .map((admin) => admin.account.name);
  let stringOfNames = '';

  for (let idx = 0; idx < teamAdminNames.length; idx++) {
    stringOfNames +=
      idx > 0 && idx === teamAdminNames.length - 1
        ? 'or ' + teamAdminNames[idx]
        : teamAdminNames[idx] + ' ';
  }

  return stringOfNames;
}
