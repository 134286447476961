import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import BasicMemberCircle from 'views/memberDisplay/BasicMemberCircle.js';
import moment from 'moment';

const PhaseActivityContainer = styled.div`
  display: flex;
  color: ${theme.colors.colorLightGray15};
  width: 336px;
  padding-left: 10px;
`;

const MemberContainer = styled.div`
  margin-left: 10px;
  line-height: 1.1;
  font-size: 13px;
  font-weight: 400;
`;

const NameContainer = styled.div`
  font-size: 13px;
  color: black;
`;

const TimeContainer = styled.div`
  font-size: 12px;
`;

const makeTimestamp = (timestamp) =>
  moment(timestamp).calendar(null, {
    lastDay: '[Yesterday]',
    lastWeek: 'MM/DD/YY', // override default last week handling
    sameElse: 'MM/DD/YY'
  });

const PhaseUserActivityTitleCell = (props) => {
  // const { phase, row } = props;
  const { userActivity } = props.row.original;
  const user = userActivity?.actor || {};

  return (
    <PhaseActivityContainer>
      <BasicMemberCircle initials={user?.initials} isLoggedInUser={true} />
      <MemberContainer>
        <NameContainer>{user?.name}</NameContainer>
        {userActivity?.timestamp && (
          <TimeContainer>
            {makeTimestamp(userActivity?.timestamp)}
          </TimeContainer>
        )}
      </MemberContainer>
    </PhaseActivityContainer>
  );
};

export default PhaseUserActivityTitleCell;
