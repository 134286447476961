import { CustomPicker } from 'react-color';
import {
  Hue,
  Saturation,
  EditableInput
} from 'react-color/lib/components/common/';
import { connect } from 'react-redux';
import theme from 'theme';
import SideBarBackArrowIcon from 'icons/SideBarBackArrowIcon';
import {
  StyledDoneButton,
  BackButton,
  StyledColorCircle,
  SelectedColorBorder,
  CustomColorPickerContainer,
  CustomColorHeader,
  CustomColorFooter,
  SaturationContainer,
  HueContainer,
  HexInputContainer
} from './styles';
import { makeGetPreference } from 'selectors';

const hexInputStyle = {
  input: {
    fontSize: '12px',
    fontWeight: '400',
    width: '61px',
    height: '23px',
    border: `1px solid ${theme.colors.colorLightGray1}`,
    textAlign: 'center'
  },
  label: {
    fontSize: '12px',
    fontWeight: '400'
  }
};

const CustomColorPicker = (props) => {
  const { hex, toggle, setColor } = props;

  const handleChange = (data) => {
    setColor(data);
  };

  const handleConfirm = () => {
    const { preference, id, isDefaultColor, handleSelect, apiCall } = props;
    const payload = { color: hex, id };
    const api = apiCall;
    if (isDefaultColor && preference) {
      api.delete(payload);
    } else if (!isDefaultColor) {
      preference ? api.update(payload) : api.create(payload);
    }
    if (handleSelect) {
      handleSelect();
    }
  };

  return (
    <CustomColorPickerContainer>
      <CustomColorHeader>
        <BackButton onClick={toggle}>
          <SideBarBackArrowIcon
            fill={theme.colors.colorCalendarBlue}
            className="sidebar-backarrow-icon"
          />
        </BackButton>
        <StyledDoneButton onClick={() => handleConfirm()}>
          Done
        </StyledDoneButton>
      </CustomColorHeader>
      <SaturationContainer>
        <Saturation {...props} onChange={(data) => handleChange(data)} />
      </SaturationContainer>
      <CustomColorFooter>
        <HueContainer>
          <Hue {...props} onChange={(data) => handleChange(data)} />
        </HueContainer>
        <HexInputContainer>
          Hex{' '}
          <EditableInput
            style={hexInputStyle}
            value={hex}
            onChange={(data) => handleChange(data)}
          />
        </HexInputContainer>
        <StyledColorCircle className="color-picker-circle" $color={hex}>
          <SelectedColorBorder />
        </StyledColorCircle>
      </CustomColorFooter>
    </CustomColorPickerContainer>
  );
};

const makeMapStateToProps = () => {
  const getPreference = makeGetPreference();
  const mapStateToProps = (state, ownProps) => ({
    preference: getPreference(state, ownProps) || ownProps.preference // for items that carry own preference independent of userTheme
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(CustomPicker(CustomColorPicker));
