import React from 'react';
import styled from 'styled-components';
import { ProjectCellContainer } from '../styles';

const Percentage = styled.div`
  padding-right: 17px;
  padding-top: 16px;
  text-align: right;
`;

const ProjectPercentageCell = ({ row }) => {
  return (
    <ProjectCellContainer height={row?.original?.itemHeight}>
      <Percentage>{row?.original?.percentage || 0}%</Percentage>
    </ProjectCellContainer>
  );
};

export default ProjectPercentageCell;
