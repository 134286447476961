import { createAction } from '@reduxjs/toolkit';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createRequestTypes } from 'appUtils';
import {
  fetchPhaseSuggestionsForTimesheetAccountsActionPayload,
  fetchPhaseSuggestionsForTimesheetAccountsSuccessResponse
} from 'SuggestionModule/types';

/* -------------------------------------------------------------------------- */

const FETCH_PHASE_SUGGESTIONS_FOR_TIMESHEET_ACCOUNTS = createRequestTypes(
  'FETCH_PHASE_SUGGESTIONS_FOR_TIMESHEET_ACCOUNTS'
);

export const fetchPhaseSuggestionsForTimesheetAccounts = createAction(
  FETCH_PHASE_SUGGESTIONS_FOR_TIMESHEET_ACCOUNTS.TRIGGER,
  ({
    meta,
    ...payload
  }: fetchPhaseSuggestionsForTimesheetAccountsActionPayload) => ({
    payload,
    meta
  })
);

const fetchPhaseSuggestionsForTimesheetAccountsActionCreatorsMap =
  createActionCreatorsMap<
    fetchPhaseSuggestionsForTimesheetAccountsActionPayload,
    fetchPhaseSuggestionsForTimesheetAccountsSuccessResponse
  >(FETCH_PHASE_SUGGESTIONS_FOR_TIMESHEET_ACCOUNTS);

/* -------------------------------------------------------------------------- */
export const phaseSuggestionsActionCreatorsMap = {
  fetchPhaseSuggestionsForTimesheetAccounts:
    fetchPhaseSuggestionsForTimesheetAccountsActionCreatorsMap
};

export const phaseSuggestionsActions = {
  fetchPhaseSuggestionsForTimesheetAccounts
};
