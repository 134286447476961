import styled from 'styled-components';
import SmallDiamondIcon from 'icons/SmallDiamondIcon';

interface WorkCategoryInfoProps {
  workCategoryName: string;
}
export const WorkCategoryInfo = ({
  workCategoryName
}: WorkCategoryInfoProps) => (
  <Title>
    <StyledSmallDiamondIcon />
    {workCategoryName}
  </Title>
);

const StyledSmallDiamondIcon = styled(SmallDiamondIcon)`
  flex: none;
  margin-right: 3px;
`;
const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
