import { renderToStaticMarkup } from 'react-dom/server';

interface MemberAttributesTooltipProps {
  billRate?: number;
  currencyFormatter?: Intl.NumberFormat;
  name: string;
  teamRoleName: string;
  totalCapacity: string | number;
}
export const getMemberAttributesTooltip = ({
  billRate,
  currencyFormatter,
  name,
  teamRoleName,
  totalCapacity
}: MemberAttributesTooltipProps): string => {
  const rows = [
    totalCapacity && (
      <div className="row">
        <p className="label">Capacity</p>
        <p className="value">{totalCapacity}/wk</p>
      </div>
    ),
    billRate && currencyFormatter && (
      <div className="row">
        <p className="label">Bill Rate</p>
        <p className="value">{currencyFormatter.format(billRate)}/h</p>
      </div>
    )
  ].filter(Boolean);

  const hasRows = rows.length;

  return renderToStaticMarkup(
    <div className="workload-member">
      <b className="name">{name}</b>
      <br />
      <span className="label">{teamRoleName || 'No Default Role'}</span>
      {hasRows && <hr className="divider" />}
      {rows.map((row) => row)}
    </div>
  );
};
