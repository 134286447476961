import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';

export const initialState = {
  filterStates: {}
};

// For use with GET /project_accounts endpoint when action.payload.filterStateId exists
// otherwise, original state.phases.phaseTotals / .projectTotals is used
const projectAccounts = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }

    case constants.FETCH_PHASE_TOTALS.SUCCESS: {
      const { initial, projectId, filterStateId } =
        action.payload.requestPayload;

      if (!filterStateId) return state;

      const { budgets } = action.payload.response;
      const [budget] = budgets;
      const { phases, ...rest } = budget || {};

      // for clearing the budget table when being filtered and no results
      if (!budget && initial) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              phaseTotals: {},
              projectTotal: {
                estimated: 0,
                total: 0,
                ...state.projectTotal,
                spent: {},
                planned: {},
                estimated_sum: {},
                account_totals: [],
                fee: {},
                over_budget: [],
                phases: []
              }
            }
          }
        };
      }

      return {
        ...state,
        filterStates: {
          ...state.filterStates,
          [filterStateId]: {
            phaseTotals: keyBy(
              phases.map((phase) => ({ ...phase, project_id: projectId })),
              (item) => item && item.phase_id
            ),
            projectTotal: rest
          }
        }
      };
    }

    default:
      return state;
  }
};

export default projectAccounts;
