import { ComponentProps } from 'react';
import SvgIcon from 'components/SvgIcon';

const CloseIcon = ({
  width = '7px',
  height = '7px',
  fill = '#0074D9',
  ...rest
}: ComponentProps<typeof SvgIcon>) => (
  <SvgIcon width={width} height={height} viewBox="0 0 7 7" {...rest}>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M4.483 3.5L6.93 1.053a.24.24 0 0 0 0-.339L6.286.071a.24.24 0 0 0-.34 0L3.5 2.517 1.054.07a.24.24 0 0 0-.34 0L.071.714a.24.24 0 0 0 0 .34l2.446 2.445L.07 5.946a.24.24 0 0 0 0 .34l.643.643a.24.24 0 0 0 .34 0L3.5 4.483 5.946 6.93a.24.24 0 0 0 .34 0l.643-.643a.24.24 0 0 0 0-.34L4.483 3.5z"
    />
  </SvgIcon>
);

export default CloseIcon;
