import { ReducerName } from 'reduxInfra/shared';
import { reducerMap } from '../reducers/ratesReducers';
import { ratesSagas } from '../sagas/ratesSagas';

export function getRatesModule() {
  return {
    id: ReducerName.TeamRates,
    reducerMap,
    sagas: [ratesSagas]
  };
}
