import { ComponentProps } from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const AddPlusIcon = ({
  fill = theme.colors.colorCalendarBlue,
  height = 24,
  strokeWidth,
  width = 24,
  ...svgIconProps
}: ComponentProps<typeof SvgIcon> & {
  fill?: string;
  strokeWidth?: string;
}) => (
  <SvgIcon height={height} viewBox="0 0 24 24" width={width} {...svgIconProps}>
    <path
      d="M18 12.75H12.75V18H11.25V12.75H6V11.25H11.25V6H12.75V11.25H18V12.75Z"
      fill={fill}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default AddPlusIcon;
