import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createMemberBudget } from 'BudgetModule/actionCreators';
import { createPhaseMembers } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import AddPlusIcon from 'icons/AddPlusIcon';
import AddRolesDropdown from 'components/dropdowns/AddRolesDropdown';
import PositionsDropdown from 'components/roles/dropdowns/PositionsDropdown';
import DevProps from 'components/dev/DevProps';

const StyledText = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorCalendarBlue};
`;
const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: fit-content;
  cursor: pointer;
  position: relative;
  padding-left: 24px;
  top: ${({ hasNoRole }) => (hasNoRole ? '5px' : '10px')};
`;

const PhaseTitleBottomRowCell = ({ row }) => {
  const dispatch = useDispatch();
  const [showRolesDropdown, setShowRolesDropdown] = useState(false);
  const rolesDropdownRef = useRef(null);
  const { positions, phase, list, hasNoRole } = row.original;

  const handleAddRole = (role) => {
    const memberBudget = role.memberBudget;

    if (!memberBudget) {
      // creating new unassigned member budget
      dispatch(
        createMemberBudget({
          projectId: phase.project_id,
          memberBudgets: [
            {
              phase_id: phase.id,
              position_id: role.id
            }
          ]
        })
      );
    } else {
      // adding existing unassigned member budget to phase
      dispatch(
        createPhaseMembers({
          projectId: phase.project_id,
          phaseId: phase.id,
          memberBudgetIds: [memberBudget.id]
        })
      );
    }
  };

  return (
    <>
      <DevProps
        phase={phase}
        positions={positions}
        showRolesDropdown={showRolesDropdown}
      />

      <StyledTitleContainer
        onClick={() => setShowRolesDropdown(true)}
        ref={rolesDropdownRef}
        hasNoRole={hasNoRole}
      >
        <AddPlusIcon fill={theme.colors.colorCalendarBlue} />
        <StyledText> Unassigned Role </StyledText>
      </StyledTitleContainer>

      {showRolesDropdown && (
        // <AddRolesDropdown
        //   onClose={() => setShowRolesDropdown(false)}
        //   targetRef={rolesDropdownRef}
        //   phase={phase}
        // />
        <PositionsDropdown
          onClose={() => setShowRolesDropdown(false)}
          onSelect={handleAddRole}
          targetRef={rolesDropdownRef}
          closeOnSelect
          headerTitle="Add Role"
          projectId={phase.project_id}
        />
      )}
    </>
  );
};

export default PhaseTitleBottomRowCell;
