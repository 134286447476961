import React from 'react';
import {
  StyledProgressPill,
  PillContainer,
  ProgressPillIndicator
} from './styles';

const buildDatatip = ({ totalNumber, indicatorNumber }) =>
  `
    <div class="progress-pill-tooltip">
      <div class="tooltip-row">
        <div class="tooltip-number">
          ${(
            ((totalNumber - indicatorNumber) * 100) /
            totalNumber
          ).toLocaleString(undefined, {
            maximumFractionDigits: 0
          })}
          %
        </div>
        <div class="tooltip-text">completed</div>
      </div>
      <div class="tooltip-separator"></div>
      <div class="tooltip-row">
        <div class="tooltip-number">${totalNumber - indicatorNumber}</div>
        <div class="tooltip-text">tasks completed</div>
      </div>
      <div class="tooltip-row">
        <div class="tooltip-number">${indicatorNumber}</div>
        <div class="tooltip-text">tasks incomplete</div>
      </div>
    </div>
  `;

const ProgressPill = ({ partialNumber, totalNumber, indicatorNumber }) => (
  <PillContainer
    isAlertStyle={!!indicatorNumber}
    data-for={`app-tooltip`}
    data-tip={buildDatatip({ totalNumber, indicatorNumber })}
    data-html
  >
    {(indicatorNumber > 0 || partialNumber > 0) && (
      <StyledProgressPill
        barWidth={(partialNumber * 100) / totalNumber}
        isAlertStyle={!!indicatorNumber}
      >
        <ProgressPillIndicator tisAlertStyle={!!indicatorNumber} />
      </StyledProgressPill>
    )}
  </PillContainer>
);

export default React.memo(ProgressPill);
