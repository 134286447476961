import { Phase } from './ProjectTeam/components/Phase';
import { ActivityPhase } from './ProjectTeam/components/ActivityPhase';
import { Membership } from './ProjectTeam/components/Membership';
import { AddMemberToPhase } from './ProjectTeam/components/AddMemberToPhase';
import { ProjectTeamCollapseAll } from './ProjectTeam/components/CollapseAll';
import { EmptyDiv } from 'components/EmptyDiv';

import { AddProjectMembershipRequirement } from './UnassignedRoles/components/AddProjectMembershipRequirement';
import { ProjectUnassignedMemberBudgetWithRequirements } from './UnassignedRoles/components/ProjectUnassignedMemberBudgetWithRequirements';
import { ProjectMembershipRequirement } from './UnassignedRoles/components/ProjectMembershipRequirement';
import { RequirementLoader } from './UnassignedRoles/components/RequirementLoader';

export const LIST_ITEM_TYPES = {
  Phase: 'phase',
  ActivityPhase: 'activity-phase',
  Membership: 'membership',
  AddMemberToPhase: 'add-member-to-phase',
  PhaseMembershipWithUnassignedMemberBudget: 'unassigned',
  ProjectTeamCollapseAll: 'project-team-collapse-all',
  /* -------------------------------------------------------------------------- */
  ProjectUnassignedMemberBudgetWithRequirements:
    'project-unassigned-mb-with-requirements',
  ProjectMembershipRequirement: 'project-membership-requirement',
  AddProjectMembershipRequirement: 'add-project-membership-requirement',
  RequirementLoader: 'requirement-loader',
  /* -------------------------------------------------------------------------- */
  EmptyItem: 'empty-item'
} as const;

export const listItemTypeToRenderHash = {
  [LIST_ITEM_TYPES.Phase]: Phase,
  [LIST_ITEM_TYPES.ActivityPhase]: ActivityPhase,
  [LIST_ITEM_TYPES.Membership]: Membership,
  [LIST_ITEM_TYPES.AddMemberToPhase]: AddMemberToPhase,
  [LIST_ITEM_TYPES.ProjectTeamCollapseAll]: ProjectTeamCollapseAll,
  // [LIST_ITEM_TYPES.PhaseMembershipWithUnassignedMemberBudget]:
  //   PhaseMembershipWithUnassignedMemberBudget,
  /* -------------------------------------------------------------------------- */
  [LIST_ITEM_TYPES.ProjectUnassignedMemberBudgetWithRequirements]:
    ProjectUnassignedMemberBudgetWithRequirements,
  [LIST_ITEM_TYPES.ProjectMembershipRequirement]: ProjectMembershipRequirement,
  [LIST_ITEM_TYPES.AddProjectMembershipRequirement]:
    AddProjectMembershipRequirement,
  [LIST_ITEM_TYPES.RequirementLoader]: RequirementLoader,
  /* -------------------------------------------------------------------------- */
  [LIST_ITEM_TYPES.EmptyItem]: EmptyDiv
};

export const renderItem = (item, index) =>
  listItemTypeToRenderHash[item.itemType](item, index);
