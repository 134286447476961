import { getAuthToken } from 'AuthenticationModule/selectors';
import { select } from 'redux-saga/effects';
import { changeEntity } from 'sagas/generics';
import * as activityPhaseMembershipActions from '../actionCreators/activityPhaseMembership';
import * as activityPhaseMembershipApis from '../api/activityPhaseMembership';

export function* updateActivityPhaseMembershipWorker(
  action: ReturnType<
    typeof activityPhaseMembershipActions['updateActivityPhaseMembership']
  >
) {
  const token = yield select(getAuthToken);
  yield changeEntity(
    activityPhaseMembershipActions.updateActivityPhaseMembershipActionCreatorsMap,
    activityPhaseMembershipApis.updateActivityPhaseMembership,
    [token, action.payload],
    action
  );
}
