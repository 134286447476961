import { useCallback } from 'react';
import { useLeanApi } from 'LeanApiModule/utils/useLeanApi';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import { LeanApiIsoStateModifiers } from 'LeanApiModule/types';
import { RequestPriorityGroup } from 'appConstants';

// staging has a function to fix time-zone issue but it hasn't reached prod yet
// temporary until it reaches prod
export const getISOStringParsedDateObj = (date: string) =>
  new Date(new Date(date).toISOString().slice(0, -1));

// custom hook for fetching phases that end within a given period of time
// through lean api in order to fetch only relevant data
// this is only used for calendar widget and calendar tab on member modal
export const useFetchPhasesThroughLeanApiForCalendar = ({
  isoStateId,
  requestPriority
}: {
  isoStateId: string;
  requestPriority?: RequestPriorityGroup;
}) => {
  const { debouncedFetchLeanApi, leanApiIsoState, fetchStatus } = useLeanApi({
    isoStateId
  });

  const fetchPhasesWithinRange = useCallback(
    ({
      startDate,
      endDate,
      accountId
    }: {
      startDate: Date;
      endDate: Date;
      accountId: number;
    }) => {
      debouncedFetchLeanApi({
        schema: {
          entity_type: 'project',
          where: {
            project_memberships: { account: { id_in: [accountId] } },
            phases: {
              discarded_at: null,
              end_date_in: eachDayOfInterval({
                start: startDate,
                end: endDate
              }).map((date) => format(date, 'MM/dd/yyyy'))
            },
            archived_at: null
          },
          select: {
            phases: {
              id: 'phase_id',
              start_date: 'start_date',
              end_date: 'end_date',
              name: 'name',
              is_budget: 'is_budget',
              total_work_days: 'total_work_days',
              project_id: 'project_id',
              bars: 'bars'
            },
            title: 'title',
            color: 'color',
            id: 'id',
            description: 'description'
          },
          order: {},
          group: {},
          exclude_records: false,
          exclude_value_counts: true,
          all: true
        },
        meta: {
          isoStateModifiers: calendarLeanApiIsoStateModifiers,
          ...(requestPriority && { requestPriority })
        }
      });
    },
    [debouncedFetchLeanApi, requestPriority]
  );
  return {
    fetchPhasesWithinRange,
    fetchStatus,
    leanApiIsoState
  };
};

interface FetchLeanApiSuccessResponse {
  records: {
    phases: {
      phase_id: number;
      start_date: string;
      end_date: string;
      name: string;
      is_budget: boolean;
      total_work_days: number;
      project_id: number;
    }[];
    id: number;
    title: string;
    color: string;
  }[];
  session_id: string;
}

const calendarLeanApiIsoStateModifiers: LeanApiIsoStateModifiers = {
  fetchLeanApiSuccess: ({
    response: _untypedResponse,
    isoState,
    initialPayload
  }) => {
    const response = _untypedResponse as FetchLeanApiSuccessResponse;
    const phaseIds: number[] = [];

    // FIXME: 'where' is initially typed as 'unknown'
    const intervalDates =
      initialPayload?.schema?.['where' as any]?.phases?.end_date_in;
    const intervalStartDate = intervalDates[0];
    const intervalEndDate = intervalDates[intervalDates.length - 1];

    response.records.forEach((record) => {
      const { phases, ...project } = record;

      isoState.dataHash.partialProjects[project.id] = {
        ...project,
        isPartial: true
      };

      phases.forEach((phase) => {
        const { phase_id, start_date, end_date, ...restPhaseData } = phase;
        // they come with 'YYYY-MM-DD'. This is to match phase hash's date format
        const phaseStartDate = format(
          getISOStringParsedDateObj(start_date),
          'MM/dd/yyyy'
        );
        const phaseEndDate = format(
          getISOStringParsedDateObj(end_date),
          'MM/dd/yyyy'
        );

        isoState.dataHash.partialPhases[phase_id] = {
          id: phase_id,
          start_date: phaseStartDate,
          end_date: phaseEndDate,
          isPartial: true,
          ...restPhaseData
        };

        phaseIds.push(phase_id);
      });

      isoState.ordersByGroup[`${intervalStartDate} - ${intervalEndDate}`] =
        phaseIds;
    });
  }
};
