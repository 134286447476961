import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { HelpersContext } from 'react-calendar-timeline';

import { createPlannerWeekKeys } from 'appUtils/timesheetUtils';
import { getOOOProject, getWFHProject } from 'selectors';

import {
  CAPACITY_DATE_KEYS,
  DEFAULT_WEEKLY_CAPACITY,
  ZOOM_STRINGS
} from 'appConstants/workload';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { zoomToIntervalHash } from 'appUtils/projectPlannerUtils';
import { formatNumWithMaxTwoDecimals } from '../formatUtils';

const moment = extendMoment(Moment);
const emptyArray = [];
const emptyObj = {};

export const getWeekDateRange = (date) => {
  if (!weekDateMemos[date]) {
    const momentRange = moment(date).range('week');
    const weekRange = Array.from(momentRange.by('day')).map((day) =>
      day.format('YYYY-MM-DD')
    );
    weekDateMemos[date] = weekRange;
  }
  return weekDateMemos[date];
};

export const getMonthDateRange = (date) => {
  if (!monthDateMemos[date]) {
    const momentRange = moment(date).range('month');
    const monthRange = Array.from(momentRange.by('day')).map((day) =>
      day.format('YYYY-MM-DD')
    );
    monthDateMemos[date] = monthRange;
  }
  return monthDateMemos[date];
};
export const getMonthWeekDayRange = (date) => {
  if (!monthWeekDayMemos[date]) {
    const momentRange = moment(date).range('month');
    const monthRange = Array.from(momentRange.by('day')).map((day) =>
      day.format('dddd').toLowerCase()
    );
    monthWeekDayMemos[date] = monthRange;
  }
  return monthWeekDayMemos[date];
};
const weekDateMemos = {};
const monthDateMemos = {};
const monthWeekDayMemos = {};

const stepMomentHash = {};
const stepFormatHash = {};

const getMomentFromStepValue = (timestamp) => {
  if (!stepMomentHash[timestamp]) {
    stepMomentHash[timestamp] = moment(timestamp);
  }
  return stepMomentHash[timestamp];
};

const today = moment();
const getStepFormatsFromStepValue = (step, stepValue, momentObj) => {
  const hashKey = `${step}-${stepValue}`;
  if (!stepFormatHash[hashKey]) {
    const dayOfWeek = momentObj.format('dddd');
    stepFormatHash[hashKey] = {
      isBorderRight: dayOfWeek === 'Sunday',
      isWeekend: dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday',
      capacityFormat: dayOfWeek.toLowerCase(),
      committedFormat: momentObj.format('YYYY-MM-DD'),
      isInUpcomingTwoWeeks:
        !momentObj.isBefore(today, 'day') &&
        momentObj.isBefore(
          moment() // don't replace with "today", mutates
            .endOf('week')
            .add(1, 'week'),
          'day'
        ),
      containsOrAfterToday: !momentObj.isBefore(today, stepValue)
    };
  }
  return stepFormatHash[hashKey];
};

const formatSteps = (
  steps,
  stepValue,
  getLeftOffsetFromDate,
  holidayDatesHash,
  isWeek
) =>
  steps.map((step) => {
    const momentObj = getMomentFromStepValue(step);
    const stepFormatValues = getStepFormatsFromStepValue(
      step,
      stepValue,
      momentObj
    );

    return {
      left: getLeftOffsetFromDate(momentObj.startOf(stepValue).valueOf()),
      isBorderRight:
        stepValue === ZOOM_STRINGS.MONTH || stepFormatValues.isBorderRight,
      isWeekend:
        stepValue !== ZOOM_STRINGS.MONTH &&
        stepFormatValues.isWeekend &&
        !isWeek,
      isHoliday:
        stepValue !== ZOOM_STRINGS.MONTH &&
        holidayDatesHash[momentObj.format('MM/DD/YYYY')] &&
        !isWeek,
      capacityFormat: stepFormatValues.capacityFormat,
      committedFormat: stepFormatValues.committedFormat,
      isInUpcomingTwoWeeks: stepFormatValues.isInUpcomingTwoWeeks,
      containsOrAfterToday: stepFormatValues.containsOrAfterToday
    };
  });

export const getCapacity = (
  capacity,
  stepValue,
  dayOfWeek,
  date,
  isDayOff,
  PTO = {}
) => {
  switch (stepValue) {
    case ZOOM_STRINGS.MONTH: {
      const monthRange = getMonthWeekDayRange(date);

      const calculatedMonthCapacity = monthRange.reduce(
        (total, date) => total + (capacity[date] || 0),
        0
      );
      const defaultMonthCapacity = 170;

      const monthCapacity = calculatedMonthCapacity || defaultMonthCapacity;

      const monthDates = getMonthDateRange(date);

      const monthPTO = monthDates.reduce(
        (total, date) => total + (PTO[date] || 0),
        0
      );
      // no negative capacity

      return {
        capacityWithoutPto: Math.max(0, monthCapacity - monthPTO),
        totalCapacity: monthCapacity,
        PTO: monthPTO
      };
    }
    case ZOOM_STRINGS.WEEK: {
      const weekCapacity =
        CAPACITY_DATE_KEYS.map((key) => capacity[key]).reduce(
          (a, b) => a + b
        ) || DEFAULT_WEEKLY_CAPACITY;
      const weekDates = getWeekDateRange(date);
      const weekPTO = weekDates.reduce(
        (total, date) => total + (PTO[date] || 0),
        0
      );

      return {
        capacityWithoutPto: Math.max(0, weekCapacity - weekPTO),
        totalCapacity: weekCapacity,
        PTO: weekPTO
      };
    }
    default: {
      const dayCapacity = isDayOff
        ? capacity[dayOfWeek] || 0
        : capacity[dayOfWeek] !== undefined
        ? capacity[dayOfWeek]
        : 8;
      const dayPTO = PTO[date] || 0;
      return {
        capacityWithoutPto: Math.max(0, dayCapacity - dayPTO),
        totalCapacity: dayCapacity,
        PTO: dayPTO
      };
    }
  }
};

const sumUtilizationsBreakdown = ({ dates, utilizationsBreakdown }) => {
  return dates.reduce(
    (acc, date) => {
      if (utilizationsBreakdown[date]) {
        const {
          capacity,
          planned,
          committed,
          PTO,
          holiday,
          cappedOffHours,
          cappedCommitted,
          available
        } = utilizationsBreakdown[date];
        return {
          capacity: acc.capacity + capacity,
          planned: acc.planned + planned,
          committed: acc.committed + committed,
          cappedCommitted: acc.cappedCommitted + cappedCommitted,
          PTO: acc.PTO + PTO,
          holiday: acc.holiday + holiday,
          cappedOffHours: acc.cappedOffHours + cappedOffHours,
          available: acc.available + available
        };
      }
      return acc;
    },
    {
      capacity: 0,
      planned: 0,
      committed: 0,
      cappedCommitted: 0,
      PTO: 0,
      holiday: 0,
      cappedOffHours: 0,
      available: 0
    }
  );
};

export const getSummedUtilizationsBreakdownByZoomStep = ({
  utilizationsBreakdown,
  /**
   * ZOOM_STRINGS
   */
  stepValue,
  /**
   * date string
   */
  date
}) => {
  switch (stepValue) {
    case ZOOM_STRINGS.MONTH: {
      const monthDates = getMonthDateRange(date);
      return sumUtilizationsBreakdown({
        dates: monthDates,
        utilizationsBreakdown
      });
    }
    case ZOOM_STRINGS.WEEK: {
      const weekDates = getWeekDateRange(date);
      return sumUtilizationsBreakdown({
        dates: weekDates,
        utilizationsBreakdown
      });
    }
    default: {
      return utilizationsBreakdown[date];
    }
  }
};

const findMatchingRange = (capacity, stepValue, date, project = {}) => {
  if (capacity.activity_phase_schedule_bars) {
    const bars = capacity.activity_phase_schedule_bars.filter(
      (bar) => bar.project_id === project.id
    );
    const dates = bars.map(
      // add 1 day to the end date because the contains function seems to be enddate exclusive
      (activityPhaseBars) =>
        moment.range(
          moment(activityPhaseBars.start_date, 'MM/DD/YYYY'),
          moment(activityPhaseBars.end_date, 'MM/DD/YYYY').add(1, 'd')
        )
    );
    const startDate = moment(date, 'YYYY-MM-DD');
    const endDate = moment(date, 'YYYY-MM-DD').endOf(stepValue);
    const targetDateRange = moment.range(startDate, endDate);
    return dates.some((range) => range.contains(targetDateRange));
  }
  return false;
};

export const getCommitted = (utilizations = emptyObj, stepValue, date) => {
  switch (stepValue) {
    case ZOOM_STRINGS.MONTH: {
      const monthRange = getMonthDateRange(date);
      const committed = monthRange.reduce(
        (total, date) => total + (utilizations[date] || 0),
        0
      );
      return committed;
    }
    case ZOOM_STRINGS.WEEK: {
      const momentObj = moment(date); // memoizing rerender above
      const weekCommitted = createPlannerWeekKeys(momentObj)
        .map((weekKey) => utilizations[weekKey])
        .reduce((a, b) => (a || 0) + (b || 0));
      return weekCommitted || 0;
    }
    default: {
      const dayCommitted = utilizations[date] || 0;
      return dayCommitted;
    }
  }
};

export const getOvercapacityMembers = ({
  unCappedUtilizationsByMember,
  cappedUtilizationsByMember,
  date
}) => {
  const overCapacityMembers = {};
  const memberAccountIds = Object.keys(cappedUtilizationsByMember);
  for (let i = 0; i < memberAccountIds.length; i++) {
    const memberId = memberAccountIds[i];

    const unCappedMemberUtilizations =
      unCappedUtilizationsByMember[memberId] ?? {};
    const cappedMemberUtilizations = cappedUtilizationsByMember[memberId] ?? {};
    const cappedMemberPTO = cappedMemberUtilizations.PTO ?? {};

    const unCappedMemberUtilizationOnDate =
      unCappedMemberUtilizations[date] ?? 0;
    const cappedMemberUtilizationOnDate = cappedMemberUtilizations[date];
    const memberPTOonDate = cappedMemberPTO[date] ?? 0;

    if (!cappedMemberUtilizationOnDate) continue;

    if (
      cappedMemberUtilizationOnDate <
      unCappedMemberUtilizationOnDate + memberPTOonDate
    ) {
      overCapacityMembers[memberId] = formatNumWithMaxTwoDecimals(
        unCappedMemberUtilizationOnDate +
          memberPTOonDate -
          cappedMemberUtilizationOnDate
      );
    }
  }

  return overCapacityMembers;
};

const useWorkloadDataProvider = (zoom, steps, holidayDatesHash) => {
  const helpersContext = React.useContext(HelpersContext);
  const { getLeftOffsetFromDate, getGroupDimensions } = helpersContext;

  const stepValue = zoomToIntervalHash[zoom];

  const isWeek =
    stepValue === ZOOM_STRINGS.WEEK || stepValue === ZOOM_STRINGS.MONTH;

  // This is required to detect changes in column widths by depending on the
  // left value of the first item.
  const [firstStep] = steps;
  const left = getLeftOffsetFromDate(
    moment(firstStep).startOf(stepValue).valueOf()
  );

  const columnWidths = useMemo(
    () =>
      steps.map(
        (step) =>
          // End of current step (Start of next step)
          getLeftOffsetFromDate(
            moment(step).add(1, stepValue).startOf(stepValue).valueOf()
          ) -
          // Start of current step
          getLeftOffsetFromDate(moment(step).startOf(stepValue).valueOf())
      ),
    /*
     * `left` forces a recalculation when the result of `getLeftOffsetFromDate`
     * changes even though the other values do not change. Removing `left` from
     * the dependencies array results in the first render sometimes being
     * incorrect when mounting a new timeline (e.g. splitscreen) and when
     * resizing the timeline container. The same strategy is employed in
     * `formattingUtils`.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLeftOffsetFromDate, stepValue, steps, left]
  );

  const formattingUtils = useMemo(
    () =>
      formatSteps(
        steps,
        stepValue,
        getLeftOffsetFromDate,
        holidayDatesHash,
        isWeek
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLeftOffsetFromDate, holidayDatesHash, isWeek, stepValue, steps, left]
  );

  return {
    formattingUtils,
    columnWidths,
    getGroupDimensions
  };
};

export default useWorkloadDataProvider;

export const useIsProject = (project, capacity = {}, stepValue) => {
  const {
    activity_phase_schedule_bars: activityPhaseScheduleBars = emptyArray
  } = capacity;
  const OOOCache = useRef({});

  // clear cache if PTO bars change or zoom changes
  useEffect(() => {
    OOOCache.current = {};
  }, [activityPhaseScheduleBars, stepValue]);

  const checkStep = useCallback(
    (date) => {
      if (!OOOCache.current[date]) {
        OOOCache.current[date] = findMatchingRange(
          capacity,
          stepValue,
          date,
          project
        );
      }
      return OOOCache.current[date];
    },
    [capacity, project, stepValue]
  );
  return { checkStep };
};

export const useIsOOO = (capacity, stepValue) => {
  const OOOProject = useSelector(getOOOProject);
  return useIsProject(OOOProject, capacity, stepValue);
};

export const useIsWFH = (capacity, stepValue) => {
  const WFHProject = useSelector(getWFHProject);
  return useIsProject(WFHProject, capacity, stepValue);
};
