import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ReassignIcon = (props) => (
  <SvgIcon
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="28"
      height="28"
    >
      <rect width="28" height="28" rx="14" fill="#ccc" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="28" height="28" rx="14" fill="#F0F0F0" />
      <path
        d="M21.6002 24.5579C21.6002 20.4128 15.81 18.651 14.0942 18.651C12.3784 18.651 6.58813 20.4128 6.58813 24.5579C6.58813 25.2615 6.58813 28.3018 6.58813 28.3018H21.6002C21.6002 28.3018 21.6002 25.2615 21.6002 24.5579Z"
        fill="#CCCCCC"
        stroke="#CCCCCC"
        strokeWidth="1.9531"
      />
      <path
        d="M17.9142 11.4083C17.9142 13.518 16.2039 15.2283 14.0942 15.2283C11.9845 15.2283 10.2742 13.518 10.2742 11.4083C10.2742 9.29856 11.9845 7.58826 14.0942 7.58826C16.2039 7.58826 17.9142 9.29856 17.9142 11.4083"
        fill="#CCCCCC"
      />

      <path
        d="M17.9142 11.4083C17.9142 13.518 16.2039 15.2283 14.0942 15.2283C11.9845 15.2283 10.2742 13.518 10.2742 11.4083C10.2742 9.29856 11.9845 7.58826 14.0942 7.58826C16.2039 7.58826 17.9142 9.29856 17.9142 11.4083"
        fill="#CCCCCC"
        stroke="#CCCCCC"
        strokeWidth="1.9531"
      />
    </g>
  </SvgIcon>
);

export default ReassignIcon;
