import { useState } from 'react';
import {
  SmallOptionsContainer,
  SmallIconContainer,
  CustomCheckBoxContainer,
  CustomCheckBox,
  StyledHelpIconContainer
} from './styles';

import {
  WORK_PLAN_CALENDAR_OPTION_TYPES,
  WORK_PLAN_OPTION_TEXT
} from 'appConstants/workload';
import styled from 'styled-components';
import theme from 'theme';
import HelpIcon from 'icons/HelpIcon';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const WorkplanCalendarOptions = ({
  showAllDay,
  workplanCalendarOptionSetterTypes,
  workplanCalendarOptionValues
}) => {
  const tooltipText = {
    [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING]:
      'Setup Work Plans to default to <br/> full weeks from Settings.',
    [WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS]: 'Tooltip text TBD.',
    [WORK_PLAN_CALENDAR_OPTION_TYPES.IS_AUTO_RESCHEDULE]:
      'Mosaic will automatically extend the <br/> work plan if it has information that <br/> work was not done on the project.'
  };

  const setAllDay =
    workplanCalendarOptionSetterTypes[WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY];
  const setIncludeWeekends =
    workplanCalendarOptionSetterTypes[
      WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS
    ];
  const setWeekendsPredictConstant =
    workplanCalendarOptionSetterTypes[
      WORK_PLAN_CALENDAR_OPTION_TYPES.SET_WEEKENDS_PREDICT_CONSTANT
    ];
  const setIsTentative =
    workplanCalendarOptionSetterTypes[
      WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE
    ];
  const setIsWeeklyPlanning =
    workplanCalendarOptionSetterTypes[
      WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING
    ];

  const showTBDTooltip = false;
  const { autoRescheduleWorkplanDemo } = useFeatureFlags();
  // isAuthRescheduleChecked is only for demo
  const [isAuthRescheduleChecked, setIsAuthRescheduleChecked] = useState(false);

  return (
    <WorkplanCalendarOptionsContainer>
      <SmallOptionsContainer
        onClick={() => {
          setIsWeeklyPlanning(
            !workplanCalendarOptionValues[
              WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING
            ]
          );
        }}
      >
        <SmallIconContainer>
          <CustomCheckBoxContainer>
            <CustomCheckBox
              isChecked={
                workplanCalendarOptionValues[
                  WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING
                ]
              }
            />
          </CustomCheckBoxContainer>
        </SmallIconContainer>
        {
          WORK_PLAN_OPTION_TEXT[
            WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING
          ]
        }
        {showTBDTooltip && (
          <StyledHelpIconContainer
            data-for="app-tooltip"
            data-html
            data-class="center"
            data-effect="solid"
            data-arrow-color={theme.colors.colorDarkBlueTooltip}
            data-tip={
              tooltipText[WORK_PLAN_CALENDAR_OPTION_TYPES.IS_WEEKLY_PLANNING]
            }
          >
            <HelpIcon
              height="8px"
              width="8px"
              circleColor="#DBDBDB"
              questionMarkColor="#000"
              fillColor="#DBDBDB"
            />
          </StyledHelpIconContainer>
        )}
      </SmallOptionsContainer>
      <SmallOptionsContainer
        onClick={() => {
          setIsTentative(
            !workplanCalendarOptionValues[
              WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE
            ]
          );
        }}
        data-testid="calendar-tentative-checkbox"
      >
        <SmallIconContainer>
          <CustomCheckBoxContainer>
            <CustomCheckBox
              isChecked={
                workplanCalendarOptionValues[
                  WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE
                ]
              }
            />
          </CustomCheckBoxContainer>
        </SmallIconContainer>
        {WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.IS_TENTATIVE]}
      </SmallOptionsContainer>
      {showAllDay ? (
        <SmallOptionsContainer
          onClick={() => {
            setAllDay(
              !workplanCalendarOptionValues[
                WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY
              ]
            );
            setWeekendsPredictConstant();
          }}
          data-testid="calendar-allday-checkbox"
        >
          <SmallIconContainer>
            <CustomCheckBoxContainer>
              <CustomCheckBox
                isChecked={
                  workplanCalendarOptionValues[
                    WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY
                  ]
                }
              />
            </CustomCheckBoxContainer>
          </SmallIconContainer>
          {WORK_PLAN_OPTION_TEXT[WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY]}
        </SmallOptionsContainer>
      ) : (
        <SmallOptionsContainer
          onClick={() => {
            setIncludeWeekends(
              !workplanCalendarOptionValues[
                WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS
              ]
            );
            setWeekendsPredictConstant();
          }}
          data-testid="calendar-includeweekends-checkbox"
        >
          <SmallIconContainer>
            <CustomCheckBoxContainer>
              <CustomCheckBox
                isChecked={
                  workplanCalendarOptionValues[
                    WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS
                  ]
                }
              />
            </CustomCheckBoxContainer>
          </SmallIconContainer>
          {
            /* Not renamed to Include Days Off yet as that feature is not yet ready */
            WORK_PLAN_OPTION_TEXT[
              WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS
            ]
          }
          <StyledHelpIconContainer
            data-for="app-tooltip"
            data-html
            data-class="center"
            data-effect="solid"
            data-arrow-color={theme.colors.colorDarkBlueTooltip}
            data-tip={
              tooltipText[WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS]
            }
          >
            <HelpIcon
              height="8px"
              width="8px"
              circleColor="#DBDBDB"
              questionMarkColor="#000"
              fillColor="#DBDBDB"
            />
          </StyledHelpIconContainer>
        </SmallOptionsContainer>
      )}
      {autoRescheduleWorkplanDemo && (
        <SmallOptionsContainer
          onClick={() => setIsAuthRescheduleChecked((prev) => !prev)}
        >
          <SmallIconContainer>
            <CustomCheckBoxContainer>
              <CustomCheckBox isChecked={isAuthRescheduleChecked} />
            </CustomCheckBoxContainer>
          </SmallIconContainer>
          {
            WORK_PLAN_OPTION_TEXT[
              WORK_PLAN_CALENDAR_OPTION_TYPES.IS_AUTO_RESCHEDULE
            ]
          }
          <StyledHelpIconContainer
            data-for="app-tooltip"
            data-html
            data-class="center"
            data-effect="solid"
            data-arrow-color={theme.colors.colorDarkBlueTooltip}
            data-tip={
              tooltipText[WORK_PLAN_CALENDAR_OPTION_TYPES.IS_AUTO_RESCHEDULE]
            }
          >
            <HelpIcon
              height="8px"
              width="8px"
              circleColor="#DBDBDB"
              questionMarkColor="#000"
              fillColor="#DBDBDB"
            />
          </StyledHelpIconContainer>
        </SmallOptionsContainer>
      )}
    </WorkplanCalendarOptionsContainer>
  );
};

const WorkplanCalendarOptionsContainer = styled.div`
  margin-left: 16px;
`;

export default WorkplanCalendarOptions;
