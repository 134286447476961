import React from 'react';
import BatchSelectAll from './BatchSelectAll';
import TaskListFlags from './TaskListFlags';
import { DayTasksCircle } from './DayTasksCircle';
import {
  TaskListHeaderStyleWrapper,
  ListTitleWrapper,
  ListTitle,
  ListSubTitle,
  TaskListSectionHeaderWrapper,
  DayTasksCountWrapper
} from './styles';

const TaskListSectionHeader = ({
  isOnTeamProject,
  isOnHomeView,
  isOnInboxView,
  isOnCompletedView,
  isOnTodayView,
  listTitle,
  listSubTitle,
  tasksDue,
  shouldRenderGhostFlags,
  titleColor,
  taskIds,
  pastDueTasks,
  pastScheduledTasks,
  marginTop,
  taskCount,
  taskCountColor,
  taskCountBorderColor,
  taskCountBackgroundColor
}) => {
  const isToday = listTitle === 'Today';
  const isSemibold = isOnHomeView && isOnTodayView;
  return (
    <TaskListSectionHeaderWrapper
      isOnHomeView={isOnHomeView}
      isOnTeamProject={isOnTeamProject}
      isOnInboxView={isOnInboxView}
      isOnTodayView={isOnTodayView}
      isOnCompletedView={isOnCompletedView}
      marginTop={marginTop}
    >
      <TaskListHeaderStyleWrapper isOnTeamProject={isOnTeamProject}>
        <ListTitleWrapper>
          {/* <DayTasksCountWrapper>
            <DayTasksCircle
              tasksCount={taskCount}
              backgroundColor={taskCountBackgroundColor}
              borderColor={taskCountBorderColor}
              color={taskCountColor}
              dataForTooltip={listTitle}
            />
          </DayTasksCountWrapper> */}
          {(listTitle || listSubTitle) && (
            <ListTitleWrapper>
              <ListTitle titleColor={titleColor} isSemibold={isSemibold}>
                {listTitle}
              </ListTitle>
              <ListSubTitle>{listSubTitle}</ListSubTitle>
              {shouldRenderGhostFlags && (
                <TaskListFlags
                  isOnTaskListheader={true}
                  dueTasks={tasksDue}
                  dateName={listTitle}
                  pastDueTasks={isToday && pastDueTasks}
                  pastScheduledTasks={isToday && pastScheduledTasks}
                />
              )}
            </ListTitleWrapper>
          )}
        </ListTitleWrapper>
      </TaskListHeaderStyleWrapper>
      <BatchSelectAll
        taskIds={taskIds}
        isOnCompletedView={isOnCompletedView}
        isOnTodayView={isOnTodayView}
      />
    </TaskListSectionHeaderWrapper>
  );
};

export default React.memo(TaskListSectionHeader);
