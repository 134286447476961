import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { ActivityFeedContainer } from 'views';
import withRouterParams from './WithRouterParams';
import UpdatesNavigationTracker from 'views/Navigation/UpdatesNavigationTracker';
import TaskModalRouter from './TaskModalRouter';

class ActivityFeedRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }
  render() {
    const { match } = this.props;
    return (
      <>
        <UpdatesNavigationTracker />
        <ActivityFeedContainer>
          <Route
            path={`${match.url}/task/:taskId`}
            component={TaskModalRouter}
          />
        </ActivityFeedContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityFeedRouter))
);
