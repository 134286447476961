import { useSelector, useDispatch } from 'react-redux';
import { getSplitFlags } from 'selectors';
import { navigateToUnplannedModal } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';

const RequestsButton = ({ className = '', label = 'Requests' }) => {
  const dispatch = useDispatch();
  const { scopeRequests, workplanRequestsFlag } = useSelector(getSplitFlags);

  if (!(scopeRequests || workplanRequestsFlag)) return null;

  return (
    <StyledButton
      className={className}
      onClick={() =>
        dispatch(
          navigateToUnplannedModal({
            unplannedViewType: workplanRequestsFlag ? 'workplans' : 'scopes',
            unplannedSubViewType: 'requests'
          })
        )
      }
    >
      {label}
    </StyledButton>
  );
};

export default RequestsButton;

/* ------------------------------------ - ----------------------------------- */

export const StyledButton = styled.button`
  color: ${theme.colors.colorRoyalBlue};
  background: transparent;
  border: none;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 13px;
  border-radius: 20px;
  position: relative;
  white-space: nowrap;
  &:hover {
    filter: brightness(98%);
    font-weight: 700;
  }

  &.planner-team-builder-button {
    margin-left: 20px;
    top: -1px;
  }
`;
