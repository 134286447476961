import { SimpleFilterDropdown } from './SimpleFilterDropdown';
import { useBillableFilter } from 'FilterModule/hooks/useBillableFilter';
import { FilterListTypeDropdown } from 'FilterModule/types';

export const BillableFilterDropdown: FilterListTypeDropdown = ({
  renderToggle
}) => {
  const { updateSelectedItems, optionsArray, selectedItems } =
    useBillableFilter();

  return (
    <SimpleFilterDropdown
      items={optionsArray}
      selectedItems={selectedItems}
      updateSelectedItems={updateSelectedItems}
      listHeight={70}
      listWidth={150}
      isSearchEnabled={false}
      renderToggle={({ isOpen }) =>
        renderToggle({
          isOpen,
          customLabel:
            selectedItems.length === optionsArray.length
              ? 'All Time'
              : undefined
        })
      }
      isBatchActionsHidden
    />
  );
};
