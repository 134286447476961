import styled from 'styled-components';
import ProjectTeamListBody from './ProjectTeamListBody';
import ProjectTeamListHeader from './ProjectTeamListHeader';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';

const ProjectTeamListContainer = ({ isLoading }: { isLoading }) => {
  return (
    <>
      <ProjectTeamListHeader />
      <SkeletonLoader isLoading={isLoading} count={15}>
        <ProjectTeamListBody />
      </SkeletonLoader>
    </>
  );
};

export default ProjectTeamListContainer;

const Container = styled.div`
  width: 20%;
`;
