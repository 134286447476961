import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import {
  fetchCapacities,
  fetchHolidays,
  fetchTeamCapacity
} from 'CapacityModule/actionCreators';
import { getTeamCapacity } from 'CapacityModule/selectors';
import { RequestPriority } from 'appConstants/api';

const useFetchCapacitiesAndHolidays = ({
  accountIds,
  requestPriority = RequestPriority.High
}) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const teamCapacity = useSelector(getTeamCapacity);

  const accountIdsEquality = useMemo(
    () => JSON.stringify(accountIds),
    [accountIds]
  );

  useEffect(() => {
    if (accountIds?.length > 0 && teamId) {
      dispatch(
        fetchCapacities({
          accountIds,
          teamId,
          meta: { requestPriority }
        })
      );
      dispatch(fetchTeamCapacity({ teamId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, teamId, accountIdsEquality, requestPriority]);

  useEffect(() => {
    if (teamId && teamCapacity?.id) {
      dispatch(fetchHolidays({ meta: { requestPriority } }));
    }
  }, [dispatch, teamCapacity, teamId, requestPriority]);
};

export default useFetchCapacitiesAndHolidays;
