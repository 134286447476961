import { VIEW_BY as APP_VIEW_BY } from 'appConstants/workload';
import keyBy from 'lodash/keyBy';

export const VIEW_BY = {
  MEMBERS: APP_VIEW_BY.MEMBERS,
  PROJECTS: APP_VIEW_BY.PROJECTS,
  POSITIONS: APP_VIEW_BY.POSITIONS,
  ORGANIZATION: APP_VIEW_BY.ORGANIZATION
};

export const DISPLAY_FORMAT = {
  DOLLARS: 'DOLLARS',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  PERCENTAGE: 'PERCENTAGE'
};

export const toolTipFormatType = {
  [DISPLAY_FORMAT.DOLLARS]: 'currency',
  [DISPLAY_FORMAT.HOURS]: 'hour',
  [DISPLAY_FORMAT.DAYS]: 'day',
  [DISPLAY_FORMAT.PERCENTAGE]: 'percentage'
};

export const varianceToolTipFormatType = {
  [DISPLAY_FORMAT.DOLLARS]: 'currency',
  [DISPLAY_FORMAT.HOURS]: 'hour',
  [DISPLAY_FORMAT.DAYS]: 'day'
};

export const DISPLAY_FORMAT_UNIT = {
  DOLLARS: '$',
  HOURS: 'h',
  DAYS: 'd'
};

export const DISPLAY_FORMAT_QUANTITY = {
  [DISPLAY_FORMAT.DOLLARS]: 'cost',
  [DISPLAY_FORMAT.HOURS]: 'hours',
  [DISPLAY_FORMAT.DAYS]: 'days'
};

export const VARIANCE_TYPE = {
  TIME: 'TIME',
  BUDGET: 'BUDGET',
  SCHEDULE: 'SCHEDULE'
};

// Default values for variance reports
export const VARIANCE_DISPLAY_FORMAT = {
  [VARIANCE_TYPE.TIME]: DISPLAY_FORMAT.HOURS,
  [VARIANCE_TYPE.BUDGET]: DISPLAY_FORMAT.DOLLARS,
  [VARIANCE_TYPE.SCHEDULE]: DISPLAY_FORMAT.DAYS
};

export const ESTIMATED_OR_PLANNED = {
  ESTIMATED: 'estimated',
  PLANNED: 'planned',
  FEE: 'fee',
  TARGET: 'target',
  REMAINING: 'remaining',
  SPENT_PLANNED: 'spentPlanned'
};

export const ESTIMATED_OR_PLANNED_DISPLAY = {
  [ESTIMATED_OR_PLANNED.ESTIMATED]: {
    dropdown: 'Member Budget',
    column: 'ESTIMATED'
  },
  [ESTIMATED_OR_PLANNED.PLANNED]: {
    dropdown: 'Planned Time',
    column: 'PLANNED'
  },
  [ESTIMATED_OR_PLANNED.FEE]: {
    dropdown: 'Project Fee',
    column: 'FEE'
  },
  [ESTIMATED_OR_PLANNED.TARGET]: {
    dropdown: 'Project Budget',
    column: 'BUDGET'
  }
};

export const FILTER_PARAMS = [
  'account_ids',
  'project_ids',
  'position_ids',
  'phase_names'
];

export const VARIANCE_NO_BUDGET_LABEL = 'No Variance';
// budget report
export const NO_BUDGET_LABEL = 'No Budget';

export const GROUP_BY = {
  POSITIONS: 'POSITIONS',
  MEMBERS: 'MEMBERS'
};

export const BUDGET_REPORT_COLUMN_SELECTOR_OPTIONS_ARRAY = [
  {
    label: 'Spent + Planned',
    value: ESTIMATED_OR_PLANNED.SPENT_PLANNED,
    menuClassName: 'report-column-options'
  },
  {
    label: 'Remaining',
    value: ESTIMATED_OR_PLANNED.REMAINING,
    menuClassName: 'report-column-options'
  }
];
export const BUDGET_REPORT_COLUMN_SELECTOR_OPTIONS_HASH = keyBy(
  BUDGET_REPORT_COLUMN_SELECTOR_OPTIONS_ARRAY,
  'value'
);

const BUDGET_REPORT_DISPLAY_OPTIONS = {
  BREAKDOWN: 'breakdown',
  BAR: 'bar',
  GRAPH: 'graph'
};

export const BUDGET_REPORT_DISPLAY_OPTIONS_ARRAY = [
  BUDGET_REPORT_DISPLAY_OPTIONS.BREAKDOWN,
  BUDGET_REPORT_DISPLAY_OPTIONS.BAR,
  BUDGET_REPORT_DISPLAY_OPTIONS.GRAPH
];

export const BUDGET_REPORT_DISPLAY_OPTIONS_HASH = {
  [BUDGET_REPORT_DISPLAY_OPTIONS.BREAKDOWN]: {
    label: 'Breakdown'
  },
  [BUDGET_REPORT_DISPLAY_OPTIONS.BAR]: {
    label: 'Bar'
  },
  [BUDGET_REPORT_DISPLAY_OPTIONS.GRAPH]: {
    label: 'Graph'
  }
};
