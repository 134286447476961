import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import {
  makeGetOwnBudgetTotals,
  getMemberBudgets
} from 'BudgetModule/selectors';
import { MembershipListItem, AddMemberToPhaseListItem } from '../types';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { ActivityPhase } from 'ActivityPhaseModule/models/activityPhase';
import { FormattedActivityPhase } from 'appUtils/hooks/useActivityPhases/types';
import useActivityPhases from 'appUtils/hooks/useActivityPhases/useActivityPhases';
import { serializeId } from 'appUtils';
import { getAccountTotalsFromFormattedPhaseTotals } from 'BudgetModule/utils/projectAccounts';

const useProjectTeamMembersListItemsBuilder = ({
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState,
  projectId
}: UseNestedCollapseHookReturnedValues & { projectId: number }) => {
  const teamMembershipByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );
  const memberBudgets = useAppSelector(getMemberBudgets);
  const {
    getActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId
  } = useActivityPhases({ projectId });

  const getBudgetTotals = useMemo(makeGetOwnBudgetTotals, []);

  const { phaseTotals } = useAppSelector((state) =>
    getBudgetTotals(state, {
      filterStateId: FilterStateIds.fetchPhaseTotals,
      shouldFormatData: true
    })
  );

  const projectTeamMembersListItemsBuilder = useCallback(
    ({
      isParentOpen,
      order: activityPhaseMembershipIdOrder,
      phaseId,
      phase,
      activityPhase,
      hasActivityPhases,
      openBudgetModal
    }: {
      isParentOpen?: boolean;
      order: number[];
      phaseId: number;
      phase: Phase;
      hasActivityPhases: boolean;
      activityPhase: FormattedActivityPhase | ActivityPhase;
      openBudgetModal: () => void;
    }) => {
      const list = activityPhaseMembershipIdOrder.reduce(
        (
          acc: (MembershipListItem | AddMemberToPhaseListItem)[],
          activityPhaseMembershipId: number,
          index
        ) => {
          const membership =
            getActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId(
              { activityPhaseMembershipId, activityPhaseId: activityPhase.id }
            );

          // Spec: Do not show discarded / archived member or unassigned role here
          if (
            membership &&
            membership.account_id &&
            teamMembershipByAccountId[membership.account_id] &&
            !membership.discarded_at
          ) {
            const serializedMembershipId = serializeId({
              itemType: LIST_ITEM_TYPES.Membership,
              id: membership.id,
              ids: null
            });

            const accountTotals = getAccountTotalsFromFormattedPhaseTotals({
              formattedPhaseTotals: phaseTotals,
              phase,
              activityId: activityPhase.activity_id,
              activityPhaseMembershipId
            });

            const memberListItem = {
              id: serializedMembershipId,
              itemType: LIST_ITEM_TYPES.Membership,
              isOpen: true,
              isLast: activityPhaseMembershipIdOrder.length - 1 === index,
              parentId: undefined,
              toggleId: undefined,
              toggleCollapse: undefined,
              isParentOpen,
              /* -------------------------------------------------------------------------- */
              teamMembershipByAccountId,
              membership,
              accountTotals,
              projectId,
              hasActivityPhases,
              memberBudget: memberBudgets[membership.member_budget_id],
              activityPhaseMembership: membership,
              openBudgetModal
            };

            acc.push(memberListItem);
          }

          return acc;
        },
        []
      );

      // Add prompt text to add phase member by clicking + icon
      if (isParentOpen) {
        list.unshift({
          id: serializeId({
            itemType: hasActivityPhases
              ? 'add-member-to-activity-phase'
              : 'add-member-to-phases',
            id: hasActivityPhases ? activityPhase.id : phaseId,
            ids: null
          }),
          itemType: LIST_ITEM_TYPES.AddMemberToPhase,
          isOpen: true,
          parentId: undefined,
          toggleId: undefined,
          toggleCollapse: undefined,
          isParentOpen
        });
      }

      return list;
    },
    [
      getActivityPhaseMembershipByActivityPhaseIdAndActivityPhaseMembershipId,
      memberBudgets,
      phaseTotals,
      projectId,
      teamMembershipByAccountId
    ]
  );

  return projectTeamMembersListItemsBuilder;
};

export default useProjectTeamMembersListItemsBuilder;
