import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import uuid from 'uuid';
import { serializeId } from 'appUtils';
import { usePopover } from 'components/Popover/usePopover';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { DependencyItemRow } from './DependencyItemRow';
import { TextButton } from './styles';
import {
  AvailableOption,
  DependableItem,
  DependencyItem,
  DepedencyListProps
} from 'components/Dependency/types';
import { AVAILABLE_OPTIONS, AVAILABLE_OPTIONS_LABEL } from './constants';
import {
  DefaultDependencyItemRenderer,
  DefaultBaseDependencyTypeRenderer,
  DefaultTargetDependencyTypeRenderer
} from './DefaultRenderers';
import omit from 'lodash/omit';
import theme from 'theme';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import { usePrevious } from 'appUtils/hooks/usePrevious';
import isEqual from 'lodash/isEqual';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import moment from 'moment';
import { ValueOf } from 'type-fest';

type InternalDependenciesState = Array<
  Partial<DependencyItem> & {
    key: string;
  }
>;

const targetOptions = [AVAILABLE_OPTIONS.start, AVAILABLE_OPTIONS.end];

export const DependencyList = ({
  dependencies = [],
  dependableItems = [],
  baseDependableTypeLabel,
  availableBaseOptions = [AVAILABLE_OPTIONS.start, AVAILABLE_OPTIONS.end],
  targetDependableTypeLabel,
  // locks target option to the selected base option
  // ex: Base: Start -> Target: {start} OR Base: End -> Target: {end}
  lockTargetOption = false,
  // If lockTargetOption true, will auto select defaultTargetItem as the selected dependable item
  defaultTargetItem,
  onChange,
  renderDependableItem = DefaultDependencyItemRenderer,
  renderBaseDepenencyTypeItem = DefaultBaseDependencyTypeRenderer,
  renderTargetDepenencyTypeItem = DefaultTargetDependencyTypeRenderer,
  maxNumDependenciesOverride,
  shouldUseSingleLineSetter,
  addButtonTooltipContent
}: DepedencyListProps) => {
  const [tempTargetAnchorEl, setTempTargetAnchorEl] = useState<
    HTMLElement | undefined
  >();

  const [tempSelectedDependableItem, setTempSelectedDependableItem] = useState<
    DependableItem | undefined
  >();

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const [editingItemKey, setEditingItemKey] = useState<string | undefined>();

  const prevDependencies = usePrevious(dependencies);

  const [internalDependencies, setInternalDependencies] =
    useState<InternalDependenciesState>(
      dependencies.map((item) => ({
        ...item,
        key: serializeId({
          itemType: item.dependableItem.dependableType,
          ids: [
            item.baseOption,
            item.dependableItem.dependableId,
            item.targetOption
          ],
          id: undefined
        })
      }))
    );
  useEffect(() => {
    if (
      dependencies &&
      prevDependencies &&
      !isEqual(dependencies, prevDependencies)
    ) {
      setInternalDependencies(
        dependencies.map((item) => ({
          ...item,
          key: serializeId({
            itemType: item.dependableItem.dependableType,
            ids: [
              item.baseOption,
              item.dependableItem.dependableId,
              item.targetOption
            ],
            id: undefined
          })
        }))
      );
    }
  }, [dependencies, prevDependencies]);

  const { MultiStepFlyout, openFlyout, closeFlyout } = useMultiStepFlyout();
  const {
    Popover: BasePopover,
    openPopover: openBasePopover,
    closePopover: closeBasePopover,
    isOpen: isBasePopoverOpen
  } = usePopover();
  const {
    Popover: TargetPopover,
    openPopover: openTargetPopover,
    closePopover: closeTargetPopover
  } = usePopover();

  const isValidDependency = (
    partialDependency: Partial<DependencyItem>
  ): boolean => {
    return Boolean(
      partialDependency.baseOption &&
        partialDependency.dependableItem &&
        partialDependency.targetOption
    );
  };

  const updateInternalDependencies = (
    key: string,
    values: Partial<DependencyItem>
  ) => {
    const nextDependencies = internalDependencies.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          ...values
        };
      }
      return item;
    });
    // update internal state
    setInternalDependencies(nextDependencies);

    const depedencyToUpdate = nextDependencies.find((item) => item.key === key);

    if (depedencyToUpdate && isValidDependency(depedencyToUpdate)) {
      onChange &&
        onChange(
          nextDependencies.flatMap(({ key, ...dependency }) => {
            // filter invalid dependency
            if (isValidDependency(dependency)) {
              return [dependency as DependencyItem];
            }
            return [];
          })
        );
    }
  };

  const addButtonRef = useRef<HTMLButtonElement>(null);
  const depedencyListContainerRef = useRef<HTMLDivElement>(null);
  const currentSelectedBaseOptions = internalDependencies.map(
    ({ baseOption }) => baseOption
  );

  /*
    Selected = this option is selected in the selected dependency item
    Selected dependency item base option popover will bold the option text
  */
  const isBaseOptionSelected = (baseOption: AvailableOption) => {
    return currentSelectedBaseOptions.includes(baseOption);
  };

  /* 
    Disabled = this option is selected in another dependency item
    Base option from popovers other than the selected dependency item will be disabled  
  */
  const isBaseOptionDisabled = (baseOption: AvailableOption) => {
    const disabled = internalDependencies.some(
      (dependency) =>
        dependency.key !== editingItemKey &&
        dependency.baseOption === baseOption
    );
    return disabled;
  };

  const handleAddDependencyClick = (event: MouseEvent<HTMLButtonElement>) => {
    const key = uuid.v4();
    const target = depedencyListContainerRef?.current ?? undefined;

    setInternalDependencies((prev) => [
      ...(prev ?? []),
      {
        key,
        baseOption:
          availableBaseOptions.length === 1 // force initial base option
            ? availableBaseOptions[0]
            : undefined
      }
    ]);

    if (shouldUseSingleLineSetter) {
      handleBaseClick({ event, target, key });
    }
  };

  const handleBaseClick = ({
    event,
    target,
    key
  }: {
    event: MouseEvent<HTMLButtonElement>;
    target?: HTMLElement;
    key: string;
  }) => {
    if (isBasePopoverOpen) {
      return closeBasePopover();
    }
    if (availableBaseOptions.length > 1) {
      setEditingItemKey(key);
      openBasePopover({ event, target });
    }
  };

  const handleTargetClick = ({
    event,
    key
  }: {
    event: MouseEvent<HTMLButtonElement>;
    key: string;
  }) => {
    setEditingItemKey(key);
    setTempTargetAnchorEl(event.currentTarget);
    closeBasePopover();
    openFlyout({ event });
  };

  const handleBaseSelect = ({ option }: { option: AvailableOption }) => {
    if (editingItemKey) {
      const newInternalDependencyValue = {
        baseOption: option,
        ...(lockTargetOption && { targetOption: option }),
        ...(lockTargetOption &&
          defaultTargetItem && {
            dependableItem: defaultTargetItem
          })
      };

      updateInternalDependencies(editingItemKey, newInternalDependencyValue);
    }
    closeBasePopover();
  };

  const handleDependableItemSelect = (
    event: MouseEvent<HTMLDivElement>,
    { item }: { item: DependableItem }
  ) => {
    if (tempTargetAnchorEl && !lockTargetOption) {
      setTempSelectedDependableItem(item);
      openTargetPopover({ target: tempTargetAnchorEl });
    }

    if (lockTargetOption && editingItemKey) {
      updateInternalDependencies(editingItemKey, {
        dependableItem: item
      });
      closeFlyout();
    }
  };

  const handleTargetSelect = ({ option }: { option: AvailableOption }) => {
    if (editingItemKey) {
      updateInternalDependencies(editingItemKey, {
        targetOption: option,
        dependableItem: tempSelectedDependableItem
      });
    }
    closeFlyout();
    closeTargetPopover();
  };

  const handleCloseToCancel = (e) => {
    /**
     * handleCloseToCancel is also called when clicked outside of flyout popover area
     *
     * This includes the target options menu. Prevent closing of flyout when clicked
     * over target options menu to allow target start/end option select.
     */
    if (
      e?.target?.className?.includes?.('dependency-target-item') ||
      e?.target?.className?.includes?.('dependency-target-options-container')
    )
      return;

    closeFlyout();
    setTempSelectedDependableItem(undefined);
  };

  const handleDependencyClear = (key: string) => {
    const filteredDependencies = internalDependencies.flatMap((item) => {
      if (item.key !== key && isValidDependency(item)) {
        return [omit(item, 'key')];
      }
      return [];
    });

    onChange && onChange(filteredDependencies as Array<DependencyItem>);
    setInternalDependencies((prev) => prev.filter((item) => item.key !== key));
  };

  const getPlaceholderText = (dependableLabel?: string): string => {
    if (dependableLabel && lockTargetOption)
      return `Select ${targetDependableTypeLabel}`;

    if (dependableLabel) return `Select ${targetDependableTypeLabel} Start/End`;

    return 'Select Start/End';
  };

  /**
   * Partially set dependency is when user clicks + Add button to add a new dependency
   * but has not yet selected all the required fields:
   * - base start / end option
   * - target dependable item
   * - target start / end option
   */

  const hasPartiallySetDependency = useMemo(
    () =>
      internalDependencies.some(
        (internalDependency) =>
          !internalDependency.baseOption ||
          !internalDependency.targetOption ||
          !internalDependency.dependableItem
      ),
    [internalDependencies]
  );

  const getTargetButtonLabel = ({
    dependableItem,
    targetOption
  }: {
    dependableItem?: DependableItem;
    targetOption?: string;
  }): string => {
    if (targetOption && !lockTargetOption)
      return `${AVAILABLE_OPTIONS_LABEL[targetOption]} Date`;

    // no need to show target option select, since its locked to base option
    if (lockTargetOption && dependableItem) return '';

    return getPlaceholderText(baseDependableTypeLabel);
  };

  /**
   * This function will check the selected dependable item's given target option.
   * Returns true jf selecting the target option will cause invalid dates
   * (start date after end date) with another dependency.
   *
   * False otherwise.
   */
  const getTargetOptionWillCauseInvalidDates = (
    targetOption: ValueOf<typeof AVAILABLE_OPTIONS>
  ) => {
    // Find the dependency being edited to get its base option
    const selectedEditingDependency = internalDependencies.find(
      (internalDependency) => internalDependency.key === editingItemKey
    );

    const selectedBaseOptionType = selectedEditingDependency?.baseOption;

    const selectedTargetDependableItemDate =
      targetOption === AVAILABLE_OPTIONS.start
        ? tempSelectedDependableItem?.startDate
        : targetOption === AVAILABLE_OPTIONS.end
        ? tempSelectedDependableItem?.endDate
        : null;

    if (!selectedBaseOptionType || !selectedTargetDependableItemDate)
      return false;

    const invalidDate = internalDependencies.some((internalDependency) => {
      const internalDependableItem = internalDependency.dependableItem;

      const internalDependencyTargetDate =
        internalDependency.targetOption === AVAILABLE_OPTIONS.start
          ? internalDependableItem?.startDate
          : internalDependency.targetOption === AVAILABLE_OPTIONS.end
          ? internalDependableItem?.endDate
          : null;

      if (!internalDependencyTargetDate || !internalDependableItem)
        return false;

      if (
        selectedBaseOptionType === AVAILABLE_OPTIONS.start &&
        internalDependency.baseOption === AVAILABLE_OPTIONS.end
      )
        return moment(selectedTargetDependableItemDate).isAfter(
          internalDependencyTargetDate
        );

      if (
        selectedBaseOptionType === AVAILABLE_OPTIONS.end &&
        internalDependency.baseOption === AVAILABLE_OPTIONS.start
      )
        return moment(selectedTargetDependableItemDate).isBefore(
          internalDependencyTargetDate
        );
    });

    return invalidDate;
  };

  const maxNumDependencies =
    maxNumDependenciesOverride ?? availableBaseOptions.length;
  const shouldShowAddButton =
    internalDependencies.length < maxNumDependencies &&
    !hasPartiallySetDependency;
  const shouldDisableAddButton = dependableItems.length === 0;

  /**
   * Offset the base popover position to the right when auto opening the base popover
   * after clicking +Add button on single line dependency setter.
   *
   * Immedietely after clicking +Add, the only item in internalDependencies has
   * baseOption undefined.
   *
   */
  const shouldOffsetBasePopover =
    shouldUseSingleLineSetter && !internalDependencies[0]?.baseOption;

  return (
    <>
      <DependencySetterLabel>
        <DependencyLinkIconContainer>
          <DependencyLinkIcon className="dependency-list" />
        </DependencyLinkIconContainer>
        DEPENDENCY
      </DependencySetterLabel>
      <DepedencyListContainer ref={depedencyListContainerRef}>
        {internalDependencies.map(
          ({ key, dependableItem, baseOption, targetOption }) => (
            <DependencyItemRow
              key={key}
              baseButtonProps={{
                label: baseOption
                  ? AVAILABLE_OPTIONS_LABEL[baseOption]
                  : getPlaceholderText(),
                onClick: (event) => handleBaseClick({ event, key }),
                tooltip:
                  availableBaseOptions.length === 1
                    ? `Only ${availableBaseOptions[0]} option is available`
                    : ''
              }}
              targetButtonProps={{
                dependableItemType: dependableItem?.dependableType ?? '',
                dependableItemLabel:
                  dependableItem?.label === 'Default 1'
                    ? 'Project Schedule'
                    : dependableItem?.label ?? '',
                label: getTargetButtonLabel({ dependableItem, targetOption }),
                onClick: (event) => handleTargetClick({ event, key }),
                disabled: !!(lockTargetOption && defaultTargetItem)
              }}
              onRemove={() => handleDependencyClear(key)}
              shouldUseSingleLineSetter={shouldUseSingleLineSetter}
            />
          )
        )}
        {shouldShowAddButton && (
          <AddButtonContainer
            data-for={'app-tooltip'}
            data-tip={
              addButtonTooltipContent ??
              `Add Another ${targetDependableTypeLabel} to Set Dependency`
            }
            data-tip-disable={!shouldDisableAddButton}
            data-effect="solid"
          >
            <TextButton
              ref={addButtonRef}
              data-testid="add-dependency-button"
              onClick={handleAddDependencyClick}
              disabled={shouldDisableAddButton}
            >
              + Add
            </TextButton>
          </AddButtonContainer>
        )}
      </DepedencyListContainer>

      <BasePopover
        className="dependency-list-popover"
        offset={shouldOffsetBasePopover ? 32 : 0}
        insideAnotherPopover
      >
        <OptionsContainer data-testid={'base-dependency-type-options'}>
          {availableBaseOptions.map((option) => (
            <OptionButton
              key={option}
              onClick={() => {
                handleBaseSelect({
                  option
                });
              }}
              className={cn({
                selected: isBaseOptionSelected(option)
              })}
              disabled={isBaseOptionDisabled(option)}
            >
              {renderBaseDepenencyTypeItem({
                option,
                dependableTypeLabel: baseDependableTypeLabel
              })}
            </OptionButton>
          ))}
        </OptionsContainer>
      </BasePopover>
      <MultiStepFlyout
        copy={{}}
        items={dependableItems}
        idKey="id"
        renderItem={renderDependableItem}
        hideFooter
        hideClose
        editDisabled
        itemHeight={56}
        listWidth={200}
        isWhite
        noHeader
        popoverClassName={'dependable-item-list'}
        insideAnotherPopover
        closePopover={handleCloseToCancel}
        handleSelect={handleDependableItemSelect}
        className="dependency-list-popover"
        isItemUnSelectable={(item) => !item.startDate}
      />
      <TargetPopover
        className={cn(
          'dependency-type-popover',
          'target',
          'dependency-list-popover'
        )}
      >
        <OptionsContainer
          data-testid="target-dependency-type-options"
          className="dependency-target-options-container"
        >
          {targetOptions.map((option) => {
            const isHidden =
              (option === AVAILABLE_OPTIONS.start &&
                !tempSelectedDependableItem?.startDate) ||
              (option === AVAILABLE_OPTIONS.end &&
                !tempSelectedDependableItem?.endDate);

            const willCauseInvalidDates =
              getTargetOptionWillCauseInvalidDates(option);

            return tempSelectedDependableItem && !isHidden ? (
              <OptionButton
                key={option}
                onClick={() => handleTargetSelect({ option })}
                disabled={willCauseInvalidDates}
              >
                {renderTargetDepenencyTypeItem({
                  option,
                  dependableItem: tempSelectedDependableItem,
                  dependableTypeLabel: targetDependableTypeLabel
                })}
              </OptionButton>
            ) : null;
          })}
        </OptionsContainer>
      </TargetPopover>
    </>
  );
};

const DepedencyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding-left: 13px;
`;

const AddButtonContainer = styled.div`
  margin-left: 20px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const OptionButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &.selected:not(:disabled) div {
    font-weight: 600;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled div {
    color: ${({ theme }) => theme.colors.colorLightGray15};
  }
  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.colors.colorTranslucentGray4};
  }
`;

const DependencySetterLabel = styled.div`
  color: ${theme.colors.colorLightGray15};
  font-weight: 600;
  font-size: 12px;
  width: 288px;
  margin: auto;
  position: relative;
`;

const DependencyLinkIconContainer = styled.div`
  margin-right: 2px;
  margin-bottom: -1px;
  display: inline-block;
`;
