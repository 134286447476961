import snakeCase from 'lodash/snakeCase';
import isPlainObject from 'lodash/isPlainObject';

const twoDecimalFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

const twoDecimalFormatWithoutGrouping = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  useGrouping: false
});

const withNaNCheck = (fn, num) => {
  const parsed = parseFloat(num);
  return isNaN(parsed) ? '' : fn(parsed);
};
export const formatNumWithMaxTwoDecimals = (
  num,
  disableThousandSeparator = false
) =>
  withNaNCheck(
    disableThousandSeparator
      ? twoDecimalFormatWithoutGrouping.format
      : twoDecimalFormat.format,
    num
  );

const oneDecimalFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 1,
  minimumFractionDigits: 0
});

const oneDecimalFormatWithoutGrouping = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 1,
  minimumFractionDigits: 0,
  useGrouping: false
});

export const formatNumWithMaxOneDecimal = (
  num,
  disableThousandSeparator = false
) =>
  withNaNCheck(
    disableThousandSeparator
      ? oneDecimalFormatWithoutGrouping.format
      : oneDecimalFormat.format,
    num
  );

export const formatNumToNearestHalf = (num) =>
  formatNumWithMaxOneDecimal(Math.round(num * 2) / 2);

const compactFormat = new Intl.NumberFormat('en', {
  notation: 'compact'
});

export const formatNumberWithSymbol = (num) =>
  compactFormat.format(parseFloat(num));

/**
 * Returns the item back with all object keys (INCLUDING NESTED OBJECTS AND OBJECTS WITHIN ARRAYS)
 * converted to snake case.
 * @param {any} item the item to be converted
 * @returns a copy of the item with snake case keys
 */
export const convertKeysToSnakeCase = (item) => {
  if (Array.isArray(item)) {
    return item.map((subItem) => convertKeysToSnakeCase(subItem));
  } else if (isPlainObject(item)) {
    return Object.entries(item).reduce((acc, cur) => {
      const [key, value] = cur;
      const snakeCaseKey = snakeCase(key);

      if (typeof value === 'object' && value !== null) {
        // value is array or object
        acc[snakeCaseKey] = convertKeysToSnakeCase(value);
      } else {
        acc[snakeCaseKey] = value;
      }
      return acc;
    }, {});
  } else {
    return item;
  }
};

export const formatOnSuccess = (onSuccess) => {
  if (typeof onSuccess === 'function') {
    return [
      {
        successAction: onSuccess
      }
    ];
  } else if (isPlainObject(onSuccess)) {
    return [onSuccess];
  } else {
    return onSuccess;
  }
};

export const makeAttachmentPayload = ({ body = {}, files }) => {
  const payload = new FormData();
  if (files) {
    Object.entries(body).forEach(([key, value]) => {
      value && payload.append(key, value);
    });
    files &&
      files.forEach((file, i) => {
        payload.append(`file_${i}`, file);
      });
  }
  return payload;
};

export const parseNull = (text) => (text === 'null' ? null : text);

/**
 *
 * @param {number} number
 * @returns If number is 0-9, return the number in words.
 *          Else, return the number converted to string.
 */
export const format0To9ToWords = (number) => {
  const numberMap = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine'
  ];

  return numberMap[number] ?? number.toString();
};

/**
 *
 * @param {string} digits - a number in string form
 * @param {number} baseFontSize - largest font size it can have
 * @param {number} threshold - largest number of digits before reducing font size
 * @param {number} factor - multiplier of how much to reduce by
 * @returns font size for digits based on it's length
 */
export const calcFontSizeFromDigits = (
  digits = '',
  baseFontSize,
  threshold,
  factor
) => {
  const digitsWithoutDecimal = `${digits}`.replace('.', '');
  if (digitsWithoutDecimal.length <= threshold) {
    return baseFontSize;
  } else {
    return baseFontSize - factor * (digitsWithoutDecimal.length - threshold);
  }
};

export const formatNumbersToWords = (number) => {
  const numberMap = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine'
  };

  return numberMap[number];
};

export * from './formatUtilsTyped';
