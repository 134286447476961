import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import { useEffect } from 'react';
import { Field, Label, SettingsList } from 'SettingsModule/components/styles';
import { BudgetTrackingField } from './Fields/BudgetTrackingField';
import { CurrencyField } from './Fields/CurrencyField';
import { PhaseReferenceField } from './Fields/PhaseReferenceField';
import { BudgetTypeField } from './Fields/BudgetTypeField';
import { ScopeField } from './Fields/ScopeField';
import { BillRateTypeField } from './Fields/BillRateTypeField';
import { InlineHelpIcon } from './InlineHelpIcon';
import { BudgetTypeVariant } from 'BudgetModule/components/BudgetModal/BudgetSettingsModal/Fields/BudgetTypeField';
import { ContractTypeVariant } from 'BudgetModule/components/BudgetModal/BudgetSettingsModal/Fields/ContractTypeField';
import { ContractTypeField } from './Fields/ContractTypeField';
import { BudgetTrackingType } from 'BudgetModule/models/BudgetViewSettings';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

export const SettingsTab = () => {
  const { hoursOnlyFlag, teamCurrencySetting } = useFeatureFlags();

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <div
      className="scrollbar"
      style={{ maxHeight: '100%', overflow: 'scroll', width: '100%' }}
    >
      <SettingsList>
        {hoursOnlyFlag && (
          <>
            <Label>Default Budget Tracking</Label>
            <Field>
              <BudgetTrackingField />
            </Field>
          </>
        )}
        {false && (
          <>
            <>
              <Label
                {...defaultTooltipProps}
                data-class="center mw-250 w-100vw"
                data-tip="Scope is high-level work to be completed over a period of time."
              >
                <span>
                  Scope
                  <InlineHelpIcon />
                </span>
              </Label>
              <Field>
                <ScopeField />
              </Field>
            </>
            <>
              <Label>Phase Reference #</Label>
              <Field>
                <PhaseReferenceField />
              </Field>
            </>
          </>
        )}
        {teamCurrencySetting && (
          <>
            <Label>Default Currency</Label>
            <Field>
              <CurrencyField />
            </Field>
          </>
        )}
        <>
          <Label>Default Time Cost Tracking</Label>
          <Field>
            <BillRateTypeField />
          </Field>
        </>
        {false && (
          <>
            <>
              <Label>Default Phase Budget</Label>
              <Field>
                <BudgetTypeField
                  budgetTracking={BudgetTrackingType.Currency}
                  variant={BudgetTypeVariant.Phase}
                />
              </Field>
            </>
            <>
              <Label>Default Phase Contract Type</Label>
              <Field>
                <ContractTypeField variant={ContractTypeVariant.Phase} />
              </Field>
            </>
            <>
              <Label>Default Activity Phase Budget</Label>
              <Field>
                <BudgetTypeField
                  budgetTracking={BudgetTrackingType.Currency}
                  variant={BudgetTypeVariant.ActivityPhase}
                />
              </Field>
            </>
            <>
              <Label>Default Activity Phase Contract Type</Label>
              <Field>
                <ContractTypeField
                  variant={ContractTypeVariant.ActivityPhase}
                />
              </Field>
            </>
            {hoursOnlyFlag && (
              <>
                <>
                  <Label>Default Phase Budget</Label>
                  <Field>
                    <BudgetTypeField
                      budgetTracking={BudgetTrackingType.Hours}
                      variant={BudgetTypeVariant.Phase}
                    />
                  </Field>
                </>
                <>
                  <Label>Default Activity Phase Budget</Label>
                  <Field>
                    <BudgetTypeField
                      budgetTracking={BudgetTrackingType.Hours}
                      variant={BudgetTypeVariant.ActivityPhase}
                    />
                  </Field>
                </>
              </>
            )}
          </>
        )}
      </SettingsList>
    </div>
  );
};
