import React, { useState } from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  height: 90px;
  width: calc(100% - 279px);
  margin-left: 279px;
  display: flex;
  justify-content: flex-end;
`;

const DoneButton = styled.div`
  width: 77px;
  height: 35px;
  border-radius: 5px;
  font-weight: 600;
  background: #0074d9;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 56px;
  margin-top: 43px;
  cursor: pointer;
`;

const MemberModalHeader = ({ handleClose }) => {
  return (
    <HeaderContainer>
      <DoneButton onClick={handleClose}> Done </DoneButton>
    </HeaderContainer>
  );
};

export default MemberModalHeader;
