import React from 'react';
import styled from 'styled-components';
import { MonthView } from './MonthView';
import { useScheduleCalendar } from './ScheduleCalendarProvider';
import { YearView } from './YearView';

/**
 * @type {Record<ViewType,React.ReactElement>}
 */

export const ScheduleCalendarByViewType = () => {
  const { viewType, currentDate } = useScheduleCalendar();

  const componentHash = {
    month: MonthView,
    year: YearView
  };

  const Component = componentHash[viewType] ?? componentHash.year;

  return (
    <RootContainer>
      <Component currentDate={currentDate}></Component>
    </RootContainer>
  );
};

const RootContainer = styled.div``;
