import React from 'react';
import { Container, EmptyTextBody } from './styles';
import Collapse from './../Collapse';
import ConnectionIcon from 'icons/ConnectionIcon';
class EmptyQuickBooksText extends React.Component {
  render() {
    return (
      <Container>
        <Collapse
          isOpen
          header={
            <div>
              {'Employees '} <ConnectionIcon /> Members
            </div>
          }
        >
          <EmptyTextBody>
            Active Employees will be available to Import as members to Mosaic.
            You will be able to select members to import. All members will be
            billed, Only import employees you wish to have as paid Mosaic
            members.
          </EmptyTextBody>
        </Collapse>
        <Collapse
          isOpen
          header={
            <div>
              {'Sub-Customer '}
              <ConnectionIcon /> Projects, Time Entries
            </div>
          }
        >
          <EmptyTextBody>
            • When linking Mosaic with QuickBooks, a ‘Sub-Customer’ is connected
            to every Mosaic ‘Project’. You will be able to select the Mosaic
            Project you wish to Import the QuickBooks Sub-customer to.
          </EmptyTextBody>
          <EmptyTextBody>
            • Clients/Customers will be imported with all jobs/projects.
          </EmptyTextBody>
          <EmptyTextBody>
            If you choose to sync or import Time Entries, all QuickBooks
            ‘Service’ Codes will be imported and matched to ‘Work Category’
            Categories on Mosaic so you can begin immediately logging time.
          </EmptyTextBody>
        </Collapse>
      </Container>
    );
  }
}

export default EmptyQuickBooksText;
