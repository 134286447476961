import { createSelector } from 'reselect';
import { getProjectTotals } from './profit';
import { getSelectedProject, getProjectHash } from './projects';
import {
  getPhasesAndMilestonesByProjectHash,
  getActivePhasesByProject,
  getFlatPhasesHash,
  getFlatPhases
} from './phases';
import { getAllActivityRowInfo } from './timesheet';
import moment from 'moment';

import { serializeBar } from 'appUtils/projectPlannerUtils';
import { getTimelineViewBy } from './filters';
import { VIEW_BY } from 'appConstants/workload';
import { getRemainingWorkDays } from 'appUtils/phaseDisplayUtils';
import { getTeamMembersHash } from './team';
import keyBy from 'lodash/keyBy';
const byId = (item) => item.id;
const emptyArray = [];

// todo: resolve import loop
const getPlannerMembers = createSelector(
  getTeamMembersHash,
  (teamMembersHash) => {
    const plannerMembers = Object.keys(teamMembersHash).reduce((acc, id) => {
      const teamMember = teamMembersHash[id];

      if (teamMember) {
        const accountExtractedMember = {
          ...teamMember,
          account_id: teamMember.account.id,
          name: teamMember.account.name,
          initials: teamMember.account.initials
        };
        acc[id] = accountExtractedMember;
      }
      return acc;
    }, {});
    return plannerMembers;
  }
);

const getWorkloadViewBy = (state) => state.workloadPlannerFilter.viewBy;
const getScheduleViewBy = (state) => state.workloadPlannerFilter.scheduleViewBy;

export const getV2Tasks = (state) => state.homeTasks.taskHash;
const getV2TasksOrder = (state) => state.homeTasks.allTasks;

export const getWorkloadSplitScreenActive = (state) =>
  state.workloadPlanner.isWorkloadSplitScreenActive;
export const getPlannerSplitScreenActive = (state) =>
  state.workloadPlanner.isPlannerSplitScreenActive;
export const getPlannerSplitScreenProjectId = (state) =>
  state.workloadPlanner.splitScreenProjectId;
export const getWorkloadSplitScreenAccountId = (state) =>
  state.workloadPlanner.splitScreenAccountId;
export const getWorkloadSplitScreenType = (state) =>
  state.workloadPlanner.workloadSplitScreenType;
export const getPlannerSplitScreenType = (state) =>
  state.workloadPlanner.plannerSplitScreenType;

export const getV2TasksArray = createSelector(
  getV2Tasks,
  getV2TasksOrder,
  (tasks, taskOrder) =>
    taskOrder.map((id) => tasks[id]).filter((task) => !!task)
);

export const getProjectTimelinePhases = createSelector(
  getSelectedProject,
  getPhasesAndMilestonesByProjectHash,
  getFlatPhasesHash,
  (project, phasesByProject, phaseHash) => {
    const phases = phasesByProject[project?.id]?.phases ?? emptyArray;
    const nonDefaultPhasesWithStartDate = phases.filter(
      (phase) =>
        !phase.is_like_default &&
        !phaseHash[phase.id]?.is_like_default &&
        phase.start_date
    );
    return (
      nonDefaultPhasesWithStartDate.map((phase) => ({
        ...phase,
        project,
        remaining_work_days: getRemainingWorkDays(phase),
        start_date: moment(phase.start_date),
        end_date: moment(phase.end_date).endOf('day'),
        start_date_label: moment(phase.start_date),
        end_date_label: moment(phase.end_date).endOf('day'),
        id: serializeBar({ itemType: 'phase', itemId: phase.id })
      })) ?? emptyArray
    );
  }
);

const getOwnProjectIds = (state, ownProps) => ownProps.projectIds;

const getOwnProjectIdsHash = createSelector(getOwnProjectIds, (ids) =>
  keyBy(ids)
);

const formatTimelineWorkCategories = (
  projectHash,
  phases,
  allActivityRowInfo,
  viewBy,
  splitScreenAccountId
) => {
  return phases.reduce((workCategoryArray, phase) => {
    phase.activity_phases.forEach((activityPhase) => {
      if (
        !activityPhase.is_default &&
        activityPhase.start_date &&
        activityPhase.end_date &&
        (viewBy !== VIEW_BY.MEMBERS ||
          activityPhase.activity_phase_memberships.some(
            (activityPhaseMembership) =>
              activityPhaseMembership.account_id == splitScreenAccountId
          ))
      ) {
        const formattedWorkCategory = {
          ...activityPhase,
          is_work_category: true,
          id: serializeBar({
            itemType: 'workCategory',
            itemId: activityPhase?.id
          }),
          ungrouped_id: 'none',
          phase: phase,
          item_project_view_group_key: `root--${phase.project_id}`,
          project_id: `root--${phase.project_id}`,
          project: { id: phase.project_id }, // for planner tasks view
          start_date: moment(activityPhase.start_date),
          end_date: moment(activityPhase.end_date).endOf('day'),
          start_date_label: moment(activityPhase.start_date),
          end_date_label: moment(activityPhase.end_date).endOf('day'),
          title: allActivityRowInfo[activityPhase.activity_id]?.title,
          item_project_view_split_screen_key: splitScreenAccountId
        };
        workCategoryArray.push(formattedWorkCategory);
      }
    });
    return workCategoryArray;
  }, []);
};

const formatTimelineTasks = (
  projectIdHash,
  tasks,
  plannerMembers,
  projectHash,
  isSplitScreenActive,
  splitScreenProjectId,
  viewBy
) => {
  return tasks
    .filter(
      (task) => viewBy === VIEW_BY.MEMBERS || projectIdHash[task.project_id]
    )
    .reduce((taskArray, task) => {
      const assignees =
        task.assignee_ids?.map((id) => plannerMembers[id]) ?? [];
      const formattedTask = {
        ...task,
        start_date: moment(task.schedule_start),
        end_date: (task.schedule_end
          ? moment.max(moment(task.schedule_start), moment(task.schedule_end))
          : moment(task.schedule_start)
        ).endOf('day'),
        is_task: true,
        id: serializeBar({ itemType: 'task', itemId: task.id }),
        ungrouped_id: 'none',
        assignees,
        project: projectHash[task.project_id]
      };
      formattedTask.duration = Math.round(
        moment
          .duration(formattedTask.end_date.diff(formattedTask.start_date))
          .asDays()
      );

      if (task.assignee_ids?.length && viewBy === VIEW_BY.MEMBERS) {
        task.assignee_ids.forEach((assigneeId) => {
          taskArray.push({
            ...formattedTask,
            item_project_view_group_key: `account--${assigneeId}--project--${task.project_id}--tasks`,
            item_project_view_split_screen_key: assigneeId,
            assignee_id: assigneeId,
            member: plannerMembers[assigneeId]
          });
        });
      } else {
        taskArray.push({
          ...formattedTask,
          item_project_view_group_key: `bottom--${task.project_id}`,
          member: plannerMembers[task.assignee_ids?.[0]]
        });
      }
      return taskArray;
    }, []);
};

export const getScheduleTimelineTasks = createSelector(
  getProjectHash,
  getV2TasksArray,
  getPlannerMembers,
  getProjectHash,
  () => false,
  () => null,
  getScheduleViewBy,
  formatTimelineTasks
);

export const getProjectTimelineTasks = createSelector(
  getOwnProjectIdsHash,
  getV2TasksArray,
  getPlannerMembers,
  getProjectHash,
  () => false,
  () => null,
  getTimelineViewBy,
  formatTimelineTasks
);

export const getPlannerTimelineTasks = createSelector(
  getProjectHash,
  getV2TasksArray,
  getPlannerMembers,
  getProjectHash,
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  () => VIEW_BY.MEMBERS,
  formatTimelineTasks
);

export const getPlannerWorkCategories = createSelector(
  getProjectHash,
  getFlatPhases,
  getAllActivityRowInfo,
  getWorkloadViewBy,
  getWorkloadSplitScreenAccountId,
  formatTimelineWorkCategories
);

export const getProjectTimelineItems = createSelector(
  getTimelineViewBy,
  getProjectTimelinePhases,
  getProjectTimelineTasks,
  (viewBy, phases, tasks) => {
    if (viewBy !== VIEW_BY.NONE) {
      return tasks;
    }
    return [...phases, ...tasks];
  }
);

export const getSelectedProjectTimelineItemIds = createSelector(
  getProjectTimelineItems,
  (items) => items.map(byId)
);

export const getProjectTimelineRow = createSelector(
  getSelectedProject,
  getTimelineViewBy,
  getActivePhasesByProject,
  (project, viewBy, activePhasesByProject) => {
    const defaultRow = [{ id: project.id }];
    switch (viewBy) {
      case VIEW_BY.MEMBERS: {
        return project.project_membership
          .filter((pm) => !!pm.account) // filter out unassigned. not sure if we want this, just leaving original behaviour
          .map((member) => ({
            ...member,
            account_id: member.account.id
          }))
          .sort((a, b) =>
            a.account?.name?.toLowerCase() > b.account?.name?.toLowerCase()
              ? 1
              : -1
          );
      }
      case VIEW_BY.TASK_PRIORITIES: {
        return project.task_priorities;
      }
      case VIEW_BY.TASK_GROUPS: {
        return project.task_group_order.map((id) => ({ id }));
      }
      case VIEW_BY.PHASES: {
        return activePhasesByProject[project.id]?.phases ?? [];
      }

      case VIEW_BY.NONE:
      default:
        return defaultRow;
    }
  }
);
