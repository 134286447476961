import React, { useRef, useState, useCallback } from 'react';
import { StyledCostCell, SubDateText, SelectRoleContainer } from './styles';
import PencilIcon from 'icons/PencilIcon';
import CostRateSelection from './CostRate/CostRateSelection';
import DropdownArrow from 'images/integrations/dropdown-arrow.svg';
import moment from 'moment';
import { FormattedCurrencyByTeam } from 'components/FormatCurrency';

const CostCell = ({ cell, row }) => {
  const { value } = cell;
  const {
    original: { member, salary }
  } = row;
  const targetRef = useRef(null);

  const startDate = salary?.start_date
    ? moment(salary.start_date).format('M/D/YY')
    : undefined;

  return (
    <StyledCostCell ref={targetRef}>
      <CostRateSelection target={targetRef} member={member}>
        {value ? (
          <>
            <FormattedCurrencyByTeam currencyValue={value} />
            <div className="hour-text">
              /h <PencilIcon size={12} />
            </div>
          </>
        ) : (
          <div style={{ position: 'relative' }}>
            <SelectRoleContainer isInTable className="app-cues-set-rate-role">
              Set Rate
            </SelectRoleContainer>
          </div>
        )}
      </CostRateSelection>
      {startDate && (
        <SubDateText
          data-for="app-tooltip"
          data-tip={startDate ? `Effective as of ${startDate}` : ''}
          data-class="center"
        >
          {startDate}
        </SubDateText>
      )}
    </StyledCostCell>
  );
};

export default CostCell;
