import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { TIMELINE_VIEW_TYPES } from 'TimelinesModule/components/constants';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { useTimelineView } from 'TimelinesModule/hooks/useTimelineView';
import { MemberAvailabilityTimelineContainer } from './MemberAvailabilityTimelineContainer';
import { AccountId } from 'models/account';
import { ComponentProps } from 'react';

interface MemberAvailabilityTimelineInitializerProps
  extends Pick<
    ComponentProps<typeof MemberAvailabilityTimelineContainer>,
    'sidebarHeader'
  > {
  accountIds: AccountId[];
}
export const MemberAvailabilityTimelineInitializer = ({
  accountIds,
  sidebarHeader
}: MemberAvailabilityTimelineInitializerProps) => {
  // on mount, generate timeline id and store to the reducer
  const { timelineView, updateTimelineView } = useTimelineView({
    type: TIMELINE_VIEW_TYPES.MEMBER_AVAILABILITY_SPLIT_SCREEN
  });

  if (!timelineView) return null;

  return (
    <DynamicModuleLoader
      modules={[getCapacitiesModule(), getUtilizationModule()]}
    >
      <MemberAvailabilityTimelineContainer
        accountIds={accountIds}
        timelineView={timelineView}
        onTimelineViewChange={updateTimelineView}
        sidebarHeader={sidebarHeader}
      />
    </DynamicModuleLoader>
  );
};
