import { useState } from 'react';
import { createEntityComment, deleteEntityComment } from 'actionCreators';
import { CommentForm, CustomPlaceholders } from './CommentForm';
import { CommentThreadContainer } from './CommentThreadContainer';
import { useDispatch } from 'react-redux';
import { EntityType } from 'models/entity';
import { useComments } from './useComments';
import DeleteCommentModal from '../taskDisplay/taskModalComponents/DeleteCommentModal';
import styled from 'styled-components';
import { GetCommentsPermission } from './CommentRow';
interface CommentsContainerProps {
  formTooltip?: string;
  parentEntityType: EntityType;
  parentEntityId: number | undefined;
  isDisabled?: boolean;
  placeholders?: CustomPlaceholders;
  getCommentsPermission: GetCommentsPermission;
  editCommentDisabledTooltip: string;
  deleteCommentDisabledTooltip: string;
}
export const CommentsContainer = ({
  formTooltip,
  parentEntityType,
  parentEntityId,
  isDisabled = false,
  placeholders,
  getCommentsPermission,
  editCommentDisabledTooltip,
  deleteCommentDisabledTooltip
}: CommentsContainerProps) => {
  const dispatch = useDispatch();
  const { comments, loadMoreComments, allCommentsLoaded } = useComments({
    parentEntityType,
    parentEntityId
  });

  const [isEditing, setIsEditing] = useState(false);
  const [commentIdToDelete, setCommentIdToDelete] = useState<
    { id: number; parentCommentId?: number } | undefined
  >();

  const handleCreate = ({ description, files }) => {
    if (parentEntityId) {
      dispatch(
        createEntityComment({
          parentEntityType,
          parentEntityId,
          description,
          files
        })
      );
      setIsEditing(false);
    }
  };

  const handleCloseModal = () => {
    setCommentIdToDelete(undefined);
  };

  const handleDelete = (value: { id: number; parentCommentId?: number }) => {
    setCommentIdToDelete(value); // open modal
  };

  const deleteCommentOrReply = () => {
    if (commentIdToDelete) {
      dispatch(deleteEntityComment(commentIdToDelete));
      handleCloseModal();
    }
  };

  const handleLoadMore = () => {
    loadMoreComments();
  };

  const handleClick = () => {
    if (!isDisabled) setIsEditing(true);
  };

  return (
    <div className="comments-container">
      <CommentForm
        formTooltip={formTooltip}
        onSave={handleCreate}
        commentType="ADD"
        isExpanded={isEditing}
        onCancel={() => setIsEditing(false)}
        onClick={handleClick}
        isDisabled={isDisabled}
        placeholders={placeholders}
      />
      {comments.map((comment) => {
        return (
          <CommentThreadContainer
            key={comment.id}
            comment={comment}
            onDelete={handleDelete}
            getCommentsPermission={getCommentsPermission}
            editCommentDisabledTooltip={editCommentDisabledTooltip}
            deleteCommentDisabledTooltip={deleteCommentDisabledTooltip}
          />
        );
      })}
      {!allCommentsLoaded && (
        <LoadMoreContainer>
          <span onClick={handleLoadMore}>See older comments</span>
        </LoadMoreContainer>
      )}
      <DeleteCommentModal
        isOpen={Boolean(commentIdToDelete)}
        toggle={handleCloseModal}
        commentId={undefined} // not used
        deleteCommentOrReply={deleteCommentOrReply}
      />
    </div>
  );
};

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.colorRoyalBlue};

  span {
    cursor: pointer;
  }
`;
