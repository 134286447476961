import styled from 'styled-components';

export const ThreeDotIconContainer = styled.div<{
  isOpen: boolean;
  alwaysShowThreeDot?: boolean;
}>`
  opacity: ${(props) => (props.alwaysShowThreeDot ? 1 : props.isOpen ? 1 : 0)};
  align-items: center;
  display: flex;
`;
