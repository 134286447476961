import { useCallback } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getRegions } from 'SettingsModule/selectors/regions';
import { useNavToSettings } from 'SettingsModule/hooks';
import { useLoadRegions } from './useLoadRegions';

const emptyObj = {};

export const useRegions = ({
  isOff,
  shouldAutoLoad
}: {
  /** prevent unnecessary hook behaviours */
  isOff?: boolean;
  /** default is true */
  shouldAutoLoad?: boolean;
} = emptyObj) => {
  const regionHash = useAppSelector(getRegions);

  const getRegionLabel = useCallback(
    (regionId: number | string) => {
      return regionHash[+regionId]?.name ?? 'No Region';
    },
    [regionHash]
  );

  const { handleNavToSettings: handleNavToRegionsPage } = useNavToSettings({
    viewType: 'members',
    tab: 'regions'
  });

  const { isLoaded, isLoading, loadRegions } = useLoadRegions({
    isOff,
    shouldAutoLoad
  });

  return {
    isLoaded,
    isLoading,
    loadRegions,
    regionHash,
    handleNavToRegionsPage,
    getRegionLabel
  };
};
