import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import { AccountFilter } from 'models/filter';

const FEE_TYPE_VALUES = {
  FIXED_FEE: 'Fixed Fee',
  HOURLY: 'Hourly'
};

const phaseFeeTypeItems = [FEE_TYPE_VALUES.FIXED_FEE, FEE_TYPE_VALUES.HOURLY];

const phaseFeeTypeDisplay = {
  [FEE_TYPE_VALUES.FIXED_FEE]: 'Fixed Fee',
  [FEE_TYPE_VALUES.HOURLY]: 'Hourly'
};

type PhaseFeeTypeFilterDropdownParams = PropsFromRedux & {
  activeFilter: AccountFilter;
  updateAccountFilterLocal: ({ ...filters }: AccountFilter) => void;
  isHidden: boolean;
};

const PhaseFeeTypeFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  isHidden
}: PhaseFeeTypeFilterDropdownParams) => {
  const setFilterItems = (nextFeeTypes: Array<string>) => {
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        ...activeFilter.custom,
        phase_fee_type: nextFeeTypes
      }
    });
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={phaseFeeTypeItems}
      itemHash={phaseFeeTypeDisplay}
      initialSelectedItems={activeFilter.custom.phase_fee_type}
      setFilterItems={setFilterItems}
      searchEnabled={false}
      toggleLabel="Fee Type"
      togglePluralLabel="Fee Types"
      allSelectedCopy="All Fee Types"
      filterId={activeFilter.id}
      batchClearOnly={false}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const component = connector(PhaseFeeTypeFilterDropdown);
export default component;
