import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getModalProjectInfo } from 'BudgetModule/selectors';
import { getMatchedRouteParams, getIsAddMilestone } from 'selectors';
import { StyledPhaseOfWorkInput } from './styles';
import PropTypes from 'prop-types';
import { getArchivedPhaseEditTooltipProps } from 'BudgetModule/components/BudgetTable/utils';

class PhaseOfWorkInput extends React.Component {
  state = {
    name: this.props.name
  };

  componentDidUpdate(prevProps) {
    const { name } = this.props;
    if (name !== prevProps.name && name !== this.state.name) {
      this.setState({ name });
    }
  }

  handleChange = (e) => {
    const { onChangeCallback } = this.props;
    if (onChangeCallback) {
      onChangeCallback(e);
    }
    this.setState({ name: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      project,
      matchedParams: { projectId },
      id,
      handleSubmit
    } = this.props;

    const { name } = this.state;
    handleSubmit({
      projectId: projectId || project.id,
      id,
      name
    });
  };

  handleBlur = () => {
    const { handleBlur } = this.props;
    if (handleBlur) {
      handleBlur();
    }
  };

  render() {
    const { isInModal, inputRef, isAddMilestone, isDisabled } = this.props;
    return (
      <form
        {...getArchivedPhaseEditTooltipProps({
          isTooltipDisabled: !isDisabled
        })}
        onSubmit={this.handleSubmit}
      >
        <StyledPhaseOfWorkInput
          disabled={isDisabled}
          type="text"
          value={this.state.name}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onBlur={this.handleBlur}
          placeholder={
            !isAddMilestone
              ? 'Type title of this phase'
              : 'Type title of this milestone'
          }
          isInModal={isInModal}
          ref={inputRef}
          data-testid="phase-name-input"
        />
      </form>
    );
  }
}

PhaseOfWorkInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number
};

const mapStateToProps = (state, ownProps) => ({
  project: getModalProjectInfo(state, ownProps),
  matchedParams: getMatchedRouteParams(state),
  isAddMilestone: getIsAddMilestone(state)
});

export default withRouter(connect(mapStateToProps)(PhaseOfWorkInput));
