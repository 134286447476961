import { put, select } from 'redux-saga/effects';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { getAuthToken } from 'selectors';

const { fetchOrganizationSettings, updateOrganizationSettings } = entityActions;

export function* fetchOrganizationSettingsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchOrganizationSettings,
    api.fetchOrganizationSettings,
    teamId,
    [token, teamId],
    action
  );
}

export function* updateOrganizationSettingsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, body } = action.payload;

  yield changeEntity(
    updateOrganizationSettings,
    api.updateOrganizationSettings,
    [token, teamId, body],
    action
  );
}
