import { useState } from 'react';
import styled from 'styled-components';
import {
  WORK_PLAN_CALENDAR_MENU_TITLE,
  WORK_PLAN_CALENDAR_MENU_TYPES
} from 'appConstants/workload';
import CalendarSetTime from './CalendarSetTime';
import theme from 'theme';
import DropdownMenuIcon from 'icons/DropdownMenuIcon';
import HelpIcon from 'icons/HelpIcon';
import { StyledHelpIconContainer } from './styles';

const WorkplanCalendarCollapsableMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { workplanCalendarMenuType } = props;

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const menuComponentByType = {
    [WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME]: CalendarSetTime
  };

  const MenuComponent = menuComponentByType[workplanCalendarMenuType];

  const tooltipText = {
    [WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME]: 'Tooltip text TBD.'
  };

  // set false until tooltip text is available
  const showTooltip = false;

  return (
    <WorkplanCalendarCollapsableMenuContainer>
      <MenuHeaderContainer onClick={handleClick}>
        <MenuTitleContainer>
          {WORK_PLAN_CALENDAR_MENU_TITLE[workplanCalendarMenuType]}
          {showTooltip && (
            <StyledHelpIconContainer
              data-for="app-tooltip"
              data-html
              data-class="center"
              data-effect="solid"
              data-arrow-color={theme.colors.colorDarkBlueTooltip}
              data-tip={tooltipText[WORK_PLAN_CALENDAR_MENU_TYPES.SET_TIME]}
            >
              <HelpIcon
                height="8px"
                width="8px"
                circleColor="#DBDBDB"
                questionMarkColor="#000"
                fillColor="#DBDBDB"
              />
            </StyledHelpIconContainer>
          )}
        </MenuTitleContainer>
        <DropdownMenuIconContainer isOpen={isOpen}>
          <DropdownMenuIcon />
        </DropdownMenuIconContainer>
      </MenuHeaderContainer>
      {isOpen && <MenuComponent {...props} />}
    </WorkplanCalendarCollapsableMenuContainer>
  );
};

const MenuHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MenuTitleContainer = styled.div`
  display: flex;
`;

const WorkplanCalendarCollapsableMenuContainer = styled.div`
  border-top: 1px solid ${theme.colors.colorLightGray6};
  margin-left: 13px;
  margin-right: 13px;
  margin-top: 13px;
  padding-top: 11px;
  padding-left: 9px;
  cursor: pointer;
`;

const DropdownMenuIconContainer = styled.div`
  padding-right: 13px;
  svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : undefined)};
  }
`;

export default WorkplanCalendarCollapsableMenu;
