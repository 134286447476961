import styled from 'styled-components';
import { DISPLAY_MEMBER_CAPACITY } from 'appConstants/workload';
import { StickyWrapper, Sticky, HoverState, NonHoverState } from '../styles';
import { TimeText } from './TimeText';
import {
  renderHoursPerDayText,
  renderPercentText
} from 'appUtils/workplanDisplayUtils';
import NoteIcon from 'icons/NoteIcon';
import theme from 'theme';

export const HoursOrPercentText = ({
  averageCapacity,
  displayMemberCapacity,
  hasBold = false,
  hideTime = false,
  item,
  workdayPercent
}) => {
  const meatadataTextComponents = {
    hoursPerDayText: (
      <Text bold={hasBold}>{renderHoursPerDayText(item, averageCapacity)}</Text>
    ),
    percentText: (
      <Text bold={hasBold}>{renderPercentText(item, workdayPercent)}</Text>
    )
  };

  const metadataTextOrder = [
    meatadataTextComponents.hoursPerDayText,
    meatadataTextComponents.percentText
  ];

  const [hoverElement, nonHoverElement] =
    displayMemberCapacity === DISPLAY_MEMBER_CAPACITY.PERCENT
      ? metadataTextOrder
      : metadataTextOrder.reverse();

  return (
    <StickyWrapper>
      <Sticky>
        <TextContainer>
          {item.description && (
            <NoteIcon
              width={10}
              height={10}
              color={theme.colors.colorSemiDarkGray1}
            />
          )}
          <HoverState className="flex">{hoverElement}</HoverState>
          <NonHoverState className="flex">
            {nonHoverElement}
            {!hideTime &&
              item.start_time?.trim().length &&
              item.end_time?.trim().length && (
                <TimeText
                  values={{
                    startTime: item.start_time,
                    endTime: item.end_time
                  }}
                />
              )}
          </NonHoverState>
        </TextContainer>
      </Sticky>
    </StickyWrapper>
  );
};

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  gap: 6px;
  padding-left: 1px;
`;

const Text = styled.span`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;
