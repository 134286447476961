import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StyledReactTooltip = styled(ReactTooltip)`
  .multi-line {
    text-align: left !important;
  }
`;

const getTooltipContent = (members) =>
  `${
    members.length
      ? `Type @ to send to others`
      : `Type @ in message to send to someone`
  } <br> ${members.join('<br>')}`;

const getCountText = (members) =>
  members.length === 1 ? `${members[0]}` : `${members.length} people`;

const MembersToNotify = ({ members }) => (
  <>
    <div
      className="mention"
      data-for="members-to-notify-tooltip"
      data-tip={getTooltipContent(members)}
      data-multiline={true}
    >
      <div className="mention-count">{getCountText(members)}</div>
      {` will be notified.`}
    </div>
    <StyledReactTooltip
      effect="solid"
      id="members-to-notify-tooltip"
      className="text-align-left"
      offset={{ left: 20 }}
    />
  </>
);

export default MembersToNotify;
