import * as constants from 'appConstants';
const {
  OPEN_TIMESHEET_ACTIVITY_MODAL_FOR_DAY,
  CLOSE_TIMESHEET_ACTIVITY_MODAL_FOR_DAY,
  FETCH_WORKLOAD_PLANNER,
  FETCH_TASKS_V2
} = constants;
import { FETCH_PROJECT_NOTES_V2 } from 'NoteModule/constants';
// This initial state should be populated with some 'valid' data for demo purposes
const initialState = {
  openTimesheetActivityModal: false,
  timesheetActivityModalData: {},
  taskCount: 0,
  notesCount: 0
};

const timesheetActivityModal = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case OPEN_TIMESHEET_ACTIVITY_MODAL_FOR_DAY: {
      const { day, accountId, projectId, phaseId } = action.payload;
      return {
        openTimesheetActivityModal: true,
        timesheetActivityModalData: {
          ...state.timesheetActivityModalData,
          day,
          accountId,
          projectId,
          phaseId
        }
      };
    }
    case FETCH_WORKLOAD_PLANNER.SUCCESS: {
      const { activity_phase_schedule_bars } = payload.response;
      return {
        ...state,
        timesheetActivityModalData: {
          ...state.timesheetActivityModalData,
          activity_phase_schedule_bars
        }
      };
    }
    case FETCH_PROJECT_NOTES_V2.SUCCESS: {
      const { total_count } = payload.response;
      return {
        ...state,
        notesCount: total_count
      };
    }
    case FETCH_TASKS_V2.SUCCESS: {
      const { task_count } = payload.response;
      return {
        ...state,
        taskCount: task_count
      };
    }
    case CLOSE_TIMESHEET_ACTIVITY_MODAL_FOR_DAY: {
      return {
        openTimesheetActivityModal: false,
        timesheetActivityModalData: {},
        taskCount: 0,
        notesCount: 0
      };
    }

    default: {
      return state;
    }
  }
};

export default timesheetActivityModal;
