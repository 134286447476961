import styled from 'styled-components';

interface BadgeProps {
  count: number;
  active?: boolean;
}
export const Badge = ({ count, active }: BadgeProps) => {
  return (
    <BadgeContainer active={active}>
      <BadgeText active={active}>{count}</BadgeText>
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div<{ active?: boolean }>`
  width: auto;
  height: 18px;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 100px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.colorRoyalBlue : theme.colors.colorMediumGray5};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.colorRoyalBlue : 'transparent'};
`;
const BadgeText = styled.div<{ active?: boolean }>`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme, active }) =>
    active ? 'white' : theme.colors.colorMediumGray5};
  line-height: 1rem;
`;
