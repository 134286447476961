import styled from 'styled-components';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import theme from 'theme';
import Issue from 'icons/issue.svg';
import noAssigneeIcon from 'images/empty-member.svg';
import noAssigneeIconWhiteBG from 'images/empty-member-whitebg2.svg';
import LockIcon from 'icons/LockIcon';
import InfoIcon from 'icons/InfoIcon';
import { BUDGET_CATEGORIES } from 'BudgetModule/constants';

export const FieldsContainer = styled.div`
  margin-top: 5px;
`;

export const ProjectNameContainer = styled.div`
  position: relative;
  margin-right: 5px;
  margin-left: 32px;
`;
export const TopHeaderSection = styled.div`
  display: flex;
`;
export const StyledLockIcon = styled(LockIcon)`
  height: 14px;
  path {
    fill: ${theme.colors.colorRoyalBlue};
  }
  margin-right: 4px;
`;
export const Page = styled.div`
  width: 860px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;

  ${({ isOnReportsView }) =>
    isOnReportsView
      ? `
      width: 100%;
      max-width: 1020px;
      margin: 0 auto;
      padding: 0 20px;
  `
      : ''}
`;

export const LinkText = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};
  font-weight: 400;
`;
export const StyledDash = styled.div`
  margin: 0 3px;
`;
export const BudgetPageButtonsRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 5;
  padding: ${({ isOnTeamView }) => (isOnTeamView ? '20px 0px 0px' : '0')};
  background: ${theme.colors.colorTranslucentGray4};
`;
export const ModalButtonsRow = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  margin: ${({ margin }) => margin || '30px 0 0 0'};
`;
export const Left = styled.div``;
export const Right = styled.div``;
export const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 80px;
  font-weight: 400;
  font-size: 12px;
  top: 40px;
  line-height: 1.2;
  color: ${theme.colors.colorMediumGray5};
`;
const Button = styled.button`
  border: 0;
  font-size: 14px;
  padding: 8px 24px;
  border-radius: 5px;
`;
export const CancelButton = styled(Button)`
  color: ${theme.colors.colorMediumGray1};
  border: 1px solid ${theme.colors.colorPaleGray5};
  background: transparent;
  margin: 0px 10px;
  padding: 8px 16px;
  font-weight: 600;

  &:hover {
    background: ${theme.colors.colorPaleGray5};
    border: 1px solid ${theme.colors.colorPaleGray5};
    color: ${theme.colors.colorMediumGray5};
  }
`;

export const MainDeleteButton = styled(Button)`
  color: ${theme.colors.colorDeleteRed};
  border: 1px solid ${theme.colors.colorDeleteRed};
  background: transparent;
  margin: 0px 10px;
  padding: 8px 16px;
  font-weight: 600;

  &:hover {
    background: ${theme.colors.colorDeleteRed};
    border: 1px solid ${theme.colors.colorDeleteRed};
    color: ${theme.colors.colorPureWhite};
  }
`;
export const ConfirmButton = styled(Button)`
  background-color: ${({ isGray, isDelete }) =>
    isGray
      ? theme.colors.colorLightGray9
      : isDelete
      ? theme.colors.colorDeleteRed
      : theme.colors.colorRoyalBlue};
  color: ${({ isGray }) =>
    isGray ? theme.colors.colorMediumGray5 : theme.colors.colorPureWhite};

  &.create-and-save-button {
    background-color: ${theme.colors.colorCalendarBlue};
    color: ${theme.colors.colorPureWhite};
  }

  &.phase-modal-archive-button {
    background-color: ${theme.colors.colorRoyalBlue};
  }
`;

export const RemoveButton = styled(Button)`
  color: ${theme.colors.colorMediumGray1};
  border: 1px solid ${theme.colors.colorPaleGray5};
  background: transparent;
  padding: 8px 16px;

  &:hover {
    border: 1px solid ${theme.colors.colorDeleteRed};
    background: transparent;
    color: ${theme.colors.colorDeleteRed};
  }
`;

export const ArchiveButton = styled(Button)`
  color: ${theme.colors.colorMediumGray1};
  border: 1px solid ${theme.colors.colorPaleGray5};
  background: transparent;
  padding: 8px 16px;
  &:hover {
    border: 1px solid ${theme.colors.colorDeleteRed};
    background: transparent;
    color: ${theme.colors.colorDeleteRed};
  }
`;
export const StyledActionButton = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  text-transform: ${(props) => (props.inBudgetModal ? 'uppercase' : 'inherit')};
  font-size: 14px;

  border: 1px solid ${theme.colors.colorPaleGray3};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 100px;
  background: ${theme.colors.colorPaleGray1};
  font-weight: 600;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    border: 1px solid ${theme.colors.colorRoyalBlue};
    cursor: pointer;
  }
`;

export const StyledContainer = styled.div`
  flex: auto;
  overflow: ${(props) => props.overflow || 'auto'};
  padding-top: ${({ isOnReportsView }) => (isOnReportsView ? '0' : '15px')};
  @media screen and (max-width: 1200px) {
    margin-top: 30px;
  }
`;

export const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #4f4f4f;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 145px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`;

export const ArchivedPhaseLabel = styled.div`
  position: absolute;
  top: ${(props) => props.top || 0}px;
  left: ${(props) => props.left || 0}px;
  margin-right: 3px;
  font-size: 10px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  border-radius: 4px;
  background-color: ${theme.colors.colorPureWhite};
  padding: 0 4px;
  text-transform: uppercase;
`;
export const ArchivedPhaseText = styled.div`
  position: absolute;
  top: 0px;
  left: 54px;
  font-weight: 600;
  background: ${theme.colors.colorPureWhite};
  color: ${theme.colors.colorMediumGray4};
`;
export const GoToProjectButton = styled.div`
  position: absolute;
  left: 25px;
  bottom: 30px;
  z-index: 12;
  font-size: 12px;
  background: ${theme.colors.colorPaleGray1};
  border: 1px solid ${theme.colors.colorPaleGray3};
  border-radius: 30px;
  color: ${theme.colors.colorRoyalBlue};
  padding: 2px 8px;
  visibility: hidden;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    border-color: ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const PercentHover = styled.div`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  position: absolute;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  font-size: 12px;
  top: 13px;

  &.timeprojection {
    &.planned {
      text-align: right;
      width: 98%;
    }
  }

  &.spent-time,
  &.planned-time {
    &.spent,
    &.planned,
    &.remaining {
      display: none;
    }
  }

  &.unspent-budget {
    &.planned,
    &.remaining {
      display: none;
    }
  }

  &.budget-table-chart,
  &.budget-report,
  &.project-detail-budget {
    display: none;
  }
`;

export const StyledPercents = styled.div`
  display: flex;
  border-radius: 3px;
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '8px')};
  background: ${theme.colors.colorPaleGray4};
`;

export const StyledBar = styled.div`
  display: flex;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  align-items: center;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '8px')};
  background: ${theme.colors.colorPaleGray4};

  &.spent-time,
  &.planned-time {
    &.show-bars {
      background-color: transparent;
    }
  }

  &.unspent-budget {
    &.members {
      &.memberHeaderRow {
        &.show-bars {
          background-color: transparent;
        }
      }
    }
  }
`;
export const CollapseIconContainer = styled.div`
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CollapseIcon = styled.div`
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
  cursor: pointer;
  path {
    transform-origin: center center;
    transform: ${(props) => (props.collapsed ? `rotate(-90deg)` : ``)};
    transition: 0.2s all;
  }
  rect {
    stroke: ${(props) =>
      props.isInTable
        ? 'transparent'
        : props.collapsed
        ? theme.colors.colorPaleGray5
        : `transparent`};
    fill: ${(props) =>
      props.isInTable ? 'transparent' : theme.colors.colorPureWhite};
  }
  &:hover {
    path {
      fill: #0074d9;
    }
    rect {
      fill: ${theme.colors.colorTranslucentGray3};
      ${({ hasHoverEffect }) =>
        hasHoverEffect &&
        `
        fill: ${theme.colors.colorPureWhite};
        stroke: ${theme.colors.colorLightGray9};
      `};
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ isOpen }) => (!isOpen ? '1px solid #e4e4e4' : '')};
  width: 100%;
  padding: ${({ isMainTotal }) => (isMainTotal ? '20px 10px' : '30px 10px')};
  cursor: pointer;

  ${HeaderText} {
    display: flex;
  }
  &:hover {
    ${GoToProjectButton} {
      visibility: visible;
    }
    ${CollapseIcon} {
      path {
        fill: #0074d9;
      }
      rect {
        stroke: #0074d9;
      }
    }
    ${StyledBar} {
      background: ${theme.colors.colorLightGray9};
    }
  }
`;

export const LegendItems = styled.div`
  display: flex;
  flex-direction: column;
`;
/*  display: flex;
  flex-direction: column; */

export const StyledLegendItem = styled.div`
  padding: 2px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorLightGray10};
  font-weight: 400;
  white-space: nowrap;
`;

export const StyledLegend = styled.div`
  position: absolute;
  z-index: 1;

  ${({ isOnReportsView }) =>
    isOnReportsView &&
    `
    left: 1090px;
    top: 15px;
  `}

  ${({ isOnTab }) =>
    isOnTab &&
    `
    left: 880px;
    top: 20px;
  `}

  ${({ inModal }) =>
    inModal &&
    `
    left: 870px;
  `}

  ${({ isProjectViewReport }) =>
    isProjectViewReport &&
    `
    left: -120px;
    top: -4px;
    }
    ${LegendItems} {
      flex-direction: row;
    }
    ${StyledLegendItem} {
      margin-right: 20px;
    }
  `}

  @media screen and (max-width: 1200px) {
    ${LegendItems} {
      flex-direction: row;
    }

    ${StyledLegendItem} {
      margin-right: 20px;
    }

    ${({ isOnReportsView }) =>
      isOnReportsView &&
      `
       right: 22px;
        top: 0;
        left: unset;
      `}

    ${({ isOnTab }) =>
      isOnTab &&
      `
        left: 420px;
        top: 4px;
      `}

    ${({ inModal }) =>
      inModal &&
      `
        left: 550px;
        top: -17px
      `}
  }
`;

const budgetColorHash = {
  spent: 'colorBudgetGreen',
  planned: 'colorPlannedHours',
  remaining: 'colorBudgetLightGreen',
  'spent hours': 'colorBudgetGreen',
  'planned hours': 'colorPlannedHours',
  'remaining hours': 'colorBudgetLightGreen',
  spent_hours: 'colorBudgetGreen',
  planned_hours: 'colorPlannedHours',
  remaining_hours: 'colorBudgetLightGreen',
  spent_cost: 'colorBudgetGreen',
  planned_cost: 'colorPlannedHours',
  remaining_cost: 'colorBudgetLightGreen',
  spent_percent: 'colorBudgetDotGreen',
  planned_percent: 'colorPlannedHours',
  remaining_percent: 'colorBudgetLightGreen',
  estimated_percent: 'colorLightGray6',
  estimated_hours_percent: 'colorBudgetBlue',
  estimated_cost_percent: 'colorBudgetBlue',
  estimated_cost: 'colorLightGray6',
  estimated: 'colorLightGray6',
  fee: 'colorBudgetBlue',
  over_budget: 'colorCalendarRed',
  schedule_variance_planned: 'colorPlannedHours',
  actual: 'colorBudgetGreen',
  [BUDGET_CATEGORIES.COMPLETED]: 'colorLightCyan3',
  [BUDGET_CATEGORIES.SCOPE_REMAINING]: 'colorMediumGray11'
};

export const calcBudgetColor = ({ text, theme }) => {
  return theme.colors[budgetColorHash[text]];
};
export const StyledLegendBox = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${calcBudgetColor};
  margin-right: 6px;
  border-radius: 12px;
  box-shadow: 0px 1px 1px rgba(211, 211, 211, 0.25);
  flex-shrink: 0;
`;

export const StyledLegendBoxTarget = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 3px;
  box-shadow: 0px 1px 1px rgba(211, 211, 211, 0.25);
  display: flex;
  align-items: center;
`;

export const EditIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 20px;
  margin-left: 4px;
  visibility: hidden;
`;

export const StyledDragGrips = styled.div`
  position: absolute;
  visibility: ${(props) =>
    props.isDragging || props.shouldShow ? 'visible' : 'hidden'};
  z-index: 200;
  width: 30px;
  left: -10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    visibility: visible;
  }
`;

export const StyledPOWListItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-radius: 3px;
  outline: none;
  cursor: pointer !important;
  background-color: ${(props) => (props.isDragging ? '#fff' : 'transparent')};

  .btn-secondary {
    padding: 0px 5px;
  }
  &:hover {
    ${EditIconContainer} {
      visibility: visible;
      path {
        stroke: #0074d9;
      }
    }
    ${StyledDragGrips} {
      visibility: visible;
    }
  }
`;

const calcBarFlex = ({ total, text }) => {
  if (total) {
    return total;
  } else {
    if (text === 'remaining_cost') {
      return 1;
    }
  }
  return 0;
};

const calcBarShow = ({ total }) => {
  return total ? 'flex' : 'none';
};

export const StyledBarSection = styled.div`
  background-color: ${calcBudgetColor};
  flex: ${calcBarFlex};
  color: ${theme.colors.colorSemiDarkGray1};
  display: ${calcBarShow};
  align-items: center;
  height: 8px;
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  padding: 0 4px;
  position: relative;
  min-width: 26px;

  &:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  &.spent-time {
    &.spent {
      display: flex;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      max-width: ${({ maxWidth }) => +maxWidth}%;
    }
    &.remaining,
    &.planned {
      display: none;
    }
  }

  &.planned-time {
    &.planned {
      display: flex;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      max-width: ${({ maxWidth }) => +maxWidth}%;
    }
    &.remaining,
    &.spent {
      display: none;
    }
  }

  &.unspent-budget {
    &.members {
      &.memberHeaderRow {
        display: flex;
        max-width: ${({ maxWidth }) => +maxWidth}%;
      }
    }

    &.remaining {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
    }
    &.spent {
      display: none;
    }
  }
`;

export const StyledBarLabel = styled.div`
  flex-basis: 0;
  font-size: 12px;
  z-index: 5;
  padding-right: 4px;
  min-width: fit-content;

  &.timeprojection {
    &.planned {
      text-align: right;
    }
  }

  &.spent-time,
  &.planned-time {
    &.spent,
    &.planned {
      display: none;
    }
  }

  &.unspent-budget {
    &.spent,
    &.overbudget {
      display: none;
    }
  }
`;
export const StyledBarLabelOverBudget = styled(StyledBarLabel)`
  display: flex;
  max-width: fit-content;

  &.budget-report,
  &.project-detail-budget {
    display: none;
  }
`;

export const OverBudgetPill = styled.div`
  background-color: #f2c94c;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  padding: 2px 4px;
`;

export const StyledBudgetModal = styled.div`
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  position: absolute;
`;

export const StyledPOWListDroppable = styled.div`
  margin-top: 20px;
  ${StyledPOWListItem} {
    ${(props) =>
      props.dragging &&
      `
      height: 60px;
    `}
  }
`;

export const PhaseOfWorkListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`;

export const StyledPOWListItemDroppable = styled.div``;
export const StyledMemberBudget = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  height: 45px;
  align-items: center;
  justify-content: flex-start;
  flex: auto;
  padding: 0px 10px;
  margin-bottom: 6px;
  background: transparent;
  outline: none;
  position: relative;
  cursor: pointer !important;
  &:hover {
    ${StyledDragGrips} {
      visibility: visible;
    }
  }
  .member-card-name {
    width: 130px;
  }
`;

export const ModalBodyDescriptionText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray3};
  margin-top: 13px;
`;

export const HelpIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
`;

export const HeaderTotals = styled.div`
  font-size: 12px;
  line-height: 1;
  display: none;
`;
export const HeaderHoursTotal = styled.div`
  font-size: 12px;
  line-height: 1;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: flex-end;
  color: ${theme.colors.colorSemiDarkGray1};
  width: 78px;
  visibility: hidden;
`;
export const HourTotal = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
  flex-grow: 1;
  overflow: visible;
  display: flex;
  position: relative;
  justify-content: flex-end;
  position: relative;
  font-size: 14px;
`;
export const HourTotalNumber = styled.span`
  text-align: right;
`;
export const HourInput = styled.input`
  width: 40px;
  text-align: right;
  border: none;
  color: ${theme.colors.colorSemiDarkGray1};
  background: transparent;
  font-weight: 400;
`;
export const HContainer = styled.span`
  font-weight: 400;
`;
export const DollarTotal = styled.div`
  width: 70px;
  display: none;
  font-weight: 700;
`;
export const TotalsContainer = styled.div`
  font-size: 15px;
  ${(props) => !props.unsetMinWidth && 'min-width: 65px;'}
  margin-left: 5px;

  ${HourTotal} {
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 600;
  }
  justify-content: space-between;
  ${(props) => props.isHidden && 'visibility: hidden;'}
`;

export const OverBudgetTotalsContainer = styled(TotalsContainer)`
  color: ${(props) => props.theme.colors.colorCalendarRed};
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  margin-left: 0px;
  ${HourTotal} {
    color: ${(props) =>
      props.unsetWarningColor
        ? props.theme.colors.colorSemiDarkGray1
        : props.theme.colors.colorCalendarRed};
    display: flex;
  }
`;

export const NameContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
`;

export const StyledPhaseOfWorkInput = styled.input`
  border: 0;
  border-bottom: ${(props) =>
    props.isInModal ? '1px solid #0074d9 ' : 'none'};
  width: 100%;
  font-size: ${(props) => (props.isInModal ? '19px' : '14px')};
  padding-bottom: ${(props) => (props.isInModal ? '6px' : '0px')};
  text-overflow: ellipsis;
  overflow: hidden;
  &::placeholder {
    color: ${theme.colors.colorLightGray15};
  }
  &.activity-modal-input {
    background-color: ${theme.colors.colorLightGray19};
    padding: 7px 13px;
    font-size: 13px;
    border-bottom: 1px solid gainsboro;
  }
  &:disabled {
    opacity: 0.65;
  }
`;
export const StyledMemberListContainer = styled.div`
  width: 100%;
  padding-left: 55px;
  align-self: flex-end;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-light-gray-1;
    border-radius: 25px;
  }
`;
export const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 5px;
  height: 16px;
  width: 16px;
  margin-top: 4px;
`;

export const StyledProjectTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${StyledInfoIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
      circle {
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;
export const ModalHeaderWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  padding: 50px 50px 0px 25px;
  z-index: 1;
`;
export const ModalHeaderLeft = styled.div`
  display: flex;
  font-size: 20px;
  color: ${theme.colors.colorPureBlack};
  font-weight: 600;
`;
export const ViewSettingsHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray5};
`;
export const ModalHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
export const StickyModalHeaderRight = styled(ModalHeaderRight)`
  position: sticky;
  right: 40px;
  z-index: 7;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 600;
`;
export const BudgetModalMemberNameContainer = styled.div`
  padding-left: 30px;
`;
export const RatesList = styled.div`
  display: flex;
  background: white;
  top: -9px;
  left: -9px;
  position: relative;
  flex-direction: column;
  z-index: 2;
  width: 166px;
  height: 40px;
`;
export const MainDropdownContainer = styled.div`
  margin-right: 30px;
`;
export const DropdownContainer = styled.div`
  height: 58px;
  background-color: #f3f3f3;
  border: 1px solid #dcdcdc;
  width: ${(props) => (props.large ? 220 : 170)}px;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  position: relative;
`;
export const DropdownTitle = styled.div`
  text-transform: uppercase;
  color: #9b9b9b;
  font-size: 10px;
`;
export const DropdownValue = styled.div``;
export const StyledDropdownsRow = styled.div`
  display: flex;
  justify-content: left;
  margin: 10px 0;
  max-width: 666px;
`;

export const StyledDropdownItem = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${theme.colors.colorSemiDarkGray3};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const StyledDecimalsToggle = styled.div`
  width: 120px;
  color: #808080;
  font-size: 12px;
  cursor: pointer;
  margin: 10px 0;
  &:hover {
    color: #2f80ed;
  }
`;

export const ThreeDotMenuListItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: #4f4f4f;
  height: 44px;
  display: flex;
  width: 100%;
  padding: 0px 18px;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${theme.colors.colorPaleGray5};
    color: ${theme.colors.colorRoyalBlue};

    svg {
      path {
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;
export const IconContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  position: relative;
  margin: 0 20px;

  &::after {
    height: 20px;
    display: block;
    width: 1px;
    background: #e3e3e3;
    border-right: 1px white;
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
  }
`;

export const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    path {
      fill: #0074d9;
    }
  }
`;

export const ArchivedLabel = styled.span`
  position: absolute;
  top: -6px;
  left: 0;
  color: ${theme.colors.colorMediumGray7};
  font-size: 8px;
  font-weight: 600;
`;

export const MemberCircle = styled.div``;
export const MemberInfo = styled.div`
  position: relative;
  margin-left: 8px;
  min-width: 0;
`;
export const MemberName = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: #4a4a4a;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: unset;
`;

export const MemberRateDescription = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;

export const StyledMemberCard = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.isInTable ? '247px' : '180px')};
  margin-left: ${(props) => (props.isInTable ? '0px' : '14px')};
  cursor: pointer;
  flex: 1;
  // ensure content doesn't overflow its container
  min-width: 0;

  svg {
    height: 32px;
  }
  ${MemberName} {
    font-size: 14px;
    ${(props) =>
      props.isInTable &&
      `
    font-size: 14px;
    font-weight: 600;
    `}
    ${(props) =>
      props.isMemberArchived &&
      `
      color: ${theme.colors.colorCalendarGray};
    `}
  }
  .member-initials {
    .initial-div {
      font-size: 12px;
    }
  }
  .unassigned-icon {
    background: ${({ isInTable }) =>
      `url(${
        isInTable ? noAssigneeIcon : noAssigneeIconWhiteBG
      }) center center no-repeat`};
    background-size: cover;

    &.no-border {
      border: none;
    }
  }
`;

export const StyledProjectDescription = styled.div`
  font-size: 12px;
  position: absolute;
  width: 180px;
  color: ${theme.colors.colorMediumGray5};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
`;

export const StyledBudgetProjectContainer = styled.div`
  width: 180px;
  white-space: nowrap;
  position: relative;
  ${StyledProjectTitle} {
    font-size: 18px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledHelpIconContainer = styled.div`
  padding: 0px 8px;
  display: flex;
`;

export const MemberSwitcherInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  .regular-member-no-hover {
    height: 40px;
    width: 40px;
    flex: 0 0 40px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const MemberNameInModal = styled.div`
  font-size: 15px;
  margin-left: 10px;
  color: ${theme.colors.colorSemiDarkGray3};
`;

export const RateConfirmRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const RateDateContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
`;
export const RateMemberDescription = styled.div`
  display: flex;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

export const RateConfirmBox = styled.div`
  background-color: #ececec;
  padding: 6px;
  margin-bottom: 6px;
`;
export const CopyContainer = styled.div`
  color: #4f4f4f;
  white-space: pre-wrap;
`;
export const CopyHeader = styled.div`
  font-size: 16px;
  margin: 10px 0;
`;
export const CopyParagraph = styled.div`
  font-size: 11px;
  margin: 10px 0;
`;
export const GreyCopyParagraph = styled.div`
  font-size: 11px;
  margin: 10px 0;
  color: #828282;
`;
export const StyledDateChangeConfirmation = styled.div`
  width: 300px !important;
  position: absolute;
  background-color: white;
  z-index: 1080;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 15px;
`;

export const StyledPOWList = styled.div`
  position: relative;
  background: ${theme.colors.colorTranslucentGray4};

  ${({ isOnTeamView }) =>
    isOnTeamView
      ? ` border-top: 1px solid ${theme.colors.colorPaleGray5}`
      : ''};
  ${(props) =>
    !props.archived &&
    `padding-bottom: 30px;
  `}

  ${StyledMemberBudget} {
    ${(props) =>
      props.archived &&
      `
      background: transparent;
      box-shadow: 0 0 0 0;
      outline: none;
      position: relative;
      &:hover {
        box-shadow: 0 0 0 0;
      }`}
  }

  ${StyledDragGrips} {
    ${(props) =>
      (props.archived || props.dragDisabled) &&
      `visibility: hidden !important;`}
  }
`;

export const AffectedDate = styled.div`
  color: ${(props) => (props.affected ? '#2f80ed' : '#4F4F4F')};
  white-space: nowrap;
`;

export const StyledCheckBox = styled.div``;
export const OuterStyledCheckBox = styled.div`
  border: 1px solid transparent;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;
export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  margin-top: 20px;
  color: ${(props) =>
    props.checked ? props.theme.colors.colorRoyalBlue : '#828282'};

  &.not-billable-container {
    margin-left: 30px;
  }
  ${OuterStyledCheckBox} {
    margin-right: 4px;
    cursor: pointer;
    border: 1px solid
      ${({ checked, theme }) =>
        checked ? theme.colors.colorRoyalBlue : theme.colors.colorLightGray16};

    &.radio-button {
      border-radius: 10px;
    }
  }

  ${StyledCheckBox} {
    cursor: pointer;
    background-color: ${({ checked, theme }) =>
      checked ? theme.colors.colorRoyalBlue : theme.colors.colorPureWhite};
    height: 10px;
    width: 10px;
    border-radius: 3px;

    &.radio-button {
      border-radius: 10px;
    }
  }
`;

export const CheckBoxText = styled.div``;
export const AddPhaseButtonContainer = styled.div`
  display: inline-flex;
`;

export const Icon = styled.div`
  height: 24px;
  width: 24px;
`;

export const IssueIcon = styled(Icon)`
  background: url(${Issue}) no-repeat center;
`;

export const SaveTemplateContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 10px;
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  flex-direction: row;
`;
export const SaveTemplateText = styled.div`
  cursor: pointer;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;
export const ShowHideText = styled.div`
  font-weight: 800;
  cursor: pointer;
  color: ${theme.colors.colorMediumGray5};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const ToggleIcon = styled.div`
  padding: 2px 6px;
  font-size: 12px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.selected
      ? theme.colors.colorPureWhite
      : theme.colors.colorCalendarGray};
  background-color: ${(props) =>
    props.selected ? theme.colors.colorRoyalBlue : theme.colors.colorPureWhite};
  border: 1px solid
    ${(props) => (props.selected ? `transparent` : theme.colors.colorPaleGray5)};
  &:first-child {
    border-right: none;
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-left: none;
    border-radius: 0 3px 3px 0;
  }
  transition: 0.2s all;
`;
export const OverdueBudgetBarContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const BarLabelsContainer = styled.div`
  position: absolute;
  top: 7px;
  display: flex;
  width: 100%;

  &.unspent-budget {
    justify-content: flex-end;
  }
`;

export const OverdueBar = styled.div`
  width: ${({ flexWidth }) => flexWidth}%;
  display: ${({ shouldShow }) => (shouldShow ? 'flex' : 'none')};
  height: 8px;
  background: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 2px,
    #ff0000 4px
  );
  opacity: 0.7;
  position: absolute;
  right: 0px;
  pointer-events: none;

  &.unspent-budget {
  }
`;
export const MemberNameAndRateDescription = styled.div``;
export const DateContainerBar = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 65px);
  top: ${(props) => (props.isProjectViewReport ? '48px' : '12px')};
  visibility: visible;
`;
export const DateContainer = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.colorSemiDarkGray3};
`;

export const StyledBarContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1px;
  border-radius: 50px;
  height: 100%;
  align-items: center;
  ${({ isMember, phaseIsOverBudget }) =>
    isMember || phaseIsOverBudget ? 'display: flex; align-items:center;' : ''}

  &:hover {
    ${PercentHover} {
      visibility: visible;
    }
    .hide-on-hover {
      visibility: hidden;
    }
  }
`;

export const BarNumber = styled.div`
  font-weight: 600;
  z-index: 5;
  font-size: 12px;
  padding-top: 30px;
  width: 100%;
  ${(props) =>
    props.isHidden &&
    `
    && {
      visibility: hidden;
    }
  `}

  &.timeprojection {
    &.planned {
      text-align: right;
    }
  }
  &.spent-time,
  &.planned-time {
    &.no-value,
    &.spent,
    &.remaining,
    &.planned {
      display: none;
    }
  }

  &.unspent-budget {
    visibility: visible !important;

    &.spent {
      display: none;
    }

    &.remaining {
      text-align: right;
    }
  }

  &.budget-table-chart,
  &.budget-report,
  &.project-detail-budget {
    visibility: visible !important;
  }
`;
export const OverduePhaseProjectBudgetBar = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  width: 65px;
  display: none;
  align-items: center;
`;

export const OverduePhaseProjectNumber = styled.div`
  font-weight: 600;
  text-align: right;
  font-size: 14px;
  margin-right: 4px;
  margin-left: 4px;
  line-height: 1;
`;

export const BudgetProjectsContainer = styled.div`
  border-top: 1px solid ${theme.colors.colorPaleGray5};
  margin-top: 10px;
  overflow: auto;
`;

export const BudgetPhaseMilestoneIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;

  .milestone-icon-box {
    stroke: ${theme.colors.colorSemiDarkGray1};
  }
  .dollar-sign-in-icon {
    fill: ${theme.colors.colorSemiDarkGray1};
  }
`;

export const SelectRoleContainer = styled.div`
  display: ${({ isInTable }) => (isInTable ? 'block' : 'none')};
  color: ${theme.colors.colorCalendarGray};
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
  `}
  ${(props) =>
    !props.unset &&
    `
    color: ${theme.colors.colorRoyalBlue};
    &:active {
      color: ${theme.colors.colorCalendarRed};
    }
  `}
  ${(props) =>
    props.onClick &&
    `
    &:hover {
      filter: brightness(75%);
    }
    cursor: pointer;
  `}
`;

export const IssueIconContainer = styled.div`
  color: ${theme.colors.colorDeleteRed};
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: 800;
  margin-left: 2px;
  margin-top: -1px;
`;

export const CustomPhaseBottomCopy = styled.div`
  margin-top: 10px;
  font-size: 13px;
`;

export const Today = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  font-size: 12px;
  font-weight: 600;
  top: 0px;
  color: ${theme.colors.colorMediumOrange};
`;

export const TodayBar = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  height: 21px;
  width: 1px;
  top: -22px;
  background: ${theme.colors.colorMediumOrange};
`;

export const ProjectBudgetHeaderButtonRow = styled.div`
  display: flex;
  position: absolute;
  top: 47px;
  width: 300px;
  align-items: center;
`;

export const MemberStatusContainer = styled.div`
  font-size: 12px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorMediumGray5};
  cursor: pointer;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const Total = styled.span`
  position: absolute;
  right: ${(props) => (props.isOnReportsView ? '38px' : '22px')};
  top: ${(props) => (props.isOnReportsView ? '15px' : '0px')};

  color: ${theme.colors.colorCalendarGray};
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
`;

export const AddEditPhaseButton = styled.div`
  padding: 3px 11px 2px;
  width: fit-content;
  display: ${({ hide }) => (hide ? 'none' : 'inline-flex')};
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  border: 1px solid ${theme.colors.colorPaleBlue};
  cursor: pointer;
  color: ${theme.colors.colorCalendarBlue};
  background: ${({ dropdownOpen }) =>
    dropdownOpen ? theme.colors.colorTranslucentGray1 : ''};
  &:hover {
    background: ${theme.colors.colorTranslucentGray1};
  }
  flex-shrink: 0;
`;

export const UnassignedMemberRateDescription = styled(MemberName)``;
export const UnassignedMemberName = styled(MemberRateDescription)`
  ${({ isInTable }) =>
    isInTable
      ? `font-size: 12px; color: ${theme.colors.colorMediumGray5};`
      : `font-size: 14px; color ${theme.colors.colorSemiDarkGray1}; font-weight: 600;`};
`;

export const TaskCategoryHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
`;

export const StyledProjectLinkContainer = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  top: -25px;
  left: -24px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;
