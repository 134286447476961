import styled from 'styled-components';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';

import {
  openAddProjectModal,
  updateAccountFilter,
  fetchGroups
} from 'actionCreators';
import { getAuth } from 'selectors';

const NewProjectLink = ({ activeFilter }) => {
  const dispatch = useDispatch();

  const auth = useSelector(getAuth);

  const handleClick = () => {
    dispatch(
      openAddProjectModal({
        priorityId: 0,
        redirectOnSuccess: false,
        onSuccessCallback: handleAddProjectToFilteredList
      })
    );
  };

  const handleAddProjectToFilteredList = (res) => {
    const { id } = res;
    dispatch(
      updateAccountFilter({
        ...activeFilter,
        project_ids: [...activeFilter.project_ids, id]
      })
    );

    // need to re-fetch the groupslist to update the project count in the sidebar
    dispatch(fetchGroups(auth.token));
  };

  return (
    <StyledNewProjectLink onClick={handleClick}>
      + New Project
    </StyledNewProjectLink>
  );
};

const StyledNewProjectLink = styled.div`
  width: 250px;
  height: 77px;
  padding-left: 26px;
  padding-top: 15px;
  color: ${theme.colors.colorRoyalBlue};
  font-size: 12px;
  line-height: 13.8px;
  font-weight: 600;
  background-color: ${theme.colors.colorPureWhite};
  border-top: 1px solid ${theme.colors.colorLightGray12};
  &:hover {
    cursor: pointer;
  }
`;

export default NewProjectLink;
