import { MultiOptionDependency } from './MultiOptionDependency';
import { SingleLineDependency } from './SingleLineDependency';
import { DependencyControllerProps } from '../types';
import { dependencyComponentType } from '../constants';
import styled from 'styled-components';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';

const dependencyComponentMap = {
  [dependencyComponentType.multiOptionDependency]: MultiOptionDependency,
  [dependencyComponentType.singleLineDependency]: SingleLineDependency
};

export const DependencyController = ({
  dependencyFormState,
  dependencyComponentType,
  dependableItems
}: DependencyControllerProps) => {
  const DependencyComponent = dependencyComponentMap[dependencyComponentType];

  return (
    <DependencyContainer>
      <DependencyLabelContainer>
        <DependencyLinkIcon />
        DEPENDENCY
      </DependencyLabelContainer>
      <DependencyComponentContainer>
        <DependencyComponent
          dependencyFormState={dependencyFormState}
          dependableItems={dependableItems}
        />
      </DependencyComponentContainer>
    </DependencyContainer>
  );
};

const DependencyContainer = styled.div`
  margin-left: 15px;
`;

const DependencyLabelContainer = styled.div`
  color: ${({ theme }) => theme.colors.colorLightGray15};
  font-weight: 600;
  font-size: 12px;
`;

const DependencyComponentContainer = styled.div`
  margin-left: 12px;
`;
