import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import {
  createProjectComment,
  editProjectComment,
  deleteProjectComment
} from 'actionCreators';
import { AddProjectStatusForm, EditProjectStatusForm } from '..';
import {
  getAuthToken,
  getGroupsState,
  getSelectedGroupId,
  getProjectsState,
  getMe
} from 'selectors';

class AddEditStatusContainer extends React.Component {
  constructor(props) {
    super(props);
    const counter = props.status ? 160 - props.status.description.length : 160;
    this.state = {
      counter,
      description: props.status ? props.status.description : '',
      account: props.status ? props.status.account : {},
      date: props.status ? props.status.date : '',
      editing: false,
      buttonBlue: false
    };
    bindAll(this, [
      'addFormSubmit',
      'editFormSubmit',
      'handleChange',
      'resetState'
    ]);
  }

  resetState() {
    this.setButtonBlue();
    this.setState({ description: '', counter: 160 });
  }

  setEditTrue() {
    this.setState({ editing: true });
  }

  setEditFalse() {
    this.setState({ editing: false });
  }

  setButtonBlue() {
    this.setState({ buttonBlue: true });
  }

  setButtonGray() {
    this.setState({ buttonBlue: false });
  }

  handleBlur(e) {
    const classString = e.relatedTarget ? e.relatedTarget.className : '';
    if (
      !classString.includes('editing') &&
      !classString.includes('button-submit') &&
      this.state.description.length
    ) {
      this.addFormSubmit(e);
    }
  }

  handleBlurEdit(e) {
    if (
      !e.relatedTarget.className.includes('editing') &&
      !e.relatedTarget.className.includes('status-delete') &&
      !e.relatedTarget.className.includes('status-cancel')
    ) {
      this.editFormSubmit(e);
    }
  }

  handleChange(event) {
    this.setButtonBlue();
    const name = event.target.name;
    const nextDescriptionSize = event.target.value.length;
    const descriptionSize = this.state[name].length;
    if (!this.state.editing) {
      this.setState({ editing: true });
    }

    if (
      nextDescriptionSize > descriptionSize &&
      this.state.counter > 0 &&
      nextDescriptionSize - descriptionSize <= this.state.counter
    ) {
      const minus = nextDescriptionSize - descriptionSize;
      this.setState({
        [name]: event.target.value,
        counter: this.state.counter - minus
      });
    } else if (nextDescriptionSize < descriptionSize) {
      const more = descriptionSize - nextDescriptionSize;
      this.setState({
        [name]: event.target.value,
        counter: this.state.counter + more
      });
    }
  }

  addFormSubmit(event) {
    event.preventDefault();
    const { projectId, createProjectComment, me } = this.props;

    createProjectComment(projectId, this.state.description, me);
    this.setEditFalse();
    this.resetState();
  }

  editFormSubmit(event) {
    event.preventDefault();
    const { projectId, status, updateShowItem } = this.props;
    const { editProjectComment, deleteProjectComment } = this.props;
    if (this.state.description.replace(/\s/g, '')) {
      editProjectComment(
        projectId,
        this.state.description,
        this.props.me.id,
        status.id
      );
    } else {
      deleteProjectComment(projectId, status.id);
    }
    this.resetState();
    updateShowItem();
  }

  render() {
    const { isAddForm, statusCount, me } = this.props;
    const setEditTrue = this.setEditTrue.bind(this);
    const handleBlur = this.handleBlur.bind(this);
    const handleBlurEdit = this.handleBlurEdit.bind(this);

    if (isAddForm) {
      return (
        <AddProjectStatusForm
          me={me}
          description={this.state.description}
          counter={this.state.counter}
          handleChange={this.handleChange}
          formSubmit={this.addFormSubmit}
          statusCount={statusCount}
          setEditTrue={setEditTrue}
          handleBlur={handleBlur}
          classes={{
            editing: this.state.editing,
            'button-blue': this.state.buttonBlue
          }}
        />
      );
    }
    return (
      <EditProjectStatusForm
        setEditTrue={setEditTrue}
        description={this.state.description}
        counter={this.state.counter}
        handleChange={this.handleChange}
        formSubmit={this.editFormSubmit}
        account={this.state.account}
        date={this.state.date}
        updateShowItem={this.props.updateShowItem}
        clearDescription={this.resetState}
        handleBlur={handleBlurEdit}
        classes={{
          editing: this.state.editing,
          'button-blue': this.state.buttonBlue
        }}
      />
    );
  }
}

AddEditStatusContainer.propTypes = {
  updateShowItem: PropTypes.func,
  isAddForm: PropTypes.bool,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  createProjectComment: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  status: PropTypes.object,
  editProjectComment: PropTypes.func.isRequired,
  deleteProjectComment: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  me: getMe(state),
  projects: getProjectsState(state),
  boardId: getSelectedGroupId(state),
  groups: getGroupsState(state),
  myToken: getAuthToken(state)
});

const mapDispatchToProps = {
  createProjectComment,
  editProjectComment,
  deleteProjectComment
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createProjectComment: (projectId, description, account) =>
    dispatchProps.createProjectComment(
      stateProps.myToken,
      projectId,
      description,
      account
    ),
  editProjectComment: (projectId, description, accountId, statusId) =>
    dispatchProps.editProjectComment(
      stateProps.myToken,
      projectId,
      description,
      accountId,
      statusId
    ),
  deleteProjectComment: (projectId, statusId) =>
    dispatchProps.deleteProjectComment(stateProps.myToken, projectId, statusId)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddEditStatusContainer);
