import React from 'react';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import getCaretCoordinates from 'textarea-caret';
import { CommentInputContainer, OutsideClickWrapper } from '../..';
import { commentTypes } from 'appConstants/commentConstants';

class ProjectReplyCommentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentBody: '',
      suggestionListLeft: 0,
      tempFiles: []
    };
    bindAll(this, [
      'expandRow',
      'contractRow',
      'handleReplyChange',
      'handleReplySubmit',
      'handleCancel',
      'handleKeyPress',
      'handleReplyBoxBlur',
      'handleFiles',
      'removeFile'
    ]);
  }

  getPermissions = () => {
    const {
      selectedTeamId,
      project: { id }
    } = this.props;
    const permissions = {
      teamId: selectedTeamId,
      projectId: id
    };
    return permissions;
  };
  componentDidUpdate(prevProps) {
    const { commentThread, isReplyOpen } = this.props;

    const replyButtonPressed = !prevProps.isReplyOpen && isReplyOpen;
    const hasNoReplies = commentThread.replies && !commentThread.replies.length;
    if (replyButtonPressed && hasNoReplies) {
      document
        .querySelector(
          `#thread-container-${commentThread.id} .comment-input textarea`
        )
        .focus();
      this.expandRow();
    }
  }

  expandRow() {
    this.setState({ isExpanded: true });
  }

  contractRow() {
    this.setState({ isExpanded: false });
  }

  handleReplyChange(event) {
    this.setState({ commentBody: event.target.value });
  }

  handleReplySubmit(fromEnter) {
    const { me, project, commentThread, createComment, closeReplyInput } =
      this.props;
    const { commentBody, tempFiles } = this.state;
    const permissions = this.getPermissions();
    if (commentBody.length) {
      createComment({
        projectId: project.id,
        description: this.state.commentBody,
        parentId: commentThread.id,
        account: me,
        files: tempFiles,
        permissions
      });
    }

    if (!fromEnter) {
      this.contractRow();
    }
    this.setState({ commentBody: '' });
    this.setState({ tempFiles: [] });
    closeReplyInput();
  }

  handleCancel() {
    const { commentThread, closeReplyInput } = this.props;
    this.setState({ commentBody: '' });
    this.contractRow();

    if (!commentThread.replies.length) {
      closeReplyInput();
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && this.state.commentBody.length) {
      event.target.blur();
      event.preventDefault();
      this.handleReplySubmit(true);
    }

    if (event.key === '@') {
      const commentInput = document.querySelector(
        '.comment-input .input textarea'
      );
      const suggestionListLeft = getCaretCoordinates(
        commentInput,
        commentInput.selectionEnd
      ).left;
      this.setState({ suggestionListLeft });
    }
  }

  handleReplyBoxBlur(e) {
    const { commentThread, closeReplyInput } = this.props;
    const trueBlur = e.target.id !== 'add-reply-input';

    const hasReplies = commentThread.replies && commentThread.replies.length;
    const isInputEmpty = !this.state.commentBody.length;

    if (!hasReplies && isInputEmpty && trueBlur) {
      this.contractRow();
      closeReplyInput();
    } else if (hasReplies && isInputEmpty && trueBlur) {
      this.contractRow();
    }
  }

  handleFiles(files) {
    this.setState({ tempFiles: Array.from(files) });
  }

  removeFile(file) {
    this.setState((prevState) => ({
      tempFiles: prevState.tempFiles.filter((stateFile) => stateFile !== file)
    }));
  }

  render() {
    const { commentBody, isExpanded, suggestionListLeft, tempFiles } =
      this.state;
    const { project } = this.props;

    return (
      <OutsideClickWrapper handleOutsideClick={this.handleReplyBoxBlur}>
        <div
          className={cn('reply-comment-container', {
            hidden: !this.props.isReplyOpen
          })}
        >
          <CommentInputContainer
            isReply
            className="comment-input"
            isExpanded={isExpanded}
            commentType={commentTypes.REPLY}
            commentBody={commentBody}
            suggestionListLeft={suggestionListLeft}
            onClick={this.expandRow}
            onCancel={this.handleCancel}
            onChange={this.handleReplyChange}
            onSubmit={this.handleReplySubmit}
            onKeyPress={this.handleKeyPress}
            handleFiles={this.handleFiles}
            unsavedFiles={tempFiles}
            removeFile={this.removeFile}
            project={project}
            projectId={project?.id}
            isReplyOpen={this.props.isReplyOpen}
          />
        </div>
      </OutsideClickWrapper>
    );
  }
}

export default ProjectReplyCommentContainer;
