import { AccountFilter } from 'models/filter';
import {
  FILTER_SCHEMA_NAME_DELIMITER,
  UNSPECIFIED_FILTER_LEVEL,
  FILTER_LEVEL_TYPES
} from '../constants';

/**
 * Returns filter level name based on level values
 * eg. given level1 = 'Time Variance', level3 = 'members'
 *     returns 'Time Variance -> ___ -> members'
 */
export const serializeFilterLevelName = ({
  level1,
  level2,
  level3,
  level4
}: {
  level1?: Nullable<string>;
  level2?: Nullable<string>;
  level3?: Nullable<string>;
  level4?: Nullable<string>;
}) => {
  let hasFoundValue = false;
  const levels: string[] = [];
  [level4, level3, level2, level1].forEach((level) => {
    if (level === undefined) {
      // only add unspecified levels if there is an existing level after it
      // (we want 'Time Variance', not 'Time Variance -> ___ -> ___ -> ___')
      if (hasFoundValue) {
        levels.push(UNSPECIFIED_FILTER_LEVEL);
      }
    } else {
      levels.push('' + level);
      hasFoundValue = true;
    }
  });
  return levels.reverse().join(FILTER_SCHEMA_NAME_DELIMITER);
};

/**
 * Returns the level values for a given filter level name
 */
export const deserializeFilterLevelName = (filterLevelName: string) => {
  const [level1, level2, level3, level4] = filterLevelName
    .split(FILTER_SCHEMA_NAME_DELIMITER)
    .map((level) => (level === UNSPECIFIED_FILTER_LEVEL ? undefined : level));

  return {
    level1,
    level2,
    level3,
    level4
  };
};

/**
 * Returns the matching filter level type for the given filterLevelName
 * eg. given 'Time Variance -> projects -> ___ -> 'location'
 *     returns '12_4'
 */
export const getFilterLevelTypeFromFilterLevelName = (
  filterLevelName: string
) => {
  const { level1, level2, level3, level4 } =
    deserializeFilterLevelName(filterLevelName);
  if (level1 && level2 && level3 && level4) return FILTER_LEVEL_TYPES['1234'];
  if (level1 && level2 && !level3 && level4) return FILTER_LEVEL_TYPES['12_4'];
  if (level1 && !level2 && level3 && level4) return FILTER_LEVEL_TYPES['1_34'];
  if (level1 && !level2 && !level3 && level4) return FILTER_LEVEL_TYPES['1__4'];

  if (level1 && level2 && level3 && !level4) return FILTER_LEVEL_TYPES['123'];
  if (level1 && !level2 && level3 && !level4) return FILTER_LEVEL_TYPES['1_3'];

  if (level1 && level2 && !level3 && !level4) return FILTER_LEVEL_TYPES['12'];
  if (level1 && !level2 && !level3 && !level4) return FILTER_LEVEL_TYPES['1'];
};

/**
 * eg. given currentFilterLevelName = 'Time Variance', nextLevelValue = 'members'
 *     returns 'Time Variance -> members'
 */
export const addLevelToFilterLevelName = (
  currentFilterLevelName: string,
  nextLevelValue: unknown
) =>
  `${currentFilterLevelName}${FILTER_SCHEMA_NAME_DELIMITER}${nextLevelValue}`;

/**
 * filterLevelNames include page name for consistency/readability, but the filters are
 * saved to BE with name = filterLevelName without page name. eg. a filter for 'Workload Planner -> members'
 * would have page_name: 'Workload Planner', and name: 'members'
 *
 * ^ this keeps filter schemas backward compatible, since this is how we've been naming our filters
 */
export const getFilterNameFromFilterLevelName = (filterLevelName: string) => {
  const { level2, level3, level4 } =
    deserializeFilterLevelName(filterLevelName);
  return serializeFilterLevelName({
    level1: level2,
    level2: level3,
    level3: level4
  });
};

/** see getFilterNameFromFilterLevelName  */
export const makeFilterLevelNameFromFilter = (filter: AccountFilter) => {
  const { page_name, name } = filter;
  return `${page_name}${FILTER_SCHEMA_NAME_DELIMITER}${name}`;
};

/**
 * Returns all matching level names (including those with unspecified levels) for the given filterName.
 * eg. given 'Time Variance -> projects -> role'
 *     returns [
 *       'Time Variance',
 *       'Time Variance -> projects',
 *       'Time Variance -> ___ -> role',
 *       'Time Variance -> projects -> role'
 *    ]
 */
export const generateMatchingFilterLevelNames = (filterLevelName: string) => {
  const { level1, level2, level3, level4 } =
    deserializeFilterLevelName(filterLevelName);

  // level1 should always exist. this is just preventing ts error
  if (!level1) return [];

  const matchingFilterLevelNames: string[] = [];

  // FILTER_LEVEL_TYPES['1'] - level1 will always exist (page level)
  matchingFilterLevelNames.push(
    serializeFilterLevelName({
      level1
    })
  );

  // Level 2
  if (level2) {
    // FILTER_LEVEL_TYPES['12']
    matchingFilterLevelNames.push(
      serializeFilterLevelName({
        level1,
        level2
      })
    );
  }

  // Level 3
  if (level3) {
    // FILTER_LEVEL_TYPES['1_3'] matches 1_3 and 123, so if level3 exists, add it
    matchingFilterLevelNames.push(
      serializeFilterLevelName({
        level1,
        level3
      })
    );
    if (level2) {
      // FILTER_LEVEL_TYPES['123'] if level2 and level3 exist
      matchingFilterLevelNames.push(
        serializeFilterLevelName({
          level1,
          level2,
          level3
        })
      );
    }
  }

  // Level 4
  if (level4) {
    // FILTER_LEVEL_TYPES['1__4'] matches the other level types below, so if level4 exists, add it
    matchingFilterLevelNames.push(
      serializeFilterLevelName({
        level1,
        level4
      })
    );
    if (level2) {
      // FILTER_LEVEL_TYPES['12_4']
      matchingFilterLevelNames.push(
        serializeFilterLevelName({
          level1,
          level2,
          level4
        })
      );
    }
    if (level3) {
      // FILTER_LEVEL_TYPES['1_34']
      matchingFilterLevelNames.push(
        serializeFilterLevelName({
          level1,
          level3,
          level4
        })
      );
    }
    if (level2 && level3) {
      // FILTER_LEVEL_TYPES['1234']
      matchingFilterLevelNames.push(
        serializeFilterLevelName({
          level1,
          level2,
          level3,
          level4
        })
      );
    }
  }

  return matchingFilterLevelNames;
};
