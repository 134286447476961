import React from 'react';
import { connect } from 'react-redux';
import { addMemberToProject, clearTimeEntryError } from 'actionCreators';
import {
  getSelectedTeamId,
  getUserIsAdmin,
  getMyUserId,
  makeGetProjectMembershipsByAccountId,
  makeGetPlaceholderInfoForTimesheetDay,
  getIsOnReportsView,
  getMyTeamMembership,
  getTimesheetsPredictionsByDate
} from 'selectors';

import cn from 'classnames';

import {
  StyledDayInput,
  StyledDayInputContainer,
  DayTimesheetContainer,
  IssueIconContainer
} from './../styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import JoinProjectModal from 'views/projectPlanner/plannerModal/JoinProjectModal';
import Popover from 'components/Popover';
import {
  formatNumWithMaxTwoDecimals,
  formatNumToNearestHalf
} from 'appUtils/formatUtils';
import moment from 'moment';

import { timesheetOptions } from 'appConstants/timesheets';

/* TODO: Refactor Join Project Modal + success callback to not have to be rendered/handled in individual timesheet cells. See TimesheetDay, TimesheetDescription, TimesheetActivity, and TimesheetProject -> ProjectsAndPhasesDropdown */
class TimesheetDay extends React.Component {
  state = {
    hours:
      this.props.timeEntry && this.props.timeEntry.hours
        ? this.props.timeEntry.hours
        : '',
    selectedProject: null,
    joinModalOpen: false,
    dropdownOpen: false,
    isEditing: false,
    showWarning: false
  };

  hourInput = React.createRef();

  componentDidMount = () => {
    this.hourInput.current.addEventListener('keydown', this.checkEnterPress);
    rebuildTooltip();
  };

  componentDidUpdate = (prevProps) => {
    const { timeEntry, activity } = this.props;
    const hoursWerePresent = prevProps.timeEntry?.hours;
    const hoursArePresent = timeEntry?.hours;
    const activityWasPresent = !!prevProps.activity?.id;
    const activityIsPresent = !!activity?.id;
    if (
      (timeEntry?.createRow && !prevProps.timeEntry?.createRow) ||
      (hoursArePresent &&
        (!hoursWerePresent || prevProps.timeEntry.hours !== timeEntry.hours))
    ) {
      this.setState({ hours: timeEntry.hours });
    }
    if (hoursWerePresent && !hoursArePresent) {
      this.setState({ hours: timeEntry.hours });
    }
    if (!activityWasPresent && activityIsPresent) {
      this.setState({ showWarning: false });
    }
    rebuildTooltip();
  };

  componentWillUnmount = () => {
    this.hourInput.current.removeEventListener('keydown', this.checkEnterPress);
  };

  getPermissions = () => {
    const { projectId, selectedTeamId, accountId, myId } = this.props;
    return {
      projectId,
      teamId: selectedTeamId,
      mine: accountId === myId
    };
  };

  checkEnterPress = (e) => {
    if (e.keyCode === 13) {
      this.hourInput.current.blur();
    }
  };

  handleChange = (e) => {
    const hours = e.target.value.match(/\d{0,8}(\.\d{0,2})?/)[0];
    this.setState({ hours });
  };

  handleHourSubmit = () => {
    const { hours } = this.state;
    if (+hours === 0) {
      this.setState({ hours: '' }, this.changeEntryAttempt);
    } else if (hours.charAt(hours.length - 1) === '.') {
      this.setState({ hours: hours.slice(0, -1) }, this.changeEntryAttempt);
    } else {
      this.changeEntryAttempt();
    }
    this.setState({
      isEditing: false
    });
  };

  setJoinProject = (project) => {
    this.setState({
      selectedProject: project,
      joinModalOpen: true
    });
  };

  joinProject = () => {
    const { addMemberToProject, accountId } = this.props;
    const { selectedProject } = this.state;
    const basicMemberRole = 3;
    const onSuccess = [
      {
        successAction: this.changeEntry,
        selector: () => {}
      }
    ];
    addMemberToProject(
      selectedProject.id,
      accountId,
      basicMemberRole,
      selectedProject.board_id,
      onSuccess
    );
    this.clearJoinProject();
  };

  clearJoinProject = () =>
    this.setState({ selectedProject: null, joinModalOpen: false });

  changeEntryAttempt = () => {
    const { accountId, projectMembersByAccountId, project } = this.props;
    if (projectMembersByAccountId[accountId] || !accountId || !project) {
      this.changeEntry();
    } else {
      this.setJoinProject(project);
    }
  };

  changeEntry = () => {
    const {
      handleUpdateTimeEntry,
      day,
      timeEntry,
      accountId,
      description,
      project,
      activity,
      handleCreateTimeEntry,
      handleDeleteTimeEntry,
      phase
    } = this.props;
    const { hours } = this.state;
    const hasHours = +hours > 0;
    const permissions = this.getPermissions();

    if (!timeEntry?.id && hasHours) {
      handleCreateTimeEntry({
        day,
        descriptionId: description?.id,
        hours,
        accountId,
        projectId: project?.id,
        planned: false,
        phaseId: phase?.id,
        billable: project?.billable && activity?.billable && phase?.billable,
        permissions
      });
    } else if (hasHours) {
      handleUpdateTimeEntry({
        timesheetId: timeEntry.id,
        day,
        descriptionId: description?.id,
        hours,
        billable: timeEntry.billable,
        status: timeEntry.status,
        permissions
      });
    } else if (timeEntry?.id) {
      handleDeleteTimeEntry({
        days: [day],
        timesheetIds: [timeEntry.id],
        descriptionIds: [description?.id],
        permissions
      });
    }
  };

  clearTimeEntryError = (e) => {
    e.stopPropagation();
    const { day, description, clearTimeEntryError } = this.props;
    clearTimeEntryError({
      day,
      descriptionId: description?.id
    });
  };

  onClickFocus = () => {
    const { isDisabled, onClickFocus, shouldTriggerDropdown } = this.props;

    if (onClickFocus) {
      onClickFocus();
    }
    if (shouldTriggerDropdown) {
      this.setState({ dropdownOpen: true });
    }
    if (isDisabled) {
      this.setState({ showWarning: true });
      return;
    }
    this.hourInput.current.focus();
  };

  renderDropdown = () => {
    const { dropdownContents, target } = this.props;
    return (
      <Popover
        target={target || this.target}
        isOpen
        closePopover={() => this.setState({ dropdownOpen: false })}
      >
        <div onClick={() => this.setState({ dropdownOpen: false })}>
          {dropdownContents()}
        </div>
      </Popover>
    );
  };

  renderError = () => {
    return (
      <IssueIconContainer
        onClick={this.clearTimeEntryError}
        style={{ cursor: 'pointer' }}
      >
        !
      </IssueIconContainer>
    );
  };

  handleFocus = () => {
    this.setState({
      isEditing: true
    });
  };

  generatePlaceholder = (plannedHours, checkinsHours, timerHours) => {
    if (plannedHours === checkinsHours && plannedHours === timerHours) {
      // if all hours are the same
      return timerHours;
    }
    const formattedHoursArr = [plannedHours, checkinsHours, timerHours].filter(
      (hours) => hours > 0
    );
    const min = Math.min(
      ...(formattedHoursArr.length > 0 ? formattedHoursArr : [0])
    );
    const max = Math.max(
      ...(formattedHoursArr.length > 0 ? formattedHoursArr : [0])
    );
    if (min === max) {
      return min;
    } else {
      return min + '-' + max;
    }
  };

  generatePlaceholderTooltip = ({
    formattedPlannedHours,
    formattedCheckinsHours,
    formattedTimerHours,
    mosaicEstimate
  }) => {
    return `<div>
      <div class='timesheet-placeholder'>
        <div>Planned </div>
        <div> ${formattedPlannedHours}</div>
      </div>
      <div class='timesheet-placeholder'>
        <div>Check-in </div>
        <div> ${formattedCheckinsHours}</div>
      </div>
      <div class='timesheet-placeholder'>
        <div>Timer </div>
        <div> ${formattedTimerHours}</div>
      </div>
      <div class='timesheet-placeholder'>Mosaic Est.
      <div> ${mosaicEstimate} </div>
    </div>
    </div>`;
  };

  render() {
    const {
      isCurrentUser,
      isAdmin,
      activitySelected,
      ContainerEl,
      renderActivityIcon,
      renderStatus,
      isDisabled,
      containerDataTip = '',
      accountId,
      myId,
      renderSyncStatus,
      info,
      timeEntry,
      project,
      timesheetColumnIndex,
      placeholderInfo,
      isOnReportsView,
      myTeamMembership,
      timesheetsPredictionsByDate,
      phase,
      day
    } = this.props;

    const {
      hours,
      joinModalOpen,
      selectedProject,
      dropdownOpen,
      isEditing,
      showWarning
    } = this.state;
    const error = info?.error || timeEntry?.error;
    const errorRegex = /Internal Server Error/gi;
    const filteredError = !errorRegex.test(error) && error;

    const formattedPlannedHours = formatNumToNearestHalf(
      placeholderInfo.plannedHours || 0
    );
    const formattedCheckinsHours = formatNumToNearestHalf(
      placeholderInfo.checkinsHours || 0
    );
    const formattedTimerHours = formatNumToNearestHalf(
      placeholderInfo.timerHours || 0
    );

    const formattedDate = moment(day).format('YYYY-MM-DD');

    // for workplan/checkins placeholder setting. if theres checkins hours we use that, otherwise use planned hours. null otherwise (we dont want to show 0)
    const workplanAndCheckinsHours =
      formattedCheckinsHours > 0
        ? formattedCheckinsHours
        : formattedPlannedHours > 0
        ? formattedPlannedHours
        : null;

    const mosaicEstimateRaw =
      timesheetsPredictionsByDate[formattedDate]?.[phase?.id];
    const mosaicEstimate =
      mosaicEstimateRaw !== undefined ? Math.round(mosaicEstimateRaw) : null;
    const placeholderSetting =
      myTeamMembership?.timesheet_preferences.show_placeholder;

    const placeholderValue =
      placeholderSetting === timesheetOptions.WORK_PLANS_AND_CHECK_INS
        ? workplanAndCheckinsHours
        : placeholderSetting === timesheetOptions.MOSAIC_ESTIMATES
        ? mosaicEstimate
        : null;

    // Do not show `null` and `0` values.
    const showPlaceholder = !!placeholderValue;

    const placeholderTooltip = showPlaceholder
      ? this.generatePlaceholderTooltip({
          formattedPlannedHours,
          formattedCheckinsHours,
          formattedTimerHours,
          mosaicEstimate
        })
      : null;

    const hoursValue = isEditing
      ? hours || ''
      : hours
      ? formatNumWithMaxTwoDecimals(hours)
      : '';

    const showTooltip =
      showWarning || (showPlaceholder && !isOnReportsView && !hours);

    const TooltipData = showWarning
      ? filteredError || containerDataTip
      : placeholderTooltip;

    return (
      <ContainerEl
        data-for="app-tooltip"
        data-tip-disable={!showTooltip}
        data-delay-show={500}
        data-effect="solid"
        data-tip={TooltipData}
        data-html
        style={{ cursor: activitySelected ? 'pointer' : '' }}
        onClick={this.onClickFocus}
        ref={(ref) => (this.target = ref)}
      >
        {renderActivityIcon && renderActivityIcon()}
        <DayTimesheetContainer>
          {renderStatus &&
            renderStatus({
              onClick: () => this.setState({ dropdownOpen: true })
            })}
          {renderSyncStatus && renderSyncStatus()}
          <StyledDayInputContainer>
            <StyledDayInput
              style={{
                width: 35,
                paddingLeft: '0px',
                textAlign: 'right',
                marginLeft: '-5px'
              }}
              ref={this.hourInput}
              className={cn('timesheet-day', {
                'enable-hover': isCurrentUser || isAdmin,
                'is-error': error
              })}
              type="text"
              value={hoursValue}
              onChange={this.handleChange}
              disabled={isDisabled}
              onBlur={this.handleHourSubmit}
              data-testid={`${project?.title} ${timesheetColumnIndex}`}
              onFocus={this.handleFocus}
              placeholder={showPlaceholder ? placeholderValue : ''}
            />
            {error && this.renderError()}
          </StyledDayInputContainer>
        </DayTimesheetContainer>
        <JoinProjectModal
          open={joinModalOpen}
          toggle={this.clearJoinProject}
          selectedProject={selectedProject}
          onConfirm={this.joinProject}
          text={'edit a timesheet entry'}
          accountId={accountId}
          currentUserId={myId}
        />
        {dropdownOpen && this.renderDropdown()}
      </ContainerEl>
    );
  }
}

const makeMapStateToProps = () => {
  const getProjectMembersByAccountId = makeGetProjectMembershipsByAccountId();
  const getPlaceholderInfoForTimesheetDay =
    makeGetPlaceholderInfoForTimesheetDay();

  const mapStateToProps = (state, ownProps) => ({
    selectedTeamId: getSelectedTeamId(state),
    isAdmin: getUserIsAdmin(state),
    projectMembersByAccountId: getProjectMembersByAccountId(state, ownProps),
    isCurrentUser: getMyUserId(state) === ownProps.accountId,
    myId: getMyUserId(state),
    placeholderInfo: getPlaceholderInfoForTimesheetDay(state, ownProps),
    isOnReportsView: getIsOnReportsView(state),
    myTeamMembership: getMyTeamMembership(state),
    timesheetsPredictionsByDate: getTimesheetsPredictionsByDate(state)
  });
  return mapStateToProps;
};
const mapDispatchToProps = {
  addMemberToProject,
  clearTimeEntryError
};

export default connect(makeMapStateToProps, mapDispatchToProps)(TimesheetDay);
