import styled from 'styled-components';
import { calculateColorPref } from 'appUtils/styleUtils';
import theme from 'theme';
import SettingsIcon from 'icons/SettingsIcon';

export const MainMenuLink = styled.div`
  height: 40px;
  margin: auto;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  span {
    opacity: 1;
  }
`;

export const StyledSettingsIconContainer = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 38px;
  background: ${theme.colors.colorMediumGray5};
  background: ${calculateColorPref};
`;
export const StyledSettingsIcon = styled(SettingsIcon)`
  path {
    stroke: ${theme.colors.colorPureWhite};
  }
`;
export const MenuLabel = styled.div`
  height: 24px;
  margin-left: 18px;
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  cursor: pointer;
  align-items: center;
  color: #fff;
  font-weight: ${(props) => (props.isModuleSelected ? '700' : 'normal')};
  font-family: 'Open Sans';
  line-height: 19px;
  font-size: 14px;
`;
export const ActivityLink = styled(MainMenuLink)`
  margin-left: -1px;
`;
export const ProjectLink = styled(MainMenuLink)`
  margin: 20px 0;
  &:hover {
    img {
      filter: brightness(2);
    }
  }
`;
export const HomeLink = styled(MainMenuLink)`
  margin: 20px 0;
`;
export const LogoLink = styled(MainMenuLink)`
  margin-top: 22px;
  margin-bottom: 20px;
  height: 44px;
  display: flex;
  flex-direction: column;
`;
export const TeamLink = styled(MainMenuLink)``;
export const PlannerLink = styled(MainMenuLink)``;
export const StyledCautionIcon = styled.img`
  position: absolute;
  visibility: hidden;

  height: 12px;
  width: 12px;
  right: 6px;
  top: 0;
`;
export const TimesheetLink = styled(MainMenuLink)`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: relative;
  &:hover ${StyledCautionIcon} {
    visibility: visible;
  }
`;

export const TimelinesLink = styled(MainMenuLink)``;
export const BoardsLink = styled(MainMenuLink)``;
export const WorkloadLink = styled(MainMenuLink)``;
export const ReportLink = styled(MainMenuLink)``;

export const HorizontalLine = styled(MainMenuLink)`
  height: 1px;
  background: #fff;
  opacity: ${(props) => (props.isExpanded ? '0.3' : '0')};
  padding: 0px;
  margin: 0px 14px;
`;
export const TeamExpandedWrapper = styled(MainMenuLink)`
  padding-left: 0px;
  &:hover {
    path {
      fill: #fff;
    }
  }
`;
export const InviteButtonLink = styled(MainMenuLink)`
  margin: 0;
  margin-bottom: 20px;
`;

export const Img = styled.img`
  display: ${(props) => (props.isModuleSelected ? 'none' : 'flex')};
  max-height: 26px;
  opacity: 0.6;
`;

export const SpaceDescription = styled.div`
  position: relative;
  left: -1px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  visibility: visible;
  opacity: ${(props) => (props.isModuleSelected ? '1' : '0.7')};
`;

export const ImageContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-in-out;
  position: relative;
  background: ${(props) =>
    props.isModuleSelected ? 'rgba(78, 90, 105, 1)' : ''};
  &:hover {
    /* background: rgba(78, 90, 105, 0.8); */
    ${Img} {
      opacity: 1;
    }
    ${SpaceDescription} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const DarkImg = styled.img`
  display: ${(props) => (props.isModuleSelected ? 'flex' : 'none')};
  max-height: 26px;
`;

export const LogoImg = styled.img`
  width: 144px;
  height: 65px;
  position: absolute;
  top: 10px;
  left: 53px;
`;
export const HamburgerImg = styled.img`
  width: 36px;
  height: 36px;
  margin-top: 10px;
  &:hover {
    background-color: #697990;
  }
`;

export const GearImg = styled.div`
  display: block;
  position: absolute;
  margin-left: 210px;
`;
export const TeamInitialsWrapper = styled.div`
  cursor: pointer;
  padding: 0px;
  margin-bottom: -10px;
  width: 100%;
  transition: 0.275s ease-in-out;
`;

export const Initials = styled.div``;
export const TeamInitials = styled.div`
  height: 38px;
  width: 38px;
  background: ${calculateColorPref};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 300px;
  margin: auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  text-align: -webkit-center;
  line-height: 24px;
  ${StyledSettingsIcon} {
    display: none;
  }
  &:hover {
    ${Initials} {
      display: none;
    }
    ${StyledSettingsIcon} {
      display: flex;
    }
    filter: brightness(1.1);
  }
`;

export const TeamName = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  width: 155px;
`;
export const LogoMenuGroup = styled.div`
  margin: 25px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuList = styled.div`
  margin-right: 12px;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  flex-flow: column;
`;

export const TeamList = styled.div`
  p {
    margin-top: 1rem;
    margin-left: 18px;
    color: #9b9b9b;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }
`;

export const TeamListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
`;

export const TeamTitleSettings = styled.div`
  height: 34px;
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const TeamListLink = styled.div`
  height: 34px;
  width: 174px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
  margin-right: 48px;
  margin-bottom: 5px;
  padding-left: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100% - 16px);

  &:hover {
    background-color: #f0f0f0;
  }
`;
export const BoardsDropdownWrapper = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 30px;
  left: ${(props) => (props.teamBoardMenuOpen ? '203px' : '182px')};
  transform: ${(props) =>
    props.teamBoardMenuOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

export const BoardCount = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding-right: 10px;
  color: ${({ theme }) => theme.colors.colorPureWhite};
`;

export const InviteImg = styled.img`
  display: block;
`;
export const InviteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  background: transparent;
  border-radius: 300px;
  border: 1px solid transparent;
`;
export const HelpButtonLink = styled(MainMenuLink)`
  margin: 0;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: relative;
`;
export const HelpButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 39px;
  line-height: 1;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.colorHelpBlue : 'rgba(255, 255, 255, 0.12)'};
  border-radius: 300px;
  font-weight: 800;
  border: none;
  &:hover {
    filter: brightness(1.1);

    background: ${({ theme }) => theme.colors.colorHelpBlue};
  }
`;
export const MemberInitialBorder = styled.div`
  border: 2px solid ${theme.colors.colorDarkBlue2};
  border-radius: 64px;
`;
export const MemberInitialsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  ${StyledSettingsIconContainer} {
    display: none;
  }

  &:hover {
    filter: brightness(1.1);
    ${StyledSettingsIconContainer} {
      display: flex;
    }
    .sidebar-member-initials {
      display: none;
    }
  }

  .sidebar-member-initials {
    height: 38px;
    width: 38px;
    flex: 0 0 38px;

    .initial-div {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans';
      white-space: nowrap;

      &.long-initials {
        font-size: 13px;
      }
    }
  }
`;
export const MessageText = styled.div`
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 5px;
  position: fixed;
  left: 70px;
  color: ${({ theme }) => theme.colors.colorPureWhite};
  background: ${({ theme }) => theme.colors.colorHelpBlue};

  &:before {
    content: ' ';
    height: 12px;
    position: absolute;
    width: 12px;
    left: -6px;
    top: 12px;
    transform: rotate(45deg);
    background: ${({ theme }) => theme.colors.colorHelpBlue};
  }
`;

export const SettingsContainer = styled.div`
  margin-top: 6px;
  &:hover {
    ${TeamInitialsWrapper} {
      margin-bottom: 3px;
    }
  }
`;

export const MembersContainer = styled(MainMenuLink)`
  /* background: ${(props) =>
    props.isModuleSelected
      ? 'rgba(78, 90, 105, 1)'
      : 'rgba(255, 255, 255, 0.12)'};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
  ${ImageContainer} {
    margin-left: 2px;
  } */
`;
