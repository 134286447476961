import { MouseEvent } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

interface TodayButtonProps {
  active?: boolean;
  variant?: 'default' | 'compact';
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}
export const TodayButton = ({ onClick, active }: TodayButtonProps) => (
  <StyledTodayButton
    className={cn('app-cues-today-button', {
      active
    })}
    onClick={onClick}
  >
    Today
  </StyledTodayButton>
);

const StyledTodayButton = styled.button`
  height: 20px;
  border: none;
  background: transparent;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.colorMediumGray4};
  cursor: pointer;
  font-size: 12px;
  padding: 0 8px;
  &.active {
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.colorCalendarOrange};
    font-weight: 600;

    &:hover {
      color: white;
      background: ${({ theme }) => theme.colors.colorCalendarOrange};
    }
  }
`;
