import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import Tribute from 'tributejs';
import { createAddNewTagTemplate } from 'appUtils/tributeUtils';
import DeleteNoteMenu from 'NoteModule/components/noteSettings/DeleteNoteMenu';
import NoteTitleWithTags from 'NoteModule/components/noteDisplay/NoteTitleWithTags';
import SlateEditor from './SlateEditor';

const editorStyle = {
  height: '100%',
  minHeight: '310px'
};
class DoNotUpdateTextarea extends React.Component {
  // prevents loss of focus due to rerenders trigged by saving
  shouldComponentUpdate(nextProps) {
    if (this.props.value === nextProps.value) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return <TextareaAutosize {...this.props} />;
  }
}

class AddEditNoteForm extends React.Component {
  state = { deleteNoteMenuOpen: false };

  componentDidUpdate(prevProps) {
    const beganEditingTitle =
      !prevProps.isEditingTitle && this.props.isEditingTitle;
    const beganEditingBody =
      !prevProps.isEditingBody &&
      this.props.isEditingBody &&
      prevProps.isEditingTitle &&
      !this.props.isEditingTitle;

    if (beganEditingTitle && this.textarea) {
      this.initTributeTagging();
      this.textarea.focus();
    } else if (beganEditingBody) {
      this.props.editor.moveAnchorToEndOfDocument().focus();
    }
  }

  initTributeTagging = () => {
    const { allTags } = this.props;
    this.tribute = new Tribute({
      selectClass: 'dropdown-highlight',
      onSelectItem: this.onSelectTag,
      noMatchTemplate: createAddNewTagTemplate,
      containerClass: 'large-text',
      values: allTags
    });

    this.tribute.attach(this.textarea);
  };

  onSelectTag = () => {
    const eventObj = {
      target: {
        value: this.textarea.value
      }
    };
    this.props.handleTitleChange(eventObj);
  };

  initTributeTagging = () => {
    const { allTags } = this.props;
    this.tribute = new Tribute({
      selectClass: 'dropdown-highlight',
      onSelectItem: this.onSelectTag,
      noMatchTemplate: createAddNewTagTemplate,
      containerClass: 'large-text',
      values: allTags
    });

    this.tribute.attach(this.textarea);
  };

  onSelectTag = () => {
    const eventObj = {
      target: {
        value: this.textarea.value
      }
    };
    this.props.handleTitleChange(eventObj);
  };

  handleTitleInputBlur = () => {
    this.props.blurTitle();
    this.props.handleSubmit();
  };

  openDeleteNoteMenu = () => {
    this.setState({ deleteNoteMenuOpen: true });
  };

  closeDeleteNoteMenu = () => {
    this.setState({ deleteNoteMenuOpen: false });
  };

  componentDidMount() {
    if (this.textarea) {
      setTimeout(() => this.textarea.focus(), 10);
      this.props.editTitle();
    }
  }

  render() {
    const {
      openDeleteNoteModal,
      title,
      slateBody,
      handleTitleChange,
      handleBodyChange,
      isEditingTitle,
      editTitle,
      deleteNote,
      projectName,
      editBody
    } = this.props;
    const closeDeleteNoteMenu = this.closeDeleteNoteMenu;

    return (
      <form autoComplete={'off'}>
        <div className="add-edit-note-modal-header">
          {isEditingTitle || !title ? (
            <DoNotUpdateTextarea
              innerRef={(ref) => (this.textarea = ref)}
              className="note-title editable"
              onFocus={this.moveCaretToEnd}
              value={title}
              onChange={handleTitleChange}
              onBlur={this.handleTitleInputBlur}
              spellCheck={false}
              placeholder="Untitled, type # to tag"
            />
          ) : (
            <NoteTitleWithTags
              className="add-edit-note-modal-title"
              onClick={editTitle}
              title={title}
            />
          )}
          <button
            type="button"
            className="delete-note-icon clickable"
            onClick={this.openDeleteNoteMenu}
          >
            <i className="note-options" />
          </button>

          {this.state.deleteNoteMenuOpen ? (
            <DeleteNoteMenu
              isOpen={this.state.deleteNoteMenuOpen}
              deleteNote={deleteNote}
              closeDeleteNoteMenu={closeDeleteNoteMenu}
              openDeleteNoteModal={openDeleteNoteModal}
              noteBody={slateBody}
              noteTitle={title}
              projectName={projectName}
            />
          ) : null}
        </div>
        <div
          className="add-edit-note-modal-body"
          onClick={editBody}
          onPaste={(e) => {
            e.stopPropagation();
          }}
        >
          <SlateEditor
            editorStyle={editorStyle}
            readOnly={isEditingTitle}
            placeholder={'Type note'}
            setEditorRef={this.props.setEditorRef}
            value={slateBody}
            onChange={handleBodyChange}
          />
        </div>
      </form>
    );
  }
}

export default AddEditNoteForm;
