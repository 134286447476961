import React from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { submitDates, setSubmissionStatus } from 'actionCreators';
import flatten from 'lodash/flatten';
import {
  TimesheetFooter,
  StyledSubmitButton,
  StyledDropdownItem,
  StyledTimesheetSubmitArrowIcon
} from './../styles';
import {
  getSubmissionStatus,
  getSomeTimeEntryIsSelected,
  getTimesheetDateKeys,
  getAreTimeEntriesStillCreating
} from 'selectors';
import { Dropdown } from 'reactstrap';
import {
  TIMESHEET_STATUS_COPY,
  TIMESHEET_STATUSES
} from 'appConstants/timesheets';
import { ReactComponent as SubmitAllIcon } from 'icons/submit-all.svg';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const SubmitAllIconContainer = styled.div`
  cursor: pointer;
  margin-top: 11px;
  pointer-events: ${(props) => props.disabled && 'none'};
`;

const TimesheetText = styled.div`
  font-size: 13px;
  font-weight: 600;
`;

class TimesheetFooterTotal extends React.Component {
  onSubmit = () => {
    const {
      submitDates,
      submissionStatus,
      dateKeys,
      areTimeEntriesStillCreating
    } = this.props;
    if (!areTimeEntriesStillCreating) {
      submitDates({ status: submissionStatus, dates: dateKeys });
    }
  };

  render() {
    const { data } = this.props;

    const matchingTimesheets =
      flatten(
        data
          ?.filter((row) => row.timesheetsByDate)
          .map((row) => Object.values(row.timesheetsByDate))
      ).filter((timesheet) => timesheet?.id) ?? [];

    const Hours = matchingTimesheets.reduce(
      (acc, entry) => {
        if (entry.status) {
          const formattedEntryHours = Number(entry.hours);
          acc.totalHours += formattedEntryHours;
          acc.unsubmittedHours +=
            entry.status === 'not_submitted' ? formattedEntryHours : 0;
        }

        return acc;
      },
      { totalHours: 0, unsubmittedHours: 0 }
    );

    const submitIconColor =
      Hours.unsubmittedHours > 0
        ? theme.colors.colorRemainingTeal
        : theme.colors.colorLightGray4;
    return (
      <div>
        <TimesheetFooter style={{ justifyContent: 'center' }}>
          <div>
            <TimesheetText>
              {formatNumWithMaxTwoDecimals(Hours.totalHours)}h
            </TimesheetText>
            <SubmitAllIconContainer
              data-for={'app-tooltip'}
              data-html
              data-effect="solid"
              data-tip={'Submit All'}
              onClick={this.onSubmit}
              disabled={Hours.unsubmittedHours === 0}
            >
              <SubmitAllIcon color={submitIconColor} />
            </SubmitAllIconContainer>
          </div>
        </TimesheetFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  submissionStatus: getSubmissionStatus(state),
  isAnyDaySelected: getSomeTimeEntryIsSelected(state),
  dateKeys: getTimesheetDateKeys(state),
  areTimeEntriesStillCreating: getAreTimeEntriesStillCreating(state)
});
const mapDispatchToProps = { submitDates, setSubmissionStatus };
const ConnectedTimesheetFooterTotal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetFooterTotal);

const TimesheetFooterTotalAdapter = (props) => (
  <ConnectedTimesheetFooterTotal {...props} />
);

export default TimesheetFooterTotalAdapter;
