import React from 'react';
import SvgIcon from '../../components/SvgIcon';

const RichNumberedListIcon = () => (
  <SvgIcon width="11" height="10" viewBox="0 0 11 10">
    <g fill="gray" fillRule="nonzero">
      <path d="M1.537 0H.863L0 .701l.45.42C.63.985.735.885.769.823h.013v.066l-.003.668-.003.674h-.68v.547h2.127V2.23h-.685V0zM10.169 1.062A.163.163 0 0 0 10.042 1H3.18a.163.163 0 0 0-.126.062.217.217 0 0 0-.054.146v1.25c0 .057.018.106.054.147a.163.163 0 0 0 .126.062h6.862c.049 0 .091-.021.127-.062a.216.216 0 0 0 .053-.147v-1.25a.216.216 0 0 0-.053-.146zM1.54 8.324l.584-.624v-.478H.08v.825h.65v-.288c.066 0 .165-.001.298-.005.133-.004.232-.005.298-.005v.005a3.335 3.335 0 0 0-.488.502c-.1.121-.167.2-.2.236l.16.304c.43-.03.645.072.645.303a.252.252 0 0 1-.132.23.593.593 0 0 1-.31.08c-.234 0-.45-.082-.651-.245L0 9.642C.27 9.88.622 10 1.056 10c.331 0 .609-.083.832-.25a.807.807 0 0 0 .334-.683.683.683 0 0 0-.184-.478.902.902 0 0 0-.497-.265zM1.588 5.574H.821c.004-.09.076-.186.215-.285.139-.1.29-.19.456-.274.165-.083.316-.2.452-.35a.721.721 0 0 0 .206-.496c0-.257-.1-.46-.302-.61a1.208 1.208 0 0 0-.743-.226 1.32 1.32 0 0 0-.637.155 1.02 1.02 0 0 0-.432.431l.514.32c.14-.21.304-.314.489-.314.1 0 .18.024.238.073a.257.257 0 0 1 .088.209c0 .083-.047.164-.142.244-.095.08-.208.158-.341.236a5.5 5.5 0 0 0-.399.258 1.262 1.262 0 0 0-.341.369.936.936 0 0 0-.142.504c0 .065.012.163.036.293h2.186v-.863h-.634v.326zM10.042 7H3.18a.164.164 0 0 0-.13.059.22.22 0 0 0-.05.15v1.25c0 .056.018.105.054.146a.163.163 0 0 0 .126.062h6.862c.049 0 .091-.02.127-.062a.216.216 0 0 0 .053-.147v-1.25a.217.217 0 0 0-.053-.146.163.163 0 0 0-.127-.062zM10.067 4H3.155a.134.134 0 0 0-.111.059.244.244 0 0 0-.044.15v1.25c0 .056.015.104.046.146.031.041.067.062.11.062h6.91c.043 0 .08-.02.11-.062a.238.238 0 0 0 .046-.147v-1.25a.239.239 0 0 0-.046-.146c-.03-.041-.067-.062-.11-.062z" />
    </g>
  </SvgIcon>
);

export default RichNumberedListIcon;
