import * as constants from 'appConstants';

export const initialState = {
  error: null,
  passwordIsSet: null,
  inviteResent: [], // will contain team IDs which have been resent invite email from within TeamMembersTab
  isResendingInvite: false,
  indexMember: null
};

const invite = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.INVITE_VALIDATION.FAILURE:
      return {
        ...state,
        error: true
      };
    case constants.PASSWORD_UPDATE.SUCCESS:
      return {
        ...state,
        passwordIsSet: true
      };
    case constants.RESEND_INVITE.TRIGGER:
      return {
        ...state,
        isResendingInvite: true,
        indexMember: action.payload.params.idx
      };
    case constants.RESEND_INVITE.SUCCESS:
      return {
        ...state,
        inviteResent: [
          ...state.inviteResent,
          action.payload.response.account_id
        ],
        isResendingInvite: false
      };
    case constants.CLEAR_RESEND_INVITE:
      return {
        ...state,
        inviteResent: []
      };
    default:
      return state;
  }
};

export default invite;
