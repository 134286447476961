import { ZOOM_LEVELS } from 'appConstants/workload';
import { DateNav } from './DateNav/DateNav';
import {
  getVisibleTimeRange,
  getWeeksFromZoom
} from 'appUtils/projectPlannerUtils';
import { dayInMilliseconds } from 'TimelinesModule/components/constants';
import { startOfDay, startOfToday } from 'date-fns';
import styled from 'styled-components';
import { TimelineZoomLevelValues } from 'TimelinesModule/types/timeline';

interface TimelineHeaderProps {
  onTimeChange: (values: {
    visibleTimeStart: EpochTimeStamp;
    visibleTimeEnd: EpochTimeStamp;
  }) => void;
  visibleTimeStart: EpochTimeStamp;
  visibleTimeEnd: EpochTimeStamp;
  zoom: TimelineZoomLevelValues;
}
export const TimelineHeader = ({
  visibleTimeStart,
  visibleTimeEnd,
  onTimeChange,
  zoom
}: TimelineHeaderProps) => {
  const handleScrollPlannerHalfTimelineWidth =
    (direction: 'forward' | 'backward') => () => {
      const weeks = getWeeksFromZoom(zoom) / 2;
      const multiplier = direction === 'forward' ? 1 : -1;
      const daysToUse = zoom === ZOOM_LEVELS.DAY ? 2 : weeks * 7;

      const distanceInMilliseconds = multiplier * dayInMilliseconds * daysToUse;

      onTimeChange({
        visibleTimeStart: visibleTimeStart + distanceInMilliseconds,
        visibleTimeEnd: visibleTimeEnd + distanceInMilliseconds
      });
    };

  const handleScrollToToday = () => {
    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart: startOfToday()
    });
    onTimeChange({
      visibleTimeStart: visibleTimeStart.valueOf(),
      visibleTimeEnd: visibleTimeEnd.valueOf()
    });
  };

  const today = startOfDay(new Date()).getTime();
  const isTodayOnScreen = visibleTimeStart <= today && today <= visibleTimeEnd;

  return (
    <TimelineHeaderContainer>
      <DateNavContainer>
        <DateNav
          onPrev={handleScrollPlannerHalfTimelineWidth('backward')}
          onNext={handleScrollPlannerHalfTimelineWidth('forward')}
          onToday={handleScrollToToday}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          isTodayOnScreen={isTodayOnScreen}
        />
      </DateNavContainer>
      {/* Left Toolbar */}
      {/* RightToolbar */}
    </TimelineHeaderContainer>
  );
};

const TimelineHeaderContainer = styled.div`
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateNavContainer = styled.div`
  position: relative;
`;
