import React, { Component } from 'react';
import PropType from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import FaPlay from 'react-icons/lib/fa/play';
import MdClose from 'react-icons/lib/md/close';
import thumbNail from 'images/video-thumbnail@2x.png';

class WelcomeModal extends Component {
  state = {
    introVideoModalIsOpen: false
  };

  handleWelcomeModalClose = () => {
    const { toggleWelcomeModal } = this.props;
    toggleWelcomeModal();
  };

  toggleIntroVideoModal = () => {
    this.setState((prevState) => ({
      introVideoModalIsOpen: !prevState.introVideoModalIsOpen
    }));
  };

  render() {
    const { welcomeModalIsOpen } = this.props;
    const { introVideoModalIsOpen } = this.state;
    return (
      <Modal
        isOpen={welcomeModalIsOpen}
        toggle={this.handleWelcomeModalClose}
        id="intro-modal"
        autoFocus={false}
      >
        <ModalBody>
          <div
            role="button"
            tabIndex={-1}
            onClick={this.toggleIntroVideoModal}
            onKeyPress={this.toggleIntroVideoModal}
          >
            <img
              alt="Welcome Video Thumbnail"
              src={thumbNail}
              height="310"
              width="540"
            />
          </div>
          <FaPlay onClick={this.toggleIntroVideoModal} />
          <button onClick={this.handleWelcomeModalClose}>Not Now</button>
          <p>
            You can also view from
            <a href="http://www.mosaicapp.com/helpcenter.html" target="_blank">
              the Help Center
            </a>
          </p>
          <Modal
            isOpen={introVideoModalIsOpen}
            toggle={this.toggleIntroVideoModal}
            id="video-container"
          >
            <MdClose onClick={this.handleWelcomeModalClose} />
            <iframe
              title="Mosaic App Welcome Video"
              src="https://player.vimeo.com/video/217669608?title=0&byline=0&portrait=0&autoplay=1"
              width="850"
              height="489"
              frameBorder="0"
              webkitAllowAullscreen
              mozAllowFullscreen
              allowFullscreen
            />
          </Modal>
        </ModalBody>
      </Modal>
    );
  }
}

WelcomeModal.propTypes = {
  welcomeModalIsOpen: PropType.bool.isRequired,
  toggleWelcomeModal: PropType.func.isRequired
};

export default WelcomeModal;
