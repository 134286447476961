import * as constants from 'appConstants';
import { action } from 'appUtils';

export const fetchPhaseTotals = {
  request: () => action(constants.FETCH_PHASE_TOTALS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_PHASE_TOTALS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_PHASE_TOTALS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};
export const fetchPhaseTotalsByBoard = {
  request: () => action(constants.FETCH_PHASE_TOTALS_BY_BOARD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASE_TOTALS_BY_BOARD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASE_TOTALS_BY_BOARD.FAILURE, {
      payload: { error }
    })
};
export const fetchPhaseTotalsBudgetReport = {
  request: () => action(constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASE_TOTALS_BUDGET_REPORT.FAILURE, {
      payload: { error }
    })
};
export const fetchPhasesByProjectIds = {
  request: () => action(constants.FETCH_PHASES_BY_PROJECT_IDS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_PHASES_BY_PROJECT_IDS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_PHASES_BY_PROJECT_IDS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};
export const fetchPhases = {
  request: () => action(constants.FETCH_PHASES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASES.FAILURE, { payload: { error } })
};
export const fetchPhaseNames = {
  request: () => action(constants.FETCH_PHASE_NAMES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASE_NAMES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASE_NAMES.FAILURE, { payload: { error } })
};

export const fetchFilteredPhases = {
  request: () => action(constants.FETCH_FILTERED_PHASES.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_FILTERED_PHASES.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_FILTERED_PHASES.FAILURE, {
      payload: { error },
      meta
    })
};

export const createPhase = {
  request: () => action(constants.CREATE_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_PHASE.FAILURE, { payload: { error } })
};
export const predictPhase = {
  request: () => action(constants.PREDICT_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.PREDICT_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PREDICT_PHASE.FAILURE, { payload: { error } })
};

export const predictWorkdaysAndUpdatePhase = {
  request: () => action(constants.PREDICT_WORKDAYS_AND_UPDATE_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.PREDICT_WORKDAYS_AND_UPDATE_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PREDICT_WORKDAYS_AND_UPDATE_PHASE.FAILURE, {
      payload: { error }
    })
};

export const createBulkPhases = {
  request: () => action(constants.CREATE_BULK_PHASES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_BULK_PHASES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_BULK_PHASES.FAILURE, { payload: { error } })
};
export const updatePhase = {
  request: () => action(constants.UPDATE_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PHASE.FAILURE, { payload: { error } })
};
export const convertPhaseToDefault = {
  request: () => action(constants.CONVERT_PHASE_TO_DEFAULT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CONVERT_PHASE_TO_DEFAULT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CONVERT_PHASE_TO_DEFAULT.FAILURE, { payload: { error } })
};
export const deletePhase = {
  request: () => action(constants.DELETE_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PHASE.FAILURE, { payload: { error } })
};
export const hardDeletePhase = {
  request: () => action(constants.HARD_DELETE_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.HARD_DELETE_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.HARD_DELETE_PHASE.FAILURE, { payload: { error } })
};
export const archivePhase = {
  request: () => action(constants.ARCHIVE_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_PHASE.FAILURE, { payload: { error } })
};
export const fetchPhaseTemplates = {
  request: () => action(constants.FETCH_PHASE_TEMPLATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASE_TEMPLATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASE_TEMPLATES.FAILURE, { payload: { error } })
};
export const createPhaseTemplate = {
  request: () => action(constants.CREATE_PHASE_TEMPLATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_PHASE_TEMPLATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_PHASE_TEMPLATE.FAILURE, { payload: { error } })
};
export const updatePhaseTemplate = {
  request: () => action(constants.UPDATE_PHASE_TEMPLATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_PHASE_TEMPLATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PHASE_TEMPLATE.FAILURE, { payload: { error } })
};
export const deletePhaseTemplate = {
  request: () => action(constants.DELETE_PHASE_TEMPLATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_PHASE_TEMPLATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PHASE_TEMPLATE.FAILURE, { payload: { error } })
};
export const reorderPhaseTemplates = {
  request: () => action(constants.REORDER_PHASE_TEMPLATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.REORDER_PHASE_TEMPLATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.REORDER_PHASE_TEMPLATES.FAILURE, { payload: { error } })
};

export const createMilestoneTemplate = {
  request: () => action(constants.CREATE_MILESTONE_TEMPLATE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_MILESTONE_TEMPLATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_MILESTONE_TEMPLATE.FAILURE, { payload: { error } })
};
export const reorderMilestoneTemplates = {
  request: () => action(constants.REORDER_MILESTONE_TEMPLATES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.REORDER_MILESTONE_TEMPLATES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.REORDER_MILESTONE_TEMPLATES.FAILURE, {
      payload: { error }
    })
};

export const deleteMemberFromPhase = {
  request: () => action(constants.DELETE_MEMBER_FROM_PHASE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_MEMBER_FROM_PHASE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_FROM_PHASE.FAILURE, { payload: { error } })
};

export const fetchPhaseMembers = {
  request: () => action(constants.FETCH_PHASE_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PHASE_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PHASE_MEMBERS.FAILURE, { payload: { error } })
};

export const createPhaseMembers = {
  request: () => action(constants.CREATE_PHASE_MEMBERS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.CREATE_PHASE_MEMBERS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error) =>
    action(constants.CREATE_PHASE_MEMBERS.FAILURE, { payload: { error } })
};

export const archivePhaseMember = {
  request: () => action(constants.ARCHIVE_PHASE_MEMBER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_PHASE_MEMBER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_PHASE_MEMBER.FAILURE, { payload: { error } })
};
export const deletePhaseMember = {
  request: () => action(constants.DELETE_PHASE_MEMBER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_PHASE_MEMBER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PHASE_MEMBER.FAILURE, { payload: { error } })
};

export const updatePhaseMembership = {
  request: () => action(constants.UPDATE_PHASE_MEMBERSHIP.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_PHASE_MEMBERSHIP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PHASE_MEMBERSHIP.FAILURE, { payload: { error } })
};
