import React from 'react';
import { connect } from 'react-redux';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import keyBy from 'lodash/keyBy';
import styled from 'styled-components';
import theme from 'theme';

import {
  makeGetSelectedPhasesByPhaseId,
  makeGetArchivedPhases,
  makeGetActivePhases,
  makeGetActiveBudgetPhases,
  makeGetAllPhasesForProject,
  makeGetNonBudgetPhases,
  makeGetArchivedMilestones
} from 'BudgetModule/selectors';
import {
  getSelectedTeamId,
  getPhaseTemplatesList,
  getMilestoneTemplatesList,
  getTeamSlug,
  getUserIsAdmin
} from 'selectors';

import {
  createBulkPhases,
  openPhaseTemplateDropdown,
  openPhaseModal,
  fetchPhaseTemplates,
  navigateToStandardMilestonesSettings,
  navigateToStandardPhasesSettings
} from 'actionCreators';
import {
  StyledPhasesOfWork as PhasesOfWork,
  StyledPhaseState,
  StyledPhaseName,
  AddedText,
  RemoveText,
  CloseIconContainer,
  StyledDoneButton,
  StyledBudgetPhaseMilestoneIcon,
  StyledBudgetIconContainer,
  AddText,
  CancelButton
} from './styles';
import MilestoneIcon from 'icons/StandardMilestoneIcon';

import { filterItemWithWhiteSpace } from 'appUtils/search';
import ReactTooltip from 'react-tooltip';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';

const byId = (item) => item.id;

const StyledPhasesOfWork = styled(PhasesOfWork)`
  padding: ${({ isOldPhaseMenu }) => (isOldPhaseMenu ? '0' : '0px 20px')};
  height: ${({ isOldPhaseMenu }) => (isOldPhaseMenu ? '49px' : '53px')};
  ${RemoveText} {
    display: ${({ isAdded }) => (isAdded ? 'none' : 'flex')};
  }
  ${AddedText} {
    color: ${theme.colors.colorCalendarBlue};
    display: ${({ isAdded }) => (isAdded ? 'flex' : 'none')};
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    ${AddedText} {
      display: ${({ isAdded }) => (isAdded ? 'none' : 'flex')};
    }
  }
`;

const StandardLabel = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 11px;
  top: 4px;
  color: ${theme.colors.colorLightGray15};
`;

const Footer = ({ isMilestone }) => (
  <LockWithTooltip
    text={`Manage Standard ${isMilestone ? 'Milestones' : 'Phases'}`}
    tooltipContent={`Only Admins and Financial</br>Managers can Add or Edit ${
      isMilestone
        ? 'Milestones.'
        : 'Phases <br /> because they affect Project Budgets.'
    }`}
  />
);

const listItemContainerStyle = { 'border-bottom': '1px solid #e3e3e3' };

/** Used to modify the phase list for a project. */
class PhaseTemplatesDropdown extends React.Component {
  state = {
    addedPhaseNames: [],
    convertedPhases: [],
    orderedActivePhases: this.props.activePhases,
    openActionDropdownItemId: false
  };

  componentDidMount() {
    const { teamId, fetchPhaseTemplates } = this.props;
    if (teamId) {
      fetchPhaseTemplates({ teamId });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { teamId, activePhases } = this.props;
    if (prevProps.activePhases !== activePhases) {
      this.setState({ orderedActivePhases: activePhases });
    }
    if (prevProps.teamId !== teamId && teamId) {
      fetchPhaseTemplates({ teamId });
    }
  }

  toggleActionDropdown = (id) =>
    this.setState((prevState) => ({
      openActionDropdownItemId:
        prevState.openActionDropdownItemId === id ? null : id
    }));

  closeActionDropdown = () => {
    this.setState({ openActionDropdownItemId: null });
  };

  addPhaseName = (name) =>
    this.setState({ addedPhaseNames: [...this.state.addedPhaseNames, name] });

  removePhaseName = (name) =>
    this.setState({
      addedPhaseNames: this.state.addedPhaseNames.filter(
        (phaseName) => phaseName !== name
      )
    });

  convertPhase = (item) =>
    this.setState({ convertedPhases: [...this.state.convertedPhases, item] });

  unconvertPhase = (item) =>
    this.setState({
      convertedPhases: this.state.convertedPhases.filter(
        (phase) => phase?.id !== item?.id
      )
    });

  renderItem = ({ item, selectCallback }) => {
    const {
      selectedPhases,
      openPhaseModal,
      projectId,
      isAllPhases,
      isMilestone,
      nonBudgetPhases,
      isOldPhaseMenu
    } = this.props;
    const { addedPhaseNames, convertedPhases } = this.state;

    const nonBudgetPhaseIds = keyBy(
      nonBudgetPhases.filter((phase) => !phase.archived),
      (phase) => phase.id
    );
    const isSelected = isMilestone
      ? nonBudgetPhaseIds[item.id]
      : selectedPhases[item.id];
    const isArchived = item.archived;

    const isAdded = addedPhaseNames.includes(item.name);
    const isConverted = convertedPhases
      .map((phase) => phase.name)
      .includes(item.name);
    const existingNonBudgetPhase = nonBudgetPhases.find(
      (phase) => phase.id === item?.id
    );

    if (!item.id) {
      // is new
      return (
        <StyledPhasesOfWork
          isOldPhaseMenu={isOldPhaseMenu}
          onClick={() => {
            openPhaseModal({
              projectId,
              isAllPhases,
              isAddPhase: true,
              isAddMilestone: false
            });
            this.closeActionDropdown();
          }}
        >
          <StyledPhaseName isAdded={isSelected} style={{ color: '#0074d9' }}>
            {item.name}
            <StandardLabel>STANDARD</StandardLabel>
          </StyledPhaseName>
          {/* {item?.isAddCustomRow && <AddAllLabel>Add All</AddAllLabel>} */}
        </StyledPhasesOfWork>
      );
    }
    if (isAdded) {
      return (
        <StyledPhasesOfWork
          isOldPhaseMenu={isOldPhaseMenu}
          onClick={this.closeActionDropdown}
          isAdded
        >
          <div style={{ display: 'flex' }}>
            <StyledBudgetIconContainer>
              {isMilestone ? (
                <MilestoneIcon />
              ) : (
                <StyledBudgetPhaseMilestoneIcon />
              )}
            </StyledBudgetIconContainer>
            <StyledPhaseName isArchived={isArchived}>
              {item?.name}
            </StyledPhaseName>
          </div>
          <StyledPhaseState isArchived={isArchived}>
            <AddedText>Added</AddedText>
            <RemoveText>Remove</RemoveText>
          </StyledPhaseState>
        </StyledPhasesOfWork>
      );
    }
    if (isConverted) {
      return (
        <StyledPhasesOfWork
          isOldPhaseMenu={isOldPhaseMenu}
          onClick={this.closeActionDropdown}
        >
          <div style={{ display: 'flex' }}>
            <StyledBudgetIconContainer>
              {isMilestone ? (
                <MilestoneIcon />
              ) : (
                <StyledBudgetPhaseMilestoneIcon />
              )}
            </StyledBudgetIconContainer>
            <StyledPhaseName isArchived={isArchived}>
              {item?.name}
            </StyledPhaseName>
          </div>
          <StyledPhaseState isArchived={isArchived}>
            <AddedText>Converted</AddedText>
          </StyledPhaseState>
        </StyledPhasesOfWork>
      );
    }

    return (
      <StyledPhasesOfWork
        isOldPhaseMenu={isOldPhaseMenu}
        onClick={this.closeActionDropdown}
        isInEditMode={this.state.openActionDropdownItemId === item.id}
      >
        <div style={{ display: 'flex' }}>
          <StyledBudgetIconContainer>
            {isMilestone ? (
              <MilestoneIcon />
            ) : (
              <StyledBudgetPhaseMilestoneIcon />
            )}
          </StyledBudgetIconContainer>
          <StyledPhaseName isArchived={isArchived}>
            {' '}
            {item?.name}
          </StyledPhaseName>
        </div>
        <StyledPhaseState isArchived={isArchived}>
          {isSelected ? (
            <RemoveText>Remove</RemoveText>
          ) : existingNonBudgetPhase && !isAllPhases ? (
            'Convert'
          ) : (
            <AddText> Add </AddText>
          )}
        </StyledPhaseState>
      </StyledPhasesOfWork>
    );
  };

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    const {
      isNew,
      isMilestone,
      selectedPhases,
      archivedPhases,
      archivedMilestones,
      nonBudgetPhases,
      isAllPhases
    } = this.props;
    const { id } = item;
    const { addedPhaseNames, convertedPhases } = this.state;
    const archivedPhasesbyId = keyBy(archivedPhases, byId);
    const archivedMilestonesbyId = keyBy(archivedMilestones, byId);
    const nonBudgetPhaseIds = keyBy(
      nonBudgetPhases.filter((phase) => !phase.archived),
      byId
    );
    if (!id) {
      if (selectCallback) {
        selectCallback();
      }
      return;
    }
    const existingNonBudgetPhase = nonBudgetPhases.find(
      (phase) => phase.id === item?.id
    );
    const isAdded = addedPhaseNames.includes(item?.name);
    const isConverted = convertedPhases
      .map((phase) => phase.name)
      .includes(item?.name);

    const selectedList = isMilestone ? nonBudgetPhaseIds : selectedPhases;
    const archivedList = isMilestone
      ? archivedMilestonesbyId
      : archivedPhasesbyId;
    if (
      (selectedList[id] && !archivedPhasesbyId[id]) ||
      item.onClick ||
      archivedList[id]
    ) {
      this.deletePhase(item);
      return;
    } else if (isAdded) {
      this.removePhaseName(item?.name);
      return;
    } else if ((isNew || archivedList[id]) && (!isAdded || !isConverted)) {
      if (!isConverted && existingNonBudgetPhase && !isAllPhases) {
        this.convertPhase(item);
      } else if (!isAdded) {
        this.addPhaseName(item?.name);
      }
      return;
    } else if (isConverted) {
      this.unconvertPhase(item);
      return;
    }
    if (selectCallback) {
      selectCallback();
    }
  };

  archivePhase = ({ id }) => {
    const { archivePhase, projectId } = this.props;
    archivePhase({
      id,
      projectId
    });
  };

  deletePhase = ({ id, name }) => {
    const { openPhaseModal, projectId, isAllPhases } = this.props;
    openPhaseModal({
      id,
      name,
      projectId,
      isDeletePhase: true,
      isAllPhases
    });
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['name']
    });

  reorder = (newOrder) => {
    const { selectedPhases } = this.props;
    const newOrderedActivePhases = newOrder
      .map((id) => selectedPhases[id])
      .filter((phase) => phase?.archived === false);
    this.setState({ orderedActivePhases: newOrderedActivePhases });
  };

  isItemDraggable = (item) => {
    const { selectedPhases } = this.props;
    return selectedPhases[item?.id]?.archived === false;
  };

  getPermissions = () => {
    return {
      teamId: this.props.teamId
    };
  };

  onFooterClick = () => {
    const {
      isAdmin,
      isMilestone,
      navigateToStandardMilestonesSettings,
      navigateToStandardPhasesSettings,
      teamSlug
    } = this.props;
    if (isAdmin) {
      if (isMilestone) {
        navigateToStandardMilestonesSettings({
          teamSlug,
          openInNewWindow: true
        });
      } else {
        navigateToStandardPhasesSettings({ teamSlug, openInNewWindow: true });
      }
    }
    ReactTooltip.hide();
    this.handleClose();
  };

  handleClose = (e) => {
    const { projectId, createBulkPhases, handleClose, isEditing, isMilestone } =
      this.props;
    const { addedPhaseNames, convertedPhases, orderedActivePhases } =
      this.state;
    if (handleClose && !isEditing) {
      if (!e || e.target.id !== 'createPhaseInput') {
        createBulkPhases({
          projectId,
          addedPhaseNames,
          orderedActivePhases,
          isBudget: !isMilestone,
          convertedPhases: convertedPhases
        });
        this.setState({ addedPhaseNames: [] });
        handleClose();
      }
    }
  };

  handleDone = () => {
    this.handleClose();
  };

  renderHeader = () => {
    const { renderHeader, renderHeaderCopy } = this.props;
    return renderHeader ? renderHeader(renderHeaderCopy) : undefined;
  };

  hasRenderHeader = () => !!this.props.renderHeader;

  getPhases = () => {
    const keyByName = (item) => item && item?.name;
    const {
      selectedPhases,
      phaseTemplates,
      milestoneTemplates,
      archivedPhases,
      archivedMilestones,
      isAllPhases,
      isMilestone, // i.e. nonBudgetPhases
      nonBudgetPhases
    } = this.props;
    const { addedPhaseNames, convertedPhases, orderedActivePhases } =
      this.state;
    const addedPhases = [];
    const converted = [];
    const remainingTemplates = [];
    const unconvertedPhases = [];
    const convertedPhaseNames = convertedPhases.map((phase) => phase.name);
    const nonBudgetPhaseNames = keyBy(
      nonBudgetPhases.filter((phase) => !phase.archived),
      keyByName
    );
    const phasesByPhaseName = keyBy(
      Object.values(selectedPhases).filter(
        (phase) => phase && !phase.is_default_phase
      ),
      keyByName
    );

    const templateList = isMilestone ? milestoneTemplates : phaseTemplates;
    templateList.forEach((template) => {
      if (
        !phasesByPhaseName[template.name] &&
        !addedPhaseNames.includes(template.name) &&
        !convertedPhaseNames.includes(template.name) &&
        !nonBudgetPhaseNames[template.name]
      ) {
        remainingTemplates.unshift({
          ...template
          // firstOnetoBeAdded: remainingTemplates.length === 0
        });
      } else if (addedPhaseNames.includes(template.name)) {
        addedPhases.push(template);
      } else if (convertedPhaseNames.includes(template.name)) {
        converted.push(template);
      }
    });

    const activeList = isMilestone ? nonBudgetPhases : orderedActivePhases;
    const archivedList = isMilestone ? archivedMilestones : archivedPhases;
    if (isAllPhases) {
      return [
        ...remainingTemplates,
        ...addedPhases,
        ...activeList.filter(
          (phase) => phase && !phase.is_default_phase && !phase.is_like_default
        ),
        ...archivedList
      ];
    } else {
      nonBudgetPhases
        .filter((phase) => !phase.archived)
        .forEach((phase) => {
          if (
            !convertedPhaseNames.includes(phase.name) &&
            !phaseTemplates
              .map((phaseTemplate) => phaseTemplate.name)
              .includes(phase.name)
          ) {
            unconvertedPhases.push(phase);
          }
        });
      return [
        ...addedPhases,
        ...remainingTemplates,
        ...orderedActivePhases.filter(
          (phase) => phase && !phase.is_default_phase
        ),
        ...convertedPhases,
        ...unconvertedPhases,
        ...archivedPhases
      ];
    }
  };

  renderCopy = () => {
    const { isMilestone } = this.props;
    return {
      headerInitial: 'Add Standard Phase',
      headerEdit: 'Add Phase',
      headerAdd: 'New Phase',
      sticky: 'Add New Phase',
      footerInitial: <Footer isMilestone={isMilestone} />,
      footerEdit: <Footer isMilestone={isMilestone} />,
      addConfirm: 'Add',
      editConfirm: 'Save',
      searchPlaceholder: 'Type name or select below'
    };
  };

  renderHeaderButton = () => {
    const { handleClose } = this.props;
    return (
      <CloseIconContainer>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <StyledDoneButton className="add-button" onClick={this.handleDone}>
          Add
        </StyledDoneButton>
      </CloseIconContainer>
    );
  };

  render() {
    const {
      target,
      isAllPhases,
      hideAddCustomPhase,
      noHeader = false,
      customStyleClass = ''
    } = this.props;

    const items = this.getPhases();
    if (!hideAddCustomPhase) {
      items.unshift({
        name: 'Add Custom Phase',
        isAddCustomRow: true
      });
    }
    return (
      <>
        <MultiStepFlyout
          copy={this.renderCopy()}
          target={target}
          items={items}
          idKey="id"
          renderHeaderButton={this.renderHeaderButton}
          hideEditIcon
          searchEnabled
          renderItem={this.renderItem}
          handleSelect={this.handleSelect}
          isWhite={true}
          itemFilter={this.itemFilter}
          reorder={this.reorder}
          handleClose={(e) => this.handleClose(e)}
          dragEnabled={!isAllPhases}
          isItemDraggable={this.isItemDraggable}
          onFooterClick={this.onFooterClick}
          phases={true}
          editMode
          renderHeader={this.hasRenderHeader() ? this.renderHeader : undefined}
          listItemContainerStyle={listItemContainerStyle}
          popoverClassName={'add-standard-phases-flyout ' + customStyleClass}
          noHeader={noHeader}
          listWidth={310}
        />
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getSelectedPhases = makeGetSelectedPhasesByPhaseId();
  const getArchivedPhases = makeGetArchivedPhases();
  const getActivePhases = makeGetActivePhases();
  const getBudgetPhases = makeGetActiveBudgetPhases();
  const getAllPhasesForProject = makeGetAllPhasesForProject();
  const getNonBudgetPhases = makeGetNonBudgetPhases();
  const getArchivedMilestones = makeGetArchivedMilestones();
  const mapStateToProps = (state, ownProps) => ({
    teamSlug: getTeamSlug(state),
    phaseTemplates: getPhaseTemplatesList(state),
    milestoneTemplates: getMilestoneTemplatesList(state),
    teamId: getSelectedTeamId(state),
    isAdmin: getUserIsAdmin(state),
    selectedPhases: getSelectedPhases(state, ownProps),
    archivedPhases: getArchivedPhases(state, ownProps),
    archivedMilestones: getArchivedMilestones(state, ownProps),
    activePhases: ownProps.isAllPhases
      ? getActivePhases(state, ownProps)
      : getBudgetPhases(state, ownProps),
    budgetPhases: getBudgetPhases(state, ownProps),
    nonBudgetPhases: getNonBudgetPhases(state, ownProps),
    allPhasesForProject: getAllPhasesForProject(state, ownProps)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  openPhaseModal,
  openPhaseTemplateDropdown,
  createBulkPhases,
  fetchPhaseTemplates,
  navigateToStandardMilestonesSettings,
  navigateToStandardPhasesSettings
};

/* Used to modify the list of phases for a project. */
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(PhaseTemplatesDropdown);
