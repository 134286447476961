import React from 'react';
import MilestoneWorkDaysInput from './MilestoneWorkDaysInput';

const MilestoneTableWorkdaysCell = (props) => {
  const { phase, row } = props;

  const isPhase = phase?.is_budget;
  const workDays = isPhase ? phase?.total_work_days || '' : null;
  const { handleSetIsOpen } = row.original;

  return (
    <div
      className="milestone-right"
      onClick={() =>
        handleSetIsOpen({
          id: phase.id,
          value: !row.original.isOpen
        })
      }
    >
      <MilestoneWorkDaysInput
        phase={phase}
        newWorkDays={workDays}
        isPhaseMilestone={true}
      />
    </div>
  );
};

export default MilestoneTableWorkdaysCell;
