import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const firstNameValidator: RowValidator = ({ row, entityType }) => {
  const { enteredFirstName } = getRowValuesForEntityType(row, entityType);

  return (
    !!enteredFirstName && enteredFirstName !== TRAINING_DATA_REQUIRED_STRING
  );
};
