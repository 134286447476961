import { MouseEvent } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import { PHASE_BUDGET_STATUS_OPTIONS } from 'appConstants/budgetStatuses';
import { usePopover } from 'components/Popover/usePopover';
import { updatePhase } from 'actionCreators';

interface MilestoneTableStatusCellProps {
  phase: {
    project_id: number;
    id: number;
    budget_status: string;
  };
}

const MilestoneTableStatusCell = ({ phase }: MilestoneTableStatusCellProps) => {
  const dispatch = useDispatch();
  const {
    Popover: StatusPopover,
    openPopover: openStatusPopover,
    closePopover
  } = usePopover();

  const handleOpenPopover = (event: MouseEvent<HTMLDivElement>) => {
    openStatusPopover({ event });
  };

  const handleUpdatePhaseStatus = (newStatus: string) => {
    dispatch(
      updatePhase({
        id: phase.id,
        projectId: phase.project_id,
        budgetStatus: newStatus
      })
    );
    closePopover();
  };

  return (
    <StatusContainer onClick={handleOpenPopover}>
      <StatusText>{phase.budget_status.toUpperCase()} </StatusText>
      <StatusPopover className="phase-status-popover">
        {PHASE_BUDGET_STATUS_OPTIONS.map((budgetStatusOption) => (
          <StyledMenuItem
            key={budgetStatusOption.value}
            onClick={() => handleUpdatePhaseStatus(budgetStatusOption.value)}
          >
            {budgetStatusOption.label}
          </StyledMenuItem>
        ))}
      </StatusPopover>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StatusText = styled.div`
  background: ${theme.colors.colorTranslucentGray4};
  color: ${theme.colors.colorSemiDarkGrey10};
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  width: fit-content;
  padding: 3px 5px;
`;

const StyledMenuItem = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: ${theme.colors.colorMediumGray9};
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 13px 8px;
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;

export default MilestoneTableStatusCell;
