import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedProject } from 'selectors';
import ProjectTimeline from 'views/projectPlanner/projectTimeline';

const TimelineContainer = () => {
  const selectedProject = useSelector(getSelectedProject);
  const projectIds = useMemo(() => [selectedProject?.id], [selectedProject]);
  return (
    <div style={{ width: '100%' }}>
      <ProjectTimeline
        projectIds={projectIds}
        plannerType="timeline"
        hideActivityButton
      />
    </div>
  );
};

export default TimelineContainer;
