import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import {
  HoverState,
  NonHoverState
} from 'views/projectPlanner/WorkPlanItemRenderers/styles';

export const TotalHoursText = ({
  dailyHours,
  totalHours,
  dayCount,
  totalDayCount
}: {
  dailyHours: number;
  totalHours: number;
  dayCount: number;
  totalDayCount: number;
}) => (
  <>
    <NonHoverState>
      {formatNumWithMaxOneDecimal(dailyHours * dayCount)}h
    </NonHoverState>
    <HoverState>
      {totalDayCount > 6
        ? `${totalDayCount}d`
        : `${formatNumWithMaxOneDecimal(totalHours)}h`}
    </HoverState>
  </>
);
