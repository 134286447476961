import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { StyledFolderIcon } from 'ReportsModule/components/styles';

function ProjectNumberAndBoard({
  project,
  boardName,
  boardId,
  showNavToProject
}) {
  return (
    <StyledProjectNumberAndBoard className="project-number-and-board">
      {project?.project_number && project.project_number !== 0 && (
        <span className="project-number">{project.project_number}</span>
      )}
      {boardName && (
        <>
          <BoardIcon boardId={boardId} className="project-board" />
          <span className="project-board-name">{boardName}</span>
        </>
      )}
    </StyledProjectNumberAndBoard>
  );
}

export default ProjectNumberAndBoard;

/* ------------------------------------ - ----------------------------------- */

const BoardIcon = styled(StyledFolderIcon)`
  width: 12px;
  height: 10px;
  path {
    stroke: ${theme.colors.colorLightGray11};
    fill: transparent !important;
  }
`;

const StyledProjectNumberAndBoard = styled.div`
  display: flex;
  align-items: center;
  min-height: 18px;
  max-width: 100%;
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  .project-board,
  .project-number {
    margin-right: 5px;
  }
  .go-to-project {
    cursor: pointer;
    margin-left: -6px;
  }
`;
