import React from 'react';
import styled from 'styled-components';
import {
  HourTotal,
  OverBudgetTotalsContainer,
  IssueIconContainer
} from 'BudgetModule/components/styles';

const StyledOverBudget = styled(OverBudgetTotalsContainer)`
  ${(props) =>
    props.hasPieChart &&
    `
    flex-basis: 65px;
    flex-shrink: 0;
    ${HourTotal} {
      justify-content: center;
    }
  `}
  ${(props) =>
    props.detachIcon &&
    `
    justify-content: center;
    ${HourTotal} {
      flex-basis: 85px;
      flex-shrink: 0;
      justify-content: center;
    }
  `}
  ${(props) =>
    props.color &&
    `
    ${HourTotal} {
      color: ${props.color} !important;
    }
  `}
`;

const OverBudget = ({
  textToRender,
  tooltipContent,
  unsetWarningColor,
  noIcon,
  detachIcon,
  hasPieChart,
  color
}) => {
  return (
    <StyledOverBudget
      data-for="app-tooltip"
      data-class="center"
      data-tip={tooltipContent}
      data-html
      style={{ position: 'relative', whiteSpace: 'nowrap' }}
      unsetMinWidth
      unsetWarningColor={unsetWarningColor}
      detachIcon={detachIcon}
      hasPieChart={hasPieChart}
      color={color}
    >
      <HourTotal>{textToRender}</HourTotal>
      {!noIcon && <IssueIconContainer>!</IssueIconContainer>}
    </StyledOverBudget>
  );
};

export default React.memo(OverBudget);
