import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import cn from 'classnames';
import chunk from 'lodash/chunk';
import { connect } from 'react-redux';
import {
  setTaskAccountFilter,
  filterByMembers,
  addMemberToProject,
  triggerTaskStoreFlush,
  openAccessModal
} from 'actionCreators';
import noteActionCreators from 'NoteModule/actionCreators';
import { MemberInitials, AddMembersContainer } from '..';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import TaskIcon from 'icons/TaskIcon';
import { ItemCount } from './styles';
import NoteIcon from 'icons/NoteIcon';
import CloseIcon from 'icons/CloseIcon';
import {
  makeAmIOnProject,
  getSearchText,
  getTags,
  getNotesSelectedTagIds,
  getMatchedRouteParams,
  makeGetEditOrViewIds
} from 'selectors';
import { buildAccessIdentifier } from 'appUtils/access';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import NewMembersIcon from 'icons/NewMembersIcon';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

const { fetchNotesByProject } = noteActionCreators;

const emptyArray = [];
const CloseIconContainer = styled.div`
  position: absolute;
  top: -20px;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
`;
const ShowMembers = styled.div`
  cursor: pointer;
  border-radius: 25px;
  background-color: #0074d9;
  width: 50px;
  height: 30px;
  color: white;
  text-align: center;
  display: flex;
  flex-flow: column;
  font-size: 13px;
`;
const BudgetModalVersion = styled.div`
  display: flex;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 13px;
  cursor: pointer;
  justify-content: flex-end;
`;

const MembersText = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorCalendarBlue};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  border-radius: 50%;
  position: relative;

  &:hover {
    &:before {
      background-color: ${theme.colors.colorLightGray19};
      content: '';
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const MembersIconContainer = styled.div`
  margin: 0 2px;
  margin-top: -3px;
`;

class ProjectMembers extends React.Component {
  state = {
    hideMembers: !this.props.startShown,
    isOpenAddMembersModal: false
  };

  addMembersInstance = React.createRef();
  handleMemberClick(event, id) {
    if (this.props.handleMemberClick) {
      return this.props.handleMemberClick(event, id);
    }

    const {
      project,
      searchText,
      isOnTaskView,
      isOnNotesView,
      fetchNotesByProject,
      tagIds,
      selectedAccountIds,
      filterByMembers,
      setTaskAccountFilter,
      triggerTaskStoreFlush
    } = this.props;

    let accountIds;

    if (selectedAccountIds.includes(id)) {
      accountIds = selectedAccountIds.filter((i) => i !== id);
    } else {
      accountIds = selectedAccountIds.concat(id);
    }

    /* used to ensure that if a user is selected who does not have
            any notes with given tagId, tag will be unselected */
    let tagArray;
    if (accountIds.length === 1 && tagIds) {
      tagArray = this.buildTagArray(accountIds);
    }

    if (isOnNotesView) {
      fetchNotesByProject({
        projectId: project.id,
        accountIds,
        searchText,
        tagIds: tagArray && tagArray.length < 1 ? [] : tagIds
      });
    } else if (isOnTaskView) {
      setTaskAccountFilter({ selectedAccountIds: accountIds });
      triggerTaskStoreFlush();
    } else {
      filterByMembers(false, accountIds);
    }
  }

  buildTagArray(newArray) {
    let name;
    let tagArray;
    const { tags, tagIds } = this.props;
    const authorId = newArray[0];
    // Find the tag that matches the tagId
    tagArray = tags.allNoteTags.filter((tag) => tag.id === tagIds);
    // Get the name of that tag
    name = tagArray.length < 1 ? null : tagArray[0].name;
    // Get all tags that have that name
    tagArray = tags.allNoteTags.filter((tag) => tag.name === name);
    // Select only that tag which matches the currently selected author Id
    tagArray = tagArray.filter((tag) => tag.account_id === authorId);

    return tagArray;
  }

  renderMemberItemsCount = (member) => {
    const { isOnTaskView, isOnNotesView } = this.props;
    return (
      <div className="member-item-count">
        {isOnTaskView
          ? member.incomplete_task_count &&
            this.renderIncompleteTaskCount(member)
          : isOnNotesView
          ? member.note_count && this.renderNoteCount(member)
          : ''}
      </div>
    );
  };

  renderIncompleteTaskCount = (member) => {
    return (
      <>
        <TaskIcon height="10px" width="10px" color="#808080" />
        <ItemCount>{member.incomplete_task_count}</ItemCount>
      </>
    );
  };

  renderNoteCount = (member) => {
    return (
      <>
        <NoteIcon height="10px" width="10px" color="#808080" />
        <ItemCount>{member.note_count}</ItemCount>
      </>
    );
  };

  showMembers = () => this.setState({ hideMembers: false });

  onAccessClick = () => {
    const { openAccessModal, project } = this.props;
    openAccessModal({
      modalIdentifier: buildAccessIdentifier({
        actableType: 'Project',
        actableId: project.id,
        actionType: 'budget_ui'
      })
    });
  };

  getNumMembersText = (type) => {
    const { project } = this.props;
    const memberList =
      project && project.project_membership?.filter((pm) => !!pm.account);
    const pluralize = memberList && memberList.length === 1 ? '' : 's';
    return `${memberList ? memberList.length : 0} ${
      type || ''
    } Member${pluralize}`;
  };

  renderMemberRow = (membersToRender, startIdx) => {
    const {
      me,
      selectedAccountIds = emptyArray,
      inBudgetModal,
      isOnTaskView,
      project
    } = this.props;
    return membersToRender.map((member, idx) => (
      <li
        key={member.account.id}
        className={cn('group-members-list-item', {
          'in-budget-modal': inBudgetModal
        })}
      >
        {selectedAccountIds.indexOf(member.account.id) > -1 ? (
          <CloseIconContainer
            onClick={(e) => this.handleMemberClick(e, member.account.id)}
          >
            <CloseIcon></CloseIcon>
          </CloseIconContainer>
        ) : (
          ''
        )}
        <MemberInitials
          projectId={project.id}
          member={member}
          shouldShowHTMLTitle={true}
          idx={`${startIdx}--${idx}`} // only used for unique tooltip identifier
          projectDetailView={true}
          projectMembers={true}
          onClick={(e) => this.handleMemberClick(e, member.account.id)}
          classes={cn('regular-member-no-hover selected', {
            'logged-member-no-hover': me && me.id === member.account.id
          })}
        />
        {this.renderMemberItemsCount(member, isOnTaskView)}
      </li>
    ));
  };

  renderMemberIcons = (membersToRender, maxPerRow) => {
    const { selectedAccountIds = emptyArray } = this.props;
    // If no max specified, render normally as one row.
    const rows = chunk(
      membersToRender,
      maxPerRow || membersToRender.length
    ).map((row, index) => (
      <div
        key={index}
        className={cn('group-members-row', {
          'have-margin': maxPerRow ? 0 : selectedAccountIds.length
        })}
      >
        {this.renderMemberRow(row, index * maxPerRow)}
      </div>
    ));
    return rows;
  };

  renderProjectMembers = () => {
    const { project, inBudgetModal, maxPerRow, isFloatLeft } = this.props;
    const { hideMembers } = this.state;
    const memberList =
      project && project.project_membership?.filter((pm) => !!pm.account);
    const memberRenderList =
      memberList?.filter((member) => member.account.initials) ?? [];

    return hideMembers || !memberList || !memberList.length ? (
      <div
        className={cn('member-number', { 'in-budget-modal': inBudgetModal })}
        title={'Click to view all project members'}
        onClick={this.showMembers}
        style={{ cursor: 'pointer' }}
      >
        {this.getNumMembersText()}
      </div>
    ) : (
      <div
        className={cn('group-members-container')}
        // currently inline-flex, revert to nothing if we want to float left.
        style={isFloatLeft ? { display: 'initial' } : {}}
      >
        {this.renderMemberIcons(memberRenderList, maxPerRow)}
      </div>
    );
  };

  joinProject = () => {
    const { me, addMemberToProject, project } = this.props;
    const basicMemberRole = 3;
    addMemberToProject(project.id, me.id, basicMemberRole, project.board_id);
  };

  renderJoinButton = () => {
    const { isOnProject, project } = this.props;
    if (project && !isOnProject)
      return (
        <li className="group-members-list-item" style={{ marginRight: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ShowMembers onClick={this.joinProject}>
              <div style={{ margin: 'auto 0' }}>Join</div>
            </ShowMembers>
          </div>
        </li>
      );
    return null;
  };

  openAddMembersModal = () => {
    const { project, openAddMembersForm } = this.props;
    this.setState({
      isOpenAddMembersModal: true
    });
    openAddMembersForm(MODAL_TYPE.PROJECT, project);
  };

  closeMembersModal = () => {
    this.setState({
      isOpenAddMembersModal: false
    });
    if (this.props.closeMembersModal) {
      this.props.closeMembersModal();
    }
  };

  togglePopover = () => {
    this.setState({
      isOpenAddMembersModal: !this.state.isOpenAddMembersModal
    });
    if (this.props.toggleMembersModal) {
      this.props.toggleMembersModal();
    }
  };

  render() {
    const {
      inBudgetModal,
      isDollars,
      project,
      editOrViewIds,
      openMembersModal
    } = this.props;
    const numMembersText = this.getNumMembersText('Project');
    const memberList =
      project && project.project_membership?.filter((pm) => !!pm.account);
    return (
      <div className="group-members" id="project-members">
        {inBudgetModal ? (
          <BudgetModalVersion
            data-for="app-tooltip"
            data-class="center"
            data-effect="solid"
            data-html
            data-tip="Manage Project<br />Members and Roles"
            onClick={openMembersModal}
          >
            <MembersText className="members-count">
              {memberList?.length || '0'}
              <MembersIconContainer>
                <NewMembersIcon strokeWidth={1} />
              </MembersIconContainer>
            </MembersText>
          </BudgetModalVersion>
        ) : (
          <>
            <ul className="group-members-list">
              {this.renderProjectMembers()}
              {this.renderJoinButton()}

              <li
                data-tip="Click to Manage Project Members"
                data-for="app-tooltip"
                data-place="left"
                data-effect="solid"
                className="group-members-list-item add-members"
              >
                <button
                  className="add-members-button"
                  onClick={this.openAddMembersModal}
                  ref={this.addMembersInstance}
                >
                  <i className="add-members-icon" />
                </button>
              </li>
            </ul>
            <DynamicModuleLoader modules={[getBudgetModule()]}>
              <AddMembersContainer
                isOpenMembersModal={this.state.isOpenAddMembersModal}
                closeMembersModal={this.closeMembersModal}
                modalType={MODAL_TYPE.PROJECT}
              />
            </DynamicModuleLoader>
          </>
        )}
      </div>
    );
  }
}

ProjectMembers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  memberList: PropTypes.array,
  me: PropTypes.object
};
const makeMapStateToProps = () => {
  const getAmIOnProject = makeAmIOnProject();
  const getEditOrViewIds = makeGetEditOrViewIds();
  const getEditOrViewIdProps = (projectId) => ({
    accessIdentifier: buildAccessIdentifier({
      actableType: 'Project',
      actableId: projectId,
      actionType: 'budget_ui'
    })
  });
  const mapStateToProps = (state, ownProps) => ({
    isOnProject: getAmIOnProject(state, ownProps),
    searchText: getSearchText(state),
    tags: getTags(state),
    tagIds: getNotesSelectedTagIds(state),
    matchedParams: getMatchedRouteParams(state),
    editOrViewIds: getEditOrViewIds(
      state,
      getEditOrViewIdProps(ownProps.projectId)
    )
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  setTaskAccountFilter,
  fetchNotesByProject,
  filterByMembers,
  triggerTaskStoreFlush,
  addMemberToProject,
  openAccessModal
};

export default connect(makeMapStateToProps, mapDispatchToProps)(ProjectMembers);
