import { initialState } from 'reducers/recurly';
import { createSelector } from 'reselect';

const getRecurlyState = (state) => state.recurly || initialState;

// const getRecurlyBillingObj = createSelector(
//   getRecurlyState,
//   (state) => state.billingInfo
// );

// export const getRecurlyBillingInfo = createSelector(
//   getRecurlyState,
//   getRecurlyBillingObj,
//   (recurlyState, billingInfo) => ({
//     firstName: billingInfo.attributes?.first_name,
//     lastName: billingInfo.attributes?.last_name,
//     lastFour: billingInfo.attributes?.payment_method?.attributes?.last_four,
//     isLoaded: recurlyState.isLoaded,
//     isLoading: recurlyState.isLoading
//   })
// );

// export const getRecurlyIsLoaded = createSelector(
//   getRecurlyState,
//   (state) => state.isLoaded
// );

export const getRecurlyBeganDate = createSelector(
  getRecurlyState,
  (state) => state.recurlyBeganDate
);
