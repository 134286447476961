import { callApi } from 'appUtils/api';
import { UpdateEntityDefaultCurrencyRequestPayload } from '../types';

export const updateEntityDefaultCurrency = (
  _: unknown,
  token: string,
  bodyParams: UpdateEntityDefaultCurrencyRequestPayload
) => {
  const fetchDefaultCurrencyParams = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...bodyParams
    })
  };

  return callApi(`entity_options`, token, fetchDefaultCurrencyParams);
};
