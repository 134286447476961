import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import AccessModalBody from './AccessModalBody';
import AccessModalHeader from './AccessModalHeader';
import { closeAccessModal } from 'actionCreators';
import { getMe } from 'selectors';

class AccessModal extends React.Component {
  render() {
    const {
      isOpen,
      members,
      nonMembers,
      isMemberEditable,
      renderNonEditableMember,
      options,
      removeOption,
      title,
      actionCopy,
      infoCopy,
      handleSelect,
      handleAdd,
      closeAccessModal,
      renderTitle,
      me
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={closeAccessModal}
        className="workload-access-modal"
      >
        <AccessModalHeader
          title={title}
          renderTitle={renderTitle}
          closeModal={closeAccessModal}
        />
        <AccessModalBody
          actionCopy={actionCopy}
          infoCopy={infoCopy}
          handleAdd={handleAdd}
          members={members}
          nonMembers={nonMembers}
          options={options}
          removeOption={removeOption}
          handleSelect={handleSelect}
          currentUser={me ? me.id : null}
          isMemberEditable={isMemberEditable}
          renderNonEditableMember={renderNonEditableMember}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.access.modalIdentifier === ownProps.accessIdentifier,
  me: getMe(state)
});
const mapDispatchToProps = {
  closeAccessModal
};
export default connect(mapStateToProps, mapDispatchToProps)(AccessModal);
