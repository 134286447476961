import styled from 'styled-components';
import {
  EmploymentTypeFilterListItem,
  BaseFilterList
} from 'FilterModule/components/FilterListsTable/types';
import { TableRow } from 'components/Table/types';
import HelpIcon from 'images/help-icon-2.svg';
import { permissionsModuleConstants } from 'PermissionsModule/components/constants';

export const EmploymentTypeLabel = ({
  rowData
}: {
  rowData: TableRow<EmploymentTypeFilterListItem, BaseFilterList>;
}) => {
  const { item } = rowData;
  const { label, type } = item;

  const tooltip = permissionsModuleConstants.employmentTypeDescriptions[type];

  return (
    <StyledLabel>
      <div>
        <span>{label}</span>
        {tooltip && (
          <StyledHelpIcon
            data-for="app-tooltip"
            data-tip={tooltip}
            data-effect="solid"
            data-class="mw-480 center"
            src={HelpIcon}
          />
        )}
      </div>
    </StyledLabel>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledLabel = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledHelpIcon = styled.img`
  margin-left: 4px;
  margin-bottom: 14px;
  filter: brightness(0.96);
  width: 8px;
  height: 8px;
`;
