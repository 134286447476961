import { initialState, initialFilterState } from 'reducers/scopes';
import { createSelector } from 'reselect';
import { makeGetFilterState } from './filterStates';
import { REDUCER_NAMES } from 'appConstants';

const getScopesState = (state) => state.scopes || initialState;

export const getScopeHash = createSelector(
  getScopesState,
  (state) => state.scopeHash
);

export const getScopeTemporaryUuids = createSelector(
  getScopesState,
  (state) => state.temporaryUuids
);

export const getScopeUpdatingIds = createSelector(
  getScopesState,
  (state) => state.updatingIds
);

export const getProjectsThatHaveFetchedScopes = createSelector(
  getScopesState,
  (state) => state.projectsThatHaveFetchedScopes
);

export const getPhasesThatHaveFetchedScopes = createSelector(
  getScopesState,
  (state) => state.phasesThatHaveFetchedScopes
);

export const getActivityPhasesThatHaveFetchedScopes = createSelector(
  getScopesState,
  (state) => state.activityPhasesThatHaveFetchedScopes
);

/**
 * Includes scope ids that have been explicitly fetched for on trigger with all: true param,
 * and any scopes in scope hash
 */
export const getFetchedScopeIds = createSelector(
  getScopesState,
  (state) => state.fetchedScopeIds
);

// use filterStates.js selectors for filter states

export const getScopesFilterStates = createSelector(
  getScopesState,
  (state) => state.filterStates
);

export const makeGetScopesFilterState = () =>
  createSelector(
    makeGetFilterState(REDUCER_NAMES.SCOPES, initialFilterState),
    (filterState) => filterState
  );

export const makeGetSelectedScopes = () =>
  createSelector(
    makeGetScopesFilterState(),
    (filterState) => filterState.selectedIds
  );

export const makeGetNumSelectedScopes = () =>
  createSelector(
    makeGetScopesFilterState(),
    (filterState) => filterState.numSelected
  );
