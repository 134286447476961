import styled from 'styled-components';
import theme from 'theme';

export const StyledColumnLabel = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  &.date {
    padding-right: 24px;
  }

  &.hour {
  }
`;
