import { createRequestTypes } from 'appUtils';

export const UPDATE_ORGANIZATION_SETTINGS = createRequestTypes(
  'UPDATE_ORGANIZATION_SETTINGS'
);

export const FETCH_ORGANIZATION_SETTINGS = createRequestTypes(
  'FETCH_ORGANIZATION_SETTINGS'
);

export const GET_AUTH_MFA_STATUS_PER_ACCOUNT = createRequestTypes(
  'GET_AUTH_MFA_STATUS_PER_ACCOUNT'
);
export const TOGGLE_INDIVIDUAL_MFA = createRequestTypes(
  'TOGGLE_INDIVIDUAL_MFA'
);

export const TOGGLE_MFA_RESET = createRequestTypes('TOGGLE_MFA_RESET');

export const TOGGLE_PERMISSION_OPEN = 'TOGGLE_PERMISSION_OPEN';
export const TOGGLE_ALL_PERMISSIONS_OPEN = 'TOGGLE_ALL_PERMISSIONS_OPEN';

export const ENTITY_TYPES = {
  teamMembership: 'TeamMembership',
  phaseMembership: 'PhaseMembership',
  office: 'Office',
  project: 'Project'
} as const;

export const ENTITY_DESCRIPTIONS = {
  workAddress: 'work address',
  officeLocation: 'office location',
  projectLocation: 'project location'
} as const;
