import styled, { css } from 'styled-components';
import theme from 'theme';

const HoverStyle = css`
  :hover {
    font-weight: 600;
    letter-spacing: -0.2px;
  }
`;

const BaseTransparentButton = styled.button`
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
`;

export const TextButton = styled(BaseTransparentButton)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.colorLightGray15 : theme.colors.colorCalendarBlue};
  ${({ disabled }) => (!disabled ? HoverStyle : undefined)}
`;
export const IconButton = styled(BaseTransparentButton)`
  display: flex;
`;

export const DependencyItemContainer = styled.div`
  display: flex;
  width: 100%;
  &:hover {
    rect {
      fill: ${theme.colors.colorTranslucentGray4};
    }
  }
`;

export const ListItemContainer = styled.div`
  width: 90%;
`;

export const ArrowIconContainer = styled.div`
  margin-top: 6px;
  margin-left: -9px;
  margin-right: 9px;
  svg {
    transform: rotate(270deg);
  }
`;
