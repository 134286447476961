import { callApi } from 'appUtils/api';
import qs from 'qs';
import {
  CreateEntityRateRequestParams,
  CreateRateGroupRequestParams,
  DeleteEntityRateRequestParams,
  DeleteRateGroupRequestParams,
  FetchRatesRequestParams,
  UpdateEntityRateRequestParams,
  UpdateRateGroupRequestParams
} from './types';
import pick from 'lodash/pick';
import { createKeysList } from './utils';

export const fetchRatesApiRequest = (
  token: string,
  params: FetchRatesRequestParams
) => {
  const query = pick(
    params,
    createKeysList<FetchRatesRequestParams>({ team_id: 0 })
  );

  const fetchInit = {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`rates?${qs.stringify(query)}`, token, fetchInit);
};

export const createRateGroupApiRequest = (
  token: string,
  params: CreateRateGroupRequestParams
) => {
  const body = pick(
    params,
    createKeysList<CreateRateGroupRequestParams>({
      active_entity_type: 0,
      currency: 0,
      description: 0,
      is_default: 0,
      name: 0,
      team_id: 0,
      template_rate_group_id: 0
    })
  );

  const fetchInit = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('rate_groups', token, fetchInit);
};

export const updateRateGroupApiRequest = (
  token: string,
  params: UpdateRateGroupRequestParams
) => {
  const { rate_group_id, ...body } = pick(
    params,
    createKeysList<UpdateRateGroupRequestParams>({
      currency: 0,
      is_archived: 0,
      is_default: 0,
      name: 0,
      rate_group_id: 0
    })
  );

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`rate_groups/${rate_group_id}`, token, fetchInit);
};

export const deleteRateGroupApiRequest = (
  token: string,
  params: DeleteRateGroupRequestParams
) => {
  const { rate_group_id } = pick(
    params,
    createKeysList<DeleteRateGroupRequestParams>({
      rate_group_id: 0
    })
  );

  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`rate_groups/${rate_group_id}`, token, fetchInit);
};

export const createEntityRateApiRequest = (
  token: string,
  params: CreateEntityRateRequestParams
) => {
  const body = pick(
    params,
    createKeysList<CreateEntityRateRequestParams>({
      end_date: 0,
      entity_id: 0,
      entity_type: 0,
      is_cost_rate: 0,
      rate_group_id: 0,
      rate_id: 0,
      start_date: 0,
      team_id: 0
    })
  );

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('entity_rates/', token, fetchInit);
};

export const updateEntityRateApiRequest = (
  token: string,
  params: UpdateEntityRateRequestParams
) => {
  const { entity_rate_id, ...body } = pick(
    params,
    createKeysList<UpdateEntityRateRequestParams>({
      end_date: 0,
      entity_rate_id: 0,
      is_archived: 0,
      is_cost_rate: 0,
      rate_id: 0,
      start_date: 0
    })
  );

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`entity_rates/${entity_rate_id}`, token, fetchInit);
};

export const deleteEntityRateApiRequest = (
  token: string,
  params: DeleteEntityRateRequestParams
) => {
  const { entity_rate_id } = pick(
    params,
    createKeysList<DeleteEntityRateRequestParams>({
      entity_rate_id: 0
    })
  );

  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`entity_rates/${entity_rate_id}`, token, fetchInit);
};
