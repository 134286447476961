import React, { useMemo } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import {
  DATA_KEY,
  DATA_KEY_TO_PERCENT,
  CAPACITY_KEY,
  CHART_STACK_ORDER,
  CHART_STACK_ORDER_WITH_CAPACITY
} from 'UtilizationModule/constants';
import { getChartTooltip, getTopDataField } from 'UtilizationModule/utils';

const Bar = styled.div`
  position: relative;
  height: 16px;
  display: flex;
  width: ${(props) => props.width}%;
  cursor: pointer;
  .show-on-hover {
    display: none;
  }
  .hide-on-hover: {
    display: unset;
  }
  &:hover {
    .show-on-hover {
      display: unset;
    }
    .hide-on-hover {
      display: none;
    }
  }
`;
const BarSection = styled.div`
  height: 100%;
  width: ${(props) => props.width}%;
  min-width: fit-content;
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  display: flex;
  align-items: center;
  padding: 0 3px;
  ${(props) =>
    props.isRightmost &&
    `
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
  justify-content: flex-end;
`;
const BillableSection = styled(BarSection)`
  background: ${theme.colors.colorConnectedGreen};
  color: white;
`;
const NonbillableSection = styled(BarSection)`
  background: ${theme.colors.colorLightGray20};
`;

const RemainingCapacitySection = styled(BarSection)`
  background: ${theme.colors.colorPaleGray5};
`;

const GraphCellContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const TotalHours = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  margin-left: 2px;
`;

const tooltipProps = {
  'data-for': 'app-tooltip',
  'data-effect': 'solid'
};

// Right now PTO and holidays are not selectable
const sectionComponent = {
  [DATA_KEY.BILLABLE]: BillableSection,
  [DATA_KEY.NONBILLABLE]: NonbillableSection,
  [CAPACITY_KEY.REMAINING_CAPACITY]: RemainingCapacitySection
};

/* ------------------------------------ - ----------------------------------- */

const GraphCell = ({ row, includeRemainingCapacity }) => {
  const { data, maxPercent } = row;

  const sectionOrder = useMemo(
    () =>
      [
        ...(includeRemainingCapacity
          ? CHART_STACK_ORDER_WITH_CAPACITY
          : CHART_STACK_ORDER)
      ].reverse(),
    [includeRemainingCapacity]
  );

  const percentRatio = maxPercent.value
    ? (data.totalPercent / maxPercent.value) * 100
    : 0;

  const rightmostDataField = getTopDataField(
    data,
    null,
    includeRemainingCapacity
  );

  const renderSection = (dataKey) => {
    const Section = sectionComponent[dataKey];
    const percent = data[DATA_KEY_TO_PERCENT[dataKey]];
    const hrs = data[dataKey];

    return percent && percent > 0 ? (
      <Section
        width={percent}
        {...tooltipProps}
        data-tip={getChartTooltip(hrs, dataKey)}
        isRightmost={dataKey === rightmostDataField}
        key={dataKey}
      >
        <span className="hide-on-hover">{`${Math.round(hrs)}h`}</span>
        <span className="show-on-hover">{`${Math.round(percent)}%`}</span>
      </Section>
    ) : null;
  };

  return (
    <GraphCellContainer>
      <Bar className="bar" width={percentRatio}>
        {sectionOrder.map((dataKey) => {
          return renderSection(dataKey);
        })}
      </Bar>
    </GraphCellContainer>
  );
};
export default GraphCell;
