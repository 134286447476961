import * as appConstants from 'appConstants';
import * as constants from '../constants';

export const initialState = {
  syncSettings: {},
  syncFetched: false
};
const qbServices = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_SYNC_SETTINGS.SUCCESS: {
      return {
        ...state,
        syncSettings: payload.response,
        syncFetched: true
      };
    }
    case constants.FETCH_SYNC_SETTINGS.FAILURE: {
      return {
        ...state,
        syncSettings: payload.response,
        syncFetched: true
      };
    }

    default: {
      return state;
    }
  }
};

export default qbServices;
