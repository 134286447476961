import React from 'react';
import { useSelector } from 'react-redux';
import { getTeamMembershipsByAccountId } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import { MemberInitials } from 'views';
import cn from 'classnames';
import { ACCESS_ROLES } from 'PermissionsModule/constants';

const StyledText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
`;

const MemberCell = ({ row }) => {
  const { userActivity } = row.original;
  const accountId = userActivity?.display_data?.time_entry?.account_id;
  const teamMembershipsByAccountId = useSelector(getTeamMembershipsByAccountId);
  const teamMember = teamMembershipsByAccountId[accountId];
  const name = teamMember?.account?.name;
  const isManager = teamMember?.role_ids?.some(
    (role) => role === ACCESS_ROLES.ADMIN
  );
  const tooltip = `<div><div>${name}</div>
    <div className="project-manager-text">${
      isManager ? 'Team Manager' : ''
    }</div>
  </div>`;
  if (!teamMember) {
    return <div />;
  }
  return (
    <StyledText>
      <div
        data-for="app-tooltip"
        data-effect="solid"
        data-html
        data-class="center"
        data-tip={tooltip}
      >
        <MemberInitials
          key={teamMember?.account?.id || userActivity.id}
          member={teamMember}
          idx={teamMember?.account?.id}
          size="small"
          classes={cn('regular-member selected', {
            selected: false
          })}
          onClick={() => {}}
          styles={{ marginRight: '10px' }}
          isOnTaskModal
        />
      </div>
      {name}
    </StyledText>
  );
};

export default MemberCell;
