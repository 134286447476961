import React from 'react';
import theme from 'theme';
import cn from 'classnames';
import { connect } from 'react-redux';
import {
  StyledTeamMemberCard,
  PendingText,
  MemberInitials,
  MemberName,
  StyledThreeDotIcon,
  ColorPickerContainer
} from './styles';
import TeamMemberMenu from './TeamMemberMenu';
import { navigateToMemberModal } from 'actionCreators';
import guestIcon from 'images/guest-icon-large.svg';
import { TeamMemberCardTasksContainer } from 'views';
import styled from 'styled-components';
import ColorPicker from 'components/ColorPicker';
import ReactTooltip from 'react-tooltip';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { StyledDropdownItem } from 'BudgetModule/components/BudgetTable/styles';
import DeleteIcon from 'icons/DeleteIcon';
import { noop } from 'appUtils';
import { permissionsUtils } from 'PermissionsModule/utils';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const RoleIndicator = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-image: url(${guestIcon});
  background-position: center center;
  /* border: 0.5px solid #ccc; */
`;

const DropdownItem = styled(StyledDropdownItem)`
  padding: 10px 12px;
  line-height: 1;
  svg {
    margin-right: 10px;
  }
  &:hover svg path {
    fill: ${theme.colors.colorDeleteRed};
  }
`;

const StyledMemberInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

class TeamMemberCard extends React.Component {
  state = {
    menuOpen: false
  };

  onCardClick = (e) => {
    const { teamMember, handleMemberCardClick } = this.props;
    const { menuOpen } = this.state;
    const isPendingVerification = teamMember.pending_email;

    if (!isPendingVerification && !menuOpen) {
      handleMemberCardClick(teamMember.id);
    }
  };

  onMenuOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      menuOpen: true
    });
  };

  onMenuClose = (e) => {
    this.setState({
      menuOpen: false
    });
  };

  renderMenu = () => {
    const { teamMember, workGroupProps = {} } = this.props;
    const { handleRemoveFromWorkGroup = noop, workGroup } = workGroupProps;
    return (
      <>
        <DropdownItem
          onClick={() =>
            handleRemoveFromWorkGroup(teamMember?.account?.id, workGroup?.id)
          }
        >
          <DeleteIcon />
          <span>Remove</span>
        </DropdownItem>
      </>
    );
  };

  render() {
    const {
      teamMember,
      myId,
      workGroupProps: { workGroup },
      navigateToMemberModal,
      clickable
    } = this.props;
    const { menuOpen } = this.state;

    const isMyCard = myId === teamMember.account.id;
    const isPendingVerification = teamMember.pending_email;
    const teamMemberName = teamMember.account.name;
    const teamMemberInitials = teamMember.account.initials;

    return (
      //  StyledTeamMemberCard extends ColorHoverArea - that should change when
      // Three Dot Menu is implemented
      <StyledTeamMemberCard
        className={cn('team-member-card', {
          me: isMyCard,
          pending: isPendingVerification
        })}
        me={isMyCard}
        pending={isPendingVerification}
        menuOpen={menuOpen}
        clickable={clickable}
        onClick={() =>
          navigateToMemberModal({
            memberId: teamMember.account.id,
            memberViewType: 'overview'
          })
        }
      >
        {/*
        Direct Color Picker is temporary until Three Dot Menu is fully implemented
        Uncomment and implement additional menu options in TeamMemberMenu
        <div onClick={this.onMenuOpen}>
          <StyledThreeDotIcon />
        </div>
        {menuOpen && (
          <TeamMemberMenu
            teamMember={teamMember}
            onMenuClose={this.onMenuClose}
            menuOpen={menuOpen}
          />
        )} */}
        {/* Begin Temporary UI */}
        <ColorPickerContainer
          onClick={(e) => e.stopPropagation()}
          data-for="app-tooltip"
          data-tip="Click to choose a member icon color"
          data-html
          data-effect="solid"
        >
          <ColorPicker
            id={teamMember.id}
            originType={ORIGIN_TYPE_STRINGS.TEAM_MEMBERSHIP}
            row
            shouldHide={false}
            className="color-picker-container"
            colorPicker
            // ref={setColorPickerRef}
            pickerLocation={
              'colorpicker-' +
              (workGroup ? `${workGroup.id}-${teamMember.id}` : teamMember.id)
            }
            memberName={teamMemberName}
            memberInitials={teamMemberInitials}
          />
        </ColorPickerContainer>
        {/* End Temporary UI */}
        {workGroup?.id && (
          <ThreeDotMenu
            vertical={false}
            slide={false}
            style={{ position: 'absolute', top: 7, right: 9 }}
          >
            {this.renderMenu()}
          </ThreeDotMenu>
        )}
        {isPendingVerification && (
          <PendingText className="pending-text">Pending</PendingText>
        )}
        <StyledMemberInfoContainer>
          <MemberInitials
            id={teamMember.id}
            originType={ORIGIN_TYPE_STRINGS.TEAM_MEMBERSHIP}
            me={isMyCard}
            classes={'selected'}
          >
            {teamMember.account.initials}
            {permissionsUtils.getIsProjectGuest(teamMember) && (
              <RoleIndicator />
            )}
          </MemberInitials>
          <MemberName>{teamMember.account.name} </MemberName>
        </StyledMemberInfoContainer>

        {/* <TeamMemberCardTasksContainer
          incompleteTasksCount={teamMember.incomplete_tasks_count}
          overdueTasksCount={teamMember.overdue_tasks_count}
        /> */}
      </StyledTeamMemberCard>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  navigateToMemberModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberCard);
