import { Header, HeaderText, StyledSortIcon } from './styles';

export const columnHeaders = {
  notes: (
    <Header>
      <HeaderText>
        NOTES <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  tasks: (
    <Header>
      <HeaderText>
        PROGRESS <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  client: (
    <Header>
      <HeaderText>
        CLIENT <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  number: (
    <Header>
      <HeaderText>
        PROJECT ID <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  members: (
    <Header>
      <HeaderText>
        MEMBERS <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  updates: (
    <Header style={{ justifyContent: 'flex-start' }}>
      <HeaderText>
        UPDATES <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  status: (
    <Header>
      <HeaderText>
        STATUS <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  stage: (
    <Header>
      <HeaderText>
        STAGE <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  priority: (
    <Header>
      <HeaderText>
        PRIORITY <StyledSortIcon />
      </HeaderText>
    </Header>
  ),
  remaining_tasks: (
    <Header>
      <HeaderText>
        TASKS <StyledSortIcon />
      </HeaderText>
    </Header>
  )
};
export default columnHeaders;
