import * as actionCreators from 'actionCreators';
import * as actionSelectors from './actionSelectors';

export const configMap = {
  FETCH_PROJECT_BY_ID: [
    {
      channel: 'HomeProjectTaskChannel',
      handleReceive: actionCreators.wsTask,
      getIds: actionSelectors.getProjectIds
    },
    {
      channel: 'ProjectStatusChannel',
      handleReceive: actionCreators.wsProjectComment,
      getIds: actionSelectors.getProjectIds
    },
    {
      channel: 'TaskCommentChannel',
      handleReceive: actionCreators.wsTaskComment,
      getIds: actionSelectors.getProjectIds
    },
    {
      channel: 'ProjectMemberChannel',
      handleReceive: actionCreators.wsProjectMember,
      getIds: actionSelectors.getProjectIds
    },
    {
      channel: 'ProjectNoteChannel',
      handleReceive: actionCreators.wsProjectNote,
      getIds: actionSelectors.getProjectIds
    }
  ],
  USER_SUCCESS: [
    {
      channel: 'AccountChannel',
      handleReceive: actionCreators.wsAccount,
      getIds: actionSelectors.getUserId
    },
    {
      channel: 'TeamMemberChannel',
      handleReceive: actionCreators.wsTeamMember,
      getIds: actionSelectors.getTeamMembershipId
    }
  ],
  FETCH_SELECTED_BOARD_SUCCESS: [
    {
      channel: 'ProjectChannel',
      handleReceive: actionCreators.wsProject,
      getIds: actionSelectors.getBoardId
    }
  ],
  SUBSCRIBE_WORKLOAD: [
    {
      channel: 'WorkloadChannel',
      handleReceive: actionCreators.wsScheduleBar,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'ScheduledEventChannel',
      handleReceive: actionCreators.wsScheduledEvent,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'PhaseWorkloadChannel',
      handleReceive: actionCreators.wsPhase,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'HolidayChannel',
      handleReceive: actionCreators.wsHoliday,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'PositionChannel',
      handleReceive: actionCreators.wsPosition,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'TeamPositionChannel',
      handleReceive: actionCreators.wsTeamPosition,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'TeamMemberSkillChannel',
      handleReceive: actionCreators.wsTeamMemberSkill,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'TeamSkillChannel',
      handleReceive: actionCreators.wsTeamSkill,
      getIds: actionSelectors.getAccountId
    },
    {
      channel: 'WorkGroupMembershipChannel',
      handleReceive: actionCreators.wsWorkGroupMembership,
      getIds: actionSelectors.getAccountId
    }
  ],
  SUBSCRIBE_ATTACHMENT: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIds
    }
  ],
  FETCH_SCOPES_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromScope
    }
  ],
  FETCH_COMMENTS_AND_METADATA_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromComments
    }
  ],
  CREATE_COMMENT_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromComment
    }
  ],
  UPDATE_COMMENT_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromComment
    }
  ],
  PROJECT_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromProject
    }
  ],
  CREATE_PROJECT_COMMENT_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromComment
    }
  ],
  EDIT_PROJECT_COMMENT_SUCCESS: [
    {
      channel: 'AttachmentChannel',
      handleReceive: actionCreators.wsAttachment,
      getIds: actionSelectors.getAttachmentIdsFromComment
    }
  ]
};
