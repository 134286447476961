import theme from 'theme';
import styled from 'styled-components';
import FilledArrow from 'icons/FilledArrow';
import { MemberAlternateRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const MemberAlternatesTitleCell = ({ row }: MemberAlternateRowProps) => {
  const { original } = row;
  const {
    numOfRemainingSuggestedMembers,
    isShowingAllMembers,
    toggleCollapse
  } = original;
  return (
    <StyledContainer onClick={toggleCollapse}>
      {isShowingAllMembers
        ? 'Hide Alternates'
        : `${numOfRemainingSuggestedMembers} Alternates`}
      <FilledArrow
        width={9}
        height={9}
        color={theme.colors.colorCalendarBlue}
      />
    </StyledContainer>
  );
};

export default MemberAlternatesTitleCell;

const StyledContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.colorCalendarBlue};
  padding-left: 11px;
  cursor: pointer;
`;
