import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportRole
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingRolesByName: Record<string, CsvImportRole>;
  newRoles: React.MutableRefObject<Record<string, boolean>>;
}

export const roleOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ additionalParams }) => {
  const { existingRolesByName, newRoles } = additionalParams;
  return [
    ...Object.keys(newRoles.current),
    ...Object.keys(existingRolesByName)
  ];
};
