import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'UsersModule/selectors';
import { getTeamMembershipsByAccountId } from 'selectors';
import styled from 'styled-components';
import MemberInitials, {
  StyledMemberCircle
} from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import DevProps from 'components/dev/DevProps';

type MemberCircleSize =
  | 'large'
  | 'medium'
  | 'default'
  | 'mediumSmall'
  | 'small';

interface MemberCirclesProps {
  /**
   * A list of the account ids to be displayed.
   */
  accountIds: Array<number>;

  /**
   * Indicates whether the circles should be centered in the container.
   */
  center?: boolean;

  /**
   * Indicates whether the circles should be seperated by default. If this is
   * set to `true`, `noAnimation` has no effect.
   */
  isExpanded?: boolean;

  /**
   * The maximum number of visibile member circles.
   */
  maxVisible?: number | 'all';

  /**
   * Indicates whether there should be an animation separating the overlapping
   * items when the user hovers over the container. This has no effect if
   * `isExpanding` is set to `true`.
   */
  noAnimation?: boolean;

  /**
   * The size of the circles. See `MemberInitials` for definitions.
   */
  size?: MemberCircleSize;
}

/**
 * Draws a horizontal list of member avatars.
 */
const MemberCircles = ({
  accountIds,
  center,
  isExpanded,
  maxVisible = 3,
  noAnimation,
  size = 'small'
}: MemberCirclesProps) => {
  const me = useSelector(getMe);
  const teamMembershipsByAccountId = useSelector(getTeamMembershipsByAccountId);
  const numHidden = maxVisible === 'all' ? 0 : accountIds.length - maxVisible;

  const members = useMemo(() => {
    const formattedMembers = accountIds
      .map((accountId) => teamMembershipsByAccountId[accountId])
      .filter((teamMember) => !!teamMember);
    return maxVisible === 'all'
      ? formattedMembers
      : formattedMembers.slice(0, maxVisible);
  }, [accountIds, maxVisible, teamMembershipsByAccountId]);

  return (
    <StyledMemberCircles
      noAnimation={noAnimation}
      className="member-circles"
      center={center}
      isExpanded={isExpanded}
      showAll={maxVisible === 'all'}
    >
      <DevProps members={members} />

      {numHidden > 0 && (
        <AdditionalMembersCircle className="member-initials" size={size}>
          +{numHidden}
        </AdditionalMembersCircle>
      )}
      {members.map((teamMember) => (
        <MemberInitials
          key={teamMember.account.id}
          member={teamMember}
          idx={teamMember.account.id}
          size={size}
          classes={cn(
            'regular-member selected',
            {
              selected: false
            },
            { 'logged-member': teamMember.account.id === me?.id }
          )}
        />
      ))}
    </StyledMemberCircles>
  );
};

export default MemberCircles;

/* ------------------------------------ - ----------------------------------- */

const AdditionalMembersCircle = styled(StyledMemberCircle)<{
  size?: MemberCircleSize;
}>`
  color: white;
  border-color: transparent !important;
  font-size: 14px;
`;

const StyledMemberCircles = styled.div<{
  center?: boolean;
  isExpanded?: boolean;
  noAnimation?: boolean;
  showAll?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-end')};
  overflow: hidden;

  .member-initials {
    border: 2px solid white;
    border-radius: 100px;
    transition: 0.275s ease-in-out;
    &:not(:last-child) {
      margin-left: -8px;
    }
  }

  ${(props) =>
    !props.noAnimation &&
    `
    &:hover {
      .member-initials:not(:last-child) {
        margin-left: 3px;
      }
    }
  `}

  ${(props) =>
    props.isExpanded &&
    props.showAll &&
    `
    transform: translateX(-3px);
    justify-content: unset;
    flex-wrap: wrap;
    flex-direction: unset;
    .member-initials {
      margin-left: 3px !important;
    }
  `}
`;
