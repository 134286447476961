import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
import produce from 'immer';
import omit from 'lodash/omit';
const byId = (item) => item.id;

export const initialState = {
  qbSubCustomers: {},
  qbSyncedSubCustomers: {},
  qbDefaultTeam: {},
  qbToMosaic: true,
  isDefaultTeamSelected: true,
  isShowOnlySynced: false,
  isFetchingSyncedSubCustomers: false,
  isInitialFetchSyncSubCustomers: false,
  selectedProjectsQBToMosaic: {},
  selectedProjectsMosaicToQB: {},
  selectedPreFillProjectsQBToMosaic: {},
  selectedPreFillProjectsMosaicToQB: {},
  mappingFilter: constants.mappingFilters.SHOW_ALL,
  isActiveMappingFilter: constants.mappingFilters.SHOW_ACTIVE
};
const qbSubCustomers = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_SUBCUSTOMERS.SUCCESS: {
      const { subcustomers } = payload.response;
      return {
        ...state,
        qbSubCustomers: keyBy(subcustomers, byId)
      };
    }
    case constants.FETCH_SYNCED_SUBCUSTOMERS.SUCCESS: {
      const { mappings } = payload.response;
      return {
        ...state,
        qbSyncedSubCustomers: keyBy(mappings, byId)
      };
    }
    case constants.UPDATE_SYNC_SETTINGS.SUCCESS: {
      const { response } = payload;
      const newState = produce(state, (draftState) => {
        draftState.qbDefaultTeam = response;
      });
      return newState;
    }
    case constants.SET_CONFIG_DIR_QB_TO_MOSAIC: {
      return {
        ...state,
        qbToMosaic: action.qbToMosaic
      };
    }
    case constants.TOGGLE_SYNCED_PROJECTS: {
      const { isShowOnlySynced } = action;
      return {
        ...state,
        isShowOnlySynced
      };
    }
    case constants.QB_TOGGLE_BATCH_SELECT: {
      const { itemType, items, value } = payload;
      if (itemType !== 'projects') {
        return state;
      }
      const itemsById = keyBy(items, byId);
      const selectedProjectsStateSlice = state.qbToMosaic
        ? 'selectedProjectsQBToMosaic'
        : 'selectedProjectsMosaicToQB';
      const selectedPrefillProjectState = state.qbToMosaic
        ? 'selectedPreFillProjectsQBToMosaic'
        : 'selectedPreFillProjectsMosaicToQB';

      if (value) {
        return {
          ...state,
          [selectedProjectsStateSlice]: {
            ...state[selectedProjectsStateSlice],
            ...itemsById
          }
        };
      } else {
        return {
          ...state,
          [selectedProjectsStateSlice]: omit(
            state[selectedProjectsStateSlice],
            Object.keys(itemsById)
          ),
          [selectedPrefillProjectState]: omit(
            state[selectedPrefillProjectState],
            Object.keys(itemsById)
          )
        };
      }
    }
    case constants.QB_FLUSH_BATCH_SELECT: {
      const { itemType } = payload;
      if (itemType !== 'projects') {
        return state;
      }
      const selectedProjectsStateSlice = state.qbToMosaic
        ? 'selectedProjectsQBToMosaic'
        : 'selectedProjectsMosaicToQB';
      return {
        ...state,
        [selectedProjectsStateSlice]: {}
      };
    }
    case constants.TOGGLE_MAPPING_FILTER: {
      const { section, mappingFilter } = payload;
      if (section === 'projects') {
        return {
          ...state,
          mappingFilter
        };
      }
      if (section === 'projectsIsActive') {
        return {
          ...state,
          isActiveMappingFilter: mappingFilter
        };
      }
      return state;
    }

    case constants.PREFILL_CREATE_NEW_ROWS: {
      const { projects } = action;
      const itemsById = keyBy(projects, byId);
      const selectedPrefillProjectState = state.qbToMosaic
        ? 'selectedPreFillProjectsQBToMosaic'
        : 'selectedPreFillProjectsMosaicToQB';
      return {
        ...state,
        [selectedPrefillProjectState]: {
          ...state[selectedPrefillProjectState],
          ...itemsById
        }
      };
    }

    case constants.FLUSH_PREFILL_CREATE_NEW_ROWS: {
      const selectedPrefillProjectState = state.qbToMosaic
        ? 'selectedPreFillProjectsQBToMosaic'
        : 'selectedPreFillProjectsMosaicToQB';
      return {
        ...state,
        [selectedPrefillProjectState]: {}
      };
    }
    case constants.FETCH_SYNCED_SUBCUSTOMER.SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        qbSyncedSubCustomers: {
          ...state.qbSyncedSubCustomers,
          [response.id]: response
        }
      };
    }
    case constants.INITIAL_FETCH_SYNC_PROJECTS: {
      const { isInitialFetching } = action;
      return {
        ...state,
        isInitialFetchSyncSubCustomers: isInitialFetching
      };
    }
    case constants.TOGGLE_PROJECTS_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isFetchingSyncedSubCustomers: isLoading
      };
    }
    case constants.TOGGLE_DEFAULT_TEAM_ERROR: {
      const { isDefaultTeamSelected } = action;
      return {
        ...state,
        isDefaultTeamSelected
      };
    }

    default: {
      return state;
    }
  }
};

export default qbSubCustomers;
