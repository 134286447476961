import * as constants from 'appConstants';
import keyBy from 'lodash/keyBy';

const initialState = {
  projects: {}
};

const keyifyMetadata = (metadataArr) => keyBy(metadataArr, 'id');

const projectMetadata = (state = initialState, action) => {
  const { type, payload } = action;
  const { response } = payload || {};
  const { metadata } = response || {};
  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.PROJECT.SUCCESS: {
      const projectId = response.projects[0].id;
      return {
        ...state,
        projects: {
          ...state.projects,
          [projectId]: {
            ...state.projects[projectId],
            ...keyifyMetadata(metadata)
          }
        }
      };
    }
    default:
      return state;
  }
};

export default projectMetadata;
