import { useMemo } from 'react';
import styled from 'styled-components';

export const StyledStickyHeader = styled.div.attrs((props) => {
  if (props.templateCols) return props.templateCols;

  let templateCols = '';

  props.columns.forEach((column) => {
    templateCols += ` ${props.columnWidths[column.headerType]}px `;
  });

  return {
    templateCols
  };
})`
  position: absolute;
  display: grid;
  width: ${(props) => props.totalWidth}px;
  height: ${(props) => props.rowHeight}px;
  grid-template-columns: ${(props) => props.templateCols};
  padding: 0 1rem;
  outline: none;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}
`;

export const StyledStickyCellContainer = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: ${({ backgroundColor }) => backgroundColor || 'white'};
  position: relative;
  // There's a .collapse:not(.show) { display: none }
  // coming from somewhere - can't find
  &.collapse {
    display: inline-block;
  }
`;

const StickyHeader = ({
  row,
  columnWidths,
  totalWidth,
  cells,
  columns,
  provided = {},
  isHidden,
  rowClassName,
  templateCols,
  backgroundColor,
  marginTop,
  height,
  customRowProps
}) => {
  const { rowTooltipProps = {} } = row.original;

  // use only the columns that are relevant to this row
  const columnsToUse = useMemo(() => {
    return columns.filter((column) => cells[column.headerType]);
  }, [columns, cells]);

  return (
    <StyledStickyHeader
      columnWidths={columnWidths}
      columns={columnsToUse}
      rowHeight={height ?? row.original.itemHeight}
      totalWidth={totalWidth}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      templateCols={templateCols}
      className={`stickyHeader tr ${rowClassName || ''}`}
      marginTop={marginTop}
      {...rowTooltipProps}
    >
      {!isHidden &&
        columnsToUse.map((column) => {
          const Cell = cells[column.headerType];
          if (!Cell) return null;
          return (
            <StyledStickyCellContainer
              key={column.headerType}
              column={column.headerType}
              className={`td ${column.id}`}
              backgroundColor={backgroundColor}
            >
              <Cell
                row={row}
                column={column}
                columns={columns}
                customRowProps={customRowProps}
              />
            </StyledStickyCellContainer>
          );
        })}
    </StyledStickyHeader>
  );
};

export default StickyHeader;
