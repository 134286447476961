import React from 'react';
import MilestoneTableTitleCell from './MilestoneTableTitleCell';
import MilestoneTableMembersCell from './MilestoneTableMembersCell';
import MilestoneTableStartDateCell from './MilestoneTableStartDateCell';
import MilestoneTableWorkdaysCell from './MilestoneTableWorkdaysCell';
import MilestoneTableDeleteCell from './MilestoneTableDeleteCell';
import MilestoneTableGripCell from './MilestoneTableGripCell';
import MilestoneTableMenuCell from './MilestoneTableMenuCell';
import MilestoneTableStatusCell from './MilestoneTableStatusCell';

class MilestoneTableCell extends React.Component {
  grip = () => <MilestoneTableGripCell {...this.props} />;
  title = () => <MilestoneTableTitleCell {...this.props} />;
  members = () => <MilestoneTableMembersCell {...this.props} />;
  startDate = () => <MilestoneTableStartDateCell {...this.props} />;
  dash = () => <div />;
  endDate = () => <div />;
  status = () => <MilestoneTableStatusCell {...this.props} />;
  workdays = () => <MilestoneTableWorkdaysCell {...this.props} />;
  delete = () => <MilestoneTableDeleteCell {...this.props} />;
  menu = () => <MilestoneTableMenuCell {...this.props} />;

  render() {
    const { column } = this.props;
    const { headerType } = column;
    const CellComponent = this[headerType];

    return <CellComponent />;
  }
}

export default MilestoneTableCell;
