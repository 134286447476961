import { createStore } from 'redux-dynamic-modules';
import { END } from 'redux-saga';
import permissionsMiddleware from './configurePermissionsMiddleware';

import createSocketsMiddleware from '../middleware/websockets';
import { responsiveStoreEnhancer } from 'redux-responsive';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { composeWithDevTools as productionComposeWithDevTools } from 'redux-devtools-extension';
import throttle from 'lodash/throttle';
import { getRootModule, history as rootHistory } from '../rootModule';
import { persistentState } from '../service';
import ActionCable from 'action-cable-react-jwt';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { routerMiddleware } from 'connected-react-router';
import { bootstrappedBrowserState } from '../reducers/browser';
import { initialState as groupsInitialState } from '../reducers/groups';
import { initialState as routeHistoryInitialState } from '../reducers/routeHistory';
import * as Sentry from '@sentry/react';
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux';
import thunk from 'redux-thunk';
import { splitFlags } from 'appConstants';
import {
  FETCH_MAPPED_MOSAIC_PROJECT_IDS,
  FETCH_MAPPED_MOSAIC_ACCOUNT_IDS
} from 'IntegrationsModule/constants';
import { initLogRocketMiddleware } from 'thirdPartyModuleConfigs/logRocketConfig';
import { initMixpanelMiddleware } from 'thirdPartyModuleConfigs/mixpanelConfig';

export const history = rootHistory;

// Split IO config
const sdkBrowserConfig = {
  core: {
    authorizationKey: process.env.SPLITIO_APIKEY ?? '',
    key: 'flag'
  }
};

export default function configureStore() {
  const persistedState = persistentState.loadState();
  const makeCable = (url, token) => ActionCable.createConsumer(url, token);
  const socketsMiddleware = createSocketsMiddleware(makeCable);

  const middlewares = [socketsMiddleware, permissionsMiddleware];

  initMixpanelMiddleware(middlewares);
  initLogRocketMiddleware(middlewares);

  middlewares.push(routerMiddleware(history), thunk);

  // It is disabled by default since it has poor performance on dev server
  // Make sure we don't mutate our state
  if (
    !!process.env.ENABLE_REDUX_IMMUTABLE_STATE_INVARIANT &&
    process.env.NODE_ENV !== 'production' &&
    process.env.TEST !== 'true'
  ) {
    // eslint-disable-next-line global-require,import/no-extraneous-dependencies
    middlewares.unshift(
      require('redux-immutable-state-invariant').default({
        ignore: [
          'notes.notesArray',
          'notes.activityFeedNotes',
          // add ignore patterns to avoid below issue
          // Invariant Violation: A state mutation was detected between dispatches ...
          // See: https://github.com/LifeCoded/mosaic-web/pull/6953
          // See: https://mosaicnyc.atlassian.net/browse/WEB-127
          'homePlanner.dateHeader',
          'homePlanner.earliestDateFetched',
          'homePlanner.latestDateFetched',
          'projectPlannerModal.visibleTimeStart',
          'projectPlannerModal.visibleTimeEnd',
          'workloadEvents.workloadRangeStart',
          'workloadEvents.workloadEvents.new.metadata.date_range',
          'workloadPlanner.visibleTimes'
        ]
      })
    );
  }

  // allow redux dev tools on staging and local production builds
  const isReduxDevToolsEnabled =
    process.env.UI_ENV === 'staging' ||
    window.location.href.includes('http://localhost');

  // Redux devtool trace currently broken
  // Redux dev tools options - see https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
  const advancedComposeEnhancers = isReduxDevToolsEnabled
    ? productionComposeWithDevTools({
        // actionCreators,
        actionsDenylist: [
          FETCH_MAPPED_MOSAIC_PROJECT_IDS.TRIGGER,
          FETCH_MAPPED_MOSAIC_PROJECT_IDS.REQUEST,
          FETCH_MAPPED_MOSAIC_PROJECT_IDS.SUCCESS,
          FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.TRIGGER,
          FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.REQUEST,
          FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.SUCCESS
        ],
        // actionsWhitelist: [],
        // trace: process.env.NODE_ENV !== 'production'
        latency: 1000, // if more than one action is dispatched in the indicated interval, all new actions will be collected and sent at once
        maxAge: 20 // max number of recent states to retain - adjust as needed for performance
      })
    : null;

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
  });

  const moduleStore = createStore(
    {
      ...(advancedComposeEnhancers && { advancedComposeEnhancers }),
      initialState: { ...persistedState, browser: bootstrappedBrowserState },
      enhancers: [responsiveStoreEnhancer, sentryReduxEnhancer],
      // see https://github.com/microsoft/redux-dynamic-modules/issues/111, for why middleware need to be loaded here instead of composed with responsiveStoreEnhancer
      extensions: [{ middleware: middlewares }, getSagaExtension()]
    },
    getRootModule()
    // any additional modules
  );

  moduleStore.subscribe(
    throttle(() => {
      persistentState.saveState({
        auth: persistentState.overridePersistantAuthStorage(
          moduleStore.getState().auth
        ),
        userPermissions: moduleStore.getState().userPermissions,
        groups: {
          ...groupsInitialState, // only persist recent board
          recentBoard: moduleStore.getState().groups.recentBoard
        },
        routeHistory: {
          ...routeHistoryInitialState,
          lastLocations: moduleStore.getState().routeHistory.lastLocations
        }
      });
    }, 1000)
  );
  // Split IO
  function onReadyCallback() {
    moduleStore.dispatch(getTreatments({ splitNames: splitFlags }));
  }
  function onUpdateCallback() {
    const state = moduleStore.getState();
    moduleStore.dispatch(
      getTreatments({
        splitNames: splitFlags,
        evalOnUpdate: true,
        attributes: {
          teamId: state.teams.selectedTeam?.id
        }
      })
    );
  }
  moduleStore.dispatch(
    initSplitSdk({
      config: sdkBrowserConfig,
      onReady: onReadyCallback,
      onUpdate: onUpdateCallback
    })
  );

  moduleStore.close = () => moduleStore.dispatch(END);
  return moduleStore;
}
