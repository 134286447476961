import { currencyFormatter, UseCurrencyFormatterByTeamProps } from '.';
import { getTeamCurrencyCodeByTeamId } from 'CurrencyModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';

export const useCurrencyFormatterByTeam = ({
  languageCode = 'en-US', // eventually will be replaced by multi language support
  options
}: UseCurrencyFormatterByTeamProps): Intl.NumberFormat | undefined => {
  const currencyCode = useAppSelector(getTeamCurrencyCodeByTeamId);

  if (!currencyCode) return;

  return currencyFormatter({
    languageCode,
    currencyCode,
    options
  });
};
