import React from 'react';
import ReactDOM from 'react-dom';
// should import config file first
import 'thirdPartyModuleConfigs';
import RouterConfig from './router';
// import './apmConfig';
import { initFetchInterceptor } from './fetchInterceptor';
import { initI18n } from '../i18n/i18nConfig';

initFetchInterceptor();

initI18n();

const container = document.getElementById('root');

ReactDOM.render(<RouterConfig />, container);
