import theme from 'theme';
import styled from 'styled-components';

export const HelpOverlayContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 20px;
  z-index: 200;
`;

export const MessageText = styled.div`
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 5px;
  position: fixed;
  bottom: 25px;
  z-index: 100;
  left: 70px;
  color: ${theme.colors.colorPureWhite};
  background: ${theme.colors.colorHelpBlue};
`;

export const HelpButtonLink = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;
export const HelpButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  font-size: 24px;
  line-height: 1;
  background: ${theme.colors.colorHelpBlue};
  color: ${({ theme }) => theme.colors.colorPureWhite};
  border-radius: 300px;
  font-weight: 800;
  border: 1px solid ${theme.colors.colorHelpBlue};
  color: ${({ theme }) => theme.colors.colorPureWhite};
  &:hover {
    filter: brightness(1.1);
    color: ${theme.colors.colorPureWhite};
  }
`;

export const StyledMilestoneContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 0 0 12px;

  svg {
    position: relative;
    top: 2px;
  }
`;
