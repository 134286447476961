import React from 'react';
import theme from 'theme';
import { RowItems, GroupRow } from 'react-calendar-timeline';
import cn from 'classnames';
import { getMyWorkPlanSettings, getSelectedTeamViewSettings } from 'selectors';
import { openWorkloadProjectRow } from 'actionCreators';
import {
  OverflowContainer,
  StyledButtonContainer,
  StyledSmallHamburger,
  SummaryContainer,
  SummaryContent,
  SummaryBackground,
  SummaryCommitted,
  StyledRef,
  StyledBorder,
  StyledWFHIconContainer,
  StyledTimeEntryContainer,
  StyledCheckmarkIcon,
  ActiveWorkplanBar,
  SummaryChip,
  AvailableText,
  PTOValueText,
  OffTextContainer
} from './styles';
import {
  CAPACITY_HEAT_MAP,
  CAPACITY_GRAY_HEAT_MAP,
  CAPACITY_FONT_COLOR,
  DISPLAY_MEMBER_CAPACITY,
  VIEW_BY,
  ZOOM_LEVELS,
  ZOOM_STRINGS,
  VIEW_TYPE,
  CONDENSED_ZOOM_LEVELS,
  CONDENSED_VIEW_OVERFLOW_TRIANGLE
} from 'appConstants/workload';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import RowActionButton from './RowActionButton';
import DroppableLayer from './projectTimeline/Rows/DroppableLayer';
import { useInView } from 'react-intersection-observer';
import { zoomToIntervalHash } from 'appUtils/projectPlannerUtils';
import sum from 'lodash/sum';
import clamp from 'lodash/clamp';
import {
  format0To9ToWords,
  formatNumWithMaxOneDecimal
} from 'appUtils/formatUtils';
import WFHIcon from 'icons/WFHIcon';
import useWorkloadDataProvider, {
  useIsOOO,
  useIsWFH,
  getWeekDateRange,
  getMonthDateRange,
  getOvercapacityMembers,
  getSummedUtilizationsBreakdownByZoomStep
} from 'appUtils/hooks/useWorkloadDataProvider';
import {
  buildColorScaleIntervals,
  buildIntervalsWithMaxima
} from 'components/ColorScaleModal/utils';
import {
  COLOR_SCALE_COLORS_DEFAULT,
  COLOR_SCALE_MINIMA_DEFAULT
} from 'components/ColorScaleModal/constants';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getWorkloadCapacityTooltip } from './workloadCapacityTooltipUtils';
import capitalize from 'lodash/capitalize';
import { CREATE_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const moment = extendMoment(Moment);
const emptyArray = [];
const emptyObj = {};
const noop = () => {};

const getShowPlannedHoursBar = (date, stepValue, projectTotals) => {
  switch (stepValue) {
    case ZOOM_STRINGS.MONTH: {
      const monthDates = getMonthDateRange(date);
      return monthDates.some(
        (date) =>
          projectTotals[date]?.budget_totals?.planned_hours &&
          projectTotals[date]?.budget_totals?.planned_hours > 0
      );
    }
    case ZOOM_STRINGS.WEEK: {
      const weekDates = getWeekDateRange(date);
      return weekDates.some(
        (date) =>
          projectTotals[date]?.budget_totals?.planned_hours &&
          projectTotals[date]?.budget_totals?.planned_hours > 0
      );
    }
    default: {
      const plannedHours = projectTotals[date]?.budget_totals?.planned_hours;
      return plannedHours && plannedHours > 0;
    }
  }
};

const getTimeEntry = (date, stepValue, timeEntryTotals) => {
  switch (stepValue) {
    case ZOOM_STRINGS.MONTH: {
      const monthDates = getMonthDateRange(date);
      const totalHours = monthDates.reduce(
        (total, date) =>
          total + (timeEntryTotals[date]?.totalTimesheetHours || 0),
        0
      );
      return totalHours;
    }
    case ZOOM_STRINGS.WEEK: {
      const weekDates = getWeekDateRange(date);
      const totalHours = weekDates.reduce(
        (total, date) =>
          total + (timeEntryTotals[date]?.totalTimesheetHours || 0),
        0
      );
      return totalHours;
    }
    default: {
      return timeEntryTotals[date]?.totalTimesheetHours || 0;
    }
  }
};

/**
 * Tracks the visibility of the row and renders the row renderer only when visible
 */
const WorkloadPlannerRowRendererWrapper = (props) => {
  const { group } = props;

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0
  }); // only measures intersection of ref and view, NOT contents of ref.

  return (
    <GroupRow>
      <StyledRef ref={ref} />
      {/* shouldRenderRow hides the bottom hover boxes */}
      {inView && group.shouldRenderRow && (
        <WorkloadPlannerRowRenderer {...props} />
      )}
    </GroupRow>
  );
};

const workplanBarTooltip = `
<div>
  Work Plans Scheduled
  <br />
  Click to expand row
</div>
`;

const WorkloadPlannerRowRenderer = (props) => {
  const {
    group,
    utilizations = emptyObj,
    steps,
    getLayerRootProps,
    capacity,
    unCappedUtilizationsByMember,
    cappedUtilizationsByMember,
    onCanvasClick,
    onRootCanvasClick,
    keys,
    workloadViewBy,
    workloadViewType,
    handleRowSecondClick,
    holidayDatesHash,
    rowActionText,
    isOnTeamSchedules,
    zoom,
    setInView,
    shouldHighlightUpcoming,
    handleCapacityContextMenu,
    handleWFHContextMenu,
    handleTimeEntryContextMenu,
    useMemberValues,
    isMemberSplitScreenRow,
    WFHContextMenuId,
    WFHContextMenuStep,
    TimeEntryContextMenuId,
    TimeEntryContextMenuStep,
    isSplitScreenRow,
    phasesLength,
    isPhaseDefault,
    lineHeight,
    condensedZoomLevel,
    canCreateWorkPlan,
    timeEntryTotals,
    showTimeEntryTotals,
    projectTotals,
    isPersonalPlanner,
    hasDroppableLayer,
    utilizationsBreakdown,
    summaryUtilizationsBreakdown
  } = props;

  const myWorkPlanSettings = useAppSelector(getMyWorkPlanSettings);
  const teamViewSettings = useAppSelector(getSelectedTeamViewSettings);
  const heatmap = buildIntervalsWithMaxima(
    buildColorScaleIntervals({
      colors:
        myWorkPlanSettings?.capacity_heat_map_colors ||
        teamViewSettings.work_load?.capacity_heat_map_colors ||
        COLOR_SCALE_COLORS_DEFAULT,
      minima:
        teamViewSettings.work_load?.capacity_heat_map_intervals ||
        COLOR_SCALE_MINIMA_DEFAULT
    })
  );

  const memberHash = useAppSelector(getTeamMembersHash);

  const stepValue = zoomToIntervalHash[zoom];
  const { checkStep: checkIsOOO } = useIsOOO(capacity, stepValue);
  const { checkStep: checkIsWFH } = useIsWFH(capacity, stepValue);

  const { formattingUtils, columnWidths, getGroupDimensions } =
    useWorkloadDataProvider(zoom, steps, holidayDatesHash);

  const groupId = group[keys.groupIdKey];
  const deserializedGroupId =
    `${groupId}`.startsWith('root') || `${groupId}`.startsWith('bottom')
      ? groupId.split('--')[1]
      : groupId;
  const groupDimensions = getGroupDimensions(groupId);
  const height = groupDimensions ? groupDimensions.height : 50;
  const testIDPrefix = group?.name || group?.title || 'summary';

  return (
    <>
      <RowItems unavailableSlots={emptyArray} />
      <UnavailableLayer
        getLayerRootProps={getLayerRootProps}
        isWorkloadSplitRoot={group?.isWorkloadSplitRoot}
        isWorkloadSplitRow={group?.isWorkloadSplitRow}
        isRoot={group?.isRoot || group?.isSplitScreen}
        isBottom={group?.isBottom}
        isSummary={group?.isSummary}
        isOpen={group?.isOpen}
        isLoading={group?.isLoading}
        CustomRootRenderer={group?.CustomRootRenderer}
        memberAccountIds={group?.member_account_ids}
        steps={steps}
        utilizations={utilizations}
        capacity={capacity}
        unCappedUtilizationsByMember={unCappedUtilizationsByMember}
        cappedUtilizationsByMember={cappedUtilizationsByMember}
        zoom={zoom}
        stepValue={stepValue}
        groupId={groupId}
        deserializedGroupId={deserializedGroupId}
        index={group?.index}
        numBoxes={Math.round(height / 50)}
        onCanvasClick={onCanvasClick}
        onRootCanvasClick={onRootCanvasClick}
        workloadViewBy={workloadViewBy}
        workloadViewType={workloadViewType}
        columnWidths={columnWidths}
        formattingUtils={formattingUtils}
        rowActionText={rowActionText}
        isOnTeamSchedules={isOnTeamSchedules}
        handleRowSecondClick={handleRowSecondClick}
        setInView={setInView}
        shouldHighlightUpcoming={shouldHighlightUpcoming}
        handleCapacityContextMenu={handleCapacityContextMenu}
        handleWFHContextMenu={handleWFHContextMenu}
        handleTimeEntryContextMenu={handleTimeEntryContextMenu}
        WFHContextMenuId={WFHContextMenuId}
        WFHContextMenuStep={WFHContextMenuStep}
        TimeEntryContextMenuId={TimeEntryContextMenuId}
        TimeEntryContextMenuStep={TimeEntryContextMenuStep}
        useMemberValues={useMemberValues}
        displayMemberCapacity={
          myWorkPlanSettings?.display_member_capacity ||
          DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS
        }
        showCheckMark={myWorkPlanSettings?.show_checkmarks_at_100}
        isMemberSplitScreenRow={isMemberSplitScreenRow}
        isSplitScreenRow={isSplitScreenRow}
        testIDPrefix={testIDPrefix}
        phasesLength={phasesLength}
        isPhaseDefault={isPhaseDefault}
        projectTitle={group?.title}
        checkIsOOO={checkIsOOO}
        checkIsWFH={checkIsWFH}
        lineHeight={lineHeight}
        condensedZoomLevel={condensedZoomLevel}
        canCreateWorkPlan={canCreateWorkPlan}
        timeEntryTotals={timeEntryTotals}
        showTimeEntryTotals={showTimeEntryTotals}
        projectTotals={projectTotals}
        isPersonalPlanner={isPersonalPlanner}
        heatmap={heatmap}
        memberHash={memberHash}
        utilizationsBreakdown={utilizationsBreakdown}
        summaryUtilizationsBreakdown={summaryUtilizationsBreakdown}
      />
      {hasDroppableLayer && (
        <DroppableLayer
          getLayerRootProps={getLayerRootProps}
          formattingUtils={formattingUtils}
          steps={steps}
          columnWidths={columnWidths}
          viewBy={workloadViewBy}
          group={group}
          deserializedGroupId={deserializedGroupId}
        />
      )}
    </>
  );
};

export default WorkloadPlannerRowRendererWrapper;

const renderTasksButton = (workloadViewType, condensedZoomLevel) => {
  return condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL &&
    workloadViewType === VIEW_TYPE.CONDENSED ? (
    <></>
  ) : (
    <StyledButtonContainer>
      <StyledSmallHamburger /> Tasks
    </StyledButtonContainer>
  );
};

const UnmemoizedHoverBoxes = ({
  numBoxes,
  isWeekend,
  isHoliday,
  isFullPTO,
  isProjectView,
  isWorkloadSplitRow,
  committedFormat,
  testIDPrefix,
  lineHeight,
  isDisabled,
  isBottom,
  isPersonalPlanner,
  isLoading,
  isSummaryLoading,
  hasZeroCapacity
}) => {
  return (
    <>
      {[...Array(numBoxes)].map((e, i) => (
        <div
          key={i}
          className={cn('hidden-boxes', {
            weekend: isWeekend,
            saturday: moment(committedFormat).format('ddd') === 'Sat',
            sunday: moment(committedFormat).format('ddd') === 'Sun',
            holiday: isHoliday,
            isFullPTO: isFullPTO && !hasZeroCapacity,
            isWorkloadSplitRow: isWorkloadSplitRow,
            isProjectView: isProjectView,
            disabled: isDisabled,
            isLoading: isLoading,
            isSummaryLoading: isSummaryLoading
          })}
          style={{ height: `${lineHeight}px` }}
          data-testid={`${testIDPrefix} ${committedFormat}`}
          data-for="app-tooltip"
          data-tip={CREATE_WORK_PLANS_TIP}
          data-effect="solid"
          data-delay-show={1000}
          data-tip-disable={!isDisabled}
        >
          {isHoliday && i === 0 && !isBottom && isPersonalPlanner && (
            <span
              style={{
                color: '#333',
                fontSize: 13,
                fontWeight: 600,
                position: 'absolute',
                marginTop: -10,
                top: '50%',
                left: '50%',
                marginLeft: -12
              }}
              className="holiday-text"
            >
              OFF
            </span>
          )}
        </div>
      ))}
    </>
  );
};
const HoverBoxes = React.memo(UnmemoizedHoverBoxes);
const UnavailableLayer = React.memo(UnmemoizedUnavailableLayer);
function UnmemoizedUnavailableLayer({
  getLayerRootProps,
  steps,
  utilizations = emptyObj,
  isRoot,
  isBottom,
  isOpen,
  isSummary,
  CustomRootRenderer,
  capacity,
  unCappedUtilizationsByMember = emptyObj,
  cappedUtilizationsByMember = emptyObj,
  memberAccountIds = emptyArray,
  isWeek,
  stepValue,
  zoom,
  deserializedGroupId,
  groupId,
  onCanvasClick,
  onRootCanvasClick,
  numBoxes,
  workloadViewBy,
  handleRowSecondClick,
  columnWidths,
  formattingUtils,
  rowActionText,
  isOnTeamSchedules = false,
  setInView = noop,
  index,
  shouldHighlightUpcoming,
  handleCapacityContextMenu,
  useMemberValues,
  displayMemberCapacity,
  showCheckMark,
  isMemberSplitScreenRow,
  handleWFHContextMenu,
  handleTimeEntryContextMenu,
  WFHContextMenuId,
  WFHContextMenuStep,
  isSplitScreenRow,
  isWorkloadSplitRoot,
  isWorkloadSplitRow,
  testIDPrefix,
  phasesLength,
  isPhaseDefault,
  projectTitle,
  checkIsOOO,
  checkIsWFH,
  workloadViewType,
  lineHeight,
  condensedZoomLevel,
  canCreateWorkPlan,
  timeEntryTotals,
  showTimeEntryTotals,
  projectTotals,
  isPersonalPlanner,
  isLoading,
  heatmap,
  memberHash,
  utilizationsBreakdown,
  summaryUtilizationsBreakdown
}) {
  const dispatch = useAppDispatch();

  const { customHeatmapSetting } = useFeatureFlags();
  const reversedHeatmap = heatmap.slice(0).reverse();

  if (isLoading) {
    // Return boxes for skeleton loader
    return (
      <HoverBoxes
        numBoxes={numBoxes}
        isLoading={!isSummary}
        isSummaryLoading={isSummary}
        lineHeight={lineHeight}
        isDisabled
      />
    );
  }

  if (workloadViewBy === VIEW_BY.NONE) {
    return null;
  }

  const isProjectRoot = !!projectTitle;

  return (
    <div
      className={cn('workload-row-renderer', {
        'is-bottom': isBottom
      })}
      // {...getLayerRootProps()}
    >
      <StyledBorder
        isWhite={isRoot}
        isProjectView={workloadViewBy === VIEW_BY.PROJECTS}
      />

      {isRoot &&
        workloadViewBy === VIEW_BY.PROJECTS &&
        isOnTeamSchedules &&
        isOpen && (
          <RowActionButton
            handleClick={handleRowSecondClick}
            projectId={deserializedGroupId}
            isOpen={isOpen}
            text={'Tasks'}
            renderButton={() =>
              renderTasksButton(workloadViewType, condensedZoomLevel)
            }
          />
        )}
      {!isOnTeamSchedules &&
        steps.map((slot, index) => {
          const {
            left,
            isBorderRight,
            isWeekend,
            isHoliday,
            committedFormat,
            containsOrAfterToday
          } = formattingUtils[index];

          const width = columnWidths[index];

          const accountUtilizationBreakdown = capacity?.account_id
            ? utilizationsBreakdown[capacity.account_id] ?? {}
            : {};

          const utilizationsBreakdownToUse = isSummary
            ? summaryUtilizationsBreakdown
            : accountUtilizationBreakdown;

          const summedBreakdown = getSummedUtilizationsBreakdownByZoomStep({
            utilizationsBreakdown: utilizationsBreakdownToUse,
            stepValue,
            date: committedFormat
          });

          const {
            capacity: totalCapacity,
            committed: unCappedCommitted,
            cappedCommitted,
            PTO,
            holiday
          } = summedBreakdown ?? {
            capacity: 0,
            planned: 0,
            committed: 0,
            cappedCommitted: 0,
            PTO: 0,
            holiday: 0,
            cappedOffHours: 0,
            available: 0
          };

          const hasZeroCapacity = totalCapacity === 0;
          const isPartialPTO = PTO > 0 && PTO < totalCapacity;

          // if PTO hours exceed total capacity, assume it's a full PTO
          const isFullPTO = PTO >= totalCapacity;

          const isWFH =
            zoom === ZOOM_LEVELS.WEEK ? checkIsWFH(committedFormat) : false;

          if ((isRoot || isSummary) && !isWorkloadSplitRoot) {
            const committed = useMemberValues
              ? unCappedCommitted
              : // May be unneeded. Further investigation required.
                cappedCommitted;

            const timeEntryHours =
              timeEntryTotals && showTimeEntryTotals
                ? getTimeEntry(committedFormat, stepValue, timeEntryTotals)
                : 0;

            const overcapacityMembers = getOvercapacityMembers({
              unCappedUtilizationsByMember,
              cappedUtilizationsByMember,
              date: committedFormat
            });

            const overCapacityMembersKeys = Object.keys(overcapacityMembers);
            const hasMemberOverCapacity = overCapacityMembersKeys.length > 0;

            const showPlannedHoursBar =
              projectTotals && isRoot
                ? getShowPlannedHoursBar(
                    committedFormat,
                    stepValue,
                    projectTotals
                  )
                : false;

            const actualAvailableCapacity =
              totalCapacity -
              (stepValue === ZOOM_STRINGS.DAY && !isSummary
                ? committed
                : cappedCommitted);

            const isOverCapacity = actualAvailableCapacity < 0;

            const ptoPercentValue =
              totalCapacity > 0 ? ((PTO / totalCapacity) * 100).toFixed(0) : '';

            const percentage = totalCapacity
              ? (isSummary ? cappedCommitted : committed) / totalCapacity
              : (isSummary ? cappedCommitted : committed)
              ? 1.3
              : 0;
            const percentvalue = totalCapacity
              ? (
                  ((isSummary ? cappedCommitted : committed) * 100) /
                  totalCapacity
                ).toFixed(0)
              : '';

            const numberPercentValue = Number(percentvalue ?? 0);

            const summaryBackgroundHeight = clamp(
              numberPercentValue >= 90 && numberPercentValue < 100
                ? 90
                : numberPercentValue,
              0,
              100
            );
            const actualAvailablePercentValue = totalCapacity
              ? ((actualAvailableCapacity * 100) / totalCapacity).toFixed(0)
              : 0;

            const getNewStyles = () => {
              // Check the intervals that have a single value first (for
              // example, 100-100%), then check the other intervals, and
              // finally use the first interval if none match.
              const capacityInterval =
                heatmap.find(
                  (interval) =>
                    interval.min === interval.max &&
                    numberPercentValue === interval.min
                ) ??
                reversedHeatmap.find(
                  (interval) => numberPercentValue > interval.min
                ) ??
                heatmap[0];

              const capacityColor = capacityInterval.style.color;
              const overflow = capacityInterval.style.overflow;
              const overflowSize =
                CONDENSED_VIEW_OVERFLOW_TRIANGLE[
                  condensedZoomLevel || 'NORMAL'
                ] * (overflow === 'some' ? 2 / 3 : 1);

              return {
                fontColor:
                  !useMemberValues ||
                  (isHoliday && numberPercentValue === 0) ||
                  (isWeekend && numberPercentValue === 0 && totalCapacity === 0)
                    ? 'transparent'
                    : '#000',
                background:
                  useMemberValues && isHoliday
                    ? `repeating-linear-gradient(135deg, #ffffff, #ffffff 3px, rgba(205, 205, 205, 0.4), rgba(205, 205, 205, 0.4) 7px)`
                    : isFullPTO && !hasZeroCapacity
                    ? `repeating-linear-gradient(135deg, #ffffff, #ffffff 3px, rgba(148, 182, 232, 0.4), rgba(148, 182, 232, 0.4) 7px)`
                    : useMemberValues && isWeekend
                    ? '#f9f9f9'
                    : !useMemberValues
                    ? 'transparent'
                    : `
                    ${
                      overflow
                        ? `linear-gradient(135deg, #CA6778 ${overflowSize}px, transparent ${overflowSize}px),`
                        : ''
                    }
                    linear-gradient(to top, ${capacityColor} ${summaryBackgroundHeight}%, ${capacityColor}A0 ${summaryBackgroundHeight}%),
                    white
                    `,
                overcapacity: undefined
              };
            };

            // Only want these special backgrounds for the Members view. if we are in members view see which background we need and apply
            const getDeprecatedStyles = () => {
              // if committed and no capacity, show as over
              const showOverCapacity = totalCapacity
                ? useMemberValues &&
                  formatNumWithMaxOneDecimal(committed) -
                    formatNumWithMaxOneDecimal(totalCapacity) >
                    0
                : !!committed;

              let heatIndex =
                percentage > 1.2 ? 'over' : Math.ceil(percentage * 4);
              // Special case handling of 100%
              if (
                (percentvalue === '100' || percentvalue === '') &&
                totalCapacity > 0
              )
                heatIndex = 5;
              if (
                (((isWeekend && totalCapacity === 0) || isHoliday) &&
                  heatIndex === 0) ||
                !useMemberValues
              ) {
                heatIndex = 'noColor';
              }

              const color =
                containsOrAfterToday &&
                shouldHighlightUpcoming &&
                useMemberValues
                  ? CAPACITY_HEAT_MAP[heatIndex]
                  : CAPACITY_GRAY_HEAT_MAP[heatIndex];

              return {
                fontColor: CAPACITY_FONT_COLOR[heatIndex],
                background:
                  isHoliday && useMemberValues
                    ? `repeating-linear-gradient(
                        135deg,
                        #ffffff,
                        #ffffff 3px,
                        rgba(205, 205, 205, 0.4),
                        rgba(205, 205, 205, 0.4) 7px
                      )`
                    : isFullPTO && !hasZeroCapacity
                    ? `repeating-linear-gradient(
                          135deg,
                          #ffffff,
                          #ffffff 3px,
                          rgba(148, 182, 232, 0.4),
                          rgba(148, 182, 232, 0.4) 7px
                        )`
                    : isWeekend && useMemberValues
                    ? '#f9f9f9'
                    : color,
                overcapacity: !showOverCapacity
                  ? undefined
                  : percentage < 1.2
                  ? 'small'
                  : 'large'
              };
            };

            const { fontColor, background, overcapacity } = customHeatmapSetting
              ? getNewStyles()
              : getDeprecatedStyles();

            const numOverCapacityMembers = overCapacityMembersKeys.length;
            const isPluralOverCapacityMembers = numOverCapacityMembers > 1;
            const numOverCapacityMembersWords = format0To9ToWords(
              numOverCapacityMembers
            );

            const hasMemberOverCapacityTooltipContent = `Note: Total Capacity is capped at the <br />
                  maximum capacity for each member. <br />
                  ${capitalize(numOverCapacityMembersWords)}
                  ${isPluralOverCapacityMembers ? ' members' : ' member'} below
                  ${isPluralOverCapacityMembers ? ' are' : ' is'} over their
                  <br />
                  individual capacity.`;

            const summaryCellStyle = {
              position: 'absolute',
              left,
              width,
              height: 'calc(100%)',
              background: isHoliday && useMemberValues ? background : '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: isBorderRight
                ? '1px solid #ccc'
                : '1px solid #e4e4e4'
            };
            const cellStyle = {
              position: 'absolute',
              left,
              width,
              background: background,

              color: fontColor,
              height: 'calc(100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: isBorderRight
                ? `2px solid ${
                    workloadViewBy === VIEW_BY.PROJECTS ? '#dbdbdb' : '#fff'
                  }`
                : `1px solid ${
                    workloadViewBy === VIEW_BY.PROJECTS ? '#dbdbdb' : '#fff'
                  }`,
              borderBottom:
                workloadViewBy === VIEW_BY.PROJECTS ? '1px solid #dbdbdb' : ''
            };
            const ptoHolidayFontStyle =
              workloadViewType === VIEW_TYPE.CONDENSED
                ? {
                    fontSize: '12px'
                  }
                : {};

            const shouldShowCheckMark =
              showCheckMark &&
              formatNumWithMaxOneDecimal(actualAvailableCapacity) === '0' &&
              committed !== 0 &&
              useMemberValues;

            const showValues = {
              [DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS]:
                formatNumWithMaxOneDecimal(committed),
              [DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS]:
                formatNumWithMaxOneDecimal(actualAvailableCapacity),
              [DISPLAY_MEMBER_CAPACITY.PERCENT]: percentvalue
            };
            const showPTOValues = {
              [DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS]:
                formatNumWithMaxOneDecimal(PTO),
              [DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS]:
                formatNumWithMaxOneDecimal(PTO),
              [DISPLAY_MEMBER_CAPACITY.PERCENT]: ptoPercentValue
            };
            const hoverValues = {
              [DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS]: formatNumWithMaxOneDecimal(
                Math.abs(actualAvailableCapacity)
              ),
              [DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS]:
                formatNumWithMaxOneDecimal(committed),
              [DISPLAY_MEMBER_CAPACITY.PERCENT]: Math.abs(
                actualAvailablePercentValue
              )
            };
            const afterShowValueText = {
              [DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS]: 'h',
              [DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS]: 'h',
              [DISPLAY_MEMBER_CAPACITY.PERCENT]: percentvalue ? '%' : ''
            };
            const afterHoverValueText = {
              [DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS]: 'h',
              [DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS]: 'h',
              [DISPLAY_MEMBER_CAPACITY.PERCENT]: percentvalue ? '%' : ''
            };
            const showAvailableTextOnHover = {
              [DISPLAY_MEMBER_CAPACITY.TOTAL_HOURS]: true,
              [DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS]: false,
              [DISPLAY_MEMBER_CAPACITY.PERCENT]: true
            };

            const formattedPTOValue = `${showPTOValues[displayMemberCapacity]}${afterShowValueText[displayMemberCapacity]}`;

            const tooltipContent = getWorkloadCapacityTooltip({
              capacity: totalCapacity,
              planned: committed,
              available: actualAvailableCapacity,
              pto: PTO,
              holiday,
              isWorkingRemotely: isWFH,
              timeEntry: timeEntryHours
            });

            if (isRoot && CustomRootRenderer)
              return (
                <CustomRootRenderer
                  left={left}
                  width={width}
                  committed={committed}
                  isOverCapacity={isOverCapacity}
                  committedFormat={committedFormat}
                  testId={`${testIDPrefix} ${committedFormat}`}
                  key={committedFormat}
                />
              );

            return (
              <div
                key={committedFormat}
                data-testid={`${testIDPrefix} ${committedFormat}`}
                style={isSummary ? summaryCellStyle : cellStyle}
                className="bucket-value"
                onClick={(e) => onRootCanvasClick(groupId, slot, e)}
                onContextMenu={(e) => {
                  useMemberValues
                    ? handleCapacityContextMenu(slot, deserializedGroupId, e)
                    : noop();
                }}
                data-for={
                  workloadViewBy !== VIEW_BY.PROJECTS ? 'app-tooltip' : ''
                }
                data-html
                data-delay-show={1000}
                data-tip={tooltipContent}
                data-effect="solid"
                data-type="light"
              >
                {!!showPlannedHoursBar &&
                  workloadViewType !== VIEW_TYPE.CONDENSED && (
                    <ActiveWorkplanBar
                      originType={'project'}
                      styleId={deserializedGroupId}
                      data-testid={`active-workplan-${deserializedGroupId}`}
                      data-for="app-tooltip"
                      data-html
                      data-tip={workplanBarTooltip}
                      data-effect="solid"
                      onClick={(e) => {
                        dispatch(
                          openWorkloadProjectRow({
                            projectId: deserializedGroupId
                          })
                        );
                        e.stopPropagation();
                      }}
                    />
                  )}
                {isSummary && !isHoliday ? (
                  <SummaryBackground
                    style={{
                      height: `${summaryBackgroundHeight}%`
                    }}
                  />
                ) : (
                  ''
                )}
                {isSummary ? (
                  <SummaryContainer
                    isHidden={committed === 0 && !isHoliday}
                    isWhite={!useMemberValues}
                  >
                    {useMemberValues ? (
                      <SummaryContent>
                        {isHoliday ? (
                          <span
                            style={ptoHolidayFontStyle}
                            className="holiday-text"
                          >
                            OFF
                          </span>
                        ) : (
                          <>
                            {hasMemberOverCapacity && (
                              <SummaryChip
                                $chipSize={
                                  CONDENSED_VIEW_OVERFLOW_TRIANGLE[
                                    condensedZoomLevel || 'NORMAL'
                                  ] *
                                  (2 / 3)
                                }
                                data-for="app-tooltip"
                                data-html
                                data-tip={hasMemberOverCapacityTooltipContent}
                                data-effect="solid"
                              />
                            )}
                            <SummaryCommitted className="hide-on-hover">
                              <span
                                style={{
                                  fontSize:
                                    workloadViewType === VIEW_TYPE.CONDENSED
                                      ? 12
                                      : ''
                                }}
                                className="hours-hover-text"
                              >
                                {percentvalue}
                                {percentvalue ? '%' : ''}
                              </span>
                            </SummaryCommitted>
                            <SummaryCommitted className="show-on-hover">
                              <span
                                style={{
                                  fontSize:
                                    workloadViewType === VIEW_TYPE.CONDENSED
                                      ? 12
                                      : ''
                                }}
                                className="hours-hover-text"
                              >
                                {formatNumWithMaxOneDecimal(
                                  actualAvailableCapacity
                                )}
                                h
                              </span>
                            </SummaryCommitted>
                            <AvailableText className="show-on-hover">
                              Available
                            </AvailableText>
                          </>
                        )}
                      </SummaryContent>
                    ) : (
                      <SummaryContent>
                        <SummaryCommitted
                          isVerySmallCondensedView={
                            workloadViewType === VIEW_TYPE.CONDENSED &&
                            condensedZoomLevel ===
                              CONDENSED_ZOOM_LEVELS.VERY_SMALL
                          }
                          className="hide-on-hover"
                        >
                          {workloadViewBy === VIEW_BY.PROJECTS &&
                          !isSplitScreenRow ? (
                            <>
                              {formatNumWithMaxOneDecimal(committed)}
                              <span className="hours-hover-text">h</span>
                            </>
                          ) : (
                            <>
                              {percentvalue}
                              <span className="hours-hover-text">
                                {percentvalue ? '%' : ''}
                              </span>
                            </>
                          )}
                        </SummaryCommitted>
                        <SummaryCommitted
                          isVerySmallCondensedView={
                            workloadViewType === VIEW_TYPE.CONDENSED &&
                            condensedZoomLevel ===
                              CONDENSED_ZOOM_LEVELS.VERY_SMALL
                          }
                          className="show-on-hover"
                        >
                          {formatNumWithMaxOneDecimal(actualAvailableCapacity)}
                          <span className="hours-hover-text">h</span>
                        </SummaryCommitted>
                        <AvailableText className="show-on-hover">
                          Available
                        </AvailableText>
                      </SummaryContent>
                    )}
                  </SummaryContainer>
                ) : (
                  <>
                    <div>
                      {isFullPTO || isHoliday ? (
                        <OffTextContainer>
                          {isHoliday ? (
                            <span
                              style={ptoHolidayFontStyle}
                              className="holiday-text percent-value show-on-hover"
                            >
                              OFF
                            </span>
                          ) : (
                            !hasZeroCapacity && (
                              <>
                                <span
                                  style={ptoHolidayFontStyle}
                                  className="pto-text"
                                >
                                  PTO
                                </span>
                                <PTOValueText>{formattedPTOValue}</PTOValueText>
                              </>
                            )
                          )}
                        </OffTextContainer>
                      ) : (
                        <>
                          <div className="hour-value">
                            <StyledTimeEntryContainer
                              onClick={(e) =>
                                handleTimeEntryContextMenu(
                                  deserializedGroupId,
                                  e,
                                  slot
                                )
                              }
                              data-testid="workload-time-entry"
                              hideTimeEntry={
                                !timeEntryHours ||
                                workloadViewType === VIEW_TYPE.CONDENSED
                              }
                            >
                              {timeEntryHours &&
                              workloadViewType !== VIEW_TYPE.CONDENSED
                                ? formatNumWithMaxOneDecimal(timeEntryHours)
                                : ''}
                            </StyledTimeEntryContainer>
                            {(isWFH ||
                              (WFHContextMenuId === deserializedGroupId &&
                                WFHContextMenuStep === slot)) &&
                              zoom === ZOOM_LEVELS.WEEK && (
                                <StyledWFHIconContainer
                                  isCondensedView={
                                    workloadViewType === VIEW_TYPE.CONDENSED
                                  }
                                  isSmallCondensedView={
                                    condensedZoomLevel ===
                                    CONDENSED_ZOOM_LEVELS.SMALL
                                  }
                                  isVerySmallCondensedView={
                                    condensedZoomLevel ===
                                    CONDENSED_ZOOM_LEVELS.VERY_SMALL
                                  }
                                >
                                  <WFHIcon
                                    fill={theme.colors.colorCalendarGray}
                                    width={11}
                                    height={12}
                                  />
                                </StyledWFHIconContainer>
                              )}
                            {committed === 0 &&
                            displayMemberCapacity !==
                              DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS ? (
                              ''
                            ) : shouldShowCheckMark ? (
                              <div
                                style={{
                                  marginTop:
                                    condensedZoomLevel ===
                                      CONDENSED_ZOOM_LEVELS.VERY_SMALL &&
                                    isPartialPTO
                                      ? -10
                                      : 0
                                }}
                              >
                                <StyledCheckmarkIcon
                                  fill={theme.colors.colorMediumGray9}
                                />
                              </div>
                            ) : (
                              <>
                                <span
                                  className="hours-hover-text"
                                  style={{
                                    fontSize:
                                      workloadViewType === VIEW_TYPE.CONDENSED
                                        ? 12
                                        : '',
                                    marginTop:
                                      condensedZoomLevel ===
                                        CONDENSED_ZOOM_LEVELS.VERY_SMALL &&
                                      isPartialPTO
                                        ? -5
                                        : 0
                                  }}
                                >
                                  {showValues[displayMemberCapacity]}
                                  {afterShowValueText[displayMemberCapacity]}
                                </span>
                              </>
                            )}
                            {isPartialPTO && (
                              <PTOValueText
                                className="hide-on-hover"
                                condensedZoomLevel={condensedZoomLevel}
                              >
                                {formattedPTOValue} PTO
                              </PTOValueText>
                            )}
                          </div>
                          <div className="percent-value show-on-hover">
                            <StyledTimeEntryContainer
                              onClick={(e) =>
                                handleTimeEntryContextMenu(
                                  deserializedGroupId,
                                  e,
                                  slot
                                )
                              }
                              data-testid="workload-time-entry"
                              hideTimeEntry={
                                !timeEntryHours ||
                                workloadViewType === VIEW_TYPE.CONDENSED
                              }
                            >
                              {timeEntryHours &&
                              workloadViewType !== VIEW_TYPE.CONDENSED
                                ? formatNumWithMaxOneDecimal(timeEntryHours)
                                : ''}
                            </StyledTimeEntryContainer>
                            {totalCapacity > 0 &&
                              !isHoliday &&
                              zoom === ZOOM_LEVELS.WEEK &&
                              workloadViewBy === VIEW_BY.MEMBERS && (
                                <StyledWFHIconContainer
                                  isCondensedView={
                                    workloadViewType === VIEW_TYPE.CONDENSED
                                  }
                                  isSmallCondensedView={
                                    condensedZoomLevel ===
                                    CONDENSED_ZOOM_LEVELS.SMALL
                                  }
                                  isVerySmallCondensedView={
                                    condensedZoomLevel ===
                                    CONDENSED_ZOOM_LEVELS.VERY_SMALL
                                  }
                                  onClick={(e) =>
                                    handleWFHContextMenu(
                                      deserializedGroupId,
                                      e,
                                      slot
                                    )
                                  }
                                >
                                  <WFHIcon
                                    width={11}
                                    height={12}
                                    fill={
                                      isWFH
                                        ? theme.colors.colorCalendarGray
                                        : theme.colors.colorCalendarBlue
                                    }
                                  />
                                </StyledWFHIconContainer>
                              )}

                            <>
                              <span
                                style={{
                                  fontSize:
                                    workloadViewType === VIEW_TYPE.CONDENSED
                                      ? 11
                                      : '',
                                  marginTop:
                                    condensedZoomLevel ===
                                    CONDENSED_ZOOM_LEVELS.VERY_SMALL
                                      ? -2
                                      : 0
                                }}
                                className="hours-hover-text"
                              >
                                {hoverValues[displayMemberCapacity]}
                                {afterHoverValueText[displayMemberCapacity]}
                              </span>
                            </>
                          </div>
                          {overcapacity ? (
                            <OverflowContainer
                              condensedZoomLevel={condensedZoomLevel}
                              size={overcapacity}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                    {!isFullPTO && !isProjectRoot && (
                      <span
                        className="percent-value"
                        style={{
                          position: 'absolute',
                          marginTop:
                            workloadViewType === VIEW_TYPE.CONDENSED &&
                            condensedZoomLevel ===
                              CONDENSED_ZOOM_LEVELS.VERY_SMALL
                              ? 13
                              : 22,
                          fontSize: 10,
                          fontWeight: 'normal',
                          textAlign: 'center',
                          color: theme.colors.colorPureBlack
                        }}
                      >
                        {showAvailableTextOnHover[displayMemberCapacity]
                          ? committed === 0 &&
                            displayMemberCapacity !==
                              DISPLAY_MEMBER_CAPACITY.AVAILABLE_HOURS
                            ? percentvalue
                              ? 'Available'
                              : ''
                            : actualAvailableCapacity < 0
                            ? 'Over'
                            : 'Available'
                          : totalCapacity !== 0
                          ? 'Planned'
                          : ''}
                      </span>
                    )}
                  </>
                )}
              </div>
            );
          } else {
            return (
              <div
                key={committedFormat}
                style={{
                  position: 'absolute',
                  left,
                  width,
                  backgroundColor: '#fff',
                  height: '100%',
                  borderRight: isBorderRight
                    ? '1px solid #ccc'
                    : '1px solid #e4e4e4',
                  overflow: 'hidden'
                }}
                onClick={(e) => onCanvasClick(groupId, slot, e)}
                className="regular-bucket"
              >
                {!isWorkloadSplitRoot && (
                  <HoverBoxes
                    numBoxes={numBoxes}
                    isWeekend={isWeekend}
                    isHoliday={isHoliday}
                    isFullPTO={isFullPTO}
                    isWorkloadSplitRow={isWorkloadSplitRow}
                    isProjectView={VIEW_BY.PROJECTS === workloadViewBy}
                    committedFormat={committedFormat}
                    testIDPrefix={testIDPrefix}
                    lineHeight={lineHeight}
                    isDisabled={!canCreateWorkPlan}
                    isBottom={isBottom}
                    isPersonalPlanner={isPersonalPlanner}
                    hasZeroCapacity={hasZeroCapacity}
                  />
                )}
              </div>
            );
          }
        })}
    </div>
  );
}
