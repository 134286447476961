import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CalendarIcon = ({
  width = '12',
  height = '12',
  className,
  currentColor
}) => (
  <SvgIcon
    className={className}
    title="Calendar Icon"
    width={width}
    height={height}
    strokeWidth={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d="M3.06424 0.796359V0H4.1197V0.796359H7.87977V0H8.93518V0.796359H11.9994L12 11.9999H0V0.796359H3.06424ZM1.03528 11.0369H10.9641V4.45626H1.03528V11.0369ZM7.88024 1.75933V2.35933H8.93566V1.75933H10.9642V3.47493H1.03534V1.75933H3.0643V2.35933H4.11971V1.75933H7.88024Z"
      fill={currentColor}
    />
  </SvgIcon>
);

export default CalendarIcon;
