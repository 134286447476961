import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const MembersContainer = styled.div`
  transform: translateY(-4px);
  margin-left: 30px;
  color: ${theme.colors.colorCalendarGray};
`;

const MilestoneHeaderMembers = () => {
  return <MembersContainer>MEMBERS</MembersContainer>;
};

export default MilestoneHeaderMembers;
