import { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import OrganizationSettingsDropdown from '../shared/OrganizationSettingsDropdown';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import CloseIcon from 'icons/CloseIcon';
import theme from 'theme';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { noop } from 'appUtils';

interface LanguageItem {
  label: string;
}
interface LanguageDropdownProps {
  languages: LanguageItem[];
}

export const LanguageFlyout = ({ languages }: LanguageDropdownProps) => {
  const { MultiStepFlyout, openFlyout, closeFlyout, isOpen } =
    useMultiStepFlyout();

  const languageDropdownRef = useRef(null);

  const handleClick = () => {
    if (isOpen) {
      closeFlyout();
    } else if (languageDropdownRef?.current) {
      openFlyout({ target: languageDropdownRef.current });
    }
  };

  const renderItem = ({ item }: { item: LanguageItem }) => {
    const { label } = item;
    return <ItemContainer>{label}</ItemContainer>;
  };

  const renderHeaderButton = (): ReactNode => {
    return (
      <HeaderButtonContainer onClick={closeFlyout}>
        <StyledCloseIcon height="10px" width="10px" />
      </HeaderButtonContainer>
    );
  };

  const handleFilter = (item: LanguageItem, searchWords: string): ReactNode =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['label']
    });

  const handleSelect = (e, { item }: { item: LanguageItem }) => {
    // Update this when linking with BE in the future
    noop();
    closeFlyout();
  };

  return (
    <OrganizationLanguageDropdownContainer
      ref={languageDropdownRef}
      onClick={handleClick}
    >
      <OrganizationSettingsDropdown label="English" />
      <MultiStepFlyout
        copy={{
          headerInitial: 'Default Language',
          searchPlaceholder: 'Search or Select'
        }}
        items={languages}
        idKey="label"
        renderItem={renderItem}
        hideFooter
        editDisabled
        itemHeight={48}
        isWhite
        searchEnabled
        renderHeaderButton={renderHeaderButton}
        popoverClassName="default-language-dropdown"
        closePopover={closeFlyout}
        handleSelect={handleSelect}
        itemFilter={handleFilter}
        className="language-list-flyout"
      />
    </OrganizationLanguageDropdownContainer>
  );
};

const OrganizationLanguageDropdownContainer = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  padding: 15px 20px;
  border-bottom: 1px solid ${theme.colors.colorPaleGray9};
  width: 100%;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledCloseIcon = styled(CloseIcon)`
  path {
    fill: ${theme.colors.colorPureBlack};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorBudgetBlue};
    }
  }
`;
