import * as constants from 'appConstants';
import * as budgetConstants from 'BudgetModule/constants';
import * as dashboardConstants from 'DashboardModule/constants';
import * as tasks from './tasks';
import * as taskComments from './taskComments';
import * as projects from './projects';
import * as projectComments from './projectComments';
import * as notes from './notes';
import * as milestones from './milestones';
import * as projectMemberships from './projectMemberships';
import * as timeEntries from './timeEntries';
import * as noteConstants from 'NoteModule/constants';
import * as teamMemberships from './teamMemberships';
import * as teamMembershipProfile from './teamMembershipProfile';
import * as rates from './rates';
import * as recurly from './recurly';
import * as memberBudgets from './memberBudgets';
import * as projectScheduleBars from './projectScheduleBars';
import * as phases from './phases';
import * as activities from './activities';
import * as workGroups from './workGroups';
import * as workGroupMemberships from './workGroupMemberships';
import * as dashboards from './dashboards';
import * as accountTeamCapacity from './accountTeamCapacity';
import * as capacityConstants from 'CapacityModule/constants';
import * as phaseMembership from './phaseMembership';
import * as skillMemberships from './skillMemberships';
import { hardDeleteTeamMembers } from 'TeamsModule/actionCreators';

/** Space permissions */

import * as budgetSpace from './V2/budgetSpace';
import * as dashboardSpace from './V2/dashboardSpace';
import * as integrationSpace from './V2/integrationSpace';
import * as organizationSpace from './V2/organizationSpace';
import * as portfolioSpace from './V2/portfolioSpace';
import * as profileSpace from './V2/profileSpace';
import * as projectSpace from './V2/projectSpace';
import * as reportSpace from './V2/reportSpace';
import * as timesheetSpace from './V2/timesheetSpace';
import * as workplanSpace from './V2/workplanSpace';
import * as budgetSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/budget';
import * as dashboardSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/dashboard';
import * as integrationSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/integration';
import * as organizationSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import * as portfolioSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import * as profileSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/profile';
import * as projectSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';
import * as reportSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/report';
import * as timesheetSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/timesheet';
import * as workplanSpaceActionCreators from 'PermissionsModule/SpaceLevelPermissions/actionCreators/workplan';

// Only implementing Update/Delete task/project comments of other users verifications
// for demo purposes.
export const permissionActions = {
  [constants.CREATE_TASK_FROM_HOME.TRIGGER]: tasks.verifyCreate,
  [constants.UPDATE_TASKS_ATTRIBUTES.TRIGGER]: tasks.verifyEdit,
  [constants.TASK_UPDATE_POSITION]: tasks.verifyEdit,
  [constants.HANDLE_HOME_TASK_COMPLETION_TOGGLE]: tasks.verifyEdit,
  [constants.BATCH_DELETE_TASKS.TRIGGER]: tasks.verifyDelete,

  [constants.CREATE_COMMENT.TRIGGER]: taskComments.verifyCreate,

  [constants.PROJECT_CREATION.TRIGGER]: projects.verifyCreate,
  [constants.PROJECT_EDITION.TRIGGER]: projects.verifyEdit,
  [constants.ARCHIVE_UNARCHIVE_PROJECT.TRIGGER]: projects.verifyArchive,
  [constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.TRIGGER]: projects.verifyArchive,
  [constants.DELETE_PROJECT.TRIGGER]: projects.verifyDelete,
  [constants.HARD_DELETE_PROJECT.TRIGGER]: projects.verifyHardDelete,

  [constants.CREATE_PROJECT_COMMENT.TRIGGER]: projectComments.verifyCreate,

  [noteConstants.NOTE_CREATION.TRIGGER]: notes.verifyCreate,
  [noteConstants.NOTE_UPDATE.TRIGGER]: notes.verifyEdit,
  [noteConstants.DELETE_NOTE.TRIGGER]: notes.verifyDelete,

  [constants.ADD_BULK_MEMBERS_TO_PROJECT.TRIGGER]:
    projectMemberships.verifyCreate,

  [constants.UPDATE_PROJECT_MEMBER_ROLE.TRIGGER]: projectMemberships.verifyEdit,
  [constants.DELETE_MEMBER_FROM_PROJECT.TRIGGER]:
    projectMemberships.verifyDelete,

  [constants.CREATE_TIMESHEET.TRIGGER]: timeEntries.verifyCreate,
  [constants.UPDATE_TIMESHEET.TRIGGER]: timeEntries.verifyEdit,
  [constants.DELETE_TIMESHEETS.TRIGGER]: timeEntries.verifyDelete,

  [constants.INVITE_MEMBER.TRIGGER]: teamMemberships.verifyCreate,
  [constants.UPDATE_TEAM_MEMBERSHIP.TRIGGER]: teamMemberships.verifyEdit,

  [constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.TRIGGER]:
    teamMembershipProfile.verifyEditMineOrOthers,
  [hardDeleteTeamMembers.type]: teamMemberships.verifyDelete,

  [budgetConstants.CREATE_RATE.TRIGGER]: rates.verifyCreate,
  [budgetConstants.UPDATE_RATE.TRIGGER]: rates.verifyEdit,
  [budgetConstants.ARCHIVE_RATE.TRIGGER]: rates.verifyEdit,
  [budgetConstants.UNARCHIVE_RATE.TRIGGER]: rates.verifyEdit,

  // [constants.FETCH_RECURLY_BILLING_INFO.TRIGGER]: recurly.verifyFetch,
  // [constants.SET_RECURLY_BILLING_INFO.TRIGGER]: recurly.verifyUpdate,

  [budgetConstants.FETCH_MEMBER_BUDGETS.TRIGGER]:
    memberBudgets.verifyReadBudgetHours,
  [budgetConstants.OPEN_BUDGET_MODAL]: memberBudgets.verifyReadBudgetUI,
  [budgetConstants.BUDGET_ACCESS_CHECK]: memberBudgets.verifyReadBudgetUI,

  [constants.VIEW_WORKLOAD_PLANNER]: projectScheduleBars.verifyReadMineOrOthers,
  [constants.FETCH_WORKLOAD_PLANNER.TRIGGER]: projectScheduleBars.verifyRead,
  [constants.CREATE_WORKLOAD_PLANNER.TRIGGER]: projectScheduleBars.verifyCreate,
  [constants.UPDATE_WORKLOAD_PLANNER.TRIGGER]: projectScheduleBars.verifyEdit,
  [constants.DELETE_WORKLOAD_PLANNER.TRIGGER]: projectScheduleBars.verifyDelete,

  [constants.DELETE_PHASE.TRIGGER]: phases.verifyDelete,
  [constants.HARD_DELETE_PHASE.TRIGGER]: phases.verifyHardDelete,
  [constants.UPDATE_PHASE.TRIGGER]: phases.verifyEdit,
  [constants.PREDICT_WORKDAYS_AND_UPDATE_PHASE.TRIGGER]:
    projectSpace.verifyEditPhaseDates,
  [constants.OPEN_PHASE_TEMPLATE_DROPDOWN]: phases.verifyEdit,
  [budgetConstants.HARD_DELETE_ACTIVITY_PHASE.TRIGGER]: phases.verifyHardDelete,

  [budgetConstants.OPEN_ACTIVITY_DROPDOWN]: activities.verifyEdit,

  [constants.CREATE_WORK_GROUP.TRIGGER]: workGroups.verifyCreate,
  [constants.FETCH_WORK_GROUPS.TRIGGER]: workGroups.verifyRead,
  [constants.CREATE_WORK_GROUP_MEMBERSHIP.TRIGGER]:
    workGroupMemberships.verifyCreate,

  [dashboardConstants.CREATE_DASHBOARD.TRIGGER]:
    dashboards.verifyCreateMineOrOthers,
  [dashboardConstants.DELETE_DASHBOARD.TRIGGER]:
    dashboards.verifyDeleteMineOrOthers,
  [dashboardConstants.UPDATE_DASHBOARD.TRIGGER]:
    dashboards.verifyUpdateMineOrOthers,
  [dashboardConstants.FETCH_DASHBOARDS.TRIGGER]:
    dashboards.verifyFetchMineOrOthers,

  [capacityConstants.UPDATE_CAPACITY.TRIGGER]: accountTeamCapacity.verifyEdit,

  [constants.CREATE_SKILL_MEMBERSHIP.TRIGGER]: skillMemberships.verifyCreate,
  [constants.UPDATE_SKILL_MEMBERSHIP.TRIGGER]: skillMemberships.verifyEdit,
  [constants.DELETE_SKILL_MEMBERSHIP.TRIGGER]: skillMemberships.verifyDelete,

  // * V2 Permissions
  [organizationSpaceActionCreators.inviteMemberToTeam]:
    organizationSpace.verifyCreateTeamMembership,
  [organizationSpaceActionCreators.deleteMemberFromTeam]:
    organizationSpace.verifyDeleteTeamMembership,
  [organizationSpaceActionCreators.createRoles]:
    organizationSpace.verifyCreateRoles,
  [organizationSpaceActionCreators.editRoles]:
    organizationSpace.verifyEditRoles,
  [organizationSpaceActionCreators.deleteRoles]:
    organizationSpace.verifyDeleteRoles,
  [organizationSpaceActionCreators.manageMemberRole]:
    organizationSpace.verifyManageMemberRole,
  [organizationSpaceActionCreators.inviteGuests]:
    organizationSpace.verifyInviteGuests,

  [dashboardSpaceActionCreators.createDashboards]:
    dashboardSpace.verifyCreateDashboards,
  [dashboardSpaceActionCreators.deleteDashboards]:
    dashboardSpace.verifyDeleteDashboards,
  [dashboardSpaceActionCreators.editDashboards]:
    dashboardSpace.verifyEditDashboards,

  [dashboardSpaceActionCreators.createWidgets]:
    dashboardSpace.verifyCreateWidgets,
  [dashboardSpaceActionCreators.editWidgets]: dashboardSpace.verifyEditWidgets,
  [dashboardSpaceActionCreators.deleteWidgets]:
    dashboardSpace.verifyDeleteWidgets,

  [portfolioSpaceActionCreators.createPortfolio]:
    portfolioSpace.verifyCreatePortfolio,
  [portfolioSpaceActionCreators.archivePortfolio]:
    portfolioSpace.verifyArchivePortfolio,
  [portfolioSpaceActionCreators.deletePortfolio]:
    portfolioSpace.verifyDeletePortfolio,
  [portfolioSpaceActionCreators.editPortfolio]:
    portfolioSpace.verifyEditPortfolio,
  [portfolioSpaceActionCreators.makePortfolioPrivate]:
    portfolioSpace.verifyMakePortfolioPrivate,

  [portfolioSpaceActionCreators.createPublicPortfolioMembership]:
    portfolioSpace.verifyCreatePublicPortfolioMembership,
  [portfolioSpaceActionCreators.createPrivatePortfolioMembership]:
    portfolioSpace.verifyCreatePrivatePortfolioMembership,
  [portfolioSpaceActionCreators.deletePublicPortfolioMembership]:
    portfolioSpace.verifyDeletePublicPortfolioMembership,
  [portfolioSpaceActionCreators.deletePrivatePortfolioMembership]:
    portfolioSpace.verifyDeletePrivatePortfolioMembership,
  [portfolioSpaceActionCreators.editPublicPortfolioMembership]:
    portfolioSpace.verifyEditPublicPortfolioMembership,
  [portfolioSpaceActionCreators.editPrivatePortfolioMembership]:
    portfolioSpace.verifyEditPrivatePortfolioMembership,

  [portfolioSpaceActionCreators.createPortolioManager]:
    portfolioSpace.verifyCreatePortfolioManager,
  [portfolioSpaceActionCreators.deletePortfolioProject]:
    portfolioSpace.verifyDeletePortfolioProject,
  [portfolioSpaceActionCreators.archivePortfolioProject]:
    portfolioSpace.verifyArchivePortfolioProject,

  [reportSpaceActionCreators.accessBudgetReport]:
    reportSpace.verifyAccessBudgetReport,
  [reportSpaceActionCreators.accessWorkloadReport]:
    reportSpace.verifyAccessWorkloadReport,
  [reportSpaceActionCreators.accessTimesheetReport]:
    reportSpace.verifyAccessTimesheetReport,
  [reportSpaceActionCreators.accessUtilizationReport]:
    reportSpace.verifyAccessUtilizationReport,
  [reportSpaceActionCreators.accessProfitReport]:
    reportSpace.verifyAccessProfitReport,
  [reportSpaceActionCreators.accessTimeVarianceReport]:
    reportSpace.verifyAccessTimeVarianceReport,
  [reportSpaceActionCreators.accessTimesheetStatusReport]:
    reportSpace.verifyAccessTimesheetStatusReport,
  [reportSpaceActionCreators.accessDemandReport]:
    reportSpace.verifyAccessDemandReport,
  [reportSpaceActionCreators.accessWorkloadForecastReport]:
    reportSpace.verifyAccessWorkloadForecastReport,
  [reportSpaceActionCreators.accessScheduleVarianceReport]:
    reportSpace.verifyAccessScheduleVarianceReport,
  [reportSpaceActionCreators.accessSkillsReport]:
    reportSpace.verifyAccessSkillsReport,
  [reportSpaceActionCreators.accessOrgChartReport]:
    reportSpace.verifyAccessOrgChartReport,
  [reportSpaceActionCreators.accessBudgetVarianceReport]:
    reportSpace.verifyAccessBudgetVarianceReport,
  [reportSpaceActionCreators.accessCheckInsReport]:
    reportSpace.verifyAccessCheckInsReport,
  [reportSpaceActionCreators.accessPlannedTimeReport]:
    reportSpace.verifyAccessPlannedTimeReport,
  [reportSpaceActionCreators.accessSpentTimeReport]:
    reportSpace.verifyAccessSpentTimeReport,
  [reportSpaceActionCreators.accessTimeProjectionReport]:
    reportSpace.verifyAccessTimeProjectionReport,
  [reportSpaceActionCreators.accessTimesheetApprovalReport]:
    reportSpace.verifyAccessTimesheetApprovalReport,
  [reportSpaceActionCreators.accessProjectsReport]:
    reportSpace.verifyAccessProjectsReport,
  [reportSpaceActionCreators.exportReportData]:
    reportSpace.verifyExportReportData,
  [profileSpaceActionCreators.editProfile]: profileSpace.verifyEditProfile,

  [timesheetSpaceActionCreators.readTimesheetEntries]:
    timesheetSpace.verifyReadTimesheetEntries,
  [timesheetSpaceActionCreators.editTimesheetEntries]:
    timesheetSpace.verifyEditTimesheetEntries,
  [timesheetSpaceActionCreators.approveTimesheetEntries]:
    timesheetSpace.verifyApproveTimesheetEntries,

  [projectSpaceActionCreators.createProjectManagers]:
    projectSpace.verifyCreateProjectManagers,
  [projectSpaceActionCreators.archiveProjects]:
    projectSpace.verifyArchiveProjects,
  [projectSpaceActionCreators.inviteGuestsToProjects]:
    projectSpace.verifyInviteGuestsToProjects,
  [projectSpaceActionCreators.createProjectMemberships]:
    projectSpace.verifyCreateProjectMemberships,
  [projectSpaceActionCreators.editProjectMemberships]:
    projectSpace.verifyEditProjectMemberships,
  [projectSpaceActionCreators.deleteProjectMemberships]:
    projectSpace.verifyDeleteProjectMemberships,
  [projectSpaceActionCreators.editProjectComments]:
    projectSpace.verifyEditProjectComments,
  [projectSpaceActionCreators.editTaskComments]:
    projectSpace.verifyEditTaskComments,
  [projectSpaceActionCreators.editWorkPlanComments]:
    projectSpace.verifyEditWorkPlanComments,
  [projectSpaceActionCreators.deleteProjectComments]:
    projectSpace.verifyDeleteProjectComments,
  [projectSpaceActionCreators.deleteTaskComments]:
    projectSpace.verifyDeleteTaskComments,
  [projectSpaceActionCreators.deleteWorkPlanComments]:
    projectSpace.verifyDeleteWorkPlanComments,
  [projectSpaceActionCreators.deleteTasks]: projectSpace.verifyDeleteTasks,
  [projectSpaceActionCreators.editProjectViews]:
    projectSpace.verifyEditProjectViews,
  [projectSpaceActionCreators.editProjectColors]:
    projectSpace.verifyEditProjectColors,
  [projectSpaceActionCreators.editProjectDates]:
    projectSpace.verifyEditProjectDates,
  [projectSpaceActionCreators.readBudgetCostRate]:
    projectSpace.verifyReadBudgetCostRate,
  [projectSpaceActionCreators.editPhaseDates]:
    projectSpace.verifyEditPhaseDates,

  [workplanSpaceActionCreators.editWorkPlans]:
    workplanSpace.verifyEditWorkPlans,
  [workplanSpaceActionCreators.editPersonalWorkPlans]:
    workplanSpace.verifyPersonalEditWorkPlans
};

const checkActionForPermissionsRequirement = (action) =>
  !!permissionActions[action.type];

export default checkActionForPermissionsRequirement;
