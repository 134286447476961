import { createContext } from 'react';
import noop from 'lodash/noop';
import { FindPeopleModalContextValuesType } from './types';

export const FindPeopleModalContext =
  createContext<FindPeopleModalContextValuesType>({
    project: undefined,
    selectedProjectId: null,
    selectedUnassignedMemberBudgetId: null,
    numOfProjectUnassignedRoles: 0,
    activeProjectPhasesOrder: [],
    allProjectPhasesOrder: [],
    isFetchingMemberSuggestionForPhase: false,
    showDemoSuggestions: false,
    phasesHash: {},
    isRequirementsSelectorOpen: false,
    isFetchingEntityRequirements: false,
    isFetchingPhaseTotals: false,
    teamId: undefined,
    teamSlug: undefined,
    requirementAssociationsByProjectMembershipIdHash: {},
    selectedUnassignedMemberBudget: undefined,
    activePhasesNum: 0,
    disableChangingProject: false,
    toggleRequirementsSelector: noop,
    handleSelectUnassignedRole: noop,
    handleSelectUnassignedRoleFromDropdown: noop,
    resetUnassignedRoleAndSelectedPhaseIds: noop,
    triggerFetchMembersSuggestion: noop,
    onDeleteRequirement: noop,
    onAddRequirementsClick: noop,
    openBudgetModal: noop,
    isProjectSelectorOpen: false,
    toggleIsProjectSelectorOpen: noop,
    openAddRemoveUnassignedRoleFromPhasesDropdown: noop,
    toggleModal: noop,
    toggleAvailabilityView: noop,
    isAvailabilityViewActive: false,
    selectedAvailabilityViewTriggerSource: undefined
  });
