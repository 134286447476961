import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { WorkSpace } from 'IntegrationsModule/types/workspace';

type FetchWorkspacesInitialPayload = ActionParamsWithMeta<{
  apiKey: string;
  endpointPath: string;
}>;

type FetchWorkspacesResponse = WorkSpace[];

const FETCH_WORKSPACES = createRequestTypes('FETCH_WORKSPACES');

const fetchWorkspaces = createAction(
  FETCH_WORKSPACES.TRIGGER,
  ({ meta, ...payload }: FetchWorkspacesInitialPayload) => ({ meta, payload })
);

const fetchWorkspacesActionCreatorsMap = createActionCreatorsMap<
  FetchWorkspacesInitialPayload,
  FetchWorkspacesResponse
>(FETCH_WORKSPACES);

/* -----------------------------------Export--------------------------------------- */

export const workspaceActions = {
  fetchWorkspaces
};

export const workspaceActionsCreatorsMap = {
  fetchWorkspaces: fetchWorkspacesActionCreatorsMap
};

export const WORKSPACE_ACTIONS_CONST = {
  FETCH_WORKSPACES
};
