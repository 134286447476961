import React from 'react';

import { areEqual } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';
import { StyledTableRow } from './styles';

// key provided by getCellProps
/* eslint-disable react/jsx-key */

const TableRow = React.memo(({ index, style, data: props }) => {
  const { prepareRow, rows } = props;
  const row = rows[index];
  prepareRow(row);
  const rowProps = row.getRowProps({ style });
  const rowStyle = rowProps.style;
  return (
    <Draggable
      index={index}
      draggableId={`${row.values.firstName}-${row.values.lastName}`} // demo draggableId - requires actual implementation
      isDragDisabled
    >
      {(draggableProvided) => (
        <StyledTableRow
          {...rowProps}
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          className="tr"
          style={{
            ...rowStyle,
            ...draggableProvided.draggableProps.style,
            margin: 0
          }}
        >
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps()}
                className={`td table-cell ${cell.column.id}`}
              >
                {cell.render('Cell')}
              </div>
            );
          })}
        </StyledTableRow>
      )}
    </Draggable>
  );
}, areEqual);

export default TableRow;
