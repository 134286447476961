import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Modal } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import {
  updateTeamMembership,
  archiveTeamMembers,
  unarchiveTeamMembers
} from 'TeamsModule/actionCreators';
import {
  getSelectedTeamId,
  getTeamMembersHashByTeamMembership
} from 'TeamsModule/selectors';
import theme from 'theme';
import { Space } from 'components/Space';
import { TextButtonWithBorder } from 'components/styles';
import {
  MemberEditFormFields,
  MemberEditFormFieldsProps
} from './MemberEditFormFields';
import { ArchiveButton } from 'BudgetModule/components/styles';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import { permissionsUtils } from 'PermissionsModule/utils';
import { isValidEmail } from 'appUtils/formatUtilsTyped';

const toggleArchiveStatusId = 'EditMemberMetadataModal_toggleArchiveStatus';

interface EditMemberMetadataModalProps {
  teamMembershipId: number;
  onToggle: () => void;
}

export const EditMemberMetadataModal = ({
  teamMembershipId,
  onToggle
}: EditMemberMetadataModalProps) => {
  const teamMembersHashByTeamMembership = useAppSelector(
    getTeamMembersHashByTeamMembership
  );

  const teamMember = teamMembersHashByTeamMembership[teamMembershipId];
  const [email, setEmail] = useState(teamMember?.account.email ?? '');
  const [firstName, setFirstName] = useState(
    teamMember?.account.first_name ?? ''
  );
  const [lastName, setLastName] = useState(teamMember?.account.last_name ?? '');
  const [referenceNumber, setReferenceNumber] = useState(
    teamMember?.employee_number ?? ''
  );
  const [employmentType, setEmploymentType] = useState(
    teamMember?.employment_type
  );

  const [teamRole, setTeamRole] = useState(teamMember?.role_ids[0]);

  const dispatch = useAppDispatch();

  const { newPermissionSettings } = useFeatureFlags();

  const { status: updateTeamMembershipRequestStatus } = useRequestStatus({
    requestStatusId: updateTeamMembership.type
  });

  const { status: toggleArchiveStatusRequestStatus } = useRequestStatus({
    requestStatusId: toggleArchiveStatusId
  });

  const teamId = useAppSelector(getSelectedTeamId);

  const isEmailChanged = teamMember?.account.email !== email;
  const isFirstNameChanged = teamMember?.account.first_name !== firstName;
  const isLastNameChanged = teamMember?.account.last_name !== lastName;

  const isReferenceNumberChanged = newPermissionSettings
    ? teamMember?.employee_number !== referenceNumber
    : false;

  const isEmploymentTypeChanged = newPermissionSettings
    ? teamMember?.employment_type !== employmentType
    : false;

  const isTeamRoleChanged = newPermissionSettings
    ? teamMember?.role_ids[0] !== teamRole
    : false;

  const hasChangedValues =
    isEmailChanged ||
    isFirstNameChanged ||
    isLastNameChanged ||
    isReferenceNumberChanged ||
    isEmploymentTypeChanged ||
    isTeamRoleChanged;

  const inputValidations = useMemo(() => {
    return {
      email: { isValid: email.trim().length > 0 },
      firstName: { isValid: firstName.trim().length > 0 },
      lastName: { isValid: lastName.trim().length > 0 },
      referenceNumber: {
        isValid: newPermissionSettings
          ? true
          : referenceNumber.trim().length > 0
      }
    };
  }, [email, firstName, lastName, newPermissionSettings, referenceNumber]);

  const isValid = useMemo(
    () => Object.values(inputValidations).every(({ isValid }) => isValid),
    [inputValidations]
  );

  const handleSubmit = () => {
    if (!isValid) {
      return;
    }

    if (teamId) {
      dispatch(
        updateTeamMembership({
          teamMembershipId,
          email: isEmailChanged ? email : undefined,
          firstName: isFirstNameChanged ? firstName : undefined,
          lastName: isLastNameChanged ? lastName : undefined,
          referenceNumber: isReferenceNumberChanged
            ? referenceNumber
            : undefined,
          employmentType: isEmploymentTypeChanged ? employmentType : undefined,
          teamRole: isTeamRoleChanged ? teamRole : undefined,
          meta: {
            requestStatusId: updateTeamMembership.type
          }
        })
      );
    }
  };

  const isInteractionDisabled = updateTeamMembershipRequestStatus?.isExecuting;

  useEffect(() => {
    if (
      updateTeamMembershipRequestStatus?.isSuccess ||
      toggleArchiveStatusRequestStatus?.isSuccess
    ) {
      onToggle();
    }
  }, [
    onToggle,
    toggleArchiveStatusRequestStatus?.isSuccess,
    updateTeamMembershipRequestStatus?.isSuccess
  ]);

  const employmentTypeOptions: MemberEditFormFieldsProps['employmentTypeOptions'] =
    useMemo(() => {
      return {
        [EMPLOYMENT_TYPES.member]: { isAvailable: true },
        [EMPLOYMENT_TYPES.internalContractor]: { isAvailable: true },
        [EMPLOYMENT_TYPES.externalProjectContractor]: {
          isAvailable: true
        },
        [EMPLOYMENT_TYPES.projectGuest]: { isAvailable: false }
      };
    }, []);

  const isMemberArchived = teamMember
    ? permissionsUtils.getIsArchived(teamMember)
    : false;

  const handleToggleMemberArchive = useCallback(() => {
    if (teamId && teamMember) {
      const actionCreatorFunction = isMemberArchived
        ? unarchiveTeamMembers
        : archiveTeamMembers;

      dispatch(
        actionCreatorFunction({
          teamId,
          teamMembershipIds: [teamMember.id],
          meta: { requestStatusId: toggleArchiveStatusId }
        })
      );
    }
  }, [dispatch, isMemberArchived, teamId, teamMember]);

  const canSubmit = useMemo(() => {
    return hasChangedValues && isValidEmail({ email });
  }, [email, hasChangedValues]);

  if (!teamMember) return <></>;

  return (
    <StyledModal isOpen toggle={onToggle}>
      <Header>Modify Access & Membership</Header>
      <Body>
        {teamRole && employmentType && (
          <MemberEditFormFields
            formFields={{
              email,
              firstName,
              lastName,
              referenceNumber,
              employmentType,
              teamRole
            }}
            employmentTypeOptions={employmentTypeOptions}
            isDisabled={isInteractionDisabled}
            onChange={({ event, field }) => {
              if (field === 'email') {
                setEmail(event.target.value);
              } else if (field === 'firstName') {
                setFirstName(event.target.value);
              } else if (field === 'lastName') {
                setLastName(event.target.value);
              } else if (field === 'referenceNumber') {
                setReferenceNumber(event.target.value);
              }
            }}
            onChangeEmploymentType={setEmploymentType}
            onChangeTeamRole={setTeamRole}
          />
        )}

        <Space value={40} vertical />
        <Footer>
          <ArchiveButton
            style={{ marginRight: 'auto' }}
            onClick={handleToggleMemberArchive}
          >
            {isMemberArchived ? 'Reactivate' : 'Archive'}
          </ArchiveButton>
          <ButtonContainer>
            <TextButtonWithBorder
              isDisabled={isInteractionDisabled}
              onClick={onToggle}
            >
              Cancel
            </TextButtonWithBorder>
            <TextButtonWithBorder
              backgroundColor={theme.colors.colorRoyalBlue}
              color="white"
              isDisabled={isInteractionDisabled}
              onClick={canSubmit ? handleSubmit : onToggle}
            >
              {canSubmit ? 'Save' : 'Done'}
            </TextButtonWithBorder>
          </ButtonContainer>
        </Footer>
      </Body>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 500px;
  .modal-content {
    background-color: ${theme.colors.colorLightGray19};
    padding: 40px 48px;
  }
`;

const Header = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorPureBlack};
  font-size: 22px;
  margin-bottom: 30px;
`;

const Body = styled.div``;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  min-width: fit-content;
  * {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
