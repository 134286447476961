import theme from 'theme';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OptionsMenuValueProps, OptionsMenuSetterProps } from '../types';
import OptionsMenuBody from './OptionsMenuBody';
import TriangleIcon from 'icons/TriangleIcon';

type OptionsMenuProps = {
  optionsMenuValueProps: OptionsMenuValueProps;
  optionsMenuSetterProps: OptionsMenuSetterProps;
  initialIsOpen: boolean;
  handleSetIsOpen: (value: boolean) => void;
};

const OptionsMenu = ({
  optionsMenuValueProps,
  optionsMenuSetterProps,
  initialIsOpen,
  handleSetIsOpen
}: OptionsMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(initialIsOpen);
  }, [initialIsOpen]);

  const handleClick = () => {
    handleSetIsOpen(!isOpen);
  };

  return (
    <OptionsMenuContainer>
      <OptionsMenuHeaderContainer onClick={handleClick}>
        <OptionsMenuHeader>More Options</OptionsMenuHeader>

        <TriangleIconContainer isOpen={isOpen}>
          <TriangleIcon
            stroke={theme.colors.colorCalendarBlue}
            fill={theme.colors.colorCalendarBlue}
          />
        </TriangleIconContainer>
      </OptionsMenuHeaderContainer>

      {isOpen && (
        <OptionsMenuBody
          optionsMenuValueProps={optionsMenuValueProps}
          optionsMenuSetterProps={optionsMenuSetterProps}
        />
      )}
    </OptionsMenuContainer>
  );
};

const OptionsMenuContainer = styled.div``;

const OptionsMenuHeaderContainer = styled.div`
  display: flex;
  font-size: 15px;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  padding-left: 15px;
`;

const OptionsMenuHeader = styled.div``;

const TriangleIconContainer = styled.div<{ isOpen: boolean }>`
  margin-left: 3px;
  margin-top: -1px;

  svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : undefined)};
  }
`;

export default OptionsMenu;
