import React from 'react';
import cn from 'classnames';
import bindAll from 'lodash/bindAll';
import getCaretCoordinates from 'textarea-caret';
import { CommentInputContainer, OutsideClickWrapper } from '../..';
import { commentTypes } from 'appConstants/commentConstants';
import { parseCommentsForMemberIds } from 'appUtils/task';

class TaskReplyCommentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentBody: '',
      suggestionListLeft: 0,
      files: []
    };
    bindAll(this, [
      'expandRow',
      'contractRow',
      'handleReplyChange',
      'handleReplySubmit',
      'handleCancel',
      'handleKeyPress',
      'handleReplyBoxBlur',
      'removeFile'
    ]);
  }

  componentDidUpdate(prevProps) {
    const { commentThread, isReplyOpen } = this.props;

    const replyButtonPressed = !prevProps.isReplyOpen && isReplyOpen;
    const hasNoReplies = commentThread.replies && !commentThread.replies.length;
    if (replyButtonPressed && hasNoReplies) {
      document
        .querySelector(
          `#thread-container-${commentThread.id} .comment-input textarea`
        )
        .focus();
      this.expandRow();
    }
  }

  getPermissions = () => {
    const { selectedTeamId, task } = this.props;
    const projectId = task.project_id;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  expandRow() {
    this.setState({ isExpanded: true });
  }

  contractRow() {
    this.setState({ isExpanded: false });
  }

  handleReplyChange(event) {
    this.setState({ commentBody: event.target.value });
  }

  handleReplySubmit(fromEnter) {
    const { me, task, commentThread, createComment, teamMembersHash } =
      this.props;
    const { commentBody, files } = this.state;
    const permissions = this.getPermissions();
    const membersToNotify = parseCommentsForMemberIds({
      commentBody,
      teamMembersHash,
      me
    });
    if (commentBody.length || files.length) {
      createComment({
        taskId: task.id,
        body: commentBody,
        membersToNotify,
        parentId: commentThread.id,
        accountId: me.id,
        files,
        permissions
      });

      this.setState({
        commentBody: '',
        files: []
      });
    }

    if (!fromEnter) {
      this.contractRow();
    }
  }

  handleCancel() {
    const { commentThread, closeReplyInput } = this.props;
    this.setState({ commentBody: '' });
    this.contractRow();

    if (!commentThread.replies.length) {
      closeReplyInput();
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && this.state.commentBody.length) {
      event.target.blur();
      event.preventDefault();
      this.handleReplySubmit(true);
    }

    if (event.key === '@') {
      const commentInput = document.querySelector(
        '.comment-input .input textarea'
      );
      const suggestionListLeft = getCaretCoordinates(
        commentInput,
        commentInput.selectionEnd
      ).left;
      this.setState({ suggestionListLeft });
    }
  }

  handleReplyBoxBlur(e) {
    const { commentThread, closeReplyInput } = this.props;
    const trueBlur = e.target.id !== 'add-reply-input';

    const hasReplies = commentThread.replies && commentThread.replies.length;
    const isInputEmpty = !this.state.commentBody.length;

    if (!hasReplies && isInputEmpty && trueBlur) {
      this.contractRow();
      closeReplyInput();
    } else if (hasReplies && isInputEmpty && trueBlur) {
      this.contractRow();
    }
    e.stopPropagation();
  }

  handleFiles = (files) => {
    this.setState({ files: Array.from(files) });
  };

  removeFile(file) {
    this.setState((prevState) => ({
      files: prevState.files.filter((stateFile) => stateFile !== file)
    }));
  }

  render() {
    const { task } = this.props;
    const { commentBody, isExpanded, suggestionListLeft, files } = this.state;

    return (
      <OutsideClickWrapper handleOutsideClick={this.handleReplyBoxBlur}>
        <div
          className={cn('reply-comment-container', {
            hidden: !this.props.isReplyOpen
          })}
        >
          <CommentInputContainer
            isReplyOpen={isExpanded}
            className="comment-input"
            isExpanded={isExpanded}
            commentType={commentTypes.REPLY}
            commentBody={commentBody}
            suggestionListLeft={suggestionListLeft}
            onClick={this.expandRow}
            onCancel={this.handleCancel}
            onChange={this.handleReplyChange}
            onSubmit={this.handleReplySubmit}
            onKeyPress={this.handleKeyPress}
            unsavedFiles={files}
            handleFiles={this.handleFiles}
            removeFile={this.removeFile}
            projectId={task?.project_id}
          />
        </div>
      </OutsideClickWrapper>
    );
  }
}

export default TaskReplyCommentContainer;
