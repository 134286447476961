import styled from 'styled-components';
import theme from 'theme';

export const ThreeDotsContainer = styled.div`
  position: relative;
`;

export const StyledIconContainer = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  svg {
    path {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const DropdownWrapper = styled.div`
  padding: 10px 0px;
  width: 100%;
`;

export const MenuContainer = styled.div`
  justify-content: center;
  position: absolute;
  left: -16px;
  top: -1px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;

  &:hover {
    background: transparent;
  }
`;

export const ThreeDotMenuListItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  color: #4f4f4f;
  display: flex;
  width: 172px;
  padding: 8px 15px;
  align-items: center;
  justify-content: flex-start;

  .delete-gray {
    display: flex;
  }
  .delete-red {
    display: none;
  }

  :hover {
    background-color: #ececec;
    .delete-gray {
      display: none;
    }
    .delete-red {
      display: flex;
    }
  }
`;
