import React from 'react';
import cn from 'classnames';
import { ACTION_TYPES } from 'appConstants/actionDescription';
import isString from 'lodash/isString';

const TaskActivityBody = ({ displayData, actionType }) => (
  <div
    className={cn('task-activity-body', {
      completed: actionType === ACTION_TYPES.TASK_COMPLETE
    })}
  >
    <div className="task-name">
      {isString(displayData) ? displayData : 'Task has been deleted'}
    </div>
  </div>
);

export default TaskActivityBody;
