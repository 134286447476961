import { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';

const SettingsContainer = lazy(() => import('./SettingsContainer'));

const LoadSettingsContainer = (props) => (
  <DynamicModuleLoader modules={[getSettingsModule(), getBudgetModule()]}>
    <Suspense fallback={<div />}>
      <SettingsContainer {...props} />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadSettingsContainer;
