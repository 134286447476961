import React from 'react';
import SvgIcon from 'components/SvgIcon';

const TaskIcon = ({ height = '15', width = '15', color = '#b3b3b3' }) => {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 15 15">
      <g fill="none" fillRule="evenodd">
        <path
          stroke={color}
          d="M7.5.5c3.867 0 7 3.133 7 7s-3.133 7-7 7-7-3.133-7-7 3.133-7 7-7z"
        />
        <path
          fill={color}
          d="M10.288 5.05L6.15 9.219 4.586 7.653l-.59.59 1.856 1.856c.083.083.196.15.295.15.1 0 .21-.067.292-.146l4.432-4.456-.583-.596z"
        />
      </g>
    </SvgIcon>
  );
};

export default TaskIcon;
