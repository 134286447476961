import {
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  getFlatPhasesHash,
  getHomeTaskObj,
  getOnTaskDetailModal,
  makeGetPhase,
  makeGetProjectById
} from 'selectors';
import { fetchTasksV2 } from 'actions/actionCreators';
import { makeGetPhaseOrder } from '../../redux/selectors/addTask';
import { PLANNER_MODAL } from 'appConstants/taskListTypes';
import { TextButtonWithBorder } from 'components/styles';
import { AddTaskRow } from './AddTaskRow';
import { RemoveIconButton } from '../shared/RemoveIconButton';
import { Task, TaskId } from 'models/task';
import { AddIconButton } from '../shared/AddIconButton';
import { isOverlapPlanTask } from '../../redux/utils';
import { TaskWithPlanOverlap } from '../../redux/types';
import { Phase, PhaseId } from 'ProjectsModule/phases/models/phase';
import xor from 'lodash/xor';
import PhaseDiamondIcon from 'icons/PhaseDiamondIcon';
import theme from 'theme';
import { useTasks } from 'appUtils/hooks/useTasks';
import { AddTaskConfirmModal } from './AddTaskConfirmModal';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import { LazyLoadingList, LazyLoadingListRef } from './LazyLoadingList';
import { AnyAction } from 'redux';
import { EmptyBehavior, GroupState, ListNode } from './LazyLoadingList/utils';
import {
  LazyLoadingContextId,
  makeGetLazyLoadingTasks
} from 'TaskModule/selectors';
import cn from 'classnames';
import { TASK_LIST_ITEM_GAP } from './constants';
import { convertUsaDateToIsoDate } from 'appUtils/dateUtils';
import { Card } from '../shared/Card';
import NumberFormat from 'react-number-format';
import { useWorkPlanTasks } from '../../hooks/useWorkPlanTasks';
import produce from 'immer';
import { Alert } from './styles';
import { useUpdate } from 'react-use';

interface AddTaskStepProps {
  projectId: number;
  phaseId: number;
  activityPhaseId: number;
  activityId: number;
  scheduleStart: string;
  scheduleEnd: string;
  accountId?: number;
  memberBudgetId?: number;
  initialTaskIds?: number[];
  onUpdateTaskIds: (taskIds: number[]) => void;
  onClose: () => void;
  onUpdateTaskHours: ReturnType<typeof useWorkPlanTasks>['onUpdateTaskHours'];
  tasksHours?: ReturnType<typeof useWorkPlanTasks>['tasksHours'];
}

/**
 * A placeholder for the card that allows creating a new task.
 */
interface AddTaskCard {
  addTask: true;
  onAddTask: () => void;
  phaseId: PhaseId;
}
const isAddTaskCard = <T,>(item: AddTaskCard | T): item is AddTaskCard =>
  'addTask' in item;

/**
 * Extends `Task` to be selectable.
 */
interface SelectableTask extends TaskWithPlanOverlap {
  /**
   * Indicates whether the task is the last among the selected tasks.
   */
  isLastSelected?: boolean;
  isSelected?: boolean;
}

type AddTaskListItem = SelectableTask | AddTaskCard;

export const AddTaskStep = ({
  projectId,
  phaseId,
  activityPhaseId,
  activityId,
  scheduleStart,
  scheduleEnd,
  accountId,
  memberBudgetId,
  initialTaskIds = [],
  onUpdateTaskIds,
  onClose,
  onUpdateTaskHours,
  tasksHours
}: AddTaskStepProps) => {
  const dispatch = useAppDispatch();
  const [taskIds, setTaskIds] = useState<Array<number>>(initialTaskIds);
  const { createTaskAndOpenModal, openTaskModal, updateTasks } = useTasks();
  const isDirty = !!xor(taskIds, initialTaskIds).length;
  const assigneeId = [accountId, memberBudgetId].filter(Boolean)[0];

  /* ------------------------------ confirm modal ----------------------------- */
  const [isConfirmMoveModalOpen, setConfirmMoveModalOpen] = useState(false);
  const [taskIdToMove, setTaskIdToMove] = useState<number | undefined>();

  const handleOpenConfirmModal = ({ taskId }: { taskId: number }) => {
    setConfirmMoveModalOpen(true);
    setTaskIdToMove(taskId);
  };

  const handleCloseConfirmModal = useCallback(() => {
    setConfirmMoveModalOpen(false);
  }, []);

  const getProjectById = useMemo(makeGetProjectById, []);
  const getPhaseById = useMemo(() => makeGetPhase(phaseId), [phaseId]);
  const project = useAppSelector((state) =>
    getProjectById(state, { projectId })
  );
  const targetPhase = useAppSelector(getPhaseById);

  /* -------------------------------- handlers -------------------------------- */
  const handleAddTask = (taskId: number) => () => {
    setTaskIds((prev) => [...prev, taskId]);
    rebuildTooltip();
  };
  const handleRemoveTask = (taskId: number) => () => {
    setTaskIds((prev) => prev.filter((id) => id !== taskId));
    setUpdatedEstimatedHours((prev) =>
      produce(prev, (draft) => {
        draft.set(taskId, { value: '', floatValue: null });
      })
    );
    rebuildTooltip();
  };

  const handleClickNewTask = useCallback(
    (phaseId: number) => () => {
      createTaskAndOpenModal({
        projectId,
        phaseId,
        startDate: scheduleStart,
        endDate: scheduleEnd,
        taskListType: PLANNER_MODAL,
        assigneeIds: [accountId, memberBudgetId].filter(Boolean).map(Number)
      });
    },
    [
      accountId,
      createTaskAndOpenModal,
      memberBudgetId,
      projectId,
      scheduleEnd,
      scheduleStart
    ]
  );

  const handleClickTask = useCallback(
    (task: Task) => () => {
      openTaskModal(task);
    },
    [openTaskModal]
  );

  const handleAttemptToAddTask = useCallback(
    (task: Task) => () => {
      if (task.phase_id && phaseId && task.phase_id !== phaseId) {
        handleOpenConfirmModal({
          taskId: task.id
        });
      } else {
        handleAddTask(task.id)();
      }
    },
    [phaseId]
  );

  const handleConfirmToMove = useCallback(() => {
    if (taskIdToMove) {
      // move task
      updateTasks({
        task_ids: [taskIdToMove],
        project_id: projectId,
        phase_id: phaseId,
        activity_phase_id: activityPhaseId,
        activity_id: activityId
      });
      // add task id to state
      handleAddTask(taskIdToMove)();
    }
  }, [
    activityId,
    activityPhaseId,
    phaseId,
    projectId,
    taskIdToMove,
    updateTasks
  ]);

  const handleAdd = () => {
    if (isDirty) onUpdateTaskIds(taskIds);
    if (updatedEstimatedHours.size)
      onUpdateTaskHours(
        new Map(
          Array.from(updatedEstimatedHours).map(([taskId, { floatValue }]) => [
            taskId,
            floatValue
          ])
        )
      );

    onClose();
  };

  /* ---------------------------- supplemental rendering ---------------------------- */
  const renderAddButton = useCallback(
    (task: Task) => (
      <TrailingContainer>
        <AddIconButton
          tooltip="Add to work plan"
          onClick={handleAttemptToAddTask(task)}
        />
      </TrailingContainer>
    ),
    [handleAttemptToAddTask]
  );

  const [updatedEstimatedHours, setUpdatedEstimatedHours] = useState<
    Map<TaskId, { value: string; floatValue: Nullable<number> }>
  >(new Map());
  const update = useUpdate();
  const renderRemoveButton = useCallback(
    (task: Task) => {
      const estimatedHours = updatedEstimatedHours.has(task.id)
        ? updatedEstimatedHours.get(task.id)?.value
        : tasksHours?.get(task.id)?.hours;
      const isTaskHoursExceeded =
        (estimatedHours ?? 0) > (task.estimated_hours ?? 0);

      return (
        <TrailingContainer>
          <RemoveIcon>
            <RemoveIconButton
              tooltip="Remove from work plan"
              onClick={handleRemoveTask(task.id)}
            />
          </RemoveIcon>
          {tasksHours && (
            <NumberFormat
              allowNegative={false}
              customInput={NumberFormatWithIcons}
              {...defaultTooltipProps}
              data-tip={
                !estimatedHours
                  ? 'Enter Time Estimate for Task on this Work Plan.'
                  : isTaskHoursExceeded
                  ? 'Estimated Time exceeds Task Time Estimate.'
                  : undefined
              }
              decimalScale={2}
              onValueChange={({ value, floatValue }, sourceInfo) => {
                if (sourceInfo.source === 'event')
                  setUpdatedEstimatedHours((current) =>
                    produce(current, (draft) => {
                      draft.set(
                        task.id,
                        (floatValue ?? 0) >= 999999
                          ? { value: '999999', floatValue: 999999 }
                          : { value, floatValue: floatValue ?? null }
                      );

                      // The new value may be identical to the old value if it
                      // exceeds the maximum, so we force an update.
                      update();
                    })
                  );
              }}
              placeholder="0"
              suffixIcons={
                <>
                  h<Alert $hide={!isTaskHoursExceeded}>!</Alert>
                </>
              }
              value={estimatedHours}
            />
          )}
        </TrailingContainer>
      );
    },
    [tasksHours, update, updatedEstimatedHours]
  );

  /* ---------------------------- lazy-loaded list ---------------------------- */
  const groupedItemListRef = useRef<LazyLoadingListRef>(null);

  // When the task modal is closed, the properties of the task may have
  // changed and as a result, its position in the task list may change.
  // Since we are unable to determine its new position, force a reloading
  // of the lazily loaded lists.
  //
  // A flag is required to avoid triggering this effect on initial component
  // load.
  const isMount = useRef(true);
  const taskDetailModalOpen = useAppSelector(getOnTaskDetailModal);
  useEffect(() => {
    if (!isMount.current && !taskDetailModalOpen)
      groupedItemListRef.current?.clearItemCache();
    isMount.current = false;
  }, [dispatch, taskDetailModalOpen]);

  // The only ID that should be fetched is the phase ID.
  const handleGetFetchAction = useCallback(
    (groupId: string): AnyAction =>
      fetchTasksV2({
        body: {
          project_ids: [projectId],
          phase_ids: [parseInt(groupId)],
          sort_attributes: [
            {
              attribute: 'date_range',
              direction: 'asc',
              additional_sort_params: {
                start_date: convertUsaDateToIsoDate(scheduleStart),
                end_date: convertUsaDateToIsoDate(scheduleEnd)
              }
            },
            ...(assigneeId
              ? [
                  {
                    attribute: 'assignee_id',
                    direction: 'asc',
                    additional_sort_params: {
                      assignee_id: assigneeId
                    }
                  }
                ]
              : []),
            {
              attribute: 'description',
              direction: 'asc'
            }
          ]
        }
      }),
    [assigneeId, projectId, scheduleEnd, scheduleStart]
  );

  // Get the selected tasks.
  const taskHash = useAppSelector(getHomeTaskObj);
  const selectedTasks = useMemo(
    () =>
      taskIds
        .map((id) => taskHash[id])
        .filter((task): task is Task => Boolean(task))
        .map<SelectableTask>((task, index, arr) => ({
          ...task,
          isLastSelected: index === arr.length - 1,
          isSelected: true,
          isOverlapPlan: isOverlapPlanTask({
            task,
            scheduleStart,
            scheduleEnd
          })
        })),
    [scheduleEnd, scheduleStart, taskHash, taskIds]
  );

  // Create the hierarchy of items.
  const phaseOrder: Array<number> = useAppSelector(
    makeGetPhaseOrder({ isAllPhases: true, projectId })
  );
  const phaseHash: Record<number, Phase> = useAppSelector(getFlatPhasesHash);
  const hierarchy = useMemo(
    () =>
      [
        ...selectedTasks,

        // - Get the valid phases from the hash.
        // - Sort the current phase to the top.
        // - Create the lazy loading group for that phase.
        ...phaseOrder
          .map((phaseId) => phaseHash[phaseId])
          .filter((phase): phase is Phase => Boolean(phase))
          .sort(({ id }) => (id === phaseId ? -1 : 0))
          .map(({ id }, index) => ({
            className: index === 0 ? 'first-group' : 'group',
            emptyBehavior: EmptyBehavior.HideList,
            id: id.toString(),
            initialState:
              id === phaseId ? GroupState.Expanded : GroupState.Collapsed,
            lazyLoadingGroup: true,
            prelist: [
              { addTask: true, onAddTask: handleClickNewTask(id), phaseId: id }
            ]
          }))
      ] as Array<ListNode<AddTaskListItem>>,
    [handleClickNewTask, phaseHash, phaseId, phaseOrder, selectedTasks]
  );

  // Get the items in the groupings to build the list.
  const excludedTaskIds = useMemo(() => new Set(taskIds), [taskIds]);
  /**
   * Filters and then maps the unselected tasks. The start and end dates are
   * used to compute the tasks that fall within a given range. Tasks that are
   * completed or have no description are filtered out.
   */
  const taskFilterMap = useCallback(
    (task: Task) =>
      !task.completed_at && task.description && !excludedTaskIds.has(task.id)
        ? ({
            ...task,
            isOverlapPlan: isOverlapPlanTask({
              task,
              scheduleStart,
              scheduleEnd
            })
          } as TaskWithPlanOverlap)
        : undefined,
    [excludedTaskIds, scheduleEnd, scheduleStart]
  );
  const itemSelector = useCallback(
    (ownProps: { lazyLoadingId: LazyLoadingContextId }) =>
      // Gets a hash of the lazy-loading groups with filtered task lists in the
      // lazy-loading context.
      makeGetLazyLoadingTasks({ ...ownProps, taskFilterMap }),
    [taskFilterMap]
  );

  // The only special item is the card for adding tasks.
  const handleGetItemId = (item: AddTaskListItem): string =>
    isAddTaskCard(item) ? `add-task-${item.phaseId}` : item.id.toString();

  // Creates the group headings.
  const handleRenderGroupHeading = useCallback(
    (groupId: string): ReactNode => {
      const phase = phaseHash[parseInt(groupId)];
      if (!phase) return;

      return phase.is_like_default ? (
        <GroupHeadingTitle>{project?.title}</GroupHeadingTitle>
      ) : (
        <>
          <StyledPhaseDiamondIcon
            width="14"
            height="15"
            strokeColor={theme.colors.colorMediumGray9}
          />
          <GroupHeadingTitle>
            {phase.name}
            {phase.id === phaseId && (
              <SelectedSubtitle>Selected</SelectedSubtitle>
            )}
          </GroupHeadingTitle>
        </>
      );
    },
    [phaseHash, phaseId, project?.title]
  );

  // Renders the specialized items.
  const handleRenderItem = useCallback(
    (item: AddTaskListItem): ReactNode =>
      isAddTaskCard(item) ? (
        <ItemWrapper className="add-task-item add-task">
          <AddButton onClick={item.onAddTask}>+ Add Task</AddButton>
        </ItemWrapper>
      ) : (
        <ItemWrapper
          className={cn('add-task-item', {
            isLastSelected: item.isLastSelected,
            isSelected: item.isSelected
          })}
        >
          <AddTaskRow
            dateWarningTooltip={
              item.phase_id === phaseId && !item.isOverlapPlan
                ? 'Date is out of<br />Work Plan range'
                : undefined
            }
            taskCardClassName={cn('task-card', {
              'first-card': item === selectedTasks[0]
            })}
            task={item}
            onClick={handleClickTask(item)}
            trailing={
              item.isSelected ? renderRemoveButton(item) : renderAddButton(item)
            }
            // TODO: switch this to proper suggestion flag when ready
            isSuggestion={
              !item.isSelected &&
              item.activity_phase_id === activityPhaseId &&
              item.isOverlapPlan
            }
          />
        </ItemWrapper>
      ),
    [
      activityPhaseId,
      handleClickTask,
      phaseId,
      renderAddButton,
      renderRemoveButton,
      selectedTasks
    ]
  );

  useEffect(() => {
    rebuildTooltip();
  }, [updatedEstimatedHours]);

  return (
    <>
      <RootContainer>
        <HeaderContainer>
          <TitleContainer>Add/Remove Tasks</TitleContainer>
          <DoneButton
            onClick={handleAdd}
            backgroundColor={theme.colors.colorRoyalBlue}
            color="white"
          >
            {isDirty && taskIds.length ? 'Add' : 'Done'}
          </DoneButton>
        </HeaderContainer>
        <LazyLoadingList
          getFetchAction={handleGetFetchAction}
          getItemId={handleGetItemId}
          hierarchy={hierarchy}
          itemClassName="add-task-item"
          itemSelector={itemSelector}
          lazyLoaderGap={TASK_LIST_ITEM_GAP}
          ref={groupedItemListRef}
          renderGroupHeading={handleRenderGroupHeading}
          renderItem={handleRenderItem}
        />
      </RootContainer>
      <AddTaskConfirmModal
        isOpen={isConfirmMoveModalOpen}
        toggle={handleCloseConfirmModal}
        onConfirm={handleConfirmToMove}
        targetPhaseName={targetPhase && targetPhase.name}
      />
    </>
  );
};

const AddButton = styled(Card).attrs(({ theme }) => ({
  className: 'isClickable',
  style: { color: theme.colors.colorCalendarBlue }
}))`
  font-weight: 600;
`;

const ItemWrapper = styled.div`
  &.isSelected {
    background-color: ${({ theme }) => theme.colors.colorPureWhite};
  }

  &.add-task-item.isLastSelected {
    padding-bottom: 18px;
  }
`;

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.colors.colorLightGray19};

  .add-task-item {
    padding-bottom: ${TASK_LIST_ITEM_GAP}px;
    padding-left: 40px;
    padding-right: calc(100% - 340px - 40px);

    &.skeleton-loader {
      width: 100%;
    }
  }

  .first-card {
    // Space for the shadow of the card.
    margin-top: 6px;
  }

  .first-group {
    padding-top: 10px;
  }
  .group {
    padding-top: ${25 - TASK_LIST_ITEM_GAP}px;
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.colorPureWhite};
  display: flex;
  justify-content: space-between;
  padding: 33px 42px;
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const TitleContainer = styled.div`
  color: ${({ theme }) => theme.colors.colorPureBlack};
  font-size: 26px;
  font-weight: 600;
`;

const DoneButton = styled(TextButtonWithBorder)`
  height: fit-content;
  font-weight: 600;
`;

const TrailingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 2px;
  width: 45px;
`;

const SelectedSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: normal;
`;

const GroupHeadingTitle = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-size: 15px;
  font-weight: 600;
  word-break: break-word;
`;

const StyledPhaseDiamondIcon = styled(PhaseDiamondIcon)`
  align-self: start;
  flex: none;
  margin: 4px 4px 0 0;
`;

const RemoveIcon = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const NumberFormatWithIcons = (
  props: HTMLAttributes<HTMLDivElement> & {
    suffixIcons?: ReactNode;
    value?: number | string;
  }
) => {
  const { placeholder, suffixIcons, value, ...containerProps } = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <EstimatedHours $isFocused={isFocused} $value={value} {...containerProps}>
      <input
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        placeholder={isFocused ? undefined : placeholder}
        defaultValue={value}
      />
      {suffixIcons}
    </EstimatedHours>
  );
};

const EstimatedHours = styled.div.attrs<{
  $isFocused: boolean;
  $value?: string | number;
}>(({ $isFocused, $value }) => ({
  className: cn({
    focused: $isFocused,
    placeholder:
      $value === undefined || (typeof $value === 'string' && !$value.length)
  })
}))<{ $isFocused: boolean; $value?: string | number }>`
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid ${({ theme }) => theme.colors.colorLightGray6};
  color: ${({ theme }) => theme.colors.colorCalendarBlue};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  line-height: 1; // Matches the bottom row of the task card
  padding: 4px; // Matches the bottom padding of the task card
  width: 100%;
  text-align: right;

  & > input {
    border: none;
    flex: 1;
    padding: 0;
    min-width: 0;
  }

  & > input,
  & > input::placeholder {
    color: inherit;
    font-weight: inherit;
    text-align: inherit;
  }

  &.placeholder:not(.focused):not(:hover) {
    color: ${({ theme }) => theme.colors.colorLightGray6};
  }

  &:hover,
  &.focused {
    border-color: ${({ theme }) => theme.colors.colorCalendarBlue};
    color: ${({ theme }) => theme.colors.colorCalendarBlue};
  }
`;
