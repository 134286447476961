/**
 * Used in a filter's name for separating level names.
 * This should not be changed as it is used for finding filters
 */
export const FILTER_SCHEMA_NAME_DELIMITER = ' -> ';

/**
 * Used in a filter's name for specifying a general filter level.
 * eg. 'Workload Planner -> _', where filter level 2 = viewBy, means the filter applies
 * to all views of the Workload Planner page. Should not be changed as it is used for finding filters.
 */
export const UNSPECIFIED_FILTER_LEVEL = '___';

/**
 * For a given page, the current filter can be made up of merged filter levels.
 * eg. on the member view (grouped by accounts) of Time Variance report, there could be 4 filter levels:
 *     'Time Variance' - the page level filter (type 1)
 *     'Time Variance -> members' - the view level filter (type 12)
 *     'Time Variance -> members -> accounts' - the groupBy level filter specifically for member view (type 123)
 *     'Time Variance -> ___ -> accounts' - the groupBy level filter, but for any view (type 1_3)
 *                                          ie. its values also apply to project view grouped by accounts
 * These types are useful for checking that the correct filters are used for merging, since at most 1
 * of each type can be used. eg. only one of 'Time Variance -> members' and 'Time Variance -> projects'
 * can be used as type 12 for merging
 */
export const FILTER_LEVEL_TYPES = {
  1: '1',
  12: '12',
  123: '123',
  1234: '1234',
  '1__4': '1__4',
  '1_34': '1_34',
  '12_4': '12_4',
  '1_3': '1_3'
} as const;
