import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import { withRouter } from 'react-router-dom';
import {
  getSelectedGroup,
  getMe,
  getSearchText,
  getShowResultsPane,
  getMatchedRouteParams
} from 'selectors';
import { connect } from 'react-redux';
import {
  fetchBoardMembers,
  openTeamMemberModal,
  fetchProjectsByGroup,
  openAddEditGroupModal,
  filterByMembers,
  fetchSelectedBoard,
  fetchCachedProjects,
  openAddMembersForm,
  handleProjectItemState
} from 'actionCreators';
import { BoardMembers, AddMembersContainer } from '..';
import Popover from 'components/Popover';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
const emptyObj = {};

class BoardMembersContainer extends Component {
  constructor(props) {
    super(props);
    bindAll(this, ['editGroupOnClick', 'updateSelectedAccountIds']);
    this.state = {
      isOpenAddMembersModal: false
    };
    this.addMembersTarget = React.createRef();
  }

  componentDidMount() {
    const {
      fetchSelectedBoard,
      handleProjectItemState,
      matchedParams: { boardId }
    } = this.props;
    boardId && fetchSelectedBoard(boardId);

    setTimeout(() => {
      handleProjectItemState(
        null,
        ['fixed', 'contracting', 'expanded'],
        [false, false, false]
      );
    }, 500);
  }

  updateSelectedAccountIds(value) {
    const {
      projects,
      groups,
      searchText,
      fetchProjectsByGroup,
      filterByMembers,
      fetchCachedProjects,
      matchedParams: { viewType }
    } = this.props;
    const isStatusView = viewType === 'status';

    const accountIds = [...projects.selectedAccountIds];
    const index = accountIds.indexOf(value);
    if (index > -1) {
      accountIds.splice(index, 1);
    } else {
      accountIds.push(value);
    }

    if (accountIds.length > 0) {
      filterByMembers(false, accountIds);

      if (isStatusView) {
        fetchProjectsByGroup({
          groupId: groups.selectedGroupID,
          searchText: searchText,
          accountIds,
          isActive: projects.isActive
        });
      }
    } else {
      filterByMembers(false, accountIds);
      if (searchText) {
        fetchProjectsByGroup({
          groupId: groups.selectedGroupID,
          searchText: searchText,
          accountIds: [],
          isActive: projects.isActive
        });
      } else {
        fetchCachedProjects();
      }
    }
  }

  editGroupOnClick() {
    const { groups, fetchBoardMembers, openAddEditGroupModal } = this.props;
    fetchBoardMembers(groups.selectedGroupID || groups.selectedBoard.id);
    openAddEditGroupModal({ isEdit: true, id: groups.selectedGroupID });
  }

  openAddMembersForm = (e, formLocation) => {
    const { openAddMembersForm, selectedBoard } = this.props;
    openAddMembersForm(MODAL_TYPE.BOARD, selectedBoard, formLocation);
  };

  openAddMembersFromIcon = (e, formLocation) => {
    const { openAddMembersForm, selectedBoard } = this.props;
    this.setState({
      isOpenAddMembersModal: true
    });
    openAddMembersForm(MODAL_TYPE.BOARD, selectedBoard, formLocation);
  };

  closeMembersModal = () => {
    this.setState({
      isOpenAddMembersModal: false
    });
  };

  togglePopover = () => {
    this.setState({
      isOpenAddMembersModal: !this.state.isOpenAddMembersModal
    });
  };

  render() {
    const {
      groups,
      me,
      projects,
      selectedBoard,
      memberList,
      openTeamMemberModal,
      exportProjectsListView
    } = this.props;

    return (
      <div ref={this.addMembersTarget}>
        <BoardMembers
          projects={projects}
          memberList={groups.memberList}
          updateSelectedAccountIds={this.updateSelectedAccountIds}
          selectedAccountIds={projects.selectedAccountIds}
          openTeamMemberModal={openTeamMemberModal}
          selectedBoardName={selectedBoard ? selectedBoard.name : ''}
          editGroupOnClick={this.editGroupOnClick}
          openAddMembersForm={this.openAddMembersForm}
          openAddMembersFromIcon={this.openAddMembersFromIcon}
          me={me}
          teamMembers={memberList}
          selectedBoard={groups.selectedBoard || emptyObj}
          exportProjectsListView={exportProjectsListView}
          data-testid="portfolio-selector"
        />
        <AddMembersContainer
          isOpenMembersModal={this.state.isOpenAddMembersModal}
          closeMembersModal={this.closeMembersModal}
          modalType={MODAL_TYPE.BOARD}
        />
      </div>
    );
  }
}

BoardMembersContainer.propTypes = {
  openTeamMemberModal: PropTypes.func.isRequired,
  fetchBoardMembers: PropTypes.func.isRequired,
  fetchProjectsByGroup: PropTypes.func.isRequired,
  openAddEditGroupModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBoard: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  fetchSelectedBoard: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  me: getMe(state),
  groups: state.groups,
  projects: state.projects,
  selectedBoard: getSelectedGroup(state),
  searchText: getSearchText(state),
  showResults: getShowResultsPane(state),
  matchedParams: getMatchedRouteParams(state)
});

const mapDispatchToProps = {
  fetchBoardMembers,
  openTeamMemberModal,
  fetchProjectsByGroup,
  openAddEditGroupModal,
  filterByMembers,
  fetchSelectedBoard,
  fetchCachedProjects,
  openAddMembersForm,
  handleProjectItemState
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardMembersContainer)
);
