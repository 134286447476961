import React from 'react';
import styled from 'styled-components';
import { IssueIconContainer } from 'BudgetModule/components/styles';
import theme from 'theme';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

const RootCell = ({
  left,
  width,
  committed,
  testId,
  isOverCapacity = false
}) => {
  return (
    <StyledRoot
      left={left}
      width={width}
      data-testid={testId}
      data-for="app-tooltip"
      data-effect="solid"
      data-tip={isOverCapacity ? 'Over Capacity' : ''}
    >
      {formatNumWithMaxTwoDecimals(committed)}h
      {isOverCapacity && <IssueIconContainer> !</IssueIconContainer>}
    </StyledRoot>
  );
};

export default RootCell;

/* ------------------------------------ - ----------------------------------- */

const StyledRoot = styled.div`
  background: ${theme.colors.colorLightGray22};
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
`;
