import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';
import SuggestionsPhaseIcon from 'icons/SuggestionsPhaseIcon';
import PhaseDiamondIcon from 'icons/BudgetPhaseMilestoneIcon';
import moment from 'moment';

const StyledSuggestionsPhaseIcon = styled(SuggestionsPhaseIcon)`
  flex-basis: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1.5px;
`;

const StyledNameContainer = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  margin-left: 4px;
  height: 100%;
`;

const StyledDateContainer = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  position: absolute;
  left: 18px;
  bottom: 0;
`;

const StyledDate = styled.div`
  color: ${theme.colors.colorSemiDarkGray6};
  font-size: 13px;
  min-width: 55px;
`;

const StyledDash = styled.div`
  margin: 0px 8px;
`;

const Name = styled.span`
  padding-left: 5px;
  max-width: 285px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PhaseTitleCell = ({ row }) => {
  const {
    phase: { name, start_date, end_date, is_like_default }
  } = row.original;
  return (
    <StyledTitleContainer>
      <StyledNameContainer>
        <PhaseDiamondIcon
          strokeColor={theme.colors.colorMediumGray9}
          strokeWidth="1.5px"
          width="13"
          height="13"
        />{' '}
        <Name>{is_like_default ? 'Project Schedule' : name} </Name>
      </StyledNameContainer>

      {start_date && end_date && (
        <StyledDateContainer>
          <StyledDate>
            {start_date ? moment(start_date).format('M/D/YY') : ''}{' '}
          </StyledDate>
          <StyledDash> - </StyledDash>
          <StyledDate>
            {end_date ? moment(end_date).format('M/D/YY') : ''}{' '}
          </StyledDate>
        </StyledDateContainer>
      )}
    </StyledTitleContainer>
  );
};

export default PhaseTitleCell;
