import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_TYPES } from 'appConstants/actionDescription';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import withHovered from 'hocs/withHovered';
import moment from 'appUtils/momentConfig';
import { noop } from 'appUtils';
import { getDisplayDataRange } from 'appUtils/workplanDisplayUtils';
import { getMyUserId, makeGetTeamMembershipByAccountId } from 'selectors';
import cn from 'classnames';
import styled from 'styled-components';
import { generateMessage } from './utils';

const getNotificationText = ({
  actionType,
  displayData = {},
  isActivityMemberModal,
  actorId,
  recipient,
  myId
}) => {
  // currently missing cases for project comments which will be added after the Project Comment spec is completed
  switch (actionType) {
    case ACTION_TYPES.TASK_CREATE:
      return <span> created a task in </span>;
    case ACTION_TYPES.TASK_EDIT_DESCRIPTION:
      return <span> edited a task description in </span>; // WIP
    case ACTION_TYPES.TASK_SET_DUE_DATE:
      return <span> set a task due date in </span>; // WIP
    case ACTION_TYPES.TASK_SET_SCHEDULE_DATE:
      return <span> set a task schedule start date in </span>; // WIP
    case ACTION_TYPES.TASK_SET_ESTIMATION:
      return <span> set a task estimation in </span>; // WIP
    case ACTION_TYPES.TASK_ASSIGN:
      return (
        <span>
          {' '}
          {isActivityMemberModal
            ? 'assigned a task in'
            : 'assigned a task to you in'}{' '}
        </span>
      );
    case ACTION_TYPES.TASK_ACCEPT:
      return <span> accepted a task in </span>; // WIP
    case ACTION_TYPES.TASK_CHANGE_PROJECT:
      return <span> changed a task's project in </span>; // WIP
    case ACTION_TYPES.TASK_FOLLOW:
      return <span> followed a task in </span>; // WIP
    case ACTION_TYPES.TASK_UNFOLLOW:
      return <span> unfollowed a task in </span>; // WIP
    case ACTION_TYPES.TASK_COMPLETE:
      return <span> marked a task completed in </span>;
    case ACTION_TYPES.PROJECT_CREATE:
      return <span> created a project </span>; // WIP
    case ACTION_TYPES.PROJECT_ARCHIVE:
      return <span> archived this project </span>;
    case ACTION_TYPES.PROJECT_ADD_MEMBER: {
      // A(Actor) R(Receipient) M(Me), R'name
      // A === R && A === M -> {joined} this project
      // -> (A === R && A === M && R === M  -> {joined} this project)
      // A === R && A !== M -> {joined} this project

      // A !== R && A === M -> {added} {R's username} to this project
      // A !== R && A !== M -> {added} {R's username} to this project
      // A !== R && A !== M && R === M -> {added} {you} to this project

      // fallback
      // added username to this project

      const recipientId = recipient?.account?.id;
      const message = generateMessage('{verb} {whom} {to} this project', {
        verb: recipient && actorId === recipientId ? 'joined' : 'added',
        whom:
          recipientId && actorId !== recipientId && recipientId === myId
            ? 'you'
            : recipient.name,
        to: recipientId && actorId !== recipientId && 'to'
      });
      return <span> {message} </span>;
    }
    case ACTION_TYPES.PROJECT_STATUS:
      return <span> commented on this project: </span>;
    case ACTION_TYPES.TASK_COMMENT:
      return (
        <span>
          commented on <b>{displayData.task_name}</b> in
        </span>
      );
    case ACTION_TYPES.TASK_REPLY:
      return (
        <span>
          replied to a comment on <b>{displayData.task_name}</b> in
        </span>
      );
    case ACTION_TYPES.TASK_MENTION:
      return <span> mentioned you in a task in </span>; // WIP
    case ACTION_TYPES.NOTE_CREATION:
      return <span> created a note in </span>;
    case ACTION_TYPES.SEND_SCHEDULED_EVENT:
      return displayData.event_type === 'workload' ? (
        <span>
          {' '}
          sent you a <b>Work Plan</b> for the
          <b>
            {' '}
            Week of {moment(getDisplayDataRange(displayData)).format('MMM. Do')}
          </b>
        </span>
      ) : displayData.event_type === 'checkin' ? (
        <span>
          {' '}
          sent you Check-ins for the
          <b>
            {' '}
            day of {moment(getDisplayDataRange(displayData)).format('MMM. Do')}
          </b>
        </span>
      ) : (
        <span>
          {' '}
          sent a<b> Timesheet Reminder</b>
        </span>
      );
    // This should be fine after we fix recipient
    case ACTION_TYPES.FOLLOWING_SCHEDULED_EVENT:
      return displayData.event_type === 'workload' ? (
        <span>
          {' '}
          sent Work Plans to{' '}
          <b>
            {displayData.recipient_count} Member
            {displayData.recipient_count === 1 ? '' : 's'}
          </b>{' '}
          for the
          <b>
            {' '}
            Week of {moment(getDisplayDataRange(displayData)).format('MMM. Do')}
          </b>
        </span>
      ) : (
        <span>
          {' '}
          sent Check-ins to{' '}
          <b>
            {displayData.recipient_count} Member
            {displayData.recipient_count === 1 ? '' : 's'}
          </b>{' '}
          for the
          <b>
            {' '}
            day of {moment(getDisplayDataRange(displayData)).format('MMM. Do')}
          </b>
        </span>
      );

    case ACTION_TYPES.INTEGRATION_MESSAGE:
      return <span> sent a notification</span>;

    default:
      return <span> took an action in </span>;
  }
};

const AvatarWrapper = styled.div`
  margin-right: 8px;
`;

const ActivityItemHeader = ({
  type,
  displayData,
  actor,
  project,
  actionType,
  goToProject = noop,
  createdByCurrentUser,
  handleClick = noop,
  toggleHoveredOn = noop,
  toggleHoveredOff = noop,
  isHovered,
  isActivityMemberModal,
  accountId
}) => {
  const myId = useSelector(getMyUserId);
  const actorId = actor.id;

  const getTeamMembershipByAccountId = useMemo(
    () => makeGetTeamMembershipByAccountId(),
    []
  );

  // if the user on the member modal, it assume the account id of current member modal is recipient id.
  const recipientId = isActivityMemberModal ? parseInt(accountId, 10) : myId;
  const recipient = useSelector((state) =>
    getTeamMembershipByAccountId(state, {
      accountId: recipientId
    })
  );

  const actorName = actorId === myId ? 'You' : actor.name;

  // when it has recipientId, wait until recipient loaded
  if (recipientId && !recipient) {
    return <></>;
  }

  return (
    <div className="activity-item-header" onClick={handleClick}>
      <AvatarWrapper>
        <MemberInitials
          member={actor}
          classes={cn('regular-member-no-hover')}
        ></MemberInitials>
      </AvatarWrapper>
      <div className="activity-description">
        {!isActivityMemberModal && (
          <span className="actor-name">{actorName}</span>
        )}
        {getNotificationText({
          actionType,
          displayData,
          isActivityMemberModal,
          actorId,
          myId,
          recipient
        })}
        {actionType !== ACTION_TYPES.PROJECT_ARCHIVE &&
          actionType !== ACTION_TYPES.PROJECT_ADD_MEMBER && (
            <span className="project-name" onClick={goToProject}>
              {project && project.title}
            </span>
          )}
      </div>
    </div>
  );
};

export default withHovered(ActivityItemHeader);
