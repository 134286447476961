import cn from 'classnames';
import styled from 'styled-components';
import theme from 'theme';
import { useFilterListType } from 'FilterModule/hooks/useFilterListType';
import { FilterListType } from 'FilterModule/constants';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { ValueOf } from 'type-fest';

interface FilterDropdownToggleProps {
  isOpen: boolean;
  testId?: string;
  filterListType: FilterListType;
  /** FilterListType to config */
  optionHash: Partial<
    Record<
      FilterListType,
      {
        [key: string]: unknown;
        label?: string;
        singularToggleLabel?: string;
        pluralToggleLabel?: string;
        toggleLabel?: string;
      }
    >
  >;
  customLabel?: string;
  fieldOverride?: string;
}

/**
 * To override default labels, use: singularToggleLabel, pluralToggleLabel, or toggleLabel
 */
export const FilterDropdownToggle = ({
  isOpen,
  filterListType,
  fieldOverride,
  optionHash,
  testId,
  customLabel
}: FilterDropdownToggleProps) => {
  const { currentFilter } = useFilterContext();
  const { numFilteredItems } = useFilterListType({
    filterListType,
    currentFilter,
    fieldOverride
  });

  const config = optionHash[filterListType];

  if (!config) {
    return null;
  }

  return (
    <FilterDropdownToggleUI
      label={
        customLabel ??
        makeLabel({
          config,
          count: numFilteredItems,
          filterListType
        })
      }
      isOpen={isOpen}
      testId={testId}
      hasSelectedItems={numFilteredItems > 0}
    />
  );
};

export const FilterDropdownToggleUI = ({
  label,
  isOpen,
  testId,
  hasSelectedItems
}: {
  label: string;
  isOpen: boolean;
  testId?: string;
  hasSelectedItems: boolean;
}) => {
  return (
    <StyledFilterDropdownToggle
      isOpen={isOpen}
      className={cn('filter-dropdown-toggle', { open: isOpen })}
      data-testid={`${testId}-filter-dropdown-toggle`}
      hasSelectedItems={hasSelectedItems}
    >
      <span className="filter-dropdown-toggle-label">{label}</span>
    </StyledFilterDropdownToggle>
  );
};

/* ------------------------------------ - ----------------------------------- */

const defaultPluralLabelsByFilterListType = {
  [FilterListType.ActivityIds]: 'Work Categories',
  [FilterListType.Projects]: 'Projects',
  [FilterListType.Priorities]: 'Priorities',
  [FilterListType.ProjectBudgetStatus]: 'Statuses',
  [FilterListType.TimesheetStatus]: 'Statuses',
  [FilterListType.CsvImportDataTypes]: 'Data Types'
};

const defaultSingularLabelsByFilterListType = {
  [FilterListType.ActivityIds]: 'Work Category',
  [FilterListType.Projects]: 'Project',
  [FilterListType.ClientNames]: 'Client',
  [FilterListType.PhaseNames]: 'Phase'
};

const defaultLabelsByFilterListType = {
  [FilterListType.Members]: 'People'
};

const makeLabel = ({
  config,
  count,
  filterListType
}: {
  config: ValueOf<FilterDropdownToggleProps['optionHash']>;
  count: number;
  filterListType: FilterListType;
}) => {
  const isMultiple = count > 1;

  const singularLabel =
    config?.singularToggleLabel ||
    defaultSingularLabelsByFilterListType[filterListType];

  const pluralLabel = isMultiple
    ? config?.pluralToggleLabel ||
      defaultPluralLabelsByFilterListType[filterListType] ||
      (singularLabel ? singularLabel + 's' : '')
    : null;

  const toggleLabel = config?.toggleLabel;

  const defaultToggleLabel = defaultLabelsByFilterListType[filterListType];

  const defaultLabel = config?.label;

  const label =
    pluralLabel ||
    singularLabel ||
    toggleLabel ||
    defaultToggleLabel ||
    defaultLabel ||
    '';

  return `${count > 0 ? `${count} ` : ''}${label}`;
};

const StyledFilterDropdownToggle = styled.div<{
  isOpen: boolean;
  hasSelectedItems: boolean;
}>`
  background: ${theme.colors.colorPaleGray10};
  padding: 3px 6px;
  font-size: 12px;
  color: ${({ hasSelectedItems }) =>
    hasSelectedItems
      ? theme.colors.colorCalendarBlue
      : theme.colors.colorMediumGray9};
  width: max-content;
  border-radius: 6px;

  cursor: pointer;
`;
