import { currencyFormatter } from 'appUtils/hooks/useFormatCurrency';
import { CURRENCY_LIST_UNIQUE } from './constants/currencyList';

export const getCurrencySymbolByCode = ({
  currencyCode
}: {
  currencyCode: string | undefined;
}): string => {
  if (!currencyCode) return '';

  return (
    currencyFormatter({
      currencyCode,
      options: { currencyDisplay: 'narrowSymbol' }
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency')?.value ?? ''
  );
};

export const getSelectedCurrencyString = ({
  currencyCode
}: {
  currencyCode: string | undefined;
}): string => {
  if (!currencyCode) return '';

  const selectedCurrencyItem = CURRENCY_LIST_UNIQUE.find(
    (currency) => currencyCode === currency.currencyCode
  );

  if (selectedCurrencyItem) {
    const { currencyName } = selectedCurrencyItem;
    const currencySymbol = getCurrencySymbolByCode({ currencyCode });

    return `${currencyName} (${currencySymbol})`;
  }

  return '';
};
