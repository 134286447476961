import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { Page } from 'models/filter';
import {
  FilterLevelSchemas,
  FilterFieldSchema,
  CurrentFilter
} from 'FilterModule/types';
import {
  defaultNumberArrayFieldSchema,
  defaultStringArrayFieldSchema,
  defaultOptionHashExistenceValidator,
  makeDefaultFilterOrderFieldSchema
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { FilterField, FilterListType } from 'FilterModule/constants';
import { DATE_RANGES } from 'appUtils/dateRangeHelpers';

const pageName = Page.ImportLogOverview;

const overviewViewFilterName = serializeFilterLevelName({
  level1: pageName,
  level2: 'overview'
});

type DateRangeOptionValueType = keyof Pick<
  typeof DATE_RANGES,
  'ALL_DATES' | 'CUSTOM'
>;

type ImportLogOverviewViewFieldSchemas = {
  [FilterField.account_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.csv_import_data_types]: typeof defaultStringArrayFieldSchema;
  [FilterField.dateRange]: FilterFieldSchema<DateRangeOptionValueType>;
  [FilterField.start_date]: FilterFieldSchema<Nullable<string>>;
  [FilterField.end_date]: FilterFieldSchema<Nullable<string>>;
  [FilterField.filterOrder]: FilterFieldSchema<
    FilterListType,
    FilterListType[],
    { fieldOverride?: string }
  >;
};

/* -------------------------- teamViewFieldSchemas ------------------------- */

const overviewViewFieldSchemas: ImportLogOverviewViewFieldSchemas = {
  [FilterField.dateRange]: {
    defaultValue: DATE_RANGES.ALL_DATES,
    optionsArray: [DATE_RANGES.ALL_DATES, DATE_RANGES.CUSTOM],
    optionHash: {
      [DATE_RANGES.ALL_DATES]: {
        label: 'All Dates',
        value: DATE_RANGES.ALL_DATES
      },
      [DATE_RANGES.CUSTOM]: {
        label: 'Custom',
        value: DATE_RANGES.CUSTOM
      }
    },
    getValidatedFieldValue: defaultOptionHashExistenceValidator
  },
  [FilterField.start_date]: {
    defaultValue: null,
    getValidatedFieldValue: null
  },

  [FilterField.end_date]: {
    defaultValue: null,
    getValidatedFieldValue: null
  },
  [FilterField.account_ids]: defaultNumberArrayFieldSchema,
  [FilterField.csv_import_data_types]: defaultStringArrayFieldSchema,
  [FilterField.filterOrder]: makeDefaultFilterOrderFieldSchema({
    defaultValue: [],
    optionsArray: [FilterListType.CsvImportDataTypes]
    // member does not work right now with the endpoint
    // optionsArray: [FilterListType.Members, FilterListType.CsvImportDataTypes]
  })
};

/* ------------------------ initialFilterLevelSchemas ----------------------- */

export const initialFilterLevelSchemas: FilterLevelSchemas = {
  [overviewViewFilterName]: {
    fieldSchemas: overviewViewFieldSchemas
  }
};

/* ---------------------------- CurrentFilterType --------------------------- */

export type ImportLogOverviewViewCurrentFilterType =
  CurrentFilter<ImportLogOverviewViewFieldSchemas>;

export const useImportLogOverviewViewFilterContext = () =>
  useFilterContext<ImportLogOverviewViewCurrentFilterType>();
