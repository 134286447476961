import React from 'react';
import { connect } from 'react-redux';
import {
  RateConfirmRow,
  RateConfirmBox,
  RateDateContainer,
  RateMemberDescription,
  AffectedDate
} from './styles';
import {
  getEarlierTeamRate,
  getLaterTeamRate,
  getEarliestTeamRate
} from 'BudgetModule/selectors';

import { EARLIEST_RATE_START_DISPLAY } from 'BudgetModule/constants';
import moment from 'appUtils/momentConfig';

class UpdateRows extends React.Component {
  formatSelectedDate = () => this.props.selectedDate.clone().format('M/DD/YY');
  renderSelectedDate = () => <div>{this.formatSelectedDate()}</div>;

  renderRateDescription = (item) => {
    const { hideDescription } = this.props;
    return (
      <RateMemberDescription>
        <div className="rate-cost">
          ${item.actual_hourly_rate || item.rate.rate}
        </div>{' '}
        {!hideDescription && item.rate?.description}
      </RateMemberDescription>
    );
  };

  renderLaterRate = () => {
    const { laterRate } = this.props;
    return laterRate ? (
      <RateConfirmRow>
        {this.renderRateDescription(laterRate)}
        <RateDateContainer>
          {this.renderSelectedDate()} -{' '}
          <div>
            {!laterRate.end_date || laterRate.end_date === 'Present'
              ? 'Present'
              : moment(laterRate.end_date).format('M/DD/YY')}
          </div>
        </RateDateContainer>
      </RateConfirmRow>
    ) : null;
  };

  renderEarlierRate = () => {
    const { earlierRate, earliestRate, disableChangeEarliestRateStart } =
      this.props;
    const isEarliestRate = earlierRate === earliestRate;
    const earlierRateDisplayDate =
      isEarliestRate && disableChangeEarliestRateStart
        ? EARLIEST_RATE_START_DISPLAY
        : moment(earlierRate?.start_date).format('M/DD/YY');
    return earlierRate ? (
      <RateConfirmRow>
        {this.renderRateDescription(earlierRate)}
        <RateDateContainer>
          <div>{earlierRateDisplayDate}</div> - {this.renderSelectedDate()}
        </RateDateContainer>
      </RateConfirmRow>
    ) : null;
  };

  renderItems = () => (
    <RateConfirmBox>
      {this.renderEarlierRate()}
      {this.renderLaterRate()}
    </RateConfirmBox>
  );

  render() {
    return this.renderItems();
  }
}

export default UpdateRows;
