import { useConfirmStopTimerModal } from './useConfirmStopTimerModal';

export const withConfirmStopTimerModal = (Component) => {
  const WithConfirmStopTimerModal = (props) => {
    const {
      ConfirmStopTimerModal,
      open: openConfirmStopTimerModal,
      close: closeConfirmStopTimerModal
    } = useConfirmStopTimerModal();

    return (
      <Component
        {...props}
        openConfirmStopTimerModal={openConfirmStopTimerModal}
        closeConfirmStopTimerModal={closeConfirmStopTimerModal}
        ConfirmStopTimerModal={ConfirmStopTimerModal}
      />
    );
  };

  return WithConfirmStopTimerModal;
};
