import { AccessRoles } from 'PermissionsModule/types';
import { permissionsUtils } from 'PermissionsModule/utils';
import { useState } from 'react';
import styled from 'styled-components';
import { AccessLevelSizeIndicator } from './AccessLevelSizeIndicator';
import theme from 'theme';
import QBDownArrow from 'icons/QBDownArrow';
import { permissionsPresentationUtils } from 'PermissionsModule/components/utils';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const { accessRoleToContent, getSecondaryContent } =
  permissionsPresentationUtils;

interface AccessLevelSummaryProps {
  role: AccessRoles;
  isMemberArchived: boolean;
  isHideArrow?: boolean;
  onClick?: () => void;
}

export const AccessLevelSummary = ({
  role,
  isMemberArchived,
  isHideArrow = false,
  onClick
}: AccessLevelSummaryProps) => {
  const accessLevelMetadata = permissionsUtils.getAccessLevelMetadata({
    teamRole: role
  });

  const { projectLevelPermissionFlag } = useFeatureFlags();

  const { accessRoleType } = accessLevelMetadata;

  const [isHovering, setIsHovering] = useState(false);

  const AccessRoleContent = accessRoleToContent[accessRoleType];
  const SecondaryContent = getSecondaryContent({
    metadata: accessLevelMetadata
  });

  return (
    <RootContainer
      className="access-level-summary"
      $isHovering={isHovering}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <AccessLevelSizeIndicator
        role={role}
        isShowGrayedOut={isMemberArchived}
      />
      <ContentContainer>
        <TextContainer
          $isMemberArchived={isMemberArchived}
          $projectLevelPermissionFlag={projectLevelPermissionFlag}
          className="text-container"
        >
          <AccessRoleContentContainer $isHovering={isHovering}>
            {AccessRoleContent}
          </AccessRoleContentContainer>{' '}
          <ProjectVisibilityContentContainer $isHovering={isHovering}>
            {projectLevelPermissionFlag && SecondaryContent}
            {!isHideArrow && (
              <ArrowContainer>
                <QBDownArrow fill={theme.colors.colorCalendarBlue} />
              </ArrowContainer>
            )}
          </ProjectVisibilityContentContainer>
        </TextContainer>
      </ContentContainer>
    </RootContainer>
  );
};

const ArrowContainer = styled.span`
  padding-left: 5px;
  align-self: flex-end;
`;

const RootContainer = styled.div<{
  $isHovering: boolean;
}>`
  color: ${({ $isHovering }) =>
    $isHovering
      ? theme.colors.colorCalendarBlue
      : theme.colors.colorSemiDarkGray1};

  ${ArrowContainer} {
    opacity: 0;
  }

  &:hover {
    ${ArrowContainer} {
      opacity: 1;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TextContainer = styled.div<{
  $isMemberArchived: boolean;
  $projectLevelPermissionFlag: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => !props.$projectLevelPermissionFlag && `display: flex`};
  ${(props) => props.$isMemberArchived && 'opacity: 0.6;'}
`;

const AccessRoleContentContainer = styled.div<{ $isHovering: boolean }>`
  ${(props) =>
    !props.$isHovering && `color: ${theme.colors.colorSemiDarkGray1}`};
  line-height: 1.3;
`;

const ProjectVisibilityContentContainer = styled.div<{
  $isHovering: boolean;
}>`
  ${(props) =>
    !props.$isHovering && `color: ${theme.colors.colorCalendarGray}`};
  line-height: 1.3;
`;
