import styled from 'styled-components';
import { MemberPermissionListItem } from '../types';
import { BaseCellRootContainer } from './styles';

export const MembersPermissionsTableEmployeeNumberCell = ({ row }) => {
  const { teamMember, onRequestEditMemberMetadata } =
    row.original as MemberPermissionListItem;

  return (
    <RootContainer
      onClick={() =>
        onRequestEditMemberMetadata({ teamMembershipId: teamMember.id })
      }
    >
      <TextContainer className="text-container">
        {teamMember.employee_number}
      </TextContainer>
    </RootContainer>
  );
};

const RootContainer = styled(BaseCellRootContainer)`
  // center with column header text
  padding-left: 10px;
`;

const TextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
