import { ReactElement } from 'react';
import GearIcon from 'icons/GearIcon';
import {
  IconContainer,
  HeaderText,
  Header,
  Section,
  Container
} from './styles';

interface PermissionsSectionProps {
  headerText: string;
  Icon?: (props: unknown) => JSX.Element;
  children: ReactElement | Array<ReactElement>;
}

const PermissionsSection = ({
  Icon = GearIcon,
  headerText,
  children
}: PermissionsSectionProps) => {
  return (
    <Container>
      <Header>
        <IconContainer>
          <Icon />
        </IconContainer>
        <HeaderText>{headerText}</HeaderText>
      </Header>
      <Section>{children}</Section>
    </Container>
  );
};
export default PermissionsSection;
