import React from 'react';
import { connect } from 'react-redux';

import { FilterableFlyout, MemberCircleNameRow } from '..';
import { updateWorkloadPlannerMembers } from 'actionCreators';
import {
  getNonPlannerMembersArray,
  getPlannerMemberIds,
  getCurrentUserId
} from 'selectors';

class TeamPlannerMemberSelector extends React.Component {
  excludeMeFromList = (memberList) => {
    const { userId } = this.props;
    return memberList.filter((member) => member.account_id != userId);
  };

  handleSelectMemberFactory = (member) => {
    const { plannerMemberIds, updateWorkloadPlannerMembers } = this.props;
    return (e) => {
      e.stopPropagation();
      updateWorkloadPlannerMembers({
        team_planner_members: plannerMemberIds.concat(member.account_id)
      });
      this.props.closeFlyout();
    };
  };

  render() {
    const { closeFlyout, nonPlannerMembers, target, boundariesElement } =
      this.props;

    return (
      <div className="team-member-selector">
        <FilterableFlyout
          target={target}
          closeDropdown={closeFlyout}
          fullItemList={this.excludeMeFromList(nonPlannerMembers)}
          ItemComponent={MemberCircleNameRow}
          filterKeysArray={['name', 'initials']}
          onSelectFactory={this.handleSelectMemberFactory}
          searchPlaceholder="Search Project Members"
          boundariesElement={boundariesElement}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  nonPlannerMembers: getNonPlannerMembersArray(state),
  plannerMemberIds: getPlannerMemberIds(state),
  userId: getCurrentUserId(state)
});

const mapDispatchToProps = {
  updateWorkloadPlannerMembers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamPlannerMemberSelector);
