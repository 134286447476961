import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import ProjectNotificationMenu from 'components/ProjectNotificationMenu';
import NotificationIcon from 'icons/NotificationIcon';

import {
  Container,
  NotificationText,
  NotificationBell,
  BeforeElement,
  ProjectRowNumber
} from './styles';
import { OutsideClickWrapper } from 'views';
import { updateNotificationPreferences } from 'actionCreators';
import { getMe } from 'selectors';

const NotificationIndicator = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${(props) =>
    props.checked ? theme.colors.colorCalendarBlue : '#f3f3f3'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  cursor: pointer;
  path {
    fill: ${(props) =>
      props.checked ? theme.colors.colorPureWhite : '#979797'};
  }
  &:hover {
    background-color: ${theme.colors.colorCalendarBlue};
    path {
      fill: ${theme.colors.colorPureWhite};
    }
  }
`;

const StyledNotificationIcon = styled(NotificationIcon)`
  margin-top: 9px;
  margin-right: 1px;
`;

const ALL_ACTIVITY = 'All Project Activity';
const PROJECT_COMMENTS = 'Updates';
const TASKS = 'Tasks';
const NOTES = 'Notes';
const emptyObj = {};
class ProjectNotificationSettings extends React.Component {
  state = this.defaultState();

  setBellRef = (ref) => (this.bellRef = ref);

  defaultState() {
    const { projectMembership } = this.props;
    const { notify_tasks, notify_comments, notify_notes } = projectMembership;

    return {
      top: 0,
      left: 0,
      isOpen: false,
      [ALL_ACTIVITY]: notify_tasks && notify_comments && notify_notes,
      [PROJECT_COMMENTS]: notify_comments && (!notify_tasks || !notify_notes),
      [TASKS]: notify_tasks && (!notify_comments || !notify_notes),
      [NOTES]: notify_notes && (!notify_comments || !notify_tasks)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { projectMembership } = this.props;
    const { notify_tasks, notify_comments, notify_notes } = projectMembership;

    if (
      this.state === prevState &&
      (notify_tasks !== this.state[TASKS] ||
        notify_notes !== this.state[NOTES] ||
        notify_comments !== this.state[PROJECT_COMMENTS])
    ) {
      this.setState({
        [ALL_ACTIVITY]: notify_tasks && notify_comments && notify_notes,
        [PROJECT_COMMENTS]: notify_comments && (!notify_tasks || !notify_notes),
        [TASKS]: notify_tasks && (!notify_comments || !notify_notes),
        [NOTES]: notify_notes && (!notify_comments || !notify_tasks)
      });
    }
  }

  openDropdown = () => {
    const { x, y } = this.bellRef.getBoundingClientRect();

    this.setState({
      isOpen: true,
      top: y + 20,
      left: x - 15
    });
  };

  getIsNotificationPreferencesChanged = () => {
    const { projectMembership } = this.props;
    const { notify_tasks, notify_comments, notify_notes } = projectMembership;

    const isAllSelected = notify_tasks && notify_comments && notify_notes;
    const isAllSelectedChanged = isAllSelected !== this.state[ALL_ACTIVITY];

    return isAllSelectedChanged
      ? true
      : !isAllSelected &&
          (notify_tasks !== this.state[TASKS] ||
            notify_notes !== this.state[NOTES] ||
            notify_comments !== this.state[PROJECT_COMMENTS]);
  };

  closeDropdownAndSubmit = (e) => {
    const allActivity = this.state[ALL_ACTIVITY];
    const projectComments = this.state[PROJECT_COMMENTS];
    const tasks = this.state[TASKS];
    const notes = this.state[NOTES];
    const { me, project, updateNotificationPreferences, isProjectMember } =
      this.props;

    this.setState({ isOpen: false });
    e.stopPropagation();

    if (!isProjectMember) {
      return;
    }

    const isNotificationPreferencesChanged =
      this.getIsNotificationPreferencesChanged();

    if (isNotificationPreferencesChanged) {
      updateNotificationPreferences({
        accountId: me.id,
        projectId: project.id,
        allActivity,
        projectComments,
        tasks,
        notes
      });
    }
  };

  selectAllActivity = () => {
    this.setState((prevState) => {
      if (prevState[ALL_ACTIVITY]) {
        return {
          [ALL_ACTIVITY]: false
        };
      } else {
        return {
          [ALL_ACTIVITY]: true,
          [PROJECT_COMMENTS]: false,
          [TASKS]: false,
          [NOTES]: false
        };
      }
    });
  };

  selectProjectComments = () => {
    this.setState((prevState) => {
      if (prevState[TASKS] && prevState[NOTES]) {
        return {
          [ALL_ACTIVITY]: true,
          [PROJECT_COMMENTS]: false,
          [TASKS]: false,
          [NOTES]: false
        };
      } else {
        return {
          [ALL_ACTIVITY]: false,
          [PROJECT_COMMENTS]: !prevState[PROJECT_COMMENTS]
        };
      }
    });
  };

  selectTasks = () => {
    this.setState((prevState) => {
      if (prevState[PROJECT_COMMENTS] && prevState[NOTES]) {
        return {
          [ALL_ACTIVITY]: true,
          [PROJECT_COMMENTS]: false,
          [TASKS]: false,
          [NOTES]: false
        };
      } else {
        return {
          [ALL_ACTIVITY]: false,
          [TASKS]: !prevState[TASKS]
        };
      }
    });
  };

  selectNotes = () => {
    this.setState((prevState) => {
      if (prevState[TASKS] && prevState[PROJECT_COMMENTS]) {
        return {
          [ALL_ACTIVITY]: true,
          [PROJECT_COMMENTS]: false,
          [TASKS]: false,
          [NOTES]: false
        };
      } else {
        return {
          [ALL_ACTIVITY]: false,
          [NOTES]: !prevState[NOTES]
        };
      }
    });
  };

  getMenuOptions = () => {
    return [
      {
        key: ALL_ACTIVITY,
        handleSelect: this.selectAllActivity,
        tooltipText:
          'You will receive notifications of all activity on this project including updates, tasks, and notes.'
      },
      {
        key: PROJECT_COMMENTS,
        handleSelect: this.selectProjectComments,
        tooltipText: 'You will receive notifications of all project updates.',
        isIndented: true
      },
      {
        key: TASKS,
        handleSelect: this.selectTasks,
        tooltipText:
          'You will receive notifications of all tasks you follow on this project, including task notes and all task updates.',
        isIndented: true
      },
      {
        key: NOTES,
        handleSelect: this.selectNotes,
        tooltipText:
          'You will receive notifications of all notes on this project.',
        isIndented: true
      }
    ];
  };

  getNotificationText = () => {
    const { onProjectRow, onCommentModal, onProjectDetail } = this.props;
    const allActivity = this.state[ALL_ACTIVITY];
    const projectComments = this.state[PROJECT_COMMENTS];
    const tasks = this.state[TASKS];
    const notes = this.state[NOTES];

    if (onProjectRow || onCommentModal || onProjectDetail) {
      return '';
    } else if (allActivity) {
      return ALL_ACTIVITY;
    } else if (projectComments || tasks || notes) {
      const commentText = projectComments ? 'All Project Updates, ' : '';
      const taskText = tasks ? 'All Tasks, ' : '';
      const noteText = notes ? 'All Notes, ' : '';
      const notificationString = commentText + taskText + noteText;

      return notificationString.slice(0, -2);
    } else {
      return 'Notifications Off';
    }
  };

  getNotificationSettings = () => {
    return omit(this.state, ['isOpen']);
  };

  render() {
    const {
      hovered,
      onCommentModal,
      onProjectDetail,
      onProjectRow,
      onProjectModal,
      isProjectMember,
      rowNumber
    } = this.props;
    const allActivity = this.state[ALL_ACTIVITY];
    const projectComments = this.state[PROJECT_COMMENTS];
    const tasks = this.state[TASKS];
    const notes = this.state[NOTES];
    const hasNotificationsOn = allActivity || projectComments || tasks || notes;
    const projectRowHasNotifications = onProjectRow && hasNotificationsOn;
    const projectDetailHasNotifications = onProjectDetail && hasNotificationsOn;
    const activeText =
      this.state.isOpen ||
      (!onProjectRow &&
        !onCommentModal &&
        !onProjectDetail &&
        hasNotificationsOn);
    const containerVisible =
      onProjectDetail || !onProjectRow || hovered || this.state.isOpen;
    return (
      <Container
        className="notification-toggle"
        visible={containerVisible}
        onClick={this.openDropdown}
        data-tip="Personal Notifications"
        data-for={'app-tooltip'}
        data-effect="solid"
        data-multiline={true}
      >
        {onProjectModal ? (
          <NotificationIndicator
            checked={
              activeText ||
              projectRowHasNotifications ||
              projectDetailHasNotifications
            }
            isDisabled={false}
            data-for="app-tooltip"
            data-tip="Click to change Notification settings"
            data-html
            data-effect="solid"
            ref={this.setBellRef}
          >
            <StyledNotificationIcon height={26} width={21} />
          </NotificationIndicator>
        ) : (
          <>
            <NotificationText
              data-tip="Click to change Notification settings"
              data-for="app-tooltip"
              data-effect="solid"
              activeText={activeText}
              className="notification-text"
              show={!(onCommentModal || onProjectDetail || onProjectRow)}
            >
              {this.getNotificationText()}
            </NotificationText>
            <NotificationBell
              className="notification-bell"
              ref={this.setBellRef}
              blueHover={onCommentModal || onProjectDetail || onProjectRow}
              isBlue={
                activeText ||
                projectRowHasNotifications ||
                projectDetailHasNotifications
              }
              isOnCommentModal={onCommentModal}
              isOnProjectRow={onProjectRow}
              isOnProjectDetail={onProjectDetail}
            />
            <ProjectRowNumber visible={containerVisible}>
              {rowNumber}
            </ProjectRowNumber>
          </>
        )}
        {this.state.isOpen ? (
          !onProjectRow ? (
            <OutsideClickWrapper
              handleOutsideClick={this.closeDropdownAndSubmit}
            >
              <ProjectNotificationMenu
                rightAlign={!onCommentModal && !onProjectDetail}
                isProjectMember={isProjectMember}
                closeModal={this.closeDropdownAndSubmit}
                menuOptions={this.getMenuOptions()}
                notificationSettings={this.getNotificationSettings()}
              />
            </OutsideClickWrapper>
          ) : (
            createPortal(
              <BeforeElement
                className="before-element"
                onClick={this.closeDropdownAndSubmit}
              >
                <ProjectNotificationMenu
                  closeModal={this.closeDropdownAndSubmit}
                  onCommentModal={onCommentModal}
                  onProjectRow={onProjectRow}
                  onProjectDetail={onProjectDetail}
                  isProjectMember={isProjectMember}
                  menuOptions={this.getMenuOptions()}
                  notificationSettings={this.getNotificationSettings()}
                  left={this.state.left + (onProjectDetail ? 16 : 0)}
                  top={this.state.top}
                />
              </BeforeElement>,
              document.querySelector('body')
            )
          )
        ) : null}
      </Container>
    );
  }
}

ProjectNotificationSettings.propTypes = {
  hovered: PropTypes.bool,
  me: PropTypes.object.isRequired,
  onCommentModal: PropTypes.bool,
  onProjectDetail: PropTypes.bool,
  onProjectRow: PropTypes.bool,
  project: PropTypes.object.isRequired,
  projectMembership: PropTypes.object.isRequired,
  isProjectMember: PropTypes.bool,
  updateNotificationPreferences: PropTypes.func.isRequired
};

ProjectNotificationSettings.defaultProps = {
  onCommentModal: false,
  onProjectDetail: false,
  onProjectRow: false
};

const mapStateToProps = (state, ownProps) => {
  const me = getMe(state);
  const projectMembership =
    (ownProps.project &&
      ownProps.project.project_membership &&
      ownProps.project.project_membership.find(
        (membership) => membership.account?.id === me.id
      )) ||
    emptyObj;

  return {
    me,
    projectMembership,
    isProjectMember: !!projectMembership.id
  };
};

const mapDispatchToProps = {
  updateNotificationPreferences
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ProjectNotificationSettings);
