import { MenuItem, MenuList } from '../../Menu';
import PencilIconDark from 'icons/PencilIconDark';
import { CheckmarkGear } from 'icons/CheckmarkGear';
import { ArchiveIcon } from 'icons/ArchiveIcon';
import DeleteIcon from 'icons/DeleteIcon';
import { ComponentProps, MouseEvent, useEffect, useRef } from 'react';
import { RateGroup } from 'RatesModule/models/RateGroup';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { updateRateGroup } from 'RatesModule/ratesActionCreators';
import { useAppDispatch } from 'reduxInfra/hooks';
import { EntityRateType } from 'RatesModule/models/EntityRate';
import Popover from 'components/Popover';

const handleDisabledClick = (e: MouseEvent) => e.stopPropagation();

export const RateGroupMenu = ({
  activeEntityType,
  deleteRateGroup,
  editRateGroup,
  onClose,
  rateGroup,
  target
}: {
  activeEntityType: EntityRateType;
  deleteRateGroup: () => void;
  editRateGroup: () => void;
  onClose: () => void;
  rateGroup: RateGroup;
  target: Element;
}) => {
  const dispatch = useAppDispatch();

  const isArchived = !!rateGroup.archived_at;

  const handleSetDefaultRateGroup = () => {
    dispatch(
      updateRateGroup({
        active_entity_type: activeEntityType,
        is_default: true,
        rate_group_id: rateGroup.id
      })
    );
  };

  const handleArchiveRateGroup = () => {
    dispatch(
      updateRateGroup({
        active_entity_type: activeEntityType,
        is_archived: !isArchived,
        rate_group_id: rateGroup.id
      })
    );
  };

  const {
    archiveLabel,
    editProps,
    setDefaultProps
  }: {
    archiveLabel: string;
    editProps: Partial<ComponentProps<typeof MenuItem>>;
    setDefaultProps: Partial<ComponentProps<typeof MenuItem>>;
  } = isArchived
    ? {
        archiveLabel: 'Unarchive Rate Group',
        editProps: {
          disabled: true,
          ...defaultTooltipProps,
          'data-tip': 'Unarchive to edit.',
          onClick: handleDisabledClick
        },
        setDefaultProps: {
          disabled: true,
          ...defaultTooltipProps,
          'data-tip': 'Unarchive to set default.',
          onClick: handleDisabledClick
        }
      }
    : {
        archiveLabel: 'Archive Rate Group',
        editProps: { onClick: editRateGroup },
        setDefaultProps: { onClick: handleSetDefaultRateGroup }
      };

  // Close the menu when the menu trigger is disconnected from the page.
  const detachObserverRef = useRef(
    new IntersectionObserver((entries) =>
      entries.forEach((entry) => {
        if (!entry.intersectionRatio) onClose();
      })
    )
  );
  useEffect(() => {
    const detachObserver = detachObserverRef.current;

    if (target.isConnected) detachObserver.observe(target);

    return () => detachObserver.disconnect();
  }, [target]);

  return (
    <Popover closePopover={onClose} hideArrow isOpen target={target}>
      <MenuList onClick={onClose}>
        <MenuItem icon={<PencilIconDark />} {...editProps}>
          Edit Rate Group
        </MenuItem>
        {!rateGroup.is_default && (
          <MenuItem icon={<CheckmarkGear />} {...setDefaultProps}>
            Set Default Rate Group
          </MenuItem>
        )}
        <MenuItem icon={<ArchiveIcon />} onClick={handleArchiveRateGroup}>
          {archiveLabel}
        </MenuItem>
        <MenuItem icon={<DeleteIcon />} onClick={deleteRateGroup}>
          Delete Rate Group
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
