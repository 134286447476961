import { useCallback } from 'react';
import { ROW_TYPES, makeShowArchivedRow } from '../tableConfigs';
import type {
  GroupedList,
  FilterListItemsBuilder,
  GroupedListsBuilder,
  DefaultGroupItem,
  GroupEntityHashType
} from '../types';
import { FilterListType } from 'FilterModule/constants';
import { serializeId } from 'appUtils';
import { serializeSectionIds } from 'appUtils/hooks/useStickyHeader';

export const useGroupedListsBuilder = ({
  listItemsBuilder,
  isOff,
  getIsOpen,
  toggleCollapse
}: {
  listItemsBuilder: FilterListItemsBuilder;
  isOff: boolean;
  getIsOpen: (
    id:
      | string
      | number
      | { parentId: string | number; toggleId: string | number }
  ) => boolean | undefined;
  toggleCollapse: (
    id:
      | string
      | number
      | { parentId: string | number; toggleId: string | number }
  ) => void;
  setParentCollapseState: any; // FIXME
}) => {
  const groupedListsBuilder: GroupedListsBuilder = useCallback(
    ({
      order,
      parentGroupId,
      filterValues,
      groupEntityType,
      stickyHeaderOffset
    }) => {
      // small optimization to avoid unnecessary Array.filter
      let hasHiddenLists = false;

      const filterListType = parentGroupId as FilterListType;

      const {
        ordersByGroup,
        groupTotalCounts,
        clearSelectedItems,
        selectAllItems,
        isAllSelectedByGroup,
        numSelectedByGroup,
        numArchivedByGroup,
        isArchivedShowingHash,
        toggleIsArchivedShowing,
        hasNextPageByGroup,
        toggleSelectGroup,
        groupEntityHash,
        getGroupEntityLabel,
        selectionLimit,
        isUnableToSelectMoreItems
      } = filterValues;

      // iterate over the order and create the lists
      const lists = (order as string[]).reduce<Record<string, GroupedList>>(
        (acc, orderId) => {
          const groupEntity =
            orderId === ''
              ? ({
                  id: ''
                } as DefaultGroupItem)
              : (groupEntityHash[orderId] as GroupEntityHashType<
                  typeof groupEntityType
                >);

          // skip this list
          if (!groupEntity) {
            hasHiddenLists = true;
            return acc;
          }

          const listId = serializeId({
            itemType: groupEntityType,
            ids: [orderId],
            id: undefined
          });

          const totalLoadableCount = groupTotalCounts?.[orderId] || 0;
          const subOrder = ordersByGroup[orderId] || [];

          const isArchivedShowingForThisGroup =
            isArchivedShowingHash?.[orderId];

          // will be 0 when archived are showing
          const numArchived = numArchivedByGroup?.[orderId] ?? 0;

          const listItems = listItemsBuilder({
            order: subOrder,
            parentGroupId: orderId,
            filterListType,
            filterValues
          });

          const _toggleIsArchivedShowing = () =>
            toggleIsArchivedShowing?.(orderId);

          const makeShowArchivedRowForThisGroup = ({
            isShowingArchived
          }: {
            isShowingArchived?: boolean;
          } = {}) =>
            makeShowArchivedRow({
              numArchived,
              paddingLeft: 50,
              toggleIsArchivedShowing: _toggleIsArchivedShowing,
              isShowingArchived
            });

          const listItemsToUse: (
            | typeof listItems[0]
            | ReturnType<typeof makeShowArchivedRow>
          )[] = [...listItems];

          if (isArchivedShowingForThisGroup) {
            listItemsToUse.push(
              makeShowArchivedRowForThisGroup({ isShowingArchived: true })
            );
          } else if (numArchived > 0) {
            listItemsToUse.push(makeShowArchivedRowForThisGroup());
          }

          const isAllSelected = isAllSelectedByGroup?.[orderId] ?? false;

          const isListOpen = getIsOpen({
            parentId: filterListType,
            toggleId: listId
          });

          const list: GroupedList = {
            entity: groupEntity,
            entityType: groupEntityType,
            filterListType,
            toggleSelect: () => toggleSelectGroup?.(orderId),
            isSelected: isAllSelected,
            numSelectedItems: numSelectedByGroup?.[orderId] ?? 0,
            clearSelectedItems,
            selectAllItems,
            selectionLimit,
            isUnableToSelectMoreItems,
            rowHeight: 58,
            rowType:
              filterListTypeToGroupHeaderRow[filterListType] ||
              ROW_TYPES.defaultFilterGroupHeaderRow,
            isList: true,
            id: listId,
            sectionId: serializeSectionIds([filterListType, listId]),
            stickyHeaderOffset,
            listItems: listItemsToUse,
            isFullyLoaded: hasNextPageByGroup
              ? !hasNextPageByGroup[orderId]
              : true,
            totalCount: totalLoadableCount,
            addEmptyRow: isListOpen,
            toggleCollapse: () =>
              toggleCollapse({ parentId: filterListType, toggleId: listId }),
            isOpen: isListOpen ?? false,
            labelText: getGroupEntityLabel(orderId)
          };

          acc[orderId] = list;

          return acc;
        },
        {}
      );

      const orderedLists = order.map((id) => lists[id]);
      return (
        hasHiddenLists ? orderedLists.filter((list) => list) : orderedLists
      ) as GroupedList[];
    },
    [getIsOpen, listItemsBuilder, toggleCollapse]
  );

  return groupedListsBuilder;
};

/* ------------------------------------ - ----------------------------------- */

const filterListTypeToGroupHeaderRow = {
  [FilterListType.SkillLevels]: ROW_TYPES.skillHeaderRow
};
