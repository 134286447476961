import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType, isNumeric } from './utils';

import {
  RowValidatorWithRequiredAdditionalParams,
  CsvImportPtoPolicy
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingPtoPoliciesGroupedByHours: Record<string, CsvImportPtoPolicy[]>;
  existingPtoPoliciesByDescription: Record<string, CsvImportPtoPolicy>;
  newPtoHours: React.MutableRefObject<Record<string, boolean>>;
  newPtoDescriptionHoursHashes: React.MutableRefObject<Record<string, string>>;
}

export const ptoHoursValidator: RowValidatorWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const {
    existingPtoPoliciesGroupedByHours,
    existingPtoPoliciesByDescription,
    newPtoHours,
    newPtoDescriptionHoursHashes
  } = additionalParams;

  const { enteredPtoHours, enteredPtoDescription } = getRowValuesForEntityType(
    row,
    entityType
  );

  if (enteredPtoHours === TRAINING_DATA_REQUIRED_STRING) return false;
  // check if entered string is numeric
  if (enteredPtoHours && !isNumeric(enteredPtoHours))
    return ERROR_MESSAGES.invalidPtoHours;
  const existingPtoPoliciesMatchedByEnteredPtoHours =
    existingPtoPoliciesGroupedByHours[enteredPtoHours];

  const enteredPtoHoursIsNewOption =
    enteredPtoHours &&
    !existingPtoPoliciesMatchedByEnteredPtoHours &&
    !newPtoHours.current[enteredPtoHours];

  if (
    enteredPtoDescription &&
    enteredPtoDescription !== TRAINING_DATA_REQUIRED_STRING
  ) {
    if (!enteredPtoHours) return ERROR_MESSAGES.ptoHoursRequired;
    else {
      // check if enteredPtoHours matches to existing one with enteredPtoDescription
      const existingPtoPolicyByEnteredPtoDescription =
        existingPtoPoliciesByDescription[enteredPtoDescription];
      const newPtoHoursByEnteredPtoDescription =
        newPtoDescriptionHoursHashes.current[enteredPtoDescription];
      if (
        existingPtoPolicyByEnteredPtoDescription === undefined &&
        newPtoHoursByEnteredPtoDescription === undefined
      ) {
        // entered pto description is new => make enteredPtoHours + enteredPtoDescription new combination is being handled in pto description validator.
        return true;
      } else if (
        // entered pto description is not new, check if enteredPtoHours matches
        existingPtoPolicyByEnteredPtoDescription?.hours === enteredPtoHours ||
        newPtoHoursByEnteredPtoDescription === enteredPtoHours
      )
        return true;
      else return ERROR_MESSAGES.ptoHoursDoesNotMatchDescription;
    }
  } else if (
    // no description entered, add entered pto hours as new option if it was not an option before.
    enteredPtoHoursIsNewOption
  )
    newPtoHours.current[enteredPtoHours] = true;
  return true;
};
