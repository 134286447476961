import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TimesheetContainer } from 'views';
import { getAllTeamMembers, getMatchedRouteParams } from 'selectors';
import {
  setSelectedTeamMember,
  setProjectPlannerMember,
  fetchMemberProjects
} from 'actionCreators';

class ProfileContainer extends React.Component {
  componentDidMount() {
    const { matchedParams } = this.props;
    const { teamMemberId } = matchedParams;
    if (teamMemberId) {
      this.loadData();
    }
  }

  getMember = () => {
    const { matchedParams, teamMembers } = this.props;
    const { teamMemberId } = matchedParams;
    const member =
      teamMembers && teamMembers.find((member) => member.id === +teamMemberId);
    return member;
  };

  componentDidUpdate(prevProps) {
    const { matchedParams } = this.props;
    const { teamMemberId } = matchedParams;
    if (teamMemberId !== prevProps.matchedParams.teamMemberId) {
      this.loadData();
    }
  }

  loadData = () => {
    const {
      setSelectedTeamMember,
      setProjectPlannerMember,
      fetchMemberProjects
    } = this.props;
    const member = this.getMember();
    if (!member) {
      return;
    }
    setSelectedTeamMember({
      selectedMemberId: member.id,
      selectedAccountId: member.account.id
    });
    setProjectPlannerMember({ memberId: member.account.id });
    fetchMemberProjects({ account_id: member.account.id });
  };

  render() {
    return <TimesheetContainer />;
  }
}
const mapStateToProps = (state) => ({
  matchedParams: getMatchedRouteParams(state),
  teamMembers: getAllTeamMembers(state)
});

const mapDispatchToProps = {
  setSelectedTeamMember,
  setProjectPlannerMember,
  fetchMemberProjects
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
);
