import styled from 'styled-components';

const ProjectTeamListHeader = () => {
  return <Container></Container>;
};

export default ProjectTeamListHeader;

const Container = styled.div`
  display: grid;
  grid-template-columns: 232px 103px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  margin-bottom: 1rem;
  padding-left: 1rem;

  .legend-item-container {
    font-size: 14px;
    font-weight: 600;
  }
`;
