import cn from 'classnames';
import styled from 'styled-components';
import { MemberInitials } from 'views';
import theme from 'theme';
import { teamUtils } from 'TeamsModule/utils';
import { usePopover } from 'components/Popover/usePopover';
import LockIcon from 'icons/LockIcon';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { navigateToMembersSettings } from 'actionCreators';
import { getTeamSlug } from 'selectors';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

export const EditableNameCell = ({ row }) => {
  const { member, onRequestEditMemberMetadata } = row.original;

  const { Popover, openPopover } = usePopover();
  const teamSlug = useAppSelector(getTeamSlug);
  const dispatch = useAppDispatch();

  const handleClickManageMembers = () => {
    if (teamSlug) {
      dispatch(
        navigateToMembersSettings({
          teamSlug
        })
      );
    }
  };

  return (
    <>
      <BaseCellRootContainer
        onClick={() =>
          onRequestEditMemberMetadata({ teamMembershipId: member.id })
        }
      >
        <ContentContainer>
          <MemberInitials
            member={member}
            size="medium"
            classes={cn('regular-member-no-hover selected')}
          />
          <div style={{ paddingLeft: '8px', width: '208px' }}>
            <TextContainer
              $isArchived={teamUtils.getIsArchived(member)}
              className="text-container"
            >
              {member.account.name}
            </TextContainer>
            {member.pending_email && (
              <PendingText
                onClick={(e) => {
                  e.stopPropagation();
                  openPopover({ event: e });
                }}
              >
                Pending
              </PendingText>
            )}
          </div>
        </ContentContainer>
      </BaseCellRootContainer>
      <Popover>
        <PendingMemberPopoverContainer onClick={handleClickManageMembers}>
          <GoToProjectIcon color={theme.colors.colorMediumGray9} />
          <span>Manage Members</span>
          <LockIcon fill={theme.colors.colorMediumGray9} />
        </PendingMemberPopoverContainer>
      </Popover>
    </>
  );
};

const TextContainer = styled.div<{ $isArchived: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  /* font-size: 12px;
    width: 100%;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    padding: 4px 0px;
    margin-left: 0px;
    cursor: pointer;
    text-overflow: ellipsis;
    opacity: 1; */
`;

const PendingText = styled.span`
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorDeleteRed};
`;

const PendingMemberPopoverContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 15px 18px;
  gap: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.colorPaleGray7};
  }
`;

const BaseCellRootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  color: ${theme.colors.colorSemiDarkGray1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  &:hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;
