import SvgIcon from 'components/SvgIcon';

const TaskGroupCollapseIcon = ({
  width = '32',
  height = '32',
  className = '',
  fill = '#A4A4A4'
}) => (
  <SvgIcon
    className={className}
    viewBox="0 0 32 32"
    fill="none"
    width={width}
    height={height}
  >
    <rect
      x="4.5"
      y="4.5"
      width="23"
      height="23"
      rx="11.5"
      fill="white"
      stroke="#D9D9D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 20.9358L17.4044 19.4007L20.899 15.5811C21.0328 15.435 21.0328 15.1974 20.899 15.0506L19.9799 14.046C19.8462 13.8991 19.6288 13.8991 19.4951 14.046L16.0005 17.8655L12.5052 14.046C12.3709 13.8991 12.1541 13.8991 12.0203 14.046L11.1012 15.0506C10.9669 15.1974 10.9669 15.4343 11.1012 15.5811L14.5953 19.4007L15.9999 20.9358Z"
      fill={fill}
    />
  </SvgIcon>
);

export default TaskGroupCollapseIcon;
