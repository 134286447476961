import React from 'react';
import DragGripIcon from 'icons/DragGripIcon';
import { StyledDragGrips } from './styles';
const MilestoneTableGripCell = ({ row, phase }) => {
  const isDisabled = !!row.original?.sortType;
  if (phase?.archived) {
    return (
      <StyledDragGrips isDisabled>
        <DragGripIcon style={{ visibility: 'hidden' }} />
      </StyledDragGrips>
    );
  }
  return (
    <StyledDragGrips isDisabled={isDisabled}>
      <DragGripIcon />
    </StyledDragGrips>
  );
};

export default MilestoneTableGripCell;
