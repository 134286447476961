import { select } from 'redux-saga/effects';
import { changeEntity } from 'sagas/generics';
import { getAuthToken } from 'AuthenticationModule/selectors';
import * as actions from '../actionCreators';
import * as orttoApi from '../api/ortto';

export function* updateOrttoUserWorker(
  action: ReturnType<typeof actions.updateOrttoUser>
) {
  const token = yield select(getAuthToken);

  yield changeEntity(
    actions.updateOrttoUserActionCreatorsMap,
    orttoApi.updateOrttoUser,
    [token, action.payload],
    action
  );
}
