import React from 'react';
import SvgIcon from 'components/SvgIcon';

const CheckMarkIcon = ({
  fill = '#333333',
  width = '16',
  height = '12',
  className
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 12"
    fillRule="none"
  >
    <path
      d="M14.2474 0.75L13.4209 1.58374C11.1428 3.86647 8.71198 6.45817 6.43648 8.77298L3.29155 6.18412L2.38468 5.43814L0.900024 7.24459L1.7996 7.99057L5.77815 11.267L6.60456 11.9472L7.35054 11.1939C9.87107 8.66795 12.6018 5.71423 15.0736 3.23676L15.9 2.40302L14.2474 0.75Z"
      fill={fill}
    />
  </SvgIcon>
);

export default CheckMarkIcon;
