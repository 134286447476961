import { ValueOf } from 'type-fest';
import styled from 'styled-components';
import theme from 'theme';
import { deserializeId } from 'appUtils';
import { memberCapacitiesTableConstants } from '../constants';
import CollapseArrow from 'components/CollapseArrow';
import { BaseTableList } from 'components/Table/types';

const { membersTypes } = memberCapacitiesTableConstants;

const memberTypeToLabelText: Record<ValueOf<typeof membersTypes>, string> = {
  [membersTypes.regularMembers]: 'MEMBER',
  [membersTypes.guests]: 'GUESTS'
};

export const MembersCapacityTableMemberTypeSectionHeaderCell = ({ row }) => {
  const { id, toggleCollapse, isOpen } = row.original as BaseTableList;
  const { itemId: memberType } = deserializeId(id);

  return (
    <RootContainer>
      <CollapseArrow toggleCollapse={toggleCollapse} isCollapsed={!isOpen} />
      {memberTypeToLabelText[memberType]}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray9};
`;
