import * as constants from 'appConstants';
import { Sections, sectionsWithLastLocation } from 'appConstants/navigation';
import {
  calcBackDestination,
  calcUpDestinationIndex
} from 'appUtils/navigation';
import omit from 'lodash/omit';

const emptyArray = [];
const emptyObject = {};
export const initialState = {
  history: emptyArray,
  navigationHistory: emptyArray,
  navigation: emptyObject,
  isNavigationBlocked: false,
  matchedParams: emptyObject,
  prevMatchedParams: emptyObject,
  launchDarklyFlags: emptyObject,
  navigatingTo: emptyObject,
  lastLocations: {}
};

const routeHistory = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.LOCATION_CHANGE: {
      return {
        ...state,
        history: [...state.history, payload]
      };
    }
    case constants.NAVIGATE_UP_CLEANUP: {
      const index = calcUpDestinationIndex(state.navigationHistory);
      let newNavigation = {};
      if (index >= state.navigationHistory.length) {
        // we should navigate to home when we reach the end
        newNavigation.section = Sections.HOME;
        newNavigation.view = null;
        newNavigation.modal = null;
      } else {
        newNavigation = state.navigationHistory[index];
      }
      const updatedNavigationHistory = state.navigationHistory.slice(index);
      return {
        ...state,
        navigation: newNavigation || emptyObject,
        navigationHistory: updatedNavigationHistory
      };
    }
    case constants.NAVIGATE_BACK_CLEANUP: {
      // This function will send you back to one these
      // - prev project you were on
      // - the first non-project section you were on
      // - home
      // Then find the next one, if any, that has a different projectId
      let newNavigation = {};
      // we should navigate to home as fallback
      newNavigation.section = Sections.HOME;
      newNavigation.view = null;
      newNavigation.modal = null;

      const { destination, index } = calcBackDestination(
        state.navigationHistory
      );
      newNavigation = destination || newNavigation;

      const updatedNavigationHistory = state.navigationHistory.slice(index);
      return {
        ...state,
        navigation: newNavigation || emptyObject,
        navigationHistory: updatedNavigationHistory
      };
    }
    case constants.NAVIGATE_TO: {
      return {
        ...state,
        navigatingTo: action.payload
      };
    }
    case constants.NAVIGATION_CHANGE: {
      const { match, section, view, modal, updateTime } = payload;
      const newNavigation = { match };

      if (section) {
        newNavigation.section = section;
        newNavigation.view = null;
        newNavigation.modal = null;
      }
      if (view) {
        newNavigation.section = section || state.navigation.section;
        newNavigation.view = view;
        newNavigation.modal = null;
      }
      if (modal) {
        newNavigation.section = section || state.navigation.section;
        newNavigation.view = view || state.navigation.view;
        newNavigation.modal = modal;
      }
      return {
        ...state,
        navigation: newNavigation,
        navigationHistory: [newNavigation, ...state.navigationHistory],
        ...(sectionsWithLastLocation[section] && {
          lastLocations: {
            ...state.lastLocations,
            [section]: {
              location: view,
              updateTime
            }
          }
        })
      };
    }
    case constants.SET_NAVIGATION_BLOCKED: {
      const { isBlocked } = payload;
      return {
        ...state,
        isNavigationBlocked: isBlocked
      };
    }
    case constants.ADD_PARSED_ROUTED_PARAMS: {
      return {
        ...state,
        prevMatchedParams: { ...state.matchedParams },
        matchedParams: { ...state.matchedParams, ...payload }
      };
    }
    case constants.REMOVE_PARSED_ROUTED_PARAMS: {
      return {
        ...state,
        prevMatchedParams: { ...state.matchedParams },
        matchedParams: omit(state.matchedParams, Object.keys(payload))
      };
    }
    case constants.RESET_PARSED_ROUTED_PARAMS: {
      return {
        ...state,
        prevMatchedParams: { ...state.matchedParams },
        matchedParams: emptyObject
      };
    }
    case constants.SET_LD_FLAGS: {
      return {
        ...state,
        launchDarklyFlags: payload
      };
    }
    default:
      return state;
  }
};

export default routeHistory;
