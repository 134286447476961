import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { getMe } from 'selectors';

const ProjectDetailBlock = ({
  title,
  handleClick,
  className,
  me,
  children
}) => {
  const childrenWithProps = React.Children.map(children, (child) =>
    child ? React.cloneElement(child, { me }) : null
  );

  return (
    <div
      title={title || 'Click to view all project updates'}
      onClick={handleClick}
      className={cn('detail-block', className)}
    >
      {childrenWithProps}
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: getMe(state)
});

export default connect(mapStateToProps)(ProjectDetailBlock);
