import React from 'react';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useRowState
} from 'react-table';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Droppable } from 'react-beautiful-dnd';

import TableRow from './TableRow';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
const noop = () => {};

const Table = ({
  virtual = false,
  columns,
  data,
  showFooter = false,
  itemHeight = 41,
  maxHeight = 500,
  loadMoreItems = noop,
  showHeader,
  autoResetRowState = false,
  columnMaxWidth,
  stateReducer,
  overscanCount = 10,
  itemCount = 2000,
  totalColumnsWidthOverride,
  headersToIgnore,
  customRowProps,
  classNames // : { list?: string }
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: columnMaxWidth || 600
    }),
    [columnMaxWidth]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetRowState,
      stateReducer
    },
    useBlockLayout,
    useResizeColumns,
    useRowState
  );

  const tableBodyProps = getTableBodyProps();

  const rowProps = React.useMemo(() => {
    return {
      rows,
      columns,
      prepareRow,
      tableBodyProps
    };
  }, [rows, columns, prepareRow, tableBodyProps]);

  const isItemLoaded = React.useCallback(
    (index) => {
      return index < rows.length;
    },
    [rows.length]
  );

  return (
    <div {...getTableProps()} className="table">
      {showHeader && (
        <TableHeader
          headerGroups={headerGroups}
          headersToIgnore={headersToIgnore}
          totalColumnsWidthOverride={totalColumnsWidthOverride}
          customRowProps={customRowProps}
        />
      )}
      <Droppable
        droppableId={'droppable'}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <TableRow index={rubric.source.index} rowProps={rowProps} />
        )}
      >
        {(droppableProvided) => (
          <div
            className="table-body"
            {...tableBodyProps}
            ref={droppableProvided.innerRef}
          >
            {virtual ? (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                threshold={30}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    onItemsRendered={onItemsRendered}
                    height={Math.min(rows.length * itemHeight, maxHeight)}
                    itemCount={rows.length}
                    itemSize={itemHeight}
                    width={totalColumnsWidthOverride || totalColumnsWidth}
                    outerRef={droppableProvided.innerRef}
                    itemData={rowProps}
                    ref={ref}
                    overscanCount={overscanCount}
                    className={classNames?.list}
                  >
                    {TableRow}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            ) : (
              rows.map((row, index) => (
                <TableRow
                  index={index}
                  data={rowProps}
                  key={row.original.id || index}
                />
              ))
            )}
          </div>
        )}
      </Droppable>
      {showFooter && <TableFooter headerGroups={headerGroups} />}
    </div>
  );
};

export default Table;
