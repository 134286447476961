import { put, select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { getAuthToken } from 'selectors';

const {
  projectColorPreferencesFetch,
  projectColorPreferenceUpdate,
  projectColorPreferenceCreate,
  projectColorPreferenceDelete,
  boardPreferencesFetch,
  boardPreferenceUpdate,
  boardPreferenceCreate,
  boardPreferenceDelete,
  taskGroupPreferencesFetch,
  taskGroupPreferenceUpdate,
  taskGroupPreferenceCreate,
  taskGroupPreferenceDelete,
  teamMembershipPreferencesFetch,
  teamMembershipPreferenceUpdate,
  teamMembershipPreferenceCreate,
  teamMembershipPreferenceDelete,
  teamPreferencesFetch,
  teamPreferenceUpdate,
  teamPreferenceCreate,
  teamPreferenceDelete,
  tagPreferencesFetch,
  tagPreferenceUpdate,
  tagPreferenceCreate,
  tagPreferenceDelete
} = entityActions;

export function* fetchProjectColorPreferences(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectColorPreferencesFetch,
    api.fetchProjectColorPreferences,
    undefined,
    [token],
    action
  );
}

export function* updateProjectColorPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    projectColorPreferenceUpdate,
    api.updateProjectColorPreference,
    [token, id, color],
    action
  );
}

export function* createProjectColorPreference(action) {
  const { id, color } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    projectColorPreferenceCreate,
    api.createProjectColorPreference,
    [token, id, color],
    action
  );
}

export function* deleteProjectColorPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    projectColorPreferenceDelete,
    api.deleteProjectColorPreference,
    [token, id, color],
    action
  );
}

export function* fetchBoardPreferences(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    boardPreferencesFetch,
    api.fetchBoardPreferences,
    undefined,
    [token],
    action
  );
}

export function* updateBoardPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    boardPreferenceUpdate,
    api.updateBoardPreference,
    [token, id, color],
    action
  );
}

export function* createBoardPreference(action) {
  const { id, color } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    boardPreferenceCreate,
    api.createBoardPreference,
    [token, id, color],
    action
  );
}

export function* deleteBoardPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    boardPreferenceDelete,
    api.deleteBoardPreference,
    [token, id, color],
    action
  );
}

export function* fetchTaskGroupPreferences(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    taskGroupPreferencesFetch,
    api.fetchTaskGroupPreferences,
    undefined,
    [token],
    action
  );
}

export function* updateTaskGroupPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    taskGroupPreferenceUpdate,
    api.updateTaskGroupPreference,
    [token, id, color],
    action
  );
}

export function* createTaskGroupPreference(action) {
  const { id, color } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    taskGroupPreferenceCreate,
    api.createTaskGroupPreference,
    [token, id, color],
    action
  );
}

export function* deleteTaskGroupPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    taskGroupPreferenceDelete,
    api.deleteTaskGroupPreference,
    [token, id, color],
    action
  );
}

export function* fetchTeamMembershipPreferences(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    teamMembershipPreferencesFetch,
    api.fetchTeamMembershipPreferences,
    undefined,
    [token],
    action
  );
}

export function* updateTeamMembershipPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    teamMembershipPreferenceUpdate,
    api.updateTeamMembershipPreference,
    [token, id, color],
    action
  );
}

export function* createTeamMembershipPreference(action) {
  const { id, color } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    teamMembershipPreferenceCreate,
    api.createTeamMembershipPreference,
    [token, id, color],
    action
  );
}

export function* deleteTeamMembershipPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    teamMembershipPreferenceDelete,
    api.deleteTeamMembershipPreference,
    [token, id, color],
    action
  );
}

export function* fetchTeamPreferences(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    teamPreferencesFetch,
    api.fetchTeamPreferences,
    undefined,
    [token],
    action
  );
}

export function* updateTeamPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    teamPreferenceUpdate,
    api.updateTeamPreference,
    [token, id, color],
    action
  );
}

export function* createTeamPreference(action) {
  const { id, color } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    teamPreferenceCreate,
    api.createTeamPreference,
    [token, id, color],
    action
  );
}

export function* deleteTeamPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    teamPreferenceDelete,
    api.deleteTeamPreference,
    [token, id, color],
    action
  );
}

export function* fetchTagPreferences(action) {
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    tagPreferencesFetch,
    api.fetchTagPreferences,
    undefined,
    [token],
    action
  );
}

export function* updateTagPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    tagPreferenceUpdate,
    api.updateTagPreference,
    [token, id, color],
    action
  );
}

export function* createTagPreference(action) {
  const { id, color } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    tagPreferenceCreate,
    api.createTagPreference,
    [token, id, color],
    action
  );
}

export function* deleteTagPreference(action) {
  const { id, color } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    tagPreferenceDelete,
    api.deleteTagPreference,
    [token, id, color],
    action
  );
}
