import { useCallback } from 'react';
import {
  getProjectIdsThatMatchTitleAndNumber,
  getRowValuesForEntityType
} from './utils';
import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';

// activity_title is required: for project that already exists, validate against project title and number
// otherwise, just non-empty is valid
// Note: schema hasn't been changed yet, so if user does not touch this column, validation won't run.
// Therefore tied the non-empty validation to the project cell in useProjectTitleValidator as well
export const useActivityValidator = ({
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  existingPhasesGroupedByName,
  existingActivitiesById,
  newActivityTitles,
  entityType
}) => {
  return useCallback(
    (rows) =>
      validateRows(
        rows,
        existingActivitiesById,
        existingPhasesGroupedByName,
        existingProjectsHashByNumber,
        existingProjectsHashByTitle,
        newActivityTitles,
        entityType
      ),
    [
      existingActivitiesById,
      existingPhasesGroupedByName,
      existingProjectsHashByNumber,
      existingProjectsHashByTitle,
      newActivityTitles,
      entityType
    ]
  );
};

/* ------------------------------------ - ----------------------------------- */

export const validateRows = (
  rows,
  existingActivitiesById,
  existingPhasesGroupedByName,
  existingProjectsHashByNumber,
  existingProjectsHashByTitle,
  newActivityTitles,
  entityType
) => {
  const rowValidationResults = [];
  let nextNewActivityTitles = {};

  const pushResult = (result) => rowValidationResults.push(result);

  rows.forEach((row) => {
    const {
      enteredProjectTitle,
      enteredProjectNumber,
      enteredPhaseName,
      enteredActivityTitle
    } = getRowValuesForEntityType(row, entityType);

    if (!enteredProjectTitle && !enteredProjectNumber && !enteredPhaseName) {
      pushResult('Project title, project number, and phase name are required');
      return;
    }
    if (!enteredProjectTitle && !enteredProjectNumber) {
      pushResult('Project title and number are required');
      return;
    }
    if (!enteredProjectTitle && !enteredPhaseName) {
      pushResult('Project title and phase name are required');
      return;
    }
    if (!enteredProjectNumber && !enteredPhaseName) {
      pushResult('Project number and phase name are required');
      return;
    }
    if (!enteredProjectTitle) {
      pushResult('Project title is required');
      return;
    }
    if (!enteredProjectNumber) {
      pushResult('Project number is required');
      return;
    }
    if (enteredActivityTitle === TRAINING_DATA_REQUIRED_STRING) {
      pushResult(false);
      return;
    }
    if (enteredPhaseName === TRAINING_DATA_REQUIRED_STRING) {
      pushResult(false);
      return;
    }

    const projectIdsThatMatchTitleAndNumber =
      getProjectIdsThatMatchTitleAndNumber({
        existingProjectsHashByTitle,
        existingProjectsHashByNumber,
        enteredProjectTitle,
        enteredProjectNumber
      });

    // in case of empty entered phase name, we want to treat it as a default phase, thus name with 'Default 1'
    const matchingPhasesByEnteredPhaseName =
      existingPhasesGroupedByName[enteredPhaseName || 'Default 1'];
    const matchingPhase = matchingPhasesByEnteredPhaseName?.find(
      (phase) => projectIdsThatMatchTitleAndNumber[phase.project_id]
    );

    const matchingPhaseHasActivityPhase =
      matchingPhase && matchingPhase.activity_order.length;

    const nextNewActivityTitlesMatchedByEnteredPhase =
      nextNewActivityTitles[enteredProjectTitle]?.[enteredProjectNumber]?.[
        enteredPhaseName
      ];

    const nextNewAcitivityTitlesHasNonEmptyActivityTitleMatchedByEnteredPhase =
      nextNewActivityTitlesMatchedByEnteredPhase &&
      Object.keys(nextNewActivityTitlesMatchedByEnteredPhase).some(
        (activityTitle) => activityTitle !== ''
      );

    const newActivityTitlesMatchedByEnteredPhase =
      newActivityTitles.current[enteredProjectTitle]?.[enteredProjectNumber]?.[
        enteredPhaseName
      ];
    const newActivityTitlesHasNonEmptyActivityTitleMatchedByEnteredPhase =
      newActivityTitlesMatchedByEnteredPhase &&
      Object.keys(newActivityTitlesMatchedByEnteredPhase).some(
        (activityTitle) => activityTitle !== ''
      );

    const existsNonEmptyActivityTitleForEnteredPhase =
      nextNewAcitivityTitlesHasNonEmptyActivityTitleMatchedByEnteredPhase ||
      newActivityTitlesHasNonEmptyActivityTitleMatchedByEnteredPhase;

    if (!enteredActivityTitle) {
      // there exists activity phase for this phase already in the team => it can not be empty
      if (matchingPhaseHasActivityPhase) {
        pushResult(ERROR_MESSAGES.workCategoryRequiredForPhase);
        return;
      } else if (existsNonEmptyActivityTitleForEnteredPhase) {
        // there exists some row in the table that has non empty activity title for this phase
        pushResult(ERROR_MESSAGES.workCategoryRequiredForRow);
        return;
      }
    }

    const matchedPhaseHasEnteredActivityTitle =
      matchingPhase &&
      matchingPhase.activity_order
        .map((id) => existingActivitiesById[id]?.title)
        .filter((title) => title)
        .includes(enteredActivityTitle);
    // only add to the hash if it did not exist in the phase already and not empty
    if (!matchedPhaseHasEnteredActivityTitle && enteredActivityTitle) {
      nextNewActivityTitles = {
        ...nextNewActivityTitles,
        [enteredProjectTitle]: {
          ...nextNewActivityTitles[enteredProjectTitle],
          [enteredProjectNumber]: {
            ...nextNewActivityTitles[enteredProjectTitle]?.[
              enteredProjectNumber
            ],
            [enteredPhaseName]: {
              ...nextNewActivityTitles[enteredProjectTitle]?.[
                enteredProjectNumber
              ]?.[enteredPhaseName],
              [enteredActivityTitle]: true
            }
          }
        }
      };
    }

    pushResult(true);
  });
  newActivityTitles.current = nextNewActivityTitles;
  return rowValidationResults;
};
