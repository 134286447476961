import theme from 'theme';
import pickBy from 'lodash/pickBy';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';
import { DATA_KEY } from 'UtilizationModule/constants';
import { ZOOM_LEVELS } from 'appConstants/workload';
import moment from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  generateDateRange,
  DATE_RANGES
} from 'appUtils/dateRangeHelpers';
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from './constants';

const tableWidthColumn = {
  headerType: 'tableWidth',
  headerLabel: '',
  id: 'tableWidth',
  align: 'center',
  accessor: (row) => row
};

const sundayColumn = {
  headerType: 'sunday',
  headerLabel: 'S',
  id: 'sunday',
  align: 'center',
  accessor: (row) => row.week?.[0]
};
const mondayColumn = {
  headerType: 'monday',
  headerLabel: 'M',
  id: 'monday',
  align: 'center',
  accessor: (row) => row.week?.[1]
};
const tuesdayColumn = {
  headerType: 'tuesday',
  headerLabel: 'T',
  id: 'tuesday',
  align: 'center',
  accessor: (row) => row.week?.[2]
};
const wednesdayColumn = {
  headerType: 'wednesday',
  headerLabel: 'W',
  id: 'wednesday',
  align: 'center',
  accessor: (row) => row.week?.[3]
};
const thursdayColumn = {
  headerType: 'thursday',
  headerLabel: 'T',
  id: 'thursday',
  align: 'center',
  accessor: (row) => row.week?.[4]
};
const fridayColumn = {
  headerType: 'friday',
  headerLabel: 'F',
  id: 'friday',
  align: 'center',
  accessor: (row) => row.week?.[5]
};
const saturdayColumn = {
  headerType: 'saturday',
  headerLabel: 'S',
  id: 'saturday',
  align: 'center',
  accessor: (row) => row.week?.[6]
};
const totalsColumn = {
  headerType: 'totals',
  headerLabel: 'TOTAL',
  id: 'totals',
  align: 'center',
  accessor: (row) => row
};

const leftPaddingColumn = {
  headerType: 'leftPadding',
  headerLabel: '',
  id: 'leftPadding',
  align: 'center',
  accessor: (row) => row
};
const rightPaddingColumn = {
  headerType: 'rightPadding',
  headerLabel: '',
  id: 'rightPadding',
  align: 'center',
  accessor: (row) => row
};

const memberColumn = {
  headerType: 'member',
  headerLabel: '',
  id: 'member',
  align: 'left',
  accessor: (row) => row
};

const getWeekTotalColumns = () =>
  [...Array(6)].map((_, index) => ({
    headerType: `weekTotal${index}`,
    headerLabel: '',
    id: `weekTotal${index}`,
    weekIndex: index,
    align: 'center',
    accessor: (row) => row
  }));

export const columnWidths = {
  leftPadding: 42,
  tableWidth: 800,
  sunday: 100,
  monday: 100,
  tuesday: 100,
  wednesday: 100,
  thursday: 100,
  friday: 100,
  saturday: 100,
  totals: 100,
  rightPadding: 60,
  member: 275,
  weekTotal0: 68,
  weekTotal1: 68,
  weekTotal2: 68,
  weekTotal3: 68,
  weekTotal4: 68,
  weekTotal5: 68
};

export const totalWidth =
  columnWidths.leftPadding +
  columnWidths.tableWidth +
  columnWidths.rightPadding;

export const timesheetStatusReportColumns = [
  leftPaddingColumn,
  memberColumn,
  ...getWeekTotalColumns(),
  tableWidthColumn,
  sundayColumn,
  mondayColumn,
  tuesdayColumn,
  wednesdayColumn,
  thursdayColumn,
  fridayColumn,
  saturdayColumn,
  totalsColumn,
  rightPaddingColumn
];

export const ITEM_HEIGHT = 65;
export const MAX_TABLE_HEIGHT_BUFFER = 190;

export const getActiveFilterParams = (filter) =>
  pickBy(filter, (value) => Array.isArray(value) && value.length);

export const getItemsByStatusView = (items, statusView) => {
  switch (statusView) {
    case TIMESHEET_STATUSES.SUBMITTED:
      return [
        items[TIMESHEET_STATUSES.NOT_ENTERED],
        items[TIMESHEET_STATUSES.UNSUBMITTED],
        items[TIMESHEET_STATUSES.SUBMITTED],
        items[TIMESHEET_STATUSES.REJECTED],
        items[DATA_KEY.HOLIDAY]
      ];
    case TIMESHEET_STATUSES.APPROVED:
      return [
        items[TIMESHEET_STATUSES.NOT_ENTERED],
        items[TIMESHEET_STATUSES.UNAPPROVED],
        items[TIMESHEET_STATUSES.APPROVED],
        items[TIMESHEET_STATUSES.REJECTED],
        items[DATA_KEY.HOLIDAY]
      ];
    // default statusView is Entered
    case TIMESHEET_STATUSES.ENTERED:
    default:
      return [
        items[TIMESHEET_STATUSES.NOT_ENTERED],
        items[TIMESHEET_STATUSES.ENTERED],
        items[TIMESHEET_STATUSES.REJECTED],
        items[DATA_KEY.HOLIDAY]
      ];
  }
};

export const getEnteredTimeByStatusView = (data, statusView) => {
  switch (statusView) {
    case TIMESHEET_STATUSES.SUBMITTED: {
      return +data[DATA_KEY.NOT_SUBMITTED] || 0;
    }

    case TIMESHEET_STATUSES.APPROVED: {
      return +data[DATA_KEY.NOT_SUBMITTED] + +data[DATA_KEY.SUBMITTED] || 0;
    }

    case TIMESHEET_STATUSES.ENTERED:
    default: {
      return (
        +data[DATA_KEY.NOT_SUBMITTED] +
          +data[DATA_KEY.APPROVED] +
          +data[DATA_KEY.SUBMITTED] || 0
      );
    }
  }
};

export const getFormattedChartData = ({
  data = {},
  capacity,
  statusView,
  isSummary,
  notEnteredColor = theme.colors.colorPureWhite
}) => {
  // Only subtract holiday hours, treat PTO like regular project
  const actualCapacity = capacity
    ? Math.max(0, capacity - (data[DATA_KEY.HOLIDAY] || 0))
    : 0;

  const totalTimesheetHours = [
    DATA_KEY.APPROVED,
    DATA_KEY.SUBMITTED,
    DATA_KEY.NOT_SUBMITTED,
    DATA_KEY.REJECTED
  ].reduce((sum, cur) => {
    return sum + (+data[cur] || 0);
  }, 0);
  const entered = getEnteredTimeByStatusView(data, statusView);
  const notEntered = actualCapacity
    ? Math.max(0, actualCapacity - totalTimesheetHours)
    : 0;

  const itemsHash = {
    // displayValue = tooltips or labels. Usage eg. Entered 8h in tooltip includes rejected, but pie chart
    // needs to show rejected as separate item
    [TIMESHEET_STATUSES.NOT_ENTERED]: {
      name: TIMESHEET_STATUSES.NOT_ENTERED,
      value: notEntered,
      color: notEnteredColor,
      label: 'Not Entered'
    },
    [TIMESHEET_STATUSES.ENTERED]: {
      name: TIMESHEET_STATUSES.ENTERED,
      value: entered,
      displayValue: entered + (+data[DATA_KEY.REJECTED] || 0),
      color: theme.colors.colorRoyalBlue,
      label: 'Entered'
    },
    [TIMESHEET_STATUSES.UNSUBMITTED]: {
      name: TIMESHEET_STATUSES.UNSUBMITTED,
      value: +data[DATA_KEY.NOT_SUBMITTED] || 0,
      displayValue:
        +data[DATA_KEY.NOT_SUBMITTED] + +data[DATA_KEY.REJECTED] || 0,
      color: theme.colors.colorRoyalBlue,
      label: 'Unsubmitted'
    },
    [TIMESHEET_STATUSES.UNAPPROVED]: {
      name: TIMESHEET_STATUSES.UNAPPROVED,
      value: +data[DATA_KEY.NOT_SUBMITTED] + +data[DATA_KEY.SUBMITTED] || 0,
      displayValue:
        +data[DATA_KEY.NOT_SUBMITTED] +
          +data[DATA_KEY.SUBMITTED] +
          +data[DATA_KEY.REJECTED] || 0,
      color: theme.colors.colorMediumGray1,
      label: 'Unapproved'
    },
    // {
    //   name: 'pto',
    //   value: +data[DATA_KEY.PTO],
    //   color: theme.colors.colorMediumGray1,
    //   label: 'PTO'
    // },
    [DATA_KEY.HOLIDAY]: {
      name: 'holiday',
      value: +data[DATA_KEY.HOLIDAY] || 0,
      color: theme.colors.colorMediumGray1,
      label: 'Holiday'
    },
    [TIMESHEET_STATUSES.SUBMITTED]: {
      name: 'submitted',
      value: +data[DATA_KEY.SUBMITTED] + +data[DATA_KEY.APPROVED] || 0,
      color: theme.colors.colorRemainingTeal,
      label: 'Submitted'
    },
    [TIMESHEET_STATUSES.APPROVED]: {
      name: 'approved',
      value: +data[DATA_KEY.APPROVED] || 0,
      color: theme.colors.colorMediumGreen6,
      label: 'Approved'
    },
    [TIMESHEET_STATUSES.REJECTED]: {
      name: 'rejected',
      value: +data[DATA_KEY.REJECTED] || 0,
      color: theme.colors.colorCalendarRed,
      label: ''
    }
  };

  let items = getItemsByStatusView(itemsHash, statusView);

  if (isSummary) {
    items = items.filter((item) => !['holiday', 'pto'].includes(item.name));
  }
  return {
    ...itemsHash,
    totalTimesheetHours,
    items,
    isSummary,
    capacity: actualCapacity,
    isPto:
      !isSummary &&
      +data[DATA_KEY.PTO] > 0 &&
      +data[DATA_KEY.PTO] === actualCapacity,
    hasPto: !isSummary && +data[DATA_KEY.PTO] > 0,
    isHoliday: !isSummary && +data[DATA_KEY.HOLIDAY] > 0
  };
};

export const GENERATE_DATE_RANGE = {
  [ZOOM_LEVELS.MONTH]: () =>
    generateDateRange({ range: DATE_RANGES.THIS_MONTH }),
  custom: ({ start, end }) => ({
    start_date: start
      ? moment(start).format(DEFAULT_DATE_FORMAT)
      : DEFAULT_START_DATE,
    end_date: end ? moment(end).format(DEFAULT_DATE_FORMAT) : DEFAULT_END_DATE
  })
};
