import { calculateColorPref } from 'appUtils/styleUtils';
import styled from 'styled-components';
import { StyledReportHeader } from 'ReportsModule/components/styles';
import { DragClone } from 'components/Table/styles';

export const StyledTaskHeader = styled(StyledReportHeader)`
  border-bottom: 2px solid
    ${(props) => (props.isHideOwnColor ? '#cccccc' : calculateColorPref(props))};
  ${DragClone} & {
    border-bottom: 2px solid transparent;
    visibility: hidden;
  }
`;
