import React, { useContext, useRef } from 'react';
import { MilestoneModalContext } from 'contexts/MilestoneModalContext';
import DropdownThickIcon from 'icons/DropdownThickIcon';
import theme from 'theme';
import { StyledAddPhaseAndMilestoneButton } from './styles';
import AddMenu from './AddMenu';

const AddPhaseAndMilestoneButton = () => {
  const {
    setShowAddMenu,
    showAddMenu,
    clearShowAddMenu,
    handleAddingMilestone,
    setAddingPhase
  } = useContext(MilestoneModalContext);
  const addPhaseButtonRef = useRef(null);

  return (
    <StyledAddPhaseAndMilestoneButton
      ref={addPhaseButtonRef}
      onClick={setShowAddMenu}
    >
      Add <DropdownThickIcon fill={theme.colors.colorPureWhite} />
      {showAddMenu && (
        <AddMenu
          showAddMenu={showAddMenu}
          clearShowAddMenu={clearShowAddMenu}
          targetRef={addPhaseButtonRef}
          setAddingPhase={setAddingPhase}
          handleAddingMilestone={handleAddingMilestone}
        />
      )}
    </StyledAddPhaseAndMilestoneButton>
  );
};

// clearShowAddMenu, -> Done
// handleAddingMilestone,
// setAddingPhase
export default AddPhaseAndMilestoneButton;
