import React from 'react';
import { useSelector } from 'react-redux';
import { getMyUserId } from 'selectors';
import styled from 'styled-components';
import { TeamMemberCard } from 'views';
import { MemberCardsSection, SectionHeader } from './styles';
import { TeamMember } from 'TeamsModule/models/teamMember';

const FillerMemberCard = styled.div`
  width: 200px;
  position: relative;
  width: 200px;
  margin: 10px;
  border-radius: 5px;
`;

const MembersSectionHeader = styled(SectionHeader)`
  padding-left: 20px;
`;

// Generate filler employee cards to fill left over cards at bottom of
// MemberCardsSection so that container can be centered with flexbox.
const fillers = Array.from(Array(20)).map((_, i) => (
  <FillerMemberCard key={i} />
));

interface TeamMembersSectionProps {
  teamMembers: TeamMember[];
  header?: string;
  workGroupProps?: unknown;
}

const TeamMembersSection = ({
  teamMembers,
  header = undefined,
  workGroupProps = {}
}: TeamMembersSectionProps) => {
  const myId = useSelector(getMyUserId);
  return (
    <>
      {header && <MembersSectionHeader>{header}</MembersSectionHeader>}
      <MemberCardsSection>
        {teamMembers.map((teamMember) => (
          <TeamMemberCard
            key={teamMember.id}
            teamMember={teamMember}
            myId={myId}
            handleMemberCardClick={() => {}}
            workGroupProps={workGroupProps}
            clickable
          />
        ))}
        {fillers}
      </MemberCardsSection>
    </>
  );
};

export default TeamMembersSection;
