import styled from 'styled-components';
import theme from 'theme';
import LockIcon from 'icons/LockIcon';
import { makeAddEntityRow } from '../tableConfigs';
import { FilterListType } from 'FilterModule/constants';

export const AddEntityCell = ({
  row
}: {
  row: {
    original: ReturnType<typeof makeAddEntityRow>;
  };
}) => {
  const { filterListType, handleNavToEntityPage } = row.original;

  return (
    <StyledAddRow
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-tip={makeAdminOnlyTooltip(filterListType)}
      onClick={handleNavToEntityPage}
    >
      <div style={plusIconStyle}> + </div>
      Add {singularLabelHash[filterListType]}
      <LockIcon fill={theme.colors.colorRoyalBlue} style={lockIconStyle} />
    </StyledAddRow>
  );
};

// -----------------------------------------------------------------------------

const singularLabelHash: Partial<Record<FilterListType, string>> = {
  [FilterListType.MembersByDepartment]: 'Department',
  [FilterListType.Departments]: 'Department',
  [FilterListType.Positions]: 'Role',
  [FilterListType.MembersByPosition]: 'Role',
  [FilterListType.Skills]: 'Skill',
  [FilterListType.SkillLevels]: 'Skill',
  [FilterListType.MembersBySkill]: 'Skill',
  [FilterListType.Regions]: 'Region',
  [FilterListType.MembersByRegion]: 'Region',
  [FilterListType.Offices]: 'Office',
  [FilterListType.MembersByOffice]: 'Office',
  [FilterListType.MembersByDiscipline]: 'Discipline',
  [FilterListType.Disciplines]: 'Discipline'
};

const makeAdminOnlyTooltip = (filterListType: FilterListType) => {
  const singularLabel = singularLabelHash[filterListType];
  return `Only Admins can Add or Edit Standard${
    singularLabel ? ` ${singularLabel}s` : 's'
  }.`;
};

const lockIconStyle = {
  marginLeft: 8
};

const plusIconStyle = {
  marginRight: 10
};

const StyledAddRow = styled.div`
  height: 100%;
  color: ${theme.colors.colorRoyalBlue};
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
`;
