import * as constants from 'appConstants';

export const initialState = {
  me: null,
  introVideoTriggeredThisVisit: false,
  isFetchingMe: false,
  isFetchingMeError: false,
  teams: []
};
const emptyObj = {};
const users = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.SET_VIDEO_MODAL_VISITED: {
      return {
        ...state,
        introVideoTriggeredThisVisit: true
      };
    }
    case constants.USER.REQUEST: {
      return {
        ...state,
        isFetchingMe: true
      };
    }
    case constants.USER.SUCCESS: {
      const team_members_hash =
        action.payload.response.account.team_planner_members.reduce(
          (hash, id) => {
            hash[id] = [];
            return hash;
          },
          {}
        );
      return {
        ...state,
        me: {
          ...action.payload.response.account,
          account_id: action.payload.response.account.id,
          team_planner_members_order:
            action.payload.response.account.team_planner_members,
          team_planner_members: team_members_hash,
          hasPassword: action.payload.response.hasPassword,
          hasCompany: action.payload.response.hasCompany
        },
        isFetchingMe: false,
        isFetchingMeError: false
      };
    }
    case constants.USER.FAILURE:
      return {
        ...state,
        isFetchingMe: false,
        me: null,
        isFetchingMeError: true
      };
    case constants.TEAM_MEMBERS.SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          default_team: action.payload.response
        }
      };
    case constants.PROFILE_UPDATE.SUCCESS: {
      const { account, hasPassword, hasCompany } = action.payload.response;
      return {
        ...state,
        me: {
          ...state.me,
          email: account.email,
          name: account.name,
          first_name: account.first_name,
          middle_initial: account.middle_initial,
          last_name: account.last_name,
          initials: account.initials,
          hasPassword,
          hasCompany
        }
      };
    }
    case constants.PROFILE_UPDATE.FAILURE:
      if (action.payload.error === 'Forbidden') {
        return {
          ...state,
          email_error: action.payload.error
        };
      } else {
        return {
          ...state,
          password_error: action.payload.error
        };
      }
    case constants.PROFILE_UPDATE.TRIGGER:
      return {
        ...state,
        password_error: null,
        email_error: null
      };
    case constants.TEAM_UPDATE.SUCCESS:
      if (
        state.me.default_team &&
        action.payload.response.id === state.me.default_team.id
      ) {
        return {
          ...state,
          me: {
            ...state.me,
            default_team: action.payload.response
          }
        };
      }
      break;
    case constants.GROUPS.REQUEST:
      return {
        ...state
      };
    case constants.GROUPS.SUCCESS:
      return {
        ...state,
        teams: action.payload.response.teams
      };
    case constants.GROUPS.FAILURE:
      return {
        ...state
      };
    case constants.CHANGE_BOARD_POSITION.REQUEST:
      return {
        ...state
      };
    case constants.CHANGE_BOARD_POSITION.SUCCESS: {
      const { id, team_id } = action.payload.response;
      let position = action.payload.response.position;
      const newTeams = state.teams.map((team, idx) => {
        if (team.team_id === team_id) {
          if (position === -1) position = team.boards.length - 1;

          const newBoardsArray = team.boards.slice();
          const board = newBoardsArray.find((boardHash) => boardHash.id === id);

          const idxToRemove = newBoardsArray.indexOf(board);

          newBoardsArray.splice(idxToRemove, 1);

          newBoardsArray.splice(position, 0, board);
          return {
            ...team,
            boards: newBoardsArray
          };
        } else return team;
      });
      return {
        ...state,
        teams: newTeams
      };
    }
    case constants.CHANGE_BOARD_POSITION.FAILURE:
      return {
        ...state
      };
    case constants.FETCH_MEMBER_PROJECTS.SUCCESS: {
      const account_id =
        action.payload.requestPayload.account_id || state.me?.id;

      const response = action.payload.response;
      const { projects } = response;
      const newSet = Array.from(
        new Set([
          ...(state.me?.team_planner_members?.[account_id] ?? []),
          ...projects.map((project) => project?.id)
        ])
      );
      return {
        ...state,
        me: {
          ...state.me,
          team_planner_members: {
            ...(state?.me?.team_planner_members ?? emptyObj),
            [account_id]: Array.from(
              new Set([
                ...(state.me?.team_planner_members?.[account_id] ?? []),
                ...projects.map((project) => project?.id)
              ])
            )
          }
        }
      };
    }
    case constants.ADD_MEMBER_TO_PROJECT.TRIGGER: {
      const { accountId, projectId } = action.payload;
      if (!state.me.team_planner_members[accountId]) {
        return state;
      }
      const updatedProjects = state.me.team_planner_members[accountId].map(
        (project) =>
          project.id === projectId
            ? { ...project, user_is_member: true }
            : project
      );
      return {
        ...state,
        me: {
          ...state.me,
          team_planner_members: {
            ...state.me.team_planner_members,
            [accountId]: updatedProjects
          }
        }
      };
    }
    case constants.UPDATE_WORKLOAD_PLANNER_MEMBERS.TRIGGER:
      const payloadMembers = action.payload.team_planner_members;
      const membersWithProjects = payloadMembers.reduce((acc, id) => {
        if (state.me.team_planner_members[id]) {
          return {
            ...acc,
            [id]: state.me.team_planner_members[id]
          };
        } else {
          return {
            ...acc,
            [id]: []
          };
        }
      }, {});

      return {
        ...state,
        me: {
          ...state.me,
          team_planner_members: membersWithProjects
        }
      };
    case constants.PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE.SUCCESS: {
      const teamMembersHash =
        action.payload.response.account.team_planner_members.reduce(
          (hash, id) => {
            hash[id] = [];
            return hash;
          },
          {}
        );
      return {
        ...state,
        me: {
          ...action.payload.response.account,
          account_id: action.payload.response.account.id,
          team_planner_members_order:
            action.payload.response.account.team_planner_members,
          team_planner_members: teamMembersHash
        },
        isFetchingMe: false
      };
    }
    default:
      return state;
  }
};

export default users;
