import { ComponentType, Suspense, useCallback, useState } from 'react';

export const useOpenerWithProps = <
  ComponentPropsType extends Record<string, unknown>,
  OpenKeys extends keyof ComponentPropsType,
  OnCloseProp extends keyof ComponentPropsType = 'onClose'
>(
  Component: ComponentType<
    Omit<ComponentPropsType, OpenKeys | OnCloseProp> &
      Pick<ComponentPropsType, OpenKeys> &
      Record<OnCloseProp, () => void>
  >,
  params?: {
    onClose?: (props: Pick<ComponentPropsType, OpenKeys>) => void;
    onCloseProperty?: OnCloseProp;
    onOpen?: (props: Pick<ComponentPropsType, OpenKeys>) => void;
  }
): {
  close: () => void;
  Component: (
    props: Omit<ComponentPropsType, OpenKeys | OnCloseProp>
  ) => JSX.Element;
  isOpen: boolean;
  open: (props: Pick<ComponentPropsType, OpenKeys>) => void;
  toggle: (props: Pick<ComponentPropsType, OpenKeys>) => void;
} => {
  const [openProps, setOpenProps] =
    useState<Pick<ComponentPropsType, OpenKeys>>();

  const onClose = params?.onClose;
  const close = useCallback(() => {
    setOpenProps((openProps) => {
      if (openProps) onClose?.(openProps);
      return undefined;
    });
  }, [onClose]);

  const onOpen = params?.onOpen;
  const open = useCallback(
    (props: Pick<ComponentPropsType, OpenKeys>) => {
      onOpen?.(props);
      setOpenProps(props);
    },
    [onOpen]
  );

  const toggle = useCallback(
    (props: Pick<ComponentPropsType, OpenKeys>) => {
      setOpenProps((openProps) => {
        if (openProps) {
          onClose?.(openProps);
          return undefined;
        } else {
          onOpen?.(props);
          return props;
        }
      });
    },
    [onClose, onOpen]
  );

  const PartialComponent = useCallback(
    (props: Omit<ComponentPropsType, OpenKeys | OnCloseProp>) =>
      openProps ? (
        <Suspense fallback={null}>
          <Component
            {...props}
            {...(openProps ?? {})}
            {...({ [params?.onCloseProperty ?? 'onClose']: close } as Record<
              OnCloseProp,
              () => void
            >)}
          />
        </Suspense>
      ) : (
        <></>
      ),
    [Component, close, openProps, params?.onCloseProperty]
  );

  return {
    close,
    Component: PartialComponent,
    isOpen: !!openProps,
    open,
    toggle
  };
};
