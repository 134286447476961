import React from 'react';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { StyledTotalEstHours } from 'views/Home/Tasks/styles';

const TotalEstHoursCell = ({ row }) => {
  if (!row?.original?.totalEstHours) return null;
  return (
    <StyledTotalEstHours data-for="app-tooltip" data-tip="Total Hours">
      {formatNumWithMaxTwoDecimals(row?.original?.totalEstHours)} h
    </StyledTotalEstHours>
  );
};

export default React.memo(TotalEstHoursCell);
