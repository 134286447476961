import React from 'react';
import { Switch } from 'components/Switch';
import { SettingDiv } from '../styles';

/**
 * @deprecated use Switcher component instead
 */
const Setting = ({
  onText = 'On',
  offText = 'Off',
  isOn,
  onChange,
  title,
  description
}) => (
  <SettingDiv>
    <div className="top">
      <div className="title">{title}</div>
      <Switch
        isChecked={isOn}
        onChange={onChange}
        checkedText={onText}
        uncheckedText={offText}
      />
    </div>
    <div className="description">{description}</div>
  </SettingDiv>
);

export default Setting;
