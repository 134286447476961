import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';

import { getTeamsState, getMe } from './core';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import { getDashboardOwnerId } from 'DashboardModule/selectors';
import { getRecurlyBeganDate } from './recurly';
import moment from 'moment';

const emptyArray = [];

const isNotIntegrationActor = (member) => !member?.is_integration_account;

export const getAllTeams = createSelector(
  getTeamsState,
  (teamsState) => teamsState?.allTeams
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getSelectedTeam = createSelector(
  getTeamsState,
  (teamsState) => teamsState?.selectedTeam
);

export const getSelectedTeamIsSkipCCFlow = createSelector(
  getSelectedTeam,
  (selectedTeam) => selectedTeam && selectedTeam.skip_credit_card_info
);

export const getViewSettings = createSelector(
  getSelectedTeam,
  (selectedTeam) => selectedTeam?.view_settings
);

export const getProjectViewSettings = createSelector(
  getViewSettings,
  (viewSettings) => viewSettings?.project_view_settings
);

export const getProfileViewSettings = createSelector(
  getViewSettings,
  (viewSettings) => viewSettings?.member_profile_view_settings
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
const getTeamMembersWithIntegrationAccounts = createSelector(
  getSelectedTeam,
  (team) => team?.team_members || emptyArray
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getTeamMembers = createSelector(
  getTeamMembersWithIntegrationAccounts,
  (teamMembers) => teamMembers.filter(isNotIntegrationActor)
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getAllTeamMembers = createSelector(
  getTeamMembers,
  getMe,
  (members, me) =>
    members
      .map((member) => ({
        ...member,
        name: member.account.name,
        initials: member.account.initials,
        isLoggedInUser: me && me.team_membership_id === member.id
      }))
      .filter((member) => !member.is_archived)
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getAllTeamMembersWithArchived = createSelector(
  getTeamMembers,
  getMe,
  (members, me) =>
    members.map((member) => ({
      ...member,
      name: member.account.name,
      initials: member.account.initials,
      isLoggedInUser: me && me.team_membership_id === member.id
    }))
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 * Team member data keyed by account id
 */
export const getTeamMembersHash = createSelector(
  getAllTeamMembersWithArchived,
  (members) => keyBy(members, 'account.id')
);

export const getRecurlyUrl = createSelector(
  getSelectedTeam,
  (selectedTeam) => selectedTeam?.recurly_management_url
);

export const getAllAdminsAndFinancialManagers = createSelector(
  getAllTeamMembers,
  (members) =>
    members.filter(
      (member) =>
        member.is_admin ||
        member.role_ids.includes(ACCESS_ROLES.FINANCIAL_MANAGER)
    )
);

export const getSharableMembersListForDashboard = createSelector(
  getAllAdminsAndFinancialManagers,
  getDashboardOwnerId,
  (members, dashboardOwner) =>
    members.filter((member) => member.account.id !== dashboardOwner)
);

export const getIsFetchingTeam = createSelector(
  getTeamsState,
  (state) => state.isFetchingTeam
);

export const getIsPreRecurlyTeam = createSelector(
  getSelectedTeam,
  getRecurlyBeganDate,
  (selectedTeam, beganDate) =>
    !selectedTeam?.created_at ||
    moment(new Date(beganDate)).isAfter(moment(selectedTeam.created_at))
);

export const getSelectedTeamBillingSubscriptionInvalid = createSelector(
  getSelectedTeam,
  (selectedTeam) => {
    const subscriptionStatus = selectedTeam?.subscription_status;
    return subscriptionStatus === null || subscriptionStatus === 'expired';
  }
);
