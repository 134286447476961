import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
const byId = (item) => item.id;

export const initialState = {
  qbServices: {},
  qbSyncedServices: {},
  isShowOnlySynced: false,
  selectedActivities: {},
  isSyncServicesLoading: false,
  selectedActivitiesQBToMosaic: {},
  selectedActivitiesMosaicToQB: {},
  mappingFilter: constants.mappingFilters.SHOW_ALL
};
const qbServices = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_SERVICES.SUCCESS: {
      const { items } = payload.response;
      return {
        ...state,
        qbServices: keyBy(items, byId)
      };
    }
    case constants.FETCH_SYNCED_SERVICE.SUCCESS: {
      const { mappings } = payload.response;
      return {
        ...state,
        qbSyncedServices: keyBy(mappings, byId)
      };
    }
    case constants.TOGGLE_SYNCED_SERVICES: {
      const { isShowOnlySynced } = action;
      return {
        ...state,
        isShowOnlySynced
      };
    }
    case constants.QB_TOGGLE_BATCH_SELECT: {
      const { itemType, items, value } = payload;
      if (itemType !== 'activities') {
        return state;
      }

      const selectedActivitiesStateSlice = 'selectedActivitiesQBToMosaic';

      const itemsById = keyBy(items, byId);
      if (value) {
        return {
          ...state,
          [selectedActivitiesStateSlice]: {
            ...state[selectedActivitiesStateSlice],
            ...itemsById
          }
        };
      } else {
        return {
          ...state,
          [selectedActivitiesStateSlice]: omit(
            state[selectedActivitiesStateSlice],
            Object.keys(itemsById)
          )
        };
      }
    }
    case constants.QB_FLUSH_BATCH_SELECT: {
      const { itemType, isQbToMosaic } = payload;
      if (itemType !== 'activities') {
        return state;
      }
      const selectedActivitiesStateSlice = isQbToMosaic
        ? 'selectedActivitiesQBToMosaic'
        : 'selectedActivitiesMosaicToQB';

      return {
        ...state,
        [selectedActivitiesStateSlice]: {}
      };
    }

    case constants.TOGGLE_MAPPING_FILTER: {
      const { section, mappingFilter } = payload;
      if (section !== 'activities') {
        return state;
      }
      return {
        ...state,
        mappingFilter
      };
    }

    case constants.TOGGLE_SERVICES_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isSyncServicesLoading: isLoading
      };
    }

    default: {
      return state;
    }
  }
};

export default qbServices;
