import { useGroupedMembersFilter } from '.';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterListType } from 'FilterModule/constants';
import { useOffices } from 'SettingsModule/hooks';

export const useMembersByOfficeFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isoStateId,
  field,
  shouldUseDraft
} = {}) => {
  const { handleNavToOfficesPage, officeHash, getOfficeLabel } = useOffices({
    isOff
  });

  const groupedMembersFilterValues = useGroupedMembersFilter({
    isOff,
    config,
    isoStateId: isoStateId ?? 'membersByOfficeFilter',
    filterListType: FilterListType.MembersByOffice,
    field,
    shouldUseDraft
  });

  return {
    ...groupedMembersFilterValues,
    handleNavToEntityPage: handleNavToOfficesPage,
    groupEntityHash: officeHash,
    getGroupEntityLabel: getOfficeLabel
  };
};
