import styled, { css } from 'styled-components';
import { Badge } from './Badge';

export interface SubMenuTabItemProps<V extends string> {
  label: string;
  value: V;
  count: number;
}

export interface SubMenuTabsProps<V extends string> {
  tabs: Array<SubMenuTabItemProps<V>>;
  currentTab: V;
  onChange: (value: V) => void;
}

export const SubMenuTabs = <V extends string>({
  tabs,
  currentTab,
  onChange
}: SubMenuTabsProps<V>) => {
  return (
    <RootContainer>
      {tabs.map(({ value, label, count }) => {
        const active = currentTab === value;
        return (
          <TabItemContainer key={label}>
            <TabButton onClick={() => onChange(value)}>
              <TabLabelContainer>
                <LabelText active={active}>{label}</LabelText>
              </TabLabelContainer>
              <Indicator active={active} />
              <CountContainer>
                <Badge count={count} active={active} />
              </CountContainer>
            </TabButton>
          </TabItemContainer>
        );
      })}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TabItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TabLabelContainer = styled.div`
  padding: 4px 2px;
`;

const TabButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CountContainer = styled.div`
  padding-top: 6px;
  align-items: center;
`;

const Indicator = styled.div<{ active: boolean }>`
  height: 2px;
  width: 100%;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.colorRoyalBlue : 'transparent'};
`;

const LabelText = styled.div<{ active: boolean }>`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  text-transform: capitalize;

  ${({ active }) => (active ? ActiveLabelStyle : undefined)}
`;

const ActiveLabelStyle = css`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
`;
