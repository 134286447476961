import React, { Component } from 'react';
import TaskCardSwitch from './TaskCardSwitch';
import { Draggable } from 'react-beautiful-dnd';

class PlannerColumnTaskList extends Component {
  render() {
    const { tasks, date, unscheduled, accountId } = this.props;
    return tasks
      ? tasks.map((taskId, index) => (
          <Draggable
            key={taskId}
            draggableId={`${'projects'}--${taskId}`}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style
                }}
              >
                <TaskCardSwitch
                  listLength={tasks.length}
                  taskId={taskId}
                  laneId={date}
                  unscheduled={unscheduled}
                  accountId={accountId}
                  currentPosition={index}
                />
              </div>
            )}
          </Draggable>
        ))
      : null;
  }
}

export default PlannerColumnTaskList;
