import React from 'react';
import styled from 'styled-components';
import { MonthView } from './MonthView';
import { getEveryFirstDayOfYear, toPx } from './utils';

/**
 *
 * @typedef {Object} YearViewProps
 * @property {Date} currentDate
 */

/**
 *
 * @param {YearViewProps} props
 */
export const YearView = ({ currentDate = new Date() }) => {
  const monthArray = getEveryFirstDayOfYear(currentDate);

  return (
    <YearContainer>
      {monthArray.map((item, index) => (
        <MonthView key={item} currentDate={item} index={index}></MonthView>
      ))}
    </YearContainer>
  );
};

const YearContainer = styled.div`
  flex: 1;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: ${({ theme }) => toPx(theme.size.spacing.monthGap)};

  & > div {
    flex-grow: 0;
  }
`;
