import styled from 'styled-components';
import NewCloseIcon from 'icons/NewCloseIcon';

export const MemberRowLeadingCell = ({ row: { original } }) => {
  const { customRowProps, onRemoveRow, rowData } = original;
  const handleRemoveMember = () => {
    onRemoveRow(rowData);
  };
  return (
    <LeadingCellContainer>
      {customRowProps.useGroup && (
        <DeleteIconContainer onClick={handleRemoveMember}>
          <NewCloseIcon
            className={undefined}
            testId={undefined}
            onClick={undefined}
          />
        </DeleteIconContainer>
      )}
    </LeadingCellContainer>
  );
};

const LeadingCellContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteIconContainer = styled.div`
  opacity: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  circle {
    visibility: hidden;
  }
  path {
    fill: ${({ theme }) => theme.colors.colorCalendarBlue};
  }
  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.colorCalendarRed};
    }
    circle {
      visibility: visible;
      fill: white;
    }
  }
`;
