import React from 'react';
import SvgIcon from 'components/SvgIcon';

const longBar = '13.75';
const shortBar = '8.75';

const NewSortIcon = ({
  height = '11',
  width = '14',
  color = '#999999',
  className,
  isFlipped = false
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M6.25 6.125V4.875H11.25V6.125H6.25ZM6.25 9.875V8.625H${
        isFlipped ? longBar : shortBar
      }V9.875H6.25ZM6.25 2.375V1.125H${
        isFlipped ? shortBar : longBar
      }V2.375H6.25ZM3.75 8.625H5.3125L3.125 10.8125L0.9375 8.625H2.5V2.375H0.9375L3.125 0.1875L5.3125 2.375H3.75V8.625Z`}
      fill={color}
    />
  </SvgIcon>
);

export default NewSortIcon;
