import React from 'react';
import { connect } from 'react-redux';
import {
  updateAccountFilterLocal,
  fetchMemberProjects,
  fetchAllProjects,
  resetProjectFilterList
} from 'actionCreators';
import {
  makeGetOrderedFilterProjects,
  makeGetOrderedFilterWorkloadProjects,
  makeGetActiveWorkloadPlannerFilter,
  makeGetActiveWorkloadPlannerFilterIdHashes,
  getWorkloadProjectsOffset,
  getSelectedTeamId
} from 'selectors';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import {
  InnerDropdownHeader,
  SelectedValue,
  SelectorOption,
  StyledInput,
  StyledInputContainer,
  CloseIconContainer,
  CustomCheckBoxContainer
} from './styles';
import NewCloseIcon from 'icons/NewCloseIcon';
import VirtualFilter from 'ReportsModule/components/Filter/VirtualFilter';
import {
  ProjectFilterMenu,
  filterTabToFilterListTypeHash,
  filterListTypeToFilterTabHash
} from 'ReportsModule/components/Filter/FilterTabs';
import { StyledFilterRow } from 'ReportsModule/components/styles';
import styled from 'styled-components';
import theme from 'theme';
import { FilterField } from 'FilterModule/constants';

const FilterStyles = styled.div`
  .filter-list-item-container,
  ${StyledFilterRow} {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colors.colorSemiDarkGray1} !important;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.colorTranslucentGray4};
      ${CustomCheckBoxContainer} {
        border-color: ${({ isDisabled }) =>
          isDisabled ? 'transparent' : theme.colors.colorRoyalBlue};
      }
    }
  }
`;

class ProjectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      isFocused: false,
      filterTab:
        filterListTypeToFilterTabHash[
          props.activeFilter?.custom?.[FilterField.mainFilterListType]
        ] || 'projectsByBoard'
    };
  }

  componentDidUpdate(prevProps) {
    const { projects, workloadProjects, isWorkload } = this.props;
    const {
      projects: prevProjects,
      workloadProjects: prevWorkloadProjects,
      isWorkload: prevIsWorkload
    } = prevProps;
    const projectsToUse = isWorkload ? workloadProjects : projects;
    const prevProjectsToUse = prevIsWorkload
      ? prevWorkloadProjects
      : prevProjects;
    if (
      projectsToUse &&
      prevProjectsToUse &&
      projectsToUse.length !== prevProjectsToUse.length
    ) {
      this.clearMemo();
    }
  }

  searchMemo = {};
  clearMemo = () => (this.searchMemo = {});
  getItems = () => {
    const { projects, workloadProjects, isWorkload } = this.props;
    const { search } = this.state;
    const projectsToUse = isWorkload ? workloadProjects : projects;
    if (!search.length) {
      return projectsToUse;
    }
    if (!this.searchMemo[search]) {
      this.searchMemo[search] = this.filterItems();
    }
    return this.searchMemo[search];
  };

  filterItems = () => {
    const { search } = this.state;
    const { projects, workloadProjects, isWorkload } = this.props;
    const projectsToUse = isWorkload ? workloadProjects : projects;
    const searchWords = search.split(' ').filter((str) => str !== '-');
    return projectsToUse.filter((item) => this.itemFilter(item, searchWords));
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: [
        'title',
        'description',
        'project_title',
        'project_description'
      ]
    });

  clearSearch = () => this.setState({ search: '' });
  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value }, this.loadOnSearch);

  areAllSelected = () => {
    const { activeFilterIdHashes } = this.props;
    return (
      activeFilterIdHashes &&
      this.getItems().every(
        (project) => activeFilterIdHashes.project_ids[project.id]
      )
    );
  };

  areSomeSelected = () => {
    const { activeFilterIdHashes } = this.props;
    return (
      activeFilterIdHashes &&
      this.getItems().some(
        (project) => activeFilterIdHashes.project_ids[project.id]
      )
    );
  };

  canSelectMore = () => {
    const { filterLimits = {}, filter } = this.props;
    return (
      !filterLimits[VIEW_BY.PROJECTS] ||
      filterLimits[VIEW_BY.PROJECTS] > filter.project_ids?.length
    );
  };

  handleSearchFocus = (isFocused) => {
    this.setState({
      ...this.state,
      isFocused
    });
  };

  renderHeader = ({ itemSelected, selectionLimit }) => {
    const {
      updateAccountFilterLocal,
      filter,
      isDefaultAllSelected,
      isProjectPhaseSelector,
      draftFilter
    } = this.props;
    const { search } = this.state;

    return (
      <>
        <StyledInputContainer style={{ display: 'flex' }}>
          <StyledInput
            onChange={this.handleChange}
            name="search"
            value={search}
            placeholder={`Search ${
              isProjectPhaseSelector ? 'phase' : 'project'
            } or select below`}
            autoComplete="off"
            autoFocus
            onFocus={() => this.handleSearchFocus(true)}
            onBlur={() => this.handleSearchFocus(false)}
            isActive={!!search}
            className="workload-filter"
          />
          {this.state.search !== '' ? (
            <CloseIconContainer
              onClick={this.clearSearch}
              className="filter-close-icon"
            >
              <NewCloseIcon stroke="transparent" />
            </CloseIconContainer>
          ) : (
            ''
          )}
        </StyledInputContainer>
        <InnerDropdownHeader
          style={{ padding: '5px', paddingLeft: 15 }}
          data-for="app-tooltip"
          data-tip={`Max ${selectionLimit} of ${itemSelected} Selected.`}
          data-tip-disable={Boolean(
            !selectionLimit ||
              (selectionLimit !== undefined &&
                itemSelected !== undefined &&
                selectionLimit > itemSelected)
          )}
        >
          {/* currently All just behaves same as clear all */}
          {!!itemSelected && isDefaultAllSelected && !selectionLimit && (
            <SelectorOption
              onClick={(e) =>
                draftFilter
                  ? draftFilter.update({ project_ids: [] })
                  : updateAccountFilterLocal({
                      ...filter,
                      project_ids: [] // bulkSelectorIds NOTE: all crashes app until vertical pagination is implemented
                    })
              }
            >
              All
            </SelectorOption>
          )}
          {itemSelected > 0 && (
            <SelectorOption
              onClick={(e) =>
                draftFilter
                  ? draftFilter.update({ project_ids: [] })
                  : updateAccountFilterLocal({
                      ...filter,
                      project_ids: [],
                      ...(isProjectPhaseSelector && { phase_ids: [] })
                    })
              }
            >
              Clear Selected &nbsp;
            </SelectorOption>
          )}
          {itemSelected ? (
            <SelectedValue isBlue={itemSelected}>
              {' '}
              {itemSelected}
              {selectionLimit ? `/${selectionLimit}` : ''}
            </SelectedValue>
          ) : (
            <SelectedValue>
              {isDefaultAllSelected ? 'All' : '0'}{' '}
              {isProjectPhaseSelector ? 'phases ' : 'projects '} selected
            </SelectedValue>
          )}
        </InnerDropdownHeader>
      </>
    );
  };

  onFilterTabSelect = ({ projects }) => {
    const { activeFilter, updateAccountFilterLocal } = this.props;
    this.setState({ filterTab: projects });
    if (filterTabToFilterListTypeHash[projects]) {
      updateAccountFilterLocal({
        ...activeFilter,
        custom: {
          ...activeFilter?.custom,
          [FilterField.mainFilterListType]:
            filterTabToFilterListTypeHash[projects]
        }
      });
    }
  };

  render() {
    const {
      viewType,
      viewBy,
      pageName,
      projectFilterTabsOverride,
      customProjectRowComponent,
      customBoardRowComponent,
      innerHeightAdjustment,
      showSubStickyHeader,
      useMyProjects,
      hideFilterTabs,
      renderCustomHeader,
      listWidth,
      enableDrag,
      isProjectsSidebar,
      filterListId,
      filterId,
      widgetConfig,
      filter,
      openCrossFieldFilters,
      isCrossFieldFiltersOpen,
      crossFieldDependencies,
      numCrossFieldFiltersUsed,
      isProjectPhaseSelector,
      customPhaseRowComponent,
      additionalFilterOptions,
      draftFilter,
      isSideFilter,
      isPtoSelectable
    } = this.props;
    const { filterTab, search, isFocused } = this.state;

    const selectorType = isProjectPhaseSelector ? 'phase_ids' : 'project_ids';
    const fieldToUse = draftFilter?.[selectorType] || filter?.[selectorType];
    const itemSelected =
      fieldToUse && fieldToUse.length !== undefined ? fieldToUse.length : '';
    const selectionLimit = widgetConfig?.limits?.[selectorType];

    return (
      <>
        {!hideFilterTabs && (
          <ProjectFilterMenu
            activeFilterTab={filterTab}
            onFilterTabSelect={this.onFilterTabSelect}
            projectFilterTabsOverride={projectFilterTabsOverride}
            openCrossFieldFilters={openCrossFieldFilters}
            isCrossFieldFiltersOpen={isCrossFieldFiltersOpen}
            numCrossFieldFiltersUsed={numCrossFieldFiltersUsed}
          />
        )}
        {renderCustomHeader
          ? renderCustomHeader({
              search,
              isFocused,
              handleSearchFocus: this.handleSearchFocus,
              handleChange: this.handleChange,
              clearSearch: this.clearSearch
            })
          : this.renderHeader({
              itemSelected,
              selectionLimit
            })}
        <FilterStyles>
          <VirtualFilter
            filterId={filterId}
            viewType={viewType}
            pageName={pageName}
            clearSearch={this.clearSearch}
            viewBy={viewBy}
            filterSections={['projects']}
            filterTabsOverride={{ projects: filterTab }}
            isProjectsSidebar={isProjectsSidebar}
            sectionsStartOpen={!isProjectsSidebar}
            showOnEngaged={false}
            showStickyHeader={false}
            showSubStickyHeader={showSubStickyHeader}
            searchOverride={{ projects: search }}
            customItems={{ projectList: [] }}
            skipHeader={{ projects: true }}
            headerLeftOffset={0}
            leftPadding={isProjectsSidebar ? undefined : '15px'}
            leftOffset={'0px'}
            width={listWidth || 225}
            innerHeightAdjustment={innerHeightAdjustment || 280}
            saveLocal
            rowTypes={
              // beware that one of them may not be present
              customProjectRowComponent || customPhaseRowComponent
                ? {
                    ProjectRow: customProjectRowComponent,
                    PhaseRow: customPhaseRowComponent
                  }
                : undefined
            }
            headerTypes={
              customBoardRowComponent
                ? { BoardRow: customBoardRowComponent }
                : undefined
            }
            useMyProjects={useMyProjects}
            enableDrag={enableDrag}
            collapseTarget={
              filterTab === 'projectsByBoard' ? 'board' : 'projectsByMember'
            }
            projectFilterListId={filterListId}
            widgetConfig={widgetConfig}
            activeFilter={filter}
            crossFieldDependencies={crossFieldDependencies}
            isProjectPhaseSelector={isProjectPhaseSelector}
            additionalFilterOptions={additionalFilterOptions}
            draftFilter={draftFilter}
            isSideFilter={isSideFilter}
            itemsCount={
              itemSelected && {
                itemSelected,
                selectionLimit
              }
            }
            isPtoSelectable={isPtoSelectable}
          />
        </FilterStyles>
      </>
    );
  }
}
const emptyArray = [];
const makeMapStateToProps = () => {
  const getOrderedFilterWorkloadProjects =
    makeGetOrderedFilterWorkloadProjects();
  const getOrderedFilterProjects = makeGetOrderedFilterProjects();
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const getActiveWorkloadPlannerFilterIdHashes =
    makeGetActiveWorkloadPlannerFilterIdHashes();
  const mapStateToProps = (state, ownProps) => {
    const filter = getActiveWorkloadPlannerFilter(state, ownProps);
    return {
      teamId: getSelectedTeamId(state),
      workloadProjects:
        getOrderedFilterWorkloadProjects(state, ownProps) || emptyArray,
      projects: getOrderedFilterProjects(state, ownProps) || emptyArray,
      filter,
      activeFilterIdHashes: getActiveWorkloadPlannerFilterIdHashes(
        state,
        ownProps
      ),
      workloadOffset: getWorkloadProjectsOffset(state)
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = {
  updateAccountFilterLocal,
  fetchMemberProjects,
  fetchAllProjects,
  resetProjectFilterList
};
export default connect(makeMapStateToProps, mapDispatchToProps)(ProjectFilter);
