import styled from 'styled-components';
import unassignedIcon from 'images/empty-member.svg';
import { calculateColorPref } from 'appUtils/styleUtils';
import theme from 'theme';
import NoteIcon from 'icons/NoteIcon';
import CommentBubbleIcon from 'icons/CommentBubbleIcon';

export const TaskCardAssignee = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  font-family: 'proxima-nova';
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
  border: 2px solid ${calculateColorPref};
  color: ${({ theme }) => theme.colors.colorPureWhite};
  background: ${({ isAssigned, assignedToMe, theme }) =>
    !isAssigned
      ? `url(${unassignedIcon}) no-repeat center;`
      : assignedToMe
      ? theme.colors.colorMediumGray5
      : theme.colors.colorMediumGray1};
`;

export const StyledNoteIcon = styled(NoteIcon)`
  margin-right: 10px;
`;
export const StyledCommentIcon = styled(CommentBubbleIcon)`
  height: 16px;
`;

export const StyledCommentIconContainer = styled.div`
  position: relative;
  display: flex;
  margin-right: 10px;
`;
export const CommentNumber = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
`;
