import WorkdayDateSelector from 'views/projectDisplay/projectCloneModal/WorkdayDateSelector';
import AllMemberConfirmationModal from 'views/projectDisplay/projectCloneModal/AllMemberConfirmationModal';

const getDefaultCloneProjectFlags = (isHoursOnly) => ({
  // SECTION: Project Members
  project_memberships: {
    name: 'Members',
    value: true,
    // confirmationComponent: AllMemberConfirmationModal,
    // showConfirmationModalOnChecked: ['budget_member_budget'],
    isRequiredBy: ['phase_memberships'],
    requiredTooltip:
      'Project Members are required for <br/> Cloning Phase Members'
  },

  // SECTION: Project Tasks
  tasks: {
    name: 'Tasks',
    value: true,
    tooltip:
      "Use 'Select' column on the task <br /> list to clone individual tasks.",
    subFlags: [
      'tasks_phase',
      'tasks_assignments',
      // 'tasks_description',
      'tasks_priority',
      'tasks_estimations',
      'tasks_attachments'
    ]
  },
  // Project Tasks children
  tasks_assignments: {
    name: 'Assignees',
    value: true,
    onlyUncheckSubflag: true,
    dependencies: ['tasks_phase', 'phase_memberships'],
    parent: 'tasks',
    isRequiredBy: ['work_plan_tasks'],
    requiredTooltip:
      "Assignees are required for <br/> Cloning Work Plan's Tasks"
  },
  tasks_priority: { name: 'Priority', parent: 'tasks', value: true },
  tasks_phase: {
    name: 'Phases',
    value: true,
    dependencies: ['phases'],
    parent: 'tasks',
    isRequiredBy: ['tasks_assignments'],
    requiredTooltip: "Phases are required for <br/> Cloning Tasks' Assignees"
  },
  tasks_estimations: { name: 'Time Estimate', parent: 'tasks', value: true },
  tasks_attachments: {
    name: 'Attachments',
    value: true,
    className: 'subSectionLastItem'
  },
  // tasks_description: { name: 'Description', value: true },

  // SECTION: Project Notes
  project_notes: { name: 'Notes', value: true },

  // SECTION: Schedules
  schedule: {
    name: 'Schedules',
    value: true,
    subFlags: ['phases', 'milestones']
  },
  milestones: {
    name: 'Milestones',
    value: true,
    parent: 'schedule',
    className: 'subSectionLastItem'
  },
  phases: {
    name: 'Phases',
    value: true,
    subFlags: ['phase_memberships', 'phase_work_days', 'phase_dependencies'],
    isRequiredBy: ['tasks_phase', 'budget'],
    requiredTooltip:
      "Phases are required for <br/> Cloning Budgets and Task's Phases",
    parent: 'schedule',
    className: 'subItemWithChild'
  },
  // Phases children
  phase_memberships: {
    name: 'Members',
    value: true,
    dependencies: ['project_memberships'],
    parent: 'phases',
    ignoreInDependencyCheck: true,
    isRequiredBy: [
      'tasks_assignments',
      'budget_member_budget',
      'scope_members',
      'work_plan_assignments'
    ],
    requiredTooltip:
      "Phase Members are required for <br/> Cloning Task's Assignees, Scope's Assignees <br/> Work Plan's Assignees and Member Budgets "
  },
  phase_work_days: {
    name: 'Workdays',
    value: true,
    customSubComponent: WorkdayDateSelector,
    parent: 'phases',
    className: 'hasCustomSubComponent',
    ignoreInDependencyCheck: true,
    isRequiredBy: ['work_plan_dependencies'],
    requiredTooltip:
      "Workdays are required for <br/> Cloning Work Plans' Dependencies "
  },
  phase_dependencies: {
    name: 'Dependencies',
    value: true,
    parent: 'phases',
    dependencies: ['phase_work_days'],
    ignoreInDependencyCheck: true
  },

  // SECTION: Budget
  budget: {
    name: 'Budget',
    value: true,
    subFlags: [
      // hide phase fee and phase budget options if hours only.
      ...(!isHoursOnly ? ['budget_phase_budget', 'budget_phase_fee'] : []),
      'budget_member_budget'
    ],
    dependencies: ['phases']
  },
  // Budget children
  // hide phase fee and phase budget options if hours only.
  ...(!isHoursOnly
    ? {
        budget_phase_fee: {
          name: 'Phase Fees',
          value: true,
          parent: 'budget',
          isRequiredBy: ['budget_phase_budget'],
          requiredTooltip:
            'Phase Fees are required for <br/> Cloning Phase Budgets'
        },
        budget_phase_budget: {
          name: 'Phase Budgets',
          value: true,
          parent: 'budget',
          dependencies: ['budget_phase_fee']
        }
      }
    : {}),

  budget_member_budget: {
    name: 'Member Budgets',
    value: true,
    isRequiredBy: ['scope_estimates'],
    requiredTooltip:
      "Member Budgets are required for <br/> Cloning Scope's Estimated Hrs.",
    onlyUncheckSubflag: true,
    dependencies: ['phase_memberships'],
    parent: 'budget',
    className: 'subSectionLastItem'
  },

  // SECTION: Scope
  scope: {
    name: 'Scope',
    value: true,
    subFlags: ['scope_members', 'scope_estimates']
  },
  // Scope children
  scope_members: {
    name: 'Assignees',
    value: true,
    onlyUncheckSubflag: true,
    parent: 'scope',
    ignoreInDependencyCheck: true,
    dependencies: ['phase_memberships'],
    isRequiredBy: ['scope_estimates'],
    requiredTooltip: 'Assignees are required for <br/> Cloning Estimated Hrs.'
  },
  scope_estimates: {
    name: 'Estimated Hrs.',
    value: true,
    dependencies: ['scope_members', 'budget_member_budget'],
    parent: 'scope',
    ignoreInDependencyCheck: true,
    className: 'subSectionLastItem'
  },

  // SECTION: Work Plans
  work_plan: {
    name: 'Work Plans',
    value: true,
    subFlags: [
      'work_plan_dependencies',
      'work_plan_tasks',
      'work_plan_assignments'
    ],
    dependencies: ['work_plan_assignments']
  },
  work_plan_dependencies: {
    name: 'Dependencies',
    value: true,
    parent: 'work_plan',
    dependencies: ['phase_work_days']
  },
  work_plan_tasks: {
    name: 'Tasks',
    value: true,
    parent: 'work_plan',
    dependencies: ['tasks_assignments']
  },
  work_plan_assignments: {
    name: 'Assignees',
    value: true,
    onlyUncheckSubflag: true,
    dependencies: ['phase_memberships'],
    parent: 'work_plan',
    grayoutWhenChecked: true,
    isRequiredBy: ['work_plan_tasks', 'work_plan_dependencies', 'work_plan'],
    requiredTooltip: 'Assignees are required for <br/> Cloning Work Plans',
    skipRequiredCheck: true
  }
});

export default getDefaultCloneProjectFlags;
