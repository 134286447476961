import { PositionsState } from 'BudgetModule/reducers/positions';
import { StatusesState } from 'reducers/statuses';
import { CommentsState } from 'reducers/comments';
import { DisciplinesState } from 'SettingsModule/reducers/disciplines';
import { LocationsState } from 'SettingsModule/reducers/locations';
import { OfficesState } from 'SettingsModule/reducers/offices';
import { PTOState } from 'SettingsModule/reducers/ptos';
import { RegionsState } from 'SettingsModule/reducers/regions';
import { ScopeRequestsState } from 'ScopeRequestsModule/types';
import { EventsState } from 'EventsModule/types';
import { WorkplanRequestsState } from 'WorkplanRequestsModule/types';
import { TeamsState } from 'TeamsModule/types';
import { UsersState } from 'UsersModule/types';
import { FiltersState } from 'FilterModule/types';
import { TeamPermissionsState } from 'PermissionsModule/reducers/types';
import { RouteHistoryState } from 'appCore/navigation/types';
import { AuthState } from 'AuthenticationModule/reducer';
import { RatesState } from 'BudgetModule/reducers/rates';
import { MemberRatesState } from 'BudgetModule/reducers/memberRates';
import { CapacityGroupsState } from 'CapacityModule/reducers/capacityGroup';
import { SuggestionState } from 'SuggestionModule/types';
import { LeanApiState } from 'LeanApiModule/types';
import { PhasesState, ProjectsState } from 'ProjectsModule/reducers/types';
import { OrgChartState } from 'OrgChartModule/types';
import { EntityRequirementsState } from 'RequirementsModule/reducers/entityRequirements';
import { TimelinesState } from 'TimelinesModule/reducers/types';
import { ImportLogState } from 'CsvImportsModule/types';
import { TeamRatesState } from 'RatesModule/reducers/types';
import { BudgetState } from 'BudgetModule/reducers/budget';
import { BudgetSettingsState } from 'BudgetModule/reducers/budgetSettings';

export enum ReducerName {
  Auth = 'auth',
  Budget = 'budget',
  BudgetSettings = 'budgetSettings',
  CapacityGroups = 'capacityGroups',
  CapacityReport = 'capacityReport',
  Comments = 'comments',
  Currency = 'currency',
  Disciplines = 'disciplines',
  EntityOptions = 'entityOptions',
  Events = 'events',
  Filters = 'filters',
  LeanApi = 'leanApi',
  Locations = 'locations',
  MemberRates = 'memberRates',
  Offices = 'offices',
  OrgChart = 'orgChart',
  Phases = 'phases',
  Positions = 'positions',
  Projects = 'projects',
  Pto = 'pto',
  Rates = 'rates',
  Regions = 'regions',
  RouteHistory = 'routeHistory',
  ScopeRequests = 'scopeRequests',
  Scopes = 'scopes',
  Statuses = 'statuses',
  Suggestion = 'suggestion',
  TeamPermissions = 'teamPermissions',
  TeamRates = 'teamRates',
  Teams = 'teams',
  Users = 'users',
  WorkplanRequests = 'workplanRequests',
  EntityRequirements = 'entityRequirements',
  Timelines = 'timelines',
  ImportLog = 'importLog'
}

/*
 * NOTE: If your module is dynamically loaded, be sure to make the state
 * optional by adding a `?` after the reducer name.
 */
export interface RootState {
  [ReducerName.Auth]: AuthState;
  [ReducerName.Budget]?: BudgetState;
  [ReducerName.BudgetSettings]?: BudgetSettingsState;
  [ReducerName.CapacityGroups]: CapacityGroupsState;
  [ReducerName.Comments]: CommentsState;
  [ReducerName.Disciplines]: DisciplinesState;
  [ReducerName.Events]: EventsState;
  [ReducerName.Filters]: FiltersState;
  [ReducerName.LeanApi]: LeanApiState;
  [ReducerName.Locations]: LocationsState;
  [ReducerName.MemberRates]: MemberRatesState;
  [ReducerName.Offices]: OfficesState;
  [ReducerName.OrgChart]: OrgChartState;
  [ReducerName.Phases]: PhasesState;
  [ReducerName.Positions]: PositionsState;
  [ReducerName.Projects]: ProjectsState;
  [ReducerName.Pto]: PTOState;
  [ReducerName.Rates]?: RatesState;
  [ReducerName.Regions]: RegionsState;
  [ReducerName.RouteHistory]: RouteHistoryState;
  [ReducerName.ScopeRequests]: ScopeRequestsState;
  [ReducerName.Statuses]: StatusesState;
  [ReducerName.Suggestion]: SuggestionState;
  [ReducerName.TeamPermissions]: TeamPermissionsState;
  [ReducerName.TeamRates]: TeamRatesState;
  [ReducerName.Teams]: TeamsState;
  [ReducerName.Users]: UsersState;
  [ReducerName.WorkplanRequests]: WorkplanRequestsState;
  [ReducerName.EntityRequirements]: EntityRequirementsState;
  [ReducerName.Timelines]: TimelinesState;
  [ReducerName.ImportLog]: ImportLogState;
}
