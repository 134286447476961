import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportRegion
} from 'CsvImportsModule/types';

interface AdditionalParams {
  existingRegionsByName: Record<string, CsvImportRegion>;
  newRegions: React.MutableRefObject<Record<string, boolean>>;
}

export const regionOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ additionalParams }) => {
  const { existingRegionsByName, newRegions } = additionalParams;

  return [
    ...Object.keys(newRegions.current),
    ...Object.keys(existingRegionsByName)
  ];
};
