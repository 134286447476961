import React from 'react';
import { DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import {
  StyledModalBody,
  InfoContainer,
  StyledLockIcon,
  ActionContainer,
  MemberName,
  StyledAddPlusIcon,
  StyledDropdownToggle,
  StyledDownArrow,
  StyledDropdownItem,
  VisibilityMemberRow,
  StyledCloseIcon
} from './styles';
import {
  defaultNonEditableRoles,
  defaultRoleDisplay,
  calculateHighestTeamMembershipRoleId
} from 'appUtils/roleDisplay';
import CloseIcon from 'icons/CloseIcon';
import cn from 'classnames';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import MembersDropdown from 'views/projectPlanner/workloadBarModal/MembersDropdown';

const defaultIsMemberEditable = (member) => false;
// for now we are defaulting to no-one being editable on this modal.

const defaultRenderNonEditable = (member) =>
  member.is_admin
    ? 'Admin'
    : defaultRoleDisplay[calculateHighestTeamMembershipRoleId(member)] ||
      'Not Editable';

class AccessModalBody extends React.Component {
  state = { openDropdownId: null, addDropdownOpen: false };
  renderOption = (option, member) => {
    return (
      <StyledDropdownItem
        key={option.label}
        onClick={() => this.handleClickOption(option.value, member)}
      >
        {option.label}
      </StyledDropdownItem>
    );
  };

  handleClickOption = (value, member) => {
    const { handleSelect } = this.props;

    handleSelect(value, member);
    this.setState({ openDropdownId: null });
  };

  renderMember = (member) => {
    const {
      options,
      removeOption,
      handleSelect,
      currentUser,
      isMemberEditable = defaultIsMemberEditable,
      renderNonEditableMember = defaultRenderNonEditable
    } = this.props;
    const { openDropdownId } = this.state;
    const { id } = member.account;
    const isOpen = id === openDropdownId;
    const isEditable = isMemberEditable(member);

    return (
      <VisibilityMemberRow key={id}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          {isEditable && (
            <StyledCloseIcon
              onClick={() => handleSelect(removeOption.value, member)}
            >
              <CloseIcon fill="#4a4a4a" height="10px" width="10px" />
            </StyledCloseIcon>
          )}
          <MemberInitials
            member={member}
            classes={cn('item-account selected', {
              'logged-member-no-hover': member.account.id === currentUser,
              'regular-member-no-hover': member.account.id !== currentUser
            })}
          />
          <MemberName>{member.account.name}</MemberName>
        </div>

        {isEditable ? (
          <Dropdown
            isOpen={isOpen}
            toggle={() => this.setState({ openDropdownId: isOpen ? null : id })}
          >
            <DropdownToggle>
              <StyledDropdownToggle>
                {member.label}
                <StyledDownArrow />
              </StyledDropdownToggle>
            </DropdownToggle>
            <DropdownMenu right className="visibility-options-dropdown">
              {options.map((option) => this.renderOption(option, member))}
            </DropdownMenu>
          </Dropdown>
        ) : (
          <StyledDropdownToggle style={{ paddingRight: '20px' }}>
            {renderNonEditableMember(member)}
          </StyledDropdownToggle>
        )}
      </VisibilityMemberRow>
    );
  };

  openAddDropdown = () => this.setState({ addDropdownOpen: true });
  closeAddDropdown = () => this.setState({ addDropdownOpen: false });
  handleAddMember = (member) => {
    const { handleAdd } = this.props;

    handleAdd(member);
  };

  render() {
    const { actionCopy, members, nonMembers, infoCopy } = this.props;
    const { addDropdownOpen } = this.state;
    return (
      <StyledModalBody>
        <InfoContainer>{infoCopy}</InfoContainer>
        {actionCopy && (
          <ActionContainer
            onClick={this.openAddDropdown}
            ref={(ref) => (this.target = ref)}
          >
            <StyledAddPlusIcon />
            {actionCopy}
          </ActionContainer>
        )}
        <div>{members.map(this.renderMember)}</div>
        {addDropdownOpen && (
          <MembersDropdown
            target={this.target}
            handleClose={this.closeAddDropdown}
            handleSelect={this.handleAddMember}
            members={nonMembers}
          />
        )}
      </StyledModalBody>
    );
  }
}

export default AccessModalBody;
