import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CopyBatchIcon from 'icons/CopyBatchIcon';
import Popover from 'components/Popover';
import { SelectBoardDropdownContainer } from 'views';
import { DELETE_PROJECT_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

const ChangePriorityList = ({
  switchPriority,
  showPriorityOptions,
  isActive,
  editProjectInfo,
  handleClone,
  canArchive,
  canDelete,
  boards,
  boardInfo
}) => {
  const [openCloneMenu, setOpenCloneMenu] = useState(false);
  const cloneTarget = useRef(null);
  const firstOption = isActive ? 'Move To Top' : 'Unarchive';
  const secondOption = isActive ? 'Archive' : 'Delete';
  const getArchiveOrDelete = () => {
    return isActive ? canArchive() : canDelete();
  };

  return (
    <div
      className={cn('project-priority clickable', {
        visible: showPriorityOptions
      })}
    >
      {showPriorityOptions ? <div className="priority-modal-triangle" /> : null}
      <div
        onClick={() => switchPriority(firstOption)}
        className="project-priority-item"
        data-for={!isActive && !canArchive() ? 'app-tooltip' : ''}
        data-effect="solid"
        data-tip="You do not have permission to unarchive projects"
      >
        <div
          className={cn('priority-icon', {
            'move-top': isActive,
            unarchive: !isActive
          })}
        />
        <p>{firstOption}</p>
      </div>
      <div
        onClick={() => switchPriority(secondOption)}
        className={cn('project-priority-item', { 'archive-view': !isActive })}
        data-for={getArchiveOrDelete() ? '' : 'app-tooltip'}
        data-effect="solid"
        data-tip={`You do not have permission to ${
          isActive ? 'archive' : 'delete'
        } projects`}
      >
        <div
          className={cn('priority-icon', {
            archive: isActive,
            trash: !isActive
          })}
        />
        <p className={!isActive ? 'deletetext' : ''}>{secondOption}</p>
      </div>
      {/* <div
        onClick={() => setOpenCloneMenu(true)}
        className={'project-priority-item'}
        data-effect="solid"
        ref={cloneTarget}
      >
        <div className="priority-icon">
          <CopyBatchIcon fill="#4F4F4F" />
        </div>
        <p>Clone</p>
        <Popover
          isOpen={openCloneMenu}
          target={cloneTarget}
          closePopover={() => setOpenCloneMenu(false)}
          placement="bottom"
        >
          <SelectBoardDropdownContainer
            isOpen
            groupList={boards}
            group={boardInfo}
            changeSelectedGroup={board => {
              handleClone(board);
              setOpenCloneMenu(false);
            }}
          />
        </Popover>
      </div> */}
      {isActive && (
        <div
          onClick={() => switchPriority('Delete')}
          className={'project-priority-item'}
          data-for={isActive && !canDelete() ? 'app-tooltip' : ''}
          data-effect="solid"
          data-tip={DELETE_PROJECT_TIP}
        >
          <div className={'priority-icon trash '} />
          <p className={'deletetext'}>Delete</p>
        </div>
      )}
    </div>
  );
};

ChangePriorityList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  switchPriority: PropTypes.func.isRequired,
  canArchive: PropTypes.func.isRequired,
  canDelete: PropTypes.func.isRequired
};

export default ChangePriorityList;
