import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${theme.colors.colorSemiDarkGray6};

  input {
    margin-right: 5px;
  }
  label {
    margin: 0;
  }
`;

const OuterInputButton = styled.div<{ type?: string }>`
  border: 2px solid ${theme.colors.colorLightGray6};
  width: 13px;
  height: 13px;
  background-color: ${theme.colors.colorPureWhite};
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '25%')};
  border-width: thin;
  margin-right: 6px;
`;

const InnerInputButton = styled.div<{ type?: string; checked?: boolean }>`
  width: 9px;
  height: 9px;
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '25%')};
  background-color: ${({ checked }) =>
    checked ? theme.colors.colorRoyalBlue : theme.colors.colorPureWhite};
  transform: translate(11%, 11%);
`;

interface ButtonFieldProps {
  onClick: (name, value, type) => void;
  value: string;
  checked: boolean;
  label: string;
  type: string;
  name: string;
  dataTip?: any;
}

const ButtonField = ({
  onClick,
  value,
  checked,
  label,
  type,
  name,
  dataTip = ''
}: ButtonFieldProps) => {
  return (
    <ButtonsContainer onClick={() => onClick(name, value, type)}>
      <OuterInputButton type={type}>
        {' '}
        <InnerInputButton type={type} checked={checked} />
      </OuterInputButton>

      <label
        data-for="app-tooltip"
        data-class="center"
        data-html
        data-effect="solid"
        data-tip={dataTip}
      >
        {label}
      </label>
    </ButtonsContainer>
  );
};

export default ButtonField;
