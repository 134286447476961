import { useCallback, useEffect, useState, useMemo } from 'react';
import get from 'lodash/get';
import set from 'lodash/fp/set';
import usePrevious from 'appUtils/usePrevious';

export const COLLAPSE_MODES = {
  COLLAPSING: 'COLLAPSING',
  OPENING: 'OPENING'
};
const MODES = COLLAPSE_MODES;

const useCollapse = ({
  defaultAllOpen,
  topLevelCollapseCount,
  toggleCallback
}) => {
  const [mode, setMode] = useState(
    defaultAllOpen ? MODES.COLLAPSING : MODES.OPENING
  );
  const [topCount, setTopCount] = useState(0);
  const prevTopCount = usePrevious(topCount);
  const [collapseValues, setCollapseValues] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(!defaultAllOpen);

  const toggleCollapse = useCallback(
    (key) => {
      const nextValue = !get(collapseValues, key);
      setCollapseValues((currValues) => set(key, nextValue, currValues));
      setTopCount((curr) => curr + (nextValue ? 1 : -1));
      toggleCallback && toggleCallback();
    },
    [toggleCallback, collapseValues]
  );

  useEffect(() => {
    if (prevTopCount !== undefined && topCount !== prevTopCount) {
      if (mode === MODES.OPENING) {
        if (allCollapsed && topCount > 0) {
          setAllCollapsed(false);
        } else if (!allCollapsed && topCount === 0) {
          setAllCollapsed(true);
        }
      } else {
        // in COLLAPSING mode
        if (!allCollapsed && topCount === topLevelCollapseCount) {
          setAllCollapsed(true);
        } else if (allCollapsed && topCount < topLevelCollapseCount) {
          setAllCollapsed(false);
        }
      }
      if (topCount === topLevelCollapseCount) {
        setMode(mode === MODES.COLLAPSING ? MODES.OPENING : MODES.COLLAPSING);
        setTopCount(0);
        setCollapseValues({});
      }
    }
  }, [allCollapsed, mode, prevTopCount, topCount, topLevelCollapseCount]);

  useEffect(() => {
    if (mode === MODES.OPENING && topCount === 0 && !allCollapsed) {
      setAllCollapsed(true);
    } else if (allCollapsed && mode === MODES.OPENING && topCount > 0) {
      setAllCollapsed(false);
    }
  }, [allCollapsed, mode, topCount]);

  const handleCollapseAll = useCallback(() => {
    setAllCollapsed(true);
    setMode(MODES.OPENING);
    setCollapseValues({});
    setTopCount(0);
  }, []);

  const handleExpandAll = useCallback(() => {
    if (allCollapsed) setAllCollapsed(false);
    setMode(MODES.COLLAPSING);
    setCollapseValues({});
    setTopCount(0);
  }, [allCollapsed]);

  const toggleCollapseAll = useCallback(() => {
    if (allCollapsed) {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
    toggleCallback && toggleCallback();
  }, [allCollapsed, handleCollapseAll, handleExpandAll, toggleCallback]);

  const getIsOpen = useCallback(
    (id) => {
      if (mode === MODES.COLLAPSING) {
        return !get(collapseValues, id);
      } else {
        return !!get(collapseValues, id);
      }
    },
    [mode, collapseValues]
  );

  const returnValues = useMemo(
    () => ({
      mode,
      topCount,
      toggleCollapse,
      handleCollapseAll,
      handleExpandAll,
      toggleCollapseAll,
      getIsOpen,
      collapseValues,
      setCollapseValues,
      allCollapsed
    }),
    [
      mode,
      topCount,
      toggleCollapse,
      handleCollapseAll,
      handleExpandAll,
      toggleCollapseAll,
      getIsOpen,
      collapseValues,
      allCollapsed
    ]
  );

  return returnValues;
};

export default useCollapse;
