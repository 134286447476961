import at from 'lodash/at';
import theme from 'theme';

export const calculateProjectColorPreference = ({
  theme,
  projectId,
  id,
  color,
  styleId
}) => {
  const colorPreference = theme.projectColors[styleId || projectId || id];
  return colorPreference || color || defaultPersonalColors[0];
};
export const calculateBarColorPreference = ({
  theme,
  styleId,
  color,
  originType
}) => {
  const barColorPrefence =
    workloadBarColors[calculateColorPref({ theme, styleId, originType })];
  return barColorPrefence;
};
export const calculateBorderColorPreference = ({
  theme,
  styleId,
  color,
  originType
}) => {
  const barColorPrefence =
    phaseBorderColors[calculateColorPref({ theme, styleId, originType })];
  return barColorPrefence;
};

export const calculateBoardBorderColor = ({ theme, boardId, id, color }) => {
  const boardColor =
    theme.boardColors[boardId || id] || theme.boardColors.default;
  return boardBorderColors[boardColor] || color || boardBorderColors.default;
};

export const calculateBoardColorPreference = ({
  theme,
  boardId,
  id,
  color
}) => {
  const colorPreference = theme.boardColors[boardId || id];
  return colorPreference || color || theme.boardColors.default;
};

export const calculateTaskGroupColorPreference = ({
  theme,
  taskGroupId,
  id,
  color,
  isNameEmpty
}) => {
  if (isNameEmpty) {
    return theme.colors.colorLightGray6;
  }
  const colorPreference = theme.taskGroupColors[taskGroupId || id];
  return colorPreference || color;
};
export const calculateTeamMembershipColorPreference = ({
  theme,
  teamMembershipId,
  id,
  color,
  styleId
}) => {
  const colorPreference =
    theme.teamMembershipColors[styleId || teamMembershipId || id];
  return colorPreference || color;
};
export const calculateTeamColorPreference = ({ theme, teamId, id, color }) => {
  const colorPreference = theme.teamColors[teamId || id];
  return colorPreference || color;
};

export const calculateTagColorPreference = ({ theme, tagId, id, color }) => {
  const colorPreference = theme.tagColors[tagId || id];
  return colorPreference || color;
};

const originTypeToCalcColorPreference = {
  project: calculateProjectColorPreference,
  board: calculateBoardColorPreference,
  taskGroup: calculateTaskGroupColorPreference,
  tag: calculateTagColorPreference,
  teamMembership: calculateTeamMembershipColorPreference,
  team: calculateTeamColorPreference
};
export const calculateColorPref = (props) =>
  originTypeToCalcColorPreference[props.originType]?.(props) || props.color;

export const getProjectTitleCircleDisplay = ({ theme, projectId, id }) => {
  return theme.projectColors[projectId || id] ? 'inline-block' : 'none';
};

export const defaultAppColors = [
  theme.colors.colorLightGreen1,
  theme.colors.colorMediumBlue3,
  theme.colors.colorMediumPurple1,
  theme.colors.colorCalendarGray,
  theme.colors.colorLightCyan1,
  theme.colors.colorLightPurple1,
  theme.colors.colorMediumGreen3,
  theme.colors.colorLightPurple2,
  theme.colors.colorDarkPurple1,
  theme.colors.colorGoldenYellow2,
  theme.colors.colorMediumOrange3,
  theme.colors.colorMediumRed2,
  theme.colors.colorMediumCyan2,
  theme.colors.colorMediumYellow2,
  theme.colors.colorLightBrown1
];
// Secondary, 'faded' versions of the above
export const defaultAppColorsFaded = [
  theme.colors.colorPaleGreen2,
  theme.colors.colorPaleBlue3,
  theme.colors.colorPalePurple4,
  theme.colors.colorTranslucentGray3,
  theme.colors.colorPaleCyan1,
  theme.colors.colorPalePurple1,
  theme.colors.colorPaleGreen1,
  theme.colors.colorPalePurple2,
  theme.colors.colorPalePurple3,
  theme.colors.colorPaleBlue3,
  theme.colors.colorPaleYellow2,
  theme.colors.colorPaleOrange1,
  theme.colors.colorPaleRed2,
  theme.colors.colorLightCyan2,
  theme.colors.colorPaleYellow3,
  theme.colors.colorPaleBrown1
];

export const primaryToSecondaryAppColors = defaultAppColors.reduce(
  (acc, cur, index) => {
    acc[cur] = defaultAppColorsFaded[index];
    return acc;
  },
  {}
);

export const defaultTeamColors = [
  '#808080',
  '#12A0EB',
  '#0C02D0',
  '#279889',
  '#BA2790',
  '#4F1A8E',
  '#EDCA14',
  '#E6701A',
  '#E10403'
];

export const defaultPersonalColors = [
  '#6F6F6F',
  '#000000',
  '#12A0EB',
  '#04D098',
  '#FE69B3',
  '#4A2E99',
  '#00C8D8',
  '#F88B5D',
  '#F04948'
];
export const defaultTeamMemberColors = [
  '#828282',
  '#F4A4CC',
  '#B89CD2',
  '#6187A7',
  '#79C1D9',
  '#87BFA0',
  '#DBBC59',
  '#F4B47A',
  '#DB8C8C'
];
export const workloadBarColors = {
  '#6F6F6F': '#F1F1F1',
  '#000000': '#E6E6E6',
  '#12A0EB': '#E7F6FD',
  '#04D098': '#E7FCF6',
  '#0AE2A6': '#E7FCF6',
  '#FE69B3': '#FFF0F7',
  '#4A2E99': '#EDEAF5',
  '#00DFF1': '#E6FCFE',
  '#00C8D8': '#E6FCFE',
  '#F88B5D': '#FEF3EF',
  '#F04948': '#FEEDED',
  '#999999': '#F9F9F9'
};

export const phaseBorderColors = {
  '#6F6F6F': '#575757',
  '#000000': '#000000',
  '#12A0EB': '#108ED1',
  '#04D098': '#04B886',
  '#0AE2A6': '#09C994',
  '#FE69B3': '#E55FA2',
  '#4A2E99': '#3E2680',
  '#00DFF1': '#00C9D9',
  '#00C8D8': '#00B1BF',
  '#F88B5D': '#DE7C53',
  '#F04948': '#D64140',
  '#999999': '#808080'
};

export const defaultTaskPropertyColors = [
  '#CCCCCC',
  '#A0F5FC',
  '#12A0EB',
  '#04D098',
  '#EFDB29',
  '#F88B5D',
  '#EE263F',
  '#CD7BD7',
  '#4A2E99'
];

export const defaultProjectPriorityColors = [
  '#CCCCCC',
  '#A0F5FC',
  '#0085FF',
  '#04D098',
  '#FFC83C',
  '#FF5811',
  '#EE263F',
  '#CD7BD7',
  '#4A2E99'
];

export const boardBorderColors = {
  default: '#4a4a4a',
  '#cdcdcd': '#b5b3b3'
};

export const colorsByColumn = {
  grays: ['#4F4F4F', '#A4A4A4', '#CDCDCD'],
  reds: ['#E90606', '#AC0101', '#EA9999'],
  oranges: ['#FF9900', '#E69138', '#F9CB9C'],
  yellows: ['#E9D527', '#F1C232', '#DFCF77'],
  greens: ['#1AE01A', '#6AA84F', '#B6D7A8'],
  cyans: ['#14EBEB', '#45818E', '#A2C4C9'],
  fadedBlues: ['#4A86E8', '#3C78D8', '#A4C2F4'],
  blues: ['#0000FF', '#3D85C6', '#9FC5E8'],
  purples: ['#8D05E8', '#674EA7', '#B4A7D6'],
  magentas: ['#D70BD7', '#A64D79' /* Custom */]
};

/**
 * Converts an ordered map of columns of colors into a list of colors in
 * row-major order.
 *
 * Optionally filters the list of columns if `orderedColumnsFilter` is set. The
 * order of the filter values is respected.
 */
const colorColumnsToColorList = (colorColumns, orderedColumnsFilter) => {
  const filteredColorColumns = orderedColumnsFilter
    ? at(colorColumns, orderedColumnsFilter)
    : Object.values(colorColumns);

  return filteredColorColumns[0].flatMap((_, columnIndex) =>
    filteredColorColumns.map((row) => row[columnIndex]).filter(Boolean)
  );
};

const defaultColors = colorColumnsToColorList(colorsByColumn, [
  'reds',
  'oranges',
  'yellows',
  'greens',
  'cyans',
  'fadedBlues',
  'blues',
  'purples',
  'magentas'
]);

const projectColors = colorColumnsToColorList(colorsByColumn, [
  'reds',
  'oranges',
  'greens',
  'cyans',
  'fadedBlues',
  'blues',
  'purples',
  'magentas'
]);

const teamMembershipColors = colorColumnsToColorList(colorsByColumn, [
  'grays',
  'reds',
  'oranges',
  'yellows',
  'greens',
  'cyans',
  'fadedBlues',
  'blues',
  'purples',
  'magentas'
]);

export const originTypeToColorsHash = {
  board: defaultColors,
  team: defaultColors,
  tag: defaultColors,
  teamMembership: teamMembershipColors,
  project: projectColors,
  projectManageMembers: defaultColors,
  taskStatus: defaultTaskPropertyColors,
  taskPriority: defaultColors,
  projectStatus: defaultTaskPropertyColors,
  projectStage: defaultTaskPropertyColors,
  projectPriority: defaultColors,
  taskGroup: defaultColors
};

export const generatePageOverlay = ({
  zIndex,
  backgroundColor,
  opacity,
  pointerEvents = 'auto'
}) => `
::before {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  z-index: ${zIndex};
  background-color: ${backgroundColor};
  opacity: ${opacity};
  pointer-events: ${pointerEvents}
  cursor: auto;
}
`;

export const getColumnWidth = (taskProperty, isOnTeamProject) => {
  switch (taskProperty) {
    case 'description':
      return 540;
    case 'due_at':
      return 116;
    case 'status':
      return 115;
    case 'phase_id':
      return 110;
    case 'completed_at':
      return 90; // milestone sits centered
    case 'schedule':
    case 'schedule_start':
      return 110;
    case 'assigned':
      return isOnTeamProject ? 95 : 40;
    case 'completed':
    case 'drag':
      return 30;
    case 'timer':
    case 'taskCompleteCell':
      return 75;
    case 'estimated_hours':
    case 'due':
    default:
      return 95;
  }
};

export const generateColumnWidth = ({ taskProperty, isOnTeamProject }) => `
    width: ${getColumnWidth(taskProperty, isOnTeamProject)}px;
`;

const convertHexToRgb = (hex) => {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
};

export const calculateBoardBorderColorWithOpacity = ({
  theme,
  boardId,
  id,
  color,
  opacity = 0.2,
  strokeColor = [0, 0, 0]
}) => {
  const colorPreference = theme.boardColors[boardId || id];
  const hexColor = colorPreference || color || theme.boardColors.default;
  const boardColor = convertHexToRgb(hexColor);

  // Overlapping an icon over an icon to get opacity effect for the border is a way trickier than originally thought because of the difference of stroke-width.
  // This is a general formula for getting rgb color opacity value with a certain background color.
  const borderColor = strokeColor.map((color, index) =>
    Math.round(opacity * color + (1 - opacity) * boardColor[index])
  );

  return `rgb(${borderColor})`;
};
