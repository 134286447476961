import React from 'react';
import styled from 'styled-components';
import StatusPieChart from 'ReportsModule/components/Time/TimesheetStatusTable/Cells/shared/StatusPieChart';
import { getFormattedChartData } from 'ReportsModule/components/Time/TimesheetStatusTable/utils';
import DevProps from 'components/dev/DevProps';
import moment from 'moment';

const StyledDateCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const DateCell = ({ row: { accountCapacity }, column, value, statusView }) => {
  // If in the middle of the week, capacity total is up to/including today
  const getCapacity = () => {
    if (column.index !== 3) return accountCapacity.total;
    let capacity = 0;
    const date = moment(value.start_date);
    while (date.isSameOrBefore(moment(value.end_date), 'day')) {
      capacity += accountCapacity[date.format('dddd').toLowerCase()];
      date.add(1, 'day');
    }
    return capacity;
  };

  const capacity = getCapacity();

  const chartData = getFormattedChartData({
    data: value,
    capacity,
    statusView,
    isSummary: true
  });

  return (
    <StyledDateCell>
      <DevProps data={{ capacity: chartData.capacity }} />
      <StatusPieChart data={chartData} />
    </StyledDateCell>
  );
};

export default React.memo(DateCell);
