import { RootState, ReducerName } from 'reduxInfra/shared';
import { createSelector, Selector } from '@reduxjs/toolkit';

const getOwnFilterStateId = (
  _: RootState,
  ownProps: {
    [key: string]: unknown;
    filterStateId: string;
  }
) => ownProps.filterStateId;

export const makeGetFilterState = <FilterStateType>(
  reducerName: ReducerName,
  initialFilterState: FilterStateType
) =>
  createSelector<
    [
      Selector<RootState, Record<string, FilterStateType> | undefined>,
      typeof getOwnFilterStateId
    ],
    FilterStateType
  >(
    (state) => state[reducerName]?.filterStates,
    getOwnFilterStateId,
    (filterStates, filterStateId) =>
      filterStates?.[filterStateId] || initialFilterState
  );
