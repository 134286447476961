import styled from 'styled-components';
import { useAppSelector } from 'reduxInfra/hooks';
import { MemberCapacityGroupListItem } from '../types';
import theme from 'theme';
import { teamUtils } from 'TeamsModule/utils';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import isNil from 'lodash/isNil';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';

export const MembersCapacityTableTotalCell = ({ row }) => {
  const { accountId, capacityGroup } =
    row.original as MemberCapacityGroupListItem;
  const teamMembersHash = useAppSelector(getTeamMembersHash);

  const teamMember = teamMembersHash[accountId];

  if (!teamMember) return <></>;

  const capacityGroupHours = Object.keys(capacityGroup.capacity).reduce(
    (acc, val: keyof typeof capacityGroup.capacity) => {
      const capacity = capacityGroup.capacity[val];
      return acc + (capacity || 0);
    },
    0
  );

  const hasTotalToShow = Object.keys(capacityGroup.capacity).some(
    (dayOfWeek) => !isNil(capacityGroup.capacity[dayOfWeek])
  );

  return (
    <RootContainer $isArchived={teamUtils.getIsArchived(teamMember)}>
      {hasTotalToShow
        ? formatNumWithMaxTwoDecimals(capacityGroupHours)
        : undefined}
    </RootContainer>
  );
};

const RootContainer = styled.div<{ $isArchived: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}
  justify-content: center;
  border-left: 1px solid ${theme.colors.colorLightGray6};
`;
