import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import { MemberInitials } from '..';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchMemberBudgets,
  fetchMemberRates,
  createMemberRate,
  deleteMemberBudget
} from 'BudgetModule/actionCreators';
import {
  makeGetActiveRateByMemberBudget,
  getTeamRates,
  getActiveTeamSalaries
} from 'BudgetModule/selectors';
import { getMemberPositionsByMemberBudget } from 'BudgetModule/selectors/positions';
import { fetchPhasesByProjectIds } from 'actionCreators';
import {
  makeGetMemberIsGuestByAccountId,
  getSelectedTeamId,
  getMatchedRouteParams,
  makeGetOwnProjectMemberBudgetsByAccountIds,
  makeGetTeamMembershipByAccountId,
  makeGetProjectMembershipsByAccountId
} from 'selectors';
import DevProps from 'components/dev/DevProps';
import RateSelection from 'BudgetModule/components/BudgetTable/RateSelection';
import PositionsDropdown from 'components/roles/dropdowns/PositionsDropdown';
import SetDefaultPromptModal from 'BudgetModule/components/SetDefaultPromptModal';
import PencilIcon from 'icons/PencilIcon';
import UnassignedMemberIcon from 'icons/UnassignedMemberIcon';
import moment from 'moment';
import useMemberPositions from 'BudgetModule/hooks/useMemberPositions';
import useCurrentRates from 'BudgetModule/hooks/useCurrentRates';
import useCurrentRoles from 'BudgetModule/hooks/useCurrentRoles';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import useMemberBudget from 'appUtils/hooks/budget/useMemberBudget';
import { noop } from 'appUtils';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import { DELETE_PROJECT_MEMBERSHIP_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';

export const Pencil = styled(PencilIcon)`
  height: 12px;
  visibility: hidden;
  margin-left: 5px;
  margin-top: 2px;
  ${(props) => props.positionAbsolute && 'position: absolute;'}
`;

const CostRate = styled.span`
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Cell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 8px;
  white-space: nowrap;
  ${(props) =>
    !props.allowOverflow &&
    `
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }`}
  &:hover {
    ${Pencil} {
      visibility: visible;
    }
  }
`;
const EditableCell = styled(Cell)`
  font-size: 13px;
  padding-left: 10px;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
  &.description {
    padding-left: 8px;
  }
`;
const DeleteCell = styled(Cell)`
  position: absolute;
  left: -32px;
  width: 32px;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  .delete-icon {
    visibility: hidden;
  }
  &:hover:not(.disabled),
  .member-item:hover & {
    .delete-icon {
      visibility: visible;
    }
  }
  color: ${theme.colors.colorMediumGray9};
  &:hover:not(.disabled) {
    color: ${theme.colors.colorCalendarBlue};
  }

  &.disabled {
    cursor: not-allowed;
  }
`;
const MemberName = styled.div`
  margin-left: 7px;
  font-weight: 600;
  font-size: 15px;
  color: ${theme.colors.colorSemiDarkGray1};
  position: relative;
  .invite-member-list .member-item & .member-is-guest {
    font-weight: 400;
    top: 16px;
    left: 0;
  }
`;

const MemberItem = styled.li`
  grid-template-columns: ${(props) =>
    props.includeRates
      ? '225px 197px 75px 183px !important'
      : '400px 260px !important'};
`;

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

/* ------------------------------------------------------------------------ */

const BudgetMemberLine = ({
  member,
  deleteMemberFromSelection,
  me,
  teamMemberModal,
  memberIsGuest,
  projectMembers,
  selection,
  fetchMemberBudgets,
  fetchPhasesByProjectIds,
  fetchMemberRates,
  salaries,
  teamMembership,
  memberBudget,
  memberBudgetId,
  projectId,
  userIsAdmin,
  matchedParams,
  selectedTeamId,
  isFirstRow,
  isLastRow,
  deleteMemberBudget,
  memberPositionsByMemberBudget,
  isHoursOnly
}) => {
  const [showRemoveMemberOverlay, setShowRemoveMemberOverlay] = useState(false);
  const showOverlay = () => setShowRemoveMemberOverlay(true);
  const hideOverlay = () => setShowRemoveMemberOverlay(false);
  const [showRolesDropdown, setShowRolesDropdown] = useState(false);
  const [showRatesDropdown, setShowRatesDropdown] = useState(false);
  const [defaultPromptType, setDefaultPromptType] = useState(null);
  const memberPositions = memberPositionsByMemberBudget[memberBudgetId];
  const rolesDropdownRef = useRef(null);
  const rateChangePopoverRef = useRef(null);
  const { handleUpdateRole } = useMemberPositions({ memberBudget, projectId });
  const { teamRate, memberRate: billRate } = useCurrentRates({ memberBudget });
  const { memberRole: currentRole, teamRole } = useCurrentRoles({
    memberBudget
  });
  const { isUnassigned, name } = useMemberBudget({ memberBudgetId, projectId });

  const getPermissions = () => {
    const { projectId } = matchedParams;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  const onDelete = () => {
    if (isUnassigned && projectId !== undefined) {
      deleteMemberBudget({ projectId, id: memberBudgetId });
    } else if (deleteMemberFromSelection) {
      const permissions = getPermissions();
      if (selection && selection.id) {
        deleteMemberFromSelection(member, selection, me, permissions);
      } else {
        deleteMemberFromSelection(member, null, null, permissions);
      }
    }
    // to update the budget modal if it's open.
    if (projectId) {
      fetchMemberBudgets({ projectId });

      fetchPhasesByProjectIds({
        projectIds: [projectId]
      });
    }
    setShowRemoveMemberOverlay(false);
  };

  const onRateClick = () => {
    fetchMemberRates({ memberBudgetId });
    if (userIsAdmin && !isUnassigned && !teamRate) {
      setDefaultPromptType('rate');
    } else {
      setShowRatesDropdown(true);
    }
  };

  const onRoleClick = () => {
    if (userIsAdmin && teamRole?.is_default) {
      setDefaultPromptType('role');
    } else {
      setShowRolesDropdown(true);
    }
  };

  const disableDelete = teamMemberModal && me && me.id === member?.account?.id;
  const { canDeleteProjectMembership } = useProjectPermissionState({
    projectId
  });

  const costRate = salaries[teamMembership?.id]?.actual_hourly_rate;

  const hasRole = currentRole && !currentRole.is_default;
  return (
    <MemberItem
      key={memberBudgetId}
      className={cn('member-item', {
        'first-row': isFirstRow,
        'last-row': isLastRow
      })}
      includeRates={!isHoursOnly}
      // showCostRate={userIsAdmin}
    >
      <DevProps currentRole={currentRole} memberPositions={memberPositions} />

      {/* Delete icon */}
      {deleteMemberFromSelection && !disableDelete && (
        <DeleteCell
          onClick={canDeleteProjectMembership ? showOverlay : noop}
          {...defaultTooltipProps}
          data-tip={DELETE_PROJECT_MEMBERSHIP_TIP}
          data-tip-disable={canDeleteProjectMembership}
          className={cn({ disabled: !canDeleteProjectMembership })}
        >
          <span className="delete-icon">&times;</span>
        </DeleteCell>
      )}

      {/* Member image and name */}
      <Cell>
        {isUnassigned ? (
          <UnassignedMemberIcon />
        ) : (
          member && (
            <MemberInitials
              member={member}
              isGuest={memberIsGuest}
              inProjectModal={true}
              projectMembers={projectMembers}
              classes={cn('regular-member-no-hover selected', {
                'logged-member-no-hover': me && member.account.id === me.id,
                pending: member.is_pending
              })}
              onClick={(e) => {
                e.preventDefault();
              }}
              nonBaseMemberRoleTop="19px"
              nonBaseMemberRoleLeft="21px"
            />
          )
        )}

        <MemberName
          data-for="app-tooltip"
          data-tip={name}
          data-delay-show={200}
          className="member-name-container"
        >
          <span className="member-name">{name}</span>
          {(memberIsGuest || member?.is_pending) && (
            <span className="member-is-guest">
              {memberIsGuest ? 'Guest' : ''}{' '}
              {member?.is_pending ? 'Pending' : ''}
            </span>
          )}
        </MemberName>
      </Cell>

      <EditableCell onClick={onRoleClick}>
        <span ref={rolesDropdownRef}>
          {hasRole ? currentRole.name : 'Set Role'}
        </span>
        <Pencil />
      </EditableCell>

      {/* Rate */}
      {!isHoursOnly && (
        <>
          <EditableCell
            className="rate"
            allowOverflow={!billRate}
            onClick={onRateClick}
            ref={rateChangePopoverRef}
          >
            <span>
              {billRate
                ? `$${formatNumWithMaxTwoDecimals(billRate.rate)}`
                : 'Set Rate'}
            </span>
            {!billRate && <Pencil />}
          </EditableCell>

          <EditableCell
            data-for="app-tooltip"
            data-tip={billRate ? billRate.description : ''}
            data-delay-show={200}
            className="description"
            onClick={onRateClick}
          >
            <span>{billRate ? billRate.description : ''}</span>
            {billRate && <Pencil />}
          </EditableCell>
        </>
      )}

      {showRatesDropdown && (
        <RateSelection
          handleClose={() => setShowRatesDropdown(false)}
          handleClick={() => {}}
          memberBudget={memberBudget}
          memberBudgetId={memberBudgetId}
          memberName={name}
          projectId={projectId}
          key={`rate-select-${memberBudgetId}`}
          openRateSelectionType={showRatesDropdown ? 'memberRates' : null}
          target={rateChangePopoverRef}
        />
      )}

      {/* Delete confirm overlay */}
      {showRemoveMemberOverlay ? (
        <div className="warning-overlay">
          <p>Are you sure you want to remove this member?</p>
          <div>
            <span onClick={onDelete} className="button">
              Yes
            </span>
            <span onClick={hideOverlay} className="button">
              No
            </span>
          </div>
        </div>
      ) : null}

      {showRolesDropdown && (
        <PositionsDropdown
          onClose={() => setShowRolesDropdown(false)}
          onSelect={handleUpdateRole}
          targetRef={rolesDropdownRef}
          closeOnSelect
        />
      )}

      {defaultPromptType && (
        <SetDefaultPromptModal
          onClose={() => setDefaultPromptType(null)}
          type={defaultPromptType}
          onCancel={() =>
            defaultPromptType === 'role'
              ? setShowRolesDropdown(true)
              : setShowRatesDropdown(true)
          }
        />
      )}
    </MemberItem>
  );
};

const makeMapStateToProps = () => {
  const getMemberIsGuest = makeGetMemberIsGuestByAccountId();
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();
  const getProjectMembershipsByAccountId =
    makeGetProjectMembershipsByAccountId();
  const mapStateToProps = (state, ownProps) => {
    const matchedParams = getMatchedRouteParams(state);
    const projectId = ownProps.projectId || matchedParams.projectId;
    const projectMembershipsByAccountId = getProjectMembershipsByAccountId(
      state,
      { projectId }
    );
    const memberBudget = ownProps.memberBudget;

    return {
      memberIsGuest: getMemberIsGuest(state, ownProps.member),
      teamMembership: getTeamMembershipByAccountId(state, ownProps.member),
      selectedTeamId: getSelectedTeamId(state),
      matchedParams,
      salaries: getActiveTeamSalaries(state),
      memberBudgetId: memberBudget.id,
      memberBudget,
      member:
        projectMembershipsByAccountId[
          memberBudget.project_membership?.account_id
        ],
      projectId,
      memberPositionsByMemberBudget: getMemberPositionsByMemberBudget(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchMemberBudgets,
  fetchPhasesByProjectIds,
  fetchMemberRates,
  createMemberRate,
  deleteMemberBudget
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(BudgetMemberLine)
);
