import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';
import cn from 'classnames';
import FolderIcon from 'icons/FolderIcon';
import { calculateBoardColorPreference } from 'appUtils/styleUtils';

const StyledFolderIcon = styled(FolderIcon)`
  margin-right: 3px;
  width: 20px;
  height: 16px;
  path {
    stroke: ${(props) =>
      calculateBoardColorPreference(props) ||
      props.theme.colors.colorSemiDarkGray1};
    stroke-width: 2;
    fill: ${(props) => calculateBoardColorPreference(props) || `transparent`};
  }
`;

export function changeSelectedGroupWrapper(changeSelectedGroup, board) {
  return (event) => {
    event.preventDefault();
    changeSelectedGroup(board);
  };
}

const SelectBoardDropdown = ({
  isOpen,
  toggle,
  group,
  groupList,
  changeSelectedGroup,
  hideToggle
}) => {
  const hasItems = !!groupList.length;
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle
        className={cn(
          { clickable: hasItems, 'no-group': !group, hide: hideToggle },
          'current-group',
          'board-select-dropdown'
        )}
        direction="right"
      >
        <StyledFolderIcon boardId={group?.id} />
        {group ? group.name : 'Portfolio'}&nbsp;
        {hasItems || isOpen ? <i className="fa fa-angle-down" /> : ''}
      </DropdownToggle>

      {hasItems ? (
        <DropdownMenu>
          {groupList
            .filter((board) => (group ? board.id !== group.id : true))
            .map((board) => (
              <DropdownItem
                type="button"
                onClick={changeSelectedGroupWrapper(changeSelectedGroup, board)}
                key={board.id}
              >
                <StyledFolderIcon boardId={board.id} />
                <span className="board-list-item">{board.name}</span>
              </DropdownItem>
            ))}
        </DropdownMenu>
      ) : (
        ''
      )}
    </Dropdown>
  );
};

SelectBoardDropdown.propTypes = {
  changeSelectedGroup: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groupList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired
};

export default SelectBoardDropdown;
