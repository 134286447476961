import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import LoadAppRouter from './Routers/LoadAppRouter';
import { exchangeAccessToken, navigateTo } from 'actionCreators';
import { setAuthTokenFetchSuccessDetails } from 'AuthenticationModule/actionCreators';

class Routing extends React.Component {
  componentDidMount() {
    const { setAuthTokenFetchSuccessDetails, exchangeAccessToken, navigateTo } =
      this.props;
    // Super admin
    const searchParams = new URLSearchParams(window.location.search);

    // if realm_id is not defined in searchParams, then it returns null
    // no need to check with has method before getting value
    const realmId = searchParams.get('realm_id');

    if (searchParams.has('access_token')) {
      const token = searchParams.get('access_token');
      exchangeAccessToken({ token, realmId });
      navigateTo({ isJustLoggedIn: true });
    } else if (searchParams.has('impersonate')) {
      const token = searchParams.get('impersonate');
      setAuthTokenFetchSuccessDetails({
        token,
        realmId,
        isImpersonating: true
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { auth, location } = this.props;
    const { auth: nextAuth, location: nextLocation } = nextProps;
    const shouldUpdate =
      auth !== nextAuth || location.pathname !== nextLocation.pathname;
    return shouldUpdate;
  }

  render() {
    return <Route path={`/`} component={LoadAppRouter} />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = {
  setAuthTokenFetchSuccessDetails,
  exchangeAccessToken,
  navigateTo
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Routing)
);
