import { useAppDispatch } from 'reduxInfra/hooks';
import { updateTeamMembership } from 'TeamsModule/actionCreators';
import { TimeEntrySettingSelect } from '../TimeEntrySettingSelect';
import {
  TimeEntryAutogenToSettingMap,
  TimeEntrySettingToAutogenMap
} from '../TimeEntrySettingSelect/constants';
import { TimeEntrySettingOption } from '../TimeEntrySettingSelect/types';
import { MemberListItem } from '../types';
import { EmptyDiv } from 'components/EmptyDiv';
import { permissionsUtils } from 'PermissionsModule/utils';

export const MembersTimesheetSettingsTableTimeEntrySettingCell = ({
  row
}: {
  row: {
    original: MemberListItem;
  };
}) => {
  const { teamMember } = row.original;

  const {
    time_entry_autogen_mode: timeEntryAutogenMode,
    id: teamMembershipId
  } = teamMember;
  const dispatch = useAppDispatch();

  const handleSelect = (value: TimeEntrySettingOption) => {
    dispatch(
      updateTeamMembership({
        teamMembershipId,
        timeEntryAutogenMode: TimeEntrySettingToAutogenMap[value]
      })
    );
  };

  const isArchived = permissionsUtils.getIsArchived(teamMember);
  if (isArchived) return <EmptyDiv />;

  return (
    <TimeEntrySettingSelect
      value={TimeEntryAutogenToSettingMap[timeEntryAutogenMode]}
      onSelect={handleSelect}
    />
  );
};
