import React from 'react';
import { connect } from 'react-redux';
import {
  makeGetProjectBasicInfoById,
  makeGetBoardByProjectBasicInfoId,
  getTimelineMemberId,
  getTimesheetDateKeys,
  getFlatPhasesHash
} from 'selectors';
import { updateDescription, deleteDescriptions } from 'actionCreators';
import { TimesheetStyledProjectCell } from './../styles';

import ThreeDotMenu from '../ThreeDotMenu/WeeklyThreeDotMenu';

import { TimeEntryInlineDeleteConfirmation } from 'views';
import { checkTimesheetsForAllNotSubmittedOrRejected } from 'appUtils/timesheetUtils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import TimesheetProject from './index';
const emptyObj = {};

class WeeklyTimesheetProject extends React.Component {
  state = {
    error: '',
    confirmModalOpen: false
  };

  openConfirmModal = () => this.setState({ confirmModalOpen: true });

  closeConfirmModal = () =>
    this.setState({ confirmModalOpen: false, error: '' });

  handleDeleteClick = () => {
    this.openConfirmModal();
  };

  handleDeleteCancel = () => {
    this.closeConfirmModal();
  };

  handleSelectProjectOrPhase = (projectOrPhase) => {
    const {
      updateDescription,
      accountId,
      startOfWeek,
      description,
      activityId,
      phasesHash,
      openActivityDropdown,
      isTimesheetsDisabled
    } = this.props;

    if (isTimesheetsDisabled) {
      return;
    }
    const { is_phase, hasPhases } = projectOrPhase;
    if (is_phase) {
      const phase = phasesHash[projectOrPhase.id];
      if (
        phase?.activity_order?.length &&
        !phase.activity_order.includes(activityId)
      ) {
        openActivityDropdown?.({ phase });
      } else {
        updateDescription({
          phaseId: projectOrPhase.id,
          date: startOfWeek,
          projectId: projectOrPhase.project_id,
          accountId,
          activityId,
          id: description.id,
          title: description.title
        });
      }
    } else if (!hasPhases) {
      updateDescription({
        phaseId: projectOrPhase.defaultOrMainPhaseId,
        date: startOfWeek,
        projectId: projectOrPhase.id,
        accountId,
        activityId,
        id: description.id,
        title: description.title
      });
      // handle default phase
    }
  };

  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  handleDeleteConfirm = () => {
    const { description, deleteDescriptions, date } = this.props;
    const { id, ...timesheets } = description.timesheetsByDate;
    if (checkTimesheetsForAllNotSubmittedOrRejected(timesheets)) {
      deleteDescriptions({
        descriptionIds: [description.id],
        timesheets,
        dateKey: date
      });
      this.closeConfirmModal();
    } else {
      this.setState({
        error: `This entry cannot be deleted because time is either Submitted or Approved. Unsubmit or contact your manager to modify Approved time.`
      });
    }
  };

  renderDropdownHeader = (copy) => <div>{copy}</div>;

  renderMenu = () => {
    const { project } = this.props;
    return (
      <ThreeDotMenu
        projectId={project.id}
        projectSlug={project.slug}
        isPersonal={project.is_personal}
        handleDeleteClick={this.handleDeleteClick}
        size={20}
      />
    );
  };

  render() {
    const { confirmModalOpen, error } = this.state;
    const {
      project,
      board,
      accountId,
      totalLeft,
      phaseId,
      phasesHash,
      description
    } = this.props;
    const phase = phasesHash[phaseId];
    return (
      <>
        <TimesheetProject
          ContainerEl={TimesheetStyledProjectCell}
          renderMenu={this.renderMenu}
          project={project}
          phase={phase}
          board={board}
          accountId={accountId}
          showTopRow
          colorPickerLocation={`timesheet-${description?.id}`}
          handleSelectProjectOrPhase={this.handleSelectProjectOrPhase}
        />
        {confirmModalOpen && (
          <TimeEntryInlineDeleteConfirmation
            handleWeekDeleteCancel={this.handleDeleteCancel}
            handleWeekDeleteConfirm={this.handleDeleteConfirm}
            error={error}
            style={{
              width: `${totalLeft - 2}px`, // -2 border width
              marginLeft: '1px',
              marginTop: '1px',
              border: '1px solid transparent',
              height: '100%'
            }}
          />
        )}
      </>
    );
  }
}
const makeMapStateToProps = () => {
  const getProjectById = makeGetProjectBasicInfoById();
  const getBoardByProjectId = makeGetBoardByProjectBasicInfoId();
  const mapStateToProps = (state, ownProps) => {
    return {
      project: getProjectById(state, ownProps) || emptyObj,
      phasesHash: getFlatPhasesHash(state),
      board: getBoardByProjectId(state, ownProps),
      accountId: getTimelineMemberId(state),
      startOfWeek: getTimesheetDateKeys(state)[0]
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateDescription,
  deleteDescriptions
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(WeeklyTimesheetProject);
