import { fork, takeLatest, takeEvery, all } from 'redux-saga/effects';

import * as constants from '../constants';
import {
  fetchNotesByProject,
  fetchNote,
  deleteNote,
  updateNote,
  postProjectNote
} from './notes';

export default function* noteSaga() {
  const noteSagas = [
    yield fork(
      takeEvery,
      constants.FETCH_NOTES_BY_PROJECT.TRIGGER,
      fetchNotesByProject
    ),

    yield fork(takeLatest, constants.FETCH_NOTE.TRIGGER, fetchNote),

    yield fork(takeLatest, constants.DELETE_NOTE.TRIGGER, deleteNote),
    yield fork(takeLatest, constants.NOTE_UPDATE.TRIGGER, updateNote),
    yield fork(takeEvery, constants.NOTE_CREATION.TRIGGER, postProjectNote)
  ];
  yield all(noteSagas);
}
