import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetFilterState } from 'selectors';
import { fetchCapacityReport } from 'CapacityModule/actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import SimplePieChart from 'components/Charts/SimplePieChart';
import { ReducerName } from 'reduxInfra/shared';
import DevProps from 'components/dev/DevProps';
import { initialFilterState } from 'CapacityModule/reducers/capacityReport';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';

const containerStyles = {
  padding: 0
};

const ChartHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin-bottom: 12px;
  text-align: center;
`;

/* ------------------------------------ - ----------------------------------- */

const StyledTooltip = styled.div`
  padding: 6px 12px;
  background: white;
  border: 1px solid ${theme.colors.colorLightGray21};
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  white-space: nowrap;
`;

const CustomTooltip = ({ payload }) => {
  if (!payload?.[0]) return null;
  return (
    <StyledTooltip>
      <span>{payload[0].name} Hours</span>
      <span style={{ marginLeft: 10, fontWeight: 600 }}>
        {formatNumWithMaxOneDecimal(payload[0].value)}h
      </span>
    </StyledTooltip>
  );
};

/* ------------------------------------ - ----------------------------------- */

const WorkloadWidgetPieChart = ({
  header,
  fetchParams,
  isSelfLoading = true
}) => {
  const dispatch = useDispatch();

  const getCapacityReportData = useMemo(
    () => makeGetFilterState(ReducerName.CapacityReport, initialFilterState),
    []
  );
  const rawData = useSelector((state) =>
    getCapacityReportData(state, {
      filterStateId: fetchParams.filterStateId
    })
  );

  useEffect(() => {
    if (
      isSelfLoading &&
      fetchParams.params.team_id &&
      fetchParams.params.account_ids.length
    ) {
      dispatch(fetchCapacityReport(fetchParams));
    }
  }, [dispatch, fetchParams, isSelfLoading]);

  const formattedData = useMemo(() => {
    const planned = +rawData.totalDemand || 0;
    const available = +rawData.totalCapacity || 0;
    return {
      planned: +rawData.totalDemand || 0,
      available: +rawData.totalCapacity || 0,
      capacity: planned + available // this is total available capacity (ie. capacity - pto - holiday)
    };
  }, [rawData.totalCapacity, rawData.totalDemand]);

  const chartData = useMemo(() => {
    const outer = [
      {
        name: 'Available',
        value: Math.max(formattedData.available, 0) ?? 100,
        color: theme.colors.colorLightGray15
      },
      {
        name: 'Planned',
        value: formattedData.planned,
        color: theme.colors.colorPaleYellow4
      }
    ];
    const inner = [
      // {
      //   name: 'notApproved',
      //   value: formattedData.notApproved,
      //   color: 'transparent'
      // },
      // {
      //   name: 'approved',
      //   value: formattedData.approved,
      //   color: theme.colors.colorMediumGreen6
      // }
    ];
    return { outer, inner };
  }, [formattedData.available, formattedData.planned]);

  return (
    <div>
      <DevProps data={formattedData} />
      <ChartHeader>{header}</ChartHeader>
      <SimplePieChart
        data={chartData.outer}
        innerData={chartData.inner}
        label={`${
          Math.round(
            (formattedData.planned / (formattedData.capacity || 1)) * 100
          ) || 0
        }%`}
        style={containerStyles}
        customTooltip={CustomTooltip}
      />
    </div>
  );
};

export default React.memo(WorkloadWidgetPieChart);
