import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import { SelectBoardDropdown } from '..';

export default class SelectBoardDropdownContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen || false,
      showArrow: false
    };

    bindAll(this, ['toggle', 'renderArrow', 'hideArrow', 'filterGroupList']);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen !== this.state.isOpen
    ) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  toggle() {
    const { toggleDropdown } = this.props;
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
    toggleDropdown && toggleDropdown();
  }

  renderArrow() {
    this.setState({
      showArrow: true
    });
  }

  hideArrow() {
    this.setState({
      showArrow: false
    });
  }

  filterGroupList() {
    const { groupList, group } = this.props;
    return group
      ? groupList.filter((lineGroup) => lineGroup.id !== group.id)
      : groupList;
  }

  render() {
    const filteredGroupList = this.filterGroupList();
    const { group, changeSelectedGroup, hideToggle } = this.props;

    return (
      <SelectBoardDropdown
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        showArrow={this.state.showArrow}
        renderArrow={this.renderArrow}
        hideArrow={this.hideArrow}
        group={group}
        groupList={filteredGroupList}
        changeSelectedGroup={changeSelectedGroup}
        hideToggle={hideToggle}
      />
    );
  }
}

SelectBoardDropdownContainer.propTypes = {
  changeSelectedGroup: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groupList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  group: PropTypes.object.isRequired
};
