import { Draft, createReducer } from '@reduxjs/toolkit';
import {
  ImportLogState,
  FetchCsvImportStatusSuccessResponse,
  ImportsByStatus
} from 'CsvImportsModule/types';
import { MAPPING_STATUSES } from 'CsvImportsModule/constants';
import { fetchCsvImportStatusActionCreatorsMap } from 'CsvImportsModule/actionCreators';

export const initialState: ImportLogState = {
  importsByStatus: {
    [MAPPING_STATUSES.Incomplete]: [],
    [MAPPING_STATUSES.Completed]: [],
    [MAPPING_STATUSES.Processing]: [],
    [MAPPING_STATUSES.Deleted]: []
  },
  importStatusHash: {}
};

const handleFetchCsvImportStatusSuccess = (
  state: Draft<ImportLogState>,
  action
) => {
  const { response } = action.payload as {
    response: FetchCsvImportStatusSuccessResponse;
  };
  state.importStatusHash = response;
  const importsByStatus = Object.values(response).reduce(
    (acc, cur) => {
      const importStatus = cur.status;
      acc[importStatus].push(cur.sessionId);
      return acc;
    },
    {
      [MAPPING_STATUSES.Incomplete]: [],
      [MAPPING_STATUSES.Completed]: [],
      [MAPPING_STATUSES.Processing]: [],
      [MAPPING_STATUSES.Deleted]: []
    } as ImportsByStatus
  );

  state.importsByStatus = importsByStatus;
};

export const importLog = createReducer(initialState, (builder) => {
  builder.addCase(
    fetchCsvImportStatusActionCreatorsMap.success,
    handleFetchCsvImportStatusSuccess
  );
});
