import styled from 'styled-components';
import KaratRight from 'icons/KaratRight';
import { ItemRow, StyledItemName } from './styles';

const StyledKaratContainer = styled.span`
  transform-origin: center center;
  transition: 0.275s ease-in-out;
`;

const SkillRow = ({ item, selectedTeamSkillAndLevel, addedSkills }) => {
  const { isArchived } = item;
  const isCurrentlySelected =
    selectedTeamSkillAndLevel.team_skill_id === item.id;
  const isAdded = addedSkills[item.id];
  return (
    <ItemRow>
      <StyledItemName
        isCurrentlySelected={isCurrentlySelected}
        isAdded={isAdded}
        isArchived={isArchived}
      >
        {item.name}{' '}
      </StyledItemName>
      <StyledKaratContainer isCurrentlySelected={isCurrentlySelected}>
        <KaratRight />
      </StyledKaratContainer>
    </ItemRow>
  );
};

export default SkillRow;
