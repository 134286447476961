import styled from 'styled-components';
import theme from 'theme';
import {
  StyledSelectCellContainer,
  StatusContainer,
  StyledDateCell,
  StyledReportCell,
  StyledReportProject,
  StyledReportHeader,
  StyledRateCell,
  StyledInputHours
} from 'ReportsModule/components/styles';
import { SelectContainer } from 'views/projectPlanner/workloadBarModal/styles';
import { TimesheetTopCell } from 'views/timesheet/Timesheet/TimesheetProject/styles';
import PencilIcon from 'icons/PencilIcon';
import { InnerDropdownOption } from 'views/projectPlanner/styles';
import { RatesDropdownContainer } from 'BudgetModule/components/BudgetTable/styles';

export const IconContainer = styled.div``;
export const HeaderText = styled.div`
  font-size: 20px;
  margin-left: 10px;
  font-weight: 600;
`;
export const TooltipIconContainer = styled.div`
  display: flex;
  margin-left: 4px;
  align-items: center;
`;
export const TooltipIcon = styled.div``;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;
export const PermissionText = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${theme.colors.colorMediumGray5};
  padding-bottom: 4px;
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;
export const Container = styled.div``;

export const StyledAdminInitials = styled.div`
  flex: 1;
`;

export const StyledAdminName = styled.div`
  font-size: 12px;
  font-weight: 600;
  flex: 1;
`;
export const RemoveText = styled.div`
  flex: 1;
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  color: ${theme.colors.colorDeleteRed};
  visibility: hidden;
`;

export const StyledAdminRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  background: ${theme.colors.colorPureWhite};
  border-bottom: 1px solid ${theme.colors.colorPaleGray5};

  &:last-child {
    border-bottom: transparent;
  }

  &:hover {
    ${RemoveText} {
      visibility: visible;
    }
  }
`;

export const StyledAdminEmail = styled.div`
  font-size: 12px;
  flex: 3;
`;
export const StyledGraphicDisplay = styled.div`
  width: 300px;
  display: flex;
  margin-top: 150px;
  justify-content: center;
`;

export const BudgetHeader = styled.div`
  display: flex;
  width: 800px;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  position: sticky;
  z-index: 5;
  top: 0px;
  background: ${theme.colors.colorTranslucentGray4};
  border-bottom: 2px solid ${theme.colors.colorMediumGray5};
  padding-bottom: 10px;
  margin-bottom: 30px;
`;

export const CollapeExpandContainer = styled.div`
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
`;

export const MemberRateDescription = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RateCellContainer = styled.div`
  max-width: 160px;
  display: flex;
  font-size: 15px;
  line-height: 18px;
  font-weight: 18px;
`;

export const RateContainer = styled.div`
  margin-right: 10px;
  font-size: 13px;
`;

export const RatePosition = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
`;

export const SelectRoleContainer = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  display: ${({ isInTable }) => (isInTable ? 'block' : 'none')};
  &:hover {
    font-weight: 600;
  }
`;

export const RateMultiplierContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left-align {
    justify-content: flex-start;
  }
`;

export const StyledDescriptionContainer = styled.div`
  padding-left: 6px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left-align {
    justify-content: flex-start;
  }
`;

export const DescriptionText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
`;

export const StyledRateMultiplierInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledRateMultiplierInput = styled.input`
  border: none;
  text-align: right;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 4px;
  border-radius: 3px;
  color: ${theme.colors.colorMediumGray5};
  background: transparent;
  caret-color: ${theme.colors.colorRoyalBlue};

  &:focus {
    background: ${theme.colors.colorPureWhite};
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 400;
  }
`;

export const StyledRateMultiplierCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  ${StyledRateMultiplierInput} {
    font-size: 13px;
    color: ${theme.colors.colorSemiDarkGray1};
    font-weight: 400;
  }
`;
export const StyledCostCell = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;

  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    svg {
      visibility: visible;
    }
  }
  svg {
    visibility: hidden;
    position: absolute;
    margin-left: 7px;
    margin-top: 3px;
  }
`;
export const StyledRateContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
  ${RatesDropdownContainer} {
    align-items: center;
    justify-content: center;
  }
`;

export const StyledPencilIcon = styled(PencilIcon)`
  margin-left: 4px;
`;

export const CloseButton = styled.div`
  width: 77px;
  height: 30px;
  border-radius: 3px;
  background-color: ${theme.colors.colorRoyalBlue};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    filter: brightness(96%);
  }
`;

export const NoButton = styled.div`
  width: 107px;
  height: 32px;
  border: 1px solid ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: ${theme.colors.colorRoyalBlue};
  margin-right: 25px;
  cursor: pointer;
`;

export const YesButton = styled.div`
  width: 123px;
  height: 32px;
  background-color: #eb5757;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  cursor: pointer;
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

export const RateConfirmRow = styled.div`
  width: 426px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const RateDateContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 130px;
  justify-content: space-between;
`;
export const RateMemberDescription = styled.div`
  display: flex;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;

  .rate-cost {
    margin-right: 14px;
  }
`;

export const RateConfirmBox = styled.div`
  background-color: #ececec;
  padding: 6px;
`;
export const CopyContainer = styled.div`
  color: #4f4f4f;
  white-space: pre-wrap;
`;
export const CopyHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
`;
export const CopyParagraph = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;
export const GreyCopyParagraph = styled.div`
  font-size: 14px;
  margin: 10px 0;
  color: #828282;
`;
export const StyledDateChangeConfirmation = styled.div`
  width: 520 !important;
  position: absolute;
  background-color: white;
  z-index: 1080;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
`;

export const AffectedDate = styled.div`
  color: ${(props) => (props.affected ? '#2f80ed' : '#4F4F4F')};
  white-space: nowrap;
`;

export const OverrideHeader = styled.div`
  margin-bottom: 40px;
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  .karat-container {
    cursor: pointer;
    position: absolute;
    left: 37px;
    margin-right: 8px;
    .karat-icon path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const SubDateText = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorCalendarGray};
  position: absolute;
  bottom: 5px;
`;

export const StyledBudgetSettingsTable = styled.div`
  ${StyledReportHeader} {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 5px;
    padding-bottom: 7px;
    border-bottom: 2px solid ${theme.colors.colorLightGray6};
  }

  .rate ${StyledReportHeader} {
    padding-left: 40px;
  }

  .variable-size-list {
    overflow-x: hidden !important;
  }

  .table {
    height: 100%;
  }
  .table-column-header {
    z-index: 1;
    &.padding {
      border-bottom: 2px solid ${theme.colors.colorLightGray6};
      height: 32px;
    }
  }
  .tr.memberRow {
    ${InnerDropdownOption} {
      cursor: default;
    }
    .show-on-hover {
      display: none;
    }
    .td.role {
      padding-left: 5px;
    }
    &:hover {
      ${StyledCostCell},
      ${StyledRateContainer} {
        color: ${theme.colors.colorRoyalBlue};
      }
      ${StyledDescriptionContainer} {
        color: ${theme.colors.colorRoyalBlue};
      }
      .edit-icon-container {
        visibility: visible !important;
      }
      .show-on-hover {
        display: inline-block;
      }
      .hide-on-hover {
        display: none;
      }
    }
  }
  .tr {
    margin-bottom: 2px !important;
    padding-bottom: 2px;
    color: ${theme.colors.colorSemiDarkGray1};
    .time-reports-pencil-icon {
      display: none;
      height: 15px;
      flex: 0 0 15px;
      margin-left: 5px;
    }
    .styled-member-name {
      font-size: 13px;
      font-weight: normal;
      color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
    }
    &:hover {
      .time-reports-pencil-icon {
        display: inline-flex;
      }
    }
    &:last-child {
      margin-bottom: 30px !important;
    }
    .billable-switch-container {
      display: none;
    }
    .billable-text {
      display: flex;
    }
    .td {
      border-bottom: 1px solid ${theme.colors.colorLightGray12};

      ${RateMultiplierContainer} {
        font-size: 13px;
      }
      ${StyledReportProject} {
        height: 100%;
        margin-top: 1px;
        padding-left: 5px;
        ${TimesheetTopCell} {
          visibility: hidden;
        }
        ${SelectContainer} {
          font-size: 13px;
          position: relative;
          top: 50%;
          margin-top: -1px;
          transform: translateY(-50%);
        }
      }
      ${StyledReportCell} {
        padding: 0px 10px;
        &:focus-within {
          border: 1px solid ${theme.colors.colorRoyalBlue};
        }
      }
      ${StyledDateCell} {
        font-size: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: 600;
      }
      ${StatusContainer} {
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      ${StyledSelectCellContainer} {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ${StyledRateCell} {
        .rate-text {
          font-weight: 400;
        }
      }

      ${StyledInputHours} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 13px;
        font-weight: 400;

        &:focus-within {
          border: 1px solid ${theme.colors.colorRoyalBlue};
        }
        .show-on-row-hover {
          left: 38px;

          &.rate-text {
            left: 57px;
          }
        }
        .hour-text {
          font-size: 13px;
          font-weight: 400;
          opacity: 1;
          visibility: visible;
          color: ${theme.colors.colorSemiDarkGray1};
        }
        input {
          width: 80px;
          font-size: 13px;
          font-weight: 400;
          caret-color: ${theme.colors.colorRoyalBlue};
          color: ${theme.colors.colorSemiDarkGray1};
        }
      }
    }
  }

  .td {
    height: 100%;
    flex-shrink: 0;
    &.padding {
      flex-shrink: 1;
    }
  }

  .tr:hover {
    .label-container {
      justify-content: center;
    }
    .billable-switch-container {
      display: flex;
      justify-content: center;
    }
    .billable-text {
      display: none;
    }
  }
`;
