import React from 'react';
import { connect } from 'react-redux';
import { isUserTeamAdmin } from 'appUtils';
import buildAdminNamesString from 'SettingsModule/utils/buildTeamAdminsString';
import { fetchTeamMembers, inviteTeamMember } from 'actionCreators';
import { getAuth, getUsers } from 'selectors';

import DepartmentsTableContainer from './DepartmentsTable/DepartmentsTableContainer';

class DepartmentsTabs extends React.Component {
  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  render() {
    const { team, users } = this.props;

    if (!users.me || !team) {
      return '';
    }

    const userIsAdmin = isUserTeamAdmin(users.me.id, team);
    const adminNames = buildAdminNamesString(team);

    return (
      <div className="team-settings-modal" style={{ overflow: 'hidden' }}>
        <div className="team-members-tab">
          {!userIsAdmin ? (
            <div className="not-admin-message">
              Contact the admin(s), {adminNames} for any required account
              changes. When a team member gets assigned as Admin (from Member
              tab), he/she will be able to see the Members tab.
            </div>
          ) : (
            <DepartmentsTableContainer />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  users: getUsers(state),
  team: state.teams.allTeams[0],
  teams: state.teams
});

const mapDispatchToProps = {
  fetchTeamMembers,
  inviteTeamMember
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsTabs);
