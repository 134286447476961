import { useCallback, useMemo, useEffect } from 'react';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import useProjectTeamMembersListItemsBuilder from './useProjectTeamMembersListItemsBuilder';
import { ActivityPhaseListItem } from '../types';
import useActivityPhases from 'appUtils/hooks/useActivityPhases/useActivityPhases';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { serializeId } from 'appUtils';

const useProjectTeamActivityPhasesGroupedListBuilder = ({
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState,
  toggleCollapseAll,
  projectId
}: UseNestedCollapseHookReturnedValues & { projectId: number }) => {
  const { getFormattedActivityPhaseByPhaseIdAndActivityId } = useActivityPhases(
    { projectId }
  );

  const memberListItemsBuilder = useProjectTeamMembersListItemsBuilder({
    getIsOpen,
    toggleCollapse,
    getCurrentParentCollapseState,
    setParentCollapseState,
    toggleCollapseAll,
    projectId
  });

  const projectTeamActivityPhasesListBuilder = useCallback(
    ({
      order: activityIdOrder,
      phase,
      isParentOpen,
      parentId,
      openBudgetModal
    }: {
      order: number[];
      phase: Phase;
      isParentOpen: boolean;
      parentId: string;
      openBudgetModal: () => void;
    }) => {
      const lists = activityIdOrder.reduce(
        (acc: ActivityPhaseListItem[], activityId: number) => {
          const activityPhase = getFormattedActivityPhaseByPhaseIdAndActivityId(
            { phaseId: phase.id, activityId }
          );
          if (activityPhase) {
            const activityPhaseToggleId = serializeId({
              itemType: LIST_ITEM_TYPES.ActivityPhase,
              id: activityPhase.id,
              ids: null
            });
            const isActivityPhaseOpen = !!getIsOpen({
              toggleId: activityPhaseToggleId,
              parentId
            });

            const activityPhaseMembershipsOrder =
              activityPhase.activity_phase_memberships.map((apms) => apms.id);

            // Building phase membership lists
            const memberListItems = memberListItemsBuilder({
              hasActivityPhases: true,
              isParentOpen: isActivityPhaseOpen,
              order: activityPhaseMembershipsOrder,
              phaseId: phase.id,
              phase,
              activityPhase,
              openBudgetModal
            });

            const activityPhaseListItem = {
              id: activityPhaseToggleId,
              listItems: memberListItems,
              isOpen: isActivityPhaseOpen,
              isParentOpen: isParentOpen,
              itemType: LIST_ITEM_TYPES.ActivityPhase,
              toggleId: activityPhaseToggleId,
              isEmpty: isActivityPhaseOpen ? !memberListItems.length : true,
              /* -------------------------------------------------------------------------- */
              visibleMembersCount: memberListItems.length,
              hasActivityPhases: true,
              phase,
              activityPhase,
              projectId,
              toggleCollapse: () =>
                toggleCollapse({
                  toggleId: activityPhaseToggleId,
                  parentId
                }),
              openBudgetModal
            };

            acc.push(activityPhaseListItem);
          }

          return acc;
        },
        []
      );

      /* ------------------------ Handling collapse states (in case the lists length change) ------------------------ */

      const collapseState = getCurrentParentCollapseState(parentId);

      // Initializing the default collapse state
      if (!collapseState) {
        setParentCollapseState({
          values: {
            totalNumToggles: lists.length,
            allCollapsed: true
          },
          id: parentId
        });
      } else if (collapseState.totalNumToggles !== lists.length) {
        // update number of toggles
        setParentCollapseState({
          values: {
            totalNumToggles: lists.length
          },
          id: parentId
        });
      }

      return lists;
    },
    [
      getCurrentParentCollapseState,
      getFormattedActivityPhaseByPhaseIdAndActivityId,
      getIsOpen,
      memberListItemsBuilder,
      projectId,
      setParentCollapseState,
      toggleCollapse
    ]
  );

  return projectTeamActivityPhasesListBuilder;
};

export default useProjectTeamActivityPhasesGroupedListBuilder;
