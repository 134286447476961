import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const SaveIcon = ({
  width = 11,
  height = 11,
  color = theme.colors.colorRoyalBlue
}) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.3 10.7V0.3H9.53333H10.7V10.7H0.3Z"
        stroke={color}
        strokeWidth="0.6"
      />
      <path
        d="M8.8002 0V3.66667H2.2002V0.291854"
        stroke={color}
        strokeWidth="0.6"
      />
    </SvgIcon>
  );
};

export default SaveIcon;
