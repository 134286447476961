import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import styled from 'styled-components';

export const LoadingCell = () => (
  <Cell>
    <SkeletonLoader
      numLoaders={3}
      loaderStyle={{ height: 60 }}
      style={{ margin: 0, padding: '0 1rem', width: '100%' }}
    />
  </Cell>
);

const Cell = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;
