import React, { ReactNode, useContext } from 'react';
import { useFilter, UseFilterParams } from 'FilterModule/hooks/useFilter';
import {
  CurrentFilter,
  ParsedFilterSchemaTypeWithCurrentFilterType,
  DraftFilter
} from './types';
import once from 'lodash/once';

type UseFilterResult = ReturnType<typeof useFilter>;

export type FilterContextProviderProps<
  CurrentFilterType extends CurrentFilter = CurrentFilter
> = UseFilterParams<CurrentFilterType> & {
  children?: ReactNode;
};

type FilterContextValues<CurrentFilterType extends CurrentFilter> = Omit<
  UseFilterResult,
  'currentFilter' | 'currentFilterSchema'
> & {
  currentFilter: CurrentFilterType;
  draftFilter: DraftFilter<CurrentFilterType>;
  currentFilterSchema: ParsedFilterSchemaTypeWithCurrentFilterType<
    UseFilterResult['currentFilterSchema'],
    CurrentFilterType
  >;
};

/* ------------------------------------ - ----------------------------------- */

export const FilterContextProvider = <CurrentFilterType extends CurrentFilter>({
  children,
  filterId,
  filterSchemaParams,
  matchingFiltersOverride,
  matchingFakeFiltersOverride,
  overrideUpdate,
  onSave
}: FilterContextProviderProps<CurrentFilterType>) => {
  const {
    registerFilterSchema,
    currentFilterSchema,
    filterInstanceSchema,
    pageFilterSchema,
    currentFilter,
    draftFilter,
    isSideFilterOpen,
    toggleIsSideFilterOpen,
    mainFilterListType,
    mainFilterListField,
    mainFilterListFieldsToValue,
    mainFilterListNumSelected
  } = useFilter<CurrentFilterType>({
    filterId,
    filterSchemaParams,
    matchingFiltersOverride,
    matchingFakeFiltersOverride,
    overrideUpdate,
    onSave
  });

  const filterContextValues = {
    registerFilterSchema,
    filterInstanceSchema,
    currentFilterSchema,
    pageFilterSchema,
    currentFilter,
    draftFilter,
    isSideFilterOpen,
    toggleIsSideFilterOpen,
    mainFilterListType,
    mainFilterListField,
    mainFilterListFieldsToValue,
    mainFilterListNumSelected
  };

  const FilterContext = createFilterContext<CurrentFilterType>();
  FilterContext.displayName = 'FilterContext';

  return (
    <FilterContext.Provider value={filterContextValues}>
      {children}
    </FilterContext.Provider>
  );
};

/* ------------------------------------ - ----------------------------------- */

const createFilterContext = once(<T extends CurrentFilter>() =>
  React.createContext(null as Nullable<FilterContextValues<T>>)
);

// for use with class components
export const FilterContext = createFilterContext<CurrentFilter>();
FilterContext.displayName = 'FilterContext';

export const useFilterContext = <T extends CurrentFilter>() => {
  const context = useContext(createFilterContext<T>());
  if (!context) {
    throw new Error(
      'useFilterContext must be used within a FilterContextProvider'
    );
  }
  return context;
};
