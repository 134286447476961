import styled from 'styled-components';
import theme from 'theme';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { calcColor } from 'BudgetModule/components/BudgetTable/styles';

export const PhaseIcon = styled(BudgetPhaseMilestoneIcon)`
  width: 12px;
  height: 12px;
  path {
    stroke: ${theme.colors.colorMediumGray9};
    stroke-width: 1.3px;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  height: 69px;
  width: 764px;
  margin-left: 78px;
  padding: 5px 0;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorLightGray6};
`;

export const StyledDot = styled.div`
  background: ${({ type, tooltipDotBackground }) =>
    tooltipDotBackground || calcColor[type]};
  height: 8px;
  width: 8px;
  border-radius: 100px;
`;
