import { createSelector } from '@reduxjs/toolkit';
import {
  getNavigationHistory,
  getNavigationState,
  isOnTeamSettingsView
} from 'appCore/navigation/selectors';
import { NavigationHistory } from 'appCore/navigation/types';
import { Views } from 'appConstants/navigation';
import { budgetSettingsModuleConstants } from 'SettingsModule/components/BudgetSettings/constants';
import { getSplitFlags } from 'selectors/core';

const { BudgetSettingsViewTypes, moduleHash, moduleIds } =
  budgetSettingsModuleConstants;

const isOnBudgetSettingsView = (
  navigationHistory: Array<NavigationHistory>,
  index = 0
) => {
  const navigationState = getNavigationState(navigationHistory, index);
  const isOnTeamSettings = isOnTeamSettingsView(navigationHistory, index);
  return isOnTeamSettings && navigationState?.view === Views.BUDGET_SETTINGS;
};

export const getIsOnBudgetSettingsView = createSelector(
  getNavigationHistory,
  isOnBudgetSettingsView
);

export const getInstalledBudgetSettingsModuleIds = createSelector(
  getSplitFlags,
  (splitFlags) => {
    const {
      workCategoryRateGroupFlag,
      newMemberRatesFlag,
      roleRateGroupFlag,
      teamBudgetSettingsFlag
    } = splitFlags;

    const modulesToFilterOut: string[] = [];

    // Remove the Settings tab if it is not enabled.
    if (!teamBudgetSettingsFlag)
      modulesToFilterOut.push(BudgetSettingsViewTypes.settings);

    // Remove the Rates tab if non of the rate subtabs are enabled.
    if (!(newMemberRatesFlag || workCategoryRateGroupFlag || roleRateGroupFlag))
      modulesToFilterOut.push(BudgetSettingsViewTypes.rates);

    const installedModuleIds = moduleIds.filter(
      (moduleId) => !modulesToFilterOut.includes(moduleHash[moduleId])
    );

    return installedModuleIds;
  }
);
