import {
  FilterLevelSchema,
  ParsedPageFilterSchema,
  FilterInstanceSchema,
  ParsedFilterInstanceSchema
} from 'FilterModule/types';

type ParseFilterSchemaParams<S> = {
  schema: S;
} & (S extends Pick<FilterLevelSchema, 'nextLevelKey'>
  ? {
      parsedValues: ParsedPageFilterSchema;
      levelName: string;
    }
  : S extends FilterInstanceSchema
  ? {
      parsedValues: ParsedFilterInstanceSchema;
      levelName?: never;
    }
  : never);

/**
 * Parses the schema and mutates parsedValues
 */
export const parseFilterSchema = <
  S extends FilterInstanceSchema | FilterLevelSchema
>({
  schema,
  parsedValues,
  levelName
}: ParseFilterSchemaParams<S>) => {
  const { mainFilter, fieldSchemas } = schema;

  if (mainFilter) {
    parsedValues.mainFilter = mainFilter;
  }

  // Parse fields of fieldSchemas
  Object.entries(fieldSchemas).forEach(([field, fieldSchema]) => {
    const {
      crossFieldDependencies,
      optionsArray,
      optionHash,
      isSaveable,
      getValidatedFieldValue
    } = fieldSchema;
    parsedValues.fields[field] = {};
    const fieldValues = parsedValues.fields[field];

    if (fieldValues) {
      if (crossFieldDependencies) {
        fieldValues.crossFieldDependencies = crossFieldDependencies;
      }

      if (getValidatedFieldValue) {
        fieldValues.validator = getValidatedFieldValue;
      }
      if (optionsArray || optionHash) {
        fieldValues.optionsConfig = {};
        if (optionsArray) {
          fieldValues.optionsConfig.optionsArray = optionsArray;
        }
        if (optionHash) {
          fieldValues.optionsConfig.optionHash = optionHash;
        }
      }
      if (isSaveable !== undefined) {
        fieldValues.isSaveable = isSaveable;
      }
      if (levelName) {
        fieldValues.levelName = levelName;
      }
    }
  });
};
