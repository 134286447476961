import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SortOptions from './SortOptions';
import { getTimelineViewBy } from 'selectors';
import { setTimelineViewBy } from 'actionCreators';

const TimelineSortOptions = ({
  viewBy,
  setTimelineViewBy,
  isOnPersonalProject
}) => <SortOptions viewBy={viewBy} setViewBy={setTimelineViewBy} />;

const mapStateToProps = (state) => ({
  viewBy: getTimelineViewBy(state)
});

export default withRouter(
  connect(mapStateToProps, { setTimelineViewBy })(TimelineSortOptions)
);
