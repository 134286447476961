import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import Popover from 'components/Popover';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { InlineHelpIcon } from '../styles';
import { SelectDropdownProps } from 'RatesModule/components/SelectInput/SelectInput';
import {
  MenuItem,
  MenuList
} from 'SettingsModule/components/BudgetSettings/Menu';
import { BillRateType } from 'BudgetModule/models/BudgetViewSettings';

export const BILL_RATE_TYPE_COPY: Record<
  BillRateType,
  { label: string; tooltip: string }
> = {
  [BillRateType.Member]: {
    label: 'Member Rates',
    tooltip:
      'Member Rates allows you to track project time cost based on Members’ rates.'
  },
  [BillRateType.Position]: {
    label: 'Role Rates',
    tooltip:
      'Role Rates allows you to track project time cost based on the Role rates.'
  },
  [BillRateType.Activity]: {
    label: 'Work Category Rates',
    tooltip:
      'Work Category Rates allows you to track project time cost based on the Work Category rates.'
  }
} as const;

export const BillRateTypeDropdown = ({
  isOpen,
  onClose,
  onSelect,
  target,
  value
}: SelectDropdownProps<BillRateType> & { value?: BillRateType }) => {
  const handleClose = () => {
    ReactTooltip.hide();
    onClose();
  };

  const handleItemClick = (id: BillRateType) => () => {
    onSelect(id);
    handleClose();
  };

  useEffect(() => () => {
    ReactTooltip.hide();
  });
  useEffect(() => {
    if (isOpen) rebuildTooltip();
    else ReactTooltip.hide();
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      stopPropagation
      target={target}
      togglePopover={handleClose}
    >
      <MenuList>
        {Object.entries(BILL_RATE_TYPE_COPY).map(
          ([itemValue, { label, tooltip }]) => (
            <MenuItem
              {...defaultTooltipProps}
              data-class="center mw-250 w-100vw"
              data-tip={tooltip}
              key={itemValue}
              onClick={handleItemClick(itemValue as BillRateType)}
              selected={itemValue === value}
            >
              {label}
              <InlineHelpIcon />
            </MenuItem>
          )
        )}
      </MenuList>
    </Popover>
  );
};
