import * as constants from '../constants';
import { createAction as createActionUtil } from 'appUtils';
import { createAction } from '@reduxjs/toolkit';
import {
  UpdateProjectBudgetViewSettingsMeta,
  UpdateProjectBudgetViewSettingsParams,
  UpdateTeamBudgetViewSettingsParams
} from 'BudgetModule/api/types';
import { createActionWithMeta } from 'reduxInfra/utils/createActionWithMeta';

export const fetchSpentHoursByDay = (params) =>
  createActionUtil(constants.FETCH_SPENT_HOURS_BY_DAY.TRIGGER, params);
export const fetchPlannedHoursByDay = (params) =>
  createActionUtil(constants.FETCH_PLANNED_HOURS_BY_DAY.TRIGGER, params);

export const fetchMemberBudgetTotals = (params) =>
  createActionUtil(constants.FETCH_MEMBER_BUDGET_TOTALS.TRIGGER, params);
export const fetchMemberBudgets = (params) =>
  createActionUtil(constants.FETCH_MEMBER_BUDGETS.TRIGGER, params);
export const createMemberBudget = (params) =>
  createActionUtil(constants.CREATE_MEMBER_BUDGET.TRIGGER, params);
export const updateMemberBudget = (params) =>
  createActionUtil(constants.UPDATE_MEMBER_BUDGET.TRIGGER, params);
export const assignMemberBudget = (params) =>
  createActionUtil(constants.ASSIGN_MEMBER_BUDGET.TRIGGER, params);
export const fetchMemberBudgetPhase = (params) =>
  createActionUtil(constants.FETCH_MEMBER_BUDGET_PHASE.TRIGGER, params);
export const deleteMemberBudget = (params) =>
  createActionUtil(constants.DELETE_MEMBER_BUDGET.TRIGGER, params);
export const archiveMemberBudgetPhase = (params) =>
  createActionUtil(constants.ARCHIVE_MEMBER_BUDGET_PHASE.TRIGGER, params);
export const unarchiveMemberBudgetPhase = (params) =>
  createActionUtil(constants.UNARCHIVE_MEMBER_BUDGET_PHASE.TRIGGER, params);

export const createPhaseEstimation = (params) =>
  createActionUtil(constants.CREATE_PHASE_ESTIMATION.TRIGGER, params);
export const updatePhaseEstimation = (params) =>
  createActionUtil(constants.UPDATE_PHASE_ESTIMATION.TRIGGER, params);
export const deletePhaseEstimation = (params) =>
  createActionUtil(constants.DELETE_PHASE_ESTIMATION.TRIGGER, params);

export const updateActivityPhase = (params) =>
  createActionUtil(constants.UPDATE_ACTIVITY_PHASE.TRIGGER, params);
export const hardDeleteActivityPhase = (params) =>
  createActionUtil(constants.HARD_DELETE_ACTIVITY_PHASE.TRIGGER, params);

export const openRemoveMemberModal = (params) =>
  createActionUtil(constants.OPEN_REMOVE_MEMBER_MODAL, params);
export const closeRemoveMemberModal = (params) =>
  createActionUtil(constants.CLOSE_REMOVE_MEMBER_MODAL, params);
export const openArchiveMemberModal = (params) =>
  createActionUtil(constants.OPEN_ARCHIVE_MEMBER_MODAL, params);
export const closeArchiveMemberModal = (params) =>
  createActionUtil(constants.CLOSE_ARCHIVE_MEMBER_MODAL, params);

export const openBudgetModal = (params) =>
  createActionUtil(constants.OPEN_BUDGET_MODAL, params);
export const closeBudgetModal = (params) =>
  createActionUtil(constants.CLOSE_BUDGET_MODAL, params);

export const budgetAccessCheck = (params) =>
  createActionUtil(constants.BUDGET_ACCESS_CHECK, params);

export const openActivityDropdown = (params) =>
  createActionUtil(constants.OPEN_ACTIVITY_DROPDOWN, params);

export const closeActivityDropdown = (params) =>
  createActionUtil(constants.CLOSE_ACTIVITY_DROPDOWN, params);

export const openActivityModal = (params) =>
  createActionUtil(constants.OPEN_ACTIVITY_MODAL, params);
export const closeActivityModal = (params) =>
  createActionUtil(constants.CLOSE_ACTIVITY_MODAL, params);

export const updateMemberBudgetOrder = (params) =>
  createActionUtil(constants.UPDATE_MEMBER_BUDGET_ORDER, params);

export const toggleDecimals = () =>
  createActionUtil(constants.TOGGLE_DECIMALS, undefined);

export const fetchTeamMembersRates = (params) =>
  createActionUtil(constants.FETCH_TEAM_MEMBERS_RATES.TRIGGER, params);
export const updateTeamMemberRate = (params) =>
  createActionUtil(constants.UPDATE_TEAM_MEMBER_RATE.TRIGGER, params);

export const fetchAllTeamRates = (params) =>
  createActionUtil(constants.FETCH_ALL_TEAM_RATES.TRIGGER, params);
export const fetchMemberTeamRates = (params) =>
  createActionUtil(constants.FETCH_MEMBER_TEAM_RATES.TRIGGER, params);
export const createMemberTeamRate = (params) =>
  createActionUtil(constants.CREATE_MEMBER_TEAM_RATE.TRIGGER, params);
export const updateMemberTeamRate = (params) =>
  createActionUtil(constants.UPDATE_MEMBER_TEAM_RATE.TRIGGER, params);
export const deleteMemberTeamRate = (params) =>
  createActionUtil(constants.DELETE_MEMBER_TEAM_RATE.TRIGGER, params);

export const fetchRates = (params) =>
  createActionUtil(constants.FETCH_RATES.TRIGGER, params);
export const createRate = (params) =>
  createActionUtil(constants.CREATE_RATE.TRIGGER, params);
export const updateRate = (params) =>
  createActionUtil(constants.UPDATE_RATE.TRIGGER, params);
export const archiveRate = (params) =>
  createActionUtil(constants.ARCHIVE_RATE.TRIGGER, params);
export const unarchiveRate = (params) =>
  createActionUtil(constants.UNARCHIVE_RATE.TRIGGER, params);
// export const deleteRate = params =>
//   createAction(constants.DELETE_RATE.TRIGGER, params);

export const fetchMemberRates = (params) =>
  createActionUtil(constants.FETCH_MEMBER_RATES.TRIGGER, params);
export const createMemberRate = (params) =>
  createActionUtil(constants.CREATE_MEMBER_RATE.TRIGGER, params);
export const updateMemberRate = (params) =>
  createActionUtil(constants.UPDATE_MEMBER_RATE.TRIGGER, params);
export const deleteMemberRate = (params) =>
  createActionUtil(constants.DELETE_MEMBER_RATE.TRIGGER, params);

/* --------------------------------- Salary --------------------------------- */
export const fetchAllTeamSalaries = (params) =>
  createActionUtil(constants.FETCH_ALL_TEAM_SALARIES.TRIGGER, params);
export const fetchMemberSalaries = (params) =>
  createActionUtil(constants.FETCH_MEMBER_SALARIES.TRIGGER, params);
export const createMemberSalary = (params) =>
  createActionUtil(constants.CREATE_MEMBER_SALARY.TRIGGER, params);
export const updateMemberSalary = (params) =>
  createActionUtil(constants.UPDATE_MEMBER_SALARY.TRIGGER, params);
export const deleteMemberSalary = (params) =>
  createActionUtil(constants.DELETE_MEMBER_SALARY.TRIGGER, params);

/* -------------------------------- Overhead -------------------------------- */
export const fetchOverhead = (params) =>
  createActionUtil(constants.FETCH_OVERHEAD.TRIGGER, params);
export const createOverhead = (params) =>
  createActionUtil(constants.CREATE_OVERHEAD.TRIGGER, params);

export const fetchTeamRoles = (params) =>
  createActionUtil(constants.FETCH_TEAM_ROLES.TRIGGER, params);
export const createTeamRole = (params) =>
  createActionUtil(constants.CREATE_TEAM_ROLE.TRIGGER, params);
export const updateTeamRole = (params) =>
  createActionUtil(constants.UPDATE_TEAM_ROLE.TRIGGER, params);
// export const deleteTeamRole = params =>
//   createAction(constants.DELETE_TEAM_ROLE.TRIGGER, params);

export const openProjectPhaseDetails = (params) =>
  createActionUtil(constants.OPEN_PROJECT_PHASE_DETAILS, params);
export const closeProjectPhaseDetails = () =>
  createActionUtil(constants.CLOSE_PROJECT_PHASE_DETAILS, undefined);
export const clearHoursDropdown = () =>
  createActionUtil(constants.CLEAR_HOURS_DROPDOWN, undefined);

export const fetchProjectBudgetViewSettings = (params) =>
  createActionUtil(
    constants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER,
    params
  );
export const updateProjectBudgetViewSettings = createActionWithMeta<
  UpdateProjectBudgetViewSettingsParams,
  UpdateProjectBudgetViewSettingsMeta
>(constants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER);
export const fetchTeamBudgetViewSettings = (params) =>
  createActionUtil(constants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.TRIGGER, params);
export const updateTeamBudgetViewSettings =
  createAction<UpdateTeamBudgetViewSettingsParams>(
    constants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.TRIGGER
  );
export const setInitialMembersOrderByPhaseId = (params) =>
  createActionUtil(constants.SET_INITIAL_MEMBERS_ORDER, params);
export const editMemberRate = (params) =>
  createActionUtil(constants.EDIT_MEMBER_RATE, params);

export const bulkAssignActivity = (params) =>
  createActionUtil(constants.BULK_ASSIGN_ACTIVITY, params);
export const bulkMoveTimeAndRemoveActivity = (params) =>
  createActionUtil(constants.BULK_MOVE_TIME_AND_REMOVE_ACTIVITY, params);
export const bulkDeleteTimeAndRemoveActivity = (params) =>
  createActionUtil(constants.BULK_DELETE_TIME_AND_REMOVE_ACTIVITY, params);

/* --------------------------------- Billing -------------------------------- */

export const fetchProjectBillingCategories = (params) =>
  createActionUtil(constants.FETCH_PROJECT_BILLING_CATEGORIES.TRIGGER, params);
export const fetchProjectInvoices = (params) =>
  createActionUtil(constants.FETCH_PROJECT_INVOICES.TRIGGER, params);
export const createProjectInvoice = (params) =>
  createActionUtil(constants.CREATE_PROJECT_INVOICE.TRIGGER, params);
export const updateProjectInvoice = (params) =>
  createActionUtil(constants.UPDATE_PROJECT_INVOICE.TRIGGER, params);
export const deleteProjectInvoice = (params) =>
  createActionUtil(constants.DELETE_PROJECT_INVOICE.TRIGGER, params);
export const setBillingTableView = (view) => ({
  type: constants.SET_BILLING_TABLE_VIEW,
  payload: {
    view
  }
});

/* ----------------------------- Budget records ----------------------------- */
export const fetchBudgetRecords = (params) =>
  createActionUtil(constants.FETCH_BUDGET_RECORDS.TRIGGER, params);
export const fetchProjectBudgetRecord = (params) =>
  createActionUtil(constants.FETCH_PROJECT_BUDGET_RECORD.TRIGGER, params);
export const clearBudgetRecordsState = (params) =>
  createActionUtil(constants.CLEAR_BUDGET_RECORDS_STATE, params);

/* ----------------------------- Positions ----------------------------- */

export const fetchPositions = (params) =>
  createActionUtil(constants.FETCH_POSITIONS.TRIGGER, params);
export const fetchPosition = (params) =>
  createActionUtil(constants.FETCH_POSITION.TRIGGER, params);
export const createPosition = (params) =>
  createActionUtil(constants.CREATE_POSITION.TRIGGER, params);
export const updatePosition = (params) =>
  createActionUtil(constants.UPDATE_POSITION.TRIGGER, params);
// do not use for now
export const deletePosition = (params) =>
  createActionUtil(constants.DELETE_POSITION.TRIGGER, params);
export const archivePosition = (params) =>
  createActionUtil(constants.ARCHIVE_POSITION.TRIGGER, params);

export const fetchTeamPositions = (params) =>
  createActionUtil(constants.FETCH_TEAM_POSITIONS.TRIGGER, params);
export const createTeamPosition = (params) =>
  createActionUtil(constants.CREATE_TEAM_POSITION.TRIGGER, params);
export const updateTeamPosition = (params) =>
  createActionUtil(constants.UPDATE_TEAM_POSITION.TRIGGER, params);
export const deleteTeamPosition = (params) =>
  createActionUtil(constants.DELETE_TEAM_POSITION.TRIGGER, params);

export const fetchMemberPositions = (params) =>
  createActionUtil(constants.FETCH_MEMBER_POSITIONS.TRIGGER, params);
export const createMemberPosition = (params) =>
  createActionUtil(constants.CREATE_MEMBER_POSITION.TRIGGER, params);
export const updateMemberPosition = (params) =>
  createActionUtil(constants.UPDATE_MEMBER_POSITION.TRIGGER, params);
export const deleteMemberPosition = (params) =>
  createActionUtil(constants.DELETE_MEMBER_POSITION.TRIGGER, params);

export const fetchAccountPositions = (params) =>
  createActionUtil(constants.FETCH_ACCOUNT_POSITIONS.TRIGGER, params);
export const createAccountPosition = (params) =>
  createActionUtil(constants.CREATE_ACCOUNT_POSITION.TRIGGER, params);
export const updateAccountPosition = (params) =>
  createActionUtil(constants.UPDATE_ACCOUNT_POSITION.TRIGGER, params);
export const deleteAccountPosition = (params) =>
  createActionUtil(constants.DELETE_ACCOUNT_POSITION.TRIGGER, params);

export const fetchPositionRates = (params) =>
  createActionUtil(constants.FETCH_POSITION_RATES.TRIGGER, params);
export const createPositionRate = (params) =>
  createActionUtil(constants.CREATE_POSITION_RATE.TRIGGER, params);
export const updatePositionRate = (params) =>
  createActionUtil(constants.UPDATE_POSITION_RATE.TRIGGER, params);
export const deletePositionRate = (params) =>
  createActionUtil(constants.DELETE_POSITION_RATE.TRIGGER, params);

export const createPositionSkills = (params) =>
  createActionUtil(constants.CREATE_POSITION_SKILLS.TRIGGER, params);
export const updatePositionSkills = (params) =>
  createActionUtil(constants.UPDATE_POSITION_SKILLS.TRIGGER, params);
export const fetchPositionSkills = (params) =>
  createActionUtil(constants.FETCH_POSITION_SKILLS.TRIGGER, params);
export const deletePositionSkills = (params) =>
  createActionUtil(constants.DELETE_POSITION_SKILLS.TRIGGER, params);

export const updatePositionLocationRange = (params) =>
  createActionUtil(constants.UPDATE_POSITION_LOCATION_RANGE.TRIGGER, params);
export const deletePositionLocationRange = (params) =>
  createActionUtil(constants.DELETE_POSITION_LOCATION_RANGE.TRIGGER, params);
