import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

// Fix param types
export const createDashboards =
  createAction<SpacePermissionCheckerParams>('CREATE_DASHBOARDS');
export const editDashboards =
  createAction<SpacePermissionCheckerParams>('EDIT_DASHBOARDS');
export const deleteDashboards =
  createAction<SpacePermissionCheckerParams>('DELETE_DASHBOARDS');

export const createWidgets =
  createAction<SpacePermissionCheckerParams>('CREATE_WIDGETS');
export const editWidgets =
  createAction<SpacePermissionCheckerParams>('EDIT_WIDGETS');
export const deleteWidgets =
  createAction<SpacePermissionCheckerParams>('DELETE_WIDGETS');
