import { useEffect } from 'react';
import { fetchTeamMembers } from 'actionCreators';

import { OfficesTableContainer } from './OfficesTable/OfficesTableContainer';
import { useAppDispatch } from 'reduxInfra/hooks';

export const OfficesTab = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, []);

  return (
    <div className="team-settings-modal" style={{ overflow: 'hidden' }}>
      <div className="team-members-tab">
        <div className="team-members-container">
          <OfficesTableContainer />
        </div>
      </div>
    </div>
  );
};
