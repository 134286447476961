import styled from 'styled-components';
import theme from 'theme';
import { TASK_LIST_ITEM_GAP } from 'views/projectPlanner/WorkplanModal/SidebarPanel/taskMenu/constants';

import SmallHamburger from 'icons/SmallHamburger';

export const StyledSmallHamburger = styled(SmallHamburger)`
  margin-right: 6px;
`;

export const SidebarTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 14px;
  padding: 1px 10px;
  color: ${theme.colors.colorRoyalBlue};
  border: 1px solid transparent;
  cursor: pointer;
  svg {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
  ${({ isOpen }) =>
    isOpen
      ? `background: ${theme.colors.colorRoyalBlue};
      color: ${theme.colors.colorPureWhite};
    svg {
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }`
      : ''};
  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorPureWhite};
    svg {
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  position: sticky;
  top: 0px;
  z-index: 1;
  background: ${theme.colors.colorTranslucentGray3};
`;
export const Body = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  .list-item {
    margin-bottom: ${TASK_LIST_ITEM_GAP}px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  }
`;
export const Close = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 5px;
  align-items: center;
  &:hover {
    svg {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const StyledSidebar = styled.div`
  background-color: ${theme.colors.colorTranslucentGray3};
  border-left: 1px solid ${theme.colors.colorPaleGray5};
  box-shadow: 0px 0 10px 0 rgba(195, 195, 195, 0.2);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 380px;
  overflow: hidden;
  position: absolute;
  right: -420px;
  top: 0px;
  transition: 0.2s;
  width: 380px;
  z-index: 201;

  &.isOpen {
    right: 0;
  }

  /** 
   * @deprecated Will be removed once the content from taskDrawerUpdateFlag is
   * finalized.
   */
  &:not(.taskDrawerUpdateFlag) {
    width: 320px;
    min-width: 320px;
    right: -360px;

    &.isOpen {
      right: 0;
    }

    ${Body} {
      padding: 0 20px;
    }
  }
`;
