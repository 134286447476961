import { useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getAlphabeticalSkillIds } from 'selectors';
import { FilterListTypeHookWithGroupings } from 'FilterModule/types';
import { FilterField } from 'FilterModule/constants';
import { useSkills } from 'SettingsModule/hooks';
import { Skill } from 'models/skill';
import { useArrayFilterField, useFilterSearch } from '.';
import { SKILL_LEVELS } from 'appConstants/skills';
import { deserializeSkillLevel } from 'SettingsModule/utils/skills';

const skillLevelsArray = Object.keys(SKILL_LEVELS).map(Number);

const emptyArray = [];

/**
 * Levels grouped by skill
 */
export const useSkillLevelsFilter: FilterListTypeHookWithGroupings = ({
  isOff,
  config,
  isResultsLoading,
  field = FilterField.skillLevels,
  shouldUseDraft
} = {}) => {
  const { handleNavToSkillsPage, skillHash, getSkillLabel, isLoaded } =
    useSkills({
      isOff
    });

  /** Array of alphabetically sorted ids */
  const skillIdsArray: number[] = useAppSelector((state) =>
    isOff ? emptyArray : getAlphabeticalSkillIds(state)
  );

  // Search on skill name, not the grouped items
  const {
    searchText,
    setSearchText,
    optionsArray: topLevelOrder
  } = useFilterSearch({
    items: skillIdsArray,
    itemHash: skillHash,
    itemSearchableKeys: searchableKeys
  });

  const { items, itemHash, ordersByGroup } = useMemo(() => {
    /**
     * each skill will have the same levels to select from, but we need to give each item a unique id
     * so they can be saved on the filter. The id will be `${skillId}-${level}`
     */
    const ordersByGroup = skillIdsArray.reduce<Record<number, string[]>>(
      (acc, skillId) => {
        acc[skillId] = skillLevelsArray.map((level) => `${skillId}-${level}`);
        return acc;
      },
      {}
    );

    const items = Array.from(new Set(Object.values(ordersByGroup).flat()));

    const itemHash = items.reduce<
      Record<string, { level?: string; label: string }>
    >((acc, cur) => {
      const { level } = deserializeSkillLevel(cur);
      acc[cur] = { level, label: `${level} | ${SKILL_LEVELS[level]}` };
      return acc;
    }, {});

    return {
      items,
      itemHash,
      ordersByGroup
    };
  }, [skillIdsArray]);

  const getOnlySelectedItemLabel = ({
    selectedItems
  }: {
    selectedItems?: string[]; // 'skillId-level'
  }) => {
    const selectedSkill = selectedItems?.[0];

    if (!selectedSkill) return '';

    const [skillId, level] = selectedSkill.split('-');
    const skill = skillId ? skillHash[skillId] : undefined;

    return skill && level ? `${skill.name} - Level ${level}` : '';
  };

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items,
    itemHash,
    isOff,
    shouldMaintainOrder: true, // prevent unnecessary sorting
    isFeSearchDisabled: true, // search is on Skill name, which is done above
    config,
    shouldUseDraft,
    getOnlySelectedItemLabel
  });

  return {
    ...arrayFilterFieldValues,
    searchText,
    setSearchText,
    isGrouped: true as Extract<boolean, true>,
    ordersByGroup,
    topLevelOrder,
    isLoading: !isLoaded || isResultsLoading,

    handleNavToEntityPage: handleNavToSkillsPage,
    itemHash,
    labelKey: 'label',
    groupEntityHash: skillHash,
    getGroupEntityLabel: getSkillLabel,
    selectAllItems: undefined
  };
};

/* ------------------------------------ - ----------------------------------- */

const searchableKeys: (keyof Skill)[] = ['name'];
