import { createReducer, Draft } from '@reduxjs/toolkit';
import { setRequestStatus, removeRequestStatusId } from 'actions/statuses';
import { logoutUser } from 'actionCreators';

export interface RequestStatusFields<E = unknown> {
  error: E | null;
  isLoading: boolean;
  isExecuting: boolean;
  isSuccess: boolean;
}

export const initialRequestStatusState: RequestStatusFields = {
  error: null,
  isLoading: false,
  isExecuting: false,
  isSuccess: false
};

export interface StatusesState {
  requestStatuses: Record<string, RequestStatusFields>;
}

const initialState: StatusesState = {
  requestStatuses: {}
};

const handleSetRequestStatus = (
  state: Draft<StatusesState>,
  action: ReturnType<typeof setRequestStatus>
) => {
  const { requestStatusId, ...statuses } = action.payload;
  state.requestStatuses[requestStatusId] = statuses;
};

const handleRemoveRequestStatusId = (
  state: Draft<StatusesState>,
  action: ReturnType<typeof removeRequestStatusId>
) => {
  const requestStatusIdOrArray = action.payload;
  const requestStatusIdsToRemove = Array.isArray(requestStatusIdOrArray)
    ? requestStatusIdOrArray
    : [requestStatusIdOrArray];

  requestStatusIdsToRemove.forEach((requestStatusId) => {
    delete state.requestStatuses[requestStatusId];
  });
};

/**
 * For holding request or any other statuses in one place under arbitrary IDs
 *
 * ---------- For automated request statuses: -----------
 *   1. add 'requestStatusId' to action.payload for any TRIGGER action
 *   2. use getRequestStatus(state, requestStatusId) to get the value
 *
 * The TRIGGER action will set the id's statuses of isExecuting to true, and isLoading to true
 * if action.payload.initial = true;
 *
 * The SUCCESS/FAILURE action will set isLoading and isExecuting to false. SUCCESS will set
 * isSuccess to true, and FAILURE will set error to action.payload.error
 *
 * When you no longer need the requestStatusId, you can dispatch(removeRequestStatusId(requestStatusId))
 */
export const statusesReducer = createReducer(initialState, (builder) => {
  builder.addCase(logoutUser, () => initialState);
  builder.addCase(setRequestStatus, handleSetRequestStatus);
  builder.addCase(removeRequestStatusId, handleRemoveRequestStatusId);
});
