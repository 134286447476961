import { select } from 'redux-saga/effects';
import { changeEntity } from 'sagas/generics';
import { getAuthToken } from 'AuthenticationModule/selectors';
import {
  fetchLeanApi,
  fetchLeanApiActionCreatorsMap
} from 'LeanApiModule/actionCreators';
import * as api from 'LeanApiModule/api';
import { leanApiSchemaVariantBuilderHash } from 'LeanApiModule/common';

export function* fetchLeanApiWorker(action: ReturnType<typeof fetchLeanApi>) {
  const token = yield select(getAuthToken);

  const {
    schemaVariant,
    schema,
    searchText,
    filterParams,
    limit,
    offset,
    all,
    excludeRecords,
    excludeValueCounts
  } = action.payload;

  const apiParams = schemaVariant
    ? leanApiSchemaVariantBuilderHash[schemaVariant]({
        searchText,
        filterParams,
        limit,
        offset,
        all,
        excludeRecords,
        excludeValueCounts
      })
    : schema;

  yield changeEntity(
    fetchLeanApiActionCreatorsMap,
    api.fetchLeanApi,
    [token, apiParams],
    action
  );
}
