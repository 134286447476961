import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import bindAll from 'lodash/bindAll';
import { LoginForm, HandleTrialExpiryModal } from '..';
import {
  loginUser,
  setModal,
  updateTeam,
  cancelTrialExpiry,
  logoutUser,
  fetchUser,
  navigateToHome
} from 'actionCreators';
import { getAuth, getBilling, getUsers, getMe, getSplitFlags } from 'selectors';
import logo from 'images/mosaic-logo.png';
import { SKIP_LOGIN_SERVER } from 'appConstants/dev';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    const { auth, users } = this.props;
    if (auth.token) {
      this.props.fetchUser();
    }
    this.state = {
      stripeLoading: true,
      stripeLoadingError: false,
      token: null
    };

    bindAll(this, ['extendTrial', 'closeSuccessAndUnlock', 'logoutAll']);
  }

  componentDidMount() {
    // if (window.Stripe) {
    //   window.Stripe.setPublishableKey(process.env.STRIPE_PUBLIC_KEY);
    // }

    this.redirectIfLoggedIn(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isTrialNotExpired =
      !this.props.users.me &&
      nextProps.users.me &&
      !nextProps.users.me.team_trial_expired;

    if (
      nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated ||
      nextProps.billing.trialExpiryProcess.triggered !==
        this.props.billing.trialExpiryProcess.triggered
    ) {
      this.redirectIfLoggedIn(nextProps);
    }

    if (isTrialNotExpired) {
      this.props.cancelTrialExpiry();
    }

    // if (window.Stripe) {
    //   window.Stripe.setPublishableKey(process.env.STRIPE_PUBLIC_KEY);
    // }
  }

  redirectIfLoggedIn(props) {
    const { auth, billing, navigateToHome } = props;
    if (
      auth.isAuthenticated &&
      auth.account?.slug &&
      !billing.trialExpiryProcess.triggered
    ) {
      navigateToHome({ userSlug: auth.account.slug });
    } else {
      window.localStorage.clear();
    }
  }

  closeSuccessAndUnlock() {
    const { cancelTrialExpiry } = this.props;

    cancelTrialExpiry();
  }

  extendTrial() {
    const { me, updateTeam } = this.props;
    const payload = {
      team: {
        id: me.default_team && me.default_team.id,
        name: me.default_team && me.default_team.name
      },
      image: null,
      extendTrial: true
    };
    updateTeam(payload);
  }

  logoutAll() {
    this.props.logoutUser();
    // window.Intercom('shutdown');
  }

  render() {
    const { loginUser, auth, billing, setModal, history, authServerLoginFlow } =
      this.props;

    if (auth.isExchangingToken) {
      return null;
    }

    // ensure we don't allow the login UI in this application to appear (briefly) if we are using the auth server login flow and we are NOT trying to override to skip login server
    if (authServerLoginFlow && !SKIP_LOGIN_SERVER) {
      return null;
    }

    return (
      <div className="auth-page">
        <img src={logo} className="login-logo" />
        <LoginForm
          auth={auth}
          handleSubmit={loginUser}
          errorMessage={auth.statusText}
          forceOnboard={() => history.push('/onboarding')}
          users={this.props.users}
          goToSignup={() =>
            window.location.replace('https://get.mosaicapp.com/get-demo')
          }
        />
        <HandleTrialExpiryModal
          setModal={setModal}
          logoutUser={this.logoutAll}
          billing={billing}
          extendTrial={this.extendTrial}
        />
      </div>
    );
  }
}

LoginContainer.propTypes = {
  loginUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const query = new URLSearchParams(
    ownProps.location.pathname + ownProps.location.search
  );
  const redirect = query && query.get('redirect');

  return {
    redirectRoute: redirect || '/home/tasks',
    auth: getAuth(state),
    billing: getBilling(state),
    users: getUsers(state),
    me: getMe(state),
    authServerLoginFlow: getSplitFlags(state).authServerLoginFlow
  };
};

const mapDispatchToProps = {
  loginUser,
  setModal,
  updateTeam,
  cancelTrialExpiry,
  logoutUser,
  fetchUser,
  navigateToHome
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
