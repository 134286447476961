/* eslint-disable */
import integrities from './integrities.json';

const loadGoogleTag = () => {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-135258078-1';
  script.integrity = integrities.googletagmanagerGlobal;
  script.crossOrigin = 'anonymous';
  head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'UA-135258078-1');

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.integrity = integrities.googleTagManager;
    j.crossOrigin = 'anonymous';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-PWJVJD4');
};

export const initGoogleTag = () => {
  loadGoogleTag();
};
