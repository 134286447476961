import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const StyledText = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
  margin-left: -10px;
`;

const RateCell = ({ cell, row }) => {
  const { userActivity } = row.original;
  const rate = userActivity?.display_data?.time_entry?.rate;
  return <StyledText>{rate ? `$${rate}/h` : ``}</StyledText>;
};

export default RateCell;
