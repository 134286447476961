import React from 'react';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import UnscheduledSlideoutMenu from './UnscheduledSlideoutMenu';
import { PlannerRow } from '..';
import { updateDayPlanner } from 'actionCreators';
import { DragDropContext } from 'react-beautiful-dnd';
import { UNSCHEDULED } from 'appConstants/planners';
import { formatDroppableId, keyifyDate } from 'appUtils/plannerUtils';
import { isOnTeamTaskPlanner } from 'appUtils/views';
import { homePlannerDragClasses } from 'appConstants/homePlannerDragClasses';
import {
  getNumOverdueAllTasks,
  getTaskPlannerMembers,
  getPlannerRowHeights,
  getMe,
  getTaskPlannerMemberIds,
  getHomePlannerDateRange,
  getAuth,
  getIsFetchingHomePlanner,
  getNavigationHistory,
  getNonPlannerMembersArray
} from 'selectors';
import MembersColumn from './MembersColumn';
import moment from 'moment';
import styled from 'styled-components';
import { AddMemberRowHeight } from '../projectPlanner/WorkloadPlannerGroupRenderer';

const PlannerScrollContainer = styled.div`
  position: relative;
  touch-action: none;
  height: calc(100vh - 198px);
  overflow-y: auto;
  margin-right: -25px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

class HomePlannerScrollableContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: null,
      mouseDragging: false,
      mouseX: 0,
      scrollTop: 0
    };
  }

  updateDayPlanner = (params) =>
    this.props.updateDayPlanner(this.props.auth.token, params);

  setScrollableDndRef = (dndEl) => (this.scrollableWindow = dndEl);

  onDragEnd = (result) => {
    const { draggableId, source, destination } = result;
    // draggable IDs are formatted personal-## or ## for project tasks
    const [taskType, taskId] = draggableId.split('--');

    // dropped outside the list
    if (!destination) {
      return;
    }

    // const destinationDate = formatDroppableId(destination.droppableId);
    const [newDateKey, newAccountId] = formatDroppableId(
      destination.droppableId
    );
    const [oldDateKey, oldAccountId] = formatDroppableId(source.droppableId);

    this.updateDayPlanner({
      taskId,
      position: destination.index,
      isPersonal: taskType === 'personal',
      date:
        newDateKey === UNSCHEDULED
          ? null
          : moment(newDateKey).format('YYYY-MM-DD'),
      laneId: newDateKey,
      dateKey: oldDateKey,
      newAccountId,
      oldAccountId,
      taskType
    });
  };

  createPlannerLanes(dateRange, planners) {
    return dateRange.map((date) => {
      const targetInPlanner = planners[keyifyDate(date)];
      return {
        date: date,
        id: keyifyDate(date),
        plannerId: targetInPlanner ? targetInPlanner.id : null,
        tasks: !targetInPlanner ? [] : targetInPlanner.tasks
      };
    });
  }

  onMouseDown = (e) => {
    const className = e.target.className;
    if (homePlannerDragClasses[className]) {
      e.preventDefault();
      this.setState({ mouseDragging: true, mouseX: e.clientX });
    }
  };

  onMouseUp = (e) => {
    e.preventDefault();
    this.setState({ mouseDragging: false });
  };

  onMouseMove = (e) => {
    const { mouseDragging, mouseX } = this.state;
    e.preventDefault();
    if (mouseDragging) {
      const newMouseX = e.clientX;
      const diff = mouseX - newMouseX;
      this.setState({ mouseX: newMouseX });
      this.scrollableWindow.scrollLeft += diff;
    }
  };
  onDndScroll = (e) => {
    const { handleScroll } = this.props;
    throttle((e) => handleScroll(e), 100);
    this.setState({ scrollTop: e.target.scrollTop });
  };
  syncScroll = (scrollTop) => {
    scrollTop !== this.state.scrollTop &&
      this.scrollableWindow.scrollTo &&
      this.scrollableWindow.scrollTo(
        this.scrollableWindow.scrollLeft,
        scrollTop
      );
    this.setState({ scrollTop });
  };

  render() {
    const {
      dateRange,
      unscheduledOpen,
      onCloseUnscheduled,
      toggleBulkUpdateModal,
      numOverdueTasks,
      plannerRowHeights,
      plannerMembers,
      fetchPlanners,
      localDateHeader,
      isAlreadyFetching,
      navigationHistory,
      nonPlannerMembers
    } = this.props;

    const shouldRenderAddMember = nonPlannerMembers.length;
    const genProps = {
      unscheduledOpen,
      toggleBulkUpdateModal,
      localDateHeader,
      fetchPlanners,
      isAlreadyFetching,
      pastScheduledTasksCount: numOverdueTasks,
      isOnTeamTaskPlanner: isOnTeamTaskPlanner(navigationHistory)
    };
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <PlannerScrollContainer>
          <UnscheduledSlideoutMenu
            isOpen={unscheduledOpen}
            onClose={onCloseUnscheduled}
            includesTeam={isOnTeamTaskPlanner(navigationHistory)}
          />
          <div
            className={cn('scrollable-dnd', {
              'unscheduled-open': unscheduledOpen,
              dragging: this.state.mouseDragging
            })}
            onScroll={this.onDndScroll}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            onMouseLeave={this.onMouseUp}
            onMouseMove={this.onMouseMove}
            ref={this.setScrollableDndRef}
          >
            {isOnTeamTaskPlanner(navigationHistory) && (
              <MembersColumn
                isUnscheduledOpen={unscheduledOpen}
                members={plannerMembers}
                plannerRowHeights={plannerRowHeights}
                scrollTop={this.state.scrollTop}
                handleScroll={this.syncScroll}
              />
            )}
            <PlannerRow
              rowHeight={100}
              key={'header'}
              dateRange={dateRange}
              accountId={-1}
              index={-1}
              {...genProps}
            />
            {plannerMembers.map((member, index) => {
              return member ? (
                <PlannerRow
                  rowHeight={plannerRowHeights[member.account_id]}
                  key={member.id}
                  dateRange={dateRange}
                  accountId={member.account_id}
                  index={index}
                  member={member}
                  {...genProps}
                />
              ) : null;
            })}
            {shouldRenderAddMember && (
              <PlannerRow
                rowHeight={AddMemberRowHeight}
                key={'footer'}
                dateRange={dateRange}
                accountId={-1}
                index={plannerMembers.length}
                {...genProps}
                isFooter
              />
            )}
          </div>
        </PlannerScrollContainer>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  auth: getAuth(state),
  dateRange: getHomePlannerDateRange(state),
  me: getMe(state),
  plannerMembers: getTaskPlannerMembers(state, ownProps),
  numOverdueTasks: getNumOverdueAllTasks(state),
  plannerRowHeights: getPlannerRowHeights(state),
  memberIds: getTaskPlannerMemberIds(state, ownProps),
  isAlreadyFetching: getIsFetchingHomePlanner(state),
  navigationHistory: getNavigationHistory(state),
  nonPlannerMembers: getNonPlannerMembersArray(state)
});

const mapDispatchToProps = {
  updateDayPlanner
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePlannerScrollableContainer)
);
