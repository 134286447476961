import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';

const WorkloadContainer = lazy(() => import('./WorkloadContainer'));
const LoadWorkloadContainer = () => (
  <DynamicModuleLoader modules={[getCapacitiesModule()]}>
    <Suspense fallback={<div />}>
      <WorkloadContainer />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadWorkloadContainer;
