import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ColumnDraggable from './ColumnDraggable';
import AddColumnIcon from 'icons/AddColumnIcon';
import {
  StyledListBreak,
  StyledColumnOrderDroppable,
  ColumnOrderDropdownButton,
  ColumnOrderDropdownWrapper,
  ColumnOrderDropdownHeader,
  StyledColumnOrderDropdown,
  DropdownHeaderLeft,
  HelpIconContainer,
  CloseButton,
  HeaderText
} from './styles';
import Popover from 'components/Popover';
import IsRemovingColumnModal from './IsRemovingColumnModal';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { stopPropagation } from 'appUtils';
import HeaderTooltipIcon from 'icons/HeaderTooltipIcon';

const indexBreak = (array) => array.indexOf('break');
const sliceBreak = (array) => array.slice(0, indexBreak(array));

class ColumnsDropdown extends Component {
  state = {
    isDropdownOpen: false,
    isRemoving: false,
    index: null,
    order: []
  };

  renderDropdownOption = (column, index) => {
    const {
      DraggableComponent = ColumnDraggable,
      renderLabel,
      columns,
      getIsActive,
      disableDrag
    } = this.props;
    const breakIndex = indexBreak(columns);
    return (
      <Draggable
        key={column}
        draggableId={`${column}`}
        index={index}
        isDragDisabled={disableDrag || index > breakIndex}
      >
        {(provided, snapshot) =>
          column === 'break' ? (
            index < columns.length - 1 ? (
              <StyledListBreak
                key={column}
                index={index}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                isDragDisabled={true}
              />
            ) : (
              <div
                key={column}
                index={index}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                isDragDisabled={true}
              />
            )
          ) : (
            <DraggableComponent
              index={index}
              column={column}
              provided={provided}
              snapshot={snapshot}
              isActive={
                getIsActive ? getIsActive(column) : index < indexBreak(columns)
              }
              handleClick={this.handleClick}
              renderLabel={renderLabel}
              disableDrag={disableDrag}
            />
          )
        }
      </Draggable>
    );
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
      isRemoving: false
    }));
  };

  openDropdown = () => {
    this.setState({
      isDropdownOpen: true
    });
  };

  closeDropdown = () => {
    this.setState({
      isDropdownOpen: false
    });
  };

  onDragEnd = (result) => {
    const { columns } = this.props;
    const { destination, draggableId } = result;
    if (!destination) {
      return;
    }
    const order = columns.filter((column) => column !== draggableId);
    order.splice(destination.index, 0, draggableId);
    this.reorder(order);
  };

  handleConfirm = () => {
    const { index } = this.state;
    const { columns } = this.props;

    const breakIndex = indexBreak(columns);
    const newOrder = columns.filter((column, i) => i !== index);
    newOrder.splice(breakIndex, 0, columns[index]);
    this.reorder(newOrder);
  };

  reorder = (order) => {
    const { handleReorder } = this.props;
    this.setState({
      order
    });
    const updatedColumns = sliceBreak(order);
    handleReorder(updatedColumns);

    this.handleCancel();
  };

  handleCancel = () => {
    this.setState({
      isRemoving: false,
      index: null
    });
  };

  handleClick = (index, column) => {
    const { columns, handleClick } = this.props;
    if (handleClick) {
      handleClick({ index, column });
    } else {
      const breakIndex = indexBreak(columns);
      const isRemoving = index < breakIndex;

      if (isRemoving) {
        this.setState({ isRemoving, index });
      } else {
        this.setState({ index }, this.handleConfirm);
      }
    }
  };

  popoverTarget = React.createRef();

  renderButton = () => (
    <ColumnOrderDropdownButton
      show={true}
      data-for="app-tooltip"
      data-tip="Add Column"
      data-effect="solid"
      data-place="left"
    >
      <div className="dropdown-header-content">
        <AddColumnIcon fillcolor="#0074d9" strokecolor="#fff" />
        <div className="add-text">Add</div>
      </div>
    </ColumnOrderDropdownButton>
  );

  render() {
    const {
      hideContents,
      show,
      columns,
      removeSubtext,
      customStyles,
      isOnProjectView,
      renderButton,
      headerText = 'Edit Columns',
      offsetRight = 0,
      noHeaderTooltip,
      isDisabled
    } = this.props;
    const { isDropdownOpen, isRemoving } = this.state;
    const renderButtonToUse = renderButton || this.renderButton;
    return (
      <>
        <ColumnOrderDropdownWrapper
          data-testid="column-order-dropdown"
          onClick={!isDisabled ? this.toggleDropdown : undefined}
          isDropdownOpen={isDropdownOpen}
          customStyles={customStyles}
          show={show}
          ref={this.popoverTarget}
        >
          {renderButtonToUse()}
          {!hideContents &&
            (isDropdownOpen ? (
              <Popover
                isOpen
                target={this.popoverTarget}
                closePopover={this.closeDropdown}
                boundariesElement="window"
                placement="bottom-end"
              >
                <StyledColumnOrderDropdown
                  onClick={stopPropagation}
                  offsetRight={offsetRight}
                >
                  {!isRemoving ? (
                    <ColumnOrderDropdownHeader>
                      <DropdownHeaderLeft>
                        <ReactTooltip
                          id="help-column-dropdown"
                          class="help-column-dropdown-tooltip"
                          effect="solid"
                          place="top"
                        />
                        <HeaderText>{headerText}</HeaderText>
                        {!noHeaderTooltip && (
                          <HelpIconContainer
                            data-for="help-column-dropdown"
                            data-tip={`This will add the column to the view for all members on this ${
                              isOnProjectView ? 'project.' : 'portfolio.'
                            } `}
                          >
                            <HeaderTooltipIcon />
                          </HelpIconContainer>
                        )}
                      </DropdownHeaderLeft>
                      <CloseButton onClick={this.closeDropdown}>
                        Done
                      </CloseButton>
                    </ColumnOrderDropdownHeader>
                  ) : null}
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    {!isRemoving && columns ? (
                      <StyledColumnOrderDroppable>
                        <Droppable droppableId="task-columns">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {columns.map(this.renderDropdownOption)}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </StyledColumnOrderDroppable>
                    ) : null}
                  </DragDropContext>
                </StyledColumnOrderDropdown>
              </Popover>
            ) : null)}
        </ColumnOrderDropdownWrapper>
        <IsRemovingColumnModal
          isOpen={isRemoving}
          handleConfirm={this.handleConfirm}
          handleCancel={this.handleCancel}
          removeSubtext={removeSubtext}
        />
      </>
    );
  }
}

export default ColumnsDropdown;
