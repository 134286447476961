import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ArrowUpIcon = ({
  width = '12',
  height = '12',
  fill = '#0074D9',
  ...rest
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 12 12"
    {...rest}
    fill="transparent"
  >
    <path
      d="M2.3999 6L5.9999 2.4L9.5999 6"
      stroke="#4F4F4F"
      strokeLinejoin="round"
    />
    <path d="M6 10.2L6 2.4" stroke="#4F4F4F" strokeLinejoin="round" />
  </SvgIcon>
);

export default ArrowUpIcon;
