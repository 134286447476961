import { put, select, call, all, take } from 'redux-saga/effects';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import { api } from '../service';
import {
  getSelectedAccountIds,
  getSearchText,
  getBoardSearchText,
  getShouldUseBoard,
  cachedProjects,
  getCurrentUserId,
  getOnScheduleView,
  getMyProjectsLength,
  getBillingCurrentModal,
  getIsCreatingProject,
  getAuthToken,
  isProjectCommentsOpen,
  getEditingNoteId,
  getTeamSlug,
  getBoardSortProperty,
  getAccountSlug,
  getProjectHash
} from 'selectors';
import {
  fetchProjectById as fetchProjectByIdAction,
  fetchMyProjects as fetchMyProjectsActionCreator,
  fetchAllProjects,
  fetchBoardMembers,
  fetchProjectsByGroup as fetchProjectsByGroupActionCreator,
  fetchProjectTeam,
  fetchTeamMembers,
  closeEditProjectModal,
  toggleDeleteProjectModal,
  populateCachedProjects,
  setModal,
  navigateToProject,
  navigateToSettings,
  navigateToBoard,
  navigateToHome,
  removeTaskGroupFromFetchQueue,
  addTaskGroupToFetchQueue,
  fetchProjectTaskGroupTasks as fetchProjectTaskGroupTasksActionCreator,
  archivePhaseMember,
  deleteProjectFromOrder,
  fetchGroups,
  fetchPhasesByProjectIds
} from 'actionCreators';
import * as constants from 'appConstants';
import WAYPOINT_INTS from 'appConstants/waypointInts';
import { createResponseEntityHandler } from 'appUtils';
import apiRequestSaga from 'appUtils/request';
import produce from 'immer';
import { getTaskFetchConfig } from './homeTasks';
import { fetchMemberBudgets } from 'BudgetModule/actionCreators';
import defaultCloneProjectFlags from '../constants/cloneProjectFlags';

const {
  fetchProjects,
  fetchProjectClients,
  project,
  projectCreation,
  statusCreation,
  statusEdit,
  statusDelete,
  projectEdition,
  updateProjectBillable,
  projectUpdatePostion,
  updateProjectArchiveFlag,
  updateBatchProjectArchiveFlag,
  projectStarUpdate,
  deleteMemberFromProject,
  notificationPreferencesUpdate,
  projectModulesUpdate,
  moveProjectToBoard,
  cloneProject
} = entityActions;

export function* postProjectComment(action) {
  const { projectId, description, account, parentId, files } = action.payload;
  const token = yield select(getAuthToken);
  const accountId = account.id;

  const { error } = yield changeEntity(statusCreation, api.postProjectComment, [
    token,
    projectId,
    description,
    accountId,
    parentId,
    files
  ]);
}

export function* editProjectComment(action) {
  const { projectId, description, accountId, commentId, tempFiles } =
    action.payload;
  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(statusEdit, api.editProjectComment, [
    token,
    projectId,
    description,
    accountId,
    commentId,
    tempFiles
  ]);
}

export function* deleteProjectComment(action) {
  const { projectId, commentId } = action.payload;
  const token = yield select(getAuthToken);
  const fromAddEditDeleteProjectStatus = true;
  const { error } = yield changeEntity(statusDelete, api.deleteProjectComment, [
    token,
    commentId
  ]);
  if (!error) {
    yield put(
      fetchProjectByIdAction(
        projectId,
        undefined,
        fromAddEditDeleteProjectStatus,
        null,
        null,
        true
      )
    );
    yield put(populateCachedProjects());
  }
}

export function* fetchProjectsByGroup(action) {
  const token = yield select(getAuthToken);
  let {
    groupId,
    limit,
    offset,
    isActive,
    searchText,
    accountIds,
    all,
    view_type
  } = action.payload.params;
  const baseLimit = limit || WAYPOINT_INTS.projects.baseLimit;
  const boardSearchText = yield select(getBoardSearchText);
  const shouldUseBoard = yield select(getShouldUseBoard);
  const cachedProjectsArr = yield select(cachedProjects);
  const sort = yield select(getBoardSortProperty);

  if (!searchText && boardSearchText && shouldUseBoard) {
    searchText = boardSearchText;
  }

  let attempt;

  if (limit) {
    // Load next projects in a specific section
    attempt = yield fetchEntity(
      fetchProjects,
      api.fetchProjectsByBoard,
      undefined,
      [
        groupId,
        token,
        isActive,
        limit,
        offset,
        searchText,
        accountIds,
        all,
        view_type,
        sort
      ],
      action
    );

    if (!attempt.error && !searchText && accountIds && !accountIds.length) {
      yield put(populateCachedProjects());
    }
  } else {
    // Initial load of 20 projects in each section
    attempt = yield fetchEntity(
      fetchProjects,
      api.fetchProjectsByBoard,
      undefined,
      [
        groupId,
        token,
        isActive,
        baseLimit,
        0,
        searchText,
        accountIds,
        all,
        view_type,
        sort
      ],
      action
    );

    if (!attempt.error && !cachedProjectsArr.length) {
      yield put(populateCachedProjects());
    }
  }
  if (attempt?.response?.projects?.length) {
    yield put(
      fetchAllProjects({
        projectIds: attempt.response.projects.map((project) => project.id),
        is_archived: null
      })
    );
  }
}

export function* fetchProjectById(action) {
  const accountIds = yield select(getSelectedAccountIds);
  const searchText = yield select(getSearchText);
  const token = yield select(getAuthToken);

  let { projectId, fromAddEditDeleteProjectStatus, limit, isProjectDetail } =
    action.payload;
  const isScheduleView = false;

  const { response, error } = yield fetchEntity(
    project,
    api.fetchProjectById,
    undefined,
    [projectId, token, isProjectDetail],
    action
  );

  if (limit > 50) limit = 20;
  const isViewingProjectComments = yield select(isProjectCommentsOpen);
  const isEditingNotes = yield select(getEditingNoteId);
  const shouldNotRefetchProjects = isViewingProjectComments || isEditingNotes;
  if (shouldNotRefetchProjects) {
    return;
  }

  if (!error && !accountIds.length && !isScheduleView) {
    if (fromAddEditDeleteProjectStatus) {
      yield put(
        fetchProjectsByGroupActionCreator({
          groupId: response.projects[0].board_id,
          accountIds: accountIds.length ? accountIds : null,
          searchText: searchText,
          isActive: !response.projects[0].archived_at
        })
      );
    } else {
      yield put(
        fetchProjectsByGroupActionCreator({
          groupId: response.projects[0].board_id,
          isActive: !response.projects[0].archived_at,
          limit: limit || null
        })
      );
    }
  } else if (!error && fromAddEditDeleteProjectStatus) {
    yield put(
      fetchProjectsByGroupActionCreator({
        groupId: response.projects[0].board_id,
        accountIds,
        isActive: !response.projects[0].archived_at
      })
    );
  }
}

export function* postProject(action) {
  const {
    title,
    description,
    client,
    number,
    billable,
    projectGroupId,
    isActive,
    token,
    memberList,
    projectGroupIdToRefresh,
    budget_status,
    budget_sub_status,
    redirectOnSuccess = true,
    onSuccessCallback
  } = action.payload;

  const isAlreadyCreatingProject = yield select(getIsCreatingProject);
  if (!isAlreadyCreatingProject) {
    const ret = yield changeEntity(
      projectCreation,
      api.postProject,
      [
        title,
        description,
        client,
        number,
        billable,
        projectGroupId,
        isActive,
        memberList,
        token,
        budget_status,
        budget_sub_status
      ],
      action
    );
    const { response } = ret;
    const { error } = ret;
    if (!error) {
      const { team_slug, group_slug, board_id, slug, id } = response;
      yield put(closeEditProjectModal());

      if (redirectOnSuccess) {
        yield put(
          navigateToProject({
            teamSlug: team_slug,
            projectSlug: slug,
            projectId: id
          })
        );
      }

      if (onSuccessCallback) {
        yield call(onSuccessCallback, response);
      }

      yield put(fetchMyProjectsActionCreator(token));
      yield put(fetchAllProjects());
      if (projectGroupIdToRefresh) {
        yield put(
          fetchProjectsByGroupActionCreator({
            groupId: projectGroupIdToRefresh,
            isActive,
            shouldSelectGroup: !!redirectOnSuccess // if it is false, selectedGroupID become undefined, see reducers/groups.js
          })
        );

        // in case project added is from a non-member of the board
        yield put(fetchBoardMembers(projectGroupIdToRefresh));
      }
    } else if (error === 'Forbidden') {
      const billingCurrentModal = yield select(getBillingCurrentModal);
      yield put(closeEditProjectModal());
      yield put(navigateToSettings());
      yield put(setModal('payment-plan-modal', billingCurrentModal));
    }
  }
}

export function* editProject(action) {
  const {
    title,
    description,
    client,
    number,
    projectGroupId,
    isActive,
    id,
    projectGroupIdToRefresh,
    calledByFormSubmit,
    stage_id,
    status_id,
    priority_id,
    billable,
    budget_status,
    budget_sub_status,
    custom_fields,
    custom_field_internal_label,
    custom_field_value,
    startDate,
    endDate,
    addGeneralTags,
    removeGeneralTags
  } = action.payload;

  const token = yield select(getAuthToken);

  if (calledByFormSubmit) {
    yield put(closeEditProjectModal());
  }

  const { error, response } = yield changeEntity(
    projectEdition,
    api.putProject,
    [
      token,
      {
        title,
        description,
        client,
        number,
        projectGroupId,
        isActive,
        token,
        id,
        projectGroupIdToRefresh,
        stage_id,
        status_id,
        priority_id,
        billable,
        budget_status,
        budget_sub_status,
        custom_fields,
        custom_field_internal_label,
        custom_field_value,
        start_date: startDate,
        end_date: endDate,
        addGeneralTags,
        removeGeneralTags
      }
    ]
  );

  if (!error) {
    if (!calledByFormSubmit) {
      yield put(fetchProjectByIdAction(id));
    }
  }
}

export function* updateProjectBillableWorker(action) {
  const { id, billable } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(updateProjectBillable, api.putProject, [
    token,
    {
      id,
      billable
    }
  ]);
}

export function* updateProjectPosition(action) {
  const {
    token,
    id,
    isActive,
    position,
    projectGroupIdToRefresh,
    accountIds,
    wasActive,
    noRefresh
  } = action.payload;
  const { error } = yield changeEntity(
    projectUpdatePostion,
    api.updateProjectPosition,
    [position, isActive, token, id, wasActive, projectGroupIdToRefresh],
    action,
    action.payload
  );
  if (!error && projectGroupIdToRefresh && !noRefresh) {
    yield put(
      fetchProjectsByGroupActionCreator({
        groupId: projectGroupIdToRefresh,
        accountIds,
        isActive: wasActive // refresh the same screen we were on
      })
    );
  }
}

export function* archiveUnarchiveProject(action) {
  const { token, projectId, isActive } = action.payload.params;
  const { error } = yield changeEntity(
    updateProjectArchiveFlag,
    api.archiveUnarchiveProject,
    [token, projectId, isActive]
  );

  if (!error) {
    yield put(fetchMyProjectsActionCreator(token));
  }
}

export function* batchArchiveUnarchiveProject(action) {
  const { token, projects, isActive } = action.payload.params;
  const projectIds = Object.keys(projects);
  const { error } = yield changeEntity(
    updateBatchProjectArchiveFlag,
    api.batchArchiveUnarchiveProject,
    [token, projectIds, isActive]
  );
  if (!error) {
    yield put(fetchMyProjectsActionCreator(token));
    yield put(fetchAllProjects({ projectIds: projectIds }));
  }
}

export function* fetchProjectMembers(action) {
  const { projectId } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    createResponseEntityHandler(constants.FETCH_PROJECT_MEMBERS),
    api.fetchProjectMembers,
    projectId,
    [token],
    action
  );
}

export function* addMemberToProject(action) {
  const {
    projectId,
    accountId,
    projectRole,
    boardId,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const currentUserId = yield select(getCurrentUserId);

  const { error, response } = yield changeEntity(
    createResponseEntityHandler(constants.ADD_MEMBER_TO_PROJECT),
    api.postAddMemberToProject,
    [projectId, accountId, projectRole, token],
    action
  );
  if (!error) {
    yield put(fetchProjectTeam(projectId));
    yield put(fetchAllProjects({ projectIds: [projectId] }));
    yield put(fetchTeamMembers());
    yield put(fetchBoardMembers(boardId));
    yield put(fetchMemberBudgets({ projectId }));

    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );

    if (currentUserId === accountId) {
      yield put(fetchMyProjectsActionCreator(token));
    }
  }
}

export function* addBulkMembersToProject(action) {
  // memberIds = account ids
  const { projectId, memberIds, projectRole = 3, boardId } = action.payload;

  const token = yield select(getAuthToken);
  const { error } = yield changeEntity(
    createResponseEntityHandler(constants.ADD_BULK_MEMBERS_TO_PROJECT),
    api.postAddMemberToProject,
    [projectId, memberIds, projectRole, token],
    action
  );

  if (!error) {
    yield put(
      fetchAllProjects({
        projectIds: [projectId]
      })
    );
    yield put(fetchMemberBudgets({ projectId }));
    yield put(
      fetchPhasesByProjectIds({
        projectIds: [projectId]
      })
    );
    yield put(fetchProjectTeam(projectId));
    yield put(fetchTeamMembers());
    yield put(fetchBoardMembers(boardId));
    yield put(fetchMyProjectsActionCreator(token));
  }
}

export function* removeMemberFromProject(action) {
  const {
    projectId,
    accountId,
    memberIsLoggedUser,
    phaseId,
    phaseMembershipId
  } = action.payload;
  const currentUserId = yield select(getCurrentUserId);
  const token = yield select(getAuthToken);
  if (phaseMembershipId && phaseId && projectId) {
    yield put(
      archivePhaseMember({
        id: phaseMembershipId,
        phaseId,
        projectId,
        archive: true
      })
    );
    yield take([
      constants.ARCHIVE_PHASE_MEMBER.SUCCESS,
      constants.ARCHIVE_PHASE_MEMBER.FAILURE
    ]);
  }

  const { error } = yield changeEntity(
    deleteMemberFromProject,
    api.deleteMemberFromProject,
    [projectId, accountId, token, memberIsLoggedUser],
    action
  );

  if (!error) {
    yield put(
      fetchAllProjects({
        projectIds: [projectId]
      })
    );
    yield put(fetchMemberBudgets({ projectId }));
    yield put(
      fetchPhasesByProjectIds({
        projectIds: [projectId]
      })
    );
    yield put(fetchProjectTeam(projectId));
    yield put(fetchTeamMembers());

    if (currentUserId === accountId) {
      yield put(fetchMyProjectsActionCreator(token));
    }
  }
}

export function* putProjectMember(action) {
  const { token, params } = action.payload;
  const { projectId, accountId, projectRole } = params;

  const { error } = yield changeEntity(
    createResponseEntityHandler(constants.UPDATE_PROJECT_MEMBER_ROLE),
    api.putUpdateProjectMember,
    [projectId, accountId, projectRole, token],
    action
  );

  if (!error) {
    yield put(fetchProjectTeam(projectId));
  }
}

export function* deleteProject(action) {
  const token = yield select(getAuthToken);
  const teamSlug = yield select(getTeamSlug);
  const userSlug = yield select(getAccountSlug);
  const {
    projectId,
    groupId,
    isActive,
    groupSlug,
    isPersonal,
    noRedirectOnDelete
  } = action.payload;
  const { error } = yield changeEntity(
    createResponseEntityHandler(constants.DELETE_PROJECT),
    api.deleteProject,
    [projectId, token]
  );

  if (!error) {
    if (isPersonal) {
      if (!noRedirectOnDelete) {
        yield put(
          navigateToHome({
            userSlug
          })
        );
      }
    } else {
      if (!noRedirectOnDelete) {
        yield put(
          navigateToBoard({
            teamSlug,
            boardSlug: groupSlug,
            boardId: groupId
          })
        );
      }
      yield put(
        fetchProjectsByGroupActionCreator({ groupId: groupId, isActive })
      );
    }
    yield put(closeEditProjectModal());
    yield put(toggleDeleteProjectModal(false));
    yield put(fetchMyProjectsActionCreator(token));
    yield put(fetchAllProjects());
    yield put(deleteProjectFromOrder({ projectId }));
  }
}

export function* hardDeleteProject(action) {
  const token = yield select(getAuthToken);
  const teamSlug = yield select(getTeamSlug);
  const userSlug = yield select(getAccountSlug);
  const {
    projectId,
    groupId,
    isActive,
    groupSlug,
    isPersonal,
    noRedirectOnDelete
  } = action.payload;
  const { error } = yield changeEntity(
    createResponseEntityHandler(constants.HARD_DELETE_PROJECT),
    api.hardDeleteProject,
    [projectId, token]
  );

  if (!error) {
    if (isPersonal) {
      if (!noRedirectOnDelete) {
        yield put(
          navigateToHome({
            userSlug
          })
        );
      }
    } else {
      if (!noRedirectOnDelete) {
        yield put(
          navigateToBoard({
            teamSlug,
            boardSlug: groupSlug,
            boardId: groupId
          })
        );
      }
      yield put(
        fetchProjectsByGroupActionCreator({ groupId: groupId, isActive })
      );
    }
    yield put(closeEditProjectModal());
    yield put(toggleDeleteProjectModal(false));
    yield put(fetchMyProjectsActionCreator(token));
    yield put(fetchAllProjects());
    yield put(deleteProjectFromOrder({ projectId }));
  }
}

export function* starProject(action) {
  const { params } = action.payload;
  const token = yield select(getAuthToken);
  const { projectId, starred, position, isProjectDetail } = params;
  const myProjectsLimit = yield select(getMyProjectsLength);

  const { error, response } = yield fetchEntity(
    projectStarUpdate,
    api.starProject,
    undefined,
    [token, projectId, starred, position],
    action
  );

  if (!error) {
    if (isProjectDetail) {
      yield put(
        fetchProjectByIdAction(projectId, [], null, null, null, isProjectDetail)
      );
    }
    yield put(
      fetchMyProjectsActionCreator(token, { limit: myProjectsLimit, offset: 0 })
    );
    yield put(fetchGroups(token));
  }
}

export function* updateNotificationPreferences(action) {
  const { accountId, projectId, allActivity, projectComments, tasks, notes } =
    action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    notificationPreferencesUpdate,
    api.updateNotificationPreferences,
    undefined,
    [token, accountId, projectId, allActivity, projectComments, tasks, notes],
    action
  );
}

export function* updateProjectModules(action) {
  const {
    projectId,
    taskColumns,
    appModules,
    taskStatusOrder,
    taskPriorityOrder,
    phaseOrder,
    movePhaseId,
    moveBehindPhaseId
  } = action.payload;

  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    projectModulesUpdate,
    api.updateProjectModules,
    [
      token,
      projectId,
      {
        taskColumns,
        appModules,
        taskStatusOrder,
        taskPriorityOrder,
        // phaseOrder, // phaseOrder is not used in API call.
        movePhaseId,
        moveBehindPhaseId
      }
    ],
    action
  );
}

const getProjectTaskState = (state) => state.projectTaskGroups;

export function* fetchProjectTaskGroupTasksQueue(action) {
  const { projectId, groupId } = action.payload;
  const { taskGroupOrders, queuedFetches } = yield select(getProjectTaskState);
  const taskGroupOrder = taskGroupOrders[projectId];
  const nextActiveFetch = taskGroupOrder.find((id) => queuedFetches[id]);

  if (!nextActiveFetch || nextActiveFetch === groupId) {
    yield call(fetchProjectTaskGroupTasks, action);
  } else {
    yield put(addTaskGroupToFetchQueue({ groupId }));
  }
}

function* callTaskGroupFetchQueueNext(action) {
  const { taskGroupOrders, queuedFetches } = yield select(getProjectTaskState);
  const { groupId, projectId } = action.payload;

  const nextGroupInQueueId = taskGroupOrders[projectId].find(
    (id) => queuedFetches[id] && id !== groupId
  );

  if (nextGroupInQueueId) {
    yield put(
      fetchProjectTaskGroupTasksActionCreator({
        projectId,
        groupId: nextGroupInQueueId
      })
    );
  }
}

function* fetchProjectTaskGroupTasks(action) {
  const { taskGroups } = yield select(getProjectTaskState);
  const { sort, filter, selectedAccountIds, ...restConfigProps } = yield select(
    getTaskFetchConfig
  );
  const { projectId } = action.payload;
  const page =
    taskGroups[action.payload.groupId] &&
    taskGroups[action.payload.groupId].current_page + 1;
  const newAction = produce(action, (theAction) => {
    theAction.payload.groupIds = [action.payload.groupId];
  });
  const body = {
    project_id: projectId,
    task_group_ids: [action.payload.groupId],
    ...restConfigProps,
    page, // important that page comes after restConfigProps because taskFetchConfig has a page key that is different than the page logic here
    assignee_id: selectedAccountIds,
    ...filter,
    sort
  };
  const options = {
    method: 'POST',
    body
  };
  const endpoint = `tasks`;
  yield call(apiRequestSaga, endpoint, options, newAction);
}

export function* fetchProjectTaskGroupTasksSuccess(action) {
  const { data, meta } = action.payload;
  const { current_page, total_pages } = data;
  const initialPayload = meta.action.payload;
  const { groupId, projectId } = initialPayload;

  if (current_page >= total_pages) {
    /* always try to grab next fetch from queue if current group is done
      if none exists, no fetch will occur and action terminates gracefully.

      waypoints already on page will exist in queue and fetch as necessary to fill page
      waypoints not on page will trigger fetch on entering page
    */
    yield put(removeTaskGroupFromFetchQueue({ groupId }));
    yield call(callTaskGroupFetchQueueNext, {
      payload: { groupId, projectId }
    });
  }
}

export function* fetchProjectsForTasksWorker(action) {
  const { tasks } = action.payload.data;
  if (tasks) {
    const projectIds = tasks.map((task) => task.project_id);
    const projectHash = yield select(getProjectHash);
    const unloadedProjectIds = projectIds.filter((id) => !projectHash[id]);

    if (unloadedProjectIds.length) {
      yield put(
        fetchAllProjects({
          projectIds: Array.from(new Set(unloadedProjectIds))
        })
      );
    }
  }
}

export function* fetchProjectClientsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;
  yield fetchEntity(
    fetchProjectClients,
    api.fetchProjectClients,
    teamId,
    [token],
    action
  );
}

export function* moveProjectsToBoard(action) {
  const token = yield select(getAuthToken);
  const { projectIds, boardId } = action.payload;
  const projectIdsArray = Object.keys(projectIds);
  const { error, response } = yield changeEntity(
    moveProjectToBoard,
    api.putBatchUpdateProjectsBoard,
    [boardId, projectIdsArray, token]
  );
}

export function* cloneProjectId(action) {
  const token = yield select(getAuthToken);
  const { projectId, targetBoard, flags, relativeWorkdays, startDate } =
    action.payload;
  const { error, response } = yield changeEntity(
    cloneProject,
    api.putCloneProject,
    [projectId, targetBoard, flags, relativeWorkdays, startDate, token],
    undefined,
    action.payload
  );
}

export function* cloneProjectList(action) {
  const {
    projectIds,
    targetBoard,
    flags,
    relativeWorkdays,
    startDate,
    batchId
  } = action.payload;
  const flagArray =
    flags ||
    Object.keys(defaultCloneProjectFlags).filter(
      (key) => defaultCloneProjectFlags[key].value
    );
  const projectIdsArray = Object.keys(projectIds);
  yield all(
    projectIdsArray.map((id) =>
      call(cloneProjectId, {
        payload: {
          targetBoard,
          flags: flagArray,
          projectId: id,
          relativeWorkdays,
          startDate,
          batchId
        }
      })
    )
  );
}
