import { ZOOM_LEVELS } from 'appConstants/workload';
import { TimelineConfig } from 'TimelinesModule/types/timeline';

export enum TIMELINE_VIEW_TYPES {
  PLANNER = 'planner',
  WORKLOAD = 'workload',
  PHASE_SPLIT_SCREEN = 'phaseSplitScreen',
  TASK_SPLIT_SCREEN = 'taskSplitScreen',
  WORKPLAN_SPLIT_SCREEN = 'workplanSplitScreen',
  PLANNER_SPLIT_SCREEN = 'plannerSplitScreen',
  MEMBER_AVAILABILITY_SPLIT_SCREEN = 'memberAvailabilitySplitScreen'
}

export enum TIMELINE_ITEM_TYPES {
  PHASE = 'phase',
  MILESTONE = 'milestone',
  ACTIVITY_PHASE = 'activityPhase',
  WORKPLAN = 'workPlan',
  TASK = 'task'
}

export enum TIMELINE_GROUP_TYPES {
  /**
   * shows project / phase / work category items in the same row
   */
  PROJECT = 'project',
  /**
   * shows summary of all rows
   */
  SUMMARY = 'summary',
  /**
   * shows work plan items in the same row
   */
  POSITION = 'position',
  /**
   * shows availability or work plan items in the same row
   */
  MEMBER = 'member',
  /**
   * shows availability or work plans items in the same row
   */
  UNASSIGNED_ROLE = 'unassignedRole',
  /**
   * shows work plan items in the same row
   */
  WORK_PLAN = 'workPlan',
  /**
   * shows placeholder cells in the same row
   * can click to add new item if it has permission
   */
  PLACEHOLDER = 'placeholder',
  /**
   * shows nothing in the empty row
   */
  EMPTY = 'empty'
}

export const dayInMilliseconds = 24 * 60 * 60 * 1000;

export const unit = {
  hour: 'h',
  percent: '%'
} as const;

export enum RowSize {
  Large = 'large', // Default
  Medium = 'medium',
  Small = 'small',
  VerySmall = 'verySmall'
}

export const ROW_HEIGHTS = {
  [RowSize.Large]: 74,
  [RowSize.Medium]: 52,
  [RowSize.Small]: 42,
  [RowSize.VerySmall]: 28
} as const;

export const defaultTimelineConfig: TimelineConfig = {
  zoom: ZOOM_LEVELS.WEEK,
  rowSize: RowSize.Large,
  shouldShowDateHeader: true
};

export const DATE_HEADER_HEIGHT = {
  Primary: 35,
  Secondary: 29
};
