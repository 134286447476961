import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchProjectTaskGroups,
  toggleTaskGroupCollapse,
  toggleTaskGroupCollapseAll,
  createProjectTaskGroup,
  updateProjectTaskGroup,
  deleteProjectTaskGroup
} from 'actionCreators';
import {
  getMatchedRouteParams,
  getIsOnTeamProject,
  isOnTeamMemberProfile,
  makeGetSomeIsBatchSelected,
  getAllTaskGroupsCollapsed,
  makeGetColorPickerIsOpen,
  makeGetIsNewTaskGroup,
  getAllTasks,
  getIsOnPersonalProject
} from 'selectors';

import TaskListSortContainer from './TaskListSortContainer';

import TaskColumnsDropdown from './TaskColumnsDropdown';
import BatchSelectAll from './BatchSelectAll';

import { StickyWrapper, NumTasksIndicator } from './styles';

import GenericGroupHeader from './GenericGroupHeader';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const noop = () => {};
const emptyStyle = {};

const deleteModalProps = {
  title: `Delete task list?`,
  content:
    ' Are you sure you want to delete this task list? You will be unable to restore the list or any tasks on it.'
};
class TaskGroupHeader extends Component {
  toggleCollapse = () => {
    const {
      toggleTaskGroupCollapse,
      group: { id, collapsed },
      isTaskGroup = true,
      setIsOpen = noop
    } = this.props;
    if (isTaskGroup) {
      toggleTaskGroupCollapse({ groupId: id, collapsed: !collapsed });
    }
    setIsOpen({ name: id, value: collapsed });
  };

  toggleCollapseAll = () => {
    const {
      toggleTaskGroupCollapseAll,
      allCollapsed,
      isTaskGroup = true
    } = this.props;
    if (isTaskGroup) {
      toggleTaskGroupCollapseAll({ collapsed: !allCollapsed });
    }
  };

  handleSubmit = ({ name }) => {
    const { group, updateProjectTaskGroup } = this.props;
    updateProjectTaskGroup({
      id: group.id,
      name
    });
  };

  handleInsertList = () => {
    const {
      createProjectTaskGroup,
      matchedParams: { projectId },
      index
    } = this.props;

    createProjectTaskGroup({ index, name: 'New Task List', projectId });
  };

  confirmDelete = () => {
    const {
      deleteProjectTaskGroup,
      group,
      matchedParams: { projectId }
    } = this.props;
    deleteProjectTaskGroup({
      id: group.id,
      projectId
    });
  };

  renderAfterName = () => {
    const { isVirtualTaskList, isOpen, numTasks } = this.props;
    return (
      isVirtualTaskList &&
      !isOpen &&
      numTasks > 0 && (
        <NumTasksIndicator>
          <span
            style={{
              fontSize: 13,
              marginLeft: 4,
              marginRight: 4,
              position: 'relative',
              bottom: 1
            }}
          >
            |
          </span>{' '}
          {numTasks}
        </NumTasksIndicator>
      )
    );
  };

  renderGroupHeader = () => {
    const {
      group = {},
      provided = {},
      snapshot = {},
      isOnlyTaskGroup,
      isVirtualTaskList,
      isNewTaskGroup,
      allCollapsed,
      toggleTaskGroupCollapseAll,
      disabled,
      editDisabled,
      dragDisabled,
      renderIcon,
      isOnPersonalProject,
      threeDotHoverBackgroundColor
    } = this.props;
    return (
      <GenericGroupHeader
        emptyNameText={
          group.is_default || group.is_like_default ? '' : 'Enter List Title'
        }
        namePlaceholderText={'Type List Title'}
        renderAfterName={this.renderAfterName}
        deleteModalProps={deleteModalProps}
        group={group}
        provided={provided}
        snapshot={snapshot}
        toggleCollapseAll={toggleTaskGroupCollapseAll}
        toggleCollapse={this.toggleCollapse}
        allCollapsed={allCollapsed}
        onConfirmDelete={this.confirmDelete}
        handleSubmit={this.handleSubmit}
        handleInsertList={this.handleInsertList}
        isDefaultGroup={group.is_default || group.is_like_default}
        isOnlyGroup={isOnlyTaskGroup}
        isNewGroup={isNewTaskGroup}
        removeLastGroupEnabled
        id={group.id}
        originType={ORIGIN_TYPE_STRINGS.TASK_GROUP} // necessary to determine color picker open/close state
        pickerLocation={'flyout-menu'}
        wrapperStyles={emptyStyle}
        preventEmptyName={!isOnlyTaskGroup}
        disabled={disabled}
        editDisabled={editDisabled}
        dragDisabled={dragDisabled}
        renderIcon={renderIcon}
        isOnPersonalProject={isOnPersonalProject}
        threeDotHoverBackgroundColor={threeDotHoverBackgroundColor}
      />
    );
  };

  render() {
    const {
      children,
      group = { collapsed: false, task_order: [] },
      isOnProjectView,
      isOnTeamProject,
      isOnTeamMemberProfile,
      index,
      someBatchSelected,
      allTasks,
      isOnPersonalProject,
      collapsedForDrag,
      hideSort,
      isVirtualTaskList,
      tableAllCollapsed
    } = this.props;
    if (!isOnProjectView) return children;
    const collapsed = group.collapsed || collapsedForDrag;
    const isVirtual = hideSort;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StickyWrapper
          isOnTeamProject={isOnTeamProject}
          isOnPersonalProject={isOnPersonalProject}
          id={group.id}
          originType={'taskGroup'}
          color="#ccc"
          isVirtual={isVirtual}
          allCollapsed={tableAllCollapsed}
        >
          {this.renderGroupHeader()}
          {!hideSort && <TaskListSortContainer collapsed={collapsed} />}
          {!isOnTeamMemberProfile &&
            isOnTeamProject &&
            index === 0 &&
            !someBatchSelected && (
              <div>
                <TaskColumnsDropdown show={true} />
              </div>
            )}
          <div
            style={{
              left: 8,
              width: 0 // width 0 necessary to prevent growing the container
            }}
          >
            {!isVirtualTaskList && (
              <BatchSelectAll
                taskGroupTaskIds={group.task_order}
                taskIds={allTasks}
                fromTaskGroups={isOnProjectView}
              />
            )}
          </div>
        </StickyWrapper>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getSomeIsBatchSelected = makeGetSomeIsBatchSelected();
  const getColorPickerIsOpen = makeGetColorPickerIsOpen();
  const getIsNewTaskGroup = makeGetIsNewTaskGroup();
  const mapStateToProps = (state, ownProps) => ({
    someBatchSelected: getSomeIsBatchSelected(state, ownProps),
    matchedParams: getMatchedRouteParams(state),
    isOnTeamProject: getIsOnTeamProject(state),
    isOnPersonalProject: getIsOnPersonalProject(state),
    isOnTeamMemberProfile: isOnTeamMemberProfile(state),
    allCollapsed: getAllTaskGroupsCollapsed(state),
    colorPickerOpen: getColorPickerIsOpen(state, ownProps),
    isNewTaskGroup: getIsNewTaskGroup(state, ownProps),
    allTasks: getAllTasks(state)
  });

  return mapStateToProps;
};
const mapDispatchToProps = {
  fetchProjectTaskGroups,
  toggleTaskGroupCollapse,
  toggleTaskGroupCollapseAll,
  createProjectTaskGroup,
  updateProjectTaskGroup,
  deleteProjectTaskGroup
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TaskGroupHeader);
