import { getTeamSlug } from 'selectors';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import { getTeamMembersHash } from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';
import { MemberCapacityGroupListItem } from '../types';
import {
  navigateToMemberModal,
  navigateToMembersRatesSettings,
  navigateToMembersSettings
} from 'actionCreators';
import MemberCard from 'BudgetModule/components/MemberCard';
import { Space } from 'components/Space';
import theme from 'theme';
import GoToIcon from 'icons/GoToProjectIcon';
import { ThreeDotMenuListItem } from 'views/personalSettings/SettingTableTemplate/shared/ThreeDotMenuListItem';
import { Sections } from 'appConstants/navigation';

export const MembersCapacityTableMemberCell = ({ row }) => {
  const teamMembersHash = useAppSelector(getTeamMembersHash);
  const teamSlug = useAppSelector(getTeamSlug);
  const { accountId } = row.original as MemberCapacityGroupListItem;

  const dispatch = useAppDispatch();
  const teamMember = teamMembersHash[accountId];

  if (!teamMember) return <></>;

  const handleViewMemberSchedule = () => {
    if (teamSlug) {
      dispatch(
        navigateToMemberModal({
          teamSlug,
          section: Sections.MEMBERS,
          memberId: accountId,
          memberViewType: 'schedule',
          membersViewType: 'members',
          openInNewWindow: true
        })
      );
    }
  };

  const handleSetMemberRole = () => {
    if (teamSlug) {
      dispatch(
        navigateToMembersSettings({
          teamSlug
        })
      );
    }
  };

  const handleSetMemberRate = () => {
    if (teamSlug) {
      dispatch(
        navigateToMembersRatesSettings({
          teamSlug
        })
      );
    }
  };

  return (
    <RootContainer $isArchived={teamUtils.getIsArchived(teamMember)}>
      <MenuContainer>
        <ThreeDotMenu
          overrideIsOpen
          color={theme.colors.colorRoyalBlue}
          style={{ width: '16px', justifyContent: 'center' }}
        >
          <ThreeDotMenuListItem
            icon={<GoToIcon color={theme.colors.colorMediumGray9} />}
            label={'View Member Schedule'}
            onClick={handleViewMemberSchedule}
          />
        </ThreeDotMenu>
      </MenuContainer>
      <Space value={10} />
      <MemberCard
        accountId={accountId}
        useTeamRate
        useTeamRole
        showHourlyRate
        isMemberArchived={teamUtils.getIsArchived(teamMember)}
        isInTable
        isSkipDefaultPrompt
        handleRoleClick={handleSetMemberRole}
        handleRateClick={handleSetMemberRate}
      />
    </RootContainer>
  );
};

const MenuContainer = styled.div``;

const RootContainer = styled.div<{ $isArchived: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  ${(props) => props.$isArchived && 'opacity: 0.6;'}

  ${MenuContainer} {
    opacity: 0;
  }

  &:hover {
    ${MenuContainer} {
      opacity: 1;
    }
  }
`;
