import { initialState, initialFilterState } from 'reducers/profit';
import { createSelector } from 'reselect';
import { replaceNegativeAggValues } from 'appUtils';
import keyBy from 'lodash/keyBy';

const getProfitState = (state) => state.profit || initialState;

const getOwnProjectId = (state, ownProps) => ownProps.projectId;

export const getProjectTotals = createSelector(
  getProfitState,
  (state) => state.projectTotals
);

const makeGetProjectTotals = () =>
  createSelector(
    getProfitState,
    (_, ownProps) => ownProps?.filterStateId,
    getProjectTotals, // eventually all calls should use filterState
    (state, filterStateId, totals) =>
      filterStateId
        ? (state.filterStates[filterStateId] || initialFilterState)
            .projectTotals
        : totals
  );

export const getProjectPlannerHoursByDate = createSelector(
  makeGetProjectTotals(),
  (projectTotals) => {
    const projectPlannerHoursHash = {};
    Object.keys(projectTotals).forEach((key) => {
      projectPlannerHoursHash[key] = keyBy(
        projectTotals[key].days,
        'start_date'
      );
    });
    return projectPlannerHoursHash;
  }
);

export const makeGetOwnProjectDailyTotals = () =>
  createSelector(
    makeGetProjectTotals(),
    getOwnProjectId,
    (totals, projectId) => totals[projectId]?.days || []
  );

export const makeGetOwnProjectMonthlyTotals = () =>
  createSelector(
    makeGetProjectTotals(),
    getOwnProjectId,
    (totals, projectId) => totals[projectId]?.months || []
  );

const formatChartData = (dateTotals, isViewingCostRate) => {
  return dateTotals.map((total) => {
    const { budget_totals, ...rest } = total;
    const formattedTotals = replaceNegativeAggValues(
      Object.keys(budget_totals).reduce((acc, cur) => {
        acc[cur] = +budget_totals[cur];
        return acc;
      }, {})
    );
    if (isViewingCostRate) {
      [
        'planned_expense',
        'spent_expense',
        'total_planned_expense',
        'total_spent_expense'
      ].forEach((dataKey) => {
        formattedTotals[dataKey] = formattedTotals[dataKey + '_cost_rate'];
      });
    }

    return { ...formattedTotals, ...rest };
  });
};

const getOwnIsViewingCostRate = (state, ownProps) => ownProps.isViewingCostRate;

export const makeGetOwnProjectDailyTotalsData = () =>
  createSelector(
    makeGetOwnProjectDailyTotals(),
    getOwnIsViewingCostRate,
    (totals, isViewingCostRate) => formatChartData(totals, isViewingCostRate)
  );

const getOwnProjectFee = (state, ownProps) =>
  +ownProps.overallTotal?.project_fee || 0;

const getIsPercentage = (state, ownProps) => ownProps.isPercentage || false;

export const makeGetOwnProjectMonthlyTotalsData = () =>
  createSelector(
    makeGetOwnProjectMonthlyTotals(),
    getOwnProjectFee,
    getIsPercentage,
    getOwnIsViewingCostRate,
    (totals, projectFee, isPercentage, isViewingCostRate) => {
      const calcPercentageOf = (value, total) => {
        return +total && +value ? (value / total) * 100 : 0;
      };

      let formattedTotals = formatChartData(totals, isViewingCostRate);
      if (isPercentage && projectFee) {
        formattedTotals = formattedTotals.map((total) => {
          const percentTotals = { ...total };
          [
            'invoice_amount',
            'invoice_estimate_amount',
            'planned_expense',
            'planned_expense_cost_rate',
            'spent_expense',
            'spent_expense_cost_rate',
            'total_invoice_amount',
            'total_invoice_estimate_amount',
            'total_planned_expense',
            'total_planned_expense_cost_rate',
            'total_spent_expense',
            'total_spent_expense_cost_rate'
          ].forEach((dataKey) => {
            percentTotals[dataKey] = calcPercentageOf(
              percentTotals[dataKey],
              projectFee
            );
          });
          return percentTotals;
        });
      }
      return formattedTotals;
    }
  );
