import { useMemo } from 'react';
import useFetchUnloadedProjects from './useFetchUnloadedProjects';
import useEntitiesHash from './useEntitiesHash';

const useEntities = ({
  projectId,
  phaseId,
  activityId,
  boardId,
  scopeId,
  workplanId,
  noFetch
}) => {
  const {
    projectHash,
    phaseHash,
    activityHash,
    boardsHash,
    scopeHash,
    workplanHash
  } = useEntitiesHash();
  const project = projectHash[projectId];

  const projectIdMemo = useMemo(() => [projectId], [projectId]);

  useFetchUnloadedProjects({ projectIds: noFetch ? [] : projectIdMemo });

  return {
    board: boardsHash[project?.board_id || boardId],
    project,
    phase: phaseHash[phaseId],
    activity: activityHash[activityId],
    scope: scopeHash[scopeId],
    workplan: workplanHash[workplanId],
    //
    projectHash,
    phaseHash,
    activityHash,
    boardsHash,
    scopeHash,
    workplanHash
  };
};

export default useEntities;
