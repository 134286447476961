import { useMemo } from 'react';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField } from '.';
import { Board } from 'models/board';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useBoards } from 'appUtils/hooks/useBoards';

const emptyObj = {};

export const usePortfoliosFilter: FilterListTypeHookWithItemHash = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  field = FilterField.board_ids
} = emptyObj) => {
  const { boardHash, isLoaded, nonPersonalBoards } = useBoards();

  const alphabeticalBoardIds = useMemo(() => {
    return nonPersonalBoards
      .slice()
      .sort((a, b) => {
        const aName = a.name;
        const bName = b.name;
        return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
      })
      .map((board) => board.id);
  }, [nonPersonalBoards]);

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: alphabeticalBoardIds,
    itemHash: boardHash,
    isOff,
    config,
    shouldUseDraft,
    itemSearchableKeys: searchableKeys,
    resultCountHash,
    labelKey
  });

  return {
    ...arrayFilterFieldValues,
    isLoading: !isLoaded || isResultsLoading,
    itemHash: boardHash,
    labelKey,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'name';

const searchableKeys: (keyof Board)[] = [labelKey];
