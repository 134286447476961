import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import Task from './Task';
import { getIsTaskModalOpen, getEditingTask } from 'selectors';
import {
  setEditTaskId,
  closeAndClearTaskViewModal,
  flushSelectedHomeTask
} from 'actionCreators';

class InlineTask extends PureComponent {
  state = {
    isClosing: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { isEditingTask, isTaskModalOpen } = this.props;
    const oldIsEditingTask = prevProps.isEditingTask;
    if (
      !isTaskModalOpen &&
      !isEditingTask &&
      isEditingTask !== oldIsEditingTask
    ) {
      this.setState({ isClosing: false });
    }
  }

  handleClickOutside = (event) => {
    const {
      setEditTaskId,
      closeAndClearTaskViewModal,
      flushSelectedHomeTask,
      isTaskModalOpen
    } = this.props;
    if (isTaskModalOpen) {
      return;
    }
    // ignore calendar clicks
    if (
      (event.target.className &&
        event.target.className.includes &&
        event.target.className.includes('rc-calendar-date')) ||
      (event.target.offsetParent &&
        event.target.offsetParent.className &&
        event.target.offsetParent.className.includes &&
        (event.target.offsetParent.className.includes('modal-content') ||
          event.target.offsetParent.className.includes('rc-trigger-popup') ||
          event.target.offsetParent.className.includes('popover')))
    ) {
      return;
    }
    this.setState({ isClosing: true });
    // when it's in an inline edit mode we don't need to navigate up on click out
    closeAndClearTaskViewModal({ skipNavigateUp: true });
    flushSelectedHomeTask();
    setEditTaskId(null);
  };

  render() {
    return (
      // div needed for onClickOutside HOC, fragment does not work
      <Task {...this.props} isClosing={this.state.isClosing} />
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    isTaskModalOpen: getIsTaskModalOpen(state),
    isEditingTask: getEditingTask(state) === ownProps.taskId
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  closeAndClearTaskViewModal,
  setEditTaskId,
  flushSelectedHomeTask
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(onClickOutside(InlineTask))
);
