import { Field, Label } from 'SettingsModule/components/styles';
import OrganizationCurrencySettingsDropdown from './OrganizationCurrencyDropdown';

interface TeamCurrencySettingProps {
  teamId: number;
}

export const TeamCurrencySetting = ({ teamId }: TeamCurrencySettingProps) => {
  return (
    <>
      <Label>Default Currency</Label>
      <Field>
        <OrganizationCurrencySettingsDropdown teamId={teamId} />
      </Field>
    </>
  );
};
