import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const initI18n = () => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(
      resourcesToBackend((language, namespace, callback) => {
        import(`./locales/${language}/${namespace}.json`)
          .then((resources) => {
            callback(null, resources);
          })
          .catch((error) => {
            callback(error, null);
          });
      })
    )
    .init({
      fallbackLng: 'en',
      debug:
        process.env.NODE_ENV === 'development' ||
        process.env.UI_ENV === 'staging',
      ns: ['task'],
      load: 'languageOnly',
      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'localStorage'],
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupLocalStorage: 'i18nextLang',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        // cache user language on
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
      }
    });
};
