import { useMemo } from 'react';
import { currencyFormatter, UseCurrencyFormatterByProjectProps } from '.';
import { makeGetDefaultCurrencyCodeById } from 'CurrencyModule/selectors/currency';
import { useAppSelector } from 'reduxInfra/hooks';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';

export const useCurrencyFormatterByProject = ({
  languageCode = 'en-US', // eventually will be replaced by multi language support
  projectId,
  options
}: UseCurrencyFormatterByProjectProps): Intl.NumberFormat | undefined => {
  const getCurrencyInfo = useMemo(() => makeGetDefaultCurrencyCodeById(), []);
  const currencyCode = useAppSelector((state) =>
    getCurrencyInfo(state, {
      entityType: ENTITY_TYPES.project,
      entityId: projectId
    })
  );

  if (!currencyCode) return;

  return currencyFormatter({
    languageCode,
    currencyCode,
    options
  });
};
