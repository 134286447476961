import { useContext } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { HelpersContext } from 'react-calendar-timeline';
import { BucketRenderer } from './BucketRenderers/BucketRenderer';
import { RowRenderer, BucketType } from './types';
import { ROW_HEIGHTS } from 'TimelinesModule/components/constants';
import { useTimelineConfig } from 'TimelinesModule/components/Timelines/providers/TimelineConfigProvider';
import { UtilizationBreakdownsByDate } from 'UtilizationModule/types';
import { getDisplayBucketType } from './utils';
import {
  BackgroundContainer,
  HolidayHatchBackground,
  PTOHatchBackground
} from './BucketRenderers/styles';
import { PlaceholderGroup } from 'TimelinesModule/types/placeholderGroup';

type PlaceholderRowRendererProps = {
  utilizationBreakdownsByDate?: UtilizationBreakdownsByDate;
};

const BACKGROUND_BY_BUCKET_TYPE = {
  [BucketType.FullPTO]: PTOHatchBackground,
  [BucketType.Holiday]: HolidayHatchBackground,
  [BucketType.Placeholder]: BackgroundContainer
} as const;

export const PlaceholderRowRenderer: RowRenderer<
  PlaceholderGroup,
  PlaceholderRowRendererProps
> = ({
  bufferedTimeStart,
  bufferedTimeEnd,
  group,
  utilizationBreakdownsByDate
}) => {
  const { getGroupDimensions } = useContext(HelpersContext);
  const { rowSize } = useTimelineConfig();

  const groupDimension = getGroupDimensions(group.id);

  const defaultRowHeight = ROW_HEIGHTS[rowSize];

  const height = groupDimension?.height ?? defaultRowHeight;
  const numBoxes = Math.round(height / defaultRowHeight);

  const numBoxesArray = Array(numBoxes);

  return (
    <BucketRenderer
      bufferedTimeStart={bufferedTimeStart}
      bufferedTimeEnd={bufferedTimeEnd}
    >
      {({ bucketMeta }) =>
        bucketMeta.map(({ isoDate, isWeekend }) => {
          const utilizationBreakdown = utilizationBreakdownsByDate?.[isoDate];
          const bucketType = utilizationBreakdown
            ? getDisplayBucketType({
                PTO: utilizationBreakdown.PTO,
                holiday: utilizationBreakdown.holiday,
                capacity: utilizationBreakdown.capacity
              })
            : BucketType.Placeholder;

          const Background = BACKGROUND_BY_BUCKET_TYPE[bucketType];

          return (
            <ColumContainer
              key={isoDate}
              className={cn({
                isWeekend
              })}
            >
              {numBoxesArray.map((_, i) => (
                <Background key={i} />
              ))}
            </ColumContainer>
          );
        })
      }
    </BucketRenderer>
  );
};

const ColumContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.colorPureWhite};
  border-right: 1px solid ${({ theme }) => theme.colors.colorPaleGray5};

  &.isWeekend {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray3};
  }

  & > :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray5};
  }
`;
