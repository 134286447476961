import styled from 'styled-components';

const PANEL_WIDTH = 440;
const PANEL_INSET_CONTENT_WIDTH = 340;
export const PANEL_INSET_PADDING = 28;
const PANEL_INSET_WIDTH = PANEL_INSET_CONTENT_WIDTH + 2 * PANEL_INSET_PADDING;
export const PANEL_INSET_MARGIN = (PANEL_WIDTH - PANEL_INSET_WIDTH) / 2;

export const PanelScroller = styled.div.attrs({ className: 'scrollbar' })`
  height: 100%;
  inset: 0 -${PANEL_INSET_PADDING}px;
  min-height: 0;
  overflow: auto;
  padding: 0 0 2rem ${PANEL_INSET_PADDING}px;
  position: relative;
  width: ${PANEL_INSET_WIDTH}px;

  & > * {
    width: ${PANEL_INSET_CONTENT_WIDTH}px;
  }
`;
