import styled from 'styled-components';
import theme from 'theme';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import QBDownArrow from 'icons/QBDownArrow';
import { CollapseIconContainer } from 'views/Home/TaskList/styles';
export const StyledTableSubHeader = styled.div`
  display: flex;
  position: relative;
  align-self: flex-end;
  justify-content: space-between;
  width: ${(props) => props.totalColumnsWidth - 110}px;
  color: ${theme.colors.colorSemiDarkGray1};
  height: ${(props) => (props.isStickyHeader ? 'auto' : '100%')};
  .logged-member-no-hover,
  .regular-member-no-hover {
    width: 35px;
    height: 35px;
    flex: 0 0 35px;
  }
  border-bottom: 1px solid
    ${(props) =>
      !props.isOpen && !props.isStickyHeader
        ? theme.colors.colorLightGray6
        : props.showHeaderLine
        ? theme.colors.colorLightGray6
        : 'transparent'};
`;
export const StyledKaratContainer = styled.div`
  left: -30px;
  position: absolute;
  height: 20px;
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 20px;
  margin: 0px 5px;
  svg {
    transform-origin: center center;
    transition: 0.275s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
    path {
      stroke-width: 1;
      stroke: ${theme.colors.colorSemiDarkGray1};
    }
  }
  &:hover {
    background: ${theme.colors.colorPaleGray6};
  }
`;

export const TimesheetCollapseIconContainer = styled(CollapseIconContainer)`
  position: absolute;
  left: -40px;
  top: 3px;
  rect {
    stroke: transparent;
    fill: transparent;
  }
  &:hover {
    rect {
      stroke: ${theme.colors.colorLightGray9};
    }
  }
`;

export const HoveredIcon = styled.div`
  .hovered {
    display: none;
  }

  .notHovered {
    display: block;
  }
  &:hover {
    .hovered {
      display: block;
    }
    .notHovered {
      display: none;
    }
  }
`;

export const MemberAndRoleContainer = styled.div`
  position: relative;
  max-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
  height: 100%;
`;
export const MemberName = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding-left: 8px;
  white-space: nowrap;
  color: ${theme.colors.colorSemiDarkGray1};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MemberRole = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px;
  color: ${theme.colors.colorMediumGray5};
`;

export const InnerDropdownOption = styled.div`
  font-size: 12px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding: 4px 0px;
  margin-left: 0px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArchivedText = styled.span`
  font-size: 11px;
  font-weight: 600;
  color: ${theme.colors.colorMediumGray1};
`;
export const StyledProjectTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
  flex: auto;
  white-space: nowrap;
`;
export const StyledProjectDescription = styled.span`
  color: ${theme.colors.colorMediumGray5};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  font-size: 12px;
  margin-left: 5px;
  width: 100%;
  .phaseNameIcon {
    width: 11px;
    height: 11px;
    position: relative;
    bottom: 1px;
    margin-right: 2px;
  }
`;
export const StyledPhaseTitle = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
  .phaseNameIcon {
    margin-right: 5px;
    path {
      stroke: ${theme.colors.colorSemiDarkGray1};
    }
  }
`;
export const StyledProjectNumber = styled.div`
  color: ${theme.colors.colorMediumGray5};
  line-height: 18px;
  height: 18px;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  padding-left: 5px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  margin-right: 4px;
`;

export const StyledProjectInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  position: relative;
  width: 100%;
`;
export const StyledTeamName = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  margin-left: 2px;
`;

export const StyledColorContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  top: 6px;
`;
export const StyledDot = styled.div`
  height: 8px;
  margin-right: 4px;
  margin-left: 2px;
  flex: 0 0 8px;
  align-self: center;
  border-radius: 10px;
  background: ${theme.colors.colorMediumGray1};
  background: ${calculateProjectColorPreference};
`;

export const AmountDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;

  ${(props) =>
    props.isTotal &&
    `
      font-weight: 600;
      font-size: 13px;
      color: ${theme.colors.colorSemiDarkGray1};
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0;
  `}
`;

export const PhaseSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
  padding-left: 5px;
  .hoverArrow {
    visibility: hidden;
  }
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    .hoverArrow {
      visibility: visible;
    }
  }
`;

export const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const StyledSelectActivityArrow = styled(QBDownArrow)`
  margin-left: 6px;
  path {
    fill: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledApproveAllContainer = styled.div`
  color: #828282;
  width: 80px;
  :hover {
    color: ${(props) =>
      props.isActive ? theme.colors.colorRoyalBlue : '#828282'};
  }
`;
