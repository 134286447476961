import styled, { css } from 'styled-components';
import theme from 'theme';
import {
  FilterListItem,
  BaseFilterList,
  FilterListsTableCustomRowProps,
  FilterListsTableVariant,
  AccountFilterListItem
} from '../types';
import { TableRow } from 'components/Table/types';
import { ROW_TYPES } from '../tableConfigs';
import {
  FilterListType,
  memberFilterListTypes,
  filterListTypeToEntityLabel
} from 'FilterModule/constants';
import {
  AccountLabel,
  ProjectLabel,
  PriorityLabel,
  EmploymentTypeLabel
} from './labels';

export const DefaultLabelCell = ({
  row,
  customRowProps,
  isScrolling
}: {
  row: {
    original: TableRow<FilterListItem, BaseFilterList>;
  };
  customRowProps: FilterListsTableCustomRowProps;
  isScrolling: boolean;
}) => {
  const {
    filterListType,
    toggleSelect,
    isSelected,
    labelText,
    rowType,
    count,
    parentList: { isUnableToSelectMoreItems }
  } = row.original;

  const { resultsFilterListType } = customRowProps;

  const LabelComponent = filterListTypeToItemLabelComponent[filterListType];

  const tooltip = `${labelText}${
    count !== undefined &&
    resultsFilterListType &&
    customFilterListTypeToEntityLabel[resultsFilterListType]
      ? `<br/>${count} ${
          resultsFilterListType
            ? customFilterListTypeToEntityLabel[resultsFilterListType] +
              (count !== 1 ? 's' : '')
            : ''
        }`
      : ''
  }`;

  return (
    <StyledLabelCell
      onClick={
        !isSelected && isUnableToSelectMoreItems ? undefined : toggleSelect
      }
      tableVariant={customRowProps.tableConfig.variant}
      labelVariant={
        rowType === ROW_TYPES.defaultFilterItemRow ||
        rowType === ROW_TYPES.defaultGroupedFilterItemRow ||
        customRowProps.tableConfig.variant === 'StackedFilters'
          ? 'default'
          : 'bolder'
      }
      className="label-cell"
      data-for="app-tooltip"
      data-tip={tooltip}
      data-class="center"
      data-html
      data-effect="solid"
      data-delay-show="700"
    >
      {LabelComponent ? (
        <LabelComponent
          customRowProps={customRowProps}
          rowData={row.original}
          isScrolling={isScrolling}
        />
      ) : (
        <span
          className={
            !filterListTypesWithOverflow[filterListType]
              ? 'no-text-overflow'
              : undefined
          }
        >
          {labelText}
        </span>
      )}

      {count !== undefined && (
        <StyledCount>
          <Separator>|</Separator> <span>{count}</span>
        </StyledCount>
      )}
    </StyledLabelCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

const filterListTypesWithOverflow = {
  [FilterListType.ShowProjectsWithUnassignedRoles]: true
};

const customFilterListTypeToEntityLabel = {
  ...filterListTypeToEntityLabel,
  ...memberFilterListTypes.reduce<Partial<Record<FilterListType, string>>>(
    (acc, filterListType) => {
      acc[filterListType] = 'Member';
      return acc;
    },
    {}
  )
};

const filterListTypeToItemLabelComponent: Partial<
  Record<
    FilterListType,
    (props: {
      rowData: TableRow<FilterListItem, BaseFilterList>;
      customRowProps?: FilterListsTableCustomRowProps;
      isScrolling?: boolean;
    }) => JSX.Element
  >
> = {
  ...memberFilterListTypes.reduce<
    Partial<
      Record<
        FilterListType,
        (props: {
          rowData: TableRow<AccountFilterListItem, BaseFilterList>;
        }) => JSX.Element
      >
    >
  >((acc, filterListType) => {
    acc[filterListType] = AccountLabel;
    return acc;
  }, {}),

  [FilterListType.ProjectsByPortfolio]: ProjectLabel,
  [FilterListType.Priorities]: PriorityLabel,
  [FilterListType.EmploymentType]: EmploymentTypeLabel
};

const Separator = styled.span`
  font-size: 21px;
`;

const StyledCount = styled.div`
  padding-left: 5px;
  padding-bottom: 1px;
  font-size: 13px;
  color: ${theme.colors.colorLightGray15};
  display: flex;
  align-items: center;
`;

type StyledLabelCellVariant = 'default' | 'bolder';

const StyledLabelCellStylesByLabelVariant: Record<
  StyledLabelCellVariant,
  ReturnType<typeof css>
> = {
  default: css`
    font-size: 13px;
    color: ${theme.colors.colorSemiDarkGray1};

    .muted & {
      color: ${theme.colors.colorCalendarGray};
    }
  `,

  bolder: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
    color: ${theme.colors.colorMediumGray9};
  `
};

const StyledLabelCellStylesByTableVariant: Record<
  FilterListsTableVariant,
  ReturnType<typeof css>
> = {
  StackedFilters: css``,

  SideFilter: css``
};

const StyledLabelCell = styled.div<{
  tableVariant: FilterListsTableVariant;
  labelVariant: StyledLabelCellVariant;
}>`
  // default styles
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 3px;

  .tr:hover & {
    color: ${theme.colors.colorRoyalBlue};
    cursor: pointer;
  }

  /* table variant specific styles */
  ${({ tableVariant }) => StyledLabelCellStylesByTableVariant[tableVariant]}

  /* label variant specific styles */
  ${({ labelVariant }) => StyledLabelCellStylesByLabelVariant[labelVariant]}
`;
