import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from 'icons/DeleteIcon';
import { DeleteIconContainer } from './styles';
import { checkHasTimeEntries } from 'actionCreators';
import { getSelectedTeamId } from 'selectors';

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  &:hover {
    path {
      fill: ${theme.colors.colorDeleteRed};
    }
  }
`;

const MilestoneTableDeleteCell = (props) => {
  const dispatch = useDispatch();
  const selectedTeamId = useSelector(getSelectedTeamId);
  const { phase, canDelete, userIsAdmin, openPhaseModal } = props;

  const isPhase = phase?.is_budget;

  const setDeleteMilestoneId = () => {
    if (!canDelete) {
      return;
    }

    if (selectedTeamId)
      dispatch(
        checkHasTimeEntries({
          phase_ids: [phase.id],
          team_id: selectedTeamId,
          permissions: {
            mine: false,
            teamId: selectedTeamId
          }
        })
      );
    openPhaseModal({
      id: phase.id,
      name: phase.name,
      projectId: phase.project_id,
      isDeletePhase: true
    });
  };

  const renderPhaseDeleteIcon = () => {
    const dataFor = canDelete ? 'app-tooltip' : 'admin-tooltip';
    const noPermissionTip = `${'You must have Project Budget Edit Permissions to remove this Phase.'} ${
      userIsAdmin ? '' : ' Contact Your Admin(s)'
    }`;
    const dataTip = `${canDelete ? '' : noPermissionTip}`;
    return (
      <>
        <DeleteIconContainer
          className="delete-icon-container"
          data-for={dataFor}
          data-effect="solid"
          data-class="phase-milestone-tooltip"
          data-tip={dataTip}
          canDelete={canDelete}
        >
          {!phase.is_like_default && (
            <div onClick={setDeleteMilestoneId}>
              <StyledDeleteIcon />
            </div>
          )}
        </DeleteIconContainer>
      </>
    );
  };

  return isPhase ? (
    renderPhaseDeleteIcon()
  ) : (
    <div>
      <DeleteIconContainer
        className={'delete-icon-container'}
        canDelete={canDelete}
      >
        <div onClick={setDeleteMilestoneId}>
          <StyledDeleteIcon />
        </div>
      </DeleteIconContainer>
    </div>
  );
};

export default MilestoneTableDeleteCell;
