import { Field, Label } from 'SettingsModule/components/styles';
import { OrganizationSettingsPopover } from '../shared/OrganizationSettingsPopover';

const options = {
  'MM/DD/YYYY': { label: 'Month/Day/Year' },
  'YYYY/MM/DD': { label: 'Year/Month/Day' },
  'YYYY/DD/MM': { label: 'Year/Day/Month' }
};

export const DateFormatSetting = () => {
  return (
    <>
      <Label>Date Format</Label>
      <Field>
        <OrganizationSettingsPopover
          options={options}
          currentlySelectedKey="MM/DD/YYYY"
        />
      </Field>
    </>
  );
};
