import styled from 'styled-components';
import { ColoredText } from '../../styles';
import theme from 'theme';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import { StickyWrapper, Sticky } from '../styles';

export const TitleText = ({ isProjectsView, item }) => (
  <StickyWrapper>
    <Sticky>
      {isProjectsView && !item.is_like_default && (
        <StyledPhaseMilestoneIcon height={14} width={14} />
      )}
      <Title>
        <Name>
          {isProjectsView
            ? (!item.is_like_default && item.phase_name) || (
                /* Forces a vertical space if nothing is rendered. */
                <>&#x200b;</>
              )
            : item.project_name}
        </Name>
        {!isProjectsView &&
          !item.is_like_default &&
          item.project_description && (
            <ColoredText>&nbsp;- {item.project_description}</ColoredText>
          )}
      </Title>
    </Sticky>
  </StickyWrapper>
);

const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon).attrs({
  strokeColor: theme.colors.colorSemiDarkGray1,
  fillColor: 'transparent'
})`
  flex: none;
`;

const Title = styled.div`
  // Text overflow only applies to block containers, so it is not possible to
  // put these rules on Sticky, which is a flexbox container.
  overflow: hidden;
  text-overflow: ellipsis;

  // Space between the phase icon and the title.
  * + & {
    margin-left: 3px;
  }
`;

const Name = styled.span`
  font-weight: 600;
`;
