import {
  RowOptionsGetterWithRequiredAdditionalParams,
  CsvImportOffice
} from 'CsvImportsModule/types';
import { getRowValuesForEntityType } from './utils';
import uniq from 'lodash/uniq';
interface AdditionalParams {
  existingOfficesByName: Record<string, CsvImportOffice>;
  projectOfficeOption: React.MutableRefObject<
    Record<string, Record<string, string>>
  >;
}

export const projectOfficeOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { existingOfficesByName, projectOfficeOption } = additionalParams;
  const { enteredProjectTitle, enteredProjectNumber } =
    getRowValuesForEntityType(row, entityType);
  const matchedOfficeByProjectTitleAndNumber =
    projectOfficeOption.current[enteredProjectTitle]?.[enteredProjectNumber];

  // return all possible office names, make matched office name the 2nd option if exists
  // including '' so that user can go back to empty field
  return uniq([
    '',
    matchedOfficeByProjectTitleAndNumber ?? '',
    ...Object.keys(existingOfficesByName)
  ]);
};
