import { PlaceholderType, GroupByType } from 'TeamsModule/models/settings';
export const TIMESHEET_REPORT_STATUSES = {
  SUBMITTED: 'submitted',
  NOT_SUBMITTED: 'not_submitted',
  APPROVED: 'approved',
  REJECTED: 'rejected'
};
export const TIMESHEET_STATUSES = {
  ...TIMESHEET_REPORT_STATUSES,
  // FE only - could include any combination of the above
  ENTERED: 'entered',
  NOT_ENTERED: 'notEntered',
  UNSUBMITTED: 'unsubmitted',
  UNAPPROVED: 'unapproved'
};
export const TIMESHEET_STATUSES_API = {
  SUBMITTED: 1,
  NOT_SUBMITTED: 0,
  APPROVED: 2,
  REJECTED: 3
};

export const TIMESHEET_STATUS_COPY = {
  [TIMESHEET_STATUSES.SUBMITTED]: 'Submit',
  [TIMESHEET_STATUSES.NOT_SUBMITTED]: 'Unsubmit',
  [TIMESHEET_STATUSES_API.SUBMITTED]: 'Submit',
  [TIMESHEET_STATUSES_API.NOT_SUBMITTED]: 'Unsubmit'
};

export const TIMESHEET_STATUS_COPY_API = {
  [TIMESHEET_STATUSES_API.SUBMITTED]: 'Submit',
  [TIMESHEET_STATUSES_API.NOT_SUBMITTED]: 'Unsubmit'
};

export const FILTER_RENDER_TEXT = {
  [TIMESHEET_STATUSES.SUBMITTED]: 'Submitted',
  [TIMESHEET_STATUSES_API.SUBMITTED]: 'Submitted',
  [TIMESHEET_STATUSES.NOT_SUBMITTED]: 'Not Submitted',
  [TIMESHEET_STATUSES_API.NOT_SUBMITTED]: 'Not Submitted',
  [TIMESHEET_STATUSES.APPROVED]: 'Approved',
  [TIMESHEET_STATUSES_API.APPROVED]: 'Approved',
  [TIMESHEET_STATUSES.REJECTED]: 'Rejected',
  [TIMESHEET_STATUSES_API.REJECTED]: 'Rejected'
};

export const TIMESHEET_STATUS_REVERSE_LOOKUP = {
  [TIMESHEET_STATUSES.SUBMITTED]: TIMESHEET_STATUSES_API.SUBMITTED,
  [TIMESHEET_STATUSES_API.SUBMITTED]: TIMESHEET_STATUSES.SUBMITTED,
  [TIMESHEET_STATUSES.NOT_SUBMITTED]: TIMESHEET_STATUSES_API.NOT_SUBMITTED,
  [TIMESHEET_STATUSES_API.NOT_SUBMITTED]: TIMESHEET_STATUSES.NOT_SUBMITTED,
  [TIMESHEET_STATUSES.APPROVED]: TIMESHEET_STATUSES_API.APPROVED,
  [TIMESHEET_STATUSES_API.APPROVED]: TIMESHEET_STATUSES.APPROVED,
  [TIMESHEET_STATUSES.REJECTED]: TIMESHEET_STATUSES_API.REJECTED,
  [TIMESHEET_STATUSES_API.REJECTED]: TIMESHEET_STATUSES.REJECTED
};

export const TIMESHEET_APPROVAL_LOG_TEXT = {
  [TIMESHEET_STATUSES.SUBMITTED]: 'submitted',
  [TIMESHEET_STATUSES.APPROVED]: 'approved',
  [TIMESHEET_STATUSES.NOT_SUBMITTED]: 'unsubmitted',
  [TIMESHEET_STATUSES.REJECTED]: 'rejected'
};

type timesheetOptionsTypes = {
  AS_ENTERED: GroupByType;
  BY_PROJECT: GroupByType;
  NO_PLACEHOLDER: PlaceholderType;
  WORK_PLANS_AND_CHECK_INS: PlaceholderType;
  MOSAIC_ESTIMATES: PlaceholderType;
};

export const timesheetOptions: timesheetOptionsTypes = {
  AS_ENTERED: 'asEntered',
  BY_PROJECT: 'project',
  NO_PLACEHOLDER: 'noPlaceholder',
  WORK_PLANS_AND_CHECK_INS: 'workplansAndCheckins',
  MOSAIC_ESTIMATES: 'mosaicEstimates'
};

export const timesheetOptionsNames = {
  [timesheetOptions.AS_ENTERED]: 'As Entered',
  [timesheetOptions.BY_PROJECT]: 'By Project',
  [timesheetOptions.NO_PLACEHOLDER]: 'No Placeholder',
  [timesheetOptions.WORK_PLANS_AND_CHECK_INS]: 'Work Plans & Check-ins',
  [timesheetOptions.MOSAIC_ESTIMATES]: 'Mosaic Estimates'
};

export const placeholderDescriptions = {
  [timesheetOptions.NO_PLACEHOLDER]: 'Estimate Off',
  [timesheetOptions.WORK_PLANS_AND_CHECK_INS]:
    'Show Placeholder Time Estimates from Work Plans and Check-ins',
  [timesheetOptions.MOSAIC_ESTIMATES]:
    "Show Placeholder Time Estimates from Mosaic's AI that includes Work Plans, Check-ins, Timers and all data in the system."
} as const;

export const placeholderOptions: {
  value: PlaceholderType;
  name?: string;
}[] = [
  {
    value: timesheetOptions.NO_PLACEHOLDER,
    name: timesheetOptionsNames[timesheetOptions.NO_PLACEHOLDER]
  },
  {
    value: timesheetOptions.WORK_PLANS_AND_CHECK_INS,
    name: timesheetOptionsNames[timesheetOptions.WORK_PLANS_AND_CHECK_INS]
  },
  {
    value: timesheetOptions.MOSAIC_ESTIMATES,
    name: timesheetOptionsNames[timesheetOptions.MOSAIC_ESTIMATES]
  }
];

export const groupByOptions: { value: GroupByType; name?: string }[] = [
  {
    value: timesheetOptions.AS_ENTERED,
    name: timesheetOptionsNames[timesheetOptions.AS_ENTERED]
  },
  {
    value: timesheetOptions.BY_PROJECT,
    name: timesheetOptionsNames[timesheetOptions.BY_PROJECT]
  }
];
