import { createAction } from '@reduxjs/toolkit';
import { SpacePermissionCheckerParams } from '../types';

export const accessBudgetReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_BUDGET_REPORT'
);

export const accessWorkloadReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_WORKLOAD_REPORT'
);

export const accessTimesheetReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_TIMESHEET_REPORT'
);

export const accessUtilizationReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_UTILIZATION_REPORT');

export const accessProfitReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_PROFIT_REPORT'
);

export const accessTimeVarianceReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_TIME_VARIANCE_REPORT');

export const accessTimesheetStatusReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_TIMESHEET_STATUS_REPORT');

export const accessDemandReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_DEMAND_REPORT'
);

export const accessWorkloadForecastReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_WORKLOAD_FORCAST_REPORT');

export const accessScheduleVarianceReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_SCHEDULE_VARIANCE_REPORT');

export const accessSkillsReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_SKILLS_REPORT'
);

export const accessOrgChartReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_ORG_CHART_REPORT'
);

export const accessBudgetVarianceReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_BUDGET_VARIANCE_REPORT');

export const accessCheckInsReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_CHECK_INS_REPORT'
);

export const accessPlannedTimeReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_PLANNED_TIME_REPORT');

export const accessSpentTimeReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_SPENT_TIME_REPORT'
);

export const accessTimeProjectionReport =
  createAction<SpacePermissionCheckerParams>('ACCESS_TIME_PROJECTION_REPORT');

export const accessTimesheetApprovalReport =
  createAction<SpacePermissionCheckerParams>(
    'ACCESS_TIMESHEET_APPROVAL_REPORT'
  );

export const accessProjectsReport = createAction<SpacePermissionCheckerParams>(
  'ACCESS_PROJECTS_REPORT'
);

export const exportReportData =
  createAction<SpacePermissionCheckerParams>('EXPORT_REPORT_DATA');
