import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { initialState as initialPredictionHoursState } from 'reducers/suggestions';

const emptyArray = [];
const emptyObj = {};

export const getPredictionHoursState = (state) =>
  state.predictionHours || initialPredictionHoursState;

export const getPredictionHours = createSelector(
  getPredictionHoursState,
  (state) => state.predictions || emptyObj
);

export const getFlatPredictionHours = createSelector(
  getPredictionHours,
  (hoursByAccountId) => flatten(Object.values(hoursByAccountId))
);

export const getPredictionHourProjectIds = createSelector(
  getFlatPredictionHours,
  (predictions) =>
    uniq(predictions.map((prediction) => prediction.project_id)) ?? emptyArray
);
