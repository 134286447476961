import React from 'react';
import styled from 'styled-components';
import { LogoComponent } from './LogoComponent.jsx';
import moment from 'moment';

const GraphDot = styled.circle`
  stroke: ${({ theme }) => theme.colors.colorConnectedGreen};
  stroke-width: 2;
  fill: ${({ theme }) => theme.colors.colorPureWhite};
  fill-opacity: 1;
`;
const DotHoverArea = styled.rect`
  cursor: pointer;
`;

export const Dot = (props) => {
  const { shouldDisplayIcons, mosaicUtilizationStartDate } =
    props.utlizationIconInfo;

  const isCurrentLogo = moment()
    .subtract(1, 'months')
    .endOf('month')
    .startOf('day')
    .isBetween(
      moment(props.payload.start_date, 'YYYY-MM-DD'),
      moment(props.payload.end_date, 'YYYY-MM-DD'),
      undefined,
      '[]'
    );

  const isMosaicStartDateLogo = moment(
    mosaicUtilizationStartDate,
    'YYYY-MM-DD'
  ).isBetween(
    moment(props.payload.start_date, 'YYYY-MM-DD'),
    moment(props.payload.end_date, 'YYYY-MM-DD'),
    undefined,
    '[]'
  );

  return (
    <>
      <GraphDot
        r={3}
        stroke={props.stroke}
        strokeWidth={props.strokeWidth}
        fill={props.fill}
        width={props.width}
        height={props.height}
        cx={props.cx}
        cy={props.cy}
      />

      {/* Hover area */}
      <DotHoverArea
        stroke="transparent"
        fill="transparent"
        x={props.cx - 8}
        y={props.cy - 8}
        width="16"
        height="16"
        onMouseOver={props.onHover}
        onMouseOut={props.onHoverOut}
      />

      {(isCurrentLogo || isMosaicStartDateLogo) && shouldDisplayIcons && (
        <LogoComponent
          cx={props.cx}
          cy={props.cy}
          setIsHoveringDot={props.setIsHoveringDot}
          isHoveringDot={props.isHoveringDot}
        />
      )}
    </>
  );
};
