import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import {
  getIsSamlEnabled,
  getRealmId,
  getTeamAuthSettings,
  getTeamSamlInfo,
  getTeamWideMfaSetting
} from 'AuthenticationModule/selectors';
import { fetchTeamMembers } from 'actionCreators';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getMyUserId } from 'UsersModule/selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import {
  associateSamlIdentity,
  generateTeamSamlInfo,
  setAuthMethod
} from 'AuthenticationModule/actionCreators';
import {
  getAuthMfaStatusPerAccount,
  toggleIndividualMfa,
  updateTeamAuthSettings
} from 'SettingsModule/actionCreators';
import {
  MFA_STATUS,
  preferredAuthMethods
} from 'AuthenticationModule/constants';
import { SupportedMfaStatus } from 'AuthenticationModule/types';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';

const changeTeamMfaSettingRequestId = 'useSecurity__changeTeamMfaSetting';

const associateSamlIdentityRequestId = 'useSecurity__associateSamlIdentity';

const setAuthMethodRequestId = 'useSecurity__setAuthMethod';

export const useSecurity = () => {
  const { useSamlAuth, useSmsMfa, mfaEnabledFlag } = useFeatureFlags();

  const isSamlActive = useAppSelector(getIsSamlEnabled);
  const myId = useAppSelector(getMyUserId);
  const teamId = useAppSelector(getSelectedTeamId);
  const teamWideMfaSetting = useAppSelector(getTeamWideMfaSetting);
  const accountsMfaStatus = useAppSelector(
    (state) => state.auth.accountsMfaStatus
  );

  const authSettings = useAppSelector(getTeamAuthSettings);
  const teamSamlInfo = useAppSelector(getTeamSamlInfo);
  const realmId = useAppSelector(getRealmId);

  const dispatch = useAppDispatch();

  const { status: changeTeamMfaSettingRequestStatus } = useRequestStatus({
    requestStatusId: changeTeamMfaSettingRequestId
  });

  const { status: associateSamlIdentityRequestStatus } = useRequestStatus({
    requestStatusId: associateSamlIdentityRequestId
  });

  const { status: setAuthMethodRequestStatus } = useRequestStatus({
    requestStatusId: setAuthMethodRequestId
  });

  const mfaStatusInfoOfCurrentUser = myId ? accountsMfaStatus[myId] : undefined;
  const isMfaActive = teamWideMfaSetting !== MFA_STATUS.disabled;
  const isUserMfaDisabled =
    mfaStatusInfoOfCurrentUser?.user_mfa_status === MFA_STATUS.disabled;

  const isRequireSetupMFA =
    (mfaStatusInfoOfCurrentUser?.require_sms_reset === true &&
      mfaStatusInfoOfCurrentUser?.require_totp_reset === true) ||
    (mfaStatusInfoOfCurrentUser?.team_preferred_mfa === MFA_STATUS.totp &&
      mfaStatusInfoOfCurrentUser?.require_totp_reset === true) ||
    (mfaStatusInfoOfCurrentUser?.team_preferred_mfa === MFA_STATUS.sms &&
      mfaStatusInfoOfCurrentUser?.require_sms_reset === true);

  const getTeamInformation = useCallback(() => {
    dispatch(fetchTeamMembers({ isGetTeamMemberMfa: true }));
  }, [dispatch]);

  const getMfaData = useCallback(
    ({ onSuccess }: { onSuccess?: () => void } = {}) => {
      if (myId) {
        dispatch(
          getAuthMfaStatusPerAccount({
            accountIds: [myId],
            meta: {
              onSuccess
            }
          })
        );
      }
    },
    [dispatch, myId]
  );

  const getSamlData = useCallback(() => {
    if (teamId) {
      dispatch(
        generateTeamSamlInfo({
          teamId,
          samlIdp: preferredAuthMethods.saml
        })
      );
    }
  }, [dispatch, teamId]);

  const modifyTeamMfaSetting = useCallback(
    ({
      value,
      onSuccess
    }: {
      value: SupportedMfaStatus;
      onSuccess: () => void;
    }) => {
      dispatch(
        updateTeamAuthSettings({
          teamMfaSetting: value,
          meta: {
            onSuccess,
            requestStatusId: changeTeamMfaSettingRequestId
          }
        })
      );
    },
    [dispatch]
  );

  const disableMfaTeamwide = useCallback(() => {
    if (authSettings?.toggled_mfa !== MFA_STATUS.disabled) {
      modifyTeamMfaSetting({
        value: MFA_STATUS.disabled,
        onSuccess: () => {
          getTeamInformation();
          getMfaData();
        }
      });
    }
  }, [
    authSettings?.toggled_mfa,
    getMfaData,
    getTeamInformation,
    modifyTeamMfaSetting
  ]);

  const handleChangeTeamMfaSetting = useCallback(
    ({
      value: mfaSetting,
      onRequireSetupMFA,
      onSuccess
    }: {
      value: SupportedMfaStatus;
      onRequireSetupMFA: () => void;
      onSuccess: () => void;
    }) => {
      // TODO: Remove `on` name of function
      const handleKnowOwnUserMfaEnabled = () => {
        // TODO: Remove `on` name of function
        const handleKnowReadyToSwitchToNewNonDisabledTeamMfaType = () => {
          modifyTeamMfaSetting({
            value: mfaSetting,
            onSuccess: () => {
              getTeamInformation();
              getMfaData();
              onSuccess();
            }
          });
        };

        if (isRequireSetupMFA) {
          onRequireSetupMFA();
          return;
        }

        // if the user not trying to turn off team-wide MFA and is instead trying to switch to one of the non-disabled team-wide MFA options, we must first disable MFA for the team before we can switch to the new non-disabled team-wide MFA option
        if (
          authSettings?.toggled_mfa !== MFA_STATUS.disabled &&
          mfaSetting !== MFA_STATUS.disabled
        ) {
          modifyTeamMfaSetting({
            value: MFA_STATUS.disabled,
            onSuccess: handleKnowReadyToSwitchToNewNonDisabledTeamMfaType
          });
          return;
        }

        handleKnowReadyToSwitchToNewNonDisabledTeamMfaType();
      };

      // user must enable the target MFA type before they can switch the team to it
      if (isUserMfaDisabled && myId) {
        dispatch(
          toggleIndividualMfa({
            accountIds: [myId],
            isEnableMfa: true,
            mfaType: mfaSetting,
            meta: {
              onSuccess: handleKnowOwnUserMfaEnabled,
              requestStatusId: changeTeamMfaSettingRequestId
            }
          })
        );
      } else {
        handleKnowOwnUserMfaEnabled();
      }
    },
    [
      authSettings?.toggled_mfa,
      dispatch,
      getMfaData,
      getTeamInformation,
      isRequireSetupMFA,
      isUserMfaDisabled,
      modifyTeamMfaSetting,
      myId
    ]
  );

  const handleAssociateSamlIdentity = useCallback(
    ({
      certificate,
      domain,
      ssoUrl,
      onSuccess
    }: {
      certificate: string;
      domain: string;
      ssoUrl: string;
      onSuccess: () => void;
    }) => {
      if (teamId) {
        dispatch(
          associateSamlIdentity({
            certificate,
            teamId,
            domain,
            entryPoint: ssoUrl,
            samlIdp: preferredAuthMethods.saml,
            meta: {
              requestStatusId: associateSamlIdentityRequestId,
              onSuccess: () => {
                dispatch(
                  setAuthMethod({
                    authMethod: preferredAuthMethods.saml,
                    teamId,
                    meta: {
                      requestStatusId: setAuthMethodRequestId,
                      onSuccess: () => {
                        getTeamInformation();
                        getSamlData();
                        onSuccess();
                      }
                    }
                  })
                );
              }
            }
          })
        );
      }
    },
    [dispatch, getSamlData, getTeamInformation, teamId]
  );

  const disableSaml = useCallback(() => {
    if (teamId) {
      dispatch(
        setAuthMethod({
          authMethod: preferredAuthMethods.default,
          teamId,
          meta: {
            requestStatusId: setAuthMethodRequestId,
            onSuccess: () => {
              getTeamInformation();
              getSamlData();
            }
          }
        })
      );
    }
  }, [dispatch, getSamlData, getTeamInformation, teamId]);

  return {
    isMfaFeatureFlagEnabled: mfaEnabledFlag,
    isSmsMfaFeatureFlagEnabled: useSmsMfa,
    isSamlFeatureFlagEnabled: useSamlAuth,
    isMfaActive,
    isSamlActive,
    isMfaForciblyDisabled:
      isSamlActive || (!!realmId && process.env.UI_ENV === 'demo'),
    isSamlForciblyDisabled:
      isMfaActive || (!!realmId && process.env.UI_ENV === 'demo'),
    teamWideMfaSetting,
    isChangingTeamMfaSetting: !!changeTeamMfaSettingRequestStatus?.isExecuting,
    isModifyingSaml:
      !!associateSamlIdentityRequestStatus?.isExecuting ||
      !!setAuthMethodRequestStatus?.isExecuting,
    teamSamlInfo,
    getMfaData,
    getTeamInformation,
    changeTeamMfaSetting: handleChangeTeamMfaSetting,
    associateSamlIdentity: handleAssociateSamlIdentity,
    disableMfaTeamwide,
    getSamlData,
    disableSaml
  };
};
