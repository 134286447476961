import reject from 'lodash/reject';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import * as constants from 'appConstants';
import { fromJS } from 'immutable';

const byId = (item) => item && item.id;
const filterProjects = (projects, projId) =>
  projects.filter((project) => project.id === projId)[0];
const createNewProjectsArr = (projects) => projects.map((project) => project);

const getOldStateProjects = (state, updatedProject) => {
  const oldStateObj = updatedProject.is_starred
    ? state.myProjects
    : state.myStarredProjects;
  return reject(oldStateObj, (project) => project.id === updatedProject.id);
};

const getNewStateProjects = (state, updatedProject) => {
  const newStateObj = updatedProject.is_starred
    ? state.myStarredProjects
    : state.myProjects;
  const sortedProjectList = reject(
    newStateObj,
    (project) => project.id === updatedProject.id
  );
  sortedProjectList.splice(updatedProject.position, 0, updatedProject);
  return sortedProjectList;
};

export const initialFilterState = {
  isFetchingCheckins: false,
  isFetchingCheckinsHours: false,
  isFetching: false,
  checkinsHours: {},
  checkins: [],
  createdCheckins: {}
};

export const initialState = {
  myProjects: [],
  myProjectsCached: [],
  myStarredProjects: [],
  myStarredProjectsCached: [],
  totalProjects: 0,
  totalAllProjects: 0,
  homeOrigin: false,
  enableChangeProject: false,
  refreshSidebar: false,
  isMovingProject: false,
  isFetchingAllProjects: false,
  isFetchingMyProjects: false,
  isFetchingCheckins: {},
  isFetchingCheckinsHours: {},
  openSidebar: false,
  sidebarScrollTop: 0,
  allProjectsOrder: [],
  starredProjectIds: [],
  unstarredProjectIds: [],
  checkinsHours: {},
  checkins: [],
  createdCheckins: {},
  filterStates: {}
};

const home = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.SET_HOME_ORIGIN:
      return {
        ...state,
        homeOrigin: action.isHomeOrigin
      };
    case constants.SET_ENABLE_CHANGE_PROJECT:
      return {
        ...state,
        enableChangeProject: action.isEnabled
      };
    case constants.FETCH_MY_PROJECTS.REQUEST:
      return {
        ...state,
        isFetchingMyProjects: true
      };
    case constants.FETCH_ALL_PROJECTS.REQUEST:
      return {
        ...state,
        isFetchingAllProjects: true
      };
    case constants.FETCH_MY_PROJECTS.SUCCESS:
      const { starred_ids, unstarred_ids } = action.payload.response;
      let myProjects = [];
      let myStarredProjects = [];
      let myProjectsCached = state.myProjectsCached;
      let myStarredProjectsCached = state.myStarredProjectsCached;

      if (
        action.payload.requestPayload.params &&
        action.payload.requestPayload.params.searchText &&
        action.payload.requestPayload.params.searchText !== ''
      ) {
        myProjects = action.payload.response.my_projects;
        myStarredProjects = action.payload.response.my_starred_projects;
      } else {
        myProjects = sortBy(
          uniqBy(
            [...action.payload.response.my_projects, ...state.myProjects],
            (project) => project.id
          ),
          (project) => project.position
        );
        myStarredProjects = sortBy(
          uniqBy(
            [
              ...action.payload.response.my_starred_projects,
              ...state.myStarredProjects
            ],
            (project) => project.id
          ),
          (project) => project.position
        );

        myProjectsCached = myProjects;
        myStarredProjectsCached = myStarredProjects;
      }

      return {
        ...state,
        myProjects: myProjects,
        myProjectsCached: myProjectsCached,
        myStarredProjects: myStarredProjects,
        myStarredProjectsCached: myStarredProjectsCached,
        isFetchingMyProjects: false,
        totalProjects: action.payload.response.total_count,
        isMovingProject: false,
        starredProjectIds: starred_ids,
        unstarredProjectIds: unstarred_ids
      };
    case constants.FETCH_ALL_PROJECTS.SUCCESS: {
      const { projects, project_count } = action.payload.response;
      const { projectIds } = action.payload.requestPayload.params || {};
      const newProjectsOrder =
        !!projectIds && projectIds.length
          ? state.allProjectsOrder || []
          : Array.from(
              new Set([
                ...state.allProjectsOrder,
                ...(projects.map(byId) || [])
              ])
            );

      const newProjectTotal = projectIds
        ? state.totalAllProjects
        : project_count;

      return {
        ...state,
        isFetchingAllProjects: false,
        totalAllProjects: newProjectTotal,
        isMovingProject: false,
        allProjectsOrder: newProjectsOrder
      };
    }
    case constants.CLEAR_SEARCH:
      return {
        ...state,
        myProjects: state.myProjectsCached,
        myStarredProjects: state.myStarredProjectsCached
      };
    case constants.LOAD_WITH_SIDEBAR_OPEN:
      return {
        ...state,
        openSidebar: action.payload.openSidebar,
        sidebarScrollTop: action.payload.scrollTop
      };
    case constants.MY_PROJECT_UPDATE.TRIGGER:
      return {
        ...state,
        isMovingProject: true
      };

    case constants.STAR_PROJECT.SUCCESS: {
      const updatedProject = action.payload.response.project[0];
      const oldStateName = updatedProject.is_starred
        ? 'myProjects'
        : 'myStarredProjects';
      const newStateName = updatedProject.is_starred
        ? 'myStarredProjects'
        : 'myProjects';

      return {
        ...state,
        [oldStateName]: getOldStateProjects(state, updatedProject),
        [newStateName]: getNewStateProjects(state, updatedProject)
      };
    }

    case constants.MY_PROJECT_UPDATE.SUCCESS: {
      const updatedProject = action.payload.response;
      const oldStateName = updatedProject.is_starred
        ? 'myProjects'
        : 'myStarredProjects';
      const newStateName = updatedProject.is_starred
        ? 'myStarredProjects'
        : 'myProjects';
      return {
        ...state,
        [oldStateName]: getOldStateProjects(state, updatedProject),
        [newStateName]: getNewStateProjects(state, updatedProject)
      };
    }

    case constants.DELETE_MEMBER_FROM_PROJECT.SUCCESS: {
      const oldMyProjects = state.myProjects;
      const oldCachedProjects = state.myProjectsCached;
      const oldStarredProjects = state.myStarredProjects;

      const updatedProject = action.payload.response;

      if (action.payload.requestPayload[3]) {
        const projectType = updatedProject.is_starred
          ? 'myStarredProjects'
          : 'myProjects';
        let newProjectsState, newCachedProjects;

        newProjectsState = reject(state[projectType], (project) => {
          return project.id === updatedProject.id;
        });

        newCachedProjects = reject(oldCachedProjects, (project) => {
          return project.id === updatedProject.id;
        });

        return {
          ...state,
          [projectType]: newProjectsState,
          myProjectsCached: newCachedProjects
        };
      }
      return state;
    }
    case constants.PROJECT_EDITION.TRIGGER: {
      if (!action.payload.title) {
        return state;
      }
      const statesToCheck = [
        'myProjects',
        'myStarredProjects',
        'myProjectsCached',
        'myStarredProjectsCached'
      ];
      const newState = {};

      statesToCheck.forEach((projState) => {
        const oldProjState = fromJS(state[`${projState}`]);
        const oldProjIdx = oldProjState.findIndex(
          (proj) => proj.get('id') === action.payload.id
        );
        if (oldProjIdx > -1) {
          const newProjState = oldProjState.setIn(
            [oldProjIdx, 'title'],
            action.payload.title
          );
          newState[projState] = newProjState.toJS();
        }
      });
      return {
        ...state,
        ...newState
      };
    }

    case constants.FETCH_CHECKINS.TRIGGER: {
      const { filterStateId } = action.payload;
      if (filterStateId) {
        const initial = !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(initial
                ? initialFilterState
                : state.filterStates[filterStateId]),
              isFetchingCheckins: true
            }
          }
        };
      } else {
        return {
          ...state,
          isFetchingCheckins: true
        };
      }
    }
    case constants.FETCH_CHECKINS.FAILURE: {
      const { filterStateId } = action.payload.requestPayload;
      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isFetchingCheckins: false
            }
          }
        };
      } else {
        return {
          ...state,
          isFetchingCheckins: false
        };
      }
    }
    case constants.FETCH_CHECKINS.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { check_ins } = response;
      const { filterStateId } = requestPayload;

      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isFetchingCheckins: false,
              checkins: check_ins
            }
          }
        };
      }
      return {
        ...state,
        isFetchingCheckins: false,
        checkins: check_ins
      };
    }

    case constants.FETCH_CHECKINS_HOURS.TRIGGER: {
      const { filterStateId } = action.payload;
      if (filterStateId) {
        const initial = !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(initial
                ? initialFilterState
                : {
                    ...state.filterStates[filterStateId]
                  }),
              isFetchingCheckinsHours: true
            }
          }
        };
      } else {
        return {
          ...state,
          isFetchingCheckinsHours: true
        };
      }
    }
    case constants.FETCH_CHECKINS_HOURS.FAILURE: {
      const { filterStateId } = action.payload.requestPayload;
      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isFetchingCheckinsHours: false
            }
          }
        };
      } else {
        return {
          ...state,
          isFetchingCheckinsHours: false
        };
      }
    }
    case constants.FETCH_CHECKINS_HOURS.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { filterStateId } = requestPayload;
      const checkIns = keyBy(response, (item) => item.account_id);

      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              isFetchingCheckinsHours: false,
              checkinsHours: {
                ...state.filterStates[filterStateId].checkinsHours,
                ...checkIns
              }
            }
          }
        };
      }
      return {
        ...state,
        isFetchingCheckinsHours: false,
        checkinsHours: {
          ...state.checkinsHours,
          ...checkIns
        }
      };
    }

    case constants.CREATE_CHECKIN.TRIGGER: {
      const { filterStateId } = action.payload;
      if (filterStateId) {
        const initial = !state.filterStates[filterStateId];
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...(initial
                ? initialFilterState
                : {
                    ...state.filterStates[filterStateId]
                  })
            }
          }
        };
      } else {
        return {
          ...state
        };
      }
    }

    case constants.CREATE_CHECKIN.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { filterStateId } = requestPayload[2];
      if (filterStateId) {
        return {
          ...state,
          filterStates: {
            ...state.filterStates,
            [filterStateId]: {
              ...state.filterStates[filterStateId],
              createdCheckins: {
                ...state.filterStates[filterStateId].createdCheckin,
                [response.id]: !requestPayload[2].isWorkplan
              }
            }
          }
        };
      }
      return {
        ...state,
        createdCheckins: {
          ...state.createdCheckins,
          [response.id]: !requestPayload[2].isWorkplan
        }
      };
    }

    default:
      return state;
  }
};

export default home;
