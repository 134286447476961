import styled from 'styled-components';
import { BaseTableColumn } from 'components/Table/types';
import theme from 'theme';

interface StandardCapacityTableHeaderCellProps {
  column: BaseTableColumn;
}

// TODO: Move this to more reusable place
export const StandardCapacityTableHeaderCell = ({
  column
}: StandardCapacityTableHeaderCellProps) => {
  return (
    <StyledHeaderCell style={{ justifyContent: column.align }}>
      {column.headerLabel}
    </StyledHeaderCell>
  );
};

const StyledHeaderCell = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarGray};
  display: flex;
  align-items: center;
`;
