import styled from 'styled-components';
import theme from 'theme';
import { updateTeamMembership } from 'TeamsModule/actionCreators';
import { PlaceholderType } from 'TeamsModule/models/settings';
import { getMyTeamMembership } from 'selectors';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { usePopover } from 'components/Popover/usePopover';
import {
  StyledFilterMenu as StyledOptionMenu,
  StyledFilterMenuItem
} from 'ReportsModule/components/Filter/styles';
import { timesheetOptions, placeholderOptions } from 'appConstants/timesheets';
import DropdownThinIcon from 'icons/DropdownThinIcon';

const EstimatedTimeController = () => {
  const dispatch = useAppDispatch();
  const teamMembership = useAppSelector(getMyTeamMembership);
  const { Popover, openPopover } = usePopover();
  const currentSettings = teamMembership?.timesheet_preferences;
  const placeholderSetting = currentSettings?.show_placeholder;
  const placeholderSettingIsOn =
    placeholderSetting === timesheetOptions.WORK_PLANS_AND_CHECK_INS ||
    placeholderSetting === timesheetOptions.MOSAIC_ESTIMATES;

  const handleSelect = (val: PlaceholderType) => {
    const body = {
      timesheetPreferences: { show_placeholder: val },
      teamMembershipId: teamMembership?.id
    };
    dispatch(updateTeamMembership(body));
  };

  return (
    <Container
      data-for="app-tooltip"
      data-class="mw-250"
      data-html
      data-effect="solid"
      data-tip="You must enter worked time. An estimated time placeholder is displayed. It is based on Work Plans, Check-ins, Timers, and Activities."
    >
      <ToggleLabel onClick={(e) => openPopover({ event: e })}>
        {placeholderSettingIsOn ? 'Estimate On' : 'Estimate Off'}
      </ToggleLabel>
      <DropdownThinIcon />
      <Popover className="timesheet-placeholder-menu">
        <StyledOptionMenu>
          {placeholderOptions.map(({ name, value }) => (
            <StyledMenuItem
              onClick={() => handleSelect(value)}
              className={placeholderSetting === value ? 'active' : ''}
              key={value}
            >
              {name}
            </StyledMenuItem>
          ))}
        </StyledOptionMenu>
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  display: flex;
  left: calc(-100% - 45px);
  top: -8px;
  align-items: center;
  justify-content: space-between;
  width: 95px;
`;

const ToggleLabel = styled.div`
  margin-right: 6px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
`;

const StyledMenuItem = styled(StyledFilterMenuItem)`
  color: ${theme.colors.colorMediumGray9};
`;

export default EstimatedTimeController;
