import styled from 'styled-components';
import theme from 'theme';
import { PermissionList } from '../types';
import { PERMISSION_SECTION } from 'PermissionsModule/constants';

export const PermissionsSectionHeaderCell = ({
  row
}: {
  row: { original: PermissionList };
}) => {
  const { permissionSectionId } = row.original;

  return (
    <StyledPermissionsSectionHeaderCell>
      {sectionToComponentHash[permissionSectionId]}
    </StyledPermissionsSectionHeaderCell>
  );
};

/* ------------------------------------ - ----------------------------------- */

// Add new sections here
const sectionToComponentHash = {
  [PERMISSION_SECTION.ORGANIZATION]: <span>Organization</span>,
  [PERMISSION_SECTION.PORTFOLIOS]: <span>Portfolios</span>,
  [PERMISSION_SECTION.TIMESHEETS]: <span>Timesheets</span>,
  [PERMISSION_SECTION.PROJECTS]: <span>Projects</span>,
  [PERMISSION_SECTION.INTEGRATIONS]: <span>Integrations</span>,
  [PERMISSION_SECTION.REPORTS]: <span>Reports</span>,
  [PERMISSION_SECTION.WORK_PLANS]: <span>Work Plans</span>,
  [PERMISSION_SECTION.BUDGET]: <span>Budget</span>,
  [PERMISSION_SECTION.PROFILE]: <span>Profile</span>,
  [PERMISSION_SECTION.DASHBOARDS]: <span>Dashboards</span>
};

const StyledPermissionsSectionHeaderCell = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
  font-weight: 600;
  font-size: 18px;
  color: ${theme.colors.colorMediumGray9};
`;
