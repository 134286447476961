import React from 'react';
import {
  ColumnPropertyCell,
  EmptyStateContainer,
  StyledProjectStatusContainer,
  StyledProjectStatusDot,
  StyledProjectStatusText
} from './styles';
import { connect } from 'react-redux';
import { getOrderedStatuses, makeGetOwnStatus } from 'selectors';
import OptionsSelectFlyout from 'components/OptionsSelectFlyout/OptionsSelectFlyout';
import ProjectStatusListItem from './ProjectStatusListItem';
import columnHeader from './ProjectColumnHeaders';

const copy = {
  addEdit: 'Add/Edit Status',
  createNew: 'Create New Status'
};

class ProjectStatus extends React.PureComponent {
  state = {
    isDropdownOpen: false
  };

  renderSelectedItem = (item) => (
    <StyledProjectStatusContainer>
      <StyledProjectStatusDot color={item.color} />
      <StyledProjectStatusText>{item.title}</StyledProjectStatusText>
    </StyledProjectStatusContainer>
  );

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };

  render() {
    const {
      projectId,
      statuses,
      status = {},
      onSubmit,
      outerPopoverTarget
    } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <ColumnPropertyCell onClick={this.toggleDropdown}>
        <OptionsSelectFlyout
          outerPopoverTarget={outerPopoverTarget}
          selectedItem={status}
          listItems={statuses}
          rowId={projectId}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={this.toggleDropdown}
          onSubmit={onSubmit}
          ListItemComponent={ProjectStatusListItem}
          copy={copy}
          droppableType="statusOrder"
          renderSelectedItem={this.renderSelectedItem}
          isDot
        />
        <EmptyStateContainer show={isDropdownOpen} isBlue={isDropdownOpen}>
          {!status.title && columnHeader['status']}
        </EmptyStateContainer>
      </ColumnPropertyCell>
    );
  }
}

const makeMapStateToProps = () => {
  const getOwnStatus = makeGetOwnStatus();
  const mapStateToProps = (state, ownProps) => ({
    status: getOwnStatus(state, ownProps),
    statuses: getOrderedStatuses(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(ProjectStatus);
