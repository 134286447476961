import useFindPeopleModal from 'SuggestionModule/hooks/useFindPeopleModal';

const withFindPeopleModal = (WrappedComponent) => {
  const WithFindPeopleModal = (props) => {
    const {
      toggleFindPeopleModal,
      FindPeopleModal,
      handleOpenFindPeopleModal
    } = useFindPeopleModal();

    return (
      <WrappedComponent
        toggleFindPeopleModal={toggleFindPeopleModal}
        handleOpenFindPeopleModal={handleOpenFindPeopleModal}
        FindPeopleModal={FindPeopleModal}
        {...props}
      />
    );
  };

  return WithFindPeopleModal;
};

export default withFindPeopleModal;
