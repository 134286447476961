import React from 'react';
import cn from 'classnames';
import FlyoutMenu from 'views/utilityComponents/FlyoutMenu';

import { plainText } from 'appUtils/slate';
import { NoteMenuIconsContainer, NoteMenuItem } from './styles';
import SendNoteEmailIcon from 'icons/SendNoteEmailIcon';
import DeleteIcon from 'icons/DeleteIcon';

class DeleteNoteMenu extends React.Component {
  handleDeleteClick = () => {
    this.props.openDeleteNoteModal();
  };

  createEmail = () => {
    const { noteBody, projectName } = this.props;
    const maxBodyChars = 2000;
    const title = this.props.noteTitle.replace(/[#]/g, '') || 'Untitled';
    let footer = null;
    if (window.location.href.includes('demo.mosaicapp.com')) {
      footer = 'Sent from MosaicApp: https:%2F%2Fdemo.mosaicapp.com';
    } else if (window.location.href.includes('pilot.mosaicapp.com')) {
      footer = 'Sent from MosaicApp: https:%2F%pilot.mosaicapp.com';
    } else {
      footer = 'Sent from MosaicApp: https:%2F%2Fprojects.mosaicapp.com';
    }
    const newLine = '%0D%0A%0D%0A';
    let bodyNoteLink = window.location.href + '\n \n';

    let plainTextBody = plainText.serialize(noteBody);
    let instructions =
      'To see the rest of the note, click on the following link: \n';
    let bodySizeLimit =
      maxBodyChars - footer.length - 2 * newLine.length - title.length;

    if (plainTextBody.length > bodySizeLimit) {
      bodySizeLimit = bodySizeLimit - bodyNoteLink.length - instructions.length;
      plainTextBody = plainTextBody.slice(0, bodySizeLimit - 3) + '...';
    } else {
      bodyNoteLink = '';
      instructions = '';
    }

    return (
      'mailto:?&subject=' +
      projectName +
      '&body=' +
      encodeURIComponent(title) +
      newLine +
      encodeURIComponent(plainTextBody) +
      newLine +
      encodeURIComponent(instructions) +
      encodeURIComponent(bodyNoteLink) +
      footer
    );
  };

  render() {
    const { isOpen, closeDeleteNoteMenu } = this.props;
    const handleDeleteClick = this.handleDeleteClick;
    return (
      <FlyoutMenu
        className={cn('delete-note-menu', { visible: isOpen })}
        open={isOpen}
        onRequestClose={closeDeleteNoteMenu}
      >
        {isOpen ? (
          <div>
            <div className="delete-note-triangle"> </div>
            <div className="delete-note-triangle-border"> </div>
          </div>
        ) : null}
        <NoteMenuItem>
          <NoteMenuIconsContainer>
            <SendNoteEmailIcon />
          </NoteMenuIconsContainer>

          <a className="link-div" href={this.createEmail()}>
            Send Email
          </a>
        </NoteMenuItem>
        <NoteMenuItem
          className="delete-note-menu-button clickable"
          onClick={handleDeleteClick}
        >
          <NoteMenuIconsContainer>
            <DeleteIcon />
          </NoteMenuIconsContainer>

          <div className="link-div">Delete Note</div>
        </NoteMenuItem>
      </FlyoutMenu>
    );
  }
}

export default DeleteNoteMenu;
