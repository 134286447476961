import React, { useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ScheduleCalendar } from 'components/ScheduleCalendar';
import CalendarTooltip, {
  getPtoTooltipContent
} from 'DashboardModule/components/Widgets/PersonalWidgets/DueDatesWidget/CalendarTooltip';

const emptyArray = [];

export const EventCalendar = ({
  data,
  backgroundEvents,
  initialDate,
  maxNumDots = 7,
  onSetRange,
  renderTooltip,
  calendarRef
}) => {
  const renderDateItem = useCallback(
    ({ dateObj, item, backgroundEvent }) => {
      const ptoTooltipContent =
        backgroundEvent.event &&
        backgroundEvent.event.type === 'PTO' &&
        getPtoTooltipContent(backgroundEvent.event);
      if (item.events) {
        const extraCount = item.events.length - maxNumDots;
        const shouldRenderMoreCount = extraCount > 0;
        const tooltipContent =
          renderTooltip && renderTooltip({ dateObj, events: item.events });
        const tooltipData = {
          data: [
            ...(tooltipContent && tooltipContent.data
              ? tooltipContent.data
              : emptyArray),
            ...(ptoTooltipContent ? [ptoTooltipContent] : emptyArray)
          ]
        };
        return (
          <DateItemContainer
            data-for="due-dates-cell-tooltip"
            data-tip={JSON.stringify(tooltipData)}
            data-tip-disable={!tooltipData.data.length}
            data-effect="solid"
            data-place="bottom"
          >
            <DotsContainer>
              {item.events.slice(0, maxNumDots).map((event) => (
                <Dot
                  key={event.eventId}
                  data-testid={`event-calendar-dot-${event.eventId}`}
                  displayColor={event.displayColor}
                ></Dot>
              ))}
              {shouldRenderMoreCount && (
                <ExtraEventsCountText data-testid="extra-events-count">
                  {`+${extraCount}`}
                </ExtraEventsCountText>
              )}
            </DotsContainer>
          </DateItemContainer>
        );
      } else if (
        backgroundEvent.event &&
        backgroundEvent.event.type === 'PTO'
      ) {
        return (
          <DateItemContainer
            data-for="due-dates-cell-tooltip"
            data-tip={JSON.stringify({ data: [ptoTooltipContent] })}
            data-effect="solid"
          />
        );
      }
    },
    [maxNumDots, renderTooltip]
  );

  const renderBackground = ({ backgroundEvent, renderOption }) => {
    const Component = backgroundComponentHash[backgroundEvent.type];
    if (!Component) return <></>;
    return <Component />;
  };
  return (
    <RootContainer>
      <ScheduleCalendar
        initialViewType={'month'}
        data={data}
        backgroundEvents={backgroundEvents}
        currentDate={initialDate}
        renderDateItem={renderDateItem}
        renderBackground={renderBackground}
        onSetRange={onSetRange}
        calendarRef={calendarRef}
      ></ScheduleCalendar>
      <CalendarTooltip className="calendar-event-tooltip"></CalendarTooltip>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  .calendar-event-tooltip {
    opacity: 1;
    padding: 14px;
    box-shadow: 0px 3px 5px rgba(171, 171, 171, 0.5);
  }
`;

const DotsContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  padding: 0 16px;
  align-content: center;
`;

const DateItemContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Dot = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 7px;
  background: ${({ theme, displayColor }) =>
    displayColor || theme.colors.colorSemiDarkGray5};
`;

const ExtraEventsCountText = styled.span`
  font-size: 10px;
  line-height: 7px;
  color: ${({ theme }) => theme.colors.colorCalendarGray};
`;

const BackgroundTile = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const PTOBackground = styled(BackgroundTile)`
  background: ${({ theme }) => theme.gradients.pto};
`;

const HolidayBackground = styled(BackgroundTile)`
  background: ${({ theme }) => theme.gradients.holiday};
`;

const backgroundComponentHash = {
  PTO: PTOBackground,
  holiday: HolidayBackground
};
