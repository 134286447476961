import { MouseEvent } from 'react';
import { CheckboxItemType } from 'SettingsModule/types/checkBoxOptions';
import {
  CheckBoxOptionText,
  DefaultTextContainer,
  PermissionCheckboxClickArea
} from './styles';
import { Checkbox } from 'components/Checkbox';

interface PermissionCheckboxInputProps {
  isChecked: boolean;
  isDisabled: boolean;
  onClick: (e: MouseEvent<HTMLElement> | null) => void;
  item: CheckboxItemType;
}

export const PermissionCheckboxInput = ({
  isChecked,
  isDisabled,
  item,
  onClick
}: PermissionCheckboxInputProps) => {
  return (
    <PermissionCheckboxClickArea isDisabled={isDisabled} onClick={onClick}>
      <Checkbox
        checked={isChecked}
        disabled={isDisabled}
        size={18}
        hitSlopRatio={1}
      />
      <CheckBoxOptionText isChecked={isChecked}>
        {item.text}
        {item.default && <DefaultTextContainer>Default</DefaultTextContainer>}
      </CheckBoxOptionText>
    </PermissionCheckboxClickArea>
  );
};
