import { RowOptionsGetterWithRequiredAdditionalParams } from 'CsvImportsModule/types';
import { getRowValuesForEntityType } from './utils';
interface AdditionalParams {
  // { projectTitle: { projectNumber: { phaseName: feeTargetValue } } }
  phaseFeeTargetOption: React.MutableRefObject<{
    [projectTitle: string]: {
      [projectNumber: string]: { [phaseName: string]: string };
    };
  }>;
}

export const phaseFeeTargetOptionsGetter: RowOptionsGetterWithRequiredAdditionalParams<
  AdditionalParams
> = ({ row, entityType, additionalParams }) => {
  const { phaseFeeTargetOption } = additionalParams;
  const { enteredProjectTitle, enteredProjectNumber, enteredPhaseName } =
    getRowValuesForEntityType(row, entityType);
  const matchedFeeTargetByEnteredFieldValues =
    phaseFeeTargetOption.current[enteredProjectTitle]?.[enteredProjectNumber]?.[
      enteredPhaseName
    ];
  // if there were no option for this project title + project number + phase name combination, return an empty array
  // including '' so that user can go back to empty field
  if (matchedFeeTargetByEnteredFieldValues === undefined) return [''];
  // else reuturn the matched option
  else if (matchedFeeTargetByEnteredFieldValues === '') return [''];
  else return ['', matchedFeeTargetByEnteredFieldValues];
};
