import React from 'react';
import SvgIcon from 'components/SvgIcon';

const MilestoneIcon = ({
  height = '14',
  width = '14',
  className,
  fillColor = 'white',
  strokeColor = '#828282'
}) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4 5.35294C9.4 5.94117 9.14462 6.68166 8.69256 7.50669C8.2476 8.31876 7.6467 9.15122 7.03388 9.9073C6.42281 10.6612 5.81013 11.3267 5.34941 11.8043C5.21942 11.9391 5.10178 12.0586 5 12.1607C4.89822 12.0586 4.78058 11.9391 4.65059 11.8043C4.18987 11.3267 3.57719 10.6612 2.96612 9.9073C2.3533 9.15122 1.7524 8.31876 1.30744 7.50669C0.85538 6.68166 0.6 5.94117 0.6 5.35294C0.6 2.68876 2.60784 0.6 5 0.6C7.39216 0.6 9.4 2.68876 9.4 5.35294Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </SvgIcon>
  );
};

export default MilestoneIcon;
