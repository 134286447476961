<svg
  width="15"
  height="13"
  viewBox="0 0 15 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.28569 10.1142C6.62642 11.0542 5.52311 11.7203 3.97578 12.1124C3.89393 12.1735 3.81208 12.204 3.70295 12.204C3.64839 12.204 3.59382 12.204 3.53926 12.1735C3.40284 12.1124 3.29372 11.9293 3.29372 11.7462C3.42962 10.9947 3.49202 10.3071 3.48091 9.68348C2.18205 8.98108 1.28613 7.49323 1.28613 5.77133C1.28613 3.37283 3.02445 1.42847 5.16877 1.42847H9.97492C12.1192 1.42847 13.8576 3.37283 13.8576 5.77133C13.8576 8.16983 12.1192 10.1142 9.97492 10.1142H7.28569Z"
    stroke="#828282"
    strokeWidth="1.2"
  />
</svg>;

import React from 'react';
import SvgIcon from 'components/SvgIcon';

const UpdateProjectIcon = () => {
  return (
    <SvgIcon width="15" height="13" viewBox="0 0 15 13" fill="none">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.28569 10.1142C6.62642 11.0542 5.52311 11.7203 3.97578 12.1124C3.89393 12.1735 3.81208 12.204 3.70295 12.204C3.64839 12.204 3.59382 12.204 3.53926 12.1735C3.40284 12.1124 3.29372 11.9293 3.29372 11.7462C3.42962 10.9947 3.49202 10.3071 3.48091 9.68348C2.18205 8.98108 1.28613 7.49323 1.28613 5.77133C1.28613 3.37283 3.02445 1.42847 5.16877 1.42847H9.97492C12.1192 1.42847 13.8576 3.37283 13.8576 5.77133C13.8576 8.16983 12.1192 10.1142 9.97492 10.1142H7.28569Z"
          stroke="#828282"
          strokeWidth="1.2"
        />
      </g>
    </SvgIcon>
  );
};

export default UpdateProjectIcon;
