import { createSelector } from 'reselect';
import { makeIdHash } from 'appUtils';

import { initialState as milestoneTemplatesInitialState } from '../reducers/milestoneTemplates';

import { getPhaseTemplateModalId } from './phases';

const emptyObj = {};

export const getMilestoneTemplatesState = (state) =>
  state.milestoneTemplates || milestoneTemplatesInitialState;

export const getMilestoneTemplateOrder = createSelector(
  getMilestoneTemplatesState,
  (milestoneTemplateState) => milestoneTemplateState.milestoneTemplateOrder
);

export const getMilestoneTemplates = createSelector(
  getMilestoneTemplatesState,
  (milestoneTemplateState) =>
    milestoneTemplateState && milestoneTemplateState.milestoneTemplates
);

export const getOrderedMilestoneTemplates = createSelector(
  getMilestoneTemplateOrder,
  getMilestoneTemplates,
  (ids, milestoneTemplates) => ids.map((id) => milestoneTemplates[id])
);

export const getMilestoneTemplatesList = createSelector(
  getMilestoneTemplates,
  (milestoneTemplates) => Object.values(milestoneTemplates)
);

export const getLowerCaseExistingMilestoneTemplates = createSelector(
  getMilestoneTemplates,
  (milestoneTemplates) =>
    makeIdHash(
      Object.values(milestoneTemplates)
        .filter(
          (milestoneTemplate) =>
            milestoneTemplate.name && !milestoneTemplate.archived
        )
        .map((milestoneTemplate) => milestoneTemplate.name.toLowerCase())
    )
);

export const getModalMilestoneTemplate = createSelector(
  getMilestoneTemplates,
  getPhaseTemplateModalId,
  (milestoneTemplates, id) => milestoneTemplates[id] || emptyObj
);

export const getModalMilestoneTemplateName = createSelector(
  getModalMilestoneTemplate,
  (milestoneTemplate) => milestoneTemplate.name
);
