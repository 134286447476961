import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getSelectedTeamId } from 'selectors';
import { StyledPhaseOfWorkInput } from './styles';
import PropTypes from 'prop-types';

class PhaseOfWorkTemplateInput extends React.Component {
  state = {
    name: this.props.name || this.props.preFillText || ''
  };

  componentDidUpdate(prevProps) {
    const { name, preFillText } = this.props;
    if (name !== prevProps.name && name !== this.state.name) {
      this.setState({ name });
    }
    if (
      preFillText !== prevProps.preFillText &&
      preFillText !== this.state.name
    ) {
      this.setState({ name: preFillText });
    }
  }

  handleChange = (e) => {
    const { onChangeCallback } = this.props;
    if (onChangeCallback) {
      onChangeCallback(e);
    }
    this.setState({ name: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { teamId, id, handleSubmit } = this.props;

    const { name } = this.state;
    handleSubmit({
      teamId,
      id,
      name
    });
  };

  handleBlur = () => {
    const { handleBlur } = this.props;
    if (handleBlur) {
      handleBlur();
    }
  };

  render() {
    const { isMilestone, isInModal, placeholder, inputClassName } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <StyledPhaseOfWorkInput
          type="text"
          value={this.state.name}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onBlur={this.handleBlur}
          className={inputClassName}
          placeholder={
            placeholder ||
            (isMilestone ? 'Type Milestone' : 'Type Phase Of Work')
          }
          isInModal={isInModal}
        />
      </form>
    );
  }
}

PhaseOfWorkTemplateInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.number
};

const mapStateToProps = (state) => ({
  teamId: getSelectedTeamId(state)
});

export default withRouter(connect(mapStateToProps)(PhaseOfWorkTemplateInput));
