export const homeDescriptions = {
  scheduled: 'This task has been moved to Scheduled',
  inbox: 'This task has been moved to Inbox',
  completed: 'This task has been moved to completed.',
  incomplete: 'This task has been marked incomplete'
};

export const projectDescriptions = {
  scheduled: 'This task has been scheduled',
  inbox: 'This task has been marked incomplete',
  completed: 'This task has been moved to completed.',
  incomplete: 'This task has been marked incomplete'
};

export const teamMemberDescriptions = {
  scheduled: 'This task has been scheduled',
  inbox: 'This task has been marked incomplete',
  completed: 'This task has been moved to completed.',
  incomplete: 'This task has been marked incomplete'
};

export const scheduled = 'scheduled';
export const inbox = 'inbox';
export const completed = 'completed';
export const incomplete = 'incomplete';
