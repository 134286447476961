import React from 'react';
import NavigationTracker from 'views/Navigation/NavigationTracker';
import { ProjectDetailViews } from './ProjectDetailViews';
import { BoardViews } from './BoardViews';
import { MembersViews } from './MembersViews';
import { TeamSettingsViews } from './TeamSettingsViews';
import { StandardSettingsViews } from './StandardSettingsViews';
import { MembersSettingsViews } from './MembersSettingsViews';
import { PermissionsSettingsViews } from 'PermissionsModule/components/settingsViews';
import { ReportsViews } from './ReportsViews';
import { BudgetModalViews } from './BudgetModalViews';
import withViewChecker, { PropsFromViewChecker } from './ViewChecker';

const moduleStore = {
  projectDetailView: ProjectDetailViews,
  boardView: BoardViews,
  membersView: MembersViews,
  membersSettingsView: MembersSettingsViews,
  standardSettingsView: StandardSettingsViews,
  teamSettingsView: TeamSettingsViews,
  reportsView: ReportsViews,
  budgetModalView: BudgetModalViews,
  permissionsSettingsView: PermissionsSettingsViews
};

type ViewFactoryProps = PropsFromViewChecker;

class ViewFactory extends React.Component<ViewFactoryProps> {
  buildView = () => {
    const { moduleType, viewKey } = this.props;
    return moduleStore[moduleType][viewKey];
  };

  shouldComponentUpdate(nextProps: ViewFactoryProps) {
    const { moduleType, viewKey, matchedParams } = this.props;
    const shouldUpdate =
      moduleType !== nextProps.moduleType ||
      viewKey !== nextProps.viewKey ||
      matchedParams?.projectId !== nextProps.matchedParams?.projectId ||
      matchedParams?.teamMemberId !== nextProps.matchedParams?.teamMemberId ||
      // Secondary views of viewTypes
      matchedParams?.reportViewType !==
        nextProps.matchedParams?.reportViewType ||
      matchedParams?.budgetSecondaryViewType !==
        nextProps.matchedParams?.budgetSecondaryViewType;
    return shouldUpdate;
  }

  render() {
    const {
      matchedParams,
      moduleType,
      children,
      viewKey,
      ignoreViewKey = false
    } = this.props;
    const View = moduleStore[moduleType][viewKey];
    const viewKeyProps = ignoreViewKey ? {} : { view: viewKey?.toUpperCase() };
    return View ? (
      <>
        <NavigationTracker {...viewKeyProps} />
        <View matchedParams={matchedParams} moduleType={moduleType} />
        {children}
      </>
    ) : null;
  }
}

export default withViewChecker(ViewFactory);
