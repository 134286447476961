import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchBudgetRecords } from 'BudgetModule/actionCreators';
import {
  makeGetBudgetRecordsByFilterStateId,
  getBudgetRecordsData
} from 'BudgetModule/selectors';
import BudgetWidgetTable from './BudgetWidgetTable';

import useFetchUnloadedProjects from 'appUtils/hooks/useFetchUnloadedProjects';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

export const ROWS_PER_PAGE = 4;

const BudgetWidget = ({
  // props
  activeFilter,
  filterId,
  widgetConfig,
  teamId,

  // selectors
  budgetRecords,
  data,

  // actions
  fetchBudgetRecords
}) => {
  const { topLevelOrder: phaseOrder, isFetching } = budgetRecords;

  const projectIds = useMemo(
    () => activeFilter.project_ids,
    [activeFilter.project_ids]
  );

  useFetchUnloadedProjects({ projectIds });

  useEffect(() => {
    rebuildTooltip();
  }, [projectIds]);

  const phaseIds = useMemo(
    () => activeFilter.phase_ids?.slice(0, widgetConfig.limits.phase_ids) || [],
    [activeFilter.phase_ids, widgetConfig]
  );
  const phaseIdEquality = useMemo(() => JSON.stringify(phaseIds), [phaseIds]);

  const fetchParams = useMemo(
    () => ({
      phase_ids: phaseIds,
      team_id: teamId,
      data_type: 'Phase'
    }),

    // do not include phaseIds directly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, phaseIdEquality]
  );

  useEffect(() => {
    if (fetchParams.phase_ids.length && teamId) {
      fetchBudgetRecords({
        initial: true,
        params: {
          team_id: teamId,
          ...fetchParams
        },
        flushProjectOrder: true,
        filterStateId: filterId
      });
    }
  }, [fetchBudgetRecords, fetchParams, filterId, teamId]);

  return (
    <div className={'dashboard-widget'}>
      <BudgetWidgetTable
        filterStateId={filterId}
        phaseOrder={phaseOrder}
        data={data}
        isFetching={isFetching}
      />
    </div>
  );
};

const makeMapStateToProps = () => {
  const getBudgetRecords = makeGetBudgetRecordsByFilterStateId();
  const mapStateToProps = (state, ownProps) => ({
    budgetRecords: getBudgetRecords(state, {
      filterStateId: ownProps.filterId
    }),
    data: getBudgetRecordsData(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = { fetchBudgetRecords };

export default connect(makeMapStateToProps, mapDispatchToProps)(BudgetWidget);
