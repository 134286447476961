import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import {
  FetchPTOPoliciesParams,
  FetchPTOPoliciesSuccessResponse,
  FetchPTOPolicyParams,
  FetchPTOPolicySuccessResponse,
  FetchPTOPolicyPerMemberSuccessResponse,
  FetchPTOPolicyPerMemberParams,
  CreatePTOPolicyParams,
  CreatePTOPolicySuccessResponse,
  UpdatePTOPolicyParams,
  UpdatePTOPolicySuccessResponse,
  ArchivePTOPolicyParams,
  ArchivePTOPolicySuccessResponse,
  FetchPTOGroupMembershipsParams,
  FetchPTOGroupMembershipsSuccessResponse,
  CreatePTOGroupMembershipParams,
  CreatePTOGroupMembershipSuccessResponse,
  UpdatePTOGroupMembershipParams,
  UpdatePTOGroupMembershipSuccessResponse,
  DeletePTOGroupMembershipParams
} from 'SettingsModule/api/pto/types';

const FETCH_PTO_POLICY_PER_MEMBER = createRequestTypes(
  'FETCH_PTO_POLICY_PER_MEMBER'
);

export const fetchPTOPolicyPerMember =
  createAction<FetchPTOPolicyPerMemberParams>(
    FETCH_PTO_POLICY_PER_MEMBER.TRIGGER
  );

export const fetchPTOPolicyPerMemberActionCreatorsMap = createActionCreatorsMap<
  FetchPTOPolicyPerMemberParams,
  FetchPTOPolicyPerMemberSuccessResponse
>(FETCH_PTO_POLICY_PER_MEMBER);

const FETCH_PTO_POLICIES = createRequestTypes('FETCH_PTO_POLICIES');

export const fetchPTOPolicies = createAction<FetchPTOPoliciesParams>(
  FETCH_PTO_POLICIES.TRIGGER
);

const FETCH_PTO_POLICY = createRequestTypes('FETCH_PTO_POLICY');

export const fetchPTOPolicy = createAction<FetchPTOPolicyParams>(
  FETCH_PTO_POLICY.TRIGGER
);

export const fetchPTOPolicyActionCreatorsMap = createActionCreatorsMap<
  FetchPTOPolicyParams,
  FetchPTOPolicySuccessResponse
>(FETCH_PTO_POLICY);

export const fetchPTOPoliciesActionCreatorsMap = createActionCreatorsMap<
  FetchPTOPoliciesParams,
  FetchPTOPoliciesSuccessResponse
>(FETCH_PTO_POLICIES);

const CREATE_PTO_POLICY = createRequestTypes('CREATE_PTO_POLICY');

export const createPTOPolicy = createAction<CreatePTOPolicyInitialPayload>(
  CREATE_PTO_POLICY.TRIGGER
);

export const createPTOPolicyActionCreatorsMap = createActionCreatorsMap<
  CreatePTOPolicyParams,
  CreatePTOPolicySuccessResponse
>(CREATE_PTO_POLICY);

const UPDATE_PTO_POLICY = createRequestTypes('UPDATE_PTO_POLICY');

export const updatePTOPolicy = createAction<UpdatePTOPolicyInitialPayload>(
  UPDATE_PTO_POLICY.TRIGGER
);

export const updatePTOPolicyActionCreatorsMap = createActionCreatorsMap<
  UpdatePTOPolicyParams,
  UpdatePTOPolicySuccessResponse
>(UPDATE_PTO_POLICY);

const ARCHIVE_PTO_POLICY = createRequestTypes('ARCHIVE_PTO_POLICY');

export const archivePTOPolicy = createAction<ArchivePTOPolicyInitialPayload>(
  ARCHIVE_PTO_POLICY.TRIGGER
);

export const archivePTOPolicyActionCreatorsMap = createActionCreatorsMap<
  ArchivePTOPolicyParams,
  ArchivePTOPolicySuccessResponse
>(ARCHIVE_PTO_POLICY);

const FETCH_PTO_GROUP_MEMBERSHIPS = createRequestTypes(
  'FETCH_PTO_GROUP_MEMBERSHIPS'
);

export const fetchPTOGroupMemberships =
  createAction<FetchPTOGroupMembershipsParams>(
    FETCH_PTO_GROUP_MEMBERSHIPS.TRIGGER
  );

export const fetchPTOGroupMembershipsActionCreatorsMap =
  createActionCreatorsMap<
    FetchPTOGroupMembershipsParams,
    FetchPTOGroupMembershipsSuccessResponse
  >(FETCH_PTO_GROUP_MEMBERSHIPS);

const CREATE_PTO_GROUP_MEMBERSHIP = createRequestTypes(
  'CREATE_PTO_GROUP_MEMBERSHIP'
);

export const createPTOGroupMembership =
  createAction<CreatePTOGroupMembershipParams>(
    CREATE_PTO_GROUP_MEMBERSHIP.TRIGGER
  );

export const createPTOGroupMembershipActionCreatorsMap =
  createActionCreatorsMap<
    CreatePTOGroupMembershipParams,
    CreatePTOGroupMembershipSuccessResponse
  >(CREATE_PTO_GROUP_MEMBERSHIP);

const UPDATE_PTO_GROUP_MEMBERSHIP = createRequestTypes(
  'UPDATE_PTO_GROUP_MEMBERSHIP'
);

export const updatePTOGroupMembership = createAction(
  UPDATE_PTO_GROUP_MEMBERSHIP.TRIGGER,
  ({ meta, ...payload }: UpdatePTOGroupMembershipParams) => ({ payload, meta })
);

export const updatePTOGroupMembershipActionCreatorsMap =
  createActionCreatorsMap<
    UpdatePTOGroupMembershipParams,
    UpdatePTOGroupMembershipSuccessResponse
  >(UPDATE_PTO_GROUP_MEMBERSHIP);

const DELETE_PTO_GROUP_MEMBERSHIP = createRequestTypes(
  'DELETE_PTO_GROUP_MEMBERSHIP'
);

export const deletePTOGroupMembership = createAction(
  DELETE_PTO_GROUP_MEMBERSHIP.TRIGGER,
  ({ meta, ...payload }: DeletePTOGroupMembershipPayload) => ({ payload, meta })
);

export const deletePTOGroupMembershipActionCreatorsMap =
  createActionCreatorsMap<DeletePTOGroupMembershipPayload>(
    DELETE_PTO_GROUP_MEMBERSHIP
  );

type CreatePTOPolicyInitialPayload = CreatePTOPolicyParams & IMeta;
type UpdatePTOPolicyInitialPayload = UpdatePTOPolicyParams & IMeta;
type ArchivePTOPolicyInitialPayload = ArchivePTOPolicyParams & IMeta;
type DeletePTOGroupMembershipPayload = DeletePTOGroupMembershipParams & {
  meta: { teamMembershipId: number };
};
