import { getSelectedTeamId } from 'selectors';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { createWorkGroup } from 'actionCreators';
import Landing from './Landing';

const StyledOptions = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.isEmpty ? '3px' : '43px')};
  margin-bottom: 15px;
`;

const AddSpan = styled.span`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
  border: 1px solid ${theme.colors.colorCalendarBlue};
  border-radius: 5px;
  padding: 1px 7px;
  padding-bottom: 3px;
  margin-left: -5px;
`;

const DepartmentsTableOptions = ({ showLanding }) => {
  const dispatch = useDispatch();
  const workGroupCount = useSelector(
    (state) => state.workGroups.workGroupsOrder.length
  );
  const teamId = useSelector(getSelectedTeamId);
  const handleCreate = () => {
    dispatch(
      createWorkGroup({
        team_id: teamId,
        name: `Work Group number ${workGroupCount + 1}`
      })
    );
  };

  if (showLanding) {
    return <Landing handleCreate={handleCreate} />;
  }

  return (
    <StyledOptions isEmpty={workGroupCount === 0}>
      <AddSpan onClick={handleCreate}>+ Department</AddSpan>
    </StyledOptions>
  );
};

export default DepartmentsTableOptions;
