import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { MemberInitials } from 'views';
import MosaicLogoIcon from 'icons/MosaicLogoIcon';
import ArrowEnterSquareIcon from 'icons/ArrowEnterSquareIcon';
import { integrationsHash } from 'IntegrationsModule/constants';
import cn from 'classnames';
import { isToday as isTodayHelper } from 'appUtils/momentUtils';
import { TIMESHEET_APPROVAL_LOG_TEXT } from 'appConstants/timesheets';
import moment from 'moment';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  height: 100%;
`;
const StyledText = styled.div`
  flex-direction: column;
`;
const SubText = styled.div`
  font-weight: 400;
  font-size: 10px;
`;

const ActionTypeMap = {
  81: 'Created',
  83: 'Updated',
  84: 'Deleted',
  85: 'Updated Hours',
  86: 'Updated Description',
  87: 'Updated Billable',
  89: 'Updated Sync Status',
  90: 'Updated Override Rate',
  91: 'Updated Entry Date'
};

const SyncLogIconContainer = styled.div`
  position: relative;
  width: 3rem;
  top: 7px;
`;

const makeTimestamp = (timestamp) =>
  moment(timestamp).calendar(null, {
    sameDay: 'h:mm a',
    lastDay: 'MM/DD/YY',
    lastWeek: 'MM/DD/YY',
    sameElse: 'MM/DD/YY'
  });

/** SyncLog */

const sourceMap = {
  mosaic: 'Sent from Mosaic', // BE to IS
  target: 'Sent from Mosaic', // Agent to IS
  web: 'Sent from Mosaic' // User on FE to IS (should be consider as 'mosaic' in most cases)
};

const ActorCell = ({ row }) => {
  const {
    userActivity,
    customRowProps: { isSyncLogTable }
  } = row.original;

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const date = userActivity?.timestamp;

  const isToday = isTodayHelper(date);

  if (isSyncLogTable) {
    const { updateSource, targetService } = userActivity;

    const tooltipDate = date
      ? moment(date).format('MMM D, YYYY [at] k:mma')
      : '';
    const timestampText = date ? moment(date).format('M/D/YY, k:mma') : '';

    return (
      <StyledContainer
        data-for={date ? 'app-tooltip' : ''}
        data-effect="solid"
        data-tip={
          !updateSource && tooltipDate
            ? `Received from Integration <br /> 
      on ${tooltipDate}`
            : ''
        }
        data-html
      >
        {updateSource && (
          <SyncLogIconContainer>
            <MosaicLogoIcon width={40} height={50} viewBox={'0 0 30 43'} />
          </SyncLogIconContainer>
        )}
        <StyledText>
          <div>
            {updateSource
              ? sourceMap[updateSource]
              : integrationsHash[targetService]?.shortLabel}
          </div>
          {timestampText && (
            <SubText>
              {/* {!isToday && ' on '} */}
              {timestampText}
            </SubText>
          )}
        </StyledText>
      </StyledContainer>
    );
  }

  const timestamp = makeTimestamp(date);

  const name = userActivity?.actor?.name;
  const member = { account: userActivity?.actor || {} };
  if (!userActivity.actor) {
    return <div />;
  }

  const actionType = userActivity?.action_type;
  const actionName = actionType
    ? actionType === 88
      ? TIMESHEET_APPROVAL_LOG_TEXT[
          userActivity.display_data?.time_entry?.status
        ]
      : ActionTypeMap[actionType]
    : '';
  const formattedActionName = actionName?.length
    ? actionName[0].toUpperCase() + actionName.slice(1)
    : actionName;

  return (
    <StyledContainer>
      <div
        data-for="app-tooltip"
        data-effect="solid"
        data-html
        data-class="center"
        data-tip={name}
      >
        <MemberInitials
          key={member.account.id || userActivity.id}
          member={member}
          size="small"
          classes={cn('regular-member selected', {
            selected: false
          })}
          isOnTaskModal
          onClick={() => {}}
          styles={{ marginRight: '10px' }}
        />
      </div>
      <StyledText>
        <div>{name}</div>
        <SubText>
          {formattedActionName}
          {!isToday && ' on '}
          {timestamp}
        </SubText>
      </StyledText>
    </StyledContainer>
  );
};

export default ActorCell;
