import React from 'react';
import HeaderCell from 'ReportsModule/components/Time/TimesheetsTable/Cells/HeaderCell';
const EmptyDiv = () => <div />;

const HoursOnlyHeader = (props) =>
  props.rows[0]?.original?.customRowProps?.isPercent ? (
    <EmptyDiv />
  ) : (
    <HeaderCell {...props} />
  );

export default HoursOnlyHeader;
