import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProjectDetailMilestones from './ProjectDetailMilestones';
import ProjectDetailHeader from './ProjectDetailHeader';
import NoteTagsList from 'NoteModule/components/noteDisplay/NoteTagsList';
import ShowOnLargest from 'components/ResponsiveHelpers/ShowOnLargest';
import TaskFiltersList from '../../Home/Sidebar/components/TaskFiltersList';
import TaskListGroupsHeader from '../../Home/TaskList/TaskListGroupsHeader';
import BodyColor from 'views/utilityComponents/BodyColor';

import withPermissionsCheck from 'hocs/withPermissionsCheck';
import {
  fetchProjectById,
  fetchProjectTeam,
  setHomeOrigin,
  clearSelectedProject,
  handleProjectItemState,
  toggleSidebarSearch,
  closeSidebarProjects,
  clearFromBoardViewNavText,
  removeProjectSubscriptions,
  closeMilestoneModal,
  fetchPhasesByProjectIds,
  fetchAllProjects,
  checkHasTimeEntries,
  flushHasTimeEntries
} from 'actionCreators';

import {
  getOnTaskView,
  getSearchText,
  getSidebarSearchOpen,
  getTaskColumnOrderWithSelection,
  getMatchedRouteParams,
  getOnNotesView,
  getSelectedProject,
  getIsMilestoneModalOpen,
  getIsOnPersonalProject,
  getTaskGroups,
  getSelectedTeamId,
  getSearch
} from 'selectors';

import ReactTooltip from 'react-tooltip';
import ConfigureModulesModal from 'components/Modals/ConfigureModulesModal';
import { ProjectCommentModal, MilestoneModal } from 'views';

class ProjectDetailContainer extends React.Component {
  componentDidMount() {
    const {
      matchedParams: { projectId },
      fetchProjectById,
      fetchProjectTeam,
      fetchPhasesByProjectIds,
      fetchAllProjects,
      checkHasTimeEntries,
      teamId,
      flushHasTimeEntries
    } = this.props;

    fetchProjectById(
      projectId,
      null,
      null,
      undefined, // limit
      false, // isScheduleView
      true // isProjectDetail
    );
    fetchAllProjects({
      projectIds: [projectId]
    });
    fetchProjectTeam(projectId);

    fetchPhasesByProjectIds({ projectIds: [projectId] });

    if (teamId && this.canCheckTimeEntries({ teamId, projectId })) {
      flushHasTimeEntries();
      checkHasTimeEntries({
        team_id: teamId,
        project_ids: [projectId],
        permissions: this.getPermissions(this.props)
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const {
      matchedParams: { projectId },
      fetchProjectById,
      fetchProjectTeam,
      removeProjectSubscriptions,
      fetchAllProjects,
      teamId,
      checkHasTimeEntries
    } = this.props;

    if (nextProps.matchedParams.projectId !== projectId) {
      removeProjectSubscriptions({
        ids: [projectId]
      });
      fetchProjectById(
        nextProps.matchedParams.projectId,
        null,
        null,
        undefined, // limit
        false,
        true
      );
      fetchAllProjects({
        projectIds: [nextProps.matchedParams.projectId]
      });
      fetchProjectTeam(nextProps.matchedParams.projectId);
    }
    const shouldCheckTimeEntries =
      (projectId && !teamId && nextProps.teamId) ||
      nextProps.matchedParams.projectId !== projectId;
    if (
      shouldCheckTimeEntries &&
      this.canCheckTimeEntries({
        teamId: nextProps.teamId,
        projectId: nextProps.matchedParams.projectId
      })
    ) {
      flushHasTimeEntries();
      checkHasTimeEntries({
        team_id: nextProps.teamId,
        project_ids: [nextProps.matchedParams.projectId],
        permissions: this.getPermissions(nextProps)
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedProject,
      handleProjectItemState,
      clearFromBoardViewNavText,
      fetchPhasesByProjectIds,
      matchedParams: { projectId }
    } = this.props;
    const prevProjectId = prevProps.matchedParams.projectId;
    const isProjectLoaded = !prevProps.selectedProject && selectedProject;

    // ensures redux is aware project detail is open, even after hard refresh
    if (prevProjectId && projectId && prevProjectId !== projectId) {
      clearFromBoardViewNavText();
      fetchPhasesByProjectIds({ projectIds: [projectId] });
    }
    if (isProjectLoaded) {
      handleProjectItemState(
        selectedProject.id,
        ['detailVisible', 'expanded', 'fixed'],
        [true, true, true]
      );
    }
  }

  getPermissions = (props) => ({
    teamId: props.teamId,
    projectId: props.matchedParams.projectId,
    mine: false
  });

  canCheckTimeEntries = ({ teamId, projectId }) => {
    const { checkPermission, checkHasTimeEntries } = this.props;
    if (!teamId || !projectId) {
      return false;
    }

    const hasPermission = checkPermission(checkHasTimeEntries, {
      team_id: teamId,
      project_ids: [projectId],
      permissions: { teamId, projectId, mine: false }
    });
    return hasPermission;
  };

  componentWillUnmount() {
    const {
      setHomeOrigin,
      clearFromBoardViewNavText,
      clearSelectedProject,
      handleProjectItemState,
      matchedParams: { projectId },
      removeProjectSubscriptions
    } = this.props;
    setHomeOrigin(false);
    clearFromBoardViewNavText();
    clearSelectedProject();
    removeProjectSubscriptions({
      ids: [projectId]
    });
    handleProjectItemState(
      null,
      ['fixed', 'contracting', 'expanded'],
      [false, false, false]
    );
  }

  handleBackgroundClick = (e) => {
    const {
      toggleSidebarSearch,
      closeSidebarProjects,
      sidebarSearchOpen,
      searchText
    } = this.props;
    if (sidebarSearchOpen) {
      e.preventDefault();
      searchText === '' && toggleSidebarSearch();
      closeSidebarProjects(null);
    }
  };

  onlyDefaultTaskGroup = () =>
    this.props.taskGroups.length === 1 && this.props.taskGroups[0].is_default;

  render() {
    const {
      selectedProject,
      isOnTaskView,
      activeTaskColumns,
      isOnNotesView,
      isMilestoneModalOpen,
      closeMilestoneModal,
      isOnPersonalProject,
      taskGroups,
      search
    } = this.props;

    const isPersonal = selectedProject && selectedProject.is_personal;

    const isHidingSidePanels = ['schedule', 'budget'].includes(
      this.props.matchedParams.projectViewType
    );

    return (
      <div className="project-list-wrapper">
        <div
          className={cn('project-detail-container expanded', {
            personal: isPersonal,
            sidebarProjectsOpen: search.sidebarProjectsOpen,
            schedule: this.props.matchedParams.projectViewType === 'schedule'
          })}
        >
          <BodyColor isWhite={isPersonal} />
          <div className="nav-and-feed" onClick={this.handleBackgroundClick}>
            <ProjectDetailHeader
              project={selectedProject}
              isPersonal={isPersonal}
            />
            {selectedProject && (
              <div
                className={cn('project-detail-content', {
                  [this.props.matchedParams.projectViewType]: true
                })}
              >
                {this.props.matchedParams.projectViewType === 'tasks' &&
                  !!taskGroups.length && (
                    <TaskListGroupsHeader
                      isOnPersonalProject={isOnPersonalProject}
                    />
                  )}
                {!isHidingSidePanels && (
                  <ShowOnLargest activeTaskColumns={activeTaskColumns}>
                    {!search.sidebarProjectsOpen && (
                      <div className="left-panel">
                        {isPersonal ? (
                          <div className="left-panel-spacing" />
                        ) : (
                          <ProjectDetailMilestones project={selectedProject} />
                        )}
                        <div
                          className={cn('status-tags-container d-print-none', {
                            personal: isPersonal
                          })}
                        >
                          {isOnTaskView && <TaskFiltersList />}
                          {isOnNotesView && <NoteTagsList />}
                        </div>
                      </div>
                    )}
                  </ShowOnLargest>
                )}
                {this.props.children}

                {!isHidingSidePanels && (
                  <ShowOnLargest activeTaskColumns={activeTaskColumns}>
                    <div className="right-panel" />
                  </ShowOnLargest>
                )}

                <ConfigureModulesModal moduleType="projectDetailView" />
                <ProjectCommentModal />
                <MilestoneModal
                  isOpen={isMilestoneModalOpen}
                  toggle={closeMilestoneModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProjectDetailContainer.propTypes = {
  fetchProjectById: PropTypes.func.isRequired
  // eslint-disable-next-line react/forbid-prop-types
};

const mapStateToProps = (state, ownProps) => ({
  selectedProject: getSelectedProject(state),
  isOnNotesView: getOnNotesView(state),
  isOnTaskView: getOnTaskView(state),
  isOnPersonalProject: getIsOnPersonalProject(state),
  sidebarSearchOpen: getSidebarSearchOpen(state),
  searchText: getSearchText(state),
  search: getSearch(state),
  activeTaskColumns: getTaskColumnOrderWithSelection(state),
  matchedParams: getMatchedRouteParams(state),
  isMilestoneModalOpen: getIsMilestoneModalOpen(state),
  taskGroups: getTaskGroups(state, ownProps),
  teamId: getSelectedTeamId(state),
  flushHasTimeEntries
});

const mapDispatchToProps = {
  fetchProjectById,
  fetchAllProjects,
  fetchProjectTeam,
  setHomeOrigin,
  clearSelectedProject,
  handleProjectItemState,
  toggleSidebarSearch,
  closeSidebarProjects,
  clearFromBoardViewNavText,
  removeProjectSubscriptions,
  closeMilestoneModal,
  fetchPhasesByProjectIds,
  checkHasTimeEntries
};

export default withRouter(
  withPermissionsCheck(
    connect(mapStateToProps, mapDispatchToProps)(ProjectDetailContainer)
  )
);
