import { reducerMap } from '../reducers';
import utilizationSaga from '../sagas';

export function getUtilizationModule() {
  return {
    id: 'utilization',
    reducerMap,
    sagas: [utilizationSaga]
    // Actions to fire when this module is added/removed
    // initialActions: []
    // finalActions: []
  };
}
