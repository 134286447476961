import React from 'react';
import HomePlannerWaypoint from './HomePlannerWaypoint';
// import { PlannerColumn, WorkloadPlannerGroupRenderer } from '..';
// import { keyifyDate } from 'appUtils/plannerUtils';
import PlannerColumnList from './PlannerColumnList';
const stickyStyle = { position: 'sticky', top: '0px', zIndex: 2 };
const noStyle = {};
class PlannerRow extends React.PureComponent {
  render() {
    const {
      dateRange,
      localDateHeader,
      fetchPlanners,
      index,
      isAlreadyFetching,
      ...props
    } = this.props;
    return (
      <div className="planner-row" style={index === -1 ? stickyStyle : noStyle}>
        {index === -1 && (
          <HomePlannerWaypoint
            dateHeader={localDateHeader}
            fetchPlanners={fetchPlanners}
            isAlreadyFetching={isAlreadyFetching}
            before
          />
        )}
        <PlannerColumnList rowIndex={index} dateRange={dateRange} {...props} />
        {index === -1 && (
          <HomePlannerWaypoint
            dateHeader={localDateHeader}
            fetchPlanners={fetchPlanners}
            isAlreadyFetching={isAlreadyFetching}
            after
          />
        )}
      </div>
    );
  }
}

export default PlannerRow;
