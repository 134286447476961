import theme from 'theme';
import styled from 'styled-components';
import ClockIcon from 'icons/ClockIcon';
import CalendarIcon from 'icons/CalendarIcon';
import {
  InfoColumn,
  TooltipArea,
  Row,
  IconContainer,
  PhaseName
} from 'views/memberDisplay/calendar/PhaseScheduleTimelineItem';
import { formatPhaseScheduleTooltip } from 'views/memberDisplay/calendar/PhaseScheduleTooltip';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import { ProjectsThenPhasesDropdownSelectedItem } from 'components/ProjectsThenPhasesDropdown/type';
import { GoogleCalendarEvent } from 'EventsModule/types';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { Project } from 'ProjectsModule/models/project';
interface EventData extends GoogleCalendarEvent {
  eventTime: string;
  phase?: Phase;
  project?: Project;
  start_datetime: string;
  end_datetime: string;
}

interface Event {
  eventId: string;
  date: string;
  type: string;
  data: EventData;
}

type IntegrationEventTimelineItemProps = {
  event: Event;
  accountId: number | string;
  associateMosaicProjectAndPhaseWithEvent: (
    item: ProjectsThenPhasesDropdownSelectedItem,
    event: Event
  ) => void;
};

export const IntegrationEventTimelineItem = ({
  event,
  accountId,
  associateMosaicProjectAndPhaseWithEvent
}: IntegrationEventTimelineItemProps) => {
  const { phase, project, eventTime, title, start_datetime, end_datetime } =
    event.data;

  const onSelectProjectPhaseActivity = (
    item: ProjectsThenPhasesDropdownSelectedItem
  ) => {
    associateMosaicProjectAndPhaseWithEvent(item, event);
  };

  return (
    <RootContainer>
      <InfoContainer>
        <TooltipArea
          data-for="phase-schedule-tooltip"
          data-tip={formatPhaseScheduleTooltip({
            phase,
            project,
            customStartDate: start_datetime,
            customEndDate: end_datetime
          })}
          data-effect="solid"
        >
          <Row>
            {title && (
              <>
                <IconContainer>
                  <CalendarIcon
                    className="calendar-icon"
                    currentColor={theme.colors.colorMediumGray9}
                    width="13"
                    height="13"
                  />
                </IconContainer>
                <Title>{title}</Title>
              </>
            )}
          </Row>
          <Row>
            <ProjectsThenPhasesSelector
              projectId={project?.id}
              accountId={accountId}
              phaseId={phase?.id}
              pickerLocation={event.eventId}
              handleSelect={onSelectProjectPhaseActivity}
              renderSelector
              hideActivity
              selectionDisabled={false}
            />
          </Row>
          <Row>
            {eventTime && (
              <>
                <IconContainer>
                  <ClockIcon
                    className="clock-icon"
                    fill={theme.colors.colorCalendarGray}
                    stroke={theme.colors.colorCalendarGray}
                    width="11"
                    height="11"
                  />
                </IconContainer>
                <Text>{eventTime}</Text>
              </>
            )}
          </Row>
          <Row>
            {/* For when location is available */}
            {/* {location && (
              <>
                <IconContainer className="location">
                  {' '}
                  <MilestoneIcon width="12" height="12" />
                </IconContainer>
                <Text>{location}</Text>
              </>
            )} */}
          </Row>
        </TooltipArea>
      </InfoContainer>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  .project-container {
    max-width: 480px;
  }

  .project-title,
  .project-description,
  .phase-name {
    font-size: 13px;
  }

  .project-selector-container {
    padding: 0 !important;
    padding-top: 5px !important;

    .down-arrow {
      visibility: hidden;

      svg {
        width: 8px;
        height: 8px;
      }
    }
    &:hover {
      .down-arrow {
        visibility: visible;
        path {
          stroke-width: 1px;
          stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
        }
      }
    }
  }
  .project-selector {
    font-size: 12px !important;
    padding: 0 !important;
  }
`;

const InfoContainer = styled(InfoColumn)`
  flex: 1;
`;

const Text = styled(PhaseName)`
  margin-left: 5px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  padding-left: 4px;
`;
