import { useAppSelector } from 'reduxInfra/hooks';
import { useCallback, useState } from 'react';
import {
  MemberListItem,
  MemberListItemsBuilder,
  ArchivedSectionHeaderListItem
} from '../types';
import { membersTimesheetSettingsTableHelpers } from './layout';
import { getTeamMembersHashByTeamMembership } from 'TeamsModule/selectors';
import { teamUtils } from 'TeamsModule/utils';

const { ROW_TYPES } = membersTimesheetSettingsTableHelpers;
type UseMemberListItemsBuilderParams = Pick<
  MemberListItem,
  'onRequestEditMemberMetadata'
>;

export const useMemberListItemsBuilder = ({
  onRequestEditMemberMetadata
}: UseMemberListItemsBuilderParams) => {
  const [isArchivedMembersVisible, setIsArchivedMembersVisible] =
    useState(false);

  const teamMembersHash = useAppSelector(getTeamMembersHashByTeamMembership);

  const memberListRowBuilder: MemberListItemsBuilder = useCallback(
    ({ order }) => {
      const archivedMembershipIds: number[] = [];
      const nonArchivedMemberhipIds: number[] = [];

      const memberPermissionListItemsBuilder = (
        teamMembershipIds: number[]
      ): MemberListItem[] => {
        return teamMembershipIds.flatMap((teamMembershipId) => {
          const teamMember = teamMembersHash[teamMembershipId];
          if (!teamMember) return [];

          const item: MemberListItem = {
            teamMember,
            onRequestEditMemberMetadata,
            rowType: ROW_TYPES.memberRow,
            rowHeight: 60
          };

          return [item];
        });
      };

      const archivedSectionHeaderListItemBuilder =
        (): ArchivedSectionHeaderListItem => {
          const value: ArchivedSectionHeaderListItem = {
            rowType: ROW_TYPES.archivedSectionHeaderRow,
            rowHeight: 35,
            archivedCount: archivedMembershipIds.length,
            isShowingArchivedPolicies: isArchivedMembersVisible,
            onClick: () => {
              setIsArchivedMembersVisible(!isArchivedMembersVisible);
            }
          };
          return value;
        };

      (order as number[]).forEach((teamMembershipId) => {
        const teamMember = teamMembersHash[teamMembershipId];

        if (teamMember) {
          if (teamUtils.getIsArchived(teamMember)) {
            archivedMembershipIds.push(teamMember.id);
          } else {
            nonArchivedMemberhipIds.push(teamMember.id);
          }
        }
      });

      const nonArchivedMemberPermissionListItems =
        memberPermissionListItemsBuilder(nonArchivedMemberhipIds);
      const archivedMemberPermissionListItems =
        memberPermissionListItemsBuilder(archivedMembershipIds);
      const archivedSectionHeaderListItem =
        archivedSectionHeaderListItemBuilder();

      let items: Array<MemberListItem | ArchivedSectionHeaderListItem> = [];

      if (!archivedMembershipIds.length) {
        items = nonArchivedMemberPermissionListItems;
      } else {
        items.push(...nonArchivedMemberPermissionListItems);

        if (!isArchivedMembersVisible) {
          items.push(archivedSectionHeaderListItem);
        } else {
          items.push(...archivedMemberPermissionListItems);
          items.push(archivedSectionHeaderListItem);
        }
      }

      return items;
    },
    [isArchivedMembersVisible, teamMembersHash, onRequestEditMemberMetadata]
  );

  return memberListRowBuilder;
};
