import { useMemo, useState, useRef } from 'react';
import { ActivityPhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { useAppDispatch } from 'reduxInfra/hooks';
import { getPhaseDatesData } from '../../utils';
import moment, { Moment } from 'moment';
import {
  DATE_DISPLAY_TINY,
  MOMENT_ISO_DATE,
  DATE_FNS_SHORT_USA_DATE
} from 'appConstants/date';
import { updateActivityPhaseMembership } from 'ActivityPhaseModule/actionCreators/activityPhaseMembership';
import { StyledColumnLabel } from 'SuggestionModule/components/FindPeople/Table/styles';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { getFormattedDateWithDateFns } from 'appUtils/dateUtils';

const ActivityMembershipDateCell = ({ row }: ActivityPhaseGroupRowProps) => {
  const dispatch = useAppDispatch();
  const calendarTarget = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { customRowProps, activityPhase, activityPhaseMembership } =
    row.original;

  const {
    endDateToUse,
    startDateToUse,
    phaseEntityHasDates,
    activityPhaseMembershipHasDates
  } = getPhaseDatesData({
    phaseEntity: activityPhase,
    activityPhaseMembership
  });

  const formattedStartDate = startDateToUse
    ? getFormattedDateWithDateFns(startDateToUse, DATE_FNS_SHORT_USA_DATE)
    : '';

  const formattedEndDate = endDateToUse
    ? getFormattedDateWithDateFns(endDateToUse, DATE_FNS_SHORT_USA_DATE)
    : '';

  const handleUpdateActivityPhaseMembershipDateRange = (
    startDate: Nullable<string>,
    endDate: Nullable<string>
  ) => {
    if (!activityPhaseMembership?.id) {
      return;
    }
    dispatch(
      updateActivityPhaseMembership({
        id: activityPhaseMembership.id,
        start_date: startDate,
        end_date: endDate
      })
    );
  };

  const handleRangeSubmit = ({
    startDate,
    endDate
  }: {
    startDate?: Moment;
    endDate?: Moment;
  }) => {
    if (!startDate?.isValid?.() && !endDate?.isValid?.()) {
      return;
    }
    setCalendarOpen(false);
    handleUpdateActivityPhaseMembershipDateRange(
      moment(startDate).format(MOMENT_ISO_DATE),
      moment(endDate).format(MOMENT_ISO_DATE)
    );
  };

  const handleClearRange = () => {
    handleUpdateActivityPhaseMembershipDateRange(null, null);
  };

  return (
    <StyledColumnLabel className="activity-phase date">
      <div
        ref={calendarTarget}
        onClick={() => setCalendarOpen(true)}
        className="date-range"
      >
        {(phaseEntityHasDates || activityPhaseMembershipHasDates) &&
        formattedStartDate &&
        formattedEndDate
          ? `${formattedStartDate} \xa0–\xa0 ${formattedEndDate}`
          : `Start \xa0–\xa0 End`}
      </div>
      {calendarOpen && (
        <DateRangeCalendar
          startOpen
          target={calendarTarget as any} // Error: target apparently has to be null | undefined..
          onClose={() => setCalendarOpen(false)}
          onSubmit={handleRangeSubmit as any} // Error: onSubmit takes empty argument
          itemStartDate={startDateToUse}
          itemEndDate={endDateToUse}
          showClear={!!(startDateToUse && endDateToUse)}
          onClear={handleClearRange}
          customInput={() => null}
          /* -------------------------------Added for TS------------------------------------------- */
          onSetRange={undefined}
          showSetRange={undefined}
          showInputs={undefined}
          labels={undefined}
          customLabel={undefined}
          workplanAllDay={undefined}
          workplanUseWeeklyPlanning={undefined}
          workplanIncludeWeekends={undefined}
          workplanTentative={undefined}
          workplanStartTime={undefined}
          workplanEndTime={undefined}
          workplanDailyHours={undefined}
          isWorkplanCalendar={undefined}
          isPhaseCalendar={undefined}
          disableDependency={undefined}
          phaseStartDate={undefined}
          phaseEndDate={undefined}
          phaseId={undefined}
          isOOO={undefined}
          accountId={undefined}
          phases={undefined}
          isTaskCalendar={undefined}
          item={undefined}
          itemType={undefined}
          isScopeCalendar={undefined}
          isMilestoneCalendar={undefined}
          calendarClassNames={undefined}
        />
      )}
    </StyledColumnLabel>
  );
};

export default ActivityMembershipDateCell;
