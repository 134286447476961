import styled from 'styled-components';
import theme from 'theme';
import HelpQuestionIcon from 'icons/HelpQuestionIcon';
import DateNavLeftArrowIcon from 'icons/DateNavLeftArrowIcon';
import DateNavRightArrowIcon from 'icons/DateNavRightArrowIcon';
import moment from 'moment';
import { getPlannerDateHeader } from 'selectors';
import { setPlannerDateHeader, fetchPlanners } from 'actionCreators';
import { useSelector, useDispatch } from 'react-redux';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import {
  WEEK_FETCH_OFFSET,
  WEEK_FETCH_AHEAD_OFFSET,
  TODAY
} from 'appConstants/planners';
import {
  getAuthToken,
  getCurrentAccount
} from 'AuthenticationModule/selectors';

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: ${({ isToday }) =>
    isToday ? `#EB8429` : theme.colors.colorMediumGray9};
  line-height: 24px;
  min-width: fit-content;
  cursor: pointer;
`;

const DateNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  min-width: fit-content;
  width: 260px;
`;

const ArrowIconsContainer = styled.div`
  display: flex;
`;

const ArrowContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.colorLightGray19};
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const StyledQuestionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  margin-right: 5px;
  margin-top: 1px;
  height: 16px;
  width: 16px;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorLightGray6};
`;

const PlannerDateNav = ({ accountId, customPlannerDate, setDate }) => {
  const plannerDate = useSelector(getPlannerDateHeader);
  const token = useSelector(getAuthToken);
  const currentAccount = useSelector(getCurrentAccount);
  const dispatch = useDispatch();
  const accountIdForFetch = accountId ?? currentAccount?.id;
  // use custom date passed in if exists
  const date = customPlannerDate ?? plannerDate;

  const prevDay = () => {
    const dateClone = date.clone();
    dateClone.subtract(1, 'days');
    if (customPlannerDate) setDate(dateClone);
    else
      dispatch(
        setPlannerDateHeader({
          dateHeader: dateClone
        })
      );

    if (!customPlannerDate && accountIdForFetch) {
      dispatch(
        fetchPlanners(token, {
          baseDate: dateClone,
          rightOffset: WEEK_FETCH_AHEAD_OFFSET,
          leftOffset: WEEK_FETCH_OFFSET,
          accountIds: [accountIdForFetch]
        })
      );
    }
  };

  const nextDay = () => {
    const dateClone = date.clone();
    dateClone.add(1, 'days');
    if (customPlannerDate) setDate(dateClone);
    else
      dispatch(
        setPlannerDateHeader({
          dateHeader: dateClone
        })
      );

    if (!customPlannerDate && accountIdForFetch) {
      dispatch(
        fetchPlanners(token, {
          baseDate: dateClone,
          rightOffset: WEEK_FETCH_AHEAD_OFFSET,
          leftOffset: WEEK_FETCH_OFFSET,
          accountIds: [accountIdForFetch]
        })
      );
    }
  };

  const onCalendarSubmit = ({ startDate }) => {
    if (startDate?.isValid?.()) {
      const momentStartDate = moment(startDate);
      if (customPlannerDate) setDate(momentStartDate);
      else
        dispatch(
          setPlannerDateHeader({
            dateHeader: momentStartDate
          })
        );

      if (!customPlannerDate && accountIdForFetch) {
        dispatch(
          fetchPlanners(token, {
            baseDate: momentStartDate,
            rightOffset: WEEK_FETCH_AHEAD_OFFSET,
            leftOffset: WEEK_FETCH_OFFSET,
            accountIds: [accountIdForFetch]
          })
        );
      }
    }
  };

  const isToday = moment(date).isSame(moment(), 'day');

  return (
    <DateNavContainer>
      <DateRangeCalendar
        itemStartDate={date.format('MM/DD/YYYY')}
        onSubmit={onCalendarSubmit}
        isSingleDay
        customInput={(startDate, endDate, handleOpen) => (
          <StyledTitle onClick={handleOpen.bind(this)} isToday={isToday}>
            {date.format('ddd MMM D, YYYY')}
          </StyledTitle>
        )}
      />
      {/* <StyledQuestionIconContainer    <----ADD THIS BACK WHEN THE TEXT IS UP
        data-for={'app-tooltip'}
        data-tip={
          'Check-ins are high-level updates to the team of what you are working on today.'
        }
        data-html
      >
        <HelpQuestionIcon
          height={8}
          width={8}
          stroke={theme.colors.colorMediumGray5}
          fill={theme.colors.colorMediumGray5}
        />
      </StyledQuestionIconContainer> */}
      <ArrowIconsContainer>
        <ArrowContainer onClick={prevDay} data-testid="date-nav-prev">
          <DateNavLeftArrowIcon />
        </ArrowContainer>
        <ArrowContainer onClick={nextDay} data-testid="date-nav-next">
          <DateNavRightArrowIcon />
        </ArrowContainer>
      </ArrowIconsContainer>
    </DateNavContainer>
  );
};

export default PlannerDateNav;
