import cn from 'classnames';
import { Modal } from 'reactstrap';

const defaultHeader = (component, _plural, isArchiveAction) => (
  <>
    {isArchiveAction ? 'Archive' : 'Delete'}{' '}
    {component.charAt(0).toUpperCase() + component.slice(1)}
  </>
);

const defaultBody = (component, plural, isArchiveAction) => (
  <>
    Are you sure you want to{' '}
    <b>permanently {isArchiveAction ? 'archive' : 'delete'}</b>
    {plural ? ' these' : ' this'} {component}? You can’t restore
    {plural ? '' : isArchiveAction ? ' an ' : ' a '}
    {isArchiveAction ? 'archived' : 'deleted'} {component}.
  </>
);
const defaultConfirm = ({ deleteOnClick }) => (
  <button onClick={deleteOnClick}>Yes</button>
);
const DeleteModal = ({
  isOpen,
  toggle,
  deleteOnClick,
  component,
  renderHeader = defaultHeader,
  renderBody = defaultBody,
  renderDelete = defaultConfirm,
  plural = false,
  renderConfirmButtons = undefined,
  showCloseIcon = true,
  isArchiveAction = false
}) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle} className="delete-modal">
      <div className="delete-modal-title">
        <h3>{renderHeader(component, plural, isArchiveAction)}</h3>
        {showCloseIcon && (
          <button type="button" aria-label="Close" onClick={toggle}>
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <div className="delete-modal-body delete-modal-word-wrap">
        {renderBody(component, plural, isArchiveAction)}
      </div>
      <div className="delete-modal-footer">
        {renderConfirmButtons ? (
          renderConfirmButtons()
        ) : (
          <>
            <div className="delete-modal-footer-cancel">
              <button onClick={toggle}>Cancel</button>
            </div>
            <div
              className={cn('delete-modal-footer-yes', {
                'is-archive': isArchiveAction
              })}
            >
              {renderDelete({ deleteOnClick })}
            </div>
          </>
        )}
      </div>
    </Modal>
  </div>
);

export default DeleteModal;
