import React from 'react';
import { ScheduleCalendarProvider } from './ScheduleCalendarProvider';
import { ScheduleCalendarByViewType } from './ScheduleCalendarByViewType';

/**
 *
 * @param {ScheduleCalendarProps} props
 */
export const ScheduleCalendar = ({
  calendarRef,
  currentDate = new Date(),
  onSetRange,
  initialViewType = 'year',
  data = [],
  backgroundEvents = [],
  renderDateItem,
  renderExtraItem,
  renderExtraFooter,
  renderBackground
}) => {
  return (
    <ScheduleCalendarProvider
      initialValues={{
        calendarRef,
        // FIXME: rename to initialDate
        initialDate: currentDate,
        initialViewType,
        data,
        onSetRange,
        backgroundEvents,
        renderDateItem,
        renderExtraItem,
        renderExtraFooter,
        renderBackground
      }}
    >
      <ScheduleCalendarByViewType></ScheduleCalendarByViewType>
    </ScheduleCalendarProvider>
  );
};
