import theme from 'theme';
import styled from 'styled-components';
import HelpIcon from 'icons/HelpIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

import { useEffect } from 'react';

const HelpIconContainer = styled.span`
  position: relative;
  bottom: 11px;
  right: 2px;

  svg {
    path:nth-child(2) {
      stroke: transparent;
      fill: ${theme.colors.colorLightGray12};
    }

    path:nth-child(3) {
      fill: ${theme.colors.colorPureBlack};
    }
  }
`;

const FloatHelpIcon = ({
  className,
  dataTip,
  height = 10,
  width = 10
}: {
  className?: string;
  dataTip?: string;
  height?: number;
  width?: number;
}) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <HelpIconContainer
      data-for="app-tooltip"
      className={className}
      data-class="center"
      data-html
      data-effect="solid"
      data-tip={dataTip}
      data-tip-disable={!dataTip}
    >
      <HelpIcon height={height} width={width} />
    </HelpIconContainer>
  );
};

export default FloatHelpIcon;
