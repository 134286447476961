import styled from 'styled-components';
import { SimpleConfirmModal } from 'components/Modals';
import { useCallback } from 'react';
import { useToggle, useMount } from 'react-use';
import { getMyTeamMembership } from 'TeamsModule/selectors';
import { useAppSelector } from 'reduxInfra/hooks';
import { getIsConnectingToDefaultRealm } from 'AuthenticationModule/selectors';
const modalPreferenceKey = 'do-not-show-team-builder-sandbox-prompt-again';

const useTeamBuilderSandboxPromptModal = () => {
  const [open, toggle] = useToggle(false);
  const teamMembership = useAppSelector(getMyTeamMembership);
  const isConnectingToDefaultRealm = useAppSelector(
    getIsConnectingToDefaultRealm
  );

  const isSandboxAccountOnDemo =
    process.env.UI_ENV === 'demo' && !isConnectingToDefaultRealm; // Connecting to default realm === not a sandbox account

  const doNotShowModalAgain =
    teamMembership?.modal_preferences?.[modalPreferenceKey];

  // Spec: If user is in a sandbox account on "demo" env, prompt this modal
  // If user selects "Do not show this again" and confirms, this modal won't show again
  useMount(() => {
    if (teamMembership && isSandboxAccountOnDemo && !doNotShowModalAgain) {
      toggle();
    }
  });

  const Modal = useCallback(() => {
    return open ? (
      <SimpleConfirmModal
        isOpen
        confirmOnly
        confirmLabel="Okay"
        preventClickOut
        hasDoNotShowAgain
        toggle={toggle}
        body={
          <ModalBody>
            Suggestions on the{' '}
            <span style={{ fontWeight: 600 }}>Team Builder</span> are for
            presentation only on demo accounts.
          </ModalBody>
        }
        doNotShowAgainSettingName={modalPreferenceKey}
      />
    ) : (
      <></>
    );
  }, [open, toggle]);

  return {
    Modal
  };
};

export default useTeamBuilderSandboxPromptModal;

const ModalBody = styled.div`
  font-size: 19px;
`;
