import styled from 'styled-components';
import { Color, ColorScaleOverflow } from '../types';
import cn from 'classnames';
import theme from 'theme';

interface ColorScalePreviewIntervalProps {
  /**
   * The color of the square.
   */
  color: Color;

  /**
   * The label to be positioned above the preview.
   */
  label?: string;

  /**
   * The level of the overflow indicator.
   */
  overflow?: ColorScaleOverflow;

  /**
   * The size of the preview in pixels.
   */
  size: number;

  /**
   * The tooltip for the preview.
   */
  tooltipContent?: string;
}

/**
 * A preview of a color scale interval.
 */
export const ColorScalePreviewInterval = ({
  color,
  label,
  overflow,
  size,
  tooltipContent
}: ColorScalePreviewIntervalProps) => {
  const preview = (
    <Square
      className={cn(overflow, 'color-scale-preview-interval')}
      data-effect="solid"
      data-for="app-tooltip"
      data-html
      data-tip={tooltipContent}
      $color={color}
      $size={size}
    />
  );

  return label !== undefined ? (
    <Container $size={size}>
      <Label>{label}</Label>
      {preview}
    </Container>
  ) : (
    preview
  );
};

const Label = styled.div`
  color: ${theme.colors.colorMediumGray9};
  font-size: 11px;
  line-height: 19px;
  white-space: nowrap;
`;

interface SquareProps {
  $color: string;
  $size: number;
}

const Square = styled.div.attrs<SquareProps>(({ $color, $size }) => ({
  style: { '--color': $color, '--size': `${$size}px` }
}))<SquareProps>`
  background: var(--color);
  display: inline-block;
  flex: none;
  height: var(--size);
  width: var(--size);

  &.some {
    background: linear-gradient(to top left, #0000 50%, #cb7986 50.1%) top
      left/31.25% 31.25% no-repeat var(--color);
  }

  &.ample {
    background: linear-gradient(to top left, #0000 50%, #cb7986 50.1%) top
      left/43.7% 43.7% no-repeat var(--color);
  }
`;

interface ContainerProps {
  $size: number;
}

const Container = styled.div.attrs<ContainerProps>(({ $size }) => ({
  style: { '--size': `${$size}px` }
}))<ContainerProps>`
  align-items: center;
  display: inline-flex;
  flex-direction: column;

  // This forces the container to match the size of the colored preview,
  // allowing the label to overflow if necessary. This was done to ensure that
  // adjacent colored previews were equally spaced.
  width: var(--size);
`;
