import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { connect } from 'react-redux';
import {
  getTeamMembershipsByAccountId,
  getSelectedTeamId,
  getSortedTeamMembers,
  makeGetEditOrViewIdsHash,
  makeGetEditIdsHash,
  makeGetViewIdsHash,
  getMe,
  getBulkAddIsOpen,
  getBulkAddIsPopulated
} from 'selectors';
import {
  updateAccess,
  closeAccessModal,
  openAddMembersForm,
  closeBulkAddMembersForm
} from 'actionCreators';
import {
  HeaderContainer,
  Subtext,
  StyledModalHeader,
  StyledModalBody,
  DoneButton,
  VisibilityMemberRow,
  StyledDropdownToggle
} from 'components/Permissions/Access/styles';
import { ToggleContainer, ToggleOption } from 'components/styles';
import { Modal } from 'reactstrap';
import AddMembersContainer from 'views/boardDisplay/AddMembersContainer';
import { MODAL_TYPE } from 'appConstants/addMemberForm';

const tabViews = {
  BUDGET_VIEWERS: 'Budget Viewers',
  PROJECT_MEMBERS: 'Project Members'
};

const StyledModalFooter = styled.div`
  font-size: 11px;
  color: ${theme.colors.colorMediumGray5};
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid ${theme.colors.colorMediumGray11};
`;

const StyledModal = styled(Modal)`
  &.budget-access-modal {
    .modal-content {
      width: 80%;
    }
    .add-members-form.suggestions {
      right: 1rem;
    }
  }
  &.budget-access-modal.modal-dialog {
    max-width: none;
    width: max-content;
    .add-members-form {
      .column-header-row,
      .invite-member-list,
      .add-new-member-input {
        padding: 0 70px;
      }
      .column-header-row {
        &.show-cost-rate {
          grid-template-columns: 193px 80px 121px;
        }
        .header-label {
          font-size: 13px;
          color: ${theme.colors.colorCalendarGray};
          font-weight: 600;
          border-bottom: 1px solid ${theme.colors.colorLightGray15};
          padding: 7px;
        }
      }
      .invite-member-list {
        width: 820px;
        display: inline-grid;
        grid-auto-rows: 57px;
        max-height: none !important;
        height: 493px !important;
        .member-item {
          height: 57px;
          margin: 0;
          & > div:not(:first-child) {
            &:not(:last-child):not(.rate) {
              border-right: 1px solid #cdcdcd;
            }
            border-bottom: 1px solid #cdcdcd;
            .last-row & {
              border-bottom: none;
            }
          }
        }
      }
      .column-header-row,
      .invite-member-list .member-item {
        display: grid !important;
        grid-template-columns: 225px 197px 75px 183px;
      }
    }
  }
  ${StyledModalHeader} {
    padding: 46px 45px 23px 70px;
    color: black;
    font-size: 28px;
  }
  ${StyledModalBody} {
    padding: 0 50px 20px;
  }
  ${VisibilityMemberRow} {
    padding-left: 0;
    padding-right: 0;
  }
  ${StyledDropdownToggle} {
    padding-right: 10px;
  }
`;

const StyledToggleOption = styled(ToggleOption)`
  font-size: 15px;
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  border: ${({ isActive }) =>
    isActive ? theme.colors.colorPaleGray1 + ' 1px solid' : ''} !important;
  color: ${theme.colors.colorMediumGray9};
  border-radius: 8px;
  padding: 7px 4px;
`;

const StyledToggleContainer = styled(ToggleContainer)`
  width: auto;
  margin: 0 50px 17px;
  border-radius: 8px;
  border: 2px solid ${theme.colors.colorTranslucentGray3};
`;

class BudgetMembersModal extends React.Component {
  state = {
    currentView: tabViews.PROJECT_MEMBERS
  };

  componentDidMount() {
    this.handleOpenAddMember();
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.project?.id;
    const curId = this.props.project?.id;

    if (prevId !== curId) {
      this.handleOpenAddMember();
    }
  }

  onToggleView = (view) => {
    if (view !== this.state.currentView) {
      this.setState({ currentView: view });
    }
  };

  handleOpenAddMember = () => {
    const { project, openAddMembersForm } = this.props;
    openAddMembersForm(MODAL_TYPE.PROJECT, project);
  };

  handleCloseModal = () => {
    const { closeBulkAddMembersForm, closeMembersModal } = this.props;
    closeBulkAddMembersForm();
    closeMembersModal();
  };

  render() {
    const {
      isOpen,
      bulkAddIsOpen,
      project,
      closeMembersModal,
      showSuggestionsOnOpen,
      isOpenMembersModal
    } = this.props;
    const { currentView } = this.state;
    return (
      <StyledModal
        isOpen={isOpen}
        toggle={this.handleCloseModal}
        className="budget-access-modal"
      >
        {!bulkAddIsOpen && (
          <>
            {/* <StyledModalHeader>
              <div>Manage Members</div>
              <DoneButton onClick={this.handleCloseModal}>Done</DoneButton>
            </StyledModalHeader> */}

            {/* <StyledToggleContainer>
              <StyledToggleOption
                isActive={currentView === tabViews.BUDGET_VIEWERS}
                onClick={() => this.onToggleView(tabViews.BUDGET_VIEWERS)}
              >
                {tabViews.BUDGET_VIEWERS}
              </StyledToggleOption>
              <StyledToggleOption
                isActive={currentView === tabViews.PROJECT_MEMBERS}
                onClick={this.handleToggleProjectMembers}
              >
                {tabViews.PROJECT_MEMBERS}
              </StyledToggleOption>
            </StyledToggleContainer> */}
          </>
        )}

        {currentView === tabViews.PROJECT_MEMBERS ? (
          <AddMembersContainer
            isContained
            modalType={MODAL_TYPE.PROJECT}
            inBudgetModal
            noFooter
            project={project}
            onClose={this.handleCloseModal}
            closeMembersModal={closeMembersModal}
            showSuggestionsOnOpen={showSuggestionsOnOpen}
            isOpenMembersModal={isOpenMembersModal}
          />
        ) : null}
      </StyledModal>
    );
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    teamMembershipsByAccountId: getTeamMembershipsByAccountId(state),
    sortedTeamMembers: getSortedTeamMembers(state),
    teamId: getSelectedTeamId(state),
    me: getMe(state),
    bulkAddIsOpen: getBulkAddIsOpen(state),
    bulkAddIsPopulated: getBulkAddIsPopulated(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccess,
  closeAccessModal,
  openAddMembersForm,
  closeBulkAddMembersForm
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(BudgetMembersModal);
