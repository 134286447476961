import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';

const {
  colors: { colorCalendarOrange, colorPureWhite }
} = theme;

const TasksCircle = styled.div`
  min-width: 22px;
  margin-right: 6px;
  padding: 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: ${({ borderColor }) =>
    borderColor ? '1px solid ' + borderColor : 'none'};
  background: ${(props) => props.backgroundColor || colorCalendarOrange};
`;
const DueTodayText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 1;
  color: ${({ color }) => color || colorPureWhite};
  min-width: 20px;
  text-align: center;
`;

const renderTooltip = (taskNumber, dataForTooltip) => {
  return `${taskNumber} task${taskNumber === 1 ? '' : 's'}  ${
    dataForTooltip === 'Reschedule'
      ? 'to Reschedule'
      : dataForTooltip === 'Upcoming'
      ? ' Upcoming'
      : `scheduled for ${dataForTooltip || `today`}`
  }`;
};
export const DayTasksCircle = (props) => {
  const { tasksCount, backgroundColor, color, borderColor, dataForTooltip } =
    props;
  return (
    <TasksCircle
      data-tip={renderTooltip(tasksCount, dataForTooltip)}
      data-for={`header-tooltips-${dataForTooltip}`}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <ReactTooltip
        id={`header-tooltips-${dataForTooltip}`}
        effect="solid"
        place="top"
      />
      <DueTodayText color={color}>{tasksCount}</DueTodayText>
    </TasksCircle>
  );
};
