import * as constants from '../constants';
import { createAction } from 'appUtils';

export const fetchHolidayGroups = (params) =>
  createAction(constants.FETCH_HOLIDAY_GROUPS.TRIGGER, params);

export const createHolidayGroup = (params) =>
  createAction(constants.CREATE_HOLIDAY_GROUP.TRIGGER, params);

export const updateHolidayGroup = (params) =>
  createAction(constants.UPDATE_HOLIDAY_GROUP.TRIGGER, params);

export const deleteHolidayGroup = (params) =>
  createAction(constants.DELETE_HOLIDAY_GROUP.TRIGGER, params);
