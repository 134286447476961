import React from 'react';
import SvgIcon from 'components/SvgIcon';

const FilterIcon = ({
  fill = '#4a4a4a',
  width = '15',
  height = '13',
  className
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 15 13"
  >
    <path
      d="M1 2.89655H14V1H1V2.89655ZM5.90566 12V10.1034H9.09434V12H5.90566ZM3.9434 7.44828V5.55172H11.0566V7.44828H3.9434Z"
      fill={fill}
    />
  </SvgIcon>
);

export default FilterIcon;
