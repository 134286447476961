import React from 'react';
import { connect } from 'react-redux';
import { makeGetActiveWorkloadPlannerFilter } from 'selectors';
import { updateAccountFilterLocal } from 'actionCreators';
import SimpleFilterDropdown from './SimpleFilterDropdown';
import {
  PROJECT_BUDGET_STATUS_OPTIONS,
  PROJECT_BUDGET_STATUSES_DISPLAY
} from 'appConstants/budgetStatuses';

const projectStatusItems = PROJECT_BUDGET_STATUS_OPTIONS.map(
  (item) => item.value
);

const ProjectBudgetStatusFilterDropdown = ({
  activeFilter,
  updateAccountFilterLocal,
  isHidden
}) => {
  const setFilterItems = (filterItem) => {
    updateAccountFilterLocal({
      ...activeFilter,
      custom: {
        ...activeFilter.custom,
        statusView: filterItem
      }
    });
  };

  if (isHidden) return null;

  return (
    <SimpleFilterDropdown
      items={projectStatusItems}
      itemHash={PROJECT_BUDGET_STATUSES_DISPLAY}
      initialSelectedItems={activeFilter.custom.statusView}
      setFilterItems={setFilterItems}
      searchEnabled={false}
      toggleLabel="Status"
      togglePluralLabel="Status"
      filterId={activeFilter.id}
      listWidth={217}
    />
  );
};

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => ({
    activeFilter: getActiveWorkloadPlannerFilter(state, ownProps)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(ProjectBudgetStatusFilterDropdown);
