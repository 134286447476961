import * as constants from 'appConstants';
import * as dashboardConstants from '../constants';
import keyBy from 'lodash/keyBy';

const byId = (item) => item.id;
export const initialState = {
  widgets: {},
  dashboardWidgets: {},
  widgetDataLoadedByAccountId: {}
};

const widgets = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case dashboardConstants.FETCH_WIDGETS.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        widgets: {
          ...state.widgets,
          ...keyBy(response, byId)
        }
      };
    }
    case dashboardConstants.FETCH_DASHBOARD_WIDGETS.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { dashboardId } = requestPayload;
      return {
        ...state,
        widgets: {
          ...state.widgets,
          ...keyBy(response, byId)
        },
        dashboardWidgets: {
          ...state.dashboardWidgets,
          [dashboardId]: response
        }
      };
    }

    case dashboardConstants.CREATE_WIDGET.SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [response.id]: response
        }
      };
    }

    case dashboardConstants.UPDATE_WIDGET.SUCCESS: {
      // response is a widget object
      const { response, requestPayload } = action.payload;
      const { dashboardId } = requestPayload;
      const widgetId = response.id;
      const updatedWidgetsByDashboardId = state.dashboardWidgets[
        dashboardId
      ].map((widget) => {
        if (widget.id === widgetId) {
          return response;
        } else {
          return widget;
        }
      });

      return {
        ...state,
        widgets: {
          ...state.widgets,
          [widgetId]: response
        },
        dashboardWidgets: {
          ...state.dashboardWidgets,
          [dashboardId]: updatedWidgetsByDashboardId
        }
      };
    }

    // The state gets cleared when Dashboard module unmounts.
    case dashboardConstants.SET_WIDGET_LOADED_STATE: {
      const { accountId, filterStateId } = action.payload;

      const nextLoadedState = state.widgetDataLoadedByAccountId[accountId]
        ? state.widgetDataLoadedByAccountId[accountId]
        : {};

      return {
        ...state,
        widgetDataLoadedByAccountId: {
          ...state.widgetDataLoadedByAccountId,
          [accountId]: {
            ...nextLoadedState,
            [filterStateId]: true
          }
        }
      };
    }

    case dashboardConstants.CLEAR_WIDGET_LOADED_STATE: {
      const { dashboardId } = action.payload;

      if (state.widgetDataLoadedByAccountId[dashboardId]) {
        const nextWidgetLoadedState = {
          ...state.widgetDataLoadedByAccountId
        };
        delete nextWidgetLoadedState[dashboardId];
        return {
          ...state,
          widgetDataLoadedByAccountId: nextWidgetLoadedState
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default widgets;
