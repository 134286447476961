import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type DollarSignWithCircleIconProps = Pick<
  SvgIconProps,
  | 'width'
  | 'height'
  | 'fill'
  | 'className'
  | 'viewBox'
  | 'stroke'
  | 'strokeWidth'
>;

const DollarSignWithCircleIcon = ({
  className,
  fill = '#4F4F4F',
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  stroke = '#4F4F4F',
  strokeWidth = '0.914286'
}: DollarSignWithCircleIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
  >
    <path
      d="M11.5 9.92588C11.5 10.5762 11.237 11.1142 10.7111 11.5397C10.1851 11.9605 9.45028 12.2259 8.50663 12.3359V14H7.50884V12.4005C6.93131 12.4005 6.37182 12.3598 5.83039 12.2785C5.28895 12.1925 4.84549 12.0753 4.5 11.9271V10.8081C4.92799 10.9851 5.42044 11.1309 5.97735 11.2457C6.53941 11.3556 7.04991 11.4106 7.50884 11.4106V8.25463C6.45175 7.94381 5.70921 7.58279 5.28122 7.17155C4.85838 6.76031 4.64696 6.22953 4.64696 5.5792C4.64696 4.95278 4.90737 4.43873 5.42818 4.03706C5.95414 3.63539 6.6477 3.39151 7.50884 3.30544V2H8.50663V3.29109C9.45543 3.315 10.3707 3.49193 11.2525 3.82188L10.8503 4.76151C10.082 4.47938 9.30074 4.31201 8.50663 4.25941V7.37239C9.31621 7.61148 9.9221 7.84579 10.3243 8.07531C10.7265 8.30006 11.023 8.56067 11.2138 8.85714C11.4046 9.14883 11.5 9.50508 11.5 9.92588ZM10.1851 10.0191C10.1851 9.67484 10.0691 9.39749 9.83702 9.18709C9.61013 8.97191 9.16667 8.75912 8.50663 8.54872V11.3389C9.6256 11.1955 10.1851 10.7555 10.1851 10.0191ZM5.95414 5.56485C5.95414 5.92827 6.07017 6.21996 6.30221 6.43993C6.53425 6.65989 6.93646 6.8679 7.50884 7.06396V4.28811C6.99834 4.36461 6.6116 4.51524 6.34862 4.73999C6.08564 4.95995 5.95414 5.23491 5.95414 5.56485Z"
      fill={fill}
    />
    <circle
      cx="8"
      cy="8"
      r="7.54286"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default DollarSignWithCircleIcon;
