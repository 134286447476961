import { createSelector } from 'reselect';
import Moment from 'moment';
import {
  formatPlannerScheduleBars,
  filterBarsByDateRange,
  accumulateWeekCommitment
} from 'appUtils/workplanDisplayUtils';
import { getPlannerSchedules } from './planner';
import { getProjectHash, getOOOProject } from './projects';
import { getFlatPhasesHash } from './phases';
import { getAllActivityRowInfo } from './timesheet';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import { extendMoment } from 'moment-range';
import keyBy from 'lodash/keyBy';
import { getCurrentAccount } from 'AuthenticationModule/selectors';

const moment = extendMoment(Moment);
const emptyArray = [];

const getOnlyMe = createSelector(getCurrentAccount, (currentAccount) => {
  return currentAccount
    ? {
        [currentAccount.id]: true
      }
    : {};
});

// todo fix import loop
const getAllGroups = (state) => state.groups.groupList;
const getGroupsHash = createSelector(getAllGroups, (groups) =>
  keyBy(groups, (item) => item.id)
);

export const makeGetWorkplanCustomStateByCustomStateId = () =>
  createSelector(
    (state) => state.workloadPlanner.customStates,
    (_, ownProps) => ownProps.customStateId,
    // do not give default value to it. the value inside of the customState can be in differnet data types
    (customStates, customStateId) => customStates[customStateId]
  );

export const getMyPlannerScheduleBars = createSelector(
  getOnlyMe,
  getPlannerSchedules,
  getProjectHash,
  getFlatPhasesHash,
  getAllActivityRowInfo,
  () => 66,
  getGroupsHash,
  getOOOProject,
  () => null, // stepValue
  () => null, // split id from url
  () => null, // context menu id
  () => false, // include unassigned
  () => null, // condensedZoomLevel
  () => true, // include split stubs
  formatPlannerScheduleBars
);

const monday = getMondayOfWeek(moment());
const nextMonday = monday.clone().add(1, 'week');
const nextSunday = monday.clone().add(1, 'week').subtract(1, 'day');
const theFollowingSunday = monday.clone().add(2, 'week').subtract(1, 'day');
const thisWeekDates = [monday, nextSunday];
const nextWeekDates = [nextMonday, theFollowingSunday];
const today = moment().startOf('day');
const nextMonth = today.clone().add(1, 'month');

export const getThisWeekScheduleBars = createSelector(
  getMyPlannerScheduleBars,
  filterBarsByDateRange(...thisWeekDates)
);
export const getThisWeekScheduleBarsWithPercentages = createSelector(
  getThisWeekScheduleBars,
  accumulateWeekCommitment(...thisWeekDates)
);
export const getNextWeekScheduleBars = createSelector(
  getMyPlannerScheduleBars,
  filterBarsByDateRange(...nextWeekDates)
);
export const getNextWeekScheduleBarsWithPercentages = createSelector(
  getNextWeekScheduleBars,
  accumulateWeekCommitment(...nextWeekDates)
);

export const getOOOScheduleBars = createSelector(
  getMyPlannerScheduleBars,
  getOOOProject,
  (bars, OOOProject) =>
    OOOProject
      ? bars.filter((bar) => bar.project_id === OOOProject.id)
      : emptyArray
);

export const getMonthOfPTOScheduleBars = createSelector(
  getOOOScheduleBars,
  (bars) =>
    filterBarsByDateRange(
      today,
      nextMonth
    )(bars).sort((bar1, bar2) =>
      moment(bar1.start_date).diff(bar2.start_date, 'days')
    )
);

const keyBySingleDate = (OOOSchedulesBars = emptyArray) => {
  const ptoByDate = {};

  OOOSchedulesBars.forEach((schedule) => {
    const { bars } = schedule;
    bars.forEach((bar) => {
      const { start_date, end_date, day_count } = bar;

      if (day_count) {
        const ptoRanges = Array.from(
          moment.range(start_date, end_date).by('day')
        );
        ptoRanges.forEach((date, index) => {
          ptoByDate[date.format('MM/DD/YYYY')] = {
            startDate: moment(start_date).format('M/D/YY'),
            endDate: moment(end_date).format('M/D/YY'),
            day: index + 1,
            total: day_count,
            isPartialPto: !schedule.all_day,
            daily_hours: schedule.daily_hours,
            ...bar
          };
        });
      }
    });
  });

  return ptoByDate;
};

export const makeGetMonthOfPTOScheduleBarsByDate = () =>
  createSelector(makeGetWorkplanCustomStateByCustomStateId(), keyBySingleDate);
