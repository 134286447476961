import styled from 'styled-components';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle,
  ClickableArea
} from 'components/BatchActions/styles';
import type {
  BaseFilterList,
  FilterListItem,
  BaseFilterGroupList
} from '../types';
import { Radio } from 'components/Radio';

import { isTableList } from 'components/Table/utils';

import {
  CustomStyledSelectToggle,
  CustomClickableArea
} from 'FilterModule/components/FilterListsTable/styles';

export const SelectCell = ({
  row
}: {
  row: {
    original: (FilterListItem | BaseFilterGroupList) & {
      parentList: BaseFilterList;
    };
  };
}) => {
  // isSelected = all sub items selected
  const {
    isSelected,
    toggleSelect,
    parentList: { isSingleSelect, isUnableToSelectMoreItems }
  } = row.original;

  const numSelectedItems = isTableList(row.original)
    ? row.original.numSelectedItems
    : -1;

  // for group headers, if limit has been reached, show as selected and allow deselecting if any child items are selected
  // (ie. act as a Clear button)
  const isSelectedToUse =
    isSelected || (!!isUnableToSelectMoreItems && numSelectedItems > 0);

  const isPartiallySelected =
    !isUnableToSelectMoreItems && !isSelected && numSelectedItems > 0;

  if (isUnableToSelectMoreItems && !isSelectedToUse) return <></>;

  const showCheckedOnHover =
    (!isSelected || isPartiallySelected) && !isUnableToSelectMoreItems;

  return (
    <StyledSelectToggleContainer>
      {isSingleSelect ? (
        <Radio
          checked={isSelected}
          size={18}
          value=""
          onChange={toggleSelect}
          className="white-background"
        />
      ) : (
        <>
          <CustomClickableArea
            onClick={toggleSelect}
            showIndeterminateStateOnHover={isSelectedToUse}
            toggleSize={14}
            showCheckedOnHover={showCheckedOnHover}
          />
          <CustomStyledSelectToggle
            isChecked={isSelectedToUse}
            isPartiallyChecked={isPartiallySelected}
            size={14}
          />
        </>
      )}
    </StyledSelectToggleContainer>
  );
};

/* ------------------------------------ - ----------------------------------- */
