import moment from 'moment';
import pickBy from 'lodash/pickBy';
import {
  TODAY,
  LANE_WIDTH,
  PLANNER_CONTAINER_LEFT_MARGIN
} from 'appConstants/planners';

export const isOverdue = (task) =>
  task &&
  !task.is_completed &&
  moment(task.schedule_start).isBefore(moment(), 'd');

export const isPastDueDate = (task) => {
  return (
    task && !task.is_completed && moment(task.due_at).isBefore(moment(), 'd')
  );
};
export const keyifyDate = (date) => {
  if (!date || date === 'unscheduled') {
    return 'unscheduled';
  }
  const dateKey = moment(date);
  return dateKey.isValid() ? dateKey.format('YYYY-MM-DD') : 'unscheduled';
};

export const getLaneQuerySelector = (date) =>
  `.droppable-lane.date-${keyifyDate(date)}`;

export const getDateFromClassName = (element) =>
  element.className.split('date-')[1];

export const getDateCoordinates = (date) => {
  const targetLane = document.querySelector(getLaneQuerySelector(date));
  if (targetLane) {
    return targetLane.getBoundingClientRect();
  }
};

export const isDateOnScreen = (date) =>
  getDateCoordinates(date) &&
  getDateCoordinates(date).left > 0 &&
  getDateCoordinates(date).right <
    window.innerWidth - PLANNER_CONTAINER_LEFT_MARGIN;

export const isTodayOnScreen = () => isDateOnScreen(TODAY);

export const calcNumDaysScrolled = (element, slideoutOpen) =>
  Math.trunc(-element.getBoundingClientRect().left / LANE_WIDTH) +
  (slideoutOpen ? 2 : 1);

export const swallowInitialFuncCall = () => {
  let hasSwallowedInitialCall = false;
  return (func) => {
    if (hasSwallowedInitialCall) {
      return (args) => func(args);
    } else {
      return () => {
        hasSwallowedInitialCall = true;
      };
    }
  };
};

export const formatDroppableId = (id) => {
  return id.split('--').slice(1);
};

export const createBlankPlanner = ({ userId, date, task }) => {
  return {
    account_id: userId,
    id: null,
    date: moment(keyifyDate(date), 'YYYY-MM-DD')._d,
    tasks: [task]
  };
};

export const getNumLanesOnScreen = (slideoutOpen) =>
  Math.floor((window.innerWidth - PLANNER_CONTAINER_LEFT_MARGIN) / LANE_WIDTH) -
  (slideoutOpen ? 1 : 0);

export const mergeHomePlanners = (stateAccountsObj, responseAccounts) => {
  const newKeys = [
    ...Object.keys(stateAccountsObj),
    ...Object.keys(responseAccounts)
  ];
  const accounts = newKeys.reduce((acc, accountId) => {
    acc[accountId] = {
      ...stateAccountsObj[accountId],
      ...responseAccounts[accountId]
    };

    return acc;
  }, {});

  return accounts;
};

export const getPlannerByDate = (planners, date, accountId) => {
  if (!planners[accountId]) {
    return null;
  }
  const targetPlanner = planners[accountId][keyifyDate(date)];
  if (targetPlanner) {
    return targetPlanner;
  } else {
    const newPlanner = {
      tasks: [],
      date: keyifyDate(date),
      account_id: accountId,
      id: null
    };
    return newPlanner;
  }
};

export const removeTaskFromPlanner = (planner, taskId) => ({
  ...planner,
  tasks:
    planner?.tasks.filter((id) => id.toString() !== taskId.toString()) ?? []
});

export const addTaskToPlanner = ({
  planner,
  plannerTaskId,
  position = null,
  completingTask
}) => {
  if (!plannerTaskId || !planner) {
    return planner;
  }
  if (completingTask) {
    return {
      ...planner,
      tasks: [...planner.tasks, plannerTaskId]
    };
  }

  const alreadyIncludesTask = planner.tasks.find(
    (taskId) => Number(taskId) === Number(plannerTaskId)
  );
  if (!position && position !== 0 && alreadyIncludesTask) {
    return planner;
  }
  if (!position && position !== 0) {
    return {
      ...planner,
      tasks: [...planner.tasks, plannerTaskId]
    };
  } else {
    return {
      ...planner,
      tasks: [
        ...planner.tasks.slice(0, position),
        plannerTaskId,
        ...planner.tasks.slice(position)
      ]
    };
  }
};

export const moveTaskToPlanner = ({
  planners,
  taskHash,
  taskId,
  dateKey,
  newDateKey,
  accountIdFrom,
  accountIdTo,
  position = null,
  completingTask = false,
  uncompletingTask = false,
  deletingTask = false,
  creatingTask = false
}) => {
  let plannerToRemoveFrom = null;
  if (dateKey) {
    plannerToRemoveFrom = getPlannerByDate(planners, dateKey, accountIdFrom);
  } else if (taskId) {
    const task = taskHash[taskId];
    plannerToRemoveFrom = getPlannerByDate(
      planners,
      task.schedule_start,
      accountIdFrom
    );
  }
  if (uncompletingTask) {
    return {
      plannerRemovedFrom: plannerToRemoveFrom,
      plannerAddedTo: plannerToRemoveFrom
    };
  }

  if (dateKey === 'unscheduled') {
    const plannerToAddTo = getPlannerByDate(planners, newDateKey, accountIdTo);
    const plannerAddedTo = addTaskToPlanner({
      planner: plannerToAddTo,
      plannerTaskId: taskId,
      position,
      completingTask
    });

    return {
      plannerAddedTo
    };
  }

  const plannerRemovedFrom = removeTaskFromPlanner(plannerToRemoveFrom, taskId);

  if (deletingTask) {
    return { plannerRemovedFrom };
  }
  const isSamePlanner =
    dateKey === newDateKey &&
    parseInt(accountIdFrom, 10) === parseInt(accountIdTo, 10);
  const plannerToAddTo =
    isSamePlanner && !creatingTask
      ? plannerRemovedFrom
      : getPlannerByDate(planners, newDateKey, accountIdTo);

  const plannerAddedTo = addTaskToPlanner({
    planner: plannerToAddTo,
    plannerTaskId: creatingTask ? 'new' : taskId,
    position: creatingTask ? null : position,
    completingTask
  });

  return {
    plannerRemovedFrom,
    plannerAddedTo
  };
};

export const deleteTaskFromPlanner = (params) =>
  moveTaskToPlanner({ ...params, deletingTask: true });

export const updatePlanners = ({
  planners,
  currentAccountId,
  newAccountId,
  dateKey,
  newDateKey,
  plannerAddedTo,
  plannerRemovedFrom
}) => {
  const newPlanners = { ...planners };
  if (plannerRemovedFrom) {
    newPlanners[currentAccountId] = {
      ...newPlanners[currentAccountId],
      [dateKey]: plannerRemovedFrom
    };
  }
  if (plannerAddedTo) {
    /*
    1. false if assigned to someone not on team planner or task was deleted
    2. necessary to do after constructing "newPlanners" because newAccountId might be same as currentAccountId and we don't want to overwrite the changes to plannerRemovedFrom
    */
    newPlanners[newAccountId] = {
      ...newPlanners[newAccountId],
      [newDateKey]: plannerAddedTo
    };
  }
  return newPlanners;
};

export const cleanParams = (params) =>
  pickBy(params, (value) => value !== undefined);

export const updateTaskHash = ({
  task,
  taskHash,
  params,
  shouldDeleteTask = false,
  shouldCleanUpNew = false
}) => {
  const newTaskHash = {
    ...taskHash,
    [task.id]: {
      ...task,
      ...cleanParams(params)
    }
  };
  if (shouldDeleteTask) {
    delete newTaskHash[task.id]; // can safely delete because we own newTaskHash
  }
  if (shouldCleanUpNew) {
    delete newTaskHash.new;
  }
  return newTaskHash;
};

export const deleteTaskFromCollection = (params) =>
  updateTaskHash({ ...params, shouldDeleteTask: true });

export const getNewDateRange = (state, leftOffset, rightOffset) => {
  const newEarliest = state.earliestDateFetched
    .clone()
    .subtract(leftOffset, 'w');
  const newLatest = state.latestDateFetched.clone().add(rightOffset, 'w');
  return { earliest: newEarliest, latest: newLatest };
};

export const getTask = (state, id) => state.taskHash[id];

export const getCurrentAccountByTaskType = (task, params) => {
  if (!task) {
    return;
  }
  return params.currentAssigneeId || task.assignee_id || task.account_id;
};

export const getNewAccountByTaskType = (task, params) => {
  const { assigneeId } = params;
  return assigneeId || getCurrentAccountByTaskType(task, params);
};

/* WIP: potential function that accepts all neccesary params and calls moveTaskToPlanner, updatePlanners, and updateTaskHash for us rather than calling them manually in each case. This will require the ability to pass all params in to updateTaskHash and cleaning/configuring the params there. */

export const reducePlanners = ({
  state,
  params,
  shouldDeleteTask = false,
  shouldCreateTask = false,
  shouldMoveTask = true
}) => {
  if (!Object.keys(state.planners).length) {
    return state;
  }
  const {
    projectId,
    taskId,
    position,
    isCompleted,
    wasCompleted,
    scheduleStartDate,
    scheduleEndDate,
    dueDate,
    dateKey,
    description,
    assigneeId,
    currentAssigneeId,
    status,
    newTask,
    estimatedHours,
    phaseId
  } = params;
  const newDateKey =
    scheduleStartDate !== undefined ? keyifyDate(scheduleStartDate) : dateKey;

  const task = newTask || getTask(state, taskId);

  if (!task && !shouldCreateTask) {
    return state;
  }

  const configuredCompletedAt =
    isCompleted === undefined ? undefined : isCompleted ? moment() : null;

  const configuredParams = {
    due_at: dueDate,
    schedule_start: scheduleStartDate,
    schedule_end: scheduleEndDate,
    assignee_id: assigneeId,
    description,
    is_completed: isCompleted,
    completed_at: configuredCompletedAt,
    project_id: projectId,
    status: status,
    estimated_hours: estimatedHours,
    phase_id: phaseId
  };

  const newTaskHash = updateTaskHash({
    task: shouldCreateTask ? { id: 'new' } : task,
    taskHash: state.taskHash,
    params: configuredParams,
    shouldDeleteTask
  });
  if (!shouldMoveTask) {
    return {
      ...state,
      taskHash: newTaskHash
    };
  } else {
    const currentAccountId = shouldCreateTask
      ? getNewAccountByTaskType(task, params)
      : getCurrentAccountByTaskType(task, params);
    const newAccountId = getNewAccountByTaskType(task, params);
    const { plannerRemovedFrom, plannerAddedTo } = moveTaskToPlanner({
      planners: state.planners,
      taskHash: state.taskHash,
      taskId: shouldCreateTask ? 'new' : taskId,
      dateKey,
      newDateKey,
      accountIdFrom: currentAccountId,
      accountIdTo: newAccountId,
      creatingTask: shouldCreateTask,
      uncompletingTask: wasCompleted,
      completingTask: isCompleted,
      position: position === undefined ? task.position : position
    });

    const newPlanners = updatePlanners({
      planners: state.planners,
      currentAccountId,
      newAccountId,
      dateKey: dateKey || keyifyDate(task.schedule_start),
      newDateKey: newDateKey || keyifyDate(task.schedule_start),
      plannerAddedTo: !shouldDeleteTask && plannerAddedTo,
      plannerRemovedFrom
    });

    return {
      ...state,
      planners: newPlanners,
      taskHash: newTaskHash
    };
  }
};

export const prepTaskParamsForReducePlanners = ({
  account_id,
  dateKey,
  description,
  due_at,
  id,
  schedule_start,
  schedule_end,
  is_completed,
  assignee_id,
  project_id
}) => ({
  isCompleted: is_completed,
  scheduleStartDate: schedule_start,
  scheduleEndDate: schedule_end,
  dueDate: due_at,
  dateKey,
  description,
  assigneeId: assignee_id,
  projectId: project_id,
  taskId: id,
  accountId: account_id
});
