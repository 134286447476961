import React from 'react';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { SORT_BY, SORT_ORDER } from 'appConstants/filters';
import {
  getSortedAlphabeticalTeamMembers,
  getAdminBudgetTableSort,
  getWorkGroupsWithMembers,
  getSkillsArray,
  getSortedTeamMembers
} from 'selectors';
import {
  getTeamRates,
  getDefaultTeamRates,
  getActiveTeamSalaries
} from 'BudgetModule/selectors';
import {
  getPositions,
  getAccountToTeamPosition
} from 'BudgetModule/selectors/positions';
import {
  calculateHighestTeamMembershipRoleId,
  calculateHighestTeamMembershipRoleLevel
} from 'appUtils/roleDisplay';
import partition from 'lodash/partition';
import flatten from 'lodash/flatten';
import HelpIcon from 'images/help-icon-2.svg';
import { getAllTeamMembersWithArchived } from 'TeamsModule/selectors';
import { permissionsUtils } from 'PermissionsModule/utils';

const emptyArray = [];

const comparisonFunctions = {};
const memberColumn = {
  headerType: SORT_BY.member,
  headerLabel: 'member name',
  accessor: (row) => row.member,
  id: 'member',
  align: 'left'
};
comparisonFunctions[SORT_BY.member] = (a, b) =>
  a?.account?.name.toLowerCase() > b?.account?.name.toLowerCase() ? 1 : -1;

const rateColumn = {
  headerType: SORT_BY.rate,
  headerLabel: 'bill rate',
  accessor: (row) => row.rate,
  id: 'rate'
};
comparisonFunctions[SORT_BY.rate] = (a, b) => +(b?.rate ?? 0) - +(a?.rate || 0);

const descriptionColumn = {
  headerType: SORT_BY.description,
  headerLabel: 'description',
  accessor: (row) => row.description,
  id: 'description',
  align: 'left'
};
comparisonFunctions[SORT_BY.description] = (a, b) => {
  if (a === undefined) return 1;
  if (b === undefined) return -1;
  return a < b ? 1 : -1;
};

const costColumn = {
  headerType: SORT_BY.cost,
  headerLabel: 'cost rate/hr',
  accessor: (row) => row.salary?.actual_hourly_rate,
  id: 'cost',
  align: 'center'
};

const rateMultiplierColumn = {
  headerType: SORT_BY.rateMultiplier,
  headerLabel: 'rate multiplier',
  accessor: (row) => row.rateMultiplier,
  id: 'rateMultiplier',
  align: 'center'
};

comparisonFunctions[SORT_BY.rateMultiplier] = (a, b) => (a > b ? 1 : -1);

const permissionColumn = {
  headerType: SORT_BY.permissionLevel,
  headerLabel: 'access',
  accessor: (row) => calculateHighestTeamMembershipRoleId(row.member),
  id: 'permissionLevel',
  align: 'left'
};
comparisonFunctions[SORT_BY.permissionLevel] = (a, b) => a - b;

const roleTooltipIconStyle = {
  width: 10,
  marginBottom: 5,
  filter: 'brightness(0.9)'
};

const roleColumn = {
  headerType: SORT_BY.role,
  headerLabel: (
    <div
      data-for="app-tooltip"
      data-class="center"
      data-tip="Add Roles from Standards<br/>on the left menu"
      data-html
      data-effect="solid"
    >
      default role <img src={HelpIcon} style={roleTooltipIconStyle} />
    </div>
  ),
  accessor: (row) => row.role,
  id: 'role',
  align: 'left'
};
comparisonFunctions[SORT_BY.role] = (a, b) =>
  a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1;

comparisonFunctions[SORT_BY.email_notification_on] = (a, b) =>
  a !== b ? (a ? 1 : -1) : 0;

const emailNotificationColumn = {
  headerType: SORT_BY.email_notification_on,
  headerLabel: 'email notification',
  accessor: (row) => row.email_notification_on,
  id: 'email_notification_on',
  align: 'center'
};

const noopSort = () => 0;

const emailColumn = {
  headerType: SORT_BY.email,
  headerLabel: 'email',
  accessor: (row) => row.email,
  id: 'email',
  align: 'left'
};

const skillColumn = {
  headerType: SORT_BY.skillLevel,
  headerLabel: 'skill level',
  accessor: (row) => row.skillLevel,
  id: 'skillLevel',
  align: 'left'
};

const paddingColumn = {
  headerType: 'padding',
  headerLabel: '',
  accessor: (row) => row,
  id: 'padding',
  align: 'left'
};

const collapseColumn = {
  headerType: 'collapse',
  headerLabel: '',
  accessor: (row) => row,
  id: 'collapse',
  align: 'left'
};

comparisonFunctions[SORT_BY.email] = (a, b) =>
  a.toLowerCase() > b.toLowerCase() ? 1 : -1;

comparisonFunctions[SORT_BY.skillLevel] = (a, b) => a - b;

comparisonFunctions.isGuest = (a, b) =>
  a.isGuest !== b.isGuest ? (a.isGuest ? 1 : -1) : 0;

const budgetSettingsColumns = [
  memberColumn,
  emailColumn,
  costColumn,
  rateColumn,
  // rateMultiplierColumn,
  // paddingColumn
  descriptionColumn
];

const memberManagementColumns = [
  memberColumn,
  emailColumn,
  permissionColumn,
  roleColumn,
  emailNotificationColumn
  // lastSeenColumn
];

const departmentsColumns = [
  collapseColumn,
  memberColumn,
  emailColumn,
  paddingColumn
];

const skillsColumns = [
  collapseColumn,
  memberColumn,
  emailColumn,
  skillColumn,
  paddingColumn
];

const positionsColumns = [
  collapseColumn,
  memberColumn,
  emailColumn,
  paddingColumn
];

/** Define custom sort function by constant sort by value */
const customSortFnHash = {
  [SORT_BY.cost]: (rows, order) =>
    orderBy(rows, 'salary.actual_hourly_rate', order),
  [SORT_BY.bill]: (rows, order) => orderBy(rows, 'rate.rate', order)
};

export const getBudgetSettingsColumnHeaders = (state) => budgetSettingsColumns;

export const getMemberManagementColumnHeaders = (state) =>
  memberManagementColumns;
export const getDepartmentColumnHeaders = (state) => departmentsColumns;
export const getSkillColumnHeaders = (state) => skillsColumns;
export const getPositionColumnHeaders = (state) => positionsColumns;

// common output selector used by multiple createSelectors below
const formatMemberBudgetSettings = (
  teamMembers,
  defaultTeamRates,
  teamRates,
  activeTeamSalaries,
  sortConfig,
  teamMemberPositions,
  positionHash
) => {
  const rows =
    teamMembers?.map((member) => {
      const memberRate = defaultTeamRates[member?.account?.id];
      const memberSalary = activeTeamSalaries[member?.id];
      const rate = memberRate
        ? {
            ...(teamRates[memberRate.rate_id] || {}),
            start_date: memberRate.start_date,
            end_date: memberRate.end_date
          }
        : undefined;
      const rateMultiplier = 1 + +(Math.random(100) * 2).toFixed(1); // teamMembersSalaryRate[member?.id];
      const teamRole = teamMemberPositions[member?.account?.id];
      const description = memberRate
        ? teamRates[memberRate.rate_id]?.description
        : undefined;
      return {
        member,
        salary: memberSalary,
        rate,
        rateMultiplier,
        email: member.account.email,
        permissionLevel: calculateHighestTeamMembershipRoleLevel(member),
        isGuest: permissionsUtils.getIsProjectGuest(member),
        teamRole,
        role: positionHash[teamRole?.position_id],
        description,
        email_notification_on: member.account.email_notification_on
      };
    }) ?? emptyArray;
  if (sortConfig?.value) {
    const { value, order } = sortConfig;
    const customSortFn = customSortFnHash[value];

    if (customSortFn) return customSortFn(rows, order);

    const sortFunction = comparisonFunctions[sortConfig.value] || noopSort;

    rows.sort((a, b) =>
      order === SORT_ORDER.desc
        ? sortFunction(a[value], b[value])
        : -1 * sortFunction(a[value], b[value])
    );
  }
  return rows;
};

export const getFormattedBudgetSettings = createSelector(
  getSortedAlphabeticalTeamMembers,
  getDefaultTeamRates,
  getTeamRates,
  getActiveTeamSalaries,
  getAdminBudgetTableSort,
  getAccountToTeamPosition,
  getPositions,
  formatMemberBudgetSettings
);

const byEntityId = (item) => item.entity_id;

export const getFormattedWorkGroupSettings = createSelector(
  getFormattedBudgetSettings,
  getWorkGroupsWithMembers,
  (formattedRows, workGroupsArray) => {
    const workGroupsWithHashMemberships = workGroupsArray.map((workGroup) => ({
      ...workGroup,
      workGroupMembersHash: keyBy(
        workGroup.work_group_memberships?.filter(
          //  TODO: remove 'account' when BE change on work_group hits prod.
          // this is only to make sure FE works when FE reaches prod but not BE.
          (workGroupMembership) =>
            workGroupMembership.entity_type === 'account' ||
            workGroupMembership.entity_type === 'Account'
        ) ?? [],
        byEntityId
      )
    }));
    return workGroupsWithHashMemberships.map((workGroup) => ({
      ...workGroup,
      formattedWorkGroupMembers: formattedRows.filter(
        (row) => workGroup.workGroupMembersHash[row.member.account.id]
      )
    }));
  }
);

export const getFormattedWorkGroupSettingsWithLeftoverMembersWorkGroup =
  createSelector(
    getFormattedBudgetSettings,
    getFormattedWorkGroupSettings,
    (state) => state.workGroups.loaded,
    (unsortedRows, workGroupsArray, isLoaded) => {
      if (!isLoaded) {
        // dont calculate or show 'members that are not in a work group' until work groups are loaded
        return emptyArray;
      }

      // members table could be sorted some non standard way - override for filters specifically.
      const sortedRows = [...unsortedRows].sort(
        (a, b) =>
          comparisonFunctions.isGuest(a, b) ||
          comparisonFunctions[SORT_BY.member](a.member, b.member)
      );

      const belongingToSomeWorkGroupAccountIds = keyBy(
        flatten(
          workGroupsArray.map(
            (workGroup) => workGroup.formattedWorkGroupMembers
          )
        ).map((workGroupMember) => workGroupMember.member.account.id)
      );
      const belongingToNoWorkGroups = sortedRows.filter(
        (row) => !belongingToSomeWorkGroupAccountIds[row.member.account.id]
      );

      const [activeMembers, archivedMembers] = partition(
        belongingToNoWorkGroups,
        (row) => !row.member.is_archived
      );
      const belongingToNoWorkGroupsMemberships = activeMembers.map((row) => ({
        entity_type: 'account',
        entity_id: row.member.account.id,
        work_group_id: 'no department'
      }));
      const archivedMembersWorkGroupMemberships = archivedMembers.map(
        (row) => ({
          entity_type: 'account',
          entity_id: row.member.account.id,
          work_group_id: 'no department'
        })
      );
      const noDepartmentWorkGroup = {
        name: 'No Department',
        id: 'no department',
        workGroupMembersHash: keyBy(
          belongingToNoWorkGroupsMemberships,
          byEntityId
        ),
        formattedWorkGroupMembers: activeMembers
      };
      const archivedDepartmentWorkGroup = {
        name: 'Archived',
        id: 'archived members',
        workGroupMembersHash: keyBy(
          archivedMembersWorkGroupMemberships,
          byEntityId
        ),
        formattedWorkGroupMembers: archivedMembers
      };
      return [
        ...workGroupsArray,
        noDepartmentWorkGroup,
        archivedDepartmentWorkGroup
      ];
    }
  );

export const makeGetFormattedSkillsWithMembers = () =>
  createSelector(
    (state, { isExcludeArchivedMembers = false } = {}) =>
      isExcludeArchivedMembers,
    getSortedTeamMembers,
    getAllTeamMembersWithArchived,
    getSkillsArray,
    getAdminBudgetTableSort,
    (
      isExcludeArchivedMembers,
      teamMembers,
      teamMembersWithArchived,
      skillsArray,
      sortConfig
    ) => {
      const members = isExcludeArchivedMembers
        ? teamMembers
        : teamMembersWithArchived;

      const skillsWithHashMemberships = skillsArray.map((skill) => ({
        ...skill,
        skillMembersHash: keyBy(
          skill.team_member_skills || [],
          (membership) => membership.account_id
        )
      }));

      const formattedSkills = skillsWithHashMemberships.map((skill) => {
        const formattedSkillMembers = members.filter(
          (row) => skill.skillMembersHash[row.account.id]
        );

        if (
          sortConfig?.value === SORT_BY.skillLevel ||
          sortConfig?.value === SORT_BY.email
        ) {
          const sortFunction =
            comparisonFunctions[sortConfig.value] || noopSort;

          const { value, order } = sortConfig;

          const itemLens = {
            [SORT_BY.skillLevel]: (item) =>
              skill.skillMembersHash[item.account.id]?.[value],
            [SORT_BY.email]: (item) => item.account.email
          }[value];

          const sortSign = order === SORT_ORDER.desc ? 1 : -1;

          formattedSkillMembers.sort(
            (a, b) => sortSign * sortFunction(itemLens(a), itemLens(b))
          );
        }

        return {
          ...skill,
          formattedSkillMembers
        };
      });
      return formattedSkills;
    }
  );
