import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

const BackArrowIcon = ({
  className = '',
  fill = theme.colors.colorSemiDarkGray1,
  height = 20,
  width = 13,
  ...svgProps
}) => {
  return (
    <SvgIcon
      width={width}
      className={className}
      height={height}
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M10.5916 0.351564L12.6433 2.40328L5.01168 10L12.6084 17.6316L10.5567 19.6833L0.908325 10.0001L10.5916 0.351564Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default BackArrowIcon;
