import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { useSecurity } from 'SettingsModule/components/Security/SecuritySettings/hooks/useSecurity';
import styled, { useTheme } from 'styled-components';
import CopyIcon from 'icons/CopyIcon';
import { BlueSubmitButton, TextButtonWithBorder } from 'components/styles';

interface SamlDetailsEditModalProps {
  isOpen: boolean;
  onToggle: () => void;
}

const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const SamlDetailsEditModal = ({
  isOpen,
  onToggle
}: SamlDetailsEditModalProps) => {
  const [ssoUrl, setSsoUrl] = useState('');
  const [certificate, setCertificate] = useState('');
  const [domain, setDomain] = useState('');

  const theme = useTheme();

  const { teamSamlInfo, isModifyingSaml, associateSamlIdentity } =
    useSecurity();

  const handleAssociateSamlIdentity = useCallback(() => {
    associateSamlIdentity({ certificate, domain, ssoUrl, onSuccess: onToggle });
  }, [associateSamlIdentity, certificate, domain, onToggle, ssoUrl]);

  useEffect(() => {
    if (teamSamlInfo) {
      // always execute whenever `isOpen` changes
      // we do this way instead of adding `isOpen` manually to the list of dependencies
      // so that `isOpen` never gets accidentally removed from the list of dependencies
      // by accident in the future by a dev
      if (isOpen || !isOpen) {
        const { saml_idp_config } = teamSamlInfo;
        const firstDomain = saml_idp_config.domains?.[0];
        setSsoUrl(saml_idp_config.entry_point || '');
        setCertificate(saml_idp_config.certificate || '');
        setDomain(firstDomain || '');
      }
    }
  }, [isOpen, teamSamlInfo]);

  if (!teamSamlInfo) return <></>;

  return (
    <StyledModal isOpen={isOpen}>
      <ContentContainer>
        <Header>SAML 2.0 Single Sign On</Header>
        <Body>
          <FormFieldContainer>
            <FieldLabel>ACS URL</FieldLabel>
            <FieldInputContainer>
              <FieldInput value={teamSamlInfo.callback_url} readOnly />
              <CopyIconContainer
                onClick={() => copyToClipboard(teamSamlInfo.callback_url)}
              >
                <CopyIcon height={20} width={20} />
              </CopyIconContainer>
            </FieldInputContainer>
          </FormFieldContainer>
          <FormFieldContainer>
            <FieldLabel>Entity ID</FieldLabel>
            <FieldInputContainer>
              <FieldInput value={teamSamlInfo.entity_id} readOnly />
              <CopyIconContainer
                onClick={() => copyToClipboard(teamSamlInfo.entity_id)}
              >
                <CopyIcon height={20} width={20} />
              </CopyIconContainer>
            </FieldInputContainer>
          </FormFieldContainer>
          <FormFieldContainer>
            <FieldLabel>Domain</FieldLabel>
            <FieldInput
              value={domain}
              placeholder="Enter domain associated with the team"
              onChange={(e) => setDomain(e.target.value)}
            />
          </FormFieldContainer>
          <FormFieldContainer>
            <FieldLabel>SSO URL</FieldLabel>
            <FieldInput
              value={ssoUrl}
              placeholder="Enter SSO URL"
              onChange={(e) => setSsoUrl(e.target.value)}
            />
          </FormFieldContainer>
          <FormFieldContainer>
            <FieldLabel>Certificate</FieldLabel>
            <FieldInput
              value={certificate}
              placeholder="Enter Certificate"
              onChange={(e) => setCertificate(e.target.value)}
            />
          </FormFieldContainer>
        </Body>

        <Footer>
          <HelpTextContainer>
            Learn more about SAML setup:{'   '}
            <ExternalLinkContainer>
              <ExternalLink
                href="https://readme.mosaicapp.com/docs/google-saml"
                target="_blank"
              >
                Google
              </ExternalLink>
              /
              <ExternalLink
                href="https://readme.mosaicapp.com/docs/microsoft-azure-saml"
                target="_blank"
              >
                Azure (Microsoft)
              </ExternalLink>
            </ExternalLinkContainer>
          </HelpTextContainer>
          <ActionButtonsContainer>
            <TextButtonWithBorder
              borderColor={theme.colors.colorLightGray12}
              onClick={onToggle}
            >
              Cancel
            </TextButtonWithBorder>
            <BlueSubmitButton
              isDisabled={!ssoUrl || !certificate || !domain || isModifyingSaml}
              fontWeight={600}
              onClick={handleAssociateSamlIdentity}
            >
              Done
            </BlueSubmitButton>
          </ActionButtonsContainer>
        </Footer>
      </ContentContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: 695px;

  .modal-content {
    width: 695px;
    height: 752px;
    border-radius: 6px !important;
    padding: 30px 36px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  gap: 24px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

const Body = styled.div`
  display: grid;
  gap: 24px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HelpTextContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-self: flex-start;
  gap: 5px;
`;

const ExternalLink = styled.a``;

const ExternalLinkContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const FieldLabel = styled.div`
  font-size: 16px;
`;

const FieldInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const FieldInput = styled.input`
  background-color: white;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.colorPaleGray7};
  padding: 19px 50px 19px 12px;
  font-size: 16px;

  &:read-only {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray6};
  }
`;

const CopyIconContainer = styled.div`
  position: absolute;
  right: 14px;
  bottom: 35%;
  cursor: pointer;
`;
