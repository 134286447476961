import { createSelector } from 'reselect';

export const getQBProjectsState = (state) =>
  (state.quickbooks && state.quickbooks.qbSubCustomers) || {};

export const getPhasesState = (state) =>
  (state.quickbooks && state.quickbooks.phases) || {};

export const getQuickbooksSelectedProjectsQBToMosaic = createSelector(
  getQBProjectsState,
  (qbSubCustomers) => qbSubCustomers.selectedProjectsQBToMosaic
);
export const getQuickbooksSelectedProjectsMosaicToQB = createSelector(
  getQBProjectsState,
  (qbSubCustomers) => qbSubCustomers.selectedProjectsMosaicToQB
);

export const getIsConfigDirQbToMosaic = createSelector(
  getQBProjectsState,
  (qbSubCustomers) => qbSubCustomers && qbSubCustomers.qbToMosaic
);
