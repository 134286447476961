import React, { useState, useMemo } from 'react';
import {
  BatchActionToggleWrapper,
  BatchActionToggleIcon
} from 'components/BatchActions/styles';
import { useDispatch, useSelector } from 'react-redux';
import { unselectAllProjects, selectProjects } from 'actionCreators';
import { getSelectedProjectIds } from 'selectors';
import { HeaderText, Header } from './styles';

const SelectAllHeader = ({ projectListGrouped }) => {
  const [hovered, setHovered] = useState(false);
  const selectedProjectIds = useSelector(getSelectedProjectIds);
  const dispatch = useDispatch();
  const selectedProjectLength = Object.keys(selectedProjectIds).length;
  const isSelected =
    selectedProjectLength > 0 &&
    selectedProjectLength === projectListGrouped.length;

  const handleClick = () => {
    if (isSelected) {
      dispatch(unselectAllProjects());
    } else if (selectedProjectLength > 0) {
      dispatch(unselectAllProjects());
      dispatch(selectProjects(projectListGrouped));
    } else {
      dispatch(selectProjects(projectListGrouped));
    }
  };
  const showButton = useMemo(() => {
    return hovered || selectedProjectLength > 0;
  }, [hovered, selectedProjectLength]);
  return (
    <Header
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {showButton ? (
        <BatchActionToggleIcon
          isBatchSelected={isSelected}
          onClick={handleClick}
        />
      ) : (
        <HeaderText>SELECT</HeaderText>
      )}
    </Header>
  );
};

export default SelectAllHeader;
