import { createRequestTypes } from 'appUtils';

export const FETCH_OFFICES = createRequestTypes('FETCH_OFFICES');
export const CREATE_OFFICE = createRequestTypes('CREATE_OFFICE');
export const UPDATE_OFFICE_METADATA = createRequestTypes(
  'UPDATE_OFFICE_METADATA'
);

export const UPDATE_OFFICE_LOCATION = createRequestTypes(
  'UPDATE_OFFICE_LOCATION'
);
export const DELETE_OFFICE = createRequestTypes('DELETE_OFFICE');

export const UPDATE_OFFICE_ENTITIES = createRequestTypes(
  'UPDATE_OFFICE_ENTITIES'
);
