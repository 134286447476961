import {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useCallback
} from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { makeGetCommentCountsByParentEntity } from 'selectors';
import without from 'lodash/without';
import noop from 'lodash/noop';

import { Scope } from 'models/scope';
import { useWorkPlanScopes } from 'views/projectPlanner/WorkplanModal/hooks/useWorkPlanScopes';
// import { useWorkPlanTasks } from 'views/projectPlanner/WorkplanModal/hooks/useWorkPlanTasks';
import { Task } from 'models/task';
import { useWorkPlanActivityLogs } from 'views/projectPlanner/WorkplanModal/hooks/useWorkPlanActivityLogs';
import { ActivityPhaseScheduleBarUserActivity } from 'views/projectPlanner/WorkplanModal/models/activityPhaseScheduleBarUserActivity';
import { EntityType } from 'models/entity';
import { WorkplanRequest } from 'models/workplanRequest';
import { WorkplanRequestModalState } from 'components/WorkplanRequests/modals/WorkplanRequestModal/types';
import { checkRequiredKeys } from 'appUtils/objectUtils';

const emptyArray = [];

const MAP_ERROR_KEYS = {
  missing_activity: 'activity_id',
  missing_project_id: 'project_id',
  missing_phase_id: 'phase_id'
};

interface WorkPlanRequestSideContextValues {
  form: {
    workplanRequest: WorkplanRequestModalState;
    hasError: any;
    isNewRequest: boolean;
  };
  comment: {
    totalCount: number;
  };
  scope: {
    scopes: Array<Scope>;
    onAddScope: (scope: Scope) => void;
    onRemoveScope: (scope: Scope) => void;
  };
  task: {
    tasks: Array<number>;
    onAddTask: () => void; // open task modal
    onMarkComplete: (task: Task) => void;
  };
  activityLog: {
    activityLogs: Array<ActivityPhaseScheduleBarUserActivity>;
  };
}
const WorkPlanRequestSidePanelContext = createContext(
  {} as WorkPlanRequestSideContextValues
);

export const WorkPlanRequestSidePanelProvider = ({
  workplanRequest,
  workplanRequestModalState,
  handleSetState,
  isNewRequest,
  children
}: {
  children: ReactNode;
  workplanRequest?: Nullable<WorkplanRequest>;
  workplanRequestModalState: WorkplanRequestModalState;
  isNewRequest: boolean;
  handleSetState: (updateParams: WorkplanRequestModalState) => void;
}) => {
  /* -------------------------------- comments -------------------------------- */
  const getCommentCounts = useMemo(
    () => makeGetCommentCountsByParentEntity(),
    []
  );

  const commentCounts = useAppSelector((state) =>
    workplanRequest?.id
      ? getCommentCounts(state, {
          parentEntityType: EntityType.ActivityPhaseScheduleBarRequest,
          parentEntityId: workplanRequest?.id
        })
      : undefined
  );

  /* --------------------------------- scopes --------------------------------- */
  const { scopes } = useWorkPlanScopes({
    scopeOrders: workplanRequestModalState.project_scope_orders || emptyArray
  });
  const onAddScope = (scope) => {
    handleSetState({
      project_scope_orders: [
        ...(workplanRequestModalState.project_scope_orders || emptyArray),
        scope.id
      ]
    });
  };

  const onRemoveScope = (scope) => {
    handleSetState({
      project_scope_orders: without(
        workplanRequestModalState.project_scope_orders,
        scope.id
      )
    });
  };

  /* ---------------------------------- tasks --------------------------------- */
  const tasks = workplanRequestModalState.project_task_orders || [];
  const onAddTask = noop;
  const onMarkComplete = noop;

  // const { tasks, onAddTask, onMarkComplete } = useWorkPlanTasks({
  //   taskOrder,
  //   workplan: state || {},
  //   phase,
  //   activity
  // });

  /* ------------------------------ activityLogs ------------------------------ */
  const { activityLogs } = useWorkPlanActivityLogs({
    actionableId: workplanRequest?.id,
    actionableType: EntityType.ActivityPhaseScheduleBarRequest
  });

  /* --------------------------------- shared --------------------------------- */
  const hasError = useCallback(
    (errorKeys) => {
      const keys = errorKeys
        .map((errorKey) => MAP_ERROR_KEYS[errorKey])
        .filter((errorKey) => errorKey);
      const missingKeys = checkRequiredKeys({
        keys,
        object: workplanRequestModalState
      });

      return Boolean(missingKeys.length);
    },
    [workplanRequestModalState]
  );

  /* -------------------------------- value -------------------------------- */

  const value = {
    form: {
      workplanRequest: workplanRequestModalState,
      isNewRequest,
      hasError
    },
    comment: {
      totalCount: commentCounts?.totalCount ?? 0
    },
    scope: {
      scopes: scopes,
      onAddScope: onAddScope,
      onRemoveScope: onRemoveScope
    },
    task: {
      tasks: tasks,
      onAddTask: onAddTask,
      onMarkComplete: onMarkComplete
    },
    activityLog: {
      activityLogs: activityLogs
    }
  };
  return (
    <WorkPlanRequestSidePanelContext.Provider value={value}>
      {children}
    </WorkPlanRequestSidePanelContext.Provider>
  );
};

export const useWorkPlanRequestSidePanelForm = () =>
  useContext(WorkPlanRequestSidePanelContext);
