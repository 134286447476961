import * as constants from 'appConstants';

export const initialState = {
  taskPriorities: []
};

const taskPriorities = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.FETCH_TASK_PRIORITIES.SUCCESS: {
      const taskPriorities = action.payload.response;
      return {
        ...state,
        taskPriorities
      };
    }

    default:
      return state;
  }
};

export default taskPriorities;
