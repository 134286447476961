import { useEffect, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import theme from 'theme';
import SimpleBar from 'components/Bars/SimpleBar';
import { rebuildTooltip, defaultTooltipProps } from 'appUtils/tooltipUtils';
import moment from 'moment';
import { HTMLTable } from 'components/HTMLTable';
import { SuggestedMemberForOpenPosition } from 'SuggestionModule/components/FindPeople/types';
import { getPhaseDatesData } from 'SuggestionModule/components/FindPeople/utils';
import { MemberGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { useMemberOptions } from 'SuggestionModule/components/FindPeople/Table/helpers';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import { StyledPlusIcon } from '../shared/style';

const calculateMemberHours = ({
  member,
  startDate,
  endDate
}: {
  member: SuggestedMemberForOpenPosition;
  startDate: Nullable<string> | undefined;
  endDate: Nullable<string> | undefined;
}) => {
  const memberSchedule = member.schedule || {};
  const scheduleDateKeys = Object.keys(memberSchedule);
  let totalCapacity = 0;
  let totalAvailability = 0;

  scheduleDateKeys.forEach((date) => {
    // Get the available hours and capacity between the selected start and end dates inclusively
    if (moment(date).isBetween(startDate, endDate, 'day', '[]')) {
      const { remaining_capacity, total_capacity } = memberSchedule[date] || {};
      totalCapacity += total_capacity ?? 0;
      totalAvailability += remaining_capacity ?? 0;
    }
  });

  // Formatting (there is a case where these numbers can bee in long decimals)
  totalAvailability = Math.round(totalAvailability);
  totalCapacity = Math.round(totalCapacity);

  return {
    totalAvailability,
    totalCapacity,
    availabilityToCapacityRatio: Math.round(
      Math.min(
        totalCapacity ? (totalAvailability / totalCapacity) * 100 : 0,
        100
      )
    )
  };
};

const getBarTooltip = ({
  totalAvailability,
  totalCapacity,
  availabilityToCapacityRatio,
  hasDatesRequired
}) => {
  const bodyRows = [
    {
      name: 'availability',
      columns: [
        {
          content: <div className="title">Availability</div>
        },
        {
          content: <div>{totalAvailability}h</div>
        },
        {
          content: (
            <div>
              | <span className="ratio">{availabilityToCapacityRatio}%</span>
            </div>
          )
        }
      ]
    },
    {
      name: 'capacity',
      columns: [
        {
          content: <div className="title">Capacity</div>
        },
        {
          content: <div>{totalCapacity}h</div>
        },
        {
          content: <div></div>
        }
      ]
    }
  ];

  return renderToString(
    <StyledTooltipContainer>
      {!hasDatesRequired && <div>Over Dates Required</div>}
      {!hasDatesRequired && <hr className="divider" />}
      <HTMLTable bodyRows={bodyRows} />
    </StyledTooltipContainer>
  );
};

const MemberHoursCell = ({ row }: MemberGroupRowProps) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  const {
    member,
    phase,
    phaseMembership,
    activityPhase,
    activityPhaseMembership,
    projectTotal,
    phaseTotals,
    accountId,
    teamMembershipsByAccountId,
    customRowProps: {
      showDemoSuggestions,
      unassignedMemberBudgetId,
      projectId,
      handleOpenMergeBudgetModal,
      resetUnassignedRoleAndSelectedPhaseIds
    }
  } = row.original;
  const isDefaultActivityPhase = activityPhase.is_default;

  const {
    openMenu,
    menuRef,
    assignToPhasesDropdownRef,
    MemberOptionMenu,
    AssignToPhasesDropdown
  } = useMemberOptions({
    handleOpenMergeBudgetModal,
    resetUnassignedRoleAndSelectedPhaseIds,
    teamMembershipsByAccountId,
    accountId,
    unassignedMemberBudgetId,
    projectId,
    projectTotal,
    phaseTotals,
    phaseId: phase.id
  });

  // Either phase or activity phase.
  // If the phase does not have active activity phase, it still has a "default activity phase"
  // isDefaultActivityPhase = true means we are showing the parent phase's date
  const { startDateToUse, endDateToUse } = getPhaseDatesData({
    phaseEntity: isDefaultActivityPhase ? phase : activityPhase,
    activityPhaseMembership
  });

  const { totalAvailability, totalCapacity, availabilityToCapacityRatio } =
    calculateMemberHours({
      member,
      startDate: startDateToUse,
      endDate: endDateToUse
    });

  const sections = useMemo(
    () => [
      {
        sectionBackground: theme.colors.colorMediumPurple2,
        width: `${availabilityToCapacityRatio}%`,
        height: ''
      }
    ],
    [availabilityToCapacityRatio]
  );

  return (
    <StyledContainer
      {...defaultTooltipProps}
      data-tip={getBarTooltip({
        totalAvailability,
        totalCapacity,
        availabilityToCapacityRatio,
        hasDatesRequired: startDateToUse && endDateToUse
      })}
    >
      <div className="bar-container">
        <div className="total-available-hours">{totalAvailability}h</div>
        <SimpleBar sections={sections} />
      </div>
      <StyledPlusIcon
        data-tip="Add to Project/Phase"
        data-for="app-tooltip"
        data-effect="solid"
        className="add-icon"
        ref={menuRef}
        onClick={openMenu}
      >
        <PlusCircleIcon />
      </StyledPlusIcon>
      <div ref={assignToPhasesDropdownRef} />
      <MemberOptionMenu />
      <AssignToPhasesDropdown />
    </StyledContainer>
  );
};

export default MemberHoursCell;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.colorMediumGray9};
  width: 100%;
  height: 100%;
  font-size: 13px;
  padding-top: 8px;

  .bar-container {
    text-align: center;
    width: 100%;
    max-width: 70%;
  }

  .total-available-hours {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .add-icon {
    padding: 7px 7px 0 7px;
  }
`;

const StyledTooltipContainer = styled.div`
  .title {
    text-align: left;
    padding-right: 12px;
  }

  .ratio {
    color: ${theme.colors.colorLightGray6};
  }

  hr {
    border-color: white;
    width: 80%;
  }
`;
