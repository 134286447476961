import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getSelectedProjectComments,
  getSortedProjectCommentsAndMetadata,
  getSelectedTeamId,
  getMe
} from 'selectors';
import {
  createProjectComment,
  editProjectComment,
  deleteProjectComment
} from 'actionCreators';

import {
  ProjectAddCommentContainer,
  ProjectCommentThreadContainer,
  MetadataRow
} from '../..';
import { CommentScrollBox } from 'views/taskDisplay/taskModalComponents/styles';
import ReactTooltip from 'react-tooltip';
import { Waypoint } from 'react-waypoint';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const ITEMS_PER_PAGE = 30;

class ProjectCommentsContainer extends React.Component {
  state = {
    page: 1
  };

  incrementPage = () =>
    this.setState((prevState) => ({ page: prevState.page + 1 }));

  componentDidUpdate(prevProps, prevState) {
    const { commentsAndMetadata } = this.props;
    const { page } = this.state;
    if (
      commentsAndMetadata !== prevProps.commentsAndMetadata ||
      page !== prevState.page
    ) {
      rebuildTooltip();
    }
  }

  render() {
    const {
      project,
      me,
      comments,
      createProjectComment,
      editProjectComment,
      deleteProjectComment,
      commentsAndMetadata,
      selectedTeamId
    } = this.props;
    const { page } = this.state;
    const numItemsToRender = Math.min(
      page * ITEMS_PER_PAGE,
      commentsAndMetadata.length
    );
    return (
      <div className="comments-container">
        <ProjectAddCommentContainer
          project={project}
          me={me}
          createComment={createProjectComment}
          selectedTeamId={selectedTeamId}
          hasComments={comments.length}
        />
        <ReactTooltip
          className="notification-tooltip"
          effect="solid"
          id="comment-icon"
          place="top"
        />
        <CommentScrollBox>
          {commentsAndMetadata
            .slice(0, numItemsToRender)
            .map((commentOrMetadata) =>
              commentOrMetadata.is_comment ? (
                <ProjectCommentThreadContainer
                  key={`comment ${commentOrMetadata.id}`}
                  me={me}
                  project={project}
                  commentThread={commentOrMetadata}
                  createComment={createProjectComment}
                  updateComment={editProjectComment}
                  deleteComment={deleteProjectComment}
                  selectedTeamId={selectedTeamId}
                />
              ) : (
                <MetadataRow
                  key={`metadata ${commentOrMetadata.id}`}
                  metadata={commentOrMetadata}
                />
              )
            )}

          <Waypoint onEnter={this.incrementPage} />
        </CommentScrollBox>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: getMe(state),
  comments: getSelectedProjectComments(state),
  commentsAndMetadata: getSortedProjectCommentsAndMetadata(state),
  selectedTeamId: getSelectedTeamId(state)
});

const mapDispatchToProps = {
  createProjectComment,
  editProjectComment,
  deleteProjectComment
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectCommentsContainer)
);
