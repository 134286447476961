import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { ReadonlyDeep } from 'type-fest';

interface MergedThrottleParams<
  ValueType,
  MergedValueType,
  AdditionalParamsType
> {
  delay: number;
  /**
   * The initial value for the accumulated value
   */
  initialValue: ReadonlyDeep<MergedValueType>;
  /**
   * The throttled/debounced callback. additionalParams can be used for passing dynamic values
   */
  func: ({
    mergedValue,
    additionalParams
  }: {
    mergedValue: ReadonlyDeep<MergedValueType>;
    additionalParams?: AdditionalParamsType;
  }) => void;
  /**
   * The function that will calculate the next merged value. additionalParams can be used for more
   * complex logic that requires knowledge of the merged value as well as some dynamic values
   */
  merge: ({
    value,
    mergedValue,
    additionalParams
  }: {
    value: ValueType;
    mergedValue: ReadonlyDeep<MergedValueType>;
    additionalParams?: AdditionalParamsType;
  }) => ReadonlyDeep<MergedValueType>;
  /**
   * lodash debounce/throttle options
   */
  options?: { leading: boolean; trailing: boolean };
  /**
   * When true, will use debounce instead of throttle
   */
  isDebounce?: boolean;
}

/**
 * Throttle/debounce that can accumulate values. The callback will be throttled/debounced and
 * the accumulated value will be reset whenever it's called.
 *
 * eg. for accumulating ids to fetch, provide:
 *    - initial value of []
 *    - callback to dispatch the fetch action using the accumulated ids
 *    - merge function to handle a new id by appending it to the array. the function would also handle
 *      preventing duplicates
 */
export const mergedThrottle = <
  ValueType,
  MergedValueType,
  AdditionalParamsType = never
>({
  delay,
  func,
  options = {
    leading: false,
    trailing: true
  },
  initialValue,
  merge,
  isDebounce = false
}: MergedThrottleParams<ValueType, MergedValueType, AdditionalParamsType>) => {
  let mergedValue = initialValue;

  const debounceOrThrottle = isDebounce ? debounce : throttle;

  const throttled = debounceOrThrottle(
    (additionalParams?: AdditionalParamsType) => {
      func({ mergedValue, additionalParams });
      mergedValue = initialValue;
    },
    delay,
    options
  );

  return ({
    value,
    additionalParams
  }: {
    value: ValueType;
    additionalParams?: AdditionalParamsType;
  }) => {
    mergedValue = merge({
      value,
      mergedValue,
      additionalParams
    });
    throttled(additionalParams);
  };
};
