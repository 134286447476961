import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';

/**
 *  @param {array} customSortAttributes An array of objects with custom orders and attributes
 *  customSortAttributes =
 *    {
 *      main: [
 *        {
 *          sortAttribute: 'demand_percentage',
 *          sortOrder: SORT_ORDER.desc
 *        },
 *        {
 *          sortAttribute: 'demand',
 *          sortOrder: SORT_ORDER.desc
 *        }
 *        {
 *          sortAttribute: 'demand',
 *          sortOrder: SORT_ORDER.asc
 *        }
 *      ],
 *      capacity: [
 *        {
 *          sortATtribute: 'capacity',
 *          sortOrder: SORT_ORDER.desc
 *        }
 *        {
 *          sortAttribute: 'capacity',
 *          sortOrder: SORT_ORDER.asc
 *        }
 *      ]
 *    }
 *  @returns {object} of functions
 *  This will return an object with functions.
 *  Index 0 of customSortAttributes.main is default sort type for any sort attributes of the page you want to use.
 *  It will save the filter values as null on the backend when it goes back to default.
 *  make useCallbacks with updateToNextSortOrder and give columnType if you want to include additional sort attributes from different column.
 *  const updateSortAttributes = useCallback(() => updateToNextSortOrder({ activeFilter, pageName, viewBy })) for default,
 *  const updateSortAttributesByCapacity = useCallback(() => updateToNextSortOrder({ activeFilter, pageName, viewBy, columnType: 'capacity' }))
 */

const emptyObj = {};

const getCurrentSortIndex = ({
  sortAttribute,
  sortOrder,
  customSortAttributes
}) => {
  // return 0 for null which is default
  if (!sortAttribute || !sortOrder) return 0;

  const index = customSortAttributes.findIndex(
    (order) =>
      order.sortAttribute === sortAttribute && order.sortOrder === sortOrder
  );

  // it will return for -1 when switching between main to additional sort attributes and adds up to 0 when used in getNextSortParams
  return index;
};

const getNextSortParams = ({
  customSortAttributes,
  currentIndex,
  columnType
}) => {
  const nextIndex =
    (currentIndex + 1) % customSortAttributes[columnType].length;

  return {
    sortAttribute:
      nextIndex || columnType !== 'main' // prevent additional sort option to be null and goes back to main
        ? customSortAttributes[columnType][nextIndex].sortAttribute
        : null,
    sortOrder:
      nextIndex || columnType !== 'main'
        ? customSortAttributes[columnType][nextIndex].sortOrder
        : null
  };
};

const useCustomSortAttributes = ({ customSortAttributes }) => {
  const dispatch = useDispatch();

  const updateToNextSortOrder = useCallback(
    ({ activeFilter = emptyObj, columnType = 'main' }) => {
      if (activeFilter.custom) {
        const { sortAttribute, sortOrder } = activeFilter.custom;
        const currentIndex = getCurrentSortIndex({
          sortAttribute,
          sortOrder,
          customSortAttributes: customSortAttributes[columnType]
        });
        const nextSortParams = getNextSortParams({
          currentIndex,
          customSortAttributes,
          columnType
        });

        dispatch(
          updateAccountFilterLocal({
            ...activeFilter,
            custom: {
              ...activeFilter.custom,
              ...nextSortParams
            }
          })
        );
      }
    },
    [customSortAttributes, dispatch]
  );

  const getSortParams = useCallback(
    ({ sortAttribute, sortOrder }) => {
      return {
        attribute: sortAttribute || customSortAttributes.main[0].sortAttribute,
        direction: sortOrder || customSortAttributes.main[0].sortOrder
      };
    },
    [customSortAttributes]
  );

  return { getSortParams, updateToNextSortOrder };
};

export default useCustomSortAttributes;
