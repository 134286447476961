import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useArrayFilterField } from './useArrayFilterField';
import { PHASE_BUDGET_STATUS_DISPLAY } from 'appConstants/budgetStatuses';
import mapValues from 'lodash/mapValues';

const emptyObj = {};

const phaseBudgetStatuses = Object.keys(PHASE_BUDGET_STATUS_DISPLAY).filter(
  (status) => status !== 'archived'
);

const statusHash = mapValues(PHASE_BUDGET_STATUS_DISPLAY, (label) => ({
  label
}));

export const usePhaseBudgetStatusFilter: FilterListTypeHookWithItemHash = ({
  isOff,
  shouldUseDraft,
  resultCountHash,
  field = FilterField.phaseBudgetStatus
} = emptyObj) => {
  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: phaseBudgetStatuses,
    itemHash: statusHash,
    shouldMaintainOrder: true,
    shouldFilterResultsWithNoCount: false,
    isOff,
    resultCountHash,
    isSearchDisabled: true,
    labelKey,
    shouldUseDraft
  });

  return {
    ...arrayFilterFieldValues,
    itemHash: statusHash,
    labelKey,
    resultCountHash
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'label';
